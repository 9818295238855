import { useDispatch, useSelector } from "react-redux";
import { activeDeck, closeCard, endDrag, selectCard, setDragCard, setNextStep, setPrevStep, setStartDarg, toggleCardExpanded } from "redux/card/cardSlice";
import { selectIntl } from "redux/intl/intlSlice";
import ScratchCards from "./card-component/cards";
import { openTipsLibrary } from "redux/asset-lib/assetLibStatusSlice";

export const Cards = () => {
  const { visible, content, activeDeckId, step, x, y, dragging, expanded } =
    useSelector(selectCard);
  const { isRtl, locale } = useSelector(selectIntl);
  const dispatch = useDispatch();
  const onActivateDeckFactory = (deckId: string) => dispatch(activeDeck(deckId));
  const onShowAll = () => {
    dispatch(openTipsLibrary())
    dispatch(closeCard());
  };
  const onCloseCards = () => dispatch(closeCard())
  const onShrinkExpandCards = () => dispatch(toggleCardExpanded())
  const onNextStep = () => dispatch(setNextStep())
  const onPrevStep = () => dispatch(setPrevStep())
  const onDrag = (e_, data) => {
    dispatch(setDragCard({ x: data.x, y: data.y }))
  }
  const onStartDrag = () => dispatch(setStartDarg())
  const onEndDrag = () => dispatch(endDrag())

  return (
    <ScratchCards
      expanded={expanded}
      visible={visible}
      content={content}
      activeDeckId={activeDeckId as string}
      step={step}
      x={x}
      y={y}
      dragging={dragging}
      isRtl={isRtl}
      locale={locale}
      dispatch={dispatch}
      onActivateDeckFactory={onActivateDeckFactory}
      onShowAll={onShowAll}
      onCloseCards={onCloseCards}
      onShrinkExpandCards={onShrinkExpandCards}
      onNextStep={onNextStep}
      onPrevStep={onPrevStep}
      onDrag={onDrag}
      onStartDrag={onStartDrag}
      onEndDrag={onEndDrag}
      showVideos={true}
    />
  );
};
