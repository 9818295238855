import { useEffect } from "react";

const FONTS = [
    'Sans Serif',
    'Serif',
    'Handwriting',
    'Marker',
    'Curly',
    'Pixel',
    'Scratch'
];

let fontsLoaded = false
export const useFontLoad = () => {
    useEffect(() => {
        if (fontsLoaded) return;
        const getFontPromises = () => {
            const fontPromises: any = [];
            // Browsers that support the font loader interface have an iterable document.fonts.values()
            // Firefox has a mocked out object that doesn't actually implement iterable, which is why
            // the deep safety check is necessary.
            if (document.fonts &&
                typeof document.fonts.values === 'function' &&
                typeof document.fonts.values()[Symbol.iterator] === 'function') {
                for (const fontFace of (document.fonts as any).values()) {
                    console.log(fontFace)
                    // Only load fonts from this list. If we load all fonts on the document, we may block on
                    // loading fonts from things like chrome extensions.
                    if (FONTS.indexOf(fontFace.family) !== -1) {
                        fontPromises.push(((fontFace as any).loaded as any));
                        fontFace.load();
                    }
                }
            }
            return fontPromises;
        };
        // Font promises must be gathered after the document is loaded, because on Mac Chrome, the promise
        // objects get replaced and the old ones never resolve.
        if (document.readyState === 'complete') {
            Promise.all(getFontPromises()).then(() => {
                fontsLoaded = true
            });
        } else {
            document.onreadystatechange = () => {
                if (document.readyState !== 'complete') return;
                document.onreadystatechange = null;
                Promise.all(getFontPromises()).then(() => {
                    fontsLoaded = true
                });
            };
        }
    }, [])
}