import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";
import Serialport, {SerialInfo} from "../../service/link-adapter/serialport";

interface SerialListState {
    list: SerialInfo[]
    started: boolean
}

const initialState: SerialListState = {
    started: false,
    list: []

}

let intervalId: any = null
export const serialListSlice = createSlice({
    name: 'alerts',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        startScanSerial: (state) => {
            state.started = true;
        },
        setSerialList: (state, action: PayloadAction<SerialInfo[]>) => {
            state.list = action.payload;
        }
    },
})

export const {startScanSerial, setSerialList} = serialListSlice.actions;
export const selectSerialList = (state: RootState) => state.serialList
export const selectSerialListStarted = (state: RootState) => state.serialList.started
export default serialListSlice.reducer
