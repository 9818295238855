
import {DeviceManager} from "./deviceManager";
import {DeviceDataType} from "../ext-asset-manager/device/type";
import VM, {vm} from "../../lib/scratch-vm";
import Serialport from "../link-adapter/serialport";
import ConnectScratch from './connectScratch';
import Burner from './burner';
import { IS_SCRATCH_MODE } from "config/config";
import { getFirmwarePath } from "service/ext-asset-manager/device/util";

/*
AppData/mind+/cache
tmp_xxxx ino文件, .o文件
cores/deviceId  .a文件

*/
export class DeviceTarget {
    deviceManager: DeviceManager
    vm: VM
    config: DeviceDataType
    device: any
    _port: string | null
    _outputMessages: string = ''
    _serial: Serialport | null = null
    _serialMessages: string = ''
    onlineConnectStatus: 0|1|2|3 = 0 // 未连接/连接中/连接成功/连接失败
    onlineConnectStatusCallback: Function|null = null;
    deviceObj: any = null // extension对象(getInfo)
    _connectScratch: ConnectScratch
    _burner: Burner
    constructor(deviceManager, vm, config) {
        this.deviceManager = deviceManager;
        this.vm = vm;
        this.config = config;
        // 当前的串口
        this._port = null;
        this._serial = null;
        this._connectScratch = new ConnectScratch(this);
        this._burner = new Burner(config);
        
    }

    // 获取主控扩展对象(getInfo)
    getDeviceExtensionObj() {
        if (this.deviceObj) return this.deviceObj;
        // 取出deviceObj
        this.deviceObj = this.vm.getExtensionObj(this.config.deviceIdWithVersion);
        return this.deviceObj;
    }

    async dispose() {
        // 停止block的运行
        vm.runtime.stopAll();
        // 断开连接
        if (IS_SCRATCH_MODE) await this._connectScratch.disconnect();
        // 触发removeDevice事件，通知串口连接组件断开
        vm.runtime.emit("removeDeviceConnect");

        // 清除主板
        vm.extensionManager.deleteExtension(this.config.deviceIdWithVersion)
    }

    isScratchConnected() {
        return this._connectScratch.isConnected();
    }

    connectScratch(port) {
        return this._connectScratch.connect(port);
    }

    disconnectScratch() {
        return this._connectScratch.disconnect();
    }


    burnFirmware(port, firmware) {
        return this.upload(port, getFirmwarePath(this.config, "online"));
    }   

    upload(port, file?: string) {
        return this._burner.upload(port, file);
    }

    compile(code: string) {
        return this._burner.compile(code);
    }

    // 是否支持擦除
    isSupportErase() {
        return this._burner.isSupportErase()
    }

    erase(port: string) {
        return this._burner.erase(port)
    }

    getTempDirPath() {
        return this._burner.getTempDirPath();
    }
}

