import ScratchTargetHighlight from './target-highlight'
import {vm} from "../../lib/scratch-vm";
import {useSelector} from "react-redux";
import {selectTargets} from "../../redux/tagets/targetsSlice";
import styles from './index.module.scss'
import {getStageDimensions} from "../../lib/screen-utils";
import {selectMode} from "../../redux/mode/modeSlice";
import {selectStageSize} from "../../redux/stage-size/stageSizeSlice";

export const TargetHighlight = () => {
    const {highlightedTargetId, highlightedTargetTime} = useSelector(selectTargets)
    const {isPlayerOnly, isFullScreen} = useSelector(selectMode)
    const {stageSize} = useSelector(selectStageSize)
    const stageDimensions = getStageDimensions(stageSize, isFullScreen);

    return <div className={styles.frameWrapper}>
        <ScratchTargetHighlight
            highlightedTargetTime={highlightedTargetTime}
            highlightedTargetId={highlightedTargetId}
            vm={vm}
            className={styles.frame}
            stageHeight={stageDimensions.height}
            stageWidth={stageDimensions.width}
        />
    </div>
}
