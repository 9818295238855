import { useEffect, useState } from "react";
import ScratchBlocks from "lib/scratch-blocks";
import { Modal } from "component/modal/index";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import styles from "./VariablePrompt.module.scss";
import Box from "component/box/box";
import { vm } from "lib/scratch-vm";
import classNames from "classnames";
import message from "component/df-message/Message";
import alertDialog from "component/df-alert/DFAlert";
const messages = defineMessages({
  prompt: {
      id: "gui.dialog.prompt",
      defaultMessage: "Note"
  },
  variableIsNull: {
      id: 'gui.dialog.variableIsNull',
      defaultMessage: 'Variable name cannot be empty'
  },
  listNameIsNull: {
      id: 'gui.dialog.listNameIsNull',
      defaultMessage: 'list name cannot be empty'
  },
  variableNameSpecialCharacters: {
      id: 'gui.dialog.variableNameSpecialCharacters',
      defaultMessage: 'variable name %1 cannot contain special characters:[\`~!@#$%^&*()+<>?:"{},.\/;\'[]\\]'
  },
  forAllSpritesMessage: {
    defaultMessage: "For all sprites",
    description:
      "Option message when creating a variable for making it available to all sprites",
    id: "gui.gui.variableScopeOptionAllSprites",
  },
  forThisSpriteMessage: {
    defaultMessage: "For this sprite only",
    description:
      "Option message when creating a varaible for making it only available to the current sprite",
    id: "gui.gui.variableScopeOptionSpriteOnly",
  },
  cloudVarOptionMessage: {
    defaultMessage: "Cloud variable (stored on server)",
    description:
      "Option message when creating a variable for making it a cloud variable, a variable that is stored on the server" /* eslint-disable-line max-len */,
    id: "gui.gui.cloudVariableOption",
  },
  availableToAllSpritesMessage: {
    defaultMessage: "This variable will be available to all sprites.",
    description:
      "A message that displays in a variable modal when the stage is selected indicating " +
      "that the variable being created will available to all sprites.",
    id: "gui.gui.variablePromptAllSpritesMessage",
  },
});
const VariablePrompt = () => {
  const intl = useIntl();
  const [prompt, setPrompt] = useState<any>(null);
  const [globalSelected, setGlobalSelected] = useState<boolean>(true);
  const [newText, setNewText] = useState("");
  useEffect(() => {
    // 替换新建变量时的对话框
    ScratchBlocks.prompt = (
      message: string,
      defaultValue: string,
      callback: any,
      optTitle?: string,
      optVarType?: string
    ) => {
      const prompt: any = { callback, message, defaultValue };
      prompt.title = optTitle
        ? optTitle
        : ScratchBlocks.Msg.VARIABLE_MODAL_TITLE;
      prompt.varType =
        typeof optVarType === "string"
          ? optVarType
          : ScratchBlocks.SCALAR_VARIABLE_TYPE;
      prompt.showVariableOptions = // This flag means that we should show variable/list options about scope
        optVarType !== ScratchBlocks.BROADCAST_MESSAGE_VARIABLE_TYPE &&
        prompt.title !== ScratchBlocks.Msg.RENAME_VARIABLE_MODAL_TITLE &&
        prompt.title !== ScratchBlocks.Msg.RENAME_LIST_MODAL_TITLE;
      prompt.showCloudOption = false;
      setNewText(defaultValue);
      // p.prompt.showCloudOption = optVarType === this.ScratchBlocks.SCALAR_VARIABLE_TYPE &&this.props.canUseCloud;
      setPrompt(prompt);
    };
    return () => {
      ScratchBlocks.prompt = null;
    };
  }, []);
  const handleCloudVariableOptionChange = (e) => { };
  const handleOk = () => {
    // 变量名是否为空
    if (!newText) {
      if (prompt.varType === "list") {
        alertDialog(intl.formatMessage(messages.prompt), intl.formatMessage(messages.listNameIsNull), { timeout: 3000, mode: 1 });
      } else {
        alertDialog(intl.formatMessage(messages.prompt), intl.formatMessage(messages.variableIsNull), { timeout: 3000, mode: 1 });
      }
      return;
    }
    // 是否有特殊字符
    const reg = /[`~!\-@#$%^&*()+<>?:"{},.\/;'[\]\\]/im
    if (reg.test(newText)) {
      alertDialog(intl.formatMessage(messages.prompt), intl.formatMessage(messages.variableNameSpecialCharacters).replace('%1', newText), { timeout: 3000, mode: 1 });
      return;
    }
    prompt.callback(newText, vm.runtime.getAllVarNamesOfType(prompt.varType), {
      scope: globalSelected ? "global" : "local",
      isCloud: false,
    });
    // 恢复默认值
    setPrompt(null);
    setNewText("");
    setGlobalSelected(true);
  };
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleOk();
    }
  };
  return (
    <>
      {prompt && (
        <PromptComponent
          title={prompt?.title}
          canAddCloudVariable={false}
          cloudSelected={false}
          defaultValue={prompt?.defaultValue}
          globalSelected={globalSelected}
          isStage={vm.runtime.getEditingTarget().isStage}
          label={prompt?.message}
          showCloudOption={false}
          onScopeOptionSelection={(e) =>
            setGlobalSelected(e.target.value === "global")
          }
          showVariableOptions={prompt?.showVariableOptions}
          onCancel={() => setPrompt(null)}
          onChange={(e) => setNewText(e.target.value)}
          onCloudVarOptionChange={handleCloudVariableOptionChange}
          onFocus={(e) => e.target.select()}
          onKeyPress={handleKeyPress}
          onOk={handleOk}
        />
      )}
    </>
  );
};
const PromptComponent = (props) => (
  <Modal
    className={styles.modalContent}
    contentLabel={props.title}
    onRequestClose={props.onCancel}
  >
    <Box className={styles.body}>
      <Box className={styles.label}>{props.label}</Box>
      <Box>
        <input
          autoFocus
          className={styles.variableNameTextInput}
          defaultValue={props.defaultValue}
          name={props.label}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onKeyPress={props.onKeyPress}
        />
      </Box>
      {props.showVariableOptions ? (
        <div>
          {props.showCloudOption ? (
            <Box className={classNames(styles.cloudOption)}>
              <label
                className={classNames({
                  [styles.disabledLabel]: !props.canAddCloudVariable,
                })}
              >
                <input
                  checked={props.cloudSelected && props.canAddCloudVariable}
                  disabled={!props.canAddCloudVariable}
                  type="checkbox"
                  onChange={props.onCloudVarOptionChange}
                />
                <FormattedMessage {...messages.cloudVarOptionMessage} />
              </label>
            </Box>
          ) : null}
        </div>
      ) : null}

      <Box className={styles.buttonRow}>
        <button className={styles.cancelButton} onClick={props.onCancel}>
          <FormattedMessage
            defaultMessage="Cancel"
            description="Button in prompt for cancelling the dialog"
            id="gui.prompt.cancel"
          />
        </button>
        <button className={styles.okButton} onClick={props.onOk}>
          <FormattedMessage
            defaultMessage="OK"
            description="Button in prompt for confirming the dialog"
            id="gui.prompt.ok"
          />
        </button>
      </Box>
    </Box>
  </Modal>
);
export default VariablePrompt;
