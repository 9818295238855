import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectProject } from "redux/project/projectSlice";

export const usePageLeaveConfirm = () => {
  const {projectChanged} = useSelector(selectProject);
  useEffect(() => {
    window.onbeforeunload = (e: any) => {
      if (projectChanged) {
        (e || window.event).returnValue = true;
        return true;
      }
      return
    };
    return () => { 
      window.onbeforeunload = null;
    }
  }, [projectChanged]);
};
