import {useEffect, useRef, useState} from "react";
import {vm} from 'lib/scratch-vm'
import {useDispatch, useSelector} from "react-redux";
import {selectMode} from "redux/mode/modeSlice";
import {selectSoundRecorderModal} from "redux/modals/modalsSlice";
import throttle from 'lodash.throttle';
import {updateBlockDrag} from "redux/block-drag/blockDragSlice";
import {setRunningState, setStartedState, setTurboState} from "redux/vm-status/vmStatusSlice";
import {updateMicIndicator} from "redux/mic-indicator/micIndicatorSlice";
import {showExtensionAlert} from "redux/alerts/alertsSlice";
import {updateEditingTarget, updateTargets} from "redux/tagets/targetsSlice";
import message from "component/df-message/Message";
import { selectProject, setProjectChanged } from "redux/project/projectSlice";

const handleKeyDown = (e) => {
    // Don't capture keys intended for Blockly inputs.
    if (e.target !== document && e.target !== document.body) return;

    const key = (!e.key || e.key === 'Dead') ? e.keyCode : e.key;
    vm.postIOData('keyboard', {
        key: key,
        isDown: true
    });

    // Prevent space/arrow key from scrolling the page.
    if (e.keyCode === 32 || // 32=space
        (e.keyCode >= 37 && e.keyCode <= 40)) { // 37, 38, 39, 40 are arrows
        e.preventDefault();
    }
}

const handleKeyUp = (e) => {
    // Always capture up events,
    // even those that have switched to other targets.
    const key = (!e.key || e.key === 'Dead') ? e.keyCode : e.key;
    vm.postIOData('keyboard', {
        key: key,
        isDown: false
    });

    // E.g., prevent scroll.
    if (e.target !== document && e.target !== document.body) {
        e.preventDefault();
    }
}

type Props = {
    username?: string
}

/**
* @Description: vm全局监听，修改对应redux状态
* @author LiuSheng
* @date 2023/5/4
*/
export const useVmListener = (props: Props) => {
    const {username} = props;
    const [initState, setInitState] = useState(false);
    const dispatch = useDispatch();
    const mode = useSelector(selectMode);
    const soundRecorderModal = useSelector(selectSoundRecorderModal);
    const {projectChanged} = useSelector(selectProject);
    const shouldUpdateTargets = !mode.isFullScreen && !mode.isPlayerOnly && !soundRecorderModal;
    const shouldUpdateProjectChanged = !mode.isFullScreen && !mode.isPlayerOnly;
    const isFirst = useRef(true);

    // dev block运行时, 未连接报错
    useEffect(() => {
        const handleNoteConnectedError = () => {
            message.error("主控未连接!", 3000, "DEVICE_NOT_CONNECTED");
        }
        vm.on("DEVICE_NOT_CONNECTED", handleNoteConnectedError)
        return () => {
            vm.removeListener("DEVICE_NOT_CONNECTED", handleNoteConnectedError)
        }
    }, [])

    // 监听block拖动
    useEffect(() => {
        const onBlockDragUpdate = throttle((data)=>dispatch(updateBlockDrag(data)), 30);
        vm.on('BLOCK_DRAG_UPDATE', onBlockDragUpdate);
        return () => {
            vm.removeListener('BLOCK_DRAG_UPDATE', onBlockDragUpdate)
        }
    }, [])

    // 监听turbo 加速模式
    useEffect(() => {
        const onTurboModeOn = () => dispatch(setTurboState(true))
        const onTurboModeOff = () => dispatch(setTurboState(false))
        vm.on('TURBO_MODE_ON', onTurboModeOn);
        vm.on('TURBO_MODE_OFF', onTurboModeOff);
        return () => {
            vm.removeListener('TURBO_MODE_ON', onTurboModeOn);
            vm.removeListener('TURBO_MODE_OFF', onTurboModeOff);
        };
    }, []);

    // 监听project运行事件
    useEffect(() => {
        console.log('监听vm 启动');
        const onProjectRunStart = () => dispatch(setRunningState(true))
        const onProjectRunStop = () => dispatch(setRunningState(false))
        const onRuntimeStarted = () => dispatch(setStartedState(true))
        vm.on('PROJECT_RUN_START', onProjectRunStart);
        vm.on('PROJECT_RUN_STOP', onProjectRunStop);
        // 这个事件在scratch中是个空函数, 因为运行事件头时, 会判断当前thread数量, 去触发'PROJECT_RUN_START'/'PROJECT_RUN_STOP'
        // vm.on('PROJECT_START', onGreenFlag);
        vm.on('RUNTIME_STARTED', onRuntimeStarted);
        return () => {
            vm.removeListener('PROJECT_RUN_START', onProjectRunStart);
            vm.removeListener('PROJECT_RUN_STOP', onProjectRunStop);
            vm.removeListener('RUNTIME_STARTED', onRuntimeStarted);
        }
    }, [])

    // 监听project改变事件
    useEffect(() => {
        const handleProjectChanged = () => {
            if (shouldUpdateProjectChanged && !projectChanged) {
                dispatch(setProjectChanged())
            }
        }
        vm.on('PROJECT_CHANGED', handleProjectChanged);
        return () => {
            vm.removeListener('PROJECT_CHANGED', handleProjectChanged);
        }
    }, [shouldUpdateProjectChanged, projectChanged])

    // 监听键盘事件, 并传递给vm
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    // 当用户发生改变时, 传递给vm
    useEffect(() => {
        vm.postIOData('userData', {username: username});
    }, [username])

    // 监听外设连接错误事件, 因为从某个项目打开scratch软件时, 页面未渲染完成时, 项目已经加载完成并要去连接扩展的外设
    useEffect(() => {
        const onShowExtensionAlert = (data) => dispatch(showExtensionAlert(data))
        vm.on('PERIPHERAL_CONNECTION_LOST_ERROR', onShowExtensionAlert);
        return () => {
            vm.removeListener('PERIPHERAL_CONNECTION_LOST_ERROR', onShowExtensionAlert);
        }
    }, [])

    //
    useEffect(() => {
        const onMicListeningUpdate = (listening) => dispatch(updateMicIndicator(listening))
        vm.on('MIC_LISTENING', onMicListeningUpdate);
        return () => {
            vm.removeListener('MIC_LISTENING', onMicListeningUpdate);
        }
    }, [])

    useEffect(() => {
        const handleTargetsUpdate = (data) => {
            if (shouldUpdateTargets) {
                // 刷新editingTarget
                dispatch(updateEditingTarget(data.editingTarget));
                // 通知相关页面targets数据有更新
                dispatch(updateTargets());
            }
        }
        vm.on('targetsUpdate', handleTargetsUpdate);
        return () => {
            vm.removeListener('targetsUpdate', handleTargetsUpdate)
        };
    }, [shouldUpdateTargets]);

    // 挂载targets监听事件
    useEffect(() => {
        // shouldUpdateTargets刷新, 从[不能渲染] --> [允许渲染], 必须要刷新一次
        // isFirst用于控制刷新时机, 页面第一次挂载时, vm还未初始化完成, 不能触发刷新
        if (shouldUpdateTargets && !isFirst.current) vm.emitTargetsUpdate(false /* Emit the event, but do not trigger project change */);
        isFirst.current = false;
    }, [shouldUpdateTargets]);

    useEffect(() => {
        // 副作用函数是顺序执行的,
        // 在最后一个副作用函数设置初始化状态, 表示[组件挂载]钩子中的内容执行完成
        setInitState(true);
    }, [])

    return {
        isVmListenerInit: initState
    }
}

