import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "redux/store";


const initialState = {
    code: ''
}

export const arduinoCodeSlice = createSlice({
    name: 'alerts',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCode: (state, action: PayloadAction<any>) => {
            state.code = action.payload
        }
    },
})

export const {setCode} = arduinoCodeSlice.actions;
export const selectArduinoCode = (state: RootState) => state.arduinoCode

export default arduinoCodeSlice.reducer
