import storage from "lib/storage"
import { useCallback, useEffect, useRef } from "react"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { resetProject, selectProject, setCanSave, setProjectChanged, setProjectError, setProjectId, setProjectLoadingStatus, setProjectPath, setProjectSavingStatus, setProjectTitle, setProjectUnchanged } from "redux/project/projectSlice"
import { selectUser } from "redux/user/userSlice"
import query from 'querystring';
import fs from "service/link-adapter/fs"
import { vm } from "lib/scratch-vm"
import { IS_WEB_PLATFORM } from "config/config"
import defaultProject from "lib/default-project"
import message from "component/df-message/Message"
import { createProject_API, getProjectData_API, getProjectInfo_API, saveProjectData_API, saveProjectInfo_API, updateProjectThumbnail_API } from "service/api/project-api"
import projectData from "lib/default-project/project-data"
import { useGetUserInfo, useLogout } from "service/user/useUser"
import { selectAndUploadLocaleProject } from "view/menu-bar/util/selectAndUploadLocaleProject"
import { getFileName } from "../../util/util"
import { selectIntl } from "redux/intl/intlSlice"
import path from "path"
import dataURItoBlob from "lib/data-uri-to-blob"
import { showBlockTab } from "redux/tab/tabSlice"

export const useProject = () => {
    const { projectId, projectTitle, canSave } = useSelector(selectProject)
    const { token } = useSelector(selectUser)
    const loadCloudProject = useLoadCloudProject();
    const loadLocalProject = useLoadLocalProject();
    const createProject = useCreateProject();
    const saveProject = useSaveProject();
    const getUserInfo = useGetUserInfo()
    const logout = useLogout()

    // 当软件启动时, 加载项目
    useEffect(() => {
        // 是否是未加载状态
        if (projectId !== -1) return;
        // 获取一次用户信息, 验证登录是否过期
        if (token) {
            getUserInfo().catch(() => {
                // 登录过期, 清除token
                logout();
            })
        }
        // 解析url
        const subPath = window.location.pathname.split("/");
        const projectId_ = parseInt(subPath[2]);
        const urlParams = query.parse(window.location.search.substring(1));
        const projectFile = urlParams["projectFile"] as string
        // 云端项目
        if (projectId_) {
            console.log("加载云端项目:", projectId_)
            loadCloudProject(projectId_)
            return;
        }
        // 离线版, 双击sb3打开
        if (projectFile) {
            console.log("sb3双击打开:", projectFile)
            loadLocalProject(projectFile)
            return;
        }
        // 创建默认项目
        createProject();
    }, []) // 只需在软件启动时判断, 无需依赖项

    useEffect(() => {
        // 修改url
        if (projectId !== 0 && projectId !== -1) {
            window.history.pushState({}, projectTitle + "on Mind+ V2.0", `/projects/${projectId}/editor`);
        } else {
            window.history.pushState({}, "Mind+ V2.0", `/projects/editor`);
        }
    }, [projectId, projectTitle])

    // 监听键盘, 保存项目
    useEffect(() => {
        const handleKeyPress = (event) => {
            const isMac = /Mac/.test(navigator.userAgent);
            const modifier = isMac ? event.metaKey : event.ctrlKey;
            if (modifier && event.key === 's') {
                saveProject();
                event.preventDefault();
            }
        }
        document.addEventListener("keydown", handleKeyPress)
        return () => {
            document.removeEventListener("keydown", handleKeyPress)
        }
    }, [saveProject])
}

// 加载云端项目
export const useLoadCloudProject = () => {
    const dispatch = useDispatch()
    const updateProjectInfo = useUpdateProjectInfo()

    const loadCloudProject = (projectId_: number) => {
        console.log("加载云端项目")
        dispatch(setProjectLoadingStatus("loading"))
        // 切换到block-tab
        dispatch(showBlockTab())
        // 获取项目信息
        return updateProjectInfo(projectId_)
            // 获取项目数据
            .then(() => getProjectData_API(projectId_))
            .then((data) => {
                return vm.loadProject(data)
            })
            .then(() => {
                dispatch(setProjectLoadingStatus(false))
                dispatch(setProjectUnchanged())
            })
            .catch(e => {
                dispatch(setProjectError(e))
            })
    }

    return loadCloudProject
}

// 刷新项目信息
export const useUpdateProjectInfo = () => {
    const dispatch = useDispatch()
    const { username } = useSelector(selectUser)
    const updateProjectInfo = (projectId_: number) => {
        console.log("刷新项目信息")
        return getProjectInfo_API(projectId_)
            .then(projectInfo => {
                if (!projectInfo) return Promise.reject("fetch project info error.")
                dispatch(setProjectId(projectInfo.id))
                // 当前用户是项目创建者
                if (projectInfo.author.username === username) {
                    dispatch(setCanSave(true))
                }
            })
    }
    return updateProjectInfo
}

// 加载本地项目
export const useLoadLocalProject = () => {
    const dispatch = useDispatch();
    const { locale } = useSelector(selectIntl)
    const { token } = useSelector(selectUser)
    const createCloudProject = useCreateCloudProject()

    const loadLocalProject = (projectPath?: string) => {
        console.log("加载本地项目")

        let filePromise: any = Promise.resolve;
        // 传递projectPath, 指定读取某个路径的sb3
        if (projectPath) {
            filePromise = () => {
                dispatch(setProjectLoadingStatus("loading"))
                // 切换到block-tab
                dispatch(showBlockTab())
                return fs.readFile(projectPath, { encoding: "binary" })
                    .then((data) => {
                        return vm.loadProject(data)
                    })
                    .then(() => path.basename(projectPath))
            }
        } else {
            // projectPath为空, 弹出文件选择框
            // TODO: 离线版是否改成electron弹窗
            filePromise = () => {
                return selectAndUploadLocaleProject().then((res: any) => {
                    dispatch(setProjectLoadingStatus("loading"))
                    // 切换到block-tab
                    dispatch(showBlockTab())
                    return vm.loadProject(res[0]).then(() => res[1])
                })
            }
        }
        return filePromise()
            .then((fileName) => {
                dispatch(resetProject())
                // 修改项目标题
                const uploadedProjectTitle = getFileName(locale, fileName)
                dispatch(setProjectTitle(uploadedProjectTitle))
                // 关闭loading界面
                dispatch(setProjectLoadingStatus(false))
                // 在线版 已登录状态, 将本地文件保存至云端
                if (token && IS_WEB_PLATFORM) {
                    createCloudProject(uploadedProjectTitle, JSON.stringify(vm.toJSON()))
                }
            })
            .catch(e => {
                // 本地加载出错, 报错
                dispatch(setProjectError(e))
            })
    }

    return loadLocalProject
}


// 创建新项目
export const useCreateProject = () => {
    const { token } = useSelector(selectUser)
    const intl = useIntl();
    const dispatch = useDispatch();
    const { locale } = useSelector(selectIntl)

    const createProject = () => {
        const defaultProjectData = projectData(intl.formatMessage)
        const createPromise = () => {
            // 已登录
            if (token) {
                // 在线版
                if (IS_WEB_PLATFORM) {
                    return createProject_API(defaultProjectData);
                }
                // 离线版
                else {
                    // 是创建云端项目, 自动保存?
                    // or 创建本地项目, 缓存?
                    // 当前方案: 离线版创建本地项目, 所以此处不做处理
                }
            }
            return Promise.resolve()
        }

        dispatch(setProjectLoadingStatus("creating"))
        // 切换到block-tab
        dispatch(showBlockTab())
        // 先清除url中的项目id
        dispatch(setProjectId(0))

        createPromise()
            // 无论api调用是否成功, vm都需要加载项目
            .catch((e) => {
                message.error("创建云端项目失败:", e)
            })
            .then((projectInfo) => {
                const projectId_ = projectInfo?.projectId || 0;
                const projectTitle_ = projectInfo?.projectTitle || getFileName(locale);
                dispatch(setProjectId(projectId_))
                dispatch(setProjectTitle(projectTitle_))
                if (projectInfo) dispatch(setCanSave(true));
            })
            .then(() => {
                return vm.loadProject(defaultProjectData)
            })
            .then(() => {
                dispatch(setProjectLoadingStatus(false))
                dispatch(setProjectUnchanged())
            })
            .catch(e => {
                console.log("创建新项目失败", e)
                dispatch(setProjectError(e))
            })
    }

    return createProject
}

// 创建云端项目
export const useCreateCloudProject = () => {
    const { projectId } = useSelector(selectProject)
    const dispatch = useDispatch()
    const intl = useIntl();
    const createCloudProject = (projectTitle: string, data?: string) => {
        console.log("创建云端项目")
        // 创建项目(只是创建projectInfo)
        return createProject_API(projectTitle)
            .then((ret) => {
                dispatch(setProjectId(ret.projectId))
                // 创建成功之后, 保存一次项目
                return saveProjectData_API(projectId, data || JSON.stringify(projectData(intl.formatMessage)))
            })
    }
    return createCloudProject
}

// 保存项目
export const useSaveProject = () => {
    const { projectId, projectPath } = useSelector(selectProject)
    const { token } = useSelector(selectUser)
    const saveProjectToCloud = useSaveProjectToCloud()
    const saveProjectToLocal = useSaveProjectToLocal()


    const saveProject = () => {
        console.log("保存项目")
        // 打开的本地项目
        if (projectPath) {
            return saveProjectToLocal();
        }
        // 云端项目, 已登录
        if (projectId > 0 && token) {
            return saveProjectToCloud();
        }
        // 新建项目
        if (IS_WEB_PLATFORM) {
            // 不响应
        } else {
            // 离线版, 打开文件另存为
        }
    }

    return saveProject
}

// 保存至云端
export const useSaveProjectToCloud = () => {
    const { saveStatus, projectId, projectTitle } = useSelector(selectProject)
    const dispatch = useDispatch();

    const saveProjectToCloud = useCallback(() => {
        if (saveStatus === "saving") return;
        console.log("保存云端项目")
        dispatch(setProjectSavingStatus("saving"))

        // 1.保存资源文件
        return Promise.all(vm.assets
            .filter(asset => !asset.clean)
            .map(
                asset => storage.store(
                    asset.assetType,
                    asset.dataFormat,
                    asset.data,
                    asset.assetId
                ).then(response => {
                    // Asset servers respond with {status: ok} for successful POSTs
                    if (response.status !== 'ok') {
                        // Errors include a `code` property, e.g. "Forbidden"
                        return Promise.reject(response.code);
                    }
                    asset.clean = true;
                })
            )
        )
            // 保存projectData
            .then(() => saveProjectData_API(projectId, JSON.stringify(vm.toJSON())))
            // 保存标题
            .then(() => saveProjectInfo_API(projectId, { title: projectTitle }))
            // 设置项目封面
            .then(() => {
                try {
                    getProjectThumbnail(dataURI => {
                        updateProjectThumbnail_API(projectId, dataURItoBlob(dataURI));
                    });
                } catch (e) {
                    return Promise.reject("save thumbnail error")
                }
            })
    }, [saveStatus])

    return saveProjectToCloud
}

function getProjectThumbnail(callback) {
    vm.postIOData('video', { forceTransparentPreview: true });
    vm.renderer.requestSnapshot(dataURI => {
        vm.postIOData('video', { forceTransparentPreview: false });
        callback(dataURI);
    });
    vm.renderer.draw();
}

// 保存至本地
export const useSaveProjectToLocal = () => {
    const { saveStatus, projectId } = useSelector(selectProject)
    const dispatch = useDispatch();

    const saveProjectToCloud = useCallback(() => {
        if (saveStatus === "saving") return;
        console.log("保存本地项目")
        dispatch(setProjectSavingStatus("saving"))
        // 1.保存projectData
        return saveProjectData_API(projectId, JSON.stringify(vm.toJSON()))
            .then(() => {

            })

        // 2.保存资源文件

        // 3.保存projectInfo

        // 4.设置项目封面
    }, [saveStatus])

    return saveProjectToCloud
}
