// @flow
import * as React from 'react';
import {ReactNode, useState} from "react";
import styles from './DFHorizontal.module.scss'

export interface Tab {
    key: string;
    label: string;
    content: ReactNode;
}

interface DfHorizontalTabsProps {
    items: Tab[];
    onChangeTab: (tab: Tab) => void;
    defaultActiveKey: string;
    activeTabKey: string;
    destroyInactiveTabPane?: boolean
}

const DfHorizontalTabs = ({
                              items,
                              onChangeTab,
                              defaultActiveKey,
                              activeTabKey,
                              destroyInactiveTabPane = true
                          }: DfHorizontalTabsProps) => {
    const [activeTab, setActiveTab] = useState(defaultActiveKey);
    const activeContent = items.find(i => i.key === activeTabKey)?.content
    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                {items.map((tab, index) => (
                    <div
                        key={tab.key}
                        className={tab.key === activeTabKey ? styles.active : ''}
                        onClick={() => onChangeTab(tab)}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
            {!destroyInactiveTabPane ? items.map(tab => (
                <div className={`${styles.content} ${tab.key === activeTabKey ? styles.show : styles.hide}`}
                     key={tab.key}>{tab.content}</div>
            )) : <div className={styles.content} key={items[activeTabKey]}>{activeContent}</div>}
        </div>
    );
};

export default DfHorizontalTabs
