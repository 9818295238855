// message.ts
import React from 'react';
import {createRoot} from 'react-dom/client';
import Message, {MessageType, MessageProps} from './DFMessage';
import styles from './Message.module.scss'



interface QueuedMessage extends MessageProps {
    id: string;
}

function uuid() {
    const uuid = window.crypto.getRandomValues(new Uint8Array(8));
    return uuid.toString().split(",").join("");
}


let container;
let messageQueue: QueuedMessage[] = [];
const CONTAINER_ID = "message__container";
let clearMessageTimeout: any = null;
const createContainer = () => {
    let container = document.getElementById(CONTAINER_ID);
    if (!container) {
        container = document.createElement("div");
        container.setAttribute("id", CONTAINER_ID);
        document.body.appendChild(container);
    }
    return container;

};
const destroyContainer = () => {
    if (container) {
        document.body.removeChild(container);
    }
};
let containerRoot: any;
const renderMessages = () => {
    const container = createContainer();
    if (!containerRoot) {
        containerRoot = createRoot(container);
    }
    const onMessageClose = (id: string) => {
        messageQueue = messageQueue.filter((message) => message.id !== id);
        renderMessages();
    };

    containerRoot.render(
        <div className={styles.container}>
            {messageQueue.map((message) => (
                <Message key={message.id} {...message} onClose={() => onMessageClose(message.id)}/>
            ))}
        </div>
    );

};
const onMessageClose = (id: string) => {
    messageQueue = messageQueue.filter((message) => message.id !== id);
    renderMessages();
    if (messageQueue.length === 0) {
        destroyContainer();
    }
};

const showMessage = (type: MessageType, content: string, duration: number = 1000, customMessageId: string | null = null) => {
    const messageId = customMessageId || uuid(); // Generate a unique ID for the message
    const message: QueuedMessage = { id: messageId, type, content, duration };
    if (messageQueue.some((queuedMessage) => queuedMessage.id === messageId)) { 
      if (clearMessageTimeout) {
        clearTimeout(clearMessageTimeout);
        // 延迟关闭
        clearMessageTimeout = setTimeout(() => {
          onMessageClose(messageId);
        }, duration);
      }
      return;
    }
    messageQueue.push(message);
    renderMessages();
    if (duration) {
      clearMessageTimeout = setTimeout(() => {
        onMessageClose(messageId);
      }, duration);
    }
};

const message = {
  success: (content: string, duration?: number, messageId?: string) => {
    showMessage("success", content, duration, messageId);
  },
  info: (content: string, duration?: number, messageId?: string) => {
    showMessage("info", content, duration,messageId);
  },
  warning: (content: string, duration?: number, messageId?: string) => {
    showMessage("warning", content, duration,messageId);
  },
  error: (content: string, duration?: number,messageId?:string) => {
    showMessage("error", content, duration,messageId);
  },
  loading: (content: string, duration?: number,messageId?:string) => {
    showMessage("loading", content, duration,messageId);
  },
};

export default message;
