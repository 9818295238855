import React, { useState, useEffect, ReactNode, FC, Children, useContext } from 'react';
import styles from './DFRadio.module.scss';

interface RadioGroupContextProps {
    disabled?: boolean
    name?: string
    value: any,
    onChange: (val: string | number | { label: ReactNode; value: string }) => void
}
interface DFRadioProps {
    wrapperClassName?: string
    value: any
    name?: string
    disabled?: boolean
    checked?: boolean
    onChange?: (e: any) => void
    children: ReactNode
}

interface DFRadioGroupProps {
    defaultValue?: any
    disabled?: boolean
    name?: string
    options?: string[] | number[] | Array<{ label: ReactNode; value: string }>
    value: any,
    vertical?: boolean
    onChange: (val: string | number | { label: ReactNode; value: string }) => void,
    children?: ReactNode
}

const RadioGroupContext = React.createContext<RadioGroupContextProps | null>(null);

export const DFRadio: FC<DFRadioProps> = (props) => {
    const groupContext = useContext(RadioGroupContext);
    const handleChange = (e) => {
        props.onChange?.(e.target.value);
        groupContext?.onChange?.(e.target.value);
    };

    const radioProps = { ...props }
    if (groupContext) {
        radioProps.name = groupContext.name
        radioProps.checked = props.value === groupContext.value;
        radioProps.disabled = radioProps.disabled ?? groupContext.disabled;
    }

    return (
        <label className={`${styles.container}  ${radioProps.disabled ? styles.disabled : ''} ${props.wrapperClassName}`}>
            <input
                type="radio"
                checked={radioProps.checked}
                onChange={handleChange}
                disabled={radioProps.disabled}
                value={props.value}
            />
            <span>
                {radioProps.children}
            </span>
        </label>
    );
};

export const DFRadioGroup: FC<DFRadioGroupProps> = ({
    defaultValue,
    disabled,
    name,
    options,
    value: propValue,
    onChange,
    vertical,
    children
}) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue || '');

    useEffect(() => {
        if (propValue !== undefined) {
            setSelectedValue(propValue);
        }
    }, [propValue]);
    const handleRadioChange = (newValue) => {
        if (disabled) {
            return;
        }
        setSelectedValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    let childrenToRender = children;
    if (options) {
        childrenToRender = options.map((option) => {
            if (typeof option === 'string') {
                return <DFRadio value={option} name={name} disabled={disabled} onChange={handleRadioChange}>{option}</DFRadio>;
            }
            return <DFRadio value={option.value} name={name} disabled={disabled} onChange={handleRadioChange}>{option.label}</DFRadio>;
        });
    }

    return (
        <div className={`${styles['radioGroup']} ${disabled ? styles.disabled : ''} ${vertical ? styles.vertical : ''}`}>
            <RadioGroupContext.Provider value={{ value: selectedValue, onChange: handleRadioChange, disabled, name }}>
                {childrenToRender}
            </RadioGroupContext.Provider>
        </div>
    );
};
