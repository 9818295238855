import React from 'react';
import styles from './ExtensionButton.module.scss';
import { useDispatch } from "react-redux";
import { openExtensionLib } from "redux/asset-lib/assetLibStatusSlice";
import addExtensionSvg from './addExtension.svg'

const ExtensionButton = ({ visibility }: { visibility: boolean }) => {
    const dispatch = useDispatch();

    // 打开扩展库
    const openExtensionLib_ = () => {
        dispatch(openExtensionLib())
    }

    return (
        <div className={styles.container} onClick={openExtensionLib_} style={{ visibility: visibility ? 'visible' : 'hidden' }}>
            <div className={styles.btnContainer}>
                <button>
                    <img src={addExtensionSvg} alt="" />
                    扩展
                </button>
            </div>
        </div>

    );
};

export default ExtensionButton;
