import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

interface ConnectionModalExtensionIdState {
    extensionId: null|string
}
const initialState:ConnectionModalExtensionIdState = {
    extensionId: null
};

export const connectionModalExtensionIdSlice = createSlice({
    name: 'connectionModalExtensionId',
    initialState,
    reducers: {
        setConnectionModalExtensionId: (state, action: PayloadAction<string>) => {
           state.extensionId = action.payload
        }
    },
})

export const {setConnectionModalExtensionId} = connectionModalExtensionIdSlice.actions;
export const selectConnectionModalExtensionId = (state: RootState) => state.connectionModalExtensionId.extensionId

export default connectionModalExtensionIdSlice.reducer
