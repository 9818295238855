import AudioRecorder from "lib/audio/audio-recorder";
import ReSamples from "lib/audio/reSample";
import { vm } from "lib/scratch-vm";
import { useEffect, useRef, useState } from "react";
import WavEncoder from "wav-encoder";

export const useSpeechRecognition = () => {
  const [levels, setLevels] = useState<any>([]);
  const audioRecorderRef = useRef<any>(null);


  const handleStarted = () => {
  }

  const handleRecordingError = (error: any) => {
    console.error("Error while recording:", error);
  }

  const handleDisposeRecord = () => {
    audioRecorderRef.current.dispose();
    audioRecorderRef.current = null;
  }

  useEffect(() => {
    const startRecordListen = () => {
      audioRecorderRef.current = new AudioRecorder();
      audioRecorderRef.current.startListening(handleStarted, handleLevelUpdate, handleRecordingError);
    }

    const startRecord = (isScratch: boolean) => {
      setLevels([]);
      if (isScratch) return
      if (!audioRecorderRef.current) startRecordListen()
      audioRecorderRef.current.startRecording()
    }

    const stopRecord = (isScratch: boolean) => {
      if (isScratch) {
        return
      }
      const { samples, sampleRate, levels } = audioRecorderRef.current.stop();
      setLevels(levels)
      if (levels.length === 0) { }
      const resample = new ReSamples(samples, sampleRate);
      resample.process(({ renderedBuffer }) => {
        const sam = renderedBuffer.getChannelData(0);
        const samRate = renderedBuffer.sampleRate;
        vm.runtime.emit('listenRecordEnd', WavEncoder.encode({
          sampleRate: samRate,
          channelData: [sam]
        }), handleDisposeRecord);
      });
    }

    const handleLevelUpdate = (levels: any) => {
      setLevels((prevLevels) => [...prevLevels, levels]);
    }

    vm.runtime.startRecordSpeechRecognition = startRecord;
    vm.runtime.stopRecordSpeechRecognition = stopRecord;
    vm.runtime.speechRecognitionLevelChange = handleLevelUpdate;
    return () => {
      vm.runtime.startRecordSpeechRecognition = null;
      vm.runtime.stopRecordSpeechRecognition = null;
      vm.runtime.speechRecognitionLevelChange = null;
    }
  }, [])

  return {
    levels
  }
}
