// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loupe_eye-dropper_39EHg {\n    position: absolute;\n    border-radius: 100%;\n    border: 1px solid #222;\n}\n", ""]);
// Exports
exports.locals = {
	"eye-dropper": "loupe_eye-dropper_39EHg",
	"eyeDropper": "loupe_eye-dropper_39EHg"
};
module.exports = exports;
