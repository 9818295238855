import ScratchSoundTab from './sound-tab'
import {useDispatch, useSelector} from "react-redux";
import {openCostumeTab} from "../../../redux/tab/tabSlice";
import {setRestore} from "../../../redux/restore-deletion/restoreDeletionSlice";
import {closeAlertWithId, showStandardAlert} from "../../../redux/alerts/alertsSlice";
import {closeSoundLib, openSoundLib, selectAssetLibStatus} from "../../../redux/asset-lib/assetLibStatusSlice";
import {
    closeSoundRecorderModal,
    openSoundRecorderModal,
    selectSoundRecorderModal
} from "../../../redux/modals/modalsSlice";
import {selectTargets} from "../../../redux/tagets/targetsSlice";
import {vm} from "../../../lib/scratch-vm";
import {selectIntl} from "../../../redux/intl/intlSlice";
import {useSelectedSoundIndex} from "./util/useSelectedSoundIndex";

export const SoundTab = (props) => {
    const {editingTarget} = useSelector(selectTargets)
    const soundRecorderVisible = useSelector(selectSoundRecorderModal)
    const {soundLibVisible} = useSelector(selectAssetLibStatus)
    const {isRtl} = useSelector(selectIntl)
    const dispatch = useDispatch()
    const sprites = (vm.runtime.targets || [])
        .filter(target => !target.isStage)
        .filter(
            // Don't report clones.
            target => !target.hasOwnProperty('isOriginal') || target.isOriginal
        ).map(
            target => target.toJSON()
        )
        .reduce(
            (targets, target, listId) => Object.assign(
                targets,
                {[target.id]: {order: listId, ...target}}
            ),
            {}
        )
    const stage = vm.runtime.getTargetForStage();

    const onActivateCostumesTab = () => dispatch(openCostumeTab())
    const onNewSoundFromLibraryClick = e => {
        e.preventDefault();
        dispatch(openSoundLib());
    }
    const onNewSoundFromRecordingClick = () => {
        dispatch(openSoundRecorderModal());
    }
    const onRequestCloseSoundLibrary = () => {
        dispatch(closeSoundLib());
    }
    const dispatchUpdateRestore = (restoreState: any) => {
        dispatch(setRestore(restoreState));
    }
    const onCloseImporting = () => dispatch(closeAlertWithId('importingAsset'))
    const onShowImporting = () => dispatch(showStandardAlert('importingAsset'))

    return <ScratchSoundTab
        onActivateCostumesTab={onActivateCostumesTab}
        onNewSoundFromLibraryClick={onNewSoundFromLibraryClick}
        onNewSoundFromRecordingClick={onNewSoundFromRecordingClick}
        onRequestCloseSoundLibrary={onRequestCloseSoundLibrary}
        dispatchUpdateRestore={dispatchUpdateRestore}
        onCloseImporting={onCloseImporting}
        onShowImporting={onShowImporting}
        editingTarget={editingTarget}
        vm={vm}
        isRtl={isRtl}
        stage={stage}
        sprites={sprites}
        soundLibraryVisible={soundLibVisible}
        soundRecorderVisible={soundRecorderVisible}

    />
}
