import classNames from "classnames";

import styles from "./ml5-trainmodal.module.scss";
import collapseIcon from "./collapse.png";
import headIcon from "./head.png";
import closeIcon from "./close.png";
import trashIcon from "./trash.png";
import editIcon from "./edit.png";
import PictureAI from "../../../component/df-camera-modal/DFCameraModal";
import { Input } from "antd";
import { injectIntl } from "react-intl";

const Ml5TraninModalComponent = (props) => {
  const { open, labelList, chooseItem } = props;
  const { vm, showPopup, collapse, imgResult, visualType, titleText } = props;

  const {
    dragWindow,
    close,
    addLabel,
    deleteImage,
    setCollapse,
    onscroll,
    addTrain,
    exportModel,
    deleteLabel,
    restartTrain,
    setChooseItem,
    predictImage,
    setImgHide,
    updateLabel,
    renameLabel,
    focusInput,
  } = props;

  const local = {
    lable: props.intl.formatMessage({
      default: "AI Visual Interface",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.lable",
    }),
    title: props.intl.formatMessage({
      default: "No Data",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.title",
    }),
    imgCount: props.intl.formatMessage({
      default: "[COUNT] pictures",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.imgCount",
    }),
    delete: props.intl.formatMessage({
      default: "delete",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.delete",
    }),
    classificationCount: props.intl.formatMessage({
      default: "[COUNT] categories in total",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.classificationCount",
    }),
    recognitionResult: props.intl.formatMessage({
      default: "The screen recognition result is:",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.recognitionResult",
    }),
    possibility: props.intl.formatMessage({
      default: "The possibilities are:",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.possibility",
    }),
    addClass: props.intl.formatMessage({
      default: "Add classification",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.addClass",
    }),
    startTraining: props.intl.formatMessage({
      default: "Start training",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.startTraining",
    }),
    prediction: props.intl.formatMessage({
      default: "Predict video pictures",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.prediction",
    }),
    retrain: props.intl.formatMessage({
      default: "Retrain",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.retrain",
    }),
    export: props.intl.formatMessage({
      default: "Export model",
      description: "Name for the 'HuskyLens AI Camera' extension",
      id: "gui.extension.AIVisualInterface.export",
    }),
  };

  return (
    <div>
      {open ? (
        <div
          id="ml5TrainPop"
          className={classNames("ml5Train_pop", styles.trainPop)}
          onMouseDown={(e) => dragWindow(e)}
        >
          <div className={classNames(styles.ml5Title, styles.pictureAI_title)}>
            <img className={styles.iconOrigin} src={headIcon} />
            {titleText || local.lable}
          </div>
          <div className={styles.rightIcon}>
            <div
              className={styles.icon}
              onClick={() => {
                close();
              }}
            >
              <img src={closeIcon} />
            </div>
          </div>

          <div className={styles.modalBody}>
            <div
              className={classNames(
                styles.modalContentLeft,
                styles.modalContent
              )}
            >
              <div className={styles.modalBodyContainer}>
                {labelList.map((item, index) => {
                  return (
                    <div
                      className={classNames(styles.imgclassContainer, {
                        [styles.imgclassContainerSelect3]: index == chooseItem,
                      })}
                      key={index}
                    >
                      <div
                        className={styles.imgsCon}
                        onClick={(e) => {
                          return;
                        }}
                      >
                        <span
                          className={classNames(styles.imgLabel, {
                            [styles.imgLabel]: index == chooseItem,
                            [styles.imgLabelSelect2]: index != chooseItem,
                          })}
                        >
                          {item.modify ? (
                            <Input
                              type="text"
                              autoFocus
                              className={styles.labelInput}
                              value={item.label}
                              onChange={(e) => {
                                renameLabel(index, e, "change");
                              }}
                              onPressEnter={(e) => {
                                renameLabel(index, e);
                              }}
                              onBlur={(e) => {
                                renameLabel(index, e);
                              }}
                            />
                          ) : (
                            item.label
                          )}
                          {!item.modify ? (
                            <div
                              className={classNames(
                                styles.iconLabel,
                                item.modify && styles.iconSelected
                              )}
                              onClick={(e) => {
                                updateLabel(index, e);
                              }}
                            >
                              <img className={styles.img} src={editIcon} />
                            </div>
                          ) : null}
                        </span>
                        <span className={styles.tipForNum}>
                          {local.imgCount.replace(
                            "[COUNT]",
                            item.imgInstance ? item.imgInstance.length || 0 : 0
                          )}
                        </span>
                      </div>
                      {!item.hide ? (
                        <div
                          className={styles.imgclassItem}
                          onWheel={(e) => onscroll(e, item)}
                        >
                          {item.imgInstance && item.imgInstance.length ? (
                            item.imgInstance
                              .filter((value, index) => {
                                return (
                                  index < 10 + (item.showNum ? item.showNum : 0)
                                );
                              })
                              .map((item_img, index_img) => {
                                return (
                                  <div
                                    key={index_img}
                                    style={{
                                      position: "relative",
                                      marginTop: "1rem",
                                    }}
                                  >
                                    <div
                                      className={styles.modelImgItem}
                                      style={{
                                        backgroundImage:
                                          'url("' + item_img.src + '")',
                                      }}
                                    ></div>
                                    <div
                                      className={styles.deleteImgIcon}
                                      title={local.delete}
                                      onClick={(e) => {
                                        deleteImage(index, index_img);
                                      }}
                                    >
                                      <img src={trashIcon} />
                                    </div>
                                  </div>
                                );
                              })
                          ) : (
                            <div>
                              <div
                                className={styles.modelImgItem}
                                style={{ backgroundImage: "none" }}
                              ></div>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
                {labelList.length ? null : (
                  <div className={styles.emptyTip}>{local.title}</div>
                )}
              </div>
            </div>
            {!collapse ? (
              <div
                className={classNames(
                  styles.modalContentRight,
                  styles.modalContent
                )}
              >
                <div className={styles.rightTop}>
                  <div
                    className={classNames(styles.imgLabel, styles.displayBlock)}
                  >
                    {local.classificationCount.replace(
                      "[COUNT]",
                      labelList.length || 0
                    )}
                  </div>
                  <div
                    className={classNames(styles.imgLabel, styles.displayBlock)}
                  >
                    {local.recognitionResult}
                    <span className={styles.lightText}>
                      {imgResult.label || ""}
                    </span>
                    <div
                      className={classNames(
                        styles.imgLabel2,
                        styles.displayBlock2
                      )}
                    >
                      {local.possibility}
                      <span className={styles.lightText}>
                        {imgResult.score || ""}
                      </span>
                    </div>
                  </div>
                  <div className={styles.videoContainer}>
                    {showPopup == "df" ? (
                      <PictureAI vm={vm} open={true} showCameraIcon={true} />
                    ) : null}
                  </div>
                </div>
                {true ? null : (
                  <div className={styles.btnClass}>
                    <button
                      className={styles.ml5Btn}
                      onClick={(e) => addLabel()}
                    >
                      {" "}
                      {local.addClass}{" "}
                    </button>
                    <button
                      className={styles.ml5Btn}
                      onClick={(e) => addTrain()}
                    >
                      {" "}
                      {local.startTraining}{" "}
                    </button>
                    <button
                      className={styles.ml5Btn}
                      onClick={(e) => predictImage()}
                    >
                      {" "}
                      {local.prediction}{" "}
                    </button>
                    <button
                      className={styles.ml5Btn}
                      onClick={(e) => restartTrain()}
                    >
                      {" "}
                      {local.retrain}{" "}
                    </button>
                    <button
                      className={styles.ml5Btn}
                      onClick={(e) => exportModel()}
                    >
                      {" "}
                      {local.export}{" "}
                    </button>
                  </div>
                )}
              </div>
            ) : null}
          </div>

          {false ? (
            <div
              className={classNames(styles.collapseBtn, {
                [styles.collapsed]: collapse,
              })}
              onClick={() => setCollapse()}
            >
              <img src={collapseIcon} />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default injectIntl(Ml5TraninModalComponent);
