import projectData from "lib/default-project/project-data"

export interface ProjectInfo {
    id: number // projectId
    author: {
        username: string // 用户名
        id: string // 用户id
        thumbnail_url: string // 用户头像
        team?: "Mind+"|string  // 官方团队
    }
    title: string // 项目标题
    // isPublished: boolean // 发布
    thumbnail_url: string // 封面图片
    history: {
        created: string // 创建时间
        modified?: string // 修改时间
        shared?: string // 分享时间
    }
    // stats: {
    //     commenters: number // 评论数量
    //     loves: number // 点赞数量
    //     views: number // 查看数量
    //     favorites: number // 收藏数量
    //     remixers: number // 改编数量
    // }
    visibility: 
        |"trshbyusr" // 已删除
        |"visible" // 正常项目
        |"trshbyadmin" // 有害项目, 被管理员删除
}

// 获取项目信息(可以不带token获取, 需要后端判断是否是已发布的项目)
export const getProjectInfo_API = (projectId: number): Promise<ProjectInfo> => {
    
    return Promise.resolve({
        id: 3000001,
        author: {
            username: "TestUser",
            id: "TestUser",
            thumbnail_url: "",
        },
        title: "测试项目1",
        thumbnail_url: "",
        history: {
            created: "2024-06-14T06:40:51",
        },
        visibility: "visible"
    })
}

// 获取项目内容(可以不带token获取, 需要后端判断是否是已发布的项目)
// 如果获取的projectData为空, 返回默认project
export const getProjectData_API = (projectId: number) => {

    return Promise.resolve(projectData(null as any))
}

// 新建项目
export const createProject_API = (projectTitle: string) => {


    return Promise.resolve({
        projectId: 300001,
    })
}

// 保存项目数据
export const saveProjectData_API = (projectId: number, projectData: string) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            Math.random() > 0.5 ? resolve(0) : reject()
        }, 2000);
    })
}

interface ProjectUpdateInfo {
    title?: string
}
// 保存项目信息
export const saveProjectInfo_API = (projectId: number, info: ProjectUpdateInfo) => {

    return Promise.resolve()
}

// 设置项目封面
export const updateProjectThumbnail_API = (projectId: number, data: Blob) => {

    return Promise.resolve()
}

