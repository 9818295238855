import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import Spinner from "component/spinner/spinner"
import styles from './save-status.module.scss';

// Wrapper for inline messages in the nav bar, which are all related to saving.
// Show any inline messages if present, else show the "Save Now" button if the
// project has changed.
// We decided to not use an inline message for "Save Now" because it is a reflection
// of the project state, rather than an event.
const SaveStatus = ({
    saveStatus,
    projectChanged,
    onClickSave
}) => {
    if (saveStatus === "saving") {
        return <div>
            <Spinner
                small
                className={styles.spinner}
                level={'info'}
            />
            <FormattedMessage
                defaultMessage="Saving project…"
                description="Message indicating that project is in process of saving"
                id="gui.alerts.saving"
            />
        </div>
    }
    if (saveStatus === "saveSuccess") {
        return <div>
            <Spinner
                small
                className={styles.spinner}
                level={'info'}
            />
            <FormattedMessage
                defaultMessage="Project saved."
                description="Message indicating that project was successfully saved"
                id="gui.alerts.saveSuccess"
            />
        </div>
    }
    if (saveStatus === "savingError") {
        return <div>
            <Spinner
                small
                className={styles.spinner}
                level={'info'}
            />
            <FormattedMessage
                defaultMessage="Project could not save."
                description="Message indicating that project could not be saved"
                id="gui.alerts.savingError"
            />
        </div>
    }
    if (projectChanged) {
        return <div
            className={styles.saveNow}
            onClick={onClickSave}
        >
            <FormattedMessage
                defaultMessage="Save Now"
                description="Title bar link for saving now"
                id="gui.menuBar.saveNowLink"
            />
        </div>
    }
    return null;
}
    

export default SaveStatus;
