import {vm} from "lib/scratch-vm";
import Renderer from "lib/scratch-render";
import VideoProvider from 'lib/video/video-provider';
import {BitmapAdapter as V2BitmapAdapter} from 'scratch-svg-renderer';
import {useEffect, useState} from "react";

/**
* @Description: vm 挂载 scratch-render
* @author LiuSheng
* @date 2023/5/5
*/
export const useStage = () => {
    const [isInit, setIsInit] = useState(false);
    useEffect(() => {
        if (!vm.renderer) {
            const canvas = document.createElement('canvas');
            const renderer = new Renderer(canvas);
            vm.attachRenderer(renderer);

            // Only attach a video custom-provider once because it is stateful
            vm.setVideoProvider(new VideoProvider());

            // Calling draw a single time before any project is loaded just makes
            // the canvas white instead of solid black–needed because it is not
            // possible to use CSS to style the canvas to have a different
            // default color
            vm.renderer.draw();
        }
        vm.attachV2BitmapAdapter(new V2BitmapAdapter());
        setIsInit(true);
    }, []);
    return {
        isStageInit: isInit
    }
}
