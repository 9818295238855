import { createRoot } from "react-dom/client";
import DFAlertDialogComponent from "./DFAlertDialogComponent";

const contentArr = [];
const getContentArr = () => contentArr;
const alertDialog = (title, content, obj) => {
  const isContentArray = Array.isArray(content);
  if (!isContentArray) {
    const arrContent = getContentArr();
    if (obj.mode === 1 && arrContent.indexOf(content.trim()) !== -1) return;
    if (obj.mode === 1 && arrContent.indexOf(content.trim()) === -1) {
      arrContent.push(content);
    }
  }
  const dialog = document.createElement("div");
  document.body.appendChild(dialog);
  const root = createRoot(dialog);

  const handleDelete = () => {
    root.unmount();
    document.body.removeChild(dialog);
    if (obj.mode === 1) deleteItemByContent(content);
  };

  root.render(
    <DFAlertDialogComponent
      title={title}
      content={content}
      obj={obj}
      onDelete={handleDelete}
    />
  );
};

const deleteItemByContent = (content) => {
  const index = contentArr.indexOf(content);
  if (index !== -1) contentArr.splice(index, 1);
};

export default alertDialog;
