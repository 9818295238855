// @flow
import DFDropdown from "component/df-dropdown/DFDropdown";
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { vm } from "../../../lib/scratch-vm";
import { selectRestoreDeletion, setRestore } from "../../../redux/restore-deletion/restoreDeletionSlice";
import { selectVmStatus } from "../../../redux/vm-status/vmStatusSlice";
import styles from "../MenuBar.module.scss";

/**
 * menu-bar中的编辑项
 * @param props
 * @constructor
 */
export const Editing = () => {
  const dispatch = useDispatch()
  const { turbo } = useSelector(selectVmStatus)
  const intl = useIntl()
  const { restoreFun, deletedItem } = useSelector(selectRestoreDeletion)




  //恢复删除
  const handleRestoreDeletion = () => {
    if (typeof restoreFun === 'function') {
      restoreFun();
      dispatch(setRestore({ restoreFun: null, deletedItem: '' }));
    }
  }
  const handleToggleTurboMode = () => {
    vm.setTurboMode(!turbo)
  }

  const onDropdownClick = ({ key }) => {
    switch (key) {
      case '1':
        handleRestoreDeletion()
        break
      case '2':
        handleToggleTurboMode()
        break
      default:
        break
    }
  }
  const items = useMemo(() => {
    //翻译菜单项中的item
    const restoreOptionMessage = (deletedItem) => {
      switch (deletedItem) {
        case 'Sprite':
          return intl.formatMessage({
            id: 'gui.menuBar.restoreSprite',
            description: 'Menu bar item for restoring the last deleted sprite.',
            defaultMessage: 'Restore Sprite'
          })
        case 'Sound':
          return intl.formatMessage({
            id: 'gui.menuBar.restoreSound',
            description: 'Menu bar item for restoring the last deleted sound.',
            defaultMessage: 'Restore Sound'
          })
        case 'Costume':
          return intl.formatMessage({
            id: 'gui.menuBar.restoreCostume',
            description: 'Menu bar item for restoring the last deleted costume.',
            defaultMessage: 'Restore Costume'
          })
        case 'Backdrop':
          return intl.formatMessage({
            id: 'gui.menuBar.restoreBackdrop',
            description: 'Menu bar item for restoring the last deleted Backdrop.',
            defaultMessage: 'Restore Backdrop'
          })
        default: {
          return intl.formatMessage({
            id: 'gui.editorMind.restore',
            description: 'Menu bar item for restoring the last deleted item in its disabled state.',
            defaultMessage: 'Restore'
          })
        }
      }
    }
    const restorable = typeof restoreFun === 'function';
    return [
      {
        key: '1',
        label: restoreOptionMessage(deletedItem),
        disabled: !restorable
      },
      {
        key: '2',
        label: turbo ? intl.formatMessage({
          defaultMessage: 'Turn off Turbo Mode',
          description: 'Menu bar item for turning off turbo mode',
          id: 'gui.menuBar.turboModeOff'
        }) : intl.formatMessage({
          defaultMessage: 'Turn on Turbo Mode',
          description: 'Menu bar item for turning on turbo mode',
          id: 'gui.menuBar.turboModeOn'
        }),
      },
    ];
  }, [deletedItem, intl, restoreFun, turbo])


  return (
    <DFDropdown trigger={'click'} items={items} onItemClick={onDropdownClick} width={'100'}>
      <div className={styles.dropdownContent}>
          <FormattedMessage
            defaultMessage="Edit"
            description="Text for edit dropdown menu"
            id="gui.menuBar.edit"
          />
      </div>
    </DFDropdown>
  );
};
