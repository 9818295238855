function getFuncInfo (generator, block) {
    let info = Object.create(null);
    let customBlock = block.getInputTargetBlock("custom_block");
    let funcName;
    if(customBlock.procCode_.indexOf("%") !== -1){
        funcName = customBlock.procCode_.slice(0, customBlock.procCode_.indexOf("%")).trim();
    }
    else{
        funcName = customBlock.procCode_;
    }
    info['name'] = `DF_${generator.transTextForVar(funcName)}`;
    let typeArrys = getFuncArgsTypeArray(customBlock.procCode_);
    let argArrys = customBlock.displayNames_.map((item, index) => {
        let prefix = getVariablePrefix(typeArrys[index]);
        item = prefix + generator.transTextForVar(item);
        return item;
    });
    info['id'] = info.name;
    for (let index in typeArrys) {
        info['id'] += typeArrys[index];
    }
    info['argTypeArrys'] = typeArrys;
    info['argArrys'] = argArrys;
    info['type'] = "void";
    return info;
}

//func variable type arr
function getFuncArgsTypeArray(procCode_) {
    let temp = [];
    while (procCode_.indexOf("%") !== -1) {
        let index = procCode_.indexOf("%");
        if (procCode_[index + 1] === "s") {
            temp.push("String");
        }
        if (procCode_[index + 1] === "n") {
            temp.push("float");
        }
        if (procCode_[index + 1] === "b") {
            temp.push("bool");
        }
        procCode_ = procCode_.slice(index + 1);
    }
    return temp;
}
function getVariablePrefix (type) {
    let prefix = '';
    if (type === "argument_reporter_string_number" ||
       type === 'String') {
        prefix = 'mind_s_';
    } else if (type === "argument_reporter_number" ||
        type === 'float') {
        prefix = 'mind_n_';
    } else if (type === "argument_reporter_boolean" ||
        type === 'bool') {
        prefix = 'mind_b_';
    }
    return prefix;
}

const procedureCode = {
    procedures_definition(generator, block, params){
		let funcInfo = getFuncInfo(generator, block);
        let func = generator.getUserCustomFunction(funcInfo.id);
        if (func) {
            func.funcInfo.argArrys = funcInfo.argArrys;
            generator.addUserCustomFunction(func.funcInfo, generator.currentHeader, true);
        } else {
            generator.addUserCustomFunction(funcInfo, generator.currentHeader);
        }
        return '';
    },
    procedures_call(generator, block, params){
		let funcName;
        if (block.procCode_.indexOf("%") !== -1) {
            funcName = block.procCode_.slice(0, block.procCode_.indexOf("%")).trim();
        }
        else {
            funcName = block.procCode_;
        }
        funcName = `DF_${generator.transTextForVar(funcName)}`;
        let pt = [];
        for (let i = 0; i < block.procCode_.length; i++) {
            if (block.procCode_[i] === '%' && block.procCode_[i + 1] === "s") {
                pt.push("%s");
            } else if (block.procCode_[i] === '%' && block.procCode_[i + 1] === "b") {
                pt.push("%b");
            } else if (block.procCode_[i] === '%' && block.procCode_[i+1] === "n") {
                pt.push("%n");
            }
        }
        let id = funcName;
        let typeArrys = [];
        let part = [];
        let i = 0;
        for (let index in params) {
            if (params[index] && params[index].code !== "") {
                part.push(params[index].code);
                if (pt[i] === "%b") {
                    id += 'bool';
                    typeArrys.push('bool');
                } else if (pt[i] === "%n") {
                    id += 'float';
                    typeArrys.push('float');
                } else {
                    id += 'String';
                    typeArrys.push('String');
                }
                i++;
            }
        }
        let func = generator.getUserCustomFunction(id);
        if (func) {
            func.funcInfo.argTypeArrys = typeArrys;
            generator.addUserCustomFunction(func.funcInfo, func.header, true);
        } else {
            func = Object.create(null);
            func['name'] = funcName;
            func['id'] = id;
            func['argTypeArrys'] = typeArrys;
            func['argArrys'] = [];
            func['type'] = "void";
            generator.addUserCustomFunction(func, null, true);
        }
        return `${funcName}(${part.join(", ")});`;
    },
}

class Scratch3ProcedureBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            procedures_definition: this.definition,
            procedures_call: this.call,
            argument_reporter_string_number: this.argumentReporterStringNumber,
            argument_reporter_boolean: this.argumentReporterBoolean
        };
    }

    getCodePrimitives () {
        return procedureCode;
    }

    definition () {
        // No-op: execute the blocks.
    }

    call (args, util) {
        if (!util.stackFrame.executed) {
            const procedureCode = args.mutation.proccode;
            const paramNamesIdsAndDefaults = util.getProcedureParamNamesIdsAndDefaults(procedureCode);

            // If null, procedure could not be found, which can happen if custom
            // block is dragged between sprites without the definition.
            // Match Scratch 2.0 behavior and noop.
            if (paramNamesIdsAndDefaults === null) {
                return;
            }

            const [paramNames, paramIds, paramDefaults] = paramNamesIdsAndDefaults;

            // Initialize params for the current stackFrame to {}, even if the procedure does
            // not take any arguments. This is so that `getParam` down the line does not look
            // at earlier stack frames for the values of a given parameter (#1729)
            util.initParams();
            for (let i = 0; i < paramIds.length; i++) {
                if (args.hasOwnProperty(paramIds[i])) {
                    util.pushParam(paramNames[i], args[paramIds[i]]);
                } else {
                    util.pushParam(paramNames[i], paramDefaults[i]);
                }
            }

            util.stackFrame.executed = true;
            util.startProcedure(procedureCode);
        }
    }

    argumentReporterStringNumber (args, util) {
        const value = util.getParam(args.VALUE);
        if (value === null) {
            // When the parameter is not found in the most recent procedure
            // call, the default is always 0.
            return 0;
        }
        return value;
    }

    argumentReporterBoolean (args, util) {
        const value = util.getParam(args.VALUE);
        if (value === null) {
            // When the parameter is not found in the most recent procedure
            // call, the default is always 0.
            return 0;
        }
        return value;
    }
}

module.exports = Scratch3ProcedureBlocks;
