import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import VM from "scratch-vm";
import costumeLibraryContent from "lib/asset/costume/costumes.json";
import spriteTags from "lib/asset/sprite/sprite-tags";
import LibraryComponent from "component/library/library.jsx";
import { vm } from "../../lib/scratch-vm";

const messages = defineMessages({
  libraryTitle: {
    defaultMessage: "Choose a Costume",
    description: "Heading for the costume library",
    id: "gui.costumeLibrary.chooseACostume",
  },
});

class CostumeLibrary extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this, ["handleItemSelected"]);
  }
  handleItemSelected(item) {
    const vmCostume = {
      name: this.props.intl.formatMessage({ id: item.transid }) || item.name,
      transid: item.transid,
      rotationCenterX: item.rotationCenterX,
      rotationCenterY: item.rotationCenterY,
      bitmapResolution: item.bitmapResolution,
      skinId: null,
    };
    this.props.vm.addCostumeFromLibrary(item.md5ext, vmCostume);
  }
  render() {
    return (
      <LibraryComponent
        data={costumeLibraryContent}
        id="costumeLibrary"
        tags={spriteTags}
        title={this.props.intl.formatMessage(messages.libraryTitle)}
        onItemSelected={this.handleItemSelected}
        onRequestClose={this.props.onRequestClose}
      />
    );
  }
}

CostumeLibrary.propTypes = {
  onRequestClose: PropTypes.func,
  vm: PropTypes.instanceOf(VM).isRequired,
};

export default injectIntl(CostumeLibrary);
