import React, { useEffect, useState } from 'react';
import styles from './DFTag.module.scss';

export interface TagProps {
    label: string;
    selected?: boolean;
    onClick?: () => void;
    onClose?: () => void;
    closeIcon?: React.ReactNode; // 自定义关闭图标
    closeAble?: boolean;
    type: "primary" | "text";
    wrapperClassName?: string;
    round?: boolean;
    square?: boolean;
}

export const DFTag: React.FC<TagProps> = ({
    label,
    selected = false,
    onClick,
    onClose,
    closeIcon = <span>&times;</span>, // 默认关闭图标
    closeAble = false,
    type = "text",
    wrapperClassName,
    round = true,
    square = false
}) => {
    if (square && round) {
        console.error("square 和 round 不能同时为 true");
    }
    return (
        <div
            className={`${styles.tag} ${selected ? styles.selected : ''} ${wrapperClassName} ${styles[type]} ${square ? styles.square : ''} ${round ? styles.round : ''}`}
            onClick={onClick}
        >
            <span className={`${styles.label} ${closeAble ? styles.closeAble : ''} `}>{label}</span>
            {closeAble && (
                <span className={styles.closeIcon} onClick={(e) => {
                    e.stopPropagation(); // 阻止点击事件冒泡
                    onClose && onClose();
                }}>
                    {closeIcon}
                </span>
            )}
        </div>
    );
};

export interface TagOption {
    label: string;
    value: string;
}

interface TagGroupProps {
    type?: "text" | "primary";
    options: TagOption[];
    value?: string[];
    onChange?: (selectedValues: string[]) => boolean | void;
    wrapperClassName?: string;
    multiple?: boolean; // 新增属性，默认为true（支持多选）
    closeAble?: boolean; // 是否允许关闭
}

export const DFTagGroup: React.FC<TagGroupProps> = ({
    options,
    value: controlledValue,
    onChange,
    wrapperClassName,
    type = "text",
    multiple = true, // 默认为多选
    closeAble = false
}) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(controlledValue || []);
    const handleTagClick = (value: string, option: TagOption) => {
        if (multiple) {
            // 多选模式：选择或取消选择
            const newSelectedValues = selectedValues.includes(value)
                ? selectedValues.filter((v) => v !== value)
                : [...selectedValues, value];


            if (onChange) {
                let result = onChange(newSelectedValues);
                console.log(result);
                if (result !== false) {
                    setSelectedValues(newSelectedValues);
                }
            }
        } else {
            // 单选模式：只能选择一个
            const newSelectedValues = selectedValues[0] === value ? [] : [value];
            if (onChange) {
                let result = onChange(newSelectedValues);
                if (result !== false) {
                    setSelectedValues(newSelectedValues);
                }
            }
        }
    };

    const handleTagClose = (value: string, option: TagOption) => {
        const newSelectedValues = selectedValues.filter((v) => v !== value);
        setSelectedValues(newSelectedValues);
        if (onChange) {
            onChange(newSelectedValues);
        }
    };

    useEffect(() => {
        if (controlledValue) {
            setSelectedValues(controlledValue)
        }
    }, [controlledValue])
    return (
        <div className={wrapperClassName}>
            {options.map((option) => (
                <DFTag
                    square={multiple}
                    round={!multiple}
                    type={type}
                    key={option.value}
                    label={option.label}
                    selected={selectedValues.includes(option.value)}
                    onClick={() => handleTagClick(option.value, option)}
                    onClose={() => handleTagClose(option.value, option)}
                    closeAble={closeAble}
                />
            ))}
        </div>
    );
};
