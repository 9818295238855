import * as React from 'react';
// import {AssetLibraries} from "component/asset-libraries/AssetLibraries";
import { ExtensionLibraries } from "component/extension-libraries/ExtensionLibraries";
import { useDispatch, useSelector } from "react-redux";
import {
  closeBackdropLib,
  closeCostumeLib,
  closeSoundLib,
  closeSpriteLib,
  closeTipsLibrary,
  selectAssetLibStatus
} from "redux/asset-lib/assetLibStatusSlice";
import CostumeLibrary from "component/costume-library/costume-library";
import { vm } from "lib/scratch-vm";
import SpriteLibrary from "component/sprite-library/sprite-library";
import { showBlockTab } from "redux/tab/tabSlice";
import BackdropLibrary from "component/backdrop-library/backdrop-library";
import { openLinkDisconnect, openLinkError, selectLinkModal } from 'redux/link-modal/linkModalSlice';
import { LinkModal } from "./link-modal/LinkModal"
import { IS_WEB_PLATFORM } from 'config/config';
import { useEffect } from 'react';
import { commandWs } from 'service/link-adapter/websocket/commandWs';
import TipsLibrary from 'component/tips-library/tips-library';
import { activeDeck, selectCard } from 'redux/card/cardSlice';
import { Cards } from 'component/card';

type Props = {};
export const AssetLib = (props: Props) => {
  const dispatch = useDispatch()
  const {
    extensionLibVisible,
    costumeLibVisible,
    spriteLibVisible,
    backdropLibVisible,
    tipsLibraryVisible
  } = useSelector(selectAssetLibStatus);

  const {visible} = useSelector(selectCard)
  const handleCloseCostumeLib = () => dispatch(closeCostumeLib())
  const onActivateBlocksTab = () => dispatch(showBlockTab());
  const onRequestCloseSpriteLibrary = () => dispatch(closeSpriteLib())
  const onRequestCloseBackdropLibrary = () => dispatch(closeBackdropLib())

  const onActivateDeck = (id) => {
    dispatch(activeDeck(id))
  }
  const handleCloseTipsLib = () => {
    dispatch(closeTipsLibrary())
  }

  return (
    <>
      {
        costumeLibVisible && <CostumeLibrary vm={vm} onRequestClose={handleCloseCostumeLib} />
      }
      {
        spriteLibVisible && <SpriteLibrary vm={vm} onActivateBlocksTab={onActivateBlocksTab} onRequestClose={onRequestCloseSpriteLibrary} />
      }
      {
        backdropLibVisible && <BackdropLibrary vm={vm} onRequestClose={onRequestCloseBackdropLibrary} />
      }
      {
        tipsLibraryVisible && <TipsLibrary visible={tipsLibraryVisible} onRequestClose={handleCloseTipsLib} onActivateDeck={onActivateDeck} />
      }
      {
        extensionLibVisible && <ExtensionLibraries />
      }
      {visible && <Cards/>}
      {
        IS_WEB_PLATFORM && <LinkModal />
      }

    </>
  );
};
