import {vm} from 'lib/scratch-vm';
import ThrottledPropertyHOC from 'lib/throttled-property-hoc';
import {injectIntl, useIntl} from 'react-intl';
import { useSelector } from 'react-redux';
import { selectTargets } from 'redux/tagets/targetsSlice';
import WaterMarkScratch from './watermark'


const ConnectedComponent: any = ThrottledPropertyHOC('asset', 500)(WaterMarkScratch as any) 

export const Watermark = () => {
    useSelector(selectTargets);

    const editingTarget = vm.runtime.getEditingTarget();
    if (!editingTarget) return null;
    let asset;
    if (editingTarget) {
        const target = editingTarget.toJSON();
        asset = target.costume?.asset;
    }
    return (
        <ConnectedComponent
            vm={vm}
            asset={asset}
        />
    )
};
