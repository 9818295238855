// @flow
import DFDropdown from "component/df-dropdown/DFDropdown";
import { useDispatch } from "react-redux";
import message from "../../../component/df-message/Message";

import { defineMessages, useIntl } from 'react-intl';
import { openTipsLibrary } from 'redux/asset-lib/assetLibStatusSlice';
import styles from "../MenuBar.module.scss";

export const Lesson = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const handleOpenTipsLib = () => {
    dispatch(openTipsLibrary());
  }
  const onDropdownClick = ({ key }) => {
    switch (key) {
      case '1':
        handleOpenOfficeDocument()
        break
      case '2':
        handleOnlineForum()
        break
      case '3':
        handleOpenTipsLib();
        break
      default:
        message.error("暂未实现!")
        break
    }

  }

  const handleOpenOfficeDocument = () => {
    window.open('https://mindplus.dfrobot.com.cn/', '_blank')
  }

  const handleOnlineForum = () => {
    window.open('http://mc.dfrobot.com.cn/forum-165-1.html', '_blank')
  }

  const items = [
    {
      key: '1',
      label: intl.formatMessage(messages.onlineLearn),
    },
    {
      key: '2',
      label: intl.formatMessage(messages.onlineForum),
    },
    {
      key: '3',
      label: intl.formatMessage(messages.videoTutorial),
    },
    {
      key: '4',
      label: intl.formatMessage(messages.offlineLearn),
    },
  ];
  return (
    <DFDropdown trigger={'click'} items={items} onItemClick={onDropdownClick} width={'100'}>
      <div className={styles.dropdownContent}>
        <span >{intl.formatMessage(messages.learn)}</span>
      </div>
    </DFDropdown>
  );
};


const messages = defineMessages({
  learn: {
    "id": 'gui.gui.learn',
    "defaultMessage": "Learning",
    "description": "top Menu Learn"
  },
  onlineLearn: {
    "id": 'gui.gui.onlineLearn',
    "defaultMessage": "Online Lenrning",
    "description": "top Menu Online Lenrning"
  },
  onlineForum: {
    "id": 'gui.gui.onlineForum',
    "defaultMessage": "Online Forum",
    "description": "top Menu Online Forum"
  },
  videoTutorial: {
    "id": 'gui.gui.videoTutorial',
    "defaultMessage": "Video tutorial",
    "description": "top Menu Video tutorial"
  },
  offlineLearn: {
    "id": 'gui.gui.offlineLearn',
    "defaultMessage": "Offline Lenrning",
    "description": "top Menu Offline Lenrning"
  },
})
