const ExtensionManager = require('../extension-support/extension-manager');
const log = require('../util/log');
const dispatch = require('../dispatch/central-dispatch');
const formatMessage = require('format-message');
const BlockType = require('../extension-support/block-type');
const uid = require('../util/uid');
const builtinExtensions = require('./builtinExtensions');
const {evalJsCode} = require('./evalJsCode');
const {getExtensionIdFromInstanceId, getRawOpcode, getExtensionIdFromOpcode, getExtensionInstanceId} = require('./extension-id');
const maybeFormatMessage = require('../util/maybe-format-message');
const DFRuntime = require('./df-runtime');


class DFExtensionManager extends ExtensionManager {
    // eslint-disable-next-line no-useless-constructor
    constructor (runtime) {
        super(runtime);
        // 记录每个target的extensions
        // this.__loadedExtensions = {
        //     scratch: new Map() // 角色和背景共用一个对象
        // };
        this._loadedExtensions = new Map();
    }

    // get _loadedExtensions () {
    //     if (this.runtime._editingTarget && this.runtime._editingTarget.isDevice) {
    //         const id = this.runtime._editingTarget.id;
    //         if (!this.__loadedExtensions[id]) {
    //             this.__loadedExtensions[id] = new Map();
    //         }
    //         return this.__loadedExtensions[id];
    //     }
    //     return this.__loadedExtensions.scratch;
    // }

    // set _loadedExtensions (data) {
    //     if (this.runtime._editingTarget.isDevice) {
    //         this.__loadedExtensions[this.runtime._editingTarget.id] = data;
    //     } else {
    //         this.__loadedExtensions.scratch = data;
    //     }
    // }

    // 指定targetId查找
    // getLoadedExtensions(targetId) {
    //     if (!targetId) return this._loadedExtensions;
    //     if (!this.__loadedExtensions[targetId])
    //         this.__loadedExtensions[targetId] = new Map();
    //     return this.__loadedExtensions[targetId];
    // }

    // 获取所有target的扩展
    getAllTargetsExtensions () {
        return Array.from(this._loadedExtensions.values()).reduce((total, item) => total.concat(item), []);
    }

    // isExtensionLoaded (extensionID, targetId) {
    //     // mark: 多主板变单主板
    //     extensionID = getExtensionIdFromInstanceId(extensionID);
    //     if (targetId) {
    //         const _loadedExtensions = this.getLoadedExtensions(targetId);
    //         return _loadedExtensions.has(extensionID);
    //     } else {
    //         return this._loadedExtensions.has(extensionID);
    //     }
    // }

    // 删除所有扩展
    deleteAllExtension () {
        Array.from(this._loadedExtensions.keys()).forEach(extensionId => {
            this.deleteExtension(extensionId);
        });
    }

    // 释放扩展资源
    releaseExtensionResource (extensionId) {
        // 获取serviceName
        const serviceName = this.getServerName(extensionId);
        const extensionObject = dispatch._getServiceProvider(serviceName);
        if (extensionObject && extensionObject.provider && extensionObject.provider.dispose) {
            extensionObject.provider.dispose();
        }
        // 设置为undefined, 删除service
        dispatch.setServiceSync(serviceName, undefined);
        // 从加载记录中移除
        this._loadedExtensions.delete(extensionId);
    }

    // 删除
    deleteExtension (extensionId) {
        this.releaseExtensionResource(extensionId);
        // 移除toolbox/block/执行函数等, 并刷新toolbox
        this.runtime._clearExtensionPrimitives(extensionId);
        // 刷新workspace
        this.runtime.emit(DFRuntime.BLOCKS_NEED_UPDATE);
    }

    getServerName (extensionId) {
        return this._loadedExtensions.get(extensionId);
    }

    // 加载设备
    async loadDevice (extensionIdWithVersion) {
        if (this.isExtensionLoaded(extensionIdWithVersion)) {
            return;
        }
        // 获取设备config
        const config = await this.runtime.extAssetManager.getDeviceConfigDataById(extensionIdWithVersion); // todo: 记录设备的版本, 对比
        if (!config) return Promise.reject(`没有找到config.json: ${extensionIdWithVersion}`);
        // 获取main.js
        const mainCode = await this.runtime.extAssetManager.getDeviceMainJsById(extensionIdWithVersion);
        const deviceClass = evalJsCode(mainCode);
        if (!deviceClass) return Promise.reject('设备资源main文件解析失败');

        // 创建设备
        await this.runtime.deviceManager.createDevice(config);

        // 加载设备中的扩展
        return this.loadExtensionURL(extensionIdWithVersion, deviceClass);
    }

    // 加载扩展
    async loadExtension (extensionIdWithVersion) {
        if (this.isExtensionLoaded(extensionIdWithVersion)) {
            return;
        }
        // 获取config.json
        const config = await this.runtime.extAssetManager.getExtensionConfigDataById(extensionIdWithVersion);
        let extensionObj = null;
        if (!config) return;
        // 内置scratch扩展没有main.js
        if (!config.isBuiltinScratch) {
            // 获取main.js
            const mainCode = await this.runtime.extAssetManager.getExtensionMainJsById(config.extensionIdWithVersion || config.extensionId);
            extensionObj = evalJsCode(mainCode);
            if (!extensionObj) return Promise.reject('扩展资源main文件解析失败');
        }
        this.runtime.deviceManager.addModule(extensionIdWithVersion, config);
        // 加载扩展
        return this.loadExtensionURL(extensionIdWithVersion, extensionObj);
    }

    // 加载外部扩展
    loadExternalExtension (extensionIdWithVersion, extensionObj) {
        // 1.eval 将字符串转换为可执行代码
        if (typeof extensionObj === 'string') extensionObj = evalJsCode(extensionObj);
        if (!extensionObj) {
            console.error('eval error.');
            return Promise.reject();
        }
        // mark: 多主板变单主板
        // extensionId = getExtensionIdFromInstanceId(extensionId);
        // 2.new extension
        const extensionClass = extensionObj.default || extensionObj;
        // 生成serviceName
        // const extensionInstanceId = this.genExtensionInstanceId(extensionId);
        // extensionId是在外部定义的, 内部id无效
        // extensionId: 用于判断是什么类型的扩展, 如: microbit
        // extensionInstanceId: 加载多个相同扩展的情况, 用于判断是哪个扩展
        // 之前scratch的设计是每个扩展只能加载一次, 现在mind+可以加载多个相同的扩展, 所以要用extensionInstanceId区分
        const extensionInstance = new extensionClass(this.runtime, extensionIdWithVersion);

        const getinfo_ = extensionInstance.getInfo.bind(extensionInstance);
        // block的disabled属性
        // const setBlockDisabled = categoryInfo_ => {
        //     if (categoryInfo_ && categoryInfo_.blocks && categoryInfo_.blocks.forEach) {
        //         const onlineFuncs = extensionInstance.getPrimitives && extensionInstance.getPrimitives();
        //         const uploadFuncs = extensionInstance.getCodePrimitives && extensionInstance.getCodePrimitives();
        //         categoryInfo_.blocks.forEach(blockInfo => {
        //             if (!blockInfo.opcode) return;
        //             const func = blockInfo.func || blockInfo.opcode;
        //             const codeFunc = blockInfo.codeFunc || blockInfo.opcode;
        //             blockInfo.disabled = {
        //                 online: !(onlineFuncs && onlineFuncs[func] || extensionInstance[func]),
        //                 upload: !(uploadFuncs && uploadFuncs[codeFunc])
        //             };
        //         });
        //     }
        // };
        extensionInstance.getInfo = () => {
            // 构建disabled参数
            const categoryInfo = getinfo_();
            // 设备的getinfo返回的是category列表
            if (categoryInfo && Array.isArray(categoryInfo)) {
                return categoryInfo.map((category, index) => {
                    // setBlockDisabled(category);
                    return {
                        ...category,
                        // 绑定id
                        id: extensionIdWithVersion,
                        categoryId: `${extensionIdWithVersion}_${index}`,
                        blocks: category.blocks&&category.blocks.filter(item=>item)
                        // id: `${extensionIdWithVersion}_${index}`
                    };
                });
            }
            // setBlockDisabled(categoryInfo);
            return {
                ...categoryInfo,
                // 绑定id
                id: extensionIdWithVersion,
                blocks: categoryInfo.blocks&&categoryInfo.blocks.filter(item=>item)
            };

        };
        // 设置语言环境
        if (extensionInstance.setLocale) extensionInstance.setLocale(formatMessage.setup().locale);
        // 3.注册extension
        const serviceName = this._registerInternalExtension(extensionInstance, extensionIdWithVersion);
        this._loadedExtensions.set(extensionIdWithVersion, serviceName);
        return Promise.resolve();
    }

    loadExtensionURL (extensionURL, extensionObj) {
        if (extensionObj) { // 先判断外部扩展(外部扩展和内部扩展都有的情况, 外部优先)
            return this.loadExternalExtension(extensionURL, extensionObj);
        } else if (builtinExtensions.hasOwnProperty(extensionURL)) { // 判断是否是内置扩展
            // todo: 内置扩展是否能加载多个？
            if (this.isExtensionLoaded(extensionURL)) {
                console.log('已经加载了该扩展', extensionURL);
                return Promise.resolve(extensionURL);
            }
            const extension = builtinExtensions[extensionURL]();
            const extensionInstance = new extension(this.runtime);
            const serviceName = this._registerInternalExtension(extensionInstance, extensionInstance.getInfo().id);
            //
            this._loadedExtensions.set(extensionURL, serviceName);
            return Promise.resolve(extensionURL);
        }
        return Promise.reject(`加载错误, 没有找到该扩展:${extensionURL}`);

    }

    _registerInternalExtension (extensionObject, extensionId) {
        const fakeWorkerId = this.nextExtensionWorker++;
        const serviceName = `extension_${fakeWorkerId}_${extensionId}`;
        dispatch.setServiceSync(serviceName, extensionObject);
        dispatch.callSync('extensions', 'registerExtensionServiceSync', serviceName);
        return serviceName;
    }

    // 生成扩展实例id
    // genExtensionInstanceId(extensionId) {
    //     // 如果是实例id, 就不用生成uid(DFRobot-microbit_xxx)
    //     if (extensionId.split('_').length >= 2) return extensionId;
    //     // 生成uid, 在扩展保存/加载之后也不会重复
    //     return `${extensionId}_${uid(true)}`
    // }

    // 兼容设备getInfo返回多个扩展
    _registerExtensionInfo (serviceName, extensionInfo) {
        if (Array.isArray(extensionInfo)) {
            extensionInfo.forEach(categoryInfo => {
                categoryInfo = this._prepareExtensionInfo(serviceName, categoryInfo);
                dispatch.call('runtime', '_registerExtensionPrimitives', categoryInfo).catch(e => {
                    log.error(`Failed to register primitives for extension on service ${serviceName}:`, e);
                });
            });
        } else {
            extensionInfo = this._prepareExtensionInfo(serviceName, extensionInfo);
            dispatch.call('runtime', '_registerExtensionPrimitives', extensionInfo).catch(e => {
                log.error(`Failed to register primitives for extension on service ${serviceName}:`, e);
            });
        }
    }

    // 修改block的执行函数
    _prepareBlockInfo (serviceName, blockInfo, isDevice) {
        blockInfo = Object.assign({}, {
            blockType: BlockType.COMMAND,
            terminal: false,
            blockAllThreads: false,
            arguments: {}
        }, blockInfo);
        blockInfo.opcode = blockInfo.opcode && this._sanitizeID(blockInfo.opcode);
        blockInfo.text = blockInfo.text || blockInfo.opcode;
        // 添加子菜单
        blockInfo.submenuId = blockInfo.submenuId || null;
        // 是否放置在‘更多’中
        blockInfo.placeInMore = blockInfo.placeInMore || false;
        // 分组信息
        blockInfo.group = blockInfo.group ? maybeFormatMessage(blockInfo.group) : null;

        // 如果既有placeInMore又有submenuId
        if (blockInfo.placeInMore && blockInfo.submenuId){
            // eslint-disable-next-line max-len
            log.warn(`Only one of the placeInMore and submenuId attributes can be configured at the same time, and placeInMore will be ignored on the block ${blockInfo.text} `);
            blockInfo.placeInMore = false;
        }

        switch (blockInfo.blockType) {
        case BlockType.EVENT:
            if (blockInfo.func) {
                log.warn(`Ignoring function "${blockInfo.func}" for event block ${blockInfo.opcode}`);
            }
            break;
        case BlockType.SUBMENU:
            break;
        case BlockType.BUTTON:
            if (blockInfo.opcode) {
                log.warn(`Ignoring opcode "${blockInfo.opcode}" for button with text: ${blockInfo.text}`);
            }
            break;
        default: {
            if (!blockInfo.opcode) {
                throw new Error('Missing opcode for block');
            }

            const funcName = blockInfo.func ? this._sanitizeID(blockInfo.func) : blockInfo.opcode;

            const getBlockInfo = blockInfo.isDynamic ?
                args => args && args.mutation && args.mutation.blockInfo :
                () => blockInfo;


            const serviceObject = dispatch.services[serviceName];

            // 实时模式执行函数
            if (serviceObject[funcName]) {
                blockInfo.func = (args, util) => {
                    // 如果是设备, 判断设备是否连接
                    if (isDevice && !this.runtime.deviceManager.isConnected()) {
                        // 触发提示
                        this.runtime.emit(DFRuntime.DEVICE_NOT_CONNECTED);
                        return Promise.reject();
                    }
                    const func = serviceObject[funcName];
                    if (!func) {
                        // 找不到func, 在实时模式下被禁用, 阻止程序运行
                        return Promise.reject();
                    }
                    const realBlockInfo = getBlockInfo(args);
                    // TODO: filter args using the keys of realBlockInfo.arguments? maybe only if sandboxed?
                    // return func.call(serviceObject, args, util, realBlockInfo);
                    return func.call(serviceObject, args, util, realBlockInfo);
                };
            }
            if (serviceObject.getPrimitives) {
                blockInfo.func = (args, util) => {
                    // 如果是设备, 判断设备是否连接
                    if (isDevice && !this.runtime.deviceManager.isConnected()) {
                        // 触发提示
                        this.runtime.emit(DFRuntime.DEVICE_NOT_CONNECTED);
                        return Promise.reject();
                    }
                    if (!serviceObject.getPrimitives()[funcName]) {
                        // 找不到func, 在实时模式下被禁用, 阻止程序运行
                        return Promise.reject();
                    }
                    const realBlockInfo = getBlockInfo(args);
                    // 不可改变this指向
                    return serviceObject.getPrimitives()[funcName](args, util, realBlockInfo);
                };
            }

            // 上传模式生成代码函数
            if (serviceObject.getCodePrimitives) {
                const codeFuncName = blockInfo.codeFunc ? this._sanitizeID(blockInfo.codeFunc) : blockInfo.opcode;
                const func = serviceObject.getCodePrimitives()[codeFuncName];
                if (!func){
                    blockInfo.codeFunc = () => Promise.reject();
                } else {
                    blockInfo.codeFunc = func.bind(serviceObject.getCodePrimitives());
                }
            }
            break;
        }
        }
        return blockInfo;
    }

    _prepareExtensionInfo (serviceName, extensionInfo) {
        extensionInfo = Object.assign({}, extensionInfo);
        // mind: id可以用短横线-, 下划线_, 和点.
        if (!/^[a-z0-9\-_\.\@]+$/i.test(extensionInfo.id)) {
            throw new Error('Invalid extension id');
        }
        extensionInfo.name = extensionInfo.name || extensionInfo.id;
        extensionInfo.blocks = extensionInfo.blocks || [];
        extensionInfo.targetTypes = extensionInfo.targetTypes || [];
        extensionInfo.blocks = extensionInfo.blocks.reduce((results, blockInfo) => {
            try {
                let result;
                switch (blockInfo) {
                case '---': // separator
                    result = '---';
                    break;
                default: // an ExtensionBlockMetadata object
                    result = this._prepareBlockInfo(serviceName, blockInfo, extensionInfo.id.indexOf('dev-') === 0);
                    break;
                }
                results.push(result);
            } catch (e) {
                // TODO: more meaningful error reporting
                log.error(`Error processing block: ${e.message}, Block:\n${JSON.stringify(blockInfo)}`);
            }
            return results;
        }, []);
        extensionInfo.menus = extensionInfo.menus || {};
        extensionInfo.menus = this._prepareMenuInfo(serviceName, extensionInfo.menus);
        return extensionInfo;
    }

    // 为了实现在一个class中定义设备的多个扩展, 所做的修改
    refreshBlocks () {
        const allPromises = Array.from(this._loadedExtensions.values()).map(serviceName =>
            dispatch.call(serviceName, 'getInfo')
                .then(info => {
                    if (Array.isArray(info)) {
                        info.forEach(categoryInfo => {
                            categoryInfo = this._prepareExtensionInfo(serviceName, categoryInfo);
                            dispatch.call('runtime', '_refreshExtensionPrimitives', categoryInfo);
                        });
                    } else {
                        info = this._prepareExtensionInfo(serviceName, info);
                        dispatch.call('runtime', '_refreshExtensionPrimitives', info);
                    }
                })
                .catch(e => {
                    log.error(`Failed to refresh built-in extension primitives: ${JSON.stringify(e)}`);
                })
        );
        return Promise.all(allPromises);
    }

    // 判断block是否禁用(toolbox)
    checkDisabledByBlockInfo (blockInfo) {
        // if (!blockInfo.disabled) return false;
        // return !!blockInfo.disabled[this.runtime.deviceManager.getCodeMode()];
        return false;
    }

    // 判断block是否被禁用(workspace)
    checkDisabledByOpcode (block) {
        if (!block || block.shadow) return false;
        // const opcode = block.opcode;
        // // 从opcode切割出extensionId
        // const extensionId = getExtensionIdFromOpcode(opcode);
        // // const extensionInstanceId = getExtensionInstanceId(opcode);
        // const rawOpcode = getRawOpcode(opcode);
        // // 默认扩展(这里是禁用block, make-toolbox是不显示block)
        // const defaultExtensions = ['motion', 'looks', 'sound', 'event', 'control', 'sensing', 'operator', 'data', 'procedures'];
        // if (defaultExtensions.indexOf(extensionId) !== -1) {
        //     if (this.runtime.deviceManager.isArduinoMode()) return ArduinoDefaultBlocksDisabled[extensionId](block.opcode);
        //     if (this.runtime.deviceManager.isScratchMode()) return ScratchDefaultBlocksDisabled[extensionId](block.opcode);
        //     return false;
        // }
        // // 扩展
        // const serviceName = this._loadedExtensions.get(extensionId);
        // if (!serviceName) return true;
        // const extensionInfo = dispatch.callSync(serviceName, 'getInfo');
        // let blocks = [];
        // if (Array.isArray(extensionInfo)) {
        //     blocks = extensionInfo.reduce((total, item) => total.concat(item.blocks), []);
        // } else {
        //     blocks = extensionInfo.blocks;
        // }
        // const blockInfo = blocks.find(item => item.opcode === rawOpcode);
        // if (blockInfo) return this.checkDisabledByBlockInfo(blockInfo);
        return false;
    }
}

module.exports = DFExtensionManager;


const ArduinoDefaultBlocksDisabled = {
    'motion' (opcode) {
        return true;
    },
    'looks' (opcode) {
        return true;
    },
    'sound' (opcode) {
        return true;
    },
    'event' (opcode) {
        if (opcode === 'event_start') return false;
        return true;
    },
    'control' (opcode) {
        if (opcode === 'control_stop') return true;
        if (opcode === 'control_create_clone_of') return true;
        if (opcode === 'control_start_as_clone') return true;
        if (opcode === 'control_delete_this_clone') return true;
        return false;
    },
    'sensing' (opcode) {
        return true;
    },
    'operator' (opcode) {
        return false;
    },
    'data' (opcode) {
        return false;
    },
    'procedures' (opcode) {
        return false;
    }
};

const ScratchDefaultBlocksDisabled = {
    'motion' (opcode) {
        return false;
    },
    'looks' (opcode) {
        return false;
    },
    'sound' (opcode) {
        return false;
    },
    'event' (opcode) {
        if (opcode === 'event_start') return true;
        return false;
    },
    'control' (opcode) {
        return false;
    },
    'sensing' (opcode) {
        return false;
    },
    'operator' (opcode) {
        return false;
    },
    'data' (opcode) {
        return false;
    },
    'procedures' (opcode) {
        return false;
    }
};
