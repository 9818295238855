import { vm } from "lib/scratch-vm";
import { useEffect, useState } from "react";
import styles from "./HuskyProgress.module.scss";
import { defineMessages, useIntl } from "react-intl";
import Box from "component/box/box";
import CloseButton from "component/close-button/close-button";

const messages = defineMessages({
  burning: {
    id: 'gui.progress.burning',
    description: 'prompt for burning dialog',
    defaultMessage: 'Is burning'
  },
  compiling: {
    id: 'gui.progress.compiling',
    description: 'prompt for burning dialog',
    defaultMessage: 'Is compiling'
  },
  uploadProgress: {
    id: 'gui.progress.uploadProgress',
    description: 'prompt for burning dialog',
    defaultMessage: 'upload progress'
  },
  compileProgress: {
    id: 'gui.progress.compileProgress',
    description: 'prompt for burning dialog',
    defaultMessage: 'compile progress'
  },
})
export const HuskyProgress = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const [X, setX] = useState(0);
  const [Y, setY] = useState(0);
  const intl = useIntl();
  const handleDragEnd = (ev: any) => {
    const endX = ev.pageX;
    const endY = ev.pageY;
    const changeX = endX - X;
    const changeY = endY - Y;
    const obj = ev.target;
    const overY = obj.offsetTop + changeY;
    const overX = obj.offsetLeft + changeX;
    obj.style.top = overY + 'px';
    obj.style.left = overX + 'px';
  }
  const handleDragStart = (e: any) => {
    const startX = e.pageX;
    const startY = e.pageY;
    setX(startX);
    setY(startY);
  }
  const onCloseProgress = () => setShow(false)
  useEffect(() => {
    vm.runtime.on('huskylensPro', (event, ...data) => {
      switch (event) {
        case 'message': {
          break;
        }
        case 'percent': {
          let percent = data[0];
          if (percent > 0 && percent <= 100) {
            setProgress(percent);
          } else {
            setError(true)
          }
          break;
        }
        case 'openPercent': {
          setProgress(0);
          setMessage("");
          setShow(true)
          break;
        }
        case 'percentMessage': {
          let message = data[0];
          setMessage(message);
          break;
        }
        case 'exit': {
          let code = data[0];
          setMessage(code)
          setTimeout(() => {
            setShow(false)
          }, 200);
          break;
        }
      }
    });
    return () => {
      vm.runtime.off('huskylensPro');
    }
  }, [])
  return (
    <>
      {
        show && (
          <div
            className={styles.progressMessageWrapper}
            draggable="true"
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <Box
              className={styles.messageWrapper}
            >
              <div className={styles.header}>
                <div
                  className={
                    styles.headerItemClose
                  }
                >
                  <CloseButton
                    size={CloseButton.SIZE_LARGE}
                    onClick={onCloseProgress}
                  />
                </div>
              </div>
              <div className={styles.messageStrWrapper}>
                <span className={styles.writeStateSpan}>
                  {message ? message : intl.formatMessage(messages.compiling)}
                </span>
              </div>
              <div
                className={styles.modeWrapper}
              >
                <span></span>
              </div>
              <div className={styles.progressNumWrapper}>
                <span
                  className={styles.writeProgressSpan}
                >{intl.formatMessage(messages.uploadProgress)}:{progress}%</span>
              </div>
              <div className={styles.progress}>
                <div className={styles.progressBarWrapper}>
                  <div
                    className={styles.progressBar + (progress != -1 ? " progress-bar-active" : "")}
                    style={{ width: progress }}
                  />
                </div>
              </div>
            </Box>
          </div >
        )
      }

    </>
  )
};
