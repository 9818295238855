import bindAll from 'lodash.bindall';
import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';


import OpcodeLabels from 'lib/opcode-labels';

import MonitorListComponent from './monitor-list-component/monitor-list.jsx';

class MonitorList extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleMonitorChange'
        ]);
        OpcodeLabels.setTranslatorFunction(props.intl.formatMessage);
    }
    handleMonitorChange (id, x, y) { // eslint-disable-line no-unused-vars
        this.props.moveMonitorRect(id, x, y);
    }
    render () {
        return (
            <MonitorListComponent
                onMonitorChange={this.handleMonitorChange}
                {...this.props}
            />
        );
    }
}

MonitorList.propTypes = {
    moveMonitorRect: PropTypes.func.isRequired
};

// todo: errorBoundaryHOC
export default injectIntl(MonitorList)
