import BrowserUtils from './browser-utils';
import downloadBlob from './download-blob';
let imageIconCache = {};
let flag = false;
class ScreenShots {
  static screenShots(ws, projectTitle) {
    if (flag) return;
    flag = true;
    const metrics = ws.getBlocksBoundingBox();
    const sg = ws.getParentSvg().cloneNode(true);
    ScreenShots.cleanUpBlocklySvg(sg);
    ScreenShots.blocklyToSvgAsync(sg, metrics.x, metrics.y, metrics.width, metrics.height).then((xsg) => {
      if (!xsg) return Promise.resolve(undefined);
      return ScreenShots.toPngAsyncInternal(xsg.width, xsg.height, 4, xsg.xml);
    }).then((uri) => {
      //TODO: 离线版待实现
      if (BrowserUtils.isSafari()) {
        uri = uri.replace(/^data:image\/[^;]/, 'data:application/octet-stream');
      }
      const name = `screenshot-${projectTitle}-${(new Date()).getTime()}`;
      downloadBlob(name, ScreenShots.base64ToBlob(uri));
    }).finally(() => {
      flag = false;
    })
  }
  static cleanUpBlocklySvg(svg) {
    BrowserUtils.removeClass(svg, "blocklySvg");
    BrowserUtils.addClass(svg, "blocklyPreview");

    BrowserUtils.toArray(svg.querySelectorAll('.blocklyMainBackground,.blocklyScrollbarBackground'))
      .forEach(el => { if (el) el.parentNode.removeChild(el) });
    BrowserUtils.toArray(svg.querySelectorAll('.blocklyZoom'))
      .forEach(el => { if (el) el.parentNode.removeChild(el) });

    svg.removeAttribute('width');
    svg.removeAttribute('height');

    BrowserUtils.toArray(svg.querySelectorAll('.blocklyBlockCanvas,.blocklyBubbleCanvas'))
      .forEach(el => el.removeAttribute('transform'));

    // In order to get the Blockly comment's text area to serialize properly they have to have names
    const parser = new DOMParser();
    BrowserUtils.toArray(svg.querySelectorAll('.scratchCommentTextarea'))
      .forEach(el => {
        const dom = parser.parseFromString(
          '<!doctype html><body>' + BrowserUtils.html2Quote((el).value),
          'text/html');
        el.textContent = dom.body.textContent;
      });
    return svg;
  }
  static blocklyToSvgAsync(sg, x, y, width, height) {
    if (!sg.childNodes[0]) return Promise.resolve(undefined);
    sg.removeAttribute("width");
    sg.removeAttribute("height");
    sg.removeAttribute("transform");
    const XLINK_NAMESPACE = "http://www.w3.org/1999/xlink";
    const xmlString = BrowserUtils.serializeNode(sg)
      .replace(/^\s*<svg[^>]+>/i, '')
      .replace(/<\/svg>\s*$/i, '') // strip out svg tag
    const svgXml = `<svg version="1.1" style="font-size: ${ScreenShots.getFontSize()}px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="${XLINK_NAMESPACE}" width="${width}" height="${height}" viewBox="${x} ${y - 20} ${width} ${height + 20}">${xmlString}</svg>`;
    const xsg = new DOMParser().parseFromString(svgXml, "image/svg+xml");
    const cssLink = xsg.createElementNS("http://www.w3.org/1999/xhtml", "style");
    // @todo add isRtl
    const isRtl = false;
    const blocklySvg = BrowserUtils.toArray(document.head.querySelectorAll("style"))
      .filter((el) => /\.blocklySvg/.test(el.innerText))[0];
    // CSS may contain <, > which need to be stored in CDATA section
    const cssString = (blocklySvg ? blocklySvg.innerText : "") + '\n\n';
    cssLink.appendChild(xsg.createCDATASection(cssString));
    xsg.documentElement.insertBefore(cssLink, xsg.documentElement.firstElementChild);

    return ScreenShots.expandImagesAsync(xsg)
      .then(() => ScreenShots.convertIconsToPngAsync(xsg))
      .then(() => {
        return {
          width: width,
          height: height,
          svg: BrowserUtils.serializeNode(xsg).replace('<style xmlns="http://www.w3.org/1999/xhtml">', '<style>'),
          xml: ScreenShots.documentToSvg(xsg),
          css: cssString
        };
      });
  }
  static getFontSize() {
    let fontSize = 10;
    const width = window.screen.width;
    if (width > 1366) {
      fontSize = 10;
    } else if (width > 1280 && width <= 1366) {
      fontSize = 8;
    } else if (width <= 1280) {
      fontSize = 7;
    } else {
      fontSize = 10;
    }
    return fontSize;
  }
  static expandImagesAsync(xsg) {
    const imageXLinkCache = {};
    const XLINK_NAMESPACE = "http://www.w3.org/1999/xlink";
    const images = xsg.getElementsByTagName("image");
    const p = BrowserUtils.toArray(images)
      .filter(image => {
        const href = image.getAttributeNS(XLINK_NAMESPACE, "href");
        return href && !/^data:/.test(href);
      })
      .map((image) => {
        const href = image.getAttributeNS(XLINK_NAMESPACE, "href");
        let dataUri = imageXLinkCache[href];
        return (dataUri ? Promise.resolve(imageXLinkCache[href])
          : BrowserUtils.loadImageAsync(image.getAttributeNS(XLINK_NAMESPACE, "href"))
            .then((img) => {
              const cvs = document.createElement("canvas");
              const ctx = cvs.getContext("2d");
              cvs.width = img.width;
              cvs.height = img.height;
              ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, cvs.width, cvs.height);
              imageXLinkCache[href] = dataUri = cvs.toDataURL("image/png");
              return dataUri;
            }).catch(e => {
              // ignore load error
              console.error(`svg render: failed to load ${href}`)
            }))
          .then(href => { image.setAttributeNS(XLINK_NAMESPACE, "href", href); })
      });
    return Promise.all(p).then(() => { })
  }
  static convertIconsToPngAsync(xsg) {
    if (!BrowserUtils.isEdge()) return Promise.resolve();
    imageIconCache = imageIconCache || {};
    const XLINK_NAMESPACE = "http://www.w3.org/1999/xlink";
    const images = xsg.getElementsByTagName("image");
    const p = BrowserUtils.toArray(images)
      .filter(image => /^data:image\/svg\+xml/.test(image.getAttributeNS(XLINK_NAMESPACE, "href")))
      .map((image) => {
        const svgUri = image.getAttributeNS(XLINK_NAMESPACE, "href");
        const width = parseInt(image.getAttribute("width").replace(/[^0-9]/g, ""));
        const height = parseInt(image.getAttribute("height").replace(/[^0-9]/g, ""));
        let pngUri = imageIconCache[svgUri];

        return (pngUri ? Promise.resolve(pngUri)
          : ScreenShots.toPngAsyncInternal(width, height, 4, svgUri))
          .then(href => {
            // imageIconCache[svgUri] = href;
            image.setAttributeNS(XLINK_NAMESPACE, "href", href);
          })
      });
    return Promise.all(p).then(() => { })
  }
  static documentToSvg(xsg) {
    const xml = new XMLSerializer().serializeToString(xsg);
    const data = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(xml)));
    return data;
  }
  static toPngAsyncInternal(width, height, pixelDensity, data) {
    return new Promise((resolve, reject) => {
      const MAX_SCREENSHOT_SIZE = (1e6) * 3; // max 3Mb
      const cvs = document.createElement("canvas");
      const ctx = cvs.getContext("2d");
      const img = new Image();
      cvs.width = width * pixelDensity;
      cvs.height = height * pixelDensity;
      while (cvs.width > 8000 || cvs.height > 8000) {
        cvs.width = (cvs.width / 2) >> 0;
        cvs.height = (cvs.height / 2) >> 0;
      }
      img.onload = function () {
        ctx.drawImage(img, 0, 0, width, height, 0, 0, cvs.width, cvs.height);
        let canvasdata = cvs.toDataURL("image/png");
        // if the generated image is too big, shrink image
        while (canvasdata.length > MAX_SCREENSHOT_SIZE) {
          cvs.width = (cvs.width / 2) >> 0;
          cvs.height = (cvs.height / 2) >> 0;
          ctx.drawImage(img, 0, 0, width, height, 0, 0, cvs.width, cvs.height);
          canvasdata = cvs.toDataURL("image/png");
        }
        resolve(canvasdata);
      };
      img.onerror = ev => {
        console.error("blocks", "blocks screenshot failed");
        resolve(undefined)
      }
      img.src = data;
    })
  }
  static base64ToBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    var parts;
    var contentType;
    var raw;
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(',');
      contentType = parts[0].split(':')[1];
      raw = decodeURIComponent(parts[1]);
      return new Blob([raw], { type: contentType });
    }
    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);
    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }
  static clearImageIconCache() {
    imageIconCache = null;
  }
}
export default ScreenShots;
