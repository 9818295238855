import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import PaintEditor from 'lib/scratch-paint';
import styles from './paint-editor-wrapper.module.scss'
import {inlineSvgFonts} from 'scratch-svg-renderer';

class PaintEditorWrapper extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleUpdateImage',
            'handleUpdateName'
        ]);
    }

    shouldComponentUpdate(nextProps) {
        return this.props.imageId !== nextProps.imageId ||
            this.props.rtl !== nextProps.rtl ||
            this.props.name !== nextProps.name;
    }

    handleUpdateName(name) {
        this.props.vm.renameCostume(this.props.selectedCostumeIndex, name);
    }

    handleUpdateImage(isVector, image, rotationCenterX, rotationCenterY) {
        if (isVector) {
            this.props.vm.updateSvg(
                this.props.selectedCostumeIndex,
                image,
                rotationCenterX,
                rotationCenterY);
        } else {
            this.props.vm.updateBitmap(
                this.props.selectedCostumeIndex,
                image,
                rotationCenterX,
                rotationCenterY,
                2 /* bitmapResolution */);
        }
    }

    render() {
        if (!this.props.imageId) return null;
        const {
            selectedCostumeIndex,
            vm,
            ...componentProps
        } = this.props;
        return (
            <div className={styles.paint}>
                <PaintEditor
                    {...componentProps}
                    image={vm.getCostume(selectedCostumeIndex)}
                    onUpdateImage={this.handleUpdateImage}
                    onUpdateName={this.handleUpdateName}
                    fontInlineFn={inlineSvgFonts}
                />
            </div>
        );
    }
}

export default PaintEditorWrapper
