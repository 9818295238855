import ScratchBlocks from 'scratch-blocks';
import { vm } from 'lib/scratch-vm'
import VMScratchBlocks from './blocks';
import alertDialog from 'component/df-alert/DFAlert';

export default ScratchBlocks;

export const CustomScratchBlocks = VMScratchBlocks(vm);


// �޸���ʾ��
ScratchBlocks.alert = function (message, timeout = 4000) {
    alertDialog(ScratchBlocks.Msg.PROMPT, message, { timeout: timeout, mode: 1, cover: false });
}
// �޸�ȷ�Ͽ�
ScratchBlocks.confirm = function (message, callback) {
    alertDialog(ScratchBlocks.Msg.PROMPT, message, { cover: false, btns: [{ text: ScratchBlocks.Msg.OK, callBack: ()=>callback(true) }, { text: ScratchBlocks.Msg.CANCEL }] });
}

