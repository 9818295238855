import React, {FC, PropsWithChildren} from 'react';

import {store} from "redux/store";
import {Provider} from 'react-redux'
import CustomIntlProvider from "./CustomIntlProvider";

const CustomProvider: FC<PropsWithChildren> = ({children}) => {
    return (
        <Provider store={store}>
            <CustomIntlProvider>
                {children}
            </CustomIntlProvider>
        </Provider>
    );
};

export default CustomProvider;
