import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

// 弹框组件显示状态（包含图片AI弹框和ML5训练弹框）
interface ModalState {
    pictureAIModalShow : boolean
    ml5trainModalShow : boolean
}

const initialState: ModalState = {
    ml5trainModalShow : false,
    pictureAIModalShow : false
}

export const modalStateSlice = createSlice({
    name: 'modalState',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setIsShowPictureAIModal: (state, action: PayloadAction<boolean>) => {
            state.pictureAIModalShow = action.payload;
        },
        setIsShowML5trainModal: (state, action: PayloadAction<boolean>) => {
            state.ml5trainModalShow = action.payload;
        },
    },
})

export const {setIsShowPictureAIModal, setIsShowML5trainModal} = modalStateSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectModalState = (state: RootState) => state.modalState

export default modalStateSlice.reducer
