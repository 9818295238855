// Message.tsx

import React, {useState, useEffect} from 'react';
import styles from './DFMessage.module.scss';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    InfoCircleOutlined,
    LoadingOutlined
} from "@ant-design/icons";

export type MessageType = 'success' | 'info' | 'warning' | 'error' | 'loading';

export interface MessageProps {
    type: MessageType;
    content: string;
    duration?: number;
    onClose?: () => void;
    id: string
}

const Message: React.FC<MessageProps> = ({id, type, content, duration = 3000, onClose}) => {
    const [isVisible, setIsVisible] = useState(true);
    const handleCloseClick = () => {
        setIsVisible(false);
        if (onClose) {
            onClose();
        }
    };

    return isVisible ? (
        <div className={`${styles.message} ${styles.visible}`}>
            <span className={`${styles[type]} `}>
                 {
                     type === 'success' && <CheckCircleOutlined/>
                 }
                {
                    type === 'info' && <InfoCircleOutlined/>
                }
                {
                    type === 'error' && <CloseCircleOutlined/>
                }
                {
                    type === 'warning' && <InfoCircleOutlined/>
                }
                {
                    type === 'loading' && <LoadingOutlined/>
                }
            </span>
            <div className={styles.content}>
                {
                    content.split("\n").map(item => <div>{item}</div>)
                }
            </div>
            {type !== 'loading' && (
                <div>
                    <div className={styles.closeButton} onClick={handleCloseClick}>
                        <CloseOutlined />
                    </div>
                </div>

            )}
        </div>
    ) : null;
};

export default Message;
