import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

// Define a type for the slice state
interface RestoreDeletionState {
    restoreFun: null|Function
    deletedItem: string
}

// Define the initial state using that type
const initialState: RestoreDeletionState = {
    restoreFun: null,
    deletedItem: ''
}

export const restoreDeletionSlice = createSlice({
    name: 'restoreDeletion',
    initialState,
    reducers: {
        setRestore: (state, action: PayloadAction<RestoreDeletionState>) => {
            state.restoreFun = action.payload.restoreFun; 
            state.deletedItem = action.payload.deletedItem; 
        }
    },
})

export const {
    setRestore
} = restoreDeletionSlice.actions

export const selectRestoreDeletion = (state: RootState) => state.restoreDeletion

export default restoreDeletionSlice.reducer
