// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".paper-canvas_paper-canvas_2biXB {\n    top: 1px; /* leave room for the border */\n    left: 1px;\n    width: calc(100% - 2px);\n    height: calc(100% - 2px);\n    margin: auto;\n    position: absolute;\n    background-color: #D9E3F2;\n}\n", ""]);
// Exports
exports.locals = {
	"paper-canvas": "paper-canvas_paper-canvas_2biXB",
	"paperCanvas": "paper-canvas_paper-canvas_2biXB"
};
module.exports = exports;
