import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

interface TargetInfo {
    scrollX: number
    scrollY: number
    scale: number
}

interface MetricsState {
    targets: {[id: string]: TargetInfo}
}

const initialState: MetricsState = {
    targets: {}
}

export const workspaceMetricsSlice = createSlice({
    name: 'workspaceMetrics',
    initialState,
    reducers: {
        updateMetrics: (state, action: PayloadAction<TargetInfo&{targetID:string}>) => {
            const payload = action.payload;
            if (
                !state.targets[payload.targetID] ||
                state.targets[payload.targetID].scrollX !== payload.scrollX ||
                state.targets[payload.targetID].scrollY !== payload.scrollY ||
                state.targets[payload.targetID].scale !== payload.scale
            ) {
                state.targets[payload.targetID] = {
                    scrollX: payload.scrollX,
                    scrollY: payload.scrollY,
                    scale: payload.scale
                }
            }
        },
    },
})

export const {updateMetrics} = workspaceMetricsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectWorkspaceMetrics = (state: RootState) => state.workspaceMetrics

export default workspaceMetricsSlice.reducer
