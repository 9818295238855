// GENERATED FILE:
export default {
  "ab": {
    "gui.backpack.header": "Абӷакыдҵа",
    "gui.backpack.errorBackpack": "Абӷакыдҵа аҭагалараан агха",
    "gui.backpack.loadingBackpack": "Аҭагалара...",
    "gui.backpack.more": "Иҵегь",
    "gui.backpack.emptyBackpack": "Абӷакыдҵа ҭацәуп",
    "gui.gui.costumesTab": "Акостиумқәа",
    "gui.gui.soundsTab": "Абжьқәа",
    "gui.gui.backdropsTab": "Аҿаԥшырақәа",
    "gui.gui.addExtension": "Иацҵатәуп арҭбаара",
    "gui.costumeTab.addCostumeFromLibrary": "Иалхтәуп акостиум",
    "gui.costumeLibrary.chooseACostume": "Иалхтәуп акостиум",
    "gui.costumeTab.addBackdropFromLibrary": "Иалхтәуп аҿаԥшыра ",
    "gui.costumeTab.addBlankCostume": "Иҭыхтәуп",
    "gui.costumeTab.addSurpriseCostume": "Асиурприз",
    "gui.costumeTab.addFileBackdrop": "Иҭагалатәуп аҿаԥшыра",
    "gui.costumeTab.addFileCostume": "Иҭагалатәуп акостиум",
    "gui.costumeTab.addCameraCostume": "Акамера",
    "gui.soundEditor.trim": "Иамырффатәуп",
    "gui.soundEditor.stop": "Иаанкылатәуп",
    "gui.soundEditor.sound": "Абжьы",
    "gui.soundEditor.play": "Иарҳәатәуп",
    "gui.soundEditor.save": "Еиқәырхатәуп",
    "gui.soundEditor.undo": "Иаҟәыхтәуп",
    "gui.soundEditor.redo": "Ирхынҳәтәуп",
    "gui.soundEditor.faster": "Ирццактәуп",
    "gui.soundEditor.slower": "Аццакыра иагырхатәуп",
    "gui.soundEditor.echo": "Аныҩбжьы",
    "gui.soundEditor.robot": "Аробот",
    "gui.soundEditor.louder": "Абжьы ардура",
    "gui.soundEditor.softer": "Абжьы армаҷра",
    "gui.soundEditor.reverse": "Еиҵыхтәуп",
    "gui.soundTab.recordSound": "Иҭаҩтәуп",
    "gui.soundTab.addSoundFromLibrary": "Иалхтәуп абжьы",
    "gui.soundTab.surpriseSound": "Асиурприз",
    "gui.soundTab.fileUploadSound": "Иҭагалатәуп абжьы",
    "gui.controls.stop": "Иаанкылатәуп",
    "gui.controls.go": "Ԥхьаҟа",
    "gui.stageHeader.stageSizeUnFull": "Иҭыҵтәуп аекран зегь арежим ",
    "gui.SpriteInfo.show": "Иаарԥштәуп",
    "gui.SpriteInfo.size": "Ашәагаа",
    "gui.directionPicker.rotationStyles.allAround": "Иргьежьтәуп",
    "gui.directionPicker.rotationStyles.leftRight": "Армарахь/Арӷьарахь",
    "gui.directionPicker.rotationStyles.dontRotate": "Иргьежьтәӡам",
    "gui.spriteSelectorItem.contextMenuDuplicate": "адубликат ҟаҵатәуп",
    "gui.spriteSelectorItem.contextMenuDelete": "ианыхтәуп",
    "gui.spriteSelectorItem.contextMenuExport": "аекспорт",
    "gui.spriteSelector.addSpriteFromPaint": "Иҭыхтәуп",
    "gui.spriteSelector.addSpriteFromSurprise": "Асиурприз",
    "gui.spriteSelector.addSpriteFromFile": "Дҭагалатәуп анагӡаҩ",
    "gui.stageSelector.stage": "Асцена",
    "gui.stageSelector.backdrops": "Аҿаԥшырақәа",
    "gui.stageSelector.addBackdropFromPaint": "Иҭыхтәуп",
    "gui.stageSelector.addBackdropFromSurprise": "Асиурприз",
    "gui.stageSelector.addBackdropFromFile": "Иҭагалатәуп аҿаԥшыра",
    "gui.modal.back": "Шьҭахьҟа",
    "gui.library.filterPlaceholder": "Аԥшаара",
    "gui.libraryTags.all": "Зегьы",
    "gui.libraryTags.animals": "Аԥстәқәа",
    "gui.libraryTags.dance": "Акәашара",
    "gui.libraryTags.effects": "Аеффектқәа",
    "gui.libraryTags.fantasy": "Афантастика",
    "gui.libraryTags.fashion": "Амода",
    "gui.libraryTags.food": "Афатә",
    "gui.libraryTags.indoors": "Ауада аҩныҵҟа",
    "gui.libraryTags.loops": "Ациклқәа",
    "gui.libraryTags.music": "Амузыка",
    "gui.libraryTags.notes": "Азгәаҭақәа",
    "gui.libraryTags.outdoors": "Адәахьы",
    "gui.libraryTags.patterns": "Аузорқәа",
    "gui.libraryTags.people": "Ауаа",
    "gui.libraryTags.percussion": "Аперкуссиа",
    "gui.libraryTags.space": "Акосмос",
    "gui.libraryTags.sports": "Аспорт",
    "gui.libraryTags.underwater": "Аӡаҵаҟатәи адунеи",
    "gui.libraryTags.voice": "Абжьы",
    "gui.libraryTags.wacky": "Ахеилагақәа",
    "gui.libraryTags.animation": "Анимациа",
    "gui.libraryTags.art": "Аҟазара",
    "gui.libraryTags.games": "Ахәмаррақәа",
    "gui.libraryTags.stories": "Аҭоурыхқәа",
    "gui.libraryTags.letters": "Анбанқәа",
    "gui.soundLibrary.chooseASound": "Иалхтәуп абжьы",
    "gui.SpriteInfo.spritePlaceholder": "Ахьӡ",
    "gui.cards.more-things-to-try": "Игәашәҭ еиҭа афункциа ҿыцқәа!",
    "gui.cards.see-more": "Еилкаатәуп еиҳаны",
    "gui.cards.shrink": "Еикәарҳәтәуп",
    "gui.cards.expand": "Еиҵыхтәуп",
    "gui.cards.close": "Иарктәуп",
    "gui.loader.message1": "Аблокқәа раԥҵара...",
    "gui.loader.message2": "Анагӡаҩцәа рҭагалара...",
    "gui.loader.message3": "Абжьқәа рҭагалара...",
    "gui.loader.message4": "Арҭбаарақәа рҭагалара...",
    "gui.loader.message5": "Ацгәқәа рхылаԥшра...",
    "gui.loader.message6": "Нанохәҭаҷқәа рынашьҭра...",
    "gui.loader.message7": "Гобо арчра...",
    "gui.loader.message8": "Асмаиликқәа разырхиара...",
    "gui.loader.headline": "Апроеқт аҭагалара",
    "gui.cameraModal.cameraModalTitle": "Афото аҭыхра",
    "gui.cameraModal.loadingCameraMessage": "Акамера аҟынтә аҭагалара...",
    "gui.cameraModal.permissionRequest": "Акамера ахархәараз иаҭахуп шәара шақәшаҳаҭхара",
    "gui.cameraModal.retakePhoto": "Афото ҽазнык аҭыхра",
    "gui.cameraModal.save": "Еиқәырхатәуп",
    "gui.cameraModal.takePhoto": "Афото аҭыхра",
    "gui.cameraModal.loadingCaption": "Аҭагалара...",
    "gui.cameraModal.enableCameraCaption": "Иаҿактәуп акамера",
    "gui.recordModal.title": "Иҭаҩтәуп абжьы",
    "gui.playbackStep.stopMsg": "Иаанкылатәуп",
    "gui.playbackStep.playMsg": "Иарҳәатәуп",
    "gui.playbackStep.loadingMsg": "Аҭагалара...",
    "gui.playbackStep.saveMsg": "Еиқәырхатәуп",
    "gui.playbackStep.reRecordMsg": "Ҿыц иҭаҩтәуп",
    "gui.webglModal.label": "Шәара шәбраузер иаднакылом WebGL.",
    "gui.webglModal.webgllink": "WebGL аднакылом",
    "gui.prompt.cancel": "Иаҟәыхтәуп",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Иацҵатәуп аҭагалараз аҭыԥ",
    "gui.customProcedures.addAnInputBoolean": "Иацҵатәуп аҭагалараз аҭыԥ",
    "gui.customProcedures.numberTextType": "ахыԥхьаӡара ма атеқст",
    "gui.customProcedures.booleanType": "алогикатә",
    "gui.customProcedures.addALabel": "Иацҵатәуп атеқст аҭыԥ",
    "gui.customProcedures.runWithoutScreenRefresh": "Идәықәҵатәуп аекран арҿыцрада",
    "gui.customProcedures.cancel": "Иаҟәыхтәуп",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Шәпроеқтқәа адунеи иашәырдыр.",
    "gui.extension.music.name": "Амузыка",
    "gui.extension.music.description": "Иаԥышәҵа амузыка аинструментқәеи адаулқәеи рыла.",
    "gui.extension.pen.name": "Акалам",
    "gui.extension.pen.description": "Иҭыхтәуп анагӡаҩцәа.",
    "gui.gui.variableScopeOptionAllSprites": "Анагӡаҩцәа зегьы рзы",
    "gui.gui.variableScopeOptionSpriteOnly": "Абри анагӡаҩ мацара изы",
    "gui.gui.variablePromptAllSpritesMessage": "Ари аҽеиҭак анагӡаҩцәа зегь ирызкуп.",
    "gui.monitor.contextMenu.default": "истандарту аԥшра",
    "gui.monitor.contextMenu.large": "аԥшра рдыуны",
    "gui.monitor.contextMenu.slider": "арымаа хәыҷ",
    "gui.monitor.contextMenu.import": "аимпорт",
    "gui.monitor.contextMenu.export": "аекспорт",
    "gui.opcodeLabels.direction": "ахырхарҭа",
    "gui.opcodeLabels.xposition": "x аҭыԥ",
    "gui.opcodeLabels.yposition": "y аҭыԥ",
    "gui.opcodeLabels.size": "ашәагаа",
    "gui.opcodeLabels.costumename": "акостиум ахьӡ",
    "gui.opcodeLabels.costumenumber": "акостиум аномер",
    "gui.opcodeLabels.backdropname": "аҿаԥшыра ахьӡ",
    "gui.opcodeLabels.backdropnumber": "аҿаԥшыра аномер",
    "gui.opcodeLabels.volume": "абжьы адура",
    "gui.opcodeLabels.tempo": "атемп",
    "gui.opcodeLabels.answer": "аҭак",
    "gui.opcodeLabels.loudness": "абжьы адура",
    "gui.opcodeLabels.year": "ашықәс",
    "gui.opcodeLabels.month": "амза",
    "gui.opcodeLabels.date": "арыцхә",
    "gui.opcodeLabels.dayofweek": "амчыбжь амш",
    "gui.opcodeLabels.hour": "асааҭ",
    "gui.opcodeLabels.minute": "аминуҭ",
    "gui.opcodeLabels.second": "асекунд",
    "gui.opcodeLabels.timer": "аамҭарбага",
    "music.categoryName": "Амузыка",
    "translate.categoryName": "Атанслиациа",
    "pen.categoryName": "Акалам",
    "pen.changeColorParam": "иԥсахтәуп акалам [COLOR_PARAM][VALUE] ала",
    "pen.changeHue": "иԥсахтәуп акалам аԥштәы [HUE] ала",
    "pen.changeShade": "иԥсахтәуп акалам агага [SHADE] ала",
    "pen.changeSize": "иԥсахтәуп акалам ашәагаа[SIZE] ала",
    "pen.clear": "ианыхтәуп зегьы",
    "pen.colorMenu.brightness": "ажжара",
    "pen.colorMenu.color": "аԥштәы",
    "pen.colorMenu.saturation": "аԥштәылгазаара",
    "pen.colorMenu.transparency": "аҵәцара",
    "pen.penDown": "акалам лашьҭтәуп",
    "pen.penUp": "акалам иҩахатәуп",
    "pen.setColor": "иқәыргылатәуп акалам аԥштәы [COLOR]",
    "pen.setColorParam": "иқәыргылатәуп акалам [COLOR_PARAM]=[VALUE]",
    "pen.setHue": "иқәыргылатәуп акалам аԥштәы [HUE]",
    "pen.setShade": "иқәыргылатәуп акалам агага [SHADE]",
    "pen.setSize": "иқәыргылатәуп акалам ашәагаа [SIZE]",
    "pen.stamp": "аштамп",
    "music.changeTempo": "иԥсахтәуп атемп [TEMPO] ала",
    "music.drumBass": "(2) Абасстә даул",
    "music.drumBongo": "(13) Бонго",
    "music.drumCabasa": "(15) Кабаса",
    "music.drumClaves": "(9) Аклавесин",
    "music.drumClosedHiHat": "(6) Иарку хаи-хет",
    "music.drumConga": "(14) Аконга",
    "music.drumCowbell": "(11) Аҵәҵәабжьы",
    "music.drumCrashCymbal": "(4) Асаанқәа рысра",
    "music.drumCuica": "(18) Акуика",
    "music.drumGuiro": "(16) Гуиро",
    "music.drumHandClap": "(8) Анапеинҟьабжь",
    "music.drumOpenHiHat": "(5) Иаарту хаи-хет",
    "music.drumSideStick": "(3) Аганахьтәи амаа",
    "music.drumSnare": "(1) Адаул хәыҷы",
    "music.drumTambourine": "(7) Атамбурин",
    "music.drumTriangle": "(12) Ахкәакь",
    "music.drumVibraslap": "(17) Авибрациа ҟазҵо аслеп",
    "music.drumWoodBlock": "(10) Амҿтәы шәындыҟәра хәҷы",
    "music.getTempo": "атемп",
    "music.instrumentBass": "(6) Абасс",
    "music.instrumentBassoon": "(14) Афагот",
    "music.instrumentCello": "(8) Авиолончель",
    "music.instrumentChoir": "(15) Ахор",
    "music.instrumentClarinet": "(10) Акларнет",
    "music.instrumentElectricGuitar": "(5) Аелектрогитара",
    "music.instrumentElectricPiano": "(2) Аелектро пианино",
    "music.instrumentFlute": "(12) Афлеита",
    "music.instrumentGuitar": "(4) Агитара",
    "music.instrumentMarimba": "(19) Маримба",
    "music.instrumentMusicBox": "(17) Амузыкатә шкатулка",
    "music.instrumentOrgan": "(3) Ауарӷан",
    "music.instrumentPiano": "(1) Афортепиано",
    "music.instrumentPizzicato": "(7) Апаццикато",
    "music.instrumentSaxophone": "(11) Асаксафон",
    "music.instrumentSteelDrum": "(18) Аџыртә даул",
    "music.instrumentSynthLead": "(20) Ихадоу асинтезатор",
    "music.instrumentSynthPad": "(21) Асинтезатор Pad",
    "music.instrumentTrombone": "(9) Атрамбон",
    "music.instrumentVibraphone": "(16) Авибрафон",
    "music.instrumentWoodenFlute": "(13) Амҿтәы флеита",
    "music.midiPlayDrumForBeats": "ихәмартәуп адаулқәа [DRUM] рҟны [BEATS] битк",
    "music.midiSetInstrument": "иԥсахтәуп амаруга [INSTRUMENT] ала",
    "music.playDrumForBeats": "шәыхәмарла адаулқәа [DRUM] рҟны, иалкааны [BEATS] ахәҭақәа",
    "music.playNoteForBeats": "анота [NOTE] ихәмартәуп [BEATS] тактк",
    "music.restForBeats": "ааҭгылара аамҭала [BEATS] хәҭак",
    "music.setInstrument": "иԥсахтәуп амаруга [INSTRUMENT] ала",
    "music.setTempo": "иԥсахтәуп атемп [TEMPO] ала",
    "videoSensing.categoryName": "Авидео ахархәара",
    "videoSensing.direction": "ахырхарҭа",
    "videoSensing.motion": "аиҭаҵра",
    "videoSensing.off": "иаҿыхтәуп",
    "videoSensing.on": "иаҿактәуп",
    "videoSensing.onFlipped": "горизонталла анырԥшраан",
    "videoSensing.setVideoTransparency": "Иқәыргылатәуп авидео аҵәцара [TRANSPARENCY]",
    "videoSensing.sprite": "анагӡаҩ",
    "videoSensing.stage": "асцена",
    "videoSensing.videoOn": "авидео [ATTRIBUTE] [SUBJECT] аҟны",
    "videoSensing.videoToggle": "авидео [VIDEO_STATE] аргьежьра",
    "videoSensing.whenMotionGreaterThan": "авидео ахь аиҭаҵраан > [REFERENCE]",
    "translate.translateBlock": "еиҭагатәуп [WORDS] [LANGUAGE] ахь",
    "translate.defaultTextToTranslate": "салам",
    "translate.viewerLanguage": "абызшәа",
    "text2speech.speakAndWaitBlock": "иҳәатәуп [WORDS]",
    "text2speech.setVoiceBlock": "иқәыргылатәуп абжьы [VOICE]",
    "text2speech.setLanguageBlock": "иқәыргылатәуп абызшәа [LANGUAGE]",
    "text2speech.alto": "аӡӷаб",
    "text2speech.tenor": "аҷкәын",
    "text2speech.squeak": "аҷыжьбжьы",
    "text2speech.giant": "агигант",
    "text2speech.kitten": "ацгәы",
    "text2speech.defaultTextToSpeak": "салам",
    "speech.whenIHear": "Сара [PHRASE] ансаҳауа",
    "speech.listenAndWait": "Иӡырҩтәуп нас иԥштәуп",
    "speech.defaultWhenIHearValue": "ԥхьаҟа",
    "paint.paintEditor.hue": "Аԥштәы",
    "paint.paintEditor.saturation": "Аԥштәылгазаара",
    "paint.paintEditor.brightness": "Ажжара",
    "gui.comingSoon.message1": "Шәгәы шәырҭынч, ари ҳара аус адулара ҳаҿуп {emoji}",
    "gui.comingSoon.message2": "Иаарласны...",
    "gui.comingSoon.message3": "Ари, ҳара аус адулара ҳаҿуп {emoji}",
    "paint.paintEditor.fill": "Аҭарҭәара",
    "paint.paintEditor.costume": "Акостиум",
    "paint.paintEditor.group": "Иргәыԥтәуп",
    "paint.paintEditor.ungroup": "Аргәыԥра ықәгатәуп",
    "paint.paintEditor.undo": "Иаҟәыхтәуп",
    "paint.paintEditor.redo": "Еиҭанагӡатәуп",
    "paint.paintEditor.forward": "Ԥхьаҟа",
    "paint.paintEditor.backward": "Шьҭахьҟа",
    "paint.paintEditor.front": "Аԥхьатәи аҿаԥшырахь",
    "paint.paintEditor.back": "Шьҭахьҟа",
    "paint.paintEditor.more": "Иҵегь",
    "paint.modeTools.brushSize": "Ашәагаа",
    "paint.modeTools.eraserSize": "Аныхга аҭбаара",
    "paint.modeTools.copy": "Акопиа ахыхтәуп",
    "paint.modeTools.paste": "Иҭаргылатәуп",
    "paint.modeTools.delete": "Ианыхтәуп",
    "paint.modeTools.curved": "Архәақәа рахь",
    "paint.modeTools.pointed": "Иҵарқәоу",
    "paint.modeTools.thickness": "Ашәпара",
    "paint.modeTools.flipHorizontal": "Горизонталла ианырԥшртәуп",
    "paint.modeTools.flipVertical": "Вертикалла ианырԥштәуп",
    "paint.modeTools.filled": "Иҭарҭәоу",
    "paint.modeTools.outlined": "Иҿыкәыршоу",
    "paint.paintEditor.bitmap": "Аконвертациа арастртә графика ахь",
    "paint.paintEditor.vector": "Аконвертациа авектортә графика ахь ",
    "paint.paintEditor.stroke": "Аконтур",
    "paint.brushMode.brush": "Ашәыга",
    "paint.eraserMode.eraser": "Аныхга",
    "paint.fillMode.fill": "Аҭарҭәара",
    "paint.lineMode.line": "Аҵәаӷәа",
    "paint.ovalMode.oval": "Агьежь",
    "paint.rectMode.rect": "Аԥшькәакьиаша",
    "paint.reshapeMode.reshape": "Аформа аԥсахра",
    "paint.roundedRectMode.roundedRect": "Ихаргьежьу аԥшькәакьиаша",
    "paint.selectMode.select": "Иалхтәуп",
    "paint.textMode.text": "Атеқст",
    "paint.colorPicker.swap": "Аиҭныԥсахлара",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "ar": {
    "gui.backpack.header": "الحقيبة",
    "gui.backpack.errorBackpack": "خطأ في تحميل الحقيبة",
    "gui.backpack.loadingBackpack": "جارٍ التحميل...",
    "gui.backpack.more": "المزيد",
    "gui.backpack.emptyBackpack": "الحقيبة فارغة",
    "gui.gui.costumesTab": "المظاهر",
    "gui.gui.soundsTab": "الأصوات",
    "gui.gui.backdropsTab": "الخلفيات",
    "gui.gui.addExtension": "إدراج إضافة",
    "gui.costumeTab.addCostumeFromLibrary": "اختيار مظهر",
    "gui.costumeLibrary.chooseACostume": "اختيار مظهر",
    "gui.costumeTab.addBackdropFromLibrary": "اختيار خلفية",
    "gui.costumeTab.addBlankCostume": "رسم",
    "gui.costumeTab.addSurpriseCostume": "مفاجأة",
    "gui.costumeTab.addFileBackdrop": "تحميل الخلفية",
    "gui.costumeTab.addFileCostume": "تحميل مظهر",
    "gui.costumeTab.addCameraCostume": "الكاميرا",
    "gui.soundEditor.trim": "قص",
    "gui.soundEditor.stop": "إيقاف",
    "gui.soundEditor.sound": "الصوت",
    "gui.soundEditor.play": "تشغيل",
    "gui.soundEditor.save": "حفظ",
    "gui.soundEditor.undo": "تراجع",
    "gui.soundEditor.redo": "إعادة",
    "gui.soundEditor.faster": "تسريع",
    "gui.soundEditor.slower": "إبطاء",
    "gui.soundEditor.echo": "صدى",
    "gui.soundEditor.robot": "روبوت ",
    "gui.soundEditor.louder": "أعلى",
    "gui.soundEditor.softer": "أخفض",
    "gui.soundEditor.reverse": "عكس ",
    "gui.soundTab.recordSound": "تسجيل",
    "gui.soundTab.addSoundFromLibrary": "اختيار صوت",
    "gui.soundTab.surpriseSound": "مفاجأة ",
    "gui.soundTab.fileUploadSound": "تحميل صوت",
    "gui.controls.stop": "توقف",
    "gui.controls.go": "انطلق",
    "gui.stageHeader.stageSizeUnFull": "الخروج من وضعية ملء الشاشة",
    "gui.SpriteInfo.show": "إظهار",
    "gui.SpriteInfo.size": "الحجم",
    "gui.directionPicker.rotationStyles.allAround": "جميع الاتجاهات",
    "gui.directionPicker.rotationStyles.leftRight": "يمين/ يسار",
    "gui.directionPicker.rotationStyles.dontRotate": "لا استدارة",
    "gui.spriteSelectorItem.contextMenuDuplicate": "مضاعفة",
    "gui.spriteSelectorItem.contextMenuDelete": "حذف",
    "gui.spriteSelectorItem.contextMenuExport": "تصدير",
    "gui.spriteSelector.addSpriteFromPaint": "رسم",
    "gui.spriteSelector.addSpriteFromSurprise": "مفاجأة ",
    "gui.spriteSelector.addSpriteFromFile": "تحميل كائن",
    "gui.stageSelector.stage": "المنصة",
    "gui.stageSelector.backdrops": "الخلفيات",
    "gui.stageSelector.addBackdropFromPaint": "رسام",
    "gui.stageSelector.addBackdropFromSurprise": "مفاجأة ",
    "gui.stageSelector.addBackdropFromFile": "تحميل خلفية",
    "gui.modal.back": "عودة إلى السابق",
    "gui.library.filterPlaceholder": "بحث",
    "gui.libraryTags.all": "الكل",
    "gui.libraryTags.animals": "حيوانات",
    "gui.libraryTags.dance": "رقص",
    "gui.libraryTags.effects": "المؤثرات",
    "gui.libraryTags.fantasy": "عالم الخيال",
    "gui.libraryTags.fashion": "أزياء ",
    "gui.libraryTags.food": "طعام",
    "gui.libraryTags.indoors": "داخلي",
    "gui.libraryTags.loops": "حلقات",
    "gui.libraryTags.music": "موسيقى",
    "gui.libraryTags.notes": "نغمات",
    "gui.libraryTags.outdoors": "خارجي",
    "gui.libraryTags.patterns": "أنماط",
    "gui.libraryTags.people": "بشر",
    "gui.libraryTags.percussion": "إيقاع",
    "gui.libraryTags.space": "فضاء",
    "gui.libraryTags.sports": "رياضة",
    "gui.libraryTags.underwater": "تحت الماء",
    "gui.libraryTags.voice": "صوت",
    "gui.libraryTags.wacky": "لعوب",
    "gui.libraryTags.animation": "رسوم متحركة",
    "gui.libraryTags.art": "فنون",
    "gui.libraryTags.games": "ألعاب",
    "gui.libraryTags.stories": "قصص",
    "gui.libraryTags.letters": "حروف",
    "gui.soundLibrary.chooseASound": "اختيار صوت",
    "gui.SpriteInfo.spritePlaceholder": "اسم",
    "gui.cards.more-things-to-try": "المزيد مما يمكن تجريبه!",
    "gui.cards.see-more": "مشاهدة المزيد",
    "gui.cards.shrink": "إخفاء",
    "gui.cards.expand": "عرض",
    "gui.cards.close": "إغلاق",
    "gui.loader.message1": "جار إنشاء اللبنات ...",
    "gui.loader.message2": "جارٍ تحميل الكائنات ...",
    "gui.loader.message3": "جارٍ تحميل الأصوات ...",
    "gui.loader.message4": "جار تحميل الإضافات ...",
    "gui.loader.message5": "جارٍ تحضير الكثير والكثير من الهررة ...",
    "gui.loader.message6": "جار إعداد العنقاء للطيران ...",
    "gui.loader.message7": "جارٍ نفخ الفيلة ...",
    "gui.loader.message8": "جارٍ تحضير الرموز التعبيرية ...",
    "gui.loader.headline": "جارٍ تحميل المشروع",
    "gui.cameraModal.cameraModalTitle": "التقط صورة",
    "gui.cameraModal.loadingCameraMessage": "جارٍ تحميل الكاميرا...",
    "gui.cameraModal.permissionRequest": "نحتاج إذنك لاستخدام الكاميرا",
    "gui.cameraModal.retakePhoto": "التقط الصورة ثانيةً",
    "gui.cameraModal.save": "حفظ",
    "gui.cameraModal.takePhoto": "التقاط صورة",
    "gui.cameraModal.loadingCaption": "جارٍ التحميل...",
    "gui.cameraModal.enableCameraCaption": "تفعيل الكاميرا",
    "gui.recordModal.title": "تسجيل صوت",
    "gui.playbackStep.stopMsg": "توقف",
    "gui.playbackStep.playMsg": "تشغيل",
    "gui.playbackStep.loadingMsg": "جارٍ التحميل...",
    "gui.playbackStep.saveMsg": "حفظ",
    "gui.playbackStep.reRecordMsg": "إعادة تسجيل",
    "gui.webglModal.label": "متصفحك لا يدعم WebGL",
    "gui.webglModal.webgllink": "لا يدعم WebGL",
    "gui.prompt.cancel": "إلغاء الأمر",
    "gui.prompt.ok": "موافق",
    "gui.customProcedures.addAnInputNumberText": "إضافة حقل",
    "gui.customProcedures.addAnInputBoolean": "إضافة حقل",
    "gui.customProcedures.numberTextType": "رقم أو نص",
    "gui.customProcedures.booleanType": "منطقي",
    "gui.customProcedures.addALabel": "إضافة نص",
    "gui.customProcedures.runWithoutScreenRefresh": "التشغيل دون تحديث الشاشة",
    "gui.customProcedures.cancel": "إلغاء الأمر",
    "gui.customProcedures.ok": "موافق",
    "gui.extension.microbit.description": "لربط مشاريعك  بالعالم.",
    "gui.extension.music.name": "الموسيقى",
    "gui.extension.music.description": "لعزف الأدوات الموسيقية والطبول.",
    "gui.extension.pen.name": "القلم",
    "gui.extension.pen.description": "للرسم بواسطة كائناتك.",
    "gui.gui.variableScopeOptionAllSprites": "لجميع الكائنات",
    "gui.gui.variableScopeOptionSpriteOnly": "لهذا الكائن فقط",
    "gui.gui.variablePromptAllSpritesMessage": "سيكون هذا المتغير متاحًا لجميع الكائنات.",
    "gui.monitor.contextMenu.default": "عرض عادي",
    "gui.monitor.contextMenu.large": "عرض كبير",
    "gui.monitor.contextMenu.slider": "المنزلقة",
    "gui.monitor.contextMenu.import": "استيراد",
    "gui.monitor.contextMenu.export": "تصدير",
    "gui.opcodeLabels.direction": "الاتجاه",
    "gui.opcodeLabels.xposition": "الموضع س",
    "gui.opcodeLabels.yposition": "الموضع ص",
    "gui.opcodeLabels.size": "الحجم",
    "gui.opcodeLabels.costumename": "اسم المظهر",
    "gui.opcodeLabels.costumenumber": "رقم المظهر",
    "gui.opcodeLabels.backdropname": "اسم الخلفية",
    "gui.opcodeLabels.backdropnumber": "رقم الخلفية",
    "gui.opcodeLabels.volume": "شدة الصوت",
    "gui.opcodeLabels.tempo": "سرعة الإيقاع",
    "gui.opcodeLabels.answer": "الإجابة",
    "gui.opcodeLabels.loudness": "الضجيج",
    "gui.opcodeLabels.year": "العام",
    "gui.opcodeLabels.month": "الشهر",
    "gui.opcodeLabels.date": "التاريخ",
    "gui.opcodeLabels.dayofweek": "يوم الأسبوع",
    "gui.opcodeLabels.hour": "الساعة",
    "gui.opcodeLabels.minute": "الدقيقة",
    "gui.opcodeLabels.second": "الثانية",
    "gui.opcodeLabels.timer": "المؤقت",
    "music.categoryName": "الموسيقى",
    "translate.categoryName": "ترجم",
    "pen.categoryName": "القلم",
    "pen.changeColorParam": "تغيير القلم [COLOR_PARAM] بمقدار[VALUE]",
    "pen.changeHue": "غيّر لون القلم بمقدار [HUE]",
    "pen.changeShade": "غيِّر تظليل القلم بمقدار [SHADE]",
    "pen.changeSize": "غيّر حجم القلم بمقدار [SIZE]",
    "pen.clear": "مسح الكل",
    "pen.colorMenu.brightness": "شدة الإضاءة",
    "pen.colorMenu.color": "اللون",
    "pen.colorMenu.saturation": "درجة اللون",
    "pen.colorMenu.transparency": "شفافية",
    "pen.penDown": "أنزل القلم",
    "pen.penUp": "ارفع القلم",
    "pen.setColor": "إجعل لون القلم مساويًا[COLOR]",
    "pen.setColorParam": "إجعل القلم [COLOR_PARAM] على [VALUE]",
    "pen.setHue": "إجعل لون القلم مساوياً لـ [HUE]",
    "pen.setShade": "إجعل ظل القلم على [SHADE]",
    "pen.setSize": "اجعل حجم القلم مساويًا  [SIZE]",
    "pen.stamp": "اطبع",
    "music.changeTempo": "غيير سرعة الاداء بمقدار  [TEMPO]",
    "music.drumBass": "(2) Bass Drum",
    "music.drumBongo": "(13) بونغو",
    "music.drumCabasa": "(15) كاباسا",
    "music.drumClaves": "(9) كلاف",
    "music.drumClosedHiHat": "(6) Closed Hi-Hat",
    "music.drumConga": "(14) كونغا",
    "music.drumCowbell": "(11) جرس",
    "music.drumCrashCymbal": "(4) صنوج",
    "music.drumCuica": "(18) كويسا",
    "music.drumGuiro": "(16) جويرو",
    "music.drumHandClap": "(8) تصفيق",
    "music.drumOpenHiHat": "(5) Open Hi-Hat",
    "music.drumSideStick": "(3) عصا جانبية",
    "music.drumSnare": "(1) Snare Drum",
    "music.drumTambourine": "(7) الدف",
    "music.drumTriangle": "(12) مثلث",
    "music.drumVibraslap": "(17) فيبراسلاب",
    "music.drumWoodBlock": "(10) كتلة الخشب",
    "music.getTempo": "سرعة الأداء",
    "music.instrumentBass": "(6) باس",
    "music.instrumentBassoon": "(14) الباسون",
    "music.instrumentCello": "(8) التشيلو",
    "music.instrumentChoir": "(15) جوقة",
    "music.instrumentClarinet": "(10) كلارينيت",
    "music.instrumentElectricGuitar": "(5) غيتار كهربائي",
    "music.instrumentElectricPiano": "(2) بيانو كهربائي",
    "music.instrumentFlute": "(12) ناي",
    "music.instrumentGuitar": "(4) غيتار",
    "music.instrumentMarimba": "(19) ماريمبا",
    "music.instrumentMusicBox": "(17) صندوق الموسيقى",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) بيانو",
    "music.instrumentPizzicato": "(7)كمان",
    "music.instrumentSaxophone": "(11) ساكسفون",
    "music.instrumentSteelDrum": "(18) Steel Drum",
    "music.instrumentSynthLead": "(20) الرصاص المركب",
    "music.instrumentSynthPad": "(21) سينث باد",
    "music.instrumentTrombone": "(9)المترددة آلة موسيقية",
    "music.instrumentVibraphone": "(16)الفيبرافون آلة موسيقية",
    "music.instrumentWoodenFlute": "(13)ناي خشبية",
    "music.midiPlayDrumForBeats": "دقّ الطبل [DRUM] لمدة  [BEATS] وحدة ايقاع",
    "music.midiSetInstrument": "اجعل الالة  على [INSTRUMENT]",
    "music.playDrumForBeats": "دقّ الطبل [DRUM] لمدة  [BEATS] وحدة ايقاع",
    "music.playNoteForBeats": "اعزف النوتة  [NOTE] لمدة [BEATS] وحدة ايقاع",
    "music.restForBeats": "إسترح لمدة [BEATS] وحدة ايقاع",
    "music.setInstrument": "اجعل الالة  على [INSTRUMENT]",
    "music.setTempo": "اجعل سرعة الأداء مساوياً لـ [TEMPO]وحدة إيقاع في الدقيقة",
    "videoSensing.categoryName": "استشعار الفيديو",
    "videoSensing.direction": "الاتجاه",
    "videoSensing.motion": "حركة",
    "videoSensing.off": "يتوقف",
    "videoSensing.on": "يعمل",
    "videoSensing.onFlipped": "عند النقر",
    "videoSensing.setVideoTransparency": "اجعل شفافية الفيديو على [TRANSPARENCY]",
    "videoSensing.sprite": "كائن",
    "videoSensing.stage": "منصة ",
    "videoSensing.videoOn": "فيديو [ATTRIBUTE] على [SUBJECT]",
    "videoSensing.videoToggle": "تحويل الفيديو [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "عندما تكون حركة الفيديو> [REFERENCE]",
    "translate.translateBlock": "ترجم [WORDS] إلى [LANGUAGE]",
    "translate.defaultTextToTranslate": "مرحبا ",
    "translate.viewerLanguage": "لغة",
    "text2speech.speakAndWaitBlock": "تحدث [WORDS]",
    "text2speech.setVoiceBlock": "اجعل الصوت مساويا [VOICE]",
    "text2speech.setLanguageBlock": "اجعل  اللغة على [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "صرير",
    "text2speech.giant": "عملاق ",
    "text2speech.kitten": "هريرة ",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "عندما أسمع [PHRASE]",
    "speech.listenAndWait": "استمع وانتظر",
    "speech.defaultWhenIHearValue": "هيا بنا",
    "paint.paintEditor.hue": "لون",
    "paint.paintEditor.saturation": "درجة اللون ",
    "paint.paintEditor.brightness": "سطوع",
    "gui.comingSoon.message1": "لا تقلق ، نحن نعمل على ذلك  {emoji}",
    "gui.comingSoon.message2": "قريبا...",
    "gui.comingSoon.message3": "نحن نعمل على ذلك {emoji}",
    "paint.paintEditor.fill": "ملء",
    "paint.paintEditor.costume": "المظهر",
    "paint.paintEditor.group": "مجموعة",
    "paint.paintEditor.ungroup": "فك تجميع",
    "paint.paintEditor.undo": "تراجع",
    "paint.paintEditor.redo": "إعادة",
    "paint.paintEditor.forward": "إلى الأمام",
    "paint.paintEditor.backward": "الى الوراء",
    "paint.paintEditor.front": "أمام",
    "paint.paintEditor.back": "ارجع الى الخلف",
    "paint.paintEditor.more": "المزيد",
    "paint.modeTools.brushSize": "الحجم",
    "paint.modeTools.eraserSize": "حجم الممحاه",
    "paint.modeTools.copy": "نسخ",
    "paint.modeTools.paste": "لصق",
    "paint.modeTools.delete": "احذف",
    "paint.modeTools.curved": "منحن",
    "paint.modeTools.pointed": "حاد",
    "paint.modeTools.thickness": "سماكة",
    "paint.modeTools.flipHorizontal": "اقلب أفقياً",
    "paint.modeTools.flipVertical": "أقلب عامودياً",
    "paint.modeTools.filled": "معبأ",
    "paint.modeTools.outlined": "أوجز",
    "paint.paintEditor.bitmap": "تحويل إلى صورة نقطية",
    "paint.paintEditor.vector": "تحويل إلى متجه",
    "paint.paintEditor.stroke": "الخطوط العريضة",
    "paint.brushMode.brush": "فرشاة",
    "paint.eraserMode.eraser": "ممحاة",
    "paint.fillMode.fill": "ملء",
    "paint.lineMode.line": "خط ",
    "paint.ovalMode.oval": "دائرة",
    "paint.rectMode.rect": "مستطيل",
    "paint.reshapeMode.reshape": "إعادة تشكيل",
    "paint.roundedRectMode.roundedRect": "مستطيل مستدير الزوايا",
    "paint.selectMode.select": "اختر",
    "paint.textMode.text": "نص",
    "paint.colorPicker.swap": "تبادل",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "am": {
    "gui.backpack.header": "ቦርሳ",
    "gui.backpack.errorBackpack": "ስሕተት በየቦርሳውን ጭነት",
    "gui.backpack.loadingBackpack": "በመጫን ላይ...",
    "gui.backpack.more": "More",
    "gui.backpack.emptyBackpack": "ቦርሳው ባዶ ነው",
    "gui.gui.costumesTab": "አልባሳት",
    "gui.gui.soundsTab": "ድምጾች",
    "gui.gui.backdropsTab": "የጀርባ ምስሎች",
    "gui.gui.addExtension": "ቅጥያ ጨምር",
    "gui.costumeTab.addCostumeFromLibrary": "ልብስ ይምረጡ",
    "gui.costumeLibrary.chooseACostume": "ልብስ ይምረጡ",
    "gui.costumeTab.addBackdropFromLibrary": "የጀርባ ምስል ይምረጡ",
    "gui.costumeTab.addBlankCostume": "ቀለም ቅባ",
    "gui.costumeTab.addSurpriseCostume": "አድናቆት",
    "gui.costumeTab.addFileBackdrop": "የጀርባ ምስሉን ስቀል",
    "gui.costumeTab.addFileCostume": "ልብሱን ስቀል",
    "gui.costumeTab.addCameraCostume": "ካሜራ",
    "gui.soundEditor.trim": "ቁረጥ",
    "gui.soundEditor.stop": "አቁም",
    "gui.soundEditor.sound": "ድምጽ",
    "gui.soundEditor.play": "ይጫወታሉ",
    "gui.soundEditor.save": "አስቀምጥ",
    "gui.soundEditor.undo": "መልስ",
    "gui.soundEditor.redo": "ድጋሜ አድርግ",
    "gui.soundEditor.faster": "በፍጥነት",
    "gui.soundEditor.slower": "በዝግታ",
    "gui.soundEditor.echo": "ማስተጋባት",
    "gui.soundEditor.robot": "ሮቦት",
    "gui.soundEditor.louder": "ከፍ በል",
    "gui.soundEditor.softer": "ቀስ በል",
    "gui.soundEditor.reverse": "ቀልብስ",
    "gui.soundTab.recordSound": "ቅዳ",
    "gui.soundTab.addSoundFromLibrary": "ድምጽ ይምረጡ",
    "gui.soundTab.surpriseSound": "አድናቆት",
    "gui.soundTab.fileUploadSound": "ድምጹን ስቀል",
    "gui.controls.stop": "አቁም",
    "gui.controls.go": "ሂድ",
    "gui.stageHeader.stageSizeUnFull": "ከሙሉ ማሳያ ሞድ ውስጥ ውጣ",
    "gui.SpriteInfo.show": "አሳይ",
    "gui.SpriteInfo.size": "ልክ",
    "gui.directionPicker.rotationStyles.allAround": "ዙሪያውን",
    "gui.directionPicker.rotationStyles.leftRight": "ግራ/ቀኝ",
    "gui.directionPicker.rotationStyles.dontRotate": "አትዞር",
    "gui.spriteSelectorItem.contextMenuDuplicate": "አባዛ",
    "gui.spriteSelectorItem.contextMenuDelete": "ደምስስ",
    "gui.spriteSelectorItem.contextMenuExport": "ማስወጣት",
    "gui.spriteSelector.addSpriteFromPaint": "ቀለም ቅባ",
    "gui.spriteSelector.addSpriteFromSurprise": "አድናቆት",
    "gui.spriteSelector.addSpriteFromFile": "ስፕራይቱን ስቀል",
    "gui.stageSelector.stage": "መድረክ",
    "gui.stageSelector.backdrops": "የጀርባ ምስሎች",
    "gui.stageSelector.addBackdropFromPaint": "ቀለም ቅባ",
    "gui.stageSelector.addBackdropFromSurprise": "አድናቆት",
    "gui.stageSelector.addBackdropFromFile": "የጀርባ ምስሉን ስቀል",
    "gui.modal.back": "ኋላ",
    "gui.library.filterPlaceholder": "ፈልጉ",
    "gui.libraryTags.all": "All",
    "gui.libraryTags.animals": "Animals",
    "gui.libraryTags.dance": "Dance",
    "gui.libraryTags.effects": "Effects",
    "gui.libraryTags.fantasy": "Fantasy",
    "gui.libraryTags.fashion": "Fashion",
    "gui.libraryTags.food": "Food",
    "gui.libraryTags.indoors": "Indoors",
    "gui.libraryTags.loops": "Loops",
    "gui.libraryTags.music": "Music",
    "gui.libraryTags.notes": "Notes",
    "gui.libraryTags.outdoors": "Outdoors",
    "gui.libraryTags.patterns": "Patterns",
    "gui.libraryTags.people": "People",
    "gui.libraryTags.percussion": "Percussion",
    "gui.libraryTags.space": "Space",
    "gui.libraryTags.sports": "Sports",
    "gui.libraryTags.underwater": "Underwater",
    "gui.libraryTags.voice": "Voice",
    "gui.libraryTags.wacky": "Wacky",
    "gui.libraryTags.animation": "Animation",
    "gui.libraryTags.art": "Art",
    "gui.libraryTags.games": "Games",
    "gui.libraryTags.stories": "Stories",
    "gui.libraryTags.letters": "Letters",
    "gui.soundLibrary.chooseASound": "ድምጽ ይምረጡ",
    "gui.SpriteInfo.spritePlaceholder": "ስም",
    "gui.cards.more-things-to-try": "ተጨማሪ ነገሮች ለመሞከር!",
    "gui.cards.see-more": "ተጨማሪ እዩ ",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Close",
    "gui.loader.message1": "ጥምሮች በመፍጠር ላይ...",
    "gui.loader.message2": "ስፕራይት በመጫን ላይ...",
    "gui.loader.message3": "ድምጽ በመጫን ላይ...",
    "gui.loader.message4": "ቅጥያ በመጫን ላይ...",
    "gui.loader.message5": "ድመት በማጎራት ላይ...",
    "gui.loader.message6": "ናኖዎች በማሰራጨት ላይ...",
    "gui.loader.message7": "ጎቦዎች በመንፋት ላይ...",
    "gui.loader.message8": "ኤሞጂዎች በማዘጋቸት ላይ...",
    "gui.loader.headline": "ፕሮጀክት በመጫን ላይ",
    "gui.cameraModal.cameraModalTitle": "ፎቶ ቅረጽ",
    "gui.cameraModal.loadingCameraMessage": "ካሜራውን በመጫን ላይ...",
    "gui.cameraModal.permissionRequest": "ይርስዎን ፍቃድ ያስፈልገናል ካመራዎን ለመጠቀም ",
    "gui.cameraModal.retakePhoto": "ደግሞ ፎቶ ቅረጽ ",
    "gui.cameraModal.save": "አስቀምጥ",
    "gui.cameraModal.takePhoto": "ፎቶ ቅረጽ",
    "gui.cameraModal.loadingCaption": "በመጫን ላይ...",
    "gui.cameraModal.enableCameraCaption": "ካሜራ አብራ ",
    "gui.recordModal.title": "ድምጹ ቅዳ",
    "gui.playbackStep.stopMsg": "አቁም",
    "gui.playbackStep.playMsg": "ይጫወታሉ",
    "gui.playbackStep.loadingMsg": "በመጫን ላይ...",
    "gui.playbackStep.saveMsg": "አስቀምጥ",
    "gui.playbackStep.reRecordMsg": "በድጋሜ ቅዳ",
    "gui.webglModal.label": "የእርስዎ አሳሽ WebGL አይደግፍም",
    "gui.webglModal.webgllink": "WebGLን አይደግፍም",
    "gui.prompt.cancel": "ይቁም",
    "gui.prompt.ok": "እሺ",
    "gui.customProcedures.addAnInputNumberText": "ግቤት ጨምር",
    "gui.customProcedures.addAnInputBoolean": "ግቤት ጨምር",
    "gui.customProcedures.numberTextType": "ቁጥር ዌይስ ጽሁፍ",
    "gui.customProcedures.booleanType": "ቡልያዊ",
    "gui.customProcedures.addALabel": "መሰየሚያ ለጥፍ",
    "gui.customProcedures.runWithoutScreenRefresh": "ፕሮግራሙን ያለ ስክሪኑ ማደስ ክፈት",
    "gui.customProcedures.cancel": "ይቁም",
    "gui.customProcedures.ok": "እሺ",
    "gui.extension.microbit.description": "የእርስዎ ፕሮጀክቶች ያገናኙ ከዓለሙ ጋር",
    "gui.extension.music.name": "ሙዚቃ",
    "gui.extension.music.description": "መሳሪያዎችና ታምቡሮች ተጫወት",
    "gui.extension.pen.name": "ብዕር",
    "gui.extension.pen.description": "ከየእርስዎ ስፕራይቶች ይሳሉ",
    "gui.gui.variableScopeOptionAllSprites": "ለሁሉም ስፕራይቶች",
    "gui.gui.variableScopeOptionSpriteOnly": "ለዚህ ስፕራይት ብቻ",
    "gui.gui.variablePromptAllSpritesMessage": "ይህ ተለዋዋጭ ለሁሉም ስፕራይቶች ጠቀቃሚ ይሆናል።",
    "gui.monitor.contextMenu.default": "መደበኛ የሚነበብ ነገር",
    "gui.monitor.contextMenu.large": "ትልቅ የሚነበብ ነገር",
    "gui.monitor.contextMenu.slider": "ማንሸራተቻ",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "direction",
    "gui.opcodeLabels.xposition": "x position",
    "gui.opcodeLabels.yposition": "y position",
    "gui.opcodeLabels.size": "size",
    "gui.opcodeLabels.costumename": "costume name",
    "gui.opcodeLabels.costumenumber": "costume number",
    "gui.opcodeLabels.backdropname": "backdrop name",
    "gui.opcodeLabels.backdropnumber": "backdrop number",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "answer",
    "gui.opcodeLabels.loudness": "loudness",
    "gui.opcodeLabels.year": "year",
    "gui.opcodeLabels.month": "month",
    "gui.opcodeLabels.date": "date",
    "gui.opcodeLabels.dayofweek": "day of week",
    "gui.opcodeLabels.hour": "hour",
    "gui.opcodeLabels.minute": "minute",
    "gui.opcodeLabels.second": "second",
    "gui.opcodeLabels.timer": "timer",
    "music.categoryName": "ሙዚቃ",
    "translate.categoryName": "Translate",
    "pen.categoryName": "ብዕር",
    "pen.changeColorParam": "የእስክሪብቶን [COLOR_PARAM] ቀይር በ[VALUE]",
    "pen.changeHue": "የእስክሪብቶን ቀልም ቀይር በ[HUE]",
    "pen.changeShade": "የእስክሪብቶን ጥቁረት ቀይር በ[SHADE]",
    "pen.changeSize": "የእስክሪብቶን ልክ ቀይር በ[SIZE]",
    "pen.clear": "ሁሉንም አስወግድ",
    "pen.colorMenu.brightness": "የብርሀን መጠን",
    "pen.colorMenu.color": "ቀለም",
    "pen.colorMenu.saturation": "ርኬት",
    "pen.colorMenu.transparency": "አሳይነት",
    "pen.penDown": "ብእር አሳርፍ",
    "pen.penUp": "ብእር አንሳ",
    "pen.setColor": "የእስክሪብቶን ቀልም ወደ [COLOR] ለውጥ",
    "pen.setColorParam": "የእስክሪብቶን [COLOR_PARAM] ወደ [VALUE] ለውጥ",
    "pen.setHue": "የእስክሪብቶን ቀለም ወደ ለውጥ",
    "pen.setShade": "የእስክሪብቶን ጥቁረት ወደ [SHADE] ለውጥ",
    "pen.setSize": "የእስክሪብቶን ልክ ወደ [SIZE] ለውጥ",
    "pen.stamp": "ምልክት",
    "music.changeTempo": "ሰልት ቀይር በ[TEMPO]",
    "music.drumBass": "(2) ትልቅ ታምቡር",
    "music.drumBongo": "(13) ቦንጎ",
    "music.drumCabasa": "(13) ካባሳ",
    "music.drumClaves": "(9) ክላቭ",
    "music.drumClosedHiHat": "(6) የተዘጋ ድምፀ ብረት",
    "music.drumConga": "(14) ኮንጋ",
    "music.drumCowbell": "(11) የላም ቃጭል",
    "music.drumCrashCymbal": "(4) ጸናጽል",
    "music.drumCuica": "(18) ኲካ",
    "music.drumGuiro": "(16) ጒሮ",
    "music.drumHandClap": "(8) ጭብጨባ",
    "music.drumOpenHiHat": "(5) የተከፈተ ድምፀ ብረት",
    "music.drumSideStick": "(3) የጎን በትር",
    "music.drumSnare": "(1) ነዛሪ ታምቡር",
    "music.drumTambourine": "(7) ታምቡሪን",
    "music.drumTriangle": "(12) ትራያንግል",
    "music.drumVibraslap": "(17) ቫይብራስላፕ",
    "music.drumWoodBlock": "(10) የእንጨት ብሎኬት",
    "music.getTempo": "ምት",
    "music.instrumentBass": "(6) ባስ",
    "music.instrumentBassoon": "(14) ባዙን",
    "music.instrumentCello": "(8) ቼሎ",
    "music.instrumentChoir": "(15) ሕብረ-ዝማሬ",
    "music.instrumentClarinet": "(10) ክላርኔት",
    "music.instrumentElectricGuitar": "(5) ኤሌክትሪክ ጊታር",
    "music.instrumentElectricPiano": "(2) ኤሌክትሪክ ፒያኖ",
    "music.instrumentFlute": "(12) ፍሉት",
    "music.instrumentGuitar": "(4) ጊታር",
    "music.instrumentMarimba": "(19) ማሪምባ",
    "music.instrumentMusicBox": "(17) የሙዚቃ ሳጥን",
    "music.instrumentOrgan": "(3) ኦርጋን",
    "music.instrumentPiano": "(1) ፒያኖ",
    "music.instrumentPizzicato": "(7) ፒዚካቶ",
    "music.instrumentSaxophone": "(11) ሳክስፎን",
    "music.instrumentSteelDrum": "(18) ብረት ታምቡር",
    "music.instrumentSynthLead": "(20) የመራሄ ድምፅ መምሪያ",
    "music.instrumentSynthPad": "(21) የመራሄ ድምፅ ደረብ",
    "music.instrumentTrombone": "(9) ትሮምቦን",
    "music.instrumentVibraphone": "(16) ቫይብራፎን",
    "music.instrumentWoodenFlute": "(13) የእንጨት ፍሉት",
    "music.midiPlayDrumForBeats": "play drum [DRUM] for [BEATS] beats",
    "music.midiSetInstrument": "set instrument to [INSTRUMENT]",
    "music.playDrumForBeats": "[DRUM]ን ታምቡር ለ[BEATS] ምት ተጫውት",
    "music.playNoteForBeats": "[NOTE]ን ኖታ ለ[BEATS] ምት ተጫውት",
    "music.restForBeats": "እረፍ ለ[BEATS] ምት",
    "music.setInstrument": "መሳሪያ ለውጥ ወደ [INSTRUMENT]",
    "music.setTempo": "ስልት ለውጥ ወደ [TEMPO]",
    "videoSensing.categoryName": "የቪድዮ ህዋስ",
    "videoSensing.direction": "አቅጣጫ",
    "videoSensing.motion": "እንቅስቃሴ",
    "videoSensing.off": "የጠፋ",
    "videoSensing.on": "እየሰራ ያለ",
    "videoSensing.onFlipped": "ሲገለብጥ",
    "videoSensing.setVideoTransparency": "የቪድዮ አሳይነት ወደ [TRANSPARENCY] ለውጥ",
    "videoSensing.sprite": "ስፕራይት",
    "videoSensing.stage": "መድረክ",
    "videoSensing.videoOn": "ቪድዮ [ATTRIBUTE] ስለ [SUBJECT]",
    "videoSensing.videoToggle": "ቪድዮ ወደ [VIDEO_STATE] ቀይር",
    "videoSensing.whenMotionGreaterThan": "የቪድዮ እንቅስቃሴ > [REFERENCE] ሲሆን",
    "translate.translateBlock": "[WORDS]ን ተርጉም ወደ [LANGUAGE]",
    "translate.defaultTextToTranslate": "ሰላም",
    "translate.viewerLanguage": "ቋንቋ",
    "text2speech.speakAndWaitBlock": "[WORDS]ን ተናገር",
    "text2speech.setVoiceBlock": "ድምጽ ወደ [VOICE] ለውጥ",
    "text2speech.setLanguageBlock": "set language to [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "ሲጢት ማለት",
    "text2speech.giant": "giant",
    "text2speech.kitten": "የድመት ግልገል",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "[PHRASE]ን ሣዳምጥ",
    "speech.listenAndWait": "አዳምጥና ጠብቅ",
    "speech.defaultWhenIHearValue": "ዕንሂድ",
    "paint.paintEditor.hue": "ቀለም",
    "paint.paintEditor.saturation": "ርኬት",
    "paint.paintEditor.brightness": "ብሩህነት",
    "gui.comingSoon.message1": "አትጨነቁ፣ የሰራንበት ነው {emoji}",
    "gui.comingSoon.message2": "በቅርቡ ይደርሳል...",
    "gui.comingSoon.message3": "የሰራንበት ነው {emoji}",
    "paint.paintEditor.fill": "ምላት",
    "paint.paintEditor.costume": "ልብስ",
    "paint.paintEditor.group": "ቡድን",
    "paint.paintEditor.ungroup": "በቡድን የተመደበውን በተን",
    "paint.paintEditor.undo": "መልስ",
    "paint.paintEditor.redo": "ድጋሜ አድርግ",
    "paint.paintEditor.forward": "ወደፊት",
    "paint.paintEditor.backward": "ወደኋላ",
    "paint.paintEditor.front": "ፊት",
    "paint.paintEditor.back": "ኋላ",
    "paint.paintEditor.more": "ተጨማሪ",
    "paint.modeTools.brushSize": "ልክ",
    "paint.modeTools.eraserSize": "የላጲስ ልክ",
    "paint.modeTools.copy": "ቅዳ",
    "paint.modeTools.paste": "ለጥፍ",
    "paint.modeTools.delete": "አጥፋ",
    "paint.modeTools.curved": "የታጠፈ",
    "paint.modeTools.pointed": "ሾለ",
    "paint.modeTools.thickness": "ውፍረት",
    "paint.modeTools.flipHorizontal": "በግድምታ አሽከርክር",
    "paint.modeTools.flipVertical": "በሽቅብዮሽ አሽከርክር",
    "paint.modeTools.filled": "የተሟላ",
    "paint.modeTools.outlined": "የተቀረፀ",
    "paint.paintEditor.bitmap": "ወደ ንድፈ ቅንጣት ለውጥ",
    "paint.paintEditor.vector": "ወደ ቬክተር ለውጥ",
    "paint.paintEditor.stroke": "ቅረፅ",
    "paint.brushMode.brush": "ብሩሽ",
    "paint.eraserMode.eraser": "ላጲስ",
    "paint.fillMode.fill": "ምላት",
    "paint.lineMode.line": "መስመር",
    "paint.ovalMode.oval": "ክብ",
    "paint.rectMode.rect": "አራት ማዕዘን",
    "paint.reshapeMode.reshape": "እንደገና ቅረጽ",
    "paint.roundedRectMode.roundedRect": "የተድበለበለ አራት ማዕዘን",
    "paint.selectMode.select": "ምረጥ",
    "paint.textMode.text": "ጽሀፍ",
    "paint.colorPicker.swap": "ለዋውጥ",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "az": {
    "gui.backpack.header": "Bel çantası",
    "gui.backpack.errorBackpack": "Bel çantasının yüklənməsində yanlışlıq",
    "gui.backpack.loadingBackpack": "Yüklənir ...",
    "gui.backpack.more": "More",
    "gui.backpack.emptyBackpack": "Bel çantası boşdur",
    "gui.gui.costumesTab": "Libaslar",
    "gui.gui.soundsTab": "Səslər",
    "gui.gui.backdropsTab": "Fonlar",
    "gui.gui.addExtension": "Genişlənmə əlavə et",
    "gui.costumeTab.addCostumeFromLibrary": "Libas seç",
    "gui.costumeLibrary.chooseACostume": "Libas seç",
    "gui.costumeTab.addBackdropFromLibrary": "Fon seç",
    "gui.costumeTab.addBlankCostume": "Paint",
    "gui.costumeTab.addSurpriseCostume": "Sürpriz",
    "gui.costumeTab.addFileBackdrop": "Fon yüklə",
    "gui.costumeTab.addFileCostume": "Libas yüklə",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Kəsmək",
    "gui.soundEditor.stop": "dayandır",
    "gui.soundEditor.sound": "Səs",
    "gui.soundEditor.play": "Çal",
    "gui.soundEditor.save": "Saxla",
    "gui.soundEditor.undo": "Ləğv et",
    "gui.soundEditor.redo": "Redo",
    "gui.soundEditor.faster": "Cəld",
    "gui.soundEditor.slower": "Yavaş",
    "gui.soundEditor.echo": "Əks-səda",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Uca",
    "gui.soundEditor.softer": "Softer",
    "gui.soundEditor.reverse": "Tərsinə çevirmək",
    "gui.soundTab.recordSound": "Record",
    "gui.soundTab.addSoundFromLibrary": "Səs seç",
    "gui.soundTab.surpriseSound": "Sürpriz",
    "gui.soundTab.fileUploadSound": "Səs yüklə",
    "gui.controls.stop": "Dayandır",
    "gui.controls.go": "Başlat",
    "gui.stageHeader.stageSizeUnFull": "Tam ekran rejimindən çıx",
    "gui.SpriteInfo.show": "Göstər",
    "gui.SpriteInfo.size": "Ölçü",
    "gui.directionPicker.rotationStyles.allAround": "Ətrafında",
    "gui.directionPicker.rotationStyles.leftRight": "Sol/Sağ",
    "gui.directionPicker.rotationStyles.dontRotate": "Döndərmə",
    "gui.spriteSelectorItem.contextMenuDuplicate": "Dublikat yarat",
    "gui.spriteSelectorItem.contextMenuDelete": "sil",
    "gui.spriteSelectorItem.contextMenuExport": "ixrac et",
    "gui.spriteSelector.addSpriteFromPaint": "Çək",
    "gui.spriteSelector.addSpriteFromSurprise": "Sürpriz",
    "gui.spriteSelector.addSpriteFromFile": "Upload Sprite",
    "gui.stageSelector.stage": "Səhnə",
    "gui.stageSelector.backdrops": "Fonlar",
    "gui.stageSelector.addBackdropFromPaint": "Paint",
    "gui.stageSelector.addBackdropFromSurprise": "Sürpriz",
    "gui.stageSelector.addBackdropFromFile": "Fon yüklə",
    "gui.modal.back": "Geri",
    "gui.library.filterPlaceholder": "Axtarış",
    "gui.libraryTags.all": "All",
    "gui.libraryTags.animals": "Animals",
    "gui.libraryTags.dance": "Dance",
    "gui.libraryTags.effects": "Effects",
    "gui.libraryTags.fantasy": "Fantasy",
    "gui.libraryTags.fashion": "Fashion",
    "gui.libraryTags.food": "Food",
    "gui.libraryTags.indoors": "Indoors",
    "gui.libraryTags.loops": "Loops",
    "gui.libraryTags.music": "Music",
    "gui.libraryTags.notes": "Notes",
    "gui.libraryTags.outdoors": "Outdoors",
    "gui.libraryTags.patterns": "Patterns",
    "gui.libraryTags.people": "People",
    "gui.libraryTags.percussion": "Percussion",
    "gui.libraryTags.space": "Space",
    "gui.libraryTags.sports": "Sports",
    "gui.libraryTags.underwater": "Underwater",
    "gui.libraryTags.voice": "Voice",
    "gui.libraryTags.wacky": "Wacky",
    "gui.libraryTags.animation": "Animation",
    "gui.libraryTags.art": "Art",
    "gui.libraryTags.games": "Games",
    "gui.libraryTags.stories": "Stories",
    "gui.libraryTags.letters": "Letters",
    "gui.soundLibrary.chooseASound": "Səs seç",
    "gui.SpriteInfo.spritePlaceholder": "Ad",
    "gui.cards.more-things-to-try": "Yoxlamağa daha çox imkan!",
    "gui.cards.see-more": "Daha çox",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Close",
    "gui.loader.message1": "Bloklar yaradılır ...",
    "gui.loader.message2": "Spraytlar yüklənir ...",
    "gui.loader.message3": "Səslər yüklənir ...",
    "gui.loader.message4": "Genişlənmələr yüklənir ...",
    "gui.loader.message5": "Tənbəl pişiklər...",
    "gui.loader.message6": "Böyük həcmli layihənin ötürülməsi",
    "gui.loader.message7": "Gobolar şişir",
    "gui.loader.message8": "Emojilər hazırlanır ...",
    "gui.loader.headline": "Layihə yüklənir",
    "gui.cameraModal.cameraModalTitle": "Fotoşəkil çek",
    "gui.cameraModal.loadingCameraMessage": "Kamera yüklənir ...",
    "gui.cameraModal.permissionRequest": "Kameranızı istifadə etmək üçün bizə sizin icazəniz lazımdır",
    "gui.cameraModal.retakePhoto": "Kamerayla yenidən çək",
    "gui.cameraModal.save": "Saxla",
    "gui.cameraModal.takePhoto": "Şəkil çək",
    "gui.cameraModal.loadingCaption": "Yüklənir ...",
    "gui.cameraModal.enableCameraCaption": "Kamera mövcuddur",
    "gui.recordModal.title": "Səs yaz",
    "gui.playbackStep.stopMsg": "Dayandır",
    "gui.playbackStep.playMsg": "Oynat",
    "gui.playbackStep.loadingMsg": "Yüklənir ...",
    "gui.playbackStep.saveMsg": "Saxla",
    "gui.playbackStep.reRecordMsg": "Səsi yenidən yaz",
    "gui.webglModal.label": "Sənin Brauzerin WebGL-i dəstəkləmir",
    "gui.webglModal.webgllink": "WebGL-i dəstəkləmir",
    "gui.prompt.cancel": "Ləğv et",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Dəyişən əlavə et",
    "gui.customProcedures.addAnInputBoolean": "Dəyişən əlavə et",
    "gui.customProcedures.numberTextType": "rəqəm və ya mətn",
    "gui.customProcedures.booleanType": "məntiqi",
    "gui.customProcedures.addALabel": "Yarlıq əlavə et",
    "gui.customProcedures.runWithoutScreenRefresh": "Ekranı yeniləmədən işə sal",
    "gui.customProcedures.cancel": "Ləğv et",
    "gui.customProcedures.ok": "Oldu",
    "gui.extension.microbit.description": "Layihələrinizi dünyayla paylaşın.",
    "gui.extension.music.name": "Musiqi",
    "gui.extension.music.description": "Alətlər və barabanlar çal.",
    "gui.extension.pen.name": "Qələm",
    "gui.extension.pen.description": "Spraytlarınla çək.",
    "gui.gui.variableScopeOptionAllSprites": "Bütün spraytlar üçün",
    "gui.gui.variableScopeOptionSpriteOnly": "Yalnız bu sprayt üçün",
    "gui.gui.variablePromptAllSpritesMessage": "Bu dəyişən bütün spraytlar üçün mövcud olacaq.",
    "gui.monitor.contextMenu.default": "normal readout",
    "gui.monitor.contextMenu.large": "large readout",
    "gui.monitor.contextMenu.slider": "sürüşkən",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "direction",
    "gui.opcodeLabels.xposition": "x position",
    "gui.opcodeLabels.yposition": "y position",
    "gui.opcodeLabels.size": "size",
    "gui.opcodeLabels.costumename": "costume name",
    "gui.opcodeLabels.costumenumber": "costume number",
    "gui.opcodeLabels.backdropname": "backdrop name",
    "gui.opcodeLabels.backdropnumber": "backdrop number",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "answer",
    "gui.opcodeLabels.loudness": "loudness",
    "gui.opcodeLabels.year": "year",
    "gui.opcodeLabels.month": "month",
    "gui.opcodeLabels.date": "date",
    "gui.opcodeLabels.dayofweek": "day of week",
    "gui.opcodeLabels.hour": "hour",
    "gui.opcodeLabels.minute": "minute",
    "gui.opcodeLabels.second": "second",
    "gui.opcodeLabels.timer": "timer",
    "music.categoryName": "Musiqi",
    "translate.categoryName": "Translate",
    "pen.categoryName": "Qələm",
    "pen.changeColorParam": "qələmin [COLOR_PARAM] rəngini [VALUE] qədər dəyiş",
    "pen.changeHue": "qələmin rəngini [HUE] qədər dəyiş",
    "pen.changeShade": "qələmin çalarını [SHADE] qədər dəyiş",
    "pen.changeSize": "qələmin ölçüsünü [SIZE] qədər dəyiş",
    "pen.clear": "hamısını sil",
    "pen.colorMenu.brightness": "parlaqlıq",
    "pen.colorMenu.color": "rəng",
    "pen.colorMenu.saturation": "dolğunluq",
    "pen.colorMenu.transparency": "şəffaflıq",
    "pen.penDown": "qələmi endir",
    "pen.penUp": "qələmi qaldır",
    "pen.setColor": "qələmin rəngini [COLOR] təyin et",
    "pen.setColorParam": "qələmin [COLOR_PARAM] rəngini [VALUE] təyin et",
    "pen.setHue": "qələmin rəngini [HUE] təyin et",
    "pen.setShade": "qələmin çalarını [SHADE] təyin et",
    "pen.setSize": "qələmin ölçüsünü [SIZE] təyin et",
    "pen.stamp": "möhürlə",
    "music.changeTempo": "tempi [TEMPO] qədər dəyiş",
    "music.drumBass": "(2) Bas barabanı",
    "music.drumBongo": "(13) Bonqo",
    "music.drumCabasa": "(15) Kabasa",
    "music.drumClaves": "(9) Klavesin",
    "music.drumClosedHiHat": "(6) Bağlı Hi-Hat",
    "music.drumConga": "(14) Konqa",
    "music.drumCowbell": "(11) Zınqırov",
    "music.drumCrashCymbal": "(4) Zərb aləti",
    "music.drumCuica": "(18) Kuika",
    "music.drumGuiro": "(16) Quiro",
    "music.drumHandClap": "(8) Əl çalmaq",
    "music.drumOpenHiHat": "(5) Açıq Hi-Hat",
    "music.drumSideStick": "(3) Çubuqlu nağara",
    "music.drumSnare": "(1) Kiçik baraban",
    "music.drumTambourine": "(7) Tamburin",
    "music.drumTriangle": "(12) Üçbucaq",
    "music.drumVibraslap": "(17) Vibrasiya edən sləp",
    "music.drumWoodBlock": "(10) Taxta qutu",
    "music.getTempo": "temp",
    "music.instrumentBass": "(6) Bas",
    "music.instrumentBassoon": "(14) Faqot",
    "music.instrumentCello": "(8) Violonçel",
    "music.instrumentChoir": "(15) Xor",
    "music.instrumentClarinet": "(10) Klarnet",
    "music.instrumentElectricGuitar": "(5) Elektro gitara",
    "music.instrumentElectricPiano": "(2) Elektro pianino",
    "music.instrumentFlute": "(12) Fleyta",
    "music.instrumentGuitar": "(4) Gitara",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Musiqi qutusu",
    "music.instrumentOrgan": "(3) Orqan",
    "music.instrumentPiano": "(1) Pianino",
    "music.instrumentPizzicato": "(7) Pizzikato",
    "music.instrumentSaxophone": "(11) Saksafon",
    "music.instrumentSteelDrum": "(18) Polad baraban",
    "music.instrumentSynthLead": "(20) Aparıcı Sintezator",
    "music.instrumentSynthPad": "(21) Sintezator Pad",
    "music.instrumentTrombone": "(9) Trambon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Taxta fleyta",
    "music.midiPlayDrumForBeats": "play drum [DRUM] for [BEATS] beats",
    "music.midiSetInstrument": "set instrument to [INSTRUMENT]",
    "music.playDrumForBeats": "[DRUM] barabanını [BEATS] takt çal",
    "music.playNoteForBeats": "[NOTE] notunu [BEATS] takt çal",
    "music.restForBeats": "[BEATS] takt çalma",
    "music.setInstrument": "aləti [INSTRUMENT] təyin et",
    "music.setTempo": "tempi [TEMPO] təyin et",
    "videoSensing.categoryName": "Video Sensoru",
    "videoSensing.direction": "istiqamət",
    "videoSensing.motion": "hərəkət",
    "videoSensing.off": "söndür",
    "videoSensing.on": "on",
    "videoSensing.onFlipped": "çevrilmiş",
    "videoSensing.setVideoTransparency": "videonun şəffaflığını [TRANSPARENCY] təyin et",
    "videoSensing.sprite": "sprayt",
    "videoSensing.stage": "səhnə",
    "videoSensing.videoOn": "videonun [ATTRIBUTE] atributları [SUBJECT] aid",
    "videoSensing.videoToggle": "videonu [VIDEO_STATE] çevir",
    "videoSensing.whenMotionGreaterThan": "videonun hərəkəti > [REFERENCE]  olduqda",
    "translate.translateBlock": "[WORDS] ifadəsini [LANGUAGE] dilinə tərcümə et",
    "translate.defaultTextToTranslate": "salam",
    "translate.viewerLanguage": "dil",
    "text2speech.speakAndWaitBlock": "speak [WORDS]",
    "text2speech.setVoiceBlock": "set voice to [VOICE]",
    "text2speech.setLanguageBlock": "set language to [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "squeak",
    "text2speech.giant": "giant",
    "text2speech.kitten": "kitten",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "Mən [PHRASE] eşitdikdə",
    "speech.listenAndWait": "dinlə və gözlə",
    "speech.defaultWhenIHearValue": "gedək",
    "paint.paintEditor.hue": "Rəng",
    "paint.paintEditor.saturation": "Dolğunluq",
    "paint.paintEditor.brightness": "Parlaqlıq",
    "gui.comingSoon.message1": "Narahat olmayın, biz bunun üzərində çalışırıq {emoji}",
    "gui.comingSoon.message2": "Tezliklə ...",
    "gui.comingSoon.message3": "Biz bunun üzərində çalışırıq {emoji}",
    "paint.paintEditor.fill": "Rənglə doldur",
    "paint.paintEditor.costume": "Libas",
    "paint.paintEditor.group": "Qrup",
    "paint.paintEditor.ungroup": "Qrupu ləğv et",
    "paint.paintEditor.undo": "Ləğv et",
    "paint.paintEditor.redo": "Redo",
    "paint.paintEditor.forward": "İrəli",
    "paint.paintEditor.backward": "Geri",
    "paint.paintEditor.front": "Ön",
    "paint.paintEditor.back": "Geri",
    "paint.paintEditor.more": "Daha çox",
    "paint.modeTools.brushSize": "Ölçü",
    "paint.modeTools.eraserSize": "Pozanın ölçüsü",
    "paint.modeTools.copy": "Kopyala",
    "paint.modeTools.paste": "Yerləşdir",
    "paint.modeTools.delete": "Sil",
    "paint.modeTools.curved": "Əyri",
    "paint.modeTools.pointed": "İşarələnmiş",
    "paint.modeTools.thickness": "Qalınlıq",
    "paint.modeTools.flipHorizontal": "Üfiqi çevir",
    "paint.modeTools.flipVertical": "Şaquli çevir",
    "paint.modeTools.filled": "Dolu",
    "paint.modeTools.outlined": "Konturlanmış",
    "paint.paintEditor.bitmap": "Rastr qrafikasına çevir",
    "paint.paintEditor.vector": "Vektor qrafikasına çevir",
    "paint.paintEditor.stroke": "Kontur",
    "paint.brushMode.brush": "Brush",
    "paint.eraserMode.eraser": "Eraser",
    "paint.fillMode.fill": "Fill",
    "paint.lineMode.line": "Line",
    "paint.ovalMode.oval": "Circle",
    "paint.rectMode.rect": "Rectangle",
    "paint.reshapeMode.reshape": "Reshape",
    "paint.roundedRectMode.roundedRect": "Rounded Rectangle",
    "paint.selectMode.select": "Select",
    "paint.textMode.text": "Text",
    "paint.colorPicker.swap": "Dəyişdir",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "id": {
    "gui.backpack.header": "Ransel",
    "gui.backpack.errorBackpack": "Gagal memuat ransel",
    "gui.backpack.loadingBackpack": "Memuat...",
    "gui.backpack.more": "Lebih",
    "gui.backpack.emptyBackpack": "Ransel kosong",
    "gui.gui.costumesTab": "Kostum",
    "gui.gui.soundsTab": "Suara",
    "gui.gui.backdropsTab": "Latar",
    "gui.gui.addExtension": "Tambahkan Ekstension",
    "gui.costumeTab.addCostumeFromLibrary": "Pilih Kostum",
    "gui.costumeLibrary.chooseACostume": "Pilih Kostum",
    "gui.costumeTab.addBackdropFromLibrary": "Pilih Latar",
    "gui.costumeTab.addBlankCostume": "Lukis",
    "gui.costumeTab.addSurpriseCostume": "Kejutan",
    "gui.costumeTab.addFileBackdrop": "Unggah Latar",
    "gui.costumeTab.addFileCostume": "Unggah Kostum",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Potong",
    "gui.soundEditor.stop": "Berhenti",
    "gui.soundEditor.sound": "Suara",
    "gui.soundEditor.play": "Mainkan",
    "gui.soundEditor.save": "Simpan",
    "gui.soundEditor.undo": "Batalkan",
    "gui.soundEditor.redo": "Ulangi",
    "gui.soundEditor.faster": "Lebih cepat",
    "gui.soundEditor.slower": "Lebih lambat",
    "gui.soundEditor.echo": "Gema",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Lebih nyaring",
    "gui.soundEditor.softer": "Lebih lembut",
    "gui.soundEditor.reverse": "Balikkan arah",
    "gui.soundTab.recordSound": "Rekam",
    "gui.soundTab.addSoundFromLibrary": "Pilih Suara",
    "gui.soundTab.surpriseSound": "Kejutan",
    "gui.soundTab.fileUploadSound": "Unggah Suara",
    "gui.controls.stop": "Hentikan",
    "gui.controls.go": "Jalankan",
    "gui.stageHeader.stageSizeUnFull": "Keluar dari mode seluruh layar",
    "gui.SpriteInfo.show": "Tampilkan",
    "gui.SpriteInfo.size": "Ukuran",
    "gui.directionPicker.rotationStyles.allAround": "Semua arah",
    "gui.directionPicker.rotationStyles.leftRight": "Kiri/Kanan",
    "gui.directionPicker.rotationStyles.dontRotate": "Jangan berputar",
    "gui.spriteSelectorItem.contextMenuDuplicate": "gandakan",
    "gui.spriteSelectorItem.contextMenuDelete": "hapus",
    "gui.spriteSelectorItem.contextMenuExport": "ekspor",
    "gui.spriteSelector.addSpriteFromPaint": "Lukis",
    "gui.spriteSelector.addSpriteFromSurprise": "Kejutan",
    "gui.spriteSelector.addSpriteFromFile": "Unggah Sprite",
    "gui.stageSelector.stage": "Panggung",
    "gui.stageSelector.backdrops": "Latar",
    "gui.stageSelector.addBackdropFromPaint": "Lukis",
    "gui.stageSelector.addBackdropFromSurprise": "Kejutan",
    "gui.stageSelector.addBackdropFromFile": "Unggah Latar",
    "gui.modal.back": "Kembali",
    "gui.library.filterPlaceholder": "Cari",
    "gui.libraryTags.all": "Semua",
    "gui.libraryTags.animals": "Hewan",
    "gui.libraryTags.dance": "Menari",
    "gui.libraryTags.effects": "Efek",
    "gui.libraryTags.fantasy": "Fantasi",
    "gui.libraryTags.fashion": "Fesyen",
    "gui.libraryTags.food": "Makanan",
    "gui.libraryTags.indoors": "Dalam ruangan",
    "gui.libraryTags.loops": "Perulangan",
    "gui.libraryTags.music": "Musik",
    "gui.libraryTags.notes": "Catatan",
    "gui.libraryTags.outdoors": "Luar Ruangan",
    "gui.libraryTags.patterns": "Pola",
    "gui.libraryTags.people": "Orang",
    "gui.libraryTags.percussion": "Perkusi",
    "gui.libraryTags.space": "Luar angkasa",
    "gui.libraryTags.sports": "Olahraga",
    "gui.libraryTags.underwater": "Di bawah laut",
    "gui.libraryTags.voice": "Suara",
    "gui.libraryTags.wacky": "Aneh",
    "gui.libraryTags.animation": "Animasi",
    "gui.libraryTags.art": "Kesenian",
    "gui.libraryTags.games": "Permainan",
    "gui.libraryTags.stories": "Cerita",
    "gui.libraryTags.letters": "Huruf",
    "gui.soundLibrary.chooseASound": "Pilih Suara",
    "gui.SpriteInfo.spritePlaceholder": "Judul",
    "gui.cards.more-things-to-try": "Lebih banyak hal untuk dicoba!",
    "gui.cards.see-more": "Lihat lebih banyak",
    "gui.cards.shrink": "Kecilkan",
    "gui.cards.expand": "Perluas",
    "gui.cards.close": "Tutup",
    "gui.loader.message1": "Membuat balok-balok …",
    "gui.loader.message2": "Memuat sprite-sprite …",
    "gui.loader.message3": "Memuat suara …",
    "gui.loader.message4": "Memuat ekstension …",
    "gui.loader.message5": "Menggembala kucing-kucing …",
    "gui.loader.message6": "Mengirim nano-nano …",
    "gui.loader.message7": "Memompa gobo-gobo …",
    "gui.loader.message8": "Menyiapkan emoji-emoji …",
    "gui.loader.headline": "Memuat Karya",
    "gui.cameraModal.cameraModalTitle": "Ambil Sebuah Foto",
    "gui.cameraModal.loadingCameraMessage": "Memuat Kamera...",
    "gui.cameraModal.permissionRequest": "Kami perlu izinmu untuk menggunakan kameramu",
    "gui.cameraModal.retakePhoto": "Ambil Ulang Foto",
    "gui.cameraModal.save": "Simpan",
    "gui.cameraModal.takePhoto": "Ambil Foto",
    "gui.cameraModal.loadingCaption": "Memuat...",
    "gui.cameraModal.enableCameraCaption": "Perbolehkan Kamera",
    "gui.recordModal.title": "Rekam Suara",
    "gui.playbackStep.stopMsg": "Hentikan",
    "gui.playbackStep.playMsg": "Mainkan",
    "gui.playbackStep.loadingMsg": "Memuat...",
    "gui.playbackStep.saveMsg": "Simpan",
    "gui.playbackStep.reRecordMsg": "Rekam ulang",
    "gui.webglModal.label": "Perambanmu Tidak Mendukung WebGL",
    "gui.webglModal.webgllink": "tidak mendukung WebGL",
    "gui.prompt.cancel": "Batal",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Tambahkan input",
    "gui.customProcedures.addAnInputBoolean": "Tambahkan input",
    "gui.customProcedures.numberTextType": "angka atau teks",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Tambahkan label",
    "gui.customProcedures.runWithoutScreenRefresh": "Bekerja secara instan",
    "gui.customProcedures.cancel": "Batal",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Hubungkan karya-karyamu ke dunia.",
    "gui.extension.music.name": "Musik",
    "gui.extension.music.description": "Mainkan instrumen dan drum.",
    "gui.extension.pen.name": "Pena",
    "gui.extension.pen.description": "Menggambar dengan sprite-spritemu.",
    "gui.gui.variableScopeOptionAllSprites": "Untuk semua sprite",
    "gui.gui.variableScopeOptionSpriteOnly": "Hanya untuk sprite ini",
    "gui.gui.variablePromptAllSpritesMessage": "Variabel ini bakal tersedia untuk semua sprite.",
    "gui.monitor.contextMenu.default": "tampilan normal",
    "gui.monitor.contextMenu.large": "tampilan besar",
    "gui.monitor.contextMenu.slider": "slider",
    "gui.monitor.contextMenu.import": "impor",
    "gui.monitor.contextMenu.export": "ekspor",
    "gui.opcodeLabels.direction": "arah",
    "gui.opcodeLabels.xposition": "posisi x",
    "gui.opcodeLabels.yposition": "posisi y",
    "gui.opcodeLabels.size": "ukuran",
    "gui.opcodeLabels.costumename": "nama kostum",
    "gui.opcodeLabels.costumenumber": "nomor kostum",
    "gui.opcodeLabels.backdropname": "nama latar",
    "gui.opcodeLabels.backdropnumber": "nomor latar",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "jawaban",
    "gui.opcodeLabels.loudness": "kenyaringan",
    "gui.opcodeLabels.year": "tahun",
    "gui.opcodeLabels.month": "bulan",
    "gui.opcodeLabels.date": "tanggal",
    "gui.opcodeLabels.dayofweek": "nama hari",
    "gui.opcodeLabels.hour": "jam",
    "gui.opcodeLabels.minute": "menit",
    "gui.opcodeLabels.second": "detik",
    "gui.opcodeLabels.timer": "pengatur waktu",
    "music.categoryName": "Musik",
    "translate.categoryName": "Terjemahkan",
    "pen.categoryName": "Pena",
    "pen.changeColorParam": "ubah [COLOR_PARAM] pena sebesar [VALUE]",
    "pen.changeHue": "ubah warna pena sebesar [HUE]",
    "pen.changeShade": "ubah kegelapan pena sebesar [SHADE]",
    "pen.changeSize": "ubah ukuran pena sebesar [SIZE]",
    "pen.clear": "hapus semua",
    "pen.colorMenu.brightness": "kecerahan",
    "pen.colorMenu.color": "warna",
    "pen.colorMenu.saturation": "saturasi",
    "pen.colorMenu.transparency": "transparansi",
    "pen.penDown": "tekan pena",
    "pen.penUp": "angkat pena",
    "pen.setColor": "atur warna pena ke [COLOR]",
    "pen.setColorParam": "atur [COLOR_PARAM] pena ke [VALUE]",
    "pen.setHue": "atur warna pena ke [HUE]",
    "pen.setShade": "atur kegelapan pena sebesar [SHADE]",
    "pen.setSize": "atur ukuran pena ke [SIZE]",
    "pen.stamp": "cap",
    "music.changeTempo": "ubah tempo sebesar [TEMPO]",
    "music.drumBass": "(2) Drum Bass",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Hi-Hat Tertutup",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Lonceng Sapi",
    "music.drumCrashCymbal": "(4) Simbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Tepukan Tangan",
    "music.drumOpenHiHat": "(5) Hi-Hat Terbuka",
    "music.drumSideStick": "(3) Tongkat Samping",
    "music.drumSnare": "(1) Drum Senar",
    "music.drumTambourine": "(7) Tamborin",
    "music.drumTriangle": "(12) Triangle",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Balok Kayu",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Paduan Suara",
    "music.instrumentClarinet": "(10) Klarinet",
    "music.instrumentElectricGuitar": "(5) Gitar Listrik",
    "music.instrumentElectricPiano": "(2) Piano Listrik",
    "music.instrumentFlute": "(12) Seruling",
    "music.instrumentGuitar": "(4) Gitar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Kotak Musik",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saksofon",
    "music.instrumentSteelDrum": "(18) Drum Baja",
    "music.instrumentSynthLead": "(20) Sintesis Pimpinan",
    "music.instrumentSynthPad": "(21) Pad Sintesis",
    "music.instrumentTrombone": "(9) Trombon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Seruling Kayu",
    "music.midiPlayDrumForBeats": "mainkan drum [DRUM] selama [BEATS] ketukan",
    "music.midiSetInstrument": "atur instrumen ke [INSTRUMENT]",
    "music.playDrumForBeats": "mainkan drum [DRUM] selama [BEATS] ketukan",
    "music.playNoteForBeats": "mainkan nada [NOTE] selama [BEATS] ketukan",
    "music.restForBeats": "diam selama [BEATS] ketukan",
    "music.setInstrument": "atur instrumen ke [INSTRUMENT]",
    "music.setTempo": "atur tempo ke [TEMPO]",
    "videoSensing.categoryName": "Sensor Video",
    "videoSensing.direction": "arah",
    "videoSensing.motion": "gerakan",
    "videoSensing.off": "mati",
    "videoSensing.on": "hidup",
    "videoSensing.onFlipped": "nyala dibalik",
    "videoSensing.setVideoTransparency": "atur transparansi video ke [TRANSPARENCY]",
    "videoSensing.sprite": "sprite",
    "videoSensing.stage": "panggung",
    "videoSensing.videoOn": "video [ATTRIBUTE] di [SUBJECT]",
    "videoSensing.videoToggle": "nyalakan video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "ketika gerakan video > [REFERENCE]",
    "translate.translateBlock": "terjemahkan [WORDS] ke [LANGUAGE]",
    "translate.defaultTextToTranslate": "halo",
    "translate.viewerLanguage": "bahasa",
    "text2speech.speakAndWaitBlock": "ujarkan [WORDS]",
    "text2speech.setVoiceBlock": "atur suara ke [VOICE]",
    "text2speech.setLanguageBlock": "atur bahasa ke [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "mencicit",
    "text2speech.giant": "raksasa",
    "text2speech.kitten": "anak kucing",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "ketika aku mendengar [PHRASE]",
    "speech.listenAndWait": "dengarkan dan tunggu",
    "speech.defaultWhenIHearValue": "ayo pergi",
    "paint.paintEditor.hue": "Warna",
    "paint.paintEditor.saturation": "Saturasi",
    "paint.paintEditor.brightness": "Keterangan",
    "gui.comingSoon.message1": "Jangan khawatir, kami sedang mengerjakannya {emoji}",
    "gui.comingSoon.message2": "Akan Segera Datang",
    "gui.comingSoon.message3": "Kami sedang mengerjakannya {emoji}",
    "paint.paintEditor.fill": "Isi",
    "paint.paintEditor.costume": "Kostum",
    "paint.paintEditor.group": "Kelompok",
    "paint.paintEditor.ungroup": "Pisahkan",
    "paint.paintEditor.undo": "Undo",
    "paint.paintEditor.redo": "Ulangi",
    "paint.paintEditor.forward": "Maju",
    "paint.paintEditor.backward": "Mundur",
    "paint.paintEditor.front": "Depan",
    "paint.paintEditor.back": "Kembali",
    "paint.paintEditor.more": "Lebih",
    "paint.modeTools.brushSize": "Ukuran",
    "paint.modeTools.eraserSize": "Ukuran penghapus",
    "paint.modeTools.copy": "Salin",
    "paint.modeTools.paste": "Tempel",
    "paint.modeTools.delete": "Hapus",
    "paint.modeTools.curved": "Bundar",
    "paint.modeTools.pointed": "Tajam",
    "paint.modeTools.thickness": "Ketebalan",
    "paint.modeTools.flipHorizontal": "Balikkan Secara Horisontal",
    "paint.modeTools.flipVertical": "Balikkan Secara Vertikal",
    "paint.modeTools.filled": "Terisi",
    "paint.modeTools.outlined": "Garis besar",
    "paint.paintEditor.bitmap": "Sesuaikan ke Bitmap",
    "paint.paintEditor.vector": "Sesuaikan ke Vektor",
    "paint.paintEditor.stroke": "Garis besar",
    "paint.brushMode.brush": "Kuas",
    "paint.eraserMode.eraser": "Penghapus",
    "paint.fillMode.fill": "Isi",
    "paint.lineMode.line": "Garis",
    "paint.ovalMode.oval": "Lingkaran",
    "paint.rectMode.rect": "Persegi panjang",
    "paint.reshapeMode.reshape": "Bentuk ulang",
    "paint.roundedRectMode.roundedRect": "Persegi Bulat",
    "paint.selectMode.select": "Pilih",
    "paint.textMode.text": "Teks",
    "paint.colorPicker.swap": "Tukar",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "bg": {
    "gui.backpack.header": "Раница",
    "gui.backpack.errorBackpack": "Грешка при зареждането на раницата",
    "gui.backpack.loadingBackpack": "Зареждане…",
    "gui.backpack.more": "Още",
    "gui.backpack.emptyBackpack": "Раницата е празна",
    "gui.gui.costumesTab": "Костюми",
    "gui.gui.soundsTab": "Звуци",
    "gui.gui.backdropsTab": "Фонове",
    "gui.gui.addExtension": "Добавяне на разширение",
    "gui.costumeTab.addCostumeFromLibrary": "Избор на костюм",
    "gui.costumeLibrary.chooseACostume": "Избор на костюм",
    "gui.costumeTab.addBackdropFromLibrary": "Избор на фон",
    "gui.costumeTab.addBlankCostume": "Нарисувай",
    "gui.costumeTab.addSurpriseCostume": "Изненада",
    "gui.costumeTab.addFileBackdrop": "Качи Декор",
    "gui.costumeTab.addFileCostume": "Качи Костюм",
    "gui.costumeTab.addCameraCostume": "Камера",
    "gui.soundEditor.trim": "Отрязване",
    "gui.soundEditor.stop": "Спиране",
    "gui.soundEditor.sound": "Звук",
    "gui.soundEditor.play": "Пускане",
    "gui.soundEditor.save": "Запазване",
    "gui.soundEditor.undo": "Отмяна",
    "gui.soundEditor.redo": "Повтаряне",
    "gui.soundEditor.faster": "По-бързо",
    "gui.soundEditor.slower": "По-бавно",
    "gui.soundEditor.echo": "Ехо",
    "gui.soundEditor.robot": "Робот",
    "gui.soundEditor.louder": "По-силно",
    "gui.soundEditor.softer": "По-меко",
    "gui.soundEditor.reverse": "Размени",
    "gui.soundTab.recordSound": "Запис",
    "gui.soundTab.addSoundFromLibrary": "Избор на звук",
    "gui.soundTab.surpriseSound": "Изненада",
    "gui.soundTab.fileUploadSound": "Качване на звук",
    "gui.controls.stop": "Спиране",
    "gui.controls.go": "Старт",
    "gui.stageHeader.stageSizeUnFull": "Излизане от режим \"Full screen\"",
    "gui.SpriteInfo.show": "Показване",
    "gui.SpriteInfo.size": "Size",
    "gui.directionPicker.rotationStyles.allAround": "Пълно завъртане",
    "gui.directionPicker.rotationStyles.leftRight": "Left/Right",
    "gui.directionPicker.rotationStyles.dontRotate": "Без въртене",
    "gui.spriteSelectorItem.contextMenuDuplicate": "копиране",
    "gui.spriteSelectorItem.contextMenuDelete": "изтриване",
    "gui.spriteSelectorItem.contextMenuExport": "експорт",
    "gui.spriteSelector.addSpriteFromPaint": "Нарисувай",
    "gui.spriteSelector.addSpriteFromSurprise": "Изненада",
    "gui.spriteSelector.addSpriteFromFile": "Качи Спрайт",
    "gui.stageSelector.stage": "Сцена",
    "gui.stageSelector.backdrops": "Фонове",
    "gui.stageSelector.addBackdropFromPaint": "Нарисувай",
    "gui.stageSelector.addBackdropFromSurprise": "Изненада",
    "gui.stageSelector.addBackdropFromFile": "Качи Декор",
    "gui.modal.back": "Назад",
    "gui.library.filterPlaceholder": "Търсене",
    "gui.libraryTags.all": "Всички",
    "gui.libraryTags.animals": "Животни",
    "gui.libraryTags.dance": "Dance",
    "gui.libraryTags.effects": "Ефекти",
    "gui.libraryTags.fantasy": "Фантастика",
    "gui.libraryTags.fashion": "Мода",
    "gui.libraryTags.food": "Food",
    "gui.libraryTags.indoors": "На закрито",
    "gui.libraryTags.loops": "Цикли",
    "gui.libraryTags.music": "Музика",
    "gui.libraryTags.notes": "Notes",
    "gui.libraryTags.outdoors": "На открито",
    "gui.libraryTags.patterns": "Patterns",
    "gui.libraryTags.people": "Хора",
    "gui.libraryTags.percussion": "Перкусионни",
    "gui.libraryTags.space": "Космос",
    "gui.libraryTags.sports": "Спорт",
    "gui.libraryTags.underwater": "Подводни",
    "gui.libraryTags.voice": "Voice",
    "gui.libraryTags.wacky": "Wacky",
    "gui.libraryTags.animation": "Animation",
    "gui.libraryTags.art": "Изкуство",
    "gui.libraryTags.games": "Игри",
    "gui.libraryTags.stories": "Истории",
    "gui.libraryTags.letters": "Букви",
    "gui.soundLibrary.chooseASound": "Избор на звук",
    "gui.SpriteInfo.spritePlaceholder": "Name",
    "gui.cards.more-things-to-try": "Още неща, които да опиташ!",
    "gui.cards.see-more": "Виж още",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Затвори",
    "gui.loader.message1": "Създаване на блокчетата…",
    "gui.loader.message2": "Зареждане на спрайтове…",
    "gui.loader.message3": "Зареждане на звукове…",
    "gui.loader.message4": "Зареждане на разширенията…",
    "gui.loader.message5": "Събиране на котките…",
    "gui.loader.message6": "Transmitting nanos …",
    "gui.loader.message7": "Inflating gobos …",
    "gui.loader.message8": "Подготвяне на емоджитата…",
    "gui.loader.headline": "Зареждане на проекта",
    "gui.cameraModal.cameraModalTitle": "Снимай",
    "gui.cameraModal.loadingCameraMessage": "Loading Camera...",
    "gui.cameraModal.permissionRequest": "Имаме нужда от Вашето разрешение, за да използваме камера Ви.",
    "gui.cameraModal.retakePhoto": "Снимай пак",
    "gui.cameraModal.save": "Запазване",
    "gui.cameraModal.takePhoto": "Снимай",
    "gui.cameraModal.loadingCaption": "Зареждане…",
    "gui.cameraModal.enableCameraCaption": "Enable Camera",
    "gui.recordModal.title": "Запиши звук",
    "gui.playbackStep.stopMsg": "Спиране",
    "gui.playbackStep.playMsg": "Пускане",
    "gui.playbackStep.loadingMsg": "Зареждане…",
    "gui.playbackStep.saveMsg": "Запазване",
    "gui.playbackStep.reRecordMsg": "Презапис",
    "gui.webglModal.label": "Браузърът не поддържа WebGL",
    "gui.webglModal.webgllink": "не поддържа WebGL",
    "gui.prompt.cancel": "Отказ",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Добави поле за въвеждане",
    "gui.customProcedures.addAnInputBoolean": "Добави поле за въвеждане",
    "gui.customProcedures.numberTextType": "число или текст",
    "gui.customProcedures.booleanType": "да-не въвеждане",
    "gui.customProcedures.addALabel": "Добавяне на етикет",
    "gui.customProcedures.runWithoutScreenRefresh": "Стартиране без обновяване на екрана",
    "gui.customProcedures.cancel": "Отказ",
    "gui.customProcedures.ok": "Добре",
    "gui.extension.microbit.description": "Свържи проектите си със света.",
    "gui.extension.music.name": "Музика",
    "gui.extension.music.description": "Изсвирете с инструменти и барабани.",
    "gui.extension.pen.name": "Молив",
    "gui.extension.pen.description": "Draw with your sprites.",
    "gui.gui.variableScopeOptionAllSprites": "За всички спрайтове",
    "gui.gui.variableScopeOptionSpriteOnly": "Само за този спрайт",
    "gui.gui.variablePromptAllSpritesMessage": "Тази променлива ще бъде достъпна за всички спрайтове.",
    "gui.monitor.contextMenu.default": "нормално изписване",
    "gui.monitor.contextMenu.large": "уголемено изписване",
    "gui.monitor.contextMenu.slider": "плъзгач",
    "gui.monitor.contextMenu.import": "импорт",
    "gui.monitor.contextMenu.export": "експорт",
    "gui.opcodeLabels.direction": "посока",
    "gui.opcodeLabels.xposition": "x позиция",
    "gui.opcodeLabels.yposition": "y позиция",
    "gui.opcodeLabels.size": "размер",
    "gui.opcodeLabels.costumename": "име на костюм",
    "gui.opcodeLabels.costumenumber": "costume number",
    "gui.opcodeLabels.backdropname": "име на декор",
    "gui.opcodeLabels.backdropnumber": "backdrop number",
    "gui.opcodeLabels.volume": "сила на звука",
    "gui.opcodeLabels.tempo": "темпо",
    "gui.opcodeLabels.answer": "отговор",
    "gui.opcodeLabels.loudness": "шумно",
    "gui.opcodeLabels.year": "година",
    "gui.opcodeLabels.month": "месец",
    "gui.opcodeLabels.date": "дата",
    "gui.opcodeLabels.dayofweek": "ден от седмицата",
    "gui.opcodeLabels.hour": "час",
    "gui.opcodeLabels.minute": "минута",
    "gui.opcodeLabels.second": "секунда",
    "gui.opcodeLabels.timer": "таймер",
    "music.categoryName": "Музика",
    "translate.categoryName": "Translate",
    "pen.categoryName": "Молив",
    "pen.changeColorParam": "промени [COLOR_PARAM] на молива с/ъс [VALUE]",
    "pen.changeHue": "промени цвета на молива с [HUE]",
    "pen.changeShade": "промени плътността на молива с/ъс [SHADE]",
    "pen.changeSize": "промени pen size by [SIZE]",
    "pen.clear": "erase all",
    "pen.colorMenu.brightness": "яркост",
    "pen.colorMenu.color": "цвят",
    "pen.colorMenu.saturation": "наситеност",
    "pen.colorMenu.transparency": "прозрачност",
    "pen.penDown": "молив долу",
    "pen.penUp": "молив горе",
    "pen.setColor": "set pen color to [COLOR]",
    "pen.setColorParam": "направи [COLOR_PARAM] на молива на [VALUE]",
    "pen.setHue": "направи цвета на молива [HUE]",
    "pen.setShade": "направи яркостта на молива [SHADE]",
    "pen.setSize": "направи дебелината на молива [SIZE]",
    "pen.stamp": "отпечати",
    "music.changeTempo": "увеличи темпо с [TEMPO]",
    "music.drumBass": "(2) Бас Барабан",
    "music.drumBongo": "(13) Бонго",
    "music.drumCabasa": "(15) Кабаса",
    "music.drumClaves": "(9) Клавес",
    "music.drumClosedHiHat": "(6) Затворен педален чинел",
    "music.drumConga": "(14) Конга",
    "music.drumCowbell": "(11) Пастирски Звънец",
    "music.drumCrashCymbal": "(4) Краш чинел",
    "music.drumCuica": "(18) Куика",
    "music.drumGuiro": "(16) Гуиро",
    "music.drumHandClap": "(8) Ръкопляскане",
    "music.drumOpenHiHat": "(5) Отворен педален чинел",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Малък Барабан",
    "music.drumTambourine": "(7) Дайре",
    "music.drumTriangle": "(12) Триъгълник",
    "music.drumVibraslap": "(17) Вибраслап",
    "music.drumWoodBlock": "(10) Дървени Блокчета",
    "music.getTempo": "темпо",
    "music.instrumentBass": "(6) Контрабас",
    "music.instrumentBassoon": "(14) Фагот",
    "music.instrumentCello": "(8) Виолончело",
    "music.instrumentChoir": "(15) Хор",
    "music.instrumentClarinet": "(10) Кларинет",
    "music.instrumentElectricGuitar": "(5) Електрическа Китара",
    "music.instrumentElectricPiano": "(2) Електрическо Пиано",
    "music.instrumentFlute": "(12) Флейта",
    "music.instrumentGuitar": "(4) Китара",
    "music.instrumentMarimba": "(19) Маримба",
    "music.instrumentMusicBox": "(17) Музикална Кутия",
    "music.instrumentOrgan": "(3) Орган",
    "music.instrumentPiano": "(1) Пиано",
    "music.instrumentPizzicato": "(7) Пицикато",
    "music.instrumentSaxophone": "(11) Саксофон",
    "music.instrumentSteelDrum": "(18) Стилпан",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Тромбон",
    "music.instrumentVibraphone": "(16) Вибрафон",
    "music.instrumentWoodenFlute": "(13) Дървена Флейта",
    "music.midiPlayDrumForBeats": "play drum [DRUM] for [BEATS] beats",
    "music.midiSetInstrument": "set instrument to [INSTRUMENT]",
    "music.playDrumForBeats": "play drum [DRUM] for [BEATS] beats",
    "music.playNoteForBeats": "play note [NOTE] for [BEATS] beats",
    "music.restForBeats": "rest for [BEATS] beats",
    "music.setInstrument": "set instrument to [INSTRUMENT]",
    "music.setTempo": "направи темпо на [TEMPO]",
    "videoSensing.categoryName": "Видео Усет",
    "videoSensing.direction": "посока",
    "videoSensing.motion": "движение",
    "videoSensing.off": "изключено",
    "videoSensing.on": "включено",
    "videoSensing.onFlipped": "on flipped",
    "videoSensing.setVideoTransparency": "направи плътност на видеото на [TRANSPARENCY]",
    "videoSensing.sprite": "спрайт",
    "videoSensing.stage": "сцена",
    "videoSensing.videoOn": "video [ATTRIBUTE] on [SUBJECT]",
    "videoSensing.videoToggle": "[VIDEO_STATE] видео",
    "videoSensing.whenMotionGreaterThan": "когато видео движение > [REFERENCE]",
    "translate.translateBlock": "преведи [WORDS] на [LANGUAGE]",
    "translate.defaultTextToTranslate": "здравей",
    "translate.viewerLanguage": "език",
    "text2speech.speakAndWaitBlock": "кажи [WORDS]",
    "text2speech.setVoiceBlock": "направи звука на [VOICE]",
    "text2speech.setLanguageBlock": "промени езика на [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "висок",
    "text2speech.squeak": "скърцане",
    "text2speech.giant": "великан",
    "text2speech.kitten": "коте",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "когато чуя [PHRASE]",
    "speech.listenAndWait": "слушай и чакай",
    "speech.defaultWhenIHearValue": "let''s go",
    "paint.paintEditor.hue": "Цвят",
    "paint.paintEditor.saturation": "Наситеност",
    "paint.paintEditor.brightness": "Яркост",
    "gui.comingSoon.message1": "Don't worry, we're on it {emoji}",
    "gui.comingSoon.message2": "Очаквайте скоро…",
    "gui.comingSoon.message3": "We're working on it {emoji}",
    "paint.paintEditor.fill": "Запълване",
    "paint.paintEditor.costume": "Костюм",
    "paint.paintEditor.group": "Групиране",
    "paint.paintEditor.ungroup": "Разгрупиране",
    "paint.paintEditor.undo": "Отмяна",
    "paint.paintEditor.redo": "Повтаряне",
    "paint.paintEditor.forward": "Напред",
    "paint.paintEditor.backward": "Назад",
    "paint.paintEditor.front": "Front",
    "paint.paintEditor.back": "Back",
    "paint.paintEditor.more": "Още",
    "paint.modeTools.brushSize": "Size",
    "paint.modeTools.eraserSize": "Размер на гумата",
    "paint.modeTools.copy": "Копиране",
    "paint.modeTools.paste": "Поставяне",
    "paint.modeTools.delete": "Изтриване",
    "paint.modeTools.curved": "Curved",
    "paint.modeTools.pointed": "Pointed",
    "paint.modeTools.thickness": "Thickness",
    "paint.modeTools.flipHorizontal": "Хоризонтално обръщане",
    "paint.modeTools.flipVertical": "Вертикално обръщане",
    "paint.modeTools.filled": "Filled",
    "paint.modeTools.outlined": "Outlined",
    "paint.paintEditor.bitmap": "Преобразуване в растерен режим",
    "paint.paintEditor.vector": "Convert to Vector",
    "paint.paintEditor.stroke": "Outline",
    "paint.brushMode.brush": "Четка",
    "paint.eraserMode.eraser": "Eraser",
    "paint.fillMode.fill": "Запълване",
    "paint.lineMode.line": "Линия",
    "paint.ovalMode.oval": "Окръжност",
    "paint.rectMode.rect": "Правоъгълник",
    "paint.reshapeMode.reshape": "Оформяне",
    "paint.roundedRectMode.roundedRect": "Rounded Rectangle",
    "paint.selectMode.select": "Маркиране",
    "paint.textMode.text": "Текст",
    "paint.colorPicker.swap": "Swap",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "ca": {
    "gui.gui.project": "Projecte",
    "gui.gui.newItem": "Nou Projecte",
    "gui.gui.load": "Obrir Projecte",
    "gui.gui.save": "Desar Projecte",
    "gui.gui.saveAs": "Guardar com",
    "gui.gui.cacheList": "Cache List",
    "gui.gui.inCache": "En Cache",
    "gui.gui.learn": "Aprendre",
    "gui.gui.clearRecord": "Esborra registre",
    "gui.gui.onlineLearn": "Documents oficials",
    "gui.gui.onlineForum": "Online Fòrum",
    "gui.gui.videoTutorial": "Video Tutorials",
    "gui.gui.offlineLearn": "Example Programs",
    "gui.menuBar.turboModeOff": "Activa mode Turbo",
    "gui.menuBar.turboModeOn": "Desactiva mode Turbo",
    "gui.menuBar.edit": "Editar",
    "gui.menuBar.restoreSprite": "Restaurar Sprite",
    "gui.menuBar.restoreSound": "Restaurar So",
    "gui.menuBar.restoreCostume": "Restaurar vestit",
    "gui.menuBar.restoreBackdrop": "Restaurar fons",
    "gui.editorMind.restore": "Restaurar",
    "gui.turboMode.active": "Mode turbo",
    "gui.gui.connectDevice": "Connectar dispositius",
    "gui.gui.disconnectDevice": "Desconnectar dispositius",
    "gui.gui.installSerialportDriver": "Instal·lar Controlador del Port",
    "gui.gui.openDM": "Obrir Administrador de Dispositius",
    "gui.gui.restoreSetting": "Restaura la configuració inicial del Dispositiu",
    "gui.gui.updateTips": "Update Tips",
    "gui.gui.newVersion": "The Latest Version",
    "gui.gui.downloadUpdate": "Download to update",
    "gui.gui.versionUpdate": "Actualitzar Programa",
    "gui.gui.isCheckMd5": "Verifying file",
    "gui.gui.downloading": "Checking Updator",
    "gui.gui.updateError": "Update Error",
    "gui.setting.feedbackMenu": "Comentari",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.gui.python": "Python",
    "gui.setting.officialWebsit": "Official Website",
    "gui.setting.officialQQGroup": "Official QQ Group",
    "gui.setting.wechatOfficialAccounts": "WeChat Official Accounts",
    "gui.setting.currentVersion": "Current Version",
    "gui.setting.checkForUpdate": "Check for Updates",
    "gui.setting.remindWhenUpdate": "Download when updated",
    "gui.setting.currentIsLatest": "The current version is the latest one",
    "gui.setting.latestVersion": "The latest version",
    "gui.setting.download": "Skip to download web",
    "gui.setting.language": "Idioma",
    "gui.setting.uploadCsv": "upload xlsx",
    "gui.setting.theme": "Theme",
    "gui.setting.feedback": "Contact",
    "gui.setting.email": "E-mail",
    "gui.setting.opinionFeedback": "Feedback",
    "gui.variableMc.variableName": "variable name",
    "gui.variableMc.variableOff": "cancel",
    "gui.variableMc.variableOn": "confirm",
    "gui.variableMc.newVariable": "nova variable",
    "gui.setting.helpUsTranslate": "Help Us Translate",
    "gui.setting.checkUpdate": "Check Update",
    "gui.setting.ok": "Ok",
    "gui.setting.languageSetting": "Llengua",
    "gui.setting.themeSetting": "Tema",
    "gui.setting.cacheSetting": "Cache Setting",
    "gui.setting.versionUpdate": "Version Update",
    "gui.setting.connectUsTitle": "Contact Us",
    "gui.setting.uploadAttachment": "Upload Attachment",
    "gui.setting.displayTitle": "Display Setting",
    "gui.setting.restartEffect": "font-size (restart effect):",
    "gui.setting.fontSizeLarge": "Large",
    "gui.setting.fontSizeMiddle": "Middle",
    "gui.setting.fontSizeSmall": "Small",
    "gui.setting.onlineRepair": "online repair tool, click open >",
    "gui.setting.cacheSwitch": "Cache (open/close)",
    "gui.setting.freeCache": "Free cache",
    "gui.setting.spaceCache": "Fixed interval",
    "gui.setting.cacheWay": "Cache Way",
    "gui.setting.interval": "interval",
    "gui.setting.freeTime": "Free time",
    "gui.setting.second": "second",
    "gui.setting.minute": "minute",
    "gui.gui.code": "Code",
    "gui.menubar.cloudProject": "Cloud Project",
    "gui.menubar.login": "Login",
    "gui.menubar.signout": "Sign out",
    "gui.menubar.saveToCloud": "Save to Cloud",
    "gui.menubar.cloudServer": "Cloud",
    "gui.setting.cleanCache": "Data Cleaning",
    "gui.setting.programCache": "Project Cache",
    "gui.setting.configJson": "Configuration File",
    "gui.setting.pythonEnv": "Python Environment",
    "gui.setting.backpack": "Schoolbag File",
    "gui.setting.compile": "Compile Information",
    "gui.setting.thirdExt": "Extended User Library",
    "gui.setting.cleanCacheBtn": "Clear Selection",
    "gui.setting.cleanCachePrompt": "Data cannot be restored after clearing, are you sure to clear it?",
    "gui.setting.cleanPythonEnvPrompt": "In order to avoid the exception of other windows caused by the Python environment cleaning, please make sure that only one Mind+ software window is opened before cleaning.",
    "gui.setting.cleanPyEnvOnPythonPrompt": "In Python mode, you need to reset the environment after cleaning resources. This process needs to wait for a while. And in order to avoid cleaning resources causing other window exceptions, please ensure that only one Mind+ software window is opened before cleaning. ",
    "gui.setting.expVersion": "Experimental Version",
    "gui.setting.expUpdateTips": "[Update Notes]: This version is an early adopter version that has not been officially released. Some functions may be unstable. Please confirm before downloading;",
    "gui.setting.cleanConfigSuccess": "Data cleaning is complete, please restart Mind+.",
    "gui.setting.cleanConfigError": "An error occurred during data cleaning, please try again",
    "gui.setting.cleanPartError": "Error cleaning [PART]...",
    "gui.setting.cleaningPyEnv": "Cleaning the Python environment...",
    "gui.setting.cleanPythonEnvError": "Python environment resource release failed, you can close the software, manually delete the folder [PATH] , and then reopen the software",
    "gui.backpack.header": "Motxila ",
    "gui.backpack.errorBackpack": "Error al carregar la motxila",
    "gui.backpack.loadingBackpack": "Carregant...",
    "gui.backpack.more": "més",
    "gui.backpack.emptyBackpack": "La motxila està buida",
    "gui.gui.blocks": "Bloc",
    "gui.gui.costumesTab": "Disfresses",
    "gui.gui.soundsTab": "Sons",
    "gui.gui.backdropsTab": "fons",
    "gui.gui.addExtension": "Extensions",
    "gui.costumeTab.addCostumeFromLibrary": "Tria un Sprite",
    "gui.costumeLibrary.chooseACostume": "Tria una disfressa ",
    "gui.costumeTab.addBackdropFromLibrary": "Triar un fons",
    "gui.costumeTab.addBlankCostume": "Pintar",
    "gui.costumeTab.addSurpriseCostume": "Sorpresa",
    "gui.costumeTab.addFileBackdrop": "Pujar fons",
    "gui.costumeTab.addFileCostume": "Pujar disfressa",
    "gui.costumeTab.addCameraCostume": "Càmera",
    "gui.sliderModal.min": "Valor mínim",
    "gui.sliderModal.max": "Valor màxim",
    "gui.sliderModal.title": "Change Slider Range",
    "gui.soundEditor.trim": "Retallar",
    "gui.soundEditor.stop": "Aturar",
    "gui.soundEditor.sound": "So",
    "gui.soundEditor.play": "Jugar",
    "gui.soundEditor.save": "Desar",
    "gui.soundEditor.undo": "Desfer",
    "gui.soundEditor.redo": "Refer",
    "gui.soundEditor.faster": "Més ràpid",
    "gui.soundEditor.slower": "Més lent",
    "gui.soundEditor.echo": "Echo",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Louder",
    "gui.soundEditor.softer": "Softer",
    "gui.soundEditor.reverse": "Reverse",
    "gui.soundTab.recordSound": "Gravar",
    "gui.soundTab.addSoundFromLibrary": "Tria un so",
    "gui.soundTab.surpriseSound": "Sorpresa",
    "gui.soundTab.fileUploadSound": "Pujar so",
    "gui.soundTab.addSound": "Afegir so",
    "gui.controls.stop": "Aturar",
    "gui.controls.go": "Anar",
    "gui.controls.customFirmware": "Custom Firmware",
    "gui.controls.defaultFirmware": "Official Firmware",
    "gui.controls.clearLocaleFirmware": "Clear Records",
    "gui.controls.checkForUpdates": "Check For Updates",
    "gui.controls.alreadyLastVersion": "Already the latest version",
    "gui.controls.uploadFirmware": "Ipload",
    "gui.gui.startPython": "Start Python",
    "gui.gui.stopPython": "Stop Python",
    "gui.controls.fullScreenControl": "Full Screen Control",
    "gui.gui.stageSizeLarge": "Large Screen Contorl",
    "gui.gui.stageSizeSmall": "Small Screen Control",
    "gui.gui.stageSizeNostage": "Stage Size Toggle - No Stage",
    "gui.gui.stageSizeFull": "Stage Size Toggle - Full Screen",
    "gui.stageHeader.stageSizeUnFull": "Exit the Full Screen",
    "gui.sprite.sprite": "Sprite",
    "gui.SpriteInfo.show": "Mostrar",
    "gui.SpriteInfo.size": "Mida",
    "gui.sprite.direction": "Direcció",
    "gui.sprite.rotation": "Rotació",
    "gui.directionPicker.rotationStyles.allAround": "En totes direccions",
    "gui.directionPicker.rotationStyles.leftRight": "esquerra/Dreta",
    "gui.directionPicker.rotationStyles.dontRotate": "No rotar",
    "gui.spriteSelectorItem.contextMenuDuplicate": "Duplicar",
    "gui.spriteSelectorItem.contextMenuDelete": "eliminar",
    "gui.spriteSelectorItem.contextMenuExport": "Exportar",
    "gui.sprite.addSpriteFromLibrary": "Llibreria de Sprites",
    "gui.spriteSelector.addSpriteFromPaint": "Pintar",
    "gui.spriteSelector.addSpriteFromSurprise": "Sorpresa",
    "gui.spriteSelector.addSpriteFromFile": "Pujar objecte",
    "gui.sprite.addSpriteFromCamera": "Càmera",
    "gui.stageSelector.stage": "Escenari",
    "gui.stageSelector.backdrops": "Fons",
    "gui.stage.addBackdropFromLibrary": "Biblioteca de fons",
    "gui.stageSelector.addBackdropFromPaint": "Pintar",
    "gui.stageSelector.addBackdropFromSurprise": "Sorpresa",
    "gui.stageSelector.addBackdropFromFile": "Pujar fons",
    "gui.stage.addBackdropFromCamera": "Càmera",
    "gui.modal.help": "Ajuda",
    "gui.modal.save": "Desar",
    "gui.modal.run": "Run",
    "gui.modal.continue": "Continue",
    "gui.modal.paused": "Paused",
    "gui.modal.back": "Tornar",
    "gui.extension.kit": "Kit",
    "gui.extension.kitTitle": "Triar Kit",
    "gui.extension.board": "Targeta",
    "gui.extension.boardTitle": "Triar tarjeta",
    "gui.extension.spreadBoard": "Shield",
    "gui.extension.spreadBoardTitle": "Select Shield",
    "gui.extension.sensor": "Sensor",
    "gui.extension.sensorTitle": "Tria Sensor",
    "gui.extension.actuator": "Actuador",
    "gui.extension.actuatorTitle": "Selecciona actuador",
    "gui.extension.communicationModule": "Comunicació",
    "gui.extension.communicationModuleTitle": "Triar bloc de comunicació",
    "gui.extension.display": "Pantalla",
    "gui.extension.displayTitle": "Tria pantalla",
    "gui.extension.function": "Funció",
    "gui.extension.functionTitle": "Tria funció",
    "gui.extension.internetService": "Internet",
    "gui.extension.internetServiceTitle": "Tria servei d'Internet",
    "gui.extension.thirdExpansion": "User-Ext",
    "gui.extension.thirdExpansionTitle": "Select user-expansion",
    "gui.extension.arduinContentLabel": "Tria dispositiu",
    "gui.extension.openUserExFile": "Open the User-Ex file on your computer",
    "gui.extension.importUserEx": "Import User-Ex",
    "gui.extension.unavailable": "Unavailable",
    "gui.extension.thirdModuleSearch": "Search or enter project URL",
    "gui.extension.thirdModulesHelp": "Note: The modules in the user library are made by Mind + enthusiasts, {clickHelp} view {tutorial} and {list}",
    "gui.extension.thirdTutorial": "development tutorial",
    "gui.extension.thirdList": "User-Ext list",
    "gui.extension.libaryOffice": "Official Library",
    "gui.extension.libaryPinpong": "pinpong",
    "gui.library.filterPlaceholder": "Cercar",
    "gui.libraryTags.all": "Tots",
    "gui.libraryTags.animals": "Animals",
    "gui.libraryTags.dance": "Balls",
    "gui.libraryTags.effects": "Efectes",
    "gui.libraryTags.fantasy": "Fantasia",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Menjar",
    "gui.libraryTags.festival": "Festival",
    "gui.libraryTags.traffic": "Tràfic",
    "gui.libraryTags.indoors": "Interiors",
    "gui.libraryTags.loops": "Loops",
    "gui.libraryTags.music": "Música",
    "gui.libraryTags.notes": "Notes",
    "gui.libraryTags.outdoors": "Exteriors",
    "gui.libraryTags.patterns": "Patrons",
    "gui.libraryTags.people": "Gent",
    "gui.libraryTags.percussion": "Percusió",
    "gui.libraryTags.space": "Espai",
    "gui.libraryTags.sports": "Esports",
    "gui.libraryTags.underwater": "Sota l'aigua",
    "gui.libraryTags.voice": "Veu",
    "gui.libraryTags.wacky": "Adsurd",
    "gui.libraryTags.animation": "Animació",
    "gui.libraryTags.art": "Art",
    "gui.libraryTags.games": "Jocs",
    "gui.libraryTags.stories": "Històries",
    "gui.libraryTags.letters": "Lletres",
    "gui.extension.backdropLib": "Biblioteca de fons",
    "gui.soundLibrary.chooseASound": "Tria so",
    "gui.SpriteInfo.spritePlaceholder": "Name",
    "gui.extension.spriteLib": "tria Sprite",
    "gui.gui.unselectedSerialport": "Dispositiu no connectat",
    "gui.gui.unConnectedDev": "Dispositiu no connectat",
    "gui.gui.pythonMode": "Python Mode",
    "gui.gui.burnFirmware": "Burnning Firmware Again",
    "gui.gui.burnFirmwareError": "Firmware Burnning Error",
    "gui.gui.connected": "Connectat",
    "gui.gui.failedConnected": "No ha estat possible la connexió",
    "gui.gui.connecting": "Connectant...",
    "gui.cards.all-how-tos": "All How-Tos",
    "gui.cards.how-tos": "Tutorials",
    "gui.cards.remove": "Remove",
    "gui.cards.more-things-to-try": "Try more!",
    "gui.cards.see-more": "See More",
    "gui.loader.message1": "Creating Blocks …",
    "gui.loader.message2": "Loading Sprites …",
    "gui.loader.message3": "Loading Sounds …",
    "gui.loader.message4": "Loading Extensions …",
    "gui.loader.message5": "Herding Mind+ …",
    "gui.loader.message6": "Transmitting Nanos …",
    "gui.loader.message7": "Inflating Gobos …",
    "gui.loader.message8": "Preparing emojis …",
    "gui.loader.headline": "Loading Project",
    "gui.cameraModal.cameraModalTitle": "Take a Photo",
    "gui.cameraModal.loadingCameraMessage": "Loading Camera...",
    "gui.cameraModal.permissionRequest": "We need your permission to use your camera",
    "gui.cameraModal.retakePhoto": "Retake a Photo",
    "gui.cameraModal.save": "Desar",
    "gui.cameraModal.takePhoto": "Take Photo",
    "gui.cameraModal.loadingCaption": "Loading...",
    "gui.cameraModal.enableCameraCaption": "Enable Camera",
    "gui.recordModal.title": "Record Sound",
    "gui.recordStep.recordByClickBtn": "Begin recording by clicking the button below",
    "gui.recordStep.permissionMicrophone": "We need your permission to use your microphone",
    "gui.recordStep.record": "Record",
    "gui.recordStep.stopRecord": "Stop recording",
    "gui.playbackStep.stopMsg": "Stop",
    "gui.playbackStep.playMsg": "Play",
    "gui.playbackStep.loadingMsg": "Loading...",
    "gui.playbackStep.saveMsg": "Save",
    "gui.playbackStep.reRecordMsg": "Re-record",
    "gui.webglModal.label": "Your Browser Does Not Support WebGL",
    "gui.webglModal.descriptionMind": "Unfortunately your computer is {webGlLink}. Mind+ needs to run WebGL, please try {restartMindplus}（{clickToRestart}）, if the problem persists {updateGpuDriver}",
    "gui.webglModal.restartMindplus": "Restart Mind+",
    "gui.webglModal.clickToRestart": "Click here to restart",
    "gui.webglModal.webgllink": "Does not support WebGL",
    "gui.webglModal.updateGpuDriver": "Please try to update the graphics card driver",
    "gui.webglModal.ok": "Ok",
    "gui.extension.scratchExampleLib": "Scratch Example Library",
    "gui.extension.pythonExampleLib": "Python Example Library",
    "gui.extension.arduinoExampleLib": "Arduino Example Library",
    "gui.prompt.cancel": "Cancel",
    "gui.prompt.ok": "OK",
    "gui.extension.makeBlock": "Crear bloc",
    "gui.customProcedures.addAnInputNumberText": "Add an input",
    "gui.customProcedures.addAnInputBoolean": "Add an input",
    "gui.customProcedures.numberTextType": "nombre o text",
    "gui.customProcedures.textType": "text",
    "gui.customProcedures.numberType": "number",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Add a label",
    "gui.customProcedures.runWithoutScreenRefresh": "Run without screen refresh",
    "gui.customProcedures.cancel": "Cancel",
    "gui.customProcedures.ok": "OK",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "Vortex educational robot from DFRobot",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "Explorer D1 educational robot from DFRobot",
    "gui.extension.arduinounoR3.name": "ArduinounoR3",
    "gui.extension.arduinounoR3.description": " Wuhan Maker Course custom Arduino Uno main control board kit.",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "Max:bot Robot based on micro:bit",
    "gui.extension.max.name": "MAX",
    "gui.extension.max.description": "MAX（ROB0137）robot based on Arduino",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "Motor:bit motor and servo expansion board",
    "gui.extension.maqueen.name": "Maqueen",
    "gui.extension.maqueen.description": "micro:Maqueen robot based on micro:bit",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.microbit.description": "Connect your projects with the physical world.",
    "gui.extension.calliope.description": "Get creative and start coding!",
    "gui.extension.calliope.name": "CALLIOPE mini",
    "gui.extension.spread.name": "micro:bit & mPython expansion board",
    "gui.extension.spreadboson.name": "micro:bit & mPython expansion board",
    "gui.extension.microTouch.name": "TouchKey",
    "gui.extension.microNaturalScience.name": "micro:naturalScience",
    "gui.extension.microTouch.description": "Integrated 4×4 keyboard and motor servo drive expansion board",
    "gui.extension.microNaturalScience.description": "Micro:bit expansion board with multiple environmental sensors",
    "gui.extension.microNaturalScienceV2.description": "Micro:bit learning product integrating abundant natural environment related sensors",
    "gui.extension.microIoT.name": "micro:IoT",
    "gui.extension.microIoT.description": "Integrated display and Internet of things micro:bit extension board",
    "gui.extension.microIoTCloud.name": "micro:IoT Board for Cloud",
    "gui.extension.microIoTCloud.description": "The micro:bit expansion board supports WiFi card interconnection, providing an excellent solution for Internet of Things classroom teaching",
    "gui.extension.spreadMaqueen.name": "maqueen extension",
    "gui.extension.pinpongBread.name": "PinPong expansion board",
    "gui.extension.pinpongBread.description": "Expansion board for Python learning on ArduinoUNO",
    "gui.extension.spread.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadboson.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadmaqueen.description": "An entry-level minicar based on the control version",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "The advanced educational robot that supports both micro:bit and mPython",
    "gui.extension.iot.name": "Internet of Things",
    "gui.extension.iot.description": "Extended Edition Based on Internet of Things",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Device controlled by Leonardo",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Uno main control board module",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Device controlled by Arduino Nano",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "Main control board based on ESP32",
    "gui.extension.telloesp32.name": "RoboMaster TT(ESP32)",
    "gui.extension.telloesp32.description": "Educational drone with DJI top flight control algorithms, safe & stable",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Device controlled by Mega2560",
    "gui.extension.firebeetleesp32.name": "FireBeetle ESP32",
    "gui.extension.firebeetleesp32e.name": "FireBeetle ESP32-E",
    "gui.extension.firebeetleesp32.description": "Device controlled by FireBeetle ESP32",
    "gui.extension.dfr0299.name": "DFPlayer MP3 Module",
    "gui.extension.dfr0299.description": "MP3 Player Module",
    "gui.extension.ser0006.name": "Micro Servo",
    "gui.extension.ser0006.description": "Micro Servo",
    "gui.extension.dfr0523.name": "Peristaltic Pump",
    "gui.extension.dfr0523.description": "Deliver fluid by squeezing and releasing the pump alternately",
    "gui.extension.dfr0017.name": "Relay Module",
    "gui.extension.dfr0017.description": "Control high current device",
    "gui.extension.steppermotor.name": "Stepper Motor",
    "gui.extension.steppermotor.description": "Realize accurate position control by pulse control",
    "gui.extension.dfr0534.name": "Serial MP3 module",
    "gui.extension.dfr0534.description": "Serial MP3 voice module",
    "gui.extension.servo360.name": "360° Micro Servo",
    "gui.extension.servo360.description": "Control speed and direction",
    "gui.extension.tel0118.name": "OBLOQ IoT Module ",
    "gui.extension.tel0118.description": "A Wi-Fi to Serial device",
    "gui.extension.dfr0095.name": "IR Transmitter Module",
    "gui.extension.dfr0095.description": "Transmit standard 38KHz infrared signal",
    "gui.extension.dfr0094.name": "IR Receiver Module",
    "gui.extension.dfr0094.description": "Receive standard 38KHz infrared signal",
    "gui.extension.midi.name": "MIDI",
    "gui.extension.midi.description": "MIDI player",
    "gui.extension.radio.name": "Wireless broadcasting",
    "gui.extension.radio.description": "Wireless broadcast communication between multiple control boards",
    "gui.extension.tel0094.name": "GPS signal receiving module",
    "gui.extension.tel0094.description": "GPS signal receiving module",
    "gui.extension.tel0026.name": "Bluetooth module",
    "gui.extension.tel0026.description": "Bluetooth serial port module",
    "gui.extension.dfr0486.name": "OLED-12864 Display",
    "gui.extension.dfr0647.name": "OLED-12832 Display",
    "gui.extension.dfr0486.description": "I2C OLED-12864 Display Module",
    "gui.extension.dfr0647.description": "I2C OLED-12832 Display Module",
    "gui.extension.fit0352.name": "WS2812 RGB LED Strip",
    "gui.extension.fit0352.description": "Control WS2812-based LED strip modules",
    "gui.extension.dfr0063.name": "LCD1602 Display",
    "gui.extension.dfr0063.description": "LCD module which can show 2 lines and 16 characters in each line",
    "gui.extension.dfr0021.name": "Digital LED Light Module",
    "gui.extension.dfr0021.description": "White, red, green and blue LED modules",
    "gui.extension.tm1650.name": "TM1650 Four digit tube",
    "gui.extension.tm1650.description": "Four digit tube",
    "gui.extension.matrix8_8.name": "MAX7219 8x8 dot matrix",
    "gui.extension.matrix8_8.description": "8x8 lattice module",
    "gui.extension.dfr0522.name": "8x16 RGB LED Matrix Panel",
    "gui.extension.dfr0522.description": "Display images, numbers... support scroll display and user-defined setting",
    "gui.extension.music.name": "Music",
    "gui.extension.music.description": "Play instruments and drums.",
    "gui.extension.pen.name": "Pen",
    "gui.extension.pen.description": "Draw with your sprites.",
    "gui.extension.video-sensing.name": "Video Sensing",
    "gui.extension.video-sensing.description": "Sense motion with the camera.",
    "gui.extension.google-translate.name": "Translate",
    "gui.extension.google-translate.description": "Translate text into many languages.",
    "gui.extension.bd-translate.name": "Baidu Translate",
    "gui.extension.bd-translate.description": "Use Baidu translation service to translate text into other languages.",
    "gui.extension.text-speech.name": "Text to Speech",
    "gui.extension.text-speech.description": "Make your projects talk",
    "gui.extension.softSerialport.name": "Software Serial Port",
    "gui.extension.softSerialport.description": "Use I/O port to simulate serial communication function",
    "gui.extension.IICScan.name": "I2C Scan",
    "gui.extension.IICScan.description": "Scan all device addresses that connected to I2C interface",
    "gui.extension.bluetooth.name": "Bluetooth",
    "gui.extension.bluetooth.description": "Bluetooth device",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "GoBLE APP can help youcontrol main control board via smart phone",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "Use it to read or write and save data with eeprom",
    "gui.extension.interrupt.name": "Interrupt",
    "gui.extension.interrupt.description": "Interrupt pin",
    "gui.extension.serialPort.description": "Conveniently realize the function of sending multiple sets of data in upload mode, and receiving data through serial port in real-time mode",
    "gui.extension.mpyfile.name": "file",
    "gui.extension.mpyfile.description": "Read and write files in the control panel, store data and export",
    "gui.extension.sdcard.name": "SD card reader module",
    "gui.extension.sdcard.description": "MicroSD card reader module",
    "gui.extension.multithread.name": "MultiThread",
    "gui.extension.multithread.description": "Allow multiple programs and the main program to run simultaneously",
    "gui.extension.speech.name": "Speech",
    "gui.extension.speech.description": "Talk to your projects.",
    "gui.extension.sen0001.name": "Ultrasonic Sensor",
    "gui.extension.bos0001.name": "Rotation Sensor",
    "gui.extension.bos0002.name": "Push Buttonr",
    "gui.extension.bos0003.name": "Self-Locking Switch",
    "gui.extension.bos0004.name": "Light Sensor",
    "gui.extension.bos0006.name": "Steam Sensor",
    "gui.extension.bos0007.name": "Flame Sensor",
    "gui.extension.bos0008.name": "Touch Sensor",
    "gui.extension.bos0009.name": "Sound Sensor",
    "gui.extension.bos0010.name": "Infrared Proximity Sensor",
    "gui.extension.bos0012.name": "Conductivity Switch",
    "gui.extension.bos0013.name": "Motion Sensor",
    "gui.extension.bos0038.name": "Soil Moisture Sensor",
    "gui.extension.bos0045.name": "Ultrasonic Distance Sensor",
    "gui.extension.bos0016.name": "Ultra-Bright LED",
    "gui.extension.bos0017.name": "LED Module",
    "gui.extension.bos0019.name": "LED String Light",
    "gui.extension.bos0021.name": "Fan Module",
    "gui.extension.bos0022.name": "Voice Recorder Module",
    "gui.extension.bos0001.description": "Analog sensor, can detect rotation position",
    "gui.extension.bos0002.description": "Press to output high level, release to output low level",
    "gui.extension.bos0003.description": "Output high level in pressed state, low level in pop-up state",
    "gui.extension.bos0004.description": "Detection of ambient light intensity",
    "gui.extension.bos0006.description": "Detect rain and fog, not immersed in water",
    "gui.extension.bos0007.description": "Detect fire source or light source with wavelength of 760~1100nm",
    "gui.extension.bos0008.description": "Touch switch, can sense human body, metal, etc",
    "gui.extension.bos0009.description": "Detect sound intensity",
    "gui.extension.bos0010.description": "Detect signals generated by different colors",
    "gui.extension.bos0012.description": "Check whether the object is conductive",
    "gui.extension.bos0013.description": "Detect infrared rays emitted by moving people or animals",
    "gui.extension.bos0038.description": "Detect soil moisture, the less moisture the smaller the output value",
    "gui.extension.bos0045.description": "Analog distance sensor, detection range 2cm~1m",
    "gui.extension.bos0016.description": "White light emitting module",
    "gui.extension.bos0017.description": "Light-emitting diodes can emit light corresponding to the color of the lamp beads",
    "gui.extension.bos0019.description": "Colorful light strip, the length of the light strip is 3 meters, the color cannot be changed",
    "gui.extension.bos0021.description": "Module for driving fan blade rotation",
    "gui.extension.bos0022.description": "Can record a 10s sound and play recording",
    "gui.extension.sen0001.description": "Accurate distance detection with the range 2~800cm, compatible with urm and SR04 ultrasonic",
    "gui.extension.dfr0023.name": "Analog LM35 Temperature Sensor",
    "gui.extension.dfr0023.description": "LM35-based semiconductor temperature sensor with the range 0~100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22 Temperature and Humidity Sensor",
    "gui.extension.dhtTHSensor.description": "Detect enviroment temperature and humidity",
    "gui.extension.dsTSensor.name": "DS18B20 Temperature Sensor",
    "gui.extension.dsTSensor.description": "Detect ambient temperature with large range of -55~+125℃ ",
    "gui.extension.sen0203.name": "Heart Rate Monitor Sensor",
    "gui.extension.sen0203.description": "Mini heart rate sensor with analog pulse and digital square wave output modes",
    "gui.extension.sen0177.name": "Laser PM2.5 Air Quality Sensor",
    "gui.extension.sen0177.description": "Measure PM1, PM2.5 and PM10. Transfering data via serial port",
    "gui.extension.sen0014.name": "GP2Y0A21 IR Distance Sensor",
    "gui.extension.sen0014.description": "IR sensor based on GP2Y0A21 with 10~80cm measuring range",
    "gui.extension.sen0204.name": "Non-contact Liquid Level Sensor",
    "gui.extension.sen0204.description": "Detect liquid level but free from any contact",
    "gui.extension.sen0160.name": "Hx711 weight sensor",
    "gui.extension.sen0160.description": "Measure the Hx711 weight sensor",
    "gui.extension.sen0161.name": "Analog pH Meter",
    "gui.extension.sen0161.description": "Measure the liquid pH value with 5V power supply",
    "gui.extension.sen0161-v2.name": "Analog pH Meter(V2)",
    "gui.extension.sen0161-v2.description": "Measure the liquid pH calue with 3.3~5.5V power supply ",
    "gui.extension.sen0244.name": "Analog TDS Sensor",
    "gui.extension.sen0244.description": "Measure the TDS(Total Dissolved Solids) of liquid which suggests the cleanliness of water",
    "gui.extension.sen0165.name": "Analog ORP Meter",
    "gui.extension.sen0165.description": "Measure the ORP(Oxidation-Reduction Potential) of liquid to test the water quality",
    "gui.extension.sen0237.name": "Analog Dissolved Oxygen Sensor",
    "gui.extension.sen0237.description": "Measure the DO(Dissolved Oxygen) of liquid to evaluate the water quality",
    "gui.extension.dfr0300-H.name": "Analog EC Meter",
    "gui.extension.dfr0300-H.description": "Measure the EC(Electrical Conductivity) of liquid to evaluate the water quality",
    "gui.extension.dfr0300.name": "Analog EC Meter(V2)",
    "gui.extension.dfr0300.description": "Measure the EC(Electrical Conductivity) of liquid to evaluate the water quality",
    "gui.extension.sen0170.name": "Anemometer",
    "gui.extension.sen0170.description": "Measure wind speed level and output voltage signal",
    "gui.extension.sen0226.name": "BMP280 Temperature Sensor",
    "gui.extension.sen0226.description": "BMP280 barometric pressure sensor for temperature and barometric measurement",
    "gui.extension.sen0228.name": "I2C VEML7700 Ambient Light Sensor",
    "gui.extension.sen0228.description": "A high accuracy ambient light digital 16-bit resolution sensor",
    "gui.extension.sen0236.name": "BME280 module",
    "gui.extension.sen0236.description": "Environmental sensors (temperature, humidity, air pressure)",
    "gui.extension.sen0517.name": "ICP10111 pressure sensor",
    "gui.extension.sen0517.description": "Accuracy ±1Pa, high precision and high stability air pressure sensor",
    "gui.extension.dfr0022.name": "Analog Grayscale Sensor",
    "gui.extension.dfr0022.description": "Detect light density and reflect analog voltage signal, whihch can be applied to line-tracking",
    "gui.extension.motucamera.name": "MU sensor board",
    "gui.extension.motucamera.description": "description for the 'MU sensor board' extension",
    "gui.extension.dfr0552.name": "12-bit DA conversion module",
    "gui.extension.dfr0552.description": "Convert the digital quantity to the corresponding dc voltage signal accurately (non-pwm)",
    "gui.extension.sen0117.name": "Speech synthesis module",
    "gui.extension.sen0117.description": "You just type in the Chinese and English characters and Numbers and it will read them",
    "gui.extension.dfr0576.name": "I2C cascade extender",
    "gui.extension.dfr0576.description": "Used to resolve I2C device address conflicts",
    "gui.extension.sen0248.name": "BME680 environmental sensor",
    "gui.extension.sen0248.description": "VOC (volatile organic compounds), temperature, humidity and air pressure can be measured",
    "gui.extension.sen0304.name": "I2C ultrasonic ranging sensor",
    "gui.extension.sen0304.description": "Ultrasonic sensors designed for rapid ranging or obstacle avoidance applications",
    "gui.extension.sen0307.name": "Analog ultrasonic ranging sensor",
    "gui.extension.sen0307.description": "Open dual probe analog ultrasonic ranging module",
    "gui.extension.sen0250.name": "BMI160 6 axis inertial motion sensor",
    "gui.extension.sen0250.description": "16-bit 3-axis acceleration + ultra-low power consumption 3-axis gyroscope",
    "gui.extension.sen0224.name": "I2C LIS2DH triaxial accelerometer",
    "gui.extension.sen0224.description": "Ultra-low power consumption 3 axis acceleration sensor",
    "gui.extension.dfr0151.name": "Real Time Clock DS1307",
    "gui.extension.dfr0151.description": "the time error is only about 1 second in 24 hours",
    "gui.extension.dfr0469.name": "Real Time Clock SD2405",
    "gui.extension.dfr0469.description": "An accurate I2C real-time clock (RTC) with crystal compensation, inner chargeable battery. ",
    "gui.extension.dfr0126.name": "Spectrum Analysis Module",
    "gui.extension.dfr0126.description": "Keep track of the sound frequency",
    "gui.extension.dfr0231.name": "NFC Module",
    "gui.extension.dfr0231.description": "Suitable for short distance wireless communication",
    "gui.extension.sen0245.name": "VL53L0X Laser Ranging Sensor",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "Analog Ambient Light Sensor",
    "gui.extension.dfr0026.description": "Detect the ambient light density",
    "gui.extension.dfr0027.name": "Digital Vibration Sensor",
    "gui.extension.dfr0027.description": "Dectect vibration signal and output digital signal",
    "gui.extension.dfr0028.name": "Digital Tilt Sensor",
    "gui.extension.dfr0028.description": "Based on the number of mercury switches, it can be used as a simple tilt sensor",
    "gui.extension.dfr0029.name": "Digital Push Button",
    "gui.extension.dfr0029.description": "Push down:high level  Release: low level",
    "gui.extension.dfr0030.name": "Digital Capacitive Touch Sensor",
    "gui.extension.dfr0030.description": "Capacitive touch switch module for sensing metal and human body",
    "gui.extension.sen0318.name": "CCS811 Air Quality Sensor",
    "gui.extension.sen0318.description": "CCS811 Measurable CO2, TVOC",
    "gui.extension.sen0315.name": "PAJ7620U2 Gesture Sensor",
    "gui.extension.sen0315.description": "IIC communication, in the range of up to 20cm, can recognize up to 13 gestures",
    "gui.extension.sen0497.name": "Temperature&Humidity Sensor",
    "gui.extension.sen0497.description": "A new generation of temperature and humidity sensor for I2C communication, an upgraded product of DHT11",
    "gui.extension.sen0514.name": "ENS160 air quality sensor",
    "gui.extension.sen0514.description": "Measures TVOC (Total Volatile Organic Compounds), eCO2 (Relative Carbon Dioxide), AQI (Air Quality Index)",
    "gui.extension.sen0518.name": " MAX30102 Heart rate blood oxygen sensor",
    "gui.extension.sen0518.description": "Measure human resting heart rate and blood oxygen saturation",
    "gui.extension.sen0376.name": "Alcohol Sensor",
    "gui.extension.sen0376.description": "Factory calibration, no calibration required,0-5ppm",
    "gui.extension.sen0321.name": "zone Sensor",
    "gui.extension.sen0321.description": "Measurement of ozone concentration in the environment",
    "gui.extension.sen0364.name": "Visible Spectrum Sensor",
    "gui.extension.sen0364.description": "Measure the visible spectrum and identify the spectral color",
    "gui.extension.sen0359.name": "Capacitive Fingerprint Sensor",
    "gui.extension.sen0359.description": "Capacitive Fingerprint Sensor",
    "gui.extension.kit0176.name": "I2C Weight Sensor",
    "gui.extension.kit0176.description": "The weight of the object can be measured, the range is 1Kg",
    "gui.extension.dfr0033.name": "Digital Magnetic Sensor",
    "gui.extension.dfr0033.description": "Detect magnetic materials within 3cm",
    "gui.extension.dfr0034.name": "Analog Sound Sensor",
    "gui.extension.dfr0034.description": "Detect the loudness in ambient",
    "gui.extension.sen0132.name": "Analog Carbon Monoxide Sensor",
    "gui.extension.sen0132.description": "Detect CO-gas concentrations within 20 to 2000ppm",
    "gui.extension.dfr0051.name": "Analog Voltage Divider",
    "gui.extension.dfr0051.description": "Detect voltage(DC) from 0.0245V to 25V",
    "gui.extension.dfr0052.name": "Analog Piezo Disk Vibration Sensor",
    "gui.extension.dfr0052.description": "To detect dynamic and Quasi-static stress and output analog signal",
    "gui.extension.dfr0058.name": "Analog Rotation Potentiometer Sensor",
    "gui.extension.dfr0058.description": "High-accuracy potentiometer with maximum rotation count of 10",
    "gui.extension.dfr0061.name": "Joystick Module",
    "gui.extension.dfr0061.description": "(X,Y) analog output, (Z) 1 digital output and it can be used as romote controller",
    "gui.extension.dfr0072.name": "Shiftout Sensor",
    "gui.extension.dfr0072.description": "description for the 'Shiftout sensor' extension",
    "gui.extension.dfr0563.name": "battery Sensor",
    "gui.extension.dfr0563.description": "description for the 'battery sensor' extension",
    "gui.extension.dfr0553.name": "16-bit AD conversion module",
    "gui.extension.dfr0553.description": "description for the '16-bit AD conversion module' extension",
    "gui.extension.dfr0588.name": "Temperature and humidity sensor",
    "gui.extension.dfr0588.description": "Temperature and humidity sensor",
    "gui.extension.dfr0076.name": "Analog Flame Sensor",
    "gui.extension.dfr0076.description": "Detect flame or light with a wavelength of 760nm-1100nm",
    "gui.extension.dfr0117.name": "Eeprom iic Sensor",
    "gui.extension.dfr0117.description": "Data storage module,32kb",
    "gui.extension.dfr0143.name": "Triple Axis Accelerometer MMA7361",
    "gui.extension.dfr0143.description": "Based on MMA7361 chip, detect gesture and movement direction",
    "gui.extension.sen0018.name": "Digital Infrared Motion Sensor",
    "gui.extension.sen0018.description": "Detect infrared released forom moving human or animal",
    "gui.extension.sen0114.name": "Analog Soil Moisture Sensor",
    "gui.extension.sen0114.description": "Read the amount of moisture present in the soil surrounding it. ",
    "gui.extension.sen0121.name": "Steam Sensor",
    "gui.extension.sen0121.description": "Detect rainwater,steam and water mist",
    "gui.extension.sen0212.name": "Color Identification Sensor",
    "gui.extension.sen0212.description": "Identify objects colors and output RGB value",
    "gui.extension.sen0253.name": "10DOF Attitude Sensor",
    "gui.extension.sen0253.description": "BBNO055 is a new sensor IC for implementing an intelligent 9-axis absolute orientation sensor.",
    "gui.extension.sen0290.name": "lightning sensor",
    "gui.extension.sen0290.description": "intelligent lightning sensor",
    "gui.extension.sen0291.name": "I2C digital power meter",
    "gui.extension.huskylens.name": "HUSKYLENS AI Camera",
    "gui.extension.huskylens.description": "Artificial intelligence vision sensor that supports face recognition and learning",
    "gui.extension.ps2.name": "PS2 handle",
    "gui.extension.ps2.description": "PS2 handle",
    "gui.extension.sen0291.description": "High precision measurement of voltage, current and power",
    "gui.extension.sen0202.name": "3D Mini Gesture Sensor",
    "gui.extension.sen0202.description": "Detect clockwise and counterclockwise rotation and movement directions",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "Micro:bit dedicated lithium battery motor expansion board",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "Enable the device connect to the Wi-Fi network",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "Enable the device get local time",
    "gui.extension.udp.name": "UDP broadcast",
    "gui.extension.udp.description": "Let devices and devices in the local area network realize multi-machine broadcast communication through UDP protocol",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "Let the device request information via HTTP",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "Let devices communicate using the MQTT protocol",
    "gui.extension.internet.name": "Internet",
    "gui.extension.internet.description": "Internet communication",
    "gui.extension.speechRecognition.name": "Speech Recognition",
    "gui.extension.speechRecognition.description": "Speech Recognition",
    "gui.extension.pictureai.defaultTitleText": "Video Window",
    "gui.extension.pictureai.cameraPermissionsTitle": "需要相机权限",
    "gui.extension.pictureai.cameraPermissionsMessage": "请前往设置中勾选授权。如已授权但仍无法使用，请确认摄像头已正常连接无其它软件占用，或重启软件。",
    "gui.extension.pictureai.cameraPermissionsCancel": "取消",
    "gui.extension.pictureai.cameraPermissionsGoToSettings": "前往设置",
    "gui.gui.loaded": "Loaded",
    "gui.gui.notLoaded": "NOT Loaded",
    "gui.gui.cantFindYouWant": "Can't find what you want?",
    "gui.gui.clickHere": "Click here",
    "gui.gui.viewHelp": " to find more",
    "gui.gui.uploadToDev": "Upload",
    "gui.gui.codeArea": "Code",
    "gui.gui.fileSys": "file system",
    "gui.gui.compileAndUpload": "Compiled and upload",
    "gui.gui.compileOnly": "Compile",
    "gui.gui.compileOpen": "Compiled and Open",
    "gui.gui.burnBootloader": "Burn wireless bootloader",
    "gui.gui.arduinoRun": "Executar",
    "gui.gui.arduinoSave": "Desar",
    "gui.gui.autoGenerated": "Generar automàticament",
    "gui.gui.manualEditing": "Edició manual",
    "gui.gui.codeBox": "Code Box",
    "gui.gui.moduleCode": "Module Code",
    "gui.menu.edit.undo": "Desfer",
    "gui.menu.edit.redo": "Refer",
    "gui.menu.edit.selectAll": "seleccionat tot",
    "gui.menu.edit.cut": "Tallar",
    "gui.menu.edit.copy": "Copiar",
    "gui.menu.edit.paste": "Enganxar",
    "gui.menu.edit.codeStyle": "Estil de codi",
    "gui.menu.edit.fontSize": "Mida de la lletra",
    "gui.menu.edit.clearCache": "Clear Cache",
    "gui.menu.edit.maxReset": "Restore Max to factory defaults",
    "gui.gui.serial": "Serial",
    "gui.gui.openSerial": "Open Serial",
    "gui.gui.closeSerial": "Close Serial",
    "gui.gui.close": "Close",
    "gui.gui.open": "Open",
    "gui.gui.clearOutput": "Clear Output",
    "gui.gui.scroll": "Scroll",
    "gui.gui.send": "Enviar",
    "gui.gui.baud": "Baud",
    "gui.gui.noLineEnd": "No Line terminators",
    "gui.gui.newLine": "Line Feed",
    "gui.gui.carriageReturn": "Carriage Return",
    "gui.gui.bothNLandCR": "LF & CR",
    "gui.gui.unavailableHexData": "Invalid hex form! Please represent hex data with two characters ((0-9/A-F) and a blank, e.g. \"00 01 A2 FF\".",
    "gui.gui.hexForm": "Hex Form",
    "gui.gui.openHexForm": "Use hex form to show and send data",
    "gui.gui.closeHexForm": "Close",
    "gui.progress.burning": "Burning",
    "gui.progress.burning1": "Burning",
    "gui.progress.compiling": "Compiling",
    "gui.burner.compileError": "Compile error",
    "gui.burner.linkError": "Link error",
    "gui.burner.generateHexError": "Generate .hex error",
    "gui.burner.generateBinError": "Generate .bin error",
    "gui.burner.burnError": "Burn error",
    "gui.burner.eraseError": "Erase error",
    "gui.burner.findLeonardoPortError": "Cannot find bootlaoder port of Leonardo",
    "gui.burner.noMicrobit": "Sorry it is not micro: bit, please connect micro: bit",
    "gui.burner.swdCfgError": "Cannot write to cmsis-dap.cfg",
    "gui.burner.UDiskPopUp": "UDisk pops up, please wait for a while and try again",
    "gui.burner.UDiskWriteError": "Write to UDisk error",
    "gui.burner.openPortError": "Could not open port: Access is denied",
    "gui.burner.firmwareError": "Firmware error, please restore and re-connect",
    "gui.burner.uploadDone": "Upload done",
    "gui.burner.connectPort": "Connecting",
    "gui.burner.burnFirmware": "Burn firmware",
    "gui.burner.writeLibFiles": "Writing library files",
    "gui.burner.connectError": "Connect error, please re-connect",
    "gui.burner.printObjectTooBig": "The project is too large; please click the \"help\" in the right top to reduce its size. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooBig": "Not enough memory; please click the \"help\" in the right top to reduce memory occupancy. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Low memory available, stability problems may occur. ",
    "gui.burner.printSizeError": "Couldn't determine program size. ",
    "gui.burner.printSize": "The project uses [%TEXTSIZE] bytes, occupies ([%TEXTSIZEPRE]) program memory space, leaving [%TEXTSIZESUR] bytes, up to [%TEXTSIZEMAX] bytes.\nGlobal variables use [%DATASIZE] bytes, ([%DATASIZEPRE]) of dynamic memory, leaving [%DATASIZESUR] byte local variables, up to [%DATASIZEMAX] bytes. ",
    "gui.progress.compileSuccess": "compile success",
    "gui.progress.compileProgress": "Compile Progress",
    "gui.progress.uploadProgress": "Upload Progress",
    "gui.progress.uploadSuccess": "upload success",
    "gui.progress.uploadSuccessVm": "upload success",
    "gui.progress.networkInterfaceCard": "NIC",
    "gui.codeLabel.dynamicVariables": "Dynamic variables",
    "gui.codeLabel.functionDeclaration": "Function declaration",
    "gui.codeLabel.staticConstants": "Static constants",
    "gui.codeLabel.createObject": "Create an object",
    "gui.codeLabel.mainProgramStart": "Main program start",
    "gui.codeLabel.SubthreadProgramStarts": "Subthread %1 program starts",
    "gui.codeLabel.customFunction": "Custom function",
    "gui.codeLabel.eventCallbackFunction": "Event callback function",
    "gui.codeLabel.globalCode": "Global variables",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "No variables allowed!",
    "gui.codeLabel.staticFunctions": "Static functions",
    "gui.progress.errMsg": "!Upload error, please check the details at the black window on the lower right",
    "gui.progress.errMsg1": "!Upload error, please check the details at the black window on the lower right",
    "gui.progress.errMsg2": "!Upload error, please check the details at the black window on the lower right",
    "gui.mainHeader.err": "!Error: The same program headers [%1] cannot be used at the same time. Please delete the duplicated block.",
    "gui.threadHeader.err1": "!Error: The program headers  [%1] cannot be used at the same time. They belong to different kits or boards.",
    "gui.threadHeader.err2": "!Error Tip: The same event headers [%1] cannot be used at the same time. Please delete the duplicated block.",
    "gui.howtos.spin-around.name": "Spin Around",
    "gui.howtos.spin.step_dragTurn": "Drag out a [turn] block",
    "gui.howtos.spin.step_clickTurn": "Click the block to run",
    "gui.howtos.spin.step_clickControl": "Click the [Control] category",
    "gui.howtos.spin.step_dragForever": "Drag out a [forever] block",
    "gui.howtos.spin.step_clickForever": "Click the block to run",
    "gui.howtos.spin.step_changeColor": "Add a [change color effect] block",
    "gui.howtos.say-hello.name": "Say Hello",
    "gui.howtos.say-hello.step_addSprite": "Add a new sprite",
    "gui.howtos.say-hello.step_clickLooks": "Click the [Looks] category",
    "gui.howtos.say-hello.step_dragSay": "Drag out a [say] block",
    "gui.howtos.say-hello.step_clickSay": "Click the block to run",
    "gui.howtos.say-hello.step_anotherSay": "Drag out another [say] block",
    "gui.howtos.say-hello.step_editSay": "Say something else",
    "gui.howtos.say-hello.step_clickStack": "Click the block to run",
    "gui.howtos.run-away.name": "Run Away",
    "gui.howtos.run-away.step_dragGoTo": "Drag out a [go to random position] block",
    "gui.howtos.run-away.step_clickGoTo": "Click the block to run",
    "gui.howtos.run-away.step3": "Click the [Events] category",
    "gui.howtos.run-away.step_addWhenClicked": "Add a “when this sprite clicked” block",
    "gui.howtos.run-away.step_clickSprite": "Click the sprite to run",
    "gui.howtos.run-away.step_addSound": "Add a “start sound” block",
    "gui.howtos.pick-up-apple.name": "Game of Catching Apples",
    "gui.howtos.pick-up-apple.step_1": "Switch to Scratch",
    "gui.howtos.pick-up-apple.step_2": "Switch hardware",
    "gui.howtos.pick-up-apple.step_3": "Connect device",
    "gui.howtos.pick-up-apple.step_4": "Compass calibration",
    "gui.howtos.pick-up-apple.step_5": "Open Scratch example programs",
    "gui.howtos.pick-up-apple.step_6": "Game of Catching Apples",
    "gui.howtos.move-left-right.name": "micro:bit and Meow",
    "gui.howtos.move-left-right.step_1": "Creat a new project",
    "gui.howtos.move-left-right.step_2": "Drag block1",
    "gui.howtos.move-left-right.step_3": "Drag block2",
    "gui.howtos.move-left-right.step_4": "Move left and right",
    "gui.howtos.install-driver.name": "Install Driver",
    "gui.howtos.transform-expression.name": "Smile or Cry",
    "gui.howtos.transform-expression.step_1": "Switch to Code",
    "gui.howtos.transform-expression.step_2": "Switch hardware",
    "gui.howtos.transform-expression.step_3": "Drag block1",
    "gui.howtos.transform-expression.step_4": "Drag block2",
    "gui.howtos.transform-expression.step_5": "Drag block3",
    "gui.howtos.transform-expression.step_6": "Transform to smile or cry",
    "gui.howtos.lot.name": "IOT (Internet of Things)",
    "gui.howtos.lot.step_1": "Select example programs",
    "gui.howtos.lot.step_2": "Burning program",
    "gui.howtos.lot.step_3": "Feature diagram",
    "gui.howtos.touch-pin.name": "Pin touch",
    "gui.howtos.touch-pin.step_1": "Switch to Code",
    "gui.howtos.touch-pin.step_2": "Select the routine",
    "gui.howtos.touch-pin.step_3": "Burning program",
    "gui.howtos.touch-pin.step_4": "Pin touch",
    "gui.howtos.save-witch.name": "Rescue Witch",
    "gui.howtos.save-witch.step_1": "Switch to Scratch",
    "gui.howtos.save-witch.step_2": "Select 'micro:bit'in expansion",
    "gui.howtos.save-witch.step_3": "Select 'Rescue Witch' in example programs",
    "gui.howtos.save-witch.step_4": "Connect the device, select micro:bit and wait for a successful connection",
    "gui.howtos.save-witch.step_5": "Rotate micro:bit all around until the LED turns off when the note [calibrate the device] pops up.",
    "gui.howtos.save-witch.step_6": "Click the green flag and swing your arm to interact with the animation",
    "gui.howtos.microbit-touch.name": "Press to Smile or Cry",
    "gui.howtos.microbit-touch.step_1": "Switch to Code mode",
    "gui.howtos.microbit-touch.step_2": "Click 'Learning' to open the example programs and select 'Pin touch'",
    "gui.howtos.microbit-touch.step_3": "Connect the corresponding COM port of the device and click 'Upload to Device'",
    "gui.howtos.microbit-touch.step_4": "Press different keys to show different expressions",
    "gui.howtos.microbit-calibration.name": "micro:bit Calibration",
    "gui.howtos.calliope-calibration.name": "calliope Calibration",
    "gui.howtos.microbit-calibration.step_1": "Rotate the micro:bit board i, and the onboard LED lights will light up in turn.",
    "gui.howtos.microbit-calibration.step_2": "Until the outermost LED lights are all on (as shown below), a smile will be displayed after the calibration is completed.",
    "gui.extension.sen0251.name": "BMP388 Barometric Pressure Sensor",
    "gui.extension.sen0251.description": "Temperature, atmospheric pressure, altitude detection function",
    "gui.extension.sen0206.name": "Non-contact infrared temperature sensor",
    "gui.extension.sen0206.description": "Far-infrared non-contact measurement of object or ambient temperature",
    "gui.gui.recentlyEdited": "Editats recentment",
    "gui.extension.weather.name": "Get the Weather",
    "gui.extension.weather.description": "Here a Wi-Fi module is needed to get weather information",
    "gui.extension.weather.description.javascript": "To obtain weather information through the Internet, the computer needs to be connected to the Internet",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "Operate the TinyWebDB network database, can be used with APP Inventor",
    "gui.extension.pictureai.name": "AI Image recognition",
    "gui.extension.pictureai.description": "do something wonderful with picture AI, need connected to the Internet",
    "gui.gui.variableScopeOptionAllSprites": "Per a tots els sprites",
    "gui.gui.variableScopeOptionSpriteOnly": "Només per aquest sprite",
    "gui.gui.variablePromptAllSpritesMessage": "Aquesta variable estarà disponible per a tots els objectes",
    "gui.monitor.contextMenu.default": "Normal Readout",
    "gui.monitor.contextMenu.large": "Large Readout",
    "gui.monitor.contextMenu.slider": "Slider",
    "gui.monitor.contextMenu.sliderRange": "change slider range",
    "gui.monitor.contextMenu.import": "Importar",
    "gui.monitor.contextMenu.export": "Exportar",
    "gui.monitor.listMonitor.listLength": "length {length}",
    "gui.monitor.listMonitor.empty": "(empty)",
    "gui.costumeTab.backdrop": "Fons",
    "gui.costumeTab.costume": "Costume",
    "gui.opcodeLabels.direction": "direction",
    "gui.opcodeLabels.xposition": "x position",
    "gui.opcodeLabels.yposition": "y position",
    "gui.opcodeLabels.size": "size",
    "gui.opcodeLabels.costumename": "costume name",
    "gui.opcodeLabels.costumenumber": "costume number",
    "gui.opcodeLabels.backdropname": "backdrop name",
    "gui.opcodeLabels.backdropnumber": "backdrop number",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "answer",
    "gui.opcodeLabels.loudness": "loudness",
    "gui.opcodeLabels.year": "year",
    "gui.opcodeLabels.month": "month",
    "gui.opcodeLabels.date": "date",
    "gui.opcodeLabels.dayofweek": "day of week",
    "gui.opcodeLabels.hour": "hora",
    "gui.opcodeLabels.minute": "minut",
    "gui.opcodeLabels.second": "segon",
    "gui.opcodeLabels.timer": "timer",
    "gui.loadProject.error": "load project error",
    "gui.fileSystem.fileCatalogue": "Catalog",
    "gui.fileSystem.boardFiles": "Board Files",
    "gui.fileSystem.boardFiles2": "Board Files",
    "gui.fileSystem.boardFilesMaixduino": "Board Files",
    "gui.fileSystem.boardFilesBoson": "BOSON MC1",
    "gui.fileSystem.computerFiles": "Computer Files",
    "gui.fileSystem.userExFiles": "Use-Ex Files",
    "gui.fileSystem.newFile": "Nou arxiu",
    "gui.fileSystem.newFolder": "Nova carpeta",
    "gui.fileSystem.deleteFolder": "esborrar carpeta",
    "gui.fileSystem.deleteFile": "delete",
    "gui.fileSystem.rename": "rename",
    "gui.fileSystem.openFolder": "open folder position",
    "gui.fileSystem.openFilePos": "open file position",
    "gui.fileSystem.openInEditor": "open in editor",
    "gui.fileSystem.runRightNow": "run right now",
    "gui.fileSystem.stopRun": "stop run",
    "gui.fileSystem.setBoot": "boot run",
    "gui.fileSystem.deleteForever": "delete forever",
    "gui.fileSystem.saveAs": "save as",
    "gui.fileSystem.undoAllEdits": "cancel all edits",
    "gui.fileSystem.copyToComputer": "copy to computer",
    "gui.fileSystem.uploadToBoard": "upload to board",
    "gui.fileSystem.uploadAndRun": "upload and run",
    "gui.wifi.pwdNotLegal": "! Error: The password is in the wrong format. The Wi-Fi password must be 8-20 digits or numbers",
    "gui.spreadmaqueen.perror": "! Error:P12 pin led lamp is not available. Please select P8 pin led lamp.",
    "gui.gui.viewConflict": "view conflict",
    "gui.gui.clickViewHelp": "How to solve?",
    "gui.gui.conflict": "Conflict",
    "gui.gui.conflictPrompt": "Conflict warning prompt",
    "gui.gui.clickPrompt": "Click to highlight blocks, Double click to locate blocks",
    "gui.extension.mpyUART.name": "Serial UART",
    "gui.extension.mpyUART.description": "UART communication",
    "gui.extension.mpyI2C.name": "I2C communication",
    "gui.extension.mpyI2C.description": "Use software to simulate I2C protocol to transmit data",
    "gui.extension.mpyServo.name": "Servo module",
    "gui.extension.mpyServo.description": "Servo module",
    "gui.extension.mpyTimer.name": "Timer",
    "gui.extension.mpyTimer.description": "Can execute the program regularly",
    "gui.extension.mpyList.name": "List",
    "gui.extension.mpyList.description": "List",
    "gui.extension.mpyDictionary.name": "Dictionary",
    "gui.extension.mpyDictionary.description": "Dictionary",
    "gui.extension.mpySet.name": "Set",
    "gui.extension.mpySet.description": "Set",
    "gui.extension.mpyTuple.name": "Tuple",
    "gui.extension.mpyTuple.description": "Tuple",
    "gui.connection.auto-scanning.noPeripheralsFound": "No devices found",
    "gui.connection.auto-scanning.prescan": "Have your device nearby, then begin searching.",
    "gui.connection.auto-scanning.pressbutton": "Press the button on your device.",
    "gui.connection.auto-scanning.start-search": "Start Searching",
    "gui.connection.connecting-searchbutton": "Searching...",
    "gui.connection.auto-scanning.try-again": "Try again",
    "gui.connection.connected": "Connected",
    "gui.connection.disconnect": "Disconnect",
    "gui.connection.go-to-editor": "Go to Editor",
    "gui.connection.connecting-cancelbutton": "Connecting...",
    "gui.connection.error.errorMessage": "Oops, looks like something went wrong.",
    "gui.connection.error.tryagainbutton": "Try again",
    "gui.connection.error.helpbutton": "Help",
    "gui.connection.peripheral-name-label": "Device name",
    "gui.connection.connect": "Connect",
    "gui.connection.scanning.lookingforperipherals": "Looking for devices",
    "gui.connection.scanning.noPeripheralsFound": "No devices found",
    "gui.connection.scanning.instructions": "Select your device in the list above.",
    "gui.connection.scanning.instructionsForTello": "Please connect your computer WiFi to Tello or RMTT first, and then select the devices listed above. ",
    "gui.connection.connect.instructionsForTello": "Please connect Tello or RMTT in the computer WiFi list first, and then select the devices listed above.",
    "gui.connection.scanning.connectTelloHandle": "Select the device listed above, or manually connect to the aircraft Wi-Fi.",
    "gui.connection.search": "Refresh",
    "gui.connection.unavailable.installscratchlink": "Make sure you have Scratch Link installed and running",
    "gui.connection.unavailable.enablebluetooth": "Check that Bluetooth is enabled",
    "gui.connection.unavailable.tryagainbutton": "Try again",
    "gui.connection.unavailable.helpbutton": "Help",
    "gui.extension.ev3.description": "Build interactive robots and more.",
    "gui.extension.ev3.connectingMessage": "Connecting. Make sure the pin on your EV3 is set to 1234.",
    "gui.extension.boost.description": "Bring robotic creations to life.",
    "gui.extension.boost.connectingMessage": "Connecting",
    "gui.extension.wedo2.description": "Build with motors and sensors.",
    "gui.extension.wedo2.connectingMessage": "Connecting",
    "gui.extension.huskyLensPro.name": "HUSKYLENS EDU",
    "gui.extension.huskyLensPro.description": "Use HUSKYLENS to connect directly to a computer to learn the basics of AI",
    "gui.extension.huskyLensPro.connectingMessage": "huskyLens is trying to request a connection, please wait",
    "gui.extension.machinelearning.name": "machine learning(ML5)",
    "gui.extension.machinelearning.description": "based on machine learning algorithm, the application of artificial intelligence can be realized without network",
    "gui.extension.huskylens.pcsetting": "Configure computer camera",
    "gui.extension.huskylens.visualmodal": "AI visualization tools",
    "gui.extension.huskylens.KNNText": "Computer-side camera simulation KNN classification",
    "gui.extension.huskylens.huskylensbasic": "Basic Functions",
    "gui.extension.huskylens.algorithmOperationLabel": "Algorithmic Operation",
    "gui.extension.huskylens.displayFunctionLabel": "Display",
    "gui.extension.huskylens.cameraFunctionLabel": "Take Photos",
    "gui.extension.huskylens.modelOperationLabel": "Model Operation",
    "gui.huskyLensPro.toConnect": "Please connect HUSKYLENS EDU first",
    "gui.huskyLensPro.toClassificationData": "Please add classification data first",
    "gui.huskyLensPro.startDeployment": "Start deployment",
    "gui.huskyLensPro.switchingAlgorithmFailed": "Cannot switch to the \"object classification\" algorithm!",
    "gui.huskyLensPro.forgettingFailure": "Learning data cannot be forgotten!",
    "gui.huskyLensPro.failedToSetCategoryLabel": "Failed to set \"category label\"!",
    "gui.huskyLensPro.requestFailed": "Request \"Deploy\" failed!",
    "gui.huskyLensPro.deploymentFailed": "\"Deploy Data\" failed!",
    "gui.huskyLensPro.completed": "Completed",
    "gui.huskyLensPro.countdown": ", expected to be completed in [TIME]",
    "gui.huskyLensPro.minutes": " minutes ",
    "gui.huskyLensPro.seconds": " seconds",
    "gui.huskyLensPro.deploying": "Model is being deployed",
    "gui.huskyLensPro.disconnected": "The connection has been disconnected and the deployment is terminated. Please check the USB connection!",
    "gui.huskyLensPro.huskylenMaker": "This feature is only available in the educational version",
    "gui.huskyLensPro.updateVersion": "Please connect to the education version and the firmware version is not less than 0.5.1, see \"Help\" for details",
    "gui.huskyLensPro.failedRetry": "Failed, please try again!",
    "gui.connection.auto-scanning.notFoundHuskylensTip": "Connection timed out, please confirm that the machine is connected to HuskyLens",
    "gui.extension.tello.name": "RoboMaster TT(single)",
    "gui.extension.tello.description": "Educational drone with DJI top flight control algorithms, safe & stable",
    "gui.extension.tello.connectingMessage": "Connecting... Make sure tello in turned on.",
    "gui.extension.telloGroup.name": "RoboMaster TT(team)",
    "gui.extension.telloGroup.description": "Educational drone with DJI top flight control algorithms, safe & stable",
    "gui.connection.auto-scanning.notFoundTelloTip": "Scanning timed out, please make sure Tello is turned on",
    "gui.extension.AIVisualInterface.lable": "AI Visual Interface",
    "gui.extension.AIVisualInterface.title": "No Data",
    "gui.extension.AIVisualInterface.imgCount": "[COUNT] pictures",
    "gui.extension.AIVisualInterface.delete": "delete",
    "gui.extension.AIVisualInterface.classificationCount": "[COUNT] categories in total",
    "gui.extension.AIVisualInterface.recognitionResult": "The screen recognition result is:",
    "gui.extension.AIVisualInterface.possibility": "The possibilities are:",
    "gui.extension.AIVisualInterface.addClass": "Add classification",
    "gui.extension.AIVisualInterface.startTraining": "Start training",
    "gui.extension.AIVisualInterface.prediction": "Predict video pictures",
    "gui.extension.AIVisualInterface.retrain": "Retrain",
    "gui.extension.AIVisualInterface.export": "Export model",
    "gui.dialog.baiduServer.togglePersonalAccount": "The current number of available vocals has reached the upper limit, please switch to your personal account and log in to the console to check the quota",
    "gui.extension.ai.name": "AI",
    "gui.extension.AI.description": "Support model call and training",
    "gui.extension.machineVision.name": "Machine Vision",
    "gui.extension.machineVision.description": "Image processing, drawing, filtering, recognizing shape, color, dimension code, feature",
    "gui.extension.machineHearing.name": "Machine Listening",
    "gui.extension.machineHearing.description": "Speech recognition through training",
    "gui.extension.maixduino.name": "Maixduino",
    "gui.extension.maixduino.description": "Main control board based on K210 chip",
    "gui.extension.boson.name": "Boson MC1",
    "gui.extension.boson.description": "BOSON-based main control board",
    "gui.extension.pyTurtle.name": "Turtle Drawing",
    "gui.extension.pyTurtle.description": "There are thousands of brain holes, between the sizes",
    "gui.extension.pyGenerator.name": "Code Generator",
    "gui.extension.pyGenerator.description": "Write code directly on image blocks to achieve graphical and code mixing",
    "gui.extension.unihiker.name": "UNIHIKER",
    "gui.extension.unihiker.description": "A new generation mainboard for complete python learning",
    "gui.extension.python.save": "Save",
    "gui.extension.python.execute": "Run",
    "gui.extension.python.pipmanger": "Library Management",
    "gui.extension.python.filesystem": "File System",
    "gui.extension.python.fileinprogram": "File in the project",
    "gui.extension.python.fileinpc": "File in the computer",
    "gui.extension.python.terminal": "Terminal",
    "gui.extension.python.clearterminal": "Clear output",
    "gui.extension.python.tabcode": "Code",
    "gui.extension.python.piplist": "Library List",
    "gui.extension.python.commendatory": "Recommended library",
    "gui.extension.python.handleinstall": "PIP Mode",
    "gui.extension.python.selectall": "Select all [COUNT]",
    "gui.extension.python.update": "Update",
    "gui.extension.python.uninstall": "Uninstall",
    "gui.extension.python.install": "Install",
    "gui.extension.python.installing": "Installing...",
    "gui.extension.python.installed": "Installed",
    "gui.extension.python.piphandleinstall": "PIP manual installation",
    "gui.extension.python.loadmore": "Load more...",
    "gui.extension.python.hidemore": "Hide more...",
    "gui.extension.python.noavailable": "There is no library available, please download it first",
    "gui.extension.python.enterCommand": "Please enter the library name first",
    "gui.extension.python.finishedtips": "Command has finished running",
    "gui.extension.python.stop": "Stop",
    "gui.extension.python.executing": "Running",
    "gui.extension.python.editorArea": "Code Area",
    "gui.extension.python.toolbox": "Graphics Area",
    "gui.extension.python.autoCreate": "Auto Generate",
    "gui.extension.python.emptyEditor": "Click on the <b>New File button</b> behind <b>Files in Project</b> on the right to create a .py file that belongs to you",
    "gui.extension.python.userDocument": "Help Document",
    "gui.extension.xiamiboard.name": "Xia Mi Expansion Board",
    "gui.extension.xiamiboard.description": "A micro:bit-based multi-functional expansion board for programming education",
    "gui.gui.saveSb3ToCloud": "Save to the cloud",
    "gui.extension.pyMqtt.name": "MQTT-py",
    "gui.extension.pyMqtt.description": "Communication via MQTT over PC Internet",
    "gui.extension.pyGame.name": "Pygame",
    "gui.extension.pyGame.description": "Create your own games and animations with Python",
    "gui.extension.pinpongBase.name": "pinpong initialize",
    "gui.extension.pinpongBase.description": "initialize pinpong library  select board function and GPIO",
    "gui.extension.pinpongServo.name": "Micro Servo",
    "gui.extension.pinpongServo.description": "Can be rotated from 0 ~ 180 degrees to a specified angle",
    "gui.extension.pinpongIrq.name": "Interrupt",
    "gui.extension.pinpongIrq.description": "pinpong Interrupt pin",
    "gui.extension.pinpongTone.name": "buzzer",
    "gui.extension.pinpongTone.description": "pinpong buzzer module",
    "gui.extension.pinpongSr04_urm10.name": "Ultrasonic Sensor",
    "gui.extension.pinpongSr04_urm10.description": "Accurate distance detection with the range 2~800cm, compatible with urm and SR04 ultrasonic",
    "gui.extension.pinpongDht.name": "DHT11/22 Temperature and Humidity Sensor",
    "gui.extension.pinpongDht.description": "Detect enviroment temperature and humidity",
    "gui.extension.pinpongNeopixel.name": "WS2812 RGB LED Strip",
    "gui.extension.pinpongNeopixel.description": "Control LED strip changes to produce effects such as flashing, flowing, jumping, etc.",
    "gui.extension.pinpongOled12864.name": "OLED Display",
    "gui.extension.pinpongOled12864.description": "Small display, suitable for smart wearable devices",
    "gui.extension.pinpongDS18B20.name": "DS18B20 Temperature Sensor",
    "gui.extension.pinpongDS18B20.description": "Detect ambient temperature with large range of -55~+125℃",
    "gui.extension.pinpongMlx90614.name": "Non-contact infrared temperature sensor",
    "gui.extension.pinpongMlx90614.description": "Far-infrared non-contact measurement of object or ambient temperature",
    "gui.extension.pinpongBmp388.name": "BMP388 Barometric Pressure Sensor",
    "gui.extension.pinpongBmp388.description": "Temperature, atmospheric pressure, altitude detection function",
    "gui.extension.pinpongIRRecv.name": "IR Receiver Module",
    "gui.extension.pinpongIRRecv.description": "Receive standard 38KHz infrared signal",
    "gui.extension.obloqMp.name": "OBLOQ IoT Module",
    "gui.extension.obloqMp.description": "A Wi-Fi to Serial device",
    "gui.extension.pyAutoGui.name": "PyAutoGui",
    "gui.extension.pyAutoGui.description": "PyAutoGUI lets your Python scripts control the mouse and keyboard to automate interactions with other applications.",
    "gui.extension.pyRequests.name": "Requests",
    "gui.extension.pyRequests.description": "HTTP for Humans",
    "gui.extension.pyJson.name": "JSON",
    "gui.extension.pyJson.description": "JSON encoder and decoder",
    "gui.extension.pySchedule.name": "Schedule",
    "gui.extension.pySchedule.description": "Python job scheduling for humans.",
    "gui.extension.pyXlrd.name": "xlrd",
    "gui.extension.pyXlrd.description": "execl read, Support .xls",
    "gui.extension.pyXlwt.name": "xlwt",
    "gui.extension.pyXlwt.description": "execl write, Support .xls",
    "gui.extension.pyOpenCV.name": "OpenCV",
    "gui.extension.pyOpenCV.description": "OpenCV is an open-source library that includes several hundreds of computer vision algorithms.",
    "gui.account.dialog.prompt": "Prompt",
    "gui.account.dialog.tips": "Prompt",
    "gui.account.dialog.signoutAlert": "You will not be able to use cloud storage and other functions after you log out. Are you sure you want to log out?",
    "gui.account.dialog.signoutSuccess": "Logged out",
    "gui.account.dialog.sure": "OK",
    "gui.account.dialog.cancel": "Cancel",
    "gui.account.dialog.error": "Error",
    "gui.account.dialog.save": "Save",
    "gui.account.dialog.getCloudFileFailed": "Error getting cloud file [NAME], please check if the file exists",
    "gui.account.dialog.getCloudError": "Error getting cloud file [NAME]",
    "gui.account.dialog.loadCloudSuccess": "Loading cloud file [NAME] succeeded",
    "gui.account.dialog.saveCloudError": "Failed to save [NAME] to the cloud, please try later.",
    "gui.account.dialog.saveCloudSuccess": "Save [NAME] to the cloud successfully",
    "gui.account.dialog.saveCloudCheckError": "Save failed, cloud file verification error, please try again later",
    "gui.account.dialog.saveCloudRepeatPrompt": "A file [NAME] with the same name already exists in the cloud. Do you want to overwrite it? ",
    "gui.account.dialog.saveCloudRepeatAlert": "A file with the same name 「[NAME]」 already exists in the cloud, please change the name",
    "gui.account.dialog.saveCover": "Overwrite",
    "gui.account.dialog.rename": "Rename",
    "gui.account.dialog.newProject": "New File",
    "gui.account.dialog.projectName": "Project Name",
    "gui.account.dialog.enterName": "Please enter the file name",
    "gui.account.dialog.nameWithSymbol": "File name cannot contain special characters, please change",
    "gui.account.dialog.nameBeyondLength": "The length of the file name cannot exceed 30",
    "gui.account.dialog.renameSuccess": "Rename the file [NAME1] to [NAME2] successfully",
    "gui.account.dialog.renameFailed": "Failed to rename file",
    "gui.account.dialog.downloadSuccess": "[NAME] download completed",
    "gui.account.dialog.cancelDownload": "Cancel Download",
    "gui.account.dialog.deleteSucess": "Deletion of cloud file [NAME] was successful",
    "gui.account.dialog.saveAs": "Save As",
    "gui.account.dialog.deleteFailed": "Error deleting cloud file [NAME]",
    "gui.account.dialog.deletePrompt": "Are you sure to delete the cloud file [NAME]? ",
    "gui.account.open": "Open",
    "gui.account.upload": "Upload",
    "gui.account.delete": "Delete",
    "gui.account.usersSpace": "[NAME]'s Cloud Project",
    "gui.account.cloudDataLoading": "Cloud data loading, please wait",
    "gui.account.emptyUserSpace": "This is empty, go and upload your first project.",
    "gui.account.dialog.login": "Login",
    "gui.account.dialog.unsupportProject": "Error opening project, unsupported project",
    "gui.account.dialog.shouldLoginFirst": "The cloud service needs your account information, please log in first",
    "gui.account.dialog.loginTimeout": "Login Timeout",
    "gui.account.account": "Account",
    "gui.account.serverAddr": "Server Address",
    "gui.account.thirdPartyAccount": "Third Party",
    "gui.account.dialog.saveCloudRenamePrompt": "File name [NAME] already exists, do you want to rename?",
    "gui.account.dialog.signup": "Sign up",
    "gui.account.dialog.phoneNumber": "Mobile Number",
    "gui.account.dialog.password": "Password",
    "gui.account.dialog.autoLogin": "Remember the password",
    "gui.account.dialog.forgotPasswd": "Forgot Password?",
    "gui.account.dialog.getCheckCode": "Get Verification Code",
    "gui.account.dialog.reSend": "Resend",
    "gui.account.dialog.checkCode": "Verification Code",
    "gui.account.dialog.readAndAgree": "I have read and accepted",
    "gui.account.dialog.userAgreement": "User Agreement",
    "gui.account.dialog.loginSuccess": "Login Successful",
    "gui.account.dialog.passwordError": "The account or password is wrong, please check if it is correct",
    "gui.account.dialog.checkNumberSended": "The verification code has been sent, please check it carefully",
    "gui.account.dialog.checkedAgree": "Agree to Agreement",
    "gui.account.dialog.networkError": "Network error, please check the connection and try again",
    "gui.account.dialog.readProtocolAndCheck": "Please read the user agreement and tick agree",
    "gui.account.dialog.signupError": "Signup error,",
    "gui.account.dialog.logininErrorWithNetwork": "Server connection error, login failed, please check the network",
    "gui.account.dialog.signupErrorDefault": "Registration failed, please try again",
    "gui.account.dialog.accountExist": "The account already exists, please log in directly",
    "gui.account.dialog.phoneCodeError": "Registration failed, verification code error",
    "gui.account.dialog.signupSuccess": "Registration is successful, please return to the login interface to continue the operation",
    "gui.account.dialog.inputEmpty": "Cannot be empty",
    "gui.account.dialog.phoneFormatIllegal": "User name format error",
    "gui.python.remoteSSH.connectSuccess": "Successfully connected to [ADDRESS]",
    "gui.python.remoteSSH.connectFailed": "Connection [ADDRESS] failed",
    "gui.python.remoteSSH.shouleReconnect": "Disconnected from [ADDRESS], do you want to reconnect?",
    "gui.python.remoteSSH.reconnect": "Reconnect",
    "gui.python.remoteSSH.disconnected": "Disconnected from [ADDRESS]",
    "gui.python.remoteSSH.accountCheckFailed": "Account information is wrong, connection with [ADDRESS] failed, please check and enter",
    "gui.python.remoteSSH.connectError": "Error connecting with [ADDRESS], [MSG]",
    "gui.python.remoteSSH.sshLogin": "SSH Login",
    "gui.python.remoteSSH.address": "Address",
    "gui.python.remoteSSH.addressCantEmpty": "Address cannot be empty",
    "gui.python.remoteSSH.user": "User Name",
    "gui.python.remoteSSH.userCantEmpty": "User name cannot be empty",
    "gui.python.remoteSSH.passwd": "Password",
    "gui.python.remoteSSH.passwdCantEmpty": "The password cannot be empty",
    "gui.python.remoteSSH.areYouSureDisconnect": "Currently connected to the Remote [ADDRESS], are you sure to disconnect?",
    "gui.python.remoteSSH.statusConnecting": "Connecting",
    "gui.python.remoteSSH.disconnectDevice": "Disconnect Remote Terminal",
    "gui.python.remoteSSH.connectDevice": "Connect Remote Terminal",
    "gui.python.remoteSSH.statusError": "Connection error",
    "gui.python.remoteSSH.statusDisconnect": "The connection has been disconnected",
    "gui.python.remoteSSH.handleInput": "Manual Input",
    "gui.python.remoteSSH.openNetNetWork": "Open Network Center",
    "gui.python.remoteSSH.goHelp": "Help Document",
    "gui.python.remoteSSH.waitForConnecting": "Connecting to [ADDRESS], please wait...",
    "gui.python.remoteSSH.cancelConnect": "Cancel connection",
    "gui.python.remoteSSH.connectTimeout": "Connection timed out",
    "gui.python.remoteSSH.addressIsLost": "Address does not exist",
    "gui.python.remoteSSH.connectRefused": "The connection request was rejected, please check the remote system settings and try again",
    "gui.python.remoteSSH.runGUIProgramTips": "When running a graphical program, if you want to view the effect on the remote desktop, you need to use the user name currently logged in on the remote system",
    "gui.python.remoteSSH.executeFileError": "Error running project",
    "gui.python.remoteSSH.waitForProjectUpload": "Project [NAME] is uploading, please wait...",
    "gui.python.remoteSSH.projectUploadSuccess": "The project was uploaded successfully, it started to run",
    "gui.python.remoteSSH.projectUploadError": "Project upload error",
    "gui.python.filetree.boardNameInFT": "File in [BOARD]",
    "gui.python.filetree.replaceFilePrompt": "A file or folder named [NAME] already exists in the target folder. Do you want to replace it?",
    "gui.python.filetree.fileUploading": "Upload [NAME] file...",
    "gui.python.filetree.fileSaving": "Save [NAME] file...",
    "gui.python.filetree.dirSaving": "Save [NAME] folder...",
    "gui.python.filetree.saveAs": "Save As",
    "gui.python.filetree.chooseDir": "Select Folder",
    "gui.python.filetree.getDirList": "Get Directory...",
    "gui.python.filetree.getDirListWithPath": "Read [PATH] directory",
    "gui.python.filetree.getRemoteDirList": "Read remote [PATH] directory",
    "gui.python.filetree.renameFile": "Rename [FROM] to [TO]",
    "gui.python.filetree.deleteDirPrompt": "Are you sure you want to delete the folder [NAME] and the files it contains?",
    "gui.python.filetree.deleteFilePrompt": "Are you sure you want to delete the file [NAME]?",
    "gui.python.filetree.downloadToPC": "Download file to computer",
    "gui.python.filetree.uploadToRemote": "Upload file to",
    "gui.python.filetree.exportFileTo": "Export [EXT] File",
    "gui.python.filetree.rmFromeFileSystem": "Remove from the file system",
    "gui.python.filetree.rmDirPrompt": "Are you sure you want to remove the local path [NAME] and its sub-files?",
    "gui.python.filetree.rmAllDirPrompt": "Are you sure to remove all local paths?",
    "gui.python.filetree.addLocalDir": "Add a local folder",
    "gui.python.filetree.removeActionDirWithAll": "Remove all local folders",
    "gui.python.filetree.fileNameIsRequired": "File or folder name must be provided",
    "gui.python.filetree.fileNameIsRepeat": "The file or folder [NAME] already exists in this location. Please choose another name.",
    "gui.python.filetree.dragParentNodeToChild": "Parent folder cannot be moved to subfolder",
    "gui.python.filetree.dragPathIsConsistent": "The file drag path is consistent",
    "gui.python.filetree.fileOperateError": "Error in file operation",
    "gui.python.filetree.fileTreeMore": "More",
    "gui.python.filetree.copy": "copy",
    "gui.python.filetree.paste": "paste",
    "gui.python.worker.initPythonHomeDir": "Initializing folder...",
    "gui.python.worker.copyPythonSource": "Initializing the folder is complete, copying Python resource files...",
    "gui.python.worker.copyPythonSourceError": "File operation error, failed to initialize Python environment, please re-enter Python mode or restart the software",
    "gui.python.worker.unzipPythonSource": "Copying resource files is complete, unzipping...",
    "gui.python.worker.checkPythonSource": "Extracting is complete, the file is being verified...",
    "gui.python.worker.unzipPythonSourceError": "Unzip error, failed to initialize Python environment, please re-enter Python mode or restart the software",
    "gui.python.xterm.local": "Local",
    "gui.python.xterm.detectPythonEnv": "Detecting the environment, please wait...",
    "gui.python.xterm.installingPythonEnv": "\r\nInstalling the environment, please wait...",
    "gui.python.xterm.installPythonEnvError": "Python environment initialization failed, please contact technical support",
    "gui.python.xterm.detectPythonEnvFinished": "\r\nEnvironment detection completed, starting...\r\n",
    "gui.python.xterm.installPythonEnvFinished": "\r\nEnvironment installation is complete, starting...\r\n",
    "gui.python.xterm.toRestartXterm": "Double-click to restart the terminal",
    "gui.python.xterm.showRemoteConnect": "Open remote connection terminal",
    "gui.python.xterm.remoteConnectShowed": "The remote connection terminal is open",
    "gui.mainHeader.help": "!Tip: If multiple programs need to be executed at the same time, use the \"multi-threading function\" in \"Extension\", refer to the help documentation for details.",
    "paint.paintEditor.hue": "Color",
    "paint.paintEditor.saturation": "Saturation",
    "paint.paintEditor.brightness": "Brightness",
    "gui.comingSoon.message1": "Don't worry, we're on it {emoji}",
    "gui.comingSoon.message2": "Coming Soon...",
    "gui.comingSoon.message3": "We're working on it {emoji}",
    "paint.paintEditor.fill": "Omplir",
    "paint.paintEditor.costume": "Disfressa",
    "paint.paintEditor.group": "Agrupar",
    "paint.paintEditor.ungroup": "Desagrupar",
    "paint.paintEditor.undo": "Desfer",
    "paint.paintEditor.redo": "Refer",
    "paint.paintEditor.forward": "Endavant",
    "paint.paintEditor.backward": "Enrera",
    "paint.paintEditor.front": "al davant",
    "paint.paintEditor.back": "al darrera",
    "paint.paintEditor.more": "més",
    "paint.modeTools.brushSize": "Mida",
    "paint.modeTools.eraserSize": "mida esborrar",
    "paint.modeTools.copy": "Copiar",
    "paint.modeTools.paste": "Enganxar",
    "paint.modeTools.delete": "Eliminar",
    "paint.modeTools.curved": "Curved",
    "paint.modeTools.pointed": "Pointed",
    "paint.modeTools.thickness": "Gruix",
    "paint.modeTools.flipHorizontal": "voltejar horizontal",
    "paint.modeTools.flipVertical": "voltejar Vertical",
    "paint.modeTools.filled": "Omplir",
    "paint.modeTools.outlined": "Outlined",
    "paint.paintEditor.bitmap": "Convertir a Mapa de bits",
    "paint.paintEditor.vector": "Convertir a Vector",
    "paint.paintEditor.stroke": "Contorn",
    "paint.brushMode.brush": "Pinzell",
    "paint.eraserMode.eraser": "Borrador",
    "paint.fillMode.fill": "Emplenar",
    "paint.lineMode.line": "línia",
    "paint.ovalMode.oval": "Cercle",
    "paint.rectMode.rect": "Rectangle",
    "paint.reshapeMode.reshape": "canviar forma",
    "paint.roundedRectMode.roundedRect": "Rectangle arrodonit",
    "paint.selectMode.select": "Seleccionar",
    "paint.textMode.text": "Text",
    "paint.colorPicker.swap": "Intercanviar"
  },
  "cr": {
    "gui.gui.project": "Projekt",
    "gui.gui.newItem": "Novi projekt",
    "gui.gui.load": "Učitaj projekt",
    "gui.gui.save": "Spremi projekt",
    "gui.gui.saveAs": "Spremi kao",
    "gui.gui.cacheList": "Popis predmemorije",
    "gui.gui.inCache": "U predmemoriji",
    "gui.gui.learn": "Učenje",
    "gui.gui.clearRecord": "Obriši popis",
    "gui.gui.onlineLearn": "Službena dokumentacija",
    "gui.gui.onlineForum": "Online Forum",
    "gui.gui.videoTutorial": "Video tutorijali",
    "gui.gui.offlineLearn": "Primjeri programa",
    "gui.menuBar.turboModeOff": "ISKLJUČI Turbo način rada",
    "gui.menuBar.turboModeOn": "UKLJUČI Turbo način rada",
    "gui.menuBar.edit": "Uredi",
    "gui.menuBar.restoreSprite": "Vrati lik",
    "gui.menuBar.restoreSound": "Vrati zvuk",
    "gui.menuBar.restoreCostume": "Vrati kostim",
    "gui.menuBar.restoreBackdrop": "Vrati pozadinu",
    "gui.editorMind.restore": "Vrati",
    "gui.turboMode.active": "Turbo način rada",
    "gui.gui.connectDevice": "Poveži uređaj",
    "gui.gui.disconnectDevice": "Odspoji uređaj",
    "gui.gui.installSerialportDriver": "Instaliraj upravljački program SerialPort",
    "gui.gui.openDM": "Otvori Upravitelj uređaja",
    "gui.gui.restoreSetting": "Vrati početne postavke uređaja",
    "gui.gui.updateTips": "Savjeti za ažuriranje",
    "gui.gui.newVersion": "Najnovija verzija",
    "gui.gui.downloadUpdate": "Preuzmi ažuriranje",
    "gui.gui.versionUpdate": "Ažuriraj verziju",
    "gui.gui.isCheckMd5": "Provjera datoteke",
    "gui.gui.downloading": "Provjera ažuriranja",
    "gui.gui.updateError": "Pogreška ažuriranja",
    "gui.setting.feedbackMenu": "Povratne informacije",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.gui.python": "Python",
    "gui.setting.officialWebsit": "Službena stranica",
    "gui.setting.officialQQGroup": "Službena QQ grupa",
    "gui.setting.wechatOfficialAccounts": "WeChat službeni računi",
    "gui.setting.currentVersion": "Trenutna verzija",
    "gui.setting.checkForUpdate": "Provjeri ažuriranja",
    "gui.setting.remindWhenUpdate": "Preuzmi kada postoji ažuriranje",
    "gui.setting.currentIsLatest": "Trenutna verzija je najnovije izdanje.",
    "gui.setting.latestVersion": "Najnovija verzija",
    "gui.setting.download": "Preskoči za preuzimanje weba",
    "gui.setting.language": "Jezik",
    "gui.setting.uploadCsv": "učitaj xlsx",
    "gui.setting.theme": "Tema",
    "gui.setting.feedback": "Kontakt",
    "gui.setting.email": "e-mail",
    "gui.setting.opinionFeedback": "Poruka",
    "gui.variableMc.variableName": "naziv varijable",
    "gui.variableMc.variableOff": "otkaži",
    "gui.variableMc.variableOn": "potvrdi",
    "gui.variableMc.newVariable": "nova varijabla",
    "gui.setting.helpUsTranslate": "Pomozi nam prevesti program",
    "gui.setting.checkUpdate": "Provjeri ažuriranja",
    "gui.setting.ok": "U redu",
    "gui.setting.languageSetting": "Jezik",
    "gui.setting.themeSetting": "Tema",
    "gui.setting.cacheSetting": "Predmemorija",
    "gui.setting.versionUpdate": "Ažuriranje",
    "gui.setting.connectUsTitle": "Kontakt",
    "gui.setting.uploadAttachment": "Učitaj privitak",
    "gui.setting.displayTitle": "Zaslon",
    "gui.setting.restartEffect": "Veličina fonta (Potrebno ponovno pokrenuti program!):",
    "gui.setting.fontSizeLarge": "Veliki font",
    "gui.setting.fontSizeMiddle": "Srednji font",
    "gui.setting.fontSizeSmall": "Mali font",
    "gui.setting.onlineRepair": "online alat za popravak, klikni otvori >",
    "gui.setting.cacheSwitch": "Predmemorija (otvori/zatvori)",
    "gui.setting.freeCache": "Očisti predmemoriju",
    "gui.setting.spaceCache": "Fiksni interval",
    "gui.setting.cacheWay": "Cache Way",
    "gui.setting.interval": "interval",
    "gui.setting.freeTime": "Slobodno vrijeme",
    "gui.setting.second": "sekunda",
    "gui.setting.minute": "minuta",
    "gui.gui.code": "Kodiraj",
    "gui.menubar.cloudProject": "Projekt u oblaku",
    "gui.menubar.login": "Prijavi se",
    "gui.menubar.signout": "Odjavi se",
    "gui.menubar.saveToCloud": "Spremi u oblak",
    "gui.menubar.cloudServer": "Oblak",
    "gui.setting.cleanCache": "Čišćenje podataka",
    "gui.setting.programCache": "Predmemorija projekta",
    "gui.setting.configJson": "Konfiguracijska datoteka",
    "gui.setting.pythonEnv": "Python okruženje",
    "gui.setting.backpack": "Schoolbag datoteka",
    "gui.setting.compile": "Kompajlirane informacije",
    "gui.setting.thirdExt": "Proširena korisnička biblioteka",
    "gui.setting.cleanCacheBtn": "Očisti sve odabrano",
    "gui.setting.cleanCachePrompt": "Podaci se ne mogu vratiti nakon brisanja, jesi li siguran da ih želiš izbrisati?",
    "gui.setting.cleanPythonEnvPrompt": "Kako bi izbjegli iznimku drugih prozora uzrokovanih čišćenjem okruženja Python, provjeri je li otvoren samo jedan prozor Mind+ softvera prije čišćenja.",
    "gui.setting.cleanPyEnvOnPythonPrompt": "U načinu rada Python moraš resetirati okruženje nakon čišćenja resursa. Ovaj proces zahtjeva neko vrijeme. Kako bi izbjegao da resursi za čišćenje uzrokuju druge iznimke prozora, prije čišćenja provjeri je li otvoren samo jedan prozor softvera Mind+.",
    "gui.setting.expVersion": "Eksperimentalna verzija",
    "gui.setting.expUpdateTips": "[Napomene o ažuriranju]: Ova verzija je verzija za ranu upotrebu koja nije službeno objavljena. Neke funkcije mogu biti nestabilne. Molimo potvrdi prije preuzimanja;",
    "gui.setting.cleanConfigSuccess": "Čišćenje podataka je završeno, ponovno pokreni Mind+.",
    "gui.setting.cleanConfigError": "Došlo je do pogreške tijekom čišćenja podataka, pokušaj ponovno",
    "gui.setting.cleanPartError": "Pogreška pri čišćenju [DIO]...",
    "gui.setting.cleaningPyEnv": "Čišćenje Python okruženja...",
    "gui.setting.cleanPythonEnvError": "Čišćenje Python okruženja nije uspjelo, možeš zatvoriti softver, ručno izbrisati mapu [PATH] , a zatim ponovo pokrenuti softver",
    "gui.backpack.header": "Ruksak",
    "gui.backpack.errorBackpack": "Pogreška pri učitavanju ruksaka",
    "gui.backpack.loadingBackpack": "Učitavam...",
    "gui.backpack.more": "Više",
    "gui.backpack.emptyBackpack": "Ruksak je prazan",
    "gui.gui.blocks": "Blokovi",
    "gui.gui.costumesTab": "Kostimi",
    "gui.gui.soundsTab": "Zvukovi",
    "gui.gui.backdropsTab": "Pozadine",
    "gui.gui.addExtension": "Ekstenzije",
    "gui.costumeTab.addCostumeFromLibrary": "Odaberi Lik",
    "gui.costumeLibrary.chooseACostume": "Odaberi Kostim",
    "gui.costumeTab.addBackdropFromLibrary": "Odaberi Pozadinu",
    "gui.costumeTab.addBlankCostume": "Boja",
    "gui.costumeTab.addSurpriseCostume": "Iznenađenje",
    "gui.costumeTab.addFileBackdrop": "Prenesi pozadinu",
    "gui.costumeTab.addFileCostume": "Učitaj",
    "gui.costumeTab.addCameraCostume": "Fotoaparat",
    "gui.sliderModal.min": "Minimalna vrijednost",
    "gui.sliderModal.max": "Maksimalna vrijednost",
    "gui.sliderModal.title": "Promjena raspona klizača",
    "gui.soundEditor.trim": "Trim",
    "gui.soundEditor.stop": "Stop",
    "gui.soundEditor.sound": "Zvuk",
    "gui.soundEditor.play": "igra",
    "gui.soundEditor.save": "Spremi",
    "gui.soundEditor.undo": "Poništi",
    "gui.soundEditor.redo": "Ponovi",
    "gui.soundEditor.faster": "Brže",
    "gui.soundEditor.slower": "Sporije",
    "gui.soundEditor.echo": "Jeka",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Glasnije",
    "gui.soundEditor.softer": "Mekši",
    "gui.soundEditor.reverse": "Obrnuto",
    "gui.soundTab.recordSound": "Snimiti",
    "gui.soundTab.addSoundFromLibrary": "Odaberi zvuk",
    "gui.soundTab.surpriseSound": "Iznenađenje",
    "gui.soundTab.fileUploadSound": "Prijenos zvuka",
    "gui.soundTab.addSound": "Dodaj zvuk",
    "gui.controls.stop": "Stop",
    "gui.controls.go": "Pokreni",
    "gui.controls.customFirmware": "Prilagođeni firmware",
    "gui.controls.defaultFirmware": "Službeni firmware",
    "gui.controls.clearLocaleFirmware": "Obriši zapise",
    "gui.controls.checkForUpdates": "Provjeri ima li ažuriranja",
    "gui.controls.alreadyLastVersion": "Već najnovija verzija",
    "gui.controls.uploadFirmware": "Učitaj",
    "gui.gui.startPython": "Pokreni Python",
    "gui.gui.stopPython": "Zaustavi Python",
    "gui.controls.fullScreenControl": "Kontrola preko cijelog zaslona",
    "gui.gui.stageSizeLarge": "Veliki zaslon Contorl",
    "gui.gui.stageSizeSmall": "Kontrola malog zaslona",
    "gui.gui.stageSizeNostage": "Prebacivanje veličine pozornice - nema pozornice",
    "gui.gui.stageSizeFull": "Prebacivanje veličine pozornice - cijeli zaslon",
    "gui.stageHeader.stageSizeUnFull": "Izađi iz prikaza preko cijelog zaslona",
    "gui.sprite.sprite": "Lik",
    "gui.SpriteInfo.show": "Prikaz",
    "gui.SpriteInfo.size": "Veličina",
    "gui.sprite.direction": "Smjer",
    "gui.sprite.rotation": "Rotacija",
    "gui.directionPicker.rotationStyles.allAround": "Svuda okolo",
    "gui.directionPicker.rotationStyles.leftRight": "Lijevo desno",
    "gui.directionPicker.rotationStyles.dontRotate": "Nemoj rotirati",
    "gui.spriteSelectorItem.contextMenuDuplicate": "Kopiraj",
    "gui.spriteSelectorItem.contextMenuDelete": "Izbriši",
    "gui.spriteSelectorItem.contextMenuExport": "Izvoz",
    "gui.sprite.addSpriteFromLibrary": "Biblioteka Likova",
    "gui.spriteSelector.addSpriteFromPaint": "Boja",
    "gui.spriteSelector.addSpriteFromSurprise": "Iznenađenje",
    "gui.spriteSelector.addSpriteFromFile": "Učitaj Lik",
    "gui.sprite.addSpriteFromCamera": "Fotoaparat",
    "gui.stageSelector.stage": "Pozornica",
    "gui.stageSelector.backdrops": "Pozadina",
    "gui.stage.addBackdropFromLibrary": "Odaberi pozadinu",
    "gui.stageSelector.addBackdropFromPaint": "Boja",
    "gui.stageSelector.addBackdropFromSurprise": "Iznenađenje",
    "gui.stageSelector.addBackdropFromFile": "Prenesi pozadinu",
    "gui.stage.addBackdropFromCamera": "Fotoaparat",
    "gui.modal.help": "Pomozi",
    "gui.modal.save": "Spremi",
    "gui.modal.run": "Pokreni",
    "gui.modal.continue": "Nastaviti",
    "gui.modal.paused": "Pauzirano",
    "gui.modal.back": "Nazad",
    "gui.extension.kit": "Kit",
    "gui.extension.kitTitle": "Odaberi Kit",
    "gui.extension.board": "Pločica",
    "gui.extension.boardTitle": "Odaberi Pločicu",
    "gui.extension.spreadBoard": "Štit",
    "gui.extension.spreadBoardTitle": "Odaberi Štit",
    "gui.extension.sensor": "Senzor",
    "gui.extension.sensorTitle": "Odaberi Senzor",
    "gui.extension.actuator": "Pokretač",
    "gui.extension.actuatorTitle": "Odaberi aktuator",
    "gui.extension.communicationModule": "Komunikacija",
    "gui.extension.communicationModuleTitle": "Odaberi Komunikacija",
    "gui.extension.display": "Prikaz",
    "gui.extension.displayTitle": "Odaberi Prikaz",
    "gui.extension.function": "Funkcija",
    "gui.extension.functionTitle": "Odaberi Funkciju",
    "gui.extension.internetService": "Internet",
    "gui.extension.internetServiceTitle": "Odaberi Internet",
    "gui.extension.thirdExpansion": "User-Ext",
    "gui.extension.thirdExpansionTitle": "Odaberi korisničko proširenje",
    "gui.extension.arduinContentLabel": "Odaberi uređaj",
    "gui.extension.openUserExFile": "Otvori datoteku User-Ex na svom računalu",
    "gui.extension.importUserEx": "Učitaj User-Ex",
    "gui.extension.unavailable": "Nedostupan",
    "gui.extension.thirdModuleSearch": "Pretraži ili unesi URL projekta",
    "gui.extension.thirdModulesHelp": "Napomena: Module u korisničkoj biblioteci izradili su Mind + entuzijasti, {clickHelp} da pogledaš {tutorial} i {list}",
    "gui.extension.thirdTutorial": "razvojni tutorial",
    "gui.extension.thirdList": "User-Ext popis",
    "gui.extension.libaryOffice": "Službena knjižnica",
    "gui.extension.libaryPinpong": "pinpong",
    "gui.library.filterPlaceholder": "Traži",
    "gui.libraryTags.all": "Svi",
    "gui.libraryTags.animals": "Životinje",
    "gui.libraryTags.dance": "Ples",
    "gui.libraryTags.effects": "Efekti",
    "gui.libraryTags.fantasy": "Fantazija",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Hrana",
    "gui.libraryTags.festival": "Festival",
    "gui.libraryTags.traffic": "Promet",
    "gui.libraryTags.indoors": "U zatvorenom prostoru",
    "gui.libraryTags.loops": "Petlje",
    "gui.libraryTags.music": "glazba, muzika",
    "gui.libraryTags.notes": "Bilješke",
    "gui.libraryTags.outdoors": "Na otvorenom",
    "gui.libraryTags.patterns": "Obrasci",
    "gui.libraryTags.people": "Ljudi",
    "gui.libraryTags.percussion": "Udaraljke",
    "gui.libraryTags.space": "Prostor",
    "gui.libraryTags.sports": "Sportski",
    "gui.libraryTags.underwater": "Pod vodom",
    "gui.libraryTags.voice": "Glas",
    "gui.libraryTags.wacky": "Ćaknut",
    "gui.libraryTags.animation": "Animacija",
    "gui.libraryTags.art": "Umjetnost",
    "gui.libraryTags.games": "Igre",
    "gui.libraryTags.stories": "Priče",
    "gui.libraryTags.letters": "Slova",
    "gui.extension.backdropLib": "Odaberi pozadinu",
    "gui.soundLibrary.chooseASound": "Odaberi zvuk",
    "gui.SpriteInfo.spritePlaceholder": "Ime",
    "gui.extension.spriteLib": "Odaberi Lik",
    "gui.gui.unselectedSerialport": "Nema povezanih uređaja",
    "gui.gui.unConnectedDev": "Nema povezanih uređaja",
    "gui.gui.pythonMode": "Python način rada",
    "gui.gui.burnFirmware": "Ponovno snimanje firmvera",
    "gui.gui.burnFirmwareError": "Pogreška snimanja firmvera",
    "gui.gui.connected": "Povezan",
    "gui.gui.failedConnected": "Povezivanje nije uspjelo",
    "gui.gui.connecting": "Povezivanje...",
    "gui.cards.all-how-tos": "Sve upute",
    "gui.cards.how-tos": "Tutoriali",
    "gui.cards.remove": "Ukloniti",
    "gui.cards.more-things-to-try": "Pokušaj više!",
    "gui.cards.see-more": "Vidi više",
    "gui.loader.message1": "Stvaranje blokova…",
    "gui.loader.message2": "Učitavanje likova…",
    "gui.loader.message3": "Učitavanje zvukova...",
    "gui.loader.message4": "Učitavanje proširenja...",
    "gui.loader.message5": "Stadni Mind+ …",
    "gui.loader.message6": "Odašiljanje Nanosa…",
    "gui.loader.message7": "Napuhavanje Goboa…",
    "gui.loader.message8": "Priprema emojija…",
    "gui.loader.headline": "Učitavanje projekta",
    "gui.cameraModal.cameraModalTitle": "Slikaj",
    "gui.cameraModal.loadingCameraMessage": "Učitavanje kamere...",
    "gui.cameraModal.permissionRequest": "Trebamo dopuštenje za korištenje tvoje kamere",
    "gui.cameraModal.retakePhoto": "Ponovno snimi fotografiju",
    "gui.cameraModal.save": "Spremi",
    "gui.cameraModal.takePhoto": "Uslikaj",
    "gui.cameraModal.loadingCaption": "Učitavam...",
    "gui.cameraModal.enableCameraCaption": "Omogući kameru",
    "gui.recordModal.title": "Snimiti zvuk",
    "gui.recordStep.recordByClickBtn": "Započni snimati klikom na gumb ispod",
    "gui.recordStep.permissionMicrophone": "Trebamo dopuštenje za korištenje mikrofona",
    "gui.recordStep.record": "Snimiti",
    "gui.recordStep.stopRecord": "Zaustavi snimanje",
    "gui.playbackStep.stopMsg": "Stop",
    "gui.playbackStep.playMsg": "igra",
    "gui.playbackStep.loadingMsg": "Učitavam...",
    "gui.playbackStep.saveMsg": "Spremi",
    "gui.playbackStep.reRecordMsg": "Ponovno snimanje",
    "gui.webglModal.label": "Tvoj preglednik ne podržava WebGL",
    "gui.webglModal.descriptionMind": "Nažalost, tvoje računalo je {webGlLink}. Mind+ treba pokrenuti WebGL, pokušaj {restartMindplus}（{clickToRestart}）, ako se problem nastavi {updateGpuDriver}",
    "gui.webglModal.restartMindplus": "Ponovno pokreni Mind+",
    "gui.webglModal.clickToRestart": "Klikni ovdje za ponovno pokretanje",
    "gui.webglModal.webgllink": "Ne podržava WebGL",
    "gui.webglModal.updateGpuDriver": "Pokušaj ažurirati upravljački program grafičke kartice",
    "gui.webglModal.ok": "U redu",
    "gui.extension.scratchExampleLib": "Biblioteka primjera u Scratchu",
    "gui.extension.pythonExampleLib": "Biblioteka primjera u Pythonu",
    "gui.extension.arduinoExampleLib": "Biblioteka primjera u Arduinu",
    "gui.prompt.cancel": "Poništi",
    "gui.prompt.ok": "OK",
    "gui.extension.makeBlock": "Napravi blok",
    "gui.customProcedures.addAnInputNumberText": "Dodaj ulaz",
    "gui.customProcedures.addAnInputBoolean": "Dodaj ulaz",
    "gui.customProcedures.numberTextType": "broj ili tekst",
    "gui.customProcedures.textType": "tekst",
    "gui.customProcedures.numberType": "broj",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Dodaj oznaku",
    "gui.customProcedures.runWithoutScreenRefresh": "Pokretanje bez osvježavanja zaslona",
    "gui.customProcedures.cancel": "Poništi",
    "gui.customProcedures.ok": "OK",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "Vortex obrazovni robot tvrtke DFRobot",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "Explorer D1 obrazovni robot tvrtke DFRobot",
    "gui.extension.arduinounoR3.name": "ArduinounoR3",
    "gui.extension.arduinounoR3.description": " Prilagođeni komplet glavne kontrolne ploče Wuhan Maker Course Arduino Uno.",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "Max:bot Robot baziran na micro:bitu",
    "gui.extension.max.name": "MAKS",
    "gui.extension.max.description": "MAX（ROB0137）robot temeljen na Arduinu",
    "gui.extension.motorbit.name": "motor: bit",
    "gui.extension.motorbit.description": "Motor: bitni motor i servo ploča za proširenje",
    "gui.extension.maqueen.name": "Maqueen",
    "gui.extension.maqueen.description": "micro:Maqueen robot temeljen na micro:bitu",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.microbit.description": "Poveži svoje projekte s fizičkim svijetom.",
    "gui.extension.calliope.description": "Budi kreativan i počni kodirati!",
    "gui.extension.calliope.name": "CALLIOPE mini",
    "gui.extension.spread.name": "micro:bit & mPython ploča za proširenje",
    "gui.extension.spreadboson.name": "micro:bit & mPython ploča za proširenje",
    "gui.extension.microTouch.name": "TouchKey",
    "gui.extension.microNaturalScience.name": "mikro:prirodna znanost",
    "gui.extension.microTouch.description": "Integrirana 4×4 tipkovnica i ploča za proširenje servo pogona motora",
    "gui.extension.microNaturalScience.description": "Micro:bit ploča za proširenje s više senzora za okoliš",
    "gui.extension.microNaturalScienceV2.description": "Micro:bit proizvod za učenje koji integrira obilje senzora povezanih s prirodnim okolišem",
    "gui.extension.microIoT.name": "mikro:IoT",
    "gui.extension.microIoT.description": "Integrirani zaslon i internet stvari micro:bit ploča za proširenje",
    "gui.extension.microIoTCloud.name": "micro:IoT ploča za oblak",
    "gui.extension.microIoTCloud.description": "Micro:bit ploča za proširenje podržava međusobno povezivanje WiFi kartica, pružajući izvrsno rješenje za nastavu u učionici Internet of Things",
    "gui.extension.spreadMaqueen.name": "maqueen nastavak",
    "gui.extension.pinpongBread.name": "PinPong ploča za proširenje",
    "gui.extension.pinpongBread.description": "Ploča za proširenje za učenje Pythona na ArduinoUNO",
    "gui.extension.spread.description": "I/O ploča za proširenje s micro:bitom i kontrolnom pločom (s pokretačkim programom motora)",
    "gui.extension.spreadboson.description": "I/O ploča za proširenje s micro:bitom i kontrolnom pločom (s pokretačkim programom motora)",
    "gui.extension.spreadmaqueen.description": "Početni miniautomobil temeljen na kontrolnoj verziji",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "Napredni obrazovni robot koji podržava i micro:bit i mPython",
    "gui.extension.iot.name": "Internet stvari",
    "gui.extension.iot.description": "Prošireno izdanje temeljeno na Internetu stvari",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Uređaj kojim upravlja Leonardo",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Modul glavne upravljačke ploče Arduino Uno",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Uređaj kojim upravlja Arduino Nano",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "Glavna upravljačka ploča temeljena na ESP32",
    "gui.extension.telloesp32.name": "RoboMaster TT (ESP32)",
    "gui.extension.telloesp32.description": "Edukativni dron s DJI vrhunskim algoritmima za kontrolu leta, siguran i stabilan",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Uređaj kojim upravlja Mega2560",
    "gui.extension.firebeetleesp32.name": "FireBeetle ESP32",
    "gui.extension.firebeetleesp32e.name": "FireBeetle ESP32-E",
    "gui.extension.firebeetleesp32.description": "Uređaj kojim upravlja FireBeetle ESP32",
    "gui.extension.dfr0299.name": "DFPlayer MP3 modul",
    "gui.extension.dfr0299.description": "MP3 player modul",
    "gui.extension.ser0006.name": "Mikro servo",
    "gui.extension.ser0006.description": "Mikro servo",
    "gui.extension.dfr0523.name": "Peristaltička pumpa",
    "gui.extension.dfr0523.description": "Ispusti tekućinu naizmjeničnim stiskanjem i otpuštanjem pumpe",
    "gui.extension.dfr0017.name": "Relejni modul",
    "gui.extension.dfr0017.description": "Upravljaj uređajem velike struje",
    "gui.extension.steppermotor.name": "Koračni motor",
    "gui.extension.steppermotor.description": "Ostvari točnu kontrolu položaja kontrolom pulsa",
    "gui.extension.dfr0534.name": "Serijski MP3 modul",
    "gui.extension.dfr0534.description": "Serijski MP3 glasovni modul",
    "gui.extension.servo360.name": "360° mikro servo",
    "gui.extension.servo360.description": "Kontroliraj brzinu i smjer",
    "gui.extension.tel0118.name": "OBLOQ IoT modul",
    "gui.extension.tel0118.description": "Wi-Fi na serijski uređaj",
    "gui.extension.dfr0095.name": "Modul IR odašiljača",
    "gui.extension.dfr0095.description": "Odašilja standardni infracrveni signal od 38KHz",
    "gui.extension.dfr0094.name": "Modul IR prijemnika",
    "gui.extension.dfr0094.description": "Primi standardni infracrveni signal od 38 KHz",
    "gui.extension.midi.name": "MIDI",
    "gui.extension.midi.description": "MIDI player",
    "gui.extension.radio.name": "Bežično emitiranje",
    "gui.extension.radio.description": "Bežična komunikacija između više upravljačkih ploča",
    "gui.extension.tel0094.name": "Modul za prijem GPS signala",
    "gui.extension.tel0094.description": "Modul za prijem GPS signala",
    "gui.extension.tel0026.name": "Bluetooth modul",
    "gui.extension.tel0026.description": "Modul Bluetooth serijskog priključka",
    "gui.extension.dfr0486.name": "Zaslon OLED-12864",
    "gui.extension.dfr0647.name": "Zaslon OLED-12832",
    "gui.extension.dfr0486.description": "I2C OLED-12864 modul zaslona",
    "gui.extension.dfr0647.description": "I2C OLED-12832 modul zaslona",
    "gui.extension.fit0352.name": "WS2812 RGB LED traka",
    "gui.extension.fit0352.description": "Kontroliraj module LED trake temeljene na WS2812",
    "gui.extension.dfr0063.name": "LCD1602 zaslon",
    "gui.extension.dfr0063.description": "LCD modul koji može prikazati 2 retka i 16 znakova u svakom retku",
    "gui.extension.dfr0021.name": "Digitalni LED svjetlosni modul",
    "gui.extension.dfr0021.description": "Bijeli, crveni, zeleni i plavi LED moduli",
    "gui.extension.tm1650.name": "TM1650 Četveroznamenkasta cijev",
    "gui.extension.tm1650.description": "Četveroznamenkasta cijev",
    "gui.extension.matrix8_8.name": "MAX7219 8x8 matrica",
    "gui.extension.matrix8_8.description": "8x8 rešetkasti modul",
    "gui.extension.dfr0522.name": "8x16 RGB LED matrična ploča",
    "gui.extension.dfr0522.description": "Prikaz slika, brojeva... podržava prikaz pomicanja i korisnički definirane postavke",
    "gui.extension.music.name": "glazba, muzika",
    "gui.extension.music.description": "Sviraj instrumente i bubnjeve.",
    "gui.extension.pen.name": "Olovka",
    "gui.extension.pen.description": "Crtaj svojim duhovima.",
    "gui.extension.video-sensing.name": "Video senzor",
    "gui.extension.video-sensing.description": "Osjeti kretanje kamerom.",
    "gui.extension.google-translate.name": "Prevedi",
    "gui.extension.google-translate.description": "Prevedi tekst na mnoge jezike.",
    "gui.extension.bd-translate.name": "Baidu Prevoditelj",
    "gui.extension.bd-translate.description": "Koristi prevoditeljski servis Baidu za prijevod teksta na druge jezike.",
    "gui.extension.text-speech.name": "Tekst u govor",
    "gui.extension.text-speech.description": "Neka tvoji projekti govore",
    "gui.extension.softSerialport.name": "Softverski serijski priključak",
    "gui.extension.softSerialport.description": "Koristi I/O port za simulaciju funkcije serijske komunikacije",
    "gui.extension.IICScan.name": "I2C skeniranje",
    "gui.extension.IICScan.description": "Skeniraj sve adrese uređaja koji su povezani na I2C sučelje",
    "gui.extension.bluetooth.name": "Bluetooth",
    "gui.extension.bluetooth.description": "Bluetooth uređaj",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "GoBLE APP može pomoći da upravljaš glavnom kontrolnom pločom putem pametnog telefona",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "Koristi ga za čitanje ili pisanje i spremanje podataka s eepromom",
    "gui.extension.interrupt.name": "Prekinuti",
    "gui.extension.interrupt.description": "Prekidna igla",
    "gui.extension.serialPort.description": "Prikladno ostvari funkciju slanja više skupova podataka u načinu rada za učitavanje i primanje podataka putem serijskog priključka u načinu rada u stvarnom vremenu",
    "gui.extension.mpyfile.name": "datoteka",
    "gui.extension.mpyfile.description": "Čitaj i zapisuj datoteke na upravljačkoj ploči, pohrani podatke i izvezi ih",
    "gui.extension.sdcard.name": "Modul čitača SD kartica",
    "gui.extension.sdcard.description": "Modul čitača microSD kartica",
    "gui.extension.multithread.name": "MultiThread",
    "gui.extension.multithread.description": "Dopusti više programa i glavni program da rade istovremeno",
    "gui.extension.speech.name": "Govor",
    "gui.extension.speech.description": "Razgovaraj sa svojim projektima.",
    "gui.extension.sen0001.name": "Ultrazvučni senzor",
    "gui.extension.bos0001.name": "Senzor rotacije",
    "gui.extension.bos0002.name": "Pritisni gumbr",
    "gui.extension.bos0003.name": "Prekidač sa samozaključavanjem",
    "gui.extension.bos0004.name": "Svijetli senzor",
    "gui.extension.bos0006.name": "Senzor pare",
    "gui.extension.bos0007.name": "Senzor plamena",
    "gui.extension.bos0008.name": "Senzor za dodir",
    "gui.extension.bos0009.name": "Senzor zvuka",
    "gui.extension.bos0010.name": "Infracrveni senzor blizine",
    "gui.extension.bos0012.name": "Prekidač vodljivosti",
    "gui.extension.bos0013.name": "Senzor pokreta",
    "gui.extension.bos0038.name": "Senzor vlage u tlu",
    "gui.extension.bos0045.name": "Ultrazvučni senzor udaljenosti",
    "gui.extension.bos0016.name": "Ultra-Bright LED",
    "gui.extension.bos0017.name": "LED modul",
    "gui.extension.bos0019.name": "LED žičano svjetlo",
    "gui.extension.bos0021.name": "Modul ventilatora",
    "gui.extension.bos0022.name": "Modul za snimanje glasa",
    "gui.extension.bos0001.description": "Analogni senzor, može detektirati položaj rotacije",
    "gui.extension.bos0002.description": "Pritisni za izlaz visoke razine, otpusti za izlaz niske razine",
    "gui.extension.bos0003.description": "Visoka razina izlaza u pritisnutom stanju, niska razina u iskačućem stanju",
    "gui.extension.bos0004.description": "Detekcija intenziteta ambijentalnog svjetla",
    "gui.extension.bos0006.description": "Otkrij kišu i maglu, bez urona u vodu",
    "gui.extension.bos0007.description": "Otkrij izvor vatre ili izvor svjetlosti s valnom duljinom od 760~1100nm",
    "gui.extension.bos0008.description": "Prekidač na dodir, može osjetiti ljudsko tijelo, metal itd",
    "gui.extension.bos0009.description": "Otkrij intenzitet zvuka",
    "gui.extension.bos0010.description": "Otkrij signale generirane različitim bojama",
    "gui.extension.bos0012.description": "Provjeri je li predmet vodljiv",
    "gui.extension.bos0013.description": "Otkrij infracrvene zrake koje emitiraju ljudi ili životinje u pokretu",
    "gui.extension.bos0038.description": "Otkrij vlažnost tla, što je manje vlage to je manja izlazna vrijednost",
    "gui.extension.bos0045.description": "Analogni senzor udaljenosti, raspon detekcije 2cm~1m",
    "gui.extension.bos0016.description": "Modul koji emitira bijelo svjetlo",
    "gui.extension.bos0017.description": "Svjetleće diode mogu emitirati svjetlost koja odgovara boji perli svjetiljke",
    "gui.extension.bos0019.description": "Šarena svjetleća traka, dužina svjetleće trake je 3 metra, boja se ne mijenja",
    "gui.extension.bos0021.description": "Modul za pogon rotacije lopatica ventilatora",
    "gui.extension.bos0022.description": "Može snimiti zvuk od 10 sekundi i reproducirati snimku",
    "gui.extension.sen0001.description": "Precizno otkrivanje udaljenosti s rasponom 2~800 cm, kompatibilno s urm i SR04 ultrazvukom",
    "gui.extension.dfr0023.name": "Analogni senzor temperature LM35",
    "gui.extension.dfr0023.description": "Senzor temperature poluvodiča na bazi LM35 s rasponom 0~100 ℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22 senzor temperature i vlažnosti",
    "gui.extension.dhtTHSensor.description": "Otkrij temperaturu i vlažnost okoline",
    "gui.extension.dsTSensor.name": "DS18B20 senzor temperature",
    "gui.extension.dsTSensor.description": "Detektiraj temperaturu okoline u širokom rasponu od -55~+125 ℃",
    "gui.extension.sen0203.name": "Senzor za praćenje otkucaja srca",
    "gui.extension.sen0203.description": "Mini senzor otkucaja srca s analognim pulsnim i digitalnim kvadratnim izlazom",
    "gui.extension.sen0177.name": "Laserski PM2.5 senzor kvalitete zraka",
    "gui.extension.sen0177.description": "Izmjeri PM1, PM2,5 i PM10. Prijenos podataka putem serijskog porta",
    "gui.extension.sen0014.name": "GP2Y0A21 IR senzor udaljenosti",
    "gui.extension.sen0014.description": "IR senzor baziran na GP2Y0A21 s rasponom mjerenja od 10~80 cm",
    "gui.extension.sen0204.name": "Beskontaktni senzor razine tekućine",
    "gui.extension.sen0204.description": "Otkrij razinu tekućine, ali bez ikakvog kontakta",
    "gui.extension.sen0160.name": "Hx711 senzor težine",
    "gui.extension.sen0160.description": "Izmjeri Hx711 senzor težine",
    "gui.extension.sen0161.name": "Analogni pH metar",
    "gui.extension.sen0161.description": "Izmjeri pH vrijednost tekućine s napajanjem od 5 V",
    "gui.extension.sen0161-v2.name": "Analogni pH metar (V2)",
    "gui.extension.sen0161-v2.description": "Izmjeri pH vrijednost tekućine s napajanjem od 3,3~5,5 V",
    "gui.extension.sen0244.name": "Analogni TDS senzor",
    "gui.extension.sen0244.description": "Izmjeri TDS (ukupno otopljene krutine) tekućine što ukazuje na čistoću vode",
    "gui.extension.sen0165.name": "Analogni ORP mjerač",
    "gui.extension.sen0165.description": "Izmjeri ORP (oksidacijsko-redukcijski potencijal) tekućine kako biste testirali kvalitetu vode",
    "gui.extension.sen0237.name": "Analogni senzor otopljenog kisika",
    "gui.extension.sen0237.description": "Izmjeri DO (otopljeni kisik) tekućine kako biste procijenili kvalitetu vode",
    "gui.extension.dfr0300-H.name": "Analogni EC mjerač",
    "gui.extension.dfr0300-H.description": "Izmjeri EC (električnu vodljivost) tekućine kako bi procijenio kvalitetu vode",
    "gui.extension.dfr0300.name": "Analogni EC mjerač (V2)",
    "gui.extension.dfr0300.description": "Izmjeri EC (električnu vodljivost) tekućine kako bi procijenio kvalitetu vode",
    "gui.extension.sen0170.name": "Anemometar",
    "gui.extension.sen0170.description": "Izmjeri razinu brzine vjetra i signal izlaznog napona",
    "gui.extension.sen0226.name": "BMP280 senzor temperature",
    "gui.extension.sen0226.description": "BMP280 barometarski senzor tlaka za temperaturu i barometrijsko mjerenje",
    "gui.extension.sen0228.name": "I2C VEML7700 senzor ambijentalnog svjetla",
    "gui.extension.sen0228.description": "Digitalni senzor ambijentalnog svjetla visoke preciznosti 16-bitne rezolucije",
    "gui.extension.sen0236.name": "BME280 modul",
    "gui.extension.sen0236.description": "Senzori okoline (temperatura, vlaga, tlak zraka)",
    "gui.extension.sen0517.name": "ICP10111 senzor pritiska",
    "gui.extension.sen0517.description": "Točnost ±1Pa, senzor tlaka zraka visoke preciznosti i visoke stabilnosti",
    "gui.extension.dfr0022.name": "Analogni senzor sivih tonova",
    "gui.extension.dfr0022.description": "Otkrij gustoću svjetlosti i reflektirajte analogni naponski signal, koji se može primijeniti na praćenje linije",
    "gui.extension.motucamera.name": "MU senzorska ploča",
    "gui.extension.motucamera.description": "opis za proširenje 'MU senzorske ploče'",
    "gui.extension.dfr0552.name": "12-bitni DA pretvorbeni modul",
    "gui.extension.dfr0552.description": "Precizno pretvori digitalnu veličinu u odgovarajući signal istosmjernog napona (bez pwm)",
    "gui.extension.sen0117.name": "Modul za sintezu govora",
    "gui.extension.sen0117.description": "Samo utipkaj kineske i engleske znakove i brojeve i ono će ih pročitati",
    "gui.extension.dfr0576.name": "I2C kaskadni produživač",
    "gui.extension.dfr0576.description": "Koristi se za rješavanje sukoba adresa I2C uređaja",
    "gui.extension.sen0248.name": "BME680 senzor okoline",
    "gui.extension.sen0248.description": "Mogu se mjeriti VOC (hlapljivi organski spojevi), temperatura, vlažnost i tlak zraka",
    "gui.extension.sen0304.name": "I2C ultrazvučni senzor za domet",
    "gui.extension.sen0304.description": "Ultrazvučni senzori dizajnirani za brzo mjerenje udaljenosti ili izbjegavanje prepreka",
    "gui.extension.sen0307.name": "Analogni ultrazvučni senzor dometa",
    "gui.extension.sen0307.description": "Otvoreni analogni ultrazvučni modul za mjerenje udaljenosti s dvostrukom sondom",
    "gui.extension.sen0250.name": "BMI160 6-osni inercijski senzor gibanja",
    "gui.extension.sen0250.description": "16-bitno 3-osno ubrzanje + ultra-niska potrošnja energije 3-osni žiroskop",
    "gui.extension.sen0224.name": "I2C LIS2DH troosni akcelerometar",
    "gui.extension.sen0224.description": "Senzor ubrzanja u 3 osi iznimno niske potrošnje energije",
    "gui.extension.dfr0151.name": "Sat realnog vremena DS1307",
    "gui.extension.dfr0151.description": "vremenska pogreška je samo oko 1 sekunde u 24 sata",
    "gui.extension.dfr0469.name": "Sat stvarnog vremena SD2405",
    "gui.extension.dfr0469.description": "Precizan I2C sat stvarnog vremena (RTC) s kristalnom kompenzacijom, unutarnjom punjivom baterijom.",
    "gui.extension.dfr0126.name": "Modul analize spektra",
    "gui.extension.dfr0126.description": "Pratite frekvenciju zvuka",
    "gui.extension.dfr0231.name": "NFC modul",
    "gui.extension.dfr0231.description": "Prikladno za bežičnu komunikaciju na kratkim udaljenostima",
    "gui.extension.sen0245.name": "VL53L0X laserski senzor za domet",
    "gui.extension.sen0245.description": "Koristi TOF tehnologiju za precizno mjerenje udaljenosti, do 2 metra",
    "gui.extension.dfr0026.name": "Analogni senzor ambijentalnog svjetla",
    "gui.extension.dfr0026.description": "Otkrij gustoću ambijentalnog svjetla",
    "gui.extension.dfr0027.name": "Digitalni senzor vibracija",
    "gui.extension.dfr0027.description": "Detektira signal vibracije i daje digitalni signal",
    "gui.extension.dfr0028.name": "Digitalni senzor nagiba",
    "gui.extension.dfr0028.description": "Na temelju broja živinih prekidača, može se koristiti kao jednostavan senzor nagiba",
    "gui.extension.dfr0029.name": "Digitalni gumb",
    "gui.extension.dfr0029.description": "Pritisni prema dolje: visoka razina Otpuštanje: niska razina",
    "gui.extension.dfr0030.name": "Digitalni kapacitivni senzor dodira",
    "gui.extension.dfr0030.description": "Kapacitivni modul prekidača na dodir za senzor metala i ljudskog tijela",
    "gui.extension.sen0318.name": "CCS811 senzor kvalitete zraka",
    "gui.extension.sen0318.description": "CCS811 Mjerljivi CO2, TVOC",
    "gui.extension.sen0315.name": "PAJ7620U2 Senzor pokreta",
    "gui.extension.sen0315.description": "IIC komunikacija, u rasponu do 20 cm, može prepoznati do 13 gesta",
    "gui.extension.sen0497.name": "Senzor temperature i vlage",
    "gui.extension.sen0497.description": "Nova generacija senzora temperature i vlage za I2C komunikaciju, nadograđeni proizvod DHT11",
    "gui.extension.sen0514.name": "ENS160 senzor kvalitete zraka",
    "gui.extension.sen0514.description": "Mjeri TVOC (ukupni hlapljivi organski spojevi), eCO2 (relativni ugljični dioksid), AQI (indeks kvalitete zraka)",
    "gui.extension.sen0518.name": " MAX30102 Senzor za kisik u krvi otkucaja srca",
    "gui.extension.sen0518.description": "Izmjeri ljudski broj otkucaja srca u mirovanju i zasićenost krvi kisikom",
    "gui.extension.sen0376.name": "Senzor za alkohol",
    "gui.extension.sen0376.description": "Tvornička kalibracija, nije potrebna kalibracija, 0-5 ppm",
    "gui.extension.sen0321.name": "senzor zone",
    "gui.extension.sen0321.description": "Mjerenje koncentracije ozona u okolišu",
    "gui.extension.sen0364.name": "Senzor vidljivog spektra",
    "gui.extension.sen0364.description": "Izmjeri vidljivi spektar i identificirajte spektralnu boju",
    "gui.extension.sen0359.name": "Kapacitivni senzor otiska prsta",
    "gui.extension.sen0359.description": "Kapacitivni senzor otiska prsta",
    "gui.extension.kit0176.name": "I2C senzor težine",
    "gui.extension.kit0176.description": "Težina predmeta se može izmjeriti, raspon je 1 kg",
    "gui.extension.dfr0033.name": "Digitalni magnetski senzor",
    "gui.extension.dfr0033.description": "Otkrij magnetske materijale unutar 3 cm",
    "gui.extension.dfr0034.name": "Analogni zvučni senzor",
    "gui.extension.dfr0034.description": "Detektiraj glasnoću u ambijentu",
    "gui.extension.sen0132.name": "Analogni senzor ugljičnog monoksida",
    "gui.extension.sen0132.description": "Otkrij koncentracije CO-plina unutar 20 do 2000 ppm",
    "gui.extension.dfr0051.name": "Analogni djelitelj napona",
    "gui.extension.dfr0051.description": "Detektiraj napon (DC) od 0,0245 V do 25 V",
    "gui.extension.dfr0052.name": "Analogni piezo disk senzor vibracija",
    "gui.extension.dfr0052.description": "Za otkrivanje dinamičkog i kvazi-statičkog naprezanja i izlaznog analognog signala",
    "gui.extension.dfr0058.name": "Analogni senzor rotacijskog potenciometra",
    "gui.extension.dfr0058.description": "Potenciometar visoke točnosti s maksimalnim brojem okretaja od 10",
    "gui.extension.dfr0061.name": "Joystick modul",
    "gui.extension.dfr0061.description": "(X,Y) analogni izlaz, (Z) 1 digitalni izlaz i može se koristiti kao daljinski upravljač",
    "gui.extension.dfr0072.name": "Senzor pomaka",
    "gui.extension.dfr0072.description": "opis za proširenje 'Shiftout sensor'",
    "gui.extension.dfr0563.name": "senzor baterije",
    "gui.extension.dfr0563.description": "opis za proširenje 'senzor baterije'",
    "gui.extension.dfr0553.name": "16-bitni modul AD konverzije",
    "gui.extension.dfr0553.description": "opis proširenja '16-bitni AD konverzijski modul'",
    "gui.extension.dfr0588.name": "Senzor temperature i vlage",
    "gui.extension.dfr0588.description": "Senzor temperature i vlage",
    "gui.extension.dfr0076.name": "Analogni senzor plamena",
    "gui.extension.dfr0076.description": "Otkrij plamen ili svjetlost s valnom duljinom od 760nm-1100nm",
    "gui.extension.dfr0117.name": "Eeprom iic senzor",
    "gui.extension.dfr0117.description": "Modul za pohranu podataka,32kb",
    "gui.extension.dfr0143.name": "Troosni akcelerometar MMA7361",
    "gui.extension.dfr0143.description": "Na temelju MMA7361 čipa, otkriva gestu i smjer kretanja",
    "gui.extension.sen0018.name": "Digitalni infracrveni senzor pokreta",
    "gui.extension.sen0018.description": "Otkrij infracrveno zračenje koje oslobađaju ljudi ili životinje u pokretu",
    "gui.extension.sen0114.name": "Analogni senzor vlage u tlu",
    "gui.extension.sen0114.description": "Pročitaj količinu vlage u tlu koje ga okružuje.",
    "gui.extension.sen0121.name": "Senzor pare",
    "gui.extension.sen0121.description": "Otkrij kišnicu, paru i vodenu maglu",
    "gui.extension.sen0212.name": "Senzor za prepoznavanje boja",
    "gui.extension.sen0212.description": "Identificirajte boje objekata i ispišite RGB vrijednost",
    "gui.extension.sen0253.name": "10DOF senzor položaja",
    "gui.extension.sen0253.description": "BBNO055 je novi senzor IC za implementaciju inteligentnog 9-osnog senzora apsolutne orijentacije.",
    "gui.extension.sen0290.name": "senzor munje",
    "gui.extension.sen0290.description": "inteligentni senzor munje",
    "gui.extension.sen0291.name": "I2C digitalni mjerač snage",
    "gui.extension.huskylens.name": "HUSKYLENS AI kamera",
    "gui.extension.huskylens.description": "Senzor za vid umjetne inteligencije koji podržava prepoznavanje lica i učenje",
    "gui.extension.ps2.name": "PS2 joystick",
    "gui.extension.ps2.description": "PS2 joystick",
    "gui.extension.sen0291.description": "Visoko precizno mjerenje napona, struje i snage",
    "gui.extension.sen0202.name": "3D mini senzor gesta",
    "gui.extension.sen0202.description": "Otkrij rotaciju i kretanje u smjeru kazaljke na satu i suprotno od njega",
    "gui.extension.iobox.name": "mikro:IO-BOX",
    "gui.extension.iobox.description": "Micro:bit namjenska litijska baterijska ploča za proširenje motora",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "Omogući povezivanje uređaja s Wi-Fi mrežom",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "Omogući uređaju dobivanje lokalnog vremena",
    "gui.extension.udp.name": "UDP emitiranje",
    "gui.extension.udp.description": "Neka uređaji i uređaji u lokalnoj mreži ostvaruju višestrojnu komunikaciju putem UDP protokola",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "Neka uređaj traži podatke putem HTTP-a",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "Neka uređaji komuniciraju pomoću MQTT protokola",
    "gui.extension.internet.name": "Internet",
    "gui.extension.internet.description": "Internet komunikacija",
    "gui.extension.speechRecognition.name": "Prepoznavanje govora",
    "gui.extension.speechRecognition.description": "Prepoznavanje govora",
    "gui.extension.pictureai.defaultTitleText": "Video prozor",
    "gui.extension.pictureai.cameraPermissionsTitle": "Potrebna je dozvola za kameru",
    "gui.extension.pictureai.cameraPermissionsMessage": "Idi na postavke i označite autorizaciju. Ako je autorizirana, ali se i dalje ne može koristiti, potvrdite da je kamera normalno povezana i da nije zauzeta drugim softverom ili ponovno pokrenite softver.",
    "gui.extension.pictureai.cameraPermissionsCancel": "Poništi",
    "gui.extension.pictureai.cameraPermissionsGoToSettings": "Idi na postavke",
    "gui.gui.loaded": "Učitano",
    "gui.gui.notLoaded": "NIJE Učitano",
    "gui.gui.cantFindYouWant": "Ne možeš pronaći ono što želiš?",
    "gui.gui.clickHere": "Klikni ovdje",
    "gui.gui.viewHelp": " da tražiš dalje",
    "gui.gui.uploadToDev": "Prenesi",
    "gui.gui.codeArea": "Kôd",
    "gui.gui.fileSys": "sustav datoteka",
    "gui.gui.compileAndUpload": "Sastavljeno i učitano",
    "gui.gui.compileOnly": "Samo kompajlirano",
    "gui.gui.compileOpen": "Kompajlirano i otvoreno",
    "gui.gui.burnBootloader": "Snimi bežični bootloader",
    "gui.gui.arduinoRun": "Pokreni",
    "gui.gui.arduinoSave": "Spremi",
    "gui.gui.autoGenerated": "Auto. generirano",
    "gui.gui.manualEditing": "Ručni unos",
    "gui.gui.codeBox": "Okvir za šifre",
    "gui.gui.moduleCode": "Šifra modula",
    "gui.menu.edit.undo": "Poništi",
    "gui.menu.edit.redo": "Ponovi",
    "gui.menu.edit.selectAll": "Odaberi sve",
    "gui.menu.edit.cut": "Izrezati",
    "gui.menu.edit.copy": "Kopirati",
    "gui.menu.edit.paste": "Zalijepiti",
    "gui.menu.edit.codeStyle": "Stil koda",
    "gui.menu.edit.fontSize": "Veličina fonta",
    "gui.menu.edit.clearCache": "Očisti predmemoriju",
    "gui.menu.edit.maxReset": "Vratite Max na tvorničke postavke",
    "gui.gui.serial": "Serijski",
    "gui.gui.openSerial": "Otvori serijski",
    "gui.gui.closeSerial": "Zatvori serijski",
    "gui.gui.close": "Zatvori",
    "gui.gui.open": "Otvori",
    "gui.gui.clearOutput": "Očisti izlaz",
    "gui.gui.scroll": "Skrolanje",
    "gui.gui.send": "Poslati",
    "gui.gui.baud": "Baud",
    "gui.gui.noLineEnd": "Nema terminatora linije",
    "gui.gui.newLine": "Line Feed",
    "gui.gui.carriageReturn": "Carriage Return",
    "gui.gui.bothNLandCR": "LF & CR",
    "gui.gui.unavailableHexData": "Nevažeći heksadecimalni oblik! Predstavite heksadecimalne podatke s dva znaka ((0-9/A-F) i razmakom, npr. \"00 01 A2 FF\".",
    "gui.gui.hexForm": "Heksadecimalni obrazac",
    "gui.gui.openHexForm": "Koristi heksadecimalni obrazac za prikaz i slanje podataka",
    "gui.gui.closeHexForm": "Zatvoriti",
    "gui.progress.burning": "Zapisujem",
    "gui.progress.burning1": "Zapisujem",
    "gui.progress.compiling": "Sastavljanje",
    "gui.burner.compileError": "Greška pri kompajliranju",
    "gui.burner.linkError": "Pogreška veze",
    "gui.burner.generateHexError": "Generiraj .hex pogrešku",
    "gui.burner.generateBinError": "Generiraj pogrešku .bin",
    "gui.burner.burnError": "Pogreška snimanja",
    "gui.burner.eraseError": "Pogreška brisanja",
    "gui.burner.findLeonardoPortError": "Ne mogu pronaći priključak za pokretanje programa Leonardo",
    "gui.burner.noMicrobit": "Žao nam je, nije micro: bit, molimo poveži micro: bit",
    "gui.burner.swdCfgError": "Nije moguće pisati u cmsis-dap.cfg",
    "gui.burner.UDiskPopUp": "Pojavljuje se UDisk, pričekajte neko vrijeme i pokušaj ponovno",
    "gui.burner.UDiskWriteError": "Pogreška pisanja na UDisk",
    "gui.burner.openPortError": "Nije moguće otvoriti port: pristup je odbijen",
    "gui.burner.firmwareError": "Pogreška firmvera, obnovi i ponovno se poveži",
    "gui.burner.uploadDone": "Prijenos završen",
    "gui.burner.connectPort": "Povezivanje",
    "gui.burner.burnFirmware": "Snimi firmware",
    "gui.burner.writeLibFiles": "Pisanje knjižničnih datoteka",
    "gui.burner.connectError": "Pogreška povezivanja, ponovno se poveži",
    "gui.burner.printObjectTooBig": "Projekt je prevelik; molimo klikni na \"pomoć\" na desnom vrhu kako bi smanjio njegovu veličinu.\nPogreška kompajliranja za ploču [%BOARD]. ",
    "gui.burner.printFlashTooBig": "Nema dovoljno memorije; klikni na \"pomoć\" u gornjem desnom dijelu kako bi smanjio zauzetost memorije.\nPogreška kompajliranja za ploču [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Malo dostupne memorije, mogu se pojaviti problemi sa stabilnošću.",
    "gui.burner.printSizeError": "Nije moguće odrediti veličinu programa.",
    "gui.burner.printSize": "Projekt koristi [%TEXTSIZE] bajtova, zauzima ([%TEXTSIZEPRE]) programsku memoriju, ostavljajući [%TEXTSIZESUR] bajtova, do [%TEXTSIZEMAX] bajtova.\nGlobalne varijable koriste [%DATASIZE] bajtova, ([%DATASIZEPRE]) dinamičke memorije, ostavljajući [%DATASIZESUR] bajtova lokalnih varijabli, do [%DATASIZEMAX] bajtova. ",
    "gui.progress.compileSuccess": "sastaviti uspjeh",
    "gui.progress.compileProgress": "Napredak kompajliranja",
    "gui.progress.uploadProgress": "Napredak prijenosa",
    "gui.progress.uploadSuccess": "Prijenos uspješan",
    "gui.progress.uploadSuccessVm": "Prijenos uspješan",
    "gui.progress.networkInterfaceCard": "NIC",
    "gui.codeLabel.dynamicVariables": "Dinamičke varijable",
    "gui.codeLabel.functionDeclaration": "Deklaracija funkcije",
    "gui.codeLabel.staticConstants": "Statičke konstante",
    "gui.codeLabel.createObject": "Stvorite objekt",
    "gui.codeLabel.mainProgramStart": "Početak glavnog programa",
    "gui.codeLabel.SubthreadProgramStarts": "Pokreće se program podnit %1",
    "gui.codeLabel.customFunction": "Prilagođena funkcija",
    "gui.codeLabel.eventCallbackFunction": "Funkcija povratnog poziva događaja",
    "gui.codeLabel.globalCode": "Globalne varijable",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "Varijable nisu dopuštene!",
    "gui.codeLabel.staticFunctions": "Statičke funkcije",
    "gui.progress.errMsg": "!Pogreška pri učitavanju, provjeri detalje u crnom prozoru dolje desno",
    "gui.progress.errMsg1": "!Pogreška pri učitavanju, provjeri detalje u crnom prozoru dolje desno",
    "gui.progress.errMsg2": "!Pogreška pri učitavanju, provjeri detalje u crnom prozoru dolje desno",
    "gui.howtos.spin-around.name": "Okreni se",
    "gui.howtos.spin.step_dragTurn": "Povuci blok [turn].",
    "gui.howtos.spin.step_clickTurn": "Pritisni blok za pokretanje",
    "gui.howtos.spin.step_clickControl": "Pritisni kategoriju [Kontrola].",
    "gui.howtos.spin.step_dragForever": "Povuci [zauvijek] blok",
    "gui.howtos.spin.step_clickForever": "Pritisni blok za pokretanje",
    "gui.howtos.spin.step_changeColor": "Dodaj blok [promijeni efekt boje].",
    "gui.howtos.say-hello.name": "Reci zdravo",
    "gui.howtos.say-hello.step_addSprite": "Dodaj novi lik",
    "gui.howtos.say-hello.step_clickLooks": "Pritisni kategoriju [Izgled].",
    "gui.howtos.say-hello.step_dragSay": "Povuci blok [recimo].",
    "gui.howtos.say-hello.step_clickSay": "Pritisni blok za pokretanje",
    "gui.howtos.say-hello.step_anotherSay": "Povuci još jedan [recimo] blok",
    "gui.howtos.say-hello.step_editSay": "Reci još nešto",
    "gui.howtos.say-hello.step_clickStack": "Pritisni blok za pokretanje",
    "gui.howtos.run-away.name": "Pobjeći",
    "gui.howtos.run-away.step_dragGoTo": "Povuci blok [idi na slučajni položaj].",
    "gui.howtos.run-away.step_clickGoTo": "Pritisni blok za pokretanje",
    "gui.howtos.run-away.step3": "Pritisni kategoriju [Događaji].",
    "gui.howtos.run-away.step_addWhenClicked": "Dodaj blok \"kada je ovaj lik kliknut\".",
    "gui.howtos.run-away.step_clickSprite": "Pritisni lik za trčanje",
    "gui.howtos.run-away.step_addSound": "Dodaj blok \"početni zvuk\".",
    "gui.howtos.pick-up-apple.name": "Igra hvatanja jabuka",
    "gui.howtos.pick-up-apple.step_1": "Prijeđi na Scratch",
    "gui.howtos.pick-up-apple.step_2": "Preklopni hardver",
    "gui.howtos.pick-up-apple.step_3": "Poveži uređaj",
    "gui.howtos.pick-up-apple.step_4": "Kalibracija kompasa",
    "gui.howtos.pick-up-apple.step_5": "Otvori Scratch primjere programa",
    "gui.howtos.pick-up-apple.step_6": "Igra hvatanja jabuka",
    "gui.howtos.move-left-right.name": "micro:bit i Meow",
    "gui.howtos.move-left-right.step_1": "Napravi novi projekt",
    "gui.howtos.move-left-right.step_2": "Povuci blok1",
    "gui.howtos.move-left-right.step_3": "Povuci blok2",
    "gui.howtos.move-left-right.step_4": "Pomicanje lijevo i desno",
    "gui.howtos.install-driver.name": "Instaliraj upravljački program",
    "gui.howtos.transform-expression.name": "Nasmiješi se ili zaplači",
    "gui.howtos.transform-expression.step_1": "Prijeđi na Code",
    "gui.howtos.transform-expression.step_2": "Preklopni hardver",
    "gui.howtos.transform-expression.step_3": "Povuci blok1",
    "gui.howtos.transform-expression.step_4": "Povuci blok2",
    "gui.howtos.transform-expression.step_5": "Povuci blok3",
    "gui.howtos.transform-expression.step_6": "Pretvori se u osmijeh ili plač",
    "gui.howtos.lot.name": "IOT (Internet of Things)",
    "gui.howtos.lot.step_1": "Odaberi primjere programa",
    "gui.howtos.lot.step_2": "Program za snimanje",
    "gui.howtos.lot.step_3": "Dijagram značajki",
    "gui.howtos.touch-pin.name": "Pin dodir",
    "gui.howtos.touch-pin.step_1": "Prijeđi na Code",
    "gui.howtos.touch-pin.step_2": "Odaberi rutinu",
    "gui.howtos.touch-pin.step_3": "Program za snimanje",
    "gui.howtos.touch-pin.step_4": "Pin dodir",
    "gui.howtos.save-witch.name": "Vještica spašavanje",
    "gui.howtos.save-witch.step_1": "Prijeđi na Scratch",
    "gui.howtos.save-witch.step_2": "Odaberi 'micro:bit'in proširenje",
    "gui.howtos.save-witch.step_3": "Odaberi 'Rescue Witch' u primjerima programa",
    "gui.howtos.save-witch.step_4": "Spoji uređaj, odaberite micro:bit i pričekajte uspješno povezivanje",
    "gui.howtos.save-witch.step_5": "Okreći micro:bit sve dok se LED ne ugasi kada se pojavi poruka [kalibrirajte uređaj].",
    "gui.howtos.save-witch.step_6": "Pritisni zelenu zastavicu i zamahnite rukom za interakciju s animacijom",
    "gui.howtos.microbit-touch.name": "Pritisni za osmijeh ili plač",
    "gui.howtos.microbit-touch.step_1": "Prebaci se na način rada za kodiranje",
    "gui.howtos.microbit-touch.step_2": "Pritisni 'Učenje' da bi otvorio primjere programa i odaberi 'Pin touch'",
    "gui.howtos.microbit-touch.step_3": "Spoji odgovarajući COM port uređaja i klikni 'Učitaj na uređaj'",
    "gui.howtos.microbit-touch.step_4": "Pritisni različite tipke za prikaz različitih izraza",
    "gui.howtos.microbit-calibration.name": "micro:bit kalibracija",
    "gui.howtos.calliope-calibration.name": "calliope Kalibracija",
    "gui.howtos.microbit-calibration.step_1": "Okreni micro:bit ploču i i LED svjetla na ploči će se redom paliti.",
    "gui.howtos.microbit-calibration.step_2": "Sve dok sva vanjska LED svjetla ne budu uključena (kao što je prikazano u nastavku), nakon završetka kalibracije bit će prikazan osmijeh.",
    "gui.extension.sen0251.name": "BMP388 barometarski senzor tlaka",
    "gui.extension.sen0251.description": "Funkcija detekcije temperature, atmosferskog tlaka, nadmorske visine",
    "gui.extension.sen0206.name": "Beskontaktni infracrveni senzor temperature",
    "gui.extension.sen0206.description": "Daleko infracrveno beskontaktno mjerenje temperature objekta ili okoline",
    "gui.gui.recentlyEdited": "Nedavno uređeno",
    "gui.extension.weather.name": "Dohvati vremensku prognozu",
    "gui.extension.weather.description": "Ovdje je potreban Wi-Fi modul za dobivanje informacija o vremenu",
    "gui.extension.weather.description.javascript": "Za dobivanje informacija o vremenu putem Interneta, računalo mora biti spojeno na Internet",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "Upravljaj TinyWebDB mrežnom bazom podataka, može se koristiti s APP Inventor",
    "gui.extension.pictureai.name": "AI prepoznavanje slike",
    "gui.extension.pictureai.description": "učiniti nešto prekrasno sa slikovnom umjetnom inteligencijom, potrebno je povezivanje s internetom",
    "gui.gui.variableScopeOptionAllSprites": "Za sve likove",
    "gui.gui.variableScopeOptionSpriteOnly": "Samo za trenutni lik",
    "gui.gui.variablePromptAllSpritesMessage": "Svi likovi mogu koristiti ovu varijablu.",
    "gui.monitor.contextMenu.default": "Normalno očitavanje",
    "gui.monitor.contextMenu.large": "Veliko očitavanje",
    "gui.monitor.contextMenu.slider": "Klizač",
    "gui.monitor.contextMenu.sliderRange": "promjena raspona klizača",
    "gui.monitor.contextMenu.import": "Uvoz",
    "gui.monitor.contextMenu.export": "Izvoz",
    "gui.monitor.listMonitor.listLength": "duljina {dužina}",
    "gui.monitor.listMonitor.empty": "(prazan)",
    "gui.costumeTab.backdrop": "Pozadina",
    "gui.costumeTab.costume": "Kostim",
    "gui.opcodeLabels.direction": "smjer",
    "gui.opcodeLabels.xposition": "x položaj",
    "gui.opcodeLabels.yposition": "y položaj",
    "gui.opcodeLabels.size": "veličina",
    "gui.opcodeLabels.costumename": "ime nošnje",
    "gui.opcodeLabels.costumenumber": "broj kostima",
    "gui.opcodeLabels.backdropname": "naziv pozadine",
    "gui.opcodeLabels.backdropnumber": "broj pozadine",
    "gui.opcodeLabels.volume": "volumen",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "odgovor",
    "gui.opcodeLabels.loudness": "glasnoća",
    "gui.opcodeLabels.year": "godina",
    "gui.opcodeLabels.month": "mjesec",
    "gui.opcodeLabels.date": "datum",
    "gui.opcodeLabels.dayofweek": "dan u tjednu",
    "gui.opcodeLabels.hour": "sat",
    "gui.opcodeLabels.minute": "minuta",
    "gui.opcodeLabels.second": "drugi",
    "gui.opcodeLabels.timer": "mjerač vremena",
    "gui.loadProject.error": "pogreška učitavanja projekta",
    "gui.fileSystem.fileCatalogue": "Katalog",
    "gui.fileSystem.boardFiles": "Datoteke na ploči",
    "gui.fileSystem.boardFiles2": "Datoteke na ploči",
    "gui.fileSystem.boardFilesMaixduino": "Datoteke na ploči",
    "gui.fileSystem.boardFilesBoson": "BOZON MC1",
    "gui.fileSystem.computerFiles": "Računalne datoteke",
    "gui.fileSystem.userExFiles": "Use-Ex datoteke",
    "gui.fileSystem.newFile": "nova datoteka",
    "gui.fileSystem.newFolder": "nova mapa",
    "gui.fileSystem.deleteFolder": "izbrisati mapu",
    "gui.fileSystem.deleteFile": "izbrisati",
    "gui.fileSystem.rename": "preimenovati",
    "gui.fileSystem.openFolder": "položaj otvorene mape",
    "gui.fileSystem.openFilePos": "otvorena pozicija datoteke",
    "gui.fileSystem.openInEditor": "otvoriti u editoru",
    "gui.fileSystem.runRightNow": "trči odmah",
    "gui.fileSystem.stopRun": "prestati trčati",
    "gui.fileSystem.setBoot": "pokretanje pokretanja",
    "gui.fileSystem.deleteForever": "izbrisati zauvijek",
    "gui.fileSystem.saveAs": "Spremi kao",
    "gui.fileSystem.undoAllEdits": "poništi sve izmjene",
    "gui.fileSystem.copyToComputer": "kopirati na računalo",
    "gui.fileSystem.uploadToBoard": "učitati na ploču",
    "gui.fileSystem.uploadAndRun": "učitati i pokrenuti",
    "gui.wifi.pwdNotLegal": "! Greška: Lozinka je u pogrešnom formatu. Lozinka za Wi-Fi mora imati 8-20 znamenki ili brojeva",
    "gui.spreadmaqueen.perror": "! Pogreška: P12 pinska LED lampa nije dostupna. Odaberi P8 pin LED lampu.",
    "gui.gui.viewConflict": "pogled sukob",
    "gui.gui.clickViewHelp": "Kako riješiti?",
    "gui.gui.conflict": "Sukob",
    "gui.gui.conflictPrompt": "Upit za upozorenje o sukobu",
    "gui.gui.clickPrompt": "Klikni za označavanje blokova, dvaput klikni za lociranje blokova",
    "gui.extension.mpyUART.name": "Serijski UART",
    "gui.extension.mpyUART.description": "UART komunikacija",
    "gui.extension.mpyI2C.name": "I2C komunikacija",
    "gui.extension.mpyI2C.description": "Koristi softver za simulaciju I2C protokola za prijenos podataka",
    "gui.extension.mpyServo.name": "Servo modul",
    "gui.extension.mpyServo.description": "Servo modul",
    "gui.extension.mpyTimer.name": "Timer",
    "gui.extension.mpyTimer.description": "Može redovito izvršavati program",
    "gui.extension.mpyList.name": "Popis",
    "gui.extension.mpyList.description": "Popis",
    "gui.extension.mpyDictionary.name": "Rječnik",
    "gui.extension.mpyDictionary.description": "Rječnik",
    "gui.extension.mpySet.name": "set",
    "gui.extension.mpySet.description": "set",
    "gui.extension.mpyTuple.name": "Tuple",
    "gui.extension.mpyTuple.description": "Tuple",
    "gui.connection.auto-scanning.noPeripheralsFound": "Nema pronađenih uređaja",
    "gui.connection.auto-scanning.prescan": "Imaj svoj uređaj u blizini, a zatim počni tražiti.",
    "gui.connection.auto-scanning.pressbutton": "Pritisni gumb na svom uređaju.",
    "gui.connection.auto-scanning.start-search": "Počni tražiti",
    "gui.connection.connecting-searchbutton": "Pretraživanje...",
    "gui.connection.auto-scanning.try-again": "Pokušaj ponovno",
    "gui.connection.connected": "Povezan",
    "gui.connection.disconnect": "Prekini vezu",
    "gui.connection.go-to-editor": "Idi na Editor",
    "gui.connection.connecting-cancelbutton": "Povezivanje...",
    "gui.connection.error.errorMessage": "Ups, izgleda da nešto nije u redu.",
    "gui.connection.error.tryagainbutton": "Pokušaj ponovno",
    "gui.connection.error.helpbutton": "Pomozi",
    "gui.connection.peripheral-name-label": "Naziv uređaja",
    "gui.connection.connect": "Spojiti",
    "gui.connection.scanning.lookingforperipherals": "Tražim uređaje",
    "gui.connection.scanning.noPeripheralsFound": "Nema pronađenih uređaja",
    "gui.connection.scanning.instructions": "Odaberi svoj uređaj na gornjem popisu.",
    "gui.connection.scanning.instructionsForTello": "Prvo poveži WiFi računalo s Tello ili RMTT, a zatim odaberi gore navedene uređaje.",
    "gui.connection.connect.instructionsForTello": "Prvo poveži Tello ili RMTT na WiFi računalo, a zatim odaberi gore navedene uređaje.",
    "gui.connection.scanning.connectTelloHandle": "Odaberi gore navedeni uređaj ili se ručno poveži na Wi-Fi u zrakoplovu.",
    "gui.connection.search": "Osvježi",
    "gui.connection.unavailable.installscratchlink": "Provjeri imaš li Scratch Link instaliran i pokrenut",
    "gui.connection.unavailable.enablebluetooth": "Provjeri je li Bluetooth omogućen",
    "gui.connection.unavailable.tryagainbutton": "Pokušaj ponovno",
    "gui.connection.unavailable.helpbutton": "Pomoć",
    "gui.extension.ev3.description": "Izgradi interaktivne robote i još puno toga.",
    "gui.extension.ev3.connectingMessage": "Povezivanje. Provjeri je li pin na tvojem EV3 postavljen na 1234.",
    "gui.extension.boost.description": "Oživi robotske kreacije.",
    "gui.extension.boost.connectingMessage": "Povezivanje",
    "gui.extension.wedo2.description": "Koristi motore i senzore.",
    "gui.extension.wedo2.connectingMessage": "Povezivanje",
    "gui.extension.huskyLensPro.name": "HUSKYLENS EDU",
    "gui.extension.huskyLensPro.description": "Koristi HUSKYLENS za izravno povezivanje s računalom kako bi naučio osnove umjetne inteligencije",
    "gui.extension.huskyLensPro.connectingMessage": "huskyLens pokušava zatražiti vezu, molimo pričekajte",
    "gui.extension.machinelearning.name": "strojno učenje (ML5)",
    "gui.extension.machinelearning.description": "na temelju algoritma strojnog učenja, primjena umjetne inteligencije može se realizirati bez mreže",
    "gui.extension.huskylens.pcsetting": "Konfiguriraj kameru računala",
    "gui.extension.huskylens.visualmodal": "AI alati za vizualizaciju",
    "gui.extension.huskylens.KNNText": "KNN klasifikacija simulacije kamere na strani računala",
    "gui.extension.huskylens.huskylensbasic": "Osnovne funkcije",
    "gui.extension.huskylens.algorithmOperationLabel": "Algoritamski rad",
    "gui.extension.huskylens.displayFunctionLabel": "Prikaz",
    "gui.extension.huskylens.cameraFunctionLabel": "Fotografirati",
    "gui.extension.huskylens.modelOperationLabel": "Model Operacija",
    "gui.huskyLensPro.toConnect": "Prvo poveži HUSKYLENS EDU",
    "gui.huskyLensPro.toClassificationData": "Prvo dodaj podatke o klasifikaciji",
    "gui.huskyLensPro.startDeployment": "Započni implementaciju",
    "gui.huskyLensPro.switchingAlgorithmFailed": "Ne može se prebaciti na algoritam \"klasifikacije objekata\"!",
    "gui.huskyLensPro.forgettingFailure": "Podaci o učenju ne mogu se zaboraviti!",
    "gui.huskyLensPro.failedToSetCategoryLabel": "Postavljanje \"oznake kategorije\" nije uspjelo!",
    "gui.huskyLensPro.requestFailed": "Zahtjev \"Raspoređivanje\" nije uspio!",
    "gui.huskyLensPro.deploymentFailed": "Primjena podataka nije uspjela!",
    "gui.huskyLensPro.completed": "Završeno",
    "gui.huskyLensPro.countdown": ", očekuje se da će biti dovršen za [TIME]",
    "gui.huskyLensPro.minutes": " minuta",
    "gui.huskyLensPro.seconds": " sekundi",
    "gui.huskyLensPro.deploying": "Model je u tijeku",
    "gui.huskyLensPro.disconnected": "Veza je prekinuta i implementacija je prekinuta. Provjeri USB vezu!",
    "gui.huskyLensPro.huskylenMaker": "Ova je značajka dostupna samo u obrazovnoj verziji",
    "gui.huskyLensPro.updateVersion": "Poveži se s obrazovnom verzijom i verzija firmvera nije manja od 0.5.1, pogledajte \"Pomoć\" za detalje",
    "gui.huskyLensPro.failedRetry": "Nije uspjelo, pokušaj ponovo!",
    "gui.connection.auto-scanning.notFoundHuskylensTip": "Veza je istekla, potvrdite da je uređaj povezan s HuskyLensom",
    "gui.extension.tello.name": "RoboMaster TT (jednostruki)",
    "gui.extension.tello.description": "Edukativni dron s DJI vrhunskim algoritmima za kontrolu leta, siguran i stabilan",
    "gui.extension.tello.connectingMessage": "Povezivanje... Provjeri je li tello uključen.",
    "gui.extension.telloGroup.name": "RoboMaster TT (tim)",
    "gui.extension.telloGroup.description": "Edukativni dron s DJI vrhunskim algoritmima za kontrolu leta, siguran i stabilan",
    "gui.connection.auto-scanning.notFoundTelloTip": "Isteklo je vrijeme skeniranja, provjeri je li Tello uključen",
    "gui.extension.AIVisualInterface.lable": "AI vizualno sučelje",
    "gui.extension.AIVisualInterface.title": "Nema podataka",
    "gui.extension.AIVisualInterface.imgCount": "[COUNT] slika",
    "gui.extension.AIVisualInterface.delete": "izbrisati",
    "gui.extension.AIVisualInterface.classificationCount": "Ukupno [COUNT] kategorija",
    "gui.extension.AIVisualInterface.recognitionResult": "Rezultat prepoznavanja zaslona je:",
    "gui.extension.AIVisualInterface.possibility": "Mogućnosti su sljedeće:",
    "gui.extension.AIVisualInterface.addClass": "Dodaj klasifikaciju",
    "gui.extension.AIVisualInterface.startTraining": "Počni trenirati",
    "gui.extension.AIVisualInterface.prediction": "Predviđanje video slika",
    "gui.extension.AIVisualInterface.retrain": "Utrenirati",
    "gui.extension.AIVisualInterface.export": "Izvozni model",
    "gui.dialog.baiduServer.togglePersonalAccount": "Trenutačni broj dostupnih vokala dosegao je gornju granicu, prijeđite na svoj osobni račun i prijavite se na konzolu da provjeri kvotu",
    "gui.extension.ai.name": "AI",
    "gui.extension.AI.description": "Poziv modela podrške i obuka",
    "gui.extension.machineVision.name": "Strojni vid",
    "gui.extension.machineVision.description": "Obrada slike, crtanje, filtriranje, prepoznavanje oblika, boja, koda dimenzija, obilježja",
    "gui.extension.machineHearing.name": "Strojno slušanje",
    "gui.extension.machineHearing.description": "Prepoznavanje govora kroz obuku",
    "gui.extension.maixduino.name": "Maixduino",
    "gui.extension.maixduino.description": "Glavna upravljačka ploča bazirana na K210 čipu",
    "gui.extension.boson.name": "Bozon MC1",
    "gui.extension.boson.description": "Glavna upravljačka ploča temeljena na BOSON-u",
    "gui.extension.pyTurtle.name": "Crtanje kornjače",
    "gui.extension.pyTurtle.description": "Postoje tisuće moždanih rupa, između veličina",
    "gui.extension.pyGenerator.name": "Generator koda",
    "gui.extension.pyGenerator.description": "Piši kod izravno na slikovne blokove kako bi postigao miješanje grafike i koda",
    "gui.extension.unihiker.name": "UNIHIKER",
    "gui.extension.unihiker.description": "Matična ploča nove generacije za potpuno učenje pythona",
    "gui.extension.python.save": "Spremi",
    "gui.extension.python.execute": "Pokreni",
    "gui.extension.python.pipmanger": "Upravljanje knjižnicom",
    "gui.extension.python.filesystem": "Sustav datoteka",
    "gui.extension.python.fileinprogram": "Datoteka u projektu",
    "gui.extension.python.fileinpc": "Datoteka u računalu",
    "gui.extension.python.terminal": "Terminal",
    "gui.extension.python.clearterminal": "Obriši izlaz",
    "gui.extension.python.tabcode": "Programski kod",
    "gui.extension.python.piplist": "Popis biblioteka",
    "gui.extension.python.commendatory": "Preporučena biblioteka",
    "gui.extension.python.handleinstall": "PIP način",
    "gui.extension.python.selectall": "Odaberi sve [COUNT]",
    "gui.extension.python.update": "Ažuriraj",
    "gui.extension.python.uninstall": "Deinstaliraj",
    "gui.extension.python.install": "Instalirati",
    "gui.extension.python.installing": "Instaliranje...",
    "gui.extension.python.installed": "instalirano",
    "gui.extension.python.piphandleinstall": "PIP ručna instalacija",
    "gui.extension.python.loadmore": "Učitaj više...",
    "gui.extension.python.hidemore": "Sakrij više...",
    "gui.extension.python.noavailable": "Nema dostupne knjižnice, prvo je preuzmite",
    "gui.extension.python.enterCommand": "Prvo unesi naziv knjižnice",
    "gui.extension.python.finishedtips": "Naredba je završila s radom",
    "gui.extension.python.stop": "Stop",
    "gui.extension.python.executing": "Pokreni",
    "gui.extension.python.editorArea": "Područje koda",
    "gui.extension.python.toolbox": "Grafičko područje",
    "gui.extension.python.autoCreate": "Automatski generiraj",
    "gui.extension.python.emptyEditor": "Klikni gumb <b>Nova datoteka</b> iza <b>Datoteke u projektu</b> s desne strane kako bi stvorio .py datoteku",
    "gui.extension.python.userDocument": "Dokument pomoći",
    "gui.extension.xiamiboard.name": "Xia Mi ploča za proširenje",
    "gui.extension.xiamiboard.description": "Višenamjenska ploča za proširenje temeljena na micro:bitu za programersko obrazovanje",
    "gui.gui.saveSb3ToCloud": "Spremi u oblak",
    "gui.extension.pyMqtt.name": "MQTT-py",
    "gui.extension.pyMqtt.description": "Komunikacija putem MQTT preko PC Interneta",
    "gui.extension.pyGame.name": "Pygame",
    "gui.extension.pyGame.description": "Stvorite vlastite igre i animacije s Pythonom",
    "gui.extension.pinpongBase.name": "pinpong inicijalizirati",
    "gui.extension.pinpongBase.description": "inicijalizirati pinpong knjižnicu odaberite funkciju ploče i GPIO",
    "gui.extension.pinpongServo.name": "Mikro servo",
    "gui.extension.pinpongServo.description": "Može se rotirati od 0 ~ 180 stupnjeva do određenog kuta",
    "gui.extension.pinpongIrq.name": "Prekinuti",
    "gui.extension.pinpongIrq.description": "pinpong Prekinuti pin",
    "gui.extension.pinpongTone.name": "zujalica",
    "gui.extension.pinpongTone.description": "pinpong modul zujalice",
    "gui.extension.pinpongSr04_urm10.name": "Ultrazvučni senzor",
    "gui.extension.pinpongSr04_urm10.description": "Precizno otkrivanje udaljenosti s rasponom 2~800 cm, kompatibilno s urm i SR04 ultrazvukom",
    "gui.extension.pinpongDht.name": "DHT11/22 senzor temperature i vlažnosti",
    "gui.extension.pinpongDht.description": "Otkrij temperaturu i vlažnost okoline",
    "gui.extension.pinpongNeopixel.name": "WS2812 RGB LED traka",
    "gui.extension.pinpongNeopixel.description": "Kontroliraj promjene LED trake kako bi proizveo efekte kao što su bljeskanje, tečenje, skakanje itd.",
    "gui.extension.pinpongOled12864.name": "OLED zaslon",
    "gui.extension.pinpongOled12864.description": "Mali zaslon, pogodan za pametne nosive uređaje",
    "gui.extension.pinpongDS18B20.name": "DS18B20 senzor temperature",
    "gui.extension.pinpongDS18B20.description": "Detektiraj temperaturu okoline u širokom rasponu od -55~+125 ℃",
    "gui.extension.pinpongMlx90614.name": "Beskontaktni infracrveni senzor temperature",
    "gui.extension.pinpongMlx90614.description": "Daleko infracrveno beskontaktno mjerenje temperature objekta ili okoline",
    "gui.extension.pinpongBmp388.name": "BMP388 barometarski senzor tlaka",
    "gui.extension.pinpongBmp388.description": "Funkcija detekcije temperature, atmosferskog tlaka, nadmorske visine",
    "gui.extension.pinpongIRRecv.name": "Modul IR prijemnika",
    "gui.extension.pinpongIRRecv.description": "Primite standardni infracrveni signal od 38 KHz",
    "gui.extension.obloqMp.name": "OBLOQ IoT modul",
    "gui.extension.obloqMp.description": "Wi-Fi na serijski uređaj",
    "gui.extension.pyAutoGui.name": "PyAutoGui",
    "gui.extension.pyAutoGui.description": "PyAutoGUI omogućuje tvojim Python skriptama da kontroliraju miš i tipkovnicu kako bi automatizirali interakcije s drugim aplikacijama.",
    "gui.extension.pyRequests.name": "Zahtjevi",
    "gui.extension.pyRequests.description": "HTTP za ljude",
    "gui.extension.pyJson.name": "JSON",
    "gui.extension.pyJson.description": "JSON koder i dekoder",
    "gui.extension.pySchedule.name": "Raspored",
    "gui.extension.pySchedule.description": "Python raspored poslova za ljude.",
    "gui.extension.pyXlrd.name": "xlrd",
    "gui.extension.pyXlrd.description": "execl čitanje, podrška .xls",
    "gui.extension.pyXlwt.name": "xlwt",
    "gui.extension.pyXlwt.description": "execl pisanje, podrška .xls",
    "gui.extension.pyOpenCV.name": "OpenCV",
    "gui.extension.pyOpenCV.description": "OpenCV je biblioteka otvorenog koda koja uključuje nekoliko stotina algoritama računalnog vida.",
    "gui.account.dialog.prompt": "Prompt",
    "gui.account.dialog.tips": "Prompt",
    "gui.account.dialog.signoutAlert": "Nećeš moći koristiti pohranu u oblaku i druge funkcije nakon što se odjavite. Jesi li siguran da se želiš odjaviti?",
    "gui.account.dialog.signoutSuccess": "Odjavljen",
    "gui.account.dialog.sure": "U redu",
    "gui.account.dialog.cancel": "Poništi",
    "gui.account.dialog.error": "Greška",
    "gui.account.dialog.save": "Spremi",
    "gui.account.dialog.getCloudFileFailed": "Pogreška pri preuzimanju datoteke u oblaku [NAME], provjeri postoji li datoteka",
    "gui.account.dialog.getCloudError": "Pogreška prilikom preuzimanja datoteke u oblaku [NAME]",
    "gui.account.dialog.loadCloudSuccess": "Učitavanje datoteke oblaka [NAME] je uspjelo",
    "gui.account.dialog.saveCloudError": "Nije uspjelo spremanje [NAME] u oblak, pokušaj kasnije.",
    "gui.account.dialog.saveCloudSuccess": "Uspješno spremite [NAME] u oblak",
    "gui.account.dialog.saveCloudCheckError": "Spremanje nije uspjelo, pogreška provjere datoteke u oblaku, pokušaj ponovno kasnije",
    "gui.account.dialog.saveCloudRepeatPrompt": "Datoteka [NAME] s istim nazivom već postoji u oblaku. Želiš li ga prebrisati?",
    "gui.account.dialog.saveCloudRepeatAlert": "Datoteka s istim nazivom 「[NAME]」 već postoji u oblaku, promijenite naziv",
    "gui.account.dialog.saveCover": "Prebrisati",
    "gui.account.dialog.rename": "Preimenovati",
    "gui.account.dialog.newProject": "Nova datoteka",
    "gui.account.dialog.projectName": "naziv projekta",
    "gui.account.dialog.enterName": "Unesi naziv datoteke",
    "gui.account.dialog.nameWithSymbol": "Naziv datoteke ne smije sadržavati posebne znakove, promijenite ga",
    "gui.account.dialog.nameBeyondLength": "Dužina naziva datoteke ne smije biti veća od 30",
    "gui.account.dialog.renameSuccess": "Uspješno preimenujte datoteku [NAME1] u [NAME2].",
    "gui.account.dialog.renameFailed": "Preimenovanje datoteke nije uspjelo",
    "gui.account.dialog.downloadSuccess": "[NAME] preuzimanje dovršeno",
    "gui.account.dialog.cancelDownload": "Odustani od preuzimanja",
    "gui.account.dialog.deleteSucess": "Brisanje datoteke u oblaku [NAME] bilo je uspješno",
    "gui.account.dialog.saveAs": "Spremi kao",
    "gui.account.dialog.deleteFailed": "Pogreška pri brisanju datoteke u oblaku [NAME]",
    "gui.account.dialog.deletePrompt": "Jesi li siguran da želiš izbrisati datoteku u oblaku [NAME]?",
    "gui.account.open": "Otvorena",
    "gui.account.upload": "Učitaj",
    "gui.account.delete": "Izbrisati",
    "gui.account.usersSpace": "Projekt oblaka korisnika [NAME].",
    "gui.account.cloudDataLoading": "Podaci iz oblaka se učitavaju, pričekajte",
    "gui.account.emptyUserSpace": "Ovo je prazno, Idi i prenesi tvoj prvi projekt.",
    "gui.account.dialog.login": "Prijaviti se",
    "gui.account.dialog.unsupportProject": "Pogreška pri otvaranju projekta, nepodržani projekt",
    "gui.account.dialog.shouldLoginFirst": "Usluga u oblaku treba podatke o tvom računu, prvo se prijavi",
    "gui.account.dialog.loginTimeout": "Istek vremena prijave",
    "gui.account.account": "Račun",
    "gui.account.serverAddr": "Adresa poslužitelja",
    "gui.account.thirdPartyAccount": "Treća strana",
    "gui.account.dialog.saveCloudRenamePrompt": "Naziv datoteke [IME] već postoji, želiš li promijeniti naziv?",
    "gui.account.dialog.signup": "Prijavi se",
    "gui.account.dialog.phoneNumber": "Broj mobitela",
    "gui.account.dialog.password": "Zaporka",
    "gui.account.dialog.autoLogin": "Zapamti lozinku",
    "gui.account.dialog.forgotPasswd": "Zaboravio si lozinku?",
    "gui.account.dialog.getCheckCode": "Nabavi kontrolni kod",
    "gui.account.dialog.reSend": "Ponovno pošalji",
    "gui.account.dialog.checkCode": "Kontrolni kod",
    "gui.account.dialog.readAndAgree": "Pročitao sam i prihvatio",
    "gui.account.dialog.userAgreement": "Korisnički ugovor",
    "gui.account.dialog.loginSuccess": "Prijava uspješna",
    "gui.account.dialog.passwordError": "Račun ili lozinka su pogrešni, provjeri jesu li točni",
    "gui.account.dialog.checkNumberSended": "Kontrolni kod je poslan, pažljivo ga provjeri",
    "gui.account.dialog.checkedAgree": "Pristati na Ugovor",
    "gui.account.dialog.networkError": "Mrežna pogreška, provjeri vezu i pokušaj ponovno",
    "gui.account.dialog.readProtocolAndCheck": "Pročitaj korisnički ugovor i označite slažem se",
    "gui.account.dialog.signupError": "Greška pri prijavi,",
    "gui.account.dialog.logininErrorWithNetwork": "Pogreška veze s poslužiteljem, prijava nije uspjela, provjeri mrežu",
    "gui.account.dialog.signupErrorDefault": "Registracija nije uspjela, pokušaj ponovo",
    "gui.account.dialog.accountExist": "Račun već postoji, prijavite se izravno",
    "gui.account.dialog.phoneCodeError": "Registracija nije uspjela, greška koda za provjeru",
    "gui.account.dialog.signupSuccess": "Registracija je uspješna, vratite se na sučelje za prijavu za nastavak operacije",
    "gui.account.dialog.inputEmpty": "Ne može biti prazno",
    "gui.account.dialog.phoneFormatIllegal": "Pogreška formata korisničkog imena",
    "gui.python.remoteSSH.connectSuccess": "Uspješno spojen na [ADDRESS]",
    "gui.python.remoteSSH.connectFailed": "Veza [ADDRESS] nije uspjela",
    "gui.python.remoteSSH.shouleReconnect": "Prekinuta veza s [ADDRESS], želiš li se ponovno povezati?",
    "gui.python.remoteSSH.reconnect": "Ponovno se Spoji",
    "gui.python.remoteSSH.disconnected": "Prekinuta veza s [ADDRESS]",
    "gui.python.remoteSSH.accountCheckFailed": "Podaci o računu su pogrešni, veza s [ADDRESS] nije uspjela, provjeri i unesi",
    "gui.python.remoteSSH.connectError": "Pogreška pri povezivanju s [ADDRESS], [MSG]",
    "gui.python.remoteSSH.sshLogin": "SSH prijava",
    "gui.python.remoteSSH.address": "Adresa",
    "gui.python.remoteSSH.addressCantEmpty": "Adresa ne može biti prazna",
    "gui.python.remoteSSH.user": "Korisničko ime",
    "gui.python.remoteSSH.userCantEmpty": "Korisničko ime ne može biti prazno",
    "gui.python.remoteSSH.passwd": "Zaporka",
    "gui.python.remoteSSH.passwdCantEmpty": "Lozinka ne može biti prazna",
    "gui.python.remoteSSH.areYouSureDisconnect": "Trenutno si povezan s daljinskim upravljačem [ADDRESS], jesi li siguran da prekidaš vezu?",
    "gui.python.remoteSSH.statusConnecting": "Povezivanje",
    "gui.python.remoteSSH.disconnectDevice": "OdSpoji daljinski terminal",
    "gui.python.remoteSSH.connectDevice": "Spoji daljinski terminal",
    "gui.python.remoteSSH.statusError": "Greška u povezivanju",
    "gui.python.remoteSSH.statusDisconnect": "Veza je prekinuta",
    "gui.python.remoteSSH.handleInput": "Ručni unos",
    "gui.python.remoteSSH.openNetNetWork": "Otvori mrežni centar",
    "gui.python.remoteSSH.goHelp": "Dokument pomoći",
    "gui.python.remoteSSH.waitForConnecting": "Povezivanje s [ADDRESS], pričekajte...",
    "gui.python.remoteSSH.cancelConnect": "Otkaži vezu",
    "gui.python.remoteSSH.connectTimeout": "Veza je istekla",
    "gui.python.remoteSSH.addressIsLost": "Adresa ne postoji",
    "gui.python.remoteSSH.connectRefused": "Zahtjev za povezivanje je odbijen, provjeri postavke udaljenog sustava i pokušaj ponovno",
    "gui.python.remoteSSH.runGUIProgramTips": "Kada pokrećeš grafički program, ako želiš vidjeti učinak na udaljenoj radnoj površini, trebaš koristiti korisničko ime koje je trenutno prijavljeno na udaljeni sustav",
    "gui.python.remoteSSH.executeFileError": "Pogreška pri pokretanju projekta",
    "gui.python.remoteSSH.waitForProjectUpload": "Projekt [NAME] se učitava, pričekajte...",
    "gui.python.remoteSSH.projectUploadSuccess": "Projekt je uspješno učitan, počeo se izvoditi",
    "gui.python.remoteSSH.projectUploadError": "Pogreška pri prijenosu projekta",
    "gui.python.filetree.boardNameInFT": "Datoteka u [BOARD]",
    "gui.python.filetree.replaceFilePrompt": "Datoteka ili mapa pod nazivom [NAME] već postoji u ciljnoj mapi. Želiš li to zamijeniti?",
    "gui.python.filetree.fileUploading": "Prenesi datoteku [NAME]...",
    "gui.python.filetree.fileSaving": "Spremi datoteku [NAME]...",
    "gui.python.filetree.dirSaving": "Spremi mapu [NAME]...",
    "gui.python.filetree.saveAs": "Spremi kao",
    "gui.python.filetree.chooseDir": "Odaberi mapu",
    "gui.python.filetree.getDirList": "Preuzmi imenik...",
    "gui.python.filetree.getDirListWithPath": "Pročitaj direktorij [PATH].",
    "gui.python.filetree.getRemoteDirList": "Čitanje udaljenog [PATH] direktorija",
    "gui.python.filetree.renameFile": "Preimenuj [FROM] u [TO]",
    "gui.python.filetree.deleteDirPrompt": "Jesi li siguran da želiš izbrisati mapu [NAME] i datoteke koje sadrži?",
    "gui.python.filetree.deleteFilePrompt": "Jesi li siguran da želiš izbrisati datoteku [NAME]?",
    "gui.python.filetree.downloadToPC": "Preuzmi datoteku na računalo",
    "gui.python.filetree.uploadToRemote": "Prenesi datoteku na",
    "gui.python.filetree.exportFileTo": "Izvoz [EXT] datoteke",
    "gui.python.filetree.rmFromeFileSystem": "Ukloni iz datotečnog sustava",
    "gui.python.filetree.rmDirPrompt": "Jesi li siguran da želiš ukloniti lokalnu stazu [NAME] i njezine poddatoteke?",
    "gui.python.filetree.rmAllDirPrompt": "Jesi li siguran da želiš ukloniti sve lokalne staze?",
    "gui.python.filetree.addLocalDir": "Dodaj lokalnu mapu",
    "gui.python.filetree.removeActionDirWithAll": "Ukloni sve lokalne mape",
    "gui.python.filetree.fileNameIsRequired": "Morate unijeti naziv datoteke ili mape",
    "gui.python.filetree.fileNameIsRepeat": "Datoteka ili mapa [NAME] već postoji na ovoj lokaciji. Odaberi drugo ime.",
    "gui.python.filetree.dragParentNodeToChild": "Nadređena mapa ne može se premjestiti u podmapu",
    "gui.python.filetree.dragPathIsConsistent": "Putanja povlačenja datoteke je dosljedna",
    "gui.python.filetree.fileOperateError": "Pogreška u radu datoteke",
    "gui.python.filetree.fileTreeMore": "Više",
    "gui.python.filetree.copy": "kopirati",
    "gui.python.filetree.paste": "zalijepiti",
    "gui.python.worker.initPythonHomeDir": "Inicijalizacija mape...",
    "gui.python.worker.copyPythonSource": "Inicijalizacija mape je dovršena, kopiranje datoteka Python resursa...",
    "gui.python.worker.copyPythonSourceError": "Pogreška pri radu s datotekom, nije uspjela inicijalizacija Python okruženja, ponovno uđi u Python način rada ili ponovno pokreni softver",
    "gui.python.worker.unzipPythonSource": "Kopiranje datoteka resursa je dovršeno, raspakiranje...",
    "gui.python.worker.checkPythonSource": "Izdvajanje je dovršeno, datoteka se provjerava...",
    "gui.python.worker.unzipPythonSourceError": "Pogreška raspakiranja, nije uspjelo inicijalizirati Python okruženje, ponovno uđite u Python način rada ili ponovno pokrenite softver",
    "gui.python.xterm.local": "Lokalni",
    "gui.python.xterm.detectPythonEnv": "Otkrivanje okruženja, molimo pričekaj...",
    "gui.python.xterm.installingPythonEnv": "Instalacija okruženja, molimo pričekaj…",
    "gui.python.xterm.installPythonEnvError": "Inicijalizacija okruženja Python nije uspjela, kontaktiraj tehničku podršku",
    "gui.python.xterm.detectPythonEnvFinished": "Dovršeno otkrivanje okruženja, počinje...",
    "gui.python.xterm.installPythonEnvFinished": "Instalacija okruženja je završena, počinje…",
    "gui.python.xterm.toRestartXterm": "Dvaput klikni za ponovno pokretanje terminala",
    "gui.python.xterm.showRemoteConnect": "Otvori terminal za daljinsko povezivanje",
    "gui.python.xterm.remoteConnectShowed": "Terminal za daljinsku vezu je otvoren",
    "music.categoryName": "glazba",
    "translate.categoryName": "Prevedi",
    "sensor.categoryName": "Senzor",
    "actuator.categoryName": "Pokretač",
    "communicate.categoryName": "Komunicirati",
    "display.categoryName": "Prikaz",
    "extendFunction.categoryName": "Funkcija",
    "internetService.categoryName": "Internet",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "calliope.categoryName": "CALLIOPE",
    "esp32.categoryName": "handpy",
    "telloesp32.categoryName": "RMTT",
    "spread.categoryName": "Pločica za proširenje",
    "speechRecognition.categoryName": "Prepoznavanje govora",
    "thirdExpansion.categoryName": "Proširenja korisnika",
    "gui.blocklyText.blockTitle.eventBlock": "programiranje događaja",
    "gui.blocklyText.blockTitle.dotScreen": "LED zaslon",
    "gui.blocklyText.blockTitle.music": "glazba",
    "gui.blocklyText.blockTitle.onBoardSensor": "senzori pločice",
    "gui.blocklyText.blockTitle.pinOperation": "korištenje pin-ova",
    "gui.blocklyText.blockTitle.wirelessCommunication": "bežična komunikacija",
    "gui.blocklyText.blockTitle.serialportOperation": "korištenje serijskog porta",
    "gui.blocklyText.blockTitle.systemResource": "resurs sustava",
    "gui.blocklyText.blockTitle.screenDisplay": "ekranski prikaz",
    "gui.blocklyText.blockTitle.ledControl": "led kontrola",
    "gui.blocklyText.blockTitle.functionalModule": "funkcionalni modul",
    "gui.blocklyText.blockTitle.movement": "pokret",
    "gui.blocklyText.blockTitle.expression": "izraz",
    "gui.blocklyText.blockTitle.light": "svjetlo",
    "gui.blocklyText.blockTitle.dance": "ples",
    "gui.blocklyText.blockTitle.sensor": "senzor",
    "gui.blocklyText.blockTitle.motorControl": "upravljanje motorom",
    "gui.blocklyText.blockTitle.kitSensor": "kit senzor",
    "gui.blocklyText.blockTitle.kitActuator": "kit aktuator",
    "gui.blocklyText.blockTitle.commonModule": "zajednički modul",
    "gui.blocklyText.blockTitle.infraredCommunication": "infracrvena komunikacija",
    "gui.blocklyText.blockTitle.mp3Module": "mp3 modul",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 RGB svjetlo",
    "gui.blocklyText.blockTitle.LCDDisplay": "LCD zaslon",
    "gui.blocklyText.blockTitle.digitalTube": "digitalna cijev",
    "gui.blocklyText.blockTitle.oledDisplay": "OLED zaslon",
    "gui.blocklyText.blockTitle.onBoardSource": "resurs pločice",
    "gui.blocklyText.blockTitle.rgbControl": "Kontrola RGB svjetla",
    "gui.blocklyText.blockTitle.maxbotResource": "max:bot resurs",
    "gui.blocklyText.blockTitle.lineInspection": "linijski pregled",
    "gui.blocklyText.blockTitle.outerSensor": "vanjski senzor",
    "gui.blocklyText.blockTitle.consolePrint": "konzolni izlaz",
    "gui.blocklyText.blockTitle.radio": "radio",
    "gui.blocklyText.blockTitle.wifi": "wifi",
    "gui.blocklyText.blockTitle.motor": "motor",
    "gui.blocklyText.blockTitle.rgblightcontrol": "ploča vodi",
    "gui.blocklyText.romeo.d1ProgramStart": "Pokreće se robotski program D1",
    "gui.blocklyText.romeo.readdigital": "očitavanje vrijednosti pin[PIN] [MODULE]",
    "gui.blocklyText.romeo.buttonModule": "Modul gumba",
    "gui.blocklyText.romeo.digitalSensor": "Digitalni senzor",
    "gui.blocklyText.romeo.collisionSensor": "Senzor sudara",
    "gui.blocklyText.romeo.lineSensor": "Senzor linije",
    "gui.blocklyText.romeo.infraredSwitch": "Infracrveni prekidač",
    "gui.blocklyText.romeo.vibrationSensor": "Senzor vibracija",
    "gui.blocklyText.romeo.readAnalog": "očitaj pin [PIN] [SENSOR]",
    "gui.blocklyText.romeo.lightSensor": "Svijetli senzor",
    "gui.blocklyText.romeo.anologSensor": "Anologni senzor",
    "gui.blocklyText.romeo.soundSensor": "Senzor zvuka",
    "gui.blocklyText.romeo.soilMoistureSensor": "Senzor vlage u tlu",
    "gui.blocklyText.romeo.frameSensor": "Senzor okvira",
    "gui.blocklyText.romeo.open": "Uključiti",
    "gui.blocklyText.romeo.close": "Isključiti",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] pina [PIN]",
    "gui.blocklyText.romeo.ledLights": "LED svjetla",
    "gui.blocklyText.romeo.digitalActuator": "Digitalni aktuator",
    "gui.blocklyText.romeo.relay": "Relej",
    "gui.blocklyText.romeo.fan": "Ventilator",
    "gui.blocklyText.romeo.writeAnalog": "postavi pin [PIN] [MODULE] na [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "Simulacijski aktuator",
    "gui.blocklyText.romeo.fanModule": "Modul ventilatora",
    "gui.blocklyText.romeo.robotSpeedMove": "robot [DIR] brzinom [SPEED]",
    "gui.blocklyText.romeo.forward": "kreni naprijed",
    "gui.blocklyText.romeo.backward": "kreni unatrag",
    "gui.blocklyText.romeo.turnLeft": "skreni lijevo",
    "gui.blocklyText.romeo.turnRight": "skreni desno",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "motor [MOTOR] [DIR] brzinom [SPEED]",
    "gui.blocklyText.romeo.interface": "Sučelje",
    "gui.blocklyText.romeo.motorForward": "naprijed",
    "gui.blocklyText.romeo.motorReverse": "nazad",
    "gui.blocklyText.romeo.stopMotor": "robot (motori M1 i M2) prestati se kretati",
    "gui.blocklyText.romeo.readUltrasonic": "Očitavanje ultrazvučne udaljenosti (cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "očitaj pin [PIN] LM35 temperatura (°C)",
    "gui.blocklyText.romeo.ds18b20": "očitaj pin [PIN] DS18B20 temperatura (°C)",
    "gui.blocklyText.romeo.pressInfrared": "infracrveni pin [PIN] primljeni gumb [BUTTON] pritisnut signal？",
    "gui.blocklyText.romeo.volumePlus": "Glasnoća+",
    "gui.blocklyText.romeo.switch": "Sklopka",
    "gui.blocklyText.romeo.volumeMinus": "Volumen-",
    "gui.blocklyText.romeo.startPause": "Start/Pauza",
    "gui.blocklyText.romeo.up": "Gore",
    "gui.blocklyText.romeo.down": "dolje",
    "gui.blocklyText.romeo.dataReadable": "Postoje li podaci za čitanje na serijskom portu?",
    "gui.blocklyText.romeo.readSerialData": "čitaj serijske podatke",
    "gui.blocklyText.romeo.timer": "vrijeme rada sustava (ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "pin [PIN] sviraj ton trube [TONE] u trajanju od [BEAT] takta",
    "gui.blocklyText.romeo.half": "Pola",
    "gui.blocklyText.romeo.quarter": "Četvrtina",
    "gui.blocklyText.romeo.oneInEighth": "Jedna osmina",
    "gui.blocklyText.romeo.wholeBeat": "Cijeli ritam",
    "gui.blocklyText.romeo.doubleBeat": "Dvostruki udarac",
    "gui.blocklyText.romeo.stop": "Stop",
    "gui.blocklyText.romeo.setServo": "postavi pin [PIN] servo na [DEGREE] stupnjeva",
    "gui.blocklyText.romeo.segment": "osmosegmentni LED (D6-D13 pin) zaslon [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "serijski izlaz [STRING] [WAY], [LINE]",
    "gui.blocklyText.romeo.originalOutput": "izvorni",
    "gui.blocklyText.romeo.stringOutput": "znakovni niz",
    "gui.blocklyText.romeo.hexOutput": "HEX",
    "gui.blocklyText.romeo.wrap": "Wrap",
    "gui.blocklyText.romeo.noWrap": "No-Wrap",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "čitaj serijske podatke [READTYPE]",
    "gui.blocklyText.arduinounoR3.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.romeo.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.max.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.maxbot.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.microbit.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.arduino.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.arduinoNano.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.mega2560.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.firebeetleesp32.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.romeo.int": "int",
    "gui.blocklyText.romeo.float": "float",
    "gui.blocklyText.romeo.setBaudRate": "postavi brzinu prijenosa serijskog porta na [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "Servo [INDEX] kut [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "očitaj pin [PIN] širinu impulsa pod [LEVEL] modom, timeout [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "NISKI",
    "gui.blocklyText.romeo.high": "VISOKI",
    "gui.blocklyText.romeo.noSound": "bez zvuka",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino se pokreće",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Pokreni Uno",
    "gui.blocklyText.boson.starts": "pokreni BOSON MC1",
    "gui.blocklyText.esp32.displayInLine": "prikazati [TEXT] u retku [LINE].",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "predmemorija [TEXT] u [LINE] načinu retka [SHOWMODE]",
    "gui.blocklyText.esp32.normal": "normalan",
    "gui.blocklyText.esp32.rev": "rev",
    "gui.blocklyText.esp32.trans": "trans",
    "gui.blocklyText.esp32.XOR": "XOR",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "brisanje predmemorije u retku [LINE].",
    "gui.blocklyText.esp32.displayClearInLine": "jasno u retku [LINE].",
    "gui.blocklyText.esp32.buzzPinC": "P6 (ugrađeni zvučni signal)",
    "gui.blocklyText.esp32.buzzerRedirect": "Zujalica preusmjerena na pin [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "svi",
    "gui.blocklyText.esp32.setTouchThreshold": "Vrijednost dodira gumba [PIN] je [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "Očitaj vrijednost dodira gumba [PIN].",
    "gui.blocklyText.esp32.arduinoUnoStarts": "pokreni ESP32",
    "gui.blocklyText.esp32.btnIsPressed": "Gumb [BUTTON] [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "kada gumb [REFERENCE] [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "kada je pin [REFERENCE] [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "Kada [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "pin [PIN] je [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "zaustavi reprodukciju u pozadini",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "sviraj notu [NOTE] u pozadini",
    "gui.blocklyText.esp32.setBuzzerFreq": "sviraj notu [NOTE] za [BEAT] takt",
    "gui.blocklyText.esp32.getSoundIntensity": "očitaj intenzitet zvuka mikrofona",
    "gui.blocklyText.esp32.readBrightness": "očitaj svjetlinu ambijentalnog svjetla",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] prikazuju [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "crvena [RED] zelena [GREEN] plava [BLUE]",
    "gui.blocklyText.esp32.acceleration": "ubrzanje čitanja (m-g) [TYPE]",
    "gui.blocklyText.esp32.esp32_tiltAngle": "očitavanje kuta nagiba [TYPE]",
    "gui.blocklyText.esp32.esp32_boardShakeThrown": "ploča je bila [ATTITUDE]?",
    "gui.blocklyText.esp32.esp32_boardTiltState": "naginjanje ploče [GESTUREMPY]",
    "gui.blocklyText.esp32.forward": "naprijed",
    "gui.blocklyText.esp32.back": "leđa",
    "gui.blocklyText.esp32.left": "lijevo",
    "gui.blocklyText.esp32.right": "pravo",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationRange": "postavi raspon ubrzanja [ACCRANGE]",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationResolution": "postavi rezoluciju ubrzanja [ACCRESOLUTION]",
    "gui.blocklyText.esp32.esp32_mpyAccurateAccValue": "točna vrijednost ubrzanja Odstupanje osi X [XAXIS] Odstupanje osi Y [YAXIS] Odstupanje osi Z [ZAXIS]",
    "gui.blocklyText.esp32.esp32_mpyAccurateCompass": "točan kompas",
    "gui.blocklyText.esp32.esp32_mpyCompassDirection": "smjer kompasa",
    "gui.blocklyText.esp32.esp32_mpyAxisMagnetic": "[TYPE] magnetska osovina",
    "gui.blocklyText.esp32.esp32_mpyMagneticStrength": "jakost magnetskog polja",
    "gui.blocklyText.esp32.esp32_mpyRemoveMagnetic": "ukloniti trenutno okolno magnetsko polje",
    "gui.blocklyText.esp32.esp32_mpyReturnRunTimeDiff": "povratna vremenska razlika, vrijeme početka [STARTTIME] vrijeme završetka [ENDTIME]",
    "gui.blocklyText.esp32.esp32_mpyRunTimer": "dohvati vrijeme izvođenja [TIMETYPE]",
    "gui.blocklyText.esp32.millisecond": "milisekunda",
    "gui.blocklyText.esp32.microsencond": "mikrosekunda",
    "gui.blocklyText.esp32.esp32_mpyLocalTime": "dohvati lokalno vrijeme [LOCALTIME]",
    "gui.blocklyText.esp32.year": "godina",
    "gui.blocklyText.esp32.month": "mjesec",
    "gui.blocklyText.esp32.day": "dan",
    "gui.blocklyText.esp32.hour": "sat",
    "gui.blocklyText.esp32.minute": "minuta",
    "gui.blocklyText.esp32.sencond": "drugi",
    "gui.blocklyText.esp32.weeknumber": "broj tjedna",
    "gui.blocklyText.esp32.daysnumber": "broj dana",
    "gui.blocklyText.esp32.esp32_mpyReset": "resetirati",
    "gui.blocklyText.esp32.esp32_mpyMacAddr": "Mac adresa",
    "gui.blocklyText.esp32.displayInXY": "prikaz [TEXT] na osi X: [X] Y: [Y], pregled [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "pisanje predmemorije [TEXT] na X: [X] Y: [Y], način [SHOWMODE] pregled [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "boja zaslona [COLOR] (jasan zaslon)",
    "gui.blocklyText.esp32.fillScreenForMpy": "boja zaslona za pisanje predmemorije [COLORMPYTHON]",
    "gui.blocklyText.esp32.clear": "čisto",
    "gui.blocklyText.esp32.fullBright": "potpuno svijetlo",
    "gui.blocklyText.esp32.blackBackground": "crna pozadina",
    "gui.blocklyText.esp32.whiteBackground": "bijela pozadina",
    "gui.blocklyText.esp32.black": "sve crno",
    "gui.blocklyText.esp32.white": "sve bijelo",
    "gui.blocklyText.esp32.lineDrawing": "povuci liniju od točke x1:[X1] y1:[Y1] do točke x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "povezan",
    "gui.blocklyText.esp32.disconnected": "odspojen",
    "gui.blocklyText.esp32.rectangleDrawing": "nacrtaj pravokutnik [FILL] s gornjim lijevim kutom na x:[X] y:[Y], Š:[WIDTH], H:[HEIGHT]",
    "gui.blocklyText.esp32.mpyrectangleDrawing": "[DRAWERASE] [FILL] ishodište crteža pravokutnika x:[X] y:[Y] širina[WIDTH] visina[HEIGHT]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCornerBorder": "[DRAWERASE] obrub kuta x [X] y [Y] širina [WIDTH] visina [HEIGHT] polumjer [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCircle": "[DRAWERASE] [FILL] krug x [X] y [Y] radijus [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseTriangle": "[DRAWERASE] [FILL] trokut x1 [X1] y1 [Y1] x2 [X2] y2 [Y2] x3 [X3] y3 [Y3]",
    "gui.blocklyText.esp32.esp32_mpyShowPixelCoordinate": "na koordinati x [X] y [Y] prikaži [SHOWMSG] kao [PIXEL] font [LINE]",
    "gui.blocklyText.esp32.Digiface_11px": "Digiface 11px",
    "gui.blocklyText.esp32.Digiface_16px": "Digiface 16px",
    "gui.blocklyText.esp32.Digiface_21px": "Digiface 21px",
    "gui.blocklyText.esp32.Digiface_30px": "Digiface 30px",
    "gui.blocklyText.esp32.Digiface_44px": "Digiface 44px",
    "gui.blocklyText.esp32.Digiface_ltalic_30px": "Digiface ltalic 30px",
    "gui.blocklyText.esp32.Digiface_ltalic_42px": "Digiface ltalic 42px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_12px": "DejaVu Sans Mono 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_16px": "DejaVu Sans Mono 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_21px": "DejaVu Sans Mono 21px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_12px": "DejaVu Sans Mono Bold 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_16px": "DejaVu Sans Mono Bold 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_21px": "DejaVu Sans Mono Bold 21px",
    "gui.blocklyText.esp32.esp32_mpyQRCode": "x [X] y [Y] nacrtaj QR kod [QRCODE] veličina [IMGSIZE]",
    "gui.blocklyText.esp32.esp32_mpyInitClock": "početni sat [CLOCKNAME] x [X] y [Y] radijus [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyClockReadTime": "sat [CLOCKNAME] očitavanje vremena",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseClock": "[DRAWERASE] sat [CLOCKNAME]",
    "gui.blocklyText.esp32.screenDisplay": "prikaz predmemorije zaslona stupa na snagu",
    "gui.blocklyText.esp32.circleDrawing": "nacrtaj krug [FILL] sa središnjom točkom na x:[X] y:[Y], r:[RADIUS]",
    "gui.blocklyText.esp32.fill": "ispuniti",
    "gui.blocklyText.esp32.notFill": "ne ispuniti",
    "gui.blocklyText.esp32.pointDrawing": "točka crtanja na x:[X] y:[Y]",
    "gui.blocklyText.esp32.esp32_mpyProgressBar": "traka napretka x [X] y [Y] širina [WIDTH] visina [HEIGHT] napredak [PROG]",
    "gui.blocklyText.esp32.esp32_mpyColumnarProgressBar": "stupčasta traka napretka [COLUMNARSTATE] x [X] y [Y] širina [WIDTH] visina [HEIGHT] napredak [PROG]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseLine": "[DRAWERASE] [COLUMNARSTATE] linija x [X] y [Y] dužina [LENGTH]",
    "gui.blocklyText.esp32.draw": "crtati",
    "gui.blocklyText.esp32.erase": "izbrisati",
    "gui.blocklyText.esp32.vertical": "vertikalna",
    "gui.blocklyText.esp32.horizontal": "horizontalna",
    "gui.blocklyText.esp32.setLineWidth": "postavi širinu linije na [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "digitalni pin [PIN] izlaz [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "očitaj digitalni PIN [PIN]",
    "gui.blocklyText.esp32.analogWrite": "analogni pin [PIN] izlaz (PWM) [VALUE]",
    "gui.blocklyText.esp32.analogRead": "očitaj analogni pin [PIN]",
    "gui.blocklyText.esp32.serialPort0": "serijski0",
    "gui.blocklyText.esp32.serialPort1": "serijski1",
    "gui.blocklyText.esp32.serialPort2": "serijski2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX] brzina prijenosa podataka [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "slika zaslona [IMAGE] na x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "učitavanje slike sa slike [IMAGE] show to x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "pritisnut",
    "gui.blocklyText.esp32.loosened": "pušten na slobodu",
    "gui.blocklyText.esp32.all": "SVE (-1)",
    "gui.blocklyText.esp32.setBaud": "serialport0 brzina prijenosa podataka [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "iz [SERIAL] čitanje serijskih podataka int ili float [READTYPE]",
    "gui.blocklyText.esp32.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.esp32.setBrightness": "postavi LED svjetlinu [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "dohvati LED svjetost",
    "gui.blocklyText.esp32.print": "ispis [DATA]",
    "gui.blocklyText.esp32.TiltForward": "Nagni prema naprijed",
    "gui.blocklyText.esp32.TiltBack": "Nagni natrag",
    "gui.blocklyText.esp32.TiltLeft": "Nagni lijevo",
    "gui.blocklyText.esp32.TiltRight": "Nagni desno",
    "gui.blocklyText.esp32.ScreenUp": "Zaslon gore",
    "gui.blocklyText.esp32.ScreenDown": "Licem prema dolje",
    "gui.blocklyText.esp32.Shake": "Tresti",
    "gui.blocklyText.esp32.isGesture": "je [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "Onemogući [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "svi",
    "gui.blocklyText.esp32.rgbdisable.No.0": "br.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "br.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "br.2",
    "gui.blocklyText.esp32.playMusic": "Pusti glazbu [MUSIC] koja se ponavlja [REPEAT]",
    "gui.blocklyText.esp32.Once": "Jednom",
    "gui.blocklyText.esp32.Forever": "Zauvijek",
    "gui.blocklyText.esp32.OnceInBackground": "Jednom u pozadini",
    "gui.blocklyText.esp32.ForeverInBackground": "Zauvijek u pozadini",
    "gui.blocklyText.radio.radioSwitch": "uključi [DATA] radio",
    "gui.blocklyText.radio.radioswitch.on": "na",
    "gui.blocklyText.radio.radioswitch.off": "isključeno",
    "gui.blocklyText.radio.setChannel": "postavi radio kanal [DATA]",
    "gui.blocklyText.radio.radioSend": "radio šalje [DATA]",
    "gui.blocklyText.radio.radioreceived": "radio primljen",
    "gui.blocklyText.radio.receivingEven": "kada je primljeno [DATA]",
    "gui.blocklyText.radio.Msg": "radio poruka",
    "gui.blocklyText.radio.receivingSpecificEven": "kada primi određenu emitiranu poruku [DATA] od radija do",
    "gui.blocklyText.esp32.radioSwitch": "uključi [DATA] radio",
    "gui.blocklyText.esp32.radioswitch.on": "na",
    "gui.blocklyText.esp32.radioswitch.off": "isključeno",
    "gui.blocklyText.esp32.setChannel": "postavi radio kanal [DATA]",
    "gui.blocklyText.esp32.radioSend": "radio šalje [DATA]",
    "gui.blocklyText.esp32.radioreceived": "radio primljen",
    "gui.blocklyText.esp32.receivingEven": "kada primi emitiranu poruku [DATA] od radija do",
    "gui.blocklyText.esp32.receivingSpecificEven": "kada primi određenu emitiranu poruku [DATA] od radija do",
    "gui.blocklyText.esp32.connectWIFI": "poveži se na Wi-Fi s SSID-om [SSID] i lozinkom [PASS]",
    "gui.blocklyText.esp32.disconnectWIFI": "prekini Wi-Fi vezu",
    "gui.blocklyText.esp32.checkWIFI": "Spojio se na Wi-Fi",
    "gui.blocklyText.esp32.configurationInformation": "Informacije o Wi-Fi konfiguraciji",
    "gui.blocklyText.esp32.configurationInformationIP": "Podaci o Wi-Fi konfiguraciji [IP]",
    "gui.blocklyText.esp32.wifi.IP": "IP",
    "gui.blocklyText.esp32.wifi.netmask": "mrežna maska",
    "gui.blocklyText.esp32.wifi.geteway": "bijeg",
    "gui.blocklyText.esp32.wifi.DNS": "DNS",
    "gui.blocklyText.esp32.openAP": "otvori AP način sa SSID-om [SSID] & lozinkom [PASS] & kanalom [CHANNEL]",
    "gui.blocklyText.esp32.closeAP": "zatvori AP mod",
    "gui.blocklyText.esp32.synchronizeTime": "sinkroniziraj mrežno vrijeme, vremensku zonu [TIMEZONE] i poslužitelj za mjerenje vremena [SERVER]",
    "gui.blocklyText.esp32.timezone.UTC+8": "UTC+8",
    "gui.blocklyText.esp32.timezone.UTC+9": "UTC+9",
    "gui.blocklyText.esp32.timezone.UTC+10": "UTC+10",
    "gui.blocklyText.esp32.timezone.UTC+11": "UTC+11",
    "gui.blocklyText.esp32.timezone.UTC+12": "UTC+12",
    "gui.blocklyText.esp32.timezone.UTC-12": "UTC-12",
    "gui.blocklyText.esp32.timezone.UTC-11": "UTC-11",
    "gui.blocklyText.esp32.timezone.UTC-10": "UTC-10",
    "gui.blocklyText.esp32.timezone.UTC-9": "UTC-9",
    "gui.blocklyText.esp32.timezone.UTC-8": "UTC-8",
    "gui.blocklyText.esp32.timezone.UTC-7": "UTC-7",
    "gui.blocklyText.esp32.timezone.UTC-6": "UTC-6",
    "gui.blocklyText.esp32.timezone.UTC-5": "UTC-5",
    "gui.blocklyText.esp32.timezone.UTC-4": "UTC-4",
    "gui.blocklyText.esp32.timezone.UTC-3": "UTC-3",
    "gui.blocklyText.esp32.timezone.UTC-2": "UTC-2",
    "gui.blocklyText.esp32.timezone.UTC-1": "UTC-1",
    "gui.blocklyText.esp32.timezone.UTC": "UTC",
    "gui.blocklyText.esp32.timezone.UTC+1": "UTC+1",
    "gui.blocklyText.esp32.timezone.UTC+2": "UTC+2",
    "gui.blocklyText.esp32.timezone.UTC+3": "UTC+3",
    "gui.blocklyText.esp32.timezone.UTC+4": "UTC+4",
    "gui.blocklyText.esp32.timezone.UTC+5": "UTC+5",
    "gui.blocklyText.esp32.timezone.UTC+6": "UTC+6",
    "gui.blocklyText.esp32.timezone.UTC+7": "UTC+7",
    "gui.blocklyText.telloesp32.arduinoUnoStarts": "Pokreće se RMTT ESP32",
    "gui.blocklyText.telloesp32.ledControl": "led kontrola",
    "gui.blocklyText.telloesp32.matrixControl": "kontrola matrice",
    "gui.blocklyText.telloesp32.flyControl": "kontrola muhe",
    "gui.blocklyText.telloesp32.customCommand": "prilagođena naredba",
    "gui.blocklyText.telloesp32.pinOperation": "pin operacija",
    "gui.blocklyText.telloesp32.readDigital": "očitaj digitalni pin [PIN]",
    "gui.blocklyText.telloesp32.readAnalog": "očitaj analogni pin [PIN]",
    "gui.blocklyText.telloesp32.setPwm": "postavi PWM pin [PIN] na [VALUE]",
    "gui.blocklyText.telloesp32.setDigital": "postavi digitalni pin [PIN] na [LEVEL]",
    "gui.blocklyText.telloesp32.functionalModule": "funkcionalni modul",
    "gui.blocklyText.telloesp32.telloesp32_matrix": "postaviti matricu [MARK]",
    "gui.blocklyText.telloesp32.showPatternWhenStart": "prikaži uzorak [MARK] pri pokretanju",
    "gui.blocklyText.telloesp32.setMatrixLight": "postavi matrično svjetlo [NUM]",
    "gui.blocklyText.telloesp32.matrixDisplayText": "matrični prikaz teksta [TEXT] boja [COLOR] svjetlo [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayTextMpy": "matrični prikaz teksta [TEXT] boja [COLOR]",
    "gui.blocklyText.telloesp32.matrixDisplayPreset": "matrični zaslon unaprijed postavljeni uzorak [PATTERN] boja [COLOR] svjetlo [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayPresetMpy": "matrični zaslon unaprijed postavljeni uzorak [PATTERN] boja [COLOR]",
    "gui.blocklyText.telloesp32.empty": "prazan",
    "gui.blocklyText.telloesp32.WiFi": "WiFi",
    "gui.blocklyText.telloesp32.DownLoad": "Preuzimanje datoteka",
    "gui.blocklyText.telloesp32.Power": "Vlast",
    "gui.blocklyText.telloesp32.heart": "srce",
    "gui.blocklyText.telloesp32.heartOutline": "obris srca",
    "gui.blocklyText.telloesp32.matrixR": "r",
    "gui.blocklyText.telloesp32.matrixB": "b",
    "gui.blocklyText.telloesp32.matrixP": "str",
    "gui.blocklyText.telloesp32.matrixDisplayRollPicture": "prikaz smjera oznake [DIRECT] frekvencija kretanja [SPEED] oznaka [MARK]",
    "gui.blocklyText.telloesp32.matrixDisplayRollText": "matrični prikaz smjer teksta [DIRECT] frekvencija pomicanja [SPEED] tekst[TEXT] boja[COLOR]",
    "gui.blocklyText.telloesp32.telloSetWifiChannel": "postavi Wi-Fi kanal [CHANNEL]",
    "gui.blocklyText.telloesp32.initTOF": "init TOF",
    "gui.blocklyText.telloesp32.readTOF": "pročitati TOF (mm)",
    "gui.blocklyText.telloesp32.buttonIsPressed": "tipka je pritisnuta?",
    "gui.blocklyText.telloesp32.setRgbColor": "boja predmemorije svjetla [COLOR]",
    "gui.blocklyText.telloesp32.rgbToColor": "set R: [RED] G: [GREEN] B: [BLUE]",
    "gui.blocklyText.telloesp32.rgbBreathWithColor": "LED disanja s bojom [COLOR] frekvencija (Hz) [TIME]",
    "gui.blocklyText.telloesp32.rgbBreath": "postavi svjetla za dah R: [RED] G: [GREEN] B: [BLUE] frekvencija (Hz): [SP]",
    "gui.blocklyText.telloesp32.rgbFlashingColor": "bljeskajuća svjetla boja1 [COLOR1] i boja2 [COLOR2] frekvencija (Hz) [SP]",
    "gui.blocklyText.telloesp32.rgbFlashing": "postavi bljeskajuća svjetla R1: [RED1] G1: [GREEN1] B1: [BLUE1] R2: [RED2] G2: [GREEN2] B2: [BLUE2] frekvencija (Hz): [SP]",
    "gui.blocklyText.telloesp32.ledCloseEffects": "LED zatvori specijalni efekti",
    "gui.blocklyText.telloesp32.matrixCloseEffects": "matrix close specijalni efekti",
    "gui.blocklyText.telloesp32.sendCustomCommand": "pošalji prilagođenu naredbu [CMD]",
    "gui.blocklyText.telloesp32.recvCustomCommand": "primi prilagođenu naredbu [MSG], vrijeme čekanja [TIMEOUT]",
    "gui.blocklyText.telloesp32.recvCustomCommandNum": "primi prilagođeni broj, vrijeme čekanja [TIMEOUT]",
    "gui.blocklyText.telloesp32.serialportOperation": "operacija serijskog porta",
    "gui.blocklyText.telloesp32.dataReadable": "Jesu li podaci [SERIAL] čitljivi?",
    "gui.blocklyText.telloesp32.readSerialData": "očitaj [SERIAL] podatke",
    "gui.blocklyText.telloesp32.setBaud": "postavi brzinu prijenosa serial0 [BAUD]",
    "gui.blocklyText.telloesp32.readSerialDataIntFloat": "iz [SERIAL] čitanje serijskih podataka int ili float [READTYPE]",
    "gui.blocklyText.telloesp32.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.telloesp32.seriaRemapping": "set [SERIAL] Rx [RX] Tx [TX] Brzina prijenosa [BAUD]",
    "gui.blocklyText.telloesp32.systemResource": "resurs sustava",
    "gui.blocklyText.telloesp32.timer": "mjerač vremena (ms)",
    "gui.blocklyText.telloesp32.consolePrint": "konzolni izlaz",
    "gui.blocklyText.telloesp32.print": "ispis [DATA]",
    "gui.blocklyText.telloesp32.telloRiseDown": "letjeti [RISEDOWN] [NUMS] cm",
    "gui.blocklyText.telloesp32.rise": "gore",
    "gui.blocklyText.telloesp32.down": "dolje",
    "gui.blocklyText.telloesp32.telloTurnDirection": "letjeti [DIRECTION] [NUMS] cm",
    "gui.blocklyText.telloesp32.turnleft": "lijevo",
    "gui.blocklyText.telloesp32.turnright": "pravo",
    "gui.blocklyText.telloesp32.turnForward": "naprijed",
    "gui.blocklyText.telloesp32.turnBack": "leđa",
    "gui.blocklyText.telloesp32.telloMotorOn": "motor uključen",
    "gui.blocklyText.telloesp32.telloMotorOff": "motor isključen",
    "gui.blocklyText.telloesp32.telloTakeOff": "polijetanje",
    "gui.blocklyText.telloesp32.telloThrowFly": "bacati muhu",
    "gui.blocklyText.telloesp32.telloLanding": "zemljište",
    "gui.blocklyText.telloesp32.telloClockwiseRotation": "rotirati [CLOCKWISE] [NUMS] stupnjeva",
    "gui.blocklyText.telloesp32.clockwise": "u smjeru kazaljke na satu",
    "gui.blocklyText.telloesp32.counterClockwise": "suprotno od kazaljke na satu",
    "gui.blocklyText.telloesp32.telloStartControl": "start kontrola",
    "gui.blocklyText.telloesp32.telloGetHeight": "dohvati [HEIGHT],timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.relatively": "relativna visina (dm)",
    "gui.blocklyText.telloesp32.barometer": "visina barometra (m)",
    "gui.blocklyText.telloesp32.tof": "TOF visina (mm)",
    "gui.blocklyText.telloesp32.telloTemp": "temperatura ploče (°C), vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.lowest": "najniža",
    "gui.blocklyText.telloesp32.highest": "najviši",
    "gui.blocklyText.telloesp32.telloAttitude": "stav Pitch (°), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.pitch": "nagib",
    "gui.blocklyText.telloesp32.roll": "svitak",
    "gui.blocklyText.telloesp32.translation": "prijevod",
    "gui.blocklyText.telloesp32.telloAcceleration": "ubrzanje (0,001g), vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.xaxis": "x os",
    "gui.blocklyText.telloesp32.yaxis": "y os",
    "gui.blocklyText.telloesp32.zaxis": "z os",
    "gui.blocklyText.telloesp32.telloSetSpeed": "postavi brzinu na [NUMS]cm/s",
    "gui.blocklyText.telloesp32.telloSpeed": "[XYZAXIS] brzina (cm/s), vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloPower": "baterija(%), vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.MissionPad": "MissionPad, vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloMissionPadXyz": "MissionPad [XYZ] (cm), vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCommandResponse": "odgovor na naredbu, vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSetyawXMid": "Sredina[MID] setyaw [YAW] sredina",
    "gui.blocklyText.telloesp32.telloGetMissionPadNum": "dohvati missionPad broj, vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCurrentSpeed": "Trenutna brzina, vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloTime": "Vrijeme rada motora (s), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloWIFISign": "Wi-Fi znak, vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSDKVersion": "Tello SDK verzija, vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloBinVersion": "Tello verzija firmvera, vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloHasHardware": "Tello dobiva verziju hardvera, vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.rmttWifiVersion": "RMTT Wi-Fi verzija, vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSN": "Tello SN kod, vrijeme čekanja [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloFlyToXYZ": "letjeti (u odnosu na zrakoplov) X[X]cm y[Y]cm z[Z]cm brzinom [SPEED]cm/s",
    "gui.blocklyText.telloesp32.getSpeed": "dohvati trenutno postavljenu brzinu (cm/s)",
    "gui.blocklyText.telloesp32.getBattery": "dohvati postotnu vrijednost trenutne preostale snage baterije",
    "gui.blocklyText.telloesp32.getTime": "dohvati vrijeme rada motora",
    "gui.blocklyText.telloesp32.getWifiNoise": "dohvati omjer Wi-Fi signala i šuma",
    "gui.blocklyText.telloesp32.getSdkVersion": "dohvati sdk verziju",
    "gui.blocklyText.telloesp32.getSnNum": "dohvati sn broj",
    "gui.blocklyText.mpy.MQTTinit": "MQTT početni [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInit": "mqtt init [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInitPlatform": "platforma",
    "gui.blocklyText.mpy.mpyMqttInitPlatformNormal": "normalan",
    "gui.blocklyText.mpy.mpyMqttInitPlatformTencent": "tencent",
    "gui.blocklyText.mpy.MQTTclient_id": "client_id",
    "gui.blocklyText.mpy.MQTTserver": "poslužitelj",
    "gui.blocklyText.mpy.MQTTport": "luka",
    "gui.blocklyText.mpy.MQTTuser": "korisnik",
    "gui.blocklyText.mpy.MQTTpassowrd": "lozinka",
    "gui.blocklyText.mpy.MQTTkeepalive": "držati na životu",
    "gui.blocklyText.mpy.MQTTtencent": "MQTT Tencent IOT [SETTING]",
    "gui.blocklyText.mpy.TencentIOT": "TencentIOT",
    "gui.blocklyText.mpy.TencentServer": "poslužitelj",
    "gui.blocklyText.mpy.TencentPort": "luka",
    "gui.blocklyText.mpy.TencentAppld": "Appld",
    "gui.blocklyText.mpy.TencentDevice": "uređaj",
    "gui.blocklyText.mpy.siotServer": "poslužitelj",
    "gui.blocklyText.mpy.siotServerUser": "Korisnik",
    "gui.blocklyText.mpy.siotServerPass": "Zaporka",
    "gui.blocklyText.mpy.Tencentoutput": "izlaz",
    "gui.blocklyText.mpy.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.mpy.TencentContrltopic": "Kontrolna tema",
    "gui.blocklyText.mpy.mpy_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.mpy.EasyIOTServer": "poslužitelj",
    "gui.blocklyText.mpy.EasyIOTServerChina": "Kina",
    "gui.blocklyText.mpy.EasyIOTServerGlobal": "Globalno",
    "gui.blocklyText.mpy.EasyIOTclient_id": "client_id",
    "gui.blocklyText.mpy.EasyIOT_id": "Iot_id",
    "gui.blocklyText.mpy.EasyIOT_password": "Iot_pwd",
    "gui.blocklyText.mpy.MQTTconnect": "spojiti na MQTT",
    "gui.blocklyText.mpy.mpy_MQTTAddSub": "pretplati se [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublish": "objavi [DATA] u temi [TOPIC]",
    "gui.blocklyText.mpy.mpy_waitMSG": "čekaj poruku teme u načinu rada [MODE].",
    "gui.blocklyText.mpy.disconnectMQTT": "prekini vezu s MQTT-om",
    "gui.blocklyText.mpy.MQTTlastwill": "postavi MQTT temu [TOPIC] zadnja će poruka kao [MSG]",
    "gui.blocklyText.mpy.MQTTreceive": "kada je primljeno [DATA] iz teme [TOPIC]",
    "gui.blocklyText.mpy.mqttMsg": "mqtt poruka",
    "gui.blocklyText.mpy.MQTTmode.block": "blok",
    "gui.blocklyText.mpy.MQTTmode.unblock": "deblokirati",
    "gui.blocklyText.mpyWeather.init": "poslužitelj vremenske prognoze postavi [DATA] kao [SETTING]",
    "gui.blocklyText.mpyWeather.weatherFeild": "[DATA] zajednička polja poslužitelja vremenske prognoze [FIELD]",
    "gui.blocklyText.mpyWeather.weatherSituation": "[DATA] vremenski poslužitelj Vrijeme sada [NOW]",
    "gui.blocklyText.mpyWeather.weatherForecast": "[DATA] vremenski poslužitelj Vremenska prognoza [FIELD] za [DATE]",
    "gui.blocklyText.mpyWeather.weatherLivingindex": "[DATA] vremenski poslužitelj Živi indeks [FIELD] indeks",
    "gui.blocklyText.mpyWeather.weatherFeild.name": "Ime",
    "gui.blocklyText.mpyWeather.weatherFeild.id": "iskaznica",
    "gui.blocklyText.mpyWeather.weatherFeild.code": "kodirati",
    "gui.blocklyText.mpyWeather.weatherFeild.path": "staza",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone": "Vremenska zona",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone_offset": "pomak_vremenske zone",
    "gui.blocklyText.mpyWeather.weatherFeild.Updated": "Ažurirano",
    "gui.blocklyText.mpyWeather.weatherFeild.updatetime": "vrijeme ažuriranja",
    "gui.blocklyText.mpyWeather.weatherFeild.original": "izvornik",
    "gui.blocklyText.mpyWeather.weatherSituation.text": "tekst",
    "gui.blocklyText.mpyWeather.weatherSituation.code": "kodirati",
    "gui.blocklyText.mpyWeather.weatherSituation.temperature": "temperatura",
    "gui.blocklyText.mpyWeather.weatherdate.today": "danas",
    "gui.blocklyText.mpyWeather.weatherdate.tomorrow": "sutra",
    "gui.blocklyText.mpyWeather.weatherdate.dayaftertomorrow": "prekosutra",
    "gui.blocklyText.mpyWeather.province": "pokrajina",
    "gui.blocklyText.mpyWeather.city": "Grad",
    "gui.blocklyText.mpyWeather.weatherforecast.highesttemperature": "najviša temperatura",
    "gui.blocklyText.mpyWeather.weatherforecast.lowesttemperature": "najniža temperatura",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweather": "dnevno vrijeme",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweathercode": "dnevni meteorološki kod",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweather": "noćno vrijeme",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweathercode": "nightweathercode",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirection": "smjer vjetra",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirectiondegree": "smjer smjera vjetra stupanj",
    "gui.blocklyText.mpyWeather.weatherforecast.windspeed": "brzina vjetra",
    "gui.blocklyText.mpyWeather.weatherforecast.windscale": "ljestvica vjetra",
    "gui.blocklyText.mpyWeather.weatherforecast.date": "datum",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Carwashindex": "Indeks pranja automobila",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Dressingindex": "Dressingindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Coldindex": "Coldindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Sportindex": "Sportindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Travelindex": "Travelindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.uvindex": "uvindex",
    "gui.blocklyText.mpyWeather.type": "vrsta podataka",
    "gui.blocklyText.mpyWeather.temperature": "temperatura",
    "gui.blocklyText.mpyWeather.APIkey": "APIključ",
    "gui.blocklyText.mpyWeather.language": "Jezik",
    "gui.blocklyText.mega2560.functionStarts": "pokreni Mega2560",
    "gui.blocklyText.mega2560.digitalRead": "očitaj digitalni pin [PIN]",
    "gui.blocklyText.mega2560.analogRead": "očitaj analogni pin [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "pwm pin [PIN] izlaz [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "digitalni pin [PIN] izlaz [VALUE]",
    "gui.blocklyText.mega2560.level.low": "NISKI",
    "gui.blocklyText.mega2560.level.high": "VISOKI",
    "gui.blocklyText.mega2560.irqRead": "očitaj digitalni pin [PIN] infracrvenog prijemnika",
    "gui.blocklyText.mega2560.ultrasonicRead": "očitaj okidanje ultrazvučnog senzora [TRIG], eho [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "pin [PIN] reproduciraj zujalicu [TONE] u trajanju od [BEAT] takta",
    "gui.blocklyText.mega2560.harf": "Harf",
    "gui.blocklyText.mega2560.quarter": "Četvrtina",
    "gui.blocklyText.mega2560.oneInEighth": "Jedna osmina",
    "gui.blocklyText.mega2560.wholeBeat": "Cijeli udarac",
    "gui.blocklyText.mega2560.doubleBeat": "Dvostruki udarac",
    "gui.blocklyText.mega2560.stop": "Stop",
    "gui.blocklyText.mega2560.servoSet": "Postavi pin [PIN] servo na [DEGREE] stupnjeva",
    "gui.blocklyText.mega2560.setBaud": "postavi [SERIAL] brzinu prijenosa na [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "iz [SERIAL] čitanje serijskih podataka int ili float [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "serijski0",
    "gui.blocklyText.mega2560.serialPort1": "serijski1",
    "gui.blocklyText.mega2560.serialPort2": "serijski2",
    "gui.blocklyText.mega2560.serialPort3": "serijski3",
    "gui.blocklyText.mega2560.dataReadable": "Postoje li podaci za čitanje na [SERIAL]?",
    "gui.blocklyText.mega2560.readSerialData": "čitanje podataka na [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "u znakovni niz",
    "gui.blocklyText.mega2560.originalOutput": "izvorni izlaz",
    "gui.blocklyText.mega2560.hexOutput": "u HEX",
    "gui.blocklyText.mega2560.wrap": "Wrap",
    "gui.blocklyText.mega2560.noWrap": "No-Wrap",
    "gui.blocklyText.mega2560.readPinLevel": "očitaj pin [PIN] širinu impulsa pod [LEVEL] modom, timeout [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "vrijeme rada sustava (ms)",
    "gui.blocklyText.beetleProMini.Starts": "Pokreće se Beetle Pro Mini",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "pokreni Leonardo",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "pokreni Mega2560",
    "gui.blocklyText.arduino.setDigital": "digitalni pin [PIN] izlaz [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "očitaj digitalni pin [PIN]",
    "gui.blocklyText.arduino.readSimilation": "očitaj analogni pin [PIN]",
    "gui.blocklyText.arduino.setPwm": "pwm pin [PIN] izlaz [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "Očitavanje ultrazvučne udaljenosti (cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "pin [PIN] sviraj ton trube [TONE] u trajanju od [BEAT] takta",
    "gui.blocklyText.arduino.serialSendStr": "serijski izlaz [STRING] [WAY], [LINE]",
    "gui.blocklyText.arduino.originalOutput": "u izvorni oblik",
    "gui.blocklyText.arduino.stringOutput": "u znakovni niz",
    "gui.blocklyText.arduino.hexOutput": "u HEX",
    "gui.blocklyText.arduino.wrap": "Zamotati",
    "gui.blocklyText.arduino.noWrap": "Bez omota",
    "gui.blocklyText.arduino.readInfrared": "očitaj digitalni pin [PIN] infracrvenog prijemnika",
    "gui.blocklyText.arduino.setBaud": "postavi brzinu prijenosa serijskog porta na [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "očitaj pin [PIN] uzorak širine pulsa [LEVEL] vremensko ograničenje [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "bez zvuka",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "pokreni Arduino Nano",
    "gui.blocklyText.vortex.vortexStart": "Pokreće se program Vortex robota",
    "gui.blocklyText.vortex.vortexMoveSpeed": "vrtložni robot [DIRECTION] brzinom [SPEED]",
    "gui.blocklyText.vortex.forward": "kreni naprijed",
    "gui.blocklyText.vortex.backup": "kretati se unatrag",
    "gui.blocklyText.vortex.turnLeft": "skreni lijevo",
    "gui.blocklyText.vortex.turnRight": "skreni desno",
    "gui.blocklyText.vortex.setMotor": "motor [MOTOR] [DIRECTION] brzinom [SPEED]",
    "gui.blocklyText.vortex.leftWheel": "lijevo",
    "gui.blocklyText.vortex.rightWheel": "pravo",
    "gui.blocklyText.vortex.setExpression": "prikaži uzorak [EXPRESSION] u [COLOR]",
    "gui.blocklyText.vortex.ledLights": "[DIRECTION][LIGHT] mijenja se u [COLOR] za [SECOND]s.",
    "gui.blocklyText.vortex.all": "svi",
    "gui.blocklyText.vortex.rightAhead": "ispred",
    "gui.blocklyText.vortex.leftRear": "lijevo stražnje",
    "gui.blocklyText.vortex.rightRear": "desno stražnje",
    "gui.blocklyText.vortex.rightAstern": "iza",
    "gui.blocklyText.vortex.topLights": "gornja svjetla",
    "gui.blocklyText.vortex.bottomLights": "donja svjetla",
    "gui.blocklyText.vortex.vortexStop": "Vortex se prestaje kretati",
    "gui.blocklyText.vortex.setVolume": "postavi glasnoću na [VALUE]",
    "gui.blocklyText.vortex.playMusic": "reproduciraj glazbu [VALUE]",
    "gui.blocklyText.vortex.stopMusic": "prestati reproducirati glazbu",
    "gui.blocklyText.vortex.startDance": "započni [VALUE] ples",
    "gui.blocklyText.vortex.stopDance": "prestani plesati",
    "gui.blocklyText.vortex.barrierDetection": "prepreka ispred?",
    "gui.blocklyText.vortex.readSensor": "čitanje senzora sivih tonova [SENSOR]",
    "gui.blocklyText.vortex.setSensor": "postavi osjetljivost senzora sivih tonova na [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "senzor sivih tonova susreće se s crnim na svom položaju [SENSOR]?",
    "gui.blocklyText.vortex.positiveFrontLeft": "ravno lijevo naprijed",
    "gui.blocklyText.vortex.frontLeft": "lijevo naprijed",
    "gui.blocklyText.vortex.positiveFrontRight": "ravno desno naprijed",
    "gui.blocklyText.vortex.frontRight": "desno sprijeda",
    "gui.blocklyText.vortex.backRight": "desno straga",
    "gui.blocklyText.vortex.backLeft": "lijevo straga",
    "gui.blocklyText.vortex.initialOldPCB": "inicijalizirati na staru verziju (plava PCB)",
    "pen.categoryName": "Olovka",
    "pen.changeColorParam": "promijeni [COLOR_PARAM] olovke za [VALUE]",
    "pen.changeHue": "promijeni boju olovke za [HUE]",
    "pen.changeShade": "promijeni svjetlinu olovke za [SHADE]",
    "pen.changeSize": "promijeni debljinu crte olovke za [SIZE]",
    "pen.clear": "obriši sve",
    "pen.colorMenu.brightness": "svjetlinu",
    "pen.colorMenu.color": "boju",
    "pen.colorMenu.saturation": "zasićenost",
    "pen.colorMenu.transparency": "prozirnost",
    "pen.penDown": "olovka dolje",
    "pen.penUp": "olovka gore",
    "pen.setColor": "postavi boju olovke na [COLOR]",
    "pen.setColorParam": "postavi [COLOR_PARAM] olovke na [VALUE]",
    "pen.setHue": "postavi boju olovke na [HUE]",
    "pen.setShade": "postavi svjetlinu olovke na [SHADE]",
    "pen.setSize": "postavi debljinu crte olovke na [SIZE]",
    "pen.stamp": "otisak",
    "music.changeTempo": "promijeni tempo za [TEMPO]",
    "music.drumBass": "(2) Bas bubanj",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Klave",
    "music.drumClosedHiHat": "(6) Zatvoreni hi-hat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Kravlje zvono",
    "music.drumCrashCymbal": "(4) Crash Cymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Pljesak",
    "music.drumOpenHiHat": "(5) Otvori Hi-Hat",
    "music.drumSideStick": "(3) Bočna palica",
    "music.drumSnare": "(1) Mali bubanj",
    "music.drumTambourine": "(7) Tambura",
    "music.drumTriangle": "(12) Trokut",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Drveni blok",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bas",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Violončelo",
    "music.instrumentChoir": "(15) Zbor",
    "music.instrumentClarinet": "(10) Klarinet",
    "music.instrumentElectricGuitar": "(5) Električna gitara",
    "music.instrumentElectricPiano": "(2) Električni klavir",
    "music.instrumentFlute": "(12) Flauta",
    "music.instrumentGuitar": "(4) Gitara",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Glazbena kutija",
    "music.instrumentOrgan": "(3) Orgulje",
    "music.instrumentPiano": "(1) Klavir",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saksofon",
    "music.instrumentSteelDrum": "(18) Čelični bubanj",
    "music.instrumentSynthLead": "(20) Glavni sintisajzer",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Drvena frula",
    "music.midiPlayDrumForBeats": "sviraj bubanj [DRUM] za [BEATS] ritam",
    "music.midiSetInstrument": "postavi instrument na [INSTRUMENT]",
    "music.playDrumForBeats": "sviraj bubanj [DRUM] za [BEATS] ritam",
    "music.playNoteForBeats": "sviraj notu [NOTE] za [BEATS] takt",
    "music.restForBeats": "pauziraj [BEATS] otkucaja",
    "music.setInstrument": "postavi instrument na [INSTRUMENT]",
    "music.setTempo": "postavi tempo na [TEMPO]",
    "gui.blocklyText.microbitV2.logo": "LOGO(V2)",
    "gui.blocklyText.microbitV2.ls": "LS(V2)",
    "gui.blocklyText.microbit.microbitStarts": "pokreni micro:bit",
    "gui.blocklyText.microbit.interruptExcute": "prekinuti pin [PIN] mod [MODE] izvršiti",
    "gui.blocklyText.microbit.cancleInterrupt": "poništi prekidni pin [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "kada primi bežične podatke",
    "gui.blocklyText.microbit.whenBtnPress": "kada se pritisne tipkalo [REFERENCE].",
    "gui.blocklyText.microbit.whenPinConnected": "kada je spojen microbit pin [REFERENCE].",
    "gui.blocklyText.microbit.whenPosChange": "kada [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "prikaži uzorak [PIC]",
    "gui.blocklyText.microbit.showInput": "prikaži [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "zaustavi animaciju",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] koordinate točke （x:[XAXIS], y:[YXAXIS]）",
    "gui.blocklyText.microbit.hideAllLights": "ugasi sve LED na zaslonu",
    "gui.blocklyText.microbit.microbitEnableLight": "LED zaslon [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "svjetlina",
    "gui.blocklyText.microbit.showLightWithBrightness": "postavi svjetlinu [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "prikaži koordinate točke (x:[XAXIS], y:[YXAXIS]), svjetlina [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "pin [PIN] sviraj melodiju [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "pin [PIN] sviraj melodiju [SOUND] dok ne završi",
    "gui.blocklyText.microbit.playNote": "pin [PIN] sviraj notu [NOTE] u trajanju od [BEAT] takta",
    "gui.blocklyText.microbit.true": "omogućiti",
    "gui.blocklyText.microbit.false": "onemogućiti",
    "gui.blocklyText.maqueen.playSound": "pin P0 sviraj melodiju [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "pin P0 sviraj melodiju [SOUND] dok ne završi",
    "gui.blocklyText.maqueen.playNote": "pin P0 sviraj notu [NOTE] u trajanju od [BEAT] takta",
    "gui.blocklyText.maqueen.RGB0": "RGB0 (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1 (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2 (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3 (3)",
    "gui.blocklyText.maqueen.All": "Sve (-1)",
    "gui.blocklyText.microbit.changeTempo": "promijeni tempo (bpm) za [VALUE]",
    "gui.blocklyText.microbit.setTempo": "postavi tempo (bpm) na [VALUE]",
    "gui.blocklyText.microbit.getTempo": "tempo (bpm)",
    "gui.blocklyText.microbit.stopSound": "zaustavi reprodukciju u pozadini",
    "gui.blocklyText.microbit.btnIsPressed": "Tipkalo [BUTTON] pritisnuto?",
    "gui.blocklyText.microbit.pinIsConnected": "[PIN] pin spojen?",
    "gui.blocklyText.microbit.isMove": "trenutno stanje [TYPE]?",
    "gui.blocklyText.microbit.compass": "očitaj orijentaciju kompasa",
    "gui.blocklyText.microbit.temperature": "očitaj temperaturu",
    "gui.blocklyText.microbit.digitalWrite": "digitalni pin [PIN] izlaz [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "očitaj digitalni PIN-a [PIN]",
    "gui.blocklyText.microbit.analogWrite": "analogni pin [PIN] izlaz [VALUE]",
    "gui.blocklyText.microbit.analogRead": "očitaj analogni pin [PIN]",
    "gui.blocklyText.microbit.up": "logo gore",
    "gui.blocklyText.microbit.down": "logo dolje",
    "gui.blocklyText.microbit.left": "nagnut ulijevo",
    "gui.blocklyText.microbit.right": "nagnut udesno",
    "gui.blocklyText.microbit.faceUp": "licem prema gore",
    "gui.blocklyText.microbit.faceDown": "licem prema dolje",
    "gui.blocklyText.microbit.freefall": "slobodan pad",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "protresen",
    "gui.blocklyText.microbit.show": "prikaži",
    "gui.blocklyText.microbit.start": "otvori",
    "gui.blocklyText.microbit.stop": "zatvori",
    "gui.blocklyText.microbit.hide": "sakrij",
    "gui.blocklyText.microbit.low": "NISKI",
    "gui.blocklyText.microbit.high": "VISOKI",
    "gui.blocklyText.microbit.microbitReadBrightness": "očitaj svjetlinu ambijentalnog svjetla",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] bežičnu komunikaciju",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "postavi bežični kanal na [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "slanje znakovnog niza [TEXT] bežičnim putem",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "primati podatke bežičnim putem",
    "gui.blocklyText.microbit.strength": "snaga",
    "gui.blocklyText.microbit.acceleration": "očitaj ubrzanje (m-g)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "očitaj magnetsku silu (µT)[TYPE]",
    "gui.blocklyText.microbit.microbitAccurateCompass": "točan kompas",
    "gui.blocklyText.microbit.microbitGetSoundLevel": "dohvati razinu zvuka (V2)",
    "gui.blocklyText.microbit.print": "Ispis [DATA]",
    "gui.blocklyText.microbit.goUp": "gore",
    "gui.blocklyText.microbit.goDown": "dolje",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "kada je primljeno [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "bežični podaci",
    "gui.blocklyText.calliope.whenBtnPress": "kada se pritisne tipkalo [REFERENCE].",
    "gui.blocklyText.calliope.calliopeReadBrightness": "čitaj svjetlinu",
    "gui.blocklyText.calliope.playNote": "[PIN] sviraj notu [NOTE] u trajanju od [BEAT] takta",
    "gui.blocklyText.calliope.playSoundUntil": "[PIN] sviraj melodiju [SOUND] dok ne završi",
    "gui.blocklyText.calliope.playSound": "[PIN] sviraj melodiju [SOUND]",
    "gui.blocklyText.calliope.calliopeEnableLight": "zaslon [ENABLE]",
    "gui.blocklyText.calliope.hideAllLights": "čisti zaslon",
    "gui.blocklyText.calliope.whenPinConnected": "kada je spojen pin [REFERENCE].",
    "gui.blocklyText.calliope.loudness": "čitaj glasnoću",
    "gui.blocklyText.calliope.rotation": "očitaj kut rotacije (°) [TYPE]",
    "gui.blocklyText.calliope.calliopeControlSinglelMotor": "kontrola jednog motora [RANGE]%",
    "gui.blocklyText.calliope.calliopeSingleMotorStop": "jedan motor [STOPTYPE]",
    "gui.blocklyText.calliope.calliopeDoubleMotor": "upravljački motor [MOTORTYPE] [RANGE]%",
    "gui.blocklyText.calliope.calliopeDoubleMotorStop": "zaustavi motor [MOTORTYPE]",
    "gui.blocklyText.calliope.calliopeLight": "set rgb-led [COLOR]",
    "gui.blocklyText.calliope.setRGBColor": "crvena [RED] zelena [GREEN] plava [BLUE] bijela [WHITE]",
    "gui.blocklyText.calliope.calliopeCloseRGB": "isključi rgb-led",
    "gui.blocklyText.calliope.pitch": "nagib",
    "gui.blocklyText.calliope.roll": "svitak",
    "gui.blocklyText.calliope.boardbuzzer": "zvučnik",
    "gui.blocklyText.calliope.motorbreak": "pauza",
    "gui.blocklyText.calliope.motorcoast": "obala",
    "gui.blocklyText.calliope.motorsleep": "spavati",
    "gui.blocklyText.calliope.open": "uključi",
    "gui.blocklyText.calliope.close": "isključi",
    "gui.blocklyText.bos0001.readRotary": "očitaj [PIN] vrijednost modula gumba",
    "gui.blocklyText.bos0002.IsPINPress": "Gumb [PIN] pritisnut?",
    "gui.blocklyText.bos0003.IsPINPress": "[PIN] samoblokirajući prekidač pritisnut?",
    "gui.blocklyText.bos0004.readLight": "očitaj [PIN] vrijednost svjetlosnog modula",
    "gui.blocklyText.bos0006.readMoisture": "očitaj [PIN] vrijednost modula vlage",
    "gui.blocklyText.bos0007.readFlame": "očitaj [PIN] vrijednost modula plamena",
    "gui.blocklyText.bos0008.IsPINTouch": "[PIN] dodir za povezivanje?",
    "gui.blocklyText.bos0009.readSound": "očitaj [PIN] vrijednost zvučnog modula",
    "gui.blocklyText.bos0010.readSmartGray": "očitaj [PIN] sivu vrijednost modula",
    "gui.blocklyText.bos0012.IsConductivitySwitch": "[PIN] modul vodljivosti je uključen?",
    "gui.blocklyText.bos0013.IsMotionSensor": "[PIN] okidač modula kretanja?",
    "gui.blocklyText.bos0038.readSoilHumidity": "očitaj [PIN] vrijednost vlažnosti tla",
    "gui.blocklyText.bos0045.readUltrasonicDistance": "očitaj [PIN] ultrazvučnu vrijednost raspona",
    "gui.blocklyText.bos0016.lightBrightLED": "[CONTROL][PIN] isticanje LED svjetla",
    "gui.blocklyText.bos0017.LEDModule": "[CONTROL][PIN]LED svjetlo",
    "gui.blocklyText.bos0019.LEDStringLight": "[CONTROL][PIN] svjetlosna traka fiksne boje",
    "gui.blocklyText.bos0021.fanModule": "[CONTROL][PIN] Obožavatelj",
    "gui.blocklyText.bos0022.voiceRecorderModule": "[PIN] za reprodukciju snimke",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot se pokreće",
    "gui.blocklyText.maxbot.playSound": "sviraj melodiju [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "sviraj melodiju [SOUND] dok ne završi",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "dogodio se sudar na [LEFTRIGHT]?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "otkrivena crna crta na [LEFTRIGHT]?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "motor [MOTOR] [DIR] brzinom [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "očitaj (P1,P2) ultrazvučni senzor [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "očitaj ultrazvučni senzor [UNIT], trig [TRIG], echo [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "NAPRIJED",
    "gui.blocklyText.maxbot.BACKWARD": "NAZAD",
    "gui.blocklyText.motorbit.CW": "CW",
    "gui.blocklyText.motorbit.CCW": "CCW",
    "gui.blocklyText.maxbot.CW": "CW",
    "gui.blocklyText.maxbot.CCW": "CCW",
    "gui.blocklyText.maxbot.ANGLE": "stupnjeva",
    "gui.blocklyText.maxbot.CIRCLE": "revolucije",
    "gui.blocklyText.maxbot.Left": "lijevi",
    "gui.blocklyText.maxbot.Right": "desni",
    "gui.blocklyText.maxbot.cm": "cm",
    "gui.blocklyText.maxbot.inch": "inč",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit se pokreće",
    "gui.blocklyText.MotorBit.MotorRun": "motor [MOTOR] rotiraj [DIR] brzinom [SPEED]",
    "gui.blocklyText.MotorBit.MotorStop": "motor [MOTOR] se zaustavlja",
    "gui.blocklyText.MotorBit.Stepper": "stepper [STEPPER] rotiraj [ROTATEDIR] za [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "SVI",
    "gui.blocklyText.maqueen.maqueenStarts": "pokreni Maqueen",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "očitaj (P1,P2)ultrazvučni senzor (cm)",
    "gui.blocklyText.maqueen.motorStop": "robot (lijevi i desni motor) se zaustavlja",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] LED svjetlo",
    "gui.blocklyText.maqueen.redLineSensor": "očitaj [LEFTRIGHT] senzor za praćenje linije",
    "gui.blocklyText.maqueen.robotSpeedMove": "robot [DIR] brzinom [SPEED]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "motor [LEFTRIGHT] pokreći [DIR] brzinom [SPEED]",
    "gui.blocklyText.maqueen.ledLeft": "lijevo (P8)",
    "gui.blocklyText.maqueen.ledRight": "desno (P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "desno (P12) (nije dostupno)",
    "gui.blocklyText.maqueen.lineSensorLeft": "lijevi (P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "desni (P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "kada (P16) primi [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "infracrveni signal",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "očitaj (P16) infracrvenog signala",
    "gui.blocklyText.maqueen.numberOfLightsBright": "pin P15 4 RGB LED osvjetljenje [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "pin P15 [NUM1] boja RGB LED diode [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "pin P15 RGB LED [NUM1] do [NUM2] prikazuje gradijent boje od [COLOR1] do [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "pin P15 pomakni piksele za [NUM]",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "pin P15 zakreni piksele za [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "pin P15 RGB LED intenzitet [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "pin P15 RGB LED [NUM1] do [NUM2] prikazuje histogram, vrijednost [VALUE], max [MAX]",
    "gui.blocklyText.maqueen.clear": "pin P15 isključi sve RGB LED diode",
    "gui.blocklyText.maqueen.rgbColor": "crvena [RED] zelena [GREEN] plava [BLUE]",
    "gui.blocklyText.tello.telloStarts": "pokreni Tello",
    "gui.blocklyText.tello.telloControlPlane": "kontrolna ravnina",
    "gui.blocklyText.tello.telloTakeOff": "polijetanje",
    "gui.blocklyText.tello.telloLanding": "zemljište",
    "gui.blocklyText.tello.telloRise": "uzleti [NUMS] cm",
    "gui.blocklyText.tello.telloDown": "odletjeti [NUMS] cm",
    "gui.blocklyText.tello.telloGoahead": "letjeti naprijed [NUMS] cm",
    "gui.blocklyText.tello.telloBack": "letjeti unatrag [NUMS] cm",
    "gui.blocklyText.tello.telloTurnLeft": "letjeti lijevo [NUMS] cm",
    "gui.blocklyText.tello.telloTurnRight": "letjeti desno [NUMS] cm",
    "gui.blocklyText.tello.telloClockwiseRotation": "zakreni [NUMS] stupnjeva u smjeru kazaljke na satu",
    "gui.blocklyText.tello.telloCounterclockwiseSelection": "zakreni [NUMS] stupnjeva suprotno od kazaljke na satu",
    "gui.blocklyText.tello.telloSpeed": "postavi brzinu na [NUMS]cm/s",
    "gui.blocklyText.tello.telloRoll": "okrenuti [ROLL]",
    "gui.blocklyText.tello.telloPower": "vlast?",
    "gui.blocklyText.tello.telloRollForward": "naprijed(f)",
    "gui.blocklyText.tello.telloRollBack": "natrag (b)",
    "gui.blocklyText.tello.telloRollLeft": "lijevo (l)",
    "gui.blocklyText.tello.telloRollRight": "desno(r)",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] primljeni podaci?",
    "gui.blocklyText.obloq.initialParameter": "obloq mqtt inicijalizacija: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.initialI2CParameter": "Obloq mqtt početni parametar [PARAMETER] I2C adresa sučelja 0x16",
    "gui.blocklyText.obloq.httpInitialI2CParameter": "Obloq http početni parametar [PARAMETER] I2C adresa sučelja 0x16",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq pošalji poruku [MSG] platformi u oblaku [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq je pročitao poruku iz [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq je pročitao poruku",
    "gui.blocklyText.obloq.httpInitialParameter": "obloq http inicijalizacija: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] vrijeme čekanja (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL] sadržaj: [CONTENT] vrijeme čekanja (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] vrijeme čekanja (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "dodaj pretplatu [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "Wi-Fi naziv",
    "gui.blocklyText.obloq.password": "zaporka",
    "gui.blocklyText.obloq.iotService": "IOT usluga",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Tema_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "IP adresa",
    "gui.blocklyText.obloq.port": "Port",
    "gui.blocklyText.obloq.addTopic": "Dodaj temu, dodaj samo do 4",
    "gui.blocklyText.obloq.deleteTopic": "Izbriši temu",
    "gui.blocklyText.obloq.moreTopic": "Tema",
    "gui.blocklyText.obloq.server": "poslužitelj",
    "gui.blocklyText.obloq.global": "Globalno",
    "gui.blocklyText.obloq.china": "Kina",
    "gui.blocklyText.obloq.softwareSerial": "Serijski softver",
    "gui.blocklyText.obloq.hardwareSerial": "Hardverski serijski",
    "gui.blocklyText.obloq.hardwareSerial1": "Serijski hardver 1",
    "gui.blocklyText.obloq.hardwareSerial2": "Serijski hardver 2",
    "gui.blocklyText.obloq.hardwareSerial3": "Serijski hardver 3",
    "gui.blocklyText.obloq.WhenReceivedData": "kada je [TOPIC] primio [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq poruka",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq dohvati [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Informacije o otklanjanju pogrešaka",
    "gui.blocklyText.obloq.ERROR": "Kod pogreške",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "infracrveno zračenje [TYPE] pin [PIN] vrijednost [VALUE] bit [BIT]",
    "gui.blocklyText.infraredEmission.send": "Infracrveni pin za slanje [PIN] Pošalji poruku: adresa [ADDRESS] naredba [COMMAND]",
    "gui.blocklyText.oled2864.displayInit": "Početna adresa OLED zaslona [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 zaslon [TEXT] u redu [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 zaslon [TEXT] na poziciji X: [X] Y: 16*[Y]",
    "gui.blocklyText.oled2864.screenRotation": "rotiraj zaslon na [ROTATION] stupnjeva",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 prozirni",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "očitaj pin [PIN] [DHT] [TYPE]",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx pin [PIN] Vrijednost koncentracije PM [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 ultrazvučna inicijalizacija Naziv [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04 ultrazvučna jedinica za mjerenje udaljenosti [UNIT]",
    "gui.blocklyText.hcsr04.readData": "očitaj HC-SRO4 Trig [PINT] Echo [PINE] jedinica [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx pin [PIN] 0,1 litara zraka u [DIAMETER] broj čestica",
    "gui.blocklyText.sen0170.readWindspeed": "očitaj pin [PIN] skala vjetra",
    "gui.blocklyText.sen0228.init": "Inicijaliziraj I2C adresu 0x10 senzora ambijentalnog svjetla (VEML7700).",
    "gui.blocklyText.sen0228.readAmbientLight": "očitaj ambijentalno svjetlo (VEML7700)",
    "gui.blocklyText.sen0097.init": "Inicijaliziraj I2C adresu 0x23 senzora ambijentalnog svjetla (BH1750).",
    "gui.blocklyText.sen0097.readAmbientLight": "očitaj Ambijentalno svjetlo (BH1750)",
    "gui.blocklyText.dfr0026.readAmbientLight": "očitaj pin [PIN] Ambijentalno svjetlo",
    "gui.blocklyText.sen0177.readDustSensor": "očitaj [TYPE] (ug/m3) sučelje [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "pin(D) [PIN] pin(MCLR) [RST] trenutna gesta [TYPE]?",
    "gui.blocklyText.sen0203.readHeartRate": "očitaj pin [PIN] [TYPE] prebaci na [SWITCH]",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] pin [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "očitaj pin [PIN] u sivim tonovima",
    "gui.blocklyText.motucamera.initIICSerial": "mu kamera init [MU] sučelje [IICSERIAL] do uspjeha init I2C adresa 0x60&0x61&0x62&0x63",
    "gui.blocklyText.motucamera.resetConfig": "mu kamera [MU] vratiti na zadane postavke",
    "gui.blocklyText.motucamera.algorithmSwitch": "mu kamera [MU] [SWITCH] algoritam [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "mu kamera [MU] [LED] kada se otkrije [COLOR1] kada se ne otkrije [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "mu kamera [MU] postavi algoritam [ALGORITHM] razina [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "mu kamera [MU] digitalni zoom [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "mu kamera [MU] postavljena brzina prijenosa [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "mu kamera [MU] postavi ravnotežu bijele [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "mu kamera [MU] high FPS mod [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "mu kamera [MU] je detektirana [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": "mu kamera [MU] detektira prepoznavanje boja x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "mu kamera [MU] otkriva boju otkriva boju = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "mu kamera dohvati [MU] alogritam [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "mu kamera [MU] prepoznavanje boja [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "mu kamera [MU] kartica oblika [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "mu kamera [MU] prometna kartica [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "mu kamera [MU] broj kartica [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "mu kamera [MU] boja prepoznaje boju = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "Prepoznavanje boja",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "Prepoznavanje boja",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "Otkrivanje lopte",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "Otkrivanje tijela",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "Kartica oblika",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "Prometna kartica",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "Brojna kartica",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "omogućiti",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "onemogućiti",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "isključeno",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "Crvena",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "zelena",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "plava",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "žuta",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "ljubičasta",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "kajanski",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "bijela",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "isključeno",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "crvena",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "zelena",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "plava",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "žuta",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "ljubičasta",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "kajanski",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "bijela",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "Auto",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "Brzina",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "Ravnoteža",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "Točnost",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "Auto",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "Zaključaj",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "bijela",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "žuta",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "Auto",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "Razina 1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "Razina 2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "Razina3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "Razina4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "Razina 5",
    "gui.blocklyText.motucamera.setHighFPSOff": "ISKLJUČENO",
    "gui.blocklyText.motucamera.setHighFPSOn": "UKLJUČENO",
    "gui.blocklyText.motucamera.colorDetectBlack": "crno",
    "gui.blocklyText.motucamera.colorDetectWhite": "bijela",
    "gui.blocklyText.motucamera.colorDetectRed": "crvena",
    "gui.blocklyText.motucamera.colorDetectYellow": "žuta",
    "gui.blocklyText.motucamera.colorDetectGreem": "zelena",
    "gui.blocklyText.motucamera.colorDetectCayan": "kajanski",
    "gui.blocklyText.motucamera.colorDetectBlue": "plava",
    "gui.blocklyText.motucamera.colorDetectPerple": "perpurno",
    "gui.blocklyText.motucamera.colorDetectOthers": "drugi",
    "gui.blocklyText.motucamera.colorDetectPositionX": "x položaj",
    "gui.blocklyText.motucamera.colorDetectPositionY": "y položaj",
    "gui.blocklyText.motucamera.colorDetectPositionW": "širina",
    "gui.blocklyText.motucamera.colorDetectPositionH": "visina",
    "gui.blocklyText.motucamera.colorDetectPositionL": "označiti",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "crveni kanal",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "zeleni kanal",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "plani kanal",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "oznaka kanala",
    "gui.blocklyText.motucamera.shapeCardTick": "označeno",
    "gui.blocklyText.motucamera.shapeCardCross": "križ",
    "gui.blocklyText.motucamera.shapeCardCircle": "krug",
    "gui.blocklyText.motucamera.shapeCardSquare": "kvadrat",
    "gui.blocklyText.motucamera.shapeCardTriangle": "trokut",
    "gui.blocklyText.motucamera.trafficCardForward": "Naprijed",
    "gui.blocklyText.motucamera.trafficCardLeft": "Lijevo",
    "gui.blocklyText.motucamera.trafficCardRight": "Desno",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "Okreni se",
    "gui.blocklyText.motucamera.trafficCardStop": "Stop",
    "gui.blocklyText.motucamera.lightSensorUse": "mu kamera [MU] svjetlosni senzor omogući [LIGHTFUNC] detekciju",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "mu kamera [MU] osjetljivost svjetlosnog senzora [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "mu kamera [MU] svjetlosni senzor čitanje otkrivanje blizine",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "mu kamera [MU] svjetlosni senzor čita detekciju ambijentalnog svjetla",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "mu kamera [MU] svjetlosni senzor očitao jednom gestu i spremio",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "mu kamera [MU] pokret svjetlosnog senzora = [GESTURE]",
    "gui.blocklyText.motucamera.upward": "prema gore",
    "gui.blocklyText.motucamera.downward": "prema dolje",
    "gui.blocklyText.motucamera.leftward": "ulijevo",
    "gui.blocklyText.motucamera.rightward": "desno",
    "gui.blocklyText.motucamera.pull": "Vuci",
    "gui.blocklyText.motucamera.push": "gurnuti",
    "gui.blocklyText.motucamera.none": "nikakav",
    "gui.blocklyText.motucamera.default": "zadano",
    "gui.blocklyText.motucamera.low": "nizak",
    "gui.blocklyText.motucamera.middle": "sredini",
    "gui.blocklyText.motucamera.high": "visoka",
    "gui.blocklyText.motucamera.proximity": "blizina",
    "gui.blocklyText.motucamera.ambientLight": "ambijentalno svjetlo",
    "gui.blocklyText.motucamera.gesture": "gesta",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi inicijalizacija porta [SERIALPORT] RX[RX] TX[TX] UART brzina prijenosa [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi set ssid [SSID] lozinka [PASSWORD] mod [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi veza uspjela？",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi prekid veze",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi postavi ciljni IP [IP] port [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi čita ciljni IP",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi čita lokalni IP",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi čitanje",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi pisanje [WRITE]",
    "gui.blocklyText.dfr0552.init": "DA modul init I2C adresa [ADDR] referentni napon [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "Amplituda trokutastog vala izlaza DA modula [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "Amplituda sinusnog vala izlaza DA modula [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "Izlazni napon DA modula [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "Memorijski napon DA modula [VOLTAGE]mV",
    "gui.blocklyText.sen0117.init": "Inicijaliziraj sučelje modula [SERIALPORT] Rx[RX] Tx[TX] Zauzeto[BUSY]",
    "gui.blocklyText.sen0117.playText": "Reproducira tekst [TEXT] pozadinska glazba [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "Reproducira glasovni upit [INDEX] čeka [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "Reproducira upute za akord [INDEX] na čekanju [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "Postavi [INDEX] na [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "Postavljanje brzine govora [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "Dohvati modul za sintezu govora [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "Izgledi volumen",
    "gui.blocklyText.sen0117.Background": "Glasnoća pozadine",
    "gui.blocklyText.sen0117.Speed": "Ubrzati",
    "gui.blocklyText.sen0117.playChordCueTone": "Reproducira melodije zvona akorda [INDEX] čeka [WAIT]",
    "gui.blocklyText.sen0117.No": "Ne (0)",
    "gui.blocklyText.sen0117.setPlayMode": "Postavi način reprodukcije na [MODE]",
    "gui.blocklyText.sen0117.pause": "Pauza",
    "gui.blocklyText.sen0117.resume": "Nastavi",
    "gui.blocklyText.sen0117.stop": "Stop",
    "gui.blocklyText.sen0117.isIdle": "Završetak igre?",
    "gui.blocklyText.dfr0576.selectPort": "Odaberi port [PORT]",
    "gui.blocklyText.dfr0576.begin": "Inicijaliziraj adresu I2C kaskadnog modula [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680 inicijalizira I2C adresu 0x77",
    "gui.blocklyText.sen0248.readBME680": "Dohvati senzor BME680 [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "Temperatura (°C)",
    "gui.blocklyText.sen0248.Pressure": "Tlak (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Vlažnost (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Nadmorska visina (m)",
    "gui.blocklyText.sen0248.GasResistance": "Otpor plina (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "inicijalizirati adresu ultrazvučnog senzora I2C [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Promjena adrese I2C ultrazvučnog senzora [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Očitaj udaljenost ultrazvučnog senzora (cm)",
    "gui.blocklyText.sen0304.readTemperature": "Očitaj temperaturu ultrazvučnog senzora (°C)",
    "gui.blocklyText.sen0307.readDistance": "Očitaj [PIN] analogni ultrazvučni val (cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 inicijalizacijska adresa [ADDR] način [MODE]",
    "gui.blocklyText.sen0250.Step": "Korak",
    "gui.blocklyText.sen0250.Measure": "Mjera",
    "gui.blocklyText.sen0250.readAccelerated": "Dohvati ubrzanje (g) osi [AXIS]",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 dohvati broj koraka",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "Žiro",
    "gui.blocklyText.sen0250.Acc": "Ubrzanje (g)",
    "gui.blocklyText.sen0224.init": "Inicijaliziraj LIS2DH senzor I2C adresu 0x18",
    "gui.blocklyText.sen0224.readAccelerated": "Očitaj vrijednost ubrzanja LIS2DH osi senzora [AXIS] (mg)",
    "gui.blocklyText.dfr0023.readLM35": "očitaj pin [PIN] LM35 temperatura (℃)",
    "gui.blocklyText.dfr0027.whenVibration": "kada je pin [PIN] otkrio vibraciju",
    "gui.blocklyText.dfr0028.readTiltSensor": "pin [PIN] otkriven nagib?",
    "gui.blocklyText.dfr0029.readPress": "pin [PIN] gumb pritisnut?",
    "gui.blocklyText.dfr0030.readTouch": "pin [PIN] otkriven dodir?",
    "gui.blocklyText.dfr0033.readMagnetic": "pin [PIN] otkriven magnet?",
    "gui.blocklyText.dfr0034.readSound": "očitaj pin [PIN] Glasnoća",
    "gui.blocklyText.dfr0094.readIRReceived": "očitaj pin [PIN] IR podataka",
    "gui.blocklyText.dfr0094.onReceiveEvent": "Kada se primi infracrveni signal Pin [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "adresa",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "naredba",
    "gui.blocklyText.midi.midiInit": "Pokretanje sučelja MIDI modula [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.midi.midiOutput": "MIDI izlazni kanal [CHANNEL] visina [PITCH] brzina [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "očitaj pin [PIN] senzor ugljičnog monoksida (CO).",
    "gui.blocklyText.dfr0051.readVoltage": "očitaj napon (V) na pinu [PIN]",
    "gui.blocklyText.dfr0052.readPiezoSensor": "očitaj Piezo disk senzor vibracija na pinu [PIN] ",
    "gui.blocklyText.dfr0058.readRotation": "očitaj Rotacijski senzor na pinu [PIN]",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "očitaj pin [PIN] JoyStick [AXIS] Os",
    "gui.blocklyText.dfr0061.readJoyStickButton": "pin [PIN] JoyStick tipka (Z) pritisnuta?",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut početni podatkovni pin [DATAPIN] RTC pin [RTCPIN] cls pin [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Šifra segmenta digitalne cijevi 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Broj prikaza digitalne cijevi [NUM]",
    "gui.blocklyText.dfr0072.dfr0072Dot": "Točka prikaza digitalne cijevi [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "pravi",
    "gui.blocklyText.dfr0072.dotFalse": "lažno",
    "gui.blocklyText.dfr0072.numOff": "isključeno",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Očitaj litijsku bateriju [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "napon (mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "preostala baterija (%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD modul init I2C adresa [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD modul čita [PIN] napon (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD modul dobiva razliku napona prolaza [AISLE].",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 i 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 i 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1. i 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 i 3",
    "gui.blocklyText.dfr0588.initPin": "Igla početne temperature [TEM] igla vlažnosti [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "dohvati temperaturnu jedinicu [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "dohvati vlažnost (%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "CELZIJA",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "FAHRENHEIT",
    "gui.blocklyText.dfr0076.readFlameSensor": "očitaj pin [PIN] Senzor plamena",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom dohvati [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom čitanje naziva teksta [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom čitanje naziva broja [KEY]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom init i2c adresa [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom pisanje teksta [VALUE] ime [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom zapis broja [VALUE] ime [KEY]",
    "gui.blocklyText.dfr0117.text": "Tekst",
    "gui.blocklyText.dfr0117.number": "Broj",
    "gui.blocklyText.dfr0117.eepromDeleteData": "Naziv brisanja i2c eeproma [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom serijski ispis svih imena baud [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom naziv [KEY] postoji?",
    "gui.blocklyText.dfr0117.eepromFormat": "i2c eeprom format",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom ime [KEY] je [TYPE]?",
    "gui.blocklyText.dfr0117.nameCount": "Broj imena",
    "gui.blocklyText.dfr0117.memorySize": "Prostor za pohranu (bajt)",
    "gui.blocklyText.dfr0117.residualMemorySize": "Preostali prostor za pohranu (bajt)",
    "gui.blocklyText.dfr0143.readAccelerometer": "očitaj pin [PIN] Troosni akcelerometar [AXIS] Os",
    "gui.blocklyText.sen0018.readInfraredMotion": "očitaj pin [PIN] Digitalni infracrveni senzor kretanja",
    "gui.blocklyText.sen0014.readDistance": "očitaj pin [PIN] Sharp IR senzor udaljenosti (mm)",
    "gui.blocklyText.sen0114.readMoisture": "očitaj pin [PIN] Senzor vlage u tlu",
    "gui.blocklyText.sen0121.readSteam": "očitaj pin [PIN] Senzor pare",
    "gui.blocklyText.sen0204.readLiquidLevel": "pin [PIN] razina tekućine dosegla određenu visinu?",
    "gui.blocklyText.sen0203.heartRate": "broj otkucaja srca (bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "zasićenost krvi kisikom (HbO2)",
    "gui.blocklyText.sen0202.up": "Gore",
    "gui.blocklyText.sen0202.down": "dolje",
    "gui.blocklyText.sen0202.left": "Lijevo",
    "gui.blocklyText.sen0202.right": "Pravo",
    "gui.blocklyText.sen0202.cw": "CW",
    "gui.blocklyText.sen0202.ccw": "CCW",
    "gui.blocklyText.dhtTHSensor.temperature": "temperatura (°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "vlaga (%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "pin [PIN] RGB LED [NUM1] do [NUM2] prikaži u boji [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "crvena [RED] zelena [GREEN] plava [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "pin [PIN]  [NUM1] do [NUM2] RGB LED prikazuju gradijent boje od [COLOR1] do [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "pin [PIN] pomakni piksele za [NUM]",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "pin [PIN] zakreni piksele za [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "pin [PIN] [NUM] RGB LED dioda",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "pin [PIN] osvjetljenje [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "pin [PIN] isključi sve RGB LED diode",
    "gui.blocklyText.ws2812.showHistogram": "pin [PIN] [NUM1] do [NUM2] RGB LED diode prikazuju histogramsku trenutnu vrijednost: [VALUE] maks. vrijednost: [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "pin [PIN] RGB LED osvjetljenje [BRIGHTNESS]",
    "gui.blocklyText.neopixel.init": "Naziv inicijalizacije lampe [NAME] pin [PIN] broj [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Svjetlosna traka [NAME] [INDEX] Boja [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Svjetlosna traka [NAME] [INDEX] Crvena [R] Zelena [G] Plava [B]",
    "gui.blocklyText.neopixel.fillColor": "Svjetlosna traka [NAME] potpuno je osvijetljena, a boja je [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Svjetlosna traka [NAME] Sve na crveno [R] Zeleno [G] Plavo [B]",
    "gui.blocklyText.neopixel.flush": "Postavke svjetlosne trake [NAME] stupaju na snagu",
    "gui.blocklyText.neopixel.off": "Svjetlosna traka [NAME] je isključena",
    "gui.blocklyText.neopixel.setBrightness": "Svjetlosna traka [NAME] postavlja osvjetljenje na [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Efekt duginog svjetla [NAME] Količina [COUNT] Svjetlina [BRIGHTNESS] Pomak [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7 (mPython ploča)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9 (mPython okvir)",
    "gui.blocklyText.dsTSensor.ds18b20": "Očitaj pin [PIN] DS18B20 temperatura (°C)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280 inicijalizira I2C adresu 0x76",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "očitaj [TYPE] senzor BMP280",
    "gui.blocklyText.bmpTSensor.atmosPressure": "barometarski (Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "nadmorsku visinu (m)",
    "gui.blocklyText.bmpTSensor.temperature": "temperatura (℃)",
    "gui.blocklyText.huskylens.init": "HuskyLens  inicijaliziraj pin [SETTING] dok ne uspiješ",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens primijeni algoritam [ALGORITHM]",
    "gui.blocklyText.huskylens.requestFirst": "HuskyLens zatraži jednom podatke i spremi ih u rezultat",
    "gui.blocklyText.huskylens.resultGetIsStudy": "HuskyLens provjeri je li ID [IDNUM] naučen iz rezultata?",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "HuskyLens provjeri je li ID [IDNUM] [BOXARROW] na zaslonu iz rezultata?",
    "gui.blocklyText.huskylens.resultGetIDParameter": "HuskyLens dohvati [PARAMETER] od ID [IDNUM] [TYPE] iz rezultata",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens provjeri je li [BOXARROW] na zaslonu iz rezultata?",
    "gui.blocklyText.huskylens.resultGetParameter": "HuskyLens dohvati [PARAMETER2] od broja [IDNUM] [TYPE] iz rezultata",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens dohvati ukupan broj naučenih ID-ova iz rezultata",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens dohvati ukupan broj [BOXARROW] iz rezultata",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens dohvati ukupan broj ID-a [IDNUM] [BOXARROW] iz rezultata",
    "gui.blocklyText.huskylens.resultGetIDofWhichParameter": "HuskyLens dohvati [PARAMETER] od ID-a [IDNUM] br. [BOXNUM] [TYPE] iz rezultata",
    "gui.blocklyText.huskylens.setIDname": "HuskyLens imenuj ID [ID] trenutnog algoritma kao [NAME]",
    "gui.blocklyText.huskylens.TakeScreenshot": "HuskyLens dohvati [TYPE] i spremi na SD karticu",
    "gui.blocklyText.huskylens.writeOSD": "HuskyLens prikaži prilagođeni tekst [NAME] na poziciji x [X] y [Y] na zaslonu",
    "gui.blocklyText.huskylens.clearOSD": "HuskyLens obriši sve prilagođene tekstove na zaslonu",
    "gui.blocklyText.huskylens.learnOnce": "HuskyLens jednom automatski nauči ID [ID]",
    "gui.blocklyText.huskylens.clearLearn": "HuskyLens zaboravi sve naučene podatke trenutnog algoritma",
    "gui.blocklyText.huskylens.loadAndSaveModel": "HuskyLens [TYPE] trenutne podatke algoritma kao br. [INDEX] model SD kartice",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.A": "fotografiju",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.B": "snimku zaslona",
    "gui.blocklyText.huskylens.LOADSAVE.A": "spremi",
    "gui.blocklyText.huskylens.LOADSAVE.B": "učitaj",
    "gui.blocklyText.huskylens.resultGetNearCenter": "HuskyLens iz rezultata dohvaća [PARAMETER2] od [TYPE] najbliže središtu zaslona",
    "gui.blocklyText.huskylens.box": "okvir",
    "gui.blocklyText.huskylens.arrow": "strelica",
    "gui.blocklyText.huskylens.faceRecognition": "Prepoznavanje lica",
    "gui.blocklyText.huskylens.objectTracking": "Praćenje objekata",
    "gui.blocklyText.huskylens.objectRecognition": "Prepoznavanje objekta",
    "gui.blocklyText.huskylens.lineTracking": "Praćenje linije",
    "gui.blocklyText.huskylens.colorRecognition": "Prepoznavanje boja",
    "gui.blocklyText.huskylens.tagRecognition": "Prepoznavanje oznaka",
    "gui.blocklyText.huskylens.objectClassification": "Klasifikacija objekata",
    "gui.blocklyText.huskylens.QRRecognition": "Prepoznavanje QR kôda (samo Edu)",
    "gui.blocklyText.huskylens.barcodeRecognition": "Prepoznavanje crtičnog kôda (samo Edu)",
    "gui.blocklyText.huskylens.x": "X centar",
    "gui.blocklyText.huskylens.y": "Y centar",
    "gui.blocklyText.huskylens.w": "širina",
    "gui.blocklyText.huskylens.h": "visina",
    "gui.blocklyText.huskylens.x1": "X početak",
    "gui.blocklyText.huskylens.y1": "Y početak",
    "gui.blocklyText.huskylens.x2": "X krajnja točka",
    "gui.blocklyText.huskylens.y2": "Y krajnja točka",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "Metoda komunikacije",
    "gui.blocklyText.huskylens.address": "Adresa",
    "gui.blocklyText.huskylens.sdaGreen": "SDA (zelena)",
    "gui.blocklyText.huskylens.sclBlue": "SCL (plava)",
    "gui.blocklyText.huskylens.rxGreen": "Rx (zelena)",
    "gui.blocklyText.huskylens.txBlue": "Tx (plava)",
    "gui.blocklyText.ps2.initPs2Handle": "init PS2 upravljanje DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] setRunmble [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2 open runmble [RUMBLE] snaga [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "Stanja ponovnog ispiranja PS2",
    "gui.blocklyText.ps2.ps2ButtonState": "PS2 gumb [BUTTON] stanje je [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2 joystick [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "UKLJUČENO",
    "gui.blocklyText.ps2.OFF": "ISKLJUČENO",
    "gui.blocklyText.ps2.Triangle": "Trokut",
    "gui.blocklyText.ps2.Circle": "Krug",
    "gui.blocklyText.ps2.Cross": "Križ",
    "gui.blocklyText.ps2.Square": "Kvadrat",
    "gui.blocklyText.ps2.Left1": "lijevo 1",
    "gui.blocklyText.ps2.Left2": "lijevo 2",
    "gui.blocklyText.ps2.Left3": "lijevo 3",
    "gui.blocklyText.ps2.Right1": "desno 1",
    "gui.blocklyText.ps2.Right2": "desno 2",
    "gui.blocklyText.ps2.Right3": "desno 3",
    "gui.blocklyText.ps2.Up": "Gore",
    "gui.blocklyText.ps2.Right": "Pravo",
    "gui.blocklyText.ps2.Down": "dolje",
    "gui.blocklyText.ps2.Left": "Lijevo",
    "gui.blocklyText.ps2.Select": "Odaberi",
    "gui.blocklyText.ps2.Start": "Početak",
    "gui.blocklyText.ps2.Hold": "Stani",
    "gui.blocklyText.ps2.Pressed": "pritisnut",
    "gui.blocklyText.ps2.Released": "Pušten na slobodu",
    "gui.blocklyText.ps2.CHANGE": "PROMIJENITI",
    "gui.blocklyText.ps2.RightX": "Desno X",
    "gui.blocklyText.ps2.RightY": "Desno Y",
    "gui.blocklyText.ps2.LeftX": "Lijevo X",
    "gui.blocklyText.ps2.LeftY": "Lijevo Y",
    "gui.blocklyText.sen0236.bme280Init": "BME280 inicijalizira I2C adresu 0x77",
    "gui.blocklyText.sen0236.readbme280Sensor": "Očitaj I2C pin BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "Temperatura (℃)",
    "gui.blocklyText.sen0236.Humidity": "Vlažnost (%rh)",
    "gui.blocklyText.sen0236.Altitude": "Nadmorska visina (m)",
    "gui.blocklyText.sen0236.Pressure": "Tlak (Pa)",
    "gui.blocklyText.sen0517.icp10111Init": "ICP10111 inicijalizira I2C adresu 0x63",
    "gui.blocklyText.sen0517.readicp10111Sensor": "Dohvati ICP10111 senzor [TYPE]",
    "gui.blocklyText.sen0517.Temperature": "Temperatura (℃)",
    "gui.blocklyText.sen0517.Altitude": "Nadmorska visina (m)",
    "gui.blocklyText.sen0517.Pressure": "Tlak (Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "inicijaliziraj adresu I2C LCD zaslona [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "prikaži [TEXT] u LCD retku [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "prikaži [TEXT] na LCD položaju X: [X] Y: [Y]",
    "gui.blocklyText.LCD1602.displayClear": "obriši ekran",
    "gui.blocklyText.IICScan.readICCAddr": "očitaj skeniranu adresu I2C uređaja",
    "gui.blocklyText.mpyfile.initFile": "inicijalna datoteka [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsList": "očitaj datoteku [FILENAME] kao listu, koristi [SEPARATE] Odvojeno",
    "gui.blocklyText.mpyfile.writeFile": "pisati u datoteku [FILENAME] podatke [WRITEDATA], koristiti [SEPARATE] Odvojeno",
    "gui.blocklyText.mpyfile.initFileMaixduino": "inicijalna datoteka [ROOT] [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsListMaixduino": "očitaj datoteku [ROOT] [FILENAME] kao listu, koristi [SEPARATE] Odvojeno",
    "gui.blocklyText.mpyfile.writeFileMaixduino": "pisati u datoteku [ROOT] [FILENAME] podatke [WRITEDATA], koristi [SEPARATE] Odvojeno",
    "gui.blocklyText.mpyfile.enter": "enter",
    "gui.blocklyText.mpyfile.comma": "zarez",
    "gui.blocklyText.mpyfile.semicolon": "točka-zarez",
    "gui.blocklyText.mpyfile.space": "space",
    "gui.blocklyText.dfr0760.softwareSerial": "Inicijaliziraj [VERSION] sučelje modula za sintezu govora [SERIALPORT] Rx(zeleno)[PINR] Tx(plavo)[PINT]",
    "gui.blocklyText.dfr0760.init": "init modul za sintezu govora I2C način [VERSION] I2C adresa 0x40",
    "gui.blocklyText.dfr0760.syntheticVoice": "sinteza govora [VOICEVALUE]",
    "gui.blocklyText.dfr0760.flashStorage": "spremi u flash [FALSH]",
    "gui.blocklyText.dfr0760.speechSynthesisSettings": "postavi glasnoću [VOLUME] brzina [SPEED] ton [INTONATION] zvučnik [SPEEKER]",
    "gui.blocklyText.dfr0760.setEnglishPron": "postavi engleski način izgovora [Pron]",
    "gui.blocklyText.dfr0760.letter": "pismo",
    "gui.blocklyText.dfr0760.word": "riječ",
    "gui.blocklyText.dfr0760.manvoice1": "muški 1(V1)",
    "gui.blocklyText.dfr0760.manvoice2": "muški 2(V1)",
    "gui.blocklyText.dfr0760.womanvoice1": "ženski 1(V1)",
    "gui.blocklyText.dfr0760.womanvoice2": "ženski 2(V1)",
    "gui.blocklyText.dfr0760.womanvoice3": "ženski 3(V1)",
    "gui.blocklyText.dfr0760.donaldDuck": "Donald Duck (V1)",
    "gui.blocklyText.dfr0715.ASRInit": "modul za prepoznavanje govora početni način prepoznavanja [MODE] mikrofonski način [PHONEMODE] I2C adresa 0x4F",
    "gui.blocklyText.dfr0715.addKeywords": "dodaj ključne riječi [KEYWORD] broj [NUMBER]",
    "gui.blocklyText.dfr0715.beginToIdentify": "postavljanje završeno početak prepoznavanja",
    "gui.blocklyText.dfr0715.recognitionOfASpeech": "prepoznati govor jednom, dohvati broj",
    "gui.blocklyText.dfr0715.loop": "petlja",
    "gui.blocklyText.dfr0715.password": "naredba",
    "gui.blocklyText.dfr0715.button": "dugme",
    "gui.blocklyText.dfr0715.mic": "zadano",
    "gui.blocklyText.dfr0715.word": "vanjski audio",
    "gui.blocklyText.sdcard.initCS": "Inicijalizacija igle SD filma [CSPIN] uspjela?",
    "gui.blocklyText.sdcard.readFileNLine": "SD čita datoteku [FILE] red [LINE]",
    "gui.blocklyText.sdcard.readFileAll": "SD čita datoteku [FILE] sav sadržaj",
    "gui.blocklyText.sdcard.writeFile": "SD pisanje datoteke [FILE] sadržaj [MSG] način rada [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD brisanje datoteke [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD dohvati datoteku [FILE] informacije [INFO]",
    "gui.blocklyText.sdcard.NAME": "IME",
    "gui.blocklyText.sdcard.PATH": "STAZA",
    "gui.blocklyText.sdcard.TYPE": "TIP",
    "gui.blocklyText.sdcard.LINE": "CRTA",
    "gui.blocklyText.sdcard.SIZE": "VELIČINA",
    "gui.blocklyText.sdcard.APPEND": "DODATI",
    "gui.blocklyText.sdcard.REPLACE": "ZAMIJENITI",
    "gui.blocklyText.sdcard.WARP": "WARP",
    "gui.blocklyText.sdcard.NOWARP": "NOWARP",
    "gui.blocklyText.sdcard.lsFile": "SD serijski port ispisuje sve informacije o datoteci u baudu [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "inicijaliziraj serijski softver [SERIALPORT] RX: [PINR] TX: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "softver serijski [SERIALPORT] brzina prijenosa [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "softverski serijski [SERIALPORT] izlaz [TEXT] [WAY], [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "Postoje li podaci za čitanje na serijskom [SERIALPORT] softvera?",
    "gui.blocklyText.softSerialport.readSerialportData": "očitaj serijski softver [SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "čitanje [TYPE] na softverskom serijskom [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "na bluetooth spojen",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "na bluetooth isključen",
    "gui.blocklyText.bluetooth.receivedKey": "kada je bluetooth primio ključ [KEY] [TYPE]",
    "gui.blocklyText.bluetooth.string": "Niz",
    "gui.blocklyText.bluetooth.number": "Broj",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "kada je bluetooth primio podatke i ispuni [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "pošalji ključ niza [KEY] vrijednost [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "pošalji brojčani ključ [KEY] vrijednost [VALUE]",
    "gui.blocklyText.bluetooth.openService": "otvori bluetooth uslugu",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "primio podatke s bluetooth-a",
    "gui.blocklyText.leonardo.functionStarts": "pokreni Leonardo",
    "gui.blocklyText.leonardo.setBaud": "postavi [SERIAL] brzinu prijenosa na [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "iz [SERIAL] čitanje serijskih podataka int ili float [READTYPE]",
    "gui.blocklyText.leonardo.getNumOfFloat": "[TARGET] zadržava [NNUMS] decimala",
    "gui.blocklyText.leonardo.serialPort1": "Serijski port0",
    "gui.blocklyText.leonardo.serialPort2": "Serijski port1",
    "gui.blocklyText.leonardo.dataReadable": "Postoje li podaci za čitanje na [SERIAL]?",
    "gui.blocklyText.leonardo.readSerialData": "očitaj [SERIAL] podatke",
    "gui.blocklyText.arduinounoR3.initTcs34725": "Inicijalizira I2C adresu 0x29 senzora boje TCS34725",
    "gui.blocklyText.arduinounoR3.functionStarts": "Pokreće se program Uno kit",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "Inicijalizira TMI1650 I2C adresu 0x34",
    "gui.blocklyText.arduinounoR3.readdigital": "očitaj pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "Digitalni senzor",
    "gui.blocklyText.arduinounoR3.buttonModule": "Modul gumba",
    "gui.blocklyText.arduinounoR3.stickButton": "Preklopna tipka",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "Senzor vibracija",
    "gui.blocklyText.arduinounoR3.readAnalog": "očitaj pin [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "Anologni senzor",
    "gui.blocklyText.arduinounoR3.lightSensor": "Svijetli senzor",
    "gui.blocklyText.arduinounoR3.soundSensor": "Senzor zvuka",
    "gui.blocklyText.arduinounoR3.stickDirection": "Rocker Direction",
    "gui.blocklyText.arduinounoR3.angleSensor": "Senzor kuta",
    "gui.blocklyText.arduinounoR3.graySensor": "Senzor sivih tonova",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "Senzor vlage u tlu",
    "gui.blocklyText.arduinounoR3.open": "uključi",
    "gui.blocklyText.arduinounoR3.close": "isključi",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "Digitalni aktuator",
    "gui.blocklyText.arduinounoR3.ledLights": "LED svjetla",
    "gui.blocklyText.arduinounoR3.fanModule": "Modul ventilatora",
    "gui.blocklyText.arduinounoR3.recordModule": "Modul za snimanje",
    "gui.blocklyText.arduinounoR3.relay": "Relej",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "Očitavanje ultrazvučne udaljenosti (cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "Simulacijski aktuator",
    "gui.blocklyText.arduinounoR3.writeAnalog": " postavi pin [PIN] [MODULE] na [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "kreni naprijed",
    "gui.blocklyText.arduinounoR3.backward": "krenu unatrag",
    "gui.blocklyText.arduinounoR3.turnLeft": "skreni lijevo",
    "gui.blocklyText.arduinounoR3.turnRight": "skreni desno",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "robot [DIR] pri [SPEED] % brzini",
    "gui.blocklyText.arduinounoR3.motorForward": "kreni naprijed",
    "gui.blocklyText.arduinounoR3.motorReverse": "kretati se unatrag",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "motor [MOTOR] [DIR] na [SPEED] % brzine",
    "gui.blocklyText.arduinounoR3.stopMotor": "robot (MA i MB motori) prestati se kretati",
    "gui.blocklyText.arduinounoR3.setServo": "postavi pin [PIN] servo na [DEGREE] stupnjeva",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "Infracrveno zračenje [TYPE] pin [PIN] vrijednost [VALUE] bit [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "infracrveni pin [PIN] je primio signal pritiska [BUTTON]？",
    "gui.blocklyText.arduinounoR3.noSound": "bez zvuka",
    "gui.blocklyText.arduinounoR3.half": "Pola",
    "gui.blocklyText.arduinounoR3.quarter": "Četvrtina",
    "gui.blocklyText.arduinounoR3.oneInEighth": "Jedna osmina",
    "gui.blocklyText.arduinounoR3.wholeBeat": "Cijeli ritam",
    "gui.blocklyText.arduinounoR3.doubleBeat": "Dvostruki udarac",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "pin [PIN] sviraj ton trube [TONE] u trajanju od [BEAT] takta",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "postavi pin [PIN] glasnoću MP3 modula na [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "postavi pin [PIN] način reprodukcije MP3 modula na [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "Stop",
    "gui.blocklyText.arduinounoR3.play": "Pokreni",
    "gui.blocklyText.arduinounoR3.loop": "Petlja",
    "gui.blocklyText.arduinounoR3.random": "Slučajna",
    "gui.blocklyText.arduinounoR3.pause": "Pauza",
    "gui.blocklyText.arduinounoR3.previous": "Prethodno",
    "gui.blocklyText.arduinounoR3.next": "Sljedeći",
    "gui.blocklyText.arduinounoR3.VolumePlus": "Glasnoća+",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "Glasnoća-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "pričvrsti [PIN] MP3 modul za reprodukciju [NUM] pjesama",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "očitaj pin [PIN] LM35 temperatura (°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "očitaj pin [PIN] DHT11 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "crvena",
    "gui.blocklyText.arduinounoR3.blue": "plava",
    "gui.blocklyText.arduinounoR3.green": "zelena",
    "gui.blocklyText.arduinounoR3.readColorSensor": "očitaj senzor boje TCS34725 [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "četverobitna nixie cijev TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "uključi",
    "gui.blocklyText.arduinounoR3.tm1650Close": "isključi",
    "gui.blocklyText.arduinounoR3.clearScreen": "čisti zaslon",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": " 4-bitna nixie cijev TM1650 niz za prikaz [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "[STATUS] [ORDER] decimalna točka 4-bitne nixie cijevi TM1650",
    "gui.blocklyText.arduinounoR3.bright": "posvijetliti",
    "gui.blocklyText.arduinounoR3.dark": "potamniti",
    "gui.blocklyText.arduinounoR3.setLightColor": "prikvači [PIN] [NUM] LED zaslon [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "pričvrsti [PIN] [NUM] LED R:[R] G:[G] B:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "pin [PIN] [NUM] LED dioda",
    "gui.blocklyText.arduinounoR3.ledControl": "LED matrica (max7219) početni parametar DIN pin [DIN], CS pin [CS], CLK pin [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "LED matrica (max7219) prikazuje uzorak [PIC]",
    "videoSensing.categoryName": "Video senzor",
    "videoSensing.direction": "smjer",
    "videoSensing.motion": "pokret",
    "videoSensing.off": "zatvori",
    "videoSensing.on": "otvori",
    "videoSensing.onFlipped": "zrcaljenje otvoreno",
    "videoSensing.setVideoTransparency": "postavi transparentnost videa na [TRANSPARENCY]",
    "videoSensing.sprite": "lik",
    "videoSensing.stage": "pozornica",
    "videoSensing.videoOn": "video [ATTRIBUTE] na temu [SUBJECT]",
    "videoSensing.videoToggle": "okreni video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "kad video pokret > [REFERENCE]",
    "gui.blocklyText.max.maxStart": "pokreni Max",
    "gui.blocklyText.max.onboardButtonPressed": "gumb na ploči [PRESSED]?",
    "gui.blocklyText.max.pressed": "pritisnut",
    "gui.blocklyText.max.notPressed": "nije pritisnut",
    "gui.blocklyText.max.all": "-1 SVE",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "postavi RGB svjetla [TYPE] boja [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "očitaj senzor zvuka",
    "gui.blocklyText.max.playSoundEffect": "reproduciraj zvučni efekt [SOUND]",
    "gui.blocklyText.max.setDPinValue": "postavi digitalni pin [PIN] na [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "očitaj digitalni pin [PIN]",
    "gui.blocklyText.max.readAPinValue": "očitaj analogni pin [PIN]",
    "gui.blocklyText.max.setServo": "postavi pin [PIN] servo na [DEGREE] stupnjeva",
    "gui.blocklyText.max.readLinefindingSensor": "čitanje [DIR] senzora za praćenje linije",
    "gui.blocklyText.max.left": "Lijevo",
    "gui.blocklyText.max.middle": "sredina",
    "gui.blocklyText.max.right": "Pravo",
    "gui.blocklyText.max.setLineFidingCarLights": "auto LED svjetla [SWITCH]",
    "gui.blocklyText.max.on": "na",
    "gui.blocklyText.max.off": "isključeno",
    "gui.blocklyText.max.readUltrasoundSensor": "očitavanje ultrazvučnog senzora (cm)",
    "gui.blocklyText.max.readLightSensor": "očitaj [LEFTRIGHT] svjetlosni senzor",
    "gui.blocklyText.max.maxSpeedMove": "MAX pomakni [DIR] brzinom [SPEED].",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] pomakni [DIR] brzinom [SPEED].",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] se zaustavlja",
    "gui.blocklyText.max.showUserDefineExpressions": "prikaži uzorak [EXPRESSION] u [COLOR]",
    "gui.blocklyText.max.clear": "čisto",
    "gui.blocklyText.max.do": "Čini",
    "gui.blocklyText.max.re": "Ponovno",
    "gui.blocklyText.max.mi": "Mi",
    "gui.blocklyText.max.fa": "Fa",
    "gui.blocklyText.max.sou": "Tako",
    "gui.blocklyText.max.la": "La",
    "gui.blocklyText.max.xi": "Ti",
    "gui.blocklyText.max.highdo": "highdo",
    "gui.blocklyText.max.brake": "kočnica",
    "gui.blocklyText.max.rebound": "odskok",
    "gui.blocklyText.max.bullets": "meci",
    "gui.blocklyText.max.longecho": "duga jeka",
    "gui.blocklyText.max.playfulending": "završetak",
    "gui.blocklyText.max.yo": "joj",
    "gui.blocklyText.max.wo": "jao",
    "gui.blocklyText.max.robottalking": "robot koji govori",
    "gui.blocklyText.sen0160.init": "Postavi Hx711 vrijednost kalibracije senzora težine [VALUE] s Dout pinom [DOUT] SCK pinom [SCK]",
    "gui.blocklyText.sen0160.readValue": "Očitaj Hx711 senzor težine (g) Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.knowflow.readORP": "očitaj pin [PIN] ORP senzor (mV)",
    "gui.blocklyText.knowflow.readEC": "očitaj pin [PIN] EC senzor (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "očitaj pin [PIN] PH senzor (V1)",
    "gui.blocklyText.knowflow.readECV2": "očitaj pin [PIN] EC senzor (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "očitaj pin [PIN] PH senzor (V2)",
    "gui.blocklyText.knowflow.readDO": "očitaj pin [PIN] DO senzor (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "pin [PIN] Peristaltička pumpa rotira [DIR] pri [SPEED]% brzini",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] pin [PIN] relej",
    "translate.translateBlock": "prevedi [WORDS] na [LANGUAGE]",
    "translate.defaultTextToTranslate": "zdravo",
    "translate.viewerLanguage": "jezik gledatelja",
    "gui.blocklyText.goble.readAxis": "čitati GOBLE klackalica [AXIS] os",
    "gui.blocklyText.goble.buttonIsPressed": "Gumb GOBLE [BUTTON] pritisnut?",
    "gui.blocklyText.eeprom.clear": "obriši eeprom",
    "gui.blocklyText.eeprom.read": "eeprom čitanje s adrese [ADDR]",
    "gui.blocklyText.eeprom.write": "eeprom pisanje na adresu [ADDR] s podacima [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom dohvati crc s adrese [ADDRA] na adresu [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom dohvati duljinu",
    "gui.blocklyText.tds.readAnalogPin": "čitanje analognog pina [PIN] TDS senzora (ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "dva koračna motora rade istovremeno X os : [XSET] y os: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "koračni motor [AXIS] os [STEPS] koraka po okretaju Brzina (r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "pomicanje koračnog motora [ROTATEDIR] za [STEPS] koraka",
    "gui.blocklyText.steppermotor.setJointSteps": "dva koračna motora rade istovremeno. Pomakni [XSTEPS] koraka po x-osi [ROTATEDIRX] Pomakni [YSTEPS] koraka po y-osi [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "pokreni [THREAD]",
    "gui.blocklyText.multithread.stopThread": "zaustavi [THREAD]",
    "gui.blocklyText.multithread.startThread": "pokrenuti [THREAD]",
    "gui.blocklyText.multithread.loop1": "nit 1",
    "gui.blocklyText.multithread.loop2": "nit 2",
    "gui.blocklyText.multithread.loop3": "nit 3",
    "gui.blocklyText.multithread.loop4": "nit 4",
    "gui.blocklyText.multithread.loop5": "nit 5",
    "gui.blocklyText.multithread.loop6": "nit 6",
    "gui.blocklyText.multithread.loop7": "nit 7",
    "gui.blocklyText.multithread.loop8": "nit 8",
    "gui.blocklyText.multithread.all": "svi",
    "text2speech.speakAndWaitBlock": "govori [WORDS]",
    "text2speech.setVoiceBlock": "postavi glas servera1 na [VOICE]",
    "text2speech.setLanguageBlock": "postavi jezik servera1 na [LANGUAGE]",
    "text2speech.setRequestSever": "postavi server [SERVER]",
    "text2speech.setSever2Voice": "postavi glas servera2 na [VOICE]",
    "text2speech.setSever2Language": "postavi jezik servera2 na [LANGUAGE]",
    "text2speech.setSever2Account": "postavi račun poslužitelja2 [MODAL]",
    "text2speech.international": "server1 (međunarodni)",
    "text2speech.china": "server2 (Kina)",
    "text2speech.alto": "alt",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "škripati",
    "text2speech.giant": "divovski",
    "text2speech.kitten": "mače",
    "text2speech.defaultTextToSpeak": "zdravo",
    "gui.blocklyText.text2speech.server2voice0": "duxiaomei",
    "gui.blocklyText.text2speech.server2voice1": "duxiaoyu",
    "gui.blocklyText.text2speech.server2voice3": "duxiaoyao",
    "gui.blocklyText.text2speech.server2voice4": "duyaya",
    "gui.blocklyText.text2speech.server2voice106": "dubowen",
    "gui.blocklyText.text2speech.server2voice110": "duxiaotong",
    "gui.blocklyText.text2speech.server2voice111": "duxiaomeng",
    "gui.blocklyText.text2speech.server2voice103": "dumiduo",
    "gui.blocklyText.text2speech.server2voice5": "duxiaojiao",
    "gui.blocklyText.DFR0151.init": "DS1307 inicijalizacija I2C adresa 0x68",
    "gui.blocklyText.DFR0151.adjustTime": "Podesi vrijeme DS1307 na [YEAR]Godina [MONTH]mjesec [DATE]datum [HOUR]sat [MINUTE]minuta [SECOND]sekunda",
    "gui.blocklyText.DFR0151.getTime": "dohvati DS1307 vrijeme [INDEX]",
    "gui.blocklyText.DFR0151.Year": "godina",
    "gui.blocklyText.DFR0151.month": "mjesec",
    "gui.blocklyText.DFR0151.date": "datum",
    "gui.blocklyText.DFR0151.hour": "sat",
    "gui.blocklyText.DFR0151.minute": "minuta",
    "gui.blocklyText.DFR0151.second": "drugi",
    "gui.blocklyText.DFR0151.week": "tjedan",
    "gui.blocklyText.DFR0469.init": "SD2405 inicijalizacija I2C adresa 0x32",
    "gui.blocklyText.DFR0469.adjustTime": "podesi SD2405 vrijeme na [YEAR]godina [MONTH]mjesec [DATE]datum [HOUR]sat [MINUTE]minuta [SECOND]sekunda",
    "gui.blocklyText.DFR0469.getTime": "dohvati SD2405 vrijeme [INDEX]",
    "gui.blocklyText.DFR0126.setPin": "Pinovi modula audio analize #S[SPIN] #R[RPIN] Analogni pin[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "čitanje frekvencijskog pojasa zvuka[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Inicijaliziraj I2C adresu 0x24 sučelja NFC modula",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Inicijaliziraj hardverski serijski port sučelja NFC modula Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "Inicijaliziraj sučelje NFC modula [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "Inicijaliziraj sučelje NFC modula [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "Blok NFC modula [DATABLOCK] bajt [BYTES] pisanje [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "očitaj blok NFC modula [DATABLOCK] bajt (broj) [BYTES]",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "očitaj blok NFC modula [DATABLOCK] svi podaci (string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "očitaj UID-a bloka NFC modula (srting)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "otkriven UID kartice: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "otkrivena kartica?",
    "gui.blocklyText.DFR0126.serial1": "serijski1",
    "gui.blocklyText.DFR0126.serial2": "serijski2",
    "gui.blocklyText.DFR0126.serial3": "serijski3",
    "gui.blocklyText.sen0245.init": "Inicijaliziraj VL53L0X laserski senzor za domet I2C adresu 0x29",
    "gui.blocklyText.sen0245.setVL53L0X": "VL53L0X točnost[PRECISION] način mjerenja[VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "dohvati VL53L0X vrijednost laserskog dometa (mm)",
    "gui.blocklyText.SEN0245.LOW": "Nisko (1 mm)",
    "gui.blocklyText.SEN0245.HIGH": "Visoko (0,25 mm)",
    "gui.blocklyText.SEN0245.Single": "Singl",
    "gui.blocklyText.SEN0245.Continuous": "Stalan",
    "gui.blocklyText.serialMp3.initSerialMp3": "Početno sučelje serijskog MP3 modula [SERIALPORT] RX(zeleno) [RX] TX(plavo) [TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "postavi način reprodukcije serijskog MP3 modula na [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "Postavi serijski MP3 modul za reprodukciju [VALUE] pjesme",
    "gui.blocklyText.serialMp3.setMP3Volume": "Postavi glasnoću serijskog MP3 modula na [VOLUME]%",
    "gui.blocklyText.serialMp3.play": "igra",
    "gui.blocklyText.serialMp3.pause": "pauza",
    "gui.blocklyText.serialMp3.end": "kraj",
    "gui.blocklyText.serialMp3.prev": "prev",
    "gui.blocklyText.serialMp3.next": "Sljedeći",
    "gui.blocklyText.serialMp3.volumeUp": "pojačati",
    "gui.blocklyText.serialMp3.volumeDown": "stišavanje zvuka",
    "gui.blocklyText.serialMp3.playlist": "popis za reprodukciju",
    "gui.blocklyText.serialMp3.insertTracks": "umetnuti pjesme",
    "gui.blocklyText.serialMp3.volume": "volumen",
    "gui.blocklyText.servo360.setServo360": "pin [PIN] servo rotacija [DIR] brzinom [SPEED] %",
    "gui.blocklyText.servo360.CW": "CW",
    "gui.blocklyText.servo360.CCW": "CCW",
    "gui.blocklyText.TCS34725.initTcs34725": "Inicijalizira I2C adresu 0x29 senzora boje TCS34725",
    "gui.blocklyText.TCS34725.readTcs34725": "očitavanje senzora boje TCS34725 [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "Uključi GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "Ne okreći GAMMU",
    "gui.blocklyText.regEmotion8_16.showText": "prikaži tekst[TEXT] u [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "pomicanje teksta [DIR]",
    "gui.blocklyText.regEmotion8_16.left": "lijevo",
    "gui.blocklyText.regEmotion8_16.right": "pravo",
    "gui.blocklyText.regEmotion8_16.lightAll": "prikaži svu matricu točka u [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "prikaži koordinate točke (x:[XAXIS], y:[YXAXIS]) u [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "obriši zaslon",
    "gui.mainHeader.err": "!Pogreška: ista programska zaglavlja [%1] ne mogu se koristiti u isto vrijeme. Molimo izbriši duplicirani blok.",
    "gui.mainHeader.help": "!Savjet: Ako je potrebno izvršiti više programa u isto vrijeme, koristi \"multi-threading funkciju\" u \"Extension\", pogledaj dokumentaciju pomoći za detalje.",
    "gui.threadHeader.err1": "!Pogreška: Programska zaglavlja [%1] ne mogu se koristiti u isto vrijeme. Oni pripadaju različitim kompletima ili pločama.",
    "gui.threadHeader.err2": "!Error Savjet: ista zaglavlja događaja [%1] ne mogu se koristiti u isto vrijeme. Molimo izbriši duplicirani blok.",
    "gui.blocklyText.mqtt.whenReceivedData": "kada je [TOPIC] primio [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT poruka",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi povezivanje s računom:[ACCOUNT] lozinka:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi povezan?",
    "gui.blocklyText.wifi.wifiDisConnected": "odspoji Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Preuzmi Wi-Fi konfiguraciju [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP adresa",
    "gui.blocklyText.wifi.getWiFiNetmask": "Maska podmreže",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP mod:[ACCOUNT2] lozinka:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi get soft ap ip",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi dohvati [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Informacije o otklanjanju pogrešaka",
    "gui.blocklyText.wifi.whenDebugInfoError": "Kod pogreške",
    "gui.blocklyText.wifi.ping": "Wi-Fi ping [HOST]",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "ntp postavi mrežno vrijeme, vremensku zonu [TIMEZONE] timer poslužitelj [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "ntp dohvati lokalno vrijeme [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "UTCWest12_t",
    "gui.blocklyText.ntp.UTCWest11_t": "UTCWest11_t",
    "gui.blocklyText.ntp.UTCWest10_t": "UTCWest10_t",
    "gui.blocklyText.ntp.UTCWest9_t": "UTCWest9_t",
    "gui.blocklyText.ntp.UTCWest8_t": "UTCWest8_t",
    "gui.blocklyText.ntp.UTCWest7_t": "UTCWest7_t",
    "gui.blocklyText.ntp.UTCWest6_t": "UTCWest6_t",
    "gui.blocklyText.ntp.UTCWest5_t": "UTCWest5_t",
    "gui.blocklyText.ntp.UTCWest4_t": "UTCWest4_t",
    "gui.blocklyText.ntp.UTCWest3_t": "UTCWest3_t",
    "gui.blocklyText.ntp.UTCWest2_t": "UTCWest2_t",
    "gui.blocklyText.ntp.UTCWest1_t": "UTCWest1_t",
    "gui.blocklyText.ntp.UTCMiddle_t": "UTCMiddle_t",
    "gui.blocklyText.ntp.UTCEast1_t": "UTCEast1_t",
    "gui.blocklyText.ntp.UTCEast2_t": "UTCEast2_t",
    "gui.blocklyText.ntp.UTCEast3_t": "UTCEast3_t",
    "gui.blocklyText.ntp.UTCEast4_t": "UTCEast4_t",
    "gui.blocklyText.ntp.UTCEast5_t": "UTCEast5_t",
    "gui.blocklyText.ntp.UTCEast6_t": "UTCEast6_t",
    "gui.blocklyText.ntp.UTCEast7_t": "UTCEast7_t",
    "gui.blocklyText.ntp.UTCEast8_t": "UTCEast8_t",
    "gui.blocklyText.ntp.UTCEast9_t": "UTCEast9_t",
    "gui.blocklyText.ntp.UTCEast10_t": "UTCEast10_t",
    "gui.blocklyText.ntp.UTCEast11_t": "UTCEast11_t",
    "gui.blocklyText.ntp.UTCEast12_t": "UTCEast12_t",
    "gui.blocklyText.ntp.year": "godina",
    "gui.blocklyText.ntp.month": "mjesec",
    "gui.blocklyText.ntp.date": "datum",
    "gui.blocklyText.ntp.hour": "sat",
    "gui.blocklyText.ntp.minute": "minuta",
    "gui.blocklyText.ntp.second": "drugi",
    "gui.blocklyText.ntp.week": "tjedan",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "kada udp poslužitelj primi [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "postavljanje porta udp poslužitelja [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "udp poslužitelj slanje emitiranja [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "kada udp klijent primi [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "postavi udp klijent IP [IPADDR] port [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "udp klijent šalje emitiranje [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "poruka",
    "gui.blocklyText.udp.ClientRecvedMsg": "poruka",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] dodaj ključ [KEY] vrijednost [VALUE]",
    "gui.blocklyText.http.requestHeader": "Dodavanje zaglavlja HTTP zahtjeva [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] URL [URL] timeout [TIMEOUT] ms",
    "gui.blocklyText.http.readMessage": "HTTP poruka za čitanje retka [LINE].",
    "gui.blocklyText.http.param": "PARAM",
    "gui.blocklyText.http.header": "ZAGLAVLJE",
    "gui.blocklyText.http.oneline": "JEDAN",
    "gui.blocklyText.http.allline": "SVI",
    "gui.blocklyText.mqtt.mqttConnect": "spoji MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT povezan?",
    "gui.blocklyText.mqtt.acceptance.meaage": "Kada je [TOPIC] primio poruku kao [MSG]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT pošalji poruku [MSG] platformi u oblaku [TOPIC]",
    "gui.blocklyText.mqtt.initial": "MQTT početni [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「IFLYTEK」sintetički zvuk [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「IFLYTEK」prepoznati zvuk [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「IFLYTEK」prepoznati rezultat",
    "gui.blocklyText.iflytek.CommunicationText": "tekst",
    "gui.blocklyText.iflytek.CommunicationTransfer": "prijenos u audio datoteku",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "audio datoteka koju treba identificirati",
    "gui.blocklyText.mqtt.mqttDisconnect": "odspoji MQTT",
    "gui.blocklyText.internet.initRequestsType": "vrsta zahtjeva",
    "gui.blocklyText.internet.initRequestsAddr": "traži adresu",
    "gui.blocklyText.internet.initAddDict": "init dict",
    "gui.blocklyText.internet.postFileAddr": "adresa poštanske datoteke",
    "gui.blocklyText.internet.postFilePath": "staza post datoteke",
    "gui.blocklyText.internet.postFileMime": "post file mime type",
    "gui.blocklyText.internet.postFileAddDict": "init dict",
    "gui.blocklyText.internet.internetGetpostInit": "internet početno [SETTING]",
    "gui.blocklyText.internet.initTypeAddr": "vrsta skupa internetskih zahtjeva [POST] adresa [ADDR] zaglavlja:[HEADERS] parametri:[URL] podaci:[DICT]",
    "gui.blocklyText.internet.internetPostFile": "internetska post datoteka [SETTING]",
    "gui.blocklyText.internet.postFileAddrType": "POST datoteka zahtijeva adresu [ADDR] put [PATH] MIME tip [TYPE]",
    "gui.blocklyText.internet.postFileDict": "POST datoteka postavi dict podatke [DICT] i pošalji datoteku",
    "gui.blocklyText.internet.responseContent": "obrazac sadržaja odgovora [DATATYPE]",
    "gui.blocklyText.internet.responseAttributes": "atributi odgovora [ATTRIBUTES]",
    "gui.blocklyText.internet.createSocket": "kreiraj utičnicu [SOKHANDEL] s af [AFINET] i upiši [SOCKTYPE]",
    "gui.blocklyText.internet.setSocket": "kreiraj utičnicu [SOKHANDEL] s af [SOCKTYPE] i upiši [TYPENUM]",
    "gui.blocklyText.internet.closeSocket": "utičnica [SOKHANDEL] zatvoriti",
    "gui.blocklyText.internet.bindSocket": "utičnica [SOKHANDEL] povezivanje s adresom [SOCKADDR] port [SOCKPORT]",
    "gui.blocklyText.internet.listenSocket": "utičnica za slušanje [SOKHANDEL] broj zaostatka [BACKLOGNUM]",
    "gui.blocklyText.internet.recvReturnTuple": "utičnica [SOKHANDEL] prima zahtjeve za povezivanje i vraća torku utičnice",
    "gui.blocklyText.internet.parseReturnIp": "analiziraj host [HOSTADDR] port [SOCKPORT] i vrati ip",
    "gui.blocklyText.internet.connectHost": "utičnica [SOKHANDEL] povezivanje s hostom [HOSTADDR] port [SOCKPORT]",
    "gui.blocklyText.internet.sendReturnBytes": "utičnica [SOKHANDEL] pošalji [SOCKMSG] i vrati poslane bajtove",
    "gui.blocklyText.internet.continueSendReturnBytes": "utičnica [SOKHANDEL] nastaviti slati [SOCKMSG] i vratiti poslane bajtove",
    "gui.blocklyText.internet.sendToAddr": "utičnica [SOKHANDEL] pošalji [SOCKMSG] na adresu [SOCKADDR]",
    "gui.blocklyText.internet.recvReturnRecvedObject": "utičnica [SOKHANDEL] primanje i vraćanje primljenog objekta maks. podataka je [MAXNUM]",
    "gui.blocklyText.internet.recvReturnRecvedTuple": "utičnica [SOKHANDEL] primanje i vraćanje primljenih torki maksimalnih podataka je [MAXNUM]",
    "gui.blocklyText.internet.setTimeout": "utičnica [SOKHANDEL] postavi timeout [TIMEOUT]",
    "gui.blocklyText.internet.setMode": "utičnica [SOKHANDEL] način rada [SOCKMODE]",
    "gui.blocklyText.internet.readReturnObject": "utičnica [SOKHANDEL] čita [READBYTE] i vraća objekt bajtova",
    "gui.blocklyText.internet.text": "tekst",
    "gui.blocklyText.internet.binary": "binarni",
    "gui.blocklyText.internet.dictionary": "rječnik",
    "gui.blocklyText.internet.block": "blok",
    "gui.blocklyText.internet.nonblock": "neblokirati",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 inicijalizira I2C adresu 0x77",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 očitava visinu (m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 očitani tlak (Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 očitana temperatura (℃)",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 mjerenje [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "objekt",
    "gui.blocklyText.sen0206.environment": "okoliš",
    "gui.blocklyText.sen0206.celsius": "celzija",
    "gui.blocklyText.sen0206.fahrenheit": "Fahrenheit",
    "gui.blocklyText.sen0253.init": "10DOF Početna razina pina I2C_ADDR [LEVEL]",
    "gui.blocklyText.sen0253.reset": "10DOF Ponovno postavljanje senzora",
    "gui.blocklyText.sen0253.power": "10DOF Postavi način napajanja [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF Dohvati Eulerove kutove [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF Dohvati kao [AXIS] os",
    "gui.blocklyText.sen0253.getAcc": "10DOF Dohvati akcelerometar [AXIS] os (mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF Dohvati os magnetometra [AXIS] (μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF Dohvati linearno ubrzanje [AXIS] os (mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF Dohvati gravitacijski kapacitet [AXIS] os (mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF Dohvati os kutne brzine [AXIS].",
    "gui.blocklyText.sen0253.getBmp280": "10DOF Dohvati [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "Nisko",
    "gui.blocklyText.sen0253.HIGH": "Visoko",
    "gui.blocklyText.sen0253.normal": "Normalno",
    "gui.blocklyText.sen0253.lowPower": "Mala snaga",
    "gui.blocklyText.sen0253.suspend": "Obustaviti",
    "gui.blocklyText.sen0253.Roll": "Rot (°)",
    "gui.blocklyText.sen0253.Pitch": "Nagib (°)",
    "gui.blocklyText.sen0253.Yaw": "Skretanje (°)",
    "gui.blocklyText.sen0253.temperature": "Temperatura",
    "gui.blocklyText.sen0253.elevation": "Nadmorska visina",
    "gui.blocklyText.sen0253.airPressure": "Tlak zraka",
    "gui.blocklyText.sen0290.init": "senzor osvjetljenja set irq pin [IRQPIN] i i2c adresa [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "senzor osvjetljenja senzor munje iic init ok？",
    "gui.blocklyText.sen0290.manualCal": "senzor osvjetljenja set kapacitivnost [CAPACITANCE] mod [MODE] dist [DIST]",
    "gui.blocklyText.sen0290.indoors": "u zatvorenom prostoru",
    "gui.blocklyText.sen0290.outdoors": "na otvorenom",
    "gui.blocklyText.sen0290.disturberDis": "disturber onemogućiti",
    "gui.blocklyText.sen0290.disturberEn": "disturber omogućiti",
    "gui.blocklyText.sen0290.errMsg": "poruka o pogrešci",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "senzor osvjetljenja dohvati status",
    "gui.blocklyText.sen0290.GetLightningDistKm": "senzor osvjetljenja dohvati udaljenost munje (Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "senzor osvjetljenja dohvati sirovu energiju udara",
    "gui.blocklyText.sen0291.sen0291Init": "Mjerač snage inicijalizira I2C adresu kao [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "Mjerač snage očitava opterećenje [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "Stvarna izmjerena struja kalibracije mjerača snage je [EXT] mA",
    "gui.blocklyText.pythonsen0291.sen0291Init": "Mjerač snage [NAME] inicijalizira I2C adresu kao [IICADDR]",
    "gui.blocklyText.pythonsen0291.sen0291Read": "Mjerač snage [NAME] očitava opterećenje [LOAD]",
    "gui.blocklyText.pythonsen0291.sen0291Calibration": "Mjerač snage [NAME] kalibrira stvarnu izmjerenu struju na [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "Napon (mV)",
    "gui.blocklyText.sen0291.electric": "Električni (mA)",
    "gui.blocklyText.sen0291.power": "Snaga (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "Shunt napon (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.weather.weatherGetWithCity": "[WEATHER] u [CITY] gradu [COUNTRY] pokrajini",
    "gui.blocklyText.weather.temperaturHigh": "maksimalna temperatura (℃)",
    "gui.blocklyText.weather.temperaturLow": "minimalna temperatura (℃)",
    "gui.blocklyText.weather.weather": "vrijeme",
    "gui.blocklyText.weather.weatherInit": "poslužitelj vremenske prognoze inicijaliziraj [SETTING]",
    "gui.blocklyText.weather.serverAddr": "adresa poslužitelja",
    "gui.blocklyText.weather.appid": "appid",
    "gui.blocklyText.weather.appsecret": "appsecret",
    "gui.blocklyText.weather.serverAddrTianqiAPI": "tianqi API (www.tianqiapi.com)",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "postavi parametar poslužitelja [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "ažuriranje oznake [TAG] vrijednost [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "očitaj oznaku [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "izbriši oznaku [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "broji oznaku",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "test mrežne veze",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "oznaka za pretraživanje od početnog broja [NUMS] broj varijabli [NUMV] oznaka [TAG] vrsta [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "označiti",
    "gui.blocklyText.tinywebdb.value": "vrijednost",
    "gui.blocklyText.tinywebdb.both": "oba",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Peking",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Šangaj",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Tajvan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "Hong Kong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.whenIHear": "kada se čuje [PHRASE]",
    "speech.listenAndWait": "slušaj i čekaj",
    "speech.getSpeech": "rezultat prepoznavanja",
    "speech.setRecordTime": "postavi vrijeme snimanja na [TIME]",
    "speech.stopRecord": "zaustavi prepoznavanje govora",
    "speech.controlSonogram": "[CONTROL] Sonogram",
    "speech.setServer": "prebaci poslužitelj za prepoznavanje govora na [SERVER]",
    "speech.defaultWhenIHearValue": "zdravo",
    "speech.show": "pokaži",
    "speech.hide": "sakrij",
    "speech.server1": "poslužitelj1",
    "speech.server2": "poslužitelj2",
    "gui.blocklyText.mp3.initMp3": "Pokreni sučelja MP3 modula DFPlayer [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "postavi glasnoću DFPlayer MP3 modula na [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "postavi način reprodukcije DFPlayer MP3 modula na [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "postavi DFPlayer MP3 modul za reprodukciju [NUM] pjesama",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "postavi pin [PIN] DFPlayer MP3 modul glasnoće na [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "postavi pin [PIN] DFPlayer MP3 modula mod reprodukcije na [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "prikvači [PIN] DFPlayer MP3 modul reproduciraj [NUM] pjesmu",
    "gui.blocklyText.tel0094.GPSInit": "GPS se inicijalizira kao [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS dobiva lokaciju [LATLON](°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "GPS dobiva lokaciju [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "Zemljopisna dužina",
    "gui.blocklyText.tel0094.LONGITUDE": "Zemljopisna širina",
    "gui.blocklyText.tel0094.YEAR": "Godina",
    "gui.blocklyText.tel0094.MONTH": "Mjesec",
    "gui.blocklyText.tel0094.DAY": "Dan",
    "gui.blocklyText.tel0094.HOUR": "Sat",
    "gui.blocklyText.tel0094.MINUTE": "Minuta",
    "gui.blocklyText.tel0094.SECOND": "Drugi",
    "gui.blocklyText.tel0094.ALL": "svi",
    "gui.blocklyText.tel0094.GPSGet": "GPS dobiva [GPS]",
    "gui.blocklyText.tel0094.getState": "Status",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "Hemisfera geografske širine",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "Hemisfera zemljopisne dužine",
    "gui.blocklyText.tel0094.getGroundSpeed": "Brzina kretanja na tlu",
    "gui.blocklyText.tel0094.getSatellitesCount": "Broj satelita",
    "gui.blocklyText.tel0094.getAltitude": "Visina",
    "gui.blocklyText.tel0094.getPdop": "Točnost položaja",
    "gui.blocklyText.tel0094.getHdop": "Horizontalna točnost",
    "gui.blocklyText.tel0094.getVdop": "Vertikalna točnost",
    "gui.blocklyText.tel0094.getPositioningMode": "Način pozicioniranja",
    "gui.blocklyText.microIoT.displayInLine": "OLED prikazuje liniju [LINE] tekst [TEXT]",
    "gui.blocklyText.microIoT.displayInXY": "OLED prikaz [TEXT] x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED prikaz slike [IMAGE] x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED jasan",
    "gui.blocklyText.microIoT.pointDrawing": "OLED točka crtanja x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED postavi širinu crte [LINEWIDTH]",
    "gui.blocklyText.microIoT.lineDrawing": "OLED linija crtanja početak x [X1] y [Y1] kraj x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED crtanje kruga [FILL] centar kruga x [X] y [Y] polumjer [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED okvir za crtanje [FILL] početak x [X] y [Y] širina [WIDTH] visina [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB postavi osvjetljenje na [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "RGB svjetla su sva isključena",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "RGB svjetla [NUM1] do [NUM2] pokazuju boje [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "RGB svjetla [NUM1] do [NUM2] prikazuju nijansu gradijenata od [COLOR1] do [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "RGB petlja za [NUM] piksela",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "RGB svjetla [NUM1] do [NUM2] pokazuju boje [COLOR]",
    "gui.blocklyText.motorIot.all": "SVI",
    "gui.blocklyText.microIot.servo": "Servo [INDEX] kut [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "MQTT početni parametar [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT pošalji poruku [MSG] platformi u oblaku [TOPIC]",
    "gui.blocklyText.microIot.MQTTMsg": "MQTT poruka",
    "gui.blocklyText.microIoTCloud.MotorRun": "motor se okreće [DIR] brzinom [SPEED]",
    "gui.blocklyText.microIoTCloud.MotorStop": "zaustavljanje motora",
    "gui.blocklyText.microIoTCloud.setTime": "postavljena godina [Y] mjesec [MON] dan [D] sat [H] minuta [MIN] sekunda [S]",
    "gui.blocklyText.microIoTCloud.setYear": "postavljena godina [Y]",
    "gui.blocklyText.microIoTCloud.setMonth": "postavi mjesec [MON]",
    "gui.blocklyText.microIoTCloud.setDay": "postaviti dan [D]",
    "gui.blocklyText.microIoTCloud.setHour": "postaviti sat [H]",
    "gui.blocklyText.microIoTCloud.setMinute": "postavljena minuta [MIN]",
    "gui.blocklyText.microIoTCloud.setSecond": "postavi sekundu [S]",
    "gui.blocklyText.microIoTCloud.getTime": "dohvati [TIME]",
    "gui.blocklyText.microTouch.whenBtnPress": "kada pritisneš tipke [KEY].",
    "gui.blocklyText.microTouch.Any": "Bilo koje",
    "gui.blocklyText.microTouch.isButtonPressed": "Tipke [KEY] su pritisnute?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "Tipke za čitanje normalnog načina rada",
    "gui.blocklyText.microTouch.readKeyMathMode": "Matematički način čitanja [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "Brojčana tipka (num)",
    "gui.blocklyText.microTouch.Function": "Funkcijska tipka (str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] vibracijski motor",
    "gui.blocklyText.microTouch.turnOn": "Uključi",
    "gui.blocklyText.microTouch.shutDown": "Isključi",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "Intenzitet zvuka",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "Prirodno svjetlo",
    "gui.blocklyText.microNaturalScience.readColour": "Očitaj [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "Crvena vrijednost",
    "gui.blocklyText.microNaturalScience.G": "Zelena vrijednost",
    "gui.blocklyText.microNaturalScience.B": "Plava vrijednost",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "UV intenzitet (μW/cm²)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "Uključiti",
    "gui.blocklyText.microNaturalScience.off": "Isključiti",
    "gui.blocklyText.microNaturalScience.setTDSK": "Postavi konstantu TDS elektrode k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "Odredi TDS konstantu elektrode k",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS (ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "Dohvati [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "Temperaturu vode (°C)",
    "gui.blocklyText.microNaturalScience.Temperature": "Temperaturu (℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "Vlažnost (%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "Nadmorsku visinu (m)",
    "gui.blocklyText.microNaturalScience.Pressure": "Tlak zraka (Pa)",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB Postavi točku matrice X [X] Y [Y] prikaži boje [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 se ne može staviti u događaj prekida, može uzrokovati abnormalno očitavanje dht11",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 se ne može staviti u događaj prekida, može uzrokovati abnormalno očitavanje dht22",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 se ne može staviti u događaj prekida, što može rezultirati abnormalnim očitanjima pm2.5",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 čita podatke, što može uzrokovati neuspjeh inicijalizacije LED dot matrixa",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 čita podatke, što može uzrokovati neuspjeh LED matričnog prikaza",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 inicijalizacija ne može se smjestiti u događaj prekida, što može uzrokovati neuspjeh inicijalizacije",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "glasnoća postavke mp3 ne može se smjestiti u događaj prekida, što može uzrokovati neuspjeh postavljanja glasnoće",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "način reprodukcije s postavkom mp3 ne može se postaviti u događaj prekida, što može uzrokovati neuspjeh načina reprodukcije",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "Reprodukcija MP3 postavki ne može se smjestiti u događaj prekida, može uzrokovati neuspješnu reprodukciju postavki",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "Inicijalizacija mp3 serijskog priključka ne može se smjestiti u događaj prekida, što može uzrokovati neuspjeh inicijalizacije",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "Način reprodukcije mp3 serijskog porta ne može se postaviti u događaj prekida, što može uzrokovati gubitak podešenog načina reprodukcije",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "Reprodukcija mp3 sa serijskog priključka ne može se staviti u događaj prekida, što može uzrokovati neuspjeh reprodukcije",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "Glasnoća postavke mp3 serijskog priključka ne može se postaviti u događaj prekida, što može uzrokovati neuspjeh glasnoće",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloqov mqtt inicijalizacijski meki serijski port ne može se postaviti u događaj prekida, što može uzrokovati neuspjeh komunikacije mekog serijskog porta",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq šalje poruku koja se ne može smjestiti u događaj prekida, što može uzrokovati neuspjeh prijenosa",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq read mqtt poruka ne može se smjestiti u događaj prekida, može uzrokovati neuspjeh prijema",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "Početni meki serijski port http od obloq ne može se postaviti u događaj prekida, što može uzrokovati neuspjeh komunikacije mekog serijskog porta",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "Get zahtjev za obloq http ne može se smjestiti u događaj prekida, što može uzrokovati neuspjeh zahtjeva",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "Obloq http post zahtjev ne može se staviti u događaj prekida, što može uzrokovati neuspjeh zahtjeva",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "Put zahtjev za obloq http ne može se smjestiti u događaj prekida, što može uzrokovati neuspjeh zahtjeva",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "Infracrveni prijem ne može se staviti u događaj prekida, što može uzrokovati neuspjeh prijema",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "Infracrveni prijem može uzrokovati neuspjeh inicijalizacije LED matrice",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "Infracrveni prijem može uzrokovati neuspjeh prikaza LED matričnog uzorka",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "Infracrveni prijenos ne može se staviti u događaj prekida, što može uzrokovati neuspjeh prijenosa",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB trake ne mogu se postaviti u događaj prekida i mogu uzrokovati neuspjeh inicijalizacije",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB trake ne mogu se postaviti u događaj prekida i mogu uzrokovati neispravnost pinova za postavljanje",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB trake ne mogu se postaviti u događaj prekida, što može uzrokovati kvar pina",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB trake ne mogu se postaviti u događaj prekida i mogu uzrokovati neispravnu boju prikaza",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB trake ne mogu se postaviti u događaj prekida i mogu uzrokovati neuspjeh podešavanja boje",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB trake ne mogu se postaviti u događaj prekida, što može uzrokovati neuspjeh premještanja",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB trake ne mogu se postaviti u događaj prekida i mogu uzrokovati neuspjeh kretanja petlje",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB trake ne mogu se postaviti u događaj prekida i mogu uzrokovati neispravan prikaz histograma",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "Led točkasta matrica ne može se postaviti u događaj prekida, može uzrokovati neuspjeh inicijalizacije",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "LED točkasta matrica ne može se postaviti u događaj prekida, što može uzrokovati neuspjeh uzorka prikaza",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "Meki serijski izlaz ne može se postaviti u događaj prekida, što može uzrokovati nenormalan izlaz",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "Podaci koje očitava soft serijski port ne mogu se smjestiti u događaj prekida, što može uzrokovati da sadržaj očitanja bude abnormalan",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Izlazni podaci mekog serijskog porta i čitanje podataka koje koriste kolege mogu uzrokovati abnormalnosti podataka",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "Cijeli broj za čitanje mekog serijskog porta ne može se smjestiti u događaj prekida, što može uzrokovati da sadržaj čitanja bude abnormalan",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "Izlazni podaci mekog serijskog porta i čitanje cijelih brojeva kod kolege mogu uzrokovati abnormalno očitavanje podataka",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "Kada su dht11 i PM2.5 u dvije niti, čitanja dht11 možda neće uspjeti ako pm2.5 čita prečesto. Preporuča se smanjiti učestalost očitavanja pm2.5",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "Kada su dht11 i ds18b20 u dvije niti, ako ds18b20 čita prečesto, dht11 možda neće uspjeti čitati, preporučuje se smanjiti učestalost čitanja ds18b20",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "Kada se dht11 i mp3 inicijaliziraju u dvije niti, mp3 inicijalizacija može uzrokovati pogreške čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "Kada su dht11 i mp3 set volume u dvije niti, mp3 set volume može uzrokovati neispravno čitanje dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "Kada su načini postavljanja dht11 i mp3 u dvije niti, način postavljanja mp3 može uzrokovati neispravno čitanje dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "Kada su dht11 i mp3 player u dvije niti, mp3 player može uzrokovati neispravno čitanje dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "Kada se dht11 i serijski mp3 inicijaliziraju u dvije niti, inicijalizacija serijskog mp3 može uzrokovati pogreške čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "Kada su dht11 i serijski mp3 kontrolni modul u dvije niti, mp3 kontrolni modul može uzrokovati pogreške čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "Kada su dht11 i serijski mp3 player u dvije niti, serijski mp3 player može uzrokovati neispravno čitanje dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "Kada su dht11 i serijska mp3 postavljena glasnoća u dvije niti, serijska mp3 postavljena glasnoća može uzrokovati neispravno čitanje dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "Kada se dht11 i RGB inicijaliziraju u dvije niti, RGB inicijalizacija može uzrokovati pogreške čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "Kada dht11 i RGB postave svjetlinu u dvije niti, RGB postavljena svjetlina može uzrokovati neispravno čitanje dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "Kada se dht11 i RGB ugase u dvije niti, nestanak RGB-a može uzrokovati pogrešku čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "Kada dht11 i RGB prikazuju boje u dvije niti, boje RGB prikaza mogu uzrokovati pogreške čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "Kada dht11 i RGB prikazuju gradijent u dvije niti, RGB prikaz gradijenta može uzrokovati pogrešku čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "Kada dht11 i RGB pomiču piksele u dvije niti, pomicanje RGB piksela može uzrokovati pogreške čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "Kada dht11 i RGB petlja pomiču piksele u dvije niti, redom, pikseli za pomicanje RGB petlje mogu uzrokovati pogreške čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "Kada dht11 i RGB prikazuju stupce u dvije niti, RGB trake prikaza mogu uzrokovati pogreške čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "Kada su dht11 i meko serijsko slanje u dvije niti, meko serijsko slanje može uzrokovati pogreške čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "Kada se dht11 i obloq MQTT inicijaliziraju u dvije niti, inicijalizacija obloq MQTT može uzrokovati pogreške čitanja dht11. Preporuča se pričekati završetak inicijalizacije prije čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "Kada dht11 i obloq MQTT šalju poruke u dvije niti, obloq MQTT šalje poruke koje mogu uzrokovati pogreške čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "Kada su dht11 i obloq HTTP put zahtjevi u dvije niti, to može uzrokovati pogrešku čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "Kada su dht11 i obloq HTTP inicijalizacija u dvije niti, to može uzrokovati pogrešku čitanja dht11. Preporuča se pričekati završetak inicijalizacije prije čitanja dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "Kada su dht11 i obloq HTTP zahtjevi za dobivanje u dvije niti, to može uzrokovati neispravno čitanje dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "Kada su dht11 i obloq HTTP post zahtjevi u dvije niti, to može uzrokovati neispravno čitanje dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "Kada su dht11 i infracrveno zračenje u dvije niti, to može uzrokovati pogreške u očitavanju dht11. Predlaže se smanjenje frekvencije infracrvenog zračenja",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "Kada su ds18b20 i PM2.5 u dvije niti, to može uzrokovati pogrešku čitanja PM2.5, pa se predlaže smanjenje učestalosti ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "Kada se ds18b20 i obloq MQTT inicijaliziraju u dvije niti, MQTT inicijalizacija možda neće uspjeti. Preporuča se pričekati završetak inicijalizacije prije čitanja ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "Kada su ds18b20 i obloq HQTT put zahtjevi u dvije niti, put zahtjev možda neće uspjeti. Preporuča se smanjiti učestalost čitanja ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "Kada su ds18b20 i obloq HTTP inicijalizacija u dvije niti, HTTP inicijalizacija možda neće uspjeti. Preporuča se pričekati završetak inicijalizacije prije čitanja ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "Kada su zahtjevi za dobivanje ds18b20 i obloq HQTT u dvije niti, zahtjev za dobivanje možda neće uspjeti. Preporuča se smanjiti učestalost čitanja ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "Kada su zahtjevi za objavu ds18b20 i obloq HQTT u dvije niti, zahtjev za objavu možda neće uspjeti. Preporuča se smanjiti učestalost čitanja ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Očitavanje ds18b20 može rezultirati izostankom infracrvenih podataka, stoga se preporučuje smanjiti učestalost očitavanja ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Čitanje ds18b20 može uzrokovati pogreške u primanju podataka putem softverskog serijskog porta, stoga se preporučuje smanjiti učestalost čitanja ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "Kada se PM2.5 čitanje i obloq MQTT inicijalizacija provode u dvije niti, može doći do greške MQTT inicijalizacije ili pogreške čitanja PM2.5. Preporuča se izbjegavati istovremeno izvršavanje MQTT inicijalizacije i PM2.5 čitanja",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "Kada PM2.5 čitanje i obloq MQTT šalju poruke u dvije niti, mogu nastati pogreške čitanja PM2.5. Preporuča se smanjiti učestalost očitanja PM2,5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "Kada su zahtjevi za čitanje PM2.5 i obloq HTTP put u dvije niti, to može uzrokovati neuspjeh zahtjeva za postavljanje ili pogrešku čitanja PM2.5. Preporuča se smanjiti učestalost postavljanja zahtjeva ili učestalost očitavanja PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "Kada se PM2.5 čitanje i obloq HTTP inicijalizacija izvode u dvije niti, HTTP inicijalizacija može biti neuspješna ili PM2.5 čitanje može poći po zlu. Preporuča se izbjegavati istovremeno izvršavanje HTTP inicijalizacija i PM2.5 čitanja",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "Kada su PM2.5 zahtjevi za čitanje i obloq HTTP get zahtjevi u dvije niti, to može uzrokovati neuspjeh zahtjeva za dobivanje ili pogrešku čitanja PM2.5. Preporuča se smanjiti učestalost zahtjeva za dobivanje ili učestalost čitanja PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "Kada su PM2.5 read i obloq HTTP post zahtjev u dvije niti, to može uzrokovati neuspjeh postavljanja zahtjeva ili PM2.5 grešku čitanja. Preporuča se smanjiti učestalost postavljanja zahtjeva ili učestalost čitanja PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "Kada se čitanje PM2.5 i inicijalizacija MP3 izvode u dvije niti, očitavanje PM2.5 možda neće uspjeti. Preporuča se izbjegavati istovremeno izvršavanje čitanja PM2.5 i MP3 inicijalizacije",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "Kad su očitavanje PM2.5 i glasnoća postavke MP3 u dvije niti, očitavanje PM2.5 možda neće uspjeti. Preporuča se ne prilagođavati glasnoću prečesto",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "Kada se PM2.5 čitanje i MP3 reprodukcija odvijaju u dvije niti, očitavanje PM2.5 možda neće uspjeti. Preporučljivo je ne igrati uređaj prečesto",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "Kada se PM2.5 čitanje i MP3 reprodukcija odvijaju u dvije niti, očitavanje PM2.5 možda neće uspjeti. Preporučljivo je ne mijenjati pjesme prečesto",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "Kada su PM2.5 čitanje i serijski MP3 player u dvije niti, očitavanje PM2.5 možda neće uspjeti. Preporučljivo je ne igrati uređaj prečesto",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "Kada se PM2.5 čitanje i serijska MP3 reprodukcija odvijaju u dvije niti, čitanje PM2.5 možda neće uspjeti. Preporučljivo je ne mijenjati pjesme prečesto",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "Kada su očitavanje PM2.5 i serijska postavka MP3 glasnoće u dvije niti, očitavanje PM2.5 možda neće uspjeti. Preporuča se ne prilagođavati glasnoću prečesto",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "Kada očitavanje PM2.5 može uzrokovati neuspjeh infracrvenog prijema, preporučuje se smanjiti učestalost očitavanja PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "Kada se očitavanje PM2.5 i inicijalizacija RGB-a izvode u dvije niti, mogu nastati pogreške u očitavanju PM2.5. Preporuča se izbjegavati istovremeno izvršavanje očitavanja PM2.5 i RGB inicijalizacije",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "Kada su očitavanje PM2.5 i RGB postavka svjetline u dvije niti, može doći do pogreške u očitavanju PM2.5. Preporuča se ne prilagođavati svjetlinu prečesto",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "Kada su očitavanje PM2.5 i RGB gašenje u dvije niti, mogu nastati pogreške u očitavanju PM2.5. Preporuča se ne gasiti RGB prečesto",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "Kada su očitavanje PM2.5 i RGB boje prikaza u dvije niti, mogu nastati pogreške u očitavanju PM2.5. Preporuča se ne mijenjati boje prečesto",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "Kada očitavanje PM2.5 i RGB prikazuju gradijent u dvije niti, može doći do pogreške očitanja PM2.5, preporučuje se ne mijenjati gradijent prečesto",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "Kada su očitavanje PM2.5 i RGB pokretni pikseli u dvije niti, mogu nastati pogreške u očitavanju PM2.5. Preporuča se ne pomicati piksele prečesto",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "Korištenje Wi-Fi-ja može uzrokovati da ugrađeni rgb prikazuje neuobičajenu boju",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "Udp koristi Wi-Fi, može uzrokovati anomaliju boja rgb zaslona na ploči",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "Korištenje Wi-Fi-ja može dovesti do toga da ugrađeni rgb postavi iznimku od tri boje",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "Udp koristi Wi-Fi, može uzrokovati da ugrađeni rgb postavi iznimku od tri boje",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "Korištenje Wi-Fi-ja može dovesti do toga da ugrađeni rgb postavi abnormalnu svjetlinu",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "Udp koristi Wi-Fi, može uzrokovati da ugrađeni rgb nenormalno postavi svjetlinu",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "Korištenje Wi-Fi-ja može uzrokovati abnormalnu svjetlinu ugrađenog rgb-a",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "Udp koristi Wi-Fi, što može uzrokovati abnormalnu svjetlinu ugrađenog rgb-a",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Korištenje Wi-Fi-ja može uzrokovati nenormalno pokretanje serijskog priključka mp3",
    "gui.blocklyText.prompt.telloesp32.emissionPinAndBit_telloWIFI": "Korištenje wifi-a može uzrokovati abnormalnost u modulu infracrvenog odašiljača",
    "gui.blocklyText.prompt.telloesp32.rgbnomal_rgbeffects": "Pri korištenju programa za blokiranje specijalnih efekata svjetla za disanje, LED svjetlo se više ne može zasebno postaviti, ukloni jedan od blokova.",
    "gui.blocklyText.prompt.telloesp32.matrixnomal_matrixeffects": "Posebni efekti implementirani su za pozadinske niti koje su u sukobu s izravnom postavkom matrice.",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp koristi Wi-Fi, što može uzrokovati abnormalnu inicijalizaciju mp3 serijskog priključka",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "Korištenje Wi-Fi-ja može uzrokovati neuobičajenu reprodukciju mp3 na serijskom priključku",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp koristi Wi-Fi, što može uzrokovati nenormalnu reprodukciju mp3 na serijskom priključku",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "Korištenje Wi-Fi-ja može uzrokovati neuspješnu reprodukciju pjesme na serijskom priključku mp3",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp koristi Wi-Fi, može uzrokovati neuspješnu reprodukciju pjesama putem serijskog priključka mp3",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "Korištenje Wi-Fi-ja može uzrokovati neuspješno postavljanje glasnoće mp3 serijskog priključka",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp koristi Wi-Fi, može uzrokovati neuspješno postavljanje glasnoće mp3 serijskog priključka",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "Korištenje Wi-Fi-ja može dovesti do postavljanja neuobičajene brzine upravljača od 360 stupnjeva",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp koristi Wi-Fi, što može dovesti do postavljanja nenormalne brzine 360 ​​upravljača",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "Kada PM2.5 čitanje i RGB petlja pomiču piksele u dvije niti, mogu nastati pogreške čitanja PM2.5. Preporuča se ne izvršavati prečesto pomicanje piksela petlje",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "Kada su stupci očitavanja PM2.5 i RGB prikaza u dvije niti, mogu nastati pogreške u očitavanju PM2.5. Preporuča se ne izvršavati prečesto RGB stupce prikaza",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "Kada se očitavanje PM2.5 i inicijalizacija niza led točaka provode u dvije niti, inicijalizacija niza led točaka može poći po zlu. Preporuča se izbjegavati istovremeno izvršavanje očitavanja PM2.5 i inicijalizacije niza led točaka",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "Kada su očitavanje PM2.5 i prikaz niza led točaka u dvije niti, prikaz niza led točaka može biti pogrešan, preporučuje se smanjiti učestalost očitavanja PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "Očitavanje PM2.5 može uzrokovati pogreške u očitavanju PM2.5. Preporuča se ne slati podatke prečesto",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "Očitavanje PM2.5 može uzrokovati neuspjeh očitavanja mekog serijskog porta. Preporuča se ne očitavati PM2.5 prečesto",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 možda neće primati infracrvene podatke, preporuča se smanjiti učestalost korištenja MP3",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "Kada je MP3 i obloq MQTT inicijalizacija u dvije niti, to može uzrokovati neuspjeh MQTT inicijalizacije. Preporuča se izbjegavati istodobnu MQTT inicijalizaciju i MP3 inicijalizaciju",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "Kada su MP3 i obloq HTTP put zahtjevi u dvije niti, moguće je uzrokovati neuspjeh postavljanja zahtjeva. Preporuča se smanjiti učestalost postavljanja zahtjeva ili smanjiti učestalost korištenja MP3-a",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "Kada su MP3 i obloq HTTP inicijalizacije u dvije niti, HTTP inicijalizacije možda neće uspjeti. Preporuča se izbjegavati HTTP inicijalizaciju i MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "Kada su MP3 i obloq HTTP zahtjevi za dobivanje u dvije niti, to može uzrokovati neuspjeh zahtjeva za dobivanje. Preporuča se smanjiti učestalost zahtjeva za dobivanje ili smanjiti učestalost korištenja MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "Kada su MP3 i obloq HTTP zahtjevi za objavu u dvije niti, to može uzrokovati neuspjeh zahtjeva za objavu. Preporuča se smanjiti učestalost zahtjeva za objavljivanjem ili smanjiti učestalost korištenja MP3-a",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 može uzrokovati neuspjeh čitanja mekog serijskog porta, preporuča se ne koristiti MP3 prečesto",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "Serijski MP3 možda neće primati infracrvene podatke, preporučuje se smanjiti frekvenciju serijskog MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "Kada su inicijalizacija serijskog porta MP3 i obloq MQTT u dvije niti, MQTT inicijalizacija možda neće uspjeti. Preporuča se izbjegavati istovremeno izvršavanje MQTT inicijalizacije i MP3 serijskog priključka",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "Kada su serijski MP3 i obloq HTTP put zahtjevi u dvije niti, to može uzrokovati neuspjeh postavljanja zahtjeva. Preporuča se smanjiti učestalost postavljanja zahtjeva ili smanjiti učestalost serijskih MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "HTTP inicijalizacija možda neće uspjeti kada se serijski MP3 i obloq inicijaliziraju u dvije niti. Preporuča se izbjegavati HTTP inicijalizaciju i serijski MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "Kada su serijski port MP3 i obloq HTTP zahtjevi za dobivanje u dvije niti, to može uzrokovati neuspjeh zahtjeva za dobivanje. Preporuča se smanjiti učestalost zahtjeva za dobivanje ili smanjiti učestalost MP3 serijskog priključka",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "Kada su serijski port MP3 i obloq HTTP zahtjevi za objavu u dvije niti, to može uzrokovati neuspjeh zahtjeva za objavu. Preporuča se smanjiti učestalost post zahtjeva ili smanjiti učestalost MP3 serijskog priključka",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "Serijski priključak MP3 može uzrokovati neuspješno čitanje serijskog priključka, preporuča se ne koristiti serijski priključak MP3 prečesto",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "Infracrveno zračenje može dovesti do pogrešaka prijema mekog serijskog obloqa, preporuča se smanjiti frekvenciju infracrvenog zračenja",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "Soft serijski obloq može uzrokovati pogreške u infracrvenom primanju podataka, stoga se preporučuje izbjegavanje čestog slanja poruka putem obloq-a",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "Inicijalizacija RGB pojasa može dovesti do pogreške prilikom primanja obloq poruka. Preporuča se izbjegavati čestu upotrebu RGB pojasa",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Postavljanje svjetline svjetlosnog pojasa može uzrokovati pogrešku u obloq primanju poruka. Preporuča se izbjegavati često podešavanje svjetline",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "Gašenje RGB remena može dovesti do pogreške u primanju poruka mekog serijskog obloqa, preporučuje se izbjegavanje čestog gašenja remena",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "Prikaz RGB svjetlosnog pojasa može uzrokovati pogrešku obloq primanja poruka, preporučuje se izbjegavanje čestog prikaza boja",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "Prikaz gradijenta na RGB vrpci može uzrokovati pogreške u obloq primanju poruka. Preporuča se izbjegavati često prikazivanje gradijenta",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "Pokretni pikseli u RGB pojasu mogu uzrokovati neispravno primanje poruka soft serial obloq-a",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "RGB traka okreće piksele i može uzrokovati da soft serial obloq prima poruke",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "Traka prikaza RGB vrpce može uzrokovati pogrešku primanja poruke soft serial obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "Kada se soft serial obloq i inicijalizacija niza led točaka provode u dvije niti, to može uzrokovati pogrešku inicijalizacije niza led točaka. Preporuča se izbjegavati istovremeno izvršavanje mekog serijskog obloqa i inicijalizacije niza led točaka",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "Kada su soft serial obloq i LED dot array prikaz u dvije niti, to može uzrokovati pogrešku prikaza led dot array prikaza",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "Slanje mekog serijskog porta može uzrokovati pogrešku čitanja obloqa, preporučuje se ne slati podatke prečesto",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "Soft serial port obloq slanje može uzrokovati grešku čitanja soft serial porta, preporučuje se ne slati podatke prečesto",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "Infracrveno zračenje može uzrokovati infracrveni prijem bez podataka, preporučuje se smanjenje frekvencije infracrvenog zračenja",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "Kada infracrveno zračenje može uzrokovati neuspjeh očitavanja mekog serijskog porta, preporuča se smanjiti frekvenciju infracrvenog zračenja",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "Inicijalizacija RGB pojasa može rezultirati izostankom infracrvenog prijema podataka",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "Postavka svjetline RGB svjetlosnog pojasa može uzrokovati nemogućnost prijema infracrvenih podataka, stoga se preporučuje izbjegavanje čestih postavki svjetline RGB svjetlosnog pojasa",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "Pojas RGB lampe je isključen, što može uzrokovati nemogućnost primanja infracrvenih podataka",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "Boja prikaza RGB svjetlosnog pojasa može uzrokovati nemogućnost prijema infracrvenih podataka, pa se preporučuje izbjegavanje čestih prikaza boja RGB svjetlosnog pojasa",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "Boja prikaza RGB svjetlosnog pojasa može uzrokovati nemogućnost prijema infracrvenih podataka, pa se preporučuje izbjegavanje čestih prikaza boja RGB svjetlosnog pojasa",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "Pokretni piksel RGB svjetlosnog pojasa može uzrokovati nemogućnost prijema infracrvenih podataka, stoga se preporučuje izbjegavanje čestih pomicanja piksela RGB svjetlosnog pojasa",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "Boja prikaza RGB svjetlosnog pojasa može uzrokovati nemogućnost prijema infracrvenih podataka, pa se preporučuje izbjegavanje čestih prikaza boja RGB svjetlosnog pojasa",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "Boja prikaza RGB svjetlosnog pojasa može uzrokovati nemogućnost prijema infracrvenih podataka, pa se preporučuje izbjegavanje čestih prikaza boja RGB svjetlosnog pojasa",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "Infracrveni prijem može uzrokovati neuspjeh inicijalizacije LED dioda",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "Infracrveni prijem može uzrokovati pogreške LED zaslona",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "Slanje mekog serijskog porta može uzrokovati nemogućnost primanja infracrvenih podataka, preporučuje se izbjegavanje čestog slanja mekog serijskog porta",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB pojas može uzrokovati neuspjeh očitavanja mekog serijskog porta, stoga se preporučuje smanjiti učestalost korištenja RGB pojasa",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Podaci primljeni putem softverskog serijskog priključka mogu uzrokovati pogrešku LED matričnog prikaza",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "Slanje softverskog serijskog priključka može dovesti do pogreške u primanju podataka softverskog serijskog priključka. Preporuča se izbjegavati izvršavanje slanja podataka i primanja podataka u isto vrijeme",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "Inicijalizacija serijskog porta MP3 može dovesti do pogrešaka u infracrvenom primanju podataka, stoga se preporučuje izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "Postavka načina reprodukcije MP3 serijskog priključka može uzrokovati pogreške u infracrvenom prijemu podataka, stoga se preporučuje izbjegavanje prečestog postavljanja načina reprodukcije",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "Serijski MP3 player može dovesti do infracrvenih pogrešaka podataka, preporuča se izbjegavati prečesto mijenjanje pjesama",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "Postavka glasnoće serijskog priključka MP3 može uzrokovati pogreške u infracrvenom primanju podataka. Preporuča se izbjegavati prečesto podešavanje glasnoće",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "Inicijalizacija max7219 može uzrokovati pogreške u infracrvenim primljenim podacima, stoga se preporučuje izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "Prikazane slike Max7219 mogu uzrokovati pogreške u primljenim infracrvenim podacima, stoga se preporučuje izbjegavanje prečestog prikazivanja slika",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "MP3 modul serijskog priključka može uzrokovati da se prekid pina ne može pokrenuti. Preporuča se izbjegavati prečesto korištenje MP3 modula serijskog priključka",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "Modul zaslona max7219 može uzrokovati da se pin prekidi ne pokreću. Preporuča se izbjegavati prečesto korištenje zaslona max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "DHT očitavanje može dovesti do pogrešaka u infracrvenom primanju poruka. Preporuča se smanjiti učestalost očitavanja DHT-a",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "Čitanje ds18b20 može uzrokovati pogreške u mekom vanjskom primanju poruka, stoga se preporučuje smanjiti učestalost čitanja ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "Očitavanje PM2.5 može uzrokovati pogreške u primanju poruka izvan softvera. Preporuča se smanjiti učestalost očitanja PM2,5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "Čitanje MP3 inicijalizacije može uzrokovati pogrešku infracrvenog primanja poruke, preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "Postavka glasnoće mp3 može uzrokovati pogrešku infracrvenog primanja poruka. Preporuča se izbjegavati prečesto podešavanje glasnoće",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "Način rada za postavljanje Mp3 može uzrokovati pogreške u infracrvenom primanju poruka. Preporuča se izbjegavati prečesto podešavanje načina rada",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "Mp3 player može uzrokovati greške u infracrvenom primanju poruka, stoga se preporučuje izbjegavanje prečestog mijenjanja glazbe",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "Postavljanje načina serijskog priključka mp3 može uzrokovati pogrešku infracrvenog primanja poruke, preporuča se izbjegavati prečesto postavljanje načina",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "Serijski mp3 player može uzrokovati pogreške u infracrvenom primanju poruka, stoga se preporučuje izbjegavanje prečestog mijenjanja glazbe",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Postavljanje glasnoće serijskog priključka mp3 može dovesti do pogreške infracrvenog primanja poruka. Preporuča se izbjegavati prečesto podešavanje glasnoće",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "Infracrveno zračenje može uzrokovati pogreške u infracrvenom primanju poruka, pa se preporučuje izbjegavanje prečestog slanja infracrvenih poruka",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "Inicijalizacija RGB pojasa može dovesti do pogrešaka u primanju infracrvenih poruka. Preporuča se izbjegavati istovremeno izvršenje",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Postavljanje svjetline RGB pojasa može uzrokovati pogreške u primanju infracrvenih poruka. Preporuča se izbjegavati prečesto podešavanje svjetline",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "Prikaz RGB pojasa može uzrokovati pogreške u infracrvenom primanju poruka. Preporuča se izbjegavati prečesto prikazivanje RGB pojasa",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "Promjena boje RGB trake svjetiljke može dovesti do pogreške infracrvene poruke. Preporuča se izbjegavati prečesto prikazivanje promjene boje u gradijentima",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Pomicanje piksela s RGB svjetlosnim pojasom može uzrokovati pogreške u infracrvenom primanju poruka. Preporuča se izbjegavati prečesto pomicanje piksela",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "Kružno kretanje piksela s RGB svjetlosnim pojasom može uzrokovati pogreške u infracrvenom primanju poruka. Preporuča se izbjegavati prečesto izvođenje kružnog kretanja piksela",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "Prikaz RGB trake u obliku stupca može uzrokovati pogrešku primanja infracrvene poruke, preporučuje se izbjegavati prečesto prikazivanje u obliku stupca",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Primanje infracrvenih poruka može uzrokovati neuspjeh inicijalizacije max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Primanje infracrvene poruke može uzrokovati max7219 pogrešku prikaza",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "Meki serijski ispis može uzrokovati pogreške u infracrvenom primanju poruka, pa se preporučuje izbjegavanje prečestog mekog serijskog ispisa",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "Soft serial port obloq MQTT inicijalizacija može uzrokovati pogreške u infracrvenim primljenim podacima, stoga se preporučuje izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "Soft serial obloq put zahtjev može dovesti do infracrvene pogreške primanja podataka, preporučuje se izbjegavanje prečestih zahtjeva za podacima",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "Soft serial port obloq HTTP inicijalizacija može dovesti do infracrvenih pogrešaka primanja podataka, preporuča se izbjegavati istovremeno izvršavanje",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "Zahtjev za dobivanje mekog serijskog obloqa može dovesti do pogreške primanja infracrvenih podataka, preporučuje se izbjegavanje prečestih zahtjeva za podacima",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "Soft serial obloq post zahtjev može dovesti do infracrvene pogreške primanja podataka, preporučuje se izbjegavanje prečestih zahtjeva podataka",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "Jasan prikaz trake RGB lampe može uzrokovati pogreške u primanju poruka u mekom infracrvenom. Preporuča se izbjegavati prečesto jasno prikazivanje",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "Očitavanje DHT-a može dovesti do pogreške mekog serijskog primanja poruke, preporučuje se smanjiti učestalost očitavanja DHT-a",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "Očitavanje ds18b20 može uzrokovati da soft serijski port primi poruku o pogrešci, preporučuje se smanjiti učestalost čitanja ds18b20",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "Očitavanje PM2.5 može uzrokovati pogreške u primanju poruka putem softverskog serijskog priključka. Preporuča se smanjiti učestalost očitanja PM2,5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "Čitanje MP3 inicijalizacije može rezultirati pogreškom mekog serijskog primanja poruke i preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Postavljanje glasnoće mp3 može uzrokovati pogrešku pri primanju poruke na softverskom serijskom portu. Preporuča se izbjegavati prečesto podešavanje glasnoće",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "Način rada za postavljanje Mp3 može uzrokovati da softverski serijski priključak primi poruku o pogrešci, preporuča se prečesto izbjegavanje načina podešavanja",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "Mp3 reprodukcija može dovesti do pogreške mekog serijskog primanja poruka, preporuča se izbjegavati prečesto mijenjanje glazbe",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "Čitanje inicijalizacije serijskog porta mp3 može uzrokovati pogrešku primanja poruke mekog serijskog porta, preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "Način postavljanja Mp3 serijskog priključka može uzrokovati pogrešku pri primanju poruka putem softverskog serijskog priključka. Preporuča se prečesto izbjegavanje načina postavljanja",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "Mp3 reprodukcija na serijskom priključku može uzrokovati pogreške u primanju poruka na mekom serijskom priključku. Preporuča se izbjegavati prečesto mijenjanje glazbe",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Postavljanje glasnoće mp3 serijskog priključka može uzrokovati pogrešku pri primanju poruke s mekog serijskog priključka. Preporuča se izbjegavati prečesto podešavanje glasnoće",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "Infracrveno zračenje može uzrokovati pogreške u primanju poruka putem softverskog serijskog porta. Preporuča se izbjegavati prečesto slanje infracrvenih poruka",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "Inicijalizacija RGB pojasa može rezultirati pogreškom u primanju poruka s mekog serijskog priključka. Preporuča se izbjegavati istovremeno izvršenje",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Postavljanje svjetline RGB trake može uzrokovati pogreške u primanju poruka s soft serijskog priključka. Preporuča se izbjegavati prečesto podešavanje svjetline",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "Jasan prikaz RGB svjetlosnog pojasa može uzrokovati pogrešku pri primanju poruke putem softverskog serijskog priključka. Preporuča se izbjegavati prečesto jasno prikazivanje",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "Prikaz RGB pojasa može uzrokovati pogreške u primanju poruka s soft serijskog priključka. Preporuča se izbjegavati prečesto prikazivanje RGB pojasa",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "Gradijent prikaza trake RGB lampe može uzrokovati pogrešku poruke u mekom serijskom priključku. Preporuča se izbjegavati prečesto prikazivanje gradijenta.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Pomicanje piksela s RGB svjetlosnim pojasom može uzrokovati neispravno primanje poruka od mekog serijskog priključka. Preporuča se izbjegavati prečesto pomicanje piksela",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "Kružno kretanje piksela RGB svjetlosnog pojasa može uzrokovati pogrešku primanja poruka mekom serijskom portu, preporuča se izbjegavati prečesto izvršavanje kružnog kretanja piksela",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "Prikaz RGB trake u stupcu može uzrokovati da softverski serijski port primi poruku o pogrešci, preporuča se izbjegavati prečesto izvođenje prikaza u stupcu",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "Poruke mekog serijskog primanja mogu uzrokovati neuspjeh inicijalizacije max7219",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "Poruka mekog serijskog primanja može uzrokovati pogrešku prikaza max7219",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "Soft serijski ispis može uzrokovati pogrešku u primanju poruka s soft serijskog priključka. Preporuča se izbjegavati prečesto izvođenje mekog serijskog ispisa",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "Očitavanje DHT-a može uzrokovati neuspjeh pokretanja prekida pina. Preporuča se smanjiti učestalost čitanja DHT-a",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "Čitanje ds18b20 može uzrokovati kvar okidača prekida pina, preporuča se smanjiti učestalost čitanja ds18b20",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "Očitavanje PM2.5 može uzrokovati kvar okidača prekida pina. Preporuča se smanjiti učestalost očitavanja PM2.5",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Čitanja MP3 inicijalizacije mogu uzrokovati neuspjeh pokretanja prekida pina i preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "Mp3 postavka glasnoće može uzrokovati neuspjeh okidača za pin interrupt. Preporuča se izbjegavati prečesto podešavanje glasnoće",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "Način rada za postavljanje Mp3 može uzrokovati neuspjeh pokretanja prekida pina. Preporuča se prečesto izbjegavanje načina postavljanja",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "Mp3 player može uzrokovati neuspjeh okidača za pin interrupt, preporuča se izbjegavati prečesto mijenjanje glazbe",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "Čitanje inicijalizacije mp3 serijskog porta može uzrokovati neuspjeh okidača prekida pina, preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "Postavljanje načina serijskog priključka mp3 može uzrokovati neuspjeh okidača prekida pina. Preporuča se prečesto izbjegavanje načina postavljanja",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "Serijski mp3 player može uzrokovati neuspjeh okidača za pin interrupt, preporuča se izbjegavati prečesto mijenjanje glazbe",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Postavljanje glasnoće serijskog priključka mp3 može uzrokovati neuspjeh okidača prekida pina. Preporuča se izbjegavati prečesto podešavanje glasnoće",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "Infracrveno zračenje može uzrokovati neuspjeh okidača prekida pina, preporuča se izbjegavati prečesto slanje infracrvenog zračenja",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "Inicijalizacija RGB vrpce može uzrokovati neuspjeh okidača prekida pina i preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Postavljanje svjetline RGB vrpce može uzrokovati neuspjeh okidača prekida pina. Preporuča se izbjegavati prečesto podešavanje svjetline",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "Jasan prikaz RGB trake može uzrokovati neuspjeh okidača prekida pina, preporučuje se izbjegavanje prečestog jasnog prikaza",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "Prikaz RGB trake može uzrokovati neuspjeh okidača prekida pina. Preporuča se izbjegavati prečesto prikazivanje RGB trake",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "Prikaz gradijenta RGB vrpce može uzrokovati neuspjeh okidača prekida pina. Preporuča se izbjegavati prečesto prikazivanje gradijenta",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "Pokretni pikseli u RGB pojasu mogu uzrokovati neuspjeh pokretanja prekida pina i preporuča se izbjegavati prečesto pomicanje piksela",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB svjetlosni piksel za pomicanje petlje za remen može uzrokovati neuspjeh okidača prekida pina, preporuča se izbjegavati prečesto pomicanje piksela za petlju",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "Traka prikaza RGB vrpce može uzrokovati neuspjeh okidača prekida pina. Preporuča se izbjegavati prečesto prikazivanje trake",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "Prekid pina može uzrokovati neuspjeh inicijalizacije max7219",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "Prekid pina može uzrokovati netočan prikaz max7219",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "Meki serijski ispis može uzrokovati neuspjeh okidača prekida pina, preporuča se izbjegavati prečesto meko serijsko ispisivanje",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "Soft serial port obloq MQTT inicijalizacija može uzrokovati neuspjeh pokretanja prekida pina i preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "Soft serial obloq put zahtjevi mogu uzrokovati neuspjeh pokretanja prekida pina i preporučuje se izbjegavati prečesto traženje podataka",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "Soft serial obloq HTTP inicijalizacija može uzrokovati neuspjeh pokretanja prekida pina, preporuča se izbjegavati istovremeno izvršavanje",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "Zahtjev za dobivanje mekog serijskog obloqa može uzrokovati neuspjeh okidača prekida pina, preporučuje se izbjegavanje prečestih zahtjeva za podacima",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "Soft serial obloq post zahtjev može uzrokovati neuspjeh okidača prekida pina, preporučuje se izbjegavanje prečestih zahtjeva za podacima",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "Očitavanje DHT-a može dovesti do pogreške primanja poruka mekog serijskog obloqa, preporuča se smanjiti učestalost očitavanja DHT-a",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "Čitanje ds18b20 može uzrokovati da obloq neispravno prima poruke, stoga se preporučuje smanjiti učestalost čitanja ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "Očitavanje PM2.5 može uzrokovati pogrešku u obloq primanju poruka. Preporuča se smanjiti učestalost očitanja PM2,5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "Čitanje MP3 inicijalizacije može rezultirati pogreškom primanja poruke mekog serijskog obloqa, preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Mp3 postavka glasnoće može rezultirati pogreškom primanja poruke mekog serijskog obloqa. Preporuča se izbjegavati prečesto podešavanje glasnoće",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "Način rada za postavljanje Mp3 može dovesti do pogreške primanja poruke soft serial obloq, preporuča se prečesto izbjegavanje načina podešavanja",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "Reprodukcija MP3 može dovesti do pogreške primanja poruka mekog serijskog obloqa, preporuča se izbjegavati prečesto mijenjanje glazbe",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "Čitanje inicijalizacije serijskog porta mp3 može uzrokovati pogrešku primanja poruke obloq mekog serijskog porta, preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "Način podešavanja mp3 serijskog priključka može uzrokovati pogrešku primanja poruke obloq mekog serijskog priključka, preporučuje se prečesto izbjegavanje načina postavljanja",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "Serijski priključak mp3 playera može dovesti do greške primanja poruka obloq mekog serijskog priključka, preporuča se izbjegavati prečesto mijenjanje glazbe",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Postavljanje glasnoće serijskog priključka mp3 može uzrokovati pogrešku primanja poruke mekog serijskog ulaza obloq. Preporuča se izbjegavati prečesto podešavanje glasnoće",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "Infracrveno zračenje može dovesti do pogreške u prijemu poruka mekog serijskog obloqa, preporuča se izbjegavati prečesto slanje infracrvenog zračenja",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Soft serial bloq slanje poruka može dovesti do pogrešaka infracrvenog prijema, preporuča se izbjegavati obloq slanje poruka prečesto",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "Inicijalizacija RGB vrpce može uzrokovati da soft serial obloq primi poruku o pogrešci, preporuča se izbjegavati istovremeno izvršavanje",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Postavljanje svjetline RGB svjetlosnog pojasa može uzrokovati pogrešku u prijemu obloq poruka. Preporuča se izbjegavati prečesto podešavanje svjetline",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Jasan prikaz RGB svjetlosnog pojasa može uzrokovati pogrešku u prijemu obloq poruke. Preporuča se izbjegavati prečesto jasno prikazivanje",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "Prikaz RGB trake može rezultirati pogreškom u obloq primanju poruka. Preporuča se izbjegavati prečesto izvođenje prikaza RGB trake",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "Gradijent prikaza trake RGB lampe može uzrokovati pogrešku poruke u obloq soft serijskom portu. Preporuča se izbjegavati prečesto prikazivanje gradijenta.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Pomicanje piksela s RGB svjetlosnim pojasom može uzrokovati pogrešku u obloq primanju poruka. Preporuča se izbjegavati prečesto pomicanje piksela",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "Kružno kretanje piksela RGB svjetlosnog pojasa može dovesti do greške primanja poruka obloq mekog serijskog porta, preporuča se izbjegavati prečesto petljasto kretanje piksela",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "Prikaz RGB trake u stupcu može uzrokovati pogrešku primanja poruka mekog serijskog obloqa, preporuča se izbjegavati prečesto prikazivanje u stupcu",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "Primanje poruka soft serial obloq može uzrokovati neuspjeh inicijalizacije max7219",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "Primanje poruke soft serial obloq može uzrokovati pogrešku prikaza max7219",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "Meki serijski ispis može uzrokovati da obloq netočno prima poruke. Preporuča se izbjegavati prečesto izvođenje mekog serijskog ispisa",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "Soft serial port obloq slanje podataka može uzrokovati pogreške u primanju podataka putem soft serial porta. Preporuča se izbjegavati prečesto slanje podataka putem obloqa",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "Soft serial obloq slanje podataka može uzrokovati da se pin prekid ne može pokrenuti, preporuča se izbjegavati obloq slanje podataka prečesto",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "Soft serijski port obloq MQTT inicijalizacija može uzrokovati da soft serijski port prima pogrešku podataka, preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "Soft serial obloq put zahtjev može uzrokovati da soft serijski port prima pogrešku podataka, preporučuje se izbjegavanje prečestih zahtjeva za podacima",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "Soft serijski port obloq HTTP inicijalizacija može uzrokovati da soft serijski port prima pogrešku podataka, preporučuje se izbjegavanje istovremenog izvršavanja",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "Zahtjev za dobivanje mekog serijskog obloqa može uzrokovati pogrešku primanja podataka od mekog serijskog porta, preporučuje se izbjegavanje prečestih zahtjeva za podacima",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "Soft serijski port obloq post zahtjev može uzrokovati da soft serijski port prima pogrešku podataka, preporučuje se izbjegavanje prečestih zahtjeva za podacima",
    "gui.blocklyText.compatible.shapedBlock": "Nova verzija mijenja točku: sastavni blok programa događaja s vlastitim varijablama zamijenjen je blokom za izgradnju programa događaja bez varijabli.",
    "gui.blocklyText.compatible.shapedBlock.variable": "Točka promjene nove verzije: promjena iz neovisnog gradivnog bloka programa u gradivni blok varijabilnog programa u programu događaja",
    "gui.blocklyText.compatible.booleanToRound": "Nova točka promjene verzije: Promijenjeno iz gradivnog bloka uvjetnog programa u gradivni blok numeričkog programa.",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "Nova verzija mijenja točku: dodaj opciju padajućeg izbornika za prebacivanje mekog i tvrdog serijskog porta u gradivni blok programa.",
    "gui.blocklyText.compatible.mp3.deletePin": "Nova točka promjene verzije: Ukloni opciju padajućeg izbornika pina u sastavnom bloku programa.",
    "gui.blocklyText.compatible.sen0206.addDropdown": "Točka promjene nove verzije: dodaj opcije padajućeg izbornika u sastavni blok programa.",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "Nova točka promjene verzije: Povećaj opciju padajućeg izbornika pina u gradivnom bloku programa.",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "Nova točka promjene verzije: izmijeni padajuću opciju serijskog priključka u gradivnom bloku programa.",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "Nova verzija mijenja točku: promijenjena je iz građevnog bloka programa događaja u gradivni blok uvjetnog programa.",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "Nova verzija mijenja točku: brisanje bloka",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "Nova verzija mijenja točku: promijeni kvadratni padajući okvir u okrugli padajući okvir",
    "gui.blocklyText.compatible.shouldBlockInitFirst": "Mali modul je izmijenjen, molimo redizajniraj program",
    "gui.blocklyText.pictureai.initToken": "init AI slike [SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "dohvati sliku iz lokalne datoteke[IMAGE]",
    "gui.blocklyText.pictureai.cameraToggle": "prebaci na kameru [CAMERA_LIST]",
    "gui.blocklyText.pictureai.getVideoImage": "dohvati sliku video prečac",
    "gui.blocklyText.pictureai.webPicture": "preuzmi sliku sa web stranice[TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH]spremi prečac kamere na lokalno[TEXT]",
    "gui.blocklyText.pictureai.face": "identificiraj sliku[TEXT] koja sadržava lice",
    "gui.blocklyText.pictureai.word": "identificiraj sliku[TEXT] koja sadržava [SELECT]",
    "gui.blocklyText.pictureai.picture": "identificiraj sliku[TEXT] koja sadržava[SELECT]",
    "gui.blocklyText.pictureai.gesture": "identificiraj sliku[TEXT] koja sadržava gestu",
    "gui.blocklyText.pictureai.TOPIC_W_A": "riječi",
    "gui.blocklyText.pictureai.TOPIC_W_B": "broj",
    "gui.blocklyText.pictureai.TOPIC_W_C": "broj automobila",
    "gui.blocklyText.pictureai.TOPIC_W_D": "rukopis",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "lijevi gležanj",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "lijevo uho",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "lijevi lakat",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "lijevo oko",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "lijevi kuk",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "lijevo koljeno",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "lijevi kut usta",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "lijevo rame",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "lijevi zglob",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "vrat",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "nos",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "desni gležanj",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "desno uho",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "desni lakat",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "desno oko",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "desni kuk",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "desno koljeno",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "desni kut usta",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "desno rame",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "desni zglob",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "vrh glave",
    "gui.blocklyText.pictureai.initUserToken": "prijeđi na zaseban račun[MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "identificiraj sliku[TEXT] koja sadržava točku tijela",
    "gui.blocklyText.pictureai.faceContrast": "lice u kontrastu na slici [TEXT] sa slikom [TEXT2]",
    "gui.blocklyText.pictureai.faceMerge": "spoji lice u sliku [TEXT] i sliku [TEXT2]",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "tijelo slike",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "predmeti i scene opće namjene",
    "gui.blocklyText.pictureai.TOPIC_P_A": "biljka",
    "gui.blocklyText.pictureai.TOPIC_P_B": "životinja",
    "gui.blocklyText.pictureai.TOPIC_P_C": "cvijet",
    "gui.blocklyText.pictureai.TOPIC_P_D": "povrće",
    "gui.blocklyText.pictureai.bodyPointSuccess": "Uspješna identifikacija ključnih točaka u ljudskom tijelu?",
    "gui.blocklyText.pictureai.bodyPointInfo": "dohvati BodyPoint [BODY] [XY]koordinatu",
    "gui.blocklyText.pictureai.noPower": "Provjeri je li račun otvorio značajku",
    "gui.blocklyText.pictureai.pictureError": "slika nije u ispravnom formatu",
    "gui.blocklyText.pictureai.noPicture": "prvo odaberi sliku",
    "gui.blocklyText.pictureai.noVideo": "Provjeri je li kamera pravilno spojena i da nijedan drugi softver nije zauzet te uključi kameru ili ponovno pokreni softver",
    "gui.blocklyText.pictureai.defaultTitleText": "Video prozor",
    "gui.blocklyText.pictureai.videoContainer": "koristi [CON] za prikaz slike kamere",
    "gui.blocklyText.pictureai.TOPIC_stage": "pozornicu",
    "gui.blocklyText.pictureai.TOPIC_popup": "popup",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "Nova točka promjene verzije: Uklonjeni su sastavni blokovi programa.",
    "gui.blocklyText.pictureai.gestureList": "gesta[TEXT]",
    "gui.blocklyText.pictureai.networkError": "Mrežna pogreška, provjeri mrežnu vezu",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "Uspješno prepoznavanje lica?",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "rezultati prepoznavanja lica [TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "lice br",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "dob",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "ljepota",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "spol",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "naočale",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "tip",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "izraz",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "lice vjerojatnosti",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "lijevo",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "vrh",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "širina",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "visina",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "orijentir",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "okluzija",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "zamutiti",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "potpunost",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "ljudski",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "crtani film",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "UKLJUČENO",
    "gui.blocklyText.maqueenPlus.off": "ISKLJUČENO",
    "gui.blocklyText.maqueenPlus.Forward": "naprijed",
    "gui.blocklyText.maqueenPlus.Backward": "unatrag",
    "gui.blocklyText.maqueenPlus.left": "lijevi",
    "gui.blocklyText.maqueenPlus.right": "desni",
    "gui.blocklyText.maqueenPlus.all": "sve",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "okreći [DIR] motor [SPEEDDIR] brzinom [SPEED]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "zaustavi [DIR] motor",
    "gui.blocklyText.maqueenPlusV2.readIR": "očitaj vrijednost IR tipke",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] kompenzacija brzine [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "očitaj brzinu za [DIR] motor",
    "gui.blocklyText.maqueenPlus.readMoterDir": "očitaj smjer za [DIR] motor (0:stop 1:naprijed 2:nazad)",
    "gui.blocklyText.maqueenPlus.clearMoterRunedCircle": "obriši okretaje kotača [DIR]",
    "gui.blocklyText.maqueenPlus.getMoterRunedCircle": "dohvati okretaje kotača [DIR]",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] kut [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "uključi [DIR] RGB diodu u [COLOR] boji",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "očitaj [INDEX] senzor za praćenje linije",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "očitaj vrijednost [INDEX] senzora osjeta u sivim tonovima",
    "gui.blocklyText.maqueenPlus.getVersion": "dohvati verziju",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "očitaj ultrazvučni senzor trig [TRIG] echo [ECHO] jedinica [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "na IR primam [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "infracrveni podaci",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INČA",
    "gui.blocklyText.maqueenPlusV2.setMotorStop": "zaustavi [DIR] motor",
    "gui.blocklyText.maqueenPlusV2.setMotorSpeedMove": "okreći [DIR] motor [SPEEDDIR] brzinom [SPEED]",
    "gui.blocklyText.maqueenPlusV2.rotateForward": "naprijed",
    "gui.blocklyText.maqueenPlusV2.rotateBackward": "unatrag",
    "gui.blocklyText.maqueenPlusV2.on": "uključi",
    "gui.blocklyText.maqueenPlusV2.off": "isključi",
    "gui.blocklyText.maqueenPlusV2.leftLED": "lijevo",
    "gui.blocklyText.maqueenPlusV2.rightLED": "desno",
    "gui.blocklyText.maqueenPlusV2.allLED": "svi",
    "gui.blocklyText.maqueenPlusV2.left": "lijevi",
    "gui.blocklyText.maqueenPlusV2.right": "desni",
    "gui.blocklyText.maqueenPlusV2.all": "oba",
    "gui.blocklyText.maqueenPlusV2.setRgb": "[DIR] LED svjetlo [COLOR]",
    "gui.blocklyText.maqueenPlusV2.readSensationSensor": "očitaj [INDEX] senzor za praćenje linije",
    "gui.blocklyText.maqueenPlusV2.readSensationSensorYscaleValue": "očitaj ADC podatak [INDEX] senzora za praćenje linije",
    "gui.blocklyText.maqueenPlusV2.readUltrasonic": "očitaj ultrazvučni senzor TRIG pin [TRIG] ECHO pin [ECHO] (u cm)",
    "gui.blocklyText.maqueenPlusV2.getVersion": "očitaj verziju",
    "gui.blocklyText.esp32.audioRecognition": "dohvati glasovnu naredbu unutar [TIME] sekundi",
    "gui.blocklyText.esp32.audioRecordingFree": "trelease predmemorija snimanja",
    "gui.blocklyText.esp32.audioRecordingStart": "snimanje zvuka s stazom pohrane [PATH] i trajanjem [TIME] sekundi",
    "gui.blocklyText.esp32.audioRecordingInit": "inicijalizirati snimanje",
    "gui.blocklyText.esp32.audioPlay": "Audio reprodukcija [URL]",
    "gui.blocklyText.esp32.audioControl": "Audio [CONTROL]",
    "gui.blocklyText.esp32.AudioControl.A": "PAUZA",
    "gui.blocklyText.esp32.AudioControl.B": "NASTAVI",
    "gui.blocklyText.esp32.AudioControl.C": "STOP",
    "gui.blocklyText.esp32.audioSetVolume": "postavi glasnoću zvuka kao [VOLUME]",
    "gui.blocklyText.esp32.audioFree": "deinit Audio",
    "gui.blocklyText.esp32.audioInit": "inicijalizirati Audio",
    "gui.blocklyText.esp32.audio": "audio",
    "gui.blocklyText.esp32.musicGetTempo": "trenutni otkucaji",
    "gui.blocklyText.esp32.musicSetTempo": "postavi svaki otkucaj na [TICKS] nota, broj otkucaja u minuti je [TEMPO]",
    "gui.blocklyText.esp32.musicSetPlaySpeed": "postavi brzinu reprodukcije na [SPEED] puta",
    "gui.blocklyText.esp32.musicPlayMelodySet": "pusti glazbu [MELODY] [WAIT] [LOOP] na pinu [PIN]",
    "gui.blocklyText.esp32.musicPlayMelody": "sviraj melodiju [MELODY] prikvači [PIN]",
    "gui.blocklyText.esp32.musicPlayNoteFrom": "sviraj ton od [MIN] do [AMX] korak [STEP] trajanje [DURATION] ms pin [PIN]",
    "gui.blocklyText.esp32.musicPlayNote": "sviraj ton [NOTE] odgoda [DELAY] milisekunda(e) na pinu [PIN]",
    "gui.blocklyText.esp32.musicTone": "ton [NOTE]",
    "gui.blocklyText.esp32.musicPlayNoteContinuous": "sviraj kontinuirani ton [NOTE] na pinu [PIN]",
    "gui.blocklyText.esp32.musicPlayList": "reproduciraj popis bilješki [NOTELIST] [WAIT] [LOOP] na pinu [PIN]",
    "gui.blocklyText.esp32.IsWait.A": "čekati",
    "gui.blocklyText.esp32.IsWait.B": "nemoj čekati",
    "gui.blocklyText.esp32.IsLoop.A": "reprodukcija u petlji",
    "gui.blocklyText.esp32.IsLoop.B": "igrati jednom",
    "gui.blocklyText.esp32.getNote": "nota [NOTE] otkucaj [BEAT]",
    "gui.blocklyText.esp32.musicPlay": "sviraj notu [NOTE] na pinu [PIN]",
    "gui.blocklyText.esp32.restore": "vratiti glazbene postavke",
    "gui.blocklyText.esp32.stopPlay": "zaustavi reprodukciju glazbe na pinu [PIN]",
    "gui.blocklyText.esp32.stopPlay_default": "zadano",
    "gui.blocklyText.mpyUART.input": "dohvati ulazni tekst iz serijskog posta, naslov [TITLE]",
    "gui.blocklyText.mpyUART.close": "zatvori serijski port [UART]",
    "gui.blocklyText.mpyUART.readLength": "serijski priključak [UART] čita podatke čija je dužina [LENGTH]",
    "gui.blocklyText.mpyUART.read": "serijski priključak [UART] čita podatke",
    "gui.blocklyText.mpyUART.readline": "serijski priključak [UART] pročitati zalog podataka",
    "gui.blocklyText.mpyUART.any": "serijski priključak [UART] ima čitljive podatke?",
    "gui.blocklyText.mpyUART.writeBytes": "serijski priključak [UART] pisanje bajtova [BYTES]",
    "gui.blocklyText.mpyUART.writeByte": "serijski port [UART] napisati bajt [BYTE]",
    "gui.blocklyText.mpyUART.init": "inicijaliziraj serijski port [UART] kao brzinu prijenosa [BAUDRATE] tx [TXPIN] rx [RXPIN]",
    "gui.blocklyText.mpyUART.initTello": "inicijaliziraj serijski priključak [UART] rx [RXPIN] tx [TXPIN] kao brzinu prijenosa [BAUDRATE]",
    "gui.blocklyText.mpyUART.writeStringTello": "serijski port [UART] pisanje niza [TEXT] [ENDSYMBOL] omot",
    "gui.blocklyText.mpyUART.anyTello": "serijski priključak [UART] ima čitljive podatke?",
    "gui.blocklyText.mpyUART.readTello": "serijski priključak [UART] čita podatke",
    "gui.blocklyText.mpyUART.closeTello": "zatvori serijski port [UART]",
    "gui.blocklyText.mpyUART.writeString": "serijski port [UART] pisanje niza [TEXT] [ENDSYMBOL] omot",
    "gui.blocklyText.mpyUART.no": "Ne",
    "gui.blocklyText.mpyUART.auto": "auto",
    "gui.blocklyText.mpyI2C.scan": "Rezultat I2C skeniranja",
    "gui.blocklyText.mpyI2C.read": "I2C adresa [ADDRESS] broj bajtova čitanja [BYTES]",
    "gui.blocklyText.mpyI2C.write": "I2C adresa [ADDRESS] je zapisana u [DATA]",
    "gui.blocklyText.mpyI2C.init": "inicijalizirati I2C SCL [SCLPIN] SDA [SDAPIN] brzina prijenosa podataka [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoInitBoard": "inicijalizacija ugrađene I2C frekvencije [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoUniversal": "Inicijaliziraj opću I2C SCL [SCLPIN] SDA [SDAPIN] frekvenciju [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoWrite": "[OBJ] Upiši [DATA] na adresu [ADDRESS]",
    "gui.blocklyText.mpyI2C.maixduinoRead": "[OBJ] je pročitao [BYTES] podataka s adrese [ADDRESS]",
    "gui.blocklyText.mpyI2C.maixduinoScan": "[OBJ] rezultat skeniranja",
    "gui.blocklyText.esp32.readPinLevel": "trajanje vanjske razine [LEVEL] pulsa na [PIN]",
    "gui.blocklyText.esp32.noPull": "bez povlačenja",
    "gui.blocklyText.esp32.pullDown": "povući dolje",
    "gui.blocklyText.esp32.pullUp": "povući",
    "gui.blocklyText.esp32.setPinMode": "postavi pin [PIN] kao [MODE] mod [PULL] i postavi vrijednost na [LEVEL]",
    "gui.blocklyText.esp32.leakyOutput": "nepropusni izlaz",
    "gui.blocklyText.mpyServo.setServo": "postavi servo [PIN] kut kao [ANGLE] širinu impulsa od [MIN] nas do [MAX] nas raspon aktiviranja kao [ANGLEMAX]",
    "gui.blocklyText.servo.init": "postavi servo [PIN] kut kao [ANGLE] timer [TIMER]",
    "gui.blocklyText.mpyServo.setAngle": "postavi servo [PIN] kut kao [ANGLE]",
    "gui.blocklyText.esp32.shaked": "potresen",
    "gui.blocklyText.esp32.thrown": "bačena",
    "gui.blocklyText.esp32.tilt_forward": "nagnuti naprijed",
    "gui.blocklyText.esp32.tilt_back": "nagnuti unazad",
    "gui.blocklyText.esp32.tilt_right": "nagnuti udesno",
    "gui.blocklyText.esp32.tilt_left": "nagnuti lijevo",
    "gui.blocklyText.esp32.tilt_none": "ravan",
    "gui.blocklyText.esp32.attitudeEvent": "kada je ploča [ATTITUDE]",
    "gui.blocklyText.esp32.to_high": "↗ od niske prema visokoj",
    "gui.blocklyText.esp32.to_low": "↘ visoka prema niskoj",
    "gui.blocklyText.esp32.pinLevelEvent": "kada pin [PIN] razina od [TRANSITION]",
    "gui.blocklyText.mpyTimer.setEvent": "Postavi tajmer [INDEX] [MODE] period [PERIOD] milisekundi",
    "gui.blocklyText.mpyTimer.repeated": "ponovljeno",
    "gui.blocklyText.mpyTimer.delayed": "odgođeno",
    "gui.blocklyText.mpyTimer.conditionEvent": "Postavi mjerač vremena [INDEX] :kada [CONDITION]",
    "gui.blocklyText.mpyTimer.getCount": "Vrijednost odbrojavanja mjerača vremena [INDEX].",
    "gui.blocklyText.mpyTimer.clearEvent": "Obriši mjerač vremena/događaj [INDEX]",
    "gui.blocklyText.mpyDictionary.init": "init rječnik [CONTENT]",
    "gui.blocklyText.mpyDictionary.getValue": "rječnik [CONTENT] vrijednost [KEY]",
    "gui.blocklyText.mpyDictionary.setValue": "postavi vrijednost rječnika [CONTENT] ključa [KEY] na [VALUE]",
    "gui.blocklyText.mpyDictionary.deleteKey": "brisanje rječnika [CONTENT] tipka [KEY]",
    "gui.blocklyText.mpyDictionary.clear": "čisti rječnik [CONTENT]",
    "gui.blocklyText.mpyDictionary.isInclude": "rječnik [CONTENT] uključuje ključ [KEY]?",
    "gui.blocklyText.mpyDictionary.getLength": "dužina rječnika [CONTENT]",
    "gui.blocklyText.mpyDictionary.list": "lista [TYPE] u rječniku [CONTENT]",
    "gui.blocklyText.mpyDictionary.TypeIndex.A": "ključ",
    "gui.blocklyText.mpyDictionary.TypeIndex.B": "vrijednost",
    "gui.blocklyText.mpyList.join": "Listu [LIST] koristi razdjelnike [SYMBOL] za kombiniranje teksta",
    "gui.blocklyText.mpyList.split": "Tekst [TEXT] koristi razdjelnike [SYMBOL] za izradu liste",
    "gui.blocklyText.mpyList.init": "početna lista [CONTENT]",
    "gui.blocklyText.mpyList.clear": "obriši očisti listu [CONTENT]",
    "gui.blocklyText.mpyList.getLength": "dužina liste [CONTENT]",
    "gui.blocklyText.mpyList.isEmpty": "[CONTENT] je prazan?",
    "gui.blocklyText.mpyList.getValue": "dohvati vrijednost na indeksu [INDEX] na listi [CONTENT]",
    "gui.blocklyText.mpyList.getValueRange": "povratna lista [CONTENT] uzima [TYPE1] [NUM1] stavke u [TYPE2] [NUM2] stavke",
    "gui.blocklyText.mpyList.insert": "umetni [VALUE] u indeks [INDEX] na listi [CONTENT]",
    "gui.blocklyText.mpyList.setValue": "postavi indeks [INDEX] na [VALUE] na listi [CONTENT]",
    "gui.blocklyText.mpyList.delete": "brisanje vrijednosti na indeksu [INDEX] s liste [CONTENT]",
    "gui.blocklyText.mpyList.append": "dodaj [APPEND] u listu [CONTENT]",
    "gui.blocklyText.mpyList.extend": "popis [LIST1] dodaj listu [LIST2]",
    "gui.blocklyText.mpyList.findIndex": "pronađi indeks [VALUE] na listi [CONTENT]",
    "gui.blocklyText.mpyList.sort": "Listu [LIST] sortiraj prema [TYPE] kao [MODE]",
    "gui.blocklyText.mpyList.SortModeIndex.A": "uzlazni",
    "gui.blocklyText.mpyList.SortModeIndex.B": "silazni",
    "gui.blocklyText.mpyList.SortTypeIndex.A": "broj",
    "gui.blocklyText.mpyList.SortTypeIndex.B": "pismo",
    "gui.blocklyText.mpyList.SortTypeIndex.C": "slovo, zanemari velika i mala slova",
    "gui.blocklyText.mpySet.init": "početni skup [CONTENT]",
    "gui.blocklyText.mpySet.update": "ažuriraj skup [CONTENT1] kao [TYPE] skupom [CONTENT2]",
    "gui.blocklyText.mpySet.TypeIndex.A": "križanje",
    "gui.blocklyText.mpySet.TypeIndex.B": "unija",
    "gui.blocklyText.mpySet.TypeIndex.C": "razlika",
    "gui.blocklyText.mpySet.addValue": "ažuriraj skup [CONTENT] s [VALUE]",
    "gui.blocklyText.mpySet.isSubsetSuperset": "set [CONTENT1] je [TYPE] skupa [CONTENT2]?",
    "gui.blocklyText.mpySet.SubsetSupersetType.A": "podskup",
    "gui.blocklyText.mpySet.SubsetSupersetType.B": "nadskup",
    "gui.blocklyText.mpySet.intersectionUnionSetDifference": "[TYPE] skupa [CONTENT1] i skupa [CONTENT2]",
    "gui.blocklyText.mpySet.length": "dužina skupa [CONTENT]",
    "gui.blocklyText.mpySet.pop": "vrati nasumični predmet iz skupa [CONTENT] i ukloni ga",
    "gui.blocklyText.mpyTuple.init": "init tuple [CONTENT]",
    "gui.blocklyText.mpyTuple.minMaxLength": "tuple [CONTENT] [TYPE]",
    "gui.blocklyText.mpyTuple.TypeIndex.A": "min vrijednost",
    "gui.blocklyText.mpyTuple.TypeIndex.B": "maksimalna vrijednost",
    "gui.blocklyText.mpyTuple.TypeIndex.C": "dužina",
    "gui.blocklyText.mpyTuple.isInclude": "tuple [CONTENT] uključuje [STRING]?",
    "gui.blocklyText.mpyTuple.getValueRange": "vrati torku [CONTENT] uzima [TYPE1] [NUM1] stavke u [TYPE2] [NUM2] stavke",
    "gui.blocklyText.mpyTuple.IndexType.A": "#",
    "gui.blocklyText.mpyTuple.IndexType.B": "odbrojavanje #",
    "gui.blocklyText.mpyTuple.listToTulpe": "listu [LIST] pretvori u tuple",
    "gui.blocklyText.mpyTuple.getValue": "dohvati vrijednost na indeksu [INDEX] u torki [CONTENT]",
    "gui.blocklyText.mpyTuple.tupleToList": "pretvoriti tuple [CONTENT] u listu",
    "gui.blocklyText.esp32.DisplayTypeMenu.A": "normalan",
    "gui.blocklyText.esp32.DisplayTypeMenu.B": "rev",
    "gui.blocklyText.esp32.displayInnerImageInXY": "prikaz ugrađene slike [IMAGE] u x:[X] y:[Y] uzorak [TYPE]",
    "gui.blocklyText.esp32.displayFileSystemImageInXY": "učitavanje slike iz datotečnog sustava [IMAGE] show to x:[X] y:[Y] pattern [TYPE]",
    "gui.blocklyText.weather.GetWeatherWithCityName": "saznaj [WEATHER] u gradu [CITY].",
    "gui.blocklyText.pictureai.createFaceClass": "stvori klasu lica [NAME]",
    "gui.blocklyText.pictureai.pictureaiAddFaceToClass": "imenovani rezultat prepoznavanja [RES] i dodan u klasu lica [CLASSNAME]",
    "gui.blocklyText.pictureai.pictureaiSelectResultsFromClass": "rezultati prepoznavanja pretraživanja u biblioteci lica [CLASSNAME] gdje je pouzdanost >= [NUMBER]",
    "gui.blocklyText.pictureai.pictureaiWhenSearchName": "Kada su rezultati pretraživanja bili [NAME]",
    "gui.blocklyText.pictureai.pictureaiSearchedName": "naziv rezultata pretraživanja",
    "gui.blocklyText.pictureai.pictureaiSelectResultReliability": "pouzdanost rezultata pretraživanja",
    "gui.blocklyText.pictureai.pleaseUseSelefAccount": "face class funkcija prebaciti na zasebni račun",
    "gui.blocklyText.pictureai.addfaceclasserror": "Pogreška pri izradi klase lica",
    "gui.blocklyText.pictureai.faceclassnameerror": "ime klase lica sastoji se od brojeva, slova i podvlaka",
    "gui.blocklyText.pictureai.faceclassnameexist": "naziv klase već postoji",
    "gui.blocklyText.pictureai.addfaceerror": "Pogreška pri dodavanju lica",
    "gui.blocklyText.pictureai.selectfaceclasserror": "Klasa lica pretraživanja pogreške",
    "gui.blocklyText.pictureai.notfoundmatch": "odgovarajući korisnik nije pronađen",
    "gui.blocklyText.pictureai.facenameerror": "Ime lica sastoji se od brojeva, slova i podvlaka",
    "boost.color.any": "bilo koja boja",
    "boost.color.black": "crno",
    "boost.color.blue": "plava",
    "boost.color.green": "zelena",
    "boost.color.red": "crvena",
    "boost.color.white": "bijela",
    "boost.color.yellow": "žuta boja",
    "boost.getMotorPosition": "položaj motora [MOTOR_REPORTER_ID].",
    "boost.getTiltAngle": "kut nagiba [TILT_DIRECTION]",
    "boost.motorDirection.backward": "onuda",
    "boost.motorDirection.forward": "ovuda",
    "boost.motorDirection.reverse": "obrnuti",
    "boost.motorOff": "isključi motor [MOTOR_ID].",
    "boost.motorOn": "uključi motor [MOTOR_ID].",
    "boost.motorOnFor": "okreni motor [MOTOR_ID] [DURATION] sekundi",
    "boost.motorOnForRotation": "okreni motor [MOTOR_ID] za [ROTATION] okretaja",
    "boost.seeingColor": "vidjeti [COLOR] cigla?",
    "boost.setLightHue": "postavi boju svjetla na [HUE]",
    "boost.setMotorDirection": "postavi motor [MOTOR_ID] smjer [MOTOR_DIRECTION]",
    "boost.setMotorPower": "postavi brzinu motora [MOTOR_ID] na [POWER] %",
    "boost.tiltDirection.any": "bilo koji",
    "boost.tiltDirection.down": "dolje",
    "boost.tiltDirection.left": "lijevo",
    "boost.tiltDirection.right": "pravo",
    "boost.tiltDirection.up": "gore",
    "boost.whenColor": "kada se vidi [COLOR] cigla",
    "boost.whenTilted": "kada je nagnut [TILT_DIRECTION_ANY]",
    "ev3.beepNote": "zvučni signal [NOTE] za [TIME] sek",
    "ev3.buttonPressed": "gumb [PORT] pritisnut?",
    "ev3.getBrightness": "svjetlina",
    "ev3.getDistance": "udaljenost",
    "ev3.getMotorPosition": "položaj motora [PORT].",
    "ev3.motorSetPower": "motor [PORT] podešena snaga [POWER] %",
    "ev3.motorTurnClockwise": "motor [PORT] okreni u ovom smjeru [TIME] sekundi",
    "ev3.motorTurnCounterClockwise": "motor [PORT] okreni na taj način [TIME] sekundi",
    "ev3.whenBrightnessLessThan": "kada je svjetlina < [DISTANCE]",
    "ev3.whenButtonPressed": "kada se pritisne tipkalo [PORT].",
    "ev3.whenDistanceLessThan": "kada je udaljenost < [DISTANCE]",
    "wedo2.getDistance": "udaljenost",
    "wedo2.getTiltAngle": "kut nagiba [TILT_DIRECTION]",
    "wedo2.isTilted": "nagnut [TILT_DIRECTION_ANY]?",
    "wedo2.motorDirection.backward": "onuda",
    "wedo2.motorDirection.forward": "ovuda",
    "wedo2.motorDirection.reverse": "obrnuti",
    "wedo2.motorId.a": "motor A",
    "wedo2.motorId.all": "sve motore",
    "wedo2.motorId.b": "motor B",
    "wedo2.motorId.default": "motor",
    "wedo2.motorOff": "isključi [MOTOR_ID].",
    "wedo2.motorOn": "uključi [MOTOR_ID].",
    "wedo2.motorOnFor": "uključi [MOTOR_ID] na [DURATION] sekundi",
    "wedo2.playNoteFor": "sviraj notu [NOTE] [DURATION] sekundi",
    "wedo2.setLightHue": "postavi boju svjetla na [HUE]",
    "wedo2.setMotorDirection": "postavi smjer [MOTOR_ID] na [MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "postavi [MOTOR_ID] snagu na [POWER]",
    "wedo2.tiltDirection.any": "bilo koji",
    "wedo2.tiltDirection.down": "dolje",
    "wedo2.tiltDirection.left": "lijevo",
    "wedo2.tiltDirection.right": "pravo",
    "wedo2.tiltDirection.up": "gore",
    "wedo2.whenDistance": "kada udaljenost [OP] [REFERENCE]",
    "wedo2.whenTilted": "kada je nagnut [TILT_DIRECTION_ANY]",
    "gui.blocklyText.video.notfound": "nije pronađeno",
    "gui.blocklyText.machinelearning.drawResults": "[TOGGLE]rezultati prepoznavanja crteža",
    "gui.blocklyText.machinelearning.initKNNImageClass": "PC KNN klasifikacija",
    "gui.blocklyText.machinelearning.imageClassificationFromCamera": "KNN klasificira sliku kamere kao [TEXT]",
    "gui.blocklyText.machinelearning.imageClassificationFromDir": "KNN klasificira sliku lokalne mape [DIR] kao [TEXT]",
    "gui.blocklyText.machinelearning.startKNNTrain": "KNN započinje trening klasifikacije",
    "gui.blocklyText.machinelearning.predictImageClass": "KNN [TOGGLE] prepoznavanje klasifikacije slike kamere",
    "gui.blocklyText.machinelearning.getImageClassification": "KNN prepoznaje rezultat klasifikacije",
    "gui.blocklyText.machinelearning.getImageClassificationReliability": "KNN prepoznaje vjerodostojnost",
    "gui.blocklyText.machinelearning.clearImageClassificationLabel": "KNN jasna oznaka [LABEL]",
    "gui.blocklyText.machinelearning.clearImageClassificationModel": "KNN jasni podaci o modelu klasifikacije",
    "gui.blocklyText.machinelearning.loadImageClassificationModel": "KNN učitati model klasifikacije na strani računala iz [PATH]",
    "gui.blocklyText.machinelearning.exportImageClassificationModel": "KNN spremiti model klasifikacije",
    "gui.blocklyText.machinelearning.traceFace": "FaceAPI inicijalizira praćenje lica",
    "gui.blocklyText.machinelearning.drawFaceAPI": "FaceAPI[TOGGLE] označava ključne točke lica",
    "gui.blocklyText.machinelearning.traceFaceNumber": "FaceAPI broj lica",
    "gui.blocklyText.machinelearning.getFaceByIndex": "FaceAPI dobiva [PART] od [INDEX] lice s koordinatom [TYPE]",
    "gui.blocklyText.machinelearning.initfaceContrast": "FaceAPI Pokretanje specifičnog prepoznavanja lica",
    "gui.blocklyText.machinelearning.refFaceFromCamera": "FaceAPI prikuplja podatke o licu s kamere označene kao [NAME]",
    "gui.blocklyText.machinelearning.refFaceFromDir": "FaceAPI prikuplja podatke o licu iz mape [DIR] označene kao naziv mape",
    "gui.blocklyText.machinelearning.faceAPIFindFace": "FaceAPI identificira lice u kameri jednom",
    "gui.blocklyText.machinelearning.allowFaceAPIResult": "Rezultati FaceAPI prepoznavanja lica",
    "gui.blocklyText.machinelearning.allowFaceAPIResultScore": "FaceAPI prepoznavanje lica daje vjerodostojnost rezultata",
    "gui.blocklyText.machinelearning.clearFaceAPIModel": "FaceAPI Brisanje podataka modela lica",
    "gui.blocklyText.machinelearning.ml5WhenSearchFaceName": "prilikom pretraživanja oznake[NAME] vjerodostojnost>=[VALUE]%",
    "gui.blocklyText.machinelearning.machinelearningTracePose": "PoseNet inicijalizira praćenje držanja",
    "gui.blocklyText.machinelearning.drawPoseNet": "PoseNet[TOGGLE] označava ključne točke držanja",
    "gui.blocklyText.machinelearning.getPoseByIndex": "PoseNet dobiva [PART] od [INDEX] položaja s koordinatom [TYPE]",
    "gui.blocklyText.machinelearning.initImageClassify": "Inicijaliziraj klasifikator slike za prepoznavanje objekata (mobileNet)",
    "gui.blocklyText.machinelearning.toggleImageClassify": "[TOGGLE] Klasifikator slika za prepoznavanje objekata Prepoznavanje u stvarnom vremenu",
    "gui.blocklyText.machinelearning.imageClassifyResult": "Rezultati prepoznavanja predmeta",
    "gui.blocklyText.machinelearning.imageClassifyResultScore": "Prepoznavanje objekta vjerodostojnost prepoznavanja",
    "gui.blocklyText.huskylens.initVisualModal": "inicijalizirati alat za vizualizaciju umjetne inteligencije na [TYPE]",
    "gui.blocklyText.huskylens.classifyImageFromCamera": "dodaj slike zaslona računalne kamere u klasifikator [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromHuskylens": "dodaj slike ekrana HuskyLens kamere u klasifikator [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromDir": "dodaj slike lokalne mape datoteka [DIR] u klasifikator [TEXT]",
    "gui.blocklyText.huskylens.visualModalClearLabel": "jasna klasifikacija [LABEL]",
    "gui.blocklyText.huskylens.visualModalClearAllLabels": "izbrisati sve klasifikacije klasifikatora",
    "gui.blocklyText.huskylens.deployClassificationToHuskylens": "Obuči model i implementiraj ga na HuskyLens",
    "gui.blocklyText.machinelearning.loadlmodel": "put modela",
    "gui.blocklyText.pictureai.TOPIC_BODY_mouth": "usta",
    "gui.blocklyText.pictureai.TOPIC_BODY_jaw_outline": "čeljust",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye_brow": "lijeva obrva",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye_brow": "desna obrva",
    "gui.blocklyText.huskylens.videoContainer": "koristi [CON] za prikaz zaslona kamere računala",
    "gui.blocklyText.huskylens.videoToggle": "[VIDEO_STATE] računalna kamera",
    "gui.blocklyText.huskylens.cameraToggle": "prebaci na kameru računala [CAMERA_LIST]",
    "gui.blocklyText.huskylens.exportImage": "Računalna kamera snima fotografiju i sprema je u lokalnu mapu [TEXT]",
    "gui.blocklyText.dialog.ml5.loadModel": "Učitava se model, stranica može izgledati zaglavljeno, pričekajte...",
    "gui.blocklyText.dialog.ml5.reInitPictureClassify": "slikovni podaci već postoje u klasifikatoru. Ponovna inicijalizacija će isprazniti postojeće podatke. Potvrditi za nastavak?",
    "gui.blocklyText.dialog.ml5.whenDeleteImageAfterTrain": "Ovi slikovni podaci su uvježbani, nakon brisanja moraju se ponovno uvježbati da bi stupili na snagu",
    "gui.blocklyText.dialog.ml5.renameLabel": "Oznaka [LABEL] već postoji, želiš li je spojiti?",
    "gui.blocklyText.dialog.ml5.modelIsEmpty": "U ovom modelu nema povjerljivih podataka. Prvo obuči model",
    "gui.blocklyText.dialog.ml5.shouldInitKNN": "Prvo inicijaliziraj KNN klasifikaciju",
    "gui.blocklyText.dialog.huskylens.emptyAllImage": "Ova operacija će izbrisati sve kategorije, jesi li siguran da nastavljaš?",
    "gui.blocklyText.dialog.huskylens.HuskyLensTakePhotoError": "Snimanje fotografija s HuskyLensa nije uspjelo, pokušaj ponovno",
    "gui.blocklyText.dialog.huskylens.overImageMaxLen": "Maksimalan broj [TYPE] klasifikatora je [LEN], ograničenje je premašeno, riješi to i pokušaj ponovno",
    "gui.blocklyText.dialog.huskylens.notAllowChinese": "Naziv kategorije trebao bi biti sastavljen od brojeva i slova s ​​najviše 50 znamenki i ne smije sadržavati kineske i posebne znakove",
    "gui.blocklyText.dialog.ml5.isNotKNN": "Klasifikator tipa prepoznavanja lica ne može se pridružiti KNN obuci",
    "gui.blocklyText.machinelearning.start": "početak",
    "gui.blocklyText.machinelearning.stop": "Stop",
    "gui.blocklyText.huskyLensPro.ML5MODEL.A": "KNN klasifikacija",
    "gui.blocklyText.huskyLensPro.ML5MODEL.B": "prepoznavanje lica",
    "gui.blocklyText.huskyLensPro.changeAlgorithm": "HuskyLens prebaci algoritam na [ALGORITHM]",
    "gui.blocklyText.huskyLensPro.learndIDCount": "HuskyLens dobiva ukupan broj naučenih ID-ova",
    "gui.blocklyText.huskyLensPro.isOnTheScreen": "HuskyLens provjeriti je li [BOXARROW] na ekranu?",
    "gui.blocklyText.huskyLensPro.parametersNearTheCenter": "HuskyLens dobiva [PARAMETER] od [BOXARROW] najbliže središtu zaslona",
    "gui.blocklyText.huskyLensPro.idIsLearned": "HuskyLens provjeriti je li ID [ID] naučen?",
    "gui.blocklyText.huskyLensPro.idIsOnTheScreen": "HuskyLens provjeriti je li ID [ID] [BOXARROW] na ekranu?",
    "gui.blocklyText.huskyLensPro.idParametersNearTheCenter": "HuskyLens dobiva [PARAMETER] od ID-a [ID] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.boxCount": "HuskyLens dobivaju ukupan broj [BOXARROW]",
    "gui.blocklyText.huskyLensPro.boxarrowParameter": "HuskyLens dobiva [PARAMETER] od broja.[INDEX] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.idBoxCount": "HuskyLens dobiva ukupan broj ID-a [ID] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.idBoxarrowParameter": "HuskyLens dobiva [PARAMETER] ID-a [ID] No.[INDEX] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.learnOnce": "HuskyLens jednom automatski uči ID [ID].",
    "gui.blocklyText.huskyLensPro.clearLearn": "HuskyLens zaboravlja sve podatke o učenju trenutnog algoritma",
    "gui.blocklyText.huskyLensPro.setIDname": "HuskyLens naziv ID [ID] trenutnog algoritma kao [NAME]",
    "gui.blocklyText.huskyLensPro.writeOSD": "HuskyLens prikazuje prilagođene tekstove [NAME] na poziciji x [X] y [Y] na zaslonu",
    "gui.blocklyText.huskyLensPro.clearOSD": "HuskyLens briše sve prilagođene tekstove na zaslonu",
    "gui.blocklyText.huskyLensPro.requestPicture": "HuskyLens [TYPE] i spremi u lokalnu mapu [PATH]",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.A": "slikaj",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.B": "snimka zaslona",
    "gui.blocklyText.huskyLensPro.TakeScreenshotToSDCard": "HuskyLens [TYPE] i spremi na SD karticu",
    "gui.blocklyText.huskyLensPro.xCenter": "X-Centar",
    "gui.blocklyText.huskyLensPro.yCenter": "Y-Centar",
    "gui.blocklyText.huskyLensPro.width": "Širina",
    "gui.blocklyText.huskyLensPro.height": "Visina",
    "gui.blocklyText.huskyLensPro.xStart": "X-Start",
    "gui.blocklyText.huskyLensPro.yStart": "Y-Start",
    "gui.blocklyText.huskyLensPro.xEnd": "X-kraj",
    "gui.blocklyText.huskyLensPro.yEnd": "Y-kraj",
    "gui.blocklyText.huskyLensPro.QRRecognition": "Prepoznavanje QR kôda",
    "gui.blocklyText.huskyLensPro.barcodeRecognition": "Prepoznavanje crtičnog kôda",
    "gui.blocklyText.huskyLensPro.saveFailed": "Spremanje slike nije uspjelo!",
    "gui.blocklyText.huskyLensPro.yes": "Da",
    "gui.blocklyText.huskyLensPro.cancel": "otkazati",
    "gui.blocklyText.huskyLensPro.promptInitializeClassifier": "Prvo inicijaliziraj klasifikator slika",
    "gui.blocklyText.huskyLensPro.promptInitializeKNNClassifier": "Prvo inicijaliziraj KNN klasifikator",
    "gui.blocklyText.huskyLensPro.promptAddClassificationData": "Prvo dodaj podatke o klasifikaciji",
    "gui.blocklyText.huskyLensPro.setTitleText": "HuskyLens AI-KNN Klasifikacija slike",
    "gui.blocklyText.huskyLensPro.tooManyPictures": "Previše je slika, izdvojeno je samo prvih pedesetak slika",
    "gui.blocklyText.huskyLensPro.checkPath": "Provjeri ima li slika na putu [PATH]",
    "gui.blocklyText.huskyLensPro.success": "uspjeh",
    "gui.blocklyText.huskyLensPro.importImg": "Ovaj put je [COUNT1] slika uspješno uvezeno, [COUNT2] nije uspjelo",
    "gui.blocklyText.huskyLensPro.recognitionResult": "Rezultat prepoznavanja:",
    "gui.blocklyText.tello.telloSetPitchRollYaw": "Postavi količinu četiri palice kanala na daljinskom upravljaču: kotrljanje [ROLL] nagib [PITCH] gas [STRENGTH] skretanje [YAW]",
    "gui.blocklyText.tello.telloQuerySpeed": "Trenutna brzina",
    "gui.blocklyText.tello.telloTime": "Vrijeme rada motora",
    "gui.blocklyText.tello.telloWIFISign": "Wi-Fi SNR",
    "gui.blocklyText.tello.telloSDKVersion": "Tello SDK verzija",
    "gui.blocklyText.tello.telloSN": "Tello SN kod",
    "gui.blocklyText.tello.reset": "Resetiraj",
    "gui.blocklyText.tello.telloFlyCurve": "Krivulja letenja x1[X1]cm y1[Y1]cm z1[Z1]cm i x2[X2]cm y2[Y2]cm z2[Z2]cm Brzina [SPEED] cm/s",
    "gui.blocklyText.tello.telloStopEmerygency": "Hitno zaustavljanje",
    "gui.blocklyText.tello.telloGetHeight": "Relativna visina (cm)",
    "gui.blocklyText.tello.telloBarometer": "Visina barometra (m)",
    "gui.blocklyText.tello.telloTofDistance": "TOF visina (cm)",
    "gui.blocklyText.tello.telloTempMin": "Minimalna temperatura matične ploče (°C)",
    "gui.blocklyText.tello.telloTempMax": "Maksimalna temperatura matične ploče (°C)",
    "gui.blocklyText.tello.TelloAttitudePitch": "Kut nagiba osi (°)",
    "gui.blocklyText.tello.TelloAttitudeRoll": "Kut položaja osi kotrljanja (°)",
    "gui.blocklyText.tello.TelloAttitudeYaw": "Kut položaja pan osi (°)",
    "gui.blocklyText.tello.telloAccelerationX": "Ubrzanje X-osi (0,001g)",
    "gui.blocklyText.tello.telloAccelerationY": "Ubrzanje osi Y (0,001g)",
    "gui.blocklyText.tello.telloAccelerationZ": "Ubrzanje Z-osi (0,001g)",
    "gui.blocklyText.tello.telloSpeedX": "Brzina osi X (cm/s)",
    "gui.blocklyText.tello.telloSpeedY": "Brzina osi Y (cm/s)",
    "gui.blocklyText.tello.telloSpeedZ": "Brzina osi Z (cm/s)",
    "gui.blocklyText.tello.telloFlyToXY": "Let (u odnosu na zrakoplov) x[X]cm y[Y]cm z[Z]cm brzina [SPEED]cm/s",
    "gui.blocklyText.tello.telloFlyToMissionPad": "odleti do MissionPada x[X]cm y[Y]cm z[Z]cm s [SPEED] cm/s Mid [MID]",
    "gui.blocklyText.tello.TelloFlyCurveMissionPad": "Linija letećeg luka ispod kartice izazova x1[X1]cm y1[Y1]cm z1[Z1]cm i x2[X2]cm y2[Y2]cm z2[Z2]cm brzina [SPEED]cm/s Sredina[MID]",
    "gui.blocklyText.tello.telloJumpInMissionPad": "Skok duž kartice izazova x[X]cm y[Y]cm z[Z]cm brzina [SPEED]cm/s skretanje [YAW] stupanj od sredine 1 [MID1] do sredine 2 [MID2]",
    "gui.blocklyText.telloesp32.telloGetStatus": "dohvati status jednom, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloReturnStatusChallenge": "dohvati iz rezultata [STATUSCHALLENGE]",
    "gui.blocklyText.telloesp32.statusChallengeX": "X koordinata kartice relativnog izazova",
    "gui.blocklyText.telloesp32.statusChallengeY": "Y koordinata relativne izazovne kartice",
    "gui.blocklyText.telloesp32.statusChallengeZ": "Kartica relativnog izazova Z koordinata",
    "gui.blocklyText.telloesp32.statusChallengePitch": "Relativna izazovna kartica Kut nagiba",
    "gui.blocklyText.telloesp32.statusChallengeYaw": "Relativna izazovna kartica Kut skretanja",
    "gui.blocklyText.telloesp32.statusChallengeRoll": "Relativna izazovna karta Roll angle",
    "gui.blocklyText.telloesp32.telloReturnStatusAttitude": "dohvati iz rezultata [STATUSATTITUDE]",
    "gui.blocklyText.telloesp32.statusAttitudePitch": "Kut nagiba",
    "gui.blocklyText.telloesp32.statusAttitudeYaw": "Kut skretanja",
    "gui.blocklyText.telloesp32.statusAttitudeRoll": "Kut nagiba",
    "gui.blocklyText.telloesp32.statusAttitudeXSpeed": "Brzina osi X",
    "gui.blocklyText.telloesp32.statusAttitudeYSpeed": "Brzina osi Y",
    "gui.blocklyText.telloesp32.statusAttitudeZSpeed": "Brzina osi Z",
    "gui.blocklyText.telloesp32.statusAttitudeXAcc": "Ubrzanje X osi",
    "gui.blocklyText.telloesp32.statusAttitudeYAcc": "Ubrzanje Y osi",
    "gui.blocklyText.telloesp32.statusAttitudeZAcc": "Ubrzanje Z osi",
    "gui.blocklyText.telloesp32.statusChallengeId": "ID kartice izazova",
    "gui.blocklyText.telloesp32.telloReturnStatusNormal": "dohvati iz rezultata [STATUSNORMAL]",
    "gui.blocklyText.telloesp32.statusNormalMaxTemp": "Maksimalna temperatura",
    "gui.blocklyText.telloesp32.statusNormalLowTemp": "Najniža temperatura",
    "gui.blocklyText.telloesp32.statusNormalTof": "udaljenost",
    "gui.blocklyText.telloesp32.statusNormalHeight": "Relativna visina",
    "gui.blocklyText.telloesp32.statusNormalBat": "Trenutna baterija",
    "gui.blocklyText.telloesp32.statusNormalBaro": "Barometarska visina",
    "gui.blocklyText.telloesp32.statusNormalMotor": "Vrijeme motora",
    "gui.blocklyText.tello.telloRotateWithYaw": "Zrakoplov se okreće pod kutom [YAW] u odnosu na karticu izazova [MID]",
    "gui.blocklyText.tello.telloStopAndHover": "Zaustavi pokret i lebdite",
    "gui.blocklyText.tello.MissionPad": "Broj kartice izazova",
    "gui.blocklyText.tello.MissionPadX": "Karta izazova X (cm)",
    "gui.blocklyText.tello.MissionPadY": "Karta izazova Y (cm)",
    "gui.blocklyText.tello.telloMissionPadZ": "Karta izazova Z(cm)",
    "gui.blocklyText.tello.telloCommandResponse": "Odgovor na naredbu",
    "gui.blocklyText.tello.direction_front": "Pogled sprijeda",
    "gui.blocklyText.tello.direction_blow": "Pogled prema dolje",
    "gui.blocklyText.tello.direction_all": "svi",
    "gui.blocklyText.tello.mid": "Kartica izazova",
    "gui.blocklyText.tello.midfastest": "m-1",
    "gui.blocklyText.tello.midlatest": "m-2",
    "gui.blocklyText.tello.telloToggleMon": "[TOGGLE] Otkrivanje kartice izazova",
    "gui.blocklyText.tello.telloSetMDireaction": "Postavi položaj detekcije [POSITION]",
    "gui.blocklyText.tello.telloSetWIFI": "Promijeni Tello Wi-Fi kao pristupnu točku [NAME] zaporka [PASSWD]",
    "gui.blocklyText.tello.telloToggleGroup": "Prebaci se na način rada stanice, poveži se s pristupnom točkom [NAME] lozinka [PASSWD]",
    "gui.blocklyText.telloGroup.telloRise": "Ustati",
    "gui.blocklyText.telloGroup.telloDown": "Dolje",
    "gui.blocklyText.telloGroup.telloGoahead": "Kreni",
    "gui.blocklyText.telloGroup.telloBack": "Nazad",
    "gui.blocklyText.telloGroup.telloTurnLeft": "Lijevo",
    "gui.blocklyText.telloGroup.telloTurnRight": "Desno",
    "gui.blocklyText.telloGroup.telloSetFly": "[DIRECTION] [NUMS] cm",
    "gui.blocklyText.telloGroup.telloClockwiseRotation": "U smjeru kazaljke na satu",
    "gui.blocklyText.telloGroup.telloCounterclockwiseSelection": "Suprotno od kazaljke na satu",
    "gui.blocklyText.telloGroup.telloClockRotation": "[CLOCKWISE] Rotacija [NUMS] stupnjeva",
    "gui.blocklyText.telloGroup.setTelloIndexWithSN": "postavi Tello SN[SN] broj kao [INDEX]",
    "gui.blocklyText.telloGroup.setTelloIndexWithSSID": "postavi Tello SSID[SSID] broj kao [INDEX]",
    "gui.blocklyText.telloGroup.scanTelloInNetwork": "skeniraj [NUM] Tello u vremenskom ograničenju mreže [TIME] s",
    "gui.blocklyText.telloGroup.telloSynchronousCommand": "Vrijeme čekanja sinkrone naredbe [TIME] sekundi",
    "gui.blocklyText.tello.telloVideoToggle": "okreni Tellov video [VIDEO_STATE]",
    "gui.blocklyText.telloGroup.TEMPH": "Maksimalna temperatura",
    "gui.blocklyText.telloGroup.TEMPL": "najniža temperatura",
    "gui.blocklyText.telloGroup.NUM": "broj",
    "gui.blocklyText.telloGroup.pitch": "nagib",
    "gui.blocklyText.telloGroup.roll": "svitak",
    "gui.blocklyText.telloGroup.translation": "prijevod",
    "gui.blocklyText.telloGroup.telloAccelerationXYZ": "Ubrzanje [XYZ] (0,001g)",
    "gui.blocklyText.telloGroup.telloSpeedXYZ": "Brzina [XYZ] (cm/s)",
    "gui.blocklyText.telloGroup.TelloAttitudePitch": "[ANGEL] kut stava (°)",
    "gui.blocklyText.telloGroup.telloTempMinAndMax": "Glavna ploča [TEMP](°C)",
    "gui.blocklyText.tello.telloDisplayRollText": "Smjer teksta prikaza pomicanja [DIRECT] frekvencija [SPEED] tekst [TEXT] boja [COLOR]",
    "gui.blocklyText.tello.telloDisplayText": "Prikaz jednog znaka [TEXT] boja [COLOR]",
    "gui.blocklyText.tello.telloRollText_up": "Gore",
    "gui.blocklyText.tello.telloRollText_down": "dolje",
    "gui.blocklyText.tello.telloRollText_left": "Lijevo",
    "gui.blocklyText.tello.telloRollText_right": "Pravo",
    "gui.blocklyText.telloGroup.initTelloGroup": "Prekini vezu",
    "gui.blocklyText.tello.shutdownForPower": "Baterija je nedovoljno [POWER], Tello će se uskoro ugasiti",
    "gui.blocklyText.tello.portCantUse": "Servisna pogreška, pristup portu [PORT] nije uspio, provjeri je li zauzet drugim programima",
    "gui.blocklyText.tello.telloReadESP32Version": "verziju firmvera modula za proširenje",
    "gui.blocklyText.tello.telloSSID": "SSID modula proširenja",
    "gui.blocklyText.tello.telloLightLEDWithColor": "LED boja [COLOR]",
    "gui.blocklyText.tello.telloLightLEDWithColor_rgb": "LED boja R:[RGBR] G:[RGBG] B:[RGBB]",
    "gui.blocklyText.tello.telloBreathLEDWithColor": "LED svjetlo za disanje u boji [COLOR] frekvencija [TIME] (Hz)",
    "gui.blocklyText.tello.telloBreathLEDWithColor_rgb": "LED svjetlo za disanje R:[RGBR] G:[RGBG] B:[RGBB] frekvencija:[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor": "LED treperi boja 1[COLOR1] i boja 2[COLOR2] frekvencija [TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor_rgb": "LED treperi R1:[RGBR1] G1:[RGBG1] B1:[RGBB1] - R2:[RGBR2] G2:[RGBG2] B2:[RGBB2] frekvencija:[TIME](Hz)",
    "gui.blocklyText.tello.telloShowPattern": "Uzorak prikaza [MARK]",
    "gui.blocklyText.tello.telloShowPatternWhenStart": "Postavi uzorak pokretanja [MARK]",
    "gui.blocklyText.tello.telloShowRollPattern": "Smjer obrasca kotrljajućeg prikaza [DIRECT] frekvencija [SPEED] uzorak [MARK]",
    "gui.blocklyText.tello.telloSetLight": "Postavi svjetlinu zaslona [NUM]",
    "gui.blocklyText.tello.telloSendCMD": "Pošalji prilagođenu naredbu [CMD]",
    "gui.blocklyText.tello.telloSendDirective": "Pošalji jasnu poruku [CMD]",
    "gui.blocklyText.tello.telloReceiveMsg": "Primljena niz poruka [MSG]",
    "gui.blocklyText.tello.telloReceiveCustomNum": "Primljena prilagođena vrijednost odgovora",
    "gui.blocklyText.tello.connectTelloWIFIError": "Povezivanje na Tello Wi-Fi nije uspjelo, pokušaj se ručno povezati na Wi-Fi",
    "gui.blocklyText.tello.connectTelloError": "Povezivanje s Tellom nije uspjelo, potvrdi status uređaja i ponovno se poveži",
    "gui.blocklyText.tello.tempIsHigh": "Tello je previsoka temperatura i uskoro će se isključiti",
    "gui.blocklyText.tello.scanErrorWithOpenWlan": "Došlo je do pogreške tijekom skeniranja, provjeri je li Wi-Fi uključen, pokušaj se ručno spojiti na Wi-Fi",
    "gui.blocklyText.tello.noTelloFound": "Tello uređaj nije dostupan, pokušaj se ručno povezati na Wi-Fi",
    "gui.blocklyText.tello.connectIsBroke": "Tello veza je prekinuta, provjeri uređaj",
    "gui.blocklyText.tello.firstConnectTello": "Prvo se poveži s Tellom",
    "gui.blocklyText.tello.telloQueryWifiVersion": "Wi-Fi verzija modula proširenja",
    "gui.blocklyText.tello.telloQueryHardwareVersion": "Dohvati verziju hardvera",
    "gui.blocklyText.tello.telloToggleTOF": "[TOGGLE] TOF modul",
    "gui.blocklyText.tello.telloToggleTOF_enable": "Omogućiti",
    "gui.blocklyText.tello.telloToggleTOF_disabled": "Onemogući",
    "gui.blocklyText.tello.activeTelloFirst": "Povezivanje nije uspjelo, novi zrakoplov nije aktiviran, molimo slijedi priručnik zrakoplova za aktivaciju",
    "gui.blocklyText.tello.startPaddle": "Uđi u način rada propelera",
    "gui.blocklyText.tello.stopPaddle": "Izađi iz načina rada propelera",
    "gui.blocklyText.tello.togglePaddle": "[TOGGLE] Način veslanja",
    "gui.blocklyText.tello.telloTogglePaddle_enable": "Unesi",
    "gui.blocklyText.tello.telloTogglePaddle_disabled": "Izlaz ",
    "gui.blocklyText.tello.telloThrowAway": "Baci za 5 sekundi",
    "gui.blocklyText.tello.telloResetPattern": "Vrati zadani uzorak pokretanja",
    "gui.blocklyText.tello.telloQueryTelloVersion": "Tello verzija firmvera",
    "gui.blocklyText.tello.telloQueryBatActive": "Tello upit o razini baterije",
    "gui.blocklyText.tello.telloReadTOF": "Očitaj TOF (mm)",
    "gui.blocklyText.telloGroup.allTellos": "svi",
    "gui.blocklyText.tello.notAllowInput": "Unesi brojeve ili engleske znakove, bez drugih posebnih znakova",
    "gui.blocklyText.tello.checkFirewallAndTello": "Linija je prekinuta, provjeri status Tello veze i dodaj softver na popis dopuštenih vatrozida računala",
    "gui.blocklyText.telloGroup.checkWlanNum": "Provjeri je li stroj spojen na ispravnu mrežu, ako je spojen na više mreža, prvo odspoji ostale veze",
    "gui.blocklyText.telloGroup.telloIsNotExist": "[INDEX] Tello ne postoji",
    "gui.blocklyText.telloGroup.findTelloWithLost": "[FOUND] pronađeno, [NOTFOUND] nije pronađeno, ip:[IP]",
    "gui.blocklyText.telloGroup.findTello": "[FOUND] pronađeno, ip:[IP]",
    "gui.blocklyText.telloGroup.cantRepeatScan": "Skeniranje, molimo ne ponavljaj skeniranje...",
    "gui.blocklyText.telloGroup.setIndexForTelloFirst": "Prvo postavi broj",
    "gui.blocklyText.maixduino.programMain": "K120 Maixduino se pokreće",
    "gui.blocklyText.maixduino.LCDInit": "Inicijalizacija zaslona [FREQ] Hz i pozadine [COLOR] invert [INVERT]",
    "gui.blocklyText.maixduino.LCDShowImageFromPath": "Prikaz putanje slike: [PATH]",
    "gui.blocklyText.maixduino.LCDShowImageFromImage": "Prikaži sliku: [IMAGE]",
    "gui.blocklyText.maixduino.LCDShowText": "Prikaz riječi [TEXT] na poziciji x [X] y [Y] u [TEXT_COLOR] pozadini [BACKGROUND_COLOR]",
    "gui.blocklyText.maixduino.LCDFill": "Očisti zaslon na [COLOR] pozadinu",
    "gui.blocklyText.maixduino.LCDRotation": "Zakretanje zaslona [ANGLE]",
    "gui.blocklyText.maixduino.LCDMirror": "Ogledalo zaslona je [MIRROR]",
    "gui.blocklyText.maixduino.LCDSize": "Razlučivost zaslona [SIZE]",
    "gui.blocklyText.maixduino.LCDRGB888": "RGB888 crvena [RED] zelena [GREEN] plava [BLUE]",
    "gui.blocklyText.maixduino.LCDColor": "Boja [COLOR]",
    "gui.blocklyText.blockTitle.maixduino.Camera": "Fotoaparat",
    "gui.blocklyText.maixduino.CameraInit": "Inicijalizacija kamere [MODE] parametri [SETTINGS]",
    "gui.blocklyText.maixduino.CameraGetImage": "Fotoaparat dohvaća sliku",
    "gui.blocklyText.maixduino.CameraControl": "Odaberi kameru s dvostrukim okom [CONTROL]",
    "gui.blocklyText.maixduino.CameraHorizontalMirroring": "Zrcalo kamere-vodoravno je [CONTROL]",
    "gui.blocklyText.maixduino.CameraVerticalMirroring": "Okomito ogledalo kamere je [CONTROL]",
    "gui.blocklyText.maixduino.CameraColorBarMode": "Šarene trake fotoaparata su [CONTROL]",
    "gui.blocklyText.maixduino.CameraSettingParameters": "Kamera je postavila [PARAMETERS] kao [VALUE]",
    "gui.blocklyText.maixduino.CameraSettingWindow": "Širina prozora sklopa kamere [WIDTH] visina [HEIGHT]",
    "gui.blocklyText.maixduino.CameraGetParameters": "Dobivanje kamere [PARAMETERS]",
    "gui.blocklyText.blockTitle.audioAndVideo": "Audio i video",
    "gui.blocklyText.maixduino.AudioPlay": "Audio reprodukcija [PATH] [WAIT]",
    "gui.blocklyText.maixduino.AudioVolume": "Glasnoća zvuka [VOLUME] %",
    "gui.blocklyText.maixduino.AudioState": "Zatraži status audio reprodukcije",
    "gui.blocklyText.maixduino.AudioRecord": "Audio zapis [PATH] za [TIME] s i [WAIT]",
    "gui.blocklyText.maixduino.VideoPlay": "Video reprodukcija [PATH] [WAIT]",
    "gui.blocklyText.maixduino.VideoVolume": "Glasnoća videa [VOLUME] %",
    "gui.blocklyText.maixduino.VideoState": "Zatraži status reprodukcije videozapisa",
    "gui.blocklyText.maixduino.VideoRecord": "Video zapis [PATH] za [TIME] s i [WAIT]",
    "gui.blocklyText.maixduino.digitalRead": "Očitaj digitalni pin [PIN]",
    "gui.blocklyText.maixduino.analogRead": "Očitaj analogni pin [PIN]",
    "gui.blocklyText.maixduino.digitalWrite": "Digitalni pin [PIN] izlaz [LEVEL]",
    "gui.blocklyText.maixduino.PWMInit": "Inicijaliziraj PWM pin [PIN] freq [FREQ] Hz timer [TIMER]",
    "gui.blocklyText.maixduino.PWMWrite": "PWM pin [PIN] izlazni radni ciklus [DUTY]",
    "gui.blocklyText.maixduino.ticksDiff": "Vremenska razlika izvršenja povratka, početak u [STARTTIME] i završetak u [ENDTIME]",
    "gui.blocklyText.maixduino.runTimer": "Vrijeme izvršenja [TIMETYPE]",
    "gui.blocklyText.maixduino.gc": "[GC] sakupljanje smeća",
    "gui.blocklyText.maixduino.systemReset": "Resetiraj",
    "gui.blocklyText.maixduino.print": "Ispis [DATA]",
    "gui.blocklyText.maixduino.LevelMenu.A": "visok",
    "gui.blocklyText.maixduino.LevelMenu.B": "nizak",
    "gui.blocklyText.maixduino.MirrorMenu.A": "UKLJUČENO",
    "gui.blocklyText.maixduino.MirrorMenu.B": "ISKLJUČENO",
    "gui.blocklyText.maixduino.SelectMenu.A": "oko 0",
    "gui.blocklyText.maixduino.SelectMenu.B": "oko 1",
    "gui.blocklyText.maixduino.SettingParametersMenu.A": "kontrast",
    "gui.blocklyText.maixduino.SettingParametersMenu.B": "svjetlina",
    "gui.blocklyText.maixduino.SettingParametersMenu.C": "dohvati vrijednost",
    "gui.blocklyText.maixduino.SettingParametersMenu.D": "zasićenost",
    "gui.blocklyText.maixduino.GetParametersMenu.A": "širina rezolucije",
    "gui.blocklyText.maixduino.GetParametersMenu.B": "širina i visina rezolucije",
    "gui.blocklyText.maixduino.GetParametersMenu.C": "dohvati vrijednost",
    "gui.blocklyText.maixduino.GetParametersMenu.D": "predmemorija okvirne slike",
    "gui.blocklyText.maixduino.GetParametersMenu.E": "ID hardvera",
    "gui.blocklyText.maixduino.TimeTypeMenu.A": "s",
    "gui.blocklyText.maixduino.TimeTypeMenu.B": "ms",
    "gui.blocklyText.maixduino.TimeTypeMenu.C": "μs",
    "gui.blocklyText.maixduino.GcMenu.A": "automatski",
    "gui.blocklyText.maixduino.GcMenu.B": "početak",
    "gui.blocklyText.maixduino.LcdSizeMenu.A": "širina",
    "gui.blocklyText.maixduino.LcdSizeMenu.B": "visina",
    "gui.blocklyText.maixduino.WaitMenu.A": "čekati",
    "gui.blocklyText.maixduino.WaitMenu.B": "pozadina",
    "gui.blocklyText.maixduino.CameraModeMenu.A": "monokularan",
    "gui.blocklyText.maixduino.CameraModeMenu.B": "dalekozor",
    "gui.blocklyText.maixduino.AngleMenu.A": "0°",
    "gui.blocklyText.maixduino.AngleMenu.B": "90°",
    "gui.blocklyText.maixduino.AngleMenu.C": "180°",
    "gui.blocklyText.maixduino.AngleMenu.D": "270°",
    "gui.blocklyText.maixduino.camerainit.frameformat": "Format okvira",
    "gui.blocklyText.maixduino.camerainit.frameformat.RGB565": "boja",
    "gui.blocklyText.maixduino.camerainit.frameformat.GRAYSCALE": "siva",
    "gui.blocklyText.maixduino.camerainit.framesize": "Veličina okvira",
    "gui.blocklyText.maixduino.camerainit.imagecapture": "Snimka zaslona",
    "gui.blocklyText.maixduino.camerainit.imagecapture.on": "UKLJUČENO",
    "gui.blocklyText.maixduino.camerainit.imagecapture.off": "ISKLJUČENO",
    "gui.blocklyText.maixduino.camerainit.skipframes": "Broj preskakanja okvira:",
    "gui.extension.AI.name": "AI",
    "gui.blocklyText.AI.anchorPoint": "Parametri sidra [POINT]",
    "gui.blocklyText.AI.classNames": "Naziv objekta [NAMES]",
    "gui.blocklyText.AI.modelLoadingAddress": "Učitaj model [ADDRESS]",
    "gui.blocklyText.AI.modelLoadingPath": "Putanja modela [PATH]",
    "gui.blocklyText.AI.yolo2Init": "yolo2 inicijalizacija mrežnog modela [MODEL], prag vjerojatnosti [PT], box_iou prag [BT] sidro [APC] i parametar sidra [AP]",
    "gui.blocklyText.AI.yolo2Run": "yolo2 pokrenuti mrežni model [MODEL] slika [IMAGE]",
    "gui.blocklyText.AI.yolo2forward": "model rada yolo2 [MODEL] slika [IMAGE]",
    "gui.blocklyText.AI.yolo2Parsing": "yolo2 analitički model objekta [OBJ] i dohvati [TYPE]",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.A": "X koordinata",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.B": "Y koordinata",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.C": "IP",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.D": "Samouvjerenost",
    "gui.blocklyText.machineHearing.newModel": "ARS kreira prazan model",
    "gui.blocklyText.machineHearing.training": "ASR početne riječi [CORPUS] za dodavanje u model [MODEL]",
    "gui.blocklyText.machineHearing.run": "ARS run model [MODEL]",
    "gui.blocklyText.machineHearing.recognize": "ASR rezultat prepoznavanja govora",
    "gui.blocklyText.machineHearing.save": "ASR spremi model [MODEL] u [PATH]",
    "gui.blocklyText.machineHearing.load": "ASR model opterećenja [PATH]",
    "gui.extension.machineVision.imageBasis.name": "Strojni vid · Osnove slike",
    "gui.blocklyText.machineVision.openEmptyImage": "Otvori praznu sliku",
    "gui.blocklyText.machineVision.openPathImage": "Otvoreni put [PATH]",
    "gui.blocklyText.machineVision.clearImage": "Jasna slika [IMAGE]",
    "gui.blocklyText.machineVision.saveImage": "Spremi sliku [IMAGE] na putanju [PATH]",
    "gui.blocklyText.machineVision.modifyPixelColor": "Izmjena slike [IMAGE] koordinate xy [COORDINATE] u [COLOR]",
    "gui.blocklyText.machineVision.getPixelColor": "Dobivanje boje slike [IMAGE] koordinate xy [COORDINATE]",
    "gui.blocklyText.machineVision.captureImage": "Slika presretanja [IMAGE] area-xywh [AREA]'",
    "gui.blocklyText.machineVision.compressedImage": "Komprimiraj sliku [IMAGE] brzinom [RATIO]",
    "gui.blocklyText.machineVision.getImageInfo": "Preuzmi sliku [IMAGE] [INFO]",
    "gui.blocklyText.machineVision.conversion": "Pretvori sliku [IMAGE] u [TYPE]",
    "gui.extension.machineVision.imageDrawing.name": "Strojni vid · Crtanje slike",
    "gui.blocklyText.machineVision.drawText": "Nacrtaj tekst [IMAGE] početna točka-xy [COORDINATE] content [CONTENT] u [COLOR] veličini fonta [SIZE]",
    "gui.blocklyText.machineVision.drawImage": "Nacrtaj [IMAGE] -xy [COORDINATE] na skaliranu sliku -xy [ZOOM]",
    "gui.blocklyText.machineVision.drawLine": "Nacrtaj [IMAGE] -xyxy na sliku linije [COORDINATE] u [COLOR] težini [SIZE]",
    "gui.blocklyText.machineVision.drawArrows": "Nacrtaj [IMAGE] -xyxy sliku strelice [COORDINATE] u [COLOR] veličini [SIZE]",
    "gui.blocklyText.machineVision.drawCross": "Nacrtaj [IMAGE] -xy križ [COORDINATE] u [COLOR] extend [EXTEND] weight [SIZE]",
    "gui.blocklyText.machineVision.drawRound": "Nacrtaj [IMAGE] -xyr kružnu sliku [COORDINATE] u [COLOR] težini [SIZE] ispunjenoj [FILLING]",
    "gui.blocklyText.machineVision.drawRectangle": "Nacrtaj [IMAGE] -xywh pravokutnik [COORDINATE] u [COLOR] težini [SIZE] ispunjen [FILLING]",
    "gui.blocklyText.machineVision.drawFeaturePoints": "Nacrtaj [IMAGE] točkasti objekt [OBJ] u [COLOR] veličina [SIZE] težina [THICKNESS] ispunjeno [FILLING]",
    "gui.extension.machineVision.imageFiltering.name": "Strojni vid · Filtriranje slike",
    "gui.blocklyText.machineVision.correctionZoom": "Ispravljanje i skaliranje slike [IMAGE] s vrijednošću korekcije [CORRECTION] i vrijednošću zumiranja [ZOOM]",
    "gui.blocklyText.machineVision.histogramEqualization": "Izjednačavanje histograma slike [IMAGE] samoprilagodba [ADAPTIVE] pri kontrastu [CONTRAST]",
    "gui.blocklyText.machineVision.fuzzyFilter": "Filtar zamućenja [IMAGE] jezgra konvolucije [CONVOLUTIONKERNEL] samoprilagodba [ADAPTIVE] kompenzacija [MAKEUP]",
    "gui.blocklyText.machineVision.cartoonFilter": "Filter crtanog filma [IMAGE] izvorna razlika [ORIGINALDIFFERENCE] susjedna razlika [ADJACENTDIFFERENCE]",
    "gui.blocklyText.machineVision.imageCorrosion": "Erode [IMAGE] jezgra konvolucije [CONVOLUTIONKERNEL] vrijednost niti [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageExpansion": "Dilate [IMAGE] konvolucijska jezgra [CONVOLUTIONKERNEL] vrijednost niti [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageFill": "Ispuna slike [IMAGE] koordinata-xy [COORDINATE] u [COLOR] izvorna razlika [ORIGINALDIFFERENCE] susjedna razlika [ADJACENTDIFFERENCE] preokreni [REVERSE] clear [CLEAR]",
    "gui.blocklyText.machineVision.ImageProjection": "Okomita projekcija slike [IMAGE] je [VERTICALPROJECTION]",
    "gui.blocklyText.machineVision.ImageInversion": "Inverzija slike [IMAGE]",
    "gui.extension.machineVision.shapeRecognition.name": "Strojni vid · Prepoznavanje oblika",
    "gui.blocklyText.machineVision.straightLineAnalysis": "Objekt analize linije [OBJ] za dobivanje [INFO]",
    "gui.blocklyText.machineVision.circularAnalysis": "Zaokruži objekt analize [OBJ] da bi dobio [INFO]",
    "gui.blocklyText.machineVision.rectangleAnalysis": "Objekt analize pravokutnika [OBJ] za dobivanje [INFO]",
    "gui.blocklyText.machineVision.lineRecognition": "Prepoznavanje linije [IMAGE] area-xywh [AREA] vrijednost niti [THRESHOLD] uključuje kut [MERGEANGLE] i nagib [MERGESLOPE]",
    "gui.blocklyText.machineVision.lineSegmentRecognition": "Prepoznavanje segmenta linije [IMAGE] područje- xywh [AREA] razmak [SPACING] uključi kut [MERGEANGLE]",
    "gui.blocklyText.machineVision.circleRecognition": "Prepoznavanje kruga [IMAGE] area-xywh [AREA] vrijednost niti [THRESHOLD] min r [MINIMUM] max r [MAXIMUM] korak r [STRIDE] uključi x [COMBINEDX] i y [COMBINEDY] i r [COMBINEDR]",
    "gui.blocklyText.machineVision.rectangleRecognition": "Prepoznavanje pravokutnika [IMAGE] area- xywh [AREA] thread value [THRESHOLD]",
    "gui.extension.machineVision.colorRecognition.name": "Strojni vid · Prepoznavanje boja",
    "gui.blocklyText.machineVision.LABFromRGB888": "LAB vrijednost ovisi o RGB888 [RGB888]",
    "gui.blocklyText.machineVision.RGB888FromLAB": "RGB888 vrijednost ovisi o LAB vrijednosti [LAB]",
    "gui.blocklyText.machineVision.grayscaleFromRGB888": "Vrijednost sive ovisi o RGB888 [RGB888]",
    "gui.blocklyText.machineVision.GRB888FromGrayscale": "Vrijednost RGB888 ovisi o vrijednosti sive boje [GRAYSCALE]",
    "gui.blocklyText.machineVision.trackingAnalysis": "Prati objekt analize [OBJ] da bi dobio [INFO]",
    "gui.blocklyText.machineVision.colorAnalysis": "Objekt analize boja [OBJ] CDF [CDF] za dobivanje [INFO]",
    "gui.blocklyText.machineVision.colorPatchTracking": "Praćenje bloka boja [IMAGE] vrijednost praga LAB [THRESHOLDLAB] area-xywh [AREA] filtrirano područje [FILTEROUTAREA] filtrirani pikseli [FILTEROUTPRIMENUMBERS] uključuju [MERGE] sloj je [OVERLAPPING]",
    "gui.blocklyText.machineVision.colorRecognition": "Prepoznavanje boja [IMAGE] area-xywh [AREA]",
    "gui.extension.machineVision.dimensionCodeRecognition.name": "Strojni vid · Prepoznavanje koda",
    "gui.blocklyText.machineVision.barcodeAnalysis": "Objekt analize crtičnog kôda [OBJ] za dobivanje [INFO]",
    "gui.blocklyText.machineVision.QRCodeAnalysis": "Objekt analize QR koda [OBJ] za dobivanje [INFO]",
    "gui.blocklyText.machineVision.aprilTagAnalysis": "Objekt analize ApriTag [OBJ] za dobivanje [INFO]",
    "gui.blocklyText.machineVision.BarCodeRecognition": "Prepoznavanje crtičnog kôda [IMAGE] area-xywh [AREA]",
    "gui.blocklyText.machineVision.QRCodeRecognition": "QR kôd prepoznaje [IMAGE] area-xywh [AREA]",
    "gui.blocklyText.machineVision.AprilTagRecognition": "ApriTag prepoznaje [IMAGE] area-xywh [AREA]",
    "gui.extension.machineVision.featureRecognition.name": "Strojni vid · Prepoznavanje značajki",
    "gui.blocklyText.machineVision.featureInformation": "Objekt informaiton značajke [OBJ] dohvati [INFO]",
    "gui.blocklyText.machineVision.directionalGradienMap": "Slika usmjerenog gradijenta [IMAGE] area-xywh [AREA] dužina [LINELENGTH]",
    "gui.blocklyText.machineVision.featureRecognition": "Prepoznavanje značajki [IMAGE] area-xywh [AREA] ključne točke [KEYPOINTS] kutne točke [CORNERPOINTS] faktor mjerila [SCALEFACTOR] višestruko mjerilo je [MULTIRATIO]",
    "gui.blocklyText.machineVision.featureComparison": "Kontrast značajke Objekt1 [OBJ1] Objekt2 [OBJ2] sličnost [SIMILARITY]",
    "gui.blocklyText.machineVision.FeatureInformationMenu.A": "koordinata-xywh",
    "gui.blocklyText.machineVision.FeatureInformationMenu.B": "x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.C": "g",
    "gui.blocklyText.machineVision.FeatureInformationMenu.D": "w",
    "gui.blocklyText.machineVision.FeatureInformationMenu.E": "h",
    "gui.blocklyText.machineVision.FeatureInformationMenu.F": "centar-x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.G": "centar-y",
    "gui.blocklyText.machineVision.FeatureInformationMenu.H": "odgovarajuća vrijednost",
    "gui.blocklyText.machineVision.FeatureInformationMenu.I": "radijan",
    "gui.blocklyText.machineVision.FeatureInformationMenu.J": "podudaranje bodova-xy",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.H": "broj",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.I": "obitelj",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.J": "radijan",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.K": "x udaljenost",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.L": "y udaljenost",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.M": "z udaljenost",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.N": "x radijan",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.O": "y radijan",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.P": "z radijan",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.F": "sadržaj",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.G": "verzija",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.H": "maska",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.I": "tip",
    "gui.blocklyText.machineVision.BarcodeAnalysisMenu.H": "kut",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.A": "siva vrijednost",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.B": "LABORATORIJ L",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.C": "LABORATORIJ A",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.D": "LABORATORIJ B",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.H": "piksela",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.J": "broj",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.K": "uključiti",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.L": "područje",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.M": "odnos gustoće",
    "gui.blocklyText.machineVision.CircularInformationMenu.A": "koordinata-xyr",
    "gui.blocklyText.machineVision.CircularInformationMenu.D": "r",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.B": "x1",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.C": "y1",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.D": "x2",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.E": "y2",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.F": "dužina",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.H": "nagib",
    "gui.blocklyText.machineVision.ImageInfoMenu.A": "širina",
    "gui.blocklyText.machineVision.ImageInfoMenu.B": "visina",
    "gui.blocklyText.machineVision.ImageInfoMenu.C": "format",
    "gui.blocklyText.machineVision.ImageInfoMenu.D": "veličina",
    "gui.blocklyText.machineVision.ConversionTypeMenu.A": "Sivo",
    "gui.blocklyText.machineVision.ConversionTypeMenu.B": "Boja",
    "gui.blocklyText.machineVision.ConversionTypeMenu.C": "Duga",
    "gui.blocklyText.machineVision.ConversionTypeMenu.D": "AI",
    "gui.extension.mpyirq.name": "Prekinuti",
    "gui.blocklyText.mpyirq.event": "prekinuti pin [PIN] mod [MODE] izvršiti",
    "gui.blocklyText.mpyirq.clean": "prekinuti pin prekida [PIN]",
    "gui.blocklyText.pyTurtle.init": "Napravi crtač kornjače [NAME]",
    "gui.blocklyText.pyTurtle.turtleSetShape": "Oblik [NAME] postavljen je na [SHAPE]",
    "gui.blocklyText.pyTurtle.turtleDone": "Crtanje je gotovo, nastavi držati prozor",
    "gui.blocklyText.pyTurtle.turtleExitClick": "Pritisni za izlaz iz crtanja",
    "gui.blocklyText.pyTurtle.turtleMove": "[NAME]Premjesti[DIRECTION][DISTANCE]",
    "gui.blocklyText.pyTurtle.turtleRotate": "[NAME]Rotacija[DIRECTION][ANGLE]",
    "gui.blocklyText.pyTurtle.turtleFaceTo": "[NAME] je okrenut [ANGLE] stupnjeva",
    "gui.blocklyText.pyTurtle.turtleMoveToPos": "[NAME] se pomiče na koordinatni X[X] Y[Y] položaj",
    "gui.blocklyText.pyTurtle.turtleSetPos": "[NAME] [XY] koordinata je postavljena na [POS]",
    "gui.blocklyText.pyTurtle.turtleGetStatus": "[NAME] dobiva trenutni [STATUS]",
    "gui.blocklyText.pyTurtle.turtleClearAll": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleClone": "[NAME] Kopiraj Turtle",
    "gui.blocklyText.pyTurtle.turtleVisible": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleSetThickness": "[NAME] Postavi debljinu kista [THICK]",
    "gui.blocklyText.pyTurtle.turtleSetScale": "[NAME] Postavi veličinu ikone kornjače. Višestruko okomito rastezanje [VERTICAL] Višestruko vodoravno rastezanje [HOR] Širina obrisa [OUTLINE]",
    "gui.blocklyText.pyTurtle.turtleSetSpeed": "[NAME] postavlja brzinu kista na [SPEED]",
    "gui.blocklyText.pyTurtle.turtleSetBackground": "[NAME] je postavio boju pozadine [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetPenColor": "[NAME] Postavi boju olovke [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetFillColor": "[NAME] postavlja boju ispune kista [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetColor": "[NAME] Postavi boju kista [COLOR1] Postavi boju ispune kista [COLOR2]",
    "gui.blocklyText.pyTurtle.turtleDrawAction": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleDrawCircle": "[NAME] crta grafiku [CIRCLE] s radijusom [RADIUS]",
    "gui.blocklyText.pyTurtle.turtleDrawCircleWithAngle": "[NAME] crta kružnicu radijusa od [RADIUS] i kuta od [DEGREE]",
    "gui.blocklyText.pyTurtle.turtleInitScreen": "[NAME] Inicijaliziraj zaslon kornjače [SCREEN]",
    "gui.blocklyText.pyTurtle.turtleSaveDraw": "[NAME] sprema nacrtanu sliku kao sliku [PICTURES]",
    "gui.blocklyText.pyTurtle.turtleSetAnimationInterval": "[NAME] postavlja interval reprodukcije animacije na [TIME] milisekundi",
    "gui.blocklyText.pyTurtle.turtleDrawText": "[NAME] napiši [TEXT]",
    "gui.blocklyText.pyTurtle.turtleDrawTextWithAttr": "[NAME] Pisanje [TEXT] Olovka se pomiče s tekstom [JUDGE] Smjer poravnanja teksta [ALIGN] Font [FONT] Veličina fonta [FONTSIZE] Stil fonta [TYPE]",
    "gui.blocklyText.pyTurtle.turtleGetFloat": "Turtle dobiva uneseni broj s pomičnim zarezom. Naslov je [TITLE], a upit je [TIP] zadana vrijednost [VALUE] minimalna vrijednost [MIN] maksimalna vrijednost [MAX]",
    "gui.blocklyText.pyTurtle.turtleGetString": "Turtle dobiva ulazni niz, naslov je [TITLE], a upit je [TIP]",
    "gui.blocklyText.pyTurtle.roll_forward": "naprijed",
    "gui.blocklyText.pyTurtle.roll_backward": "nazad",
    "gui.blocklyText.pyTurtle.roll_l_left": "Lijevo",
    "gui.blocklyText.pyTurtle.roll_l_right": "Nadesno",
    "gui.blocklyText.pyTurtle.xy_x": "x",
    "gui.blocklyText.pyTurtle.xy_y": "Y",
    "gui.blocklyText.pyTurtle.status_pos": "Položaj",
    "gui.blocklyText.pyTurtle.status_shape": "Oblik",
    "gui.blocklyText.pyTurtle.status_heading": "Naslov",
    "gui.blocklyText.pyTurtle.status_width": "Širina četke",
    "gui.blocklyText.pyTurtle.status_shapesize": "Veličina ikone kornjače",
    "gui.blocklyText.pyTurtle.status_speed": "Ubrzati",
    "gui.blocklyText.pyTurtle.action_clear": "Očisti zaslon",
    "gui.blocklyText.pyTurtle.action_reset": "Resetiraj",
    "gui.blocklyText.pyTurtle.action_home": "Povratak na podrijetlo",
    "gui.blocklyText.pyTurtle.pen_action_penup": "Olovka gore",
    "gui.blocklyText.pyTurtle.pen_action_pendown": "Pendown",
    "gui.blocklyText.pyTurtle.visible_hideturtle": "Sakrij kornjaču",
    "gui.blocklyText.pyTurtle.visible_showturtle": "Prikaži Kornjaču",
    "gui.blocklyText.pyTurtle.visible_turtle": "Kornjača",
    "gui.blocklyText.pyTurtle.visible_arrow": "Strijela",
    "gui.blocklyText.pyTurtle.visible_circle": "Krug",
    "gui.blocklyText.pyTurtle.visible_square": "Blok",
    "gui.blocklyText.pyTurtle.visible_triangle": "Trokut",
    "gui.blocklyText.pyTurtle.visible_classic": "Zadano",
    "gui.blocklyText.pyTurtle.drawaction_begin": "Počni puniti",
    "gui.blocklyText.pyTurtle.drawaction_end": "Kraj Ispune",
    "gui.blocklyText.pyTurtle.circle_circle": "Krug",
    "gui.blocklyText.pyTurtle.circle_dot": "točka",
    "gui.blocklyText.pyTurtle.flag_False": "Ne",
    "gui.blocklyText.pyTurtle.flag_True": "Da",
    "gui.blocklyText.pyTurtle.align_left": "Poravnaj lijevo",
    "gui.blocklyText.pyTurtle.align_center": "Centrirano",
    "gui.blocklyText.pyTurtle.align_right": "Poravnaj desno",
    "gui.blocklyText.pyTurtle.align_normal": "Standard",
    "gui.blocklyText.pyTurtle.align_bold": "Podebljano",
    "gui.blocklyText.pyTurtle.align_italic": "Nagib",
    "gui.blocklyText.pyTurtle.align_bi": "Koso i odvažno",
    "gui.blocklyText.pyTurtle.turtleScreenSetup": "Inicijaliziraj veličinu prozora kornjače širina [WIDTH] visina [HEIGHT] pomak X [LEFT] Y [TOP]",
    "gui.blocklyText.pyTurtle.turtleScreenSize": "Inicijaliziraj platno kornjače širina [WIDTH] visina [HEIGHT] boja pozadine [COLOR]",
    "gui.blocklyText.pyGenerator.importModule": "uvoz [NAME]",
    "gui.blocklyText.pyGenerator.importAs": "uvezi [MOUDLE] kao [NAME]",
    "gui.blocklyText.pyGenerator.fromImport": "iz [FROM] uvoz [MOUDLE]",
    "gui.blocklyText.pyGenerator.annotation": "# [TEXT]",
    "gui.blocklyText.pyGenerator.defineFunction": "def [FUNCTION]([ARGUMENT]):",
    "gui.blocklyText.pyGenerator.callFunction": "Poziv funkcije [FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.callFunctionWithValue": "Poziv funkcije za dobivanje povratne vrijednosti [FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.defineClass": "razred [CLASS]:",
    "gui.blocklyText.pyGenerator.instanceClass": "[CLASS] [INSTANCE]",
    "gui.blocklyText.pyGenerator.reporterType": "Šifra [CODE]",
    "gui.blocklyText.pyGenerator.typeBoolean": "Šifra [CODE]",
    "gui.blocklyText.pyGenerator.annotationBlock": "Blokiraj komentar",
    "gui.blocklyText.pyGenerator.originCode": "Šifra [CODE]",
    "gui.extension.xiamiboard.title": "Xia Mi ploča za proširenje",
    "gui.extension.xiamiboard.setMotor": "motor [Select] pomakni [Direction] brzinom [Speed]",
    "gui.extension.xiamiboard.stopMotor": "zaustavi motor [Select]",
    "gui.extension.xiamiboard.setTrafficLights": "postavi semafor crveno [Red] žuto [Yellow] zeleno [Green]",
    "gui.extension.xiamiboard.readAngle": "očitaj senzor kuta zakretanja",
    "gui.extension.xiamiboard.readFlame": "očitaj senzor plamena",
    "gui.extension.xiamiboard.readMotorState": "očitaj motor [Select] stanje [State]",
    "gui.extension.xiamiboard.readTrafficLights": "očitaj stanje semafora [State]",
    "gui.extension.xiamiboard.readRelay": "postaviti relej [State]",
    "gui.extension.xiamiboard.readUltrasonicDistance": "očitaj ultrazvučni senzor (cm)",
    "gui.extension.xiamiboard.initializationScreen": "init OLED zaslon",
    "gui.extension.xiamiboard.screenDisplay": "OLED prikaz teksta [Text] na koordinatama x [Row] y [Column]",
    "gui.extension.xiamiboard.clearScreenAll": "jasan OLED zaslon",
    "gui.extension.xiamiboard.clearScreen": "brisanje retka OLED zaslona [Row] od stupca [ColumnFrom] do [ColumnTo]",
    "gui.extension.xiamiboard.readInfrared": "očitaj vrijednost IR tipke",
    "gui.extension.xiamiboard.receiveData": "primljeno na IR [Data]",
    "gui.extension.xiamiboard.wirelessData": "infracrveni podaci",
    "gui.extension.xiamiboard.xiamiRGB": "crvena [Red] zelena [Green] plava [Blue]",
    "gui.extension.xiamiboard.setRGBLightsRange": "Postavi osvjetljenje RGB lampe [Range] Boja zaslona [Color]",
    "gui.extension.xiamiboard.setLightsColor": "RGB LED [Num] prikazuje boju [Color]",
    "gui.extension.xiamiboard.setLightsBrightness": "postavi RGB LED svjetlinu na [Brightness]",
    "gui.extension.xiamiboard.coloseLightsAll": "isključi sve RGB LED diode",
    "gui.extension.xiamiboard.initializeST": "init [TYPE] temperaturni senzor",
    "gui.extension.xiamiboard.readSTState": "stanje [TYPE] čitanja [State]",
    "gui.blocklyText.xiamiboard.all": "svi",
    "gui.blocklyText.xiamiboard.cw": "naprijed",
    "gui.blocklyText.xiamiboard.ccw": "nazad",
    "gui.blocklyText.xiamiboard.speed": "ubrzati",
    "gui.blocklyText.xiamiboard.dir": "smjer",
    "gui.blocklyText.xiamiboard.temp": "temperatura",
    "gui.blocklyText.xiamiboard.hum": "vlažnost",
    "gui.blocklyText.xiamiboard.colorRed": "Crvena",
    "gui.blocklyText.xiamiboard.colorYellow": "žuta boja",
    "gui.blocklyText.xiamiboard.colorGreen": "zelena",
    "gui.blocklyText.xiamiboard.close": "povezan",
    "gui.blocklyText.xiamiboard.disconnect": "odspojen",
    "gui.blocklyText.xiamiboard.lightAll": "svi",
    "gui.extension.serialPort.name": "poslati podatke u način rada u stvarnom vremenu",
    "gui.extension.serialPortToAC.name": "poslati podatke u način rada za učitavanje",
    "gui.extension.serialPortjJS.name": "primati podatke o načinu prijenosa",
    "gui.extension.pyMqtt.initMqtt": "MQTT početni [SETTING]",
    "gui.extension.pyMqtt.connect": "spoji MQTT",
    "gui.extension.pyMqtt.loop_forever": "MQTT ostaje povezan, zauvijek",
    "gui.extension.pyMqtt.loopNeverForever": "MQTT ostaje povezan, vrijeme čekanja [Time] sek",
    "gui.extension.pyMqtt.disconnect": "odspoji MQTT",
    "gui.extension.pyMqtt.pushlish": "MQTT objavi [INFO] u [Topic]",
    "gui.extension.pyMqtt.subscribe": "MQTT pretplati se na [Topic]",
    "gui.extension.pyMqtt.received": "na MQTT primio [MqttInfo] od [Topic]",
    "gui.extension.pyMqtt.topic": "MQTT tema",
    "gui.extension.pyMqtt.mqttInfo": "MQTT poruka",
    "gui.blocklyText.pyMqtt.pyMqttInitPlatform": "MQTT platforma",
    "gui.blocklyText.pyMqtt.EasyIOT_Server": "EasyIoT poslužitelj",
    "gui.blocklyText.pyMqtt.EasyIOTServerChina": "EasyIoT Kina",
    "gui.blocklyText.pyMqtt.EasyIOTServerGlobal": "EasyIoT Global",
    "gui.blocklyText.pyMqtt.EasyIOT_Port": "EasyIoT priključak",
    "gui.blocklyText.pyMqtt.siotServer": "SIoT poslužitelj",
    "gui.blocklyText.pyMqtt.siotServerPort": "SIoT luka",
    "gui.blocklyText.pyMqtt.siotServerUser": "SIoT korisničko ime",
    "gui.blocklyText.pyMqtt.siotServerPass": "SIoT lozinka",
    "gui.extension.pyGame.pygameModule": "Pygame modul",
    "gui.extension.pyGame.pygameInit": "inicijalizirati sve uvezene pygame module",
    "gui.extension.pyGame.pygameQuit": "deinstaliraj sve inicijalizirane pygame module",
    "gui.extension.pyGame.displayModule": "Modul zaslona",
    "gui.extension.pyGame.displayInit": "inicijalizirati zaslonski modul",
    "gui.extension.pyGame.displayQuit": "deinstalirati inicijalizirani zaslonski modul",
    "gui.extension.pyGame.windowInit": "inicijalizirati prozor [Win] od [Width] x [Height] [Flags]",
    "gui.extension.pyGame.windowUpdate": "sučelje prozora za učitavanje (neki softveri)",
    "gui.extension.pyGame.windowSetIcon": "postavi ikonu prozora [Value]",
    "gui.extension.pyGame.windowSetTitle": "postavi naslov prozora [Value]",
    "gui.extension.pyGame.windowFlip": "ažuriranje površinskog objekta na zaslon",
    "gui.extension.pyGame.windowDraw": "izvlačenje [Suf] na [Postion] prozora [Win]",
    "gui.extension.pyGame.surfaceObject": "Površinski objekt",
    "gui.extension.pyGame.createSurface": "kreiraj Surface objekt [Surface] s [Width] x [Height]",
    "gui.extension.pyGame.blitSurface": "nacrtaj [Obj] u [Pos] površinskog objekta [Surface]",
    "gui.extension.pyGame.fillSurface": "Ispuni Surface objekt [Surface] s [Color]",
    "gui.extension.pyGame.surfaceSetAt": "postavi [Pos] piksel površinskog objekta [Surface] na [Color]",
    "gui.extension.pyGame.surfaceGetAt": "dohvati boju [Pos] piksela Surface objekta [Surface]",
    "gui.extension.pyGame.surfaceGetWidth": "dohvati Površina objekta [Surface] širina",
    "gui.extension.pyGame.surfaceGetHeight": "dohvati Površina objekta [Surface] visina",
    "gui.extension.pyGame.surfaceGetRect": "dohvati Površinski objekt [Surface] pravokutno područje",
    "gui.extension.pyGame.eventModule": "Modul događaja",
    "gui.extension.pyGame.windowEvent": "dohvati događaj prozora",
    "gui.extension.pyGame.eventType": "[Event] vrsta događaja",
    "gui.extension.pyGame.eventKey": "[Event] [Mod]",
    "gui.extension.pyGame.event": "[Event] događaj",
    "gui.extension.pyGame.eventConstant": "Tipka [Constant].",
    "gui.extension.pyGame.eventModKey": "Kombinacija tipki [Constant].",
    "gui.extension.pyGame.eventMod": "Provjeri je li pritisnuta kombinacija tipki [Keys].",
    "gui.extension.pyGame.eventOr": "[Key1]|[Key2]",
    "gui.extension.pyGame.fontModule": "Modul fonta",
    "gui.extension.pyGame.fontInit": "inicijalizirati modul fonta",
    "gui.extension.pyGame.fontQuit": "isprazni inicijalizirani modul fonta",
    "gui.extension.pyGame.createFont": "stvoriti objekt fonta [Size] [Source] iz [Font]",
    "gui.extension.pyGame.getDefaultFont": "dohvati naziv datoteke fonta na sustavu",
    "gui.extension.pyGame.getCanUsedFont": "dohvati popis dostupnih fontova na sustavu",
    "gui.extension.pyGame.generationFont": "stvoriti tekst [Text] anti-aliasing [Antialias] boja [Color] neprozirnost [Opacity] po objektu fonta [Font]",
    "gui.extension.pyGame.renderStyle": "postavi font [Font] stil [Bold] [Italics] [Underline]",
    "gui.extension.pyGame.imageModule": "Modul slike",
    "gui.extension.pyGame.imageLoad": "učitaj sliku iz [FileName]",
    "gui.extension.pyGame.supportExtension": "je li proširenje naziva datoteke u učitanoj slici podržano?",
    "gui.extension.pyGame.imageSave": "spremi [Img] kao [FileName]",
    "gui.extension.pyGame.drawModule": "Modul crtanja",
    "gui.extension.pyGame.drawRect": "nacrtaj pravokutnik bojom [Color] konfiguriraj [Rect] širinu obruba [Width] na [Win]",
    "gui.extension.pyGame.drawCircle": "nacrtaj krug bojom [Color] položaj [Rect] radijus [Size] širina granice [Width] do [Win]",
    "gui.extension.pyGame.drawLine": "crtanje crte bojom [Color] početni položaj [start_pos] krajnji položaj [end_pos] širina [Width] do [Win]",
    "gui.extension.pyGame.drawLines": "nacrtaj više linija bojom [Color] zatvoreno [Closed] položaj [Pointlist] širina [Width] do [Win]",
    "gui.extension.pyGame.mixerModule": "Modul miješalice",
    "gui.extension.pyGame.mixerInit": "inicijalizirati modul miksera",
    "gui.extension.pyGame.mixerQuit": "deinstalirati inicijalizirani modul miksera",
    "gui.extension.pyGame.createSound": "učitaj zvučni objekt iz [FileName]",
    "gui.extension.pyGame.soundPlay": "sviraj melodiju [Sound]",
    "gui.extension.pyGame.soundStop": "zaustavi melodiju [Sound]",
    "gui.extension.pyGame.soundFadeout": "postavi vrijeme [Time] (ms) da zvuk [Sound] nestane (postupno slabi dok ne nestane)",
    "gui.extension.pyGame.soundSetVolume": "postavljanje zvuka [Sound] glasnoća [Value]%",
    "gui.extension.pyGame.soundGetbusy": "ako zvuk i dalje svira?",
    "gui.extension.pyGame.timeModule": "Vremenski modul",
    "gui.extension.pyGame.getRuntime": "dohvati vrijeme izvođenja (milisekunde)",
    "gui.extension.pyGame.pauseWay": "pauza [Time] (ms) po [Way]",
    "gui.extension.pyGame.setTimer": "kreiraj mjerač vremena za [Once] [Event] događaj nakon [Time] (ms)",
    "gui.extension.pyGame.clockInit": "inicijalizirati sat [Clock]",
    "gui.extension.pyGame.updateClock": "ažuriraj sat [Clock]",
    "gui.extension.pyGame.getClockUp": "dohvati vrijeme iskorišteno u prethodnoj ljestvici [Clock]",
    "gui.extension.pyGame.getClockUpRaw": "dohvati stvarno vrijeme u prethodnoj skali [Clock]",
    "gui.extension.pyGame.getClockFps": "dohvati [Clock] za izračunavanje brzine kadrova sata",
    "gui.extension.pyGame.musicModule": "Glazbeni modul",
    "gui.extension.pyGame.musicLoad": "učitaj glazbeni objekt iz [FileName]",
    "gui.extension.pyGame.setVolume": "postavi glasnoću glazbe [Value]%",
    "gui.extension.pyGame.musicPlay": "pusti muziku",
    "gui.extension.pyGame.musicRewind": "reproducirati glazbu",
    "gui.extension.pyGame.musicPause": "pauziraj glazbu",
    "gui.extension.pyGame.musicUnpause": "ponovno pauziranje glazbe",
    "gui.extension.pyGame.musicStop": "zaustavi glazbu",
    "gui.extension.pyGame.musicFadeout": "postavi vrijeme [Time] (ms) da glazba nestane (postupno slabi dok ne nestane)",
    "gui.extension.pyGame.musicGetbusy": "Ako glazba i dalje svira?",
    "gui.extension.pyGame.colorModule": "Modul boja",
    "gui.extension.pyGame.color": "pretvori [Color] u RGB",
    "gui.blocklyText.pyGame.gain": "dobitak",
    "gui.blocklyText.pyGame.state": "država",
    "gui.blocklyText.pyGame.unicode": "Unicode",
    "gui.blocklyText.pyGame.key": "Ključ",
    "gui.blocklyText.pyGame.mod": "Mod",
    "gui.blocklyText.pyGame.pos": "poz",
    "gui.blocklyText.pyGame.rel": "Rel",
    "gui.blocklyText.pyGame.buttons": "Gumbi",
    "gui.blocklyText.pyGame.size": "veličina prozora",
    "gui.blocklyText.pyGame.w": "širina prozora",
    "gui.blocklyText.pyGame.h": "visina prozora",
    "gui.blocklyText.pyGame.once": "Jednom",
    "gui.blocklyText.pyGame.repeat": "Ponoviti",
    "gui.blocklyText.pyGame.close": "zatvoreno",
    "gui.blocklyText.pyGame.noclose": "ne-zatvoreno",
    "gui.blocklyText.pyGame.fullscreen": "PUNI ZASLON",
    "gui.blocklyText.pyGame.doublebuf": "DOUBLEBUF",
    "gui.blocklyText.pyGame.hwsurface": "HWPALETTE",
    "gui.blocklyText.pyGame.opengl": "OPENGL",
    "gui.blocklyText.pyGame.resizable": "PROMJENJIVA VELIČINA",
    "gui.blocklyText.pyGame.noframe": "NOFRAME",
    "gui.blocklyText.pyGame.none": "NIJEDAN",
    "gui.blocklyText.pyGame.external": "prilagođen",
    "gui.blocklyText.pyGame.system": "sustav",
    "gui.blocklyText.pyGame.normal": "normalan",
    "gui.blocklyText.pyGame.italics": "Kurziv",
    "gui.blocklyText.pyGame.bold": "Podebljano",
    "gui.blocklyText.pyGame.underline": "Podvlaka",
    "gui.blocklyText.pyGame.wait": "Čekati",
    "gui.blocklyText.pyGame.delay": "Odgoditi",
    "gui.blocklyText.pyGame.false": "Laž",
    "gui.blocklyText.pyGame.true": "Istina",
    "gui.blocklyText.pyGame.quit": "Prestati",
    "gui.blocklyText.pyGame.activeevent": "AKTIVNI DOGAĐAJ",
    "gui.blocklyText.pyGame.keydown": "KEYDOWN",
    "gui.blocklyText.pyGame.keyup": "KEYUP",
    "gui.blocklyText.pyGame.mousemotion": "MOUSEMOTION",
    "gui.blocklyText.pyGame.mousebuttonup": "GUMB MIŠA GORE",
    "gui.blocklyText.pyGame.mousebuttondown": "TIPKA MIŠA",
    "gui.blocklyText.pyGame.videoresize": "PROMJENA VELIČINE VIDEOZAPISA",
    "gui.blocklyText.pyGame.videoexpose": "VIDEOIZLOŽITI",
    "gui.blocklyText.pyGame.insert": "Insert",
    "gui.blocklyText.pyGame.home": "Home",
    "gui.blocklyText.pyGame.end": "End",
    "gui.blocklyText.pyGame.pageup": "Page Up",
    "gui.blocklyText.pyGame.pagedown": "Page Down",
    "gui.blocklyText.pyGame.numlock": "Num Lock",
    "gui.blocklyText.pyGame.capslock": "Caps Lock",
    "gui.blocklyText.pyGame.scrollock": "Scroll Lock",
    "gui.blocklyText.pyGame.rightshift": "Desni Shift",
    "gui.blocklyText.pyGame.leftshift": "Lijevi Shift",
    "gui.blocklyText.pyGame.rightcontrol": "Desni Ctrl",
    "gui.blocklyText.pyGame.leftcontrol": "Lijevi Ctrl",
    "gui.blocklyText.pyGame.rightalt": "Desni Alt",
    "gui.blocklyText.pyGame.leftalt": "Lijevi Alt",
    "gui.blocklyText.pyGame.rightmeta": "Desni Meta",
    "gui.blocklyText.pyGame.leftmeta": "Lijevi Meta",
    "gui.blocklyText.pyGame.leftWindowskey": "Lijevi Windows",
    "gui.blocklyText.pyGame.rightWindowskey": "Desni Windows",
    "gui.blocklyText.pyGame.modeshift": "Promjena načina rada",
    "gui.blocklyText.pyGame.help": "Pomozite",
    "gui.blocklyText.pyGame.printscreen": "Ispiši ekran",
    "gui.blocklyText.pyGame.sysrq": "sysrq",
    "gui.blocklyText.pyGame.break": "pauza",
    "gui.blocklyText.pyGame.menu": "izbornik",
    "gui.blocklyText.pyGame.power": "power",
    "gui.blocklyText.pyGame.Euro": "Euro",
    "gui.blocklyText.pyGame.delete": "delete",
    "gui.blocklyText.pyGame.backspace": "backspace",
    "gui.blocklyText.pyGame.tab": "tab",
    "gui.blocklyText.pyGame.clear": "clear",
    "gui.blocklyText.pyGame.return": "return",
    "gui.blocklyText.pyGame.pause": "pause",
    "gui.blocklyText.pyGame.escape": "ESC",
    "gui.blocklyText.pyGame.space": "space",
    "gui.blocklyText.pyGame.quotedbl": "quotedbl",
    "gui.blocklyText.pyGame.quote": "quote",
    "gui.blocklyText.pyGame.NONE": "Nijedan",
    "gui.blocklyText.pyGame.LSHIFT": "LShift",
    "gui.blocklyText.pyGame.RSHIFT": "RShift",
    "gui.blocklyText.pyGame.SHIFT": "Shift",
    "gui.blocklyText.pyGame.LCTRL": "LCtrl",
    "gui.blocklyText.pyGame.RCTRL": "RCtrl",
    "gui.blocklyText.pyGame.CTRL": "Ctrl",
    "gui.blocklyText.pyGame.LALT": "LAlt",
    "gui.blocklyText.pyGame.RALT": "RAlt",
    "gui.blocklyText.pyGame.ALT": "Alt",
    "gui.blocklyText.pyGame.LMETA": "LMeta",
    "gui.blocklyText.pyGame.RMETA": "Rmeta",
    "gui.blocklyText.pyGame.META": "Meta",
    "gui.blocklyText.pyGame.CAPS": "capslock",
    "gui.blocklyText.pyGame.NUM": "numlock",
    "gui.blocklyText.pyGame.MODE": "AltGr",
    "gui.extension.pinpongBase.pinpongInit": "pinpong inicijaliziraj ploču [Board] port [Port]",
    "gui.extension.pinpongBase.pinInit": "[Instance] inicijalizacija pina [Pin] mod [Mod]",
    "gui.extension.pinpongBase.readPinValue": "[Instance] čita digitalnu vrijednost",
    "gui.extension.pinpongBase.setPinValue": "[Instance] postavi vrijednost digitalnog izlaza [Value]",
    "gui.extension.pinpongBase.readAnalogValue": "[Instance] čita analognu vrijednost",
    "gui.extension.pinpongBase.setAnalogValue": "[Instance] postavi vrijednost analognog izlaza (PWM) [Value]",
    "gui.blocklyText.pinpongBase.uno": "Arduino UNO",
    "gui.blocklyText.pinpongBase.leonardo": "Arduino Leonardo",
    "gui.blocklyText.pinpongBase.nano": "Arduino Nano",
    "gui.blocklyText.pinpongBase.mega1280": "Arduino Mega1280",
    "gui.blocklyText.pinpongBase.mega2560": "Arduino Mega2560",
    "gui.blocklyText.pinpongBase.microbit": "micro:bit",
    "gui.blocklyText.pinpongBase.handpy": "mPython",
    "gui.blocklyText.pinpongBase.xugu": "xugu",
    "gui.blocklyText.pinpongBase.rpi": "Raspberry Pi",
    "gui.blocklyText.pinpongBase.auto": "auto",
    "gui.blocklyText.pinpongBase.unihiker": "unihiker",
    "gui.blocklyText.pinpongBase.firebeetle_esp32": "Firebeetle ESP32",
    "gui.blocklyText.pinpongBase.firebeetle_esp8266": "Firebeetle ESP8266",
    "gui.blocklyText.pinpongBase.lp": "LattePanda",
    "gui.blocklyText.pinpongBase.esp32": "ESP32",
    "gui.blocklyText.pinpongBase.esp8266": "ESP8266",
    "gui.blocklyText.pinpongBase.nezha": "nezha",
    "gui.blocklyText.pinpongBase.JH7100": "JH7100",
    "gui.blocklyText.pinpongBase.win": "pobijediti",
    "gui.blocklyText.pinpongBase.out": "digitalni izlaz",
    "gui.blocklyText.pinpongBase.in": "digitalni ulaz",
    "gui.blocklyText.pinpongBase.analog": "analogni ulaz",
    "gui.blocklyText.pinpongBase.pwm": "analogni izlaz",
    "gui.blocklyText.pinpongBase.null": "nikakav",
    "gui.blocklyText.pinpongServo.name": "servo",
    "gui.extension.pinpongServo.servoInit": "servo [Servo] inicijalizirati pin [Pin]",
    "gui.extension.pinpongServo.setServo": "servo [Servo] rotirati [Deg] stupnjeva",
    "gui.blocklyText.PinpongBoard.name": "PinPong rastezljiva ploča",
    "gui.extension.PinpongBoard.connectWifi": "PinPong ploča za proširenje spaja se na pristupnu točku [ssid] lozinka [password] do uspjeha",
    "gui.extension.PinpongBoard.getIp": "dohvati IP adresu",
    "gui.extension.PinpongBoard.TCPInit": "PinPong ploča init ip [ip] port 8081",
    "gui.extension.PinpongBoard.USBInit": "PinPong ploča za proširenje init USB priključak [port]",
    "gui.blocklyText.PinpongBoard.ledControl": "LED kontrola",
    "gui.extension.PinpongBoard.LEDInit": "pin početni D2 mod IZLAZ",
    "gui.extension.PinpongBoard.LEDSetValue": "postaviti vrijednost PIN broja [value]",
    "gui.blocklyText.PinpongBoard.buttonControl": "upravljanje gumbom",
    "gui.extension.PinpongBoard.buttonInit": "pin početni [PIN] mod ULAZ",
    "gui.extension.PinpongBoard.buttonGetVlue": "gumb dohvaća [PIN] vrijednost",
    "gui.blocklyText.PinpongBoard.irControl": "njihova kontrola",
    "gui.extension.PinpongBoard.irInit": "iRRecv ​​početni pin 3 [Event]",
    "gui.blocklyText.PinpongBoard.toneControl": "kontrola tona",
    "gui.extension.PinpongBoard.toneInit": "početni ton",
    "gui.extension.PinpongBoard.toneSetFreq": "postavljena frekvencija tona [Freq] Hz",
    "gui.extension.PinpongBoard.toneGetFreq": "ton dohvaća frekv",
    "gui.extension.PinpongBoard.toneSwitch": "ton [switch]",
    "gui.extension.PinpongBoard.keep": "postaviti frekvenciju tona [Freq] zadržati [Time]",
    "gui.blocklyText.PinpongBoard.RGBControl": "RGB kontrola",
    "gui.extension.PinpongBoard.RGBInit": "Inicijaliziraj RGB svjetlo",
    "gui.extension.PinpongBoard.setRGB": "postavi RGB boju [Color]",
    "gui.extension.PinpongBoard.setRGBBrightness": "postavi RGB svjetlinu [BRIGHTNESS]",
    "gui.extension.PinpongBoard.closeRGB": "bliska RGB boja",
    "gui.blocklyText.PinpongBoard.angleSensor": "kontrola senzora kuta",
    "gui.extension.PinpongBoard.angleInit": "init senzor kuta",
    "gui.extension.PinpongBoard.getAngleValue": "dohvati vrijednost kuta",
    "gui.blocklyText.PinpongBoard.lightSensor": "kontrola senzora svjetla",
    "gui.extension.PinpongBoard.lightInit": "init svjetlosni senzor",
    "gui.extension.PinpongBoard.getLightValue": "dohvati svjetlosnu vrijednost",
    "gui.blocklyText.PinpongBoard.OLEDscreen": "oled12864 kontrola",
    "gui.extension.PinpongBoard.oled12864Init": "početni oled12864",
    "gui.extension.PinpongBoard.oled12864ShowString": "oled [X] prikaži niz [String]",
    "gui.extension.PinpongBoard.oled12864ShowStringXY": "oled X:[X] Y:16*[Y] prikaži niz [String]",
    "gui.extension.PinpongBoard.oledCal": "OLED kal. podaci [TEXT] [BIT] bit",
    "gui.extension.PinpongBoard.oled12864Fill": "oled12864 ispuni [Option]",
    "gui.blocklyText.PinpongBoard.aht20": "aht20 senzorska kontrola",
    "gui.extension.PinpongBoard.aht20Init": "početna vlažnost",
    "gui.extension.PinpongBoard.getTempValue": "dohvati vrijednost temp",
    "gui.extension.PinpongBoard.getHumiValue": "dohvati humi vrijednost",
    "gui.blocklyText.PinpongBoard.mpu6050": "kontrola senzora mpu6050",
    "gui.extension.PinpongBoard.mpu6050Init": "mpu6050 početni",
    "gui.extension.PinpongBoard.getAccValue": "dohvati acc [Option] vrijednost",
    "gui.extension.PinpongBoard.getGyroValue": "dohvati vrijednost žiroskopa [Option].",
    "gui.blocklyText.PinpongBoard.motor": "upravljanje motorom",
    "gui.extension.PinpongBoard.motorInit": "motorički početni",
    "gui.blocklyText.PinpongBoard.black": "crno",
    "gui.blocklyText.PinpongBoard.white": "bijela",
    "gui.blocklyText.PinpongBoard.high": "visoka razina",
    "gui.blocklyText.PinpongBoard.low": "niska razina",
    "gui.blocklyText.PinpongBoard.on": "na",
    "gui.blocklyText.PinpongBoard.off": "isključeno",
    "gui.blocklyText.pinpongIrq.name": "prekinuti",
    "gui.extension.pinpongIrq.setIrq": "postavi pin [Pin] prekid [Trigger] funkcija povratnog poziva [Handler]",
    "gui.extension.pinpongIrq.INTevent": "kada je funkcija povratnog poziva [Event] pokrenula parametar [Pin]",
    "gui.extension.pinpongIrq.pinInfo": "pin",
    "gui.blocklyText.pinpongIrq.rising": "uzlazni rub",
    "gui.blocklyText.pinpongIrq.falling": "padajući rub",
    "gui.blocklyText.pinpongIrq.change": "promjena razine",
    "gui.blocklyText.pinpongTone.name": "zujalica",
    "gui.extension.pinpongTone.toneInit": "zujalica [Tone] inicijalizacija pin [Pin]",
    "gui.extension.pinpongTone.setToneFreq": "postavljanje zujalice [Tone] frekvencija [Freq]",
    "gui.extension.pinpongTone.readToneFreq": "čitanje zujalice [Tone] frekvencija",
    "gui.extension.pinpongTone.onTone": "uključi zujanje [Tone]",
    "gui.extension.pinpongTone.offTone": "isključi zujalicu [Tone]",
    "gui.extension.pinpongTone.keep": "zujalica [Tone] nastavlja zvučati [Time] ms na frekvenciji [Freq]",
    "gui.blocklyText.pinpongSr04_urm10.name": "ultrazvučni",
    "gui.extension.pinpongSr04_urm10.sr04_urm10Init": "ultrazvučni senzor [Sr04_urm10] inicijalizacija trig pin [Triger] echo pin [Echo]",
    "gui.extension.pinpongSr04_urm10.readSr04_urm10": "očitavanje ultrazvučnog senzora [Sr04_urm10] udaljenost (cm)",
    "gui.extension.pinpongDht.dhtInit": "senzor temperature i vlage [Dht] model [DhtMod] inicijalizacijski pin [Pin]",
    "gui.extension.pinpongDht.read": "[Dht] očitaj [Type]",
    "gui.extension.pinpongDht.Temperature": "temperatura (℃)",
    "gui.extension.pinpongDht.Humidity": "vlažnost (%rh)",
    "gui.blocklyText.pinpongNeopixel.name": "RGB LED traka",
    "gui.extension.pinpongNeopixel.neopixelInit": "RGB LED [Np] inicijalizacija pina [Pin] broj LED-a [Num]",
    "gui.extension.pinpongNeopixel.setNeopixel": "[Np] postavi LED [No] da prikazuje boju [Color]",
    "gui.extension.pinpongNeopixel.color": "crvena [Red] zelena [Green] plava [Blue]",
    "gui.extension.pinpongOled12864.oled12864Init": "OLED zaslon [Oled] inicijalizacija širine [Width] Visina [Height]",
    "gui.extension.pinpongOled12864.oled12864Fill": "[Oled] cijeli zaslon [Option]",
    "gui.extension.pinpongOled12864.oled12864ShowString": "[Oled] prikaži tekst [String] na x [X] y [Y]",
    "gui.extension.pinpongOled12864.show": "[Oled] postavke zaslona stupaju na snagu",
    "gui.blocklyText.pinpongOled12864.display": "ispuniti",
    "gui.blocklyText.pinpongOled12864.hide": "sakriti",
    "gui.extension.pinpongDS18B20.ds18b20Init": "senzor temperature [Ds18b20] pin za inicijalizaciju [Pin]",
    "gui.extension.pinpongDS18B20.readCelsius": "očitavanje [Ds18b20] temperatura (°C)",
    "gui.extension.pinpongMlx90614.mlx90614Init": "MLX90614 init [IRT] I2C adresa [ADDR]",
    "gui.extension.pinpongMlx90614.read": "mlx90614 [OBJ] očitaj [TYPE] [UINT]",
    "gui.extension.pinpongBmp388.BMP388Init": "BMP388 [BMP] poč I2C adresa 0x77",
    "gui.extension.pinpongBmp388.read": "BMP388[BMP]čitanje[TYPE]",
    "gui.blocklyText.pinpongBmp388.altitude": "nadmorska visina (m)",
    "gui.blocklyText.pinpongBmp388.pressure": "tlak (Pa)",
    "gui.blocklyText.pinpongBmp388.temperature": "temperatura (℃)",
    "gui.blocklyText.pinpongIRRecv.name": "IR prijemnik",
    "gui.extension.pinpongIRRecv.iRRecvInit": "IR prijemnik [IRRecv] inicijalizacija pina [Pin] funkcija povratnog poziva [Event]",
    "gui.extension.pinpongIRRecv.onReceive": "kada funkcija povratnog poziva [Event] primi [Data]",
    "gui.extension.pinpongIRRecv.data": "infracrveni signal",
    "gui.blocklyText.obloqMp.WhenReceivedData": "kada je primljen [MSG] od [EventType].",
    "gui.blocklyText.obloqMp.ObloqEventType": "Vrste MQTT događaja",
    "gui.blocklyText.obloqMp.ObloqMsg": "MQTT poruka",
    "gui.blocklyText.obloqMp.initialParameter": "obloq mqtt inicijalizacija: [PARAMETER] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloqMp.initialI2CParameter": "Obloq mqtt početni parametar [PARAMETER] sučelje I2C funkcija povratnog poziva [Event]",
    "gui.blocklyText.obloqMp.subscribe": "Pretplati se na Obloq mqtt [TOPIC]",
    "gui.blocklyText.obloqMp.sendmsgToCloudPlatform": "Obloq pošalji poruku [MSG] platformi u oblaku [TOPIC]",
    "gui.blocklyText.obloqMp.whenDebugInfo": "Obloq dohvati [DEBUG] [ERROR]",
    "gui.blocklyText.obloqMp.DEBUG": "Informacije o otklanjanju pogrešaka",
    "gui.blocklyText.obloqMp.ERROR": "Kod pogreške",
    "gui.blocklyText.obloqMp.httpInitialParameter": "Obloq http inicijalizacija: [PARAMETER] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloqMp.httpInitialI2CParameter": "Obloq http početni parametar [PARAMETER] sučelje I2C",
    "gui.blocklyText.obloqMp.httpGet": "Obloq http(get) url: [URL] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.wifiGetLocalIP": "Preuzmi Wi-Fi konfiguraciju [CONFIGURATION]",
    "gui.blocklyText.obloqMp.getWiFiLocalIP": "IP adresa",
    "gui.blocklyText.obloqMp.wifi": "Wi-Fi",
    "gui.blocklyText.obloqMp.SSID": "Wi-Fi naziv",
    "gui.blocklyText.obloqMp.PWD": "Lozinka za Wi-Fi",
    "gui.blocklyText.obloqMp.iot": "Platforma",
    "gui.blocklyText.obloqMp.EasyIOT_Server": "EasyIot",
    "gui.blocklyText.obloqMp.EasyIOTServerChina": "Kina",
    "gui.blocklyText.obloqMp.EasyIOTServerGlobal": "Globalno",
    "gui.blocklyText.obloqMp.Port": "Port",
    "gui.blocklyText.obloqMp.Username": "Korisničko ime",
    "gui.blocklyText.obloqMp.Password": "Zaporka",
    "gui.blocklyText.obloqMp.siotServer": "SiotIot",
    "gui.blocklyText.serialPort.whenReceivedData": "Kada se primi poruka serijskog priključka [MESSAGE] [VALUE]",
    "gui.blocklyText.serialPort.value": "Vrijednost",
    "gui.blocklyText.serialPort.readSerialData": "Pročitaj vrijednost [MESSAGE]",
    "gui.blocklyText.serialPort.serialPortSendMessage": "Slanje poruka serijskog priključka [MESSAGE] vrijednost [VALUE]",
    "gui.extension.pyAutoGui.pyAutoGuiModule": "pyautogui brzo postavljanje",
    "gui.extension.pyAutoGui.init": "inicijalizirati pyautogui",
    "gui.extension.pyAutoGui.getPosition": "dohvati koordinate miša ([X], [Y])",
    "gui.extension.pyAutoGui.getSize": "dohvati razlučivost zaslona ([Width], [Height])",
    "gui.extension.pyAutoGui.height": "visina",
    "gui.extension.pyAutoGui.width": "širina",
    "gui.extension.pyAutoGui.onScreen": "ako koordinate ([X],[Y]) na zaslonu",
    "gui.extension.pyAutoGui.setDelay": "PyAutoGUI poziv [Delay]",
    "gui.extension.pyAutoGui.setFailSafe": "[Switch] sigurni način rada",
    "gui.extension.pyAutoGui.mouse": "područje funkcije miša",
    "gui.extension.pyAutoGui.mouseMoveToOrRel": "pomakni miš na [Attribute] ([X], [Y]) kašnjenje [Seconds] sekunde postavi [Style]",
    "gui.extension.pyAutoGui.mouseDragToOrRel": "pomakni miša na [Attribute] ([X], [Y]) odgoda [Seconds] klik [Key]",
    "gui.extension.pyAutoGui.mouseClick": "pomakni miš na ([X], [Y]) klikni [Key] [Frequency] puta u intervalu [Seconds] sekundi",
    "gui.extension.pyAutoGui.mouseDownOrUp": "pomakni miša na ([X],[Y]) [Action] [Key]",
    "gui.extension.pyAutoGui.mouseScroll": "pomicanje mišem [NUM]",
    "gui.extension.pyAutoGui.keyboard": "područje funkcija tipkovnice",
    "gui.extension.pyAutoGui.keyboardWrite": "Izlaz tipkovnice [String] svakih [NUM] sekundi",
    "gui.extension.pyAutoGui.keyboardDownOrUp": "tipkovnica klikni [Key] [NUM] puta",
    "gui.extension.pyAutoGui.keyboardPress": "[Button][Key]",
    "gui.extension.pyAutoGui.keyboardHold": "drži [Key]",
    "gui.extension.pyAutoGui.keyboardHotkey": "pritisni prečac (kombinacija) [Key]",
    "gui.extension.pyAutoGui.message": "područje funkcije okvira za poruke",
    "gui.extension.pyAutoGui.messageSetting": "prikaz okvira s porukom [SETTING]",
    "gui.extension.pyAutoGui.image": "područje funkcije slike",
    "gui.extension.pyAutoGui.cbImg": "spremi podatke slike zaslona [Pos] kao [Screen] i vratite objekt",
    "gui.extension.pyAutoGui.getRgb": "dohvati RGB od [Obj] ([X], [Y])",
    "gui.extension.pyAutoGui.getPixel": "dohvati RGB zaslona ([X], [Y])",
    "gui.extension.pyAutoGui.pixelMatchesColor": "ako RGB na zaslonu ([X], [Y]) odgovara [Rgb] s rasponom +-[Tolerance]",
    "gui.extension.pyAutoGui.locateOnScreen": "dohvati koordinate [Image] [Setting] na ekranu u sivim tonovima [Grayscale]",
    "gui.extension.pyAutoGui.locateOn": "dohvati koordinate od dohvati [Image] [Setting] na određenom [Screen] podudaranje sivih tonova [Grayscale]",
    "gui.extension.pyAutoGui.imgCenter": "dohvati [Image] središnje koordinate",
    "gui.blocklyText.pyAutoGui.messageSetting": "prikaz okvira s porukom [SETTING]",
    "gui.blocklyText.pyAutoGui.platform": "vrsta okvira za poruke",
    "gui.blocklyText.pyAutoGui.simpleMessageBox": "jednostavan okvir za poruke",
    "gui.blocklyText.pyAutoGui.messageBox": "okvir za poruke",
    "gui.blocklyText.pyAutoGui.inputMessageBox": "okvir s porukom tipa unosa",
    "gui.blocklyText.pyAutoGui.inputPasswordMessageBox": "okvir s lozinkom",
    "gui.blocklyText.pyAutoGui.title": "titula",
    "gui.blocklyText.pyAutoGui.text": "tekst",
    "gui.blocklyText.pyAutoGui.okButton": "potvrdi naziv gumba",
    "gui.blocklyText.pyAutoGui.cancelButton": "naziv gumba za otkazivanje",
    "gui.blocklyText.pyAutoGui.inputDefault": "zadana vrijednost okvira za poruke",
    "gui.blocklyText.pyAutoGui.encrypted": "šifrirano",
    "gui.blocklyText.pyAutoGui.false": "onemogućiti",
    "gui.blocklyText.pyAutoGui.true": "omogućiti",
    "gui.blocklyText.pyAutoGui.None": "nikakav",
    "gui.blocklyText.pyAutoGui.mouseLeft": "lijevi gumb",
    "gui.blocklyText.pyAutoGui.mouseMiddle": "srednje dugme",
    "gui.blocklyText.pyAutoGui.mouseRight": "desni gumb",
    "gui.blocklyText.pyAutoGui.INQUAD": "počni polako",
    "gui.blocklyText.pyAutoGui.OUTQUAD": "stani polako",
    "gui.blocklyText.pyAutoGui.INOUTQUAD": "počni i polako stani",
    "gui.blocklyText.pyAutoGui.INBOUNCE": "uska elastična traka",
    "gui.blocklyText.pyAutoGui.OUTELASTIC": "široka elastična traka",
    "gui.blocklyText.pyAutoGui.ActionDown": "pritisnut",
    "gui.blocklyText.pyAutoGui.ActionUp": "pušten na slobodu",
    "gui.blocklyText.pyAutoGui.DirDown": "dolje",
    "gui.blocklyText.pyAutoGui.DirUp": "gore",
    "gui.blocklyText.pyAutoGui.ABS": "apsolutni položaj",
    "gui.blocklyText.pyAutoGui.REL": "relativni položaj",
    "gui.extension.pyRequests.request": "zahtjev",
    "gui.extension.pyRequests.requestGet": "napraviti zahtjev za dobivanje [Url]",
    "gui.extension.pyRequests.requestPost": "napraviti [Data] post zahtjev [Url]",
    "gui.extension.pyRequests.getStatusCode": "dohvati [ReqObj] kod zahtjeva",
    "gui.extension.pyRequests.raiseForStatus": "provjeri je li [ReqObj] zahtjev uspješan",
    "gui.extension.pyRequests.apparentEncoding": "dohvati [ReqObj] pravi kod",
    "gui.extension.pyRequests.setEncoding": "postavi kod [ReqObj] na [Code]",
    "gui.extension.pyRequests.handleHTML": "raščlani [ReqObj] sadržaj zahtjeva kao [Option]",
    "gui.extension.pyRequests.handleHeader": "dohvati [ReqObj] glavu",
    "gui.extension.pyRequests.getValue": "dohvati vrijednost [Key] u [Item]",
    "gui.blocklyText.pyRequests.text": "format teksta",
    "gui.blocklyText.pyRequests.json": "JSON format",
    "gui.blocklyText.pyRequests.content": "binarni format",
    "gui.extension.pyJson.dumpName": "kodirati",
    "gui.extension.pyJson.jsonDump": "kodiraj Json podatke [Data] i spremi u [FileObj] ASCII escape [Switch]",
    "gui.extension.pyJson.jsonDumps": "kodirati Json podatke [Data] u string ASCII izlaz [Switch]",
    "gui.extension.pyJson.loadName": "dekodirati",
    "gui.extension.pyJson.jsonload": "dekodirati objekt Json datoteke [FileObj] u Python objekt [PyObj]",
    "gui.extension.pyJson.jsonloads": "dekodirati Json podatke [Data] u Python objekt [PyObj]",
    "gui.extension.pyJson.valueName": "vrijednost",
    "gui.extension.pyJson.get": "dohvati vrijednost [PyObj] [Key]",
    "gui.extension.pyJson.set": "postavi vrijednost [PyObj] [Key] na [Value]",
    "gui.extension.pySchedule.createJobTitle": "stvoriti zadatak",
    "gui.extension.pySchedule.createJob": "kreiraj zadatak [JobName] koji se pokreće svakih [Num] [TimeType]",
    "gui.extension.pySchedule.createOneJob": "kreiraj zadatak [JobName] koji se pokreće svakih [WEEKTYPES]",
    "gui.extension.pySchedule.createRandomJob": "kreiraj zadatak [JobName] koji se pokreće jednom nasumično svakih [Num]-[LastNum] [TimeType]",
    "gui.extension.pySchedule.setJobExactTime": "postavi vrijeme početka [Time] zadatka [JobName]",
    "gui.extension.pySchedule.setJobTags": "postavi [Tags] zadatka [JobName]",
    "gui.extension.pySchedule.setJobUntil": "postavi vrijeme završetka [Until] zadatka [JobName]",
    "gui.extension.pySchedule.setJobFunction": "postavi zadatak [JobName] pokrenutu metodu [Function] (argumenti [Args])",
    "gui.extension.pySchedule.taskManageTitle": "upravljanje zadacima",
    "gui.extension.pySchedule.cancelJob": "poništi zadatak [JobName]",
    "gui.extension.pySchedule.getAllJob": "dohvati sve zadatke koji ispunjavaju uvjet [Tags]",
    "gui.extension.pySchedule.cancelAllJob": "poništi sve zadatke koji ispunjavaju uvjet [Tag]",
    "gui.extension.pySchedule.getNextJobTime": "dohvati vrijeme(a) sljedećeg zadatka",
    "gui.extension.pySchedule.runAllJob": "pokrenuti sve zadatke",
    "gui.extension.pySchedule.runAllJobToo": "dohvati rezultate svih zadataka, postavi interval zadataka na [Num] s",
    "gui.blocklyText.pySchedule.WEEKS": "tjedan",
    "gui.blocklyText.pySchedule.DAYS": "dan",
    "gui.blocklyText.pySchedule.HOURS": "sat",
    "gui.blocklyText.pySchedule.MINUTES": "minuta",
    "gui.blocklyText.pySchedule.SECONDS": "drugi",
    "gui.blocklyText.pySchedule.monday": "ponedjeljak",
    "gui.blocklyText.pySchedule.tuesday": "utorak",
    "gui.blocklyText.pySchedule.wednesday": "srijeda",
    "gui.blocklyText.pySchedule.thursday": "četvrtak",
    "gui.blocklyText.pySchedule.friday": "petak",
    "gui.blocklyText.pySchedule.saturday": "subota",
    "gui.blocklyText.pySchedule.sunday": "nedjelja",
    "gui.extension.pyXlrd.init": "Pročitaj datoteku",
    "gui.extension.pyXlrd.openWorkbook": "otvori Excel radnu knjigu [File] kao [FileObj]",
    "gui.extension.pyXlrd.tableTitle": "Radni list",
    "gui.extension.pyXlrd.getSheets": "dohvati sve radne listove [FileObj]",
    "gui.extension.pyXlrd.getSheetsName": "dohvati sve nazive radnih listova [FileObj]",
    "gui.extension.pyXlrd.selectSheet": "dohvati [Screen] od [FileObj] kao objekt radnog lista [TableObj] od [Value]",
    "gui.extension.pyXlrd.rowTitle": "Red",
    "gui.extension.pyXlrd.getRowNum": "dohvati broj redaka u [TableObj]",
    "gui.extension.pyXlrd.getRow": "dohvati informacije u retku [Num] od stupca [StartNum] do [EndNum] od [TableObj]",
    "gui.extension.pyXlrd.getGenerator": "povratni generator za ponavljanje kroz svaki red u [TableObj]",
    "gui.extension.pyXlrd.getRowType": "dohvati vrstu podataka u retku [Num] od stupca [StartNum] do [EndNum] od [TableObj]",
    "gui.extension.pyXlrd.getRowValue": "dohvati vrijednost podataka u retku [Num] od stupca [StartNum] do [EndNum] od [TableObj]",
    "gui.extension.pyXlrd.getRowLen": "dohvati broj stupaca u retku [Num] od [TableObj]",
    "gui.extension.pyXlrd.colTitle": "Stupac",
    "gui.extension.pyXlrd.getColNum": "dohvati broj stupaca u [TableObj]",
    "gui.extension.pyXlrd.getCol": "dohvati informacije u stupcu [Num] od retka [StartNum] do [EndNum] [TableObj]",
    "gui.extension.pyXlrd.getColType": "dohvati vrstu podataka u stupcu [Num] od retka [StartNum] do [EndNum] od [TableObj]",
    "gui.extension.pyXlrd.getColValue": "dohvati vrijednost podataka u stupcu [Num] od retka [StartNum] do [EndNum] od [TableObj]",
    "gui.extension.pyXlrd.cellTitle": "Ćelija",
    "gui.extension.pyXlrd.getCell": "dohvati objekt ćelije [CellObj] u retku [RowNum] i stupcu [ColNum] od [TableObj]",
    "gui.extension.pyXlrd.getCellType": "dohvati vrstu ćelije u retku [RowNum] i stupcu [ColNum] od [TableObj]",
    "gui.extension.pyXlrd.getCellValue": "dohvati vrijednost ćelije u retku [RowNum] i stupcu [ColNum] od [TableObj]",
    "gui.extension.pyXlrd.optionIndex": "indeks",
    "gui.extension.pyXlrd.optionName": "Ime",
    "gui.extension.pyXlwt.init": "Stvori datoteku",
    "gui.extension.pyXlwt.createWorkbook": "izradi Excel datoteku [FileObj] i postavi kodiranje datoteke [Encoding]",
    "gui.extension.pyXlwt.addSheet": "dodaj radni list [SheetName] u datoteku [FileObj], prekrij [Bool] i vrati [SheetObj]",
    "gui.extension.pyXlwt.saveWorkbook": "spremi Excel datoteku [FileObj] u [Path]",
    "gui.extension.pyXlwt.editTable": "Uredi Excel radni list",
    "gui.extension.pyXlwt.createFontStyle": "kreiraj stil fonta [FontObj]",
    "gui.extension.pyXlwt.setFontBaseStyle": "postavi font [FontObj] na zajednički stil [Style][Value]",
    "gui.extension.pyXlwt.setFontSpecialStyle": "postavi font [FontObj] na poseban stil [Style]",
    "gui.extension.pyXlwt.createBorderStyle": "izradi stil obruba [BorderObj]",
    "gui.extension.pyXlwt.setBorderBaseStyle": "postavi granicu [BorderObj] na zajednički stil [Style] [Value]",
    "gui.extension.pyXlwt.createAlignmentStyle": "izradi stil poravnanja [AlignmentObj]",
    "gui.extension.pyXlwt.setAlignmentHorzStyle": "postavi stil poravnanja [AlignmentObj] na horizontalno [HorzStyle]",
    "gui.extension.pyXlwt.setAlignmentVertStyle": "postavi stil poravnanja [AlignmentObj] na okomito [VertStyle]",
    "gui.extension.pyXlwt.setAlignmentWrap": "postavi stil poravnanja [AlignmentObj] i prijelom riječi [Bool]",
    "gui.extension.pyXlwt.createStyle": "stvori totalni stil [StyleObj]",
    "gui.extension.pyXlwt.setStyle": "set [Type] [Value] ukupnog stila [StyleObj]",
    "gui.extension.pyXlwt.easyStyle": "stvoriti jednostavan stil [StyleObj] stil [StyleString] format podataka [NumFormat]",
    "gui.extension.pyXlwt.eadit": "zapiši podatke [Label] u ćeliju u retku [Row] i stupcu [Col] radnog lista [SheetObj] sa stilom [Style]",
    "gui.blocklyText.pyXlwt.font": "stil fonta",
    "gui.blocklyText.pyXlwt.border": "rubni stil",
    "gui.blocklyText.pyXlwt.alignment": "stil poravnanja",
    "gui.blocklyText.pyXlwt.alignLeft": "poravnaj lijevo",
    "gui.blocklyText.pyXlwt.alignRight": "poravnaj desno",
    "gui.blocklyText.pyXlwt.alignTop": "poravnati vrh",
    "gui.blocklyText.pyXlwt.centerAlign": "poravnaj sredinu",
    "gui.blocklyText.pyXlwt.alignBotton": "poravnaj dno",
    "gui.blocklyText.pyXlwt.top": "gornja granica",
    "gui.blocklyText.pyXlwt.botton": "donji rub",
    "gui.blocklyText.pyXlwt.left": "lijevi rub",
    "gui.blocklyText.pyXlwt.right": "desna granica",
    "gui.blocklyText.pyXlwt.name": "naziv fonta",
    "gui.blocklyText.pyXlwt.height": "veličina fonta",
    "gui.blocklyText.pyXlwt.color_index": "indeks boja fonta",
    "gui.blocklyText.pyXlwt.bold": "podebljano",
    "gui.blocklyText.pyXlwt.underline": "naglasiti",
    "gui.blocklyText.pyXlwt.italic": "kurziv",
    "gui.extension.pyOpenCV.base": "osnovne operacije",
    "gui.extension.pyOpenCV.imread": "čitaj datoteku slike [FileName] postavi [Option] i vrati [ImgObj]",
    "gui.extension.pyOpenCV.imwrite": "spremi [ImgObj] u [FileName]",
    "gui.extension.pyOpenCV.imdecode": "pročitajte podatke u [Buff] u [Option] režimu Povratna slika [ImgObj]",
    "gui.extension.pyOpenCV.imencode": "upiši [Ext] sliku [ImgObj] u međuspremnik [Buff] i vrati rezultat [Ret]",
    "gui.extension.pyOpenCV.windowShow": "prozor je prikazan",
    "gui.extension.pyOpenCV.imshow": "prikaz slike [ImgObj] prozoru [WinName]",
    "gui.extension.pyOpenCV.fullWindow": "Prozor sa postavkama [WinName] prikazan je na punom ekranu",
    "gui.extension.pyOpenCV.namedWindow": "stvoriti prozor pod nazivom [WinName]",
    "gui.extension.pyOpenCV.destroyWindow": "uništi prozor pod nazivom [WinName]",
    "gui.extension.pyOpenCV.destroyAllWindows": "uništi sve prozore",
    "gui.extension.pyOpenCV.moveWindow": "pomakni prozor pod nazivom [WinName] na položaj ([X], [Y])",
    "gui.extension.pyOpenCV.resizeWindow": "promijeni veličinu prozora pod nazivom [WinName] na ([Width], [Height])",
    "gui.extension.pyOpenCV.resizeImg": "promenite veličinu slike [ImgObj] do širine[Width] visine [Height] izlaza [OutImg]",
    "gui.extension.pyOpenCV.intercept": "uhvatite izlaz slike od [Start] do [End] [Type] na sliki [ImgObj] izlaza [OutImg]",
    "gui.extension.pyOpenCV.copyImg": "kopiranje slike [ImgObj] u novi objekat [CopyImg]",
    "gui.extension.pyOpenCV.rotate": "prevucite sliku [ImgObj][RotateType]",
    "gui.extension.pyOpenCV.waitKey": "čekati pritisnutu tipku [Key] za [Second] ms",
    "gui.extension.pyOpenCV.key": "ključ [Key]",
    "gui.extension.pyOpenCV.selectROIs": "kreiranje snimka ekrana miša slike [ImgObj], obrazac [WindowsName] [SwitchCenter] centar pravougaonik [SwitchCrosshair] unakrsna linija Izlaz na koordinate [OutImg]",
    "gui.extension.pyOpenCV.imgAttr": "svojstva slike",
    "gui.extension.pyOpenCV.imgShape": "dohvati broj redaka, stupaca i kanala slike [ImgObj]",
    "gui.extension.pyOpenCV.imgSize": "dohvati veličinu slike [ImgObj](ukupan broj piksela)",
    "gui.extension.pyOpenCV.imgDtype": "dohvati vrstu podataka slike [ImgObj]",
    "gui.extension.pyOpenCV.copyMakeBorder": "Dodajte ivicu zgoraj [TopNum] dno [BottomNum] levo [LeftNum] desno [RightNum] sa [BorderType] na slici [ImgObj] Izlaz [OutImg]",
    "gui.extension.pyOpenCV.video": "video operacija",
    "gui.extension.pyOpenCV.VideoCapture": "kreiraj objekt VideoCapture [VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureOpen": "koZristi VideoCapture objekt [VideoObj] za otvaranje video datoteke/uređaja [VideoId]",
    "gui.extension.pyOpenCV.SetVideoCaptureSize": "postavljanje objekta VideoCapture [VideoObj] rezolucije hvatanja na [Width] × [Height]",
    "gui.extension.pyOpenCV.VideoCaptureOpenVideo": "otvaranje video datoteke pomoću objekta VideoCapture [VideoObj][Video]",
    "gui.extension.pyOpenCV.VideoCaptureIsOpened": "ako je objekt VideoCapture [VideoObj] već inicijaliziran",
    "gui.extension.pyOpenCV.VideoCaptureRelease": "zatvori objekt VideoCapture [VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureGrab": "ako postoji sljedeći okvir u objektu VideoCapture [VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureRead": "dohvati sljedeći okvir [VideoFrame] iz objekta VideoCapture [VideoObj] i status [VideoRet]",
    "gui.extension.pyOpenCV.VideoCaptureGet": "dohvati vrijednost za svojstvo [Properties] objekta VideoCapture [VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureSet": "postavi vrijednost za svojstvo [Properties] objekta VideoCapture [VideoObj] na [Value]",
    "gui.extension.pyOpenCV.imgConvert": "pretvorba slike",
    "gui.extension.pyOpenCV.adaptiveThreshold": "procesna slika [ImgObj] s [Method], maksimalna vrijednost [MaxNum], vrsta praga [Type], veličina područja susjedstva [Size] i konstanta [Constant], izlaz [OutImg]",
    "gui.extension.pyOpenCV.cvtColor": "pretvoriti sliku [ImgObj] u [OutImg] pomoću [Flags]",
    "gui.extension.pyOpenCV.distanceTransform": "Izračunavanje [distanceType] maske svakog piksela koji nije nula na slici [ImgObj] na najbliži nulti piksel [maskSize] Izlaz [Distance]",
    "gui.extension.pyOpenCV.threshold": "obradi sliku [ImgObj] s pragom [ThreshValue], maksimalnom vrijednošću [MaxNum], vrstom praga [ThresholdTypes], obrađenim izlazom [Ret] i slikom [OutImg]",
    "gui.extension.pyOpenCV.ObjectDetection": "otkrivanje predmeta",
    "gui.extension.pyOpenCV.CascadeClassifier": "izradi kaskadni klasifikator [CascadeClassifierObj]",
    "gui.extension.pyOpenCV.CascadeClassifierLoad": "učitaj kaskadni klasifikator [CascadeClassifierObj] iz datoteke [FileName]",
    "gui.extension.pyOpenCV.CascadeClassifierEmpty": "ako je kaskadni klasifikator [CascadeClassifierObj] već učitan",
    "gui.extension.pyOpenCV.detectMultiScale": "kaskadni klasifikator [CascadeClassifierObj] otkriva objekte različitih veličina na slici [ImgObj] s [Method], skalom [ScaleFactor], minNeighbors [MinNeighbors], povratnom metodom 3 i izlazom [OutputRejectLevels]",
    "gui.extension.pyOpenCV.Draws": "alat za crtanje",
    "gui.extension.pyOpenCV.putText": "nacrtajte tekst [Text] na slici [ImgObj] Donji levi ugao koordinira X [PositionX] Y[PositionY] Boja [Color] Veličina fonta [FontSize] Širina linije [Thickness]",
    "gui.extension.pyOpenCV.arrowedLine": "nacrtaj strelicu između točaka [StartP] do [EndP] bojom [Color], debljinom [Thickness], upiši [LineType] na slici [ImgObj]",
    "gui.extension.pyOpenCV.circle": "nacrtaj krug sa središtem [CenterP], radijusom [Radius], bojom [Color], debljinom [Thickness], tipom [LineType] na slici [ImgObj]",
    "gui.extension.pyOpenCV.clipLine": "isjeci liniju između točaka [StartP] do [EndP] na pravokutniku veličine [ImgSize] i vrati se ako je unutar točaka [P1] do [P2] pravokutnika [Ret]",
    "gui.extension.pyOpenCV.drawMarker": "nacrtaj kreatora na poziciji [P] bojom [Color], vrstom markera [MarkerType], debljinom [Thickness], veličinom [MarkerSize], vrstom linije [LineType] na slici [ImgObj]",
    "gui.extension.pyOpenCV.ellipse": "nacrtaj elipsu sa središtem [CenterP], osi [Axes], kutom [Angle], početnim kutom [StartAngle], krajnjim kutom [EndAngle], bojom [Color], debljinom [Thickness], tipom [LineType] na slici [ImgObj]",
    "gui.extension.pyOpenCV.line": "nacrtaj liniju između [StartP] do [EndP] točaka bojom [Color], debljinom [Thickness], upiši [LineType] na slici [ImgObj]",
    "gui.extension.pyOpenCV.rectangle": "nacrtaj pravokutnik s gornjim lijevim vrhom [TopP], donjim desnim vrhom [BottomP], bojom [Color], debljinom [Thickness], tipom [LineType] na slici [ImgObj]",
    "gui.extension.pyOpenCV.gaussian": "Gaussian blur smoothing je izveden na slici [ImgObj]，Izlaz kao slika [OutImg]",
    "gui.extension.pyOpenCV.canny": "Izvršite otkrivanje ivica na slici [ImgObj]，Prag1[ThreshValue1]、Prag2[ThreshValue2]，Slika izlazne ivice binarizacije [OutImg]",
    "gui.extension.pyOpenCV.contours": "Potražite tačku prikaza strukture na slici [ImgObj].，[Mode]Režimu、[Method]Metod，Vraćanje rezultata sekvencama[Contours]",
    "gui.extension.pyOpenCV.drawContours": "Crtanje kontura na slici [ImgObj][Contours][Color]Širina linije[Thickness]，Izlaz slike sa linijama konture [OutImg]",
    "gui.blocklyText.pyOpenCV.retr_external": "Samo van konture",
    "gui.blocklyText.pyOpenCV.retr_list": "Bez čina",
    "gui.blocklyText.pyOpenCV.retr_ccomp": "Drvo na dva nivoa",
    "gui.blocklyText.pyOpenCV.retr_tree": "Stablo nivoa",
    "gui.blocklyText.pyOpenCV.chain_approx_none": "Sve tačke konture",
    "gui.blocklyText.pyOpenCV.chain_approx_simple": "Samo za kraj koordinata",
    "gui.blocklyText.pyOpenCV.chain_approx_tc89_l1": "Algoritam apropsimacije",
    "gui.blocklyText.pyOpenCV.imread_unchanged": "originalna slika",
    "gui.blocklyText.pyOpenCV.imread_grayscale": "slika u sivim tonovima",
    "gui.blocklyText.pyOpenCV.imread_color": "BGR slika",
    "gui.blocklyText.pyOpenCV.border_replicate": "ponoviti",
    "gui.blocklyText.pyOpenCV.border_reflect": "odražavati",
    "gui.blocklyText.pyOpenCV.border_reflect101": "odražavati 101",
    "gui.blocklyText.pyOpenCV.border_wrap": "omotati",
    "gui.blocklyText.pyOpenCV.border_constant": "konstantno",
    "gui.blocklyText.pyOpenCV.column": "Kolone",
    "gui.blocklyText.pyOpenCV.row": "Da",
    "gui.blocklyText.pyOpenCV.rotate_90_clockwise": "okrenite 90 stepeni u smeru kretanja kazaljke na satu",
    "gui.blocklyText.pyOpenCV.rotate_180": "rotiraj za 180 stepeni",
    "gui.blocklyText.pyOpenCV.rotate_90_counterclockwise": "rotiranje za 90 stepeni u smeru suprotnom od kretanja kazaljki na satu",
    "gui.blocklyText.pyOpenCV.cap_prop_buffersize": "veličina bafera slike",
    "gui.blocklyText.pyOpenCV.cap_prop_pos_msec": "trenutni položaj videa",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_width": "širina",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_height": "visina",
    "gui.blocklyText.pyOpenCV.cap_prop_fps": "okvirna stopa",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_count": "broj okvira",
    "gui.blocklyText.pyOpenCV.adaptive_thresh_mean_c": "prosjek",
    "gui.blocklyText.pyOpenCV.adaptive_thresh_gaussian_c": "Gaussovo zamućenje",
    "gui.blocklyText.pyOpenCV.thresh_binary": "postavi na maksimum kada se prekorači prag, inače uzmi 0",
    "gui.blocklyText.pyOpenCV.thresh_binary_inv": "postavi na 0 kada se prekorači prag, u suprotnom, uzmi maksimum",
    "gui.blocklyText.pyOpenCV.thresh_trunc": "postavljena na kritičnu točku pri prekoračenju praga, inače nepromijenjena",
    "gui.blocklyText.pyOpenCV.thresh_tozero": "zadrži nepromijenjeno kada se prekorači prag, u suprotnom, postavi na 0",
    "gui.blocklyText.pyOpenCV.thresh_tozero_inv": "postavljeno na 0 kada se prekorači prag, inače nepromijenjeno",
    "gui.blocklyText.pyOpenCV.color_bgr2bgra": "dodaj alfa kanal BGR slici",
    "gui.blocklyText.pyOpenCV.color_rgb2rgba": "dodaj alfa kanal RGB slici",
    "gui.blocklyText.pyOpenCV.color_bgra2bgr": "ukloni Alpha kanal iz BGR slike",
    "gui.blocklyText.pyOpenCV.color_rgba2rgb": "ukloni alfa kanal iz RGB slike",
    "gui.blocklyText.pyOpenCV.color_bgr2rgba": "pretvori BGR u RGB",
    "gui.blocklyText.pyOpenCV.color_rgb2bgra": "pretvori RGB u BGR",
    "gui.blocklyText.pyOpenCV.color_bgr2gray": "pretvori između BGR i sivih tonova",
    "gui.blocklyText.pyOpenCV.color_rgb2gray": "pretvori između RGB i sivih tonova",
    "gui.blocklyText.pyOpenCV.color_gray2bgr": "Konvertovanje iz prostora sivih tonova u BGR",
    "gui.blocklyText.pyOpenCV.color_bgr2hsv": "Pretvara u i iz BGR i HSV prostora u boji",
    "gui.blocklyText.pyOpenCV.color_rgb2hsv": "Pretvara u i iz RGB i HSV prostora u boji",
    "gui.blocklyText.pyOpenCV.dist_l1": "Udaljenost menhetna",
    "gui.blocklyText.pyOpenCV.dist_l2": "jednostavna euklidska udaljenost",
    "gui.blocklyText.pyOpenCV.dist_c": "Razdaljina čebiševa",
    "gui.blocklyText.pyOpenCV.detectmultiscale": "uobičajene metode otkrivanja",
    "gui.blocklyText.pyOpenCV.detectmultiscale2": "metoda otkrivanja 2",
    "gui.blocklyText.pyOpenCV.detectmultiscale3": "metoda otkrivanja 3",
    "gui.blocklyText.pyOpenCV.filled": "ispunjena linija",
    "gui.blocklyText.pyOpenCV.line_4": "4-povezani vod",
    "gui.blocklyText.pyOpenCV.line_8": "8-povezani vod",
    "gui.blocklyText.pyOpenCV.line_aa": "antialiased linija",
    "gui.blocklyText.pyOpenCV.marker_cross": " križić marker",
    "gui.blocklyText.pyOpenCV.marker_tilted_cross": " Oznaka križića nagnuta pod kutom od 45 stupnjeva",
    "gui.blocklyText.pyOpenCV.marker_star": " zvjezdica marker",
    "gui.blocklyText.pyOpenCV.marker_diamond": " dijamantni marker",
    "gui.blocklyText.pyOpenCV.marker_square": " kvadratni marker",
    "gui.blocklyText.pyOpenCV.marker_triangle_up": " trokutasti marker usmjeren prema gore",
    "gui.blocklyText.pyOpenCV.marker_triangle_down": " trokutasti marker usmjeren prema dolje",
    "gui.blocklyText.pyOpenCV.haarcascade_eye": "oko",
    "gui.blocklyText.pyOpenCV.haarcascade_eye_tree_eyeglasses": "oko drvo naočale",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalcatface": "mačje lice",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalcatface_extended": "mačje lice produženo",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt": "lice alt",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt_tree": "lice alt_tree",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt2": "lice alt2",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_default": "lice zadano",
    "gui.blocklyText.pyOpenCV.haarcascade_fullbody": "cijelo tijelo",
    "gui.blocklyText.pyOpenCV.haarcascade_lefteye_2splits": "lijevo oko",
    "gui.blocklyText.pyOpenCV.haarcascade_licence_plate_rus_16stages": "licencija",
    "gui.blocklyText.pyOpenCV.haarcascade_lowerbody": "Donji dio tijela",
    "gui.blocklyText.pyOpenCV.haarcascade_profileface": "profilno lice",
    "gui.blocklyText.pyOpenCV.haarcascade_righteye_2splits": "desno oko",
    "gui.blocklyText.pyOpenCV.haarcascade_russian_plate_number": "broj ruske tablice",
    "gui.blocklyText.pyOpenCV.haarcascade_smile": "usta",
    "gui.blocklyText.pyOpenCV.haarcascade_upperbody": "Gornji dio tijela",
    "gui.blocklyText.unihiker.updateParamsMenu_width": "širina",
    "gui.blocklyText.unihiker.updateParamsMenu_height": "visoko",
    "gui.blocklyText.unihiker.updateParamsMenu_radial": "Radius",
    "gui.blocklyText.unihiker.updateParamsMenu_linewidth": "Širina linije",
    "gui.blocklyText.unihiker.updateParamsMenu_start": "Početna točka",
    "gui.blocklyText.unihiker.updateParamsMenu_end": "Krajnja točka",
    "gui.blocklyText.unihiker.standardPointMenu_center": "Centar",
    "gui.blocklyText.unihiker.standardPointMenu_top": "vrh",
    "gui.blocklyText.unihiker.standardPointMenu_bottom": "dno",
    "gui.blocklyText.unihiker.standardPointMenu_left": "lijevo",
    "gui.blocklyText.unihiker.standardPointMenu_right": "Pravo",
    "gui.blocklyText.unihiker.standardPointMenu_topleft": "Gore lijevo",
    "gui.blocklyText.unihiker.standardPointMenu_topright": "Gore desno",
    "gui.blocklyText.unihiker.standardPointMenu_bottomleft": "Dolje lijevo",
    "gui.blocklyText.unihiker.standardPointMenu_bottomright": "Dolje desno",
    "gui.blocklyText.unihiker.xyzsMenu_S": "Snaga",
    "gui.blocklyText.unihiker.expressionMenu_Angry": "Ljut",
    "gui.blocklyText.unihiker.expressionMenu_Nerve": "Živac",
    "gui.blocklyText.unihiker.expressionMenu_Peace": "Mir",
    "gui.blocklyText.unihiker.expressionMenu_Shock": "iznenađeni",
    "gui.blocklyText.unihiker.expressionMenu_Sleep": "Spavati",
    "gui.blocklyText.unihiker.expressionMenu_Smile": "Osmijeh",
    "gui.blocklyText.unihiker.expressionMenu_Sweat": "Znoj",
    "gui.blocklyText.unihiker.expressionMenu_Think": "Razmišljanje",
    "gui.blocklyText.unihiker.expressionMenu_Wink": "Mig",
    "gui.blocklyText.unihiker.unihikerPinMenu_button": "Ključ",
    "gui.blocklyText.unihiker.blockTitle.screenDisplay": "Zaslon",
    "gui.blocklyText.unihiker.showText": "Naziv objekta [NAME] prikazuje tekst [TEXT] na X[X] Y[Y] veličina fonta [FONTSIZE] boja [COLOR]",
    "gui.blocklyText.unihiker.showTextWithDigitalTubeStyle": "Naziv objekta [NAME] prikazuje font imitacije digitalne cijevi [TEXT] u: X[X] Y[Y] veličini fonta [FONTSIZE] boji [COLOR]",
    "gui.blocklyText.unihiker.showImage": "Naziv objekta [NAME] prikazuje sliku [IMG] na X[X] Y[Y]",
    "gui.blocklyText.unihiker.showInsetLiveExpression": "Naziv objekta [NAME] prikazuje ugrađeni dinamički izraz [EXPRESSION] u X[X] Y[Y] intervalu [DURATION] sekundi",
    "gui.blocklyText.unihiker.addButtonClickFun": " Naziv objekta [NAME] gumb za dodavanje [BUTTON] na X[X] Y[Y] širina [WIDTH] visina [HEIGHT] klik funkcija povratnog poziva [FUN]",
    "gui.blocklyText.unihiker.showClock": "Naziv objekta [NAME] prikazuje sat na radijusu X[X] Y[Y] [RADIUS] boja [COLOR]",
    "gui.blocklyText.unihiker.showClockWithFillStyle": "Naziv objekta [NAME] prikazuje sat popune na radijusu X[X] Y[Y] [RADIUS] boja obruba [COLOR1] boja ispune [COLOR2]",
    "gui.blocklyText.unihiker.showQrCode": "Naziv objekta [NAME] prikazuje sadržaj QR koda [VALUE] na X[X] Y[Y] stranici duljine [WIDTH]",
    "gui.blocklyText.unihiker.drawLine": "Naziv objekta[NAME] prikaz segmenta početne točke X[X1] Y[Y1] krajnje točke X[X2] Y[Y2] širina crte [LINEWIDTH] boja [COLOR]",
    "gui.blocklyText.unihiker.drawRectangle": "Naziv objekta [NAME] prikazuje pravokutnik na X[X] Y[Y] širina [WIDTH] visina [HEIGHT] širina linije [LINEWIDTH] boja obruba [COLOR]",
    "gui.blocklyText.unihiker.drawRectangleWithFillStyle": "Naziv objekta [NAME] prikazuje ispunjeni pravokutnik na X[X] Y[Y] širina [WIDTH] visina [HEIGHT] boja ispune [COLOR]",
    "gui.blocklyText.unihiker.drawRoundRectangle": "Naziv objekta [NAME] prikazuje zaobljeni pravokutnik na X[X] Y[Y] širini [WIDTH] visini [HEIGHT] radijusu kuta [RADIUS] širini linije [LINEWIDTH] boji obruba [COLOR]",
    "gui.blocklyText.unihiker.drawRoundRectangleWithFillStyle": "Naziv objekta [NAME] prikazuje zaobljeni pravokutnik ispune na X[X] Y[Y] širini [WIDTH] visini [HEIGHT] polumjeru kuta [RADIUS] boji ispune [COLOR]",
    "gui.blocklyText.unihiker.drawCircle": "Naziv objekta [NAME] prikazuje krug na radijusu X[X] Y[Y] [RADIUS] širini linije [LINEWIDTH] boji ruba [COLOR]",
    "gui.blocklyText.unihiker.drawCircleWithFillStyle": "Naziv objekta [NAME] prikazuje krug ispune na radijusu X[X] Y[Y] [RADIUS] boja ispune [COLOR]",
    "gui.blocklyText.unihiker.updateObjectNumberParams": "Parametar broja [PARAMS] naziva objekta ažuriranja [NAME] je [VALUE]",
    "gui.blocklyText.unihiker.updateObjectStringParams": "Parametar znakovnog sadržaja naziva objekta ažuriranja [NAME] je [VALUE]",
    "gui.blocklyText.unihiker.updateObjectColorPalette": "Boja naziva objekta ažuriranja [NAME] je [COLOR]",
    "gui.blocklyText.unihiker.updateObjectColorRGB": "Boja naziva objekta ažuriranja [NAME] je crvena[RED]zelena[GREEN]plava[BLUE]",
    "gui.blocklyText.unihiker.updateObjectColorName": "Boja naziva objekta ažuriranja [NAME] je [COLOR]",
    "gui.blocklyText.unihiker.updateObjectStandardPoint": "Referentna točka naziva objekta ažuriranja [NAME] je [POSITION]",
    "gui.blocklyText.unihiker.updateTimeObjectValue": "Vrijeme za ažuriranje objekta sata [NAME] je [HOUR] sati [MINUTE] minuta [SECOND] sekundi",
    "gui.blocklyText.unihiker.setObjClickFun": "Funkcija povratnog poziva klika naziva objekta [NAME] je [FUNCTIONNAME]",
    "gui.blocklyText.unihiker.whenClickTriggerFun": "Kada se aktivira funkcija povratnog poziva klikom [FUNCTIONNAME].",
    "gui.blocklyText.unihiker.updateImgObjSrc": "Ažuriranje objekta slike [IMG] izvor slike je [SRC]",
    "gui.blocklyText.unihiker.updateExpressionObjSrc": "Ažuriraj izraz objekta [NAME] izvor izraza je [EXPRESSION]",
    "gui.blocklyText.unihiker.blockTitle.mouseAndKeyboardEvent": "Događaj miša i tipkovnice",
    "gui.blocklyText.unihiker.whenMouseMove": "Kada se primi događaj pomicanja miša, vrati koordinate [X] [Y]",
    "gui.blocklyText.unihiker.whenButtonABPress": "Kada se pritisne tipkalo [BUTTONAB].",
    "gui.blocklyText.unihiker.whenKeyboardPress": "Kada se pritisne tipka tipkovnice [KEYBOARD].",
    "gui.blocklyText.unihiker.numberKeyboard": "Brojčane i slovne tipke [KEYBOARD]",
    "gui.blocklyText.unihiker.otherKeyboard": "Druga tipkovnica [KEYBOARD]",
    "gui.blocklyText.unihiker.waitForWhenButtonABPress": "Pričekaj dok se ne pritisne gumb [BUTTONAB].",
    "gui.blocklyText.unihiker.blockTitle.multithreading": "Višenitnost",
    "gui.blocklyText.unihiker.startThread": "Početak niti objekt [THREAD].",
    "gui.blocklyText.unihiker.quitThread": "Objekt niti [THREAD] zaustavljen",
    "gui.blocklyText.unihiker.whenThreadStart": "Izvrši kada se pokrene objekt niti [THREAD].",
    "gui.blocklyText.unihiker.blockTitle.lmSensors": "Ugrađeni senzori",
    "gui.blocklyText.unihiker.isButtonABPressed": "Pritisnut je gumb [BUTTONAB]?",
    "gui.blocklyText.unihiker.readAmbientLight": "Očitaj intenzitet ambijentalnog svjetla",
    "gui.blocklyText.unihiker.readMicrophoneSound": "Očitaj razinu zvuka mikrofona",
    "gui.blocklyText.unihiker.readXYZSAcceleratedSpeed": "Vrijednost ubrzanja čitanja [XYZS]",
    "gui.blocklyText.unihiker.readGyroscopeXYZ": "Pročitaj vrijednost žiroskopa [XYZ]",
    "gui.blocklyText.unihiker.blockTitle.onboardBuzzer": "Ugrađeni zujalica",
    "gui.blocklyText.unihiker.playMusic": "Sviraj melodiju [MUSIC] Ponovi [REPEAT]",
    "gui.blocklyText.unihiker.musicSetTempo": "Sviraj notu [TICKS] [TEMPO] takt",
    "gui.blocklyText.unihiker.setBuzzerFreq": "Sviraj notu [NOTE] [BEAT] takt",
    "gui.blocklyText.unihiker.backSetBuzzerFreq": "Reproduciraj note u pozadini[NOTE]",
    "gui.blocklyText.unihiker.buzzerSwitch": "Zaustavi reprodukciju u pozadini",
    "gui.blocklyText.unihiker.buzzerRedirect": "Zujalica preusmjerava na pin [PIN]",
    "gui.blocklyText.unihiker.blockTitle.pinOperation": "Pin operacija",
    "gui.blocklyText.unihiker.unihikerPin": "Prazna ploče pin [PIN]",
    "gui.blocklyText.unihiker.digitalRead": "Očitaj digitalni pin [PIN]",
    "gui.blocklyText.unihiker.analogRead": "Očitaj analogni pin (ADC) [PIN]",
    "gui.blocklyText.unihiker.digitalWrite": "Postavi digitalni pin [PIN] izlaz [VALUE]",
    "gui.blocklyText.unihiker.analogWrite": "Postavi analogni pin[PIN] izlaz (PWM)[VALUE]",
    "gui.blocklyText.unihiker.blockTitle.audioPlayback": "Audio snimanje i reprodukcija",
    "gui.blocklyText.unihiker.getRecord": "Snimanje [TIME] sekundi do kraja, naziv datoteke [NAME]",
    "gui.blocklyText.unihiker.getRecordBackground": "Započni pozadinsko snimanje, naziv datoteke [NAME]",
    "gui.blocklyText.unihiker.stopRecordBackground": "Zaustavi pozadinsko snimanje",
    "gui.blocklyText.unihiker.playAudio": "Reproduciraj audio datoteku [NAME] do kraja",
    "gui.blocklyText.unihiker.startPlayAudioBackground": "Počni reproducirati audio datoteku [NAME] u pozadini",
    "gui.blocklyText.unihiker.getBgAudioRemainingDuration": "Dohvati preostalo trajanje (sekunde) reprodukcije zvuka u pozadini",
    "gui.blocklyText.unihiker.pauseBgAudio": "Pauziraj reprodukciju u pozadini",
    "gui.blocklyText.unihiker.continueBgAudio": "Ponovo pokreni reprodukciju u pozadini",
    "gui.blocklyText.unihiker.stopBgAudio": "Završi reprodukciju u pozadini",
    "gui.extension.unihiker.input.button": "Dugme",
    "gui.extension.unihiker.input.hello": "zdravo",
    "gui.blocklyText.unihiker.updateButtonStatus": "Objekt gumba za ažuriranje [BUTTON] je [STATUS]",
    "gui.blocklyText.unihiker.updateObjectParamWithInput": "Parametar [KEY] objekta ažuriranja [OBJECT] je [VALUE]",
    "gui.blocklyText.unihiker.updateObjectParamsWithInput": "Parametar 1 [KEY1] [OBJECT] objekta ažuriranja je [VALUE1] Parametar 2 [KEY2] je [VALUE2]",
    "gui.blocklyText.unihiker.removeObject": "Ukloni objekt [OBJECT]",
    "gui.blocklyText.unihiker.removeAllObject": "Ukloni sve predmete",
    "gui.blocklyText.unihiker.buttonStatusMenu_normal": "Omogućiti",
    "gui.blocklyText.unihiker.buttonStatusMenu_disable": "Onemogućeno",
    "gui.blocklyText.unihiker.updateParamsMenu_fontSize": "Veličina fonta",
    "gui.blocklyText.unihiker.generate.keyboardWithButtonConflict": "!Poruka o pogrešci: Tipka na tipkovnici [AB] i tipka za prazan red [AB] mogu se koristiti samo u isto vrijeme, izbriši duplikat definiranog modula",
    "gui.blocklyText.pythonsen0291.extensionName": "Mjerač snage",
    "paint.paintEditor.hue": "Boja",
    "paint.paintEditor.saturation": "Zasićenje",
    "paint.paintEditor.brightness": "Svjetlina",
    "gui.comingSoon.message1": "Ne brini, u tome smo {emoji}",
    "gui.comingSoon.message2": "Dolazi uskoro...",
    "gui.comingSoon.message3": "Radimo na tome {emoji}",
    "paint.paintEditor.fill": "Ispuna",
    "paint.paintEditor.costume": "Kostim",
    "paint.paintEditor.group": "Grupiraj",
    "paint.paintEditor.ungroup": "Razgrupiraj",
    "paint.paintEditor.undo": "Poništi",
    "paint.paintEditor.redo": "Ponovi",
    "paint.paintEditor.forward": "Naprijed",
    "paint.paintEditor.backward": "Nazad",
    "paint.paintEditor.front": "Ispred",
    "paint.paintEditor.back": "Iza",
    "paint.paintEditor.more": "Više",
    "paint.modeTools.brushSize": "Veličina",
    "paint.modeTools.eraserSize": "Veličina gumice",
    "paint.modeTools.copy": "Kopiraj",
    "paint.modeTools.paste": "Zalijepi",
    "paint.modeTools.delete": "Izbriši",
    "paint.modeTools.curved": "Zakrivljen",
    "paint.modeTools.pointed": "Šiljati",
    "paint.modeTools.thickness": "Debljina",
    "paint.modeTools.flipHorizontal": "Okreni vodoravno",
    "paint.modeTools.flipVertical": "Okreni okomito",
    "paint.modeTools.filled": "Ispunjeno",
    "paint.modeTools.outlined": "Ocrtano",
    "paint.paintEditor.bitmap": "Pretvori u bitmap",
    "paint.paintEditor.vector": "Pretvori u vektor",
    "paint.paintEditor.stroke": "Obrub",
    "paint.brushMode.brush": "Četka",
    "paint.eraserMode.eraser": "Gumica",
    "paint.fillMode.fill": "Ispuna",
    "paint.lineMode.line": "Crta",
    "paint.ovalMode.oval": "Krug",
    "paint.rectMode.rect": "Pravokutnik",
    "paint.reshapeMode.reshape": "Preoblikuj",
    "paint.roundedRectMode.roundedRect": "Zaobljeni pravokutnik",
    "paint.selectMode.select": "Odaberi",
    "paint.textMode.text": "Tekst",
    "paint.colorPicker.swap": "Zamijeni",
    "gui.dialog.notConnectDevice": "Nema povezanih uređaja",
    "gui.dialog.prompt": "Informacija",
    "gui.dialog.openPort": "Otvori port",
    "gui.dialog.error": "Greška",
    "gui.dialog.notSelectDevice": "Nije odabran nijedan uređaj, klikni [Proširenje] u donjem lijevom kutu softvera</br>te klikni za odabir uređaja koji želiš povezati",
    "gui.dialog.connectDeviceError1": "Poveži uređaj (",
    "gui.dialog.connectDeviceError2": ")Nije uspjelo, slijedi korake u nastavku:</br> *Zamijeni USB priključak i ponovno uključi USB kabel</br> *Provjeri je li spojen",
    "gui.dialog.connectDeviceError3": "Glavna kontrolna ploča</br> * Pridruži se službenoj komunikacijskoj grupi (671877416) za prijavu problema",
    "gui.dialog.calibration1": "</br> *Molimo okreni",
    "gui.dialog.calibration2": "Kalibriraj glavnu kontrolnu ploču</br>",
    "gui.dialog.runAsManager": "Zatvori Mind+ i klikni desnom tipkom miša na ikonu softvera i odaberi \"Pokreni kao administrator\". Nakon pokretanja ponovno odaberi ovu funkciju.",
    "gui.dialog.runErrorForVortex": "Sustav ne radi ispravno, odspoji Vortex",
    "gui.dialog.runError": "Sustav ne radi ispravno",
    "gui.dialog.close": "Zatvoriti",
    "gui.dialog.variableNameSpecialCharacters": "naziv varijable %1 ne može sadržavati posebne znakove:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "naziv funkcije %1 ne može sadržavati posebne znakove: [\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "argumenti funkcije %1 ne mogu sadržavati posebne znakove:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "i",
    "gui.dialog.yes": "Da",
    "gui.dialog.no": "Ne",
    "gui.dialog.continue": "Nastavi",
    "gui.dialog.maxReset": "Izbrisati kodove u području za uređivanje i učitati kodove tvorničkih postavki?",
    "gui.dialog.feedbackCannotEmpty": "Molimo ispuni povratne informacije.",
    "gui.dialog.noInstallCompiler": "Nije instaliran kompajler",
    "gui.dialog.install": "Instaliraj online",
    "gui.dialog.cancle": "Otkaži",
    "gui.dialog.installingCompiler": "instaliranje kompilatora",
    "gui.dialog.uploading": "učitavanje",
    "gui.dialog.changeLanuage": "Promjenom jezika izbrisat će se trenutni program. Jesi li siguran da želiš nastaviti?",
    "gui.dialog.boardNotSupportMpy": "trenutna ploča: %1 ne podržava micopython",
    "gui.dialog.loadBlankItem": "Nepodržana stavka</br> ponovno učitava praznu stavku",
    "gui.dialog.switchBaudError": "Došlo je do pogreške u prebacivanju brzine prijenosa</br>",
    "gui.dialog.turnMicrobitCompass": "Trebaš kalibrirati elektronički kompas, molimo podesi položaj mciro: bit za pomicanje trepćućih LED svjetala dok se sva LED svjetla ne upale",
    "gui.dialog.turnCalliopeCompass": "Potrebno je kalibrirati elektronički kompas, molimo prilagodi položaj Calliope da pomiče trepćuća LED svjetla dok se sva LED svjetla ne upale",
    "gui.dialog.operationShow": "Demonstracija operacije",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "Računalo",
    "gui.dialog.delete": "izbrisati",
    "gui.dialog.sureDeleteFile": "Jesi li siguran da želiš izbrisati datoteku [%2] na %1?",
    "gui.dialog.sureDeleteFolder": "Jesi li siguran da želiš izbrisati mapu [%2] na %1?",
    "gui.dialog.uncorrectPath": "Nije odabran ispravan put",
    "gui.dialog.laterDownLoad": "Ažuriraj kasnije",
    "gui.dialog.downLoadUpdate": "Preuzmi ažuriranje",
    "gui.dialog.downLoading": "Preuzimanje...",
    "gui.dialog.dwnLoadWebsite": "Preuzmi web mjesto",
    "gui.dialog.laterUpdate": "Instaliraj kasnije",
    "gui.dialog.update": "Instalirati",
    "gui.dialog.getUpdateMsgError": "Došlo je do pogreške pri dobivanju ažurirane poruke",
    "gui.dialog.ok": "U redu",
    "gui.dialog.closeCodeBoxes": "Jesi li siguran da želiš zatvoriti okvire koda %1?",
    "gui.dialog.emailError": "Format e-pošte nije ispravan!",
    "gui.dialog.emailEmpty": "E-mail ne smije biti prazan!",
    "gui.dialog.bigFilePrompt": "Datoteka je velika, budi strpljiv i pričekaj, hvala!",
    "gui.dialog.successFeedbackPrompt1": "Hvala na povratnim informacijama, stranica će se ponovno učitati nakon 3 sekunde.",
    "gui.dialog.successFeedbackPrompt2": "Povratne informacije su poslane. Pošalji ga nakon nekoliko sekundi.",
    "gui.dialog.failedFeedbackPrompt": "Slanje nije uspjelo. jako nam je žao zbog uzrokovanih neugodnosti. Posjeti naš internetski forum kako bi prijavio svoje povratne informacije na https://www.dfrobot.com/forum/, hvala!",
    "gui.dialog.successFeedbackPrompt3": "Hvala na povratnoj informaciji",
    "gui.dialog.forcedCloseSoftWare": "Došlo je do pogreške pri spremanju datoteke, prisilno zatvoriti softver?",
    "gui.dialog.saveSuccess": "Projekt je spremljen",
    "gui.dialog.noSelectTruePath": "Nije odabran ispravan put",
    "gui.dialog.newItemContent": "Stvorit ćeš prazan projekt da bi prebrisao trenutni projekt, jesi li siguran da ga želiš izraditi?",
    "gui.dialog.deleteBlocksError": "Brisanje %1 bloka nije uspjelo, izbriši ga ručno",
    "gui.dialog.netIsConnect": "Provjeri je li mreža povezana",
    "gui.dialog.needToUpdate": "Ažuriraj kompajler na najnoviju verziju: %1?",
    "gui.dialog.cantUseMpythonInArduinoC": "Nije instaliran kompajler, ne možeš koristiti mpython pod Arduino C. Ako računalo nema mrežu, možeš preuzeti 'Compiler offline plug-in package' na službenoj web stranici.",
    "gui.dialog.enterMpyMode": "uđi u način rada micropython",
    "gui.dialog.runHardware": "Prvo spoji uređaj, a zatim upravljaj hardverom",
    "gui.dialog.sureDelete": "Jesi li siguran da želiš ovo izbrisati?",
    "gui.dialog.notSupportWebGL": "Došlo je do pogreške pri učitavanju pozornice, to se može dogoditi ako tvoje računalo ne podržava WebGL,</br> Pogledajdetalje prikazane u nastavku: </br> %1 </br>QQ grupa nas kontaktira: 671877416",
    "gui.dialog.boardIsnotMPython": "Trenutačno povezana glavna kontrolna ploča nije mPython, poveži se na mPython",
    "gui.dialog.swdMicrobitUpload": "Tvoje računalo mora koristiti SWD za snimanje micro:bita, pokreni Mind+ s administratorskim ovlastima.",
    "gui.dialog.openPortError": "pogreška otvaranja porta %1: %2",
    "gui.dialog.pressedA": "Pritisni i drži tipkalo A, ne otpuštaj.",
    "gui.dialog.unpressedA": "Molimo otpusti tipkalo A",
    "gui.dialog.unsupportProject": "Nepodržana stavka</br> ponovno učitava praznu stavku.",
    "gui.dialog.openProjectError": "Projekti izrađeni u Scratch modu moraju se otvoriti u Scratch modu</br> Provjeri trenutni način.",
    "gui.dialog.installCompilerError1": "Preuzimanje nije uspjelo, možeš preuzeti izvanmrežni instalacijski program ili pokušati ponovno",
    "gui.dialog.installCompilerOffline": "Preuzmi izvanmrežni kompilator",
    "gui.dialog.installCompilerError2": "Pogreška pri potvrdi, možeš preuzeti izvanmrežni instalacijski program ili pokušaj ponovo preuzeti",
    "gui.dialog.installCompilerError3": "Pogreška pri instalaciji, možeš preuzeti izvanmrežni instalacijski program ili pokušaj ponovo preuzeti",
    "gui.dialog.installCompilerSuccess": "Kompajler je instaliran, možeš koristiti Esp32 pod ArduinoC",
    "gui.dialog.sureAdapter": "Adapter nije pronađen, provjeri je li adapter spojen",
    "gui.dialog.checkPort": "Otvaranje serijskog priključka nije uspjelo, pokušaj ponovno spojiti serijski priključak ili provjeri je li serijski priključak zauzet.",
    "gui.dialog.changeBoard": "Promjena pločice će izbrisati trenutni program. Jesi li siguran da želiš nastaviti?",
    "gui.crashMessage.somethingWrong": "Ups! Nešto je pošlo po zlu.",
    "gui.crashMessage.cacheListPrompt": "Datoteku projekta možeš pronaći u izborniku 「Projekt」> 「Popis predmemorije」",
    "gui.crashMessage.reload": "Ponovno pokretanje",
    "gui.crashMessage.submit": "podnijeti",
    "gui.crashMessage.attachment": "Prilog",
    "gui.crashMessage.errFeedbackNote": "Ovdje možeš napisati poruke o pogrešci ili prijedloge, Mind+ će tako postati još bolji.",
    "gui.crashMessage.promptContent": "Jako nam je žao, izgleda kako se Mind+ srušio. Ovu pogrešku možeš poslati timu Mind+ s desne strane, hvala! Molimo osvježi svoju stranicu da pokušaš ponovno.",
    "gui.dialog.isSaveProject": "Spremiti trenutni projekt?",
    "gui.dialog.save": "Spremi",
    "gui.dialog.notSave": "Nemoj spremiti",
    "gui.dialog.variableIsNull": "Naziv varijable ne može biti prazan",
    "gui.dialog.listNameIsNull": "naziv liste ne može biti prazan",
    "gui.dialog.variableIsRepeat": "imena varijabli ne mogu se ponavljati, molimo izmijenite",
    "gui.dialog.pictureAINotNull": "ne može biti nula",
    "gui.dialog.pictureAIInitServer": "prvo inicijaliziraj poslužitelj",
    "gui.dialog.pictureAIInitServerError": "Pogreška pri pokretanju poslužitelja, provjeri broj računa i tajni ključ",
    "gui.dialog.versionPrompt": "trenutna verzija sb3 datoteke「%1」",
    "gui.dialog.versionGreater": "Sb3 verzija datoteke je viša od trenutne verzije softvera, sb3 verzija datoteke「%1」",
    "gui.dialog.noModulePrompt": "Prema trenutnoj klasifikaciji nema malih modula. Pogledaj pomoć za detalje.",
    "gui.dialog.firstSelectBoard": "Prvo odaberite \"Ploča\" ili \"Komplet\".",
    "gui.dialog.downloadFileError": "Pogreška preuzimanja datoteke",
    "gui.dialog.noSearchModules": "Nisu pronađeni povezani moduli, provjeri putanju pretraživanja",
    "gui.dialog.networkError": "Pogreška mreže",
    "gui.dialog.saveThirdModulesError": "Pogreška prilikom spremanja korisničke knjižnice",
    "gui.dialog.coverUseExForLoadProject": "Knjižnica s istim imenom postoji u korisničkoj biblioteci, koja je zamijenjena korisničkom bibliotekom u projektu",
    "gui.dialog.isContinueUpdateThirdModule": "Trenutno postoje iste knjižnice korisničkih proširenja. Nastavak ažuriranja prebrisat će trenutnu korisničku biblioteku. Želiš li nastaviti?",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "Izmijenjeno je polje 「%2」 od %1, koje je potrebno ručno uvesti u biblioteku proširenja. Ovo osvježenje je otkazano",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "Pogreška analiziranja %1, ovo osvježavanje će se nastaviti, ali sadržaj u 「%2」 neće biti ažuriran",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "Modificira se oblik građevnog bloka ili vrsta okvira za unos. Ručno izbriši označeni sastavni blok u programskom području, a zatim ga osvježi. Ovo osvježenje je otkazano.",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh1": "Za izmjenu oblika bloka ili vrste okvira za unos, ručno izbriši označeni blok u programskom području i provjeri lik「%1」prije osvježavanja.",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh2": "Modificira se oblik bloka ili vrsta okvira za unos. Provjeri lik「%1」i ponovno ga osvježi. Ovo osvježenje je otkazano.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "Promjena oblika građevnog bloka ili vrste okvira za unos, ručno izbriši istaknuti građevni blok u programskom području prije učitavanja i učitavanje se poništava.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad1": "Za izmjenu oblika bloka ili vrste okvira za unos, ručno izbriši označeni blok u programskom području i provjeri lik「%1」prije učitavanja, učitavanje je ovaj put poništeno.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad2": "Modificira se oblik bloka ili vrsta okvira za unos. Provjeri lik「%1」i ponovno ga učitaj. Ovo učitavanje je poništeno.",
    "gui.dialog.thirdex.filesEmpty": "Polje datoteka ne može biti prazno, što može uzrokovati gubitak povezanih datoteka. Provjeri uvezene datoteke korisničke knjižnice!",
    "gui.dialog.thirdex.refreshing": "osvježavajući...",
    "gui.dialog.thirdex.refreshSuccess": "osvježiti uspjeh",
    "gui.dialog.isSaving": "Spremanje...",
    "gui.dialog.isExporting": "Izvoz...",
    "gui.dialog.mpySb3Prompt": "「%1」je ažurirao način rada micropython. Ako postoji bilo kakva greška u sadržaju projekta, trebate je ručno izmijeniti",
    "gui.dialog.translate.fieldError": "Sljedeća polja pogreške mogu uzrokovati pogreške u prikazu građevnih blokova",
    "gui.dialog.translate.startReadFile": "Učitavam datoteku, budi strpljiv...",
    "gui.dialog.translate.fileFormatError": "Ne postoji relevantan sadržaj datoteke prijevoda, format sadržaja datoteke ne odgovara",
    "gui.dialog.connecting.requestCloseFW": "Provjeri je li vatrozid ovog stroja otvoren, što će uzrokovati neuspjeh normalnog povezivanja Tello-a, klikni OK da zatvorite vatrozid",
    "gui.dialog.cache.crash": "Otkriva se neuobičajeno gašenje softvera i projekt se može vratiti putem datoteke predmemorije. Otvori putanju \"projekt\">\"cache list\"",
    "gui.dialog.python.detectPip": "Pip okruženje se inicijalizira, pričekaj",
    "gui.dialog.python.detectPipSucccess": "Inicijalizacija okruženja Pip dovršena",
    "gui.dialog.python.detectPipError": "pip inicijalizacija nije uspjela, funkcija upravljanja knjižnicom nije dostupna. Provjeri mrežnu vezu, također možeš pokrenuti \"[PYTHON]\" -m pip install --upgrade pip u naredbenom retku, a zatim ponovno pokrenuti Koristite ovu značajku.",
    "gui.dialog.python.installPipreqsError": "Instalacija [name] nije uspjela, korisnička knjižnica neće moći normalno izvesti prilagođeni popis ovisnosti knjižnice, instaliraj je ručno.",
    "gui.dialog.screenshoting": "Snimka zaslona u tijeku",
    "gui.dialog.saveScreenshot": "Spremi snimku zaslona",
    "gui.dialog.saveScreenshotSuccess": "Uspješno spremi snimku zaslona",
    "gui.dialog.serialDriverInstalling": "Instalacija serijskog upravljačkog programa...",
    "gui.dialog.resetDeviceSuccess": "Uređaj je uspješno vraćen",
    "gui.dialog.resetDeviceFailed": "Vraćanje uređaja nije uspjelo",
    "gui.dialog.exportSuccess": "Izvoz uspješan",
    "gui.dialog.exportFailed": "Izvoz nije uspio",
    "gui.dialog.exportCostume": "Izvozna uloga",
    "gui.dialog.loadProjectError": "Pogreška pri učitavanju projekta",
    "gui.dialog.readFileError": "Pogreška pri čitanju datoteke",
    "gui.dialog.projectIsDeleted": "Projekt je izbrisan",
    "gui.dialog.projectIsNotExist": "Otvaranje projekta nije uspjelo, datoteka ne postoji",
    "gui.dialog.checkNetwork": "Trenutna mreža nije dostupna, provjeri može li računalo pristupiti internetu",
    "gui.dialog.checkIotNetworkAndParameter": "Veza s internetom stvari nije uspjela, provjeri jesu li parametri ispravno popunjeni",
    "gui.dialog.knownError": "Poznati problem:",
    "gui.dialog.unknownError": "Nepoznat problem:",
    "gui.dialog.cancelInstall": "Odustani od instalacije",
    "gui.dialog.cannotFindDriver": "Datoteka upravljačkog programa nije pronađena, provjeri stazu:",
    "gui.dialog.programError": "Greška programa",
    "gui.dialog.pleaseHandleInstall": "Molimo instaliraj alat ručno:",
    "gui.dialog.installSerialDriverError": "Pogreška pri instaliranju serijskog upravljačkog programa",
    "gui.dialog.installSerialDriverSuccess": "Instalacija serijskog upravljačkog programa dovršena",
    "gui.dialog.hadInstall": "instalirano",
    "gui.dialog.cache.sameAsCacheDeleteBlock": "Sučelje predmemorije ima istu korisničku biblioteku, povezani blok će biti izbrisan, treba li nastaviti",
    "gui.dialog.cache.saveAs": "Spremi kao",
    "gui.dialog.cache.exportExtError": "Pogreška pri izvozu proširenja:",
    "gui.dialog.cache.exportExtSuccess": "Uspjeh izvoza proširenja",
    "gui.dialog.thirdExt.lostVersionKeyWithThird": "Nedostaje polje za verziju, provjeri polje za verziju, trebalo bi biti na istoj razini kao i ID polje",
    "gui.dialog.thirdExt.notFoundRelatedFile": "Nije pronađena povezana datoteka",
    "gui.dialog.thirdExt.requestFileError": "Pogreška pri traženju datoteke",
    "gui.dialog.thirdExt.unzipLibrariesFailed": "Raspakiranje biblioteka.zip nije uspjelo",
    "gui.dialog.thirdExt.pipreqsLostAndFillConfig": "Biblioteka pipreqs nije ispravno instalirana i postoji pogreška u automatskom izvozu liste zavisnih paketa. Radi praktičnosti korisnika, molimo ručno konfiguriraj informacije o ovisnoj knjižnici u config.json",
    "gui.dialog.thirdExt.exportExtToFolderSuccess": "Prošireno je uspješno izvezeno u imenik",
    "gui.dialog.thirdExt.configJsonKeyLost": "'Polje [KEY] u config.json ne može biti prazno!'",
    "gui.dialog.thirdExt.configJsonKeyLost_ID": "ID u config.json je ID modula i ne može biti prazan!",
    "gui.dialog.thirdExt.configJsonKeyLost_platform": "platforma definira podržanu platformu, barem jedna platforma (win, mac, web) je podržana! Trenutno je podržana samo win (winddowns) platforma",
    "gui.dialog.thirdExt.configJsonKeyLost_asset": "Jezični način rada podržan definicijom sredstava u config.json ne može biti prazan!",
    "gui.dialog.thirdExt.configJsonError_asset": "Sadržaj imovine u config.json definiran je pogrešno. Podržan je barem jedan jezični način rada",
    "gui.dialog.thirdExt.configJsonLost_version": "Nedostaje polje za verziju, provjeri polje za verziju, trebalo bi biti na istoj razini kao i ID polje",
    "gui.dialog.thirdExt.configJsonLost_asset_mode": "Glavno polje jezičnog načina [MODE] u config.json ne može biti prazno!",
    "gui.dialog.thirdExt.configJsonError_mode_board": "Ploča polja jezičnog načina rada [MODE] definira status podržane matične ploče i najmanje jedna matična ploča je podržana",
    "gui.dialog.stage.microphoneLost": "Ne čuje se zvuk, provjeri je li mikrofon priključen",
    "gui.dialog.ml5.closeMl5Modal": "Zatvori prozor i izgubit ćeš podatke o obuci, spremi ih na vrijeme, hoćeš li nastaviti sa zatvaranjem",
    "gui.dialog.ml5.restartMl5Train": "Ova operacija će izbrisati sve kategorije, jesi li sigurni da nastavljaš?",
    "gui.dialog.ml5.ml5DefaultLabel": "1. kategorija",
    "gui.dialog.account.getCloudListError": "Pogreška pri dohvaćanju liste datoteka, treba li ponovno učitati",
    "gui.dialog.account.requestCloudList": "Ponovno učitati",
    "gui.dialog.python.getPIPListForUpdateError": "Isteklo je vrijeme za dobivanje podataka ažuriranja PIP-a",
    "gui.dialog.python.pipHasNoUpdate": "Nema dostupnih ažuriranja",
    "gui.dialog.python.pipUpdateSucess": "[SUCCESS] uspješno nadograđeno, [FAILED] neuspješno, odnosno [INFO]",
    "gui.dialog.python.pipUpdateAllSucess": "Nadogradnja je bila uspješna, ukupno [SUCCESS].",
    "gui.dialog.python.pipUpdateError": "Nadogradnja [NAME] nije uspjela. Pojedinosti se mogu vidjeti na stranici PIP moda",
    "gui.dialog.python.pipInstallError": "Instalacija [IME] [VERZIJA] nije uspjela, pojedinosti potraži na stranici načina PIP",
    "gui.dialog.python.pipUninstallSucess": "[SUCCESS] uspješno deinstalirano, [FAILED] neuspješno, odnosno [INFO]",
    "gui.dialog.python.pipUninstallAllSucess": "Deinstalacija je uspjela, ukupno [COUNT].",
    "gui.dialog.python.pipUninstallError": "Deinstalacija [NAME] nije uspjela. Pojedinosti se mogu vidjeti na stranici PIP moda",
    "gui.dialog.python.sourceUnknow": "Nepoznati izvor",
    "gui.dialog.python.sourceTsinghuaUniversity": "Izvor Sveučilišta Tsinghua",
    "gui.dialog.python.sourceDouban": "Douban Izvor",
    "gui.dialog.python.sourceAli": "Ali izvor",
    "gui.dialog.python.sourceNeteasy": "Neteasy Izvor",
    "gui.dialog.python.sourceZGKJDX": "Znanost i tehnologija Kine",
    "gui.dialog.python.sourceOfficial": "Python službeni izvor",
    "gui.dialog.python.rebootSoftForVsEditor": "Prijevod funkcija povezanih s uređivačem koda treba ponovno pokrenuti kako bi stupio na snagu",
    "gui.dialog.procedure.procedureNameIsRepeated": "Funkcija 「[IME]」 je već definirana, preimenuj je",
    "gui.dialog.procedure.procedureNameIsEmpty": "Naziv funkcije ne može biti prazan",
    "gui.dialog.project.saveProjectCheck": "Sekundarna provjera",
    "gui.dialog.project.trySaveProjectAgain": "Žao nam je, došlo je do pogreške prilikom analize spremljene datoteke, pokušaj ponovno spremiti",
    "gui.dialog.project.saveFailed": "Greška pri spremanju",
    "gui.dialog.thirdExt.downloadNow": "Preuzeti sada",
    "gui.dialog.thirdExt.downloadingLib": "Preuzimanje",
    "gui.dialog.thirdExt.downloadFailed": "Preuzimanje nije uspjelo",
    "gui.dialog.thirdExt.detectThirdExtLibrariesInfo": "Otkriveno je da ova korisnička biblioteka zahtijeva [NEED] ovisne biblioteke, [INSTALLED] već postoji, želiš li odmah preuzeti preostale [REST]?",
    "gui.dialog.thirdExt.downloadFinishFailed": "Nije uspjelo preuzimanje [LENGTH] biblioteke, to je [INFO], idi na upravljanje bibliotekom kako bi vidio određene informacije",
    "gui.dialog.thirdExt.downloadFinishSuccess": "[LENGTH] biblioteke su uspješno preuzete, za detalje idi na upravljanje bibliotekom",
    "gui.dialog.pythonRemote.detectingRemoteLibraries": "Otkrivanje zavisnih verzija biblioteke na [NAME], pričekajte...",
    "gui.dialog.pythonRemote.detectRemoteLibrariesFinish": "Otkrivanje dovršeno, sve nove verzije",
    "gui.dialog.pythonRemote.detectRemoteLibrariesInfo": "Otkriveno je da se [LENGTH] biblioteka na <b>[NAME]</b> može nadograditi, želiš li to sada preuzeti?",
    "gui.dialog.pythonRemote.detectRemotePipError": "PIP nije dostupan u udaljenom sustavu, potvrdi postojanje Python okruženja prije korištenja funkcije upravljanja knjižnicom",
    "BACKDROPS_ARCTIC": "Arktik",
    "BACKDROPS_BASEBALL": "Bejzbol",
    "BACKDROPS_BASEBALL_2": "Bejzbol 2",
    "BACKDROPS_BASKETBALL": "Košarka",
    "BACKDROPS_BASKETBALL_2": "Košarka 2",
    "BACKDROPS_BEACH_MALIBU": "Plaža Malibu",
    "BACKDROPS_BEACH_RIO": "Plaža Rio",
    "BACKDROPS_BEDROOM": "Spavaća soba",
    "BACKDROPS_BEDROOM_1": "Spavaća soba 1",
    "BACKDROPS_BEDROOM_2": "Spavaća soba 2",
    "BACKDROPS_BEDROOM_3": "Spavaća soba 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Klupa Sa Pogledom",
    "BACKDROPS_BLUE_SKY": "Plavo nebo",
    "BACKDROPS_BLUE_SKY_2": "Plavo nebo 2",
    "BACKDROPS_BLUE_SKY3": "Plavo nebo 3",
    "BACKDROPS_BOARDWALK": "Riva",
    "BACKDROPS_CANYON": "Kanjon",
    "BACKDROPS_CASTLE_1": "Dvorac 1",
    "BACKDROPS_CASTLE_2": "Dvorac 2",
    "BACKDROPS_CASTLE_3": "Dvorac 3",
    "BACKDROPS_CASTLE_4": "Dvorac 4",
    "BACKDROPS_CHALKBOARD": "Ploca",
    "BACKDROPS_CIRCLES": "Krugovi",
    "BACKDROPS_CITY_WITH_WATER": "Grad s vodom",
    "BACKDROPS_COLORFUL_CITY": "Šareni grad",
    "BACKDROPS_CONCERT": "Koncert",
    "BACKDROPS_DESERT": "Pustinja",
    "BACKDROPS_FARM": "Farma",
    "BACKDROPS_FIELD_AT_MIT": "Polje na Mit",
    "BACKDROPS_FLOWERS": "Cvijeće",
    "BACKDROPS_FOREST": "Šuma",
    "BACKDROPS_GALAXY": "Galaksija",
    "BACKDROPS_GARDEN-ROCK": "Vrtna stijena",
    "BACKDROPS_GREEK_THEATER": "Grčko kazalište",
    "BACKDROPS_HALL": "Dvorana",
    "BACKDROPS_HAY_FIELD": "Polje sijena",
    "BACKDROPS_GRAFFITI": "Grafiti",
    "BACKDROPS_HEARTS1": "Srca1",
    "BACKDROPS_HEARTS2": "Srca2",
    "BACKDROPS_HILL": "Brdo",
    "BACKDROPS_JUNGLE": "Džungla",
    "BACKDROPS_JURASSIC": "Jurski",
    "BACKDROPS_LIGHT": "Svjetlo",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": "Mjesec",
    "BACKDROPS_MOUNTAIN": "Planina",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "Maglica",
    "BACKDROPS_NEON_TUNNEL": "Neonski tunel",
    "BACKDROPS_NIGHT_CITY": "Noćni grad",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Noćni grad s ulicom",
    "BACKDROPS_PARTY": "Zabava",
    "BACKDROPS_PATHWAY": "Put",
    "BACKDROPS_PARTY_ROOM": "Party soba",
    "BACKDROPS_PLAYGROUND": "Igralište",
    "BACKDROPS_PLAYING_FIELD": "Igralište",
    "BACKDROPS_POOL": "Bazen",
    "BACKDROPS_PURPLE": "Ljubičasta",
    "BACKDROPS_RAYS": "Zrake",
    "BACKDROPS_REFRIGERATOR": "Hladnjak",
    "BACKDROPS_ROOM_1": "Soba 1",
    "BACKDROPS_ROOM_2": "Soba 2",
    "BACKDROPS_SAVANNA": "Savana",
    "BACKDROPS_SCHOOL": "Škola",
    "BACKDROPS_SLOPES": "Padine",
    "BACKDROPS_SOCCER": "Nogomet",
    "BACKDROPS_SOCCER_2": "Nogomet 2",
    "BACKDROPS_SPACE": "Prostor",
    "BACKDROPS_SPACE_2": "Prostor 2",
    "BACKDROPS_SPACE_3": "Prostor 3",
    "BACKDROPS_SPACE_4": "Prostor 4",
    "BACKDROPS_SPOTLIGHT-STAGE": "Reflektor-pozornica",
    "BACKDROPS_STARS": "Zvijezde",
    "BACKDROPS_STRIPES": "Pruge",
    "BACKDROPS_THEATER": "Kazalište",
    "BACKDROPS_THEATER_2": "Kazalište 2",
    "BACKDROPS_TREE": "Drvo",
    "BACKDROPS_UNDERWATER_1": "Pod vodom 1",
    "BACKDROPS_UNDERWATER_2": "Pod vodom 2",
    "BACKDROPS_URBAN": "Urbani",
    "BACKDROPS_WALL_1": "Zid 1",
    "BACKDROPS_WALL_2": "Zid 2",
    "BACKDROPS_WATER_AND_ROCKS": "Voda i stijene",
    "BACKDROPS_WETLAND": "Močvarno područje",
    "BACKDROPS_WINTER": "Zima",
    "BACKDROPS_WINTER-LIGHTS": "Zimska svjetla",
    "BACKDROPS_WITCH_HOUSE": "Kuća vještica",
    "BACKDROPS_WOODS": "Šume",
    "BACKDROPS_WOODS_AND_BENCH": "Šuma i klupa",
    "BACKDROPS_XY-GRID": "Xy-mreža",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "Abby-a",
    "COSTUMES_ABBY-B": "Abby-b",
    "COSTUMES_ABBY-C": "Abby-c",
    "COSTUMES_ABBY-D": "Abby-d",
    "COSTUMES_AMON": "Amon",
    "COSTUMES_ANDIE-A": "Andie-a",
    "COSTUMES_ANDIE-B": "Andie-b",
    "COSTUMES_ANDIE-C": "Andie-c",
    "COSTUMES_ANDIE-D": "Andie-d",
    "COSTUMES_ANINA_POP_DOWN": "Anina Pop Down",
    "COSTUMES_ANINA_POP_FRONT": "Anina Pop Front",
    "COSTUMES_ANINA_POP_L_ARM": "Anina Pop L Arm",
    "COSTUMES_ANINA_POP_LEFT": "Anina Pop Lijevo",
    "COSTUMES_ANINA_POP_R_ARM": "Anina Pop R Arm",
    "COSTUMES_ANINA_POP_RIGHT": "Anina Pop Desno",
    "COSTUMES_ANINA_POP_STAND": "Anina Pop Stand",
    "COSTUMES_ANINA_R_CROSS": "Anina R Križ",
    "COSTUMES_ANINA_STANCE": "Anina Stance",
    "COSTUMES_ANINA_TOP_FREEZE": "Anina Top Freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "Anina Top L Step",
    "COSTUMES_ANINA_TOP_R_STEP": "Anina Top R Step",
    "COSTUMES_ANINA_TOP_STAND": "Anina Gornji stalak",
    "COSTUMES_APPLE": "Jabuka",
    "COSTUMES_ARROW1-A": "Strelica1-a",
    "COSTUMES_ARROW1-B": "Strelica1-b",
    "COSTUMES_ARROW1-C": "Strelica 1-c",
    "COSTUMES_ARROW1-D": "Strelica1-d",
    "COSTUMES_AVERY_WALKING-A": "Avery walking-a",
    "COSTUMES_AVERY_WALKING-B": "Avery walking-b",
    "COSTUMES_AVERY_WALKING-C": "Avery walking-c",
    "COSTUMES_AVERY_WALKING-D": "Avery walking-d",
    "COSTUMES_AVERY-A": "Avery-a",
    "COSTUMES_AVERY-B": "Avery-b",
    "COSTUMES_BALL-A": "Lopta-a",
    "COSTUMES_BALL-B": "Lopta-b",
    "COSTUMES_BALL-C": "Lopta-c",
    "COSTUMES_BALL-D": "Lopta-d",
    "COSTUMES_BALL-E": "Ball-e",
    "COSTUMES_BALLERINA-A": "Balerina-a",
    "COSTUMES_BALLERINA-B": "Balerina-b",
    "COSTUMES_BALLERINA-C": "Balerina-c",
    "COSTUMES_BALLERINA-D": "Balerina-d",
    "COSTUMES_BALLOON1-A": "Balon1-a",
    "COSTUMES_BALLOON1-B": "Balon1-b",
    "COSTUMES_BALLOON1-C": "Balon1-c",
    "COSTUMES_BANANAS": "Banane",
    "COSTUMES_BASEBALL": "Bejzbol",
    "COSTUMES_BASKETBALL": "Košarka",
    "COSTUMES_BAT-A": "Šišmiš-a",
    "COSTUMES_BAT-B": "Šišmiš-b",
    "COSTUMES_BAT-C": "Šišmiš-c",
    "COSTUMES_BAT-D": "Šišmiš-d",
    "COSTUMES_BATTER-A": "Bacač-a",
    "COSTUMES_BATTER-B": "Bacač-b",
    "COSTUMES_BATTER-C": "Bacač-c",
    "COSTUMES_BATTER-D": "Bacač-d",
    "COSTUMES_BEACHBALL": "Lopta za plažu",
    "COSTUMES_BEAR-A": "Medvjed-a",
    "COSTUMES_BEAR-B": "Medvjed-b",
    "COSTUMES_BEAR-WALK-A": "Medvjed-hod-a",
    "COSTUMES_BEAR-WALK-B": "Medvjed-hod-b",
    "COSTUMES_BEAR-WALK-C": "Medvjed-hod-c",
    "COSTUMES_BEAR-WALK-D": "Medvjed-hod-d",
    "COSTUMES_BEAR-WALK-E": "Medvjed-hod-e",
    "COSTUMES_BEAR-WALK-F": "Medvjed-hod-f",
    "COSTUMES_BEAR-WALK-G": "Medvjed-hod-g",
    "COSTUMES_BEAR-WALK-H": "Medvjed-hod-h",
    "COSTUMES_BEETLE": "Buba",
    "COSTUMES_BELL1": "Zvono1",
    "COSTUMES_BEN-A": "Ben-a",
    "COSTUMES_BEN-B": "Ben-b",
    "COSTUMES_BEN-C": "Ben-c",
    "COSTUMES_BEN-D": "Ben-d",
    "COSTUMES_BOWL-A": "Zdjela-a",
    "COSTUMES_BOWTIE": "leptir mašna",
    "COSTUMES_BREAD": "Kruh",
    "COSTUMES_BROOM": "Metla",
    "COSTUMES_BUILDING-A": "Zgrada-a",
    "COSTUMES_BUILDING-B": "Zgrada-b",
    "COSTUMES_BUILDING-C": "Zgrada-c",
    "COSTUMES_BUILDING-D": "Zgrada-d",
    "COSTUMES_BUILDING-E": "Zgrada-e",
    "COSTUMES_BUILDING-F": "Zgrada-f",
    "COSTUMES_BUILDING-G": "Zgrada-g",
    "COSTUMES_BUILDING-H": "Zgrada-h",
    "COSTUMES_BUILDING-I": "Zgrada-i",
    "COSTUMES_BUILDING-J": "Zgrada-j",
    "COSTUMES_BUTTERFLY1-A": "Leptir1-a",
    "COSTUMES_BUTTERFLY1-B": "Leptir1-b",
    "COSTUMES_BUTTERFLY1-C": "Leptir1-c",
    "COSTUMES_BUTTERFLY2-A": "Leptir2-a",
    "COSTUMES_BUTTERFLY2-B": "Leptir2-b",
    "COSTUMES_BUTTON1": "Gumb1",
    "COSTUMES_BUTTON2-A": "Gumb2-a",
    "COSTUMES_BUTTON2-B": "Gumb2-b",
    "COSTUMES_BUTTON3-A": "Gumb3-a",
    "COSTUMES_BUTTON3-B": "Gumb3-b",
    "COSTUMES_BUTTON4-A": "Gumb 4-a",
    "COSTUMES_BUTTON4-B": "Gumb 4-b",
    "COSTUMES_BUTTON5-A": "Gumb5-a",
    "COSTUMES_BUTTON5-B": "Gumb5-b",
    "COSTUMES_CAKE-A": "Kolač-a",
    "COSTUMES_CAKE-B": "Kolač-b",
    "COSTUMES_CALVRETT_JUMPING": "Calvrett skače",
    "COSTUMES_CALVRETT_THINKING": "Razmišljanje Calvreta",
    "COSTUMES_CASEY-A": "Casey-a",
    "COSTUMES_CASEY-B": "Casey-b",
    "COSTUMES_CASEY-C": "Casey-c",
    "COSTUMES_CASEY-D": "casey-d",
    "COSTUMES_CASSY-A": "Cassy-a",
    "COSTUMES_CASSY-B": "Cassy-b",
    "COSTUMES_CASSY-C": "Cassy-c",
    "COSTUMES_CASSY-D": "Cassy-d",
    "COSTUMES_CAT_2": "mačka 2",
    "COSTUMES_CATCHER-A": "Hvatač-a",
    "COSTUMES_CATCHER-B": "Hvatač-b",
    "COSTUMES_CATCHER-C": "Hvatač-c",
    "COSTUMES_CATCHER-D": "Hvatač-d",
    "COSTUMES_CENTAUR-A": "Kentaur-a",
    "COSTUMES_CENTAUR-B": "Kentaur-b",
    "COSTUMES_CENTAUR-C": "kentaur-c",
    "COSTUMES_CENTAUR-D": "kentaur-d",
    "COSTUMES_CHAMP99-A": "Champ99-a",
    "COSTUMES_CHAMP99-B": "Champ99-b",
    "COSTUMES_CHAMP99-C": "Champ99-c",
    "COSTUMES_CHAMP99-D": "Champ99-d",
    "COSTUMES_CHAMP99-E": "Champ99-e",
    "COSTUMES_CHAMP99-F": "Champn99-f",
    "COSTUMES_CHAMP99-G": "Champ99-g",
    "COSTUMES_CHEESY_PUFFS": "Puffs sa sirom",
    "COSTUMES_CHICK-A": "pilić-a",
    "COSTUMES_CHICK-B": "pilić-b",
    "COSTUMES_CHICK-C": "pilić-c",
    "COSTUMES_CITY_BUS-A": "Gradski autobus-a",
    "COSTUMES_CITY_BUS-B": "Gradski autobus-b",
    "COSTUMES_CLOUD": "Oblak",
    "COSTUMES_CLOUD-A": "Oblak-a",
    "COSTUMES_CLOUD-B": "Oblak-b",
    "COSTUMES_CLOUD-C": "Oblak-c",
    "COSTUMES_CLOUD-D": "Oblak-d",
    "COSTUMES_CONVERTIBLE": "Kabriolet",
    "COSTUMES_CONVERTIBLE_3": "kabriolet 2",
    "COSTUMES_CRAB-A": "Rak-a",
    "COSTUMES_CRAB-B": "Rak-b",
    "COSTUMES_CRYSTAL-A": "Kristal-a",
    "COSTUMES_CRYSTAL-B": "Kristalno-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "Dee-a",
    "COSTUMES_DEE-B": "Dee-b",
    "COSTUMES_DEE-C": "Dee-c",
    "COSTUMES_DEE-D": "Djelo",
    "COSTUMES_DEE-E": "Dee-e",
    "COSTUMES_DEVIN-A": "Devin-a",
    "COSTUMES_DEVIN-B": "Devin-b",
    "COSTUMES_DEVIN-C": "Devin-c",
    "COSTUMES_DEVIN-D": "Devin-d",
    "COSTUMES_DINOSAUR1-A": "Dinosaur1-a",
    "COSTUMES_DINOSAUR1-B": "Dinosaur1-b",
    "COSTUMES_DINOSAUR1-C": "Dinosaur1-c",
    "COSTUMES_DINOSAUR1-D": "Dinosaur1-d",
    "COSTUMES_DINOSAUR2-A": "Dinosaur2-a",
    "COSTUMES_DINOSAUR2-B": "Dinosaur 2-b",
    "COSTUMES_DINOSAUR2-C": "Dinosaur 2-c",
    "COSTUMES_DINOSAUR2-D": "Dinosaur 2-d",
    "COSTUMES_DINOSAUR3-A": "Dinosaur3-a",
    "COSTUMES_DINOSAUR3-B": "Dinosaur3-b",
    "COSTUMES_DINOSAUR3-C": "Dinosaur3-c",
    "COSTUMES_DINOSAUR3-D": "Dinosaur 3-d",
    "COSTUMES_DINOSAUR3-E": "Dinosaur 3-e",
    "COSTUMES_DINOSAUR4-A": "Dinosaur4-a",
    "COSTUMES_DINOSAUR4-B": "Dinosaur4-b",
    "COSTUMES_DINOSAUR4-C": "Dinosaur4-c",
    "COSTUMES_DINOSAUR4-D": "Dinosaur4-d",
    "COSTUMES_DIVER1": "Ronilac1",
    "COSTUMES_DIVER2": "Ronilac2",
    "COSTUMES_DM_FREEZE": "dm smrzavanje",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop lijevo",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop desno",
    "COSTUMES_DM_POP_STAND": "dm pop stalak",
    "COSTUMES_DM_STANCE": "dm stav",
    "COSTUMES_DM_TOP_L_LEG": "dm gornja L noga",
    "COSTUMES_DM_TOP_R_LEG": "dm gornja R noga",
    "COSTUMES_DM_TOP_R_LEG2": "dm gornja R noga2",
    "COSTUMES_DM_TOP_STAND": "dm gornji stalak",
    "COSTUMES_DOG1-A": "Pas1-a",
    "COSTUMES_DOG1-B": "Pas1-b",
    "COSTUMES_DOG2-A": "Pas2-a",
    "COSTUMES_DOG2-B": "Pas2-b",
    "COSTUMES_DOG2-C": "Pas2-c",
    "COSTUMES_DONUT": "Krafna",
    "COSTUMES_DORIAN-A": "Dorijan-a",
    "COSTUMES_DORIAN-B": "Dorijan-b",
    "COSTUMES_DORIAN-C": "Dorijan-c",
    "COSTUMES_DORIAN-D": "Dorijan-d",
    "COSTUMES_DOT-A": "Točka-a",
    "COSTUMES_DOT-B": "Točka-b",
    "COSTUMES_DOT-C": "Točka-c",
    "COSTUMES_DOT-D": "Točka-d",
    "COSTUMES_DOVE-A": "Golub-a",
    "COSTUMES_DOVE-B": "Golubica-b",
    "COSTUMES_DRAGON-A": "Zmaj-a",
    "COSTUMES_DRAGON-B": "Zmaj-b",
    "COSTUMES_DRAGON-C": "Zmaj-c",
    "COSTUMES_DRAGON1-A": "Zmaj1-a",
    "COSTUMES_DRAGON1-B": "Zmaj1-b",
    "COSTUMES_DRAGONFLY-A": "Vilin konjic-a",
    "COSTUMES_DRAGONFLY-B": "Dragonfly-b",
    "COSTUMES_DRESS-A": "Haljina-a",
    "COSTUMES_DRESS-B": "Haljina-b",
    "COSTUMES_DRESS-C": "Haljina-c",
    "COSTUMES_DRUM-A": "Bubanj-a",
    "COSTUMES_DRUM-B": "Bubanj-b",
    "COSTUMES_DRUM-CYMBAL-A": "Bubanj-cimbal-a",
    "COSTUMES_DRUM-CYMBAL-B": "Bubanj-cimbal-b",
    "COSTUMES_DRUM-HIGHHAT-A": "Drum-highhat-a",
    "COSTUMES_DRUM-HIGHHAT-B": "Drum-highhat-b",
    "COSTUMES_DRUM-KIT": "Komplet bubnjeva",
    "COSTUMES_DRUM-KIT-B": "Komplet bubnjeva-b",
    "COSTUMES_DRUM-SNARE-A": "Bubanj-zamka-a",
    "COSTUMES_DRUM-SNARE-B": "Bubanj-zamka-b",
    "COSTUMES_DRUMS_CONGA-A": "Bubnjevi Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Bubnjevi Conga-b",
    "COSTUMES_DUCK": "Patka",
    "COSTUMES_EARTH": "Zemlja",
    "COSTUMES_EASEL-A": "Štafelaj-a",
    "COSTUMES_EASEL-B": "Štafelaj-b",
    "COSTUMES_EASEL-C": "Štafelaj-c",
    "COSTUMES_EGG-A": "Jaje-a",
    "COSTUMES_EGG-B": "Jaje-b",
    "COSTUMES_EGG-C": "Jaje-c",
    "COSTUMES_EGG-D": "Jaje-d",
    "COSTUMES_EGG-E": "Jaje-e",
    "COSTUMES_EGG-F": "Jaje-f",
    "COSTUMES_ELEPHANT-A": "Slon-a",
    "COSTUMES_ELEPHANT-B": "Slon-b",
    "COSTUMES_ELF-A": "Vilenjak-a",
    "COSTUMES_ELF-B": "Vilenjak-b",
    "COSTUMES_ELF-C": "Vilenjak-c",
    "COSTUMES_ELF-D": "Elf-d",
    "COSTUMES_ELF-E": "Vilenjak-e",
    "COSTUMES_FAIRY-A": "Vila-a",
    "COSTUMES_FAIRY-B": "Vila-b",
    "COSTUMES_FAIRY-C": "Vila-c",
    "COSTUMES_FAIRY-D": "Vila-d",
    "COSTUMES_FAIRY-E": "Vila-e",
    "COSTUMES_FISH-A": "Riba-a",
    "COSTUMES_FISH-B": "Riba-b",
    "COSTUMES_FISH-C": "Riba-c",
    "COSTUMES_FISH-D": "Riba-d",
    "COSTUMES_FISHBOWL-A": "Fishbowl-a",
    "COSTUMES_FISHBOWL-B": "Zdjela za ribu-b",
    "COSTUMES_FOOD_TRUCK-A": "Kamion s hranom-a",
    "COSTUMES_FOOD_TRUCK-B": "Kamion s hranom-b",
    "COSTUMES_FOOD_TRUCK-C": "Kamion s hranom-c",
    "COSTUMES_FOOTBALL_RUNNING": "Nogomet Trčanje",
    "COSTUMES_FOOTBALL_STANDING": "Nogomet Stojeći",
    "COSTUMES_FORTUNE_COOKIE": "Kolačić sudbine",
    "COSTUMES_FOX-A": "Lisica-a",
    "COSTUMES_FOX-B": "Lisica-b",
    "COSTUMES_FOX-C": "Lisica-c",
    "COSTUMES_FRANK-A": "Frank-a",
    "COSTUMES_FRANK-B": "Frank-b",
    "COSTUMES_FRANK-C": "Frank-c",
    "COSTUMES_FRANK-D": "Frank-d",
    "COSTUMES_FROG": "Žaba",
    "COSTUMES_FROG_2-A": "Žaba 2-a",
    "COSTUMES_FROG_2-B": "Žaba 2-b",
    "COSTUMES_FROG_2-C": "Žaba 2-c",
    "COSTUMES_FRUIT_PLATTER": "Plata s voćem",
    "COSTUMES_FRUITSALAD": "Voćna salata",
    "COSTUMES_GHOST-A": "Duh-a",
    "COSTUMES_GHOST-B": "Duh-b",
    "COSTUMES_GHOST-C": "Duh-c",
    "COSTUMES_GHOST-D": "Duh-d",
    "COSTUMES_GIFT-A": "Poklon-a",
    "COSTUMES_GIFT-B": "Poklon-b",
    "COSTUMES_GIRAFFE-A": "Žirafa-a",
    "COSTUMES_GIRAFFE-B": "Žirafa-b",
    "COSTUMES_GIRAFFE-C": "Žirafa-c",
    "COSTUMES_GLASS_WATER-A": "Staklena voda-a",
    "COSTUMES_GLASS_WATER-B": "Čaša vode-b",
    "COSTUMES_GLASSES-A": "Naočale-a",
    "COSTUMES_GLASSES-B": "Naočale-b",
    "COSTUMES_GLASSES-C": "Naočale-c",
    "COSTUMES_GLASSES-E": "Naočale-e",
    "COSTUMES_GOALIE-A": "Vratar-a",
    "COSTUMES_GOALIE-B": "Vratar-b",
    "COSTUMES_GOALIE-C": "vratar-c",
    "COSTUMES_GOALIE-D": "Vratar-d",
    "COSTUMES_GOALIE-E": "Vratar-e",
    "COSTUMES_GOBLIN-A": "Goblin-a",
    "COSTUMES_GOBLIN-B": "Goblin-b",
    "COSTUMES_GOBLIN-C": "Goblin-c",
    "COSTUMES_GOBLIN-D": "Goblin-d",
    "COSTUMES_GRASSHOPPER-A": "Skakavac-a",
    "COSTUMES_GRASSHOPPER-B": "Skakavac-b",
    "COSTUMES_GRASSHOPPER-C": "Skakavac-c",
    "COSTUMES_GRASSHOPPER-D": "Skakavac-d",
    "COSTUMES_GRASSHOPPER-E": "Skakavac-e",
    "COSTUMES_GRASSHOPPER-F": "Skakavac-f",
    "COSTUMES_GREEN_FLAG": "Zelena zastava",
    "COSTUMES_GRIFFIN-A": "Griffin-a",
    "COSTUMES_GRIFFIN-B": "Griffin-b",
    "COSTUMES_GRIFFIN-C": "Griffin-c",
    "COSTUMES_GRIFFIN-D": "Griffin-d",
    "COSTUMES_GUITAR-A": "gitara-a",
    "COSTUMES_GUITAR-B": "gitara-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "Gitara-električna1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "Gitara-električna1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "Gitara-električna2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "Gitara-električna2-b",
    "COSTUMES_HANNAH-A": "Hana-a",
    "COSTUMES_HANNAH-B": "Hana-b",
    "COSTUMES_HANNAH-C": "Hana-c",
    "COSTUMES_HARE-A": "Zec-a",
    "COSTUMES_HARE-B": "Zec-b",
    "COSTUMES_HARE-C": "Zec-c",
    "COSTUMES_HARPER-A": "Harper-a",
    "COSTUMES_HARPER-B": "Harper-b",
    "COSTUMES_HARPER-C": "Harper-c",
    "COSTUMES_HAT-A": "Šešir-a",
    "COSTUMES_HAT-B": "Šešir-b",
    "COSTUMES_HAT-C": "Šešir-c",
    "COSTUMES_HAT-D": "Šešir-d",
    "COSTUMES_HATCHLING-A": "Mladić-a",
    "COSTUMES_HATCHLING-B": "Mladić-b",
    "COSTUMES_HATCHLING-C": "Mladić-c",
    "COSTUMES_HEART_CODE": "Šifra srca",
    "COSTUMES_HEART_FACE": "Srce Lice",
    "COSTUMES_HEART_LOVE": "Srce Ljubav",
    "COSTUMES_HEART_PURPLE": "Ljubičasto srce",
    "COSTUMES_HEART_RED": "Crveno srce",
    "COSTUMES_HEART_SMILE": "Osmijeh srca",
    "COSTUMES_HEART_SWEET": "Srce Slatko",
    "COSTUMES_HEDGEHOG-A": "Jež-a",
    "COSTUMES_HEDGEHOG-B": "Jež-b",
    "COSTUMES_HEDGEHOG-C": "Jež-c",
    "COSTUMES_HEDGEHOG-D": "Jež-d",
    "COSTUMES_HEDGEHOG-E": "Jež-e",
    "COSTUMES_HEN-A": "Kokoš-a",
    "COSTUMES_HEN-B": "Kokoš-b",
    "COSTUMES_HEN-C": "Kokoš-c",
    "COSTUMES_HEN-D": "Kokoš-d",
    "COSTUMES_HIPPO1-A": "Nilski konj1-a",
    "COSTUMES_HIPPO1-B": "Nilski konj1-b",
    "COSTUMES_HOME_BUTTON": "Gumb Početna",
    "COSTUMES_HORSE-A": "Konj-a",
    "COSTUMES_HORSE-B": "Konj-b",
    "COSTUMES_JAIME_WALKING-A": "Jaime Walking-a",
    "COSTUMES_JAIME_WALKING-B": "Jaime Walking-b",
    "COSTUMES_JAIME_WALKING-C": "Jaime Walking-c",
    "COSTUMES_JAIME_WALKING-D": "Jaime Walking-d",
    "COSTUMES_JAIME_WALKING-E": "Jaime Walking-e",
    "COSTUMES_JAIME-A": "Jaime-a",
    "COSTUMES_JAIME-B": "Jaime-b",
    "COSTUMES_JAMAL-A": "Džemal-a",
    "COSTUMES_JAMAL-B": "Džemal-b",
    "COSTUMES_JAMAL-C": "Jamal-c",
    "COSTUMES_JAMAL-D": "Džemal-d",
    "COSTUMES_JAR-A": "Jar-a",
    "COSTUMES_JAR-B": "Jar-b",
    "COSTUMES_JELLYFISH-A": "Meduza-a",
    "COSTUMES_JELLYFISH-B": "Meduza-b",
    "COSTUMES_JELLYFISH-C": "Meduza-c",
    "COSTUMES_JELLYFISH-D": "Meduza-d",
    "COSTUMES_JO_POP_DOWN": "Jo Pop Down",
    "COSTUMES_JO_POP_FRONT": "Jo Pop Front",
    "COSTUMES_JO_POP_L_ARM": "Jo Pop L Arm",
    "COSTUMES_JO_POP_LEFT": "Jo Pop Lijevo",
    "COSTUMES_JO_POP_R_ARM": "Jo Pop R Arm",
    "COSTUMES_JO_POP_RIGHT": "Jo Pop U pravu",
    "COSTUMES_JO_POP_STAND": "Jo Pop Stand",
    "COSTUMES_JO_STANCE": "Jo Stance",
    "COSTUMES_JO_TOP_L_CROSS": "Jo Top L Križ",
    "COSTUMES_JO_TOP_L_LEG": "Jo Gornja L noga",
    "COSTUMES_JO_TOP_R_CROSS": "Jo Top R Cross",
    "COSTUMES_JO_TOP_R_LEG": "Jo Top R Leg",
    "COSTUMES_JO_TOP_STAND": "Jo Top Stand",
    "COSTUMES_JORDYN-A": "Jordin-a",
    "COSTUMES_JORDYN-B": "Jordin-b",
    "COSTUMES_JORDYN-C": "Jordyn-c",
    "COSTUMES_JORDYN-D": "Jordyn-d",
    "COSTUMES_KAI-A": "Kai-a",
    "COSTUMES_KAI-B": "Kai-b",
    "COSTUMES_KEY": "Ključ",
    "COSTUMES_KEYBOARD-A": "Tipkovnica-a",
    "COSTUMES_KEYBOARD-B": "Tipkovnica-b",
    "COSTUMES_KIRAN-A": "Kiran-a",
    "COSTUMES_KIRAN-B": "Kiran-b",
    "COSTUMES_KIRAN-C": "Kiran-c",
    "COSTUMES_KIRAN-D": "Kiran-d",
    "COSTUMES_KIRAN-E": "Kiran-e",
    "COSTUMES_KIRAN-F": "Kiran-f",
    "COSTUMES_KNIGHT": "Vitez",
    "COSTUMES_LADYBUG2": "Bubamara1",
    "COSTUMES_LADYBUG2-A": "Bubamara2-a",
    "COSTUMES_LADYBUG2-B": "Bubamara2-b",
    "COSTUMES_LAPTOP": "Prijenosno računalo",
    "COSTUMES_LB_POP_DOWN": "Lb Pop Down",
    "COSTUMES_LB_POP_FRONT": "Lb pop front",
    "COSTUMES_LB_POP_L_ARM": "Lb Pop L Arm",
    "COSTUMES_LB_POP_LEFT": "Lb Pop Lijevo",
    "COSTUMES_LB_POP_R_ARM": "Lb Pop R Arm",
    "COSTUMES_LB_POP_RIGHT": "Lb Pop Desno",
    "COSTUMES_LB_POP_STAND": "Lb pop stalak",
    "COSTUMES_LB_STANCE": "lb stav",
    "COSTUMES_LB_TOP_L_CROSS": "Lb Vrh L Križ",
    "COSTUMES_LB_TOP_L_LEG": "Lb Gornja L noga",
    "COSTUMES_LB_TOP_R_CROSS": "Lb Gornji R Križ",
    "COSTUMES_LB_TOP_R_LEG": "Lb Gornja R noga",
    "COSTUMES_LB_TOP_STAND": "Lb Gornji stalak",
    "COSTUMES_LIGHTNING": "Munja",
    "COSTUMES_LINE": "Crta",
    "COSTUMES_LION-A": "Lav-a",
    "COSTUMES_LION-B": "Lav-b",
    "COSTUMES_LION-C": "Lav-c",
    "COSTUMES_LLAMA": "Lama",
    "COSTUMES_LLAMA-B": "Lama-b",
    "COSTUMES_LLAMA-C": "Lama-c",
    "COSTUMES_MAGICWAND": "Čarobni štapić",
    "COSTUMES_MAX-A": "Max-a",
    "COSTUMES_MAX-B": "Max-b",
    "COSTUMES_MAX-C": "Max-c",
    "COSTUMES_MAX-D": "Max-d",
    "COSTUMES_MERMAID-A": "Sirena-a",
    "COSTUMES_MERMAID-B": "Sirena-b",
    "COSTUMES_MERMAID-C": "Sirena-c",
    "COSTUMES_MERMAID-D": "Sirena-d",
    "COSTUMES_MICROPHONE-A": "Mikrofon-a",
    "COSTUMES_MICROPHONE-B": "Mikrofon-b",
    "COSTUMES_MILK-A": "Mlijeko-a",
    "COSTUMES_MILK-B": "Mlijeko-b",
    "COSTUMES_MILK-C": "Mlijeko-c",
    "COSTUMES_MILK-D": "Mlijeko-d",
    "COSTUMES_MILK-E": "Mlijeko-e",
    "COSTUMES_MONET-A": "Monet-a",
    "COSTUMES_MONET-B": "Monet-b",
    "COSTUMES_MONET-C": "Monet-c",
    "COSTUMES_MONET-D": "Monet-d",
    "COSTUMES_MONET-E": "Monet-e",
    "COSTUMES_MONKEY-A": "Majmun-a",
    "COSTUMES_MONKEY-B": "Majmun-b",
    "COSTUMES_MONKEY-C": "Majmun-c",
    "COSTUMES_MOTORCYCLE-A": "Motocikl-a",
    "COSTUMES_MOTORCYCLE-B": "Motocikl-b",
    "COSTUMES_MOTORCYCLE-C": "Motocikl-c",
    "COSTUMES_MOTORCYCLE-D": "Motocikl-d",
    "COSTUMES_MOUSE1-A": "Miš1-a",
    "COSTUMES_MOUSE1-B": "Miš1-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "Susjed Pony",
    "COSTUMES_OCTOPUS-A": "Hobotnica-a",
    "COSTUMES_OCTOPUS-B": "Hobotnica-b",
    "COSTUMES_OCTOPUS-C": "Hobotnica-c",
    "COSTUMES_OCTOPUS-D": "Hobotnica-d",
    "COSTUMES_OCTOPUS-E": "Hobotnica-e",
    "COSTUMES_ORANGE": "naranča",
    "COSTUMES_ORANGE2-A": "Narančasta2-a",
    "COSTUMES_ORANGE2-B": "Narančasta2-b",
    "COSTUMES_OUTFIELDER-A": "Vanjski igrač-a",
    "COSTUMES_OUTFIELDER-B": "Vanjski igrač-b",
    "COSTUMES_OUTFIELDER-C": "Vanjski igrač-c",
    "COSTUMES_OUTFIELDER-D": "Vanjski igrač-d",
    "COSTUMES_OWL-A": "Sova-a",
    "COSTUMES_OWL-B": "Sova-b",
    "COSTUMES_OWL-C": "Sova-c",
    "COSTUMES_PADDLE": "Veslo",
    "COSTUMES_PANTHER-A": "Pantera-a",
    "COSTUMES_PANTHER-B": "Pantera-b",
    "COSTUMES_PANTHER-C": "Pantera-c",
    "COSTUMES_PANTS-A": "Hlače-a",
    "COSTUMES_PANTS-B": "Hlače-b",
    "COSTUMES_PARROT-A": "Papiga-a",
    "COSTUMES_PARROT-B": "Papiga-b",
    "COSTUMES_PARTY_HAT-A": "Party Hat-a",
    "COSTUMES_PARTY_HAT-B": "Šešir za zabavu-b",
    "COSTUMES_PARTY_HAT-E": "Kapa za zabavu-c",
    "COSTUMES_PENCIL-A": "Olovka-a",
    "COSTUMES_PENCIL-B": "Olovka-b",
    "COSTUMES_PENGUIN-A": "Pingvin-a",
    "COSTUMES_PENGUIN-B": "Pingvin-b",
    "COSTUMES_PENGUIN-C": "Pingvin-c",
    "COSTUMES_PENGUIN2-A": "Pingvin2-a",
    "COSTUMES_PENGUIN2-B": "Pingvin2-b",
    "COSTUMES_PENGUIN2-C": "Pingvin2-c",
    "COSTUMES_PENGUIN2-D": "Pingvin2-d",
    "COSTUMES_PITCHER-A": "Vrč-a",
    "COSTUMES_PITCHER-B": "Vrč-b",
    "COSTUMES_PITCHER-C": "Vrč-c",
    "COSTUMES_PITCHER-D": "Vrč-d",
    "COSTUMES_PLANET2": "Planet2",
    "COSTUMES_POLAR_BEAR-A": "Polarni medvjed-a",
    "COSTUMES_POLAR_BEAR-B": "Polarni medvjed-b",
    "COSTUMES_POLAR_BEAR-C": "Polarni medvjed-c",
    "COSTUMES_POTION-A": "Napitak-a",
    "COSTUMES_POTION-B": "Napitak-b",
    "COSTUMES_POTION-C": "Napitak-c",
    "COSTUMES_PRINCE": "Princ",
    "COSTUMES_PRINCESS-A": "Princeza-a",
    "COSTUMES_PRINCESS-B": "Princeza-b",
    "COSTUMES_PRINCESS-C": "Princeza-c",
    "COSTUMES_PRINCESS-D": "Princeza-d",
    "COSTUMES_PRINCESS-E": "Princeza-e",
    "COSTUMES_PUFFERFISH-A": "Riba pufer-a",
    "COSTUMES_PUFFERFISH-B": "Puharica-b",
    "COSTUMES_PUFFERFISH-C": "Puharica-c",
    "COSTUMES_PUFFERFISH-D": "Puharica-d",
    "COSTUMES_PUPPY_BACK": "Puppy Back",
    "COSTUMES_PUPPY_RIGHT": "Psić Desno",
    "COSTUMES_PUPPY_SIDE": "Šteneća strana",
    "COSTUMES_PUPPY_SIT": "Psić Sjedi",
    "COSTUMES_RABBIT-A": "Zec-a",
    "COSTUMES_RABBIT-B": "Zec-b",
    "COSTUMES_RABBIT-C": "Zec-c",
    "COSTUMES_RABBIT-D": "Zec-d",
    "COSTUMES_RABBIT-E": "Zec-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": "Duga",
    "COSTUMES_REFEREE-A": "sudac-a",
    "COSTUMES_REFEREE-B": "sudac-b",
    "COSTUMES_REFEREE-C": "sudac-c",
    "COSTUMES_REFEREE-D": "sudac-d",
    "COSTUMES_REINDEER": "sob",
    "COSTUMES_RETRO_ROBOT_A": "Retro robot a",
    "COSTUMES_RETRO_ROBOT_B": "Retro robot b",
    "COSTUMES_RETRO_ROBOT_C": "Retro robot c",
    "COSTUMES_RIPLEY-A": "Ripley-a",
    "COSTUMES_RIPLEY-B": "Ripley-b",
    "COSTUMES_RIPLEY-C": "Ripley-c",
    "COSTUMES_RIPLEY-D": "Ripley-d",
    "COSTUMES_RIPLEY-E": "Ripley-e",
    "COSTUMES_RIPLEY-F": "Ripley-f",
    "COSTUMES_ROBOT-A": "Robot-a",
    "COSTUMES_ROBOT-B": "Robot-b",
    "COSTUMES_ROBOT-C": "Robot-c",
    "COSTUMES_ROBOT-D": "Robot-d",
    "COSTUMES_ROCKETSHIP-A": "Raketni brod-a",
    "COSTUMES_ROCKETSHIP-B": "Raketni brod-b",
    "COSTUMES_ROCKETSHIP-C": "Raketni brod-c",
    "COSTUMES_ROCKETSHIP-D": "Raketni brod-d",
    "COSTUMES_ROCKETSHIP-E": "Raketni brod-e",
    "COSTUMES_ROCKS": "Stijene",
    "COSTUMES_ROOSTER-A": "Pijetao-a",
    "COSTUMES_ROOSTER-B": "Pijetao-b",
    "COSTUMES_ROOSTER-C": "Pijetao-c",
    "COSTUMES_RUBY-A": "Ruby-a",
    "COSTUMES_RUBY-B": "Rubin-b",
    "COSTUMES_SAILBOAT": "Jedrilica",
    "COSTUMES_SAM": "Sam",
    "COSTUMES_SAXOPHONE-A": "Saksofon-a",
    "COSTUMES_SAXOPHONE-B": "saksofon-b",
    "COSTUMES_SCARF-A": "Šal-a",
    "COSTUMES_SCARF-B": "Šal-b",
    "COSTUMES_SCARF-C": "Šal-c",
    "COSTUMES_SHARK-A": "Morski pas-a",
    "COSTUMES_SHARK-B": "Morski pas-b",
    "COSTUMES_SHARK2-A": "Morski pas2-a",
    "COSTUMES_SHARK2-B": "Morski pas2-b",
    "COSTUMES_SHARK2-C": "Morski pas2-c",
    "COSTUMES_SHIRT-A": "Košulja-a",
    "COSTUMES_SHOES-A": "Cipele-a",
    "COSTUMES_SHOES-B": "Cipele-b",
    "COSTUMES_SHOES-C": "Cipele-c",
    "COSTUMES_SHOES-D": "Cipele-d",
    "COSTUMES_SHORTS-A": "Kratke hlače-a",
    "COSTUMES_SHORTS-B": "Kratke hlače-b",
    "COSTUMES_SHORTS-C": "Kratke hlače-c",
    "COSTUMES_SINGER1": "Pjevač1",
    "COSTUMES_SKELETON-A": "Kostur-a",
    "COSTUMES_SKELETON-B": "Kostur-b",
    "COSTUMES_SKELETON-D": "Kostur-d",
    "COSTUMES_SKELETON-E": "Kostur-e",
    "COSTUMES_SNAKE-A": "Zmija-a",
    "COSTUMES_SNAKE-B": "Zmija-b",
    "COSTUMES_SNAKE-C": "Zmija-c",
    "COSTUMES_SNOWFLAKE": "Pahuljica",
    "COSTUMES_SNOWMAN": "Snjegović",
    "COSTUMES_SOCCER_BALL": "Nogometna lopta",
    "COSTUMES_SPEAKER": "Zvučnik",
    "COSTUMES_SQUIRREL": "Vjeverica",
    "COSTUMES_STAR": "Zvijezda",
    "COSTUMES_STARFISH-A": "Morska zvijezda-a",
    "COSTUMES_STARFISH-B_": "Morska zvijezda-b",
    "COSTUMES_STOP": "Stop",
    "COSTUMES_STRAWBERRY-A": "Jagoda-a",
    "COSTUMES_STRAWBERRY-B": "Jagoda-b",
    "COSTUMES_STRAWBERRY-C": "Jagoda-c",
    "COSTUMES_STRAWBERRY-D": "Jagoda-d",
    "COSTUMES_STRAWBERRY-E": "Jagoda-e",
    "COSTUMES_SUN": "Sunce",
    "COSTUMES_SUNGLASSES-A": "Sunčane naočale-a",
    "COSTUMES_SUNGLASSES-B": "Sunčane naočale-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-čarobnjak",
    "COSTUMES_TAKEOUT-A": "Takeout-a",
    "COSTUMES_TAKEOUT-B": "Za van-b",
    "COSTUMES_TAKEOUT-C": "Za van-c",
    "COSTUMES_TAKEOUT-D": "Za van-d",
    "COSTUMES_TAKEOUT-E": "Takeout-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 Pop Down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 pop front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 Pop L arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 Pop lijevo",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 Pop R Arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 Pop Right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 pop stalak",
    "COSTUMES_TEN80_STANCE": "Ten80 stav",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 gornje zamrzavanje",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 gornja L stepenica",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 gornji R križ",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 gornji R korak",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 gornji stalak",
    "COSTUMES_TENNISBALL": "Teniska loptica",
    "COSTUMES_TOUCAN-A": "Tukan-a",
    "COSTUMES_TOUCAN-B": "Tukan-b",
    "COSTUMES_TOUCAN-C": "Tukan-c",
    "COSTUMES_TRAMPOLINE": "Trampolin",
    "COSTUMES_TREE1": "Drvo1",
    "COSTUMES_TREES-A": "Drveće-a",
    "COSTUMES_TREES-B": "Drveće-b",
    "COSTUMES_TRUCK-A": "Kamion-a",
    "COSTUMES_TRUCK-B": "Kamion-b",
    "COSTUMES_TRUCK-C": "Kamion-c",
    "COSTUMES_TRUMPET-A": "Truba-a",
    "COSTUMES_TRUMPET-B": "Truba-b",
    "COSTUMES_UNICORN": "Jednorog",
    "COSTUMES_UNICORN_2": "Jednorog 2",
    "COSTUMES_UNICORN_RUNNING-A": "Jednorog koji trči-a",
    "COSTUMES_UNICORN_RUNNING-B": "Jednorog koji trči-b",
    "COSTUMES_UNICORN_RUNNING-C": "Jednorog koji trči-c",
    "COSTUMES_UNICORN_RUNNING-D": "Jednorog koji trči-d",
    "COSTUMES_UNICORN_RUNNING-E": "Trčanje jednoroga",
    "COSTUMES_UNICORN_RUNNING-F": "Jednorog koji trči-f",
    "COSTUMES_WAND": "Štapić",
    "COSTUMES_WANDA": "Wanda",
    "COSTUMES_WATERMELON-A": "Lubenica-a",
    "COSTUMES_WATERMELON-B": "Lubenica-b",
    "COSTUMES_WATERMELON-C": "Lubenica-c",
    "COSTUMES_WINTER_HAT": "Zimska kapa",
    "COSTUMES_WITCH": "Vještica",
    "COSTUMES_WITCH-A": "Vještica-a",
    "COSTUMES_WITCH-B": "Vještica-b",
    "COSTUMES_WITCH-C": "Vještica-c",
    "COSTUMES_WITCH-D": "Vještica-d",
    "COSTUMES_WIZARD_HAT": "Čarobnjački šešir",
    "COSTUMES_WIZARD-A": "Čarobnjak-a",
    "COSTUMES_WIZARD-B": "Čarobnjak-b",
    "COSTUMES_WIZARD-C": "Čarobnjak-c",
    "COSTUMES_WIZARD-TOAD-A": "Čarobnjak-žaba-a",
    "COSTUMES_WIZARD-TOAD-B": "Čarobnjak-žaba-b",
    "COSTUMES_ZEBRA-A": "Zebra-a",
    "COSTUMES_ZEBRA-B": "Zebra-b",
    "COSTUMES_BLOCK-A": "Blok-a",
    "COSTUMES_BLOCK-B": "Blok-b",
    "COSTUMES_BLOCK-C": "Blokiraj-c",
    "COSTUMES_BLOCK-D": "Blokiraj-d",
    "COSTUMES_BLOCK-E": "Blokiraj-e",
    "COSTUMES_BLOCK-F": "Blokiraj-f",
    "COSTUMES_BLOCK-G": "Blok-g",
    "COSTUMES_BLOCK-H": "Blokiraj-h",
    "COSTUMES_BLOCK-I": "Blokiraj-i",
    "COSTUMES_BLOCK-J": "Blokiraj-j",
    "COSTUMES_BLOCK-K": "Blokiraj-k",
    "COSTUMES_BLOCK-L": "Blok-l",
    "COSTUMES_BLOCK-M": "Blok-m",
    "COSTUMES_BLOCK-N": "Blokiraj-n",
    "COSTUMES_BLOCK-O": "Blokiraj-o",
    "COSTUMES_BLOCK-P": "Blokiraj-str",
    "COSTUMES_BLOCK-Q": "Blokiraj-q",
    "COSTUMES_BLOCK-R": "Blokiraj-r",
    "COSTUMES_BLOCK-S": "Blokovi",
    "COSTUMES_BLOCK-T": "Blokiraj-t",
    "COSTUMES_BLOCK-U": "Blokiraj",
    "COSTUMES_BLOCK-V": "Blokiraj-v",
    "COSTUMES_BLOCK-W": "Blokiraj-w",
    "COSTUMES_BLOCK-X": "Blokiraj-x",
    "COSTUMES_BLOCK-Y": "Blokiraj",
    "COSTUMES_BLOCK-Z": "Blokiraj-z",
    "COSTUMES_GLOW-0": "Sjaj-0",
    "COSTUMES_GLOW-1": "Sjaj-1",
    "COSTUMES_GLOW-2": "Sjaj-2",
    "COSTUMES_GLOW-3": "Sjaj-3",
    "COSTUMES_GLOW-4": "Sjaj-4",
    "COSTUMES_GLOW-5": "Sjaj-5",
    "COSTUMES_GLOW-6": "Sjaj-6",
    "COSTUMES_GLOW-7": "Sjaj-7",
    "COSTUMES_GLOW-8": "Sjaj-8",
    "COSTUMES_GLOW-9": "Sjaj-9",
    "COSTUMES_GLOW-A": "Sjaj-A",
    "COSTUMES_GLOW-B": "Sjaj-B",
    "COSTUMES_GLOW-C": "Sjaj-C",
    "COSTUMES_GLOW-D": "Sjaj-D",
    "COSTUMES_GLOW-E": "Sjaj-E",
    "COSTUMES_GLOW-F": "Sjaj-F",
    "COSTUMES_GLOW-G": "Sjaj-G",
    "COSTUMES_GLOW-H": "Sjaj-H",
    "COSTUMES_GLOW-I": "Sjaj-I",
    "COSTUMES_GLOW-J": "Glow-J",
    "COSTUMES_GLOW-K": "Sjaj-K",
    "COSTUMES_GLOW-L": "Sjaj-L",
    "COSTUMES_GLOW-M": "Sjaj-M",
    "COSTUMES_GLOW-N": "Sjaj-N",
    "COSTUMES_GLOW-O": "Sjaj-O",
    "COSTUMES_GLOW-P": "Sjaj-P",
    "COSTUMES_GLOW-Q": "Sjaj-Q",
    "COSTUMES_GLOW-R": "Sjaj-R",
    "COSTUMES_GLOW-S": "Sjaj-S",
    "COSTUMES_GLOW-T": "Sjaj-T",
    "COSTUMES_GLOW-U": "Sjaj-U",
    "COSTUMES_GLOW-V": "Sjaj-V",
    "COSTUMES_GLOW-W": "Sjaj-W",
    "COSTUMES_GLOW-X": "Sjaj-X",
    "COSTUMES_GLOW-Y": "Sjaj-Y",
    "COSTUMES_GLOW-Z": "Sjaj-Z",
    "COSTUMES_STORY-A-1": "Priča-A-1",
    "COSTUMES_STORY-A-2": "Priča-A-2",
    "COSTUMES_STORY-A-3": "Priča-A-3",
    "COSTUMES_STORY-B-1": "Priča-B-1",
    "COSTUMES_STORY-B-2": "Priča-B-2",
    "COSTUMES_STORY-B-3": "Priča-B-3",
    "COSTUMES_STORY-C-1": "Priča-C-1",
    "COSTUMES_STORY-C-2": "Priča-C-2",
    "COSTUMES_STORY-C-3": "Priča-C-3",
    "COSTUMES_STORY-D-1": "Priča-D-1",
    "COSTUMES_STORY-D-2": "Priča-D-2",
    "COSTUMES_STORY-D-3": "Priča-D-3",
    "COSTUMES_STORY-E-1": "Priča-E-1",
    "COSTUMES_STORY-E-2": "Priča-E-2",
    "COSTUMES_STORY-E-3": "Priča-E-3",
    "COSTUMES_STORY-F-1": "Priča-F-1",
    "COSTUMES_STORY-F-2": "Priča-F-2",
    "COSTUMES_STORY-F-3": "Priča-F-3",
    "COSTUMES_STORY-G-1": "Priča-G-1",
    "COSTUMES_STORY-G-2": "Priča-G-2",
    "COSTUMES_STORY-G-3": "Priča-G-3",
    "COSTUMES_STORY-H-1": "Priča-H-1",
    "COSTUMES_STORY-H-2": "Priča-H-2",
    "COSTUMES_STORY-H-3": "Priča-H-3",
    "COSTUMES_STORY-I-1": "Priča-I-1",
    "COSTUMES_STORY-I-2": "Priča-I-2",
    "COSTUMES_STORY-I-3": "Priča-I-3",
    "COSTUMES_STORY-J-1": "Priča-J-1",
    "COSTUMES_STORY-J-2": "Priča-J-2",
    "COSTUMES_STORY-J-3": "Priča-J-3",
    "COSTUMES_STORY-K-1": "Priča-K-1",
    "COSTUMES_STORY-K-2": "Priča-K-2",
    "COSTUMES_STORY-K-3": "priča-K-3",
    "COSTUMES_STORY-L-1": "Priča-L-1",
    "COSTUMES_STORY-L-2": "Priča-L-2",
    "COSTUMES_STORY-L-3": "Priča-L-3",
    "COSTUMES_STORY-M-1": "Priča-M-1",
    "COSTUMES_STORY-M-2": "Priča-M-2",
    "COSTUMES_STORY-M-3": "Priča-M-3",
    "COSTUMES_STORY-N-1": "Priča-N-1",
    "COSTUMES_STORY-N-2": "Priča-N-2",
    "COSTUMES_STORY-N-3": "Priča-N-3",
    "COSTUMES_STORY-O-1": "Priča-O-1",
    "COSTUMES_STORY-O-2": "Priča-O-2",
    "COSTUMES_STORY-O-3": "Priča-O-3",
    "COSTUMES_STORY-P-1": "Priča-P-1",
    "COSTUMES_STORY-P-2": "Priča-P-2",
    "COSTUMES_STORY-P-3": "Priča-P-3",
    "COSTUMES_STORY-Q-1": "Priča-Q-1",
    "COSTUMES_STORY-Q-2": "Priča-Q-2",
    "COSTUMES_STORY-Q-3": "Priča-Q-3",
    "COSTUMES_STORY-R-1": "Priča-R-1",
    "COSTUMES_STORY-R-2": "Priča-R-2",
    "COSTUMES_STORY-R-3": "Priča-R-3",
    "COSTUMES_STORY-S-1": "Priča-S-1",
    "COSTUMES_STORY-S-2": "Priča-S-2",
    "COSTUMES_STORY-S-3": "Priča-S-3",
    "COSTUMES_STORY-T-1": "Priča-T-1",
    "COSTUMES_STORY-T-2": "Priča-T-2",
    "COSTUMES_STORY-T-3": "Priča-T-3",
    "COSTUMES_STORY-U-1": "Priča-U-1",
    "COSTUMES_STORY-U-2": "Priča-U-2",
    "COSTUMES_STORY-U-3": "Priča-U-3",
    "COSTUMES_STORY-V-1": "Priča-V-1",
    "COSTUMES_STORY-V-2": "Priča-V-2",
    "COSTUMES_STORY-V-3": "Priča-V-3",
    "COSTUMES_STORY-W-1": "Priča-W-1",
    "COSTUMES_STORY-W-2": "Priča-W-2",
    "COSTUMES_STORY-W-3": "Priča-W-3",
    "COSTUMES_STORY-X-1": "Priča-X-1",
    "COSTUMES_STORY-X-2": "Priča-X-2",
    "COSTUMES_STORY-X-3": "Priča-X-3",
    "COSTUMES_STORY-Y-1": "Priča-Y-1",
    "COSTUMES_STORY-Y-2": "Priča-Y-2",
    "COSTUMES_STORY-Y-3": "Priča-Y-3",
    "COSTUMES_STORY-Z-1": "Priča-Z-1",
    "COSTUMES_STORY-Z-2": "Priča-Z-2",
    "COSTUMES_STORY-Z-3": "Priča-Z-3",
    "COSTUMES_STEAMED-STUFFED-BUN": "Punjena lepinja kuhana na pari",
    "COSTUMES_LUCKY-BAG": "Vreća iz koje se izvlače srećke",
    "COSTUMES_DUMPLINGS": "Knedle",
    "COSTUMES_GOLD-COINS": "Zlatni novčići",
    "COSTUMES_A-GIFT": "Poklon-A",
    "COSTUMES_STEAMED-BREAD": "Kruh kuhan na pari",
    "COSTUMES_PLATES": "Ploče",
    "COSTUMES_SILVER-INGOT": "Srebrni polugac",
    "COSTUMES_FIRECRACKERS": "Petarde",
    "COSTUMES_FIRECRACKERS-1": "Petarde 1",
    "COSTUMES_FIRECRACKERS-2": "Petarde 2",
    "COSTUMES_FIRECRACKERS-3": "Petarde 3",
    "COSTUMES_LANTERN": "fenjer",
    "COSTUMES_LANTERN-1": "Lampion 1",
    "COSTUMES_LANTERN-2": "Lampion 2",
    "COSTUMES_DRUM": "Bubanj-A",
    "COSTUMES_DRUM-1": "Bubanj 1",
    "COSTUMES_DRUM-2": "Bubanj 2",
    "COSTUMES_DRUM-3": "Bubanj 3",
    "COSTUMES_RED-ENVELOPE": "Crvena omotnica",
    "COSTUMES_RED-ENVELOPE-1": "Crvena omotnica 1",
    "COSTUMES_RED-ENVELOPE-2": "Crvena omotnica 2",
    "COSTUMES_SCROLL": "Svitak",
    "COSTUMES_SCROLL-1": "Pomaknite se 1",
    "COSTUMES_SCROLL-2": "Pomaknite se 2",
    "COSTUMES_SCROLL-3": "Pomaknite se 3",
    "COSTUMES_YETI": "Yeti-A",
    "COSTUMES_YETI-1": "Yeti 1",
    "COSTUMES_YETI-2": "Yeti 2",
    "COSTUMES_FIREWORKS": "Vatromet",
    "COSTUMES_FIREWORKS-1": "Vatromet 1",
    "COSTUMES_FIREWORKS-2": "Vatromet 2",
    "COSTUMES_FIREWORKS-3": "Vatromet 3",
    "COSTUMES_FIREWORKS-4": "Vatromet 4",
    "COSTUMES_FIREWORKS-5": "Vatromet 5",
    "COSTUMES_FIREWORKS-6": "Vatromet 6",
    "COSTUMES_FIREWORKS-7": "Vatromet 7",
    "COSTUMES_FIREWORKS-8": "Vatromet 8",
    "COSTUMES_FIREWORKS-9": "Vatromet 9",
    "SOUNDS_A_BASS": "Bas",
    "SOUNDS_A_ELEC_BASS": "Elec bas",
    "SOUNDS_A_ELEC_GUITAR": "Električna gitara",
    "SOUNDS_A_ELEC_PIANO": "Elec klavir",
    "SOUNDS_A_GUITAR": "Gitara",
    "SOUNDS_A_MINOR_UKULELE": "Mali ukulele",
    "SOUNDS_A_PIANO": "Klavir",
    "SOUNDS_A_SAX": "Saksofon",
    "SOUNDS_A_TROMBONE": "Trombon",
    "SOUNDS_A_TRUMPET": "Truba",
    "SOUNDS_AFRO_STRING": "Afro žica",
    "SOUNDS_ALERT": "uzbuna",
    "SOUNDS_ALIEN_CREAK1": "Vanzemaljska škripa 1",
    "SOUNDS_ALIEN_CREAK2": "Vanzemaljska škripa 2",
    "SOUNDS_B_BASS": "B Bas",
    "SOUNDS_B_ELEC_BASS": "B Elec bas",
    "SOUNDS_B_ELEC_GUITAR": "B električna gitara",
    "SOUNDS_B_ELEC_PIANO": "B Elec klavir",
    "SOUNDS_B_GUITAR": "B gitara",
    "SOUNDS_B_PIANO": "B glasovir",
    "SOUNDS_B_SAX": "B saksofon",
    "SOUNDS_B_TROMBONE": "B trombon",
    "SOUNDS_B_TRUMPET": "B Truba",
    "SOUNDS_BAA": "Baa",
    "SOUNDS_BARK": "Kora",
    "SOUNDS_BASKETBALL_BOUNCE": "Košarkaška lopta",
    "SOUNDS_BASS_BEATBOX": "Bas beatbox",
    "SOUNDS_BEAT_BOX1": "Beat Box1",
    "SOUNDS_BEAT_BOX2": "Beat Box 2",
    "SOUNDS_BELL_CYMBAL": "zvonasta činela",
    "SOUNDS_BELL_TOLL": "Zvono zvona",
    "SOUNDS_BIG_BOING": "Veliki Boing",
    "SOUNDS_BIRD": "Ptica",
    "SOUNDS_BIRTHDAY": "Rođendan",
    "SOUNDS_BITE": "Ugristi",
    "SOUNDS_BOING": "Boing",
    "SOUNDS_BONK": "Bonk",
    "SOUNDS_BOOM_CLOUD": "Boom Cloud",
    "SOUNDS_BOOP_BING_BOP": "Boop Bing Bop",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": "Bowling Strike",
    "SOUNDS_BUBBLES": "Mjehurići",
    "SOUNDS_BUZZ_WHIR": "Buzz Whir",
    "SOUNDS_C_BASS": "C bas",
    "SOUNDS_C_ELEC_BASS": "C Elec bas",
    "SOUNDS_C_ELEC_GUITAR": "C električna gitara",
    "SOUNDS_C_ELEC_PIANO": "C Elec klavir",
    "SOUNDS_C_GUITAR": "C gitara",
    "SOUNDS_C_MAJOR_UKULELE": "C-dur ukulele",
    "SOUNDS_C_PIANO": "C glasovir",
    "SOUNDS_C_SAX": "C saksofon",
    "SOUNDS_C_TROMBONE": "C trombon",
    "SOUNDS_C_TRUMPET": "C Truba",
    "SOUNDS_C2_BASS": "C2 bas",
    "SOUNDS_C2_ELEC_BASS": "C2 Elec bas",
    "SOUNDS_C2_ELEC_GUITAR": "C2 električna gitara",
    "SOUNDS_C2_ELEC_PIANO": "C2 Elec klavir",
    "SOUNDS_C2_GUITAR": "C2 gitara",
    "SOUNDS_C2_PIANO": "C2 glasovir",
    "SOUNDS_C2_SAX": "C2 saksofon",
    "SOUNDS_C2_TROMBONE": "C2 trombon",
    "SOUNDS_C2_TRUMPET": "C2 truba",
    "SOUNDS_CAR_HORN": "Truba",
    "SOUNDS_CAR_PASSING": "Prolazak automobila",
    "SOUNDS_CAR_VROOM": "Auto Vroom",
    "SOUNDS_CAVE": "Špilja",
    "SOUNDS_CHATTER": "Brbljanje",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "Bodriti",
    "SOUNDS_CHILL": "Ohladite se",
    "SOUNDS_CHIRP": "Cvrkut",
    "SOUNDS_CHOMP": "Chomp",
    "SOUNDS_CHORD": "Akord",
    "SOUNDS_CLANG": "zveket",
    "SOUNDS_CLAP_BEATBOX": "Pljeskati Beatbox",
    "SOUNDS_CLAPPING": "Pljeskanje",
    "SOUNDS_CLASSICAL_PIANO": "Klasični klavir",
    "SOUNDS_CLOCK_TICKING": "Sat otkucava",
    "SOUNDS_CLOWN_HONK": "Clown Honk",
    "SOUNDS_COIN": "Novčić",
    "SOUNDS_COLLECT": "Skupljati",
    "SOUNDS_COMPUTER_BEEP": "Zvučni signal računala 1",
    "SOUNDS_COMPUTER_BEEP2": "Zvučni signal računala 2",
    "SOUNDS_CONNECT": "Spojiti",
    "SOUNDS_COUGH1": "Kašalj 1",
    "SOUNDS_COUGH2": "kašalj2",
    "SOUNDS_CRANK": "Ručica",
    "SOUNDS_CRASH_BEATBOX": "Crash Beatbox",
    "SOUNDS_CRASH_CYMBAL": "crash cimbal",
    "SOUNDS_CRAZY_LAUGH": "Ludi smijeh",
    "SOUNDS_CRICKET": "Kriket",
    "SOUNDS_CRICKETS": "Cvrčci",
    "SOUNDS_CROAK": "graknuti",
    "SOUNDS_CROWD_GASP": "Udah gomile",
    "SOUNDS_CROWD_LAUGH": "Smijeh gomile",
    "SOUNDS_CRUNCH": "Škripanje",
    "SOUNDS_CYMBAL": "činele",
    "SOUNDS_CYMBAL_CRASH": "Cymbal Crash",
    "SOUNDS_CYMBAL_ECHO": "Cymbal Echo",
    "SOUNDS_D_BASS": "D Bas",
    "SOUNDS_D_ELEC_BASS": "D Elec bas",
    "SOUNDS_D_ELEC_GUITAR": "D električna gitara",
    "SOUNDS_D_ELEC_PIANO": "D Elec klavir",
    "SOUNDS_D_GUITAR": "D gitara",
    "SOUNDS_D_PIANO": "D Klavir",
    "SOUNDS_D_SAX": "D saksofon",
    "SOUNDS_D_TROMBONE": "D trombon",
    "SOUNDS_D_TRUMPET": "D Truba",
    "SOUNDS_DANCE_AROUND": "Pleši okolo",
    "SOUNDS_DANCE_CELEBRATE": "Ples Slavi",
    "SOUNDS_DANCE_CELEBRATE2": "Ples slavi 2",
    "SOUNDS_DANCE_CHILL_OUT": "Dance Chill Out",
    "SOUNDS_DANCE_ENERGETIC": "Energičan ples",
    "SOUNDS_DANCE_FUNKY": "Dance Funky",
    "SOUNDS_DANCE_HEAD_NOD": "Plesna glava Kimanje",
    "SOUNDS_DANCE_MAGIC": "Plesna čarolija",
    "SOUNDS_DANCE_SITAR": "Ples Sitar",
    "SOUNDS_DANCE_SLOW_MO": "Dance Slow Mo",
    "SOUNDS_DANCE_SNARE_BEAT": "Dance Snare Beat",
    "SOUNDS_DANCE_SPACE": "Plesni prostor",
    "SOUNDS_DISCONNECT": "Prekini vezu",
    "SOUNDS_DOG1": "Pas",
    "SOUNDS_DOG2": "Pas2",
    "SOUNDS_DOOR_CLOSING": "Zatvaranje vrata",
    "SOUNDS_DOOR_CREAK": "Škripa vrata",
    "SOUNDS_DOORBELL": "Zvonce na vratima",
    "SOUNDS_DRIP_DROP": "Drip Drop",
    "SOUNDS_DRIVE_AROUND": "Vozite se okolo",
    "SOUNDS_DRUM": "Bubanj",
    "SOUNDS_DRUM_BASS1": "Bas bubanj 1",
    "SOUNDS_DRUM_BASS2": "Bas bubanj 2",
    "SOUNDS_DRUM_BASS3": "Bas bubanj 3",
    "SOUNDS_DRUM_BOING": "Bubanj Boing",
    "SOUNDS_DRUM_BUZZ": "Zujanje bubnja",
    "SOUNDS_DRUM_FUNKY": "Drum Funky",
    "SOUNDS_DRUM_JAM": "Drum Jam",
    "SOUNDS_DRUM_MACHINE": "Drum Machine",
    "SOUNDS_DRUM_ROLL": "bubanj",
    "SOUNDS_DRUM_SATELLITE": "Satelit bubnja",
    "SOUNDS_DRUM_SET1": "Set bubnjeva 1",
    "SOUNDS_DRUM_SET2": "Set bubnjeva 2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "Patka",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dunnn",
    "SOUNDS_E_BASS": "E bas",
    "SOUNDS_E_ELEC_BASS": "E Elec bas",
    "SOUNDS_E_ELEC_GUITAR": "E električna gitara",
    "SOUNDS_E_ELEC_PIANO": "E Elec klavir",
    "SOUNDS_E_GUITAR": "E gitara",
    "SOUNDS_E_PIANO": "E glasovir",
    "SOUNDS_E_SAX": "E saksofon",
    "SOUNDS_E_TROMBONE": "E trombon",
    "SOUNDS_E_TRUMPET": "E truba",
    "SOUNDS_EGGS": "jaja",
    "SOUNDS_ELEC_PIANO_A_MINOR": "Elec klavir a-mol",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "Elec klavir C-dur",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "Elec klavir F-dur",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "Elec klavir G-dur",
    "SOUNDS_ELEC_PIANO_LOOP": "Elec Piano Loop",
    "SOUNDS_EMOTIONAL_PIANO": "Emocionalni klavir",
    "SOUNDS_ENGINE": "Motor",
    "SOUNDS_F_BASS": "F Bas",
    "SOUNDS_F_ELEC_BASS": "F Elec bas",
    "SOUNDS_F_ELEC_GUITAR": "F električna gitara",
    "SOUNDS_F_ELEC_PIANO": "F Elec klavir",
    "SOUNDS_F_GUITAR": "F gitara",
    "SOUNDS_F_MAJOR_UKULELE": "F-dur ukulele",
    "SOUNDS_F_PIANO": "F glasovir",
    "SOUNDS_F_SAX": "F saksofon",
    "SOUNDS_F_TROMBONE": "F trombon",
    "SOUNDS_F_TRUMPET": "F Truba",
    "SOUNDS_FAIRYDUST": "Vilina prašina",
    "SOUNDS_FINGER_SNAP": "Pucketanje prstiju",
    "SOUNDS_FLAM_SNARE": "Flam Snare",
    "SOUNDS_FOOTSTEPS": "Koraci",
    "SOUNDS_G_BASS": "G Bas",
    "SOUNDS_G_ELEC_BASS": "G Elec bas",
    "SOUNDS_G_ELEC_GUITAR": "G električna gitara",
    "SOUNDS_G_ELEC_PIANO": "G Elec klavir",
    "SOUNDS_G_GUITAR": "G gitara",
    "SOUNDS_G_PIANO": "G Klavir",
    "SOUNDS_G_SAX": "G saksofon",
    "SOUNDS_G_TROMBONE": "G Trombon",
    "SOUNDS_G_TRUMPET": "G Truba",
    "SOUNDS_G_UKULELE": "G Ukulele",
    "SOUNDS_GALLOP": "Galop",
    "SOUNDS_GARDEN": "Vrt",
    "SOUNDS_GLASS_BREAKING": "Razbijanje stakla",
    "SOUNDS_GLUG": "Glug",
    "SOUNDS_GOAL_CHEER": "Goal Cheer",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Guska",
    "SOUNDS_GROWL": "Režanje",
    "SOUNDS_GRUNT": "Roktati",
    "SOUNDS_GUITAR_CHORDS1": "Akordi za gitaru 1",
    "SOUNDS_GUITAR_CHORDS2": "Akordi za gitaru 2",
    "SOUNDS_GUITAR_STRUM": "Gitara",
    "SOUNDS_HAND_CLAP": "Pljesak",
    "SOUNDS_HEAD_SHAKE": "Odmahivanje glavom",
    "SOUNDS_HEY": "hej",
    "SOUNDS_HI_BEATBOX": "Bok Beatbox",
    "SOUNDS_HI_NA_TABLA": "Bok Na Tabla",
    "SOUNDS_HI_TUN_TABLA": "Bok Tun Tabla",
    "SOUNDS_HIGH_CONGA": "Visoka Conga",
    "SOUNDS_HIGH_HAT": "Snob",
    "SOUNDS_HIGH_TOM": "Visoki Tom",
    "SOUNDS_HIGH_WHOOSH": "Visoki Whoosh",
    "SOUNDS_HIHAT_BEATBOX": "Hihat Beatbox",
    "SOUNDS_HIHAT_CYMBAL": "Hihat Cymbal",
    "SOUNDS_HIP_HOP": "Hip hop",
    "SOUNDS_HORSE": "Konj",
    "SOUNDS_HORSE_GALLOP": "konjski galop",
    "SOUNDS_HUMAN_BEATBOX1": "Ljudski beatbox1",
    "SOUNDS_HUMAN_BEATBOX2": "Ljudski beatbox2",
    "SOUNDS_JUMP": "Skok",
    "SOUNDS_JUNGLE": "Džungla",
    "SOUNDS_JUNGLE_FROGS": "Žabe iz džungle",
    "SOUNDS_KICK_BACK": "Natrag",
    "SOUNDS_KICK_DRUM": "bubanj",
    "SOUNDS_LARGE_COWBELL": "Veliko kravlje zvono",
    "SOUNDS_LASER1": "laser1",
    "SOUNDS_LASER2": "laser2",
    "SOUNDS_LAUGH1": "smij se1",
    "SOUNDS_LAUGH2": "smij se2",
    "SOUNDS_LAUGH3": "smij se3",
    "SOUNDS_LO_GEH_TABLA": "Lo Geh Tabla",
    "SOUNDS_LO_GLISS_TABLA": "Lo Gliss Tabla",
    "SOUNDS_LOSE": "Izgubiti",
    "SOUNDS_LOW_BOING": "Niski Boing",
    "SOUNDS_LOW_CONGA": "Niska Conga",
    "SOUNDS_LOW_SQUEAK": "Niska škripa",
    "SOUNDS_LOW_TOM": "Niski Tom",
    "SOUNDS_LOW_WHOOSH": "Nizak Whoosh",
    "SOUNDS_MACHINE": "Mašina",
    "SOUNDS_MAGIC_SPELL": "Čarolija",
    "SOUNDS_MEDIEVAL1": "Srednjovjekovni1",
    "SOUNDS_MEDIEVAL2": "Srednjovjekovni2",
    "SOUNDS_MEOW": "Mijau",
    "SOUNDS_MEOW2": "mijau2",
    "SOUNDS_MOO": "Muu",
    "SOUNDS_MOTORCYCLE_PASSING": "Prolazak motocikla",
    "SOUNDS_MOVIE_1": "film 1",
    "SOUNDS_MOVIE_2": "film 2",
    "SOUNDS_MUTED_CONGA": "Prigušeni Conga",
    "SOUNDS_MYSTERY": "Misterija",
    "SOUNDS_OCEAN_WAVE": "Oceanski val",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "ups",
    "SOUNDS_ORCHESTRA_TUNING": "Ugađanje orkestra",
    "SOUNDS_OWL": "Sova",
    "SOUNDS_PARTY_NOISE": "Party Noise",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": "Ping pong hit",
    "SOUNDS_PLUCK": "čupati",
    "SOUNDS_PLUNGE": "Poniranje",
    "SOUNDS_POLICE_SIREN": "Policijska sirena",
    "SOUNDS_POP": "Pop",
    "SOUNDS_RAIN": "Kiša",
    "SOUNDS_RATTLE": "Klepet",
    "SOUNDS_REFEREE_WHISTLE": "Zvižduk suca",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "Odskočiti",
    "SOUNDS_RIDE_CYMBAL": "Jaši činele",
    "SOUNDS_RING_TONE": "Melodija zvona",
    "SOUNDS_RIP": "Počivao u miru",
    "SOUNDS_RIPPLES": "Valovi",
    "SOUNDS_ROLL_CYMBAL": "Roll Cymbal",
    "SOUNDS_ROOSTER": "Pijetao",
    "SOUNDS_SCRAMBLING_FEET": "Koprcanje stopala",
    "SOUNDS_SCRATCH_BEATBOX": "scratch beatbox",
    "SOUNDS_SCRATCHY_BEAT": "Scratchy Beat",
    "SOUNDS_SCREAM1": "Vrisak1",
    "SOUNDS_SCREAM2": "Vrisak2",
    "SOUNDS_SCREECH": "Vrisak",
    "SOUNDS_SEAGULLS": "Galebovi",
    "SOUNDS_SEWING_MACHINE": "Mašina za šivanje",
    "SOUNDS_SHAKER": "Shaker",
    "SOUNDS_SHIP_BELL": "Brodsko zvono",
    "SOUNDS_SIDESTICK_SNARE": "Sidestic Snare",
    "SOUNDS_SINGER1": "Pjevač1",
    "SOUNDS_SINGER2": "Pjevač2",
    "SOUNDS_SIREN_WHISTLE": "Zvižduk sirene",
    "SOUNDS_SKID": "Skliznuti",
    "SOUNDS_SLIDE_WHISTLE": "Slajd zviždaljka",
    "SOUNDS_SMALL_COWBELL": "Malo kravlje zvono",
    "SOUNDS_SNAP": "Snap",
    "SOUNDS_SNARE_BEATBOX": "Snare Beatbox",
    "SOUNDS_SNARE_BEATBOX2": "Snare Beatbox 2",
    "SOUNDS_SNARE_DRUM": "Mali bubanj",
    "SOUNDS_SNEAKER_SQUEAK": "Škripa tenisica",
    "SOUNDS_SNEEZE1": "kihanje1",
    "SOUNDS_SNEEZE2": "kihanje2",
    "SOUNDS_SNORING": "Hrkanje",
    "SOUNDS_SNORT": "frknuti",
    "SOUNDS_SPACE_AMBIENCE": "Svemirski ambijent",
    "SOUNDS_SPACE_FLYBY": "Svemirski let",
    "SOUNDS_SPACE_NOISE": "Svemirska buka",
    "SOUNDS_SPACE_RIPPLE": "Svemirsko mreškanje",
    "SOUNDS_SPIRAL": "Spirala",
    "SOUNDS_SPLASH": "Uprskati",
    "SOUNDS_SPLASH_CYMBAL": "Prskajuće činele",
    "SOUNDS_SPOOKY_STRING": "Sablasni niz",
    "SOUNDS_SQUAWK": "Kričati",
    "SOUNDS_SQUEAKS": "Škripi",
    "SOUNDS_SQUEAKY_TOY": "Škripava igračka",
    "SOUNDS_SQUISH_POP": "Squish Pop",
    "SOUNDS_STRING_ACCENT": "Gudački naglasak",
    "SOUNDS_STRING_PLUCK": "Trzalo za žice",
    "SOUNDS_SUCTION_CUP": "Vakumska čašica",
    "SOUNDS_SUSPENSE": "Suspenzija",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura",
    "SOUNDS_TAP_CONGA": "Dodirnite Conga",
    "SOUNDS_TAP_SNARE": "Dodirnite Snare",
    "SOUNDS_TECHNO": "tehno",
    "SOUNDS_TECHNO2": "Techno2",
    "SOUNDS_TELEPHONE_RING": "Zvono telefona",
    "SOUNDS_TELEPHONE_RING2": "Zvono telefona 2",
    "SOUNDS_TELEPORT": "Teleportirati",
    "SOUNDS_TELEPORT2": "Teleport 2",
    "SOUNDS_TELEPORT3": "Teleport 3",
    "SOUNDS_TENNIS_HIT": "Teniski hit",
    "SOUNDS_THUNDER_STORM": "Oluja s grmljavinom",
    "SOUNDS_TOM_DRUM": "Tom bubanj",
    "SOUNDS_TOY_HONK": "Igračka Sirena",
    "SOUNDS_TOY_ZING": "Toy Zing",
    "SOUNDS_TRAFFIC": "Promet",
    "SOUNDS_TRAIN_WHISTLE": "Zviždaljka vlaka",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_TRIUMPH": "Trijumf",
    "SOUNDS_TROPICAL_BIRDS": "Tropske ptice",
    "SOUNDS_TRUMPET1": "Truba1",
    "SOUNDS_TRUMPET2": "Truba2",
    "SOUNDS_VIDEO_GAME_1": "Video igra 1",
    "SOUNDS_VIDEO_GAME_2": "Video igra 2",
    "SOUNDS_WAH_BEATBOX": "Wah Beatbox",
    "SOUNDS_WAND": "Štapić",
    "SOUNDS_WATER_DROP": "Kap vode",
    "SOUNDS_WHINNY": "cvileći",
    "SOUNDS_WHISTLE_THUMP": "Zviždaljka Tup",
    "SOUNDS_WHIZ": "Fijuk",
    "SOUNDS_WHOOP": "Poklič",
    "SOUNDS_WIN": "Pobijediti",
    "SOUNDS_WOBBLE": "Kolebanje",
    "SOUNDS_WOLF_HOWL": "Vučje urlanje",
    "SOUNDS_WOOD_TAP": "Drvena slavina",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "Xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "da",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina ples",
    "SPRITES_APPLE": "Jabuka",
    "SPRITES_ARROW1": "Strelica1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Walking",
    "SPRITES_BALL": "Lopta",
    "SPRITES_BALLERINA": "Balerina",
    "SPRITES_BALLOON1": "Balon1",
    "SPRITES_BANANAS": "Banane",
    "SPRITES_BASEBALL": "Bejzbol",
    "SPRITES_BASKETBALL": "Košarka",
    "SPRITES_BAT": "Šišmiš",
    "SPRITES_BATTER": "Bacač",
    "SPRITES_BEACHBALL": "Lopta za plažu",
    "SPRITES_BEAR": "Snositi",
    "SPRITES_BEAR-WALKING": "Hodanje medvjeda",
    "SPRITES_BEETLE": "Buba",
    "SPRITES_BELL": "zvono",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Zdjela",
    "SPRITES_BOWTIE": "leptir mašna",
    "SPRITES_BREAD": "Kruh",
    "SPRITES_BROOM": "Metla",
    "SPRITES_BUILDINGS": "Građevine",
    "SPRITES_BUTTERFLY_1": "leptir 1",
    "SPRITES_BUTTERFLY_2": "leptir 2",
    "SPRITES_BUTTON1": "Gumb1",
    "SPRITES_BUTTON2": "Gumb2",
    "SPRITES_BUTTON3": "Gumb3",
    "SPRITES_BUTTON4": "Gumb4",
    "SPRITES_BUTTON5": "Gumb5",
    "SPRITES_CAKE": "Torta",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "mačka 2",
    "SPRITES_CATCHER": "Hvatač",
    "SPRITES_CENTAUR": "Kentaur",
    "SPRITES_CHAMP99": "šampion99",
    "SPRITES_CHEESY_PUFFS": "Puffs sa sirom",
    "SPRITES_CHICK": "Pilence",
    "SPRITES_CITY_BUS": "Gradski autobus",
    "SPRITES_CLOUD": "Oblak",
    "SPRITES_CLOUDS": "Oblaci",
    "SPRITES_CONVERTIBLE": "Kabriolet",
    "SPRITES_CONVERTIBLE_2": "kabriolet 2",
    "SPRITES_CRAB": "Rak",
    "SPRITES_CRYSTAL": "Kristal",
    "SPRITES_D-MONEY_DANCE": "D-novčani ples",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "dinosaur 1",
    "SPRITES_DINOSAUR2": "dinosaur 2",
    "SPRITES_DINOSAUR3": "dinosaur3",
    "SPRITES_DINOSAUR4": "dinosaur 4",
    "SPRITES_DINOSAUR5": "dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur 5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DIVER1": "Ronilac1",
    "SPRITES_DIVER2": "Ronilac2",
    "SPRITES_DOG1": "Pas1",
    "SPRITES_DOG2": "Pas2",
    "SPRITES_DONUT": "Krafna",
    "SPRITES_DORIAN": "Dorijane",
    "SPRITES_DOT": "Točka",
    "SPRITES_DOVE": "Golubica",
    "SPRITES_DRAGON": "Zmaj",
    "SPRITES_DRAGONFLY": "vilin konjic",
    "SPRITES_DRESS": "Haljina",
    "SPRITES_DRUM": "Bubanj",
    "SPRITES_DRUM_KIT": "Komplet bubnjeva",
    "SPRITES_DRUM-CYMBAL": "Bubanj-cimbal",
    "SPRITES_DRUM-HIGHHAT": "Bubanj",
    "SPRITES_DRUM-SNARE": "Bubanj-zamka",
    "SPRITES_DRUMS_CONGA": "Bubnjevi Conga",
    "SPRITES_DRUMS_TABLA": "Bubnjevi Tabla",
    "SPRITES_DUCK": "Patka",
    "SPRITES_EARTH": "Zemlja",
    "SPRITES_EASEL": "Stalak",
    "SPRITES_EGG": "Jaje",
    "SPRITES_ELEPHANT": "Slon",
    "SPRITES_ELF": "Patuljak",
    "SPRITES_FAIRY": "Vila",
    "SPRITES_FISH": "Riba",
    "SPRITES_FISHBOWL": "Zdjela za ribu",
    "SPRITES_FOOD_TRUCK": "Kamion s hranom",
    "SPRITES_FOOTBALL": "Nogomet",
    "SPRITES_FORTUNE_COOKIE": "Kolačić sudbine",
    "SPRITES_FOX": "Lisica",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "Žaba",
    "SPRITES_FROG_2_": "žaba 2",
    "SPRITES_FRUIT_PLATTER": "Plata s voćem",
    "SPRITES_FRUIT_SALAD": "Voćna salata",
    "SPRITES_GHOST": "Duh",
    "SPRITES_GIFT": "Dar",
    "SPRITES_GIRAFFE": "Žirafa",
    "SPRITES_GLASS_WATER": "Staklena voda",
    "SPRITES_GLASSES": "Naočale",
    "SPRITES_GOALIE": "Golman",
    "SPRITES_GOBLIN": "Zao",
    "SPRITES_GRASSHOPPER": "Skakavac",
    "SPRITES_GREEN_FLAG": "Zelena zastava",
    "SPRITES_GRIFFIN": "Grifin",
    "SPRITES_GUITAR": "Gitara",
    "SPRITES_GUITAR-ELECTRIC1": "Gitara-električna 1",
    "SPRITES_GUITAR-ELECTRIC2": "Gitara-električna 2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Zec",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Šešir1",
    "SPRITES_HATCHLING": "mladunče",
    "SPRITES_HEART": "Srce",
    "SPRITES_HEART_CANDY": "Slatkiši u obliku srca",
    "SPRITES_HEART_FACE": "Srce Lice",
    "SPRITES_HEDGEHOG": "Jež",
    "SPRITES_HEN": "Kokoš",
    "SPRITES_HIPPO1": "Nilski konj1",
    "SPRITES_HOME_BUTTON": "Gumb Početna",
    "SPRITES_HORSE": "Konj",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "Staklenka",
    "SPRITES_JELLYFISH": "Meduza",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_JOUVI_DANCE": "Jouvi Ples",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "Ključ",
    "SPRITES_KEYBOARD": "Tipkovnica",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Vitez",
    "SPRITES_LB_DANCE": "LB Ples",
    "SPRITES_LADYBUG1": "Bubamara1",
    "SPRITES_LADYBUG2": "Bubamara2",
    "SPRITES_LAPTOP": "Prijenosno računalo",
    "SPRITES_LIGHTNING": "Munja",
    "SPRITES_LINE": "Crta",
    "SPRITES_LION": "Lav",
    "SPRITES_LLAMA": "Lama",
    "SPRITES_MAGIC_WAND": "Čarobni štapić",
    "SPRITES_MAX": "Maks",
    "SPRITES_MERMAID": "Sirena",
    "SPRITES_MICROPHONE": "Mikrofon",
    "SPRITES_MILK": "Mlijeko",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Majmun",
    "SPRITES_MOTORCYCLE": "Motocikl",
    "SPRITES_MOUSE1": "Miš1",
    "SPRITES_MUFFIN": "Kolač",
    "SPRITES_NEIGH_PONY": "Susjed Pony",
    "SPRITES_OCTOPUS": "Hobotnica",
    "SPRITES_ORANGE": "naranča",
    "SPRITES_ORANGE2": "Narančasta2",
    "SPRITES_OUTFIELDER": "Vanjski igrač",
    "SPRITES_OWL": "Sova",
    "SPRITES_PADDLE": "Veslo",
    "SPRITES_PANTHER": "Pantera",
    "SPRITES_PANTS": "Hlače",
    "SPRITES_PARROT": "Papiga",
    "SPRITES_PARTY_HATS": "Šeširi za zabavu",
    "SPRITES_PENCIL": "Olovka",
    "SPRITES_PENGUIN": "Pingvin",
    "SPRITES_PENGUIN_2": "Pingvin 2",
    "SPRITES_PITCHER": "Bacač",
    "SPRITES_PLANET2": "Planet2",
    "SPRITES_POLAR_BEAR": "Polarni medvjed",
    "SPRITES_POTION": "Napitak",
    "SPRITES_PRINCE": "Princ",
    "SPRITES_PRINCESS": "Princeza",
    "SPRITES_PUFFERFISH": "Napuhača",
    "SPRITES_PUPPY": "Štene",
    "SPRITES_RABBIT": "Zec",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": "Duga",
    "SPRITES_REFEREE": "Sudac",
    "SPRITES_REINDEER": "Sob",
    "SPRITES_RETRO_ROBOT": "Retro robot",
    "SOUNDS_COMPUTER_BEEPS1": "zvučni signali računala1",
    "SOUNDS_COMPUTER_BEEPS2": "zvučni signali računala 2",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Robot",
    "SPRITES_ROCKETSHIP": "Raketni brod",
    "SPRITES_ROCKS": "Stijene",
    "SPRITES_ROOSTER": "Pijetao",
    "SPRITES_RUBY": "Rubin",
    "SPRITES_SAILBOAT": "Jedrilica",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "Saksofon",
    "SPRITES_SCARF": "Šal",
    "SPRITES_SHARK": "Morski pas",
    "SPRITES_SHARK_2": "morski pas 2",
    "SPRITES_SHIRT": "košulja",
    "SPRITES_SHOES": "Cipele",
    "SPRITES_SHORTS": "Kratke hlače",
    "SPRITES_SINGER1": "Pjevač1",
    "SPRITES_SKELETON": "Kostur",
    "SPRITES_SNAKE": "Zmija",
    "SPRITES_SNOWFLAKE": "Pahuljica",
    "SPRITES_SNOWMAN": "Snjegović",
    "SPRITES_SOCCER_BALL": "Nogometna lopta",
    "SPRITES_SPEAKER": "Zvučnik",
    "SPRITES_SQUIRREL": "Vjeverica",
    "SPRITES_STAR": "Zvijezda",
    "SPRITES_STARFISH": "Morska zvijezda",
    "SPRITES_STOP": "Stop",
    "SPRITES_STRAWBERRY": "jagoda",
    "SPRITES_SUN": "Sunce",
    "SPRITES_SUNGLASSES1": "Sunčane naočale1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Za ponijeti",
    "SPRITES_TEN80_DANCE": "Ten80 Ples",
    "SPRITES_TENNIS_BALL": "Teniska loptica",
    "SPRITES_TOUCAN": "Tukan",
    "SPRITES_TRAMPOLINE": "Trampolin",
    "SPRITES_TREE1": "Drvo1",
    "SPRITES_TREES": "Drveće",
    "SPRITES_TRUCK": "Kamion",
    "SPRITES_TRUMPET": "Truba",
    "SPRITES_UNICORN": "Jednorog",
    "SPRITES_UNICORN_2": "Jednorog 2",
    "SPRITES_UNICORN_RUNNING": "Trčanje jednoroga",
    "SPRITES_WAND": "Štapić",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Lubenica",
    "SPRITES_WINTER_HAT": "Zimska kapa",
    "SPRITES_WITCH": "Vještica",
    "SPRITES_WIZARD": "čarobnjak",
    "SPRITES_WIZARD_HAT": "Čarobnjački šešir",
    "SPRITES_WIZARD-TOAD": "Čarobnjak-krastača",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "Blok-A",
    "SPRITES_BLOCK-B": "Blok-B",
    "SPRITES_BLOCK-C": "Blok-C",
    "SPRITES_BLOCK-D": "Blok-D",
    "SPRITES_BLOCK-E": "Blok-E",
    "SPRITES_BLOCK-F": "Blok-F",
    "SPRITES_BLOCK-G": "Blok-G",
    "SPRITES_BLOCK-H": "Blok-H",
    "SPRITES_BLOCK-I": "Blok-I",
    "SPRITES_BLOCK-J": "Blok-J",
    "SPRITES_BLOCK-K": "Blok-K",
    "SPRITES_BLOCK-L": "Blok-L",
    "SPRITES_BLOCK-M": "Blok-M",
    "SPRITES_BLOCK-N": "Blok-N",
    "SPRITES_BLOCK-O": "Blok-O",
    "SPRITES_BLOCK-P": "Blok-P",
    "SPRITES_BLOCK-Q": "Blok-Q",
    "SPRITES_BLOCK-R": "Blok-R",
    "SPRITES_BLOCK-S": "Blok-S",
    "SPRITES_BLOCK-T": "Blok-T",
    "SPRITES_BLOCK-U": "Blok-U",
    "SPRITES_BLOCK-V": "Blok-V",
    "SPRITES_BLOCK-W": "Blok-W",
    "SPRITES_BLOCK-X": "Blok-X",
    "SPRITES_BLOCK-Y": "Blok-Y",
    "SPRITES_BLOCK-Z": "Blok-Z",
    "SPRITES_GLOW-0": "Sjaj-0",
    "SPRITES_GLOW-1": "Sjaj-1",
    "SPRITES_GLOW-2": "Sjaj-2",
    "SPRITES_GLOW-3": "Sjaj-3",
    "SPRITES_GLOW-4": "Sjaj-4",
    "SPRITES_GLOW-5": "Sjaj-5",
    "SPRITES_GLOW-6": "Sjaj-6",
    "SPRITES_GLOW-7": "Sjaj-7",
    "SPRITES_GLOW-8": "Sjaj-8",
    "SPRITES_GLOW-9": "Sjaj-9",
    "SPRITES_GLOW-A": "Sjaj-A",
    "SPRITES_GLOW-B": "Sjaj-B",
    "SPRITES_GLOW-C": "Sjaj-C",
    "SPRITES_GLOW-D": "Sjaj-D",
    "SPRITES_GLOW-E": "Sjaj-E",
    "SPRITES_GLOW-F": "Sjaj-F",
    "SPRITES_GLOW-G": "Sjaj-G",
    "SPRITES_GLOW-H": "Sjaj-H",
    "SPRITES_GLOW-I": "Sjaj-I",
    "SPRITES_GLOW-J": "Sjaj-J",
    "SPRITES_GLOW-K": "Sjaj-K",
    "SPRITES_GLOW-L": "Sjaj-L",
    "SPRITES_GLOW-M": "Sjaj-M",
    "SPRITES_GLOW-N": "Sjaj-N",
    "SPRITES_GLOW-O": "Sjaj-O",
    "SPRITES_GLOW-P": "Sjaj-P",
    "SPRITES_GLOW-Q": "Sjaj-Q",
    "SPRITES_GLOW-R": "Sjaj-R",
    "SPRITES_GLOW-S": "Sjaj-S",
    "SPRITES_GLOW-T": "Sjaj-T",
    "SPRITES_GLOW-U": "Sjaj-U",
    "SPRITES_GLOW-V": "Sjaj-V",
    "SPRITES_GLOW-W": "Sjaj-W",
    "SPRITES_GLOW-X": "Sjaj-X",
    "SPRITES_GLOW-Y": "Sjaj-Y",
    "SPRITES_GLOW-Z": "Sjaj-Z",
    "SPRITES_STORY-A": "Priča-A",
    "SPRITES_STORY-B": "Priča-B",
    "SPRITES_STORY-C": "Priča-C",
    "SPRITES_STORY-D": "Priča-D",
    "SPRITES_STORY-E": "Priča-E",
    "SPRITES_STORY-F": "Priča-F",
    "SPRITES_STORY-G": "Priča-G",
    "SPRITES_STORY-H": "Priča-H",
    "SPRITES_STORY-I": "Priča-I",
    "SPRITES_STORY-J": "Priča-J",
    "SPRITES_STORY-K": "Priča-K",
    "SPRITES_STORY-L": "Priča-L",
    "SPRITES_STORY-M": "Priča-M",
    "SPRITES_STORY-N": "Priča-N",
    "SPRITES_STORY-O": "Priča-O",
    "SPRITES_STORY-P": "Priča-P",
    "SPRITES_STORY-Q": "Priča-Q",
    "SPRITES_STORY-R": "Priča-R",
    "SPRITES_STORY-S": "Priča-S",
    "SPRITES_STORY-T": "Priča-T",
    "SPRITES_STORY-U": "Priča-U",
    "SPRITES_STORY-V": "Priča-V",
    "SPRITES_STORY-W": "Priča-W",
    "SPRITES_STORY-X": "Priča-X",
    "SPRITES_STORY-Y": "Priča-Y",
    "SPRITES_STORY-Z": "Priča-Z",
    "SPRITES_MIND+2022_01_CIVIL-AIRCRAFT": "Zrakoplov civilnog zrakoplovstva",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT1": "Civilno zrakoplovstvo Aircraft-Front",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT2": "Civilno zrakoplovstvo – strana",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT3": "Zrakoplov civilnog zrakoplovstva-vrh",
    "SPRITES_MIND+2022_02_AEROSPACEPLANE": "Space Shuttle",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE1": "Space Shuttle - naprijed",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE2": "Space Shuttle-Side",
    "SPRITES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM": "Kabina svemirskog šatla",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM1": "Prednja kabina svemirskog šatla",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM2": "Svemirski šatl sa strane kabine",
    "SPRITES_MIND+2022_04_TRAFFIC-LIGHT": "Izražajni semafori",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT1": "Izraz semafor-crveno",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT2": "Izraz semafor-žuto",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT3": "Izraz semafor-zeleno",
    "SPRITES_MIND+2022_05_FLYBROW": "cepelin",
    "COSTUMES_MIND+2022_05_FLYBROW1": "Strana zračnog broda",
    "COSTUMES_MIND+2022_05_FLYBROW2": "Cepelin - Pogled gore",
    "SPRITES_MIND+2022_06_HIGHSPEED-RAIL": "Željeznica velike brzine",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL1": "Željeznica velike brzine - naprijed",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL2": "Na strani željeznice velike brzine",
    "SPRITES_MIND+2022_07_POLICECAR": "Policijski auto",
    "COSTUMES_MIND+2022_07_POLICECAR1": "Policijski auto-Sport 1",
    "COSTUMES_MIND+2022_07_POLICECAR2": "Policijski auto-Sport 2",
    "COSTUMES_MIND+2022_07_POLICECAR3": "Policijski auto - Sport 3",
    "COSTUMES_MIND+2022_07_POLICECAR4": "Policijski auto - Sport 4",
    "COSTUMES_MIND+2022_07_POLICECAR5": "Policijsko auto-svjetlo 1",
    "COSTUMES_MIND+2022_07_POLICECAR6": "Svjetla policijskog automobila 2",
    "COSTUMES_MIND+2022_07_POLICECAR7": "Policijsko vozilo - stacionarno",
    "SPRITES_MIND+2022_08_CAR": "Automobil",
    "COSTUMES_MIND+2022_08_CAR1": "Automobil-1",
    "COSTUMES_MIND+2022_08_CAR2": "Automobil-2",
    "COSTUMES_MIND+2022_08_CAR3": "Automobil-3",
    "COSTUMES_MIND+2022_08_CAR4": "Automobil-4",
    "COSTUMES_MIND+2022_08_CAR5": "Auto-5",
    "COSTUMES_MIND+2022_08_CAR6": "Auto-6",
    "SPRITES_MIND+2022_09_DININGCAR": "Vagon-restoran",
    "SPRITES_MIND+2022_10_BUS": "Autobus",
    "SPRITES_MIND+2022_11_GO-KART": "Kart",
    "SPRITES_MIND+2022_12_MOTORBIKE": "Motocikl",
    "SPRITES_MIND+2022_13_TAXI": "Taksi",
    "COSTUMES_MIND+2022_13_TAXI01": "Taxi - prednja strana",
    "COSTUMES_MIND+2022_13_TAXI02": "Taxi-Side",
    "COSTUMES_MIND+2022_13_TAXI03": "Taksi - Ugasite svjetla sprijeda i sa strane",
    "COSTUMES_MIND+2022_13_TAXI04": "Taksi - Prednja bočna svjetla upaljena 1",
    "COSTUMES_MIND+2022_13_TAXI05": "Taksi - Svjetla na prednjoj strani 2",
    "COSTUMES_MIND+2022_13_TAXI06": "Taxi-sprijeda",
    "SPRITES_MIND+2022_14_PUMPKINCAR": "Pumpkin Car",
    "COSTUMES_MIND+2022_14_PUMPKINCAR1": "Bundeva Auto-1",
    "COSTUMES_MIND+2022_14_PUMPKINCAR2": "Bundeva Auto-2",
    "COSTUMES_MIND+2022_14_PUMPKINCAR3": "Bundeva Auto-3",
    "COSTUMES_MIND+2022_14_PUMPKINCAR4": "Pumpkin Car - Otvorena vrata",
    "COSTUMES_MIND+2022_14_PUMPKINCAR5": "Pumpkin Car-Night",
    "SPRITES_MIND+2022_15_LITTLESAILBOAT": "Malo jedrenje",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT1": "Little Sailing-Side",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT2": "Malo jedrenje-gornja strana",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT3": "Jedrilica-kosa gornja strana",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT4": "Jedrilica-kosa gornja strana 1",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT5": "Jedrilica-kosa gornja strana 2",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT6": "Jedrilica-kosa gornja strana 3",
    "SPRITES_MIND+2022_16_RUBBLERACER": "Mali svemirski brod",
    "COSTUMES_MIND+2022_16_RUBBLERACER1": "Mali svemirski brod-01",
    "COSTUMES_MIND+2022_16_RUBBLERACER2": "Mali svemirski brod-02",
    "COSTUMES_MIND+2022_16_RUBBLERACER3": "Mali svemirski brod-03",
    "SPRITES_MIND+2022_17_BARNEY": "Mala kolica",
    "COSTUMES_MIND+2022_17_BARNEY1": "Mini kolica - puna strana",
    "COSTUMES_MIND+2022_17_BARNEY2": "Rudarska kolica - gornja strana",
    "COSTUMES_MIND+2022_17_BARNEY3": "Rudarska kolica - gornja strana 2",
    "COSTUMES_MIND+2022_17_BARNEY4": "Rudarska kolica - gornja strana 3",
    "COSTUMES_MIND+2022_17_BARNEY5": "Mala rudarska kolica - gornja strana prazna 1",
    "SPRITES_MIND+2022_18_UTILITYCART": "Košarica",
    "COSTUMES_MIND+2022_18_UTILITYCART1": "Cart-Side",
    "COSTUMES_MIND+2022_18_UTILITYCART2": "Kolica - Gornja strana",
    "COSTUMES_MIND+2022_18_UTILITYCART3": "Gornja strana kolica puna 1",
    "COSTUMES_MIND+2022_18_UTILITYCART4": "Kolica - gornja strana puna 2",
    "COSTUMES_MIND+2022_18_UTILITYCART5": "Gornja strana kolica puna 3",
    "SPRITES_MIND+2022_19_BIKE": "Bicikl",
    "COSTUMES_MIND+2022_19_BIKE1": "Biciklistička strana 1",
    "COSTUMES_MIND+2022_19_BIKE2": "Biciklistička strana 2",
    "COSTUMES_MIND+2022_19_BIKE3": "Biciklistička strana 3",
    "COSTUMES_MIND+2022_19_BIKE4": "Biciklistička strana 4",
    "COSTUMES_MIND+2022_19_BIKE5": "Prednji dio bicikla",
    "SPRITES_MIND+2022_20_RACECAR": "Trkaći auto",
    "COSTUMES_MIND+2022_20_RACECAR1": "Race-Side",
    "COSTUMES_MIND+2022_20_RACECAR2": "Plavi trkaći auto-top 1",
    "COSTUMES_MIND+2022_20_RACECAR3": "Plavi trkaći auto - Top 2",
    "COSTUMES_MIND+2022_20_RACECAR4": "Plavi trkaći auto - Top 3",
    "COSTUMES_MIND+2022_20_RACECAR5": "Žuti trkaći auto - Top 1",
    "COSTUMES_MIND+2022_20_RACECAR6": "Žuti trkaći auto - Top 2",
    "COSTUMES_MIND+2022_20_RACECAR7": "Žuti trkaći auto - prva 3",
    "COSTUMES_MIND+2022_20_RACECAR8": "Ružičasti trkaći auto-top 1",
    "COSTUMES_MIND+2022_20_RACECAR9": "Ružičasti trkaći auto - Top 2",
    "COSTUMES_MIND+2022_20_RACECAR10": "Ružičasti trkaći auto-top 3",
    "SPRITES_MIND+2022_21_METEORITES1": "meteorit",
    "COSTUMES_MIND+2022_21_METEORITES1_1": "Meteorit 1-01",
    "COSTUMES_MIND+2022_21_METEORITES1_2": "Meteorit 1-02",
    "COSTUMES_MIND+2022_21_METEORITES1_3": "Meteorit 1-03",
    "SPRITES_MIND+2022_22_METEORITES2": "Meteorit 2",
    "COSTUMES_MIND+2022_22_METEORITES2_1": "Meteorit 2-01",
    "COSTUMES_MIND+2022_22_METEORITES2_2": "Meteorit 2-02",
    "COSTUMES_MIND+2022_22_METEORITES2_3": "Meteorit 2-03",
    "SPRITES_MIND+2022_23_METEORITES3": "Meteorit 3",
    "COSTUMES_MIND+2022_23_METEORITES3_1": "Meteorit 3-01",
    "COSTUMES_MIND+2022_23_METEORITES3_2": "Meteorit 3-02",
    "COSTUMES_MIND+2022_23_METEORITES3_3": "Meteorit 3-03",
    "SPRITES_MIND+2022_24_METEORITES4": "Meteorit 4",
    "COSTUMES_MIND+2022_24_METEORITES4_1": "Meteorit 4-01",
    "COSTUMES_MIND+2022_24_METEORITES4_2": "Meteorit 4-02",
    "COSTUMES_MIND+2022_24_METEORITES4_3": "Meteorit 4-03",
    "COSTUMES_MIND+2022_24_METEORITES4_4": "Meteorit 4-04",
    "SPRITES_MIND+2022_25_METEORITES5": "Meteorit 5",
    "COSTUMES_MIND+2022_25_METEORITES5_1": "Meteorit 5-01",
    "COSTUMES_MIND+2022_25_METEORITES5_2": "Meteorit 5-02",
    "COSTUMES_MIND+2022_25_METEORITES5_3": "Meteorit 5-03",
    "COSTUMES_MIND+2022_25_METEORITES5_4": "Meteorit 5-04",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_01": "Avantura meteorita A-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_02": "Avantura meteorita A-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_01": "Avantura meteorita B-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_02": "Avantura meteorita B-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_03": "Avantura meteorita B-3"
  },
  "cs": {
    "gui.backpack.header": "Batoh",
    "gui.backpack.errorBackpack": "Chyba při načítání batohu",
    "gui.backpack.loadingBackpack": "Nahrávám..",
    "gui.backpack.more": "Více",
    "gui.backpack.emptyBackpack": "Batoh je prázdný",
    "gui.gui.costumesTab": "Kostýmy",
    "gui.gui.soundsTab": "Zvuky",
    "gui.gui.backdropsTab": "Pozadí",
    "gui.gui.addExtension": "Přidej rozšíření",
    "gui.costumeTab.addCostumeFromLibrary": "Vyber kostým",
    "gui.costumeLibrary.chooseACostume": "Vyber kostým",
    "gui.costumeTab.addBackdropFromLibrary": "Vybrat pozadí",
    "gui.costumeTab.addBlankCostume": "Kreslit",
    "gui.costumeTab.addSurpriseCostume": "Překvapení",
    "gui.costumeTab.addFileBackdrop": "Nahrát pozadí",
    "gui.costumeTab.addFileCostume": "Nahrát kostým",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Uprav",
    "gui.soundEditor.stop": "Zastavit",
    "gui.soundEditor.sound": "Zvuk",
    "gui.soundEditor.play": "Hraj",
    "gui.soundEditor.save": "Uložit",
    "gui.soundEditor.undo": "Zpět",
    "gui.soundEditor.redo": "Znovu",
    "gui.soundEditor.faster": "Rychleji",
    "gui.soundEditor.slower": "Pomaleji",
    "gui.soundEditor.echo": "Ozvěna",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Více nahlas",
    "gui.soundEditor.softer": "Měkčeji",
    "gui.soundEditor.reverse": "Pozpátku",
    "gui.soundTab.recordSound": "Nahraj",
    "gui.soundTab.addSoundFromLibrary": "Vyber zvuk.",
    "gui.soundTab.surpriseSound": "Překvapení",
    "gui.soundTab.fileUploadSound": "Nahrát zvuk",
    "gui.controls.stop": "Zastavit",
    "gui.controls.go": "Spustit",
    "gui.stageHeader.stageSizeUnFull": "Ukončit režim fullscreen",
    "gui.SpriteInfo.show": "Ukázat",
    "gui.SpriteInfo.size": "Velikost",
    "gui.directionPicker.rotationStyles.allAround": "Dokola",
    "gui.directionPicker.rotationStyles.leftRight": "Vlevo/vpravo",
    "gui.directionPicker.rotationStyles.dontRotate": "Neotáčet",
    "gui.spriteSelectorItem.contextMenuDuplicate": "zkopíruj",
    "gui.spriteSelectorItem.contextMenuDelete": "smazat",
    "gui.spriteSelectorItem.contextMenuExport": "export",
    "gui.spriteSelector.addSpriteFromPaint": "Kreslit",
    "gui.spriteSelector.addSpriteFromSurprise": "Překvapení",
    "gui.spriteSelector.addSpriteFromFile": "Nahrát postavu",
    "gui.stageSelector.stage": "Scéna",
    "gui.stageSelector.backdrops": "Pozadí",
    "gui.stageSelector.addBackdropFromPaint": "Kresli",
    "gui.stageSelector.addBackdropFromSurprise": "Překvapení",
    "gui.stageSelector.addBackdropFromFile": "Nahrát pozadí",
    "gui.modal.back": "Zpět",
    "gui.library.filterPlaceholder": "Hledat",
    "gui.libraryTags.all": "Všechno",
    "gui.libraryTags.animals": "Zvířata",
    "gui.libraryTags.dance": "Tanec",
    "gui.libraryTags.effects": "Efekty",
    "gui.libraryTags.fantasy": "Fantazie",
    "gui.libraryTags.fashion": "Móda",
    "gui.libraryTags.food": "Jídlo",
    "gui.libraryTags.indoors": "Uvnitř",
    "gui.libraryTags.loops": "Smyčky",
    "gui.libraryTags.music": "Hudba",
    "gui.libraryTags.notes": "Poznámky",
    "gui.libraryTags.outdoors": "Vnější",
    "gui.libraryTags.patterns": "Vzory",
    "gui.libraryTags.people": "Lidé",
    "gui.libraryTags.percussion": "Bicí",
    "gui.libraryTags.space": "Vesmír",
    "gui.libraryTags.sports": "Sporty",
    "gui.libraryTags.underwater": "Podvodní",
    "gui.libraryTags.voice": "Hlasy",
    "gui.libraryTags.wacky": "Divné",
    "gui.libraryTags.animation": "Animace",
    "gui.libraryTags.art": "Umění",
    "gui.libraryTags.games": "Hry",
    "gui.libraryTags.stories": "Příběhy",
    "gui.libraryTags.letters": "Písmena",
    "gui.soundLibrary.chooseASound": "Vybrat zvuk",
    "gui.SpriteInfo.spritePlaceholder": "Název",
    "gui.cards.more-things-to-try": "Více věcí na vyzkoušení!",
    "gui.cards.see-more": "Další informace",
    "gui.cards.shrink": "Zmenši",
    "gui.cards.expand": "Zvětši",
    "gui.cards.close": "Zavřít",
    "gui.loader.message1": "Vytvářím bloky ...",
    "gui.loader.message2": "Načítám postavy ...",
    "gui.loader.message3": "Načítání zvuků...",
    "gui.loader.message4": "Načítám rozšíření ...",
    "gui.loader.message5": "Kočkování koček...",
    "gui.loader.message6": "Přenáším ...",
    "gui.loader.message7": "Nafukování planet...",
    "gui.loader.message8": "Připravuji smajlíky ...",
    "gui.loader.headline": "Nahrávám projekt...",
    "gui.cameraModal.cameraModalTitle": "Pořiď fotografii",
    "gui.cameraModal.loadingCameraMessage": "Načítání kamery...",
    "gui.cameraModal.permissionRequest": "Potřebujeme povolení k používání tvé kamery",
    "gui.cameraModal.retakePhoto": "Znovu pořídit fotku",
    "gui.cameraModal.save": "Uložit",
    "gui.cameraModal.takePhoto": "Pořídit fotku",
    "gui.cameraModal.loadingCaption": "Nahrávám..",
    "gui.cameraModal.enableCameraCaption": "Povolit kameru",
    "gui.recordModal.title": "Nahrát zvuk",
    "gui.playbackStep.stopMsg": "Zastavit",
    "gui.playbackStep.playMsg": "Přehrát",
    "gui.playbackStep.loadingMsg": "Nahrávám..",
    "gui.playbackStep.saveMsg": "Uložit",
    "gui.playbackStep.reRecordMsg": "Znovu nahrát",
    "gui.webglModal.label": "Tvůj prohlížeč nepodporuje WebGL",
    "gui.webglModal.webgllink": "nepodporuje WebGL",
    "gui.prompt.cancel": "Zrušit",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Přidej parametr",
    "gui.customProcedures.addAnInputBoolean": "Přidat vstup",
    "gui.customProcedures.numberTextType": "číslo nebo text",
    "gui.customProcedures.booleanType": "logický",
    "gui.customProcedures.addALabel": "Přidej popisek",
    "gui.customProcedures.runWithoutScreenRefresh": "Spustit bez obnovy obrazovky",
    "gui.customProcedures.cancel": "Zrušit",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Propojení tvých projektů se světem.",
    "gui.extension.music.name": "Hudba",
    "gui.extension.music.description": "Hraní na nástroje a bicí.",
    "gui.extension.pen.name": "Pero",
    "gui.extension.pen.description": "Kreslení s tvými postavami.",
    "gui.gui.variableScopeOptionAllSprites": "Pro všechny postavy",
    "gui.gui.variableScopeOptionSpriteOnly": "Jen pro tuto postavu",
    "gui.gui.variablePromptAllSpritesMessage": "Tato proměnná bude dostupná všem postavám.",
    "gui.monitor.contextMenu.default": "normální zobrazení",
    "gui.monitor.contextMenu.large": "zvětšené zobrazení",
    "gui.monitor.contextMenu.slider": "posuvník",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "směr",
    "gui.opcodeLabels.xposition": "x",
    "gui.opcodeLabels.yposition": "y",
    "gui.opcodeLabels.size": "velikost",
    "gui.opcodeLabels.costumename": "název kostýmu",
    "gui.opcodeLabels.costumenumber": "číslo kostýmu",
    "gui.opcodeLabels.backdropname": "název pozadí",
    "gui.opcodeLabels.backdropnumber": "číslo pozadí",
    "gui.opcodeLabels.volume": "hlasitost",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "odpověď",
    "gui.opcodeLabels.loudness": "hlasitost",
    "gui.opcodeLabels.year": "rok",
    "gui.opcodeLabels.month": "měsíc",
    "gui.opcodeLabels.date": "den v měsíci",
    "gui.opcodeLabels.dayofweek": "den týdne",
    "gui.opcodeLabels.hour": "hodina",
    "gui.opcodeLabels.minute": "minuta",
    "gui.opcodeLabels.second": "sekunda",
    "gui.opcodeLabels.timer": "stopky",
    "music.categoryName": "Hudba",
    "translate.categoryName": "Překlad",
    "pen.categoryName": "Pero",
    "pen.changeColorParam": "změň [COLOR_PARAM] pera o [VALUE]",
    "pen.changeHue": "změň barvu pera o [HUE]",
    "pen.changeShade": "změň odstín pera o [SHADE]",
    "pen.changeSize": "změň tloušťku pera o [SIZE]",
    "pen.clear": "smaž",
    "pen.colorMenu.brightness": "jas",
    "pen.colorMenu.color": "barva",
    "pen.colorMenu.saturation": "sytost",
    "pen.colorMenu.transparency": "průhlednost",
    "pen.penDown": "pero zapni",
    "pen.penUp": "pero vypni",
    "pen.setColor": "nastav barvu pera na [COLOR]",
    "pen.setColorParam": "nastav [COLOR_PARAM] pera na [VALUE]",
    "pen.setHue": "nastav barvu pera na [HUE]",
    "pen.setShade": "nastav odstín pera na [SHADE]",
    "pen.setSize": "nastav tloušťku pera na [SIZE]",
    "pen.stamp": "otiskni se",
    "music.changeTempo": "změň tempo o [TEMPO]",
    "music.drumBass": "(2) Velký buben",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Kabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Uzavřený hi-hat",
    "music.drumConga": "(14) Konga",
    "music.drumCowbell": "(11) Kravský zvonec",
    "music.drumCrashCymbal": "(4) Crash činel",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Tlesknutí",
    "music.drumOpenHiHat": "(5) Otevřený hi-hat",
    "music.drumSideStick": "(3) Okraj bubínku",
    "music.drumSnare": "(1) Malý bubínek virbl",
    "music.drumTambourine": "(7) Tamburína",
    "music.drumTriangle": "(12) Triangl",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Dřevěný blok",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Kontrabas",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Violoncello",
    "music.instrumentChoir": "(15) Sbor",
    "music.instrumentClarinet": "(10) Klarinet",
    "music.instrumentElectricGuitar": "(5) Elektronická kytara",
    "music.instrumentElectricPiano": "(2) Elektronické piáno",
    "music.instrumentFlute": "(12) Flétna",
    "music.instrumentGuitar": "(4) Kytara",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Hrací skříňka",
    "music.instrumentOrgan": "(3) Varhany",
    "music.instrumentPiano": "(1) Piáno",
    "music.instrumentPizzicato": "(7) Drnkání prstem",
    "music.instrumentSaxophone": "(11) Saxofon",
    "music.instrumentSteelDrum": "(18) Ocelový buben",
    "music.instrumentSynthLead": "(20) Syntetizátor sólo",
    "music.instrumentSynthPad": "(21) Syntetizátor pozadí",
    "music.instrumentTrombone": "(9) Trombón",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Dřevěná flétna",
    "music.midiPlayDrumForBeats": "bubnuj [DRUM] příštích [BEATS] taktů",
    "music.midiSetInstrument": "nastav nástroj na [INSTRUMENT]",
    "music.playDrumForBeats": "bubnuj [DRUM] příštích [BEATS] taktů",
    "music.playNoteForBeats": "hraj notu [NOTE] příštích [BEATS] taktů",
    "music.restForBeats": "pauza [BEATS] taktů",
    "music.setInstrument": "nastav nástroj na [INSTRUMENT]",
    "music.setTempo": "nastav tempo na [TEMPO]",
    "videoSensing.categoryName": "Vnímání videa",
    "videoSensing.direction": "směr",
    "videoSensing.motion": "pohyb",
    "videoSensing.off": "vypnuto",
    "videoSensing.on": "zapnuto",
    "videoSensing.onFlipped": "na převrácené straně",
    "videoSensing.setVideoTransparency": "nastav průhlednost videa na [TRANSPARENCY]",
    "videoSensing.sprite": "postava",
    "videoSensing.stage": "scéna",
    "videoSensing.videoOn": "video [ATTRIBUTE] na [SUBJECT]",
    "videoSensing.videoToggle": "[VIDEO_STATE] video",
    "videoSensing.whenMotionGreaterThan": "při pohybu na videu > [REFERENCE]",
    "translate.translateBlock": "přelož [WORDS] do [LANGUAGE]",
    "translate.defaultTextToTranslate": "ahoj",
    "translate.viewerLanguage": "jazyk",
    "text2speech.speakAndWaitBlock": "řekni [WORDS]",
    "text2speech.setVoiceBlock": "nastav hlas na [VOICE]",
    "text2speech.setLanguageBlock": "nastav jazyk na [LANGUAGE]",
    "text2speech.alto": "alt",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "kvik",
    "text2speech.giant": "obr",
    "text2speech.kitten": "kotě",
    "text2speech.defaultTextToSpeak": "ahoj",
    "speech.whenIHear": "když slyším [PHRASE]",
    "speech.listenAndWait": "poslouchej a čekej",
    "speech.defaultWhenIHearValue": "do toho",
    "paint.paintEditor.hue": "Barva",
    "paint.paintEditor.saturation": "Sytost",
    "paint.paintEditor.brightness": "Světlost",
    "gui.comingSoon.message1": "Neboj, pracujeme na tom {emoji}",
    "gui.comingSoon.message2": "Již brzy...",
    "gui.comingSoon.message3": "Pracujeme na tom  {emoji}",
    "paint.paintEditor.fill": "Vyplnit",
    "paint.paintEditor.costume": "Kostým",
    "paint.paintEditor.group": "Seskupit",
    "paint.paintEditor.ungroup": "Odskupit",
    "paint.paintEditor.undo": "Vrátit",
    "paint.paintEditor.redo": "Znovu provést",
    "paint.paintEditor.forward": "Dopředu",
    "paint.paintEditor.backward": "Dozadu",
    "paint.paintEditor.front": "Dopředu",
    "paint.paintEditor.back": "Zpět",
    "paint.paintEditor.more": "Více",
    "paint.modeTools.brushSize": "Velikost",
    "paint.modeTools.eraserSize": "Velikost gumy",
    "paint.modeTools.copy": "Zkopírovat",
    "paint.modeTools.paste": "Vložit",
    "paint.modeTools.delete": "Odstranit",
    "paint.modeTools.curved": "Zakřivit",
    "paint.modeTools.pointed": "Zašpičatět",
    "paint.modeTools.thickness": "Tloušťka",
    "paint.modeTools.flipHorizontal": "Přetočit horizontálně",
    "paint.modeTools.flipVertical": "Přetočit vertikálně",
    "paint.modeTools.filled": "Výplň",
    "paint.modeTools.outlined": "Obrys",
    "paint.paintEditor.bitmap": "Převést do bitmapy",
    "paint.paintEditor.vector": "Převeď na vektor",
    "paint.paintEditor.stroke": "Obrys",
    "paint.brushMode.brush": "Štětec",
    "paint.eraserMode.eraser": "Guma",
    "paint.fillMode.fill": "Vyplnit",
    "paint.lineMode.line": "Úsečka",
    "paint.ovalMode.oval": "Kružnice",
    "paint.rectMode.rect": "Obdélník",
    "paint.reshapeMode.reshape": "Změna tvaru",
    "paint.roundedRectMode.roundedRect": "Zaoblený obdélník",
    "paint.selectMode.select": "Výběr",
    "paint.textMode.text": "Text",
    "paint.colorPicker.swap": "Záměna",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "cy": {
    "gui.backpack.header": "Pecyn Cefn",
    "gui.backpack.errorBackpack": "Gwall wrth lwytho'r pecyn cefn",
    "gui.backpack.loadingBackpack": "Llwytho...",
    "gui.backpack.more": "Rhagor",
    "gui.backpack.emptyBackpack": "Mae pecyn cefn yn wag",
    "gui.gui.costumesTab": "Gwisgoedd",
    "gui.gui.soundsTab": "Seiniau",
    "gui.gui.backdropsTab": "Cefnlenni",
    "gui.gui.addExtension": "Ychwanegu Estyniad",
    "gui.costumeTab.addCostumeFromLibrary": "Dewiswch Wisg",
    "gui.costumeLibrary.chooseACostume": "Dewiswch Wisg",
    "gui.costumeTab.addBackdropFromLibrary": "Dewiswch Gefnlen",
    "gui.costumeTab.addBlankCostume": "Paentio",
    "gui.costumeTab.addSurpriseCostume": "Annisgwyl",
    "gui.costumeTab.addFileBackdrop": "Llwytho Cefnlen i Fyny",
    "gui.costumeTab.addFileCostume": "Llwytho Gwisg i Fyny",
    "gui.costumeTab.addCameraCostume": "Camera",
    "gui.soundEditor.trim": "Tocio",
    "gui.soundEditor.stop": "Aros",
    "gui.soundEditor.sound": "Sain",
    "gui.soundEditor.play": "Chwarae",
    "gui.soundEditor.save": "Cadw",
    "gui.soundEditor.undo": "Dadwneud",
    "gui.soundEditor.redo": "Ailwneud",
    "gui.soundEditor.faster": "Yn gyflymach",
    "gui.soundEditor.slower": "Arafach",
    "gui.soundEditor.echo": "Atsain",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Yn uwch",
    "gui.soundEditor.softer": "Yn feddalach",
    "gui.soundEditor.reverse": "Gwrthdroi",
    "gui.soundTab.recordSound": "Recordio",
    "gui.soundTab.addSoundFromLibrary": "Dewis Sain",
    "gui.soundTab.surpriseSound": "Annisgwyl",
    "gui.soundTab.fileUploadSound": "Llwytho Sain i Fyny...",
    "gui.controls.stop": "Aros",
    "gui.controls.go": "Mynd",
    "gui.stageHeader.stageSizeUnFull": "Gadael y modd sgrin llawn",
    "gui.SpriteInfo.show": "Dangos",
    "gui.SpriteInfo.size": "Maint",
    "gui.directionPicker.rotationStyles.allAround": "O Amgylch",
    "gui.directionPicker.rotationStyles.leftRight": "Chwith/De",
    "gui.directionPicker.rotationStyles.dontRotate": "Peidiwch troi",
    "gui.spriteSelectorItem.contextMenuDuplicate": "dyblygu",
    "gui.spriteSelectorItem.contextMenuDelete": "dileu",
    "gui.spriteSelectorItem.contextMenuExport": "allforio",
    "gui.spriteSelector.addSpriteFromPaint": "Paentio",
    "gui.spriteSelector.addSpriteFromSurprise": "Annisgwyl",
    "gui.spriteSelector.addSpriteFromFile": "Llwytho Corlun i Fyny",
    "gui.stageSelector.stage": "Llwyfan",
    "gui.stageSelector.backdrops": "Cefnlenni",
    "gui.stageSelector.addBackdropFromPaint": "Paent",
    "gui.stageSelector.addBackdropFromSurprise": "Annisgwyl",
    "gui.stageSelector.addBackdropFromFile": "Llwytho'r Gefnlen i Fyny",
    "gui.modal.back": "Nôl",
    "gui.library.filterPlaceholder": "Chwilio",
    "gui.libraryTags.all": "Y Cyfan",
    "gui.libraryTags.animals": "Anifeiliaid",
    "gui.libraryTags.dance": "Dawns",
    "gui.libraryTags.effects": "Effeithiau",
    "gui.libraryTags.fantasy": "Ffantasi",
    "gui.libraryTags.fashion": "Ffasiwn",
    "gui.libraryTags.food": "Bwyd",
    "gui.libraryTags.indoors": "Dan do",
    "gui.libraryTags.loops": "Cylchoedd",
    "gui.libraryTags.music": "Cerddoriaeth",
    "gui.libraryTags.notes": "Nodiadau",
    "gui.libraryTags.outdoors": "Tu allan",
    "gui.libraryTags.patterns": "Patrymau",
    "gui.libraryTags.people": "Pobl",
    "gui.libraryTags.percussion": "Offerynnau taro",
    "gui.libraryTags.space": "Gofod",
    "gui.libraryTags.sports": "Chwaraeon",
    "gui.libraryTags.underwater": "O dan y dŵr",
    "gui.libraryTags.voice": "Llais",
    "gui.libraryTags.wacky": "Gwirion",
    "gui.libraryTags.animation": "Animeiddio",
    "gui.libraryTags.art": "Celf",
    "gui.libraryTags.games": "Gemau",
    "gui.libraryTags.stories": "Straeon",
    "gui.libraryTags.letters": "Llythyrau",
    "gui.soundLibrary.chooseASound": "Dewiswch Sain",
    "gui.SpriteInfo.spritePlaceholder": "Enw",
    "gui.cards.more-things-to-try": "Pethau eraill i'w gwneud!",
    "gui.cards.see-more": "Gweld rhagor",
    "gui.cards.shrink": "Lleihau",
    "gui.cards.expand": "Ehangu",
    "gui.cards.close": "Cau",
    "gui.loader.message1": "Creu blociau …",
    "gui.loader.message2": "Yn llwytho ciplun ...",
    "gui.loader.message3": "Yn llwytho seiniau …",
    "gui.loader.message4": "Yn llwytho estyniadau …",
    "gui.loader.message5": "Casglu cathod …",
    "gui.loader.message6": "Darlledu nanos …",
    "gui.loader.message7": "Enchwythu gobos …",
    "gui.loader.message8": "Paratoi emojis …",
    "gui.loader.headline": "Llwytho Project",
    "gui.cameraModal.cameraModalTitle": "Cymryd Llun",
    "gui.cameraModal.loadingCameraMessage": "Llwytho Camera...",
    "gui.cameraModal.permissionRequest": "Mae angen eich caniatâd i ni ddefnyddio eich camera",
    "gui.cameraModal.retakePhoto": "Cymryd Llun Eto",
    "gui.cameraModal.save": "Cadw",
    "gui.cameraModal.takePhoto": "Cymryd Llun",
    "gui.cameraModal.loadingCaption": "Llwytho...",
    "gui.cameraModal.enableCameraCaption": "Galluogi Camera",
    "gui.recordModal.title": "Recordio Sain",
    "gui.playbackStep.stopMsg": "Aros",
    "gui.playbackStep.playMsg": "Chwarae",
    "gui.playbackStep.loadingMsg": "Llwytho...",
    "gui.playbackStep.saveMsg": "Cadw",
    "gui.playbackStep.reRecordMsg": "Ail Recordio",
    "gui.webglModal.label": "Nid yw eich Porwr yn Cynnal WebGL",
    "gui.webglModal.webgllink": "nid yw'#n cynnal WebGL",
    "gui.prompt.cancel": "Diddymu",
    "gui.prompt.ok": "Iawn",
    "gui.customProcedures.addAnInputNumberText": "Ychwanegu mewnbwn",
    "gui.customProcedures.addAnInputBoolean": "Ychwanegu mewnbwn",
    "gui.customProcedures.numberTextType": "rhif neu destun",
    "gui.customProcedures.booleanType": "boole",
    "gui.customProcedures.addALabel": "Ychwanegwch label",
    "gui.customProcedures.runWithoutScreenRefresh": "Yn rhedeg heb adnewyddu'r sgrin",
    "gui.customProcedures.cancel": "Diddymu",
    "gui.customProcedures.ok": "Iawn",
    "gui.extension.microbit.description": "Cysylltu eich project â'r byd.",
    "gui.extension.music.name": "Cerddoriaeth",
    "gui.extension.music.description": "Chwarae offerynau a drymiau",
    "gui.extension.pen.name": "Pin",
    "gui.extension.pen.description": "Lluniadu gyda'ch corluniau.",
    "gui.gui.variableScopeOptionAllSprites": "Ar gyfer pob ciplun",
    "gui.gui.variableScopeOptionSpriteOnly": "Ar gyfer y ciplun yma'n unig",
    "gui.gui.variablePromptAllSpritesMessage": "Bydd y newidyn yma ar gael ar gyfer pob corlun.",
    "gui.monitor.contextMenu.default": "darlleniad arferol",
    "gui.monitor.contextMenu.large": "sgrîn fawr",
    "gui.monitor.contextMenu.slider": "llithrydd",
    "gui.monitor.contextMenu.import": "mewnforio",
    "gui.monitor.contextMenu.export": "allforio",
    "gui.opcodeLabels.direction": "cyfeiriad",
    "gui.opcodeLabels.xposition": "safle x",
    "gui.opcodeLabels.yposition": "safle y",
    "gui.opcodeLabels.size": "maint",
    "gui.opcodeLabels.costumename": "enw'r wisg",
    "gui.opcodeLabels.costumenumber": "rhif gwisg",
    "gui.opcodeLabels.backdropname": "enw cefnlen",
    "gui.opcodeLabels.backdropnumber": "rhif cefnlen",
    "gui.opcodeLabels.volume": "lefel sain",
    "gui.opcodeLabels.tempo": "amseriad",
    "gui.opcodeLabels.answer": "ateb",
    "gui.opcodeLabels.loudness": "cryfder sain",
    "gui.opcodeLabels.year": "blwyddyn",
    "gui.opcodeLabels.month": "mis",
    "gui.opcodeLabels.date": "dyddiad",
    "gui.opcodeLabels.dayofweek": "diwrnod o'r wythnos",
    "gui.opcodeLabels.hour": "awr",
    "gui.opcodeLabels.minute": "munud",
    "gui.opcodeLabels.second": "eiliad",
    "gui.opcodeLabels.timer": "amserydd",
    "music.categoryName": "Cerddoriaeth",
    "translate.categoryName": "Cyfieithu",
    "pen.categoryName": "Pin",
    "pen.changeColorParam": "newid pin [COLOR_PARAM]gan [VALUE]",
    "pen.changeHue": "newid lliw pin gan [HUE]",
    "pen.changeShade": "newid arlliw pin gan [SHADE]",
    "pen.changeSize": "newid maint pin gan [SIZE]",
    "pen.clear": "dileu popeth",
    "pen.colorMenu.brightness": "disgleirdeb",
    "pen.colorMenu.color": "lliw",
    "pen.colorMenu.saturation": "dirlawnder",
    "pen.colorMenu.transparency": "tryloywder",
    "pen.penDown": "pin i lawr",
    "pen.penUp": "pin i fyny",
    "pen.setColor": "gosod lliw pin i [COLOR]",
    "pen.setColorParam": "gosod y pin [COLOR_PARAM]i [VALUE]",
    "pen.setHue": "gosod lliw pin i [HUE]",
    "pen.setShade": "gosod arlliw pin i [SHADE]",
    "pen.setSize": "gosod maint pin i [SIZE]",
    "pen.stamp": "stampio",
    "music.changeTempo": "Newid tempo gan [TEMPO]",
    "music.drumBass": "(2) Drwm Bas",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Clafiau",
    "music.drumClosedHiHat": "(^) Symbalau Pedal Troed Caeëdig",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Cloch Buwch",
    "music.drumCrashCymbal": "(4) Symbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Clap Llaw",
    "music.drumOpenHiHat": "(5) Symbalau Pedal Troed Agored",
    "music.drumSideStick": "(3) Pren ochr",
    "music.drumSnare": "(1) Drwm Gwifrau",
    "music.drumTambourine": "(7) Tambwrîn",
    "music.drumTriangle": "(12) Triongl",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Bloc Pren",
    "music.getTempo": "amseriad",
    "music.instrumentBass": "(6) Bas",
    "music.instrumentBassoon": "(14) Basŵn",
    "music.instrumentCello": "(8) Sielo",
    "music.instrumentChoir": "(15) Côr",
    "music.instrumentClarinet": "(10) Clarinét",
    "music.instrumentElectricGuitar": "(5) Gitâr Drydan",
    "music.instrumentElectricPiano": "(2) Piano Trydan",
    "music.instrumentFlute": "(12) Ffliwt",
    "music.instrumentGuitar": "(4) Gitâr",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Blwch Cerddoriaeth",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Sacsoffon",
    "music.instrumentSteelDrum": "(18) Drwm Dur",
    "music.instrumentSynthLead": "(20) Synth Blaen",
    "music.instrumentSynthPad": "(21) Pad Synth",
    "music.instrumentTrombone": "(9) Trombôn",
    "music.instrumentVibraphone": "(16) Fibraffon",
    "music.instrumentWoodenFlute": "(13) Ffliwt Pren",
    "music.midiPlayDrumForBeats": "chwarae drwm [DRUM] am [BEATS] guriad",
    "music.midiSetInstrument": "gosod offeryn i [INSTRUMENT]",
    "music.playDrumForBeats": "chwarae drwm [DRUM] am [BEATS] curiad",
    "music.playNoteForBeats": "chwarae nodyn[NOTE]am [BEATS]curiad",
    "music.restForBeats": "oedi am [BEATS]curiad",
    "music.setInstrument": "gosod offeryn i [INSTRUMENT]",
    "music.setTempo": "gosod tempo [TEMPO]",
    "videoSensing.categoryName": "Synhwyro Fideo",
    "videoSensing.direction": "cyfeiriad",
    "videoSensing.motion": "symudiad",
    "videoSensing.off": "i ffwrdd",
    "videoSensing.on": "ymlaen",
    "videoSensing.onFlipped": "wrth ei fflipio",
    "videoSensing.setVideoTransparency": "gosod tryloywder fideo i [TRANSPARENCY]",
    "videoSensing.sprite": "corlun",
    "videoSensing.stage": "llwyfan",
    "videoSensing.videoOn": "fideo [ATTRIBUTE] ar [SUBJECT]",
    "videoSensing.videoToggle": "troi fideo [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "pan mae symud fideo > [REFERENCE]",
    "translate.translateBlock": "cyfieithu[WORDS] i'r [LANGUAGE]",
    "translate.defaultTextToTranslate": "helo",
    "translate.viewerLanguage": "iaith",
    "text2speech.speakAndWaitBlock": "siarad [WORDS]",
    "text2speech.setVoiceBlock": "gosod llais i [VOICE]",
    "text2speech.setLanguageBlock": "gosod iaith i [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "gwich",
    "text2speech.giant": "cawr",
    "text2speech.kitten": "cath fach",
    "text2speech.defaultTextToSpeak": "helo",
    "speech.whenIHear": "pan fydda i'n clywed [PHRASE]",
    "speech.listenAndWait": "gwrando ac aros",
    "speech.defaultWhenIHearValue": "ffwrdd a ni",
    "paint.paintEditor.hue": "Lliw",
    "paint.paintEditor.saturation": "Dirlawnder",
    "paint.paintEditor.brightness": "Disgleirdeb",
    "gui.comingSoon.message1": "Peidiwch poeni, rydym wrthi {emoji}",
    "gui.comingSoon.message2": "Yn Dod Cyn Bo Hir...",
    "gui.comingSoon.message3": "Rydym yn gweithio arno {emoji}",
    "paint.paintEditor.fill": "Llanw",
    "paint.paintEditor.costume": "Gwisg",
    "paint.paintEditor.group": "Grŵp",
    "paint.paintEditor.ungroup": "Dad-grwpio",
    "paint.paintEditor.undo": "Dadwneud",
    "paint.paintEditor.redo": "Ailwneud",
    "paint.paintEditor.forward": "Ymlaen",
    "paint.paintEditor.backward": "Nôl",
    "paint.paintEditor.front": "Blaen",
    "paint.paintEditor.back": "Nôl",
    "paint.paintEditor.more": "Rhagor",
    "paint.modeTools.brushSize": "Maint",
    "paint.modeTools.eraserSize": "Maint rhwbiwr",
    "paint.modeTools.copy": "Copïo",
    "paint.modeTools.paste": "Gludo",
    "paint.modeTools.delete": "Dileu",
    "paint.modeTools.curved": "Crwm",
    "paint.modeTools.pointed": "Gyda Blaen",
    "paint.modeTools.thickness": "Trwch",
    "paint.modeTools.flipHorizontal": "Fflipio'n Llorweddol",
    "paint.modeTools.flipVertical": "Fflipio'n Fertigol",
    "paint.modeTools.filled": "Llanwyd",
    "paint.modeTools.outlined": "Amlinellwyd",
    "paint.paintEditor.bitmap": "Trosi i Ddidfap",
    "paint.paintEditor.vector": "Trosi i Fector",
    "paint.paintEditor.stroke": "Amlinell",
    "paint.brushMode.brush": "Brwsh",
    "paint.eraserMode.eraser": "Rhwbiwr",
    "paint.fillMode.fill": "Llanw",
    "paint.lineMode.line": "Llinell",
    "paint.ovalMode.oval": "Cylch",
    "paint.rectMode.rect": "Petryal",
    "paint.reshapeMode.reshape": "Ail siapio",
    "paint.roundedRectMode.roundedRect": "Petryal Crwm",
    "paint.selectMode.select": "Dewis",
    "paint.textMode.text": "Testun",
    "paint.colorPicker.swap": "Cyfnewid",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "da": {
    "gui.backpack.header": "Rygsæk",
    "gui.backpack.errorBackpack": "Fejl, kan ikke indlæse rygsækken",
    "gui.backpack.loadingBackpack": "Indlæser ...",
    "gui.backpack.more": "Mere",
    "gui.backpack.emptyBackpack": "Rygsækken er tom",
    "gui.gui.costumesTab": "Kostumer",
    "gui.gui.soundsTab": "Lyde",
    "gui.gui.backdropsTab": "Baggrunde",
    "gui.gui.addExtension": "Tilføj udvidelse",
    "gui.costumeTab.addCostumeFromLibrary": "Vælg et kostume",
    "gui.costumeLibrary.chooseACostume": "Vælg et kostume",
    "gui.costumeTab.addBackdropFromLibrary": "Vælg en baggrund",
    "gui.costumeTab.addBlankCostume": "Tegn",
    "gui.costumeTab.addSurpriseCostume": "Overraskelse",
    "gui.costumeTab.addFileBackdrop": "Hent baggrund",
    "gui.costumeTab.addFileCostume": "Hent kostume",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Klip",
    "gui.soundEditor.stop": "Stop",
    "gui.soundEditor.sound": "Lyd",
    "gui.soundEditor.play": "Spil",
    "gui.soundEditor.save": "Gem",
    "gui.soundEditor.undo": "Fortryd",
    "gui.soundEditor.redo": "Gør om",
    "gui.soundEditor.faster": "Hurtigere",
    "gui.soundEditor.slower": "Langsommere",
    "gui.soundEditor.echo": "Ekko",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Højere",
    "gui.soundEditor.softer": "Blødere",
    "gui.soundEditor.reverse": "Baglæns",
    "gui.soundTab.recordSound": "Optag",
    "gui.soundTab.addSoundFromLibrary": "Vælg en lyd",
    "gui.soundTab.surpriseSound": "Overraskelse",
    "gui.soundTab.fileUploadSound": "Hent lyd",
    "gui.controls.stop": "Stop",
    "gui.controls.go": "Gå",
    "gui.stageHeader.stageSizeUnFull": "Gå ud af fuld skærm",
    "gui.SpriteInfo.show": "Vis",
    "gui.SpriteInfo.size": "Størrelse",
    "gui.directionPicker.rotationStyles.allAround": "Alle retninger",
    "gui.directionPicker.rotationStyles.leftRight": "Venstre/højre",
    "gui.directionPicker.rotationStyles.dontRotate": "Drej ikke",
    "gui.spriteSelectorItem.contextMenuDuplicate": "kopier",
    "gui.spriteSelectorItem.contextMenuDelete": "slet",
    "gui.spriteSelectorItem.contextMenuExport": "eksporter",
    "gui.spriteSelector.addSpriteFromPaint": "Tegn",
    "gui.spriteSelector.addSpriteFromSurprise": "Overraskelse",
    "gui.spriteSelector.addSpriteFromFile": "Hent sprite",
    "gui.stageSelector.stage": "Scene",
    "gui.stageSelector.backdrops": "Baggrunde",
    "gui.stageSelector.addBackdropFromPaint": "Tegn",
    "gui.stageSelector.addBackdropFromSurprise": "Overraskelse",
    "gui.stageSelector.addBackdropFromFile": "Hent baggrund",
    "gui.modal.back": "Tilbage",
    "gui.library.filterPlaceholder": "Søg",
    "gui.libraryTags.all": "Alle",
    "gui.libraryTags.animals": "Dyr",
    "gui.libraryTags.dance": "Dans",
    "gui.libraryTags.effects": "Effekter",
    "gui.libraryTags.fantasy": "Fantasi",
    "gui.libraryTags.fashion": "Mode",
    "gui.libraryTags.food": "Mad",
    "gui.libraryTags.indoors": "Indendørs",
    "gui.libraryTags.loops": "Gentagelser",
    "gui.libraryTags.music": "Musik",
    "gui.libraryTags.notes": "Noder",
    "gui.libraryTags.outdoors": "Udendørs",
    "gui.libraryTags.patterns": "Mønstre",
    "gui.libraryTags.people": "Mennesker",
    "gui.libraryTags.percussion": "Slagtøj",
    "gui.libraryTags.space": "Rummet",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Under vandet",
    "gui.libraryTags.voice": "Stemme",
    "gui.libraryTags.wacky": "Skør",
    "gui.libraryTags.animation": "Animation",
    "gui.libraryTags.art": "Kunst",
    "gui.libraryTags.games": "Spil",
    "gui.libraryTags.stories": "Historier",
    "gui.libraryTags.letters": "Bogstaver",
    "gui.soundLibrary.chooseASound": "Vælg en lyd",
    "gui.SpriteInfo.spritePlaceholder": "Navn",
    "gui.cards.more-things-to-try": "Flere ting, som du kan prøve!",
    "gui.cards.see-more": "Se mere",
    "gui.cards.shrink": "Mindre",
    "gui.cards.expand": "Udvid",
    "gui.cards.close": "Luk",
    "gui.loader.message1": "Laver brikker ...",
    "gui.loader.message2": "Indlæser sprites ...",
    "gui.loader.message3": "Indlæser lyde ...",
    "gui.loader.message4": "Indlæser udvidelser ...",
    "gui.loader.message5": "Passer katte ...",
    "gui.loader.message6": "Transmitterer nanos ...",
    "gui.loader.message7": "Puster gobos op ...",
    "gui.loader.message8": "Forbereder emojis …",
    "gui.loader.headline": "Indlæser projekt",
    "gui.cameraModal.cameraModalTitle": "Tag et billede",
    "gui.cameraModal.loadingCameraMessage": "Indlæser kamera...",
    "gui.cameraModal.permissionRequest": "Vi skal bruge din tilladelse til at bruge dit kamera",
    "gui.cameraModal.retakePhoto": "Tag billede igen",
    "gui.cameraModal.save": "Gem",
    "gui.cameraModal.takePhoto": "Tag billede",
    "gui.cameraModal.loadingCaption": "Indlæser ...",
    "gui.cameraModal.enableCameraCaption": "Aktivér kamera",
    "gui.recordModal.title": "Optag lyd",
    "gui.playbackStep.stopMsg": "Stop",
    "gui.playbackStep.playMsg": "Spil",
    "gui.playbackStep.loadingMsg": "Indlæser ...",
    "gui.playbackStep.saveMsg": "Gem",
    "gui.playbackStep.reRecordMsg": "Optag igen",
    "gui.webglModal.label": "Din browser understøtter ikke WebGL",
    "gui.webglModal.webgllink": "understøtter ikke WebGL",
    "gui.prompt.cancel": "Annuller",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Tilføj input",
    "gui.customProcedures.addAnInputBoolean": "Tilføj input",
    "gui.customProcedures.numberTextType": "tal eller tekst",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Tilføj en label",
    "gui.customProcedures.runWithoutScreenRefresh": "Kør uden skærmopdatering",
    "gui.customProcedures.cancel": "Annuller",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Forbind dine projekter med den fysiske verden.",
    "gui.extension.music.name": "Musik",
    "gui.extension.music.description": "Spil på instrumenter og trommer",
    "gui.extension.pen.name": "Pen",
    "gui.extension.pen.description": "Tegn med dine sprites.",
    "gui.gui.variableScopeOptionAllSprites": "For alle sprites",
    "gui.gui.variableScopeOptionSpriteOnly": "Kun for denne sprite",
    "gui.gui.variablePromptAllSpritesMessage": "Denne variabel kan bruges ved alle sprites.",
    "gui.monitor.contextMenu.default": "normal visning",
    "gui.monitor.contextMenu.large": "stor visning",
    "gui.monitor.contextMenu.slider": "skyder",
    "gui.monitor.contextMenu.import": "importer",
    "gui.monitor.contextMenu.export": "eksporter",
    "gui.opcodeLabels.direction": "retning",
    "gui.opcodeLabels.xposition": "x position",
    "gui.opcodeLabels.yposition": "y position",
    "gui.opcodeLabels.size": "størrelse",
    "gui.opcodeLabels.costumename": "navn på kostume",
    "gui.opcodeLabels.costumenumber": "kostume nummer",
    "gui.opcodeLabels.backdropname": "navn på baggrund",
    "gui.opcodeLabels.backdropnumber": "baggrund nummer",
    "gui.opcodeLabels.volume": "lydstyrke",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "svar",
    "gui.opcodeLabels.loudness": "lydstyrke",
    "gui.opcodeLabels.year": "år",
    "gui.opcodeLabels.month": "måned",
    "gui.opcodeLabels.date": "dato",
    "gui.opcodeLabels.dayofweek": "ugedag",
    "gui.opcodeLabels.hour": "time",
    "gui.opcodeLabels.minute": "minut",
    "gui.opcodeLabels.second": "sekund",
    "gui.opcodeLabels.timer": "stopur",
    "music.categoryName": "Musik",
    "translate.categoryName": "Oversæt",
    "pen.categoryName": "Pen",
    "pen.changeColorParam": "ændre pen [COLOR_PARAM] med [VALUE]",
    "pen.changeHue": "ændre penfarve med [HUE]",
    "pen.changeShade": "ændre skyggen på pennen med [SHADE]",
    "pen.changeSize": "ændre pen størrelse med[SIZE]",
    "pen.clear": "slet alt",
    "pen.colorMenu.brightness": "lysstyrke",
    "pen.colorMenu.color": "farve",
    "pen.colorMenu.saturation": "farvemætning",
    "pen.colorMenu.transparency": "gennemsigtighed",
    "pen.penDown": "pen ned",
    "pen.penUp": "pen op",
    "pen.setColor": "skift penfarve til [COLOR]",
    "pen.setColorParam": "sæt pen [COLOR_PARAM] til [VALUE]",
    "pen.setHue": "sæt penfarve til [HUE]",
    "pen.setShade": "sæt skyggen på pennen til [SHADE]",
    "pen.setSize": "sæt penstørrelse til [SIZE]",
    "pen.stamp": "stempel",
    "music.changeTempo": "ændre tempo med [TEMPO]",
    "music.drumBass": "(2) Stortromme",
    "music.drumBongo": "(13) Bongotromme",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Lukket hi-hat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Koklokke",
    "music.drumCrashCymbal": "(4) Bækken",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Klap med hænderne",
    "music.drumOpenHiHat": "(5) Åben hi-hat",
    "music.drumSideStick": "(3) Kantslag",
    "music.drumSnare": "(1) Lilletromme",
    "music.drumTambourine": "(7) Tamburin",
    "music.drumTriangle": "(12) Triangel",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Træklods",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bas",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Kor",
    "music.instrumentClarinet": "(10) Klarinet",
    "music.instrumentElectricGuitar": "(5) Elektrisk guitar",
    "music.instrumentElectricPiano": "(2) Elektrisk klaver",
    "music.instrumentFlute": "(12) Fløjte",
    "music.instrumentGuitar": "(4) Guitar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Spilledåse",
    "music.instrumentOrgan": "(3) Orgel",
    "music.instrumentPiano": "(1) Klaver",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxofon",
    "music.instrumentSteelDrum": "(18) Ståltromme",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trækbasun",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Træfløjte",
    "music.midiPlayDrumForBeats": "spil tromme [DRUM] i [BEATS] slag",
    "music.midiSetInstrument": "skift instrument til [INSTRUMENT]",
    "music.playDrumForBeats": "spil [DRUM] i [BEATS] slag",
    "music.playNoteForBeats": "spil node [NOTE] i [BEATS] slag",
    "music.restForBeats": "pause i [BEATS] slag",
    "music.setInstrument": "skift instrument til [INSTRUMENT]",
    "music.setTempo": "sæt tempo til [TEMPO]",
    "videoSensing.categoryName": "Video Registrering",
    "videoSensing.direction": "retning",
    "videoSensing.motion": "bevægelse",
    "videoSensing.off": "slukket",
    "videoSensing.on": "tændt",
    "videoSensing.onFlipped": "spejlvendt",
    "videoSensing.setVideoTransparency": "sæt video gennemsigtighed til [TRANSPARENCY]",
    "videoSensing.sprite": "sprite",
    "videoSensing.stage": "scene",
    "videoSensing.videoOn": "video [ATTRIBUTE] på [SUBJECT]",
    "videoSensing.videoToggle": "video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "når video bevægelse > [REFERENCE]",
    "translate.translateBlock": "oversæt [WORDS] til [LANGUAGE]",
    "translate.defaultTextToTranslate": "hej",
    "translate.viewerLanguage": "sprog",
    "text2speech.speakAndWaitBlock": "sig [WORDS]",
    "text2speech.setVoiceBlock": "indstil stemme til [VOICE]",
    "text2speech.setLanguageBlock": "sæt sprog til [LANGUAGE]",
    "text2speech.alto": "kvinde",
    "text2speech.tenor": "mand",
    "text2speech.squeak": "knirkende",
    "text2speech.giant": "kæmpe",
    "text2speech.kitten": "killing",
    "text2speech.defaultTextToSpeak": "hej",
    "speech.whenIHear": "når jeg hører [PHRASE]",
    "speech.listenAndWait": "lyt og vent",
    "speech.defaultWhenIHearValue": "lad os komme i gang",
    "paint.paintEditor.hue": "Farve",
    "paint.paintEditor.saturation": "Farvemætning",
    "paint.paintEditor.brightness": "Lysstyrke",
    "gui.comingSoon.message1": "Du skal ikke bekymre dig, vi arbejder på sagen {emoji}",
    "gui.comingSoon.message2": "Kommer snart...",
    "gui.comingSoon.message3": "Vi arbejder på sagen {emoji}",
    "paint.paintEditor.fill": "Fyld",
    "paint.paintEditor.costume": "Kostume",
    "paint.paintEditor.group": "Gruppe",
    "paint.paintEditor.ungroup": "Opdel",
    "paint.paintEditor.undo": "Fortryd",
    "paint.paintEditor.redo": "Gør om",
    "paint.paintEditor.forward": "Forrest",
    "paint.paintEditor.backward": "Bagerst",
    "paint.paintEditor.front": "Foran",
    "paint.paintEditor.back": "Bagved",
    "paint.paintEditor.more": "Mere",
    "paint.modeTools.brushSize": "Størrelse",
    "paint.modeTools.eraserSize": "Viskelæderets størrelse",
    "paint.modeTools.copy": "Kopier",
    "paint.modeTools.paste": "Sæt ind",
    "paint.modeTools.delete": "Slet",
    "paint.modeTools.curved": "Buet",
    "paint.modeTools.pointed": "Spids",
    "paint.modeTools.thickness": "Tykkelse",
    "paint.modeTools.flipHorizontal": "Vend venstre-højre",
    "paint.modeTools.flipVertical": "Vend op og ned",
    "paint.modeTools.filled": "Fyldt",
    "paint.modeTools.outlined": "Kant streg",
    "paint.paintEditor.bitmap": "Konverter til Bitmap",
    "paint.paintEditor.vector": "Konverter til Vektor",
    "paint.paintEditor.stroke": "Kant",
    "paint.brushMode.brush": "Pensel",
    "paint.eraserMode.eraser": "Viskelæder",
    "paint.fillMode.fill": "Fyld",
    "paint.lineMode.line": "Linje",
    "paint.ovalMode.oval": "Cirkel",
    "paint.rectMode.rect": "Rektangel",
    "paint.reshapeMode.reshape": "Omform",
    "paint.roundedRectMode.roundedRect": "Afrundet rektangel",
    "paint.selectMode.select": "Vælg",
    "paint.textMode.text": "Tekst",
    "paint.colorPicker.swap": "Byt",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "de": {
    "gui.gui.project": "Projekt",
    "gui.gui.newItem": "Neues Projekt",
    "gui.gui.load": "Projekt laden",
    "gui.gui.save": "Projekt speichern",
    "gui.gui.saveAs": "Speichern unter",
    "gui.gui.cacheList": "Cache-Liste",
    "gui.gui.inCache": "Im Cache",
    "gui.gui.learn": "Lernen",
    "gui.gui.clearRecord": "Datensatz löschen",
    "gui.gui.onlineLearn": "Offizielle Dokumente",
    "gui.gui.onlineForum": "Online-Forum",
    "gui.gui.videoTutorial": "Video-Anleitungen",
    "gui.gui.offlineLearn": "Beispiel-Programme",
    "gui.menuBar.turboModeOff": "Turbomodus ausschalten",
    "gui.menuBar.turboModeOn": "Turbomodus einschalten",
    "gui.menuBar.edit": "Bearbeiten",
    "gui.menuBar.restoreSprite": "Sprite wiederherstellen",
    "gui.menuBar.restoreSound": "Sound wiederherstellen",
    "gui.menuBar.restoreCostume": "Kostüm wiederherstellen",
    "gui.menuBar.restoreBackdrop": "Kulisse wiederherstellen",
    "gui.editorMind.restore": "Wiederherstellen",
    "gui.turboMode.active": "Turbo-Modus",
    "gui.gui.connectDevice": "Gerät verbinden",
    "gui.gui.disconnectDevice": "Gerät entfernen",
    "gui.gui.installSerialportDriver": "SerialPort-Treiber installieren",
    "gui.gui.openDM": "Geräte-Manager öffnen",
    "gui.gui.restoreSetting": "Ursprüngliche Einstellungen des Geräts wiederherstellen",
    "gui.gui.updateTips": "Tipps zum Aktualisieren",
    "gui.gui.newVersion": "Die neueste Version",
    "gui.gui.downloadUpdate": "Update herunterladen",
    "gui.gui.versionUpdate": "Aktualisieren der Version",
    "gui.gui.isCheckMd5": "Überprüfen der Datei",
    "gui.gui.downloading": "Updater prüfen",
    "gui.gui.updateError": "Update-Fehler",
    "gui.setting.feedbackMenu": "Rückmeldung",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.setting.officialWebsit": "Offizielle Website",
    "gui.setting.officialQQGroup": "Offizielle QQ-Gruppe",
    "gui.setting.wechatOfficialAccounts": "Offizielle WeChat-Konten",
    "gui.setting.currentVersion": "Aktuelle Version",
    "gui.setting.checkForUpdate": "Auf Updates prüfen",
    "gui.setting.remindWhenUpdate": "Herunterladen, wenn aktualisiert",
    "gui.setting.currentIsLatest": "Die aktuelle Version ist die neueste Version",
    "gui.setting.latestVersion": "Die neueste Version",
    "gui.setting.download": "Zum Web-Download springen",
    "gui.setting.language": "Sprache",
    "gui.setting.uploadCsv": "xlsx hochladen",
    "gui.setting.theme": "Thema",
    "gui.setting.feedback": "Kontakt",
    "gui.setting.email": "E-Mail",
    "gui.setting.opinionFeedback": "Rückmeldung",
    "gui.variableMc.variableName": "Variablenname",
    "gui.variableMc.variableOff": "abbrechen",
    "gui.variableMc.variableOn": "bestätigen",
    "gui.variableMc.newVariable": "neue Variable",
    "gui.setting.helpUsTranslate": "Helfe uns bei der Übersetzung",
    "gui.setting.checkUpdate": "Update prüfen",
    "gui.setting.ok": "Ok",
    "gui.setting.languageSetting": "Sprache",
    "gui.setting.themeSetting": "Thema",
    "gui.setting.cacheSetting": "Cache-Einstellung",
    "gui.setting.versionUpdate": "Version aktualisieren",
    "gui.setting.connectUsTitle": "Kontaktiere  uns",
    "gui.setting.uploadAttachment": "Anhang hochladen",
    "gui.setting.displayTitle": "Display-Einstellung",
    "gui.setting.fontSizeSetting": "font-size",
    "gui.setting.restartEffect": "Neustarteffekt",
    "gui.setting.fontSizeLarge": "Groß",
    "gui.setting.fontSizeMiddle": "Mittel",
    "gui.setting.fontSizeSmall": "Klein",
    "gui.setting.onlineRepair": "Online-Reparaturwerkzeug, klicke auf Öffnen >",
    "gui.setting.cacheSwitch": "Cache (öffnen/schließen)",
    "gui.setting.freeCache": "Freier Cache",
    "gui.setting.spaceCache": "Festes Intervall",
    "gui.setting.cacheWay": "Cache Weg",
    "gui.setting.interval": "Intervall",
    "gui.setting.freeTime": "Freie Zeit",
    "gui.setting.second": "Sekunde",
    "gui.setting.minute": "Minute",
    "gui.gui.python": "Python",
    "gui.gui.code": "Code",
    "gui.gui.saveAsMp": "Speichern als MP-Projekt",
    "gui.gui.saveAsSb3": "Speichern als SB3-Projekt",
    "gui.gui.openInstallSerialportDriverCourse": "Lies das Tutorial zur Treiberinstallation",
    "gui.menubar.cloudProject": "Cloud-Projekt",
    "gui.menubar.login": "Anmeldung",
    "gui.menubar.signout": "Abmelden",
    "gui.menubar.saveToCloud": "In der Cloud speichern",
    "gui.menubar.cloudServer": "Cloud",
    "gui.setting.cleanCache": "Cache leeren",
    "gui.setting.programCache": "Projekt-Cache",
    "gui.setting.configJson": "Konfigurationsdatei",
    "gui.setting.pythonEnv": "Python-Umgebung",
    "gui.setting.backpack": "Schoolbag-Daten",
    "gui.setting.compile": "Compilerdaten",
    "gui.setting.thirdExt": "Erweiterte Benutzerbibliothek",
    "gui.setting.dashboard": "IoT Dashboard",
    "gui.setting.cleanCacheBtn": "Auswahl löschen",
    "gui.setting.cleanCachePrompt": "Daten können nach dem Löschen nicht wiederhergestellt werden. Möchtest du sie wirklich löschen?",
    "gui.setting.cleanPythonEnvPrompt": "Um die Ausnahme anderer Fenster durch die Bereinigung der Python-Umgebung zu vermeiden, stelle bitte sicher, dass vor der Bereinigung nur ein Mind+-Softwarefenster geöffnet ist.",
    "gui.setting.cleanPyEnvOnPythonPrompt": "Im Python-Modus muss die Umgebung nach dem Bereinigen der Ressourcen zurückgesetzt werden. Dieser Vorgang dauert eine Weile.  Um zu vermeiden, dass die Bereinigung von Ressourcen Fehler verursacht, stelle bitte sicher, dass vor der Bereinigung nur ein Mind+-Softwarefenster geöffnet ist.",
    "gui.setting.cleanDashboardPrompt": "Alle Projects im Dashboard wurden entfernt und können nicht wieder hergestellt werden.",
    "gui.setting.expVersion": "Experimentelle Version",
    "gui.setting.expUpdateTips": "[Update-Notes]: Bei dieser Version handelt es sich um eine Early-Adopter-Version, die noch nicht offiziell veröffentlicht wurde. Einige Funktionen sind möglicherweise instabil. Bitte bestätige dies vor dem Herunterladen.",
    "gui.setting.cleanConfigSuccess": "Die Datenbereinigung ist abgeschlossen. Bitte starte Mind+ neu.",
    "gui.setting.cleanConfigError": "Bei der Datenbereinigung ist ein Fehler aufgetreten. Bitte versuche es erneut",
    "gui.setting.cleanPartError": "Fehler beim Reinigen von [PART]...",
    "gui.setting.cleaningPyEnv": "Bereinigen der Python-Umgebung...",
    "gui.setting.cleanPythonEnvError": "Wenn die Veröffentlichung der Python-Umgebungsressource fehlschlägt, kannst du die Software schließen, den Ordner [PATH] manuell löschen und die Software dann erneut öffnen",
    "gui.setting.multiWindowSetting": "Multi-Open-Software ",
    "gui.setting.multiWindowSettingTips": "Es wird empfohlen, die Funktion für Computer mit schwächerer Leistung zu deaktivieren, um Systemverzögerungen durch das gleichzeitige Öffnen mehrerer Apps zu vermeiden.",
    "gui.setting.multiWindowTrue": "aktiviert",
    "gui.setting.multiWindowFalse": "deaktviert",
    "gui.setting.systemSetting": "Systemeinstellungen",
    "gui.backpack.header": "Lager",
    "gui.backpack.errorBackpack": "Fehler beim Laden des Lagers",
    "gui.backpack.loadingBackpack": "Wird geladen ...",
    "gui.backpack.more": "Mehr",
    "gui.backpack.emptyBackpack": "Das Lager ist leer.",
    "gui.gui.costumesTab": "Kostüme",
    "gui.gui.soundsTab": "Klänge",
    "gui.gui.backdropsTab": "Bühnenbilder",
    "gui.gui.addExtension": "Erweitern",
    "gui.costumeTab.addCostumeFromLibrary": "Wähle ein Kostüm",
    "gui.costumeLibrary.chooseACostume": "Wähle ein Kostüm",
    "gui.costumeTab.addBackdropFromLibrary": "Wähle ein Bühnenbild",
    "gui.costumeTab.addBlankCostume": "Malen",
    "gui.costumeTab.addSurpriseCostume": "Überraschung",
    "gui.costumeTab.addFileBackdrop": "Bühnenbild hochladen",
    "gui.costumeTab.addFileCostume": "Kostüm hochladen",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Zuschneiden",
    "gui.soundEditor.stop": "Stopp",
    "gui.soundEditor.sound": "Klang",
    "gui.soundEditor.play": "Abspielen",
    "gui.soundEditor.save": "Speichern",
    "gui.soundEditor.undo": "Rückgängig",
    "gui.soundEditor.redo": "Wiederherstellen",
    "gui.soundEditor.faster": "Schneller",
    "gui.soundEditor.slower": "Langsamer",
    "gui.soundEditor.echo": "Echo",
    "gui.soundEditor.robot": "Roboter",
    "gui.soundEditor.louder": "Lauter",
    "gui.soundEditor.softer": "Leiser",
    "gui.soundEditor.reverse": "Umkehren",
    "gui.soundTab.recordSound": "Aufnehmen",
    "gui.soundTab.addSoundFromLibrary": "Klang wählen",
    "gui.soundTab.surpriseSound": "Überraschung",
    "gui.soundTab.fileUploadSound": "Klang hochladen",
    "gui.controls.stop": "Stopp",
    "gui.controls.go": "Los",
    "gui.stageHeader.stageSizeUnFull": "Vollbildmodus verlassen",
    "gui.SpriteInfo.show": "Zeige dich",
    "gui.SpriteInfo.size": "Größe",
    "gui.directionPicker.rotationStyles.allAround": "Rundherum",
    "gui.directionPicker.rotationStyles.leftRight": "Links/Rechts",
    "gui.directionPicker.rotationStyles.dontRotate": "Nicht drehen",
    "gui.spriteSelectorItem.contextMenuDuplicate": "Duplizieren",
    "gui.spriteSelectorItem.contextMenuDelete": "Löschen",
    "gui.spriteSelectorItem.contextMenuExport": "Exportieren",
    "gui.spriteSelector.addSpriteFromPaint": "Malen",
    "gui.spriteSelector.addSpriteFromSurprise": "Überraschung",
    "gui.spriteSelector.addSpriteFromFile": "Figur hochladen",
    "gui.stageSelector.stage": "Bühne",
    "gui.stageSelector.backdrops": "Bühnenbilder",
    "gui.stageSelector.addBackdropFromPaint": "Malen",
    "gui.stageSelector.addBackdropFromSurprise": "Überraschung",
    "gui.stageSelector.addBackdropFromFile": "Bühnenbild hochladen",
    "gui.modal.back": "Zurück",
    "gui.library.filterPlaceholder": "Suche",
    "gui.libraryTags.all": "Alles",
    "gui.libraryTags.animals": "Tiere",
    "gui.libraryTags.dance": "Tanz",
    "gui.libraryTags.effects": "Effekte",
    "gui.libraryTags.fantasy": "Fantasie",
    "gui.libraryTags.fashion": "Mode",
    "gui.libraryTags.food": "Essen",
    "gui.libraryTags.indoors": "Innenräume",
    "gui.libraryTags.loops": "Schleifen",
    "gui.libraryTags.music": "Musik",
    "gui.libraryTags.notes": "Noten",
    "gui.libraryTags.outdoors": "Landschaften",
    "gui.libraryTags.patterns": "Muster",
    "gui.libraryTags.people": "Personen",
    "gui.libraryTags.percussion": "Schlagwerk",
    "gui.libraryTags.space": "Weltraum",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Unterwasserwelt",
    "gui.libraryTags.voice": "Stimmen",
    "gui.libraryTags.wacky": "Verrücktes",
    "gui.libraryTags.animation": "Animationen",
    "gui.libraryTags.art": "Kunst",
    "gui.libraryTags.games": "Spiele",
    "gui.libraryTags.stories": "Geschichten",
    "gui.libraryTags.letters": "Buchstaben",
    "gui.soundLibrary.chooseASound": "Klang wählen",
    "gui.SpriteInfo.spritePlaceholder": "Name",
    "gui.cards.more-things-to-try": "Probiere mehr Dinge aus",
    "gui.cards.see-more": "Mehr erfahren",
    "gui.loader.message1": "Blöcke werden erstellt ...",
    "gui.loader.message2": "Figuren werden geladen ...",
    "gui.loader.message3": "Klänge werden geladen ...",
    "gui.loader.message4": "Erweiterungen werden geladen ...",
    "gui.loader.message5": "Katzen werden eingesammelt ...",
    "gui.loader.message6": "Nanos werden übertragen ...",
    "gui.loader.message7": "Gobos werden aufgeblasen ...",
    "gui.loader.message8": "Emojis werden vorbereitet ...",
    "gui.loader.headline": "Projekt wird geladen",
    "gui.cameraModal.cameraModalTitle": "Ein Foto aufnehmen",
    "gui.cameraModal.loadingCameraMessage": "Kamera wird geladen ...",
    "gui.cameraModal.permissionRequest": "Wir benötigen deine Zustimmung, um deine Kamera zu verwenden",
    "gui.cameraModal.retakePhoto": "Foto erneut aufnehmen",
    "gui.cameraModal.save": "Speichern",
    "gui.cameraModal.takePhoto": "Foto aufnehmen",
    "gui.cameraModal.loadingCaption": "Wird geladen ....",
    "gui.cameraModal.enableCameraCaption": "Kamera aktivieren",
    "gui.recordModal.title": "Klang aufnehmen",
    "gui.playbackStep.stopMsg": "Stopp",
    "gui.playbackStep.playMsg": "Abspielen",
    "gui.playbackStep.loadingMsg": "Wird geladen ...",
    "gui.playbackStep.saveMsg": "Speichern",
    "gui.playbackStep.reRecordMsg": "Erneut aufnehmen",
    "gui.webglModal.label": "Dein Browser unterstützt kein WebGL",
    "gui.webglModal.webgllink": "unterstützt kein WebGL",
    "gui.prompt.cancel": "Abbrechen",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Eingabefeld hinzufügen",
    "gui.customProcedures.addAnInputBoolean": "Eingabefeld hinzufügen",
    "gui.customProcedures.numberTextType": "Zahl oder Text",
    "gui.customProcedures.booleanType": "Wahrheitswert",
    "gui.customProcedures.addALabel": "Beschriftung hinzufügen",
    "gui.customProcedures.runWithoutScreenRefresh": "Ohne Bildschirmaktualisierung laufen lassen",
    "gui.customProcedures.cancel": "Abbrechen",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Verbinde deine Projekte mit der realen Welt.",
    "gui.extension.music.name": "Musik",
    "gui.extension.music.description": "Spiele Instrumente und Schlagzeug.",
    "gui.extension.pen.name": "Malstift",
    "gui.extension.pen.description": "Zeichne mit deinen Figuren.",
    "gui.gui.variableScopeOptionAllSprites": "Für alle Figuren",
    "gui.gui.variableScopeOptionSpriteOnly": "Nur für diese Figur",
    "gui.gui.variablePromptAllSpritesMessage": "Diese Variable wird für alle Figuren verfügbar sein.",
    "gui.monitor.contextMenu.default": "Normale Anzeigengröße",
    "gui.monitor.contextMenu.large": "Großanzeige",
    "gui.monitor.contextMenu.slider": "Schieberegler",
    "gui.monitor.contextMenu.import": "Importieren",
    "gui.monitor.contextMenu.export": "Exportieren",
    "gui.opcodeLabels.direction": "Richtung",
    "gui.opcodeLabels.xposition": "x-Position",
    "gui.opcodeLabels.yposition": "y-Position",
    "gui.opcodeLabels.size": "Größe",
    "gui.opcodeLabels.costumename": "Kostümname",
    "gui.opcodeLabels.costumenumber": "Kostümnummer",
    "gui.opcodeLabels.backdropname": "Bühnenbildname",
    "gui.opcodeLabels.backdropnumber": "Bühnenbildnummer",
    "gui.opcodeLabels.volume": "Lautstärke",
    "gui.opcodeLabels.tempo": "Tempo",
    "gui.opcodeLabels.answer": "Antwort",
    "gui.opcodeLabels.loudness": "Lautstärke",
    "gui.opcodeLabels.year": "Jahr",
    "gui.opcodeLabels.month": "Monat",
    "gui.opcodeLabels.date": "Datum",
    "gui.opcodeLabels.dayofweek": "Wochentag",
    "gui.opcodeLabels.hour": "Stunde",
    "gui.opcodeLabels.minute": "Minute",
    "gui.opcodeLabels.second": "Sekunde",
    "gui.opcodeLabels.timer": "Stoppuhr",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "Keine Variablen erlaubt!",
    "gui.burner.burnError": "Brennfehler",
    "gui.burner.burnFirmware": "Firmware brennen",
    "gui.burner.compileError": "Fehler beim Kompilieren",
    "gui.burner.connectError": "Verbindungsfehler, bitte erneut verbinden",
    "gui.burner.connectPort": "Verbinden mit",
    "gui.burner.eraseError": "Löschfehler",
    "gui.burner.findLeonardoPortError": "Kann den Bootloader-Port von Leonardo nicht finden",
    "gui.burner.firmwareError": "Firmware-Fehler, bitte wiederherstellen und erneut verbinden",
    "gui.burner.generateBinError": ".bin-Fehler generieren",
    "gui.burner.generateHexError": ".hex-Fehler generieren",
    "gui.burner.linkError": "Link-Fehler",
    "gui.burner.noMicrobit": "Sorry, es ist kein micro:bit, bitte micro:bit anschließen",
    "gui.burner.openPortError": "Konnte den Port nicht öffnen: Zugriff wird verweigert",
    "gui.burner.printFlashTooBig": "Nicht genügend Speicher; bitte klicke auf die \"Hilfe\" oben rechts, um die Speicherbelegung zu reduzieren. \nFehler beim Kompilieren für die Karte [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Zu wenig Speicher verfügbar, es können Stabilitätsprobleme auftreten. ",
    "gui.burner.printObjectTooBig": "Das Projekt ist zu groß; bitte klicke auf die \"Hilfe\" oben rechts, um es zu verkleinern. \nFehler beim Kompilieren für Board [%BOARD]. ",
    "gui.burner.printSize": "Das Projekt verwendet [%TEXTSIZE] Bytes, belegt ([%TEXTSIZEPRE]) Programmspeicherplatz und lässt [%TEXTSIZESUR] Bytes übrig, bis zu [%TEXTSIZEMAX] Bytes.\nGlobale Variablen belegen [%DATASIZE] Bytes, ([%DATASIZEPRE]) dynamischen Speicher, so dass [%DATASIZESUR] Bytes lokale Variablen übrig bleiben, bis zu [%DATASIZEMAX] Bytes. ",
    "gui.burner.printSizeError": "Konnte die Programmgröße nicht ermitteln. ",
    "gui.burner.swdCfgError": "Kann nicht in cmsis-dap.cfg schreiben",
    "gui.burner.UDiskPopUp": "UDisk poppt auf, bitte warte eine Weile und versuche es erneut",
    "gui.burner.UDiskWriteError": "Fehler beim Schreiben auf UDisk",
    "gui.burner.uploadDone": "Upload abgeschlossen",
    "gui.burner.writeLibFiles": "Bibliotheksdateien schreiben",
    "gui.cards.all-how-tos": "Alle Anleitungen",
    "gui.cards.how-tos": "Anleitungen",
    "gui.cards.remove": "Entfernen",
    "gui.codeLabel.createObject": "Erzeugen eines Objekts",
    "gui.codeLabel.customFunction": "Benutzerdefinierte Funktion",
    "gui.codeLabel.dynamicVariables": "Dynamische Variablen",
    "gui.codeLabel.eventCallbackFunction": "Ereignis-Callback-Funktion",
    "gui.codeLabel.functionDeclaration": "Funktionsdeklaration",
    "gui.codeLabel.globalCode": "Globale Variablen",
    "gui.codeLabel.mainProgramStart": "Start des Hauptprogramms",
    "gui.codeLabel.staticConstants": "Statische Konstanten",
    "gui.codeLabel.staticFunctions": "Statische Funktionen",
    "gui.codeLabel.SubthreadProgramStarts": "Subthread %1 Programm startet",
    "gui.connection.auto-scanning.notFoundHuskylensTip": "Verbindung wurde unterbrochen, bitte bestätige, dass das Gerät mit HuskyLens verbunden ist",
    "gui.controls.alreadyLastVersion": "Bereits die neueste Version",
    "gui.controls.checkForUpdates": "Auf Updates prüfen",
    "gui.controls.clearLocaleFirmware": "Datensätze löschen",
    "gui.controls.customFirmware": "Individuelle Firmware",
    "gui.controls.defaultFirmware": "Offizielle Firmware",
    "gui.controls.fullScreenControl": "Vollbild-Kontrolle",
    "gui.controls.uploadFirmware": "Ipload",
    "gui.costumeTab.backdrop": "Hintergrund",
    "gui.costumeTab.costume": "Kostüm",
    "gui.customProcedures.numberType": "Zahl",
    "gui.customProcedures.textType": "Text",
    "gui.dialog.baiduServer.togglePersonalAccount": "Die aktuelle Anzahl der verfügbaren Vokale hat die Obergrenze erreicht. Bitte wechsel zu deinem persönlichen Konto und melde dich an der Konsole an, um die Quote zu überprüfen",
    "gui.extension.actuator": "Aktor",
    "gui.extension.actuatorTitle": "Aktor auswählen",
    "gui.extension.AI.description": "Modellaufruf und Training unterstützen",
    "gui.extension.ai.name": "KI",
    "gui.extension.AIVisualInterface.addClass": "Klassifizierung hinzufügen",
    "gui.extension.AIVisualInterface.classificationCount": "[COUNT] Kategorien insgesamt",
    "gui.extension.AIVisualInterface.delete": "löschen",
    "gui.extension.AIVisualInterface.export": "Modell exportieren",
    "gui.extension.AIVisualInterface.imgCount": "[COUNT] Bilder",
    "gui.extension.AIVisualInterface.lable": "KI Visuelle Schnittstelle",
    "gui.extension.AIVisualInterface.possibility": "Die Möglichkeiten sind:",
    "gui.extension.AIVisualInterface.prediction": "Videobilder vorhersagen",
    "gui.extension.AIVisualInterface.recognitionResult": "Das Ergebnis der Bilderkennung ist:",
    "gui.extension.AIVisualInterface.retrain": "Neu trainieren",
    "gui.extension.AIVisualInterface.startTraining": "Training starten",
    "gui.extension.AIVisualInterface.title": "Keine Daten",
    "gui.extension.arduinContentLabel": "Gerät wählen",
    "gui.extension.arduinoExampleLib": "Arduino-Beispielbibliothek",
    "gui.extension.backdropLib": "Wähle einen Hintergrund",
    "gui.extension.bd-translate.description": "Verwende den Baidu-Übersetzungsdienst, um Text in andere Sprachen zu übersetzen.",
    "gui.extension.bd-translate.name": "Baidu Übersetzungsdienst",
    "gui.extension.board": "Board",
    "gui.extension.boardTitle": "Board auswählen",
    "gui.extension.bos0001.description": "Analoger Sensor, kann Drehposition erkennen",
    "gui.extension.bos0001.name": "Rotations-Sensor",
    "gui.extension.bos0002.description": "Drücken zur Ausgabe eines hohen Pegels, Loslassen zur Ausgabe eines niedrigen Pegels",
    "gui.extension.bos0002.name": "Taster",
    "gui.extension.bos0003.description": "Ausgabe von High-Pegel im gedrückten Zustand, Low-Pegel im losgelassenen Zustand",
    "gui.extension.bos0003.name": "Selbstsperrender Schalter",
    "gui.extension.bos0004.description": "Erkennung der Umgebungslichtintensität",
    "gui.extension.bos0004.name": "Licht-Sensor",
    "gui.extension.bos0006.description": "Erkennung von Regen und Nebel, nicht in Wasser getaucht",
    "gui.extension.bos0006.name": "Dampf-Sensor",
    "gui.extension.bos0007.description": "Erkennung von Feuerquellen oder Lichtquellen mit einer Wellenlänge von 760~1100nm",
    "gui.extension.bos0007.name": "Flammensensor",
    "gui.extension.bos0008.description": "Berührungsschalter, kann menschlichen Körper, Metall, etc. erkennen",
    "gui.extension.bos0008.name": "Berührungssensor",
    "gui.extension.bos0009.description": "Erkennen von Schallintensität",
    "gui.extension.bos0009.name": "Schall-Sensor",
    "gui.extension.bos0010.description": "Erkennen von Signalen, die durch verschiedene Farben erzeugt werden",
    "gui.extension.bos0010.name": "Infrarot-Näherungssensor",
    "gui.extension.bos0012.description": "Prüfen, ob das Objekt leitfähig ist",
    "gui.extension.bos0012.name": "Leitfähigkeitsschalter",
    "gui.extension.bos0013.description": "Erkennen von Infrarotstrahlen, die von sich bewegenden Menschen oder Tieren ausgesendet werden",
    "gui.extension.bos0013.name": "Bewegungsmelder",
    "gui.extension.bos0038.description": "Erkennen von Bodenfeuchtigkeit. Je geringer die Feuchtigkeit, desto kleiner der Ausgangswert",
    "gui.extension.bos0038.name": "Bodenfeuchtesensor",
    "gui.extension.bos0045.description": "Analoger Abstandssensor, Erfassungsbereich 2cm~1m",
    "gui.extension.bos0045.name": "Ultraschall-Abstandssensor",
    "gui.extension.bos0016.name": "Ultrahelle LED ",
    "gui.extension.bos0017.name": "Led Modul ",
    "gui.extension.bos0019.name": "LED Lichterkette ",
    "gui.extension.bos0021.name": "Lüftermodul ",
    "gui.extension.bos0022.name": "Sprachaufzeichnungsmodul",
    "gui.extension.bos0016.description": "Weißlicht emittierendes Modul ",
    "gui.extension.bos0017.description": "Leuchtdioden können Licht emittieren, das der Farbe der Lampenperlen entspricht ",
    "gui.extension.bos0019.description": "Bunter Lichtstreifen, die Länge des Lichtstreifens beträgt 3 Meter, die Farbe kann nicht geändert werden ",
    "gui.extension.bos0021.description": "Modul zum Antreiben der Lüfterblattdrehung",
    "gui.extension.bos0022.description": "Kann einen 10s Sound aufnehmen und eine Aufnahme abspielen",
    "gui.extension.calliope.description": "Werde kreativ und beginne zu programmieren!",
    "gui.extension.calliope.name": "CALLIOPE mini",
    "gui.extension.communicationModule": "Kommunikation",
    "gui.extension.communicationModuleTitle": "Kommunikation auswählen",
    "gui.extension.display": "Anzeige",
    "gui.extension.displayTitle": "Display auswählen",
    "gui.extension.function": "Funktion",
    "gui.extension.functionTitle": "Funktion auswählen",
    "gui.extension.google-translate.description": "Übersetze Text in viele Sprachen.",
    "gui.extension.google-translate.name": "Übersetzen",
    "gui.extension.http.description": "Lass das Gerät Informationen über HTTP abfragen",
    "gui.extension.http.name": "HTTP",
    "gui.extension.huskylens.algorithmOperationLabel": "Algorithmische Bedienung",
    "gui.extension.huskylens.cameraFunctionLabel": "Fotos aufnehmen",
    "gui.extension.huskylens.description": "Visueller Sensor für künstliche Intelligenz, der die Gesichtserkennung und das Lernen unterstützt",
    "gui.extension.huskylens.displayFunctionLabel": "Anzeigen",
    "gui.extension.huskylens.huskylensbasic": "Grundlegende Funktionen",
    "gui.extension.huskylens.KNNText": "Computerseitige Kamerasimulation KNN-Klassifikation",
    "gui.extension.huskylens.modelOperationLabel": "Modell-Bedienung",
    "gui.extension.huskylens.name": "HUSKYLENS AI Camera",
    "gui.extension.huskylens.pcsetting": "Computerkamera konfigurieren",
    "gui.extension.huskylens.visualmodal": "KI-Visualisierungstools",
    "gui.extension.huskyLensPro.connectingMessage": "HuskyLens versucht, eine Verbindung anzufordern, bitte warten",
    "gui.extension.huskyLensPro.description": "Verwende HUSKYLENS, um sich direkt mit einem Computer zu verbinden und die Grundlagen der KI zu erlernen",
    "gui.extension.huskyLensPro.name": "HUSKYLENS EDU",
    "gui.extension.importUserEx": "Erweiterung importieren",
    "gui.extension.internet.description": "Internet-Kommunikation",
    "gui.extension.internet.name": "Internet",
    "gui.extension.internetService": "Internet",
    "gui.extension.internetServiceTitle": "Wähle Internet",
    "gui.extension.kit": "Bausatz",
    "gui.extension.kitTitle": "Bausatz auswählen",
    "gui.extension.machineHearing.description": "Spracherkennung durch Training",
    "gui.extension.machineHearing.name": "Maschinelles Zuhören",
    "gui.extension.machinelearning.description": "Basierend auf dem Algorithmus des maschinellen Lernens kann die Anwendung der künstlichen Intelligenz ohne Netzwerk realisiert werden",
    "gui.extension.machinelearning.name": "Maschinelles Lernen(ML5)",
    "gui.extension.machineVision.description": "Bildverarbeitung, Zeichnen, Filtern, Erkennen von Form, Farbe, Dimensionscode, Merkmal",
    "gui.extension.machineVision.name": "Maschinelles Sehen",
    "gui.extension.maixduino.description": "Hauptsteuerplatine basierend auf K210-Chip",
    "gui.extension.maixduino.name": "Maixduino",
    "gui.extension.makeBlock": "Einen Block erstellen",
    "gui.extension.mqtt.description": "Lass Geräte über das MQTT-Protokoll kommunizieren",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.openUserExFile": "Öffne die Erweiterungsdatei",
    "gui.extension.pictureai.cameraPermissionsCancel": "取消",
    "gui.extension.pictureai.cameraPermissionsGoToSettings": "前往设置",
    "gui.extension.pictureai.cameraPermissionsMessage": "请前往设置中勾选授权。如已授权但仍无法使用，请确认摄像头已正常连接无其它软件占用，或重启软件。",
    "gui.extension.pictureai.cameraPermissionsTitle": "需要相机权限",
    "gui.extension.pictureai.defaultTitleText": "Video-Fenster",
    "gui.extension.pictureai.description": "um etwas Wunderbares mit der Bild-KI zu machen, muss eine Verbindung mit dem Internet bestehen",
    "gui.extension.pictureai.name": "KI-Bilderkennung",
    "gui.extension.pythonExampleLib": "Python-Beispielbibliothek",
    "gui.extension.scratchExampleLib": "Scratch-Beispielbibliothek",
    "gui.extension.sensor": "Sensor",
    "gui.extension.sensorTitle": "Sensor auswählen",
    "gui.extension.ser0006.description": "Kann in eine bestimmte Winkelposition zwischen 0 und 180 Grad gedreht werden ",
    "gui.extension.ser0006.name": "Mikro-Servo",
    "gui.extension.speechRecognition.description": "Spracherkennung",
    "gui.extension.speechRecognition.name": "Spracherkennung",
    "gui.extension.spreadBoard": "Abschirmung",
    "gui.extension.spreadBoardTitle": "Abschirmung auswählen",
    "gui.extension.spriteLib": "Wähle ein Sprite",
    "gui.extension.text-speech.description": "Bringe Deine Projekte zum Sprechen",
    "gui.extension.text-speech.name": "Text zu Sprache wandeln",
    "gui.extension.thirdExpansion": "Benutzer-Erweiterung",
    "gui.extension.thirdExpansionTitle": "Benutzer-Erweiterung wählen",
    "gui.extension.thirdList": "Erweiterungsliste",
    "gui.extension.thirdModuleSearch": "Suchen oder Projekt-URL eingeben",
    "gui.extension.thirdModulesHelp": "Hinweis: Die Module in der Benutzer-Bibliothek werden von Mind+ Enthusiasten, {clickHelp} für {tutorial} und {list}.",
    "gui.extension.thirdTutorial": "Entwickler-Tutorial",
    "gui.extension.tinywebdb.description": "Betreibe die TinyWebDB Netzwerkdatenbank, kann mit APP Inventor verwendet werden",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.udp.description": "Lass Geräte und Einrichtungen im lokalen Netzwerk eine Multi-Maschinen-Broadcast-Kommunikation über das UDP-Protokoll realisieren",
    "gui.extension.udp.name": "UDP-Broadcast",
    "gui.extension.unavailable": "Nicht verfügbar",
    "gui.extension.video-sensing.description": "Erfasse Bewegungen mit der Kamera.",
    "gui.extension.video-sensing.name": "Video-Erkennung",
    "gui.extension.weather.description": "Hier wird ein Wi-Fi-Modul benötigt, um Wetterinformationen zu erhalten",
    "gui.extension.weather.description.javascript": "Um Wetterinformationen über das Internet abzurufen, muss der Computer mit dem Internet verbunden sein",
    "gui.extension.weather.name": "Das Wetter abrufen",
    "gui.gui.arduinoRun": "Ausführen",
    "gui.gui.arduinoSave": "Speichern",
    "gui.gui.autoGenerated": "Automatisch generieren",
    "gui.gui.baud": "Baud",
    "gui.gui.blocks": "Blöcke",
    "gui.gui.bothNLandCR": "LF & CR",
    "gui.gui.burnBootloader": "Drahtlosen Bootloader brennen",
    "gui.gui.burnFirmware": "Firmware erneut brennen",
    "gui.gui.burnFirmwareError": "Fehler beim Brennen der Firmware",
    "gui.gui.cantFindYouWant": "Du findest nicht, was du suchst?",
    "gui.gui.carriageReturn": "Returntaste",
    "gui.gui.clearOutput": "Ausgang löschen",
    "gui.gui.clickHere": "Klicke hier",
    "gui.gui.clickPrompt": "Klicken, um Blöcke zu markieren. Doppelklicken, um Blöcke zu lokalisieren.",
    "gui.gui.clickViewHelp": "Wie kann man den Konflikt lösen?",
    "gui.gui.close": "Schließen",
    "gui.gui.closeHexForm": "Schließen",
    "gui.gui.closeSerial": "Serielle Schnittstelle schließen",
    "gui.gui.codeArea": "Code",
    "gui.gui.codeBox": "Code-Box",
    "gui.gui.compileAndUpload": "Kompiliert und hochgeladen",
    "gui.gui.compileOnly": "Nur kompiliert",
    "gui.gui.compileOpen": "Kompiliert und öffnen",
    "gui.gui.conflict": "Konflikt",
    "gui.gui.conflictPrompt": "Konflikt-Warnmeldung",
    "gui.gui.connected": "Verbunden",
    "gui.gui.connecting": "Verbinden...",
    "gui.gui.failedConnected": "Verbindung fehlgeschlagen",
    "gui.gui.fileSys": "Dateisystem",
    "gui.gui.hexForm": "Hex-Form",
    "gui.gui.loaded": "Geladen",
    "gui.gui.manualEditing": "Manuelles Editieren",
    "gui.gui.moduleCode": "Modul-Code",
    "gui.gui.newLine": "Zeilenvorschub",
    "gui.gui.noLineEnd": "Keine Leitungsabschlüsse",
    "gui.gui.notLoaded": "NICHT geladen",
    "gui.gui.open": "Öffnen",
    "gui.gui.openHexForm": "Hex-Form zum Anzeigen und Senden von Daten verwenden",
    "gui.gui.openSerial": "Serielle Schnittstelle öffnen",
    "gui.gui.pythonMode": "Python-Modus",
    "gui.gui.recentlyEdited": "Kürzlich bearbeitetes Projekt",
    "gui.gui.scroll": "Scrollen",
    "gui.gui.send": "Senden",
    "gui.gui.serial": "Serielle Schnittstelle",
    "gui.gui.stageSizeFull": "Bühnengröße umschalten - Vollbild",
    "gui.gui.stageSizeLarge": "Großbildschirm-Steuerung",
    "gui.gui.stageSizeNostage": "Umschalten der Bühnengröße - Keine Bühne",
    "gui.gui.stageSizeSmall": "Steuerung des kleinen Bildschirms",
    "gui.gui.startPython": "Python starten",
    "gui.gui.stopPython": "Python anhalten",
    "gui.gui.unavailableHexData": "Ungültige Hex-Form! Bitte stelle Hex-Daten mit zwei Zeichen ((0-9/A-F) und einem Leerzeichen dar, z. B. \"00 01 A2 FF\".",
    "gui.gui.unConnectedDev": "Kein Gerät angeschlossen",
    "gui.gui.unselectedSerialport": "Kein Gerät angeschlossen",
    "gui.gui.uploadToDev": "Hochladen",
    "gui.gui.viewConflict": "Ansichtskonflikt",
    "gui.gui.viewHelp": " um mehr zu finden",
    "gui.huskyLensPro.completed": "Abgeschlossen",
    "gui.huskyLensPro.countdown": "wird voraussichtlich in [TIME] abgeschlossen sein",
    "gui.huskyLensPro.deploying": "Modell wird bereitgestellt",
    "gui.huskyLensPro.deploymentFailed": "Daten bereitstellen fehlgeschlagen!",
    "gui.huskyLensPro.disconnected": "Die Verbindung wurde getrennt und die Bereitstellung ist beendet. Bitte überprüfe die USB-Verbindung!",
    "gui.huskyLensPro.failedRetry": "Fehlgeschlagen. Bitte versuche es erneut!",
    "gui.huskyLensPro.failedToSetCategoryLabel": "Das Setzen der \"Kategoriebezeichnung\" ist fehlgeschlagen!",
    "gui.huskyLensPro.forgettingFailure": "Lerndaten können nicht vergessen werden!",
    "gui.huskyLensPro.huskylenMaker": "Diese Funktion ist nur in der Ausbildungsversion verfügbar",
    "gui.huskyLensPro.minutes": " Minuten ",
    "gui.huskyLensPro.requestFailed": "Anforderung \"Deploy\" fehlgeschlagen!",
    "gui.huskyLensPro.seconds": " Sekunden",
    "gui.huskyLensPro.startDeployment": "Einsatz starten",
    "gui.huskyLensPro.switchingAlgorithmFailed": "Kann nicht auf den Algorithmus \"Objektklassifikation\" umschalten!",
    "gui.huskyLensPro.toClassificationData": "Bitte zuerst Klassifizierungsdaten hinzufügen",
    "gui.huskyLensPro.toConnect": "Bitte zuerst HUSKYLENS EDU anschließen",
    "gui.huskyLensPro.updateVersion": "Bitte verbinde dich mit der Lernversion und die Firmware-Version sollte nicht kleiner als 0.5.1 sein. Siehe \"Hilfe\" für Details",
    "gui.loadProject.error": "Projektfehler laden\n",
    "gui.menu.edit.clearCache": "Cache löschen",
    "gui.menu.edit.codeStyle": "Code-Stil",
    "gui.menu.edit.copy": "Kopieren",
    "gui.menu.edit.cut": "Ausschneiden",
    "gui.menu.edit.fontSize": "Schriftgröße",
    "gui.menu.edit.paste": "Einfügen",
    "gui.menu.edit.redo": "Wiederherstellen",
    "gui.menu.edit.selectAll": "Alles auswählen",
    "gui.menu.edit.undo": "Rückgängig",
    "gui.modal.continue": "Weiter",
    "gui.modal.help": "Hilfe",
    "gui.modal.paused": "Pausiert",
    "gui.modal.run": "Ausführen",
    "gui.modal.save": "Speichern",
    "gui.monitor.listMonitor.empty": "(leer)",
    "gui.monitor.listMonitor.listLength": "Länge {length}",
    "gui.progress.burning": "Brennen",
    "gui.progress.burning1": "Brennen",
    "gui.progress.compileProgress": "Kompilieren läuft",
    "gui.progress.compileSuccess": "Kompilier-Erfolg",
    "gui.progress.compiling": "Kompilieren",
    "gui.progress.errMsg": "!Upload-Fehler, bitte prüfe die Details im schwarzen Fenster rechts unten",
    "gui.progress.errMsg1": "!Upload-Fehler, bitte prüfe die Details im schwarzen Fenster rechts unten",
    "gui.progress.errMsg2": "!Upload-Fehler, bitte prüfe die Details im schwarzen Fenster rechts unten",
    "gui.progress.networkInterfaceCard": "NIC",
    "gui.progress.uploadProgress": "Upload-Fortschritt",
    "gui.progress.uploadSuccess": "Upload-Erfolg",
    "gui.recordStep.permissionMicrophone": "Wir benötigen Deine Erlaubnis zur Verwendung Deines Mikrofons",
    "gui.recordStep.record": "Aufnehmen",
    "gui.recordStep.recordByClickBtn": "Starte  die Aufnahme, indem Du auf die Schaltfläche unten klickst",
    "gui.recordStep.stopRecord": "Aufnahme stoppen",
    "gui.soundTab.addSound": "Ton hinzufügen",
    "gui.spreadmaqueen.perror": "! Fehler: Die P12-Pin-LED-Lampe ist nicht verfügbar. Bitte Wähle eine P8-Pin-LED-Lampe.",
    "gui.sprite.addSpriteFromCamera": "Kamera",
    "gui.sprite.addSpriteFromLibrary": "Sprite-Bibliothek",
    "gui.sprite.direction": "Richtung",
    "gui.sprite.rotation": "Rotation",
    "gui.sprite.sprite": "Sprite",
    "gui.stage.addBackdropFromCamera": "Kamera",
    "gui.stage.addBackdropFromLibrary": "Hintergrund auswählen",
    "gui.webglModal.descriptionMind": "Leider sieht es so aus, als ob dein Computer {webGlLink} nicht unterstützt. Diese Technologie wird benötigt, damit Mind+ läuft. {updateGpuDriver}",
    "gui.webglModal.ok": "Ok",
    "gui.webglModal.updateGpuDriver": "Bitte versuche, den Grafikkartentreiber zu aktualisieren",
    "gui.wifi.pwdNotLegal": "! Fehler: Das Passwort hat das falsche Format. Das Wi-Fi-Passwort muss aus 8-20 Ziffern oder Zahlen bestehen",
    "gui.sliderModal.min": "Minimum value",
    "gui.sliderModal.max": "Maximum value",
    "gui.sliderModal.title": "Change Slider Range",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "Vortex educational robot from DFRobot",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "Explorer D1 educational robot from DFRobot",
    "gui.extension.arduinounoR3.name": "ArduinounoR3",
    "gui.extension.arduinounoR3.description": " Wuhan Maker Course custom Arduino Uno main control board kit.",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "Max:bot Robot based on micro:bit",
    "gui.extension.max.name": "MAX",
    "gui.extension.max.description": "MAX（ROB0137）robot based on Arduino",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "Motor:bit motor and servo expansion board",
    "gui.extension.maqueen.name": "Maqueen",
    "gui.extension.maqueen.description": "micro:Maqueen robot based on micro:bit",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.spread.name": "micro:bit & mPython expansion board",
    "gui.extension.spreadboson.name": "micro:bit & mPython expansion board",
    "gui.extension.microTouch.name": "TouchKey",
    "gui.extension.microNaturalScience.name": "micro:naturalScience",
    "gui.extension.microTouch.description": "Integrated 4×4 keyboard and motor servo drive expansion board",
    "gui.extension.microNaturalScience.description": "Micro:bit expansion board with multiple environmental sensors",
    "gui.extension.microIoT.name": "micro:IoT",
    "gui.extension.microIoT.description": "Integrated display and Internet of things micro:bit extension board",
    "gui.extension.spreadMaqueen.name": "maqueen extension",
    "gui.extension.spread.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadboson.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadmaqueen.description": "An entry-level minicar based on the control version",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "The advanced educational robot that supports both micro:bit and mPython",
    "gui.extension.iot.name": "Internet of Things",
    "gui.extension.iot.description": "Extended Edition Based on Internet of Things",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Device controlled by Leonardo",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Uno main control board module",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Device controlled by Arduino Nano",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "Main control board based on ESP32",
    "gui.extension.telloesp32.name": "RoboMaster TT(ESP32)",
    "gui.extension.telloesp32.description": "Educational drone with DJI top flight control algorithms, safe & stable",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Device controlled by Mega2560",
    "gui.extension.firebeetleesp32.name": "FireBeetle ESP32",
    "gui.extension.firebeetleesp32.description": "Device controlled by FireBeetle ESP32",
    "gui.extension.dfr0299.name": "DFPlayer MP3 Module",
    "gui.extension.dfr0299.description": "MP3 Player Module",
    "gui.extension.dfr0523.name": "Peristaltic Pump",
    "gui.extension.dfr0523.description": "Deliver fluid by squeezing and releasing the pump alternately",
    "gui.extension.dfr0017.name": "Relay Module",
    "gui.extension.dfr0017.description": "Control high current device",
    "gui.extension.steppermotor.name": "Stepper Motor",
    "gui.extension.steppermotor.description": "Realize accurate position control by pulse control",
    "gui.extension.dfr0534.name": "Serial MP3 module",
    "gui.extension.dfr0534.description": "Serial MP3 voice module",
    "gui.extension.servo360.name": "360° Micro Servo",
    "gui.extension.servo360.description": "Control speed and direction",
    "gui.extension.tel0118.name": "OBLOQ IoT Module ",
    "gui.extension.tel0118.description": "A Wi-Fi to Serial device",
    "gui.extension.dfr0095.name": "IR Transmitter Module",
    "gui.extension.dfr0095.description": "Transmit standard 38KHz infrared signal",
    "gui.extension.dfr0094.name": "IR Receiver Module",
    "gui.extension.dfr0094.description": "Receive standard 38KHz infrared signal",
    "gui.extension.midi.name": "MIDI",
    "gui.extension.midi.description": "MIDI player",
    "gui.extension.radio.name": "Wireless broadcasting",
    "gui.extension.radio.description": "Wireless broadcast communication between multiple control boards",
    "gui.extension.tel0094.name": "GPS signal receiving module",
    "gui.extension.tel0094.description": "GPS signal receiving module",
    "gui.extension.tel0026.name": "Bluetooth module",
    "gui.extension.tel0026.description": "Bluetooth serial port module",
    "gui.extension.dfr0486.name": "OLED-12864 Display",
    "gui.extension.dfr0486.description": "I2C OLED-12864 Display Module",
    "gui.extension.fit0352.name": "WS2812 RGB LED Strip",
    "gui.extension.fit0352.description": "Control WS2812-based LED strip modules",
    "gui.extension.dfr0063.name": "LCD1602 Display",
    "gui.extension.dfr0063.description": "LCD module which can show 2 lines and 16 characters in each line",
    "gui.extension.dfr0021.name": "Digital LED Light Module",
    "gui.extension.dfr0021.description": "White, red, green and blue LED modules",
    "gui.extension.tm1650.name": "TM1650 Four digit tube",
    "gui.extension.tm1650.description": "Four digit tube",
    "gui.extension.matrix8_8.name": "MAX7219 8x8 dot matrix",
    "gui.extension.matrix8_8.description": "8x8 lattice module",
    "gui.extension.dfr0522.name": "8x16 RGB LED Matrix Panel",
    "gui.extension.dfr0522.description": "Display images, numbers... support scroll display and user-defined setting",
    "gui.extension.softSerialport.name": "Software Serial Port",
    "gui.extension.softSerialport.description": "Use I/O port to simulate serial communication function",
    "gui.extension.IICScan.name": "I2C Scan",
    "gui.extension.IICScan.description": "Scan all device addresses that connected to I2C interface",
    "gui.extension.bluetooth.name": "Bluetooth",
    "gui.extension.bluetooth.description": "Bluetooth device",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "GoBLE APP can help youcontrol main control board via smart phone",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "Use it to read or write and save data with eeprom",
    "gui.extension.interrupt.name": "Interrupt",
    "gui.extension.interrupt.description": "Interrupt pin",
    "gui.extension.mpyfile.name": "Datei",
    "gui.extension.mpyfile.description": "Read and write files in the control panel, store data and export",
    "gui.extension.sdcard.name": "SD card reader module",
    "gui.extension.sdcard.description": "MicroSD card reader module",
    "gui.extension.multithread.name": "MultiThread",
    "gui.extension.multithread.description": "Allow multiple programs and the main program to run simultaneously",
    "gui.extension.speech.name": "Speech",
    "gui.extension.speech.description": "Talk to your projects.",
    "gui.extension.sen0001.name": "Ultrasonic Sensor",
    "gui.extension.sen0001.description": "Accurate distance detection with the range 2~800cm, compatible with urm and SR04 ultrasonic",
    "gui.extension.dfr0023.name": "Analog LM35 Temperature Sensor",
    "gui.extension.dfr0023.description": "LM35-based semiconductor temperature sensor with the range 0~100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22 Temperature and Humidity Sensor",
    "gui.extension.dhtTHSensor.description": "Detect enviroment temperature and humidity",
    "gui.extension.dsTSensor.name": "DS18B20 Temperature Sensor",
    "gui.extension.dsTSensor.description": "Detect ambient temperature with large range of -55~+125℃ ",
    "gui.extension.sen0203.name": "Heart Rate Monitor Sensor",
    "gui.extension.sen0203.description": "Mini heart rate sensor with analog pulse and digital square wave output modes",
    "gui.extension.sen0177.name": "Laser PM2.5 Air Quality Sensor",
    "gui.extension.sen0177.description": "Measure PM1, PM2.5 and PM10. Transfering data via serial port",
    "gui.extension.sen0014.name": "GP2Y0A21 IR Distance Sensor",
    "gui.extension.sen0014.description": "IR sensor based on GP2Y0A21 with 10~80cm measuring range",
    "gui.extension.sen0204.name": "Non-contact Liquid Level Sensor",
    "gui.extension.sen0204.description": "Detect liquid level but free from any contact",
    "gui.extension.sen0160.name": "Hx711 weight sensor",
    "gui.extension.sen0160.description": "Measure the Hx711 weight sensor",
    "gui.extension.sen0161.name": "Analog pH Meter",
    "gui.extension.sen0161.description": "Measure the liquid pH value with 5V power supply",
    "gui.extension.sen0161-v2.name": "Analog pH Meter(V2)",
    "gui.extension.sen0161-v2.description": "Measure the liquid pH calue with 3.3~5.5V power supply ",
    "gui.extension.sen0244.name": "Analog TDS Sensor",
    "gui.extension.sen0244.description": "Measure the TDS(Total Dissolved Solids) of liquid which suggests the cleanliness of water",
    "gui.extension.sen0165.name": "Analog ORP Meter",
    "gui.extension.sen0165.description": "Measure the ORP(Oxidation-Reduction Potential) of liquid to test the water quality",
    "gui.extension.sen0237.name": "Analog Dissolved Oxygen Sensor",
    "gui.extension.sen0237.description": "Measure the DO(Dissolved Oxygen) of liquid to evaluate the water quality",
    "gui.extension.dfr0300-H.name": "Analog EC Meter",
    "gui.extension.dfr0300-H.description": "Measure the EC(Electrical Conductivity) of liquid to evaluate the water quality",
    "gui.extension.dfr0300.name": "Analog EC Meter(V2)",
    "gui.extension.dfr0300.description": "Measure the EC(Electrical Conductivity) of liquid to evaluate the water quality",
    "gui.extension.sen0170.name": "Anemometer",
    "gui.extension.sen0170.description": "Measure wind speed level and output voltage signal",
    "gui.extension.sen0226.name": "BMP280 Temperature Sensor",
    "gui.extension.sen0226.description": "BMP280 barometric pressure sensor for temperature and barometric measurement",
    "gui.extension.sen0228.name": "I2C VEML7700 Ambient Light Sensor",
    "gui.extension.sen0228.description": "A high accuracy ambient light digital 16-bit resolution sensor",
    "gui.extension.sen0236.name": "BME280 module",
    "gui.extension.sen0236.description": "Environmental sensors (temperature, humidity, air pressure)",
    "gui.extension.dfr0022.name": "Analog Grayscale Sensor",
    "gui.extension.dfr0022.description": "Detect light density and reflect analog voltage signal, whihch can be applied to line-tracking",
    "gui.extension.motucamera.name": "MU sensor board",
    "gui.extension.motucamera.description": "description for the 'MU sensor board' extension",
    "gui.extension.dfr0552.name": "12-bit DA conversion module",
    "gui.extension.dfr0552.description": "Convert the digital quantity to the corresponding dc voltage signal accurately (non-pwm)",
    "gui.extension.sen0117.name": "Speech synthesis module",
    "gui.extension.sen0117.description": "You just type in the Chinese and English characters and Numbers and it will read them",
    "gui.extension.dfr0576.name": "I2C cascade extender",
    "gui.extension.dfr0576.description": "Used to resolve I2C device address conflicts",
    "gui.extension.sen0248.name": "BME680 environmental sensor",
    "gui.extension.sen0248.description": "VOC (volatile organic compounds), temperature, humidity and air pressure can be measured",
    "gui.extension.sen0304.name": "I2C ultrasonic ranging sensor",
    "gui.extension.sen0304.description": "Ultrasonic sensors designed for rapid ranging or obstacle avoidance applications",
    "gui.extension.sen0307.name": "Analog ultrasonic ranging sensor",
    "gui.extension.sen0307.description": "Open dual probe analog ultrasonic ranging module",
    "gui.extension.sen0250.name": "BMI160 6 axis inertial motion sensor",
    "gui.extension.sen0250.description": "16-bit 3-axis acceleration + ultra-low power consumption 3-axis gyroscope",
    "gui.extension.sen0224.name": "I2C LIS2DH triaxial accelerometer",
    "gui.extension.sen0224.description": "Ultra-low power consumption 3 axis acceleration sensor",
    "gui.extension.dfr0151.name": "Real Time Clock DS1307",
    "gui.extension.dfr0151.description": "the time error is only about 1 second in 24 hours",
    "gui.extension.dfr0469.name": "Real Time Clock SD2405",
    "gui.extension.dfr0469.description": "An accurate I2C real-time clock (RTC) with crystal compensation, inner chargeable battery. ",
    "gui.extension.dfr0126.name": "Spectrum Analysis Module",
    "gui.extension.dfr0126.description": "Keep track of the sound frequency",
    "gui.extension.dfr0231.name": "NFC Module",
    "gui.extension.dfr0231.description": "Suitable for short distance wireless communication",
    "gui.extension.sen0245.name": "VL53L0X Laser Ranging Sensor",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "Analog Ambient Light Sensor",
    "gui.extension.dfr0026.description": "Detect the ambient light density",
    "gui.extension.dfr0027.name": "Digital Vibration Sensor",
    "gui.extension.dfr0027.description": "Dectect vibration signal and output digital signal",
    "gui.extension.dfr0028.name": "Digital Tilt Sensor",
    "gui.extension.dfr0028.description": "Based on the number of mercury switches, it can be used as a simple tilt sensor",
    "gui.extension.dfr0029.name": "Digital Push Button",
    "gui.extension.dfr0029.description": "Push down:high level  Release: low level",
    "gui.extension.dfr0030.name": "Digital Capacitive Touch Sensor",
    "gui.extension.dfr0030.description": "Capacitive touch switch module for sensing metal and human body",
    "gui.extension.dfr0033.name": "Digital Magnetic Sensor",
    "gui.extension.dfr0033.description": "Detect magnetic materials within 3cm",
    "gui.extension.dfr0034.name": "Analog Sound Sensor",
    "gui.extension.dfr0034.description": "Detect the loudness in ambient",
    "gui.extension.sen0132.name": "Analog Carbon Monoxide Sensor",
    "gui.extension.sen0132.description": "Detect CO-gas concentrations within 20 to 2000ppm",
    "gui.extension.dfr0051.name": "Analog Voltage Divider",
    "gui.extension.dfr0051.description": "Detect voltage(DC) from 0.0245V to 25V",
    "gui.extension.dfr0052.name": "Analog Piezo Disk Vibration Sensor",
    "gui.extension.dfr0052.description": "To detect dynamic and Quasi-static stress and output analog signal",
    "gui.extension.dfr0058.name": "Analog Rotation Potentiometer Sensor",
    "gui.extension.dfr0058.description": "High-accuracy potentiometer with maximum rotation count of 10",
    "gui.extension.dfr0061.name": "Joystick Module",
    "gui.extension.dfr0061.description": "(X,Y) analog output, (Z) 1 digital output and it can be used as romote controller",
    "gui.extension.dfr0072.name": "Shiftout Sensor",
    "gui.extension.dfr0072.description": "description for the 'Shiftout sensor' extension",
    "gui.extension.dfr0563.name": "battery Sensor",
    "gui.extension.dfr0563.description": "description for the 'battery sensor' extension",
    "gui.extension.dfr0553.name": "16-bit AD conversion module",
    "gui.extension.dfr0553.description": "description for the '16-bit AD conversion module' extension",
    "gui.extension.dfr0588.name": "Temperature and humidity sensor",
    "gui.extension.dfr0588.description": "Temperature and humidity sensor",
    "gui.extension.dfr0076.name": "Analog Flame Sensor",
    "gui.extension.dfr0076.description": "Detect flame or light with a wavelength of 760nm-1100nm",
    "gui.extension.dfr0117.name": "Eeprom iic Sensor",
    "gui.extension.dfr0117.description": "Data storage module,32kb",
    "gui.extension.dfr0143.name": "Triple Axis Accelerometer MMA7361",
    "gui.extension.dfr0143.description": "Based on MMA7361 chip, detect gesture and movement direction",
    "gui.extension.sen0018.name": "Digital Infrared Motion Sensor",
    "gui.extension.sen0018.description": "Detect infrared released forom moving human or animal",
    "gui.extension.sen0114.name": "Analog Soil Moisture Sensor",
    "gui.extension.sen0114.description": "Read the amount of moisture present in the soil surrounding it. ",
    "gui.extension.sen0121.name": "Steam Sensor",
    "gui.extension.sen0121.description": "Detect rainwater,steam and water mist",
    "gui.extension.sen0212.name": "Color Identification Sensor",
    "gui.extension.sen0212.description": "Identify objects colors and output RGB value",
    "gui.extension.sen0253.name": "10DOF Attitude Sensor",
    "gui.extension.sen0253.description": "BBNO055 is a new sensor IC for implementing an intelligent 9-axis absolute orientation sensor.",
    "gui.extension.sen0290.name": "lightning sensor",
    "gui.extension.sen0290.description": "intelligent lightning sensor",
    "gui.extension.sen0291.name": "I2C digital power meter",
    "gui.extension.ps2.name": "PS2 handle",
    "gui.extension.ps2.description": "PS2 handle",
    "gui.extension.sen0291.description": "High precision measurement of voltage, current and power",
    "gui.extension.sen0202.name": "3D Mini Gesture Sensor",
    "gui.extension.sen0202.description": "Detect clockwise and counterclockwise rotation and movement directions",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "Micro:bit dedicated lithium battery motor expansion board",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "Enable the device connect to the Wi-Fi network",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "Enable the device get local time",
    "gui.menu.edit.maxReset": "Restore Max to factory defaults",
    "gui.progress.uploadSuccessVm": "upload success",
    "gui.howtos.spin-around.name": "Spin Around",
    "gui.howtos.spin.step_dragTurn": "Drag out a [turn] block",
    "gui.howtos.spin.step_clickTurn": "Click the block to run",
    "gui.howtos.spin.step_clickControl": "Click the [Control] category",
    "gui.howtos.spin.step_dragForever": "Drag out a [forever] block",
    "gui.howtos.spin.step_clickForever": "Click the block to run",
    "gui.howtos.spin.step_changeColor": "Add a [change color effect] block",
    "gui.howtos.say-hello.name": "Say Hello",
    "gui.howtos.say-hello.step_addSprite": "Add a new sprite",
    "gui.howtos.say-hello.step_clickLooks": "Click the [Looks] category",
    "gui.howtos.say-hello.step_dragSay": "Drag out a [say] block",
    "gui.howtos.say-hello.step_clickSay": "Click the block to run",
    "gui.howtos.say-hello.step_anotherSay": "Drag out another [say] block",
    "gui.howtos.say-hello.step_editSay": "Say something else",
    "gui.howtos.say-hello.step_clickStack": "Click the block to run",
    "gui.howtos.run-away.name": "Run Away",
    "gui.howtos.run-away.step_dragGoTo": "Drag out a [go to random position] block",
    "gui.howtos.run-away.step_clickGoTo": "Click the block to run",
    "gui.howtos.run-away.step3": "Click the [Events] category",
    "gui.howtos.run-away.step_addWhenClicked": "Add a “when this sprite clicked” block",
    "gui.howtos.run-away.step_clickSprite": "Click the sprite to run",
    "gui.howtos.run-away.step_addSound": "Add a “start sound” block",
    "gui.howtos.pick-up-apple.name": "Game of Catching Apples",
    "gui.howtos.pick-up-apple.step_1": "Switch to Scratch",
    "gui.howtos.pick-up-apple.step_2": "Switch hardware",
    "gui.howtos.pick-up-apple.step_3": "Connect device",
    "gui.howtos.pick-up-apple.step_4": "Compass calibration",
    "gui.howtos.pick-up-apple.step_5": "Open Scratch example programs",
    "gui.howtos.pick-up-apple.step_6": "Game of Catching Apples",
    "gui.howtos.move-left-right.name": "micro:bit and Meow",
    "gui.howtos.move-left-right.step_1": "Creat a new project",
    "gui.howtos.move-left-right.step_2": "Drag block1",
    "gui.howtos.move-left-right.step_3": "Drag block2",
    "gui.howtos.move-left-right.step_4": "Move left and right",
    "gui.howtos.install-driver.name": "Install Driver",
    "gui.howtos.transform-expression.name": "Smile or Cry",
    "gui.howtos.transform-expression.step_1": "Switch to Code",
    "gui.howtos.transform-expression.step_2": "Switch hardware",
    "gui.howtos.transform-expression.step_3": "Drag block1",
    "gui.howtos.transform-expression.step_4": "Drag block2",
    "gui.howtos.transform-expression.step_5": "Drag block3",
    "gui.howtos.transform-expression.step_6": "Transform to smile or cry",
    "gui.howtos.lot.name": "IOT (Internet of Things)",
    "gui.howtos.lot.step_1": "Select example programs",
    "gui.howtos.lot.step_2": "Burning program",
    "gui.howtos.lot.step_3": "Feature diagram",
    "gui.howtos.touch-pin.name": "Pin touch",
    "gui.howtos.touch-pin.step_1": "Switch to Code",
    "gui.howtos.touch-pin.step_2": "Select the routine",
    "gui.howtos.touch-pin.step_3": "Burning program",
    "gui.howtos.touch-pin.step_4": "Pin touch",
    "gui.howtos.save-witch.name": "Rescue Witch",
    "gui.howtos.save-witch.step_1": "Switch to Scratch",
    "gui.howtos.save-witch.step_2": "Select 'micro:bit'in expansion",
    "gui.howtos.save-witch.step_3": "Select 'Rescue Witch' in example programs",
    "gui.howtos.save-witch.step_4": "Connect the device, select micro:bit and wait for a successful connection",
    "gui.howtos.save-witch.step_5": "Rotate micro:bit all around until the LED turns off when the note [calibrate the device] pops up.",
    "gui.howtos.save-witch.step_6": "Click the green flag and swing your arm to interact with the animation",
    "gui.howtos.microbit-touch.name": "Press to Smile or Cry",
    "gui.howtos.microbit-touch.step_1": "Switch to Code mode",
    "gui.howtos.microbit-touch.step_2": "Click 'Learning' to open the example programs and select 'Pin touch'",
    "gui.howtos.microbit-touch.step_3": "Connect the corresponding COM port of the device and click 'Upload to Device'",
    "gui.howtos.microbit-touch.step_4": "Press different keys to show different expressions",
    "gui.howtos.microbit-calibration.name": "micro:bit kallibrieren",
    "gui.howtos.microbit-calibration.step_1": "Drehe die Platine langsam und die LED leuchten nacheinander auf.",
    "gui.howtos.microbit-calibration.step_2": "Drehe und neige bis alle LED leuchten (wie unten gezeigt). Nach Abschluss der Kalibrierung ein Smiley angezeigt.",
    "gui.extension.sen0251.name": "BMP388 Barometric Pressure Sensor",
    "gui.extension.sen0251.description": "Temperature, atmospheric pressure, altitude detection function",
    "gui.extension.sen0206.name": "Non-contact infrared temperature sensor",
    "gui.extension.sen0206.description": "Far-infrared non-contact measurement of object or ambient temperature",
    "gui.monitor.contextMenu.sliderRange": "change slider range",
    "gui.fileSystem.fileCatalogue": "Katalog",
    "gui.fileSystem.boardFiles": "Board Files",
    "gui.fileSystem.boardFiles2": "Board Files",
    "gui.fileSystem.boardFilesMaixduino": "Board Files",
    "gui.fileSystem.boardFilesBoson": "BOSON MC1",
    "gui.fileSystem.computerFiles": "Computer-Dateien",
    "gui.fileSystem.userExFiles": "Use-Ex Files",
    "gui.fileSystem.newFile": "Neue Datei",
    "gui.fileSystem.newFolder": "Neuer Ordner",
    "gui.fileSystem.deleteFolder": "Ordner löschen",
    "gui.fileSystem.deleteFile": "löschen",
    "gui.fileSystem.rename": "umbenennnen",
    "gui.fileSystem.openFolder": "open folder position",
    "gui.fileSystem.openFilePos": "open file position",
    "gui.fileSystem.openInEditor": "open in editor",
    "gui.fileSystem.runRightNow": "run right now",
    "gui.fileSystem.stopRun": "stop run",
    "gui.fileSystem.setBoot": "boot run",
    "gui.fileSystem.deleteForever": "delete forever",
    "gui.fileSystem.saveAs": "Speichern unter",
    "gui.fileSystem.undoAllEdits": "cancel all edits",
    "gui.fileSystem.copyToComputer": "copy to computer",
    "gui.fileSystem.uploadToBoard": "upload to board",
    "gui.fileSystem.uploadAndRun": "upload and run",
    "gui.extension.mpyUART.name": "Serial UART",
    "gui.extension.mpyUART.description": "UART communication",
    "gui.extension.mpyI2C.name": "I2C communication",
    "gui.extension.mpyI2C.description": "Use software to simulate I2C protocol to transmit data",
    "gui.extension.mpyServo.name": "Servo module",
    "gui.extension.mpyServo.description": "Servo module",
    "gui.extension.mpyTimer.name": "Timer",
    "gui.extension.mpyTimer.description": "Can execute the program regularly",
    "gui.extension.mpyList.name": "List",
    "gui.extension.mpyList.description": "List",
    "gui.extension.mpyDictionary.name": "Dictionary",
    "gui.extension.mpyDictionary.description": "Dictionary",
    "gui.extension.mpySet.name": "Set",
    "gui.extension.mpySet.description": "Set",
    "gui.extension.mpyTuple.name": "Tuple",
    "gui.extension.mpyTuple.description": "Tuple",
    "gui.connection.auto-scanning.noPeripheralsFound": "Kein Gerät gefunden",
    "gui.connection.auto-scanning.prescan": "Have your device nearby, then begin searching.",
    "gui.connection.auto-scanning.pressbutton": "Press the button on your device.",
    "gui.connection.auto-scanning.start-search": "Start Searching",
    "gui.connection.connecting-searchbutton": "Searching...",
    "gui.connection.auto-scanning.try-again": "Try again",
    "gui.connection.connected": "verbunden",
    "gui.connection.disconnect": "getrennt",
    "gui.connection.go-to-editor": "Go to Editor",
    "gui.connection.connecting-cancelbutton": "Connecting...",
    "gui.connection.error.errorMessage": "Oops, looks like something went wrong.",
    "gui.connection.error.tryagainbutton": "Try again",
    "gui.connection.error.helpbutton": "Hilfe",
    "gui.connection.peripheral-name-label": "Device name",
    "gui.connection.connect": "Connect",
    "gui.connection.scanning.lookingforperipherals": "Looking for devices",
    "gui.connection.scanning.noPeripheralsFound": "Kein Gerät gefunden",
    "gui.connection.scanning.instructions": "Select your device in the list above.",
    "gui.connection.scanning.instructionsForTello": "Please connect your computer WiFi to Tello or RMTT first, and then select the devices listed above. ",
    "gui.connection.connect.instructionsForTello": "Please connect Tello or RMTT in the computer WiFi list first, and then select the devices listed above.",
    "gui.connection.scanning.connectTelloHandle": "Select the device listed above, or manually connect to the aircraft Wi-Fi.",
    "gui.connection.search": "Refresh",
    "gui.connection.unavailable.installscratchlink": "Make sure you have Scratch Link installed and running",
    "gui.connection.unavailable.enablebluetooth": "Check that Bluetooth is enabled",
    "gui.connection.unavailable.tryagainbutton": "Try again",
    "gui.connection.unavailable.helpbutton": "Hilfe",
    "gui.extension.ev3.description": "Baue interaktive Roboter und mehr.",
    "gui.extension.ev3.connectingMessage": "Connecting. Make sure the pin on your EV3 is set to 1234.",
    "gui.extension.boost.description": "Erwecke Roboterkreationen zum Leben.",
    "gui.extension.boost.connectingMessage": "Connecting",
    "gui.extension.wedo2.description": "Baue mit Sensoren und Motoren.",
    "gui.extension.wedo2.connectingMessage": "Connecting",
    "gui.extension.tello.name": "RoboMaster TT(single)",
    "gui.extension.tello.description": "Educational drone with DJI top flight control algorithms, safe & stable",
    "gui.extension.tello.connectingMessage": "Connecting... Make sure tello in turned on.",
    "gui.extension.telloGroup.name": "RoboMaster TT(team)",
    "gui.extension.telloGroup.description": "Educational drone with DJI top flight control algorithms, safe & stable",
    "gui.connection.auto-scanning.notFoundTelloTip": "Scanning timed out, please make sure Tello is turned on",
    "gui.extension.pyTurtle.name": "Turtle Drawing",
    "gui.extension.pyTurtle.description": "There are thousands of brain holes, between the sizes",
    "gui.extension.pyGenerator.name": "Code Generator",
    "gui.extension.pyGenerator.description": "Write code directly on image blocks to achieve graphical and code mixing",
    "gui.extension.python.save": "Speichern",
    "gui.extension.python.execute": "Starten",
    "gui.extension.python.pipmanger": "Bibliotheksverwaltung",
    "gui.extension.python.filesystem": "Dateisystem",
    "gui.extension.python.fileinprogram": "Dateien im Projekt",
    "gui.extension.python.fileinpc": "Dateien auf dem Computer",
    "gui.extension.python.terminal": "Terminal",
    "gui.extension.python.clearterminal": "Ausgabe löschen",
    "gui.extension.python.tabcode": "Code",
    "gui.extension.python.piplist": "Bibliotheksliste",
    "gui.extension.python.commendatory": "Empfohlende Bibliotheken",
    "gui.extension.python.handleinstall": "PIP-Modus",
    "gui.extension.python.selectall": "Wähle alle [COUNT]",
    "gui.extension.python.update": "Aktualisieren",
    "gui.extension.python.uninstall": "Deinstallieren",
    "gui.extension.python.install": "Installieren",
    "gui.extension.python.installing": "Installieren...",
    "gui.extension.python.installed": "Installiert",
    "gui.extension.python.piphandleinstall": "Manuelle PIP-Installation",
    "gui.extension.python.finishedtips": "Die Ausführung des Befehls wurde beendet",
    "gui.extension.python.loadmore": "Mehr laden...",
    "gui.extension.python.hidemore": "Mehr ausblenden...",
    "gui.extension.python.noavailable": "Es ist keine Bibliothek verfügbar. Bitte lade diese zuerst herunter",
    "gui.extension.python.enterCommand": "Bitte gib zuerst den Bibliotheksnamen ein",
    "gui.extension.python.stop": "Stoppen",
    "gui.extension.python.executing": "Läuft",
    "gui.extension.python.editorArea": "Codebereich",
    "gui.extension.python.toolbox": "Grafikbereich",
    "gui.extension.python.autoCreate": "Automatisch generieren",
    "gui.libraryTags.festival": "Festival",
    "gui.libraryTags.traffic": "Verkehr",
    "gui.libraryTags.mind": "Mind+",
    "gui.libraryTags.natural": "Natur",
    "gui.libraryTags.button": "Taste",
    "gui.libraryTags.dialog": "Dialog",
    "gui.libraryTags.epidemicPrevention": "Pandemieprävention",
    "gui.libraryTags.numberAndLetter": "Zahlen und Buchstaben",
    "gui.libraryTags.gameComponents": "Spielekomponenten",
    "gui.webglModal.restartMindplus": "Mind+ neu starten",
    "gui.webglModal.clickToRestart": "Klicke hier zum Neustart",
    "gui.howtos.calliope-calibration.name": "Calliope kallibireren",
    "gui.extension.python.emptyEditor": "Klicke rechts auf die Schaltfläche <b>Neue Datei</b> hinter <b>Dateien im Projekt</b>, um eine .py-Datei zu erstellen.",
    "gui.extension.python.userDocument": "Hilfedokument",
    "gui.account.dialog.prompt": "Prompt",
    "gui.account.dialog.tips": "Prompt",
    "gui.account.dialog.signoutAlert": "Nach der Abmeldung kannst du den Cloud-Speicher und andere Funktionen nicht mehr nutzen. Möchtest du dich wirklich abmelden?",
    "gui.account.dialog.signoutSuccess": "Abgemeldet",
    "gui.account.dialog.sure": "OK",
    "gui.account.dialog.cancel": "Abbruch",
    "gui.account.dialog.error": "Fehler",
    "gui.account.dialog.save": "Speichern",
    "gui.account.dialog.getCloudFileFailed": "Beim Abrufen der Cloud-Datei [NAME] ist ein Fehler aufgetreten. Bitte überprüfe, ob die Datei vorhanden ist",
    "gui.account.dialog.getCloudError": "Fehler beim Abrufen der Cloud-Datei [NAME]",
    "gui.account.dialog.loadCloudSuccess": "Das Laden der Cloud-Datei [NAME] war erfolgreich",
    "gui.account.dialog.saveCloudError": "[NAME] konnte nicht in der Cloud gespeichert werden. Bitte versuche es später.",
    "gui.account.dialog.saveCloudSuccess": "[NAME] erfolgreich in der Cloud speichern",
    "gui.account.dialog.saveCloudCheckError": "Speichern fehlgeschlagen, Cloud-Datei-Überprüfungsfehler, bitte versuchen es später noch einmal",
    "gui.account.dialog.saveCloudRepeatPrompt": "Eine Datei [NAME] mit demselben Namen ist bereits in der Cloud vorhanden. Willst du es überschreiben?",
    "gui.account.dialog.saveCloudRepeatAlert": "Eine Datei mit demselben Namen „[NAME]“ ist bereits in der Cloud vorhanden. Bitte ändere den Namen",
    "gui.account.dialog.saveCover": "Überschreiben",
    "gui.account.dialog.rename": "Umbenennen",
    "gui.account.dialog.newProject": "Neue Datei",
    "gui.account.dialog.projectName": "Projektname",
    "gui.account.dialog.enterName": "Bitte gib den Dateinamen ein",
    "gui.account.dialog.nameWithSymbol": "Der Dateiname darf keine Sonderzeichen enthalten. Bitte ändere ihn",
    "gui.account.dialog.nameBeyondLength": "Die Länge des Dateinamens darf 30 nicht überschreiten",
    "gui.account.dialog.renameSuccess": "Datei [NAME1] erfolgreich in [NAME2] umbenannt",
    "gui.account.dialog.renameFailed": "Datei konnte nicht umbenannt werden",
    "gui.account.dialog.downloadSuccess": "[NAME]-Download abgeschlossen",
    "gui.account.dialog.cancelDownload": "Download abbrechen",
    "gui.account.dialog.deleteSucess": "Das Löschen der Cloud-Datei [NAME] war erfolgreich",
    "gui.account.dialog.saveAs": "Speichern als",
    "gui.account.dialog.deleteFailed": "Fehler beim Löschen der Cloud-Datei [NAME]",
    "gui.account.dialog.deletePrompt": "Bistdu sicher, dass du die Cloud-Datei [NAME] löschen möchtest?",
    "gui.account.open": "Offen",
    "gui.account.upload": "Hochladen",
    "gui.account.delete": "Löschen",
    "gui.account.usersSpace": "Das Cloud-Projekt von [NAME]",
    "gui.account.cloudDataLoading": "Cloud-Daten werden geladen, bitte warten",
    "gui.account.emptyUserSpace": "Dies ist leer. Lade dein erstes Projekt hoch.",
    "gui.account.dialog.login": "Anmeldung",
    "gui.account.dialog.unsupportProject": "Fehler beim Öffnen des Projekts, nicht unterstütztes Projekt",
    "gui.account.dialog.shouldLoginFirst": "Der Cloud-Dienst benötigt deine Kontoinformationen. Bitte melde dich sich zuerst an",
    "gui.account.dialog.loginTimeout": "Zeitüberschreitung beim Anmelden",
    "gui.account.account": "Konto",
    "gui.account.serverAddr": "Serveradresse",
    "gui.account.thirdPartyAccount": "Dritte Seite",
    "gui.account.dialog.saveCloudRenamePrompt": "Der Dateiname [NAME] existiert bereits. Möchtest du ihn umbenennen?",
    "gui.account.dialog.signup": "Melden Sie sich an",
    "gui.account.dialog.phoneNumber": "Handynummer",
    "gui.account.dialog.password": "Passwort",
    "gui.account.dialog.autoLogin": "Merke dir das Passwort",
    "gui.account.dialog.forgotPasswd": "Passwort vergessen?",
    "gui.account.dialog.getCheckCode": "Hole dir den Bestätigungscode",
    "gui.account.dialog.reSend": "Erneut senden",
    "gui.account.dialog.checkCode": "Bestätigungscode",
    "gui.account.dialog.readAndAgree": "Ich habe es gelesen und akzeptiert",
    "gui.account.dialog.userAgreement": "Nutzungsbedingungen",
    "gui.account.dialog.loginSuccess": "Anmeldung erfolgreich",
    "gui.account.dialog.passwordError": "Das Konto oder Passwort ist falsch. Bitte überprüfe, ob es korrekt ist",
    "gui.account.dialog.checkNumberSended": "Der Bestätigungscode wurde gesendet. Bitte überprüfe ihn sorgfältig",
    "gui.account.dialog.checkedAgree": "Der Vereinbarung zustimmen",
    "gui.account.dialog.networkError": "Netzwerkfehler, bitte überprüfe die Verbindung und versuche es erneut",
    "gui.account.dialog.readProtocolAndCheck": "Bitte lesen Sie die Nutzungsvereinbarung und kreuze „Zustimmen“ an",
    "gui.account.dialog.signupError": "Anmeldefehler,",
    "gui.account.dialog.logininErrorWithNetwork": "Serververbindungsfehler, Anmeldung fehlgeschlagen, bitte überprüfe das Netzwerk",
    "gui.account.dialog.signupErrorDefault": "Die Registrierung ist fehlgeschlagen. Bitte versuche es erneut",
    "gui.account.dialog.accountExist": "Das Konto existiert bereits, bitte logge dich direkt ein",
    "gui.account.dialog.phoneCodeError": "Registrierung fehlgeschlagen, Bestätigungscodefehler",
    "gui.account.dialog.signupSuccess": "Die Registrierung ist erfolgreich. Bitte kehre zur Anmeldeoberfläche zurück, um den Vorgang fortzusetzen",
    "gui.account.dialog.inputEmpty": "Kann nicht leer sein",
    "gui.account.dialog.phoneFormatIllegal": "Fehler im Format des Benutzernamens",
    "gui.python.remoteSSH.connectSuccess": "Erfolgreich verbunden mit [ADRESSE]",
    "gui.python.remoteSSH.connectFailed": "Verbindung [ADRESSE] fehlgeschlagen",
    "gui.python.remoteSSH.shouleReconnect": "Verbindung zu [ADRESSE] getrennt. Möchtest du die Verbindung wiederherstellen?",
    "gui.python.remoteSSH.reconnect": "Wieder verbinden",
    "gui.python.remoteSSH.disconnected": "Verbindung zu [ADRESSE] getrennt",
    "gui.python.remoteSSH.accountCheckFailed": "Die Kontoinformationen sind falsch, die Verbindung mit [ADRESSE] ist fehlgeschlagen. Bitte überprüfen und neu eingeben",
    "gui.python.remoteSSH.connectError": "Fehler beim Herstellen der Verbindung mit [ADRESSE], [MSG], Hilfedokument zur Lösungsansicht",
    "gui.python.remoteSSH.sshLogin": "SSH-Anmeldung",
    "gui.python.remoteSSH.address": "Adresse",
    "gui.python.remoteSSH.addressCantEmpty": "Die Adresse darf nicht leer sein",
    "gui.python.remoteSSH.user": "Nutzername",
    "gui.python.remoteSSH.userCantEmpty": "Benutzername darf nicht leer sein",
    "gui.python.remoteSSH.passwd": "Passwort",
    "gui.python.remoteSSH.passwdCantEmpty": "Das Passwort darf nicht leer sein",
    "gui.python.remoteSSH.areYouSureDisconnect": "Derzeit mit der Fernbedienung [ADRESSE] verbunden. Möchtest du die Verbindung wirklich trennen?",
    "gui.python.remoteSSH.statusConnecting": "Verbinden",
    "gui.python.remoteSSH.disconnectDevice": "Remote-Terminal trennen",
    "gui.python.remoteSSH.connectDevice": "Remote-Terminal verbinden",
    "gui.python.remoteSSH.statusError": "Verbindungsfehler",
    "gui.python.remoteSSH.statusDisconnect": "Die Verbindung wurde getrennt",
    "gui.python.remoteSSH.handleInput": "Manuelle Eingabe",
    "gui.python.remoteSSH.openNetNetWork": "Netzwerkcenter öffnen",
    "gui.python.remoteSSH.goHelp": "Hilfedokument",
    "gui.python.remoteSSH.waitForConnecting": "Verbindung zu [ADRESSE] wird hergestellt, bitte warten...",
    "gui.python.remoteSSH.cancelConnect": "Verbindung abbrechen",
    "gui.python.remoteSSH.connectTimeout": "Zeitüberschreitung der Verbindung",
    "gui.python.remoteSSH.addressIsLost": "Adresse existiert nicht",
    "gui.python.remoteSSH.connectRefused": "Die Verbindungsanfrage wurde abgelehnt. Bitte überprüfe die Einstellungen des Remote-Systems und versuche es erneut",
    "gui.python.remoteSSH.runGUIProgramTips": "Wenn beim Ausführen eines grafischen Programms die Auswirkungen auf den Remote-Desktop anzeigen werden sollen, dann ist der Benutzernamen zu verwenden, der aktuell auf dem Remote-System angemeldet ist",
    "gui.python.remoteSSH.executeFileError": "Fehler beim Ausführen des Projekts",
    "gui.python.remoteSSH.waitForProjectUpload": "Projekt [NAME] wird hochgeladen, bitte warten...",
    "gui.python.remoteSSH.projectUploadSuccess": "Das Projekt wurde erfolgreich hochgeladen und gestartet",
    "gui.python.remoteSSH.projectUploadError": "Fehler beim Hochladen des Projekts",
    "gui.python.filetree.boardNameInFT": "Datei in [BOARD]",
    "gui.python.filetree.replaceFilePrompt": "Im Zielordner ist bereits eine Datei oder ein Ordner mit dem Namen [NAME] vorhanden. Möchtest Du es ersetzen?",
    "gui.python.filetree.fileUploading": "[NAME]-Datei hochladen...",
    "gui.python.filetree.fileSaving": "Datei [NAME] speichern...",
    "gui.python.filetree.dirSaving": "Ordner [NAME] speichern...",
    "gui.python.filetree.saveAs": "Speichern als",
    "gui.python.filetree.chooseDir": "Ordner auswählen",
    "gui.python.filetree.getDirList": "Verzeichnis abrufen...",
    "gui.python.filetree.getDirListWithPath": "Verzeichnis [PATH] lesen",
    "gui.python.filetree.getRemoteDirList": "Remote-Verzeichnis [PATH] lesen",
    "gui.python.filetree.renameFile": "[FROM] in [TO] umbenennen",
    "gui.python.filetree.deleteDirPrompt": "Bist du sicher, dass der Ordner [NAME] und die darin enthaltenen Dateien gelöscht werden sollen?",
    "gui.python.filetree.deleteFilePrompt": "Bist du sicher, dass Sie die Datei [NAME] gelöscht werden soll?",
    "gui.python.filetree.downloadToPC": "Lade die Datei auf den Computer herunter",
    "gui.python.filetree.uploadToRemote": "Datei hochladen auf",
    "gui.python.filetree.exportFileTo": "[EXT]-Datei exportieren",
    "gui.python.filetree.rmFromeFileSystem": "Aus dem Dateisystem entfernen",
    "gui.python.filetree.rmDirPrompt": "Bist du sicher, dass du den lokalen Pfad [NAME] und seine Unterdateien entfernen möchtest?",
    "gui.python.filetree.rmAllDirPrompt": "Bist Du sicher, alle lokalen Pfade zu entfernen?",
    "gui.python.filetree.addLocalDir": "Füge einen lokalen Ordner hinzu",
    "gui.python.filetree.removeActionDirWithAll": "Entferne alle lokalen Ordner",
    "gui.python.filetree.fileNameIsRequired": "Der Datei- oder Ordnername muss angegeben werden",
    "gui.python.filetree.fileNameIsRepeat": "Die Datei oder der Ordner [NAME] existiert bereits an diesem Speicherort. Bitte wähle einen anderen Namen.",
    "gui.python.filetree.dragParentNodeToChild": "Der übergeordnete Ordner kann nicht in einen Unterordner verschoben werden",
    "gui.python.filetree.dragPathIsConsistent": "Der Pfad-Hierarchie ist konsistent",
    "gui.python.filetree.fileOperateError": "Fehler beim Dateivorgang",
    "gui.python.filetree.fileTreeMore": "Mehr",
    "gui.python.filetree.copy": "Kopieren",
    "gui.python.filetree.paste": "Einfügen",
    "gui.python.worker.initPythonHomeDir": "Ordner wird initialisiert...",
    "gui.python.worker.copyPythonSource": "Die Initialisierung des Ordners ist abgeschlossen, das Kopieren der Python-Ressourcendateien ...",
    "gui.python.worker.copyPythonSourceError": "Dateioperationsfehler, Python-Umgebung konnte nicht initialisiert werden. Bitte wechsel erneut in den Python-Modus oder starte die Software neu",
    "gui.python.worker.unzipPythonSource": "Das Kopieren der Ressourcendateien ist abgeschlossen, das Entpacken ...",
    "gui.python.worker.checkPythonSource": "Das Extrahieren ist abgeschlossen, die Datei wird überprüft ...",
    "gui.python.worker.unzipPythonSourceError": "Fehler beim Entpacken, Python-Umgebung konnte nicht initialisiert werden. Bitte wechsel erneut in den Python-Modus oder starte die Software neu",
    "gui.python.xterm.local": "Lokal",
    "gui.python.xterm.detectPythonEnv": "Die Umgebung wird erkannt, bitte warten...",
    "gui.python.xterm.installingPythonEnv": "Installation der Umgebung, bitte warten…",
    "gui.python.xterm.installPythonEnvError": "Die Initialisierung der Python-Umgebung ist fehlgeschlagen. Bitte wende sich an den technischen Support",
    "gui.python.xterm.detectPythonEnvFinished": "Umgebungserkennung abgeschlossen, beginnt...",
    "gui.python.xterm.installPythonEnvFinished": "Die Umgebungsinstallation ist abgeschlossen und beginnt...",
    "gui.python.xterm.toRestartXterm": "Doppelklicke, um das Terminal neu zu starten",
    "gui.python.xterm.showRemoteConnect": "Öffnen Sie das Remote-Verbindungsterminal",
    "gui.python.xterm.remoteConnectShowed": "Das Remote-Verbindungsterminal ist geöffnet",
    "gui.dialog.thirdExt.openPipManger": "Öffne die Bibliotheksverwaltung",
    "gui.python.editor.undo": "Rückgängig",
    "gui.python.editor.redo": "Wiederholen",
    "gui.python.editor.search": "Suchen",
    "gui.python.editor.boldFont": "Fettgedruckt",
    "gui.python.editor.zoomInt": "Vergrößern",
    "gui.python.editor.zoomOut": "Verkleinern",
    "gui.python.editor.toTop": "Nach oben",
    "music.categoryName": "Musik",
    "translate.categoryName": "Übersetzung",
    "pen.categoryName": "Malstift",
    "pen.changeColorParam": "ändere Stift [COLOR_PARAM] um [VALUE]",
    "pen.changeHue": "ändere Stiftfarbe um [HUE]",
    "pen.changeShade": "ändere Farbstärke um [SHADE]",
    "pen.changeSize": "ändere Stiftdicke um [SIZE]",
    "pen.clear": "lösche alles",
    "pen.colorMenu.brightness": "Helligkeit",
    "pen.colorMenu.color": "Farbe",
    "pen.colorMenu.saturation": "Sättigung",
    "pen.colorMenu.transparency": "Transparenz",
    "pen.penDown": "schalte Stift ein",
    "pen.penUp": "schalte Stift aus",
    "pen.setColor": "setze Stiftfarbe auf [COLOR]",
    "pen.setColorParam": "setze Stift [COLOR_PARAM] auf [VALUE]",
    "pen.setHue": "setze Stiftfarbe auf [HUE]",
    "pen.setShade": "setze Farbstärke auf [SHADE]",
    "pen.setSize": "setze Stiftdicke auf [SIZE]",
    "pen.stamp": "hinterlasse Abdruck",
    "music.changeTempo": "ändere Tempo um [TEMPO]",
    "music.drumBass": "(2) Basstrommel",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Klangstäbe",
    "music.drumClosedHiHat": "(6) Geschlossene Hi-Hat",
    "music.drumConga": "(14) Konga",
    "music.drumCowbell": "(11) Kuhglocke",
    "music.drumCrashCymbal": "(4) Crash-Becken",
    "music.drumCuica": "(18) Cuíca",
    "music.drumGuiro": "(16) Güiro",
    "music.drumHandClap": "(8) Klatschen",
    "music.drumOpenHiHat": "(5) Offene Hi-Hat",
    "music.drumSideStick": "(3) Side-Stick",
    "music.drumSnare": "(1) Snare-Drum",
    "music.drumTambourine": "(7) Tamburin",
    "music.drumTriangle": "(12) Triangel",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Holzblock",
    "music.getTempo": "Tempo",
    "music.instrumentBass": "(6) Bass-Gitarre",
    "music.instrumentBassoon": "(14) Fagott",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Chor",
    "music.instrumentClarinet": "(10) Klarinette",
    "music.instrumentElectricGuitar": "(5) E-Gitarre",
    "music.instrumentElectricPiano": "(2) E-Piano",
    "music.instrumentFlute": "(12) Querflöte",
    "music.instrumentGuitar": "(4) Gitarre",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Musikbox",
    "music.instrumentOrgan": "(3) Orgel",
    "music.instrumentPiano": "(1) Klavier",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxophon",
    "music.instrumentSteelDrum": "(18) Steel-Drum",
    "music.instrumentSynthLead": "(20) Lead-Synthesizer",
    "music.instrumentSynthPad": "(21) Pad-Synthesizer",
    "music.instrumentTrombone": "(9) Posaune",
    "music.instrumentVibraphone": "(16) Vibraphon",
    "music.instrumentWoodenFlute": "(13) Blockflöte",
    "music.midiPlayDrumForBeats": "spiele Schlaginstrument [DRUM] für [BEATS] Schläge",
    "music.midiSetInstrument": "setze Instrument auf [INSTRUMENT]",
    "music.playDrumForBeats": "spiele Schlaginstrument [DRUM] für [BEATS] Schläge",
    "music.playNoteForBeats": "spiele Ton [NOTE] für [BEATS] Schläge",
    "music.restForBeats": "pausiere [BEATS] Schläge",
    "music.setInstrument": "setze Instrument auf [INSTRUMENT]",
    "music.setTempo": "setze Tempo auf [TEMPO]",
    "videoSensing.categoryName": "Video-Erfassung",
    "videoSensing.direction": "Richtung",
    "videoSensing.motion": "Bewegung",
    "videoSensing.off": "aus",
    "videoSensing.on": "an",
    "videoSensing.onFlipped": "auf gespiegelt",
    "videoSensing.setVideoTransparency": "setze Video-Transparenz auf [TRANSPARENCY]",
    "videoSensing.sprite": "Figur",
    "videoSensing.stage": "Bühne",
    "videoSensing.videoOn": "Video-[ATTRIBUTE] von [SUBJECT]",
    "videoSensing.videoToggle": "schalte Video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "Wenn Video-Bewegung > [REFERENCE]",
    "translate.translateBlock": "übersetze [WORDS] nach [LANGUAGE]",
    "translate.defaultTextToTranslate": "Hallo",
    "translate.viewerLanguage": "Sprache",
    "text2speech.speakAndWaitBlock": "sage [WORDS]",
    "text2speech.setVoiceBlock": "ändere die Stimme zu [VOICE]",
    "text2speech.setLanguageBlock": "setze Sprache auf [LANGUAGE]",
    "text2speech.alto": "Alt",
    "text2speech.tenor": "Tenor",
    "text2speech.squeak": "Quietschen",
    "text2speech.giant": "Riese",
    "text2speech.kitten": "Kätzchen",
    "text2speech.defaultTextToSpeak": "Hallo",
    "speech.whenIHear": "Wenn ich [PHRASE]höre",
    "speech.listenAndWait": "hör zu und warte",
    "speech.defaultWhenIHearValue": "los geht's",
    "sensor.categoryName": "Sensor",
    "actuator.categoryName": "Aktor",
    "communicate.categoryName": "Kommunizieren",
    "display.categoryName": "Anzeigen",
    "extendFunction.categoryName": "Funktion",
    "internetService.categoryName": "Internet",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "handpy",
    "telloesp32.categoryName": "RMTT",
    "spread.categoryName": "Erweiterungsplatine",
    "speechRecognition.categoryName": "Spracherkenner",
    "thirdExpansion.categoryName": "Benutzer-Erweiterung",
    "gui.blocklyText.blockTitle.eventBlock": "Ereignisprogramm",
    "gui.blocklyText.blockTitle.dotScreen": "Punktbildschirm",
    "gui.blocklyText.blockTitle.music": "Musik",
    "gui.blocklyText.blockTitle.onBoardSensor": "Sensor der Karte",
    "gui.blocklyText.blockTitle.pinOperation": "Pin-Bedienung",
    "gui.blocklyText.blockTitle.wirelessCommunication": "drahtlose Kommunikation",
    "gui.blocklyText.blockTitle.serialportOperation": "Serialport-Betrieb",
    "gui.blocklyText.blockTitle.systemResource": "System-Ressource",
    "gui.blocklyText.blockTitle.screenDisplay": "Bildschirmdarstellung",
    "gui.blocklyText.blockTitle.ledControl": "LED-Steuerung",
    "gui.blocklyText.blockTitle.functionalModule": "Funktionsmodul",
    "gui.blocklyText.blockTitle.movement": "Bewegung",
    "gui.blocklyText.blockTitle.expression": "Ausdruck",
    "gui.blocklyText.blockTitle.light": "Licht",
    "gui.blocklyText.blockTitle.dance": "Tanz",
    "gui.blocklyText.blockTitle.sensor": "Sensor",
    "gui.blocklyText.blockTitle.motorControl": "Motorsteuerung",
    "gui.blocklyText.blockTitle.kitSensor": "Bausatz Sensor",
    "gui.blocklyText.blockTitle.kitActuator": "Bausatz Aktor",
    "gui.blocklyText.blockTitle.commonModule": "gemeinsames Modul",
    "gui.blocklyText.blockTitle.infraredCommunication": "Infrarot-Kommunikation",
    "gui.blocklyText.blockTitle.mp3Module": "MP3-Modul",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 RGB-Licht",
    "gui.blocklyText.blockTitle.LCDDisplay": "LCD-Display",
    "gui.blocklyText.blockTitle.digitalTube": "digitale Röhre",
    "gui.blocklyText.blockTitle.oledDisplay": "OLED-Anzeige",
    "gui.blocklyText.blockTitle.onBoardSource": "Platinen-Ressource",
    "gui.blocklyText.blockTitle.rgbControl": "RGB-Lichtsteuerung",
    "gui.blocklyText.blockTitle.maxbotResource": "max:bot Ressource",
    "gui.blocklyText.blockTitle.lineInspection": "Linienkontrolle",
    "gui.blocklyText.blockTitle.outerSensor": "Außensensor",
    "gui.blocklyText.blockTitle.consolePrint": "Konsolenausgang",
    "gui.blocklyText.blockTitle.radio": "Funk",
    "gui.blocklyText.blockTitle.wifi": "WiFi",
    "gui.blocklyText.romeo.d1ProgramStart": "D1 robot program starts",
    "gui.blocklyText.romeo.readdigital": "read value pin[PIN] [MODULE] ",
    "gui.blocklyText.romeo.buttonModule": "Button Module",
    "gui.blocklyText.romeo.digitalSensor": "Digital Sensor",
    "gui.blocklyText.romeo.collisionSensor": "Crash Sensor",
    "gui.blocklyText.romeo.lineSensor": "Line Sensor",
    "gui.blocklyText.romeo.infraredSwitch": "Infrared Switch",
    "gui.blocklyText.romeo.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.romeo.readAnalog": "read pin [PIN] [SENSOR] ",
    "gui.blocklyText.romeo.lightSensor": "Light Sensor",
    "gui.blocklyText.romeo.anologSensor": "Anolog Sensor",
    "gui.blocklyText.romeo.soundSensor": "Sound Sensor",
    "gui.blocklyText.romeo.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.romeo.frameSensor": "Frame Sensor",
    "gui.blocklyText.romeo.open": "Turn on",
    "gui.blocklyText.romeo.close": "Turn off",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] of pin [PIN] ",
    "gui.blocklyText.romeo.ledLights": "LED Lights",
    "gui.blocklyText.romeo.digitalActuator": "Digital Actuator",
    "gui.blocklyText.romeo.relay": "Relay",
    "gui.blocklyText.romeo.fan": "Fan",
    "gui.blocklyText.romeo.writeAnalog": "set pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.romeo.fanModule": "Fan Module",
    "gui.blocklyText.romeo.robotSpeedMove": "robot [DIR] at speed[SPEED]",
    "gui.blocklyText.romeo.forward": "move forward",
    "gui.blocklyText.romeo.backward": "move backward",
    "gui.blocklyText.romeo.turnLeft": "turn left",
    "gui.blocklyText.romeo.turnRight": "turn right",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "motor [MOTOR] [DIR] at speed[SPEED]",
    "gui.blocklyText.romeo.interface": "Interface",
    "gui.blocklyText.romeo.motorForward": "Forward",
    "gui.blocklyText.romeo.motorReverse": "Backward",
    "gui.blocklyText.romeo.stopMotor": "robot (M1 and M2 motors) stop moving",
    "gui.blocklyText.romeo.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "read pin [PIN] LM35 temperature(°C)",
    "gui.blocklyText.romeo.ds18b20": "read pin [PIN] DS18B20 temperature(°C)",
    "gui.blocklyText.romeo.pressInfrared": "infrared pin [PIN] received button [BUTTON] pressed signal？",
    "gui.blocklyText.romeo.volumePlus": "Volume+",
    "gui.blocklyText.romeo.switch": "Switch",
    "gui.blocklyText.romeo.volumeMinus": "Volume-",
    "gui.blocklyText.romeo.startPause": "Start/Pause",
    "gui.blocklyText.romeo.up": "Up",
    "gui.blocklyText.romeo.down": "Down",
    "gui.blocklyText.romeo.dataReadable": "Is there data to read on serial port?",
    "gui.blocklyText.romeo.readSerialData": "read serial data",
    "gui.blocklyText.romeo.timer": "Laufzeit(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.romeo.half": "Half",
    "gui.blocklyText.romeo.quarter": "Quarter",
    "gui.blocklyText.romeo.oneInEighth": "One-eighth",
    "gui.blocklyText.romeo.wholeBeat": "Whole Beat",
    "gui.blocklyText.romeo.doubleBeat": "Double Beat",
    "gui.blocklyText.romeo.stop": "Stop",
    "gui.blocklyText.romeo.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.romeo.segment": "eight-segment LED (D6-D13 pin) display [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "serial output [STRING] [WAY], [LINE]",
    "gui.blocklyText.romeo.originalOutput": "origin",
    "gui.blocklyText.romeo.stringOutput": "string",
    "gui.blocklyText.romeo.hexOutput": "HEX",
    "gui.blocklyText.romeo.wrap": "Wrap",
    "gui.blocklyText.romeo.noWrap": "No-Wrap",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "read serial data [READTYPE]",
    "gui.blocklyText.arduinounoR3.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.romeo.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.max.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.maxbot.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.microbit.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.arduino.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.arduinoNano.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.mega2560.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.firebeetleesp32.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.romeo.int": "int",
    "gui.blocklyText.romeo.float": "float",
    "gui.blocklyText.romeo.setBaudRate": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "read pin [PIN] pulse width under [LEVEL] mode, timeout [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "LOW",
    "gui.blocklyText.romeo.high": "HIGH",
    "gui.blocklyText.romeo.noSound": "no sound",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino starts",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno starts",
    "gui.blocklyText.esp32.displayInLine": "display [TEXT] in the [LINE] line",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.normal": "normal",
    "gui.blocklyText.esp32.rev": "rev",
    "gui.blocklyText.esp32.trans": "trans",
    "gui.blocklyText.esp32.XOR": "XOR",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "clear cache in the [LINE] line",
    "gui.blocklyText.esp32.displayClearInLine": "clear in the [LINE] line",
    "gui.blocklyText.esp32.buzzPinC": "P6  (Onboard buzzer)",
    "gui.blocklyText.esp32.buzzerRedirect": "Buzzer redirected to pin [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "All",
    "gui.blocklyText.esp32.setTouchThreshold": "Set button [PIN] touch value is [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "Read button [PIN] touch value",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 starts",
    "gui.blocklyText.esp32.btnIsPressed": "[BUTTON] button [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "when button [REFERENCE] [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "when pin [REFERENCE] is [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "When [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "pin [PIN] is [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "stop background playback",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "play note [NOTE] in background",
    "gui.blocklyText.esp32.setBuzzerFreq": "play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.esp32.getSoundIntensity": "read microphone sound intensity",
    "gui.blocklyText.esp32.readBrightness": "read ambient light brightness",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] show [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.esp32.acceleration": "read acceleration(m-g) [TYPE]",
    "gui.blocklyText.esp32.esp32_tiltAngle": "read tilt angle [TYPE]",
    "gui.blocklyText.esp32.esp32_boardShakeThrown": "the board was [ATTITUDE]?",
    "gui.blocklyText.esp32.esp32_boardTiltState": "the board tilt [GESTUREMPY]",
    "gui.blocklyText.esp32.forward": "forward",
    "gui.blocklyText.esp32.back": "back",
    "gui.blocklyText.esp32.left": "left",
    "gui.blocklyText.esp32.right": "right",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationRange": "set acceleration range [ACCRANGE]",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationResolution": "set acceleration resolution [ACCRESOLUTION]",
    "gui.blocklyText.esp32.esp32_mpyAccurateAccValue": "accurate acceleration value X-axis deviation [XAXIS] Y-axis deviation [YAXIS] Z-axis deviation [ZAXIS]",
    "gui.blocklyText.esp32.esp32_mpyAccurateCompass": "accurate compass",
    "gui.blocklyText.esp32.esp32_mpyCompassDirection": "compass direction",
    "gui.blocklyText.esp32.esp32_mpyAxisMagnetic": "[TYPE] axis magnetic",
    "gui.blocklyText.esp32.esp32_mpyMagneticStrength": "magnetic field strength",
    "gui.blocklyText.esp32.esp32_mpyRemoveMagnetic": "remove the current ambient magnetic field",
    "gui.blocklyText.esp32.esp32_mpyReturnRunTimeDiff": "return run time difference, start time [STARTTIME] end time [ENDTIME]",
    "gui.blocklyText.esp32.esp32_mpyRunTimer": "get run time [TIMETYPE]",
    "gui.blocklyText.esp32.millisecond": "millisecond",
    "gui.blocklyText.esp32.microsencond": "microsencond",
    "gui.blocklyText.esp32.esp32_mpyLocalTime": "get local time [LOCALTIME]",
    "gui.blocklyText.esp32.year": "year",
    "gui.blocklyText.esp32.month": "month",
    "gui.blocklyText.esp32.day": "day",
    "gui.blocklyText.esp32.hour": "hour",
    "gui.blocklyText.esp32.minute": "minute",
    "gui.blocklyText.esp32.sencond": "sencond",
    "gui.blocklyText.esp32.weeknumber": "week number",
    "gui.blocklyText.esp32.daysnumber": "days number",
    "gui.blocklyText.esp32.esp32_mpyReset": "reset",
    "gui.blocklyText.esp32.esp32_mpyMacAddr": "MAC address",
    "gui.blocklyText.esp32.displayInXY": "display [TEXT] at X: [X]  Y: [Y] axis, preview [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "screen display color [COLOR] (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "write cache screen color [COLORMPYTHON]",
    "gui.blocklyText.esp32.clear": "clear",
    "gui.blocklyText.esp32.fullBright": "full bright",
    "gui.blocklyText.esp32.blackBackground": "black background",
    "gui.blocklyText.esp32.whiteBackground": "white background",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": "all white",
    "gui.blocklyText.esp32.lineDrawing": "draw line from Point x1:[X1] y1:[Y1] to Point x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "connected",
    "gui.blocklyText.esp32.disconnected": "disconnected",
    "gui.blocklyText.esp32.rectangleDrawing": "draw rectangle [FILL] with top-left corner at x:[X] y:[Y], W:[WIDTH], H:[HEIGHT]",
    "gui.blocklyText.esp32.mpyrectangleDrawing": "[DRAWERASE] [FILL] rectangle drawing origin x:[X] y:[Y] width[WIDTH] height[HEIGHT]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCornerBorder": "[DRAWERASE] corner border x [X] y [Y] width [WIDTH] height [HEIGHT] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCircle": "[DRAWERASE] [FILL] circle x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseTriangle": "[DRAWERASE] [FILL] triangle x1 [X1] y1 [Y1] x2 [X2] y2 [Y2] x3 [X3] y3 [Y3]",
    "gui.blocklyText.esp32.esp32_mpyShowPixelCoordinate": "at coordinate x [X] y [Y] show [SHOWMSG] as [PIXEL] font [LINE]",
    "gui.blocklyText.esp32.Digiface_11px": "Digiface 11px",
    "gui.blocklyText.esp32.Digiface_16px": "Digiface 16px",
    "gui.blocklyText.esp32.Digiface_21px": "Digiface 21px",
    "gui.blocklyText.esp32.Digiface_30px": "Digiface 30px",
    "gui.blocklyText.esp32.Digiface_44px": "Digiface 44px",
    "gui.blocklyText.esp32.Digiface_ltalic_30px": "Digiface ltalic 30px",
    "gui.blocklyText.esp32.Digiface_ltalic_42px": "Digiface ltalic 42px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_12px": "DejaVu Sans Mono 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_16px": "DejaVu Sans Mono 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_21px": "DejaVu Sans Mono 21px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_12px": "DejaVu Sans Mono Bold 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_16px": "DejaVu Sans Mono Bold 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_21px": "DejaVu Sans Mono Bold 21px",
    "gui.blocklyText.esp32.esp32_mpyQRCode": "x [X] y [Y] draw QR code [QRCODE] size [IMGSIZE]",
    "gui.blocklyText.esp32.esp32_mpyInitClock": "init clock [CLOCKNAME] x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyClockReadTime": "clock [CLOCKNAME] read time",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseClock": "[DRAWERASE] clock [CLOCKNAME]",
    "gui.blocklyText.esp32.screenDisplay": "screen cache display takes effect",
    "gui.blocklyText.esp32.circleDrawing": "draw circle [FILL] with center point at x:[X] y:[Y], r:[RADIUS]",
    "gui.blocklyText.esp32.fill": "fill",
    "gui.blocklyText.esp32.notFill": "not fill",
    "gui.blocklyText.esp32.pointDrawing": "draw point at x:[X] y:[Y]",
    "gui.blocklyText.esp32.esp32_mpyProgressBar": "progress bar x [X] y [Y] width [WIDTH] height [HEIGHT] progress [PROG]",
    "gui.blocklyText.esp32.esp32_mpyColumnarProgressBar": "columnar progress bar [COLUMNARSTATE] x [X] y [Y] width [WIDTH] height [HEIGHT] progress [PROG]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseLine": "[DRAWERASE] [COLUMNARSTATE] line x [X] y [Y] length [LENGTH]",
    "gui.blocklyText.esp32.draw": "draw",
    "gui.blocklyText.esp32.erase": "erase",
    "gui.blocklyText.esp32.vertical": "vertical",
    "gui.blocklyText.esp32.horizontal": "horizontal",
    "gui.blocklyText.esp32.setLineWidth": "set line width to [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.esp32.analogWrite": "analog pin [PIN] output(PWM) [VALUE]",
    "gui.blocklyText.esp32.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.esp32.serialPort0": "serial0",
    "gui.blocklyText.esp32.serialPort1": "serial1",
    "gui.blocklyText.esp32.serialPort2": "serial2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX] the baud rate [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "screen display image [IMAGE] at x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "loading picture from image [IMAGE] show to x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "pressed",
    "gui.blocklyText.esp32.loosened": "released",
    "gui.blocklyText.esp32.all": "ALL(-1)",
    "gui.blocklyText.esp32.setBaud": "serialport0 baud rate [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.esp32.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.esp32.setBrightness": "set led brightness [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "get led lightness",
    "gui.blocklyText.esp32.print": "print [DATA]",
    "gui.blocklyText.esp32.TiltForward": "Tilt forward",
    "gui.blocklyText.esp32.TiltBack": "Tilt back",
    "gui.blocklyText.esp32.TiltLeft": "Tilt left",
    "gui.blocklyText.esp32.TiltRight": "Tilt right",
    "gui.blocklyText.esp32.ScreenUp": "Screen up",
    "gui.blocklyText.esp32.ScreenDown": "Face down",
    "gui.blocklyText.esp32.Shake": "Shake",
    "gui.blocklyText.esp32.isGesture": "is [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "Disable [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "All",
    "gui.blocklyText.esp32.rgbdisable.No.0": "No.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "No.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "No.2",
    "gui.blocklyText.esp32.playMusic": "Play music [MUSIC] repeating [REPEAT]",
    "gui.blocklyText.esp32.Once": "Once",
    "gui.blocklyText.esp32.Forever": "Forever",
    "gui.blocklyText.esp32.OnceInBackground": "Once in background",
    "gui.blocklyText.esp32.ForeverInBackground": "Forever in background",
    "gui.blocklyText.radio.radioSwitch": "Funk [DATA] einschalten",
    "gui.blocklyText.radio.radioswitch.on": "ein",
    "gui.blocklyText.radio.radioswitch.off": "aus",
    "gui.blocklyText.radio.setChannel": "Funkkanal einstellen [DATA]",
    "gui.blocklyText.radio.radioSend": "Funk sendet [DATA]",
    "gui.blocklyText.radio.radioreceived": "Funk empfangen",
    "gui.blocklyText.radio.receivingEven": "wenn empfangen [DATA]",
    "gui.blocklyText.radio.Msg": "Funknachricht",
    "gui.blocklyText.radio.receivingSpecificEven": "wenn eine bestimmte Funknachricht [DATA] vom Radio empfangen wird, mache",
    "gui.blocklyText.esp32.radioSwitch": "turn [DATA] radio",
    "gui.blocklyText.esp32.radioswitch.on": "on",
    "gui.blocklyText.esp32.radioswitch.off": "off",
    "gui.blocklyText.esp32.setChannel": "set radio channl [DATA]",
    "gui.blocklyText.esp32.radioSend": "radio sends [DATA]",
    "gui.blocklyText.esp32.radioreceived": "radio received",
    "gui.blocklyText.esp32.receivingEven": "when receiving broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.receivingSpecificEven": "when receiving specific broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.connectWIFI": "connect to Wi-Fi with SSID [SSID] & Password [PASS]",
    "gui.blocklyText.esp32.disconnectWIFI": "disconnect Wi-Fi connection",
    "gui.blocklyText.esp32.checkWIFI": "Has connected to Wi-Fi",
    "gui.blocklyText.esp32.configurationInformation": "Wi-Fi configuration information",
    "gui.blocklyText.esp32.configurationInformationIP": "Wi-Fi configuration information [IP]",
    "gui.blocklyText.esp32.wifi.IP": "IP",
    "gui.blocklyText.esp32.wifi.netmask": "netmask",
    "gui.blocklyText.esp32.wifi.geteway": "geteway",
    "gui.blocklyText.esp32.wifi.DNS": "DNS",
    "gui.blocklyText.esp32.openAP": "open AP mode with with SSID [SSID] & Password [PASS] & Channel [CHANNEL]",
    "gui.blocklyText.esp32.closeAP": "close AP mode",
    "gui.blocklyText.esp32.synchronizeTime": "synchronize network time,timezone [TIMEZONE]& timing server [SERVER]",
    "gui.blocklyText.esp32.timezone.UTC+8": "UTC+8",
    "gui.blocklyText.esp32.timezone.UTC+9": "UTC+9",
    "gui.blocklyText.esp32.timezone.UTC+10": "UTC+10",
    "gui.blocklyText.esp32.timezone.UTC+11": "UTC+11",
    "gui.blocklyText.esp32.timezone.UTC+12": "UTC+12",
    "gui.blocklyText.esp32.timezone.UTC-12": "UTC-12",
    "gui.blocklyText.esp32.timezone.UTC-11": "UTC-11",
    "gui.blocklyText.esp32.timezone.UTC-10": "UTC-10",
    "gui.blocklyText.esp32.timezone.UTC-9": "UTC-9",
    "gui.blocklyText.esp32.timezone.UTC-8": "UTC-8",
    "gui.blocklyText.esp32.timezone.UTC-7": "UTC-7",
    "gui.blocklyText.esp32.timezone.UTC-6": "UTC-6",
    "gui.blocklyText.esp32.timezone.UTC-5": "UTC-5",
    "gui.blocklyText.esp32.timezone.UTC-4": "UTC-4",
    "gui.blocklyText.esp32.timezone.UTC-3": "UTC-3",
    "gui.blocklyText.esp32.timezone.UTC-2": "UTC-2",
    "gui.blocklyText.esp32.timezone.UTC-1": "UTC-1",
    "gui.blocklyText.esp32.timezone.UTC": "UTC",
    "gui.blocklyText.esp32.timezone.UTC+1": "UTC+1",
    "gui.blocklyText.esp32.timezone.UTC+2": "UTC+2",
    "gui.blocklyText.esp32.timezone.UTC+3": "UTC+3",
    "gui.blocklyText.esp32.timezone.UTC+4": "UTC+4",
    "gui.blocklyText.esp32.timezone.UTC+5": "UTC+5",
    "gui.blocklyText.esp32.timezone.UTC+6": "UTC+6",
    "gui.blocklyText.esp32.timezone.UTC+7": "UTC+7",
    "gui.blocklyText.telloesp32.arduinoUnoStarts": "RMTT ESP32 starts",
    "gui.blocklyText.telloesp32.ledControl": "led control",
    "gui.blocklyText.telloesp32.matrixControl": "matrix control",
    "gui.blocklyText.telloesp32.flyControl": "fly control",
    "gui.blocklyText.telloesp32.customCommand": "custom command",
    "gui.blocklyText.telloesp32.pinOperation": "pin operation",
    "gui.blocklyText.telloesp32.readDigital": "read digital pin [PIN]",
    "gui.blocklyText.telloesp32.readAnalog": "read Analog pin [PIN]",
    "gui.blocklyText.telloesp32.setPwm": "set PWM pin [PIN] to [VALUE]",
    "gui.blocklyText.telloesp32.setDigital": "set digital pin [PIN] to [LEVEL]",
    "gui.blocklyText.telloesp32.functionalModule": "functional module",
    "gui.blocklyText.telloesp32.telloesp32_matrix": "set matrix [MARK]",
    "gui.blocklyText.telloesp32.showPatternWhenStart": "show pattern[MARK] when start",
    "gui.blocklyText.telloesp32.setMatrixLight": "set matrix light [NUM]",
    "gui.blocklyText.telloesp32.matrixDisplayText": "matrix display text [TEXT] color [COLOR] light [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayTextMpy": "matrix display text [TEXT] color [COLOR]",
    "gui.blocklyText.telloesp32.matrixDisplayPreset": "matrix display preset pattern [PATTERN] color [COLOR] light [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayPresetMpy": "matrix display preset pattern [PATTERN] color [COLOR]",
    "gui.blocklyText.telloesp32.empty": "empty",
    "gui.blocklyText.telloesp32.WiFi": "WiFi",
    "gui.blocklyText.telloesp32.DownLoad": "DownLoad",
    "gui.blocklyText.telloesp32.Power": "Power",
    "gui.blocklyText.telloesp32.heart": "heart",
    "gui.blocklyText.telloesp32.heartOutline": "heart-outline",
    "gui.blocklyText.telloesp32.matrixR": "r",
    "gui.blocklyText.telloesp32.matrixB": "b",
    "gui.blocklyText.telloesp32.matrixP": "p",
    "gui.blocklyText.telloesp32.matrixDisplayRollPicture": "display mark direction [DIRECT] move frequency [SPEED] mark[MARK]",
    "gui.blocklyText.telloesp32.matrixDisplayRollText": "matrix display text direction [DIRECT] move frequency [SPEED] text[TEXT] color[COLOR]",
    "gui.blocklyText.telloesp32.telloSetWifiChannel": "set Wi-Fi channel [CHANNEL]",
    "gui.blocklyText.telloesp32.initTOF": "init TOF",
    "gui.blocklyText.telloesp32.readTOF": "read TOF (mm)",
    "gui.blocklyText.telloesp32.buttonIsPressed": "button is pressed?",
    "gui.blocklyText.telloesp32.setRgbColor": "lights cache color [COLOR]",
    "gui.blocklyText.telloesp32.rgbToColor": "set R: [RED] G: [GREEN] B: [BLUE]",
    "gui.blocklyText.telloesp32.rgbBreathWithColor": "breath LED with color[COLOR] frequency(Hz) [TIME]",
    "gui.blocklyText.telloesp32.rgbBreath": "set breath lights R: [RED] G: [GREEN] B: [BLUE] frequency(Hz): [SP]",
    "gui.blocklyText.telloesp32.rgbFlashingColor": "flashing lights color1 [COLOR1] and color2 [COLOR2] frequency(Hz) [SP]",
    "gui.blocklyText.telloesp32.rgbFlashing": "set flashing lights R1: [RED1] G1: [GREEN1] B1: [BLUE1] R2: [RED2] G2: [GREEN2] B2: [BLUE2] frequency(Hz): [SP]",
    "gui.blocklyText.telloesp32.ledCloseEffects": "LED close special effects",
    "gui.blocklyText.telloesp32.matrixCloseEffects": "matrix close special effects",
    "gui.blocklyText.telloesp32.sendCustomCommand": "send custom command [CMD]",
    "gui.blocklyText.telloesp32.recvCustomCommand": "receive custom command [MSG], timeout [TIMEOUT]",
    "gui.blocklyText.telloesp32.recvCustomCommandNum": "receive custom num, timeout [TIMEOUT]",
    "gui.blocklyText.telloesp32.serialportOperation": "serialport operation",
    "gui.blocklyText.telloesp32.dataReadable": "Is [SERIAL] data readable?",
    "gui.blocklyText.telloesp32.readSerialData": "read [SERIAL] data",
    "gui.blocklyText.telloesp32.setBaud": "set serial0 baud rate [BAUD]",
    "gui.blocklyText.telloesp32.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.telloesp32.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.telloesp32.seriaRemapping": "set [SERIAL] Rx [RX] Tx [TX] The baud rate [BAUD]",
    "gui.blocklyText.telloesp32.systemResource": "system resource",
    "gui.blocklyText.telloesp32.timer": "timer(ms)",
    "gui.blocklyText.telloesp32.consolePrint": "console output",
    "gui.blocklyText.telloesp32.print": "print [DATA]",
    "gui.blocklyText.telloesp32.telloRiseDown": "fly [RISEDOWN] [NUMS]cm",
    "gui.blocklyText.telloesp32.rise": "up",
    "gui.blocklyText.telloesp32.down": "down",
    "gui.blocklyText.telloesp32.telloTurnDirection": "fly [DIRECTION] [NUMS] cm",
    "gui.blocklyText.telloesp32.turnleft": "left",
    "gui.blocklyText.telloesp32.turnright": "right",
    "gui.blocklyText.telloesp32.turnForward": "forward",
    "gui.blocklyText.telloesp32.turnBack": "back",
    "gui.blocklyText.telloesp32.telloMotorOn": "motor on",
    "gui.blocklyText.telloesp32.telloMotorOff": "motor off",
    "gui.blocklyText.telloesp32.telloTakeOff": "take off",
    "gui.blocklyText.telloesp32.telloThrowFly": "throw fly",
    "gui.blocklyText.telloesp32.telloLanding": "land",
    "gui.blocklyText.telloesp32.telloClockwiseRotation": "rotate [CLOCKWISE] [NUMS] degree",
    "gui.blocklyText.telloesp32.clockwise": "clockwise",
    "gui.blocklyText.telloesp32.counterClockwise": "counterClockwise",
    "gui.blocklyText.telloesp32.telloStartControl": "start control",
    "gui.blocklyText.telloesp32.telloGetHeight": "get [HEIGHT],timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.relatively": "relatively height(dm)",
    "gui.blocklyText.telloesp32.barometer": "barometer height(m)",
    "gui.blocklyText.telloesp32.tof": "TOF height(mm)",
    "gui.blocklyText.telloesp32.telloTemp": "board temperature(°C),timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.lowest": "lowest",
    "gui.blocklyText.telloesp32.highest": "highest",
    "gui.blocklyText.telloesp32.telloAttitude": "attitude Pitch(°), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.pitch": "pitch",
    "gui.blocklyText.telloesp32.roll": "roll",
    "gui.blocklyText.telloesp32.translation": "translation",
    "gui.blocklyText.telloesp32.telloAcceleration": "acceleration(0.001g), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.xaxis": "x axis",
    "gui.blocklyText.telloesp32.yaxis": "y axis",
    "gui.blocklyText.telloesp32.zaxis": "z axis",
    "gui.blocklyText.telloesp32.telloSetSpeed": "set speed to [NUMS]cm/s",
    "gui.blocklyText.telloesp32.telloSpeed": "[XYZAXIS] speed(cm/s), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloPower": "battery( %), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.MissionPad": "MissionPad, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloMissionPadXyz": "MissionPad [XYZ] (cm), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCommandResponse": "command response, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSetyawXMid": "Mid[MID] setyaw [YAW] mid",
    "gui.blocklyText.telloesp32.telloGetMissionPadNum": "get missionPad num, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCurrentSpeed": "Current speed, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloTime": "Motor running time (s), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloWIFISign": "Wi-Fi sign, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSDKVersion": "Tello SDK Version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloBinVersion": "Tello firmware version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloHasHardware": "Tello get hardware version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.rmttWifiVersion": "RMTT Wi-Fi version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSN": "Tello SN code, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloFlyToXYZ": "fly (relative to the aircraft) X[X]cm y[Y]cm z[Z]cm with speed [SPEED]cm/s",
    "gui.blocklyText.telloesp32.getSpeed": "get current seted speed (cm/s)",
    "gui.blocklyText.telloesp32.getBattery": "get the percentage value of the current remaining battery power",
    "gui.blocklyText.telloesp32.getTime": "get motor running time",
    "gui.blocklyText.telloesp32.getWifiNoise": "get Wi-Fi signal to noise ratio",
    "gui.blocklyText.telloesp32.getSdkVersion": "get sdk version",
    "gui.blocklyText.telloesp32.getSnNum": "get sn number",
    "gui.blocklyText.mpy.MQTTinit": "MQTT initial [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInit": "mqtt init [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInitPlatform": "platform",
    "gui.blocklyText.mpy.mpyMqttInitPlatformNormal": "normal",
    "gui.blocklyText.mpy.mpyMqttInitPlatformTencent": "tencent",
    "gui.blocklyText.mpy.MQTTclient_id": "client_id",
    "gui.blocklyText.mpy.MQTTserver": "server",
    "gui.blocklyText.mpy.MQTTport": "port",
    "gui.blocklyText.mpy.MQTTuser": "user",
    "gui.blocklyText.mpy.MQTTpassowrd": "passowrd",
    "gui.blocklyText.mpy.MQTTkeepalive": "keepalive",
    "gui.blocklyText.mpy.MQTTtencent": "MQTT Tencent IOT [SETTING]",
    "gui.blocklyText.mpy.TencentIOT": "TencentIOT",
    "gui.blocklyText.mpy.TencentServer": "server",
    "gui.blocklyText.mpy.TencentPort": "port",
    "gui.blocklyText.mpy.TencentAppld": "Appld",
    "gui.blocklyText.mpy.TencentDevice": "device",
    "gui.blocklyText.mpy.siotServer": "Server",
    "gui.blocklyText.mpy.siotServerUser": "User",
    "gui.blocklyText.mpy.siotServerPass": "Password",
    "gui.blocklyText.mpy.Tencentoutput": "output",
    "gui.blocklyText.mpy.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.mpy.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.mpy.mpy_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.mpy.EasyIOTServer": "server",
    "gui.blocklyText.mpy.EasyIOTServerChina": "China",
    "gui.blocklyText.mpy.EasyIOTServerGlobal": "Global",
    "gui.blocklyText.mpy.EasyIOTclient_id": "client_id",
    "gui.blocklyText.mpy.EasyIOT_id": "Iot_id",
    "gui.blocklyText.mpy.EasyIOT_password": "Iot_pwd",
    "gui.blocklyText.mpy.MQTTconnect": "connect to MQTT",
    "gui.blocklyText.mpy.mpy_MQTTAddSub": "subscribe [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublish": "publish [DATA] to topic [TOPIC]",
    "gui.blocklyText.mpy.mpy_waitMSG": "wait for topic message in [MODE] mode",
    "gui.blocklyText.mpy.disconnectMQTT": "disconnect from MQTT",
    "gui.blocklyText.mpy.MQTTlastwill": "set MQTT topic [TOPIC] last will message as [MSG]",
    "gui.blocklyText.mpy.MQTTreceive": "when received [DATA] from topic [TOPIC]",
    "gui.blocklyText.mpy.mqttMsg": "mqtt message",
    "gui.blocklyText.mpy.MQTTmode.block": "block",
    "gui.blocklyText.mpy.MQTTmode.unblock": "unblock",
    "gui.blocklyText.mpyWeather.init": "weather server set [DATA] as [SETTING]",
    "gui.blocklyText.mpyWeather.weatherFeild": "[DATA] weather server common fields [FIELD]",
    "gui.blocklyText.mpyWeather.weatherSituation": "[DATA] weather server Weather now [NOW]",
    "gui.blocklyText.mpyWeather.weatherForecast": "[DATA] weather server Weather forecast [FIELD] for [DATE]",
    "gui.blocklyText.mpyWeather.weatherLivingindex": "[DATA] weather server Living index [FIELD] index",
    "gui.blocklyText.mpyWeather.weatherFeild.name": "name",
    "gui.blocklyText.mpyWeather.weatherFeild.id": "id",
    "gui.blocklyText.mpyWeather.weatherFeild.code": "code",
    "gui.blocklyText.mpyWeather.weatherFeild.path": "path",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone": "timezone",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone_offset": "timezone_offset",
    "gui.blocklyText.mpyWeather.weatherFeild.Updated": "Updated",
    "gui.blocklyText.mpyWeather.weatherFeild.updatetime": "updatetime",
    "gui.blocklyText.mpyWeather.weatherFeild.original": "original",
    "gui.blocklyText.mpyWeather.weatherSituation.text": "text",
    "gui.blocklyText.mpyWeather.weatherSituation.code": "code",
    "gui.blocklyText.mpyWeather.weatherSituation.temperature": "temperature",
    "gui.blocklyText.mpyWeather.weatherdate.today": "today",
    "gui.blocklyText.mpyWeather.weatherdate.tomorrow": "tomorrow",
    "gui.blocklyText.mpyWeather.weatherdate.dayaftertomorrow": "dayaftertomorrow",
    "gui.blocklyText.mpyWeather.province": "province",
    "gui.blocklyText.mpyWeather.city": "city",
    "gui.blocklyText.mpyWeather.weatherforecast.highesttemperature": "highesttemperature",
    "gui.blocklyText.mpyWeather.weatherforecast.lowesttemperature": "lowesttemperature",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweather": "daytimeweather",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweathercode": "daytimeweathercode",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweather": "nightweather",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweathercode": "nightweathercode",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirection": "winddirection",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirectiondegree": "winddirectiondegree",
    "gui.blocklyText.mpyWeather.weatherforecast.windspeed": "windspeed",
    "gui.blocklyText.mpyWeather.weatherforecast.windscale": "windscale",
    "gui.blocklyText.mpyWeather.weatherforecast.date": "date",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Carwashindex": "Carwashindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Dressingindex": "Dressingindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Coldindex": "Coldindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Sportindex": "Sportindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Travelindex": "Travelindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.uvindex": "uvindex",
    "gui.blocklyText.mpyWeather.type": "data type",
    "gui.blocklyText.mpyWeather.temperature": "temperature",
    "gui.blocklyText.mpyWeather.APIkey": "APIkey",
    "gui.blocklyText.mpyWeather.language": "language",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 starts",
    "gui.blocklyText.mega2560.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.mega2560.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "pwm pin [PIN] output [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.mega2560.level.low": "LOW",
    "gui.blocklyText.mega2560.level.high": "HIGH",
    "gui.blocklyText.mega2560.irqRead": "read digital pin [PIN] of Infrared Receiver",
    "gui.blocklyText.mega2560.ultrasonicRead": "read ulrasonic sensor trig [TRIG], echo [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "pin [PIN] play buzzer tone [TONE] for [BEAT] beat",
    "gui.blocklyText.mega2560.harf": "Harf",
    "gui.blocklyText.mega2560.quarter": "Quarter",
    "gui.blocklyText.mega2560.oneInEighth": "One-eighth",
    "gui.blocklyText.mega2560.wholeBeat": "Whole Beat",
    "gui.blocklyText.mega2560.doubleBeat": "Double Beat",
    "gui.blocklyText.mega2560.stop": "Stop",
    "gui.blocklyText.mega2560.servoSet": "Set pin [PIN] servo to [DEGREE] degrees",
    "gui.blocklyText.mega2560.setBaud": "set [SERIAL] baud rate to [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "serial0",
    "gui.blocklyText.mega2560.serialPort1": "serial1",
    "gui.blocklyText.mega2560.serialPort2": "serial2",
    "gui.blocklyText.mega2560.serialPort3": "serial3",
    "gui.blocklyText.mega2560.dataReadable": "Is there data to read on [SERIAL] ?",
    "gui.blocklyText.mega2560.readSerialData": "read data on [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "in string",
    "gui.blocklyText.mega2560.originalOutput": "original output",
    "gui.blocklyText.mega2560.hexOutput": "in HEX",
    "gui.blocklyText.mega2560.wrap": "Wrap",
    "gui.blocklyText.mega2560.noWrap": "No-Wrap",
    "gui.blocklyText.mega2560.readPinLevel": "read pin [PIN] pulse width under [LEVEL] mode, timeout [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "system uptime(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini starts",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo starts",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 starts",
    "gui.blocklyText.arduino.setDigital": "digital pin [PIN] ouput [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "read digital pin [PIN]",
    "gui.blocklyText.arduino.readSimilation": "read analog pin [PIN]",
    "gui.blocklyText.arduino.setPwm": "pwm pin [PIN] output [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat ",
    "gui.blocklyText.arduino.serialSendStr": "serial output [STRING] [WAY], [LINE]",
    "gui.blocklyText.arduino.originalOutput": "in origin",
    "gui.blocklyText.arduino.stringOutput": "in string",
    "gui.blocklyText.arduino.hexOutput": "in HEX",
    "gui.blocklyText.arduino.wrap": "Wrap",
    "gui.blocklyText.arduino.noWrap": "No-Wrap",
    "gui.blocklyText.arduino.readInfrared": "read digital pin [PIN] of infrared Receiver",
    "gui.blocklyText.arduino.setBaud": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "read pin [PIN] pulse width pattern [LEVEL] timeout [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "no sound",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Arduino Nano starts",
    "gui.blocklyText.vortex.vortexStart": "Vortex robot program starts",
    "gui.blocklyText.vortex.vortexMoveSpeed": "vortex robot [DIRECTION] at speed[SPEED] ",
    "gui.blocklyText.vortex.forward": "move forward",
    "gui.blocklyText.vortex.backup": "move backward",
    "gui.blocklyText.vortex.turnLeft": "turn left",
    "gui.blocklyText.vortex.turnRight": "turn right",
    "gui.blocklyText.vortex.setMotor": "motor [MOTOR] [DIRECTION] at speed[SPEED]  ",
    "gui.blocklyText.vortex.leftWheel": "left",
    "gui.blocklyText.vortex.rightWheel": "right",
    "gui.blocklyText.vortex.setExpression": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.vortex.ledLights": "[DIRECTION][LIGHT] change to [COLOR] in [SECOND]secs.",
    "gui.blocklyText.vortex.all": "all",
    "gui.blocklyText.vortex.rightAhead": "front",
    "gui.blocklyText.vortex.leftRear": "left rear",
    "gui.blocklyText.vortex.rightRear": "right rear",
    "gui.blocklyText.vortex.rightAstern": "rear",
    "gui.blocklyText.vortex.topLights": "top lights",
    "gui.blocklyText.vortex.bottomLights": "bottom lights",
    "gui.blocklyText.vortex.vortexStop": "Vortex stops moving ",
    "gui.blocklyText.vortex.setVolume": "set volume to [VALUE]",
    "gui.blocklyText.vortex.playMusic": "play the [VALUE] music",
    "gui.blocklyText.vortex.stopMusic": "stop playing music",
    "gui.blocklyText.vortex.startDance": "start the [VALUE] dance",
    "gui.blocklyText.vortex.stopDance": "stop dancing",
    "gui.blocklyText.vortex.barrierDetection": "obstacle ahead?",
    "gui.blocklyText.vortex.readSensor": "read Grayscale Sensor [SENSOR]",
    "gui.blocklyText.vortex.setSensor": "set grayscale sensors sensitivity to [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "grayscale sensor meets black on its [SENSOR] position?",
    "gui.blocklyText.vortex.positiveFrontLeft": "straight left front",
    "gui.blocklyText.vortex.frontLeft": "left front",
    "gui.blocklyText.vortex.positiveFrontRight": "straight right front",
    "gui.blocklyText.vortex.frontRight": "right front",
    "gui.blocklyText.vortex.backRight": "right rear",
    "gui.blocklyText.vortex.backLeft": "left rear",
    "gui.blocklyText.vortex.initialOldPCB": "initialize to old version (blue PCB)",
    "gui.blocklyText.microbitV2.logo": "LOGO(V2)",
    "gui.blocklyText.microbitV2.ls": "LS(V2)",
    "gui.blocklyText.microbit.microbitStarts": "micro:bit startet",
    "gui.blocklyText.microbit.interruptExcute": "Interrupt-Pin [PIN] Modus [MODE] excute",
    "gui.blocklyText.microbit.cancleInterrupt": "kann Interrupt-Pin [PIN] löschen",
    "gui.blocklyText.microbit.wirelessReceiveData": "bei Empfang von Funkdaten",
    "gui.blocklyText.microbit.whenBtnPress": "wenn Taste [REFERENCE] gedrückt wird",
    "gui.blocklyText.microbit.whenPinConnected": "wenn Mikrobit-Pin [REFERENCE] angeschlossen",
    "gui.blocklyText.microbit.whenPosChange": "wenn [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "Muster anzeigen [PIC]",
    "gui.blocklyText.microbit.showInput": "Anzeige [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "Animation stoppen",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] Koordinaten des Punktes （x:[XAXIS], y:[YXAXIS]）",
    "gui.blocklyText.microbit.hideAllLights": "alle Punktmatrizen löschen",
    "gui.blocklyText.microbit.microbitEnableLight": "Gitterbildschirm [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "Helligkeit",
    "gui.blocklyText.microbit.showLightWithBrightness": "Helligkeit setzen [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "Koordinaten des Punktes anzeigen (x:[XAXIS], y:[YXAXIS]), Helligkeit [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "pin [PIN] Ton abspielen [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "pin [PIN] Ton abspielen [SOUND] bis fertig",
    "gui.blocklyText.microbit.playNote": "pin [PIN] Ton abspielen [NOTE] für [BEAT] Takt",
    "gui.blocklyText.microbit.true": "aktivieren",
    "gui.blocklyText.microbit.false": "deaktivieren",
    "gui.blocklyText.maqueen.playSound": "Pin P0 Ton abspielen [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "Pin P0 Ton bis zu Ende spielen [SOUND]",
    "gui.blocklyText.maqueen.playNote": "Pin P0 spielt Note [NOTE] für [BEAT] Schläge",
    "gui.blocklyText.maqueen.RGB0": "RGB0 (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1 (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2 (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3 (3)",
    "gui.blocklyText.maqueen.All": "Alle (-1)",
    "gui.blocklyText.microbit.changeTempo": "Tempo (bpm) um [VALUE] ändern",
    "gui.blocklyText.microbit.setTempo": "Tempo (bpm) auf [VALUE] setzen",
    "gui.blocklyText.microbit.getTempo": "Tempo (bpm)",
    "gui.blocklyText.microbit.stopSound": "Hintergrundwiedergabe stoppen",
    "gui.blocklyText.microbit.btnIsPressed": "[BUTTON] Taste gedrückt?",
    "gui.blocklyText.microbit.pinIsConnected": "[PIN] Pin angeschlossen?",
    "gui.blocklyText.microbit.isMove": "aktueller Zustand [TYPE]?",
    "gui.blocklyText.microbit.compass": "Kompassausrichtung lesen",
    "gui.blocklyText.microbit.temperature": "Temperatur lesen",
    "gui.blocklyText.microbit.digitalWrite": "digitaler Pin [PIN] Ausgang [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "digitalen Pin [PIN] lesen ",
    "gui.blocklyText.microbit.analogWrite": "analoger Pin [PIN] Ausgang [VALUE]",
    "gui.blocklyText.microbit.analogRead": "analogen Pin [PIN] lesen",
    "gui.blocklyText.microbit.up": "Logo oben",
    "gui.blocklyText.microbit.down": "Logo unten",
    "gui.blocklyText.microbit.left": "nach links kippen",
    "gui.blocklyText.microbit.right": "nach rechts kippen",
    "gui.blocklyText.microbit.faceUp": "nach oben richten",
    "gui.blocklyText.microbit.faceDown": "nach unten richten",
    "gui.blocklyText.microbit.freefall": "freier Fall",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "schütteln",
    "gui.blocklyText.microbit.show": "anzeigen",
    "gui.blocklyText.microbit.hide": "ausblenden",
    "gui.blocklyText.microbit.low": "NIEDRIG",
    "gui.blocklyText.microbit.high": "HOCH",
    "gui.blocklyText.microbit.microbitReadBrightness": "Umgebungslicht-Helligkeit lesen",
    "gui.blocklyText.microbit.microbitOpenWireless": "schalte drahtlose Kommunikation [SWITCH]",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "Funkkanal auf [NUM] setzen",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "Zeichenkette [TEXT] über Funk senden",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "Daten über Funk empfangen",
    "gui.blocklyText.microbit.strength": "Stärke",
    "gui.blocklyText.microbit.acceleration": "Beschleunigung lesen(m-g)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "Magnetkraft lesen(µT)[TYPE]",
    "gui.blocklyText.microbit.microbitAccurateCompass": "genauer Kompass",
    "gui.blocklyText.microbit.microbitGetSoundLevel": "Schallpegel auslesen(V2)",
    "gui.blocklyText.microbit.print": "Drucken [DATA]",
    "gui.blocklyText.microbit.goUp": "hoch",
    "gui.blocklyText.microbit.goDown": "runter",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "beim Empfang von [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "Funkdaten",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot Startet",
    "gui.blocklyText.maxbot.playSound": "Ton abspielen [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "Ton abspielen [SOUND] bis fertig",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "Kollision auf der [LEFTRIGHT] aufgetreten ?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "schwarze Linie auf der [LEFTRIGHT] erkannt ?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "Motor [MOTOR] [DIR] mit Geschwindigkeit [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "lese (P1,P2) Ultraschallsensor [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "lese Ultraschallsensor Einheit [UNIT], Trigger [TRIG], Echo [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "VORWÄRTS",
    "gui.blocklyText.maxbot.BACKWARD": "RÜCKWÄRTS",
    "gui.blocklyText.motorbit.CW": "CW",
    "gui.blocklyText.motorbit.CCW": "CCW",
    "gui.blocklyText.maxbot.CW": "CW",
    "gui.blocklyText.maxbot.CCW": "CCW",
    "gui.blocklyText.maxbot.ANGLE": "Grad",
    "gui.blocklyText.maxbot.CIRCLE": "Umdrehungen",
    "gui.blocklyText.maxbot.Left": "links",
    "gui.blocklyText.maxbot.Right": "rechts",
    "gui.blocklyText.maxbot.cm": "cm",
    "gui.blocklyText.maxbot.inch": "Zoll",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit startet",
    "gui.blocklyText.MotorBit.MotorRun": "Motor [MOTOR] dreht [DIR] mit Geschwindigkeit [SPEED]",
    "gui.blocklyText.MotorBit.MotorStop": "Motor [MOTOR] stoppt",
    "gui.blocklyText.MotorBit.Stepper": "Schrittmotor [STEPPER] dreht [ROTATEDIR] für [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "ALLE",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen Starts",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "read (P1,P2)ultrasonic sensor (cm)",
    "gui.blocklyText.maqueen.motorStop": "robot (left and right motors) stops",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] LED light",
    "gui.blocklyText.maqueen.redLineSensor": "read [LEFTRIGHT] line tracking sensor",
    "gui.blocklyText.maqueen.robotSpeedMove": "robot [DIR] at speed [SPEED]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "motor [LEFTRIGHT] move [DIR] at speed [SPEED]",
    "gui.blocklyText.maqueen.ledLeft": "Left(P8)",
    "gui.blocklyText.maqueen.ledRight": "Right(P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "Right(P12) (not available)",
    "gui.blocklyText.maqueen.lineSensorLeft": "Left(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "Right(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "when (P16) received [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "infrared signal",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "read(P16) infrared signal",
    "gui.blocklyText.maqueen.numberOfLightsBright": "pin P15 4 RGB LEDs brightness [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "pin P15 the [NUM1] LED display color [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "pin P15 LED [NUM1] to [NUM2] show gradient color from [COLOR1] to [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "pin P15 shift pixels by [NUM]",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "pin P15 rotate pixels by [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "pin P15 LED brightness [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "pin P15 LED [NUM1] to [NUM2] show histogram, value [VALUE], max [MAX]",
    "gui.blocklyText.maqueen.clear": "pin P15 clear all LEDs",
    "gui.blocklyText.maqueen.rgbColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.tello.telloStarts": "Tello Starts",
    "gui.blocklyText.tello.telloControlPlane": "control plane",
    "gui.blocklyText.tello.telloTakeOff": "take off",
    "gui.blocklyText.tello.telloLanding": "land",
    "gui.blocklyText.tello.telloRise": "fly up [NUMS]cm",
    "gui.blocklyText.tello.telloDown": "fly down [NUMS]cm",
    "gui.blocklyText.tello.telloGoahead": "fly forward [NUMS]cm",
    "gui.blocklyText.tello.telloBack": "fly backward [NUMS]cm",
    "gui.blocklyText.tello.telloTurnLeft": "fly left [NUMS]cm",
    "gui.blocklyText.tello.telloTurnRight": "fly right [NUMS]cm",
    "gui.blocklyText.tello.telloClockwiseRotation": "rotate [NUMS] degree clockwise",
    "gui.blocklyText.tello.telloCounterclockwiseSelection": "rotate [NUMS] degree counter clockwise",
    "gui.blocklyText.tello.telloSpeed": "set speed to [NUMS]cm/s",
    "gui.blocklyText.tello.telloRoll": "flip [ROLL]",
    "gui.blocklyText.tello.telloPower": "power?",
    "gui.blocklyText.tello.telloRollForward": "forward(f)",
    "gui.blocklyText.tello.telloRollBack": "back(b)",
    "gui.blocklyText.tello.telloRollLeft": "left(l)",
    "gui.blocklyText.tello.telloRollRight": "right(r)",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] received data?",
    "gui.blocklyText.obloq.initialParameter": "obloq mqtt initialization: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq read the message from [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq read the message",
    "gui.blocklyText.obloq.httpInitialParameter": "obloq http initialization: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "add subscribe [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "Wi-Fi name",
    "gui.blocklyText.obloq.password": "password",
    "gui.blocklyText.obloq.iotService": "IOT Service",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "IP Address",
    "gui.blocklyText.obloq.port": "Port",
    "gui.blocklyText.obloq.addTopic": "Add Topic, only add up to 4",
    "gui.blocklyText.obloq.deleteTopic": "Delete Topic",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "Server",
    "gui.blocklyText.obloq.global": "Global",
    "gui.blocklyText.obloq.china": "China",
    "gui.blocklyText.obloq.softwareSerial": "Software Serial",
    "gui.blocklyText.obloq.hardwareSerial": "Hardware Serial",
    "gui.blocklyText.obloq.hardwareSerial1": "Hardware Serial1",
    "gui.blocklyText.obloq.hardwareSerial2": "Hardware Serial2",
    "gui.blocklyText.obloq.hardwareSerial3": "Hardware Serial3",
    "gui.blocklyText.obloq.WhenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq message",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq get [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Debug info",
    "gui.blocklyText.obloq.ERROR": "Error code",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.infraredEmission.send": "Infrared send pin [PIN] Send message: address [ADDRESS] command [COMMAND]",
    "gui.blocklyText.oled2864.displayInit": "OLED display init address [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 display [TEXT] in line [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 display [TEXT] at position X: [X] Y: 16*[Y]",
    "gui.blocklyText.oled2864.screenRotation": "rotate screen to [ROTATION] degrees",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 clear",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "read pin [PIN] [DHT] [TYPE]",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx pin [PIN] PM concentration value [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 ultrasonic initialization Name [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04 ultrasonic distance measurement unit [UNIT]",
    "gui.blocklyText.hcsr04.readData": "read HC-SRO4 Trig [PINT] Echo [PINE] unit [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx pin [PIN] 0.1 liters of air in [DIAMETER] number of particles",
    "gui.blocklyText.sen0170.readWindspeed": "read pin [PIN] wind scale",
    "gui.blocklyText.sen0228.init": "Initialize ambient light (VEML7700) sensor",
    "gui.blocklyText.sen0228.readAmbientLight": "read Ambient Light(VEML7700)",
    "gui.blocklyText.dfr0026.readAmbientLight": "read pin [PIN] Ambient light",
    "gui.blocklyText.sen0177.readDustSensor": "read [TYPE] (ug/m3) interface [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "pin(D) [PIN] pin(MCLR) [RST] current gesture [TYPE]?",
    "gui.blocklyText.sen0203.readHeartRate": "read pin [PIN] [TYPE] switch to [SWITCH]",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] pin [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "read pin [PIN] grayscale",
    "gui.blocklyText.motucamera.initIICSerial": "mu camera init [MU] interface [IICSERIAL] until init success",
    "gui.blocklyText.motucamera.resetConfig": "mu camera [MU] reset to default",
    "gui.blocklyText.motucamera.algorithmSwitch": "mu camera [MU] [SWITCH] algorithm [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "mu camera [MU] [LED] when detect [COLOR1] when undetect [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "mu camera [MU] set algorithm [ALGORITHM] level [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "mu camera [MU] digital zoom [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "mu camera [MU] set baudrate [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "mu camera [MU] set white balance [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "mu camera [MU] high FPS mode [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "mu camera [MU] is detect [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": "mu camera [MU] is detect color recogniza x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "mu camera [MU] is detect color detect color = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "mu camera get [MU] alogrithm [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "mu camera [MU] color recognize [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "mu camera [MU] shape card [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "mu camera [MU] traffic card [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "mu camera [MU] number card [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "mu camera [MU] color recognize color = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "Color Detect",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "Color Recognize",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "Ball Detect",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "Body Detect",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "Shape Card",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "Traffic Card",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "Number Card",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "enable",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "disable",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "white",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "white",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "Auto",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "Speed",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "Balance",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "Accuracy",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "Auto",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "Lock",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "white",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "yellow",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "Auto",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "Level1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "Level2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "Level3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "Level4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "Level5",
    "gui.blocklyText.motucamera.setHighFPSOff": "OFF",
    "gui.blocklyText.motucamera.setHighFPSOn": "ON",
    "gui.blocklyText.motucamera.colorDetectBlack": "black",
    "gui.blocklyText.motucamera.colorDetectWhite": "white",
    "gui.blocklyText.motucamera.colorDetectRed": "red",
    "gui.blocklyText.motucamera.colorDetectYellow": "yellow",
    "gui.blocklyText.motucamera.colorDetectGreem": "green",
    "gui.blocklyText.motucamera.colorDetectCayan": "cayan",
    "gui.blocklyText.motucamera.colorDetectBlue": "blue",
    "gui.blocklyText.motucamera.colorDetectPerple": "perple",
    "gui.blocklyText.motucamera.colorDetectOthers": "others",
    "gui.blocklyText.motucamera.colorDetectPositionX": "x position",
    "gui.blocklyText.motucamera.colorDetectPositionY": "y position",
    "gui.blocklyText.motucamera.colorDetectPositionW": "width",
    "gui.blocklyText.motucamera.colorDetectPositionH": "height",
    "gui.blocklyText.motucamera.colorDetectPositionL": "label",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "red channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "green channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "blue channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "label channel",
    "gui.blocklyText.motucamera.shapeCardTick": "tick",
    "gui.blocklyText.motucamera.shapeCardCross": "cross",
    "gui.blocklyText.motucamera.shapeCardCircle": "circle",
    "gui.blocklyText.motucamera.shapeCardSquare": "square",
    "gui.blocklyText.motucamera.shapeCardTriangle": "triangle",
    "gui.blocklyText.motucamera.trafficCardForward": "Forward",
    "gui.blocklyText.motucamera.trafficCardLeft": "Left",
    "gui.blocklyText.motucamera.trafficCardRight": "Right",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "Turn Around",
    "gui.blocklyText.motucamera.trafficCardStop": "Stop",
    "gui.blocklyText.motucamera.lightSensorUse": "mu camera [MU] light sensor enable [LIGHTFUNC] detection",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "mu camera [MU] light sensor sensitivity [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "mu camera [MU] light sensor read proximity detection",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "mu camera [MU] light sensor read ambient light detection",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "mu camera [MU] light sensor read once gesture and saved",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "mu camera [MU] light sensor gesture =  [GESTURE]",
    "gui.blocklyText.motucamera.upward": "upward",
    "gui.blocklyText.motucamera.downward": "downward",
    "gui.blocklyText.motucamera.leftward": "leftward",
    "gui.blocklyText.motucamera.rightward": "rightward",
    "gui.blocklyText.motucamera.pull": "pull",
    "gui.blocklyText.motucamera.push": "push",
    "gui.blocklyText.motucamera.none": "none",
    "gui.blocklyText.motucamera.default": "default",
    "gui.blocklyText.motucamera.low": "low",
    "gui.blocklyText.motucamera.middle": "middle",
    "gui.blocklyText.motucamera.high": "high",
    "gui.blocklyText.motucamera.proximity": "proximity",
    "gui.blocklyText.motucamera.ambientLight": "ambient light",
    "gui.blocklyText.motucamera.gesture": "gesture",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi initialize port [SERIALPORT] RX[RX] TX[TX] UART baudrate [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi set ssid [SSID] password [PASSWORD] mode [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi conection succeeded？",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi disconnect",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi set target IP [IP] port [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi read target IP",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi read local IP",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi read",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi Write [WRITE]",
    "gui.blocklyText.dfr0552.init": "DA module init I2C address [ADDR] voltage reference [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "DA module output triangular wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "DA module output sine wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "DA module output voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "DA module memory voltage [VOLTAGE]mV",
    "gui.blocklyText.sen0117.init": "Initialize module interface [SERIALPORT] Rx[RX] Tx[TX] Busy[BUSY]",
    "gui.blocklyText.sen0117.playText": "Plays text [TEXT] background music [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "Plays voice prompt [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "Plays chord prompts [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "Set up the [INDEX] to [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "Setting the speech rate [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "Get the speech synthesis module [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "Prospects volume",
    "gui.blocklyText.sen0117.Background": "Background volume",
    "gui.blocklyText.sen0117.Speed": "Speed",
    "gui.blocklyText.sen0117.playChordCueTone": "Plays chord ring tones [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.No": "No (0)",
    "gui.blocklyText.sen0117.setPlayMode": "Set the playback mode to [MODE]",
    "gui.blocklyText.sen0117.pause": "Pause",
    "gui.blocklyText.sen0117.resume": "Resume",
    "gui.blocklyText.sen0117.stop": "Stop",
    "gui.blocklyText.sen0117.isIdle": "Play finish?",
    "gui.blocklyText.dfr0576.selectPort": "Select port [PORT]",
    "gui.blocklyText.dfr0576.begin": "Initialize the I2C cascade module address is [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680 initializes",
    "gui.blocklyText.sen0248.readBME680": "Get the BME680 sensor [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "Temperature (°C)",
    "gui.blocklyText.sen0248.Pressure": "Pressure (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Humidity (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0248.GasResistance": "Gas Resistance (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "initialize the I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Change I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Read ultrasonic sensor distance(cm)",
    "gui.blocklyText.sen0304.readTemperature": "Read ultrasonic sensor temperature(°C)",
    "gui.blocklyText.sen0307.readDistance": "Read [PIN] analog ultrasonic wave(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 initialization address [ADDR] mode [MODE]",
    "gui.blocklyText.sen0250.Step": "Step",
    "gui.blocklyText.sen0250.Measure": "Measure",
    "gui.blocklyText.sen0250.readAccelerated": "Get the [AXIS] axis Acceleration(g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 get step number",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "Gyro",
    "gui.blocklyText.sen0250.Acc": "Acceleration (g)",
    "gui.blocklyText.sen0224.init": "Initialize the LIS2DH sensor",
    "gui.blocklyText.sen0224.readAccelerated": "Read acceleration value of LIS2DH sensor [AXIS] axis (mg)",
    "gui.blocklyText.dfr0023.readLM35": "read pin [PIN] LM35 temprature(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "when pin [PIN] detected vibration",
    "gui.blocklyText.dfr0028.readTiltSensor": "pin [PIN] detected tilt?",
    "gui.blocklyText.dfr0029.readPress": "pin [PIN] button pressed?",
    "gui.blocklyText.dfr0030.readTouch": "pin [PIN] detected touch?",
    "gui.blocklyText.dfr0033.readMagnetic": "pin [PIN] detected magnet?",
    "gui.blocklyText.dfr0034.readSound": "read pin [PIN] Loudness",
    "gui.blocklyText.dfr0094.readIRReceived": "read pin [PIN] IR data",
    "gui.blocklyText.dfr0094.onReceiveEvent": "When the infrared signal is received Pin [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "address",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "command",
    "gui.blocklyText.midi.midiInit": "Init MIDI module interface [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.midi.midiOutput": "MIDI output channel [CHANNEL] pitch [PITCH] speed [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "read pin [PIN] Carbon Monoxide (CO) sensor",
    "gui.blocklyText.dfr0051.readVoltage": "read pin [PIN] Voltage(V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "read pin [PIN] Piezo Disk Vibration Sensor",
    "gui.blocklyText.dfr0058.readRotation": "read pin [PIN] Rotation Sensor",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "read pin [PIN] JoyStick [AXIS] Axis",
    "gui.blocklyText.dfr0061.readJoyStickButton": "pin [PIN] JoyStick Button(Z) pressed?",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut init data pin [DATAPIN] RTC pin [RTCPIN] cls pin [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Digital tube display segment code 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Digital tube display number [NUM]",
    "gui.blocklyText.dfr0072.dfr0072Dot": "Digital tube display dot [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "true",
    "gui.blocklyText.dfr0072.dotFalse": "false",
    "gui.blocklyText.dfr0072.numOff": "off",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Read lithium battery [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "voltage(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "remainingBattery(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD module init I2C address [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD module read [PIN] voltage (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD module get aisle [AISLE] voltage difference",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 and 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 and 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 and 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 and 3",
    "gui.blocklyText.dfr0588.initPin": "Init temperature pin [TEM] humidity pin [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "get temperature unit [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "get humidity(%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "CELSIUS",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "FAHRENHEIT",
    "gui.blocklyText.dfr0076.readFlameSensor": "read pin [PIN] Flame Sensor",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom get [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom read text name [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom read number name [KEY]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom init i2c address [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom write text [VALUE] name [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom write number [VALUE] name [KEY]",
    "gui.blocklyText.dfr0117.text": "Text",
    "gui.blocklyText.dfr0117.number": "Number",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom delete name [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom serial print all names baud [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom name [KEY] exist?",
    "gui.blocklyText.dfr0117.eepromFormat": "i2c eeprom format",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom name [KEY] is [TYPE]?",
    "gui.blocklyText.dfr0117.nameCount": "Name count",
    "gui.blocklyText.dfr0117.memorySize": "Storage space(byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "Remaining storage space(byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": "read pin [PIN] Triple Axis Accelerometer [AXIS] Axis",
    "gui.blocklyText.sen0018.readInfraredMotion": "read pin [PIN] Digital Infrared motion sensor",
    "gui.blocklyText.sen0014.readDistance": "read pin [PIN] Sharp IR Distance Sensor(mm)",
    "gui.blocklyText.sen0114.readMoisture": "read pin [PIN] Soil Moisture Sensor",
    "gui.blocklyText.sen0121.readSteam": "read pin [PIN] Steam Sensor",
    "gui.blocklyText.sen0204.readLiquidLevel": "pin [PIN] liquid level reached a certain height ?",
    "gui.blocklyText.sen0203.heartRate": "heart rate(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "blood oxygen saturation(HbO2)",
    "gui.blocklyText.sen0202.up": "Up",
    "gui.blocklyText.sen0202.down": "Down",
    "gui.blocklyText.sen0202.left": "Left",
    "gui.blocklyText.sen0202.right": "Right",
    "gui.blocklyText.sen0202.cw": "CW",
    "gui.blocklyText.sen0202.ccw": "CCW",
    "gui.blocklyText.dhtTHSensor.temperature": "temperature(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "moisture(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "pin [PIN] RGB [NUM1] to [NUM2] show color [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "pin [PIN]  [NUM1] to [NUM2] RGBs show gradient color from [COLOR1] to [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "pin [PIN] shift pixels by [NUM]",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "pin [PIN] rotate pixels by [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "pin [PIN] [NUM] RGB LEDs",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "pin [PIN] brightness [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "pin [PIN] clear all LEDs",
    "gui.blocklyText.ws2812.showHistogram": "pin [PIN]  [NUM1] to [NUM2] RGB LEDs show histogram  current value: [VALUE]  max value: [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "pin [PIN] LED brightness [BRIGHTNESS]",
    "gui.blocklyText.neopixel.init": "Lamp initialization name [NAME] pin [PIN] number [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Light strip [NAME] [INDEX] Color [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Light strip [NAME] [INDEX] Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.fillColor": "The light strip [NAME] is fully lit and the color is [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Light strip [NAME] All on Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.flush": "Light strip [NAME] settings take effect",
    "gui.blocklyText.neopixel.off": "Light strip [NAME] is off",
    "gui.blocklyText.neopixel.setBrightness": "Light strip [NAME] sets brightness to [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Rainbow light effect [NAME] Quantity [COUNT] Brightness [BRIGHTNESS] Offset [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7(mPython board)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9(mPython box)",
    "gui.blocklyText.dsTSensor.ds18b20": "Read pin [PIN] DS18B20 temperature(°C)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280 initializes",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "read [TYPE] BMP280 sensor",
    "gui.blocklyText.bmpTSensor.atmosPressure": "barometric(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "altitude(m)",
    "gui.blocklyText.bmpTSensor.temperature": "temperature(℃)",
    "gui.blocklyText.huskylens.init": "HuskyLens initialize pin [SETTING] until success",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens switch algorithm to [ALGORITHM]",
    "gui.blocklyText.huskylens.requestFirst": "HuskyLens request data once and save into the result",
    "gui.blocklyText.huskylens.resultGetIsStudy": "HuskyLens check if ID [IDNUM] is learned from the result?",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "HuskyLens check if ID [IDNUM] [BOXARROW] is on screen from the result?",
    "gui.blocklyText.huskylens.resultGetIDParameter": "HuskyLens get [PARAMETER] of ID [IDNUM] [TYPE] from the result",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens check if [BOXARROW] is on screen from the result?",
    "gui.blocklyText.huskylens.resultGetParameter": "HuskyLens get [PARAMETER2] of the No. [IDNUM] [TYPE] from the result",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens get a total number of learned IDs from the result",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens get a total number of [BOXARROW] from the result",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens get a total number of ID [IDNUM] [BOXARROW] from the result",
    "gui.blocklyText.huskylens.resultGetIDofWhichParameter": "HuskyLens get [PARAMETER] of the ID [IDNUM] No. [BOXNUM] [TYPE] from the result",
    "gui.blocklyText.huskylens.setIDname": "HuskyLens name ID [ID] of the current algorithm as [NAME]",
    "gui.blocklyText.huskylens.TakeScreenshot": "HuskyLens take [TYPE] and save to SD card",
    "gui.blocklyText.huskylens.writeOSD": "HuskyLens show custom texts [NAME] at position x [X] y [Y] on screen",
    "gui.blocklyText.huskylens.clearOSD": "HuskyLens clear all custom texts on screen",
    "gui.blocklyText.huskylens.learnOnce": "HuskyLens learn ID [ID] once automatically",
    "gui.blocklyText.huskylens.clearLearn": "HuskyLens forget all learning data of the current algorithm",
    "gui.blocklyText.huskylens.loadAndSaveModel": "HuskyLens [TYPE] current algorithm data as No. [INDEX] model of SD card",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.A": "photo",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.B": "screenshot",
    "gui.blocklyText.huskylens.LOADSAVE.A": "save",
    "gui.blocklyText.huskylens.LOADSAVE.B": "load",
    "gui.blocklyText.huskylens.resultGetNearCenter": "HuskyLens get [PARAMETER2] of [TYPE] closest to the center of screen from the result",
    "gui.blocklyText.huskylens.box": "frame",
    "gui.blocklyText.huskylens.arrow": "arrow",
    "gui.blocklyText.huskylens.faceRecognition": "Face recognition",
    "gui.blocklyText.huskylens.objectTracking": "Object tracking",
    "gui.blocklyText.huskylens.objectRecognition": "Object recognition",
    "gui.blocklyText.huskylens.lineTracking": "Line tracking",
    "gui.blocklyText.huskylens.colorRecognition": "Color recognition",
    "gui.blocklyText.huskylens.tagRecognition": "Tag recognition",
    "gui.blocklyText.huskylens.objectClassification": "Object classification",
    "gui.blocklyText.huskylens.QRRecognition": "QR recognition (Edu only)",
    "gui.blocklyText.huskylens.barcodeRecognition": "Barcode recognition (Edu only)",
    "gui.blocklyText.huskylens.x": "X center",
    "gui.blocklyText.huskylens.y": "Y center",
    "gui.blocklyText.huskylens.w": "width",
    "gui.blocklyText.huskylens.h": "height",
    "gui.blocklyText.huskylens.x1": "X beginning",
    "gui.blocklyText.huskylens.y1": "Y beginning",
    "gui.blocklyText.huskylens.x2": "X endpoint",
    "gui.blocklyText.huskylens.y2": "Y endpoint",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "Communication Method",
    "gui.blocklyText.huskylens.address": "Address",
    "gui.blocklyText.huskylens.sdaGreen": "SDA(Green)",
    "gui.blocklyText.huskylens.sclBlue": "SCL(Blue)",
    "gui.blocklyText.huskylens.rxGreen": "Rx(Green)",
    "gui.blocklyText.huskylens.txBlue": "Tx(Blue)",
    "gui.blocklyText.ps2.initPs2Handle": "init PS2 handle DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] setRunmble [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2 open runmble [RUMBLE] strength [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "PS2 reflush states",
    "gui.blocklyText.ps2.ps2ButtonState": "PS2 button [BUTTON] state is [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2 joystick [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "ON",
    "gui.blocklyText.ps2.OFF": "OFF",
    "gui.blocklyText.ps2.Triangle": "Triangle",
    "gui.blocklyText.ps2.Circle": "Circle",
    "gui.blocklyText.ps2.Cross": "Cross",
    "gui.blocklyText.ps2.Square": "Square",
    "gui.blocklyText.ps2.Left1": "Left 1",
    "gui.blocklyText.ps2.Left2": "Left 2",
    "gui.blocklyText.ps2.Left3": "Left 3",
    "gui.blocklyText.ps2.Right1": "Right 1",
    "gui.blocklyText.ps2.Right2": "Right 2",
    "gui.blocklyText.ps2.Right3": "Right 3",
    "gui.blocklyText.ps2.Up": "Up",
    "gui.blocklyText.ps2.Right": "Right",
    "gui.blocklyText.ps2.Down": "Down",
    "gui.blocklyText.ps2.Left": "Left",
    "gui.blocklyText.ps2.Select": "Select",
    "gui.blocklyText.ps2.Start": "Start",
    "gui.blocklyText.ps2.Hold": "Hold",
    "gui.blocklyText.ps2.Pressed": "Pressed",
    "gui.blocklyText.ps2.Released": "Released",
    "gui.blocklyText.ps2.CHANGE": "CHANGE",
    "gui.blocklyText.ps2.RightX": "Right X",
    "gui.blocklyText.ps2.RightY": "Right Y",
    "gui.blocklyText.ps2.LeftX": "Left X",
    "gui.blocklyText.ps2.LeftY": "Left Y",
    "gui.blocklyText.sen0236.bme280Init": "BME280 initializes",
    "gui.blocklyText.sen0236.readbme280Sensor": "Read I2C pin BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "Temperature(℃)",
    "gui.blocklyText.sen0236.Humidity": "Humidity(%rh)",
    "gui.blocklyText.sen0236.Altitude": "Altitude(m)",
    "gui.blocklyText.sen0236.Pressure": "Pressure(Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "initialize I2C LCD screen address [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "display [TEXT] in LCD line [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "display [TEXT] at LCD position X: [X] Y: [Y] ",
    "gui.blocklyText.LCD1602.displayClear": "clear the screen ",
    "gui.blocklyText.IICScan.readICCAddr": "read scanned I2C device address",
    "gui.blocklyText.mpyfile.initFile": "init file [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsList": "read file [FILENAME] as list, use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.writeFile": "write to file [FILENAME] data [WRITEDATA],use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.initFileMaixduino": "init file [ROOT] [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsListMaixduino": "read file [ROOT] [FILENAME] as list, use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.writeFileMaixduino": "write to file [ROOT] [FILENAME] data [WRITEDATA],use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.enter": "enter",
    "gui.blocklyText.mpyfile.comma": "comma",
    "gui.blocklyText.mpyfile.semicolon": "semicolon",
    "gui.blocklyText.mpyfile.space": "space",
    "gui.blocklyText.sdcard.initCS": "SD film pin [CSPIN] initialization successful?",
    "gui.blocklyText.sdcard.readFileNLine": "SD reads file [FILE] line [LINE]",
    "gui.blocklyText.sdcard.readFileAll": "SD reads file [FILE] all content",
    "gui.blocklyText.sdcard.writeFile": "SD write file [FILE] content [MSG] mode [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD delete file [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD get file [FILE] info [INFO]",
    "gui.blocklyText.sdcard.NAME": "NAME",
    "gui.blocklyText.sdcard.PATH": "PATH",
    "gui.blocklyText.sdcard.TYPE": "TYPE",
    "gui.blocklyText.sdcard.LINE": "LINE",
    "gui.blocklyText.sdcard.SIZE": "SIZE",
    "gui.blocklyText.sdcard.APPEND": "APPEND",
    "gui.blocklyText.sdcard.REPLACE": "REPLACE",
    "gui.blocklyText.sdcard.WARP": "WARP",
    "gui.blocklyText.sdcard.NOWARP": "NOWARP",
    "gui.blocklyText.sdcard.lsFile": "SD serial port prints all file information baud [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "initialize software serial [SERIALPORT] RX: [PINR] TX: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "software serial [SERIALPORT] baud rate [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "software serial [SERIALPORT] output [TEXT] [WAY], [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "Is there data to read on software serial [SERIALPORT]?",
    "gui.blocklyText.softSerialport.readSerialportData": "read software serial[SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "read [TYPE] on software serial [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "on bluetooth connected",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "on bluetooth disconnected",
    "gui.blocklyText.bluetooth.receivedKey": "when bluetooth received key [KEY] [TYPE]",
    "gui.blocklyText.bluetooth.string": "String",
    "gui.blocklyText.bluetooth.number": "Number",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "when bluetooth received data and meet [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "send string key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "send number key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.openService": "open bluetooth service",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "received data from bluetooth",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo Starts",
    "gui.blocklyText.leonardo.setBaud": "set [SERIAL] baud rate to [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.leonardo.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.leonardo.serialPort1": "SerialPort0",
    "gui.blocklyText.leonardo.serialPort2": "SerialPort1",
    "gui.blocklyText.leonardo.dataReadable": "Is there data to read on [SERIAL]?",
    "gui.blocklyText.leonardo.readSerialData": "read [SERIAL] data",
    "gui.blocklyText.arduinounoR3.initTcs34725": "Initializes the TCS34725 color sensor",
    "gui.blocklyText.arduinounoR3.functionStarts": "Uno kit program starts",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "Initializes the TMI1650 display",
    "gui.blocklyText.arduinounoR3.readdigital": "read pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "Digital Sensor",
    "gui.blocklyText.arduinounoR3.buttonModule": "Button Module",
    "gui.blocklyText.arduinounoR3.stickButton": "Rocker Button",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.arduinounoR3.readAnalog": "read pin [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "Anolog Sensor",
    "gui.blocklyText.arduinounoR3.lightSensor": "Light Sensor",
    "gui.blocklyText.arduinounoR3.soundSensor": "Sound Sensor",
    "gui.blocklyText.arduinounoR3.stickDirection": "Rocker Direction",
    "gui.blocklyText.arduinounoR3.angleSensor": "Angle Sensor",
    "gui.blocklyText.arduinounoR3.graySensor": "Grayscale Sensor",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.arduinounoR3.open": "turn on",
    "gui.blocklyText.arduinounoR3.close": "turn off",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "Digital Actuator",
    "gui.blocklyText.arduinounoR3.ledLights": "LED Lights",
    "gui.blocklyText.arduinounoR3.fanModule": "Fan Module",
    "gui.blocklyText.arduinounoR3.recordModule": "Record Module",
    "gui.blocklyText.arduinounoR3.relay": "Relay",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.arduinounoR3.writeAnalog": " set pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "move forward",
    "gui.blocklyText.arduinounoR3.backward": "move backward",
    "gui.blocklyText.arduinounoR3.turnLeft": "turn left",
    "gui.blocklyText.arduinounoR3.turnRight": "turn right",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "robot [DIR] at [SPEED] % speed ",
    "gui.blocklyText.arduinounoR3.motorForward": "move forward",
    "gui.blocklyText.arduinounoR3.motorReverse": "move backward",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "motor [MOTOR] [DIR] at [SPEED] % speed ",
    "gui.blocklyText.arduinounoR3.stopMotor": "robot (MA and MB motors) stop moving",
    "gui.blocklyText.arduinounoR3.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "Infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "infrared pin [PIN] received the [BUTTON] pressed signal？",
    "gui.blocklyText.arduinounoR3.noSound": "no sound",
    "gui.blocklyText.arduinounoR3.half": "Half",
    "gui.blocklyText.arduinounoR3.quarter": "Quarter",
    "gui.blocklyText.arduinounoR3.oneInEighth": "One-eighth",
    "gui.blocklyText.arduinounoR3.wholeBeat": "Whole Beat",
    "gui.blocklyText.arduinounoR3.doubleBeat": "Double Beat",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "set pin [PIN] MP3 module volume to [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "set pin [PIN] MP3 module playing mode to [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "Stop",
    "gui.blocklyText.arduinounoR3.play": "Play",
    "gui.blocklyText.arduinounoR3.loop": "Loop",
    "gui.blocklyText.arduinounoR3.random": "Random",
    "gui.blocklyText.arduinounoR3.pause": "Pause",
    "gui.blocklyText.arduinounoR3.previous": "Previous",
    "gui.blocklyText.arduinounoR3.next": "Next",
    "gui.blocklyText.arduinounoR3.VolumePlus": "Volume+",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "Volume-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "pin [PIN] MP3 module play the [NUM] song",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "read pin [PIN] LM35 temperature(°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "read pin [PIN] DHT11 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "red",
    "gui.blocklyText.arduinounoR3.blue": "blue",
    "gui.blocklyText.arduinounoR3.green": "green",
    "gui.blocklyText.arduinounoR3.readColorSensor": "read TCS34725 color sensor [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "four-bit nixie tube TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "turn on",
    "gui.blocklyText.arduinounoR3.tm1650Close": "turn off",
    "gui.blocklyText.arduinounoR3.clearScreen": "clear screen",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": " 4-bit nixie tube TM1650 display string [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "[STATUS] the [ORDER] decimal point of 4-bit nixie tube TM1650",
    "gui.blocklyText.arduinounoR3.bright": "brighten",
    "gui.blocklyText.arduinounoR3.dark": "darken",
    "gui.blocklyText.arduinounoR3.setLightColor": "pin [PIN] the [NUM] LED display [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "pin [PIN] the [NUM] LED R:[R] G:[G] B:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "pin [PIN] [NUM] LEDs",
    "gui.blocklyText.arduinounoR3.ledControl": "LED matrix (max7219) initial parameter DIN pin [DIN], CS pin [CS], CLK pin [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "LED matrix (max7219) show pattern [PIC]",
    "gui.blocklyText.max.maxStart": "Max starts",
    "gui.blocklyText.max.onboardButtonPressed": "onboard button [PRESSED]?",
    "gui.blocklyText.max.pressed": "pressed",
    "gui.blocklyText.max.notPressed": "not pressed",
    "gui.blocklyText.max.all": "-1 ALL",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "set RGB lights [TYPE] color [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "read sound sensor",
    "gui.blocklyText.max.playSoundEffect": "play sound effect[SOUND]",
    "gui.blocklyText.max.setDPinValue": "set digital pin [PIN] to [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "read digital pin [PIN]",
    "gui.blocklyText.max.readAPinValue": "read analog pin [PIN]",
    "gui.blocklyText.max.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.max.readLinefindingSensor": "read [DIR] line-tracking sensor",
    "gui.blocklyText.max.left": "Left",
    "gui.blocklyText.max.middle": "Middle",
    "gui.blocklyText.max.right": "Right",
    "gui.blocklyText.max.setLineFidingCarLights": "car LED lights [SWITCH]",
    "gui.blocklyText.max.on": "on",
    "gui.blocklyText.max.off": "off",
    "gui.blocklyText.max.readUltrasoundSensor": "read ulrasonic sensor(cm)",
    "gui.blocklyText.max.readLightSensor": "read [LEFTRIGHT] light sensor",
    "gui.blocklyText.max.maxSpeedMove": "MAX move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] stops",
    "gui.blocklyText.max.showUserDefineExpressions": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.max.clear": "clear",
    "gui.blocklyText.max.do": "Do",
    "gui.blocklyText.max.re": "Re",
    "gui.blocklyText.max.mi": "Mi",
    "gui.blocklyText.max.fa": "Fa",
    "gui.blocklyText.max.sou": "So",
    "gui.blocklyText.max.la": "La",
    "gui.blocklyText.max.xi": "Ti",
    "gui.blocklyText.max.highdo": "highdo",
    "gui.blocklyText.max.brake": "brake",
    "gui.blocklyText.max.rebound": "rebound",
    "gui.blocklyText.max.bullets": "bullets",
    "gui.blocklyText.max.longecho": "long echo",
    "gui.blocklyText.max.playfulending": "ending",
    "gui.blocklyText.max.yo": "yo",
    "gui.blocklyText.max.wo": "wo",
    "gui.blocklyText.max.robottalking": "robot talking",
    "gui.blocklyText.sen0160.init": "Set Hx711 weight sensor calibration value [VALUE] with Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.sen0160.readValue": "Read Hx711 weight sensor(g) Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.knowflow.readORP": "read pin [PIN] ORP sensor (mV)",
    "gui.blocklyText.knowflow.readEC": "read pin [PIN] EC sensor (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "read pin [PIN] PH sensor(V1)",
    "gui.blocklyText.knowflow.readECV2": "read pin [PIN] EC sensor (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "read pin [PIN] PH sensor(V2)",
    "gui.blocklyText.knowflow.readDO": "read pin [PIN] DO sensor (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "pin [PIN] Peristaltic Pump rotate [DIR] at [SPEED]% speed",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] pin [PIN] relay",
    "gui.blocklyText.goble.readAxis": "read GOBLE rocker [AXIS] axis",
    "gui.blocklyText.goble.buttonIsPressed": "GOBLE [BUTTON] button pressed?",
    "gui.blocklyText.eeprom.clear": "eeprom clear",
    "gui.blocklyText.eeprom.read": "eeprom read from address [ADDR]",
    "gui.blocklyText.eeprom.write": "eeprom write to address [ADDR] with data [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom get crc from address [ADDRA] to address [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom get length",
    "gui.blocklyText.tds.readAnalogPin": "read analog pin [PIN] TDS sensor(ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "two stepper motors run simultaneously   X axis : [XSET] y axis: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "stepper motor [AXIS] axis  [STEPS] steps per revolution  Speed(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "stepper motor move [ROTATEDIR] for [STEPS] steps",
    "gui.blocklyText.steppermotor.setJointSteps": "two stepper motors run simultaneously. Move [XSTEPS] steps on x-axis [ROTATEDIRX]  Move [YSTEPS] steps on y-axis [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] starts",
    "gui.blocklyText.multithread.stopThread": "stop [THREAD]",
    "gui.blocklyText.multithread.startThread": "start up [THREAD]",
    "gui.blocklyText.multithread.loop1": "thread 1",
    "gui.blocklyText.multithread.loop2": "thread 2",
    "gui.blocklyText.multithread.loop3": "thread 3",
    "gui.blocklyText.multithread.loop4": "thread 4",
    "gui.blocklyText.multithread.loop5": "thread 5",
    "gui.blocklyText.multithread.loop6": "thread 6",
    "gui.blocklyText.multithread.loop7": "thread 7",
    "gui.blocklyText.multithread.loop8": "thread 8",
    "gui.blocklyText.multithread.all": "all",
    "text2speech.setRequestSever": "set sever[SERVER]",
    "text2speech.setSever2Voice": "set server2's voic to [VOICE]",
    "text2speech.setSever2Language": "set server2's language to [LANGUAGE]",
    "text2speech.setSever2Account": "set server2's account[MODAL]",
    "text2speech.international": "server1(international)",
    "text2speech.china": "server2(China)",
    "gui.blocklyText.text2speech.server2voice0": "duxiaomei",
    "gui.blocklyText.text2speech.server2voice1": "duxiaoyu",
    "gui.blocklyText.text2speech.server2voice3": "duxiaoyao",
    "gui.blocklyText.text2speech.server2voice4": "duyaya",
    "gui.blocklyText.text2speech.server2voice106": "dubowen",
    "gui.blocklyText.text2speech.server2voice110": "duxiaotong",
    "gui.blocklyText.text2speech.server2voice111": "duxiaomeng",
    "gui.blocklyText.text2speech.server2voice103": "dumiduo",
    "gui.blocklyText.text2speech.server2voice5": "duxiaojiao",
    "gui.blocklyText.DFR0151.init": "DS1307 initialization",
    "gui.blocklyText.DFR0151.adjustTime": "Adjust the DS1307 time to [YEAR]Year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0151.getTime": "get DS1307 time [INDEX]",
    "gui.blocklyText.DFR0151.Year": "year",
    "gui.blocklyText.DFR0151.month": "month",
    "gui.blocklyText.DFR0151.date": "date",
    "gui.blocklyText.DFR0151.hour": "hour",
    "gui.blocklyText.DFR0151.minute": "minute",
    "gui.blocklyText.DFR0151.second": "second",
    "gui.blocklyText.DFR0151.week": "week",
    "gui.blocklyText.DFR0469.init": "SD2405 initialization",
    "gui.blocklyText.DFR0469.adjustTime": "adjust SD2405 time to [YEAR]year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0469.getTime": "get SD2405 time [INDEX]",
    "gui.blocklyText.DFR0126.setPin": "The audio analysis module  pins #S[SPIN] #R[RPIN] Analog pin[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "read sound frequency  band[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Initialize the NFC module interface I2C",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Initialize the NFC module interface hardware serial port Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "Initialize the NFC module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "Initialize the NFC module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "NFC module block [DATABLOCK] byte [BYTES] write [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "read NFC module block [DATABLOCK] the [BYTES] byte (number)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "read NFC module block [DATABLOCK] all data (string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "read NFC module block UID (srting)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "detected card UID: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "detected card?",
    "gui.blocklyText.DFR0126.serial1": "serial1",
    "gui.blocklyText.DFR0126.serial2": "serial2",
    "gui.blocklyText.DFR0126.serial3": "serial3",
    "gui.blocklyText.sen0245.init": "Initialize the VL53L0X laser ranging sensor",
    "gui.blocklyText.sen0245.setVL53L0X": "VL53L0X accuracy[PRECISION] measurement mode[VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "get VL53L0X laser ranging value(mm)",
    "gui.blocklyText.SEN0245.LOW": "Low(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "High(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "Single",
    "gui.blocklyText.SEN0245.Continuous": "Continuous",
    "gui.blocklyText.serialMp3.initSerialMp3": "Init serial MP3 module interface [SERIALPORT] RX(green) [RX] TX(blue) [TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "set serial MP3 module playing mode to [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "Set serial MP3 module playing [VALUE] song",
    "gui.blocklyText.serialMp3.setMP3Volume": "Set serial MP3 module volume to [VOLUME]%",
    "gui.blocklyText.serialMp3.play": "play",
    "gui.blocklyText.serialMp3.pause": "pause",
    "gui.blocklyText.serialMp3.end": "end",
    "gui.blocklyText.serialMp3.prev": "prev",
    "gui.blocklyText.serialMp3.next": "next",
    "gui.blocklyText.serialMp3.volumeUp": "volume up",
    "gui.blocklyText.serialMp3.volumeDown": "volume down",
    "gui.blocklyText.serialMp3.playlist": "playlist",
    "gui.blocklyText.serialMp3.insertTracks": "insert tracks",
    "gui.blocklyText.serialMp3.volume": "volume",
    "gui.blocklyText.servo360.setServo360": "pin [PIN] servo rotate [DIR] at [SPEED] % speed",
    "gui.blocklyText.servo360.CW": "CW",
    "gui.blocklyText.servo360.CCW": "CCW",
    "gui.blocklyText.TCS34725.initTcs34725": "Initializes the TCS34725 color sensor",
    "gui.blocklyText.TCS34725.readTcs34725": "read TCS34725 color sensor [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "Turn GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "Don\"t turn GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "show text[TEXT] in [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "text scroll [DIR]",
    "gui.blocklyText.regEmotion8_16.left": "left",
    "gui.blocklyText.regEmotion8_16.right": "right",
    "gui.blocklyText.regEmotion8_16.lightAll": "show all dot matrix in [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "show coordinates of point (x:[XAXIS], y:[YXAXIS]) in [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "clear screen",
    "gui.mainHeader.err": "!Fehler: Die gleiche Programm-Header [%1] können nicht gleichzeitig verwendet werden. Bitte lösche die duplizierte Block.",
    "gui.mainHeader.help": "!Tipp: Wenn mehrere Programme gleichzeitig ausgeführt werden müssen, verwende die \"multi-threading function\" in \"Extension\", Details der Hilfe-Dokumentation.",
    "gui.threadHeader.err1": "!Fehler: Die Programm-Header [%1] können nicht gleichzeitig verwendet werden. Sie gehören verschiedenen Kits oder Platten.",
    "gui.threadHeader.err2": "!Fehler Tipp: Die gleichen Ereignis-Header [%1] können nicht gleichzeitig verwendet werden. Bitte lösche den duplizierten Block.",
    "gui.blocklyText.mqtt.whenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT message",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi connect to account:[ACCOUNT] password:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi connected?",
    "gui.blocklyText.wifi.wifiDisConnected": "disconnect Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP mode:[ACCOUNT2] password:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi get soft ap ip",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi get [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Error code",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "ntp set network time, time zone [TIMEZONE] timer server [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "ntp get local time [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "UTCWest12_t",
    "gui.blocklyText.ntp.UTCWest11_t": "UTCWest11_t",
    "gui.blocklyText.ntp.UTCWest10_t": "UTCWest10_t",
    "gui.blocklyText.ntp.UTCWest9_t": "UTCWest9_t",
    "gui.blocklyText.ntp.UTCWest8_t": "UTCWest8_t",
    "gui.blocklyText.ntp.UTCWest7_t": "UTCWest7_t",
    "gui.blocklyText.ntp.UTCWest6_t": "UTCWest6_t",
    "gui.blocklyText.ntp.UTCWest5_t": "UTCWest5_t",
    "gui.blocklyText.ntp.UTCWest4_t": "UTCWest4_t",
    "gui.blocklyText.ntp.UTCWest3_t": "UTCWest3_t",
    "gui.blocklyText.ntp.UTCWest2_t": "UTCWest2_t",
    "gui.blocklyText.ntp.UTCWest1_t": "UTCWest1_t",
    "gui.blocklyText.ntp.UTCMiddle_t": "UTCMiddle_t",
    "gui.blocklyText.ntp.UTCEast1_t": "UTCEast1_t",
    "gui.blocklyText.ntp.UTCEast2_t": "UTCEast2_t",
    "gui.blocklyText.ntp.UTCEast3_t": "UTCEast3_t",
    "gui.blocklyText.ntp.UTCEast4_t": "UTCEast4_t",
    "gui.blocklyText.ntp.UTCEast5_t": "UTCEast5_t",
    "gui.blocklyText.ntp.UTCEast6_t": "UTCEast6_t",
    "gui.blocklyText.ntp.UTCEast7_t": "UTCEast7_t",
    "gui.blocklyText.ntp.UTCEast8_t": "UTCEast8_t",
    "gui.blocklyText.ntp.UTCEast9_t": "UTCEast9_t",
    "gui.blocklyText.ntp.UTCEast10_t": "UTCEast10_t",
    "gui.blocklyText.ntp.UTCEast11_t": "UTCEast11_t",
    "gui.blocklyText.ntp.UTCEast12_t": "UTCEast12_t",
    "gui.blocklyText.ntp.year": "year",
    "gui.blocklyText.ntp.month": "month",
    "gui.blocklyText.ntp.date": "date",
    "gui.blocklyText.ntp.hour": "hour",
    "gui.blocklyText.ntp.minute": "minute",
    "gui.blocklyText.ntp.second": "second",
    "gui.blocklyText.ntp.week": "week",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "wenn udp server recv [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "udp server port setzen [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "udp server sendet broadcast [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "wenn udp client recv [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "udp-Client IP [IPADDR] auf Port [PORT] setzen",
    "gui.blocklyText.udp.udpClientSendBroadcast": "udp-client sendet broadcast [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "Nachricht",
    "gui.blocklyText.udp.ClientRecvedMsg": "Nachricht",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] add key [KEY] value [VALUE]",
    "gui.blocklyText.http.requestHeader": "HTTP-Anfrage-Header hinzufügen [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] URL [URL] timeout [TIMEOUT] ms",
    "gui.blocklyText.http.readMessage": "HTTP lese [LINE] Zeile Nachricht",
    "gui.blocklyText.http.param": "PARAM",
    "gui.blocklyText.http.header": "HEADER",
    "gui.blocklyText.http.oneline": "ONE",
    "gui.blocklyText.http.allline": "ALLE",
    "gui.blocklyText.mqtt.mqttConnect": "MQTT verbinden",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT verbunden?",
    "gui.blocklyText.mqtt.acceptance.meaage": "Wenn [TOPIC] die Nachricht [MSG] empfangen hat",
    "gui.blocklyText.mqtt.mqttSend": "MQTT Nachricht [MSG] an die Cloud-Plattform [TOPIC] sendet",
    "gui.blocklyText.mqtt.initial": "MQTT initial [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「IFLYTEK」synthetic audio [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「IFLYTEK」identify audio [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「IFLYTEK」identify result",
    "gui.blocklyText.iflytek.CommunicationText": "text",
    "gui.blocklyText.iflytek.CommunicationTransfer": "transfer to audio file",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "audio file to be identified",
    "gui.blocklyText.mqtt.mqttDisconnect": "disconnect MQTT",
    "gui.blocklyText.internet.initRequestsType": "requests type",
    "gui.blocklyText.internet.initRequestsAddr": "requests address",
    "gui.blocklyText.internet.initAddDict": "init dict",
    "gui.blocklyText.internet.postFileAddr": "post file address",
    "gui.blocklyText.internet.postFilePath": "post file path",
    "gui.blocklyText.internet.postFileMime": "post file mime type",
    "gui.blocklyText.internet.postFileAddDict": "init dict",
    "gui.blocklyText.internet.internetGetpostInit": "internet initial [SETTING]",
    "gui.blocklyText.internet.initTypeAddr": "internet requests set type [POST] address [ADDR] headers:[HEADERS] params:[URL] data:[DICT]",
    "gui.blocklyText.internet.internetPostFile": "internet post file [SETTING]",
    "gui.blocklyText.internet.postFileAddrType": "POST file requests address [ADDR] path [PATH] MIME type [TYPE]",
    "gui.blocklyText.internet.postFileDict": "POST file set dict data [DICT] and send file",
    "gui.blocklyText.internet.responseContent": "response content form [DATATYPE]",
    "gui.blocklyText.internet.responseAttributes": "response attributes [ATTRIBUTES]",
    "gui.blocklyText.internet.createSocket": "create socket [SOKHANDEL] with af [AFINET] and type [SOCKTYPE]",
    "gui.blocklyText.internet.setSocket": "create socket [SOKHANDEL] with af [SOCKTYPE] and type [TYPENUM]",
    "gui.blocklyText.internet.closeSocket": "socket [SOKHANDEL] close",
    "gui.blocklyText.internet.bindSocket": "socket [SOKHANDEL] bind with address [SOCKADDR] port [SOCKPORT]",
    "gui.blocklyText.internet.listenSocket": "listen socket [SOKHANDEL] backlog number [BACKLOGNUM]",
    "gui.blocklyText.internet.recvReturnTuple": "socket [SOKHANDEL] receive connect requests and return socket tuple",
    "gui.blocklyText.internet.parseReturnIp": "parse host [HOSTADDR] port [SOCKPORT] and return ip",
    "gui.blocklyText.internet.connectHost": "socket [SOKHANDEL] connect host [HOSTADDR] port [SOCKPORT]",
    "gui.blocklyText.internet.sendReturnBytes": "socket [SOKHANDEL] send [SOCKMSG] and return sended bytes",
    "gui.blocklyText.internet.continueSendReturnBytes": "socket [SOKHANDEL] continue send [SOCKMSG] and return sended bytes",
    "gui.blocklyText.internet.sendToAddr": "socket [SOKHANDEL] send [SOCKMSG] to address [SOCKADDR]",
    "gui.blocklyText.internet.recvReturnRecvedObject": "socket [SOKHANDEL] receive and return received object max data is [MAXNUM]",
    "gui.blocklyText.internet.recvReturnRecvedTuple": "socket [SOKHANDEL] receive and return received tuple max data is [MAXNUM]",
    "gui.blocklyText.internet.setTimeout": "socket [SOKHANDEL] set timeout [TIMEOUT]",
    "gui.blocklyText.internet.setMode": "socket [SOKHANDEL] set mode [SOCKMODE]",
    "gui.blocklyText.internet.readReturnObject": "socket [SOKHANDEL] read [READBYTE] and return bytes object",
    "gui.blocklyText.internet.text": "text",
    "gui.blocklyText.internet.binary": "binary",
    "gui.blocklyText.internet.dictionary": "dictionary",
    "gui.blocklyText.internet.block": "block",
    "gui.blocklyText.internet.nonblock": "non-block",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 initializes",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 read altitude(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 read pressure(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 read temperature(℃)",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 measurement [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "object",
    "gui.blocklyText.sen0206.environment": "environment",
    "gui.blocklyText.sen0206.celsius": "celsius",
    "gui.blocklyText.sen0206.fahrenheit": "fahrenheit",
    "gui.blocklyText.sen0253.init": "10DOF Initial pin I2C_ADDR level [LEVEL]",
    "gui.blocklyText.sen0253.reset": "10DOF Sensor reset",
    "gui.blocklyText.sen0253.power": "10DOF Set power mode [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF Get euler angles [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF Get qua [AXIS] axis",
    "gui.blocklyText.sen0253.getAcc": "10DOF Get accelerometer [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF Get magnetometer [AXIS] axis(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF Get linear acceleration [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF Get gravit capacity [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF Get angular speed [AXIS] axis",
    "gui.blocklyText.sen0253.getBmp280": "10DOF Get [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "Low",
    "gui.blocklyText.sen0253.HIGH": "High",
    "gui.blocklyText.sen0253.normal": "Normal",
    "gui.blocklyText.sen0253.lowPower": "Low power",
    "gui.blocklyText.sen0253.suspend": "Suspend",
    "gui.blocklyText.sen0253.Roll": "Roll(°)",
    "gui.blocklyText.sen0253.Pitch": "Pitch(°)",
    "gui.blocklyText.sen0253.Yaw": "Yaw(°)",
    "gui.blocklyText.sen0253.temperature": "Temperature",
    "gui.blocklyText.sen0253.elevation": "Elevation",
    "gui.blocklyText.sen0253.airPressure": "AirPressure",
    "gui.blocklyText.sen0290.init": "lighting sensor set irq pin [IRQPIN] and i2c address [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "lighting sensor lightning sensor iic init ok？",
    "gui.blocklyText.sen0290.manualCal": "lighting sensor set capacitance [CAPACITANCE] mode [MODE] dist [DIST]",
    "gui.blocklyText.sen0290.indoors": "indoors",
    "gui.blocklyText.sen0290.outdoors": "outdoors",
    "gui.blocklyText.sen0290.disturberDis": "disturber disable",
    "gui.blocklyText.sen0290.disturberEn": "disturber enable",
    "gui.blocklyText.sen0290.errMsg": "error msg",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "lighting sensor get status",
    "gui.blocklyText.sen0290.GetLightningDistKm": "lighting sensor get lightning dist(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "lighting sensor get strike energy raw",
    "gui.blocklyText.sen0291.sen0291Init": "The power meter initializes the I2C address as [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "The power meter reads the load [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "The actual measured current of the power meter calibration is [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "Voltage (mV)",
    "gui.blocklyText.sen0291.electric": "Electric (mA)",
    "gui.blocklyText.sen0291.power": "Power (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "Shunt Voltage (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.weather.weatherGetWithCity": "[WEATHER] in [CITY] city [COUNTRY] province",
    "gui.blocklyText.weather.temperaturHigh": "maximum temperature(℃)",
    "gui.blocklyText.weather.temperaturLow": "minimum temperature(℃)",
    "gui.blocklyText.weather.weather": "weather",
    "gui.blocklyText.weather.weatherInit": "weather server Initialize [SETTING]",
    "gui.blocklyText.weather.serverAddr": "server address",
    "gui.blocklyText.weather.appid": "appid",
    "gui.blocklyText.weather.appsecret": "appsecret",
    "gui.blocklyText.weather.serverAddrTianqiAPI": "tianqi API(www.tianqiapi.com)",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "Server-Parameter setzen [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "update tag [TAG] value [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "Tag lesen [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "Tag löschen [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "Tag zählen",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "Netzwerkverbindungstest",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "Tag suchen ab Startnummer [NUMS] Variable zählen [NUMV] Tag [TAG] Typ [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "Tag",
    "gui.blocklyText.tinywebdb.value": "Wert",
    "gui.blocklyText.tinywebdb.both": "beide",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "HongKong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.getSpeech": "Erkennungsergebnis",
    "speech.setRecordTime": "Aufnahmezeit auf [TIME] einstellen",
    "speech.stopRecord": "Spracherkennung stoppen",
    "speech.controlSonogram": "[CONTROL] Sonogramm",
    "speech.setServer": "Spracherkennungsserver auf [SERVER] umschalten",
    "speech.show": "anzeigen",
    "speech.hide": "ausblenden",
    "speech.server1": "Server1",
    "speech.server2": "Server2",
    "gui.blocklyText.mp3.initMp3": "Init MP3 module interface [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "set MP3 module volume to [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "set MP3 module playing mode to [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "set MP3 module playing [NUM] song",
    "gui.blocklyText.tel0094.GPSInit": "GPS initializes as [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS gets the location [LATLON](°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "GPS gets the location [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "Longitude",
    "gui.blocklyText.tel0094.LONGITUDE": "Latitude",
    "gui.blocklyText.tel0094.YEAR": "Year",
    "gui.blocklyText.tel0094.MONTH": "Month",
    "gui.blocklyText.tel0094.DAY": "Day",
    "gui.blocklyText.tel0094.HOUR": "Hour",
    "gui.blocklyText.tel0094.MINUTE": "Minute",
    "gui.blocklyText.tel0094.SECOND": "Second",
    "gui.blocklyText.tel0094.ALL": "All",
    "gui.blocklyText.tel0094.GPSGet": "GPS gets [GPS]",
    "gui.blocklyText.tel0094.getState": "Status",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "Latitude hemisphere",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "Longitude hemisphere",
    "gui.blocklyText.tel0094.getGroundSpeed": "Ground speed",
    "gui.blocklyText.tel0094.getSatellitesCount": "Number of satellites",
    "gui.blocklyText.tel0094.getAltitude": "Altitude",
    "gui.blocklyText.tel0094.getPdop": "Position accuracy",
    "gui.blocklyText.tel0094.getHdop": "Horizontal accuracy",
    "gui.blocklyText.tel0094.getVdop": "Vertical accuracy",
    "gui.blocklyText.tel0094.getPositioningMode": "Positioning mode",
    "gui.blocklyText.microIoT.displayInLine": "OLED show line [LINE] text [TEXT]",
    "gui.blocklyText.microIoT.displayInXY": "OLED show [TEXT] x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED show image [IMAGE] x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED clear",
    "gui.blocklyText.microIoT.pointDrawing": "OLED draw point x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED set line width [LINEWIDTH]",
    "gui.blocklyText.microIoT.lineDrawing": "OLED draw line start x [X1] y [Y1] end x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED draw circle [FILL] circle center x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED draw box [FILL] start x [X] y [Y] width [WIDTH] height [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB set brightness to [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "RGB lights are all off",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "RGB lights [NUM1] through [NUM2] show colors [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "RGB lights [NUM1] to [NUM2] show gradients hue from [COLOR1] to [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "RGB loop by [NUM] pixel",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "RGB lights [NUM1] through [NUM2] show colors [COLOR]",
    "gui.blocklyText.motorIot.all": "ALL",
    "gui.blocklyText.microIot.servo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "MQTT initial parameter [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.microIot.MQTTMsg": "MQTT message",
    "gui.blocklyText.microTouch.whenBtnPress": "when keys [KEY] press",
    "gui.blocklyText.microTouch.Any": "Any",
    "gui.blocklyText.microTouch.isButtonPressed": "Keys [KEY] is pressed?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "Normal mode read keys",
    "gui.blocklyText.microTouch.readKeyMathMode": "Math mode read [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "Number key(num)",
    "gui.blocklyText.microTouch.Function": "Function key(str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] vibration motor",
    "gui.blocklyText.microTouch.turnOn": "Turn on",
    "gui.blocklyText.microTouch.shutDown": "Shut down",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "Sound intensity",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "Natural light",
    "gui.blocklyText.microNaturalScience.readColour": "Read [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "Red value",
    "gui.blocklyText.microNaturalScience.G": "Green value",
    "gui.blocklyText.microNaturalScience.B": "Blue value",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "UV intensity (μW/cm²)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "Turn on",
    "gui.blocklyText.microNaturalScience.off": "Turn off",
    "gui.blocklyText.microNaturalScience.setTDSK": "Set the TDS electrode constant k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "Obtain the TDS electrode constant k",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS(ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "Get [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "Water temperature (°C)",
    "gui.blocklyText.microNaturalScience.Temperature": "Temperature(℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "Humidity(%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "Altitude(m)",
    "gui.blocklyText.microNaturalScience.Pressure": "Pressure(Pa)",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB Matrixpunkt einstellen X [X] Y [Y] Farben anzeigen [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 can not be placed in the interrupt event, may cause dht11 reading abnormal",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 can not be placed in the interrupt event, may cause dht22 reading abnormal",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 cannot be placed in an interrupt event, which may result in abnormal pm2.5 readings",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 reads data, which may cause led dot matrix initialization failure",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 reads data, which may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "the mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to be set to fail",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "the mp3 setting playback mode cannot be placed in an interrupt event, which may cause the playback mode to fail",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "Mp3 settings playback can not be placed in the interrupt event, may cause the settings to play failed",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "Serial port mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "The serial port mp3 playback mode cannot be placed in an interrupt event, which may cause the setup play mode to be lost",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "Serial port mp3 playback cannot be placed in an interrupt event, which may cause playback to fail",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "The serial port mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to fail",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloq's mqtt initialization soft serial port cannot be placed in an interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq sends a message that cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq read mqtt message can not be placed in the interrupt event, may cause the reception to fail",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "The initial soft serial port of http of obloq cannot be placed in the interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "The get request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "Obloq http post request cannot be placed in an interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "The put request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "Infrared reception cannot be placed in an interrupt event, which may cause reception failure",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "Infrared reception may cause the LED dot matrix initialization to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "Infrared reception may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "Infrared transmission cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause initialization to fail",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause setup pins to fail",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the pin to fail to fail",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause display color to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause color adjustment failure",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the move to fail",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause loop movement to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause the display histogram to fail",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "Led dot matrix can not be placed in the interrupt event, may cause initialization failure",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "The led dot matrix cannot be placed in an interrupt event, which may cause the display pattern to fail",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "The soft serial output cannot be placed in an interrupt event, which may cause the output to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "The data read by the soft serial port cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Soft serial port output data and read data colleague use, may cause data abnormalities",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "The soft serial port read integer cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "Soft serial port output data and reading integer colleague use may cause abnormal reading data",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "When dht11 and PM2.5 are in two threads, dht11 reads may fail if pm2.5 reads too frequently. It is recommended to reduce the pm2.5 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "When dht11 and ds18b20 are in two threads respectively, if ds18b20 reads too frequently, dht11 may fail to read, it is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "When dht11 and mp3 are initialized in two threads, mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "When dht11 and mp3 set volume are in two threads respectively, mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "When dht11 and mp3 setup modes are in two threads respectively, mp3 setup mode may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "When dht11 and mp3 player are in two threads, mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "When dht11 and serial mp3 are initialized in two threads, serial mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "When dht11 and serial mp3 control module are in two threads respectively, the mp3 control module may cause dht11 reading errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "When dht11 and serial mp3 player are in two threads, serial mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "When dht11 and serial mp3 set volume are in two threads respectively, serial mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "When dht11 and RGB are initialized in two threads, RGB initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "When dht11 and RGB set brightness in two threads, RGB set brightness may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "When dht11 and RGB are extinguished in two threads, RGB extinction may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "When dht11 and RGB display colors in two threads, RGB display colors may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "When dht11 and RGB display a gradient in two threads, respectively, RGB display a gradient may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "When dht11 and RGB move pixels in two threads, RGB move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "When dht11 and RGB loop move pixels in two threads, respectively, RGB loop move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "When dht11 and RGB display columns in two threads respectively, RGB display bars may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "When dht11 and soft serial send are in two threads respectively, soft serial send may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "When dht11 and obloq MQTT are initialized in two threads respectively, obloq MQTT initialization may cause dht11 to read errors. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "When dht11 and obloq MQTT send messages in two threads respectively, obloq MQTT send messages that may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "When dht11 and obloq HTTP put requests are in two threads, it can cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "When dht11 and obloq HTTP initialization are in two threads respectively, it may cause dht11 reading error. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "When dht11 and obloq HTTP get requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "When dht11 and obloq HTTP post requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "When dht11 and infrared emission are in two threads respectively, it may cause errors in reading dht11. It is suggested to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "When ds18b20 and PM2.5 are in two threads respectively, it may cause PM2.5 reading error, so it is suggested to reduce ds18b20 frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "When ds18b20 and obloq MQTT are initialized in two threads respectively, MQTT initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "When ds18b20 and obloq HQTT put requests are in two threads respectively, the put request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "When ds18b20 and obloq HTTP initialization are in two threads respectively, the HTTP initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "When ds18b20 and obloq HQTT get requests are in two threads respectively, the get request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "When ds18b20 and obloq HQTT post requests are in two threads respectively, the post request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Reading ds18b20 may result in no infrared data, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Reading ds18b20 may cause errors in receiving data by soft serial port, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "When PM2.5 read and obloq MQTT initialization are carried out in two threads respectively, MQTT initialization failure or PM2.5 read error may be caused. It is recommended to avoid simultaneous execution of MQTT initialization and PM2.5 read",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "When PM2.5 reading and obloq MQTT send messages in two threads respectively, PM2.5 reading errors may be caused. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "When PM2.5 read and obloq HTTP put requests are in two threads, it may cause put request failure or PM2.5 read error. It is recommended to reduce put request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "When PM2.5 reads and obloq HTTP initializations are carried out in two threads respectively, HTTP initializations may fail or PM2.5 reads may go wrong. It is recommended to avoid simultaneous execution of HTTP initializations and PM2.5 reads",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "When PM2.5 read and obloq HTTP get requests are in two threads respectively, it may cause get request failure or PM2.5 read error. It is recommended to reduce get request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "When PM2.5 read and obloq HTTP post request are in two threads respectively, it may cause post request failure or PM2.5 read error. It is recommended to reduce the frequency of post request or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "When PM2.5 reading and MP3 initialization are carried out in two threads respectively, PM2.5 reading may fail. It is recommended to avoid the simultaneous execution of PM2.5 reading and MP3 initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "When PM2.5 reading and MP3 setting volume are in two threads respectively, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "When PM2.5 reading and serial MP3 player are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "When PM2.5 reading and serial MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "When PM2.5 reading and serial MP3 setting volume are respectively in two threads, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "When PM2.5 reading may cause infrared reception failure, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "When PM2.5 reading and RGB initialization are carried out in two threads respectively, PM2.5 reading errors may be caused. It is recommended to avoid simultaneous execution of PM2.5 reading and RGB initialization",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "When PM2.5 reading and RGB setting brightness are respectively in two threads, PM2.5 reading error may be caused. It is recommended not to adjust brightness too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "When PM2.5 reading and RGB extinguishing are in two threads, PM2.5 reading errors may be caused. It is recommended not to extinguish RGB too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "When PM2.5 reading and RGB display colors are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to switch colors too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "When PM2.5 reading and RGB display the gradient in two threads respectively, PM2.5 reading error may be caused, it is recommended not to switch the gradient too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "When PM2.5 reading and RGB moving pixels are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to move pixels too frequently",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "Using Wi-Fi may cause the onboard rgb to display an abnormal color",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "Udp uses Wi-Fi, may cause the onboard rgb display color anomaly",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "Using Wi-Fi may cause the onboard rgb to set a three-color exception",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "Udp uses Wi-Fi, may cause the onboard rgb to set a three-color exception",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "Using Wi-Fi may cause the onboard rgb to set abnormal brightness",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "Udp uses Wi-Fi, may cause the onboard rgb to set the brightness abnormally",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "Using Wi-Fi may cause the onboard rgb to get abnormal brightness",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "Udp uses Wi-Fi, which may cause the onboard rgb to get abnormal brightness",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to be initialized abnormally",
    "gui.blocklyText.prompt.telloesp32.rgbnomal_rgbeffects": "When using the breathing light special effect block program, the LED light can no longer be set separately, please remove one of the blocks.",
    "gui.blocklyText.prompt.telloesp32.matrixnomal_matrixeffects": "Special effects are implemented for background threads, which conflict with the direct setting of matrix.",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 initialization to be abnormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "Using Wi-Fi may cause serial port mp3 to play track failed",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to play tracks failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "Using Wi-Fi may cause the serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "Using Wi-Fi may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp uses Wi-Fi, which may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "When PM2.5 read and RGB loop move pixels in two threads respectively, PM2.5 read errors may be caused. It is recommended not to execute loop move pixels too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "When PM2.5 reading and RGB display columns are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to execute RGB display columns too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "When PM2.5 reading and led dot array initialization are carried out in two threads respectively, led dot array initialization may go wrong. It is recommended to avoid the simultaneous execution of PM2.5 reading and led dot array initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "When PM2.5 reading and led dot array display are in two threads respectively, led dot array display may be wrong, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5 reading may cause errors in PM2.5 reading. It is recommended not to send data too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5 reading may cause soft serial port reading failure. It is recommended not to read PM2.5 too frequently",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 may not receive infrared data, it is recommended to reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "When MP3 and obloq MQTT initialization is in two threads, it may cause MQTT initialization to fail. It is recommended to avoid simultaneous MQTT initialization and MP3 initialization",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "When MP3 and obloq HTTP put requests are in two threads, it is possible to cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "When MP3 and obloq HTTP initializations are in two threads, HTTP initializations may fail. It is recommended to avoid both HTTP initializations and MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "When MP3 and obloq HTTP get requests are in two threads, it may cause get requests to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "When MP3 and obloq HTTP post requests are in two threads, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 may cause soft serial port read failure, it is recommended not to use MP3 too often",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "Serial MP3 may not receive infrared data, it is recommended to reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "When serial port MP3 and obloq MQTT initialization are in two threads respectively, MQTT initialization may fail. It is recommended to avoid simultaneous execution of MQTT initialization and serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "When serial MP3 and obloq HTTP put requests are in two threads, it may cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "HTTP initialization may fail when serial MP3 and obloq are initialized in two threads. It is recommended to avoid both HTTP initialization and serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "When the serial port MP3 and obloq HTTP get requests are in two threads respectively, it may cause the get request to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "When the serial port MP3 and obloq HTTP post requests are in two threads respectively, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of the serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "Serial port MP3 may cause soft serial port read failure, it is recommended not to use serial port MP3 too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving errors, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "Soft serial obloq may cause errors in infrared receiving data, so it is recommended to avoid sending messages frequently by obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "The initialization of RGB band may lead to the error of obloq receiving messages. It is recommended to avoid frequent use of RGB band",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Setting the brightness of the light belt may cause an error in obloq receiving messages. It is recommended to avoid frequent brightness adjustment",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB belt extinguish may lead to soft serial obloq receiving messages error, it is recommended to avoid frequent extinguish the belt",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB light band display may cause obloq to receive messages error, it is recommended to avoid frequent display of color",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "The display of gradient on the RGB band may cause errors in obloq receiving messages. It is recommended to avoid frequent display of gradient",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB band moving pixels may cause soft serial obloq to receive messages incorrectly",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "The RGB band loops the pixels around and may cause the soft serial obloq to receive messages out",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB ribbon display bar may cause soft serial obloq receive message error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "When soft serial obloq and led dot array initialization are carried out in two threads respectively, it may cause led dot array initialization error. It is recommended to avoid simultaneous execution of soft serial obloq and led dot array initialization",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "When soft serial obloq and led dot array display are respectively in two threads, it may cause led dot array display error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "Soft serial port sending may cause obloq reading error, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "Soft serial port obloq send may cause soft serial port read failure, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "Infrared emission may cause infrared reception of no data, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "When infrared emission may cause soft serial port reading failure, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "The initialization of RGB band may result in no infrared reception of data",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "The brightness setting of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent brightness setting of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "The RGB lamp belt is off, which may cause the infrared data can not be received",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "The moving pixel of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent moving pixel of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "Infrared reception may cause led initialization failure",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "Infrared reception may cause led display errors",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "Soft serial port sending may cause infrared data can not receive, it is recommended to avoid frequent sending soft serial port",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB band may cause soft serial port reading failure, so it is recommended to reduce the use frequency of RGB band",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Data received by soft serial port may cause led dot matrix display error",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "The sending of soft serial port may lead to the error of receiving data of soft serial port. It is recommended to avoid the execution of sending data and receiving data at the same time",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "The initialization of serial port MP3 may lead to errors in infrared receiving data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "MP3 serial port setting playing mode may cause errors in infrared receiving data, so it is recommended to avoid setting playing mode too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "Serial MP3 player may lead to infrared data errors, it is recommended to avoid switching songs too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "The volume setting of serial port MP3 may cause errors in infrared receiving data. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "Initialization of max7219 may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "Max7219 display images may cause errors in infrared received data, so it is recommended to avoid displaying images too frequently",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "The MP3 module of serial port may cause the pin interrupt cannot be triggered. It is recommended to avoid using MP3 module of serial port too often",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "The max7219 display module may cause pin interrupts not to be triggered. It is recommended to avoid using the max7219 display too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "DHT reading may lead to errors in infrared receiving messages. It is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "Ds18b20 reading may cause errors in soft external receiving messages, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5 reading may cause errors in receiving messages outside the software. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "Mp3 initialization read may cause infrared receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "The volume setting of mp3 may cause the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "Mp3 setting mode may cause errors in infrared receiving messages. It is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "Mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "Setting mode of serial port mp3 may cause error of infrared receiving message, it is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "Serial mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Setting the volume of serial port mp3 may lead to the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "Infrared emission may cause errors in infrared receiving messages, so it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "The initialization of the RGB band may lead to errors in receiving infrared messages. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving infrared messages. It is recommended to avoid setting the brightness too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB band display may cause errors in infrared receiving messages. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "Gradient discoloration of RGB lamp band display may lead to error of infrared receiving message. It is recommended to avoid displaying gradient discoloration too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Moving pixels with RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid moving pixels too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "The circular movement of pixels with the RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB ribbon display columnar may cause infrared receiving message error, it is recommended to avoid displaying columnar too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Infrared receiving messages may cause initialization failure of max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Infrared receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "Soft serial printing may cause errors in infrared receiving messages, so it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "Soft serial port obloq MQTT initialization may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "Soft serial obloq put request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "Soft serial port obloq HTTP initialization may lead to infrared receiving data errors, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "Soft serial obloq get request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "Soft serial obloq post request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "The clear display of RGB lamp band may cause errors in receiving messages in the soft infrared. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "DHT reading may lead to soft serial receiving message error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "Ds18b20 reading may cause the soft serial port to receive the message error, it is recommended to reduce the ds18b20 reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5 reading may cause errors in receiving messages by soft serial port. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "Mp3 initialization reads may result in a soft serial receiving message error, and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Setting the volume of mp3 may cause the error of receiving the message on the soft serial port. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "Mp3 setting mode may cause the soft serial port to receive the message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "Mp3 playback may lead to soft serial receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "Mp3 setting mode of serial port may cause error of receiving messages by soft serial port. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "Mp3 playing on serial port may cause errors in receiving messages on soft serial port. It is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message from soft serial port. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "Infrared emission may cause errors in receiving messages by soft serial port. It is recommended to avoid sending infrared messages too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "The initialization of the RGB band may result in an error in receiving messages from the soft serial port. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving messages from the soft serial port. It is recommended to avoid setting the brightness too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "Clear display of RGB light band may cause error of receiving message by soft serial port. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB band display may cause errors in receiving messages from the soft serial port. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB lamp tape display gradient may cause message error in soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause the soft serial port to receive messages incorrectly. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB light belt circular movement of pixels may cause the soft serial port to receive messages error, it is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB ribbon display columnar may cause the soft serial port to receive the message error, it is recommended to avoid performing display columnar too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "Soft serial receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "Soft serial receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "Soft serial printing may cause error in receiving messages from the soft serial port. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "DHT reads may cause pin interrupt triggering failure. It is recommended to reduce the DHT read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "Ds18b20 read may cause pin interrupt trigger failure, it is recommended to reduce ds18b20 read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5 reading may cause pin interrupt trigger failure. It is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Mp3 initialization reads may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "Mp3 setting volume may cause pin interrupt trigger failure. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "Mp3 setting mode may cause pin interrupt triggering failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "Mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "Serial port mp3 initialization read may cause pin interrupt trigger failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "Setting mode of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "Serial mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Setting the volume of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "Infrared emission may cause pin interrupt trigger failure, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB ribbon initialization may cause pin interrupt trigger failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Setting brightness of RGB band may cause pin interrupt trigger failure. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB strip clear display may cause pin interrupt trigger failure, it is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB tape display may cause pin interrupt trigger failure. It is recommended to avoid RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "The RGB tape display of the gradient may cause the pin interrupt trigger to fail. It is recommended to avoid displaying the gradient too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB band moving pixels may cause pin interrupt triggering failure and it is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB light belt loop move pixel may cause pin interrupt trigger failure, it is recommended to avoid too often loop move pixel",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB ribbon display bar may cause pin interrupt trigger failure. It is recommended to avoid displaying bar too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "Pin interruption may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "Pin interruption may cause max7219 to display incorrectly",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "Soft serial printing may cause pin interrupt trigger failure, it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "Soft serial port obloq MQTT initialization may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "Soft serial obloq put requests may cause pin interrupt triggering failure and it is recommended to avoid requesting data too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "Soft serial obloq HTTP initialization may cause pin interrupt triggering failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "Soft serial obloq get request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "Soft serial obloq post request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "DHT reading may lead to soft serial obloq receiving messages error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "Ds18b20 reading may cause obloq to receive messages incorrectly, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5 reading may cause error in obloq receiving messages. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "Mp3 initialization read may result in soft serial obloq receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Mp3 setting volume may result in soft serial obloq receiving message error. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "Mp3 setting mode may lead to soft serial obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "Mp3 playback may lead to soft serial obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port obloq receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "Serial port mp3 setting mode may cause soft serial port obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "Serial port mp3 player may lead to soft serial port obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message of soft serial port obloq. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving messages error, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Soft serial bloq sending messages may lead to infrared reception errors, it is recommended to avoid obloq sending messages too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB ribbon initialization may cause soft serial obloq to receive an error message, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Setting brightness of RGB light belt may cause error of obloq receiving messages. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Clear display of RGB light band may cause error of obloq receiving message. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB tape display may result in an error in obloq receiving messages. It is recommended to avoid performing RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB lamp tape display gradient may cause message error in obloq soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause an error in obloq receiving messages. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB light belt circular movement of pixels may lead to soft serial port obloq receive messages error, it is recommended to avoid too often loop movement of pixels",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB ribbon display columnar may cause soft serial obloq receive messages error, it is recommended to avoid too often display columnar",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "Soft serial obloq receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "Soft serial obloq receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "Soft serial printing may cause obloq to receive messages incorrectly. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "Soft serial port obloq sending data may cause errors in receiving data by soft serial port. It is recommended to avoid too frequent sending of data by obloq",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "Soft serial obloq sending data may cause pin interrupt cannot be triggered, it is recommended to avoid obloq sending data too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "Soft serial port obloq MQTT initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "Soft serial obloq put request may cause the soft serial port to receive data error, it is recommended to avoid too frequent requests for data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "Soft serial port obloq HTTP initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "Soft serial obloq get request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "Soft serial port obloq post request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.compatible.shapedBlock": "Der neue Versionsänderungspunkt: Der Ereignisprogramm-Baustein mit eigenen Variablen wird durch den Ereignisprogramm-Baustein ohne Variablen ersetzt.",
    "gui.blocklyText.compatible.shapedBlock.variable": "Der neue Versionsänderungspunkt: Wechsel vom eigenständigen Programmbaustein zum variablen Programmbaustein im Ereignisprogramm.",
    "gui.blocklyText.compatible.booleanToRound": "Neuer Versionsänderungspunkt: Wechsel von bedingtem Programmbaustein zu numerischem Programmbaustein.",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "Neuer Versions-Änderungspunkt: Hinzufügen der Dropdown-Option für den Schalter der weichen und harten seriellen Schnittstelle im Programmbaustein.",
    "gui.blocklyText.compatible.mp3.deletePin": "Neuer Versionsänderungspunkt: Dropdown-Option \"Pin\" im Programmbaustein entfernen.",
    "gui.blocklyText.compatible.sen0206.addDropdown": "Neuer Versionsänderungspunkt: Dropdown-Optionen im Programmbaustein hinzufügen.",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "Neuer Versions-Änderungspunkt: Erhöhen der Pin-Dropdown-Option in der Programm-Baugruppe.",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "Neuer Versionsänderungspunkt: Die Dropdown-Option für den seriellen Anschluss in der Programmbaugruppe ändern.",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "Neuer Versionsänderungspunkt: von der Ereignisprogramm-Baugruppe zur bedingten Programm-Baugruppe gewechselt.",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "Der neue Versionsänderungspunkt: Baustein löschen",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "Die neue Version ändert Punkt: das quadratische Drop-Down-Feld in ein rundes Drop-Down-Feld ändern",
    "gui.blocklyText.pictureai.initToken": "init AI von Bild[SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "Bild aus lokaler Datei holen[IMAGE]",
    "gui.blocklyText.pictureai.cameraToggle": "Umschalten auf Kamera [CAMERA_LIST]",
    "gui.blocklyText.pictureai.getVideoImage": "Bild aus Video erhalten",
    "gui.blocklyText.pictureai.webPicture": "Bild von Website holen[TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH]Kamera-Verknüpfung in lokaler Datei speichern[TEXT]",
    "gui.blocklyText.pictureai.face": "identifiziere Bild[TEXT] enthält Gesicht",
    "gui.blocklyText.pictureai.word": "identifiziere Bild[TEXT] enthält [SELECT]",
    "gui.blocklyText.pictureai.picture": "identifiziere Bild[TEXT] enthält[SELECT]",
    "gui.blocklyText.pictureai.gesture": "Bild identifizieren[TEXT] enthält Gestik",
    "gui.blocklyText.pictureai.TOPIC_W_A": "Wörter",
    "gui.blocklyText.pictureai.TOPIC_W_B": "Nummer",
    "gui.blocklyText.pictureai.TOPIC_W_C": "Autonummer",
    "gui.blocklyText.pictureai.TOPIC_W_D": "Handschrift",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "linker Knöchel",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "linkes Ohr",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "linker Ellenbogen",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "linkes Auge",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "linke Hüfte",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "linkes Knie",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "linker Mundwinkel",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "linke Schulter",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "linkes Handgelenk",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "Hals",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "Nase",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "rechter Knöchel",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "rechtes Ohr",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "rechter Ellenbogen",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "rechtes Auge",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "rechte Hüfte",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "rechtes Knie",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "rechter Mundwinkel",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "rechte Schulter",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "rechtes Handgelenk",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "oberer Kopf",
    "gui.blocklyText.pictureai.initUserToken": "zu einem separaten Konto wechseln[MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "Bild identifizieren[TEXT] enthält Körperpunkt",
    "gui.blocklyText.pictureai.faceContrast": "Gesicht in Bild [TEXT] mit Bild [TEXT2] kontrastieren",
    "gui.blocklyText.pictureai.faceMerge": "Gesicht in Bild [TEXT] und Bild [TEXT2] verschmelzen",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "Bildkörper",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "Objekte und Szenen für allgemeine Zwecke",
    "gui.blocklyText.pictureai.TOPIC_P_A": "Pflanze",
    "gui.blocklyText.pictureai.TOPIC_P_B": "Tier",
    "gui.blocklyText.pictureai.TOPIC_P_C": "Blume",
    "gui.blocklyText.pictureai.TOPIC_P_D": "Gemüse",
    "gui.blocklyText.pictureai.bodyPointSuccess": "Erfolgreiche Identifikation von Schlüsselpunkten im menschlichen Körper?",
    "gui.blocklyText.pictureai.bodyPointInfo": "bekomme BodyPoint [BODY] [XY]-Koordinate",
    "gui.blocklyText.pictureai.noPower": "Bitte prüfe, ob das Konto die Funktion geöffnet hat",
    "gui.blocklyText.pictureai.pictureError": "das Bild ist nicht im richtigen Format",
    "gui.blocklyText.pictureai.noPicture": "bitte Wähle zuerst das Bild aus",
    "gui.blocklyText.pictureai.noVideo": "Bitte stelle  sicher, dass die Kamera richtig angeschlossen ist und keine andere Software belegt ist und schalte die Kamera ein oder starte die Software neu",
    "gui.blocklyText.pictureai.defaultTitleText": "Video-Fenster",
    "gui.blocklyText.pictureai.videoContainer": "verwende [CON] show camera sceen",
    "gui.blocklyText.pictureai.TOPIC_stage": "Bühne",
    "gui.blocklyText.pictureai.TOPIC_popup": "Popup",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "Änderungspunkt der neuen Version: Es wurden Programmbausteine entfernt.",
    "gui.blocklyText.pictureai.gestureList": "Geste[TEXT]",
    "gui.blocklyText.pictureai.networkError": "Netzwerkfehler, bitte überprüfe die Netzwerkverbindung",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "Erfolgreiche Gesichtserkennung?",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "Gesichtserkennungsergebnisse [TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "Gesicht num",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "Alter",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "Schönheit",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "Geschlecht",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "Brille",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "Typ",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "Ausdruck",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "Gesichtswahrscheinlichkeit",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "links",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "oben",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "Breite",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "Höhe",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "Orientierungspunkt",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "Okklusion",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "Unschärfe",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "Vollständigkeit",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "Mensch",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "Cartoon",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Forward",
    "gui.blocklyText.maqueenPlus.Backward": "Backward",
    "gui.blocklyText.maqueenPlus.left": "Left",
    "gui.blocklyText.maqueenPlus.right": "Right",
    "gui.blocklyText.maqueenPlus.all": "All",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "set motor [DIR] move by [SPEED] speed [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "set motor [DIR] stop",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] speed compensation [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "read [DIR] motor speed",
    "gui.blocklyText.maqueenPlus.readMoterDir": "read [DIR] motor direction (0:stop 1:forward 2:back)",
    "gui.blocklyText.maqueenPlus.clearMoterRunedCircle": "clear the revolutions of wheel [DIR]",
    "gui.blocklyText.maqueenPlus.getMoterRunedCircle": "get the revolutions of wheel [DIR]",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "set [DIR] RGB show [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "read the line tracking sensor [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "read the sensation sensor [INDEX] grayscale value",
    "gui.blocklyText.maqueenPlus.getVersion": "get version",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "read ultrasonic sensor trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "when received [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "infrared data",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "gui.blocklyText.esp32.audioRecognition": "get voice command within [TIME] seconds",
    "gui.blocklyText.esp32.audioRecordingFree": "trelease cache of recording",
    "gui.blocklyText.esp32.audioRecordingStart": "record audio with storage path [PATH] and duration [TIME] second(s)",
    "gui.blocklyText.esp32.audioRecordingInit": "initialize recording",
    "gui.blocklyText.esp32.audioPlay": "Audio play [URL]",
    "gui.blocklyText.esp32.audioControl": "Audio [CONTROL]",
    "gui.blocklyText.esp32.AudioControl.A": "PAUSE",
    "gui.blocklyText.esp32.AudioControl.B": "GO ON",
    "gui.blocklyText.esp32.AudioControl.C": "STOP",
    "gui.blocklyText.esp32.audioSetVolume": "set Audio volume as [VOLUME]",
    "gui.blocklyText.esp32.audioFree": "deinit Audio",
    "gui.blocklyText.esp32.audioInit": "initialize Audio",
    "gui.blocklyText.esp32.audio": "audio",
    "gui.blocklyText.esp32.musicGetTempo": "current beats",
    "gui.blocklyText.esp32.musicSetTempo": "set each beat equal to [TICKS] notes, the number of beats per minute is [TEMPO]",
    "gui.blocklyText.esp32.musicSetPlaySpeed": "set the playback speed to [SPEED] times",
    "gui.blocklyText.esp32.musicPlayMelodySet": "play music [MELODY] [WAIT] [LOOP] at pin [PIN]",
    "gui.blocklyText.esp32.musicPlayMelody": "play music [MELODY] pin [PIN]",
    "gui.blocklyText.esp32.musicPlayNoteFrom": "play tone from [MIN] to [AMX] step [STEP] duration [DURATION] ms pin [PIN]",
    "gui.blocklyText.esp32.musicPlayNote": "play tone [NOTE] delay [DELAY] millisecond(s) at pin [PIN]",
    "gui.blocklyText.esp32.musicTone": "tone [NOTE]",
    "gui.blocklyText.esp32.musicPlayNoteContinuous": "play continuous tone [NOTE] at pin [PIN]",
    "gui.blocklyText.esp32.musicPlayList": "play note list [NOTELIST] [WAIT] [LOOP] at pin [PIN]",
    "gui.blocklyText.esp32.IsWait.A": "wait",
    "gui.blocklyText.esp32.IsWait.B": "don't wait",
    "gui.blocklyText.esp32.IsLoop.A": "loop playback",
    "gui.blocklyText.esp32.IsLoop.B": "play once",
    "gui.blocklyText.esp32.getNote": "note [NOTE] beat [BEAT]",
    "gui.blocklyText.esp32.musicPlay": "play note [NOTE] at pin [PIN]",
    "gui.blocklyText.esp32.restore": "restore music settings",
    "gui.blocklyText.esp32.stopPlay": "stop playing music at pin [PIN]",
    "gui.blocklyText.esp32.stopPlay_default": "default",
    "gui.blocklyText.mpyUART.input": "get input text from serial post ,title [TITLE]",
    "gui.blocklyText.mpyUART.close": "close serial port [UART]",
    "gui.blocklyText.mpyUART.readLength": "serial port [UART] read data with length as [LENGTH]",
    "gui.blocklyText.mpyUART.read": "serial port [UART] read data",
    "gui.blocklyText.mpyUART.readline": "serial port [UART] read a lien of data",
    "gui.blocklyText.mpyUART.any": "serial port [UART] has readable data?",
    "gui.blocklyText.mpyUART.writeBytes": "serial port [UART] write bytes [BYTES]",
    "gui.blocklyText.mpyUART.writeByte": "serial port [UART] write a byte [BYTE]",
    "gui.blocklyText.mpyUART.init": "initialize serial port [UART] as baudrate [BAUDRATE] tx [TXPIN] rx [RXPIN]",
    "gui.blocklyText.mpyUART.initTello": "initialize serial port [UART] rx [RXPIN] tx [TXPIN] as baudrate [BAUDRATE]",
    "gui.blocklyText.mpyUART.writeStringTello": "serial port [UART] write string [TEXT] [ENDSYMBOL] wrap",
    "gui.blocklyText.mpyUART.anyTello": "serial port [UART] has readable data?",
    "gui.blocklyText.mpyUART.readTello": "serial port [UART] read data",
    "gui.blocklyText.mpyUART.closeTello": "close serial port [UART]",
    "gui.blocklyText.mpyUART.writeString": "serial port [UART] write string [TEXT] [ENDSYMBOL] wrap",
    "gui.blocklyText.mpyUART.no": "no",
    "gui.blocklyText.mpyUART.auto": "auto",
    "gui.blocklyText.mpyI2C.scan": "I2C scan result",
    "gui.blocklyText.mpyI2C.read": "I2C address [ADDRESS] read bytes number [BYTES]",
    "gui.blocklyText.mpyI2C.write": "I2C address [ADDRESS] is written to [DATA]",
    "gui.blocklyText.mpyI2C.init": "initialize I2C SCL [SCLPIN] SDA [SDAPIN] baudrate [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoInitBoard": "initialize the onboard I2C frequency [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoUniversal": "Initialize general I2C SCL [SCLPIN] SDA [SDAPIN] frequency [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoWrite": "[OBJ] Write [DATA] to address [ADDRESS]",
    "gui.blocklyText.mpyI2C.maixduinoRead": "[OBJ] read [BYTES] data from address [ADDRESS]",
    "gui.blocklyText.mpyI2C.maixduinoScan": "[OBJ] scan result",
    "gui.blocklyText.esp32.readPinLevel": "duration(us) of external [LEVEL] pulse level at [PIN]",
    "gui.blocklyText.esp32.noPull": "no pull",
    "gui.blocklyText.esp32.pullDown": "pull down",
    "gui.blocklyText.esp32.pullUp": "pull up",
    "gui.blocklyText.esp32.setPinMode": "set pin [PIN] as [MODE] mode [PULL] and set the value to [LEVEL]",
    "gui.blocklyText.esp32.leakyOutput": "leaky output",
    "gui.blocklyText.mpyServo.setServo": "den Servo [PIN]-Winkel als [ANGLE] einstellen Pulsweite von [MIN] us bis [MAX] us Ansteuerungsbereich als [ANGLEMAX]",
    "gui.blocklyText.servo.init": "den Servo [PIN]-Winkel auf [ANGLE] einstellen Timer [TIMER]",
    "gui.blocklyText.mpyServo.setAngle": "den Servo [PIN]-Winkel auf [ANGLE] einstellen",
    "gui.blocklyText.esp32.shaked": "shaked",
    "gui.blocklyText.esp32.thrown": "thrown",
    "gui.blocklyText.esp32.tilt_forward": "tilt forward",
    "gui.blocklyText.esp32.tilt_back": "tilt back",
    "gui.blocklyText.esp32.tilt_right": "tilt right",
    "gui.blocklyText.esp32.tilt_left": "tilt left",
    "gui.blocklyText.esp32.tilt_none": "flat",
    "gui.blocklyText.esp32.attitudeEvent": "when the board is [ATTITUDE]",
    "gui.blocklyText.esp32.to_high": "↗ low to high",
    "gui.blocklyText.esp32.to_low": "↘ high to low",
    "gui.blocklyText.esp32.pinLevelEvent": "when pin [PIN] level from [TRANSITION]",
    "gui.blocklyText.mpyTimer.setEvent": "Set timer [INDEX] [MODE] period [PERIOD] milliseconds",
    "gui.blocklyText.mpyTimer.repeated": "repeated",
    "gui.blocklyText.mpyTimer.delayed": "delayed",
    "gui.blocklyText.mpyTimer.conditionEvent": "Set timer [INDEX] :when [CONDITION]",
    "gui.blocklyText.mpyTimer.getCount": "Timer [INDEX] count value",
    "gui.blocklyText.mpyTimer.clearEvent": "Clear timer/event [INDEX]",
    "gui.blocklyText.mpyDictionary.init": "init dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.getValue": "dictionary [CONTENT] value of [KEY]",
    "gui.blocklyText.mpyDictionary.setValue": "set dictionary [CONTENT] value of key [KEY] to [VALUE]",
    "gui.blocklyText.mpyDictionary.deleteKey": "delete dictionary [CONTENT] key [KEY]",
    "gui.blocklyText.mpyDictionary.clear": "clear dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.isInclude": "dictionary [CONTENT] include key [KEY]?",
    "gui.blocklyText.mpyDictionary.getLength": "length of dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.list": "list of [TYPE] in dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.TypeIndex.A": "key",
    "gui.blocklyText.mpyDictionary.TypeIndex.B": "value",
    "gui.blocklyText.mpyList.join": "List [LIST] use separators [SYMBOL] to combine text",
    "gui.blocklyText.mpyList.split": "Text [TEXT] use separators [SYMBOL] to make lists",
    "gui.blocklyText.mpyList.init": "init list [CONTENT]",
    "gui.blocklyText.mpyList.clear": "clear list [CONTENT]",
    "gui.blocklyText.mpyList.getLength": "length of list [CONTENT]",
    "gui.blocklyText.mpyList.isEmpty": "[CONTENT] is empty?",
    "gui.blocklyText.mpyList.getValue": "get value at index [INDEX] in list [CONTENT]",
    "gui.blocklyText.mpyList.getValueRange": "return list [CONTENT] takes [TYPE1] [NUM1] items to [TYPE2] [NUM2] items",
    "gui.blocklyText.mpyList.insert": "insert [VALUE] at index [INDEX] in list [CONTENT]",
    "gui.blocklyText.mpyList.setValue": "set index [INDEX] to [VALUE] in list [CONTENT]",
    "gui.blocklyText.mpyList.delete": "delete value at index [INDEX] from list [CONTENT]",
    "gui.blocklyText.mpyList.append": "append [APPEND] into list [CONTENT]",
    "gui.blocklyText.mpyList.extend": "list [LIST1] append list [LIST2]",
    "gui.blocklyText.mpyList.findIndex": "find index of [VALUE] in list [CONTENT]",
    "gui.blocklyText.mpyList.sort": "List [LIST] sort by [TYPE] as [MODE]",
    "gui.blocklyText.mpyList.SortModeIndex.A": "ascending",
    "gui.blocklyText.mpyList.SortModeIndex.B": "descending",
    "gui.blocklyText.mpyList.SortTypeIndex.A": "number",
    "gui.blocklyText.mpyList.SortTypeIndex.B": "letter",
    "gui.blocklyText.mpyList.SortTypeIndex.C": "letter, ignore case",
    "gui.blocklyText.mpySet.init": "init set [CONTENT]",
    "gui.blocklyText.mpySet.update": "update set [CONTENT1] as the [TYPE] with set [CONTENT2]",
    "gui.blocklyText.mpySet.TypeIndex.A": "intersection",
    "gui.blocklyText.mpySet.TypeIndex.B": "union",
    "gui.blocklyText.mpySet.TypeIndex.C": "difference",
    "gui.blocklyText.mpySet.addValue": "update set [CONTENT] with [VALUE]",
    "gui.blocklyText.mpySet.isSubsetSuperset": "set [CONTENT1] is [TYPE] of set [CONTENT2]?",
    "gui.blocklyText.mpySet.SubsetSupersetType.A": "subset",
    "gui.blocklyText.mpySet.SubsetSupersetType.B": "superset",
    "gui.blocklyText.mpySet.intersectionUnionSetDifference": "[TYPE] of set [CONTENT1] and set [CONTENT2]",
    "gui.blocklyText.mpySet.length": "length of set [CONTENT]",
    "gui.blocklyText.mpySet.pop": "return a random item from the set [CONTENT] ,and remove it",
    "gui.blocklyText.mpyTuple.init": "init tuple [CONTENT]",
    "gui.blocklyText.mpyTuple.minMaxLength": "tuple [CONTENT] [TYPE]",
    "gui.blocklyText.mpyTuple.TypeIndex.A": "min value",
    "gui.blocklyText.mpyTuple.TypeIndex.B": "max value",
    "gui.blocklyText.mpyTuple.TypeIndex.C": "length",
    "gui.blocklyText.mpyTuple.isInclude": "tuple [CONTENT] include [STRING]?",
    "gui.blocklyText.mpyTuple.getValueRange": "return tuple [CONTENT] takes [TYPE1] [NUM1] items to [TYPE2] [NUM2] items",
    "gui.blocklyText.mpyTuple.IndexType.A": "#",
    "gui.blocklyText.mpyTuple.IndexType.B": "countdown #",
    "gui.blocklyText.mpyTuple.listToTulpe": "list [LIST] conversion to tuple",
    "gui.blocklyText.mpyTuple.getValue": "get value at index [INDEX] in tuple [CONTENT]",
    "gui.blocklyText.mpyTuple.tupleToList": "convert tuple [CONTENT] into list",
    "gui.blocklyText.esp32.DisplayTypeMenu.A": "normal",
    "gui.blocklyText.esp32.DisplayTypeMenu.B": "rev",
    "gui.blocklyText.esp32.displayInnerImageInXY": "display built-in image [IMAGE] to x:[X] y:[Y] pattern [TYPE]",
    "gui.blocklyText.esp32.displayFileSystemImageInXY": "loading image from file system [IMAGE] show to x:[X] y:[Y] pattern [TYPE]",
    "gui.blocklyText.weather.GetWeatherWithCityName": "get [WEATHER] in [CITY] city",
    "gui.blocklyText.pictureai.createFaceClass": "Gesichtsklasse [NAME] erstellt",
    "gui.blocklyText.pictureai.pictureaiAddFaceToClass": "benanntes Erkennungsergebnis [RES] und zur Gesichtsklasse [CLASSNAME] hinzugefügt",
    "gui.blocklyText.pictureai.pictureaiSelectResultsFromClass": "Erkennungsergebnisse in der Gesichtsbibliothek [CLASSNAME] gesucht, wobei die Zuverlässigkeit >= [NUMBER]",
    "gui.blocklyText.pictureai.pictureaiWhenSearchName": "Wenn die Suchergebnisse [NAME]",
    "gui.blocklyText.pictureai.pictureaiSearchedName": "Name der Suchergebnisse",
    "gui.blocklyText.pictureai.pictureaiSelectResultReliability": "Zuverlässigkeit der Suchergebnisse",
    "gui.blocklyText.pictureai.pleaseUseSelefAccount": "Gesichtsklassenfunktion auf separates Konto umschalten",
    "gui.blocklyText.pictureai.addfaceclasserror": "Fehler beim Erstellen der Gesichtsklasse",
    "gui.blocklyText.pictureai.faceclassnameerror": "der Name der Gesichtsklasse ist aus Zahlen, Buchstaben und Unterstrichen zusammengesetzt",
    "gui.blocklyText.pictureai.faceclassnameexist": "Klassenname ist bereits vorhanden",
    "gui.blocklyText.pictureai.addfaceerror": "Fehler beim Hinzufügen von Gesichtern",
    "gui.blocklyText.pictureai.selectfaceclasserror": "Fehler beim Suchen der Gesichtsklasse ",
    "gui.blocklyText.pictureai.notfoundmatch": "passender Benutzer wird nicht gefunden",
    "gui.blocklyText.pictureai.facenameerror": "Der Name des Gesichts setzt sich aus Zahlen, Buchstaben und Unterstrichen zusammen",
    "boost.color.any": "any color",
    "boost.color.black": "black",
    "boost.color.blue": "blue",
    "boost.color.green": "green",
    "boost.color.red": "red",
    "boost.color.white": "white",
    "boost.color.yellow": "yellow",
    "boost.getMotorPosition": "motor [MOTOR_REPORTER_ID] position",
    "boost.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "boost.motorDirection.backward": "that way",
    "boost.motorDirection.forward": "this way",
    "boost.motorDirection.reverse": "reverse",
    "boost.motorOff": "turn motor [MOTOR_ID] off",
    "boost.motorOn": "turn motor [MOTOR_ID] on",
    "boost.motorOnFor": "turn motor [MOTOR_ID] for [DURATION] seconds",
    "boost.motorOnForRotation": "turn motor [MOTOR_ID] for [ROTATION] rotations",
    "boost.seeingColor": "seeing [COLOR] brick?",
    "boost.setLightHue": "set light color to [HUE]",
    "boost.setMotorDirection": "set motor [MOTOR_ID] direction [MOTOR_DIRECTION]",
    "boost.setMotorPower": "set motor [MOTOR_ID] speed to [POWER] %",
    "boost.tiltDirection.any": "any",
    "boost.tiltDirection.down": "down",
    "boost.tiltDirection.left": "left",
    "boost.tiltDirection.right": "right",
    "boost.tiltDirection.up": "up",
    "boost.whenColor": "when [COLOR] brick seen",
    "boost.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "ev3.beepNote": "beep note [NOTE] for [TIME] secs",
    "ev3.buttonPressed": "button [PORT] pressed?",
    "ev3.getBrightness": "brightness",
    "ev3.getDistance": "distance",
    "ev3.getMotorPosition": "motor [PORT] position",
    "ev3.motorSetPower": "motor [PORT] set power [POWER] %",
    "ev3.motorTurnClockwise": "motor [PORT] turn this way for [TIME] seconds",
    "ev3.motorTurnCounterClockwise": "motor [PORT] turn that way for [TIME] seconds",
    "ev3.whenBrightnessLessThan": "when brightness < [DISTANCE]",
    "ev3.whenButtonPressed": "when button [PORT] pressed",
    "ev3.whenDistanceLessThan": "when distance < [DISTANCE]",
    "wedo2.getDistance": "distance",
    "wedo2.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "wedo2.isTilted": "tilted [TILT_DIRECTION_ANY]?",
    "wedo2.motorDirection.backward": "that way",
    "wedo2.motorDirection.forward": "this way",
    "wedo2.motorDirection.reverse": "reverse",
    "wedo2.motorId.a": "motor A",
    "wedo2.motorId.all": "all motors",
    "wedo2.motorId.b": "motor B",
    "wedo2.motorId.default": "motor",
    "wedo2.motorOff": "turn [MOTOR_ID] off",
    "wedo2.motorOn": "turn [MOTOR_ID] on",
    "wedo2.motorOnFor": "turn [MOTOR_ID] on for [DURATION] seconds",
    "wedo2.playNoteFor": "play note [NOTE] for [DURATION] seconds",
    "wedo2.setLightHue": "set light color to [HUE]",
    "wedo2.setMotorDirection": "set [MOTOR_ID] direction to [MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "set [MOTOR_ID] power to [POWER]",
    "wedo2.tiltDirection.any": "any",
    "wedo2.tiltDirection.down": "down",
    "wedo2.tiltDirection.left": "left",
    "wedo2.tiltDirection.right": "right",
    "wedo2.tiltDirection.up": "up",
    "wedo2.whenDistance": "when distance [OP] [REFERENCE]",
    "wedo2.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "gui.blocklyText.video.notfound": "nicht gefunden",
    "gui.blocklyText.machinelearning.drawResults": "[TOGGLE]Zeichnungserkennungsergebnisse",
    "gui.blocklyText.machinelearning.initKNNImageClass": "PC KNN-Klassifizierung",
    "gui.blocklyText.machinelearning.imageClassificationFromCamera": "KNN klassifizieren das Kamerabild als [TEXT]",
    "gui.blocklyText.machinelearning.imageClassificationFromDir": "KNN klassifizieren das lokale Ordnerbild [DIR] als [TEXT]",
    "gui.blocklyText.machinelearning.startKNNTrain": "KNN starten Klassifizierungstraining",
    "gui.blocklyText.machinelearning.predictImageClass": "KNN [TOGGLE] Erkennen der Kamerabild-Klassifikation",
    "gui.blocklyText.machinelearning.getImageClassification": "KNN erkennt das Klassifizierungsergebnis",
    "gui.blocklyText.machinelearning.getImageClassificationReliability": "KNN erkennt die Glaubwürdigkeit",
    "gui.blocklyText.machinelearning.clearImageClassificationLabel": "KNN löscht Tag [LABEL]",
    "gui.blocklyText.machinelearning.clearImageClassificationModel": "KNN löschen Klassifikationsmodelldaten",
    "gui.blocklyText.machinelearning.loadImageClassificationModel": "KNN lädt das rechnerseitige Klassifikationsmodell aus [PATH]",
    "gui.blocklyText.machinelearning.exportImageClassificationModel": "KNN speichert das Klassifikationsmodell",
    "gui.blocklyText.machinelearning.traceFace": "FaceAPI initialisiert die Gesichtsverfolgung",
    "gui.blocklyText.machinelearning.drawFaceAPI": "FaceAPI[TOGGLE] Schlüsselpunkte des Gesichts markieren",
    "gui.blocklyText.machinelearning.traceFaceNumber": "FaceAPI Anzahl der Gesichter",
    "gui.blocklyText.machinelearning.getFaceByIndex": "FaceAPI hole [PART] von [INDEX] Gesicht mit Koordinate [TYPE]",
    "gui.blocklyText.machinelearning.initfaceContrast": "FaceAPI spezifische Gesichtserkennung initialisieren",
    "gui.blocklyText.machinelearning.refFaceFromCamera": "FaceAPI sammelt Gesichtsdaten von der als [NAME] markierten Kamera",
    "gui.blocklyText.machinelearning.refFaceFromDir": "FaceAPI sammelt Gesichtsdaten aus dem Ordner [DIR], der als Ordnername markiert ist",
    "gui.blocklyText.machinelearning.faceAPIFindFace": "FaceAPI identifiziert einmalig das Gesicht in der Kamera",
    "gui.blocklyText.machinelearning.allowFaceAPIResult": "FaceAPI-Gesichtserkennungsergebnisse",
    "gui.blocklyText.machinelearning.allowFaceAPIResultScore": "FaceAPI Gesichtserkennungsergebnisse Glaubwürdigkeit",
    "gui.blocklyText.machinelearning.clearFaceAPIModel": "FaceAPI Gesichtsmodelldaten löschen",
    "gui.blocklyText.machinelearning.ml5WhenSearchFaceName": "beim Suchen label[NAME] credibility>=[VALUE]%",
    "gui.blocklyText.machinelearning.machinelearningTracePose": "PoseNet initialisiert die Haltungsverfolgung",
    "gui.blocklyText.machinelearning.drawPoseNet": "PoseNet[TOGGLE] Markieren von Haltungs-Schlüsselpunkten",
    "gui.blocklyText.machinelearning.getPoseByIndex": "PoseNet holt [PART] von [INDEX] Körperhaltung mit Koordinate [TYPE]",
    "gui.blocklyText.machinelearning.initImageClassify": "Objekt-Erkennung Bildklassifikator initialisieren (mobileNet)",
    "gui.blocklyText.machinelearning.toggleImageClassify": "[TOGGLE] Objekterkennung Bildklassifikator Echtzeit-Erkennung",
    "gui.blocklyText.machinelearning.imageClassifyResult": "Objekterkennung Erkennungsergebnisse",
    "gui.blocklyText.machinelearning.imageClassifyResultScore": "Objekterkennung Erkennungsglaubwürdigkeit",
    "gui.blocklyText.huskylens.initVisualModal": "AI-Visualisierungstool initialisieren auf [TYPE]",
    "gui.blocklyText.huskylens.classifyImageFromCamera": "Bildschirmbilder der Computerkamera zum Klassifikator hinzufügen [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromHuskylens": "Bildschirmbilder der HuskyLens-Kamera zum Klassifikator hinzufügen [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromDir": "Bilder des lokalen Dateiordners [DIR] zum Klassifikator hinzufügen [TEXT]",
    "gui.blocklyText.huskylens.visualModalClearLabel": "Klassifizierung löschen [LABEL]",
    "gui.blocklyText.huskylens.visualModalClearAllLabels": "alle Klassifizierungen des Klassifizierers löschen",
    "gui.blocklyText.huskylens.deployClassificationToHuskylens": "Das Modell trainieren und auf HuskyLens anwenden",
    "gui.blocklyText.machinelearning.loadlmodel": "Modellpfad",
    "gui.blocklyText.pictureai.TOPIC_BODY_mouth": "Mund",
    "gui.blocklyText.pictureai.TOPIC_BODY_jaw_outline": "Kiefer",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye_brow": "linke Augenbraue",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye_brow": "rechte Augenbraue",
    "gui.blocklyText.huskylens.videoContainer": "Verwende [CON], um den Bildschirm der Computerkamera anzuzeigen",
    "gui.blocklyText.huskylens.videoToggle": "[VIDEO_STATE] Computerkamera",
    "gui.blocklyText.huskylens.cameraToggle": "auf Computerkamera umschalten [CAMERA_LIST]",
    "gui.blocklyText.huskylens.exportImage": "Die Computerkamera nimmt ein Foto auf und speichert es im lokalen Ordner [TEXT]",
    "gui.blocklyText.dialog.ml5.loadModel": "Laden des Modells, die Seite kann stecken bleiben, bitte warten...",
    "gui.blocklyText.dialog.ml5.reInitPictureClassify": "die Bilddaten sind bereits im Klassifikator vorhanden. Eine Neuinitialisierung wird die vorhandenen Daten leeren. Bestätigen, um fortzufahren?",
    "gui.blocklyText.dialog.ml5.whenDeleteImageAfterTrain": "Diese Bilddaten wurden trainiert, nach dem Löschen müssen sie neu trainiert werden, um wirksam zu werden",
    "gui.blocklyText.dialog.ml5.renameLabel": "Das Tag [LABEL] existiert bereits, willst du es zusammenführen?",
    "gui.blocklyText.dialog.ml5.modelIsEmpty": "Es gibt keine klassifizierten Daten in diesem Modell. Bitte trainiere zuerst das Modell",
    "gui.blocklyText.dialog.ml5.shouldInitKNN": "Bitte initialisiere zuerst die KNN-Klassifikation",
    "gui.blocklyText.dialog.huskylens.emptyAllImage": "Dieser Vorgang wird alle Kategorien löschen, willst du wirklich fortfahren?",
    "gui.blocklyText.dialog.huskylens.HuskyLensTakePhotoError": "Das Aufnehmen von Fotos von HuskyLens ist fehlgeschlagen. Bitte versuche es erneut",
    "gui.blocklyText.dialog.huskylens.overImageMaxLen": "Die maximale Anzahl von [TYPE] Klassifikatoren ist [LEN]. Das Limit wurde überschritten. Bitte sortiere es und versuche es erneut",
    "gui.blocklyText.dialog.huskylens.notAllowChinese": "Der Kategoriename sollte aus Zahlen und Buchstaben mit nicht mehr als 50 Ziffern bestehen und darf keine chinesischen und Sonderzeichen enthalten",
    "gui.blocklyText.dialog.ml5.isNotKNN": "Gesichtserkennungs-Typ-Klassifikator kann nicht am KNN-Training teilnehmen",
    "gui.blocklyText.machinelearning.start": "starten",
    "gui.blocklyText.machinelearning.stop": "stoppen",
    "gui.blocklyText.huskyLensPro.ML5MODEL.A": "KNN-Klassifizierung",
    "gui.blocklyText.huskyLensPro.ML5MODEL.B": "Gesichtserkennung",
    "gui.blocklyText.huskyLensPro.changeAlgorithm": "HuskyLens schaltet Algorithmus auf [ALGORITHM] um",
    "gui.blocklyText.huskyLensPro.learndIDCount": "HuskyLens ermittelt die Gesamtzahl der gelernten IDs",
    "gui.blocklyText.huskyLensPro.isOnTheScreen": "HuskyLens prüft, ob [BOXARROW] auf dem Bildschirm ist?",
    "gui.blocklyText.huskyLensPro.parametersNearTheCenter": "HuskyLens ermittelt [PARAMETER] von [BOXARROW], der der Mitte des Bildschirms am nächsten ist",
    "gui.blocklyText.huskyLensPro.idIsLearned": "HuskyLens prüft, ob ID [ID] gelernt ist?",
    "gui.blocklyText.huskyLensPro.idIsOnTheScreen": "HuskyLens prüft, ob ID [ID] [BOXARROW] auf dem Bildschirm ist?",
    "gui.blocklyText.huskyLensPro.idParametersNearTheCenter": "HuskyLens ermittelt [PARAMETER] von ID [ID] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.boxCount": "HuskyLens erhält die Gesamtzahl von [BOXARROW]",
    "gui.blocklyText.huskyLensPro.boxarrowParameter": "HuskyLens holt [PARAMETER] der Nr. [INDEX] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.idBoxCount": "HuskyLens erhalten eine Gesamtzahl von ID [ID] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.idBoxarrowParameter": "HuskyLens erhalten [PARAMETER] der Nr. [ID] [INDEX] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.learnOnce": "HuskyLens lernt ID [ID] einmal automatisch",
    "gui.blocklyText.huskyLensPro.clearLearn": "HuskyLens vergisst alle Lerndaten des aktuellen Algorithmus",
    "gui.blocklyText.huskyLensPro.setIDname": "HuskyLens benennt ID [ID] des aktuellen Algorithmus als [NAME]",
    "gui.blocklyText.huskyLensPro.writeOSD": "HuskyLens zeigt eigene Texte [NAME] an Position x [X] y [Y] auf dem Bildschirm an",
    "gui.blocklyText.huskyLensPro.clearOSD": "HuskyLens löscht alle benutzerdefinierten Texte auf dem Bildschirm",
    "gui.blocklyText.huskyLensPro.requestPicture": "HuskyLens [TYPE] und in einem lokalen Ordner [PATH] speichern",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.A": "ein Foto machen",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.B": "Bildschirmfoto",
    "gui.blocklyText.huskyLensPro.TakeScreenshotToSDCard": "HuskyLens [TYPE] und auf der SD-Karte speichern",
    "gui.blocklyText.huskyLensPro.xCenter": "X-Mitte",
    "gui.blocklyText.huskyLensPro.yCenter": "Y-Mitte",
    "gui.blocklyText.huskyLensPro.width": "Breite",
    "gui.blocklyText.huskyLensPro.height": "Höhe",
    "gui.blocklyText.huskyLensPro.xStart": "X-Start",
    "gui.blocklyText.huskyLensPro.yStart": "Y-Anfang",
    "gui.blocklyText.huskyLensPro.xEnd": "X-Ende",
    "gui.blocklyText.huskyLensPro.yEnd": "Y-Ende",
    "gui.blocklyText.huskyLensPro.QRRecognition": "QR-Code-Erkennung",
    "gui.blocklyText.huskyLensPro.barcodeRecognition": "Barcode-Erkennung",
    "gui.blocklyText.huskyLensPro.saveFailed": "Bild konnte nicht gespeichert werden!",
    "gui.blocklyText.huskyLensPro.yes": "ja",
    "gui.blocklyText.huskyLensPro.cancel": "abbrechen",
    "gui.blocklyText.huskyLensPro.promptInitializeClassifier": "Bitte initialisiere zuerst den Bildklassifikator",
    "gui.blocklyText.huskyLensPro.promptInitializeKNNClassifier": "Bitte initialisiere zuerst den KNN-Klassifikator",
    "gui.blocklyText.huskyLensPro.promptAddClassificationData": "Bitte zuerst Klassifikationsdaten hinzufügen",
    "gui.blocklyText.huskyLensPro.setTitleText": "HuskyLens AI-KNN Bildklassifizierung",
    "gui.blocklyText.huskyLensPro.tooManyPictures": "Es gibt zu viele Bilder, nur die ersten fünfzig Bilder werden extrahiert",
    "gui.blocklyText.huskyLensPro.checkPath": "Bitte prüfe , ob es Bilder im Pfad [PATH] gibt",
    "gui.blocklyText.huskyLensPro.success": "Erfolg",
    "gui.blocklyText.huskyLensPro.importImg": "[COUNT1] Bilder wurden diesmal erfolgreich importiert, [COUNT2] schlug fehl",
    "gui.blocklyText.huskyLensPro.recognitionResult": "Das Erkennungsergebnis:",
    "gui.blocklyText.tello.telloSetPitchRollYaw": "Set the amount of the four channel sticks of the remote control: roll [ROLL] pitch [PITCH] throttle [STRENGTH] yaw [YAW]",
    "gui.blocklyText.tello.telloQuerySpeed": "Current Speed",
    "gui.blocklyText.tello.telloTime": "Motor running time(s)",
    "gui.blocklyText.tello.telloWIFISign": "Wi-Fi SNR",
    "gui.blocklyText.tello.telloSDKVersion": "Tello SDK Version",
    "gui.blocklyText.tello.telloSN": "Tello SN Code",
    "gui.blocklyText.tello.reset": "Reset",
    "gui.blocklyText.tello.telloFlyCurve": "Flying curve warp x1[X1]cm y1[Y1]cm z1[Z1]cm and x2[X2]cm y2[Y2]cm z2[Z2]cm Speed ​​[SPEED] cm/s",
    "gui.blocklyText.tello.telloStopEmerygency": "Emergency Stop",
    "gui.blocklyText.tello.telloGetHeight": "Relative Height (cm)",
    "gui.blocklyText.tello.telloBarometer": "Barometer Height (m)",
    "gui.blocklyText.tello.telloTofDistance": "TOF Height (cm)",
    "gui.blocklyText.tello.telloTempMin": "Motherboard minimum temperature (°C)",
    "gui.blocklyText.tello.telloTempMax": "Motherboard maximum temperature (°C)",
    "gui.blocklyText.tello.TelloAttitudePitch": "Pitch axis attitude angle (°)",
    "gui.blocklyText.tello.TelloAttitudeRoll": "Roll axis attitude angle (°)",
    "gui.blocklyText.tello.TelloAttitudeYaw": "Pan axis attitude angle (°)",
    "gui.blocklyText.tello.telloAccelerationX": "X-axis acceleration (0.001g)",
    "gui.blocklyText.tello.telloAccelerationY": "Y-axis acceleration (0.001g)",
    "gui.blocklyText.tello.telloAccelerationZ": "Z-axis acceleration (0.001g)",
    "gui.blocklyText.tello.telloSpeedX": "X-axis speed (cm/s)",
    "gui.blocklyText.tello.telloSpeedY": "Y-axis speed (cm/s)",
    "gui.blocklyText.tello.telloSpeedZ": "Z-axis speed (cm/s)",
    "gui.blocklyText.tello.telloFlyToXY": "Fly (relative to the aircraft) x[X]cm y[Y]cm z[Z]cm speed [SPEED]cm/s",
    "gui.blocklyText.tello.TelloFlyCurveMissionPad": "Flying arc line under challenge card x1[X1]cm y1[Y1]cm z1[Z1]cm and x2[X2]cm y2[Y2]cm z2[Z2]cm speed [SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.telloJumpInMissionPad": "Jump along challenge card x[X]cm y[Y]cm z[Z]cm speed [SPEED]cm/s yaw [YAW] degree from Mid1 [MID1] to Mid2 [MID2]",
    "gui.blocklyText.telloesp32.telloGetStatus": "get status once, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloReturnStatusChallenge": "get from the result [STATUSCHALLENGE]",
    "gui.blocklyText.telloesp32.statusChallengeX": "Relative challenge card X coordinate",
    "gui.blocklyText.telloesp32.statusChallengeY": "Relative challenge card Y coordinate",
    "gui.blocklyText.telloesp32.statusChallengeZ": "Relative challenge card Z coordinate",
    "gui.blocklyText.telloesp32.statusChallengePitch": "Relative challenge card Pitch angle",
    "gui.blocklyText.telloesp32.statusChallengeYaw": "Relative challenge card Yaw angle",
    "gui.blocklyText.telloesp32.statusChallengeRoll": "Relative challenge card Roll angle",
    "gui.blocklyText.telloesp32.telloReturnStatusAttitude": "get from the result [STATUSATTITUDE]",
    "gui.blocklyText.telloesp32.statusAttitudePitch": "Pitch angle",
    "gui.blocklyText.telloesp32.statusAttitudeYaw": "Yaw angle",
    "gui.blocklyText.telloesp32.statusAttitudeRoll": "Roll angle",
    "gui.blocklyText.telloesp32.statusAttitudeXSpeed": "X axis speed",
    "gui.blocklyText.telloesp32.statusAttitudeYSpeed": "Y axis speed",
    "gui.blocklyText.telloesp32.statusAttitudeZSpeed": "Z axis speed",
    "gui.blocklyText.telloesp32.statusAttitudeXAcc": "X axis acceleration",
    "gui.blocklyText.telloesp32.statusAttitudeYAcc": "Y axis acceleration",
    "gui.blocklyText.telloesp32.statusAttitudeZAcc": "Z axis acceleration",
    "gui.blocklyText.telloesp32.statusChallengeId": "Challenge Card ID",
    "gui.blocklyText.telloesp32.telloReturnStatusNormal": "get from the result [STATUSNORMAL]",
    "gui.blocklyText.telloesp32.statusNormalMaxTemp": "Maximum temperature",
    "gui.blocklyText.telloesp32.statusNormalLowTemp": "Lowest temperature",
    "gui.blocklyText.telloesp32.statusNormalTof": "tof distance",
    "gui.blocklyText.telloesp32.statusNormalHeight": "Relative height",
    "gui.blocklyText.telloesp32.statusNormalBat": "Current battery",
    "gui.blocklyText.telloesp32.statusNormalBaro": "Barometric altitude",
    "gui.blocklyText.telloesp32.statusNormalMotor": "Motor time",
    "gui.blocklyText.tello.telloRotateWithYaw": "The aircraft rotates to the [YAW] angle relative to the challenge card [MID]",
    "gui.blocklyText.tello.telloStopAndHover": "Stop motion and hover",
    "gui.blocklyText.tello.MissionPad": "Challenge Card Number",
    "gui.blocklyText.tello.MissionPadX": "Challenge Card X(cm)",
    "gui.blocklyText.tello.MissionPadY": "Challenge Card Y(cm)",
    "gui.blocklyText.tello.telloMissionPadZ": "Challenge Card Z(cm)",
    "gui.blocklyText.tello.telloCommandResponse": "Reply to Command",
    "gui.blocklyText.tello.direction_front": "Front View",
    "gui.blocklyText.tello.direction_blow": "Down View",
    "gui.blocklyText.tello.direction_all": "All",
    "gui.blocklyText.tello.mid": "Challenge Card",
    "gui.blocklyText.tello.midfastest": "m-1",
    "gui.blocklyText.tello.midlatest": "m-2",
    "gui.blocklyText.tello.telloToggleMon": "[TOGGLE] Challenge Card Detection",
    "gui.blocklyText.tello.telloSetMDireaction": "Set the detection position [POSITION]",
    "gui.blocklyText.tello.telloSetWIFI": "Modify Tello's Wi-Fi as a hotspot [NAME] password [PASSWD]",
    "gui.blocklyText.tello.telloToggleGroup": "Switch to station mode, connect to the hotspot [NAME] password [PASSWD]",
    "gui.blocklyText.telloGroup.telloRise": "Rise",
    "gui.blocklyText.telloGroup.telloDown": "Down",
    "gui.blocklyText.telloGroup.telloGoahead": "Go",
    "gui.blocklyText.telloGroup.telloBack": "Back",
    "gui.blocklyText.telloGroup.telloTurnLeft": "Left",
    "gui.blocklyText.telloGroup.telloTurnRight": "Right",
    "gui.blocklyText.telloGroup.telloSetFly": "[DIRECTION] [NUMS]cm",
    "gui.blocklyText.telloGroup.telloClockwiseRotation": "Clockwise",
    "gui.blocklyText.telloGroup.telloCounterclockwiseSelection": "Counterclockwise",
    "gui.blocklyText.telloGroup.telloClockRotation": "[CLOCKWISE] Rotation [NUMS] degrees",
    "gui.blocklyText.telloGroup.setTelloIndexWithSN": "set Tello SN[SN] number as [INDEX]",
    "gui.blocklyText.telloGroup.setTelloIndexWithSSID": "set Tello SSID[SSID] number as [INDEX]",
    "gui.blocklyText.telloGroup.sanTelloInNetwork": "scan [NUM] Tello in network timeout [TIME]s",
    "gui.blocklyText.telloGroup.telloSynchronousCommand": "Synchronous command waiting time [TIME] seconds",
    "gui.blocklyText.tello.telloVideoToggle": "turn Tello`s video [VIDEO_STATE]",
    "gui.blocklyText.telloGroup.TEMPH": "Maximum temperature",
    "gui.blocklyText.telloGroup.TEMPL": "lowest temperature",
    "gui.blocklyText.telloGroup.NUM": "number ",
    "gui.blocklyText.telloGroup.pitch": "pitch",
    "gui.blocklyText.telloGroup.roll": "roll",
    "gui.blocklyText.telloGroup.translation": "translation",
    "gui.blocklyText.telloGroup.telloAccelerationXYZ": "Acceleration [XYZ] (0.001g)",
    "gui.blocklyText.telloGroup.telloSpeedXYZ": "Speed [XYZ] (cm/s)",
    "gui.blocklyText.telloGroup.TelloAttitudePitch": "[ANGEL] attitude angle(°)",
    "gui.blocklyText.telloGroup.telloTempMinAndMax": "Main board [TEMP](°C)",
    "gui.blocklyText.tello.telloDisplayRollText": "Scroll display text direction [DIRECT] frequency [SPEED] text [TEXT] color [COLOR]",
    "gui.blocklyText.tello.telloDisplayText": "Display a single character [TEXT] color [COLOR]",
    "gui.blocklyText.tello.telloRollText_up": "Up",
    "gui.blocklyText.tello.telloRollText_down": "Down",
    "gui.blocklyText.tello.telloRollText_left": "Left",
    "gui.blocklyText.tello.telloRollText_right": "Right",
    "gui.blocklyText.telloGroup.initTelloGroup": "Disconnect",
    "gui.blocklyText.tello.shutdownForPower": "The battery is insufficient [POWER], Tello will shut down soon",
    "gui.blocklyText.tello.portCantUse": "Service error, port [PORT] access failed, please check if it is occupied by other programs",
    "gui.blocklyText.tello.telloReadESP32Version": "the firmware version of the expansion module",
    "gui.blocklyText.tello.telloSSID": "the SSID of the expansion module",
    "gui.blocklyText.tello.telloLightLEDWithColor": "LED color[COLOR]",
    "gui.blocklyText.tello.telloLightLEDWithColor_rgb": "LED color R:[RGBR] G:[RGBG] B:[RGBB]",
    "gui.blocklyText.tello.telloBreathLEDWithColor": "LED breathing light color [COLOR] frequency [TIME](Hz)",
    "gui.blocklyText.tello.telloBreathLEDWithColor_rgb": "LED breathing light R:[RGBR] G:[RGBG] B:[RGBB] frequency:[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor": "LED flashes color 1[COLOR1] and color 2[COLOR2] frequency [TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor_rgb": "LED flashing R1:[RGBR1] G1:[RGBG1] B1:[RGBB1] - R2:[RGBR2] G2:[RGBG2] B2:[RGBB2] frequency:[TIME](Hz)",
    "gui.blocklyText.tello.telloShowPattern": "Display pattern [MARK]",
    "gui.blocklyText.tello.telloShowPatternWhenStart": "Set the boot pattern [MARK]",
    "gui.blocklyText.tello.telloShowRollPattern": "Rolling display pattern direction [DIRECT] frequency [SPEED] pattern [MARK]",
    "gui.blocklyText.tello.telloSetLight": "Set screen brightness [NUM]",
    "gui.blocklyText.tello.telloSendCMD": "Send custom command [CMD]",
    "gui.blocklyText.tello.telloSendDirective": "Send a clear message [CMD]",
    "gui.blocklyText.tello.telloReceiveMsg": "Received string message [MSG]",
    "gui.blocklyText.tello.telloReceiveCustomNum": "Customized response value received",
    "gui.blocklyText.tello.connectTelloWIFIError": "Failed to connect to Tello Wi-Fi, please try to connect to Wi-Fi manually",
    "gui.blocklyText.tello.connectTelloError": "Failed to connect to Tello, please confirm the device status and reconnect",
    "gui.blocklyText.tello.tempIsHigh": "Tello temperature is too high and will shut down soon",
    "gui.blocklyText.tello.scanErrorWithOpenWlan": "An error occurred during scanning, please check whether Wi-Fi is turned on,Please try to connect to Wi-Fi manually",
    "gui.blocklyText.tello.noTelloFound": "No Tello device available, please try to connect to Wi-Fi manually",
    "gui.blocklyText.tello.connectIsBroke": "Tello connection has been disconnected, please check the device",
    "gui.blocklyText.tello.firstConnectTello": "Please connect to Tello first",
    "gui.blocklyText.tello.telloQueryWifiVersion": "the Wi-Fi version of the extension module",
    "gui.blocklyText.tello.telloQueryHardwareVersion": "Get Hardware Version",
    "gui.blocklyText.tello.telloToggleTOF": "[TOGGLE] TOF Module",
    "gui.blocklyText.tello.telloToggleTOF_enable": "Enable",
    "gui.blocklyText.tello.telloToggleTOF_disabled": "Disable",
    "gui.blocklyText.tello.activeTelloFirst": "Connection failed, the new aircraft is not activated, please follow the aircraft manual to activate",
    "gui.blocklyText.tello.startPaddle": "Enter propeller mode",
    "gui.blocklyText.tello.stopPaddle": "Exit the propeller mode",
    "gui.blocklyText.tello.togglePaddle": "[TOGGLE] Paddle Mode",
    "gui.blocklyText.tello.telloTogglePaddle_enable": "Enter",
    "gui.blocklyText.tello.telloTogglePaddle_disabled": "Exit",
    "gui.blocklyText.tello.telloThrowAway": "Throw away in 5 seconds",
    "gui.blocklyText.tello.telloResetPattern": "Restore the default boot pattern",
    "gui.blocklyText.tello.telloQueryTelloVersion": "Tello firmware version",
    "gui.blocklyText.tello.telloQueryBatActive": "Tello query battery level",
    "gui.blocklyText.tello.telloReadTOF": "Read TOF(mm)",
    "gui.blocklyText.telloGroup.allTellos": "all",
    "gui.blocklyText.tello.notAllowInput": "Please enter numbers or English characters, no other special characters",
    "gui.blocklyText.tello.checkFirewallAndTello": "The line is disconnected, Please check the tello connection status, and please add the software to the computer firewall whitelist",
    "gui.blocklyText.telloGroup.checkWlanNum": "Please make sure the machine is connected to the correct network, if it is connected to multiple networks, please disconnect other connections first",
    "gui.blocklyText.telloGroup.telloIsNotExist": "[INDEX] Tello does not exist",
    "gui.blocklyText.telloGroup.findTelloWithLost": "[FOUND] found, [NOTFOUND] not found, ip:[IP]",
    "gui.blocklyText.telloGroup.findTello": "[FOUND] found, ip:[IP]",
    "gui.blocklyText.telloGroup.cantRepeatScan": "Scanning, please do not repeat scanning...",
    "gui.blocklyText.telloGroup.setIndexForTelloFirst": "Please set the number first",
    "gui.blocklyText.maixduino.programMain": "K120 Maixduino starts",
    "gui.blocklyText.maixduino.LCDInit": "Display initialize freq [FREQ] Hz, background [COLOR] and Invertierung [INVERT]",
    "gui.blocklyText.maixduino.LCDShowImageFromPath": "Display image path: [PATH]",
    "gui.blocklyText.maixduino.LCDShowImageFromImage": "Display image: [IMAGE]",
    "gui.blocklyText.maixduino.LCDShowText": "Display words [TEXT]  at position x [X] y [Y] in [TEXT_COLOR] background [BACKGROUND_COLOR]",
    "gui.blocklyText.maixduino.LCDFill": "Clear the display to [COLOR] background",
    "gui.blocklyText.maixduino.LCDRotation": "Display rotate [ANGLE]",
    "gui.blocklyText.maixduino.LCDMirror": "Screen mirror is [MIRROR]",
    "gui.blocklyText.maixduino.LCDSize": "Display resolution [SIZE]",
    "gui.blocklyText.maixduino.LCDRGB888": "RGB888 red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.maixduino.LCDColor": "Color [COLOR]",
    "gui.blocklyText.blockTitle.maixduino.Camera": "Camera",
    "gui.blocklyText.maixduino.CameraInit": "Camera initialize [MODE] parameters [SETTINGS]",
    "gui.blocklyText.maixduino.CameraGetImage": "Camera get image",
    "gui.blocklyText.maixduino.CameraControl": "Double eye camera choose [CONTROL]",
    "gui.blocklyText.maixduino.CameraHorizontalMirroring": "Camera mirror-horizontal is [CONTROL]",
    "gui.blocklyText.maixduino.CameraVerticalMirroring": "Camera mirror-vertical is [CONTROL]",
    "gui.blocklyText.maixduino.CameraColorBarMode": "Camera colorful strips is [CONTROL]",
    "gui.blocklyText.maixduino.CameraSettingParameters": "Camera set [PARAMETERS] as [VALUE]",
    "gui.blocklyText.maixduino.CameraSettingWindow": "Camera set window width [WIDTH] height [HEIGHT]",
    "gui.blocklyText.maixduino.CameraGetParameters": "Camera get [PARAMETERS]",
    "gui.blocklyText.blockTitle.audioAndVideo": "Audio and Video",
    "gui.blocklyText.maixduino.AudioPlay": "Audio play [PATH] [WAIT]",
    "gui.blocklyText.maixduino.AudioVolume": "Audio volume [VOLUME] %",
    "gui.blocklyText.maixduino.AudioState": "Get audio play status",
    "gui.blocklyText.maixduino.AudioRecord": "Audio record [PATH] for [TIME] s and [WAIT]",
    "gui.blocklyText.maixduino.VideoPlay": "Video play [PATH] [WAIT]",
    "gui.blocklyText.maixduino.VideoVolume": "Video volume [VOLUME] %",
    "gui.blocklyText.maixduino.VideoState": "Get video play status",
    "gui.blocklyText.maixduino.VideoRecord": "Video record [PATH] for [TIME] s and [WAIT]",
    "gui.blocklyText.maixduino.digitalRead": "Read digital pin [PIN]",
    "gui.blocklyText.maixduino.analogRead": "Read analog pin [PIN]",
    "gui.blocklyText.maixduino.digitalWrite": "Digital pin [PIN] output [LEVEL]",
    "gui.blocklyText.maixduino.PWMInit": "Initialize PWM pin [PIN] freq [FREQ] Hz timer [TIMER]",
    "gui.blocklyText.maixduino.PWMWrite": "PWM pin [PIN] output duty cycle [DUTY]",
    "gui.blocklyText.maixduino.ticksDiff": "Return execute time difference, begin at [STARTTIME] and end at [ENDTIME]",
    "gui.blocklyText.maixduino.runTimer": "Execute time [TIMETYPE]",
    "gui.blocklyText.maixduino.gc": "[GC] garbage collection",
    "gui.blocklyText.maixduino.systemReset": "Reset",
    "gui.blocklyText.maixduino.print": "Print [DATA]",
    "gui.blocklyText.maixduino.LevelMenu.A": "high",
    "gui.blocklyText.maixduino.LevelMenu.B": "low",
    "gui.blocklyText.maixduino.MirrorMenu.A": "ON",
    "gui.blocklyText.maixduino.MirrorMenu.B": "OFF",
    "gui.blocklyText.maixduino.SelectMenu.A": "eye 0",
    "gui.blocklyText.maixduino.SelectMenu.B": "eye 1",
    "gui.blocklyText.maixduino.SettingParametersMenu.A": "contrast",
    "gui.blocklyText.maixduino.SettingParametersMenu.B": "brightness",
    "gui.blocklyText.maixduino.SettingParametersMenu.C": "gain value",
    "gui.blocklyText.maixduino.SettingParametersMenu.D": "saturation",
    "gui.blocklyText.maixduino.GetParametersMenu.A": "resolution width",
    "gui.blocklyText.maixduino.GetParametersMenu.B": "resolution width and height",
    "gui.blocklyText.maixduino.GetParametersMenu.C": "gain value",
    "gui.blocklyText.maixduino.GetParametersMenu.D": "frame image cache",
    "gui.blocklyText.maixduino.GetParametersMenu.E": "hardware ID",
    "gui.blocklyText.maixduino.TimeTypeMenu.A": "s",
    "gui.blocklyText.maixduino.TimeTypeMenu.B": "ms",
    "gui.blocklyText.maixduino.TimeTypeMenu.C": "μs",
    "gui.blocklyText.maixduino.GcMenu.A": "automatic",
    "gui.blocklyText.maixduino.GcMenu.B": "start",
    "gui.blocklyText.maixduino.LcdSizeMenu.A": "width",
    "gui.blocklyText.maixduino.LcdSizeMenu.B": "height",
    "gui.blocklyText.maixduino.WaitMenu.A": "wait",
    "gui.blocklyText.maixduino.WaitMenu.B": "background",
    "gui.blocklyText.maixduino.CameraModeMenu.A": "monocular",
    "gui.blocklyText.maixduino.CameraModeMenu.B": "binocular",
    "gui.blocklyText.maixduino.AngleMenu.A": "0°",
    "gui.blocklyText.maixduino.AngleMenu.B": "90°",
    "gui.blocklyText.maixduino.AngleMenu.C": "180°",
    "gui.blocklyText.maixduino.AngleMenu.D": "270°",
    "gui.blocklyText.maixduino.camerainit.frameformat": "Frame Format",
    "gui.blocklyText.maixduino.camerainit.frameformat.RGB565": "color",
    "gui.blocklyText.maixduino.camerainit.frameformat.GRAYSCALE": "gray",
    "gui.blocklyText.maixduino.camerainit.framesize": "Frame Size",
    "gui.blocklyText.maixduino.camerainit.imagecapture": "Screenshot",
    "gui.blocklyText.maixduino.camerainit.imagecapture.on": "ON",
    "gui.blocklyText.maixduino.camerainit.imagecapture.off": "OFF",
    "gui.blocklyText.maixduino.camerainit.skipframes": "Frame Skip No.:",
    "gui.extension.AI.name": "AI",
    "gui.blocklyText.AI.anchorPoint": "Anchor parameters [POINT]",
    "gui.blocklyText.AI.classNames": "Object name [NAMES]",
    "gui.blocklyText.AI.modelLoadingAddress": "Load model [ADDRESS]",
    "gui.blocklyText.AI.modelLoadingPath": "Model path [PATH]",
    "gui.blocklyText.AI.yolo2Init": "yolo2 initialize network model [MODEL] , probability threshold [PT] , box_iou threshold [BT] anchor [APC] and anchor parameter [AP]",
    "gui.blocklyText.AI.yolo2Run": "yolo2 run network model [MODEL] image [IMAGE]",
    "gui.blocklyText.AI.yolo2forward": "yolo2 operation model [MODEL] image [IMAGE]",
    "gui.blocklyText.AI.yolo2Parsing": "yolo2 analytic model object [OBJ] and get [TYPE]",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.A": "X coordinate",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.B": "Y coordinate",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.C": "IP",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.D": "Confidence",
    "gui.blocklyText.machineHearing.newModel": "ARS create blank model",
    "gui.blocklyText.machineHearing.training": "ASR start train words [CORPUS] to add to model [MODEL]",
    "gui.blocklyText.machineHearing.run": "ARS run model [MODEL]",
    "gui.blocklyText.machineHearing.recognize": "ASR speech recognition result",
    "gui.blocklyText.machineHearing.save": "ASR save model [MODEL] to [PATH]",
    "gui.blocklyText.machineHearing.load": "ASR load model [PATH]",
    "gui.extension.machineVision.imageBasis.name": "Machine Vision · Image Basics",
    "gui.blocklyText.machineVision.openEmptyImage": "Open blank image",
    "gui.blocklyText.machineVision.openPathImage": "Open path [PATH]",
    "gui.blocklyText.machineVision.clearImage": "Clear image [IMAGE]",
    "gui.blocklyText.machineVision.saveImage": "Save image [IMAGE] to path [PATH]",
    "gui.blocklyText.machineVision.modifyPixelColor": "Modify image [IMAGE] coordinate xy [COORDINATE] in [COLOR]",
    "gui.blocklyText.machineVision.getPixelColor": "Get color of image [IMAGE] coordinate xy [COORDINATE]",
    "gui.blocklyText.machineVision.captureImage": "Intercept image [IMAGE] area-xywh [AREA]'",
    "gui.blocklyText.machineVision.compressedImage": "Compress image [IMAGE] with rate [RATIO]",
    "gui.blocklyText.machineVision.getImageInfo": "Get image [IMAGE] [INFO]",
    "gui.blocklyText.machineVision.conversion": "Convert image [IMAGE] to [TYPE]",
    "gui.extension.machineVision.imageDrawing.name": "Machine Vision · Image Drawing",
    "gui.blocklyText.machineVision.drawText": "Draw text [IMAGE] start point-xy [COORDINATE] content [CONTENT] in [COLOR] font size [SIZE]",
    "gui.blocklyText.machineVision.drawImage": "Draw [IMAGE] -xy [COORDINATE] to a scaling image -xy [ZOOM]",
    "gui.blocklyText.machineVision.drawLine": "Draw [IMAGE] -xyxy to a line image [COORDINATE] in [COLOR] weight [SIZE]",
    "gui.blocklyText.machineVision.drawArrows": "Draw [IMAGE] -xyxy an arrow image [COORDINATE] in [COLOR] size [SIZE]",
    "gui.blocklyText.machineVision.drawCross": "Draw [IMAGE] -xy a cross [COORDINATE] in [COLOR] extend [EXTEND] weight [SIZE]",
    "gui.blocklyText.machineVision.drawRound": "Draw [IMAGE] -xyr a circle image [COORDINATE] in [COLOR] weight [SIZE] filled [FILLING]",
    "gui.blocklyText.machineVision.drawRectangle": "Draw [IMAGE] -xywh a rectangle [COORDINATE] in [COLOR] weight [SIZE] filled [FILLING]",
    "gui.blocklyText.machineVision.drawFeaturePoints": "Draw [IMAGE] a feature point object [OBJ] in [COLOR] size [SIZE] weight [THICKNESS] filled [FILLING]",
    "gui.extension.machineVision.imageFiltering.name": "Machine Vision · Image Filtering",
    "gui.blocklyText.machineVision.correctionZoom": "Image rectification and scaling [IMAGE]  with correcting value [CORRECTION] and zoom value [ZOOM]",
    "gui.blocklyText.machineVision.histogramEqualization": "Image histogram equalization [IMAGE] self-adopting [ADAPTIVE] at contrast [CONTRAST]",
    "gui.blocklyText.machineVision.fuzzyFilter": "Blur filter [IMAGE] convolution kernel [CONVOLUTIONKERNEL] self-adopting [ADAPTIVE] compensation [MAKEUP]",
    "gui.blocklyText.machineVision.cartoonFilter": "Cartoon filter [IMAGE] original difference [ORIGINALDIFFERENCE] neighboring difference [ADJACENTDIFFERENCE]",
    "gui.blocklyText.machineVision.imageCorrosion": "Erode [IMAGE] convolution kernel [CONVOLUTIONKERNEL] thread value [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageExpansion": "Dilate [IMAGE] convolution kernel [CONVOLUTIONKERNEL] thread value [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageFill": "Image padding [IMAGE] coordinate-xy [COORDINATE] in [COLOR] original difference [ORIGINALDIFFERENCE] neighboring difference [ADJACENTDIFFERENCE] invert [REVERSE] clear [CLEAR]",
    "gui.blocklyText.machineVision.ImageProjection": "Vertical image projection [IMAGE] is [VERTICALPROJECTION]",
    "gui.blocklyText.machineVision.ImageInversion": "Image inversion [IMAGE]",
    "gui.extension.machineVision.shapeRecognition.name": "Machine Vision · Shape Recognition",
    "gui.blocklyText.machineVision.straightLineAnalysis": "Line analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.circularAnalysis": "Circle analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.rectangleAnalysis": "Rectangle analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.lineRecognition": "Line recognition [IMAGE] area-xywh [AREA] thread value [THRESHOLD] includes angle [MERGEANGLE] and slope [MERGESLOPE]",
    "gui.blocklyText.machineVision.lineSegmentRecognition": "Line segment recognition [IMAGE] area- xywh [AREA] space [SPACING] include angle [MERGEANGLE]",
    "gui.blocklyText.machineVision.circleRecognition": "Circle recognition [IMAGE] area-xywh [AREA] thread value [THRESHOLD] min r [MINIMUM] max r [MAXIMUM] step r [STRIDE] include x [COMBINEDX] and y [COMBINEDY] and r [COMBINEDR]",
    "gui.blocklyText.machineVision.rectangleRecognition": "Rectangle recognition [IMAGE] area- xywh [AREA] thread value [THRESHOLD]",
    "gui.extension.machineVision.colorRecognition.name": "Machine Vision · Color Recognition",
    "gui.blocklyText.machineVision.LABFromRGB888": "LAB value depends on RGB888 [RGB888]",
    "gui.blocklyText.machineVision.RGB888FromLAB": "RGB888 value depends on LAB value [LAB]",
    "gui.blocklyText.machineVision.grayscaleFromRGB888": "Gray value depends on RGB888 [RGB888]",
    "gui.blocklyText.machineVision.GRB888FromGrayscale": "RGB888 value depends on gray value [GRAYSCALE]",
    "gui.blocklyText.machineVision.trackingAnalysis": "Track analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.colorAnalysis": "Color analysis object [OBJ] CDF [CDF] to get [INFO]",
    "gui.blocklyText.machineVision.colorPatchTracking": "Color block tracking [IMAGE] threshold value LAB [THRESHOLDLAB] area-xywh [AREA] filtered area [FILTEROUTAREA] filtered pixels [FILTEROUTPRIMENUMBERS] include [MERGE] overlay is [OVERLAPPING]",
    "gui.blocklyText.machineVision.colorRecognition": "Color recognition [IMAGE] area-xywh [AREA]",
    "gui.extension.machineVision.dimensionCodeRecognition.name": "Machine Vision · Code Recognition",
    "gui.blocklyText.machineVision.barcodeAnalysis": "Barcode analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.QRCodeAnalysis": "QR code analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.aprilTagAnalysis": "ApriTag analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.BarCodeRecognition": "Barcode recognize [IMAGE] area-xywh [AREA]",
    "gui.blocklyText.machineVision.QRCodeRecognition": "QR code recognize [IMAGE] area-xywh [AREA]",
    "gui.blocklyText.machineVision.AprilTagRecognition": "ApriTag recognize [IMAGE] area-xywh [AREA]",
    "gui.extension.machineVision.featureRecognition.name": "Machine Vision · Feature Recognition",
    "gui.blocklyText.machineVision.featureInformation": "Feature informaiton object [OBJ] get [INFO]",
    "gui.blocklyText.machineVision.directionalGradienMap": "Directted gradient image [IMAGE] area-xywh [AREA] length [LINELENGTH]",
    "gui.blocklyText.machineVision.featureRecognition": "Feature recognition [IMAGE] area-xywh [AREA] key points [KEYPOINTS] angle points [CORNERPOINTS] scale factor [SCALEFACTOR] multiple scale is [MULTIRATIO]",
    "gui.blocklyText.machineVision.featureComparison": "Feature contrast Object1 [OBJ1] Object2 [OBJ2] similarity [SIMILARITY]",
    "gui.blocklyText.machineVision.FeatureInformationMenu.A": "coordinate-xywh",
    "gui.blocklyText.machineVision.FeatureInformationMenu.B": "x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.C": "y",
    "gui.blocklyText.machineVision.FeatureInformationMenu.D": "w",
    "gui.blocklyText.machineVision.FeatureInformationMenu.E": "h",
    "gui.blocklyText.machineVision.FeatureInformationMenu.F": "center-x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.G": "center-y",
    "gui.blocklyText.machineVision.FeatureInformationMenu.H": "matach value",
    "gui.blocklyText.machineVision.FeatureInformationMenu.I": "radian",
    "gui.blocklyText.machineVision.FeatureInformationMenu.J": "match point-xy",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.H": "number",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.I": "family",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.J": "radian",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.K": "x distance",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.L": "y distance",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.M": "z distance",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.N": "x radian",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.O": "y radian",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.P": "z radian",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.F": "content",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.G": "version",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.H": "mask",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.I": "type",
    "gui.blocklyText.machineVision.BarcodeAnalysisMenu.H": "angle",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.A": "gray value",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.B": "LAB L",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.C": "LAB A",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.D": "LAB B",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.H": "pixels",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.J": "number",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.K": "include",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.L": "area",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.M": "density ratio",
    "gui.blocklyText.machineVision.CircularInformationMenu.A": "coordinate-xyr",
    "gui.blocklyText.machineVision.CircularInformationMenu.D": "r",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.B": "x1",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.C": "y1",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.D": "x2",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.E": "y2",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.F": "length",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.H": "slope",
    "gui.blocklyText.machineVision.ImageInfoMenu.A": "width",
    "gui.blocklyText.machineVision.ImageInfoMenu.B": "height",
    "gui.blocklyText.machineVision.ImageInfoMenu.C": "format",
    "gui.blocklyText.machineVision.ImageInfoMenu.D": "size",
    "gui.blocklyText.machineVision.ConversionTypeMenu.A": "Gray",
    "gui.blocklyText.machineVision.ConversionTypeMenu.B": "Color",
    "gui.blocklyText.machineVision.ConversionTypeMenu.C": "Rainbow",
    "gui.blocklyText.machineVision.ConversionTypeMenu.D": "AI",
    "gui.extension.mpyirq.name": "Unterbrechung",
    "gui.blocklyText.mpyirq.event": "Interrupt-Pin [PIN] Modus [MODE] ausführen",
    "gui.blocklyText.mpyirq.clean": "cancel Interrupt-Pin [PIN]",
    "calliope.categoryName": "CALLIOPE",
    "gui.blocklyText.blockTitle.motor": "Motor",
    "gui.blocklyText.blockTitle.rgblightcontrol": "Lichtstärke lesen",
    "gui.blocklyText.microbit.start": "öffnen",
    "gui.blocklyText.microbit.stop": "schließen",
    "gui.blocklyText.calliope.whenBtnPress": "wenn Taste [REFERENCE] gedrückt",
    "gui.blocklyText.calliope.whenPinConnected": "wenn Pin [REFERENCE] angeschlossen",
    "gui.blocklyText.calliope.calliopeReadBrightness": "Umgebungslicht-Helligkeit lesen",
    "gui.blocklyText.calliope.playNote": "[PIN] Lautsprecher Ton abspielen [NOTE] für [BEAT] Takt",
    "gui.blocklyText.calliope.playSoundUntil": "[PIN] Lautsprecher Ton abspielen [SOUND] bis fertig",
    "gui.blocklyText.calliope.playSound": "[PIN] Lautsprecher Ton abspielen [SOUND]",
    "gui.blocklyText.calliope.calliopeEnableLight": "Bildschirm [ENABLE]",
    "gui.blocklyText.calliope.hideAllLights": "Bildschirm löschen",
    "gui.blocklyText.calliope.loudness": "Lautstärke lesen",
    "gui.blocklyText.calliope.rotation": "Rotationswinkel(°) lesen [TYPE]",
    "gui.blocklyText.calliope.calliopeControlSinglelMotor": "Einzelmotorsteuerung [RANGE]%",
    "gui.blocklyText.calliope.calliopeSingleMotorStop": "Einzelmotor [STOPTYPE]",
    "gui.blocklyText.calliope.calliopeDoubleMotor": "Steuerung Motor [MOTORTYPE] [RANGE]%",
    "gui.blocklyText.calliope.calliopeDoubleMotorStop": "Motor anhalten [MOTORTYPE]",
    "gui.blocklyText.calliope.calliopeLight": "RGB-LED auf [COLOR] setzen",
    "gui.blocklyText.calliope.setRGBColor": "rot [RED] grün [GREEN] blau [BLUE] weiß [WHITE]",
    "gui.blocklyText.calliope.calliopeCloseRGB": "RGB-LED ausschalten",
    "gui.blocklyText.calliope.pitch": "Neigung",
    "gui.blocklyText.calliope.roll": "Rollen",
    "gui.blocklyText.calliope.boardbuzzer": "Eingebauter Buzzer",
    "gui.blocklyText.calliope.motorbreak": "Pause",
    "gui.blocklyText.calliope.motorcoast": "ausrollen",
    "gui.blocklyText.calliope.motorsleep": "schlafen",
    "gui.blocklyText.calliope.open": "ein",
    "gui.blocklyText.calliope.close": "aus",
    "gui.blocklyText.bos0001.readRotary": "lesen [PIN]-Knopf Modulwert",
    "gui.blocklyText.bos0002.IsPINPress": "[PIN]-Taste gedrückt?",
    "gui.blocklyText.bos0003.IsPINPress": "[PIN] Selbstverriegelungsschalter gedrückt?",
    "gui.blocklyText.bos0004.readLight": "lesen [PIN] Lichtmodulwert",
    "gui.blocklyText.bos0006.readMoisture": "lesen [PIN] Feuchtemodulwert",
    "gui.blocklyText.bos0007.readFlame": "lesen [PIN] Flammenmodulwert",
    "gui.blocklyText.bos0008.IsPINTouch": "[PIN] Berührung zum Verbinden?",
    "gui.blocklyText.bos0009.readSound": "lesen [PIN] Schallmodulwert",
    "gui.blocklyText.bos0010.readSmartGray": "lesen [PIN] Graumodulwert",
    "gui.blocklyText.bos0012.IsConductivitySwitch": "[PIN] Leitfähigkeitsmodul ist eingeschaltet?",
    "gui.blocklyText.bos0013.IsMotionSensor": "[PIN] Bewegungsmodul auslösen?",
    "gui.blocklyText.bos0038.readSoilHumidity": "lese [PIN] Bodenfeuchtigkeitswert",
    "gui.blocklyText.bos0045.readUltrasonicDistance": "lese [PIN] Ultraschallmesswert",
    "gui.blocklyText.bos0016.lightBrightLED": "[CONTROL][PIN] LED-Licht hervorheben",
    "gui.blocklyText.bos0017.LEDModule": "[CONTROL][PIN]LED-Licht",
    "gui.blocklyText.bos0019.LEDStringLight": "[CONTROL][PIN] feste Farbe Lichtleiste",
    "gui.blocklyText.bos0021.fanModule": "[CONTROL][PIN] Lüfter",
    "gui.blocklyText.bos0022.voiceRecorderModule": "[PIN] eine Aufnahme abspielen",
    "gui.blocklyText.obloq.initialI2CParameter": "Obloq mqtt initial parameter [PARAMETER] interface I2C",
    "gui.blocklyText.obloq.httpInitialI2CParameter": "Obloq http initial parameter [PARAMETER] interface I2C",
    "gui.blocklyText.wifi.ping": "Wi-Fi ping [HOST]",
    "gui.blocklyText.wifi.scan": "",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "set pin [PIN] DFPlayer MP3 module volume to [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "set pin [PIN] DFPlayer MP3 module playing mode to [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "pin [PIN] DFPlayer MP3 module play the [NUM] song",
    "gui.blocklyText.prompt.telloesp32.emissionPinAndBit_telloWIFI": "The use of wifi may cause an abnormality in the infrared transmitter module",
    "gui.blocklyText.pyTurtle.init": "Create a turtle plotter [NAME]",
    "gui.blocklyText.pyTurtle.turtleSetShape": "[NAME] shape is set to [SHAPE]",
    "gui.blocklyText.pyTurtle.turtleDone": "Drawing is over, continue to keep the window",
    "gui.blocklyText.pyTurtle.turtleExitClick": "Click to exit drawing",
    "gui.blocklyText.pyTurtle.turtleMove": "[NAME]Move[DIRECTION][DISTANCE]",
    "gui.blocklyText.pyTurtle.turtleRotate": "[NAME]Rotation[DIRECTION][ANGLE]",
    "gui.blocklyText.pyTurtle.turtleFaceTo": "[NAME] faces [ANGLE] degrees",
    "gui.blocklyText.pyTurtle.turtleMoveToPos": "[NAME] moves to coordinate X[X] Y[Y] position",
    "gui.blocklyText.pyTurtle.turtleSetPos": "[NAME] [XY] coordinate is set to [POS]",
    "gui.blocklyText.pyTurtle.turtleGetStatus": "[NAME] gets the current [STATUS]",
    "gui.blocklyText.pyTurtle.turtleClearAll": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleClone": "[NAME] Copy Turtle",
    "gui.blocklyText.pyTurtle.turtleVisible": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleSetThickness": "[NAME] Set brush thickness [THICK]",
    "gui.blocklyText.pyTurtle.turtleSetScale": "[NAME] Set the size of the turtle icon. Vertical stretch multiple [VERTICAL] Horizontal stretch multiple [HOR] Outline width [OUTLINE]",
    "gui.blocklyText.pyTurtle.turtleSetSpeed": "[NAME] sets the brush speed to [SPEED]",
    "gui.blocklyText.pyTurtle.turtleSetBackground": "[NAME] set background color [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetPenColor": "[NAME] Set the pen color [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetFillColor": "[NAME] sets the brush fill color [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetColor": "[NAME] Set the brush color [COLOR1] Set the brush fill color [COLOR2]",
    "gui.blocklyText.pyTurtle.turtleDrawAction": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleDrawCircle": "[NAME] draws graphics [CIRCLE] with radius [RADIUS]",
    "gui.blocklyText.pyTurtle.turtleDrawCircleWithAngle": "[NAME] draws a circle with a radius of [RADIUS] and an angle of [DEGREE]",
    "gui.blocklyText.pyTurtle.turtleInitScreen": "[NAME] Initialize the turtle screen [SCREEN]",
    "gui.blocklyText.pyTurtle.turtleSaveDraw": "[NAME] saves the drawn image as a picture [PICTURES]",
    "gui.blocklyText.pyTurtle.turtleSetAnimationInterval": "[NAME] sets the animation playback interval to [TIME] milliseconds",
    "gui.blocklyText.pyTurtle.turtleDrawText": "[NAME] write [TEXT]",
    "gui.blocklyText.pyTurtle.turtleDrawTextWithAttr": "[NAME] Writing [TEXT] The pen moves with the text [JUDGE] Text alignment direction [ALIGN] Font [FONT] Font size [FONTSIZE] Font style [TYPE]",
    "gui.blocklyText.pyTurtle.turtleGetFloat": "Turtle gets the entered floating point number. The title is [TITLE] and the prompt is [TIP] default value [VALUE] minimum value [MIN] maximum value [MAX]",
    "gui.blocklyText.pyTurtle.turtleGetString": "Turtle gets the input string, the title is [TITLE] and the prompt is [TIP]",
    "gui.blocklyText.pyTurtle.roll_forward": "forward",
    "gui.blocklyText.pyTurtle.roll_backward": "Backward",
    "gui.blocklyText.pyTurtle.roll_l_left": "Left",
    "gui.blocklyText.pyTurtle.roll_l_right": "To the right",
    "gui.blocklyText.pyTurtle.xy_x": "X",
    "gui.blocklyText.pyTurtle.xy_y": "Y",
    "gui.blocklyText.pyTurtle.status_pos": "Position",
    "gui.blocklyText.pyTurtle.status_shape": "Shape",
    "gui.blocklyText.pyTurtle.status_heading": "Heading",
    "gui.blocklyText.pyTurtle.status_width": "Brush Width",
    "gui.blocklyText.pyTurtle.status_shapesize": "Turtle icon size",
    "gui.blocklyText.pyTurtle.status_speed": "Speed",
    "gui.blocklyText.pyTurtle.action_clear": "Clear Screen",
    "gui.blocklyText.pyTurtle.action_reset": "Reset",
    "gui.blocklyText.pyTurtle.action_home": "Return to Origin",
    "gui.blocklyText.pyTurtle.pen_action_penup": "Pen up",
    "gui.blocklyText.pyTurtle.pen_action_pendown": "Pendown",
    "gui.blocklyText.pyTurtle.visible_hideturtle": "Hide Turtle",
    "gui.blocklyText.pyTurtle.visible_showturtle": "Show Turtle",
    "gui.blocklyText.pyTurtle.visible_turtle": "Turtle",
    "gui.blocklyText.pyTurtle.visible_arrow": "Arrow",
    "gui.blocklyText.pyTurtle.visible_circle": "Circle",
    "gui.blocklyText.pyTurtle.visible_square": "Block",
    "gui.blocklyText.pyTurtle.visible_triangle": "Triangle",
    "gui.blocklyText.pyTurtle.visible_classic": "Default",
    "gui.blocklyText.pyTurtle.drawaction_begin": "Start filling",
    "gui.blocklyText.pyTurtle.drawaction_end": "End Fill",
    "gui.blocklyText.pyTurtle.circle_circle": "Circle",
    "gui.blocklyText.pyTurtle.circle_dot": "dot",
    "gui.blocklyText.pyTurtle.flag_False": "No",
    "gui.blocklyText.pyTurtle.flag_True": "Yes",
    "gui.blocklyText.pyTurtle.align_left": "Align Left",
    "gui.blocklyText.pyTurtle.align_center": "Centered",
    "gui.blocklyText.pyTurtle.align_right": "Align Right",
    "gui.blocklyText.pyTurtle.align_normal": "Standard",
    "gui.blocklyText.pyTurtle.align_bold": "Bold",
    "gui.blocklyText.pyTurtle.align_italic": "Tilt",
    "gui.blocklyText.pyTurtle.align_bi": "Slanted and bold",
    "gui.blocklyText.pyTurtle.turtleScreenSetup": "Initialize the turtle window size width [WIDTH] height [HEIGHT] offset X [LEFT] Y [TOP]",
    "gui.blocklyText.pyTurtle.turtleScreenSize": "Initialize the turtle canvas width [WIDTH] height [HEIGHT] background color [COLOR]",
    "gui.blocklyText.pyGenerator.importModule": "import [NAME]",
    "gui.blocklyText.pyGenerator.importAs": "import [MOUDLE] as [NAME]",
    "gui.blocklyText.pyGenerator.fromImport": "from [FROM] import [MOUDLE]",
    "gui.blocklyText.pyGenerator.annotation": "# [TEXT]",
    "gui.blocklyText.pyGenerator.defineFunction": "def [FUNCTION]([ARGUMENT]):",
    "gui.blocklyText.pyGenerator.callFunction": "Call function [FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.callFunctionWithValue": "Call function to get the return value [FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.defineClass": "class [CLASS]:",
    "gui.blocklyText.pyGenerator.instanceClass": "[CLASS] [INSTANCE]",
    "gui.blocklyText.pyGenerator.reporterType": "Code [CODE]",
    "gui.blocklyText.pyGenerator.typeBoolean": "Code [CODE]",
    "gui.blocklyText.pyGenerator.annotationBlock": "Block comment",
    "gui.blocklyText.pyGenerator.originCode": "Code [CODE]",
    "gui.blocklyText.blockTitle.onBoardFunc": "Board-Funktionen",
    "paint.paintEditor.hue": "Farbe",
    "paint.paintEditor.saturation": "Sättigung",
    "paint.paintEditor.brightness": "Helligkeit",
    "gui.comingSoon.message1": "Keine Sorge, wir kümmern uns darum {emoji}",
    "gui.comingSoon.message2": "Bald verfügbar...",
    "gui.comingSoon.message3": "Wir arbeiten daran {emoji}",
    "paint.paintEditor.fill": "Füllfarbe",
    "paint.paintEditor.costume": "Kostüm",
    "paint.paintEditor.group": "Gruppieren",
    "paint.paintEditor.ungroup": "Gruppierung aufheben",
    "paint.paintEditor.undo": "Rückgängig",
    "paint.paintEditor.redo": "Wiederherstellen",
    "paint.paintEditor.forward": "nach vorne",
    "paint.paintEditor.backward": "nach hinten",
    "paint.paintEditor.front": "Ganz nach vorne",
    "paint.paintEditor.back": "Ganz nach hinten",
    "paint.paintEditor.more": "Mehr",
    "paint.modeTools.brushSize": "Größe",
    "paint.modeTools.eraserSize": "Radiererbreite",
    "paint.modeTools.copy": "Kopieren",
    "paint.modeTools.paste": "Einfügen",
    "paint.modeTools.delete": "Löschen",
    "paint.modeTools.curved": "gekrümmt",
    "paint.modeTools.pointed": "gerade",
    "paint.modeTools.thickness": "Linienstärke",
    "paint.modeTools.flipHorizontal": "Horizontal spiegeln",
    "paint.modeTools.flipVertical": "Vertikal spiegeln",
    "paint.modeTools.filled": "Ausgefüllt",
    "paint.modeTools.outlined": "Umrandet",
    "paint.paintEditor.bitmap": "In Rastergrafik umwandeln",
    "paint.paintEditor.vector": "In Vektorgrafik umwandeln",
    "paint.paintEditor.stroke": "Randfarbe",
    "paint.brushMode.brush": "Pinsel",
    "paint.eraserMode.eraser": "Radierer",
    "paint.fillMode.fill": "Fülleimer",
    "paint.lineMode.line": "Linie",
    "paint.ovalMode.oval": "Kreis",
    "paint.rectMode.rect": "Rechteck",
    "paint.reshapeMode.reshape": "Verformen",
    "paint.roundedRectMode.roundedRect": "Abgerundetes Rechteck ",
    "paint.selectMode.select": "Auswählen",
    "paint.textMode.text": "Text",
    "paint.colorPicker.swap": "Tauschen",
    "gui.dialog.notConnectDevice": "Kein Gerät angeschlossen ",
    "gui.dialog.prompt": "Hinweis",
    "gui.dialog.openPort": "Port öffnen",
    "gui.dialog.error": "Fehler",
    "gui.dialog.notSelectDevice": "Kein Gerät ausgewählt, bitte klicke auf [Extension] in der unteren linken Ecke der Software,</br>Klicke, um das Gerät auszuwählen, das du verbinden möchtest.",
    "gui.dialog.connectDeviceError1": "Gerät verbinden(",
    "gui.dialog.connectDeviceError2": ")Fehler, bitte versuche folgendes:</br> *USB Stecker entfernen und neu verbinden</br> *Verbindung prüfen",
    "gui.dialog.connectDeviceError3": "Hauptsteuerplatine</br> *Trete der offiziellen Kommunikationsgruppe (671877416) bei, um Probleme zu melden.",
    "gui.dialog.calibration1": "</br> *Bitte drehen",
    "gui.dialog.calibration2": "Kalibriere das Hauptkontroll-Board</br>",
    "gui.dialog.runAsManager": "Bitte schließe Mind+ und klicke mit der rechten Maustaste auf das Softwaresymbol und wähle \"Als Administrator ausführen\" aus. Nach dem Start wähle diese Funktion erneut.",
    "gui.dialog.runErrorForVortex": "System arbeitet nicht richtig, bitte Vortex trennen",
    "gui.dialog.runError": "System arbeitet nicht ordnungsgemäß",
    "gui.dialog.close": "Schließen",
    "gui.dialog.variableNameSpecialCharacters": "Variablenname %1 darf keine Sonderzeichen enthalten:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "Funktionsname %1 darf keine Sonderzeichen enthalten:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "Funktionsargumente %1 dürfen keine Sonderzeichen enthalten:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "und",
    "gui.dialog.yes": "Ja",
    "gui.dialog.no": "Nein",
    "gui.dialog.maxReset": "Die Codes im Editierbereich löschen und die Werkseinstellungscodes laden?",
    "gui.dialog.feedbackCannotEmpty": "Bitte gebe uns eine Rückmeldung.",
    "gui.dialog.noInstallCompiler": "Kein Compiler installiert ",
    "gui.dialog.install": "Online installieren",
    "gui.dialog.cancle": "Abbrechen",
    "gui.dialog.installingCompiler": "Compiler installieren",
    "gui.dialog.uploading": "hochladen",
    "gui.dialog.changeLanuage": "Durch den Wechsel der Sprache wird das aktuelle Programm gelöscht. Bist du sicher, dass du fortfahren möchtest?",
    "gui.dialog.boardNotSupportMpy": "aktuelles Board: %1 unterstützt Micropython nicht",
    "gui.dialog.loadBlankItem": "Nicht unterstütztes Element</br> lädt ein leeres Element nach",
    "gui.dialog.switchBaudError": "Fehler beim Umschalten der Baudrate aufgetreten</br>",
    "gui.dialog.turnMicrobitCompass": "Der elektronische Kompass muss kalibriert werden. Bitte justiere die Einstellung des Microcontrollers, um die blinkenden LED-Lichter zu bewegen, bis alle LEDs leuchten",
    "gui.dialog.operationShow": "Demonstration der Bedienung",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "Computer",
    "gui.dialog.delete": "löschen",
    "gui.dialog.sureDeleteFile": "Bist du sicher, dass du die Datei [%2] auf %1 löschen willst?",
    "gui.dialog.sureDeleteFolder": "Bist du sicher, dass du den Ordner [%2] auf %1 löschen willst?",
    "gui.dialog.uncorrectPath": "Nicht den richtigen Pfad auswählen",
    "gui.dialog.laterDownLoad": "Später aktualisieren",
    "gui.dialog.dwnLoadWebsite": "Website herunterladen",
    "gui.dialog.laterUpdate": "Später installieren",
    "gui.dialog.update": "Installieren",
    "gui.dialog.getUpdateMsgError": "Fehler beim Abrufen der Update-Meldung aufgetreten",
    "gui.dialog.ok": "Ok",
    "gui.dialog.closeCodeBoxes": "Bist du sicher, dass du %1 Codefelder schließen willst?",
    "gui.dialog.emailError": "E-Mail-Format ist falsch!",
    "gui.dialog.emailEmpty": "E-Mail kann nicht leer sein!",
    "gui.dialog.bigFilePrompt": "Die Datei ist groß! Bitte habe Geduld und warte. Danke!",
    "gui.dialog.successFeedbackPrompt1": "Danke für Deine Feedback! Die Seite wird nach 3 Sekunden neu geladen.",
    "gui.dialog.successFeedbackPrompt2": "Dein Feedback wurde abgeschickt. Bitte sende es nach ein paar Sekunden ab.",
    "gui.dialog.failedFeedbackPrompt": "Absenden fehlgeschlagen. Es tut uns sehr leid für die entstandenen Unannehmlichkeiten. Bitte besuche unser Online-Forum, um dein Feedback unter https://www.dfrobot.com/forum/ einzureichen. Vielen Dank!",
    "gui.dialog.successFeedbackPrompt3": "Vielen Dank für dein Feedback",
    "gui.dialog.forcedCloseSoftWare": "Beim Speichern der Datei ist ein Fehler aufgetreten. Software sofort beenden?",
    "gui.dialog.saveSuccess": "Das Projekt wird gespeichert",
    "gui.dialog.noSelectTruePath": "Falscher Pfad gewählt",
    "gui.dialog.newItemContent": "Du wirst ein leeres Projekt erstellen, um das aktuelle Projekt zu überschreiben. Bist du sicher, dass du es erstellen willst?",
    "gui.dialog.deleteBlocksError": "Der Baustein %1 konnte nicht gelöscht werden. Bitte lösche ihn manuell.",
    "gui.dialog.netIsConnect": "Bitte prüfe, ob das Netzwerk angeschlossen ist",
    "gui.dialog.needToUpdate": "Aktualisiere den Compiler auf die neueste Version: %1?",
    "gui.dialog.cantUseMpythonInArduinoC": "Kein Compiler installiert! Du kannst mpython nicht unter Arduino C verwenden. Wenn der Computer kein Netzwerk hat, kannst du das 'Compiler offline plug-in package' von der offiziellen Website herunterladen.",
    "gui.dialog.enterMpyMode": "Micropython-Modus aufrufen",
    "gui.dialog.runHardware": "Schließe zuerst das Gerät an, dann bediene die Hardware",
    "gui.dialog.sureDelete": "Bist du sicher, dass du dies löschen willst?",
    "gui.dialog.notSupportWebGL": "Beim Laden der Bühne ist ein Fehler aufgetreten. Dies kann passieren, wenn dein Computer WebGL nicht unterstützt,</br> Beachte die unten aufgeführten Details: </br> %1 </br>QQ-Gruppe kontaktiere uns: 671877416",
    "gui.dialog.boardIsnotMPython": "Die aktuell angeschlossene Hauptsteuerplatine ist nicht mPython verbunden. Bitte verbinde dich mit mPython",
    "gui.dialog.swdMicrobitUpload": "Deine Computer muss die SWD zum Brennen von micro:bit verwenden. Bitte führe Mind+ mit Administratorrechten aus.",
    "gui.dialog.openPortError": "Fehler beim Öffnen von Port %1: %2",
    "gui.dialog.pressedA": "Bitte halte den Knopf A gedrückt und lasse ihn nicht los.",
    "gui.dialog.unpressedA": "Bitte Taste A loslassen",
    "gui.dialog.unsupportProject": "Nicht unterstütztes Element</br> lädt ein leeres Element nach.",
    "gui.dialog.openProjectError": "Im Scratch-Modus erstellte Projekte müssen im Scratch-Modus geöffnet werden</br> Bitte überprüfe den aktuellen Modus.",
    "gui.dialog.installCompilerError1": "Download fehlgeschlagen! Du kannst den Offline-Installer herunterladen oder es erneut versuchen.",
    "gui.dialog.installCompilerOffline": "Offline-Compiler herunterladen",
    "gui.dialog.installCompilerError2": "Verifizierungsfehler! Du kannst das Offline-Installationsprogramm herunterladen oder den Download erneut versuchen",
    "gui.dialog.installCompilerError3": "Installationsfehler! Du kannst das Offline-Installationsprogramm herunterladen oder den Download erneut versuchen",
    "gui.dialog.installCompilerSuccess": "Der Compiler wurde installiert. Du kannst Esp32 unter ArduinoC verwenden",
    "gui.dialog.sureAdapter": "Der Adapter wurde nicht gefunden. Bitte bestätige, ob der Adapter angeschlossen ist.",
    "gui.dialog.checkPort": "Die serielle Schnittstelle konnte nicht geöffnet werden. Bitte prüfe, ob die serielle Schnittstelle belegt ist.",
    "gui.dialog.changeBoard": "Das Umschalten der Platine löscht das aktuelle Programm. Bist du sicher, dass du fortfahren möchtest?",
    "gui.crashMessage.somethingWrong": "Ups! Etwas ist schief gelaufen.",
    "gui.crashMessage.reload": "Neustarten",
    "gui.crashMessage.submit": "Senden",
    "gui.crashMessage.attachment": "Anlage",
    "gui.crashMessage.errFeedbackNote": "Sie können hier Fehlermeldungen oder Anregungen schreiben. Mind+ wird mit Deineer Unterstützung besser werden.",
    "gui.crashMessage.promptContent": "Es tut uns sehr leid. Es scheint, dass Mind+ abgestürzt ist. Du kannst diesen Fehler an das Mind + Team auf der rechten Seite senden, vielen Dank! Bitte aktualisiere deine Seite, um es erneut zu versuchen.",
    "gui.dialog.isSaveProject": "Das aktuelle Projekt speichern?",
    "gui.dialog.save": "Speichern",
    "gui.dialog.notSave": "Nicht speichern",
    "gui.dialog.continue": "Weiter",
    "gui.dialog.downLoadUpdate": "Update herunterladen",
    "gui.crashMessage.cacheListPrompt": "Sie können die Projektdatei im Menü 「Projekt」> 「Cache-Liste」 finden.",
    "gui.dialog.variableIsNull": "Variablenname kann nicht leer sein",
    "gui.dialog.listNameIsNull": "Listenname kann nicht leer sein",
    "gui.dialog.variableIsRepeat": "Variablennamen können nicht wiederholt werden. Bitte ändern.",
    "gui.dialog.pictureAINotNull": "kann nicht null sein",
    "gui.dialog.pictureAIInitServer": "Bitte initialisiere zuerst den Server.",
    "gui.dialog.pictureAIInitServerError": "Fehler beim Initialisieren des Servers. Bitte prüfe die Kontonummer und den geheimen Schlüssel.",
    "gui.dialog.versionPrompt": "aktuelle sb3-Dateiversion「%1」",
    "gui.dialog.versionGreater": "Sb3-Dateiversion ist höher als die aktuelle Softwareversion, sb3-Dateiversion「%1」",
    "gui.dialog.noModulePrompt": "Es gibt keine kleinen Module unter der aktuellen Klassifizierung. Siehe Hilfe für Details.",
    "gui.dialog.firstSelectBoard": "Bitte Wähle zuerst 'Board' oder 'Kit'.",
    "gui.dialog.downloadFileError": "Fehler beim Herunterladen der Datei.",
    "gui.dialog.noSearchModules": "Keine zugehörigen Module gefunden. Bitte prüfe den Suchpfad.",
    "gui.dialog.networkError": "Netzwerk-Fehler",
    "gui.dialog.saveThirdModulesError": "Fehler beim Speichern der Benutzerbibliothek.",
    "gui.dialog.coverUseExForLoadProject": "Es existiert eine Bibliothek mit dem gleichen Namen in der Anwenderbibliothek, die im Projekt durch die Anwenderbibliothek ersetzt wurde.",
    "gui.dialog.isContinueUpdateThirdModule": "Es gibt derzeit die gleichen Benutzererweiterungsbibliotheken. Wenn du mit der Aktualisierung fortfährst, wird die aktuelle Benutzerbibliothek überschrieben. Möchtest du fortfahren?",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "Es wurde das Feld 「%2」 von %1 geändert, das manuell in die Erweiterungsbibliothek importiert werden muss. Diese Aktualisierung wird abgebrochen.",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "Fehler beim Parsen von %1, diese Aktualisierung wird fortgesetzt, aber der Inhalt in 「%2」 wird nicht aktualisiert",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "Die Form des Bausteins oder der Typ des Eingabefelds wurde geändert. Bitte lösche den markierten Baustein im Programmierbereich manuell und aktualisiere ihn anschließend. Das Aktualisieren wird abgebrochen.",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh1": "Um die Form des Bausteins oder den Typ des Eingabefeldes zu ändern, löschen bitte den markierten Baustein im Programmierbereich manuell und überprüfe das Sprite「%1」vor dem Aktualisieren.",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh2": "Die Form des Blocks oder der Typ des Eingabefelds wurde geändert. Bitte prüfe das Sprite「%1」und aktualisieren es erneut. Das Aktualisieren wird abgebrochen.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "Änderung der Form des Bausteins oder des Typs des Eingabefeldes, bitte löschen den markierten Baustein im Programmierbereich vor dem Laden manuell, und das Laden wird abgebrochen.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad1": "Um die Form des Bausteins oder den Typ des Eingabefeldes zu ändern, lösche bitte manuell den markierten Baustein im Programmierbereich und prüfe das Sprite「%1」vor dem Laden, das Laden wird diesmal abgebrochen.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad2": "Die Form des Blocks oder der Typ des Eingabefeldes wird geändert. Bitte überprüfe das Sprite「%1」und lade es erneut. Dieses Laden wird abgebrochen.",
    "gui.dialog.thirdex.filesEmpty": "Das Dateifeld darf nicht leer sein, da sonst zugehörige Dateien verloren gehen können. Bitte überprüfe die importierten User-Library-Dateien!",
    "gui.dialog.thirdex.refreshing": "aktualisieren...",
    "gui.dialog.thirdex.refreshSuccess": "Aktualisierung erfolgreich",
    "gui.dialog.isSaving": "Abspeichern...",
    "gui.dialog.isExporting": "Exportieren...",
    "gui.dialog.mpySb3Prompt": "「%1」hat den Micropython-Modus aktualisiert. Wenn der Inhalt des Projekts fehlerhaft ist, musst du es manuell ändern.",
    "gui.dialog.translate.fieldError": "Die folgenden Fehlerfelder können zu Fehlern bei der Anzeige von Bausteinen führen.",
    "gui.dialog.translate.startReadFile": "Startet das Lesen der Dateien. Bitte habe etwas Geduld...",
    "gui.dialog.translate.fileFormatError": "Es gibt keinen relevanten Inhalt der Übersetzungsdatei. Das Format des Dateiinhalts stimmt nicht überein.",
    "gui.dialog.connecting.requestCloseFW": "Prüfe, ob die Firewall dieses Rechners geöffnet ist. Dies führt dazu, dass Tello sich nicht normal verbinden kann, klicke auf OK, um die Firewall zu schließen.",
    "gui.dialog.turnCalliopeCompass": "Du musst den elektronischen Kompass kalibrieren. Bitte passe die Einstellung des Tellos an, um die blinkenden LED-Lichter zu bewegen, bis alle LED-Lichter leuchten.",
    "gui.dialog.downLoading": "Herunterladen…",
    "gui.dialog.cancelSave": "Speichern abbrechen",
    "BACKDROPS_ARCTIC": "Arktis",
    "BACKDROPS_BASEBALL": "Baseball",
    "BACKDROPS_BASEBALL_2": "Baseball 2",
    "BACKDROPS_BASKETBALL": "Basketball",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Strand Malibu",
    "BACKDROPS_BEACH_RIO": "Strand Rio",
    "BACKDROPS_BEDROOM": "Schlafzimmer",
    "BACKDROPS_BEDROOM_2": "Schlafraum 2",
    "BACKDROPS_BEDROOM_3": "Schlafraum 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Bank mit Aussicht",
    "BACKDROPS_BLUE_SKY": "Blauer Himmel",
    "BACKDROPS_BLUE_SKY_2": "Blauer Himmel 2",
    "BACKDROPS_BLUE_SKY3": "Blauer Himmel3",
    "BACKDROPS_BOARDWALK": "Uferpromenade",
    "BACKDROPS_CANYON": "Schlucht",
    "BACKDROPS_CASTLE_1": "Schloss 1",
    "BACKDROPS_CASTLE_2": "Schloss 2",
    "BACKDROPS_CASTLE_3": "Schloss 3",
    "BACKDROPS_CASTLE_4": "Schloss 4",
    "BACKDROPS_CHALKBOARD": "Kreidetafel",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "Kreise",
    "BACKDROPS_CITY_WITH_WATER": "Stadt mit Wasser",
    "BACKDROPS_COLORFUL_CITY": "Bunte Stadt",
    "BACKDROPS_CONCERT": "Konzert",
    "BACKDROPS_DESERT": "Wüste",
    "BACKDROPS_FARM": "Bauernhof",
    "BACKDROPS_FIELD_AT_MIT": "Feld bei Mit",
    "BACKDROPS_FLOWERS": "Blumen",
    "BACKDROPS_FOREST": "Wald",
    "BACKDROPS_GALAXY": "Galaxie",
    "BACKDROPS_GARDEN-ROCK": "Garten-Rock",
    "BACKDROPS_GREEK_THEATER": "Griechisches Theater",
    "BACKDROPS_HALL": "Halle",
    "BACKDROPS_HAY_FIELD": "Heu-Feld",
    "BACKDROPS_GRAFFITI": "Graffiti",
    "BACKDROPS_HEARTS1": "Herzen1",
    "BACKDROPS_HEARTS2": "Herzen2",
    "BACKDROPS_HILL": "Hügel",
    "BACKDROPS_JUNGLE": "Dschungel",
    "BACKDROPS_JURASSIC": "Jurassic",
    "BACKDROPS_LIGHT": "Licht",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": "Mond",
    "BACKDROPS_MOUNTAIN": "Berg",
    "BACKDROPS_MURAL": "Wandmalerei",
    "BACKDROPS_NEBULA": "Nebelfleck",
    "BACKDROPS_NEON_TUNNEL": "Neon-Tunnel",
    "BACKDROPS_NIGHT_CITY": "Nacht Stadt",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Nachtstadt mit Straße",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "Party",
    "BACKDROPS_PATHWAY": "Weg",
    "BACKDROPS_PARTY_ROOM": "Partyraum",
    "BACKDROPS_PLAYGROUND": "Spielplatz",
    "BACKDROPS_PLAYING_FIELD": "Spielfeld",
    "BACKDROPS_POOL": "Schwimmbad",
    "BACKDROPS_PURPLE": "Lila",
    "BACKDROPS_RAYS": "Strahlen",
    "BACKDROPS_REFRIGERATOR": "Kühlschrank",
    "BACKDROPS_ROOM_1": "Zimmer 1",
    "BACKDROPS_ROOM_2": "Zimmer 2",
    "BACKDROPS_SAVANNA": "Savanne",
    "BACKDROPS_SCHOOL": "Schule",
    "BACKDROPS_SLOPES": "Pisten",
    "BACKDROPS_SOCCER": "Fußball",
    "BACKDROPS_SOCCER_2": "Fußball 2",
    "BACKDROPS_SPACE": "Raumfahrt",
    "BACKDROPS_SPACE_2": "Platz 2",
    "BACKDROPS_SPACE_3": "Platz 3",
    "BACKDROPS_SPACE_4": "Platz 4",
    "BACKDROPS_SPOTLIGHT-STAGE": "Spotlight-Bühne",
    "BACKDROPS_STARS": "Sterne",
    "BACKDROPS_STRIPES": "Streifen",
    "BACKDROPS_THEATER": "Theater",
    "BACKDROPS_THEATER_2": "Theater 2",
    "BACKDROPS_TREE": "Baum",
    "BACKDROPS_UNDERWATER_1": "Unterwasser 1",
    "BACKDROPS_UNDERWATER_2": "Unterwasser 2",
    "BACKDROPS_URBAN": "Stadt",
    "BACKDROPS_WALL_1": "Mauer 1",
    "BACKDROPS_WALL_2": "Mauer 2",
    "BACKDROPS_WATER_AND_ROCKS": "Wasser und Felsen",
    "BACKDROPS_WETLAND": "Feuchtgebiet",
    "BACKDROPS_WINTER": "Winter",
    "BACKDROPS_WINTER-LIGHTS": "Winterlichter",
    "BACKDROPS_WITCH_HOUSE": "Hexenhaus",
    "BACKDROPS_WOODS": "Wälder",
    "BACKDROPS_WOODS_AND_BENCH": "Wald und Bank",
    "BACKDROPS_XY-GRID": "Xy-Raster",
    "BACKDROPS_XY-GRID-20PX": "Xy-Raster-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-Raster-30px",
    "COSTUMES_ABBY-A": "Abby-a",
    "COSTUMES_ABBY-B": "Abby-b",
    "COSTUMES_ABBY-C": "Abby-c",
    "COSTUMES_ABBY-D": "Abby-d",
    "COSTUMES_AMON": "Amon",
    "COSTUMES_ANDIE-A": "Andie-a",
    "COSTUMES_ANDIE-B": "Andie-b",
    "COSTUMES_ANDIE-C": "Andie-c",
    "COSTUMES_ANDIE-D": "Andie-d",
    "COSTUMES_ANINA_POP_DOWN": "Anina Pop unten",
    "COSTUMES_ANINA_POP_FRONT": "Anina Pop Vorderseite",
    "COSTUMES_ANINA_POP_L_ARM": "Anina Pop L Arm",
    "COSTUMES_ANINA_POP_LEFT": "Anina Pop Links",
    "COSTUMES_ANINA_POP_R_ARM": "Anina Pop R Arm",
    "COSTUMES_ANINA_POP_RIGHT": "Anina Pop Rechts",
    "COSTUMES_ANINA_POP_STAND": "Anina Pop Ständer",
    "COSTUMES_ANINA_R_CROSS": "Anina R Kreuz",
    "COSTUMES_ANINA_STANCE": "Anina Stehen",
    "COSTUMES_ANINA_TOP_FREEZE": "Anina Oben Einfrieren",
    "COSTUMES_ANINA_TOP_L_STEP": "Anina Oberer L-Schritt",
    "COSTUMES_ANINA_TOP_R_STEP": "Anina Top R Schritt",
    "COSTUMES_ANINA_TOP_STAND": "Anina Top Stand",
    "COSTUMES_APPLE": "Apfel",
    "COSTUMES_ARROW1-A": "Pfeil1-a",
    "COSTUMES_ARROW1-B": "Pfeil1-b",
    "COSTUMES_ARROW1-C": "Pfeil1-c",
    "COSTUMES_ARROW1-D": "Pfeil1-d",
    "COSTUMES_AVERY_WALKING-A": "Avery gehend-a",
    "COSTUMES_AVERY_WALKING-B": "Avery gehend-b",
    "COSTUMES_AVERY_WALKING-C": "Avery gehend-c",
    "COSTUMES_AVERY_WALKING-D": "Avery gehend-d",
    "COSTUMES_AVERY-A": "Avery-a",
    "COSTUMES_AVERY-B": "Avery-b",
    "COSTUMES_BALL-A": "Ball-a",
    "COSTUMES_BALL-B": "Ball-b",
    "COSTUMES_BALL-C": "Ball-c",
    "COSTUMES_BALL-D": "Ball-d",
    "COSTUMES_BALL-E": "Ball-e",
    "COSTUMES_BALLERINA-A": "Ballerina-a",
    "COSTUMES_BALLERINA-B": "Ballerina-b",
    "COSTUMES_BALLERINA-C": "Ballerina-c",
    "COSTUMES_BALLERINA-D": "Ballerina-d",
    "COSTUMES_BALLOON1-A": "Luftballon1-a",
    "COSTUMES_BALLOON1-B": "Luftballon1-b",
    "COSTUMES_BALLOON1-C": "Luftballon1-c",
    "COSTUMES_BANANAS": "Bananen",
    "COSTUMES_BASEBALL": "Baseball",
    "COSTUMES_BASKETBALL": "Basketball",
    "COSTUMES_BAT-A": "Schläger-a",
    "COSTUMES_BAT-B": "Schläger-b",
    "COSTUMES_BAT-C": "Schläger-c",
    "COSTUMES_BAT-D": "Schläger-d",
    "COSTUMES_BATTER-A": "Schläger-a",
    "COSTUMES_BATTER-B": "Schläger-b",
    "COSTUMES_BATTER-C": "Batter-c",
    "COSTUMES_BATTER-D": "Schläger-d",
    "COSTUMES_BEACHBALL": "Beachball",
    "COSTUMES_BEAR-A": "Bär-a",
    "COSTUMES_BEAR-B": "Bär-b",
    "COSTUMES_BEAR-WALK-A": "Bär-Gang-a",
    "COSTUMES_BEAR-WALK-B": "Bären-Gang-b",
    "COSTUMES_BEAR-WALK-C": "Bärenspaziergang-c",
    "COSTUMES_BEAR-WALK-D": "Bärengang-d",
    "COSTUMES_BEAR-WALK-E": "Bären-Gang-e",
    "COSTUMES_BEAR-WALK-F": "Bären-Gang-f",
    "COSTUMES_BEAR-WALK-G": "Bären-Gang-g",
    "COSTUMES_BEAR-WALK-H": "Bären-Gang-h",
    "COSTUMES_BEETLE": "Käfer",
    "COSTUMES_BELL1": "Glocke1",
    "COSTUMES_BEN-A": "Ben-a",
    "COSTUMES_BEN-B": "Ben-b",
    "COSTUMES_BEN-C": "Ben-c",
    "COSTUMES_BEN-D": "Ben-d",
    "COSTUMES_BOWL-A": "Schale-a",
    "COSTUMES_BOWTIE": "Bowtie",
    "COSTUMES_BREAD": "Brot",
    "COSTUMES_BROOM": "Besen",
    "COSTUMES_BUILDING-A": "Gebäude-a",
    "COSTUMES_BUILDING-B": "Gebäude-b",
    "COSTUMES_BUILDING-C": "Gebäude-c",
    "COSTUMES_BUILDING-D": "Gebäude-d",
    "COSTUMES_BUILDING-E": "Gebäude-e",
    "COSTUMES_BUILDING-F": "Gebäude-f",
    "COSTUMES_BUILDING-G": "Gebäude-g",
    "COSTUMES_BUILDING-H": "Gebäude-h",
    "COSTUMES_BUILDING-I": "Gebäude-i",
    "COSTUMES_BUILDING-J": "Gebäude-j",
    "COSTUMES_BUTTERFLY1-A": "Schmetterling1-a",
    "COSTUMES_BUTTERFLY1-B": "Schmetterling1-b",
    "COSTUMES_BUTTERFLY1-C": "Schmetterling1-c",
    "COSTUMES_BUTTERFLY2-A": "Schmetterling2-a",
    "COSTUMES_BUTTERFLY2-B": "Schmetterling2-b",
    "COSTUMES_BUTTON1": "Knopf1",
    "COSTUMES_BUTTON2-A": "Taste2-a",
    "COSTUMES_BUTTON2-B": "Taste2-b",
    "COSTUMES_BUTTON3-A": "Taste3-a",
    "COSTUMES_BUTTON3-B": "Taster3-b",
    "COSTUMES_BUTTON4-A": "Taster4-a",
    "COSTUMES_BUTTON4-B": "Taster4-b",
    "COSTUMES_BUTTON5-A": "Taste5-a",
    "COSTUMES_BUTTON5-B": "Taste5-b",
    "COSTUMES_CAKE-A": "Torte-a",
    "COSTUMES_CAKE-B": "Torte-b",
    "COSTUMES_CALVRETT_JUMPING": "Calvrett Springen",
    "COSTUMES_CALVRETT_THINKING": "Calvrett Denkend",
    "COSTUMES_CASEY-A": "Casey-a",
    "COSTUMES_CASEY-B": "Casey-b",
    "COSTUMES_CASEY-C": "Casey-c",
    "COSTUMES_CASEY-D": "Casey-d",
    "COSTUMES_CASSY-A": "Cassy-a",
    "COSTUMES_CASSY-B": "Cassy-b",
    "COSTUMES_CASSY-C": "Cassy-c",
    "COSTUMES_CASSY-D": "Cassy-d",
    "COSTUMES_CAT_2": "Katze-2",
    "COSTUMES_CATCHER-A": "Fänger-a",
    "COSTUMES_CATCHER-B": "Fänger-b",
    "COSTUMES_CATCHER-C": "Fänger-c",
    "COSTUMES_CATCHER-D": "Fänger-d",
    "COSTUMES_CENTAUR-A": "Zentaur-a",
    "COSTUMES_CENTAUR-B": "Zentaur-b",
    "COSTUMES_CENTAUR-C": "Zentaur-c",
    "COSTUMES_CENTAUR-D": "Zentaur-d",
    "COSTUMES_CHAMP99-A": "Champ99-a",
    "COSTUMES_CHAMP99-B": "Champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "Käsebällchen",
    "COSTUMES_CHICK-A": "Küken-a",
    "COSTUMES_CHICK-B": "Küken-b",
    "COSTUMES_CHICK-C": "Küken-c",
    "COSTUMES_CITY_BUS-A": "Stadtbus-a",
    "COSTUMES_CITY_BUS-B": "Stadtbus-b",
    "COSTUMES_CLOUD": "Wolke",
    "COSTUMES_CLOUD-A": "Wolke-a",
    "COSTUMES_CLOUD-B": "Wolke-b",
    "COSTUMES_CLOUD-C": "Wolke-c",
    "COSTUMES_CLOUD-D": "Wolke-d",
    "COSTUMES_CONVERTIBLE": "Wandelbar",
    "COSTUMES_CONVERTIBLE_3": "Cabrio 2",
    "COSTUMES_CRAB-A": "Krabbe-a",
    "COSTUMES_CRAB-B": "Krabbe-b",
    "COSTUMES_CRYSTAL-A": "Kristall-a",
    "COSTUMES_CRYSTAL-B": "Kristall-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "Dee-a",
    "COSTUMES_DEE-B": "Dee-b",
    "COSTUMES_DEE-C": "Dee-c",
    "COSTUMES_DEE-D": "Dee-d",
    "COSTUMES_DEE-E": "Dee-e",
    "COSTUMES_DEVIN-A": "Devin-a",
    "COSTUMES_DEVIN-B": "Devin-b",
    "COSTUMES_DEVIN-C": "Devin-c",
    "COSTUMES_DEVIN-D": "Devin-d",
    "COSTUMES_DINOSAUR1-A": "Dinosaurier1-a",
    "COSTUMES_DINOSAUR1-B": "Dinosaurier1-b",
    "COSTUMES_DINOSAUR1-C": "Dinosaurier1-c",
    "COSTUMES_DINOSAUR1-D": "Dinosaurier1-d",
    "COSTUMES_DINOSAUR2-A": "Dinosaurier2-a",
    "COSTUMES_DINOSAUR2-B": "Dinosaurier2-b",
    "COSTUMES_DINOSAUR2-C": "Dinosaurier2-c",
    "COSTUMES_DINOSAUR2-D": "Dinosaurier2-d",
    "COSTUMES_DINOSAUR3-A": "Dinosaurier3-a",
    "COSTUMES_DINOSAUR3-B": "Dinosaurier3-b",
    "COSTUMES_DINOSAUR3-C": "Dinosaurier3-c",
    "COSTUMES_DINOSAUR3-D": "Dinosaurier3-d",
    "COSTUMES_DINOSAUR3-E": "Dinosaurier3-e",
    "COSTUMES_DINOSAUR4-A": "Dinosaurier4-a",
    "COSTUMES_DINOSAUR4-B": "Dinosaurier4-b",
    "COSTUMES_DINOSAUR4-C": "Dinosaurier4-c",
    "COSTUMES_DINOSAUR4-D": "Dinosaurier4-d",
    "COSTUMES_DIVER1": "Taucher1",
    "COSTUMES_DIVER2": "Taucher2",
    "COSTUMES_DM_FREEZE": "dm einfrieren",
    "COSTUMES_DM_POP_DOWN": "dm pop unten",
    "COSTUMES_DM_POP_FRONT": "dm pop vorne",
    "COSTUMES_DM_POP_L_ARM": "dm pop L Arm",
    "COSTUMES_DM_POP_LEFT": "dm pop links",
    "COSTUMES_DM_POP_R_ARM": "dm pop R Arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop rechts",
    "COSTUMES_DM_POP_STAND": "dm pop Stand",
    "COSTUMES_DM_STANCE": "dm stand",
    "COSTUMES_DM_TOP_L_LEG": "dm oberes L-Bein",
    "COSTUMES_DM_TOP_R_LEG": "dm oberes R-Bein",
    "COSTUMES_DM_TOP_R_LEG2": "dm Oberteil R Bein2",
    "COSTUMES_DM_TOP_STAND": "dm oberer Stand",
    "COSTUMES_DOG1-A": "Hund1-a",
    "COSTUMES_DOG1-B": "Hund1-b",
    "COSTUMES_DOG2-A": "Hund2-a",
    "COSTUMES_DOG2-B": "Hund2-b",
    "COSTUMES_DOG2-C": "Hund2-c",
    "COSTUMES_DONUT": "Donut",
    "COSTUMES_DORIAN-A": "Dorian-a",
    "COSTUMES_DORIAN-B": "Dorian-b",
    "COSTUMES_DORIAN-C": "Dorian-c",
    "COSTUMES_DORIAN-D": "Dorian-d",
    "COSTUMES_DOT-A": "Punkt-a",
    "COSTUMES_DOT-B": "Punkt-b",
    "COSTUMES_DOT-C": "Punkt-c",
    "COSTUMES_DOT-D": "Punkt-d",
    "COSTUMES_DOVE-A": "Taube-a",
    "COSTUMES_DOVE-B": "Taube-b",
    "COSTUMES_DRAGON-A": "Drache-a",
    "COSTUMES_DRAGON-B": "Drache-b",
    "COSTUMES_DRAGON-C": "Drache-c",
    "COSTUMES_DRAGON1-A": "Drache1-a",
    "COSTUMES_DRAGON1-B": "Drache1-b",
    "COSTUMES_DRAGONFLY-A": "Libelle-a",
    "COSTUMES_DRAGONFLY-B": "Libelle-b",
    "COSTUMES_DRESS-A": "Kleid-a",
    "COSTUMES_DRESS-B": "Kleid-b",
    "COSTUMES_DRESS-C": "Kleid-c",
    "COSTUMES_DRUM-A": "Trommel-a",
    "COSTUMES_DRUM-B": "Trommel-b",
    "COSTUMES_DRUM-CYMBAL-A": "Trommel-Zimbel-a",
    "COSTUMES_DRUM-CYMBAL-B": "Trommel-Zimbel-b",
    "COSTUMES_DRUM-HIGHHAT-A": "Schlagzeug-Highhat-a",
    "COSTUMES_DRUM-HIGHHAT-B": "Schlagzeug-Highhat-b",
    "COSTUMES_DRUM-KIT": "Drum-Kit",
    "COSTUMES_DRUM-KIT-B": "Schlagzeug-b",
    "COSTUMES_DRUM-SNARE-A": "Schlagzeug-Snare-a",
    "COSTUMES_DRUM-SNARE-B": "Trommel-Snare-b",
    "COSTUMES_DRUMS_CONGA-A": "Schlagzeug Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Schlagzeug Conga-b",
    "COSTUMES_DUCK": "Ente",
    "COSTUMES_EARTH": "Erde",
    "COSTUMES_EASEL-A": "Staffelei-a",
    "COSTUMES_EASEL-B": "Staffelei-b",
    "COSTUMES_EASEL-C": "Staffelei-c",
    "COSTUMES_EGG-A": "Ei-a",
    "COSTUMES_EGG-B": "Ei-b",
    "COSTUMES_EGG-C": "Ei-c",
    "COSTUMES_EGG-D": "Ei-d",
    "COSTUMES_EGG-E": "Ei-e",
    "COSTUMES_EGG-F": "Ei-f",
    "COSTUMES_ELEPHANT-A": "Elefant-a",
    "COSTUMES_ELEPHANT-B": "Elefant-b",
    "COSTUMES_ELF-A": "Elf-a",
    "COSTUMES_ELF-B": "Elf-b",
    "COSTUMES_ELF-C": "Elf-c",
    "COSTUMES_ELF-D": "Elf-d",
    "COSTUMES_ELF-E": "Elfe-e",
    "COSTUMES_FAIRY-A": "Fee-a",
    "COSTUMES_FAIRY-B": "Fee-b",
    "COSTUMES_FAIRY-C": "Fee-c",
    "COSTUMES_FAIRY-D": "Fee-d",
    "COSTUMES_FAIRY-E": "Fee-e",
    "COSTUMES_FISH-A": "Fisch-a",
    "COSTUMES_FISH-B": "Fisch-b",
    "COSTUMES_FISH-C": "Fisch-c",
    "COSTUMES_FISH-D": "Fisch-d",
    "COSTUMES_FISHBOWL-A": "Fisch-Schale-a",
    "COSTUMES_FISHBOWL-B": "Fischschüssel-b",
    "COSTUMES_FOOD_TRUCK-A": "Food Truck-a",
    "COSTUMES_FOOD_TRUCK-B": "Lebensmittel-Truck-b",
    "COSTUMES_FOOD_TRUCK-C": "Imbisswagen-c",
    "COSTUMES_FOOTBALL_RUNNING": "Fußball laufend",
    "COSTUMES_FOOTBALL_STANDING": "Fußball stehend",
    "COSTUMES_FORTUNE_COOKIE": "Glückskeks",
    "COSTUMES_FOX-A": "Fuchs-a",
    "COSTUMES_FOX-B": "Fuchs-b",
    "COSTUMES_FOX-C": "Fuchs-c",
    "COSTUMES_FRANK-A": "Frank-a",
    "COSTUMES_FRANK-B": "Frank-b",
    "COSTUMES_FRANK-C": "Frank-c",
    "COSTUMES_FRANK-D": "Frank-d",
    "COSTUMES_FROG": "Frosch",
    "COSTUMES_FROG_2-A": "Frosch 2-a",
    "COSTUMES_FROG_2-B": "Frosch 2-b",
    "COSTUMES_FROG_2-C": "Frosch 2-c",
    "COSTUMES_FRUIT_PLATTER": "Obstteller",
    "COSTUMES_FRUITSALAD": "Fruchtsalat",
    "COSTUMES_GHOST-A": "Geist-a",
    "COSTUMES_GHOST-B": "Geist-b",
    "COSTUMES_GHOST-C": "Geist-c",
    "COSTUMES_GHOST-D": "Geist-d",
    "COSTUMES_GIFT-A": "Geschenk-a",
    "COSTUMES_GIFT-B": "Geschenk-b",
    "COSTUMES_GIRAFFE-A": "Giraffe-a",
    "COSTUMES_GIRAFFE-B": "Giraffe-b",
    "COSTUMES_GIRAFFE-C": "Giraffe-c",
    "COSTUMES_GLASS_WATER-A": "Glas Wasser-a",
    "COSTUMES_GLASS_WATER-B": "Glas Wasser-b",
    "COSTUMES_GLASSES-A": "Gläser-a",
    "COSTUMES_GLASSES-B": "Gläser-b",
    "COSTUMES_GLASSES-C": "Gläser-c",
    "COSTUMES_GLASSES-E": "Gläser-e",
    "COSTUMES_GOALIE-A": "Torwart-a",
    "COSTUMES_GOALIE-B": "Torwart-b",
    "COSTUMES_GOALIE-C": "Torwart-c",
    "COSTUMES_GOALIE-D": "Torwart-d",
    "COSTUMES_GOALIE-E": "Torwart-e",
    "COSTUMES_GOBLIN-A": "Kobold-a",
    "COSTUMES_GOBLIN-B": "Kobold-b",
    "COSTUMES_GOBLIN-C": "Kobold-c",
    "COSTUMES_GOBLIN-D": "Kobold-d",
    "COSTUMES_GRASSHOPPER-A": "Grashüpfer-a",
    "COSTUMES_GRASSHOPPER-B": "Grashüpfer-b",
    "COSTUMES_GRASSHOPPER-C": "Grashüpfer-c",
    "COSTUMES_GRASSHOPPER-D": "Grashüpfer-d",
    "COSTUMES_GRASSHOPPER-E": "Grashüpfer-e",
    "COSTUMES_GRASSHOPPER-F": "Grasshüpfer-f",
    "COSTUMES_GREEN_FLAG": "Grüne Flagge",
    "COSTUMES_GRIFFIN-A": "Greifen-a",
    "COSTUMES_GRIFFIN-B": "Greif-b",
    "COSTUMES_GRIFFIN-C": "Greif-c",
    "COSTUMES_GRIFFIN-D": "Greifen-d",
    "COSTUMES_GUITAR-A": "Gitarre-a",
    "COSTUMES_GUITAR-B": "Gitarre-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "Gitarre-elektrisch1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "Gitarre-elektrisch1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "Gitarre-elektrisch2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "Gitarre-elektrisch2-b",
    "COSTUMES_HANNAH-A": "Hannah-a",
    "COSTUMES_HANNAH-B": "Hannah-b",
    "COSTUMES_HANNAH-C": "Hannah-c",
    "COSTUMES_HARE-A": "Hase-a",
    "COSTUMES_HARE-B": "Hase-b",
    "COSTUMES_HARE-C": "Hase-c",
    "COSTUMES_HARPER-A": "Harper-a",
    "COSTUMES_HARPER-B": "Hase-b",
    "COSTUMES_HARPER-C": "Hase-c",
    "COSTUMES_HAT-A": "Hut-a",
    "COSTUMES_HAT-B": "Hut-b",
    "COSTUMES_HAT-C": "Hut-c",
    "COSTUMES_HAT-D": "Hut-d",
    "COSTUMES_HATCHLING-A": "Schlüpfling-a",
    "COSTUMES_HATCHLING-B": "Schlüpfling-b",
    "COSTUMES_HATCHLING-C": "Schlüpfling-c",
    "COSTUMES_HEART_CODE": "Herz-Code",
    "COSTUMES_HEART_FACE": "Herz-Gesicht",
    "COSTUMES_HEART_LOVE": "Herz Liebe",
    "COSTUMES_HEART_PURPLE": "Herz Violett",
    "COSTUMES_HEART_RED": "Herz Rot",
    "COSTUMES_HEART_SMILE": "Herz Lächeln",
    "COSTUMES_HEART_SWEET": "Herz Süß",
    "COSTUMES_HEDGEHOG-A": "Igel-a",
    "COSTUMES_HEDGEHOG-B": "Igel-b",
    "COSTUMES_HEDGEHOG-C": "Igel-c",
    "COSTUMES_HEDGEHOG-D": "Igel-d",
    "COSTUMES_HEDGEHOG-E": "Igel-e",
    "COSTUMES_HEN-A": "Henne-a",
    "COSTUMES_HEN-B": "Henne-b",
    "COSTUMES_HEN-C": "Henne-c",
    "COSTUMES_HEN-D": "Huhn-d",
    "COSTUMES_HIPPO1-A": "Flusspferd1-a",
    "COSTUMES_HIPPO1-B": "Nilpferd1-b",
    "COSTUMES_HOME_BUTTON": "Home-Taste",
    "COSTUMES_HORSE-A": "Pferd-a",
    "COSTUMES_HORSE-B": "Pferd-b",
    "COSTUMES_JAIME_WALKING-A": "Jaime Gehen-a",
    "COSTUMES_JAIME_WALKING-B": "Jaime gehend-b",
    "COSTUMES_JAIME_WALKING-C": "Jaime Gehen-c",
    "COSTUMES_JAIME_WALKING-D": "Jaime Gehen-d",
    "COSTUMES_JAIME_WALKING-E": "Jaime gehend-e",
    "COSTUMES_JAIME-A": "Jaime-a",
    "COSTUMES_JAIME-B": "Jaime-b",
    "COSTUMES_JAMAL-A": "Jamal-a",
    "COSTUMES_JAMAL-B": "Dschamal-b",
    "COSTUMES_JAMAL-C": "Jamal-c",
    "COSTUMES_JAMAL-D": "Dschamal-d",
    "COSTUMES_JAR-A": "Jar-a",
    "COSTUMES_JAR-B": "Jar-b",
    "COSTUMES_JELLYFISH-A": "Qualle-a",
    "COSTUMES_JELLYFISH-B": "Qualle-b",
    "COSTUMES_JELLYFISH-C": "Qualle-c",
    "COSTUMES_JELLYFISH-D": "Qualle-d",
    "COSTUMES_JO_POP_DOWN": "Jo Pop unten",
    "COSTUMES_JO_POP_FRONT": "Jo Pop Vorderseite",
    "COSTUMES_JO_POP_L_ARM": "Jo Pop L Arm",
    "COSTUMES_JO_POP_LEFT": "Jo Pop Links",
    "COSTUMES_JO_POP_R_ARM": "Jo Pop R Arm",
    "COSTUMES_JO_POP_RIGHT": "Jo Pop Rechts",
    "COSTUMES_JO_POP_STAND": "Jo Pop Stand",
    "COSTUMES_JO_STANCE": "Jo Stehend",
    "COSTUMES_JO_TOP_L_CROSS": "Jo Oben L Kreuz",
    "COSTUMES_JO_TOP_L_LEG": "Jo Oberteil L Bein",
    "COSTUMES_JO_TOP_R_CROSS": "Jo Oberteil R Kreuz",
    "COSTUMES_JO_TOP_R_LEG": "Jo Top R Bein",
    "COSTUMES_JO_TOP_STAND": "Jo Oberteil Stand",
    "COSTUMES_JORDYN-A": "Jordyn-a",
    "COSTUMES_JORDYN-B": "Jordyn-b",
    "COSTUMES_JORDYN-C": "Jordyn-c",
    "COSTUMES_JORDYN-D": "Jordyn-d",
    "COSTUMES_KAI-A": "Kai-a",
    "COSTUMES_KAI-B": "Kai-b",
    "COSTUMES_KEY": "Taste",
    "COSTUMES_KEYBOARD-A": "Tastatur-a",
    "COSTUMES_KEYBOARD-B": "Tastatur-b",
    "COSTUMES_KIRAN-A": "Kiran-a",
    "COSTUMES_KIRAN-B": "Kiran-b",
    "COSTUMES_KIRAN-C": "Kiran-c",
    "COSTUMES_KIRAN-D": "Kiran-d",
    "COSTUMES_KIRAN-E": "Kiran-e",
    "COSTUMES_KIRAN-F": "Kiran-f",
    "COSTUMES_KNIGHT": "Ritter",
    "COSTUMES_LADYBUG2": "Marienkäfer1",
    "COSTUMES_LADYBUG2-A": "Marienkäfer2-a",
    "COSTUMES_LADYBUG2-B": "Marienkäfer2-b",
    "COSTUMES_LAPTOP": "Laptop",
    "COSTUMES_LB_POP_DOWN": "Lb Pop unten",
    "COSTUMES_LB_POP_FRONT": "Lb Pop Front",
    "COSTUMES_LB_POP_L_ARM": "Lb Pop L Arm",
    "COSTUMES_LB_POP_LEFT": "Lb Pop Links",
    "COSTUMES_LB_POP_R_ARM": "Lb Pop R Arm",
    "COSTUMES_LB_POP_RIGHT": "Lb Pop Rechts",
    "COSTUMES_LB_POP_STAND": "Lb Pop Stand",
    "COSTUMES_LB_STANCE": "Lb Stand",
    "COSTUMES_LB_TOP_L_CROSS": "Lb Oben L Kreuz",
    "COSTUMES_LB_TOP_L_LEG": "Lb Oben L Bein",
    "COSTUMES_LB_TOP_R_CROSS": "Lb Oben R Kreuz",
    "COSTUMES_LB_TOP_R_LEG": "Lb Oben R Bein",
    "COSTUMES_LB_TOP_STAND": "Lb Oben Stand",
    "COSTUMES_LIGHTNING": "Blitz",
    "COSTUMES_LINE": "Linie",
    "COSTUMES_LION-A": "Löwe-a",
    "COSTUMES_LION-B": "Löwe-b",
    "COSTUMES_LION-C": "Löwe-c",
    "COSTUMES_LLAMA": "Lama",
    "COSTUMES_LLAMA-B": "Llama-b",
    "COSTUMES_LLAMA-C": "Llama-c",
    "COSTUMES_MAGICWAND": "Zauberwand",
    "COSTUMES_MAX-A": "Max-a",
    "COSTUMES_MAX-B": "Max-b",
    "COSTUMES_MAX-C": "Max-c",
    "COSTUMES_MAX-D": "Max-d",
    "COSTUMES_MERMAID-A": "Nixe-a",
    "COSTUMES_MERMAID-B": "Meerjungfrau-b",
    "COSTUMES_MERMAID-C": "Nixe-c",
    "COSTUMES_MERMAID-D": "Meerjungfrau-d",
    "COSTUMES_MICROPHONE-A": "Mikrofon-a",
    "COSTUMES_MICROPHONE-B": "Mikrofon-b",
    "COSTUMES_MILK-A": "Milch-a",
    "COSTUMES_MILK-B": "Milch-b",
    "COSTUMES_MILK-C": "Milch-c",
    "COSTUMES_MILK-D": "Milch-d",
    "COSTUMES_MILK-E": "Milch-e",
    "COSTUMES_MONET-A": "Monet-a",
    "COSTUMES_MONET-B": "Monet-b",
    "COSTUMES_MONET-C": "Monet-c",
    "COSTUMES_MONET-D": "Monet-d",
    "COSTUMES_MONET-E": "Monet-e",
    "COSTUMES_MONKEY-A": "Affe-a",
    "COSTUMES_MONKEY-B": "Affe-b",
    "COSTUMES_MONKEY-C": "Affe-c",
    "COSTUMES_MOTORCYCLE-A": "Motorrad-a",
    "COSTUMES_MOTORCYCLE-B": "Motorrad-b",
    "COSTUMES_MOTORCYCLE-C": "Motorrad-c",
    "COSTUMES_MOTORCYCLE-D": "Motorrad-d",
    "COSTUMES_MOUSE1-A": "Maus1-a",
    "COSTUMES_MOUSE1-B": "Maus1-b",
    "COSTUMES_MUFFIN-A": "Muffin-a",
    "COSTUMES_MUFFIN-B": "Muffin-b",
    "COSTUMES_NEIGH_PONY": "Wieherndes Pony",
    "COSTUMES_OCTOPUS-A": "Oktopus-a",
    "COSTUMES_OCTOPUS-B": "Oktopus-b",
    "COSTUMES_OCTOPUS-C": "Oktopus-c",
    "COSTUMES_OCTOPUS-D": "Oktopus-d",
    "COSTUMES_OCTOPUS-E": "Oktopus-e",
    "COSTUMES_ORANGE": "Orange",
    "COSTUMES_ORANGE2-A": "Orange2-a",
    "COSTUMES_ORANGE2-B": "Orange2-b",
    "COSTUMES_OUTFIELDER-A": "Außenspieler-a",
    "COSTUMES_OUTFIELDER-B": "Außenspieler-b",
    "COSTUMES_OUTFIELDER-C": "Außenspieler-c",
    "COSTUMES_OUTFIELDER-D": "Außenstürmer-d",
    "COSTUMES_OWL-A": "Eule-a",
    "COSTUMES_OWL-B": "Eule-b",
    "COSTUMES_OWL-C": "Eule-c",
    "COSTUMES_PADDLE": "Paddel",
    "COSTUMES_PANTHER-A": "Panther-a",
    "COSTUMES_PANTHER-B": "Panther-b",
    "COSTUMES_PANTHER-C": "Panther-c",
    "COSTUMES_PANTS-A": "Hose-a",
    "COSTUMES_PANTS-B": "Hose-b",
    "COSTUMES_PARROT-A": "Papagei-a",
    "COSTUMES_PARROT-B": "Papagei-b",
    "COSTUMES_PARTY_HAT-A": "Party-Hut-a",
    "COSTUMES_PARTY_HAT-B": "Party-Hut-b",
    "COSTUMES_PARTY_HAT-E": "Party-Hut-c",
    "COSTUMES_PENCIL-A": "Bleistift-a",
    "COSTUMES_PENCIL-B": "Bleistift-b",
    "COSTUMES_PENGUIN-A": "Pinguin-a",
    "COSTUMES_PENGUIN-B": "Pinguin-b",
    "COSTUMES_PENGUIN-C": "Pinguin-c",
    "COSTUMES_PENGUIN2-A": "Pinguin2-a",
    "COSTUMES_PENGUIN2-B": "Pinguin2-b",
    "COSTUMES_PENGUIN2-C": "Pinguin2-c",
    "COSTUMES_PENGUIN2-D": "Pinguin2-d",
    "COSTUMES_PITCHER-A": "Krug-a",
    "COSTUMES_PITCHER-B": "Krug-b",
    "COSTUMES_PITCHER-C": "Krug-c",
    "COSTUMES_PITCHER-D": "Krug-d",
    "COSTUMES_PLANET2": "Planet2",
    "COSTUMES_POLAR_BEAR-A": "Eisbär-a",
    "COSTUMES_POLAR_BEAR-B": "Eisbär-b",
    "COSTUMES_POLAR_BEAR-C": "Eisbär-c",
    "COSTUMES_POTION-A": "Trank-a",
    "COSTUMES_POTION-B": "Trank-b",
    "COSTUMES_POTION-C": "Zaubertrank-c",
    "COSTUMES_PRINCE": "Prinz",
    "COSTUMES_PRINCESS-A": "Prinzessin-a",
    "COSTUMES_PRINCESS-B": "Prinzessin-b",
    "COSTUMES_PRINCESS-C": "Prinzessin-c",
    "COSTUMES_PRINCESS-D": "Prinzessin-d",
    "COSTUMES_PRINCESS-E": "Prinzessin-e",
    "COSTUMES_PUFFERFISH-A": "Kugelfisch-a",
    "COSTUMES_PUFFERFISH-B": "Kugelfisch-b",
    "COSTUMES_PUFFERFISH-C": "Kugelfisch-c",
    "COSTUMES_PUFFERFISH-D": "Kugelfisch-d",
    "COSTUMES_PUPPY_BACK": "Welpe hinten",
    "COSTUMES_PUPPY_RIGHT": "Welpe rechts",
    "COSTUMES_PUPPY_SIDE": "Welpe Seite",
    "COSTUMES_PUPPY_SIT": "Welpe Sitz",
    "COSTUMES_RABBIT-A": "Kaninchen-a",
    "COSTUMES_RABBIT-B": "Kaninchen-b",
    "COSTUMES_RABBIT-C": "Kaninchen-c",
    "COSTUMES_RABBIT-D": "Kaninchen-d",
    "COSTUMES_RABBIT-E": "Kaninchen-e",
    "COSTUMES_RADIO-A": "Funk-a",
    "COSTUMES_RADIO-B": "Funk-b",
    "COSTUMES_RAINBOW": "Regenbogen",
    "COSTUMES_REFEREE-A": "schiedsrichter-a",
    "COSTUMES_REFEREE-B": "schiedsrichter-b",
    "COSTUMES_REFEREE-C": "schiedsrichter-c",
    "COSTUMES_REFEREE-D": "schiedsrichter-d",
    "COSTUMES_REINDEER": "Rentier",
    "COSTUMES_RETRO_ROBOT_A": "Retro-Roboter a",
    "COSTUMES_RETRO_ROBOT_B": "Retro-Roboter b",
    "COSTUMES_RETRO_ROBOT_C": "Retro-Roboter c",
    "COSTUMES_RIPLEY-A": "Ripley-a",
    "COSTUMES_RIPLEY-B": "Ripley-b",
    "COSTUMES_RIPLEY-C": "Ripley-c",
    "COSTUMES_RIPLEY-D": "Ripley-d",
    "COSTUMES_RIPLEY-E": "Ripley-e",
    "COSTUMES_RIPLEY-F": "Ripley-f",
    "COSTUMES_ROBOT-A": "Roboter-a",
    "COSTUMES_ROBOT-B": "Roboter-b",
    "COSTUMES_ROBOT-C": "Roboter-c",
    "COSTUMES_ROBOT-D": "Roboter-d",
    "COSTUMES_ROCKETSHIP-A": "Raketenschiff-a",
    "COSTUMES_ROCKETSHIP-B": "Raketenschiff-b",
    "COSTUMES_ROCKETSHIP-C": "Raketenschiff-c",
    "COSTUMES_ROCKETSHIP-D": "Raketenschiff-d",
    "COSTUMES_ROCKETSHIP-E": "Raketenschiff-e",
    "COSTUMES_ROCKS": "Felsen",
    "COSTUMES_ROOSTER-A": "Hahn-a",
    "COSTUMES_ROOSTER-B": "Gockel-b",
    "COSTUMES_ROOSTER-C": "Hahn-c",
    "COSTUMES_RUBY-A": "Rubin-a",
    "COSTUMES_RUBY-B": "Rubin-b",
    "COSTUMES_SAILBOAT": "Segelboot",
    "COSTUMES_SAM": "Sam",
    "COSTUMES_SAXOPHONE-A": "Saxophon-a",
    "COSTUMES_SAXOPHONE-B": "Saxophon-b",
    "COSTUMES_SCARF-A": "Halstuch-a",
    "COSTUMES_SCARF-B": "Halstuch-b",
    "COSTUMES_SCARF-C": "Halstuch-c",
    "COSTUMES_SHARK-A": "Hai-a",
    "COSTUMES_SHARK-B": "Hai-b",
    "COSTUMES_SHARK2-A": "Hai2-a",
    "COSTUMES_SHARK2-B": "Hai2-b",
    "COSTUMES_SHARK2-C": "Hai2-c",
    "COSTUMES_SHIRT-A": "Hemd-a",
    "COSTUMES_SHOES-A": "Schuhe-a",
    "COSTUMES_SHOES-B": "Schuhe-b",
    "COSTUMES_SHOES-C": "Schuhe-c",
    "COSTUMES_SHOES-D": "Schuhe-d",
    "COSTUMES_SHORTS-A": "Kurze Hose-a",
    "COSTUMES_SHORTS-B": "Kurze Hose-b",
    "COSTUMES_SHORTS-C": "Kurze Hose-c",
    "COSTUMES_SINGER1": "Sängerin1",
    "COSTUMES_SKELETON-A": "Skelett-a",
    "COSTUMES_SKELETON-B": "Skelett-b",
    "COSTUMES_SKELETON-D": "Skelett-d",
    "COSTUMES_SKELETON-E": "Skelett-e",
    "COSTUMES_SNAKE-A": "Schlange-a",
    "COSTUMES_SNAKE-B": "Schlange-b",
    "COSTUMES_SNAKE-C": "Schlange-c",
    "COSTUMES_SNOWFLAKE": "Schneeflocke",
    "COSTUMES_SNOWMAN": "Schneemann",
    "COSTUMES_SOCCER_BALL": "Fußball",
    "COSTUMES_SPEAKER": "Lautsprecher",
    "COSTUMES_SQUIRREL": "Eichhörnchen",
    "COSTUMES_STAR": "Stern",
    "COSTUMES_STARFISH-A": "Seestern-",
    "COSTUMES_STARFISH-B_": "Seestern-b ",
    "COSTUMES_STOP": "Stopp",
    "COSTUMES_STRAWBERRY-A": "Erdbeere-a",
    "COSTUMES_STRAWBERRY-B": "Erdbeere-b",
    "COSTUMES_STRAWBERRY-C": "Erdbeere-c",
    "COSTUMES_STRAWBERRY-D": "Erdbeere-d",
    "COSTUMES_STRAWBERRY-E": "Erdbeere-e",
    "COSTUMES_SUN": "Sonne",
    "COSTUMES_SUNGLASSES-A": "Sonnenbrille-a",
    "COSTUMES_SUNGLASSES-B": "Sonnenbrille-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-Zauberer",
    "COSTUMES_TAKEOUT-A": "Takeout-a",
    "COSTUMES_TAKEOUT-B": "Takeout-b",
    "COSTUMES_TAKEOUT-C": "Imbiss-c",
    "COSTUMES_TAKEOUT-D": "Imbiss-d",
    "COSTUMES_TAKEOUT-E": "Takeout-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 Pop Down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 Pop Front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 Pop L Arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 Pop Links",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 Pop R Arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 Pop Rechts",
    "COSTUMES_TEN80_POP_STAND": "Ten80 Pop Stand",
    "COSTUMES_TEN80_STANCE": "Ten80 Stand",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 Top Freeze",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 oben L Schritt",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 oben R Kreuz",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 oberer R-Schritt",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 Top Stand",
    "COSTUMES_TENNISBALL": "Tennisball",
    "COSTUMES_TOUCAN-A": "Tukan-a",
    "COSTUMES_TOUCAN-B": "Tukan-b",
    "COSTUMES_TOUCAN-C": "Tukan-c",
    "COSTUMES_TRAMPOLINE": "Trampolin",
    "COSTUMES_TREE1": "Baum1",
    "COSTUMES_TREES-A": "Bäume-a",
    "COSTUMES_TREES-B": "Bäume-b",
    "COSTUMES_TRUCK-A": "Lkw-a",
    "COSTUMES_TRUCK-B": "Lkw-b",
    "COSTUMES_TRUCK-C": "Lkw-c",
    "COSTUMES_TRUMPET-A": "Trompete-a",
    "COSTUMES_TRUMPET-B": "Trompete-b",
    "COSTUMES_UNICORN": "Einhorn",
    "COSTUMES_UNICORN_2": "Einhorn 2",
    "COSTUMES_UNICORN_RUNNING-A": "Einhorn laufend-a",
    "COSTUMES_UNICORN_RUNNING-B": "Einhorn laufend-b",
    "COSTUMES_UNICORN_RUNNING-C": "Einhorn laufend-c",
    "COSTUMES_UNICORN_RUNNING-D": "Einhorn Laufen-d",
    "COSTUMES_UNICORN_RUNNING-E": "Einhorn Laufen-e",
    "COSTUMES_UNICORN_RUNNING-F": "Einhorn-Laufen-f",
    "COSTUMES_WAND": "Wanda",
    "COSTUMES_WANDA": "Wanda",
    "COSTUMES_WATERMELON-A": "Wassermelone-a",
    "COSTUMES_WATERMELON-B": "Wassermelone-b",
    "COSTUMES_WATERMELON-C": "Wassermelone-c",
    "COSTUMES_WINTER_HAT": "Wintermütze",
    "COSTUMES_WITCH": "Hexe",
    "COSTUMES_WITCH-A": "Hexe-a",
    "COSTUMES_WITCH-B": "Hexe-b",
    "COSTUMES_WITCH-C": "Hexe-c",
    "COSTUMES_WITCH-D": "Hexe-d",
    "COSTUMES_WIZARD_HAT": "Zauberer-Hut",
    "COSTUMES_WIZARD-A": "Zauberer-a",
    "COSTUMES_WIZARD-B": "Zauberer-b",
    "COSTUMES_WIZARD-C": "Zauberer-c",
    "COSTUMES_WIZARD-TOAD-A": "Zauberer-Kröte-b",
    "COSTUMES_WIZARD-TOAD-B": "Zauberer-Kröte-a",
    "COSTUMES_ZEBRA-A": "Zebra-a",
    "COSTUMES_ZEBRA-B": "Zebra-b",
    "COSTUMES_BLOCK-A": "Block-a",
    "COSTUMES_BLOCK-B": "Block-b",
    "COSTUMES_BLOCK-C": "Block-c",
    "COSTUMES_BLOCK-D": "Block-d",
    "COSTUMES_BLOCK-E": "Block-e",
    "COSTUMES_BLOCK-F": "Block-f",
    "COSTUMES_BLOCK-G": "Block-g",
    "COSTUMES_BLOCK-H": "Block-h",
    "COSTUMES_BLOCK-I": "Block-i",
    "COSTUMES_BLOCK-J": "Block-j",
    "COSTUMES_BLOCK-K": "Block-k",
    "COSTUMES_BLOCK-L": "Block-l",
    "COSTUMES_BLOCK-M": "Block-m",
    "COSTUMES_BLOCK-N": "Block-n",
    "COSTUMES_BLOCK-O": "Block-o",
    "COSTUMES_BLOCK-P": "Block-p",
    "COSTUMES_BLOCK-Q": "Block-q",
    "COSTUMES_BLOCK-R": "Block-r",
    "COSTUMES_BLOCK-S": "Block-s",
    "COSTUMES_BLOCK-T": "Block-t",
    "COSTUMES_BLOCK-U": "Block-u",
    "COSTUMES_BLOCK-V": "Block-v",
    "COSTUMES_BLOCK-W": "Block-w",
    "COSTUMES_BLOCK-X": "Block-x",
    "COSTUMES_BLOCK-Y": "Block-y",
    "COSTUMES_BLOCK-Z": "Block-z",
    "COSTUMES_GLOW-0": "Glow-0",
    "COSTUMES_GLOW-1": "Glow-1",
    "COSTUMES_GLOW-2": "Glow-2",
    "COSTUMES_GLOW-3": "Glow-3",
    "COSTUMES_GLOW-4": "Glow-4",
    "COSTUMES_GLOW-5": "Glow-5",
    "COSTUMES_GLOW-6": "Glow-6",
    "COSTUMES_GLOW-7": "Glow-7",
    "COSTUMES_GLOW-8": "Glow-8",
    "COSTUMES_GLOW-9": "Glow-9",
    "COSTUMES_GLOW-A": "Glow-A",
    "COSTUMES_GLOW-B": "Glow-B",
    "COSTUMES_GLOW-C": "Glow-C",
    "COSTUMES_GLOW-D": "Glow-D",
    "COSTUMES_GLOW-E": "Glow-E",
    "COSTUMES_GLOW-F": "Glow-F",
    "COSTUMES_GLOW-G": "Glow-G",
    "COSTUMES_GLOW-H": "Glow-H",
    "COSTUMES_GLOW-I": "Glow-I",
    "COSTUMES_GLOW-J": "Glow-J",
    "COSTUMES_GLOW-K": "Glow-K",
    "COSTUMES_GLOW-L": "Glow-L",
    "COSTUMES_GLOW-M": "Glow-M",
    "COSTUMES_GLOW-N": "Glow-N",
    "COSTUMES_GLOW-O": "Glow-O",
    "COSTUMES_GLOW-P": "Glow-P",
    "COSTUMES_GLOW-Q": "Glow-Q",
    "COSTUMES_GLOW-R": "Glow-R",
    "COSTUMES_GLOW-S": "Glow-S",
    "COSTUMES_GLOW-T": "Glow-T",
    "COSTUMES_GLOW-U": "Glow-U",
    "COSTUMES_GLOW-V": "Glow-V",
    "COSTUMES_GLOW-W": "Glow-W",
    "COSTUMES_GLOW-X": "Glow-X",
    "COSTUMES_GLOW-Y": "Glow-Y",
    "COSTUMES_GLOW-Z": "Glow-Z",
    "COSTUMES_STORY-A-1": "Geschichte-A-1",
    "COSTUMES_STORY-A-2": "Geschichte-A-2",
    "COSTUMES_STORY-A-3": "Geschichte-A-3",
    "COSTUMES_STORY-B-1": "Geschichte-B-1",
    "COSTUMES_STORY-B-2": "Geschichte-B-2",
    "COSTUMES_STORY-B-3": "Geschichte-B-3",
    "COSTUMES_STORY-C-1": "Geschichte-C-1",
    "COSTUMES_STORY-C-2": "Geschichte-C-2",
    "COSTUMES_STORY-C-3": "Geschichte-C-3",
    "COSTUMES_STORY-D-1": "Geschichte-D-1",
    "COSTUMES_STORY-D-2": "Geschichte-D-2",
    "COSTUMES_STORY-D-3": "Geschichte-D-3",
    "COSTUMES_STORY-E-1": "Geschichte-E-1",
    "COSTUMES_STORY-E-2": "Geschichte-E-2",
    "COSTUMES_STORY-E-3": "Geschichte-E-3",
    "COSTUMES_STORY-F-1": "Geschichte-F-1",
    "COSTUMES_STORY-F-2": "Geschichte-F-2",
    "COSTUMES_STORY-F-3": "Geschichte-F-3",
    "COSTUMES_STORY-G-1": "Geschichte-G-1",
    "COSTUMES_STORY-G-2": "Geschichte-G-2",
    "COSTUMES_STORY-G-3": "Geschichte-G-3",
    "COSTUMES_STORY-H-1": "Geschichte-H-1",
    "COSTUMES_STORY-H-2": "Geschichte-H-2",
    "COSTUMES_STORY-H-3": "Geschichte-H-3",
    "COSTUMES_STORY-I-1": "Geschichte-I-1",
    "COSTUMES_STORY-I-2": "Geschichte-I-2",
    "COSTUMES_STORY-I-3": "Geschichte-I-3",
    "COSTUMES_STORY-J-1": "Geschichte-J-1",
    "COSTUMES_STORY-J-2": "Geschichte-J-2",
    "COSTUMES_STORY-J-3": "Geschichte-J-3",
    "COSTUMES_STORY-K-1": "Geschichte-K-1",
    "COSTUMES_STORY-K-2": "Geschichte-K-2",
    "COSTUMES_STORY-K-3": "Geschichte-K-3",
    "COSTUMES_STORY-L-1": "Geschichte-L-1",
    "COSTUMES_STORY-L-2": "Geschichte-L-2",
    "COSTUMES_STORY-L-3": "Geschichte-L-3",
    "COSTUMES_STORY-M-1": "Geschichte-M-1",
    "COSTUMES_STORY-M-2": "Geschichte-M-2",
    "COSTUMES_STORY-M-3": "Geschichte-M-3",
    "COSTUMES_STORY-N-1": "Geschichte-N-1",
    "COSTUMES_STORY-N-2": "Geschichte-N-2",
    "COSTUMES_STORY-N-3": "Geschichte-N-3",
    "COSTUMES_STORY-O-1": "Geschichte-O-1",
    "COSTUMES_STORY-O-2": "Geschichte-O-2",
    "COSTUMES_STORY-O-3": "Geschichte-O-3",
    "COSTUMES_STORY-P-1": "Geschichte-P-1",
    "COSTUMES_STORY-P-2": "Geschichte-P-2",
    "COSTUMES_STORY-P-3": "Geschichte-P-3",
    "COSTUMES_STORY-Q-1": "Geschichte-Q-1",
    "COSTUMES_STORY-Q-2": "Geschichte-Q-2",
    "COSTUMES_STORY-Q-3": "Geschichte-Q-3",
    "COSTUMES_STORY-R-1": "Geschichte-R-1",
    "COSTUMES_STORY-R-2": "Geschichte-R-2",
    "COSTUMES_STORY-R-3": "Geschichte-R-3",
    "COSTUMES_STORY-S-1": "Geschichte-S-1",
    "COSTUMES_STORY-S-2": "Geschichte-S-2",
    "COSTUMES_STORY-S-3": "Geschichte-S-3",
    "COSTUMES_STORY-T-1": "Geschichte-T-1",
    "COSTUMES_STORY-T-2": "Geschichte-T-2",
    "COSTUMES_STORY-T-3": "Geschichte-T-3",
    "COSTUMES_STORY-U-1": "Geschichte-U-1",
    "COSTUMES_STORY-U-2": "Geschichte-U-2",
    "COSTUMES_STORY-U-3": "Geschichte-U-3",
    "COSTUMES_STORY-V-1": "Geschichte-V-1",
    "COSTUMES_STORY-V-2": "Geschichte-V-2",
    "COSTUMES_STORY-V-3": "Geschichte-V-3",
    "COSTUMES_STORY-W-1": "Geschichte-W-1",
    "COSTUMES_STORY-W-2": "Geschichte-W-2",
    "COSTUMES_STORY-W-3": "Geschichte-W-3",
    "COSTUMES_STORY-X-1": "Geschichte-X-1",
    "COSTUMES_STORY-X-2": "Geschichte-X-2",
    "COSTUMES_STORY-X-3": "Geschichte-X-3",
    "COSTUMES_STORY-Y-1": "Geschichte-Y-1",
    "COSTUMES_STORY-Y-2": "Geschichte-Y-2",
    "COSTUMES_STORY-Y-3": "Geschichte-Y-3",
    "COSTUMES_STORY-Z-1": "Geschichte-Z-1",
    "COSTUMES_STORY-Z-2": "Geschichte-Z-2",
    "COSTUMES_STORY-Z-3": "Geschichte-Z-3",
    "SOUNDS_A_BASS": "A Bass",
    "SOUNDS_A_ELEC_BASS": "A E-Bass",
    "SOUNDS_A_ELEC_GUITAR": "A E-Gitarre",
    "SOUNDS_A_ELEC_PIANO": "A Elektronisches Klavier",
    "SOUNDS_A_GUITAR": "A Gitarre",
    "SOUNDS_A_MINOR_UKULELE": "A Moll-Ukulele",
    "SOUNDS_A_PIANO": "A Klavier",
    "SOUNDS_A_SAX": "A Saxophon",
    "SOUNDS_A_TROMBONE": "A Posaune",
    "SOUNDS_A_TRUMPET": "A Trompete",
    "SOUNDS_AFRO_STRING": "Afro-Streicher",
    "SOUNDS_ALERT": "Alert",
    "SOUNDS_ALIEN_CREAK1": "Außerirdisches Quietschen1",
    "SOUNDS_ALIEN_CREAK2": "Alien-Knarren2",
    "SOUNDS_B_BASS": "B Bass",
    "SOUNDS_B_ELEC_BASS": "B-Elektronischer Bass",
    "SOUNDS_B_ELEC_GUITAR": "B E-Gitarre",
    "SOUNDS_B_ELEC_PIANO": "B Elektronisches Klavier",
    "SOUNDS_B_GUITAR": "B Gitarre",
    "SOUNDS_B_PIANO": "B Klavier",
    "SOUNDS_B_SAX": "B Saxophon",
    "SOUNDS_B_TROMBONE": "B Posaune",
    "SOUNDS_B_TRUMPET": "B Trompete",
    "SOUNDS_BAA": "Baa",
    "SOUNDS_BARK": "Bellen",
    "SOUNDS_BASKETBALL_BOUNCE": "Basketball Bounce",
    "SOUNDS_BASS_BEATBOX": "Bass Beatbox",
    "SOUNDS_BEAT_BOX1": "Beat Box1",
    "SOUNDS_BEAT_BOX2": "Beatbox2",
    "SOUNDS_BELL_CYMBAL": "Glocke Cymbal",
    "SOUNDS_BELL_TOLL": "Glockenton",
    "SOUNDS_BIG_BOING": "Großer Boing",
    "SOUNDS_BIRD": "Vogel",
    "SOUNDS_BIRTHDAY": "Geburtstag",
    "SOUNDS_BITE": "Biss",
    "SOUNDS_BOING": "Boing",
    "SOUNDS_BONK": "Bonk",
    "SOUNDS_BOOM_CLOUD": "Boom Wolke",
    "SOUNDS_BOOP_BING_BOP": "Boop Bing Bop",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": "Bowling Strike",
    "SOUNDS_BUBBLES": "Blasen",
    "SOUNDS_BUZZ_WHIR": "Buzz Whir",
    "SOUNDS_C_BASS": "C-Bass",
    "SOUNDS_C_ELEC_BASS": "C-Elektronischer Bass",
    "SOUNDS_C_ELEC_GUITAR": "C E-Gitarre",
    "SOUNDS_C_ELEC_PIANO": "C Elektronisches Klavier",
    "SOUNDS_C_GUITAR": "C-Gitarre",
    "SOUNDS_C_MAJOR_UKULELE": "C-Dur-Ukulele",
    "SOUNDS_C_PIANO": "C Klavier",
    "SOUNDS_C_SAX": "C Saxophon",
    "SOUNDS_C_TROMBONE": "C Posaune",
    "SOUNDS_C_TRUMPET": "C Trompete",
    "SOUNDS_C2_BASS": "C2 Bass",
    "SOUNDS_C2_ELEC_BASS": "C2 E-Bass",
    "SOUNDS_C2_ELEC_GUITAR": "C2 E-Gitarre",
    "SOUNDS_C2_ELEC_PIANO": "C2 Elektronisches Klavier",
    "SOUNDS_C2_GUITAR": "C2 Gitarre",
    "SOUNDS_C2_PIANO": "C2 Klavier",
    "SOUNDS_C2_SAX": "C2 Saxophon",
    "SOUNDS_C2_TROMBONE": "C2 Posaune",
    "SOUNDS_C2_TRUMPET": "C2 Trompete",
    "SOUNDS_CAR_HORN": "Autohorn",
    "SOUNDS_CAR_PASSING": "Auto Vorbeifahren",
    "SOUNDS_CAR_VROOM": "Auto Vroom",
    "SOUNDS_CAVE": "Höhle",
    "SOUNDS_CHATTER": "Schnattern",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "Cheer",
    "SOUNDS_CHILL": "Chillen",
    "SOUNDS_CHIRP": "Zirpen",
    "SOUNDS_CHOMP": "Chomp",
    "SOUNDS_CHORD": "Akkord",
    "SOUNDS_CLANG": "Klirren",
    "SOUNDS_CLAP_BEATBOX": "Klatschen Beatbox",
    "SOUNDS_CLAPPING": "Klatschen",
    "SOUNDS_CLASSICAL_PIANO": "Klassisches Klavier",
    "SOUNDS_CLOCK_TICKING": "Tickende Uhr",
    "SOUNDS_CLOWN_HONK": "Clown hupen",
    "SOUNDS_COIN": "Münze",
    "SOUNDS_COLLECT": "Sammeln",
    "SOUNDS_COMPUTER_BEEP": "Computer-Ton1",
    "SOUNDS_COMPUTER_BEEP2": "Computer Piep2",
    "SOUNDS_CONNECT": "Verbinden",
    "SOUNDS_COUGH1": "Husten1",
    "SOUNDS_COUGH2": "Husten2",
    "SOUNDS_CRANK": "Kurbeln",
    "SOUNDS_CRASH_BEATBOX": "Crash-Beatbox",
    "SOUNDS_CRASH_CYMBAL": "Crash-Becken",
    "SOUNDS_CRAZY_LAUGH": "Verrücktes Lachen",
    "SOUNDS_CRICKET": "Grille",
    "SOUNDS_CRICKETS": "Grillen",
    "SOUNDS_CROAK": "Krächzen",
    "SOUNDS_CROWD_GASP": "Menschenmenge Keuchen",
    "SOUNDS_CROWD_LAUGH": "Menschenmenge",
    "SOUNDS_CRUNCH": "Knirschen",
    "SOUNDS_CYMBAL": "Cymbal",
    "SOUNDS_CYMBAL_CRASH": "Becken Crash",
    "SOUNDS_CYMBAL_ECHO": "Becken-Echo",
    "SOUNDS_D_BASS": "D Bass",
    "SOUNDS_D_ELEC_BASS": "D-Elektronischer Bass",
    "SOUNDS_D_ELEC_GUITAR": "E-Gitarre",
    "SOUNDS_D_ELEC_PIANO": "D E-Piano",
    "SOUNDS_D_GUITAR": "D Gitarre ",
    "SOUNDS_D_PIANO": "D Klavier",
    "SOUNDS_D_SAX": "D Saxophon",
    "SOUNDS_D_TROMBONE": "D Posaune",
    "SOUNDS_D_TRUMPET": "D Trompete",
    "SOUNDS_DANCE_AROUND": "Umtanzen",
    "SOUNDS_DANCE_CELEBRATE": "Tanzen Feiern",
    "SOUNDS_DANCE_CELEBRATE2": "Tanz Celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": "Tanzen Chill Out",
    "SOUNDS_DANCE_ENERGETIC": "Tanzen Energetisch",
    "SOUNDS_DANCE_FUNKY": "Funky tanzen",
    "SOUNDS_DANCE_HEAD_NOD": "Tanzen Kopfnicken",
    "SOUNDS_DANCE_MAGIC": "Tanz Magic",
    "SOUNDS_DANCE_SITAR": "Sitar tanzen",
    "SOUNDS_DANCE_SLOW_MO": "Tanz Slow Mo",
    "SOUNDS_DANCE_SNARE_BEAT": "Tanzen Snare Beat",
    "SOUNDS_DANCE_SPACE": "Raum tanzen",
    "SOUNDS_DISCONNECT": "Trennen",
    "SOUNDS_DOG1": "Hund",
    "SOUNDS_DOG2": "Hund2",
    "SOUNDS_DOOR_CLOSING": "Tür schließen",
    "SOUNDS_DOOR_CREAK": "Tür knarren",
    "SOUNDS_DOORBELL": "Türklingel",
    "SOUNDS_DRIP_DROP": "Tropfende Tropfen",
    "SOUNDS_DRIVE_AROUND": "Herumfahren",
    "SOUNDS_DRUM": "Trommel",
    "SOUNDS_DRUM_BASS1": "Trommel Bass1",
    "SOUNDS_DRUM_BASS2": "Schlagzeug Bass2",
    "SOUNDS_DRUM_BASS3": "Schlagzeug Bass3",
    "SOUNDS_DRUM_BOING": "Drum Boing",
    "SOUNDS_DRUM_BUZZ": "Schlagzeug Buzz",
    "SOUNDS_DRUM_FUNKY": "Schlagzeug Funky",
    "SOUNDS_DRUM_JAM": "Drum Jam",
    "SOUNDS_DRUM_MACHINE": "Trommelmaschine",
    "SOUNDS_DRUM_ROLL": "Trommelwirbel",
    "SOUNDS_DRUM_SATELLITE": "Trommel-Satellit",
    "SOUNDS_DRUM_SET1": "Schlagzeug-Set1",
    "SOUNDS_DRUM_SET2": "Schlagzeug-Set2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "Duck",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dun Dunn",
    "SOUNDS_E_BASS": "E Bass",
    "SOUNDS_E_ELEC_BASS": "E E-Bass",
    "SOUNDS_E_ELEC_GUITAR": "E E-Gitarre",
    "SOUNDS_E_ELEC_PIANO": "E E-Piano",
    "SOUNDS_E_GUITAR": "E Gitarre",
    "SOUNDS_E_PIANO": "E Klavier",
    "SOUNDS_E_SAX": "E Saxophon",
    "SOUNDS_E_TROMBONE": "E Posaune",
    "SOUNDS_E_TRUMPET": "E Trompete",
    "SOUNDS_EGGS": "Eier",
    "SOUNDS_ELEC_PIANO_A_MINOR": "E-Klavier A-Moll",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "E-Klavier C-Dur",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "E-Klavier F-Dur",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "E-Piano G-Dur",
    "SOUNDS_ELEC_PIANO_LOOP": "E-Piano Schleife",
    "SOUNDS_EMOTIONAL_PIANO": "Emotionales Klavier",
    "SOUNDS_ENGINE": "Motor",
    "SOUNDS_F_BASS": "F-Bass",
    "SOUNDS_F_ELEC_BASS": "F-Elektronischer Bass",
    "SOUNDS_F_ELEC_GUITAR": "F-Elektronische Gitarre",
    "SOUNDS_F_ELEC_PIANO": "F Elektrisches Klavier",
    "SOUNDS_F_GUITAR": "F Gitarre",
    "SOUNDS_F_MAJOR_UKULELE": "F-Dur-Ukulele",
    "SOUNDS_F_PIANO": "F Klavier",
    "SOUNDS_F_SAX": "F Saxophon",
    "SOUNDS_F_TROMBONE": "F Posaune",
    "SOUNDS_F_TRUMPET": "F Trompete",
    "SOUNDS_FAIRYDUST": "Fairydust",
    "SOUNDS_FINGER_SNAP": "Fingerschnippen",
    "SOUNDS_FLAM_SNARE": "Flam Snare",
    "SOUNDS_FOOTSTEPS": "Fußstapfen",
    "SOUNDS_G_BASS": "G Bass",
    "SOUNDS_G_ELEC_BASS": "G-Elektronischer Bass",
    "SOUNDS_G_ELEC_GUITAR": "G-Elektronische Gitarre",
    "SOUNDS_G_ELEC_PIANO": "G-Elektronisches Klavier",
    "SOUNDS_G_GUITAR": "G Gitarre",
    "SOUNDS_G_PIANO": "G Klavier",
    "SOUNDS_G_SAX": "G Saxophon",
    "SOUNDS_G_TROMBONE": "G Posaune",
    "SOUNDS_G_TRUMPET": "G Trompete",
    "SOUNDS_G_UKULELE": "G Ukulele",
    "SOUNDS_GALLOP": "Galopp",
    "SOUNDS_GARDEN": "Garten",
    "SOUNDS_GLASS_BREAKING": "Glas brechen",
    "SOUNDS_GLUG": "Glühwein",
    "SOUNDS_GOAL_CHEER": "Torjubel",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Gans",
    "SOUNDS_GROWL": "Knurren",
    "SOUNDS_GRUNT": "Grunzen",
    "SOUNDS_GUITAR_CHORDS1": "Gitarrenakkorde1",
    "SOUNDS_GUITAR_CHORDS2": "Gitarren-Akkorde2",
    "SOUNDS_GUITAR_STRUM": "Gitarre klimpern",
    "SOUNDS_HAND_CLAP": "Hände klatschen",
    "SOUNDS_HEAD_SHAKE": "Kopfschütteln",
    "SOUNDS_HEY": "Hey",
    "SOUNDS_HI_BEATBOX": "Hi Beatbox",
    "SOUNDS_HI_NA_TABLA": "Hi Na Tabla",
    "SOUNDS_HI_TUN_TABLA": "Hi Tun Tabla",
    "SOUNDS_HIGH_CONGA": "Hohe Conga",
    "SOUNDS_HIGH_HAT": "Hoher Hut",
    "SOUNDS_HIGH_TOM": "Hoher Tom",
    "SOUNDS_HIGH_WHOOSH": "Hoher Whoosh",
    "SOUNDS_HIHAT_BEATBOX": "Hihat Beatbox",
    "SOUNDS_HIHAT_CYMBAL": "Hihat-Becken",
    "SOUNDS_HIP_HOP": "Hip Hop",
    "SOUNDS_HORSE": "Pferd",
    "SOUNDS_HORSE_GALLOP": "Pferdegalopp",
    "SOUNDS_HUMAN_BEATBOX1": "Menschliche Beatbox1",
    "SOUNDS_HUMAN_BEATBOX2": "Menschliche Beatbox2",
    "SOUNDS_JUMP": "Sprung",
    "SOUNDS_JUNGLE": "Dschungel",
    "SOUNDS_JUNGLE_FROGS": "Dschungel Frösche",
    "SOUNDS_KICK_BACK": "Kick Back",
    "SOUNDS_KICK_DRUM": "Kick-Trommel",
    "SOUNDS_LARGE_COWBELL": "Große Kuhglocke",
    "SOUNDS_LASER1": "Laser1",
    "SOUNDS_LASER2": "Laser2",
    "SOUNDS_LAUGH1": "Lachen1",
    "SOUNDS_LAUGH2": "Lachen2",
    "SOUNDS_LAUGH3": "Lachen3",
    "SOUNDS_LO_GEH_TABLA": "Lo Geh Tabla",
    "SOUNDS_LO_GLISS_TABLA": "Lo Gliss Tabla",
    "SOUNDS_LOSE": "Lose",
    "SOUNDS_LOW_BOING": "Tief Boing",
    "SOUNDS_LOW_CONGA": "Tief Conga",
    "SOUNDS_LOW_SQUEAK": "Tiefes Quietschen",
    "SOUNDS_LOW_TOM": "Tief Tom",
    "SOUNDS_LOW_WHOOSH": "Tiefes Whoosh",
    "SOUNDS_MACHINE": "Maschine",
    "SOUNDS_MAGIC_SPELL": "Zauberspruch",
    "SOUNDS_MEDIEVAL1": "Mittelalter1",
    "SOUNDS_MEDIEVAL2": "Mittelalterlich2",
    "SOUNDS_MEOW": "Miau",
    "SOUNDS_MEOW2": "Miau2",
    "SOUNDS_MOO": "Muh",
    "SOUNDS_MOTORCYCLE_PASSING": "Motorrad im Vorbeifahren",
    "SOUNDS_MOVIE_1": "Film 1",
    "SOUNDS_MOVIE_2": "Film 2",
    "SOUNDS_MUTED_CONGA": "Gedämpfter Conga",
    "SOUNDS_MYSTERY": "Geheimnisvoll",
    "SOUNDS_OCEAN_WAVE": "Meereswelle",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "Hoppla",
    "SOUNDS_ORCHESTRA_TUNING": "Orchesterstimmung",
    "SOUNDS_OWL": "Eule",
    "SOUNDS_PARTY_NOISE": "Party-Lärm",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": "Ping Pong Hit",
    "SOUNDS_PLUCK": "Zupfen",
    "SOUNDS_PLUNGE": "Eintauchen",
    "SOUNDS_POLICE_SIREN": "Polizeisirene",
    "SOUNDS_POP": "Pop",
    "SOUNDS_RAIN": "Regen",
    "SOUNDS_RATTLE": "Rasseln",
    "SOUNDS_REFEREE_WHISTLE": "Schiedsrichter-Pfeife",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "Querschläger",
    "SOUNDS_RIDE_CYMBAL": "Ride-Becken",
    "SOUNDS_RING_TONE": "Klingelton",
    "SOUNDS_RIP": "Rip",
    "SOUNDS_RIPPLES": "Ripples",
    "SOUNDS_ROLL_CYMBAL": "Roll-Becken",
    "SOUNDS_ROOSTER": "Hahn",
    "SOUNDS_SCRAMBLING_FEET": "Kratzende Füße",
    "SOUNDS_SCRATCH_BEATBOX": "Scratch Beatbox",
    "SOUNDS_SCRATCHY_BEAT": "Kratziger Beat",
    "SOUNDS_SCREAM1": "Schrei1",
    "SOUNDS_SCREAM2": "Schrei2",
    "SOUNDS_SCREECH": "Kreischen",
    "SOUNDS_SEAGULLS": "Möwen",
    "SOUNDS_SEWING_MACHINE": "Nähmaschine",
    "SOUNDS_SHAKER": "Schüttler",
    "SOUNDS_SHIP_BELL": "Schiffsglocke",
    "SOUNDS_SIDESTICK_SNARE": "Seitenständer Schlinge",
    "SOUNDS_SINGER1": "Singer1",
    "SOUNDS_SINGER2": "Singer2",
    "SOUNDS_SIREN_WHISTLE": "Sirene Pfeife",
    "SOUNDS_SKID": "Kufe",
    "SOUNDS_SLIDE_WHISTLE": "Rutsche Pfeife",
    "SOUNDS_SMALL_COWBELL": "Kleine Kuhglocke",
    "SOUNDS_SNAP": "Schnapper",
    "SOUNDS_SNARE_BEATBOX": "Schlinge Beatbox",
    "SOUNDS_SNARE_BEATBOX2": "Snare Beatbox2",
    "SOUNDS_SNARE_DRUM": "Schnarrtrommel",
    "SOUNDS_SNEAKER_SQUEAK": "Sneaker Quietschen",
    "SOUNDS_SNEEZE1": "Sneeze1",
    "SOUNDS_SNEEZE2": "Niesen2",
    "SOUNDS_SNORING": "Schnarchen",
    "SOUNDS_SNORT": "Schnarchen",
    "SOUNDS_SPACE_AMBIENCE": "Weltraum-Ambiente",
    "SOUNDS_SPACE_FLYBY": "Weltraum Flyby",
    "SOUNDS_SPACE_NOISE": "Weltraum-Rauschen",
    "SOUNDS_SPACE_RIPPLE": "Weltraum Ripple",
    "SOUNDS_SPIRAL": "Spirale",
    "SOUNDS_SPLASH": "Plätschern",
    "SOUNDS_SPLASH_CYMBAL": "Splash-Becken",
    "SOUNDS_SPOOKY_STRING": "Gespenstische Saite",
    "SOUNDS_SQUAWK": "Quietschen",
    "SOUNDS_SQUEAKS": "Quietschen",
    "SOUNDS_SQUEAKY_TOY": "Quietschendes Spielzeug",
    "SOUNDS_SQUISH_POP": "Quietsch-Pop",
    "SOUNDS_STRING_ACCENT": "String Akzent",
    "SOUNDS_STRING_PLUCK": "Schnur zupfen",
    "SOUNDS_SUCTION_CUP": "Saugnapf",
    "SOUNDS_SUSPENSE": "Suspense",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura",
    "SOUNDS_TAP_CONGA": "Tap Conga",
    "SOUNDS_TAP_SNARE": "Tap Snare",
    "SOUNDS_TECHNO": "Techno",
    "SOUNDS_TECHNO2": "Techno2",
    "SOUNDS_TELEPHONE_RING": "Telefon-Klingel",
    "SOUNDS_TELEPHONE_RING2": "Telefonklingel2",
    "SOUNDS_TELEPORT": "Teleport",
    "SOUNDS_TELEPORT2": "Teleport2",
    "SOUNDS_TELEPORT3": "Teleportieren3",
    "SOUNDS_TENNIS_HIT": "Tennis Treffer",
    "SOUNDS_THUNDER_STORM": "Donnersturm",
    "SOUNDS_TOM_DRUM": "Tom-Trommel",
    "SOUNDS_TOY_HONK": "Spielzeug-Honk",
    "SOUNDS_TOY_ZING": "Spielzeug Zing",
    "SOUNDS_TRAFFIC": "Verkehr",
    "SOUNDS_TRAIN_WHISTLE": "Zugpfeife",
    "SOUNDS_TRAP_BEAT": "Trap-Schlag",
    "SOUNDS_TRIUMPH": "Triumph",
    "SOUNDS_TROPICAL_BIRDS": "Tropische Vögel",
    "SOUNDS_TRUMPET1": "Trompete1",
    "SOUNDS_TRUMPET2": "Trompete2",
    "SOUNDS_VIDEO_GAME_1": "Video-Spiel 1",
    "SOUNDS_VIDEO_GAME_2": "Videospiel 2",
    "SOUNDS_WAH_BEATBOX": "Wah Beatbox",
    "SOUNDS_WAND": "Zauberstab",
    "SOUNDS_WATER_DROP": "Wassertropfen",
    "SOUNDS_WHINNY": "Wiehern",
    "SOUNDS_WHISTLE_THUMP": "Pfeife Thump",
    "SOUNDS_WHIZ": "Whiz",
    "SOUNDS_WHOOP": "Whoop",
    "SOUNDS_WIN": "Gewinnen",
    "SOUNDS_WOBBLE": "Wackeln",
    "SOUNDS_WOLF_HOWL": "Wolf heulen",
    "SOUNDS_WOOD_TAP": "Holz klopfen",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "Xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Tanz",
    "SPRITES_APPLE": "Apfel",
    "SPRITES_ARROW1": "Pfeil1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Gehen",
    "SPRITES_BALL": "Ball",
    "SPRITES_BALLERINA": "Ballerina",
    "SPRITES_BALLOON1": "Luftballon1",
    "SPRITES_BANANAS": "Bananen",
    "SPRITES_BASEBALL": "Baseball",
    "SPRITES_BASKETBALL": "Basketball",
    "SPRITES_BAT": "Schläger",
    "SPRITES_BATTER": "Schläger",
    "SPRITES_BEACHBALL": "Strandball",
    "SPRITES_BEAR": "Bär",
    "SPRITES_BEAR-WALKING": "Bärenwanderung",
    "SPRITES_BEETLE": "Käfer",
    "SPRITES_BELL": "Glocke",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Schüssel",
    "SPRITES_BOWTIE": "Bowtie",
    "SPRITES_BREAD": "Brot",
    "SPRITES_BROOM": "Besen",
    "SPRITES_BUILDINGS": "Gebäude",
    "SPRITES_BUTTERFLY_1": "Schmetterling 1",
    "SPRITES_BUTTERFLY_2": "Schmetterling 2",
    "SPRITES_BUTTON1": "Knopf1",
    "SPRITES_BUTTON2": "Taste2",
    "SPRITES_BUTTON3": "Schaltfläche3",
    "SPRITES_BUTTON4": "Taste4",
    "SPRITES_BUTTON5": "Schaltfläche5",
    "SPRITES_CAKE": "Torte",
    "SPRITES_CALVRETT": "Kalvrett",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CASSY_DANCE": "Cassy Tanz",
    "SPRITES_CAT_2": "Katze 2",
    "SPRITES_CATCHER": "Fänger",
    "SPRITES_CENTAUR": "Zentaur",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Käsebällchen",
    "SPRITES_CHICK": "Küken",
    "SPRITES_CITY_BUS": "Stadtbus",
    "SPRITES_CLOUD": "Wolke",
    "SPRITES_CLOUDS": "Wolken",
    "SPRITES_CONVERTIBLE": "Cabrio",
    "SPRITES_CONVERTIBLE_2": "Cabrio 2",
    "SPRITES_CRAB": "Krabbe",
    "SPRITES_CRYSTAL": "Kristall",
    "SPRITES_D-MONEY_DANCE": "D-Geld-Tanz",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "Dinosaurier1",
    "SPRITES_DINOSAUR2": "Dinosaurier2",
    "SPRITES_DINOSAUR3": "Dinosaurier3",
    "SPRITES_DINOSAUR4": "Dinosaurier4",
    "SPRITES_DINOSAUR5": "Dinosaurier5",
    "COSTUMES_DINOSAUR5-A": "Dinosaurier5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaurier5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaurier5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaurier5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaurier5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaurier5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaurier5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaurier5-h",
    "SPRITES_DIVER1": "Taucher1",
    "SPRITES_DIVER2": "Taucher2",
    "SPRITES_DOG1": "Hund1",
    "SPRITES_DOG2": "Hund2",
    "SPRITES_DONUT": "Donut",
    "SPRITES_DORIAN": "Dorian",
    "SPRITES_DOT": "Dot",
    "SPRITES_DOVE": "Taube",
    "SPRITES_DRAGON": "Drache",
    "SPRITES_DRAGONFLY": "Libelle",
    "SPRITES_DRESS": "Kleid",
    "SPRITES_DRUM": "Schlagzeug",
    "SPRITES_DRUM_KIT": "Schlagzeug",
    "SPRITES_DRUM-CYMBAL": "Trommel-Zimbel",
    "SPRITES_DRUM-HIGHHAT": "Trommel-Highhat",
    "SPRITES_DRUM-SNARE": "Trommel-Schlinge",
    "SPRITES_DRUMS_CONGA": "Schlagzeug Conga",
    "SPRITES_DRUMS_TABLA": "Schlagzeug Tabla",
    "SPRITES_DUCK": "Ente",
    "SPRITES_EARTH": "Erde",
    "SPRITES_EASEL": "Staffelei",
    "SPRITES_EGG": "Ei",
    "SPRITES_ELEPHANT": "Elefant",
    "SPRITES_ELF": "Elf",
    "SPRITES_FAIRY": "Fee",
    "SPRITES_FISH": "Fisch",
    "SPRITES_FISHBOWL": "Fischglas",
    "SPRITES_FOOD_TRUCK": "Lebensmittelwagen",
    "SPRITES_FOOTBALL": "Fußball",
    "SPRITES_FORTUNE_COOKIE": "Glückskeks",
    "SPRITES_FOX": "Fuchs",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "Frosch",
    "SPRITES_FROG_2_": "Frosch 2 ",
    "SPRITES_FRUIT_PLATTER": "Obstteller",
    "SPRITES_FRUIT_SALAD": "Obstsalat",
    "SPRITES_GHOST": "Geist",
    "SPRITES_GIFT": "Geschenk",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GLASS_WATER": "Glas Wasser",
    "SPRITES_GLASSES": "Gläser",
    "SPRITES_GOALIE": "Torwart",
    "SPRITES_GOBLIN": "Kobold",
    "SPRITES_GRASSHOPPER": "Grashüpfer",
    "SPRITES_GREEN_FLAG": "Grüne Fahne",
    "SPRITES_GRIFFIN": "Greifen",
    "SPRITES_GUITAR": "Gitarre",
    "SPRITES_GUITAR-ELECTRIC1": "Gitarre-elektrisch1",
    "SPRITES_GUITAR-ELECTRIC2": "Gitarre-elektrisch2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hase",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hut1 ",
    "SPRITES_HATCHLING": "Schlüpfling",
    "SPRITES_HEART": "Herz",
    "SPRITES_HEART_CANDY": "Herz-Bonbon",
    "SPRITES_HEART_FACE": "Herz-Gesicht",
    "SPRITES_HEDGEHOG": "Igel",
    "SPRITES_HEN": "Henne",
    "SPRITES_HIPPO1": "Nilpferd1",
    "SPRITES_HOME_BUTTON": "Home-Taste",
    "SPRITES_HORSE": "Pferd",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "Krug",
    "SPRITES_JELLYFISH": "Qualle",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_JOUVI_DANCE": "Jouvi-Tanz",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "Taste",
    "SPRITES_KEYBOARD": "Tastatur",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Ritter",
    "SPRITES_LB_DANCE": "LB-Tanz",
    "SPRITES_LADYBUG1": "Marienkäfer1",
    "SPRITES_LADYBUG2": "Marienkäfer2",
    "SPRITES_LAPTOP": "Laptop",
    "SPRITES_LIGHTNING": "Blitz",
    "SPRITES_LINE": "Linie",
    "SPRITES_LION": "Löwe",
    "SPRITES_LLAMA": "Lama",
    "SPRITES_MAGIC_WAND": "Zauberstab",
    "SPRITES_MAX": "Max",
    "SPRITES_MERMAID": "Meerjungfrau",
    "SPRITES_MICROPHONE": "Mikrofon",
    "SPRITES_MILK": "Milch",
    "SPRITES_MIND+": "Geist+",
    "COSTUMES_MIND+1": "Geist+1",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Affe",
    "SPRITES_MOTORCYCLE": "Motorrad",
    "SPRITES_MOUSE1": "Maus1",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": "Wieherndes Pony",
    "SPRITES_OCTOPUS": "Oktopus",
    "SPRITES_ORANGE": "Orange",
    "SPRITES_ORANGE2": "Orange2",
    "SPRITES_OUTFIELDER": "Außenspieler",
    "SPRITES_OWL": "Eule",
    "SPRITES_PADDLE": "Paddel",
    "SPRITES_PANTHER": "Panther",
    "SPRITES_PANTS": "Hose",
    "SPRITES_PARROT": "Papagei",
    "SPRITES_PARTY_HATS": "Party Hüte",
    "SPRITES_PENCIL": "Bleistift",
    "SPRITES_PENGUIN": "Pinguin",
    "SPRITES_PENGUIN_2": "Pinguin 2",
    "SPRITES_PITCHER": "Krug",
    "SPRITES_PLANET2": "Planet2",
    "SPRITES_POLAR_BEAR": "Eisbär",
    "SPRITES_POTION": "Trank",
    "SPRITES_PRINCE": "Prinz",
    "SPRITES_PRINCESS": "Prinzessin",
    "SPRITES_PUFFERFISH": "Kugelfisch",
    "SPRITES_PUPPY": "Welpe",
    "SPRITES_RABBIT": "Kaninchen",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": "Regenbogen",
    "SPRITES_REFEREE": "Schiedsrichter",
    "SPRITES_REINDEER": "Rentier",
    "SPRITES_RETRO_ROBOT": "Retro-Roboter",
    "SOUNDS_COMPUTER_BEEPS1": "Computer piepst1",
    "SOUNDS_COMPUTER_BEEPS2": "Computer piepst2",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Roboter",
    "SPRITES_ROCKETSHIP": "Raketenschiff",
    "SPRITES_ROCKS": "Felsen",
    "SPRITES_ROOSTER": "Hahn",
    "SPRITES_RUBY": "Rubin",
    "SPRITES_SAILBOAT": "Segelboot",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "Saxophon",
    "SPRITES_SCARF": "Halstuch",
    "SPRITES_SHARK": "Hai",
    "SPRITES_SHARK_2": "Hai 2",
    "SPRITES_SHIRT": "Hemd",
    "SPRITES_SHOES": "Schuhe",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "Sänger1",
    "SPRITES_SKELETON": "Skelett",
    "SPRITES_SNAKE": "Schlange",
    "SPRITES_SNOWFLAKE": "Schneeflocke",
    "SPRITES_SNOWMAN": "Schneemann",
    "SPRITES_SOCCER_BALL": "Fußball",
    "SPRITES_SPEAKER": "Lautsprecher",
    "SPRITES_SQUIRREL": "Eichhörnchen",
    "SPRITES_STAR": "Stern",
    "SPRITES_STARFISH": "Seestern",
    "SPRITES_STOP": "Stopp",
    "SPRITES_STRAWBERRY": "Erdbeere",
    "SPRITES_SUN": "Sonne",
    "SPRITES_SUNGLASSES1": "Sonnenbrille1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Mitnehmen",
    "SPRITES_TEN80_DANCE": "Ten80 Tanz",
    "SPRITES_TENNIS_BALL": "Tennisball",
    "SPRITES_TOUCAN": "Tukan",
    "SPRITES_TRAMPOLINE": "Trampolin",
    "SPRITES_TREE1": "Baum1",
    "SPRITES_TREES": "Bäume",
    "SPRITES_TRUCK": "LKW",
    "SPRITES_TRUMPET": "Trompete",
    "SPRITES_UNICORN": "Einhorn",
    "SPRITES_UNICORN_2": "Einhorn 2",
    "SPRITES_UNICORN_RUNNING": "Einhorn laufend",
    "SPRITES_WAND": "Zauberstab",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Wassermelone",
    "SPRITES_WINTER_HAT": "Wintermütze",
    "SPRITES_WITCH": "Hexe",
    "SPRITES_WIZARD": "Zauberer",
    "SPRITES_WIZARD_HAT": "Zauberer-Hut",
    "SPRITES_WIZARD-TOAD": "Zauberer-Kröte",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "Block-A",
    "SPRITES_BLOCK-B": "Block-B",
    "SPRITES_BLOCK-C": "Block-C",
    "SPRITES_BLOCK-D": "Block-D",
    "SPRITES_BLOCK-E": "Block-E",
    "SPRITES_BLOCK-F": "Block-F",
    "SPRITES_BLOCK-G": "Block-G",
    "SPRITES_BLOCK-H": "Block-H",
    "SPRITES_BLOCK-I": "Block-I",
    "SPRITES_BLOCK-J": "Block-J",
    "SPRITES_BLOCK-K": "Block-K",
    "SPRITES_BLOCK-L": "Block-L",
    "SPRITES_BLOCK-M": "Block-M",
    "SPRITES_BLOCK-N": "Block-N",
    "SPRITES_BLOCK-O": "Block-O",
    "SPRITES_BLOCK-P": "Block-P",
    "SPRITES_BLOCK-Q": "Block-Q",
    "SPRITES_BLOCK-R": "Block-R",
    "SPRITES_BLOCK-S": "Block-S",
    "SPRITES_BLOCK-T": "Block-T",
    "SPRITES_BLOCK-U": "Block-U",
    "SPRITES_BLOCK-V": "Block-V",
    "SPRITES_BLOCK-W": "Block-W",
    "SPRITES_BLOCK-X": "Block-X",
    "SPRITES_BLOCK-Y": "Block-Y",
    "SPRITES_BLOCK-Z": "Block-Z",
    "SPRITES_GLOW-0": "Glow-0",
    "SPRITES_GLOW-1": "Glow-1",
    "SPRITES_GLOW-2": "Glow-2",
    "SPRITES_GLOW-3": "Glow-3",
    "SPRITES_GLOW-4": "Glow-4",
    "SPRITES_GLOW-5": "Glow-5",
    "SPRITES_GLOW-6": "Glow-6",
    "SPRITES_GLOW-7": "Glow-7",
    "SPRITES_GLOW-8": "Glow-8",
    "SPRITES_GLOW-9": "Glow-9",
    "SPRITES_GLOW-A": "Glow-A",
    "SPRITES_GLOW-B": "Glow-B",
    "SPRITES_GLOW-C": "Glow-C",
    "SPRITES_GLOW-D": "Glow-D",
    "SPRITES_GLOW-E": "Glow-E",
    "SPRITES_GLOW-F": "Glow-F",
    "SPRITES_GLOW-G": "Glow-G",
    "SPRITES_GLOW-H": "Glow-H",
    "SPRITES_GLOW-I": "Glow-I",
    "SPRITES_GLOW-J": "Glow-J",
    "SPRITES_GLOW-K": "Glow-K",
    "SPRITES_GLOW-L": "Glow-L",
    "SPRITES_GLOW-M": "Glow-M",
    "SPRITES_GLOW-N": "Glow-N",
    "SPRITES_GLOW-O": "Glow-O",
    "SPRITES_GLOW-P": "Glow-P",
    "SPRITES_GLOW-Q": "Glow-Q",
    "SPRITES_GLOW-R": "Glow-R",
    "SPRITES_GLOW-S": "Glow-S",
    "SPRITES_GLOW-T": "Glow-T",
    "SPRITES_GLOW-U": "Glow-U",
    "SPRITES_GLOW-V": "Glow-V",
    "SPRITES_GLOW-W": "Glow-W",
    "SPRITES_GLOW-X": "Glow-X",
    "SPRITES_GLOW-Y": "Glow-Y",
    "SPRITES_GLOW-Z": "Glühen-Z",
    "SPRITES_STORY-A": "Geschichte-A",
    "SPRITES_STORY-B": "Geschichte-B",
    "SPRITES_STORY-C": "Geschichte-C",
    "SPRITES_STORY-D": "Geschichte-D",
    "SPRITES_STORY-E": "Geschichte-E",
    "SPRITES_STORY-F": "Geschichte-F",
    "SPRITES_STORY-G": "Geschichte-G",
    "SPRITES_STORY-H": "Geschichte-H",
    "SPRITES_STORY-I": "Geschichte-I",
    "SPRITES_STORY-J": "Geschichte-J",
    "SPRITES_STORY-K": "Geschichte-K",
    "SPRITES_STORY-L": "Story-L",
    "SPRITES_STORY-M": "Story-M",
    "SPRITES_STORY-N": "Geschichte-N",
    "SPRITES_STORY-O": "Geschichte-O",
    "SPRITES_STORY-P": "Geschichte-P",
    "SPRITES_STORY-Q": "Geschichte-Q",
    "SPRITES_STORY-R": "Geschichte-R",
    "SPRITES_STORY-S": "Story-S",
    "SPRITES_STORY-T": "Geschichte-T",
    "SPRITES_STORY-U": "Geschichte-U",
    "SPRITES_STORY-V": "Geschichte-V",
    "SPRITES_STORY-W": "Geschichte-W",
    "SPRITES_STORY-X": "Geschichte-X",
    "SPRITES_STORY-Y": "Story-Y",
    "SPRITES_STORY-Z": "Geschichte-Z",
    "BACKDROPS_BEDROOM_1": "Schlafzimmer 1",
    "COSTUMES_STEAMED-STUFFED-BUN": "Gefüllte Brötchen",
    "COSTUMES_LUCKY-BAG": "Wundertüte",
    "COSTUMES_DUMPLINGS": "Teigtaschen",
    "COSTUMES_GOLD-COINS": "Goldmünzen",
    "COSTUMES_A-GIFT": "Ein Geschenk-A",
    "COSTUMES_STEAMED-BREAD": "Gedämpftes Brot",
    "COSTUMES_PLATES": "Teller",
    "COSTUMES_SILVER-INGOT": "Silberbarren",
    "COSTUMES_FIRECRACKERS": "Feuerwerkskörper",
    "COSTUMES_FIRECRACKERS-1": "Feuerwerkskörper 1",
    "COSTUMES_FIRECRACKERS-2": "Feuerwerkskörper 2",
    "COSTUMES_FIRECRACKERS-3": "Feuerwerkskörper 3",
    "COSTUMES_LANTERN": "Laterne",
    "COSTUMES_LANTERN-1": "Laterne 1",
    "COSTUMES_LANTERN-2": "Laterne 2",
    "COSTUMES_DRUM": "Drum-A",
    "COSTUMES_DRUM-1": "Drum 1",
    "COSTUMES_DRUM-2": "Drum 2",
    "COSTUMES_DRUM-3": "Drum 3",
    "COSTUMES_RED-ENVELOPE": "Roter Umschlag",
    "COSTUMES_RED-ENVELOPE-1": "Roter Umschlag 1",
    "COSTUMES_RED-ENVELOPE-2": "Roter Umschlag 2",
    "COSTUMES_SCROLL": "Rolle",
    "COSTUMES_SCROLL-1": "Rolle 1",
    "COSTUMES_SCROLL-2": "Rolle 2",
    "COSTUMES_SCROLL-3": "Rolle 3",
    "COSTUMES_YETI": "Yeti-A",
    "COSTUMES_YETI-1": "Yeti 1",
    "COSTUMES_YETI-2": "Yeti 2",
    "COSTUMES_FIREWORKS": "Feuerwerk",
    "COSTUMES_FIREWORKS-1": "Feuerwerk 1",
    "COSTUMES_FIREWORKS-2": "Feuerwerk 2",
    "COSTUMES_FIREWORKS-3": "Feuerwerk 3",
    "COSTUMES_FIREWORKS-4": "Feuerwerk 4",
    "COSTUMES_FIREWORKS-5": "Feuerwerk 5",
    "COSTUMES_FIREWORKS-6": "Feuerwerk 6",
    "COSTUMES_FIREWORKS-7": "Feuerwerk 7",
    "COSTUMES_FIREWORKS-8": "Feuerwerk 8",
    "COSTUMES_FIREWORKS-9": "Feuerwerk 9",
    "SPRITES_MIND+2022_01_CIVIL-AIRCRAFT": "Flugzeuge der Zivilluftfahrt",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT1": "Zivilluftfahrt-Flugzeugfront",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT2": "Zivilluftfahrt - Seite",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT3": "Zivilluftfahrt-Flugzeug-Top",
    "SPRITES_MIND+2022_02_AEROSPACEPLANE": "Space Shuttle",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE1": "Space Shuttle - Vorderseite",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE2": "Space-Shuttle-Seite",
    "SPRITES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM": "Space-Shuttle-Kabine",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM1": "Space-Shuttle-Kabinenfront",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM2": "Kabinenseite des Space Shuttles",
    "SPRITES_MIND+2022_04_TRAFFIC-LIGHT": "Ausdruck Ampel",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT1": "Ausdruck ampelrot",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT2": "Ausdruck Ampelgelb",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT3": "Ausdruck ampelgrün",
    "SPRITES_MIND+2022_05_FLYBROW": "Luftschiff",
    "COSTUMES_MIND+2022_05_FLYBROW1": "Luftschiffseite",
    "COSTUMES_MIND+2022_05_FLYBROW2": "Luftschiff-Look Up",
    "SPRITES_MIND+2022_06_HIGHSPEED-RAIL": "Hochgeschwindigkeitszug",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL1": "Hochgeschwindigkeitszug",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL2": "Hochgeschwindigkeitsbahnseite",
    "SPRITES_MIND+2022_07_POLICECAR": "Polizeiauto",
    "COSTUMES_MIND+2022_07_POLICECAR1": "Polizeiauto-Sport 1",
    "COSTUMES_MIND+2022_07_POLICECAR2": "Polizeiauto-Sport 2",
    "COSTUMES_MIND+2022_07_POLICECAR3": "Polizeiauto - Sport 3",
    "COSTUMES_MIND+2022_07_POLICECAR4": "Polizeiauto - Sport 4",
    "COSTUMES_MIND+2022_07_POLICECAR5": "Polizeiauto-Licht 1",
    "COSTUMES_MIND+2022_07_POLICECAR6": "Polizeiauto-Lichter 2",
    "COSTUMES_MIND+2022_07_POLICECAR7": "Polizeiauto – stationär",
    "SPRITES_MIND+2022_08_CAR": "Auto",
    "COSTUMES_MIND+2022_08_CAR1": "Auto-1",
    "COSTUMES_MIND+2022_08_CAR2": "Auto-2",
    "COSTUMES_MIND+2022_08_CAR3": "Auto-3",
    "COSTUMES_MIND+2022_08_CAR4": "Auto-4",
    "COSTUMES_MIND+2022_08_CAR5": "Auto-5",
    "COSTUMES_MIND+2022_08_CAR6": "Auto-6",
    "SPRITES_MIND+2022_09_DININGCAR": "Speisewagen",
    "SPRITES_MIND+2022_10_BUS": "Bus",
    "SPRITES_MIND+2022_11_GO-KART": "Kart",
    "SPRITES_MIND+2022_12_MOTORBIKE": "Motorrad",
    "SPRITES_MIND+2022_13_TAXI": "Taxi",
    "COSTUMES_MIND+2022_13_TAXI01": "Taxi – Vorderseite",
    "COSTUMES_MIND+2022_13_TAXI02": "Taxiseite",
    "COSTUMES_MIND+2022_13_TAXI03": "Taxi – Schalten Sie das Licht vorne und an der Seite aus",
    "COSTUMES_MIND+2022_13_TAXI04": "Taxi – Standlicht vorn an 1",
    "COSTUMES_MIND+2022_13_TAXI05": "Taxi - Lichter auf der Vorderseite 2",
    "COSTUMES_MIND+2022_13_TAXI06": "Taxi-Front",
    "SPRITES_MIND+2022_14_PUMPKINCAR": "Kürbisauto",
    "COSTUMES_MIND+2022_14_PUMPKINCAR1": "Kürbisauto-1",
    "COSTUMES_MIND+2022_14_PUMPKINCAR2": "Kürbisauto-2",
    "COSTUMES_MIND+2022_14_PUMPKINCAR3": "Kürbisauto-3",
    "COSTUMES_MIND+2022_14_PUMPKINCAR4": "Kürbisauto – Tür offen",
    "COSTUMES_MIND+2022_14_PUMPKINCAR5": "Kürbisauto-Nacht",
    "SPRITES_MIND+2022_15_LITTLESAILBOAT": "Kleines Segeln",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT1": "Kleine Segelseite",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT2": "Kleine Segeloberseite",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT3": "Segelboot-schräge Oberseite",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT4": "Segelboot – schräge Oberseite 1",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT5": "Segelboot – schräge Oberseite 2",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT6": "Segelboot – schräge Oberseite 3",
    "SPRITES_MIND+2022_16_RUBBLERACER": "Kleines Raumschiff",
    "COSTUMES_MIND+2022_16_RUBBLERACER1": "Kleines Raumschiff-01",
    "COSTUMES_MIND+2022_16_RUBBLERACER2": "Kleines Raumschiff-02",
    "COSTUMES_MIND+2022_16_RUBBLERACER3": "Kleines Raumschiff-03",
    "SPRITES_MIND+2022_17_BARNEY": "Kleiner Wagen",
    "COSTUMES_MIND+2022_17_BARNEY1": "Mini-Wagen – Seite voll",
    "COSTUMES_MIND+2022_17_BARNEY2": "Bergbauwagen – Oberseite",
    "COSTUMES_MIND+2022_17_BARNEY3": "Bergbauwagen – Oberseite 2",
    "COSTUMES_MIND+2022_17_BARNEY4": "Bergbauwagen – Oberseite 3",
    "COSTUMES_MIND+2022_17_BARNEY5": "Kleiner Minenwagen – Oberseite leer 1",
    "SPRITES_MIND+2022_18_UTILITYCART": "Wagen",
    "COSTUMES_MIND+2022_18_UTILITYCART1": "Wagenseite",
    "COSTUMES_MIND+2022_18_UTILITYCART2": "Wagen – Oberseite",
    "COSTUMES_MIND+2022_18_UTILITYCART3": "Wagenoberseite voll 1",
    "COSTUMES_MIND+2022_18_UTILITYCART4": "Warenkorb – Oberseite voll 2",
    "COSTUMES_MIND+2022_18_UTILITYCART5": "Wagenoberseite voll 3",
    "SPRITES_MIND+2022_19_BIKE": "Fahrrad",
    "COSTUMES_MIND+2022_19_BIKE1": "Fahrradseite 1",
    "COSTUMES_MIND+2022_19_BIKE2": "Fahrradseite 2",
    "COSTUMES_MIND+2022_19_BIKE3": "Fahrradseite 3",
    "COSTUMES_MIND+2022_19_BIKE4": "Fahrradseite 4",
    "COSTUMES_MIND+2022_19_BIKE5": "Fahrradfront",
    "SPRITES_MIND+2022_20_RACECAR": "Rennauto",
    "COSTUMES_MIND+2022_20_RACECAR1": "Rennseite",
    "COSTUMES_MIND+2022_20_RACECAR2": "Blaues Rennauto-Top 1",
    "COSTUMES_MIND+2022_20_RACECAR3": "Blauer Rennwagen – Top 2",
    "COSTUMES_MIND+2022_20_RACECAR4": "Blauer Rennwagen – Top 3",
    "COSTUMES_MIND+2022_20_RACECAR5": "Gelber Rennwagen – Top 1",
    "COSTUMES_MIND+2022_20_RACECAR6": "Gelber Rennwagen – Top 2",
    "COSTUMES_MIND+2022_20_RACECAR7": "Gelber Rennwagen – Top 3",
    "COSTUMES_MIND+2022_20_RACECAR8": "Rosa Rennwagen-Top 1",
    "COSTUMES_MIND+2022_20_RACECAR9": "Rosa Rennwagen – Top 2",
    "COSTUMES_MIND+2022_20_RACECAR10": "Rosa Rennwagen-Top 3",
    "SPRITES_MIND+2022_21_METEORITES1": "Meteorit",
    "COSTUMES_MIND+2022_21_METEORITES1_1": "Meteorit 1-01",
    "COSTUMES_MIND+2022_21_METEORITES1_2": "Meteorit 1-02",
    "COSTUMES_MIND+2022_21_METEORITES1_3": "Meteorit 1-03",
    "SPRITES_MIND+2022_22_METEORITES2": "Meteorit 2",
    "COSTUMES_MIND+2022_22_METEORITES2_1": "Meteorit 2-01",
    "COSTUMES_MIND+2022_22_METEORITES2_2": "Meteorit 2-02",
    "COSTUMES_MIND+2022_22_METEORITES2_3": "Meteorit 2-03",
    "SPRITES_MIND+2022_23_METEORITES3": "Meteorit 3",
    "COSTUMES_MIND+2022_23_METEORITES3_1": "Meteorit 3-01",
    "COSTUMES_MIND+2022_23_METEORITES3_2": "Meteorit 3-02",
    "COSTUMES_MIND+2022_23_METEORITES3_3": "Meteorit 3-03",
    "SPRITES_MIND+2022_24_METEORITES4": "Meteorit 4",
    "COSTUMES_MIND+2022_24_METEORITES4_1": "Meteorit 4-01",
    "COSTUMES_MIND+2022_24_METEORITES4_2": "Meteorit 4-02",
    "COSTUMES_MIND+2022_24_METEORITES4_3": "Meteorit 4-03",
    "COSTUMES_MIND+2022_24_METEORITES4_4": "Meteorit 4-04",
    "SPRITES_MIND+2022_25_METEORITES5": "Meteorit 5",
    "COSTUMES_MIND+2022_25_METEORITES5_1": "Meteorit 5-01",
    "COSTUMES_MIND+2022_25_METEORITES5_2": "Meteorit 5-02",
    "COSTUMES_MIND+2022_25_METEORITES5_3": "Meteorit 5-03",
    "COSTUMES_MIND+2022_25_METEORITES5_4": "Meteorit 5-04",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_01": "Meteoritenabenteuer A-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_02": "Meteoritenabenteuer A-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_01": "Meteoritenabenteuer B-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_02": "Meteoritenabenteuer B-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_03": "Meteoritenabenteuer B-3",
    "MIND+_FACE_SIDE": "Mind+ Gesichtsausdruck – Seite",
    "MIND+_FACE_SIDE-1": "Mind+ Gesichtsausdruck – Seite 1",
    "MIND+_FACE_SIDE-2": "Mind+ Gesichtsausdruck – Seite 2",
    "MIND+_FACE_SIDE-3": "Mind+ Gesichtsausdruck – Seite 3",
    "MIND+_FACE_SIDE-4": "Mind+ Gesichtsausdruck – Seite 4",
    "MIND+_FACE_SIDE-5": "Mind+ Gesichtsausdruck – Seite 5",
    "MIND+_FACE_SIDE-6": "Mind+ Gesichtsausdruck – Seite 6",
    "MIND+_FACE_SIDE-7": "Mind+ Gesichtsausdruck – Seite 7",
    "MIND+_FACE_SIDE-8": "Mind+ Gesichtsausdruck – Seite 8",
    "MIND+_FACE_SIDE-9": "Mind+ Gesichtsausdruck – Seite 9",
    "MIND+_FACE_SIDE-10": "Mind+ Gesichtsausdruck – Seite 10",
    "MIND+_FACE_POSITIVE": "Mind+ Gesichtsausdruck – vorne",
    "MIND+_FACE_POSITIVE-1": "Mind+ Gesichtsausdruck – Vorderseite-1",
    "MIND+_FACE_POSITIVE-2": "Mind+ Gesichtsausdruck – Vorderseite-2",
    "MIND+_FACE_POSITIVE-3": "Mind+ Gesichtsausdruck – Vorderseite-3",
    "MIND+_FACE_POSITIVE-4": "Mind+ Gesichtsausdruck – Vorderseite-4",
    "MIND+_FACE_POSITIVE-5": "Mind+ Gesichtsausdruck – Vorderseite-5",
    "MIND+_FACE_POSITIVE-6": "Mind+ Gesichtsausdruck – Vorderseite-6",
    "MIND+_FACE_POSITIVE-7": "Mind+ Gesichtsausdruck – Vorderseite-7",
    "MIND+_FACE_POSITIVE-8": "Mind+ Gesichtsausdruck – Vorderseite-8",
    "MIND+_FACE_POSITIVE-9": "Mind+ Gesichtsausdruck – Vorderseite-9",
    "MIND+_FACE_POSITIVE-10": "Mind+ Gesichtsausdruck – Vorderseite-10",
    "MIND+_DOZE": "dösen",
    "MIND+_DOZE-1": "dösen-1",
    "MIND+_DOZE-2": "dösen-2",
    "MIND+_DOZE-3": "dösen-3",
    "MIND+_SQUAT": "hocken und aufstehen",
    "MIND+_SQUAT-1": "Kniebeugen und Aufstehen-1",
    "MIND+_SQUAT-2": "Kniebeugen und Aufstehen-2",
    "MIND+_SQUAT-3": "Kniebeugen und Aufstehen-3",
    "MIND+_ARCHITECT": "Mind+ Architekt",
    "MIND+_ARCHITECT-1": "Mind+ Architekt-1",
    "MIND+_ARCHITECT-2": "Mind+ Architect-2",
    "MIND+_ARCHITECT-3": "Mind+ Architect-3",
    "MIND+_TRAFFIC_POLICE": "Mind+ Verkehrspolizist",
    "MIND+_TRAFFIC_POLICE-1": "Mind+ Verkehrspolizist-1",
    "MIND+_TRAFFIC_POLICE-2": "Mind+ Verkehrspolizist-2",
    "MIND+_TRAFFIC_POLICE-3": "Mind+ Verkehrspolizist-3",
    "MIND+_TRAFFIC_POLICE-4": "Mind+ Verkehrspolizist-4",
    "MIND+_TRAFFIC_POLICE-5": "Mind+ Verkehrspolizist-5",
    "MIND+_TRAFFIC_POLICE-6": "Mind+ Verkehrspolizist-6",
    "MIND+_POLICE": "Mind+ Polizist",
    "MIND+_POLICE-1": "Mind+ Polizist-1",
    "MIND+_POLICE-2": "Mind+ Polizist-2",
    "MIND+_POLICE-3": "Mind+ Polizist-3",
    "MIND+_POLICE-4": "Mind+ Polizist-4",
    "MIND+_POLICE-5": "Mind+ Polizist-5",
    "MIND+_POLICE-6": "Mind+ Polizistenhose-1",
    "MIND+_POLICE-7": "Mind+ Polizistenhose-2",
    "MIND+_SCIENTIST": "Mind+ Wissenschaftler",
    "MIND+_SCIENTIST-1": "Mind+ Wissenschaftler-1",
    "MIND+_SCIENTIST-2": "Mind+ Wissenschaftler-2",
    "MIND+_SCIENTIST-3": "Mind+ Wissenschaftler-3",
    "MIND+_SANTA_CLAUS": "Mind+ Weihnachtsmann",
    "MIND+_SANTA_CLAUS-1": "Mind+ Weihnachtsmann-winkende Hand",
    "MIND+_SANTA_CLAUS-2": "Mind+ Weihnachtsmann-Geschenke geben",
    "MIND+_SANTA_CLAUS-3": "Mind+ Weihnachtsmann-Tanz",
    "MIND+_FIREMAN": "Mind+ Feuerwehrmann",
    "MIND+_FIREMAN-1": "Mind+ Feuerwehrmann-1",
    "MIND+_FIREMAN-2": "Mind+ Feuerwehrmann-2",
    "MIND+_FIREMAN-3": "Mind+ Feuerwehrmann-3",
    "MIND+_FIREMAN-4": "Verstand+ Feuerwehrmann-4",
    "MIND+_DOCTOR": "Mind+ Doktor",
    "MIND+_DOCTOR-1": "Mind+ Arzt-1",
    "MIND+_DOCTOR-2": "Mind+ Arzt-2",
    "MIND+_DOCTOR-3": "Mind+ Arzt-3",
    "MIND+_ASTRONAUT_SPACECRAFT": "Mind+ Astronaut - Raumschiff",
    "MIND+_ASTRONAUT_SPACECRAFT-1": "Mind+ Astronaut - Raumschiff-1",
    "MIND+_ASTRONAUT_SPACECRAFT-2": "Mind+ Astronaut - Raumschiff-2",
    "MIND+_ASTRONAUT_SPACECRAFT-3": "Mind+ Astronaut - Raumschiff-3",
    "MIND+_ASTRONAUT_SPACECRAFT-4": "Mind+ Astronaut - Raumschiff-4",
    "MIND+_ASTRONAUT_SPACECRAFT-5": "Mind+ Astronaut - Raumschiff-5",
    "MIND+_ASTRONAUT_STANDING": "Mind+ Astronaut – stehend",
    "MIND+_ASTRONAUT_STANDING-1": "Mind+ Astronaut – stehend-1",
    "MIND+_ASTRONAUT_STANDING-2": "Mind+ Astronaut – stehend-2",
    "BRUSHWOOD": "Büschel",
    "BRUSHWOOD-1": "Büschel-1",
    "BRUSHWOOD-2": "Büschel-2",
    "BRUSHWOOD-3": "Büschel-3",
    "BRUSHWOOD-4": "Büschel-4",
    "STONE": "Stein",
    "STONE-1": "Stein-1",
    "STONE-2": "Stein-2",
    "STONE-3": "Stein-3",
    "STONE-4": "Stein-4",
    "TREE": "Baum",
    "TREE-1": "Baum-1",
    "TREE-2": "Baum-2",
    "TREE-3": "Baum-3",
    "TREE-4": "Baum-4",
    "COPSE": "Gehölz",
    "COPSE-1": "Wäldchen-1",
    "COPSE-2": "Wäldchen-2",
    "COPSE-3": "Wäldchen-3",
    "COPSE-4": "Wäldchen-4",
    "SNOW_MOUNTAIN": "Schneeberg",
    "SNOW_MOUNTAIN-1": "Schneeberg-1",
    "SNOW_MOUNTAIN-2": "Schneeberg-2",
    "SNOW_MOUNTAIN-3": "Schneeberg-3",
    "SNOW_MOUNTAIN-4": "Schneeberg-4",
    "CLOUD": "Wolke",
    "CLOUD-1": "Wolke-1",
    "CLOUD-2": "Wolke-2",
    "CLOUD-3": "Wolke-3",
    "CLOUD-4": "Wolke-4",
    "CARTOON_PINK_BLUE_BUTTON_GROUP": "Cartoon-Gruppe mit rosa und blauen Knöpfen",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-1": "Knopfladen",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-2": "Tastenspiel",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-3": "Taste-ausschalten",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-4": "Schaltfläche „Weiter“.",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-5": "Knopf-Stummschaltung",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-6": "Knopf-Start",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-7": "Knopf-Einschalten",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-8": "Tasteneinstellungen",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-9": "Schaltfläche-nächste Ebene",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-10": "Tastenlautstärke",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-11": "Taste-Pause",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-12": "Knopfwiederholung",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-13": "Pfeil nach oben",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-14": "Pfeil nach unten",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-15": "Pfeil nach rechts",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-16": "Pfeil nach links",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP": "Tech-Lila-Tastengruppe",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-1": "Knopf leer",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-2": "spielen",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-3": "Aktie",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-4": "überprüfen",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-5": "abschalten",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-6": "weitermachen",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-7": "stumm",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-8": "Spiel beginnen",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-9": "hoch",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-10": "Einstellungen",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-11": "runter",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-12": "Musik",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-13": "Spieleinstellungen",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-14": "Rechts",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-15": "Pause",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-16": "rechteckig, knopfleer",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-17": "Neustart",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-18": "links",
    "DIALOG-1": "Dialog-1",
    "DIALOG-2": "Dialog-2",
    "DIALOG-3": "Dialog-3",
    "DIALOG-4": "Dialog-4",
    "DIALOG-5": "Dialog-5",
    "DIALOG-6": "Dialog-6",
    "DIALOG-7": "Dialog-7",
    "DIALOG-8": "Dialog-8",
    "DIALOG-9": "Dialog-9",
    "DIALOG-10": "Dialog-10",
    "Epidemic_Prevention_MIND+": "Mind+ trägt Schutzanzüge",
    "Epidemic_Prevention_MIND+-1": "Mind+ trägt Schutzanzüge1",
    "Epidemic_Prevention_MIND+-2": "Mind+ trägt Schutzanzüge2",
    "VIRUS": "Virus",
    "VIRUS-1": "virus-a",
    "VIRUS-2": "virus-b",
    "VIRUS-3": "Virus-c",
    "ANTIGEN_REAGENT": "Antigentest",
    "ANTIGEN_REAGENT-1": "Antigentest-horizontal",
    "ANTIGEN_REAGENT-2": "Antigentest-vertikal",
    "MASK": "Maske",
    "MASK-1": "Maske-1",
    "MASK-2": "Maske-2",
    "MASK-3": "Maske-3",
    "MASK-4": "Maske-4",
    "MASK-5": "Maske-5",
    "MASK-6": "Maske-6",
    "TEMPERATURE_MEASURING_GUN": "Thermometerpistole",
    "STETHOSCOPE": "Stethoskop",
    "DISINFECTANT": "Desinfektionsmittel",
    "NUMBER-0": "0",
    "NUMBER-1": "1",
    "NUMBER-2": "2",
    "NUMBER-3": "3",
    "NUMBER-4": "4",
    "NUMBER-5": "5",
    "NUMBER-6": "6",
    "NUMBER-7": "7",
    "NUMBER-8": "8",
    "NUMBER-9": "9",
    "LETTER-A": "A",
    "LETTER-B": "B",
    "LETTER-C": "C",
    "LETTER-D": "D",
    "LETTER-E": "E",
    "LETTER-F": "F",
    "LETTER-G": "G",
    "LETTER-H": "H",
    "LETTER-I": "I",
    "LETTER-J": "J",
    "LETTER-K": "K",
    "LETTER-L": "L",
    "LETTER-M": "M",
    "LETTER-N": "N",
    "LETTER-O": "O",
    "LETTER-P": "P",
    "LETTER-Q": "Q",
    "LETTER-R": "R",
    "LETTER-S": "S",
    "LETTER-T": "T",
    "LETTER-U": "U",
    "LETTER-V": "V",
    "LETTER-W": "W",
    "LETTER-X": "X",
    "LETTER-Y": "Y",
    "LETTER-Z": "Z",
    "EXCLAMATORY_MARK": "Ausrufezeichen",
    "EXCLAMATORY_MARK-1": "Ausrufezeichen-1",
    "EXCLAMATORY_MARK-2": "Ausrufezeichen-2",
    "EXCLAMATORY_MARK-3": "Ausrufezeichen-3",
    "YELLOW_PROGRESS_BAR": "gelber Fortschrittsbalken",
    "YELLOW_PROGRESS_BAR-1": "Gelber Fortschrittsbalken-1",
    "YELLOW_PROGRESS_BAR-2": "Gelber Fortschrittsbalken-2",
    "YELLOW_PROGRESS_BAR-3": "Gelber Fortschrittsbalken-3",
    "PROGRESS_BAR": "Fortschrittsanzeige",
    "PROGRESS_BAR-1": "Fortschrittsbalken 30",
    "PROGRESS_BAR-2": "Fortschrittsbalken 60",
    "PROGRESS_BAR-3": "Fortschrittsbalken 100",
    "BLUE_PROGRESS_BAR": "blauer Fortschrittsbalken",
    "BLUE_PROGRESS_BAR-1": "Blauer Fortschrittsbalken-1",
    "BLUE_PROGRESS_BAR-2": "Blauer Fortschrittsbalken-2",
    "BLUE_PROGRESS_BAR-3": "Blauer Fortschrittsbalken-3",
    "STEREOSCOPIC_QUESTION_MARK": "3D-Fragezeichen",
    "STEREOSCOPIC_QUESTION_MARK-1": "Fragezeichen-grau",
    "STEREOSCOPIC_QUESTION_MARK-2": "Fragezeichen-Gold",
    "BUBBLE_BOX": "Popover",
    "BUBBLE_BOX-1": "popover-1",
    "BUBBLE_BOX-2": "Popover-2",
    "VICTORY_FAIL": "Sieg und Misserfolg",
    "VICTORY_FAIL-1": "Sheng",
    "VICTORY_FAIL-2": "li",
    "VICTORY_FAIL-3": "schi",
    "VICTORY_FAIL-4": "bai",
    "REFERENCE_NO": "Anführungszeichen",
    "REFERENCE_NO-1": "Anführungszeichen-1",
    "REFERENCE_NO-2": "Anführungszeichen-2",
    "RED_STRIPE": "roter Balken",
    "GOLD_COIN1": "Goldmünze 1",
    "BLUE_STRIPE": "blauer Balken",
    "LIFE": "Leben",
    "QUADRANGULAR_STAR": "viereckiger Stern",
    "QUESTION_MARK": "Fragezeichen",
    "FIVE-POINTED_STAR": "Pentagramm",
    "AIRSHIP": "Luftschiff1",
    "METEORITE": "Meteorit",
    "AIRCRAFT": "Flugzeug",
    "AIRCRAFT-1": "Flugzeug-1",
    "AIRCRAFT-2": "Flugzeug-2",
    "AIRCRAFT-3": "Flugzeug-3",
    "RETRO_AIRCRAFT": "Retro-Flugzeuge",
    "RETRO_AIRCRAFT-1": "Retro-Flugzeug-1",
    "RETRO_AIRCRAFT-2": "Retro-Flugzeug-2",
    "RETRO_AIRCRAFT-3": "Retro-Flugzeuge-3",
    "JEEP": "Jeep",
    "JEEP-1": "Jeep-1",
    "JEEP-2": "Jeep-2",
    "JEEP-3": "Jeep-3",
    "MOTORBOAT": "Motorboot",
    "MOTORBOAT-1": "Motorboot_Seitenansicht",
    "MOTORBOAT-2": "Motorboot_Draufsicht",
    "MOTORBOAT-3": "Motorboot_Rückansicht",
    "MOTORBOAT-4": "Motorboot_Vorderansicht",
    "SUBMARINE": "U-Boot",
    "SUBMARINE-1": "U-Boot-Seitenansicht",
    "SUBMARINE-2": "U-Boot-Ansicht von oben",
    "SUBMARINE-3": "submarine_back-Ansicht",
    "SUBMARINE-4": "submarine_frontansicht",
    "SMALL_SAILBOAT": "Segelboot1",
    "SMALL_SAILBOAT-1": "Segelboot_Seitenansicht",
    "SMALL_SAILBOAT-2": "Segelboot_Draufsicht",
    "SMALL_SAILBOAT-3": "Ansicht „segelboot_zurück“.",
    "SMALL_SAILBOAT-4": "Segelboot_Vorderansicht",
    "VAN": "Transporter",
    "VAN-1": "van_side-Ansicht",
    "VAN-2": "van_top-Ansicht",
    "VAN-3": "van_back-Ansicht",
    "VAN-4": "van_frontansicht",
    "HELICOPTER": "Hubschrauber",
    "HELICOPTER-1": "Hubschrauber_Seitenansicht",
    "HELICOPTER-2": "Helicopter_Top-Ansicht - 1",
    "HELICOPTER-3": "Helicopter_Top-Ansicht - 2",
    "HELICOPTER-4": "Helicopter_Top-Ansicht - 3",
    "HELICOPTER-5": "Helicopter_back-Ansicht",
    "HELICOPTER-6": "hubschrauber_vorderansicht",
    "BICYCLE": "Fahrrad - 1",
    "FIRECRACKERS": "Feuerwerkskörper",
    "FIRECRACKERS-1": "Feuerwerkskörper - 1",
    "FIRECRACKERS-2": "Feuerwerkskörper - 2",
    "FIRECRACKERS-3": "Feuerwerkskörper - 3",
    "COLORFUL_FLAGS": "bunte Flagge",
    "COLORFUL_FLAGS-1": "bunte Flagge - 1",
    "COLORFUL_FLAGS-2": "bunte Flagge - 2",
    "HAPPY_KNOT": "Segensknoten",
    "HAPPY_KNOT-1": "Segensknoten - 1",
    "HAPPY_KNOT-2": "Segensknoten - 2",
    "HAPPY_KNOT-3": "Segensknoten - 3",
    "HAPPY_KNOT-4": "Segensknoten - 4",
    "HAPPY_KNOT-5": "Segensknoten - 5",
    "HAPPY_KNOT-6": "Segensknoten - 6",
    "RED_ENVELOPE_ANIMATION": "GIF mit rotem Umschlag",
    "RED_ENVELOPE_ANIMATION-1": "GIF mit rotem Umschlag - 1",
    "RED_ENVELOPE_ANIMATION-2": "GIF mit rotem Umschlag - 2",
    "RED_ENVELOPE_ANIMATION-3": "GIF mit rotem Umschlag - 3",
    "RED_ENVELOPE_ANIMATION-4": "GIF mit rotem Umschlag - 4",
    "RED_ENVELOPE_ANIMATION-5": "GIF mit rotem Umschlag - 5",
    "RED_ENVELOPE_ANIMATION-6": "GIF mit rotem Umschlag - 6",
    "GOLD_COIN": "Goldmünze 2",
    "GOLD_COIN-1": "Goldmünze - 1",
    "GOLD_COIN-2": "Goldmünze - 2",
    "GOLD_COIN-3": "Goldmünze - 3",
    "ELK_CART": "Elchschlitten",
    "ELK_CART-1": "Elchschlitten - statisch",
    "ELK_CART-2": "Elchschlitten - in Bewegung",
    "CALENDAR": "Kalender",
    "CHRISTMAS_CANDY": "Weihnachtszuckerstange",
    "CHRISTMAS_CANDY-1": "Weihnachtszuckerstange - 1",
    "CHRISTMAS_CANDY-2": "Weihnachtszuckerstange - 2",
    "CHRISTMAS_CANDY-3": "Weihnachtszuckerstange - 3",
    "SANTA_CLAUS": "Weihnachtsmann",
    "SANTA_CLAUS-1": "Weihnachtsmann - 1",
    "SANTA_CLAUS-2": "Weihnachtsmann - 2",
    "SANTA_CLAUS-3": "Weihnachtsmann - 3",
    "SANTA_CLAUS2": "Weihnachtsmann 2",
    "JINGLING_BELL": "Klingglöckchen",
    "JINGLING_BELL-1": "Jingle Bell - 1",
    "JINGLING_BELL-2": "Jingle Bell - 2",
    "JINGLING_BELL-3": "Jingle Bell - Gold",
    "JINGLING_BELL-4": "Jingle Bell - Silber",
    "CHRISTMAS_TREE": "Weihnachtsbaum",
    "CHRISTMAS_TREE-1": "Weihnachtsbaum - 1",
    "CHRISTMAS_TREE-2": "Weihnachtsbaum - 2",
    "CHRISTMAS_TREE_DYNAMIC": "Weihnachtsbaum - GIF",
    "CHRISTMAS_TREE_DYNAMIC-1": "Weihnachtsbaum - rote Lichter",
    "CHRISTMAS_TREE_DYNAMIC-2": "Weihnachtsbaum - gelbe Lichter",
    "CHRISTMAS_TREE_DYNAMIC-3": "Weihnachtsbaum – Licht aus",
    "CHRISTMAS_TREE_DYNAMIC-4": "Weihnachtsbaum - Lichter an",
    "CHRISTMAS_STOCKING": "Weihnachtsstrumpf",
    "CHRISTMAS_STOCKING-1": "Weihnachtsstrumpf - grüne Sterne auf rotem Hintergrund",
    "CHRISTMAS_STOCKING-2": "Weihnachtsstrumpf - rote Sterne auf grünem Hintergrund",
    "CHRISTMAS_STOCKING-3": "Weihnachtsstrumpf - rote Streifen",
    "CHRISTMAS_STOCKING-4": "Weihnachtsstrumpf - grüne Streifen",
    "CANDY": "Süßigkeiten",
    "SOCKS": "Socken",
    "SMALL_WINDMILL": "kleine Windmühle",
    "LITTLE_WOODEN_HORSE": "kleines Holzpferd",
    "SNOWFLAKE": "Schneeflocke",
    "SNOWMAN1": "Schneemann 1",
    "SNOWMAN1-1": "Schneemann - Initiale",
    "SNOWMAN1-2": "Schneemann - mit Hut",
    "SNOWMAN1-3": "Schneemann - mit Handschuhen",
    "SNOWMAN1-4": "Schneemann - mit Schal",
    "SNOWMAN2": "Schneemann 2",
    "SNOWMAN2-1": "Schneemann - 1",
    "SNOWMAN2-2": "Schneemann - 2",
    "SNOWMAN2-3": "Schneemannkopf",
    "REINDEER": "Rentier",
    "REINDEER-1": "Rentier - 1",
    "REINDEER-2": "Rentier - 2",
    "REINDEER_CART": "Rentierschlitten",
    "REINDEER_CART-1": "Rentierschlitten - 1",
    "REINDEER_CART-2": "Rentierschlitten - 2",
    "ROUND_LANTERN": "runde Laterne",
    "ROUND_LANTERN-1": "runde Laterne - 1",
    "ROUND_LANTERN-2": "runde Laterne - 2",
    "LONG_LANTERN": "lange Laterne",
    "LONG_LANTERN-1": "lange Laterne - 1",
    "LONG_LANTERN-2": "lange Laterne - 2",
    "PAPER_AIRPLANE": "Papierflieger",
    "PAPER_AIRPLANE-1": "Papierflieger - rot",
    "PAPER_AIRPLANE-2": "Papierflieger - gelb",
    "PAPER_AIRPLANE-3": "Papierflieger - blau",
    "PAPER_AIRPLANE-4": "Papierflieger - grün",
    "CHINESE_KNOT": "chinesischer Knoten",
    "CHINESE_KNOT-1": "Chinesischer Knoten - 1",
    "CHINESE_KNOT-2": "Chinesischer Knoten - 2",
    "SKIING_PEOPLE": "Skifahren - Leute",
    "SKIING_PEOPLE-1": "Skifahren - Menschen - Springen 1",
    "SKIING_PEOPLE-2": "Skifahren - Menschen - Springen 2",
    "SKIING_PEOPLE-3": "Skifahren - Menschen - Rutschen 1",
    "SKIING_PEOPLE-4": "Skifahren - Menschen - Rutschen 2",
    "BOY": "Junge",
    "BOY-1": "Junge - 1",
    "BOY-2": "Junge - 2",
    "BOY-3": "Junge - 3",
    "BOY-4": "Junge - 4",
    "AVATAR_BOY": "Jungen-Avatar",
    "AVATAR_GIRL": "Mädchen-Avatar",
    "GIRL": "Mädchen",
    "GIRL-1": "Mädchen - 1",
    "GIRL-2": "Mädchen - 2",
    "GIRL-3": "Mädchen - 3",
    "GIRL-4": "Mädchen - 4",
    "TURKEY": "Truthahnhuhn",
    "GINGERBREAD_MAN": "Lebkuchenmann",
    "SKI_MARK": "Skimarke",
    "SKI_MARK-1": "Skimarke - 1",
    "SKI_MARK-2": "Skimarke - 2",
    "SKI_MARK-3": "Skimarke - 3",
    "SKI_MARK-4": "Skimarke - 4",
    "BACKDROPS_2022_SKI-FIELD-1": "Skipiste - 1",
    "BACKDROPS_2022_SKI-FIELD-2": "Skipiste - 2",
    "BACKDROPS_2022_AIRPORT-RUNWAY": "Flughafen-Rollbahn",
    "BACKDROPS_2022_INTERSECTION": "Überschneidung",
    "BACKDROPS_2022_SKY-1": "Himmel - 1",
    "BACKDROPS_2022_SKY-2": "Himmel - 2",
    "BACKDROPS_2022_SETTING-SUN-1": "Sonnenuntergang - 1",
    "BACKDROPS_2022_SETTING-SUN-2": "Sonnenuntergang - 2",
    "BACKDROPS_2022_SETTING-SUN-3": "Sonnenuntergang - 3",
    "BACKDROPS_2022_MARS": "Mars",
    "BACKDROPS_2022_STARRY-SKY-1": "Sternenhimmel - 1",
    "BACKDROPS_2022_STARRY-SKY-2": "Sternenhimmel - 2",
    "BACKDROPS_2022_STARRY-SKY-3": "Sternenhimmel - 3",
    "BACKDROPS_2022_MOON-1": "Mond - 1",
    "BACKDROPS_2022_MOON-2": "Mond - 2",
    "BACKDROPS_2022_SANTA-BACKGROUND": "Weihnachtsmann-Hintergrund",
    "BACKDROPS_2022_CHRISTMAS-THEME-BACKGROUND": "Weihnachtshintergrund",
    "BACKDROPS_2022_SNOWMAN-BACKGROUND": "Schneemann-Hintergrund",
    "WHITE-MOON": "Weißer Mond",
    "GRASSLAND": "Wiese",
    "YELLOW-MOON": "Gelber Mond",
    "SKATEBOARD-1": "Skateboard - seitlich",
    "SKATEBOARD-2": "Skateboard - unten",
    "SKATEBOARD-3": "Skateboard - oben",
    "SKATEBOARD-4": "Skateboard - vorn",
    "SKATEBOARD": "Skateboard",
    "CARTOON-ARTPLANES-1": "Cartoon Artplans - vorn",
    "CARTOON-ARTPLANES-2": "Cartoon Artplans - unten",
    "CARTOON-ARTPLANES-3": "Cartoon Artplans - rechts",
    "CARTOON-ARTPLANES-4": "Cartoon Artplans - links",
    "CARTOON-ARTPLANES-5": "Cartoon Artplans - oben",
    "CARTOON-ARTPLANES-6": "Cartoon Artplans - hinten",
    "CARTOON-ARTPLANES": "Cartoon-Artplans",
    "BICYCLE-GRAY-1": "Fahrrad-Grau - seitlich",
    "BICYCLE-GRAY-2": "Fahrrad-Grau - oben",
    "BICYCLE-GRAY-3": "Fahrradgrau - vorn",
    "BICYCLE-GRAY-4": "Fahrradgrau - hinten",
    "BICYCLE-GRAY": "Fahrrad-Grau",
    "BATS-1": "Fledermäuse-1",
    "BATS-2": "Fledermäuse-2",
    "BATS-3": "Fledermäuse-3",
    "BATS-4": "Fledermäuse-4",
    "BATS": "Fledermäuse",
    "CASTLE-1": "Schloss-1",
    "CASTLE-2": "Schloss-2",
    "CASTLE": "Schloss",
    "JACK-o'-LANTERN-1": "Kürbislaterne1-1",
    "JACK-o'-LANTERN-2": "Kürbislaterne1-2",
    "JACK-o'-LANTERN-3": "Kürbislaterne1-3",
    "JACK-o'-LANTERN-4": "Kürbislaterne2",
    "JACK-o'-LANTERN-5": "Kürbislaterne3",
    "JACK-o'-LANTERN-6": "Kürbislaterne4",
    "JACK-o'-LANTERN": "Kürbislaterne",
    "WOODS-1": "Wald-1",
    "WOODS-2": "Wald-2",
    "WOODS": "Wald",
    "DANCING-LION-RED-1": "Tanzender Löwe-Rot-1",
    "DANCING-LION-RED-2": "Tanzender Löwe-Rot-2",
    "DANCING-LION-RED-3": "Tanzender Löwe-Rot-3",
    "DANCING-LION-RED-4": "Tanzender Löwe-Rot-4",
    "DANCING-LION-RED-5": "Tanzender Löwe-Rot-5",
    "DANCING-LION-RED-6": "Tanzender Löwe-Rot-6",
    "DANCING-LION-RED": "Tanzender Löwe-Rot",
    "DANCING-LION-BLUE-1": "Tanzender Löwe-Blau-1",
    "DANCING-LION-BLUE-2": "Tanzender Löwe-Blau-2",
    "DANCING-LION-BLUE-3": "Tanzender Löwe-Blau-3",
    "DANCING-LION-BLUE": "Tanzender Löwe-Blau",
    "DANCING-LION-GREEN-1": "Tanzender Löwe-Grün-1",
    "DANCING-LION-GREEN-2": "Tanzender Löwe-Grün-2",
    "DANCING-LION-GREEN-3": "Tanzender Löwe-Grün-3",
    "DANCING-LION-GREEN": "Tanzender Löwe-Grün",
    "SPECTRE-1": "Gespenst-1",
    "SPECTRE-2": "Gespenst-2",
    "SPECTRE-3": "Gespenst-3",
    "SPECTRE-4": "Gespenst-4",
    "SPECTRE": "Gespenst",
    "SPIDER-1": "Spinne-1",
    "SPIDER-2": "Spinne-2",
    "SPIDER-3": "Spinne-3",
    "SPIDER": "Spinne",
    "GOLF-PEOPLE1-1": "Golf-Leute1-1",
    "GOLF-PEOPLE1-2": "Golf-Leute1-2",
    "GOLF-PEOPLE1-3": "Golf-Leute1-3",
    "GOLF-PEOPLE1": "Golf-Leute1",
    "GOLF-PEOPLE2-1": "Golf-Leute2-1",
    "GOLF-PEOPLE2-2": "Golf-Leute2-2",
    "GOLF-PEOPLE2-3": "Golf-Leute2-3",
    "GOLF-PEOPLE2": "Golf-Leute2",
    "GOLF": "Golf",
    "GOLF-HOLES": "Golf-Löcher",
    "GOLF-SEATS": "Golfsitze",
    "GREEN-LEAVES-1": "Grüne Blätter-1",
    "GREEN-LEAVES-2": "Grüne Blätter-2",
    "GREEN-LEAVES": "Grüne Blätter",
    "CACTUS-1": "Kaktus-1",
    "CACTUS-2": "Kaktus-2",
    "CACTUS": "Kaktus",
    "FLORETS-1": "Blume-1",
    "FLORETS-2": "Blume-2",
    "FLORETS-3": "Blume-3",
    "FLORETS-4": "Blume-4",
    "FLORETS-5": "Blume-5",
    "FLORETS-6": "Blume-6",
    "FLORETS-7": "Blume-7",
    "FLORETS-8": "Blume-8",
    "FLORETS": "Blume"
  },
  "et": {
    "gui.backpack.header": "Seljakott",
    "gui.backpack.errorBackpack": "Viga seljakoti laadimisel",
    "gui.backpack.loadingBackpack": "Laadib...",
    "gui.backpack.more": "Rohkem",
    "gui.backpack.emptyBackpack": "Seljakott on tühi",
    "gui.gui.costumesTab": "Kostüümid",
    "gui.gui.soundsTab": "Helid",
    "gui.gui.backdropsTab": "Taustad",
    "gui.gui.addExtension": "Lisa laiendus",
    "gui.costumeTab.addCostumeFromLibrary": "Vali kostüüm",
    "gui.costumeLibrary.chooseACostume": "Vali kostüüm",
    "gui.costumeTab.addBackdropFromLibrary": "Vali taust",
    "gui.costumeTab.addBlankCostume": "Joonista",
    "gui.costumeTab.addSurpriseCostume": "Juhuslik",
    "gui.costumeTab.addFileBackdrop": "Laadi taust",
    "gui.costumeTab.addFileCostume": "Laadi kostüüm",
    "gui.costumeTab.addCameraCostume": "Kaamera",
    "gui.soundEditor.trim": "Kärbi",
    "gui.soundEditor.stop": "Stopp",
    "gui.soundEditor.sound": "Heli",
    "gui.soundEditor.play": "Käivita",
    "gui.soundEditor.save": "Salvesta",
    "gui.soundEditor.undo": "Võta tagasi",
    "gui.soundEditor.redo": "Tee uuesti",
    "gui.soundEditor.faster": "Kiiremini",
    "gui.soundEditor.slower": "Aeglasemalt",
    "gui.soundEditor.echo": "Kaja",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Valjemini",
    "gui.soundEditor.softer": "Pehmemalt",
    "gui.soundEditor.reverse": "Tagurpidi",
    "gui.soundTab.recordSound": "Salvesta",
    "gui.soundTab.addSoundFromLibrary": "Vali heli",
    "gui.soundTab.surpriseSound": "Juhuslik",
    "gui.soundTab.fileUploadSound": "Laadi heli",
    "gui.controls.stop": "Stopp",
    "gui.controls.go": "Mine",
    "gui.stageHeader.stageSizeUnFull": "Sulge täisekraan",
    "gui.SpriteInfo.show": "Näita",
    "gui.SpriteInfo.size": "Suurus",
    "gui.directionPicker.rotationStyles.allAround": "suvaline",
    "gui.directionPicker.rotationStyles.leftRight": "vasak/parem",
    "gui.directionPicker.rotationStyles.dontRotate": "ei pöördu",
    "gui.spriteSelectorItem.contextMenuDuplicate": "paljunda",
    "gui.spriteSelectorItem.contextMenuDelete": "eemalda",
    "gui.spriteSelectorItem.contextMenuExport": "eksport",
    "gui.spriteSelector.addSpriteFromPaint": "Joonista",
    "gui.spriteSelector.addSpriteFromSurprise": "Juhuslik",
    "gui.spriteSelector.addSpriteFromFile": "Laadi sprait",
    "gui.stageSelector.stage": "Lava",
    "gui.stageSelector.backdrops": "Taustad",
    "gui.stageSelector.addBackdropFromPaint": "Joonista",
    "gui.stageSelector.addBackdropFromSurprise": "Juhuslik",
    "gui.stageSelector.addBackdropFromFile": "Laadi taust",
    "gui.modal.back": "Tagasi",
    "gui.library.filterPlaceholder": "Otsing",
    "gui.libraryTags.all": "Kõik",
    "gui.libraryTags.animals": "Loomad",
    "gui.libraryTags.dance": "Tantsimine",
    "gui.libraryTags.effects": "Efektid",
    "gui.libraryTags.fantasy": "Fantaasia",
    "gui.libraryTags.fashion": "Mood",
    "gui.libraryTags.food": "Toit",
    "gui.libraryTags.indoors": "Ruumis",
    "gui.libraryTags.loops": "Kordused",
    "gui.libraryTags.music": "Muusika",
    "gui.libraryTags.notes": "Noodid",
    "gui.libraryTags.outdoors": "Õues",
    "gui.libraryTags.patterns": "Mustrid",
    "gui.libraryTags.people": "Inimesed",
    "gui.libraryTags.percussion": "Löökpillid",
    "gui.libraryTags.space": "Kosmos",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Vee all",
    "gui.libraryTags.voice": "Hääled",
    "gui.libraryTags.wacky": "Jabur",
    "gui.libraryTags.animation": "Animatsioon",
    "gui.libraryTags.art": "Kunst",
    "gui.libraryTags.games": "Mängud",
    "gui.libraryTags.stories": "Lood",
    "gui.libraryTags.letters": "Tähed",
    "gui.soundLibrary.chooseASound": "Vali heli",
    "gui.SpriteInfo.spritePlaceholder": "Nimi",
    "gui.cards.more-things-to-try": "Proovi veel asju!",
    "gui.cards.see-more": "Vaata veel",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Sulge",
    "gui.loader.message1": "Plokkide loomine...",
    "gui.loader.message2": "Spraitide laadimine...",
    "gui.loader.message3": "Helide laadimine...",
    "gui.loader.message4": "Laienduste laadimine...",
    "gui.loader.message5": "Kaslaste karjatamine...",
    "gui.loader.message6": "Nanode edastamine...",
    "gui.loader.message7": "Gobode vuntsimine...",
    "gui.loader.message8": "Emotikonide valmistamine...",
    "gui.loader.headline": "Projekti laadimine",
    "gui.cameraModal.cameraModalTitle": "Tee foto",
    "gui.cameraModal.loadingCameraMessage": "Laadib kaamerat...",
    "gui.cameraModal.permissionRequest": "Vajame luba kaamera kasutamiseks.",
    "gui.cameraModal.retakePhoto": "Tee uus foto",
    "gui.cameraModal.save": "Salvesta",
    "gui.cameraModal.takePhoto": "Tee foto",
    "gui.cameraModal.loadingCaption": "Laadib...",
    "gui.cameraModal.enableCameraCaption": "Luba kaamerat kasutada",
    "gui.recordModal.title": "Salvesta heli",
    "gui.playbackStep.stopMsg": "Stopp",
    "gui.playbackStep.playMsg": "Käivita",
    "gui.playbackStep.loadingMsg": "Laadib...",
    "gui.playbackStep.saveMsg": "Salvesta",
    "gui.playbackStep.reRecordMsg": "Salvesta uuesti",
    "gui.webglModal.label": "Su veebilehitseja ei toeta WebGL-i",
    "gui.webglModal.webgllink": "ei toeta WebGL-i",
    "gui.prompt.cancel": "Katkesta",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Lisa parameeter",
    "gui.customProcedures.addAnInputBoolean": "Lisa parameeter",
    "gui.customProcedures.numberTextType": "arv või tekst",
    "gui.customProcedures.booleanType": "tõeväärtus",
    "gui.customProcedures.addALabel": "Lisa märgend",
    "gui.customProcedures.runWithoutScreenRefresh": "Täida ekraanikuva uuendamata",
    "gui.customProcedures.cancel": "Katkesta",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Ühenda oma projektid pärismaailmaga.",
    "gui.extension.music.name": "Muusika",
    "gui.extension.music.description": "Mängi pille ja trumme.",
    "gui.extension.pen.name": "Pliiats",
    "gui.extension.pen.description": "Joonista spraitidega",
    "gui.gui.variableScopeOptionAllSprites": "Kõikide spraitide jaoks",
    "gui.gui.variableScopeOptionSpriteOnly": "Ainult selle spraidi jaoks",
    "gui.gui.variablePromptAllSpritesMessage": "See muutuja on kättesaadav kõigile spraitidele",
    "gui.monitor.contextMenu.default": "tavaline näidik",
    "gui.monitor.contextMenu.large": "suur näidik",
    "gui.monitor.contextMenu.slider": "liugur",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "suund",
    "gui.opcodeLabels.xposition": "x",
    "gui.opcodeLabels.yposition": "y",
    "gui.opcodeLabels.size": "suurus",
    "gui.opcodeLabels.costumename": "kostüümi nimi",
    "gui.opcodeLabels.costumenumber": "kostüümi nr",
    "gui.opcodeLabels.backdropname": "tausta nimi",
    "gui.opcodeLabels.backdropnumber": "tausta nr",
    "gui.opcodeLabels.volume": "helitugevus",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "vastus",
    "gui.opcodeLabels.loudness": "valjus",
    "gui.opcodeLabels.year": "aasta",
    "gui.opcodeLabels.month": "kuu",
    "gui.opcodeLabels.date": "kuupäev",
    "gui.opcodeLabels.dayofweek": "nädalapäev",
    "gui.opcodeLabels.hour": "tunnid",
    "gui.opcodeLabels.minute": "minutid",
    "gui.opcodeLabels.second": "sekundid",
    "gui.opcodeLabels.timer": "taimer",
    "music.categoryName": "Muusika",
    "translate.categoryName": "Tõlgi",
    "pen.categoryName": "Pliiats",
    "pen.changeColorParam": "muuda pliiatsi [COLOR_PARAM] [VALUE] võrra",
    "pen.changeHue": "muuda pliiatsi värvi [HUE] võrra",
    "pen.changeShade": "muuda pliiatsi varjundit [SHADE] võrra",
    "pen.changeSize": "muuda pliiatsi suurust [SIZE] võrra",
    "pen.clear": "kustuta kõik",
    "pen.colorMenu.brightness": "heledus",
    "pen.colorMenu.color": "värv",
    "pen.colorMenu.saturation": "küllastus",
    "pen.colorMenu.transparency": "läbipaistvus",
    "pen.penDown": "pliiats alla",
    "pen.penUp": "pliiats üles",
    "pen.setColor": "võta pliiatsi värviks [COLOR]",
    "pen.setColorParam": "võta pliiatsi [COLOR_PARAM] [VALUE]",
    "pen.setHue": "võta pliiatsi värviks [HUE]",
    "pen.setShade": "võta pliiatsi varjundiks [SHADE]",
    "pen.setSize": "võta pliiatsi suuruseks [SIZE]",
    "pen.stamp": "jäljend",
    "music.changeTempo": "muuda tempot [TEMPO] võrra",
    "music.drumBass": "(2) Basstrumm",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Pulgad",
    "music.drumClosedHiHat": "(6) Suletud Hi-Hat",
    "music.drumConga": "(14) Konga",
    "music.drumCowbell": "(11) Lehmakell",
    "music.drumCrashCymbal": "(4) Taldrikud",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Käteplaks",
    "music.drumOpenHiHat": "(5) Lahtine Hi-Hat",
    "music.drumSideStick": "(3) Trummi serv",
    "music.drumSnare": "(1) Soolotrumm",
    "music.drumTambourine": "(7) Tamburiin",
    "music.drumTriangle": "(12) Triangel",
    "music.drumVibraslap": "(17) Põskvibrafon",
    "music.drumWoodBlock": "(10) Puitklots",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Fagott",
    "music.instrumentCello": "(8) Tšello",
    "music.instrumentChoir": "(15) Koor",
    "music.instrumentClarinet": "(10) Klarnet",
    "music.instrumentElectricGuitar": "(5) Elektrikitarr",
    "music.instrumentElectricPiano": "(2) Elektriklaver",
    "music.instrumentFlute": "(12) Flööt",
    "music.instrumentGuitar": "(4) Kitarr",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Mängutoos",
    "music.instrumentOrgan": "(3) Orel",
    "music.instrumentPiano": "(1) Klaver",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saksofon",
    "music.instrumentSteelDrum": "(18) Terastrumm",
    "music.instrumentSynthLead": "(20) Süntesaatori soolo",
    "music.instrumentSynthPad": "(21) Süntesaatori taust",
    "music.instrumentTrombone": "(9) Tromboon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Puitflööt",
    "music.midiPlayDrumForBeats": "mängi trummi [DRUM] kestusega [BEATS]",
    "music.midiSetInstrument": "võta instrumendiks [INSTRUMENT]",
    "music.playDrumForBeats": "mängi trummi [DRUM] kestusega [BEATS]",
    "music.playNoteForBeats": "mängi nooti [NOTE] [BEATS] lööki",
    "music.restForBeats": "paus [BEATS] lööki",
    "music.setInstrument": "võta instrumendiks [INSTRUMENT]",
    "music.setTempo": "võta tempoks [TEMPO]",
    "videoSensing.categoryName": "Videoandurid",
    "videoSensing.direction": "suund",
    "videoSensing.motion": "liikumine",
    "videoSensing.off": "välja",
    "videoSensing.on": "sisse",
    "videoSensing.onFlipped": "peegeldatuna",
    "videoSensing.setVideoTransparency": "võta video läbipaistvus [TRANSPARENCY]",
    "videoSensing.sprite": "sprait",
    "videoSensing.stage": "lava",
    "videoSensing.videoOn": "[SUBJECT] video [ATTRIBUTE] ",
    "videoSensing.videoToggle": "lülita video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "kui video liikumine > [REFERENCE]",
    "translate.translateBlock": "tõlgi [WORDS] [LANGUAGE] keelde",
    "translate.defaultTextToTranslate": "tere",
    "translate.viewerLanguage": "keel",
    "text2speech.speakAndWaitBlock": "ütle [WORDS]",
    "text2speech.setVoiceBlock": "võta hääleks [VOICE]",
    "text2speech.setLanguageBlock": "võta keeleks [LANGUAGE]",
    "text2speech.alto": "alt",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "piiksumine",
    "text2speech.giant": "mörin",
    "text2speech.kitten": "kiisu",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "kui kuulen [PHRASE]",
    "speech.listenAndWait": "kuula ja oota",
    "speech.defaultWhenIHearValue": "alustame",
    "paint.paintEditor.hue": "Värv",
    "paint.paintEditor.saturation": "Küllastus",
    "paint.paintEditor.brightness": "Heledus",
    "gui.comingSoon.message1": "Ära muretse, tegeleme sellega {emoji}",
    "gui.comingSoon.message2": "Ilmub varsti...",
    "gui.comingSoon.message3": "Tegeleme sellega {emoji}",
    "paint.paintEditor.fill": "Täide",
    "paint.paintEditor.costume": "Kostüüm",
    "paint.paintEditor.group": "Rühmita",
    "paint.paintEditor.ungroup": "Tühista rühmitus",
    "paint.paintEditor.undo": "Võta tagasi",
    "paint.paintEditor.redo": "Tee uuesti",
    "paint.paintEditor.forward": "Ettepoole",
    "paint.paintEditor.backward": "Tahapoole",
    "paint.paintEditor.front": "Esiplaanile",
    "paint.paintEditor.back": "Tahaplaanile",
    "paint.paintEditor.more": "Rohkem",
    "paint.modeTools.brushSize": "Suurus",
    "paint.modeTools.eraserSize": "Kustutaja suurus",
    "paint.modeTools.copy": "Kopeeri",
    "paint.modeTools.paste": "Kleebi",
    "paint.modeTools.delete": "Kustuta",
    "paint.modeTools.curved": "Kõver",
    "paint.modeTools.pointed": "Teravatipuline",
    "paint.modeTools.thickness": "Paksus",
    "paint.modeTools.flipHorizontal": "Horisontaalne peegeldus",
    "paint.modeTools.flipVertical": "Vertikaalne peegeldus",
    "paint.modeTools.filled": "Täidetud",
    "paint.modeTools.outlined": "Piirjoonega",
    "paint.paintEditor.bitmap": "Rastergraafikasse",
    "paint.paintEditor.vector": "Vektorgraafikasse",
    "paint.paintEditor.stroke": "Piirjoon",
    "paint.brushMode.brush": "Pintsel",
    "paint.eraserMode.eraser": "Kustutaja",
    "paint.fillMode.fill": "Täide",
    "paint.lineMode.line": "Joon",
    "paint.ovalMode.oval": "Ring",
    "paint.rectMode.rect": "Ristkülik",
    "paint.reshapeMode.reshape": "Kuju muutmine",
    "paint.roundedRectMode.roundedRect": "Ümarate nurkadega ristkülik",
    "paint.selectMode.select": "Vali",
    "paint.textMode.text": "Tekst",
    "paint.colorPicker.swap": "Vaheta",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "el": {
    "gui.gui.project": "Εφαρμογή",
    "gui.gui.newItem": "Νέα Εφαρμογή",
    "gui.gui.load": "Άνοιγμα Εφαρμογής",
    "gui.gui.save": "Αποθήκευση",
    "gui.gui.saveAs": "Αποθήκευση ως…",
    "gui.gui.learn": "Εκμάθηση",
    "gui.gui.clearRecord": "Εκκαθάριση εγγραφής",
    "gui.gui.onlineLearn": "Official Documents",
    "gui.gui.onlineForum": "Online Forum",
    "gui.gui.videoTutorial": "Video Tutorials",
    "gui.gui.offlineLearn": "Παραδείγματα",
    "gui.menuBar.turboModeOff": "Turn OFF Turbo Mode",
    "gui.menuBar.turboModeOn": "Turn ON Turbo Mode",
    "gui.menuBar.edit": "Επεξεργασία",
    "gui.menuBar.restoreSprite": "Επαναφορά Sprite",
    "gui.menuBar.restoreSound": "Επαναφορά ήχου",
    "gui.menuBar.restoreCostume": "Επαναφορά κουστουμιού",
    "gui.menuBar.restoreBackdrop": "Restore Backdrop",
    "gui.editorMind.restore": "Επαναφορά",
    "gui.turboMode.active": "Turbo Mode",
    "gui.gui.connectDevice": "Σύνδεση Συσκευής",
    "gui.gui.disconnectDevice": "Αποσύνδεση Συσκευής",
    "gui.gui.installSerialportDriver": "Εγκατάσταση Driver σειριακής",
    "gui.gui.openDM": "Άνοιξε τη διαχείριση συσκευών",
    "gui.gui.restoreSetting": "Επαναφορά αρχικών ρυθμίσεων συσκευής",
    "gui.gui.updateTips": "Update Tips",
    "gui.gui.newVersion": "Η τελευταία έκδοση",
    "gui.gui.downloadUpdate": "Αναβαθμίστε",
    "gui.gui.versionUpdate": "Αναβαθμίστε την έκδοση",
    "gui.gui.isCheckMd5": "Verifying file",
    "gui.gui.downloading": "Checking Updator",
    "gui.gui.updateError": "Πρόβλημα αναβάθμισης ",
    "gui.setting.feedbackMenu": "Ανατροφοδότηση",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.setting.officialWebsit": "Επίσημη Ιστοσελίδα",
    "gui.setting.officialQQGroup": "Official QQ Group",
    "gui.setting.wechatOfficialAccounts": "WeChat Official Accounts",
    "gui.setting.currentVersion": "Τρέχουσα έκδοση",
    "gui.setting.checkForUpdate": "Ελεγχος για αναβάθμιση ",
    "gui.setting.remindWhenUpdate": "Download when updated",
    "gui.setting.currentIsLatest": "Το πρόγραμμα είναι ενημερωμένο",
    "gui.setting.latestVersion": "Η τελευταία έκδοση",
    "gui.setting.download": "Skip to download web",
    "gui.setting.language": "Γλώσσα",
    "gui.setting.uploadCsv": "Ανεβάστε xlsx",
    "gui.setting.theme": "Θέματα",
    "gui.setting.feedback": "Επικοινωνία",
    "gui.setting.email": "E-mail",
    "gui.setting.opinionFeedback": "Ανατροφοδότηση",
    "gui.variableMc.variableName": "όνομα μεταβλητής",
    "gui.variableMc.variableOff": "ακύρωση",
    "gui.variableMc.variableOn": "επικύρωση",
    "gui.variableMc.newVariable": "νέα μεταβλητή",
    "gui.setting.helpUsTranslate": "Help Us Translate",
    "gui.setting.checkUpdate": "έλεγχος για ενημερώσεις",
    "gui.setting.ok": "Εντάξει",
    "gui.setting.languageSetting": "Γλώσσα",
    "gui.setting.themeSetting": "Θέμα",
    "gui.setting.versionUpdate": "Ενημέρωση έκδοσης",
    "gui.setting.connectUsTitle": "Επικοινωνία με μας",
    "gui.setting.uploadAttachment": "Ανέβασμα Συνημμένου",
    "gui.setting.displayTitle": "Ρυθμίσεις Οθόνης",
    "gui.setting.fontSizeSetting": "μέγεθος γραμματοσειράς",
    "gui.setting.restartEffect": "restart effect",
    "gui.setting.fontSizeLarge": "Μεγάλα",
    "gui.setting.fontSizeMiddle": "Μέτρια",
    "gui.setting.fontSizeSmall": "Μικρά",
    "gui.setting.onlineRepair": "online repair tool, click open >",
    "gui.backpack.header": "Σακίδιο",
    "gui.backpack.errorBackpack": "Σφάλμα κατά τη φόρτωση σακιδίου",
    "gui.backpack.loadingBackpack": "Γίνεται φόρτωση...",
    "gui.backpack.more": "Περισσότερα",
    "gui.backpack.emptyBackpack": "Το σακίδιο είναι άδειο",
    "gui.gui.costumesTab": "Ενδυμασίες",
    "gui.gui.soundsTab": "Ήχοι",
    "gui.gui.backdropsTab": "Yπόβαθρα",
    "gui.gui.addExtension": "Προσθήκη Επέκτασης",
    "gui.costumeTab.addCostumeFromLibrary": "Επιλέξτε Ενδυμασία",
    "gui.costumeLibrary.chooseACostume": "Επιλέξτε Ενδυμασία",
    "gui.costumeTab.addBackdropFromLibrary": "Επιλέξτε Υπόβαθρο",
    "gui.costumeTab.addBlankCostume": "Ζωγραφική",
    "gui.costumeTab.addSurpriseCostume": "Έκπληξη",
    "gui.costumeTab.addFileBackdrop": "Μεταφόρτωση Υποβάθρου",
    "gui.costumeTab.addFileCostume": "Μεταφόρτωση Ενδυμασίας",
    "gui.costumeTab.addCameraCostume": "Κάμερα",
    "gui.soundEditor.trim": "Περικοπή",
    "gui.soundEditor.stop": "Διακοπή",
    "gui.soundEditor.sound": "Ήχος",
    "gui.soundEditor.play": "Αναπαραγωγή",
    "gui.soundEditor.save": "Αποθήκευση",
    "gui.soundEditor.undo": "Αναίρεση",
    "gui.soundEditor.redo": "Επανάληψη",
    "gui.soundEditor.faster": "Γρηγορότερα",
    "gui.soundEditor.slower": "Πιο αργά",
    "gui.soundEditor.echo": "Ηχώ",
    "gui.soundEditor.robot": "Ρομπότ",
    "gui.soundEditor.louder": "Δυνατότερα",
    "gui.soundEditor.softer": "Απαλότερα",
    "gui.soundEditor.reverse": "Αντιστροφή",
    "gui.soundTab.recordSound": "Ηχογράφηση",
    "gui.soundTab.addSoundFromLibrary": "Διάλεξε Ήχο",
    "gui.soundTab.surpriseSound": "Έκπληξη",
    "gui.soundTab.fileUploadSound": "Μεταφόρτωση Ήχου",
    "gui.controls.stop": "Σταμάτα",
    "gui.controls.go": "Ξεκίνα",
    "gui.stageHeader.stageSizeUnFull": "Έξοδος από προβολή πλήρους οθόνης",
    "gui.SpriteInfo.show": "Προβολή",
    "gui.SpriteInfo.size": "Μέγεθος",
    "gui.directionPicker.rotationStyles.allAround": "Πλήρης Περιστροφή",
    "gui.directionPicker.rotationStyles.leftRight": "Αριστερά/Δεξιά",
    "gui.directionPicker.rotationStyles.dontRotate": "Χωρίς περιστροφή",
    "gui.spriteSelectorItem.contextMenuDuplicate": "διπλασιασμός",
    "gui.spriteSelectorItem.contextMenuDelete": "διαγραφή",
    "gui.spriteSelectorItem.contextMenuExport": "εξαγωγή",
    "gui.spriteSelector.addSpriteFromPaint": "Ζωγραφική",
    "gui.spriteSelector.addSpriteFromSurprise": "Έκπληξη",
    "gui.spriteSelector.addSpriteFromFile": "Μεταφόρτωση Αντικείμενου",
    "gui.stageSelector.stage": "Σκηνή",
    "gui.stageSelector.backdrops": "Yπόβαθρα",
    "gui.stageSelector.addBackdropFromPaint": "Ζωγραφική",
    "gui.stageSelector.addBackdropFromSurprise": "Έκπληξη",
    "gui.stageSelector.addBackdropFromFile": "Μεταφόρτωση Υποβάθρου",
    "gui.modal.back": "Πίσω",
    "gui.library.filterPlaceholder": "Αναζήτηση",
    "gui.libraryTags.all": "Όλα",
    "gui.libraryTags.animals": "Ζώα",
    "gui.libraryTags.dance": "Χορός",
    "gui.libraryTags.effects": "Εφέ",
    "gui.libraryTags.fantasy": "Φαντασία",
    "gui.libraryTags.fashion": "Μόδα",
    "gui.libraryTags.food": "Τρόφιμα",
    "gui.libraryTags.indoors": "Εσωτερικοί χώροι",
    "gui.libraryTags.loops": "Λούπες",
    "gui.libraryTags.music": "Μουσική",
    "gui.libraryTags.notes": "Νότες",
    "gui.libraryTags.outdoors": "Εξωτερικοί Χώροι",
    "gui.libraryTags.patterns": "Μοτίβα",
    "gui.libraryTags.people": "Άνθρωποι",
    "gui.libraryTags.percussion": "Κρουστά",
    "gui.libraryTags.space": "Διάστημα",
    "gui.libraryTags.sports": "Αθλήματα",
    "gui.libraryTags.underwater": "Υποβρύχια",
    "gui.libraryTags.voice": "Φωνή",
    "gui.libraryTags.wacky": "Αστείοι ήχοι",
    "gui.libraryTags.animation": "Κινούμενα σχέδια",
    "gui.libraryTags.art": "Τέχνη",
    "gui.libraryTags.games": "Παιχνίδια",
    "gui.libraryTags.stories": "Ιστορίες",
    "gui.libraryTags.letters": "Γράμματα",
    "gui.soundLibrary.chooseASound": "Διάλεξε Ήχο",
    "gui.SpriteInfo.spritePlaceholder": "Όνομα",
    "gui.cards.more-things-to-try": "Περισσότερα πράγματα για να δοκιμάσετε!",
    "gui.cards.see-more": "Δείτε περισσότερα",
    "gui.cards.shrink": "Σύμπτυξη",
    "gui.cards.expand": "Ανάπτυξη",
    "gui.cards.close": "Κλείσιμο",
    "gui.loader.message1": "Δημιουργία εντολών...",
    "gui.loader.message2": "Φόρτωση αντικειμένων ...",
    "gui.loader.message3": "Φόρτωση ήχων ...",
    "gui.loader.message4": "Φόρτωση επεκτάσεων ...",
    "gui.loader.message5": "Υπερφόρτωση ...",
    "gui.loader.message6": "Μετάδοση νανο ...",
    "gui.loader.message7": "Αρχικοποίηση του Gobo...",
    "gui.loader.message8": "Προετοιμασία emoji...",
    "gui.loader.headline": "Φόρτωση Έργου",
    "gui.cameraModal.cameraModalTitle": "Λήψη φωτογραφίας",
    "gui.cameraModal.loadingCameraMessage": "Φόρτωση κάμερας...",
    "gui.cameraModal.permissionRequest": "Χρειαζόμαστε την άδεια σας για χρήση της κάμερας",
    "gui.cameraModal.retakePhoto": "Νέα Λήψη Φωτογραφίας",
    "gui.cameraModal.save": "Αποθήκευση",
    "gui.cameraModal.takePhoto": "Λήψη φωτογραφίας",
    "gui.cameraModal.loadingCaption": "Γίνεται φόρτωση...",
    "gui.cameraModal.enableCameraCaption": "Ενεργοποίηση κάμερας",
    "gui.recordModal.title": "Εγγραφή Ήχου",
    "gui.playbackStep.stopMsg": "Διακοπή",
    "gui.playbackStep.playMsg": "Αναπαραγωγή",
    "gui.playbackStep.loadingMsg": "Γίνεται φόρτωση...",
    "gui.playbackStep.saveMsg": "Αποθήκευση",
    "gui.playbackStep.reRecordMsg": "Επανάληψη εγγραφής",
    "gui.webglModal.label": "Ο φυλλομετρητής σας δεν υποστηρίζει WebGL",
    "gui.webglModal.webgllink": "δεν υποστηρίζει WebGL",
    "gui.prompt.cancel": "Ακύρωση",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Πρόσθεσε είσοδο δεδομένων",
    "gui.customProcedures.addAnInputBoolean": "Πρόσθεσε είσοδο δεδομένων",
    "gui.customProcedures.numberTextType": "αριθμός ή κείμενο",
    "gui.customProcedures.booleanType": "λογική",
    "gui.customProcedures.addALabel": "Πρόσθεσε ετικέτα",
    "gui.customProcedures.runWithoutScreenRefresh": "Εκτέλεση χωρίς ανανέωση οθόνης",
    "gui.customProcedures.cancel": "Ακύρωση",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Σύνδεσε τα έργα σου με τον πραγματικό κόσμο.",
    "gui.extension.music.name": "Μουσική",
    "gui.extension.music.description": "Παίξε όργανα και τύμπανα.",
    "gui.extension.pen.name": "Πένα",
    "gui.extension.pen.description": "Σχεδίασε με τα αντικείμενά σου.",
    "gui.gui.variableScopeOptionAllSprites": "Για όλα τα αντικείμενα",
    "gui.gui.variableScopeOptionSpriteOnly": "Μόνο για αυτό το αντικείμενο",
    "gui.gui.variablePromptAllSpritesMessage": "Η μεταβλητή αυτή είναι διαθέσιμη σε όλα τα αντικείμενα.",
    "gui.monitor.contextMenu.default": "κανονική προβολή κειμένου",
    "gui.monitor.contextMenu.large": "μεγάλη προβολή κειμένου",
    "gui.monitor.contextMenu.slider": "γραμμή κύλισης",
    "gui.monitor.contextMenu.import": "εισαγωγή",
    "gui.monitor.contextMenu.export": "εξαγωγή",
    "gui.opcodeLabels.direction": "κατεύθυνση",
    "gui.opcodeLabels.xposition": "θέση x",
    "gui.opcodeLabels.yposition": "θέση y",
    "gui.opcodeLabels.size": "μέγεθος",
    "gui.opcodeLabels.costumename": "όνομα ενδυμασίας",
    "gui.opcodeLabels.costumenumber": "αριθμός ενδυμασίας",
    "gui.opcodeLabels.backdropname": "όνομα υποβάθρου",
    "gui.opcodeLabels.backdropnumber": "αριθμός υποβάθρου",
    "gui.opcodeLabels.volume": "ένταση",
    "gui.opcodeLabels.tempo": "ρυθμός",
    "gui.opcodeLabels.answer": "απάντηση",
    "gui.opcodeLabels.loudness": "ένταση",
    "gui.opcodeLabels.year": "έτος",
    "gui.opcodeLabels.month": "μήνας",
    "gui.opcodeLabels.date": "ημερομηνία",
    "gui.opcodeLabels.dayofweek": "μέρα της εβδομάδας",
    "gui.opcodeLabels.hour": "ώρα",
    "gui.opcodeLabels.minute": "λεπτό",
    "gui.opcodeLabels.second": "δευτερόλεπτο",
    "gui.opcodeLabels.timer": "χρονόμετρο",
    "music.categoryName": "Μουσική",
    "translate.categoryName": "Μετάφρασε",
    "pen.categoryName": "Πένα",
    "pen.changeColorParam": "άλλαξε [COLOR_PARAM] πένας κατά [VALUE]",
    "pen.changeHue": "άλλαξε χρώμα πένας κατά [HUE]",
    "pen.changeShade": "άλλαξε σκιά πένας κατά [SHADE]",
    "pen.changeSize": "άλλαξε μέγεθος πένας κατά [SIZE]",
    "pen.clear": "καθάρισε όλα",
    "pen.colorMenu.brightness": "φωτεινότητα",
    "pen.colorMenu.color": "χρώμα",
    "pen.colorMenu.saturation": "κορεσμό",
    "pen.colorMenu.transparency": "διαφάνεια",
    "pen.penDown": "κατέβασε πένα",
    "pen.penUp": "σήκωσε πένα",
    "pen.setColor": "όρισε χρώμα πένας σε [COLOR]",
    "pen.setColorParam": "όρισε [COLOR_PARAM] πένας σε [VALUE]",
    "pen.setHue": "όρισε χρώμα πένας σε [HUE]",
    "pen.setShade": "όρισε σκιά πένας σε [SHADE]",
    "pen.setSize": "όρισε μέγεθος πένας σε [SIZE]",
    "pen.stamp": "σφραγίδα",
    "music.changeTempo": "άλλαξε ρυθμό κατά [TEMPO]",
    "music.drumBass": "(2) Μπάσο Τύμπανο",
    "music.drumBongo": "(13) Μπόνγκο",
    "music.drumCabasa": "(15) Καμπάσα",
    "music.drumClaves": "(9) Κλάβες",
    "music.drumClosedHiHat": "(6) Κλειστά Πιατίνια",
    "music.drumConga": "(14) Κόνγκα",
    "music.drumCowbell": "(11) Κουδούνα",
    "music.drumCrashCymbal": "(4) Κύμβαλο Crash",
    "music.drumCuica": "(18) Κουίκα",
    "music.drumGuiro": "(16) Γκουίρο",
    "music.drumHandClap": "(8) Παλαμάκια",
    "music.drumOpenHiHat": "(5) Ανοικτά Πιατίνια",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Ταμπούρο",
    "music.drumTambourine": "(7) Ντέφι",
    "music.drumTriangle": "(12) Τρίγωνο",
    "music.drumVibraslap": "(17) Βίμπρασλαπ",
    "music.drumWoodBlock": "(10) Ξύλινη Κασετίνα",
    "music.getTempo": "ρυθμός",
    "music.instrumentBass": "(6) Μπάσο",
    "music.instrumentBassoon": "(14) Φαγκότο",
    "music.instrumentCello": "(8) Τσέλο",
    "music.instrumentChoir": "(15) Χορωδία",
    "music.instrumentClarinet": "(10) Κλαρινέτο",
    "music.instrumentElectricGuitar": "(5) Ηλεκτρική κιθάρα",
    "music.instrumentElectricPiano": "(2) Ηλεκτρικό πιάνο",
    "music.instrumentFlute": "(12) Φλάουτο",
    "music.instrumentGuitar": "(4) Κιθάρα",
    "music.instrumentMarimba": "(19) Μαρίμπα",
    "music.instrumentMusicBox": "(17) Μουσικό Κουτί",
    "music.instrumentOrgan": "(3) Εκκλησιαστικό Όργανο",
    "music.instrumentPiano": "(1) Πιάνο",
    "music.instrumentPizzicato": "(7) Πιτσικάτο",
    "music.instrumentSaxophone": "(11) Σαξόφωνο",
    "music.instrumentSteelDrum": "(18) Μεταλλικό Τύμπανο",
    "music.instrumentSynthLead": "(20) Εισαγωγή Συνθεσάιζερ",
    "music.instrumentSynthPad": "(21) Πληκτρολόγιο Συνθεσάιζερ",
    "music.instrumentTrombone": "(9) Τρομπόνι",
    "music.instrumentVibraphone": "(16) Βιμπράφωνο",
    "music.instrumentWoodenFlute": "(13) Ξύλινο φλάουτο",
    "music.midiPlayDrumForBeats": "παίξε τύμπανο [DRUM] για [BEATS] χτύπους",
    "music.midiSetInstrument": "όρισε όργανο σε [INSTRUMENT]",
    "music.playDrumForBeats": "παίξε τύμπανο [DRUM] για [BEATS] χτύπους",
    "music.playNoteForBeats": "παίξε νότα [NOTE] για [BEATS] χτύπους",
    "music.restForBeats": "κάνε παύση για [BEATS] χτύπους",
    "music.setInstrument": "όρισε όργανο σε [INSTRUMENT]",
    "music.setTempo": "όρισε ρυθμό σε [TEMPO]",
    "videoSensing.categoryName": "Προβολή από Κάμερα",
    "videoSensing.direction": "κατεύθυνση",
    "videoSensing.motion": "κίνηση",
    "videoSensing.off": "απενεργοποιημένο",
    "videoSensing.on": "ενεργοποιημένο",
    "videoSensing.onFlipped": "αναστραμμένο",
    "videoSensing.setVideoTransparency": "όρισε διαφάνεια βίντεο σε [TRANSPARENCY]",
    "videoSensing.sprite": "αντικείμενο",
    "videoSensing.stage": "σκηνή",
    "videoSensing.videoOn": "βίντεο [ATTRIBUTE] για [SUBJECT]",
    "videoSensing.videoToggle": "βίντεο [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "όταν κίνηση βίντεο > [REFERENCE]",
    "translate.translateBlock": "μετάφρασε [WORDS] σε [LANGUAGE]",
    "translate.defaultTextToTranslate": "γεια",
    "translate.viewerLanguage": "γλώσσα",
    "text2speech.speakAndWaitBlock": "μίλησε [WORDS]",
    "text2speech.setVoiceBlock": "όρισε φωνή σε [VOICE]",
    "text2speech.setLanguageBlock": "όρισε γλώσσα σε [LANGUAGE]",
    "text2speech.alto": "άλτο",
    "text2speech.tenor": "τενόρος",
    "text2speech.squeak": "στριγκλιά ",
    "text2speech.giant": "γίγαντας",
    "text2speech.kitten": "γατάκι",
    "text2speech.defaultTextToSpeak": "γεια",
    "speech.whenIHear": "όταν ακούσω [PHRASE]",
    "speech.listenAndWait": "άκουσε και περίμενε",
    "speech.defaultWhenIHearValue": "let's go",
    "sensor.categoryName": "Αισθητήρες",
    "actuator.categoryName": "Actuador",
    "communicate.categoryName": "Επικοινωνία",
    "display.categoryName": "Οθόνη",
    "extendFunction.categoryName": "Συνάρτηση",
    "internetService.categoryName": "Internet",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "handpy",
    "spread.categoryName": "Πλακέτες επέκτασης",
    "speechRecognition.categoryName": "Αναγνώριση Ομιλίας",
    "thirdExpansion.categoryName": "Επεκτάσεις χρήστη",
    "gui.blocklyText.blockTitle.eventBlock": "event program",
    "gui.blocklyText.blockTitle.dotScreen": "Οθόνη κουκίδων",
    "gui.blocklyText.blockTitle.music": "Μουσική",
    "gui.blocklyText.blockTitle.onBoardSensor": "Αισθητήρες πλακέτας",
    "gui.blocklyText.blockTitle.pinOperation": "Λειτουργίες ακροδεκτών",
    "gui.blocklyText.blockTitle.wirelessCommunication": "Ασύρματη Επικοινωνία",
    "gui.blocklyText.blockTitle.serialportOperation": "Σειριακή σύνδεση",
    "gui.blocklyText.blockTitle.systemResource": "system resource",
    "gui.blocklyText.blockTitle.screenDisplay": "screen display",
    "gui.blocklyText.blockTitle.ledControl": "Έλεγχος LED",
    "gui.blocklyText.blockTitle.functionalModule": "functional module",
    "gui.blocklyText.blockTitle.movement": "Κινήσεις",
    "gui.blocklyText.blockTitle.expression": "εκφράσεις",
    "gui.blocklyText.blockTitle.light": "φως",
    "gui.blocklyText.blockTitle.dance": "χωρός",
    "gui.blocklyText.blockTitle.sensor": "αισθητήρας",
    "gui.blocklyText.blockTitle.motorControl": "Έλεγχος κινητήρων",
    "gui.blocklyText.blockTitle.kitSensor": "kit sensor",
    "gui.blocklyText.blockTitle.kitActuator": "kit actuator",
    "gui.blocklyText.blockTitle.commonModule": "common module",
    "gui.blocklyText.blockTitle.infraredCommunication": "Ασύρματη Επικοινωνία",
    "gui.blocklyText.blockTitle.mp3Module": "mp3 επέκταση",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 RGB light",
    "gui.blocklyText.blockTitle.LCDDisplay": "LCD οθόνη",
    "gui.blocklyText.blockTitle.digitalTube": "digital tube",
    "gui.blocklyText.blockTitle.oledDisplay": "OLED οθόνη",
    "gui.blocklyText.blockTitle.onBoardSource": "board resource",
    "gui.blocklyText.blockTitle.rgbControl": "RGB light control",
    "gui.blocklyText.blockTitle.maxbotResource": "max:bot resource",
    "gui.blocklyText.blockTitle.lineInspection": "αναγνώριση γραμμής",
    "gui.blocklyText.blockTitle.outerSensor": "εξωτερικός αισθητήρας",
    "gui.blocklyText.blockTitle.consolePrint": "έξοδος κοσνόλας",
    "gui.blocklyText.romeo.d1ProgramStart": "D1 robot program starts",
    "gui.blocklyText.romeo.readdigital": "read value pin[PIN] [MODULE] ",
    "gui.blocklyText.romeo.buttonModule": "Button Module",
    "gui.blocklyText.romeo.digitalSensor": "Ψηφιακός Αισθητήρας",
    "gui.blocklyText.romeo.collisionSensor": "Αισθητήρας κρούσης",
    "gui.blocklyText.romeo.lineSensor": "Αισθητήρας Γραμμής",
    "gui.blocklyText.romeo.infraredSwitch": "Infrared Switch",
    "gui.blocklyText.romeo.vibrationSensor": "Αισθητήρας Δόνησης",
    "gui.blocklyText.romeo.readAnalog": "read pin [PIN] [SENSOR] ",
    "gui.blocklyText.romeo.lightSensor": "Light Sensor",
    "gui.blocklyText.romeo.anologSensor": "Anolog Sensor",
    "gui.blocklyText.romeo.soundSensor": "Sound Sensor",
    "gui.blocklyText.romeo.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.romeo.frameSensor": "Frame Sensor",
    "gui.blocklyText.romeo.open": "Turn on",
    "gui.blocklyText.romeo.close": "Turn off",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] of pin [PIN] ",
    "gui.blocklyText.romeo.ledLights": "LED Lights",
    "gui.blocklyText.romeo.digitalActuator": "Digital Actuator",
    "gui.blocklyText.romeo.relay": "Relay",
    "gui.blocklyText.romeo.fan": "Fan",
    "gui.blocklyText.romeo.writeAnalog": "set pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.romeo.fanModule": "Fan Module",
    "gui.blocklyText.romeo.robotSpeedMove": "robot [DIR] at speed[SPEED]",
    "gui.blocklyText.romeo.forward": "move forward",
    "gui.blocklyText.romeo.backward": "move backward",
    "gui.blocklyText.romeo.turnLeft": "turn left",
    "gui.blocklyText.romeo.turnRight": "turn right",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "motor [MOTOR] [DIR] at speed[SPEED]",
    "gui.blocklyText.romeo.interface": "Interface",
    "gui.blocklyText.romeo.motorForward": "Forward",
    "gui.blocklyText.romeo.motorReverse": "Backward",
    "gui.blocklyText.romeo.stopMotor": "robot (M1 and M2 motors) stop moving",
    "gui.blocklyText.romeo.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "read pin [PIN] LM35 temperature(°C)",
    "gui.blocklyText.romeo.ds18b20": "read pin [PIN] DS18B20 temperature(°C)",
    "gui.blocklyText.romeo.pressInfrared": "infrared pin [PIN] received button [BUTTON] pressed signal？",
    "gui.blocklyText.romeo.volumePlus": "Volume+",
    "gui.blocklyText.romeo.switch": "Switch",
    "gui.blocklyText.romeo.volumeMinus": "Volume-",
    "gui.blocklyText.romeo.startPause": "Start/Pause",
    "gui.blocklyText.romeo.up": "Up",
    "gui.blocklyText.romeo.down": "Down",
    "gui.blocklyText.romeo.dataReadable": "Is there data to read on serial port?",
    "gui.blocklyText.romeo.readSerialData": "read serial data",
    "gui.blocklyText.romeo.timer": "system uptime(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.romeo.half": "Half",
    "gui.blocklyText.romeo.quarter": "Quarter",
    "gui.blocklyText.romeo.oneInEighth": "One-eighth",
    "gui.blocklyText.romeo.wholeBeat": "Whole Beat",
    "gui.blocklyText.romeo.doubleBeat": "Double Beat",
    "gui.blocklyText.romeo.stop": "Stop",
    "gui.blocklyText.romeo.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.romeo.segment": "eight-segment LED (D6-D13 pin) display [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "serial output [STRING] [WAY], [LINE]",
    "gui.blocklyText.romeo.originalOutput": "origin",
    "gui.blocklyText.romeo.stringOutput": "string",
    "gui.blocklyText.romeo.hexOutput": "HEX",
    "gui.blocklyText.romeo.wrap": "Wrap",
    "gui.blocklyText.romeo.noWrap": "No-Wrap",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "read serial data [READTYPE]",
    "gui.blocklyText.romeo.int": "int",
    "gui.blocklyText.romeo.float": "float",
    "gui.blocklyText.romeo.setBaudRate": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "read pin [PIN] pulse width under [LEVEL] mode, timeout [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "LOW",
    "gui.blocklyText.romeo.high": "HIGH",
    "gui.blocklyText.romeo.noSound": "no sound",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino starts",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno starts",
    "gui.blocklyText.esp32.displayInLine": "display [TEXT] in the [LINE] line",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "clear cache in the [LINE] line",
    "gui.blocklyText.esp32.displayClearInLine": "clear in the [LINE] line",
    "gui.blocklyText.esp32.buzzPinC": "P6  (Onboard buzzer)",
    "gui.blocklyText.esp32.buzzerRedirect": "Buzzer redirected to pin [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "All",
    "gui.blocklyText.esp32.setTouchThreshold": "Set button [PIN] touch threshold value is [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "Read button [PIN] touch value",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 starts",
    "gui.blocklyText.esp32.btnIsPressed": "[BUTTON] button [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "when button [REFERENCE] [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "when pin [REFERENCE] is [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "When [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "pin [PIN] is [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "stop background playback",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "play note [NOTE] in background",
    "gui.blocklyText.esp32.setBuzzerFreq": "play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.esp32.getSoundIntensity": "read microphone sound intensity",
    "gui.blocklyText.esp32.readBrightness": "read ambient light brightness",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] show [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.esp32.acceleration": "read acceleration(mg) [TYPE]",
    "gui.blocklyText.esp32.displayInXY": "display [TEXT] at X: [X]  Y: [Y] axis, preview [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "screen display color [COLOR] (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "write cache screen color [COLORMPYTHON] (clear screen)",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": "all white",
    "gui.blocklyText.esp32.lineDrawing": "draw line from Point x1:[X1] y1:[Y1] to Point x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "connected",
    "gui.blocklyText.esp32.disconnected": "disconnected",
    "gui.blocklyText.esp32.rectangleDrawing": "draw rectangle [FILL] with top-left corner at x:[X] y:[Y], W:[WIDTH], H:[HEIGHT]",
    "gui.blocklyText.esp32.screenDisplay": "screen cache display takes effect",
    "gui.blocklyText.esp32.circleDrawing": "draw circle [FILL] with center point at x:[X] y:[Y], r:[RADIUS]",
    "gui.blocklyText.esp32.fill": "fill",
    "gui.blocklyText.esp32.notFill": "not fill",
    "gui.blocklyText.esp32.pointDrawing": "draw point at x:[X] y:[Y]",
    "gui.blocklyText.esp32.setLineWidth": "set line width to [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.esp32.analogWrite": "analog pin [PIN] output [VALUE]",
    "gui.blocklyText.esp32.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.esp32.serialPort0": "serial0",
    "gui.blocklyText.esp32.serialPort1": "serial1",
    "gui.blocklyText.esp32.serialPort2": "serial2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX] the baud rate [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "screen display image [IMAGE] at x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "write cache image [IMAGE] at x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "pressed",
    "gui.blocklyText.esp32.loosened": "released",
    "gui.blocklyText.esp32.all": "ALL(-1)",
    "gui.blocklyText.esp32.setBaud": "serialport0 baud rate [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.esp32.setBrightness": "set led brightness [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "get led lightness",
    "gui.blocklyText.esp32.print": "print [DATA]",
    "gui.blocklyText.esp32.playMusicNow": "play music [PLAY]",
    "gui.blocklyText.esp32.TiltForward": "Tilt forward",
    "gui.blocklyText.esp32.TiltBack": "Tilt back",
    "gui.blocklyText.esp32.TiltLeft": "Tilt left",
    "gui.blocklyText.esp32.TiltRight": "Tilt right",
    "gui.blocklyText.esp32.ScreenUp": "Screen up",
    "gui.blocklyText.esp32.ScreenDown": "Face down",
    "gui.blocklyText.esp32.Shake": "Shake",
    "gui.blocklyText.esp32.isGesture": "is [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "Disable [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "All",
    "gui.blocklyText.esp32.rgbdisable.No.0": "No.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "No.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "No.2",
    "gui.blocklyText.esp32.playMusic": "Play music [MUSIC] repeating [REPEAT]",
    "gui.blocklyText.esp32.Once": "Once",
    "gui.blocklyText.esp32.Forever": "Forever",
    "gui.blocklyText.esp32.OnceInBackground": "Once in background",
    "gui.blocklyText.esp32.ForeverInBackground": "Forever in background",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 starts",
    "gui.blocklyText.mega2560.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.mega2560.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "pwm pin [PIN] output [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.mega2560.level.low": "LOW",
    "gui.blocklyText.mega2560.level.high": "HIGH",
    "gui.blocklyText.mega2560.irqRead": "read digital pin [PIN] of Infrared Receiver",
    "gui.blocklyText.mega2560.ultrasonicRead": "read ulrasonic sensor trig [TRIG], echo [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "pin [PIN] play buzzer tone [TONE] for [BEAT] beat",
    "gui.blocklyText.mega2560.harf": "Harf",
    "gui.blocklyText.mega2560.quarter": "Quarter",
    "gui.blocklyText.mega2560.oneInEighth": "One-eighth",
    "gui.blocklyText.mega2560.wholeBeat": "Whole Beat",
    "gui.blocklyText.mega2560.doubleBeat": "Double Beat",
    "gui.blocklyText.mega2560.stop": "Stop",
    "gui.blocklyText.mega2560.servoSet": "Set pin [PIN] servo to [DEGREE] degrees",
    "gui.blocklyText.mega2560.setBaud": "set [SERIAL] baud rate to [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "serial0",
    "gui.blocklyText.mega2560.serialPort1": "serial1",
    "gui.blocklyText.mega2560.serialPort2": "serial2",
    "gui.blocklyText.mega2560.serialPort3": "serial3",
    "gui.blocklyText.mega2560.dataReadable": "Is there data to read on [SERIAL] ?",
    "gui.blocklyText.mega2560.readSerialData": "read data on [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "in string",
    "gui.blocklyText.mega2560.originalOutput": "original output",
    "gui.blocklyText.mega2560.hexOutput": "in HEX",
    "gui.blocklyText.mega2560.wrap": "Wrap",
    "gui.blocklyText.mega2560.noWrap": "No-Wrap",
    "gui.blocklyText.mega2560.readPinLevel": "read pin [PIN] pulse width under [LEVEL] mode, timeout [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "system uptime(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini starts",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo starts",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 starts",
    "gui.blocklyText.arduino.setDigital": "digital pin [PIN] ouput [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "read digital pin [PIN]",
    "gui.blocklyText.arduino.readSimilation": "read analog pin [PIN]",
    "gui.blocklyText.arduino.setPwm": "pwm pin [PIN] output [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat ",
    "gui.blocklyText.arduino.serialSendStr": "serial output [STRING] [WAY], [LINE]",
    "gui.blocklyText.arduino.originalOutput": "in origin",
    "gui.blocklyText.arduino.stringOutput": "in string",
    "gui.blocklyText.arduino.hexOutput": "in HEX",
    "gui.blocklyText.arduino.wrap": "Wrap",
    "gui.blocklyText.arduino.noWrap": "No-Wrap",
    "gui.blocklyText.arduino.readInfrared": "read digital pin [PIN] of infrared Receiver",
    "gui.blocklyText.arduino.setBaud": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "read pin [PIN] pulse width pattern [LEVEL] timeout [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "no sound",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Arduino Nano starts",
    "gui.blocklyText.vortex.vortexStart": "Vortex robot program starts",
    "gui.blocklyText.vortex.vortexMoveSpeed": "vortex robot [DIRECTION] at speed[SPEED] ",
    "gui.blocklyText.vortex.forward": "move forward",
    "gui.blocklyText.vortex.backup": "move backward",
    "gui.blocklyText.vortex.turnLeft": "turn left",
    "gui.blocklyText.vortex.turnRight": "turn right",
    "gui.blocklyText.vortex.setMotor": "motor [MOTOR] [DIRECTION] at speed[SPEED]  ",
    "gui.blocklyText.vortex.leftWheel": "left",
    "gui.blocklyText.vortex.rightWheel": "right",
    "gui.blocklyText.vortex.setExpression": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.vortex.ledLights": "[DIRECTION][LIGHT] change to [COLOR] in [SECOND]secs.",
    "gui.blocklyText.vortex.all": "all",
    "gui.blocklyText.vortex.rightAhead": "front",
    "gui.blocklyText.vortex.leftRear": "left rear",
    "gui.blocklyText.vortex.rightRear": "right rear",
    "gui.blocklyText.vortex.rightAstern": "rear",
    "gui.blocklyText.vortex.topLights": "top lights",
    "gui.blocklyText.vortex.bottomLights": "bottom lights",
    "gui.blocklyText.vortex.vortexStop": "Vortex stops moving ",
    "gui.blocklyText.vortex.setVolume": "set volume to [VALUE]",
    "gui.blocklyText.vortex.playMusic": "play the [VALUE] music",
    "gui.blocklyText.vortex.stopMusic": "stop playing music",
    "gui.blocklyText.vortex.startDance": "start the [VALUE] dance",
    "gui.blocklyText.vortex.stopDance": "stop dancing",
    "gui.blocklyText.vortex.barrierDetection": "obstacle ahead?",
    "gui.blocklyText.vortex.readSensor": "read Grayscale Sensor [SENSOR]",
    "gui.blocklyText.vortex.setSensor": "set grayscale sensors sensitivity to [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "grayscale sensor meets black on its [SENSOR] position?",
    "gui.blocklyText.vortex.positiveFrontLeft": "straight left front",
    "gui.blocklyText.vortex.frontLeft": "left front",
    "gui.blocklyText.vortex.positiveFrontRight": "straight right front",
    "gui.blocklyText.vortex.frontRight": "right front",
    "gui.blocklyText.vortex.backRight": "right rear",
    "gui.blocklyText.vortex.backLeft": "left rear",
    "gui.blocklyText.vortex.initialOldPCB": "initialize to old version (blue PCB)",
    "gui.blocklyText.microbit.microbitStarts": "ξεκίνησε micro:bit ",
    "gui.blocklyText.microbit.interruptExcute": "interrupt pin [PIN] mode [MODE] excute",
    "gui.blocklyText.microbit.cancleInterrupt": "ακύρωσε interrupt pin [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "Όταν λαμβάνονται ασύρματα δεδομένα",
    "gui.blocklyText.microbit.whenBtnPress": "Όταν πατηθεί το κουμπί [REFERENCE]",
    "gui.blocklyText.microbit.whenPinConnected": "όταν συνδεθεί ο ακροδέκτης [REFERENCE]",
    "gui.blocklyText.microbit.whenPosChange": "όταν [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "εμφάνισε σχέδιο [PIC]",
    "gui.blocklyText.microbit.showInput": "εμφάνισε [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "σταμάτα βίντεο",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] συντεταγμένες σημείου (x:[XAXIS], y:[YXAXIS]）",
    "gui.blocklyText.microbit.hideAllLights": "καθάρισε όλη την οθόνη",
    "gui.blocklyText.microbit.microbitEnableLight": "Πλέγμα οθόνης [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "φωτεινότητα",
    "gui.blocklyText.microbit.showLightWithBrightness": "Ρύθμιση φωτεινότητας [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "εμφάνισε συντεταγμένες σημείου (x:[XAXIS], y:[YXAXIS]), φωτεινότητα [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "ακροδέκτης [PIN] παίξε ήχο [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "ακροδέκτης [PIN] παίξε ήχο [SOUND] έως τέλος",
    "gui.blocklyText.microbit.playNote": "ακροδέκτης [PIN] παίξε νότα [NOTE] με ρυθμό [BEAT]",
    "gui.blocklyText.microbit.true": "ενεργοποίησε",
    "gui.blocklyText.microbit.false": "απενεργοποίησε",
    "gui.blocklyText.maqueen.playSound": "ακροδέκτης P0 παίξε ήχο [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "ακροδέκτης P0 παίξε ήχο [SOUND] μέχρι κάτω",
    "gui.blocklyText.maqueen.playNote": "ακροδέκτης P0 παίξε ήχο [NOTE] με ρυθμό [BEAT]",
    "gui.blocklyText.maqueen.RGB0": "RGB0  (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1  (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2  (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3  (3)",
    "gui.blocklyText.maqueen.All": "Όλα  (-1)",
    "gui.blocklyText.microbit.changeTempo": "άλλαξε ρυθμό (bpm) κατά [VALUE]",
    "gui.blocklyText.microbit.setTempo": "όρισε ρυθμό (bpm) σε [VALUE]",
    "gui.blocklyText.microbit.getTempo": "ρυθμός (bpm)",
    "gui.blocklyText.microbit.stopSound": "σταμάτησε το ήχο του παρασκηνίου",
    "gui.blocklyText.microbit.btnIsPressed": "Πατήθηκε το κουμπί [BUTTON] ;",
    "gui.blocklyText.microbit.pinIsConnected": "ακροδέκτης [PIN] συνδέθηκε;",
    "gui.blocklyText.microbit.isMove": "τρέχουσα κατάσταση [TYPE]?",
    "gui.blocklyText.microbit.compass": "διάβασε πυξίδα",
    "gui.blocklyText.microbit.temperature": "διάβασε θερμοκρασία",
    "gui.blocklyText.microbit.digitalWrite": "θέσε ακροδέκτη [PIN] σε [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "διάβασε ψηφιακό ακροδέκτη [PIN]",
    "gui.blocklyText.microbit.analogWrite": "θέσε αναλογικό ακροδέκτη [PIN] σε [VALUE]",
    "gui.blocklyText.microbit.analogRead": "διάβασε αναλογικό ακροδέκτη [PIN]",
    "gui.blocklyText.microbit.up": "logo up",
    "gui.blocklyText.microbit.down": "logo down",
    "gui.blocklyText.microbit.left": "μετακινήθηκε αριστερά",
    "gui.blocklyText.microbit.right": "μετακινήθηκε δεξιά",
    "gui.blocklyText.microbit.faceUp": "πρόσωπο προς τα πάνω",
    "gui.blocklyText.microbit.faceDown": "πρόσωπο προς τα κάτω",
    "gui.blocklyText.microbit.freefall": "ελεύθερη πτώση",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "κούνα",
    "gui.blocklyText.microbit.show": "δείξε",
    "gui.blocklyText.microbit.hide": "κρύψε",
    "gui.blocklyText.microbit.low": "LOW",
    "gui.blocklyText.microbit.high": "HIGH",
    "gui.blocklyText.microbit.microbitReadBrightness": "διάβασε το περιβαλλοντικό φως",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] ασύρματη επικοινωνία",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "θέσε το κανάλι της ασύρματης επικοινωνίας σε [NUM] ",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "στείλε κείμενο [TEXT] ασύρματα",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "παρέλαβε δεδομένα ασύρματα",
    "gui.blocklyText.microbit.strength": "ένταση",
    "gui.blocklyText.microbit.acceleration": "διάβασε επιτάχυνση (mg)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "διάβασε μαγνητική τιμή (µT)[TYPE]",
    "gui.blocklyText.microbit.print": "Εκτύπωσε [DATA]",
    "gui.blocklyText.microbit.goUp": "πάνω",
    "gui.blocklyText.microbit.goDown": "κάτω",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "όταν ληφθούν ασύρματα δεδομένα [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "ασύρματα δεδομένα",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot Starts",
    "gui.blocklyText.maxbot.playSound": "play sound [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "play sound [SOUND] until done",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "collision occurred on the [LEFTRIGHT] ?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "black line detected on the [LEFTRIGHT]  ?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "motor [MOTOR] [DIR] at speed [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "read (P1,P2) ulrasonic sensor [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "read ulrasonic sensor Unit [UNIT], trig [TRIG], echo [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "FORWARD",
    "gui.blocklyText.maxbot.BACKWARD": "BACKWARD",
    "gui.blocklyText.motorbit.CW": "CW",
    "gui.blocklyText.motorbit.CCW": "CCW",
    "gui.blocklyText.maxbot.CW": "CW",
    "gui.blocklyText.maxbot.CCW": "CCW",
    "gui.blocklyText.maxbot.ANGLE": "degrees",
    "gui.blocklyText.maxbot.CIRCLE": "revolutions",
    "gui.blocklyText.maxbot.Left": "left",
    "gui.blocklyText.maxbot.Right": "right",
    "gui.blocklyText.maxbot.cm": "cm",
    "gui.blocklyText.maxbot.inch": "inch",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit Starts",
    "gui.blocklyText.MotorBit.MotorRun": "motor [MOTOR] rotate [DIR] at speed [SPEED]",
    "gui.blocklyText.MotorBit.MotorStop": "motor [MOTOR] stops",
    "gui.blocklyText.MotorBit.Stepper": "stepper [STEPPER] rotate [ROTATEDIR] for [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "ALL",
    "gui.blocklyText.maqueen.maqueenStarts": "Αρχή Maqueen",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "Διάβασε αισθητήρα απόστασης (P1,P2) ",
    "gui.blocklyText.maqueen.motorStop": "Σταμάτα τους κινητήρες",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] LED",
    "gui.blocklyText.maqueen.redLineSensor": "διάβασε [LEFTRIGHT] αισθητήρες γραμμής",
    "gui.blocklyText.maqueen.robotSpeedMove": "ταχύτητα ρομπότ [DIR] σε [SPEED]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "κινητήρας [LEFTRIGHT] κινήσου [DIR] με ταχύτητα [SPEED]",
    "gui.blocklyText.maqueen.ledLeft": "Αριστερά(P8)",
    "gui.blocklyText.maqueen.ledRight": "Δεξιά(P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "Δεξιά(P12) (not available)",
    "gui.blocklyText.maqueen.lineSensorLeft": "Αριστερά(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "Δεξιά(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "όταν (P16) γίνει λήψη του [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "Ασύρματο σήμα",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "διάβασε(P16) ασύρματο σήμα",
    "gui.blocklyText.maqueen.numberOfLightsBright": "ακροδέκτης P15 4 RGB LEDs φωτεινότητα [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "ακροδέκτης P15 [NUM1] χρώμα LED  [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "ακροδέκτης P15 LED [NUM1] έως [NUM2] εμφάνισε χρώμα από [COLOR1] σε [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "ακροδέκτης P15 μετακίνησε pixel [NUM]",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "ακροδέκτης P15 περιέστρεψε pixels κατά [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "ακροδέκτης  P15 ένταση LED  [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "ακροδέκτης P15 LED [NUM1] to [NUM2] εμφάνισε κηματομορφή, τιμή [VALUE], μέγιστο [MAX]",
    "gui.blocklyText.maqueen.clear": "ακροδέκτης P15 καθάρισε όλα τα LEDs",
    "gui.blocklyText.maqueen.rgbColor": "κόκκινο [RED] πράσινο [GREEN] μπλε [BLUE]",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] παραλαβή δεδομένων;",
    "gui.blocklyText.obloq.initialParameter": "obloq mqtt initialization: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq read the message from [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq read the message",
    "gui.blocklyText.obloq.httpInitialParameter": "obloq http initialization: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "add subscribe [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "Wi-Fi name",
    "gui.blocklyText.obloq.password": "password",
    "gui.blocklyText.obloq.iotService": "IOT Service",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "IP Address",
    "gui.blocklyText.obloq.port": "Port",
    "gui.blocklyText.obloq.addTopic": "Add Topic, only add up to 4",
    "gui.blocklyText.obloq.deleteTopic": "Delete Topic",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "Server",
    "gui.blocklyText.obloq.global": "Global",
    "gui.blocklyText.obloq.china": "China",
    "gui.blocklyText.obloq.softwareSerial": "Software Serial",
    "gui.blocklyText.obloq.hardwareSerial": "Hardware Serial",
    "gui.blocklyText.obloq.hardwareSerial1": "Hardware Serial1",
    "gui.blocklyText.obloq.hardwareSerial2": "Hardware Serial2",
    "gui.blocklyText.obloq.hardwareSerial3": "Hardware Serial3",
    "gui.blocklyText.obloq.WhenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq message",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq get [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Debug info",
    "gui.blocklyText.obloq.ERROR": "Error code",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.oled2864.displayInit": "OLED display init address [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 display [TEXT] in line [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 display [TEXT] at position X: [X] Y*16: [Y]",
    "gui.blocklyText.oled2864.screenRotation": "rotate screen to [ROTATION] degrees",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 clear",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "read pin [PIN] [DHT] [TYPE]",
    "gui.blocklyText.sen0170.readWindspeed": "read pin [PIN] wind scale",
    "gui.blocklyText.sen0228.init": "Initialize ambient light (VEML7700) sensor",
    "gui.blocklyText.sen0228.readAmbientLight": "read Ambient Light(VEML7700)",
    "gui.blocklyText.dfr0026.readAmbientLight": "read pin [PIN] Ambient light",
    "gui.blocklyText.sen0177.readDustSensor": "read [TYPE] (ug/m3) interface [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "pin(D) [PIN] pin(MCLR) [RST] current gesture [TYPE]?",
    "gui.blocklyText.sen0203.readHeartRate": "read pin [PIN] [TYPE] switch to [SWITCH]",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] pin [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "read pin [PIN] grayscale",
    "gui.blocklyText.motucamera.initIICSerial": "mu camera init [MU] interface [IICSERIAL] until init success",
    "gui.blocklyText.motucamera.resetConfig": "mu camera [MU] reset to default",
    "gui.blocklyText.motucamera.algorithmSwitch": "mu camera [MU] [SWITCH] algorithm [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "mu camera [MU] [LED] when detect [COLOR1] when undetect [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "mu camera [MU] set algorithm [ALGORITHM] level [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "mu camera [MU] digital zoom [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "mu camera [MU] set baudrate [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "mu camera [MU] set white balance [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "mu camera [MU] high FPS mode [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "mu camera [MU] is detect [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": "mu camera [MU] is detect color recogniza x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "mu camera [MU] is detect color detect color = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "mu camera get [MU] alogrithm [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "mu camera [MU] color recognize [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "mu camera [MU] shape card [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "mu camera [MU] traffic card [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "mu camera [MU] number card [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "mu camera [MU] color recognize color = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "Color Detect",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "Color Recognize",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "Ball Detect",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "Body Detect",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "Shape Card",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "Traffic Card",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "Number Card",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "enable",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "disable",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "white",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "white",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "Auto",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "Speed",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "Balance",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "Accuracy",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "Auto",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "Lock",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "white",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "yellow",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "Auto",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "Level1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "Level2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "Level3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "Level4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "Level5",
    "gui.blocklyText.motucamera.setHighFPSOff": "OFF",
    "gui.blocklyText.motucamera.setHighFPSOn": "ON",
    "gui.blocklyText.motucamera.colorDetectBlack": "black",
    "gui.blocklyText.motucamera.colorDetectWhite": "white",
    "gui.blocklyText.motucamera.colorDetectRed": "red",
    "gui.blocklyText.motucamera.colorDetectYellow": "yellow",
    "gui.blocklyText.motucamera.colorDetectGreem": "green",
    "gui.blocklyText.motucamera.colorDetectCayan": "cayan",
    "gui.blocklyText.motucamera.colorDetectBlue": "blue",
    "gui.blocklyText.motucamera.colorDetectPerple": "perple",
    "gui.blocklyText.motucamera.colorDetectOthers": "others",
    "gui.blocklyText.motucamera.colorDetectPositionX": "x position",
    "gui.blocklyText.motucamera.colorDetectPositionY": "y position",
    "gui.blocklyText.motucamera.colorDetectPositionW": "width",
    "gui.blocklyText.motucamera.colorDetectPositionH": "height",
    "gui.blocklyText.motucamera.colorDetectPositionL": "label",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "red channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "green channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "blue channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "label channel",
    "gui.blocklyText.motucamera.shapeCardTick": "tick",
    "gui.blocklyText.motucamera.shapeCardCross": "cross",
    "gui.blocklyText.motucamera.shapeCardCircle": "circle",
    "gui.blocklyText.motucamera.shapeCardSquare": "square",
    "gui.blocklyText.motucamera.shapeCardTriangle": "triangle",
    "gui.blocklyText.motucamera.trafficCardForward": "Forward",
    "gui.blocklyText.motucamera.trafficCardLeft": "Left",
    "gui.blocklyText.motucamera.trafficCardRight": "Right",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "Turn Around",
    "gui.blocklyText.motucamera.trafficCardStop": "Stop",
    "gui.blocklyText.motucamera.lightSensorUse": "mu camera [MU] light sensor enable [LIGHTFUNC] detection",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "mu camera [MU] light sensor sensitivity [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "mu camera [MU] light sensor read proximity detection",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "mu camera [MU] light sensor read ambient light detection",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "mu camera [MU] light sensor read once gesture and saved",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "mu camera [MU] light sensor gesture =  [GESTURE]",
    "gui.blocklyText.motucamera.upward": "upward",
    "gui.blocklyText.motucamera.downward": "downward",
    "gui.blocklyText.motucamera.leftward": "leftward",
    "gui.blocklyText.motucamera.rightward": "rightward",
    "gui.blocklyText.motucamera.pull": "pull",
    "gui.blocklyText.motucamera.push": "push",
    "gui.blocklyText.motucamera.none": "none",
    "gui.blocklyText.motucamera.default": "default",
    "gui.blocklyText.motucamera.low": "low",
    "gui.blocklyText.motucamera.middle": "middle",
    "gui.blocklyText.motucamera.high": "high",
    "gui.blocklyText.motucamera.proximity": "proximity",
    "gui.blocklyText.motucamera.ambientLight": "ambient light",
    "gui.blocklyText.motucamera.gesture": "gesture",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi initialize port [SERIALPORT] RX[RX] TX[TX] UART baudrate [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi set ssid [SSID] password [PASSWORD] mode [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi conection succeeded？",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi disconnect",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi set target IP [IP] port [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi read target IP",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi read local IP",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi read",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi Write [WRITE]",
    "gui.blocklyText.dfr0552.init": "DA module init I2C address [ADDR] voltage reference [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "DA module output triangular wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "DA module output sine wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "DA module output voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "DA module memory voltage [VOLTAGE]mV",
    "gui.blocklyText.sen0117.init": "Initialize module interface [SERIALPORT] Rx[RX] Tx[TX] Busy[BUSY]",
    "gui.blocklyText.sen0117.playText": "Plays text [TEXT] background music [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "Plays voice prompt [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "Plays chord prompts [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "Set up the [INDEX] to [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "Setting the speech rate [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "Get the speech synthesis module [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "Prospects volume",
    "gui.blocklyText.sen0117.Background": "Background volume",
    "gui.blocklyText.sen0117.Speed": "Speed",
    "gui.blocklyText.sen0117.playChordCueTone": "Plays chord ring tones [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.No": "No (0)",
    "gui.blocklyText.sen0117.setPlayMode": "Set the playback mode to [MODE]",
    "gui.blocklyText.sen0117.pause": "Pause",
    "gui.blocklyText.sen0117.resume": "Resume",
    "gui.blocklyText.sen0117.stop": "Stop",
    "gui.blocklyText.sen0117.isIdle": "Play finish?",
    "gui.blocklyText.dfr0576.selectPort": "Select port [PORT]",
    "gui.blocklyText.dfr0576.begin": "Initialize the I2C cascade module address is [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680 initializes",
    "gui.blocklyText.sen0248.readBME680": "Get the BME680 sensor [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "Temperature (°C)",
    "gui.blocklyText.sen0248.Pressure": "Pressure (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Humidity (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0248.GasResistance": "Gas Resistance (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "initialize the I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Change I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Read ultrasonic sensor distance(cm)",
    "gui.blocklyText.sen0304.readTemperature": "Read ultrasonic sensor temperature(°C)",
    "gui.blocklyText.sen0307.readDistance": "Read [PIN] analog ultrasonic wave(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 initialization address [ADDR] mode [MODE]",
    "gui.blocklyText.sen0250.Step": "Step",
    "gui.blocklyText.sen0250.Measure": "Measure",
    "gui.blocklyText.sen0250.readAccelerated": "Get the [AXIS] axis Acceleration(g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 get step number",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "Gyro",
    "gui.blocklyText.sen0250.Acc": "Acceleration (g)",
    "gui.blocklyText.sen0224.init": "Initialize the LIS2DH sensor",
    "gui.blocklyText.sen0224.readAccelerated": "Read acceleration value of LIS2DH sensor [AXIS] axis (mg)",
    "gui.blocklyText.dfr0023.readLM35": "read pin [PIN] LM35 temprature(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "when pin [PIN] detected vibration",
    "gui.blocklyText.dfr0028.readTiltSensor": "pin [PIN] detected tilt?",
    "gui.blocklyText.dfr0029.readPress": "pin [PIN] button pressed?",
    "gui.blocklyText.dfr0030.readTouch": "pin [PIN] detected touch?",
    "gui.blocklyText.dfr0033.readMagnetic": "pin [PIN] detected magnet?",
    "gui.blocklyText.dfr0034.readSound": "read pin [PIN] Loudness",
    "gui.blocklyText.dfr0094.readIRReceived": "read pin [PIN] IR data",
    "gui.blocklyText.midi.midiInit": "Init MIDI module interface [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.midi.midiOutput": "MIDI output channel [CHANNEL] pitch [PITCH] speed [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "read pin [PIN] Carbon Monoxide (CO) sensor",
    "gui.blocklyText.dfr0051.readVoltage": "read pin [PIN] Voltage(V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "read pin [PIN] Piezo Disk Vibration Sensor",
    "gui.blocklyText.dfr0058.readRotation": "read pin [PIN] Rotation Sensor",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "read pin [PIN] JoyStick [AXIS] Axis",
    "gui.blocklyText.dfr0061.readJoyStickButton": "pin [PIN] JoyStick Button(Z) pressed?",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut init data pin [DATAPIN] RTC pin [RTCPIN] cls pin [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Digital tube display segment code 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Digital tube display number [NUM]",
    "gui.blocklyText.dfr0072.dfr0072Dot": "Digital tube display dot [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "true",
    "gui.blocklyText.dfr0072.dotFalse": "false",
    "gui.blocklyText.dfr0072.numOff": "off",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Read lithium battery [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "voltage(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "remainingBattery(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD module init I2C address [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD module read [PIN] voltage (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD module get aisle [AISLE] voltage difference",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 and 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 and 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 and 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 and 3",
    "gui.blocklyText.dfr0588.initPin": "Init temperature pin [TEM] humidity pin [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "get temperature unit [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "get humidity(%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "CELSIUS",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "FAHRENHEIT",
    "gui.blocklyText.dfr0076.readFlameSensor": "read pin [PIN] Flame Sensor",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom get [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom read text name [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom read number name [KEY]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom init i2c address [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom write text [VALUE] name [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom write number [VALUE] name [KEY]",
    "gui.blocklyText.dfr0117.text": "Text",
    "gui.blocklyText.dfr0117.number": "Number",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom delete name [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom serial print all names baud [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom name [KEY] exist?",
    "gui.blocklyText.dfr0117.eepromFormat": "i2c eeprom format",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom name [KEY] is [TYPE]?",
    "gui.blocklyText.dfr0117.nameCount": "Name count",
    "gui.blocklyText.dfr0117.memorySize": "Storage space(byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "Remaining storage space(byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": "read pin [PIN] Triple Axis Accelerometer [AXIS] Axis",
    "gui.blocklyText.sen0018.readInfraredMotion": "read pin [PIN] Digital Infrared motion sensor",
    "gui.blocklyText.sen0014.readDistance": "read pin [PIN] Sharp IR Distance Sensor(mm)",
    "gui.blocklyText.sen0114.readMoisture": "read pin [PIN] Soil Moisture Sensor",
    "gui.blocklyText.sen0121.readSteam": "read pin [PIN] Steam Sensor",
    "gui.blocklyText.sen0204.readLiquidLevel": "pin [PIN] liquid level reached a certain height ?",
    "gui.blocklyText.sen0203.heartRate": "heart rate(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "blood oxygen saturation(HbO2)",
    "gui.blocklyText.sen0202.up": "Up",
    "gui.blocklyText.sen0202.down": "Down",
    "gui.blocklyText.sen0202.left": "Left",
    "gui.blocklyText.sen0202.right": "Right",
    "gui.blocklyText.sen0202.cw": "CW",
    "gui.blocklyText.sen0202.ccw": "CCW",
    "gui.blocklyText.dhtTHSensor.temperature": "temperature(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "moisture(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "pin [PIN] RGB [NUM1] to [NUM2] show color [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "pin [PIN]  [NUM1] to [NUM2] RGBs show gradient color from [COLOR1] to [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "pin [PIN] shift pixels by [NUM]",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "pin [PIN] rotate pixels by [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "pin [PIN] [NUM] RGB LEDs",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "pin [PIN] brightness [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "pin [PIN] clear all LEDs",
    "gui.blocklyText.ws2812.showHistogram": "pin [PIN]  [NUM1] to [NUM2] RGB LEDs show histogram  current value: [VALUE]  max value: [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "pin [PIN] LED brightness [BRIGHTNESS]",
    "gui.blocklyText.dsTSensor.ds18b20": "Read [PIN] pin DS18B20 temperature(°C)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280 initializes",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "read [TYPE] BMP280 sensor",
    "gui.blocklyText.bmpTSensor.atmosPressure": "barometric(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "altitude(m)",
    "gui.blocklyText.bmpTSensor.temperature": "temperature(℃)",
    "gui.blocklyText.huskylens.init": "HuskyLens Initialize Pin [SETTING] until success",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens change [ALGORITHM] algorithm until succes",
    "gui.blocklyText.huskylens.requestFirst": "HuskyLens request once enter the result",
    "gui.blocklyText.huskylens.resultGetIsStudy": "HuskyLens get from result ID [IDNUM] have learned?",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "HuskyLens get from result ID [IDNUM] [BOXARROW] in picture?",
    "gui.blocklyText.huskylens.resultGetIDParameter": "HuskyLens get from result ID [IDNUM] [TYPE] parameter [PARAMETER]",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens get from result studyed ID",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens get from result [BOXARROW] total",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens get from result ID [IDNUM] [BOXARROW] total",
    "gui.blocklyText.huskylens.resultGetIDofWhichParameter": "HuskyLens get from result ID [IDNUM] [BOXNUM] [TYPE] parameter [PARAMETER]",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens get from result [BOXARROW] in picture",
    "gui.blocklyText.huskylens.resultGetNearCenter": "HuskyLens get from result near the center [TYPE] [PARAMETER2] parameter",
    "gui.blocklyText.huskylens.resultGetParameter": "HuskyLens get from result [IDNUM] [TYPE] parameter [PARAMETER2]",
    "gui.blocklyText.huskylens.box": "box",
    "gui.blocklyText.huskylens.arrow": "array",
    "gui.blocklyText.huskylens.faceRecognition": "Face recognition",
    "gui.blocklyText.huskylens.objectTracking": "Object tracking",
    "gui.blocklyText.huskylens.objectRecognition": "Object recognition",
    "gui.blocklyText.huskylens.lineTracking": "Line tracking",
    "gui.blocklyText.huskylens.colorRecognition": "Color recognition",
    "gui.blocklyText.huskylens.tagRecognition": "Tag recognition",
    "gui.blocklyText.huskylens.objectClassification": "Object classification",
    "gui.blocklyText.huskylens.ID": "ID",
    "gui.blocklyText.huskylens.x": "X coordinates",
    "gui.blocklyText.huskylens.y": "Y coordonates",
    "gui.blocklyText.huskylens.w": "Object width",
    "gui.blocklyText.huskylens.h": "Object height",
    "gui.blocklyText.huskylens.x1": "X1 StartPoint",
    "gui.blocklyText.huskylens.y1": "Y1 StartPoint",
    "gui.blocklyText.huskylens.x2": "X2 EndPoint",
    "gui.blocklyText.huskylens.y2": "Y2 EndPoint",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "Communication Method",
    "gui.blocklyText.huskylens.address": "Address",
    "gui.blocklyText.huskylens.sdaGreen": "SDA(Green)",
    "gui.blocklyText.huskylens.sclBlue": "SCL(Blue)",
    "gui.blocklyText.huskylens.rxGreen": "Rx(Green)",
    "gui.blocklyText.huskylens.txBlue": "Tx(Blue)",
    "gui.blocklyText.ps2.initPs2Handle": "init PS2 handle DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] setRunmble [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2 open runmble [RUMBLE] strength [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "PS2 reflush states",
    "gui.blocklyText.ps2.ps2ButtonState": "PS2 button [BUTTON] state is [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2 joystick [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "ON",
    "gui.blocklyText.ps2.OFF": "OFF",
    "gui.blocklyText.ps2.Triangle": "Triangle",
    "gui.blocklyText.ps2.Circle": "Circle",
    "gui.blocklyText.ps2.Cross": "Cross",
    "gui.blocklyText.ps2.Square": "Square",
    "gui.blocklyText.ps2.Left1": "Left 1",
    "gui.blocklyText.ps2.Left2": "Left 2",
    "gui.blocklyText.ps2.Left3": "Left 3",
    "gui.blocklyText.ps2.Right1": "Right 1",
    "gui.blocklyText.ps2.Right2": "Right 2",
    "gui.blocklyText.ps2.Right3": "Right 3",
    "gui.blocklyText.ps2.Up": "Up",
    "gui.blocklyText.ps2.Right": "Right",
    "gui.blocklyText.ps2.Down": "Down",
    "gui.blocklyText.ps2.Left": "Left",
    "gui.blocklyText.ps2.Select": "Select",
    "gui.blocklyText.ps2.Start": "Start",
    "gui.blocklyText.ps2.Hold": "Hold",
    "gui.blocklyText.ps2.Pressed": "Pressed",
    "gui.blocklyText.ps2.Released": "Released",
    "gui.blocklyText.ps2.CHANGE": "CHANGE",
    "gui.blocklyText.ps2.RightX": "Right X",
    "gui.blocklyText.ps2.RightY": "Right Y",
    "gui.blocklyText.ps2.LeftX": "Left X",
    "gui.blocklyText.ps2.LeftY": "Left Y",
    "gui.blocklyText.sen0236.bme280Init": "BME280 initializes",
    "gui.blocklyText.sen0236.readbme280Sensor": "Read BME280 sensor [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "Temperature(℃)",
    "gui.blocklyText.sen0236.Humidity": "Humidity(%rh)",
    "gui.blocklyText.sen0236.Altitude": "Altitude(m)",
    "gui.blocklyText.sen0236.Pressure": "Pressure(Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "initialize I2C LCD screen address [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "display [TEXT] in LCD line [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "display [TEXT] at LCD position X: [X] Y: [Y] ",
    "gui.blocklyText.LCD1602.displayClear": "clear the screen ",
    "gui.blocklyText.IICScan.readICCAddr": "read scanned I2C device address",
    "gui.blocklyText.sdcard.initCS": "SD film pin [CSPIN] initialization successful?",
    "gui.blocklyText.sdcard.readFileNLine": "SD reads file [FILE] line [LINE]",
    "gui.blocklyText.sdcard.readFileAll": "SD reads file [FILE] all content",
    "gui.blocklyText.sdcard.writeFile": "SD write file [FILE] content [MSG] mode [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD delete file [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD get file [FILE] info [INFO]",
    "gui.blocklyText.sdcard.NAME": "NAME",
    "gui.blocklyText.sdcard.PATH": "PATH",
    "gui.blocklyText.sdcard.TYPE": "TYPE",
    "gui.blocklyText.sdcard.LINE": "LINE",
    "gui.blocklyText.sdcard.SIZE": "SIZE",
    "gui.blocklyText.sdcard.APPEND": "APPEND",
    "gui.blocklyText.sdcard.REPLACE": "REPLACE",
    "gui.blocklyText.sdcard.WARP": "WARP",
    "gui.blocklyText.sdcard.NOWARP": "NOWARP",
    "gui.blocklyText.sdcard.lsFile": "SD serial port prints all file information baud [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "ενεργοποίησε σεριακή [SERIALPORT] RX: [PINR] TX: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "ταχύτητα σειριακής [SERIALPORT] baud rate [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "στη σειριακή [SERIALPORT] γράψε [TEXT] [WAY], [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "Υπάρχουν δεδομένα για διάβασμα στη σειριακή πόρτα [SERIALPORT];",
    "gui.blocklyText.softSerialport.readSerialportData": "διάβασε τη σειριακή πόρτα[SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "διάβασε [TYPE] στη σειριακή [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "on bluetooth connected",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "on bluetooth disconnected",
    "gui.blocklyText.bluetooth.receivedKey": "when bluetooth received key [KEY] [TYPE]",
    "gui.blocklyText.bluetooth.string": "String",
    "gui.blocklyText.bluetooth.number": "Number",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "when bluetooth received data and meet [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "send string key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "send number key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.openService": "open bluetooth service",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "received data from bluetooth",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo Starts",
    "gui.blocklyText.leonardo.setBaud": "set [SERIAL] baud rate to [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.leonardo.serialPort1": "SerialPort0",
    "gui.blocklyText.leonardo.serialPort2": "SerialPort1",
    "gui.blocklyText.leonardo.dataReadable": "Is there data to read on [SERIAL]?",
    "gui.blocklyText.leonardo.readSerialData": "read [SERIAL] data",
    "gui.blocklyText.arduinounoR3.initTcs34725": "Initializes the TCS34725 color sensor",
    "gui.blocklyText.arduinounoR3.functionStarts": "Uno kit program starts",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "Initializes the TMI1650 display",
    "gui.blocklyText.arduinounoR3.readdigital": "read pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "Digital Sensor",
    "gui.blocklyText.arduinounoR3.buttonModule": "Button Module",
    "gui.blocklyText.arduinounoR3.stickButton": "Rocker Button",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.arduinounoR3.readAnalog": "read pin [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "Anolog Sensor",
    "gui.blocklyText.arduinounoR3.lightSensor": "Light Sensor",
    "gui.blocklyText.arduinounoR3.soundSensor": "Sound Sensor",
    "gui.blocklyText.arduinounoR3.stickDirection": "Rocker Direction",
    "gui.blocklyText.arduinounoR3.angleSensor": "Angle Sensor",
    "gui.blocklyText.arduinounoR3.graySensor": "Grayscale Sensor",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.arduinounoR3.open": "turn on",
    "gui.blocklyText.arduinounoR3.close": "turn off",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "Digital Actuator",
    "gui.blocklyText.arduinounoR3.ledLights": "LED Lights",
    "gui.blocklyText.arduinounoR3.fanModule": "Fan Module",
    "gui.blocklyText.arduinounoR3.recordModule": "Record Module",
    "gui.blocklyText.arduinounoR3.relay": "Relay",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.arduinounoR3.writeAnalog": " set pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "move forward",
    "gui.blocklyText.arduinounoR3.backward": "move backward",
    "gui.blocklyText.arduinounoR3.turnLeft": "turn left",
    "gui.blocklyText.arduinounoR3.turnRight": "turn right",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "robot [DIR] at [SPEED] % speed ",
    "gui.blocklyText.arduinounoR3.motorForward": "move forward",
    "gui.blocklyText.arduinounoR3.motorReverse": "move backward",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "motor [MOTOR] [DIR] at [SPEED] % speed ",
    "gui.blocklyText.arduinounoR3.stopMotor": "robot (MA and MB motors) stop moving",
    "gui.blocklyText.arduinounoR3.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "Infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "infrared pin [PIN] received the [BUTTON] pressed signal？",
    "gui.blocklyText.arduinounoR3.noSound": "no sound",
    "gui.blocklyText.arduinounoR3.half": "Half",
    "gui.blocklyText.arduinounoR3.quarter": "Quarter",
    "gui.blocklyText.arduinounoR3.oneInEighth": "One-eighth",
    "gui.blocklyText.arduinounoR3.wholeBeat": "Whole Beat",
    "gui.blocklyText.arduinounoR3.doubleBeat": "Double Beat",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "set pin [PIN] MP3 module volume to [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "set pin [PIN] MP3 module playing mode to [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "Stop",
    "gui.blocklyText.arduinounoR3.play": "Play",
    "gui.blocklyText.arduinounoR3.loop": "Loop",
    "gui.blocklyText.arduinounoR3.random": "Random",
    "gui.blocklyText.arduinounoR3.pause": "Pause",
    "gui.blocklyText.arduinounoR3.previous": "Previous",
    "gui.blocklyText.arduinounoR3.next": "Next",
    "gui.blocklyText.arduinounoR3.VolumePlus": "Volume+",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "Volume-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "pin [PIN] MP3 module play the [NUM] song",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "read pin [PIN] LM35 temperature(°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "read pin [PIN] DHT11 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "red",
    "gui.blocklyText.arduinounoR3.blue": "blue",
    "gui.blocklyText.arduinounoR3.green": "green",
    "gui.blocklyText.arduinounoR3.readColorSensor": "read TCS34725 color sensor [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "four-bit nixie tube TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "turn on",
    "gui.blocklyText.arduinounoR3.tm1650Close": "turn off",
    "gui.blocklyText.arduinounoR3.clearScreen": "clear screen",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": " 4-bit nixie tube TM1650 display string [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "[STATUS] the [ORDER] decimal point of 4-bit nixie tube TM1650",
    "gui.blocklyText.arduinounoR3.bright": "brighten",
    "gui.blocklyText.arduinounoR3.dark": "darken",
    "gui.blocklyText.arduinounoR3.setLightColor": "pin [PIN] the [NUM] LED display [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "pin [PIN] the [NUM] LED R:[R] G:[G] B:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "pin [PIN] [NUM] LEDs",
    "gui.blocklyText.arduinounoR3.ledControl": "LED matrix (max7219) initial parameter DIN pin [DIN], CS pin [CS], CLK pin [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "LED matrix (max7219) show pattern [PIC]",
    "gui.blocklyText.max.maxStart": "Max starts",
    "gui.blocklyText.max.onboardButtonPressed": "onboard button [PRESSED]?",
    "gui.blocklyText.max.pressed": "pressed",
    "gui.blocklyText.max.notPressed": "not pressed",
    "gui.blocklyText.max.all": "-1 ALL",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "set RGB lights [TYPE] color [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "read sound sensor",
    "gui.blocklyText.max.playSoundEffect": "play sound effect[SOUND]",
    "gui.blocklyText.max.setDPinValue": "set digital pin [PIN] to [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "read digital pin [PIN]",
    "gui.blocklyText.max.readAPinValue": "read analog pin [PIN]",
    "gui.blocklyText.max.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.max.readLinefindingSensor": "read [DIR] line-tracking sensor",
    "gui.blocklyText.max.left": "Left",
    "gui.blocklyText.max.middle": "Middle",
    "gui.blocklyText.max.right": "Right",
    "gui.blocklyText.max.setLineFidingCarLights": "car LED lights [SWITCH]",
    "gui.blocklyText.max.on": "on",
    "gui.blocklyText.max.off": "off",
    "gui.blocklyText.max.readUltrasoundSensor": "read ulrasonic sensor(cm)",
    "gui.blocklyText.max.readLightSensor": "read [LEFTRIGHT] light sensor",
    "gui.blocklyText.max.maxSpeedMove": "MAX move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] stops",
    "gui.blocklyText.max.showUserDefineExpressions": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.max.clear": "clear",
    "gui.blocklyText.max.do": "Do",
    "gui.blocklyText.max.re": "Re",
    "gui.blocklyText.max.mi": "Mi",
    "gui.blocklyText.max.fa": "Fa",
    "gui.blocklyText.max.sou": "So",
    "gui.blocklyText.max.la": "La",
    "gui.blocklyText.max.xi": "Ti",
    "gui.blocklyText.max.highdo": "highdo",
    "gui.blocklyText.max.brake": "brake",
    "gui.blocklyText.max.rebound": "rebound",
    "gui.blocklyText.max.bullets": "bullets",
    "gui.blocklyText.max.longecho": "long echo",
    "gui.blocklyText.max.playfulending": "ending",
    "gui.blocklyText.max.yo": "yo",
    "gui.blocklyText.max.wo": "wo",
    "gui.blocklyText.max.robottalking": "robot talking",
    "gui.blocklyText.sen0160.init": "Set Hx711 weight sensor calibration value [VALUE] with Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.sen0160.readValue": "Read Hx711 weight sensor(g) Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.knowflow.readORP": "read pin [PIN] ORP sensor (mV)",
    "gui.blocklyText.knowflow.readEC": "read pin [PIN] EC sensor (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "read pin [PIN] PH sensor(V1)",
    "gui.blocklyText.knowflow.readECV2": "read pin [PIN] EC sensor (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "read pin [PIN] PH sensor(V2)",
    "gui.blocklyText.knowflow.readDO": "read pin [PIN] DO sensor (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "pin [PIN] Peristaltic Pump rotate [DIR] at [SPEED]% speed",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] pin [PIN] relay",
    "gui.blocklyText.goble.readAxis": "read GOBLE rocker [AXIS] axis",
    "gui.blocklyText.goble.buttonIsPressed": "GOBLE [BUTTON] button pressed?",
    "gui.blocklyText.eeprom.clear": "eeprom clear",
    "gui.blocklyText.eeprom.read": "eeprom read from address [ADDR]",
    "gui.blocklyText.eeprom.write": "eeprom write to address [ADDR] with data [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom get crc from address [ADDRA] to address [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom get length",
    "gui.blocklyText.tds.readAnalogPin": "read analog pin [PIN] TDS sensor(ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "two stepper motors run simultaneously   X axis : [XSET] y axis: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "stepper motor [AXIS] axis  [STEPS] steps per revolution  Speed(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "stepper motor move [ROTATEDIR] for [STEPS] steps",
    "gui.blocklyText.steppermotor.setJointSteps": "two stepper motors run simultaneously. Move [XSTEPS] steps on x-axis [ROTATEDIRX]  Move [YSTEPS] steps on y-axis [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] starts",
    "gui.blocklyText.multithread.stopThread": "stop [THREAD]",
    "gui.blocklyText.multithread.startThread": "start up [THREAD]",
    "gui.blocklyText.multithread.loop1": "loop1",
    "gui.blocklyText.multithread.loop2": "loop2",
    "gui.blocklyText.multithread.loop3": "loop3",
    "gui.blocklyText.multithread.loop4": "loop4",
    "gui.blocklyText.multithread.loop5": "loop5",
    "gui.blocklyText.multithread.loop6": "loop6",
    "gui.blocklyText.multithread.loop7": "loop7",
    "gui.blocklyText.multithread.loop8": "loop8",
    "gui.blocklyText.multithread.all": "all",
    "gui.blocklyText.DFR0151.init": "DS1307 initialization",
    "gui.blocklyText.DFR0151.adjustTime": "Adjust the DS1307 time to [YEAR]Year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0151.getTime": "get DS1307 time [INDEX]",
    "gui.blocklyText.DFR0151.Year": "year",
    "gui.blocklyText.DFR0151.month": "month",
    "gui.blocklyText.DFR0151.date": "date",
    "gui.blocklyText.DFR0151.hour": "hour",
    "gui.blocklyText.DFR0151.minute": "minute",
    "gui.blocklyText.DFR0151.second": "second",
    "gui.blocklyText.DFR0151.week": "week",
    "gui.blocklyText.DFR0469.init": "SD2405 initialization",
    "gui.blocklyText.DFR0469.adjustTime": "adjust SD2405 time to [YEAR]year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0469.getTime": "get SD2405 time [INDEX]",
    "gui.blocklyText.DFR0126.setPin": "The audio analysis module  pins #S[SPIN] #R[RPIN] Analog pin[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "read sound frequency  band[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Initialize the NFC module interface I2C",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Initialize the NFC module interface hardware serial port Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "Initialize the NFC module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "Initialize the NFC module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "NFC module block [DATABLOCK] byte [BYTES] write [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "read NFC module block [DATABLOCK] the [BYTES] byte (number)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "read NFC module block [DATABLOCK] all data (string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "read NFC module block UID (srting)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "detected card UID: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "detected card?",
    "gui.blocklyText.DFR0126.serial1": "serial1",
    "gui.blocklyText.DFR0126.serial2": "serial2",
    "gui.blocklyText.DFR0126.serial3": "serial3",
    "gui.blocklyText.sen0245.init": "Initialize the VL53L0X laser ranging sensor",
    "gui.blocklyText.sen0245.setVL53L0X": "VL53L0X accuracy[PRECISION] measurement mode[VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "get VL53L0X laser ranging value(mm)",
    "gui.blocklyText.SEN0245.LOW": "Low(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "High(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "Single",
    "gui.blocklyText.SEN0245.Continuous": "Continuous",
    "gui.blocklyText.serialMp3.initSerialMp3": "Init serial MP3 module interface [SERIALPORT] RX(green) [RX] TX(blue) [TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "set serial MP3 module playing mode to [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "Set serial MP3 module playing [VALUE] song",
    "gui.blocklyText.serialMp3.setMP3Volume": "Set serial MP3 module volume to [VOLUME]%",
    "gui.blocklyText.serialMp3.play": "play",
    "gui.blocklyText.serialMp3.pause": "pause",
    "gui.blocklyText.serialMp3.end": "end",
    "gui.blocklyText.serialMp3.prev": "prev",
    "gui.blocklyText.serialMp3.next": "next",
    "gui.blocklyText.serialMp3.volumeUp": "volume up",
    "gui.blocklyText.serialMp3.volumeDown": "volume down",
    "gui.blocklyText.serialMp3.playlist": "playlist",
    "gui.blocklyText.serialMp3.insertTracks": "insert tracks",
    "gui.blocklyText.serialMp3.volume": "volume",
    "gui.blocklyText.servo360.setServo360": "pin [PIN] servo rotate [DIR] at [SPEED] % speed",
    "gui.blocklyText.servo360.CW": "CW",
    "gui.blocklyText.servo360.CCW": "CCW",
    "gui.blocklyText.TCS34725.initTcs34725": "Initializes the TCS34725 color sensor",
    "gui.blocklyText.TCS34725.readTcs34725": "read TCS34725 color sensor [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "Turn GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "Don\"t turn GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "show text[TEXT] in [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "text scroll [DIR]",
    "gui.blocklyText.regEmotion8_16.left": "left",
    "gui.blocklyText.regEmotion8_16.right": "right",
    "gui.blocklyText.regEmotion8_16.lightAll": "show all dot matrix in [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "show coordinates of point (x:[XAXIS], y:[YXAXIS]) in [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "clear screen",
    "gui.codeLabel.dynamicVariables": "Dynamic variables",
    "gui.codeLabel.functionDeclaration": "Function declaration",
    "gui.codeLabel.staticConstants": "Static constants",
    "gui.codeLabel.createObject": "Create an object",
    "gui.codeLabel.mainProgramStart": "Main program start",
    "gui.codeLabel.SubthreadProgramStarts": "Subthread %1 program starts",
    "gui.codeLabel.customFunction": "Custom function",
    "gui.codeLabel.eventCallbackFunction": "Event callback function",
    "gui.codeLabel.staticFunctions": "Static functions",
    "gui.mainHeader.err": "!Error: The same program headers [%1] cannot be used at the same time. Please delete the duplicated block.",
    "gui.mainHeader.help": "!Tip: If multiple programs need to be executed at the same time, use the \"multi-threading function\" in \"Extension\", refer to the help documentation for details.",
    "gui.threadHeader.err1": "!Error: The program headers  [%1] cannot be used at the same time. They belong to different kits or boards.",
    "gui.threadHeader.err2": "!Error Tip: The same event headers [%1] cannot be used at the same time. Please delete the duplicated block.",
    "gui.blocklyText.mqtt.whenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT message",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi connect to account:[ACCOUNT] password:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi connected?",
    "gui.blocklyText.wifi.wifiDisConnected": "disconnect Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP mode:[ACCOUNT2] password:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi get soft ap ip",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi get [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Error code",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "ntp set network time, time zone [TIMEZONE] timer server [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "ntp get local time [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "UTCWest12_t",
    "gui.blocklyText.ntp.UTCWest11_t": "UTCWest11_t",
    "gui.blocklyText.ntp.UTCWest10_t": "UTCWest10_t",
    "gui.blocklyText.ntp.UTCWest9_t": "UTCWest9_t",
    "gui.blocklyText.ntp.UTCWest8_t": "UTCWest8_t",
    "gui.blocklyText.ntp.UTCWest7_t": "UTCWest7_t",
    "gui.blocklyText.ntp.UTCWest6_t": "UTCWest6_t",
    "gui.blocklyText.ntp.UTCWest5_t": "UTCWest5_t",
    "gui.blocklyText.ntp.UTCWest4_t": "UTCWest4_t",
    "gui.blocklyText.ntp.UTCWest3_t": "UTCWest3_t",
    "gui.blocklyText.ntp.UTCWest2_t": "UTCWest2_t",
    "gui.blocklyText.ntp.UTCWest1_t": "UTCWest1_t",
    "gui.blocklyText.ntp.UTCMiddle_t": "UTCMiddle_t",
    "gui.blocklyText.ntp.UTCEast1_t": "UTCEast1_t",
    "gui.blocklyText.ntp.UTCEast2_t": "UTCEast2_t",
    "gui.blocklyText.ntp.UTCEast3_t": "UTCEast3_t",
    "gui.blocklyText.ntp.UTCEast4_t": "UTCEast4_t",
    "gui.blocklyText.ntp.UTCEast5_t": "UTCEast5_t",
    "gui.blocklyText.ntp.UTCEast6_t": "UTCEast6_t",
    "gui.blocklyText.ntp.UTCEast7_t": "UTCEast7_t",
    "gui.blocklyText.ntp.UTCEast8_t": "UTCEast8_t",
    "gui.blocklyText.ntp.UTCEast9_t": "UTCEast9_t",
    "gui.blocklyText.ntp.UTCEast10_t": "UTCEast10_t",
    "gui.blocklyText.ntp.UTCEast11_t": "UTCEast11_t",
    "gui.blocklyText.ntp.UTCEast12_t": "UTCEast12_t",
    "gui.blocklyText.ntp.year": "year",
    "gui.blocklyText.ntp.month": "month",
    "gui.blocklyText.ntp.date": "date",
    "gui.blocklyText.ntp.hour": "hour",
    "gui.blocklyText.ntp.minute": "minute",
    "gui.blocklyText.ntp.second": "second",
    "gui.blocklyText.ntp.week": "week",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "when udp server recv [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "set udp server port [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "udp server send broadcast [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "when udp client recv [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "set udp client IP [IPADDR] port [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "udp client send broadcast [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "message",
    "gui.blocklyText.udp.ClientRecvedMsg": "message",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] add key [KEY] value [VALUE]",
    "gui.blocklyText.http.requestHeader": "HTTP request header add [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] URL [URL] timeout [TIMEOUT] ms",
    "gui.blocklyText.http.readMessage": "HTTP read [LINE] line message",
    "gui.blocklyText.http.param": "PARAM",
    "gui.blocklyText.http.header": "HEADER",
    "gui.blocklyText.http.oneline": "ONE",
    "gui.blocklyText.http.allline": "ALL",
    "gui.blocklyText.mqtt.mqttConnect": "connect MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT connected?",
    "gui.blocklyText.mqtt.acceptance.meaage": "When [TOPIC] received the message as [MSG]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.mqtt.initial": "MQTT initial [SETTING]",
    "gui.blocklyText.mqtt.mqttDisconnect": "disconnect MQTT",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 initializes",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 read altitude(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 read pressure(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 read temperature(℃)",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 measurement [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "object",
    "gui.blocklyText.sen0206.environment": "environment",
    "gui.blocklyText.sen0206.celsius": "celsius",
    "gui.blocklyText.sen0206.fahrenheit": "fahrenheit",
    "gui.blocklyText.sen0253.init": "10DOF Initial pin I2C_ADDR level [LEVEL]",
    "gui.blocklyText.sen0253.reset": "10DOF Sensor reset",
    "gui.blocklyText.sen0253.power": "10DOF Set power mode [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF Get euler angles [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF Get qua [AXIS] axis",
    "gui.blocklyText.sen0253.getAcc": "10DOF Get accelerometer [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF Get magnetometer [AXIS] axis(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF Get linear acceleration [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF Get gravit capacity [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF Get angular speed [AXIS] axis",
    "gui.blocklyText.sen0253.getBmp280": "10DOF Get [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "Low",
    "gui.blocklyText.sen0253.HIGH": "High",
    "gui.blocklyText.sen0253.normal": "Normal",
    "gui.blocklyText.sen0253.lowPower": "Low power",
    "gui.blocklyText.sen0253.suspend": "Suspend",
    "gui.blocklyText.sen0253.Roll": "Roll(°)",
    "gui.blocklyText.sen0253.Pitch": "Pitch(°)",
    "gui.blocklyText.sen0253.Yaw": "Yaw(°)",
    "gui.blocklyText.sen0253.temperature": "Temperature",
    "gui.blocklyText.sen0253.elevation": "Elevation",
    "gui.blocklyText.sen0253.airPressure": "AirPressure",
    "gui.blocklyText.sen0290.init": "lighting sensor set irq pin [IRQPIN] and i2c address [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "lighting sensor lightning sensor iic init ok？",
    "gui.blocklyText.sen0290.manualCal": "lighting sensor set capacitance [CAPACITANCE] mode [MODE] dist [DIST]",
    "gui.blocklyText.sen0290.indoors": "indoors",
    "gui.blocklyText.sen0290.outdoors": "outdoors",
    "gui.blocklyText.sen0290.disturberDis": "disturber disable",
    "gui.blocklyText.sen0290.disturberEn": "disturber enable",
    "gui.blocklyText.sen0290.errMsg": "error msg",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "lighting sensor get status",
    "gui.blocklyText.sen0290.GetLightningDistKm": "lighting sensor get lightning dist(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "lighting sensor get strike energy raw",
    "gui.blocklyText.sen0291.sen0291Init": "The power meter initializes the I2C address as [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "The power meter reads the load [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "The actual measured current of the power meter calibration is [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "Voltage (mV)",
    "gui.blocklyText.sen0291.electric": "Electric (mA)",
    "gui.blocklyText.sen0291.power": "Power (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "Shunt Voltage (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.weather.weatherGetWithCity": "[WEATHER] in [CITY] city [COUNTRY] province",
    "gui.blocklyText.weather.temperaturHigh": "maximum temperature(℃)",
    "gui.blocklyText.weather.temperaturLow": "minimum temperature(℃)",
    "gui.blocklyText.weather.weather": "weather",
    "gui.blocklyText.weather.weatherInit": "weather server Initialize [SETTING]",
    "gui.blocklyText.weather.serverAddr": "server address",
    "gui.blocklyText.weather.appid": "appid",
    "gui.blocklyText.weather.appsecret": "appsecret",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "set server parameter [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "update tag [TAG] value [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "read tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "delete tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "count tag",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "network connection test",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "search tag from start number [NUMS] variable count [NUMV] tag [TAG] type [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "tag",
    "gui.blocklyText.tinywebdb.value": "value",
    "gui.blocklyText.tinywebdb.both": "both",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "HongKong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.getSpeech": "recognition result",
    "speech.setRecordTime": "set record time to [TIME]",
    "speech.stopRecord": "stop speech recognition",
    "speech.controlSonogram": "[CONTROL] Sonogram",
    "speech.setServer": "switch speech recognition server to [SERVER]",
    "speech.show": "show",
    "speech.hide": "hide",
    "speech.server1": "server1",
    "speech.server2": "server2",
    "gui.blocklyText.mp3.initMp3": "Init DFPlayer MP3 module interface [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "set DFPlayer MP3 module volume to [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "set DFPlayer MP3 module playing mode to [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "set DFPlayer MP3 module playing [NUM] song",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "set pin [PIN] DFPlayer MP3 module volume to [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "set pin [PIN] DFPlayer MP3 module playing mode to [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "pin [PIN] DFPlayer MP3 module play the [NUM] song",
    "gui.blocklyText.tel0094.GPSInit": "GPS initializes as [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS gets the location [LATLON](°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "GPS gets the location [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "Longitude",
    "gui.blocklyText.tel0094.LONGITUDE": "Latitude",
    "gui.blocklyText.tel0094.YEAR": "Year",
    "gui.blocklyText.tel0094.MONTH": "Month",
    "gui.blocklyText.tel0094.DAY": "Day",
    "gui.blocklyText.tel0094.HOUR": "Hour",
    "gui.blocklyText.tel0094.MINUTE": "Minute",
    "gui.blocklyText.tel0094.SECOND": "Second",
    "gui.blocklyText.tel0094.ALL": "All",
    "gui.blocklyText.tel0094.GPSGet": "GPS gets [GPS]",
    "gui.blocklyText.tel0094.getState": "Status",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "Latitude hemisphere",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "Longitude hemisphere",
    "gui.blocklyText.tel0094.getGroundSpeed": "Ground speed",
    "gui.blocklyText.tel0094.getSatellitesCount": "Number of satellites",
    "gui.blocklyText.tel0094.getAltitude": "Altitude",
    "gui.blocklyText.tel0094.getPdop": "Position accuracy",
    "gui.blocklyText.tel0094.getHdop": "Horizontal accuracy",
    "gui.blocklyText.tel0094.getVdop": "Vertical accuracy",
    "gui.blocklyText.tel0094.getPositioningMode": "Positioning mode",
    "gui.blocklyText.microIoT.displayInLine": "OLED show line [LINE] text [TEXT]",
    "gui.blocklyText.microIoT.displayInXY": "OLED show [TEXT] x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED show image [IMAGE] x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED clear",
    "gui.blocklyText.microIoT.pointDrawing": "OLED draw point x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED set line width [LINEWIDTH]",
    "gui.blocklyText.microIoT.lineDrawing": "OLED draw line start x [X1] y [Y1] end x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED draw circle [FILL] circle center x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED draw box [FILL] start x [X] y [Y] width [WIDTH] height [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB set brightness to [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "RGB lights are all off",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "RGB lights [NUM1] through [NUM2] show colors [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "RGB lights [NUM1] to [NUM2] show gradients hue from [COLOR1] to [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "RGB loop by [NUM] pixel",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "RGB lights [NUM1] through [NUM2] show colors [COLOR]",
    "gui.blocklyText.motorIot.all": "ALL",
    "gui.blocklyText.microIot.servo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "MQTT initial parameter [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.microIot.MQTTMsg": "MQTT message",
    "gui.blocklyText.microTouch.whenBtnPress": "when keys [KEY] press",
    "gui.blocklyText.microTouch.Any": "Any",
    "gui.blocklyText.microTouch.isButtonPressed": "Keys [KEY] is pressed?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "Normal mode read keys",
    "gui.blocklyText.microTouch.readKeyMathMode": "Math mode read [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "Number key(num)",
    "gui.blocklyText.microTouch.Function": "Function key(str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] vibration motor",
    "gui.blocklyText.microTouch.turnOn": "Turn on",
    "gui.blocklyText.microTouch.shutDown": "Shut down",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "Sound intensity",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "Natural light",
    "gui.blocklyText.microNaturalScience.readColour": "Read [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "Red value",
    "gui.blocklyText.microNaturalScience.G": "Green value",
    "gui.blocklyText.microNaturalScience.B": "Blue value",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "UV intensity (μW/cm²)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "Turn on",
    "gui.blocklyText.microNaturalScience.off": "Turn off",
    "gui.blocklyText.microNaturalScience.setTDSK": "Set the TDS electrode constant k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "Obtain the TDS electrode constant k",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS(ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "Get [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "Water temperature (°C)",
    "gui.blocklyText.microNaturalScience.Temperature": "Temperature(℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "Humidity(%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "Altitude(m)",
    "gui.blocklyText.microNaturalScience.Pressure": "Pressure(Pa)",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB Set matrix point X [X] Y [Y] show colors [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 can not be placed in the interrupt event, may cause dht11 reading abnormal",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 can not be placed in the interrupt event, may cause dht22 reading abnormal",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 cannot be placed in an interrupt event, which may result in abnormal pm2.5 readings",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 reads data, which may cause led dot matrix initialization failure",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 reads data, which may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "the mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to be set to fail",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "the mp3 setting playback mode cannot be placed in an interrupt event, which may cause the playback mode to fail",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "Mp3 settings playback can not be placed in the interrupt event, may cause the settings to play failed",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "Serial port mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "The serial port mp3 playback mode cannot be placed in an interrupt event, which may cause the setup play mode to be lost",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "Serial port mp3 playback cannot be placed in an interrupt event, which may cause playback to fail",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "The serial port mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to fail",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloq's mqtt initialization soft serial port cannot be placed in an interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq sends a message that cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq read mqtt message can not be placed in the interrupt event, may cause the reception to fail",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "The initial soft serial port of http of obloq cannot be placed in the interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "The get request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "Obloq http post request cannot be placed in an interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "The put request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "Infrared reception cannot be placed in an interrupt event, which may cause reception failure",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "Infrared reception may cause the LED dot matrix initialization to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "Infrared reception may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "Infrared transmission cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause initialization to fail",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause setup pins to fail",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the pin to fail to fail",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause display color to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause color adjustment failure",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the move to fail",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause loop movement to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause the display histogram to fail",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "Led dot matrix can not be placed in the interrupt event, may cause initialization failure",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "The led dot matrix cannot be placed in an interrupt event, which may cause the display pattern to fail",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "The soft serial output cannot be placed in an interrupt event, which may cause the output to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "The data read by the soft serial port cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Soft serial port output data and read data colleague use, may cause data abnormalities",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "The soft serial port read integer cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "Soft serial port output data and reading integer colleague use may cause abnormal reading data",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "When dht11 and PM2.5 are in two threads, dht11 reads may fail if pm2.5 reads too frequently. It is recommended to reduce the pm2.5 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "When dht11 and ds18b20 are in two threads respectively, if ds18b20 reads too frequently, dht11 may fail to read, it is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "When dht11 and mp3 are initialized in two threads, mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "When dht11 and mp3 set volume are in two threads respectively, mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "When dht11 and mp3 setup modes are in two threads respectively, mp3 setup mode may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "When dht11 and mp3 player are in two threads, mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "When dht11 and serial mp3 are initialized in two threads, serial mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "When dht11 and serial mp3 control module are in two threads respectively, the mp3 control module may cause dht11 reading errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "When dht11 and serial mp3 player are in two threads, serial mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "When dht11 and serial mp3 set volume are in two threads respectively, serial mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "When dht11 and RGB are initialized in two threads, RGB initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "When dht11 and RGB set brightness in two threads, RGB set brightness may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "When dht11 and RGB are extinguished in two threads, RGB extinction may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "When dht11 and RGB display colors in two threads, RGB display colors may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "When dht11 and RGB display a gradient in two threads, respectively, RGB display a gradient may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "When dht11 and RGB move pixels in two threads, RGB move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "When dht11 and RGB loop move pixels in two threads, respectively, RGB loop move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "When dht11 and RGB display columns in two threads respectively, RGB display bars may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "When dht11 and soft serial send are in two threads respectively, soft serial send may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "When dht11 and obloq MQTT are initialized in two threads respectively, obloq MQTT initialization may cause dht11 to read errors. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "When dht11 and obloq MQTT send messages in two threads respectively, obloq MQTT send messages that may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "When dht11 and obloq HTTP put requests are in two threads, it can cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "When dht11 and obloq HTTP initialization are in two threads respectively, it may cause dht11 reading error. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "When dht11 and obloq HTTP get requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "When dht11 and obloq HTTP post requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "When dht11 and infrared emission are in two threads respectively, it may cause errors in reading dht11. It is suggested to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "When ds18b20 and PM2.5 are in two threads respectively, it may cause PM2.5 reading error, so it is suggested to reduce ds18b20 frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "When ds18b20 and obloq MQTT are initialized in two threads respectively, MQTT initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "When ds18b20 and obloq HQTT put requests are in two threads respectively, the put request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "When ds18b20 and obloq HTTP initialization are in two threads respectively, the HTTP initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "When ds18b20 and obloq HQTT get requests are in two threads respectively, the get request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "When ds18b20 and obloq HQTT post requests are in two threads respectively, the post request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Reading ds18b20 may result in no infrared data, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Reading ds18b20 may cause errors in receiving data by soft serial port, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "When PM2.5 read and obloq MQTT initialization are carried out in two threads respectively, MQTT initialization failure or PM2.5 read error may be caused. It is recommended to avoid simultaneous execution of MQTT initialization and PM2.5 read",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "When PM2.5 reading and obloq MQTT send messages in two threads respectively, PM2.5 reading errors may be caused. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "When PM2.5 read and obloq HTTP put requests are in two threads, it may cause put request failure or PM2.5 read error. It is recommended to reduce put request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "When PM2.5 reads and obloq HTTP initializations are carried out in two threads respectively, HTTP initializations may fail or PM2.5 reads may go wrong. It is recommended to avoid simultaneous execution of HTTP initializations and PM2.5 reads",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "When PM2.5 read and obloq HTTP get requests are in two threads respectively, it may cause get request failure or PM2.5 read error. It is recommended to reduce get request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "When PM2.5 read and obloq HTTP post request are in two threads respectively, it may cause post request failure or PM2.5 read error. It is recommended to reduce the frequency of post request or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "When PM2.5 reading and MP3 initialization are carried out in two threads respectively, PM2.5 reading may fail. It is recommended to avoid the simultaneous execution of PM2.5 reading and MP3 initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "When PM2.5 reading and MP3 setting volume are in two threads respectively, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "When PM2.5 reading and serial MP3 player are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "When PM2.5 reading and serial MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "When PM2.5 reading and serial MP3 setting volume are respectively in two threads, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "When PM2.5 reading may cause infrared reception failure, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "When PM2.5 reading and RGB initialization are carried out in two threads respectively, PM2.5 reading errors may be caused. It is recommended to avoid simultaneous execution of PM2.5 reading and RGB initialization",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "When PM2.5 reading and RGB setting brightness are respectively in two threads, PM2.5 reading error may be caused. It is recommended not to adjust brightness too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "When PM2.5 reading and RGB extinguishing are in two threads, PM2.5 reading errors may be caused. It is recommended not to extinguish RGB too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "When PM2.5 reading and RGB display colors are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to switch colors too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "When PM2.5 reading and RGB display the gradient in two threads respectively, PM2.5 reading error may be caused, it is recommended not to switch the gradient too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "When PM2.5 reading and RGB moving pixels are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to move pixels too frequently",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_readIRReceived": "Infrared reception may cause the on-board rgb display color to be abnormal, which can be solved by calling rgb display color multiple times",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "Using Wi-Fi may cause the onboard rgb to display an abnormal color",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "Udp uses Wi-Fi, may cause the onboard rgb display color anomaly",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_readIRReceived": "Infrared reception may cause the onboard rgb to set a three-color anomaly, which can be solved by calling rgb display color multiple times",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "Using Wi-Fi may cause the onboard rgb to set a three-color exception",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "Udp uses Wi-Fi, may cause the onboard rgb to set a three-color exception",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_readIRReceived": "Infrared reception may cause the on-board rgb to set the brightness abnormally, which can be solved by setting the brightness by calling rgb multiple times",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "Using Wi-Fi may cause the onboard rgb to set abnormal brightness",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "Udp uses Wi-Fi, may cause the onboard rgb to set the brightness abnormally",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_readIRReceived": "Infrared reception may cause the on-board rgb to obtain an abnormal brightness value, which can be solved by calling rgb multiple times",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "Using Wi-Fi may cause the onboard rgb to get abnormal brightness",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "Udp uses Wi-Fi, which may cause the onboard rgb to get abnormal brightness",
    "gui.blocklyText.prompt.esp32.initSerialMp3_readIRReceived": "Infrared reception may cause serial port mp3 initialization failure, which can be solved by calling serial port mp3 initialization multiple times",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to be initialized abnormally",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 initialization to be abnormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_readIRReceived": "Infrared reception may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_readIRReceived": "Infrared reception may cause the serial port mp3 to play the track failure, which can be solved by calling the serial port mp3 to play the track multiple times",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "Using Wi-Fi may cause serial port mp3 to play track failed",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to play tracks failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_readIRReceived": "Infrared reception may cause the serial port mp3 to set the volume failure. It can be solved by setting the volume by calling the serial port mp33 multiple times",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "Using Wi-Fi may cause the serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.setServo360_readIRReceived": "Infrared reception may cause the 360 servo to set the speed abnormally, which can be solved by setting the speed of the 360 servos multiple times",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "Using Wi-Fi may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp uses Wi-Fi, which may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.esp32.readIRReceived_wifi": "Using Wi-Fi may cause infrared reception to be abnormal",
    "gui.blocklyText.prompt.esp32.readIRReceived_udp": "Udp uses Wi-Fi, may cause infrared reception abnormalities",
    "gui.blocklyText.prompt.esp32.dhtTHSensorReadMoistureTemperature_readIRReceived": "Infrared reception may cause dht11 to read abnormally, which can be solved by calling dht11 multiple times",
    "gui.blocklyText.prompt.esp32.dsTSensorReaddtemperature_readIRReceived": "Infrared reception may cause dht22 to read abnormalities, which can be solved by calling dht22 multiple times",
    "gui.blocklyText.prompt.esp32.ws2812NumberOfLightsBright_readIRReceived": "Infrared reception may cause the lamp to move, set the brightness, set the color and other abnormalities, and can be solved by calling the lamp with multiple operations",
    "gui.blocklyText.prompt.esp32.ws2812NumberOfLightsBright_wifi": "Using Wi-Fi may cause the light strip to move, set brightness, set color, etc",
    "gui.blocklyText.prompt.esp32.ws2812NumberOfLightsBright_udp": "Udp uses Wi-Fi, which may cause the light to move, set brightness, set color, etc",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "When PM2.5 read and RGB loop move pixels in two threads respectively, PM2.5 read errors may be caused. It is recommended not to execute loop move pixels too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "When PM2.5 reading and RGB display columns are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to execute RGB display columns too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "When PM2.5 reading and led dot array initialization are carried out in two threads respectively, led dot array initialization may go wrong. It is recommended to avoid the simultaneous execution of PM2.5 reading and led dot array initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "When PM2.5 reading and led dot array display are in two threads respectively, led dot array display may be wrong, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5 reading may cause errors in PM2.5 reading. It is recommended not to send data too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5 reading may cause soft serial port reading failure. It is recommended not to read PM2.5 too frequently",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 may not receive infrared data, it is recommended to reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "When MP3 and obloq MQTT initialization is in two threads, it may cause MQTT initialization to fail. It is recommended to avoid simultaneous MQTT initialization and MP3 initialization",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "When MP3 and obloq HTTP put requests are in two threads, it is possible to cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "When MP3 and obloq HTTP initializations are in two threads, HTTP initializations may fail. It is recommended to avoid both HTTP initializations and MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "When MP3 and obloq HTTP get requests are in two threads, it may cause get requests to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "When MP3 and obloq HTTP post requests are in two threads, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 may cause soft serial port read failure, it is recommended not to use MP3 too often",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "Serial MP3 may not receive infrared data, it is recommended to reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "When serial port MP3 and obloq MQTT initialization are in two threads respectively, MQTT initialization may fail. It is recommended to avoid simultaneous execution of MQTT initialization and serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "When serial MP3 and obloq HTTP put requests are in two threads, it may cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "HTTP initialization may fail when serial MP3 and obloq are initialized in two threads. It is recommended to avoid both HTTP initialization and serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "When the serial port MP3 and obloq HTTP get requests are in two threads respectively, it may cause the get request to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "When the serial port MP3 and obloq HTTP post requests are in two threads respectively, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of the serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "Serial port MP3 may cause soft serial port read failure, it is recommended not to use serial port MP3 too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving errors, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "Soft serial obloq may cause errors in infrared receiving data, so it is recommended to avoid sending messages frequently by obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "The initialization of RGB band may lead to the error of obloq receiving messages. It is recommended to avoid frequent use of RGB band",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Setting the brightness of the light belt may cause an error in obloq receiving messages. It is recommended to avoid frequent brightness adjustment",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB belt extinguish may lead to soft serial obloq receiving messages error, it is recommended to avoid frequent extinguish the belt",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB light band display may cause obloq to receive messages error, it is recommended to avoid frequent display of color",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "The display of gradient on the RGB band may cause errors in obloq receiving messages. It is recommended to avoid frequent display of gradient",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB band moving pixels may cause soft serial obloq to receive messages incorrectly",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "The RGB band loops the pixels around and may cause the soft serial obloq to receive messages out",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB ribbon display bar may cause soft serial obloq receive message error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "When soft serial obloq and led dot array initialization are carried out in two threads respectively, it may cause led dot array initialization error. It is recommended to avoid simultaneous execution of soft serial obloq and led dot array initialization",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "When soft serial obloq and led dot array display are respectively in two threads, it may cause led dot array display error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "Soft serial port sending may cause obloq reading error, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "Soft serial port obloq send may cause soft serial port read failure, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "Infrared emission may cause infrared reception of no data, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "When infrared emission may cause soft serial port reading failure, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "The initialization of RGB band may result in no infrared reception of data",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "The brightness setting of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent brightness setting of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "The RGB lamp belt is off, which may cause the infrared data can not be received",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "The moving pixel of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent moving pixel of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "Infrared reception may cause led initialization failure",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "Infrared reception may cause led display errors",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "Soft serial port sending may cause infrared data can not receive, it is recommended to avoid frequent sending soft serial port",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB band may cause soft serial port reading failure, so it is recommended to reduce the use frequency of RGB band",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Data received by soft serial port may cause led dot matrix display error",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "The sending of soft serial port may lead to the error of receiving data of soft serial port. It is recommended to avoid the execution of sending data and receiving data at the same time",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "The initialization of serial port MP3 may lead to errors in infrared receiving data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "MP3 serial port setting playing mode may cause errors in infrared receiving data, so it is recommended to avoid setting playing mode too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "Serial MP3 player may lead to infrared data errors, it is recommended to avoid switching songs too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "The volume setting of serial port MP3 may cause errors in infrared receiving data. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "Initialization of max7219 may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "Max7219 display images may cause errors in infrared received data, so it is recommended to avoid displaying images too frequently",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "The MP3 module of serial port may cause the pin interrupt cannot be triggered. It is recommended to avoid using MP3 module of serial port too often",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "The max7219 display module may cause pin interrupts not to be triggered. It is recommended to avoid using the max7219 display too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "DHT reading may lead to errors in infrared receiving messages. It is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "Ds18b20 reading may cause errors in soft external receiving messages, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5 reading may cause errors in receiving messages outside the software. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "Mp3 initialization read may cause infrared receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "The volume setting of mp3 may cause the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "Mp3 setting mode may cause errors in infrared receiving messages. It is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "Mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "Setting mode of serial port mp3 may cause error of infrared receiving message, it is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "Serial mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Setting the volume of serial port mp3 may lead to the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "Infrared emission may cause errors in infrared receiving messages, so it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "The initialization of the RGB band may lead to errors in receiving infrared messages. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving infrared messages. It is recommended to avoid setting the brightness too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB band display may cause errors in infrared receiving messages. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "Gradient discoloration of RGB lamp band display may lead to error of infrared receiving message. It is recommended to avoid displaying gradient discoloration too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Moving pixels with RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid moving pixels too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "The circular movement of pixels with the RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB ribbon display columnar may cause infrared receiving message error, it is recommended to avoid displaying columnar too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Infrared receiving messages may cause initialization failure of max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Infrared receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "Soft serial printing may cause errors in infrared receiving messages, so it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "Soft serial port obloq MQTT initialization may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "Soft serial obloq put request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "Soft serial port obloq HTTP initialization may lead to infrared receiving data errors, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "Soft serial obloq get request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "Soft serial obloq post request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "The clear display of RGB lamp band may cause errors in receiving messages in the soft infrared. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "DHT reading may lead to soft serial receiving message error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "Ds18b20 reading may cause the soft serial port to receive the message error, it is recommended to reduce the ds18b20 reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5 reading may cause errors in receiving messages by soft serial port. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "Mp3 initialization reads may result in a soft serial receiving message error, and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Setting the volume of mp3 may cause the error of receiving the message on the soft serial port. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "Mp3 setting mode may cause the soft serial port to receive the message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "Mp3 playback may lead to soft serial receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "Mp3 setting mode of serial port may cause error of receiving messages by soft serial port. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "Mp3 playing on serial port may cause errors in receiving messages on soft serial port. It is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message from soft serial port. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "Infrared emission may cause errors in receiving messages by soft serial port. It is recommended to avoid sending infrared messages too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "The initialization of the RGB band may result in an error in receiving messages from the soft serial port. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving messages from the soft serial port. It is recommended to avoid setting the brightness too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "Clear display of RGB light band may cause error of receiving message by soft serial port. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB band display may cause errors in receiving messages from the soft serial port. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB lamp tape display gradient may cause message error in soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause the soft serial port to receive messages incorrectly. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB light belt circular movement of pixels may cause the soft serial port to receive messages error, it is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB ribbon display columnar may cause the soft serial port to receive the message error, it is recommended to avoid performing display columnar too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "Soft serial receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "Soft serial receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "Soft serial printing may cause error in receiving messages from the soft serial port. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "DHT reads may cause pin interrupt triggering failure. It is recommended to reduce the DHT read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "Ds18b20 read may cause pin interrupt trigger failure, it is recommended to reduce ds18b20 read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5 reading may cause pin interrupt trigger failure. It is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Mp3 initialization reads may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "Mp3 setting volume may cause pin interrupt trigger failure. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "Mp3 setting mode may cause pin interrupt triggering failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "Mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "Serial port mp3 initialization read may cause pin interrupt trigger failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "Setting mode of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "Serial mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Setting the volume of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "Infrared emission may cause pin interrupt trigger failure, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB ribbon initialization may cause pin interrupt trigger failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Setting brightness of RGB band may cause pin interrupt trigger failure. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB strip clear display may cause pin interrupt trigger failure, it is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB tape display may cause pin interrupt trigger failure. It is recommended to avoid RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "The RGB tape display of the gradient may cause the pin interrupt trigger to fail. It is recommended to avoid displaying the gradient too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB band moving pixels may cause pin interrupt triggering failure and it is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB light belt loop move pixel may cause pin interrupt trigger failure, it is recommended to avoid too often loop move pixel",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB ribbon display bar may cause pin interrupt trigger failure. It is recommended to avoid displaying bar too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "Pin interruption may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "Pin interruption may cause max7219 to display incorrectly",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "Soft serial printing may cause pin interrupt trigger failure, it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "Soft serial port obloq MQTT initialization may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "Soft serial obloq put requests may cause pin interrupt triggering failure and it is recommended to avoid requesting data too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "Soft serial obloq HTTP initialization may cause pin interrupt triggering failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "Soft serial obloq get request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "Soft serial obloq post request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "DHT reading may lead to soft serial obloq receiving messages error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "Ds18b20 reading may cause obloq to receive messages incorrectly, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5 reading may cause error in obloq receiving messages. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "Mp3 initialization read may result in soft serial obloq receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Mp3 setting volume may result in soft serial obloq receiving message error. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "Mp3 setting mode may lead to soft serial obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "Mp3 playback may lead to soft serial obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port obloq receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "Serial port mp3 setting mode may cause soft serial port obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "Serial port mp3 player may lead to soft serial port obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message of soft serial port obloq. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving messages error, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Soft serial bloq sending messages may lead to infrared reception errors, it is recommended to avoid obloq sending messages too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB ribbon initialization may cause soft serial obloq to receive an error message, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Setting brightness of RGB light belt may cause error of obloq receiving messages. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Clear display of RGB light band may cause error of obloq receiving message. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB tape display may result in an error in obloq receiving messages. It is recommended to avoid performing RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB lamp tape display gradient may cause message error in obloq soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause an error in obloq receiving messages. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB light belt circular movement of pixels may lead to soft serial port obloq receive messages error, it is recommended to avoid too often loop movement of pixels",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB ribbon display columnar may cause soft serial obloq receive messages error, it is recommended to avoid too often display columnar",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "Soft serial obloq receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "Soft serial obloq receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "Soft serial printing may cause obloq to receive messages incorrectly. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "Soft serial port obloq sending data may cause errors in receiving data by soft serial port. It is recommended to avoid too frequent sending of data by obloq",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "Soft serial obloq sending data may cause pin interrupt cannot be triggered, it is recommended to avoid obloq sending data too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "Soft serial port obloq MQTT initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "Soft serial obloq put request may cause the soft serial port to receive data error, it is recommended to avoid too frequent requests for data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "Soft serial port obloq HTTP initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "Soft serial obloq get request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "Soft serial port obloq post request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.compatible.shapedBlock": "The new version changes point: the event program building block with its own variables is replaced by the event program building block without variables.",
    "gui.blocklyText.compatible.shapedBlock.variable": "The new version change point: change from independent program building block to variable program building block in event program",
    "gui.blocklyText.compatible.booleanToRound": "New version change point: Changed from conditional program building block to numerical program building block.",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "The new version changes point: add soft and hard serial port switch drop-down option in the program building block.",
    "gui.blocklyText.compatible.mp3.deletePin": "New version change point: Remove the pin drop-down option in the program building block.",
    "gui.blocklyText.compatible.sen0206.addDropdown": "New version change point: Add drop-down options to the program building block.",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "New version change point: Increase the pin drop-down option in the program building block.",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "New version change point: Modify the serial port drop-down option in the program building block.",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "The new version changes point: changed from the event program building block to the conditional program building block.",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "The new version changes point: delete block",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "The new version changes point: modify the square drop-down box to a circular drop-down box",
    "gui.blocklyText.pictureai.initToken": "init AI of picture[SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "get picture from local file[IMAGE]",
    "gui.blocklyText.pictureai.getVideoImage": "get picture video shortcut",
    "gui.blocklyText.pictureai.webPicture": "get picture from website[TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH]save camera shortcut to local[TEXT]",
    "gui.blocklyText.pictureai.face": "identify picture[TEXT] contain face",
    "gui.blocklyText.pictureai.word": "identify picture[TEXT] contain [SELECT]",
    "gui.blocklyText.pictureai.picture": "identify picture[TEXT] contain[SELECT]",
    "gui.blocklyText.pictureai.gesture": "identify picture[TEXT] contain gestture",
    "gui.blocklyText.pictureai.TOPIC_W_A": "words",
    "gui.blocklyText.pictureai.TOPIC_W_B": "number",
    "gui.blocklyText.pictureai.TOPIC_W_C": "car number",
    "gui.blocklyText.pictureai.TOPIC_W_D": "handwriting",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "left_ankle",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "left_ear",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "left_elbow",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "left_eye",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "left_hip",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "left_knee",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "left_mouth_corner",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "left_shoulder",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "left_wrist",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "neck",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "nose",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "right_ankle",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "right_ear",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "right_elbow",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "right_eye",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "right_hip",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "right_knee",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "right_mouth_corner",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "right_shoulder",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "right_wrist",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "top_head",
    "gui.blocklyText.pictureai.initUserToken": "switch to a separate account[MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "identify picture[TEXT] contain body point",
    "gui.blocklyText.pictureai.faceContrast": "contrast face in picture [TEXT] with picture [TEXT2]",
    "gui.blocklyText.pictureai.faceMerge": "merge face in picture [TEXT] and picture [TEXT2]",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "image body",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "general purpose objects and scenes",
    "gui.blocklyText.pictureai.TOPIC_P_A": "plant",
    "gui.blocklyText.pictureai.TOPIC_P_B": "animal",
    "gui.blocklyText.pictureai.TOPIC_P_C": "flower",
    "gui.blocklyText.pictureai.TOPIC_P_D": "vegetables",
    "gui.blocklyText.pictureai.bodyPointSuccess": "Successful identification of key points in the human body?",
    "gui.blocklyText.pictureai.bodyPointInfo": "get BodyPoint [BODY] [XY]coordinate",
    "gui.blocklyText.pictureai.noPower": "Please check if the account has opened the feature",
    "gui.blocklyText.pictureai.pictureError": "the picture is not in the correct format",
    "gui.blocklyText.pictureai.noPicture": "please select picture first",
    "gui.blocklyText.pictureai.noVideo": "Please make sure that the camera is connected properly and that no other software is occupied, and turn on the camera, or restart the software",
    "gui.blocklyText.pictureai.videoContainer": "use [CON] show camera sceen",
    "gui.blocklyText.pictureai.TOPIC_stage": "stage",
    "gui.blocklyText.pictureai.TOPIC_popup": "popup",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "New version change point: There are program building blocks removed.",
    "gui.blocklyText.pictureai.gestureList": "gesture[TEXT]",
    "gui.blocklyText.pictureai.networkError": "Network error, please check the network connection",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "Successful face recognition?",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "face recognition results [TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "face num",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "age",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "beauty",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "gender",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "glasses",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "type",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "expression",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "face probability",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "left",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "top",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "width",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "height",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "landmark",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "occlusion",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "blur",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "completeness",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "human",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "cartoon",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Μπροστά",
    "gui.blocklyText.maqueenPlus.Backward": "Πίσω",
    "gui.blocklyText.maqueenPlus.left": "Αριστερά",
    "gui.blocklyText.maqueenPlus.right": "Δεξιά",
    "gui.blocklyText.maqueenPlus.all": "Όλα",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "κίνησε κινητήρα [DIR] με ταχύτητα [SPEED] [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "σταμάτησε κινητήρα [DIR]",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] speed compensation [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "διάβασε ταχύτητα κινητήρα [DIR]",
    "gui.blocklyText.maqueenPlus.readMoterDir": "read [DIR] motor direction (0:stop 1:forward 2:back)",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "set [DIR] RGB show [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "read the sensation sensor [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "read the sensation sensor [INDEX] grayscale value",
    "gui.blocklyText.maqueenPlus.getVersion": "get version",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "read ultrasonic sensor trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "όταν ληφθούν [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "ασύρματα δεδομένα",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "paint.paintEditor.hue": "Χρώμα",
    "paint.paintEditor.saturation": "Κορεσμός",
    "paint.paintEditor.brightness": "Φωτεινότητα",
    "gui.comingSoon.message1": "Μην ανησυχείτε, το επεξεργαζόμαστε {emoji}",
    "gui.comingSoon.message2": "Έρχεται Σύντομα...",
    "gui.comingSoon.message3": "Το δουλεύουμε {emoji}",
    "paint.paintEditor.fill": "Γέμισμα",
    "paint.paintEditor.costume": "Ενδυμασία",
    "paint.paintEditor.group": "Ομαδοποίηση",
    "paint.paintEditor.ungroup": "Διαχωρισμός",
    "paint.paintEditor.undo": "Αναίρεση",
    "paint.paintEditor.redo": "Επανάληψη",
    "paint.paintEditor.forward": "Προς τα εμπρός",
    "paint.paintEditor.backward": "Προς τα πίσω",
    "paint.paintEditor.front": "Προσκήνιο",
    "paint.paintEditor.back": "Παρασκήνιο",
    "paint.paintEditor.more": "Περισσότερα",
    "paint.modeTools.brushSize": "Μέγεθος",
    "paint.modeTools.eraserSize": "Μέγεθος γόμας",
    "paint.modeTools.copy": "Αντιγραφή",
    "paint.modeTools.paste": "Επικόλληση",
    "paint.modeTools.delete": "Διαγραφή",
    "paint.modeTools.curved": "Καμπύλες",
    "paint.modeTools.pointed": "Μυτερό",
    "paint.modeTools.thickness": "Πάχος",
    "paint.modeTools.flipHorizontal": "Οριζόντια Αναστροφή",
    "paint.modeTools.flipVertical": "Κατακόρυφη Αναστροφή",
    "paint.modeTools.filled": "Γεμάτο",
    "paint.modeTools.outlined": "Με περίγραμμα",
    "paint.paintEditor.bitmap": "Μετατροπή σε Ψηφιοαπεικόνιση (Bitmap)",
    "paint.paintEditor.vector": "Μετατροπή σε Διάνυσμα (Vector)",
    "paint.paintEditor.stroke": "Περίγραμμα",
    "paint.brushMode.brush": "Πινέλο",
    "paint.eraserMode.eraser": "Γόμα",
    "paint.fillMode.fill": "Γέμισμα",
    "paint.lineMode.line": "Γραμμή",
    "paint.ovalMode.oval": "Κύκλος",
    "paint.rectMode.rect": "Ορθογώνιο",
    "paint.reshapeMode.reshape": "Αλλαγή σχήματος",
    "paint.roundedRectMode.roundedRect": "Στρογγυλεμένο Ορθογώνιο",
    "paint.selectMode.select": "Επιλογή",
    "paint.textMode.text": "Κείμενο",
    "paint.colorPicker.swap": "Αντιμετάθεση",
    "gui.dialog.notConnectDevice": "Δεν συνδέθηκε συσκευή",
    "gui.dialog.prompt": "Σημειώσεις",
    "gui.dialog.openPort": "Άνοιγμα θύρας",
    "gui.dialog.error": "Λάθος",
    "gui.dialog.notSelectDevice": "Δεν επιλέχθηκε συσκευή. Παρακαλούμε κάντε κλι στο [Extension] κάτω αριστερά για να επιλέξετε συσκευή που θέλετε να συνδέσετε",
    "gui.dialog.connectDeviceError1": "Σύνδεση συσεκυής(",
    "gui.dialog.connectDeviceError2": ")Failed, please follow the steps below:</br> *Replace the usb port and re-plug the usb cable</br> *Check if it is connected",
    "gui.dialog.connectDeviceError3": "Main Control Board</br> * Join Official Communications Group (671877416) To Report Issues",
    "gui.dialog.calibration1": "</br> *Please turn",
    "gui.dialog.calibration2": "Ρύθμιση του κύριου </br>",
    "gui.dialog.runAsManager": "Please close Mind+ and right click on the software icon and select \"Run as administrator\". After starting, select this function again.",
    "gui.dialog.runErrorForVortex": "System not working properly, disconnect Vortex",
    "gui.dialog.runError": "System not working properly",
    "gui.dialog.close": "Close",
    "gui.dialog.variableNameSpecialCharacters": "variable name %1 cannot contain special characters:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "function name %1 cannot contain special characters:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "function arguments %1 cannot contain special characters:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "και",
    "gui.dialog.yes": "Ναι",
    "gui.dialog.no": "Όχι",
    "gui.dialog.maxReset": "Delete the codes in editing area and load the factory setting codes?",
    "gui.dialog.feedbackCannotEmpty": "Please fill in the feedback.",
    "gui.dialog.noInstallCompiler": "No compiler installed ",
    "gui.dialog.install": "Install online",
    "gui.dialog.cancle": "Cancel",
    "gui.dialog.installingCompiler": "installing compiler",
    "gui.dialog.uploading": "Φόρτωση",
    "gui.dialog.changeLanuage": "Switching the language will clear the current program. Are you sure you want to continue?",
    "gui.dialog.boardNotSupportMpy": "current board: %1 not support micopython",
    "gui.dialog.loadBlankItem": "Unsupported item</br> is reloading a blank item",
    "gui.dialog.switchBaudError": "Error occurred in switching baud rate</br>",
    "gui.dialog.turnMicrobitCompass": "Hold the micro:bit horizontally and tilt it on the spot trying to fill in the screen to calibrate its compass",
    "gui.dialog.operationShow": "Operation Demonstration",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "computer",
    "gui.dialog.delete": "διαγραφή",
    "gui.dialog.sureDeleteFile": "Θέλετε να διαγράψετε το αρχείο [%2] στο %1?",
    "gui.dialog.sureDeleteFolder": "Θέλετε να διαγράψετε το φάκελλο [%2] στο %1?",
    "gui.dialog.uncorrectPath": "Not select the correct path",
    "gui.dialog.laterDownLoad": "Update Later",
    "gui.dialog.dwnLoadWebsite": "Download Website",
    "gui.dialog.laterUpdate": "Εγκατάσταση Αργότερα",
    "gui.dialog.update": "Εγκατάσταση",
    "gui.dialog.getUpdateMsgError": "Error occurred in getting updated message",
    "gui.dialog.ok": "Εντάξει",
    "gui.dialog.closeCodeBoxes": "Are you sure you want to close %1 code boxes?",
    "gui.dialog.emailError": "E-mail format is incorrect!",
    "gui.dialog.emailEmpty": "E-mail can not be empty!",
    "gui.dialog.bigFilePrompt": "The file is big, please be patient and wait, thank you!",
    "gui.dialog.successFeedbackPrompt1": "Thanks for your feedback, the page will be reloaded after 3 seconds.",
    "gui.dialog.successFeedbackPrompt2": "Your feedback has been submitted. Please submit it after a few seconds.",
    "gui.dialog.failedFeedbackPrompt": "Submit falied. we are so sorry for the inconvenience caused. Please visit our online forum to report your feedback at https://www.dfrobot.com/forum/, thank you!",
    "gui.dialog.successFeedbackPrompt3": "Thanks for your feedback",
    "gui.dialog.forcedCloseSoftWare": "An error occurred in saving the file, force close the software?",
    "gui.dialog.saveSuccess": "The project is saved",
    "gui.dialog.noSelectTruePath": "Not select the correct path",
    "gui.dialog.newItemContent": "You will create a blank project to overwrite the current project, are you sure to create it?",
    "gui.dialog.deleteBlocksError": "Failed to delete %1 block, please delete it manually",
    "gui.dialog.netIsConnect": "Please check if the network is connected",
    "gui.dialog.needToUpdate": "Update the compiler to the latest version: %1?",
    "gui.dialog.cantUseMpythonInArduinoC": "No compiler installed, you can't use mpython under Arduino C.If the computer does not have a network, you can download the 'Compiler offline plug-in package' on the official website.",
    "gui.dialog.enterMpyMode": "enter micropython mode",
    "gui.dialog.runHardware": "Connect the device first, then operate the hardware",
    "gui.dialog.sureDelete": "Are you sure you want to delete this?",
    "gui.dialog.notSupportWebGL": "An error occurred in loading the stage, this may happen when your computer cannot support WebGL,</br> Refer to the details shown below: </br> %1 </br>QQ group contact us: 671877416",
    "gui.dialog.boardIsnotMPython": "The current main control board connected is not mPython, please connect to mPython",
    "gui.dialog.swdMicrobitUpload": "Your computer must use the SWD to burn micro:bit, please run Mind+ with administrator privileges.",
    "gui.dialog.openPortError": "open port %1 error: %2",
    "gui.dialog.pressedA": "Please press and hold button A, do not release.",
    "gui.dialog.unpressedA": "Please release button A",
    "gui.dialog.unsupportProject": "Unsupported item</br> is reloading a blank item.",
    "gui.dialog.openProjectError": "Projects made in Scratch mode must be opened in Scratch mode</br> Please check the current mode.",
    "gui.dialog.installCompilerError1": "Download failed, you can download the offline installer or try it again",
    "gui.dialog.installCompilerOffline": "Download offline Compiler",
    "gui.dialog.installCompilerError2": "Verification error, you can download the offline installer or try to download again",
    "gui.dialog.installCompilerError3": "Installation error, you can download the offline installer or try to download again",
    "gui.dialog.installCompilerSuccess": "The compiler has been installed, you can use Esp32 under ArduinoC",
    "gui.dialog.sureAdapter": "The adapter was not found, please confirm whether the adapter is connected",
    "gui.dialog.checkPort": "Failed to open the serial port, please check if the serial port is occupied.",
    "gui.dialog.changeBoard": "Switching board will clear the current program. Are you sure you want to continue?",
    "gui.crashMessage.somethingWrong": "Ουπς! Κάτι πήγε στραβά!",
    "gui.crashMessage.reload": "Επαναφόρτωση",
    "gui.crashMessage.submit": "Αποστολή",
    "gui.crashMessage.attachment": "Συνημμένο",
    "gui.crashMessage.errFeedbackNote": "You can write error messages or suggestions here, Mind+ will get better with your join.",
    "gui.crashMessage.promptContent": "We are so sorry, Sorry, it seems that Mind + has crashed. You can send this error to the Mind + team on the right side, thank you very much! Please refresh your page to try again.",
    "gui.dialog.isSaveProject": "Να αποθηκευθεί το έργο;",
    "gui.dialog.save": "Αποθήκευση",
    "gui.dialog.notSave": "Δεν αποθηκεύθηκε",
    "gui.dialog.continue": "Συνέχεια",
    "gui.dialog.downLoadUpdate": "Κατέβασε Αναβάθμιση",
    "gui.dialog.variableIsNull": "Το όνομα της μεταβλητής δεν μπορεί να είναι κενό",
    "gui.dialog.listNameIsNull": "Το όνομα λίστας δεν μπορεί να είναι κενό",
    "gui.dialog.variableIsRepeat": "Διπλό όνομα μεταβλητής, παρακαλώ αλλάξτε όνομα",
    "gui.dialog.pictureAINotNull": "δεν μπορεί να είναι κενό",
    "gui.dialog.pictureAIInitServer": "please initialize the server first",
    "gui.dialog.pictureAIInitServerError": "Error initializing the server, please check the account number and secret key",
    "gui.dialog.versionPrompt": "The current project version「%1」",
    "gui.dialog.versionGreater": "The project version is higher than the current software version, project version 「%1」",
    "gui.dialog.noModulePrompt": "There are no small modules under the current classification. See help for details.",
    "gui.dialog.firstSelectBoard": "Παρακαλούμε επιλέξτε πλακέτα ή κιτ πρώτα",
    "gui.dialog.loadThirdModuleError": "Load module error",
    "gui.dialog.loaclaeLoadThirdModuleError": "Error loading module locally",
    "gui.dialog.downloadFileError": "Download file error",
    "gui.dialog.noSearchModules": "No related modules found, please check the search path",
    "gui.dialog.networkError": "Πρόβλημα δικτύου",
    "gui.dialog.saveThirdModulesError": "Error saving user library",
    "gui.dialog.isContinueUpdateThirdModule": "There are currently the same user extension libraries. Continue to update will overwrite the current user library. Do you want to continue?",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "Modified the field 「%2」 of %1, which needs to be manually reloaded in the extension library. This refresh is canceled",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "Error parsing %1, this refresh will continue, but the content in 「%2」 will not be updated",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "The shape of the building block or the type of the input box is modified. Please manually delete the highlighted building block in the programming area, and then refresh it. This refresh is cancelled.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "Modification of the shape of the building block or the type of the input box, please manually delete the highlighted building block in the programming area before loading, and the loading is canceled.",
    "gui.dialog.thirdex.filesEmpty": "The files field cannot be empty, which may cause related files to be lost. Please check the imported user library files!",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "en": {
    "gui.gui.project": "Project",
    "gui.gui.newItem": "New Project",
    "gui.gui.load": "Load Project",
    "gui.gui.save": "Save Project",
    "gui.gui.saveAs": "Save As",
    "gui.gui.saveAsMp": "Save As MP Project",
    "gui.gui.saveAsSb3": "Save As SB3 Project",
    "gui.gui.cacheList": "Cache List",
    "gui.gui.inCache": "In Cache",
    "gui.gui.learn": "Learning",
    "gui.gui.clearRecord": "Clear Record",
    "gui.gui.onlineLearn": "Official Documents",
    "gui.gui.onlineForum": "Online Forum",
    "gui.gui.videoTutorial": "Video Tutorials",
    "gui.gui.offlineLearn": "Example Programs",
    "gui.menuBar.turboModeOff": "Turn OFF Turbo Mode",
    "gui.menuBar.turboModeOn": "Turn ON Turbo Mode",
    "gui.menuBar.edit": "Edit",
    "gui.menuBar.restoreSprite": "Restore Sprite",
    "gui.menuBar.restoreSound": "Restore Sound",
    "gui.menuBar.restoreCostume": "Restore Costume",
    "gui.menuBar.restoreBackdrop": "Restore Backdrop",
    "gui.editorMind.restore": "Restore",
    "gui.turboMode.active": "Turbo Mode",
    "gui.gui.connectDevice": "Connect Device",
    "gui.gui.disconnectDevice": "Disconnect Device",
    "gui.gui.installSerialportDriver": "Install SerialPort Driver",
    "gui.gui.openInstallSerialportDriverCourse": "View the tutorial for driver installation",
    "gui.gui.openDM": "Open Device Manager",
    "gui.gui.restoreSetting": "Restore device initial settings",
    "gui.gui.updateTips": "Update Tips",
    "gui.gui.newVersion": "The Latest Version",
    "gui.gui.downloadUpdate": "Download to update",
    "gui.gui.versionUpdate": "Update the version",
    "gui.gui.isCheckMd5": "Verifying file",
    "gui.gui.downloading": "Checking Updator",
    "gui.gui.updateError": "Update Error",
    "gui.setting.feedbackMenu": "Feedback",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.gui.python": "Python",
    "gui.setting.officialWebsit": "Official Website",
    "gui.setting.officialQQGroup": "Official QQ Group",
    "gui.setting.wechatOfficialAccounts": "WeChat Official Accounts",
    "gui.setting.currentVersion": "Current Version",
    "gui.setting.checkForUpdate": "Check for Updates",
    "gui.setting.remindWhenUpdate": "Download when updated",
    "gui.setting.currentIsLatest": "The current version is the latest one",
    "gui.setting.latestVersion": "The latest version",
    "gui.setting.download": "Skip to download web",
    "gui.setting.language": "Language",
    "gui.setting.uploadCsv": "upload xlsx",
    "gui.setting.theme": "Theme",
    "gui.setting.feedback": "Contact",
    "gui.setting.email": "E-mail",
    "gui.setting.opinionFeedback": "Feedback",
    "gui.variableMc.variableName": "variable name",
    "gui.variableMc.variableOff": "cancel",
    "gui.variableMc.variableOn": "confirm",
    "gui.variableMc.newVariable": "new variable",
    "gui.setting.helpUsTranslate": "Help Us Translate",
    "gui.setting.checkUpdate": "Check Update",
    "gui.setting.ok": "Ok",
    "gui.setting.languageSetting": "Language",
    "gui.setting.themeSetting": "Theme",
    "gui.setting.cacheSetting": "Cache Setting",
    "gui.setting.versionUpdate": "Version Update",
    "gui.setting.connectUsTitle": "Contact Us",
    "gui.setting.uploadAttachment": "Upload Attachment",
    "gui.setting.displayTitle": "Display Setting",
    "gui.setting.fontSizeSetting": "font-size",
    "gui.setting.restartEffect": "restart effect",
    "gui.setting.fontSizeLarge": "Large",
    "gui.setting.fontSizeMiddle": "Middle",
    "gui.setting.fontSizeSmall": "Small",
    "gui.setting.onlineRepair": "online repair tool, click open >",
    "gui.setting.cacheSwitch": "Cache (open/close)",
    "gui.setting.freeCache": "Free cache",
    "gui.setting.spaceCache": "Fixed interval",
    "gui.setting.cacheWay": "Cache Way",
    "gui.setting.interval": "interval",
    "gui.setting.freeTime": "Free time",
    "gui.setting.second": "second",
    "gui.setting.minute": "minute",
    "gui.gui.code": "Code",
    "gui.menubar.cloudProject": "Cloud Project",
    "gui.menubar.login": "Login",
    "gui.menubar.signout": "Sign out",
    "gui.menubar.saveToCloud": "Save to Cloud",
    "gui.menubar.cloudServer": "Cloud",
    "gui.setting.cleanCache": "Data Cleaning",
    "gui.setting.programCache": "Project Cache",
    "gui.setting.configJson": "Configuration File",
    "gui.setting.pythonEnv": "Python Environment",
    "gui.setting.backpack": "Schoolbag File",
    "gui.setting.compile": "Compile Information",
    "gui.setting.thirdExt": "Extended User Library",
    "gui.setting.dashboard": "IoT Dashboard",
    "gui.setting.cleanCacheBtn": "Clear Selection",
    "gui.setting.cleanCachePrompt": "Data cannot be restored after clearing, are you sure to clear it?",
    "gui.setting.cleanPythonEnvPrompt": "In order to avoid the exception of other windows caused by the Python environment cleaning, please make sure that only one Mind+ software window is opened before cleaning.",
    "gui.setting.cleanPyEnvOnPythonPrompt": "In Python mode, you need to reset the environment after cleaning resources. This process needs to wait for a while. And in order to avoid cleaning resources causing other window exceptions, please ensure that only one Mind+ software window is opened before cleaning. ",
    "gui.setting.cleanDashboardPrompt": "All projects in the Dashboard are cleaned and cannot be recovered.",
    "gui.setting.expVersion": "Experimental Version",
    "gui.setting.expUpdateTips": "[Update Notes]: This version is an early adopter version that has not been officially released. Some functions may be unstable. Please confirm before downloading;",
    "gui.setting.cleanConfigSuccess": "Data cleaning is complete, please restart Mind+.",
    "gui.setting.cleanConfigError": "An error occurred during data cleaning, please try again",
    "gui.setting.cleanPartError": "Error cleaning [PART]...",
    "gui.setting.cleaningPyEnv": "Cleaning the Python environment...",
    "gui.setting.cleanPythonEnvError": "Python environment resource release failed, you can close the software, manually delete the folder [PATH] , and then reopen the software",
    "gui.setting.multiWindowSetting": "Multi-Open Software",
    "gui.setting.multiWindowSettingTips": "It is recommended to disable the function for computers of weaker performance to avoid system lag caused by opening multiple apps at the same time.",
    "gui.setting.multiWindowTrue": "Open",
    "gui.setting.multiWindowFalse": "Close",
    "gui.setting.gpu": "GPU acceleration",
    "gui.setting.gpu.tips": "If there are no exceptions, keep it enabled by default.",
    "gui.setting.gpu.true": "Open",
    "gui.setting.gpu.false": "Close",
    "gui.setting.systemSetting": "System Settings",
    "gui.backpack.header": "Backpack",
    "gui.backpack.errorBackpack": "Error loading backpack",
    "gui.backpack.loadingBackpack": "Loading...",
    "gui.backpack.more": "More",
    "gui.backpack.emptyBackpack": "The backpack is empty now. Try dragging some command blocks into it.",
    "gui.gui.blocks": "Blocks",
    "gui.gui.costumesTab": "Costumes",
    "gui.gui.soundsTab": "Sounds",
    "gui.gui.backdropsTab": "Backdrops",
    "gui.gui.addExtension": "Extensions",
    "gui.costumeTab.addCostumeFromLibrary": "Choose a Sprite",
    "gui.costumeLibrary.chooseACostume": "Choose a Costume",
    "gui.costumeTab.addBackdropFromLibrary": "Choose a Backdrop",
    "gui.costumeTab.addBlankCostume": "Paint",
    "gui.costumeTab.addSurpriseCostume": "Surprise",
    "gui.costumeTab.addFileBackdrop": "Upload Backdrop",
    "gui.costumeTab.addFileCostume": "Upload",
    "gui.costumeTab.addCameraCostume": "Camera",
    "gui.sliderModal.min": "Minimum value",
    "gui.sliderModal.max": "Maximum value",
    "gui.sliderModal.title": "Change Slider Range",
    "gui.soundEditor.trim": "Trim",
    "gui.soundEditor.stop": "Stop",
    "gui.soundEditor.sound": "Sound",
    "gui.soundEditor.play": "Play",
    "gui.soundEditor.save": "Save",
    "gui.soundEditor.undo": "Undo",
    "gui.soundEditor.redo": "Redo",
    "gui.soundEditor.faster": "Faster",
    "gui.soundEditor.slower": "Slower",
    "gui.soundEditor.echo": "Echo",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Louder",
    "gui.soundEditor.softer": "Softer",
    "gui.soundEditor.reverse": "Reverse",
    "gui.soundTab.recordSound": "Record",
    "gui.soundTab.addSoundFromLibrary": "Choose a Sound",
    "gui.soundTab.surpriseSound": "Surprise",
    "gui.soundTab.fileUploadSound": "Upload Sound",
    "gui.soundTab.addSound": "Add Sound",
    "gui.controls.stop": "Stop",
    "gui.controls.go": "Go",
    "gui.controls.customFirmware": "Custom Firmware",
    "gui.controls.defaultFirmware": "Official Firmware",
    "gui.controls.clearLocaleFirmware": "Clear Records",
    "gui.controls.checkForUpdates": "Check For Updates",
    "gui.controls.alreadyLastVersion": "Already the latest version",
    "gui.controls.uploadFirmware": "Ipload",
    "gui.gui.startPython": "Start Python",
    "gui.gui.stopPython": "Stop Python",
    "gui.controls.fullScreenControl": "Full Screen Control",
    "gui.gui.stageSizeLarge": "Large Screen Contorl",
    "gui.gui.stageSizeSmall": "Small Screen Control",
    "gui.gui.stageSizeNostage": "Stage Size Toggle - No Stage",
    "gui.gui.stageSizeFull": "Stage Size Toggle - Full Screen",
    "gui.stageHeader.stageSizeUnFull": "Exit the Full Screen",
    "gui.sprite.sprite": "Sprite",
    "gui.SpriteInfo.show": "Show",
    "gui.SpriteInfo.size": "Size",
    "gui.sprite.direction": "Direction",
    "gui.sprite.rotation": "Rotation",
    "gui.directionPicker.rotationStyles.allAround": "All Around",
    "gui.directionPicker.rotationStyles.leftRight": "Left/Right",
    "gui.directionPicker.rotationStyles.dontRotate": "Do not rotate",
    "gui.spriteSelectorItem.contextMenuDuplicate": "Copy",
    "gui.spriteSelectorItem.contextMenuDelete": "Delete",
    "gui.spriteSelectorItem.contextMenuExport": "Export",
    "gui.sprite.addSpriteFromLibrary": "Sprite Library",
    "gui.spriteSelector.addSpriteFromPaint": "Paint",
    "gui.spriteSelector.addSpriteFromSurprise": "Surprise",
    "gui.spriteSelector.addSpriteFromFile": "Upload Sprite",
    "gui.sprite.addSpriteFromCamera": "Camera",
    "gui.stageSelector.stage": "Stage",
    "gui.stageSelector.backdrops": "Backdrop",
    "gui.stage.addBackdropFromLibrary": "Choose a Backdrop",
    "gui.stageSelector.addBackdropFromPaint": "Paint",
    "gui.stageSelector.addBackdropFromSurprise": "Surprise",
    "gui.stageSelector.addBackdropFromFile": "Upload Backdrop",
    "gui.stage.addBackdropFromCamera": "Camera",
    "gui.modal.help": "Help",
    "gui.modal.save": "Save",
    "gui.modal.run": "Run",
    "gui.modal.continue": "Continue",
    "gui.modal.paused": "Paused",
    "gui.modal.back": "Back",
    "gui.extension.kit": "Kit",
    "gui.extension.kitTitle": "Select Kit",
    "gui.extension.board": "Board",
    "gui.extension.boardTitle": "Select Board",
    "gui.extension.spreadBoard": "Shield",
    "gui.extension.spreadBoardTitle": "Select Shield",
    "gui.extension.sensor": "Sensor",
    "gui.extension.sensorTitle": "Select Sensor",
    "gui.extension.actuator": "Actuator",
    "gui.extension.actuatorTitle": "Select Actuator",
    "gui.extension.communicationModule": "Communication",
    "gui.extension.communicationModuleTitle": "Select Communication",
    "gui.extension.display": "Display",
    "gui.extension.displayTitle": "Select Display",
    "gui.extension.function": "Function",
    "gui.extension.functionTitle": "Select Function",
    "gui.extension.internetService": "Internet",
    "gui.extension.internetServiceTitle": "Select Internet",
    "gui.extension.thirdExpansion": "User-Ext",
    "gui.extension.thirdExpansionTitle": "Select user-expansion",
    "gui.extension.arduinContentLabel": "Select Device",
    "gui.extension.openUserExFile": "Open the User-Ex file on your computer",
    "gui.extension.importUserEx": "Import User-Ex",
    "gui.extension.unavailable": "Unavailable",
    "gui.extension.thirdModuleSearch": "Search or enter project URL",
    "gui.extension.thirdModulesHelp": "Note: The modules in the user library are made by Mind + enthusiasts, {clickHelp} view {tutorial} and {list}",
    "gui.extension.thirdTutorial": "development tutorial",
    "gui.extension.thirdList": "User-Ext list",
    "gui.extension.libaryOffice": "Official Library",
    "gui.extension.libaryPinpong": "pinpong",
    "gui.library.filterPlaceholder": "Search",
    "gui.libraryTags.all": "All",
    "gui.libraryTags.animals": "Animals",
    "gui.libraryTags.dance": "Dance",
    "gui.libraryTags.effects": "Effects",
    "gui.libraryTags.fantasy": "Fantasy",
    "gui.libraryTags.fashion": "Fashion",
    "gui.libraryTags.food": "Food",
    "gui.libraryTags.festival": "Festival",
    "gui.libraryTags.traffic": "Traffic",
    "gui.libraryTags.indoors": "Indoors",
    "gui.libraryTags.loops": "Loops",
    "gui.libraryTags.music": "Music",
    "gui.libraryTags.notes": "Notes",
    "gui.libraryTags.outdoors": "Outdoors",
    "gui.libraryTags.patterns": "Patterns",
    "gui.libraryTags.people": "People",
    "gui.libraryTags.percussion": "Percussion",
    "gui.libraryTags.space": "Space",
    "gui.libraryTags.sports": "Sports",
    "gui.libraryTags.underwater": "Underwater",
    "gui.libraryTags.voice": "Voice",
    "gui.libraryTags.wacky": "Wacky",
    "gui.libraryTags.animation": "Animation",
    "gui.libraryTags.art": "Arts",
    "gui.libraryTags.games": "Games",
    "gui.libraryTags.stories": "Stories",
    "gui.libraryTags.letters": "Letters",
    "gui.libraryTags.mind": "Mind+",
    "gui.libraryTags.natural": "nature",
    "gui.libraryTags.button": "button",
    "gui.libraryTags.dialog": "dialog",
    "gui.libraryTags.epidemicPrevention": "epidemic prevention",
    "gui.libraryTags.numberAndLetter": "number and letter",
    "gui.libraryTags.gameComponents": "game components",
    "gui.extension.backdropLib": "Choose a Backdrop",
    "gui.soundLibrary.chooseASound": "Choose a Sound",
    "gui.SpriteInfo.spritePlaceholder": "Name",
    "gui.extension.spriteLib": "Choose a Sprite",
    "gui.gui.unselectedSerialport": "No Device Connected",
    "gui.gui.unConnectedDev": "No Device Connected",
    "gui.gui.pythonMode": "Python Mode",
    "gui.gui.burnFirmware": "Burnning Firmware Again",
    "gui.gui.burnFirmwareError": "Firmware Burnning Error",
    "gui.gui.connected": "Connected",
    "gui.gui.failedConnected": "Failed to Connect",
    "gui.gui.connecting": "Connecting...",
    "gui.cards.all-how-tos": "All How-Tos",
    "gui.cards.how-tos": "Tutorials",
    "gui.cards.remove": "Remove",
    "gui.cards.more-things-to-try": "Try more!",
    "gui.cards.see-more": "See More",
    "gui.loader.message1": "Creating Blocks …",
    "gui.loader.message2": "Loading Sprites …",
    "gui.loader.message3": "Loading Sounds …",
    "gui.loader.message4": "Loading Extensions …",
    "gui.loader.message5": "Herding Mind+ …",
    "gui.loader.message6": "Transmitting Nanos …",
    "gui.loader.message7": "Inflating Gobos …",
    "gui.loader.message8": "Preparing emojis …",
    "gui.loader.headline": "Loading Project",
    "gui.cameraModal.cameraModalTitle": "Take a Photo",
    "gui.cameraModal.loadingCameraMessage": "Loading Camera...",
    "gui.cameraModal.permissionRequest": "We need your permission to use your camera",
    "gui.cameraModal.retakePhoto": "Retake a Photo",
    "gui.cameraModal.save": "Save",
    "gui.cameraModal.takePhoto": "Take Photo",
    "gui.cameraModal.loadingCaption": "Loading...",
    "gui.cameraModal.enableCameraCaption": "Enable Camera",
    "gui.recordModal.title": "Record Sound",
    "gui.recordStep.recordByClickBtn": "Begin recording by clicking the button below",
    "gui.recordStep.permissionMicrophone": "We need your permission to use your microphone",
    "gui.recordStep.record": "Record",
    "gui.recordStep.stopRecord": "Stop recording",
    "gui.playbackStep.stopMsg": "Stop",
    "gui.playbackStep.playMsg": "Play",
    "gui.playbackStep.loadingMsg": "Loading...",
    "gui.playbackStep.saveMsg": "Save",
    "gui.playbackStep.reRecordMsg": "Re-record",
    "gui.webglModal.label": "Your Browser Does Not Support WebGL",
    "gui.webglModal.descriptionMind": "Unfortunately your computer is {webGlLink}. Mind+ needs to run WebGL, please try {restartMindplus}（{clickToRestart}）, if the problem persists {updateGpuDriver} or {gotoSettingGPU}",
    "gui.webglModal.restartMindplus": "Restart Mind+",
    "gui.webglModal.clickToRestart": "Click here to restart",
    "gui.webglModal.webgllink": "Does not support WebGL",
    "gui.webglModal.updateGpuDriver": "Please try to update the graphics card driver",
    "gui.webglModal.gotoSettingGPU": "Attempt to enable GPU acceleration in the settings.",
    "gui.webglModal.ok": "Ok",
    "gui.extension.scratchExampleLib": "Scratch Example Library",
    "gui.extension.pythonExampleLib": "Python Example Library",
    "gui.extension.arduinoExampleLib": "Arduino Example Library",
    "gui.prompt.cancel": "Cancel",
    "gui.prompt.ok": "OK",
    "gui.extension.makeBlock": "Make a Block",
    "gui.customProcedures.addAnInputNumberText": "Add an input",
    "gui.customProcedures.addAnInputBoolean": "Add an input",
    "gui.customProcedures.numberTextType": "number or text",
    "gui.customProcedures.textType": "text",
    "gui.customProcedures.numberType": "number",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Add a label",
    "gui.customProcedures.runWithoutScreenRefresh": "Run without screen refresh",
    "gui.customProcedures.cancel": "Cancel",
    "gui.customProcedures.ok": "OK",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "Vortex educational robot from DFRobot",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "Explorer D1 educational robot from DFRobot",
    "gui.extension.arduinounoR3.name": "ArduinounoR3",
    "gui.extension.arduinounoR3.description": " Wuhan Maker Course custom Arduino Uno main control board kit.",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "Max:bot Robot based on micro:bit",
    "gui.extension.max.name": "MAX",
    "gui.extension.max.description": "MAX（ROB0137）robot based on Arduino",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "Motor:bit motor and servo expansion board",
    "gui.extension.maqueen.name": "Maqueen",
    "gui.extension.maqueen.description": "micro:Maqueen robot based on micro:bit",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.microbit.description": "Connect your projects with the physical world.",
    "gui.extension.calliope.description": "Get creative and start coding!",
    "gui.extension.calliope.name": "CALLIOPE mini",
    "gui.extension.spread.name": "micro:bit & mPython expansion board",
    "gui.extension.spreadboson.name": "micro:bit & mPython expansion board",
    "gui.extension.microTouch.name": "TouchKey",
    "gui.extension.microNaturalScience.name": "micro:naturalScience",
    "gui.extension.microTouch.description": "Integrated 4×4 keyboard and motor servo drive expansion board",
    "gui.extension.microNaturalScience.description": "Micro:bit expansion board with multiple environmental sensors",
    "gui.extension.microNaturalScienceV2.description": "Micro:bit learning product integrating abundant natural environment related sensors",
    "gui.extension.microIoT.name": "micro:IoT",
    "gui.extension.microIoT.description": "Integrated display and Internet of things micro:bit extension board",
    "gui.extension.microIoTCloud.name": "micro:IoT Board for Cloud",
    "gui.extension.microIoTCloud.description": "The micro:bit expansion board supports WiFi card interconnection, providing an excellent solution for Internet of Things classroom teaching",
    "gui.extension.spreadMaqueen.name": "maqueen extension",
    "gui.extension.pinpongBread.name": "PinPong expansion board",
    "gui.extension.pinpongBread.description": "Expansion board for Python learning on ArduinoUNO",
    "gui.extension.spread.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadboson.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadmaqueen.description": "An entry-level minicar based on the control version",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "The advanced educational robot that supports both micro:bit and mPython",
    "gui.extension.iot.name": "Internet of Things",
    "gui.extension.iot.description": "Extended Edition Based on Internet of Things",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Device controlled by Leonardo",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Uno main control board module",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Device controlled by Arduino Nano",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "Main control board based on ESP32",
    "gui.extension.telloesp32.name": "RoboMaster TT(ESP32)",
    "gui.extension.telloesp32.description": "Educational drone with DJI top flight control algorithms, safe & stable",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Device controlled by Mega2560",
    "gui.extension.firebeetleesp32.name": "FireBeetle ESP32",
    "gui.extension.firebeetleesp32e.name": "FireBeetle ESP32-E",
    "gui.extension.firebeetleesp32.description": "Device controlled by FireBeetle ESP32",
    "gui.extension.dfr0299.name": "DFPlayer MP3 Module",
    "gui.extension.dfr0299.description": "MP3 Player Module",
    "gui.extension.ser0006.name": "Micro Servo",
    "gui.extension.ser0006.description": "Micro Servo",
    "gui.extension.dfr0523.name": "Peristaltic Pump",
    "gui.extension.dfr0523.description": "Deliver fluid by squeezing and releasing the pump alternately",
    "gui.extension.dfr0017.name": "Relay Module",
    "gui.extension.dfr0017.description": "Control high current device",
    "gui.extension.steppermotor.name": "Stepper Motor",
    "gui.extension.steppermotor.description": "Realize accurate position control by pulse control",
    "gui.extension.dfr0534.name": "Serial MP3 module",
    "gui.extension.dfr0534.description": "Serial MP3 voice module",
    "gui.extension.servo360.name": "360° Micro Servo",
    "gui.extension.servo360.description": "Control speed and direction",
    "gui.extension.tel0118.name": "OBLOQ IoT Module ",
    "gui.extension.tel0118.description": "A Wi-Fi to Serial device",
    "gui.extension.dfr0095.name": "IR Transmitter Module",
    "gui.extension.dfr0095.description": "Transmit standard 38KHz infrared signal",
    "gui.extension.dfr0094.name": "IR Receiver Module",
    "gui.extension.dfr0094.description": "Receive standard 38KHz infrared signal",
    "gui.extension.midi.name": "MIDI",
    "gui.extension.midi.description": "MIDI player",
    "gui.extension.radio.name": "Wireless broadcasting",
    "gui.extension.radio.description": "Wireless broadcast communication between multiple control boards",
    "gui.extension.tel0094.name": "GPS signal receiving module",
    "gui.extension.tel0094.description": "GPS signal receiving module",
    "gui.extension.tel0026.name": "Bluetooth module",
    "gui.extension.tel0026.description": "Bluetooth serial port module",
    "gui.extension.dfr0486.name": "OLED-12864 Display",
    "gui.extension.dfr0647.name": "OLED-12832 Display",
    "gui.extension.dfr0486.description": "I2C OLED-12864 Display Module",
    "gui.extension.dfr0647.description": "I2C OLED-12832 Display Module",
    "gui.extension.fit0352.name": "WS2812 RGB LED Strip",
    "gui.extension.fit0352.description": "Control WS2812-based LED strip modules",
    "gui.extension.dfr0063.name": "LCD1602 Display",
    "gui.extension.dfr0063.description": "LCD module which can show 2 lines and 16 characters in each line",
    "gui.extension.dfr0021.name": "Digital LED Light Module",
    "gui.extension.dfr0021.description": "White, red, green and blue LED modules",
    "gui.extension.tm1650.name": "TM1650 Four digit tube",
    "gui.extension.tm1650.description": "Four digit tube",
    "gui.extension.matrix8_8.name": "MAX7219 8x8 dot matrix",
    "gui.extension.matrix8_8.description": "8x8 lattice module",
    "gui.extension.dfr0522.name": "8x16 RGB LED Matrix Panel",
    "gui.extension.dfr0522.description": "Display images, numbers... support scroll display and user-defined setting",
    "gui.extension.music.name": "Music",
    "gui.extension.music.description": "Play instruments and drums.",
    "gui.extension.pen.name": "Pen",
    "gui.extension.pen.description": "Draw with your sprites.",
    "gui.extension.video-sensing.name": "Video Sensing",
    "gui.extension.video-sensing.description": "Sense motion with the camera.",
    "gui.extension.google-translate.name": "Translate",
    "gui.extension.google-translate.description": "Translate text into many languages.",
    "gui.extension.bd-translate.name": "Baidu Translate",
    "gui.extension.bd-translate.description": "Use Baidu translation service to translate text into other languages.",
    "gui.extension.text-speech.name": "Text to Speech",
    "gui.extension.text-speech.description": "Make your projects talk",
    "gui.extension.softSerialport.name": "Software Serial Port",
    "gui.extension.softSerialport.description": "Use I/O port to simulate serial communication function",
    "gui.extension.IICScan.name": "I2C Scan",
    "gui.extension.IICScan.description": "Scan all device addresses that connected to I2C interface",
    "gui.extension.bluetooth.name": "Bluetooth",
    "gui.extension.bluetooth.description": "Bluetooth device",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "GoBLE APP can help youcontrol main control board via smart phone",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "Use it to read or write and save data with eeprom",
    "gui.extension.interrupt.name": "Interrupt",
    "gui.extension.interrupt.description": "Interrupt pin",
    "gui.extension.serialPort.description": "Conveniently realize the function of sending multiple sets of data in upload mode, and receiving data through serial port in real-time mode",
    "gui.extension.mpyfile.name": "file",
    "gui.extension.mpyfile.description": "Read and write files in the control panel, store data and export",
    "gui.extension.sdcard.name": "SD card reader module",
    "gui.extension.sdcard.description": "MicroSD card reader module",
    "gui.extension.multithread.name": "MultiThread",
    "gui.extension.multithread.description": "Allow multiple programs and the main program to run simultaneously",
    "gui.extension.speech.name": "Speech",
    "gui.extension.speech.description": "Talk to your projects.",
    "gui.extension.sen0001.name": "Ultrasonic Sensor",
    "gui.extension.bos0001.name": "Rotation Sensor",
    "gui.extension.bos0002.name": "Push Buttonr",
    "gui.extension.bos0003.name": "Self-Locking Switch",
    "gui.extension.bos0004.name": "Light Sensor",
    "gui.extension.bos0006.name": "Steam Sensor",
    "gui.extension.bos0007.name": "Flame Sensor",
    "gui.extension.bos0008.name": "Touch Sensor",
    "gui.extension.bos0009.name": "Sound Sensor",
    "gui.extension.bos0010.name": "Infrared Proximity Sensor",
    "gui.extension.bos0012.name": "Conductivity Switch",
    "gui.extension.bos0013.name": "Motion Sensor",
    "gui.extension.bos0038.name": "Soil Moisture Sensor",
    "gui.extension.bos0045.name": "Ultrasonic Distance Sensor",
    "gui.extension.bos0016.name": "Ultra-Bright LED",
    "gui.extension.bos0017.name": "LED Module",
    "gui.extension.bos0019.name": "LED String Light",
    "gui.extension.bos0021.name": "Fan Module",
    "gui.extension.bos0022.name": "Voice Recorder Module",
    "gui.extension.bos0001.description": "Analog sensor, can detect rotation position",
    "gui.extension.bos0002.description": "Press to output high level, release to output low level",
    "gui.extension.bos0003.description": "Output high level in pressed state, low level in pop-up state",
    "gui.extension.bos0004.description": "Detection of ambient light intensity",
    "gui.extension.bos0006.description": "Detect rain and fog, not immersed in water",
    "gui.extension.bos0007.description": "Detect fire source or light source with wavelength of 760~1100nm",
    "gui.extension.bos0008.description": "Touch switch, can sense human body, metal, etc",
    "gui.extension.bos0009.description": "Detect sound intensity",
    "gui.extension.bos0010.description": "Detect signals generated by different colors",
    "gui.extension.bos0012.description": "Check whether the object is conductive",
    "gui.extension.bos0013.description": "Detect infrared rays emitted by moving people or animals",
    "gui.extension.bos0038.description": "Detect soil moisture, the less moisture the smaller the output value",
    "gui.extension.bos0045.description": "Analog distance sensor, detection range 2cm~1m",
    "gui.extension.bos0016.description": "White light emitting module",
    "gui.extension.bos0017.description": "Light-emitting diodes can emit light corresponding to the color of the lamp beads",
    "gui.extension.bos0019.description": "Colorful light strip, the length of the light strip is 3 meters, the color cannot be changed",
    "gui.extension.bos0021.description": "Module for driving fan blade rotation",
    "gui.extension.bos0022.description": "Can record a 10s sound and play recording",
    "gui.extension.sen0001.description": "Accurate distance detection with the range 2~800cm, compatible with urm and SR04 ultrasonic",
    "gui.extension.dfr0023.name": "Analog LM35 Temperature Sensor",
    "gui.extension.dfr0023.description": "LM35-based semiconductor temperature sensor with the range 0~100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22 Temperature and Humidity Sensor",
    "gui.extension.dhtTHSensor.description": "Detect enviroment temperature and humidity",
    "gui.extension.dsTSensor.name": "DS18B20 Temperature Sensor",
    "gui.extension.dsTSensor.description": "Detect ambient temperature with large range of -55~+125℃ ",
    "gui.extension.sen0203.name": "Heart Rate Monitor Sensor",
    "gui.extension.sen0203.description": "Mini heart rate sensor with analog pulse and digital square wave output modes",
    "gui.extension.sen0177.name": "Laser PM2.5 Air Quality Sensor",
    "gui.extension.sen0177.description": "Measure PM1, PM2.5 and PM10. Transfering data via serial port",
    "gui.extension.sen0014.name": "GP2Y0A21 IR Distance Sensor",
    "gui.extension.sen0014.description": "IR sensor based on GP2Y0A21 with 10~80cm measuring range",
    "gui.extension.sen0204.name": "Non-contact Liquid Level Sensor",
    "gui.extension.sen0204.description": "Detect liquid level but free from any contact",
    "gui.extension.sen0160.name": "Hx711 weight sensor",
    "gui.extension.sen0160.description": "Measure the Hx711 weight sensor",
    "gui.extension.sen0161.name": "Analog pH Meter",
    "gui.extension.sen0161.description": "Measure the liquid pH value with 5V power supply",
    "gui.extension.sen0161-v2.name": "Analog pH Meter(V2)",
    "gui.extension.sen0161-v2.description": "Measure the liquid pH calue with 3.3~5.5V power supply ",
    "gui.extension.sen0244.name": "Analog TDS Sensor",
    "gui.extension.sen0244.description": "Measure the TDS(Total Dissolved Solids) of liquid which suggests the cleanliness of water",
    "gui.extension.sen0165.name": "Analog ORP Meter",
    "gui.extension.sen0165.description": "Measure the ORP(Oxidation-Reduction Potential) of liquid to test the water quality",
    "gui.extension.sen0237.name": "Analog Dissolved Oxygen Sensor",
    "gui.extension.sen0237.description": "Measure the DO(Dissolved Oxygen) of liquid to evaluate the water quality",
    "gui.extension.dfr0300-H.name": "Analog EC Meter",
    "gui.extension.dfr0300-H.description": "Measure the EC(Electrical Conductivity) of liquid to evaluate the water quality",
    "gui.extension.dfr0300.name": "Analog EC Meter(V2)",
    "gui.extension.dfr0300.description": "Measure the EC(Electrical Conductivity) of liquid to evaluate the water quality",
    "gui.extension.sen0170.name": "Anemometer",
    "gui.extension.sen0170.description": "Measure wind speed level and output voltage signal",
    "gui.extension.sen0226.name": "BMP280 Temperature Sensor",
    "gui.extension.sen0226.description": "BMP280 barometric pressure sensor for temperature and barometric measurement",
    "gui.extension.sen0228.name": "I2C VEML7700 Ambient Light Sensor",
    "gui.extension.sen0228.description": "A high accuracy ambient light digital 16-bit resolution sensor",
    "gui.extension.sen0236.name": "BME280 module",
    "gui.extension.sen0236.description": "Environmental sensors (temperature, humidity, air pressure)",
    "gui.extension.sen0517.name": "ICP10111 pressure sensor",
    "gui.extension.sen0517.description": "Accuracy ±1Pa, high precision and high stability air pressure sensor",
    "gui.extension.dfr0022.name": "Analog Grayscale Sensor",
    "gui.extension.dfr0022.description": "Detect light density and reflect analog voltage signal, whihch can be applied to line-tracking",
    "gui.extension.motucamera.name": "MU sensor board",
    "gui.extension.motucamera.description": "description for the 'MU sensor board' extension",
    "gui.extension.dfr0552.name": "12-bit DA conversion module",
    "gui.extension.dfr0552.description": "Convert the digital quantity to the corresponding dc voltage signal accurately (non-pwm)",
    "gui.extension.sen0117.name": "Speech synthesis module",
    "gui.extension.sen0117.description": "You just type in the Chinese and English characters and Numbers and it will read them",
    "gui.extension.dfr0576.name": "I2C cascade extender",
    "gui.extension.dfr0576.description": "Used to resolve I2C device address conflicts",
    "gui.extension.sen0248.name": "BME680 environmental sensor",
    "gui.extension.sen0248.description": "VOC (volatile organic compounds), temperature, humidity and air pressure can be measured",
    "gui.extension.sen0304.name": "I2C ultrasonic ranging sensor",
    "gui.extension.sen0304.description": "Ultrasonic sensors designed for rapid ranging or obstacle avoidance applications",
    "gui.extension.sen0307.name": "Analog ultrasonic ranging sensor",
    "gui.extension.sen0307.description": "Open dual probe analog ultrasonic ranging module",
    "gui.extension.sen0250.name": "BMI160 6 axis inertial motion sensor",
    "gui.extension.sen0250.description": "16-bit 3-axis acceleration + ultra-low power consumption 3-axis gyroscope",
    "gui.extension.sen0224.name": "I2C LIS2DH triaxial accelerometer",
    "gui.extension.sen0224.description": "Ultra-low power consumption 3 axis acceleration sensor",
    "gui.extension.dfr0151.name": "Real Time Clock DS1307",
    "gui.extension.dfr0151.description": "the time error is only about 1 second in 24 hours",
    "gui.extension.dfr0469.name": "Real Time Clock SD2405",
    "gui.extension.dfr0469.description": "An accurate I2C real-time clock (RTC) with crystal compensation, inner chargeable battery. ",
    "gui.extension.dfr0126.name": "Spectrum Analysis Module",
    "gui.extension.dfr0126.description": "Keep track of the sound frequency",
    "gui.extension.dfr0231.name": "NFC Module",
    "gui.extension.dfr0231.description": "Suitable for short distance wireless communication",
    "gui.extension.sen0245.name": "VL53L0X Laser Ranging Sensor",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "Analog Ambient Light Sensor",
    "gui.extension.dfr0026.description": "Detect the ambient light density",
    "gui.extension.dfr0027.name": "Digital Vibration Sensor",
    "gui.extension.dfr0027.description": "Dectect vibration signal and output digital signal",
    "gui.extension.dfr0028.name": "Digital Tilt Sensor",
    "gui.extension.dfr0028.description": "Based on the number of mercury switches, it can be used as a simple tilt sensor",
    "gui.extension.dfr0029.name": "Digital Push Button",
    "gui.extension.dfr0029.description": "Push down:high level  Release: low level",
    "gui.extension.dfr0030.name": "Digital Capacitive Touch Sensor",
    "gui.extension.dfr0030.description": "Capacitive touch switch module for sensing metal and human body",
    "gui.extension.sen0318.name": "CCS811 Air Quality Sensor",
    "gui.extension.sen0318.description": "CCS811 Measurable CO2, TVOC",
    "gui.extension.sen0315.name": "PAJ7620U2 Gesture Sensor",
    "gui.extension.sen0315.description": "IIC communication, in the range of up to 20cm, can recognize up to 13 gestures",
    "gui.extension.sen0497.name": "Temperature&Humidity Sensor",
    "gui.extension.sen0497.description": "A new generation of temperature and humidity sensor for I2C communication, an upgraded product of DHT11",
    "gui.extension.sen0514.name": "ENS160 air quality sensor",
    "gui.extension.sen0514.description": "Measures TVOC (Total Volatile Organic Compounds), eCO2 (Relative Carbon Dioxide), AQI (Air Quality Index)",
    "gui.extension.sen0518.name": " MAX30102 Heart rate blood oxygen sensor",
    "gui.extension.sen0518.description": "Measure human resting heart rate and blood oxygen saturation",
    "gui.extension.dfr0991.name": "Button module with RGB",
    "gui.extension.dfr0991.description": "Read the status of the button to control the color and on/off of the RGB light",
    "gui.extension.sen0376.name": "Alcohol Sensor",
    "gui.extension.sen0376.description": "Factory calibration, no calibration required,0-5ppm",
    "gui.extension.sen0321.name": "zone Sensor",
    "gui.extension.sen0321.description": "Measurement of ozone concentration in the environment",
    "gui.extension.sen0364.name": "Visible Spectrum Sensor",
    "gui.extension.sen0364.description": "Measure the visible spectrum and identify the spectral color",
    "gui.extension.sen0359.name": "Capacitive Fingerprint Sensor",
    "gui.extension.sen0359.description": "Capacitive Fingerprint Sensor",
    "gui.extension.kit0176.name": "I2C Weight Sensor",
    "gui.extension.kit0176.description": "The weight of the object can be measured, the range is 1Kg",
    "gui.extension.dfr0033.name": "Digital Magnetic Sensor",
    "gui.extension.dfr0033.description": "Detect magnetic materials within 3cm",
    "gui.extension.dfr0034.name": "Analog Sound Sensor",
    "gui.extension.dfr0034.description": "Detect the loudness in ambient",
    "gui.extension.sen0132.name": "Analog Carbon Monoxide Sensor",
    "gui.extension.sen0132.description": "Detect CO-gas concentrations within 20 to 2000ppm",
    "gui.extension.dfr0051.name": "Analog Voltage Divider",
    "gui.extension.dfr0051.description": "Detect voltage(DC) from 0.0245V to 25V",
    "gui.extension.dfr0052.name": "Analog Piezo Disk Vibration Sensor",
    "gui.extension.dfr0052.description": "To detect dynamic and Quasi-static stress and output analog signal",
    "gui.extension.dfr0058.name": "Analog Rotation Potentiometer Sensor",
    "gui.extension.dfr0058.description": "High-accuracy potentiometer with maximum rotation count of 10",
    "gui.extension.dfr0061.name": "Joystick Module",
    "gui.extension.dfr0061.description": "(X,Y) analog output, (Z) 1 digital output and it can be used as romote controller",
    "gui.extension.dfr0072.name": "Shiftout Sensor",
    "gui.extension.dfr0072.description": "description for the 'Shiftout sensor' extension",
    "gui.extension.dfr0563.name": "battery Sensor",
    "gui.extension.dfr0563.description": "description for the 'battery sensor' extension",
    "gui.extension.dfr0553.name": "16-bit AD conversion module",
    "gui.extension.dfr0553.description": "description for the '16-bit AD conversion module' extension",
    "gui.extension.dfr0588.name": "Temperature and humidity sensor",
    "gui.extension.dfr0588.description": "Temperature and humidity sensor",
    "gui.extension.dfr0076.name": "Analog Flame Sensor",
    "gui.extension.dfr0076.description": "Detect flame or light with a wavelength of 760nm-1100nm",
    "gui.extension.dfr0117.name": "Eeprom iic Sensor",
    "gui.extension.dfr0117.description": "Data storage module,32kb",
    "gui.extension.dfr0143.name": "Triple Axis Accelerometer MMA7361",
    "gui.extension.dfr0143.description": "Based on MMA7361 chip, detect gesture and movement direction",
    "gui.extension.sen0018.name": "Digital Infrared Motion Sensor",
    "gui.extension.sen0018.description": "Detect infrared released forom moving human or animal",
    "gui.extension.sen0114.name": "Analog Soil Moisture Sensor",
    "gui.extension.sen0114.description": "Read the amount of moisture present in the soil surrounding it. ",
    "gui.extension.sen0121.name": "Steam Sensor",
    "gui.extension.sen0121.description": "Detect rainwater,steam and water mist",
    "gui.extension.sen0212.name": "Color Identification Sensor",
    "gui.extension.sen0212.description": "Identify objects colors and output RGB value",
    "gui.extension.sen0253.name": "10DOF Attitude Sensor",
    "gui.extension.sen0253.description": "BBNO055 is a new sensor IC for implementing an intelligent 9-axis absolute orientation sensor.",
    "gui.extension.sen0290.name": "lightning sensor",
    "gui.extension.sen0290.description": "intelligent lightning sensor",
    "gui.extension.sen0291.name": "I2C digital power meter",
    "gui.extension.huskylens.name": "HUSKYLENS AI Camera",
    "gui.extension.huskylens.description": "Artificial intelligence vision sensor that supports face recognition and learning",
    "gui.extension.ps2.name": "PS2 handle",
    "gui.extension.ps2.description": "PS2 universal handle receiving module",
    "gui.extension.sen0291.description": "High precision measurement of voltage, current and power",
    "gui.extension.sen0202.name": "3D Mini Gesture Sensor",
    "gui.extension.sen0202.description": "Detect clockwise and counterclockwise rotation and movement directions",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "Micro:bit dedicated lithium battery motor expansion board",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "Enable the device connect to the Wi-Fi network",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "Enable the device get local time",
    "gui.extension.udp.name": "UDP broadcast",
    "gui.extension.udp.description": "Let devices and devices in the local area network realize multi-machine broadcast communication through UDP protocol",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "Let the device request information via HTTP",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "Let devices communicate using the MQTT protocol",
    "gui.extension.internet.name": "Internet",
    "gui.extension.internet.description": "Internet communication",
    "gui.extension.speechRecognition.name": "Speech Recognition",
    "gui.extension.speechRecognition.description": "Speech Recognition",
    "gui.extension.pictureai.defaultTitleText": "Video Window",
    "gui.extension.pictureai.cameraPermissionsTitle": "需要相机权限",
    "gui.extension.pictureai.cameraPermissionsMessage": "请前往设置中勾选授权。如已授权但仍无法使用，请确认摄像头已正常连接无其它软件占用，或重启软件。",
    "gui.extension.pictureai.cameraPermissionsCancel": "取消",
    "gui.extension.pictureai.cameraPermissionsGoToSettings": "前往设置",
    "gui.gui.loaded": "Loaded",
    "gui.gui.notLoaded": "NOT Loaded",
    "gui.gui.cantFindYouWant": "Can't find what you want?",
    "gui.gui.clickHere": "Click here",
    "gui.gui.viewHelp": " to find more",
    "gui.gui.uploadToDev": "Upload",
    "gui.gui.codeArea": "Code",
    "gui.gui.fileSys": "file system",
    "gui.gui.compileAndUpload": "Compiled and upload",
    "gui.gui.compileOnly": "Compile",
    "gui.gui.compileOpen": "Compiled and Open",
    "gui.gui.burnBootloader": "Burn wireless bootloader",
    "gui.gui.arduinoRun": "Run",
    "gui.gui.arduinoSave": "Save",
    "gui.gui.autoGenerated": "Auto Generate",
    "gui.gui.manualEditing": "Manual Editing",
    "gui.gui.codeBox": "Code Box",
    "gui.gui.moduleCode": "Module Code",
    "gui.menu.edit.undo": "Undo",
    "gui.menu.edit.redo": "Redo",
    "gui.menu.edit.selectAll": "Select All",
    "gui.menu.edit.cut": "Cut",
    "gui.menu.edit.copy": "Copy",
    "gui.menu.edit.paste": "Paste",
    "gui.menu.edit.codeStyle": "Code Style",
    "gui.menu.edit.fontSize": "Font-size",
    "gui.menu.edit.clearCache": "Clear Cache",
    "gui.menu.edit.maxReset": "Restore Max to factory defaults",
    "gui.gui.serial": "Serial",
    "gui.gui.openSerial": "Open Serial",
    "gui.gui.closeSerial": "Close Serial",
    "gui.gui.close": "Close",
    "gui.gui.open": "Open",
    "gui.gui.clearOutput": "Clear Output",
    "gui.gui.scroll": "Scroll",
    "gui.gui.send": "Send",
    "gui.gui.baud": "Baud",
    "gui.gui.noLineEnd": "No Line terminators",
    "gui.gui.newLine": "Line Feed",
    "gui.gui.carriageReturn": "Carriage Return",
    "gui.gui.bothNLandCR": "LF & CR",
    "gui.gui.unavailableHexData": "Invalid hex form! Please represent hex data with two characters ((0-9/A-F) and a blank, e.g. \"00 01 A2 FF\".",
    "gui.gui.hexForm": "Hex Form",
    "gui.gui.openHexForm": "Use hex form to show and send data",
    "gui.gui.closeHexForm": "Close",
    "gui.progress.burning": "Burning",
    "gui.progress.burning1": "Burning",
    "gui.progress.compiling": "Compiling",
    "gui.burner.compileError": "Compile error",
    "gui.burner.linkError": "Link error",
    "gui.burner.generateHexError": "Generate .hex error",
    "gui.burner.generateBinError": "Generate .bin error",
    "gui.burner.burnError": "Burn error",
    "gui.burner.eraseError": "Erase error",
    "gui.burner.findLeonardoPortError": "Cannot find bootlaoder port of Leonardo",
    "gui.burner.noMicrobit": "Sorry it is not micro: bit, please connect micro: bit",
    "gui.burner.swdCfgError": "Cannot write to cmsis-dap.cfg",
    "gui.burner.UDiskPopUp": "UDisk pops up, please wait for a while and try again",
    "gui.burner.UDiskWriteError": "Write to UDisk error",
    "gui.burner.openPortError": "Could not open port: Access is denied",
    "gui.burner.firmwareError": "Firmware error, please restore and re-connect",
    "gui.burner.uploadDone": "Upload done",
    "gui.burner.connectPort": "Connecting",
    "gui.burner.burnFirmware": "Burn firmware",
    "gui.burner.writeLibFiles": "Writing library files",
    "gui.burner.connectError": "Connect error, please re-connect.please refer to the help document for solutions",
    "gui.burner.connectPico": "Pico firmware downloaded successfully, please re-select the serial port connection",
    "gui.burner.printObjectTooBig": "The project is too large; please click the \"help\" in the right top to reduce its size. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooBig": "Not enough memory; please click the \"help\" in the right top to reduce memory occupancy. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Low memory available, stability problems may occur. ",
    "gui.burner.printSizeError": "Couldn't determine program size. ",
    "gui.burner.printSize": "The project uses [%TEXTSIZE] bytes, occupies ([%TEXTSIZEPRE]) program memory space, leaving [%TEXTSIZESUR] bytes, up to [%TEXTSIZEMAX] bytes.\nGlobal variables use [%DATASIZE] bytes, ([%DATASIZEPRE]) of dynamic memory, leaving [%DATASIZESUR] byte local variables, up to [%DATASIZEMAX] bytes. ",
    "gui.progress.compileSuccess": "compile success",
    "gui.progress.compileProgress": "Compile Progress",
    "gui.progress.uploadProgress": "Upload Progress",
    "gui.progress.uploadSuccess": "upload success",
    "gui.progress.uploadSuccessVm": "upload success",
    "gui.progress.networkInterfaceCard": "NIC",
    "gui.codeLabel.dynamicVariables": "Dynamic variables",
    "gui.codeLabel.functionDeclaration": "Function declaration",
    "gui.codeLabel.staticConstants": "Static constants",
    "gui.codeLabel.createObject": "Create an object",
    "gui.codeLabel.mainProgramStart": "Main program start",
    "gui.codeLabel.SubthreadProgramStarts": "Subthread %1 program starts",
    "gui.codeLabel.customFunction": "Custom function",
    "gui.codeLabel.eventCallbackFunction": "Event callback function",
    "gui.codeLabel.globalCode": "Global variables",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "No variables allowed!",
    "gui.codeLabel.staticFunctions": "Static functions",
    "gui.progress.errMsg": "!Upload error, please check the details at the black window on the lower right",
    "gui.progress.errMsg1": "!Upload error, please check the details at the black window on the lower right",
    "gui.progress.errMsg2": "!Upload error, please check the details at the black window on the lower right",
    "gui.howtos.spin-around.name": "Spin Around",
    "gui.howtos.spin.step_dragTurn": "Drag out a [turn] block",
    "gui.howtos.spin.step_clickTurn": "Click the block to run",
    "gui.howtos.spin.step_clickControl": "Click the [Control] category",
    "gui.howtos.spin.step_dragForever": "Drag out a [forever] block",
    "gui.howtos.spin.step_clickForever": "Click the block to run",
    "gui.howtos.spin.step_changeColor": "Add a [change color effect] block",
    "gui.howtos.say-hello.name": "Say Hello",
    "gui.howtos.say-hello.step_addSprite": "Add a new sprite",
    "gui.howtos.say-hello.step_clickLooks": "Click the [Looks] category",
    "gui.howtos.say-hello.step_dragSay": "Drag out a [say] block",
    "gui.howtos.say-hello.step_clickSay": "Click the block to run",
    "gui.howtos.say-hello.step_anotherSay": "Drag out another [say] block",
    "gui.howtos.say-hello.step_editSay": "Say something else",
    "gui.howtos.say-hello.step_clickStack": "Click the block to run",
    "gui.howtos.run-away.name": "Run Away",
    "gui.howtos.run-away.step_dragGoTo": "Drag out a [go to random position] block",
    "gui.howtos.run-away.step_clickGoTo": "Click the block to run",
    "gui.howtos.run-away.step3": "Click the [Events] category",
    "gui.howtos.run-away.step_addWhenClicked": "Add a “when this sprite clicked” block",
    "gui.howtos.run-away.step_clickSprite": "Click the sprite to run",
    "gui.howtos.run-away.step_addSound": "Add a “start sound” block",
    "gui.howtos.pick-up-apple.name": "Game of Catching Apples",
    "gui.howtos.pick-up-apple.step_1": "Switch to Scratch",
    "gui.howtos.pick-up-apple.step_2": "Switch hardware",
    "gui.howtos.pick-up-apple.step_3": "Connect device",
    "gui.howtos.pick-up-apple.step_4": "Compass calibration",
    "gui.howtos.pick-up-apple.step_5": "Open Scratch example programs",
    "gui.howtos.pick-up-apple.step_6": "Game of Catching Apples",
    "gui.howtos.move-left-right.name": "micro:bit and Meow",
    "gui.howtos.move-left-right.step_1": "Creat a new project",
    "gui.howtos.move-left-right.step_2": "Drag block1",
    "gui.howtos.move-left-right.step_3": "Drag block2",
    "gui.howtos.move-left-right.step_4": "Move left and right",
    "gui.howtos.install-driver.name": "Install Driver",
    "gui.howtos.transform-expression.name": "Smile or Cry",
    "gui.howtos.transform-expression.step_1": "Switch to Code",
    "gui.howtos.transform-expression.step_2": "Switch hardware",
    "gui.howtos.transform-expression.step_3": "Drag block1",
    "gui.howtos.transform-expression.step_4": "Drag block2",
    "gui.howtos.transform-expression.step_5": "Drag block3",
    "gui.howtos.transform-expression.step_6": "Transform to smile or cry",
    "gui.howtos.lot.name": "IOT (Internet of Things)",
    "gui.howtos.lot.step_1": "Select example programs",
    "gui.howtos.lot.step_2": "Burning program",
    "gui.howtos.lot.step_3": "Feature diagram",
    "gui.howtos.touch-pin.name": "Pin touch",
    "gui.howtos.touch-pin.step_1": "Switch to Code",
    "gui.howtos.touch-pin.step_2": "Select the routine",
    "gui.howtos.touch-pin.step_3": "Burning program",
    "gui.howtos.touch-pin.step_4": "Pin touch",
    "gui.howtos.save-witch.name": "Rescue Witch",
    "gui.howtos.save-witch.step_1": "Switch to Scratch",
    "gui.howtos.save-witch.step_2": "Select 'micro:bit'in expansion",
    "gui.howtos.save-witch.step_3": "Select 'Rescue Witch' in example programs",
    "gui.howtos.save-witch.step_4": "Connect the device, select micro:bit and wait for a successful connection",
    "gui.howtos.save-witch.step_5": "Rotate micro:bit all around until the LED turns off when the note [calibrate the device] pops up.",
    "gui.howtos.save-witch.step_6": "Click the green flag and swing your arm to interact with the animation",
    "gui.howtos.microbit-touch.name": "Press to Smile or Cry",
    "gui.howtos.microbit-touch.step_1": "Switch to Code mode",
    "gui.howtos.microbit-touch.step_2": "Click 'Learning' to open the example programs and select 'Pin touch'",
    "gui.howtos.microbit-touch.step_3": "Connect the corresponding COM port of the device and click 'Upload to Device'",
    "gui.howtos.microbit-touch.step_4": "Press different keys to show different expressions",
    "gui.howtos.microbit-calibration.name": "micro:bit Calibration",
    "gui.howtos.calliope-calibration.name": "calliope Calibration",
    "gui.howtos.microbit-calibration.step_1": "Rotate the micro:bit board i, and the onboard LED lights will light up in turn.",
    "gui.howtos.microbit-calibration.step_2": "Until the outermost LED lights are all on (as shown below), a smile will be displayed after the calibration is completed.",
    "gui.extension.sen0251.name": "BMP388 Barometric Pressure Sensor",
    "gui.extension.sen0251.description": "Temperature, atmospheric pressure, altitude detection function",
    "gui.extension.sen0206.name": "Non-contact infrared temperature sensor",
    "gui.extension.sen0206.description": "Far-infrared non-contact measurement of object or ambient temperature",
    "gui.gui.recentlyEdited": "Recently Edited",
    "gui.extension.weather.name": "Get the Weather",
    "gui.extension.weather.description": "Here a Wi-Fi module is needed to get weather information",
    "gui.extension.weather.description.javascript": "To obtain weather information through the Internet, the computer needs to be connected to the Internet",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "Operate the TinyWebDB network database, can be used with APP Inventor",
    "gui.extension.pictureai.name": "AI Image recognition",
    "gui.extension.pictureai.description": "do something wonderful with picture AI, need connected to the Internet",
    "gui.gui.variableScopeOptionAllSprites": "For all sprites",
    "gui.gui.variableScopeOptionSpriteOnly": "Only for the current sprite",
    "gui.gui.variablePromptAllSpritesMessage": "All sprites can use this variable.",
    "gui.monitor.contextMenu.default": "Normal Readout",
    "gui.monitor.contextMenu.large": "Large Readout",
    "gui.monitor.contextMenu.slider": "Slider",
    "gui.monitor.contextMenu.sliderRange": "change slider range",
    "gui.monitor.contextMenu.import": "Import",
    "gui.monitor.contextMenu.export": "Export",
    "gui.monitor.listMonitor.listLength": "length {length}",
    "gui.monitor.listMonitor.empty": "(empty)",
    "gui.costumeTab.backdrop": "Backdrop",
    "gui.costumeTab.costume": "Costume",
    "gui.opcodeLabels.direction": "direction",
    "gui.opcodeLabels.xposition": "x position",
    "gui.opcodeLabels.yposition": "y position",
    "gui.opcodeLabels.size": "size",
    "gui.opcodeLabels.costumename": "costume name",
    "gui.opcodeLabels.costumenumber": "costume number",
    "gui.opcodeLabels.backdropname": "backdrop name",
    "gui.opcodeLabels.backdropnumber": "backdrop number",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "answer",
    "gui.opcodeLabels.loudness": "loudness",
    "gui.opcodeLabels.year": "year",
    "gui.opcodeLabels.month": "month",
    "gui.opcodeLabels.date": "date",
    "gui.opcodeLabels.dayofweek": "day of week",
    "gui.opcodeLabels.hour": "hour",
    "gui.opcodeLabels.minute": "minute",
    "gui.opcodeLabels.second": "second",
    "gui.opcodeLabels.timer": "timer",
    "gui.loadProject.error": "load project error",
    "gui.fileSystem.fileCatalogue": "Catalog",
    "gui.fileSystem.boardFiles": "Board Files",
    "gui.fileSystem.boardFilesPico": "Pico Files",
    "gui.fileSystem.boardFiles2": "Board Files",
    "gui.fileSystem.boardFilesMaixduino": "Board Files",
    "gui.fileSystem.boardFilesBoson": "BOSON MC1",
    "gui.fileSystem.computerFiles": "Computer Files",
    "gui.fileSystem.userExFiles": "Use-Ex Files",
    "gui.fileSystem.newFile": "new file",
    "gui.fileSystem.newFolder": "new folder",
    "gui.fileSystem.deleteFolder": "delete folder",
    "gui.fileSystem.deleteFile": "delete",
    "gui.fileSystem.rename": "rename",
    "gui.fileSystem.openFolder": "open folder position",
    "gui.fileSystem.openFilePos": "open file position",
    "gui.fileSystem.openInEditor": "open in editor",
    "gui.fileSystem.runRightNow": "run right now",
    "gui.fileSystem.stopRun": "stop run",
    "gui.fileSystem.setBoot": "boot run",
    "gui.fileSystem.deleteForever": "delete forever",
    "gui.fileSystem.saveAs": "save as",
    "gui.fileSystem.undoAllEdits": "cancel all edits",
    "gui.fileSystem.copyToComputer": "copy to computer",
    "gui.fileSystem.uploadToBoard": "upload to board",
    "gui.fileSystem.uploadAndRun": "upload and run",
    "gui.wifi.pwdNotLegal": "! Error: The password is in the wrong format. The Wi-Fi password must be 8-20 digits or numbers",
    "gui.spreadmaqueen.perror": "! Error:P12 pin led lamp is not available. Please select P8 pin led lamp.",
    "gui.gui.viewConflict": "view conflict",
    "gui.gui.clickViewHelp": "How to solve?",
    "gui.gui.conflict": "Conflict",
    "gui.gui.conflictPrompt": "Conflict warning prompt",
    "gui.gui.clickPrompt": "Click to highlight blocks, Double click to locate blocks",
    "gui.extension.mpyUART.name": "Serial UART",
    "gui.extension.mpyUART.description": "UART communication",
    "gui.extension.mpyI2C.name": "I2C communication",
    "gui.extension.mpyI2C.description": "Use software to simulate I2C protocol to transmit data",
    "gui.extension.mpyServo.name": "Servo module",
    "gui.extension.mpyServo.description": "Servo module",
    "gui.extension.mpyTimer.name": "Timer",
    "gui.extension.mpyTimer.description": "Can execute the program regularly",
    "gui.extension.mpyList.name": "List",
    "gui.extension.mpyList.description": "List",
    "gui.extension.mpyDictionary.name": "Dictionary",
    "gui.extension.mpyDictionary.description": "Dictionary",
    "gui.extension.mpySet.name": "Set",
    "gui.extension.mpySet.description": "Set",
    "gui.extension.mpyTuple.name": "Tuple",
    "gui.extension.mpyTuple.description": "Tuple",
    "gui.connection.auto-scanning.noPeripheralsFound": "No devices found",
    "gui.connection.auto-scanning.prescan": "Have your device nearby, then begin searching.",
    "gui.connection.auto-scanning.pressbutton": "Press the button on your device.",
    "gui.connection.auto-scanning.start-search": "Start Searching",
    "gui.connection.connecting-searchbutton": "Searching...",
    "gui.connection.auto-scanning.try-again": "Try again",
    "gui.connection.connected": "Connected",
    "gui.connection.disconnect": "Disconnect",
    "gui.connection.go-to-editor": "Go to Editor",
    "gui.connection.connecting-cancelbutton": "Connecting...",
    "gui.connection.error.errorMessage": "Oops, looks like something went wrong.",
    "gui.connection.error.tryagainbutton": "Try again",
    "gui.connection.error.helpbutton": "Help",
    "gui.connection.peripheral-name-label": "Device name",
    "gui.connection.connect": "Connect",
    "gui.connection.scanning.lookingforperipherals": "Looking for devices",
    "gui.connection.scanning.noPeripheralsFound": "No devices found",
    "gui.connection.scanning.instructions": "Select your device in the list above.",
    "gui.connection.scanning.instructionsForTello": "Please connect your computer WiFi to Tello or RMTT first, and then select the devices listed above. ",
    "gui.connection.connect.instructionsForTello": "Please connect Tello or RMTT in the computer WiFi list first, and then select the devices listed above.",
    "gui.connection.scanning.connectTelloHandle": "Select the device listed above, or manually connect to the aircraft Wi-Fi.",
    "gui.connection.search": "Refresh",
    "gui.connection.unavailable.installscratchlink": "Make sure you have Scratch Link installed and running",
    "gui.connection.unavailable.enablebluetooth": "Check that Bluetooth is enabled",
    "gui.connection.unavailable.tryagainbutton": "Try again",
    "gui.connection.unavailable.helpbutton": "Help",
    "gui.extension.ev3.description": "Build interactive robots and more.",
    "gui.extension.ev3.connectingMessage": "Connecting. Make sure the pin on your EV3 is set to 1234.",
    "gui.extension.boost.description": "Bring robotic creations to life.",
    "gui.extension.boost.connectingMessage": "Connecting",
    "gui.extension.wedo2.description": "Build with motors and sensors.",
    "gui.extension.wedo2.connectingMessage": "Connecting",
    "gui.extension.huskyLensPro.name": "HUSKYLENS EDU",
    "gui.extension.huskyLensPro.description": "Use HUSKYLENS to connect directly to a computer to learn the basics of AI",
    "gui.extension.huskyLensPro.connectingMessage": "huskyLens is trying to request a connection, please wait",
    "gui.extension.machinelearning.name": "machine learning(ML5)",
    "gui.extension.machinelearning.description": "based on machine learning algorithm, the application of artificial intelligence can be realized without network",
    "gui.extension.huskylens.pcsetting": "Configure computer camera",
    "gui.extension.huskylens.visualmodal": "AI visualization tools",
    "gui.extension.huskylens.KNNText": "Computer-side camera simulation KNN classification",
    "gui.extension.huskylens.huskylensbasic": "Basic Functions",
    "gui.extension.huskylens.algorithmOperationLabel": "Algorithmic Operation",
    "gui.extension.huskylens.displayFunctionLabel": "Display",
    "gui.extension.huskylens.cameraFunctionLabel": "Take Photos",
    "gui.extension.huskylens.modelOperationLabel": "Model Operation",
    "gui.huskyLensPro.toConnect": "Please connect HUSKYLENS EDU first",
    "gui.huskyLensPro.toClassificationData": "Please add classification data first",
    "gui.huskyLensPro.startDeployment": "Start deployment",
    "gui.huskyLensPro.switchingAlgorithmFailed": "Cannot switch to the \"object classification\" algorithm!",
    "gui.huskyLensPro.forgettingFailure": "Learning data cannot be forgotten!",
    "gui.huskyLensPro.failedToSetCategoryLabel": "Failed to set \"category label\"!",
    "gui.huskyLensPro.requestFailed": "Request \"Deploy\" failed!",
    "gui.huskyLensPro.deploymentFailed": "\"Deploy Data\" failed!",
    "gui.huskyLensPro.completed": "Completed",
    "gui.huskyLensPro.countdown": ", expected to be completed in [TIME]",
    "gui.huskyLensPro.minutes": " minutes ",
    "gui.huskyLensPro.seconds": " seconds",
    "gui.huskyLensPro.deploying": "Model is being deployed",
    "gui.huskyLensPro.disconnected": "The connection has been disconnected and the deployment is terminated. Please check the USB connection!",
    "gui.huskyLensPro.huskylenMaker": "This feature is only available in the educational version",
    "gui.huskyLensPro.updateVersion": "Please connect to the education version and the firmware version is not less than 0.5.1, see \"Help\" for details",
    "gui.huskyLensPro.failedRetry": "Failed, please try again!",
    "gui.connection.auto-scanning.notFoundHuskylensTip": "Connection timed out, please confirm that the machine is connected to HuskyLens",
    "gui.extension.tello.name": "RoboMaster TT(single)",
    "gui.extension.tello.description": "Educational drone with DJI top flight control algorithms, safe & stable",
    "gui.extension.tello.connectingMessage": "Connecting... Make sure tello in turned on.",
    "gui.extension.telloGroup.name": "RoboMaster TT(team)",
    "gui.extension.telloGroup.description": "Educational drone with DJI top flight control algorithms, safe & stable",
    "gui.connection.auto-scanning.notFoundTelloTip": "Scanning timed out, please make sure Tello is turned on",
    "gui.extension.AIVisualInterface.lable": "AI Visual Interface",
    "gui.extension.AIVisualInterface.title": "No Data",
    "gui.extension.AIVisualInterface.imgCount": "[COUNT] pictures",
    "gui.extension.AIVisualInterface.delete": "delete",
    "gui.extension.AIVisualInterface.classificationCount": "[COUNT] categories in total",
    "gui.extension.AIVisualInterface.recognitionResult": "The screen recognition result is:",
    "gui.extension.AIVisualInterface.possibility": "The possibilities are:",
    "gui.extension.AIVisualInterface.addClass": "Add classification",
    "gui.extension.AIVisualInterface.startTraining": "Start training",
    "gui.extension.AIVisualInterface.prediction": "Predict video pictures",
    "gui.extension.AIVisualInterface.retrain": "Retrain",
    "gui.extension.AIVisualInterface.export": "Export model",
    "gui.dialog.baiduServer.togglePersonalAccount": "The current number of available vocals has reached the upper limit, please switch to your personal account and log in to the console to check the quota",
    "gui.extension.ai.name": "AI",
    "gui.extension.AI.description": "Support model call and training",
    "gui.extension.machineVision.name": "Machine Vision",
    "gui.extension.machineVision.description": "Image processing, drawing, filtering, recognizing shape, color, dimension code, feature",
    "gui.extension.machineHearing.name": "Machine Listening",
    "gui.extension.machineHearing.description": "Speech recognition through training",
    "gui.extension.maixduino.name": "Maixduino",
    "gui.extension.maixduino.description": "Main control board based on K210 chip",
    "gui.extension.boson.name": "Boson MC1",
    "gui.extension.boson.description": "BOSON-based main control board",
    "gui.extension.pico.name": "Raspberry Pi Pico",
    "gui.extension.pico.description": "Raspberry Pi Pico main control board",
    "gui.extension.pyTurtle.name": "Turtle Drawing",
    "gui.extension.pyTurtle.description": "There are thousands of brain holes, between the sizes",
    "gui.extension.pyGenerator.name": "Code Generator",
    "gui.extension.pyGenerator.description": "Write code directly on image blocks to achieve graphical and code mixing",
    "gui.extension.unihiker.name": "UNIHIKER",
    "gui.extension.unihiker.description": "A new generation mainboard for complete python learning",
    "gui.extension.python.save": "Save",
    "gui.extension.python.execute": "Run",
    "gui.extension.python.pipmanger": "Library Management",
    "gui.extension.python.filesystem": "File System",
    "gui.extension.python.fileinprogram": "File in the project",
    "gui.extension.python.fileinpc": "File in the computer",
    "gui.extension.python.terminal": "Terminal",
    "gui.extension.python.clearterminal": "Clear output",
    "gui.extension.python.tabcode": "Code",
    "gui.extension.python.piplist": "Library List",
    "gui.extension.python.commendatory": "Recommended library",
    "gui.extension.python.handleinstall": "PIP Mode",
    "gui.extension.python.selectall": "Select all [COUNT]",
    "gui.extension.python.update": "Update",
    "gui.extension.python.uninstall": "Uninstall",
    "gui.extension.python.install": "Install",
    "gui.extension.python.installing": "Installing...",
    "gui.extension.python.installed": "Installed",
    "gui.extension.python.piphandleinstall": "PIP manual installation",
    "gui.extension.python.loadmore": "Load more...",
    "gui.extension.python.hidemore": "Hide more...",
    "gui.extension.python.noavailable": "There is no library available, please download it first",
    "gui.extension.python.enterCommand": "Please enter the library name first",
    "gui.extension.python.finishedtips": "Command has finished running",
    "gui.extension.python.stop": "Stop",
    "gui.extension.python.executing": "Running",
    "gui.extension.python.editorArea": "Code Area",
    "gui.extension.python.toolbox": "Graphics Area",
    "gui.extension.python.autoCreate": "Auto Generate",
    "gui.extension.python.emptyEditor": "Click on the <b>New File button</b> behind <b>Files in Project</b> on the right to create a .py file that belongs to you",
    "gui.extension.python.userDocument": "Help Document",
    "gui.extension.xiamiboard.name": "Xia Mi Expansion Board",
    "gui.extension.xiamiboard.description": "A micro:bit-based multi-functional expansion board for programming education",
    "gui.gui.saveSb3ToCloud": "Save to the cloud",
    "gui.extension.pyMqtt.name": "MQTT-py",
    "gui.extension.pyMqtt.description": "Communication via MQTT over PC Internet",
    "gui.extension.pyGame.name": "Pygame",
    "gui.extension.pyGame.description": "Create your own games and animations with Python",
    "gui.extension.pinpongBase.name": "pinpong initialize",
    "gui.extension.pinpongBase.description": "initialize pinpong library  select board function and GPIO",
    "gui.extension.pinpongServo.name": "Micro Servo",
    "gui.extension.pinpongServo.description": "Can be rotated from 0 ~ 180 degrees to a specified angle",
    "gui.extension.pinpongIrq.name": "Interrupt",
    "gui.extension.pinpongIrq.description": "pinpong Interrupt pin",
    "gui.extension.pinpongTone.name": "buzzer",
    "gui.extension.pinpongTone.description": "pinpong buzzer module",
    "gui.extension.pinpongSr04_urm10.name": "Ultrasonic Sensor",
    "gui.extension.pinpongSr04_urm10.description": "Accurate distance detection with the range 2~800cm, compatible with urm and SR04 ultrasonic",
    "gui.extension.pinpongDht.name": "DHT11/22 Temperature and Humidity Sensor",
    "gui.extension.pinpongDht.description": "Detect enviroment temperature and humidity",
    "gui.extension.pinpongNeopixel.name": "WS2812 RGB LED Strip",
    "gui.extension.pinpongNeopixel.description": "Control LED strip changes to produce effects such as flashing, flowing, jumping, etc.",
    "gui.extension.pinpongOled12864.name": "OLED Display",
    "gui.extension.pinpongOled12864.description": "Small display, suitable for smart wearable devices",
    "gui.extension.pinpongDS18B20.name": "DS18B20 Temperature Sensor",
    "gui.extension.pinpongDS18B20.description": "Detect ambient temperature with large range of -55~+125℃",
    "gui.extension.pinpongMlx90614.name": "Non-contact infrared temperature sensor",
    "gui.extension.pinpongMlx90614.description": "Far-infrared non-contact measurement of object or ambient temperature",
    "gui.extension.pinpongBmp388.name": "BMP388 Barometric Pressure Sensor",
    "gui.extension.pinpongBmp388.description": "Temperature, atmospheric pressure, altitude detection function",
    "gui.extension.pinpongIRRecv.name": "IR Receiver Module",
    "gui.extension.pinpongIRRecv.description": "Receive standard 38KHz infrared signal",
    "gui.extension.pythonsen0334.name": "SHT31-F digital temperature and humidity sensor",
    "gui.extension.pythonsen0334.description": "Measurable temperature and humidity",
    "gui.extension.pythonsen0303.name": "VEML6075 UV sensor",
    "gui.extension.pythonsen0303.description": "Ability to detect UVA and UVB strength",
    "gui.extension.pythonsen0377.name": "MEMS gas sensor",
    "gui.extension.pythonsen0377.description": "Support the detection of CO, C2H5OH(Alcohol)H2, NO2, NH3 and other gas concentrations",
    "gui.extension.pythonsen0460.name": "Air Quality Sensor",
    "gui.extension.pythonsen0460.description": "EN0460: Air quality sensor with Gravity interface to measure PM2.5 value",
    "gui.extension.obloqMp.name": "OBLOQ IoT Module",
    "gui.extension.obloqMp.description": "A Wi-Fi to Serial device",
    "gui.extension.pyAutoGui.name": "PyAutoGui",
    "gui.extension.pyAutoGui.description": "PyAutoGUI lets your Python scripts control the mouse and keyboard to automate interactions with other applications.",
    "gui.extension.pyRequests.name": "Requests",
    "gui.extension.pyRequests.description": "HTTP for Humans",
    "gui.extension.pyJson.name": "JSON",
    "gui.extension.pyJson.description": "JSON encoder and decoder",
    "gui.extension.pySchedule.name": "Schedule",
    "gui.extension.pySchedule.description": "Python job scheduling for humans.",
    "gui.extension.pyXlrd.name": "xlrd",
    "gui.extension.pyXlrd.description": "execl read, Support .xls",
    "gui.extension.pyXlwt.name": "xlwt",
    "gui.extension.pyXlwt.description": "execl write, Support .xls",
    "gui.extension.pyOpenCV.name": "OpenCV",
    "gui.extension.pyOpenCV.description": "OpenCV is an open-source library that includes several hundreds of computer vision algorithms.",
    "gui.extension.pyBaiDuAi.name": "Baidu face recognition",
    "gui.extension.pyBaiDuAi.description": "recognize face with Baidu AI interface",
    "gui.extension.pyBaiDuImage.name": "Baidu image recognition",
    "gui.extension.pyBaiDuImage.description": "recognize image with Baidu AI interface",
    "gui.extension.pyBaiDuSpeech.name": "Baidu Speech",
    "gui.extension.pyBaiDuSpeech.description": "Baidu Speech technology provides developers with multiple smart services including speech-to-text, text-to-speech, etc.",
    "gui.extension.pyXfSpeech.name": "Xunfei Speech",
    "gui.extension.pyXfSpeech.description": "Xunfei Speech provides speech synthesis and speech recognition capabilities",
    "gui.account.dialog.prompt": "Prompt",
    "gui.account.dialog.tips": "Prompt",
    "gui.account.dialog.signoutAlert": "You will not be able to use cloud storage and other functions after you log out. Are you sure you want to log out?",
    "gui.account.dialog.signoutSuccess": "Logged out",
    "gui.account.dialog.sure": "OK",
    "gui.account.dialog.cancel": "Cancel",
    "gui.account.dialog.error": "Error",
    "gui.account.dialog.save": "Save",
    "gui.account.dialog.getCloudFileFailed": "Error getting cloud file [NAME], please check if the file exists",
    "gui.account.dialog.getCloudError": "Error getting cloud file [NAME]",
    "gui.account.dialog.loadCloudSuccess": "Loading cloud file [NAME] succeeded",
    "gui.account.dialog.saveCloudError": "Failed to save [NAME] to the cloud, please try later.",
    "gui.account.dialog.saveCloudSuccess": "Save [NAME] to the cloud successfully",
    "gui.account.dialog.saveCloudCheckError": "Save failed, cloud file verification error, please try again later",
    "gui.account.dialog.saveCloudRepeatPrompt": "A file [NAME] with the same name already exists in the cloud. Do you want to overwrite it? ",
    "gui.account.dialog.saveCloudRepeatAlert": "A file with the same name 「[NAME]」 already exists in the cloud, please change the name",
    "gui.account.dialog.saveCover": "Overwrite",
    "gui.account.dialog.rename": "Rename",
    "gui.account.dialog.newProject": "New File",
    "gui.account.dialog.projectName": "Project Name",
    "gui.account.dialog.enterName": "Please enter the file name",
    "gui.account.dialog.nameWithSymbol": "File name cannot contain special characters, please change",
    "gui.account.dialog.nameBeyondLength": "The length of the file name cannot exceed 30",
    "gui.account.dialog.renameSuccess": "Rename the file [NAME1] to [NAME2] successfully",
    "gui.account.dialog.renameFailed": "Failed to rename file",
    "gui.account.dialog.downloadSuccess": "[NAME] download completed",
    "gui.account.dialog.cancelDownload": "Cancel Download",
    "gui.account.dialog.deleteSucess": "Deletion of cloud file [NAME] was successful",
    "gui.account.dialog.saveAs": "Save As",
    "gui.account.dialog.deleteFailed": "Error deleting cloud file [NAME]",
    "gui.account.dialog.deletePrompt": "Are you sure to delete the cloud file [NAME]? ",
    "gui.account.open": "Open",
    "gui.account.upload": "Upload",
    "gui.account.delete": "Delete",
    "gui.account.usersSpace": "[NAME]'s Cloud Project",
    "gui.account.cloudDataLoading": "Cloud data loading, please wait",
    "gui.account.emptyUserSpace": "This is empty, go and upload your first project.",
    "gui.account.dialog.login": "Login",
    "gui.account.dialog.unsupportProject": "Error opening project, unsupported project",
    "gui.account.dialog.shouldLoginFirst": "The cloud service needs your account information, please log in first",
    "gui.account.dialog.loginTimeout": "Login Timeout",
    "gui.account.account": "Account",
    "gui.account.serverAddr": "Server Address",
    "gui.account.thirdPartyAccount": "Third Party",
    "gui.account.dialog.saveCloudRenamePrompt": "File name [NAME] already exists, do you want to rename?",
    "gui.account.dialog.signup": "Sign up",
    "gui.account.dialog.phoneNumber": "Mobile Number",
    "gui.account.dialog.password": "Password",
    "gui.account.dialog.autoLogin": "Remember the password",
    "gui.account.dialog.forgotPasswd": "Forgot Password?",
    "gui.account.dialog.getCheckCode": "Get Verification Code",
    "gui.account.dialog.reSend": "Resend",
    "gui.account.dialog.checkCode": "Verification Code",
    "gui.account.dialog.readAndAgree": "I have read and accepted",
    "gui.account.dialog.userAgreement": "User Agreement",
    "gui.account.dialog.loginSuccess": "Login Successful",
    "gui.account.dialog.passwordError": "The account or password is wrong, please check if it is correct",
    "gui.account.dialog.checkNumberSended": "The verification code has been sent, please check it carefully",
    "gui.account.dialog.checkedAgree": "Agree to Agreement",
    "gui.account.dialog.networkError": "Network error, please check the connection and try again",
    "gui.account.dialog.readProtocolAndCheck": "Please read the user agreement and tick agree",
    "gui.account.dialog.signupError": "Signup error,",
    "gui.account.dialog.logininErrorWithNetwork": "Server connection error, login failed, please check the network",
    "gui.account.dialog.signupErrorDefault": "Registration failed, please try again",
    "gui.account.dialog.accountExist": "The account already exists, please log in directly",
    "gui.account.dialog.phoneCodeError": "Registration failed, verification code error",
    "gui.account.dialog.signupSuccess": "Registration is successful, please return to the login interface to continue the operation",
    "gui.account.dialog.inputEmpty": "Cannot be empty",
    "gui.account.dialog.phoneFormatIllegal": "User name format error",
    "gui.python.remoteSSH.connectSuccess": "Successfully connected to [ADDRESS]",
    "gui.python.remoteSSH.connectFailed": "Connection [ADDRESS] failed",
    "gui.python.remoteSSH.shouleReconnect": "Disconnected from [ADDRESS], do you want to reconnect?",
    "gui.python.remoteSSH.reconnect": "Reconnect",
    "gui.python.remoteSSH.disconnected": "Disconnected from [ADDRESS]",
    "gui.python.remoteSSH.accountCheckFailed": "Account information is wrong, connection with [ADDRESS] failed, please check and enter",
    "gui.python.remoteSSH.connectError": "Error connecting with [ADDRESS], [MSG], solution view help document",
    "gui.python.remoteSSH.sshLogin": "SSH Login",
    "gui.python.remoteSSH.address": "Address",
    "gui.python.remoteSSH.addressCantEmpty": "Address cannot be empty",
    "gui.python.remoteSSH.user": "User Name",
    "gui.python.remoteSSH.userCantEmpty": "User name cannot be empty",
    "gui.python.remoteSSH.passwd": "Password",
    "gui.python.remoteSSH.passwdCantEmpty": "The password cannot be empty",
    "gui.python.remoteSSH.areYouSureDisconnect": "Currently connected to the Remote [ADDRESS], are you sure to disconnect?",
    "gui.python.remoteSSH.statusConnecting": "Connecting",
    "gui.python.remoteSSH.disconnectDevice": "Disconnect Remote Terminal",
    "gui.python.remoteSSH.connectDevice": "Connect Remote Terminal",
    "gui.python.remoteSSH.statusError": "Connection error",
    "gui.python.remoteSSH.statusDisconnect": "The connection has been disconnected",
    "gui.python.remoteSSH.handleInput": "Manual Input",
    "gui.python.remoteSSH.openNetNetWork": "Open Network Center",
    "gui.python.remoteSSH.goHelp": "Help Document",
    "gui.python.remoteSSH.waitForConnecting": "Connecting to [ADDRESS], please wait...",
    "gui.python.remoteSSH.cancelConnect": "Cancel connection",
    "gui.python.remoteSSH.connectTimeout": "Connection timed out",
    "gui.python.remoteSSH.addressIsLost": "Address does not exist",
    "gui.python.remoteSSH.connectRefused": "The connection request was rejected, please check the remote system settings and try again",
    "gui.python.remoteSSH.runGUIProgramTips": "When running a graphical program, if you want to view the effect on the remote desktop, you need to use the user name currently logged in on the remote system",
    "gui.python.remoteSSH.executeFileError": "Error running project",
    "gui.python.remoteSSH.waitForProjectUpload": "Project [NAME] is uploading, please wait...",
    "gui.python.remoteSSH.projectUploadSuccess": "The project was uploaded successfully, it started to run",
    "gui.python.remoteSSH.projectUploadError": "Project upload error",
    "gui.python.filetree.boardNameInFT": "File in [BOARD]",
    "gui.python.filetree.replaceFilePrompt": "A file or folder named [NAME] already exists in the target folder. Do you want to replace it?",
    "gui.python.filetree.fileUploading": "Upload [NAME] file...",
    "gui.python.filetree.fileSaving": "Save [NAME] file...",
    "gui.python.filetree.dirSaving": "Save [NAME] folder...",
    "gui.python.filetree.saveAs": "Save As",
    "gui.python.filetree.chooseDir": "Select Folder",
    "gui.python.filetree.getDirList": "Get Directory...",
    "gui.python.filetree.getDirListWithPath": "Read [PATH] directory",
    "gui.python.filetree.getRemoteDirList": "Read remote [PATH] directory",
    "gui.python.filetree.renameFile": "Rename [FROM] to [TO]",
    "gui.python.filetree.deleteDirPrompt": "Are you sure you want to delete the folder [NAME] and the files it contains?",
    "gui.python.filetree.deleteFilePrompt": "Are you sure you want to delete the file [NAME]?",
    "gui.python.filetree.downloadToPC": "Download file to computer",
    "gui.python.filetree.uploadToRemote": "Upload file to",
    "gui.python.filetree.exportFileTo": "Export [EXT] File",
    "gui.python.filetree.rmFromeFileSystem": "Remove from the file system",
    "gui.python.filetree.rmDirPrompt": "Are you sure you want to remove the local path [NAME] and its sub-files?",
    "gui.python.filetree.rmAllDirPrompt": "Are you sure to remove all local paths?",
    "gui.python.filetree.addLocalDir": "Add a local folder",
    "gui.python.filetree.removeActionDirWithAll": "Remove all local folders",
    "gui.python.filetree.fileNameIsRequired": "File or folder name must be provided",
    "gui.python.filetree.fileNameIsRepeat": "The file or folder [NAME] already exists in this location. Please choose another name.",
    "gui.python.filetree.dragParentNodeToChild": "Parent folder cannot be moved to subfolder",
    "gui.python.filetree.dragPathIsConsistent": "The file drag path is consistent",
    "gui.python.filetree.fileOperateError": "Error in file operation",
    "gui.python.filetree.fileTreeMore": "More",
    "gui.python.filetree.copy": "copy",
    "gui.python.filetree.paste": "paste",
    "gui.python.worker.initPythonHomeDir": "Initializing folder...",
    "gui.python.worker.copyPythonSource": "Initializing the folder is complete, copying Python resource files...",
    "gui.python.worker.copyPythonSourceError": "File operation error, failed to initialize Python environment, please re-enter Python mode or restart the software",
    "gui.python.worker.unzipPythonSource": "Copying resource files is complete, unzipping...",
    "gui.python.worker.checkPythonSource": "Extracting is complete, the file is being verified...",
    "gui.python.worker.unzipPythonSourceError": "Unzip error, failed to initialize Python environment, please re-enter Python mode or restart the software",
    "gui.python.xterm.local": "Local",
    "gui.python.xterm.detectPythonEnv": "Detecting the environment, please wait...",
    "gui.python.xterm.installingPythonEnv": "\r\nInstalling the environment, please wait...",
    "gui.python.xterm.installPythonEnvError": "Python environment initialization failed, please contact technical support",
    "gui.python.xterm.detectPythonEnvFinished": "\r\nEnvironment detection completed, starting...\r\n",
    "gui.python.xterm.installPythonEnvFinished": "\r\nEnvironment installation is complete, starting...\r\n",
    "gui.python.xterm.toRestartXterm": "Double-click to restart the terminal",
    "gui.python.xterm.showRemoteConnect": "Open remote connection terminal",
    "gui.python.xterm.remoteConnectShowed": "The remote connection terminal is open",
    "gui.dialog.thirdExt.openPipManger": "Open Library Management",
    "gui.python.editor.undo": "Undo",
    "gui.python.editor.redo": "Redo",
    "gui.python.editor.search": "Search",
    "gui.python.editor.boldFont": "Bold Font",
    "gui.python.editor.zoomInt": "Zoom In",
    "gui.python.editor.zoomOut": "Zoom Out",
    "gui.python.editor.toTop": "To Top",
    "music.categoryName": "Music",
    "translate.categoryName": "Translate",
    "sensor.categoryName": "Sensor",
    "actuator.categoryName": "Actuator",
    "communicate.categoryName": "Communicate",
    "display.categoryName": "Display",
    "extendFunction.categoryName": "Function",
    "internetService.categoryName": "Internet",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "calliope.categoryName": "CALLIOPE",
    "esp32.categoryName": "handpy",
    "telloesp32.categoryName": "RMTT",
    "spread.categoryName": "Expansion Board",
    "speechRecognition.categoryName": "Speech Recognition",
    "thirdExpansion.categoryName": "User Expansion",
    "gui.blocklyText.blockTitle.eventBlock": "event program",
    "gui.blocklyText.blockTitle.dotScreen": "dot screen",
    "gui.blocklyText.blockTitle.music": "music",
    "gui.blocklyText.blockTitle.onBoardSensor": "board's sensor",
    "gui.blocklyText.blockTitle.onBoardFunc": "board's function",
    "gui.blocklyText.blockTitle.pinOperation": "pin operation",
    "gui.blocklyText.blockTitle.wirelessCommunication": "wireless communication",
    "gui.blocklyText.blockTitle.serialportOperation": "serialport operation",
    "gui.blocklyText.blockTitle.systemResource": "system resource",
    "gui.blocklyText.blockTitle.screenDisplay": "screen display",
    "gui.blocklyText.blockTitle.ledControl": "led control",
    "gui.blocklyText.blockTitle.functionalModule": "functional module",
    "gui.blocklyText.blockTitle.movement": "movement",
    "gui.blocklyText.blockTitle.expression": "expression",
    "gui.blocklyText.blockTitle.light": "light",
    "gui.blocklyText.blockTitle.dance": "dance",
    "gui.blocklyText.blockTitle.sensor": "sensor",
    "gui.blocklyText.blockTitle.motorControl": "motor control",
    "gui.blocklyText.blockTitle.kitSensor": "kit sensor",
    "gui.blocklyText.blockTitle.kitActuator": "kit actuator",
    "gui.blocklyText.blockTitle.commonModule": "common module",
    "gui.blocklyText.blockTitle.infraredCommunication": "infrared communication",
    "gui.blocklyText.blockTitle.mp3Module": "mp3 module",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 RGB light",
    "gui.blocklyText.blockTitle.LCDDisplay": "LCD display",
    "gui.blocklyText.blockTitle.digitalTube": "digital tube",
    "gui.blocklyText.blockTitle.oledDisplay": "OLED display",
    "gui.blocklyText.blockTitle.onBoardSource": "board resource",
    "gui.blocklyText.blockTitle.rgbControl": "RGB light control",
    "gui.blocklyText.blockTitle.maxbotResource": "max:bot resource",
    "gui.blocklyText.blockTitle.lineInspection": "line inspection",
    "gui.blocklyText.blockTitle.outerSensor": "outer sensor",
    "gui.blocklyText.blockTitle.consolePrint": "console output",
    "gui.blocklyText.blockTitle.radio": "radio",
    "gui.blocklyText.blockTitle.wifi": "wifi",
    "gui.blocklyText.blockTitle.motor": "motor",
    "gui.blocklyText.blockTitle.rgblightcontrol": "board's led",
    "gui.blocklyText.blockTitle.vibrationMotor": "vibration motor",
    "gui.blocklyText.romeo.d1ProgramStart": "D1 robot program starts",
    "gui.blocklyText.romeo.readdigital": "read value pin[PIN] [MODULE]",
    "gui.blocklyText.romeo.buttonModule": "Button Module",
    "gui.blocklyText.romeo.digitalSensor": "Digital Sensor",
    "gui.blocklyText.romeo.collisionSensor": "Crash Sensor",
    "gui.blocklyText.romeo.lineSensor": "Line Sensor",
    "gui.blocklyText.romeo.infraredSwitch": "Infrared Switch",
    "gui.blocklyText.romeo.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.romeo.readAnalog": "read pin [PIN] [SENSOR]",
    "gui.blocklyText.romeo.lightSensor": "Light Sensor",
    "gui.blocklyText.romeo.anologSensor": "Anolog Sensor",
    "gui.blocklyText.romeo.soundSensor": "Sound Sensor",
    "gui.blocklyText.romeo.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.romeo.frameSensor": "Frame Sensor",
    "gui.blocklyText.romeo.open": "Turn on",
    "gui.blocklyText.romeo.close": "Turn off",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] of pin [PIN]",
    "gui.blocklyText.romeo.ledLights": "LED Lights",
    "gui.blocklyText.romeo.digitalActuator": "Digital Actuator",
    "gui.blocklyText.romeo.relay": "Relay",
    "gui.blocklyText.romeo.fan": "Fan",
    "gui.blocklyText.romeo.writeAnalog": "set pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.romeo.fanModule": "Fan Module",
    "gui.blocklyText.romeo.robotSpeedMove": "robot [DIR] at speed[SPEED]",
    "gui.blocklyText.romeo.forward": "move forward",
    "gui.blocklyText.romeo.backward": "move backward",
    "gui.blocklyText.romeo.turnLeft": "turn left",
    "gui.blocklyText.romeo.turnRight": "turn right",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "motor [MOTOR] [DIR] at speed[SPEED]",
    "gui.blocklyText.romeo.interface": "Interface",
    "gui.blocklyText.romeo.motorForward": "Forward",
    "gui.blocklyText.romeo.motorReverse": "Backward",
    "gui.blocklyText.romeo.stopMotor": "robot (M1 and M2 motors) stop moving",
    "gui.blocklyText.romeo.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "read pin [PIN] LM35 temperature(°C)",
    "gui.blocklyText.romeo.ds18b20": "read pin [PIN] DS18B20 temperature(°C)",
    "gui.blocklyText.romeo.pressInfrared": "infrared pin [PIN] received button [BUTTON] pressed signal？",
    "gui.blocklyText.romeo.volumePlus": "Volume+",
    "gui.blocklyText.romeo.switch": "Switch",
    "gui.blocklyText.romeo.volumeMinus": "Volume-",
    "gui.blocklyText.romeo.startPause": "Start/Pause",
    "gui.blocklyText.romeo.up": "Up",
    "gui.blocklyText.romeo.down": "Down",
    "gui.blocklyText.romeo.dataReadable": "Is there data to read on serial port?",
    "gui.blocklyText.romeo.readSerialData": "read serial data",
    "gui.blocklyText.romeo.timer": "system uptime(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.romeo.half": "Half",
    "gui.blocklyText.romeo.quarter": "Quarter",
    "gui.blocklyText.romeo.oneInEighth": "One-eighth",
    "gui.blocklyText.romeo.wholeBeat": "Whole Beat",
    "gui.blocklyText.romeo.doubleBeat": "Double Beat",
    "gui.blocklyText.romeo.stop": "Stop",
    "gui.blocklyText.romeo.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.pythondfr0548.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.romeo.segment": "eight-segment LED (D6-D13 pin) display [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "serial output [STRING] [WAY], [LINE]",
    "gui.blocklyText.romeo.originalOutput": "origin",
    "gui.blocklyText.romeo.stringOutput": "string",
    "gui.blocklyText.romeo.hexOutput": "HEX",
    "gui.blocklyText.romeo.wrap": "Wrap",
    "gui.blocklyText.romeo.noWrap": "No-Wrap",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "read serial data [READTYPE]",
    "gui.blocklyText.arduinounoR3.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.romeo.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.max.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.maxbot.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.microbit.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.arduino.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.arduinoNano.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.mega2560.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.firebeetleesp32.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.romeo.int": "int",
    "gui.blocklyText.romeo.float": "float",
    "gui.blocklyText.romeo.setBaudRate": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "read pin [PIN] pulse width under [LEVEL] mode, timeout [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "LOW",
    "gui.blocklyText.romeo.high": "HIGH",
    "gui.blocklyText.romeo.noSound": "no sound",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino starts",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno starts",
    "gui.blocklyText.boson.starts": "BOSON MC1 starts",
    "gui.blocklyText.pico.starts": "Pico starts",
    "gui.blocklyText.boson.mpyAttitudeEvent": "when the BOSON MC1 board what [ATTITUDE]",
    "gui.blocklyText.boson.mpyBoardShakeThrown": "Whether the BOSON MC1 board is [ATTITUDE]?",
    "gui.blocklyText.boson.readAcceleration": "Read the value of acceleration (m-g) [TYPE]",
    "gui.blocklyText.boson.setAccelerationRange": "Set the acceleration range to [ACCRANGE]",
    "gui.blocklyText.boson.vibrationMotor": "[SWITCH] vibration motor",
    "gui.blocklyText.boson.vibrationMotorTime": "continuous vibration [TIME] second",
    "gui.blocklyText.boson.displayClear.js": "Clear the screen to [COLOR]",
    "gui.blocklyText.boson.displayClear": "Clear the screen to [COLOR] [PUSH]",
    "gui.blocklyText.boson.displaySetLineWidth": "Set the line width to [LINE] direction [DIRECTION]",
    "gui.blocklyText.boson.displayText": "screen display text [TEXT] coordinate x[X] y[Y] color[COLOR] font[FONT] background[BACKGROUND] [NOWRAP] ",
    "gui.blocklyText.boson.displayText.js": "screen display text [TEXT] coordinate x[X] y[Y] color[COLOR] [NOWRAP]",
    "gui.blocklyText.boson.displayTextLineshow": "Display text on screen line [RAW] [TEXT] color[COLOR] font[FONT] background[BACKGROUND] [NOWRAP] [MIDDLE] ",
    "gui.blocklyText.boson.displayPixel": "draw dots x[X] y[Y] color [COLOR]",
    "gui.blocklyText.boson.displayLine": "line drawing starting x[X1] y[Y1] end x[X2] y[Y2] color [COLOR]",
    "gui.blocklyText.boson.displayHline": "draw horizontal line starting x[X] y[Y] len [LEN] color [COLOR]",
    "gui.blocklyText.boson.displayVline": "draw vertical line starting x[X] y[Y] len [LEN] color [COLOR]",
    "gui.blocklyText.boson.displayRect": "draw rectangle starting x[X] y[Y] width[W] height[H] color [COLOR] position [POSITION] [FILL]",
    "gui.blocklyText.boson.displayRect.js": "draw rectangle starting x[X] y[Y] width[W] height[H] color [COLOR] [FILL]",
    "gui.blocklyText.boson.displayCircle": "draw a circle center of circle x[X] y[Y] radius[R] color [COLOR] position [POSITION] [FILL]",
    "gui.blocklyText.boson.displayCircle.js": "draw a circle center of circle x[X] y[Y] radius[R] color [COLOR] [FILL]",
    "gui.blocklyText.boson.displayTriangle": "draw triangles x1[X1] y1[Y1] x2[X2] y2[Y2] x3[X3] y3[Y3] color [COLOR] position [POSITION] [FILL]",
    "gui.blocklyText.boson.displayRoundRect": "draw rounded rectangle starting x[X] y[Y] width[WIDTH] height[HEIGTH] Corner radius[R] color [COLOR] position [POSITION]",
    "gui.blocklyText.boson.displayThreePointArc": "three point arc x1[X1] y1[Y1] x2[X2] y2[Y2] x3[X3] y3[Y3] color [COLOR] discrete spacing [SPACE] position [POSITION]",
    "gui.blocklyText.boson.mpyDisplayComputerImage": "Load pictures from computer [IMAGE] Display in grayscale to x[X] y[Y] position [POSITION] mode[TYPE] ",
    "gui.blocklyText.boson.mpyDisplayComputerRgbImage": "Load image from computer [IMAGE] display to x[X] y[Y] position [POSITION]",
    "gui.blocklyText.boson.boson_mpyDisplayFileSystemImage": "Load image from file system [PATH]",
    "gui.blocklyText.boson.showPixelCoordinate": "at coordinate x [X] y [Y] show [SHOWMSG] font [PIXEL] color [COLOR] background [BGCOLOR]",
    "gui.blocklyText.boson.displayQRCode": "Draw QR code [QRCODE] starting x[X] y[Y] 大小 [QRSIZE] color [COLOR]",
    "gui.blocklyText.boson.mpyDisplayClockInit": "Initialize the clock [NAME] starting x[X] y[Y] radius [RADIUS] color [COLOR] background color [BACKCOLOR]",
    "gui.blocklyText.boson.mpyDisplayClockUpdate": "Update the clock [NAME]",
    "gui.blocklyText.boson.displayClockInit": "Initialize the clock [ID] starting x[X] y[Y] radius [RADIUS] color [COLOR] background color [BACKCOLOR]",
    "gui.blocklyText.boson.displayClockUpdate": "Update the clock [ID]",
    "gui.blocklyText.boson.mpyDisplayProgressInit": "Initialize progress bar [NAME] starting x[X] y[Y] width [WIDTH] height [HEIGTH] progress rate [PROGRESS]% progress color [COLOR1] progress background color [COLOR2] background color [BACKCOLOR]",
    "gui.blocklyText.boson.mpyDisplayProgressUpdate": "update progress bar [NAME] progress rate [PROGRESS]%",
    "gui.blocklyText.boson.displayProgressInit": "Initialize progress bar [ID] starting x[X] y[Y] width [WIDTH] height [HEIGTH] progress rate [PROGRESS] progress color [COLOR1] progress background color [COLOR2] background color [BACKCOLOR]",
    "gui.blocklyText.boson.displayProgressUpdate": "update progress bar [ID] progress rate [PROGRESS]",
    "gui.blocklyText.boson.displayBrightness": "screen brightness is [VALUE]%",
    "gui.blocklyText.boson.font16_16": "font16_16",
    "gui.blocklyText.boson.font20_20": "font20_20",
    "gui.blocklyText.boson.middleMenu.true": "Center",
    "gui.blocklyText.boson.middleMenu.false": "ordinary",
    "gui.blocklyText.boson.directionMenu.false": "normal",
    "gui.blocklyText.boson.directionMenu.true": "reverse",
    "gui.blocklyText.boson.positionMenu.customize": "custom",
    "gui.blocklyText.boson.positionMenu.horizontalCenter": "Centered horizontally",
    "gui.blocklyText.boson.positionMenu.leftCenter": "center left",
    "gui.blocklyText.boson.positionMenu.rightCenter": "center right",
    "gui.blocklyText.boson.positionMenu.upCenter": "top center",
    "gui.blocklyText.boson.positionMenu.downCenter": "down center",
    "gui.blocklyText.boson.positionMenu.leftTop": "top left",
    "gui.blocklyText.boson.positionMenu.leftDown": "bottom left",
    "gui.blocklyText.boson.positionMenu.rightTop": "top right",
    "gui.blocklyText.boson.positionMenu.rightDown": "bottom right",
    "gui.blocklyText.boson.strength": "strength",
    "gui.blocklyText.boson.switchMenu.on": "Open",
    "gui.blocklyText.boson.switchMenu.off": "close",
    "gui.blocklyText.boson.nowrapMenu.true": "newline",
    "gui.blocklyText.boson.nowrapMenu.false": "no line break",
    "gui.blocklyText.boson.fillMenu.false": "hollow",
    "gui.blocklyText.boson.fillMenu.true": "solid",
    "gui.blocklyText.boson.push.false": "clear cache",
    "gui.blocklyText.boson.push.true": "effective immediately",
    "gui.blocklyText.esp8266.starts": "ESP8266 starts",
    "gui.blocklyText.esp32.displayInLine": "display [TEXT] in the [LINE] line",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.normal": "normal",
    "gui.blocklyText.esp32.rev": "rev",
    "gui.blocklyText.esp32.trans": "trans",
    "gui.blocklyText.esp32.XOR": "XOR",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "clear cache in the [LINE] line",
    "gui.blocklyText.esp32.displayClearInLine": "clear in the [LINE] line",
    "gui.blocklyText.esp32.buzzPinC": "P6  (Onboard buzzer)",
    "gui.blocklyText.esp32.buzzerRedirect": "Buzzer redirected to pin [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "All",
    "gui.blocklyText.esp32.setTouchThreshold": "Set button [PIN] touch value is [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "Read button [PIN] touch value",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 starts",
    "gui.blocklyText.esp32.btnIsPressed": "[BUTTON] button [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "when button [REFERENCE] [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "when pin [REFERENCE] is [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "When [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "pin [PIN] is [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "stop background playback",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "play note [NOTE] in background",
    "gui.blocklyText.esp32.setBuzzerFreq": "play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.esp32.getSoundIntensity": "read microphone sound intensity",
    "gui.blocklyText.esp32.readBrightness": "read ambient light brightness",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] show [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.esp32.accelerationCalibration": "Calibration of acceleration X axis offset [XOFFSET] Y axis offset [YOFFSET] Z axis offset [ZOFFSET] (m-g)",
    "gui.blocklyText.esp32.acceleration": "read acceleration(m-g) [TYPE]",
    "gui.blocklyText.esp32.esp32_tiltAngle": "read tilt angle [TYPE]",
    "gui.blocklyText.esp32.esp32_boardShakeThrown": "the board was [ATTITUDE]?",
    "gui.blocklyText.esp32.esp32_boardTiltState": "the board tilt [GESTUREMPY]",
    "gui.blocklyText.esp32.forward": "forward",
    "gui.blocklyText.esp32.back": "back",
    "gui.blocklyText.esp32.left": "left",
    "gui.blocklyText.esp32.right": "right",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationRange": "set acceleration range [ACCRANGE]",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationResolution": "set acceleration resolution [ACCRESOLUTION]",
    "gui.blocklyText.esp32.esp32_mpyAccurateAccValue": "accurate acceleration value X-axis deviation [XAXIS] Y-axis deviation [YAXIS] Z-axis deviation [ZAXIS]",
    "gui.blocklyText.esp32.esp32_mpyAccurateCompass": "accurate compass",
    "gui.blocklyText.esp32.esp32_mpyCompassDirection": "compass direction",
    "gui.blocklyText.esp32.esp32_mpyAxisMagnetic": "[TYPE] axis magnetic",
    "gui.blocklyText.esp32.esp32_mpyMagneticStrength": "magnetic field strength",
    "gui.blocklyText.esp32.esp32_mpyRemoveMagnetic": "remove the current ambient magnetic field",
    "gui.blocklyText.esp32.esp32_mpyReturnRunTimeDiff": "return run time difference, start time [STARTTIME] end time [ENDTIME]",
    "gui.blocklyText.esp32.esp32_mpyRunTimer": "get run time [TIMETYPE]",
    "gui.blocklyText.esp32.millisecond": "millisecond",
    "gui.blocklyText.esp32.microsencond": "microsencond",
    "gui.blocklyText.esp32.esp32_mpyLocalTime": "get local time [LOCALTIME]",
    "gui.blocklyText.esp32.year": "year",
    "gui.blocklyText.esp32.month": "month",
    "gui.blocklyText.esp32.day": "day",
    "gui.blocklyText.esp32.hour": "hour",
    "gui.blocklyText.esp32.minute": "minute",
    "gui.blocklyText.esp32.sencond": "sencond",
    "gui.blocklyText.esp32.weeknumber": "week number",
    "gui.blocklyText.esp32.daysnumber": "days number",
    "gui.blocklyText.esp32.esp32_mpyReset": "reset",
    "gui.blocklyText.esp32.esp32_mpyMacAddr": "MAC address",
    "gui.blocklyText.esp32.displayInXY": "display [TEXT] at X: [X]  Y: [Y] axis, preview [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "screen display color [COLOR] (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "write cache screen color [COLORMPYTHON]",
    "gui.blocklyText.esp32.clear": "clear",
    "gui.blocklyText.esp32.fullBright": "full bright",
    "gui.blocklyText.esp32.blackBackground": "black background",
    "gui.blocklyText.esp32.whiteBackground": "white background",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": "all white",
    "gui.blocklyText.esp32.lineDrawing": "draw line from Point x1:[X1] y1:[Y1] to Point x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "connected",
    "gui.blocklyText.esp32.disconnected": "disconnected",
    "gui.blocklyText.esp32.rectangleDrawing": "draw rectangle [FILL] with top-left corner at x:[X] y:[Y], W:[WIDTH], H:[HEIGHT]",
    "gui.blocklyText.esp32.mpyrectangleDrawing": "[DRAWERASE] [FILL] rectangle drawing origin x:[X] y:[Y] width[WIDTH] height[HEIGHT]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCornerBorder": "[DRAWERASE] corner border x [X] y [Y] width [WIDTH] height [HEIGHT] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCircle": "[DRAWERASE] [FILL] circle x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseTriangle": "[DRAWERASE] [FILL] triangle x1 [X1] y1 [Y1] x2 [X2] y2 [Y2] x3 [X3] y3 [Y3]",
    "gui.blocklyText.esp32.esp32_mpyShowPixelCoordinate": "at coordinate x [X] y [Y] show [SHOWMSG] as [PIXEL] font [LINE]",
    "gui.blocklyText.esp32.Digiface_11px": "Digiface 11px",
    "gui.blocklyText.esp32.Digiface_16px": "Digiface 16px",
    "gui.blocklyText.esp32.Digiface_21px": "Digiface 21px",
    "gui.blocklyText.esp32.Digiface_30px": "Digiface 30px",
    "gui.blocklyText.esp32.Digiface_44px": "Digiface 44px",
    "gui.blocklyText.esp32.Digiface_ltalic_30px": "Digiface ltalic 30px",
    "gui.blocklyText.esp32.Digiface_ltalic_42px": "Digiface ltalic 42px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_12px": "DejaVu Sans Mono 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_16px": "DejaVu Sans Mono 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_21px": "DejaVu Sans Mono 21px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_12px": "DejaVu Sans Mono Bold 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_16px": "DejaVu Sans Mono Bold 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_21px": "DejaVu Sans Mono Bold 21px",
    "gui.blocklyText.esp32.esp32_mpyQRCode": "x [X] y [Y] draw QR code [QRCODE] size [IMGSIZE]",
    "gui.blocklyText.esp32.esp32_mpyInitClock": "init clock [CLOCKNAME] x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyClockReadTime": "clock [CLOCKNAME] read time",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseClock": "[DRAWERASE] clock [CLOCKNAME]",
    "gui.blocklyText.esp32.screenDisplay": "screen cache display takes effect",
    "gui.blocklyText.esp32.circleDrawing": "draw circle [FILL] with center point at x:[X] y:[Y], r:[RADIUS]",
    "gui.blocklyText.esp32.fill": "fill",
    "gui.blocklyText.esp32.notFill": "not fill",
    "gui.blocklyText.esp32.pointDrawing": "draw point at x:[X] y:[Y]",
    "gui.blocklyText.esp32.esp32_mpyProgressBar": "progress bar x [X] y [Y] width [WIDTH] height [HEIGHT] progress [PROG]",
    "gui.blocklyText.esp32.esp32_mpyColumnarProgressBar": "columnar progress bar [COLUMNARSTATE] x [X] y [Y] width [WIDTH] height [HEIGHT] progress [PROG]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseLine": "[DRAWERASE] [COLUMNARSTATE] line x [X] y [Y] length [LENGTH]",
    "gui.blocklyText.esp32.draw": "draw",
    "gui.blocklyText.esp32.erase": "erase",
    "gui.blocklyText.esp32.vertical": "vertical",
    "gui.blocklyText.esp32.horizontal": "horizontal",
    "gui.blocklyText.esp32.setLineWidth": "set line width to [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.esp32.analogWrite": "analog pin [PIN] output(PWM) [VALUE]",
    "gui.blocklyText.esp32.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.esp32.serialPort0": "serial0",
    "gui.blocklyText.esp32.serialPort1": "serial1",
    "gui.blocklyText.esp32.serialPort2": "serial2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX] the baud rate [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "screen display image [IMAGE] at x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "loading picture from image [IMAGE] show to x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "pressed",
    "gui.blocklyText.esp32.loosened": "released",
    "gui.blocklyText.esp32.all": "ALL(-1)",
    "gui.blocklyText.esp32.setBaud": "serialport0 baud rate [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.esp32.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.esp32.setBrightness": "set led brightness [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "get led lightness",
    "gui.blocklyText.esp32.print": "print [DATA]",
    "gui.blocklyText.esp32.TiltForward": "Tilt forward",
    "gui.blocklyText.esp32.TiltBack": "Tilt back",
    "gui.blocklyText.esp32.TiltLeft": "Tilt left",
    "gui.blocklyText.esp32.TiltRight": "Tilt right",
    "gui.blocklyText.esp32.ScreenUp": "Screen up",
    "gui.blocklyText.esp32.ScreenDown": "Face down",
    "gui.blocklyText.esp32.Shake": "Shake",
    "gui.blocklyText.esp32.isGesture": "is [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "Disable [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "All",
    "gui.blocklyText.esp32.rgbdisable.No.0": "No.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "No.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "No.2",
    "gui.blocklyText.esp32.playMusic": "Play music [MUSIC] repeating [REPEAT]",
    "gui.blocklyText.esp32.Once": "Once",
    "gui.blocklyText.esp32.Forever": "Forever",
    "gui.blocklyText.esp32.OnceInBackground": "Once in background",
    "gui.blocklyText.esp32.ForeverInBackground": "Forever in background",
    "gui.blocklyText.radio.radioSwitch": "turn [DATA] radio",
    "gui.blocklyText.radio.radioswitch.on": "on",
    "gui.blocklyText.radio.radioswitch.off": "off",
    "gui.blocklyText.radio.setChannel": "set radio channl [DATA]",
    "gui.blocklyText.radio.radioSend": "radio sends [DATA]",
    "gui.blocklyText.radio.radioreceived": "radio received",
    "gui.blocklyText.radio.receivingEven": "when received [DATA]",
    "gui.blocklyText.radio.Msg": "radio message",
    "gui.blocklyText.radio.receivingSpecificEven": "when receiving specific broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.radioSwitch": "turn [DATA] radio",
    "gui.blocklyText.esp32.radioswitch.on": "on",
    "gui.blocklyText.esp32.radioswitch.off": "off",
    "gui.blocklyText.esp32.setChannel": "set radio channl [DATA]",
    "gui.blocklyText.esp32.radioSend": "radio sends [DATA]",
    "gui.blocklyText.esp32.radioreceived": "radio received",
    "gui.blocklyText.esp32.receivingEven": "when receiving broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.receivingSpecificEven": "when receiving specific broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.connectWIFI": "connect to Wi-Fi with SSID [SSID] & Password [PASS]",
    "gui.blocklyText.esp32.disconnectWIFI": "disconnect Wi-Fi connection",
    "gui.blocklyText.esp32.checkWIFI": "Has connected to Wi-Fi",
    "gui.blocklyText.esp32.configurationInformation": "Wi-Fi configuration information",
    "gui.blocklyText.esp32.configurationInformationIP": "Wi-Fi configuration information [IP]",
    "gui.blocklyText.esp32.wifi.IP": "IP",
    "gui.blocklyText.esp32.wifi.netmask": "netmask",
    "gui.blocklyText.esp32.wifi.geteway": "geteway",
    "gui.blocklyText.esp32.wifi.DNS": "DNS",
    "gui.blocklyText.esp32.openAP": "open AP mode with with SSID [SSID] & Password [PASS] & Channel [CHANNEL]",
    "gui.blocklyText.esp32.closeAP": "close AP mode",
    "gui.blocklyText.esp32.synchronizeTime": "synchronize network time,timezone [TIMEZONE]& timing server [SERVER]",
    "gui.blocklyText.esp32.timezone.UTC+8": "UTC+8",
    "gui.blocklyText.esp32.timezone.UTC+9": "UTC+9",
    "gui.blocklyText.esp32.timezone.UTC+10": "UTC+10",
    "gui.blocklyText.esp32.timezone.UTC+11": "UTC+11",
    "gui.blocklyText.esp32.timezone.UTC+12": "UTC+12",
    "gui.blocklyText.esp32.timezone.UTC-12": "UTC-12",
    "gui.blocklyText.esp32.timezone.UTC-11": "UTC-11",
    "gui.blocklyText.esp32.timezone.UTC-10": "UTC-10",
    "gui.blocklyText.esp32.timezone.UTC-9": "UTC-9",
    "gui.blocklyText.esp32.timezone.UTC-8": "UTC-8",
    "gui.blocklyText.esp32.timezone.UTC-7": "UTC-7",
    "gui.blocklyText.esp32.timezone.UTC-6": "UTC-6",
    "gui.blocklyText.esp32.timezone.UTC-5": "UTC-5",
    "gui.blocklyText.esp32.timezone.UTC-4": "UTC-4",
    "gui.blocklyText.esp32.timezone.UTC-3": "UTC-3",
    "gui.blocklyText.esp32.timezone.UTC-2": "UTC-2",
    "gui.blocklyText.esp32.timezone.UTC-1": "UTC-1",
    "gui.blocklyText.esp32.timezone.UTC": "UTC",
    "gui.blocklyText.esp32.timezone.UTC+1": "UTC+1",
    "gui.blocklyText.esp32.timezone.UTC+2": "UTC+2",
    "gui.blocklyText.esp32.timezone.UTC+3": "UTC+3",
    "gui.blocklyText.esp32.timezone.UTC+4": "UTC+4",
    "gui.blocklyText.esp32.timezone.UTC+5": "UTC+5",
    "gui.blocklyText.esp32.timezone.UTC+6": "UTC+6",
    "gui.blocklyText.esp32.timezone.UTC+7": "UTC+7",
    "gui.blocklyText.telloesp32.arduinoUnoStarts": "RMTT ESP32 starts",
    "gui.blocklyText.telloesp32.ledControl": "led control",
    "gui.blocklyText.telloesp32.matrixControl": "matrix control",
    "gui.blocklyText.telloesp32.flyControl": "fly control",
    "gui.blocklyText.telloesp32.customCommand": "custom command",
    "gui.blocklyText.telloesp32.pinOperation": "pin operation",
    "gui.blocklyText.telloesp32.readDigital": "read digital pin [PIN]",
    "gui.blocklyText.telloesp32.readAnalog": "read Analog pin [PIN]",
    "gui.blocklyText.telloesp32.setPwm": "set PWM pin [PIN] to [VALUE]",
    "gui.blocklyText.telloesp32.setDigital": "set digital pin [PIN] to [LEVEL]",
    "gui.blocklyText.telloesp32.functionalModule": "functional module",
    "gui.blocklyText.telloesp32.telloesp32_matrix": "set matrix [MARK]",
    "gui.blocklyText.telloesp32.showPatternWhenStart": "show pattern[MARK] when start",
    "gui.blocklyText.telloesp32.setMatrixLight": "set matrix light [NUM]",
    "gui.blocklyText.telloesp32.matrixDisplayText": "matrix display text [TEXT] color [COLOR] light [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayTextMpy": "matrix display text [TEXT] color [COLOR]",
    "gui.blocklyText.telloesp32.matrixDisplayPreset": "matrix display preset pattern [PATTERN] color [COLOR] light [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayPresetMpy": "matrix display preset pattern [PATTERN] color [COLOR]",
    "gui.blocklyText.telloesp32.empty": "empty",
    "gui.blocklyText.telloesp32.WiFi": "WiFi",
    "gui.blocklyText.telloesp32.DownLoad": "DownLoad",
    "gui.blocklyText.telloesp32.Power": "Power",
    "gui.blocklyText.telloesp32.heart": "heart",
    "gui.blocklyText.telloesp32.heartOutline": "heart-outline",
    "gui.blocklyText.telloesp32.matrixR": "r",
    "gui.blocklyText.telloesp32.matrixB": "b",
    "gui.blocklyText.telloesp32.matrixP": "p",
    "gui.blocklyText.telloesp32.matrixDisplayRollPicture": "display mark direction [DIRECT] move frequency [SPEED] mark[MARK]",
    "gui.blocklyText.telloesp32.matrixDisplayRollText": "matrix display text direction [DIRECT] move frequency [SPEED] text[TEXT] color[COLOR]",
    "gui.blocklyText.telloesp32.telloSetWifiChannel": "set Wi-Fi channel [CHANNEL]",
    "gui.blocklyText.telloesp32.initTOF": "init TOF",
    "gui.blocklyText.telloesp32.readTOF": "read TOF (mm)",
    "gui.blocklyText.telloesp32.buttonIsPressed": "button is pressed?",
    "gui.blocklyText.telloesp32.setRgbColor": "lights cache color [COLOR]",
    "gui.blocklyText.telloesp32.rgbToColor": "set R: [RED] G: [GREEN] B: [BLUE]",
    "gui.blocklyText.telloesp32.rgbBreathWithColor": "breath LED with color[COLOR] frequency(Hz) [TIME]",
    "gui.blocklyText.telloesp32.rgbBreath": "set breath lights R: [RED] G: [GREEN] B: [BLUE] frequency(Hz): [SP]",
    "gui.blocklyText.telloesp32.rgbFlashingColor": "flashing lights color1 [COLOR1] and color2 [COLOR2] frequency(Hz) [SP]",
    "gui.blocklyText.telloesp32.rgbFlashing": "set flashing lights R1: [RED1] G1: [GREEN1] B1: [BLUE1] R2: [RED2] G2: [GREEN2] B2: [BLUE2] frequency(Hz): [SP]",
    "gui.blocklyText.telloesp32.ledCloseEffects": "LED close special effects",
    "gui.blocklyText.telloesp32.matrixCloseEffects": "matrix close special effects",
    "gui.blocklyText.telloesp32.sendCustomCommand": "send custom command [CMD]",
    "gui.blocklyText.telloesp32.recvCustomCommand": "receive custom command [MSG], timeout [TIMEOUT]",
    "gui.blocklyText.telloesp32.recvCustomCommandNum": "receive custom num, timeout [TIMEOUT]",
    "gui.blocklyText.telloesp32.serialportOperation": "serialport operation",
    "gui.blocklyText.telloesp32.dataReadable": "Is [SERIAL] data readable?",
    "gui.blocklyText.telloesp32.readSerialData": "read [SERIAL] data",
    "gui.blocklyText.telloesp32.setBaud": "set serial0 baud rate [BAUD]",
    "gui.blocklyText.telloesp32.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.telloesp32.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.telloesp32.seriaRemapping": "set [SERIAL] Rx [RX] Tx [TX] The baud rate [BAUD]",
    "gui.blocklyText.telloesp32.systemResource": "system resource",
    "gui.blocklyText.telloesp32.timer": "timer(ms)",
    "gui.blocklyText.telloesp32.consolePrint": "console output",
    "gui.blocklyText.telloesp32.print": "print [DATA]",
    "gui.blocklyText.telloesp32.telloRiseDown": "fly [RISEDOWN] [NUMS]cm",
    "gui.blocklyText.telloesp32.rise": "up",
    "gui.blocklyText.telloesp32.down": "down",
    "gui.blocklyText.telloesp32.telloTurnDirection": "fly [DIRECTION] [NUMS] cm",
    "gui.blocklyText.telloesp32.turnleft": "left",
    "gui.blocklyText.telloesp32.turnright": "right",
    "gui.blocklyText.telloesp32.turnForward": "forward",
    "gui.blocklyText.telloesp32.turnBack": "back",
    "gui.blocklyText.telloesp32.telloMotorOn": "motor on",
    "gui.blocklyText.telloesp32.telloMotorOff": "motor off",
    "gui.blocklyText.telloesp32.telloTakeOff": "take off",
    "gui.blocklyText.telloesp32.telloThrowFly": "throw fly",
    "gui.blocklyText.telloesp32.telloLanding": "land",
    "gui.blocklyText.telloesp32.telloClockwiseRotation": "rotate [CLOCKWISE] [NUMS] degree",
    "gui.blocklyText.telloesp32.clockwise": "clockwise",
    "gui.blocklyText.telloesp32.counterClockwise": "counterClockwise",
    "gui.blocklyText.telloesp32.telloStartControl": "start control",
    "gui.blocklyText.telloesp32.telloGetHeight": "get [HEIGHT],timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.relatively": "relatively height(dm)",
    "gui.blocklyText.telloesp32.barometer": "barometer height(m)",
    "gui.blocklyText.telloesp32.tof": "TOF height(mm)",
    "gui.blocklyText.telloesp32.telloTemp": "board temperature(°C),timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.lowest": "lowest",
    "gui.blocklyText.telloesp32.highest": "highest",
    "gui.blocklyText.telloesp32.telloAttitude": "attitude Pitch(°), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.pitch": "pitch",
    "gui.blocklyText.telloesp32.roll": "roll",
    "gui.blocklyText.telloesp32.translation": "translation",
    "gui.blocklyText.telloesp32.telloAcceleration": "acceleration(0.001g), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.xaxis": "x axis",
    "gui.blocklyText.telloesp32.yaxis": "y axis",
    "gui.blocklyText.telloesp32.zaxis": "z axis",
    "gui.blocklyText.telloesp32.telloSetSpeed": "set speed to [NUMS]cm/s",
    "gui.blocklyText.telloesp32.telloSpeed": "[XYZAXIS] speed(cm/s), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloPower": "battery( %), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.MissionPad": "MissionPad, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloMissionPadXyz": "MissionPad [XYZ] (cm), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCommandResponse": "command response, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSetyawXMid": "Mid[MID] setyaw [YAW] mid",
    "gui.blocklyText.telloesp32.telloGetMissionPadNum": "get missionPad num, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCurrentSpeed": "Current speed, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloTime": "Motor running time (s), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloWIFISign": "Wi-Fi sign, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSDKVersion": "Tello SDK Version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloBinVersion": "Tello firmware version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloHasHardware": "Tello get hardware version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.rmttWifiVersion": "RMTT Wi-Fi version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSN": "Tello SN code, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloFlyToXYZ": "fly (relative to the aircraft) X[X]cm y[Y]cm z[Z]cm with speed [SPEED]cm/s",
    "gui.blocklyText.telloesp32.getSpeed": "get current seted speed (cm/s)",
    "gui.blocklyText.telloesp32.getBattery": "get the percentage value of the current remaining battery power",
    "gui.blocklyText.telloesp32.getTime": "get motor running time",
    "gui.blocklyText.telloesp32.getWifiNoise": "get Wi-Fi signal to noise ratio",
    "gui.blocklyText.telloesp32.getSdkVersion": "get sdk version",
    "gui.blocklyText.telloesp32.getSnNum": "get sn number",
    "gui.blocklyText.mpy.MQTTinit": "MQTT initial [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInit": "mqtt init [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInitPlatform": "platform",
    "gui.blocklyText.mpy.mpyMqttInitPlatformNormal": "normal",
    "gui.blocklyText.mpy.mpyMqttInitPlatformTencent": "tencent",
    "gui.blocklyText.mpy.MQTTclient_id": "client_id",
    "gui.blocklyText.mpy.MQTTserver": "server",
    "gui.blocklyText.mpy.MQTTport": "port",
    "gui.blocklyText.mpy.MQTTuser": "user",
    "gui.blocklyText.mpy.MQTTpassowrd": "passowrd",
    "gui.blocklyText.mpy.MQTTkeepalive": "keepalive",
    "gui.blocklyText.mpy.MQTTtencent": "MQTT Tencent IOT [SETTING]",
    "gui.blocklyText.mpy.TencentIOT": "TencentIOT",
    "gui.blocklyText.mpy.TencentServer": "server",
    "gui.blocklyText.mpy.TencentPort": "port",
    "gui.blocklyText.mpy.TencentAppld": "Appld",
    "gui.blocklyText.mpy.TencentDevice": "device",
    "gui.blocklyText.mpy.siotServer": "Server",
    "gui.blocklyText.mpy.siotServerUser": "User",
    "gui.blocklyText.mpy.siotServerPass": "Password",
    "gui.blocklyText.mpy.Tencentoutput": "output",
    "gui.blocklyText.mpy.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.mpy.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.mpy.mpy_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.mpy.EasyIOTServer": "server",
    "gui.blocklyText.mpy.EasyIOTServerChina": "China",
    "gui.blocklyText.mpy.EasyIOTServerGlobal": "Global",
    "gui.blocklyText.mpy.EasyIOTclient_id": "client_id",
    "gui.blocklyText.mpy.EasyIOT_id": "Iot_id",
    "gui.blocklyText.mpy.EasyIOT_password": "Iot_pwd",
    "gui.blocklyText.mpy.MQTTconnect": "connect to MQTT",
    "gui.blocklyText.mpy.mpy_MQTTAddSub": "subscribe [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublish": "publish [DATA] to topic [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublishQos1": "publish [DATA] to topic [TOPIC] and saved to database (SIoT V2)",
    "gui.blocklyText.mpy.mpy_waitMSG": "wait for topic message in [MODE] mode",
    "gui.blocklyText.mpy.disconnectMQTT": "disconnect from MQTT",
    "gui.blocklyText.mpy.MQTTlastwill": "set MQTT topic [TOPIC] last will message as [MSG]",
    "gui.blocklyText.mpy.MQTTreceive": "when received [DATA] from topic [TOPIC]",
    "gui.blocklyText.mpy.mqttMsg": "mqtt message",
    "gui.blocklyText.mpy.MQTTmode.block": "block",
    "gui.blocklyText.mpy.MQTTmode.unblock": "unblock",
    "gui.blocklyText.mpyWeather.init": "weather server set [DATA] as [SETTING]",
    "gui.blocklyText.mpyWeather.weatherFeild": "[DATA] weather server common fields [FIELD]",
    "gui.blocklyText.mpyWeather.weatherSituation": "[DATA] weather server Weather now [NOW]",
    "gui.blocklyText.mpyWeather.weatherForecast": "[DATA] weather server Weather forecast [FIELD] for [DATE]",
    "gui.blocklyText.mpyWeather.weatherLivingindex": "[DATA] weather server Living index [FIELD] index",
    "gui.blocklyText.mpyWeather.weatherFeild.name": "name",
    "gui.blocklyText.mpyWeather.weatherFeild.id": "id",
    "gui.blocklyText.mpyWeather.weatherFeild.code": "code",
    "gui.blocklyText.mpyWeather.weatherFeild.path": "path",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone": "timezone",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone_offset": "timezone_offset",
    "gui.blocklyText.mpyWeather.weatherFeild.Updated": "Updated",
    "gui.blocklyText.mpyWeather.weatherFeild.updatetime": "updatetime",
    "gui.blocklyText.mpyWeather.weatherFeild.original": "original",
    "gui.blocklyText.mpyWeather.weatherSituation.text": "text",
    "gui.blocklyText.mpyWeather.weatherSituation.code": "code",
    "gui.blocklyText.mpyWeather.weatherSituation.temperature": "temperature",
    "gui.blocklyText.mpyWeather.weatherdate.today": "today",
    "gui.blocklyText.mpyWeather.weatherdate.tomorrow": "tomorrow",
    "gui.blocklyText.mpyWeather.weatherdate.dayaftertomorrow": "dayaftertomorrow",
    "gui.blocklyText.mpyWeather.province": "province",
    "gui.blocklyText.mpyWeather.city": "city",
    "gui.blocklyText.mpyWeather.weatherforecast.highesttemperature": "highesttemperature",
    "gui.blocklyText.mpyWeather.weatherforecast.lowesttemperature": "lowesttemperature",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweather": "daytimeweather",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweathercode": "daytimeweathercode",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweather": "nightweather",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweathercode": "nightweathercode",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirection": "winddirection",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirectiondegree": "winddirectiondegree",
    "gui.blocklyText.mpyWeather.weatherforecast.windspeed": "windspeed",
    "gui.blocklyText.mpyWeather.weatherforecast.windscale": "windscale",
    "gui.blocklyText.mpyWeather.weatherforecast.date": "date",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Carwashindex": "Carwashindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Dressingindex": "Dressingindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Coldindex": "Coldindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Sportindex": "Sportindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Travelindex": "Travelindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.uvindex": "uvindex",
    "gui.blocklyText.mpyWeather.type": "data type",
    "gui.blocklyText.mpyWeather.temperature": "temperature",
    "gui.blocklyText.mpyWeather.APIkey": "APIkey",
    "gui.blocklyText.mpyWeather.language": "language",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 starts",
    "gui.blocklyText.mega2560.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.mega2560.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "pwm pin [PIN] output [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.mega2560.level.low": "LOW",
    "gui.blocklyText.mega2560.level.high": "HIGH",
    "gui.blocklyText.mega2560.irqRead": "read digital pin [PIN] of Infrared Receiver",
    "gui.blocklyText.mega2560.ultrasonicRead": "read ulrasonic sensor trig [TRIG], echo [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "pin [PIN] play buzzer tone [TONE] for [BEAT] beat",
    "gui.blocklyText.mega2560.harf": "Harf",
    "gui.blocklyText.mega2560.quarter": "Quarter",
    "gui.blocklyText.mega2560.oneInEighth": "One-eighth",
    "gui.blocklyText.mega2560.wholeBeat": "Whole Beat",
    "gui.blocklyText.mega2560.doubleBeat": "Double Beat",
    "gui.blocklyText.mega2560.stop": "Stop",
    "gui.blocklyText.mega2560.servoSet": "Set pin [PIN] servo to [DEGREE] degrees",
    "gui.blocklyText.mega2560.setBaud": "set [SERIAL] baud rate to [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "serial0",
    "gui.blocklyText.mega2560.serialPort1": "serial1",
    "gui.blocklyText.mega2560.serialPort2": "serial2",
    "gui.blocklyText.mega2560.serialPort3": "serial3",
    "gui.blocklyText.mega2560.dataReadable": "Is there data to read on [SERIAL] ?",
    "gui.blocklyText.mega2560.readSerialData": "read data on [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "in string",
    "gui.blocklyText.mega2560.originalOutput": "original output",
    "gui.blocklyText.mega2560.hexOutput": "in HEX",
    "gui.blocklyText.mega2560.wrap": "Wrap",
    "gui.blocklyText.mega2560.noWrap": "No-Wrap",
    "gui.blocklyText.mega2560.readPinLevel": "read pin [PIN] pulse width under [LEVEL] mode, timeout [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "system uptime(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini starts",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo starts",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 starts",
    "gui.blocklyText.arduino.setDigital": "digital pin [PIN] ouput [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "read digital pin [PIN]",
    "gui.blocklyText.arduino.readSimilation": "read analog pin [PIN]",
    "gui.blocklyText.arduino.setPwm": "pwm pin [PIN] output [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.arduino.serialSendStr": "serial output [STRING] [WAY], [LINE]",
    "gui.blocklyText.arduino.originalOutput": "in origin",
    "gui.blocklyText.arduino.stringOutput": "in string",
    "gui.blocklyText.arduino.hexOutput": "in HEX",
    "gui.blocklyText.arduino.wrap": "Wrap",
    "gui.blocklyText.arduino.noWrap": "No-Wrap",
    "gui.blocklyText.arduino.readInfrared": "read digital pin [PIN] of infrared Receiver",
    "gui.blocklyText.arduino.setBaud": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "read pin [PIN] pulse width pattern [LEVEL] timeout [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "no sound",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Arduino Nano starts",
    "gui.blocklyText.vortex.vortexStart": "Vortex robot program starts",
    "gui.blocklyText.vortex.vortexMoveSpeed": "vortex robot [DIRECTION] at speed[SPEED]",
    "gui.blocklyText.vortex.forward": "move forward",
    "gui.blocklyText.vortex.backup": "move backward",
    "gui.blocklyText.vortex.turnLeft": "turn left",
    "gui.blocklyText.vortex.turnRight": "turn right",
    "gui.blocklyText.vortex.setMotor": "motor [MOTOR] [DIRECTION] at speed[SPEED]",
    "gui.blocklyText.vortex.leftWheel": "left",
    "gui.blocklyText.vortex.rightWheel": "right",
    "gui.blocklyText.vortex.setExpression": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.vortex.ledLights": "[DIRECTION][LIGHT] change to [COLOR] in [SECOND]secs.",
    "gui.blocklyText.vortex.all": "all",
    "gui.blocklyText.vortex.rightAhead": "front",
    "gui.blocklyText.vortex.leftRear": "left rear",
    "gui.blocklyText.vortex.rightRear": "right rear",
    "gui.blocklyText.vortex.rightAstern": "rear",
    "gui.blocklyText.vortex.topLights": "top lights",
    "gui.blocklyText.vortex.bottomLights": "bottom lights",
    "gui.blocklyText.vortex.vortexStop": "Vortex stops moving",
    "gui.blocklyText.vortex.setVolume": "set volume to [VALUE]",
    "gui.blocklyText.vortex.playMusic": "play the [VALUE] music",
    "gui.blocklyText.vortex.stopMusic": "stop playing music",
    "gui.blocklyText.vortex.startDance": "start the [VALUE] dance",
    "gui.blocklyText.vortex.stopDance": "stop dancing",
    "gui.blocklyText.vortex.barrierDetection": "obstacle ahead?",
    "gui.blocklyText.vortex.readSensor": "read Grayscale Sensor [SENSOR]",
    "gui.blocklyText.vortex.setSensor": "set grayscale sensors sensitivity to [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "grayscale sensor meets black on its [SENSOR] position?",
    "gui.blocklyText.vortex.positiveFrontLeft": "straight left front",
    "gui.blocklyText.vortex.frontLeft": "left front",
    "gui.blocklyText.vortex.positiveFrontRight": "straight right front",
    "gui.blocklyText.vortex.frontRight": "right front",
    "gui.blocklyText.vortex.backRight": "right rear",
    "gui.blocklyText.vortex.backLeft": "left rear",
    "gui.blocklyText.vortex.initialOldPCB": "initialize to old version (blue PCB)",
    "pen.categoryName": "Pen",
    "pen.changeColorParam": "change pen [COLOR_PARAM] by [VALUE]",
    "pen.changeHue": "change pen color by [HUE]",
    "pen.changeShade": "change pen brightness by [SHADE]",
    "pen.changeSize": "change pen size by [SIZE]",
    "pen.clear": "erase all",
    "pen.colorMenu.brightness": "brightness",
    "pen.colorMenu.color": "color",
    "pen.colorMenu.saturation": "saturation",
    "pen.colorMenu.transparency": "transparency",
    "pen.penDown": "pen down",
    "pen.penUp": "pen up",
    "pen.setColor": "set pen color to [COLOR]",
    "pen.setColorParam": "set pen [COLOR_PARAM] to [VALUE]",
    "pen.setHue": "set pen color to [HUE]",
    "pen.setShade": "set pen brightness to [SHADE]",
    "pen.setSize": "set pen size to [SIZE]",
    "pen.stamp": "stamp",
    "music.changeTempo": "change tempo by [TEMPO]",
    "music.drumBass": "(2) Bass Drum",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Closed Hi-Hat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Cowbell",
    "music.drumCrashCymbal": "(4) Crash Cymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Hand Clap",
    "music.drumOpenHiHat": "(5) Open Hi-Hat",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Snare Drum",
    "music.drumTambourine": "(7) Tambourine",
    "music.drumTriangle": "(12) Triangle",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Wood Block",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Bassoon",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Choir",
    "music.instrumentClarinet": "(10) Clarinet",
    "music.instrumentElectricGuitar": "(5) Electric Guitar",
    "music.instrumentElectricPiano": "(2) Electric Piano",
    "music.instrumentFlute": "(12) Flute",
    "music.instrumentGuitar": "(4) Guitar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Music Box",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxophone",
    "music.instrumentSteelDrum": "(18) Steel Drum",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibraphone",
    "music.instrumentWoodenFlute": "(13) Wooden Flute",
    "music.midiPlayDrumForBeats": "play drum [DRUM] for [BEATS] beat",
    "music.midiSetInstrument": "set instrument to [INSTRUMENT]",
    "music.playDrumForBeats": "play drum [DRUM] for [BEATS] beat",
    "music.playNoteForBeats": "play note [NOTE] for [BEATS] beat",
    "music.restForBeats": "rest for [BEATS] beats",
    "music.setInstrument": "set instrument to [INSTRUMENT]",
    "music.setTempo": "set tempo to [TEMPO]",
    "gui.blocklyText.microbitV2.logo": "LOGO(V2)",
    "gui.blocklyText.microbitV2.ls": "LS(V2)",
    "gui.blocklyText.microbit.microbitStarts": "micro:bit starts",
    "gui.blocklyText.microbit.interruptExcute": "interrupt pin [PIN] mode [MODE] excute",
    "gui.blocklyText.microbit.cancleInterrupt": "cancel interrupt pin [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "when received wireless data",
    "gui.blocklyText.microbit.whenBtnPress": "when button [REFERENCE] pressed",
    "gui.blocklyText.microbit.whenPinConnected": "when microbit pin [REFERENCE] connected",
    "gui.blocklyText.microbit.whenPosChange": "when [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "display pattern [PIC]",
    "gui.blocklyText.microbit.showInput": "display [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "stop animation",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] coordinates of point （x:[XAXIS], y:[YXAXIS]）",
    "gui.blocklyText.microbit.hideAllLights": "clear all dot matrixes",
    "gui.blocklyText.microbit.microbitEnableLight": "Lattice Screen [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "brightness",
    "gui.blocklyText.microbit.showLightWithBrightness": "set brightness [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "show coordinates of point (x:[XAXIS], y:[YXAXIS]), brightness [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "pin [PIN] play sound [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "pin [PIN] play sound [SOUND] until done",
    "gui.blocklyText.microbit.playNote": "pin [PIN] play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.microbit.true": "enable",
    "gui.blocklyText.microbit.false": "disable",
    "gui.blocklyText.maqueen.playSound": "pin P0 play sound [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "pin P0 play sound [SOUND] until down",
    "gui.blocklyText.maqueen.playNote": "pin P0 play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.maqueen.RGB0": "RGB0  (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1  (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2  (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3  (3)",
    "gui.blocklyText.maqueen.All": "All  (-1)",
    "gui.blocklyText.microbit.changeTempo": "change tempo (bpm) by [VALUE]",
    "gui.blocklyText.microbit.setTempo": "set tempo (bpm) to [VALUE]",
    "gui.blocklyText.microbit.getTempo": "tempo (bpm)",
    "gui.blocklyText.microbit.stopSound": "stop background playback",
    "gui.blocklyText.microbit.btnIsPressed": "[BUTTON] button pressed?",
    "gui.blocklyText.microbit.pinIsConnected": "[PIN] pin connected?",
    "gui.blocklyText.microbit.isMove": "current state [TYPE]?",
    "gui.blocklyText.microbit.compass": "read compass orientation",
    "gui.blocklyText.microbit.temperature": "read temperature",
    "gui.blocklyText.microbit.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.microbit.analogWrite": "analog pin [PIN] output [VALUE]",
    "gui.blocklyText.microbit.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.microbit.up": "logo up",
    "gui.blocklyText.microbit.down": "logo down",
    "gui.blocklyText.microbit.left": "tilt to left",
    "gui.blocklyText.microbit.right": "tilt to right",
    "gui.blocklyText.microbit.faceUp": "face up",
    "gui.blocklyText.microbit.faceDown": "face down",
    "gui.blocklyText.microbit.freefall": "freefall",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "shake",
    "gui.blocklyText.microbit.show": "show",
    "gui.blocklyText.microbit.start": "open",
    "gui.blocklyText.microbit.stop": "close",
    "gui.blocklyText.microbit.hide": "hide",
    "gui.blocklyText.microbit.low": "LOW",
    "gui.blocklyText.microbit.high": "HIGH",
    "gui.blocklyText.microbit.microbitReadBrightness": "read ambient light brightness",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] wireless communication",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "set wireless channel to [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "send string [TEXT] via wireless",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "receive data via wireless",
    "gui.blocklyText.microbit.strength": "strength",
    "gui.blocklyText.microbit.acceleration": "read acceleration(m-g)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "read magnetic force(µT)[TYPE]",
    "gui.blocklyText.microbit.microbitAccurateCompass": "accurate compass",
    "gui.blocklyText.microbit.microbitGetSoundLevel": "get sound level(V2)",
    "gui.blocklyText.microbit.print": "Print [DATA]",
    "gui.blocklyText.microbit.goUp": "up",
    "gui.blocklyText.microbit.goDown": "down",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "when received [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "wireless data",
    "gui.blocklyText.calliope.whenBtnPress": "when button [REFERENCE] pressed",
    "gui.blocklyText.calliope.calliopeReadBrightness": "read brightness",
    "gui.blocklyText.calliope.playNote": "[PIN] play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.calliope.playSoundUntil": "[PIN] play sound [SOUND] until done",
    "gui.blocklyText.calliope.playSound": "[PIN] play sound [SOUND]",
    "gui.blocklyText.calliope.calliopeEnableLight": "screen [ENABLE]",
    "gui.blocklyText.calliope.hideAllLights": "clear screen",
    "gui.blocklyText.calliope.whenPinConnected": "when pin [REFERENCE] connected",
    "gui.blocklyText.calliope.loudness": "read loudness",
    "gui.blocklyText.calliope.rotation": "read rotation angle(°) [TYPE]",
    "gui.blocklyText.calliope.calliopeControlSinglelMotor": "single motor control [RANGE]%",
    "gui.blocklyText.calliope.calliopeSingleMotorStop": "single motor [STOPTYPE]",
    "gui.blocklyText.calliope.calliopeDoubleMotor": "control motor [MOTORTYPE] [RANGE]%",
    "gui.blocklyText.calliope.calliopeDoubleMotorStop": "stop motor [MOTORTYPE]",
    "gui.blocklyText.calliope.calliopeLight": "set rgb-led [COLOR]",
    "gui.blocklyText.calliope.setRGBColor": "red [RED] green [GREEN] blue [BLUE] white [WHITE]",
    "gui.blocklyText.calliope.calliopeCloseRGB": "turn rgb-led off",
    "gui.blocklyText.calliope.pitch": "pitch",
    "gui.blocklyText.calliope.roll": "roll",
    "gui.blocklyText.calliope.boardbuzzer": "speaker",
    "gui.blocklyText.calliope.motorbreak": "break",
    "gui.blocklyText.calliope.motorcoast": "coast",
    "gui.blocklyText.calliope.motorsleep": "sleep",
    "gui.blocklyText.calliope.open": "turn on",
    "gui.blocklyText.calliope.close": "turn off",
    "gui.blocklyText.bos0001.readRotary": "read [PIN] knob module value",
    "gui.blocklyText.bos0002.IsPINPress": "[PIN] button pressed?",
    "gui.blocklyText.bos0003.IsPINPress": "[PIN] self-locking switch pressed?",
    "gui.blocklyText.bos0004.readLight": "read [PIN] light module value",
    "gui.blocklyText.bos0006.readMoisture": "read [PIN] moisture module value",
    "gui.blocklyText.bos0007.readFlame": "read [PIN] flame module value",
    "gui.blocklyText.bos0008.IsPINTouch": "[PIN] touch to connect?",
    "gui.blocklyText.bos0009.readSound": "read [PIN] sound module value",
    "gui.blocklyText.bos0010.readSmartGray": "read [PIN] gray module value",
    "gui.blocklyText.bos0012.IsConductivitySwitch": "[PIN] conductivity module is turned on?",
    "gui.blocklyText.bos0013.IsMotionSensor": "[PIN] motion module trigger?",
    "gui.blocklyText.bos0038.readSoilHumidity": "read [PIN] soil moisture value",
    "gui.blocklyText.bos0045.readUltrasonicDistance": "read [PIN] ultrasonic ranging value",
    "gui.blocklyText.bos0016.lightBrightLED": "[CONTROL][PIN] highlight LED light",
    "gui.blocklyText.bos0017.LEDModule": "[CONTROL][PIN]LED light",
    "gui.blocklyText.bos0019.LEDStringLight": "[CONTROL][PIN] fixed color light strip",
    "gui.blocklyText.bos0021.fanModule": "[CONTROL][PIN] Fan",
    "gui.blocklyText.bos0022.voiceRecorderModule": "[PIN] to play a recording",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot Starts",
    "gui.blocklyText.maxbot.playSound": "play sound [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "play sound [SOUND] until done",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "collision occurred on the [LEFTRIGHT] ?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "black line detected on the [LEFTRIGHT]  ?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "motor [MOTOR] [DIR] at speed [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "read (P1,P2) ulrasonic sensor [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "read ulrasonic sensor Unit [UNIT], trig [TRIG], echo [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "FORWARD",
    "gui.blocklyText.maxbot.BACKWARD": "BACKWARD",
    "gui.blocklyText.motorbit.CW": "CW",
    "gui.blocklyText.motorbit.CCW": "CCW",
    "gui.blocklyText.maxbot.CW": "CW",
    "gui.blocklyText.maxbot.CCW": "CCW",
    "gui.blocklyText.maxbot.ANGLE": "degrees",
    "gui.blocklyText.maxbot.CIRCLE": "revolutions",
    "gui.blocklyText.maxbot.Left": "left",
    "gui.blocklyText.maxbot.Right": "right",
    "gui.blocklyText.maxbot.cm": "cm",
    "gui.blocklyText.maxbot.inch": "inch",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit Starts",
    "gui.blocklyText.MotorBit.MotorRun": "motor [MOTOR] rotate [DIR] at speed [SPEED]",
    "gui.blocklyText.MotorBit.MotorStop": "motor [MOTOR] stops",
    "gui.blocklyText.MotorBit.Stepper": "stepper [STEPPER] rotate [ROTATEDIR] for [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.pythondfr0548.MotorRun": "motor [MOTOR] rotate [DIR] at speed [SPEED]",
    "gui.blocklyText.pythondfr0548.MotorStop": "motor [MOTOR] stops",
    "gui.blocklyText.pythondfr0548.Stepper": "stepper [STEPPER] rotate [ROTATEDIR] for [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "ALL",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen Starts",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "read (P1,P2)ultrasonic sensor (cm)",
    "gui.blocklyText.maqueen.motorStop": "robot (left and right motors) stops",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] LED light",
    "gui.blocklyText.maqueen.redLineSensor": "read [LEFTRIGHT] line tracking sensor",
    "gui.blocklyText.maqueen.robotSpeedMove": "robot [DIR] at speed [SPEED]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "motor [LEFTRIGHT] move [DIR] at speed [SPEED]",
    "gui.blocklyText.maqueen.ledLeft": "Left(P8)",
    "gui.blocklyText.maqueen.ledRight": "Right(P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "Right(P12) (not available)",
    "gui.blocklyText.maqueen.lineSensorLeft": "Left(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "Right(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "when (P16) received [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "infrared signal",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "read(P16) infrared signal",
    "gui.blocklyText.maqueen.numberOfLightsBright": "pin P15 4 RGB LEDs brightness [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "pin P15 the [NUM1] LED display color [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "pin P15 LED [NUM1] to [NUM2] show gradient color from [COLOR1] to [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "pin P15 shift pixels by [NUM]",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "pin P15 rotate pixels by [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "pin P15 LED brightness [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "pin P15 LED [NUM1] to [NUM2] show histogram, value [VALUE], max [MAX]",
    "gui.blocklyText.maqueen.clear": "pin P15 clear all LEDs",
    "gui.blocklyText.maqueen.rgbColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.tello.telloStarts": "Tello Starts",
    "gui.blocklyText.tello.telloControlPlane": "control plane",
    "gui.blocklyText.tello.telloTakeOff": "take off",
    "gui.blocklyText.tello.telloLanding": "land",
    "gui.blocklyText.tello.telloRise": "fly up [NUMS]cm",
    "gui.blocklyText.tello.telloDown": "fly down [NUMS]cm",
    "gui.blocklyText.tello.telloGoahead": "fly forward [NUMS]cm",
    "gui.blocklyText.tello.telloBack": "fly backward [NUMS]cm",
    "gui.blocklyText.tello.telloTurnLeft": "fly left [NUMS]cm",
    "gui.blocklyText.tello.telloTurnRight": "fly right [NUMS]cm",
    "gui.blocklyText.tello.telloClockwiseRotation": "rotate [NUMS] degree clockwise",
    "gui.blocklyText.tello.telloCounterclockwiseSelection": "rotate [NUMS] degree counter clockwise",
    "gui.blocklyText.tello.telloSpeed": "set speed to [NUMS]cm/s",
    "gui.blocklyText.tello.telloRoll": "flip [ROLL]",
    "gui.blocklyText.tello.telloPower": "power?",
    "gui.blocklyText.tello.telloRollForward": "forward(f)",
    "gui.blocklyText.tello.telloRollBack": "back(b)",
    "gui.blocklyText.tello.telloRollLeft": "left(l)",
    "gui.blocklyText.tello.telloRollRight": "right(r)",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] received data?",
    "gui.blocklyText.obloq.initialParameter": "obloq mqtt initialization: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.initialI2CParameter": "Obloq mqtt initial parameter [PARAMETER] interface I2C address 0x16",
    "gui.blocklyText.obloq.httpInitialI2CParameter": "Obloq http initial parameter [PARAMETER] interface I2C address 0x16",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.obloq.sendmsgSaveToCloudPlatform": "Obloq send message [MSG] to cloud platform [TOPIC] and saved to database (SIoT V2)",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq read the message from [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq read the message",
    "gui.blocklyText.obloq.httpInitialParameter": "obloq http initialization: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "add subscribe [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "Wi-Fi name",
    "gui.blocklyText.obloq.password": "password",
    "gui.blocklyText.obloq.iotService": "IOT Service",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "IP Address",
    "gui.blocklyText.obloq.port": "Port",
    "gui.blocklyText.obloq.addTopic": "Add Topic, only add up to 4",
    "gui.blocklyText.obloq.deleteTopic": "Delete Topic",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "Server",
    "gui.blocklyText.obloq.global": "Global",
    "gui.blocklyText.obloq.china": "China",
    "gui.blocklyText.obloq.softwareSerial": "Software Serial",
    "gui.blocklyText.obloq.hardwareSerial": "Hardware Serial",
    "gui.blocklyText.obloq.hardwareSerial1": "Hardware Serial1",
    "gui.blocklyText.obloq.hardwareSerial2": "Hardware Serial2",
    "gui.blocklyText.obloq.hardwareSerial3": "Hardware Serial3",
    "gui.blocklyText.obloq.WhenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq message",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq get [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Debug info",
    "gui.blocklyText.obloq.ERROR": "Error code",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.infraredEmission.send": "Infrared send pin [PIN] Send message: address [ADDRESS] command [COMMAND]",
    "gui.blocklyText.oled2864.displayInit": "OLED display init address [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 display [TEXT] in line [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 display [TEXT] at position X: [X] Y: 16*[Y]",
    "gui.blocklyText.oled2864.screenRotation": "rotate screen to [ROTATION] degrees",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 clear",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "read pin [PIN] [DHT] [TYPE]",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx pin [PIN] PM concentration value [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 ultrasonic initialization Name [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04 ultrasonic distance measurement unit [UNIT]",
    "gui.blocklyText.hcsr04.readData": "read HC-SRO4 Trig [PINT] Echo [PINE] unit [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx pin [PIN] 0.1 liters of air in [DIAMETER] number of particles",
    "gui.blocklyText.sen0170.readWindspeed": "read pin [PIN] wind scale",
    "gui.blocklyText.sen0228.init": "Initialize ambient light (VEML7700) sensor I2C address 0x10",
    "gui.blocklyText.sen0228.readAmbientLight": "read Ambient Light(VEML7700)",
    "gui.blocklyText.pythonsen0228.init": "Initialize ambient light (VEML7700) sensor [NAME] I2C address 0x10",
    "gui.blocklyText.pythonsen0228.readAmbientLight": "read [NAME] Ambient Light(VEML7700)",
    "gui.blocklyText.sen0097.init": "Initialize ambient light (BH1750) sensor I2C address 0x23",
    "gui.blocklyText.sen0097.readAmbientLight": "read Ambient Light(BH1750)",
    "gui.blocklyText.dfr0026.readAmbientLight": "read pin [PIN] Ambient light",
    "gui.blocklyText.pythondfr0026.extensionName": "ambient light",
    "gui.blocklyText.pythondfr0026.readAmbientLight": "read pin [PIN] Ambient light",
    "gui.blocklyText.sen0177.readDustSensor": "read [TYPE] (ug/m3) interface [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "pin(D) [PIN] pin(MCLR) [RST] current gesture [TYPE]?",
    "gui.blocklyText.sen0203.readHeartRate": "read pin [PIN] [TYPE] switch to [SWITCH]",
    "gui.blocklyText.pythonsen0203.init": "heart rate sensor [NAME] initializes pin [PIN] mode [MODE]",
    "gui.blocklyText.pythonsen0203.update": "[NAME] get data once",
    "gui.blocklyText.pythonsen0203.readHeartRate": "[NAME] Parse heart rate from the data (times per minute)",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] pin [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "read pin [PIN] grayscale",
    "gui.blocklyText.pythondfr0022.extensionName": "Grayscale",
    "gui.blocklyText.pythondfr0022.readGrayscale": "read pin [PIN] grayscale",
    "gui.blocklyText.motucamera.initIICSerial": "mu camera init [MU] interface [IICSERIAL] until init success I2C address 0x60&0x61&0x62&0x63",
    "gui.blocklyText.motucamera.resetConfig": "mu camera [MU] reset to default",
    "gui.blocklyText.motucamera.algorithmSwitch": "mu camera [MU] [SWITCH] algorithm [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "mu camera [MU] [LED] when detect [COLOR1] when undetect [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "mu camera [MU] set algorithm [ALGORITHM] level [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "mu camera [MU] digital zoom [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "mu camera [MU] set baudrate [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "mu camera [MU] set white balance [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "mu camera [MU] high FPS mode [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "mu camera [MU] is detect [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": "mu camera [MU] is detect color recogniza x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "mu camera [MU] is detect color detect color = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "mu camera get [MU] alogrithm [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "mu camera [MU] color recognize [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "mu camera [MU] shape card [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "mu camera [MU] traffic card [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "mu camera [MU] number card [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "mu camera [MU] color recognize color = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "Color Detect",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "Color Recognize",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "Ball Detect",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "Body Detect",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "Shape Card",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "Traffic Card",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "Number Card",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "enable",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "disable",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "white",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "white",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "Auto",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "Speed",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "Balance",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "Accuracy",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "Auto",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "Lock",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "white",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "yellow",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "Auto",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "Level1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "Level2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "Level3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "Level4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "Level5",
    "gui.blocklyText.motucamera.setHighFPSOff": "OFF",
    "gui.blocklyText.motucamera.setHighFPSOn": "ON",
    "gui.blocklyText.motucamera.colorDetectBlack": "black",
    "gui.blocklyText.motucamera.colorDetectWhite": "white",
    "gui.blocklyText.motucamera.colorDetectRed": "red",
    "gui.blocklyText.motucamera.colorDetectYellow": "yellow",
    "gui.blocklyText.motucamera.colorDetectGreem": "green",
    "gui.blocklyText.motucamera.colorDetectCayan": "cayan",
    "gui.blocklyText.motucamera.colorDetectBlue": "blue",
    "gui.blocklyText.motucamera.colorDetectPerple": "perple",
    "gui.blocklyText.motucamera.colorDetectOthers": "others",
    "gui.blocklyText.motucamera.colorDetectPositionX": "x position",
    "gui.blocklyText.motucamera.colorDetectPositionY": "y position",
    "gui.blocklyText.motucamera.colorDetectPositionW": "width",
    "gui.blocklyText.motucamera.colorDetectPositionH": "height",
    "gui.blocklyText.motucamera.colorDetectPositionL": "label",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "red channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "green channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "blue channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "label channel",
    "gui.blocklyText.motucamera.shapeCardTick": "tick",
    "gui.blocklyText.motucamera.shapeCardCross": "cross",
    "gui.blocklyText.motucamera.shapeCardCircle": "circle",
    "gui.blocklyText.motucamera.shapeCardSquare": "square",
    "gui.blocklyText.motucamera.shapeCardTriangle": "triangle",
    "gui.blocklyText.motucamera.trafficCardForward": "Forward",
    "gui.blocklyText.motucamera.trafficCardLeft": "Left",
    "gui.blocklyText.motucamera.trafficCardRight": "Right",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "Turn Around",
    "gui.blocklyText.motucamera.trafficCardStop": "Stop",
    "gui.blocklyText.motucamera.lightSensorUse": "mu camera [MU] light sensor enable [LIGHTFUNC] detection",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "mu camera [MU] light sensor sensitivity [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "mu camera [MU] light sensor read proximity detection",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "mu camera [MU] light sensor read ambient light detection",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "mu camera [MU] light sensor read once gesture and saved",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "mu camera [MU] light sensor gesture =  [GESTURE]",
    "gui.blocklyText.motucamera.upward": "upward",
    "gui.blocklyText.motucamera.downward": "downward",
    "gui.blocklyText.motucamera.leftward": "leftward",
    "gui.blocklyText.motucamera.rightward": "rightward",
    "gui.blocklyText.motucamera.pull": "pull",
    "gui.blocklyText.motucamera.push": "push",
    "gui.blocklyText.motucamera.none": "none",
    "gui.blocklyText.motucamera.default": "default",
    "gui.blocklyText.motucamera.low": "low",
    "gui.blocklyText.motucamera.middle": "middle",
    "gui.blocklyText.motucamera.high": "high",
    "gui.blocklyText.motucamera.proximity": "proximity",
    "gui.blocklyText.motucamera.ambientLight": "ambient light",
    "gui.blocklyText.motucamera.gesture": "gesture",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi initialize port [SERIALPORT] RX[RX] TX[TX] UART baudrate [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi set ssid [SSID] password [PASSWORD] mode [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi conection succeeded？",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi disconnect",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi set target IP [IP] port [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi read target IP",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi read local IP",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi read",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi Write [WRITE]",
    "gui.blocklyText.motucamera.client": "client",
    "gui.blocklyText.motucamera.hot-spot": "hot-spot",
    "gui.blocklyText.dfr0552.init": "DA module init I2C address [ADDR] voltage reference [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "DA module output triangular wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "DA module output sine wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "DA module output voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "DA module memory voltage [VOLTAGE]mV",
    "gui.blocklyText.pythonsen0334.SHT31Init": "SHT31 Init [ADDRESS]",
    "gui.blocklyText.pythonsen0334.SHT31GetData": "read SHT31 data [DATA]",
    "gui.blocklyText.pythonsen0334.TemperC": "TemperC",
    "gui.blocklyText.pythonsen0334.TemperF": "TemperF",
    "gui.blocklyText.pythonsen0334.HumidRH": "HumidRH",
    "gui.blocklyText.pythonsen0334.Number": "Number",
    "gui.blocklyText.pythonsen0460.begin": "Initialize device until success  set I2C addr 0x19",
    "gui.blocklyText.pythonsen0460.readConcentration": "read [KAIGUAN] concentration in [ENVIRONMENT] (ug/m3)",
    "gui.blocklyText.pythonsen0460.readCount": "read the number of particles with size of [PARTICLEDIAMETER] in 0.1L volume of air",
    "gui.blocklyText.pythonsen0460.readDeviceVersion": "get version",
    "gui.blocklyText.pythonsen0460.PM2_5": "PM2.5",
    "gui.blocklyText.pythonsen0460.PM1_0": "PM1.0",
    "gui.blocklyText.pythonsen0460.PM10": "PM10",
    "gui.blocklyText.pythonsen0460.standard": "standard particles",
    "gui.blocklyText.pythonsen0460.atmospheric": "atmospheric environment",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_0_3": "0.3um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_0_5": "0.5um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_1_0": "1.0um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_2_5": "2.5um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_5_0": "5.0um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_10": "10um",
    "gui.blocklyText.pythonsen0303.VEML6075Init": "Initialize VEML6075 UV sensor wait until successful",
    "gui.blocklyText.pythonsen0303.VEML6075ReadyUV": "Read VEML6075 [UV] Strength",
    "gui.blocklyText.pythonsen0303.VEML6075Ready": "Read VEML6075 UV Index",
    "gui.blocklyText.pythonsen0303.VEML6075ReadyME": "Read VEML6075 Radiation Emitted Mw/cm2",
    "gui.blocklyText.pythonsen0303.UVA": "Long-wave UV(UVA)",
    "gui.blocklyText.pythonsen0303.UVB": "Medium-wave UV(UVB)",
    "gui.blocklyText.pythonsen0377.MEMSgas_Iint": "MEMSgas begin A0[A0] A1[A1]",
    "gui.blocklyText.pythonsen0377.MEMSgaswarm_up_time": "MEMSgas warm_up_time [MIN] minute",
    "gui.blocklyText.pythonsen0377.MEMSgas_get_power_mode": "MEMSgas get_power_mode ?",
    "gui.blocklyText.pythonsen0377.MEMSgasset_mode": "MEMSgas set_mode [MODE]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_adc_data": "MEMSgas get_adc_data[DATA]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_gas_exist": "MEMSgas get_gas_exist[TYPE]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_gas_ppm": "MEMSgas get_gas_ppm[TYPE]",
    "gui.blocklyText.pythonsen0377.wakeup_mode": "wakeup_mode",
    "gui.blocklyText.pythonsen0377.sleep_mode": "sleep_mode",
    "gui.blocklyText.pythonsen0377.OX_MODE": "OX_MODE",
    "gui.blocklyText.pythonsen0377.RED_MODE": "RED_MODE",
    "gui.blocklyText.pythonsen0377.CO": "Carbon Monoxide",
    "gui.blocklyText.pythonsen0377.CH4": "Methane",
    "gui.blocklyText.pythonsen0377.C2H5OH": "Ethanol",
    "gui.blocklyText.pythonsen0377.C3H8": "Propane",
    "gui.blocklyText.pythonsen0377.C4H10": "Iso Butane",
    "gui.blocklyText.pythonsen0377.H2": "Hydrogen",
    "gui.blocklyText.pythonsen0377.H2S": "Hydrothion",
    "gui.blocklyText.pythonsen0377.NH3": "Ammonia",
    "gui.blocklyText.pythonsen0377.NO": "Nitric Oxide",
    "gui.blocklyText.pythonsen0377.NO2": "Nitrogen Dioxide",
    "gui.blocklyText.dfr0699.init": "Audio recording and playback module sensor initialization address [ADDR] Wait until success",
    "gui.blocklyText.dfr0699.chooseAudioNum": "Select the audio number [AUDIO]",
    "gui.blocklyText.dfr0699.getAudioNum": "Get the current recording number",
    "gui.blocklyText.dfr0699.reRecord": "Re-record",
    "gui.blocklyText.dfr0699.startRecording": "start recording",
    "gui.blocklyText.dfr0699.recordingEnd": "recording end",
    "gui.blocklyText.dfr0699.recordingIsExist": "Does the recording exist?",
    "gui.blocklyText.dfr0699.recordingPlay": "recording play",
    "gui.blocklyText.dfr0699.waitRecordingPlay": "wait recording play end",
    "gui.blocklyText.dfr0699.fingerprintSpeechSynthesisMode": "Speech synthesis speech [VALUE] text [STR] number [MODE]",
    "gui.blocklyText.dfr0699.china": "Chinese",
    "gui.blocklyText.dfr0699.english": "English",
    "gui.blocklyText.dfr0699.fingerprintSpeechSynthesis": "Speech synthesized speech [VALUE] Number [NUM]",
    "gui.blocklyText.dfr0699.alternately": "REPLACE",
    "gui.blocklyText.dfr0699.synthesis": "SYNTHESIS",
    "gui.blocklyText.sen0117.init": "Initialize module interface [SERIALPORT] Rx[RX] Tx[TX] Busy[BUSY]",
    "gui.blocklyText.sen0117.playText": "Plays text [TEXT] background music [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "Plays voice prompt [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "Plays chord prompts [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "Set up the [INDEX] to [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "Setting the speech rate [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "Get the speech synthesis module [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "Prospects volume",
    "gui.blocklyText.sen0117.Background": "Background volume",
    "gui.blocklyText.sen0117.Speed": "Speed",
    "gui.blocklyText.sen0117.playChordCueTone": "Plays chord ring tones [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.No": "No (0)",
    "gui.blocklyText.sen0117.setPlayMode": "Set the playback mode to [MODE]",
    "gui.blocklyText.sen0117.pause": "Pause",
    "gui.blocklyText.sen0117.resume": "Resume",
    "gui.blocklyText.sen0117.stop": "Stop",
    "gui.blocklyText.sen0117.isIdle": "Play finish?",
    "gui.blocklyText.dfr0576.selectPort": "Select port [PORT]",
    "gui.blocklyText.dfr0576.begin": "Initialize the I2C cascade module address is [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680 initializes I2C address 0x77",
    "gui.blocklyText.sen0248.readBME680": "Get the BME680 sensor [TYPE]",
    "gui.blocklyText.pythonsen0248.bme680Init": "BME680 initializes I2C address 0x77",
    "gui.blocklyText.pythonsen0248.updateBme680": "get BME680 sensor data once",
    "gui.blocklyText.pythonsen0248.readBME680": "Parse [TYPE] from the data",
    "gui.blocklyText.sen0248.Temperature": "Temperature (°C)",
    "gui.blocklyText.sen0248.Pressure": "Pressure (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Humidity (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0248.GasResistance": "Gas Resistance (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "initialize the I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Change I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Read ultrasonic sensor distance(cm)",
    "gui.blocklyText.sen0304.readTemperature": "Read ultrasonic sensor temperature(°C)",
    "gui.blocklyText.pythonsen0304.initialIICAddr": "initialize the I2C ultrasonic sensor [NAME] address [ADDR]",
    "gui.blocklyText.pythonsen0304.changeI2CAddr": "Change I2C ultrasonic sensor [NAME] address [ADDR] (Power off restart effective)",
    "gui.blocklyText.pythonsen0304.readDistance": "Read ultrasonic sensor [NAME] distance(cm)",
    "gui.blocklyText.pythonsen0304.readTemperature": "Read ultrasonic sensor [NAME] temperature(°C)",
    "gui.blocklyText.sen0307.readDistance": "Read [PIN] analog ultrasonic wave(cm)",
    "gui.blocklyText.pythonsen0307.init": "analog ultrasonic [NAME] initial [PIN]",
    "gui.blocklyText.pythonsen0307.readDistance": "[NAME] Read analog ultrasonic wave(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 initialization address [ADDR] mode [MODE]",
    "gui.blocklyText.sen0250.Step": "Step",
    "gui.blocklyText.sen0250.Measure": "Measure",
    "gui.blocklyText.sen0250.readAccelerated": "Get the [AXIS] axis Acceleration(g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 get step number",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "Gyro",
    "gui.blocklyText.sen0250.Acc": "Acceleration (g)",
    "gui.blocklyText.sen0224.init": "Initialize the LIS2DH sensor I2C address 0x18",
    "gui.blocklyText.sen0224.readAccelerated": "Read acceleration value of LIS2DH sensor [AXIS] axis (mg)",
    "gui.blocklyText.dfr0023.readLM35": "read pin [PIN] LM35 temprature(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "when pin [PIN] detected vibration",
    "gui.blocklyText.dfr0028.readTiltSensor": "pin [PIN] detected tilt?",
    "gui.blocklyText.pythondfr0028.extensionName": "Tilt",
    "gui.blocklyText.pythondfr0028.readTiltSensor": "pin [PIN] detected tilt?",
    "gui.blocklyText.dfr0029.readPress": "pin [PIN] button pressed?",
    "gui.blocklyText.pythondfr0029.extensionName": "Button",
    "gui.blocklyText.pythondfr0029.readPress": "pin [PIN] is button pressed?",
    "gui.blocklyText.dfr0030.readTouch": "pin [PIN] detected touch?",
    "gui.blocklyText.pythondfr0030.extensionName": "Touch",
    "gui.blocklyText.pythondfr0030.readTouch": "pin [PIN] detects touch?",
    "gui.blocklyText.dfr0033.readMagnetic": "pin [PIN] detected magnet?",
    "gui.blocklyText.pythondfr0033.extensionName": "Magnetic",
    "gui.blocklyText.pythondfr0033.readMagnetic": "pin [PIN] detected magnet?",
    "gui.blocklyText.dfr0034.readSound": "read pin [PIN] sound volume",
    "gui.blocklyText.pythondfr0034.extensionName": "Sound",
    "gui.blocklyText.pythondfr0034.readSound": "read pin [PIN] sound volume",
    "gui.blocklyText.dfr0094.readIRReceived": "read pin [PIN] IR data",
    "gui.blocklyText.dfr0094.onReceiveEvent": "When the infrared signal is received Pin [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "address",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "command",
    "gui.blocklyText.midi.midiInit": "Init MIDI module interface [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.midi.midiOutput": "MIDI output channel [CHANNEL] pitch [PITCH] speed [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "read pin [PIN] Carbon Monoxide (CO) sensor",
    "gui.blocklyText.pythonsen0132.extensionName": "CO",
    "gui.blocklyText.pythonsen0132.readCarbonMonoxide": "read pin [PIN] Carbon Monoxide (CO) sensor",
    "gui.blocklyText.dfr0051.readVoltage": "read pin [PIN] Voltage(V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "read pin [PIN] Piezo Disk Vibration Sensor",
    "gui.blocklyText.pythondfr0052.extensionName": "Vibration",
    "gui.blocklyText.pythondfr0052.readPiezoSensor": "read pin [PIN] Piezo Disk Vibration Sensor",
    "gui.blocklyText.dfr0058.readRotation": "read pin [PIN] Rotation Sensor",
    "gui.blocklyText.pythondfr0058.extensionName": "Rotation",
    "gui.blocklyText.pythondfr0058.readRotation": "read pin [PIN] Rotation Sensor",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "read pin [PIN] JoyStick [AXIS] Axis",
    "gui.blocklyText.dfr0061.readJoyStickButton": "pin [PIN] JoyStick Button(Z) pressed?",
    "gui.blocklyText.pythondfr0061.extensionName": "JoyStick",
    "gui.blocklyText.pythondfr0061.readJoyStickAxis": "read pin [PIN] JoyStick [AXIS] Axis",
    "gui.blocklyText.pythondfr0061.readJoyStickButton": "pin [PIN] JoyStick Button(Z) pressed?",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut init data pin [DATAPIN] RTC pin [RTCPIN] cls pin [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Digital tube display segment code 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Digital tube display number [NUM]",
    "gui.blocklyText.dfr0072.dfr0072Dot": "Digital tube display dot [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "true",
    "gui.blocklyText.dfr0072.dotFalse": "false",
    "gui.blocklyText.dfr0072.numOff": "off",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Read lithium battery [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "voltage(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "remainingBattery(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD module init I2C address [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD module read [PIN] voltage (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD module get aisle [AISLE] voltage difference",
    "gui.blocklyText.pythondfr0553.dfr0553InitIICAddr": "AD module [NAME] init I2C address [ADDR]",
    "gui.blocklyText.pythondfr0553.dfr0553ReadVoltage": "AD module [NAME] read [PIN] voltage (mV)",
    "gui.blocklyText.pythondfr0553.dfr0553GetAisleVoltageDifference": "AD module [NAME] get aisle [AISLE] voltage difference",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 and 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 and 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 and 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 and 3",
    "gui.blocklyText.dfr0588.initPin": "Init temperature pin [TEM] humidity pin [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "get temperature unit [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "get humidity(%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "CELSIUS",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "FAHRENHEIT",
    "gui.blocklyText.dfr0076.readFlameSensor": "read pin [PIN] Flame Sensor",
    "gui.blocklyText.pythondfr0076.extensionName": "Flame",
    "gui.blocklyText.pythondfr0076.readFlameSensor": "read pin [PIN] Flame Sensor",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom get [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom read text name [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom read number name [KEY]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom init i2c address [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom write text [VALUE] name [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom write number [VALUE] name [KEY]",
    "gui.blocklyText.dfr0117.text": "Text",
    "gui.blocklyText.dfr0117.number": "Number",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom delete name [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom serial print all names baud [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom name [KEY] exist?",
    "gui.blocklyText.dfr0117.eepromFormat": "i2c eeprom format",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom name [KEY] is [TYPE]?",
    "gui.blocklyText.dfr0117.nameCount": "Name count",
    "gui.blocklyText.dfr0117.memorySize": "Storage space(byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "Remaining storage space(byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": "read pin [PIN] Triple Axis Accelerometer [AXIS] Axis",
    "gui.blocklyText.sen0018.readInfraredMotion": "read pin [PIN] Digital Infrared motion sensor",
    "gui.blocklyText.pythonsen0018.extensionName": "Motion",
    "gui.blocklyText.pythonsen0018.readInfraredMotion": "read pin [PIN] Digital Infrared motion sensor?",
    "gui.blocklyText.sen0014.readDistance": "read pin [PIN] Sharp IR Distance Sensor(mm)",
    "gui.blocklyText.sen0114.readMoisture": "read pin [PIN] Soil Moisture Sensor",
    "gui.blocklyText.pythonsen0114.extensionName": "Moisture",
    "gui.blocklyText.pythonsen0114.readMoisture": "read pin [PIN] Soil Moisture Sensor",
    "gui.blocklyText.sen0121.readSteam": "read pin [PIN] Steam Sensor",
    "gui.blocklyText.pythonsen0121.extensionName": "Steam",
    "gui.blocklyText.pythonsen0121.readSteam": "read pin [PIN] Steam Sensor",
    "gui.blocklyText.sen0204.readLiquidLevel": "pin [PIN] liquid level reached a certain height ?",
    "gui.blocklyText.pythonsen0204.extensionName": "Liquid Level",
    "gui.blocklyText.pythonsen0204.readLiquidLevel": "pin [PIN] liquid level reached a certain height ?",
    "gui.blocklyText.sen0203.heartRate": "heart rate(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "blood oxygen saturation(HbO2)",
    "gui.blocklyText.sen0202.up": "Up",
    "gui.blocklyText.sen0202.down": "Down",
    "gui.blocklyText.sen0202.left": "Left",
    "gui.blocklyText.sen0202.right": "Right",
    "gui.blocklyText.sen0202.cw": "CW",
    "gui.blocklyText.sen0202.ccw": "CCW",
    "gui.blocklyText.dhtTHSensor.temperature": "temperature(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "moisture(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "pin [PIN] RGB [NUM1] to [NUM2] show color [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "pin [PIN]  [NUM1] to [NUM2] RGBs show gradient color from [COLOR1] to [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "pin [PIN] shift pixels by [NUM]",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "pin [PIN] rotate pixels by [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "pin [PIN] [NUM] RGB LEDs",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "pin [PIN] brightness [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "pin [PIN] clear all LEDs",
    "gui.blocklyText.ws2812.showHistogram": "pin [PIN]  [NUM1] to [NUM2] RGB LEDs show histogram  current value: [VALUE]  max value: [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "pin [PIN] LED brightness [BRIGHTNESS]",
    "gui.blocklyText.neopixel.init": "Lamp initialization name [NAME] pin [PIN] number [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Light strip [NAME] [INDEX] Color [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Light strip [NAME] [INDEX] Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.fillColor": "The light strip [NAME] is fully lit and the color is [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Light strip [NAME] All on Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.flush": "Light strip [NAME] settings take effect",
    "gui.blocklyText.neopixel.off": "Light strip [NAME] is off",
    "gui.blocklyText.neopixel.setBrightness": "Light strip [NAME] sets brightness to [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Rainbow light effect [NAME] Quantity [COUNT] Brightness [BRIGHTNESS] Offset [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7(mPython board)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9(mPython box)",
    "gui.blocklyText.dsTSensor.ds18b20": "Read pin [PIN] DS18B20 temperature(°C)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280 initializes I2C address 0x77",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "read [TYPE] BMP280 sensor",
    "gui.blocklyText.pythonsen0372.bmp280Init": "BMP280 [NAME] initializes I2C address [ADDRESS]",
    "gui.blocklyText.pythonsen0372.readBmpSensor": "[NAME] read [TYPE] BMP280 sensor",
    "gui.blocklyText.bmpTSensor.atmosPressure": "barometric(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "altitude(m)",
    "gui.blocklyText.bmpTSensor.temperature": "temperature(℃)",
    "gui.blocklyText.huskylens.init": "HuskyLens initialize pin [SETTING] until success",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens switch algorithm to [ALGORITHM]",
    "gui.blocklyText.huskylens.requestFirst": "HuskyLens request data once and save into the result",
    "gui.blocklyText.huskylens.resultGetIsStudy": "HuskyLens check if ID [IDNUM] is learned from the result?",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "HuskyLens check if ID [IDNUM] [BOXARROW] is on screen from the result?",
    "gui.blocklyText.huskylens.resultGetIDParameter": "HuskyLens get [PARAMETER] of ID [IDNUM] [TYPE] from the result",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens check if [BOXARROW] is on screen from the result?",
    "gui.blocklyText.huskylens.resultGetParameter": "HuskyLens get [PARAMETER2] of the No. [IDNUM] [TYPE] from the result",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens get a total number of learned IDs from the result",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens get a total number of [BOXARROW] from the result",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens get a total number of ID [IDNUM] [BOXARROW] from the result",
    "gui.blocklyText.huskylens.resultGetIDofWhichParameter": "HuskyLens get [PARAMETER] of the ID [IDNUM] No. [BOXNUM] [TYPE] from the result",
    "gui.blocklyText.huskylens.setIDname": "HuskyLens name ID [ID] of the current algorithm as [NAME]",
    "gui.blocklyText.huskylens.TakeScreenshot": "HuskyLens take [TYPE] and save to SD card",
    "gui.blocklyText.huskylens.writeOSD": "HuskyLens show custom texts [NAME] at position x [X] y [Y] on screen",
    "gui.blocklyText.huskylens.clearOSD": "HuskyLens clear all custom texts on screen",
    "gui.blocklyText.huskylens.learnOnce": "HuskyLens learn ID [ID] once automatically",
    "gui.blocklyText.huskylens.clearLearn": "HuskyLens forget all learning data of the current algorithm",
    "gui.blocklyText.huskylens.loadAndSaveModel": "HuskyLens [TYPE] current algorithm data as No. [INDEX] model of SD card",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.A": "photo",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.B": "screenshot",
    "gui.blocklyText.huskylens.LOADSAVE.A": "save",
    "gui.blocklyText.huskylens.LOADSAVE.B": "load",
    "gui.blocklyText.huskylens.resultGetNearCenter": "HuskyLens get [PARAMETER2] of [TYPE] closest to the center of screen from the result",
    "gui.blocklyText.huskylens.box": "frame",
    "gui.blocklyText.huskylens.arrow": "arrow",
    "gui.blocklyText.huskylens.faceRecognition": "Face recognition",
    "gui.blocklyText.huskylens.objectTracking": "Object tracking",
    "gui.blocklyText.huskylens.objectRecognition": "Object recognition",
    "gui.blocklyText.huskylens.lineTracking": "Line tracking",
    "gui.blocklyText.huskylens.colorRecognition": "Color recognition",
    "gui.blocklyText.huskylens.tagRecognition": "Tag recognition",
    "gui.blocklyText.huskylens.objectClassification": "Object classification",
    "gui.blocklyText.huskylens.QRRecognition": "QR recognition (Edu only)",
    "gui.blocklyText.huskylens.barcodeRecognition": "Barcode recognition (Edu only)",
    "gui.blocklyText.huskylens.x": "X center",
    "gui.blocklyText.huskylens.y": "Y center",
    "gui.blocklyText.huskylens.w": "width",
    "gui.blocklyText.huskylens.h": "height",
    "gui.blocklyText.huskylens.x1": "X beginning",
    "gui.blocklyText.huskylens.y1": "Y beginning",
    "gui.blocklyText.huskylens.x2": "X endpoint",
    "gui.blocklyText.huskylens.y2": "Y endpoint",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "Communication Method",
    "gui.blocklyText.huskylens.address": "Address",
    "gui.blocklyText.huskylens.sdaGreen": "SDA(Green)",
    "gui.blocklyText.huskylens.sclBlue": "SCL(Blue)",
    "gui.blocklyText.huskylens.rxGreen": "Rx(Green)",
    "gui.blocklyText.huskylens.txBlue": "Tx(Blue)",
    "gui.blocklyText.huskylens.ID": "ID",
    "gui.blocklyText.pythonhuskylens.extensionName": "HuskyLens",
    "gui.blocklyText.pythonhuskylens.init": "HuskyLens initialize I2C address is 0x32",
    "gui.blocklyText.pythonhuskylens.changeAlgorithm": "HuskyLens switch algorithm to [ALGORITHM]",
    "gui.blocklyText.pythonhuskylens.requestFirst": "HuskyLens request data once and save into the result",
    "gui.blocklyText.pythonhuskylens.resultGetIsStudy": "HuskyLens check if ID [IDNUM] is learned from the result?",
    "gui.blocklyText.pythonhuskylens.resultGetIDIsInPicture": "HuskyLens check if ID [IDNUM] [BOXARROW] is on screen from the result?",
    "gui.blocklyText.pythonhuskylens.resultGetIDParameter": "HuskyLens get [PARAMETER] of ID [IDNUM] [TYPE] from the result",
    "gui.blocklyText.pythonhuskylens.resultGetIsInPicture": "HuskyLens check if [BOXARROW] is on screen from the result?",
    "gui.blocklyText.pythonhuskylens.resultGetParameter": "HuskyLens get [PARAMETER2] of the No. [IDNUM] [TYPE] from the result",
    "gui.blocklyText.pythonhuskylens.resultGetStudyedID": "HuskyLens get a total number of learned IDs from the result",
    "gui.blocklyText.pythonhuskylens.resultGetIsBoxArrowTotal": "HuskyLens get a total number of [BOXARROW] from the result",
    "gui.blocklyText.pythonhuskylens.resultGetIDsBoxArrowTotal": "HuskyLens get a total number of ID [IDNUM] [BOXARROW] from the result",
    "gui.blocklyText.pythonhuskylens.resultGetIDofWhichParameter": "HuskyLens get [PARAMETER] of the ID [IDNUM] No. [BOXNUM] [TYPE] from the result",
    "gui.blocklyText.pythonhuskylens.setIDname": "HuskyLens name ID [ID] of the current algorithm as [IDNAME]",
    "gui.blocklyText.pythonhuskylens.TakeScreenshot": "HuskyLens take [TYPE] and save to SD card",
    "gui.blocklyText.pythonhuskylens.writeOSD": "HuskyLens show custom texts [TEXT] at position x [X] y [Y] on screen",
    "gui.blocklyText.pythonhuskylens.clearOSD": "HuskyLens  clear all custom texts on screen",
    "gui.blocklyText.pythonhuskylens.learnOnce": "HuskyLens learn ID [ID] once automatically",
    "gui.blocklyText.pythonhuskylens.clearLearn": "HuskyLens forget all learning data of the current algorithm",
    "gui.blocklyText.pythonhuskylens.loadAndSaveModel": "HuskyLens [TYPE] current algorithm data as No. [INDEX] model of SD card",
    "gui.blocklyText.pythonhuskylens.resultGetNearCenter": "HuskyLens get [PARAMETER2] of [TYPE] closest to the center of screen from the result",
    "gui.blocklyText.ps2.initPs2Handle": "init PS2 handle DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] setRunmble [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2 open runmble [RUMBLE] strength [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "PS2 reflush states",
    "gui.blocklyText.ps2.ps2ButtonState": "PS2 button [BUTTON] state is [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2 joystick [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "ON",
    "gui.blocklyText.ps2.OFF": "OFF",
    "gui.blocklyText.ps2.Triangle": "Triangle",
    "gui.blocklyText.ps2.Circle": "Circle",
    "gui.blocklyText.ps2.Cross": "Cross",
    "gui.blocklyText.ps2.Square": "Square",
    "gui.blocklyText.ps2.Left1": "Left 1",
    "gui.blocklyText.ps2.Left2": "Left 2",
    "gui.blocklyText.ps2.Left3": "Left 3",
    "gui.blocklyText.ps2.Right1": "Right 1",
    "gui.blocklyText.ps2.Right2": "Right 2",
    "gui.blocklyText.ps2.Right3": "Right 3",
    "gui.blocklyText.ps2.Up": "Up",
    "gui.blocklyText.ps2.Right": "Right",
    "gui.blocklyText.ps2.Down": "Down",
    "gui.blocklyText.ps2.Left": "Left",
    "gui.blocklyText.ps2.Select": "Select",
    "gui.blocklyText.ps2.Start": "Start",
    "gui.blocklyText.ps2.Hold": "Hold",
    "gui.blocklyText.ps2.Pressed": "Pressed",
    "gui.blocklyText.ps2.Released": "Released",
    "gui.blocklyText.ps2.CHANGE": "CHANGE",
    "gui.blocklyText.ps2.RightX": "Right X",
    "gui.blocklyText.ps2.RightY": "Right Y",
    "gui.blocklyText.ps2.LeftX": "Left X",
    "gui.blocklyText.ps2.LeftY": "Left Y",
    "gui.blocklyText.sen0236.bme280Init": "BME280 initializes I2C address 0x77",
    "gui.blocklyText.sen0236.readbme280Sensor": "Read I2C pin BME280 [TYPE]",
    "gui.blocklyText.pythonsen0236.bme280Init": "BME280 initializes I2C address 0x77",
    "gui.blocklyText.pythonsen0236.readbme280Sensor": "Read I2C pin BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "Temperature(℃)",
    "gui.blocklyText.sen0236.Humidity": "Humidity(%rh)",
    "gui.blocklyText.sen0236.Altitude": "Altitude(m)",
    "gui.blocklyText.sen0236.Pressure": "Pressure(Pa)",
    "gui.blocklyText.sen0517.icp10111Init": "ICP10111 initializes I2C address 0x63",
    "gui.blocklyText.sen0517.readicp10111Sensor": "Get the ICP10111 sensor [TYPE]",
    "gui.blocklyText.sen0517.Temperature": "Temperature(℃)",
    "gui.blocklyText.sen0517.Altitude": "Altitude(m)",
    "gui.blocklyText.sen0517.Pressure": "Pressure(Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "initialize I2C LCD screen address [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "display [TEXT] in LCD line [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "display [TEXT] at LCD position X: [X] Y: [Y]",
    "gui.blocklyText.LCD1602.displayClear": "clear the screen",
    "gui.blocklyText.pythonLCD1602.initialIICAddr": "initialize I2C LCD [NAME] screen address [ADDR]",
    "gui.blocklyText.pythonLCD1602.displayInLine": "display [TEXT] in LCD [NAME] line [LINE]",
    "gui.blocklyText.pythonLCD1602.displayInXY": "display [TEXT] at LCD [NAME] position X: [X] Y: [Y]",
    "gui.blocklyText.pythonLCD1602.displayClear": "clear the screen [NAME]",
    "gui.blocklyText.IICScan.readICCAddr": "read scanned I2C device address",
    "gui.blocklyText.mpyfile.initFile": "init file [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsList": "read file [FILENAME] as list, use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.writeFile": "write to file [FILENAME] data [WRITEDATA],use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.initFileMaixduino": "init file [ROOT] [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsListMaixduino": "read file [ROOT] [FILENAME] as list, use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.writeFileMaixduino": "write to file [ROOT] [FILENAME] data [WRITEDATA],use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.enter": "enter",
    "gui.blocklyText.mpyfile.comma": "comma",
    "gui.blocklyText.mpyfile.semicolon": "semicolon",
    "gui.blocklyText.mpyfile.space": "space",
    "gui.blocklyText.pythondfr0760.extensionName": "Speech synthesis",
    "gui.blocklyText.pythondfr0760.init": "init speech synthesis module I2C mode [VERSION] I2C address 0x40",
    "gui.blocklyText.pythondfr0760.syntheticVoice": "speech synthesis [VOICEVALUE]",
    "gui.blocklyText.pythondfr0760.flashStorage": "save to flash [FALSH]",
    "gui.blocklyText.pythondfr0760.speechSynthesisSettings": "set volume [VOLUME]  speed [SPEED]  tone [INTONATION]  speaker [SPEEKER]",
    "gui.blocklyText.pythondfr0760.setEnglishPron": "set English pronounce mode [Pron]",
    "gui.blocklyText.pythondfr0760.setNumberPron": "Set the pronunciation of the number [Pron]",
    "gui.blocklyText.pythondfr0760.manvoice1": "male 1(V1)",
    "gui.blocklyText.pythondfr0760.manvoice2": "male 2(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice1": "female 1(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice2": "female 2(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice3": "female 3(V1)",
    "gui.blocklyText.pythondfr0760.donaldDuck": "Donald Duck(V1)",
    "gui.blocklyText.pythondfr0760.auto": "Autojudged",
    "gui.blocklyText.pythondfr0760.number": "Number",
    "gui.blocklyText.pythondfr0760.value": "Numeric",
    "gui.blocklyText.dfr0760.letter": "letter",
    "gui.blocklyText.dfr0760.word": "word",
    "gui.blocklyText.dfr0760.manvoice1": "male 1(V1)",
    "gui.blocklyText.dfr0760.manvoice2": "male 2(V1)",
    "gui.blocklyText.dfr0760.womanvoice1": "female 1(V1)",
    "gui.blocklyText.dfr0760.womanvoice2": "female 2(V1)",
    "gui.blocklyText.dfr0760.womanvoice3": "female 3(V1)",
    "gui.blocklyText.dfr0760.donaldDuck": "Donald Duck(V1)",
    "gui.blocklyText.dfr0760.softwareSerial": "Initialize the [VERSION] speech synthesis module interface [SERIALPORT] Rx(Green)[PINR] Tx(Blue)[PINT]",
    "gui.blocklyText.dfr0760.init": "init speech synthesis module  I2C mode [VERSION] I2C address 0x40",
    "gui.blocklyText.dfr0760.syntheticVoice": "speech synthesis [VOICEVALUE]",
    "gui.blocklyText.dfr0760.flashStorage": "save to flash [FALSH]",
    "gui.blocklyText.dfr0760.speechSynthesisSettings": "set volume [VOLUME]  speed [SPEED]  tone [INTONATION]  speaker [SPEEKER]",
    "gui.blocklyText.dfr0760.setEnglishPron": "set English pronounce mode [Pron]",
    "gui.blocklyText.dfr0715.ASRInit": "speech recognition module  init  recognition mode [MODE]  microphone mode [PHONEMODE] I2C address 0x4F",
    "gui.blocklyText.dfr0715.addKeywords": "add keywords [KEYWORD] number [NUMBER]",
    "gui.blocklyText.dfr0715.beginToIdentify": "setup done   start recognizing",
    "gui.blocklyText.dfr0715.recognitionOfASpeech": "recognize speech once, get number",
    "gui.blocklyText.dfr0646.tmi1650Init": "Initializes the TMI1650 display I2C address [ADDRESS]",
    "gui.blocklyText.dfr0646.eightTubeMode": "eight digital tubes TM1650 [TYPE]",
    "gui.blocklyText.dfr0646.eightTubeShowInput": "eight digital tubes TM1650 show string [TEXT]",
    "gui.blocklyText.dfr0646.eightTubeControlPoint": "eight digital tubes TM1650 [ORDER] decimal point [STATUS]",
    "gui.blocklyText.pythondfr0715.extensionName": "Speech Recognition",
    "gui.blocklyText.pythondfr0715.ASRInit": "speech recognition module init  recognition mode [MODE]  microphone mode [PHONEMODE] I2C address 0x4F",
    "gui.blocklyText.pythondfr0715.addKeywords": "add keywords [KEYWORD] number [NUMBER]",
    "gui.blocklyText.pythondfr0715.beginToIdentify": "setup done   start recognizing",
    "gui.blocklyText.pythondfr0715.recognitionOfASpeech": "recognize speech once, get number",
    "gui.blocklyText.dfr0715.loop": "loop",
    "gui.blocklyText.dfr0715.password": "command",
    "gui.blocklyText.dfr0715.button": "button",
    "gui.blocklyText.dfr0715.mic": "default",
    "gui.blocklyText.dfr0715.word": "external audio",
    "gui.blocklyText.sdcard.initCS": "SD film pin [CSPIN] initialization successful?",
    "gui.blocklyText.sdcard.readFileNLine": "SD reads file [FILE] line [LINE]",
    "gui.blocklyText.sdcard.readFileAll": "SD reads file [FILE] all content",
    "gui.blocklyText.sdcard.writeFile": "SD write file [FILE] content [MSG] mode [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD delete file [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD get file [FILE] info [INFO]",
    "gui.blocklyText.sdcard.NAME": "NAME",
    "gui.blocklyText.sdcard.PATH": "PATH",
    "gui.blocklyText.sdcard.TYPE": "TYPE",
    "gui.blocklyText.sdcard.LINE": "LINE",
    "gui.blocklyText.sdcard.SIZE": "SIZE",
    "gui.blocklyText.sdcard.APPEND": "APPEND",
    "gui.blocklyText.sdcard.REPLACE": "REPLACE",
    "gui.blocklyText.sdcard.WARP": "WARP",
    "gui.blocklyText.sdcard.NOWARP": "NOWARP",
    "gui.blocklyText.sdcard.lsFile": "SD serial port prints all file information baud [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "initialize software serial [SERIALPORT] RX: [PINR] TX: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "software serial [SERIALPORT] baud rate [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "software serial [SERIALPORT] output [TEXT] [WAY], [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "Is there data to read on software serial [SERIALPORT]?",
    "gui.blocklyText.softSerialport.readSerialportData": "read software serial[SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "read [TYPE] on software serial [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "on bluetooth connected",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "on bluetooth disconnected",
    "gui.blocklyText.bluetooth.receivedKey": "when bluetooth received key [KEY] [TYPE]",
    "gui.blocklyText.bluetooth.string": "String",
    "gui.blocklyText.bluetooth.number": "Number",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "when bluetooth received data and meet [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "send string key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "send number key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.openService": "open bluetooth service",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "received data from bluetooth",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo Starts",
    "gui.blocklyText.leonardo.setBaud": "set [SERIAL] baud rate to [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.leonardo.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.leonardo.serialPort1": "SerialPort0",
    "gui.blocklyText.leonardo.serialPort2": "SerialPort1",
    "gui.blocklyText.leonardo.dataReadable": "Is there data to read on [SERIAL]?",
    "gui.blocklyText.leonardo.readSerialData": "read [SERIAL] data",
    "gui.blocklyText.arduinounoR3.initTcs34725": "Initializes the TCS34725 color sensor I2C address 0x29",
    "gui.blocklyText.arduinounoR3.functionStarts": "Uno kit program starts",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "Initializes the TMI1650 display I2C address 0x34",
    "gui.blocklyText.arduinounoR3.readdigital": "read pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "Digital Sensor",
    "gui.blocklyText.arduinounoR3.buttonModule": "Button Module",
    "gui.blocklyText.arduinounoR3.stickButton": "Rocker Button",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.arduinounoR3.readAnalog": "read pin [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "Anolog Sensor",
    "gui.blocklyText.arduinounoR3.lightSensor": "Light Sensor",
    "gui.blocklyText.arduinounoR3.soundSensor": "Sound Sensor",
    "gui.blocklyText.arduinounoR3.stickDirection": "Rocker Direction",
    "gui.blocklyText.arduinounoR3.angleSensor": "Angle Sensor",
    "gui.blocklyText.arduinounoR3.graySensor": "Grayscale Sensor",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.arduinounoR3.open": "turn on",
    "gui.blocklyText.arduinounoR3.close": "turn off",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "Digital Actuator",
    "gui.blocklyText.arduinounoR3.ledLights": "LED Lights",
    "gui.blocklyText.arduinounoR3.fanModule": "Fan Module",
    "gui.blocklyText.arduinounoR3.recordModule": "Record Module",
    "gui.blocklyText.arduinounoR3.relay": "Relay",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.arduinounoR3.writeAnalog": " set pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "move forward",
    "gui.blocklyText.arduinounoR3.backward": "move backward",
    "gui.blocklyText.arduinounoR3.turnLeft": "turn left",
    "gui.blocklyText.arduinounoR3.turnRight": "turn right",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "robot [DIR] at [SPEED] % speed",
    "gui.blocklyText.arduinounoR3.motorForward": "move forward",
    "gui.blocklyText.arduinounoR3.motorReverse": "move backward",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "motor [MOTOR] [DIR] at [SPEED] % speed",
    "gui.blocklyText.arduinounoR3.stopMotor": "robot (MA and MB motors) stop moving",
    "gui.blocklyText.arduinounoR3.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "Infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "infrared pin [PIN] received the [BUTTON] pressed signal？",
    "gui.blocklyText.arduinounoR3.noSound": "no sound",
    "gui.blocklyText.arduinounoR3.half": "Half",
    "gui.blocklyText.arduinounoR3.quarter": "Quarter",
    "gui.blocklyText.arduinounoR3.oneInEighth": "One-eighth",
    "gui.blocklyText.arduinounoR3.wholeBeat": "Whole Beat",
    "gui.blocklyText.arduinounoR3.doubleBeat": "Double Beat",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "set pin [PIN] MP3 module volume to [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "set pin [PIN] MP3 module playing mode to [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "Stop",
    "gui.blocklyText.arduinounoR3.play": "Play",
    "gui.blocklyText.arduinounoR3.loop": "Loop",
    "gui.blocklyText.arduinounoR3.random": "Random",
    "gui.blocklyText.arduinounoR3.pause": "Pause",
    "gui.blocklyText.arduinounoR3.previous": "Previous",
    "gui.blocklyText.arduinounoR3.next": "Next",
    "gui.blocklyText.arduinounoR3.VolumePlus": "Volume+",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "Volume-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "pin [PIN] MP3 module play the [NUM] song",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "read pin [PIN] LM35 temperature(°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "read pin [PIN] DHT11 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "red",
    "gui.blocklyText.arduinounoR3.blue": "blue",
    "gui.blocklyText.arduinounoR3.green": "green",
    "gui.blocklyText.arduinounoR3.readColorSensor": "read TCS34725 color sensor [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "four-bit nixie tube TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "turn on",
    "gui.blocklyText.arduinounoR3.tm1650Close": "turn off",
    "gui.blocklyText.arduinounoR3.clearScreen": "clear screen",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": " 4-bit nixie tube TM1650 display string [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "[STATUS] the [ORDER] decimal point of 4-bit nixie tube TM1650",
    "gui.blocklyText.arduinounoR3.bright": "brighten",
    "gui.blocklyText.arduinounoR3.dark": "darken",
    "gui.blocklyText.arduinounoR3.setLightColor": "pin [PIN] the [NUM] LED display [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "pin [PIN] the [NUM] LED R:[R] G:[G] B:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "pin [PIN] [NUM] LEDs",
    "gui.blocklyText.arduinounoR3.ledControl": "LED matrix (max7219) initial parameter DIN pin [DIN], CS pin [CS], CLK pin [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "LED matrix (max7219) show pattern [PIC]",
    "videoSensing.categoryName": "Video Sensing",
    "videoSensing.direction": "direction",
    "videoSensing.motion": "motion",
    "videoSensing.off": "close",
    "videoSensing.on": "open",
    "videoSensing.onFlipped": "mirroring open",
    "videoSensing.setVideoTransparency": "set video transparency to [TRANSPARENCY]",
    "videoSensing.sprite": "sprite",
    "videoSensing.stage": "stage",
    "videoSensing.videoOn": "video [ATTRIBUTE] on [SUBJECT]",
    "videoSensing.videoToggle": "turn video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "when video motion > [REFERENCE]",
    "gui.blocklyText.max.maxStart": "Max starts",
    "gui.blocklyText.max.onboardButtonPressed": "onboard button [PRESSED]?",
    "gui.blocklyText.max.pressed": "pressed",
    "gui.blocklyText.max.notPressed": "not pressed",
    "gui.blocklyText.max.all": "-1 ALL",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "set RGB lights [TYPE] color [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "read sound sensor",
    "gui.blocklyText.max.playSoundEffect": "play sound effect[SOUND]",
    "gui.blocklyText.max.setDPinValue": "set digital pin [PIN] to [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "read digital pin [PIN]",
    "gui.blocklyText.max.readAPinValue": "read analog pin [PIN]",
    "gui.blocklyText.max.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.max.readLinefindingSensor": "read [DIR] line-tracking sensor",
    "gui.blocklyText.max.left": "Left",
    "gui.blocklyText.max.middle": "Middle",
    "gui.blocklyText.max.right": "Right",
    "gui.blocklyText.max.setLineFidingCarLights": "car LED lights [SWITCH]",
    "gui.blocklyText.max.on": "on",
    "gui.blocklyText.max.off": "off",
    "gui.blocklyText.max.readUltrasoundSensor": "read ulrasonic sensor(cm)",
    "gui.blocklyText.max.readLightSensor": "read [LEFTRIGHT] light sensor",
    "gui.blocklyText.max.maxSpeedMove": "MAX move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] stops",
    "gui.blocklyText.max.showUserDefineExpressions": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.max.clear": "clear",
    "gui.blocklyText.max.do": "Do",
    "gui.blocklyText.max.re": "Re",
    "gui.blocklyText.max.mi": "Mi",
    "gui.blocklyText.max.fa": "Fa",
    "gui.blocklyText.max.sou": "So",
    "gui.blocklyText.max.la": "La",
    "gui.blocklyText.max.xi": "Ti",
    "gui.blocklyText.max.highdo": "highdo",
    "gui.blocklyText.max.brake": "brake",
    "gui.blocklyText.max.rebound": "rebound",
    "gui.blocklyText.max.bullets": "bullets",
    "gui.blocklyText.max.longecho": "long echo",
    "gui.blocklyText.max.playfulending": "ending",
    "gui.blocklyText.max.yo": "yo",
    "gui.blocklyText.max.wo": "wo",
    "gui.blocklyText.max.robottalking": "robot talking",
    "gui.blocklyText.sen0160.init": "Set Hx711 weight sensor calibration value [VALUE] with Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.sen0160.readValue": "Read Hx711 weight sensor(g) Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.knowflow.readORP": "read pin [PIN] ORP sensor (mV)",
    "gui.blocklyText.knowflow.readEC": "read pin [PIN] EC sensor (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "read pin [PIN] PH sensor(V1)",
    "gui.blocklyText.knowflow.readECV2": "read pin [PIN] EC sensor (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "read pin [PIN] PH sensor(V2)",
    "gui.blocklyText.knowflow.readDO": "read pin [PIN] DO sensor (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "pin [PIN] Peristaltic Pump rotate [DIR] at [SPEED]% speed",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] pin [PIN] relay",
    "translate.translateBlock": "translate [WORDS] to [LANGUAGE]",
    "translate.defaultTextToTranslate": "hello",
    "translate.viewerLanguage": "viewer language",
    "gui.blocklyText.goble.readAxis": "read GOBLE rocker [AXIS] axis",
    "gui.blocklyText.goble.buttonIsPressed": "GOBLE [BUTTON] button pressed?",
    "gui.blocklyText.eeprom.clear": "eeprom clear",
    "gui.blocklyText.eeprom.read": "eeprom read from address [ADDR]",
    "gui.blocklyText.eeprom.write": "eeprom write to address [ADDR] with data [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom get crc from address [ADDRA] to address [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom get length",
    "gui.blocklyText.tds.readAnalogPin": "read analog pin [PIN] TDS sensor(ppm)",
    "gui.blocklyText.pythonsen0244.tdsInit": "TDS [NAME] initial [PIN]",
    "gui.blocklyText.pythonsen0244.readAnalogPin": "read TDS [NAME] value (ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "two stepper motors run simultaneously   X axis : [XSET] y axis: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "stepper motor [AXIS] axis  [STEPS] steps per revolution  Speed(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "stepper motor move [ROTATEDIR] for [STEPS] steps",
    "gui.blocklyText.steppermotor.setJointSteps": "two stepper motors run simultaneously. Move [XSTEPS] steps on x-axis [ROTATEDIRX]  Move [YSTEPS] steps on y-axis [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] starts",
    "gui.blocklyText.multithread.stopThread": "stop [THREAD]",
    "gui.blocklyText.multithread.startThread": "start up [THREAD]",
    "gui.blocklyText.multithread.loop1": "thread 1",
    "gui.blocklyText.multithread.loop2": "thread 2",
    "gui.blocklyText.multithread.loop3": "thread 3",
    "gui.blocklyText.multithread.loop4": "thread 4",
    "gui.blocklyText.multithread.loop5": "thread 5",
    "gui.blocklyText.multithread.loop6": "thread 6",
    "gui.blocklyText.multithread.loop7": "thread 7",
    "gui.blocklyText.multithread.loop8": "thread 8",
    "gui.blocklyText.multithread.all": "all",
    "text2speech.speakAndWaitBlock": "speak [WORDS]",
    "text2speech.setVoiceBlock": "set server1's voice to [VOICE]",
    "text2speech.setLanguageBlock": "set server1's language to [LANGUAGE]",
    "text2speech.setRequestSever": "set sever[SERVER]",
    "text2speech.setSever2Voice": "set server2's voic to [VOICE]",
    "text2speech.setSever2Language": "set server2's language to [LANGUAGE]",
    "text2speech.setSever2Account": "set server2's account[MODAL]",
    "text2speech.international": "server1(international)",
    "text2speech.china": "server2(China)",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "squeak",
    "text2speech.giant": "giant",
    "text2speech.kitten": "kitten",
    "text2speech.defaultTextToSpeak": "hello",
    "gui.blocklyText.text2speech.server2voice0": "duxiaomei",
    "gui.blocklyText.text2speech.server2voice1": "duxiaoyu",
    "gui.blocklyText.text2speech.server2voice3": "duxiaoyao",
    "gui.blocklyText.text2speech.server2voice4": "duyaya",
    "gui.blocklyText.text2speech.server2voice106": "dubowen",
    "gui.blocklyText.text2speech.server2voice110": "duxiaotong",
    "gui.blocklyText.text2speech.server2voice111": "duxiaomeng",
    "gui.blocklyText.text2speech.server2voice103": "dumiduo",
    "gui.blocklyText.text2speech.server2voice5": "duxiaojiao",
    "gui.blocklyText.DFR0151.init": "DS1307 initialization I2C address 0x68",
    "gui.blocklyText.DFR0151.adjustTime": "Adjust the DS1307 time to [YEAR]Year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0151.getTime": "get DS1307 time [INDEX]",
    "gui.blocklyText.DFR0151.Year": "year",
    "gui.blocklyText.DFR0151.month": "month",
    "gui.blocklyText.DFR0151.date": "date",
    "gui.blocklyText.DFR0151.hour": "hour",
    "gui.blocklyText.DFR0151.minute": "minute",
    "gui.blocklyText.DFR0151.second": "second",
    "gui.blocklyText.DFR0151.week": "week",
    "gui.blocklyText.pythondfr0151.init": "DS1307 initialization I2C address 0x68",
    "gui.blocklyText.pythondfr0151.adjustTime": "Adjust the DS1307 time to [YEAR]Year [MONTH]month [DATE]date [WEEK]week [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.pythondfr0151.upTime": "Get the time data once",
    "gui.blocklyText.pythondfr0151.getTime": "Parsing [INDEX] from time data",
    "gui.blocklyText.DFR0469.init": "SD2405 initialization I2C address 0x32",
    "gui.blocklyText.DFR0469.adjustTime": "adjust SD2405 time to [YEAR]year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0469.getTime": "get SD2405 time [INDEX]",
    "gui.blocklyText.pythondfr0469.init": "SD2405 initialization I2C address 0x32",
    "gui.blocklyText.pythondfr0469.adjustTime": "adjust SD2405 time to [YEAR]year [MONTH]month [DATE]date [WEEK]week [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.pythondfr0469.upTime": "Get the time data once",
    "gui.blocklyText.pythondfr0469.getTime": "Parsing [INDEX] from time data",
    "gui.blocklyText.DFR0126.setPin": "The audio analysis module  pins #S[SPIN] #R[RPIN] Analog pin[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "read sound frequency  band[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Initialize the NFC module interface I2C address 0x24",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Initialize the NFC module interface hardware serial port Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "Initialize the NFC module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "Initialize the NFC module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "NFC module block [DATABLOCK] byte [BYTES] write [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "read NFC module block [DATABLOCK] the [BYTES] byte (number)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "read NFC module block [DATABLOCK] all data (string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "read NFC module block UID (srting)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "detected card UID: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "detected card?",
    "gui.blocklyText.DFR0126.serial1": "serial1",
    "gui.blocklyText.DFR0126.serial2": "serial2",
    "gui.blocklyText.DFR0126.serial3": "serial3",
    "gui.blocklyText.pythondfr0231.extensionName": "RFID/NFC",
    "gui.blocklyText.pythondfr0231.initSerialNfcIIC": "Initialize the NFC module interface I2C address 0x24",
    "gui.blocklyText.pythondfr0231.writeSerialNfc": "NFC module block [DATABLOCK] byte [BYTES] write [VALUE]",
    "gui.blocklyText.pythondfr0231.readSerialNfcAll": "read NFC module block [DATABLOCK] all data (string)",
    "gui.blocklyText.pythondfr0231.readSerialNfc": "read NFC module block [DATABLOCK] the [BYTES] byte (number)",
    "gui.blocklyText.pythondfr0231.readSerialNfcUid": "read NFC module block UID (srting)",
    "gui.blocklyText.pythondfr0231.scanSerialNfcUid": "detected card UID: [TEXT]?",
    "gui.blocklyText.pythondfr0231.scanSerialNfc": "detected card?",
    "gui.blocklyText.sen0245.init": "Initialize the VL53L0X laser ranging sensor I2C address 0x29",
    "gui.blocklyText.sen0245.setVL53L0X": "VL53L0X accuracy[PRECISION] measurement mode[VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "get VL53L0X laser ranging value(mm)",
    "gui.blocklyText.pythonsen0315.extensionName": "PAJ7620U2",
    "gui.blocklyText.pythonsen0315.PAJ7620U2init": "init PAJ7620U2 gesture recognition sensor [MODE] mode until successful",
    "gui.blocklyText.pythonsen0315.PAJ7620U2GetValue": "PAJ7620U2 gesture recognition sensor read value once",
    "gui.blocklyText.pythonsen0315.PAJ7620U2IsTriggered": "PAJ7620U2 gesture sensor [GESTURE] is trggered",
    "gui.blocklyText.sen0376.alcoholInit": "init alcohol sensor until success addr: [ADDR] mode: [MODE]",
    "gui.blocklyText.sen0376.auto": "AUTOMATIC",
    "gui.blocklyText.sen0376.passive": "PASSIVE",
    "gui.blocklyText.sen0376.alcoholGetValue": "alcohol sensor get [TIME] times average",
    "gui.blocklyText.sen0321.auto": "AUTOMATIC",
    "gui.blocklyText.sen0321.passive": "PASSIVE",
    "gui.blocklyText.sen0321.ozoneInit": "init i2c addr [ADDR] select mode [MODE]",
    "gui.blocklyText.sen0321.ozoneGetValue": "ozone sensor get [TIME] times average",
    "gui.blocklyText.pythonspread.extensionName": "Dual expansion board",
    "gui.blocklyText.pythonsen0460.extensionName": "Concentration of dust",
    "gui.blocklyText.pythonsen0304.extensionName": "Ultrasonic (I2C)",
    "gui.blocklyText.pythonsen0307.extensionName": "Ultrasonic (analog)",
    "gui.blocklyText.pythonsen0245.extensionName": "VL53L0X",
    "gui.blocklyText.pythonsen0245.init": "Initialize the VL53L0X laser ranging sensor I2C address 0x29",
    "gui.blocklyText.pythonsen0245.setVL53L0X": "VL53L0X accuracy [PRECISION] measurement mode[VALUE]",
    "gui.blocklyText.pythonsen0245.readVL53L0X": "get VL53L0X laser ranging value(mm)",
    "gui.blocklyText.SEN0245.LOW": "Low(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "High(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "Single",
    "gui.blocklyText.SEN0245.Continuous": "Continuous",
    "gui.blocklyText.serialMp3.initSerialMp3": "Init serial MP3 module interface [SERIALPORT] RX(green) [RX] TX(blue) [TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "set serial MP3 module playing mode to [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "Set serial MP3 module playing [VALUE] song",
    "gui.blocklyText.serialMp3.setMP3Volume": "Set serial MP3 module volume to [VOLUME]%",
    "gui.blocklyText.serialMp3.play": "play",
    "gui.blocklyText.serialMp3.pause": "pause",
    "gui.blocklyText.serialMp3.end": "end",
    "gui.blocklyText.serialMp3.prev": "prev",
    "gui.blocklyText.serialMp3.next": "next",
    "gui.blocklyText.serialMp3.volumeUp": "volume up",
    "gui.blocklyText.serialMp3.volumeDown": "volume down",
    "gui.blocklyText.serialMp3.playlist": "playlist",
    "gui.blocklyText.serialMp3.insertTracks": "insert tracks",
    "gui.blocklyText.serialMp3.volume": "volume",
    "gui.blocklyText.servo360.setServo360": "pin [PIN] servo rotate [DIR] at [SPEED] % speed",
    "gui.blocklyText.servo360.CW": "CW",
    "gui.blocklyText.servo360.CCW": "CCW",
    "gui.blocklyText.TCS34725.initTcs34725": "Initializes the TCS34725 color sensor I2C address 0x29",
    "gui.blocklyText.TCS34725.readTcs34725": "read TCS34725 color sensor [COLOR] [FORMAT]",
    "gui.blocklyText.pythonsen0212.extensionName": "TCS34725",
    "gui.blocklyText.pythonsen0212.initTcs34725": "Initializes the TCS34725 color sensor I2C address 0x29",
    "gui.blocklyText.pythonsen0212.updateTcs34725": "get TCS34725 data once",
    "gui.blocklyText.pythonsen0212.readTcs34725": "Parse the TCS34725 color sensor [COLOR] [FORMAT] from the data",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "Turn GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "Don\"t turn GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "show text[TEXT] in [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "text scroll [DIR]",
    "gui.blocklyText.regEmotion8_16.left": "left",
    "gui.blocklyText.regEmotion8_16.right": "right",
    "gui.blocklyText.regEmotion8_16.lightAll": "show all dot matrix in [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "show coordinates of point (x:[XAXIS], y:[YXAXIS]) in [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "clear screen",
    "gui.mainHeader.err": "!Error: The same program headers [%1] cannot be used at the same time. Please delete the duplicated block.",
    "gui.mainHeader.help": "!Tip: If multiple programs need to be executed at the same time, use the \"multi-threading function\" in \"Extension\", refer to the help documentation for details.",
    "gui.threadHeader.err1": "!Error: The program headers  [%1] cannot be used at the same time. They belong to different kits or boards.",
    "gui.threadHeader.err2": "!Error Tip: The same event headers [%1] cannot be used at the same time. Please delete the duplicated block.",
    "gui.blocklyText.mqtt.whenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT message",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi connect to account:[ACCOUNT] password:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi connected?",
    "gui.blocklyText.wifi.wifiDisConnected": "disconnect Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP mode:[ACCOUNT2] password:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi get soft ap ip",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi get [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Error code",
    "gui.blocklyText.wifi.ping": "Wi-Fi ping [HOST]",
    "gui.blocklyText.wifi.scan": "",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "ntp set network time, time zone [TIMEZONE] timer server [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "ntp get local time [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "UTCWest12_t",
    "gui.blocklyText.ntp.UTCWest11_t": "UTCWest11_t",
    "gui.blocklyText.ntp.UTCWest10_t": "UTCWest10_t",
    "gui.blocklyText.ntp.UTCWest9_t": "UTCWest9_t",
    "gui.blocklyText.ntp.UTCWest8_t": "UTCWest8_t",
    "gui.blocklyText.ntp.UTCWest7_t": "UTCWest7_t",
    "gui.blocklyText.ntp.UTCWest6_t": "UTCWest6_t",
    "gui.blocklyText.ntp.UTCWest5_t": "UTCWest5_t",
    "gui.blocklyText.ntp.UTCWest4_t": "UTCWest4_t",
    "gui.blocklyText.ntp.UTCWest3_t": "UTCWest3_t",
    "gui.blocklyText.ntp.UTCWest2_t": "UTCWest2_t",
    "gui.blocklyText.ntp.UTCWest1_t": "UTCWest1_t",
    "gui.blocklyText.ntp.UTCMiddle_t": "UTCMiddle_t",
    "gui.blocklyText.ntp.UTCEast1_t": "UTCEast1_t",
    "gui.blocklyText.ntp.UTCEast2_t": "UTCEast2_t",
    "gui.blocklyText.ntp.UTCEast3_t": "UTCEast3_t",
    "gui.blocklyText.ntp.UTCEast4_t": "UTCEast4_t",
    "gui.blocklyText.ntp.UTCEast5_t": "UTCEast5_t",
    "gui.blocklyText.ntp.UTCEast6_t": "UTCEast6_t",
    "gui.blocklyText.ntp.UTCEast7_t": "UTCEast7_t",
    "gui.blocklyText.ntp.UTCEast8_t": "UTCEast8_t",
    "gui.blocklyText.ntp.UTCEast9_t": "UTCEast9_t",
    "gui.blocklyText.ntp.UTCEast10_t": "UTCEast10_t",
    "gui.blocklyText.ntp.UTCEast11_t": "UTCEast11_t",
    "gui.blocklyText.ntp.UTCEast12_t": "UTCEast12_t",
    "gui.blocklyText.ntp.year": "year",
    "gui.blocklyText.ntp.month": "month",
    "gui.blocklyText.ntp.date": "date",
    "gui.blocklyText.ntp.hour": "hour",
    "gui.blocklyText.ntp.minute": "minute",
    "gui.blocklyText.ntp.second": "second",
    "gui.blocklyText.ntp.week": "week",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "when udp server recv [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "set udp server port [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "udp server send broadcast [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "when udp client recv [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "set udp client IP [IPADDR] port [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "udp client send broadcast [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "message",
    "gui.blocklyText.udp.ClientRecvedMsg": "message",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] add key [KEY] value [VALUE]",
    "gui.blocklyText.http.requestHeader": "HTTP request header add [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] URL [URL] timeout [TIMEOUT] ms",
    "gui.blocklyText.http.readMessage": "HTTP read [LINE] line message",
    "gui.blocklyText.http.param": "PARAM",
    "gui.blocklyText.http.header": "HEADER",
    "gui.blocklyText.http.oneline": "ONE",
    "gui.blocklyText.http.allline": "ALL",
    "gui.blocklyText.mqtt.mqttConnect": "connect MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT connected?",
    "gui.blocklyText.mqtt.acceptance.meaage": "When [TOPIC] received the message as [MSG]",
    "gui.blocklyText.mqtt.mqttSubscribe": "MQTT subscribe to [TOPIC]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.mqtt.mqttSendSave": "MQTT send message [MSG] to cloud platform [TOPIC] and saved to database (SIoT V2)",
    "gui.blocklyText.mqtt.acceptance.topic.meaage": "When [TOPIC] received the message as [MSG]",
    "gui.blocklyText.mqtt.whenReceivedTopicData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.mqtt.mqttSendToTopic": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.mqtt.mqttSendToTopicSave": "MQTT send message [MSG] to cloud platform [TOPIC] and saved to database (SIoT V2)",
    "gui.blocklyText.mqtt.initial": "MQTT initial [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「IFLYTEK」synthetic audio [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「IFLYTEK」identify audio [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「IFLYTEK」identify result",
    "gui.blocklyText.iflytek.CommunicationText": "text",
    "gui.blocklyText.iflytek.CommunicationTransfer": "transfer to audio file",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "audio file to be identified",
    "gui.blocklyText.mqtt.mqttDisconnect": "disconnect MQTT",
    "gui.blocklyText.internet.initRequestsType": "requests type",
    "gui.blocklyText.internet.initRequestsAddr": "requests address",
    "gui.blocklyText.internet.initAddDict": "init dict",
    "gui.blocklyText.internet.postFileAddr": "post file address",
    "gui.blocklyText.internet.postFilePath": "post file path",
    "gui.blocklyText.internet.postFileMime": "post file mime type",
    "gui.blocklyText.internet.postFileAddDict": "init dict",
    "gui.blocklyText.internet.internetGetpostInit": "internet initial [SETTING]",
    "gui.blocklyText.internet.initTypeAddr": "internet requests set type [POST] address [ADDR] headers:[HEADERS] params:[URL] data:[DICT]",
    "gui.blocklyText.internet.internetPostFile": "internet post file [SETTING]",
    "gui.blocklyText.internet.postFileAddrType": "POST file requests address [ADDR] path [PATH] MIME type [TYPE]",
    "gui.blocklyText.internet.postFileDict": "POST file set dict data [DICT] and send file",
    "gui.blocklyText.internet.responseContent": "response content form [DATATYPE]",
    "gui.blocklyText.internet.responseAttributes": "response attributes [ATTRIBUTES]",
    "gui.blocklyText.internet.createSocket": "create socket [SOKHANDEL] with af [AFINET] and type [SOCKTYPE]",
    "gui.blocklyText.internet.setSocket": "create socket [SOKHANDEL] with af [SOCKTYPE] and type [TYPENUM]",
    "gui.blocklyText.internet.closeSocket": "socket [SOKHANDEL] close",
    "gui.blocklyText.internet.bindSocket": "socket [SOKHANDEL] bind with address [SOCKADDR] port [SOCKPORT]",
    "gui.blocklyText.internet.listenSocket": "listen socket [SOKHANDEL] backlog number [BACKLOGNUM]",
    "gui.blocklyText.internet.recvReturnTuple": "socket [SOKHANDEL] receive connect requests and return socket tuple",
    "gui.blocklyText.internet.parseReturnIp": "parse host [HOSTADDR] port [SOCKPORT] and return ip",
    "gui.blocklyText.internet.connectHost": "socket [SOKHANDEL] connect host [HOSTADDR] port [SOCKPORT]",
    "gui.blocklyText.internet.sendReturnBytes": "socket [SOKHANDEL] send [SOCKMSG] and return sended bytes",
    "gui.blocklyText.internet.continueSendReturnBytes": "socket [SOKHANDEL] continue send [SOCKMSG] and return sended bytes",
    "gui.blocklyText.internet.sendToAddr": "socket [SOKHANDEL] send [SOCKMSG] to address [SOCKADDR]",
    "gui.blocklyText.internet.recvReturnRecvedObject": "socket [SOKHANDEL] receive and return received object max data is [MAXNUM]",
    "gui.blocklyText.internet.recvReturnRecvedTuple": "socket [SOKHANDEL] receive and return received tuple max data is [MAXNUM]",
    "gui.blocklyText.internet.setTimeout": "socket [SOKHANDEL] set timeout [TIMEOUT]",
    "gui.blocklyText.internet.setMode": "socket [SOKHANDEL] set mode [SOCKMODE]",
    "gui.blocklyText.internet.readReturnObject": "socket [SOKHANDEL] read [READBYTE] and return bytes object",
    "gui.blocklyText.internet.text": "text",
    "gui.blocklyText.internet.binary": "binary",
    "gui.blocklyText.internet.dictionary": "dictionary",
    "gui.blocklyText.internet.block": "block",
    "gui.blocklyText.internet.nonblock": "non-block",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 initializes I2C address 0x77",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 read altitude(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 read pressure(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 read temperature(℃)",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 measurement [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "object",
    "gui.blocklyText.sen0206.environment": "environment",
    "gui.blocklyText.sen0206.celsius": "celsius",
    "gui.blocklyText.sen0206.fahrenheit": "fahrenheit",
    "gui.blocklyText.sen0206.init": "Initialize the infrared temperature sensor address to [VALUE]",
    "gui.blocklyText.sen0206.setEmissivityCorrectionCoefficient": "set emissivity correction Coefficient [VALUE]",
    "gui.blocklyText.sen0206.setI2CAddress": "set I2C Address [VALUE]",
    "gui.blocklyText.microNaturalScienceV2.OLED": "OLED",
    "gui.blocklyText.microNaturalScienceV2.Motor": "Motor",
    "gui.blocklyText.microNaturalScienceV2.RGB": "RGB",
    "gui.blocklyText.microNaturalScienceV2.IoT": "IoT",
    "gui.blocklyText.microNaturalScienceV2.IOT_Username": "Iot_UserName",
    "gui.blocklyText.microNaturalScienceV2.IOT_Password": "Iot_Password",
    "gui.blocklyText.microNaturalScienceV2.IOT_topic": "topic_0",
    "gui.blocklyText.sen0253.init": "10DOF Initial pin I2C_ADDR level [LEVEL]",
    "gui.blocklyText.sen0253.reset": "10DOF Sensor reset",
    "gui.blocklyText.sen0253.power": "10DOF Set power mode [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF Get euler angles [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF Get qua [AXIS] axis",
    "gui.blocklyText.sen0253.getAcc": "10DOF Get accelerometer [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF Get magnetometer [AXIS] axis(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF Get linear acceleration [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF Get gravit capacity [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF Get angular speed [AXIS] axis",
    "gui.blocklyText.sen0253.getBmp280": "10DOF Get [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "Low",
    "gui.blocklyText.sen0253.HIGH": "High",
    "gui.blocklyText.sen0253.normal": "Normal",
    "gui.blocklyText.sen0253.lowPower": "Low power",
    "gui.blocklyText.sen0253.suspend": "Suspend",
    "gui.blocklyText.sen0253.Roll": "Roll(°)",
    "gui.blocklyText.sen0253.Pitch": "Pitch(°)",
    "gui.blocklyText.sen0253.Yaw": "Yaw(°)",
    "gui.blocklyText.sen0253.temperature": "Temperature",
    "gui.blocklyText.sen0253.elevation": "Elevation",
    "gui.blocklyText.sen0253.airPressure": "AirPressure",
    "gui.blocklyText.sen0290.init": "lighting sensor set irq pin [IRQPIN] and i2c address [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "lighting sensor lightning sensor iic init ok？",
    "gui.blocklyText.sen0290.manualCal": "lighting sensor set capacitance [CAPACITANCE] mode [MODE] dist [DIST]",
    "gui.blocklyText.sen0290.indoors": "indoors",
    "gui.blocklyText.sen0290.outdoors": "outdoors",
    "gui.blocklyText.sen0290.disturberDis": "disturber disable",
    "gui.blocklyText.sen0290.disturberEn": "disturber enable",
    "gui.blocklyText.sen0290.errMsg": "error msg",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "lighting sensor get status",
    "gui.blocklyText.sen0290.GetLightningDistKm": "lighting sensor get lightning dist(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "lighting sensor get strike energy raw",
    "gui.blocklyText.sen0291.sen0291Init": "The power meter initializes the I2C address as [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "The power meter reads the load [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "The actual measured current of the power meter calibration is [EXT] mA",
    "gui.blocklyText.pythonsen0291.sen0291Init": "The power meter [NAME] initializes the I2C address as [IICADDR]",
    "gui.blocklyText.pythonsen0291.sen0291Read": "The power meter [NAME] reads the load [LOAD]",
    "gui.blocklyText.pythonsen0291.sen0291Calibration": "Power meter [NAME] calibrates actual measured current to [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "Voltage (mV)",
    "gui.blocklyText.sen0291.electric": "Electric (mA)",
    "gui.blocklyText.sen0291.power": "Power (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "Shunt Voltage (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.dfr0991.rgbButtonInit": "rgbButton Init [ADDR]",
    "gui.blocklyText.dfr0991.setRgbButtonColor1": "set rgbButton Color red[RED] green[GREEN] blue[BLUE] ",
    "gui.blocklyText.dfr0991.setRgbButtonColor": "set rgbButton Color [COLOR]",
    "gui.blocklyText.dfr0991.getRgbButtonStatus": "get rgbButton status",
    "gui.blocklyText.weather.weatherGetWithCity": "[WEATHER] in [CITY] city [COUNTRY] province",
    "gui.blocklyText.weather.temperaturHigh": "maximum temperature(℃)",
    "gui.blocklyText.weather.temperaturLow": "minimum temperature(℃)",
    "gui.blocklyText.weather.weather": "weather",
    "gui.blocklyText.weather.weatherInit": "weather server Initialize [SETTING]",
    "gui.blocklyText.weather.serverAddr": "server address",
    "gui.blocklyText.weather.appid": "appid",
    "gui.blocklyText.weather.appsecret": "appsecret",
    "gui.blocklyText.weather.serverAddrTianqiAPI": "tianqi API(www.tianqiapi.com)",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "set server parameter [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "update tag [TAG] value [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "read tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "delete tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "count tag",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "network connection test",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "search tag from start number [NUMS] variable count [NUMV] tag [TAG] type [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "tag",
    "gui.blocklyText.tinywebdb.value": "value",
    "gui.blocklyText.tinywebdb.both": "both",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "HongKong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.whenIHear": "when heard [PHRASE]",
    "speech.listenAndWait": "listen and wait",
    "speech.getSpeech": "recognition result",
    "speech.setRecordTime": "set record time to [TIME]",
    "speech.stopRecord": "stop speech recognition",
    "speech.controlSonogram": "[CONTROL] Sonogram",
    "speech.setServer": "switch speech recognition server to [SERVER]",
    "speech.defaultWhenIHearValue": "hello",
    "speech.show": "show",
    "speech.hide": "hide",
    "speech.server1": "server1",
    "speech.server2": "server2",
    "gui.blocklyText.mp3.initMp3": "Init DFPlayer MP3 module interface [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "set DFPlayer MP3 module volume to [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "set DFPlayer MP3 module playing mode to [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "set DFPlayer MP3 module playing [NUM] song",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "set pin [PIN] DFPlayer MP3 module volume to [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "set pin [PIN] DFPlayer MP3 module playing mode to [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "pin [PIN] DFPlayer MP3 module play the [NUM] song",
    "gui.blocklyText.tel0094.GPSInit": "GPS initializes as [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS gets the location [LATLON](°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "GPS gets the location [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "Longitude",
    "gui.blocklyText.tel0094.LONGITUDE": "Latitude",
    "gui.blocklyText.tel0094.YEAR": "Year",
    "gui.blocklyText.tel0094.MONTH": "Month",
    "gui.blocklyText.tel0094.DAY": "Day",
    "gui.blocklyText.tel0094.HOUR": "Hour",
    "gui.blocklyText.tel0094.MINUTE": "Minute",
    "gui.blocklyText.tel0094.SECOND": "Second",
    "gui.blocklyText.tel0094.ALL": "All",
    "gui.blocklyText.tel0094.GPSGet": "GPS gets [GPS]",
    "gui.blocklyText.tel0094.getState": "Status",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "Latitude hemisphere",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "Longitude hemisphere",
    "gui.blocklyText.tel0094.getGroundSpeed": "Ground speed",
    "gui.blocklyText.tel0094.getSatellitesCount": "Number of satellites",
    "gui.blocklyText.tel0094.getAltitude": "Altitude",
    "gui.blocklyText.tel0094.getPdop": "Position accuracy",
    "gui.blocklyText.tel0094.getHdop": "Horizontal accuracy",
    "gui.blocklyText.tel0094.getVdop": "Vertical accuracy",
    "gui.blocklyText.tel0094.getPositioningMode": "Positioning mode",
    "gui.blocklyText.microIoT.displayInLine": "OLED show line [LINE] text [TEXT]",
    "gui.blocklyText.microIoT.displayInXY": "OLED show [TEXT] x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED show image [IMAGE] x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED clear",
    "gui.blocklyText.microIoT.pointDrawing": "OLED draw point x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED set line width [LINEWIDTH]",
    "gui.blocklyText.microIoT.lineDrawing": "OLED draw line start x [X1] y [Y1] end x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED draw circle [FILL] circle center x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED draw box [FILL] start x [X] y [Y] width [WIDTH] height [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB set brightness to [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "RGB lights are all off",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "RGB lights [NUM1] through [NUM2] show colors [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "RGB lights [NUM1] to [NUM2] show gradients hue from [COLOR1] to [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "RGB loop by [NUM] pixel",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "RGB lights [NUM1] through [NUM2] show colors [COLOR]",
    "gui.blocklyText.motorIot.all": "ALL",
    "gui.blocklyText.microIot.servo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "MQTT initial parameter [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.microIot.mqttSendmsgSave": "MQTT send message [MSG] to cloud platform [TOPIC] and saved to database (SIoT V2)",
    "gui.blocklyText.microIot.MQTTMsg": "MQTT message",
    "gui.blocklyText.microIoTCloud.MotorRun": "motor rotate [DIR] at speed [SPEED]",
    "gui.blocklyText.microIoTCloud.MotorStop": "motor stop",
    "gui.blocklyText.microIoTCloud.setTime": "set year [Y] month [MON] day [D] hour [H] minute [MIN] second [S]",
    "gui.blocklyText.microIoTCloud.setYear": "set year [Y]",
    "gui.blocklyText.microIoTCloud.setMonth": "set month [MON]",
    "gui.blocklyText.microIoTCloud.setDay": "set day [D]",
    "gui.blocklyText.microIoTCloud.setHour": "set hour [H]",
    "gui.blocklyText.microIoTCloud.setMinute": "set minute [MIN]",
    "gui.blocklyText.microIoTCloud.setSecond": "set second [S]",
    "gui.blocklyText.microIoTCloud.getTime": "get [TIME]",
    "gui.blocklyText.microIoTCloud.sendmsgSaveToCloudPlatform": "send message [MSG] to cloud platform [TOPIC] and saved to database (SIoT V2)",
    "gui.blocklyText.microTouch.whenBtnPress": "when keys [KEY] press",
    "gui.blocklyText.microTouch.Any": "Any",
    "gui.blocklyText.microTouch.isButtonPressed": "Keys [KEY] is pressed?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "Normal mode read keys",
    "gui.blocklyText.microTouch.readKeyMathMode": "Math mode read [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "Number key(num)",
    "gui.blocklyText.microTouch.Function": "Function key(str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] vibration motor",
    "gui.blocklyText.microTouch.turnOn": "Turn on",
    "gui.blocklyText.microTouch.shutDown": "Shut down",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "Sound intensity",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "Natural light",
    "gui.blocklyText.microNaturalScience.readColour": "Read [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "Red value",
    "gui.blocklyText.microNaturalScience.G": "Green value",
    "gui.blocklyText.microNaturalScience.B": "Blue value",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "UV intensity (μW/cm²)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "Turn on",
    "gui.blocklyText.microNaturalScience.off": "Turn off",
    "gui.blocklyText.microNaturalScience.setTDSK": "Set the TDS electrode constant k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "Obtain the TDS electrode constant k",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS(ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "Get [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "Water temperature (°C)",
    "gui.blocklyText.microNaturalScience.Temperature": "Temperature(℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "Humidity(%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "Altitude(m)",
    "gui.blocklyText.microNaturalScience.Pressure": "Pressure(Pa)",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB Set matrix point X [X] Y [Y] show colors [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 can not be placed in the interrupt event, may cause dht11 reading abnormal",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 can not be placed in the interrupt event, may cause dht22 reading abnormal",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 cannot be placed in an interrupt event, which may result in abnormal pm2.5 readings",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 reads data, which may cause led dot matrix initialization failure",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 reads data, which may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "the mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to be set to fail",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "the mp3 setting playback mode cannot be placed in an interrupt event, which may cause the playback mode to fail",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "Mp3 settings playback can not be placed in the interrupt event, may cause the settings to play failed",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "Serial port mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "The serial port mp3 playback mode cannot be placed in an interrupt event, which may cause the setup play mode to be lost",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "Serial port mp3 playback cannot be placed in an interrupt event, which may cause playback to fail",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "The serial port mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to fail",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloq's mqtt initialization soft serial port cannot be placed in an interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq sends a message that cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq read mqtt message can not be placed in the interrupt event, may cause the reception to fail",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "The initial soft serial port of http of obloq cannot be placed in the interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "The get request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "Obloq http post request cannot be placed in an interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "The put request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "Infrared reception cannot be placed in an interrupt event, which may cause reception failure",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "Infrared reception may cause the LED dot matrix initialization to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "Infrared reception may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "Infrared transmission cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause initialization to fail",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause setup pins to fail",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the pin to fail to fail",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause display color to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause color adjustment failure",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the move to fail",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause loop movement to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause the display histogram to fail",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "Led dot matrix can not be placed in the interrupt event, may cause initialization failure",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "The led dot matrix cannot be placed in an interrupt event, which may cause the display pattern to fail",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "The soft serial output cannot be placed in an interrupt event, which may cause the output to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "The data read by the soft serial port cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Soft serial port output data and read data colleague use, may cause data abnormalities",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "The soft serial port read integer cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "Soft serial port output data and reading integer colleague use may cause abnormal reading data",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "When dht11 and PM2.5 are in two threads, dht11 reads may fail if pm2.5 reads too frequently. It is recommended to reduce the pm2.5 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "When dht11 and ds18b20 are in two threads respectively, if ds18b20 reads too frequently, dht11 may fail to read, it is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "When dht11 and mp3 are initialized in two threads, mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "When dht11 and mp3 set volume are in two threads respectively, mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "When dht11 and mp3 setup modes are in two threads respectively, mp3 setup mode may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "When dht11 and mp3 player are in two threads, mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "When dht11 and serial mp3 are initialized in two threads, serial mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "When dht11 and serial mp3 control module are in two threads respectively, the mp3 control module may cause dht11 reading errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "When dht11 and serial mp3 player are in two threads, serial mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "When dht11 and serial mp3 set volume are in two threads respectively, serial mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "When dht11 and RGB are initialized in two threads, RGB initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "When dht11 and RGB set brightness in two threads, RGB set brightness may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "When dht11 and RGB are extinguished in two threads, RGB extinction may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "When dht11 and RGB display colors in two threads, RGB display colors may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "When dht11 and RGB display a gradient in two threads, respectively, RGB display a gradient may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "When dht11 and RGB move pixels in two threads, RGB move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "When dht11 and RGB loop move pixels in two threads, respectively, RGB loop move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "When dht11 and RGB display columns in two threads respectively, RGB display bars may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "When dht11 and soft serial send are in two threads respectively, soft serial send may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "When dht11 and obloq MQTT are initialized in two threads respectively, obloq MQTT initialization may cause dht11 to read errors. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "When dht11 and obloq MQTT send messages in two threads respectively, obloq MQTT send messages that may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "When dht11 and obloq HTTP put requests are in two threads, it can cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "When dht11 and obloq HTTP initialization are in two threads respectively, it may cause dht11 reading error. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "When dht11 and obloq HTTP get requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "When dht11 and obloq HTTP post requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "When dht11 and infrared emission are in two threads respectively, it may cause errors in reading dht11. It is suggested to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "When ds18b20 and PM2.5 are in two threads respectively, it may cause PM2.5 reading error, so it is suggested to reduce ds18b20 frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "When ds18b20 and obloq MQTT are initialized in two threads respectively, MQTT initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "When ds18b20 and obloq HQTT put requests are in two threads respectively, the put request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "When ds18b20 and obloq HTTP initialization are in two threads respectively, the HTTP initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "When ds18b20 and obloq HQTT get requests are in two threads respectively, the get request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "When ds18b20 and obloq HQTT post requests are in two threads respectively, the post request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Reading ds18b20 may result in no infrared data, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Reading ds18b20 may cause errors in receiving data by soft serial port, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "When PM2.5 read and obloq MQTT initialization are carried out in two threads respectively, MQTT initialization failure or PM2.5 read error may be caused. It is recommended to avoid simultaneous execution of MQTT initialization and PM2.5 read",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "When PM2.5 reading and obloq MQTT send messages in two threads respectively, PM2.5 reading errors may be caused. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "When PM2.5 read and obloq HTTP put requests are in two threads, it may cause put request failure or PM2.5 read error. It is recommended to reduce put request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "When PM2.5 reads and obloq HTTP initializations are carried out in two threads respectively, HTTP initializations may fail or PM2.5 reads may go wrong. It is recommended to avoid simultaneous execution of HTTP initializations and PM2.5 reads",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "When PM2.5 read and obloq HTTP get requests are in two threads respectively, it may cause get request failure or PM2.5 read error. It is recommended to reduce get request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "When PM2.5 read and obloq HTTP post request are in two threads respectively, it may cause post request failure or PM2.5 read error. It is recommended to reduce the frequency of post request or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "When PM2.5 reading and MP3 initialization are carried out in two threads respectively, PM2.5 reading may fail. It is recommended to avoid the simultaneous execution of PM2.5 reading and MP3 initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "When PM2.5 reading and MP3 setting volume are in two threads respectively, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "When PM2.5 reading and serial MP3 player are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "When PM2.5 reading and serial MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "When PM2.5 reading and serial MP3 setting volume are respectively in two threads, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "When PM2.5 reading may cause infrared reception failure, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "When PM2.5 reading and RGB initialization are carried out in two threads respectively, PM2.5 reading errors may be caused. It is recommended to avoid simultaneous execution of PM2.5 reading and RGB initialization",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "When PM2.5 reading and RGB setting brightness are respectively in two threads, PM2.5 reading error may be caused. It is recommended not to adjust brightness too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "When PM2.5 reading and RGB extinguishing are in two threads, PM2.5 reading errors may be caused. It is recommended not to extinguish RGB too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "When PM2.5 reading and RGB display colors are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to switch colors too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "When PM2.5 reading and RGB display the gradient in two threads respectively, PM2.5 reading error may be caused, it is recommended not to switch the gradient too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "When PM2.5 reading and RGB moving pixels are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to move pixels too frequently",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "Using Wi-Fi may cause the onboard rgb to display an abnormal color",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "Udp uses Wi-Fi, may cause the onboard rgb display color anomaly",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "Using Wi-Fi may cause the onboard rgb to set a three-color exception",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "Udp uses Wi-Fi, may cause the onboard rgb to set a three-color exception",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "Using Wi-Fi may cause the onboard rgb to set abnormal brightness",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "Udp uses Wi-Fi, may cause the onboard rgb to set the brightness abnormally",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "Using Wi-Fi may cause the onboard rgb to get abnormal brightness",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "Udp uses Wi-Fi, which may cause the onboard rgb to get abnormal brightness",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to be initialized abnormally",
    "gui.blocklyText.prompt.telloesp32.emissionPinAndBit_telloWIFI": "The use of wifi may cause an abnormality in the infrared transmitter module",
    "gui.blocklyText.prompt.telloesp32.rgbnomal_rgbeffects": "When using the breathing light special effect block program, the LED light can no longer be set separately, please remove one of the blocks.",
    "gui.blocklyText.prompt.telloesp32.matrixnomal_matrixeffects": "Special effects are implemented for background threads, which conflict with the direct setting of matrix.",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 initialization to be abnormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "Using Wi-Fi may cause serial port mp3 to play track failed",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to play tracks failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "Using Wi-Fi may cause the serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "Using Wi-Fi may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp uses Wi-Fi, which may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "When PM2.5 read and RGB loop move pixels in two threads respectively, PM2.5 read errors may be caused. It is recommended not to execute loop move pixels too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "When PM2.5 reading and RGB display columns are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to execute RGB display columns too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "When PM2.5 reading and led dot array initialization are carried out in two threads respectively, led dot array initialization may go wrong. It is recommended to avoid the simultaneous execution of PM2.5 reading and led dot array initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "When PM2.5 reading and led dot array display are in two threads respectively, led dot array display may be wrong, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5 reading may cause errors in PM2.5 reading. It is recommended not to send data too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5 reading may cause soft serial port reading failure. It is recommended not to read PM2.5 too frequently",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 may not receive infrared data, it is recommended to reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "When MP3 and obloq MQTT initialization is in two threads, it may cause MQTT initialization to fail. It is recommended to avoid simultaneous MQTT initialization and MP3 initialization",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "When MP3 and obloq HTTP put requests are in two threads, it is possible to cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "When MP3 and obloq HTTP initializations are in two threads, HTTP initializations may fail. It is recommended to avoid both HTTP initializations and MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "When MP3 and obloq HTTP get requests are in two threads, it may cause get requests to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "When MP3 and obloq HTTP post requests are in two threads, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 may cause soft serial port read failure, it is recommended not to use MP3 too often",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "Serial MP3 may not receive infrared data, it is recommended to reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "When serial port MP3 and obloq MQTT initialization are in two threads respectively, MQTT initialization may fail. It is recommended to avoid simultaneous execution of MQTT initialization and serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "When serial MP3 and obloq HTTP put requests are in two threads, it may cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "HTTP initialization may fail when serial MP3 and obloq are initialized in two threads. It is recommended to avoid both HTTP initialization and serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "When the serial port MP3 and obloq HTTP get requests are in two threads respectively, it may cause the get request to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "When the serial port MP3 and obloq HTTP post requests are in two threads respectively, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of the serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "Serial port MP3 may cause soft serial port read failure, it is recommended not to use serial port MP3 too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving errors, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "Soft serial obloq may cause errors in infrared receiving data, so it is recommended to avoid sending messages frequently by obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "The initialization of RGB band may lead to the error of obloq receiving messages. It is recommended to avoid frequent use of RGB band",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Setting the brightness of the light belt may cause an error in obloq receiving messages. It is recommended to avoid frequent brightness adjustment",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB belt extinguish may lead to soft serial obloq receiving messages error, it is recommended to avoid frequent extinguish the belt",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB light band display may cause obloq to receive messages error, it is recommended to avoid frequent display of color",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "The display of gradient on the RGB band may cause errors in obloq receiving messages. It is recommended to avoid frequent display of gradient",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB band moving pixels may cause soft serial obloq to receive messages incorrectly",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "The RGB band loops the pixels around and may cause the soft serial obloq to receive messages out",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB ribbon display bar may cause soft serial obloq receive message error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "When soft serial obloq and led dot array initialization are carried out in two threads respectively, it may cause led dot array initialization error. It is recommended to avoid simultaneous execution of soft serial obloq and led dot array initialization",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "When soft serial obloq and led dot array display are respectively in two threads, it may cause led dot array display error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "Soft serial port sending may cause obloq reading error, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "Soft serial port obloq send may cause soft serial port read failure, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "Infrared emission may cause infrared reception of no data, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "When infrared emission may cause soft serial port reading failure, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "The initialization of RGB band may result in no infrared reception of data",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "The brightness setting of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent brightness setting of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "The RGB lamp belt is off, which may cause the infrared data can not be received",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "The moving pixel of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent moving pixel of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "Infrared reception may cause led initialization failure",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "Infrared reception may cause led display errors",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "Soft serial port sending may cause infrared data can not receive, it is recommended to avoid frequent sending soft serial port",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB band may cause soft serial port reading failure, so it is recommended to reduce the use frequency of RGB band",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Data received by soft serial port may cause led dot matrix display error",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "The sending of soft serial port may lead to the error of receiving data of soft serial port. It is recommended to avoid the execution of sending data and receiving data at the same time",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "The initialization of serial port MP3 may lead to errors in infrared receiving data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "MP3 serial port setting playing mode may cause errors in infrared receiving data, so it is recommended to avoid setting playing mode too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "Serial MP3 player may lead to infrared data errors, it is recommended to avoid switching songs too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "The volume setting of serial port MP3 may cause errors in infrared receiving data. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "Initialization of max7219 may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "Max7219 display images may cause errors in infrared received data, so it is recommended to avoid displaying images too frequently",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "The MP3 module of serial port may cause the pin interrupt cannot be triggered. It is recommended to avoid using MP3 module of serial port too often",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "The max7219 display module may cause pin interrupts not to be triggered. It is recommended to avoid using the max7219 display too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "DHT reading may lead to errors in infrared receiving messages. It is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "Ds18b20 reading may cause errors in soft external receiving messages, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5 reading may cause errors in receiving messages outside the software. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "Mp3 initialization read may cause infrared receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "The volume setting of mp3 may cause the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "Mp3 setting mode may cause errors in infrared receiving messages. It is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "Mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "Setting mode of serial port mp3 may cause error of infrared receiving message, it is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "Serial mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Setting the volume of serial port mp3 may lead to the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "Infrared emission may cause errors in infrared receiving messages, so it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "The initialization of the RGB band may lead to errors in receiving infrared messages. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving infrared messages. It is recommended to avoid setting the brightness too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB band display may cause errors in infrared receiving messages. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "Gradient discoloration of RGB lamp band display may lead to error of infrared receiving message. It is recommended to avoid displaying gradient discoloration too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Moving pixels with RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid moving pixels too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "The circular movement of pixels with the RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB ribbon display columnar may cause infrared receiving message error, it is recommended to avoid displaying columnar too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Infrared receiving messages may cause initialization failure of max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Infrared receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "Soft serial printing may cause errors in infrared receiving messages, so it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "Soft serial port obloq MQTT initialization may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "Soft serial obloq put request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "Soft serial port obloq HTTP initialization may lead to infrared receiving data errors, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "Soft serial obloq get request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "Soft serial obloq post request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "The clear display of RGB lamp band may cause errors in receiving messages in the soft infrared. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "DHT reading may lead to soft serial receiving message error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "Ds18b20 reading may cause the soft serial port to receive the message error, it is recommended to reduce the ds18b20 reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5 reading may cause errors in receiving messages by soft serial port. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "Mp3 initialization reads may result in a soft serial receiving message error, and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Setting the volume of mp3 may cause the error of receiving the message on the soft serial port. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "Mp3 setting mode may cause the soft serial port to receive the message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "Mp3 playback may lead to soft serial receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "Mp3 setting mode of serial port may cause error of receiving messages by soft serial port. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "Mp3 playing on serial port may cause errors in receiving messages on soft serial port. It is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message from soft serial port. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "Infrared emission may cause errors in receiving messages by soft serial port. It is recommended to avoid sending infrared messages too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "The initialization of the RGB band may result in an error in receiving messages from the soft serial port. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving messages from the soft serial port. It is recommended to avoid setting the brightness too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "Clear display of RGB light band may cause error of receiving message by soft serial port. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB band display may cause errors in receiving messages from the soft serial port. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB lamp tape display gradient may cause message error in soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause the soft serial port to receive messages incorrectly. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB light belt circular movement of pixels may cause the soft serial port to receive messages error, it is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB ribbon display columnar may cause the soft serial port to receive the message error, it is recommended to avoid performing display columnar too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "Soft serial receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "Soft serial receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "Soft serial printing may cause error in receiving messages from the soft serial port. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "DHT reads may cause pin interrupt triggering failure. It is recommended to reduce the DHT read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "Ds18b20 read may cause pin interrupt trigger failure, it is recommended to reduce ds18b20 read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5 reading may cause pin interrupt trigger failure. It is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Mp3 initialization reads may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "Mp3 setting volume may cause pin interrupt trigger failure. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "Mp3 setting mode may cause pin interrupt triggering failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "Mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "Serial port mp3 initialization read may cause pin interrupt trigger failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "Setting mode of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "Serial mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Setting the volume of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "Infrared emission may cause pin interrupt trigger failure, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB ribbon initialization may cause pin interrupt trigger failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Setting brightness of RGB band may cause pin interrupt trigger failure. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB strip clear display may cause pin interrupt trigger failure, it is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB tape display may cause pin interrupt trigger failure. It is recommended to avoid RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "The RGB tape display of the gradient may cause the pin interrupt trigger to fail. It is recommended to avoid displaying the gradient too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB band moving pixels may cause pin interrupt triggering failure and it is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB light belt loop move pixel may cause pin interrupt trigger failure, it is recommended to avoid too often loop move pixel",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB ribbon display bar may cause pin interrupt trigger failure. It is recommended to avoid displaying bar too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "Pin interruption may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "Pin interruption may cause max7219 to display incorrectly",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "Soft serial printing may cause pin interrupt trigger failure, it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "Soft serial port obloq MQTT initialization may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "Soft serial obloq put requests may cause pin interrupt triggering failure and it is recommended to avoid requesting data too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "Soft serial obloq HTTP initialization may cause pin interrupt triggering failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "Soft serial obloq get request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "Soft serial obloq post request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "DHT reading may lead to soft serial obloq receiving messages error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "Ds18b20 reading may cause obloq to receive messages incorrectly, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5 reading may cause error in obloq receiving messages. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "Mp3 initialization read may result in soft serial obloq receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Mp3 setting volume may result in soft serial obloq receiving message error. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "Mp3 setting mode may lead to soft serial obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "Mp3 playback may lead to soft serial obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port obloq receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "Serial port mp3 setting mode may cause soft serial port obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "Serial port mp3 player may lead to soft serial port obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message of soft serial port obloq. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving messages error, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Soft serial bloq sending messages may lead to infrared reception errors, it is recommended to avoid obloq sending messages too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB ribbon initialization may cause soft serial obloq to receive an error message, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Setting brightness of RGB light belt may cause error of obloq receiving messages. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Clear display of RGB light band may cause error of obloq receiving message. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB tape display may result in an error in obloq receiving messages. It is recommended to avoid performing RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB lamp tape display gradient may cause message error in obloq soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause an error in obloq receiving messages. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB light belt circular movement of pixels may lead to soft serial port obloq receive messages error, it is recommended to avoid too often loop movement of pixels",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB ribbon display columnar may cause soft serial obloq receive messages error, it is recommended to avoid too often display columnar",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "Soft serial obloq receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "Soft serial obloq receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "Soft serial printing may cause obloq to receive messages incorrectly. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "Soft serial port obloq sending data may cause errors in receiving data by soft serial port. It is recommended to avoid too frequent sending of data by obloq",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "Soft serial obloq sending data may cause pin interrupt cannot be triggered, it is recommended to avoid obloq sending data too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "Soft serial port obloq MQTT initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "Soft serial obloq put request may cause the soft serial port to receive data error, it is recommended to avoid too frequent requests for data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "Soft serial port obloq HTTP initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "Soft serial obloq get request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "Soft serial port obloq post request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.compatible.shapedBlock": "The new version changes point: the event program building block with its own variables is replaced by the event program building block without variables.",
    "gui.blocklyText.compatible.shapedBlock.variable": "The new version change point: change from independent program building block to variable program building block in event program",
    "gui.blocklyText.compatible.booleanToRound": "New version change point: Changed from conditional program building block to numerical program building block.",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "The new version changes point: add soft and hard serial port switch drop-down option in the program building block.",
    "gui.blocklyText.compatible.mp3.deletePin": "New version change point: Remove the pin drop-down option in the program building block.",
    "gui.blocklyText.compatible.sen0206.addDropdown": "New version change point: Add drop-down options to the program building block.",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "New version change point: Increase the pin drop-down option in the program building block.",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "New version change point: Modify the serial port drop-down option in the program building block.",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "The new version changes point: changed from the event program building block to the conditional program building block.",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "The new version changes point: delete block",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "The new version changes point: modify the square drop-down box to a circular drop-down box",
    "gui.blocklyText.compatible.shouldBlockInitFirst": "The small module has been modified, please redesign the program",
    "gui.blocklyText.compatible.CCS811init": "New version change point: block parameters reduced, split into 2 blocks, please drag again",
    "gui.blocklyText.compatible.obloq": "The new version changes point: delete block",
    "gui.blocklyText.pictureai.initToken": "init AI of picture[SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "get picture from local file[IMAGE]",
    "gui.blocklyText.pictureai.cameraToggle": "switch to camera [CAMERA_LIST]",
    "gui.blocklyText.pictureai.getVideoImage": "get picture video shortcut",
    "gui.blocklyText.pictureai.webPicture": "get picture from website[TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH]save camera shortcut to local[TEXT]",
    "gui.blocklyText.pictureai.face": "identify picture[TEXT] contain face",
    "gui.blocklyText.pictureai.word": "identify picture[TEXT] contain [SELECT]",
    "gui.blocklyText.pictureai.picture": "identify picture[TEXT] contain[SELECT]",
    "gui.blocklyText.pictureai.gesture": "identify picture[TEXT] contain gestture",
    "gui.blocklyText.pictureai.TOPIC_W_A": "words",
    "gui.blocklyText.pictureai.TOPIC_W_B": "number",
    "gui.blocklyText.pictureai.TOPIC_W_C": "car number",
    "gui.blocklyText.pictureai.TOPIC_W_D": "handwriting",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "left ankle",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "left ear",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "left elbow",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "left eye",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "left hip",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "left knee",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "left mouth corner",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "left shoulder",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "left wrist",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "neck",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "nose",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "right ankle",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "right ear",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "right elbow",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "right eye",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "right hip",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "right knee",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "right mouth corner",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "right shoulder",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "right wrist",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "top head",
    "gui.blocklyText.pictureai.initUserToken": "switch to a separate account[MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "identify picture[TEXT] contain body point",
    "gui.blocklyText.pictureai.faceContrast": "contrast face in picture [TEXT] with picture [TEXT2]",
    "gui.blocklyText.pictureai.faceMerge": "merge face in picture [TEXT] and picture [TEXT2]",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "image body",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "general purpose objects and scenes",
    "gui.blocklyText.pictureai.TOPIC_P_A": "plant",
    "gui.blocklyText.pictureai.TOPIC_P_B": "animal",
    "gui.blocklyText.pictureai.TOPIC_P_C": "flower",
    "gui.blocklyText.pictureai.TOPIC_P_D": "vegetables",
    "gui.blocklyText.pictureai.TOPIC_P_E": "Currency",
    "gui.blocklyText.pictureai.bodyPointSuccess": "Successful identification of key points in the human body?",
    "gui.blocklyText.pictureai.bodyPointInfo": "get BodyPoint [BODY] [XY]coordinate",
    "gui.blocklyText.pictureai.noPower": "Please check if the account has opened the feature",
    "gui.blocklyText.pictureai.pictureError": "the picture is not in the correct format",
    "gui.blocklyText.pictureai.noPicture": "please select picture first",
    "gui.blocklyText.pictureai.noVideo": "Please make sure that the camera is connected properly and that no other software is occupied, and turn on the camera, or restart the software",
    "gui.blocklyText.pictureai.defaultTitleText": "Video window",
    "gui.blocklyText.pictureai.videoContainer": "use [CON] show camera sceen",
    "gui.blocklyText.pictureai.TOPIC_stage": "stage",
    "gui.blocklyText.pictureai.TOPIC_popup": "popup",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "New version change point: There are program building blocks removed.",
    "gui.blocklyText.compatible.shapeChanged": "New version change point: the shape of the block has changed, please drag it again.",
    "gui.blocklyText.pictureai.gestureList": "gesture[TEXT]",
    "gui.blocklyText.pictureai.networkError": "Network error, please check the network connection",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "Successful face recognition?",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "face recognition results [TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "face num",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "age",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "beauty",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "gender",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "glasses",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "type",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "expression",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "face probability",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "left",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "top",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "width",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "height",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "landmark",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "occlusion",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "blur",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "completeness",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "human",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "cartoon",
    "gui.blocklyText.pictureai.TOPIC_FACE_emotion": "Emotion",
    "gui.blocklyText.pictureai.TOPIC_FACE_mask": "Wearing Mask",
    "gui.blocklyText.pictureai.TOPIC_FACE_left_eye": "Left Eye Openness",
    "gui.blocklyText.pictureai.TOPIC_FACE_right_eye": "Right Eye Openness",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Forward",
    "gui.blocklyText.maqueenPlus.Backward": "Backward",
    "gui.blocklyText.maqueenPlus.left": "Left",
    "gui.blocklyText.maqueenPlus.right": "Right",
    "gui.blocklyText.maqueenPlus.all": "All",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "set motor [DIR] move by [SPEED] speed [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "set motor [DIR] stop",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] speed compensation [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "read [DIR] motor speed",
    "gui.blocklyText.maqueenPlus.readMoterDir": "read [DIR] motor direction (0:stop 1:forward 2:back)",
    "gui.blocklyText.maqueenPlus.clearMoterRunedCircle": "clear the revolutions of wheel [DIR]",
    "gui.blocklyText.maqueenPlus.getMoterRunedCircle": "get the revolutions of wheel [DIR]",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "set [DIR] RGB show [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "read the line tracking sensor [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "read the sensation sensor [INDEX] grayscale value",
    "gui.blocklyText.maqueenPlus.getVersion": "get version",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "read ultrasonic sensor trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "on IR received [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "infrared data",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "gui.blocklyText.maqueenPlusV2.setMotorStop": "set [DIR] motor stop",
    "gui.blocklyText.maqueenPlusV2.setMotorSpeedMove": "set [DIR] motor direction [SPEEDDIR] speed [SPEED]",
    "gui.blocklyText.maqueenPlusV2.rotateForward": "rotate forward",
    "gui.blocklyText.maqueenPlusV2.rotateBackward": "rotate backward",
    "gui.blocklyText.maqueenPlusV2.readIR": "read IR key value",
    "gui.blocklyText.maqueenPlusV2.on": "open",
    "gui.blocklyText.maqueenPlusV2.off": "close",
    "gui.blocklyText.maqueenPlusV2.leftLED": "left",
    "gui.blocklyText.maqueenPlusV2.rightLED": "right",
    "gui.blocklyText.maqueenPlusV2.allLED": "all",
    "gui.blocklyText.maqueenPlusV2.left": "left",
    "gui.blocklyText.maqueenPlusV2.right": "right",
    "gui.blocklyText.maqueenPlusV2.all": "all",
    "gui.blocklyText.maqueenPlusV2.setRgb": "control [DIR] led light [COLOR]",
    "gui.blocklyText.maqueenPlusV2.readSensationSensor": "read line sensor [INDEX] state",
    "gui.blocklyText.maqueenPlusV2.readSensationSensorYscaleValue": "read line sensor [INDEX] ADC data",
    "gui.blocklyText.maqueenPlusV2.readUltrasonic": "set ultrasonic sensor TRIG pin [TRIG] ECHO pin [ECHO] read data company:cm",
    "gui.blocklyText.maqueenPlusV2.getVersion": "read version",
    "gui.blocklyText.esp32.audioRecognition": "get voice command within [TIME] seconds",
    "gui.blocklyText.esp32.audioRecordingFree": "trelease cache of recording",
    "gui.blocklyText.esp32.audioRecordingStart": "record audio with storage path [PATH] and duration [TIME] second(s)",
    "gui.blocklyText.esp32.audioRecordingInit": "initialize recording",
    "gui.blocklyText.esp32.audioPlay": "Audio play [URL]",
    "gui.blocklyText.esp32.audioControl": "Audio [CONTROL]",
    "gui.blocklyText.esp32.AudioControl.A": "PAUSE",
    "gui.blocklyText.esp32.AudioControl.B": "GO ON",
    "gui.blocklyText.esp32.AudioControl.C": "STOP",
    "gui.blocklyText.esp32.audioSetVolume": "set Audio volume as [VOLUME]",
    "gui.blocklyText.esp32.audioFree": "deinit Audio",
    "gui.blocklyText.esp32.audioInit": "initialize Audio",
    "gui.blocklyText.esp32.audio": "audio",
    "gui.blocklyText.esp32.musicGetTempo": "current beats",
    "gui.blocklyText.esp32.musicSetTempo": "set each beat equal to [TICKS] notes, the number of beats per minute is [TEMPO]",
    "gui.blocklyText.esp32.musicSetPlaySpeed": "set the playback speed to [SPEED] times",
    "gui.blocklyText.esp32.musicPlayMelodySet": "play music [MELODY] [WAIT] [LOOP] at pin [PIN]",
    "gui.blocklyText.esp32.musicPlayMelody": "play music [MELODY] pin [PIN]",
    "gui.blocklyText.esp32.musicPlayNoteFrom": "play tone from [MIN] to [AMX] step [STEP] duration [DURATION] ms pin [PIN]",
    "gui.blocklyText.esp32.musicPlayNote": "play tone [NOTE] delay [DELAY] millisecond(s) at pin [PIN]",
    "gui.blocklyText.esp32.musicTone": "tone [NOTE]",
    "gui.blocklyText.esp32.musicPlayNoteContinuous": "play continuous tone [NOTE] at pin [PIN]",
    "gui.blocklyText.esp32.musicPlayList": "play note list [NOTELIST] [WAIT] [LOOP] at pin [PIN]",
    "gui.blocklyText.esp32.IsWait.A": "wait",
    "gui.blocklyText.esp32.IsWait.B": "don't wait",
    "gui.blocklyText.esp32.IsLoop.A": "loop playback",
    "gui.blocklyText.esp32.IsLoop.B": "play once",
    "gui.blocklyText.esp32.getNote": "note [NOTE] beat [BEAT]",
    "gui.blocklyText.esp32.musicPlay": "play note [NOTE] at pin [PIN]",
    "gui.blocklyText.esp32.restore": "restore music settings",
    "gui.blocklyText.esp32.stopPlay": "stop playing music at pin [PIN]",
    "gui.blocklyText.esp32.stopPlay_default": "default",
    "gui.blocklyText.esp32.musicPin_default": "default",
    "gui.blocklyText.mpyUART.input": "get input text from serial post ,title [TITLE]",
    "gui.blocklyText.mpyUART.close": "close serial port [UART]",
    "gui.blocklyText.mpyUART.readLength": "serial port [UART] read data with length as [LENGTH]",
    "gui.blocklyText.mpyUART.read": "serial port [UART] read data",
    "gui.blocklyText.mpyUART.readline": "serial port [UART] read a lien of data",
    "gui.blocklyText.mpyUART.any": "serial port [UART] has readable data?",
    "gui.blocklyText.mpyUART.writeBytes": "serial port [UART] write bytes [BYTES]",
    "gui.blocklyText.mpyUART.writeByte": "serial port [UART] write a byte [BYTE]",
    "gui.blocklyText.mpyUART.init": "initialize serial port [UART] as baudrate [BAUDRATE] tx [TXPIN] rx [RXPIN]",
    "gui.blocklyText.mpyUART.initTello": "initialize serial port [UART] rx [RXPIN] tx [TXPIN] as baudrate [BAUDRATE]",
    "gui.blocklyText.mpyUART.writeStringTello": "serial port [UART] write string [TEXT] [ENDSYMBOL] wrap",
    "gui.blocklyText.mpyUART.anyTello": "serial port [UART] has readable data?",
    "gui.blocklyText.mpyUART.readTello": "serial port [UART] read data",
    "gui.blocklyText.mpyUART.closeTello": "close serial port [UART]",
    "gui.blocklyText.mpyUART.writeString": "serial port [UART] write string [TEXT] [ENDSYMBOL] wrap",
    "gui.blocklyText.mpyUART.no": "no",
    "gui.blocklyText.mpyUART.auto": "auto",
    "gui.blocklyText.mpyI2C.scan": "I2C scan result",
    "gui.blocklyText.mpyI2C.read": "I2C address [ADDRESS] read bytes number [BYTES]",
    "gui.blocklyText.mpyI2C.write": "I2C address [ADDRESS] is written to [DATA]",
    "gui.blocklyText.mpyI2C.init": "initialize I2C SCL [SCLPIN] SDA [SDAPIN] baudrate [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoInitBoard": "initialize the onboard I2C frequency [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoUniversal": "Initialize general I2C SCL [SCLPIN] SDA [SDAPIN] frequency [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoWrite": "[OBJ] Write [DATA] to address [ADDRESS]",
    "gui.blocklyText.mpyI2C.maixduinoRead": "[OBJ] read [BYTES] data from address [ADDRESS]",
    "gui.blocklyText.mpyI2C.maixduinoScan": "[OBJ] scan result",
    "gui.blocklyText.esp32.readPinLevel": "duration(us) of external [LEVEL] pulse level at [PIN]",
    "gui.blocklyText.esp32.noPull": "no pull",
    "gui.blocklyText.esp32.pullDown": "pull down",
    "gui.blocklyText.esp32.pullUp": "pull up",
    "gui.blocklyText.esp32.setPinMode": "set pin [PIN] as [MODE] mode [PULL] and set the value to [LEVEL]",
    "gui.blocklyText.esp32.leakyOutput": "leaky output",
    "gui.blocklyText.mpyServo.setServo": "set the servo [PIN] angle as [ANGLE] pulse width from [MIN] us to [MAX] us actuation range as [ANGLEMAX]",
    "gui.blocklyText.servo.init": "set the servo [PIN] angle as [ANGLE] timer [TIMER]",
    "gui.blocklyText.mpyServo.setAngle": "set the servo [PIN] angle as [ANGLE]",
    "gui.blocklyText.esp32.shaked": "shaked",
    "gui.blocklyText.esp32.thrown": "thrown",
    "gui.blocklyText.esp32.tilt_forward": "tilt forward",
    "gui.blocklyText.esp32.tilt_back": "tilt back",
    "gui.blocklyText.esp32.tilt_right": "tilt right",
    "gui.blocklyText.esp32.tilt_left": "tilt left",
    "gui.blocklyText.esp32.tilt_none": "flat",
    "gui.blocklyText.esp32.attitudeEvent": "when the board is [ATTITUDE]",
    "gui.blocklyText.esp32.to_high": "↗ low to high",
    "gui.blocklyText.esp32.to_low": "↘ high to low",
    "gui.blocklyText.esp32.pinLevelEvent": "when pin [PIN] level from [TRANSITION]",
    "gui.blocklyText.mpyTimer.setEvent": "Set timer [INDEX] [MODE] period [PERIOD] milliseconds",
    "gui.blocklyText.mpyTimer.repeated": "repeated",
    "gui.blocklyText.mpyTimer.delayed": "delayed",
    "gui.blocklyText.mpyTimer.conditionEvent": "Set timer [INDEX] :when [CONDITION]",
    "gui.blocklyText.mpyTimer.getCount": "Timer [INDEX] count value",
    "gui.blocklyText.mpyTimer.clearEvent": "Clear timer/event [INDEX]",
    "gui.blocklyText.mpyDictionary.init": "init dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.getValue": "dictionary [CONTENT] value of [KEY]",
    "gui.blocklyText.mpyDictionary.setValue": "set dictionary [CONTENT] value of key [KEY] to [VALUE]",
    "gui.blocklyText.mpyDictionary.deleteKey": "delete dictionary [CONTENT] key [KEY]",
    "gui.blocklyText.mpyDictionary.clear": "clear dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.isInclude": "dictionary [CONTENT] include key [KEY]?",
    "gui.blocklyText.mpyDictionary.getLength": "length of dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.list": "list of [TYPE] in dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.TypeIndex.A": "key",
    "gui.blocklyText.mpyDictionary.TypeIndex.B": "value",
    "gui.blocklyText.mpyList.join": "List [LIST] use separators [SYMBOL] to combine text",
    "gui.blocklyText.mpyList.split": "Text [TEXT] use separators [SYMBOL] to make lists",
    "gui.blocklyText.mpyList.init": "init list [CONTENT]",
    "gui.blocklyText.mpyList.clear": "clear list [CONTENT]",
    "gui.blocklyText.mpyList.getLength": "length of list [CONTENT]",
    "gui.blocklyText.mpyList.isEmpty": "[CONTENT] is empty?",
    "gui.blocklyText.mpyList.getValue": "get value at index [INDEX] in list [CONTENT]",
    "gui.blocklyText.mpyList.getValueRange": "return list [CONTENT] takes [TYPE1] [NUM1] items to [TYPE2] [NUM2] items",
    "gui.blocklyText.mpyList.insert": "insert [VALUE] at index [INDEX] in list [CONTENT]",
    "gui.blocklyText.mpyList.setValue": "set index [INDEX] to [VALUE] in list [CONTENT]",
    "gui.blocklyText.mpyList.delete": "delete value at index [INDEX] from list [CONTENT]",
    "gui.blocklyText.mpyList.append": "append [APPEND] into list [CONTENT]",
    "gui.blocklyText.mpyList.extend": "list [LIST1] append list [LIST2]",
    "gui.blocklyText.mpyList.findIndex": "find index of [VALUE] in list [CONTENT]",
    "gui.blocklyText.mpyList.sort": "List [LIST] sort by [TYPE] as [MODE]",
    "gui.blocklyText.mpyList.SortModeIndex.A": "ascending",
    "gui.blocklyText.mpyList.SortModeIndex.B": "descending",
    "gui.blocklyText.mpyList.SortTypeIndex.A": "number",
    "gui.blocklyText.mpyList.SortTypeIndex.B": "letter",
    "gui.blocklyText.mpyList.SortTypeIndex.C": "letter, ignore case",
    "gui.blocklyText.mpySet.init": "init set [CONTENT]",
    "gui.blocklyText.mpySet.update": "update set [CONTENT1] as the [TYPE] with set [CONTENT2]",
    "gui.blocklyText.mpySet.TypeIndex.A": "intersection",
    "gui.blocklyText.mpySet.TypeIndex.B": "union",
    "gui.blocklyText.mpySet.TypeIndex.C": "difference",
    "gui.blocklyText.mpySet.addValue": "update set [CONTENT] with [VALUE]",
    "gui.blocklyText.mpySet.isSubsetSuperset": "set [CONTENT1] is [TYPE] of set [CONTENT2]?",
    "gui.blocklyText.mpySet.SubsetSupersetType.A": "subset",
    "gui.blocklyText.mpySet.SubsetSupersetType.B": "superset",
    "gui.blocklyText.mpySet.intersectionUnionSetDifference": "[TYPE] of set [CONTENT1] and set [CONTENT2]",
    "gui.blocklyText.mpySet.length": "length of set [CONTENT]",
    "gui.blocklyText.mpySet.pop": "return a random item from the set [CONTENT] ,and remove it",
    "gui.blocklyText.mpyTuple.init": "init tuple [CONTENT]",
    "gui.blocklyText.mpyTuple.minMaxLength": "tuple [CONTENT] [TYPE]",
    "gui.blocklyText.mpyTuple.TypeIndex.A": "min value",
    "gui.blocklyText.mpyTuple.TypeIndex.B": "max value",
    "gui.blocklyText.mpyTuple.TypeIndex.C": "length",
    "gui.blocklyText.mpyTuple.isInclude": "tuple [CONTENT] include [STRING]?",
    "gui.blocklyText.mpyTuple.getValueRange": "return tuple [CONTENT] takes [TYPE1] [NUM1] items to [TYPE2] [NUM2] items",
    "gui.blocklyText.mpyTuple.IndexType.A": "#",
    "gui.blocklyText.mpyTuple.IndexType.B": "countdown #",
    "gui.blocklyText.mpyTuple.listToTulpe": "list [LIST] conversion to tuple",
    "gui.blocklyText.mpyTuple.getValue": "get value at index [INDEX] in tuple [CONTENT]",
    "gui.blocklyText.mpyTuple.tupleToList": "convert tuple [CONTENT] into list",
    "gui.blocklyText.esp32.DisplayTypeMenu.A": "normal",
    "gui.blocklyText.esp32.DisplayTypeMenu.B": "rev",
    "gui.blocklyText.esp32.displayInnerImageInXY": "display built-in image [IMAGE] to x:[X] y:[Y] pattern [TYPE]",
    "gui.blocklyText.esp32.displayFileSystemImageInXY": "loading image from file system [IMAGE] show to x:[X] y:[Y] pattern [TYPE]",
    "gui.blocklyText.weather.GetWeatherWithCityName": "get [WEATHER] in [CITY] city",
    "gui.blocklyText.pictureai.createFaceClass": "cretae face class [NAME]",
    "gui.blocklyText.pictureai.pictureaiAddFaceToClass": "named recognition result [RES] and added to the face class [CLASSNAME]",
    "gui.blocklyText.pictureai.pictureaiSelectResultsFromClass": "search recognition results in the face library [CLASSNAME] where reliability >= [NUMBER]",
    "gui.blocklyText.pictureai.pictureaiWhenSearchName": "When the search results were [NAME]",
    "gui.blocklyText.pictureai.pictureaiSearchedName": "name of search results",
    "gui.blocklyText.pictureai.pictureaiSelectResultReliability": "reliability of search results",
    "gui.blocklyText.pictureai.pleaseUseSelefAccount": "face class function switch to separate account",
    "gui.blocklyText.pictureai.addfaceclasserror": "Error creating face class",
    "gui.blocklyText.pictureai.faceclassnameerror": "the name of the face class is composed of Numbers, letters and underscores",
    "gui.blocklyText.pictureai.faceclassnameexist": "class name is already exist",
    "gui.blocklyText.pictureai.addfaceerror": "Error adding face",
    "gui.blocklyText.pictureai.selectfaceclasserror": "Error search face class",
    "gui.blocklyText.pictureai.notfoundmatch": "match user is not found",
    "gui.blocklyText.pictureai.facenameerror": "The face name is composed of numbers, letters, and underscores",
    "boost.color.any": "any color",
    "boost.color.black": "black",
    "boost.color.blue": "blue",
    "boost.color.green": "green",
    "boost.color.red": "red",
    "boost.color.white": "white",
    "boost.color.yellow": "yellow",
    "boost.getMotorPosition": "motor [MOTOR_REPORTER_ID] position",
    "boost.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "boost.motorDirection.backward": "that way",
    "boost.motorDirection.forward": "this way",
    "boost.motorDirection.reverse": "reverse",
    "boost.motorOff": "turn motor [MOTOR_ID] off",
    "boost.motorOn": "turn motor [MOTOR_ID] on",
    "boost.motorOnFor": "turn motor [MOTOR_ID] for [DURATION] seconds",
    "boost.motorOnForRotation": "turn motor [MOTOR_ID] for [ROTATION] rotations",
    "boost.seeingColor": "seeing [COLOR] brick?",
    "boost.setLightHue": "set light color to [HUE]",
    "boost.setMotorDirection": "set motor [MOTOR_ID] direction [MOTOR_DIRECTION]",
    "boost.setMotorPower": "set motor [MOTOR_ID] speed to [POWER] %",
    "boost.tiltDirection.any": "any",
    "boost.tiltDirection.down": "down",
    "boost.tiltDirection.left": "left",
    "boost.tiltDirection.right": "right",
    "boost.tiltDirection.up": "up",
    "boost.whenColor": "when [COLOR] brick seen",
    "boost.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "ev3.beepNote": "beep note [NOTE] for [TIME] secs",
    "ev3.buttonPressed": "button [PORT] pressed?",
    "ev3.getBrightness": "brightness",
    "ev3.getDistance": "distance",
    "ev3.getMotorPosition": "motor [PORT] position",
    "ev3.motorSetPower": "motor [PORT] set power [POWER] %",
    "ev3.motorTurnClockwise": "motor [PORT] turn this way for [TIME] seconds",
    "ev3.motorTurnCounterClockwise": "motor [PORT] turn that way for [TIME] seconds",
    "ev3.whenBrightnessLessThan": "when brightness < [DISTANCE]",
    "ev3.whenButtonPressed": "when button [PORT] pressed",
    "ev3.whenDistanceLessThan": "when distance < [DISTANCE]",
    "wedo2.getDistance": "distance",
    "wedo2.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "wedo2.isTilted": "tilted [TILT_DIRECTION_ANY]?",
    "wedo2.motorDirection.backward": "that way",
    "wedo2.motorDirection.forward": "this way",
    "wedo2.motorDirection.reverse": "reverse",
    "wedo2.motorId.a": "motor A",
    "wedo2.motorId.all": "all motors",
    "wedo2.motorId.b": "motor B",
    "wedo2.motorId.default": "motor",
    "wedo2.motorOff": "turn [MOTOR_ID] off",
    "wedo2.motorOn": "turn [MOTOR_ID] on",
    "wedo2.motorOnFor": "turn [MOTOR_ID] on for [DURATION] seconds",
    "wedo2.playNoteFor": "play note [NOTE] for [DURATION] seconds",
    "wedo2.setLightHue": "set light color to [HUE]",
    "wedo2.setMotorDirection": "set [MOTOR_ID] direction to [MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "set [MOTOR_ID] power to [POWER]",
    "wedo2.tiltDirection.any": "any",
    "wedo2.tiltDirection.down": "down",
    "wedo2.tiltDirection.left": "left",
    "wedo2.tiltDirection.right": "right",
    "wedo2.tiltDirection.up": "up",
    "wedo2.whenDistance": "when distance [OP] [REFERENCE]",
    "wedo2.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "gui.blocklyText.video.notfound": "not found",
    "gui.blocklyText.machinelearning.drawResults": "[TOGGLE]draw recognition results",
    "gui.blocklyText.machinelearning.initKNNImageClass": "PC KNN Classification",
    "gui.blocklyText.machinelearning.imageClassificationFromCamera": "KNN classify the camera image as[TEXT]",
    "gui.blocklyText.machinelearning.imageClassificationFromDir": "KNN classify the local folder image [DIR] as [TEXT]",
    "gui.blocklyText.machinelearning.startKNNTrain": "KNN start classification training",
    "gui.blocklyText.machinelearning.predictImageClass": "KNN [TOGGLE] recognizing the camera picture classification",
    "gui.blocklyText.machinelearning.getImageClassification": "KNN recognize the classification result",
    "gui.blocklyText.machinelearning.getImageClassificationReliability": "KNN recognizes the credibility",
    "gui.blocklyText.machinelearning.clearImageClassificationLabel": "KNN clear tag [LABEL]",
    "gui.blocklyText.machinelearning.clearImageClassificationModel": "KNN clear classification model data",
    "gui.blocklyText.machinelearning.loadImageClassificationModel": "KNN load the computer-side classification model from [PATH]",
    "gui.blocklyText.machinelearning.exportImageClassificationModel": "KNN save the classification model",
    "gui.blocklyText.machinelearning.traceFace": "FaceAPI initializes face tracking",
    "gui.blocklyText.machinelearning.drawFaceAPI": "FaceAPI[TOGGLE] mark face key points",
    "gui.blocklyText.machinelearning.traceFaceNumber": "FaceAPI number of faces",
    "gui.blocklyText.machinelearning.getFaceByIndex": "FaceAPI get [PART] of  [INDEX] face with coordinate [TYPE]",
    "gui.blocklyText.machinelearning.initfaceContrast": "FaceAPI Initialize specific face recognition",
    "gui.blocklyText.machinelearning.refFaceFromCamera": "FaceAPI collect face data from the camera marked as [NAME]",
    "gui.blocklyText.machinelearning.refFaceFromDir": "FaceAPI collect face data from the folder [DIR] marked as folder name",
    "gui.blocklyText.machinelearning.faceAPIFindFace": "FaceAPI identify the face in the camera once",
    "gui.blocklyText.machinelearning.allowFaceAPIResult": "FaceAPI face recognition results",
    "gui.blocklyText.machinelearning.allowFaceAPIResultScore": "FaceAPI face recognition results credibility",
    "gui.blocklyText.machinelearning.clearFaceAPIModel": "FaceAPI Clear face model data",
    "gui.blocklyText.machinelearning.ml5WhenSearchFaceName": "when searching label[NAME] credibility>=[VALUE]%",
    "gui.blocklyText.machinelearning.machinelearningTracePose": "PoseNet initialize the posture tracking",
    "gui.blocklyText.machinelearning.drawPoseNet": "PoseNet[TOGGLE] marking posture key points",
    "gui.blocklyText.machinelearning.getPoseByIndex": "PoseNet get [PART] of [INDEX] posture with coordinate [TYPE]",
    "gui.blocklyText.machinelearning.initImageClassify": "Initialize object recognition image classifier (mobileNet)",
    "gui.blocklyText.machinelearning.toggleImageClassify": "[TOGGLE] Object recognition image classifier Real-time recognition",
    "gui.blocklyText.machinelearning.imageClassifyResult": "Object recognition recognition results",
    "gui.blocklyText.machinelearning.imageClassifyResultScore": "Object recognition recognition credibility",
    "gui.blocklyText.huskylens.initVisualModal": "initialize AI visualization tool to [TYPE]",
    "gui.blocklyText.huskylens.classifyImageFromCamera": "add screen pictures of the computer camera to the classifier [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromHuskylens": "add screen pictures of HuskyLens camera to the classifier [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromDir": "add images of the local file folder [DIR] to the classifier [TEXT]",
    "gui.blocklyText.huskylens.visualModalClearLabel": "clear classification [LABEL]",
    "gui.blocklyText.huskylens.visualModalClearAllLabels": "clear all classification of classifier",
    "gui.blocklyText.huskylens.deployClassificationToHuskylens": "Train the model and deploy to HuskyLens",
    "gui.blocklyText.machinelearning.loadlmodel": "model path",
    "gui.blocklyText.pictureai.TOPIC_BODY_mouth": "mouth",
    "gui.blocklyText.pictureai.TOPIC_BODY_jaw_outline": "jaw",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye_brow": "left eye brow",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye_brow": "right eye brow",
    "gui.blocklyText.huskylens.videoContainer": "use [CON] to show computer camera screen",
    "gui.blocklyText.huskylens.videoToggle": "[VIDEO_STATE] computer camera",
    "gui.blocklyText.huskylens.cameraToggle": "switch to computer camera [CAMERA_LIST]",
    "gui.blocklyText.huskylens.exportImage": "The computer camera take photo and save to local folder [TEXT]",
    "gui.blocklyText.dialog.ml5.loadModel": "Loading the model, the page may appear stuck, please wait...",
    "gui.blocklyText.dialog.ml5.ifLagTryEnableGPUInSettings": "If experiencing lag, try enabling GPU acceleration in settings.",
    "gui.blocklyText.dialog.ml5.reInitPictureClassify": "the image data already exists in the classifier. Reinitialization will empty the existing data. Confirm to continue?",
    "gui.blocklyText.dialog.ml5.whenDeleteImageAfterTrain": "This image data has been trained, after deletion, need to be retrained to take effect",
    "gui.blocklyText.dialog.ml5.renameLabel": "The tag [LABEL] already exists, do you want to merge it?",
    "gui.blocklyText.dialog.ml5.modelIsEmpty": "There is no classified data in this model. Please train the model first",
    "gui.blocklyText.dialog.ml5.shouldInitKNN": "Please initialize the KNN classification first",
    "gui.blocklyText.dialog.huskylens.emptyAllImage": "This operation will delete all categories, are you sure to continue?",
    "gui.blocklyText.dialog.huskylens.HuskyLensTakePhotoError": "Taking photos from HuskyLens failed, please try again",
    "gui.blocklyText.dialog.huskylens.overImageMaxLen": "The maximum number of [TYPE] classifiers is [LEN], the limit has been exceeded, please sort it out and try again",
    "gui.blocklyText.dialog.huskylens.notAllowChinese": "The category name should be composed of numbers and letters with no more than 50 digits, and cannot contain Chinese and special characters",
    "gui.blocklyText.dialog.ml5.isNotKNN": "Face recognition type classifier cannot join KNN training",
    "gui.blocklyText.machinelearning.start": "start",
    "gui.blocklyText.machinelearning.stop": "stop",
    "gui.blocklyText.huskyLensPro.ML5MODEL.A": "KNN classification",
    "gui.blocklyText.huskyLensPro.ML5MODEL.B": "face recognition",
    "gui.blocklyText.huskyLensPro.changeAlgorithm": "HuskyLens switch algorithm to [ALGORITHM]",
    "gui.blocklyText.huskyLensPro.learndIDCount": "HuskyLens get a total number of learned IDs",
    "gui.blocklyText.huskyLensPro.isOnTheScreen": "HuskyLens check if [BOXARROW] is on screen?",
    "gui.blocklyText.huskyLensPro.parametersNearTheCenter": "HuskyLens get [PARAMETER] of [BOXARROW] closest to the center of screen",
    "gui.blocklyText.huskyLensPro.idIsLearned": "HuskyLens check if ID [ID] is learned?",
    "gui.blocklyText.huskyLensPro.idIsOnTheScreen": "HuskyLens check if ID [ID] [BOXARROW] is on screen?",
    "gui.blocklyText.huskyLensPro.idParametersNearTheCenter": "HuskyLens get [PARAMETER] of ID [ID] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.boxCount": "HuskyLens get a total number of [BOXARROW]",
    "gui.blocklyText.huskyLensPro.boxarrowParameter": "HuskyLens get [PARAMETER] of the No.[INDEX] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.idBoxCount": "HuskyLens get a total number of ID [ID] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.idBoxarrowParameter": "HuskyLens get [PARAMETER] of the ID [ID] No.[INDEX] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.learnOnce": "HuskyLens learn ID [ID] once automatically",
    "gui.blocklyText.huskyLensPro.clearLearn": "HuskyLens forget all learning data of the current algorithm",
    "gui.blocklyText.huskyLensPro.setIDname": "HuskyLens name ID [ID] of the current algorithm as [NAME]",
    "gui.blocklyText.huskyLensPro.writeOSD": "HuskyLens show custom texts [NAME] at position x [X] y [Y] on screen",
    "gui.blocklyText.huskyLensPro.clearOSD": "HuskyLens clear all custom texts on screen",
    "gui.blocklyText.huskyLensPro.requestPicture": "HuskyLens [TYPE] and save to a local folder [PATH]",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.A": "take a photo",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.B": "screenshot",
    "gui.blocklyText.huskyLensPro.TakeScreenshotToSDCard": "HuskyLens [TYPE] and save to the SD card",
    "gui.blocklyText.huskyLensPro.xCenter": "X-Center",
    "gui.blocklyText.huskyLensPro.yCenter": "Y-Center",
    "gui.blocklyText.huskyLensPro.width": "Width",
    "gui.blocklyText.huskyLensPro.height": "Height",
    "gui.blocklyText.huskyLensPro.xStart": "X-Start",
    "gui.blocklyText.huskyLensPro.yStart": "Y-Start",
    "gui.blocklyText.huskyLensPro.xEnd": "X-End",
    "gui.blocklyText.huskyLensPro.yEnd": "Y-End",
    "gui.blocklyText.huskyLensPro.QRRecognition": "QR code recognition",
    "gui.blocklyText.huskyLensPro.barcodeRecognition": "Bar code recognition",
    "gui.blocklyText.huskyLensPro.saveFailed": "Failed to save picture!",
    "gui.blocklyText.huskyLensPro.yes": "yes",
    "gui.blocklyText.huskyLensPro.cancel": "cancel",
    "gui.blocklyText.huskyLensPro.promptInitializeClassifier": "Please initialize the image classifier first",
    "gui.blocklyText.huskyLensPro.promptInitializeKNNClassifier": "Please initialize the KNN classifier first",
    "gui.blocklyText.huskyLensPro.promptAddClassificationData": "Please add classification data first",
    "gui.blocklyText.huskyLensPro.setTitleText": "HuskyLens AI-KNN Image classification",
    "gui.blocklyText.huskyLensPro.tooManyPictures": "There are too many pictures, only the first fifty pictures are extracted",
    "gui.blocklyText.huskyLensPro.checkPath": "Please check if there are pictures in the path [PATH]",
    "gui.blocklyText.huskyLensPro.success": "success",
    "gui.blocklyText.huskyLensPro.importImg": "[COUNT1] pictures successfully imported this time, [COUNT2] failed",
    "gui.blocklyText.huskyLensPro.recognitionResult": "The recognition result:",
    "gui.blocklyText.tello.telloSetPitchRollYaw": "Set the amount of the four channel sticks of the remote control: roll [ROLL] pitch [PITCH] throttle [STRENGTH] yaw [YAW]",
    "gui.blocklyText.tello.telloQuerySpeed": "Current Speed",
    "gui.blocklyText.tello.telloTime": "Motor running time(s)",
    "gui.blocklyText.tello.telloWIFISign": "Wi-Fi SNR",
    "gui.blocklyText.tello.telloSDKVersion": "Tello SDK Version",
    "gui.blocklyText.tello.telloSN": "Tello SN Code",
    "gui.blocklyText.tello.reset": "Reset",
    "gui.blocklyText.tello.telloFlyCurve": "Flying curve warp x1[X1]cm y1[Y1]cm z1[Z1]cm and x2[X2]cm y2[Y2]cm z2[Z2]cm Speed ​​[SPEED] cm/s",
    "gui.blocklyText.tello.telloStopEmerygency": "Emergency Stop",
    "gui.blocklyText.tello.telloGetHeight": "Relative Height (cm)",
    "gui.blocklyText.tello.telloBarometer": "Barometer Height (m)",
    "gui.blocklyText.tello.telloTofDistance": "TOF Height (cm)",
    "gui.blocklyText.tello.telloTempMin": "Motherboard minimum temperature (°C)",
    "gui.blocklyText.tello.telloTempMax": "Motherboard maximum temperature (°C)",
    "gui.blocklyText.tello.TelloAttitudePitch": "Pitch axis attitude angle (°)",
    "gui.blocklyText.tello.TelloAttitudeRoll": "Roll axis attitude angle (°)",
    "gui.blocklyText.tello.TelloAttitudeYaw": "Pan axis attitude angle (°)",
    "gui.blocklyText.tello.telloAccelerationX": "X-axis acceleration (0.001g)",
    "gui.blocklyText.tello.telloAccelerationY": "Y-axis acceleration (0.001g)",
    "gui.blocklyText.tello.telloAccelerationZ": "Z-axis acceleration (0.001g)",
    "gui.blocklyText.tello.telloSpeedX": "X-axis speed (cm/s)",
    "gui.blocklyText.tello.telloSpeedY": "Y-axis speed (cm/s)",
    "gui.blocklyText.tello.telloSpeedZ": "Z-axis speed (cm/s)",
    "gui.blocklyText.tello.telloFlyToXY": "Fly (relative to the aircraft) x[X]cm y[Y]cm z[Z]cm speed [SPEED]cm/s",
    "gui.blocklyText.tello.telloFlyToMissionPad": "fly to MissionPad x[X]cm y[Y]cm z[Z]cm  with [SPEED] cm/s Mid [MID]",
    "gui.blocklyText.tello.TelloFlyCurveMissionPad": "Flying arc line under challenge card x1[X1]cm y1[Y1]cm z1[Z1]cm and x2[X2]cm y2[Y2]cm z2[Z2]cm speed [SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.telloJumpInMissionPad": "Jump along challenge card x[X]cm y[Y]cm z[Z]cm speed [SPEED]cm/s yaw [YAW] degree from Mid1 [MID1] to Mid2 [MID2]",
    "gui.blocklyText.telloesp32.telloGetStatus": "get status once, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloReturnStatusChallenge": "get from the result [STATUSCHALLENGE]",
    "gui.blocklyText.telloesp32.statusChallengeX": "Relative challenge card X coordinate",
    "gui.blocklyText.telloesp32.statusChallengeY": "Relative challenge card Y coordinate",
    "gui.blocklyText.telloesp32.statusChallengeZ": "Relative challenge card Z coordinate",
    "gui.blocklyText.telloesp32.statusChallengePitch": "Relative challenge card Pitch angle",
    "gui.blocklyText.telloesp32.statusChallengeYaw": "Relative challenge card Yaw angle",
    "gui.blocklyText.telloesp32.statusChallengeRoll": "Relative challenge card Roll angle",
    "gui.blocklyText.telloesp32.telloReturnStatusAttitude": "get from the result [STATUSATTITUDE]",
    "gui.blocklyText.telloesp32.statusAttitudePitch": "Pitch angle",
    "gui.blocklyText.telloesp32.statusAttitudeYaw": "Yaw angle",
    "gui.blocklyText.telloesp32.statusAttitudeRoll": "Roll angle",
    "gui.blocklyText.telloesp32.statusAttitudeXSpeed": "X axis speed",
    "gui.blocklyText.telloesp32.statusAttitudeYSpeed": "Y axis speed",
    "gui.blocklyText.telloesp32.statusAttitudeZSpeed": "Z axis speed",
    "gui.blocklyText.telloesp32.statusAttitudeXAcc": "X axis acceleration",
    "gui.blocklyText.telloesp32.statusAttitudeYAcc": "Y axis acceleration",
    "gui.blocklyText.telloesp32.statusAttitudeZAcc": "Z axis acceleration",
    "gui.blocklyText.telloesp32.statusChallengeId": "Challenge Card ID",
    "gui.blocklyText.telloesp32.telloReturnStatusNormal": "get from the result [STATUSNORMAL]",
    "gui.blocklyText.telloesp32.statusNormalMaxTemp": "Maximum temperature",
    "gui.blocklyText.telloesp32.statusNormalLowTemp": "Lowest temperature",
    "gui.blocklyText.telloesp32.statusNormalTof": "tof distance",
    "gui.blocklyText.telloesp32.statusNormalHeight": "Relative height",
    "gui.blocklyText.telloesp32.statusNormalBat": "Current battery",
    "gui.blocklyText.telloesp32.statusNormalBaro": "Barometric altitude",
    "gui.blocklyText.telloesp32.statusNormalMotor": "Motor time",
    "gui.blocklyText.tello.telloRotateWithYaw": "The aircraft rotates to the [YAW] angle relative to the challenge card [MID]",
    "gui.blocklyText.tello.telloStopAndHover": "Stop motion and hover",
    "gui.blocklyText.tello.MissionPad": "Challenge Card Number",
    "gui.blocklyText.tello.MissionPadX": "Challenge Card X(cm)",
    "gui.blocklyText.tello.MissionPadY": "Challenge Card Y(cm)",
    "gui.blocklyText.tello.telloMissionPadZ": "Challenge Card Z(cm)",
    "gui.blocklyText.tello.telloCommandResponse": "Reply to Command",
    "gui.blocklyText.tello.direction_front": "Front View",
    "gui.blocklyText.tello.direction_blow": "Down View",
    "gui.blocklyText.tello.direction_all": "All",
    "gui.blocklyText.tello.mid": "Challenge Card",
    "gui.blocklyText.tello.midfastest": "m-1",
    "gui.blocklyText.tello.midlatest": "m-2",
    "gui.blocklyText.tello.telloToggleMon": "[TOGGLE] Challenge Card Detection",
    "gui.blocklyText.tello.telloSetMDireaction": "Set the detection position [POSITION]",
    "gui.blocklyText.tello.telloSetWIFI": "Modify Tello's Wi-Fi as a hotspot [NAME] password [PASSWD]",
    "gui.blocklyText.tello.telloToggleGroup": "Switch to station mode, connect to the hotspot [NAME] password [PASSWD]",
    "gui.blocklyText.telloGroup.telloRise": "Rise",
    "gui.blocklyText.telloGroup.telloDown": "Down",
    "gui.blocklyText.telloGroup.telloGoahead": "Go",
    "gui.blocklyText.telloGroup.telloBack": "Back",
    "gui.blocklyText.telloGroup.telloTurnLeft": "Left",
    "gui.blocklyText.telloGroup.telloTurnRight": "Right",
    "gui.blocklyText.telloGroup.telloSetFly": "[DIRECTION] [NUMS]cm",
    "gui.blocklyText.telloGroup.telloClockwiseRotation": "Clockwise",
    "gui.blocklyText.telloGroup.telloCounterclockwiseSelection": "Counterclockwise",
    "gui.blocklyText.telloGroup.telloClockRotation": "[CLOCKWISE] Rotation [NUMS] degrees",
    "gui.blocklyText.telloGroup.setTelloIndexWithSN": "set Tello SN[SN] number as [INDEX]",
    "gui.blocklyText.telloGroup.setTelloIndexWithSSID": "set Tello SSID[SSID] number as [INDEX]",
    "gui.blocklyText.telloGroup.scanTelloInNetwork": "scan [NUM] Tello in network timeout [TIME]s",
    "gui.blocklyText.telloGroup.telloSynchronousCommand": "Synchronous command waiting time [TIME] seconds",
    "gui.blocklyText.tello.telloVideoToggle": "turn Tello`s video [VIDEO_STATE]",
    "gui.blocklyText.telloGroup.TEMPH": "Maximum temperature",
    "gui.blocklyText.telloGroup.TEMPL": "lowest temperature",
    "gui.blocklyText.telloGroup.NUM": "number",
    "gui.blocklyText.telloGroup.pitch": "pitch",
    "gui.blocklyText.telloGroup.roll": "roll",
    "gui.blocklyText.telloGroup.translation": "translation",
    "gui.blocklyText.telloGroup.telloAccelerationXYZ": "Acceleration [XYZ] (0.001g)",
    "gui.blocklyText.telloGroup.telloSpeedXYZ": "Speed [XYZ] (cm/s)",
    "gui.blocklyText.telloGroup.TelloAttitudePitch": "[ANGEL] attitude angle(°)",
    "gui.blocklyText.telloGroup.telloTempMinAndMax": "Main board [TEMP](°C)",
    "gui.blocklyText.tello.telloDisplayRollText": "Scroll display text direction [DIRECT] frequency [SPEED] text [TEXT] color [COLOR]",
    "gui.blocklyText.tello.telloDisplayText": "Display a single character [TEXT] color [COLOR]",
    "gui.blocklyText.tello.telloRollText_up": "Up",
    "gui.blocklyText.tello.telloRollText_down": "Down",
    "gui.blocklyText.tello.telloRollText_left": "Left",
    "gui.blocklyText.tello.telloRollText_right": "Right",
    "gui.blocklyText.telloGroup.initTelloGroup": "Disconnect",
    "gui.blocklyText.tello.shutdownForPower": "The battery is insufficient [POWER], Tello will shut down soon",
    "gui.blocklyText.tello.portCantUse": "Service error, port [PORT] access failed, please check if it is occupied by other programs",
    "gui.blocklyText.tello.telloReadESP32Version": "the firmware version of the expansion module",
    "gui.blocklyText.tello.telloSSID": "the SSID of the expansion module",
    "gui.blocklyText.tello.telloLightLEDWithColor": "LED color[COLOR]",
    "gui.blocklyText.tello.telloLightLEDWithColor_rgb": "LED color R:[RGBR] G:[RGBG] B:[RGBB]",
    "gui.blocklyText.tello.telloBreathLEDWithColor": "LED breathing light color [COLOR] frequency [TIME](Hz)",
    "gui.blocklyText.tello.telloBreathLEDWithColor_rgb": "LED breathing light R:[RGBR] G:[RGBG] B:[RGBB] frequency:[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor": "LED flashes color 1[COLOR1] and color 2[COLOR2] frequency [TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor_rgb": "LED flashing R1:[RGBR1] G1:[RGBG1] B1:[RGBB1] - R2:[RGBR2] G2:[RGBG2] B2:[RGBB2] frequency:[TIME](Hz)",
    "gui.blocklyText.tello.telloShowPattern": "Display pattern [MARK]",
    "gui.blocklyText.tello.telloShowPatternWhenStart": "Set the boot pattern [MARK]",
    "gui.blocklyText.tello.telloShowRollPattern": "Rolling display pattern direction [DIRECT] frequency [SPEED] pattern [MARK]",
    "gui.blocklyText.tello.telloSetLight": "Set screen brightness [NUM]",
    "gui.blocklyText.tello.telloSendCMD": "Send custom command [CMD]",
    "gui.blocklyText.tello.telloSendDirective": "Send a clear message [CMD]",
    "gui.blocklyText.tello.telloReceiveMsg": "Received string message [MSG]",
    "gui.blocklyText.tello.telloReceiveCustomNum": "Customized response value received",
    "gui.blocklyText.tello.connectTelloWIFIError": "Failed to connect to Tello Wi-Fi, please try to connect to Wi-Fi manually",
    "gui.blocklyText.tello.connectTelloError": "Failed to connect to Tello, please confirm the device status and reconnect",
    "gui.blocklyText.tello.tempIsHigh": "Tello temperature is too high and will shut down soon",
    "gui.blocklyText.tello.scanErrorWithOpenWlan": "An error occurred during scanning, please check whether Wi-Fi is turned on,Please try to connect to Wi-Fi manually",
    "gui.blocklyText.tello.noTelloFound": "No Tello device available, please try to connect to Wi-Fi manually",
    "gui.blocklyText.tello.connectIsBroke": "Tello connection has been disconnected, please check the device",
    "gui.blocklyText.tello.firstConnectTello": "Please connect to Tello first",
    "gui.blocklyText.tello.telloQueryWifiVersion": "the Wi-Fi version of the extension module",
    "gui.blocklyText.tello.telloQueryHardwareVersion": "Get Hardware Version",
    "gui.blocklyText.tello.telloToggleTOF": "[TOGGLE] TOF Module",
    "gui.blocklyText.tello.telloToggleTOF_enable": "Enable",
    "gui.blocklyText.tello.telloToggleTOF_disabled": "Disable",
    "gui.blocklyText.tello.activeTelloFirst": "Connection failed, the new aircraft is not activated, please follow the aircraft manual to activate",
    "gui.blocklyText.tello.startPaddle": "Enter propeller mode",
    "gui.blocklyText.tello.stopPaddle": "Exit the propeller mode",
    "gui.blocklyText.tello.togglePaddle": "[TOGGLE] Paddle Mode",
    "gui.blocklyText.tello.telloTogglePaddle_enable": "Enter",
    "gui.blocklyText.tello.telloTogglePaddle_disabled": "Exit",
    "gui.blocklyText.tello.telloThrowAway": "Throw away in 5 seconds",
    "gui.blocklyText.tello.telloResetPattern": "Restore the default boot pattern",
    "gui.blocklyText.tello.telloQueryTelloVersion": "Tello firmware version",
    "gui.blocklyText.tello.telloQueryBatActive": "Tello query battery level",
    "gui.blocklyText.tello.telloReadTOF": "Read TOF(mm)",
    "gui.blocklyText.telloGroup.allTellos": "all",
    "gui.blocklyText.tello.notAllowInput": "Please enter numbers or English characters, no other special characters",
    "gui.blocklyText.tello.checkFirewallAndTello": "The line is disconnected, Please check the tello connection status, and please add the software to the computer firewall whitelist",
    "gui.blocklyText.telloGroup.checkWlanNum": "Please make sure the machine is connected to the correct network, if it is connected to multiple networks, please disconnect other connections first",
    "gui.blocklyText.telloGroup.telloIsNotExist": "[INDEX] Tello does not exist",
    "gui.blocklyText.telloGroup.findTelloWithLost": "[FOUND] found, [NOTFOUND] not found, ip:[IP]",
    "gui.blocklyText.telloGroup.findTello": "[FOUND] found, ip:[IP]",
    "gui.blocklyText.telloGroup.cantRepeatScan": "Scanning, please do not repeat scanning...",
    "gui.blocklyText.telloGroup.setIndexForTelloFirst": "Please set the number first",
    "gui.blocklyText.maixduino.programMain": "K120 Maixduino starts",
    "gui.blocklyText.maixduino.LCDInit": "Display initialize freq [FREQ] Hz and background [COLOR] invert [INVERT]",
    "gui.blocklyText.maixduino.LCDShowImageFromPath": "Display image path: [PATH]",
    "gui.blocklyText.maixduino.LCDShowImageFromImage": "Display image: [IMAGE]",
    "gui.blocklyText.maixduino.LCDShowText": "Display words [TEXT]  at position x [X] y [Y] in [TEXT_COLOR] background [BACKGROUND_COLOR]",
    "gui.blocklyText.maixduino.LCDFill": "Clear the display to [COLOR] background",
    "gui.blocklyText.maixduino.LCDRotation": "Display rotate [ANGLE]",
    "gui.blocklyText.maixduino.LCDMirror": "Screen mirror is [MIRROR]",
    "gui.blocklyText.maixduino.LCDSize": "Display resolution [SIZE]",
    "gui.blocklyText.maixduino.LCDRGB888": "RGB888 red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.maixduino.LCDColor": "Color [COLOR]",
    "gui.blocklyText.blockTitle.maixduino.Camera": "Camera",
    "gui.blocklyText.maixduino.CameraInit": "Camera initialize [MODE] parameters [SETTINGS]",
    "gui.blocklyText.maixduino.CameraGetImage": "Camera get image",
    "gui.blocklyText.maixduino.CameraControl": "Double eye camera choose [CONTROL]",
    "gui.blocklyText.maixduino.CameraHorizontalMirroring": "Camera mirror-horizontal is [CONTROL]",
    "gui.blocklyText.maixduino.CameraVerticalMirroring": "Camera mirror-vertical is [CONTROL]",
    "gui.blocklyText.maixduino.CameraColorBarMode": "Camera colorful strips is [CONTROL]",
    "gui.blocklyText.maixduino.CameraSettingParameters": "Camera set [PARAMETERS] as [VALUE]",
    "gui.blocklyText.maixduino.CameraSettingWindow": "Camera set window width [WIDTH] height [HEIGHT]",
    "gui.blocklyText.maixduino.CameraGetParameters": "Camera get [PARAMETERS]",
    "gui.blocklyText.blockTitle.audioAndVideo": "Audio and Video",
    "gui.blocklyText.maixduino.AudioPlay": "Audio play [PATH] [WAIT]",
    "gui.blocklyText.maixduino.AudioVolume": "Audio volume [VOLUME] %",
    "gui.blocklyText.maixduino.AudioState": "Get audio play status",
    "gui.blocklyText.maixduino.AudioRecord": "Audio record [PATH] for [TIME] s and [WAIT]",
    "gui.blocklyText.maixduino.VideoPlay": "Video play [PATH] [WAIT]",
    "gui.blocklyText.maixduino.VideoVolume": "Video volume [VOLUME] %",
    "gui.blocklyText.maixduino.VideoState": "Get video play status",
    "gui.blocklyText.maixduino.VideoRecord": "Video record [PATH] for [TIME] s and [WAIT]",
    "gui.blocklyText.maixduino.digitalRead": "Read digital pin [PIN]",
    "gui.blocklyText.maixduino.analogRead": "Read analog pin [PIN]",
    "gui.blocklyText.maixduino.digitalWrite": "Digital pin [PIN] output [LEVEL]",
    "gui.blocklyText.maixduino.PWMInit": "Initialize PWM pin [PIN] freq [FREQ] Hz timer [TIMER]",
    "gui.blocklyText.maixduino.PWMWrite": "PWM pin [PIN] output duty cycle [DUTY]",
    "gui.blocklyText.maixduino.ticksDiff": "Return execute time difference, begin at [STARTTIME] and end at [ENDTIME]",
    "gui.blocklyText.maixduino.runTimer": "Execute time [TIMETYPE]",
    "gui.blocklyText.maixduino.gc": "[GC] garbage collection",
    "gui.blocklyText.maixduino.systemReset": "Reset",
    "gui.blocklyText.maixduino.print": "Print [DATA]",
    "gui.blocklyText.maixduino.LevelMenu.A": "high",
    "gui.blocklyText.maixduino.LevelMenu.B": "low",
    "gui.blocklyText.maixduino.MirrorMenu.A": "ON",
    "gui.blocklyText.maixduino.MirrorMenu.B": "OFF",
    "gui.blocklyText.maixduino.SelectMenu.A": "eye 0",
    "gui.blocklyText.maixduino.SelectMenu.B": "eye 1",
    "gui.blocklyText.maixduino.SettingParametersMenu.A": "contrast",
    "gui.blocklyText.maixduino.SettingParametersMenu.B": "brightness",
    "gui.blocklyText.maixduino.SettingParametersMenu.C": "gain value",
    "gui.blocklyText.maixduino.SettingParametersMenu.D": "saturation",
    "gui.blocklyText.maixduino.GetParametersMenu.A": "resolution width",
    "gui.blocklyText.maixduino.GetParametersMenu.B": "resolution width and height",
    "gui.blocklyText.maixduino.GetParametersMenu.C": "gain value",
    "gui.blocklyText.maixduino.GetParametersMenu.D": "frame image cache",
    "gui.blocklyText.maixduino.GetParametersMenu.E": "hardware ID",
    "gui.blocklyText.maixduino.TimeTypeMenu.A": "s",
    "gui.blocklyText.maixduino.TimeTypeMenu.B": "ms",
    "gui.blocklyText.maixduino.TimeTypeMenu.C": "μs",
    "gui.blocklyText.maixduino.GcMenu.A": "automatic",
    "gui.blocklyText.maixduino.GcMenu.B": "start",
    "gui.blocklyText.maixduino.LcdSizeMenu.A": "width",
    "gui.blocklyText.maixduino.LcdSizeMenu.B": "height",
    "gui.blocklyText.maixduino.WaitMenu.A": "wait",
    "gui.blocklyText.maixduino.WaitMenu.B": "background",
    "gui.blocklyText.maixduino.CameraModeMenu.A": "monocular",
    "gui.blocklyText.maixduino.CameraModeMenu.B": "binocular",
    "gui.blocklyText.maixduino.AngleMenu.A": "0°",
    "gui.blocklyText.maixduino.AngleMenu.B": "90°",
    "gui.blocklyText.maixduino.AngleMenu.C": "180°",
    "gui.blocklyText.maixduino.AngleMenu.D": "270°",
    "gui.blocklyText.maixduino.camerainit.frameformat": "Frame Format",
    "gui.blocklyText.maixduino.camerainit.frameformat.RGB565": "color",
    "gui.blocklyText.maixduino.camerainit.frameformat.GRAYSCALE": "gray",
    "gui.blocklyText.maixduino.camerainit.framesize": "Frame Size",
    "gui.blocklyText.maixduino.camerainit.imagecapture": "Screenshot",
    "gui.blocklyText.maixduino.camerainit.imagecapture.on": "ON",
    "gui.blocklyText.maixduino.camerainit.imagecapture.off": "OFF",
    "gui.blocklyText.maixduino.camerainit.skipframes": "Frame Skip No.:",
    "gui.extension.AI.name": "AI",
    "gui.blocklyText.AI.anchorPoint": "Anchor parameters [POINT]",
    "gui.blocklyText.AI.classNames": "Object name [NAMES]",
    "gui.blocklyText.AI.modelLoadingAddress": "Load model [ADDRESS]",
    "gui.blocklyText.AI.modelLoadingPath": "Model path [PATH]",
    "gui.blocklyText.AI.yolo2Init": "yolo2 initialize network model [MODEL] , probability threshold [PT] , box_iou threshold [BT] anchor [APC] and anchor parameter [AP]",
    "gui.blocklyText.AI.yolo2Run": "yolo2 run network model [MODEL] image [IMAGE]",
    "gui.blocklyText.AI.yolo2forward": "yolo2 operation model [MODEL] image [IMAGE]",
    "gui.blocklyText.AI.yolo2Parsing": "yolo2 analytic model object [OBJ] and get [TYPE]",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.A": "X coordinate",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.B": "Y coordinate",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.C": "IP",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.D": "Confidence",
    "gui.blocklyText.machineHearing.newModel": "ARS create blank model",
    "gui.blocklyText.machineHearing.training": "ASR start train words [CORPUS] to add to model [MODEL]",
    "gui.blocklyText.machineHearing.run": "ARS run model [MODEL]",
    "gui.blocklyText.machineHearing.recognize": "ASR speech recognition result",
    "gui.blocklyText.machineHearing.save": "ASR save model [MODEL] to [PATH]",
    "gui.blocklyText.machineHearing.load": "ASR load model [PATH]",
    "gui.extension.machineVision.imageBasis.name": "Machine Vision · Image Basics",
    "gui.blocklyText.machineVision.openEmptyImage": "Open blank image",
    "gui.blocklyText.machineVision.openPathImage": "Open path [PATH]",
    "gui.blocklyText.machineVision.clearImage": "Clear image [IMAGE]",
    "gui.blocklyText.machineVision.saveImage": "Save image [IMAGE] to path [PATH]",
    "gui.blocklyText.machineVision.modifyPixelColor": "Modify image [IMAGE] coordinate xy [COORDINATE] in [COLOR]",
    "gui.blocklyText.machineVision.getPixelColor": "Get color of image [IMAGE] coordinate xy [COORDINATE]",
    "gui.blocklyText.machineVision.captureImage": "Intercept image [IMAGE] area-xywh [AREA]'",
    "gui.blocklyText.machineVision.compressedImage": "Compress image [IMAGE] with rate [RATIO]",
    "gui.blocklyText.machineVision.getImageInfo": "Get image [IMAGE] [INFO]",
    "gui.blocklyText.machineVision.conversion": "Convert image [IMAGE] to [TYPE]",
    "gui.extension.machineVision.imageDrawing.name": "Machine Vision · Image Drawing",
    "gui.blocklyText.machineVision.drawText": "Draw text [IMAGE] start point-xy [COORDINATE] content [CONTENT] in [COLOR] font size [SIZE]",
    "gui.blocklyText.machineVision.drawImage": "Draw [IMAGE] -xy [COORDINATE] to a scaling image -xy [ZOOM]",
    "gui.blocklyText.machineVision.drawLine": "Draw [IMAGE] -xyxy to a line image [COORDINATE] in [COLOR] weight [SIZE]",
    "gui.blocklyText.machineVision.drawArrows": "Draw [IMAGE] -xyxy an arrow image [COORDINATE] in [COLOR] size [SIZE]",
    "gui.blocklyText.machineVision.drawCross": "Draw [IMAGE] -xy a cross [COORDINATE] in [COLOR] extend [EXTEND] weight [SIZE]",
    "gui.blocklyText.machineVision.drawRound": "Draw [IMAGE] -xyr a circle image [COORDINATE] in [COLOR] weight [SIZE] filled [FILLING]",
    "gui.blocklyText.machineVision.drawRectangle": "Draw [IMAGE] -xywh a rectangle [COORDINATE] in [COLOR] weight [SIZE] filled [FILLING]",
    "gui.blocklyText.machineVision.drawFeaturePoints": "Draw [IMAGE] a feature point object [OBJ] in [COLOR] size [SIZE] weight [THICKNESS] filled [FILLING]",
    "gui.extension.machineVision.imageFiltering.name": "Machine Vision · Image Filtering",
    "gui.blocklyText.machineVision.correctionZoom": "Image rectification and scaling [IMAGE]  with correcting value [CORRECTION] and zoom value [ZOOM]",
    "gui.blocklyText.machineVision.histogramEqualization": "Image histogram equalization [IMAGE] self-adopting [ADAPTIVE] at contrast [CONTRAST]",
    "gui.blocklyText.machineVision.fuzzyFilter": "Blur filter [IMAGE] convolution kernel [CONVOLUTIONKERNEL] self-adopting [ADAPTIVE] compensation [MAKEUP]",
    "gui.blocklyText.machineVision.cartoonFilter": "Cartoon filter [IMAGE] original difference [ORIGINALDIFFERENCE] neighboring difference [ADJACENTDIFFERENCE]",
    "gui.blocklyText.machineVision.imageCorrosion": "Erode [IMAGE] convolution kernel [CONVOLUTIONKERNEL] thread value [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageExpansion": "Dilate [IMAGE] convolution kernel [CONVOLUTIONKERNEL] thread value [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageFill": "Image padding [IMAGE] coordinate-xy [COORDINATE] in [COLOR] original difference [ORIGINALDIFFERENCE] neighboring difference [ADJACENTDIFFERENCE] invert [REVERSE] clear [CLEAR]",
    "gui.blocklyText.machineVision.ImageProjection": "Vertical image projection [IMAGE] is [VERTICALPROJECTION]",
    "gui.blocklyText.machineVision.ImageInversion": "Image inversion [IMAGE]",
    "gui.extension.machineVision.shapeRecognition.name": "Machine Vision · Shape Recognition",
    "gui.blocklyText.machineVision.straightLineAnalysis": "Line analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.circularAnalysis": "Circle analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.rectangleAnalysis": "Rectangle analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.lineRecognition": "Line recognition [IMAGE] area-xywh [AREA] thread value [THRESHOLD] includes angle [MERGEANGLE] and slope [MERGESLOPE]",
    "gui.blocklyText.machineVision.lineSegmentRecognition": "Line segment recognition [IMAGE] area- xywh [AREA] space [SPACING] include angle [MERGEANGLE]",
    "gui.blocklyText.machineVision.circleRecognition": "Circle recognition [IMAGE] area-xywh [AREA] thread value [THRESHOLD] min r [MINIMUM] max r [MAXIMUM] step r [STRIDE] include x [COMBINEDX] and y [COMBINEDY] and r [COMBINEDR]",
    "gui.blocklyText.machineVision.rectangleRecognition": "Rectangle recognition [IMAGE] area- xywh [AREA] thread value [THRESHOLD]",
    "gui.extension.machineVision.colorRecognition.name": "Machine Vision · Color Recognition",
    "gui.blocklyText.machineVision.LABFromRGB888": "LAB value depends on RGB888 [RGB888]",
    "gui.blocklyText.machineVision.RGB888FromLAB": "RGB888 value depends on LAB value [LAB]",
    "gui.blocklyText.machineVision.grayscaleFromRGB888": "Gray value depends on RGB888 [RGB888]",
    "gui.blocklyText.machineVision.GRB888FromGrayscale": "RGB888 value depends on gray value [GRAYSCALE]",
    "gui.blocklyText.machineVision.trackingAnalysis": "Track analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.colorAnalysis": "Color analysis object [OBJ] CDF [CDF] to get [INFO]",
    "gui.blocklyText.machineVision.colorPatchTracking": "Color block tracking [IMAGE] threshold value LAB [THRESHOLDLAB] area-xywh [AREA] filtered area [FILTEROUTAREA] filtered pixels [FILTEROUTPRIMENUMBERS] include [MERGE] overlay is [OVERLAPPING]",
    "gui.blocklyText.machineVision.colorRecognition": "Color recognition [IMAGE] area-xywh [AREA]",
    "gui.extension.machineVision.dimensionCodeRecognition.name": "Machine Vision · Code Recognition",
    "gui.blocklyText.machineVision.barcodeAnalysis": "Barcode analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.QRCodeAnalysis": "QR code analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.aprilTagAnalysis": "ApriTag analysis object [OBJ] to get [INFO]",
    "gui.blocklyText.machineVision.BarCodeRecognition": "Barcode recognize [IMAGE] area-xywh [AREA]",
    "gui.blocklyText.machineVision.QRCodeRecognition": "QR code recognize [IMAGE] area-xywh [AREA]",
    "gui.blocklyText.machineVision.AprilTagRecognition": "ApriTag recognize [IMAGE] area-xywh [AREA]",
    "gui.extension.machineVision.featureRecognition.name": "Machine Vision · Feature Recognition",
    "gui.blocklyText.machineVision.featureInformation": "Feature informaiton object [OBJ] get [INFO]",
    "gui.blocklyText.machineVision.directionalGradienMap": "Directted gradient image [IMAGE] area-xywh [AREA] length [LINELENGTH]",
    "gui.blocklyText.machineVision.featureRecognition": "Feature recognition [IMAGE] area-xywh [AREA] key points [KEYPOINTS] angle points [CORNERPOINTS] scale factor [SCALEFACTOR] multiple scale is [MULTIRATIO]",
    "gui.blocklyText.machineVision.featureComparison": "Feature contrast Object1 [OBJ1] Object2 [OBJ2] similarity [SIMILARITY]",
    "gui.blocklyText.machineVision.FeatureInformationMenu.A": "coordinate-xywh",
    "gui.blocklyText.machineVision.FeatureInformationMenu.B": "x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.C": "y",
    "gui.blocklyText.machineVision.FeatureInformationMenu.D": "w",
    "gui.blocklyText.machineVision.FeatureInformationMenu.E": "h",
    "gui.blocklyText.machineVision.FeatureInformationMenu.F": "center-x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.G": "center-y",
    "gui.blocklyText.machineVision.FeatureInformationMenu.H": "matach value",
    "gui.blocklyText.machineVision.FeatureInformationMenu.I": "radian",
    "gui.blocklyText.machineVision.FeatureInformationMenu.J": "match point-xy",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.H": "number",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.I": "family",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.J": "radian",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.K": "x distance",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.L": "y distance",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.M": "z distance",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.N": "x radian",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.O": "y radian",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.P": "z radian",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.F": "content",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.G": "version",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.H": "mask",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.I": "type",
    "gui.blocklyText.machineVision.BarcodeAnalysisMenu.H": "angle",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.A": "gray value",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.B": "LAB L",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.C": "LAB A",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.D": "LAB B",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.H": "pixels",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.J": "number",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.K": "include",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.L": "area",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.M": "density ratio",
    "gui.blocklyText.machineVision.CircularInformationMenu.A": "coordinate-xyr",
    "gui.blocklyText.machineVision.CircularInformationMenu.D": "r",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.B": "x1",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.C": "y1",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.D": "x2",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.E": "y2",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.F": "length",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.H": "slope",
    "gui.blocklyText.machineVision.ImageInfoMenu.A": "width",
    "gui.blocklyText.machineVision.ImageInfoMenu.B": "height",
    "gui.blocklyText.machineVision.ImageInfoMenu.C": "format",
    "gui.blocklyText.machineVision.ImageInfoMenu.D": "size",
    "gui.blocklyText.machineVision.ConversionTypeMenu.A": "Gray",
    "gui.blocklyText.machineVision.ConversionTypeMenu.B": "Color",
    "gui.blocklyText.machineVision.ConversionTypeMenu.C": "Rainbow",
    "gui.blocklyText.machineVision.ConversionTypeMenu.D": "AI",
    "gui.extension.mpyirq.name": "Interrupt",
    "gui.blocklyText.mpyirq.event": "interrupt pin [PIN] mode [MODE] excute",
    "gui.blocklyText.mpyirq.clean": "cancle interrupt pin [PIN]",
    "gui.blocklyText.pyTurtle.init": "Create a turtle plotter [NAME]",
    "gui.blocklyText.pyTurtle.turtleSetShape": "[NAME] shape is set to [SHAPE]",
    "gui.blocklyText.pyTurtle.turtleDone": "Drawing is over, continue to keep the window",
    "gui.blocklyText.pyTurtle.turtleExitClick": "Click to exit drawing",
    "gui.blocklyText.pyTurtle.turtleMove": "[NAME]Move[DIRECTION][DISTANCE]",
    "gui.blocklyText.pyTurtle.turtleRotate": "[NAME]Rotation[DIRECTION][ANGLE]",
    "gui.blocklyText.pyTurtle.turtleFaceTo": "[NAME] faces [ANGLE] degrees",
    "gui.blocklyText.pyTurtle.turtleMoveToPos": "[NAME] moves to coordinate X[X] Y[Y] position",
    "gui.blocklyText.pyTurtle.turtleSetPos": "[NAME] [XY] coordinate is set to [POS]",
    "gui.blocklyText.pyTurtle.turtleGetStatus": "[NAME] gets the current [STATUS]",
    "gui.blocklyText.pyTurtle.turtleClearAll": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleClone": "[NAME] Copy Turtle",
    "gui.blocklyText.pyTurtle.turtleVisible": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleSetThickness": "[NAME] Set brush thickness [THICK]",
    "gui.blocklyText.pyTurtle.turtleSetScale": "[NAME] Set the size of the turtle icon. Vertical stretch multiple [VERTICAL] Horizontal stretch multiple [HOR] Outline width [OUTLINE]",
    "gui.blocklyText.pyTurtle.turtleSetSpeed": "[NAME] sets the brush speed to [SPEED]",
    "gui.blocklyText.pyTurtle.turtleSetBackground": "[NAME] set background color [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetPenColor": "[NAME] Set the pen color [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetFillColor": "[NAME] sets the brush fill color [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetColor": "[NAME] Set the brush color [COLOR1] Set the brush fill color [COLOR2]",
    "gui.blocklyText.pyTurtle.turtleDrawAction": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleDrawCircle": "[NAME] draws graphics [CIRCLE] with radius [RADIUS]",
    "gui.blocklyText.pyTurtle.turtleDrawCircleWithAngle": "[NAME] draws a circle with a radius of [RADIUS] and an angle of [DEGREE]",
    "gui.blocklyText.pyTurtle.turtleInitScreen": "[NAME] Initialize the turtle screen [SCREEN]",
    "gui.blocklyText.pyTurtle.turtleSaveDraw": "[NAME] saves the drawn image as a picture [PICTURES]",
    "gui.blocklyText.pyTurtle.turtleSetAnimationInterval": "[NAME] sets the animation playback interval to [TIME] milliseconds",
    "gui.blocklyText.pyTurtle.turtleDrawText": "[NAME] write [TEXT]",
    "gui.blocklyText.pyTurtle.turtleDrawTextWithAttr": "[NAME] Writing [TEXT] The pen moves with the text [JUDGE] Text alignment direction [ALIGN] Font [FONT] Font size [FONTSIZE] Font style [TYPE]",
    "gui.blocklyText.pyTurtle.turtleGetFloat": "Turtle gets the entered floating point number. The title is [TITLE] and the prompt is [TIP] default value [VALUE] minimum value [MIN] maximum value [MAX]",
    "gui.blocklyText.pyTurtle.turtleGetString": "Turtle gets the input string, the title is [TITLE] and the prompt is [TIP]",
    "gui.blocklyText.pyTurtle.roll_forward": "forward",
    "gui.blocklyText.pyTurtle.roll_backward": "Backward",
    "gui.blocklyText.pyTurtle.roll_l_left": "Left",
    "gui.blocklyText.pyTurtle.roll_l_right": "To the right",
    "gui.blocklyText.pyTurtle.xy_x": "X",
    "gui.blocklyText.pyTurtle.xy_y": "Y",
    "gui.blocklyText.pyTurtle.status_pos": "Position",
    "gui.blocklyText.pyTurtle.status_shape": "Shape",
    "gui.blocklyText.pyTurtle.status_heading": "Heading",
    "gui.blocklyText.pyTurtle.status_width": "Brush Width",
    "gui.blocklyText.pyTurtle.status_shapesize": "Turtle icon size",
    "gui.blocklyText.pyTurtle.status_speed": "Speed",
    "gui.blocklyText.pyTurtle.action_clear": "Clear Screen",
    "gui.blocklyText.pyTurtle.action_reset": "Reset",
    "gui.blocklyText.pyTurtle.action_home": "Return to Origin",
    "gui.blocklyText.pyTurtle.pen_action_penup": "Pen up",
    "gui.blocklyText.pyTurtle.pen_action_pendown": "Pendown",
    "gui.blocklyText.pyTurtle.visible_hideturtle": "Hide Turtle",
    "gui.blocklyText.pyTurtle.visible_showturtle": "Show Turtle",
    "gui.blocklyText.pyTurtle.visible_turtle": "Turtle",
    "gui.blocklyText.pyTurtle.visible_arrow": "Arrow",
    "gui.blocklyText.pyTurtle.visible_circle": "Circle",
    "gui.blocklyText.pyTurtle.visible_square": "Block",
    "gui.blocklyText.pyTurtle.visible_triangle": "Triangle",
    "gui.blocklyText.pyTurtle.visible_classic": "Default",
    "gui.blocklyText.pyTurtle.drawaction_begin": "Start filling",
    "gui.blocklyText.pyTurtle.drawaction_end": "End Fill",
    "gui.blocklyText.pyTurtle.circle_circle": "Circle",
    "gui.blocklyText.pyTurtle.circle_dot": "dot",
    "gui.blocklyText.pyTurtle.flag_False": "No",
    "gui.blocklyText.pyTurtle.flag_True": "Yes",
    "gui.blocklyText.pyTurtle.align_left": "Align Left",
    "gui.blocklyText.pyTurtle.align_center": "Centered",
    "gui.blocklyText.pyTurtle.align_right": "Align Right",
    "gui.blocklyText.pyTurtle.align_normal": "Standard",
    "gui.blocklyText.pyTurtle.align_bold": "Bold",
    "gui.blocklyText.pyTurtle.align_italic": "Tilt",
    "gui.blocklyText.pyTurtle.align_bi": "Slanted and bold",
    "gui.blocklyText.pyTurtle.turtleScreenSetup": "Initialize the turtle window size width [WIDTH] height [HEIGHT] offset X [LEFT] Y [TOP]",
    "gui.blocklyText.pyTurtle.turtleScreenSize": "Initialize the turtle canvas width [WIDTH] height [HEIGHT] background color [COLOR]",
    "gui.blocklyText.pyGenerator.importModule": "import [NAME]",
    "gui.blocklyText.pyGenerator.importAs": "import [MOUDLE] as [NAME]",
    "gui.blocklyText.pyGenerator.fromImport": "from [FROM] import [MOUDLE]",
    "gui.blocklyText.pyGenerator.annotation": "# [TEXT]",
    "gui.blocklyText.pyGenerator.defineFunction": "def [FUNCTION]([ARGUMENT]):",
    "gui.blocklyText.pyGenerator.callFunction": "Call function [FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.callFunctionWithValue": "Call function to get the return value [FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.defineClass": "class [CLASS]:",
    "gui.blocklyText.pyGenerator.instanceClass": "[CLASS] [INSTANCE]",
    "gui.blocklyText.pyGenerator.reporterType": "Code [CODE]",
    "gui.blocklyText.pyGenerator.typeBoolean": "Code [CODE]",
    "gui.blocklyText.pyGenerator.annotationBlock": "Block comment",
    "gui.blocklyText.pyGenerator.originCode": "Code [CODE]",
    "gui.extension.xiamiboard.title": "Xia Mi Expansion Board",
    "gui.extension.xiamiboard.setMotor": "motor [Select] move [Direction] at speed [Speed]",
    "gui.extension.xiamiboard.stopMotor": "stop motor [Select]",
    "gui.extension.xiamiboard.setTrafficLights": "set traffic light red [Red] yellow [Yellow] green [Green]",
    "gui.extension.xiamiboard.readAngle": "read rotation angle sensor",
    "gui.extension.xiamiboard.readFlame": "read flame sensor",
    "gui.extension.xiamiboard.readMotorState": "read motor [Select] state [State]",
    "gui.extension.xiamiboard.readTrafficLights": "read traffic light state [State]",
    "gui.extension.xiamiboard.readRelay": "set relay [State]",
    "gui.extension.xiamiboard.readUltrasonicDistance": "read ultrasonic sensor(cm)",
    "gui.extension.xiamiboard.initializationScreen": "init OLED display",
    "gui.extension.xiamiboard.screenDisplay": "OLED display text [Text] at coordinates of x [Row] y [Column]",
    "gui.extension.xiamiboard.clearScreenAll": "clear OLED display",
    "gui.extension.xiamiboard.clearScreen": "clear OLED display row [Row] from column [ColumnFrom] to [ColumnTo]",
    "gui.extension.xiamiboard.readInfrared": "read IR key value",
    "gui.extension.xiamiboard.receiveData": "on IR received [Data]",
    "gui.extension.xiamiboard.wirelessData": "infrared data",
    "gui.extension.xiamiboard.xiamiRGB": "red [Red] green [Green] blue [Blue]",
    "gui.extension.xiamiboard.setRGBLightsRange": "Set the RGB lamp lighting [Range] Display color [Color]",
    "gui.extension.xiamiboard.setLightsColor": "RGB LED [Num] show color [Color]",
    "gui.extension.xiamiboard.setLightsBrightness": "set RGB LED brightness to [Brightness]",
    "gui.extension.xiamiboard.coloseLightsAll": "turn off all RGB LEDs",
    "gui.extension.xiamiboard.initializeST": "init [TYPE] temperature sensor",
    "gui.extension.xiamiboard.readSTState": "read [TYPE] state [State]",
    "gui.blocklyText.xiamiboard.all": "all",
    "gui.blocklyText.xiamiboard.cw": "forward",
    "gui.blocklyText.xiamiboard.ccw": "backward",
    "gui.blocklyText.xiamiboard.speed": "speed",
    "gui.blocklyText.xiamiboard.dir": "direction",
    "gui.blocklyText.xiamiboard.temp": "temperature",
    "gui.blocklyText.xiamiboard.hum": "humidity",
    "gui.blocklyText.xiamiboard.colorRed": "red",
    "gui.blocklyText.xiamiboard.colorYellow": "yellow",
    "gui.blocklyText.xiamiboard.colorGreen": "green",
    "gui.blocklyText.xiamiboard.close": "connected",
    "gui.blocklyText.xiamiboard.disconnect": "disconnected",
    "gui.blocklyText.xiamiboard.lightAll": "all",
    "gui.extension.serialPort.name": "send data to real-time mode",
    "gui.extension.serialPortToAC.name": "send data to upload mode",
    "gui.extension.serialPortjJS.name": "receive upload mode data",
    "gui.extension.pyMqtt.initMqtt": "MQTT initial [SETTING]",
    "gui.extension.pyMqtt.connect": "connect MQTT",
    "gui.extension.pyMqtt.loop_forever": "MQTT stay connected, forever",
    "gui.extension.pyMqtt.loopNeverForever": "MQTT stay connected, timeout [Time] sec",
    "gui.extension.pyMqtt.disconnect": "disconnect MQTT",
    "gui.extension.pyMqtt.pushlish": "MQTT publish [INFO] to [Topic]",
    "gui.extension.pyMqtt.pushlish_save": "MQTT publish [INFO] to [Topic] and saved to database (SIoT V2)",
    "gui.extension.pyMqtt.subscribe": "MQTT subscribe to [Topic]",
    "gui.extension.pyMqtt.received": "on MQTT received [MqttInfo] from [Topic]",
    "gui.extension.pyMqtt.topic": "MQTT topic",
    "gui.extension.pyMqtt.mqttInfo": "MQTT message",
    "gui.blocklyText.pyMqtt.pyMqttInitPlatform": "MQTT platform",
    "gui.blocklyText.pyMqtt.EasyIOT_Server": "EasyIoT server",
    "gui.blocklyText.pyMqtt.EasyIOTServerChina": "EasyIoT China",
    "gui.blocklyText.pyMqtt.EasyIOTServerGlobal": "EasyIoT Global",
    "gui.blocklyText.pyMqtt.EasyIOT_Port": "EasyIoT Port",
    "gui.blocklyText.pyMqtt.EasyIOT_id": "EasyIoT ID",
    "gui.blocklyText.pyMqtt.siotServer": "SIoT server",
    "gui.blocklyText.pyMqtt.siotServerPort": "SIoT Port",
    "gui.blocklyText.pyMqtt.siotServerUser": "SIoT username",
    "gui.blocklyText.pyMqtt.siotServerPass": "SIoT password",
    "gui.extension.pyGame.pygameModule": "Pygame Module",
    "gui.extension.pyGame.pygameInit": "initialize all imported pygame modules",
    "gui.extension.pyGame.pygameQuit": "uninstall all initialized pygame modules",
    "gui.extension.pyGame.displayModule": "Display Module",
    "gui.extension.pyGame.displayInit": "initialize display module",
    "gui.extension.pyGame.displayQuit": "uninstall initialized display module",
    "gui.extension.pyGame.windowInit": "initialize window [Win] of [Width] x [Height] [Flags]",
    "gui.extension.pyGame.windowUpdate": "upload window interface (some softwares)",
    "gui.extension.pyGame.windowSetIcon": "set window icon [Value]",
    "gui.extension.pyGame.windowSetTitle": "set window title [Value]",
    "gui.extension.pyGame.windowFlip": "update Surface object to screen",
    "gui.extension.pyGame.windowDraw": "draw [Suf] to [Postion] of window [Win]",
    "gui.extension.pyGame.surfaceObject": "Surface Object",
    "gui.extension.pyGame.createSurface": "create Surface object [Surface] with [Width] x [Height]",
    "gui.extension.pyGame.blitSurface": "draw [Obj] to [Pos] of Surface object [Surface]",
    "gui.extension.pyGame.fillSurface": "fill Surface object [Surface] with [Color]",
    "gui.extension.pyGame.surfaceSetAt": "set [Pos] pixels of Surface object [Surface] to [Color]",
    "gui.extension.pyGame.surfaceGetAt": "get color of [Pos] pixels of Surface object [Surface]",
    "gui.extension.pyGame.surfaceGetWidth": "get Surface object [Surface] width",
    "gui.extension.pyGame.surfaceGetHeight": "get Surface object [Surface] height",
    "gui.extension.pyGame.surfaceGetRect": "get Surface object [Surface] rectangular area",
    "gui.extension.pyGame.surfaceMove": "Surface[Surface] offset coordinates [CONTENT]",
    "gui.extension.pyGame.eventModule": "Event Module",
    "gui.extension.pyGame.windowEvent": "get window event",
    "gui.extension.pyGame.eventType": "[Event] event type",
    "gui.extension.pyGame.eventKey": "[Event] [Mod]",
    "gui.extension.pyGame.event": "[Event] event",
    "gui.extension.pyGame.eventConstant": "[Constant] key",
    "gui.extension.pyGame.eventModKey": "[Constant] key combination",
    "gui.extension.pyGame.eventMod": "Check whether the [Keys] key combination is pressed",
    "gui.extension.pyGame.eventOr": "[Key1]|[Key2]",
    "gui.extension.pyGame.fontModule": "Font Module",
    "gui.extension.pyGame.fontInit": "initialize font module",
    "gui.extension.pyGame.fontQuit": "unload initialized font module",
    "gui.extension.pyGame.createFont": "create font object [Size] [Source] from [Font]",
    "gui.extension.pyGame.getDefaultFont": "get font file name on system",
    "gui.extension.pyGame.getCanUsedFont": "get available font list on system",
    "gui.extension.pyGame.generationFont": "create text [Text] of anti-aliasing [Antialias] color [Color] opacity [Opacity] by font object [Font]",
    "gui.extension.pyGame.renderStyle": "set font [Font] style [Bold] [Italics] [Underline]",
    "gui.extension.pyGame.imageModule": "Image Module",
    "gui.extension.pyGame.imageLoad": "load image from [FileName]",
    "gui.extension.pyGame.supportExtension": "whether filename extension in loaded image is supported?",
    "gui.extension.pyGame.imageSave": "save [Img] as [FileName]",
    "gui.extension.pyGame.drawModule": "Draw Module",
    "gui.extension.pyGame.drawRect": "draw rectangle with color [Color] configure [Rect] border width [Width] to [Win]",
    "gui.extension.pyGame.drawCircle": "draw circle with color [Color] position [Rect] radius [Size] border width [Width] to [Win]",
    "gui.extension.pyGame.drawLine": "draw line with color [Color] start position [start_pos] end position [end_pos] width [Width] to [Win]",
    "gui.extension.pyGame.drawLines": "draw multiple lines with color [Color] closed [Closed] position [Pointlist] width [Width] to [Win]",
    "gui.extension.pyGame.mixerModule": "Mixer Module",
    "gui.extension.pyGame.mixerInit": "initialize mixer module",
    "gui.extension.pyGame.mixerQuit": "uninstall initialized mixer module",
    "gui.extension.pyGame.createSound": "load sound object from [FileName]",
    "gui.extension.pyGame.soundPlay": "play sound [Sound]",
    "gui.extension.pyGame.soundStop": "stop sound [Sound]",
    "gui.extension.pyGame.soundFadeout": "set time [Time] (ms) for sound [Sound] to fade out (gradually weaken until it disappears)",
    "gui.extension.pyGame.soundSetVolume": "set sound [Sound] volume [Value]%",
    "gui.extension.pyGame.soundGetbusy": "if sound still plays?",
    "gui.extension.pyGame.timeModule": "Time Module",
    "gui.extension.pyGame.getRuntime": "get run time(milliseconds)",
    "gui.extension.pyGame.pauseWay": "pause [Time] (ms) by [Way]",
    "gui.extension.pyGame.setTimer": "create timer of [Once] [Event] event after [Time] (ms)",
    "gui.extension.pyGame.clockInit": "initialize clock [Clock]",
    "gui.extension.pyGame.updateClock": "update clock [Clock]",
    "gui.extension.pyGame.getClockUp": "get time used in previous scale of [Clock]",
    "gui.extension.pyGame.getClockUpRaw": "get real time used in previous scale of [Clock]",
    "gui.extension.pyGame.getClockFps": "get [Clock] to calculate frame rate of clock",
    "gui.extension.pyGame.musicModule": "Music Module",
    "gui.extension.pyGame.musicLoad": "load music object from [FileName]",
    "gui.extension.pyGame.setVolume": "set music volume [Value]%",
    "gui.extension.pyGame.musicPlay": "play music",
    "gui.extension.pyGame.musicRewind": "replay music",
    "gui.extension.pyGame.musicPause": "pause music",
    "gui.extension.pyGame.musicUnpause": "unpause music",
    "gui.extension.pyGame.musicStop": "stop music",
    "gui.extension.pyGame.musicFadeout": "set time [Time] (ms) for music to fade out (gradually weaken until it disappears)",
    "gui.extension.pyGame.musicGetbusy": "If music still plays?",
    "gui.extension.pyGame.colorModule": "Color Module",
    "gui.extension.pyGame.color": "convert [Color] to RGB",
    "gui.blocklyText.pyGame.gain": "Gain",
    "gui.blocklyText.pyGame.state": "State",
    "gui.blocklyText.pyGame.unicode": "Unicode",
    "gui.blocklyText.pyGame.key": "Key",
    "gui.blocklyText.pyGame.mod": "Mod",
    "gui.blocklyText.pyGame.pos": "Pos",
    "gui.blocklyText.pyGame.rel": "Rel",
    "gui.blocklyText.pyGame.buttons": "Buttons",
    "gui.blocklyText.pyGame.size": "window size",
    "gui.blocklyText.pyGame.w": "window width",
    "gui.blocklyText.pyGame.h": "window height",
    "gui.blocklyText.pyGame.once": "Once",
    "gui.blocklyText.pyGame.repeat": "Repeat",
    "gui.blocklyText.pyGame.close": "closed",
    "gui.blocklyText.pyGame.noclose": "no-closed",
    "gui.blocklyText.pyGame.fullscreen": "FULLSCREEN",
    "gui.blocklyText.pyGame.doublebuf": "DOUBLEBUF",
    "gui.blocklyText.pyGame.hwsurface": "HWPALETTE",
    "gui.blocklyText.pyGame.opengl": "OPENGL",
    "gui.blocklyText.pyGame.resizable": "RESIZABLE",
    "gui.blocklyText.pyGame.noframe": "NOFRAME",
    "gui.blocklyText.pyGame.none": "NONE",
    "gui.blocklyText.pyGame.external": "custom",
    "gui.blocklyText.pyGame.system": "system",
    "gui.blocklyText.pyGame.normal": "normal",
    "gui.blocklyText.pyGame.italics": "Italics",
    "gui.blocklyText.pyGame.bold": "Bold",
    "gui.blocklyText.pyGame.underline": "Underscore",
    "gui.blocklyText.pyGame.wait": "Wait",
    "gui.blocklyText.pyGame.delay": "Delay",
    "gui.blocklyText.pyGame.false": "False",
    "gui.blocklyText.pyGame.true": "True",
    "gui.blocklyText.pyGame.quit": "Quit",
    "gui.blocklyText.pyGame.activeevent": "ACTIVEEVENT",
    "gui.blocklyText.pyGame.keydown": "KEYDOWN",
    "gui.blocklyText.pyGame.keyup": "KEYUP",
    "gui.blocklyText.pyGame.mousemotion": "MOUSEMOTION",
    "gui.blocklyText.pyGame.mousebuttonup": "MOUSEBUTTONUP",
    "gui.blocklyText.pyGame.mousebuttondown": "MOUSEBUTTONDOWN",
    "gui.blocklyText.pyGame.videoresize": "VIDEORESIZE",
    "gui.blocklyText.pyGame.videoexpose": "VIDEOEXPOSE",
    "gui.blocklyText.pyGame.insert": "insert",
    "gui.blocklyText.pyGame.home": "home",
    "gui.blocklyText.pyGame.end": "end",
    "gui.blocklyText.pyGame.pageup": "Page Up",
    "gui.blocklyText.pyGame.pagedown": "Page Down",
    "gui.blocklyText.pyGame.numlock": "Num Lock",
    "gui.blocklyText.pyGame.capslock": "Caps Lock",
    "gui.blocklyText.pyGame.scrollock": "Scrol Lock",
    "gui.blocklyText.pyGame.rightshift": "Right Shift",
    "gui.blocklyText.pyGame.leftshift": "Left Shift",
    "gui.blocklyText.pyGame.rightcontrol": "Right Control",
    "gui.blocklyText.pyGame.leftcontrol": "Left Control",
    "gui.blocklyText.pyGame.rightalt": "Right Alt",
    "gui.blocklyText.pyGame.leftalt": "Left Alt",
    "gui.blocklyText.pyGame.rightmeta": "Right Meta",
    "gui.blocklyText.pyGame.leftmeta": "Left Meta",
    "gui.blocklyText.pyGame.leftWindowskey": "Left Windows",
    "gui.blocklyText.pyGame.rightWindowskey": "Right Windows",
    "gui.blocklyText.pyGame.modeshift": "Mode Shift",
    "gui.blocklyText.pyGame.help": "help",
    "gui.blocklyText.pyGame.printscreen": "printscreen",
    "gui.blocklyText.pyGame.sysrq": "sysrq",
    "gui.blocklyText.pyGame.break": "break",
    "gui.blocklyText.pyGame.menu": "menu",
    "gui.blocklyText.pyGame.power": "power",
    "gui.blocklyText.pyGame.Euro": "Euro",
    "gui.blocklyText.pyGame.delete": "delete",
    "gui.blocklyText.pyGame.backspace": "backspace",
    "gui.blocklyText.pyGame.tab": "tab",
    "gui.blocklyText.pyGame.clear": "clear",
    "gui.blocklyText.pyGame.return": "return",
    "gui.blocklyText.pyGame.pause": "pause",
    "gui.blocklyText.pyGame.escape": "ESC",
    "gui.blocklyText.pyGame.space": "space",
    "gui.blocklyText.pyGame.quotedbl": "quotedbl",
    "gui.blocklyText.pyGame.quote": "quote",
    "gui.blocklyText.pyGame.NONE": "None",
    "gui.blocklyText.pyGame.LSHIFT": "LShift",
    "gui.blocklyText.pyGame.RSHIFT": "RShift",
    "gui.blocklyText.pyGame.SHIFT": "Shift",
    "gui.blocklyText.pyGame.LCTRL": "LCtrl",
    "gui.blocklyText.pyGame.RCTRL": "RCtrl",
    "gui.blocklyText.pyGame.CTRL": "Ctrl",
    "gui.blocklyText.pyGame.LALT": "LAlt",
    "gui.blocklyText.pyGame.RALT": "RAlt",
    "gui.blocklyText.pyGame.ALT": "Alt",
    "gui.blocklyText.pyGame.LMETA": "LMeta",
    "gui.blocklyText.pyGame.RMETA": "RMeta",
    "gui.blocklyText.pyGame.META": "Meta",
    "gui.blocklyText.pyGame.CAPS": "capslock",
    "gui.blocklyText.pyGame.NUM": "numlock",
    "gui.blocklyText.pyGame.MODE": "AltGr",
    "gui.extension.pinpongBase.pinpongInit": "pinpong initialize board [Board] port [Port]",
    "gui.extension.pinpongBase.pinpongUpdateFirmware": "pinpong update firmware board [Board] port [Port]",
    "gui.extension.pinpongBase.pinInit": "[Instance] initialize pin [Pin] mode [Mod]",
    "gui.extension.pinpongBase.readPinValue": "[Instance] read digital value",
    "gui.extension.pinpongBase.setPinValue": "[Instance] set digital output value [Value]",
    "gui.extension.pinpongBase.readAnalogValue": "[Instance] read analog value",
    "gui.extension.pinpongBase.setAnalogValue": "[Instance] set analog output (PWM) value [Value]",
    "gui.blocklyText.pinpongBase.uno": "Arduino UNO",
    "gui.blocklyText.pinpongBase.leonardo": "Arduino Leonardo",
    "gui.blocklyText.pinpongBase.nano": "Arduino Nano",
    "gui.blocklyText.pinpongBase.mega1280": "Arduino Mega1280",
    "gui.blocklyText.pinpongBase.mega2560": "Arduino Mega2560",
    "gui.blocklyText.pinpongBase.microbit": "micro:bit",
    "gui.blocklyText.pinpongBase.handpy": "mPython",
    "gui.blocklyText.pinpongBase.xugu": "xugu",
    "gui.blocklyText.pinpongBase.rpi": "Raspberry Pi",
    "gui.blocklyText.pinpongBase.auto": "auto",
    "gui.blocklyText.pinpongBase.unihiker": "unihiker",
    "gui.blocklyText.pinpongBase.firebeetle_esp32": "Firebeetle ESP32",
    "gui.blocklyText.pinpongBase.firebeetle_esp8266": "Firebeetle ESP8266",
    "gui.blocklyText.pinpongBase.lp": "LattePanda",
    "gui.blocklyText.pinpongBase.esp32": "ESP32",
    "gui.blocklyText.pinpongBase.esp8266": "ESP8266",
    "gui.blocklyText.pinpongBase.nezha": "nezha",
    "gui.blocklyText.pinpongBase.JH7100": "JH7100",
    "gui.blocklyText.pinpongBase.win": "win",
    "gui.blocklyText.pinpongBase.out": "digital output",
    "gui.blocklyText.pinpongBase.in": "digital input",
    "gui.blocklyText.pinpongBase.analog": "analog input",
    "gui.blocklyText.pinpongBase.pwm": "analog output",
    "gui.blocklyText.pinpongBase.null": "none",
    "gui.blocklyText.pinpongServo.name": "servo",
    "gui.extension.pinpongServo.servoInit": "servo [Servo] initialize pin [Pin]",
    "gui.extension.pinpongServo.setServo": "servo [Servo] turns to a position of [Deg] degrees",
    "gui.blocklyText.PinpongBoard.name": "PinPong expanding board",
    "gui.extension.PinpongBoard.connectWifi": "PinPong expansion board connects to hotspot [ssid] password [password] until successful",
    "gui.extension.PinpongBoard.getIp": "get IP address",
    "gui.extension.PinpongBoard.TCPInit": "PinPong board init ip [ip] port 8081",
    "gui.extension.PinpongBoard.USBInit": "PinPong expansion board init USB port [port]",
    "gui.blocklyText.PinpongBoard.ledControl": "LED control",
    "gui.extension.PinpongBoard.LEDInit": "pin initial D2 mode OUTPUT",
    "gui.extension.PinpongBoard.LEDSetValue": "set pin number value [value]",
    "gui.blocklyText.PinpongBoard.buttonControl": "button control",
    "gui.extension.PinpongBoard.buttonInit": "pin initial [PIN] mode INPUT",
    "gui.extension.PinpongBoard.buttonGetVlue": "button get [PIN] value",
    "gui.blocklyText.PinpongBoard.irControl": "ir control",
    "gui.extension.PinpongBoard.irInit": "iRRecv initial pin 3 [Event]",
    "gui.blocklyText.PinpongBoard.toneControl": "tone control",
    "gui.extension.PinpongBoard.toneInit": "initial tone",
    "gui.extension.PinpongBoard.toneSetFreq": "tone set frequency [Freq] Hz",
    "gui.extension.PinpongBoard.toneGetFreq": "tone get freq",
    "gui.extension.PinpongBoard.toneSwitch": "tone [switch]",
    "gui.extension.PinpongBoard.keep": "set tone frequency [Freq] keep [Time]",
    "gui.blocklyText.PinpongBoard.RGBControl": "RGB control",
    "gui.extension.PinpongBoard.RGBInit": "Initialize RGB light",
    "gui.extension.PinpongBoard.setRGB": "set RGB color [Color]",
    "gui.extension.PinpongBoard.setRGBBrightness": "set RGB brightness [BRIGHTNESS]",
    "gui.extension.PinpongBoard.closeRGB": "close RGB color",
    "gui.blocklyText.PinpongBoard.angleSensor": "angle sensor control",
    "gui.extension.PinpongBoard.angleInit": "init angle sensor",
    "gui.extension.PinpongBoard.getAngleValue": "get angle value",
    "gui.blocklyText.PinpongBoard.lightSensor": "light sensor control",
    "gui.extension.PinpongBoard.lightInit": "init light sensor",
    "gui.extension.PinpongBoard.getLightValue": "get light value",
    "gui.blocklyText.PinpongBoard.OLEDscreen": "oled12864 control",
    "gui.extension.PinpongBoard.oled12864Init": "initial oled12864",
    "gui.extension.PinpongBoard.oled12864ShowString": "oled [X] show string [String]",
    "gui.extension.PinpongBoard.oled12864ShowStringXY": "oled X:[X] Y:16*[Y] show string [String]",
    "gui.extension.PinpongBoard.oledCal": "OLED cal data [TEXT] [BIT] bit",
    "gui.extension.PinpongBoard.oled12864Fill": "oled12864 fill [Option]",
    "gui.blocklyText.PinpongBoard.aht20": "aht20 sensor control",
    "gui.extension.PinpongBoard.aht20Init": "initial humiture",
    "gui.extension.PinpongBoard.getTempValue": "get temp value",
    "gui.extension.PinpongBoard.getHumiValue": "get humi value",
    "gui.blocklyText.PinpongBoard.mpu6050": "mpu6050 sensor control",
    "gui.extension.PinpongBoard.mpu6050Init": "mpu6050 initial",
    "gui.extension.PinpongBoard.getAccValue": "get acc [Option] value",
    "gui.extension.PinpongBoard.getGyroValue": "get gyro [Option] value",
    "gui.blocklyText.PinpongBoard.motor": "motor control",
    "gui.extension.PinpongBoard.motorInit": "motor initial",
    "gui.blocklyText.PinpongBoard.black": "black",
    "gui.blocklyText.PinpongBoard.white": "white",
    "gui.blocklyText.PinpongBoard.high": "high level",
    "gui.blocklyText.PinpongBoard.low": "low level",
    "gui.blocklyText.PinpongBoard.on": "on",
    "gui.blocklyText.PinpongBoard.off": "off",
    "gui.blocklyText.PinpongBoard.temp": "temperature",
    "gui.blocklyText.PinpongBoard.humi": "humidity",
    "gui.extension.PinpongBoard.show": "OLED display",
    "gui.extension.PinpongBoard.getIrValue": "get ir value",
    "gui.extension.PinpongBoard.LightsBright": "set brightness [BRIGHTNESS]",
    "gui.extension.PinpongBoard.clear": "set clear",
    "gui.extension.PinpongBoard.RGBShowColorFromWith": "set RGB show color [COLOR]",
    "gui.extension.PinpongBoard.readSHTC3": "read SHTC3 [VALUE] value",
    "gui.extension.PinpongBoard.readPitch": "mpu6050 get pitch value",
    "gui.extension.PinpongBoard.readYaw": "mpu6050 get yaw value",
    "gui.extension.PinpongBoard.readRoll": "mpu6050 get roll value",
    "gui.blocklyText.pinpongIrq.name": "interrupt",
    "gui.extension.pinpongIrq.setIrq": "set pin [Pin]  interrupt [Trigger]  callback function [Handler]",
    "gui.extension.pinpongIrq.INTevent": "when callback function [Event] triggered  parameter [Pin]",
    "gui.extension.pinpongIrq.pinInfo": "pin",
    "gui.blocklyText.pinpongIrq.rising": "rising edge",
    "gui.blocklyText.pinpongIrq.falling": "falling edge",
    "gui.blocklyText.pinpongIrq.change": "level change",
    "gui.blocklyText.pinpongTone.name": "buzzer",
    "gui.extension.pinpongTone.toneInit": "buzzer [Tone]  initialize pin [Pin]",
    "gui.extension.pinpongTone.setToneFreq": "set buzzer [Tone] frequency [Freq]",
    "gui.extension.pinpongTone.readToneFreq": "read buzzer [Tone] frequency",
    "gui.extension.pinpongTone.onTone": "turn on buzzer [Tone]",
    "gui.extension.pinpongTone.offTone": "turn off buzzer [Tone]",
    "gui.extension.pinpongTone.keep": "buzzer [Tone] keep beeping for [Time] ms at frequency of [Freq]",
    "gui.blocklyText.pinpongSr04_urm10.name": "ultrasonic",
    "gui.extension.pinpongSr04_urm10.sr04_urm10Init": "ultrasonic sensor [Sr04_urm10]  initialize trig pin [Triger] echo pin [Echo]",
    "gui.extension.pinpongSr04_urm10.readSr04_urm10": "read ultrasonic sensor [Sr04_urm10] distance(cm)",
    "gui.extension.pinpongDht.dhtInit": "temperature and humidity sensor [Dht] model [DhtMod]  initialize pin [Pin]",
    "gui.extension.pinpongDht.read": "[Dht] read [Type]",
    "gui.extension.pinpongDht.Temperature": "temperature(℃)",
    "gui.extension.pinpongDht.Humidity": "humidity(%rh)",
    "gui.blocklyText.pinpongNeopixel.name": "RGB LED strip",
    "gui.extension.pinpongNeopixel.neopixelInit": "RGB LED [Np]  initialize pin [Pin] number of LED [Num]",
    "gui.extension.pinpongNeopixel.brightness": "RGB LED [Np] set brightness [BRIGHTNESS]",
    "gui.extension.pinpongNeopixel.neopixelClear": "[Np] clear",
    "gui.extension.pinpongNeopixel.setNeopixel": "[Np] set LED [No] to show color [Color]",
    "gui.extension.pinpongNeopixel.showColorFromWith": "[Np] from [NUM1] with [NUM2] leds show color [COLOR]",
    "gui.extension.pinpongNeopixel.color": "red [Red] green [Green] blue [Blue]",
    "gui.extension.pinpongNeopixel.showGradientColor": "[Np] from [NUM1] with [NUM2] leds show rainbow from [COLOR1] to [COLOR2]",
    "gui.extension.pinpongNeopixel.shiftPixelsToShow": "[Np] shift pixels by [NUM]",
    "gui.extension.pinpongNeopixel.rotatePixelsToShow": "[Np] rotate pixels by [NUM]",
    "gui.extension.pinpongNeopixel.showHistogram": "[Np] show histogram from [NUM1] with [NUM2] leds value [VALUE] max [MAX]",
    "gui.extension.pinpongOled12864.oled12864Init": "OLED display [Oled] initialize  width [Width] height:8*[Height]",
    "gui.extension.pinpongOled12864.oled12864Fill": "[Oled] full screen [Option]",
    "gui.extension.pinpongOled12864.oled12864ShowString": "[Oled] show text [String] at x [X] y [Y]",
    "gui.extension.pinpongOled12864.show": "[Oled] display settings take effect",
    "gui.blocklyText.pinpongOled12864.display": "fill",
    "gui.blocklyText.pinpongOled12864.hide": "hide",
    "gui.extension.pinpongDS18B20.ds18b20Init": "temperature sensor [Ds18b20]  initialize pin [Pin]",
    "gui.extension.pinpongDS18B20.readCelsius": "read [Ds18b20] temperature (°C)",
    "gui.extension.pinpongMlx90614.mlx90614Init": "MLX90614 init [IRT] I2C addr [ADDR]",
    "gui.extension.pinpongMlx90614.read": "mlx90614 [OBJ] read [TYPE] [UINT]",
    "gui.extension.pinpongBmp388.BMP388Init": "BMP388 [BMP] init I2C address 0x77",
    "gui.extension.pinpongBmp388.read": "BMP388[BMP]read[TYPE]",
    "gui.blocklyText.pinpongBmp388.altitude": "altitude(m)",
    "gui.blocklyText.pinpongBmp388.pressure": "pressure(Pa)",
    "gui.blocklyText.pinpongBmp388.temperature": "temperature(℃)",
    "gui.blocklyText.pinpongIRRecv.name": "IR receiver",
    "gui.extension.pinpongIRRecv.iRRecvInit": "IR receiver [IRRecv] initialize pin [Pin] callback function [Event]",
    "gui.extension.pinpongIRRecv.onReceive": "when callback function [Event] received [Data]",
    "gui.extension.pinpongIRRecv.data": "infrared signal",
    "gui.blocklyText.obloqMp.WhenReceivedData": "when [MSG] of [EventType] received",
    "gui.blocklyText.obloqMp.ObloqEventType": "MQTT event Types",
    "gui.blocklyText.obloqMp.ObloqMsg": "MQTT message",
    "gui.blocklyText.obloqMp.initialParameter": "obloq mqtt initialization: [PARAMETER] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloqMp.initialI2CParameter": "Obloq mqtt initial parameter [PARAMETER] interface I2C callback function [Event]",
    "gui.blocklyText.obloqMp.subscribe": "Obloq mqtt subscribe [TOPIC]",
    "gui.blocklyText.obloqMp.sendmsgToCloudPlatform": "Obloq send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.obloqMp.whenDebugInfo": "Obloq get [DEBUG] [ERROR]",
    "gui.blocklyText.obloqMp.DEBUG": "Debug info",
    "gui.blocklyText.obloqMp.ERROR": "Error code",
    "gui.blocklyText.obloqMp.httpInitialParameter": "Obloq http initialization: [PARAMETER] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloqMp.httpInitialI2CParameter": "Obloq http initial parameter [PARAMETER] interface I2C",
    "gui.blocklyText.obloqMp.httpGet": "Obloq http(get) url: [URL] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.obloqMp.getWiFiLocalIP": "IP address",
    "gui.blocklyText.obloqMp.wifi": "Wi-Fi",
    "gui.blocklyText.obloqMp.SSID": "Wi-Fi name",
    "gui.blocklyText.obloqMp.PWD": "Wi-Fi password",
    "gui.blocklyText.obloqMp.iot": "Platform",
    "gui.blocklyText.obloqMp.EasyIOT_Server": "EasyIot",
    "gui.blocklyText.obloqMp.EasyIOTServerChina": "China",
    "gui.blocklyText.obloqMp.EasyIOTServerGlobal": "Global",
    "gui.blocklyText.obloqMp.Port": "Port",
    "gui.blocklyText.obloqMp.Username": "Username",
    "gui.blocklyText.obloqMp.Password": "Password",
    "gui.blocklyText.obloqMp.siotServer": "SiotIot",
    "gui.blocklyText.serialPort.whenReceivedData": "When a serial port message is received [MESSAGE] [VALUE]",
    "gui.blocklyText.serialPort.value": "Value",
    "gui.blocklyText.serialPort.readSerialData": "Read the value of [MESSAGE]",
    "gui.blocklyText.serialPort.serialPortSendMessage": "Sending serial port messages [MESSAGE] value [VALUE]",
    "gui.extension.pyAutoGui.pyAutoGuiModule": "pyautogui quick setup",
    "gui.extension.pyAutoGui.init": "initialize pyautogui",
    "gui.extension.pyAutoGui.getPosition": "get mouse coordinates ([X], [Y])",
    "gui.extension.pyAutoGui.getSize": "get screen resolution ([Width], [Height])",
    "gui.extension.pyAutoGui.height": "height",
    "gui.extension.pyAutoGui.width": "width",
    "gui.extension.pyAutoGui.onScreen": "if coordinates ([X],[Y]) on screen",
    "gui.extension.pyAutoGui.setDelay": "PyAutoGUI call [Delay]",
    "gui.extension.pyAutoGui.setFailSafe": "[Switch] failsafe mode",
    "gui.extension.pyAutoGui.mouse": "mouse function area",
    "gui.extension.pyAutoGui.mouseMoveToOrRel": "move mouse to [Attribute] ([X], [Y])  delay [Seconds] seconds  set [Style]",
    "gui.extension.pyAutoGui.mouseDragToOrRel": "move mouse to [Attribute] ([X], [Y])  delay [Seconds]  click [Key]",
    "gui.extension.pyAutoGui.mouseClick": "move mouse to ([X], [Y])  click [Key] [Frequency] times at interval [Seconds] seconds",
    "gui.extension.pyAutoGui.mouseDownOrUp": "move mouse to ([X],[Y])  [Action] [Key]",
    "gui.extension.pyAutoGui.mouseScroll": "mouse scroll [NUM]",
    "gui.extension.pyAutoGui.keyboard": "keyboard function area",
    "gui.extension.pyAutoGui.keyboardWrite": "Keyboard output [String] in every [NUM] seconds",
    "gui.extension.pyAutoGui.keyboardDownOrUp": "keyboard click [Key] for [NUM] times",
    "gui.extension.pyAutoGui.keyboardPress": "[Button][Key]",
    "gui.extension.pyAutoGui.keyboardHold": "hold down [Key]",
    "gui.extension.pyAutoGui.keyboardHotkey": "press shortcut (combination) [Key]",
    "gui.extension.pyAutoGui.message": "message box function area",
    "gui.extension.pyAutoGui.messageSetting": "display message box [SETTING]",
    "gui.extension.pyAutoGui.image": "image function area",
    "gui.extension.pyAutoGui.cbImg": "save screen [Pos] image data as [Screen] and return object",
    "gui.extension.pyAutoGui.getRgb": "get RGB of [Obj] ([X], [Y])",
    "gui.extension.pyAutoGui.getPixel": "get RGB of screen ([X], [Y])",
    "gui.extension.pyAutoGui.pixelMatchesColor": "if RGB on screen ([X], [Y]) match [Rgb] with range +-[Tolerance]",
    "gui.extension.pyAutoGui.locateOnScreen": "get coordinates of [Image] [Setting] on screen  grayscale match[Grayscale]",
    "gui.extension.pyAutoGui.locateOn": "get coordinates of get [Image] [Setting] on specific [Screen]  grayscale match[Grayscale]",
    "gui.extension.pyAutoGui.imgCenter": "get [Image] center coordinates",
    "gui.blocklyText.pyAutoGui.messageSetting": "display message box [SETTING]",
    "gui.blocklyText.pyAutoGui.platform": "message box type",
    "gui.blocklyText.pyAutoGui.simpleMessageBox": "simple message box",
    "gui.blocklyText.pyAutoGui.messageBox": "message box",
    "gui.blocklyText.pyAutoGui.inputMessageBox": "input-type message box",
    "gui.blocklyText.pyAutoGui.inputPasswordMessageBox": "password message box",
    "gui.blocklyText.pyAutoGui.title": "title",
    "gui.blocklyText.pyAutoGui.text": "text",
    "gui.blocklyText.pyAutoGui.okButton": "confirm button name",
    "gui.blocklyText.pyAutoGui.cancelButton": "cancel button name",
    "gui.blocklyText.pyAutoGui.inputDefault": "message box default value",
    "gui.blocklyText.pyAutoGui.encrypted": "encrypted",
    "gui.blocklyText.pyAutoGui.false": "disable",
    "gui.blocklyText.pyAutoGui.true": "enable",
    "gui.blocklyText.pyAutoGui.None": "none",
    "gui.blocklyText.pyAutoGui.mouseLeft": "left button",
    "gui.blocklyText.pyAutoGui.mouseMiddle": "middle button",
    "gui.blocklyText.pyAutoGui.mouseRight": "right button",
    "gui.blocklyText.pyAutoGui.INQUAD": "start slowly",
    "gui.blocklyText.pyAutoGui.OUTQUAD": "stop slowly",
    "gui.blocklyText.pyAutoGui.INOUTQUAD": "start and stop slowly",
    "gui.blocklyText.pyAutoGui.INBOUNCE": "narrow elastic band",
    "gui.blocklyText.pyAutoGui.OUTELASTIC": "wide elastic band",
    "gui.blocklyText.pyAutoGui.ActionDown": "pressed",
    "gui.blocklyText.pyAutoGui.ActionUp": "released",
    "gui.blocklyText.pyAutoGui.DirDown": "down",
    "gui.blocklyText.pyAutoGui.DirUp": "up",
    "gui.blocklyText.pyAutoGui.ABS": "absolute position",
    "gui.blocklyText.pyAutoGui.REL": "relative position",
    "gui.extension.pyRequests.request": "request",
    "gui.extension.pyRequests.requestGet": "make a get request [Url]",
    "gui.extension.pyRequests.requestPost": "make a [Data] post request [Url]",
    "gui.extension.pyRequests.getStatusCode": "get [ReqObj] request code",
    "gui.extension.pyRequests.raiseForStatus": "check if [ReqObj] request succeed",
    "gui.extension.pyRequests.apparentEncoding": "get [ReqObj] real code",
    "gui.extension.pyRequests.setEncoding": "set [ReqObj] code to [Code]",
    "gui.extension.pyRequests.handleHTML": "parse [ReqObj] request content as [Option]",
    "gui.extension.pyRequests.handleHeader": "get [ReqObj] head",
    "gui.extension.pyRequests.getValue": "get value of [Key] in [Item]",
    "gui.blocklyText.pyRequests.text": "text format",
    "gui.blocklyText.pyRequests.json": "JSON format",
    "gui.blocklyText.pyRequests.content": "binary format",
    "gui.extension.pyJson.dumpName": "encode",
    "gui.extension.pyJson.jsonDump": "encode Json data [Data] and save into [FileObj] ASCII escape[Switch]",
    "gui.extension.pyJson.jsonDumps": "encode Json data [Data] into string  ASCII escape[Switch]",
    "gui.extension.pyJson.loadName": "decode",
    "gui.extension.pyJson.jsonload": "decode Json file object [FileObj] into Python object [PyObj]",
    "gui.extension.pyJson.jsonloads": "decode Json data [Data] into Python object [PyObj]",
    "gui.extension.pyJson.valueName": "value",
    "gui.extension.pyJson.get": "get value of [PyObj] [Key]",
    "gui.extension.pyJson.set": "set value of [PyObj] [Key] to [Value]",
    "gui.extension.pySchedule.createJobTitle": "create task",
    "gui.extension.pySchedule.createJob": "create task [JobName] that runs once every [Num] [TimeType]",
    "gui.extension.pySchedule.createOneJob": "create task [JobName] that runs once every [WEEKTYPES]",
    "gui.extension.pySchedule.createRandomJob": "create task [JobName] that runs once randomly every [Num]-[LastNum] [TimeType]",
    "gui.extension.pySchedule.setJobExactTime": "set start time [Time] of task [JobName]",
    "gui.extension.pySchedule.setJobTags": "set [Tags] of task [JobName]",
    "gui.extension.pySchedule.setJobUntil": "set end time [Until] of task  [JobName]",
    "gui.extension.pySchedule.setJobFunction": "set task [JobName] running method [Function] (arguements [Args])",
    "gui.extension.pySchedule.taskManageTitle": "task management",
    "gui.extension.pySchedule.cancelJob": "cancel task [JobName]",
    "gui.extension.pySchedule.getAllJob": "get all tasks that meet condition [Tags]",
    "gui.extension.pySchedule.cancelAllJob": "cancel all tasks that meet condition [Tag]",
    "gui.extension.pySchedule.getNextJobTime": "get running time(s) of next task",
    "gui.extension.pySchedule.runAllJob": "run all tasks",
    "gui.extension.pySchedule.runAllJobToo": "get results of all tasks, set task interval to [Num] s",
    "gui.blocklyText.pySchedule.WEEKS": "week",
    "gui.blocklyText.pySchedule.DAYS": "day",
    "gui.blocklyText.pySchedule.HOURS": "hour",
    "gui.blocklyText.pySchedule.MINUTES": "minute",
    "gui.blocklyText.pySchedule.SECONDS": "second",
    "gui.blocklyText.pySchedule.monday": "monday",
    "gui.blocklyText.pySchedule.tuesday": "tuesday",
    "gui.blocklyText.pySchedule.wednesday": "wednesday",
    "gui.blocklyText.pySchedule.thursday": "thursday",
    "gui.blocklyText.pySchedule.friday": "friday",
    "gui.blocklyText.pySchedule.saturday": "saturday",
    "gui.blocklyText.pySchedule.sunday": "sunday",
    "gui.extension.pyXlrd.init": "Read File",
    "gui.extension.pyXlrd.openWorkbook": "open Excel workbook [File] as [FileObj]",
    "gui.extension.pyXlrd.tableTitle": "Worksheet",
    "gui.extension.pyXlrd.getSheets": "get all worksheets of [FileObj]",
    "gui.extension.pyXlrd.getSheetsName": "get all worksheet names of [FileObj]",
    "gui.extension.pyXlrd.selectSheet": "get [Screen] of [FileObj] as worksheet object [TableObj] of [Value]",
    "gui.extension.pyXlrd.rowTitle": "Row",
    "gui.extension.pyXlrd.getRowNum": "get number of rows in [TableObj]",
    "gui.extension.pyXlrd.getRow": "get information in row [Num] from column [StartNum] to [EndNum] of [TableObj]",
    "gui.extension.pyXlrd.getGenerator": "return generator for iterating through each row in [TableObj]",
    "gui.extension.pyXlrd.getRowType": "get data type in row [Num] from column [StartNum] to [EndNum] of [TableObj]",
    "gui.extension.pyXlrd.getRowValue": "get data value in row [Num] from column [StartNum] to [EndNum] of [TableObj]",
    "gui.extension.pyXlrd.getRowLen": "get number of columns in row [Num] of [TableObj]",
    "gui.extension.pyXlrd.colTitle": "Column",
    "gui.extension.pyXlrd.getColNum": "get number of columns in [TableObj]",
    "gui.extension.pyXlrd.getCol": "get information in column [Num] from row [StartNum] to [EndNum] of [TableObj]",
    "gui.extension.pyXlrd.getColType": "get data type in column [Num] from row [StartNum] to [EndNum] of [TableObj]",
    "gui.extension.pyXlrd.getColValue": "get data value in column [Num] from row [StartNum] to [EndNum] of [TableObj]",
    "gui.extension.pyXlrd.cellTitle": "Cell",
    "gui.extension.pyXlrd.getCell": "get cell object [CellObj] in row [RowNum] and column [ColNum] of [TableObj]",
    "gui.extension.pyXlrd.getCellType": "get cell type in row [RowNum] and column [ColNum] of [TableObj]",
    "gui.extension.pyXlrd.getCellValue": "get cell value in row [RowNum] and column [ColNum] of [TableObj]",
    "gui.extension.pyXlrd.optionIndex": "index",
    "gui.extension.pyXlrd.optionName": "name",
    "gui.extension.pyXlwt.init": "Create File",
    "gui.extension.pyXlwt.createWorkbook": "create Excel file [FileObj] and set file encode [Encoding]",
    "gui.extension.pyXlwt.addSheet": "add worksheet [SheetName] to file [FileObj], overlay [Bool] and return [SheetObj]",
    "gui.extension.pyXlwt.saveWorkbook": "save Excel file [FileObj] to [Path]",
    "gui.extension.pyXlwt.editTable": "Edit Excel Worksheet",
    "gui.extension.pyXlwt.createFontStyle": "create font style [FontObj]",
    "gui.extension.pyXlwt.setFontBaseStyle": "set font [FontObj] to common style [Style][Value]",
    "gui.extension.pyXlwt.setFontSpecialStyle": "set font [FontObj] to special style [Style]",
    "gui.extension.pyXlwt.createBorderStyle": "create border style [BorderObj]",
    "gui.extension.pyXlwt.setBorderBaseStyle": "set border [BorderObj] to common style [Style] [Value]",
    "gui.extension.pyXlwt.createAlignmentStyle": "create alignment style [AlignmentObj]",
    "gui.extension.pyXlwt.setAlignmentHorzStyle": "set alignment style [AlignmentObj] to horizontal [HorzStyle]",
    "gui.extension.pyXlwt.setAlignmentVertStyle": "set alignment style [AlignmentObj] to vertical [VertStyle]",
    "gui.extension.pyXlwt.setAlignmentWrap": "set alignment style [AlignmentObj] and word wrap [Bool]",
    "gui.extension.pyXlwt.createStyle": "create total style [StyleObj]",
    "gui.extension.pyXlwt.setStyle": "set [Type] [Value] of total style [StyleObj]",
    "gui.extension.pyXlwt.easyStyle": "create simple style [StyleObj]  style [StyleString]  data format [NumFormat]",
    "gui.extension.pyXlwt.eadit": "write data [Label] into cell in row [Row] and column [Col] of worksheet [SheetObj] with style [Style]",
    "gui.blocklyText.pyXlwt.font": "font style",
    "gui.blocklyText.pyXlwt.border": "border style",
    "gui.blocklyText.pyXlwt.alignment": "alignment style",
    "gui.blocklyText.pyXlwt.alignLeft": "align left",
    "gui.blocklyText.pyXlwt.alignRight": "align right",
    "gui.blocklyText.pyXlwt.alignTop": "align top",
    "gui.blocklyText.pyXlwt.centerAlign": "align middle",
    "gui.blocklyText.pyXlwt.alignBotton": "align bottom",
    "gui.blocklyText.pyXlwt.top": "top border",
    "gui.blocklyText.pyXlwt.botton": "bottom border",
    "gui.blocklyText.pyXlwt.left": "left border",
    "gui.blocklyText.pyXlwt.right": "right border",
    "gui.blocklyText.pyXlwt.name": "font name",
    "gui.blocklyText.pyXlwt.height": "font size",
    "gui.blocklyText.pyXlwt.color_index": "font color index",
    "gui.blocklyText.pyXlwt.bold": "bold",
    "gui.blocklyText.pyXlwt.underline": "underline",
    "gui.blocklyText.pyXlwt.italic": "italic",
    "gui.extension.pyOpenCV.base": "basic operations",
    "gui.extension.pyOpenCV.imread": "read image file [FileName]  set [Option] and return [ImgObj]",
    "gui.extension.pyOpenCV.imwrite": "save [ImgObj] to [FileName]",
    "gui.extension.pyOpenCV.imdecode": "read data in [Buff] in [Option] mode Return image [ImgObj]",
    "gui.extension.pyOpenCV.imencode": "write [Ext] image [ImgObj] into buffer [Buff] and return result [Ret]",
    "gui.extension.pyOpenCV.windowShow": "window display",
    "gui.extension.pyOpenCV.imshow": "display image [ImgObj] to Window [WinName]",
    "gui.extension.pyOpenCV.fullWindow": "the settings window [WinName] is displayed in full screen",
    "gui.extension.pyOpenCV.namedWindow": "create a window named [WinName]",
    "gui.extension.pyOpenCV.destroyWindow": "destroy a window named [WinName]",
    "gui.extension.pyOpenCV.destroyAllWindows": "destroy all windows",
    "gui.extension.pyOpenCV.moveWindow": "move a window named [WinName] to position ([X], [Y])",
    "gui.extension.pyOpenCV.resizeWindow": "resize a window named [WinName] to ([Width], [Height])",
    "gui.extension.pyOpenCV.resizeImg": "resize the image [ImgObj] to width[Width] height [Height] output [OutImg]",
    "gui.extension.pyOpenCV.intercept": "capture images from [Start] to [End][Type] in the image [ImgObj] Output [OutImg] ",
    "gui.extension.pyOpenCV.copyImg": "copy the image [ImgObj] to a new object [CopyImg]",
    "gui.extension.pyOpenCV.rotate": "drag the image [ImgObj][RotateType]",
    "gui.extension.pyOpenCV.waitKey": "wait for a pressed key [Key] for [Second] ms",
    "gui.extension.pyOpenCV.key": "key [Key]",
    "gui.extension.pyOpenCV.selectROIs": "create a mouse screenshot of the image [ImgObj], the form [WindowsName] [SwitchCenter] center rectangle [SwitchCrosshair] cross line Output to coordinates [OutImg]",
    "gui.extension.pyOpenCV.imgAttr": "image properties",
    "gui.extension.pyOpenCV.imgShape": "get number of rows, columns, and channels of image [ImgObj]",
    "gui.extension.pyOpenCV.imgSize": "get size of image [ImgObj](total number of pixels)",
    "gui.extension.pyOpenCV.imgDtype": "get datatype of image [ImgObj]",
    "gui.extension.pyOpenCV.copyMakeBorder": "add a border with width of top [TopNum] bottom [BottomNum] left [LeftNum] right [RightNum] with [BorderType] on the image [ImgObj] Output [OutImg]",
    "gui.extension.pyOpenCV.video": "video operation",
    "gui.extension.pyOpenCV.VideoCapture": "create VideoCapture object [VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureOpen": "use VideoCapture object [VideoObj] to open video file/device [VideoId]",
    "gui.extension.pyOpenCV.SetVideoCaptureSize": "set the VideoCapture object [VideoObj] capture resolution to [Width] × [Height]",
    "gui.extension.pyOpenCV.VideoCaptureOpenVideo": "open a video file using the VideoCapture object [VideoObj] [Video]",
    "gui.extension.pyOpenCV.VideoCaptureIsOpened": "if VideoCapture object [VideoObj] has been initialized already",
    "gui.extension.pyOpenCV.VideoCaptureRelease": "close VideoCapture object [VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureGrab": "if there is next frame in VideoCapture object [VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureRead": "grab next frame [VideoFrame] from VideoCapture object [VideoObj] and status [VideoRet]",
    "gui.extension.pyOpenCV.VideoCaptureGet": "get value for property [Properties] of VideoCapture object [VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureSet": "set value for property [Properties] of VideoCapture object [VideoObj] to [Value]",
    "gui.extension.pyOpenCV.imgConvert": "image conversion",
    "gui.extension.pyOpenCV.adaptiveThreshold": "process image [ImgObj] with [Method], max value [MaxNum], threshold type [Type], neighbourhood area size [Size], and constant [Constant], output [OutImg]",
    "gui.extension.pyOpenCV.cvtColor": "convert image [ImgObj] to [OutImg] by [Flags]",
    "gui.extension.pyOpenCV.distanceTransform": "Calculate the [distanceType] mask of each non-zero pixel in the image [ImgObj] to the nearest zero pixel [maskSize] Output [Distance]",
    "gui.extension.pyOpenCV.threshold": "process image [ImgObj] with threshold [ThreshValue], max value [MaxNum], threshold type [ThresholdTypes], output processed [Ret] and image [OutImg]",
    "gui.extension.pyOpenCV.ObjectDetection": "object detection",
    "gui.extension.pyOpenCV.CascadeClassifier": "create a cascade classifier [CascadeClassifierObj]",
    "gui.extension.pyOpenCV.CascadeClassifierLoad": "load cascade classifier [CascadeClassifierObj] from file [FileName]",
    "gui.extension.pyOpenCV.CascadeClassifierEmpty": "if cascade classifier [CascadeClassifierObj] has been loaded already",
    "gui.extension.pyOpenCV.detectMultiScale": "cascade classifier [CascadeClassifierObj] detect objects of different sizes in image [ImgObj] with [Method], scale [ScaleFactor], minNeighbors [MinNeighbors], return method 3 and output [OutputRejectLevels]",
    "gui.extension.pyOpenCV.Draws": "drawing tool",
    "gui.extension.pyOpenCV.putText": "draw text [Text] on the image [ImgObj] Bottom left corner coordinates X [PositionX] Y[PositionY] Color [Color] Font size [FontSize] Line width [Thickness]",
    "gui.extension.pyOpenCV.arrowedLine": "draw an arrow between [StartP] to [EndP] points with color [Color], thickness [Thickness], type [LineType] on image [ImgObj]",
    "gui.extension.pyOpenCV.circle": "draw a circle with center [CenterP], radius [Radius], color [Color], thickness [Thickness], type [LineType] on image [ImgObj]",
    "gui.extension.pyOpenCV.clipLine": "clip a line between [StartP] to [EndP] points on rectangle of size [ImgSize] and return if it is within points [P1] to [P2] of rectangle [Ret]",
    "gui.extension.pyOpenCV.drawMarker": "draw a maker on position [P] with color [Color], marker type [MarkerType], thickness [Thickness], size [MarkerSize], line type [LineType] on image [ImgObj]",
    "gui.extension.pyOpenCV.ellipse": "draw an ellipse with center [CenterP], axes [Axes], angle [Angle], startAngle [StartAngle], endAngle [EndAngle], color [Color], thickness [Thickness], type [LineType] on image [ImgObj]",
    "gui.extension.pyOpenCV.line": "draw a line between [StartP] to [EndP] points with color [Color], thickness [Thickness], type [LineType] on image [ImgObj]",
    "gui.extension.pyOpenCV.rectangle": "draw a rectangle with top left vertex [TopP], bottom right vertex[BottomP], color [Color], thickness [Thickness], type [LineType] on image [ImgObj]",
    "gui.extension.pyOpenCV.gesture": "The gesture in the picture [Img] is [Gesture]?",
    "gui.extension.pyOpenCV.gestureByVideo": "The gesture in the camera is [Gesture]?",
    "gui.extension.pyOpenCV.gaussian": "Gaussian blur smoothing is performed on the image [ImgObj] and output as image [OutImg]",
    "gui.extension.pyOpenCV.canny": "Perform edge detection on the image [ImgObj], threshold 1 [ThreshValue1], threshold 2 [ThreshValue2], and output edge binarization image [OutImg]",
    "gui.extension.pyOpenCV.contours": "Find the contour point in the picture [ImgObj], [Mode] mode, [Method] method, and return the result to the sequence [Contours]",
    "gui.extension.pyOpenCV.drawContours": "Draw contour lines [Contours] with color [Color] and thickness [Thickness] on the image [ImgObj] to output an image with contours [OutImg]",
    "gui.blocklyText.pyOpenCV.retr_external": "outer contour",
    "gui.blocklyText.pyOpenCV.retr_list": "no rank",
    "gui.blocklyText.pyOpenCV.retr_ccomp": "two-level tree",
    "gui.blocklyText.pyOpenCV.retr_tree": "a level tree",
    "gui.blocklyText.pyOpenCV.chain_approx_none": "all contour points",
    "gui.blocklyText.pyOpenCV.chain_approx_simple": "end coordinates only",
    "gui.blocklyText.pyOpenCV.chain_approx_tc89_l1": "approximation algorithm",
    "gui.blocklyText.pyOpenCV.imread_unchanged": "original image",
    "gui.blocklyText.pyOpenCV.imread_grayscale": "grayscale image",
    "gui.blocklyText.pyOpenCV.imread_color": "BGR image",
    "gui.blocklyText.pyOpenCV.border_replicate": "replicate",
    "gui.blocklyText.pyOpenCV.border_reflect": "reflect",
    "gui.blocklyText.pyOpenCV.border_reflect101": "reflect 101",
    "gui.blocklyText.pyOpenCV.border_wrap": "wrap",
    "gui.blocklyText.pyOpenCV.border_constant": "constant",
    "gui.blocklyText.pyOpenCV.column": "column",
    "gui.blocklyText.pyOpenCV.row": "row",
    "gui.blocklyText.pyOpenCV.rotate_90_clockwise": "turn 90 degrees clockwise",
    "gui.blocklyText.pyOpenCV.rotate_180": "rotate 180 degrees",
    "gui.blocklyText.pyOpenCV.rotate_90_counterclockwise": "rotate 90 degrees counterclockwise",
    "gui.blocklyText.pyOpenCV.cap_prop_buffersize": "image buffer size",
    "gui.blocklyText.pyOpenCV.cap_prop_pos_msec": "current position of video",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_width": "width",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_height": "height",
    "gui.blocklyText.pyOpenCV.cap_prop_fps": "frame rate",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_count": "number of frames",
    "gui.blocklyText.pyOpenCV.adaptive_thresh_mean_c": "average",
    "gui.blocklyText.pyOpenCV.adaptive_thresh_gaussian_c": "Gaussian Blurring",
    "gui.blocklyText.pyOpenCV.thresh_binary": "set to maximum when exceeding threshold, otherwise, take 0",
    "gui.blocklyText.pyOpenCV.thresh_binary_inv": "set to 0 when exceeding threshold, otherwise, take maximum",
    "gui.blocklyText.pyOpenCV.thresh_trunc": "set to critical point when exceeding threshold, otherwise, unchanged",
    "gui.blocklyText.pyOpenCV.thresh_tozero": "keep unchanged when exceeding threshold, otherwise, set to 0",
    "gui.blocklyText.pyOpenCV.thresh_tozero_inv": "set to 0 when exceeding threshold, otherwise, unchanged",
    "gui.blocklyText.pyOpenCV.color_bgr2bgra": "add Alpha channel to BGR image",
    "gui.blocklyText.pyOpenCV.color_rgb2rgba": "add Alpha channel to RGB image",
    "gui.blocklyText.pyOpenCV.color_bgra2bgr": "remove Alpha channel from BGR image",
    "gui.blocklyText.pyOpenCV.color_rgba2rgb": "remove Alpha channel from RGB image",
    "gui.blocklyText.pyOpenCV.color_bgr2rgba": "convert BGR to RGB",
    "gui.blocklyText.pyOpenCV.color_rgb2bgra": "convert RGB to BGR",
    "gui.blocklyText.pyOpenCV.color_bgr2gray": "convert between BGR and grayscale",
    "gui.blocklyText.pyOpenCV.color_rgb2gray": "convert between RGB and grayscale",
    "gui.blocklyText.pyOpenCV.color_gray2bgr": "convert from grayscale space to BGR",
    "gui.blocklyText.pyOpenCV.color_bgr2hsv": "converts to and from the BGR and HSV color spaces",
    "gui.blocklyText.pyOpenCV.color_rgb2hsv": "converts to and from the RGB and HSV color spaces",
    "gui.blocklyText.pyOpenCV.dist_l1": "Manhattan distance",
    "gui.blocklyText.pyOpenCV.dist_l2": "simple Euclidean distance",
    "gui.blocklyText.pyOpenCV.dist_c": "Chebyshev distance",
    "gui.blocklyText.pyOpenCV.detectmultiscale": "common detection methods",
    "gui.blocklyText.pyOpenCV.detectmultiscale2": "detection method 2",
    "gui.blocklyText.pyOpenCV.detectmultiscale3": "detection method 3",
    "gui.blocklyText.pyOpenCV.filled": "filled line",
    "gui.blocklyText.pyOpenCV.line_4": "4-connected line",
    "gui.blocklyText.pyOpenCV.line_8": "8-connected line",
    "gui.blocklyText.pyOpenCV.line_aa": "antialiased line",
    "gui.blocklyText.pyOpenCV.marker_cross": " crosshair marker",
    "gui.blocklyText.pyOpenCV.marker_tilted_cross": " 45 degree tilted crosshair marker",
    "gui.blocklyText.pyOpenCV.marker_star": " star marker",
    "gui.blocklyText.pyOpenCV.marker_diamond": " diamond marker",
    "gui.blocklyText.pyOpenCV.marker_square": " square marker",
    "gui.blocklyText.pyOpenCV.marker_triangle_up": " upward pointing triangle marker",
    "gui.blocklyText.pyOpenCV.marker_triangle_down": " downward pointing triangle marker",
    "gui.blocklyText.pyOpenCV.haarcascade_eye": "eye",
    "gui.blocklyText.pyOpenCV.haarcascade_eye_tree_eyeglasses": "eye tree eyeglasses",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalcatface": "cat face",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalcatface_extended": "cat face extended",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt": "face alt",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt_tree": "face alt_tree",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt2": "face alt2",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_default": "face default",
    "gui.blocklyText.pyOpenCV.haarcascade_fullbody": "full body",
    "gui.blocklyText.pyOpenCV.haarcascade_lefteye_2splits": "left eye",
    "gui.blocklyText.pyOpenCV.haarcascade_licence_plate_rus_16stages": "licence",
    "gui.blocklyText.pyOpenCV.haarcascade_lowerbody": "lower body",
    "gui.blocklyText.pyOpenCV.haarcascade_profileface": "profile face",
    "gui.blocklyText.pyOpenCV.haarcascade_righteye_2splits": "right eye",
    "gui.blocklyText.pyOpenCV.haarcascade_russian_plate_number": "russian plate number",
    "gui.blocklyText.pyOpenCV.haarcascade_smile": "mouth",
    "gui.blocklyText.pyOpenCV.haarcascade_upperbody": "upper body",
    "gui.blocklyText.pyOpenCV.gesture1": "gesture1",
    "gui.blocklyText.pyOpenCV.gesture2": "gesture2",
    "gui.blocklyText.pyOpenCV.gesture3": "gesture3",
    "gui.blocklyText.pyOpenCV.gesture4": "gesture4",
    "gui.blocklyText.pyOpenCV.gesture5": "gesture5",
    "gui.blocklyText.unihiker.updateParamsMenu_width": "width",
    "gui.blocklyText.unihiker.updateParamsMenu_height": "High",
    "gui.blocklyText.unihiker.updateParamsMenu_radial": "Radius",
    "gui.blocklyText.unihiker.updateParamsMenu_linewidth": "Linewidth",
    "gui.blocklyText.unihiker.updateParamsMenu_start": "Start point",
    "gui.blocklyText.unihiker.updateParamsMenu_end": "End point",
    "gui.blocklyText.unihiker.standardPointMenu_center": "Center",
    "gui.blocklyText.unihiker.standardPointMenu_top": "top",
    "gui.blocklyText.unihiker.standardPointMenu_bottom": "bottom",
    "gui.blocklyText.unihiker.standardPointMenu_left": "left",
    "gui.blocklyText.unihiker.standardPointMenu_right": "Right",
    "gui.blocklyText.unihiker.standardPointMenu_topleft": "Top left",
    "gui.blocklyText.unihiker.standardPointMenu_topright": "Top right",
    "gui.blocklyText.unihiker.standardPointMenu_bottomleft": "Bottom Left",
    "gui.blocklyText.unihiker.standardPointMenu_bottomright": "Bottom Right",
    "gui.blocklyText.unihiker.GESTURE_gesture1": "gesture1",
    "gui.blocklyText.unihiker.GESTURE_gesture2": "gesture2",
    "gui.blocklyText.unihiker.GESTURE_gesture3": "gesture3",
    "gui.blocklyText.unihiker.GESTURE_gesture4": "gesture4",
    "gui.blocklyText.unihiker.GESTURE_gesture5": "gesture5",
    "gui.blocklyText.unihiker.xyzsMenu_S": "Strength",
    "gui.blocklyText.unihiker.expressionMenu_Angry": "Angry",
    "gui.blocklyText.unihiker.expressionMenu_Nerve": "Nerve",
    "gui.blocklyText.unihiker.expressionMenu_Peace": "Peace",
    "gui.blocklyText.unihiker.expressionMenu_Shock": "surprised",
    "gui.blocklyText.unihiker.expressionMenu_Sleep": "Sleep",
    "gui.blocklyText.unihiker.expressionMenu_Smile": "Smile",
    "gui.blocklyText.unihiker.expressionMenu_Sweat": "Sweat",
    "gui.blocklyText.unihiker.expressionMenu_Think": "Thinking",
    "gui.blocklyText.unihiker.expressionMenu_Wink": "Wink",
    "gui.blocklyText.unihiker.unihikerPinMenu_button": "Key",
    "gui.blocklyText.unihiker.blockTitle.screenDisplay": "Screen Display",
    "gui.blocklyText.unihiker.showText": "Object name [NAME] shows text [TEXT] at X[X] Y[Y] font size [FONTSIZE] color [COLOR]",
    "gui.blocklyText.unihiker.showTextWithDigitalTubeStyle": "The object name [NAME] shows the imitation digital tube font [TEXT] in: X[X] Y[Y] font size [FONTSIZE] color [COLOR]",
    "gui.blocklyText.unihiker.showImage": "Object name [NAME] shows image [IMG] at X[X] Y[Y]",
    "gui.blocklyText.unihiker.showInsetLiveExpression": "Object name [NAME] shows built-in dynamic expression [EXPRESSION] at X[X] Y[Y] interval [DURATION] seconds",
    "gui.blocklyText.unihiker.addButtonClickFun": " Object name [NAME] add button [BUTTON] at X[X] Y[Y] width [WIDTH] height [HEIGHT] click callback function [FUN]",
    "gui.blocklyText.unihiker.showClock": "Object name [NAME] shows clock at X[X] Y[Y] radius [RADIUS] color [COLOR]",
    "gui.blocklyText.unihiker.showClockWithFillStyle": "Object name [NAME] shows fill clock at X[X] Y[Y] radius [RADIUS] border color [COLOR1] fill color [COLOR2]",
    "gui.blocklyText.unihiker.showQrCode": "The object name [NAME] shows the QR code content [VALUE] at X[X] Y[Y] side length [WIDTH]",
    "gui.blocklyText.unihiker.drawLine": "Object name[NAME] display line segment start point X[X1] Y[Y1] end point X[X2] Y[Y2] line width [LINEWIDTH] color [COLOR]",
    "gui.blocklyText.unihiker.drawRectangle": "Object name [NAME] displays rectangle at X[X] Y[Y] width [WIDTH] height [HEIGHT] line width [LINEWIDTH] border color [COLOR]",
    "gui.blocklyText.unihiker.drawRectangleWithFillStyle": "Object name [NAME] shows filled rectangle at X[X] Y[Y] width [WIDTH] height [HEIGHT] fill color [COLOR]",
    "gui.blocklyText.unihiker.drawRoundRectangle": "Object name [NAME] shows rounded rectangle at X[X] Y[Y] width [WIDTH] height [HEIGHT] corner radius [RADIUS] line width [LINEWIDTH] border color [COLOR]",
    "gui.blocklyText.unihiker.drawRoundRectangleWithFillStyle": "Object name [NAME] shows rounded fill rectangle at X[X] Y[Y] width [WIDTH] height [HEIGHT] corner radius [RADIUS] fill color [COLOR]",
    "gui.blocklyText.unihiker.drawCircle": "Object name [NAME] shows a circle at X[X] Y[Y] radius [RADIUS] line width [LINEWIDTH] border color [COLOR]",
    "gui.blocklyText.unihiker.drawCircleWithFillStyle": "Object name [NAME] shows fill circle at X[X] Y[Y] radius [RADIUS] fill color [COLOR]",
    "gui.blocklyText.unihiker.updateObjectNumberParams": "The number parameter [PARAMS] of the update object name [NAME] is [VALUE]",
    "gui.blocklyText.unihiker.updateObjectNumbersParams": "The number parameter [PARAMS1] of the update object name [NAME] is [VALUE1] [PARAMS2] is [VALUE2]",
    "gui.blocklyText.unihiker.updateObjectStringParams": "The text content parameter of the update object name [NAME] is [VALUE]",
    "gui.blocklyText.unihiker.updateObjectColorPalette": "The color of the update object name [NAME] is [COLOR]",
    "gui.blocklyText.unihiker.updateObjectColorRGB": "The color of the update object name [NAME] is red[RED]green[GREEN]blue[BLUE]",
    "gui.blocklyText.unihiker.updateObjectColorName": "The color of the update object name [NAME] is [COLOR]",
    "gui.blocklyText.unihiker.updateObjectStandardPoint": "The reference point of the update object name [NAME] is [POSITION]",
    "gui.blocklyText.unihiker.updateTimeObjectValue": "The time to update the clock object [NAME] is [HOUR] hours [MINUTE] minutes [SECOND] seconds",
    "gui.blocklyText.unihiker.setObjClickFun": "The click callback function of the object name [NAME] is [FUNCTIONNAME]",
    "gui.blocklyText.unihiker.whenClickTriggerFun": "When the click callback function [FUNCTIONNAME] is triggered",
    "gui.blocklyText.unihiker.updateImgObjSrc": "Update image object [IMG] image source is [SRC]",
    "gui.blocklyText.unihiker.updateExpressionObjSrc": "Update expression object [NAME] expression source is [EXPRESSION]",
    "gui.blocklyText.unihiker.blockTitle.mouseAndKeyboardEvent": "Mouse and keyboard event",
    "gui.blocklyText.unihiker.whenMouseMove": "When a mouse move event is received, return coordinates [X] [Y]",
    "gui.blocklyText.unihiker.whenButtonABPress": "When button [BUTTONAB] of unihiker is pressed",
    "gui.blocklyText.unihiker.whenKeyboardPress": "When the keyboard key [KEYBOARD] is pressed",
    "gui.blocklyText.unihiker.numberKeyboard": "Number and letter keys[KEYBOARD]",
    "gui.blocklyText.unihiker.otherKeyboard": "Other Keyboard[KEYBOARD]",
    "gui.blocklyText.unihiker.waitForWhenButtonABPress": "Wait until button [BUTTONAB] of unihiker is pressed",
    "gui.blocklyText.unihiker.blockTitle.multithreading": "Multithreading",
    "gui.blocklyText.unihiker.startThread": "Thread object [THREAD] start",
    "gui.blocklyText.unihiker.quitThread": "Thread object [THREAD] stopped",
    "gui.blocklyText.unihiker.whenThreadStart": "Execute when the thread object [THREAD] starts",
    "gui.blocklyText.unihiker.blockTitle.lmSensors": "Onboard Sensors",
    "gui.blocklyText.unihiker.isButtonABPressed": "Button [BUTTONAB] was pressed?",
    "gui.blocklyText.unihiker.readAmbientLight": "Read ambient light intensity",
    "gui.blocklyText.unihiker.readMicrophoneSound": "Read microphone sound level",
    "gui.blocklyText.unihiker.readXYZSAcceleratedSpeed": "Read acceleration value[XYZS]",
    "gui.blocklyText.unihiker.readGyroscopeXYZ": "Read the value of the gyroscope[XYZ]",
    "gui.blocklyText.unihiker.blockTitle.onboardBuzzer": "Onboard Buzzer",
    "gui.blocklyText.unihiker.playMusic": "Play Music [MUSIC] Repeat [REPEAT]",
    "gui.blocklyText.unihiker.musicSetTempo": "set each beat equal to [TICKS] notes, the number of beats per minute is [TEMPO]",
    "gui.blocklyText.unihiker.setBuzzerFreq": "Play note [NOTE] [BEAT] beat",
    "gui.blocklyText.unihiker.backSetBuzzerFreq": "Play notes in the background[NOTE]",
    "gui.blocklyText.unihiker.buzzerSwitch": "Stop background playback",
    "gui.blocklyText.unihiker.buzzerRedirect": "Buzzer redirect to pin [PIN]",
    "gui.blocklyText.unihiker.blockTitle.pinOperation": "Pin Operation",
    "gui.blocklyText.unihiker.unihikerPin": "Blank board pin[PIN]",
    "gui.blocklyText.unihiker.digitalRead": "Read digital pin [PIN]",
    "gui.blocklyText.unihiker.analogRead": "Read analog pin(ADC 0-4095)[PIN]",
    "gui.blocklyText.unihiker.digitalWrite": "Set digital pin [PIN] output [VALUE]",
    "gui.blocklyText.unihiker.analogWrite": "Set analog pin[PIN] output(PWM)[VALUE]",
    "gui.blocklyText.unihiker.blockTitle.audioPlayback": "Audio recording and playback",
    "gui.blocklyText.unihiker.getRecord": "Record [TIME] seconds until the end, file name [NAME]",
    "gui.blocklyText.unihiker.getRecordBackground": "Start background recording, file name [NAME]",
    "gui.blocklyText.unihiker.stopRecordBackground": "Stop background recording",
    "gui.blocklyText.unihiker.playAudio": "Play audio file [NAME] until end",
    "gui.blocklyText.unihiker.startPlayAudioBackground": "Start playing audio file [NAME] in the background",
    "gui.blocklyText.unihiker.getBgAudioRemainingDuration": "Get the remaining duration (seconds) of playing audio in the background",
    "gui.blocklyText.unihiker.pauseBgAudio": "Pause background playback",
    "gui.blocklyText.unihiker.continueBgAudio": "Restart background playback",
    "gui.blocklyText.unihiker.stopBgAudio": "End background playback",
    "gui.extension.unihiker.input.button": "Button",
    "gui.extension.unihiker.input.hello": "Hello",
    "gui.blocklyText.unihiker.updateButtonStatus": "Update button object [BUTTON] is [STATUS]",
    "gui.blocklyText.unihiker.updateObjectParamWithInput": "The parameter [KEY] of the update object [OBJECT] is [VALUE]",
    "gui.blocklyText.unihiker.updateObjectParamsWithInput": "The parameter [KEY1] of the update object [OBJECT] is [VALUE1] and the parameter [KEY2] is [VALUE2]",
    "gui.blocklyText.unihiker.removeObject": "Remove Object [OBJECT]",
    "gui.blocklyText.unihiker.removeAllObject": "Remove all objects",
    "gui.blocklyText.unihiker.buttonStatusMenu_normal": "Enable",
    "gui.blocklyText.unihiker.buttonStatusMenu_disable": "Disabled",
    "gui.blocklyText.unihiker.updateParamsMenu_fontSize": "Font size",
    "gui.blocklyText.unihiker.generate.keyboardWithButtonConflict": "!Error message: Keyboard key [AB] and row empty board key [AB] events can only be used at the same time, please delete the duplicate defined module",
    "gui.blocklyText.pythonsen0291.extensionName": "Power Meter",
    "gui.blocklyText.pythondfr0553.extensionName": "AD module",
    "gui.blocklyText.pythondfr0563.extensionName": "battery voltage",
    "gui.blocklyText.pyBaiDuAi.app_id": "AppID",
    "gui.blocklyText.pyBaiDuAi.api_key": "API Key",
    "gui.blocklyText.pyBaiDuAi.secret_key": "Secret Key",
    "gui.blocklyText.pyBaiDuAi.groupDelete": "delete face group [GroupName]",
    "gui.blocklyText.pyBaiDuAi.updateUser": "update face [Face] of user named [FaceName] in face group [GroupName]",
    "gui.blocklyText.pyBaiDuAi.faceDelete": "delete face (Token) [Token]  of user named [FaceName] in face group [GroupName]",
    "gui.blocklyText.pyBaiDuAi.getUser": "get user information of [FaceName] in face group [GroupName]",
    "gui.blocklyText.pyBaiDuAi.faceGetlist": "query face lists of [FaceName] in face group [GroupName]",
    "gui.blocklyText.pyBaiDuAi.age": "age",
    "gui.blocklyText.pyBaiDuAi.gender": "gender",
    "gui.blocklyText.pyBaiDuAi.beauty": "facial attractiveness",
    "gui.blocklyText.pyBaiDuAi.face_shape": "face shape",
    "gui.blocklyText.pyBaiDuAi.glasses": "eyeglasses",
    "gui.blocklyText.pyBaiDuAi.expression": "expression",
    "gui.blocklyText.pyBaiDuAi.face_probability": "probability of real face ",
    "gui.blocklyText.pyBaiDuAi.Width": "width",
    "gui.blocklyText.pyBaiDuAi.Height": "height",
    "gui.blocklyText.pyBaiDuAi.mask": "mask",
    "gui.blocklyText.pyBaiDuAi.objectDetect": "object detection",
    "gui.blocklyText.pyBaiDuAi.advancedGeneral": "general objects & scenarios",
    "gui.blocklyText.pyBaiDuAi.plantDetect": "plant",
    "gui.blocklyText.pyBaiDuAi.animalDetect": "animal",
    "gui.blocklyText.pyBaiDuAi.currency": "currency",
    "gui.blocklyText.pyBaiDuAi.basicGeneral": "general character",
    "gui.blocklyText.pyBaiDuAi.numbers": "number",
    "gui.blocklyText.pyBaiDuAi.licensePlate": "license plate",
    "gui.blocklyText.pyBaiDuAi.handwriting": "handwriting",
    "gui.blocklyText.pyBaiDuAi.init": "Initialize",
    "gui.blocklyText.pyBaiDuAi.AipFaceInit": "initialize Baidu face recognition [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipImageClassifyInit": "initialize Baidu image recognition [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipOcrInit": "initialize Baidu OCR [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipBodyAnalysisInit": "initialize Baidu human body analysis [SETTING]",
    "gui.blocklyText.pyBaiDuAi.getFace": "get face object",
    "gui.blocklyText.pyBaiDuAi.getImage": "get image object",
    "gui.blocklyText.pyBaiDuAi.face": "face recognition",
    "gui.blocklyText.pyBaiDuAi.generationFaceFormBase64": "create face image from local file [IMG]",
    "gui.blocklyText.pyBaiDuAi.generationFaceFormUrl": "create face image from URL [URL]",
    "gui.blocklyText.pyBaiDuAi.detect": "recognize [Image] face information and save into result",
    "gui.blocklyText.pyBaiDuAi.detectIsOk": "Is face recognition successful?",
    "gui.blocklyText.pyBaiDuAi.detectGet": "get [TYPE] of face information",
    "gui.blocklyText.pyBaiDuAi.match": "similarity between face [Face1] and [Face2] ",
    "gui.blocklyText.pyBaiDuAi.groupAdd": "create face group [GroupName]",
    "gui.blocklyText.pyBaiDuAi.addUser": "add face [Face] of user named [FaceName] to face group [GroupName]",
    "gui.blocklyText.pyBaiDuAi.img": "image recognition",
    "gui.blocklyText.pyBaiDuAi.imageDetectType": "recognize [DetectType] in image [IMG]",
    "gui.blocklyText.pyBaiDuAi.ocr": "OCR",
    "gui.blocklyText.pyBaiDuAi.body": "human body analysis",
    "gui.blocklyText.pyBaiDuAi.gesture": "recognize gesture in image [IMG]",
    "gui.blocklyText.pyBaiDuAi.gestureis": "Does gesture [Gesture] belong to [GestureType]?",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysis": "recognize human body features in image [IMG]",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysisIsOk": "Is human body features recognition successful?",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysisGet": "get [XY] coordinate of [BODY] in result of human body features",
    "gui.blocklyText.pyBaiDuAi.BASE64": "image base64 value",
    "gui.blocklyText.pyBaiDuAi.URL": "image URL",
    "gui.blocklyText.pyBaiDuAi.FACE_TOKEN": "image token",
    "gui.blocklyText.pyBaiDuAi.readImage": "read image [IMG]",
    "gui.blocklyText.pyBaiDuAi.search": "search in face group [GroupName] for name that shows >= [Score] similarity with [Face] ",
    "gui.blocklyText.pyBaiDuSpeech.init": "initialize Baidu speech client [SETTING]",
    "gui.blocklyText.pyBaiDuSpeech.asr": "recognize audio file [Speech] in [Format] with sample rate [Rate] ",
    "gui.blocklyText.pyBaiDuSpeech.synthesisOption": "set text-to-speech format [Format]  voice [Per]  speed [Spd]  pitch [Pit]  volume [Vol]",
    "gui.blocklyText.pyBaiDuSpeech.synthesis": "request to convert text [Text] to speech",
    "gui.blocklyText.pyBaiDuSpeech.isOk": "Is text-to-speech successful?",
    "gui.blocklyText.pyBaiDuSpeech.saveAudio": "name synthesized speech file as [Audio] and save",
    "gui.blocklyText.pyBaiDuSpeech.pcm": "pcm file",
    "gui.blocklyText.pyBaiDuSpeech.wav": "wav file",
    "gui.blocklyText.pyBaiDuSpeech.amr": "amr file",
    "gui.blocklyText.pyBaiDuSpeech.mp3": "mp3 file",
    "gui.extension.pyXfSpeech.init": "Initialize",
    "gui.extension.pyXfSpeech.speechInit": "Initialize Xunfei Speech [SETTING]",
    "gui.extension.pyXfSpeech.speechSynthesis": "Speech Synthesis",
    "gui.extension.pyXfSpeech.speechSynthesisOption": "Set speech synthesis parameters Speed[SPEED] Pitch[PITCH] Volume[VOLUME] [SETTING]",
    "gui.extension.pyXfSpeech.speechSynthesisSend": "Synthesize and save [Text] to [File] using speech synthesis",
    "gui.extension.pyXfSpeech.speechRecognition": "Speech Recognition",
    "gui.extension.pyXfSpeech.speechRecognitionSend": "Recognize [Audio] audio file",
    "gui.blocklyText.pyXfSpeech.noBackgroundsound": "None",
    "gui.blocklyText.pyXfSpeech.withBackgroundsound": "Yes",
    "gui.blocklyText.pyXfSpeech.xiaoyan": "Xunfei Xiaoyan",
    "gui.blocklyText.pyXfSpeech.aisjiuxu": "Xunfei Xiujiu",
    "gui.blocklyText.pyXfSpeech.aisxping": "Xunfei Xiaoping",
    "gui.blocklyText.pyXfSpeech.aisjinger": "Xunfei Xiaojing",
    "gui.blocklyText.pyXfSpeech.aisbabyxu": "Xunfei Xubao",
    "gui.blocklyText.pyXfSpeech.vcn": "Voice",
    "gui.blocklyText.pyXfSpeech.bgs": "Background sound",
    "gui.blocklyText.pythondfr0095.extensionName": "IR send",
    "gui.blocklyText.pythondfr0095.pinpongIRSend": "Infrared emission pin [PIN] send [DATA]",
    "gui.blocklyText.pythonMotorBit.MotorRun": "motor [MOTOR] move by [SPEED] speed [DIR]",
    "gui.blocklyText.pythonMotorBit.MotorStop": "motor [MOTOR] stop",
    "gui.blocklyText.pythonobloq.whenDebugInfo": "[NAME] get [DEBUG] [ERROR]",
    "gui.blocklyText.pythonobloq.WhenReceivedData": "when [EVENT] [TOPIC] received [MSG]",
    "gui.blocklyText.pythonobloq.DEBUG": "Debug info",
    "gui.blocklyText.pythonobloq.ERROR": "Error code",
    "gui.blocklyText.pythonobloq.ObloqMsg": "oobloq message",
    "gui.blocklyText.pythonobloq.initialI2CParameter": "[NAME] mqtt initial parameter [PARAMETER] interface I2C",
    "gui.blocklyText.pythonobloq.sendmsgToCloudPlatform": "[NAME] send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.pythonobloq.isReceivedData": "is [NAME] [TOPIC] received data ?",
    "gui.blocklyText.pythonobloq.readMsgFromCloudPlatformUno": "[NAME] Read the message from [TOPIC]",
    "gui.blocklyText.pythonobloq.httpInitialI2CParameter": "[NAME] http initial parameter [PARAMETER] interface I2C",
    "gui.blocklyText.pythonobloq.httpGet": "[NAME] http(get) url: [URL] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.pythonobloq.httpPost": "[NAME] http(post) url: [URL] content: [CONTENT] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.pythonobloq.httpPut": "[NAME] http(put) url: [URL] content: [CONTENT] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.pythonobloq.wifiGetLocalIP": "[NAME] Get WIFI configuration [CONFIGURATION]",
    "gui.extension.pictureai.fist": "fist",
    "gui.extension.pictureai.pray": "pray",
    "gui.extension.pictureai.bow": "bow",
    "gui.extension.pictureai.farewell": "farewell",
    "gui.extension.pictureai.one_heart": "one heart",
    "gui.extension.pictureai.thumbs_up": "thumbs up",
    "gui.extension.pictureai.love_you": "love you",
    "gui.extension.pictureai.palm_up": "palm up",
    "gui.extension.pictureai.two_hearts": "two hearts",
    "gui.extension.pictureai.middle_finger": "middle finger",
    "gui.extension.pictureai.face": "face",
    "gui.extension.pictureai.switch_account_failed": "switch account failed",
    "gui.extension.pictureai.id_not_exist": "id not exist",
    "gui.extension.pictureai.error": "error",
    "gui.extension.pictureai.file_not_exist_in_path": "file not exist in path",
    "gui.extension.pictureai.path_not_exist": "path not exist",
    "gui.extension.pictureai.save_image_failed": "save image failed",
    "gui.extension.pictureai.image_recognition": "image recognition",
    "gui.extension.pictureai.no_stage_video_stream": "no stage video stream",
    "gui.extension.pictureai.please_fill_image_address": "please fill image address",
    "gui.extension.pictureai.image_address_format_error": "image address format error",
    "gui.extension.pictureai.face_recognition": "face recognition",
    "gui.extension.pictureai.main_character_in_image": "main character in image",
    "gui.extension.pictureai.one_gender_age": " one {gender}，about {age} years old",
    "gui.extension.pictureai.male": "male",
    "gui.extension.pictureai.female": "female",
    "gui.extension.pictureai.wearing_glasses": ", wearing glasses",
    "gui.extension.pictureai.smiling": ", smiling",
    "gui.extension.pictureai.no_face_in_image": "No face in the image, please try another one.",
    "gui.extension.pictureai.app_authentication_failed": "App authentication failed, please check in the console whether the face recognition function is enabled.",
    "gui.extension.pictureai.app_usage_exceeded": "App usage has exceeded the quota, please check in the console.",
    "gui.extension.pictureai.square_face": "square face",
    "gui.extension.pictureai.cone_face": "cone face",
    "gui.extension.pictureai.oval_face": "oval face",
    "gui.extension.pictureai.heart_face": "heart face",
    "gui.extension.pictureai.round_face": "round face",
    "gui.extension.pictureai.unknown_face": "unknown face",
    "gui.extension.pictureai.glasses": "glasses",
    "gui.extension.pictureai.sunglasses": "sunglasses",
    "gui.extension.pictureai.smile": "smile",
    "gui.extension.pictureai.normal": "normal",
    "gui.extension.pictureai.angry": "angry",
    "gui.extension.pictureai.disgust": "disgust",
    "gui.extension.pictureai.fear": "fear",
    "gui.extension.pictureai.happy": "happy",
    "gui.extension.pictureai.sad": "sad",
    "gui.extension.pictureai.surprised": "surprised",
    "gui.extension.pictureai.expressionless": "expressionless",
    "gui.extension.pictureai.pouting": "pouting",
    "gui.extension.pictureai.making_faces": "making faces",
    "gui.extension.pictureai.no_mask": "no mask",
    "gui.extension.pictureai.wearing_mask": "wearing mask",
    "gui.extension.pictureai.please_do_face_recognition_first": "please do face recognition first",
    "gui.extension.pictureai.face_comparison": "face comparison",
    "gui.extension.pictureai.face_similarity": "face similarity",
    "gui.extension.pictureai.ensure_faces_in_both_images": "ensure faces in both images",
    "gui.extension.pictureai.check_image": "check image",
    "gui.extension.pictureai.text_recognition": "text recognition",
    "gui.extension.pictureai.handwriting": "handwriting",
    "gui.extension.pictureai.license_plate": "license plate",
    "gui.extension.pictureai.number": "number",
    "gui.extension.pictureai.no_text_in_image": "no text in image",
    "gui.extension.pictureai.produced_in": ", produced in",
    "gui.extension.pictureai.face_value": ", face value is",
    "gui.extension.pictureai.possible_non_currency": "possible non currency",
    "gui.extension.pictureai.possible_object": "possible object is",
    "gui.extension.pictureai.gesture_recognition": "gesture recognition",
    "gui.extension.pictureai.someone_thumbs_up_in_image": "someone thumbs up in image",
    "gui.extension.pictureai.someone_makes_heart_with_single_hands_in_image": "someone makes heart with one hand in image",
    "gui.extension.pictureai.someone_booing_in_image": "someone booing in image",
    "gui.extension.pictureai.someone_says_congratulations_in_image": "someone says congratulations in image",
    "gui.extension.pictureai.someone_says_ok_in_image": "someone says ok in image",
    "gui.extension.pictureai.someone_prays_in_image": "someone prays in image",
    "gui.extension.pictureai.someone_makes_heart_with_both_hands_in_image": "someone makes heart with both hands in image",
    "gui.extension.pictureai.someone_says_goodbye_in_image": "someone says goodbye in image",
    "gui.extension.pictureai.fist_in_image": "fist in image",
    "gui.extension.pictureai.rock_on": "rock on",
    "gui.extension.pictureai.someone_despises_you_in_image": "someone despises you in image",
    "gui.extension.pictureai.cannot_understand_gesture_in_image": "Can't understand the gesture in the picture, please try another one.",
    "gui.extension.pictureai.face_in_image_no_gesture": "face in image no gesture",
    "gui.extension.pictureai.recognition_failed": "recognition failed",
    "gui.extension.pictureai.human_keypoints_recognition": "human keypoints recognition",
    "gui.extension.pictureai.check_image_no_valid_human": "Please check the image, no valid human body found in the picture, please try another one.",
    "gui.extension.pictureai.result_as_shown": "result as shown",
    "gui.extension.pictureai.please_do_human_keypoints_analysis_first": "please do human keypoints analysis first",
    "gui.blocklyText.pico.pico_led": "led show [STATE]",
    "gui.blocklyText.pico.pico_readTemp": "read temperature",
    "gui.blocklyText.pico.pico_pinMode": "Set [PIN] pin mode to [MODE]",
    "gui.blocklyText.pico.serialPort0": "USB",
    "gui.blocklyText.pico.serialPort1": "serial1",
    "gui.blocklyText.pico.serialPort2": "serial2",
    "gui.blocklyText.pico.seriaRemapping": "[SERIALPORT] Rx [PINR] Tx [PINT] the baud rate [BAUD]",
    "gui.blocklyText.microbit.change": "change level",
    "gui.blocklyText.dfr0647.displayInit": "OLED display init address [ADDR]",
    "gui.blocklyText.dfr0647.displayShowTextInXY": "display [TEXT] in X: [X] axis Y: [Y] axis",
    "gui.blocklyText.dfr0647.clear": "OLED 128*32 clear",
    "gui.blocklyText.sen0318.CCS811init": "init ccs811 air quality sensor until success and get time [TIME] I2C address 0x5A",
    "gui.blocklyText.sen0318.writeBaseline": "write CCS811 air quality cardinal value [BASE]",
    "gui.blocklyText.sen0318.readValueBool": "read air quality value?",
    "gui.blocklyText.sen0318.readCO2TVCO": "read air quality [TYPE] value",
    "gui.blocklyText.sen0318.CCS811SetBase": "Set base: [BASE]",
    "gui.blocklyText.sen0318.250ms": "250ms",
    "gui.blocklyText.sen0318.1s": "1s",
    "gui.blocklyText.sen0318.10s": "10s",
    "gui.blocklyText.sen0318.60s": "60s",
    "gui.blocklyText.sen0318.close": "close",
    "gui.blocklyText.sen0318.CCS811ReadBaseline": "read CCS811 air quality cardinal value",
    "gui.blocklyText.pythonsen0318.readValueBool": "read air quality value?",
    "gui.blocklyText.pythonsen0318.readCO2TVCO": "read air quality [TYPE] value",
    "gui.blocklyText.pythonsen0318.CCS811init": "init ccs811 air quality sensor until success and get time [TIME], I2C address 0x5A",
    "gui.blocklyText.pythonsen0318.writeBaseline": "write CCS811 air quality cardinal value [BASE]",
    "gui.blocklyText.pythonsen0318.CCS811ReadBaseline": "read CCS811 air quality cardinal value",
    "gui.blocklyText.sen0315.PAJ7620U2init": "init PAJ7620U2 gesture recognition sensor [MODE] mode I2C address 0x73",
    "gui.blocklyText.sen0315.high": "high",
    "gui.blocklyText.sen0315.low": "low",
    "gui.blocklyText.sen0315.PAJ7620U2GetValue": "PAJ7620U2 gesture recognition sensor read value once",
    "gui.blocklyText.sen0315.PAJ7620U2IsTriggered": "PAJ7620U2 gesture sensor [GESTURE] is trggered",
    "gui.blocklyText.sen0315.Right": "Right",
    "gui.blocklyText.sen0315.Left": "Left",
    "gui.blocklyText.sen0315.Up": "Up",
    "gui.blocklyText.sen0315.Down": "Down",
    "gui.blocklyText.sen0315.Forward": "Forward",
    "gui.blocklyText.sen0315.Backward": "Backward",
    "gui.blocklyText.sen0315.Clockwise": "Clockwise",
    "gui.blocklyText.sen0315.Anti-Clockwise": "Anti-Clockwise",
    "gui.blocklyText.sen0315.Wave": "Wave",
    "gui.blocklyText.sen0315.WaveSlowlyDisorder": "WaveSlowlyDisorder",
    "gui.blocklyText.sen0315.WaveSlowlyLeftRight": "WaveSlowlyLeftRight",
    "gui.blocklyText.sen0315.WaveSlowlyUpDown": "WaveSlowlyUpDown",
    "gui.blocklyText.sen0315.WaveSlowlyForwardBackward": "WaveSlowlyForwardBackward",
    "gui.blocklyText.sen0364.visibleSpectrumInit": "init visible spectrum sensor I2C address 0x39",
    "gui.blocklyText.sen0364.LEDswitch": "enable LED [SWITCH]",
    "gui.blocklyText.sen0364.LEDBrightness": "set LED brightness [BRIGHTNESS]",
    "gui.blocklyText.sen0364.off": "OFF",
    "gui.blocklyText.sen0364.on": "ON",
    "gui.blocklyText.sen0364.spectrumGetValue": "spectrum sensor get [CHANNEL] channel value",
    "gui.blocklyText.sen0364.spectrumGetFreq": "spectrum sensor get blink freq",
    "gui.blocklyText.sen0364.visibleSpectrumSet": "Set the channel mapping as [MODE] (Mode 1 can read channels (F1-F4), Mode 2 can read channels (F5-NIR))",
    "gui.blocklyText.kit0176.weightInit": "init weight sensor until success addr: [ADDR]",
    "gui.blocklyText.kit0176.weightCal": "weight sensor calibration [CAL]",
    "gui.blocklyText.kit0176.weightAutoCal": "Set the trigger threshold for automatic calibration of the weight sensor module [CAL]",
    "gui.blocklyText.kit0176.weightStartUpCal": "Start automatic calibration",
    "gui.blocklyText.kit0176.IsSucesse": "Was the calibration successful?",
    "gui.blocklyText.kit0176.setCal": "Set/update calibration value",
    "gui.blocklyText.kit0176.getWeightValue": "Get the weight of an object",
    "gui.blocklyText.kit0176.getCalValue": "Get calibration value",
    "gui.blocklyText.kit0176.setCalValue": "Set/update calibration value [CAL] (manual mode)",
    "gui.blocklyText.pythonsen0497.DHT20Init": "Wait until the DHT20 temperature and humidity sensor module I2C connection is successful",
    "gui.blocklyText.pythonsen0497.DHT20Read": "DHT20 sensor read [INDEX]",
    "gui.blocklyText.sen0497.temp": "TEMP",
    "gui.blocklyText.sen0497.humi": "HUMI",
    "gui.blocklyText.sen0497.DHT20Init": "Wait until the DHT20 temperature and humidity sensor module I2C connection is successful I2C address 0x38",
    "gui.blocklyText.sen0497.DHT20Read": "DHT20 sensor read [INDEX]",
    "gui.blocklyText.pythonsen0514.ens160Init": "Initialize the ens160 sensor [ADDR]",
    "gui.blocklyText.pythonsen0514.ens160SetMode": "ENS160 air quality sensor setup mode [MODE]",
    "gui.blocklyText.pythonsen0514.ens160CalComp": "ENS160 air quality sensor cal temp [TEMP] and humi [HUMI]",
    "gui.blocklyText.pythonsen0514.ens160GetValue": "ENS160 read [VALUE]",
    "gui.blocklyText.pythonsen0518.max30102Init": "Initialize MAX30102 I2C address 0x57",
    "gui.blocklyText.pythonsen0518.max30102StartCollect": "MAX30102 starts collecting",
    "gui.blocklyText.pythonsen0518.max30102GetValue": "MMAX30102 read [VALUE]",
    "gui.blocklyText.sen0514.ens160Init": "Initialize ENS160 air quality sensor [ADDR]",
    "gui.blocklyText.sen0514.ens160SetMode": "ENS160 setting mode [MODE]",
    "gui.blocklyText.sen0514.standard": "standard mode",
    "gui.blocklyText.sen0514.idle": "idle mode",
    "gui.blocklyText.sen0514.sleep": "sleep mode",
    "gui.blocklyText.sen0514.ens160CalComp": "ENS160 Calibration and Compensation Temperature [TEMP]℃ Humidity [HUMI]%rh",
    "gui.blocklyText.sen0514.ens160GetValue": "ENS160 read [VALUE]",
    "gui.blocklyText.sen0514.eco2": "CO2 concentration",
    "gui.blocklyText.sen0514.tvoc": "TVOC concentration",
    "gui.blocklyText.sen0514.aqi": "air quality index",
    "gui.blocklyText.sen0514.status": "operating status",
    "gui.blocklyText.sen0518.max30102Init": "Initialize MAX30102 I2C address 0x57",
    "gui.blocklyText.sen0518.initSerialMax30102": "Initialize the MAX30102 module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.sen0518.max30102StartCollect": "MAX30102 starts collecting",
    "gui.blocklyText.sen0518.max30102GetValue": "MAX30102 reads [VALUE]",
    "gui.blocklyText.sen0518.spo2": "blood oxygen %",
    "gui.blocklyText.sen0518.heartbeat": "heartrate Times/min",
    "gui.blocklyText.sen0518.temp": "Sensor temperature °C",
    "gui.blocklyText.sen0359.fingerprintInit": "init fingerprint sensor I2C address 0x1F",
    "gui.blocklyText.sen0359.fingerprintSuccess": "wait fingerprint sensor init success",
    "gui.blocklyText.sen0359.green": "green",
    "gui.blocklyText.sen0359.red": "red",
    "gui.blocklyText.sen0359.yellow": "yellow",
    "gui.blocklyText.sen0359.blue": "blue",
    "gui.blocklyText.sen0359.blue1": "blue1",
    "gui.blocklyText.sen0359.magenta": "magenta",
    "gui.blocklyText.sen0359.white": "white",
    "gui.blocklyText.sen0359.breathe": "breathe",
    "gui.blocklyText.sen0359.flash": "flash",
    "gui.blocklyText.sen0359.noropen": "noropen",
    "gui.blocklyText.sen0359.norclose": "norclose",
    "gui.blocklyText.sen0359.slowopen": "slowopen",
    "gui.blocklyText.sen0359.slowclose": "slowclose",
    "gui.blocklyText.sen0359.slowflash": "slowflash",
    "gui.blocklyText.sen0359.fingerprintSetLED": "set LED color [COLOR] mode [MODE] times [TIMES]",
    "gui.blocklyText.sen0359.fingerTouch": "the finger touch?",
    "gui.blocklyText.sen0359.fingerprintCollection": "fingerprint collection once is success? tineout [TIME]",
    "gui.blocklyText.sen0359.fingerprintMatch": "Match the collected fingerprints with all IDs",
    "gui.blocklyText.sen0359.fingerprintMatchID": "Match the collected fingerprints with ID [ID]",
    "gui.blocklyText.sen0359.getFingerprintID": "Get a registrable ID",
    "gui.blocklyText.sen0359.fingerprintIDRegistrable": "ID [ID] already registered?",
    "gui.blocklyText.sen0359.getRegisterNum": "Get registrable user number",
    "gui.blocklyText.sen0359.saveID": "save fingerprint id [ID] is success?",
    "gui.blocklyText.sen0359.deleteID": "delete fingerprint id [ID]",
    "gui.blocklyText.sen0359.deleteIDAll": "delete fingerprint all",
    "gui.blocklyText.pythonsen0359.extensionName": "fingerprint",
    "gui.blocklyText.pythonsen0359.fingerprintInit": "init fingerprint sensor",
    "gui.blocklyText.pythonsen0359.fingerprintSuccess": "wait fingerprint sensor init success",
    "gui.blocklyText.pythonsen0359.fingerprintSetLED": "set LED color [COLOR] mode [MODE] times [TIMES]",
    "gui.blocklyText.pythonsen0359.fingerTouch": "the finger touch?",
    "gui.blocklyText.pythonsen0359.fingerprintCollection": "fingerprint collection once is success? tineout [TIME]",
    "gui.blocklyText.pythonsen0359.fingerprintMatch": "Match the collected fingerprints with all IDs",
    "gui.blocklyText.pythonsen0359.fingerprintMatchID": "Match the collected fingerprints with ID [ID] ?",
    "gui.blocklyText.pythonsen0359.getFingerprintID": "Get a registrable ID",
    "gui.blocklyText.pythonsen0359.fingerprintIDRegistrable": "ID [ID] already registered?",
    "gui.blocklyText.pythonsen0359.getRegisterNum": "Get registrable user number",
    "gui.blocklyText.pythonsen0359.saveID": "save fingerprint id [ID] is success?",
    "gui.blocklyText.pythonsen0359.deleteID": "delete fingerprint id [ID]",
    "gui.blocklyText.pythonsen0359.deleteIDAll": "delete fingerprint all",
    "gui.blocklyText.firebeetleesp32.Starts": "FireBeetle ESP32 starts",
    "gui.blocklyText.firebeetleesp32.pinOperation": "pin operation",
    "gui.blocklyText.firebeetleesp32.readDigital": "read digital pin [PIN]",
    "gui.blocklyText.firebeetleesp32.readAnalog": "read Analog pin [PIN]",
    "gui.blocklyText.firebeetleesp32.setPwm": "set PWM pin [PIN] to [VALUE]",
    "gui.blocklyText.firebeetleesp32.setDigital": "set digital pin [PIN] to [LEVEL]",
    "gui.blocklyText.firebeetleesp32.functionalModule": "functional module",
    "gui.blocklyText.firebeetleesp32.readUlrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.firebeetleesp32.trumpet": "set [PIN] pin trumpet tone [TONE] beat [BEAT]",
    "gui.blocklyText.firebeetleesp32.readInfrared": "read Infrared Receiver Modules digital pin [PIN]",
    "gui.blocklyText.firebeetleesp32.readPinLevel": "read pin [PIN] pulse width pattern [LEVEL] timeout [TIMEOUT](us)",
    "gui.blocklyText.firebeetleesp32.serialportOperation": "serialport operation",
    "gui.blocklyText.firebeetleesp32.serialSendStr": "serial [WAY] [STRING] [LINE]",
    "gui.blocklyText.firebeetleesp32.dataReadable": "Is serial data readable?",
    "gui.blocklyText.firebeetleesp32.readSerialData": "read serial data",
    "gui.blocklyText.firebeetleesp32.setBaud": "set serial interface baud rate [BAUD]",
    "gui.blocklyText.firebeetleesp32.readSerialDataIntFloat": "read serial data int or float [READTYPE]",
    "gui.blocklyText.firebeetleesp32.timer": "timer(ms)",
    "gui.blocklyText.firebeetleesp32.int": "int",
    "gui.blocklyText.firebeetleesp32.float": "float",
    "gui.blocklyText.firebeetleesp32.pwm1": "IO0",
    "gui.blocklyText.firebeetleesp32.pwm2": "IO2/D9",
    "gui.blocklyText.firebeetleesp32.pwm3": "IO5/D8",
    "gui.blocklyText.firebeetleesp32.pwm4": "IO13/D7",
    "gui.blocklyText.firebeetleesp32.pwm5": "IO15/A4",
    "gui.blocklyText.firebeetleesp32.pwm6": "IO25/D2",
    "gui.blocklyText.firebeetleesp32.pwm7": "IO26/D3",
    "gui.blocklyText.firebeetleesp32.pwm8": "IO27/D4",
    "gui.blocklyText.firebeetleesp32.pwm9": "IO10/D6",
    "gui.blocklyText.firebeetleesp32.high": "HIGH",
    "gui.blocklyText.firebeetleesp32.low": "LOW",
    "gui.blocklyText.firebeetleesp32.wrap": "Wrap",
    "gui.blocklyText.firebeetleesp32.noWrap": "No-Wrap",
    "gui.blocklyText.firebeetleesp32.stringOutput": "string output",
    "gui.blocklyText.firebeetleesp32.originalOutput": "original output",
    "gui.blocklyText.firebeetleesp32.hexOutput": "HEX output",
    "gui.blocklyText.firebeetleesp32.half": "Half",
    "gui.blocklyText.firebeetleesp32.quarter": "Quarter",
    "gui.blocklyText.firebeetleesp32.oneInEighth": "One in Eighth",
    "gui.blocklyText.firebeetleesp32.wholeBeat": "Whole Beat",
    "gui.blocklyText.firebeetleesp32.doubleBeat": "Double Beat",
    "gui.blocklyText.firebeetleesp32.noSound": "no sound",
    "gui.blocklyText.firebeetleesp32e.Starts": "FireBeetle ESP32-E starts",
    "gui.blocklyText.esp32.MQTTclient_id": "client_id",
    "gui.blocklyText.esp32.MQTTserver": "server",
    "gui.blocklyText.esp32.MQTTport": "port",
    "gui.blocklyText.esp32.MQTTuser": "user",
    "gui.blocklyText.esp32.MQTTpassowrd": "password",
    "gui.blocklyText.esp32.MQTTkeepalive": "keepalive",
    "gui.blocklyText.esp32.TencentIOT": "Tencent IOT",
    "gui.blocklyText.esp32.TencentServer": "Server",
    "gui.blocklyText.esp32.TencentPort": "Port",
    "gui.blocklyText.esp32.TencentAppld": "Appld",
    "gui.blocklyText.esp32.TencentDevice": "Device",
    "gui.blocklyText.esp32.Tencentoutput": "Output",
    "gui.blocklyText.esp32.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.esp32.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.esp32.EasyIOTServer": "Server",
    "gui.blocklyText.esp32.EasyIOTclient_id": "client_id",
    "gui.blocklyText.esp32.EasyIOT_id": "IoT_id",
    "gui.blocklyText.esp32.EasyIOT_password": "IoT_pwd",
    "paint.paintEditor.hue": "Color",
    "paint.paintEditor.saturation": "Saturation",
    "paint.paintEditor.brightness": "Brightness",
    "gui.comingSoon.message1": "Don't worry, we're on it {emoji}",
    "gui.comingSoon.message2": "Coming Soon...",
    "gui.comingSoon.message3": "We're working on it {emoji}",
    "paint.paintEditor.fill": "Fill",
    "paint.paintEditor.costume": "Costume",
    "paint.paintEditor.group": "Group",
    "paint.paintEditor.ungroup": "Ungroup",
    "paint.paintEditor.undo": "Undo",
    "paint.paintEditor.redo": "Redo",
    "paint.paintEditor.forward": "Forward",
    "paint.paintEditor.backward": "Backward",
    "paint.paintEditor.front": "Front",
    "paint.paintEditor.back": "Back",
    "paint.paintEditor.more": "More",
    "paint.modeTools.brushSize": "Size",
    "paint.modeTools.eraserSize": "Eraser size",
    "paint.modeTools.copy": "Copy",
    "paint.modeTools.paste": "Paste",
    "paint.modeTools.delete": "Delete",
    "paint.modeTools.curved": "Curved",
    "paint.modeTools.pointed": "Pointed",
    "paint.modeTools.thickness": "Thickness",
    "paint.modeTools.flipHorizontal": "Flip Horizontal",
    "paint.modeTools.flipVertical": "Flip Vertical",
    "paint.modeTools.filled": "Filled",
    "paint.modeTools.outlined": "Outlined",
    "paint.paintEditor.bitmap": "Convert to Bitmap",
    "paint.paintEditor.vector": "Convert to Vector",
    "paint.paintEditor.stroke": "Outline",
    "paint.brushMode.brush": "Brush",
    "paint.eraserMode.eraser": "Eraser",
    "paint.fillMode.fill": "Fill",
    "paint.lineMode.line": "Line",
    "paint.ovalMode.oval": "Circle",
    "paint.rectMode.rect": "Rectangle",
    "paint.reshapeMode.reshape": "Reshape",
    "paint.roundedRectMode.roundedRect": "Rounded Rectangle",
    "paint.selectMode.select": "Select",
    "paint.textMode.text": "Text",
    "paint.colorPicker.swap": "Swap",
    "gui.dialog.notConnectDevice": "No Device Connected ",
    "gui.dialog.prompt": "Note",
    "gui.dialog.openPort": "Open Port",
    "gui.dialog.error": "Error",
    "gui.dialog.notSelectDevice": "No device selected, please click [Extension] at the lower-left corner of the software,</br>click to select the device you want to connect",
    "gui.dialog.connectDeviceError1": "Connect device(",
    "gui.dialog.connectDeviceError2": ")Failed, please follow the steps below:</br> *Replace the usb port and re-plug the usb cable</br> *Check if it is connected",
    "gui.dialog.connectDeviceError3": "Main Control Board</br> * Join Official Communications Group (671877416) To Report Issues",
    "gui.dialog.calibration1": "</br> *Please turn",
    "gui.dialog.calibration2": "Calibrate the main control board</br>",
    "gui.dialog.runAsManager": "Please close Mind+ and right click on the software icon and select \"Run as administrator\". After starting, select this function again.",
    "gui.dialog.runErrorForVortex": "System not working properly, disconnect Vortex",
    "gui.dialog.runError": "System not working properly",
    "gui.dialog.close": "Close",
    "gui.dialog.variableNameSpecialCharacters": "variable name %1 cannot contain special characters:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "function name %1 cannot contain special characters:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "function arguments %1 cannot contain special characters:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "and",
    "gui.dialog.yes": "Yes",
    "gui.dialog.no": "No",
    "gui.dialog.continue": "Continue",
    "gui.dialog.maxReset": "Delete the codes in editing area and load the factory setting codes?",
    "gui.dialog.feedbackCannotEmpty": "Please fill in the feedback.",
    "gui.dialog.noInstallCompiler": "No compiler installed ",
    "gui.dialog.install": "Install online",
    "gui.dialog.cancle": "Cancel",
    "gui.dialog.installingCompiler": "installing compiler",
    "gui.dialog.uploading": "uploading",
    "gui.dialog.changeLanuage": "Switching the language will clear the current program. Are you sure you want to continue?",
    "gui.dialog.boardNotSupportMpy": "current board: %1 not support micopython",
    "gui.dialog.loadBlankItem": "Unsupported item</br> is reloading a blank item",
    "gui.dialog.switchBaudError": "Error occurred in switching baud rate</br>",
    "gui.dialog.turnMicrobitCompass": "Need to calibrate the electronic compass, please adjust the attitude of mciro: bit to move the blinking LED lights until all LED lights are on",
    "gui.dialog.turnCalliopeCompass": "Need to calibrate the electronic compass, please adjust the attitude of calliope to move the blinking LED lights until all LED lights are on",
    "gui.dialog.operationShow": "Operation Demonstration",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "computer",
    "gui.dialog.delete": "delete",
    "gui.dialog.sureDeleteFile": "Are you sure you want to delete the file [%2] on %1?",
    "gui.dialog.sureDeleteFolder": "Are you sure you want to delete the folder [%2] on %1?",
    "gui.dialog.uncorrectPath": "Not select the correct path",
    "gui.dialog.laterDownLoad": "Update Later",
    "gui.dialog.downLoadUpdate": "Download Update",
    "gui.dialog.downLoading": "Downloading...",
    "gui.dialog.dwnLoadWebsite": "Download Website",
    "gui.dialog.laterUpdate": "Install Later",
    "gui.dialog.update": "Install",
    "gui.dialog.getUpdateMsgError": "Error occurred in getting updated message",
    "gui.dialog.ok": "Ok",
    "gui.dialog.closeCodeBoxes": "Are you sure you want to close %1 code boxes?",
    "gui.dialog.emailError": "E-mail format is incorrect!",
    "gui.dialog.emailEmpty": "E-mail can not be empty!",
    "gui.dialog.bigFilePrompt": "The file is big, please be patient and wait, thank you!",
    "gui.dialog.successFeedbackPrompt1": "Thanks for your feedback, the page will be reloaded after 3 seconds.",
    "gui.dialog.successFeedbackPrompt2": "Your feedback has been submitted. Please submit it after a few seconds.",
    "gui.dialog.failedFeedbackPrompt": "Submit falied. we are so sorry for the inconvenience caused. Please visit our online forum to report your feedback at https://www.dfrobot.com/forum/, thank you!",
    "gui.dialog.successFeedbackPrompt3": "Thanks for your feedback",
    "gui.dialog.forcedCloseSoftWare": "An error occurred in saving the file, force close the software?",
    "gui.dialog.saveSuccess": "The project is saved",
    "gui.dialog.noSelectTruePath": "Not select the correct path",
    "gui.dialog.newItemContent": "You will create a blank project to overwrite the current project, are you sure to create it?",
    "gui.dialog.deleteBlocksError": "Failed to delete %1 block, please delete it manually",
    "gui.dialog.netIsConnect": "Please check if the network is connected",
    "gui.dialog.needToUpdate": "Update the compiler to the latest version: %1?",
    "gui.dialog.cantUseMpythonInArduinoC": "No compiler installed, you can't use mpython under Arduino C.If the computer does not have a network, you can download the 'Compiler offline plug-in package' on the official website.",
    "gui.dialog.enterMpyMode": "enter micropython mode",
    "gui.dialog.runHardware": "Connect the device first, then operate the hardware",
    "gui.dialog.sureDelete": "Are you sure you want to delete this?",
    "gui.dialog.notSupportWebGL": "An error occurred in loading the stage, this may happen when your computer cannot support WebGL,</br> Refer to the details shown below: </br> %1 </br>QQ group contact us: 671877416",
    "gui.dialog.boardIsnotMPython": "The current main control board connected is not mPython, please connect to mPython",
    "gui.dialog.swdMicrobitUpload": "Your computer must use the SWD to burn micro:bit, please run Mind+ with administrator privileges.",
    "gui.dialog.openPortError": "open port %1 error: %2",
    "gui.dialog.pressedA": "Please press and hold button A, do not release.",
    "gui.dialog.unpressedA": "Please release button A",
    "gui.dialog.unsupportProject": "Unsupported item</br> is reloading a blank item.",
    "gui.dialog.openProjectError": "Projects made in Scratch mode must be opened in Scratch mode</br> Please check the current mode.",
    "gui.dialog.installCompilerError1": "Download failed, you can download the offline installer or try it again",
    "gui.dialog.installCompilerOffline": "Download offline Compiler",
    "gui.dialog.installCompilerError2": "Verification error, you can download the offline installer or try to download again",
    "gui.dialog.installCompilerError3": "Installation error, you can download the offline installer or try to download again",
    "gui.dialog.installCompilerSuccess": "The compiler has been installed, you can use Esp32 under ArduinoC",
    "gui.dialog.sureAdapter": "The adapter was not found, please confirm whether the adapter is connected",
    "gui.dialog.checkPort": "Failed to open the serial port, please try to reconnect the serial port, or check whether the serial port is occupied.",
    "gui.dialog.changeBoard": "Switching board will clear the current program. Are you sure you want to continue?",
    "gui.dialog.removeBoard": "Removing board will clear the current program. Are you sure you want to continue?",
    "gui.crashMessage.somethingWrong": "Oops! Something went wrong.",
    "gui.crashMessage.cacheListPrompt": "You can find the project file in the menu 「Project」> 「Cache List」",
    "gui.crashMessage.reload": "Restart",
    "gui.crashMessage.submit": "Submit",
    "gui.crashMessage.attachment": "Attachment",
    "gui.crashMessage.errFeedbackNote": "You can write error messages or suggestions here, Mind+ will get better with your join.",
    "gui.crashMessage.promptContent": "We are so sorry, Sorry, it seems that Mind + has crashed. You can send this error to the Mind + team on the right side, thank you very much! Please refresh your page to try again.",
    "gui.dialog.isSaveProject": "Save the current project?",
    "gui.dialog.save": "Save",
    "gui.dialog.notSave": "Not Save",
    "gui.dialog.cancelSave": "Cancel Save",
    "gui.dialog.saveMPProjectToSb3": "The currently selected format is .sb3, only some basic building blocks in real-time mode are saved, do you want to continue saving?",
    "gui.dialog.variableIsNull": "Variable name cannot be empty",
    "gui.dialog.listNameIsNull": "list name cannot be empty",
    "gui.dialog.variableIsRepeat": "variable names cannot be repeated, please modify",
    "gui.dialog.pictureAINotNull": "cannot be null",
    "gui.dialog.pictureAIInitServer": "please initialize the server first",
    "gui.dialog.pictureAIInitServerError": "Error initializing the server, please check the account number and secret key",
    "gui.dialog.versionPrompt": "The current project version「%1」",
    "gui.dialog.versionGreater": "The project version is higher than the current software version, project version 「%1」",
    "gui.dialog.noModulePrompt": "There are no small modules under the current classification. See help for details.",
    "gui.dialog.firstSelectBoard": "Please select 'Board' or 'Kit' first.",
    "gui.dialog.downloadFileError": "Download file error",
    "gui.dialog.noSearchModules": "No related modules found, please check the search path",
    "gui.dialog.networkError": "Network error",
    "gui.dialog.saveThirdModulesError": "Error saving user library",
    "gui.dialog.coverUseExForLoadProject": "A library with the same name exists in the user library, which has been replaced by the user library in the project",
    "gui.dialog.isContinueUpdateThirdModule": "There are currently the same user extension libraries. Continue to update will overwrite the current user library. Do you want to continue?",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "Modified the field 「%2」 of %1, which needs to be manually import in the extension library. This refresh is canceled",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "Error parsing %1, this refresh will continue, but the content in 「%2」 will not be updated",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "The shape of the building block or the type of the input box is modified. Please manually delete the highlighted building block in the programming area, and then refresh it. This refresh is cancelled.",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh1": "To modify the shape of the block or the input box type, please manually delete the highlighted block in the programming area and check the sprite「%1」before refreshing.",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh2": "The shape of the block or the type of the input box is modified. Please check the sprite「%1」and refresh it again. This refresh is canceled.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "Modification of the shape of the building block or the type of the input box, please manually delete the highlighted building block in the programming area before loading, and the loading is canceled.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad1": "To modify the shape of the block or the type of the input box, please manually delete the highlighted block in the programming area and check the sprite「%1」before loading, the loading is canceled this time.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad2": "The shape of the block or the input box type is modified. Please check the sprite「%1」and load it again. This loading is canceled.",
    "gui.dialog.thirdex.filesEmpty": "The files field cannot be empty, which may cause related files to be lost. Please check the imported user library files!",
    "gui.dialog.thirdex.refreshing": "refreshing...",
    "gui.dialog.thirdex.refreshSuccess": "refresh success",
    "gui.dialog.isSaving": "Saving...",
    "gui.dialog.isExporting": "Exporting...",
    "gui.dialog.mpySb3Prompt": "「%1」has updated the micropython mode. If there is any error in the content of the project, you need to manually modify it",
    "gui.dialog.translate.fieldError": "The following error fields may cause errors in the display of building blocks",
    "gui.dialog.translate.translateError": "There is an error in the file content. Please choose to export or update the translation again",
    "gui.dialog.translate.startReadFile": "Start reading files, please be patient...",
    "gui.dialog.translate.fileFormatError": "There is no relevant translation file content, the file content format does not match",
    "gui.dialog.connecting.requestCloseFW": "Check that the firewall of this machine is open, which will cause Tello to fail to connect normally, click OK to close the firewall",
    "gui.dialog.cache.crash": "The abnormal shutdown of the software is detected, and the project can be restored through the cache file. Open the path\"project\">\"cache list\"",
    "gui.dialog.python.detectPip": "Pip environment is initializing, please wait",
    "gui.dialog.python.detectPipSucccess": "Pip environment initialization completed",
    "gui.dialog.python.detectPipError": "pip initialization failed, library management function is not available. Please check the network connection, you can also run \"[PYTHON]\" -m pip install --upgrade pip in the command line, and then restart Use this feature.",
    "gui.dialog.python.installPipreqsError": "[name] installation failed, the user library will not be able to export the custom library dependency list normally, please install it manually.",
    "gui.dialog.screenshoting": "Screenshot in progress",
    "gui.dialog.saveScreenshot": "Save Screenshot",
    "gui.dialog.saveScreenshotSuccess": "Save the screenshot successfully",
    "gui.dialog.serialDriverInstalling": "Serial Driver Installin...",
    "gui.dialog.resetDeviceSuccess": "The device is restored successfully",
    "gui.dialog.resetDeviceFailed": "Failed to restore the device",
    "gui.dialog.exportSuccess": "Export Successful",
    "gui.dialog.exportFailed": "Export Failed",
    "gui.dialog.exportCostume": "Export Role",
    "gui.dialog.loadProjectError": "Error loading project",
    "gui.dialog.readFileError": "Error reading file",
    "gui.dialog.projectIsDeleted": "Project has been deleted",
    "gui.dialog.projectIsNotExist": "Failed to open project, file does not exist",
    "gui.dialog.checkNetwork": "The current network is unavailable, please check if the computer can access the Internet",
    "gui.dialog.checkIotNetworkAndParameter": "The Internet of Things connection failed, please check whether the parameters are filled in correctly",
    "gui.dialog.knownError": "Known issue:",
    "gui.dialog.unknownError": "Unknown problem:",
    "gui.dialog.cancelInstall": "Cancel Installation",
    "gui.dialog.cannotFindDriver": "The driver file was not found, please check the path:",
    "gui.dialog.programError": "Program error",
    "gui.dialog.pleaseHandleInstall": "Please install the tool manually:",
    "gui.dialog.installSerialDriverError": "Error installing serial driver",
    "gui.dialog.installSerialDriverSuccess": "Serial Driver Installation Completed",
    "gui.dialog.hadInstall": "Installed",
    "gui.dialog.cache.sameAsCacheDeleteBlock": "The cache interface has the same user library, the related block will be deleted, whether to continue",
    "gui.dialog.cache.saveAs": "Save As",
    "gui.dialog.cache.exportExtError": "Error exporting extension:",
    "gui.dialog.cache.exportExtSuccess": "Export extension success",
    "gui.dialog.thirdExt.lostVersionKeyWithThird": "The version field is missing, please check the version field, it should be at the same level as the id field",
    "gui.dialog.thirdExt.notFoundRelatedFile": "No related file found",
    "gui.dialog.thirdExt.requestFileError": "Error requesting file",
    "gui.dialog.thirdExt.unzipLibrariesFailed": "Failed to unzip libraries.zip",
    "gui.dialog.thirdExt.pipreqsLostAndFillConfig": "The pipreqs library is not installed correctly, and there is an error in automatically exporting the dependent package list. For the convenience of users, please manually configure the dependent library information in config.json",
    "gui.dialog.thirdExt.exportExtToFolderSuccess": "Extended successfully exported to the directory",
    "gui.dialog.thirdExt.configJsonKeyLost": "'The [KEY] field in config.json cannot be empty!'",
    "gui.dialog.thirdExt.configJsonKeyLost_ID": "The id in config.json is the ID of the module and cannot be empty!",
    "gui.dialog.thirdExt.configJsonKeyLost_platform": "platform defines the supported platform, at least one platform (win, mac, web) is supported! Currently only win (winddowns) platform is supported",
    "gui.dialog.thirdExt.configJsonKeyLost_asset": "The language mode supported by asset definition in config.json, cannot be empty!",
    "gui.dialog.thirdExt.configJsonError_asset": "The asset content in config.json is defined incorrectly. At least one language mode is supported",
    "gui.dialog.thirdExt.configJsonLost_version": "The version field is missing, please check the version field, it should be at the same level as the id field",
    "gui.dialog.thirdExt.configJsonLost_asset_mode": "The field main of asset language mode [MODE] in config.json cannot be empty!",
    "gui.dialog.thirdExt.configJsonError_mode_board": "The field board of language mode [MODE] defines the status of the motherboard supported, and at least one motherboard is supported",
    "gui.dialog.stage.microphoneLost": "No sound is heard, please check if the microphone is plugged in",
    "gui.dialog.ml5.closeMl5Modal": "Close the window will lose the training data, please save it in time, whether to continue to close",
    "gui.dialog.ml5.restartMl5Train": "This operation will delete all categories, are you sure to continue?",
    "gui.dialog.ml5.ml5DefaultLabel": "Category 1",
    "gui.dialog.account.getCloudListError": "Error getting file list, whether to reload",
    "gui.dialog.account.requestCloudList": "Reload",
    "gui.dialog.python.getPIPListForUpdateError": "Timed out getting PIP update data",
    "gui.dialog.python.pipHasNoUpdate": "No update available",
    "gui.dialog.python.pipUpdateSucess": "[SUCCESS] successfully upgraded, [FAILED] failed, respectively [INFO]",
    "gui.dialog.python.pipUpdateAllSucess": "The upgrade was successful, [SUCCESS] in total",
    "gui.dialog.python.pipUpdateError": "Upgrading [NAME] failed. Details can be viewed on the PIP mode page",
    "gui.dialog.python.pipInstallError": "Failed to install [NAME] [VERSION], please check the PIP mode page for details",
    "gui.dialog.python.pipUninstallSucess": "[SUCCESS] successfully uninstalled, [FAILED] failed, respectively [INFO]",
    "gui.dialog.python.pipUninstallAllSucess": "Uninstall successfully, [COUNT] in total",
    "gui.dialog.python.pipUninstallError": "Uninstallation of [NAME] failed. Details can be viewed on the PIP mode page",
    "gui.dialog.python.sourceUnknow": "Unknown source",
    "gui.dialog.python.sourceTsinghuaUniversity": "Tsinghua University Source",
    "gui.dialog.python.sourceDouban": "Douban Source",
    "gui.dialog.python.sourceAli": "Ali source",
    "gui.dialog.python.sourceNeteasy": "Neteasy Source",
    "gui.dialog.python.sourceZGKJDX": "Science and Technology of China",
    "gui.dialog.python.sourceOfficial": "Python official source",
    "gui.dialog.python.rebootSoftForVsEditor": "The translation of the code editor related functions needs to be restarted to take effect",
    "gui.dialog.procedure.procedureNameIsRepeated": "The function 「[NAME]」 is already defined, please rename it",
    "gui.dialog.procedure.procedureNameIsEmpty": "Function name cannot be empty",
    "gui.dialog.project.saveProjectCheck": "Secondary Check",
    "gui.dialog.project.trySaveProjectAgain": "Sorry, there was an error parsing the saved file, please try saving again",
    "gui.dialog.software.secondInstanceTips": "The current software setting does not allow multiple openings. If you need to modify it, please update the settings in [Settings - System Settings - Software Multiple Openings]",
    "gui.dialog.project.saveFailed": "Error saving",
    "gui.dialog.pythonRemote.saveMPProjectToSb3": "The currently selected format is .sb3, only some basic building blocks in real-time mode are saved, do you want to continue saving?",
    "gui.dialog.thirdExt.downloadNow": "Download Now",
    "gui.dialog.thirdExt.downloadingLib": "Downloading",
    "gui.dialog.thirdExt.downloadFailed": "Download failed",
    "gui.dialog.thirdExt.detectThirdExtLibrariesInfo": "Detected that this user library requires [NEED] dependent libraries, [INSTALLED] already exist, do you want to download the remaining [REST] immediately?",
    "gui.dialog.thirdExt.downloadFinishFailed": "Failed to download [LENGTH] library, it is [INFO], please go to library management to view the specific information",
    "gui.dialog.thirdExt.downloadFinishSuccess": "[LENGTH] libraries have been downloaded successfully, for details, please go to library management to view",
    "gui.dialog.pythonRemote.detectingRemoteLibraries": "Detecting dependent library versions on [NAME], please wait...",
    "gui.dialog.pythonRemote.detectRemoteLibrariesFinish": "Detection completed, all new versions",
    "gui.dialog.pythonRemote.detectRemoteLibrariesInfo": "Detected that [LENGTH] libraries on <b>[NAME]</b> can be upgraded, do you want to download it now?",
    "gui.dialog.pythonRemote.detectRemotePipError": "PIP is unavailable in the remote system, please confirm that the Python environment exists before using the library management function",
    "gui.dialog.thirdExt.supportedModesAndMotherboards": "Supported modes and motherboards in the user library: ",
    "gui.dialog.thirdExt.offlineArduinoC": "Offline arduino C",
    "gui.dialog.thirdExt.offlineMicroPython": "Offline microPython",
    "gui.dialog.thirdExt.online": "Online",
    "gui.dialog.thirdExt.python": "Python",
    "gui.dialog.thirdExt.currentModeAndMotherboard": "Mind+ current mode and motherboard",
    "gui.dialog.thirdExt.currentMode": "Mind+ current mode",
    "gui.dialog.loadBlockError": "Loading failed, please switch to [%1] and then load the block program.",
    "gui.dialog.loadBoardError": "Loading failed, please load [%1] board or kit first.",
    "gui.dialog.exportTranslateFile": "Export",
    "gui.dialog.translate.endReadFile": "There are no errors in uploading the file, the language is being updated",
    "BACKDROPS_ARCTIC": "Arctic",
    "BACKDROPS_BASEBALL": "Baseball",
    "BACKDROPS_BASEBALL_2": "Baseball 2",
    "BACKDROPS_BASKETBALL": "Basketball",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Beach Malibu",
    "BACKDROPS_BEACH_RIO": "Beach Rio",
    "BACKDROPS_BEDROOM": "Bedroom",
    "BACKDROPS_BEDROOM_1": "Bedroom 1",
    "BACKDROPS_BEDROOM_2": "Bedroom 2",
    "BACKDROPS_BEDROOM_3": "Bedroom 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Bench With View",
    "BACKDROPS_BLUE_SKY": "Blue Sky",
    "BACKDROPS_BLUE_SKY_2": "Blue Sky 2",
    "BACKDROPS_BLUE_SKY3": "Blue Sky3",
    "BACKDROPS_BOARDWALK": "Boardwalk",
    "BACKDROPS_CANYON": "Canyon",
    "BACKDROPS_CASTLE_1": "Castle 1",
    "BACKDROPS_CASTLE_2": "Castle 2",
    "BACKDROPS_CASTLE_3": "Castle 3",
    "BACKDROPS_CASTLE_4": "Castle 4",
    "BACKDROPS_CHALKBOARD": "Chalkboard",
    "BACKDROPS_CIRCLES": "Circles",
    "BACKDROPS_CITY_WITH_WATER": "City With Water",
    "BACKDROPS_COLORFUL_CITY": "Colorful City",
    "BACKDROPS_CONCERT": "Concert",
    "BACKDROPS_DESERT": "Desert",
    "BACKDROPS_FARM": "Farm",
    "BACKDROPS_FIELD_AT_MIT": "Field At Mit",
    "BACKDROPS_FLOWERS": "Flowers",
    "BACKDROPS_FOREST": "Forest",
    "BACKDROPS_GALAXY": "Galaxy",
    "BACKDROPS_GARDEN-ROCK": "Garden-rock",
    "BACKDROPS_GREEK_THEATER": "Greek Theater",
    "BACKDROPS_HALL": "Hall",
    "BACKDROPS_HAY_FIELD": "Hay Field",
    "BACKDROPS_GRAFFITI": "Graffiti",
    "BACKDROPS_HEARTS1": "Hearts1",
    "BACKDROPS_HEARTS2": "Hearts2",
    "BACKDROPS_HILL": "Hill",
    "BACKDROPS_JUNGLE": "Jungle",
    "BACKDROPS_JURASSIC": "Jurassic",
    "BACKDROPS_LIGHT": "Light",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": "Moon",
    "BACKDROPS_MOUNTAIN": "Mountain",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "Nebula",
    "BACKDROPS_NEON_TUNNEL": "Neon Tunnel",
    "BACKDROPS_NIGHT_CITY": "Night City",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Night City With Street",
    "BACKDROPS_PARTY": "Party",
    "BACKDROPS_PATHWAY": "Pathway",
    "BACKDROPS_PARTY_ROOM": "Party Room",
    "BACKDROPS_PLAYGROUND": "Playground",
    "BACKDROPS_PLAYING_FIELD": "Playing Field",
    "BACKDROPS_POOL": "Pool",
    "BACKDROPS_PURPLE": "Purple",
    "BACKDROPS_RAYS": "Rays",
    "BACKDROPS_REFRIGERATOR": "Refrigerator",
    "BACKDROPS_ROOM_1": "Room 1",
    "BACKDROPS_ROOM_2": "Room 2",
    "BACKDROPS_SAVANNA": "Savanna",
    "BACKDROPS_SCHOOL": "School",
    "BACKDROPS_SLOPES": "Slopes",
    "BACKDROPS_SOCCER": "Soccer",
    "BACKDROPS_SOCCER_2": "Soccer 2",
    "BACKDROPS_SPACE": "Space",
    "BACKDROPS_SPACE_2": "Space 2",
    "BACKDROPS_SPACE_3": "Space 3",
    "BACKDROPS_SPACE_4": "Space 4",
    "BACKDROPS_SPOTLIGHT-STAGE": "Spotlight-stage",
    "BACKDROPS_STARS": "Stars",
    "BACKDROPS_STRIPES": "Stripes",
    "BACKDROPS_THEATER": "Theater",
    "BACKDROPS_THEATER_2": "Theater 2",
    "BACKDROPS_TREE": "Tree",
    "BACKDROPS_UNDERWATER_1": "Underwater 1",
    "BACKDROPS_UNDERWATER_2": "Underwater 2",
    "BACKDROPS_URBAN": "Urban",
    "BACKDROPS_WALL_1": "Wall 1",
    "BACKDROPS_WALL_2": "Wall 2",
    "BACKDROPS_WATER_AND_ROCKS": "Water And Rocks",
    "BACKDROPS_WETLAND": "Wetland",
    "BACKDROPS_WINTER": "Winter",
    "BACKDROPS_WINTER-LIGHTS": "Winter-lights",
    "BACKDROPS_WITCH_HOUSE": "Witch House",
    "BACKDROPS_WOODS": "Woods",
    "BACKDROPS_WOODS_AND_BENCH": "Woods And Bench",
    "BACKDROPS_XY-GRID": "Xy-grid",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "Abby-a",
    "COSTUMES_ABBY-B": "Abby-b",
    "COSTUMES_ABBY-C": "Abby-c",
    "COSTUMES_ABBY-D": "Abby-d",
    "COSTUMES_AMON": "Amon",
    "COSTUMES_ANDIE-A": "Andie-a",
    "COSTUMES_ANDIE-B": "Andie-b",
    "COSTUMES_ANDIE-C": "Andie-c",
    "COSTUMES_ANDIE-D": "Andie-d",
    "COSTUMES_ANINA_POP_DOWN": "Anina Pop Down",
    "COSTUMES_ANINA_POP_FRONT": "Anina Pop Front",
    "COSTUMES_ANINA_POP_L_ARM": "Anina Pop L Arm",
    "COSTUMES_ANINA_POP_LEFT": "Anina Pop Left",
    "COSTUMES_ANINA_POP_R_ARM": "Anina Pop R Arm",
    "COSTUMES_ANINA_POP_RIGHT": "Anina Pop Right",
    "COSTUMES_ANINA_POP_STAND": "Anina Pop Stand",
    "COSTUMES_ANINA_R_CROSS": "Anina R Cross",
    "COSTUMES_ANINA_STANCE": "Anina Stance",
    "COSTUMES_ANINA_TOP_FREEZE": "Anina Top Freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "Anina Top L Step",
    "COSTUMES_ANINA_TOP_R_STEP": "Anina Top R Step",
    "COSTUMES_ANINA_TOP_STAND": "Anina Top Stand",
    "COSTUMES_APPLE": "Apple",
    "COSTUMES_ARROW1-A": "Arrow1-a",
    "COSTUMES_ARROW1-B": "Arrow1-b",
    "COSTUMES_ARROW1-C": "Arrow1-c",
    "COSTUMES_ARROW1-D": "Arrow1-d",
    "COSTUMES_AVERY_WALKING-A": "Avery walking-a",
    "COSTUMES_AVERY_WALKING-B": "Avery walking-b",
    "COSTUMES_AVERY_WALKING-C": "Avery walking-c",
    "COSTUMES_AVERY_WALKING-D": "Avery walking-d",
    "COSTUMES_AVERY-A": "Avery-a",
    "COSTUMES_AVERY-B": "Avery-b",
    "COSTUMES_BALL-A": "Ball-a",
    "COSTUMES_BALL-B": "Ball-b",
    "COSTUMES_BALL-C": "Ball-c",
    "COSTUMES_BALL-D": "Ball-d",
    "COSTUMES_BALL-E": "Ball-e",
    "COSTUMES_BALLERINA-A": "Ballerina-a",
    "COSTUMES_BALLERINA-B": "Ballerina-b",
    "COSTUMES_BALLERINA-C": "Ballerina-c",
    "COSTUMES_BALLERINA-D": "Ballerina-d",
    "COSTUMES_BALLOON1-A": "Balloon1-a",
    "COSTUMES_BALLOON1-B": "Balloon1-b",
    "COSTUMES_BALLOON1-C": "Balloon1-c",
    "COSTUMES_BANANAS": "Bananas",
    "COSTUMES_BASEBALL": "Baseball",
    "COSTUMES_BASKETBALL": "Basketball",
    "COSTUMES_BAT-A": "Bat-a",
    "COSTUMES_BAT-B": "Bat-b",
    "COSTUMES_BAT-C": "Bat-c",
    "COSTUMES_BAT-D": "Bat-d",
    "COSTUMES_BATTER-A": "Batter-a",
    "COSTUMES_BATTER-B": "Batter-b",
    "COSTUMES_BATTER-C": "Batter-c",
    "COSTUMES_BATTER-D": "Batter-d",
    "COSTUMES_BEACHBALL": "Beachball",
    "COSTUMES_BEAR-A": "Bear-a",
    "COSTUMES_BEAR-B": "Bear-b",
    "COSTUMES_BEAR-WALK-A": "Bear-walk-a",
    "COSTUMES_BEAR-WALK-B": "Bear-walk-b",
    "COSTUMES_BEAR-WALK-C": "Bear-walk-c",
    "COSTUMES_BEAR-WALK-D": "Bear-walk-d",
    "COSTUMES_BEAR-WALK-E": "Bear-walk-e",
    "COSTUMES_BEAR-WALK-F": "Bear-walk-f",
    "COSTUMES_BEAR-WALK-G": "Bear-walk-g",
    "COSTUMES_BEAR-WALK-H": "Bear-walk-h",
    "COSTUMES_BEETLE": "Beetle",
    "COSTUMES_BELL1": "Bell1",
    "COSTUMES_BEN-A": "Ben-a",
    "COSTUMES_BEN-B": "Ben-b",
    "COSTUMES_BEN-C": "Ben-c",
    "COSTUMES_BEN-D": "Ben-d",
    "COSTUMES_BOWL-A": "Bowl-a",
    "COSTUMES_BOWTIE": "Bowtie",
    "COSTUMES_BREAD": "Bread",
    "COSTUMES_BROOM": "Broom",
    "COSTUMES_BUILDING-A": "Building-a",
    "COSTUMES_BUILDING-B": "Building-b",
    "COSTUMES_BUILDING-C": "Building-c",
    "COSTUMES_BUILDING-D": "Building-d",
    "COSTUMES_BUILDING-E": "Building-e",
    "COSTUMES_BUILDING-F": "Building-f",
    "COSTUMES_BUILDING-G": "Building-g",
    "COSTUMES_BUILDING-H": "Building-h",
    "COSTUMES_BUILDING-I": "Building-i",
    "COSTUMES_BUILDING-J": "Building-j",
    "COSTUMES_BUTTERFLY1-A": "Butterfly1-a",
    "COSTUMES_BUTTERFLY1-B": "Butterfly1-b",
    "COSTUMES_BUTTERFLY1-C": "Butterfly1-c",
    "COSTUMES_BUTTERFLY2-A": "Butterfly2-a",
    "COSTUMES_BUTTERFLY2-B": "Butterfly2-b",
    "COSTUMES_BUTTON1": "Button1",
    "COSTUMES_BUTTON2-A": "Button2-a",
    "COSTUMES_BUTTON2-B": "Button2-b",
    "COSTUMES_BUTTON3-A": "Button3-a",
    "COSTUMES_BUTTON3-B": "Button3-b",
    "COSTUMES_BUTTON4-A": "Button4-a",
    "COSTUMES_BUTTON4-B": "Button4-b",
    "COSTUMES_BUTTON5-A": "Button5-a",
    "COSTUMES_BUTTON5-B": "Button5-b",
    "COSTUMES_CAKE-A": "Cake-a",
    "COSTUMES_CAKE-B": "Cake-b",
    "COSTUMES_CALVRETT_JUMPING": "Calvrett Jumping",
    "COSTUMES_CALVRETT_THINKING": "Calvrett Thinking",
    "COSTUMES_CASEY-A": "Casey-a",
    "COSTUMES_CASEY-B": "Casey-b",
    "COSTUMES_CASEY-C": "Casey-c",
    "COSTUMES_CASEY-D": "casey-d",
    "COSTUMES_CASSY-A": "Cassy-a",
    "COSTUMES_CASSY-B": "Cassy-b",
    "COSTUMES_CASSY-C": "Cassy-c",
    "COSTUMES_CASSY-D": "Cassy-d",
    "COSTUMES_CAT_2": "Cat 2",
    "COSTUMES_CATCHER-A": "Catcher-a",
    "COSTUMES_CATCHER-B": "Catcher-b",
    "COSTUMES_CATCHER-C": "Catcher-c",
    "COSTUMES_CATCHER-D": "Catcher-d",
    "COSTUMES_CENTAUR-A": "Centaur-a",
    "COSTUMES_CENTAUR-B": "Centaur-b",
    "COSTUMES_CENTAUR-C": "centaur-c",
    "COSTUMES_CENTAUR-D": "centaur-d",
    "COSTUMES_CHAMP99-A": "Champ99-a",
    "COSTUMES_CHAMP99-B": "Champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "Cheesy Puffs",
    "COSTUMES_CHICK-A": "chick-a",
    "COSTUMES_CHICK-B": "chick-b",
    "COSTUMES_CHICK-C": "chick-c",
    "COSTUMES_CITY_BUS-A": "City Bus-a",
    "COSTUMES_CITY_BUS-B": "City Bus-b",
    "COSTUMES_CLOUD": "Cloud",
    "COSTUMES_CLOUD-A": "Cloud-a",
    "COSTUMES_CLOUD-B": "Cloud-b",
    "COSTUMES_CLOUD-C": "Cloud-c",
    "COSTUMES_CLOUD-D": "Cloud-d",
    "COSTUMES_CONVERTIBLE": "Convertible",
    "COSTUMES_CONVERTIBLE_3": "Convertible 2",
    "COSTUMES_CRAB-A": "Crab-a",
    "COSTUMES_CRAB-B": "Crab-b",
    "COSTUMES_CRYSTAL-A": "Crystal-a",
    "COSTUMES_CRYSTAL-B": "Crystal-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "Dee-a",
    "COSTUMES_DEE-B": "Dee-b",
    "COSTUMES_DEE-C": "Dee-c",
    "COSTUMES_DEE-D": "Dee-d",
    "COSTUMES_DEE-E": "Dee-e",
    "COSTUMES_DEVIN-A": "Devin-a",
    "COSTUMES_DEVIN-B": "Devin-b",
    "COSTUMES_DEVIN-C": "Devin-c",
    "COSTUMES_DEVIN-D": "Devin-d",
    "COSTUMES_DINOSAUR1-A": "Dinosaur1-a",
    "COSTUMES_DINOSAUR1-B": "Dinosaur1-b",
    "COSTUMES_DINOSAUR1-C": "Dinosaur1-c",
    "COSTUMES_DINOSAUR1-D": "Dinosaur1-d",
    "COSTUMES_DINOSAUR2-A": "Dinosaur2-a",
    "COSTUMES_DINOSAUR2-B": "Dinosaur2-b",
    "COSTUMES_DINOSAUR2-C": "Dinosaur2-c",
    "COSTUMES_DINOSAUR2-D": "Dinosaur2-d",
    "COSTUMES_DINOSAUR3-A": "Dinosaur3-a",
    "COSTUMES_DINOSAUR3-B": "Dinosaur3-b",
    "COSTUMES_DINOSAUR3-C": "Dinosaur3-c",
    "COSTUMES_DINOSAUR3-D": "Dinosaur3-d",
    "COSTUMES_DINOSAUR3-E": "Dinosaur3-e",
    "COSTUMES_DINOSAUR4-A": "Dinosaur4-a",
    "COSTUMES_DINOSAUR4-B": "Dinosaur4-b",
    "COSTUMES_DINOSAUR4-C": "Dinosaur4-c",
    "COSTUMES_DINOSAUR4-D": "Dinosaur4-d",
    "COSTUMES_DIVER1": "Diver1",
    "COSTUMES_DIVER2": "Diver2",
    "COSTUMES_DM_FREEZE": "dm freeze",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "dm stance",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": "Dog1-a",
    "COSTUMES_DOG1-B": "Dog1-b",
    "COSTUMES_DOG2-A": "Dog2-a",
    "COSTUMES_DOG2-B": "Dog2-b",
    "COSTUMES_DOG2-C": "Dog2-c",
    "COSTUMES_DONUT": "Donut",
    "COSTUMES_DORIAN-A": "Dorian-a",
    "COSTUMES_DORIAN-B": "Dorian-b",
    "COSTUMES_DORIAN-C": "Dorian-c",
    "COSTUMES_DORIAN-D": "Dorian-d",
    "COSTUMES_DOT-A": "Dot-a",
    "COSTUMES_DOT-B": "Dot-b",
    "COSTUMES_DOT-C": "Dot-c",
    "COSTUMES_DOT-D": "Dot-d",
    "COSTUMES_DOVE-A": "Dove-a",
    "COSTUMES_DOVE-B": "Dove-b",
    "COSTUMES_DRAGON-A": "Dragon-a",
    "COSTUMES_DRAGON-B": "Dragon-b",
    "COSTUMES_DRAGON-C": "Dragon-c",
    "COSTUMES_DRAGON1-A": "Dragon1-a",
    "COSTUMES_DRAGON1-B": "Dragon1-b",
    "COSTUMES_DRAGONFLY-A": "Dragonfly-a",
    "COSTUMES_DRAGONFLY-B": "Dragonfly-b",
    "COSTUMES_DRESS-A": "Dress-a",
    "COSTUMES_DRESS-B": "Dress-b",
    "COSTUMES_DRESS-C": "Dress-c",
    "COSTUMES_DRUM-A": "Drum-a",
    "COSTUMES_DRUM-B": "Drum-b",
    "COSTUMES_DRUM-CYMBAL-A": "Drum-cymbal-a",
    "COSTUMES_DRUM-CYMBAL-B": "Drum-cymbal-b",
    "COSTUMES_DRUM-HIGHHAT-A": "Drum-highhat-a",
    "COSTUMES_DRUM-HIGHHAT-B": "Drum-highhat-b",
    "COSTUMES_DRUM-KIT": "Drum-kit",
    "COSTUMES_DRUM-KIT-B": "Drum-kit-b",
    "COSTUMES_DRUM-SNARE-A": "Drum-snare-a",
    "COSTUMES_DRUM-SNARE-B": "Drum-snare-b",
    "COSTUMES_DRUMS_CONGA-A": "Drums Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Drums Conga-b",
    "COSTUMES_DUCK": "Duck",
    "COSTUMES_EARTH": "Earth",
    "COSTUMES_EASEL-A": "Easel-a",
    "COSTUMES_EASEL-B": "Easel-b",
    "COSTUMES_EASEL-C": "Easel-c",
    "COSTUMES_EGG-A": "Egg-a",
    "COSTUMES_EGG-B": "Egg-b",
    "COSTUMES_EGG-C": "Egg-c",
    "COSTUMES_EGG-D": "Egg-d",
    "COSTUMES_EGG-E": "Egg-e",
    "COSTUMES_EGG-F": "Egg-f",
    "COSTUMES_ELEPHANT-A": "Elephant-a",
    "COSTUMES_ELEPHANT-B": "Elephant-b",
    "COSTUMES_ELF-A": "Elf-a",
    "COSTUMES_ELF-B": "Elf-b",
    "COSTUMES_ELF-C": "Elf-c",
    "COSTUMES_ELF-D": "Elf-d",
    "COSTUMES_ELF-E": "Elf-e",
    "COSTUMES_FAIRY-A": "Fairy-a",
    "COSTUMES_FAIRY-B": "Fairy-b",
    "COSTUMES_FAIRY-C": "Fairy-c",
    "COSTUMES_FAIRY-D": "Fairy-d",
    "COSTUMES_FAIRY-E": "Fairy-e",
    "COSTUMES_FISH-A": "Fish-a",
    "COSTUMES_FISH-B": "Fish-b",
    "COSTUMES_FISH-C": "Fish-c",
    "COSTUMES_FISH-D": "Fish-d",
    "COSTUMES_FISHBOWL-A": "Fishbowl-a",
    "COSTUMES_FISHBOWL-B": "Fishbowl-b",
    "COSTUMES_FOOD_TRUCK-A": "Food Truck-a",
    "COSTUMES_FOOD_TRUCK-B": "Food Truck-b",
    "COSTUMES_FOOD_TRUCK-C": "Food Truck-c",
    "COSTUMES_FOOTBALL_RUNNING": "Football Running",
    "COSTUMES_FOOTBALL_STANDING": "Football Standing",
    "COSTUMES_FORTUNE_COOKIE": "Fortune Cookie",
    "COSTUMES_FOX-A": "Fox-a",
    "COSTUMES_FOX-B": "Fox-b",
    "COSTUMES_FOX-C": "Fox-c",
    "COSTUMES_FRANK-A": "Frank-a",
    "COSTUMES_FRANK-B": "Frank-b",
    "COSTUMES_FRANK-C": "Frank-c",
    "COSTUMES_FRANK-D": "Frank-d",
    "COSTUMES_FROG": "Frog",
    "COSTUMES_FROG_2-A": "Frog 2-a",
    "COSTUMES_FROG_2-B": "Frog 2-b",
    "COSTUMES_FROG_2-C": "Frog 2-c",
    "COSTUMES_FRUIT_PLATTER": "Fruit Platter",
    "COSTUMES_FRUITSALAD": "Fruitsalad",
    "COSTUMES_GHOST-A": "Ghost-a",
    "COSTUMES_GHOST-B": "Ghost-b",
    "COSTUMES_GHOST-C": "Ghost-c",
    "COSTUMES_GHOST-D": "Ghost-d",
    "COSTUMES_GIFT-A": "Gift-a",
    "COSTUMES_GIFT-B": "Gift-b",
    "COSTUMES_GIRAFFE-A": "Giraffe-a",
    "COSTUMES_GIRAFFE-B": "Giraffe-b",
    "COSTUMES_GIRAFFE-C": "Giraffe-c",
    "COSTUMES_GLASS_WATER-A": "Glass Water-a",
    "COSTUMES_GLASS_WATER-B": "Glass water-b",
    "COSTUMES_GLASSES-A": "Glasses-a",
    "COSTUMES_GLASSES-B": "Glasses-b",
    "COSTUMES_GLASSES-C": "Glasses-c",
    "COSTUMES_GLASSES-E": "Glasses-e",
    "COSTUMES_GOALIE-A": "Goalie-a",
    "COSTUMES_GOALIE-B": "Goalie-b",
    "COSTUMES_GOALIE-C": "goalie-c",
    "COSTUMES_GOALIE-D": "Goalie-d",
    "COSTUMES_GOALIE-E": "Goalie-e",
    "COSTUMES_GOBLIN-A": "Goblin-a",
    "COSTUMES_GOBLIN-B": "Goblin-b",
    "COSTUMES_GOBLIN-C": "Goblin-c",
    "COSTUMES_GOBLIN-D": "Goblin-d",
    "COSTUMES_GRASSHOPPER-A": "Grasshopper-a",
    "COSTUMES_GRASSHOPPER-B": "Grasshopper-b",
    "COSTUMES_GRASSHOPPER-C": "Grasshopper-c",
    "COSTUMES_GRASSHOPPER-D": "Grasshopper-d",
    "COSTUMES_GRASSHOPPER-E": "Grasshopper-e",
    "COSTUMES_GRASSHOPPER-F": "Grasshopper-f",
    "COSTUMES_GREEN_FLAG": "Green Flag",
    "COSTUMES_GRIFFIN-A": "Griffin-a",
    "COSTUMES_GRIFFIN-B": "Griffin-b",
    "COSTUMES_GRIFFIN-C": "Griffin-c",
    "COSTUMES_GRIFFIN-D": "Griffin-d",
    "COSTUMES_GUITAR-A": "guitar-a",
    "COSTUMES_GUITAR-B": "guitar-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "Guitar-electric1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "Guitar-electric1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "Guitar-electric2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "Guitar-electric2-b",
    "COSTUMES_HANNAH-A": "Hannah-a",
    "COSTUMES_HANNAH-B": "Hannah-b",
    "COSTUMES_HANNAH-C": "Hannah-c",
    "COSTUMES_HARE-A": "Hare-a",
    "COSTUMES_HARE-B": "Hare-b",
    "COSTUMES_HARE-C": "Hare-c",
    "COSTUMES_HARPER-A": "Harper-a",
    "COSTUMES_HARPER-B": "Harper-b",
    "COSTUMES_HARPER-C": "Harper-c",
    "COSTUMES_HAT-A": "Hat-a",
    "COSTUMES_HAT-B": "Hat-b",
    "COSTUMES_HAT-C": "Hat-c",
    "COSTUMES_HAT-D": "Hat-d",
    "COSTUMES_HATCHLING-A": "Hatchling-a",
    "COSTUMES_HATCHLING-B": "Hatchling-b",
    "COSTUMES_HATCHLING-C": "Hatchling-c",
    "COSTUMES_HEART_CODE": "Heart Code",
    "COSTUMES_HEART_FACE": "Heart Face",
    "COSTUMES_HEART_LOVE": "Heart Love",
    "COSTUMES_HEART_PURPLE": "Heart Purple",
    "COSTUMES_HEART_RED": "Heart Red",
    "COSTUMES_HEART_SMILE": "Heart Smile",
    "COSTUMES_HEART_SWEET": "Heart Sweet",
    "COSTUMES_HEDGEHOG-A": "Hedgehog-a",
    "COSTUMES_HEDGEHOG-B": "Hedgehog-b",
    "COSTUMES_HEDGEHOG-C": "Hedgehog-c",
    "COSTUMES_HEDGEHOG-D": "Hedgehog-d",
    "COSTUMES_HEDGEHOG-E": "Hedgehog-e",
    "COSTUMES_HEN-A": "Hen-a",
    "COSTUMES_HEN-B": "Hen-b",
    "COSTUMES_HEN-C": "Hen-c",
    "COSTUMES_HEN-D": "Hen-d",
    "COSTUMES_HIPPO1-A": "Hippo1-a",
    "COSTUMES_HIPPO1-B": "Hippo1-b",
    "COSTUMES_HOME_BUTTON": "Home Button",
    "COSTUMES_HORSE-A": "Horse-a",
    "COSTUMES_HORSE-B": "Horse-b",
    "COSTUMES_JAIME_WALKING-A": "Jaime Walking-a",
    "COSTUMES_JAIME_WALKING-B": "Jaime Walking-b",
    "COSTUMES_JAIME_WALKING-C": "Jaime Walking-c",
    "COSTUMES_JAIME_WALKING-D": "Jaime Walking-d",
    "COSTUMES_JAIME_WALKING-E": "Jaime Walking-e",
    "COSTUMES_JAIME-A": "Jaime-a",
    "COSTUMES_JAIME-B": "Jaime-b",
    "COSTUMES_JAMAL-A": "Jamal-a",
    "COSTUMES_JAMAL-B": "Jamal-b",
    "COSTUMES_JAMAL-C": "Jamal-c",
    "COSTUMES_JAMAL-D": "Jamal-d",
    "COSTUMES_JAR-A": "Jar-a",
    "COSTUMES_JAR-B": "Jar-b",
    "COSTUMES_JELLYFISH-A": "Jellyfish-a",
    "COSTUMES_JELLYFISH-B": "Jellyfish-b",
    "COSTUMES_JELLYFISH-C": "Jellyfish-c",
    "COSTUMES_JELLYFISH-D": "Jellyfish-d",
    "COSTUMES_JO_POP_DOWN": "Jo Pop Down",
    "COSTUMES_JO_POP_FRONT": "Jo Pop Front",
    "COSTUMES_JO_POP_L_ARM": "Jo Pop L Arm",
    "COSTUMES_JO_POP_LEFT": "Jo Pop Left",
    "COSTUMES_JO_POP_R_ARM": "Jo Pop R Arm",
    "COSTUMES_JO_POP_RIGHT": "Jo Pop Right",
    "COSTUMES_JO_POP_STAND": "Jo Pop Stand",
    "COSTUMES_JO_STANCE": "Jo Stance",
    "COSTUMES_JO_TOP_L_CROSS": "Jo Top L Cross",
    "COSTUMES_JO_TOP_L_LEG": "Jo Top L Leg",
    "COSTUMES_JO_TOP_R_CROSS": "Jo Top R Cross",
    "COSTUMES_JO_TOP_R_LEG": "Jo Top R Leg",
    "COSTUMES_JO_TOP_STAND": "Jo Top Stand",
    "COSTUMES_JORDYN-A": "Jordyn-a",
    "COSTUMES_JORDYN-B": "Jordyn-b",
    "COSTUMES_JORDYN-C": "Jordyn-c",
    "COSTUMES_JORDYN-D": "Jordyn-d",
    "COSTUMES_KAI-A": "Kai-a",
    "COSTUMES_KAI-B": "Kai-b",
    "COSTUMES_KEY": "Key",
    "COSTUMES_KEYBOARD-A": "Keyboard-a",
    "COSTUMES_KEYBOARD-B": "Keyboard-b",
    "COSTUMES_KIRAN-A": "Kiran-a",
    "COSTUMES_KIRAN-B": "Kiran-b",
    "COSTUMES_KIRAN-C": "Kiran-c",
    "COSTUMES_KIRAN-D": "Kiran-d",
    "COSTUMES_KIRAN-E": "Kiran-e",
    "COSTUMES_KIRAN-F": "Kiran-f",
    "COSTUMES_KNIGHT": "Knight",
    "COSTUMES_LADYBUG2": "Ladybug1",
    "COSTUMES_LADYBUG2-A": "Ladybug2-a",
    "COSTUMES_LADYBUG2-B": "Ladybug2-b",
    "COSTUMES_LAPTOP": "Laptop",
    "COSTUMES_LB_POP_DOWN": "Lb Pop Down",
    "COSTUMES_LB_POP_FRONT": "Lb Pop Front",
    "COSTUMES_LB_POP_L_ARM": "Lb Pop L Arm",
    "COSTUMES_LB_POP_LEFT": "Lb Pop Left",
    "COSTUMES_LB_POP_R_ARM": "Lb Pop R Arm",
    "COSTUMES_LB_POP_RIGHT": "Lb Pop Right",
    "COSTUMES_LB_POP_STAND": "Lb Pop Stand",
    "COSTUMES_LB_STANCE": "lb Stance",
    "COSTUMES_LB_TOP_L_CROSS": "Lb Top L Cross",
    "COSTUMES_LB_TOP_L_LEG": "Lb Top L Leg",
    "COSTUMES_LB_TOP_R_CROSS": "Lb Top R Cross",
    "COSTUMES_LB_TOP_R_LEG": "Lb Top R Leg",
    "COSTUMES_LB_TOP_STAND": "Lb Top Stand",
    "COSTUMES_LIGHTNING": "Lightning",
    "COSTUMES_LINE": "Line",
    "COSTUMES_LION-A": "Lion-a",
    "COSTUMES_LION-B": "Lion-b",
    "COSTUMES_LION-C": "Lion-c",
    "COSTUMES_LLAMA": "Llama",
    "COSTUMES_LLAMA-B": "Llama-b",
    "COSTUMES_LLAMA-C": "Llama-c",
    "COSTUMES_MAGICWAND": "Magicwand",
    "COSTUMES_MAX-A": "Max-a",
    "COSTUMES_MAX-B": "Max-b",
    "COSTUMES_MAX-C": "Max-c",
    "COSTUMES_MAX-D": "Max-d",
    "COSTUMES_MERMAID-A": "Mermaid-a",
    "COSTUMES_MERMAID-B": "Mermaid-b",
    "COSTUMES_MERMAID-C": "Mermaid-c",
    "COSTUMES_MERMAID-D": "Mermaid-d",
    "COSTUMES_MICROPHONE-A": "Microphone-a",
    "COSTUMES_MICROPHONE-B": "Microphone-b",
    "COSTUMES_MILK-A": "Milk-a",
    "COSTUMES_MILK-B": "Milk-b",
    "COSTUMES_MILK-C": "Milk-c",
    "COSTUMES_MILK-D": "Milk-d",
    "COSTUMES_MILK-E": "Milk-e",
    "COSTUMES_MONET-A": "Monet-a",
    "COSTUMES_MONET-B": "Monet-b",
    "COSTUMES_MONET-C": "Monet-c",
    "COSTUMES_MONET-D": "Monet-d",
    "COSTUMES_MONET-E": "Monet-e",
    "COSTUMES_MONKEY-A": "Monkey-a",
    "COSTUMES_MONKEY-B": "Monkey-b",
    "COSTUMES_MONKEY-C": "Monkey-c",
    "COSTUMES_MOTORCYCLE-A": "Motorcycle-a",
    "COSTUMES_MOTORCYCLE-B": "Motorcycle-b",
    "COSTUMES_MOTORCYCLE-C": "Motorcycle-c",
    "COSTUMES_MOTORCYCLE-D": "Motorcycle-d",
    "COSTUMES_MOUSE1-A": "Mouse1-a",
    "COSTUMES_MOUSE1-B": "Mouse1-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "Neigh Pony",
    "COSTUMES_OCTOPUS-A": "Octopus-a",
    "COSTUMES_OCTOPUS-B": "Octopus-b",
    "COSTUMES_OCTOPUS-C": "Octopus-c",
    "COSTUMES_OCTOPUS-D": "Octopus-d",
    "COSTUMES_OCTOPUS-E": "Octopus-e",
    "COSTUMES_ORANGE": "Orange",
    "COSTUMES_ORANGE2-A": "Orange2-a",
    "COSTUMES_ORANGE2-B": "Orange2-b",
    "COSTUMES_OUTFIELDER-A": "Outfielder-a",
    "COSTUMES_OUTFIELDER-B": "Outfielder-b",
    "COSTUMES_OUTFIELDER-C": "Outfielder-c",
    "COSTUMES_OUTFIELDER-D": "Outfielder-d",
    "COSTUMES_OWL-A": "Owl-a",
    "COSTUMES_OWL-B": "Owl-b",
    "COSTUMES_OWL-C": "Owl-c",
    "COSTUMES_PADDLE": "Paddle",
    "COSTUMES_PANTHER-A": "Panther-a",
    "COSTUMES_PANTHER-B": "Panther-b",
    "COSTUMES_PANTHER-C": "Panther-c",
    "COSTUMES_PANTS-A": "Pants-a",
    "COSTUMES_PANTS-B": "Pants-b",
    "COSTUMES_PARROT-A": "Parrot-a",
    "COSTUMES_PARROT-B": "Parrot-b",
    "COSTUMES_PARTY_HAT-A": "Party Hat-a",
    "COSTUMES_PARTY_HAT-B": "Party Hat-b",
    "COSTUMES_PARTY_HAT-E": "Party Hat-c",
    "COSTUMES_PENCIL-A": "Pencil-a",
    "COSTUMES_PENCIL-B": "Pencil-b",
    "COSTUMES_PENGUIN-A": "Penguin-a",
    "COSTUMES_PENGUIN-B": "Penguin-b",
    "COSTUMES_PENGUIN-C": "Penguin-c",
    "COSTUMES_PENGUIN2-A": "Penguin2-a",
    "COSTUMES_PENGUIN2-B": "Penguin2-b",
    "COSTUMES_PENGUIN2-C": "Penguin2-c",
    "COSTUMES_PENGUIN2-D": "Penguin2-d",
    "COSTUMES_PITCHER-A": "Pitcher-a",
    "COSTUMES_PITCHER-B": "Pitcher-b",
    "COSTUMES_PITCHER-C": "Pitcher-c",
    "COSTUMES_PITCHER-D": "Pitcher-d",
    "COSTUMES_PLANET2": "Planet2",
    "COSTUMES_POLAR_BEAR-A": "Polar Bear-a",
    "COSTUMES_POLAR_BEAR-B": "Polar Bear-b",
    "COSTUMES_POLAR_BEAR-C": "Polar Bear-c",
    "COSTUMES_POTION-A": "Potion-a",
    "COSTUMES_POTION-B": "Potion-b",
    "COSTUMES_POTION-C": "Potion-c",
    "COSTUMES_PRINCE": "Prince",
    "COSTUMES_PRINCESS-A": "Princess-a",
    "COSTUMES_PRINCESS-B": "Princess-b",
    "COSTUMES_PRINCESS-C": "Princess-c",
    "COSTUMES_PRINCESS-D": "Princess-d",
    "COSTUMES_PRINCESS-E": "Princess-e",
    "COSTUMES_PUFFERFISH-A": "Pufferfish-a",
    "COSTUMES_PUFFERFISH-B": "Pufferfish-b",
    "COSTUMES_PUFFERFISH-C": "Pufferfish-c",
    "COSTUMES_PUFFERFISH-D": "Pufferfish-d",
    "COSTUMES_PUPPY_BACK": "Puppy Back",
    "COSTUMES_PUPPY_RIGHT": "Puppy Right",
    "COSTUMES_PUPPY_SIDE": "Puppy Side",
    "COSTUMES_PUPPY_SIT": "Puppy Sit",
    "COSTUMES_RABBIT-A": "Rabbit-a",
    "COSTUMES_RABBIT-B": "Rabbit-b",
    "COSTUMES_RABBIT-C": "Rabbit-c",
    "COSTUMES_RABBIT-D": "Rabbit-d",
    "COSTUMES_RABBIT-E": "Rabbit-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": "Rainbow",
    "COSTUMES_REFEREE-A": "referee-a",
    "COSTUMES_REFEREE-B": "referee-b",
    "COSTUMES_REFEREE-C": "referee-c",
    "COSTUMES_REFEREE-D": "referee-d",
    "COSTUMES_REINDEER": "reindeer",
    "COSTUMES_RETRO_ROBOT_A": "Retro Robot a",
    "COSTUMES_RETRO_ROBOT_B": "Retro Robot b",
    "COSTUMES_RETRO_ROBOT_C": "Retro Robot c",
    "COSTUMES_RIPLEY-A": "Ripley-a",
    "COSTUMES_RIPLEY-B": "Ripley-b",
    "COSTUMES_RIPLEY-C": "Ripley-c",
    "COSTUMES_RIPLEY-D": "Ripley-d",
    "COSTUMES_RIPLEY-E": "Ripley-e",
    "COSTUMES_RIPLEY-F": "Ripley-f",
    "COSTUMES_ROBOT-A": "Robot-a",
    "COSTUMES_ROBOT-B": "Robot-b",
    "COSTUMES_ROBOT-C": "Robot-c",
    "COSTUMES_ROBOT-D": "Robot-d",
    "COSTUMES_ROCKETSHIP-A": "Rocketship-a",
    "COSTUMES_ROCKETSHIP-B": "Rocketship-b",
    "COSTUMES_ROCKETSHIP-C": "Rocketship-c",
    "COSTUMES_ROCKETSHIP-D": "Rocketship-d",
    "COSTUMES_ROCKETSHIP-E": "Rocketship-e",
    "COSTUMES_ROCKS": "Rocks",
    "COSTUMES_ROOSTER-A": "Rooster-a",
    "COSTUMES_ROOSTER-B": "Rooster-b",
    "COSTUMES_ROOSTER-C": "Rooster-c",
    "COSTUMES_RUBY-A": "Ruby-a",
    "COSTUMES_RUBY-B": "Ruby-b",
    "COSTUMES_SAILBOAT": "Sailboat",
    "COSTUMES_SAM": "Sam",
    "COSTUMES_SAXOPHONE-A": "Saxophone-a",
    "COSTUMES_SAXOPHONE-B": "Saxophone-b",
    "COSTUMES_SCARF-A": "Scarf-a",
    "COSTUMES_SCARF-B": "Scarf-b",
    "COSTUMES_SCARF-C": "Scarf-c",
    "COSTUMES_SHARK-A": "Shark-a",
    "COSTUMES_SHARK-B": "Shark-b",
    "COSTUMES_SHARK2-A": "Shark2-a",
    "COSTUMES_SHARK2-B": "Shark2-b",
    "COSTUMES_SHARK2-C": "Shark2-c",
    "COSTUMES_SHIRT-A": "Shirt-a",
    "COSTUMES_SHOES-A": "Shoes-a",
    "COSTUMES_SHOES-B": "Shoes-b",
    "COSTUMES_SHOES-C": "Shoes-c",
    "COSTUMES_SHOES-D": "Shoes-d",
    "COSTUMES_SHORTS-A": "Shorts-a",
    "COSTUMES_SHORTS-B": "Shorts-b",
    "COSTUMES_SHORTS-C": "Shorts-c",
    "COSTUMES_SINGER1": "Singer1",
    "COSTUMES_SKELETON-A": "Skeleton-a",
    "COSTUMES_SKELETON-B": "Skeleton-b",
    "COSTUMES_SKELETON-D": "Skeleton-d",
    "COSTUMES_SKELETON-E": "Skeleton-e",
    "COSTUMES_SNAKE-A": "Snake-a",
    "COSTUMES_SNAKE-B": "Snake-b",
    "COSTUMES_SNAKE-C": "Snake-c",
    "COSTUMES_SNOWFLAKE": "Snowflake",
    "COSTUMES_SNOWMAN": "Snowman",
    "COSTUMES_SOCCER_BALL": "Soccer Ball",
    "COSTUMES_SPEAKER": "Speaker",
    "COSTUMES_SQUIRREL": "Squirrel",
    "COSTUMES_STAR": "Star",
    "COSTUMES_STARFISH-A": "Starfish-a",
    "COSTUMES_STARFISH-B_": "Starfish-b ",
    "COSTUMES_STOP": "Stop",
    "COSTUMES_STRAWBERRY-A": "Strawberry-a",
    "COSTUMES_STRAWBERRY-B": "Strawberry-b",
    "COSTUMES_STRAWBERRY-C": "Strawberry-c",
    "COSTUMES_STRAWBERRY-D": "Strawberry-d",
    "COSTUMES_STRAWBERRY-E": "Strawberry-e",
    "COSTUMES_SUN": "Sun",
    "COSTUMES_SUNGLASSES-A": "Sunglasses-a",
    "COSTUMES_SUNGLASSES-B": "Sunglasses-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-wizard",
    "COSTUMES_TAKEOUT-A": "Takeout-a",
    "COSTUMES_TAKEOUT-B": "Takeout-b",
    "COSTUMES_TAKEOUT-C": "Takeout-c",
    "COSTUMES_TAKEOUT-D": "Takeout-d",
    "COSTUMES_TAKEOUT-E": "Takeout-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 Pop Down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 Pop Front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 Pop L Arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 Pop Left",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 Pop R Arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 Pop Right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 Pop Stand",
    "COSTUMES_TEN80_STANCE": "Ten80 stance",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 top freeze",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 top L step",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 top R cross",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 top R step",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 top stand",
    "COSTUMES_TENNISBALL": "Tennisball",
    "COSTUMES_TOUCAN-A": "Toucan-a",
    "COSTUMES_TOUCAN-B": "Toucan-b",
    "COSTUMES_TOUCAN-C": "Toucan-c",
    "COSTUMES_TRAMPOLINE": "Trampoline",
    "COSTUMES_TREE1": "Tree1",
    "COSTUMES_TREES-A": "Trees-a",
    "COSTUMES_TREES-B": "Trees-b",
    "COSTUMES_TRUCK-A": "Truck-a",
    "COSTUMES_TRUCK-B": "Truck-b",
    "COSTUMES_TRUCK-C": "Truck-c",
    "COSTUMES_TRUMPET-A": "Trumpet-a",
    "COSTUMES_TRUMPET-B": "Trumpet-b",
    "COSTUMES_UNICORN": "Unicorn",
    "COSTUMES_UNICORN_2": "Unicorn 2",
    "COSTUMES_UNICORN_RUNNING-A": "Unicorn Running-a",
    "COSTUMES_UNICORN_RUNNING-B": "Unicorn Running-b",
    "COSTUMES_UNICORN_RUNNING-C": "Unicorn Running-c",
    "COSTUMES_UNICORN_RUNNING-D": "Unicorn Running-d",
    "COSTUMES_UNICORN_RUNNING-E": "Unicorn Running-e",
    "COSTUMES_UNICORN_RUNNING-F": "Unicorn Running-f",
    "COSTUMES_WAND": "Wand",
    "COSTUMES_WANDA": "Wanda",
    "COSTUMES_WATERMELON-A": "Watermelon-a",
    "COSTUMES_WATERMELON-B": "Watermelon-b",
    "COSTUMES_WATERMELON-C": "Watermelon-c",
    "COSTUMES_WINTER_HAT": "Winter Hat",
    "COSTUMES_WITCH": "Witch",
    "COSTUMES_WITCH-A": "Witch-a",
    "COSTUMES_WITCH-B": "Witch-b",
    "COSTUMES_WITCH-C": "Witch-c",
    "COSTUMES_WITCH-D": "Witch-d",
    "COSTUMES_WIZARD_HAT": "Wizard Hat",
    "COSTUMES_WIZARD-A": "Wizard-a",
    "COSTUMES_WIZARD-B": "Wizard-b",
    "COSTUMES_WIZARD-C": "Wizard-c",
    "COSTUMES_WIZARD-TOAD-A": "Wizard-toad-a",
    "COSTUMES_WIZARD-TOAD-B": "Wizard-toad-b",
    "COSTUMES_ZEBRA-A": "Zebra-a",
    "COSTUMES_ZEBRA-B": "Zebra-b",
    "COSTUMES_BLOCK-A": "Block-a",
    "COSTUMES_BLOCK-B": "Block-b",
    "COSTUMES_BLOCK-C": "Block-c",
    "COSTUMES_BLOCK-D": "Block-d",
    "COSTUMES_BLOCK-E": "Block-e",
    "COSTUMES_BLOCK-F": "Block-f",
    "COSTUMES_BLOCK-G": "Block-g",
    "COSTUMES_BLOCK-H": "Block-h",
    "COSTUMES_BLOCK-I": "Block-i",
    "COSTUMES_BLOCK-J": "Block-j",
    "COSTUMES_BLOCK-K": "Block-k",
    "COSTUMES_BLOCK-L": "Block-l",
    "COSTUMES_BLOCK-M": "Block-m",
    "COSTUMES_BLOCK-N": "Block-n",
    "COSTUMES_BLOCK-O": "Block-o",
    "COSTUMES_BLOCK-P": "Block-p",
    "COSTUMES_BLOCK-Q": "Block-q",
    "COSTUMES_BLOCK-R": "Block-r",
    "COSTUMES_BLOCK-S": "Block-s",
    "COSTUMES_BLOCK-T": "Block-t",
    "COSTUMES_BLOCK-U": "Block-u",
    "COSTUMES_BLOCK-V": "Block-v",
    "COSTUMES_BLOCK-W": "Block-w",
    "COSTUMES_BLOCK-X": "Block-x",
    "COSTUMES_BLOCK-Y": "Block-y",
    "COSTUMES_BLOCK-Z": "Block-z",
    "COSTUMES_GLOW-0": "Glow-0",
    "COSTUMES_GLOW-1": "Glow-1",
    "COSTUMES_GLOW-2": "Glow-2",
    "COSTUMES_GLOW-3": "Glow-3",
    "COSTUMES_GLOW-4": "Glow-4",
    "COSTUMES_GLOW-5": "Glow-5",
    "COSTUMES_GLOW-6": "Glow-6",
    "COSTUMES_GLOW-7": "Glow-7",
    "COSTUMES_GLOW-8": "Glow-8",
    "COSTUMES_GLOW-9": "Glow-9",
    "COSTUMES_GLOW-A": "Glow-A",
    "COSTUMES_GLOW-B": "Glow-B",
    "COSTUMES_GLOW-C": "Glow-C",
    "COSTUMES_GLOW-D": "Glow-D",
    "COSTUMES_GLOW-E": "Glow-E",
    "COSTUMES_GLOW-F": "Glow-F",
    "COSTUMES_GLOW-G": "Glow-G",
    "COSTUMES_GLOW-H": "Glow-H",
    "COSTUMES_GLOW-I": "Glow-I",
    "COSTUMES_GLOW-J": "Glow-J",
    "COSTUMES_GLOW-K": "Glow-K",
    "COSTUMES_GLOW-L": "Glow-L",
    "COSTUMES_GLOW-M": "Glow-M",
    "COSTUMES_GLOW-N": "Glow-N",
    "COSTUMES_GLOW-O": "Glow-O",
    "COSTUMES_GLOW-P": "Glow-P",
    "COSTUMES_GLOW-Q": "Glow-Q",
    "COSTUMES_GLOW-R": "Glow-R",
    "COSTUMES_GLOW-S": "Glow-S",
    "COSTUMES_GLOW-T": "Glow-T",
    "COSTUMES_GLOW-U": "Glow-U",
    "COSTUMES_GLOW-V": "Glow-V",
    "COSTUMES_GLOW-W": "Glow-W",
    "COSTUMES_GLOW-X": "Glow-X",
    "COSTUMES_GLOW-Y": "Glow-Y",
    "COSTUMES_GLOW-Z": "Glow-Z",
    "COSTUMES_STORY-A-1": "Story-A-1",
    "COSTUMES_STORY-A-2": "Story-A-2",
    "COSTUMES_STORY-A-3": "Story-A-3",
    "COSTUMES_STORY-B-1": "Story-B-1",
    "COSTUMES_STORY-B-2": "Story-B-2",
    "COSTUMES_STORY-B-3": "Story-B-3",
    "COSTUMES_STORY-C-1": "Story-C-1",
    "COSTUMES_STORY-C-2": "Story-C-2",
    "COSTUMES_STORY-C-3": "Story-C-3",
    "COSTUMES_STORY-D-1": "Story-D-1",
    "COSTUMES_STORY-D-2": "Story-D-2",
    "COSTUMES_STORY-D-3": "Story-D-3",
    "COSTUMES_STORY-E-1": "Story-E-1",
    "COSTUMES_STORY-E-2": "Story-E-2",
    "COSTUMES_STORY-E-3": "Story-E-3",
    "COSTUMES_STORY-F-1": "Story-F-1",
    "COSTUMES_STORY-F-2": "Story-F-2",
    "COSTUMES_STORY-F-3": "Story-F-3",
    "COSTUMES_STORY-G-1": "Story-G-1",
    "COSTUMES_STORY-G-2": "Story-G-2",
    "COSTUMES_STORY-G-3": "Story-G-3",
    "COSTUMES_STORY-H-1": "Story-H-1",
    "COSTUMES_STORY-H-2": "Story-H-2",
    "COSTUMES_STORY-H-3": "Story-H-3",
    "COSTUMES_STORY-I-1": "Story-I-1",
    "COSTUMES_STORY-I-2": "Story-I-2",
    "COSTUMES_STORY-I-3": "Story-I-3",
    "COSTUMES_STORY-J-1": "Story-J-1",
    "COSTUMES_STORY-J-2": "Story-J-2",
    "COSTUMES_STORY-J-3": "Story-J-3",
    "COSTUMES_STORY-K-1": "Story-K-1",
    "COSTUMES_STORY-K-2": "Story-K-2",
    "COSTUMES_STORY-K-3": "story-K-3",
    "COSTUMES_STORY-L-1": "Story-L-1",
    "COSTUMES_STORY-L-2": "Story-L-2",
    "COSTUMES_STORY-L-3": "Story-L-3",
    "COSTUMES_STORY-M-1": "Story-M-1",
    "COSTUMES_STORY-M-2": "Story-M-2",
    "COSTUMES_STORY-M-3": "Story-M-3",
    "COSTUMES_STORY-N-1": "Story-N-1",
    "COSTUMES_STORY-N-2": "Story-N-2",
    "COSTUMES_STORY-N-3": "Story-N-3",
    "COSTUMES_STORY-O-1": "Story-O-1",
    "COSTUMES_STORY-O-2": "Story-O-2",
    "COSTUMES_STORY-O-3": "Story-O-3",
    "COSTUMES_STORY-P-1": "Story-P-1",
    "COSTUMES_STORY-P-2": "Story-P-2",
    "COSTUMES_STORY-P-3": "Story-P-3",
    "COSTUMES_STORY-Q-1": "Story-Q-1",
    "COSTUMES_STORY-Q-2": "Story-Q-2",
    "COSTUMES_STORY-Q-3": "Story-Q-3",
    "COSTUMES_STORY-R-1": "Story-R-1",
    "COSTUMES_STORY-R-2": "Story-R-2",
    "COSTUMES_STORY-R-3": "Story-R-3",
    "COSTUMES_STORY-S-1": "Story-S-1",
    "COSTUMES_STORY-S-2": "Story-S-2",
    "COSTUMES_STORY-S-3": "Story-S-3",
    "COSTUMES_STORY-T-1": "Story-T-1",
    "COSTUMES_STORY-T-2": "Story-T-2",
    "COSTUMES_STORY-T-3": "Story-T-3",
    "COSTUMES_STORY-U-1": "Story-U-1",
    "COSTUMES_STORY-U-2": "Story-U-2",
    "COSTUMES_STORY-U-3": "Story-U-3",
    "COSTUMES_STORY-V-1": "Story-V-1",
    "COSTUMES_STORY-V-2": "Story-V-2",
    "COSTUMES_STORY-V-3": "Story-V-3",
    "COSTUMES_STORY-W-1": "Story-W-1",
    "COSTUMES_STORY-W-2": "Story-W-2",
    "COSTUMES_STORY-W-3": "Story-W-3",
    "COSTUMES_STORY-X-1": "Story-X-1",
    "COSTUMES_STORY-X-2": "Story-X-2",
    "COSTUMES_STORY-X-3": "Story-X-3",
    "COSTUMES_STORY-Y-1": "Story-Y-1",
    "COSTUMES_STORY-Y-2": "Story-Y-2",
    "COSTUMES_STORY-Y-3": "Story-Y-3",
    "COSTUMES_STORY-Z-1": "Story-Z-1",
    "COSTUMES_STORY-Z-2": "Story-Z-2",
    "COSTUMES_STORY-Z-3": "Story-Z-3",
    "COSTUMES_STEAMED-STUFFED-BUN": "Steamed Stuffed Bun",
    "COSTUMES_LUCKY-BAG": "Lucky Bag",
    "COSTUMES_DUMPLINGS": "Dumplings",
    "COSTUMES_GOLD-COINS": "Gold Coins",
    "COSTUMES_A-GIFT": "A Gift-A",
    "COSTUMES_STEAMED-BREAD": "Steamed Bread",
    "COSTUMES_PLATES": "Plates",
    "COSTUMES_SILVER-INGOT": "Silver Ingot",
    "COSTUMES_FIRECRACKERS": "Firecrackers",
    "COSTUMES_FIRECRACKERS-1": "Firecrackers 1",
    "COSTUMES_FIRECRACKERS-2": "Firecrackers 2",
    "COSTUMES_FIRECRACKERS-3": "Firecrackers 3",
    "COSTUMES_LANTERN": "Lantern",
    "COSTUMES_LANTERN-1": "Lantern 1",
    "COSTUMES_LANTERN-2": "Lantern 2",
    "COSTUMES_DRUM": "Drum-A",
    "COSTUMES_DRUM-1": "Drum 1",
    "COSTUMES_DRUM-2": "Drum 2",
    "COSTUMES_DRUM-3": "Drum 3",
    "COSTUMES_RED-ENVELOPE": "Red Envelope",
    "COSTUMES_RED-ENVELOPE-1": "Red Envelope 1",
    "COSTUMES_RED-ENVELOPE-2": "Red Envelope 2",
    "COSTUMES_SCROLL": "Scroll",
    "COSTUMES_SCROLL-1": "Scroll 1",
    "COSTUMES_SCROLL-2": "Scroll 2",
    "COSTUMES_SCROLL-3": "Scroll 3",
    "COSTUMES_YETI": "Yeti-A",
    "COSTUMES_YETI-1": "Yeti 1",
    "COSTUMES_YETI-2": "Yeti 2",
    "COSTUMES_FIREWORKS": "Fireworks",
    "COSTUMES_FIREWORKS-1": "Fireworks 1",
    "COSTUMES_FIREWORKS-2": "Fireworks 2",
    "COSTUMES_FIREWORKS-3": "Fireworks 3",
    "COSTUMES_FIREWORKS-4": "Fireworks 4",
    "COSTUMES_FIREWORKS-5": "Fireworks 5",
    "COSTUMES_FIREWORKS-6": "Fireworks 6",
    "COSTUMES_FIREWORKS-7": "Fireworks 7",
    "COSTUMES_FIREWORKS-8": "Fireworks 8",
    "COSTUMES_FIREWORKS-9": "Fireworks 9",
    "SOUNDS_A_BASS": "A Bass",
    "SOUNDS_A_ELEC_BASS": "A Elec Bass",
    "SOUNDS_A_ELEC_GUITAR": "A Elec Guitar",
    "SOUNDS_A_ELEC_PIANO": "A Elec Piano",
    "SOUNDS_A_GUITAR": "A Guitar",
    "SOUNDS_A_MINOR_UKULELE": "A Minor Ukulele",
    "SOUNDS_A_PIANO": "A Piano",
    "SOUNDS_A_SAX": "A Sax",
    "SOUNDS_A_TROMBONE": "A Trombone",
    "SOUNDS_A_TRUMPET": "A Trumpet",
    "SOUNDS_AFRO_STRING": "Afro String",
    "SOUNDS_ALERT": "Alert",
    "SOUNDS_ALIEN_CREAK1": "Alien Creak1",
    "SOUNDS_ALIEN_CREAK2": "Alien Creak2",
    "SOUNDS_B_BASS": "B Bass",
    "SOUNDS_B_ELEC_BASS": "B Elec Bass",
    "SOUNDS_B_ELEC_GUITAR": "B Elec Guitar",
    "SOUNDS_B_ELEC_PIANO": "B Elec Piano",
    "SOUNDS_B_GUITAR": "B Guitar",
    "SOUNDS_B_PIANO": "B Piano",
    "SOUNDS_B_SAX": "B Sax",
    "SOUNDS_B_TROMBONE": "B Trombone",
    "SOUNDS_B_TRUMPET": "B Trumpet",
    "SOUNDS_BAA": "Baa",
    "SOUNDS_BARK": "Bark",
    "SOUNDS_BASKETBALL_BOUNCE": "Basketball Bounce",
    "SOUNDS_BASS_BEATBOX": "Bass Beatbox",
    "SOUNDS_BEAT_BOX1": "Beat Box1",
    "SOUNDS_BEAT_BOX2": "Beat Box2",
    "SOUNDS_BELL_CYMBAL": "Bell Cymbal",
    "SOUNDS_BELL_TOLL": "Bell Toll",
    "SOUNDS_BIG_BOING": "Big Boing",
    "SOUNDS_BIRD": "Bird",
    "SOUNDS_BIRTHDAY": "Birthday",
    "SOUNDS_BITE": "Bite",
    "SOUNDS_BOING": "Boing",
    "SOUNDS_BONK": "Bonk",
    "SOUNDS_BOOM_CLOUD": "Boom Cloud",
    "SOUNDS_BOOP_BING_BOP": "Boop Bing Bop",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": "Bowling Strike",
    "SOUNDS_BUBBLES": "Bubbles",
    "SOUNDS_BUZZ_WHIR": "Buzz Whir",
    "SOUNDS_C_BASS": "C Bass",
    "SOUNDS_C_ELEC_BASS": "C Elec Bass",
    "SOUNDS_C_ELEC_GUITAR": "C Elec Guitar",
    "SOUNDS_C_ELEC_PIANO": "C Elec Piano",
    "SOUNDS_C_GUITAR": "C Guitar",
    "SOUNDS_C_MAJOR_UKULELE": "C Major Ukulele",
    "SOUNDS_C_PIANO": "C Piano",
    "SOUNDS_C_SAX": "C Sax",
    "SOUNDS_C_TROMBONE": "C Trombone",
    "SOUNDS_C_TRUMPET": "C Trumpet",
    "SOUNDS_C2_BASS": "C2 Bass",
    "SOUNDS_C2_ELEC_BASS": "C2 Elec Bass",
    "SOUNDS_C2_ELEC_GUITAR": "C2 Elec Guitar",
    "SOUNDS_C2_ELEC_PIANO": "C2 Elec Piano",
    "SOUNDS_C2_GUITAR": "C2 guitar",
    "SOUNDS_C2_PIANO": "C2 Piano",
    "SOUNDS_C2_SAX": "C2 Sax",
    "SOUNDS_C2_TROMBONE": "C2 Trombone",
    "SOUNDS_C2_TRUMPET": "C2 Trumpet",
    "SOUNDS_CAR_HORN": "Car Horn",
    "SOUNDS_CAR_PASSING": "Car Passing",
    "SOUNDS_CAR_VROOM": "Car Vroom",
    "SOUNDS_CAVE": "Cave",
    "SOUNDS_CHATTER": "Chatter",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "Cheer",
    "SOUNDS_CHILL": "Chill",
    "SOUNDS_CHIRP": "Chirp",
    "SOUNDS_CHOMP": "Chomp",
    "SOUNDS_CHORD": "Chord",
    "SOUNDS_CLANG": "Clang",
    "SOUNDS_CLAP_BEATBOX": "Clap Beatbox",
    "SOUNDS_CLAPPING": "Clapping",
    "SOUNDS_CLASSICAL_PIANO": "Classical Piano",
    "SOUNDS_CLOCK_TICKING": "Clock Ticking",
    "SOUNDS_CLOWN_HONK": "Clown Honk",
    "SOUNDS_COIN": "Coin",
    "SOUNDS_COLLECT": "Collect",
    "SOUNDS_COMPUTER_BEEP": "Computer Beep1",
    "SOUNDS_COMPUTER_BEEP2": "Computer Beep2",
    "SOUNDS_CONNECT": "Connect",
    "SOUNDS_COUGH1": "Cough1",
    "SOUNDS_COUGH2": "Cough2",
    "SOUNDS_CRANK": "Crank",
    "SOUNDS_CRASH_BEATBOX": "Crash Beatbox",
    "SOUNDS_CRASH_CYMBAL": "crash cymbal",
    "SOUNDS_CRAZY_LAUGH": "Crazy Laugh",
    "SOUNDS_CRICKET": "Cricket",
    "SOUNDS_CRICKETS": "Crickets",
    "SOUNDS_CROAK": "Croak",
    "SOUNDS_CROWD_GASP": "Crowd Gasp",
    "SOUNDS_CROWD_LAUGH": "Crowd Laugh",
    "SOUNDS_CRUNCH": "Crunch",
    "SOUNDS_CYMBAL": "Cymbal",
    "SOUNDS_CYMBAL_CRASH": "Cymbal Crash",
    "SOUNDS_CYMBAL_ECHO": "Cymbal Echo",
    "SOUNDS_D_BASS": "D Bass",
    "SOUNDS_D_ELEC_BASS": "D Elec Bass",
    "SOUNDS_D_ELEC_GUITAR": "D Elec Guitar",
    "SOUNDS_D_ELEC_PIANO": "D Elec Piano",
    "SOUNDS_D_GUITAR": "D Guitar",
    "SOUNDS_D_PIANO": "D Piano",
    "SOUNDS_D_SAX": "D Sax",
    "SOUNDS_D_TROMBONE": "D Trombone",
    "SOUNDS_D_TRUMPET": "D Trumpet",
    "SOUNDS_DANCE_AROUND": "Dance Around",
    "SOUNDS_DANCE_CELEBRATE": "Dance Celebrate",
    "SOUNDS_DANCE_CELEBRATE2": "Dance Celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": "Dance Chill Out",
    "SOUNDS_DANCE_ENERGETIC": "Dance Energetic",
    "SOUNDS_DANCE_FUNKY": "Dance Funky",
    "SOUNDS_DANCE_HEAD_NOD": "Dance Head Nod",
    "SOUNDS_DANCE_MAGIC": "Dance Magic",
    "SOUNDS_DANCE_SITAR": "Dance Sitar",
    "SOUNDS_DANCE_SLOW_MO": "Dance Slow Mo",
    "SOUNDS_DANCE_SNARE_BEAT": "Dance Snare Beat",
    "SOUNDS_DANCE_SPACE": "Dance Space",
    "SOUNDS_DISCONNECT": "Disconnect",
    "SOUNDS_DOG1": "Dog",
    "SOUNDS_DOG2": "Dog2",
    "SOUNDS_DOOR_CLOSING": "Door Closing",
    "SOUNDS_DOOR_CREAK": "Door Creak",
    "SOUNDS_DOORBELL": "Doorbell",
    "SOUNDS_DRIP_DROP": "Drip Drop",
    "SOUNDS_DRIVE_AROUND": "Drive Around",
    "SOUNDS_DRUM": "Drum",
    "SOUNDS_DRUM_BASS1": "Drum Bass1",
    "SOUNDS_DRUM_BASS2": "Drum Bass2",
    "SOUNDS_DRUM_BASS3": "Drum Bass3",
    "SOUNDS_DRUM_BOING": "Drum Boing",
    "SOUNDS_DRUM_BUZZ": "Drum Buzz",
    "SOUNDS_DRUM_FUNKY": "Drum Funky",
    "SOUNDS_DRUM_JAM": "Drum Jam",
    "SOUNDS_DRUM_MACHINE": "Drum Machine",
    "SOUNDS_DRUM_ROLL": "Drum Roll",
    "SOUNDS_DRUM_SATELLITE": "Drum Satellite",
    "SOUNDS_DRUM_SET1": "Drum Set1",
    "SOUNDS_DRUM_SET2": "Drum Set2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "Duck",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dunnn",
    "SOUNDS_E_BASS": "E Bass",
    "SOUNDS_E_ELEC_BASS": "E Elec Bass",
    "SOUNDS_E_ELEC_GUITAR": "E Elec Guitar",
    "SOUNDS_E_ELEC_PIANO": "E Elec Piano",
    "SOUNDS_E_GUITAR": "E Guitar",
    "SOUNDS_E_PIANO": "E Piano",
    "SOUNDS_E_SAX": "E Sax",
    "SOUNDS_E_TROMBONE": "E Trombone",
    "SOUNDS_E_TRUMPET": "E Trumpet",
    "SOUNDS_EGGS": "Eggs",
    "SOUNDS_ELEC_PIANO_A_MINOR": "Elec Piano A Minor",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "Elec Piano C Major",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "Elec Piano F Major",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "Elec Piano G Major",
    "SOUNDS_ELEC_PIANO_LOOP": "Elec Piano Loop",
    "SOUNDS_EMOTIONAL_PIANO": "Emotional Piano",
    "SOUNDS_ENGINE": "Engine",
    "SOUNDS_F_BASS": "F Bass",
    "SOUNDS_F_ELEC_BASS": "F Elec Bass",
    "SOUNDS_F_ELEC_GUITAR": "F Elec Guitar",
    "SOUNDS_F_ELEC_PIANO": "F Elec Piano",
    "SOUNDS_F_GUITAR": "F Guitar",
    "SOUNDS_F_MAJOR_UKULELE": "F Major Ukulele",
    "SOUNDS_F_PIANO": "F Piano",
    "SOUNDS_F_SAX": "F Sax",
    "SOUNDS_F_TROMBONE": "F Trombone",
    "SOUNDS_F_TRUMPET": "F Trumpet",
    "SOUNDS_FAIRYDUST": "Fairydust",
    "SOUNDS_FINGER_SNAP": "Finger Snap",
    "SOUNDS_FLAM_SNARE": "Flam Snare",
    "SOUNDS_FOOTSTEPS": "Footsteps",
    "SOUNDS_G_BASS": "G Bass",
    "SOUNDS_G_ELEC_BASS": "G Elec Bass",
    "SOUNDS_G_ELEC_GUITAR": "G Elec Guitar",
    "SOUNDS_G_ELEC_PIANO": "G Elec Piano",
    "SOUNDS_G_GUITAR": "G Guitar",
    "SOUNDS_G_PIANO": "G Piano",
    "SOUNDS_G_SAX": "G Sax",
    "SOUNDS_G_TROMBONE": "G Trombone",
    "SOUNDS_G_TRUMPET": "G Trumpet",
    "SOUNDS_G_UKULELE": "G Ukulele",
    "SOUNDS_GALLOP": "Gallop",
    "SOUNDS_GARDEN": "Garden",
    "SOUNDS_GLASS_BREAKING": "Glass Breaking",
    "SOUNDS_GLUG": "Glug",
    "SOUNDS_GOAL_CHEER": "Goal Cheer",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Goose",
    "SOUNDS_GROWL": "Growl",
    "SOUNDS_GRUNT": "Grunt",
    "SOUNDS_GUITAR_CHORDS1": "Guitar Chords1",
    "SOUNDS_GUITAR_CHORDS2": "Guitar Chords2",
    "SOUNDS_GUITAR_STRUM": "Guitar Strum",
    "SOUNDS_HAND_CLAP": "Hand Clap",
    "SOUNDS_HEAD_SHAKE": "Head Shake",
    "SOUNDS_HEY": "Hey",
    "SOUNDS_HI_BEATBOX": "Hi Beatbox",
    "SOUNDS_HI_NA_TABLA": "Hi Na Tabla",
    "SOUNDS_HI_TUN_TABLA": "Hi Tun Tabla",
    "SOUNDS_HIGH_CONGA": "High Conga",
    "SOUNDS_HIGH_HAT": "High Hat",
    "SOUNDS_HIGH_TOM": "High Tom",
    "SOUNDS_HIGH_WHOOSH": "High Whoosh",
    "SOUNDS_HIHAT_BEATBOX": "Hihat Beatbox",
    "SOUNDS_HIHAT_CYMBAL": "Hihat Cymbal",
    "SOUNDS_HIP_HOP": "Hip Hop",
    "SOUNDS_HORSE": "Horse",
    "SOUNDS_HORSE_GALLOP": "horse gallop",
    "SOUNDS_HUMAN_BEATBOX1": "Human Beatbox1",
    "SOUNDS_HUMAN_BEATBOX2": "Human Beatbox2",
    "SOUNDS_JUMP": "Jump",
    "SOUNDS_JUNGLE": "Jungle",
    "SOUNDS_JUNGLE_FROGS": "Jungle Frogs",
    "SOUNDS_KICK_BACK": "Kick Back",
    "SOUNDS_KICK_DRUM": "Kick Drum",
    "SOUNDS_LARGE_COWBELL": "Large Cowbell",
    "SOUNDS_LASER1": "laser1",
    "SOUNDS_LASER2": "laser2",
    "SOUNDS_LAUGH1": "Laugh1",
    "SOUNDS_LAUGH2": "Laugh2",
    "SOUNDS_LAUGH3": "Laugh3",
    "SOUNDS_LO_GEH_TABLA": "Lo Geh Tabla",
    "SOUNDS_LO_GLISS_TABLA": "Lo Gliss Tabla",
    "SOUNDS_LOSE": "Lose",
    "SOUNDS_LOW_BOING": "Low Boing",
    "SOUNDS_LOW_CONGA": "Low Conga",
    "SOUNDS_LOW_SQUEAK": "Low Squeak",
    "SOUNDS_LOW_TOM": "Low Tom",
    "SOUNDS_LOW_WHOOSH": "Low Whoosh",
    "SOUNDS_MACHINE": "Machine",
    "SOUNDS_MAGIC_SPELL": "Magic Spell",
    "SOUNDS_MEDIEVAL1": "Medieval1",
    "SOUNDS_MEDIEVAL2": "Medieval2",
    "SOUNDS_MEOW": "Meow",
    "SOUNDS_MEOW2": "meow2",
    "SOUNDS_MOO": "Moo",
    "SOUNDS_MOTORCYCLE_PASSING": "Motorcycle Passing",
    "SOUNDS_MOVIE_1": "Movie 1",
    "SOUNDS_MOVIE_2": "Movie 2",
    "SOUNDS_MUTED_CONGA": "Muted Conga",
    "SOUNDS_MYSTERY": "Mystery",
    "SOUNDS_OCEAN_WAVE": "Ocean Wave",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "Oops",
    "SOUNDS_ORCHESTRA_TUNING": "Orchestra Tuning",
    "SOUNDS_OWL": "Owl",
    "SOUNDS_PARTY_NOISE": "Party Noise",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": "Ping Pong Hit",
    "SOUNDS_PLUCK": "Pluck",
    "SOUNDS_PLUNGE": "Plunge",
    "SOUNDS_POLICE_SIREN": "Police Siren",
    "SOUNDS_POP": "Pop",
    "SOUNDS_RAIN": "Rain",
    "SOUNDS_RATTLE": "Rattle",
    "SOUNDS_REFEREE_WHISTLE": "Referee Whistle",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "Ricochet",
    "SOUNDS_RIDE_CYMBAL": "Ride Cymbal",
    "SOUNDS_RING_TONE": "Ring Tone",
    "SOUNDS_RIP": "Rip",
    "SOUNDS_RIPPLES": "Ripples",
    "SOUNDS_ROLL_CYMBAL": "Roll Cymbal",
    "SOUNDS_ROOSTER": "Rooster",
    "SOUNDS_SCRAMBLING_FEET": "Scrambling Feet",
    "SOUNDS_SCRATCH_BEATBOX": "scratch beatbox",
    "SOUNDS_SCRATCHY_BEAT": "Scratchy Beat",
    "SOUNDS_SCREAM1": "Scream1",
    "SOUNDS_SCREAM2": "Scream2",
    "SOUNDS_SCREECH": "Screech",
    "SOUNDS_SEAGULLS": "Seagulls",
    "SOUNDS_SEWING_MACHINE": "Sewing Machine",
    "SOUNDS_SHAKER": "Shaker",
    "SOUNDS_SHIP_BELL": "Ship Bell",
    "SOUNDS_SIDESTICK_SNARE": "Sidestick Snare",
    "SOUNDS_SINGER1": "Singer1",
    "SOUNDS_SINGER2": "Singer2",
    "SOUNDS_SIREN_WHISTLE": "Siren Whistle",
    "SOUNDS_SKID": "Skid",
    "SOUNDS_SLIDE_WHISTLE": "Slide Whistle",
    "SOUNDS_SMALL_COWBELL": "Small Cowbell",
    "SOUNDS_SNAP": "Snap",
    "SOUNDS_SNARE_BEATBOX": "Snare Beatbox",
    "SOUNDS_SNARE_BEATBOX2": "Snare Beatbox2",
    "SOUNDS_SNARE_DRUM": "Snare Drum",
    "SOUNDS_SNEAKER_SQUEAK": "Sneaker Squeak",
    "SOUNDS_SNEEZE1": "Sneeze1",
    "SOUNDS_SNEEZE2": "Sneeze2",
    "SOUNDS_SNORING": "Snoring",
    "SOUNDS_SNORT": "Snort",
    "SOUNDS_SPACE_AMBIENCE": "Space Ambience",
    "SOUNDS_SPACE_FLYBY": "Space Flyby",
    "SOUNDS_SPACE_NOISE": "Space Noise",
    "SOUNDS_SPACE_RIPPLE": "Space Ripple",
    "SOUNDS_SPIRAL": "Spiral",
    "SOUNDS_SPLASH": "Splash",
    "SOUNDS_SPLASH_CYMBAL": "Splash Cymbal",
    "SOUNDS_SPOOKY_STRING": "Spooky String",
    "SOUNDS_SQUAWK": "Squawk",
    "SOUNDS_SQUEAKS": "Squeaks",
    "SOUNDS_SQUEAKY_TOY": "Squeaky Toy",
    "SOUNDS_SQUISH_POP": "Squish Pop",
    "SOUNDS_STRING_ACCENT": "String Accent",
    "SOUNDS_STRING_PLUCK": "String Pluck",
    "SOUNDS_SUCTION_CUP": "Suction Cup",
    "SOUNDS_SUSPENSE": "Suspense",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura",
    "SOUNDS_TAP_CONGA": "Tap Conga",
    "SOUNDS_TAP_SNARE": "Tap Snare",
    "SOUNDS_TECHNO": "Techno",
    "SOUNDS_TECHNO2": "Techno2",
    "SOUNDS_TELEPHONE_RING": "Telephone Ring",
    "SOUNDS_TELEPHONE_RING2": "Telephone Ring2",
    "SOUNDS_TELEPORT": "Teleport",
    "SOUNDS_TELEPORT2": "Teleport2",
    "SOUNDS_TELEPORT3": "Teleport3",
    "SOUNDS_TENNIS_HIT": "Tennis Hit",
    "SOUNDS_THUNDER_STORM": "Thunder Storm",
    "SOUNDS_TOM_DRUM": "Tom Drum",
    "SOUNDS_TOY_HONK": "Toy Honk",
    "SOUNDS_TOY_ZING": "Toy Zing",
    "SOUNDS_TRAFFIC": "Traffic",
    "SOUNDS_TRAIN_WHISTLE": "Train Whistle",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_TRIUMPH": "Triumph",
    "SOUNDS_TROPICAL_BIRDS": "Tropical Birds",
    "SOUNDS_TRUMPET1": "Trumpet1",
    "SOUNDS_TRUMPET2": "Trumpet2",
    "SOUNDS_VIDEO_GAME_1": "Video Game 1",
    "SOUNDS_VIDEO_GAME_2": "Video Game 2",
    "SOUNDS_WAH_BEATBOX": "Wah Beatbox",
    "SOUNDS_WAND": "Wand",
    "SOUNDS_WATER_DROP": "Water drop",
    "SOUNDS_WHINNY": "Whinny",
    "SOUNDS_WHISTLE_THUMP": "Whistle Thump",
    "SOUNDS_WHIZ": "Whiz",
    "SOUNDS_WHOOP": "Whoop",
    "SOUNDS_WIN": "Win",
    "SOUNDS_WOBBLE": "Wobble",
    "SOUNDS_WOLF_HOWL": "Wolf Howl",
    "SOUNDS_WOOD_TAP": "Wood Tap",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "Xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_APPLE": "Apple",
    "SPRITES_ARROW1": "Arrow1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Walking",
    "SPRITES_BALL": "Ball",
    "SPRITES_BALLERINA": "Ballerina",
    "SPRITES_BALLOON1": "Balloon1",
    "SPRITES_BANANAS": "Bananas",
    "SPRITES_BASEBALL": "Baseball",
    "SPRITES_BASKETBALL": "Basketball",
    "SPRITES_BAT": "Bat",
    "SPRITES_BATTER": "Batter",
    "SPRITES_BEACHBALL": "Beachball",
    "SPRITES_BEAR": "Bear",
    "SPRITES_BEAR-WALKING": "Bear-walking",
    "SPRITES_BEETLE": "Beetle",
    "SPRITES_BELL": "Bell",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Bowl",
    "SPRITES_BOWTIE": "Bowtie",
    "SPRITES_BREAD": "Bread",
    "SPRITES_BROOM": "Broom",
    "SPRITES_BUILDINGS": "Buildings",
    "SPRITES_BUTTERFLY_1": "Butterfly 1",
    "SPRITES_BUTTERFLY_2": "Butterfly 2",
    "SPRITES_BUTTON1": "Button1",
    "SPRITES_BUTTON2": "Button2",
    "SPRITES_BUTTON3": "Button3",
    "SPRITES_BUTTON4": "Button4",
    "SPRITES_BUTTON5": "Button5",
    "SPRITES_CAKE": "Cake",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Cat 2",
    "SPRITES_CATCHER": "Catcher",
    "SPRITES_CENTAUR": "Centaur",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Chick",
    "SPRITES_CITY_BUS": "City Bus",
    "SPRITES_CLOUD": "Cloud",
    "SPRITES_CLOUDS": "Clouds",
    "SPRITES_CONVERTIBLE": "Convertible",
    "SPRITES_CONVERTIBLE_2": "Convertible 2",
    "SPRITES_CRAB": "Crab",
    "SPRITES_CRYSTAL": "Crystal",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "Dinosaur1",
    "SPRITES_DINOSAUR2": "Dinosaur2",
    "SPRITES_DINOSAUR3": "Dinosaur3",
    "SPRITES_DINOSAUR4": "Dinosaur4",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DIVER1": "Diver1",
    "SPRITES_DIVER2": "Diver2",
    "SPRITES_DOG1": "Dog1",
    "SPRITES_DOG2": "Dog2",
    "SPRITES_DONUT": "Donut",
    "SPRITES_DORIAN": "Dorian",
    "SPRITES_DOT": "Dot",
    "SPRITES_DOVE": "Dove",
    "SPRITES_DRAGON": "Dragon",
    "SPRITES_DRAGONFLY": "Dragonfly",
    "SPRITES_DRESS": "Dress",
    "SPRITES_DRUM": "Drum",
    "SPRITES_DRUM_KIT": "Drum Kit",
    "SPRITES_DRUM-CYMBAL": "Drum-cymbal",
    "SPRITES_DRUM-HIGHHAT": "Drum-highhat",
    "SPRITES_DRUM-SNARE": "Drum-snare",
    "SPRITES_DRUMS_CONGA": "Drums Conga",
    "SPRITES_DRUMS_TABLA": "Drums Tabla",
    "SPRITES_DUCK": "Duck",
    "SPRITES_EARTH": "Earth",
    "SPRITES_EASEL": "Easel",
    "SPRITES_EGG": "Egg",
    "SPRITES_ELEPHANT": "Elephant",
    "SPRITES_ELF": "Elf",
    "SPRITES_FAIRY": "Fairy",
    "SPRITES_FISH": "Fish",
    "SPRITES_FISHBOWL": "Fishbowl",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Football",
    "SPRITES_FORTUNE_COOKIE": "Fortune Cookie",
    "SPRITES_FOX": "Fox",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "Frog",
    "SPRITES_FROG_2_": "Frog 2 ",
    "SPRITES_FRUIT_PLATTER": "Fruit Platter",
    "SPRITES_FRUIT_SALAD": "Fruit Salad",
    "SPRITES_GHOST": "Ghost",
    "SPRITES_GIFT": "Gift",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GLASS_WATER": "Glass Water",
    "SPRITES_GLASSES": "Glasses",
    "SPRITES_GOALIE": "Goalie",
    "SPRITES_GOBLIN": "Goblin",
    "SPRITES_GRASSHOPPER": "Grasshopper",
    "SPRITES_GREEN_FLAG": "Green Flag",
    "SPRITES_GRIFFIN": "Griffin",
    "SPRITES_GUITAR": "Guitar",
    "SPRITES_GUITAR-ELECTRIC1": "Guitar-electric1",
    "SPRITES_GUITAR-ELECTRIC2": "Guitar-electric2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hare",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hat1 ",
    "SPRITES_HATCHLING": "Hatchling",
    "SPRITES_HEART": "Heart",
    "SPRITES_HEART_CANDY": "Heart Candy",
    "SPRITES_HEART_FACE": "Heart Face",
    "SPRITES_HEDGEHOG": "Hedgehog",
    "SPRITES_HEN": "Hen",
    "SPRITES_HIPPO1": "Hippo1",
    "SPRITES_HOME_BUTTON": "Home Button",
    "SPRITES_HORSE": "Horse",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "Jar",
    "SPRITES_JELLYFISH": "Jellyfish",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "Key",
    "SPRITES_KEYBOARD": "Keyboard",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Knight",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_LADYBUG1": "Ladybug1",
    "SPRITES_LADYBUG2": "Ladybug2",
    "SPRITES_LAPTOP": "Laptop",
    "SPRITES_LIGHTNING": "Lightning",
    "SPRITES_LINE": "Line",
    "SPRITES_LION": "Lion",
    "SPRITES_LLAMA": "Llama",
    "SPRITES_MAGIC_WAND": "Magic Wand",
    "SPRITES_MAX": "Max",
    "SPRITES_MERMAID": "Mermaid",
    "SPRITES_MICROPHONE": "Microphone",
    "SPRITES_MILK": "Milk",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Monkey",
    "SPRITES_MOTORCYCLE": "Motorcycle",
    "SPRITES_MOUSE1": "Mouse1",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": "Neigh Pony",
    "SPRITES_OCTOPUS": "Octopus",
    "SPRITES_ORANGE": "Orange",
    "SPRITES_ORANGE2": "Orange2",
    "SPRITES_OUTFIELDER": "Outfielder",
    "SPRITES_OWL": "Owl",
    "SPRITES_PADDLE": "Paddle",
    "SPRITES_PANTHER": "Panther",
    "SPRITES_PANTS": "Pants",
    "SPRITES_PARROT": "Parrot",
    "SPRITES_PARTY_HATS": "Party Hats",
    "SPRITES_PENCIL": "Pencil",
    "SPRITES_PENGUIN": "Penguin",
    "SPRITES_PENGUIN_2": "Penguin 2",
    "SPRITES_PITCHER": "Pitcher",
    "SPRITES_PLANET2": "Planet2",
    "SPRITES_POLAR_BEAR": "Polar Bear",
    "SPRITES_POTION": "Potion",
    "SPRITES_PRINCE": "Prince",
    "SPRITES_PRINCESS": "Princess",
    "SPRITES_PUFFERFISH": "Pufferfish",
    "SPRITES_PUPPY": "Puppy",
    "SPRITES_RABBIT": "Rabbit",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": "Rainbow",
    "SPRITES_REFEREE": "Referee",
    "SPRITES_REINDEER": "Reindeer",
    "SPRITES_RETRO_ROBOT": "Retro Robot",
    "SOUNDS_COMPUTER_BEEPS1": "computer beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "computer beeps2",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Robot",
    "SPRITES_ROCKETSHIP": "Rocketship",
    "SPRITES_ROCKS": "Rocks",
    "SPRITES_ROOSTER": "Rooster",
    "SPRITES_RUBY": "Ruby",
    "SPRITES_SAILBOAT": "Sailboat",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "Saxophone",
    "SPRITES_SCARF": "Scarf",
    "SPRITES_SHARK": "Shark",
    "SPRITES_SHARK_2": "Shark 2",
    "SPRITES_SHIRT": "Shirt",
    "SPRITES_SHOES": "Shoes",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "Singer1",
    "SPRITES_SKELETON": "Skeleton",
    "SPRITES_SNAKE": "Snake",
    "SPRITES_SNOWFLAKE": "Snowflake",
    "SPRITES_SNOWMAN": "Snowman",
    "SPRITES_SOCCER_BALL": "Soccer Ball",
    "SPRITES_SPEAKER": "Speaker",
    "SPRITES_SQUIRREL": "Squirrel",
    "SPRITES_STAR": "Star",
    "SPRITES_STARFISH": "Starfish",
    "SPRITES_STOP": "Stop",
    "SPRITES_STRAWBERRY": "Strawberry",
    "SPRITES_SUN": "Sun",
    "SPRITES_SUNGLASSES1": "Sunglasses1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Takeout",
    "SPRITES_TEN80_DANCE": "Ten80 Dance",
    "SPRITES_TENNIS_BALL": "Tennis Ball",
    "SPRITES_TOUCAN": "Toucan",
    "SPRITES_TRAMPOLINE": "Trampoline",
    "SPRITES_TREE1": "Tree1",
    "SPRITES_TREES": "Trees",
    "SPRITES_TRUCK": "Truck",
    "SPRITES_TRUMPET": "Trumpet",
    "SPRITES_UNICORN": "Unicorn",
    "SPRITES_UNICORN_2": "Unicorn 2",
    "SPRITES_UNICORN_RUNNING": "Unicorn Running",
    "SPRITES_WAND": "Wand",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Watermelon",
    "SPRITES_WINTER_HAT": "Winter Hat",
    "SPRITES_WITCH": "Witch",
    "SPRITES_WIZARD": "Wizard",
    "SPRITES_WIZARD_HAT": "Wizard Hat",
    "SPRITES_WIZARD-TOAD": "Wizard-toad",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "Block-A",
    "SPRITES_BLOCK-B": "Block-B",
    "SPRITES_BLOCK-C": "Block-C",
    "SPRITES_BLOCK-D": "Block-D",
    "SPRITES_BLOCK-E": "Block-E",
    "SPRITES_BLOCK-F": "Block-F",
    "SPRITES_BLOCK-G": "Block-G",
    "SPRITES_BLOCK-H": "Block-H",
    "SPRITES_BLOCK-I": "Block-I",
    "SPRITES_BLOCK-J": "Block-J",
    "SPRITES_BLOCK-K": "Block-K",
    "SPRITES_BLOCK-L": "Block-L",
    "SPRITES_BLOCK-M": "Block-M",
    "SPRITES_BLOCK-N": "Block-N",
    "SPRITES_BLOCK-O": "Block-O",
    "SPRITES_BLOCK-P": "Block-P",
    "SPRITES_BLOCK-Q": "Block-Q",
    "SPRITES_BLOCK-R": "Block-R",
    "SPRITES_BLOCK-S": "Block-S",
    "SPRITES_BLOCK-T": "Block-T",
    "SPRITES_BLOCK-U": "Block-U",
    "SPRITES_BLOCK-V": "Block-V",
    "SPRITES_BLOCK-W": "Block-W",
    "SPRITES_BLOCK-X": "Block-X",
    "SPRITES_BLOCK-Y": "Block-Y",
    "SPRITES_BLOCK-Z": "Block-Z",
    "SPRITES_GLOW-0": "Glow-0",
    "SPRITES_GLOW-1": "Glow-1",
    "SPRITES_GLOW-2": "Glow-2",
    "SPRITES_GLOW-3": "Glow-3",
    "SPRITES_GLOW-4": "Glow-4",
    "SPRITES_GLOW-5": "Glow-5",
    "SPRITES_GLOW-6": "Glow-6",
    "SPRITES_GLOW-7": "Glow-7",
    "SPRITES_GLOW-8": "Glow-8",
    "SPRITES_GLOW-9": "Glow-9",
    "SPRITES_GLOW-A": "Glow-A",
    "SPRITES_GLOW-B": "Glow-B",
    "SPRITES_GLOW-C": "Glow-C",
    "SPRITES_GLOW-D": "Glow-D",
    "SPRITES_GLOW-E": "Glow-E",
    "SPRITES_GLOW-F": "Glow-F",
    "SPRITES_GLOW-G": "Glow-G",
    "SPRITES_GLOW-H": "Glow-H",
    "SPRITES_GLOW-I": "Glow-I",
    "SPRITES_GLOW-J": "Glow-J",
    "SPRITES_GLOW-K": "Glow-K",
    "SPRITES_GLOW-L": "Glow-L",
    "SPRITES_GLOW-M": "Glow-M",
    "SPRITES_GLOW-N": "Glow-N",
    "SPRITES_GLOW-O": "Glow-O",
    "SPRITES_GLOW-P": "Glow-P",
    "SPRITES_GLOW-Q": "Glow-Q",
    "SPRITES_GLOW-R": "Glow-R",
    "SPRITES_GLOW-S": "Glow-S",
    "SPRITES_GLOW-T": "Glow-T",
    "SPRITES_GLOW-U": "Glow-U",
    "SPRITES_GLOW-V": "Glow-V",
    "SPRITES_GLOW-W": "Glow-W",
    "SPRITES_GLOW-X": "Glow-X",
    "SPRITES_GLOW-Y": "Glow-Y",
    "SPRITES_GLOW-Z": "Glow-Z",
    "SPRITES_STORY-A": "Story-A",
    "SPRITES_STORY-B": "Story-B",
    "SPRITES_STORY-C": "Story-C",
    "SPRITES_STORY-D": "Story-D",
    "SPRITES_STORY-E": "Story-E",
    "SPRITES_STORY-F": "Story-F",
    "SPRITES_STORY-G": "Story-G",
    "SPRITES_STORY-H": "Story-H",
    "SPRITES_STORY-I": "Story-I",
    "SPRITES_STORY-J": "Story-J",
    "SPRITES_STORY-K": "Story-K",
    "SPRITES_STORY-L": "Story-L",
    "SPRITES_STORY-M": "Story-M",
    "SPRITES_STORY-N": "Story-N",
    "SPRITES_STORY-O": "Story-O",
    "SPRITES_STORY-P": "Story-P",
    "SPRITES_STORY-Q": "Story-Q",
    "SPRITES_STORY-R": "Story-R",
    "SPRITES_STORY-S": "Story-S",
    "SPRITES_STORY-T": "Story-T",
    "SPRITES_STORY-U": "Story-U",
    "SPRITES_STORY-V": "Story-V",
    "SPRITES_STORY-W": "Story-W",
    "SPRITES_STORY-X": "Story-X",
    "SPRITES_STORY-Y": "Story-Y",
    "SPRITES_STORY-Z": "Story-Z",
    "SPRITES_MIND+2022_01_CIVIL-AIRCRAFT": "Civil Aviation Aircraft",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT1": "Civil Aviation Aircraft-Front",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT2": "Civil Aviation - Side",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT3": "Civil Aviation Aircraft-Top",
    "SPRITES_MIND+2022_02_AEROSPACEPLANE": "Space Shuttle",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE1": "Space Shuttle - Front",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE2": "Space Shuttle-Side",
    "SPRITES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM": "Space Shuttle Cabin",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM1": "Space Shuttle Cabin-Front",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM2": "Space Shuttle Cabin-Side",
    "SPRITES_MIND+2022_04_TRAFFIC-LIGHT": "Expression traffic lights",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT1": "Expression traffic light-red",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT2": "Expression traffic light-yellow",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT3": "Expression traffic light-green",
    "SPRITES_MIND+2022_05_FLYBROW": "Airship",
    "COSTUMES_MIND+2022_05_FLYBROW1": "Airship-Side",
    "COSTUMES_MIND+2022_05_FLYBROW2": "Airship-Look Up",
    "SPRITES_MIND+2022_06_HIGHSPEED-RAIL": "High Speed ​​Rail",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL1": "High Speed ​​Rail-Front",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL2": "High Speed ​​Rail-Side",
    "SPRITES_MIND+2022_07_POLICECAR": "Police Car",
    "COSTUMES_MIND+2022_07_POLICECAR1": "Police Car-Sport 1",
    "COSTUMES_MIND+2022_07_POLICECAR2": "Police Car-Sport 2",
    "COSTUMES_MIND+2022_07_POLICECAR3": "Police Car - Sports 3",
    "COSTUMES_MIND+2022_07_POLICECAR4": "Police Car - Sports 4",
    "COSTUMES_MIND+2022_07_POLICECAR5": "Police Car-Light 1",
    "COSTUMES_MIND+2022_07_POLICECAR6": "Police Car-Lights 2",
    "COSTUMES_MIND+2022_07_POLICECAR7": "Police Car - Stationary",
    "SPRITES_MIND+2022_08_CAR": "Car",
    "COSTUMES_MIND+2022_08_CAR1": "Car-1",
    "COSTUMES_MIND+2022_08_CAR2": "Car-2",
    "COSTUMES_MIND+2022_08_CAR3": "Car-3",
    "COSTUMES_MIND+2022_08_CAR4": "Car-4",
    "COSTUMES_MIND+2022_08_CAR5": "Car-5",
    "COSTUMES_MIND+2022_08_CAR6": "Car-6",
    "SPRITES_MIND+2022_09_DININGCAR": "Dining Car",
    "SPRITES_MIND+2022_10_BUS": "Bus",
    "SPRITES_MIND+2022_11_GO-KART": "Kart",
    "SPRITES_MIND+2022_12_MOTORBIKE": "Motorcycle",
    "SPRITES_MIND+2022_13_TAXI": "Taxi",
    "COSTUMES_MIND+2022_13_TAXI01": "Taxi - Front Side",
    "COSTUMES_MIND+2022_13_TAXI02": "Taxi-Side",
    "COSTUMES_MIND+2022_13_TAXI03": "Taxi - Turn off the lights on the front and side",
    "COSTUMES_MIND+2022_13_TAXI04": "Taxi - Front side lights on 1",
    "COSTUMES_MIND+2022_13_TAXI05": "Taxi - Lights on the front side 2",
    "COSTUMES_MIND+2022_13_TAXI06": "Taxi-Front",
    "SPRITES_MIND+2022_14_PUMPKINCAR": "Pumpkin Car",
    "COSTUMES_MIND+2022_14_PUMPKINCAR1": "Pumpkin Car-1",
    "COSTUMES_MIND+2022_14_PUMPKINCAR2": "Pumpkin Car-2",
    "COSTUMES_MIND+2022_14_PUMPKINCAR3": "Pumpkin Car-3",
    "COSTUMES_MIND+2022_14_PUMPKINCAR4": "Pumpkin Car - Door Open",
    "COSTUMES_MIND+2022_14_PUMPKINCAR5": "Pumpkin Car-Night",
    "SPRITES_MIND+2022_15_LITTLESAILBOAT": "Little Sailing",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT1": "Little Sailing-Side",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT2": "Little Sailing-Top Side",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT3": "Sailboat-Sloping Top Side",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT4": "Sailboat-Sloping Top Side 1",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT5": "Sailboat-Sloping Top Side 2",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT6": "Sailboat-Sloping Top Side 3",
    "SPRITES_MIND+2022_16_RUBBLERACER": "Small spaceship",
    "COSTUMES_MIND+2022_16_RUBBLERACER1": "Little Spaceship-01",
    "COSTUMES_MIND+2022_16_RUBBLERACER2": "Little Spaceship-02",
    "COSTUMES_MIND+2022_16_RUBBLERACER3": "Little Spaceship-03",
    "SPRITES_MIND+2022_17_BARNEY": "Small cart",
    "COSTUMES_MIND+2022_17_BARNEY1": "Mini Cart - Side Full",
    "COSTUMES_MIND+2022_17_BARNEY2": "Mining Cart - Top Side",
    "COSTUMES_MIND+2022_17_BARNEY3": "Mining Cart - Top Side 2",
    "COSTUMES_MIND+2022_17_BARNEY4": "Mining Cart - Top Side 3",
    "COSTUMES_MIND+2022_17_BARNEY5": "Small minecart - top side empty 1",
    "SPRITES_MIND+2022_18_UTILITYCART": "Cart",
    "COSTUMES_MIND+2022_18_UTILITYCART1": "Cart-Side",
    "COSTUMES_MIND+2022_18_UTILITYCART2": "Cart - Top Side",
    "COSTUMES_MIND+2022_18_UTILITYCART3": "Cart-top side full 1",
    "COSTUMES_MIND+2022_18_UTILITYCART4": "Cart - Top Side Full 2",
    "COSTUMES_MIND+2022_18_UTILITYCART5": "Cart-top side full 3",
    "SPRITES_MIND+2022_19_BIKE": "Bike",
    "COSTUMES_MIND+2022_19_BIKE1": "Bike-Side 1",
    "COSTUMES_MIND+2022_19_BIKE2": "Bike-Side 2",
    "COSTUMES_MIND+2022_19_BIKE3": "Bike-Side 3",
    "COSTUMES_MIND+2022_19_BIKE4": "Bike-Side 4",
    "COSTUMES_MIND+2022_19_BIKE5": "Bike-Front",
    "SPRITES_MIND+2022_20_RACECAR": "Race Car",
    "COSTUMES_MIND+2022_20_RACECAR1": "Race-Side",
    "COSTUMES_MIND+2022_20_RACECAR2": "Blue Race Car-Top 1",
    "COSTUMES_MIND+2022_20_RACECAR3": "Blue Race Car - Top 2",
    "COSTUMES_MIND+2022_20_RACECAR4": "Blue Race Car - Top 3",
    "COSTUMES_MIND+2022_20_RACECAR5": "Yellow Race Car - Top 1",
    "COSTUMES_MIND+2022_20_RACECAR6": "Yellow Race Car - Top 2",
    "COSTUMES_MIND+2022_20_RACECAR7": "Yellow Race Car - Top 3",
    "COSTUMES_MIND+2022_20_RACECAR8": "Pink Race Car-Top 1",
    "COSTUMES_MIND+2022_20_RACECAR9": "Pink Race Car - Top 2",
    "COSTUMES_MIND+2022_20_RACECAR10": "Pink Race Car-Top 3",
    "SPRITES_MIND+2022_21_METEORITES1": "Meteorite",
    "COSTUMES_MIND+2022_21_METEORITES1_1": "Meteorite 1-01",
    "COSTUMES_MIND+2022_21_METEORITES1_2": "Meteorite 1-02",
    "COSTUMES_MIND+2022_21_METEORITES1_3": "Meteorite 1-03",
    "SPRITES_MIND+2022_22_METEORITES2": "Meteorite 2",
    "COSTUMES_MIND+2022_22_METEORITES2_1": "Meteorite 2-01",
    "COSTUMES_MIND+2022_22_METEORITES2_2": "Meteorite 2-02",
    "COSTUMES_MIND+2022_22_METEORITES2_3": "Meteorite 2-03",
    "SPRITES_MIND+2022_23_METEORITES3": "Meteorite 3",
    "COSTUMES_MIND+2022_23_METEORITES3_1": "Meteorite 3-01",
    "COSTUMES_MIND+2022_23_METEORITES3_2": "Meteorite 3-02",
    "COSTUMES_MIND+2022_23_METEORITES3_3": "Meteorite 3-03",
    "SPRITES_MIND+2022_24_METEORITES4": "Meteorite 4",
    "COSTUMES_MIND+2022_24_METEORITES4_1": "Meteorite 4-01",
    "COSTUMES_MIND+2022_24_METEORITES4_2": "Meteorite 4-02",
    "COSTUMES_MIND+2022_24_METEORITES4_3": "Meteorite 4-03",
    "COSTUMES_MIND+2022_24_METEORITES4_4": "Meteorite 4-04",
    "SPRITES_MIND+2022_25_METEORITES5": "Meteorite 5",
    "COSTUMES_MIND+2022_25_METEORITES5_1": "Meteorite 5-01",
    "COSTUMES_MIND+2022_25_METEORITES5_2": "Meteorite 5-02",
    "COSTUMES_MIND+2022_25_METEORITES5_3": "Meteorite 5-03",
    "COSTUMES_MIND+2022_25_METEORITES5_4": "Meteorite 5-04",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_01": "Meteorite Adventure A-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_02": "Meteorite Adventure A-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_01": "Meteorite Adventure B-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_02": "Meteorite Adventure B-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_03": "Meteorite Adventure B-3",
    "MIND+_FACE_SIDE": "Mind+ facial expression - side",
    "MIND+_FACE_SIDE-1": "Mind+ facial expression - side-1",
    "MIND+_FACE_SIDE-2": "Mind+ facial expression - side-2",
    "MIND+_FACE_SIDE-3": "Mind+ facial expression - side-3",
    "MIND+_FACE_SIDE-4": "Mind+ facial expression - side-4",
    "MIND+_FACE_SIDE-5": "Mind+ facial expression - side-5",
    "MIND+_FACE_SIDE-6": "Mind+ facial expression - side-6",
    "MIND+_FACE_SIDE-7": "Mind+ facial expression - side-7",
    "MIND+_FACE_SIDE-8": "Mind+ facial expression - side-8",
    "MIND+_FACE_SIDE-9": "Mind+ facial expression - side-9",
    "MIND+_FACE_SIDE-10": "Mind+ facial expression - side-10",
    "MIND+_FACE_POSITIVE": "Mind+ facial expression - front",
    "MIND+_FACE_POSITIVE-1": "Mind+ facial expression - front-1",
    "MIND+_FACE_POSITIVE-2": "Mind+ facial expression - front-2",
    "MIND+_FACE_POSITIVE-3": "Mind+ facial expression - front-3",
    "MIND+_FACE_POSITIVE-4": "Mind+ facial expression - front-4",
    "MIND+_FACE_POSITIVE-5": "Mind+ facial expression - front-5",
    "MIND+_FACE_POSITIVE-6": "Mind+ facial expression - front-6",
    "MIND+_FACE_POSITIVE-7": "Mind+ facial expression - front-7",
    "MIND+_FACE_POSITIVE-8": "Mind+ facial expression - front-8",
    "MIND+_FACE_POSITIVE-9": "Mind+ facial expression - front-9",
    "MIND+_FACE_POSITIVE-10": "Mind+ facial expression - front-10",
    "MIND+_DOZE": "doze",
    "MIND+_DOZE-1": "doze-1",
    "MIND+_DOZE-2": "doze-2",
    "MIND+_DOZE-3": "doze-3",
    "MIND+_SQUAT": "squat & rise",
    "MIND+_SQUAT-1": "squat & rise-1",
    "MIND+_SQUAT-2": "squat & rise-2",
    "MIND+_SQUAT-3": "squat & rise-3",
    "MIND+_ARCHITECT": "Mind+ architect",
    "MIND+_ARCHITECT-1": "Mind+ architect-1",
    "MIND+_ARCHITECT-2": "Mind+ architect-2",
    "MIND+_ARCHITECT-3": "Mind+ architect-3",
    "MIND+_TRAFFIC_POLICE": "Mind+ traffic officer",
    "MIND+_TRAFFIC_POLICE-1": "Mind+ traffic officer-1",
    "MIND+_TRAFFIC_POLICE-2": "Mind+ traffic officer-2",
    "MIND+_TRAFFIC_POLICE-3": "Mind+ traffic officer-3",
    "MIND+_TRAFFIC_POLICE-4": "Mind+ traffic officer-4",
    "MIND+_TRAFFIC_POLICE-5": "Mind+ traffic officer-5",
    "MIND+_TRAFFIC_POLICE-6": "Mind+ traffic officer-6",
    "MIND+_POLICE": "Mind+ police officer",
    "MIND+_POLICE-1": "Mind+ police officer-1",
    "MIND+_POLICE-2": "Mind+ police officer-2",
    "MIND+_POLICE-3": "Mind+ police officer-3",
    "MIND+_POLICE-4": "Mind+ police officer-4",
    "MIND+_POLICE-5": "Mind+ police officer-5",
    "MIND+_POLICE-6": "Mind+ police officer-trousers-1",
    "MIND+_POLICE-7": "Mind+ police officer-trousers-2",
    "MIND+_SCIENTIST": "Mind+ scientist",
    "MIND+_SCIENTIST-1": "Mind+ scientist-1",
    "MIND+_SCIENTIST-2": "Mind+ scientist-2",
    "MIND+_SCIENTIST-3": "Mind+ scientist-3",
    "MIND+_SANTA_CLAUS": "Mind+ Santa Claus",
    "MIND+_SANTA_CLAUS-1": "Mind+ Santa Claus-wave hand",
    "MIND+_SANTA_CLAUS-2": "Mind+ Santa Claus-give presents",
    "MIND+_SANTA_CLAUS-3": "Mind+ Santa Claus-dance",
    "MIND+_FIREMAN": "Mind+ fireman",
    "MIND+_FIREMAN-1": "Mind+ fireman-1",
    "MIND+_FIREMAN-2": "Mind+ fireman-2",
    "MIND+_FIREMAN-3": "Mind+ fireman-3",
    "MIND+_FIREMAN-4": "Mind+ fireman-4",
    "MIND+_DOCTOR": "Mind+ doctor",
    "MIND+_DOCTOR-1": "Mind+ doctor-1",
    "MIND+_DOCTOR-2": "Mind+ doctor-2",
    "MIND+_DOCTOR-3": "Mind+ doctor-3",
    "MIND+_ASTRONAUT_SPACECRAFT": "Mind+ astronaut - spacecraft",
    "MIND+_ASTRONAUT_SPACECRAFT-1": "Mind+ astronaut - spacecraft-1",
    "MIND+_ASTRONAUT_SPACECRAFT-2": "Mind+ astronaut - spacecraft-2",
    "MIND+_ASTRONAUT_SPACECRAFT-3": "Mind+ astronaut - spacecraft-3",
    "MIND+_ASTRONAUT_SPACECRAFT-4": "Mind+ astronaut - spacecraft-4",
    "MIND+_ASTRONAUT_SPACECRAFT-5": "Mind+ astronaut - spacecraft-5",
    "MIND+_ASTRONAUT_STANDING": "Mind+ astronaut - standing",
    "MIND+_ASTRONAUT_STANDING-1": "Mind+ astronaut - standing-1",
    "MIND+_ASTRONAUT_STANDING-2": "Mind+ astronaut - standing-2",
    "BRUSHWOOD": "tussock",
    "BRUSHWOOD-1": "tussock-1",
    "BRUSHWOOD-2": "tussock-2",
    "BRUSHWOOD-3": "tussock-3",
    "BRUSHWOOD-4": "tussock-4",
    "STONE": "stone",
    "STONE-1": "stone-1",
    "STONE-2": "stone-2",
    "STONE-3": "stone-3",
    "STONE-4": "stone-4",
    "TREE": "tree",
    "TREE-1": "tree-1",
    "TREE-2": "tree-2",
    "TREE-3": "tree-3",
    "TREE-4": "tree-4",
    "COPSE": "copse",
    "COPSE-1": "copse-1",
    "COPSE-2": "copse-2",
    "COPSE-3": "copse-3",
    "COPSE-4": "copse-4",
    "SNOW_MOUNTAIN": "snow mountain",
    "SNOW_MOUNTAIN-1": "snow mountain-1",
    "SNOW_MOUNTAIN-2": "snow mountain-2",
    "SNOW_MOUNTAIN-3": "snow mountain-3",
    "SNOW_MOUNTAIN-4": "snow mountain-4",
    "CLOUD": "cloud",
    "CLOUD-1": "cloud-1",
    "CLOUD-2": "cloud-2",
    "CLOUD-3": "cloud-3",
    "CLOUD-4": "cloud-4",
    "CARTOON_PINK_BLUE_BUTTON_GROUP": "cartoon pink & blue button group",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-1": "button-loading",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-2": "button-play",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-3": "button-turn off",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-4": "button-continue",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-5": "button-mute",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-6": "button-start",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-7": "button-power on",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-8": "button-settings",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-9": "button-next level",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-10": "button-volume",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-11": "button-pause",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-12": "button-repeat",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-13": "arrow-up",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-14": "arrow-down",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-15": "arrow-right",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-16": "arrow-left",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP": "tech purple button group",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-1": "button-empty",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-2": "play",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-3": "share",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-4": "check",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-5": "turn off",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-6": "continue",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-7": "mute",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-8": "start game",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-9": "up",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-10": "settings",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-11": "down",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-12": "music",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-13": "game settings",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-14": "right",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-15": "pause",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-16": "rectangular button-empty",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-17": "restart",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-18": "left",
    "DIALOG-1": "dialog-1",
    "DIALOG-2": "dialog-2",
    "DIALOG-3": "dialog-3",
    "DIALOG-4": "dialog-4",
    "DIALOG-5": "dialog-5",
    "DIALOG-6": "dialog-6",
    "DIALOG-7": "dialog-7",
    "DIALOG-8": "dialog-8",
    "DIALOG-9": "dialog-9",
    "DIALOG-10": "dialog-10",
    "Epidemic_Prevention_MIND+": "Mind+ wearing protective coveralls",
    "Epidemic_Prevention_MIND+-1": "Mind+ wearing protective coveralls1",
    "Epidemic_Prevention_MIND+-2": "Mind+ wearing protective coveralls2",
    "VIRUS": "virus",
    "VIRUS-1": "virus-a",
    "VIRUS-2": "virus-b",
    "VIRUS-3": "virus-c",
    "ANTIGEN_REAGENT": "antigen test",
    "ANTIGEN_REAGENT-1": "antigen test-horizontal",
    "ANTIGEN_REAGENT-2": "antigen test-vertical",
    "MASK": "mask",
    "MASK-1": "mask-1",
    "MASK-2": "mask-2",
    "MASK-3": "mask-3",
    "MASK-4": "mask-4",
    "MASK-5": "mask-5",
    "MASK-6": "mask-6",
    "TEMPERATURE_MEASURING_GUN": "thermometer gun",
    "STETHOSCOPE": "stethoscope",
    "DISINFECTANT": "disinfectant",
    "NUMBER-0": "0",
    "NUMBER-1": "1",
    "NUMBER-2": "2",
    "NUMBER-3": "3",
    "NUMBER-4": "4",
    "NUMBER-5": "5",
    "NUMBER-6": "6",
    "NUMBER-7": "7",
    "NUMBER-8": "8",
    "NUMBER-9": "9",
    "LETTER-A": "A",
    "LETTER-B": "B",
    "LETTER-C": "C",
    "LETTER-D": "D",
    "LETTER-E": "E",
    "LETTER-F": "F",
    "LETTER-G": "G",
    "LETTER-H": "H",
    "LETTER-I": "I",
    "LETTER-J": "J",
    "LETTER-K": "K",
    "LETTER-L": "L",
    "LETTER-M": "M",
    "LETTER-N": "N",
    "LETTER-O": "O",
    "LETTER-P": "P",
    "LETTER-Q": "Q",
    "LETTER-R": "R",
    "LETTER-S": "S",
    "LETTER-T": "T",
    "LETTER-U": "U",
    "LETTER-V": "V",
    "LETTER-W": "W",
    "LETTER-X": "X",
    "LETTER-Y": "Y",
    "LETTER-Z": "Z",
    "EXCLAMATORY_MARK": "exclamation mark",
    "EXCLAMATORY_MARK-1": "exclamation mark-1",
    "EXCLAMATORY_MARK-2": "exclamation mark-2",
    "EXCLAMATORY_MARK-3": "exclamation mark-3",
    "YELLOW_PROGRESS_BAR": "yellow progress bar",
    "YELLOW_PROGRESS_BAR-1": "yellow progress bar-1",
    "YELLOW_PROGRESS_BAR-2": "yellow progress bar-2",
    "YELLOW_PROGRESS_BAR-3": "yellow progress bar-3",
    "PROGRESS_BAR": "progress bar",
    "PROGRESS_BAR-1": "progress bar 30",
    "PROGRESS_BAR-2": "progress bar 60",
    "PROGRESS_BAR-3": "progress bar 100",
    "BLUE_PROGRESS_BAR": "blue progress bar",
    "BLUE_PROGRESS_BAR-1": "blue progress bar-1",
    "BLUE_PROGRESS_BAR-2": "blue progress bar-2",
    "BLUE_PROGRESS_BAR-3": "blue progress bar-3",
    "STEREOSCOPIC_QUESTION_MARK": "3D question mark",
    "STEREOSCOPIC_QUESTION_MARK-1": "question mark-gray",
    "STEREOSCOPIC_QUESTION_MARK-2": "question mark-gold",
    "BUBBLE_BOX": "popover",
    "BUBBLE_BOX-1": "popover-1",
    "BUBBLE_BOX-2": "popover-2",
    "VICTORY_FAIL": "victory & failure",
    "VICTORY_FAIL-1": "sheng",
    "VICTORY_FAIL-2": "li",
    "VICTORY_FAIL-3": "shi",
    "VICTORY_FAIL-4": "bai",
    "REFERENCE_NO": "quotation mark",
    "REFERENCE_NO-1": "quotation mark-1",
    "REFERENCE_NO-2": "quotation mark-2",
    "RED_STRIPE": "red bar",
    "GOLD_COIN1": "gold coin 1",
    "BLUE_STRIPE": "blue bar",
    "LIFE": "life",
    "QUADRANGULAR_STAR": "four-angle star",
    "QUESTION_MARK": "question mark",
    "FIVE-POINTED_STAR": "pentagram",
    "AIRSHIP": "airship1",
    "METEORITE": "meterorite",
    "AIRCRAFT": "aircraft",
    "AIRCRAFT-1": "aircraft-1",
    "AIRCRAFT-2": "aircraft-2",
    "AIRCRAFT-3": "aircraft-3",
    "RETRO_AIRCRAFT": "retro aircraft",
    "RETRO_AIRCRAFT-1": "retro aircraft-1",
    "RETRO_AIRCRAFT-2": "retro aircraft-2",
    "RETRO_AIRCRAFT-3": "retro aircraft-3",
    "JEEP": "Jeep",
    "JEEP-1": "Jeep-1",
    "JEEP-2": "Jeep-2",
    "JEEP-3": "Jeep-3",
    "MOTORBOAT": "motor boat",
    "MOTORBOAT-1": "motor boat_side view",
    "MOTORBOAT-2": "motor boat_top view",
    "MOTORBOAT-3": "motor boat_back view",
    "MOTORBOAT-4": "motor boat_front view",
    "SUBMARINE": "submarine",
    "SUBMARINE-1": "submarine_side view",
    "SUBMARINE-2": "submarine_top view",
    "SUBMARINE-3": "submarine_back view",
    "SUBMARINE-4": "submarine_front view",
    "SMALL_SAILBOAT": "sailboat1",
    "SMALL_SAILBOAT-1": "sailboat_side view",
    "SMALL_SAILBOAT-2": "sailboat_top view",
    "SMALL_SAILBOAT-3": "sailboat_back view",
    "SMALL_SAILBOAT-4": "sailboat_front view",
    "VAN": "van",
    "VAN-1": "van_side view",
    "VAN-2": "van_top view",
    "VAN-3": "van_back view",
    "VAN-4": "van_front view",
    "HELICOPTER": "helicopter",
    "HELICOPTER-1": "helicopter_side view",
    "HELICOPTER-2": "helicopter_top view - 1",
    "HELICOPTER-3": "helicopter_top view - 2",
    "HELICOPTER-4": "helicopter_top view - 3",
    "HELICOPTER-5": "helicopter_back view",
    "HELICOPTER-6": "helicopter_front view",
    "BICYCLE": "bicycle - 1",
    "FIRECRACKERS": "firecrackers",
    "FIRECRACKERS-1": "firecrackers - 1",
    "FIRECRACKERS-2": "firecrackers - 2",
    "FIRECRACKERS-3": "firecrackers - 3",
    "COLORFUL_FLAGS": "colorful flag",
    "COLORFUL_FLAGS-1": "colorful flag - 1",
    "COLORFUL_FLAGS-2": "colorful flag - 2",
    "HAPPY_KNOT": "blessing knot",
    "HAPPY_KNOT-1": "blessing knot - 1",
    "HAPPY_KNOT-2": "blessing knot - 2",
    "HAPPY_KNOT-3": "blessing knot - 3",
    "HAPPY_KNOT-4": "blessing knot - 4",
    "HAPPY_KNOT-5": "blessing knot - 5",
    "HAPPY_KNOT-6": "blessing knot - 6",
    "RED_ENVELOPE_ANIMATION": "red-envelope GIF",
    "RED_ENVELOPE_ANIMATION-1": "red-envelope GIF - 1",
    "RED_ENVELOPE_ANIMATION-2": "red-envelope GIF - 2",
    "RED_ENVELOPE_ANIMATION-3": "red-envelope GIF - 3",
    "RED_ENVELOPE_ANIMATION-4": "red-envelope GIF - 4",
    "RED_ENVELOPE_ANIMATION-5": "red-envelope GIF - 5",
    "RED_ENVELOPE_ANIMATION-6": "red-envelope GIF - 6",
    "GOLD_COIN": "gold coin 2",
    "GOLD_COIN-1": "gold coin - 1",
    "GOLD_COIN-2": "gold coin - 2",
    "GOLD_COIN-3": "gold coin - 3",
    "ELK_CART": "elk sled",
    "ELK_CART-1": "elk sled - static",
    "ELK_CART-2": "elk sled - moving",
    "CALENDAR": "calendar",
    "CHRISTMAS_CANDY": "Christmas candy cane",
    "CHRISTMAS_CANDY-1": "Christmas candy cane - 1",
    "CHRISTMAS_CANDY-2": "Christmas candy cane - 2",
    "CHRISTMAS_CANDY-3": "Christmas candy cane - 3",
    "SANTA_CLAUS": "Santa Claus",
    "SANTA_CLAUS-1": "Santa Claus - 1",
    "SANTA_CLAUS-2": "Santa Claus - 2",
    "SANTA_CLAUS-3": "Santa Claus - 3",
    "SANTA_CLAUS2": "Santa Claus 2",
    "JINGLING_BELL": "jingle bell",
    "JINGLING_BELL-1": "jingle bell - 1",
    "JINGLING_BELL-2": "jingle bell - 2",
    "JINGLING_BELL-3": "jingle bell - gold",
    "JINGLING_BELL-4": "jingle bell - silver",
    "CHRISTMAS_TREE": "Christmas tree",
    "CHRISTMAS_TREE-1": "Christmas tree - 1",
    "CHRISTMAS_TREE-2": "Christmas tree - 2",
    "CHRISTMAS_TREE_DYNAMIC": "Christmas tree - GIF",
    "CHRISTMAS_TREE_DYNAMIC-1": "Christmas tree - red lights",
    "CHRISTMAS_TREE_DYNAMIC-2": "Christmas tree - yellow lights",
    "CHRISTMAS_TREE_DYNAMIC-3": "Christmas tree - lights off",
    "CHRISTMAS_TREE_DYNAMIC-4": "Christmas tree - lights on",
    "CHRISTMAS_STOCKING": "Christmas stocking",
    "CHRISTMAS_STOCKING-1": "Christmas stocking - green stars on red background",
    "CHRISTMAS_STOCKING-2": "Christmas stocking - red stars on green background",
    "CHRISTMAS_STOCKING-3": "Christmas stocking - red stripes",
    "CHRISTMAS_STOCKING-4": "Christmas stocking - green stripes",
    "CANDY": "candy",
    "SOCKS": "socks",
    "SMALL_WINDMILL": "small windmill",
    "LITTLE_WOODEN_HORSE": "small wooden horse",
    "SNOWFLAKE": "snowflake",
    "SNOWMAN1": "snowman 1",
    "SNOWMAN1-1": "snowman - initial",
    "SNOWMAN1-2": "snowman - with hat",
    "SNOWMAN1-3": "snowman - with gloves",
    "SNOWMAN1-4": "snowman - with scarf",
    "SNOWMAN2": "snowman 2",
    "SNOWMAN2-1": "snowman - 1",
    "SNOWMAN2-2": "snowman - 2",
    "SNOWMAN2-3": "snowman head",
    "REINDEER": "reindeer",
    "REINDEER-1": "reindeer - 1",
    "REINDEER-2": "reindeer - 2",
    "REINDEER_CART": "reindeer sled",
    "REINDEER_CART-1": "reindeer sled - 1",
    "REINDEER_CART-2": "reindeer sled - 2",
    "ROUND_LANTERN": "round lantern",
    "ROUND_LANTERN-1": "round lantern - 1",
    "ROUND_LANTERN-2": "round lantern - 2",
    "LONG_LANTERN": "long lantern",
    "LONG_LANTERN-1": "long lantern - 1",
    "LONG_LANTERN-2": "long lantern - 2",
    "PAPER_AIRPLANE": "paper plane",
    "PAPER_AIRPLANE-1": "paper plane - red",
    "PAPER_AIRPLANE-2": "paper plane - yellow",
    "PAPER_AIRPLANE-3": "paper plane - blue",
    "PAPER_AIRPLANE-4": "paper plane - green",
    "CHINESE_KNOT": "Chinese knot",
    "CHINESE_KNOT-1": "Chinese knot - 1",
    "CHINESE_KNOT-2": "Chinese knot - 2",
    "SKIING_PEOPLE": "skiing - people",
    "SKIING_PEOPLE-1": "skiing - people - jumping 1",
    "SKIING_PEOPLE-2": "skiing - people - jumping 2",
    "SKIING_PEOPLE-3": "skiing - people - sliding 1",
    "SKIING_PEOPLE-4": "skiing - people - sliding 2",
    "BOY": "boy",
    "BOY-1": "boy - 1",
    "BOY-2": "boy - 2",
    "BOY-3": "boy - 3",
    "BOY-4": "boy - 4",
    "AVATAR_BOY": "boy avatar",
    "AVATAR_GIRL": "girl avatar",
    "GIRL": "girl",
    "GIRL-1": "girl - 1",
    "GIRL-2": "girl - 2",
    "GIRL-3": "girl - 3",
    "GIRL-4": "girl - 4",
    "TURKEY": "turkey chicken",
    "GINGERBREAD_MAN": "gingerbread man",
    "SKI_MARK": "ski mark",
    "SKI_MARK-1": "ski mark - 1",
    "SKI_MARK-2": "ski mark - 2",
    "SKI_MARK-3": "ski mark - 3",
    "SKI_MARK-4": "ski mark - 4",
    "BACKDROPS_2022_SKI-FIELD-1": "ski slope - 1",
    "BACKDROPS_2022_SKI-FIELD-2": "ski slope - 2",
    "BACKDROPS_2022_AIRPORT-RUNWAY": "airport runway",
    "BACKDROPS_2022_INTERSECTION": "intersection",
    "BACKDROPS_2022_SKY-1": "sky - 1",
    "BACKDROPS_2022_SKY-2": "sky - 2",
    "BACKDROPS_2022_SETTING-SUN-1": "sunset - 1",
    "BACKDROPS_2022_SETTING-SUN-2": "sunset - 2",
    "BACKDROPS_2022_SETTING-SUN-3": "sunset - 3",
    "BACKDROPS_2022_MARS": "Mars",
    "BACKDROPS_2022_STARRY-SKY-1": "starry sky - 1",
    "BACKDROPS_2022_STARRY-SKY-2": "starry sky - 2",
    "BACKDROPS_2022_STARRY-SKY-3": "starry sky - 3",
    "BACKDROPS_2022_MOON-1": "Moon - 1",
    "BACKDROPS_2022_MOON-2": "Moon - 2",
    "BACKDROPS_2022_SANTA-BACKGROUND": "Santa Claus background",
    "BACKDROPS_2022_CHRISTMAS-THEME-BACKGROUND": "Christmas background",
    "BACKDROPS_2022_SNOWMAN-BACKGROUND": "snowman background",
    "WHITE-MOON": "White Moon",
    "GRASSLAND": "Grassland",
    "YELLOW-MOON": "Yellow Moon",
    "SKATEBOARD-1": "Skateboard - Side",
    "SKATEBOARD-2": "Skateboard - Bottom",
    "SKATEBOARD-3": "Skateboard - Top",
    "SKATEBOARD-4": "Skateboard - Front",
    "SKATEBOARD": "Skateboard",
    "CARTOON-ARTPLANES-1": "Cartoon Artplans - Front",
    "CARTOON-ARTPLANES-2": "Cartoon Artplans - Bottom",
    "CARTOON-ARTPLANES-3": "Cartoon Artplans - Right",
    "CARTOON-ARTPLANES-4": "Cartoon Artplans - Left",
    "CARTOON-ARTPLANES-5": "Cartoon Artplans - Top",
    "CARTOON-ARTPLANES-6": "Cartoon Artplans - Rear",
    "CARTOON-ARTPLANES": "Cartoon Artplans",
    "BICYCLE-GRAY-1": "Bicycle-Gray - Side",
    "BICYCLE-GRAY-2": "Bicycle-Gray - Top",
    "BICYCLE-GRAY-3": "Bicycle-Gray - Front",
    "BICYCLE-GRAY-4": "Bicycle-Gray - Rear",
    "BICYCLE-GRAY": "Bicycle-Gray",
    "BATS-1": "Bats-1",
    "BATS-2": "Bats-2",
    "BATS-3": "Bats-3",
    "BATS-4": "Bats-4",
    "BATS": "Bats ",
    "CASTLE-1": "Castle-1",
    "CASTLE-2": "Castle-2",
    "CASTLE": "Castle",
    "JACK-o'-LANTERN-1": "Jack-o'-lantern1-1",
    "JACK-o'-LANTERN-2": "Jack-o'-lantern1-2",
    "JACK-o'-LANTERN-3": "Jack-o'-lantern1-3",
    "JACK-o'-LANTERN-4": "Jack-o'-lantern2",
    "JACK-o'-LANTERN-5": "Jack-o'-lantern3",
    "JACK-o'-LANTERN-6": "Jack-o'-lantern4",
    "JACK-o'-LANTERN": "Jack-o'-lantern",
    "WOODS-1": "woods-1",
    "WOODS-2": "woods-2",
    "WOODS": "woods",
    "DANCING-LION-RED-1": "Dancing Lion-Red-1",
    "DANCING-LION-RED-2": "Dancing Lion-Red-2",
    "DANCING-LION-RED-3": "Dancing Lion-Red-3",
    "DANCING-LION-RED-4": "Dancing Lion-Red-4",
    "DANCING-LION-RED-5": "Dancing Lion-Red-5",
    "DANCING-LION-RED-6": "Dancing Lion-Red-6",
    "DANCING-LION-RED": "Dancing Lion-Red",
    "DANCING-LION-BLUE-1": "Dancing Lion-Blue-1",
    "DANCING-LION-BLUE-2": "Dancing Lion-Blue-2",
    "DANCING-LION-BLUE-3": "Dancing Lion-Blue-3",
    "DANCING-LION-BLUE": "Dancing Lion-Blue",
    "DANCING-LION-GREEN-1": "Dancing Lion-Green-1",
    "DANCING-LION-GREEN-2": "Dancing Lion-Green-2",
    "DANCING-LION-GREEN-3": "Dancing Lion-Green-3",
    "DANCING-LION-GREEN": "Dancing Lion-Green",
    "SPECTRE-1": "Specter-1",
    "SPECTRE-2": "Specter-2",
    "SPECTRE-3": "Specter-3",
    "SPECTRE-4": "Specter-4",
    "SPECTRE": "Specter",
    "SPIDER-1": "Spider-1",
    "SPIDER-2": "Spider-2",
    "SPIDER-3": "Spider-3",
    "SPIDER": "Spider",
    "GOLF-PEOPLE1-1": "Golf-People1-1",
    "GOLF-PEOPLE1-2": "Golf-People1-2",
    "GOLF-PEOPLE1-3": "Golf-People1-3",
    "GOLF-PEOPLE1": "Golf-People1",
    "GOLF-PEOPLE2-1": "Golf-People2-1",
    "GOLF-PEOPLE2-2": "Golf-People2-2",
    "GOLF-PEOPLE2-3": "Golf-People2-3",
    "GOLF-PEOPLE2": "Golf-People2",
    "GOLF": "Golf",
    "GOLF-HOLES": "Golf-Holes",
    "GOLF-SEATS": "Golf-Seats",
    "GREEN-LEAVES-1": "Green-Leaves-1",
    "GREEN-LEAVES-2": "Green-Leaves-2",
    "GREEN-LEAVES": "Green-Leaves",
    "CACTUS-1": "Cactus-1",
    "CACTUS-2": "Cactus-2",
    "CACTUS": "Cactus",
    "FLORETS-1": "Florets-1",
    "FLORETS-2": "Florets-2",
    "FLORETS-3": "Florets-3",
    "FLORETS-4": "Florets-4",
    "FLORETS-5": "Florets-5",
    "FLORETS-6": "Florets-6",
    "FLORETS-7": "Florets-7",
    "FLORETS-8": "Florets-8",
    "FLORETS": "Florets"
  },
  "es": {
    "gui.backpack.header": "Mochila",
    "gui.backpack.errorBackpack": "Error al cargar la mochila",
    "gui.backpack.loadingBackpack": "Cargando...",
    "gui.backpack.more": "Más",
    "gui.backpack.emptyBackpack": "La mochila está vacía",
    "gui.gui.costumesTab": "Disfraces",
    "gui.gui.soundsTab": "Sonidos",
    "gui.gui.backdropsTab": "Fondos",
    "gui.gui.addExtension": "Añadir extensión",
    "gui.costumeTab.addCostumeFromLibrary": "Elige un disfraz",
    "gui.costumeLibrary.chooseACostume": "Elige un disfraz",
    "gui.costumeTab.addBackdropFromLibrary": "Elige un fondo",
    "gui.costumeTab.addBlankCostume": "Pinta",
    "gui.costumeTab.addSurpriseCostume": "Sorpresa",
    "gui.costumeTab.addFileBackdrop": "Carga un fondo",
    "gui.costumeTab.addFileCostume": "Carga un disfraz",
    "gui.costumeTab.addCameraCostume": "Cámara",
    "gui.soundEditor.trim": "Recortar",
    "gui.soundEditor.stop": "Detener",
    "gui.soundEditor.sound": "Sonido",
    "gui.soundEditor.play": "Reproducir",
    "gui.soundEditor.save": "Guardar",
    "gui.soundEditor.undo": "Deshacer",
    "gui.soundEditor.redo": "Rehacer",
    "gui.soundEditor.faster": "Más rápido",
    "gui.soundEditor.slower": "Más lento",
    "gui.soundEditor.echo": "Eco",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Más fuerte",
    "gui.soundEditor.softer": "Más suave",
    "gui.soundEditor.reverse": "Invertir",
    "gui.soundTab.recordSound": "Grabar",
    "gui.soundTab.addSoundFromLibrary": "Elige un sonido",
    "gui.soundTab.surpriseSound": "Sorpresa",
    "gui.soundTab.fileUploadSound": "Carga un sonido",
    "gui.controls.stop": "Detener",
    "gui.controls.go": "Ir",
    "gui.stageHeader.stageSizeUnFull": "Salir del modo de pantalla completa",
    "gui.SpriteInfo.show": "Mostrar",
    "gui.SpriteInfo.size": "Tamaño",
    "gui.directionPicker.rotationStyles.allAround": "En todas direcciones",
    "gui.directionPicker.rotationStyles.leftRight": "Izquierda/Derecha",
    "gui.directionPicker.rotationStyles.dontRotate": "No rotar",
    "gui.spriteSelectorItem.contextMenuDuplicate": "duplicar",
    "gui.spriteSelectorItem.contextMenuDelete": "borrar",
    "gui.spriteSelectorItem.contextMenuExport": "exportar",
    "gui.spriteSelector.addSpriteFromPaint": "Pinta",
    "gui.spriteSelector.addSpriteFromSurprise": "Sorpresa",
    "gui.spriteSelector.addSpriteFromFile": "Subir objeto",
    "gui.stageSelector.stage": "Escenario",
    "gui.stageSelector.backdrops": "Fondos",
    "gui.stageSelector.addBackdropFromPaint": "Pinta",
    "gui.stageSelector.addBackdropFromSurprise": "Sorpresa",
    "gui.stageSelector.addBackdropFromFile": "Carga un fondo",
    "gui.modal.back": "Atrás",
    "gui.library.filterPlaceholder": "Busca",
    "gui.libraryTags.all": "Todos",
    "gui.libraryTags.animals": "Animales",
    "gui.libraryTags.dance": "Bailar",
    "gui.libraryTags.effects": "Efectos",
    "gui.libraryTags.fantasy": "Fantasía",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Comida",
    "gui.libraryTags.indoors": "Interiores",
    "gui.libraryTags.loops": "Bucles",
    "gui.libraryTags.music": "Música",
    "gui.libraryTags.notes": "Notas",
    "gui.libraryTags.outdoors": "Exteriores",
    "gui.libraryTags.patterns": "Patrones",
    "gui.libraryTags.people": "Gente",
    "gui.libraryTags.percussion": "Percusión",
    "gui.libraryTags.space": "Espacio",
    "gui.libraryTags.sports": "Deportes",
    "gui.libraryTags.underwater": "Bajo el mar",
    "gui.libraryTags.voice": "Voz",
    "gui.libraryTags.wacky": "Locuras",
    "gui.libraryTags.animation": "Animación",
    "gui.libraryTags.art": "Arte",
    "gui.libraryTags.games": "Juegos",
    "gui.libraryTags.stories": "Historias",
    "gui.libraryTags.letters": "Letras",
    "gui.soundLibrary.chooseASound": "Elige un sonido",
    "gui.SpriteInfo.spritePlaceholder": "Nombre",
    "gui.cards.more-things-to-try": "¡Más cosas para probar!",
    "gui.cards.see-more": "Ver más",
    "gui.cards.shrink": "Encoger",
    "gui.cards.expand": "Expandir",
    "gui.cards.close": "Cerrar",
    "gui.loader.message1": "Creando bloques ...",
    "gui.loader.message2": "Cargando objetos...",
    "gui.loader.message3": "Cargando sonidos...",
    "gui.loader.message4": "Cargando extensiones...",
    "gui.loader.message5": "Pastoreando gatos...",
    "gui.loader.message6": "Transmitiendo nanos...",
    "gui.loader.message7": "Inflando gobos...",
    "gui.loader.message8": "Preparando emojis...",
    "gui.loader.headline": "Cargando proyecto",
    "gui.cameraModal.cameraModalTitle": "Hacer una foto",
    "gui.cameraModal.loadingCameraMessage": "Cargando cámara...",
    "gui.cameraModal.permissionRequest": "Necesitamos tu permiso para usar la cámara",
    "gui.cameraModal.retakePhoto": "Repetir foto",
    "gui.cameraModal.save": "Guardar",
    "gui.cameraModal.takePhoto": "Hacer una foto",
    "gui.cameraModal.loadingCaption": "Cargando...",
    "gui.cameraModal.enableCameraCaption": "Activar la cámara",
    "gui.recordModal.title": "Grabar sonido",
    "gui.playbackStep.stopMsg": "Detener",
    "gui.playbackStep.playMsg": "Reproducir",
    "gui.playbackStep.loadingMsg": "Cargando...",
    "gui.playbackStep.saveMsg": "Guardar",
    "gui.playbackStep.reRecordMsg": "Volver a grabar",
    "gui.webglModal.label": "Tu navegador no soporta WebGL",
    "gui.webglModal.webgllink": "no soporta WebGL",
    "gui.prompt.cancel": "Cancelar",
    "gui.prompt.ok": "Aceptar",
    "gui.customProcedures.addAnInputNumberText": "Añadir una entrada",
    "gui.customProcedures.addAnInputBoolean": "Añadir una entrada",
    "gui.customProcedures.numberTextType": "número o texto",
    "gui.customProcedures.booleanType": "lógica",
    "gui.customProcedures.addALabel": "Añadir una etiqueta",
    "gui.customProcedures.runWithoutScreenRefresh": "Ejecutar al instante",
    "gui.customProcedures.cancel": "Cancelar",
    "gui.customProcedures.ok": "Aceptar",
    "gui.extension.microbit.description": "Conecta tus proyectos con el mundo.",
    "gui.extension.music.name": "Música",
    "gui.extension.music.description": "Toca instrumentos y percusión.",
    "gui.extension.pen.name": "Lápiz",
    "gui.extension.pen.description": "Dibuja con tus objetos.",
    "gui.gui.variableScopeOptionAllSprites": "Para todos los objetos",
    "gui.gui.variableScopeOptionSpriteOnly": "Sólo para este objeto",
    "gui.gui.variablePromptAllSpritesMessage": "Esta variable estará disponible en todos los objetos.",
    "gui.monitor.contextMenu.default": "tamaño normal",
    "gui.monitor.contextMenu.large": "tamaño grande",
    "gui.monitor.contextMenu.slider": "deslizador",
    "gui.monitor.contextMenu.import": "importar",
    "gui.monitor.contextMenu.export": "exportar",
    "gui.opcodeLabels.direction": "dirección",
    "gui.opcodeLabels.xposition": "posición en x",
    "gui.opcodeLabels.yposition": "posición en y",
    "gui.opcodeLabels.size": "tamaño",
    "gui.opcodeLabels.costumename": "nombre de disfraz",
    "gui.opcodeLabels.costumenumber": "número de disfraz",
    "gui.opcodeLabels.backdropname": "nombre de fondo",
    "gui.opcodeLabels.backdropnumber": "número de fondo",
    "gui.opcodeLabels.volume": "volumen",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "respuesta",
    "gui.opcodeLabels.loudness": "volumen del sonido",
    "gui.opcodeLabels.year": "año",
    "gui.opcodeLabels.month": "mes",
    "gui.opcodeLabels.date": "día",
    "gui.opcodeLabels.dayofweek": "día de la semana",
    "gui.opcodeLabels.hour": "hora",
    "gui.opcodeLabels.minute": "minuto",
    "gui.opcodeLabels.second": "segundo",
    "gui.opcodeLabels.timer": "cronómetro",
    "music.categoryName": "Música",
    "translate.categoryName": "Traducción",
    "pen.categoryName": "Lápiz",
    "pen.changeColorParam": "cambiar [COLOR_PARAM] de lápiz por [VALUE]",
    "pen.changeHue": "cambiar color de lápiz por [HUE]",
    "pen.changeShade": "cambiar sombra de lápiz por [SHADE]",
    "pen.changeSize": "cambiar tamaño de lápiz por [SIZE]",
    "pen.clear": "borrar todo",
    "pen.colorMenu.brightness": "brillo",
    "pen.colorMenu.color": "color",
    "pen.colorMenu.saturation": "saturación",
    "pen.colorMenu.transparency": "transparencia",
    "pen.penDown": "bajar lápiz",
    "pen.penUp": "subir lápiz",
    "pen.setColor": "fijar color de lápiz a [COLOR]",
    "pen.setColorParam": "fijar [COLOR_PARAM] de lápiz a [VALUE]",
    "pen.setHue": "fijar color de lápiz a [HUE]",
    "pen.setShade": "fijar sombra de lápiz a [SHADE]",
    "pen.setSize": "fijar tamaño de lápiz a [SIZE]",
    "pen.stamp": "sellar",
    "music.changeTempo": "cambiar tempo por [TEMPO]",
    "music.drumBass": "(2) Bombo",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Charles cerrado",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Cencerro",
    "music.drumCrashCymbal": "(4) Platillo crash",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Güiro",
    "music.drumHandClap": "(8) Palmada",
    "music.drumOpenHiHat": "(5) Charles abierto",
    "music.drumSideStick": "(3) Golpe lateral",
    "music.drumSnare": "(1) Caja",
    "music.drumTambourine": "(7) Pandereta",
    "music.drumTriangle": "(12) Triángulo",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Caja china",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bajo",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Violonchelo",
    "music.instrumentChoir": "(15) Coro",
    "music.instrumentClarinet": "(10) Clarinete",
    "music.instrumentElectricGuitar": "(5) Guitarra eléctrica",
    "music.instrumentElectricPiano": "(2) Piano eléctrico",
    "music.instrumentFlute": "(12) Flauta",
    "music.instrumentGuitar": "(4) Guitarra",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Caja de música",
    "music.instrumentOrgan": "(3) Órgano",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxofón",
    "music.instrumentSteelDrum": "(18) Tambor metálico",
    "music.instrumentSynthLead": "(20) Sintetizador melódico",
    "music.instrumentSynthPad": "(21) Sintetizador de fondo",
    "music.instrumentTrombone": "(9) Trombón",
    "music.instrumentVibraphone": "(16) Vibráfono",
    "music.instrumentWoodenFlute": "(13) Flauta de madera",
    "music.midiPlayDrumForBeats": "tocar tambor [DRUM] durante [BEATS] tiempos",
    "music.midiSetInstrument": "fijar instrumento a [INSTRUMENT]",
    "music.playDrumForBeats": "tocar tambor [DRUM] durante [BEATS] tiempos",
    "music.playNoteForBeats": "tocar nota [NOTE] durante [BEATS] tiempos",
    "music.restForBeats": "silencio de [BEATS] tiempos",
    "music.setInstrument": "fijar instrumento a [INSTRUMENT]",
    "music.setTempo": "dar al tempo el valor [TEMPO]",
    "videoSensing.categoryName": "Sensor de video",
    "videoSensing.direction": "dirección",
    "videoSensing.motion": "movimiento",
    "videoSensing.off": "apagar",
    "videoSensing.on": "encender",
    "videoSensing.onFlipped": "invertir",
    "videoSensing.setVideoTransparency": "fijar transparencia de vídeo a [TRANSPARENCY]",
    "videoSensing.sprite": "objeto",
    "videoSensing.stage": "escenario",
    "videoSensing.videoOn": "[ATTRIBUTE] de vídeo en [SUBJECT]",
    "videoSensing.videoToggle": "[VIDEO_STATE] vídeo",
    "videoSensing.whenMotionGreaterThan": "cuando movimiento de vídeo > [REFERENCE]",
    "translate.translateBlock": "traducir [WORDS] al [LANGUAGE]",
    "translate.defaultTextToTranslate": "hola",
    "translate.viewerLanguage": "idioma",
    "text2speech.speakAndWaitBlock": "decir [WORDS]",
    "text2speech.setVoiceBlock": "asignar voz a [VOICE]",
    "text2speech.setLanguageBlock": "fijar idioma a [LANGUAGE]",
    "text2speech.alto": "contralto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "chillido",
    "text2speech.giant": "gigante",
    "text2speech.kitten": "gatito",
    "text2speech.defaultTextToSpeak": "hola",
    "speech.whenIHear": "cuando oiga [PHRASE]",
    "speech.listenAndWait": "escuchar y esperar",
    "speech.defaultWhenIHearValue": "vamos",
    "paint.paintEditor.hue": "Color",
    "paint.paintEditor.saturation": "Saturación",
    "paint.paintEditor.brightness": "Brillo",
    "gui.comingSoon.message1": "No te preocupes, estamos en ello {emoji}",
    "gui.comingSoon.message2": "Próximamente...",
    "gui.comingSoon.message3": "Estamos trabajando en ello {emoji}",
    "paint.paintEditor.fill": "Rellenar",
    "paint.paintEditor.costume": "Disfraz",
    "paint.paintEditor.group": "Agrupar",
    "paint.paintEditor.ungroup": "Desagrupar",
    "paint.paintEditor.undo": "Deshacer",
    "paint.paintEditor.redo": "Rehacer",
    "paint.paintEditor.forward": "Adelante",
    "paint.paintEditor.backward": "Atrás",
    "paint.paintEditor.front": "Al frente",
    "paint.paintEditor.back": "Al fondo",
    "paint.paintEditor.more": "Más",
    "paint.modeTools.brushSize": "Tamaño",
    "paint.modeTools.eraserSize": "Tamaño de la goma",
    "paint.modeTools.copy": "Copiar",
    "paint.modeTools.paste": "Pegar",
    "paint.modeTools.delete": "Eliminar",
    "paint.modeTools.curved": "Curvado",
    "paint.modeTools.pointed": "Recto",
    "paint.modeTools.thickness": "Grosor",
    "paint.modeTools.flipHorizontal": "Voltear horizontalmente",
    "paint.modeTools.flipVertical": "Voltear verticalmente",
    "paint.modeTools.filled": "Relleno",
    "paint.modeTools.outlined": "Contorneado",
    "paint.paintEditor.bitmap": "Convertir a mapa de bits",
    "paint.paintEditor.vector": "Convertir a vector",
    "paint.paintEditor.stroke": "Borde",
    "paint.brushMode.brush": "Pincel",
    "paint.eraserMode.eraser": "Goma",
    "paint.fillMode.fill": "Rellenar",
    "paint.lineMode.line": "Línea",
    "paint.ovalMode.oval": "Círculo",
    "paint.rectMode.rect": "Rectángulo",
    "paint.reshapeMode.reshape": "Volver a dar forma",
    "paint.roundedRectMode.roundedRect": "Rectángulo con bordes redoneados",
    "paint.selectMode.select": "Seleccionar",
    "paint.textMode.text": "Texto",
    "paint.colorPicker.swap": "Invertir",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "es-419": {
    "gui.gui.project": "Proyecto",
    "gui.gui.newItem": "Nuevo Proyecto",
    "gui.gui.load": "Abrir Proyecto",
    "gui.gui.save": "Guardar Proyecto",
    "gui.gui.saveAs": "Guardar Como",
    "gui.gui.learn": "Manual",
    "gui.gui.clearRecord": "Clear Record",
    "gui.gui.onlineLearn": "Documentos Oficiales",
    "gui.gui.onlineForum": "Foro",
    "gui.gui.videoTutorial": "Tutorial de Video",
    "gui.gui.offlineLearn": "Programa de Ejemplo",
    "gui.menuBar.turboModeOff": "Activar el Modo Turbo",
    "gui.menuBar.turboModeOn": "Desactivar el Modo Turbo",
    "gui.menuBar.edit": "Editar",
    "gui.menuBar.restoreSprite": "Restaurar Sprite",
    "gui.menuBar.restoreSound": "Restaurar Sonido",
    "gui.menuBar.restoreCostume": "Restaurar Vestido",
    "gui.editorMind.restore": "Restaurar",
    "gui.turboMode.active": "El Modo Turbo",
    "gui.gui.connectDevice": "Conectar Dispositivos",
    "gui.gui.disconnectDevice": "Desconectar Dispositivos",
    "gui.gui.installSerialportDriver": "Instalar driver del puerto a un clic",
    "gui.gui.openDM": "Abrir Administrador de Dispositivos",
    "gui.gui.updateTips": "Aviso de Actualización",
    "gui.gui.newVersion": "La Última Versión",
    "gui.gui.downloadUpdate": "Descargar actualización",
    "gui.gui.versionUpdate": "Actualizar Programa",
    "gui.gui.downloading": "Checking Updator",
    "gui.setting.feedbackMenu": "Comentario",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.setting.officialWebsit": "Web Oficial",
    "gui.setting.officialQQGroup": "Grupo de QQ Oficial",
    "gui.setting.wechatOfficialAccounts": "Cuenta Pública Oficial de Wechat",
    "gui.setting.currentVersion": "Versión Actual",
    "gui.setting.checkForUpdate": "Buscar Actualización",
    "gui.setting.remindWhenUpdate": "Actualización Automática",
    "gui.setting.currentIsLatest": "Ya está actualizado",
    "gui.setting.latestVersion": "La Última Versión",
    "gui.setting.download": "Descargar actualización",
    "gui.setting.language": "Idioma",
    "gui.setting.uploadCsv": "Subir csv",
    "gui.setting.theme": "Tema",
    "gui.setting.feedback": "Contacto",
    "gui.setting.email": "Correo Electrónico",
    "gui.setting.opinionFeedback": "Comentario",
    "gui.variableMc.variableName": "variable name",
    "gui.variableMc.variableOff": "cancel",
    "gui.variableMc.variableOn": "confirm",
    "gui.variableMc.newVariable": "new variable",
    "gui.setting.helpUsTranslate": "Help Us Translate",
    "gui.setting.checkUpdate": "Check Update",
    "gui.setting.ok": "Ok",
    "gui.setting.languageSetting": "Idioma",
    "gui.setting.themeSetting": "Theme",
    "gui.setting.versionUpdate": "Version Update",
    "gui.setting.connectUsTitle": "Contact us",
    "gui.setting.uploadAttachment": "Subir Adjuntos",
    "gui.backpack.header": "Mochila",
    "gui.backpack.errorBackpack": "Error al cargar la mochila",
    "gui.backpack.loadingBackpack": "Cargando...",
    "gui.backpack.more": "Más",
    "gui.backpack.emptyBackpack": "La mochila está vacía",
    "gui.gui.blocks": "Bloque",
    "gui.gui.costumesTab": "Disfraces",
    "gui.gui.soundsTab": "Sonidos",
    "gui.gui.backdropsTab": "Fondos",
    "gui.gui.addExtension": "Extensión",
    "gui.costumeTab.addCostumeFromLibrary": "Eligir un disfraz",
    "gui.costumeLibrary.chooseACostume": "Eligir un disfraz",
    "gui.costumeTab.addBackdropFromLibrary": "Eligir un fondo",
    "gui.costumeTab.addBlankCostume": "Pintar",
    "gui.costumeTab.addSurpriseCostume": "Sorpresa",
    "gui.costumeTab.addFileBackdrop": "Subir fondo",
    "gui.costumeTab.addFileCostume": "Subir Disfraz",
    "gui.costumeTab.addCameraCostume": "Cámara",
    "gui.soundEditor.trim": "Recortar",
    "gui.soundEditor.stop": "Detener",
    "gui.soundEditor.sound": "Sonido",
    "gui.soundEditor.play": "Jugar",
    "gui.soundEditor.save": "Guardar",
    "gui.soundEditor.undo": "Deshacer",
    "gui.soundEditor.redo": "Rehacer",
    "gui.soundEditor.faster": "Más rápido",
    "gui.soundEditor.slower": "Más lento",
    "gui.soundEditor.echo": "Eco",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Más fuerte",
    "gui.soundEditor.softer": "Más suave",
    "gui.soundEditor.reverse": "Atrás",
    "gui.soundTab.recordSound": "Grabar",
    "gui.soundTab.addSoundFromLibrary": "Elige un sonido",
    "gui.soundTab.surpriseSound": "Sorpresa",
    "gui.soundTab.fileUploadSound": "Subir sonido",
    "gui.soundTab.addSound": "Añadir sonido",
    "gui.controls.stop": "Detener",
    "gui.controls.go": "Ir",
    "gui.gui.startPython": "Iniciar python",
    "gui.gui.stopPython": "Detener python",
    "gui.controls.fullScreenControl": "Full Screen Control",
    "gui.gui.stageSizeLarge": "Cabilla del tamaño del escenario - grande",
    "gui.gui.stageSizeSmall": "Cabilla del tamaño del escenario - pequeño",
    "gui.gui.stageSizeNostage": "Cabilla del tamaño del escenario - nulo",
    "gui.gui.stageSizeFull": "Cabilla del tamaño del escenario - pantalla completa",
    "gui.stageHeader.stageSizeUnFull": "Salir del modo de pantalla completa",
    "gui.sprite.sprite": "duende",
    "gui.SpriteInfo.show": "mostrar",
    "gui.SpriteInfo.size": "Tamaño",
    "gui.sprite.direction": "dirección ",
    "gui.sprite.rotation": "Rotation",
    "gui.directionPicker.rotationStyles.allAround": "En todas direcciones",
    "gui.directionPicker.rotationStyles.leftRight": "Izquierda/Derecha",
    "gui.directionPicker.rotationStyles.dontRotate": "No rotar",
    "gui.spriteSelectorItem.contextMenuDuplicate": "duplicar",
    "gui.spriteSelectorItem.contextMenuDelete": "eliminar",
    "gui.spriteSelectorItem.contextMenuExport": "exportar",
    "gui.sprite.addSpriteFromLibrary": "Biblioteca de duendes",
    "gui.spriteSelector.addSpriteFromPaint": "Pintar",
    "gui.spriteSelector.addSpriteFromSurprise": "Sorpresa",
    "gui.spriteSelector.addSpriteFromFile": "Subir un objeto",
    "gui.sprite.addSpriteFromCamera": "Cámara",
    "gui.stageSelector.stage": "Escenario",
    "gui.stageSelector.backdrops": "Fondos",
    "gui.stage.addBackdropFromLibrary": "Biblioteca de fondos",
    "gui.stageSelector.addBackdropFromPaint": "Pintar",
    "gui.stageSelector.addBackdropFromSurprise": "Sorpresa",
    "gui.stageSelector.addBackdropFromFile": "Subir Fondo",
    "gui.stage.addBackdropFromCamera": "Cámara",
    "gui.modal.back": "Regresar",
    "gui.extension.kit": "Kit",
    "gui.extension.kitTitle": "Elegir kit",
    "gui.extension.board": "Tarjeta",
    "gui.extension.boardTitle": "Elegir tarjeta",
    "gui.extension.spreadBoard": "Shield",
    "gui.extension.spreadBoardTitle": "Select Shield",
    "gui.extension.sensor": "Sensor",
    "gui.extension.sensorTitle": "Elegir sensor",
    "gui.extension.actuator": "Actuador",
    "gui.extension.actuatorTitle": "Elegir actuador",
    "gui.extension.communicationModule": "Communication",
    "gui.extension.communicationModuleTitle": "Elegir bloque de comunicación",
    "gui.extension.display": "Pantalla",
    "gui.extension.displayTitle": "Elegir pantalla",
    "gui.extension.function": "Function",
    "gui.extension.functionTitle": "Elegir bloque de funciones",
    "gui.extension.internetService": "Internet",
    "gui.extension.internetServiceTitle": "Elegir servicio de internet",
    "gui.extension.arduinContentLabel": "Elegir dispositivos",
    "gui.library.filterPlaceholder": "Buscar",
    "gui.libraryTags.all": "Todos",
    "gui.libraryTags.animals": "Animales",
    "gui.libraryTags.dance": "Bailes",
    "gui.libraryTags.effects": "Efectos",
    "gui.libraryTags.fantasy": "Fantasía",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Alimento",
    "gui.libraryTags.indoors": "Interiores",
    "gui.libraryTags.loops": "Ciclos",
    "gui.libraryTags.music": "Música",
    "gui.libraryTags.notes": "Notas",
    "gui.libraryTags.outdoors": "Exteriores",
    "gui.libraryTags.patterns": "Patrones",
    "gui.libraryTags.people": "Gente",
    "gui.libraryTags.percussion": "Percusión",
    "gui.libraryTags.space": "Espacio",
    "gui.libraryTags.sports": "Deportes",
    "gui.libraryTags.underwater": "Bajo el mar",
    "gui.libraryTags.voice": "Voz",
    "gui.libraryTags.wacky": "Absurdo",
    "gui.libraryTags.animation": "Animación",
    "gui.libraryTags.art": "Arte",
    "gui.libraryTags.games": "Juegos",
    "gui.libraryTags.stories": "Historias",
    "gui.libraryTags.letters": "Letras",
    "gui.extension.backdropLib": "Biblioteca de fondos",
    "gui.soundLibrary.chooseASound": "Elige un sonido",
    "gui.SpriteInfo.spritePlaceholder": "Nombre",
    "gui.extension.spriteLib": "Biblioteca de duendes",
    "gui.gui.unselectedSerialport": "Dispositivos no conectados",
    "gui.gui.unConnectedDev": "Dispositivos no conectados",
    "gui.gui.pythonMode": "Modo Python",
    "gui.gui.burnFirmware": "Grabar firmware",
    "gui.gui.burnFirmwareError": "Error de grabar firmware",
    "gui.gui.connected": "conectado",
    "gui.gui.failedConnected": "No ha sido posible la conexión",
    "gui.gui.connecting": "Conectando",
    "gui.cards.all-how-tos": "Todos los tutoriales",
    "gui.cards.how-tos": "Tutoriales principiantes",
    "gui.cards.remove": "Salida",
    "gui.cards.more-things-to-try": "¡Más cosas para probar!",
    "gui.cards.see-more": "Ver más",
    "gui.loader.message1": "Creando bloques",
    "gui.loader.message2": "Cargando objetos ...",
    "gui.loader.message3": "Cargando sonidos ...",
    "gui.loader.message4": "Cargando extensiones ...",
    "gui.loader.message5": "Arrear Mind+ …",
    "gui.loader.message6": "Transmitiendo nanos ...",
    "gui.loader.message7": "Inflando Gobos ...",
    "gui.loader.message8": "Preparando emojis ...",
    "gui.loader.headline": "Cargando proyecto",
    "gui.cameraModal.cameraModalTitle": "Tomar una foto",
    "gui.cameraModal.loadingCameraMessage": "Cargando cámara...",
    "gui.cameraModal.permissionRequest": "Necesitamos permiso para usar tu cámara",
    "gui.cameraModal.retakePhoto": "Vuelve a tomar la foto",
    "gui.cameraModal.save": "Guardar",
    "gui.cameraModal.takePhoto": "Sacar una foto",
    "gui.cameraModal.loadingCaption": "Cargando...",
    "gui.cameraModal.enableCameraCaption": "Activar la cámara",
    "gui.recordModal.title": "Grabar Sonido",
    "gui.recordStep.recordByClickBtn": "Pulsar el botón para grabar",
    "gui.recordStep.permissionMicrophone": "Se necesita su permiso para utilizar su micrófono",
    "gui.recordStep.record": "Grabar ",
    "gui.recordStep.stopRecord": "Parar la grabación",
    "gui.playbackStep.stopMsg": "Detener",
    "gui.playbackStep.playMsg": "Jugar",
    "gui.playbackStep.loadingMsg": "Cargando...",
    "gui.playbackStep.saveMsg": "Guardar",
    "gui.playbackStep.reRecordMsg": "Volver a grabar",
    "gui.webglModal.label": "Su navegador no es compatible con WebGL",
    "gui.webglModal.descriptionMind": "Desafortunadamente tu ordenador {webGlLink}. Mind+ necesitará WebGL {updateGpuDriver} ",
    "gui.webglModal.webgllink": "no es compatible con WebGL",
    "gui.webglModal.updateGpuDriver": "Por favor actualiza el driver para tu tarjeta de vídeo",
    "gui.webglModal.ok": "Ok",
    "gui.extension.scratchExampleLib": "Programa de ejemplo de Scratch",
    "gui.extension.pythonExampleLib": "Programa de ejemplo de Python",
    "gui.extension.arduinoExampleLib": "Programa de ejemplo de Arduino",
    "gui.prompt.cancel": "Cancelar",
    "gui.prompt.ok": "De acuerdo",
    "gui.extension.makeBlock": "Añadir un bloque personalizado",
    "gui.customProcedures.addAnInputNumberText": "Agregar una entrada",
    "gui.customProcedures.addAnInputBoolean": "Agregar una entrada",
    "gui.customProcedures.numberTextType": "número o texto",
    "gui.customProcedures.booleanType": "booleano",
    "gui.customProcedures.addALabel": "Agregar una etiqueta",
    "gui.customProcedures.runWithoutScreenRefresh": "Ejecutar sin actualizar la pantalla",
    "gui.customProcedures.cancel": "Cancelar",
    "gui.customProcedures.ok": "De acuerdo",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "Robot con fines educativos de DFRobot",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "Robot Explorer D1 de DFRobot",
    "gui.extension.arduinounoR3.name": "Kit Arduino uno de fuente abierta",
    "gui.extension.arduinounoR3.description": "Curso de kit de la tarjeta de control principal basada en arduino uno elaborado con Wuhan Maker",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "Carrito automático basado en la plataforma de micro:bit",
    "gui.extension.max.name": "Carrita Max",
    "gui.extension.max.description": "Carrito automático basado en la plataforma de arduino",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "tarjeta de expansión exclusiva para el uso del servomotor de microbit",
    "gui.extension.maqueen.name": "micro:Maqueen",
    "gui.extension.maqueen.description": "Carrito automático básico basado en la plataforma micro:bit",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.microbit.description": "Conecta tus proyectos con el mundo.",
    "gui.extension.spread.name": "micro:bit & mPython expansion board",
    "gui.extension.spreadMaqueen.name": "maqueen extension",
    "gui.extension.spread.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadmaqueen.description": "An entry-level minicar based on the control version",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "The advanced educational robot that supports both micro:bit and mPython",
    "gui.extension.iot.name": "Internet of Things",
    "gui.extension.iot.description": "Extended Edition Based on Internet of Things",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Dispositivos controlados por tarjeta de control principal de Leonardo",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Dispositivos controlados por tarjeta de control principal de Arduino Uno",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Dispositivos controlados por tarjeta de control principal de Arduino Nano",
    "gui.extension.mpython.name": "Tarjeta de control",
    "gui.extension.mpython.description": "Tarjeta de control basado en ESP32",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Dispositivos controlados por tarjeta de control principal de Mega 2560",
    "gui.extension.dfr0299.name": "DFPlayer Bloque de MP3",
    "gui.extension.dfr0299.description": "Reproducir archivo de sonido del tipo MP3 y WAV",
    "gui.extension.ser0006.name": "Bloque de servomotor",
    "gui.extension.ser0006.description": "Bloque de servomotor",
    "gui.extension.dfr0523.name": "bomba peristáltica",
    "gui.extension.dfr0523.description": "Apretar y soltar los tubos de la bomba peristáltica para transportar líquido",
    "gui.extension.dfr0017.name": "Relay module",
    "gui.extension.dfr0017.description": "Para controlar dispositivos de alta corriente",
    "gui.extension.steppermotor.name": "Motor de pasos",
    "gui.extension.steppermotor.description": "Se emplea para imprimador 3D, robots y plataforma móvil de tamaño medio",
    "gui.extension.dfr0534.name": "Bloque de serie MP3",
    "gui.extension.dfr0534.description": "Reproducir música a través del código serie se soportan MP3, WAV y WMA",
    "gui.extension.servo360.name": "Servomotor mini de 360 grados",
    "gui.extension.servo360.description": "Control exclusivo de dirección y velocidad, no ángulo",
    "gui.extension.tel0118.name": "Bloque de internet de objetos OBLOQ",
    "gui.extension.tel0118.description": "Recibir y emitir mensajes de IOT a través de Wi-Fi",
    "gui.extension.dfr0095.name": "Bloque de emisor infrarrojo",
    "gui.extension.dfr0095.description": "Se puede emitir señal infrarroja de 38KHz",
    "gui.extension.dfr0094.name": "Bloque de recibidor infrarrojo",
    "gui.extension.dfr0094.description": "Se puede recibir señal infrarroja de 38KHz",
    "gui.extension.tel0094.name": "Bloque de recibidor de señal de GPS",
    "gui.extension.tel0094.description": "Bloque de recibidor de señal de GPS",
    "gui.extension.tel0026.name": "Bloque de bluetooth",
    "gui.extension.tel0026.description": "Bloque de serie de bluetooth",
    "gui.extension.dfr0486.name": "Pantalla OLED-12864",
    "gui.extension.dfr0486.description": "Pantalla de tamaño pequeño, sirve para los equipos portátiles",
    "gui.extension.fit0352.name": "Luz RGB WS2812",
    "gui.extension.fit0352.description": "Controlar la cinta de LED para tener efectos de parpadear,  cambiar colores, etc.",
    "gui.extension.dfr0063.name": "Bloque LCD1602 (Puerto I2C)",
    "gui.extension.dfr0063.description": "Pantalla LCD, capacidad de 2 líneas de visualización, 16 bits cada línea",
    "gui.extension.dfr0021.name": "Bloque de LED",
    "gui.extension.dfr0021.description": "Se ilumina con alta nivel eléctrico y se atenúa con bajo nivel eléctrico",
    "gui.extension.tm1650.name": "Tubo digital de 4 segmentos TM1650",
    "gui.extension.tm1650.description": "Bloque de visualización, sirve para temporizadores y indicadores etc.",
    "gui.extension.matrix8_8.name": "matrix 8x8 MAX7219",
    "gui.extension.matrix8_8.description": "Bloque mini de matrix",
    "gui.extension.dfr0522.name": "Matrix mini 8×16 de RGB para la visualización de expresiones",
    "gui.extension.dfr0522.description": "Matrix mini 8×16 de RGB para la visualización de expresiones",
    "gui.extension.music.name": "Música",
    "gui.extension.music.description": "Toca instrumentos y tambores.",
    "gui.extension.pen.name": "Lápiz",
    "gui.extension.pen.description": "Dibuja con tus objetos.",
    "gui.extension.video-sensing.name": "Detección de vídeos",
    "gui.extension.video-sensing.description": "Detectar movimientos a través de cámara",
    "gui.extension.google-translate.name": "Traducción de Google",
    "gui.extension.google-translate.description": "Traducir textos a varios idiomas",
    "gui.extension.text-speech.name": "Leer textos",
    "gui.extension.text-speech.description": "hacer que hable tu proyecto",
    "gui.extension.softSerialport.name": "Software Serial Port",
    "gui.extension.softSerialport.description": "Utilizar puerto I/O para simular la función de comunicación",
    "gui.extension.IICScan.name": "Escaneo de dirección I2C",
    "gui.extension.IICScan.description": "Escanear todas las direcciones de equipos conectadas al puerto de I2C",
    "gui.extension.bluetooth.name": "Bluetooth",
    "gui.extension.bluetooth.description": "Dispositivos de bluetooth",
    "gui.extension.goble.name": "Boble",
    "gui.extension.goble.description": "Se puede controlar la tarjeta de control a través del programa GoBle",
    "gui.extension.interrupt.name": "Interrupt",
    "gui.extension.interrupt.description": "Interrumpir la clavija",
    "gui.extension.multithread.name": "Hilos múltiples",
    "gui.extension.multithread.description": "Permitido ejecutar varios programas",
    "gui.extension.speech.name": "Speech",
    "gui.extension.speech.description": "Hablar con tu proyecto",
    "gui.extension.sen0001.name": "Sensor ultrasónico",
    "gui.extension.sen0001.description": "Medición precisa, con el rango de 2 a 800 cm",
    "gui.extension.dfr0023.name": "Sensor Analógico de temperatura lineal LM35",
    "gui.extension.dfr0023.description": "Medir temperatura, rango: 0 - 100 centígrados",
    "gui.extension.dhtTHSensor.name": "Sensor de temperatura y humedad DHT11/22",
    "gui.extension.dhtTHSensor.description": "Medir la temperatura y humedad ambiental",
    "gui.extension.dsTSensor.name": "Sensor de temperatura DS18B20",
    "gui.extension.dsTSensor.description": "Medir temperatura, rango: -55 - +125 centígrados",
    "gui.extension.sen0203.name": "Sensor del ritmo cardíaco",
    "gui.extension.sen0203.description": "Medir el ritmo cardíaco estático",
    "gui.extension.sen0177.name": "Sensor de láser de PM2.5",
    "gui.extension.sen0177.description": "Medir la concentración de partículas en el aire, ej. PM2.5",
    "gui.extension.sen0014.name": "Sensor de distancia infrarrojo",
    "gui.extension.sen0014.description": "Medir distancia, rango: 10- 80 cm",
    "gui.extension.sen0204.name": "Sensor del nivel de líquido no tacto",
    "gui.extension.sen0204.description": "Sensor del nivel de líquido no tacto dentro de un contenedor cerrado ",
    "gui.extension.sen0161.name": "medidor analógico de PH",
    "gui.extension.sen0161.description": "Medir el valor PH de líquido, con voltaje de 5V",
    "gui.extension.sen0161-v2.name": "medidor analógico de PH (V2)",
    "gui.extension.sen0161-v2.description": "Medir el valor PH de líquido, con voltaje de 5V",
    "gui.extension.sen0244.name": "Sensor analógico de TDS",
    "gui.extension.sen0244.description": "Medir TDS de líquido, refleja la limpieza de agua",
    "gui.extension.sen0165.name": "Medidor analógico ORP",
    "gui.extension.sen0165.description": "Medir ORP de líquido, para evaluar la calidad de agua",
    "gui.extension.sen0237.name": "Medidor analógico ORP",
    "gui.extension.sen0237.description": "medir la cantidad de oxígeno en el agua para evaluar la calidad de agua",
    "gui.extension.dfr0300-H.name": "Medidor analógico EC",
    "gui.extension.dfr0300-H.description": "Medir la conductividad eléctrica de líquido para evaluar la calidad de agua",
    "gui.extension.dfr0300.name": "Medidor analógico EC (V2)",
    "gui.extension.dfr0300.description": "Medir la conductividad eléctrica de líquido para evaluar la calidad de agua",
    "gui.extension.sen0170.name": "Anemómetro",
    "gui.extension.sen0170.description": "Medir la velocidad de viento",
    "gui.extension.sen0226.name": "Sensor de temperatura BMP280",
    "gui.extension.sen0226.description": "Medir la temperatura y la presión atmosférica",
    "gui.extension.sen0228.name": "Sensor del brillo ambiental",
    "gui.extension.sen0228.description": "I2C VEML7700 Ambient Light Sensor(0~120Klx)",
    "gui.extension.dfr0022.name": "Sensor analógico de escala de grises",
    "gui.extension.dfr0022.description": "Detectar diferentes colores para patrullar la línea",
    "gui.extension.dfr0552.name": "12-bit DA conversion module",
    "gui.extension.dfr0552.description": "Convert the digital quantity to the corresponding dc voltage signal accurately (non-pwm)",
    "gui.extension.dfr0576.name": "I2C cascade extender",
    "gui.extension.dfr0576.description": "Used to resolve I2C device address conflicts",
    "gui.extension.sen0248.name": "BME680 environmental sensor",
    "gui.extension.sen0248.description": "VOC (volatile organic compounds), temperature, humidity and air pressure can be measured",
    "gui.extension.sen0304.name": "I2C ultrasonic ranging sensor",
    "gui.extension.sen0304.description": "Ultrasonic sensors designed for rapid ranging or obstacle avoidance applications",
    "gui.extension.sen0307.name": "Analog ultrasonic ranging sensor",
    "gui.extension.sen0307.description": "Open dual probe analog ultrasonic ranging module",
    "gui.extension.sen0250.name": "BMI160 6 axis inertial motion sensor",
    "gui.extension.sen0250.description": "16-bit 3-axis acceleration + ultra-low power consumption 3-axis gyroscope",
    "gui.extension.sen0224.name": "I2C LIS2DH triaxial accelerometer",
    "gui.extension.sen0224.description": "Ultra-low power consumption 3 axis acceleration sensor",
    "gui.extension.dfr0151.name": "Reloj de tiempo real DS1307",
    "gui.extension.dfr0151.description": "Producir año, mes, día, semana, hora, minuto, segundo",
    "gui.extension.dfr0469.name": "Reloj de tiempo real SD2405",
    "gui.extension.dfr0469.description": "Producir año, mes, día, semana, hora, minuto, segundo",
    "gui.extension.dfr0126.name": "Bloque de analizador de espectro",
    "gui.extension.dfr0126.description": "Analizar las 7 frecuencias de sonido",
    "gui.extension.dfr0231.name": "Bloque de NFC de puerto serie",
    "gui.extension.dfr0231.description": "Sirve para la comunicación inalámbrica de distancia corta, se puede leer la tarjeta y escribir datos en ella",
    "gui.extension.sen0245.name": "Sensor láser de medición de distancia VL5310",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "Sensor analógico del brillo ambiental",
    "gui.extension.dfr0026.description": "Medir la intensidad del brillo ambiental",
    "gui.extension.dfr0027.name": "Sensor digital de la vibración",
    "gui.extension.dfr0027.description": "detectar señal de vibración, producir señal digital",
    "gui.extension.dfr0028.name": "Sensor digital de la inclinación",
    "gui.extension.dfr0028.description": "Sirve como interruptor, no se puede medir el ángulo de inclinación",
    "gui.extension.dfr0029.name": "Bloque de botón digital grande",
    "gui.extension.dfr0029.description": "Producir el nivel alto eléctrico al pulsarlo, nivel balo elcéctrico al soltarlo",
    "gui.extension.dfr0030.name": "Sensor digital de tacto",
    "gui.extension.dfr0030.description": "Interruptor de tactos, se puede detectar a los seres humanos, metales, etc.",
    "gui.extension.dfr0033.name": "Sensor digital adhesivo magnético",
    "gui.extension.dfr0033.description": "Detectar objetos magnéticos, rango 3cm",
    "gui.extension.dfr0034.name": "sensor analógico de sonido",
    "gui.extension.dfr0034.description": "Medir la intensidad de sonido",
    "gui.extension.sen0132.name": "Sensor analógico de monóxido de carbono ",
    "gui.extension.sen0132.description": "Medir la concentración del monóxido de carbono, rango: 20-2000ppm",
    "gui.extension.dfr0051.name": "Bloque analógico de medición de voltaje",
    "gui.extension.dfr0051.description": "Medir el voltaje de corriente continua debajo de 25v",
    "gui.extension.dfr0052.name": "Sensor analógico de vibración de piezoeléctrico ",
    "gui.extension.dfr0052.description": "Detectar señal de vibración, producir señal analógica",
    "gui.extension.dfr0058.name": "Sensor analógico de rotación",
    "gui.extension.dfr0058.description": "Ajustar el voltaje basado en potenciómetro de alta precisión",
    "gui.extension.dfr0061.name": "Bloque de mando Joystick",
    "gui.extension.dfr0061.description": "Mando personalizable, 2 ejes de salida analógica, una salida digital",
    "gui.extension.dfr0072.name": "Shiftout Sensor",
    "gui.extension.dfr0072.description": "description for the 'Shiftout sensor' extension",
    "gui.extension.dfr0563.name": "battery Sensor",
    "gui.extension.dfr0563.description": "description for the 'battery sensor' extension",
    "gui.extension.dfr0553.name": "16-bit AD conversion module",
    "gui.extension.dfr0553.description": "description for the '16-bit AD conversion module' extension",
    "gui.extension.dfr0076.name": "Analog Flame sensor",
    "gui.extension.dfr0076.description": "detectar fuego o luz con la longitud de onda entre 760 y 1100 nm",
    "gui.extension.dfr0143.name": "Sensor de aceleración de 3 ejes",
    "gui.extension.dfr0143.description": "Detectar el estado físico de un objeto, la dirección de su movimiento",
    "gui.extension.sen0018.name": "Sensor térmico infrarrojo de movimiento",
    "gui.extension.sen0018.description": "Detectar la radiación infrarroja que produce una persona o un animal en movimiento",
    "gui.extension.sen0114.name": "Analog Soil Moisture Sensor",
    "gui.extension.sen0114.description": "Detectar la humedad del suelo, el valor que produce se disminuye cuando le falta agua",
    "gui.extension.sen0121.name": "Sensor de vapor",
    "gui.extension.sen0121.description": "Detectar la lluvia, niebla y vapor",
    "gui.extension.sen0212.name": "Sensor de colores",
    "gui.extension.sen0212.description": "Reconocer los colores de la superficie de un objeto, produce valores RGB",
    "gui.extension.sen0253.name": "Sensor de postura 10DOF",
    "gui.extension.sen0253.description": "Sensor direccional absoluto BNO055+BMP280",
    "gui.extension.sen0290.name": "lightning sensor",
    "gui.extension.sen0290.description": "intelligent lightning sensor",
    "gui.extension.sen0202.name": "Mini sensor 3D de reconocimiento de gestos de mano",
    "gui.extension.sen0202.description": "Detectar la dirección de movimiento de los dedos",
    "gui.extension.iobox.name": "micro:1O-BOX",
    "gui.extension.iobox.description": "Tarjeta de expansión exclusiva para la batería de litio de micro:bit",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "Hacer el dispositivo conectar a internet a través de Wi-Fi",
    "gui.extension.udp.name": "UDP broadcast",
    "gui.extension.udp.description": "Let devices and devices in the local area network realize multi-machine broadcast communication through UDP protocol",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "Hacer que el dispositivo pueda conectar utilizando el protocolo MQTT",
    "gui.extension.speechRecognition.name": "Reconocimiento de Voz",
    "gui.extension.speechRecognition.description": "Reconocimiento de Voz",
    "gui.gui.loaded": "Cargado",
    "gui.gui.notLoaded": "NO cargado",
    "gui.gui.cantFindYouWant": "¿No puedes encontrar lo que quieres?",
    "gui.gui.clickHere": "Haz clic aquí",
    "gui.gui.viewHelp": "Ver ayuda",
    "gui.gui.uploadToDev": "Subir al dispositivo",
    "gui.gui.compileAndUpload": "Compilar y subir",
    "gui.gui.compileOnly": "Solo compilar",
    "gui.gui.compileOpen": "Compilar y abrir",
    "gui.gui.burnBootloader": "Grabar bootloader inalámbrico",
    "gui.gui.arduinoRun": "Ejecutar",
    "gui.gui.arduinoSave": "Guardar",
    "gui.gui.autoGenerated": "Generar automático",
    "gui.gui.manualEditing": "Edición manual",
    "gui.gui.codeBox": "Caja de código",
    "gui.gui.moduleCode": "Código de módulo",
    "gui.menu.edit.undo": "Deshacer",
    "gui.menu.edit.redo": "Rehacer",
    "gui.menu.edit.selectAll": "Seleccionar todo",
    "gui.menu.edit.cut": "Cortar",
    "gui.menu.edit.copy": "Copiar",
    "gui.menu.edit.paste": "Pegar",
    "gui.menu.edit.codeStyle": "Estilo de código",
    "gui.menu.edit.fontSize": "Tamaño de texto",
    "gui.menu.edit.clearCache": "Borrar caché ",
    "gui.menu.edit.maxReset": "Max restablece las configuraciones de fábrica",
    "gui.gui.serial": "Serie",
    "gui.gui.openSerial": "Abrir serie",
    "gui.gui.closeSerial": "Cerrar serie",
    "gui.gui.close": "close",
    "gui.gui.open": "open",
    "gui.gui.clearOutput": "Borrar salida",
    "gui.gui.scroll": "Desplazar la página",
    "gui.gui.send": "Enviar",
    "gui.gui.baud": "Frecuencia en baudios",
    "gui.gui.noLineEnd": "no finales de línea",
    "gui.gui.newLine": "cambio de línea",
    "gui.gui.carriageReturn": "Entrar",
    "gui.gui.bothNLandCR": "Entrar y cambiar la línea",
    "gui.gui.unavailableHexData": "Invalid hex form! Please represent hex data with two characters ((0-9/A-F) and a blank, e.g. \"00 01 A2 FF\".",
    "gui.gui.hexForm": "Hex Form",
    "gui.gui.openHexForm": "Use hex form to show and send data",
    "gui.gui.closeHexForm": "Close",
    "gui.progress.burning": "Is burning",
    "gui.progress.compiling": "Compilando",
    "gui.burner.compileError": "Compile error",
    "gui.burner.linkError": "Link error",
    "gui.burner.generateHexError": "Generate .hex error",
    "gui.burner.generateBinError": "Generate .bin error",
    "gui.burner.burnError": "Burn error",
    "gui.burner.eraseError": "Erase error",
    "gui.burner.findLeonardoPortError": "Cannot find bootlaoder port of Leonardo",
    "gui.burner.noMicrobit": "Sorry it is not micro: bit, please connect micro: bit",
    "gui.burner.UDiskPopUp": "UDisk pops up, please wait for a while and try again",
    "gui.burner.UDiskWriteError": "Write to UDisk error",
    "gui.burner.openPortError": "Could not open port: Access is denied",
    "gui.burner.printObjectTooBig": "The project is too large; please click the \"help\" in the right top to reduce its size. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooBig": "Not enough memory; please click the \"help\" in the right top to reduce memory occupancy. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Low memory available, stability problems may occur. ",
    "gui.burner.printSizeError": "No se pudo determinar el tamaño del programa ",
    "gui.burner.swdCfgError": "Cannot write to cmsis-dap.cfg",
    "gui.burner.printSize": "El proyecto utiliza [%TEXTSIZE] bytes, ocupa ([%TEXTSIZEPRE]) espacio de memoria del programa, dejando [%TEXTSIZESUR] bytes, hasta [%TEXTSIZEMAX] bytes. La \nVariable global usa [%DATASIZE] bytes, ([%DATASIZEPRE]) de memoria dinámica, dejando [%DATASIZESUR] byte variables locales, hasta [%DATASIZEMAX] bytes. ",
    "gui.progress.compileSuccess": "compile success",
    "gui.progress.compileProgress": "Progreso de compilación",
    "gui.progress.uploadProgress": "Progreso de subida",
    "gui.progress.uploadSuccess": "upload success",
    "gui.codeLabel.dynamicVariables": "Dynamic variables",
    "gui.codeLabel.functionDeclaration": "Function declaration",
    "gui.codeLabel.staticConstants": "Static constants",
    "gui.codeLabel.createObject": "Create an object",
    "gui.codeLabel.mainProgramStart": "Main program start",
    "gui.codeLabel.SubthreadProgramStarts": "Subthread %1 program starts",
    "gui.codeLabel.customFunction": "Custom function",
    "gui.codeLabel.eventCallbackFunction": "Event callback function",
    "gui.codeLabel.globalCode": "Global variables",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "No variables allowed!",
    "gui.codeLabel.staticFunctions": "Static functions",
    "gui.progress.errMsg": "Error de subida, ver información detallada en la ventana en la parte inferior derecha",
    "gui.howtos.spin-around.name": "Girar",
    "gui.howtos.spin.step_dragTurn": "Arrastrar un bloque [giro] ",
    "gui.howtos.spin.step_clickTurn": "Hacer clic en el bloque para ejecutar",
    "gui.howtos.spin.step_clickControl": "Hacer clic en el bloque [control]  a la izquierda",
    "gui.howtos.spin.step_dragForever": "Hacer clic en el bloque [ejecución circulatoria]  a la izquierda",
    "gui.howtos.spin.step_clickForever": "Click the stack to run it",
    "gui.howtos.spin.step_changeColor": "Añadir un bloque de [aumentar los efectos de imagen] ",
    "gui.howtos.say-hello.name": "Decir hello",
    "gui.howtos.say-hello.step_addSprite": "Añadir un duende",
    "gui.howtos.say-hello.step_clickLooks": "Hacer clic en el bloque [aparición] ",
    "gui.howtos.say-hello.step_dragSay": "Arrastrar un bloque [decir] ",
    "gui.howtos.say-hello.step_clickSay": "Hacer clic en el bloque para ejecutar",
    "gui.howtos.say-hello.step_anotherSay": "Arrastrar otro bloque [decir] ",
    "gui.howtos.say-hello.step_editSay": "Editar bloque",
    "gui.howtos.say-hello.step_clickStack": "Click the stack to run it",
    "gui.howtos.run-away.name": "Huir",
    "gui.howtos.run-away.step_dragGoTo": "Arrastrar un bloque [movimiento al alzar] ",
    "gui.howtos.run-away.step_clickGoTo": "Hacer clic en el bloque para ejecutar",
    "gui.howtos.run-away.step3": "Hacer clic en el bloque de [evento] ",
    "gui.howtos.run-away.step_addWhenClicked": "Añadir un bloque de [cuando hacer clic en el personaje] ",
    "gui.howtos.run-away.step_clickSprite": "Hacer clic en el personaje para ejecutar",
    "gui.howtos.run-away.step_addSound": "Añadir un bloque de [empezar a reproducir sonido] ",
    "gui.howtos.pick-up-apple.name": "pick-up apple",
    "gui.howtos.pick-up-apple.step_1": "Cambiar a la interfaz de ejecución interpretiva",
    "gui.howtos.pick-up-apple.step_2": "Cambiar producto",
    "gui.howtos.pick-up-apple.step_3": "Connect the device",
    "gui.howtos.pick-up-apple.step_4": "Calibrar la brújula",
    "gui.howtos.pick-up-apple.step_5": "Abrir el programa de ejemplo",
    "gui.howtos.pick-up-apple.step_6": "Coger una manzana",
    "gui.howtos.move-left-right.name": "move left right",
    "gui.howtos.move-left-right.step_1": "Nuevo Proyecto",
    "gui.howtos.move-left-right.step_2": "arrastrar el bloque 1",
    "gui.howtos.move-left-right.step_3": "arrastrar el bloque 2",
    "gui.howtos.move-left-right.step_4": "gatito de radiocontrol de micro:bit",
    "gui.howtos.install-driver.name": "Instalar driver ",
    "gui.howtos.transform-expression.name": "Smile or Cry",
    "gui.howtos.transform-expression.step_1": "Cambiar a la interfaz de la subida de ejecución",
    "gui.howtos.transform-expression.step_2": "Cambiar producto",
    "gui.howtos.transform-expression.step_3": "arrastrar el bloque 1",
    "gui.howtos.transform-expression.step_4": "arrastrar el bloque 2",
    "gui.howtos.transform-expression.step_5": "arrastrar el bloque 3",
    "gui.howtos.transform-expression.step_6": "Pulsar para visualizar expresión",
    "gui.howtos.lot.name": "IOT (Internet of Things)",
    "gui.howtos.lot.step_1": "Seleccionar el programa de ejemplo",
    "gui.howtos.lot.step_2": "Grabar el programa",
    "gui.howtos.lot.step_3": "Internet de objetos",
    "gui.howtos.touch-pin.name": "touch pin",
    "gui.howtos.touch-pin.step_1": "Cambiar a la interfaz de la subida de ejecución",
    "gui.howtos.touch-pin.step_2": "Seleccionar el programa de ejemplo",
    "gui.howtos.touch-pin.step_3": "Grabar el programa",
    "gui.howtos.touch-pin.step_4": "touch pin",
    "gui.howtos.save-witch.name": "Save Witch",
    "gui.howtos.save-witch.step_1": "Cambiar a la ejecución en tiempo real",
    "gui.howtos.save-witch.step_2": "Seleccionar la tarjeta [micro:bit]  en la expansión",
    "gui.howtos.save-witch.step_3": "Seleccionar [salvar a la bruja]  en el programa de ejemplo",
    "gui.howtos.save-witch.step_4": "Conectar el dispositivo, seleccionar microbit, esperar la conexión",
    "gui.howtos.save-witch.step_5": "Si aparece el aviso [calibrar dispositivo]  rota la tarjeta de control principal hasta que se atenúen los LEDs",
    "gui.howtos.save-witch.step_6": "Hacer clic en la bandera verde, interactúa con la animación a través de mover tu brazo",
    "gui.howtos.microbit-touch.name": "microbit cambia de expresiones al un toque",
    "gui.howtos.microbit-touch.step_1": "Cambiar a la interfaz de la subida de ejecución",
    "gui.howtos.microbit-touch.step_2": "Abrir [programa de ejemplo]  bajo [aprender] , seleccionar [toque de clavija] ",
    "gui.howtos.microbit-touch.step_3": "Conectar el puerto COM correspondiente, hacer clic en [subir al dispositivo] ",
    "gui.howtos.microbit-touch.step_4": "Visualizar distintas expresiones al pulsar diferentes botones",
    "gui.howtos.microbit-calibration.name": "Calibrar micro:bit",
    "gui.howtos.microbit-calibration.step_1": "Poner la tarjeta microbit perpendicular al suelo y rotar. Los LEDs integrados en la tarjeta se iluminan respectivamente",
    "gui.howtos.microbit-calibration.step_2": "Hasta que se iluminan todos los LEDs se completa la calibración, aparecerá un emoticono sonriente",
    "gui.extension.sen0251.name": "Sensor de temperatura y la presión atmosférica BMP388",
    "gui.extension.sen0251.description": "Tiene la función de medir la temperatura y la presión atmosférica",
    "gui.extension.sen0206.name": "Sensor infrarrojo no tacto de temperatura",
    "gui.extension.sen0206.description": "Far-infrared non-contact measurement of object or ambient temperature",
    "gui.gui.recentlyEdited": "Recién editado",
    "gui.extension.weather.name": "Obtener información de tiempo",
    "gui.extension.weather.description": "Obtener información de tiempo en tiempo real",
    "gui.extension.tinywebdb.name": "tinywebdb",
    "gui.extension.tinywebdb.description": "operar la base de datos tinywebdb",
    "gui.gui.variableScopeOptionAllSprites": "Para todos los objetos",
    "gui.gui.variableScopeOptionSpriteOnly": "Sólo para este objeto",
    "gui.gui.variablePromptAllSpritesMessage": "Esta variable estará disponible para todos los objetos.",
    "gui.monitor.contextMenu.default": "tamaño normal",
    "gui.monitor.contextMenu.large": "tamaño grande",
    "gui.monitor.contextMenu.slider": "deslizador",
    "gui.monitor.contextMenu.import": "importar",
    "gui.monitor.contextMenu.export": "exportar",
    "gui.monitor.listMonitor.listLength": "largo {length}",
    "gui.monitor.listMonitor.empty": "(vacío)",
    "gui.costumeTab.backdrop": "fondo",
    "gui.costumeTab.costume": "postura",
    "gui.opcodeLabels.direction": "dirección",
    "gui.opcodeLabels.xposition": "posición x",
    "gui.opcodeLabels.yposition": "posición y",
    "gui.opcodeLabels.size": "tamaño",
    "gui.opcodeLabels.costumename": "nombre del disfraz",
    "gui.opcodeLabels.costumenumber": "número de disfraz",
    "gui.opcodeLabels.backdropname": "nombre de fondo",
    "gui.opcodeLabels.backdropnumber": "número de fondo",
    "gui.opcodeLabels.volume": "volumen",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "respuesta",
    "gui.opcodeLabels.loudness": "volumen del sonido",
    "gui.opcodeLabels.year": "año",
    "gui.opcodeLabels.month": "mes",
    "gui.opcodeLabels.date": "fecha",
    "gui.opcodeLabels.dayofweek": "día de la semana",
    "gui.opcodeLabels.hour": "hora",
    "gui.opcodeLabels.minute": "minuto",
    "gui.opcodeLabels.second": "segundo",
    "gui.opcodeLabels.timer": "cronómetro",
    "gui.loadProject.error": "load project error",
    "gui.fileSystem.boardFiles": "Board Files",
    "gui.fileSystem.computerFiles": "Board Computer",
    "gui.fileSystem.newFile": "new file",
    "gui.fileSystem.newFolder": "new folder",
    "gui.fileSystem.deleteFolder": "delete folder",
    "gui.fileSystem.openFolder": "open folder",
    "gui.fileSystem.openInEditor": "open in editor",
    "gui.fileSystem.runRightNow": "run right now",
    "gui.fileSystem.stopRun": "stop run",
    "gui.fileSystem.setBoot": "boot run",
    "gui.fileSystem.deleteForever": "delete forever",
    "gui.fileSystem.saveAs": "save as",
    "gui.fileSystem.undoAllEdits": "cancel all edits",
    "gui.fileSystem.copyToComputer": "copy to computer",
    "gui.fileSystem.uploadToBoard": "upload to board",
    "gui.fileSystem.uploadAndRun": "upload and run",
    "music.categoryName": "Música",
    "translate.categoryName": "Traductor",
    "sensor.categoryName": "Sensor",
    "actuator.categoryName": "Actuador",
    "communicate.categoryName": "Comunicar",
    "display.categoryName": "Pantalla",
    "extendFunction.categoryName": "Función Extendida",
    "internetService.categoryName": "Servicio de Internet",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "Tarjeta de control",
    "spread.categoryName": "Expansion Board",
    "speechRecognition.categoryName": "Reconocimiento de Voz",
    "gui.blocklyText.romeo.d1ProgramStart": "Inicia el programa de robot D1",
    "gui.blocklyText.romeo.readdigital": "read value pin[PIN] [MODULE] ",
    "gui.blocklyText.romeo.buttonModule": "Módulo de Botón",
    "gui.blocklyText.romeo.digitalSensor": "Sensor Digital",
    "gui.blocklyText.romeo.collisionSensor": "Sensor de Colisiones",
    "gui.blocklyText.romeo.lineSensor": "Sensor de Línea",
    "gui.blocklyText.romeo.infraredSwitch": "Interruptor de Infrarrojos",
    "gui.blocklyText.romeo.vibrationSensor": "Sensor de Vibración",
    "gui.blocklyText.romeo.readAnalog": "Leer el valor de la clavija [PIN] [SENSOR] ",
    "gui.blocklyText.romeo.lightSensor": "Sensor de Luz",
    "gui.blocklyText.romeo.anologSensor": "Sensor Analógico",
    "gui.blocklyText.romeo.soundSensor": "Sensor de Sonido",
    "gui.blocklyText.romeo.soilMoistureSensor": "Sensor de Humedad del Suelo",
    "gui.blocklyText.romeo.frameSensor": "Sensor de Fuego",
    "gui.blocklyText.romeo.open": "Encender",
    "gui.blocklyText.romeo.close": "Apagar",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] of pin [PIN] ",
    "gui.blocklyText.romeo.ledLights": "Luz LED",
    "gui.blocklyText.romeo.digitalActuator": "Actuador Digital",
    "gui.blocklyText.romeo.relay": "Relé",
    "gui.blocklyText.romeo.fan": "Ventilador",
    "gui.blocklyText.romeo.writeAnalog": "Establecer valor de clavija [PIN] [MODULE] como [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "Actuador de Simulación",
    "gui.blocklyText.romeo.fanModule": "Módulo de Fan",
    "gui.blocklyText.romeo.robotSpeedMove": "Robot [DIR] a la velocidad [SPEED]",
    "gui.blocklyText.romeo.forward": "move forward",
    "gui.blocklyText.romeo.backward": "move backward",
    "gui.blocklyText.romeo.turnLeft": "Girar a la izquierda",
    "gui.blocklyText.romeo.turnRight": "Girar a la derecha",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "make motor [MOTOR] move [DIR] at a speed of [SPEED]",
    "gui.blocklyText.romeo.interface": "Interfaz",
    "gui.blocklyText.romeo.motorForward": "Forward",
    "gui.blocklyText.romeo.motorReverse": "Backward",
    "gui.blocklyText.romeo.stopMotor": "Robot (Motor M1 y M2) para",
    "gui.blocklyText.romeo.readUltrasonic": "Lectura de distancia ultrasónica (cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "leer pin [PIN] LM35 temperatura (° C)",
    "gui.blocklyText.romeo.ds18b20": "leer pin [PIN] DS18B20 temperatura (° C)",
    "gui.blocklyText.romeo.pressInfrared": "Si la clavija [PIN] del sensor infrarrojo ha recibido la señal de pulsar el botón [BUTTON]",
    "gui.blocklyText.romeo.volumePlus": "Volume +",
    "gui.blocklyText.romeo.switch": "Interruptor",
    "gui.blocklyText.romeo.volumeMinus": "Volume-",
    "gui.blocklyText.romeo.startPause": "Empieza/Pausa",
    "gui.blocklyText.romeo.up": "Arriba",
    "gui.blocklyText.romeo.down": "Abajo",
    "gui.blocklyText.romeo.dataReadable": "¿Hay datos en el puerto serie?",
    "gui.blocklyText.romeo.readSerialData": "Leer datos en serie",
    "gui.blocklyText.romeo.timer": "Tiempo de Actividad del Sistema(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "Establecer la clavija [PIN] el tono de la trompeta [TONE] y el ritmo [BEAT]",
    "gui.blocklyText.romeo.half": "1/2",
    "gui.blocklyText.romeo.quarter": "1/4",
    "gui.blocklyText.romeo.oneInEighth": "1/8",
    "gui.blocklyText.romeo.wholeBeat": "Beat Doble",
    "gui.blocklyText.romeo.doubleBeat": "Beat entero",
    "gui.blocklyText.romeo.stop": "Parar",
    "gui.blocklyText.romeo.setServo": "Establece la clavija [PIN] grado del servomotor como [DEGREE]",
    "gui.blocklyText.romeo.segment": "Hacer el LED de 8 segmentos (D6-D13 pin) presentar [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "serial [WAY] [STRING] [LINE]",
    "gui.blocklyText.romeo.originalOutput": "original output",
    "gui.blocklyText.romeo.stringOutput": "string output",
    "gui.blocklyText.romeo.hexOutput": "HEX output",
    "gui.blocklyText.romeo.wrap": "Partir",
    "gui.blocklyText.romeo.noWrap": "No Partir",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "Leer datos en serie del tipo int o float [READTYPE]",
    "gui.blocklyText.romeo.int": "Entero",
    "gui.blocklyText.romeo.float": "Real",
    "gui.blocklyText.romeo.setBaudRate": "Establecer la frecuencia en baudios del puerto serie como [BAUD]",
    "gui.blocklyText.romeo.readPinWidth": "Leer la clavija [PIN] modo de la duración de impulsos [LEVEL] el límite de tiempo [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "Bajo",
    "gui.blocklyText.romeo.high": "Alto",
    "gui.blocklyText.romeo.noSound": "No hay sonido",
    "gui.blocklyText.arduino.arduinoStarts": "Inicia Arduino",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Inicia Uno",
    "gui.blocklyText.esp32.displayInLine": "display [TEXT] in the [LINE] line",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "clear cache in the [LINE] line",
    "gui.blocklyText.esp32.displayClearInLine": "clear in the [LINE] line",
    "gui.blocklyText.esp32.arduinoUnoStarts": "Inicia ESP32",
    "gui.blocklyText.esp32.btnIsPressed": "El botón [BUTTON] está [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenBtnPress": "Cuando el botón [REFERENCE] está [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "Cuando el botón de tacto [REFERENCE] está [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "When [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "Cuando el botón de tacto [PIN] está [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "Para la reproducción de fondo",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "Reproducción de la nota [NOTE] de fondo",
    "gui.blocklyText.esp32.setBuzzerFreq": "Reproducir la nota [NOTE] al rítmo de [BEAT]",
    "gui.blocklyText.esp32.getSoundIntensity": "Leer la intensidad de sonido del micrófono",
    "gui.blocklyText.esp32.readBrightness": "read environmental brightness",
    "gui.blocklyText.esp32.setLightsColor": "Luz [LIGHTS] muestra el color de [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "Rojo [RED] Verde [GREEN] Azul [BLUE]",
    "gui.blocklyText.esp32.acceleration": "Leer aceleración(mg) [TYPE]",
    "gui.blocklyText.esp32.displayInXY": "Mostrar [TEXT] en el eje X:[X] eje Y:[Y] axis vista previa [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "Visualiza el color [COLOR] en la pantalla (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "Pone el color de la pantalla [COLORMPYTHON] en el caché (clear screen)",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": "blanco completo",
    "gui.blocklyText.esp32.lineDrawing": "trazar una línea desde x1:[X1] y1:[Y1] hasta x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "conectado",
    "gui.blocklyText.esp32.disconnected": "desconectado",
    "gui.blocklyText.esp32.rectangleDrawing": "dibujar un rectángulo [FILL] desde x:[X] y:[Y] con [WIDTH] de ancho y [HEIGHT] de alto",
    "gui.blocklyText.esp32.screenDisplay": "tiene efecto de la visualización de pantalla de el caché",
    "gui.blocklyText.esp32.circleDrawing": "dibujar un círculo [FILL] desde x:[X] y:[Y] con un radio de [RADIUS]",
    "gui.blocklyText.esp32.fill": "rellenar",
    "gui.blocklyText.esp32.notFill": "no rellenar",
    "gui.blocklyText.esp32.pointDrawing": "dibujar un punto en x:[X] y:[Y]",
    "gui.blocklyText.esp32.setLineWidth": "Establecer el ancho del trazo como [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "digital write [PIN] value [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "digital read [PIN]",
    "gui.blocklyText.esp32.analogWrite": "analog write(PWM) [PIN] value [VALUE]",
    "gui.blocklyText.esp32.analogRead": "analog read [PIN]",
    "gui.blocklyText.esp32.serialPort0": "serial0",
    "gui.blocklyText.esp32.serialPort1": "serial1",
    "gui.blocklyText.esp32.serialPort2": "serial2",
    "gui.blocklyText.esp32.seriaRemapping": "Establecer [SERIAL] Rx [RX] Tx [TX] con la frecuencia en baudios [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "Visualizar la imagen [IMAGE] en x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "Visualizar la imagen [IMAGE] del caché en x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "pulsado",
    "gui.blocklyText.esp32.loosened": "suelto",
    "gui.blocklyText.esp32.all": "todo",
    "gui.blocklyText.esp32.setBaud": "Establecer la frecuencia en baudios [BAUD] del serie0",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.esp32.setBrightness": "Configurar el brillo del LED [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "Leer la intensidad del brillo del LED",
    "gui.blocklyText.esp32.print": "Imprimir [DATA]",
    "gui.blocklyText.esp32.TiltForward": "Tilt forward",
    "gui.blocklyText.esp32.TiltBack": "Tilt back",
    "gui.blocklyText.esp32.TiltLeft": "Tilt left",
    "gui.blocklyText.esp32.TiltRight": "Tilt right",
    "gui.blocklyText.esp32.ScreenUp": "Screen up",
    "gui.blocklyText.esp32.ScreenDown": "Face down",
    "gui.blocklyText.esp32.Shake": "Shake",
    "gui.blocklyText.esp32.isGesture": "is [GESTURE]?",
    "gui.blocklyText.mega2560.functionStarts": "Iniciar Mega2560",
    "gui.blocklyText.mega2560.digitalRead": "digital read [PIN]",
    "gui.blocklyText.mega2560.analogRead": "analog read [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "Establecer la clavija de pwm [PIN] como [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "digital write [PIN] value [VALUE]",
    "gui.blocklyText.mega2560.level.low": "Bajo",
    "gui.blocklyText.mega2560.level.high": "Alto",
    "gui.blocklyText.mega2560.irqRead": "Leer la clavija digital [PIN] del recibidor infrarrojo",
    "gui.blocklyText.mega2560.ultrasonicRead": "Leer valor de la clavija trig de sensor ultrasónico [TRIG] echo [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "Establecer el ritmo [BEAT] y el tono [TONE] de la clavija [PIN] del zumbador",
    "gui.blocklyText.mega2560.harf": "Harf",
    "gui.blocklyText.mega2560.quarter": "1/4",
    "gui.blocklyText.mega2560.oneInEighth": "1/8",
    "gui.blocklyText.mega2560.wholeBeat": "Beat Doble",
    "gui.blocklyText.mega2560.doubleBeat": "Beat entero",
    "gui.blocklyText.mega2560.stop": "Parar",
    "gui.blocklyText.mega2560.servoSet": "Ajuste el servo pin [PIN] a [DEGREE] grados",
    "gui.blocklyText.mega2560.setBaud": "Establecer la frecuencia en baudios [BAUD] del serie [SERIAL]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "serial0",
    "gui.blocklyText.mega2560.serialPort1": "serial1",
    "gui.blocklyText.mega2560.serialPort2": "serial2",
    "gui.blocklyText.mega2560.serialPort3": "serial3",
    "gui.blocklyText.mega2560.dataReadable": "¿El dato del [SERIAL] es legible?",
    "gui.blocklyText.mega2560.readSerialData": "Leer dato de [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "en tipo cadena",
    "gui.blocklyText.mega2560.hexOutput": "en tipo HEX",
    "gui.blocklyText.mega2560.wrap": "Partir",
    "gui.blocklyText.mega2560.noWrap": "No Partir",
    "gui.blocklyText.mega2560.readPinLevel": "Leer la clavija [PIN] modo de la duración de impulsos [LEVEL] el límite de tiempo [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "Tiempo de Actividad del Sistema(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Iniciar Beetle Pro Mini",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Iniciar Leonardo",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Iniciar Mega2560",
    "gui.blocklyText.arduino.setDigital": "Establecer la salida de la clavija digital [PIN] como [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "Leer la clavija digital [PIN]",
    "gui.blocklyText.arduino.readSimilation": "Leer la clavija analógico [PIN]",
    "gui.blocklyText.arduino.setPwm": "Establecer la clavija de pwm [PIN] como [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "Lectura de distancia ultrasónica (cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "Establecer la clavija [PIN] el tono de la trompeta [TONE] y el ritmo [BEAT]",
    "gui.blocklyText.arduino.serialSendStr": "Serie [WAY] [STRING] [LINE]",
    "gui.blocklyText.arduino.originalOutput": "Salida original",
    "gui.blocklyText.arduino.stringOutput": "en tipo cadena",
    "gui.blocklyText.arduino.hexOutput": "en tipo HEX",
    "gui.blocklyText.arduino.wrap": "Partir",
    "gui.blocklyText.arduino.noWrap": "No Partir",
    "gui.blocklyText.arduino.readInfrared": "Leer la clavija digital [PIN] del recibidor infrarrojo",
    "gui.blocklyText.arduino.setBaud": "Establecer la frecuencia en baudios del puerto serie como [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "leer pin [PIN] patrón de ancho de pulso [LEVEL] tiempo de espera [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "No hay sonido",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Iniciar arduino nano",
    "gui.blocklyText.vortex.vortexStart": "Iniciar el programa del robot Vortex",
    "gui.blocklyText.vortex.vortexMoveSpeed": "El robot Vortex va a la dirección [DIRECTION] a la velocidad [SPEED]",
    "gui.blocklyText.vortex.forward": "Adelantarse",
    "gui.blocklyText.vortex.backup": "Marcha atrás",
    "gui.blocklyText.vortex.turnLeft": "Girar a la izquierda",
    "gui.blocklyText.vortex.turnRight": "Girar a la derecha",
    "gui.blocklyText.vortex.setMotor": "Configurar el motor [MOTOR] para ir a la dirección [DIRECTION] a la velocidad de [SPEED]",
    "gui.blocklyText.vortex.leftWheel": "Rueda izquierda",
    "gui.blocklyText.vortex.rightWheel": "Rueda derecha",
    "gui.blocklyText.vortex.setExpression": "Establecer el color [COLOR] de la expresión facial[EXPRESSION]",
    "gui.blocklyText.vortex.ledLights": "Hacer que el [DIRECTION][LIGHT] cambie a [COLOR] en [SECOND] segundos",
    "gui.blocklyText.vortex.all": "todo",
    "gui.blocklyText.vortex.rightAhead": "delante",
    "gui.blocklyText.vortex.leftRear": "trasero derecho",
    "gui.blocklyText.vortex.rightRear": "trasero izquierdo",
    "gui.blocklyText.vortex.rightAstern": "trasero",
    "gui.blocklyText.vortex.topLights": "luz superior",
    "gui.blocklyText.vortex.bottomLights": "luz inferior",
    "gui.blocklyText.vortex.vortexStop": "Parar Vortex",
    "gui.blocklyText.vortex.setVolume": "Ajustar el volumen al nivel [VALUE]",
    "gui.blocklyText.vortex.playMusic": "Reproducir la música del número [VALUE]",
    "gui.blocklyText.vortex.stopMusic": "Parar la música",
    "gui.blocklyText.vortex.startDance": "hacer el baile del número [VALUE]",
    "gui.blocklyText.vortex.stopDance": "Parar de bailar",
    "gui.blocklyText.vortex.barrierDetection": "¿ha detectado barreras?",
    "gui.blocklyText.vortex.readSensor": "Leer el sensor de la escala de grises [SENSOR]",
    "gui.blocklyText.vortex.setSensor": "Establecer la sensibilidad del sensor de la escala de grises como [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "¿El sensor de la escala de grises ha detectado el color negro [SENSOR]?",
    "gui.blocklyText.vortex.positiveFrontLeft": "hacia izquierda delantera",
    "gui.blocklyText.vortex.frontLeft": "izquierda delantera",
    "gui.blocklyText.vortex.positiveFrontRight": "hacia derecha delantera",
    "gui.blocklyText.vortex.frontRight": "derecha delantera",
    "gui.blocklyText.vortex.backRight": "trasero izquierdo",
    "gui.blocklyText.vortex.backLeft": "trasero derecho",
    "gui.blocklyText.vortex.initialOldPCB": "Inicializar a la versión antigua (PCB azul)",
    "pen.categoryName": "Lápiz",
    "pen.changeColorParam": "cambiar el [COLOR_PARAM] del lápiz en [VALUE]",
    "pen.changeHue": "cambiar el color del lápiz en [HUE]",
    "pen.changeShade": "cambiar la intensidad del lápiz en [SHADE]",
    "pen.changeSize": "cambiar el tamaño del lápiz en [SIZE]",
    "pen.clear": "borrar todo",
    "pen.colorMenu.brightness": "brillo",
    "pen.colorMenu.color": "color",
    "pen.colorMenu.saturation": "saturación",
    "pen.colorMenu.transparency": "transparencia",
    "pen.penDown": "lápiz abajo",
    "pen.penUp": "lápiz arriba",
    "pen.setColor": "establecer color de lápiz a [COLOR]",
    "pen.setColorParam": "establecer [COLOR_PARAM] de lápiz a [VALUE]",
    "pen.setHue": "establecer color de lápiz a [HUE]",
    "pen.setShade": "establecer intensidad de lápiz a [SHADE]",
    "pen.setSize": "establecer color de lápiz a [SIZE]",
    "pen.stamp": "sellar",
    "music.changeTempo": "cambiar el tempo en [TEMPO]",
    "music.drumBass": "(2) Bombo",
    "music.drumBongo": "(13) Bongó",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Charles Cerrado",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Cencerro",
    "music.drumCrashCymbal": "(4) Platillo Crash",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Güiro",
    "music.drumHandClap": "(8) Aplauso",
    "music.drumOpenHiHat": "(5) Charles abierto",
    "music.drumSideStick": "(3) Golpe lateral",
    "music.drumSnare": "(1) Caja",
    "music.drumTambourine": "(7) Pandereta",
    "music.drumTriangle": "(12) Triángulo",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Caja China",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bajo",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Violonchelo",
    "music.instrumentChoir": "(15) Coro",
    "music.instrumentClarinet": "(10) Clarinete",
    "music.instrumentElectricGuitar": "(5) Guitarra Eléctrica",
    "music.instrumentElectricPiano": "(2) Piano Eléctrico",
    "music.instrumentFlute": "(12) Flauta",
    "music.instrumentGuitar": "(4) Guitarra",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Caja de música",
    "music.instrumentOrgan": "(3) Órgano",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxofón",
    "music.instrumentSteelDrum": "(18) Tambor metálico",
    "music.instrumentSynthLead": "(20) Sintetizador melódico",
    "music.instrumentSynthPad": "(21) Sintetizador de fondo",
    "music.instrumentTrombone": "(9) Trombón",
    "music.instrumentVibraphone": "(16) Vibráfono",
    "music.instrumentWoodenFlute": "(13) Flauta de Madera",
    "music.midiPlayDrumForBeats": "tocar el tambor [DRUM] por [BEATS] pulsos",
    "music.midiSetInstrument": "fijar instrumento a [INSTRUMENT]",
    "music.playDrumForBeats": "tocar el tambor [DRUM] por [BEATS] pulsos",
    "music.playNoteForBeats": "tocar la nota [NOTE] por [BEATS] pulsos",
    "music.restForBeats": "descansar por [BEATS] pulsos",
    "music.setInstrument": "fijar instrumento a [INSTRUMENT]",
    "music.setTempo": "fijar tempo a [TEMPO]",
    "gui.blocklyText.microbit.microbitStarts": "Iniciar micro:bit",
    "gui.blocklyText.microbit.interruptExcute": "Interrumpir el modo [MODE] de la clavija [PIN]",
    "gui.blocklyText.microbit.cancleInterrupt": "Cancelar la interrupción de la clavija [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "Al recibir datos inalámbricos",
    "gui.blocklyText.microbit.whenBtnPress": "Al pulsar el botón [REFERENCE]",
    "gui.blocklyText.microbit.whenPinConnected": "Al conectar la clavija [REFERENCE]",
    "gui.blocklyText.microbit.whenPosChange": "Al [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "Visualizar patrón [PIC]",
    "gui.blocklyText.microbit.showInput": "Visualizar texto [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "Parar la animación",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] punto de coordenada （x:[XAXIS], y:[YXAXIS]）",
    "gui.blocklyText.microbit.hideAllLights": "Borrar todos los puntos de matriz",
    "gui.blocklyText.microbit.microbitEnableLight": "LED enable [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "Brillo",
    "gui.blocklyText.microbit.showLightWithBrightness": "Configurar el brillo [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "iluminar el punto el eje x:[XAXIS] , el eje y:[YXAXIS], brillo [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "La clavija [PIN] reproduce sonido de fondo [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "La clavija [PIN] reproduce sonido de fondo [SOUND] hasta el fin",
    "gui.blocklyText.microbit.playNote": "La clavija [PIN] reproduce nota [NOTE] al ritmo [BEAT]",
    "gui.blocklyText.maqueen.playSound": "La clavija0 reproduce sonido [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "La clavija0 reproduce sonido [SOUND] hasta el fin",
    "gui.blocklyText.maqueen.playNote": "La clavija0 reproduce nota [NOTE] al ritmo [BEAT]",
    "gui.blocklyText.maqueen.RGB0": "RGB0  (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1  (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2  (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3  (3)",
    "gui.blocklyText.maqueen.All": "All  (-1)",
    "gui.blocklyText.microbit.changeTempo": "Acelerar el tempo (bpm) por [VALUE]",
    "gui.blocklyText.microbit.setTempo": "Establecer el tempo (bpm) como [VALUE]",
    "gui.blocklyText.microbit.getTempo": "tempo (bpm)",
    "gui.blocklyText.microbit.stopSound": "Para la reproducción de fondo",
    "gui.blocklyText.microbit.btnIsPressed": "¿El botón [BUTTON] está pulsado?",
    "gui.blocklyText.microbit.pinIsConnected": "[PIN] ¿La clavija está conectado?",
    "gui.blocklyText.microbit.isMove": "¿El estado actual es [TYPE]?",
    "gui.blocklyText.microbit.compass": "Leer la dirección de la brújula",
    "gui.blocklyText.microbit.temperature": "Leer la temperatura",
    "gui.blocklyText.microbit.digitalWrite": "Establecer la clavija digital [PIN] como [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "Leer la clavija digital [PIN]",
    "gui.blocklyText.microbit.analogWrite": "Establecer la clavija analógica [PIN] como [VALUE]",
    "gui.blocklyText.microbit.analogRead": "Leer la clavija analógico [PIN]",
    "gui.blocklyText.microbit.up": "Logo arriba",
    "gui.blocklyText.microbit.down": "Logo abajo",
    "gui.blocklyText.microbit.left": "Inclinar lateral izquierda",
    "gui.blocklyText.microbit.right": "Inclinar lateral derecha",
    "gui.blocklyText.microbit.faceUp": "Pantalla arriba",
    "gui.blocklyText.microbit.faceDown": "Pantalla abajo",
    "gui.blocklyText.microbit.freefall": "Caída libre",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "Agitar",
    "gui.blocklyText.microbit.show": "Iluminar",
    "gui.blocklyText.microbit.hide": "Atenuar",
    "gui.blocklyText.microbit.low": "Bajo",
    "gui.blocklyText.microbit.high": "Alto",
    "gui.blocklyText.microbit.microbitReadBrightness": "Leer el brillo ambiental",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] comunicación inalámbrica",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "Establecer la canal inalámbrica como [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "Enviar cadena [TEXT] a través de conexión inalámbrica",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "Dato recibido de la conexión inalámbrica",
    "gui.blocklyText.microbit.strength": "fuerza",
    "gui.blocklyText.microbit.acceleration": "Leer el valor de la aceleración(mg)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "Leer el valor de la fuerza magnética(µT)[TYPE]",
    "gui.blocklyText.microbit.print": "Imprimir [DATA]",
    "gui.blocklyText.microbit.goUp": "Arriba",
    "gui.blocklyText.microbit.goDown": "Abajo",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "when received [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "wireless data",
    "gui.blocklyText.maxbot.microbitStarts": "Iniciar Maxbot",
    "gui.blocklyText.maxbot.playSound": "Reproducir sonido [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "Reproducir sonido [SOUND] hasta el fin",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "Colisión a la [LEFTRIGHT]",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "Línea negra detectado a la [LEFTRIGHT]",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "El motor [MOTOR] va a la dirección [DIR] a la velocidad de [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "Leer el sensor ultrasónico (P1,P2) con la unidad de distancia [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "Leer el sensor ultrasónico con la unidad [UNIT] trig [TRIG] echo [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "FORWARD",
    "gui.blocklyText.maxbot.BACKWARD": "BACKWARD",
    "gui.blocklyText.motorbit.CW": "La rotación directa",
    "gui.blocklyText.motorbit.CCW": "La rotación inversa",
    "gui.blocklyText.maxbot.CW": "La rotación directa",
    "gui.blocklyText.maxbot.CCW": "La rotación inversa",
    "gui.blocklyText.maxbot.ANGLE": "Ángulo",
    "gui.blocklyText.maxbot.CIRCLE": "Círculo",
    "gui.blocklyText.maxbot.Left": "A la izquierda",
    "gui.blocklyText.maxbot.Right": "A la derecha",
    "gui.blocklyText.maxbot.cm": "Centímetro",
    "gui.blocklyText.maxbot.inch": "Pulgada",
    "gui.blocklyText.MotorBit.microbitStarts": "Iniciar MotorBit",
    "gui.blocklyText.MotorBit.MotorRun": "Establecer la velocidad [SPEED] del motor [MOTOR] que va a la dirección [DIR]",
    "gui.blocklyText.MotorBit.MotorStop": "Para el motor [MOTOR]",
    "gui.blocklyText.MotorBit.Stepper": "El motor de pasos [STEPPER] gira [ROTATEDIR] [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "todo",
    "gui.blocklyText.maqueen.maqueenStarts": "Iniciar Maqueen",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "Leer distancia (P1,P2) ",
    "gui.blocklyText.maqueen.motorStop": "El robot para (el motor izquierdo y derecho)",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] luz LED",
    "gui.blocklyText.maqueen.redLineSensor": "Leer [LEFTRIGHT] el sensor de seguimiento en línea",
    "gui.blocklyText.maqueen.robotSpeedMove": "El robot va a la velocidad [SPEED] con la dirección [DIR]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "Establecer la velocidad [SPEED] del motor [LEFTRIGHT] [DIR]",
    "gui.blocklyText.maqueen.ledLeft": "Izquierda (P8)",
    "gui.blocklyText.maqueen.ledRight": "Derecha (P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "Derecha (P12) No disponible",
    "gui.blocklyText.maqueen.lineSensorLeft": "Izquierda (P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "Derecha (P13)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "Al recibir la señal infrarroja [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "infrared signal",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "Leer el valor de la señal infrarroja (P16)",
    "gui.blocklyText.maqueen.numberOfLightsBright": "Luz RGB la clavija P15 número total 4 brillo [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "Luz RGB la clavija P15 número de la luz [NUM1] visualizar color [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "Luz RGB la clavija P15 número de la luz [NUM1] a [NUM2] visualizar colores de [COLOR1] a [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "Luz RGB la clavija P15 mueve [NUM]",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "Luz RGB la clavija P15 gira [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "Luz RGB la clavija P15 brillo [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "Luz RGB la clavija P15 número de luz [NUM1] a [NUM2] visualiza diagrama de barras valor actual [VALUE] valor máximo [MAX]",
    "gui.blocklyText.maqueen.clear": "Luz RGB la clavija P15 atenuar todo",
    "gui.blocklyText.maqueen.rgbColor": "Rojo [RED] Verde [GREEN] Azul [BLUE]",
    "gui.blocklyText.obloq.isReceivedData": "¿ha recibido datos [TOPIC]?",
    "gui.blocklyText.obloq.initialParameter": "Inicializar Obloq mqtt: [PARAMETER] clavija de recepción (verde): [PINR] emisor (azul): [PINT] [SERIALPORT]",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq envía mensaje [MSG] a [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq lee mensaje de [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq lee mensaje",
    "gui.blocklyText.obloq.httpInitialParameter": "Inicializar Obloq mqtt: [PARAMETER] clavija de recepción (verde): [PINR] emisor (azul): [PINT] [SERIALPORT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) enlace: [URL] límite de tiempo(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) enlace: [URL] contenido: [CONTENT]  pasarse el límite de tiempo(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) enlace: [URL] contenido: [CONTENT] pasarse el límite de tiempo(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "Nuevo suscriptor [TOPIC]: [MSG]",
    "gui.blocklyText.obloq.username": "user name",
    "gui.blocklyText.obloq.password": "password",
    "gui.blocklyText.obloq.iotService": "IOT Service",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "IP Address",
    "gui.blocklyText.obloq.port": "Port",
    "gui.blocklyText.obloq.addTopic": "Añadir tema, 4 como máximo",
    "gui.blocklyText.obloq.deleteTopic": "Borrar Topoc",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "Servidor",
    "gui.blocklyText.obloq.global": "Global",
    "gui.blocklyText.obloq.china": "China",
    "gui.blocklyText.obloq.softwareSerial": "Serie software",
    "gui.blocklyText.obloq.hardwareSerial": "Serie hardware",
    "gui.blocklyText.obloq.hardwareSerial1": "Hardware Serial1",
    "gui.blocklyText.obloq.hardwareSerial2": "Hardware Serial2",
    "gui.blocklyText.obloq.hardwareSerial3": "Hardware Serial3",
    "gui.blocklyText.obloq.WhenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq message",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq get [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Debug info",
    "gui.blocklyText.obloq.ERROR": "Error code",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "Emisor infrarrojo [TYPE] la clavija [PIN] valor [VALUE] bit [BIT]",
    "gui.blocklyText.infraredEmission.send": "Infrared send pin [PIN] Send message: address [ADDRESS] command [COMMAND]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 visualiza [TEXT] en la línea [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 visualiza [TEXT] en X: [X] Y:16* [Y] de la coordinada",
    "gui.blocklyText.oled2864.screenRotation": "La pantalla gira al [ROTATION] grado",
    "gui.blocklyText.oled2864.clear": "Borrar todo de OLED128*64",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "Leer [DHT] [TYPE] de la clavija [PIN] ",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx pin [PIN] PM concentration value [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 ultrasonic initialization Name [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04 ultrasonic distance measurement unit [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx pin [PIN] 0.1 liters of air in [DIAMETER] number of particles",
    "gui.blocklyText.sen0170.readWindspeed": "Leer la escala de la medición del viento de la clavija [PIN]",
    "gui.blocklyText.sen0228.readAmbientLight": "Leer el brillo ambiental (VEML7700)",
    "gui.blocklyText.dfr0026.readAmbientLight": "Leer el brillo ambiental de la clavija [PIN]",
    "gui.blocklyText.sen0177.readDustSensor": "read [TYPE] (ug/m3) interface [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "El estado actual de la clavija D) [PIN] la clavija(MCLR) [RST] [TYPE]",
    "gui.blocklyText.sen0203.readHeartRate": "Leer la clavija [PIN] [TYPE] cambiar a [SWITCH]",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] la clavija [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "Leer la escala de grises de la clavija [PIN]",
    "gui.blocklyText.dfr0552.init": "DA module init I2C address [ADDR] voltage reference [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "DA module output triangular wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "DA module output sine wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "DA module output voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "DA module memory voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0576.selectPort": "Select port [PORT]",
    "gui.blocklyText.dfr0576.begin": "Initialization address [ADDR]",
    "gui.blocklyText.sen0248.readBME680": "Get the BME680 sensor [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "Temperature (°C)",
    "gui.blocklyText.sen0248.Pressure": "Pressure (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Humidity (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0248.GasResistance": "Resistencia a los gases (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "initialize the I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Change I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Read ultrasonic sensor distance(cm)",
    "gui.blocklyText.sen0304.readTemperature": "Read ultrasonic sensor temperature(°C)",
    "gui.blocklyText.sen0307.readDistance": "Read [PIN] analog ultrasonic wave(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 initialization address [ADDR] mode [MODE]",
    "gui.blocklyText.sen0250.Step": "Step",
    "gui.blocklyText.sen0250.Measure": "Measure",
    "gui.blocklyText.sen0250.readAccelerated": "BMI160 get the [AXIS] axis Acceleration(g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 get step number",
    "gui.blocklyText.sen0250.Gyro": "Gyro",
    "gui.blocklyText.sen0250.Acc": "Acceleration (g)",
    "gui.blocklyText.sen0224.readAccelerated": "Read acceleration value of LIS2DH sensor [AXIS] axis (mg)",
    "gui.blocklyText.dfr0023.readLM35": "Leer la temperatura(LM35) de la clavija [PIN](℃)",
    "gui.blocklyText.dfr0027.whenVibration": "Cuando la clavija [PIN] detecta la vibración",
    "gui.blocklyText.dfr0028.readTiltSensor": "Cuando la clavija [PIN] detecta la inclinación",
    "gui.blocklyText.dfr0029.readPress": "Cuando la clavija [PIN] detecta que el botón esté pulsado",
    "gui.blocklyText.dfr0030.readTouch": "Cuando la clavija [PIN] detecta el tacto",
    "gui.blocklyText.dfr0033.readMagnetic": "Cuando la clavija [PIN] detecta el imán",
    "gui.blocklyText.dfr0034.readSound": "Leer el nivel de volumen de la clavija [PIN]",
    "gui.blocklyText.dfr0094.readIRReceived": "Leer el dato del infrarrojo de la clavija [PIN]",
    "gui.blocklyText.dfr0094.onReceiveEvent": "When the infrared signal is received Pin [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "address",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "command",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "Leer el sensor de monóxido de carbono de la clavija [PIN]",
    "gui.blocklyText.dfr0051.readVoltage": "Leer el voltaje de la clavija [PIN]",
    "gui.blocklyText.dfr0052.readPiezoSensor": "Leer el Sensor de vibración de piezoeléctrico de la clavija [PIN]",
    "gui.blocklyText.dfr0058.readRotation": "Leer el [PIN] sensor de rotación",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "Leer lel eje [AXIS] del mando de la clavija [PIN]",
    "gui.blocklyText.dfr0061.readJoyStickButton": "¿La clavija [PIN] el botón del mando (Z) está pulsado?",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut init data pin [DATAPIN] RTC pin [RTCPIN] cls pin [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Digital tube display segment code 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Digital tube display number [NUM]",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Read lithium battery [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "voltage(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "remainingBattery(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD module init I2C address [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD module read [PIN] voltage (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD module get aisle [AISLE] voltage difference",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 and 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 and 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 and 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 and 3",
    "gui.blocklyText.dfr0076.readFlameSensor": "Leer sensor de fuego de la clavija [PIN]",
    "gui.blocklyText.dfr0143.readAccelerometer": "Leer sensor de acelerómetro [AXIS] de tres ejes de la clavija [PIN]",
    "gui.blocklyText.sen0018.readInfraredMotion": "Leer sensor infrarrojo del movimiento de la clavija [PIN]",
    "gui.blocklyText.sen0014.readDistance": "Leer sensor de distancia infrarrojo Sharp de la clavija [PIN]",
    "gui.blocklyText.sen0114.readMoisture": "Leer sensor de la humedad del suelo de la clavija [PIN]",
    "gui.blocklyText.sen0121.readSteam": "Leer sensor [PIN] del vapor",
    "gui.blocklyText.sen0204.readLiquidLevel": "¿El líquido ha [PIN] alcanzado a un determinado nivel?",
    "gui.blocklyText.sen0203.heartRate": "Ritmo cardiaco(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "Saturación de oxígeno en la sangre(HbO2)",
    "gui.blocklyText.sen0202.up": "Arriba",
    "gui.blocklyText.sen0202.down": "Abajo",
    "gui.blocklyText.sen0202.left": "A la izquierda",
    "gui.blocklyText.sen0202.right": "A la derecha",
    "gui.blocklyText.sen0202.cw": "La rotación directa",
    "gui.blocklyText.sen0202.ccw": "La rotación inversa",
    "gui.blocklyText.dhtTHSensor.temperature": "Temperatura(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "Hidratación(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "Luz RGB la clavija [PIN] número de la luz [NUM1] a [NUM2] visualizar color [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "rojo [RED] verde [GREEN] azul [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "Luz RGB la clavija [PIN] número de la luz [NUM1] a [NUM2] visualizar colores de [COLOR1] a [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "Luz RGB la clavija [PIN] mueve [NUM]",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "Luz RGB la clavija [PIN] gira [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "Inicializar luz RGB la clavija [PIN] número total [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "Luz RGB la clavija [PIN] número total brillo [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "Luz RGB la clavija [PIN] atenuar todo",
    "gui.blocklyText.ws2812.showHistogram": "Luz RGB la clavija [PIN] número de luz [NUM1] a [NUM2] visualiza diagrama de barras valor actual [VALUE] valor máximo [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "Luz RGB la clavija [PIN] establece el brillo [BRIGHTNESS]",
    "gui.blocklyText.dsTSensor.ds18b20": "Leer pin [PIN] DS18B20 temperatura (°C)",
    "gui.blocklyText.neopixel.init": "Lamp initialization name [NAME] pin [PIN] number [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Light strip [NAME] [INDEX] Color [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Light strip [NAME] [INDEX] Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.fillColor": "The light strip [NAME] is fully lit and the color is [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Light strip [NAME] All on Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.flush": "Light strip [NAME] settings take effect",
    "gui.blocklyText.neopixel.off": "Light strip [NAME] is off",
    "gui.blocklyText.neopixel.setBrightness": "Light strip [NAME] sets brightness to [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Rainbow light effect [NAME] Quantity [COUNT] Brightness [BRIGHTNESS] Offset [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7(mPython board)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9(mPython box)",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "Leer valor [TYPE] el sensor BMP280",
    "gui.blocklyText.bmpTSensor.atmosPressure": "Presión atmosférica(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "Altitud(m)",
    "gui.blocklyText.bmpTSensor.temperature": "Temperatura(℃)",
    "gui.blocklyText.LCD1602.initialIICAddr": "Inicializar la pantalla LCD I2C dirección [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "Visualiza [TEXT] en la línea [LINE] en la pantalla LCD I2C",
    "gui.blocklyText.LCD1602.displayInXY": "Visualiza [TEXT] en X: [X] Y: [Y] de la coordinada en la pantalla LCD I2C",
    "gui.blocklyText.LCD1602.displayClear": "Borrar todo en la pantalla I2C",
    "gui.blocklyText.IICScan.readICCAddr": "Leer la dirección de dispositivos I2C escaneados",
    "gui.blocklyText.softSerialport.initialSerialport": "Inicializar puerto serie [SERIALPORT] RX: [PINR] TX: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "Establecer la frecuencia [SERIALPORT] en baudios [BAUD] de la serie software",
    "gui.blocklyText.softSerialport.printStr": "Serie software [SERIALPORT] [WAY] [TEXT] [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "¿Hay datos legibles de la serie software [SERIALPORT] ?",
    "gui.blocklyText.softSerialport.readSerialportData": "Leer datos de serie software [SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "Leer dato [TYPE] de la serie software [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "Iniciar cuando el bluetooth está conectado",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "Iniciar cuando el bluetooth está desconectado",
    "gui.blocklyText.bluetooth.receivedKey": "Iniciar llave [KEY] valor [TYPE] cuando se recibe datos a través de bluetooth",
    "gui.blocklyText.bluetooth.string": "Cadena",
    "gui.blocklyText.bluetooth.number": "Número",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "Cuando se recibe datos a través de bluetooth y se encuentra con [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "Enviar cadena llave [KEY] valor [VALUE] a través de bluetooth",
    "gui.blocklyText.bluetooth.sendNumber": "Enviar número llave [KEY] valor [VALUE] a través de bluetooth",
    "gui.blocklyText.bluetooth.openService": "Activar bluetooth",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "Se recibe mensajes a través de bluetooth",
    "gui.blocklyText.leonardo.functionStarts": "Iniciar Leonardo",
    "gui.blocklyText.leonardo.setBaud": "Establecer la frecuencia en baudios [BAUD] del serie [SERIAL]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.leonardo.serialPort1": "Puerto serie 0",
    "gui.blocklyText.leonardo.serialPort2": "SerialPort1",
    "gui.blocklyText.leonardo.dataReadable": "¿El dato del [SERIAL] es legible?",
    "gui.blocklyText.leonardo.readSerialData": "Leer dato de [SERIAL]",
    "gui.blocklyText.arduinounoR3.functionStarts": "Iniciar el programa kit de uno",
    "gui.blocklyText.arduinounoR3.readdigital": "Leer [MODULE] de la clavija [PIN]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "Sensor Digital",
    "gui.blocklyText.arduinounoR3.buttonModule": "Módulo de Botón",
    "gui.blocklyText.arduinounoR3.stickButton": "Botón de mando",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "Sensor de Vibración",
    "gui.blocklyText.arduinounoR3.readAnalog": "Leer [SENSOR] de la clavija [PIN]",
    "gui.blocklyText.arduinounoR3.anologSensor": "Sensor Analógico",
    "gui.blocklyText.arduinounoR3.lightSensor": "Sensor de Luz",
    "gui.blocklyText.arduinounoR3.soundSensor": "Sensor de Sonido",
    "gui.blocklyText.arduinounoR3.stickDirection": "Dirección de mando",
    "gui.blocklyText.arduinounoR3.angleSensor": "Sensor de ángulo",
    "gui.blocklyText.arduinounoR3.graySensor": "Sensor de la escala de grises",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "Sensor de Humedad del Suelo",
    "gui.blocklyText.arduinounoR3.open": "Encender",
    "gui.blocklyText.arduinounoR3.close": "Apagar",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] la clavija [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "Actuador Digital",
    "gui.blocklyText.arduinounoR3.ledLights": "Luz LED",
    "gui.blocklyText.arduinounoR3.fanModule": "Módulo de Fan",
    "gui.blocklyText.arduinounoR3.recordModule": "Módulo de grabación",
    "gui.blocklyText.arduinounoR3.relay": "Relé",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "Lectura de distancia ultrasónica (cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "Actuador de Simulación",
    "gui.blocklyText.arduinounoR3.writeAnalog": "Establecer el valor de [MODULE] de la clavija [PIN] como [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "move forward",
    "gui.blocklyText.arduinounoR3.backward": "move backward",
    "gui.blocklyText.arduinounoR3.turnLeft": "Girar a la izquierda",
    "gui.blocklyText.arduinounoR3.turnRight": "Girar a la derecha",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "El robot va a la velocidad [SPEED] a la dirección [DIR]",
    "gui.blocklyText.arduinounoR3.motorForward": "move forward",
    "gui.blocklyText.arduinounoR3.motorReverse": "move backward",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "Establecer la velocidad [SPEED] [DIR] del motor [MOTOR]",
    "gui.blocklyText.arduinounoR3.stopMotor": "Parar el motor MA y MB del robot",
    "gui.blocklyText.arduinounoR3.setServo": "Establece la clavija [PIN] grado del servomotor como [DEGREE]",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "Emisor infrarrojo [TYPE] la clavija [PIN] valor [VALUE] bit [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "¿La clavija infrarrojo [PIN] ha recibido la señal de que el botón [BUTTON] está pulsado?",
    "gui.blocklyText.arduinounoR3.noSound": "No hay sonido",
    "gui.blocklyText.arduinounoR3.half": "1/2",
    "gui.blocklyText.arduinounoR3.quarter": "1/4",
    "gui.blocklyText.arduinounoR3.oneInEighth": "1/8",
    "gui.blocklyText.arduinounoR3.wholeBeat": "Beat Doble",
    "gui.blocklyText.arduinounoR3.doubleBeat": "Beat entero",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "Establecer la clavija [PIN] el tono de la trompeta [TONE] y el ritmo [BEAT]",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "Configurar el volumen [VOLUME] del módulo de MP3 de la clavija [PIN]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "Configurar el modo de reproducción del módulo de MP3 como [MODE] de la clavija [PIN]",
    "gui.blocklyText.arduinounoR3.stop": "Parar",
    "gui.blocklyText.arduinounoR3.play": "Play",
    "gui.blocklyText.arduinounoR3.loop": "Modo cíclico",
    "gui.blocklyText.arduinounoR3.random": "Modo de reproducción aleatoria",
    "gui.blocklyText.arduinounoR3.pause": "Pausa",
    "gui.blocklyText.arduinounoR3.previous": "Canción anterior",
    "gui.blocklyText.arduinounoR3.next": "Siguiente canción",
    "gui.blocklyText.arduinounoR3.VolumePlus": "Volume +",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "Volume-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "Reproducir la canción número [NUM] del módulo MP3 de la clavija [PIN]",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "leer pin [PIN] LM35 temperatura (°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "Leer [TYPE] de DHT11 de la clavija [PIN]",
    "gui.blocklyText.arduinounoR3.red": "rojo",
    "gui.blocklyText.arduinounoR3.blue": "azul",
    "gui.blocklyText.arduinounoR3.green": "verde",
    "gui.blocklyText.arduinounoR3.readColorSensor": "Leer [COLORTYPE] del sensor de color TCS34725",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "Tubo Nixi de 4 segmentos TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "Encender",
    "gui.blocklyText.arduinounoR3.tm1650Close": "Apagar",
    "gui.blocklyText.arduinounoR3.clearScreen": "clear Screen",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": "Tubo Nixi de 4 segmentos visualiza cadena [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "El número del punto [ORDER] decimal del tubo Nixi de 4 segmentos [STATUS]",
    "gui.blocklyText.arduinounoR3.bright": "brighten",
    "gui.blocklyText.arduinounoR3.dark": "Tenue",
    "gui.blocklyText.arduinounoR3.setLightColor": "Luz RGB la clavija [PIN] número [NUM] de luz [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "Luz RGB la clavija [PIN] número [NUM] de luz R:[R] G:[G] B:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "Luz RGB la clavija [PIN] número de luz [NUM]",
    "gui.blocklyText.arduinounoR3.ledControl": "Puntos de LED (max7219) parámetros de inicialización clavija de DIN [DIN] clavija de CS [CS] clavija de CLK [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "Puntos de LED (max7219) visualiza imagen [PIC]",
    "videoSensing.categoryName": "Sensor de video",
    "videoSensing.direction": "dirección",
    "videoSensing.motion": "movimiento",
    "videoSensing.off": "desactivar",
    "videoSensing.on": "activar",
    "videoSensing.onFlipped": "invertir",
    "videoSensing.setVideoTransparency": "fijar transparencia de video a [TRANSPARENCY]",
    "videoSensing.sprite": "objeto",
    "videoSensing.stage": "escenario",
    "videoSensing.videoOn": "[ATTRIBUTE] del video en [SUBJECT]",
    "videoSensing.videoToggle": "[VIDEO_STATE] video",
    "videoSensing.whenMotionGreaterThan": "cuando el movimiento del video > [REFERENCE]",
    "gui.blocklyText.max.maxStart": "Iniciar Max",
    "gui.blocklyText.max.onboardButtonPressed": "Botón integrado en la tarjeta [PRESSED]",
    "gui.blocklyText.max.pressed": "pulsado",
    "gui.blocklyText.max.notPressed": "no pulsado",
    "gui.blocklyText.max.all": "‘-1 todo",
    "gui.blocklyText.max.A": " 0 trasero derecho",
    "gui.blocklyText.max.B": "1 delantero derecho",
    "gui.blocklyText.max.C": " 2 delantero izquierdo",
    "gui.blocklyText.max.D": " 3 trasero izquierdo",
    "gui.blocklyText.max.rgbLightsColor": "Configurar el color [COLOR] de la luz RGB [TYPE]",
    "gui.blocklyText.max.readSoundSensor": "Leer el valor del sensor de sonido",
    "gui.blocklyText.max.playSoundEffect": "Reproducir efecto sonoro [SOUND]",
    "gui.blocklyText.max.setDPinValue": "Establecer el valor [LEVEL] de la clavija [PIN]",
    "gui.blocklyText.max.readDPinValue": "Leer el valor de la clavija digital [PIN]",
    "gui.blocklyText.max.readAPinValue": "Leer el valor de la clavija analógica [PIN]",
    "gui.blocklyText.max.setServo": "Establece la clavija [PIN] grado del servomotor como [DEGREE]",
    "gui.blocklyText.max.readLinefindingSensor": "Leer el valor [DIR] del sensor de sensor de seguimiento de línea",
    "gui.blocklyText.max.left": "A la izquierda",
    "gui.blocklyText.max.middle": "Medio",
    "gui.blocklyText.max.right": "A la derecha",
    "gui.blocklyText.max.setLineFidingCarLights": "Configurar la luz de seguimiento de línea [SWITCH]",
    "gui.blocklyText.max.on": "Encendido",
    "gui.blocklyText.max.off": "Apagado",
    "gui.blocklyText.max.readUltrasoundSensor": "Leer la distancia del sensor ultrasónico (cm)",
    "gui.blocklyText.max.readLightSensor": "Leer el valor del sensor de luz [LEFTRIGHT]",
    "gui.blocklyText.max.maxSpeedMove": "Max va a la velocidad [SPEED] a la dirección [DIR]",
    "gui.blocklyText.max.setMaxMove": "Max va a la velocidad [SPEED] de la rueda [WHEEL] a la dirección [DIR]",
    "gui.blocklyText.max.stopMax": "Max para la rueda [WHEEL]",
    "gui.blocklyText.max.showUserDefineExpressions": "Visualizar expresión personalizado [EXPRESSION] con el color [COLOR]",
    "gui.blocklyText.max.clear": "Borrar todo",
    "gui.blocklyText.max.do": "do",
    "gui.blocklyText.max.re": "re",
    "gui.blocklyText.max.mi": "mi",
    "gui.blocklyText.max.fa": "fa",
    "gui.blocklyText.max.sou": "sou",
    "gui.blocklyText.max.la": "la",
    "gui.blocklyText.max.xi": "xi",
    "gui.blocklyText.max.highdo": "highdo",
    "gui.blocklyText.max.brake": "brake",
    "gui.blocklyText.max.rebound": "rebound",
    "gui.blocklyText.max.bullets": "bullets",
    "gui.blocklyText.max.longecho": "long echo",
    "gui.blocklyText.max.playfulending": "playful ending",
    "gui.blocklyText.max.yo": "yo",
    "gui.blocklyText.max.wo": "wo",
    "gui.blocklyText.max.robottalking": "robot talking",
    "gui.blocklyText.knowflow.readORP": "Leer sensor ORP (mv) de la clavija analógica [PIN]",
    "gui.blocklyText.knowflow.readEC": "Leer sensor EC (ms/cm) (V1) de la clavija analógica [PIN]",
    "gui.blocklyText.knowflow.readPH": "Leer sensor PH (V1) de la clavija analógica [PIN]",
    "gui.blocklyText.knowflow.readECV2": "Leer sensor EC (ms/cm) (V2) de la clavija analógica [PIN]",
    "gui.blocklyText.knowflow.readPHV2": "Leer sensor PH (V2) de la clavija analógica [PIN]",
    "gui.blocklyText.knowflow.readDO": "Leer sensor DO (mg/L) de la clavija analógica [PIN]",
    "gui.blocklyText.peristalticPump.speedMove": "La bomba peristáltica de la clavija [PIN] gira a la velocidad [SPEED] a la dirección [DIR]",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] relé de la clavija [PIN]",
    "translate.translateBlock": "traducir [WORDS] al [LANGUAGE]",
    "translate.defaultTextToTranslate": "hola",
    "translate.viewerLanguage": "lenguaje",
    "gui.blocklyText.goble.readAxis": "Leer eje [AXIS] del mando GOBLE",
    "gui.blocklyText.goble.buttonIsPressed": "¿El botón [BUTTON] del mando GOBLE está pulsado?",
    "gui.blocklyText.tds.readAnalogPin": "Leer sensor TDS de la clavija [PIN](ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "Dos motores de pasos operan simultáneamente eje X: [XSET] eje Y: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "Motores [AXIS] de pasos [STEPS] por revolución (r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "Motor de pasos mueve [STEPS] pasos a la dirección [ROTATEDIR]",
    "gui.blocklyText.steppermotor.setJointSteps": "Dos motores de pasos operan simultáneamente movimiento en el eje X [XSTEPS] pasos a la dirección [ROTATEDIRX] movimiento en el eje Y [YSTEPS] pasos a la dirección [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "Iniciar programa [THREAD]",
    "gui.blocklyText.multithread.stopThread": "Para [THREAD]",
    "gui.blocklyText.multithread.startThread": "Iniciar [THREAD]",
    "gui.blocklyText.multithread.loop1": "thread 1",
    "gui.blocklyText.multithread.loop2": "thread 2",
    "gui.blocklyText.multithread.loop3": "thread 3",
    "gui.blocklyText.multithread.loop4": "thread 4",
    "gui.blocklyText.multithread.loop5": "thread 5",
    "gui.blocklyText.multithread.loop6": "thread 6",
    "gui.blocklyText.multithread.loop7": "thread 7",
    "gui.blocklyText.multithread.loop8": "thread 8",
    "gui.blocklyText.multithread.all": "todo",
    "text2speech.speakAndWaitBlock": "decir [WORDS]",
    "text2speech.setVoiceBlock": "fijar voz a [VOICE]",
    "text2speech.setLanguageBlock": "fijar idioma a [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "chirrido",
    "text2speech.giant": "gigante",
    "text2speech.kitten": "gatito",
    "text2speech.defaultTextToSpeak": "hello",
    "gui.blocklyText.DFR0151.adjustTime": "Configurar la hora del DS1307 como [YEAR]año [MONTH]mes [DATE]día [HOUR]hora [MINUTE]minuto [SECOND]segundo",
    "gui.blocklyText.DFR0151.getTime": "Coger el tiempo de DS1307 [INDEX]",
    "gui.blocklyText.DFR0151.Year": "Año",
    "gui.blocklyText.DFR0151.month": "Mes",
    "gui.blocklyText.DFR0151.date": "Día",
    "gui.blocklyText.DFR0151.hour": "Hora",
    "gui.blocklyText.DFR0151.minute": "Minuto",
    "gui.blocklyText.DFR0151.second": "Segundo",
    "gui.blocklyText.DFR0151.week": "Semana",
    "gui.blocklyText.DFR0469.adjustTime": "Configurar la hora del SD2405 como [YEAR]año [MONTH]mes [DATE]día [HOUR]hora [MINUTE]minuto [SECOND]segundo",
    "gui.blocklyText.DFR0469.getTime": "Coger el tiempo de SD2405 [INDEX]",
    "gui.blocklyText.DFR0126.setPin": "Módulo de análisis de audio establecer la clavija #S[SPIN] #R[RPIN] la clavija analógica[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "Módulo de análisis de audio  leer frecuencia de sonido banda de frecuencia [FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Initialize the NFC module interface I2C",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Initialize the NFC module interface hardware serial port Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "Inicializar el módulo de puerto serie elige [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "Inicializar el módulo de puerto serie elige [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "Módulo de puerto serie NFC bloque de datos [DATABLOCK] insertar [VALUE] al bit del número [BYTES]",
    "gui.blocklyText.DFR0126.readSerialNfc": "Leer módulo de puerto serie NFC bloque de datos [DATABLOCK] bit del número [BYTES]",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "Leer módulo de puerto serie NFC bloque de datos [DATABLOCK] todos los datos del tipo cadena",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "Leer módulo de puerto serie NFC UID (cadena)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "¿Se detecta tarjeta con el UID: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "¿Se ha detectado tarjeta?",
    "gui.blocklyText.DFR0126.serial1": "serial1",
    "gui.blocklyText.DFR0126.serial2": "serial2",
    "gui.blocklyText.DFR0126.serial3": "serial3",
    "gui.blocklyText.sen0245.setVL53L0X": "Establecer el número de medición [VALUE] del bloque de medición láser [PRECISION] VL53L0X",
    "gui.blocklyText.sen0245.readVL53L0X": "Leer el valor del bloque de medición láser VL53L0X (mm)",
    "gui.blocklyText.SEN0245.LOW": "Precisión baja",
    "gui.blocklyText.SEN0245.HIGH": "Precisión alta",
    "gui.blocklyText.SEN0245.Single": "Medición única",
    "gui.blocklyText.SEN0245.Continuous": "Medición en continuo",
    "gui.blocklyText.serialMp3.initSerialMp3": "Inicializar el puerto serie del bloque de MP3 [SERIALPORT] #RX[RX] #TX[TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "Controlar el puerto serie del bloque de MP3 [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "El puerto serie del bloque de MP3 reproduce la canción [VALUE]",
    "gui.blocklyText.serialMp3.setMP3Volume": "El volumen del puerto serie del bloque de MP3 es [VOLUME]",
    "gui.blocklyText.serialMp3.play": "Play",
    "gui.blocklyText.serialMp3.pause": "Pausa",
    "gui.blocklyText.serialMp3.end": "Detener",
    "gui.blocklyText.serialMp3.prev": "Canción anterior",
    "gui.blocklyText.serialMp3.next": "Siguiente canción",
    "gui.blocklyText.serialMp3.volumeUp": "Subir el volumen",
    "gui.blocklyText.serialMp3.volumeDown": "Bajar el volumen",
    "gui.blocklyText.serialMp3.playlist": "Lista de reproducción",
    "gui.blocklyText.serialMp3.insertTracks": "Insertar canción",
    "gui.blocklyText.serialMp3.volume": "Volumen",
    "gui.blocklyText.servo360.setServo360": "Establece la velocidad [SPEED] de la clavija [PIN] a la dirección [DIR]",
    "gui.blocklyText.servo360.CW": "La rotación directa",
    "gui.blocklyText.servo360.CCW": "La rotación inversa",
    "gui.blocklyText.TCS34725.readTcs34725": "Leer el sensor de color TCS34725 [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "Convertir en GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "No convertir en GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "Visualizar texto [TEXT] con el color [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "Desplazar texto a la dirección [DIR]",
    "gui.blocklyText.regEmotion8_16.left": "A la izquierda",
    "gui.blocklyText.regEmotion8_16.right": "A la derecha",
    "gui.blocklyText.regEmotion8_16.lightAll": "Iluminar los lattices con el color [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "Punto de coordenada x:[XAXIS], y:[YXAXIS] con el color de [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "Borrar lattices",
    "gui.mainHeader.err": "Error: solo se puede utilizar una cabecera [%1], por favor borra los bloques duplicados",
    "gui.mainHeader.help": "Ayuda: si se necesita ejecutuar varios programas simultáneamente, por favor utiliza \"la función de hilos múltiples\"  en \"expansión\" ",
    "gui.threadHeader.err1": "Error: solo se puede utilizar una cabecera [%1], las cabeceras duplicadas pertenecen a diferentes kit o tarjeta madre",
    "gui.threadHeader.err2": "Error: solo se puede utilizar una cabecera de evento [%1], por favor borra los bloques duplicados",
    "gui.blocklyText.mqtt.whenReceivedData": "Se inicia cuando [TOPIC] recibe datos [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT message",
    "gui.blocklyText.wifi.wifiConnectByPwd": "conectarse a Wi-Fi: cuenta: [ACCOUNT] contraseña: [PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "¿Wi-Fi conectado?",
    "gui.blocklyText.wifi.wifiDisConnected": "disconnect Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP mode:[ACCOUNT2] password:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi get soft ap ip",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi get [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Error code",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "when udp server recv [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "set udp server port [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "udp server send broadcast [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "when udp client recv [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "set udp client IP [IPADDR] port [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "udp client send broadcast [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "message",
    "gui.blocklyText.udp.ClientRecvedMsg": "message",
    "gui.blocklyText.mqtt.mqttConnect": "Intentar a conectar MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "¿MQTT conectado?",
    "gui.blocklyText.mqtt.acceptance.meaage": "When [TOPIC] received the message as [MSG]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT envía mensaje [MSG] a [TOPIC]",
    "gui.blocklyText.mqtt.initial": "Inicializar parámetro de MQTT [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「IFLYTEK」synthetic audio [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「IFLYTEK」identify audio [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「IFLYTEK」identify result",
    "gui.blocklyText.iflytek.CommunicationText": "text",
    "gui.blocklyText.iflytek.CommunicationTransfer": "transfer to audio file",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "audio file to be identified",
    "gui.blocklyText.mqtt.mqttDisconnect": "disconnect MQTT",
    "gui.blocklyText.sen0251.readAltitude": " BMP338 lee la altitud",
    "gui.blocklyText.sen0251.readPressure": "BMP338 lee la presión atmosférica",
    "gui.blocklyText.sen0251.readTemperature": "BMP338 lee la temperatura",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 measurement [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "object",
    "gui.blocklyText.sen0206.environment": "environment",
    "gui.blocklyText.sen0206.celsius": "celsius",
    "gui.blocklyText.sen0206.fahrenheit": "fahrenheit",
    "gui.blocklyText.sen0253.init": "10DOF inicializar 'I2C_ADDR' nivel eléctrico [LEVEL]",
    "gui.blocklyText.sen0253.reset": "Sensor 10DOF se inicializa",
    "gui.blocklyText.sen0253.power": "10DOF configurar modo de consumo enérgico [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF coge el ángulo euler [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF coge el eje de cuaternio [AXIS]",
    "gui.blocklyText.sen0253.getAcc": "10DOF coge el eje de aceleración [AXIS](mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF coge el eje de magnetómetro [AXIS](μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF coge el eje de la aceleración lineal [AXIS](mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF coge el eje de vector de la fuerza de la gravedad [AXIS](mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF coge el eje de la velocidad angular [AXIS]",
    "gui.blocklyText.sen0253.getBmp280": "10DOF coge [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "Bajo",
    "gui.blocklyText.sen0253.HIGH": "Alto",
    "gui.blocklyText.sen0253.normal": "Normal",
    "gui.blocklyText.sen0253.lowPower": "Consumo bajo",
    "gui.blocklyText.sen0253.suspend": "Suspender",
    "gui.blocklyText.sen0253.Roll": "Ángulo de balanceo(°)",
    "gui.blocklyText.sen0253.Pitch": "Ángulo de inclinación(°)",
    "gui.blocklyText.sen0253.Yaw": "Ángulo de guiñada(°)",
    "gui.blocklyText.sen0253.temperature": "Temperatura",
    "gui.blocklyText.sen0253.elevation": "Altitud",
    "gui.blocklyText.sen0253.airPressure": "Presión atmosférica",
    "gui.blocklyText.sen0290.init": "SEN0290 set irq pin [IRQPIN] and i2c address [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "SEN0290 lightning sensor iic init",
    "gui.blocklyText.sen0290.manualCal": "SEN0290 set capacitance [CAPACITANCE] mode [MODE] dist [DIST]",
    "gui.blocklyText.sen0290.indoors": "indoors",
    "gui.blocklyText.sen0290.outdoors": "outdoors",
    "gui.blocklyText.sen0290.disturberDis": "disturber disable",
    "gui.blocklyText.sen0290.disturberEn": "disturber enable",
    "gui.blocklyText.sen0290.sen0290WhenRecvErrMsg": "SEN0290 When receive [ErrMsg]",
    "gui.blocklyText.sen0290.errMsg": "error msg",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "SEN0290 get interrupt src",
    "gui.blocklyText.sen0290.GetLightningDistKm": "SEN0290get lightning dist(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "SEN0290 get strike energy raw",
    "gui.blocklyText.weather.weatherGetWithCity": "El tiempo [WEATHER] de País [COUNTRY] Ciudad [CITY]",
    "gui.blocklyText.weather.temperaturHigh": "Temperatura máxima",
    "gui.blocklyText.weather.temperaturLow": "Temperatura mínima",
    "gui.blocklyText.weather.weather": "Tiempo",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "Establecer parámetros del servidor [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "Actualizar el valor [VALUE] de la etiqueta [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "Leer la etiqueta [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "Suprimir la etiqueta [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "Contar",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "Probar la conexión de internet",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "search tag from start number [NUMS] variable count [NUMV] tag [TAG] type [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "Etiqueta",
    "gui.blocklyText.tinywebdb.value": "Valor",
    "gui.blocklyText.tinywebdb.both": "Ambos",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "HongKong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.whenIHear": "al escuchar [PHRASE]",
    "speech.listenAndWait": "escuchar y esperar",
    "speech.getSpeech": "Resultado de reconocimiento",
    "speech.setRecordTime": "set record time to [TIME]",
    "speech.stopRecord": "Para el reconocimiento de voz",
    "speech.controlSonogram": "[CONTROL] ecografía",
    "speech.setServer": "Cambiar el servidor del reconocimiento de voz a [SERVER]",
    "speech.defaultWhenIHearValue": "vamos",
    "speech.show": "Iluminar",
    "speech.hide": "Atenuar",
    "speech.server1": "Servidor 1",
    "speech.server2": "server2",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 can not be placed in the interrupt event, may cause dht11 reading abnormal",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 can not be placed in the interrupt event, may cause dht22 reading abnormal",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 cannot be placed in an interrupt event, which may result in abnormal pm2.5 readings",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 reads data, which may cause led dot matrix initialization failure",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 reads data, which may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "the mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to be set to fail",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "the mp3 setting playback mode cannot be placed in an interrupt event, which may cause the playback mode to fail",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "Mp3 settings playback can not be placed in the interrupt event, may cause the settings to play failed",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "Serial port mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "The serial port mp3 playback mode cannot be placed in an interrupt event, which may cause the setup play mode to be lost",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "Serial port mp3 playback cannot be placed in an interrupt event, which may cause playback to fail",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "The serial port mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to fail",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloq's mqtt initialization soft serial port cannot be placed in an interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq sends a message that cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq read mqtt message can not be placed in the interrupt event, may cause the reception to fail",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "The initial soft serial port of http of obloq cannot be placed in the interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "The get request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "Obloq http post request cannot be placed in an interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "The put request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "Infrared reception cannot be placed in an interrupt event, which may cause reception failure",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "Infrared reception may cause the LED dot matrix initialization to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "Infrared reception may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "Infrared transmission cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause initialization to fail",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause setup pins to fail",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the pin to fail to fail",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause display color to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause color adjustment failure",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the move to fail",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause loop movement to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause the display histogram to fail",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "Led dot matrix can not be placed in the interrupt event, may cause initialization failure",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "The led dot matrix cannot be placed in an interrupt event, which may cause the display pattern to fail",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "The soft serial output cannot be placed in an interrupt event, which may cause the output to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "The data read by the soft serial port cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Soft serial port output data and read data colleague use, may cause data abnormalities",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "The soft serial port read integer cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "Soft serial port output data and reading integer colleague use may cause abnormal reading data",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "When dht11 and PM2.5 are in two threads, dht11 reads may fail if pm2.5 reads too frequently. It is recommended to reduce the pm2.5 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "When dht11 and ds18b20 are in two threads respectively, if ds18b20 reads too frequently, dht11 may fail to read, it is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "When dht11 and mp3 are initialized in two threads, mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "When dht11 and mp3 set volume are in two threads respectively, mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "When dht11 and mp3 setup modes are in two threads respectively, mp3 setup mode may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "When dht11 and mp3 player are in two threads, mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "When dht11 and serial mp3 are initialized in two threads, serial mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "When dht11 and serial mp3 control module are in two threads respectively, the mp3 control module may cause dht11 reading errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "When dht11 and serial mp3 player are in two threads, serial mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "When dht11 and serial mp3 set volume are in two threads respectively, serial mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "When dht11 and RGB are initialized in two threads, RGB initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "When dht11 and RGB set brightness in two threads, RGB set brightness may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "When dht11 and RGB are extinguished in two threads, RGB extinction may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "When dht11 and RGB display colors in two threads, RGB display colors may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "When dht11 and RGB display a gradient in two threads, respectively, RGB display a gradient may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "When dht11 and RGB move pixels in two threads, RGB move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "When dht11 and RGB loop move pixels in two threads, respectively, RGB loop move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "When dht11 and RGB display columns in two threads respectively, RGB display bars may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "When dht11 and soft serial send are in two threads respectively, soft serial send may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "When dht11 and obloq MQTT are initialized in two threads respectively, obloq MQTT initialization may cause dht11 to read errors. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "When dht11 and obloq MQTT send messages in two threads respectively, obloq MQTT send messages that may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "When dht11 and obloq HTTP put requests are in two threads, it can cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "When dht11 and obloq HTTP initialization are in two threads respectively, it may cause dht11 reading error. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "When dht11 and obloq HTTP get requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "When dht11 and obloq HTTP post requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "When dht11 and infrared emission are in two threads respectively, it may cause errors in reading dht11. It is suggested to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "When ds18b20 and PM2.5 are in two threads respectively, it may cause PM2.5 reading error, so it is suggested to reduce ds18b20 frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "When ds18b20 and obloq MQTT are initialized in two threads respectively, MQTT initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "When ds18b20 and obloq HQTT put requests are in two threads respectively, the put request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "When ds18b20 and obloq HTTP initialization are in two threads respectively, the HTTP initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "When ds18b20 and obloq HQTT get requests are in two threads respectively, the get request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "When ds18b20 and obloq HQTT post requests are in two threads respectively, the post request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Reading ds18b20 may result in no infrared data, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Reading ds18b20 may cause errors in receiving data by soft serial port, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "When PM2.5 read and obloq MQTT initialization are carried out in two threads respectively, MQTT initialization failure or PM2.5 read error may be caused. It is recommended to avoid simultaneous execution of MQTT initialization and PM2.5 read",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "When PM2.5 reading and obloq MQTT send messages in two threads respectively, PM2.5 reading errors may be caused. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "When PM2.5 read and obloq HTTP put requests are in two threads, it may cause put request failure or PM2.5 read error. It is recommended to reduce put request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "When PM2.5 reads and obloq HTTP initializations are carried out in two threads respectively, HTTP initializations may fail or PM2.5 reads may go wrong. It is recommended to avoid simultaneous execution of HTTP initializations and PM2.5 reads",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "When PM2.5 read and obloq HTTP get requests are in two threads respectively, it may cause get request failure or PM2.5 read error. It is recommended to reduce get request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "When PM2.5 read and obloq HTTP post request are in two threads respectively, it may cause post request failure or PM2.5 read error. It is recommended to reduce the frequency of post request or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "When PM2.5 reading and MP3 initialization are carried out in two threads respectively, PM2.5 reading may fail. It is recommended to avoid the simultaneous execution of PM2.5 reading and MP3 initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "When PM2.5 reading and MP3 setting volume are in two threads respectively, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "When PM2.5 reading and serial MP3 player are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "When PM2.5 reading and serial MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "When PM2.5 reading and serial MP3 setting volume are respectively in two threads, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "When PM2.5 reading may cause infrared reception failure, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "When PM2.5 reading and RGB initialization are carried out in two threads respectively, PM2.5 reading errors may be caused. It is recommended to avoid simultaneous execution of PM2.5 reading and RGB initialization",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "When PM2.5 reading and RGB setting brightness are respectively in two threads, PM2.5 reading error may be caused. It is recommended not to adjust brightness too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "When PM2.5 reading and RGB extinguishing are in two threads, PM2.5 reading errors may be caused. It is recommended not to extinguish RGB too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "When PM2.5 reading and RGB display colors are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to switch colors too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "When PM2.5 reading and RGB display the gradient in two threads respectively, PM2.5 reading error may be caused, it is recommended not to switch the gradient too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "When PM2.5 reading and RGB moving pixels are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to move pixels too frequently",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to be initialized abnormally",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 initialization to be abnormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "Using Wi-Fi may cause serial port mp3 to play track failed",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to play tracks failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "Using Wi-Fi may cause the serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "Using Wi-Fi may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp uses Wi-Fi, which may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "When PM2.5 read and RGB loop move pixels in two threads respectively, PM2.5 read errors may be caused. It is recommended not to execute loop move pixels too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "When PM2.5 reading and RGB display columns are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to execute RGB display columns too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "When PM2.5 reading and led dot array initialization are carried out in two threads respectively, led dot array initialization may go wrong. It is recommended to avoid the simultaneous execution of PM2.5 reading and led dot array initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "When PM2.5 reading and led dot array display are in two threads respectively, led dot array display may be wrong, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5 reading may cause errors in PM2.5 reading. It is recommended not to send data too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5 reading may cause soft serial port reading failure. It is recommended not to read PM2.5 too frequently",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 may not receive infrared data, it is recommended to reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "When MP3 and obloq MQTT initialization is in two threads, it may cause MQTT initialization to fail. It is recommended to avoid simultaneous MQTT initialization and MP3 initialization",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "When MP3 and obloq HTTP put requests are in two threads, it is possible to cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "When MP3 and obloq HTTP initializations are in two threads, HTTP initializations may fail. It is recommended to avoid both HTTP initializations and MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "When MP3 and obloq HTTP get requests are in two threads, it may cause get requests to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "When MP3 and obloq HTTP post requests are in two threads, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 may cause soft serial port read failure, it is recommended not to use MP3 too often",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "Serial MP3 may not receive infrared data, it is recommended to reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "When serial port MP3 and obloq MQTT initialization are in two threads respectively, MQTT initialization may fail. It is recommended to avoid simultaneous execution of MQTT initialization and serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "When serial MP3 and obloq HTTP put requests are in two threads, it may cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "HTTP initialization may fail when serial MP3 and obloq are initialized in two threads. It is recommended to avoid both HTTP initialization and serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "When the serial port MP3 and obloq HTTP get requests are in two threads respectively, it may cause the get request to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "When the serial port MP3 and obloq HTTP post requests are in two threads respectively, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of the serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "Serial port MP3 may cause soft serial port read failure, it is recommended not to use serial port MP3 too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving errors, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "Soft serial obloq may cause errors in infrared receiving data, so it is recommended to avoid sending messages frequently by obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "The initialization of RGB band may lead to the error of obloq receiving messages. It is recommended to avoid frequent use of RGB band",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Setting the brightness of the light belt may cause an error in obloq receiving messages. It is recommended to avoid frequent brightness adjustment",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB belt extinguish may lead to soft serial obloq receiving messages error, it is recommended to avoid frequent extinguish the belt",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB light band display may cause obloq to receive messages error, it is recommended to avoid frequent display of color",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "The display of gradient on the RGB band may cause errors in obloq receiving messages. It is recommended to avoid frequent display of gradient",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB band moving pixels may cause soft serial obloq to receive messages incorrectly",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "The RGB band loops the pixels around and may cause the soft serial obloq to receive messages out",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB ribbon display bar may cause soft serial obloq receive message error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "When soft serial obloq and led dot array initialization are carried out in two threads respectively, it may cause led dot array initialization error. It is recommended to avoid simultaneous execution of soft serial obloq and led dot array initialization",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "When soft serial obloq and led dot array display are respectively in two threads, it may cause led dot array display error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "Soft serial port sending may cause obloq reading error, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "Soft serial port obloq send may cause soft serial port read failure, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "Infrared emission may cause infrared reception of no data, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "When infrared emission may cause soft serial port reading failure, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "The initialization of RGB band may result in no infrared reception of data",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "The brightness setting of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent brightness setting of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "The RGB lamp belt is off, which may cause the infrared data can not be received",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "The moving pixel of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent moving pixel of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "Infrared reception may cause led initialization failure",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "Infrared reception may cause led display errors",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "Soft serial port sending may cause infrared data can not receive, it is recommended to avoid frequent sending soft serial port",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB band may cause soft serial port reading failure, so it is recommended to reduce the use frequency of RGB band",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Data received by soft serial port may cause led dot matrix display error",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "The sending of soft serial port may lead to the error of receiving data of soft serial port. It is recommended to avoid the execution of sending data and receiving data at the same time",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "The initialization of serial port MP3 may lead to errors in infrared receiving data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "MP3 serial port setting playing mode may cause errors in infrared receiving data, so it is recommended to avoid setting playing mode too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "Serial MP3 player may lead to infrared data errors, it is recommended to avoid switching songs too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "The volume setting of serial port MP3 may cause errors in infrared receiving data. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "Initialization of max7219 may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "Max7219 display images may cause errors in infrared received data, so it is recommended to avoid displaying images too frequently",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "The MP3 module of serial port may cause the pin interrupt cannot be triggered. It is recommended to avoid using MP3 module of serial port too often",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "The max7219 display module may cause pin interrupts not to be triggered. It is recommended to avoid using the max7219 display too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "DHT reading may lead to errors in infrared receiving messages. It is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "Ds18b20 reading may cause errors in soft external receiving messages, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5 reading may cause errors in receiving messages outside the software. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "Mp3 initialization read may cause infrared receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "The volume setting of mp3 may cause the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "Mp3 setting mode may cause errors in infrared receiving messages. It is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "Mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "Setting mode of serial port mp3 may cause error of infrared receiving message, it is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "Serial mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Setting the volume of serial port mp3 may lead to the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "Infrared emission may cause errors in infrared receiving messages, so it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "The initialization of the RGB band may lead to errors in receiving infrared messages. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving infrared messages. It is recommended to avoid setting the brightness too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB band display may cause errors in infrared receiving messages. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "Gradient discoloration of RGB lamp band display may lead to error of infrared receiving message. It is recommended to avoid displaying gradient discoloration too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Moving pixels with RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid moving pixels too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "The circular movement of pixels with the RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB ribbon display columnar may cause infrared receiving message error, it is recommended to avoid displaying columnar too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Infrared receiving messages may cause initialization failure of max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Infrared receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "Soft serial printing may cause errors in infrared receiving messages, so it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "Soft serial port obloq MQTT initialization may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "Soft serial obloq put request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "Soft serial port obloq HTTP initialization may lead to infrared receiving data errors, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "Soft serial obloq get request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "Soft serial obloq post request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "The clear display of RGB lamp band may cause errors in receiving messages in the soft infrared. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "DHT reading may lead to soft serial receiving message error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "Ds18b20 reading may cause the soft serial port to receive the message error, it is recommended to reduce the ds18b20 reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5 reading may cause errors in receiving messages by soft serial port. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "Mp3 initialization reads may result in a soft serial receiving message error, and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Setting the volume of mp3 may cause the error of receiving the message on the soft serial port. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "Mp3 setting mode may cause the soft serial port to receive the message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "Mp3 playback may lead to soft serial receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "Mp3 setting mode of serial port may cause error of receiving messages by soft serial port. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "Mp3 playing on serial port may cause errors in receiving messages on soft serial port. It is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message from soft serial port. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "Infrared emission may cause errors in receiving messages by soft serial port. It is recommended to avoid sending infrared messages too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "The initialization of the RGB band may result in an error in receiving messages from the soft serial port. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving messages from the soft serial port. It is recommended to avoid setting the brightness too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "Clear display of RGB light band may cause error of receiving message by soft serial port. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB band display may cause errors in receiving messages from the soft serial port. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB lamp tape display gradient may cause message error in soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause the soft serial port to receive messages incorrectly. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB light belt circular movement of pixels may cause the soft serial port to receive messages error, it is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB ribbon display columnar may cause the soft serial port to receive the message error, it is recommended to avoid performing display columnar too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "Soft serial receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "Soft serial receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "Soft serial printing may cause error in receiving messages from the soft serial port. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "DHT reads may cause pin interrupt triggering failure. It is recommended to reduce the DHT read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "Ds18b20 read may cause pin interrupt trigger failure, it is recommended to reduce ds18b20 read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5 reading may cause pin interrupt trigger failure. It is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Mp3 initialization reads may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "Mp3 setting volume may cause pin interrupt trigger failure. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "Mp3 setting mode may cause pin interrupt triggering failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "Mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "Serial port mp3 initialization read may cause pin interrupt trigger failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "Setting mode of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "Serial mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Setting the volume of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "Infrared emission may cause pin interrupt trigger failure, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB ribbon initialization may cause pin interrupt trigger failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Setting brightness of RGB band may cause pin interrupt trigger failure. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB strip clear display may cause pin interrupt trigger failure, it is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB tape display may cause pin interrupt trigger failure. It is recommended to avoid RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "The RGB tape display of the gradient may cause the pin interrupt trigger to fail. It is recommended to avoid displaying the gradient too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB band moving pixels may cause pin interrupt triggering failure and it is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB light belt loop move pixel may cause pin interrupt trigger failure, it is recommended to avoid too often loop move pixel",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB ribbon display bar may cause pin interrupt trigger failure. It is recommended to avoid displaying bar too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "Pin interruption may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "Pin interruption may cause max7219 to display incorrectly",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "Soft serial printing may cause pin interrupt trigger failure, it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "Soft serial port obloq MQTT initialization may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "Soft serial obloq put requests may cause pin interrupt triggering failure and it is recommended to avoid requesting data too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "Soft serial obloq HTTP initialization may cause pin interrupt triggering failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "Soft serial obloq get request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "Soft serial obloq post request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "DHT reading may lead to soft serial obloq receiving messages error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "Ds18b20 reading may cause obloq to receive messages incorrectly, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5 reading may cause error in obloq receiving messages. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "Mp3 initialization read may result in soft serial obloq receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Mp3 setting volume may result in soft serial obloq receiving message error. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "Mp3 setting mode may lead to soft serial obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "Mp3 playback may lead to soft serial obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port obloq receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "Serial port mp3 setting mode may cause soft serial port obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "Serial port mp3 player may lead to soft serial port obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message of soft serial port obloq. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving messages error, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Soft serial bloq sending messages may lead to infrared reception errors, it is recommended to avoid obloq sending messages too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB ribbon initialization may cause soft serial obloq to receive an error message, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Setting brightness of RGB light belt may cause error of obloq receiving messages. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Clear display of RGB light band may cause error of obloq receiving message. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB tape display may result in an error in obloq receiving messages. It is recommended to avoid performing RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB lamp tape display gradient may cause message error in obloq soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause an error in obloq receiving messages. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB light belt circular movement of pixels may lead to soft serial port obloq receive messages error, it is recommended to avoid too often loop movement of pixels",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB ribbon display columnar may cause soft serial obloq receive messages error, it is recommended to avoid too often display columnar",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "Soft serial obloq receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "Soft serial obloq receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "Soft serial printing may cause obloq to receive messages incorrectly. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "Soft serial port obloq sending data may cause errors in receiving data by soft serial port. It is recommended to avoid too frequent sending of data by obloq",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "Soft serial obloq sending data may cause pin interrupt cannot be triggered, it is recommended to avoid obloq sending data too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "Soft serial port obloq MQTT initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "Soft serial obloq put request may cause the soft serial port to receive data error, it is recommended to avoid too frequent requests for data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "Soft serial port obloq HTTP initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "Soft serial obloq get request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "Soft serial port obloq post request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Forward",
    "gui.blocklyText.maqueenPlus.Backward": "Backward",
    "gui.blocklyText.maqueenPlus.left": "Left",
    "gui.blocklyText.maqueenPlus.right": "Right",
    "gui.blocklyText.maqueenPlus.all": "All",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "set motor [DIR] move by [SPEED] speed [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "set motor [DIR] stop",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] speed compensation [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "read [DIR] motor speed",
    "gui.blocklyText.maqueenPlus.readMoterDir": "read [DIR] motor direction (0:stop 1:forward 2:back)",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "set [DIR] RGB show [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "read the sensation sensor [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "read the sensation sensor [INDEX] grayscale value",
    "gui.blocklyText.maqueenPlus.getVersion": "get version",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "read ultrasonic sensor trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "when received [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "infrared data",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "paint.paintEditor.hue": "Color",
    "paint.paintEditor.saturation": "Saturación",
    "paint.paintEditor.brightness": "Brillo",
    "gui.comingSoon.message1": "No te preocupes, estamos en eso {emoji}",
    "gui.comingSoon.message2": "Próximamente...",
    "gui.comingSoon.message3": "Estamos trabajando en ello {emoji}",
    "paint.paintEditor.fill": "Relleno",
    "paint.paintEditor.costume": "Disfraz",
    "paint.paintEditor.group": "Agrupar",
    "paint.paintEditor.ungroup": "Desagrupar",
    "paint.paintEditor.undo": "Deshacer",
    "paint.paintEditor.redo": "Rehacer",
    "paint.paintEditor.forward": "Adelante",
    "paint.paintEditor.backward": "Atrás",
    "paint.paintEditor.front": "Al frente",
    "paint.paintEditor.back": "Al fondo",
    "paint.paintEditor.more": "Más",
    "paint.modeTools.brushSize": "Tamaño",
    "paint.modeTools.eraserSize": "Tamaño del borrador",
    "paint.modeTools.copy": "Copiar",
    "paint.modeTools.paste": "Pegar",
    "paint.modeTools.delete": "Eliminar",
    "paint.modeTools.curved": "Curvo",
    "paint.modeTools.pointed": "Puntiagudo",
    "paint.modeTools.thickness": "Grosor",
    "paint.modeTools.flipHorizontal": "Voltear horizontal",
    "paint.modeTools.flipVertical": "Voltear vertical",
    "paint.modeTools.filled": "Relleno",
    "paint.modeTools.outlined": "Con contorno",
    "paint.paintEditor.bitmap": "Convertir a mapa de bits",
    "paint.paintEditor.vector": "Convertir a vector",
    "paint.paintEditor.stroke": "Contorno",
    "paint.brushMode.brush": "Pincel",
    "paint.eraserMode.eraser": "Borrador",
    "paint.fillMode.fill": "Relleno",
    "paint.lineMode.line": "Línea",
    "paint.ovalMode.oval": "Círculo",
    "paint.rectMode.rect": "Rectángulo",
    "paint.reshapeMode.reshape": "Cambiar forma",
    "paint.roundedRectMode.roundedRect": "Rectángulo redondeado",
    "paint.selectMode.select": "Seleccionar",
    "paint.textMode.text": "Texto",
    "paint.colorPicker.swap": "Intercambiar",
    "gui.dialog.notConnectDevice": "No se encuentran dispositivos conectados",
    "gui.dialog.prompt": "Nota",
    "gui.dialog.openPort": "Abrir Puerto",
    "gui.dialog.error": "Error",
    "gui.dialog.notSelectDevice": "No hay dispositivos seleccionados, por favor haz un clic en Extensión",
    "gui.dialog.connectDeviceError1": "Conectar Dispositivos",
    "gui.dialog.connectDeviceError2": ")Error, por favor comprueba: </br> *Cambiar del puerto de USB y reconectar el cable</br> *Comprueba si está conectado",
    "gui.dialog.connectDeviceError3": "Panel de Mando Principal</br> * Entra en el grupo oficial (671877416) para preguntas y dudas",
    "gui.dialog.calibration1": "Gira por favor",
    "gui.dialog.calibration2": "Calibrar Tabla de Control Principal</br>",
    "gui.dialog.runAsManager": "Please close Mind+ and right click on the software icon and select \"Run as administrator\". After starting, select this function again.",
    "gui.dialog.runErrorForVortex": "El sistema no estaba funcionando debidamente,salir de Vortex handshake",
    "gui.dialog.runError": "El sistema no estaba funcionando debidamente",
    "gui.dialog.close": "Close",
    "gui.dialog.variableNameSpecialCharacters": "variable name %1 cannot contain special characters:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "function name %1 cannot contain special characters:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "function arguments %1 cannot contain special characters:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "y",
    "gui.dialog.yes": "sí",
    "gui.dialog.no": "no",
    "gui.dialog.maxReset": "Borrar códigos en la zona de edición y cargar códigos originales de fábrica",
    "gui.dialog.feedbackCannotEmpty": "Por favor rellena el formulario de respuesta",
    "gui.dialog.noInstallCompiler": "No hay compilador instalado",
    "gui.dialog.install": "Instalar línea",
    "gui.dialog.cancle": "Cancelar",
    "gui.dialog.installingCompiler": "Instalando compilador",
    "gui.dialog.uploading": "Subiendo",
    "gui.dialog.changeLanuage": "Cambiar de idiomas va a borrar el actual programa,¿continúas?",
    "gui.dialog.boardNotSupportMpy": "La tabla actual %1 no soporta micropython",
    "gui.dialog.loadBlankItem": "ítem no soportado</br> cargando un ítem nuevo",
    "gui.dialog.switchBaudError": "Error en cambiar de  frecuencia en baudios",
    "gui.dialog.turnMicrobitCompass": "Need to calibrate the electronic compass, please adjust the attitude of mciro: bit to move the blinking LED lights until all LED lights are on",
    "gui.dialog.operationShow": "Demostración Operativa",
    "gui.dialog.mpython": "Tarjeta de control",
    "gui.dialog.computer": "Ordenador",
    "gui.dialog.delete": "Borrar",
    "gui.dialog.sureDeleteFile": "Estás seguro de borrar el archivo %2 en %1",
    "gui.dialog.sureDeleteFolder": "Estás seguro de borrar la carpeta %2 en %1",
    "gui.dialog.uncorrectPath": "No has elegido la ruta correcta",
    "gui.dialog.laterDownLoad": "Descargar más tarde",
    "gui.dialog.downLoadUpdate": "Download Update",
    "gui.dialog.dwnLoadWebsite": "Descargar actualización",
    "gui.dialog.laterUpdate": "Descargar más tarde",
    "gui.dialog.update": "Actualizar ahora",
    "gui.dialog.getUpdateMsgError": "Error en recibir mensaje de actualización",
    "gui.dialog.ok": "Ok",
    "gui.dialog.closeCodeBoxes": "Estás seguro de cerrar la ventana de código %1 ",
    "gui.dialog.emailError": "El formato de E-mail es incorrecto",
    "gui.dialog.emailEmpty": "El E-mail no puede ser vacío",
    "gui.dialog.bigFilePrompt": "El archivo es grande, espera un momento por favor",
    "gui.dialog.successFeedbackPrompt1": "Gracias por tu comentario, se encargará la página en 3 segundos",
    "gui.dialog.successFeedbackPrompt2": "Tu comentario se ha enviado, por favor envía otro más tarde",
    "gui.dialog.failedFeedbackPrompt": "Tu comentario no ha sido enviado correctamente, disculpa las molestias. Puedes contactar con nosotros a través de QQ para preguntas y dudas. Gracias.",
    "gui.dialog.successFeedbackPrompt3": "Gracias por tu comentario",
    "gui.dialog.forcedCloseSoftWare": "Error en guardar el archivo. ¿Cerrar el programa?",
    "gui.dialog.saveSuccess": "El proyecto se ha guardado",
    "gui.dialog.noSelectTruePath": "No has elegido la ruta correcta",
    "gui.dialog.newItemContent": "Crearás un nuevo proyecto y se sobrescribirá el proyecto actual. ¿Estás seguro?",
    "gui.dialog.deleteBlocksError": "Error en borrar el bloque %1. Por favor bórralo manualmente",
    "gui.dialog.netIsConnect": "Comprueba si el internet está conectado",
    "gui.dialog.needToUpdate": "¿Actualizar el compilador a la última versión: %1?",
    "gui.dialog.cantUseMpythonInArduinoC": "No hay compilador instalado, no podrás utilizar mpython bajo Arduino C.Si la computadora no tiene una red, puede descargar el 'Paquete de complementos fuera de línea del compilador' en el sitio web oficial.",
    "gui.dialog.enterMpyMode": "Entrar en modo micropython",
    "gui.dialog.runHardware": "Conecta el dispositivos antes de manipularlo",
    "gui.dialog.sureDelete": "¿Estás seguro de borrarlo?",
    "gui.dialog.notSupportWebGL": "Error en cargar stage, es posible que no soporte WebGL,</br> Mensaje detallado de Error: </br> %1 </br> Contacta con nosotros a través de QQ: 671877416",
    "gui.dialog.boardIsnotMPython": "La tabla de control principal conectado no es mPython, por favor conecta mPython",
    "gui.dialog.swdMicrobitUpload": "Hay que utlizar SWD para grabar micro:bit. Ejecutar Mind+ como administrador",
    "gui.dialog.openPortError": "Abrir Puerto %1 Error: %2",
    "gui.dialog.pressedA": "Mantén pulsado el botón A",
    "gui.dialog.unpressedA": "Suelta el botón A",
    "gui.dialog.unsupportProject": "ítem no soportado</br> cargando un ítem nuevo",
    "gui.dialog.openProjectError": "Proyectos en el modo Scratch se abre solamente en el modo Scratch</br> Por favor comprueba que la interfaz está en el modo Scratch o el modo arduino",
    "gui.dialog.installCompilerError1": "El compilador no se ha descargado debidamente, Intenta descargarlo de nuevo o descargar el instalador offline ",
    "gui.dialog.installCompilerOffline": "Instalar compilador sin conexión",
    "gui.dialog.installCompilerError2": "Error de verificación. Puedes descargar el instalador offline ",
    "gui.dialog.installCompilerError3": "Error de instalación. Puedes descargar el instalador offline ",
    "gui.dialog.installCompilerSuccess": "El compilador se ha instalado correctamente. Podrás utilizar Esp32",
    "gui.dialog.sureAdapter": "No hay adaptador. Comprueba si el adaptador está conectado",
    "gui.dialog.checkPort": "Error de abrir el puerto. Comprueba si el puerto está ocupado",
    "gui.dialog.changeBoard": "Switching board will clear the current program. Are you sure you want to continue?",
    "gui.crashMessage.somethingWrong": "¡Vaya! algo va mal",
    "gui.crashMessage.reload": "Recargar",
    "gui.crashMessage.submit": "Enviar",
    "gui.crashMessage.attachment": "Adjunto",
    "gui.crashMessage.errFeedbackNote": "Puedes escribirnos un mensaje para notificar errores o dar consejos. Mind+ se mejorará con tu apoyo",
    "gui.crashMessage.promptContent": "Disculpa las molestias. Ha habido un fallo en Mind+. Puedes notificar el error a la derecha o volver a cargar la ventana.",
    "gui.dialog.isSaveProject": "¿Guardar el proyecto?",
    "gui.dialog.save": "Guardar",
    "gui.dialog.notSave": "No guardar",
    "gui.dialog.variableIsNull": "Variable name cannot be empty",
    "gui.dialog.listNameIsNull": "list name cannot be empty",
    "gui.dialog.pictureAINotNull": "cannot be null",
    "gui.dialog.pictureAIInitServer": "please initialize the server first",
    "gui.dialog.pictureAIInitServerError": "Error initializing the server, please check the account number and secret key",
    "gui.dialog.versionPrompt": "The current project version「%1」",
    "gui.dialog.versionGreater": "The project version is higher than the current software version, project version 「%1」",
    "BACKDROPS_ARCTIC": "Arctic",
    "BACKDROPS_BASEBALL": "Baseball",
    "BACKDROPS_BASEBALL_2": "Baseball 2",
    "BACKDROPS_BASKETBALL": "Basketball",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Beach Malibu",
    "BACKDROPS_BEACH_RIO": "Beach Rio",
    "BACKDROPS_BEDROOM": "Bedroom",
    "BACKDROPS_BEDROOM_2": "Bedroom 2",
    "BACKDROPS_BEDROOM_3": "Bedroom 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Bench With View",
    "BACKDROPS_BLUE_SKY": "Blue Sky",
    "BACKDROPS_BLUE_SKY_2": "Blue Sky 2",
    "BACKDROPS_BLUE_SKY3": "Blue Sky3",
    "BACKDROPS_BOARDWALK": "Boardwalk",
    "BACKDROPS_CANYON": "Canyon",
    "BACKDROPS_CASTLE_1": "Castle 1",
    "BACKDROPS_CASTLE_2": "Castle 2",
    "BACKDROPS_CASTLE_3": "Castle 3",
    "BACKDROPS_CASTLE_4": "Castle 4",
    "BACKDROPS_CHALKBOARD": "Chalkboard",
    "BACKDROPS_CIRCLES": "Circles",
    "BACKDROPS_CITY_WITH_WATER": "City With Water",
    "BACKDROPS_COLORFUL_CITY": "Colorful City",
    "BACKDROPS_CONCERT": "Concert",
    "BACKDROPS_DESERT": "Desert",
    "BACKDROPS_FARM": "Farm",
    "BACKDROPS_FIELD_AT_MIT": "Field At Mit",
    "BACKDROPS_FLOWERS": "Flowers",
    "BACKDROPS_FOREST": "Forest",
    "BACKDROPS_GALAXY": "Galaxy",
    "BACKDROPS_GARDEN-ROCK": "Garden-rock",
    "BACKDROPS_GREEK_THEATER": "Greek Theater",
    "BACKDROPS_HALL": "Hall",
    "BACKDROPS_HAY_FIELD": "Hay Field",
    "BACKDROPS_GRAFFITI": "Graffiti",
    "BACKDROPS_HEARTS1": "Hearts1",
    "BACKDROPS_HEARTS2": "Hearts2",
    "BACKDROPS_HILL": "Hill",
    "BACKDROPS_JUNGLE": "Jungle",
    "BACKDROPS_JURASSIC": "Jurassic",
    "BACKDROPS_LIGHT": "Light",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": "Moon",
    "BACKDROPS_MOUNTAIN": "Mountain",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "Nebula",
    "BACKDROPS_NEON_TUNNEL": "Neon Tunnel",
    "BACKDROPS_NIGHT_CITY": "Night City",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Night City With Street",
    "BACKDROPS_PARTY": "Party",
    "BACKDROPS_PATHWAY": "Pathway",
    "BACKDROPS_PARTY_ROOM": "Party Room",
    "BACKDROPS_PLAYGROUND": "Playground",
    "BACKDROPS_PLAYING_FIELD": "Playing Field",
    "BACKDROPS_POOL": "Pool",
    "BACKDROPS_PURPLE": "Purple",
    "BACKDROPS_RAYS": "Rays",
    "BACKDROPS_REFRIGERATOR": "Refrigerator",
    "BACKDROPS_ROOM_1": "Room 1",
    "BACKDROPS_ROOM_2": "Room 2",
    "BACKDROPS_SAVANNA": "Savanna",
    "BACKDROPS_SCHOOL": "School",
    "BACKDROPS_SLOPES": "Slopes",
    "BACKDROPS_SOCCER": "Soccer",
    "BACKDROPS_SOCCER_2": "Soccer 2",
    "BACKDROPS_SPACE": "Space",
    "BACKDROPS_SPACE_2": "Space 2",
    "BACKDROPS_SPACE_3": "Space 3",
    "BACKDROPS_SPACE_4": "Space 4",
    "BACKDROPS_SPOTLIGHT-STAGE": "Spotlight-stage",
    "BACKDROPS_STARS": "Stars",
    "BACKDROPS_STRIPES": "Stripes",
    "BACKDROPS_THEATER": "Theater",
    "BACKDROPS_THEATER_2": "Theater 2",
    "BACKDROPS_TREE": "Tree",
    "BACKDROPS_UNDERWATER_1": "Underwater 1",
    "BACKDROPS_UNDERWATER_2": "Underwater 2",
    "BACKDROPS_URBAN": "Urban",
    "BACKDROPS_WALL_1": "Wall 1",
    "BACKDROPS_WALL_2": "Wall 2",
    "BACKDROPS_WATER_AND_ROCKS": "Water And Rocks",
    "BACKDROPS_WETLAND": "Wetland",
    "BACKDROPS_WINTER": "Winter",
    "BACKDROPS_WINTER-LIGHTS": "Winter-lights",
    "BACKDROPS_WITCH_HOUSE": "Witch House",
    "BACKDROPS_WOODS": "Woods",
    "BACKDROPS_WOODS_AND_BENCH": "Woods And Bench",
    "BACKDROPS_XY-GRID": "Xy-grid",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "abby-a",
    "COSTUMES_ABBY-B": "abby-b",
    "COSTUMES_ABBY-C": "abby-c",
    "COSTUMES_ABBY-D": "abby-d",
    "COSTUMES_AMON": "amon",
    "COSTUMES_ANDIE-A": "andie-a",
    "COSTUMES_ANDIE-B": "andie-b",
    "COSTUMES_ANDIE-C": "andie-c",
    "COSTUMES_ANDIE-D": "andie-d",
    "COSTUMES_ANINA_POP_DOWN": "anina pop down",
    "COSTUMES_ANINA_POP_FRONT": "anina pop front",
    "COSTUMES_ANINA_POP_L_ARM": "anina pop L arm",
    "COSTUMES_ANINA_POP_LEFT": "anina pop left",
    "COSTUMES_ANINA_POP_R_ARM": "anina pop R arm",
    "COSTUMES_ANINA_POP_RIGHT": "anina pop right",
    "COSTUMES_ANINA_POP_STAND": "anina pop stand",
    "COSTUMES_ANINA_R_CROSS": "anina R cross",
    "COSTUMES_ANINA_STANCE": "anina stance",
    "COSTUMES_ANINA_TOP_FREEZE": "anina top freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "anina top L step",
    "COSTUMES_ANINA_TOP_R_STEP": "anina top R step",
    "COSTUMES_ANINA_TOP_STAND": "anina top stand",
    "COSTUMES_APPLE": "apple",
    "COSTUMES_ARROW1-A": "arrow1-a",
    "COSTUMES_ARROW1-B": "arrow1-b",
    "COSTUMES_ARROW1-C": "arrow1-c",
    "COSTUMES_ARROW1-D": "arrow1-d",
    "COSTUMES_AVERY_WALKING-A": "avery walking-a",
    "COSTUMES_AVERY_WALKING-B": "avery walking-b",
    "COSTUMES_AVERY_WALKING-C": "avery walking-c",
    "COSTUMES_AVERY_WALKING-D": "avery walking-d",
    "COSTUMES_AVERY-A": "avery-a",
    "COSTUMES_AVERY-B": "avery-b",
    "COSTUMES_BALL-A": "ball-a",
    "COSTUMES_BALL-B": "ball-b",
    "COSTUMES_BALL-C": "ball-c",
    "COSTUMES_BALL-D": "ball-d",
    "COSTUMES_BALL-E": "ball-e",
    "COSTUMES_BALLERINA-A": "ballerina-a",
    "COSTUMES_BALLERINA-B": "ballerina-b",
    "COSTUMES_BALLERINA-C": "ballerina-c",
    "COSTUMES_BALLERINA-D": "ballerina-d",
    "COSTUMES_BALLOON1-A": "balloon1-a",
    "COSTUMES_BALLOON1-B": "balloon1-b",
    "COSTUMES_BALLOON1-C": "balloon1-c",
    "COSTUMES_BANANAS": "bananas",
    "COSTUMES_BASEBALL": "baseball",
    "COSTUMES_BASKETBALL": "basketball",
    "COSTUMES_BAT-A": "bat-a",
    "COSTUMES_BAT-B": "bat-b",
    "COSTUMES_BAT-C": "bat-c",
    "COSTUMES_BAT-D": "bat-d",
    "COSTUMES_BATTER-A": "batter-a",
    "COSTUMES_BATTER-B": "batter-b",
    "COSTUMES_BATTER-C": "batter-c",
    "COSTUMES_BATTER-D": "batter-d",
    "COSTUMES_BEACHBALL": "beachball",
    "COSTUMES_BEAR-A": "bear-a",
    "COSTUMES_BEAR-B": "bear-b",
    "COSTUMES_BEAR-WALK-A": "bear-walk-a",
    "COSTUMES_BEAR-WALK-B": "bear-walk-b",
    "COSTUMES_BEAR-WALK-C": "bear-walk-c",
    "COSTUMES_BEAR-WALK-D": "bear-walk-d",
    "COSTUMES_BEAR-WALK-E": "bear-walk-e",
    "COSTUMES_BEAR-WALK-F": "bear-walk-f",
    "COSTUMES_BEAR-WALK-G": "bear-walk-g",
    "COSTUMES_BEAR-WALK-H": "bear-walk-h",
    "COSTUMES_BEETLE": "beetle",
    "COSTUMES_BELL1": "bell1",
    "COSTUMES_BEN-A": "ben-a",
    "COSTUMES_BEN-B": "ben-b",
    "COSTUMES_BEN-C": "ben-c",
    "COSTUMES_BEN-D": "ben-d",
    "COSTUMES_BOWL-A": "bowl-a",
    "COSTUMES_BOWTIE": "bowtie",
    "COSTUMES_BREAD": "bread",
    "COSTUMES_BROOM": "broom",
    "COSTUMES_BUILDING-A": "building-a",
    "COSTUMES_BUILDING-B": "building-b",
    "COSTUMES_BUILDING-C": "building-c",
    "COSTUMES_BUILDING-D": "building-d",
    "COSTUMES_BUILDING-E": "building-e",
    "COSTUMES_BUILDING-F": "building-f",
    "COSTUMES_BUILDING-G": "building-g",
    "COSTUMES_BUILDING-H": "building-h",
    "COSTUMES_BUILDING-I": "building-i",
    "COSTUMES_BUILDING-J": "building-j",
    "COSTUMES_BUTTERFLY1-A": "butterfly1-a",
    "COSTUMES_BUTTERFLY1-B": "butterfly1-b",
    "COSTUMES_BUTTERFLY1-C": "butterfly1-c",
    "COSTUMES_BUTTERFLY2-A": "butterfly2-a",
    "COSTUMES_BUTTERFLY2-B": "butterfly2-b",
    "COSTUMES_BUTTON1": "button1",
    "COSTUMES_BUTTON2-A": "button2-a",
    "COSTUMES_BUTTON2-B": "button2-b",
    "COSTUMES_BUTTON3-A": "button3-a",
    "COSTUMES_BUTTON3-B": "button3-b",
    "COSTUMES_BUTTON4-A": "button4-a",
    "COSTUMES_BUTTON4-B": "button4-b",
    "COSTUMES_BUTTON5-A": "button5-a",
    "COSTUMES_BUTTON5-B": "button5-b",
    "COSTUMES_CAKE-A": "cake-a",
    "COSTUMES_CAKE-B": "cake-b",
    "COSTUMES_CALVRETT_JUMPING": "calvrett jumping",
    "COSTUMES_CALVRETT_THINKING": "calvrett thinking",
    "COSTUMES_CASEY-A": "casey-a",
    "COSTUMES_CASEY-B": "casey-b",
    "COSTUMES_CASEY-C": "casey-c",
    "COSTUMES_CASEY-D": "casey-d",
    "COSTUMES_CASSY-A": "cassy-a",
    "COSTUMES_CASSY-B": "cassy-b",
    "COSTUMES_CASSY-C": "cassy-c",
    "COSTUMES_CASSY-D": "cassy-d",
    "COSTUMES_CAT_2": "cat 2",
    "COSTUMES_CATCHER-A": "catcher-a",
    "COSTUMES_CATCHER-B": "catcher-b",
    "COSTUMES_CATCHER-C": "catcher-c",
    "COSTUMES_CATCHER-D": "catcher-d",
    "COSTUMES_CENTAUR-A": "centaur-a",
    "COSTUMES_CENTAUR-B": "centaur-b",
    "COSTUMES_CENTAUR-C": "centaur-c",
    "COSTUMES_CENTAUR-D": "centaur-d",
    "COSTUMES_CHAMP99-A": "champ99-a",
    "COSTUMES_CHAMP99-B": "champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "cheesy puffs",
    "COSTUMES_CHICK-A": "chick-a",
    "COSTUMES_CHICK-B": "chick-b",
    "COSTUMES_CHICK-C": "chick-c",
    "COSTUMES_CITY_BUS-A": "City Bus-a",
    "COSTUMES_CITY_BUS-B": "City Bus-b",
    "COSTUMES_CLOUD": "cloud",
    "COSTUMES_CLOUD-A": "cloud-a",
    "COSTUMES_CLOUD-B": "cloud-b",
    "COSTUMES_CLOUD-C": "cloud-c",
    "COSTUMES_CLOUD-D": "cloud-d",
    "COSTUMES_CONVERTIBLE": "convertible",
    "COSTUMES_CONVERTIBLE_3": "convertible 3",
    "COSTUMES_CRAB-A": "crab-a",
    "COSTUMES_CRAB-B": "crab-b",
    "COSTUMES_CRYSTAL-A": "crystal-a",
    "COSTUMES_CRYSTAL-B": "crystal-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "dee-a",
    "COSTUMES_DEE-B": "dee-b",
    "COSTUMES_DEE-C": "dee-c",
    "COSTUMES_DEE-D": "dee-d",
    "COSTUMES_DEE-E": "dee-e",
    "COSTUMES_DEVIN-A": "devin-a",
    "COSTUMES_DEVIN-B": "devin-b",
    "COSTUMES_DEVIN-C": "devin-c",
    "COSTUMES_DEVIN-D": "devin-d",
    "COSTUMES_DINOSAUR1-A": "dinosaur1-a",
    "COSTUMES_DINOSAUR1-B": "dinosaur1-b",
    "COSTUMES_DINOSAUR1-C": "dinosaur1-c",
    "COSTUMES_DINOSAUR1-D": "dinosaur1-d",
    "COSTUMES_DINOSAUR2-A": "dinosaur2-a",
    "COSTUMES_DINOSAUR2-B": "dinosaur2-b",
    "COSTUMES_DINOSAUR2-C": "dinosaur2-c",
    "COSTUMES_DINOSAUR2-D": "dinosaur2-d",
    "COSTUMES_DINOSAUR3-A": "dinosaur3-a",
    "COSTUMES_DINOSAUR3-B": "dinosaur3-b",
    "COSTUMES_DINOSAUR3-C": "dinosaur3-c",
    "COSTUMES_DINOSAUR3-D": "dinosaur3-d",
    "COSTUMES_DINOSAUR3-E": "dinosaur3-e",
    "COSTUMES_DINOSAUR4-A": "dinosaur4-a",
    "COSTUMES_DINOSAUR4-B": "dinosaur4-b",
    "COSTUMES_DINOSAUR4-C": "dinosaur4-c",
    "COSTUMES_DINOSAUR4-D": "dinosaur4-d",
    "COSTUMES_DIVER1": "diver1",
    "COSTUMES_DIVER2": "diver2",
    "COSTUMES_DM_FREEZE": "dm freeze",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "dm stance",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": "dog1-a",
    "COSTUMES_DOG1-B": "dog1-b",
    "COSTUMES_DOG2-A": "dog2-a",
    "COSTUMES_DOG2-B": "dog2-b",
    "COSTUMES_DOG2-C": "dog2-c",
    "COSTUMES_DONUT": "donut",
    "COSTUMES_DORIAN-A": "dorian-a",
    "COSTUMES_DORIAN-B": "dorian-b",
    "COSTUMES_DORIAN-C": "dorian-c",
    "COSTUMES_DORIAN-D": "dorian-d",
    "COSTUMES_DOT-A": "dot-a",
    "COSTUMES_DOT-B": "dot-b",
    "COSTUMES_DOT-C": "dot-c",
    "COSTUMES_DOT-D": "dot-d",
    "COSTUMES_DOVE-A": "dove-a",
    "COSTUMES_DOVE-B": "dove-b",
    "COSTUMES_DRAGON-A": "dragon-a",
    "COSTUMES_DRAGON-B": "dragon-b",
    "COSTUMES_DRAGON-C": "dragon-c",
    "COSTUMES_DRAGON1-A": "Dragon1-a",
    "COSTUMES_DRAGON1-B": "Dragon1-b",
    "COSTUMES_DRAGONFLY-A": "Dragonfly-a",
    "COSTUMES_DRAGONFLY-B": "Dragonfly-b",
    "COSTUMES_DRESS-A": "dress-a",
    "COSTUMES_DRESS-B": "dress-b",
    "COSTUMES_DRESS-C": "dress-c",
    "COSTUMES_DRUM-A": "drum-a",
    "COSTUMES_DRUM-B": "drum-b",
    "COSTUMES_DRUM-CYMBAL-A": "drum-cymbal-a",
    "COSTUMES_DRUM-CYMBAL-B": "drum-cymbal-b",
    "COSTUMES_DRUM-HIGHHAT-A": "drum-highhat-a",
    "COSTUMES_DRUM-HIGHHAT-B": "drum-highhat-b",
    "COSTUMES_DRUM-KIT": "drum-kit",
    "COSTUMES_DRUM-KIT-B": "drum-kit-b",
    "COSTUMES_DRUM-SNARE-A": "drum-snare-a",
    "COSTUMES_DRUM-SNARE-B": "drum-snare-b",
    "COSTUMES_DRUMS_CONGA-A": "Drums Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Drums Conga-b",
    "COSTUMES_DUCK": "duck",
    "COSTUMES_EARTH": "Earth",
    "COSTUMES_EASEL-A": "Easel-a",
    "COSTUMES_EASEL-B": "Easel-b",
    "COSTUMES_EASEL-C": "Easel-c",
    "COSTUMES_EGG-A": "egg-a",
    "COSTUMES_EGG-B": "egg-b",
    "COSTUMES_EGG-C": "egg-c",
    "COSTUMES_EGG-D": "egg-d",
    "COSTUMES_EGG-E": "egg-e",
    "COSTUMES_EGG-F": "egg-f",
    "COSTUMES_ELEPHANT-A": "elephant-a",
    "COSTUMES_ELEPHANT-B": "elephant-b",
    "COSTUMES_ELF-A": "elf-a",
    "COSTUMES_ELF-B": "elf-b",
    "COSTUMES_ELF-C": "elf-c",
    "COSTUMES_ELF-D": "elf-d",
    "COSTUMES_ELF-E": "elf-e",
    "COSTUMES_FAIRY-A": "fairy-a",
    "COSTUMES_FAIRY-B": "fairy-b",
    "COSTUMES_FAIRY-C": "fairy-c",
    "COSTUMES_FAIRY-D": "fairy-d",
    "COSTUMES_FAIRY-E": "fairy-e",
    "COSTUMES_FISH-A": "fish-a",
    "COSTUMES_FISH-B": "fish-b",
    "COSTUMES_FISH-C": "fish-c",
    "COSTUMES_FISH-D": "fish-d",
    "COSTUMES_FISHBOWL-A": "Fishbowl-a",
    "COSTUMES_FISHBOWL-B": "Fishbowl-b",
    "COSTUMES_FOOD_TRUCK-A": "Food Truck-a",
    "COSTUMES_FOOD_TRUCK-B": "Food Truck-b",
    "COSTUMES_FOOD_TRUCK-C": "Food Truck-c",
    "COSTUMES_FOOTBALL_RUNNING": "football running",
    "COSTUMES_FOOTBALL_STANDING": "football standing",
    "COSTUMES_FORTUNE_COOKIE": "fortune cookie",
    "COSTUMES_FOX-A": "fox-a",
    "COSTUMES_FOX-B": "fox-b",
    "COSTUMES_FOX-C": "fox-c",
    "COSTUMES_FRANK-A": "frank-a",
    "COSTUMES_FRANK-B": "frank-b",
    "COSTUMES_FRANK-C": "frank-c",
    "COSTUMES_FRANK-D": "frank-d",
    "COSTUMES_FROG": "frog",
    "COSTUMES_FROG_2-A": "Frog 2-a",
    "COSTUMES_FROG_2-B": "Frog 2-b",
    "COSTUMES_FROG_2-C": "Frog 2-c",
    "COSTUMES_FRUIT_PLATTER": "fruit platter",
    "COSTUMES_FRUITSALAD": "fruitsalad",
    "COSTUMES_GHOST-A": "ghost-a",
    "COSTUMES_GHOST-B": "ghost-b",
    "COSTUMES_GHOST-C": "ghost-c",
    "COSTUMES_GHOST-D": "ghost-d",
    "COSTUMES_GIFT-A": "gift-a",
    "COSTUMES_GIFT-B": "gift-b",
    "COSTUMES_GIRAFFE-A": "giraffe-a",
    "COSTUMES_GIRAFFE-B": "giraffe-b",
    "COSTUMES_GIRAFFE-C": "giraffe-c",
    "COSTUMES_GLASS_WATER-A": "glass water-a",
    "COSTUMES_GLASS_WATER-B": "glass water-b",
    "COSTUMES_GLASSES-A": "glasses-a",
    "COSTUMES_GLASSES-B": "glasses-b",
    "COSTUMES_GLASSES-C": "glasses-c",
    "COSTUMES_GLASSES-E": "glasses-e",
    "COSTUMES_GOALIE-A": "goalie-a",
    "COSTUMES_GOALIE-B": "goalie-b",
    "COSTUMES_GOALIE-C": "goalie-c",
    "COSTUMES_GOALIE-D": "goalie-d",
    "COSTUMES_GOALIE-E": "goalie-e",
    "COSTUMES_GOBLIN-A": "goblin-a",
    "COSTUMES_GOBLIN-B": "goblin-b",
    "COSTUMES_GOBLIN-C": "goblin-c",
    "COSTUMES_GOBLIN-D": "goblin-d",
    "COSTUMES_GRASSHOPPER-A": "Grasshopper-a",
    "COSTUMES_GRASSHOPPER-B": "Grasshopper-b",
    "COSTUMES_GRASSHOPPER-C": "Grasshopper-c",
    "COSTUMES_GRASSHOPPER-D": "Grasshopper-d",
    "COSTUMES_GRASSHOPPER-E": "Grasshopper-e",
    "COSTUMES_GRASSHOPPER-F": "Grasshopper-f",
    "COSTUMES_GREEN_FLAG": "green flag",
    "COSTUMES_GRIFFIN-A": "Griffin-a",
    "COSTUMES_GRIFFIN-B": "Griffin-b",
    "COSTUMES_GRIFFIN-C": "Griffin-c",
    "COSTUMES_GRIFFIN-D": "Griffin-d",
    "COSTUMES_GUITAR-A": "guitar-a",
    "COSTUMES_GUITAR-B": "guitar-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "guitar-electric1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "guitar-electric1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "guitar-electric2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "guitar-electric2-b",
    "COSTUMES_HANNAH-A": "hannah-a",
    "COSTUMES_HANNAH-B": "hannah-b",
    "COSTUMES_HANNAH-C": "hannah-c",
    "COSTUMES_HARE-A": "hare-a",
    "COSTUMES_HARE-B": "hare-b",
    "COSTUMES_HARE-C": "hare-c",
    "COSTUMES_HARPER-A": "harper-a",
    "COSTUMES_HARPER-B": "harper-b",
    "COSTUMES_HARPER-C": "harper-c",
    "COSTUMES_HAT-A": "hat-a",
    "COSTUMES_HAT-B": "hat-b",
    "COSTUMES_HAT-C": "hat-c",
    "COSTUMES_HAT-D": "hat-d",
    "COSTUMES_HATCHLING-A": "hatchling-a",
    "COSTUMES_HATCHLING-B": "hatchling-b",
    "COSTUMES_HATCHLING-C": "hatchling-c",
    "COSTUMES_HEART_CODE": "heart code",
    "COSTUMES_HEART_FACE": "heart face",
    "COSTUMES_HEART_LOVE": "heart love",
    "COSTUMES_HEART_PURPLE": "heart purple",
    "COSTUMES_HEART_RED": "heart red",
    "COSTUMES_HEART_SMILE": "heart smile",
    "COSTUMES_HEART_SWEET": "heart sweet",
    "COSTUMES_HEDGEHOG-A": "hedgehog-a",
    "COSTUMES_HEDGEHOG-B": "hedgehog-b",
    "COSTUMES_HEDGEHOG-C": "hedgehog-c",
    "COSTUMES_HEDGEHOG-D": "hedgehog-d",
    "COSTUMES_HEDGEHOG-E": "hedgehog-e",
    "COSTUMES_HEN-A": "hen-a",
    "COSTUMES_HEN-B": "hen-b",
    "COSTUMES_HEN-C": "hen-c",
    "COSTUMES_HEN-D": "hen-d",
    "COSTUMES_HIPPO1-A": "hippo1-a",
    "COSTUMES_HIPPO1-B": "hippo1-b",
    "COSTUMES_HOME_BUTTON": "home button",
    "COSTUMES_HORSE-A": "horse-a",
    "COSTUMES_HORSE-B": "horse-b",
    "COSTUMES_JAIME_WALKING-A": "jaime walking-a",
    "COSTUMES_JAIME_WALKING-B": "jaime walking-b",
    "COSTUMES_JAIME_WALKING-C": "jaime walking-c",
    "COSTUMES_JAIME_WALKING-D": "jaime walking-d",
    "COSTUMES_JAIME_WALKING-E": "jaime walking-e",
    "COSTUMES_JAIME-A": "jaime-a",
    "COSTUMES_JAIME-B": "jaime-b",
    "COSTUMES_JAMAL-A": "jamal-a",
    "COSTUMES_JAMAL-B": "jamal-b",
    "COSTUMES_JAMAL-C": "jamal-c",
    "COSTUMES_JAMAL-D": "jamal-d",
    "COSTUMES_JAR-A": "jar-a",
    "COSTUMES_JAR-B": "jar-b",
    "COSTUMES_JELLYFISH-A": "jellyfish-a",
    "COSTUMES_JELLYFISH-B": "jellyfish-b",
    "COSTUMES_JELLYFISH-C": "jellyfish-c",
    "COSTUMES_JELLYFISH-D": "jellyfish-d",
    "COSTUMES_JO_POP_DOWN": "jo pop down",
    "COSTUMES_JO_POP_FRONT": "jo pop front",
    "COSTUMES_JO_POP_L_ARM": "jo pop L arm",
    "COSTUMES_JO_POP_LEFT": "jo pop left",
    "COSTUMES_JO_POP_R_ARM": "jo pop R arm",
    "COSTUMES_JO_POP_RIGHT": "jo pop right",
    "COSTUMES_JO_POP_STAND": "jo pop stand",
    "COSTUMES_JO_STANCE": "jo stance",
    "COSTUMES_JO_TOP_L_CROSS": "jo top L cross",
    "COSTUMES_JO_TOP_L_LEG": "jo top L leg",
    "COSTUMES_JO_TOP_R_CROSS": "jo top R cross",
    "COSTUMES_JO_TOP_R_LEG": "jo top R leg",
    "COSTUMES_JO_TOP_STAND": "jo top stand",
    "COSTUMES_JORDYN-A": "jordyn-a",
    "COSTUMES_JORDYN-B": "jordyn-b",
    "COSTUMES_JORDYN-C": "jordyn-c",
    "COSTUMES_JORDYN-D": "jordyn-d",
    "COSTUMES_KAI-A": "kai-a",
    "COSTUMES_KAI-B": "kai-b",
    "COSTUMES_KEY": "key",
    "COSTUMES_KEYBOARD-A": "keyboard-a",
    "COSTUMES_KEYBOARD-B": "keyboard-b",
    "COSTUMES_KIRAN-A": "kiran-a",
    "COSTUMES_KIRAN-B": "kiran-b",
    "COSTUMES_KIRAN-C": "kiran-c",
    "COSTUMES_KIRAN-D": "kiran-d",
    "COSTUMES_KIRAN-E": "kiran-e",
    "COSTUMES_KIRAN-F": "kiran-f",
    "COSTUMES_KNIGHT": "knight",
    "COSTUMES_LADYBUG2": "ladybug2",
    "COSTUMES_LADYBUG2-A": "ladybug2-a",
    "COSTUMES_LADYBUG2-B": "ladybug2-b",
    "COSTUMES_LAPTOP": "laptop",
    "COSTUMES_LB_POP_DOWN": "lb pop down",
    "COSTUMES_LB_POP_FRONT": "lb pop front",
    "COSTUMES_LB_POP_L_ARM": "lb pop L arm",
    "COSTUMES_LB_POP_LEFT": "lb pop left",
    "COSTUMES_LB_POP_R_ARM": "lb pop R arm",
    "COSTUMES_LB_POP_RIGHT": "lb pop right",
    "COSTUMES_LB_POP_STAND": "lb pop stand",
    "COSTUMES_LB_STANCE": "lb stance",
    "COSTUMES_LB_TOP_L_CROSS": "lb top L cross",
    "COSTUMES_LB_TOP_L_LEG": "lb top L leg",
    "COSTUMES_LB_TOP_R_CROSS": "lb top R cross",
    "COSTUMES_LB_TOP_R_LEG": "lb top R leg",
    "COSTUMES_LB_TOP_STAND": "lb top stand",
    "COSTUMES_LIGHTNING": "lightning",
    "COSTUMES_LINE": "line",
    "COSTUMES_LION-A": "lion-a",
    "COSTUMES_LION-B": "lion-b",
    "COSTUMES_LION-C": "lion-c",
    "COSTUMES_LLAMA": "llama",
    "COSTUMES_LLAMA-B": "llama-b",
    "COSTUMES_LLAMA-C": "llama-c",
    "COSTUMES_MAGICWAND": "magicwand",
    "COSTUMES_MAX-A": "max-a",
    "COSTUMES_MAX-B": "max-b",
    "COSTUMES_MAX-C": "max-c",
    "COSTUMES_MAX-D": "max-d",
    "COSTUMES_MERMAID-A": "mermaid-a",
    "COSTUMES_MERMAID-B": "mermaid-b",
    "COSTUMES_MERMAID-C": "mermaid-c",
    "COSTUMES_MERMAID-D": "mermaid-d",
    "COSTUMES_MICROPHONE-A": "microphone-a",
    "COSTUMES_MICROPHONE-B": "microphone-b",
    "COSTUMES_MILK-A": "milk-a",
    "COSTUMES_MILK-B": "milk-b",
    "COSTUMES_MILK-C": "milk-c",
    "COSTUMES_MILK-D": "milk-d",
    "COSTUMES_MILK-E": "milk-e",
    "COSTUMES_MONET-A": "monet-a",
    "COSTUMES_MONET-B": "monet-b",
    "COSTUMES_MONET-C": "monet-c",
    "COSTUMES_MONET-D": "monet-d",
    "COSTUMES_MONET-E": "monet-e",
    "COSTUMES_MONKEY-A": "monkey-a",
    "COSTUMES_MONKEY-B": "monkey-b",
    "COSTUMES_MONKEY-C": "monkey-c",
    "COSTUMES_MOTORCYCLE-A": "Motorcycle-a",
    "COSTUMES_MOTORCYCLE-B": "Motorcycle-b",
    "COSTUMES_MOTORCYCLE-C": "Motorcycle-c",
    "COSTUMES_MOTORCYCLE-D": "Motorcycle-d",
    "COSTUMES_MOUSE1-A": "mouse1-a",
    "COSTUMES_MOUSE1-B": "mouse1-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "neigh pony",
    "COSTUMES_OCTOPUS-A": "octopus-a",
    "COSTUMES_OCTOPUS-B": "octopus-b",
    "COSTUMES_OCTOPUS-C": "octopus-c",
    "COSTUMES_OCTOPUS-D": "octopus-d",
    "COSTUMES_OCTOPUS-E": "octopus-e",
    "COSTUMES_ORANGE": "orange",
    "COSTUMES_ORANGE2-A": "orange2-a",
    "COSTUMES_ORANGE2-B": "orange2-b",
    "COSTUMES_OUTFIELDER-A": "outfielder-a",
    "COSTUMES_OUTFIELDER-B": "outfielder-b",
    "COSTUMES_OUTFIELDER-C": "outfielder-c",
    "COSTUMES_OUTFIELDER-D": "outfielder-d",
    "COSTUMES_OWL-A": "owl-a",
    "COSTUMES_OWL-B": "owl-b",
    "COSTUMES_OWL-C": "owl-c",
    "COSTUMES_PADDLE": "paddle",
    "COSTUMES_PANTHER-A": "panther-a",
    "COSTUMES_PANTHER-B": "panther-b",
    "COSTUMES_PANTHER-C": "panther-c",
    "COSTUMES_PANTS-A": "pants-a",
    "COSTUMES_PANTS-B": "pants-b",
    "COSTUMES_PARROT-A": "parrot-a",
    "COSTUMES_PARROT-B": "parrot-b",
    "COSTUMES_PARTY_HAT-A": "Party Hat-a",
    "COSTUMES_PARTY_HAT-B": "Party Hat-b",
    "COSTUMES_PARTY_HAT-E": "Party Hat-e",
    "COSTUMES_PENCIL-A": "pencil-a",
    "COSTUMES_PENCIL-B": "pencil-b",
    "COSTUMES_PENGUIN-A": "penguin-a",
    "COSTUMES_PENGUIN-B": "penguin-b",
    "COSTUMES_PENGUIN-C": "penguin-c",
    "COSTUMES_PENGUIN2-A": "penguin2-a",
    "COSTUMES_PENGUIN2-B": "penguin2-b",
    "COSTUMES_PENGUIN2-C": "penguin2-c",
    "COSTUMES_PENGUIN2-D": "penguin2-d",
    "COSTUMES_PITCHER-A": "pitcher-a",
    "COSTUMES_PITCHER-B": "pitcher-b",
    "COSTUMES_PITCHER-C": "pitcher-c",
    "COSTUMES_PITCHER-D": "pitcher-d",
    "COSTUMES_PLANET2": "planet2",
    "COSTUMES_POLAR_BEAR-A": "polar bear-a",
    "COSTUMES_POLAR_BEAR-B": "polar bear-b",
    "COSTUMES_POLAR_BEAR-C": "polar bear-c",
    "COSTUMES_POTION-A": "potion-a",
    "COSTUMES_POTION-B": "potion-b",
    "COSTUMES_POTION-C": "potion-c",
    "COSTUMES_PRINCE": "prince",
    "COSTUMES_PRINCESS-A": "princess-a",
    "COSTUMES_PRINCESS-B": "princess-b",
    "COSTUMES_PRINCESS-C": "princess-c",
    "COSTUMES_PRINCESS-D": "princess-d",
    "COSTUMES_PRINCESS-E": "princess-e",
    "COSTUMES_PUFFERFISH-A": "pufferfish-a",
    "COSTUMES_PUFFERFISH-B": "pufferfish-b",
    "COSTUMES_PUFFERFISH-C": "pufferfish-c",
    "COSTUMES_PUFFERFISH-D": "pufferfish-d",
    "COSTUMES_PUPPY_BACK": "puppy back",
    "COSTUMES_PUPPY_RIGHT": "puppy right",
    "COSTUMES_PUPPY_SIDE": "puppy side",
    "COSTUMES_PUPPY_SIT": "puppy sit",
    "COSTUMES_RABBIT-A": "rabbit-a",
    "COSTUMES_RABBIT-B": "rabbit-b",
    "COSTUMES_RABBIT-C": "rabbit-c",
    "COSTUMES_RABBIT-D": "rabbit-d",
    "COSTUMES_RABBIT-E": "rabbit-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": "rainbow",
    "COSTUMES_REFEREE-A": "referee-a",
    "COSTUMES_REFEREE-B": "referee-b",
    "COSTUMES_REFEREE-C": "referee-c",
    "COSTUMES_REFEREE-D": "referee-d",
    "COSTUMES_REINDEER": "reindeer",
    "COSTUMES_RETRO_ROBOT_A": "Retro Robot a",
    "COSTUMES_RETRO_ROBOT_B": "Retro Robot b",
    "COSTUMES_RETRO_ROBOT_C": "Retro Robot c",
    "COSTUMES_RIPLEY-A": "ripley-a",
    "COSTUMES_RIPLEY-B": "ripley-b",
    "COSTUMES_RIPLEY-C": "ripley-c",
    "COSTUMES_RIPLEY-D": "ripley-d",
    "COSTUMES_RIPLEY-E": "ripley-e",
    "COSTUMES_RIPLEY-F": "ripley-f",
    "COSTUMES_ROBOT-A": "robot-a",
    "COSTUMES_ROBOT-B": "robot-b",
    "COSTUMES_ROBOT-C": "robot-c",
    "COSTUMES_ROBOT-D": "robot-d",
    "COSTUMES_ROCKETSHIP-A": "rocketship-a",
    "COSTUMES_ROCKETSHIP-B": "rocketship-b",
    "COSTUMES_ROCKETSHIP-C": "rocketship-c",
    "COSTUMES_ROCKETSHIP-D": "rocketship-d",
    "COSTUMES_ROCKETSHIP-E": "rocketship-e",
    "COSTUMES_ROCKS": "rocks",
    "COSTUMES_ROOSTER-A": "rooster-a",
    "COSTUMES_ROOSTER-B": "rooster-b",
    "COSTUMES_ROOSTER-C": "rooster-c",
    "COSTUMES_RUBY-A": "ruby-a",
    "COSTUMES_RUBY-B": "ruby-b",
    "COSTUMES_SAILBOAT": "sailboat",
    "COSTUMES_SAM": "sam",
    "COSTUMES_SAXOPHONE-A": "saxophone-a",
    "COSTUMES_SAXOPHONE-B": "saxophone-b",
    "COSTUMES_SCARF-A": "Scarf-a",
    "COSTUMES_SCARF-B": "Scarf-b",
    "COSTUMES_SCARF-C": "Scarf-c",
    "COSTUMES_SHARK-A": "shark-a",
    "COSTUMES_SHARK-B": "shark-b",
    "COSTUMES_SHARK2-A": "shark2-a",
    "COSTUMES_SHARK2-B": "shark2-b",
    "COSTUMES_SHARK2-C": "shark2-c",
    "COSTUMES_SHIRT-A": "shirt-a",
    "COSTUMES_SHOES-A": "shoes-a",
    "COSTUMES_SHOES-B": "shoes-b",
    "COSTUMES_SHOES-C": "shoes-c",
    "COSTUMES_SHOES-D": "shoes-d",
    "COSTUMES_SHORTS-A": "shorts-a",
    "COSTUMES_SHORTS-B": "shorts-b",
    "COSTUMES_SHORTS-C": "shorts-c",
    "COSTUMES_SINGER1": "Singer1",
    "COSTUMES_SKELETON-A": "skeleton-a",
    "COSTUMES_SKELETON-B": "skeleton-b",
    "COSTUMES_SKELETON-D": "skeleton-d",
    "COSTUMES_SKELETON-E": "skeleton-e",
    "COSTUMES_SNAKE-A": "snake-a",
    "COSTUMES_SNAKE-B": "snake-b",
    "COSTUMES_SNAKE-C": "snake-c",
    "COSTUMES_SNOWFLAKE": "snowflake",
    "COSTUMES_SNOWMAN": "snowman",
    "COSTUMES_SOCCER_BALL": "soccer ball",
    "COSTUMES_SPEAKER": "speaker",
    "COSTUMES_SQUIRREL": "squirrel",
    "COSTUMES_STAR": "star",
    "COSTUMES_STARFISH-A": "starfish-a",
    "COSTUMES_STARFISH-B_": "starfish-b ",
    "COSTUMES_STOP": "stop",
    "COSTUMES_STRAWBERRY-A": "strawberry-a",
    "COSTUMES_STRAWBERRY-B": "strawberry-b",
    "COSTUMES_STRAWBERRY-C": "strawberry-c",
    "COSTUMES_STRAWBERRY-D": "strawberry-d",
    "COSTUMES_STRAWBERRY-E": "strawberry-e",
    "COSTUMES_SUN": "sun",
    "COSTUMES_SUNGLASSES-A": "sunglasses-a",
    "COSTUMES_SUNGLASSES-B": "sunglasses-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-wizard",
    "COSTUMES_TAKEOUT-A": "takeout-a",
    "COSTUMES_TAKEOUT-B": "takeout-b",
    "COSTUMES_TAKEOUT-C": "takeout-c",
    "COSTUMES_TAKEOUT-D": "takeout-d",
    "COSTUMES_TAKEOUT-E": "takeout-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 pop down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 pop front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 pop L arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 pop left",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 pop R arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 pop right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 pop stand",
    "COSTUMES_TEN80_STANCE": "Ten80 stance",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 top freeze",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 top L step",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 top R cross",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 top R step",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 top stand",
    "COSTUMES_TENNISBALL": "tennisball",
    "COSTUMES_TOUCAN-A": "toucan-a",
    "COSTUMES_TOUCAN-B": "toucan-b",
    "COSTUMES_TOUCAN-C": "toucan-c",
    "COSTUMES_TRAMPOLINE": "trampoline",
    "COSTUMES_TREE1": "tree1",
    "COSTUMES_TREES-A": "trees-a",
    "COSTUMES_TREES-B": "trees-b",
    "COSTUMES_TRUCK-A": "Truck-a",
    "COSTUMES_TRUCK-B": "Truck-b",
    "COSTUMES_TRUCK-C": "Truck-c",
    "COSTUMES_TRUMPET-A": "trumpet-a",
    "COSTUMES_TRUMPET-B": "trumpet-b",
    "COSTUMES_UNICORN": "unicorn",
    "COSTUMES_UNICORN_2": "unicorn 2",
    "COSTUMES_UNICORN_RUNNING-A": "unicorn running-a",
    "COSTUMES_UNICORN_RUNNING-B": "unicorn running-b",
    "COSTUMES_UNICORN_RUNNING-C": "unicorn running-c",
    "COSTUMES_UNICORN_RUNNING-D": "unicorn running-d",
    "COSTUMES_UNICORN_RUNNING-E": "unicorn running-e",
    "COSTUMES_UNICORN_RUNNING-F": "unicorn running-f",
    "COSTUMES_WAND": "wand",
    "COSTUMES_WANDA": "wanda",
    "COSTUMES_WATERMELON-A": "watermelon-a",
    "COSTUMES_WATERMELON-B": "watermelon-b",
    "COSTUMES_WATERMELON-C": "watermelon-c",
    "COSTUMES_WINTER_HAT": "Winter Hat",
    "COSTUMES_WITCH": "Witch",
    "COSTUMES_WITCH-A": "witch-a",
    "COSTUMES_WITCH-B": "witch-b",
    "COSTUMES_WITCH-C": "witch-c",
    "COSTUMES_WITCH-D": "witch-d",
    "COSTUMES_WIZARD_HAT": "Wizard Hat",
    "COSTUMES_WIZARD-A": "wizard-a",
    "COSTUMES_WIZARD-B": "wizard-b",
    "COSTUMES_WIZARD-C": "wizard-c",
    "COSTUMES_WIZARD-TOAD-A": "wizard-toad-a",
    "COSTUMES_WIZARD-TOAD-B": "wizard-toad-b",
    "COSTUMES_ZEBRA-A": "zebra-a",
    "COSTUMES_ZEBRA-B": "zebra-b",
    "COSTUMES_BLOCK-A": "Block-a",
    "COSTUMES_BLOCK-B": "Block-b",
    "COSTUMES_BLOCK-C": "Block-c",
    "COSTUMES_BLOCK-D": "Block-d",
    "COSTUMES_BLOCK-E": "Block-e",
    "COSTUMES_BLOCK-F": "Block-f",
    "COSTUMES_BLOCK-G": "Block-g",
    "COSTUMES_BLOCK-H": "Block-h",
    "COSTUMES_BLOCK-I": "Block-i",
    "COSTUMES_BLOCK-J": "Block-j",
    "COSTUMES_BLOCK-K": "Block-k",
    "COSTUMES_BLOCK-L": "Block-l",
    "COSTUMES_BLOCK-M": "Block-m",
    "COSTUMES_BLOCK-N": "Block-n",
    "COSTUMES_BLOCK-O": "Block-o",
    "COSTUMES_BLOCK-P": "Block-p",
    "COSTUMES_BLOCK-Q": "Block-q",
    "COSTUMES_BLOCK-R": "Block-r",
    "COSTUMES_BLOCK-S": "Block-s",
    "COSTUMES_BLOCK-T": "Block-t",
    "COSTUMES_BLOCK-U": "Block-u",
    "COSTUMES_BLOCK-V": "Block-v",
    "COSTUMES_BLOCK-W": "Block-w",
    "COSTUMES_BLOCK-X": "Block-x",
    "COSTUMES_BLOCK-Y": "Block-y",
    "COSTUMES_BLOCK-Z": "Block-z",
    "COSTUMES_GLOW-0": "Glow-0",
    "COSTUMES_GLOW-1": "Glow-1",
    "COSTUMES_GLOW-2": "Glow-2",
    "COSTUMES_GLOW-3": "Glow-3",
    "COSTUMES_GLOW-4": "Glow-4",
    "COSTUMES_GLOW-5": "Glow-5",
    "COSTUMES_GLOW-6": "Glow-6",
    "COSTUMES_GLOW-7": "Glow-7",
    "COSTUMES_GLOW-8": "Glow-8",
    "COSTUMES_GLOW-9": "Glow-9",
    "COSTUMES_GLOW-A": "Glow-A",
    "COSTUMES_GLOW-B": "Glow-B",
    "COSTUMES_GLOW-C": "Glow-C",
    "COSTUMES_GLOW-D": "Glow-D",
    "COSTUMES_GLOW-E": "Glow-E",
    "COSTUMES_GLOW-F": "Glow-F",
    "COSTUMES_GLOW-G": "Glow-G",
    "COSTUMES_GLOW-H": "Glow-H",
    "COSTUMES_GLOW-I": "Glow-I",
    "COSTUMES_GLOW-J": "Glow-J",
    "COSTUMES_GLOW-K": "Glow-K",
    "COSTUMES_GLOW-L": "Glow-L",
    "COSTUMES_GLOW-M": "Glow-M",
    "COSTUMES_GLOW-N": "Glow-N",
    "COSTUMES_GLOW-O": "Glow-O",
    "COSTUMES_GLOW-P": "Glow-P",
    "COSTUMES_GLOW-Q": "Glow-Q",
    "COSTUMES_GLOW-R": "Glow-R",
    "COSTUMES_GLOW-S": "Glow-S",
    "COSTUMES_GLOW-T": "Glow-T",
    "COSTUMES_GLOW-U": "Glow-U",
    "COSTUMES_GLOW-V": "Glow-V",
    "COSTUMES_GLOW-W": "Glow-W",
    "COSTUMES_GLOW-X": "Glow-X",
    "COSTUMES_GLOW-Y": "Glow-Y",
    "COSTUMES_GLOW-Z": "Glow-Z",
    "COSTUMES_STORY-A-1": "story-A-1",
    "COSTUMES_STORY-A-2": "story-A-2",
    "COSTUMES_STORY-A-3": "story-A-3",
    "COSTUMES_STORY-B-1": "story-B-1",
    "COSTUMES_STORY-B-2": "story-B-2",
    "COSTUMES_STORY-B-3": "story-B-3",
    "COSTUMES_STORY-C-1": "story-C-1",
    "COSTUMES_STORY-C-2": "story-C-2",
    "COSTUMES_STORY-C-3": "story-C-3",
    "COSTUMES_STORY-D-1": "story-D-1",
    "COSTUMES_STORY-D-2": "story-D-2",
    "COSTUMES_STORY-D-3": "story-D-3",
    "COSTUMES_STORY-E-1": "story-E-1",
    "COSTUMES_STORY-E-2": "story-E-2",
    "COSTUMES_STORY-E-3": "story-E-3",
    "COSTUMES_STORY-F-1": "story-F-1",
    "COSTUMES_STORY-F-2": "story-F-2",
    "COSTUMES_STORY-F-3": "story-F-3",
    "COSTUMES_STORY-G-1": "story-G-1",
    "COSTUMES_STORY-G-2": "story-G-2",
    "COSTUMES_STORY-G-3": "story-G-3",
    "COSTUMES_STORY-H-1": "story-H-1",
    "COSTUMES_STORY-H-2": "story-H-2",
    "COSTUMES_STORY-H-3": "story-H-3",
    "COSTUMES_STORY-I-1": "story-I-1",
    "COSTUMES_STORY-I-2": "story-I-2",
    "COSTUMES_STORY-I-3": "story-I-3",
    "COSTUMES_STORY-J-1": "story-J-1",
    "COSTUMES_STORY-J-2": "story-J-2",
    "COSTUMES_STORY-J-3": "story-J-3",
    "COSTUMES_STORY-K-1": "story-K-1",
    "COSTUMES_STORY-K-2": "story-K-2",
    "COSTUMES_STORY-K-3": "story-K-3",
    "COSTUMES_STORY-L-1": "story-L-1",
    "COSTUMES_STORY-L-2": "story-L-2",
    "COSTUMES_STORY-L-3": "story-L-3",
    "COSTUMES_STORY-M-1": "story-M-1",
    "COSTUMES_STORY-M-2": "story-M-2",
    "COSTUMES_STORY-M-3": "story-M-3",
    "COSTUMES_STORY-N-1": "story-N-1",
    "COSTUMES_STORY-N-2": "story-N-2",
    "COSTUMES_STORY-N-3": "story-N-3",
    "COSTUMES_STORY-O-1": "story-O-1",
    "COSTUMES_STORY-O-2": "story-O-2",
    "COSTUMES_STORY-O-3": "story-O-3",
    "COSTUMES_STORY-P-1": "story-P-1",
    "COSTUMES_STORY-P-2": "story-P-2",
    "COSTUMES_STORY-P-3": "story-P-3",
    "COSTUMES_STORY-Q-1": "story-Q-1",
    "COSTUMES_STORY-Q-2": "story-Q-2",
    "COSTUMES_STORY-Q-3": "story-Q-3",
    "COSTUMES_STORY-R-1": "story-R-1",
    "COSTUMES_STORY-R-2": "story-R-2",
    "COSTUMES_STORY-R-3": "story-R-3",
    "COSTUMES_STORY-S-1": "story-S-1",
    "COSTUMES_STORY-S-2": "story-S-2",
    "COSTUMES_STORY-S-3": "story-S-3",
    "COSTUMES_STORY-T-1": "story-T-1",
    "COSTUMES_STORY-T-2": "story-T-2",
    "COSTUMES_STORY-T-3": "story-T-3",
    "COSTUMES_STORY-U-1": "story-U-1",
    "COSTUMES_STORY-U-2": "story-U-2",
    "COSTUMES_STORY-U-3": "story-U-3",
    "COSTUMES_STORY-V-1": "story-V-1",
    "COSTUMES_STORY-V-2": "story-V-2",
    "COSTUMES_STORY-V-3": "story-V-3",
    "COSTUMES_STORY-W-1": "story-W-1",
    "COSTUMES_STORY-W-2": "story-W-2",
    "COSTUMES_STORY-W-3": "story-W-3",
    "COSTUMES_STORY-X-1": "story-X-1",
    "COSTUMES_STORY-X-2": "story-X-2",
    "COSTUMES_STORY-X-3": "story-X-3",
    "COSTUMES_STORY-Y-1": "story-Y-1",
    "COSTUMES_STORY-Y-2": "story-Y-2",
    "COSTUMES_STORY-Y-3": "story-Y-3",
    "COSTUMES_STORY-Z-1": "story-Z-1",
    "COSTUMES_STORY-Z-2": "story-Z-2",
    "COSTUMES_STORY-Z-3": "story-Z-3",
    "SOUNDS_A_BASS": "A Bass",
    "SOUNDS_A_ELEC_BASS": "A Elec Bass",
    "SOUNDS_A_ELEC_GUITAR": "A Elec Guitar",
    "SOUNDS_A_ELEC_PIANO": "A Elec Piano",
    "SOUNDS_A_GUITAR": "A Guitar",
    "SOUNDS_A_MINOR_UKULELE": "A Minor Ukulele",
    "SOUNDS_A_PIANO": "A Piano",
    "SOUNDS_A_SAX": "A Sax",
    "SOUNDS_A_TROMBONE": "A Trombone",
    "SOUNDS_A_TRUMPET": "A Trumpet",
    "SOUNDS_AFRO_STRING": "Afro String",
    "SOUNDS_ALERT": "Alert",
    "SOUNDS_ALIEN_CREAK1": "Alien Creak1",
    "SOUNDS_ALIEN_CREAK2": "Alien Creak2",
    "SOUNDS_B_BASS": "B Bass",
    "SOUNDS_B_ELEC_BASS": "B Elec Bass",
    "SOUNDS_B_ELEC_GUITAR": "B Elec Guitar",
    "SOUNDS_B_ELEC_PIANO": "B Elec Piano",
    "SOUNDS_B_GUITAR": "B Guitar",
    "SOUNDS_B_PIANO": "B Piano",
    "SOUNDS_B_SAX": "B Sax",
    "SOUNDS_B_TROMBONE": "B Trombone",
    "SOUNDS_B_TRUMPET": "B Trumpet",
    "SOUNDS_BAA": "Baa",
    "SOUNDS_BARK": "bark",
    "SOUNDS_BASKETBALL_BOUNCE": "basketball bounce",
    "SOUNDS_BASS_BEATBOX": "bass beatbox",
    "SOUNDS_BEAT_BOX1": "Beat Box1",
    "SOUNDS_BEAT_BOX2": "Beat Box2",
    "SOUNDS_BELL_CYMBAL": "bell cymbal",
    "SOUNDS_BELL_TOLL": "bell toll",
    "SOUNDS_BIG_BOING": "Big Boing",
    "SOUNDS_BIRD": "Bird",
    "SOUNDS_BIRTHDAY": "Birthday",
    "SOUNDS_BITE": "Bite",
    "SOUNDS_BOING": "boing",
    "SOUNDS_BONK": "Bonk",
    "SOUNDS_BOOM_CLOUD": "Boom Cloud",
    "SOUNDS_BOOP_BING_BOP": "Boop Bing Bop",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": "Bowling Strike",
    "SOUNDS_BUBBLES": "bubbles",
    "SOUNDS_BUZZ_WHIR": "buzz whir",
    "SOUNDS_C_BASS": "C Bass",
    "SOUNDS_C_ELEC_BASS": "C Elec Bass",
    "SOUNDS_C_ELEC_GUITAR": "C Elec Guitar",
    "SOUNDS_C_ELEC_PIANO": "C Elec Piano",
    "SOUNDS_C_GUITAR": "C Guitar",
    "SOUNDS_C_MAJOR_UKULELE": "C Major Ukulele",
    "SOUNDS_C_PIANO": "C Piano",
    "SOUNDS_C_SAX": "C Sax",
    "SOUNDS_C_TROMBONE": "C Trombone",
    "SOUNDS_C_TRUMPET": "C Trumpet",
    "SOUNDS_C2_BASS": "C2 Bass",
    "SOUNDS_C2_ELEC_BASS": "C2 Elec Bass",
    "SOUNDS_C2_ELEC_GUITAR": "C2 Elec Guitar",
    "SOUNDS_C2_ELEC_PIANO": "C2 Elec Piano",
    "SOUNDS_C2_GUITAR": "C2 guitar",
    "SOUNDS_C2_PIANO": "C2 Piano",
    "SOUNDS_C2_SAX": "C2 Sax",
    "SOUNDS_C2_TROMBONE": "C2 Trombone",
    "SOUNDS_C2_TRUMPET": "C2 Trumpet",
    "SOUNDS_CAR_HORN": "car horn",
    "SOUNDS_CAR_PASSING": "Car Passing",
    "SOUNDS_CAR_VROOM": "car vroom",
    "SOUNDS_CAVE": "Cave",
    "SOUNDS_CHATTER": "Chatter",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "cheer",
    "SOUNDS_CHILL": "Chill",
    "SOUNDS_CHIRP": "Chirp",
    "SOUNDS_CHOMP": "chomp",
    "SOUNDS_CHORD": "Chord",
    "SOUNDS_CLANG": "Clang",
    "SOUNDS_CLAP_BEATBOX": "Clap Beatbox",
    "SOUNDS_CLAPPING": "Clapping",
    "SOUNDS_CLASSICAL_PIANO": "Classical Piano",
    "SOUNDS_CLOCK_TICKING": "Clock Ticking",
    "SOUNDS_CLOWN_HONK": "clown honk",
    "SOUNDS_COIN": "Coin",
    "SOUNDS_COLLECT": "collect",
    "SOUNDS_COMPUTER_BEEP": "computer beep",
    "SOUNDS_COMPUTER_BEEP2": "Computer Beep2",
    "SOUNDS_CONNECT": "Connect",
    "SOUNDS_COUGH1": "Cough1",
    "SOUNDS_COUGH2": "Cough2",
    "SOUNDS_CRANK": "Crank",
    "SOUNDS_CRASH_BEATBOX": "Crash Beatbox",
    "SOUNDS_CRASH_CYMBAL": "crash cymbal",
    "SOUNDS_CRAZY_LAUGH": "Crazy Laugh",
    "SOUNDS_CRICKET": "Cricket",
    "SOUNDS_CRICKETS": "Crickets",
    "SOUNDS_CROAK": "croak",
    "SOUNDS_CROWD_GASP": "Crowd Gasp",
    "SOUNDS_CROWD_LAUGH": "Crowd Laugh",
    "SOUNDS_CRUNCH": "Crunch",
    "SOUNDS_CYMBAL": "Cymbal",
    "SOUNDS_CYMBAL_CRASH": "Cymbal Crash",
    "SOUNDS_CYMBAL_ECHO": "Cymbal Echo",
    "SOUNDS_D_BASS": "D Bass",
    "SOUNDS_D_ELEC_BASS": "D Elec Bass",
    "SOUNDS_D_ELEC_GUITAR": "D Elec Guitar",
    "SOUNDS_D_ELEC_PIANO": "D Elec Piano",
    "SOUNDS_D_GUITAR": "D Guitar",
    "SOUNDS_D_PIANO": "D Piano",
    "SOUNDS_D_SAX": "D Sax",
    "SOUNDS_D_TROMBONE": "D Trombone",
    "SOUNDS_D_TRUMPET": "D Trumpet",
    "SOUNDS_DANCE_AROUND": "dance around",
    "SOUNDS_DANCE_CELEBRATE": "dance celebrate",
    "SOUNDS_DANCE_CELEBRATE2": "dance celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": "dance chill out",
    "SOUNDS_DANCE_ENERGETIC": "Dance Energetic",
    "SOUNDS_DANCE_FUNKY": "dance funky",
    "SOUNDS_DANCE_HEAD_NOD": "Dance Head Nod",
    "SOUNDS_DANCE_MAGIC": "dance magic",
    "SOUNDS_DANCE_SITAR": "Dance Sitar",
    "SOUNDS_DANCE_SLOW_MO": "Dance Slow Mo",
    "SOUNDS_DANCE_SNARE_BEAT": "Dance Snare Beat",
    "SOUNDS_DANCE_SPACE": "Dance Space",
    "SOUNDS_DISCONNECT": "Disconnect",
    "SOUNDS_DOG1": "dog1",
    "SOUNDS_DOG2": "dog2",
    "SOUNDS_DOOR_CLOSING": "Door Closing",
    "SOUNDS_DOOR_CREAK": "Door Creak",
    "SOUNDS_DOORBELL": "Doorbell",
    "SOUNDS_DRIP_DROP": "Drip Drop",
    "SOUNDS_DRIVE_AROUND": "Drive Around",
    "SOUNDS_DRUM": "Drum",
    "SOUNDS_DRUM_BASS1": "Drum Bass1",
    "SOUNDS_DRUM_BASS2": "Drum Bass2",
    "SOUNDS_DRUM_BASS3": "Drum Bass3",
    "SOUNDS_DRUM_BOING": "Drum Boing",
    "SOUNDS_DRUM_BUZZ": "Drum Buzz",
    "SOUNDS_DRUM_FUNKY": "Drum Funky",
    "SOUNDS_DRUM_JAM": "Drum Jam",
    "SOUNDS_DRUM_MACHINE": "Drum Machine",
    "SOUNDS_DRUM_ROLL": "Drum Roll",
    "SOUNDS_DRUM_SATELLITE": "Drum Satellite",
    "SOUNDS_DRUM_SET1": "Drum Set1",
    "SOUNDS_DRUM_SET2": "Drum Set2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "duck",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dunnn",
    "SOUNDS_E_BASS": "E Bass",
    "SOUNDS_E_ELEC_BASS": "E Elec Bass",
    "SOUNDS_E_ELEC_GUITAR": "E Elec Guitar",
    "SOUNDS_E_ELEC_PIANO": "E Elec Piano",
    "SOUNDS_E_GUITAR": "E Guitar",
    "SOUNDS_E_PIANO": "E Piano",
    "SOUNDS_E_SAX": "E Sax",
    "SOUNDS_E_TROMBONE": "E Trombone",
    "SOUNDS_E_TRUMPET": "E Trumpet",
    "SOUNDS_EGGS": "Eggs",
    "SOUNDS_ELEC_PIANO_A_MINOR": "Elec Piano A Minor",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "Elec Piano C Major",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "Elec Piano F Major",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "Elec Piano G Major",
    "SOUNDS_ELEC_PIANO_LOOP": "Elec Piano Loop",
    "SOUNDS_EMOTIONAL_PIANO": "Emotional Piano",
    "SOUNDS_ENGINE": "Engine",
    "SOUNDS_F_BASS": "F Bass",
    "SOUNDS_F_ELEC_BASS": "F Elec Bass",
    "SOUNDS_F_ELEC_GUITAR": "F Elec Guitar",
    "SOUNDS_F_ELEC_PIANO": "F Elec Piano",
    "SOUNDS_F_GUITAR": "F Guitar",
    "SOUNDS_F_MAJOR_UKULELE": "F Major Ukulele",
    "SOUNDS_F_PIANO": "F Piano",
    "SOUNDS_F_SAX": "F Sax",
    "SOUNDS_F_TROMBONE": "F Trombone",
    "SOUNDS_F_TRUMPET": "F Trumpet",
    "SOUNDS_FAIRYDUST": "fairydust",
    "SOUNDS_FINGER_SNAP": "Finger Snap",
    "SOUNDS_FLAM_SNARE": "flam snare",
    "SOUNDS_FOOTSTEPS": "Footsteps",
    "SOUNDS_G_BASS": "G Bass",
    "SOUNDS_G_ELEC_BASS": "G Elec Bass",
    "SOUNDS_G_ELEC_GUITAR": "G Elec Guitar",
    "SOUNDS_G_ELEC_PIANO": "G Elec Piano",
    "SOUNDS_G_GUITAR": "G Guitar",
    "SOUNDS_G_PIANO": "G Piano",
    "SOUNDS_G_SAX": "G Sax",
    "SOUNDS_G_TROMBONE": "G Trombone",
    "SOUNDS_G_TRUMPET": "G Trumpet",
    "SOUNDS_G_UKULELE": "G Ukulele",
    "SOUNDS_GALLOP": "Gallop",
    "SOUNDS_GARDEN": "Garden",
    "SOUNDS_GLASS_BREAKING": "Glass Breaking",
    "SOUNDS_GLUG": "glug",
    "SOUNDS_GOAL_CHEER": "Goal Cheer",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Goose",
    "SOUNDS_GROWL": "Growl",
    "SOUNDS_GRUNT": "grunt",
    "SOUNDS_GUITAR_CHORDS1": "Guitar Chords1",
    "SOUNDS_GUITAR_CHORDS2": "Guitar Chords2",
    "SOUNDS_GUITAR_STRUM": "Guitar Strum",
    "SOUNDS_HAND_CLAP": "Hand Clap",
    "SOUNDS_HEAD_SHAKE": "Head Shake",
    "SOUNDS_HEY": "Hey",
    "SOUNDS_HI_BEATBOX": "Hi Beatbox",
    "SOUNDS_HI_NA_TABLA": "Hi Na Tabla",
    "SOUNDS_HI_TUN_TABLA": "Hi Tun Tabla",
    "SOUNDS_HIGH_CONGA": "High Conga",
    "SOUNDS_HIGH_HAT": "High Hat",
    "SOUNDS_HIGH_TOM": "High Tom",
    "SOUNDS_HIGH_WHOOSH": "High Whoosh",
    "SOUNDS_HIHAT_BEATBOX": "Hihat Beatbox",
    "SOUNDS_HIHAT_CYMBAL": "hihat cymbal",
    "SOUNDS_HIP_HOP": "Hip Hop",
    "SOUNDS_HORSE": "horse",
    "SOUNDS_HORSE_GALLOP": "horse gallop",
    "SOUNDS_HUMAN_BEATBOX1": "Human Beatbox1",
    "SOUNDS_HUMAN_BEATBOX2": "Human Beatbox2",
    "SOUNDS_JUMP": "Jump",
    "SOUNDS_JUNGLE": "Jungle",
    "SOUNDS_JUNGLE_FROGS": "Jungle Frogs",
    "SOUNDS_KICK_BACK": "Kick Back",
    "SOUNDS_KICK_DRUM": "Kick Drum",
    "SOUNDS_LARGE_COWBELL": "Large Cowbell",
    "SOUNDS_LASER1": "laser1",
    "SOUNDS_LASER2": "laser2",
    "SOUNDS_LAUGH1": "Laugh1",
    "SOUNDS_LAUGH2": "Laugh2",
    "SOUNDS_LAUGH3": "Laugh3",
    "SOUNDS_LO_GEH_TABLA": "Lo Geh Tabla",
    "SOUNDS_LO_GLISS_TABLA": "Lo Gliss Tabla",
    "SOUNDS_LOSE": "Lose",
    "SOUNDS_LOW_BOING": "Low Boing",
    "SOUNDS_LOW_CONGA": "Low Conga",
    "SOUNDS_LOW_SQUEAK": "Low Squeak",
    "SOUNDS_LOW_TOM": "Low Tom",
    "SOUNDS_LOW_WHOOSH": "Low Whoosh",
    "SOUNDS_MACHINE": "Machine",
    "SOUNDS_MAGIC_SPELL": "Magic Spell",
    "SOUNDS_MEDIEVAL1": "Medieval1",
    "SOUNDS_MEDIEVAL2": "Medieval2",
    "SOUNDS_MEOW": "meow",
    "SOUNDS_MEOW2": "meow2",
    "SOUNDS_MOO": "Moo",
    "SOUNDS_MOTORCYCLE_PASSING": "Motorcycle Passing",
    "SOUNDS_MOVIE_1": "Movie 1",
    "SOUNDS_MOVIE_2": "Movie 2",
    "SOUNDS_MUTED_CONGA": "Muted Conga",
    "SOUNDS_MYSTERY": "Mystery",
    "SOUNDS_OCEAN_WAVE": "ocean wave",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "Oops",
    "SOUNDS_ORCHESTRA_TUNING": "Orchestra Tuning",
    "SOUNDS_OWL": "owl",
    "SOUNDS_PARTY_NOISE": "Party Noise",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": "Ping Pong Hit",
    "SOUNDS_PLUCK": "Pluck",
    "SOUNDS_PLUNGE": "Plunge",
    "SOUNDS_POLICE_SIREN": "Police Siren",
    "SOUNDS_POP": "pop",
    "SOUNDS_RAIN": "Rain",
    "SOUNDS_RATTLE": "rattle",
    "SOUNDS_REFEREE_WHISTLE": "referee whistle",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "Ricochet",
    "SOUNDS_RIDE_CYMBAL": "Ride Cymbal",
    "SOUNDS_RING_TONE": "Ring Tone",
    "SOUNDS_RIP": "Rip",
    "SOUNDS_RIPPLES": "Ripples",
    "SOUNDS_ROLL_CYMBAL": "roll cymbal",
    "SOUNDS_ROOSTER": "rooster",
    "SOUNDS_SCRAMBLING_FEET": "Scrambling Feet",
    "SOUNDS_SCRATCH_BEATBOX": "scratch beatbox",
    "SOUNDS_SCRATCHY_BEAT": "Scratchy Beat",
    "SOUNDS_SCREAM1": "Scream1",
    "SOUNDS_SCREAM2": "Scream2",
    "SOUNDS_SCREECH": "Screech",
    "SOUNDS_SEAGULLS": "Seagulls",
    "SOUNDS_SEWING_MACHINE": "Sewing Machine",
    "SOUNDS_SHAKER": "Shaker",
    "SOUNDS_SHIP_BELL": "Ship Bell",
    "SOUNDS_SIDESTICK_SNARE": "sidestick snare",
    "SOUNDS_SINGER1": "Singer1",
    "SOUNDS_SINGER2": "Singer2",
    "SOUNDS_SIREN_WHISTLE": "Siren Whistle",
    "SOUNDS_SKID": "Skid",
    "SOUNDS_SLIDE_WHISTLE": "Slide Whistle",
    "SOUNDS_SMALL_COWBELL": "Small Cowbell",
    "SOUNDS_SNAP": "Snap",
    "SOUNDS_SNARE_BEATBOX": "Snare Beatbox",
    "SOUNDS_SNARE_BEATBOX2": "snare beatbox2",
    "SOUNDS_SNARE_DRUM": "Snare Drum",
    "SOUNDS_SNEAKER_SQUEAK": "Sneaker Squeak",
    "SOUNDS_SNEEZE1": "Sneeze1",
    "SOUNDS_SNEEZE2": "Sneeze2",
    "SOUNDS_SNORING": "Snoring",
    "SOUNDS_SNORT": "snort",
    "SOUNDS_SPACE_AMBIENCE": "Space Ambience",
    "SOUNDS_SPACE_FLYBY": "Space Flyby",
    "SOUNDS_SPACE_NOISE": "Space Noise",
    "SOUNDS_SPACE_RIPPLE": "space ripple",
    "SOUNDS_SPIRAL": "Spiral",
    "SOUNDS_SPLASH": "splash",
    "SOUNDS_SPLASH_CYMBAL": "splash cymbal",
    "SOUNDS_SPOOKY_STRING": "Spooky String",
    "SOUNDS_SQUAWK": "Squawk",
    "SOUNDS_SQUEAKS": "Squeaks",
    "SOUNDS_SQUEAKY_TOY": "Squeaky Toy",
    "SOUNDS_SQUISH_POP": "Squish Pop",
    "SOUNDS_STRING_ACCENT": "String Accent",
    "SOUNDS_STRING_PLUCK": "String Pluck",
    "SOUNDS_SUCTION_CUP": "Suction Cup",
    "SOUNDS_SUSPENSE": "Suspense",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura",
    "SOUNDS_TAP_CONGA": "Tap Conga",
    "SOUNDS_TAP_SNARE": "tap snare",
    "SOUNDS_TECHNO": "Techno",
    "SOUNDS_TECHNO2": "Techno2",
    "SOUNDS_TELEPHONE_RING": "Telephone Ring",
    "SOUNDS_TELEPHONE_RING2": "Telephone Ring2",
    "SOUNDS_TELEPORT": "Teleport",
    "SOUNDS_TELEPORT2": "Teleport2",
    "SOUNDS_TELEPORT3": "Teleport3",
    "SOUNDS_TENNIS_HIT": "Tennis Hit",
    "SOUNDS_THUNDER_STORM": "Thunder Storm",
    "SOUNDS_TOM_DRUM": "Tom Drum",
    "SOUNDS_TOY_HONK": "toy honk",
    "SOUNDS_TOY_ZING": "Toy Zing",
    "SOUNDS_TRAFFIC": "Traffic",
    "SOUNDS_TRAIN_WHISTLE": "Train Whistle",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_TRIUMPH": "Triumph",
    "SOUNDS_TROPICAL_BIRDS": "Tropical Birds",
    "SOUNDS_TRUMPET1": "Trumpet1",
    "SOUNDS_TRUMPET2": "Trumpet2",
    "SOUNDS_VIDEO_GAME_1": "Video Game 1",
    "SOUNDS_VIDEO_GAME_2": "Video Game 2",
    "SOUNDS_WAH_BEATBOX": "wah beatbox",
    "SOUNDS_WAND": "Wand",
    "SOUNDS_WATER_DROP": "Water drop",
    "SOUNDS_WHINNY": "Whinny",
    "SOUNDS_WHISTLE_THUMP": "Whistle Thump",
    "SOUNDS_WHIZ": "Whiz",
    "SOUNDS_WHOOP": "Whoop",
    "SOUNDS_WIN": "Win",
    "SOUNDS_WOBBLE": "Wobble",
    "SOUNDS_WOLF_HOWL": "wolf howl",
    "SOUNDS_WOOD_TAP": "Wood Tap",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_APPLE": "Apple",
    "SPRITES_ARROW1": "Arrow1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Walking",
    "SPRITES_BALL": "Ball",
    "SPRITES_BALLERINA": "Ballerina",
    "SPRITES_BALLOON1": "Balloon1",
    "SPRITES_BANANAS": "Bananas",
    "SPRITES_BASEBALL": "Baseball",
    "SPRITES_BASKETBALL": "Basketball",
    "SPRITES_BAT": "Bat",
    "SPRITES_BATTER": "Batter",
    "SPRITES_BEACHBALL": "Beachball",
    "SPRITES_BEAR": "Bear",
    "SPRITES_BEAR-WALKING": "Bear-walking",
    "SPRITES_BEETLE": "Beetle",
    "SPRITES_BELL": "Bell",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Bowl",
    "SPRITES_BOWTIE": "Bowtie",
    "SPRITES_BREAD": "Bread",
    "SPRITES_BROOM": "Broom",
    "SPRITES_BUILDINGS": "Buildings",
    "SPRITES_BUTTERFLY_1": "Butterfly 1",
    "SPRITES_BUTTERFLY_2": "Butterfly 2",
    "SPRITES_BUTTON1": "Button1",
    "SPRITES_BUTTON2": "Button2",
    "SPRITES_BUTTON3": "Button3",
    "SPRITES_BUTTON4": "Button4",
    "SPRITES_BUTTON5": "Button5",
    "SPRITES_CAKE": "Cake",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Cat 2",
    "SPRITES_CATCHER": "Catcher",
    "SPRITES_CENTAUR": "Centaur",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Chick",
    "SPRITES_CITY_BUS": "City Bus",
    "SPRITES_CLOUD": "Cloud",
    "SPRITES_CLOUDS": "Clouds",
    "SPRITES_CONVERTIBLE": "Convertible",
    "SPRITES_CONVERTIBLE_2": "Convertible 2",
    "SPRITES_CRAB": "Crab",
    "SPRITES_CRYSTAL": "Crystal",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "Dinosaur1",
    "SPRITES_DINOSAUR2": "Dinosaur2",
    "SPRITES_DINOSAUR3": "Dinosaur3",
    "SPRITES_DINOSAUR4": "Dinosaur4",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DIVER1": "Diver1",
    "SPRITES_DIVER2": "Diver2",
    "SPRITES_DOG1": "Dog1",
    "SPRITES_DOG2": "Dog2",
    "SPRITES_DONUT": "Donut",
    "SPRITES_DORIAN": "Dorian",
    "SPRITES_DOT": "Dot",
    "SPRITES_DOVE": "Dove",
    "SPRITES_DRAGON": "Dragon",
    "SPRITES_DRAGONFLY": "Dragonfly",
    "SPRITES_DRESS": "Dress",
    "SPRITES_DRUM": "Drum",
    "SPRITES_DRUM_KIT": "Drum Kit",
    "SPRITES_DRUM-CYMBAL": "Drum-cymbal",
    "SPRITES_DRUM-HIGHHAT": "Drum-highhat",
    "SPRITES_DRUM-SNARE": "Drum-snare",
    "SPRITES_DRUMS_CONGA": "Drums Conga",
    "SPRITES_DRUMS_TABLA": "Drums Tabla",
    "SPRITES_DUCK": "Duck",
    "SPRITES_EARTH": "Earth",
    "SPRITES_EASEL": "Easel",
    "SPRITES_EGG": "Egg",
    "SPRITES_ELEPHANT": "Elephant",
    "SPRITES_ELF": "Elf",
    "SPRITES_FAIRY": "Fairy",
    "SPRITES_FISH": "Fish",
    "SPRITES_FISHBOWL": "Fishbowl",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Football",
    "SPRITES_FORTUNE_COOKIE": "Fortune Cookie",
    "SPRITES_FOX": "Fox",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "Frog",
    "SPRITES_FROG_2_": "Frog 2 ",
    "SPRITES_FRUIT_PLATTER": "Fruit Platter",
    "SPRITES_FRUIT_SALAD": "Fruit Salad",
    "SPRITES_GHOST": "Ghost",
    "SPRITES_GIFT": "Gift",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GLASS_WATER": "Glass Water",
    "SPRITES_GLASSES": "Glasses",
    "SPRITES_GOALIE": "Goalie",
    "SPRITES_GOBLIN": "Goblin",
    "SPRITES_GRASSHOPPER": "Grasshopper",
    "SPRITES_GREEN_FLAG": "Green Flag",
    "SPRITES_GRIFFIN": "Griffin",
    "SPRITES_GUITAR": "Guitar",
    "SPRITES_GUITAR-ELECTRIC1": "Guitar-electric1",
    "SPRITES_GUITAR-ELECTRIC2": "Guitar-electric2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hare",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hat1 ",
    "SPRITES_HATCHLING": "Hatchling",
    "SPRITES_HEART": "Heart",
    "SPRITES_HEART_CANDY": "Heart Candy",
    "SPRITES_HEART_FACE": "Heart Face",
    "SPRITES_HEDGEHOG": "Hedgehog",
    "SPRITES_HEN": "Hen",
    "SPRITES_HIPPO1": "Hippo1",
    "SPRITES_HOME_BUTTON": "Home Button",
    "SPRITES_HORSE": "Horse",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "Jar",
    "SPRITES_JELLYFISH": "Jellyfish",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "Key",
    "SPRITES_KEYBOARD": "Keyboard",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Knight",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_LADYBUG1": "Ladybug1",
    "SPRITES_LADYBUG2": "Ladybug2",
    "SPRITES_LAPTOP": "Laptop",
    "SPRITES_LIGHTNING": "Lightning",
    "SPRITES_LINE": "Line",
    "SPRITES_LION": "Lion",
    "SPRITES_LLAMA": "Llama",
    "SPRITES_MAGIC_WAND": "Magic Wand",
    "SPRITES_MAX": "Max",
    "SPRITES_MERMAID": "Mermaid",
    "SPRITES_MICROPHONE": "Microphone",
    "SPRITES_MILK": "Milk",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Monkey",
    "SPRITES_MOTORCYCLE": "Motorcycle",
    "SPRITES_MOUSE1": "Mouse1",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": "Neigh Pony",
    "SPRITES_OCTOPUS": "Octopus",
    "SPRITES_ORANGE": "Orange",
    "SPRITES_ORANGE2": "Orange2",
    "SPRITES_OUTFIELDER": "Outfielder",
    "SPRITES_OWL": "Owl",
    "SPRITES_PADDLE": "Paddle",
    "SPRITES_PANTHER": "Panther",
    "SPRITES_PANTS": "Pants",
    "SPRITES_PARROT": "Parrot",
    "SPRITES_PARTY_HATS": "Party Hats",
    "SPRITES_PENCIL": "Pencil",
    "SPRITES_PENGUIN": "Penguin",
    "SPRITES_PENGUIN_2": "Penguin 2",
    "SPRITES_PITCHER": "Pitcher",
    "SPRITES_PLANET2": "Planet2",
    "SPRITES_POLAR_BEAR": "Polar Bear",
    "SPRITES_POTION": "Potion",
    "SPRITES_PRINCE": "Prince",
    "SPRITES_PRINCESS": "Princess",
    "SPRITES_PUFFERFISH": "Pufferfish",
    "SPRITES_PUPPY": "Puppy",
    "SPRITES_RABBIT": "Rabbit",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": "Rainbow",
    "SPRITES_REFEREE": "Referee",
    "SPRITES_REINDEER": "Reindeer",
    "SPRITES_RETRO_ROBOT": "Retro Robot",
    "SOUNDS_COMPUTER_BEEPS1": "computer beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "computer beeps2",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Robot",
    "SPRITES_ROCKETSHIP": "Rocketship",
    "SPRITES_ROCKS": "Rocks",
    "SPRITES_ROOSTER": "Rooster",
    "SPRITES_RUBY": "Ruby",
    "SPRITES_SAILBOAT": "Sailboat",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "Saxophone",
    "SPRITES_SCARF": "Scarf",
    "SPRITES_SHARK": "Shark",
    "SPRITES_SHARK_2": "Shark 2",
    "SPRITES_SHIRT": "Shirt",
    "SPRITES_SHOES": "Shoes",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "Singer1",
    "SPRITES_SKELETON": "Skeleton",
    "SPRITES_SNAKE": "Snake",
    "SPRITES_SNOWFLAKE": "Snowflake",
    "SPRITES_SNOWMAN": "Snowman",
    "SPRITES_SOCCER_BALL": "Soccer Ball",
    "SPRITES_SPEAKER": "Speaker",
    "SPRITES_SQUIRREL": "Squirrel",
    "SPRITES_STAR": "Star",
    "SPRITES_STARFISH": "Starfish",
    "SPRITES_STOP": "Stop",
    "SPRITES_STRAWBERRY": "Strawberry",
    "SPRITES_SUN": "Sun",
    "SPRITES_SUNGLASSES1": "Sunglasses1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Takeout",
    "SPRITES_TEN80_DANCE": "Ten80 Dance",
    "SPRITES_TENNIS_BALL": "Tennis Ball",
    "SPRITES_TOUCAN": "Toucan",
    "SPRITES_TRAMPOLINE": "Trampoline",
    "SPRITES_TREE1": "Tree1",
    "SPRITES_TREES": "Trees",
    "SPRITES_TRUCK": "Truck",
    "SPRITES_TRUMPET": "Trumpet",
    "SPRITES_UNICORN": "Unicorn",
    "SPRITES_UNICORN_2": "Unicorn 2",
    "SPRITES_UNICORN_RUNNING": "Unicorn Running",
    "SPRITES_WAND": "Wand",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Watermelon",
    "SPRITES_WINTER_HAT": "Winter Hat",
    "SPRITES_WITCH": "Witch",
    "SPRITES_WIZARD": "Wizard",
    "SPRITES_WIZARD_HAT": "Wizard Hat",
    "SPRITES_WIZARD-TOAD": "Wizard-toad",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "Block-A",
    "SPRITES_BLOCK-B": "Block-B",
    "SPRITES_BLOCK-C": "Block-C",
    "SPRITES_BLOCK-D": "Block-D",
    "SPRITES_BLOCK-E": "Block-E",
    "SPRITES_BLOCK-F": "Block-F",
    "SPRITES_BLOCK-G": "Block-G",
    "SPRITES_BLOCK-H": "Block-H",
    "SPRITES_BLOCK-I": "Block-I",
    "SPRITES_BLOCK-J": "Block-J",
    "SPRITES_BLOCK-K": "Block-K",
    "SPRITES_BLOCK-L": "Block-L",
    "SPRITES_BLOCK-M": "Block-M",
    "SPRITES_BLOCK-N": "Block-N",
    "SPRITES_BLOCK-O": "Block-O",
    "SPRITES_BLOCK-P": "Block-P",
    "SPRITES_BLOCK-Q": "Block-Q",
    "SPRITES_BLOCK-R": "Block-R",
    "SPRITES_BLOCK-S": "Block-S",
    "SPRITES_BLOCK-T": "Block-T",
    "SPRITES_BLOCK-U": "Block-U",
    "SPRITES_BLOCK-V": "Block-V",
    "SPRITES_BLOCK-W": "Block-W",
    "SPRITES_BLOCK-X": "Block-X",
    "SPRITES_BLOCK-Y": "Block-Y",
    "SPRITES_BLOCK-Z": "Block-Z",
    "SPRITES_GLOW-0": "Glow-0",
    "SPRITES_GLOW-1": "Glow-1",
    "SPRITES_GLOW-2": "Glow-2",
    "SPRITES_GLOW-3": "Glow-3",
    "SPRITES_GLOW-4": "Glow-4",
    "SPRITES_GLOW-5": "Glow-5",
    "SPRITES_GLOW-6": "Glow-6",
    "SPRITES_GLOW-7": "Glow-7",
    "SPRITES_GLOW-8": "Glow-8",
    "SPRITES_GLOW-9": "Glow-9",
    "SPRITES_GLOW-A": "Glow-A",
    "SPRITES_GLOW-B": "Glow-B",
    "SPRITES_GLOW-C": "Glow-C",
    "SPRITES_GLOW-D": "Glow-D",
    "SPRITES_GLOW-E": "Glow-E",
    "SPRITES_GLOW-F": "Glow-F",
    "SPRITES_GLOW-G": "Glow-G",
    "SPRITES_GLOW-H": "Glow-H",
    "SPRITES_GLOW-I": "Glow-I",
    "SPRITES_GLOW-J": "Glow-J",
    "SPRITES_GLOW-K": "Glow-K",
    "SPRITES_GLOW-L": "Glow-L",
    "SPRITES_GLOW-M": "Glow-M",
    "SPRITES_GLOW-N": "Glow-N",
    "SPRITES_GLOW-O": "Glow-O",
    "SPRITES_GLOW-P": "Glow-P",
    "SPRITES_GLOW-Q": "Glow-Q",
    "SPRITES_GLOW-R": "Glow-R",
    "SPRITES_GLOW-S": "Glow-S",
    "SPRITES_GLOW-T": "Glow-T",
    "SPRITES_GLOW-U": "Glow-U",
    "SPRITES_GLOW-V": "Glow-V",
    "SPRITES_GLOW-W": "Glow-W",
    "SPRITES_GLOW-X": "Glow-X",
    "SPRITES_GLOW-Y": "Glow-Y",
    "SPRITES_GLOW-Z": "Glow-Z",
    "SPRITES_STORY-A": "Story-A",
    "SPRITES_STORY-B": "Story-B",
    "SPRITES_STORY-C": "Story-C",
    "SPRITES_STORY-D": "Story-D",
    "SPRITES_STORY-E": "Story-E",
    "SPRITES_STORY-F": "Story-F",
    "SPRITES_STORY-G": "Story-G",
    "SPRITES_STORY-H": "Story-H",
    "SPRITES_STORY-I": "Story-I",
    "SPRITES_STORY-J": "Story-J",
    "SPRITES_STORY-K": "Story-K",
    "SPRITES_STORY-L": "Story-L",
    "SPRITES_STORY-M": "Story-M",
    "SPRITES_STORY-N": "Story-N",
    "SPRITES_STORY-O": "Story-O",
    "SPRITES_STORY-P": "Story-P",
    "SPRITES_STORY-Q": "Story-Q",
    "SPRITES_STORY-R": "Story-R",
    "SPRITES_STORY-S": "Story-S",
    "SPRITES_STORY-T": "Story-T",
    "SPRITES_STORY-U": "Story-U",
    "SPRITES_STORY-V": "Story-V",
    "SPRITES_STORY-W": "Story-W",
    "SPRITES_STORY-X": "Story-X",
    "SPRITES_STORY-Y": "Story-Y",
    "SPRITES_STORY-Z": "Story-Z"
  },
  "eu": {
    "gui.backpack.header": "Motxila",
    "gui.backpack.errorBackpack": "Errorea motxila kargatzean",
    "gui.backpack.loadingBackpack": "Kargatzen...",
    "gui.backpack.more": "Gehiago",
    "gui.backpack.emptyBackpack": "Motxila hutsik dago",
    "gui.gui.costumesTab": "Tankerak",
    "gui.gui.soundsTab": "Soinuak",
    "gui.gui.backdropsTab": "Atzeko oihalak",
    "gui.gui.addExtension": "Gehitu gehigarri bat",
    "gui.costumeTab.addCostumeFromLibrary": "Aukeratu tankera bat",
    "gui.costumeLibrary.chooseACostume": "Aukeratu tankera bat",
    "gui.costumeTab.addBackdropFromLibrary": "Aukeratu atzeko oihal bat",
    "gui.costumeTab.addBlankCostume": "Margotu",
    "gui.costumeTab.addSurpriseCostume": "Ezustekoa",
    "gui.costumeTab.addFileBackdrop": "Kargatu atzeko oihala",
    "gui.costumeTab.addFileCostume": "Kargatu tankera",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Moztu",
    "gui.soundEditor.stop": "Gelditu",
    "gui.soundEditor.sound": "Soinua",
    "gui.soundEditor.play": "Erreproduzitu",
    "gui.soundEditor.save": "Gorde",
    "gui.soundEditor.undo": "Desegin",
    "gui.soundEditor.redo": "Berregin",
    "gui.soundEditor.faster": "Azkarrago",
    "gui.soundEditor.slower": "Motelago",
    "gui.soundEditor.echo": "Oihartzuna",
    "gui.soundEditor.robot": "Robota",
    "gui.soundEditor.louder": "Ozenago",
    "gui.soundEditor.softer": "Isilago",
    "gui.soundEditor.reverse": "Alderantzikatu",
    "gui.soundTab.recordSound": "Grabatu",
    "gui.soundTab.addSoundFromLibrary": "Aukeratu soinu bat",
    "gui.soundTab.surpriseSound": "Ezustekoa",
    "gui.soundTab.fileUploadSound": "Kargatu soinua",
    "gui.controls.stop": "Gelditu",
    "gui.controls.go": "Abiatu",
    "gui.stageHeader.stageSizeUnFull": "Atera pantaila osoko modutik",
    "gui.SpriteInfo.show": "Erakutsi",
    "gui.SpriteInfo.size": "Tamaina",
    "gui.directionPicker.rotationStyles.allAround": "Norabide guztietara",
    "gui.directionPicker.rotationStyles.leftRight": "Ezker/Eskuin",
    "gui.directionPicker.rotationStyles.dontRotate": "Ez biratu",
    "gui.spriteSelectorItem.contextMenuDuplicate": "bikoiztu",
    "gui.spriteSelectorItem.contextMenuDelete": "ezabatu",
    "gui.spriteSelectorItem.contextMenuExport": "esportatu",
    "gui.spriteSelector.addSpriteFromPaint": "Margotu",
    "gui.spriteSelector.addSpriteFromSurprise": "Ezustekoa",
    "gui.spriteSelector.addSpriteFromFile": "Kargatu pertsonaia",
    "gui.stageSelector.stage": "Eszena",
    "gui.stageSelector.backdrops": "Atzeko oihalak",
    "gui.stageSelector.addBackdropFromPaint": "Margotu",
    "gui.stageSelector.addBackdropFromSurprise": "Ezustekoa",
    "gui.stageSelector.addBackdropFromFile": "Kargatu atzeko oihala",
    "gui.modal.back": "Atzera",
    "gui.library.filterPlaceholder": "Bilatu",
    "gui.libraryTags.all": "Guztiak",
    "gui.libraryTags.animals": "Animaliak",
    "gui.libraryTags.dance": "Dantza",
    "gui.libraryTags.effects": "Efektuak",
    "gui.libraryTags.fantasy": "Fantasia",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Janaria",
    "gui.libraryTags.indoors": "Barnealdeak",
    "gui.libraryTags.loops": "Begiztak",
    "gui.libraryTags.music": "Musika",
    "gui.libraryTags.notes": "Notak",
    "gui.libraryTags.outdoors": "Kanpoaldeak",
    "gui.libraryTags.patterns": "Patroiak",
    "gui.libraryTags.people": "Jendea",
    "gui.libraryTags.percussion": "Perkusioa",
    "gui.libraryTags.space": "Espazioa",
    "gui.libraryTags.sports": "Kirolak",
    "gui.libraryTags.underwater": "Itsaspean",
    "gui.libraryTags.voice": "Ahotsa",
    "gui.libraryTags.wacky": "Zoroa",
    "gui.libraryTags.animation": "Animazioa",
    "gui.libraryTags.art": "Artea",
    "gui.libraryTags.games": "Jolasak",
    "gui.libraryTags.stories": "Istorioak",
    "gui.libraryTags.letters": "Hizkiak",
    "gui.soundLibrary.chooseASound": "Aukeratu soinu bat",
    "gui.SpriteInfo.spritePlaceholder": "Izena",
    "gui.cards.more-things-to-try": "Probatzeko gauza gehiago!",
    "gui.cards.see-more": "Ikusi gehiago",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Itxi",
    "gui.loader.message1": "Blokeak sortzen ...",
    "gui.loader.message2": "Pertsonaiak kargatzen ...",
    "gui.loader.message3": "Soinuak kargatzen ...",
    "gui.loader.message4": "Gehigarriak kargatzen ...",
    "gui.loader.message5": "Katuak gidatzen...",
    "gui.loader.message6": "Nanoak igortzen ...",
    "gui.loader.message7": "Puxikak puzten ...",
    "gui.loader.message8": "Emojiak prestatzen ...",
    "gui.loader.headline": "Proiektua kargatzen",
    "gui.cameraModal.cameraModalTitle": "Atera argazki bat",
    "gui.cameraModal.loadingCameraMessage": "Kamera kargatzen...",
    "gui.cameraModal.permissionRequest": "Zure baimena behar dugu zure kamera erabiltzeko",
    "gui.cameraModal.retakePhoto": "Atera argazkia berriz",
    "gui.cameraModal.save": "Gorde",
    "gui.cameraModal.takePhoto": "Atera argazkia",
    "gui.cameraModal.loadingCaption": "Kargatzen...",
    "gui.cameraModal.enableCameraCaption": "Gaitu kamera",
    "gui.recordModal.title": "Grabatu soinua",
    "gui.playbackStep.stopMsg": "Gelditu",
    "gui.playbackStep.playMsg": "Erreproduzitu",
    "gui.playbackStep.loadingMsg": "Kargatzen...",
    "gui.playbackStep.saveMsg": "Gorde",
    "gui.playbackStep.reRecordMsg": "Grabatu berriz",
    "gui.webglModal.label": "Zure nabigatzaileak ez du WebGL onartzen",
    "gui.webglModal.webgllink": "ez duela WebGL onartzen",
    "gui.prompt.cancel": "Utzi",
    "gui.prompt.ok": "Ados",
    "gui.customProcedures.addAnInputNumberText": "Gehitu sarrera bat",
    "gui.customProcedures.addAnInputBoolean": "Gehitu sarrera bat",
    "gui.customProcedures.numberTextType": "zenbakia edo testua",
    "gui.customProcedures.booleanType": "boolearra",
    "gui.customProcedures.addALabel": "Gehitu etiketa bat",
    "gui.customProcedures.runWithoutScreenRefresh": "Abiatu pantaila eguneratu gabe",
    "gui.customProcedures.cancel": "Utzi",
    "gui.customProcedures.ok": "Ados",
    "gui.extension.microbit.description": "Konektatu zure proiektuak munduarekin.",
    "gui.extension.music.name": "Musika",
    "gui.extension.music.description": "Jo musika tresnak eta danborrak.",
    "gui.extension.pen.name": "Arkatza",
    "gui.extension.pen.description": "Marraztu zure pertsonaiaz.",
    "gui.gui.variableScopeOptionAllSprites": "Pertsonai guztientzat",
    "gui.gui.variableScopeOptionSpriteOnly": "Pertsonai honentzat soilik",
    "gui.gui.variablePromptAllSpritesMessage": "Aldagai hau pertsonai guztientzat erabili daiteke.",
    "gui.monitor.contextMenu.default": "ohiko tamaina",
    "gui.monitor.contextMenu.large": "tamaina handia",
    "gui.monitor.contextMenu.slider": "graduatzailea",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "norabidea",
    "gui.opcodeLabels.xposition": "x kokapena",
    "gui.opcodeLabels.yposition": "y kokapena",
    "gui.opcodeLabels.size": "tamaina",
    "gui.opcodeLabels.costumename": "tankeraren izena",
    "gui.opcodeLabels.costumenumber": "pertsonaia zenbakia",
    "gui.opcodeLabels.backdropname": "atzeko oihalaren izena",
    "gui.opcodeLabels.backdropnumber": "atzeko oihal zenbakia",
    "gui.opcodeLabels.volume": "bolumena",
    "gui.opcodeLabels.tempo": "tempoa",
    "gui.opcodeLabels.answer": "erantzuna",
    "gui.opcodeLabels.loudness": "ozentasuna",
    "gui.opcodeLabels.year": "urtea",
    "gui.opcodeLabels.month": "hilabetea",
    "gui.opcodeLabels.date": "data",
    "gui.opcodeLabels.dayofweek": "asteko eguna",
    "gui.opcodeLabels.hour": "ordua",
    "gui.opcodeLabels.minute": "minutua",
    "gui.opcodeLabels.second": "segundoa",
    "gui.opcodeLabels.timer": "kronometroa",
    "music.categoryName": "Musika",
    "translate.categoryName": "Itzuli",
    "pen.categoryName": "Arkatza",
    "pen.changeColorParam": "aldatu arkatzaren [COLOR_PARAM][VALUE] unitate",
    "pen.changeHue": "aldatu arkatzaren kolorea [HUE] tonu",
    "pen.changeShade": "aldatu arkatzaren itzala [SHADE] unitate",
    "pen.changeSize": "aldatu arkatzaren tamaina [SIZE] unitate",
    "pen.clear": "ezabatu guztia",
    "pen.colorMenu.brightness": "distira",
    "pen.colorMenu.color": "kolorea",
    "pen.colorMenu.saturation": "asetasuna",
    "pen.colorMenu.transparency": "gardentasuna",
    "pen.penDown": "jaitsi arkatza ",
    "pen.penUp": "igo arkatza",
    "pen.setColor": "ezarri arkatzaren kolorea: [COLOR]",
    "pen.setColorParam": "ezarri arkatzaren [COLOR_PARAM]: [VALUE]",
    "pen.setHue": "ezarri arkatzaren kolorea: [HUE]",
    "pen.setShade": "ezarri arkatzaren itzala: [SHADE]",
    "pen.setSize": "ezarri arkatzaren tamaina: [SIZE]",
    "pen.stamp": "zigilatu",
    "music.changeTempo": "aldatu tempoa [TEMPO] unitate",
    "music.drumBass": "(2) Dunbala",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Kabasa",
    "music.drumClaves": "(9) Klabeak",
    "music.drumClosedHiHat": "(6) Charleston txindata itxiak",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Zintzarria",
    "music.drumCrashCymbal": "(4) Txindata",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Txaloa",
    "music.drumOpenHiHat": "(5) Charleston txindata irekiak",
    "music.drumSideStick": "(3) Albo kolpea",
    "music.drumSnare": "(1) Kaxa danborra",
    "music.drumTambourine": "(7) Panderoa",
    "music.drumTriangle": "(12) Triangelua",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Egur blokea",
    "music.getTempo": "tempoa",
    "music.instrumentBass": "(6) Baxua",
    "music.instrumentBassoon": "(14) Fagota",
    "music.instrumentCello": "(8) Txeloa",
    "music.instrumentChoir": "(15) Korua",
    "music.instrumentClarinet": "(10) Klarinetea",
    "music.instrumentElectricGuitar": "(5) Gitarra elektrikoa",
    "music.instrumentElectricPiano": "(2) Piano elektrikoa",
    "music.instrumentFlute": "(12) Txirula",
    "music.instrumentGuitar": "(4) Gitarra",
    "music.instrumentMarimba": "(19) Marinba",
    "music.instrumentMusicBox": "(17) Musika kaxa",
    "music.instrumentOrgan": "(3) Organoa",
    "music.instrumentPiano": "(1) Pianoa",
    "music.instrumentPizzicato": "(7) Pizzikatoa",
    "music.instrumentSaxophone": "(11) Saxofoia",
    "music.instrumentSteelDrum": "(18) Altzairuzko danborra",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Tronboia",
    "music.instrumentVibraphone": "(16) Bibrafonoa",
    "music.instrumentWoodenFlute": "(13) Zurezko txirula",
    "music.midiPlayDrumForBeats": "play drum [DRUM] for [BEATS] beats",
    "music.midiSetInstrument": "ezarri tresna: [INSTRUMENT]",
    "music.playDrumForBeats": "jo  [DRUM]danborrean [BEATS] pultsuz",
    "music.playNoteForBeats": "jo [NOTE] nota [BEATS] pultsuz",
    "music.restForBeats": "isilunea [BEATS] pultsuz",
    "music.setInstrument": "ezarri [INSTRUMENT] tresna",
    "music.setTempo": "ezarri [TEMPO] tempoa",
    "videoSensing.categoryName": "Bideo-sentsorea",
    "videoSensing.direction": "norabidea",
    "videoSensing.motion": "mugimendua",
    "videoSensing.off": "itzali",
    "videoSensing.on": "piztu",
    "videoSensing.onFlipped": "piztu alderantziz",
    "videoSensing.setVideoTransparency": "ezarri bideo gardentasuna: [TRANSPARENCY]",
    "videoSensing.sprite": "pertsonaia",
    "videoSensing.stage": "eszena",
    "videoSensing.videoOn": "bideo- [ATTRIBUTE] hemen: [SUBJECT]",
    "videoSensing.videoToggle": " [VIDEO_STATE] bideoa",
    "videoSensing.whenMotionGreaterThan": "bideo-mugimendua > [REFERENCE] denean",
    "translate.translateBlock": "itzuli [WORDS]  [LANGUAGE]-ra",
    "translate.defaultTextToTranslate": "kaixo",
    "translate.viewerLanguage": "hizkuntza",
    "text2speech.speakAndWaitBlock": "esan [WORDS]",
    "text2speech.setVoiceBlock": "ezarri ahotsa: [VOICE]",
    "text2speech.setLanguageBlock": "ezarri hizkuntza: [LANGUAGE]",
    "text2speech.alto": "altua",
    "text2speech.tenor": "tenorea",
    "text2speech.squeak": "kirrinka",
    "text2speech.giant": "erraldoia",
    "text2speech.kitten": "katutxoa",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": " [PHRASE] entzutean",
    "speech.listenAndWait": "entzun eta itxaron",
    "speech.defaultWhenIHearValue": "goazen",
    "paint.paintEditor.hue": "Kolorea",
    "paint.paintEditor.saturation": "Asetasuna",
    "paint.paintEditor.brightness": "Distira",
    "gui.comingSoon.message1": "Lasai, horretan gabiltza {emoji}",
    "gui.comingSoon.message2": "Laster ...",
    "gui.comingSoon.message3": "Horretan ari gara {emoji}",
    "paint.paintEditor.fill": "Bete",
    "paint.paintEditor.costume": "Tankera",
    "paint.paintEditor.group": "Taldekatu",
    "paint.paintEditor.ungroup": "Desegin taldea",
    "paint.paintEditor.undo": "Desegin",
    "paint.paintEditor.redo": "Berregin",
    "paint.paintEditor.forward": "Aurrealderantz",
    "paint.paintEditor.backward": "Atzealderantz",
    "paint.paintEditor.front": "Aurrealdera",
    "paint.paintEditor.back": "Atzealdera",
    "paint.paintEditor.more": "Gehiago",
    "paint.modeTools.brushSize": "Tamaina",
    "paint.modeTools.eraserSize": "Ezabatzailearen tamaina",
    "paint.modeTools.copy": "Kopiatu",
    "paint.modeTools.paste": "Itsatsi",
    "paint.modeTools.delete": "Ezabatu",
    "paint.modeTools.curved": "Kurbatua",
    "paint.modeTools.pointed": "Zorrotza",
    "paint.modeTools.thickness": "Lodiera",
    "paint.modeTools.flipHorizontal": "Islatu horizontalki",
    "paint.modeTools.flipVertical": "Islatu bertikalki",
    "paint.modeTools.filled": "Betea",
    "paint.modeTools.outlined": "Eskematizatua",
    "paint.paintEditor.bitmap": "Bihurtu Bitmap",
    "paint.paintEditor.vector": "Bihurtu bektore",
    "paint.paintEditor.stroke": "Eskema",
    "paint.brushMode.brush": "Pintzela",
    "paint.eraserMode.eraser": "Ezabatzailea",
    "paint.fillMode.fill": "Bete",
    "paint.lineMode.line": "Marra",
    "paint.ovalMode.oval": "Zirkuloa",
    "paint.rectMode.rect": "Laukizuzena",
    "paint.reshapeMode.reshape": "Aldatu forma",
    "paint.roundedRectMode.roundedRect": "Laukizuzen biribildua",
    "paint.selectMode.select": "Hautatu",
    "paint.textMode.text": "Testua",
    "paint.colorPicker.swap": "Trukatu",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "fa": {
    "gui.backpack.header": "کوله پشتی",
    "gui.backpack.errorBackpack": "خطا در بارگیری کوله پشتی",
    "gui.backpack.loadingBackpack": "بارگیری ...",
    "gui.backpack.more": "بیشتر",
    "gui.backpack.emptyBackpack": "کوله پشتی خالی است",
    "gui.gui.costumesTab": "لباس‌ها",
    "gui.gui.soundsTab": "صداها",
    "gui.gui.backdropsTab": "پس زمینه",
    "gui.gui.addExtension": "اضافه کردن افزونه",
    "gui.costumeTab.addCostumeFromLibrary": "یک لباس را انتخاب کنید",
    "gui.costumeLibrary.chooseACostume": "یک لباس را انتخاب کنید",
    "gui.costumeTab.addBackdropFromLibrary": "یک پس زمینه را انتخاب کنید",
    "gui.costumeTab.addBlankCostume": "نقاشی",
    "gui.costumeTab.addSurpriseCostume": "غافلگیر",
    "gui.costumeTab.addFileBackdrop": "بارگذاری پس زمینه",
    "gui.costumeTab.addFileCostume": "بارگذاری لباس",
    "gui.costumeTab.addCameraCostume": "دوربین",
    "gui.soundEditor.trim": "اصلاح کردن",
    "gui.soundEditor.stop": "توقف",
    "gui.soundEditor.sound": "صدا",
    "gui.soundEditor.play": "پخش",
    "gui.soundEditor.save": "ذخیره",
    "gui.soundEditor.undo": "برگرد",
    "gui.soundEditor.redo": "دوباره انجام بده",
    "gui.soundEditor.faster": "سریع تر",
    "gui.soundEditor.slower": "آرام‌ تر",
    "gui.soundEditor.echo": "پژواک",
    "gui.soundEditor.robot": "ربات",
    "gui.soundEditor.louder": "بلند تر",
    "gui.soundEditor.softer": "نرم تر",
    "gui.soundEditor.reverse": "معکوس",
    "gui.soundTab.recordSound": "ضبط",
    "gui.soundTab.addSoundFromLibrary": "یک صدا را انتخاب کنید",
    "gui.soundTab.surpriseSound": "غافلگیر",
    "gui.soundTab.fileUploadSound": "بارگذاری صدا",
    "gui.controls.stop": "توقف",
    "gui.controls.go": "برو",
    "gui.stageHeader.stageSizeUnFull": "خروج از حالت تمام صفحه",
    "gui.SpriteInfo.show": "نمایش",
    "gui.SpriteInfo.size": "اندازه",
    "gui.directionPicker.rotationStyles.allAround": "اطراف ",
    "gui.directionPicker.rotationStyles.leftRight": "چپ/راست",
    "gui.directionPicker.rotationStyles.dontRotate": "نچرخانید",
    "gui.spriteSelectorItem.contextMenuDuplicate": "تکثیر",
    "gui.spriteSelectorItem.contextMenuDelete": "حذف",
    "gui.spriteSelectorItem.contextMenuExport": "خروجی گرفتن",
    "gui.spriteSelector.addSpriteFromPaint": "نقاشی",
    "gui.spriteSelector.addSpriteFromSurprise": "غافلگیر",
    "gui.spriteSelector.addSpriteFromFile": "بارگذاری شکلک",
    "gui.stageSelector.stage": "صحنه",
    "gui.stageSelector.backdrops": "پس زمینه ها",
    "gui.stageSelector.addBackdropFromPaint": "نقاشی",
    "gui.stageSelector.addBackdropFromSurprise": "غافلگیر",
    "gui.stageSelector.addBackdropFromFile": "بارگذاری پس زمینه",
    "gui.modal.back": "بازگشت",
    "gui.library.filterPlaceholder": "جست و جو",
    "gui.libraryTags.all": "همه",
    "gui.libraryTags.animals": "حیوانات",
    "gui.libraryTags.dance": "رقص",
    "gui.libraryTags.effects": "جلوه ها",
    "gui.libraryTags.fantasy": "فانتزی",
    "gui.libraryTags.fashion": "سبک",
    "gui.libraryTags.food": "غذا",
    "gui.libraryTags.indoors": "داخل خانه",
    "gui.libraryTags.loops": "حلقه ها",
    "gui.libraryTags.music": "موسیقی",
    "gui.libraryTags.notes": "یادداشت ها",
    "gui.libraryTags.outdoors": "خارج از خانه",
    "gui.libraryTags.patterns": "الگو ها",
    "gui.libraryTags.people": "مردم",
    "gui.libraryTags.percussion": "موسیقی ضربی",
    "gui.libraryTags.space": "فضا",
    "gui.libraryTags.sports": "ورزش ها",
    "gui.libraryTags.underwater": "زیر آب",
    "gui.libraryTags.voice": "صدا",
    "gui.libraryTags.wacky": "گیج",
    "gui.libraryTags.animation": "پویا نمایی",
    "gui.libraryTags.art": "هنر",
    "gui.libraryTags.games": "بازی ها",
    "gui.libraryTags.stories": "داستان ها",
    "gui.libraryTags.letters": "حروف",
    "gui.soundLibrary.chooseASound": "یک صدا انتخاب کنید",
    "gui.SpriteInfo.spritePlaceholder": "نام",
    "gui.cards.more-things-to-try": "چیز های بیشتری را امتحان کنید!",
    "gui.cards.see-more": "بیشتر ببینید",
    "gui.cards.shrink": "جمع کردن",
    "gui.cards.expand": "باز کردن",
    "gui.cards.close": "بستن",
    "gui.loader.message1": "در حال ایجاد قطعه‌ ها...",
    "gui.loader.message2": "بارگیری شکلک‌ ها...",
    "gui.loader.message3": "بارگیری صدا ها...",
    "gui.loader.message4": "بارگیری افزونه‌ ها...",
    "gui.loader.message5": "جمع کردن گربه ها...",
    "gui.loader.message6": "انتقال نانو ها...",
    "gui.loader.message7": "باد کردن گوبو ها...",
    "gui.loader.message8": "آماده کردن شکلک های کوچک...",
    "gui.loader.headline": "در حال بارگیری پروژه",
    "gui.cameraModal.cameraModalTitle": "گرفتن یک عکس",
    "gui.cameraModal.loadingCameraMessage": "بارگیری دوربین ...",
    "gui.cameraModal.permissionRequest": "برای استفاده از دوربین شما به اجازه ی شما نیاز داریم",
    "gui.cameraModal.retakePhoto": "دوباره عکس گرفتن",
    "gui.cameraModal.save": "ذخیره کردن",
    "gui.cameraModal.takePhoto": "عکس گرفتن",
    "gui.cameraModal.loadingCaption": "بارگیری ...",
    "gui.cameraModal.enableCameraCaption": "فعال کردن دوربین شما",
    "gui.recordModal.title": "ضبط صدا",
    "gui.playbackStep.stopMsg": "توقف",
    "gui.playbackStep.playMsg": "پخش",
    "gui.playbackStep.loadingMsg": "بارگیری ...",
    "gui.playbackStep.saveMsg": "ذخیره",
    "gui.playbackStep.reRecordMsg": "ضبط مجدد",
    "gui.webglModal.label": "مرورگر شما قابلیت WebGL را پشتیبانی نمی کند.",
    "gui.webglModal.webgllink": "قابلیت WebGL پشتیبانی نمی شود",
    "gui.prompt.cancel": "لغو",
    "gui.prompt.ok": "تایید",
    "gui.customProcedures.addAnInputNumberText": "افزودن یک ورودی",
    "gui.customProcedures.addAnInputBoolean": "افزودن یک ورودی",
    "gui.customProcedures.numberTextType": "عدد یا متن",
    "gui.customProcedures.booleanType": "منطقی",
    "gui.customProcedures.addALabel": "افزودن یک برچسب",
    "gui.customProcedures.runWithoutScreenRefresh": "اجرا بدون تازه سازی صفحه",
    "gui.customProcedures.cancel": "لغو",
    "gui.customProcedures.ok": "باشه",
    "gui.extension.microbit.description": "پروژه ی خود را به جهان متصل کنید.",
    "gui.extension.music.name": "موسیقی",
    "gui.extension.music.description": "ساز ها و طبل ها را بنوازید.",
    "gui.extension.pen.name": "قلم",
    "gui.extension.pen.description": "با شکلک های خودتان نقاشی بکشید. ",
    "gui.gui.variableScopeOptionAllSprites": "برای همه ی شکلک ها",
    "gui.gui.variableScopeOptionSpriteOnly": "فقط برای این شکلک",
    "gui.gui.variablePromptAllSpritesMessage": "این متغیر در دسترس همه ی شکلک ها خواهد بود.",
    "gui.monitor.contextMenu.default": "بازخوانی معمولی",
    "gui.monitor.contextMenu.large": "بازخوانی بزرگ",
    "gui.monitor.contextMenu.slider": "لغزنده",
    "gui.monitor.contextMenu.import": "وارد کردن",
    "gui.monitor.contextMenu.export": "خروجی گرفتن",
    "gui.opcodeLabels.direction": "جهت",
    "gui.opcodeLabels.xposition": "موقعیت x",
    "gui.opcodeLabels.yposition": "موقعیت y",
    "gui.opcodeLabels.size": "اندازه",
    "gui.opcodeLabels.costumename": "نام لباس",
    "gui.opcodeLabels.costumenumber": "شماره ی لباس",
    "gui.opcodeLabels.backdropname": "نام پس زمینه",
    "gui.opcodeLabels.backdropnumber": "شماره ی پس زمینه",
    "gui.opcodeLabels.volume": "حجم",
    "gui.opcodeLabels.tempo": "سرعت نواختن",
    "gui.opcodeLabels.answer": "پاسخ",
    "gui.opcodeLabels.loudness": "بلندی صدا",
    "gui.opcodeLabels.year": "سال",
    "gui.opcodeLabels.month": "ماه",
    "gui.opcodeLabels.date": "تاریخ",
    "gui.opcodeLabels.dayofweek": "روز هفته",
    "gui.opcodeLabels.hour": "ساعت",
    "gui.opcodeLabels.minute": "دقیقه",
    "gui.opcodeLabels.second": "ثانیه",
    "gui.opcodeLabels.timer": "زمان سنج",
    "music.categoryName": "موسیقی",
    "translate.categoryName": "ترجمه کردن",
    "pen.categoryName": "قلم",
    "pen.changeColorParam": "تغییر [COLOR_PARAM] قلم به میزان [VALUE]",
    "pen.changeHue": "تغییر رنگ قلم به میزان [HUE]",
    "pen.changeShade": "تغییر سایه ی قلم به میزان [SHADE]",
    "pen.changeSize": "تغییر اندازه ی قلم به میزان [SIZE]",
    "pen.clear": "همه را پاک کن",
    "pen.colorMenu.brightness": "روشنایی",
    "pen.colorMenu.color": "رنگ",
    "pen.colorMenu.saturation": "خلوص رنگ ",
    "pen.colorMenu.transparency": "شفافیت",
    "pen.penDown": "قلم پایین بیاید",
    "pen.penUp": "قلم بالا برود",
    "pen.setColor": "تنظیم رنگ قلم به [COLOR]",
    "pen.setColorParam": "تنظیم [COLOR_PARAM] قلم به [VALUE]",
    "pen.setHue": "تنظیم رنگ قلم به [HUE]",
    "pen.setShade": "تنظیم سایه ی قلم به [SHADE]",
    "pen.setSize": "تنظیم اندازه ی قلم به [SIZE]",
    "pen.stamp": "مهر",
    "music.changeTempo": "تغییر ضرب موسیقی توسط [TEMPO]",
    "music.drumBass": "(2) طبل بزرگ بم",
    "music.drumBongo": "(13) طبل بانگو",
    "music.drumCabasa": "(15) جغجغه ی کاباسا",
    "music.drumClaves": "(9) چوب های کلیوز",
    "music.drumClosedHiHat": "(6) ضرب دوسنج موازی نزدیک به هم",
    "music.drumConga": "(14) تیمپوی مدل کنگا",
    "music.drumCowbell": "(11) زنگ مدل کبل",
    "music.drumCrashCymbal": "(4) سنج تک پایه دار",
    "music.drumCuica": "(18) طبل مدل کویکا",
    "music.drumGuiro": "(16) جغجغه ی چوبی گویرو",
    "music.drumHandClap": "(8) دست زدن",
    "music.drumOpenHiHat": "(5) سنج دوگانه ی موازی ضربه ای با چوب",
    "music.drumSideStick": "(3) ضربه ی کنار چوب ",
    "music.drumSnare": "(1) تبل کوچک",
    "music.drumTambourine": "(7) دایره زنگی",
    "music.drumTriangle": "(12) زنگ مثلثی",
    "music.drumVibraslap": "(17) ضربه ی ارتعاشی",
    "music.drumWoodBlock": "(10) قطعه ی چوب ",
    "music.getTempo": "سرعت نواختن",
    "music.instrumentBass": "(6) گیتار بیس",
    "music.instrumentBassoon": "(14) ساز بادی فاگوت",
    "music.instrumentCello": "(8) ویولن سل",
    "music.instrumentChoir": "(15) گروه کر",
    "music.instrumentClarinet": "(10) ساز بادی کلارینت",
    "music.instrumentElectricGuitar": "(5) گیتار برقی",
    "music.instrumentElectricPiano": "(2) پیانو برقی",
    "music.instrumentFlute": "(12) فلوت",
    "music.instrumentGuitar": "(4) گیتار",
    "music.instrumentMarimba": "(19) سنتور چوبی آفریقایی مدل ماریمبا",
    "music.instrumentMusicBox": "(17) ساز کوکی جعبه ی موسیقی",
    "music.instrumentOrgan": "(3) ارگ",
    "music.instrumentPiano": "(1) پیانو",
    "music.instrumentPizzicato": "(7) ساز زهی و دستی پیتزیکاتو",
    "music.instrumentSaxophone": "(11) ساکسیفون",
    "music.instrumentSteelDrum": "(18) طبل فولادی",
    "music.instrumentSynthLead": "(20) صدای زیر ساز برقی ",
    "music.instrumentSynthPad": "(21) صدای پایدار ساز برقی",
    "music.instrumentTrombone": "(9) شیپور ترومبون",
    "music.instrumentVibraphone": "(16) ساز کوبه ای ویبرافون",
    "music.instrumentWoodenFlute": "(13) فلوت چوبی",
    "music.midiPlayDrumForBeats": "به مدت [BEATS] ضرب صدای [DRUM] را در ساز درامز پخش کن",
    "music.midiSetInstrument": "تنظیم وسیله به [INSTRUMENT]",
    "music.playDrumForBeats": "به مدت [BEATS] ضرب صدای [DRUM] را در ساز درامز پخش کن",
    "music.playNoteForBeats": "پخش نت [NOTE] به مدت [BEATS] ضرب",
    "music.restForBeats": "برای مدت [BEATS] ضرب استراحت کن",
    "music.setInstrument": "تنظیم وسیله به [INSTRUMENT]",
    "music.setTempo": "تنظیم سرعت نواختن به [TEMPO]",
    "videoSensing.categoryName": "دریافتن از طریق ویدیو",
    "videoSensing.direction": "جهت",
    "videoSensing.motion": "حرکت",
    "videoSensing.off": "خاموش",
    "videoSensing.on": "روشن",
    "videoSensing.onFlipped": "در حال تبدیل سریع ",
    "videoSensing.setVideoTransparency": "تنظیم میزان شفافیت ویدئو به [TRANSPARENCY]",
    "videoSensing.sprite": "شکلک",
    "videoSensing.stage": "صحنه",
    "videoSensing.videoOn": "ویدئو [ATTRIBUTE] بر روی [SUBJECT]",
    "videoSensing.videoToggle": "تبدیل ویدئو [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "وقتی که حرکت ویدئو > [REFERENCE]",
    "translate.translateBlock": "ترجمه کردن [WORDS] به [LANGUAGE]",
    "translate.defaultTextToTranslate": "سلام",
    "translate.viewerLanguage": "زبان",
    "text2speech.speakAndWaitBlock": "گفتن [WORDS]",
    "text2speech.setVoiceBlock": "تنظیم صدا به [VOICE]",
    "text2speech.setLanguageBlock": "تنظیم زبان به [LANGUAGE]",
    "text2speech.alto": "صدای اوج",
    "text2speech.tenor": "صدای زیر مردانه",
    "text2speech.squeak": "جیغ زدن",
    "text2speech.giant": "غول",
    "text2speech.kitten": "بچه گربه",
    "text2speech.defaultTextToSpeak": "سلام",
    "speech.whenIHear": "وقتی که من [PHRASE] را شنیدم",
    "speech.listenAndWait": "گوش کن و صبر کن",
    "speech.defaultWhenIHearValue": "بیا بریم",
    "paint.paintEditor.hue": "رنگ",
    "paint.paintEditor.saturation": "خلوص رنگ",
    "paint.paintEditor.brightness": "روشنایی",
    "gui.comingSoon.message1": "نگران نباشید، ما داریم روی آن کار می کنیم{emoji}",
    "gui.comingSoon.message2": "به زودی...",
    "gui.comingSoon.message3": "ما در حال کار بر روی آن هستیم {emoji}",
    "paint.paintEditor.fill": "پر کردن",
    "paint.paintEditor.costume": "حالت",
    "paint.paintEditor.group": "گروه",
    "paint.paintEditor.ungroup": "انحلال گروه",
    "paint.paintEditor.undo": "باطل کن",
    "paint.paintEditor.redo": "دوباره انجام بده",
    "paint.paintEditor.forward": "رو به جلو",
    "paint.paintEditor.backward": "رو به عقب",
    "paint.paintEditor.front": "جلو",
    "paint.paintEditor.back": "عقب",
    "paint.paintEditor.more": "بیشتر",
    "paint.modeTools.brushSize": "اندازه",
    "paint.modeTools.eraserSize": "اندازه ی پاک کن",
    "paint.modeTools.copy": "رونوشت",
    "paint.modeTools.paste": "چسباندن",
    "paint.modeTools.delete": "حذف",
    "paint.modeTools.curved": "منحنی",
    "paint.modeTools.pointed": "نوک دار",
    "paint.modeTools.thickness": "ضخامت",
    "paint.modeTools.flipHorizontal": "وارونه ی افقی",
    "paint.modeTools.flipVertical": "وارونه ی عمودی",
    "paint.modeTools.filled": "پر شده",
    "paint.modeTools.outlined": "مشخص شده",
    "paint.paintEditor.bitmap": "تبدیل به حالت بیت‌مپ",
    "paint.paintEditor.vector": "تبدیل به حالت برداری",
    "paint.paintEditor.stroke": "طرح کلی",
    "paint.brushMode.brush": "قلم مو",
    "paint.eraserMode.eraser": "پاک کن",
    "paint.fillMode.fill": "پر کردن",
    "paint.lineMode.line": "خط",
    "paint.ovalMode.oval": "دایره",
    "paint.rectMode.rect": "مستطیل",
    "paint.reshapeMode.reshape": "تغییر شکل",
    "paint.roundedRectMode.roundedRect": "مستطیل دور گرد",
    "paint.selectMode.select": "انتخاب",
    "paint.textMode.text": "متن",
    "paint.colorPicker.swap": "تعویض ",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "fr": {
    "gui.gui.project": "Projet",
    "gui.gui.newItem": "Nouveau projet",
    "gui.gui.load": "Charger un projet",
    "gui.gui.save": "Sauvegarder le projet",
    "gui.gui.saveAs": "Enregistrer sous",
    "gui.gui.learn": "Apprendre",
    "gui.gui.clearRecord": "Clear Record",
    "gui.gui.onlineLearn": "Documents officiels",
    "gui.gui.onlineForum": "Forum en ligne",
    "gui.gui.videoTutorial": "Tutoriels vidéos",
    "gui.gui.offlineLearn": "Exemples de programmes",
    "gui.menuBar.turboModeOff": "Désactiver le mode Turbo",
    "gui.menuBar.turboModeOn": "Activer le mode Turbo",
    "gui.menuBar.edit": "Modifier",
    "gui.menuBar.restoreSprite": "Restaurer Sprite",
    "gui.menuBar.restoreSound": "Restaurer le son",
    "gui.menuBar.restoreCostume": "Restaurer Costume",
    "gui.editorMind.restore": "Restaurer",
    "gui.turboMode.active": "Mode Turbo",
    "gui.gui.connectDevice": "Connecter le périphérique",
    "gui.gui.disconnectDevice": "Déconnecter le périphérique",
    "gui.gui.installSerialportDriver": "Installer le pilote SerialPort",
    "gui.gui.openDM": "Ouvrir le gestionnaire de périphériques",
    "gui.gui.updateTips": "mise à jour des Conseils",
    "gui.gui.newVersion": "la dernière version",
    "gui.gui.downloadUpdate": "Télécharger",
    "gui.gui.versionUpdate": "Version de la Mise à jour",
    "gui.gui.downloading": "Checking Updator",
    "gui.setting.feedbackMenu": "Retour d'information",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.setting.officialWebsit": "Site officiel",
    "gui.setting.officialQQGroup": "Groupe QQ officiel",
    "gui.setting.wechatOfficialAccounts": "Comptes officiels de WeChat",
    "gui.setting.currentVersion": "Version actuelle",
    "gui.setting.checkForUpdate": "Vérifier les mises à jour",
    "gui.setting.remindWhenUpdate": "télécharger lors de la mise à jour",
    "gui.setting.currentIsLatest": "La version actuelle est la dernière",
    "gui.setting.latestVersion": "Dernière version",
    "gui.setting.download": "Télécharger",
    "gui.setting.language": "La langue",
    "gui.setting.uploadCsv": "télécharger csv",
    "gui.setting.theme": "Thème",
    "gui.setting.feedback": "Retour d'information",
    "gui.setting.email": "E-mail",
    "gui.setting.opinionFeedback": "Opinion/Commentaires",
    "gui.variableMc.variableName": "nom de la variable",
    "gui.variableMc.variableOff": "annuler",
    "gui.variableMc.variableOn": "confirmer",
    "gui.variableMc.newVariable": "nouvelle variable",
    "gui.setting.helpUsTranslate": "Help Us Translate",
    "gui.setting.checkUpdate": "Check Update",
    "gui.setting.ok": "Ok ",
    "gui.setting.languageSetting": "Language",
    "gui.setting.themeSetting": "Thème",
    "gui.setting.versionUpdate": "Version Update",
    "gui.setting.connectUsTitle": "Contact us",
    "gui.setting.uploadAttachment": "Upload Attachment",
    "gui.menuBar.restoreBackdrop": "Restaurer Fond",
    "gui.gui.restoreSetting": "Restaurer les paramètres initiaux du périphérique",
    "gui.gui.isCheckMd5": "Vérification du fichier",
    "gui.gui.updateError": "Erreur de mise à jour",
    "gui.setting.displayTitle": "Choix du texte",
    "gui.setting.fontSizeSetting": "Taille du texte",
    "gui.setting.restartEffect": "redémarrer pour appliquer",
    "gui.setting.fontSizeLarge": "Grand",
    "gui.setting.fontSizeMiddle": "Moyen",
    "gui.setting.fontSizeSmall": "Petit",
    "gui.backpack.header": "Sac à dos",
    "gui.backpack.errorBackpack": "Erreur lors du chargement du sac-à-dos",
    "gui.backpack.loadingBackpack": "Chargement...",
    "gui.backpack.more": "Plus",
    "gui.backpack.emptyBackpack": "Le sac-à-dos est vide",
    "gui.gui.blocks": "Blocs",
    "gui.gui.costumesTab": "Costumes",
    "gui.gui.soundsTab": "Des sons",
    "gui.gui.backdropsTab": "Fond",
    "gui.gui.addExtension": "Extensions",
    "gui.costumeTab.addCostumeFromLibrary": "Bibliothèque de costumes",
    "gui.costumeLibrary.chooseACostume": "Choisissez un costume",
    "gui.costumeTab.addBackdropFromLibrary": "Bibliothèque de fond",
    "gui.costumeTab.addBlankCostume": "Peindre",
    "gui.costumeTab.addSurpriseCostume": "Surprise",
    "gui.costumeTab.addFileBackdrop": "Télécharger le Fond",
    "gui.costumeTab.addFileCostume": "Télécharger",
    "gui.costumeTab.addCameraCostume": "Caméra",
    "gui.soundEditor.trim": "Réduire",
    "gui.soundEditor.stop": "Arrêtez",
    "gui.soundEditor.sound": "Son",
    "gui.soundEditor.play": "Jouer",
    "gui.soundEditor.save": "Sauvegarder",
    "gui.soundEditor.undo": "Annuler",
    "gui.soundEditor.redo": "Refaire",
    "gui.soundEditor.faster": "Plus rapide",
    "gui.soundEditor.slower": "Ralentir",
    "gui.soundEditor.echo": "Écho",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Plus fort",
    "gui.soundEditor.softer": "Plus doux",
    "gui.soundEditor.reverse": "Inverser",
    "gui.soundTab.recordSound": "Record",
    "gui.soundTab.addSoundFromLibrary": "Bibliothèque de sons",
    "gui.soundTab.surpriseSound": "Surprise",
    "gui.soundTab.fileUploadSound": "Télécharger son",
    "gui.soundTab.addSound": "Ajouter un son",
    "gui.controls.stop": "Arrêtez",
    "gui.controls.go": "Démarre",
    "gui.gui.startPython": "démarrer python",
    "gui.gui.stopPython": "arrêter python",
    "gui.controls.fullScreenControl": "Contrôle plein écran",
    "gui.gui.stageSizeLarge": "Bascule sur la Grande scène",
    "gui.gui.stageSizeSmall": "Bascule sur la petite scène",
    "gui.gui.stageSizeNostage": "Bascule sur aucune scène",
    "gui.gui.stageSizeFull": "Bascule sur la scène Plein écran",
    "gui.stageHeader.stageSizeUnFull": "Quitter le mode plein écran",
    "gui.sprite.sprite": "Lutin",
    "gui.SpriteInfo.show": "Montre",
    "gui.SpriteInfo.size": "Taille",
    "gui.sprite.direction": "Direction",
    "gui.sprite.rotation": "Rotation",
    "gui.directionPicker.rotationStyles.allAround": "Tout autour",
    "gui.directionPicker.rotationStyles.leftRight": "Gauche droite",
    "gui.directionPicker.rotationStyles.dontRotate": "Ne pas faire pivoter",
    "gui.spriteSelectorItem.contextMenuDuplicate": "dupliquer",
    "gui.spriteSelectorItem.contextMenuDelete": "effacer",
    "gui.spriteSelectorItem.contextMenuExport": "exportation",
    "gui.sprite.addSpriteFromLibrary": "Bibliothèque de Lutin",
    "gui.spriteSelector.addSpriteFromPaint": "Peindre",
    "gui.spriteSelector.addSpriteFromSurprise": "Surprise",
    "gui.spriteSelector.addSpriteFromFile": "Charger un Lutin",
    "gui.sprite.addSpriteFromCamera": "Caméra",
    "gui.stageSelector.stage": "Scène",
    "gui.stageSelector.backdrops": "Fond",
    "gui.stage.addBackdropFromLibrary": "Bibliothèque de fond",
    "gui.stageSelector.addBackdropFromPaint": "Peindre",
    "gui.stageSelector.addBackdropFromSurprise": "Surprise",
    "gui.stageSelector.addBackdropFromFile": "charger un fond",
    "gui.stage.addBackdropFromCamera": "Caméra",
    "gui.modal.back": "Retour",
    "gui.extension.kit": "Kit",
    "gui.extension.kitTitle": "Choisir le kit",
    "gui.extension.board": "Carte",
    "gui.extension.boardTitle": "Choisir la carte",
    "gui.extension.spreadBoard": "Shield",
    "gui.extension.spreadBoardTitle": "Choisir un Shield",
    "gui.extension.sensor": "Capteur",
    "gui.extension.sensorTitle": "Choisir le capteur",
    "gui.extension.actuator": "Actionneur",
    "gui.extension.actuatorTitle": "Choisir l'Actionneur",
    "gui.extension.communicationModule": "Communication",
    "gui.extension.communicationModuleTitle": "Sélectionner la carte de communication",
    "gui.extension.display": "Afficheur",
    "gui.extension.displayTitle": "Sélectionner l'afficheur",
    "gui.extension.function": "Fonction",
    "gui.extension.functionTitle": "Sélectionner une Fonction",
    "gui.extension.internetService": "Internet",
    "gui.extension.internetServiceTitle": "Sélectionner une fonction Internet",
    "gui.extension.arduinContentLabel": "Bibliothèque d'extension Arduino",
    "gui.library.filterPlaceholder": "Chercher",
    "gui.libraryTags.all": "Tout",
    "gui.libraryTags.animals": "Animaux",
    "gui.libraryTags.dance": "Danse",
    "gui.libraryTags.effects": "Effets",
    "gui.libraryTags.fantasy": "Fantastique",
    "gui.libraryTags.fashion": "Mode",
    "gui.libraryTags.food": "Aliments",
    "gui.libraryTags.indoors": "À l'intérieur",
    "gui.libraryTags.loops": "Boucles",
    "gui.libraryTags.music": "Musique",
    "gui.libraryTags.notes": "Remarques",
    "gui.libraryTags.outdoors": "En plein air",
    "gui.libraryTags.patterns": "Patrons",
    "gui.libraryTags.people": "Personnes",
    "gui.libraryTags.percussion": "Percussion",
    "gui.libraryTags.space": "Cosmos",
    "gui.libraryTags.sports": "Sports",
    "gui.libraryTags.underwater": "Sous la mer",
    "gui.libraryTags.voice": "Voix",
    "gui.libraryTags.wacky": "Farfelu",
    "gui.libraryTags.animation": "Animation",
    "gui.libraryTags.art": "Art",
    "gui.libraryTags.games": "Jeux",
    "gui.libraryTags.stories": "Histoires",
    "gui.libraryTags.letters": "Des lettres",
    "gui.extension.backdropLib": "Bibliothèque de fond",
    "gui.soundLibrary.chooseASound": "Choisissez un son",
    "gui.SpriteInfo.spritePlaceholder": "Nom",
    "gui.extension.spriteLib": "Bibliothèque de Lutins",
    "gui.gui.unselectedSerialport": "Aucun Appareil connecté",
    "gui.gui.unConnectedDev": "aucun appareil connecté",
    "gui.gui.pythonMode": "Mode Python",
    "gui.gui.burnFirmware": "Flasher le firmware",
    "gui.gui.burnFirmwareError": "Erreur de flashage du firmware",
    "gui.gui.connected": "Connecté",
    "gui.gui.failedConnected": "échec de connexion",
    "gui.gui.connecting": "Connexion en cours...",
    "gui.cards.all-how-tos": "Tous les Modes d'emploi",
    "gui.cards.how-tos": "Mode d'emploi",
    "gui.cards.remove": "Fermer",
    "gui.cards.more-things-to-try": "Plus de choses à essayer!",
    "gui.cards.see-more": "Voir plus",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Fermer",
    "gui.loader.message1": "Création des blocs…",
    "gui.loader.message2": "Chargement des Lutins…",
    "gui.loader.message3": "Chargement des sons…",
    "gui.loader.message4": "Chargement des extensions…",
    "gui.loader.message5": "Garder les Mind+",
    "gui.loader.message6": "Transmettre des nanos…",
    "gui.loader.message7": "Gonfler des Gobos…",
    "gui.loader.message8": "Préparer des emojis…",
    "gui.loader.headline": "Chargement du projet",
    "gui.cameraModal.cameraModalTitle": "Prendre une photo",
    "gui.cameraModal.loadingCameraMessage": "Chargement de la caméra ...",
    "gui.cameraModal.permissionRequest": "Nous avons besoin de votre permission pour utiliser votre appareil photo",
    "gui.cameraModal.retakePhoto": "Reprendre la photo",
    "gui.cameraModal.save": "Sauvegarder",
    "gui.cameraModal.takePhoto": "Prendre une photo",
    "gui.cameraModal.loadingCaption": "Chargement...",
    "gui.cameraModal.enableCameraCaption": "Activer la caméra",
    "gui.recordModal.title": "Enregistrer le son",
    "gui.recordStep.recordByClickBtn": "Commencez l'enregistrement en cliquant sur le bouton ci-dessous",
    "gui.recordStep.permissionMicrophone": "Nous avons besoin de votre permission pour utiliser votre microphone",
    "gui.recordStep.record": "Record",
    "gui.recordStep.stopRecord": "Arrête d'enregistrer",
    "gui.playbackStep.stopMsg": "Arrêtez",
    "gui.playbackStep.playMsg": "Jouer",
    "gui.playbackStep.loadingMsg": "Chargement...",
    "gui.playbackStep.saveMsg": "Sauvegarder",
    "gui.playbackStep.reRecordMsg": "Ré-enregistrer",
    "gui.webglModal.label": "Votre navigateur ne supporte pas WebGL",
    "gui.webglModal.descriptionMind": "Malheureusement， cela ressemble à votre ordinateur {webGlLink}. Cette technologie est nécessaire à Mind+ pour exécuter {updateGpuDriver}",
    "gui.webglModal.webgllink": "ne supporte pas WebGL",
    "gui.webglModal.updateGpuDriver": "Essayez de mettre à jour le pilote de la carte graphique",
    "gui.webglModal.ok": "Ok",
    "gui.extension.scratchExampleLib": "Bibliothèque d'exemples Scratch",
    "gui.extension.pythonExampleLib": "Bibliothèque d'exemples Python",
    "gui.extension.arduinoExampleLib": "Bibliothèque d'exemples Arduino",
    "gui.prompt.cancel": "Annuler",
    "gui.prompt.ok": "Accepter",
    "gui.extension.makeBlock": "Faire un bloc",
    "gui.customProcedures.addAnInputNumberText": "Ajouter un paramètre",
    "gui.customProcedures.addAnInputBoolean": "Ajouter une entrée",
    "gui.customProcedures.numberTextType": "nombre ou texte",
    "gui.customProcedures.booleanType": "booléen",
    "gui.customProcedures.addALabel": "Ajouter une étiquette",
    "gui.customProcedures.runWithoutScreenRefresh": "Exécuter sans rafraîchissement d'écran",
    "gui.customProcedures.cancel": "Annuler",
    "gui.customProcedures.ok": "Accepter",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "Robot éducatif Vortex de DFRobot",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "Robot éducatif Explorer D1 de DFRobot",
    "gui.extension.arduinounoR3.name": "ArduinounoR3",
    "gui.extension.arduinounoR3.description": "Associé aux cours 'Wuhan Maker' avec un kit  basé sur l'arduino UNO.",
    "gui.extension.maxbot.name": "Max: bot",
    "gui.extension.maxbot.description": "Robot Max: bot basé sur micro:bit",
    "gui.extension.max.name": "MAX (ROB0137)",
    "gui.extension.max.description": "Robot MAX（ROB0137) based basé sur l'Arduino",
    "gui.extension.motorbit.name": "Moteur: bit",
    "gui.extension.motorbit.description": "Motor:bit， carte d'extension de servo et moteur",
    "gui.extension.maqueen.name": "Maqueen",
    "gui.extension.maqueen.description": "micro:Maqueen robot basé sur micro:bit",
    "gui.extension.microbit.name": "Microbit",
    "gui.extension.microbit.description": "Connectez vos projets avec le monde physique.",
    "gui.extension.spread.name": "micro:bit & mPython expansion board",
    "gui.extension.spreadMaqueen.name": "maqueen extension",
    "gui.extension.spread.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadmaqueen.description": "An entry-level minicar based on the control version",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "The advanced educational robot that supports both micro:bit and mPython",
    "gui.extension.iot.name": "Internet of Things",
    "gui.extension.iot.description": "Extended Edition Based on Internet of Things",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Carte Arduino LEONARDO basée sur un ATMega32u4",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "carte Arduino Uno",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Carte Arduino Nano",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "Carte de style micro:bit mais basée sur ESP32",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Carte Arduino Mega 2560",
    "gui.extension.dfr0299.name": "DFPlayer Module MP3",
    "gui.extension.dfr0299.description": "Module lecteur MP3",
    "gui.extension.ser0006.name": "Micro Servo",
    "gui.extension.ser0006.description": "Micro Servo",
    "gui.extension.dfr0523.name": "Pompe péristaltique",
    "gui.extension.dfr0523.description": "La pompe péristaltique peut délivrer du fluide en pressant et relâchant la pompe en alternance",
    "gui.extension.dfr0017.name": "Module relais",
    "gui.extension.dfr0017.description": "Contrôle des appareils à courantet tension élevée via un port d'E/S numérique",
    "gui.extension.steppermotor.name": "Moteur pas à pas",
    "gui.extension.steppermotor.description": "Réaliser un contrôle de position précis par contrôle d'impulsion",
    "gui.extension.dfr0534.name": "Module MP3 série",
    "gui.extension.dfr0534.description": "Module vocal MP3 série",
    "gui.extension.servo360.name": "Micro servo",
    "gui.extension.servo360.description": "Seul existe le contrôle de la vitesse et de la direction (pas d'arrêt angulaire)",
    "gui.extension.tel0118.name": "OBLOQ - Module IoT",
    "gui.extension.tel0118.description": "Bloc obloq serialport IoT pour uno",
    "gui.extension.dfr0095.name": "Module émetteur IR",
    "gui.extension.dfr0095.description": "Module émetteur IR (38 KHz)",
    "gui.extension.dfr0094.name": "Module récepteur IR",
    "gui.extension.dfr0094.description": "Module récepteur IR (38 KHz)",
    "gui.extension.tel0094.name": "Module de réception de signal GPS",
    "gui.extension.tel0094.description": "Module de réception de signal GPS",
    "gui.extension.tel0026.name": "Module Bluetooth",
    "gui.extension.tel0026.description": "Module de port série Bluetooth",
    "gui.extension.dfr0486.name": "Écran OLED-12864",
    "gui.extension.dfr0486.description": "Module d'affichage I2C OLED-12864",
    "gui.extension.fit0352.name": "Bande LED RGB WS2812",
    "gui.extension.fit0352.description": "Pour piloter les modules de bandes à LED basés sur WS2812",
    "gui.extension.dfr0063.name": "Écran LCD1602",
    "gui.extension.dfr0063.description": "Module d'affichage LCD1602 I2C",
    "gui.extension.dfr0021.name": "Module de lumière LED numérique",
    "gui.extension.dfr0021.description": "Modules de DEL blanches, rouges, vertes et bleues",
    "gui.extension.tm1650.name": "TM1650 Four digit tube",
    "gui.extension.tm1650.description": "Four digit tube",
    "gui.extension.matrix8_8.name": "Matrice MAX7219 8x8 points",
    "gui.extension.matrix8_8.description": "Module matrice de Leds 8x8",
    "gui.extension.dfr0522.name": "Carte emoji à matrice de points à DEL RVB 8x16",
    "gui.extension.dfr0522.description": "Carte emoji à matrice de points à DEL RVB 8x16",
    "gui.extension.music.name": "La musique",
    "gui.extension.music.description": "Jouer des instruments et des tambours.",
    "gui.extension.pen.name": "Stylo",
    "gui.extension.pen.description": "Dessine avec tes Lutins.",
    "gui.extension.video-sensing.name": "Détection vidéo",
    "gui.extension.video-sensing.description": "Détecter le mouvement avec la caméra.",
    "gui.extension.google-translate.name": "Google Traduction",
    "gui.extension.google-translate.description": "Traduire le texte dans plusieurs langues.",
    "gui.extension.text-speech.name": "Texte pour parler",
    "gui.extension.text-speech.description": "Laissez votre projet parler",
    "gui.extension.softSerialport.name": "Port série logiciel",
    "gui.extension.softSerialport.description": "Utiliser le port d'E/S pour simuler la fonction de communication série",
    "gui.extension.IICScan.name": "Scan I2C",
    "gui.extension.IICScan.description": "Pour analyser toutes les adresses de périphérique connectées à l'interface I2C",
    "gui.extension.bluetooth.name": "Bluetooth",
    "gui.extension.bluetooth.description": "Périphérique Bluetooth",
    "gui.extension.goble.name": "l'Apli. GoBle",
    "gui.extension.goble.description": "Télécommande universelle Bluetooth qui permet de contrôler des robots.",
    "gui.extension.interrupt.name": "Interruption",
    "gui.extension.interrupt.description": "Pin d'interruption",
    "gui.extension.multithread.name": "MultiThread",
    "gui.extension.multithread.description": "Permet l'exécution simultanée de plusieurs programmes",
    "gui.extension.speech.name": "Discours",
    "gui.extension.speech.description": "Parlez à vos projets. ",
    "gui.extension.sen0001.name": "Capteur à ultrasons",
    "gui.extension.sen0001.description": "Compatible avec les capteurs à ultrasons de la série URM et SR-04",
    "gui.extension.dfr0023.name": "Capteur de température analogique LM35",
    "gui.extension.dfr0023.description": "Capteur de température basé sur LM35",
    "gui.extension.dhtTHSensor.name": "Capteur de température et d'humidité DHT11/22",
    "gui.extension.dhtTHSensor.description": "Capteur de température et d'humidité DHT11/22",
    "gui.extension.dsTSensor.name": "Capteur de température DS18B20",
    "gui.extension.dsTSensor.description": "Capteur de température numérique DS18B20 pour la détection de la température ambiante",
    "gui.extension.sen0203.name": "Capteur de moniteur de fréquence cardiaque",
    "gui.extension.sen0203.description": "Mini capteur de fréquence cardiaque avec modes de sortie à impulsion analogique ou numérique",
    "gui.extension.sen0177.name": "Capteur de qualité de l'air laser PM2.5",
    "gui.extension.sen0177.description": "Mesure PM1，PM2.5 et PM10. Transfére des données via le port série",
    "gui.extension.sen0014.name": "Capteur de distance infrarouge GP2Y0A21",
    "gui.extension.sen0014.description": "Capteur IR basé sur GP2Y0A21 avec plage de mesure de 80 cm",
    "gui.extension.sen0204.name": "Capteur sans contact du niveau de liquide",
    "gui.extension.sen0204.description": "Détection sans contact du niveau du liquide",
    "gui.extension.sen0161.name": "PH mètre analogique",
    "gui.extension.sen0161.description": "Mesure le pH du liquide",
    "gui.extension.sen0161-v2.name": "PH mètre analogique (V2)",
    "gui.extension.sen0161-v2.description": "Mesure le pH du liquide",
    "gui.extension.sen0244.name": "Capteur TDS analogique",
    "gui.extension.sen0244.description": "Mesure le TDS: la concentration totale des substances dissoutes du liquide",
    "gui.extension.sen0165.name": "Compteur ORP analogique",
    "gui.extension.sen0165.description": "Mesure le potentiel d'oxydo-réduction (ORP) du liquide",
    "gui.extension.sen0237.name": "Capteur d'oxygène dissous analogique",
    "gui.extension.sen0237.description": "Mesure l'oxygène dissous du liquide",
    "gui.extension.dfr0300-H.name": "Compteur analogique",
    "gui.extension.dfr0300-H.description": "Mesure la conductivité électrique du liquide",
    "gui.extension.dfr0300.name": "Compteur analogique CE (V2)",
    "gui.extension.dfr0300.description": "Mesure la conductivité électrique du liquide",
    "gui.extension.sen0170.name": "Anémomètre",
    "gui.extension.sen0170.description": "Mesure le niveau de vitesse du vent",
    "gui.extension.sen0226.name": "Capteur de température BMP280",
    "gui.extension.sen0226.description": "Capteur de pression barométrique BMP280 pour les mesures de température et barométrique",
    "gui.extension.sen0228.name": "Capteur de lumière ambiante I2C VEML7700",
    "gui.extension.sen0228.description": "Capteur de lumière ambiante IEM VEML7700 (0~120Klx)",
    "gui.extension.dfr0022.name": "Capteur analogique en niveaux de gris",
    "gui.extension.dfr0022.description": "Détecter la densité lumineuse et refléter le signal de tension analogique sur le contrôleur Arduino",
    "gui.extension.dfr0552.name": "12-bit DA conversion module",
    "gui.extension.dfr0552.description": "Convert the digital quantity to the corresponding dc voltage signal accurately (non-pwm)",
    "gui.extension.dfr0576.name": "I2C cascade extender",
    "gui.extension.dfr0576.description": "Used to resolve I2C device address conflicts",
    "gui.extension.sen0248.name": "BME680 environmental sensor",
    "gui.extension.sen0248.description": "VOC (volatile organic compounds), temperature, humidity and air pressure can be measured",
    "gui.extension.sen0304.name": "I2C ultrasonic ranging sensor",
    "gui.extension.sen0304.description": "Ultrasonic sensors designed for rapid ranging or obstacle avoidance applications",
    "gui.extension.sen0307.name": "Analog ultrasonic ranging sensor",
    "gui.extension.sen0307.description": "Open dual probe analog ultrasonic ranging module",
    "gui.extension.sen0250.name": "BMI160 6 axis inertial motion sensor",
    "gui.extension.sen0250.description": "16-bit 3-axis acceleration + ultra-low power consumption 3-axis gyroscope",
    "gui.extension.sen0224.name": "I2C LIS2DH triaxial accelerometer",
    "gui.extension.sen0224.description": "Ultra-low power consumption 3 axis acceleration sensor",
    "gui.extension.dfr0151.name": "Horloge temps réel DS1307",
    "gui.extension.dfr0151.description": "Sortiepériphériques de année，  mois，  jour，  semaine， heure， minute， seconde",
    "gui.extension.dfr0469.name": "Horloge temps réel SD2405",
    "gui.extension.dfr0469.description": "Sortie， année， mois， jour， semaine， heure， minute， seconde",
    "gui.extension.dfr0126.name": "Module d'analyse de spectre",
    "gui.extension.dfr0126.description": "Garde une trace de la fréquence musicale et laissez votre contrôleur interagir avec la musique",
    "gui.extension.dfr0231.name": "Module NFC de port série",
    "gui.extension.dfr0231.description": "Module de communication en champ proche NFC",
    "gui.extension.sen0245.name": "Capteur de télémétrie laser VL53L0X",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "Capteur analogique de lumière ambiante",
    "gui.extension.dfr0026.description": "Détecte l'intensité de la lumière ambiante",
    "gui.extension.dfr0027.name": "Capteur numérique de vibration",
    "gui.extension.dfr0027.description": "Signal de vibration Dectect",
    "gui.extension.dfr0028.name": "Capteur numérique d'inclinaison ",
    "gui.extension.dfr0028.description": "Travaille comme commutateur d'inclinaison numérique",
    "gui.extension.dfr0029.name": "Bouton poussoir numérique",
    "gui.extension.dfr0029.description": "Appuyé:niveau HAUT Relaché:niveau BAS",
    "gui.extension.dfr0030.name": "Capteur tactile capacitif numérique",
    "gui.extension.dfr0030.description": "Module de commutation tactile capacitif pour la détection du métal et du corps humain",
    "gui.extension.dfr0033.name": "Capteur magnétique numérique",
    "gui.extension.dfr0033.description": "Détecter les matériaux magnétiques dans les 3cm",
    "gui.extension.dfr0034.name": "Capteur de son analogique",
    "gui.extension.dfr0034.description": "Détecter le volume en ambiant",
    "gui.extension.sen0132.name": "Capteur de monoxyde de carbone analogique",
    "gui.extension.sen0132.description": "Détecter les concentrations de gaz CO entre 20 et 2000 ppm",
    "gui.extension.dfr0051.name": "Diviseur de tension analogique",
    "gui.extension.dfr0051.description": "Détecter la tension (DC) de 0.0245V à 25V",
    "gui.extension.dfr0052.name": "Capteur de vibration à disque piézo analogique",
    "gui.extension.dfr0052.description": "Détecte le stress dynamique et quasi statique",
    "gui.extension.dfr0058.name": "Capteur de potentiomètre de rotation analogique",
    "gui.extension.dfr0058.description": "Potentiomètre haute précision avec un maximum de 10 tours",
    "gui.extension.dfr0061.name": "Module de manette",
    "gui.extension.dfr0061.description": "(X，  Y) sortie analogique (Z) 1 sortie numérique",
    "gui.extension.dfr0072.name": "Shiftout Sensor",
    "gui.extension.dfr0072.description": "description for the 'Shiftout sensor' extension",
    "gui.extension.dfr0563.name": "battery Sensor",
    "gui.extension.dfr0563.description": "description for the 'battery sensor' extension",
    "gui.extension.dfr0553.name": "16-bit AD conversion module",
    "gui.extension.dfr0553.description": "description for the '16-bit AD conversion module' extension",
    "gui.extension.dfr0076.name": "Capteur de flamme analogique",
    "gui.extension.dfr0076.description": "Détecter une flamme ou une lumière d'une longueur d'onde de 760nm à 1100nm",
    "gui.extension.dfr0143.name": "Accéléromètre à trois axes MMA7361",
    "gui.extension.dfr0143.description": "Basé sur la puce MMA7361. Détection du geste et de la direction du mouvement",
    "gui.extension.sen0018.name": "Capteur de mouvement infrarouge numérique",
    "gui.extension.sen0018.description": "Détecter un humain ou un animal en mouvement",
    "gui.extension.sen0114.name": "Capteur d'humidité du sol analogique",
    "gui.extension.sen0114.description": "Détecter l'humidité dans le sol",
    "gui.extension.sen0121.name": "Capteur de vapeur",
    "gui.extension.sen0121.description": "Détecte la vapeur et l'eau",
    "gui.extension.sen0212.name": "Capteur d'identification de couleur",
    "gui.extension.sen0212.description": "Capteur I2C de reconnaissance de couleur",
    "gui.extension.sen0253.name": "Capteur d'attitude 10DOF",
    "gui.extension.sen0253.description": "BNO055+BMP280: Capteurdirectionnel absolu de chez 10DOF  ",
    "gui.extension.sen0290.name": "lightning sensor",
    "gui.extension.sen0290.description": "intelligent lightning sensor",
    "gui.extension.sen0202.name": "Mini capteur 3D Gesture",
    "gui.extension.sen0202.description": "Détecter les sens de rotation et de déplacement dans le sens horaire/anti-horaire",
    "gui.extension.iobox.name": "micro: IO-BOX",
    "gui.extension.iobox.description": "Micro:bit carte d'extension pour moteur avec batterie au lithium",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "Permet à périphérique de se connecter au réseau Wi-Fi",
    "gui.extension.udp.name": "UDP broadcast",
    "gui.extension.udp.description": "Let devices and devices in the local area network realize multi-machine broadcast communication through UDP protocol",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "Permet aux périphériques de communiquer en utilisant le protocole MQTT",
    "gui.extension.speechRecognition.name": "Reconnaissance de la parole",
    "gui.extension.speechRecognition.description": "Reconnaissance de la parole",
    "gui.gui.loaded": "chargé",
    "gui.gui.notLoaded": "pas chargé",
    "gui.gui.cantFindYouWant": "Vous ne trouvez pas ce que vous voulez",
    "gui.gui.clickHere": "cliquez ici",
    "gui.gui.viewHelp": "voir aide",
    "gui.gui.uploadToDev": "Télécharger",
    "gui.gui.compileAndUpload": "Envoi compilé",
    "gui.gui.compileOnly": "Compilé uniquement",
    "gui.gui.compileOpen": "Compilé Ouvert",
    "gui.gui.burnBootloader": "Graver le chargeur de démarrage sans fil",
    "gui.gui.arduinoRun": "Démarre",
    "gui.gui.arduinoSave": "Sauvegarder",
    "gui.gui.autoGenerated": "Génération automatique",
    "gui.gui.manualEditing": "Edition manuelle",
    "gui.gui.codeBox": "boîtes de code",
    "gui.gui.moduleCode": "code du module",
    "gui.menu.edit.undo": "Annuler",
    "gui.menu.edit.redo": "Refaire",
    "gui.menu.edit.selectAll": "Tout sélectionner",
    "gui.menu.edit.cut": "Couper",
    "gui.menu.edit.copy": "Copie",
    "gui.menu.edit.paste": "Coller",
    "gui.menu.edit.codeStyle": "Style de code",
    "gui.menu.edit.fontSize": "taille de police",
    "gui.menu.edit.clearCache": "Vider le cache",
    "gui.menu.edit.maxReset": "Max restaure les paramètres d'usine",
    "gui.gui.serial": "Port série",
    "gui.gui.openSerial": "ouvrir le port série",
    "gui.gui.closeSerial": "fermer le port série ",
    "gui.gui.close": "Fermer",
    "gui.gui.open": "Ouvrir",
    "gui.gui.clearOutput": "effacer la console",
    "gui.gui.scroll": "fait défiler",
    "gui.gui.send": "Envoie",
    "gui.gui.baud": "baud",
    "gui.gui.noLineEnd": "Pas de fin de ligne",
    "gui.gui.newLine": "Saut de ligne",
    "gui.gui.carriageReturn": "Retour de chariot",
    "gui.gui.bothNLandCR": "LF & CR",
    "gui.gui.unavailableHexData": "Invalid hex form! Please represent hex data with two characters ((0-9/A-F) and a blank, e.g. \"00 01 A2 FF\".",
    "gui.gui.hexForm": "Hex Form",
    "gui.gui.openHexForm": "Use hex form to show and send data",
    "gui.gui.closeHexForm": "Close",
    "gui.progress.burning": "Flashage",
    "gui.progress.compiling": "Compile",
    "gui.burner.compileError": "Compile error",
    "gui.burner.linkError": "Link error",
    "gui.burner.generateHexError": "Generate .hex error",
    "gui.burner.generateBinError": "Generate .bin error",
    "gui.burner.burnError": "Burn error",
    "gui.burner.eraseError": "Erase error",
    "gui.burner.findLeonardoPortError": "Cannot find bootlaoder port of Leonardo",
    "gui.burner.noMicrobit": "Sorry it is not micro: bit, please connect micro: bit",
    "gui.burner.UDiskPopUp": "UDisk pops up, please wait for a while and try again",
    "gui.burner.UDiskWriteError": "Write to UDisk error",
    "gui.burner.openPortError": "Could not open port: Access is denied",
    "gui.burner.printObjectTooBig": "The project is too large; please click the \"help\" in the right top to reduce its size. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooBig": "Not enough memory; please click the \"help\" in the right top to reduce memory occupancy. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Low memory available, stability problems may occur. ",
    "gui.burner.printSizeError": "Impossible de déterminer la taille du programme ",
    "gui.burner.swdCfgError": "Cannot write to cmsis-dap.cfg",
    "gui.burner.printSize": "Le projet utilise [%TEXTSIZE] octets, occupe ([%TEXTSIZEPRE]) l'espace mémoire du programme, laissant [%TEXTSIZESUR] octets, jusqu'à [%TEXTSIZEMAX] octets. La variable globale \nUtilise [%DATASIZE] octets ([%DATASIZEPRE]) de la mémoire dynamique, en laissant les variables locales [%DATASIZESUR], jusqu’à [%DATASIZEMAX]. ",
    "gui.progress.compileSuccess": "compilation réussi",
    "gui.progress.compileProgress": "compilation en cours",
    "gui.progress.uploadProgress": "téléversement en cours ",
    "gui.progress.uploadSuccess": "téléversement réussi",
    "gui.progress.errMsg": "!Erreur de téléchargement vous pouvez voir un message d'erreur dans la console dans le coin inférieur droit",
    "gui.howtos.spin-around.name": "Tourner autour",
    "gui.howtos.spin.step_dragTurn": "Faites glisser un bloc “tourner”",
    "gui.howtos.spin.step_clickTurn": "Cliquez sur le bloc pour l'exécuter",
    "gui.howtos.spin.step_clickControl": "Cliquez sur la catégorie “Contrôle”",
    "gui.howtos.spin.step_dragForever": "Faites glisser un bloc “pour toujours”",
    "gui.howtos.spin.step_clickForever": "Cliquez sur la pile pour l'exécuter",
    "gui.howtos.spin.step_changeColor": "Ajouter un bloc “change effet couleur”",
    "gui.howtos.say-hello.name": "Dis bonjour",
    "gui.howtos.say-hello.step_addSprite": "Ajouter un nouveau Lutin",
    "gui.howtos.say-hello.step_clickLooks": "Cliquez sur la catégorie Looks \"\"",
    "gui.howtos.say-hello.step_dragSay": "Faites glisser un bloc “dire”",
    "gui.howtos.say-hello.step_clickSay": "Cliquez sur le bloc pour l'exécuter",
    "gui.howtos.say-hello.step_anotherSay": "Faites glisser un autre bloc “dire”",
    "gui.howtos.say-hello.step_editSay": "Dites quelque chose d'autre",
    "gui.howtos.say-hello.step_clickStack": "Cliquez sur la pile pour l'exécuter",
    "gui.howtos.run-away.name": "Fuyez",
    "gui.howtos.run-away.step_dragGoTo": "aller à la position aléatoire",
    "gui.howtos.run-away.step_clickGoTo": "Cliquez sur le bloc pour l'exécuter",
    "gui.howtos.run-away.step3": "Cliquez sur la catégorie Événements \"\"",
    "gui.howtos.run-away.step_addWhenClicked": "Ajouter un bloc “quand on clique ce Lutin”",
    "gui.howtos.run-away.step_clickSprite": "Clique sur le Lutin pour l'animer",
    "gui.howtos.run-away.step_addSound": "Ajouter un bloc “démarrer le son”",
    "gui.howtos.pick-up-apple.name": "pick-up apple",
    "gui.howtos.pick-up-apple.step_1": "Basculer vers l'interface d'exécution interprétative",
    "gui.howtos.pick-up-apple.step_2": "Changer de produit",
    "gui.howtos.pick-up-apple.step_3": "Connecter l'appareil",
    "gui.howtos.pick-up-apple.step_4": "Calibrage de la boussole",
    "gui.howtos.pick-up-apple.step_5": "Ouvrez la routine",
    "gui.howtos.pick-up-apple.step_6": "ramasser la pomme",
    "gui.howtos.move-left-right.name": "déplacer gauche droite",
    "gui.howtos.move-left-right.step_1": "Nouveau projet",
    "gui.howtos.move-left-right.step_2": "Faites glisser le bloc 1",
    "gui.howtos.move-left-right.step_3": "Faites glisser le bloc 2",
    "gui.howtos.move-left-right.step_4": "se déplacer à gauche et à droite",
    "gui.howtos.install-driver.name": "installer le pilote",
    "gui.howtos.transform-expression.name": "transformer l'expression",
    "gui.howtos.transform-expression.step_1": "Passer à la page d'exécution de téléchargement",
    "gui.howtos.transform-expression.step_2": "Changer de produit",
    "gui.howtos.transform-expression.step_3": "Faites glisser le bloc 1",
    "gui.howtos.transform-expression.step_4": "Faites glisser le bloc 2",
    "gui.howtos.transform-expression.step_5": "Faites glisser le bloc 3",
    "gui.howtos.transform-expression.step_6": "transformer l'expression",
    "gui.howtos.lot.name": "Diagramme fonctionnel",
    "gui.howtos.lot.step_1": "Sélectionnez la routine",
    "gui.howtos.lot.step_2": "Comment télèverser le programme ",
    "gui.howtos.lot.step_3": "Diagramme fonctionnel",
    "gui.howtos.touch-pin.name": "broche tactile",
    "gui.howtos.touch-pin.step_1": "Passer à la page pour télécharger",
    "gui.howtos.touch-pin.step_2": "Sélectionnez une fonction",
    "gui.howtos.touch-pin.step_3": "Programme de gravure",
    "gui.howtos.touch-pin.step_4": "broche tactile",
    "gui.howtos.save-witch.name": "Sauvez la sorcière",
    "gui.howtos.save-witch.step_1": "Passage à l'exécution en temps réel",
    "gui.howtos.save-witch.step_2": "Sélectionnez la carte de contrôle principale 'micro: bit' pour l'expansion",
    "gui.howtos.save-witch.step_3": "Sélectionnez Enregistrer la sorcière \"dans l'exemple de programme\"",
    "gui.howtos.save-witch.step_4": "Connecter l'appareil，  sélectionner le microbit attendre que la connexion aboutisse",
    "gui.howtos.save-witch.step_5": "Si vous êtes invité à calibrer le périphérique \"",
    "gui.howtos.save-witch.step_6": "Cliquez sur le drapeau vert et balancez votre bras pour interagir avec l'animation",
    "gui.howtos.microbit-touch.name": "microbit touch pour changer d'expression",
    "gui.howtos.microbit-touch.step_1": "Passer à la page pour télécharger",
    "gui.howtos.microbit-touch.step_2": "Ouvrez le Exemple de programme \"dans le menu\" Apprentissage \"et sélectionnez\" Pin touch \"\"",
    "gui.howtos.microbit-touch.step_3": "Connectez le port COM correspondant au périphérique et cliquez 'Télécharger'",
    "gui.howtos.microbit-touch.step_4": "Appuyez sur différentes touches pour afficher différentes expressions",
    "gui.howtos.microbit-calibration.name": "micro: bit calibration",
    "gui.howtos.microbit-calibration.step_1": "Faites pivoter la carte micro:bit perpendiculairement au sol et les LEDs intégrés s’allumeront à tour de rôle.",
    "gui.howtos.microbit-calibration.step_2": "Jusqu'à ce que les LEDs extérieurs soient toutes allumées (comme indiqué ci-dessous) un sourire apparaîtra une fois l'étalonnage terminé.",
    "gui.extension.sen0251.name": "Capteur de température de pression d'air BMP388",
    "gui.extension.sen0251.description": "Température，  pression atmosphérique，  et détection d'altitude",
    "gui.extension.sen0206.name": "Capteur de température infrarouge sans contact",
    "gui.extension.sen0206.description": "Far-infrared non-contact measurement of object or ambient temperature",
    "gui.gui.recentlyEdited": "Récemment modifié",
    "gui.extension.weather.name": "obtenir le temps",
    "gui.extension.weather.description": "obtenir  la météo en temps réel",
    "gui.extension.tinywebdb.name": "tinywebdb",
    "gui.extension.tinywebdb.description": "service de bases de données sur le Web",
    "gui.gui.variableScopeOptionAllSprites": "Pour tous les sprites",
    "gui.gui.variableScopeOptionSpriteOnly": "Pour ce sprite seulement",
    "gui.gui.variablePromptAllSpritesMessage": "Cette variable sera disponible pour tous les sprites.",
    "gui.monitor.contextMenu.default": "lecture normale",
    "gui.monitor.contextMenu.large": "grande lecture",
    "gui.monitor.contextMenu.slider": "glisseur",
    "gui.monitor.contextMenu.import": "importation",
    "gui.monitor.contextMenu.export": "exportation",
    "gui.monitor.listMonitor.listLength": "longueur {length}",
    "gui.monitor.listMonitor.empty": "(vide)",
    "gui.costumeTab.backdrop": "Fond",
    "gui.costumeTab.costume": "costume",
    "gui.opcodeLabels.direction": "direction",
    "gui.opcodeLabels.xposition": "position x",
    "gui.opcodeLabels.yposition": "position y",
    "gui.opcodeLabels.size": "Taille",
    "gui.opcodeLabels.costumename": "nom du costume",
    "gui.opcodeLabels.costumenumber": "numéro de costume",
    "gui.opcodeLabels.backdropname": "nom du Fond",
    "gui.opcodeLabels.backdropnumber": "numéro du Fond",
    "gui.opcodeLabels.volume": "le volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "réponse",
    "gui.opcodeLabels.loudness": "intensité",
    "gui.opcodeLabels.year": "année",
    "gui.opcodeLabels.month": "mois",
    "gui.opcodeLabels.date": "jour",
    "gui.opcodeLabels.dayofweek": "jour de la semaine",
    "gui.opcodeLabels.hour": "heure",
    "gui.opcodeLabels.minute": "minute",
    "gui.opcodeLabels.second": "seconde",
    "gui.opcodeLabels.timer": "Chronomètre ",
    "gui.loadProject.error": "load project error",
    "gui.fileSystem.boardFiles": "Board Files",
    "gui.fileSystem.computerFiles": "Board Computer",
    "gui.fileSystem.newFile": "new file",
    "gui.fileSystem.newFolder": "new folder",
    "gui.fileSystem.deleteFolder": "delete folder",
    "gui.fileSystem.openFolder": "open folder",
    "gui.fileSystem.openInEditor": "open in editor",
    "gui.fileSystem.runRightNow": "run right now",
    "gui.fileSystem.stopRun": "stop run",
    "gui.fileSystem.setBoot": "boot run",
    "gui.fileSystem.deleteForever": "delete forever",
    "gui.fileSystem.saveAs": "save as",
    "gui.fileSystem.undoAllEdits": "cancel all edits",
    "gui.fileSystem.copyToComputer": "copy to computer",
    "gui.fileSystem.uploadToBoard": "upload to board",
    "gui.fileSystem.uploadAndRun": "upload and run",
    "gui.extension.microTouch.name": "TouchKey",
    "gui.extension.microTouch.description": "Carte d'extension de clavier 4 × 4 et de servomoteur intégrés",
    "gui.extension.microIoT.name": "micro:IoT",
    "gui.extension.microIoT.description": "Affichage intégré et Internet des objets micro: carte d'extension de bits",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "Utilisez-le pour lire ou écrire et sauvegarder des données avec eeprom",
    "gui.extension.sdcard.name": "Module de lecteur de carte SD",
    "gui.extension.sdcard.description": "Module de lecteur de carte MicroSD",
    "gui.extension.sen0160.name": "Capteur de poids Hx711",
    "gui.extension.sen0160.description": "Mesurer le capteur de poids Hx711",
    "gui.extension.sen0236.name": "BME280 module",
    "gui.extension.sen0236.description": "Environmental sensors (temperature, humidity, air pressure)",
    "gui.extension.motucamera.name": "Carte de capteur MU",
    "gui.extension.motucamera.description": "description de l'extension 'carte de capteur MU'",
    "gui.extension.sen0117.name": "Module de synthèse vocale",
    "gui.extension.sen0117.description": "Vous venez de taper les caractères chinois et anglais et les chiffres et il les lira",
    "gui.extension.dfr0588.name": "Capteur de température et d'humidité",
    "gui.extension.dfr0588.description": "Capteur de température et d'humidité",
    "gui.extension.dfr0117.name": "Capteur eprom iic",
    "gui.extension.dfr0117.description": "Module de stockage de données, 32 Ko",
    "gui.extension.sen0291.name": "Wattmètre numérique I2C",
    "gui.extension.sen0291.description": "Mesure de haute précision de tension, courant et puissance",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "Laisser le périphérique demander des informations via HTTP",
    "gui.codeLabel.dynamicVariables": "Dynamic variables",
    "gui.codeLabel.functionDeclaration": "Function declaration",
    "gui.codeLabel.staticConstants": "Static constants",
    "gui.codeLabel.createObject": "Create an object",
    "gui.codeLabel.mainProgramStart": "Début du programme principal",
    "gui.codeLabel.SubthreadProgramStarts": "Le sous-programme% 1 commence",
    "gui.codeLabel.customFunction": "Fonction personnalisée",
    "gui.codeLabel.eventCallbackFunction": "Fonction de rappel d'événement",
    "gui.codeLabel.globalCode": "Global variables",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "No variables allowed!",
    "gui.codeLabel.staticFunctions": "Fonctions statiques",
    "gui.extension.pictureai.name": "Reconnaissance d'images AI",
    "gui.extension.pictureai.description": "faire quelque chose de merveilleux avec l'image AI",
    "gui.extension.iflytek.description": "IFLYTEK",
    "gui.fileSystem.fileCatalogue": "Catalogue",
    "gui.wifi.pwdNotLegal": "! Erreur: le mot de passe est dans le mauvais format. Le mot de passe Wi-Fi doit être composé de 8 à 20 chiffres ou chiffres.",
    "gui.spreadmaqueen.perror": "! Erreur: La lampe à led à broches P12 n'est pas disponible. Veuillez sélectionner une lampe à LED à broches P8.",
    "gui.gui.viewConflict": "voir le conflit",
    "gui.gui.conflict": "Conflict",
    "gui.gui.clickPrompt": "Cliquez pour mettre en surbrillance des blocs, double-cliquez pour localiser des blocs",
    "music.categoryName": "Music",
    "translate.categoryName": "Translate",
    "sensor.categoryName": "sensor",
    "actuator.categoryName": "actuator",
    "communicate.categoryName": "communicate",
    "display.categoryName": "display",
    "extendFunction.categoryName": "extendFunction",
    "internetService.categoryName": "internetService",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "handpy",
    "spread.categoryName": "Expansion Board",
    "speechRecognition.categoryName": "speech recognition",
    "gui.blocklyText.romeo.d1ProgramStart": "D1 robot program starts",
    "gui.blocklyText.romeo.readdigital": "read value pin[PIN] [MODULE] ",
    "gui.blocklyText.romeo.buttonModule": "Button Module",
    "gui.blocklyText.romeo.digitalSensor": "Digital Sensor",
    "gui.blocklyText.romeo.collisionSensor": "Collision Sensor",
    "gui.blocklyText.romeo.lineSensor": "Line Sensor",
    "gui.blocklyText.romeo.infraredSwitch": "Infrared Switch",
    "gui.blocklyText.romeo.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.romeo.readAnalog": "read value pin [PIN] [SENSOR] ",
    "gui.blocklyText.romeo.lightSensor": "Light Sensor",
    "gui.blocklyText.romeo.anologSensor": "Anolog Sensor",
    "gui.blocklyText.romeo.soundSensor": "Sound Sensor",
    "gui.blocklyText.romeo.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.romeo.frameSensor": "Frame Sensor",
    "gui.blocklyText.romeo.open": "Turn on",
    "gui.blocklyText.romeo.close": "Turn off",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] of pin [PIN] ",
    "gui.blocklyText.romeo.ledLights": "LED Lights",
    "gui.blocklyText.romeo.digitalActuator": "Digital Actuator",
    "gui.blocklyText.romeo.relay": "Relay",
    "gui.blocklyText.romeo.fan": "Fan",
    "gui.blocklyText.romeo.writeAnalog": "set value of pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.romeo.fanModule": "Fan Module",
    "gui.blocklyText.romeo.robotSpeedMove": "robot [DIR] at a speed of [SPEED]",
    "gui.blocklyText.romeo.forward": "move forward",
    "gui.blocklyText.romeo.backward": "move backward",
    "gui.blocklyText.romeo.turnLeft": "turn left",
    "gui.blocklyText.romeo.turnRight": "turn right",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "make motor [MOTOR] move [DIR] at a speed of [SPEED]",
    "gui.blocklyText.romeo.interface": "Interface",
    "gui.blocklyText.romeo.motorForward": "Forward",
    "gui.blocklyText.romeo.motorReverse": "Backward",
    "gui.blocklyText.romeo.stopMotor": "robot (M1 and M2 motors) stop moving",
    "gui.blocklyText.romeo.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "lire broche [PIN] LM35 température (°C)",
    "gui.blocklyText.romeo.ds18b20": "lire la broche [PIN] DS18B20 température (°C)",
    "gui.blocklyText.romeo.pressInfrared": "Is infrared pin [PIN] received the pressed [BUTTON] signal？",
    "gui.blocklyText.romeo.volumePlus": "Volume+",
    "gui.blocklyText.romeo.switch": "Switch",
    "gui.blocklyText.romeo.volumeMinus": "Volume-",
    "gui.blocklyText.romeo.startPause": "Start/Pause",
    "gui.blocklyText.romeo.up": "Up",
    "gui.blocklyText.romeo.down": "Down",
    "gui.blocklyText.romeo.dataReadable": "Is there data to read on serial port?",
    "gui.blocklyText.romeo.readSerialData": "read serial data",
    "gui.blocklyText.romeo.timer": "system uptime(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "set pin [PIN] trumpet tone [TONE] and beat [BEAT]",
    "gui.blocklyText.romeo.half": "Half",
    "gui.blocklyText.romeo.quarter": "Quarter",
    "gui.blocklyText.romeo.oneInEighth": "One-eighth",
    "gui.blocklyText.romeo.wholeBeat": "Whole Beat",
    "gui.blocklyText.romeo.doubleBeat": "Double Beat",
    "gui.blocklyText.romeo.stop": "Stop",
    "gui.blocklyText.romeo.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.romeo.segment": "eight-segment LED (D6-D13 pin) display [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "serial [WAY] [STRING] [LINE]",
    "gui.blocklyText.romeo.originalOutput": "original output",
    "gui.blocklyText.romeo.stringOutput": "string output",
    "gui.blocklyText.romeo.hexOutput": "HEX output",
    "gui.blocklyText.romeo.wrap": "Wrap",
    "gui.blocklyText.romeo.noWrap": "No-Wrap",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "read serial data [READTYPE]",
    "gui.blocklyText.romeo.int": "int",
    "gui.blocklyText.romeo.float": "float",
    "gui.blocklyText.romeo.setBaudRate": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "read pin [PIN] pulse width mode [LEVEL]  timeout [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "LOW",
    "gui.blocklyText.romeo.high": "HIGH",
    "gui.blocklyText.romeo.noSound": "no sound",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino starts",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno starts",
    "gui.blocklyText.esp32.displayInLine": "display [TEXT] in the [LINE] line",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "clear cache in the [LINE] line",
    "gui.blocklyText.esp32.displayClearInLine": "clear in the [LINE] line",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 starts",
    "gui.blocklyText.esp32.btnIsPressed": "[BUTTON] button is [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "when button [REFERENCE] [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "when pin [REFERENCE] is [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "When [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "[PIN] pin is [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "Stop background playback",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "background play note [NOTE]",
    "gui.blocklyText.esp32.setBuzzerFreq": "play note [NOTE] beat [BEAT]",
    "gui.blocklyText.esp32.getSoundIntensity": "read microphone sound intensity",
    "gui.blocklyText.esp32.readBrightness": "read environmental brightness",
    "gui.blocklyText.esp32.setLightsColor": "lights [LIGHTS] show color [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.esp32.acceleration": "read acceleration(mg) [TYPE]",
    "gui.blocklyText.esp32.displayInXY": "display [TEXT] in X: [X] axis Y*16: [Y] axis preview [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "screen displayed as [COLOR] (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "cache screen displayed as [COLORMPYTHON] (clear screen)",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": "all white",
    "gui.blocklyText.esp32.lineDrawing": "line drawing origin x1:[X1] y1:[Y1] end x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "connected",
    "gui.blocklyText.esp32.disconnected": "disconnected",
    "gui.blocklyText.esp32.rectangleDrawing": "rectangle drawing[FILL] origin x:[X] y:[Y] width[WIDTH] height[HEIGHT]",
    "gui.blocklyText.esp32.screenDisplay": "screen cache display takes effect",
    "gui.blocklyText.esp32.circleDrawing": "circle drawing[FILL] origin x:[X] y:[Y] radius:[RADIUS]",
    "gui.blocklyText.esp32.fill": "fill",
    "gui.blocklyText.esp32.notFill": "not fill",
    "gui.blocklyText.esp32.pointDrawing": "point drawing x:[X] y:[Y]",
    "gui.blocklyText.esp32.setLineWidth": "set the line width to [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "digital write [PIN] value [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "digital read [PIN]",
    "gui.blocklyText.esp32.analogWrite": "analog write(PWM) [PIN] value [VALUE]",
    "gui.blocklyText.esp32.analogRead": "analog read [PIN]",
    "gui.blocklyText.esp32.serialPort0": "serial0",
    "gui.blocklyText.esp32.serialPort1": "serial1",
    "gui.blocklyText.esp32.serialPort2": "serial2",
    "gui.blocklyText.esp32.seriaRemapping": "set [SERIAL] Rx [RX] Tx [TX] The baud rate [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "image drawing[IMAGE] origin x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "cache image drawing[IMAGE] origin x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "pressed",
    "gui.blocklyText.esp32.loosened": "loosened",
    "gui.blocklyText.esp32.all": "all",
    "gui.blocklyText.esp32.setBaud": "set serial0 baud rate [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.esp32.setBrightness": "set led brightness [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "get led lightness",
    "gui.blocklyText.esp32.print": "print [DATA]",
    "gui.blocklyText.esp32.TiltForward": "Tilt forward",
    "gui.blocklyText.esp32.TiltBack": "Tilt back",
    "gui.blocklyText.esp32.TiltLeft": "Tilt left",
    "gui.blocklyText.esp32.TiltRight": "Tilt right",
    "gui.blocklyText.esp32.ScreenUp": "Screen up",
    "gui.blocklyText.esp32.ScreenDown": "Face down",
    "gui.blocklyText.esp32.Shake": "Shake",
    "gui.blocklyText.esp32.isGesture": "is [GESTURE]?",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 starts",
    "gui.blocklyText.mega2560.digitalRead": "digital read [PIN]",
    "gui.blocklyText.mega2560.analogRead": "analog read [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "set pwm pin [PIN] to [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "digital write [PIN] value [VALUE]",
    "gui.blocklyText.mega2560.level.low": "LOW",
    "gui.blocklyText.mega2560.level.high": "HIGH",
    "gui.blocklyText.mega2560.irqRead": "read Infrared Receiver Modules digital pin [PIN]",
    "gui.blocklyText.mega2560.ultrasonicRead": "read ulrasonic sensor trig [TRIG] echo [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "set [PIN] pin buzzer tone [TONE] beat [BEAT]",
    "gui.blocklyText.mega2560.harf": "Harf",
    "gui.blocklyText.mega2560.quarter": "Quarter",
    "gui.blocklyText.mega2560.oneInEighth": "One-eighth",
    "gui.blocklyText.mega2560.wholeBeat": "Whole Beat",
    "gui.blocklyText.mega2560.doubleBeat": "Double Beat",
    "gui.blocklyText.mega2560.stop": "Stop",
    "gui.blocklyText.mega2560.servoSet": "Réglez le servo de broche [PIN] sur [DEGREE] degré",
    "gui.blocklyText.mega2560.setBaud": "set [SERIAL] baud rate [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "serial0",
    "gui.blocklyText.mega2560.serialPort1": "serial1",
    "gui.blocklyText.mega2560.serialPort2": "serial2",
    "gui.blocklyText.mega2560.serialPort3": "serial3",
    "gui.blocklyText.mega2560.dataReadable": "Is [SERIAL] data readable?",
    "gui.blocklyText.mega2560.readSerialData": "read [SERIAL] data",
    "gui.blocklyText.mega2560.stringOutput": "in string",
    "gui.blocklyText.mega2560.hexOutput": "in HEX",
    "gui.blocklyText.mega2560.wrap": "Wrap",
    "gui.blocklyText.mega2560.noWrap": "No-Wrap",
    "gui.blocklyText.mega2560.readPinLevel": "read pin [PIN] pulse width mode [LEVEL]  timeout [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "system uptime(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini starts",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo starts",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 starts",
    "gui.blocklyText.arduino.setDigital": "set digital pin [PIN] to [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "read digital pin [PIN]",
    "gui.blocklyText.arduino.readSimilation": "read analog pin [PIN]",
    "gui.blocklyText.arduino.setPwm": "set pwm pin [PIN] to [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "Lecture distance ultrasonore (cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "set pin [PIN] trumpet tone [TONE] and beat [BEAT]",
    "gui.blocklyText.arduino.serialSendStr": "serial output [STRING] [WAY] and [LINE]",
    "gui.blocklyText.arduino.originalOutput": "in original",
    "gui.blocklyText.arduino.stringOutput": "in string",
    "gui.blocklyText.arduino.hexOutput": "in HEX",
    "gui.blocklyText.arduino.wrap": "Wrap",
    "gui.blocklyText.arduino.noWrap": "No-Wrap",
    "gui.blocklyText.arduino.readInfrared": "read Infrared Receiver Modules digital pin [PIN]",
    "gui.blocklyText.arduino.setBaud": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "broche de lecture [PIN] motif de durée d'impulsion [LEVEL] délai d'attente [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "no sound",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "arduino nano starts",
    "gui.blocklyText.vortex.vortexStart": "vortex robot program start",
    "gui.blocklyText.vortex.vortexMoveSpeed": "vortex robot [DIRECTION] at a speed of [SPEED] ",
    "gui.blocklyText.vortex.forward": "go forward",
    "gui.blocklyText.vortex.backup": "back up",
    "gui.blocklyText.vortex.turnLeft": "turn left",
    "gui.blocklyText.vortex.turnRight": "turn right",
    "gui.blocklyText.vortex.setMotor": "make motor [MOTOR] [DIRECTION] at a speed of [SPEED]",
    "gui.blocklyText.vortex.leftWheel": "left wheel",
    "gui.blocklyText.vortex.rightWheel": "right wheel",
    "gui.blocklyText.vortex.setExpression": "set expression [EXPRESSION] to color [COLOR]",
    "gui.blocklyText.vortex.ledLights": "make the [DIRECTION][LIGHT] change to [COLOR] in [SECOND]secs.",
    "gui.blocklyText.vortex.all": "all",
    "gui.blocklyText.vortex.rightAhead": "right ahead",
    "gui.blocklyText.vortex.leftRear": "left rear",
    "gui.blocklyText.vortex.rightRear": "right rear",
    "gui.blocklyText.vortex.rightAstern": "right astern",
    "gui.blocklyText.vortex.topLights": "top lights",
    "gui.blocklyText.vortex.bottomLights": "bottom lights",
    "gui.blocklyText.vortex.vortexStop": "vortex stops moving ",
    "gui.blocklyText.vortex.setVolume": "set the volume to [VALUE]",
    "gui.blocklyText.vortex.playMusic": "play the [VALUE] music",
    "gui.blocklyText.vortex.stopMusic": "stop playing music",
    "gui.blocklyText.vortex.startDance": "start the [VALUE] dance",
    "gui.blocklyText.vortex.stopDance": "stop dancing",
    "gui.blocklyText.vortex.barrierDetection": "barriers detection?",
    "gui.blocklyText.vortex.readSensor": "read [SENSOR] Grayscale Sensor",
    "gui.blocklyText.vortex.setSensor": "set grayscale sensors sensitivity to [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "grayscale sensor meets black on its [SENSOR] position?",
    "gui.blocklyText.vortex.positiveFrontLeft": "straight left front",
    "gui.blocklyText.vortex.frontLeft": "left front",
    "gui.blocklyText.vortex.positiveFrontRight": "straight right front",
    "gui.blocklyText.vortex.frontRight": "right front",
    "gui.blocklyText.vortex.backRight": "right rear",
    "gui.blocklyText.vortex.backLeft": "left rear",
    "gui.blocklyText.vortex.initialOldPCB": "initialize to the old version (blue PCB)",
    "pen.categoryName": "Pen",
    "pen.changeColorParam": "change pen [COLOR_PARAM] by [VALUE]",
    "pen.changeHue": "change pen color by [HUE]",
    "pen.changeShade": "change pen shade by [SHADE]",
    "pen.changeSize": "change pen size by [SIZE]",
    "pen.clear": "erase all",
    "pen.colorMenu.brightness": "brightness",
    "pen.colorMenu.color": "color",
    "pen.colorMenu.saturation": "saturation",
    "pen.colorMenu.transparency": "transparency",
    "pen.penDown": "pen down",
    "pen.penUp": "pen up",
    "pen.setColor": "set pen color to [COLOR]",
    "pen.setColorParam": "set pen [COLOR_PARAM] to [VALUE]",
    "pen.setHue": "set pen color to [HUE]",
    "pen.setShade": "set pen shade to [SHADE]",
    "pen.setSize": "set pen size to [SIZE]",
    "pen.stamp": "stamp",
    "music.changeTempo": "change tempo by [TEMPO]",
    "music.drumBass": "(2) Bass Drum",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Closed Hi-Hat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Cowbell",
    "music.drumCrashCymbal": "(4) Crash Cymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Hand Clap",
    "music.drumOpenHiHat": "(5) Open Hi-Hat",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Snare Drum",
    "music.drumTambourine": "(7) Tambourine",
    "music.drumTriangle": "(12) Triangle",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Wood Block",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Bassoon",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Choir",
    "music.instrumentClarinet": "(10) Clarinet",
    "music.instrumentElectricGuitar": "(5) Electric Guitar",
    "music.instrumentElectricPiano": "(2) Electric Piano",
    "music.instrumentFlute": "(12) Flute",
    "music.instrumentGuitar": "(4) Guitar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Music Box",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxophone",
    "music.instrumentSteelDrum": "(18) Steel Drum",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibraphone",
    "music.instrumentWoodenFlute": "(13) Wooden Flute",
    "music.midiPlayDrumForBeats": "play drum [DRUM] for [BEATS] beats",
    "music.midiSetInstrument": "set instrument to [INSTRUMENT]",
    "music.playDrumForBeats": "play drum [DRUM] for [BEATS] beats",
    "music.playNoteForBeats": "play note [NOTE] for [BEATS] beats",
    "music.restForBeats": "rest for [BEATS] beats",
    "music.setInstrument": "set instrument to [INSTRUMENT]",
    "music.setTempo": "set tempo to [TEMPO]",
    "gui.blocklyText.microbit.microbitStarts": "micro:bit starts",
    "gui.blocklyText.microbit.interruptExcute": "interrupt pin [PIN] mode [MODE] excute",
    "gui.blocklyText.microbit.cancleInterrupt": "cancel interrupt pin [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "when received wireless data",
    "gui.blocklyText.microbit.whenBtnPress": "when button [REFERENCE] pressed",
    "gui.blocklyText.microbit.whenPinConnected": "when microbit pin [REFERENCE] connected",
    "gui.blocklyText.microbit.whenPosChange": "when [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "display pattern [PIC]",
    "gui.blocklyText.microbit.showInput": "display [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "stop animation",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] coordinates of point （x:[XAXIS]， y:[YXAXIS]）",
    "gui.blocklyText.microbit.hideAllLights": "hide all dot matrix",
    "gui.blocklyText.microbit.microbitEnableLight": "LED enable [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "lightness",
    "gui.blocklyText.microbit.showLightWithBrightness": "set brightness [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "light up on the x:[XAXIS] axis， y:[YXAXIS] axis， brightness [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "pin [PIN] play sound [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "pin [PIN] play sound [SOUND] until done",
    "gui.blocklyText.microbit.playNote": "pin [PIN] play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.maqueen.playSound": "pin P0 play sound [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "pin P0 play sound [SOUND] until down",
    "gui.blocklyText.maqueen.playNote": "pin P0 play note [NOTE] for a [BEAT] beat",
    "gui.blocklyText.maqueen.RGB0": "RGB0  (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1  (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2  (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3  (3)",
    "gui.blocklyText.maqueen.All": "All  (-1)",
    "gui.blocklyText.microbit.changeTempo": "change tempo (bpm) by [VALUE]",
    "gui.blocklyText.microbit.setTempo": "set tempo (bpm) to [VALUE]",
    "gui.blocklyText.microbit.getTempo": "tempo (bpm)",
    "gui.blocklyText.microbit.stopSound": "stop background playback",
    "gui.blocklyText.microbit.btnIsPressed": "[BUTTON] button is pressed?",
    "gui.blocklyText.microbit.pinIsConnected": "[PIN] pin is connected?",
    "gui.blocklyText.microbit.isMove": "current state is [TYPE]?",
    "gui.blocklyText.microbit.compass": "read compass orientation",
    "gui.blocklyText.microbit.temperature": "read temperature",
    "gui.blocklyText.microbit.digitalWrite": "set digital pin [PIN] output [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.microbit.analogWrite": "set analog pin [PIN] output [VALUE]",
    "gui.blocklyText.microbit.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.microbit.up": "logo up",
    "gui.blocklyText.microbit.down": "logo down",
    "gui.blocklyText.microbit.left": "tilt to left",
    "gui.blocklyText.microbit.right": "tilt to right",
    "gui.blocklyText.microbit.faceUp": "face up",
    "gui.blocklyText.microbit.faceDown": "face down",
    "gui.blocklyText.microbit.freefall": "freefall",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "shake",
    "gui.blocklyText.microbit.show": "show",
    "gui.blocklyText.microbit.hide": "hide",
    "gui.blocklyText.microbit.low": "LOW",
    "gui.blocklyText.microbit.high": "HIGH",
    "gui.blocklyText.microbit.microbitReadBrightness": "read ambient brightness",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] wireless communication",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "set wireless channel [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "send string [TEXT] via wireless",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "receive data from wireless",
    "gui.blocklyText.microbit.strength": "strength",
    "gui.blocklyText.microbit.acceleration": "read acceleration(mg)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "read magnetic force(µT)[TYPE]",
    "gui.blocklyText.microbit.print": "Print [DATA]",
    "gui.blocklyText.microbit.goUp": "up",
    "gui.blocklyText.microbit.goDown": "down",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "when received [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "wireless data",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot Starts",
    "gui.blocklyText.maxbot.playSound": "play sound [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "play sound [SOUND] until done",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "collision occurred on the [LEFTRIGHT] ?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "black line detected on the [LEFTRIGHT]  ?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "make motor [MOTOR] move [DIR] by [SPEED] speed ",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "read (P1，P2) ulrasonic sensor distance unit [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "read ulrasonic sensor  Unit [UNIT] trig [TRIG] echo [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "FORWARD",
    "gui.blocklyText.maxbot.BACKWARD": "BACKWARD",
    "gui.blocklyText.motorbit.CW": "CW",
    "gui.blocklyText.motorbit.CCW": "CCW",
    "gui.blocklyText.maxbot.CW": "CW",
    "gui.blocklyText.maxbot.CCW": "CCW",
    "gui.blocklyText.maxbot.ANGLE": "ANGLE",
    "gui.blocklyText.maxbot.CIRCLE": "CIRCLE",
    "gui.blocklyText.maxbot.Left": "LEFT",
    "gui.blocklyText.maxbot.Right": "RIGHT",
    "gui.blocklyText.maxbot.cm": "cm",
    "gui.blocklyText.maxbot.inch": "inch",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit Starts",
    "gui.blocklyText.MotorBit.MotorRun": "motor [MOTOR] move [DIR] by [SPEED] speed",
    "gui.blocklyText.MotorBit.MotorStop": "motor [MOTOR] stop",
    "gui.blocklyText.MotorBit.Stepper": "stepper [STEPPER] rotate [ROTATEDIR] [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "ALL",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen Starts",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "read (P1，P2) ultrasonic distance(cm)",
    "gui.blocklyText.maqueen.motorStop": "robot (left and right motors) stop",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] led light",
    "gui.blocklyText.maqueen.redLineSensor": "read [LEFTRIGHT] line tracking sensor",
    "gui.blocklyText.maqueen.robotSpeedMove": "robot [DIR] by [SPEED] speed ",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "set motor [LEFTRIGHT] move [DIR] by [SPEED] speed ",
    "gui.blocklyText.maqueen.ledLeft": "Left(P8)",
    "gui.blocklyText.maqueen.ledRight": "Right(P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "Right(P12) (not available)",
    "gui.blocklyText.maqueen.lineSensorLeft": "Left(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "Right(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "when (P16) received [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "infrared signal",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "read(P16) infrared signal value",
    "gui.blocklyText.maqueen.numberOfLightsBright": "pin P15 4 RGB leds  Brightness [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "set pin P15 LED [NUM1] display color [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "pin P15 LED [NUM1] to [NUM2] leds show rainbow from [COLOR1] to [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "set pin P15 shift pixels by [NUM]",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "set pin P15 rotate pixels by [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "set pin P15 LED brightness [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "set pin P15 show histogram from [NUM1] with [NUM2] leds value [VALUE] max [MAX]",
    "gui.blocklyText.maqueen.clear": "set pin P15 RGB LEDs die out",
    "gui.blocklyText.maqueen.rgbColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.obloq.isReceivedData": "is [TOPIC] received data?",
    "gui.blocklyText.obloq.initialParameter": "obloq mqtt initialization: [PARAMETER] TX: pin [PINR] RX: pin [PINT] [SERIALPORT]",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq Read the message from [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq Read the message",
    "gui.blocklyText.obloq.httpInitialParameter": "obloq http initialization: [PARAMETER] TX: pin [PINR] RX: pin [PINT] [SERIALPORT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] timeout(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "add subscribe [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "user name",
    "gui.blocklyText.obloq.password": "password",
    "gui.blocklyText.obloq.iotService": "IOT Service",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "IP Address",
    "gui.blocklyText.obloq.port": "Port",
    "gui.blocklyText.obloq.addTopic": "Add Topic， only add up to 4",
    "gui.blocklyText.obloq.deleteTopic": "Delete Topic",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "Server",
    "gui.blocklyText.obloq.global": "Global",
    "gui.blocklyText.obloq.china": "China",
    "gui.blocklyText.obloq.softwareSerial": "Software Serial",
    "gui.blocklyText.obloq.hardwareSerial": "Hardware Serial",
    "gui.blocklyText.obloq.hardwareSerial1": "Hardware Serial1",
    "gui.blocklyText.obloq.hardwareSerial2": "Hardware Serial2",
    "gui.blocklyText.obloq.hardwareSerial3": "Hardware Serial3",
    "gui.blocklyText.obloq.WhenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq message",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq get [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Debug info",
    "gui.blocklyText.obloq.ERROR": "Error code",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "Infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.infraredEmission.send": "Infrared send pin [PIN] Send message: address [ADDRESS] command [COMMAND]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 display [TEXT] in line [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 display [TEXT] in position X: [X] Y:16* [Y]",
    "gui.blocklyText.oled2864.screenRotation": "The screen rotates to [ROTATION] degrees",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 clear",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "read pin [PIN] [DHT] [TYPE]",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx pin [PIN] PM concentration value [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 ultrasonic initialization Name [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04 ultrasonic distance measurement unit [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx pin [PIN] 0.1 liters of air in [DIAMETER] number of particles",
    "gui.blocklyText.sen0170.readWindspeed": "read pin [PIN] wind scale",
    "gui.blocklyText.sen0228.readAmbientLight": "read Ambient Light(VEML7700)",
    "gui.blocklyText.dfr0026.readAmbientLight": "read pin [PIN] Ambient light",
    "gui.blocklyText.sen0177.readDustSensor": "read [TYPE] (ug/m3) interface [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "pin(D) [PIN] pin(MCLR) [RST] current gesture [TYPE]?",
    "gui.blocklyText.sen0203.readHeartRate": "read pin [PIN] [TYPE] switch [SWITCH]",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] pin [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "read pin [PIN] grayscale",
    "gui.blocklyText.dfr0552.init": "DA module init I2C address [ADDR] voltage reference [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "DA module output triangular wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "DA module output sine wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "DA module output voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "DA module memory voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0576.selectPort": "Select port [PORT]",
    "gui.blocklyText.dfr0576.begin": "Initialization address [ADDR]",
    "gui.blocklyText.sen0248.readBME680": "Get the BME680 sensor [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "Temperature (°C)",
    "gui.blocklyText.sen0248.Pressure": "Pressure (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Humidity (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0248.GasResistance": "Résistance aux gaz (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "initialize the I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Change I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Read ultrasonic sensor distance(cm)",
    "gui.blocklyText.sen0304.readTemperature": "Read ultrasonic sensor temperature(°C)",
    "gui.blocklyText.sen0307.readDistance": "Read [PIN] analog ultrasonic wave(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 initialization address [ADDR] mode [MODE]",
    "gui.blocklyText.sen0250.Step": "Step",
    "gui.blocklyText.sen0250.Measure": "Measure",
    "gui.blocklyText.sen0250.readAccelerated": "BMI160 get the [AXIS] axis Acceleration(g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 get step number",
    "gui.blocklyText.sen0250.Gyro": "Gyro",
    "gui.blocklyText.sen0250.Acc": "Acceleration (g)",
    "gui.blocklyText.sen0224.readAccelerated": "Read acceleration value of LIS2DH sensor [AXIS] axis (mg)",
    "gui.blocklyText.dfr0023.readLM35": "read pin [PIN] LM35 temprature(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "when pin [PIN] detected vibration",
    "gui.blocklyText.dfr0028.readTiltSensor": "pin [PIN] detected tilt?",
    "gui.blocklyText.dfr0029.readPress": "pin [PIN] key button pressed?",
    "gui.blocklyText.dfr0030.readTouch": "pin [PIN] detected touch?",
    "gui.blocklyText.dfr0033.readMagnetic": "pin [PIN] detected magnet?",
    "gui.blocklyText.dfr0034.readSound": "read pin [PIN] Loudness",
    "gui.blocklyText.dfr0094.readIRReceived": "read pin [PIN] IR data",
    "gui.blocklyText.dfr0094.onReceiveEvent": "When the infrared signal is received Pin [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "address",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "command",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "read pin [PIN] Carbon Monoxide (CO) sensor",
    "gui.blocklyText.dfr0051.readVoltage": "read pin [PIN] Voltage(V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "read pin [PIN] Piezo Disk Vibration Sensor",
    "gui.blocklyText.dfr0058.readRotation": "read pin [PIN] Rotation Sensor",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "read pin [PIN] JoyStick [AXIS] Axis",
    "gui.blocklyText.dfr0061.readJoyStickButton": "pin [PIN] JoyStick Button(Z) pressed?",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut init data pin [DATAPIN] RTC pin [RTCPIN] cls pin [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Digital tube display segment code 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Digital tube display number [NUM]",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Read lithium battery [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "voltage(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "remainingBattery(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD module init I2C address [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD module read [PIN] voltage (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD module get aisle [AISLE] voltage difference",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 and 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 and 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 and 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 and 3",
    "gui.blocklyText.dfr0076.readFlameSensor": "read pin [PIN] Flame Sensor",
    "gui.blocklyText.dfr0143.readAccelerometer": "read pin [PIN] Triple Axis Accelerometer [AXIS] Axis",
    "gui.blocklyText.sen0018.readInfraredMotion": "read pin [PIN] Digital Infrared motion sensor",
    "gui.blocklyText.sen0014.readDistance": "read pin [PIN] Sharp IR Distance Sensor(mm)",
    "gui.blocklyText.sen0114.readMoisture": "read pin [PIN] Soil Moisture Sensor",
    "gui.blocklyText.sen0121.readSteam": "read pin [PIN] Steam Sensor",
    "gui.blocklyText.sen0204.readLiquidLevel": "pin [PIN] detected liquid level reached a certain height ?",
    "gui.blocklyText.sen0203.heartRate": "heart rate(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "blood oxygen saturation(HbO2)",
    "gui.blocklyText.sen0202.up": "Up",
    "gui.blocklyText.sen0202.down": "Down",
    "gui.blocklyText.sen0202.left": "Left",
    "gui.blocklyText.sen0202.right": "Right",
    "gui.blocklyText.sen0202.cw": "CW",
    "gui.blocklyText.sen0202.ccw": "CCW",
    "gui.blocklyText.dhtTHSensor.temperature": "temperature(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "moisture(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "pin [PIN]  [NUM1] to [NUM2] Leds show color [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "pin [PIN]  [NUM1] to [NUM2] Leds show gradient color  hue: [COLOR1] to [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "pin [PIN] shift pixels by [NUM]",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "pin [PIN] shift pixels cyclically by [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "pin [PIN]  [NUM] RGB lights",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "the number of RGB light pin [PIN] brightness [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "pin [PIN] clear",
    "gui.blocklyText.ws2812.showHistogram": "pin [PIN]  [NUM1] to [NUM2] Leds show histogram  current value: [VALUE]  max value: [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "pin [PIN]  LEDs brightness [BRIGHTNESS]",
    "gui.blocklyText.neopixel.init": "Lamp initialization name [NAME] pin [PIN] number [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Light strip [NAME] [INDEX] Color [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Light strip [NAME] [INDEX] Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.fillColor": "The light strip [NAME] is fully lit and the color is [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Light strip [NAME] All on Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.flush": "Light strip [NAME] settings take effect",
    "gui.blocklyText.neopixel.off": "Light strip [NAME] is off",
    "gui.blocklyText.neopixel.setBrightness": "Light strip [NAME] sets brightness to [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Rainbow light effect [NAME] Quantity [COUNT] Brightness [BRIGHTNESS] Offset [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7(mPython board)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9(mPython box)",
    "gui.blocklyText.dsTSensor.ds18b20": "Read pin [PIN] DS18B20 temperature(°C)",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "read value [TYPE] BMP280 sensor",
    "gui.blocklyText.bmpTSensor.atmosPressure": "barometric(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "altitude(m)",
    "gui.blocklyText.bmpTSensor.temperature": "temperature(℃)",
    "gui.blocklyText.LCD1602.initialIICAddr": "initialize I2C LCD screen address [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "display [TEXT] in LCD line [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "display [TEXT] at LCD position X: [X] Y: [Y] ",
    "gui.blocklyText.LCD1602.displayClear": "clear the screen ",
    "gui.blocklyText.IICScan.readICCAddr": "read scanned I2C device address",
    "gui.blocklyText.softSerialport.initialSerialport": "initial software serial [SERIALPORT] RX: [PINR] TX: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "set software serial [SERIALPORT] baud [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "software serial [SERIALPORT] [WAY] [TEXT] [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "Is there data to read on software serial [SERIALPORT]?",
    "gui.blocklyText.softSerialport.readSerialportData": "read data of software serial[SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "read [TYPE] on software serial [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "when bluetooth connect success",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "when bluetooth disconnect",
    "gui.blocklyText.bluetooth.receivedKey": "when bluetooth received key [KEY] [TYPE]",
    "gui.blocklyText.bluetooth.string": "String",
    "gui.blocklyText.bluetooth.number": "Number",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "when bluetooth received data and meet [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "send string key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "send number key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.openService": "open bluetooth service",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "received data from bluetooth",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo Starts",
    "gui.blocklyText.leonardo.setBaud": "set [SERIAL] baud rate [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.leonardo.serialPort1": "SerialPort0",
    "gui.blocklyText.leonardo.serialPort2": "SerialPort1",
    "gui.blocklyText.leonardo.dataReadable": "Is [SERIAL] data readable?",
    "gui.blocklyText.leonardo.readSerialData": "read [SERIAL] data",
    "gui.blocklyText.arduinounoR3.functionStarts": "uno kit program starts",
    "gui.blocklyText.arduinounoR3.readdigital": "read value pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "Digital Sensor",
    "gui.blocklyText.arduinounoR3.buttonModule": "Button Module",
    "gui.blocklyText.arduinounoR3.stickButton": "Rocker Button",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.arduinounoR3.readAnalog": "read value pin [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "Anolog Sensor",
    "gui.blocklyText.arduinounoR3.lightSensor": "Light Sensor",
    "gui.blocklyText.arduinounoR3.soundSensor": "Sound Sensor",
    "gui.blocklyText.arduinounoR3.stickDirection": "Rocker Direction",
    "gui.blocklyText.arduinounoR3.angleSensor": "Angle Sensor",
    "gui.blocklyText.arduinounoR3.graySensor": "Grayscale Sensor",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.arduinounoR3.open": "Turn on",
    "gui.blocklyText.arduinounoR3.close": "Turn off",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] [MODULE] of pin [PIN]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "Digital Actuator",
    "gui.blocklyText.arduinounoR3.ledLights": "LED Lights",
    "gui.blocklyText.arduinounoR3.fanModule": "Fan Module",
    "gui.blocklyText.arduinounoR3.recordModule": "Record Module",
    "gui.blocklyText.arduinounoR3.relay": "Relay",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "Lecture distance ultrasonore (cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.arduinounoR3.writeAnalog": "set value pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "move forward",
    "gui.blocklyText.arduinounoR3.backward": "move backward",
    "gui.blocklyText.arduinounoR3.turnLeft": "turn Left",
    "gui.blocklyText.arduinounoR3.turnRight": "turn Right",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "robot [DIR] by [SPEED] % speed ",
    "gui.blocklyText.arduinounoR3.motorForward": "move forward",
    "gui.blocklyText.arduinounoR3.motorReverse": "move backward",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "set motor [MOTOR] [DIR] by [SPEED] % speed ",
    "gui.blocklyText.arduinounoR3.stopMotor": "robot (MA and MB motors) stop moving",
    "gui.blocklyText.arduinounoR3.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "Infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "If infrared pin [PIN] received the pressed [BUTTON] signal？",
    "gui.blocklyText.arduinounoR3.noSound": "no sound",
    "gui.blocklyText.arduinounoR3.half": "Half",
    "gui.blocklyText.arduinounoR3.quarter": "Quarter",
    "gui.blocklyText.arduinounoR3.oneInEighth": "One-eighth",
    "gui.blocklyText.arduinounoR3.wholeBeat": "Whole Beat",
    "gui.blocklyText.arduinounoR3.doubleBeat": "Double Beat",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "set pin [PIN] trumpet tone [TONE] and beat [BEAT]",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "set pin [PIN] MP3 module volume to [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "set pin [PIN] MP3 module playing mode to [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "Stop",
    "gui.blocklyText.arduinounoR3.play": "Play",
    "gui.blocklyText.arduinounoR3.loop": "Loop",
    "gui.blocklyText.arduinounoR3.random": "Random",
    "gui.blocklyText.arduinounoR3.pause": "Pause",
    "gui.blocklyText.arduinounoR3.previous": "Previous",
    "gui.blocklyText.arduinounoR3.next": "Next",
    "gui.blocklyText.arduinounoR3.VolumePlus": "Volume+",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "Volume-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "pin [PIN] MP3 module to play the [NUM] song",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "lire broche [PIN] LM35 température (°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "read value pin [PIN] DHT11 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "red",
    "gui.blocklyText.arduinounoR3.blue": "blue",
    "gui.blocklyText.arduinounoR3.green": "green",
    "gui.blocklyText.arduinounoR3.readColorSensor": "read value TCS34725 color sensor [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "four-bit nixie tube TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "turn on",
    "gui.blocklyText.arduinounoR3.tm1650Close": "turn off",
    "gui.blocklyText.arduinounoR3.clearScreen": "clear Screen",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": "four-bit nixie tube TM1650 display string [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "the [ORDER] decimal point of 4-bit nixie tube TM1650 [STATUS]",
    "gui.blocklyText.arduinounoR3.bright": "brighten",
    "gui.blocklyText.arduinounoR3.dark": "darken",
    "gui.blocklyText.arduinounoR3.setLightColor": "pin [PIN] the [NUM] LED display [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "pin [PIN] the [NUM] LED R:[R] G:[G] B:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "pin [PIN] [NUM] LEDs",
    "gui.blocklyText.arduinounoR3.ledControl": "LED matrix (max7219) initial parameter DIN pin [DIN]，  CS pin [CS]，  CLK pin [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "LED matrix (max7219) show pattern [PIC]",
    "videoSensing.categoryName": "Video Sensing",
    "videoSensing.direction": "direction",
    "videoSensing.motion": "motion",
    "videoSensing.off": "off",
    "videoSensing.on": "on",
    "videoSensing.onFlipped": "on flipped",
    "videoSensing.setVideoTransparency": "set video transparency to [TRANSPARENCY]",
    "videoSensing.sprite": "sprite",
    "videoSensing.stage": "stage",
    "videoSensing.videoOn": "video [ATTRIBUTE] on [SUBJECT]",
    "videoSensing.videoToggle": "turn video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "when video motion > [REFERENCE]",
    "gui.blocklyText.max.maxStart": "Max starts",
    "gui.blocklyText.max.onboardButtonPressed": "Is onboard button [PRESSED]",
    "gui.blocklyText.max.pressed": "pressed",
    "gui.blocklyText.max.notPressed": "not pressed",
    "gui.blocklyText.max.all": "-1 ALL",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "set RGB lights [TYPE] color [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "read sound sensor value",
    "gui.blocklyText.max.playSoundEffect": "play [SOUND] sound effect",
    "gui.blocklyText.max.setDPinValue": "set digital pin [PIN] value to [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "read digital pin [PIN] value",
    "gui.blocklyText.max.readAPinValue": "read analog pin [PIN] value",
    "gui.blocklyText.max.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.max.readLinefindingSensor": "read line finding sensor [DIR] value",
    "gui.blocklyText.max.left": "Left",
    "gui.blocklyText.max.middle": "Middle",
    "gui.blocklyText.max.right": "Right",
    "gui.blocklyText.max.setLineFidingCarLights": "set line finding car lights [SWITCH]",
    "gui.blocklyText.max.on": "On",
    "gui.blocklyText.max.off": "Off",
    "gui.blocklyText.max.readUltrasoundSensor": "read ulrasonic sensor distance (cm)",
    "gui.blocklyText.max.readLightSensor": "read light sensor value [LEFTRIGHT]",
    "gui.blocklyText.max.maxSpeedMove": "MAX move by [SPEED] speed [DIR]",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] stop",
    "gui.blocklyText.max.showUserDefineExpressions": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.max.clear": "clear",
    "gui.blocklyText.max.do": "do",
    "gui.blocklyText.max.re": "re",
    "gui.blocklyText.max.mi": "mi",
    "gui.blocklyText.max.fa": "fa",
    "gui.blocklyText.max.sou": "sou",
    "gui.blocklyText.max.la": "la",
    "gui.blocklyText.max.xi": "xi",
    "gui.blocklyText.max.highdo": "highdo",
    "gui.blocklyText.max.brake": "brake",
    "gui.blocklyText.max.rebound": "rebound",
    "gui.blocklyText.max.bullets": "bullets",
    "gui.blocklyText.max.longecho": "long echo",
    "gui.blocklyText.max.playfulending": "playful ending",
    "gui.blocklyText.max.yo": "yo",
    "gui.blocklyText.max.wo": "wo",
    "gui.blocklyText.max.robottalking": "robot talking",
    "gui.blocklyText.knowflow.readORP": "read pin [PIN] ORP sensor (mV)",
    "gui.blocklyText.knowflow.readEC": "read pin [PIN] EC sensor (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "read pin [PIN] PH sensor(V1)",
    "gui.blocklyText.knowflow.readECV2": "read pin [PIN] EC sensor (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "read pin [PIN] PH sensor(V2)",
    "gui.blocklyText.knowflow.readDO": "read pin [PIN] DO sensor (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "set pin [PIN] Peristaltic Pump rotate [DIR] by [SPEED]% speed",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] pin [PIN] relay",
    "translate.translateBlock": "translate [WORDS] to [LANGUAGE]",
    "translate.defaultTextToTranslate": "hello",
    "translate.viewerLanguage": "viewer language",
    "gui.blocklyText.goble.readAxis": "read GOBLE rocker [AXIS] axis",
    "gui.blocklyText.goble.buttonIsPressed": "Is GOBLE [BUTTON] button pressed",
    "gui.blocklyText.tds.readAnalogPin": "read analog pin [PIN] TDS sensor(ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "two stepper motors run simultaneously   X axis : [XSET] y axis: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "stepper motor [AXIS] axis  [STEPS] steps per revolution  Speed(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "stepper motor moving steps [STEPS]  Direction [ROTATEDIR]",
    "gui.blocklyText.steppermotor.setJointSteps": "two stepper motors run simultaneously. Move [XSTEPS] steps on x-axis [ROTATEDIRX]  Move [YSTEPS] steps on y-axis [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] starts",
    "gui.blocklyText.multithread.stopThread": "stop [THREAD]",
    "gui.blocklyText.multithread.startThread": "start up [THREAD]",
    "gui.blocklyText.multithread.loop1": "thread 1",
    "gui.blocklyText.multithread.loop2": "thread 2",
    "gui.blocklyText.multithread.loop3": "thread 3",
    "gui.blocklyText.multithread.loop4": "thread 4",
    "gui.blocklyText.multithread.loop5": "thread 5",
    "gui.blocklyText.multithread.loop6": "thread 6",
    "gui.blocklyText.multithread.loop7": "thread 7",
    "gui.blocklyText.multithread.loop8": "thread 8",
    "gui.blocklyText.multithread.all": "all",
    "text2speech.speakAndWaitBlock": "speak [WORDS]",
    "text2speech.setVoiceBlock": "set voice to [VOICE]",
    "text2speech.setLanguageBlock": "set language to [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "squeak",
    "text2speech.giant": "giant",
    "text2speech.kitten": "kitten",
    "text2speech.defaultTextToSpeak": "bonjour",
    "gui.blocklyText.DFR0151.adjustTime": "Adjust the DS1307 time to [YEAR]Year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0151.getTime": "Gets the DS1307 time [INDEX]",
    "gui.blocklyText.DFR0151.Year": "Year",
    "gui.blocklyText.DFR0151.month": "month",
    "gui.blocklyText.DFR0151.date": "date",
    "gui.blocklyText.DFR0151.hour": "hour",
    "gui.blocklyText.DFR0151.minute": "minute",
    "gui.blocklyText.DFR0151.second": "second",
    "gui.blocklyText.DFR0151.week": "week",
    "gui.blocklyText.DFR0469.adjustTime": "Adjust the SD2405 time to [YEAR]Year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0469.getTime": "Gets the SD2405 time [INDEX]",
    "gui.blocklyText.DFR0126.setPin": "The audio analysis module  sets the pins #S[SPIN] #R[RPIN] Analog pin[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "The audio analysis module read sound frequency frequency band[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Initialize the NFC module interface I2C",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Initialize the NFC module interface hardware serial port Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "Initialize the serial port NFC module select [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "Initialize the serial port NFC module select [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "Serial port NFC module block [DATABLOCK] byte [BYTES] write [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "Read serial NFC module block [DATABLOCK] byte [BYTES] (number)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "Read serial NFC module block [DATABLOCK] all data (string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "Read serial NFC module block UID (srting)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "detected card UID: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "detected card?",
    "gui.blocklyText.DFR0126.serial1": "serial1",
    "gui.blocklyText.DFR0126.serial2": "serial2",
    "gui.blocklyText.DFR0126.serial3": "serial3",
    "gui.blocklyText.sen0245.setVL53L0X": "Set VL53L0X laser ranging module [PRECISION] number of measurement [VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "Obtain VL53L0X laser ranging value(mm)",
    "gui.blocklyText.SEN0245.LOW": "Low accuracy(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "High precision(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "Single",
    "gui.blocklyText.SEN0245.Continuous": "Continuous",
    "gui.blocklyText.serialMp3.initSerialMp3": "Init serial MP3 module interface [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "Control serial MP3 module [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "Control serial MP3 module player [VALUE]",
    "gui.blocklyText.serialMp3.setMP3Volume": "Serial MP3 module volume to [VOLUME]",
    "gui.blocklyText.serialMp3.play": "play",
    "gui.blocklyText.serialMp3.pause": "pause",
    "gui.blocklyText.serialMp3.end": "end",
    "gui.blocklyText.serialMp3.prev": "prev",
    "gui.blocklyText.serialMp3.next": "next",
    "gui.blocklyText.serialMp3.volumeUp": "volume up",
    "gui.blocklyText.serialMp3.volumeDown": "volume down",
    "gui.blocklyText.serialMp3.playlist": "playlist",
    "gui.blocklyText.serialMp3.insertTracks": "insert tracks",
    "gui.blocklyText.serialMp3.volume": "volume",
    "gui.blocklyText.servo360.setServo360": "set pin [PIN] as [SPEED] % speed [DIR]",
    "gui.blocklyText.servo360.CW": "CW",
    "gui.blocklyText.servo360.CCW": "CCW",
    "gui.blocklyText.TCS34725.readTcs34725": "Read the TCS34725 color sensor [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "Turn the GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "Don't turn GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "show text[TEXT] [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "set text [DIR] scroll show ",
    "gui.blocklyText.regEmotion8_16.left": "left",
    "gui.blocklyText.regEmotion8_16.right": "right",
    "gui.blocklyText.regEmotion8_16.lightAll": "show all dot matrix in [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "show coordinates of point (x:[XAXIS]，  y:[YXAXIS]) in [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "clean screen",
    "gui.mainHeader.err": "!Error: The program header [%1] can only be used at the same time. Please delete the duplicated module.",
    "gui.mainHeader.help": "!Help Tip: If multiple programs need to be executed at the same time， please use the \"multi-threading function\" in \"Extension\"， please refer to the help documentation.",
    "gui.threadHeader.err1": "!Error: The program header [%1] cannot be used at the same time. They belong to different kits or board.",
    "gui.threadHeader.err2": "!Error Tip: The event header [%1] can only be used at the same time. Please delete the duplicated module.",
    "gui.blocklyText.mqtt.whenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT message",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi connect account:[ACCOUNT] password:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi connected?",
    "gui.blocklyText.wifi.wifiDisConnected": "disconnect Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP mode:[ACCOUNT2] password:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi get soft ap ip",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi get [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Error code",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "when udp server recv [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "set udp server port [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "udp server send broadcast [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "when udp client recv [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "set udp client IP [IPADDR] port [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "udp client send broadcast [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "message",
    "gui.blocklyText.udp.ClientRecvedMsg": "message",
    "gui.blocklyText.mqtt.mqttConnect": "connect MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT connected?",
    "gui.blocklyText.mqtt.acceptance.meaage": "When [TOPIC] received the message as [MSG]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.mqtt.initial": "MQTT initial [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「IFLYTEK」synthetic audio [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「IFLYTEK」identify audio [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「IFLYTEK」identify result",
    "gui.blocklyText.iflytek.CommunicationText": "text",
    "gui.blocklyText.iflytek.CommunicationTransfer": "transfer to audio file",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "audio file to be identified",
    "gui.blocklyText.mqtt.mqttDisconnect": "disconnect MQTT",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 Reads Altitude(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 Reads Pressure(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 Reads Temperature(℃)",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 measurement [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "object",
    "gui.blocklyText.sen0206.environment": "environment",
    "gui.blocklyText.sen0206.celsius": "celsius",
    "gui.blocklyText.sen0206.fahrenheit": "fahrenheit",
    "gui.blocklyText.sen0253.init": "10DOF Initial pin I2C_ADDR level [LEVEL]",
    "gui.blocklyText.sen0253.reset": "10DOF Sensor reset",
    "gui.blocklyText.sen0253.power": "10DOF Set power mode [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF Get euler angles [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF Get qua [AXIS] axis",
    "gui.blocklyText.sen0253.getAcc": "10DOF Get accelerometer [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF Get magnetometer [AXIS] axis(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF Get linear acceleration [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF Get gravit capacity [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF Get angular speed [AXIS] axis",
    "gui.blocklyText.sen0253.getBmp280": "10DOF Get [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "Low",
    "gui.blocklyText.sen0253.HIGH": "High",
    "gui.blocklyText.sen0253.normal": "Normal",
    "gui.blocklyText.sen0253.lowPower": "Low power",
    "gui.blocklyText.sen0253.suspend": "Suspend",
    "gui.blocklyText.sen0253.Roll": "Roll(°)",
    "gui.blocklyText.sen0253.Pitch": "Pitch(°)",
    "gui.blocklyText.sen0253.Yaw": "Yaw(°)",
    "gui.blocklyText.sen0253.temperature": "Temperature",
    "gui.blocklyText.sen0253.elevation": "Elevation",
    "gui.blocklyText.sen0253.airPressure": "AirPressure",
    "gui.blocklyText.sen0290.init": "SEN0290 set irq pin [IRQPIN] and i2c address [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "SEN0290 lightning sensor iic init",
    "gui.blocklyText.sen0290.manualCal": "SEN0290 set capacitance [CAPACITANCE] mode [MODE] dist [DIST]",
    "gui.blocklyText.sen0290.indoors": "indoors",
    "gui.blocklyText.sen0290.outdoors": "outdoors",
    "gui.blocklyText.sen0290.disturberDis": "disturber disable",
    "gui.blocklyText.sen0290.disturberEn": "disturber enable",
    "gui.blocklyText.sen0290.sen0290WhenRecvErrMsg": "SEN0290 When receive [ErrMsg]",
    "gui.blocklyText.sen0290.errMsg": "error msg",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "SEN0290 get interrupt src",
    "gui.blocklyText.sen0290.GetLightningDistKm": "SEN0290get lightning dist(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "SEN0290 get strike energy raw",
    "gui.blocklyText.weather.weatherGetWithCity": "[WEATHER] in [CITY] city [COUNTRY] province",
    "gui.blocklyText.weather.temperaturHigh": "maximum temperature(℃)",
    "gui.blocklyText.weather.temperaturLow": "minimum temperature(℃)",
    "gui.blocklyText.weather.weather": "weather",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "set server parameter [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "update tag [TAG] value [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "read tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "delete tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "count tag",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "test connect",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "search tag from start number [NUMS] variable number [NUMV] tag [TAG] type [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "tag",
    "gui.blocklyText.tinywebdb.value": "value",
    "gui.blocklyText.tinywebdb.both": "both",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "HongKong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.whenIHear": "when heard [PHRASE]",
    "speech.listenAndWait": "listen and wait",
    "speech.getSpeech": "recognition result",
    "speech.setRecordTime": "set record time [TIME]",
    "speech.stopRecord": "stop speech recognition",
    "speech.controlSonogram": "[CONTROL] Sonogram",
    "speech.setServer": "Switch the voice recognition server to [SERVER]",
    "speech.defaultWhenIHearValue": "hello",
    "speech.show": "show",
    "speech.hide": "hide",
    "speech.server1": "server1",
    "speech.server2": "server2",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 can not be placed in the interrupt event, may cause dht11 reading abnormal",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 can not be placed in the interrupt event, may cause dht22 reading abnormal",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 cannot be placed in an interrupt event, which may result in abnormal pm2.5 readings",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 reads data, which may cause led dot matrix initialization failure",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 reads data, which may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "the mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to be set to fail",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "the mp3 setting playback mode cannot be placed in an interrupt event, which may cause the playback mode to fail",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "Mp3 settings playback can not be placed in the interrupt event, may cause the settings to play failed",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "Serial port mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "The serial port mp3 playback mode cannot be placed in an interrupt event, which may cause the setup play mode to be lost",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "Serial port mp3 playback cannot be placed in an interrupt event, which may cause playback to fail",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "The serial port mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to fail",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloq's mqtt initialization soft serial port cannot be placed in an interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq sends a message that cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq read mqtt message can not be placed in the interrupt event, may cause the reception to fail",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "The initial soft serial port of http of obloq cannot be placed in the interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "The get request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "Obloq http post request cannot be placed in an interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "The put request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "Infrared reception cannot be placed in an interrupt event, which may cause reception failure",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "Infrared reception may cause the LED dot matrix initialization to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "Infrared reception may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "Infrared transmission cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause initialization to fail",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause setup pins to fail",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the pin to fail to fail",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause display color to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause color adjustment failure",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the move to fail",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause loop movement to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause the display histogram to fail",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "Led dot matrix can not be placed in the interrupt event, may cause initialization failure",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "The led dot matrix cannot be placed in an interrupt event, which may cause the display pattern to fail",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "The soft serial output cannot be placed in an interrupt event, which may cause the output to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "The data read by the soft serial port cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Soft serial port output data and read data colleague use, may cause data abnormalities",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "The soft serial port read integer cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "Soft serial port output data and reading integer colleague use may cause abnormal reading data",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "When dht11 and PM2.5 are in two threads, dht11 reads may fail if pm2.5 reads too frequently. It is recommended to reduce the pm2.5 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "When dht11 and ds18b20 are in two threads respectively, if ds18b20 reads too frequently, dht11 may fail to read, it is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "When dht11 and mp3 are initialized in two threads, mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "When dht11 and mp3 set volume are in two threads respectively, mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "When dht11 and mp3 setup modes are in two threads respectively, mp3 setup mode may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "When dht11 and mp3 player are in two threads, mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "When dht11 and serial mp3 are initialized in two threads, serial mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "When dht11 and serial mp3 control module are in two threads respectively, the mp3 control module may cause dht11 reading errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "When dht11 and serial mp3 player are in two threads, serial mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "When dht11 and serial mp3 set volume are in two threads respectively, serial mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "When dht11 and RGB are initialized in two threads, RGB initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "When dht11 and RGB set brightness in two threads, RGB set brightness may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "When dht11 and RGB are extinguished in two threads, RGB extinction may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "When dht11 and RGB display colors in two threads, RGB display colors may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "When dht11 and RGB display a gradient in two threads, respectively, RGB display a gradient may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "When dht11 and RGB move pixels in two threads, RGB move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "When dht11 and RGB loop move pixels in two threads, respectively, RGB loop move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "When dht11 and RGB display columns in two threads respectively, RGB display bars may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "When dht11 and soft serial send are in two threads respectively, soft serial send may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "When dht11 and obloq MQTT are initialized in two threads respectively, obloq MQTT initialization may cause dht11 to read errors. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "When dht11 and obloq MQTT send messages in two threads respectively, obloq MQTT send messages that may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "When dht11 and obloq HTTP put requests are in two threads, it can cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "When dht11 and obloq HTTP initialization are in two threads respectively, it may cause dht11 reading error. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "When dht11 and obloq HTTP get requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "When dht11 and obloq HTTP post requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "When dht11 and infrared emission are in two threads respectively, it may cause errors in reading dht11. It is suggested to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "When ds18b20 and PM2.5 are in two threads respectively, it may cause PM2.5 reading error, so it is suggested to reduce ds18b20 frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "When ds18b20 and obloq MQTT are initialized in two threads respectively, MQTT initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "When ds18b20 and obloq HQTT put requests are in two threads respectively, the put request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "When ds18b20 and obloq HTTP initialization are in two threads respectively, the HTTP initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "When ds18b20 and obloq HQTT get requests are in two threads respectively, the get request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "When ds18b20 and obloq HQTT post requests are in two threads respectively, the post request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Reading ds18b20 may result in no infrared data, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Reading ds18b20 may cause errors in receiving data by soft serial port, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "When PM2.5 read and obloq MQTT initialization are carried out in two threads respectively, MQTT initialization failure or PM2.5 read error may be caused. It is recommended to avoid simultaneous execution of MQTT initialization and PM2.5 read",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "When PM2.5 reading and obloq MQTT send messages in two threads respectively, PM2.5 reading errors may be caused. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "When PM2.5 read and obloq HTTP put requests are in two threads, it may cause put request failure or PM2.5 read error. It is recommended to reduce put request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "When PM2.5 reads and obloq HTTP initializations are carried out in two threads respectively, HTTP initializations may fail or PM2.5 reads may go wrong. It is recommended to avoid simultaneous execution of HTTP initializations and PM2.5 reads",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "When PM2.5 read and obloq HTTP get requests are in two threads respectively, it may cause get request failure or PM2.5 read error. It is recommended to reduce get request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "When PM2.5 read and obloq HTTP post request are in two threads respectively, it may cause post request failure or PM2.5 read error. It is recommended to reduce the frequency of post request or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "When PM2.5 reading and MP3 initialization are carried out in two threads respectively, PM2.5 reading may fail. It is recommended to avoid the simultaneous execution of PM2.5 reading and MP3 initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "When PM2.5 reading and MP3 setting volume are in two threads respectively, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "When PM2.5 reading and serial MP3 player are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "When PM2.5 reading and serial MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "When PM2.5 reading and serial MP3 setting volume are respectively in two threads, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "When PM2.5 reading may cause infrared reception failure, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "When PM2.5 reading and RGB initialization are carried out in two threads respectively, PM2.5 reading errors may be caused. It is recommended to avoid simultaneous execution of PM2.5 reading and RGB initialization",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "When PM2.5 reading and RGB setting brightness are respectively in two threads, PM2.5 reading error may be caused. It is recommended not to adjust brightness too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "When PM2.5 reading and RGB extinguishing are in two threads, PM2.5 reading errors may be caused. It is recommended not to extinguish RGB too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "When PM2.5 reading and RGB display colors are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to switch colors too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "When PM2.5 reading and RGB display the gradient in two threads respectively, PM2.5 reading error may be caused, it is recommended not to switch the gradient too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "When PM2.5 reading and RGB moving pixels are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to move pixels too frequently",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to be initialized abnormally",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 initialization to be abnormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "Using Wi-Fi may cause serial port mp3 to play track failed",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to play tracks failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "Using Wi-Fi may cause the serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "Using Wi-Fi may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp uses Wi-Fi, which may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "When PM2.5 read and RGB loop move pixels in two threads respectively, PM2.5 read errors may be caused. It is recommended not to execute loop move pixels too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "When PM2.5 reading and RGB display columns are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to execute RGB display columns too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "When PM2.5 reading and led dot array initialization are carried out in two threads respectively, led dot array initialization may go wrong. It is recommended to avoid the simultaneous execution of PM2.5 reading and led dot array initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "When PM2.5 reading and led dot array display are in two threads respectively, led dot array display may be wrong, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5 reading may cause errors in PM2.5 reading. It is recommended not to send data too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5 reading may cause soft serial port reading failure. It is recommended not to read PM2.5 too frequently",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 may not receive infrared data, it is recommended to reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "When MP3 and obloq MQTT initialization is in two threads, it may cause MQTT initialization to fail. It is recommended to avoid simultaneous MQTT initialization and MP3 initialization",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "When MP3 and obloq HTTP put requests are in two threads, it is possible to cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "When MP3 and obloq HTTP initializations are in two threads, HTTP initializations may fail. It is recommended to avoid both HTTP initializations and MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "When MP3 and obloq HTTP get requests are in two threads, it may cause get requests to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "When MP3 and obloq HTTP post requests are in two threads, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 may cause soft serial port read failure, it is recommended not to use MP3 too often",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "Serial MP3 may not receive infrared data, it is recommended to reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "When serial port MP3 and obloq MQTT initialization are in two threads respectively, MQTT initialization may fail. It is recommended to avoid simultaneous execution of MQTT initialization and serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "When serial MP3 and obloq HTTP put requests are in two threads, it may cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "HTTP initialization may fail when serial MP3 and obloq are initialized in two threads. It is recommended to avoid both HTTP initialization and serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "When the serial port MP3 and obloq HTTP get requests are in two threads respectively, it may cause the get request to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "When the serial port MP3 and obloq HTTP post requests are in two threads respectively, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of the serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "Serial port MP3 may cause soft serial port read failure, it is recommended not to use serial port MP3 too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving errors, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "Soft serial obloq may cause errors in infrared receiving data, so it is recommended to avoid sending messages frequently by obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "The initialization of RGB band may lead to the error of obloq receiving messages. It is recommended to avoid frequent use of RGB band",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Setting the brightness of the light belt may cause an error in obloq receiving messages. It is recommended to avoid frequent brightness adjustment",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB belt extinguish may lead to soft serial obloq receiving messages error, it is recommended to avoid frequent extinguish the belt",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB light band display may cause obloq to receive messages error, it is recommended to avoid frequent display of color",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "The display of gradient on the RGB band may cause errors in obloq receiving messages. It is recommended to avoid frequent display of gradient",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB band moving pixels may cause soft serial obloq to receive messages incorrectly",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "The RGB band loops the pixels around and may cause the soft serial obloq to receive messages out",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB ribbon display bar may cause soft serial obloq receive message error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "When soft serial obloq and led dot array initialization are carried out in two threads respectively, it may cause led dot array initialization error. It is recommended to avoid simultaneous execution of soft serial obloq and led dot array initialization",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "When soft serial obloq and led dot array display are respectively in two threads, it may cause led dot array display error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "Soft serial port sending may cause obloq reading error, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "Soft serial port obloq send may cause soft serial port read failure, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "Infrared emission may cause infrared reception of no data, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "When infrared emission may cause soft serial port reading failure, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "The initialization of RGB band may result in no infrared reception of data",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "The brightness setting of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent brightness setting of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "The RGB lamp belt is off, which may cause the infrared data can not be received",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "The moving pixel of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent moving pixel of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "Infrared reception may cause led initialization failure",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "Infrared reception may cause led display errors",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "Soft serial port sending may cause infrared data can not receive, it is recommended to avoid frequent sending soft serial port",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB band may cause soft serial port reading failure, so it is recommended to reduce the use frequency of RGB band",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Data received by soft serial port may cause led dot matrix display error",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "The sending of soft serial port may lead to the error of receiving data of soft serial port. It is recommended to avoid the execution of sending data and receiving data at the same time",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "The initialization of serial port MP3 may lead to errors in infrared receiving data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "MP3 serial port setting playing mode may cause errors in infrared receiving data, so it is recommended to avoid setting playing mode too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "Serial MP3 player may lead to infrared data errors, it is recommended to avoid switching songs too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "The volume setting of serial port MP3 may cause errors in infrared receiving data. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "Initialization of max7219 may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "Max7219 display images may cause errors in infrared received data, so it is recommended to avoid displaying images too frequently",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "The MP3 module of serial port may cause the pin interrupt cannot be triggered. It is recommended to avoid using MP3 module of serial port too often",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "The max7219 display module may cause pin interrupts not to be triggered. It is recommended to avoid using the max7219 display too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "DHT reading may lead to errors in infrared receiving messages. It is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "Ds18b20 reading may cause errors in soft external receiving messages, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5 reading may cause errors in receiving messages outside the software. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "Mp3 initialization read may cause infrared receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "The volume setting of mp3 may cause the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "Mp3 setting mode may cause errors in infrared receiving messages. It is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "Mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "Setting mode of serial port mp3 may cause error of infrared receiving message, it is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "Serial mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Setting the volume of serial port mp3 may lead to the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "Infrared emission may cause errors in infrared receiving messages, so it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "The initialization of the RGB band may lead to errors in receiving infrared messages. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving infrared messages. It is recommended to avoid setting the brightness too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB band display may cause errors in infrared receiving messages. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "Gradient discoloration of RGB lamp band display may lead to error of infrared receiving message. It is recommended to avoid displaying gradient discoloration too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Moving pixels with RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid moving pixels too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "The circular movement of pixels with the RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB ribbon display columnar may cause infrared receiving message error, it is recommended to avoid displaying columnar too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Infrared receiving messages may cause initialization failure of max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Infrared receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "Soft serial printing may cause errors in infrared receiving messages, so it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "Soft serial port obloq MQTT initialization may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "Soft serial obloq put request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "Soft serial port obloq HTTP initialization may lead to infrared receiving data errors, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "Soft serial obloq get request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "Soft serial obloq post request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "The clear display of RGB lamp band may cause errors in receiving messages in the soft infrared. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "DHT reading may lead to soft serial receiving message error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "Ds18b20 reading may cause the soft serial port to receive the message error, it is recommended to reduce the ds18b20 reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5 reading may cause errors in receiving messages by soft serial port. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "Mp3 initialization reads may result in a soft serial receiving message error, and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Setting the volume of mp3 may cause the error of receiving the message on the soft serial port. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "Mp3 setting mode may cause the soft serial port to receive the message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "Mp3 playback may lead to soft serial receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "Mp3 setting mode of serial port may cause error of receiving messages by soft serial port. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "Mp3 playing on serial port may cause errors in receiving messages on soft serial port. It is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message from soft serial port. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "Infrared emission may cause errors in receiving messages by soft serial port. It is recommended to avoid sending infrared messages too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "The initialization of the RGB band may result in an error in receiving messages from the soft serial port. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving messages from the soft serial port. It is recommended to avoid setting the brightness too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "Clear display of RGB light band may cause error of receiving message by soft serial port. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB band display may cause errors in receiving messages from the soft serial port. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB lamp tape display gradient may cause message error in soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause the soft serial port to receive messages incorrectly. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB light belt circular movement of pixels may cause the soft serial port to receive messages error, it is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB ribbon display columnar may cause the soft serial port to receive the message error, it is recommended to avoid performing display columnar too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "Soft serial receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "Soft serial receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "Soft serial printing may cause error in receiving messages from the soft serial port. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "DHT reads may cause pin interrupt triggering failure. It is recommended to reduce the DHT read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "Ds18b20 read may cause pin interrupt trigger failure, it is recommended to reduce ds18b20 read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5 reading may cause pin interrupt trigger failure. It is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Mp3 initialization reads may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "Mp3 setting volume may cause pin interrupt trigger failure. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "Mp3 setting mode may cause pin interrupt triggering failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "Mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "Serial port mp3 initialization read may cause pin interrupt trigger failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "Setting mode of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "Serial mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Setting the volume of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "Infrared emission may cause pin interrupt trigger failure, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB ribbon initialization may cause pin interrupt trigger failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Setting brightness of RGB band may cause pin interrupt trigger failure. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB strip clear display may cause pin interrupt trigger failure, it is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB tape display may cause pin interrupt trigger failure. It is recommended to avoid RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "The RGB tape display of the gradient may cause the pin interrupt trigger to fail. It is recommended to avoid displaying the gradient too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB band moving pixels may cause pin interrupt triggering failure and it is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB light belt loop move pixel may cause pin interrupt trigger failure, it is recommended to avoid too often loop move pixel",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB ribbon display bar may cause pin interrupt trigger failure. It is recommended to avoid displaying bar too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "Pin interruption may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "Pin interruption may cause max7219 to display incorrectly",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "Soft serial printing may cause pin interrupt trigger failure, it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "Soft serial port obloq MQTT initialization may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "Soft serial obloq put requests may cause pin interrupt triggering failure and it is recommended to avoid requesting data too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "Soft serial obloq HTTP initialization may cause pin interrupt triggering failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "Soft serial obloq get request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "Soft serial obloq post request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "DHT reading may lead to soft serial obloq receiving messages error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "Ds18b20 reading may cause obloq to receive messages incorrectly, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5 reading may cause error in obloq receiving messages. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "Mp3 initialization read may result in soft serial obloq receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Mp3 setting volume may result in soft serial obloq receiving message error. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "Mp3 setting mode may lead to soft serial obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "Mp3 playback may lead to soft serial obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port obloq receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "Serial port mp3 setting mode may cause soft serial port obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "Serial port mp3 player may lead to soft serial port obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message of soft serial port obloq. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving messages error, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Soft serial bloq sending messages may lead to infrared reception errors, it is recommended to avoid obloq sending messages too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB ribbon initialization may cause soft serial obloq to receive an error message, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Setting brightness of RGB light belt may cause error of obloq receiving messages. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Clear display of RGB light band may cause error of obloq receiving message. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB tape display may result in an error in obloq receiving messages. It is recommended to avoid performing RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB lamp tape display gradient may cause message error in obloq soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause an error in obloq receiving messages. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB light belt circular movement of pixels may lead to soft serial port obloq receive messages error, it is recommended to avoid too often loop movement of pixels",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB ribbon display columnar may cause soft serial obloq receive messages error, it is recommended to avoid too often display columnar",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "Soft serial obloq receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "Soft serial obloq receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "Soft serial printing may cause obloq to receive messages incorrectly. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "Soft serial port obloq sending data may cause errors in receiving data by soft serial port. It is recommended to avoid too frequent sending of data by obloq",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "Soft serial obloq sending data may cause pin interrupt cannot be triggered, it is recommended to avoid obloq sending data too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "Soft serial port obloq MQTT initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "Soft serial obloq put request may cause the soft serial port to receive data error, it is recommended to avoid too frequent requests for data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "Soft serial port obloq HTTP initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "Soft serial obloq get request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "Soft serial port obloq post request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.blockTitle.eventBlock": "programme de l'événement",
    "gui.blocklyText.blockTitle.dotScreen": "écran à points",
    "gui.blocklyText.blockTitle.music": "la musique",
    "gui.blocklyText.blockTitle.onBoardSensor": "capteur du conseil",
    "gui.blocklyText.blockTitle.pinOperation": "opération de la goupille",
    "gui.blocklyText.blockTitle.wirelessCommunication": "Communication sans fil",
    "gui.blocklyText.blockTitle.serialportOperation": "opération serialport",
    "gui.blocklyText.blockTitle.systemResource": "ressource système",
    "gui.blocklyText.blockTitle.screenDisplay": "écran d'affichage",
    "gui.blocklyText.blockTitle.ledControl": "led de contrôle",
    "gui.blocklyText.blockTitle.functionalModule": "module fonctionnel",
    "gui.blocklyText.blockTitle.movement": "mouvement",
    "gui.blocklyText.blockTitle.expression": "expression",
    "gui.blocklyText.blockTitle.light": "lumière",
    "gui.blocklyText.blockTitle.dance": "Danse",
    "gui.blocklyText.blockTitle.sensor": "capteur",
    "gui.blocklyText.blockTitle.motorControl": "contrôle moteur",
    "gui.blocklyText.blockTitle.kitSensor": "kit capteur",
    "gui.blocklyText.blockTitle.kitActuator": "kit actionneur",
    "gui.blocklyText.blockTitle.commonModule": "module commun",
    "gui.blocklyText.blockTitle.infraredCommunication": "communication infrarouge",
    "gui.blocklyText.blockTitle.mp3Module": "module mp3",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 lumière RVB",
    "gui.blocklyText.blockTitle.LCDDisplay": "affichage LCD",
    "gui.blocklyText.blockTitle.digitalTube": "tube numérique",
    "gui.blocklyText.blockTitle.oledDisplay": "Écran OLED",
    "gui.blocklyText.blockTitle.onBoardSource": "ressource du conseil",
    "gui.blocklyText.blockTitle.rgbControl": "Contrôle de la lumière RVB",
    "gui.blocklyText.blockTitle.maxbotResource": "ressource max: bot",
    "gui.blocklyText.blockTitle.lineInspection": "inspection en ligne",
    "gui.blocklyText.blockTitle.outerSensor": "capteur externe",
    "gui.blocklyText.blockTitle.consolePrint": "sortie de la console",
    "gui.blocklyText.esp32.touchPinAll": "All",
    "gui.blocklyText.esp32.setTouchThreshold": "La valeur tactile du bouton de réglage [PIN] est [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "Touche de lecture [PIN] valeur tactile",
    "gui.blocklyText.esp32.rgbDisable": "Désactiver le voyant [INDEX]",
    "gui.blocklyText.esp32.rgbdisable.all": "All",
    "gui.blocklyText.esp32.rgbdisable.No.0": "No.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "No.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "No.2",
    "gui.blocklyText.mega2560.originalOutput": "sortie originale",
    "gui.blocklyText.microbit.true": "enable",
    "gui.blocklyText.microbit.false": "désactiver",
    "gui.blocklyText.oled2864.displayInit": "Adresse d'initialisation d'affichage OLED [ADDR]",
    "gui.blocklyText.sen0228.init": "Initialiser le capteur de lumière ambiante (VEML7700)",
    "gui.blocklyText.motucamera.initIICSerial": "mu camera init [MU] interface [IICSERIAL] jusqu'à la réussite de l'init",
    "gui.blocklyText.motucamera.resetConfig": "mu camera [MU] réinitialisé",
    "gui.blocklyText.motucamera.algorithmSwitch": "algorithme mu camera [MU] [SWITCH] [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "mu camera [MU] [LED] lors de la détection de [COLOR1] lors de la détection de [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "caméra mu [MU] régler le niveau d'algorithme [ALGORITHM] [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "mu camera [MU] zoom numérique [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "mu camera [MU] régler le débit en bauds [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "mu camera [MU] règle la balance des blancs [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "caméra mu [MU] mode FPS élevé [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "mu camera [MU] détecte [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": "mu camera [MU] détecte la reconnaissance des couleurs x = [NUMX] y = [NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "mu camera [MU] détecte couleur détecte couleur = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "mon appareil photo obtient [MU] algorithme [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "mu camera [MU] couleur reconnaît [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "mu camera [MU] Carte de forme [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "Carte de circulation mu camera [MU] [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "ma carte numérique [MU] de l'appareil photo [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "mu camera [MU] couleur reconnaître couleur = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "Détecter les couleurs",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "Couleur reconnaître",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "Détection de balle",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "Détecter le corps",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "Carte de forme",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "Carte de circulation",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "Numéro de carte",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "enable",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "désactiver",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "white",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "white",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "Auto",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "La vitesse",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "Équilibre",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "Précision",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "Auto",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "Fermer à clé",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "white",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "yellow",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "Auto",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "Niveau 1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "Niveau 2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "Niveau 3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "Niveau 4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "Niveau 5",
    "gui.blocklyText.motucamera.setHighFPSOff": "OFF",
    "gui.blocklyText.motucamera.setHighFPSOn": "ON",
    "gui.blocklyText.motucamera.colorDetectBlack": "black",
    "gui.blocklyText.motucamera.colorDetectWhite": "white",
    "gui.blocklyText.motucamera.colorDetectRed": "red",
    "gui.blocklyText.motucamera.colorDetectYellow": "yellow",
    "gui.blocklyText.motucamera.colorDetectGreem": "green",
    "gui.blocklyText.motucamera.colorDetectCayan": "cayan",
    "gui.blocklyText.motucamera.colorDetectBlue": "blue",
    "gui.blocklyText.motucamera.colorDetectPerple": "perple",
    "gui.blocklyText.motucamera.colorDetectOthers": "others",
    "gui.blocklyText.motucamera.colorDetectPositionX": "x position",
    "gui.blocklyText.motucamera.colorDetectPositionY": "la position y",
    "gui.blocklyText.motucamera.colorDetectPositionW": "width",
    "gui.blocklyText.motucamera.colorDetectPositionH": "la taille",
    "gui.blocklyText.motucamera.colorDetectPositionL": "étiquette",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "Circuit Rouge",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "Circuit Vert",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "canal bleu",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "canal de l'étiquette",
    "gui.blocklyText.motucamera.shapeCardTick": "tick",
    "gui.blocklyText.motucamera.shapeCardCross": "cross",
    "gui.blocklyText.motucamera.shapeCardCircle": "circle",
    "gui.blocklyText.motucamera.shapeCardSquare": "square",
    "gui.blocklyText.motucamera.shapeCardTriangle": "Triangle",
    "gui.blocklyText.motucamera.trafficCardForward": "Vers l'avant",
    "gui.blocklyText.motucamera.trafficCardLeft": "La gauche",
    "gui.blocklyText.motucamera.trafficCardRight": "Right",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "Tourner autour",
    "gui.blocklyText.motucamera.trafficCardStop": "Arrêtez",
    "gui.blocklyText.sen0117.init": "Initialiser l'interface du module [SERIALPORT] Rx [RX] Tx [TX] Occupé [BUSY]",
    "gui.blocklyText.sen0117.playText": "Lit le texte [TEXT] musique de fond [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "Lit l'invite vocale [INDEX] en attente [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "Joue les invites d'accord [INDEX] en attente [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "Configurez [INDEX] sur [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "Réglage du débit de la parole [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "Obtenir le module de synthèse vocale [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "Volume de perspectives",
    "gui.blocklyText.sen0117.Background": "Volume de fond",
    "gui.blocklyText.sen0117.Speed": "La vitesse",
    "gui.blocklyText.sen0117.playChordCueTone": "Reproduit les sonneries d'accord [INDEX] en attente [WAIT]",
    "gui.blocklyText.sen0117.No": "Non (0)",
    "gui.blocklyText.sen0117.setPlayMode": "Réglez le mode de lecture sur [MODE]",
    "gui.blocklyText.sen0117.pause": "Pause",
    "gui.blocklyText.sen0117.resume": "Resume",
    "gui.blocklyText.sen0117.stop": "Arrêtez",
    "gui.blocklyText.sen0117.isIdle": "Jouer terminer?",
    "gui.blocklyText.sen0248.bme680Init": "BME680 s'initialise",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO = 1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO = 0)",
    "gui.blocklyText.sen0224.init": "Initialiser le capteur LIS2DH",
    "gui.blocklyText.dfr0072.dfr0072Dot": "Affichage numérique du tube dot [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "true",
    "gui.blocklyText.dfr0072.dotFalse": "false",
    "gui.blocklyText.dfr0072.numOff": "off",
    "gui.blocklyText.dfr0588.initPin": "Tige température init. [TEM] Tige humidité [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "obtenir l'unité de température [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "obtenir de l'humidité (% rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "CELSIUS",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "FAHRENHEIT",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom get [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom lire le nom du texte [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "nom de numéro de lecture eeprom i2c [KEY]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom init adresse i2c [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom write text [VALUE] nom [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom write number [VALUE] nom [KEY]",
    "gui.blocklyText.dfr0117.text": "Text",
    "gui.blocklyText.dfr0117.number": "Nombre",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom delete name [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom serial affiche tous les noms baud [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "Nom eeprom i2c [KEY] existe-t-il?",
    "gui.blocklyText.dfr0117.eepromFormat": "format eeprom i2c",
    "gui.blocklyText.dfr0117.eepromGetType": "Nom eeprom i2c [KEY] est [TYPE]?",
    "gui.blocklyText.dfr0117.nameCount": "Nom compte",
    "gui.blocklyText.dfr0117.memorySize": "Espace de stockage (octet)",
    "gui.blocklyText.dfr0117.residualMemorySize": "Espace de stockage restant (octet)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280 s'initialise",
    "gui.blocklyText.sen0236.bme280Init": "BME280 s'initialise",
    "gui.blocklyText.sen0236.readbme280Sensor": "Lire le capteur BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "Température (℃)",
    "gui.blocklyText.sen0236.Humidity": "Humidité (% rh)",
    "gui.blocklyText.sen0236.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0236.Pressure": "Pression (Pa)",
    "gui.blocklyText.sdcard.initCS": "Initialisation de la broche de film SD [CSPIN] réussie?",
    "gui.blocklyText.sdcard.readFileNLine": "SD lit le fichier [FILE] ligne [LINE]",
    "gui.blocklyText.sdcard.readFileAll": "SD lit le fichier [FILE] tout le contenu",
    "gui.blocklyText.sdcard.writeFile": "Fichier d'écriture SD [FILE] contenu [MSG] mode [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD delete file [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD get file [FILE] info [INFO]",
    "gui.blocklyText.sdcard.NAME": "NAME",
    "gui.blocklyText.sdcard.PATH": "CHEMIN",
    "gui.blocklyText.sdcard.TYPE": "TYPE",
    "gui.blocklyText.sdcard.LINE": "LINE",
    "gui.blocklyText.sdcard.SIZE": "TAILLE",
    "gui.blocklyText.sdcard.APPEND": "AJOUTER",
    "gui.blocklyText.sdcard.REPLACE": "REMPLACER",
    "gui.blocklyText.sdcard.WARP": "CHAÎNE",
    "gui.blocklyText.sdcard.NOWARP": "NOWRAP",
    "gui.blocklyText.sdcard.lsFile": "Le port série SD imprime toutes les informations de fichier en bauds [BAUD]",
    "gui.blocklyText.arduinounoR3.initTcs34725": "Initialise le capteur de couleur TCS34725",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "Initialise l'affichage TMI1650",
    "gui.blocklyText.sen0160.init": "Définissez la valeur d'étalonnage du capteur de poids Hx711 [VALUE] avec la broche Dout [DOUT] Broche SCK [SCK]",
    "gui.blocklyText.sen0160.readValue": "Lire le capteur de poids Hx711 (g) Broche Dout [DOUT] Broche SCK [SCK]",
    "gui.blocklyText.eeprom.clear": "eeprom clair",
    "gui.blocklyText.eeprom.read": "eeprom lu depuis l'adresse [ADDR]",
    "gui.blocklyText.eeprom.write": "eeprom écrire à l'adresse [ADDR] avec les données [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom obtenir crc de l'adresse [ADDRA] à l'adresse [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom obtenir la longueur",
    "gui.blocklyText.DFR0151.init": "Initialisation DS1307",
    "gui.blocklyText.DFR0469.init": "Initialisation SD2405",
    "gui.blocklyText.sen0245.init": "Initialiser le capteur de télémétrie laser VL53L0X",
    "gui.blocklyText.TCS34725.initTcs34725": "Initialise le capteur de couleur TCS34725",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] ajouter clé [KEY] valeur [VALUE]",
    "gui.blocklyText.http.requestHeader": "En-tête de requête HTTP add [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] URL [URL] délai d'attente [TIMEOUT] ms",
    "gui.blocklyText.http.readMessage": "Message de ligne HTTP read [LINE]",
    "gui.blocklyText.http.param": "PARAM",
    "gui.blocklyText.http.header": "ENTÊTE",
    "gui.blocklyText.http.oneline": "ONE",
    "gui.blocklyText.http.allline": "ALL",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 s'initialise",
    "gui.blocklyText.sen0291.sen0291Init": "Le wattmètre initialise l'adresse I2C en tant que [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "Le wattmètre lit la charge [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "Le courant réel mesuré de l’étalonnage du wattmètre est [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "Tension (mV)",
    "gui.blocklyText.sen0291.electric": "Électrique (mA)",
    "gui.blocklyText.sen0291.power": "Puissance (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "Tension de shunt (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.mp3.initMp3": "Interface du module DFPlayer MP3 Init [SERIALPORT] Rx [RX] Tx [TX]",
    "gui.blocklyText.mp3.setMP3Volume": "régler le volume du module DFPlayer MP3 sur [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "régler le mode de lecture du module DFPlayer MP3 sur [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "régler le module DFPlayer MP3 en cours de lecture de la chanson [NUM]",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "set pin [PIN] DFPlayer MP3 module volume to [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "set pin [PIN] DFPlayer MP3 module playing mode to [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "pin [PIN] DFPlayer MP3 module play the [NUM] song",
    "gui.blocklyText.tel0094.GPSInit": "Le GPS s’initialise en tant que [SERIALPORT] Rx [RX] Tx [TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "Le GPS obtient la position [LATLON] (°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "Le GPS obtient la position [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "Longitude",
    "gui.blocklyText.tel0094.LONGITUDE": "Latitude",
    "gui.blocklyText.tel0094.YEAR": "Year",
    "gui.blocklyText.tel0094.MONTH": "Month",
    "gui.blocklyText.tel0094.DAY": "journée",
    "gui.blocklyText.tel0094.HOUR": "Hour",
    "gui.blocklyText.tel0094.MINUTE": "Minute",
    "gui.blocklyText.tel0094.SECOND": "Second",
    "gui.blocklyText.tel0094.ALL": "All",
    "gui.blocklyText.tel0094.GPSGet": "GPS obtient [GPS]",
    "gui.blocklyText.tel0094.getState": "Status",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "Hémisphère Latitude",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "Hémisphère de longitude",
    "gui.blocklyText.tel0094.getGroundSpeed": "Vitesse au sol",
    "gui.blocklyText.tel0094.getSatellitesCount": "Nombre de satellites",
    "gui.blocklyText.tel0094.getAltitude": "Altitude",
    "gui.blocklyText.tel0094.getPdop": "Précision de la position",
    "gui.blocklyText.tel0094.getHdop": "Précision horizontale",
    "gui.blocklyText.tel0094.getVdop": "Précision verticale",
    "gui.blocklyText.tel0094.getPositioningMode": "Mode de positionnement",
    "gui.blocklyText.microIoT.displayInLine": "Ligne d'affichage OLED [LINE] texte [TEXT]",
    "gui.blocklyText.microIoT.displayInXY": "Spectacle OLED [TEXT] x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "Image d'exposition OLED [IMAGE] x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED clair",
    "gui.blocklyText.microIoT.pointDrawing": "OLED dessine le point x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED définit la largeur de ligne [LINEWIDTH]",
    "gui.blocklyText.microIoT.lineDrawing": "Ligne de tracé OLED début x [X1] y [Y1] fin x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED dessine un cercle [FILL] centre du cercle x [X] y [Y] rayon [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "Boîte de dessin OLED [FILL] début x [X] y [Y] largeur [WIDTH] hauteur [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RVB régler la luminosité sur [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "Les lumières RVB sont toutes éteintes",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "Les lumières RVB [NUM1] à [NUM2] indiquent les couleurs [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "Les voyants RVB [NUM1] à [NUM2] indiquent les dégradés de teinte de [COLOR1] à [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "Boucle RVB par pixel [NUM]",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "Les lumières RVB [NUM1] à [NUM2] indiquent les couleurs [COLOR]",
    "gui.blocklyText.motorIot.all": "ALL",
    "gui.blocklyText.microIot.servo": "Angle servo [INDEX] [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "Paramètre initial MQTT [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT envoie un message [MSG] à la plateforme cloud [TOPIC]",
    "gui.blocklyText.microIot.MQTTMsg": "Message MQTT",
    "gui.blocklyText.microTouch.whenBtnPress": "lorsque les touches [KEY] appuyez sur",
    "gui.blocklyText.microTouch.Any": "Any",
    "gui.blocklyText.microTouch.isButtonPressed": "Les touches [KEY] sont enfoncées?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "Touches de lecture en mode normal",
    "gui.blocklyText.microTouch.readKeyMathMode": "Lecture en mode mathématique [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "Touche numérique (num)",
    "gui.blocklyText.microTouch.Function": "Touche de fonction (str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] moteur de vibration",
    "gui.blocklyText.microTouch.turnOn": "Allumer",
    "gui.blocklyText.microTouch.shutDown": "Fermer",
    "gui.blocklyText.micoiot.coordinateDisplay": "RVB Définir le point de matrice X [X] Y [Y] afficher les couleurs [COLOR]",
    "gui.blocklyText.compatible.shapedBlock": "La nouvelle version change de point: le bloc de construction du programme d'événements avec ses propres variables est remplacé par le bloc de construction du programme d'événements sans variables.",
    "gui.blocklyText.compatible.shapedBlock.variable": "Le nouveau point de changement de version: passage du bloc de création de programme indépendant à un bloc de construction de programme variable dans le programme de l'événement",
    "gui.blocklyText.compatible.booleanToRound": "Nouveau point de changement de version: Passé du bloc de construction de programme conditionnel à un bloc de base de programme numérique.",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "La nouvelle version change de point: ajoutez une option de menu déroulant de commutateur de port série souple et matériel dans le bloc de construction du programme.",
    "gui.blocklyText.compatible.mp3.deletePin": "Nouveau point de changement de version: Supprimez l'option de liste déroulante des broches dans le bloc de construction du programme.",
    "gui.blocklyText.compatible.sen0206.addDropdown": "Nouveau point de changement de version: Ajoutez des options déroulantes au bloc de construction du programme.",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "Nouveau point de changement de version: augmentez l'option de liste déroulante des broches dans le bloc de construction du programme.",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "Nouveau point de changement de version: modifiez l'option de liste déroulante du port série dans le bloc de construction du programme.",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "La nouvelle version change de point: elle est passée du bloc de construction du programme d'événements au bloc de construction du programme conditionnel.",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "La nouvelle version change de point: supprimer le bloc",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "La nouvelle version change de point: modifiez la liste déroulante carrée en une liste déroulante circulaire",
    "gui.blocklyText.pictureai.initToken": "init AI de l'image [SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "obtenir une image d'un fichier local [IMAGE]",
    "gui.blocklyText.pictureai.getVideoImage": "obtenir un raccourci vidéo",
    "gui.blocklyText.pictureai.webPicture": "obtenir une image du site web [TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH] enregistrer le raccourci de l'appareil photo en local [TEXT]",
    "gui.blocklyText.pictureai.face": "identifier l'image [TEXT] contient le visage",
    "gui.blocklyText.pictureai.word": "identifier l'image [TEXT] contient [SELECT]",
    "gui.blocklyText.pictureai.picture": "identifier l'image [TEXT] contient [SELECT]",
    "gui.blocklyText.pictureai.gesture": "identifier l'image [TEXT] contient le geste",
    "gui.blocklyText.pictureai.TOPIC_W_A": "mots",
    "gui.blocklyText.pictureai.TOPIC_W_B": "number",
    "gui.blocklyText.pictureai.TOPIC_W_C": "numéro d'immatriculation",
    "gui.blocklyText.pictureai.TOPIC_W_D": "écriture",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "Gauche",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "oreille gauche",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "coude gauche",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "oeil gauche",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "hanche gauche",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "genou gauche",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "coin de la bouche gauche",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "épaule gauche",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "poignet gauche",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "neck",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "nose",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "cheville droite",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "oreille droite",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "coude droit",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "l'œil droit",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "hanche droite",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "genou droit",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "coin de la bouche droite",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "épaule droite",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "poignet droit",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "tête supérieure",
    "gui.blocklyText.pictureai.initUserToken": "basculer vers un compte séparé [MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "identifier l'image [TEXT] contient le point du corps",
    "gui.blocklyText.pictureai.faceContrast": "contraste de visage dans l'image [TEXT] avec l'image [TEXT2]",
    "gui.blocklyText.pictureai.faceMerge": "fusionner le visage dans l'image [TEXT] et l'image [TEXT2]",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "corps de l'image",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "objets et scènes d'usage général",
    "gui.blocklyText.pictureai.TOPIC_P_A": "plant",
    "gui.blocklyText.pictureai.TOPIC_P_B": "animal",
    "gui.blocklyText.pictureai.TOPIC_P_C": "fleur",
    "gui.blocklyText.pictureai.TOPIC_P_D": "des légumes",
    "gui.blocklyText.pictureai.bodyPointSuccess": "Identification réussie des points clés du corps humain?",
    "gui.blocklyText.pictureai.bodyPointInfo": "obtenir les coordonnées BodyPoint [BODY] [XY]",
    "gui.blocklyText.pictureai.noPower": "Veuillez vérifier si le compte a ouvert la fonctionnalité.",
    "gui.blocklyText.pictureai.pictureError": "l'image n'est pas au bon format",
    "gui.blocklyText.pictureai.noPicture": "s'il vous plaît sélectionnez d'abord l'image",
    "gui.blocklyText.pictureai.noVideo": "Assurez-vous que la caméra est correctement connectée et qu'aucun autre logiciel n'est occupé, puis allumez la caméra ou redémarrez le logiciel.",
    "gui.blocklyText.pictureai.videoContainer": "utiliser [CON] afficher l'écran de la caméra",
    "gui.blocklyText.pictureai.TOPIC_stage": "stage",
    "gui.blocklyText.pictureai.TOPIC_popup": "apparaitre",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "Nouveau point de changement de version: des blocs de construction de programme ont été supprimés.",
    "gui.blocklyText.pictureai.gestureList": "geste [TEXT]",
    "gui.blocklyText.pictureai.networkError": "Erreur réseau, veuillez vérifier la connexion réseau",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "Reconnaissance faciale réussie?",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "résultats de reconnaissance faciale [TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "visage engourdi",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "âge",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "beauté",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "Le sexe HOMME ou FEMME",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "glasses",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "type",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "expression",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "probabilité de visage",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "la gauche",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "top",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "width",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "la taille",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "point de repère",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "occlusion",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "brouiller",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "complétude",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "Humain",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "dessin animé",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Forward",
    "gui.blocklyText.maqueenPlus.Backward": "Backward",
    "gui.blocklyText.maqueenPlus.left": "Left",
    "gui.blocklyText.maqueenPlus.right": "Right",
    "gui.blocklyText.maqueenPlus.all": "All",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "set motor [DIR] move by [SPEED] speed [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "set motor [DIR] stop",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] speed compensation [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "read [DIR] motor speed",
    "gui.blocklyText.maqueenPlus.readMoterDir": "read [DIR] motor direction (0:stop 1:forward 2:back)",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "set [DIR] RGB show [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "read the sensation sensor [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "read the sensation sensor [INDEX] grayscale value",
    "gui.blocklyText.maqueenPlus.getVersion": "get version",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "read ultrasonic sensor trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "when received [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "infrared data",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "paint.paintEditor.hue": "Couleur",
    "paint.paintEditor.saturation": "Saturation",
    "paint.paintEditor.brightness": "Luminosité",
    "gui.comingSoon.message1": "Ne t'inquiète pas, on y travaille {emoji}",
    "gui.comingSoon.message2": "Ça arrive bientôt...",
    "gui.comingSoon.message3": "Nous y travaillons {emoji}",
    "paint.paintEditor.fill": "Remplissage",
    "paint.paintEditor.costume": "Costume",
    "paint.paintEditor.group": "Grouper",
    "paint.paintEditor.ungroup": "Dégrouper",
    "paint.paintEditor.undo": "Restaurer",
    "paint.paintEditor.redo": "Refaire",
    "paint.paintEditor.forward": "Avancer",
    "paint.paintEditor.backward": "Reculer",
    "paint.paintEditor.front": "Avant-plan",
    "paint.paintEditor.back": "Arrière-plan",
    "paint.paintEditor.more": "Plus",
    "paint.modeTools.brushSize": "Taille",
    "paint.modeTools.eraserSize": "Taille de l'effaceur",
    "paint.modeTools.copy": "Copier",
    "paint.modeTools.paste": "Coller",
    "paint.modeTools.delete": "Supprimer",
    "paint.modeTools.curved": "Courbé",
    "paint.modeTools.pointed": "Pointu",
    "paint.modeTools.thickness": "Épaisseur",
    "paint.modeTools.flipHorizontal": "Retourner horizontalement",
    "paint.modeTools.flipVertical": "Retourner verticalement",
    "paint.modeTools.filled": "Plein",
    "paint.modeTools.outlined": "Contours",
    "paint.paintEditor.bitmap": "Convertir en bitmap",
    "paint.paintEditor.vector": "Convertir en Vecteur",
    "paint.paintEditor.stroke": "Contour",
    "paint.brushMode.brush": "Pinceau",
    "paint.eraserMode.eraser": "Gomme",
    "paint.fillMode.fill": "Remplissage",
    "paint.lineMode.line": "Ligne",
    "paint.ovalMode.oval": "Cercle",
    "paint.rectMode.rect": "Rectangle",
    "paint.reshapeMode.reshape": "Redessiner",
    "paint.roundedRectMode.roundedRect": "Rectangle arrondi",
    "paint.selectMode.select": "Sélectionner",
    "paint.textMode.text": "Texte",
    "paint.colorPicker.swap": "Inverser",
    "gui.dialog.notConnectDevice": "Aucun appareil connecté， veuillez sélectionner l'appareil à connecter dans le menu",
    "gui.dialog.prompt": "Prompt",
    "gui.dialog.openPort": "Port ouvert",
    "gui.dialog.error": "Erreur",
    "gui.dialog.notSelectDevice": "Aucun périphérique n'est sélectionné， veuillez cliquer sur \"Extension\" dans le coin inférieur gauche du logiciel， </br> cliquez pour sélectionner le périphérique que vous souhaitez connecter",
    "gui.dialog.connectDeviceError1": "Connecter un appareil (",
    "gui.dialog.connectDeviceError2": ") a échoué， Veuillez vérifier les points suivants:</br> *Remplacez le port USB et rebranchez le câble USB </br> *Vérifiez s'il est connecté",
    "gui.dialog.connectDeviceError3": "Panneau de configuration principal </br> *Rejoignez Official Communications Group (671877416) Vos commentaires",
    "gui.dialog.calibration1": "</br> *s'il vous plaît tourner",
    "gui.dialog.calibration2": "Tableau de commande principal pour l'étalonnage </br>",
    "gui.dialog.runAsManager": "Please close Mind+ and right click on the software icon and select \"Run as administrator\". After starting, select this function again.",
    "gui.dialog.runErrorForVortex": "Le système ne fonctionne pas correctement， quittez l'établissement de liaison Vortex",
    "gui.dialog.runError": "Le système ne fonctionne pas normalement",
    "gui.dialog.close": "Fermer",
    "gui.dialog.variableNameSpecialCharacters": "Le nom de variable %1 ne peut pas contenir de caractères spéciaux: [\\` ~! @ # $% ^ & *() + <> ?: \" {} ，. /; \\ '[] \\] ",
    "gui.dialog.funcNameSpecialCharacters": "Le nom de fonction %1 ne peut pas contenir de caractères spéciaux: [\\` ~! @ # $% ^ & *() + <> ?: \" {} ，. /; \\ '[] \\] ",
    "gui.dialog.funcArgsSpecialCharacters": "Les arguments de la fonction %1 ne peuvent pas contenir de caractères spéciaux: [\\` ~! @ # $% ^ & *() + <> ?: \" {} ，. /; \\ '[] \\] ",
    "gui.dialog.and": "et",
    "gui.dialog.yes": "Oui",
    "gui.dialog.no": "Non",
    "gui.dialog.maxReset": "Indique s'il faut supprimer le code de zone d'édition et charger le code de réglage d'usine",
    "gui.dialog.feedbackCannotEmpty": "Commentaires / Retours Ne peut être vide!",
    "gui.dialog.noInstallCompiler": "Compilateur non installé",
    "gui.dialog.install": "installer ligne",
    "gui.dialog.cancle": "annuler",
    "gui.dialog.installingCompiler": "installation du compilateur",
    "gui.dialog.uploading": "Téléchargement",
    "gui.dialog.changeLanuage": "Changer de langue effacera le programme en cours continuera-t-il?",
    "gui.dialog.boardNotSupportMpy": "Conseil actuel: %1 ne prend pas en charge le micopython",
    "gui.dialog.loadBlankItem": "L'élément non pris en charge </br> recharge un élément vide",
    "gui.dialog.switchBaudError": "erreur de commutation du débit en bauds </br>",
    "gui.dialog.turnMicrobitCompass": "Need to calibrate the electronic compass, please adjust the attitude of mciro: bit to move the blinking LED lights until all LED lights are on",
    "gui.dialog.operationShow": "Démonstration opérationnelle",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "ordinateur",
    "gui.dialog.delete": "effacer",
    "gui.dialog.sureDeleteFile": "Êtes-vous sûr de vouloir supprimer le fichier \" %2\" \"sur %1?\"",
    "gui.dialog.sureDeleteFolder": "Êtes-vous sûr de vouloir supprimer le dossier \" %2\" \"sur %1?\"",
    "gui.dialog.uncorrectPath": "n'a pas choisi le bon chemin",
    "gui.dialog.laterDownLoad": "Télécharger plus tard",
    "gui.dialog.downLoadUpdate": "Download Update",
    "gui.dialog.dwnLoadWebsite": "Télécharger",
    "gui.dialog.laterUpdate": "Mettre à jour plus tard",
    "gui.dialog.update": "Mettre à jour",
    "gui.dialog.getUpdateMsgError": "Erreur lors de la mise à jour ",
    "gui.dialog.ok": "Ok",
    "gui.dialog.closeCodeBoxes": "Êtes-vous sûr de vouloir fermer les boîtes de code %1?",
    "gui.dialog.emailError": "Le format de l'e-mail est incorrect!",
    "gui.dialog.emailEmpty": "L'e-mail ne peut pas être vide!",
    "gui.dialog.bigFilePrompt": "Le fichier est un peu gros， s'il vous plaît soyez patient， merci",
    "gui.dialog.successFeedbackPrompt1": "Merci pour vos commentaires， la page se rechargera au bout de 3 secondes.",
    "gui.dialog.successFeedbackPrompt2": "Vos commentaires ont été soumis. Merci de les soumettre en quelques secondes.",
    "gui.dialog.failedFeedbackPrompt": "Nous sommes désolés de vous envoyer vos commentaires. Nous sommes également désolés pour le désagrément causé. Vous pouvez également ajouter le groupe d'échange officiel QQ pour vos commentaires， merci",
    "gui.dialog.successFeedbackPrompt3": "Merci pour votre avis",
    "gui.dialog.forcedCloseSoftWare": "Une erreur est survenue lors de l'enregistrement du fichier. Forcer la fermeture du logiciel?",
    "gui.dialog.saveSuccess": "Le projet est enregistré",
    "gui.dialog.noSelectTruePath": "n'a pas sélectionné le bon chemin",
    "gui.dialog.newItemContent": "Vous allez créer un projet vierge pour écraser le projet actuel. Êtes-vous sûr de le créer?",
    "gui.dialog.deleteBlocksError": "Échec de la suppression de %1 bloc ，, supprimez-le manuellement",
    "gui.dialog.netIsConnect": "S'il vous plaît vérifier si le réseau est connecté",
    "gui.dialog.needToUpdate": "Dois-je mettre à jour le compilateur vers la dernière version: %1",
    "gui.dialog.cantUseMpythonInArduinoC": "Aucun compilateur installé, vous ne pouvez pas utiliser mpython sous Arduino C.Si l'ordinateur ne dispose pas d'un réseau, vous pouvez télécharger le 'package de plug-in hors ligne du compilateur' sur le site officiel.",
    "gui.dialog.enterMpyMode": "entrer en mode micropython",
    "gui.dialog.runHardware": "Connectez d'abord le périphérique pour manipuler le matériel",
    "gui.dialog.sureDelete": "Êtes-vous sûr de vouloir supprimer ceci?",
    "gui.dialog.notSupportWebGL": "Une erreur s'est produite lors du chargement de l'étape. Elle ne prend peut-être pas en charge WebGL. </br> Voici un message d'erreur détaillé:</br> %1 </br> Le groupe QQ nous a contactés: 671877416",
    "gui.dialog.boardIsnotMPython": "La carte de contrôle principale de connexion actuelle n'est pas mPython， veuillez vous connecter à mPython",
    "gui.dialog.swdMicrobitUpload": "Votre ordinateur doit utiliser le SWD pour graver micro: bit ，, exécutez Mind + avec les privilèges de l'administrateur.",
    "gui.dialog.openPortError": "erreur de port ouvert %1: %2",
    "gui.dialog.pressedA": "S'il vous plaît appuyez et maintenez le bouton A， ne pas relâcher",
    "gui.dialog.unpressedA": "S'il vous plaît unpressed A",
    "gui.dialog.unsupportProject": "L'élément non pris en charge </br> recharge un élément vide",
    "gui.dialog.openProjectError": "Les projets en mode Scratch doivent être ouverts en mode scratch </br>. Veuillez vérifier si l'interface actuelle est en mode scratch ou en mode arduino",
    "gui.dialog.installCompilerError1": "Le compilateur n'a pas pu télécharger vous pouvez télécharger le programme d'installation hors connexion ou réessayer de télécharger",
    "gui.dialog.installCompilerOffline": "Installer le compilateur hors ligne",
    "gui.dialog.installCompilerError2": "Erreur de vérification， vous pouvez télécharger le programme d'installation hors connexion ou réessayer de télécharger",
    "gui.dialog.installCompilerError3": "Erreur d'installation vous pouvez télécharger le programme d'installation hors connexion ou réessayer de télécharger",
    "gui.dialog.installCompilerSuccess": "Le compilateur a été installé, vous pouvez utiliser Esp32 sous ArduinoC",
    "gui.dialog.sureAdapter": "L'adaptateur n'a pas été trouvé, veuillez confirmer si l'adaptateur est connecté",
    "gui.dialog.checkPort": "Échec d'ouverture du port série ，, veuillez vérifier si le port série est occupé.",
    "gui.dialog.changeBoard": "Changer de tableau effacera le programme en cours. Êtes-vous sûr de vouloir continuer?",
    "gui.crashMessage.somethingWrong": "Oups! Quelque chose s'est mal passé.",
    "gui.crashMessage.reload": "Recharger",
    "gui.crashMessage.submit": "Soumettre",
    "gui.crashMessage.attachment": "Attachement",
    "gui.crashMessage.errFeedbackNote": "Vous pouvez écrire des messages d'erreur ou des suggestions ici， Mind + s'améliorera avec votre participation.",
    "gui.crashMessage.promptContent": "Nous sommes vraiment désolés， Désolé, il semble que Mind + se soit écrasé. Vous pouvez envoyer cette erreur à l'équipe Mind + située à droite merci beaucoup! Veuillez actualiser votre page pour réessayer.",
    "gui.dialog.isSaveProject": "Que ce soit pour enregistrer le projet actuel?",
    "gui.dialog.save": "Sauvegarder",
    "gui.dialog.notSave": "Ne pas enregistrer ",
    "gui.dialog.variableIsNull": "Variable name cannot be empty",
    "gui.dialog.listNameIsNull": "list name cannot be empty",
    "gui.dialog.pictureAINotNull": "cannot be null",
    "gui.dialog.pictureAIInitServer": "please initialize the server first",
    "gui.dialog.pictureAIInitServerError": "Error initializing the server, please check the account number and secret key",
    "gui.dialog.versionPrompt": "The current project version「%1」",
    "gui.dialog.versionGreater": "The project version is higher than the current software version, project version 「%1」",
    "BACKDROPS_ARCTIC": "Arctique",
    "BACKDROPS_BASEBALL": "Base-ball",
    "BACKDROPS_BASEBALL_2": "Baseball 2",
    "BACKDROPS_BASKETBALL": "Basketball",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Plage Malibu",
    "BACKDROPS_BEACH_RIO": "Plage rio",
    "BACKDROPS_BEDROOM": "Chambre",
    "BACKDROPS_BEDROOM_2": "Chambre 2",
    "BACKDROPS_BEDROOM_3": "Chambre 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Banc avec vue",
    "BACKDROPS_BLUE_SKY": "Ciel bleu",
    "BACKDROPS_BLUE_SKY_2": "Ciel bleu 2",
    "BACKDROPS_BLUE_SKY3": "Ciel bleu3",
    "BACKDROPS_BOARDWALK": "Promenade",
    "BACKDROPS_CANYON": "Canyon",
    "BACKDROPS_CASTLE_1": "Château 1",
    "BACKDROPS_CASTLE_2": "Château 2",
    "BACKDROPS_CASTLE_3": "Château 3",
    "BACKDROPS_CASTLE_4": "Château 4",
    "BACKDROPS_CHALKBOARD": "Tableau",
    "BACKDROPS_CIRCLES": "Cercles",
    "BACKDROPS_CITY_WITH_WATER": "Ville avec eau",
    "BACKDROPS_COLORFUL_CITY": "Ville colorée",
    "BACKDROPS_CONCERT": "Concert",
    "BACKDROPS_DESERT": "Désert",
    "BACKDROPS_FARM": "Ferme",
    "BACKDROPS_FIELD_AT_MIT": "Field At Mit",
    "BACKDROPS_FLOWERS": "Fleurs",
    "BACKDROPS_FOREST": "Forêt",
    "BACKDROPS_GALAXY": "Galaxie",
    "BACKDROPS_GARDEN-ROCK": "Jardin-roche",
    "BACKDROPS_GREEK_THEATER": "Théâtre grec",
    "BACKDROPS_HALL": "Salle",
    "BACKDROPS_HAY_FIELD": "Champ de foin",
    "BACKDROPS_GRAFFITI": "Graffiti",
    "BACKDROPS_HEARTS1": "Coeurs1",
    "BACKDROPS_HEARTS2": "Coeurs2",
    "BACKDROPS_HILL": "Colline",
    "BACKDROPS_JUNGLE": "Jungle",
    "BACKDROPS_JURASSIC": "Jurassique",
    "BACKDROPS_LIGHT": "Lumière",
    "BACKDROPS_METRO": "Métro",
    "BACKDROPS_MOON": "Lune",
    "BACKDROPS_MOUNTAIN": "Montagne",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "Nébuleuse",
    "BACKDROPS_NEON_TUNNEL": "Tunnel de néon",
    "BACKDROPS_NIGHT_CITY": "Ville de nuit",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Ville de nuit avec rue",
    "BACKDROPS_PARTY": "Fête",
    "BACKDROPS_PATHWAY": "Sentier",
    "BACKDROPS_PARTY_ROOM": "Salle de fête",
    "BACKDROPS_PLAYGROUND": "Cour de récréation",
    "BACKDROPS_PLAYING_FIELD": "Terrain de jeu",
    "BACKDROPS_POOL": "Bassin",
    "BACKDROPS_PURPLE": "Violet",
    "BACKDROPS_RAYS": "Des rayons",
    "BACKDROPS_REFRIGERATOR": "Réfrigérateur",
    "BACKDROPS_ROOM_1": "Chambre 1",
    "BACKDROPS_ROOM_2": "Chambre 2",
    "BACKDROPS_SAVANNA": "Savane",
    "BACKDROPS_SCHOOL": "École",
    "BACKDROPS_SLOPES": "Pistes",
    "BACKDROPS_SOCCER": "Football",
    "BACKDROPS_SOCCER_2": "Football 2",
    "BACKDROPS_SPACE": "Espace",
    "BACKDROPS_SPACE_2": "Espace 2",
    "BACKDROPS_SPACE_3": "Espace 3",
    "BACKDROPS_SPACE_4": "Espace 4",
    "BACKDROPS_SPOTLIGHT-STAGE": "Spotlight-stage",
    "BACKDROPS_STARS": "Étoiles",
    "BACKDROPS_STRIPES": "Rayures",
    "BACKDROPS_THEATER": "Théâtre",
    "BACKDROPS_THEATER_2": "Théâtre 2",
    "BACKDROPS_TREE": "Arbre",
    "BACKDROPS_UNDERWATER_1": "Sous l'eau 1",
    "BACKDROPS_UNDERWATER_2": "Sous l'eau 2",
    "BACKDROPS_URBAN": "Urbain",
    "BACKDROPS_WALL_1": "Mur 1",
    "BACKDROPS_WALL_2": "Mur 2",
    "BACKDROPS_WATER_AND_ROCKS": "Eau et rochers",
    "BACKDROPS_WETLAND": "Zone humide",
    "BACKDROPS_WINTER": "Hiver",
    "BACKDROPS_WINTER-LIGHTS": "Lumières d'hiver",
    "BACKDROPS_WITCH_HOUSE": "La maison de la sorcière",
    "BACKDROPS_WOODS": "Forêt",
    "BACKDROPS_WOODS_AND_BENCH": "Bois et banc",
    "BACKDROPS_XY-GRID": "Grille Xy",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "abby-a",
    "COSTUMES_ABBY-B": "abby-b",
    "COSTUMES_ABBY-C": "abby-c",
    "COSTUMES_ABBY-D": "abby-d",
    "COSTUMES_AMON": "amon",
    "COSTUMES_ANDIE-A": "andie-a",
    "COSTUMES_ANDIE-B": "andie-b",
    "COSTUMES_ANDIE-C": "andie-c",
    "COSTUMES_ANDIE-D": "Andie-D",
    "COSTUMES_ANINA_POP_DOWN": "anina pop down",
    "COSTUMES_ANINA_POP_FRONT": "anina pop front",
    "COSTUMES_ANINA_POP_L_ARM": "anina pop L bras",
    "COSTUMES_ANINA_POP_LEFT": "anina pop à gauche",
    "COSTUMES_ANINA_POP_R_ARM": "anina pop R bras",
    "COSTUMES_ANINA_POP_RIGHT": "anina pop right",
    "COSTUMES_ANINA_POP_STAND": "anina pop stand",
    "COSTUMES_ANINA_R_CROSS": "anina r cross",
    "COSTUMES_ANINA_STANCE": "Anina position",
    "COSTUMES_ANINA_TOP_FREEZE": "anina top gel",
    "COSTUMES_ANINA_TOP_L_STEP": "anina top L step",
    "COSTUMES_ANINA_TOP_R_STEP": "anina top R step",
    "COSTUMES_ANINA_TOP_STAND": "anina top stand",
    "COSTUMES_APPLE": "Pomme",
    "COSTUMES_ARROW1-A": "flèche1-a",
    "COSTUMES_ARROW1-B": "flèche1-b",
    "COSTUMES_ARROW1-C": "flèche1-c",
    "COSTUMES_ARROW1-D": "flèche1-d",
    "COSTUMES_AVERY_WALKING-A": "avery walking-a",
    "COSTUMES_AVERY_WALKING-B": "avery walking-b",
    "COSTUMES_AVERY_WALKING-C": "avery walking-c",
    "COSTUMES_AVERY_WALKING-D": "avery walking-d",
    "COSTUMES_AVERY-A": "avery-a",
    "COSTUMES_AVERY-B": "avery-b",
    "COSTUMES_BALL-A": "balle-a",
    "COSTUMES_BALL-B": "ball-b",
    "COSTUMES_BALL-C": "ball-c",
    "COSTUMES_BALL-D": "balle-d",
    "COSTUMES_BALL-E": "ball-e",
    "COSTUMES_BALLERINA-A": "ballerine-a",
    "COSTUMES_BALLERINA-B": "ballerine-b",
    "COSTUMES_BALLERINA-C": "ballerine-c",
    "COSTUMES_BALLERINA-D": "ballerine-d",
    "COSTUMES_BALLOON1-A": "ballon1-a",
    "COSTUMES_BALLOON1-B": "ballon1-b",
    "COSTUMES_BALLOON1-C": "ballon1-c",
    "COSTUMES_BANANAS": "bananes",
    "COSTUMES_BASEBALL": "base-ball",
    "COSTUMES_BASKETBALL": "basketball",
    "COSTUMES_BAT-A": "Vampire-a",
    "COSTUMES_BAT-B": "Vampire-b",
    "COSTUMES_BAT-C": "Vampire-c",
    "COSTUMES_BAT-D": "Vampire-d",
    "COSTUMES_BATTER-A": "pâte a",
    "COSTUMES_BATTER-B": "bat-b",
    "COSTUMES_BATTER-C": "pâte-c",
    "COSTUMES_BATTER-D": "bat-d",
    "COSTUMES_BEACHBALL": "ballon de plage",
    "COSTUMES_BEAR-A": "ours-a",
    "COSTUMES_BEAR-B": "ours-b",
    "COSTUMES_BEAR-WALK-A": "ours-promenade-a",
    "COSTUMES_BEAR-WALK-B": "ours se promene-b",
    "COSTUMES_BEAR-WALK-C": "ours se promene-c",
    "COSTUMES_BEAR-WALK-D": "ours se promene-d",
    "COSTUMES_BEAR-WALK-E": "ours se promene-e",
    "COSTUMES_BEAR-WALK-F": "ours se promene-f",
    "COSTUMES_BEAR-WALK-G": "ours se promene-g",
    "COSTUMES_BEAR-WALK-H": "ours se promene-h",
    "COSTUMES_BEETLE": "scarabée",
    "COSTUMES_BELL1": "bell1",
    "COSTUMES_BEN-A": "ben-a",
    "COSTUMES_BEN-B": "ben-b",
    "COSTUMES_BEN-C": "ben-c",
    "COSTUMES_BEN-D": "ben-d",
    "COSTUMES_BOWL-A": "bol-a",
    "COSTUMES_BOWTIE": "nœud papillon",
    "COSTUMES_BREAD": "pain",
    "COSTUMES_BROOM": "balai",
    "COSTUMES_BUILDING-A": "bâtiment-a",
    "COSTUMES_BUILDING-B": "bâtiment-b",
    "COSTUMES_BUILDING-C": "bâtiment-c",
    "COSTUMES_BUILDING-D": "bâtiment-d",
    "COSTUMES_BUILDING-E": "bâtiment-e",
    "COSTUMES_BUILDING-F": "bâtiment-f",
    "COSTUMES_BUILDING-G": "bâtiment-g",
    "COSTUMES_BUILDING-H": "bâtiment-h",
    "COSTUMES_BUILDING-I": "bâtiment-i",
    "COSTUMES_BUILDING-J": "bâtiment-j",
    "COSTUMES_BUTTERFLY1-A": "papillon1-a",
    "COSTUMES_BUTTERFLY1-B": "papillon1-b",
    "COSTUMES_BUTTERFLY1-C": "papillon1-c",
    "COSTUMES_BUTTERFLY2-A": "papillon2-a",
    "COSTUMES_BUTTERFLY2-B": "papillon2-b",
    "COSTUMES_BUTTON1": "bouton1",
    "COSTUMES_BUTTON2-A": "bouton2-a",
    "COSTUMES_BUTTON2-B": "bouton2-b",
    "COSTUMES_BUTTON3-A": "bouton3-a",
    "COSTUMES_BUTTON3-B": "bouton3-b",
    "COSTUMES_BUTTON4-A": "bouton4-a",
    "COSTUMES_BUTTON4-B": "bouton4-b",
    "COSTUMES_BUTTON5-A": "bouton5-a",
    "COSTUMES_BUTTON5-B": "bouton5-b",
    "COSTUMES_CAKE-A": "gâteau-a",
    "COSTUMES_CAKE-B": "gâteau-b",
    "COSTUMES_CALVRETT_JUMPING": "calvrett sautant",
    "COSTUMES_CALVRETT_THINKING": "calvrett pensant",
    "COSTUMES_CASEY-A": "casey-a",
    "COSTUMES_CASEY-B": "casey-b",
    "COSTUMES_CASEY-C": "casey-c",
    "COSTUMES_CASEY-D": "casey-d",
    "COSTUMES_CASSY-A": "cassy-a",
    "COSTUMES_CASSY-B": "cassy-b",
    "COSTUMES_CASSY-C": "cassy-c",
    "COSTUMES_CASSY-D": "cassy-d",
    "COSTUMES_CAT_2": "chat 2",
    "COSTUMES_CATCHER-A": "receveur-a",
    "COSTUMES_CATCHER-B": "receveur-b",
    "COSTUMES_CATCHER-C": "receveur-c",
    "COSTUMES_CATCHER-D": "receveur-d",
    "COSTUMES_CENTAUR-A": "centaure-a",
    "COSTUMES_CENTAUR-B": "centaure-b",
    "COSTUMES_CENTAUR-C": "centaure-c",
    "COSTUMES_CENTAUR-D": "centaure-d",
    "COSTUMES_CHAMP99-A": "champ99-a",
    "COSTUMES_CHAMP99-B": "champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "choux au fromage",
    "COSTUMES_CHICK-A": "chick-a",
    "COSTUMES_CHICK-B": "chick-b",
    "COSTUMES_CHICK-C": "chick-c",
    "COSTUMES_CITY_BUS-A": "Bus de la ville-a",
    "COSTUMES_CITY_BUS-B": "Bus de la ville-b",
    "COSTUMES_CLOUD": "nuage",
    "COSTUMES_CLOUD-A": "nuage-a",
    "COSTUMES_CLOUD-B": "cloud-b",
    "COSTUMES_CLOUD-C": "cloud-c",
    "COSTUMES_CLOUD-D": "nuage-d",
    "COSTUMES_CONVERTIBLE": "convertible",
    "COSTUMES_CONVERTIBLE_3": "convertible 3",
    "COSTUMES_CRAB-A": "crab-a",
    "COSTUMES_CRAB-B": "crab-b",
    "COSTUMES_CRYSTAL-A": "cristal-a",
    "COSTUMES_CRYSTAL-B": "cristal-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "dee-a",
    "COSTUMES_DEE-B": "dee-b",
    "COSTUMES_DEE-C": "dee-c",
    "COSTUMES_DEE-D": "dee-d",
    "COSTUMES_DEE-E": "dee-e",
    "COSTUMES_DEVIN-A": "devin-a",
    "COSTUMES_DEVIN-B": "devin-b",
    "COSTUMES_DEVIN-C": "devin-c",
    "COSTUMES_DEVIN-D": "devin-d",
    "COSTUMES_DINOSAUR1-A": "dinosaure1-a",
    "COSTUMES_DINOSAUR1-B": "dinosaure1-b",
    "COSTUMES_DINOSAUR1-C": "dinosaure1-c",
    "COSTUMES_DINOSAUR1-D": "dinosaure 1-d",
    "COSTUMES_DINOSAUR2-A": "dinosaure2-a",
    "COSTUMES_DINOSAUR2-B": "dinosaure2-b",
    "COSTUMES_DINOSAUR2-C": "dinosaure2-c",
    "COSTUMES_DINOSAUR2-D": "dinosaure2-d",
    "COSTUMES_DINOSAUR3-A": "dinosaure3-a",
    "COSTUMES_DINOSAUR3-B": "dinosaure3-b",
    "COSTUMES_DINOSAUR3-C": "dinosaure3-c",
    "COSTUMES_DINOSAUR3-D": "dinosaure3-d",
    "COSTUMES_DINOSAUR3-E": "dinosaure3-e",
    "COSTUMES_DINOSAUR4-A": "dinosaure4-a",
    "COSTUMES_DINOSAUR4-B": "dinosaure4-b",
    "COSTUMES_DINOSAUR4-C": "dinosaure4-c",
    "COSTUMES_DINOSAUR4-D": "dinosaure4-d",
    "COSTUMES_DIVER1": "plongeur1",
    "COSTUMES_DIVER2": "plongeur2",
    "COSTUMES_DM_FREEZE": "dm gel",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L bras",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R bras",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "position de dm",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": "dog1-a",
    "COSTUMES_DOG1-B": "dog1-b",
    "COSTUMES_DOG2-A": "dog2-a",
    "COSTUMES_DOG2-B": "dog2-b",
    "COSTUMES_DOG2-C": "dog2-c",
    "COSTUMES_DONUT": "Donut",
    "COSTUMES_DORIAN-A": "dorian-a",
    "COSTUMES_DORIAN-B": "dorian-b",
    "COSTUMES_DORIAN-C": "dorian-c",
    "COSTUMES_DORIAN-D": "dorian-d",
    "COSTUMES_DOT-A": "point-a",
    "COSTUMES_DOT-B": "point-b",
    "COSTUMES_DOT-C": "point-c",
    "COSTUMES_DOT-D": "point-d",
    "COSTUMES_DOVE-A": "colombe-a",
    "COSTUMES_DOVE-B": "colombe-b",
    "COSTUMES_DRAGON-A": "dragon-a",
    "COSTUMES_DRAGON-B": "dragon-b",
    "COSTUMES_DRAGON-C": "dragon-c",
    "COSTUMES_DRAGON1-A": "Dragon1-a",
    "COSTUMES_DRAGON1-B": "Dragon1-b",
    "COSTUMES_DRAGONFLY-A": "Libellule-a",
    "COSTUMES_DRAGONFLY-B": "Libellule-b",
    "COSTUMES_DRESS-A": "robe-a",
    "COSTUMES_DRESS-B": "robe-b",
    "COSTUMES_DRESS-C": "robe-c",
    "COSTUMES_DRUM-A": "drum-a",
    "COSTUMES_DRUM-B": "drum-b",
    "COSTUMES_DRUM-CYMBAL-A": "drum-cymbal-a",
    "COSTUMES_DRUM-CYMBAL-B": "drum-cymbal-b",
    "COSTUMES_DRUM-HIGHHAT-A": "drum-highhat-a",
    "COSTUMES_DRUM-HIGHHAT-B": "drum-highhat-b",
    "COSTUMES_DRUM-KIT": "batterie",
    "COSTUMES_DRUM-KIT-B": "drum-kit-b",
    "COSTUMES_DRUM-SNARE-A": "drum-snare-a",
    "COSTUMES_DRUM-SNARE-B": "drum-snare-b",
    "COSTUMES_DRUMS_CONGA-A": "Batterie Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Batterie Conga-b",
    "COSTUMES_DUCK": "canard",
    "COSTUMES_EARTH": "Terre",
    "COSTUMES_EASEL-A": "Chevalet-a",
    "COSTUMES_EASEL-B": "Chevalet-b",
    "COSTUMES_EASEL-C": "Chevalet-c",
    "COSTUMES_EGG-A": "oeuf-a",
    "COSTUMES_EGG-B": "oeuf-b",
    "COSTUMES_EGG-C": "oeuf-c",
    "COSTUMES_EGG-D": "oeuf-d",
    "COSTUMES_EGG-E": "oeuf-e",
    "COSTUMES_EGG-F": "oeuf-f",
    "COSTUMES_ELEPHANT-A": "elephant-a",
    "COSTUMES_ELEPHANT-B": "elephant-b",
    "COSTUMES_ELF-A": "elf-a",
    "COSTUMES_ELF-B": "elf-b",
    "COSTUMES_ELF-C": "elf-c",
    "COSTUMES_ELF-D": "elf-d",
    "COSTUMES_ELF-E": "elf-e",
    "COSTUMES_FAIRY-A": "fée-a",
    "COSTUMES_FAIRY-B": "fée-b",
    "COSTUMES_FAIRY-C": "fée-c",
    "COSTUMES_FAIRY-D": "fée-d",
    "COSTUMES_FAIRY-E": "fée-e",
    "COSTUMES_FISH-A": "poisson-a",
    "COSTUMES_FISH-B": "poisson-b",
    "COSTUMES_FISH-C": "poisson-c",
    "COSTUMES_FISH-D": "poisson-d",
    "COSTUMES_FISHBOWL-A": "Bocal à poisson-a",
    "COSTUMES_FISHBOWL-B": "Bocal à poisson-b",
    "COSTUMES_FOOD_TRUCK-A": "Camion alimentaire-a",
    "COSTUMES_FOOD_TRUCK-B": "Camion alimentaire-b",
    "COSTUMES_FOOD_TRUCK-C": "Camion alimentaire-c",
    "COSTUMES_FOOTBALL_RUNNING": "football courant",
    "COSTUMES_FOOTBALL_STANDING": "football debout",
    "COSTUMES_FORTUNE_COOKIE": "biscuit de fortune",
    "COSTUMES_FOX-A": "renard-a",
    "COSTUMES_FOX-B": "renard-b",
    "COSTUMES_FOX-C": "renard-c",
    "COSTUMES_FRANK-A": "frank-a",
    "COSTUMES_FRANK-B": "frank-b",
    "COSTUMES_FRANK-C": "frank-c",
    "COSTUMES_FRANK-D": "frank-d",
    "COSTUMES_FROG": "la grenouille",
    "COSTUMES_FROG_2-A": "Grenouille 2-a",
    "COSTUMES_FROG_2-B": "Grenouille 2-b",
    "COSTUMES_FROG_2-C": "Grenouille 2-c",
    "COSTUMES_FRUIT_PLATTER": "plateau de fruits",
    "COSTUMES_FRUITSALAD": "salade de fruit",
    "COSTUMES_GHOST-A": "fantôme-a",
    "COSTUMES_GHOST-B": "ghost-b",
    "COSTUMES_GHOST-C": "fantôme-c",
    "COSTUMES_GHOST-D": "fantôme-d",
    "COSTUMES_GIFT-A": "cadeau-a",
    "COSTUMES_GIFT-B": "cadeau-b",
    "COSTUMES_GIRAFFE-A": "girafe-a",
    "COSTUMES_GIRAFFE-B": "girafe-b",
    "COSTUMES_GIRAFFE-C": "girafe-c",
    "COSTUMES_GLASS_WATER-A": "verre eau-a",
    "COSTUMES_GLASS_WATER-B": "verre eau-b",
    "COSTUMES_GLASSES-A": "lunettes-a",
    "COSTUMES_GLASSES-B": "lunettes-b",
    "COSTUMES_GLASSES-C": "lunettes-c",
    "COSTUMES_GLASSES-E": "lunettes-e",
    "COSTUMES_GOALIE-A": "gardien de but-a",
    "COSTUMES_GOALIE-B": "gardien de but-b",
    "COSTUMES_GOALIE-C": "gardien de but-c",
    "COSTUMES_GOALIE-D": "gardien de but-d",
    "COSTUMES_GOALIE-E": "gardien de but-e",
    "COSTUMES_GOBLIN-A": "gobelin-a",
    "COSTUMES_GOBLIN-B": "gobelin-b",
    "COSTUMES_GOBLIN-C": "gobelin-c",
    "COSTUMES_GOBLIN-D": "gobelin-d",
    "COSTUMES_GRASSHOPPER-A": "Sauterelle-a",
    "COSTUMES_GRASSHOPPER-B": "Sauterelle-b",
    "COSTUMES_GRASSHOPPER-C": "Sauterelle-c",
    "COSTUMES_GRASSHOPPER-D": "Sauterelle-d",
    "COSTUMES_GRASSHOPPER-E": "Sauterelle-e",
    "COSTUMES_GRASSHOPPER-F": "Sauterelle-f",
    "COSTUMES_GREEN_FLAG": "drapeau vert",
    "COSTUMES_GRIFFIN-A": "Griffon-a",
    "COSTUMES_GRIFFIN-B": "Griffon-b",
    "COSTUMES_GRIFFIN-C": "Griffon-c",
    "COSTUMES_GRIFFIN-D": "Griffon-d",
    "COSTUMES_GUITAR-A": "guitare-a",
    "COSTUMES_GUITAR-B": "guitare-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "guitare-électrique1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "guitare électrique1-b ",
    "COSTUMES_GUITAR-ELECTRIC2-A": "guitare électrique2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "guitare électrique2-b",
    "COSTUMES_HANNAH-A": "hannah-a",
    "COSTUMES_HANNAH-B": "hannah-b",
    "COSTUMES_HANNAH-C": "hannah-c",
    "COSTUMES_HARE-A": "hare-a",
    "COSTUMES_HARE-B": "lièvre-b",
    "COSTUMES_HARE-C": "lièvre-c",
    "COSTUMES_HARPER-A": "harper-a",
    "COSTUMES_HARPER-B": "harper-b",
    "COSTUMES_HARPER-C": "harper-c",
    "COSTUMES_HAT-A": "chapeau-a",
    "COSTUMES_HAT-B": "chapeau-b",
    "COSTUMES_HAT-C": "chapeau-c",
    "COSTUMES_HAT-D": "chapeau-d",
    "COSTUMES_HATCHLING-A": "hatchling-a",
    "COSTUMES_HATCHLING-B": "hatchling-b",
    "COSTUMES_HATCHLING-C": "hatchling-c",
    "COSTUMES_HEART_CODE": "code du coeur",
    "COSTUMES_HEART_FACE": "visage de coeur",
    "COSTUMES_HEART_LOVE": "amour de coeur",
    "COSTUMES_HEART_PURPLE": "coeur violet",
    "COSTUMES_HEART_RED": "coeur rouge",
    "COSTUMES_HEART_SMILE": "sourire de coeur",
    "COSTUMES_HEART_SWEET": "coeur doux",
    "COSTUMES_HEDGEHOG-A": "hérisson-a",
    "COSTUMES_HEDGEHOG-B": "hérisson-b",
    "COSTUMES_HEDGEHOG-C": "hérisson-c",
    "COSTUMES_HEDGEHOG-D": "hérisson-d",
    "COSTUMES_HEDGEHOG-E": "hérisson-e",
    "COSTUMES_HEN-A": "poule-a",
    "COSTUMES_HEN-B": "poule-b",
    "COSTUMES_HEN-C": "poule-c",
    "COSTUMES_HEN-D": "poule-d",
    "COSTUMES_HIPPO1-A": "hippo1-a",
    "COSTUMES_HIPPO1-B": "hippo1-b",
    "COSTUMES_HOME_BUTTON": "bouton d'accueil",
    "COSTUMES_HORSE-A": "cheval-a",
    "COSTUMES_HORSE-B": "cheval-b",
    "COSTUMES_JAIME_WALKING-A": "jaime marche-a",
    "COSTUMES_JAIME_WALKING-B": "jaime marche-b",
    "COSTUMES_JAIME_WALKING-C": "jaime marche-c",
    "COSTUMES_JAIME_WALKING-D": "jaime marche-d",
    "COSTUMES_JAIME_WALKING-E": "jaime marche-e",
    "COSTUMES_JAIME-A": "jaime-a",
    "COSTUMES_JAIME-B": "jaime-b",
    "COSTUMES_JAMAL-A": "jamal-a",
    "COSTUMES_JAMAL-B": "jamal-b",
    "COSTUMES_JAMAL-C": "jamal-c",
    "COSTUMES_JAMAL-D": "jamal-d",
    "COSTUMES_JAR-A": "jar-a",
    "COSTUMES_JAR-B": "jar-b",
    "COSTUMES_JELLYFISH-A": "méduse-a",
    "COSTUMES_JELLYFISH-B": "méduse-b",
    "COSTUMES_JELLYFISH-C": "méduse-c",
    "COSTUMES_JELLYFISH-D": "méduse-d",
    "COSTUMES_JO_POP_DOWN": "jo pop down",
    "COSTUMES_JO_POP_FRONT": "jo pop avant",
    "COSTUMES_JO_POP_L_ARM": "jo pop bras",
    "COSTUMES_JO_POP_LEFT": "jo pop left",
    "COSTUMES_JO_POP_R_ARM": "jo pop bras r",
    "COSTUMES_JO_POP_RIGHT": "jo pop right",
    "COSTUMES_JO_POP_STAND": "stand pop pop",
    "COSTUMES_JO_STANCE": "jo stance",
    "COSTUMES_JO_TOP_L_CROSS": "jo top l cross",
    "COSTUMES_JO_TOP_L_LEG": "jo top l leg",
    "COSTUMES_JO_TOP_R_CROSS": "jo top R cross",
    "COSTUMES_JO_TOP_R_LEG": "jo top r leg",
    "COSTUMES_JO_TOP_STAND": "jo top stand",
    "COSTUMES_JORDYN-A": "jordyn-a",
    "COSTUMES_JORDYN-B": "jordyn-b",
    "COSTUMES_JORDYN-C": "jordyn-c",
    "COSTUMES_JORDYN-D": "jordyn-d",
    "COSTUMES_KAI-A": "kai-a",
    "COSTUMES_KAI-B": "kai-b",
    "COSTUMES_KEY": "clé",
    "COSTUMES_KEYBOARD-A": "clavier-a",
    "COSTUMES_KEYBOARD-B": "clavier-b",
    "COSTUMES_KIRAN-A": "kiran-a",
    "COSTUMES_KIRAN-B": "kiran-b",
    "COSTUMES_KIRAN-C": "kiran-c",
    "COSTUMES_KIRAN-D": "kiran-d",
    "COSTUMES_KIRAN-E": "kiran-e",
    "COSTUMES_KIRAN-F": "kiran-f",
    "COSTUMES_KNIGHT": "Chevalier",
    "COSTUMES_LADYBUG2": "coccinelle",
    "COSTUMES_LADYBUG2-A": "coccinelle-a",
    "COSTUMES_LADYBUG2-B": "coccinelle-b",
    "COSTUMES_LAPTOP": "portable",
    "COSTUMES_LB_POP_DOWN": "lb pop down",
    "COSTUMES_LB_POP_FRONT": "lb pop front",
    "COSTUMES_LB_POP_L_ARM": "lb pop L bras",
    "COSTUMES_LB_POP_LEFT": "lb pop left",
    "COSTUMES_LB_POP_R_ARM": "lb pop R bras",
    "COSTUMES_LB_POP_RIGHT": "lb pop right",
    "COSTUMES_LB_POP_STAND": "stand pop",
    "COSTUMES_LB_STANCE": "position de lb",
    "COSTUMES_LB_TOP_L_CROSS": "lb top L croix",
    "COSTUMES_LB_TOP_L_LEG": "jambe supérieure L",
    "COSTUMES_LB_TOP_R_CROSS": "lb top R cross",
    "COSTUMES_LB_TOP_R_LEG": "jambe supérieure R",
    "COSTUMES_LB_TOP_STAND": "stand supérieur lb",
    "COSTUMES_LIGHTNING": "foudre",
    "COSTUMES_LINE": "ligne",
    "COSTUMES_LION-A": "lion-a",
    "COSTUMES_LION-B": "lion-b",
    "COSTUMES_LION-C": "lion-c",
    "COSTUMES_LLAMA": "lama",
    "COSTUMES_LLAMA-B": "lama-b",
    "COSTUMES_LLAMA-C": "lama-c",
    "COSTUMES_MAGICWAND": "baguette magique",
    "COSTUMES_MAX-A": "max-a",
    "COSTUMES_MAX-B": "max-b",
    "COSTUMES_MAX-C": "max-c",
    "COSTUMES_MAX-D": "max-d",
    "COSTUMES_MERMAID-A": "sirène-a",
    "COSTUMES_MERMAID-B": "sirène-b",
    "COSTUMES_MERMAID-C": "sirène-c",
    "COSTUMES_MERMAID-D": "sirène-d",
    "COSTUMES_MICROPHONE-A": "micro-a",
    "COSTUMES_MICROPHONE-B": "microphone b",
    "COSTUMES_MILK-A": "lait-a",
    "COSTUMES_MILK-B": "lait-b",
    "COSTUMES_MILK-C": "lait-c",
    "COSTUMES_MILK-D": "lait-d",
    "COSTUMES_MILK-E": "lait-e",
    "COSTUMES_MONET-A": "monet-a",
    "COSTUMES_MONET-B": "monet-b",
    "COSTUMES_MONET-C": "monet-c",
    "COSTUMES_MONET-D": "monet-d",
    "COSTUMES_MONET-E": "monet-e",
    "COSTUMES_MONKEY-A": "singe-a",
    "COSTUMES_MONKEY-B": "singe-b",
    "COSTUMES_MONKEY-C": "singe-c",
    "COSTUMES_MOTORCYCLE-A": "Moto-a",
    "COSTUMES_MOTORCYCLE-B": "Moto-b",
    "COSTUMES_MOTORCYCLE-C": "Moto-c",
    "COSTUMES_MOTORCYCLE-D": "Moto-d",
    "COSTUMES_MOUSE1-A": "mouse1-a",
    "COSTUMES_MOUSE1-B": "souris1-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "poney voisin",
    "COSTUMES_OCTOPUS-A": "poulpe-a",
    "COSTUMES_OCTOPUS-B": "poulpe-b",
    "COSTUMES_OCTOPUS-C": "poulpe-c",
    "COSTUMES_OCTOPUS-D": "poulpe-d",
    "COSTUMES_OCTOPUS-E": "poulpe-e",
    "COSTUMES_ORANGE": "Orange",
    "COSTUMES_ORANGE2-A": "orange2-a",
    "COSTUMES_ORANGE2-B": "orange2-b",
    "COSTUMES_OUTFIELDER-A": "outfielder-a",
    "COSTUMES_OUTFIELDER-B": "outfielder-b",
    "COSTUMES_OUTFIELDER-C": "outfielder-c",
    "COSTUMES_OUTFIELDER-D": "outfielder-d",
    "COSTUMES_OWL-A": "chouette-a",
    "COSTUMES_OWL-B": "chouette-b",
    "COSTUMES_OWL-C": "hibou-c",
    "COSTUMES_PADDLE": "pagayer",
    "COSTUMES_PANTHER-A": "panthère a",
    "COSTUMES_PANTHER-B": "panthère-b",
    "COSTUMES_PANTHER-C": "panthère-c",
    "COSTUMES_PANTS-A": "pantalon-a",
    "COSTUMES_PANTS-B": "pantalon-b",
    "COSTUMES_PARROT-A": "perroquet-a",
    "COSTUMES_PARROT-B": "perroquet-b",
    "COSTUMES_PARTY_HAT-A": "Chapeau de fête-a",
    "COSTUMES_PARTY_HAT-B": "Chapeau de fête-b",
    "COSTUMES_PARTY_HAT-E": "Chapeau de fête-e",
    "COSTUMES_PENCIL-A": "crayon-a",
    "COSTUMES_PENCIL-B": "crayon-b",
    "COSTUMES_PENGUIN-A": "pingouin-a",
    "COSTUMES_PENGUIN-B": "pingouin-b",
    "COSTUMES_PENGUIN-C": "pingouin-c",
    "COSTUMES_PENGUIN2-A": "pingouin2-a",
    "COSTUMES_PENGUIN2-B": "pingouin2-b",
    "COSTUMES_PENGUIN2-C": "pingouin2-c",
    "COSTUMES_PENGUIN2-D": "pingouin2-d",
    "COSTUMES_PITCHER-A": "pichet-a",
    "COSTUMES_PITCHER-B": "pichet-b",
    "COSTUMES_PITCHER-C": "pichet-c",
    "COSTUMES_PITCHER-D": "pichet-d",
    "COSTUMES_PLANET2": "planet2",
    "COSTUMES_POLAR_BEAR-A": "ours polaire-a",
    "COSTUMES_POLAR_BEAR-B": "ours polaire-b",
    "COSTUMES_POLAR_BEAR-C": "ours polaire-c",
    "COSTUMES_POTION-A": "potion-a",
    "COSTUMES_POTION-B": "potion-b",
    "COSTUMES_POTION-C": "potion-c",
    "COSTUMES_PRINCE": "prince",
    "COSTUMES_PRINCESS-A": "princesse-a",
    "COSTUMES_PRINCESS-B": "princesse-b",
    "COSTUMES_PRINCESS-C": "princesse-c",
    "COSTUMES_PRINCESS-D": "princesse-d",
    "COSTUMES_PRINCESS-E": "princesse-e",
    "COSTUMES_PUFFERFISH-A": "pufferfish-a",
    "COSTUMES_PUFFERFISH-B": "pufferfish-b",
    "COSTUMES_PUFFERFISH-C": "pufferfish-c",
    "COSTUMES_PUFFERFISH-D": "pufferfish-d",
    "COSTUMES_PUPPY_BACK": "chiot de retour",
    "COSTUMES_PUPPY_RIGHT": "chiot droit",
    "COSTUMES_PUPPY_SIDE": "côté chiot",
    "COSTUMES_PUPPY_SIT": "chiot assis",
    "COSTUMES_RABBIT-A": "lapin-a",
    "COSTUMES_RABBIT-B": "lapin-b",
    "COSTUMES_RABBIT-C": "lapin-c",
    "COSTUMES_RABBIT-D": "lapin-d",
    "COSTUMES_RABBIT-E": "lapin-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": "arc en ciel",
    "COSTUMES_REFEREE-A": "arbitre-a",
    "COSTUMES_REFEREE-B": "arbitre-b",
    "COSTUMES_REFEREE-C": "arbitre-c",
    "COSTUMES_REFEREE-D": "arbitre-d",
    "COSTUMES_REINDEER": "renne",
    "COSTUMES_RETRO_ROBOT_A": "Robot rétro a",
    "COSTUMES_RETRO_ROBOT_B": "Robot rétro b",
    "COSTUMES_RETRO_ROBOT_C": "Robot rétro c",
    "COSTUMES_RIPLEY-A": "ripley-a",
    "COSTUMES_RIPLEY-B": "ripley-b",
    "COSTUMES_RIPLEY-C": "ripley-c",
    "COSTUMES_RIPLEY-D": "ripley-d",
    "COSTUMES_RIPLEY-E": "ripley-e",
    "COSTUMES_RIPLEY-F": "ripley-f",
    "COSTUMES_ROBOT-A": "robot-a",
    "COSTUMES_ROBOT-B": "robot-b",
    "COSTUMES_ROBOT-C": "robot-c",
    "COSTUMES_ROBOT-D": "robot-d",
    "COSTUMES_ROCKETSHIP-A": "rocketship-a",
    "COSTUMES_ROCKETSHIP-B": "rocketship-b",
    "COSTUMES_ROCKETSHIP-C": "rocketship-c",
    "COSTUMES_ROCKETSHIP-D": "rocketship-d",
    "COSTUMES_ROCKETSHIP-E": "rocketship-e",
    "COSTUMES_ROCKS": "roches",
    "COSTUMES_ROOSTER-A": "coq-a",
    "COSTUMES_ROOSTER-B": "coq-b",
    "COSTUMES_ROOSTER-C": "coq-c",
    "COSTUMES_RUBY-A": "ruby-a",
    "COSTUMES_RUBY-B": "ruby-b",
    "COSTUMES_SAILBOAT": "voilier",
    "COSTUMES_SAM": "sam",
    "COSTUMES_SAXOPHONE-A": "saxophone-a",
    "COSTUMES_SAXOPHONE-B": "saxophone-b",
    "COSTUMES_SCARF-A": "Echarpe-a",
    "COSTUMES_SCARF-B": "Écharpe-b",
    "COSTUMES_SCARF-C": "Écharpe-c",
    "COSTUMES_SHARK-A": "requin-a",
    "COSTUMES_SHARK-B": "requin-b",
    "COSTUMES_SHARK2-A": "requin2-a",
    "COSTUMES_SHARK2-B": "requin2-b",
    "COSTUMES_SHARK2-C": "requin2-c",
    "COSTUMES_SHIRT-A": "chemise-a",
    "COSTUMES_SHOES-A": "chaussures-a",
    "COSTUMES_SHOES-B": "chaussures-b",
    "COSTUMES_SHOES-C": "chaussures-c",
    "COSTUMES_SHOES-D": "chaussures-d",
    "COSTUMES_SHORTS-A": "shorts-a",
    "COSTUMES_SHORTS-B": "shorts-b",
    "COSTUMES_SHORTS-C": "shorts-c",
    "COSTUMES_SINGER1": "Chanteur1",
    "COSTUMES_SKELETON-A": "squelette-a",
    "COSTUMES_SKELETON-B": "squelette-b",
    "COSTUMES_SKELETON-D": "squelette-d",
    "COSTUMES_SKELETON-E": "squelette-e",
    "COSTUMES_SNAKE-A": "serpent-a",
    "COSTUMES_SNAKE-B": "serpent-b",
    "COSTUMES_SNAKE-C": "serpent-c",
    "COSTUMES_SNOWFLAKE": "flocon de neige",
    "COSTUMES_SNOWMAN": "bonhomme de neige",
    "COSTUMES_SOCCER_BALL": "ballon de football",
    "COSTUMES_SPEAKER": "orateur",
    "COSTUMES_SQUIRREL": "écureuil",
    "COSTUMES_STAR": "étoile",
    "COSTUMES_STARFISH-A": "étoile de mer-a",
    "COSTUMES_STARFISH-B_": "étoile de mer-b",
    "COSTUMES_STOP": "Arrêtez",
    "COSTUMES_STRAWBERRY-A": "fraise-a",
    "COSTUMES_STRAWBERRY-B": "fraise-b",
    "COSTUMES_STRAWBERRY-C": "fraise-c",
    "COSTUMES_STRAWBERRY-D": "fraise-d",
    "COSTUMES_STRAWBERRY-E": "fraise-e",
    "COSTUMES_SUN": "Soleil",
    "COSTUMES_SUNGLASSES-A": "lunettes de soleil-a",
    "COSTUMES_SUNGLASSES-B": "lunettes de soleil-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-magicien",
    "COSTUMES_TAKEOUT-A": "emporter-a",
    "COSTUMES_TAKEOUT-B": "à emporter-b",
    "COSTUMES_TAKEOUT-C": "à emporter-c",
    "COSTUMES_TAKEOUT-D": "à emporter-d",
    "COSTUMES_TAKEOUT-E": "emporter-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 pop down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 pop front",
    "COSTUMES_TEN80_POP_L_ARM": "Bras Ten80 Pop L",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 pop left",
    "COSTUMES_TEN80_POP_R_ARM": "Bras Ten80 Pop R",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 pop right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 pop stand",
    "COSTUMES_TEN80_STANCE": "Ten80 position",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 top gel",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 top L step",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 top R cross",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 top R step",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 top stand",
    "COSTUMES_TENNISBALL": "balle de tennis",
    "COSTUMES_TOUCAN-A": "toucan-a",
    "COSTUMES_TOUCAN-B": "toucan-b",
    "COSTUMES_TOUCAN-C": "toucan-c",
    "COSTUMES_TRAMPOLINE": "trampoline",
    "COSTUMES_TREE1": "arbre1",
    "COSTUMES_TREES-A": "arbres-a",
    "COSTUMES_TREES-B": "arbres-b",
    "COSTUMES_TRUCK-A": "Camion-a",
    "COSTUMES_TRUCK-B": "Camion-b",
    "COSTUMES_TRUCK-C": "Camion-c",
    "COSTUMES_TRUMPET-A": "trompette-a",
    "COSTUMES_TRUMPET-B": "trompette-b",
    "COSTUMES_UNICORN": "Licorne",
    "COSTUMES_UNICORN_2": "licorne 2",
    "COSTUMES_UNICORN_RUNNING-A": "Licorne au galop-a",
    "COSTUMES_UNICORN_RUNNING-B": "Licorne au galop-b",
    "COSTUMES_UNICORN_RUNNING-C": "Licorne au galop-c",
    "COSTUMES_UNICORN_RUNNING-D": "Licorne au galop-d",
    "COSTUMES_UNICORN_RUNNING-E": "Licorne au galop-e",
    "COSTUMES_UNICORN_RUNNING-F": "Licorne au galop-f",
    "COSTUMES_WAND": "baguette magique",
    "COSTUMES_WANDA": "wanda",
    "COSTUMES_WATERMELON-A": "pastèque-a",
    "COSTUMES_WATERMELON-B": "pastèque-b",
    "COSTUMES_WATERMELON-C": "pastèque-c",
    "COSTUMES_WINTER_HAT": "Chapeau d'hiver",
    "COSTUMES_WITCH": "Sorcière",
    "COSTUMES_WITCH-A": "sorcière-a",
    "COSTUMES_WITCH-B": "sorcière b",
    "COSTUMES_WITCH-C": "sorcière-c",
    "COSTUMES_WITCH-D": "sorcière-d",
    "COSTUMES_WIZARD_HAT": "Chapeau de magicien",
    "COSTUMES_WIZARD-A": "sorcier-a",
    "COSTUMES_WIZARD-B": "sorcier-b",
    "COSTUMES_WIZARD-C": "sorcier-c",
    "COSTUMES_WIZARD-TOAD-A": "sorcier-crapaud-a",
    "COSTUMES_WIZARD-TOAD-B": "sorcier-crapaud-b",
    "COSTUMES_ZEBRA-A": "zèbre-a",
    "COSTUMES_ZEBRA-B": "zèbre-b",
    "COSTUMES_BLOCK-A": "Block-a",
    "COSTUMES_BLOCK-B": "Block-b",
    "COSTUMES_BLOCK-C": "Block-c",
    "COSTUMES_BLOCK-D": "Block-d",
    "COSTUMES_BLOCK-E": "Block-e",
    "COSTUMES_BLOCK-F": "Block-f",
    "COSTUMES_BLOCK-G": "Block-g",
    "COSTUMES_BLOCK-H": "Block-h",
    "COSTUMES_BLOCK-I": "Block-i",
    "COSTUMES_BLOCK-J": "Block-j",
    "COSTUMES_BLOCK-K": "Block-k",
    "COSTUMES_BLOCK-L": "Block-l",
    "COSTUMES_BLOCK-M": "Block-m",
    "COSTUMES_BLOCK-N": "Block-n",
    "COSTUMES_BLOCK-O": "Block-o",
    "COSTUMES_BLOCK-P": "Block-p",
    "COSTUMES_BLOCK-Q": "Block-q",
    "COSTUMES_BLOCK-R": "Block-r",
    "COSTUMES_BLOCK-S": "Block-s",
    "COSTUMES_BLOCK-T": "Block-t",
    "COSTUMES_BLOCK-U": "Block-u",
    "COSTUMES_BLOCK-V": "Block-v",
    "COSTUMES_BLOCK-W": "Block-w",
    "COSTUMES_BLOCK-X": "Block-x",
    "COSTUMES_BLOCK-Y": "Block-y",
    "COSTUMES_BLOCK-Z": "Block-z",
    "COSTUMES_GLOW-0": "Glow-0",
    "COSTUMES_GLOW-1": "Glow-1",
    "COSTUMES_GLOW-2": "Glow-2",
    "COSTUMES_GLOW-3": "Glow-3",
    "COSTUMES_GLOW-4": "Glow-4",
    "COSTUMES_GLOW-5": "Glow-5",
    "COSTUMES_GLOW-6": "Glow-6",
    "COSTUMES_GLOW-7": "Glow-7",
    "COSTUMES_GLOW-8": "Glow-8",
    "COSTUMES_GLOW-9": "Glow-9",
    "COSTUMES_GLOW-A": "Glow-A",
    "COSTUMES_GLOW-B": "Glow-B",
    "COSTUMES_GLOW-C": "Glow-C",
    "COSTUMES_GLOW-D": "Glow-D",
    "COSTUMES_GLOW-E": "Glow-E",
    "COSTUMES_GLOW-F": "Glow-F",
    "COSTUMES_GLOW-G": "Glow-G",
    "COSTUMES_GLOW-H": "Glow-H",
    "COSTUMES_GLOW-I": "Glow-I",
    "COSTUMES_GLOW-J": "Glow-J",
    "COSTUMES_GLOW-K": "Glow-K",
    "COSTUMES_GLOW-L": "Glow-L",
    "COSTUMES_GLOW-M": "Glow-M",
    "COSTUMES_GLOW-N": "Glow-N",
    "COSTUMES_GLOW-O": "Glow-O",
    "COSTUMES_GLOW-P": "Glow-P",
    "COSTUMES_GLOW-Q": "Glow-Q",
    "COSTUMES_GLOW-R": "Glow-R",
    "COSTUMES_GLOW-S": "Glow-S",
    "COSTUMES_GLOW-T": "Glow-T",
    "COSTUMES_GLOW-U": "Glow-U",
    "COSTUMES_GLOW-V": "Glow-V",
    "COSTUMES_GLOW-W": "Glow-W",
    "COSTUMES_GLOW-X": "Glow-X",
    "COSTUMES_GLOW-Y": "Glow-Y",
    "COSTUMES_GLOW-Z": "Glow-Z",
    "COSTUMES_STORY-A-1": "Story-A-1",
    "COSTUMES_STORY-A-2": "Story-A-2",
    "COSTUMES_STORY-A-3": "Story-A-3",
    "COSTUMES_STORY-B-1": "Story-B-1",
    "COSTUMES_STORY-B-2": "Story-B-2",
    "COSTUMES_STORY-B-3": "Story-B-3",
    "COSTUMES_STORY-C-1": "Story-C-1",
    "COSTUMES_STORY-C-2": "Story-C-2",
    "COSTUMES_STORY-C-3": "Story-C-3",
    "COSTUMES_STORY-D-1": "Story-D-1",
    "COSTUMES_STORY-D-2": "Story-D-2",
    "COSTUMES_STORY-D-3": "Story-D-3",
    "COSTUMES_STORY-E-1": "Story-E-1",
    "COSTUMES_STORY-E-2": "Story-E-2",
    "COSTUMES_STORY-E-3": "Story-E-3",
    "COSTUMES_STORY-F-1": "Story-F-1",
    "COSTUMES_STORY-F-2": "Story-F-2",
    "COSTUMES_STORY-F-3": "Story-F-3",
    "COSTUMES_STORY-G-1": "Story-G-1",
    "COSTUMES_STORY-G-2": "Story-G-2",
    "COSTUMES_STORY-G-3": "Story-G-3",
    "COSTUMES_STORY-H-1": "Story-H-1",
    "COSTUMES_STORY-H-2": "Story-H-2",
    "COSTUMES_STORY-H-3": "Story-H-3",
    "COSTUMES_STORY-I-1": "Story-I-1",
    "COSTUMES_STORY-I-2": "Story-I-2",
    "COSTUMES_STORY-I-3": "Story-I-3",
    "COSTUMES_STORY-J-1": "Story-J-1",
    "COSTUMES_STORY-J-2": "Story-J-2",
    "COSTUMES_STORY-J-3": "Story-J-3",
    "COSTUMES_STORY-K-1": "Story-K-1",
    "COSTUMES_STORY-K-2": "Story-K-2",
    "COSTUMES_STORY-K-3": "story-K-3",
    "COSTUMES_STORY-L-1": "Story-L-1",
    "COSTUMES_STORY-L-2": "Story-L-2",
    "COSTUMES_STORY-L-3": "Story-L-3",
    "COSTUMES_STORY-M-1": "Story-M-1",
    "COSTUMES_STORY-M-2": "Story-M-2",
    "COSTUMES_STORY-M-3": "Story-M-3",
    "COSTUMES_STORY-N-1": "Story-N-1",
    "COSTUMES_STORY-N-2": "Story-N-2",
    "COSTUMES_STORY-N-3": "Story-N-3",
    "COSTUMES_STORY-O-1": "Story-O-1",
    "COSTUMES_STORY-O-2": "Story-O-2",
    "COSTUMES_STORY-O-3": "Story-O-3",
    "COSTUMES_STORY-P-1": "Story-P-1",
    "COSTUMES_STORY-P-2": "Story-P-2",
    "COSTUMES_STORY-P-3": "Story-P-3",
    "COSTUMES_STORY-Q-1": "Story-Q-1",
    "COSTUMES_STORY-Q-2": "Story-Q-2",
    "COSTUMES_STORY-Q-3": "Story-Q-3",
    "COSTUMES_STORY-R-1": "Story-R-1",
    "COSTUMES_STORY-R-2": "Story-R-2",
    "COSTUMES_STORY-R-3": "Story-R-3",
    "COSTUMES_STORY-S-1": "Story-S-1",
    "COSTUMES_STORY-S-2": "Story-S-2",
    "COSTUMES_STORY-S-3": "Story-S-3",
    "COSTUMES_STORY-T-1": "Story-T-1",
    "COSTUMES_STORY-T-2": "Story-T-2",
    "COSTUMES_STORY-T-3": "Story-T-3",
    "COSTUMES_STORY-U-1": "Story-U-1",
    "COSTUMES_STORY-U-2": "Story-U-2",
    "COSTUMES_STORY-U-3": "Story-U-3",
    "COSTUMES_STORY-V-1": "Story-V-1",
    "COSTUMES_STORY-V-2": "Story-V-2",
    "COSTUMES_STORY-V-3": "Story-V-3",
    "COSTUMES_STORY-W-1": "Story-W-1",
    "COSTUMES_STORY-W-2": "Story-W-2",
    "COSTUMES_STORY-W-3": "Story-W-3",
    "COSTUMES_STORY-X-1": "Story-X-1",
    "COSTUMES_STORY-X-2": "Story-X-2",
    "COSTUMES_STORY-X-3": "Story-X-3",
    "COSTUMES_STORY-Y-1": "Story-Y-1",
    "COSTUMES_STORY-Y-2": "Story-Y-2",
    "COSTUMES_STORY-Y-3": "Story-Y-3",
    "COSTUMES_STORY-Z-1": "Story-Z-1",
    "COSTUMES_STORY-Z-2": "Story-Z-2",
    "COSTUMES_STORY-Z-3": "Story-Z-3",
    "SOUNDS_A_BASS": "A Bass",
    "SOUNDS_A_ELEC_BASS": "A Elec Bass",
    "SOUNDS_A_ELEC_GUITAR": "A Elec Guitar",
    "SOUNDS_A_ELEC_PIANO": "A Elec Piano",
    "SOUNDS_A_GUITAR": "A Guitar",
    "SOUNDS_A_MINOR_UKULELE": "A Minor Ukulele",
    "SOUNDS_A_PIANO": "A Piano",
    "SOUNDS_A_SAX": "A Sax",
    "SOUNDS_A_TROMBONE": "A Trombone",
    "SOUNDS_A_TRUMPET": "A Trumpet",
    "SOUNDS_AFRO_STRING": "Afro String",
    "SOUNDS_ALERT": "Alert",
    "SOUNDS_ALIEN_CREAK1": "Alien Creak1",
    "SOUNDS_ALIEN_CREAK2": "Alien Creak2",
    "SOUNDS_B_BASS": "B Bass",
    "SOUNDS_B_ELEC_BASS": "B Elec Bass",
    "SOUNDS_B_ELEC_GUITAR": "B Elec Guitar",
    "SOUNDS_B_ELEC_PIANO": "B Elec Piano",
    "SOUNDS_B_GUITAR": "B Guitar",
    "SOUNDS_B_PIANO": "B Piano",
    "SOUNDS_B_SAX": "B Sax",
    "SOUNDS_B_TROMBONE": "B Trombone",
    "SOUNDS_B_TRUMPET": "B Trumpet",
    "SOUNDS_BAA": "Baa",
    "SOUNDS_BARK": "Bark",
    "SOUNDS_BASKETBALL_BOUNCE": "Basketball Bounce",
    "SOUNDS_BASS_BEATBOX": "Bass Beatbox",
    "SOUNDS_BEAT_BOX1": "Beat Box1",
    "SOUNDS_BEAT_BOX2": "Beat Box2",
    "SOUNDS_BELL_CYMBAL": "Bell Cymbal",
    "SOUNDS_BELL_TOLL": "Bell Toll",
    "SOUNDS_BIG_BOING": "Big Boing",
    "SOUNDS_BIRD": "Bird",
    "SOUNDS_BIRTHDAY": "Birthday",
    "SOUNDS_BITE": "Bite",
    "SOUNDS_BOING": "Boing",
    "SOUNDS_BONK": "Bonk",
    "SOUNDS_BOOM_CLOUD": "Boom Cloud",
    "SOUNDS_BOOP_BING_BOP": "Boop Bing Bop",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": "Bowling Strike",
    "SOUNDS_BUBBLES": "Bubbles",
    "SOUNDS_BUZZ_WHIR": "Buzz Whir",
    "SOUNDS_C_BASS": "C Bass",
    "SOUNDS_C_ELEC_BASS": "C Elec Bass",
    "SOUNDS_C_ELEC_GUITAR": "C Elec Guitar",
    "SOUNDS_C_ELEC_PIANO": "C Elec Piano",
    "SOUNDS_C_GUITAR": "C Guitar",
    "SOUNDS_C_MAJOR_UKULELE": "C Major Ukulele",
    "SOUNDS_C_PIANO": "C Piano",
    "SOUNDS_C_SAX": "C Sax",
    "SOUNDS_C_TROMBONE": "C Trombone",
    "SOUNDS_C_TRUMPET": "C Trumpet",
    "SOUNDS_C2_BASS": "C2 Bass",
    "SOUNDS_C2_ELEC_BASS": "C2 Elec Bass",
    "SOUNDS_C2_ELEC_GUITAR": "C2 Elec Guitar",
    "SOUNDS_C2_ELEC_PIANO": "C2 Elec Piano",
    "SOUNDS_C2_GUITAR": "C2 guitar",
    "SOUNDS_C2_PIANO": "C2 Piano",
    "SOUNDS_C2_SAX": "C2 Sax",
    "SOUNDS_C2_TROMBONE": "C2 Trombone",
    "SOUNDS_C2_TRUMPET": "C2 Trumpet",
    "SOUNDS_CAR_HORN": "Car Horn",
    "SOUNDS_CAR_PASSING": "Car Passing",
    "SOUNDS_CAR_VROOM": "Car Vroom",
    "SOUNDS_CAVE": "Cave",
    "SOUNDS_CHATTER": "Chatter",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "Cheer",
    "SOUNDS_CHILL": "Chill",
    "SOUNDS_CHIRP": "Chirp",
    "SOUNDS_CHOMP": "Chomp",
    "SOUNDS_CHORD": "Chord",
    "SOUNDS_CLANG": "Clang",
    "SOUNDS_CLAP_BEATBOX": "Clap Beatbox",
    "SOUNDS_CLAPPING": "Clapping",
    "SOUNDS_CLASSICAL_PIANO": "Classical Piano",
    "SOUNDS_CLOCK_TICKING": "Clock Ticking",
    "SOUNDS_CLOWN_HONK": "Clown Honk",
    "SOUNDS_COIN": "Coin",
    "SOUNDS_COLLECT": "Collect",
    "SOUNDS_COMPUTER_BEEP": "Computer Beep1",
    "SOUNDS_COMPUTER_BEEP2": "Computer Beep2",
    "SOUNDS_CONNECT": "Connect",
    "SOUNDS_COUGH1": "Cough1",
    "SOUNDS_COUGH2": "Cough2",
    "SOUNDS_CRANK": "Crank",
    "SOUNDS_CRASH_BEATBOX": "Crash Beatbox",
    "SOUNDS_CRASH_CYMBAL": "crash cymbal",
    "SOUNDS_CRAZY_LAUGH": "Crazy Laugh",
    "SOUNDS_CRICKET": "Cricket",
    "SOUNDS_CRICKETS": "Crickets",
    "SOUNDS_CROAK": "Croak",
    "SOUNDS_CROWD_GASP": "Crowd Gasp",
    "SOUNDS_CROWD_LAUGH": "Crowd Laugh",
    "SOUNDS_CRUNCH": "Crunch",
    "SOUNDS_CYMBAL": "Cymbal",
    "SOUNDS_CYMBAL_CRASH": "Cymbal Crash",
    "SOUNDS_CYMBAL_ECHO": "Cymbal Echo",
    "SOUNDS_D_BASS": "D Bass",
    "SOUNDS_D_ELEC_BASS": "D Elec Bass",
    "SOUNDS_D_ELEC_GUITAR": "D Elec Guitar",
    "SOUNDS_D_ELEC_PIANO": "D Elec Piano",
    "SOUNDS_D_GUITAR": "D Guitar",
    "SOUNDS_D_PIANO": "D Piano",
    "SOUNDS_D_SAX": "D Sax",
    "SOUNDS_D_TROMBONE": "D Trombone",
    "SOUNDS_D_TRUMPET": "D Trompette",
    "SOUNDS_DANCE_AROUND": "Dance Around",
    "SOUNDS_DANCE_CELEBRATE": "dance celebration",
    "SOUNDS_DANCE_CELEBRATE2": "Dance Celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": "Dance Chill Out",
    "SOUNDS_DANCE_ENERGETIC": "Dance Energique",
    "SOUNDS_DANCE_FUNKY": "Dance Funky",
    "SOUNDS_DANCE_HEAD_NOD": "Dance Head Nod",
    "SOUNDS_DANCE_MAGIC": "dance magique",
    "SOUNDS_DANCE_SITAR": "Dance Sitar",
    "SOUNDS_DANCE_SLOW_MO": "Dance Slow Mo",
    "SOUNDS_DANCE_SNARE_BEAT": "Dance Snare Beat",
    "SOUNDS_DANCE_SPACE": "Dance Space",
    "SOUNDS_DISCONNECT": "Disconnect",
    "SOUNDS_DOG1": "Dog",
    "SOUNDS_DOG2": "Dog2",
    "SOUNDS_DOOR_CLOSING": "Door Closing",
    "SOUNDS_DOOR_CREAK": "Door Creak",
    "SOUNDS_DOORBELL": "Doorbell",
    "SOUNDS_DRIP_DROP": "Drip Drop",
    "SOUNDS_DRIVE_AROUND": "Drive Around",
    "SOUNDS_DRUM": "Drum",
    "SOUNDS_DRUM_BASS1": "Drum Bass1",
    "SOUNDS_DRUM_BASS2": "Drum Bass2",
    "SOUNDS_DRUM_BASS3": "Drum Bass3",
    "SOUNDS_DRUM_BOING": "Drum Boing",
    "SOUNDS_DRUM_BUZZ": "Drum Buzz",
    "SOUNDS_DRUM_FUNKY": "Drum Funky",
    "SOUNDS_DRUM_JAM": "Drum Jam",
    "SOUNDS_DRUM_MACHINE": "Drum Machine",
    "SOUNDS_DRUM_ROLL": "Drum Roll",
    "SOUNDS_DRUM_SATELLITE": "Drum Satellite",
    "SOUNDS_DRUM_SET1": "Drum Set1",
    "SOUNDS_DRUM_SET2": "Drum Set2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "Duck",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dunnn",
    "SOUNDS_E_BASS": "E Bass",
    "SOUNDS_E_ELEC_BASS": "E Elec Bass",
    "SOUNDS_E_ELEC_GUITAR": "E Elec Guitar",
    "SOUNDS_E_ELEC_PIANO": "E Elec Piano",
    "SOUNDS_E_GUITAR": "E Guitar",
    "SOUNDS_E_PIANO": "E Piano",
    "SOUNDS_E_SAX": "E Sax",
    "SOUNDS_E_TROMBONE": "E Trombone",
    "SOUNDS_E_TRUMPET": "E Trumpet",
    "SOUNDS_EGGS": "Eggs",
    "SOUNDS_ELEC_PIANO_A_MINOR": "Elec Piano A Minor",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "Elec Piano C Major",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "Elec Piano F Major",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "Elec Piano G Major",
    "SOUNDS_ELEC_PIANO_LOOP": "Elec Piano Loop",
    "SOUNDS_EMOTIONAL_PIANO": "Emotional Piano",
    "SOUNDS_ENGINE": "Engine",
    "SOUNDS_F_BASS": "F Bass",
    "SOUNDS_F_ELEC_BASS": "F Elec Bass",
    "SOUNDS_F_ELEC_GUITAR": "F Elec Guitar",
    "SOUNDS_F_ELEC_PIANO": "F Elec Piano",
    "SOUNDS_F_GUITAR": "F Guitar",
    "SOUNDS_F_MAJOR_UKULELE": "F Major Ukulele",
    "SOUNDS_F_PIANO": "F Piano",
    "SOUNDS_F_SAX": "F Sax",
    "SOUNDS_F_TROMBONE": "F Trombone",
    "SOUNDS_F_TRUMPET": "F Trumpet",
    "SOUNDS_FAIRYDUST": "Fairydust",
    "SOUNDS_FINGER_SNAP": "Finger Snap",
    "SOUNDS_FLAM_SNARE": "Flam Snare",
    "SOUNDS_FOOTSTEPS": "Footsteps",
    "SOUNDS_G_BASS": "G Bass",
    "SOUNDS_G_ELEC_BASS": "G Elec Bass",
    "SOUNDS_G_ELEC_GUITAR": "G Elec Guitar",
    "SOUNDS_G_ELEC_PIANO": "G Elec Piano",
    "SOUNDS_G_GUITAR": "G Guitar",
    "SOUNDS_G_PIANO": "G Piano",
    "SOUNDS_G_SAX": "G Sax",
    "SOUNDS_G_TROMBONE": "G Trombone",
    "SOUNDS_G_TRUMPET": "G Trumpet",
    "SOUNDS_G_UKULELE": "G Ukulele",
    "SOUNDS_GALLOP": "Gallop",
    "SOUNDS_GARDEN": "Garden",
    "SOUNDS_GLASS_BREAKING": "Glass Breaking",
    "SOUNDS_GLUG": "Glug",
    "SOUNDS_GOAL_CHEER": "Goal Cheer",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Goose",
    "SOUNDS_GROWL": "Growl",
    "SOUNDS_GRUNT": "Grunt",
    "SOUNDS_GUITAR_CHORDS1": "Guitar Chords1",
    "SOUNDS_GUITAR_CHORDS2": "Guitar Chords2",
    "SOUNDS_GUITAR_STRUM": "Guitar Strum",
    "SOUNDS_HAND_CLAP": "Hand Clap",
    "SOUNDS_HEAD_SHAKE": "Head Shake",
    "SOUNDS_HEY": "Hey",
    "SOUNDS_HI_BEATBOX": "Hi Beatbox",
    "SOUNDS_HI_NA_TABLA": "Hi Na Tabla",
    "SOUNDS_HI_TUN_TABLA": "Hi Tun Tabla",
    "SOUNDS_HIGH_CONGA": "High Conga",
    "SOUNDS_HIGH_HAT": "High Hat",
    "SOUNDS_HIGH_TOM": "High Tom",
    "SOUNDS_HIGH_WHOOSH": "High Whoosh",
    "SOUNDS_HIHAT_BEATBOX": "Hihat Beatbox",
    "SOUNDS_HIHAT_CYMBAL": "Hihat Cymbal",
    "SOUNDS_HIP_HOP": "Hip Hop",
    "SOUNDS_HORSE": "Horse",
    "SOUNDS_HORSE_GALLOP": "horse gallop",
    "SOUNDS_HUMAN_BEATBOX1": "Human Beatbox1",
    "SOUNDS_HUMAN_BEATBOX2": "Human Beatbox2",
    "SOUNDS_JUMP": "Jump",
    "SOUNDS_JUNGLE": "Jungle",
    "SOUNDS_JUNGLE_FROGS": "Jungle Frogs",
    "SOUNDS_KICK_BACK": "Kick Back",
    "SOUNDS_KICK_DRUM": "Kick Drum",
    "SOUNDS_LARGE_COWBELL": "Large Cowbell",
    "SOUNDS_LASER1": "laser1",
    "SOUNDS_LASER2": "laser2",
    "SOUNDS_LAUGH1": "Laugh1",
    "SOUNDS_LAUGH2": "Laugh2",
    "SOUNDS_LAUGH3": "Laugh3",
    "SOUNDS_LO_GEH_TABLA": "Lo Geh Tabla",
    "SOUNDS_LO_GLISS_TABLA": "Lo Gliss Tabla",
    "SOUNDS_LOSE": "Lose",
    "SOUNDS_LOW_BOING": "Low Boing",
    "SOUNDS_LOW_CONGA": "Low Conga",
    "SOUNDS_LOW_SQUEAK": "Low Squeak",
    "SOUNDS_LOW_TOM": "Low Tom",
    "SOUNDS_LOW_WHOOSH": "Low Whoosh",
    "SOUNDS_MACHINE": "Machine",
    "SOUNDS_MAGIC_SPELL": "Magic Spell",
    "SOUNDS_MEDIEVAL1": "Medieval1",
    "SOUNDS_MEDIEVAL2": "Medieval2",
    "SOUNDS_MEOW": "Meow",
    "SOUNDS_MEOW2": "meow2",
    "SOUNDS_MOO": "Moo",
    "SOUNDS_MOTORCYCLE_PASSING": "Motorcycle Passing",
    "SOUNDS_MOVIE_1": "Movie 1",
    "SOUNDS_MOVIE_2": "Movie 2",
    "SOUNDS_MUTED_CONGA": "Muted Conga",
    "SOUNDS_MYSTERY": "Mystery",
    "SOUNDS_OCEAN_WAVE": "Ocean Wave",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "Oops",
    "SOUNDS_ORCHESTRA_TUNING": "Orchestra Tuning",
    "SOUNDS_OWL": "Owl",
    "SOUNDS_PARTY_NOISE": "Party Noise",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": "Ping Pong Hit",
    "SOUNDS_PLUCK": "Pluck",
    "SOUNDS_PLUNGE": "Plunge",
    "SOUNDS_POLICE_SIREN": "Police Siren",
    "SOUNDS_POP": "Pop",
    "SOUNDS_RAIN": "Rain",
    "SOUNDS_RATTLE": "Rattle",
    "SOUNDS_REFEREE_WHISTLE": "Referee Whistle",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "Ricochet",
    "SOUNDS_RIDE_CYMBAL": "Ride Cymbal",
    "SOUNDS_RING_TONE": "Ring Tone",
    "SOUNDS_RIP": "Rip",
    "SOUNDS_RIPPLES": "Ripples",
    "SOUNDS_ROLL_CYMBAL": "Roll Cymbal",
    "SOUNDS_ROOSTER": "Rooster",
    "SOUNDS_SCRAMBLING_FEET": "Scrambling Feet",
    "SOUNDS_SCRATCH_BEATBOX": "scratch beatbox",
    "SOUNDS_SCRATCHY_BEAT": "Scratchy Beat",
    "SOUNDS_SCREAM1": "Scream1",
    "SOUNDS_SCREAM2": "Scream2",
    "SOUNDS_SCREECH": "Screech",
    "SOUNDS_SEAGULLS": "Seagulls",
    "SOUNDS_SEWING_MACHINE": "Sewing Machine",
    "SOUNDS_SHAKER": "Shaker",
    "SOUNDS_SHIP_BELL": "Ship Bell",
    "SOUNDS_SIDESTICK_SNARE": "Sidestick Snare",
    "SOUNDS_SINGER1": "Singer1",
    "SOUNDS_SINGER2": "Singer2",
    "SOUNDS_SIREN_WHISTLE": "Siren Whistle",
    "SOUNDS_SKID": "Skid",
    "SOUNDS_SLIDE_WHISTLE": "Slide Whistle",
    "SOUNDS_SMALL_COWBELL": "Small Cowbell",
    "SOUNDS_SNAP": "Snap",
    "SOUNDS_SNARE_BEATBOX": "Snare Beatbox",
    "SOUNDS_SNARE_BEATBOX2": "Snare Beatbox2",
    "SOUNDS_SNARE_DRUM": "Snare Drum",
    "SOUNDS_SNEAKER_SQUEAK": "Sneaker Squeak",
    "SOUNDS_SNEEZE1": "Sneeze1",
    "SOUNDS_SNEEZE2": "Sneeze2",
    "SOUNDS_SNORING": "Snoring",
    "SOUNDS_SNORT": "Snort",
    "SOUNDS_SPACE_AMBIENCE": "Space Ambience",
    "SOUNDS_SPACE_FLYBY": "Space Flyby",
    "SOUNDS_SPACE_NOISE": "Space Noise",
    "SOUNDS_SPACE_RIPPLE": "Space Ripple",
    "SOUNDS_SPIRAL": "Spiral",
    "SOUNDS_SPLASH": "Splash",
    "SOUNDS_SPLASH_CYMBAL": "Splash Cymbal",
    "SOUNDS_SPOOKY_STRING": "Spooky String",
    "SOUNDS_SQUAWK": "Squawk",
    "SOUNDS_SQUEAKS": "Squeaks",
    "SOUNDS_SQUEAKY_TOY": "Squeaky Toy",
    "SOUNDS_SQUISH_POP": "Squish Pop",
    "SOUNDS_STRING_ACCENT": "String Accent",
    "SOUNDS_STRING_PLUCK": "String Pluck",
    "SOUNDS_SUCTION_CUP": "Suction Cup",
    "SOUNDS_SUSPENSE": "Suspense",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura",
    "SOUNDS_TAP_CONGA": "Tap Conga",
    "SOUNDS_TAP_SNARE": "Tap Snare",
    "SOUNDS_TECHNO": "Techno",
    "SOUNDS_TECHNO2": "Techno2",
    "SOUNDS_TELEPHONE_RING": "Telephone Ring",
    "SOUNDS_TELEPHONE_RING2": "Telephone Ring2",
    "SOUNDS_TELEPORT": "Teleport",
    "SOUNDS_TELEPORT2": "Teleport2",
    "SOUNDS_TELEPORT3": "Teleport3",
    "SOUNDS_TENNIS_HIT": "Tennis Hit",
    "SOUNDS_THUNDER_STORM": "Thunder Storm",
    "SOUNDS_TOM_DRUM": "Tom Drum",
    "SOUNDS_TOY_HONK": "Toy Honk",
    "SOUNDS_TOY_ZING": "Toy Zing",
    "SOUNDS_TRAFFIC": "Traffic",
    "SOUNDS_TRAIN_WHISTLE": "Train Whistle",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_TRIUMPH": "Triumph",
    "SOUNDS_TROPICAL_BIRDS": "Tropical Birds",
    "SOUNDS_TRUMPET1": "Trumpet1",
    "SOUNDS_TRUMPET2": "Trumpet2",
    "SOUNDS_VIDEO_GAME_1": "Video Game 1",
    "SOUNDS_VIDEO_GAME_2": "Video Game 2",
    "SOUNDS_WAH_BEATBOX": "Wah Beatbox",
    "SOUNDS_WAND": "Wand",
    "SOUNDS_WATER_DROP": "Water drop",
    "SOUNDS_WHINNY": "Whinny",
    "SOUNDS_WHISTLE_THUMP": "Whistle Thump",
    "SOUNDS_WHIZ": "Whiz",
    "SOUNDS_WHOOP": "Whoop",
    "SOUNDS_WIN": "Win",
    "SOUNDS_WOBBLE": "Wobble",
    "SOUNDS_WOLF_HOWL": "Wolf Howl",
    "SOUNDS_WOOD_TAP": "Wood Tap",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "Xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_APPLE": "Apple",
    "SPRITES_ARROW1": "Arrow1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Walking",
    "SPRITES_BALL": "Ball",
    "SPRITES_BALLERINA": "Ballerina",
    "SPRITES_BALLOON1": "Balloon1",
    "SPRITES_BANANAS": "Bananas",
    "SPRITES_BASEBALL": "Baseball",
    "SPRITES_BASKETBALL": "Basketball",
    "SPRITES_BAT": "Bat",
    "SPRITES_BATTER": "Batter",
    "SPRITES_BEACHBALL": "Beachball",
    "SPRITES_BEAR": "Bear",
    "SPRITES_BEAR-WALKING": "Bear-walking",
    "SPRITES_BEETLE": "Beetle",
    "SPRITES_BELL": "Bell",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Bowl",
    "SPRITES_BOWTIE": "Bowtie",
    "SPRITES_BREAD": "Bread",
    "SPRITES_BROOM": "Broom",
    "SPRITES_BUILDINGS": "Buildings",
    "SPRITES_BUTTERFLY_1": "Butterfly 1",
    "SPRITES_BUTTERFLY_2": "Butterfly 2",
    "SPRITES_BUTTON1": "Button1",
    "SPRITES_BUTTON2": "Button2",
    "SPRITES_BUTTON3": "Button3",
    "SPRITES_BUTTON4": "Button4",
    "SPRITES_BUTTON5": "Button5",
    "SPRITES_CAKE": "Cake",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Cat 2",
    "SPRITES_CATCHER": "Catcher",
    "SPRITES_CENTAUR": "Centaur",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Chick",
    "SPRITES_CITY_BUS": "City Bus",
    "SPRITES_CLOUD": "Cloud",
    "SPRITES_CLOUDS": "Clouds",
    "SPRITES_CONVERTIBLE": "Convertible",
    "SPRITES_CONVERTIBLE_2": "Convertible 2",
    "SPRITES_CRAB": "Crab",
    "SPRITES_CRYSTAL": "Crystal",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "Dinosaur1",
    "SPRITES_DINOSAUR2": "Dinosaur2",
    "SPRITES_DINOSAUR3": "Dinosaur3",
    "SPRITES_DINOSAUR4": "Dinosaur4",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DIVER1": "Diver1",
    "SPRITES_DIVER2": "Diver2",
    "SPRITES_DOG1": "Dog1",
    "SPRITES_DOG2": "Dog2",
    "SPRITES_DONUT": "Donut",
    "SPRITES_DORIAN": "Dorian",
    "SPRITES_DOT": "Dot",
    "SPRITES_DOVE": "Dove",
    "SPRITES_DRAGON": "Dragon",
    "SPRITES_DRAGONFLY": "Libellule",
    "SPRITES_DRESS": "Dress",
    "SPRITES_DRUM": "Drum",
    "SPRITES_DRUM_KIT": "Drum Kit",
    "SPRITES_DRUM-CYMBAL": "Drum-cymbal",
    "SPRITES_DRUM-HIGHHAT": "Drum-highhat",
    "SPRITES_DRUM-SNARE": "Drum-snare",
    "SPRITES_DRUMS_CONGA": "Drums Conga",
    "SPRITES_DRUMS_TABLA": "Drums Tabla",
    "SPRITES_DUCK": "Duck",
    "SPRITES_EARTH": "Earth",
    "SPRITES_EASEL": "Easel",
    "SPRITES_EGG": "Egg",
    "SPRITES_ELEPHANT": "Elephant",
    "SPRITES_ELF": "Elf",
    "SPRITES_FAIRY": "Fairy",
    "SPRITES_FISH": "Fish",
    "SPRITES_FISHBOWL": "Fishbowl",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Football",
    "SPRITES_FORTUNE_COOKIE": "Fortune Cookie",
    "SPRITES_FOX": "Fox",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "Frog",
    "SPRITES_FROG_2_": "Frog 2 ",
    "SPRITES_FRUIT_PLATTER": "Fruit Platter",
    "SPRITES_FRUIT_SALAD": "Fruit Salad",
    "SPRITES_GHOST": "Ghost",
    "SPRITES_GIFT": "Gift",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GLASS_WATER": "Glass Water",
    "SPRITES_GLASSES": "Glasses",
    "SPRITES_GOALIE": "Goalie",
    "SPRITES_GOBLIN": "Goblin",
    "SPRITES_GRASSHOPPER": "Grasshopper",
    "SPRITES_GREEN_FLAG": "Green Flag",
    "SPRITES_GRIFFIN": "Griffin",
    "SPRITES_GUITAR": "Guitar",
    "SPRITES_GUITAR-ELECTRIC1": "Guitar-electric1",
    "SPRITES_GUITAR-ELECTRIC2": "Guitar-electric2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hare",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hat1 ",
    "SPRITES_HATCHLING": "Hatchling",
    "SPRITES_HEART": "Heart",
    "SPRITES_HEART_CANDY": "Heart Candy",
    "SPRITES_HEART_FACE": "Heart Face",
    "SPRITES_HEDGEHOG": "Hedgehog",
    "SPRITES_HEN": "Hen",
    "SPRITES_HIPPO1": "Hippo1",
    "SPRITES_HOME_BUTTON": "Home Button",
    "SPRITES_HORSE": "Horse",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "Jar",
    "SPRITES_JELLYFISH": "Jellyfish",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "Key",
    "SPRITES_KEYBOARD": "Keyboard",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Knight",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_LADYBUG1": "Ladybug1",
    "SPRITES_LADYBUG2": "Ladybug2",
    "SPRITES_LAPTOP": "Laptop",
    "SPRITES_LIGHTNING": "Lightning",
    "SPRITES_LINE": "Line",
    "SPRITES_LION": "Lion",
    "SPRITES_LLAMA": "Llama",
    "SPRITES_MAGIC_WAND": "Magic Wand",
    "SPRITES_MAX": "Max",
    "SPRITES_MERMAID": "Mermaid",
    "SPRITES_MICROPHONE": "Microphone",
    "SPRITES_MILK": "Milk",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Monkey",
    "SPRITES_MOTORCYCLE": "Motorcycle",
    "SPRITES_MOUSE1": "Mouse1",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": "Neigh Pony",
    "SPRITES_OCTOPUS": "Octopus",
    "SPRITES_ORANGE": "Orange",
    "SPRITES_ORANGE2": "Orange2",
    "SPRITES_OUTFIELDER": "Outfielder",
    "SPRITES_OWL": "Owl",
    "SPRITES_PADDLE": "Paddle",
    "SPRITES_PANTHER": "Panther",
    "SPRITES_PANTS": "Pants",
    "SPRITES_PARROT": "Parrot",
    "SPRITES_PARTY_HATS": "Party Hats",
    "SPRITES_PENCIL": "Pencil",
    "SPRITES_PENGUIN": "Penguin",
    "SPRITES_PENGUIN_2": "Penguin 2",
    "SPRITES_PITCHER": "Pitcher",
    "SPRITES_PLANET2": "Planet2",
    "SPRITES_POLAR_BEAR": "Polar Bear",
    "SPRITES_POTION": "Potion",
    "SPRITES_PRINCE": "Prince",
    "SPRITES_PRINCESS": "Princess",
    "SPRITES_PUFFERFISH": "Pufferfish",
    "SPRITES_PUPPY": "Puppy",
    "SPRITES_RABBIT": "Rabbit",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": "Rainbow",
    "SPRITES_REFEREE": "Referee",
    "SPRITES_REINDEER": "Reindeer",
    "SPRITES_RETRO_ROBOT": "Retro Robot",
    "SOUNDS_COMPUTER_BEEPS1": "computer beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "computer beeps2",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Robot",
    "SPRITES_ROCKETSHIP": "fusée",
    "SPRITES_ROCKS": "Rocks",
    "SPRITES_ROOSTER": "Rooster",
    "SPRITES_RUBY": "Ruby",
    "SPRITES_SAILBOAT": "Sailboat",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "Saxophone",
    "SPRITES_SCARF": "Scarf",
    "SPRITES_SHARK": "requin",
    "SPRITES_SHARK_2": "requin 2",
    "SPRITES_SHIRT": "Shirt",
    "SPRITES_SHOES": "Shoes",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "Singer1",
    "SPRITES_SKELETON": "Skeleton",
    "SPRITES_SNAKE": "Snake",
    "SPRITES_SNOWFLAKE": "Snowflake",
    "SPRITES_SNOWMAN": "Snowman",
    "SPRITES_SOCCER_BALL": "Ballon de Foot",
    "SPRITES_SPEAKER": "Speaker",
    "SPRITES_SQUIRREL": "Squirrel",
    "SPRITES_STAR": "Star",
    "SPRITES_STARFISH": "Starfish",
    "SPRITES_STOP": "Stop",
    "SPRITES_STRAWBERRY": "Strawberry",
    "SPRITES_SUN": "Sun",
    "SPRITES_SUNGLASSES1": "Sunglasses1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Takeout",
    "SPRITES_TEN80_DANCE": "Ten80 Dance",
    "SPRITES_TENNIS_BALL": "Tennis Ball",
    "SPRITES_TOUCAN": "Toucan",
    "SPRITES_TRAMPOLINE": "Trampoline",
    "SPRITES_TREE1": "Tree1",
    "SPRITES_TREES": "Trees",
    "SPRITES_TRUCK": "Truck",
    "SPRITES_TRUMPET": "Trumpet",
    "SPRITES_UNICORN": "Unicorn",
    "SPRITES_UNICORN_2": "Unicorn 2",
    "SPRITES_UNICORN_RUNNING": "Unicorn Running",
    "SPRITES_WAND": "Wand",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Watermelon",
    "SPRITES_WINTER_HAT": "Winter Hat",
    "SPRITES_WITCH": "Witch",
    "SPRITES_WIZARD": "Wizard",
    "SPRITES_WIZARD_HAT": "Wizard Hat",
    "SPRITES_WIZARD-TOAD": "Wizard-toad",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "Block-A",
    "SPRITES_BLOCK-B": "Block-B",
    "SPRITES_BLOCK-C": "Block-C",
    "SPRITES_BLOCK-D": "Block-D",
    "SPRITES_BLOCK-E": "Block-E",
    "SPRITES_BLOCK-F": "Block-F",
    "SPRITES_BLOCK-G": "Block-G",
    "SPRITES_BLOCK-H": "Block-H",
    "SPRITES_BLOCK-I": "Block-I",
    "SPRITES_BLOCK-J": "Block-J",
    "SPRITES_BLOCK-K": "Block-K",
    "SPRITES_BLOCK-L": "Block-L",
    "SPRITES_BLOCK-M": "Block-M",
    "SPRITES_BLOCK-N": "Block-N",
    "SPRITES_BLOCK-O": "Block-O",
    "SPRITES_BLOCK-P": "Block-P",
    "SPRITES_BLOCK-Q": "Block-Q",
    "SPRITES_BLOCK-R": "Block-R",
    "SPRITES_BLOCK-S": "Block-S",
    "SPRITES_BLOCK-T": "Block-T",
    "SPRITES_BLOCK-U": "Block-U",
    "SPRITES_BLOCK-V": "Block-V",
    "SPRITES_BLOCK-W": "Block-W",
    "SPRITES_BLOCK-X": "Block-X",
    "SPRITES_BLOCK-Y": "Block-Y",
    "SPRITES_BLOCK-Z": "Block-Z",
    "SPRITES_GLOW-0": "Glow-0",
    "SPRITES_GLOW-1": "Glow-1",
    "SPRITES_GLOW-2": "Glow-2",
    "SPRITES_GLOW-3": "Glow-3",
    "SPRITES_GLOW-4": "Glow-4",
    "SPRITES_GLOW-5": "Glow-5",
    "SPRITES_GLOW-6": "Glow-6",
    "SPRITES_GLOW-7": "Glow-7",
    "SPRITES_GLOW-8": "Glow-8",
    "SPRITES_GLOW-9": "Glow-9",
    "SPRITES_GLOW-A": "Glow-A",
    "SPRITES_GLOW-B": "Glow-B",
    "SPRITES_GLOW-C": "Glow-C",
    "SPRITES_GLOW-D": "Glow-D",
    "SPRITES_GLOW-E": "Glow-E",
    "SPRITES_GLOW-F": "Glow-F",
    "SPRITES_GLOW-G": "Glow-G",
    "SPRITES_GLOW-H": "Glow-H",
    "SPRITES_GLOW-I": "Glow-I",
    "SPRITES_GLOW-J": "Glow-J",
    "SPRITES_GLOW-K": "Glow-K",
    "SPRITES_GLOW-L": "Glow-L",
    "SPRITES_GLOW-M": "Glow-M",
    "SPRITES_GLOW-N": "Glow-N",
    "SPRITES_GLOW-O": "Glow-O",
    "SPRITES_GLOW-P": "Glow-P",
    "SPRITES_GLOW-Q": "Glow-Q",
    "SPRITES_GLOW-R": "Glow-R",
    "SPRITES_GLOW-S": "Glow-S",
    "SPRITES_GLOW-T": "Glow-T",
    "SPRITES_GLOW-U": "Glow-U",
    "SPRITES_GLOW-V": "Glow-V",
    "SPRITES_GLOW-W": "Glow-W",
    "SPRITES_GLOW-X": "Glow-X",
    "SPRITES_GLOW-Y": "Glow-Y",
    "SPRITES_GLOW-Z": "Glow-Z",
    "SPRITES_STORY-A": "Story-A",
    "SPRITES_STORY-B": "Story-B",
    "SPRITES_STORY-C": "Story-C",
    "SPRITES_STORY-D": "Story-D",
    "SPRITES_STORY-E": "Story-E",
    "SPRITES_STORY-F": "Story-F",
    "SPRITES_STORY-G": "Story-G",
    "SPRITES_STORY-H": "Story-H",
    "SPRITES_STORY-I": "Story-I",
    "SPRITES_STORY-J": "Story-J",
    "SPRITES_STORY-K": "Story-K",
    "SPRITES_STORY-L": "Story-L",
    "SPRITES_STORY-M": "Story-M",
    "SPRITES_STORY-N": "Story-N",
    "SPRITES_STORY-O": "Story-O",
    "SPRITES_STORY-P": "Story-P",
    "SPRITES_STORY-Q": "Story-Q",
    "SPRITES_STORY-R": "Story-R",
    "SPRITES_STORY-S": "Story-S",
    "SPRITES_STORY-T": "Story-T",
    "SPRITES_STORY-U": "Story-U",
    "SPRITES_STORY-V": "Story-V",
    "SPRITES_STORY-W": "Story-W",
    "SPRITES_STORY-X": "Story-X",
    "SPRITES_STORY-Y": "Story-Y",
    "SPRITES_STORY-Z": "Story-Z"
  },
  "ga": {
    "gui.backpack.header": "Mála Droma",
    "gui.backpack.errorBackpack": "Earráid agus an mála droma á lódáil",
    "gui.backpack.loadingBackpack": "Á Lódáil...",
    "gui.backpack.more": "Tuilleadh",
    "gui.backpack.emptyBackpack": "Tá an mála droma folamh",
    "gui.gui.costumesTab": "Cultacha",
    "gui.gui.soundsTab": "Fuaimeanna",
    "gui.gui.backdropsTab": "Cúlraí",
    "gui.gui.addExtension": "Cuir Breiseán Leis",
    "gui.costumeTab.addCostumeFromLibrary": "Roghnaigh Culaith",
    "gui.costumeLibrary.chooseACostume": "Roghnaigh Culaith",
    "gui.costumeTab.addBackdropFromLibrary": "Roghnaigh Cúlra",
    "gui.costumeTab.addBlankCostume": "Péint",
    "gui.costumeTab.addSurpriseCostume": "Randamach",
    "gui.costumeTab.addFileBackdrop": "Uaslódáil Cúlra",
    "gui.costumeTab.addFileCostume": "Uaslódáil Culaith",
    "gui.costumeTab.addCameraCostume": "Ceamara",
    "gui.soundEditor.trim": "Bearr",
    "gui.soundEditor.stop": "Stop",
    "gui.soundEditor.sound": "Fuaim",
    "gui.soundEditor.play": "Seinn",
    "gui.soundEditor.save": "Sábháil",
    "gui.soundEditor.undo": "Cealaigh",
    "gui.soundEditor.redo": "Athdhéan",
    "gui.soundEditor.faster": "Níos Sciobtha",
    "gui.soundEditor.slower": "Níos moille",
    "gui.soundEditor.echo": "Macalla",
    "gui.soundEditor.robot": "Róbat",
    "gui.soundEditor.louder": "Níos Airde",
    "gui.soundEditor.softer": "Níos Ciúine",
    "gui.soundEditor.reverse": "Aisiompaigh",
    "gui.soundTab.recordSound": "Taifead",
    "gui.soundTab.addSoundFromLibrary": "Roghnaigh Fuaim",
    "gui.soundTab.surpriseSound": "Randamach",
    "gui.soundTab.fileUploadSound": "Uaslódáil Comhad Fuaime",
    "gui.controls.stop": "Stop",
    "gui.controls.go": "Tosaigh",
    "gui.stageHeader.stageSizeUnFull": "Scoir den lánscáileán",
    "gui.SpriteInfo.show": "Taispeáin",
    "gui.SpriteInfo.size": "Méid",
    "gui.directionPicker.rotationStyles.allAround": "Timpeall ar fad",
    "gui.directionPicker.rotationStyles.leftRight": "Clé/Deas",
    "gui.directionPicker.rotationStyles.dontRotate": "Ná rothlaigh",
    "gui.spriteSelectorItem.contextMenuDuplicate": "cóip",
    "gui.spriteSelectorItem.contextMenuDelete": "scrios",
    "gui.spriteSelectorItem.contextMenuExport": "easpórtáil",
    "gui.spriteSelector.addSpriteFromPaint": "Péint",
    "gui.spriteSelector.addSpriteFromSurprise": "Randamach",
    "gui.spriteSelector.addSpriteFromFile": "Uaslódáil Sprid",
    "gui.stageSelector.stage": "Stáitse",
    "gui.stageSelector.backdrops": "Cúlraí",
    "gui.stageSelector.addBackdropFromPaint": "Péint",
    "gui.stageSelector.addBackdropFromSurprise": "Randamach",
    "gui.stageSelector.addBackdropFromFile": "Uaslódáil Cúlra",
    "gui.modal.back": "Chun deiridh",
    "gui.library.filterPlaceholder": "Cuardaigh",
    "gui.libraryTags.all": "Uile",
    "gui.libraryTags.animals": "Ainmhithe",
    "gui.libraryTags.dance": "Damhsa",
    "gui.libraryTags.effects": "Maisíochtaí",
    "gui.libraryTags.fantasy": "Fantaisíocht",
    "gui.libraryTags.fashion": "Faisean",
    "gui.libraryTags.food": "Bia",
    "gui.libraryTags.indoors": "Taobh Istigh",
    "gui.libraryTags.loops": "Lúba",
    "gui.libraryTags.music": "Ceol",
    "gui.libraryTags.notes": "Nótaí",
    "gui.libraryTags.outdoors": "Amuigh Faoin Aer",
    "gui.libraryTags.patterns": "Patrúin",
    "gui.libraryTags.people": "Daoine",
    "gui.libraryTags.percussion": "Cnaguirlisí",
    "gui.libraryTags.space": "Spás",
    "gui.libraryTags.sports": "Spórt",
    "gui.libraryTags.underwater": "Faoi uisce",
    "gui.libraryTags.voice": "Guth",
    "gui.libraryTags.wacky": "Wacky",
    "gui.libraryTags.animation": "Beochan",
    "gui.libraryTags.art": "Ealaín",
    "gui.libraryTags.games": "Cluichí",
    "gui.libraryTags.stories": "Scéalta",
    "gui.libraryTags.letters": "Litreacha",
    "gui.soundLibrary.chooseASound": "Roghnaigh Fuaim",
    "gui.SpriteInfo.spritePlaceholder": "Ainm",
    "gui.cards.more-things-to-try": "Tuilleadh rudaí le triail a bhaint astu!",
    "gui.cards.see-more": "Tuilleadh",
    "gui.cards.shrink": "Laghdaigh",
    "gui.cards.expand": "Leathnaigh",
    "gui.cards.close": "Dún",
    "gui.loader.message1": "Blocanna á gcruthú...",
    "gui.loader.message2": "Sprideanna á lódáil...",
    "gui.loader.message3": "Fuaimeanna á lódáil...",
    "gui.loader.message4": "Breiseáin á lódáil...",
    "gui.loader.message5": "Cait á gcluicheadh...",
    "gui.loader.message6": "Nanónna á dtarchur...",
    "gui.loader.message7": "Góbónna á séideadh...",
    "gui.loader.message8": "Emoji á n-ullmhú...",
    "gui.loader.headline": "Tionscadal á Lódáil",
    "gui.cameraModal.cameraModalTitle": "Glac Pictiúr",
    "gui.cameraModal.loadingCameraMessage": "Ceamara á Lódáil...",
    "gui.cameraModal.permissionRequest": "Caithfidh tú cead a thabhairt dúinn do cheamara a úsáid",
    "gui.cameraModal.retakePhoto": "Glac Pictiúr Arís",
    "gui.cameraModal.save": "Sábháil",
    "gui.cameraModal.takePhoto": "Glac Pictiúr",
    "gui.cameraModal.loadingCaption": "Á Lódáil...",
    "gui.cameraModal.enableCameraCaption": "Cumasaigh an Ceamara",
    "gui.recordModal.title": "Taifeadadh",
    "gui.playbackStep.stopMsg": "Stop",
    "gui.playbackStep.playMsg": "Seinn",
    "gui.playbackStep.loadingMsg": "Á Lódáil...",
    "gui.playbackStep.saveMsg": "Sábháil",
    "gui.playbackStep.reRecordMsg": "Ataifead",
    "gui.webglModal.label": "Ní thacaíonn do bhrabhsálaí le WebGL",
    "gui.webglModal.webgllink": "nach dtacaíonn do bhrabhsálaí le WebGL",
    "gui.prompt.cancel": "Cealaigh",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Cuir réimse ionchurtha leis",
    "gui.customProcedures.addAnInputBoolean": "Cuir réimse ionchurtha leis",
    "gui.customProcedures.numberTextType": "uimhir nó téacs",
    "gui.customProcedures.booleanType": "athróg Boole",
    "gui.customProcedures.addALabel": "Cuir lipéad leis",
    "gui.customProcedures.runWithoutScreenRefresh": "Rith gan an scáileán a athnuachan",
    "gui.customProcedures.cancel": "Cealaigh",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Cuir do chuid tionscadal os comhair an domhain.",
    "gui.extension.music.name": "Ceol",
    "gui.extension.music.description": "Casadh ceoil le hionstraimí agus le drumaí.",
    "gui.extension.pen.name": "Peann",
    "gui.extension.pen.description": "Dearadh le do chuid sprideanna.",
    "gui.gui.variableScopeOptionAllSprites": "Na sprideanna go léir",
    "gui.gui.variableScopeOptionSpriteOnly": "An sprid seo amháin",
    "gui.gui.variablePromptAllSpritesMessage": "Beidh an athróg seo ar fáil do gach sprid.",
    "gui.monitor.contextMenu.default": "gnáth-asléamh",
    "gui.monitor.contextMenu.large": "asléamh mór",
    "gui.monitor.contextMenu.slider": "sleamhnán",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "treo",
    "gui.opcodeLabels.xposition": "ionad x",
    "gui.opcodeLabels.yposition": "ionad y",
    "gui.opcodeLabels.size": "méid",
    "gui.opcodeLabels.costumename": "ainm na culaithe",
    "gui.opcodeLabels.costumenumber": "uimhir na culaithe",
    "gui.opcodeLabels.backdropname": "ainm an chúlra",
    "gui.opcodeLabels.backdropnumber": "uimhir an chúlra",
    "gui.opcodeLabels.volume": "airde",
    "gui.opcodeLabels.tempo": "luas",
    "gui.opcodeLabels.answer": "freagra",
    "gui.opcodeLabels.loudness": "treise",
    "gui.opcodeLabels.year": "bliain",
    "gui.opcodeLabels.month": "mí",
    "gui.opcodeLabels.date": "dáta",
    "gui.opcodeLabels.dayofweek": "lá den tseachtain",
    "gui.opcodeLabels.hour": "uair",
    "gui.opcodeLabels.minute": "nóiméad",
    "gui.opcodeLabels.second": "soicind",
    "gui.opcodeLabels.timer": "amadóir",
    "music.categoryName": "Ceol",
    "translate.categoryName": "Aistriúchán",
    "pen.categoryName": "Peann",
    "pen.changeColorParam": "athraigh [COLOR_PARAM] an phinn de [VALUE]",
    "pen.changeHue": "athraigh dath an phinn de [HUE]",
    "pen.changeShade": "athraigh dorchadas an phinn de [SHADE]",
    "pen.changeSize": "athraigh méid an phinn de [SIZE]",
    "pen.clear": "scrios uile",
    "pen.colorMenu.brightness": "gile",
    "pen.colorMenu.color": "dath",
    "pen.colorMenu.saturation": "sáithiú",
    "pen.colorMenu.transparency": "trédhearcacht",
    "pen.penDown": "peann síos",
    "pen.penUp": "peann suas",
    "pen.setColor": "socraigh dath an phinn: [COLOR]",
    "pen.setColorParam": "socraigh [COLOR_PARAM] an phinn: [VALUE]",
    "pen.setHue": "socraigh dath an phinn: [HUE]",
    "pen.setShade": "socraigh dorchadas an phinn: [SHADE]",
    "pen.setSize": "socraigh méid an phinn: [SIZE]",
    "pen.stamp": "stampa",
    "music.changeTempo": "athraigh an luas de [TEMPO]",
    "music.drumBass": "(2) Dord-Druma",
    "music.drumBongo": "(13) Bongó",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Clabhaí",
    "music.drumClosedHiHat": "(6) Ard-hata dúnta",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Clog Bó",
    "music.drumCrashCymbal": "(4) Ciombal Plimpe",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guíoró",
    "music.drumHandClap": "(8) Bualadh Bos",
    "music.drumOpenHiHat": "(5) Ard-Hata Oscailte",
    "music.drumSideStick": "(3) Maide Taoibh",
    "music.drumSnare": "(1) Sreangdhruma",
    "music.drumTambourine": "(7) Tambóirín",
    "music.drumTriangle": "(12) Triantán",
    "music.drumVibraslap": "(17) Vibreaslap",
    "music.drumWoodBlock": "(10) Bloc Adhmaid",
    "music.getTempo": "luas",
    "music.instrumentBass": "(6) Dord",
    "music.instrumentBassoon": "(14) Basún",
    "music.instrumentCello": "(8) Dordveidhil",
    "music.instrumentChoir": "(15) Cór",
    "music.instrumentClarinet": "(10) Clairinéad",
    "music.instrumentElectricGuitar": "(5) Giotár Leictreach",
    "music.instrumentElectricPiano": "(2) Pianó Leictreach",
    "music.instrumentFlute": "(12) Fliúit",
    "music.instrumentGuitar": "(4) Giotár",
    "music.instrumentMarimba": "(19) Mairimbe",
    "music.instrumentMusicBox": "(17) Ceolbhosca",
    "music.instrumentOrgan": "(3) Orgán",
    "music.instrumentPiano": "(1) Pianó",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Sacsafón",
    "music.instrumentSteelDrum": "(18) Druma Cruach",
    "music.instrumentSynthLead": "(20) Príomhshintéiseoir",
    "music.instrumentSynthPad": "(21) Ceapshintéiseoir",
    "music.instrumentTrombone": "(9) Trombón",
    "music.instrumentVibraphone": "(16) Vibreafón",
    "music.instrumentWoodenFlute": "(13) Fliúit Adhmaid",
    "music.midiPlayDrumForBeats": "seinn druma [DRUM] ar feadh [BEATS] buille",
    "music.midiSetInstrument": "socraigh an ionstraim: [INSTRUMENT]",
    "music.playDrumForBeats": "seinn druma [DRUM] ar feadh [BEATS] buille",
    "music.playNoteForBeats": "seinn nóta [NOTE] ar feadh [BEATS] buille",
    "music.restForBeats": "stad ar feadh [BEATS] buille",
    "music.setInstrument": "socraigh an ionstraim: [INSTRUMENT]",
    "music.setTempo": "socraigh an luas: [TEMPO]",
    "videoSensing.categoryName": "Físbhraiteacht",
    "videoSensing.direction": "treo",
    "videoSensing.motion": "gluaiseacht",
    "videoSensing.off": "as",
    "videoSensing.on": "ann",
    "videoSensing.onFlipped": "ann-smeachta",
    "videoSensing.setVideoTransparency": "socraigh an trédhearcacht físe: [TRANSPARENCY]",
    "videoSensing.sprite": "sprid",
    "videoSensing.stage": "stáitse",
    "videoSensing.videoOn": "[ATTRIBUTE] físe ar [SUBJECT]",
    "videoSensing.videoToggle": "cas an físeán [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "Nuair atá físghluaiseacht > [REFERENCE]",
    "translate.translateBlock": "aistrigh [WORDS] go [LANGUAGE]",
    "translate.defaultTextToTranslate": "dia dhuit",
    "translate.viewerLanguage": "teanga",
    "text2speech.speakAndWaitBlock": "abair [WORDS]",
    "text2speech.setVoiceBlock": "socraigh an guth: [VOICE]",
    "text2speech.setLanguageBlock": "socraigh an teanga: [LANGUAGE]",
    "text2speech.alto": "alt",
    "text2speech.tenor": "teanór",
    "text2speech.squeak": "gíog",
    "text2speech.giant": "fathach",
    "text2speech.kitten": "piscín",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "nuair a chloisim [PHRASE]",
    "speech.listenAndWait": "éist agus fan",
    "speech.defaultWhenIHearValue": "ar aghaidh linn",
    "paint.paintEditor.hue": "Dath",
    "paint.paintEditor.saturation": "Sáithiú",
    "paint.paintEditor.brightness": "Gile",
    "gui.comingSoon.message1": "Ná buair do cheann, táimid ag plé leis {emoji}",
    "gui.comingSoon.message2": "Le teacht go luath...",
    "gui.comingSoon.message3": "Táimid ag plé leis {emoji}",
    "paint.paintEditor.fill": "Líon",
    "paint.paintEditor.costume": "Culaith",
    "paint.paintEditor.group": "Grúpa",
    "paint.paintEditor.ungroup": "Díghrúpáil",
    "paint.paintEditor.undo": "Cealaigh",
    "paint.paintEditor.redo": "Athdhéan",
    "paint.paintEditor.forward": "Ar aghaidh",
    "paint.paintEditor.backward": "Ar gcúl",
    "paint.paintEditor.front": "Chun tosaigh",
    "paint.paintEditor.back": "Chun deiridh",
    "paint.paintEditor.more": "Tuilleadh",
    "paint.modeTools.brushSize": "Méid",
    "paint.modeTools.eraserSize": "Méid an léirscriosáin",
    "paint.modeTools.copy": "Cóipeáil",
    "paint.modeTools.paste": "Greamaigh",
    "paint.modeTools.delete": "Scrios",
    "paint.modeTools.curved": "Cuartha",
    "paint.modeTools.pointed": "Bioraithe",
    "paint.modeTools.thickness": "Leithead",
    "paint.modeTools.flipHorizontal": "Smeach go cothrománach",
    "paint.modeTools.flipVertical": "Smeach go hingearach",
    "paint.modeTools.filled": "Líonta",
    "paint.modeTools.outlined": "Imlínithe",
    "paint.paintEditor.bitmap": "Tiontaigh go mapa giotán",
    "paint.paintEditor.vector": "Mód Veicteora",
    "paint.paintEditor.stroke": "Imlíne",
    "paint.brushMode.brush": "Scuab",
    "paint.eraserMode.eraser": "Léirscriosán",
    "paint.fillMode.fill": "Líon",
    "paint.lineMode.line": "Líne",
    "paint.ovalMode.oval": "Ciorcal",
    "paint.rectMode.rect": "Dronuilleog",
    "paint.reshapeMode.reshape": "Athdheilbhigh",
    "paint.roundedRectMode.roundedRect": "Dronuilleog Chruinn",
    "paint.selectMode.select": "Roghnaigh",
    "paint.textMode.text": "Téacs",
    "paint.colorPicker.swap": "Babhtáil",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "gd": {
    "gui.backpack.header": "Màla-droma",
    "gui.backpack.errorBackpack": "Mearachd le luchdadh na màla-droma",
    "gui.backpack.loadingBackpack": "’Ga luchdadh…",
    "gui.backpack.more": "Barrachd",
    "gui.backpack.emptyBackpack": "Tha a’ mhàla-droma falamh",
    "gui.gui.costumesTab": "Dreachan",
    "gui.gui.soundsTab": "Fuaimean",
    "gui.gui.backdropsTab": "Cùlaibhean",
    "gui.gui.addExtension": "Cuir leudachan ris",
    "gui.costumeTab.addCostumeFromLibrary": "Tagh dreach",
    "gui.costumeLibrary.chooseACostume": "Tagh dreach",
    "gui.costumeTab.addBackdropFromLibrary": "Tagh cùlaibh",
    "gui.costumeTab.addBlankCostume": "Peant",
    "gui.costumeTab.addSurpriseCostume": "Tagh fear dhomh",
    "gui.costumeTab.addFileBackdrop": "Luchdaich suas cùlaibh",
    "gui.costumeTab.addFileCostume": "Luchdaich suas dreach",
    "gui.costumeTab.addCameraCostume": "Camara",
    "gui.soundEditor.trim": "Beàrr",
    "gui.soundEditor.stop": "Cuir stad air",
    "gui.soundEditor.sound": "Fuaim",
    "gui.soundEditor.play": "Cluich",
    "gui.soundEditor.save": "Sàbhail",
    "gui.soundEditor.undo": "Neo-dhèan",
    "gui.soundEditor.redo": "Ath-dhèan",
    "gui.soundEditor.faster": "Nas luaithe",
    "gui.soundEditor.slower": "Nas maille",
    "gui.soundEditor.echo": "Mac-talla",
    "gui.soundEditor.robot": "Robotair",
    "gui.soundEditor.louder": "Nas àirde",
    "gui.soundEditor.softer": "Nas ìsle",
    "gui.soundEditor.reverse": "Contrarra",
    "gui.soundTab.recordSound": "Clàraich",
    "gui.soundTab.addSoundFromLibrary": "Tagh fuaim",
    "gui.soundTab.surpriseSound": "Tagh tè dhomh",
    "gui.soundTab.fileUploadSound": "Luchdaich suas fuaim",
    "gui.controls.stop": "Cuir stad air",
    "gui.controls.go": "Siuthad",
    "gui.stageHeader.stageSizeUnFull": "Fàg modh na làn-sgrìn",
    "gui.SpriteInfo.show": "Seall",
    "gui.SpriteInfo.size": "Meud",
    "gui.directionPicker.rotationStyles.allAround": "Cuairt shlàn",
    "gui.directionPicker.rotationStyles.leftRight": "Gu clì/deas",
    "gui.directionPicker.rotationStyles.dontRotate": "Na cuairtich",
    "gui.spriteSelectorItem.contextMenuDuplicate": "dùblaich",
    "gui.spriteSelectorItem.contextMenuDelete": "sguab às",
    "gui.spriteSelectorItem.contextMenuExport": "às-phortaich",
    "gui.spriteSelector.addSpriteFromPaint": "Peant",
    "gui.spriteSelector.addSpriteFromSurprise": "Tagh tè dhomh",
    "gui.spriteSelector.addSpriteFromFile": "Luchdaich suas sprìd",
    "gui.stageSelector.stage": "Àrd-ùrlar",
    "gui.stageSelector.backdrops": "Cùlaibhean",
    "gui.stageSelector.addBackdropFromPaint": "Peant",
    "gui.stageSelector.addBackdropFromSurprise": "Tagh fear dhomh",
    "gui.stageSelector.addBackdropFromFile": "Luchdaich suas cùlaibh",
    "gui.modal.back": "Air ais",
    "gui.library.filterPlaceholder": "Lorg",
    "gui.libraryTags.all": "Na h-uile",
    "gui.libraryTags.animals": "Beathaichean",
    "gui.libraryTags.dance": "Dannsa",
    "gui.libraryTags.effects": "Èifeachdan",
    "gui.libraryTags.fantasy": "Fantastachd",
    "gui.libraryTags.fashion": "Fasan",
    "gui.libraryTags.food": "Biadh",
    "gui.libraryTags.indoors": "Taobh a-staigh",
    "gui.libraryTags.loops": "Lùban",
    "gui.libraryTags.music": "Ceòl",
    "gui.libraryTags.notes": "Pongan",
    "gui.libraryTags.outdoors": "Taobh a-muigh",
    "gui.libraryTags.patterns": "Pàtranan",
    "gui.libraryTags.people": "Daoine",
    "gui.libraryTags.percussion": "Beum-ionnsramaid",
    "gui.libraryTags.space": "Fànas",
    "gui.libraryTags.sports": "Spòrs",
    "gui.libraryTags.underwater": "Fon uisge",
    "gui.libraryTags.voice": "Guth",
    "gui.libraryTags.wacky": "Èibhinn",
    "gui.libraryTags.animation": "Beòthachadh",
    "gui.libraryTags.art": "Ealan",
    "gui.libraryTags.games": "Geamannan",
    "gui.libraryTags.stories": "Sgeòil",
    "gui.libraryTags.letters": "Litrichean",
    "gui.soundLibrary.chooseASound": "Tagh fuaim",
    "gui.SpriteInfo.spritePlaceholder": "Ainm",
    "gui.cards.more-things-to-try": "Barrachd rudan ri am feuchainn!",
    "gui.cards.see-more": "Seall a bharrachd",
    "gui.cards.shrink": "Crùb",
    "gui.cards.expand": "Leudaich",
    "gui.cards.close": "Dùin",
    "gui.loader.message1": "A’ cruthachadh nam blocaichean…",
    "gui.loader.message2": "A’ luchdadh nan sprìdean…",
    "gui.loader.message3": "A’ luchdadh nam fuaimean…",
    "gui.loader.message4": "A’ luchdadh nan leudachan…",
    "gui.loader.message5": "Cho foighidinneach ri cat…",
    "gui.loader.message6": "A’ slìobadh a’ chait…",
    "gui.loader.message7": "A’ leigeil an cat às a phòca…",
    "gui.loader.message8": "Ag ullachadh nan emojis…",
    "gui.loader.headline": "A’ luchdadh a’ phròiseict",
    "gui.cameraModal.cameraModalTitle": "Tog dealbh",
    "gui.cameraModal.loadingCameraMessage": "A’ luchdadh a’ chamara…",
    "gui.cameraModal.permissionRequest": "Tha sinn feumach air cead gus an camara agad a chleachdadh",
    "gui.cameraModal.retakePhoto": "Tog an dealbh a‑rithist",
    "gui.cameraModal.save": "Sàbhail",
    "gui.cameraModal.takePhoto": "Tog dealbh",
    "gui.cameraModal.loadingCaption": "’Ga luchdadh…",
    "gui.cameraModal.enableCameraCaption": "Cuir an camara an comas",
    "gui.recordModal.title": "Clàraich fuaim",
    "gui.playbackStep.stopMsg": "Cuir stad air",
    "gui.playbackStep.playMsg": "Cluich",
    "gui.playbackStep.loadingMsg": "Ga luchdadh…",
    "gui.playbackStep.saveMsg": "Sàbhail",
    "gui.playbackStep.reRecordMsg": "Clàraich a‑rithist e",
    "gui.webglModal.label": "Cha chuir am brabhsair agad taic ri WebGL",
    "gui.webglModal.webgllink": "taic ri WebGL",
    "gui.prompt.cancel": "Sguir dheth",
    "gui.prompt.ok": "Ceart ma-thà",
    "gui.customProcedures.addAnInputNumberText": "Cuir raon ris",
    "gui.customProcedures.addAnInputBoolean": "Cuir raon ris",
    "gui.customProcedures.numberTextType": "àireamh no teacsa",
    "gui.customProcedures.booleanType": "Booleach",
    "gui.customProcedures.addALabel": "Cuir leubail ris",
    "gui.customProcedures.runWithoutScreenRefresh": "Ruith às aonais ath-nuadhachadh na sgrìn",
    "gui.customProcedures.cancel": "Sguir dheth",
    "gui.customProcedures.ok": "Ceart ma-thà",
    "gui.extension.microbit.description": "Ceanglaich na pròiseactan agad ris an t-saoghal.",
    "gui.extension.music.name": "Ceòl",
    "gui.extension.music.description": "Cluich innealan-ciùil is drumaichean.",
    "gui.extension.pen.name": "Peann",
    "gui.extension.pen.description": "Tarraing leis na sprìdean agad.",
    "gui.gui.variableScopeOptionAllSprites": "Airson a h‑uile sprìd",
    "gui.gui.variableScopeOptionSpriteOnly": "Airson na sprìde seo a‑mhàin",
    "gui.gui.variablePromptAllSpritesMessage": "Bidh an caochladair seo ri làimh dhan a h‑uile sprìd.",
    "gui.monitor.contextMenu.default": "sealladh àbhaisteach",
    "gui.monitor.contextMenu.large": "sealladh mòr",
    "gui.monitor.contextMenu.slider": "an sleamhnachan",
    "gui.monitor.contextMenu.import": "ion-phortaich",
    "gui.monitor.contextMenu.export": "às-phortaich",
    "gui.opcodeLabels.direction": "comhair",
    "gui.opcodeLabels.xposition": "ionad x",
    "gui.opcodeLabels.yposition": "ionad y",
    "gui.opcodeLabels.size": "meud",
    "gui.opcodeLabels.costumename": "ainm an dreacha",
    "gui.opcodeLabels.costumenumber": "àireamh an dreacha",
    "gui.opcodeLabels.backdropname": "ainm a’ chùlaibh",
    "gui.opcodeLabels.backdropnumber": "àireamh a’ chùlaibh",
    "gui.opcodeLabels.volume": "àirde na fuaime",
    "gui.opcodeLabels.tempo": "luaths",
    "gui.opcodeLabels.answer": "freagairt",
    "gui.opcodeLabels.loudness": "àirde na fuaime",
    "gui.opcodeLabels.year": "am bliadhna",
    "gui.opcodeLabels.month": "am mìos",
    "gui.opcodeLabels.date": "an ceann-là",
    "gui.opcodeLabels.dayofweek": "latha na seachdaine",
    "gui.opcodeLabels.hour": "an uair",
    "gui.opcodeLabels.minute": "a’ mhionaid",
    "gui.opcodeLabels.second": "an diog",
    "gui.opcodeLabels.timer": "an tìmear",
    "music.categoryName": "Ceòl",
    "translate.categoryName": "Eadar-theangaich",
    "pen.categoryName": "Peann",
    "pen.changeColorParam": "atharraich [COLOR_PARAM] a’ phinn le [VALUE]",
    "pen.changeHue": "atharraich dath a’ phinn le [HUE]",
    "pen.changeShade": "atharraich tuar a’ phinn le [SHADE]",
    "pen.changeSize": "atharraich meud a’ phinn le [SIZE]",
    "pen.clear": "suath às na h‑uile",
    "pen.colorMenu.brightness": "soilleireachd",
    "pen.colorMenu.color": "dath",
    "pen.colorMenu.saturation": "sàthachd",
    "pen.colorMenu.transparency": "trìd-shoillearachd",
    "pen.penDown": "peann sìos",
    "pen.penUp": "peann suas",
    "pen.setColor": "suidhich dath a’ phinn air [COLOR]",
    "pen.setColorParam": "suidhich [COLOR_PARAM] a’ phinn air [VALUE]",
    "pen.setHue": "suidhich dath a’ phinn air [HUE]",
    "pen.setShade": "suidhich tuar a’ phinn air [SHADE]",
    "pen.setSize": "suidhich meud a’ phinn air [SIZE]",
    "pen.stamp": "stampa",
    "music.changeTempo": "atharraich an luaths le [TEMPO]",
    "music.drumBass": "(2) Beus-dhruma",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Hi-hat dùinte",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Clag bà",
    "music.drumCrashCymbal": "(4) Ciombal crash",
    "music.drumCuica": "(18) Cuíca",
    "music.drumGuiro": "(16) Güiro",
    "music.drumHandClap": "(8) Bualadh bhasan",
    "music.drumOpenHiHat": "(5) Hi-hat fosgailte",
    "music.drumSideStick": "(3) Taobh biorain",
    "music.drumSnare": "(1) Druma-tormain",
    "music.drumTambourine": "(7) Guit-dhruma",
    "music.drumTriangle": "(12) Triantan",
    "music.drumVibraslap": "(17) Crith-sgleog",
    "music.drumWoodBlock": "(10) Bloca fiodha",
    "music.getTempo": "luaths",
    "music.instrumentBass": "(6) Beus",
    "music.instrumentBassoon": "(14) Torm-fheadan",
    "music.instrumentCello": "(8) Beus-fhìdheall",
    "music.instrumentChoir": "(15) Còisir",
    "music.instrumentClarinet": "(10) Clàirneid",
    "music.instrumentElectricGuitar": "(5) Giotàr dealain",
    "music.instrumentElectricPiano": "(2) Piàno dealain",
    "music.instrumentFlute": "(12) Duiseal",
    "music.instrumentGuitar": "(4) Giotàr",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Bogsa-ciùil",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) Piàno",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Sacsafon",
    "music.instrumentSteelDrum": "(18) Druma stàillinn",
    "music.instrumentSynthLead": "(20) Sinteisear seirme",
    "music.instrumentSynthPad": "(21) Sinteisear pada",
    "music.instrumentTrombone": "(9) Trompan",
    "music.instrumentVibraphone": "(16) Critheafon",
    "music.instrumentWoodenFlute": "(13) Duiseal fiodha",
    "music.midiPlayDrumForBeats": "cluich druma [DRUM] fad [BEATS] buille",
    "music.midiSetInstrument": "suidhich an t‑inneal-ciùil air [INSTRUMENT]",
    "music.playDrumForBeats": "cluich druma [DRUM] fad [BEATS] buille",
    "music.playNoteForBeats": "cluich pong [NOTE] fad [BEATS] buille",
    "music.restForBeats": "tost fad [BEATS] buille",
    "music.setInstrument": "suidhich an t‑inneal-ciùil air [INSTRUMENT]",
    "music.setTempo": "suidhich an luaths air [TEMPO]",
    "videoSensing.categoryName": "Mothachadh video",
    "videoSensing.direction": "comhair",
    "videoSensing.motion": "gluasad",
    "videoSensing.off": "dheth",
    "videoSensing.on": "air",
    "videoSensing.onFlipped": "air, le flip",
    "videoSensing.setVideoTransparency": "suidhich trìd-shoillse a’ video air [TRANSPARENCY]",
    "videoSensing.sprite": "an sprìd",
    "videoSensing.stage": "an àrd-ùrlar",
    "videoSensing.videoOn": "[ATTRIBUTE] a’ video air [SUBJECT]",
    "videoSensing.videoToggle": "cuir a’ video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "nuair a thèid gluasad a’ video > [REFERENCE]",
    "translate.translateBlock": "eadar-theangaich [WORDS] gu [LANGUAGE]",
    "translate.defaultTextToTranslate": "shin thu",
    "translate.viewerLanguage": "cànan",
    "text2speech.speakAndWaitBlock": "can [WORDS] gu h-àrd",
    "text2speech.setVoiceBlock": "suidhich a’ ghuth air [VOICE]",
    "text2speech.setLanguageBlock": "suidhich an cànan air [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "bìog",
    "text2speech.giant": "famhair",
    "text2speech.kitten": "piseag",
    "text2speech.defaultTextToSpeak": "shin thu",
    "speech.whenIHear": "nuair a chluinneas mi [PHRASE]",
    "speech.listenAndWait": "èist is fan",
    "speech.defaultWhenIHearValue": "tiugainn",
    "paint.paintEditor.hue": "Dath",
    "paint.paintEditor.saturation": "Sàthachd",
    "paint.paintEditor.brightness": "Soilleireachd",
    "gui.comingSoon.message1": "Na gabh dragh, tha sinn ag obair air {emoji}",
    "gui.comingSoon.message2": "Ri thighinn a dh’aithghearr…",
    "gui.comingSoon.message3": "Tha sinn ag obair air {emoji}",
    "paint.paintEditor.fill": "Lìonadh",
    "paint.paintEditor.costume": "Dreach",
    "paint.paintEditor.group": "Buidhnich",
    "paint.paintEditor.ungroup": "Sgaoil am buidheann",
    "paint.paintEditor.undo": "Neo-dhèan",
    "paint.paintEditor.redo": "Ath-dhèan",
    "paint.paintEditor.forward": "Gluais an comhair a’ bheòil",
    "paint.paintEditor.backward": "Gluais an comhair a’ chùil",
    "paint.paintEditor.front": "Gluais dhan fhìor-bheulaibh",
    "paint.paintEditor.back": "Gluais dhan fhìor-chùlaibh",
    "paint.paintEditor.more": "Barrachd",
    "paint.modeTools.brushSize": "Meud na bruise",
    "paint.modeTools.eraserSize": "Meud an t‑suathain",
    "paint.modeTools.copy": "Dèan lethbhreac",
    "paint.modeTools.paste": "Cuir ann",
    "paint.modeTools.delete": "Sguab às",
    "paint.modeTools.curved": "Cruinn",
    "paint.modeTools.pointed": "Biorach",
    "paint.modeTools.thickness": "Tiughad",
    "paint.modeTools.flipHorizontal": "Flip air a’ chòmhnard",
    "paint.modeTools.flipVertical": "Flip gu h‑inghearach",
    "paint.modeTools.filled": "Lìonta",
    "paint.modeTools.outlined": "Oir‑loidhne",
    "paint.paintEditor.bitmap": "Iompaich na bitmap",
    "paint.paintEditor.vector": "Iompaich na vector",
    "paint.paintEditor.stroke": "Oir‑loidhne",
    "paint.brushMode.brush": "Bruis",
    "paint.eraserMode.eraser": "Suathan",
    "paint.fillMode.fill": "Lìonadh",
    "paint.lineMode.line": "Loidhne",
    "paint.ovalMode.oval": "Cearcall",
    "paint.rectMode.rect": "Ceart-cheàrnach",
    "paint.reshapeMode.reshape": "Atharraich cruth",
    "paint.roundedRectMode.roundedRect": "Ceart-cheàrnach cruinnte",
    "paint.selectMode.select": "Tagh",
    "paint.textMode.text": "Teacsa",
    "paint.colorPicker.swap": "Suaip",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "gl": {
    "gui.backpack.header": "Mochila",
    "gui.backpack.errorBackpack": "Produciuse un erro ao cargar a mochila",
    "gui.backpack.loadingBackpack": "A cargar...",
    "gui.backpack.more": "Máis",
    "gui.backpack.emptyBackpack": "A mochila está baleira",
    "gui.gui.costumesTab": "Vestimentas",
    "gui.gui.soundsTab": "Sons",
    "gui.gui.backdropsTab": "Fondos",
    "gui.gui.addExtension": "Engadir extensión",
    "gui.costumeTab.addCostumeFromLibrary": "Escoller unha  vestimenta",
    "gui.costumeLibrary.chooseACostume": "Escoller unha vestimenta",
    "gui.costumeTab.addBackdropFromLibrary": "Escoller un fondo",
    "gui.costumeTab.addBlankCostume": "Pintar",
    "gui.costumeTab.addSurpriseCostume": "Sorpresa",
    "gui.costumeTab.addFileBackdrop": "Cargar un fondo",
    "gui.costumeTab.addFileCostume": "Cargar unha vestimenta",
    "gui.costumeTab.addCameraCostume": "Cámara",
    "gui.soundEditor.trim": "Recortar",
    "gui.soundEditor.stop": "Parar",
    "gui.soundEditor.sound": "Son",
    "gui.soundEditor.play": "Reproducir",
    "gui.soundEditor.save": "Gardar",
    "gui.soundEditor.undo": "Desfacer",
    "gui.soundEditor.redo": "Refacer",
    "gui.soundEditor.faster": "Máis rápido",
    "gui.soundEditor.slower": "Máis lento",
    "gui.soundEditor.echo": "Eco",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Máis forte",
    "gui.soundEditor.softer": "Máis suave",
    "gui.soundEditor.reverse": "Reverter",
    "gui.soundTab.recordSound": "Gravar",
    "gui.soundTab.addSoundFromLibrary": "Escoller un son",
    "gui.soundTab.surpriseSound": "Sorpresa",
    "gui.soundTab.fileUploadSound": "Cargar un son",
    "gui.controls.stop": "Parar",
    "gui.controls.go": "Ir",
    "gui.stageHeader.stageSizeUnFull": "Saír do modo de pantalla completa",
    "gui.SpriteInfo.show": "Amosar",
    "gui.SpriteInfo.size": "Tamaño",
    "gui.directionPicker.rotationStyles.allAround": "En todas as direccións",
    "gui.directionPicker.rotationStyles.leftRight": "Esquerda/Dereita",
    "gui.directionPicker.rotationStyles.dontRotate": "Non rotar",
    "gui.spriteSelectorItem.contextMenuDuplicate": "duplicar",
    "gui.spriteSelectorItem.contextMenuDelete": "borrar",
    "gui.spriteSelectorItem.contextMenuExport": "exportar",
    "gui.spriteSelector.addSpriteFromPaint": "Pintar",
    "gui.spriteSelector.addSpriteFromSurprise": "Sorpresa",
    "gui.spriteSelector.addSpriteFromFile": "Cargar unha figura",
    "gui.stageSelector.stage": "Escenario",
    "gui.stageSelector.backdrops": "Fondos",
    "gui.stageSelector.addBackdropFromPaint": "Pintar",
    "gui.stageSelector.addBackdropFromSurprise": "Sorpresa",
    "gui.stageSelector.addBackdropFromFile": "Cargar un fondo",
    "gui.modal.back": "Atrás",
    "gui.library.filterPlaceholder": "Buscar",
    "gui.libraryTags.all": "Todas",
    "gui.libraryTags.animals": "Animais",
    "gui.libraryTags.dance": "Baile",
    "gui.libraryTags.effects": "Efectos",
    "gui.libraryTags.fantasy": "Fantasía",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Comida",
    "gui.libraryTags.indoors": "Interiores",
    "gui.libraryTags.loops": "Bucles",
    "gui.libraryTags.music": "Música",
    "gui.libraryTags.notes": "Notas",
    "gui.libraryTags.outdoors": "Exteriores",
    "gui.libraryTags.patterns": "Patróns",
    "gui.libraryTags.people": "Xente",
    "gui.libraryTags.percussion": "Percusión",
    "gui.libraryTags.space": "Espazo",
    "gui.libraryTags.sports": "Deportes",
    "gui.libraryTags.underwater": "Baixo a auga",
    "gui.libraryTags.voice": "Voz",
    "gui.libraryTags.wacky": "Extravagante",
    "gui.libraryTags.animation": "Animación",
    "gui.libraryTags.art": "Arte",
    "gui.libraryTags.games": "Xogos",
    "gui.libraryTags.stories": "Historias",
    "gui.libraryTags.letters": "Letras",
    "gui.soundLibrary.chooseASound": "Escoller un son",
    "gui.SpriteInfo.spritePlaceholder": "Nome",
    "gui.cards.more-things-to-try": "Máis cousas coas que experimentar!",
    "gui.cards.see-more": "Ver máis",
    "gui.cards.shrink": "Recoller",
    "gui.cards.expand": "Estender",
    "gui.cards.close": "Pechar",
    "gui.loader.message1": "A crear os bloques ...",
    "gui.loader.message2": "A cargar as figuras ...",
    "gui.loader.message3": "A cargar os sons...",
    "gui.loader.message4": "A cargar as extensións ...",
    "gui.loader.message5": "O amor que arde de présa consómese logo...",
    "gui.loader.message6": "Boa vida non quer présa...",
    "gui.loader.message7": "Canta máis présa máis vagar ...",
    "gui.loader.message8": "A preparar os emojis ...",
    "gui.loader.headline": "Estase a cargar o proxecto",
    "gui.cameraModal.cameraModalTitle": "Tirar unha foto",
    "gui.cameraModal.loadingCameraMessage": "A cargar a cámara...",
    "gui.cameraModal.permissionRequest": "Precisamos do teu permiso para usarmos a cámara",
    "gui.cameraModal.retakePhoto": "Tirar outra foto",
    "gui.cameraModal.save": "Gardar",
    "gui.cameraModal.takePhoto": "Tirar unha foto",
    "gui.cameraModal.loadingCaption": "A cargar...",
    "gui.cameraModal.enableCameraCaption": "Activar a cámara",
    "gui.recordModal.title": "Gravar son",
    "gui.playbackStep.stopMsg": "Parar",
    "gui.playbackStep.playMsg": "Reproducir",
    "gui.playbackStep.loadingMsg": "A cargar...",
    "gui.playbackStep.saveMsg": "Gardar",
    "gui.playbackStep.reRecordMsg": "Gravar outra vez",
    "gui.webglModal.label": "O navegador non admite WebGL",
    "gui.webglModal.webgllink": "non é compatíbel con WebGL",
    "gui.prompt.cancel": "Cancelar",
    "gui.prompt.ok": "Aceptar",
    "gui.customProcedures.addAnInputNumberText": "Engadir unha entrada",
    "gui.customProcedures.addAnInputBoolean": "Engadir unha entrada",
    "gui.customProcedures.numberTextType": "número ou texto",
    "gui.customProcedures.booleanType": "booleana",
    "gui.customProcedures.addALabel": "Engadir unha etiqueta",
    "gui.customProcedures.runWithoutScreenRefresh": "Executar sen actualizar a pantalla",
    "gui.customProcedures.cancel": "Cancelar",
    "gui.customProcedures.ok": "Aceptar",
    "gui.extension.microbit.description": "Conecta os teus proxectos co mundo.",
    "gui.extension.music.name": "Música",
    "gui.extension.music.description": "Toca instrumentos e tambores.",
    "gui.extension.pen.name": "Lapis",
    "gui.extension.pen.description": "Debuxa coas túas figuras.",
    "gui.gui.variableScopeOptionAllSprites": "Para todas as figuras",
    "gui.gui.variableScopeOptionSpriteOnly": "Só para esta figura",
    "gui.gui.variablePromptAllSpritesMessage": "Esta variábel aplícase a todas as figuras.",
    "gui.monitor.contextMenu.default": "tamaño normal",
    "gui.monitor.contextMenu.large": "tamaño grande",
    "gui.monitor.contextMenu.slider": "barra de desprazamento",
    "gui.monitor.contextMenu.import": "importar",
    "gui.monitor.contextMenu.export": "exportar",
    "gui.opcodeLabels.direction": "dirección",
    "gui.opcodeLabels.xposition": "posición en x",
    "gui.opcodeLabels.yposition": "posición en y",
    "gui.opcodeLabels.size": "tamaño",
    "gui.opcodeLabels.costumename": "nome da vestimenta",
    "gui.opcodeLabels.costumenumber": "número de vestimenta",
    "gui.opcodeLabels.backdropname": "nome do fondo",
    "gui.opcodeLabels.backdropnumber": "número de fondo",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "resposta",
    "gui.opcodeLabels.loudness": "intensidade do son",
    "gui.opcodeLabels.year": "ano",
    "gui.opcodeLabels.month": "mes",
    "gui.opcodeLabels.date": "data",
    "gui.opcodeLabels.dayofweek": "día da semana",
    "gui.opcodeLabels.hour": "hora",
    "gui.opcodeLabels.minute": "minuto",
    "gui.opcodeLabels.second": "segundo",
    "gui.opcodeLabels.timer": "cronómetro",
    "music.categoryName": "Música",
    "translate.categoryName": "Traducir",
    "pen.categoryName": "Lapis",
    "pen.changeColorParam": "sumar [VALUE] á [COLOR_PARAM] do lapis",
    "pen.changeHue": "sumar [HUE] á cor do lapis",
    "pen.changeShade": "sumar [SHADE] a intensidade do lapis",
    "pen.changeSize": "aumentar o tamaño do lapis en [SIZE]",
    "pen.clear": "borrar todos",
    "pen.colorMenu.brightness": "luminosidade",
    "pen.colorMenu.color": "cor",
    "pen.colorMenu.saturation": "saturación",
    "pen.colorMenu.transparency": "transparencia",
    "pen.penDown": "baixar o lapis",
    "pen.penUp": "subir o lapis",
    "pen.setColor": "usar un lapis de cor [COLOR]",
    "pen.setColorParam": "pór a [COLOR_PARAM] do lapis en [VALUE]",
    "pen.setHue": "usar un lapis de cor [HUE]",
    "pen.setShade": "pór a intensidade do lapis a [SHADE]",
    "pen.setSize": "usar un lapis de tamaño [SIZE]",
    "pen.stamp": "selar",
    "music.changeTempo": "aumentar o tempo en [TEMPO]",
    "music.drumBass": "(2) Bombo",
    "music.drumBongo": "(13) Bongó",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Charles pechado",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Chocallo",
    "music.drumCrashCymbal": "(4) Prato de ataque",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Güiro",
    "music.drumHandClap": "(8) Palmada",
    "music.drumOpenHiHat": "(5) Charles aberto",
    "music.drumSideStick": "(3) Golpe lateral",
    "music.drumSnare": "(1) Tambor de caixa",
    "music.drumTambourine": "(7) Pandeireta",
    "music.drumTriangle": "(12) Triángulo",
    "music.drumVibraslap": "(17) Trafaplás",
    "music.drumWoodBlock": "(10) Bloque de madeira",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Baixo",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Violonchelo",
    "music.instrumentChoir": "(15) Coro",
    "music.instrumentClarinet": "(10) Clarinete",
    "music.instrumentElectricGuitar": "(5) Guitarra eléctrica",
    "music.instrumentElectricPiano": "(2) Piano eléctrico",
    "music.instrumentFlute": "(12) Frauta traveseira",
    "music.instrumentGuitar": "(4) Guitarra",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Caixa de música",
    "music.instrumentOrgan": "(3) Órgano",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxofón",
    "music.instrumentSteelDrum": "(18) Tambor metálico",
    "music.instrumentSynthLead": "(20) Sintetizador melódico",
    "music.instrumentSynthPad": "(21) Sintetizador de fondo",
    "music.instrumentTrombone": "(9) Trombón",
    "music.instrumentVibraphone": "(16) Vibráfono",
    "music.instrumentWoodenFlute": "(13) Frauta de madeira",
    "music.midiPlayDrumForBeats": "tocar o tambor [DRUM] durante [BEATS] pulsos",
    "music.midiSetInstrument": "tocar o instrumento [INSTRUMENT]",
    "music.playDrumForBeats": "tocar o tambor [DRUM] durante [BEATS] pulsos",
    "music.playNoteForBeats": "tocar a nota [NOTE] durante [BEATS] pulsos",
    "music.restForBeats": "descanso de [BEATS] pulsos",
    "music.setInstrument": "usar o instrumento [INSTRUMENT]",
    "music.setTempo": "aplicar un tempo de [TEMPO]",
    "videoSensing.categoryName": "Sensor de vídeo",
    "videoSensing.direction": "dirección",
    "videoSensing.motion": "movemento",
    "videoSensing.off": "apagado",
    "videoSensing.on": "aceso",
    "videoSensing.onFlipped": "ao voltear",
    "videoSensing.setVideoTransparency": "pór a transparencia de vídeo en [TRANSPARENCY]",
    "videoSensing.sprite": "figura",
    "videoSensing.stage": "escenario",
    "videoSensing.videoOn": "[ATTRIBUTE] de vídeo a [SUBJECT]",
    "videoSensing.videoToggle": "vídeo [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "Cando o movemento de vídeo > [REFERENCE]",
    "translate.translateBlock": "traducir [WORDS] a [LANGUAGE]",
    "translate.defaultTextToTranslate": "Ola",
    "translate.viewerLanguage": "idioma",
    "text2speech.speakAndWaitBlock": "falar [WORDS]",
    "text2speech.setVoiceBlock": "darlle a voz de [VOICE]",
    "text2speech.setLanguageBlock": "usar o idioma [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "bradar",
    "text2speech.giant": "xigante",
    "text2speech.kitten": "gatiño",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "ao ouvir [PHRASE]",
    "speech.listenAndWait": "escoitar e agardar",
    "speech.defaultWhenIHearValue": "Imos aló",
    "paint.paintEditor.hue": "Cor",
    "paint.paintEditor.saturation": "Saturación",
    "paint.paintEditor.brightness": "Luminosidade",
    "gui.comingSoon.message1": "Non te preocupes, andamos niso {emoji}",
    "gui.comingSoon.message2": "Proximamente...",
    "gui.comingSoon.message3": "Andamos a traballar nisto {emoji}",
    "paint.paintEditor.fill": "Recheo",
    "paint.paintEditor.costume": "Vestimenta",
    "paint.paintEditor.group": "Agrupar",
    "paint.paintEditor.ungroup": "Desagrupar",
    "paint.paintEditor.undo": "Desfacer",
    "paint.paintEditor.redo": "Refacer",
    "paint.paintEditor.forward": "Adiante",
    "paint.paintEditor.backward": "Atrás",
    "paint.paintEditor.front": "Traer á fronte",
    "paint.paintEditor.back": "Levar ao fondo",
    "paint.paintEditor.more": "Máis",
    "paint.modeTools.brushSize": "Tamaño",
    "paint.modeTools.eraserSize": "Tamaño do borrador",
    "paint.modeTools.copy": "Copiar",
    "paint.modeTools.paste": "Pegar",
    "paint.modeTools.delete": "Eliminar",
    "paint.modeTools.curved": "A curvas",
    "paint.modeTools.pointed": "Bordes Afiados",
    "paint.modeTools.thickness": "Gordura",
    "paint.modeTools.flipHorizontal": "Voltear en horizontal",
    "paint.modeTools.flipVertical": "Voltear en vertical",
    "paint.modeTools.filled": "Enchido",
    "paint.modeTools.outlined": "Bordos",
    "paint.paintEditor.bitmap": "Converter en mapa de bits",
    "paint.paintEditor.vector": "Converter en vectores",
    "paint.paintEditor.stroke": "Bordo",
    "paint.brushMode.brush": "Pincel",
    "paint.eraserMode.eraser": "Borrador",
    "paint.fillMode.fill": "Encher",
    "paint.lineMode.line": "Liña",
    "paint.ovalMode.oval": "Círculo",
    "paint.rectMode.rect": "Rectángulo",
    "paint.reshapeMode.reshape": "Reformar",
    "paint.roundedRectMode.roundedRect": "Rectángulo arredondado",
    "paint.selectMode.select": "Seleccionar",
    "paint.textMode.text": "Texto",
    "paint.colorPicker.swap": "Trocar",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "ko": {
    "gui.gui.project": "프로그램",
    "gui.gui.newItem": "새 프로그램 만들기",
    "gui.gui.load": "프로그램 열기",
    "gui.gui.save": "프로그램 저장하기",
    "gui.gui.saveAs": "다른 이름으로 프로그램 저장하기",
    "gui.gui.learn": "교재",
    "gui.gui.clearRecord": "Clear Record",
    "gui.gui.onlineLearn": "공식 문서",
    "gui.gui.onlineForum": "온라인 포럼",
    "gui.gui.videoTutorial": "비디오 강좌",
    "gui.gui.offlineLearn": "예시 프로그램",
    "gui.menuBar.turboModeOff": "가속 모드 닫기",
    "gui.menuBar.turboModeOn": "가속 모드 열기",
    "gui.menuBar.edit": "편집",
    "gui.menuBar.restoreSprite": "스프라이트 복구하기",
    "gui.menuBar.restoreSound": "사운드 복구하기",
    "gui.menuBar.restoreCostume": "코스튬 복구하기",
    "gui.editorMind.restore": "복구 및 삭제",
    "gui.turboMode.active": "가속 모드",
    "gui.gui.connectDevice": "장치 연결하기",
    "gui.gui.disconnectDevice": "장치 연결 끊기",
    "gui.gui.installSerialportDriver": "시리어 포트 드라이버 원 클릭 설치",
    "gui.gui.openDM": "장치 관리자 열기",
    "gui.gui.updateTips": "업데이트 알림",
    "gui.gui.newVersion": "새 버젼",
    "gui.gui.downloadUpdate": "다운로드 후 업데이트 하기",
    "gui.gui.versionUpdate": "버젼 업데이트하기",
    "gui.gui.downloading": "Checking Updator",
    "gui.setting.feedbackMenu": "의견 및 피드백",
    "gui.gui.arduino": "업로드",
    "gui.gui.scratch": "실시간",
    "gui.setting.officialWebsit": "공식 홈페이지",
    "gui.setting.officialQQGroup": "공식 QQ 커뮤니티",
    "gui.setting.wechatOfficialAccounts": "공식 WEIXIN 계정",
    "gui.setting.currentVersion": "현재 버젼",
    "gui.setting.checkForUpdate": "업데이트 확인하기",
    "gui.setting.remindWhenUpdate": "업데이트시 자동 다운로드하기",
    "gui.setting.currentIsLatest": "최신버젼을 사용중에 있습니다.",
    "gui.setting.latestVersion": "최신버젼",
    "gui.setting.download": "다운로드 화면으로 바로가기",
    "gui.setting.language": "언어",
    "gui.setting.uploadCsv": "CSV 업로드하기",
    "gui.setting.theme": "주제",
    "gui.setting.feedback": "의견 보내기",
    "gui.setting.email": "이메일",
    "gui.setting.opinionFeedback": "의견/피드백",
    "gui.variableMc.variableName": "변수이름",
    "gui.variableMc.variableOff": "취소하기",
    "gui.variableMc.variableOn": "확인하기",
    "gui.variableMc.newVariable": "변수 새로 만들기",
    "gui.setting.helpUsTranslate": "Help Us Translate",
    "gui.setting.checkUpdate": "Check Update",
    "gui.setting.ok": "제출하기",
    "gui.setting.languageSetting": "언어",
    "gui.setting.themeSetting": "주제",
    "gui.setting.versionUpdate": "Version Update",
    "gui.setting.connectUsTitle": "Contact us",
    "gui.setting.uploadAttachment": "Upload Attachment",
    "gui.backpack.header": "개인 저장소",
    "gui.backpack.errorBackpack": "개인 저장소를 불러오는 중 오류 ",
    "gui.backpack.loadingBackpack": "로딩중...",
    "gui.backpack.more": "더 보기",
    "gui.backpack.emptyBackpack": "개인 저장소가 비었습니다",
    "gui.gui.blocks": "모듈",
    "gui.gui.costumesTab": "모델",
    "gui.gui.soundsTab": "사운드",
    "gui.gui.backdropsTab": "배경",
    "gui.gui.addExtension": "확장",
    "gui.costumeTab.addCostumeFromLibrary": "모델 고르기",
    "gui.costumeLibrary.chooseACostume": "모델 고르기",
    "gui.costumeTab.addBackdropFromLibrary": "배경 고르기",
    "gui.costumeTab.addBlankCostume": "그리기",
    "gui.costumeTab.addSurpriseCostume": "랜덤",
    "gui.costumeTab.addFileBackdrop": "배경 업로드하기",
    "gui.costumeTab.addFileCostume": "모델 업로드하기",
    "gui.costumeTab.addCameraCostume": "카메라",
    "gui.soundEditor.trim": "자르기",
    "gui.soundEditor.stop": "멈추기",
    "gui.soundEditor.sound": "사운드",
    "gui.soundEditor.play": "재생",
    "gui.soundEditor.save": "저장",
    "gui.soundEditor.undo": "되돌리기",
    "gui.soundEditor.redo": "재시도",
    "gui.soundEditor.faster": "빠르게",
    "gui.soundEditor.slower": "느리게",
    "gui.soundEditor.echo": "메아리",
    "gui.soundEditor.robot": "로봇",
    "gui.soundEditor.louder": "크게",
    "gui.soundEditor.softer": "작게",
    "gui.soundEditor.reverse": "뒤집기",
    "gui.soundTab.recordSound": "녹음하기",
    "gui.soundTab.addSoundFromLibrary": "사운드 고르기",
    "gui.soundTab.surpriseSound": "랜덤",
    "gui.soundTab.fileUploadSound": "사운드 업로드하기",
    "gui.soundTab.addSound": "음성 삽입",
    "gui.controls.stop": "중지",
    "gui.controls.go": "운행하기",
    "gui.gui.startPython": "Python 실행하기",
    "gui.gui.stopPython": "Python 중지하기",
    "gui.controls.fullScreenControl": "전체 화면",
    "gui.gui.stageSizeLarge": "크게 보기",
    "gui.gui.stageSizeSmall": "작게 보기",
    "gui.gui.stageSizeNostage": "화면 없애기",
    "gui.gui.stageSizeFull": "전체 화면",
    "gui.stageHeader.stageSizeUnFull": "전체 화면 모드 종료하기",
    "gui.sprite.sprite": "스프라이트",
    "gui.SpriteInfo.show": "보이기",
    "gui.SpriteInfo.size": "크기",
    "gui.sprite.direction": "방향",
    "gui.sprite.rotation": "각도",
    "gui.directionPicker.rotationStyles.allAround": "임의로 회전하기",
    "gui.directionPicker.rotationStyles.leftRight": "왼쪽/오른쪽",
    "gui.directionPicker.rotationStyles.dontRotate": "회전하지 않기",
    "gui.spriteSelectorItem.contextMenuDuplicate": "복사",
    "gui.spriteSelectorItem.contextMenuDelete": "삭제",
    "gui.spriteSelectorItem.contextMenuExport": "내보내기",
    "gui.sprite.addSpriteFromLibrary": "스프라이트 라이브러리",
    "gui.spriteSelector.addSpriteFromPaint": "그리기",
    "gui.spriteSelector.addSpriteFromSurprise": "랜덤",
    "gui.spriteSelector.addSpriteFromFile": "스프라이트 업로드하기",
    "gui.sprite.addSpriteFromCamera": "카메라",
    "gui.stageSelector.stage": "무대",
    "gui.stageSelector.backdrops": "배경",
    "gui.stage.addBackdropFromLibrary": "백업 선택하기",
    "gui.stageSelector.addBackdropFromPaint": "그리기",
    "gui.stageSelector.addBackdropFromSurprise": "서프라이즈 ",
    "gui.stageSelector.addBackdropFromFile": "배경 업로드하기",
    "gui.stage.addBackdropFromCamera": "카메라",
    "gui.modal.back": "뒤로",
    "gui.extension.kit": "키트",
    "gui.extension.kitTitle": "키트 선택하기",
    "gui.extension.board": "메인보드",
    "gui.extension.boardTitle": "메인보드 선택하기",
    "gui.extension.spreadBoard": "확장보드",
    "gui.extension.spreadBoardTitle": "확장보드 선택하기",
    "gui.extension.sensor": "센서",
    "gui.extension.sensorTitle": "센서 선택하기",
    "gui.extension.actuator": "액츄에이터",
    "gui.extension.actuatorTitle": "액츄에이터 선택하기",
    "gui.extension.communicationModule": "통신모듈",
    "gui.extension.communicationModuleTitle": "통신모듈 선택하기",
    "gui.extension.display": "디스플레이",
    "gui.extension.displayTitle": "디스플레이 선택하기",
    "gui.extension.function": "기능 모듈",
    "gui.extension.functionTitle": "기능 모듈 선택하기",
    "gui.extension.internetService": "인터넷 서비스",
    "gui.extension.internetServiceTitle": "인터넷 서비스를 선택해주세요.",
    "gui.extension.arduinContentLabel": "장치 선택하기",
    "gui.library.filterPlaceholder": "검색",
    "gui.libraryTags.all": "모두",
    "gui.libraryTags.animals": "동물",
    "gui.libraryTags.dance": "댄스",
    "gui.libraryTags.effects": "효과",
    "gui.libraryTags.fantasy": "판타지",
    "gui.libraryTags.fashion": "패션",
    "gui.libraryTags.food": "음식",
    "gui.libraryTags.indoors": "실내",
    "gui.libraryTags.loops": "루프",
    "gui.libraryTags.music": "음악",
    "gui.libraryTags.notes": "음표",
    "gui.libraryTags.outdoors": "실외",
    "gui.libraryTags.patterns": "패턴",
    "gui.libraryTags.people": "인물",
    "gui.libraryTags.percussion": "타악기",
    "gui.libraryTags.space": "우주",
    "gui.libraryTags.sports": "스포츠",
    "gui.libraryTags.underwater": "물 속",
    "gui.libraryTags.voice": "보이스",
    "gui.libraryTags.wacky": "이상한",
    "gui.libraryTags.animation": "애니메이션",
    "gui.libraryTags.art": "예술",
    "gui.libraryTags.games": "게임",
    "gui.libraryTags.stories": "이야기",
    "gui.libraryTags.letters": "글자",
    "gui.extension.backdropLib": "배경 선택하기",
    "gui.soundLibrary.chooseASound": "사운드 고르기",
    "gui.SpriteInfo.spritePlaceholder": "이름",
    "gui.extension.spriteLib": "스프라이트 선택하기",
    "gui.gui.unselectedSerialport": "장치가 연결되어있지 않습니다.",
    "gui.gui.unConnectedDev": "장치가 연결되어있지 않습니다.",
    "gui.gui.pythonMode": "Python 프로그래밍 모드",
    "gui.gui.burnFirmware": "펌웨어를 다시 구워주세요.",
    "gui.gui.burnFirmwareError": "펌웨어를 굽는데 오류가 발생하였습니다.",
    "gui.gui.connected": "장치 연결에 성공했습니다.",
    "gui.gui.failedConnected": "장치 연결에 실패했습니다.",
    "gui.gui.connecting": "현재 장치에 연결중입니다.",
    "gui.cards.all-how-tos": "모든 과정",
    "gui.cards.how-tos": "입문자 과정",
    "gui.cards.remove": "과정에서 나가기",
    "gui.cards.more-things-to-try": "다른 것들도 시도해보세요!",
    "gui.cards.see-more": "더 보기",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "닫기",
    "gui.loader.message1": "블록을 생성하는 중입니다.",
    "gui.loader.message2": "스프라이트를 불러오는 중입니다.",
    "gui.loader.message3": "사운드를 불러오는 중입니다.",
    "gui.loader.message4": "확장 기능을 불러오는 중입니다.",
    "gui.loader.message5": "고양이를 몰고 가는 중입니다.",
    "gui.loader.message6": "나노를 전송하는 중입니다.",
    "gui.loader.message7": "Gobo를 팽창시키는 중입니다.",
    "gui.loader.message8": "이모티콘을 준비하는 중입니다.",
    "gui.loader.headline": "프로젝트를 불러오는 중입니다",
    "gui.cameraModal.cameraModalTitle": "사진 찍기",
    "gui.cameraModal.loadingCameraMessage": "카메라를 불러오는 중입니다",
    "gui.cameraModal.permissionRequest": "카메라 사용을 허락하시겠습니까?",
    "gui.cameraModal.retakePhoto": "사진 다시 찍기",
    "gui.cameraModal.save": "저장",
    "gui.cameraModal.takePhoto": "사진 찍기",
    "gui.cameraModal.loadingCaption": "로딩 중...",
    "gui.cameraModal.enableCameraCaption": "카메라 작동 허용하기",
    "gui.recordModal.title": "사운드 녹음하기",
    "gui.recordStep.recordByClickBtn": "아래 버튼을 클릭하여 녹음을 시작하십시오.",
    "gui.recordStep.permissionMicrophone": "마이크 사용을 허락하시겠습니까?",
    "gui.recordStep.record": "녹음하기",
    "gui.recordStep.stopRecord": "녹음 중지하기",
    "gui.playbackStep.stopMsg": "멈추기",
    "gui.playbackStep.playMsg": "재생",
    "gui.playbackStep.loadingMsg": "로딩 중...",
    "gui.playbackStep.saveMsg": "저장",
    "gui.playbackStep.reRecordMsg": "다시 녹음하기",
    "gui.webglModal.label": "브라우저가 WebGL을 지원하지 않습니다",
    "gui.webglModal.descriptionMind": "귀하의 컴퓨터 {webGlLink} .Mind +는 WebGL, {updateGpuDriver}를 실행해야합니다.",
    "gui.webglModal.webgllink": "WebGL을 지원하지 않는 상태입니다.",
    "gui.webglModal.updateGpuDriver": "그래픽 카드 드라이버를 업데이트하십시오.",
    "gui.webglModal.ok": "확정",
    "gui.extension.scratchExampleLib": "예시 프로그램 - 실시간 실행",
    "gui.extension.pythonExampleLib": "예시 프로그램 - Python",
    "gui.extension.arduinoExampleLib": "예시 프로그램 - 업로드 실행",
    "gui.prompt.cancel": "취소",
    "gui.prompt.ok": "확인",
    "gui.extension.makeBlock": "사용자 지정 모듈 추가하기",
    "gui.customProcedures.addAnInputNumberText": "입력값 추가하기",
    "gui.customProcedures.addAnInputBoolean": "입력값 추가하기",
    "gui.customProcedures.numberTextType": "숫자 또는 문자열",
    "gui.customProcedures.booleanType": "논리값",
    "gui.customProcedures.addALabel": "라벨 넣기",
    "gui.customProcedures.runWithoutScreenRefresh": "화면 새로고침 없이 실행하기",
    "gui.customProcedures.cancel": "취소",
    "gui.customProcedures.ok": "완성",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "DFRobot에서 출시한  Vortex 교육용 로봇",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "DFRobot출시한 익스플로러 D1 교육용 로봇",
    "gui.extension.arduinounoR3.name": "arduino uno오픈소스 키트",
    "gui.extension.arduinounoR3.description": "우한 메이커 교과 과정과 Arduino Uno 메인보드를 결합한 키트입니다.",
    "gui.extension.maxbot.name": "Max:bot미니 자동차",
    "gui.extension.maxbot.description": "micro:bit플랫폼에 기반을 둔 미니 로봇 자동차 입니다.",
    "gui.extension.max.name": "MAX 미니 자동차",
    "gui.extension.max.description": "아두이노에 기반을 둔 미니 로봇 자동차입니다.",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "마이크로비트 서보모터 전용 확장 보드",
    "gui.extension.maqueen.name": "micro:Maqueen",
    "gui.extension.maqueen.description": "마이크로비트 플랫폼에 기반을 둔 초보자용 미니 로봇 자동차 입니다.",
    "gui.extension.microbit.name": "마이크로비트",
    "gui.extension.microbit.description": "프로젝트를 실생활에 적용해보세요.",
    "gui.extension.spread.name": "micro:bit & mPython expansion board",
    "gui.extension.spreadMaqueen.name": "maqueen extension",
    "gui.extension.spread.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadmaqueen.description": "An entry-level minicar based on the control version",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "The advanced educational robot that supports both micro:bit and mPython",
    "gui.extension.iot.name": "Internet of Things",
    "gui.extension.iot.description": "Extended Edition Based on Internet of Things",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Leonardo 메인보드로 장치 제어",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Uno메인보드로 장치 제어",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Arduino Nano메인보드로 장치 제어",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "ESP32를 기반으로 한 메인보드",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Mega 2560 메인보드로 장치 제어",
    "gui.extension.dfr0299.name": "DFPlayer MP3모듈",
    "gui.extension.dfr0299.description": "MP3、WAV형식 파일 재생",
    "gui.extension.ser0006.name": "마이크로 서보",
    "gui.extension.ser0006.description": "0~180도 내에 설정된 각도로 회전할 수 있습니다. ",
    "gui.extension.dfr0523.name": "연동펌프",
    "gui.extension.dfr0523.description": "펌프를 조이거나 느슨하게 하여 유체를 전달합니다.",
    "gui.extension.dfr0017.name": "릴레이 모듈",
    "gui.extension.dfr0017.description": "고전류 장치를 제어하는데 사용됩니다.",
    "gui.extension.steppermotor.name": "스테퍼 모터",
    "gui.extension.steppermotor.description": "3D 프린터, 모바일 로봇, 중형 모바일 플랫폼 등에 사용됩니다.",
    "gui.extension.dfr0534.name": "시리얼 포트 MP3모듈 ",
    "gui.extension.dfr0534.description": "시리얼 포트 명령을 통해 음악 재생\r\nMP3, WAV, WMA 지원",
    "gui.extension.servo360.name": "360° 마이크로 서보",
    "gui.extension.servo360.description": "회전방향, 속도를 제어할 수 있으나, 각도를 제어할 수 없습니다.",
    "gui.extension.tel0118.name": "OBLOQ사물인터넷 모듈",
    "gui.extension.tel0118.description": "Wi-Fi를 통해 IoT 정보 수집 및 전송",
    "gui.extension.dfr0095.name": "적외선 송신 모듈",
    "gui.extension.dfr0095.description": "표준 38KHz 적외선 송신",
    "gui.extension.dfr0094.name": "적외선 수신 모듈",
    "gui.extension.dfr0094.description": "표준 38KHz 적외선 수신",
    "gui.extension.tel0094.name": "GPS 신호 수신 모듈",
    "gui.extension.tel0094.description": "GPS 신호 수신 모듈",
    "gui.extension.tel0026.name": "블루투스 모듈",
    "gui.extension.tel0026.description": "블루투스 시리얼 포트 모듈",
    "gui.extension.dfr0486.name": "OLED-12864 디스플레이",
    "gui.extension.dfr0486.description": "I2C OLED-12864 디스플레이 모듈, 스마트 웨어러블 기기의 디스플레이로 사용 가능",
    "gui.extension.fit0352.name": "WS2812 RGB조명",
    "gui.extension.fit0352.description": "WS2812 기반의 LED 스트립 모듈 컨트롤하기",
    "gui.extension.dfr0063.name": "LCD1602모듈（I2C포트）",
    "gui.extension.dfr0063.description": "LCD 모듈,\r\n각 행마다 16자씩 총 2행 표시 ",
    "gui.extension.dfr0021.name": "디지털 LED 라이트 모듈",
    "gui.extension.dfr0021.description": "높은 레벨일 때 켜기, 낮은 레벨일 때 끄기",
    "gui.extension.tm1650.name": "TM1650자리 닉시튜브",
    "gui.extension.tm1650.description": "디스플레이 모듈,\r\n타이머, 미터 컨트롤 ",
    "gui.extension.matrix8_8.name": "MAX7219 8x8 도트 매트릭스",
    "gui.extension.matrix8_8.description": "미니 도트 매트릭스 디스플레이 모듈",
    "gui.extension.dfr0522.name": "\r\n8 × 16 RGB LED 도트 매트릭스 판넬",
    "gui.extension.dfr0522.description": "로봇 표정 패널, 컬러 스펙트럼 분석기 등으로 사용할 수 있습니다.",
    "gui.extension.music.name": "음악",
    "gui.extension.music.description": "악기와 타악기를 연주하세요.",
    "gui.extension.pen.name": "펜",
    "gui.extension.pen.description": "스프라이트를 사용해 그리기",
    "gui.extension.video-sensing.name": "동영상 탐지",
    "gui.extension.video-sensing.description": "카메라를 사용해 동작 감지하기",
    "gui.extension.google-translate.name": "구글 번역",
    "gui.extension.google-translate.description": "문자를 다중언어로 번역하기",
    "gui.extension.text-speech.name": "문자 읽기",
    "gui.extension.text-speech.description": "프로젝트를 말하게 하세요.",
    "gui.extension.softSerialport.name": "소프트웨어 시리얼 포트",
    "gui.extension.softSerialport.description": "\r\n\r\nI / O 포트를 이용해 시리얼 통신  포트 통신기능 테스트해보세요.\r\n",
    "gui.extension.IICScan.name": "I2C주소 스캔",
    "gui.extension.IICScan.description": "I2C 인터페이스의 모든 현재 장치 주소 스캔",
    "gui.extension.bluetooth.name": "블루투스",
    "gui.extension.bluetooth.description": "블루투스 장치",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "GoBle APP을 앱으로 휴대폰을 사용하여 메인보드를 컨트롤 할 수 있습니다.",
    "gui.extension.interrupt.name": "핀 인터럽트",
    "gui.extension.interrupt.description": "핀 인터럽트",
    "gui.extension.multithread.name": "멀티 스레딩",
    "gui.extension.multithread.description": "여러 프로그램이 주 프로그램과 동시에 실행되도록 허용",
    "gui.extension.speech.name": "스피치",
    "gui.extension.speech.description": "당신의 프로젝트와 교류해보세요.",
    "gui.extension.sen0001.name": "초음파 거리 탐지 센서",
    "gui.extension.sen0001.description": "정밀 거리 측정하기\r\n측정범위 2 ~ 800cm",
    "gui.extension.dfr0023.name": "LM35 선형 아날로그 온도 센서\r\n",
    "gui.extension.dfr0023.description": "온도 측정 범위 0～100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22 온습도 센서",
    "gui.extension.dhtTHSensor.description": "주변 온도 및 습도 측정하기",
    "gui.extension.dsTSensor.name": "DS18B20온도 센서",
    "gui.extension.dsTSensor.description": "온도 측정하기. \r\n범위－55～＋125℃",
    "gui.extension.sen0203.name": "심장박동 센서",
    "gui.extension.sen0203.description": "신체 심박수 측정",
    "gui.extension.sen0177.name": "레이저 PM2.5. 공기 품질 센서",
    "gui.extension.sen0177.description": "공기 중의 입자 농도 측정 예)PM2.5",
    "gui.extension.sen0014.name": "적외선 거리 측정 센서",
    "gui.extension.sen0014.description": "거리 탐색 범위10～80cm",
    "gui.extension.sen0204.name": "호환 비접촉 수위센서",
    "gui.extension.sen0204.description": "밀폐용기의 수위를 비접촉식으로 측정하기",
    "gui.extension.sen0161.name": "아날로그 Ph 값",
    "gui.extension.sen0161.description": "용액의 pH값(수소 이온 지수) 측정하기,\r\n공급 전력 5V ",
    "gui.extension.sen0161-v2.name": "아날로그 pH값(V2)",
    "gui.extension.sen0161-v2.description": "용액의 pH값（수소 이온 지수)측정하기,\r\n공급 전력3.3～5.5V ",
    "gui.extension.sen0244.name": "아날로그 TDS 센서",
    "gui.extension.sen0244.description": "용액의 TDS(용존 고형물 총량) 측정 및 수질 수질 평가하기",
    "gui.extension.sen0165.name": "아날로그 ORP 값",
    "gui.extension.sen0165.description": "용액의 ORP(용존 고형물 총량) 측정 및 수질 수질 평가하기",
    "gui.extension.sen0237.name": "아날로그 DO 값",
    "gui.extension.sen0237.description": "용액 전도도 측정 및 수질 평가하기",
    "gui.extension.dfr0300-H.name": "아날로그 EC 값",
    "gui.extension.dfr0300-H.description": "용액 전도도 측정 및 수질 평가하기",
    "gui.extension.dfr0300.name": "아날로그 EC 값 (V2)",
    "gui.extension.dfr0300.description": "용액 전도도 측정 및 수질 평가하기",
    "gui.extension.sen0170.name": "풍속 센서",
    "gui.extension.sen0170.description": "풍속 측정",
    "gui.extension.sen0226.name": "BMP280 온도센서",
    "gui.extension.sen0226.description": "온도 및 대기압 측정",
    "gui.extension.sen0228.name": "조도센서",
    "gui.extension.sen0228.description": "주변 조도 정밀 측정하기",
    "gui.extension.dfr0022.name": "아날로그 회색톤 센서",
    "gui.extension.dfr0022.description": "다양한 색상을 감지하며, 라인트래킹에 사용할 수 있습니다.",
    "gui.extension.dfr0552.name": "12-bit DA conversion module",
    "gui.extension.dfr0552.description": "Convert the digital quantity to the corresponding dc voltage signal accurately (non-pwm)",
    "gui.extension.dfr0576.name": "I2C cascade extender",
    "gui.extension.dfr0576.description": "Used to resolve I2C device address conflicts",
    "gui.extension.sen0248.name": "BME680 environmental sensor",
    "gui.extension.sen0248.description": "VOC (volatile organic compounds), temperature, humidity and air pressure can be measured",
    "gui.extension.sen0304.name": "I2C ultrasonic ranging sensor",
    "gui.extension.sen0304.description": "Ultrasonic sensors designed for rapid ranging or obstacle avoidance applications",
    "gui.extension.sen0307.name": "Analog ultrasonic ranging sensor",
    "gui.extension.sen0307.description": "Open dual probe analog ultrasonic ranging module",
    "gui.extension.sen0250.name": "BMI160 6 axis inertial motion sensor",
    "gui.extension.sen0250.description": "16-bit 3-axis acceleration + ultra-low power consumption 3-axis gyroscope",
    "gui.extension.sen0224.name": "I2C LIS2DH triaxial accelerometer",
    "gui.extension.sen0224.description": "Ultra-low power consumption 3 axis acceleration sensor",
    "gui.extension.dfr0151.name": "실시간 시계 DS1307",
    "gui.extension.dfr0151.description": "년,월, 일, 주, 시, 분, 초 출력하기",
    "gui.extension.dfr0469.name": "실시간 시계 SD2405",
    "gui.extension.dfr0469.description": "년,월, 일, 주, 시, 분, 초 액세스 하기",
    "gui.extension.dfr0126.name": "스펙트럼 분석 모듈",
    "gui.extension.dfr0126.description": "7 가지 주파수 분석",
    "gui.extension.dfr0231.name": "시리얼 포트 NFC 모듈",
    "gui.extension.dfr0231.description": "단거리 무선 통신에 사용되며, 카드를 판독할 수 있습니다. ",
    "gui.extension.sen0245.name": "VL53L0X 레이저 거리 탐지 센서",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "아날로그 조도 센서",
    "gui.extension.dfr0026.description": "주변 조도 측정",
    "gui.extension.dfr0027.name": "디지털 진동 센서",
    "gui.extension.dfr0027.description": "진동 신호를 측정하여, 디지털 신호를 출력합니다.",
    "gui.extension.dfr0028.name": "디지털 틸트 센서",
    "gui.extension.dfr0028.description": "디지털 스위치로 사용할 수 있으며 기울기 각도를 측정 할 수 없습니다.",
    "gui.extension.dfr0029.name": "디지털 버튼 모듈",
    "gui.extension.dfr0029.description": "누르기: 높은 레벨 \r\n놓기: 낮은 레벨\r\n진동발생",
    "gui.extension.dfr0030.name": "디지털 터치 센서",
    "gui.extension.dfr0030.description": "터치로 켜고 끌 수 있으며, 인체 및 금속 등을 감지합니다.",
    "gui.extension.dfr0033.name": "디지털 마그네틱 센서",
    "gui.extension.dfr0033.description": "검출 범위 약 3cm 내의 자성 물질  (마그넷)  검출하기",
    "gui.extension.dfr0034.name": "아날로그 사운드 센서",
    "gui.extension.dfr0034.description": "주변의 사운드 강도 측정하기",
    "gui.extension.sen0132.name": "아날로그 일산화탄소 가스센서",
    "gui.extension.sen0132.description": "일산화 탄소 농도 측정 범위20～2000ppm",
    "gui.extension.dfr0051.name": "아날로그 전압 측정 모듈",
    "gui.extension.dfr0051.description": "25V 이하의 직류 전압(DC) 측정",
    "gui.extension.dfr0052.name": "아날로그 압전세라믹 진동센서",
    "gui.extension.dfr0052.description": "진동 신호를 측정하고, 아날로그 신호를 출력합니다.",
    "gui.extension.dfr0058.name": "아날로그 로테이션 가변저항 센서",
    "gui.extension.dfr0058.description": "고정밀 전위차계를 기반으로 전압 조절",
    "gui.extension.dfr0061.name": "조이스틱 모듈",
    "gui.extension.dfr0061.description": "원격 제어 가능, 2 축 아날로그 출력, 1 디지털 출력",
    "gui.extension.dfr0072.name": "Shiftout Sensor",
    "gui.extension.dfr0072.description": "description for the 'Shiftout sensor' extension",
    "gui.extension.dfr0563.name": "battery Sensor",
    "gui.extension.dfr0563.description": "description for the 'battery sensor' extension",
    "gui.extension.dfr0553.name": "16-bit AD conversion module",
    "gui.extension.dfr0553.description": "description for the '16-bit AD conversion module' extension",
    "gui.extension.dfr0076.name": "화염센서",
    "gui.extension.dfr0076.description": "화원 혹은 파장이 960~1100 나노미터 광원에 있는지 탐색합니다.",
    "gui.extension.dfr0143.name": "3축 가속도 센서",
    "gui.extension.dfr0143.description": "물체의 동작 및 운동방향을 측정합니다.",
    "gui.extension.sen0018.name": "디지털 적외선 모션 센서",
    "gui.extension.sen0018.description": "사람 및 동물이 방출하는 적외선을 감지",
    "gui.extension.sen0114.name": "토양수분 센서",
    "gui.extension.sen0114.description": "토양 수분 측정, 수분 부족시 센서 출력 값이 감소합니다.",
    "gui.extension.sen0121.name": "수분센서",
    "gui.extension.sen0121.description": "비, 안개, 수증기 감지",
    "gui.extension.sen0212.name": "칼라 식별 센서",
    "gui.extension.sen0212.description": "물체의 표면 색을 식별하고, RGB값을 출력합니다.",
    "gui.extension.sen0253.name": "10DOF 센서",
    "gui.extension.sen0253.description": "BNO055+BMP280스마트10DOF절대 방향 센서",
    "gui.extension.sen0290.name": "lightning sensor",
    "gui.extension.sen0290.description": "intelligent lightning sensor",
    "gui.extension.sen0202.name": "3D손 동작 식별 미니 센서",
    "gui.extension.sen0202.description": "손가락 운동방향 및 손가락 회전방향 식별",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "마이크로비트 전용 리튬배터리 모터 확장보드",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "장치를 Wi-Fi에 연결하십시오.",
    "gui.extension.udp.name": "UDP broadcast",
    "gui.extension.udp.description": "Let devices and devices in the local area network realize multi-machine broadcast communication through UDP protocol",
    "gui.extension.mqtt.name": "IoT",
    "gui.extension.mqtt.description": "하드웨어 없이 IoT 통신을 실행합니다. ",
    "gui.extension.speechRecognition.name": "음성식별",
    "gui.extension.speechRecognition.description": "음성식별",
    "gui.gui.loaded": "이미 추가되었습니다.",
    "gui.gui.notLoaded": "아직 추가되지 않았습니다.",
    "gui.gui.cantFindYouWant": "원하시는 것을 찾지 못하셨습니까?",
    "gui.gui.clickHere": "여기를 클릭하세요",
    "gui.gui.viewHelp": "도움말 보기",
    "gui.gui.uploadToDev": "장치에 업로드하기",
    "gui.gui.compileAndUpload": "컴파일 후 업로드하기",
    "gui.gui.compileOnly": "컴파일에 한정하기",
    "gui.gui.compileOpen": "컴파일 후 열기",
    "gui.gui.burnBootloader": "무선 부트로더 굽기",
    "gui.gui.arduinoRun": "운행하기",
    "gui.gui.arduinoSave": "저장하기",
    "gui.gui.autoGenerated": "자동생성",
    "gui.gui.manualEditing": "수동편집",
    "gui.gui.codeBox": "코드박스",
    "gui.gui.moduleCode": "모듈 코드",
    "gui.menu.edit.undo": "해지하기",
    "gui.menu.edit.redo": "복구하기",
    "gui.menu.edit.selectAll": "전부 선택하기",
    "gui.menu.edit.cut": "자르기",
    "gui.menu.edit.copy": "복사하기",
    "gui.menu.edit.paste": "붙여넣기",
    "gui.menu.edit.codeStyle": "코드 스타일",
    "gui.menu.edit.fontSize": "폰트 사이즈",
    "gui.menu.edit.clearCache": "캐시 삭제하기",
    "gui.menu.edit.maxReset": "Max 설정 복원",
    "gui.gui.serial": "시리얼 포트",
    "gui.gui.openSerial": "시리얼 포트 열기",
    "gui.gui.closeSerial": "시리얼 포트 닫기",
    "gui.gui.close": "닫기",
    "gui.gui.open": "열기",
    "gui.gui.clearOutput": "출력 지우기",
    "gui.gui.scroll": "스크롤",
    "gui.gui.send": "보내기",
    "gui.gui.baud": "전송 속도",
    "gui.gui.noLineEnd": "터미네이터 없음",
    "gui.gui.newLine": "줄 바꿈",
    "gui.gui.carriageReturn": "시작하기",
    "gui.gui.bothNLandCR": "시작 및 줄 바꾸기",
    "gui.gui.unavailableHexData": "Invalid hex form! Please represent hex data with two characters ((0-9/A-F) and a blank, e.g. \"00 01 A2 FF\".",
    "gui.gui.hexForm": "Hex Form",
    "gui.gui.openHexForm": "Use hex form to show and send data",
    "gui.gui.closeHexForm": "Close",
    "gui.progress.burning": "업로드 중입니다.",
    "gui.progress.compiling": "컴파일 중입니다.",
    "gui.burner.compileError": "Compile error",
    "gui.burner.linkError": "Link error",
    "gui.burner.generateHexError": "Generate .hex error",
    "gui.burner.generateBinError": "Generate .bin error",
    "gui.burner.burnError": "Burn error",
    "gui.burner.eraseError": "Erase error",
    "gui.burner.findLeonardoPortError": "Cannot find bootlaoder port of Leonardo",
    "gui.burner.noMicrobit": "Sorry it is not micro: bit, please connect micro: bit",
    "gui.burner.UDiskPopUp": "UDisk pops up, please wait for a while and try again",
    "gui.burner.UDiskWriteError": "Write to UDisk error",
    "gui.burner.openPortError": "Could not open port: Access is denied",
    "gui.burner.printObjectTooBig": "The project is too large; please click the \"help\" in the right top to reduce its size. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooBig": "Not enough memory; please click the \"help\" in the right top to reduce memory occupancy. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Low memory available, stability problems may occur. ",
    "gui.burner.printSizeError": "프로그램 크기를 결정할 수 없습니다 ",
    "gui.burner.swdCfgError": "Cannot write to cmsis-dap.cfg",
    "gui.burner.printSize": "프로젝트는 [%TEXTSIZE] 바이트를 사용하고 ([%TEXTSIZEPRE]) 프로그램 메모리 공간을 차지하며 [%TEXTSIZESUR] 바이트를 [%TEXTSIZEMAX] 바이트까지 남겨 둡니다. \n전역 변수는 동적 메모리의 [%DATASIZE] 바이트 ([%DATASIZEPRE])를 사용하여 [%DATASIZESUR] 바이트 로컬 변수는 [%DATASIZEMAX] 바이트까지 유지합니다. ",
    "gui.progress.compileSuccess": "컴파일에 성공했습니다.",
    "gui.progress.compileProgress": "컴파일 진행상황",
    "gui.progress.uploadProgress": "업로드 진행상황",
    "gui.progress.uploadSuccess": "업로드에 성공했습니다.",
    "gui.progress.errMsg": "!업로드 하는데 문제가 발생했습니다. 오류 정보는 우측 하단의 흑색 창을 확인해주세요.",
    "gui.howtos.spin-around.name": "회전하기",
    "gui.howtos.spin.step_dragTurn": "\"오른쪽으로 회전\"블록 드래그하기",
    "gui.howtos.spin.step_clickTurn": "실행할 블록을 클릭하세요.",
    "gui.howtos.spin.step_clickControl": "좌측의 컨트롤 모듈을 클릭하세요.",
    "gui.howtos.spin.step_dragForever": "\"루프실행\" 블록 드래그하기",
    "gui.howtos.spin.step_clickForever": "실행할 블록을 클릭하세요.",
    "gui.howtos.spin.step_changeColor": "\"색깔 특수효과\" 블록 삽입하기",
    "gui.howtos.say-hello.name": "Hello 말하기",
    "gui.howtos.say-hello.step_addSprite": "새 소울 추가하기",
    "gui.howtos.say-hello.step_clickLooks": "좌측의 \"외관\" 모듈 클릭하기",
    "gui.howtos.say-hello.step_dragSay": "\"말하기\" 블록 드래그하기",
    "gui.howtos.say-hello.step_clickSay": "실행할 블록을 클릭하세요.",
    "gui.howtos.say-hello.step_anotherSay": "\"말하기\" 블록 드래그하기",
    "gui.howtos.say-hello.step_editSay": "블록 편집하기",
    "gui.howtos.say-hello.step_clickStack": "실행할 블록을 클릭하세요.",
    "gui.howtos.run-away.name": "도망가기",
    "gui.howtos.run-away.step_dragGoTo": "\"임의 위치로 이동\" 블록 드래그하기",
    "gui.howtos.run-away.step_clickGoTo": "블록을 클릭해 실행하기",
    "gui.howtos.run-away.step3": "우측의 \"이벤트\" 카테고리 선택",
    "gui.howtos.run-away.step_addWhenClicked": "\"이 스프라이트를 클릭했을 때\" 블록 삽입하기",
    "gui.howtos.run-away.step_clickSprite": "스프라이트 실행 클릭",
    "gui.howtos.run-away.step_addSound": "\"음악 재생 시작\" 블록 삽입하기",
    "gui.howtos.pick-up-apple.name": "사과 캐치하기 게임",
    "gui.howtos.pick-up-apple.step_1": "설명 및 실행 페이지로 바로가기",
    "gui.howtos.pick-up-apple.step_2": "상품 바꾸기",
    "gui.howtos.pick-up-apple.step_3": "장치 연결하기",
    "gui.howtos.pick-up-apple.step_4": "나침반 교정하기",
    "gui.howtos.pick-up-apple.step_5": "예시 코드 보기",
    "gui.howtos.pick-up-apple.step_6": "사과 캐치하기 게임",
    "gui.howtos.move-left-right.name": "마이크로비트로 고양이 원거리 컨트롤하기",
    "gui.howtos.move-left-right.step_1": "새 프로젝트 만들기",
    "gui.howtos.move-left-right.step_2": "드라이버 블록 1",
    "gui.howtos.move-left-right.step_3": "드라이버 블록 2",
    "gui.howtos.move-left-right.step_4": "마이크로비트로 고양이 원거리 컨트롤하기",
    "gui.howtos.install-driver.name": "설치 드라이버",
    "gui.howtos.transform-expression.name": "버튼을 누르면 표정이 나타납니다.",
    "gui.howtos.transform-expression.step_1": "업로드 및 실행 화면으로 가기",
    "gui.howtos.transform-expression.step_2": "상품 바꾸기",
    "gui.howtos.transform-expression.step_3": "블록 드래그1",
    "gui.howtos.transform-expression.step_4": "블록 드래그2",
    "gui.howtos.transform-expression.step_5": "블록 드래그 3",
    "gui.howtos.transform-expression.step_6": "버튼을 누르면 표정이 나타납니다.",
    "gui.howtos.lot.name": "사물인터넷(IoT)",
    "gui.howtos.lot.step_1": "예시 프로그램 선택하기",
    "gui.howtos.lot.step_2": "프로그램 굽기",
    "gui.howtos.lot.step_3": "사물인터넷(IoT)",
    "gui.howtos.touch-pin.name": "핀 터치",
    "gui.howtos.touch-pin.step_1": "업로드 및 실행 화면으로 가기",
    "gui.howtos.touch-pin.step_2": "여정 선택하기",
    "gui.howtos.touch-pin.step_3": "프로그램 굽기",
    "gui.howtos.touch-pin.step_4": "핀 터치",
    "gui.howtos.save-witch.name": "마녀 구하기",
    "gui.howtos.save-witch.step_1": "\"실시간 실행\"으로 바꾸기",
    "gui.howtos.save-witch.step_2": "확장에서 \"마이크로비트\" 메인컨트롤러를 선택하세요",
    "gui.howtos.save-witch.step_3": "\"예시프로그램\"의 \"마녀구하기\"를 선택하세요",
    "gui.howtos.save-witch.step_4": "장치를 연결하고 마이크로비트를 선택한 다음, 연결이 성공할 때까지 기다립니다.",
    "gui.howtos.save-witch.step_5": "보정장치 프롬프트가 표시되면, LED 표시등이 완전히 꺼질 때 까지 주 제어 보드를 한 번 돌립니다.",
    "gui.howtos.save-witch.step_6": "초록색 깃발을 클릭하고 팔을 흔들어 애니메이션과 상호 작용하십시오.",
    "gui.howtos.microbit-touch.name": "마이크로비트를 터치해서 표정 바꾸기",
    "gui.howtos.microbit-touch.step_1": "업로드 및 실행 화면으로 가기",
    "gui.howtos.microbit-touch.step_2": "\"학습\"탭 아래 \"예시 프로그램\"을 열어, \"핀 터치\"를 선택합니다.",
    "gui.howtos.microbit-touch.step_3": "장치에 부합하는 COM포트를 연결하고, \"장치에 업로드하기\"를 누르세요.",
    "gui.howtos.microbit-touch.step_4": "각각의 버튼에 따라 다양한 표정이 나타납니다.",
    "gui.howtos.microbit-calibration.name": "micro:bit 보정",
    "gui.howtos.microbit-calibration.step_1": "마이크로비트 보드를 지면과 수직으로 놓고 회전시키면, 온보드 LED 조명이 순서대로 켜집니다.",
    "gui.howtos.microbit-calibration.step_2": "가장자리의 LED조명이 아래 그림과 같이 전부 켜지고 스마일 표정이 나타나면, 보정이 완료됨을 뜻합니다. ",
    "gui.extension.sen0251.name": "BMP388 기압온도 센서",
    "gui.extension.sen0251.description": "온도, 대기압, 해발 측정 기능 탑재",
    "gui.extension.sen0206.name": "비접촉식 적외선 온도 센서",
    "gui.extension.sen0206.description": "Far-infrared non-contact measurement of object or ambient temperature.",
    "gui.gui.recentlyEdited": "최근 편집",
    "gui.extension.weather.name": "날씨 확인하기",
    "gui.extension.weather.description": "날씨 정보를 얻고 Wi-Fi 모듈과 함께 사용해보세요!",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "TinyWebDB 네트워크 데이터베이스 작동하기\r\nAPP Inventor와 함께 사용할 수 있습니다.",
    "gui.gui.variableScopeOptionAllSprites": "모든 스프라이트에서 사용",
    "gui.gui.variableScopeOptionSpriteOnly": "이 스프라이트에서만 사용",
    "gui.gui.variablePromptAllSpritesMessage": "이 변수는 모든 스프라이트에서 사용할 수 있습니다.",
    "gui.monitor.contextMenu.default": "일반보기",
    "gui.monitor.contextMenu.large": "크게보기",
    "gui.monitor.contextMenu.slider": "슬라이더",
    "gui.monitor.contextMenu.import": "가져오기",
    "gui.monitor.contextMenu.export": "내보내기",
    "gui.monitor.listMonitor.listLength": "길이 {length}",
    "gui.monitor.listMonitor.empty": "(비어 있음)",
    "gui.costumeTab.backdrop": "배경",
    "gui.costumeTab.costume": "모델",
    "gui.opcodeLabels.direction": "방향",
    "gui.opcodeLabels.xposition": "x 좌표",
    "gui.opcodeLabels.yposition": "y 좌표",
    "gui.opcodeLabels.size": "크기",
    "gui.opcodeLabels.costumename": "모델 이름",
    "gui.opcodeLabels.costumenumber": "모델 번호",
    "gui.opcodeLabels.backdropname": "배경 이름",
    "gui.opcodeLabels.backdropnumber": "배경 번호",
    "gui.opcodeLabels.volume": "음량",
    "gui.opcodeLabels.tempo": "재생 속도",
    "gui.opcodeLabels.answer": "대답",
    "gui.opcodeLabels.loudness": "음량",
    "gui.opcodeLabels.year": "년",
    "gui.opcodeLabels.month": "월",
    "gui.opcodeLabels.date": "일",
    "gui.opcodeLabels.dayofweek": "요일",
    "gui.opcodeLabels.hour": "시",
    "gui.opcodeLabels.minute": "분",
    "gui.opcodeLabels.second": "초",
    "gui.opcodeLabels.timer": "타이머",
    "gui.loadProject.error": "프로그램을 로딩하는데 실패했습니다.",
    "gui.fileSystem.boardFiles": "Board Files",
    "gui.fileSystem.computerFiles": "Board Computer",
    "gui.fileSystem.newFile": "new file",
    "gui.fileSystem.newFolder": "new folder",
    "gui.fileSystem.deleteFolder": "delete folder",
    "gui.fileSystem.openFolder": "open folder",
    "gui.fileSystem.openInEditor": "open in editor",
    "gui.fileSystem.runRightNow": "run right now",
    "gui.fileSystem.stopRun": "stop run",
    "gui.fileSystem.setBoot": "boot run",
    "gui.fileSystem.deleteForever": "delete forever",
    "gui.fileSystem.saveAs": "save as",
    "gui.fileSystem.undoAllEdits": "cancel all edits",
    "gui.fileSystem.copyToComputer": "copy to computer",
    "gui.fileSystem.uploadToBoard": "upload to board",
    "gui.fileSystem.uploadAndRun": "upload and run",
    "music.categoryName": "음악",
    "translate.categoryName": "번역",
    "sensor.categoryName": "센서",
    "actuator.categoryName": "엑츄에이터",
    "communicate.categoryName": "통신모듈",
    "display.categoryName": "디스플레이",
    "extendFunction.categoryName": "기능모듈",
    "internetService.categoryName": "인터넷 서비스",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "handpy",
    "spread.categoryName": "확장보드",
    "speechRecognition.categoryName": "음성식별",
    "gui.blocklyText.romeo.d1ProgramStart": "D1 로봇 프로그램 시작",
    "gui.blocklyText.romeo.readdigital": "[PIN] 핀 [MODULE] 값 읽기",
    "gui.blocklyText.romeo.buttonModule": "버튼 모듈",
    "gui.blocklyText.romeo.digitalSensor": "디지털 센서",
    "gui.blocklyText.romeo.collisionSensor": "충격 센서",
    "gui.blocklyText.romeo.lineSensor": "라인트래킹 센서",
    "gui.blocklyText.romeo.infraredSwitch": "적외선 스위치",
    "gui.blocklyText.romeo.vibrationSensor": "진동 센서",
    "gui.blocklyText.romeo.readAnalog": "[PIN] 핀 [SENSOR] 값 읽기",
    "gui.blocklyText.romeo.lightSensor": "빛 센서",
    "gui.blocklyText.romeo.anologSensor": "아날로그 센서",
    "gui.blocklyText.romeo.soundSensor": "소리 센서",
    "gui.blocklyText.romeo.soilMoistureSensor": "토양 수분 센서",
    "gui.blocklyText.romeo.frameSensor": "화염 센서",
    "gui.blocklyText.romeo.open": "열기",
    "gui.blocklyText.romeo.close": "닫기",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [PIN] 핀의 [MODULE]",
    "gui.blocklyText.romeo.ledLights": "LED 조명",
    "gui.blocklyText.romeo.digitalActuator": "디지털 엑츄에이터",
    "gui.blocklyText.romeo.relay": "릴레이",
    "gui.blocklyText.romeo.fan": "팬",
    "gui.blocklyText.romeo.writeAnalog": "[PIN] 핀 [MODULE] 값을 [VALUE]로 설정하기",
    "gui.blocklyText.romeo.simulationActuator": "시뮬레이션 엑츄에이터",
    "gui.blocklyText.romeo.fanModule": "팬 모듈",
    "gui.blocklyText.romeo.robotSpeedMove": "로봇을  [SPEED]속도로 [DIR]",
    "gui.blocklyText.romeo.forward": "전진하기",
    "gui.blocklyText.romeo.backward": "후퇴하기",
    "gui.blocklyText.romeo.turnLeft": "왼쪽 회전",
    "gui.blocklyText.romeo.turnRight": "오른쪽 회전",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "모토 [MOTOR]를  [SPEED]속도 로 [DIR] 설정하기",
    "gui.blocklyText.romeo.interface": "포트",
    "gui.blocklyText.romeo.motorForward": "정방향 회전",
    "gui.blocklyText.romeo.motorReverse": "반방향 회전",
    "gui.blocklyText.romeo.stopMotor": "로보트(M1 과 M2 모터) 작동 중지",
    "gui.blocklyText.romeo.readUltrasonic": "초음파 거리 (cm) 삼각 판독 [TRIG] 에코 [ECHO]",
    "gui.blocklyText.romeo.lm35": "판독 핀 [PIN] LM35 온도 (°C)",
    "gui.blocklyText.romeo.ds18b20": "판독 핀 [PIN] DS18B20 온도 (°C)",
    "gui.blocklyText.romeo.pressInfrared": "적외선 [PIN]핀 이 [BUTTON] 눌러졌음을 수신하였습니까？",
    "gui.blocklyText.romeo.volumePlus": "음량+",
    "gui.blocklyText.romeo.switch": "스위치",
    "gui.blocklyText.romeo.volumeMinus": "음량-",
    "gui.blocklyText.romeo.startPause": "재생/중지",
    "gui.blocklyText.romeo.up": "위",
    "gui.blocklyText.romeo.down": "아래",
    "gui.blocklyText.romeo.dataReadable": "시리얼포트에 읽을 데이터가 있습니까?",
    "gui.blocklyText.romeo.readSerialData": "시리얼포트 데이터 읽기",
    "gui.blocklyText.romeo.timer": "시스템 운영 시간(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "[PIN]핀 부저 톤[TONE]을 비트 [BEAT]로 설정",
    "gui.blocklyText.romeo.half": "2분의 1",
    "gui.blocklyText.romeo.quarter": "4분의 1",
    "gui.blocklyText.romeo.oneInEighth": "8분의 1",
    "gui.blocklyText.romeo.wholeBeat": "전체 비트",
    "gui.blocklyText.romeo.doubleBeat": "더블 비트",
    "gui.blocklyText.romeo.stop": "정지",
    "gui.blocklyText.romeo.setServo": " [PIN]  핀 서보를  [DEGREE] 도로 설정하기",
    "gui.blocklyText.romeo.segment": " 8 분할 닉시튜프(D6-D13핀) [TUBE] 설정하기",
    "gui.blocklyText.romeo.serialSendString": "시리어포트[WAY] [STRING] [LINE]",
    "gui.blocklyText.romeo.originalOutput": "오리지날 출력",
    "gui.blocklyText.romeo.stringOutput": "문자열 출력",
    "gui.blocklyText.romeo.hexOutput": "16진 출력",
    "gui.blocklyText.romeo.wrap": "행 바꾸기",
    "gui.blocklyText.romeo.noWrap": "행 바꾸지 않기",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "시리어 포트에서 [READTYPE] 읽기",
    "gui.blocklyText.romeo.int": "정수",
    "gui.blocklyText.romeo.float": "소수",
    "gui.blocklyText.romeo.setBaudRate": "시리어포트 전송률을 [BAUD] 설정하기",
    "gui.blocklyText.romeo.readPinWidth": "핀 [PIN] 펄스폭 읽기.모드 [LEVEL] 초과시간[TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "낮은 레벨",
    "gui.blocklyText.romeo.high": "높은 레벨",
    "gui.blocklyText.romeo.noSound": "무음",
    "gui.blocklyText.arduino.arduinoStarts": "아두이노 메인 프로그램",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno 메인 프로그램",
    "gui.blocklyText.esp32.displayInLine": "display [TEXT] in the [LINE] line",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "clear cache in the [LINE] line",
    "gui.blocklyText.esp32.displayClearInLine": "clear in the [LINE] line",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 메인 프로그램",
    "gui.blocklyText.esp32.btnIsPressed": "버튼 [BUTTON] 이 [BUTTONSTATE] 입니까？",
    "gui.blocklyText.esp32.whenBtnPress": "[REFERENCE]버튼이  [BUTTONSTATE] 일 때",
    "gui.blocklyText.esp32.whenPinConnected": "터치한 버튼 [REFERENCE] 이 [TOUCHSTATE] 일 때",
    "gui.blocklyText.esp32.onGesture": "When [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "터치한 버튼[PIN]이 [TOUCHSTATE] 입니까?",
    "gui.blocklyText.esp32.buzzerSwitch": "배경음악 재생 중지",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "백그라운드에서 음표 [NOTE] 재생하기",
    "gui.blocklyText.esp32.setBuzzerFreq": "음표[NOTE]를 [BEAT]비트로 재생하기",
    "gui.blocklyText.esp32.getSoundIntensity": "마이크 소리 강도 읽기",
    "gui.blocklyText.esp32.readBrightness": "조도 읽기",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS]에 색깔 [COLOR] 보이기",
    "gui.blocklyText.esp32.rgbToColor": "빨강 [RED] 초록 [GREEN] 파랑[BLUE]",
    "gui.blocklyText.esp32.acceleration": "가속도값(mg) [TYPE]읽기 ",
    "gui.blocklyText.esp32.displayInXY": "화면에 좌표 X에 텍스트 [TEXT]가 표시됩니다 : [X] Y : [Y] 미리보기 [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "화면에 [COLOR]로 나타납니다 (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "화면에 캐시를 [COLORMPYTHON]로 기입하기 (clear screen)",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": "전체 흰색",
    "gui.blocklyText.esp32.lineDrawing": "선 그리기 시작점 x1:[X1] y1:[Y1] 종착점x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "연결하기",
    "gui.blocklyText.esp32.disconnected": "연결끊기",
    "gui.blocklyText.esp32.rectangleDrawing": "사각형 그리기[FILL] 시작점 x:[X] y:[Y] 폭:[WIDTH] 높이:[HEIGHT]",
    "gui.blocklyText.esp32.screenDisplay": "화면 캐시 표시가 적용됩니다.",
    "gui.blocklyText.esp32.circleDrawing": "원 그리기[FILL] 중점 x:[X] y:[Y] 반경:[RADIUS]",
    "gui.blocklyText.esp32.fill": "채우기",
    "gui.blocklyText.esp32.notFill": "채우지 않기",
    "gui.blocklyText.esp32.pointDrawing": "좌표 x:[X] y:[Y]",
    "gui.blocklyText.esp32.setLineWidth": "선 넓이를 [LINEWIDTH]로 설정하기",
    "gui.blocklyText.esp32.digitalWrite": "디지털 핀 [PIN] 출력[VALUE]로 설정하기",
    "gui.blocklyText.esp32.digitalRead": "디지털핀 [PIN] 읽기",
    "gui.blocklyText.esp32.analogWrite": "아날로그 핀 [PIN] 출력 (PWM) [VALUE] 설정",
    "gui.blocklyText.esp32.analogRead": "아날로그핀 [PIN] 읽기",
    "gui.blocklyText.esp32.serialPort0": "시리얼 포트 0",
    "gui.blocklyText.esp32.serialPort1": "시리얼 포트 1",
    "gui.blocklyText.esp32.serialPort2": "시리얼 포트 2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX] 전송률을 [BAUD]로 설정하기",
    "gui.blocklyText.esp32.imageDrawing": "좌표 x:[X] y:[Y]의 이미지[IMAGE]를 화면에 보이기 ",
    "gui.blocklyText.esp32.imageDrawingForMpy": " x:[X] y:[Y]의 캐시 이미지 [IMAGE]를 화면에 적기",
    "gui.blocklyText.esp32.pressed": "누르기",
    "gui.blocklyText.esp32.loosened": "손 떼기",
    "gui.blocklyText.esp32.all": "전부",
    "gui.blocklyText.esp32.setBaud": "시리어포트0 전송율[BAUD] 설정하기",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "[SERIAL] 에서  [READTYPE] 읽기",
    "gui.blocklyText.esp32.setBrightness": "LED조명 밝기를 [BRIGHTVALUE] 로 설정하기",
    "gui.blocklyText.esp32.getLightness": "LED 밝기 읽기",
    "gui.blocklyText.esp32.print": "프린트 [DATA]",
    "gui.blocklyText.esp32.playMusicNow": "재생 [PLAY]",
    "gui.blocklyText.esp32.TiltForward": "Tilt forward",
    "gui.blocklyText.esp32.TiltBack": "Tilt back",
    "gui.blocklyText.esp32.TiltLeft": "Tilt left",
    "gui.blocklyText.esp32.TiltRight": "Tilt right",
    "gui.blocklyText.esp32.ScreenUp": "Screen up",
    "gui.blocklyText.esp32.ScreenDown": "Face down",
    "gui.blocklyText.esp32.Shake": "Shake",
    "gui.blocklyText.esp32.isGesture": "is [GESTURE]?",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 메인 프로그램",
    "gui.blocklyText.mega2560.digitalRead": "디지털핀 [PIN] 읽기",
    "gui.blocklyText.mega2560.analogRead": "아날로그핀 [PIN] 읽기",
    "gui.blocklyText.mega2560.pwmWrite": "pwm핀 [PIN] 출력 [VALUE]로 설정하기",
    "gui.blocklyText.mega2560.digitalWrite": "디지털핀 [PIN] 출력 [VALUE]로 설정하기",
    "gui.blocklyText.mega2560.level.low": "낮은 레벨",
    "gui.blocklyText.mega2560.level.high": "높은 레벨",
    "gui.blocklyText.mega2560.irqRead": "적외선 수신 모듈 읽기디지털 핀 [PIN]",
    "gui.blocklyText.mega2560.ultrasonicRead": "초음파센서 trig를 [TRIG]로, echo를[ECHO]로 읽기",
    "gui.blocklyText.mega2560.buzzerSet": "핀 [PIN] 부저 톤을 [TONE] 비트를 [BEAT]로 설정하기",
    "gui.blocklyText.mega2560.harf": "2분의 1",
    "gui.blocklyText.mega2560.quarter": "4분의 1",
    "gui.blocklyText.mega2560.oneInEighth": "8분의 1",
    "gui.blocklyText.mega2560.wholeBeat": "전체 비트",
    "gui.blocklyText.mega2560.doubleBeat": "더블 비트",
    "gui.blocklyText.mega2560.stop": "정지",
    "gui.blocklyText.mega2560.servoSet": "핀 [PIN] 서보를 [DEGREE] 각도로 설정",
    "gui.blocklyText.mega2560.setBaud": "[SERIAL] 전송률을 [BAUD] 로 설정하기",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": " [SERIAL] 에서 [READTYPE] 읽기",
    "gui.blocklyText.mega2560.serialPort0": "시리얼 포트 0",
    "gui.blocklyText.mega2560.serialPort1": "시리얼 포트 1",
    "gui.blocklyText.mega2560.serialPort2": "시리얼 포트 2",
    "gui.blocklyText.mega2560.serialPort3": "시리얼 포트 3",
    "gui.blocklyText.mega2560.dataReadable": "[SERIAL] 에 읽을 데이터가 있습니까？",
    "gui.blocklyText.mega2560.readSerialData": " [SERIAL] 데이터 읽기",
    "gui.blocklyText.mega2560.stringOutput": "문자열 출력",
    "gui.blocklyText.mega2560.hexOutput": "16진 출력",
    "gui.blocklyText.mega2560.wrap": "행 바꾸기",
    "gui.blocklyText.mega2560.noWrap": "행 바꾸지 않기",
    "gui.blocklyText.mega2560.readPinLevel": "핀 [PIN] 펄스 폭 읽기모드 [LEVEL] 초과시간 [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "시스템 운영 시간(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini 메인 프로그램",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo 메인 프로그램",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 메인 프로그램",
    "gui.blocklyText.arduino.setDigital": "디지털핀 [PIN] 출력을 [LEVEL]로 설정하기",
    "gui.blocklyText.arduino.readDigital": "디지털 핀 [PIN] 읽기",
    "gui.blocklyText.arduino.readSimilation": "아날로그 핀 [PIN] 읽기",
    "gui.blocklyText.arduino.setPwm": "pwm핀 [PIN] 출력 [VALUE]로 설정하기",
    "gui.blocklyText.arduino.readUlrasonic": "초음파 거리 (cm) 삼각 판독 [TRIG] 에코 [ECHO]",
    "gui.blocklyText.arduino.trumpet": "핀 [PIN] 부저 톤을 [TONE]으로,  비트를 [BEAT] 로 설정하기",
    "gui.blocklyText.arduino.serialSendStr": "시리얼포트[WAY] [STRING] [LINE]",
    "gui.blocklyText.arduino.originalOutput": "오리지날 출력",
    "gui.blocklyText.arduino.stringOutput": "문자열 출력",
    "gui.blocklyText.arduino.hexOutput": "16진 출력",
    "gui.blocklyText.arduino.wrap": "행 바꾸기",
    "gui.blocklyText.arduino.noWrap": "행 바꾸지 않기",
    "gui.blocklyText.arduino.readInfrared": "적외선 수신 모듈 읽기디지털 핀 [PIN]",
    "gui.blocklyText.arduino.setBaud": "시리얼포트 전송률을 [BAUD]로 설정하기",
    "gui.blocklyText.arduino.readPinLevel": "판독 핀 [PIN] 펄스 폭 패턴 [LEVEL] 타임 아웃 [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "무음",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Nano 메인 프로그램 시작",
    "gui.blocklyText.vortex.vortexStart": "Vortex 로봇 프로그램 시작",
    "gui.blocklyText.vortex.vortexMoveSpeed": "Vortex 를 [SPEED]의 속도로 [DIRECTION]하기",
    "gui.blocklyText.vortex.forward": "전진하기",
    "gui.blocklyText.vortex.backup": "후퇴하기",
    "gui.blocklyText.vortex.turnLeft": "좌회전하기",
    "gui.blocklyText.vortex.turnRight": "우회전하기",
    "gui.blocklyText.vortex.setMotor": "모터 [MOTOR]를  [SPEED]의 속도로 [DIRECTION] 설정하기",
    "gui.blocklyText.vortex.leftWheel": "왼쪽 바퀴",
    "gui.blocklyText.vortex.rightWheel": "오른쪽 바퀴",
    "gui.blocklyText.vortex.setExpression": "[EXPRESSION]이모티콘 얼굴색을 [COLOR]로 설정하기",
    "gui.blocklyText.vortex.ledLights": "[DIRECTION][LIGHT][SECOND] 초 내 변화를 [COLOR] 로 설정하기",
    "gui.blocklyText.vortex.all": "전부",
    "gui.blocklyText.vortex.rightAhead": "바로 앞",
    "gui.blocklyText.vortex.leftRear": "왼쪽 뒤",
    "gui.blocklyText.vortex.rightRear": "오른쪽 뒤",
    "gui.blocklyText.vortex.rightAstern": "바로 뒤",
    "gui.blocklyText.vortex.topLights": "밝은 색",
    "gui.blocklyText.vortex.bottomLights": "어두운 색",
    "gui.blocklyText.vortex.vortexStop": "Vortex가 운동을 멈췄습니다.",
    "gui.blocklyText.vortex.setVolume": "음량 크기를  [VALUE]로 설정하기",
    "gui.blocklyText.vortex.playMusic": "[VALUE] 번째 음악 재생하기",
    "gui.blocklyText.vortex.stopMusic": "음악 정지",
    "gui.blocklyText.vortex.startDance": "[VALUE]번째 동작 시작하기",
    "gui.blocklyText.vortex.stopDance": "춤 정지",
    "gui.blocklyText.vortex.barrierDetection": "전방에 장애물이 있습니까?",
    "gui.blocklyText.vortex.readSensor": "[SENSOR]회색톤 센서 값 읽기",
    "gui.blocklyText.vortex.setSensor": "회색톤 센서가 변화량을  [VALUE]로 측정하도록 설정하기",
    "gui.blocklyText.vortex.sensorMeetBlack": "회색톤 센서 [SENSOR]가 검은 선을 감지하였습니까？",
    "gui.blocklyText.vortex.positiveFrontLeft": "왼쪽 앞",
    "gui.blocklyText.vortex.frontLeft": "왼쪽 앞",
    "gui.blocklyText.vortex.positiveFrontRight": "오른쪽 앞",
    "gui.blocklyText.vortex.frontRight": "오른쪽 앞",
    "gui.blocklyText.vortex.backRight": "오른쪽 뒤",
    "gui.blocklyText.vortex.backLeft": "왼쪽 뒤",
    "gui.blocklyText.vortex.initialOldPCB": "이전 버젼(파란색 PCB)으로 초기화하기",
    "pen.categoryName": "펜",
    "pen.changeColorParam": "펜 [COLOR_PARAM]을(를) [VALUE]만큼 바꾸기",
    "pen.changeHue": "펜 색깔을 [HUE]만큼 바꾸기",
    "pen.changeShade": "펜 명암을 [SHADE] 만큼 바꾸기 ",
    "pen.changeSize": "펜 굵기를 [SIZE] 만큼 바꾸기",
    "pen.clear": "모두 지우기",
    "pen.colorMenu.brightness": "명도",
    "pen.colorMenu.color": "색깔",
    "pen.colorMenu.saturation": "채도 ",
    "pen.colorMenu.transparency": "투명도",
    "pen.penDown": "펜 내리기",
    "pen.penUp": "펜 올리기",
    "pen.setColor": "펜 색깔을 [COLOR](으)로 정하기 ",
    "pen.setColorParam": "펜 [COLOR_PARAM]을(를) [VALUE](으)로 정하기",
    "pen.setHue": "펜 색깔을 [HUE](으)로 정하기",
    "pen.setShade": "펜 명암을 [SHADE](으)로 정하기 ",
    "pen.setSize": "펜 굵기를 [SIZE](으)로 정하기",
    "pen.stamp": "도장",
    "music.changeTempo": "템포를 [TEMPO]로 바꾸기",
    "music.drumBass": "(2) 베이스 드럼",
    "music.drumBongo": "(13) 봉고",
    "music.drumCabasa": "(15) 카바사",
    "music.drumClaves": "(9) 클라베",
    "music.drumClosedHiHat": "(6) 닫힌 하이햇",
    "music.drumConga": "(14) 콩가",
    "music.drumCowbell": "(11) 카우벨 ",
    "music.drumCrashCymbal": "(4) 크래시 심벌",
    "music.drumCuica": "(18) 쿠이카 ",
    "music.drumGuiro": "(16) 귀로 ",
    "music.drumHandClap": "(8) 박수",
    "music.drumOpenHiHat": "(5) 열린 하이햇",
    "music.drumSideStick": "(3) 사이드 스틱",
    "music.drumSnare": "(1) 스네어 드럼 ",
    "music.drumTambourine": "(7) 탬버린",
    "music.drumTriangle": "(12) 트라이앵글 ",
    "music.drumVibraslap": "(17) 비브라슬랩 ",
    "music.drumWoodBlock": "(10) 나무 블록",
    "music.getTempo": "템포",
    "music.instrumentBass": "(6) 베이스",
    "music.instrumentBassoon": "(14) 바순",
    "music.instrumentCello": "(8) 첼로 ",
    "music.instrumentChoir": "(15) 합창단 ",
    "music.instrumentClarinet": "(10) 클라리넷 ",
    "music.instrumentElectricGuitar": "(5) 전자 기타 ",
    "music.instrumentElectricPiano": "(2) 전자 피아노 ",
    "music.instrumentFlute": "(12) 플루트 ",
    "music.instrumentGuitar": "(4) 기타",
    "music.instrumentMarimba": "(19) 마림바 ",
    "music.instrumentMusicBox": "(17) 뮤직 박스",
    "music.instrumentOrgan": "(3) 오르간",
    "music.instrumentPiano": "(1) 피아노",
    "music.instrumentPizzicato": "(7) 피치카토 ",
    "music.instrumentSaxophone": "(11) 색소폰 ",
    "music.instrumentSteelDrum": "(18) 스틸 드럼 ",
    "music.instrumentSynthLead": "(20) 신드 리드",
    "music.instrumentSynthPad": "(21) 신드 패드 ",
    "music.instrumentTrombone": "(9) 트럼본",
    "music.instrumentVibraphone": "(16) 비브라폰",
    "music.instrumentWoodenFlute": "(13) 나무 플루트 ",
    "music.midiPlayDrumForBeats": "[DRUM]를 [BEATS] 비트로 연주하기",
    "music.midiSetInstrument": "악기를 [INSTRUMENT](으)로 정하기",
    "music.playDrumForBeats": "[DRUM]를 [BEATS] 비트로 연주하기",
    "music.playNoteForBeats": "음표[NOTE]를 [BEATS] 비트로 연주하기",
    "music.restForBeats": "[BEATS] 박자 쉬기 ",
    "music.setInstrument": "악기를 [INSTRUMENT](으)로 정하기",
    "music.setTempo": "빠르기를 [TEMPO](으)로 정하기",
    "gui.blocklyText.microbit.microbitStarts": "마이크로비트 메인 프로그램 시작",
    "gui.blocklyText.microbit.interruptExcute": "인터럽트 핀[PIN] 모드 [MODE]실행",
    "gui.blocklyText.microbit.cancleInterrupt": "인터럽트핀[PIN] 취소하기",
    "gui.blocklyText.microbit.wirelessReceiveData": "오프라인으로 데이터 수신하기",
    "gui.blocklyText.microbit.whenBtnPress": "[REFERENCE] 버튼이 눌러졌을 때",
    "gui.blocklyText.microbit.whenPinConnected": "포트 [REFERENCE] 가 연결됐을 때",
    "gui.blocklyText.microbit.whenPosChange": "[REFERENCE]일 때",
    "gui.blocklyText.microbit.showFont": "그림[PIC] 표시하기",
    "gui.blocklyText.microbit.showInput": "문자 표시하기 [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "애니메이션 중지",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] 좌표 x:[XAXIS], y:[YXAXIS]",
    "gui.blocklyText.microbit.hideAllLights": "모든 도트 매트릭스 제거하기",
    "gui.blocklyText.microbit.microbitEnableLight": "LED enable [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "밝기",
    "gui.blocklyText.microbit.showLightWithBrightness": "밝기[BRIGHTVALUE] 설정",
    "gui.blocklyText.microbit.controlLightBrightness": "밝히기 좌표x:[XAXIS], y:[YXAXIS], 밝기 [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "포트[PIN] 백그라운드 음악 [SOUND]을 재생합니다.",
    "gui.blocklyText.microbit.playSoundUntil": "포트 [PIN]이 사운드[SOUND]를 끝날 때까지 재생합니다.",
    "gui.blocklyText.microbit.playNote": "포트[PIN]가 음표[NOTE]비트[BEAT] 를 재생합니다.",
    "gui.blocklyText.maqueen.playSound": "포트 P0이 사운드[SOUND] 를 재생합니다.",
    "gui.blocklyText.maqueen.playSoundUntil": "포트 P0이 사운드[SOUND]를 끝날 때까지 재생합니다.",
    "gui.blocklyText.maqueen.playNote": "포트 P0 이 음표[NOTE]비트 [BEAT]를 재생합니다.",
    "gui.blocklyText.maqueen.RGB0": "RGB0 (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1 (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2 (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3 (3)",
    "gui.blocklyText.maqueen.All": "전부 (-1)",
    "gui.blocklyText.microbit.changeTempo": "BPM(bpm)을 [VALUE]로 바꾸기",
    "gui.blocklyText.microbit.setTempo": "BPM(bpm)을 [VALUE]로 설정하기",
    "gui.blocklyText.microbit.getTempo": "BPM",
    "gui.blocklyText.microbit.stopSound": "배경 재생 정지",
    "gui.blocklyText.microbit.btnIsPressed": "버튼 [BUTTON]이 눌러졌습니까?",
    "gui.blocklyText.microbit.pinIsConnected": "핀[PIN]이 연결되었습니까？",
    "gui.blocklyText.microbit.isMove": "현재 상태 [TYPE]는？",
    "gui.blocklyText.microbit.compass": "나침반 방향(°) 읽기",
    "gui.blocklyText.microbit.temperature": "온도값(℃) 읽기",
    "gui.blocklyText.microbit.digitalWrite": "디지털핀 [PIN] 출력 [VALUE] 설정하기",
    "gui.blocklyText.microbit.digitalRead": "디지털핀 [PIN] 읽기",
    "gui.blocklyText.microbit.analogWrite": "아날로그핀 [PIN] 출력 [VALUE] 설정하기",
    "gui.blocklyText.microbit.analogRead": "아날로그핀 [PIN] 읽기",
    "gui.blocklyText.microbit.up": "로고가 위로 향하게",
    "gui.blocklyText.microbit.down": "로고가 아래로 향하게",
    "gui.blocklyText.microbit.left": "왼쪽으로 기울이기",
    "gui.blocklyText.microbit.right": "오른쪽으로 기울이기",
    "gui.blocklyText.microbit.faceUp": "디스플레이를 위로 향하게",
    "gui.blocklyText.microbit.faceDown": "디스플레이를 아래로 향하게",
    "gui.blocklyText.microbit.freefall": "자유낙하운동",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "흔들기",
    "gui.blocklyText.microbit.show": "켜기",
    "gui.blocklyText.microbit.hide": "끄기",
    "gui.blocklyText.microbit.low": "낮은 레벨",
    "gui.blocklyText.microbit.high": "높은 레벨",
    "gui.blocklyText.microbit.microbitReadBrightness": "주변 조도 읽어내기",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] 오프라인 통신",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "오프라인 채널을 [NUM]로 설정하기",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "오프라인으로  [TEXT] 전송하기",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "오프라인으로 데이터 수신하기",
    "gui.blocklyText.microbit.strength": "강도",
    "gui.blocklyText.microbit.acceleration": "가속도 값[TYPE] 읽기(mg)",
    "gui.blocklyText.microbit.magneticForce": "자력계 값 [TYPE] 읽기(µT)",
    "gui.blocklyText.microbit.print": "프린트 [DATA]",
    "gui.blocklyText.microbit.goUp": "올라가기",
    "gui.blocklyText.microbit.goDown": "내려가기",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "[WirelessData]를 수신할 때",
    "gui.blocklyText.microbit.wirelessData": "오프라인 데이터",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot 메인 프로그램 시작",
    "gui.blocklyText.maxbot.playSound": "사운드 재생[SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "사운드 [SOUND]를 끝날 때까지 재생하기",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "[LEFTRIGHT]충돌이 발생했습니까?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "[LEFTRIGHT]이 검은 선을 발견했습니까?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": " 모토 [MOTOR] 를 [SPEED]의 속도로 [DIR] 하기",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "(P1,P2)초음파센서 거리 단위[UNIT] 읽기",
    "gui.blocklyText.sen0001.readUltrasound": "초음파센서 단위 [UNIT], trig [TRIG], echo [ECHO] 읽기",
    "gui.blocklyText.maxbot.FORWARD": "앞으로",
    "gui.blocklyText.maxbot.BACKWARD": "뒤로",
    "gui.blocklyText.motorbit.CW": "시계 방향으로 회전",
    "gui.blocklyText.motorbit.CCW": "시계 반대 방향으로 회전",
    "gui.blocklyText.maxbot.CW": "시계 방향으로 회전",
    "gui.blocklyText.maxbot.CCW": "시계 반대 방향으로 회전",
    "gui.blocklyText.maxbot.ANGLE": "각도",
    "gui.blocklyText.maxbot.CIRCLE": "랩 수",
    "gui.blocklyText.maxbot.Left": "왼쪽",
    "gui.blocklyText.maxbot.Right": "오른쪽",
    "gui.blocklyText.maxbot.cm": "센티미터",
    "gui.blocklyText.maxbot.inch": "인치",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit 메인 프로그램 시작",
    "gui.blocklyText.MotorBit.MotorRun": "모터 [MOTOR]를 [SPEED]의 속도로 [DIR]설정하기",
    "gui.blocklyText.MotorBit.MotorStop": "모터 [MOTOR] 정지",
    "gui.blocklyText.MotorBit.Stepper": "스테퍼 모터[STEPPER][ROTATEDIR]  [NUM] [ANGLECIRCLE]로 설정하기",
    "gui.blocklyText.MotorBit.all": "전부",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen 메인 프로그램 시작",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "(P1 P2)초음파거리(cm) 읽기",
    "gui.blocklyText.maqueen.motorStop": "로봇(좌측 우측 모터)정지",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] Led조명",
    "gui.blocklyText.maqueen.redLineSensor": "라인트래킹 센서 [LEFTRIGHT] 읽기",
    "gui.blocklyText.maqueen.robotSpeedMove": "로봇을 [SPEED]의 속도로 [DIR]하기",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "모터 [LEFTRIGHT]를 [SPEED]의 속도로 [DIR] 설정하기",
    "gui.blocklyText.maqueen.ledLeft": "좌측(P8)",
    "gui.blocklyText.maqueen.ledRight": "우측(P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "우측(P12) (사용할 수 없음)",
    "gui.blocklyText.maqueen.lineSensorLeft": "좌측(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "우측(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "적외선 신호를 수신했을 때 실행하기 [WirelessData]",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "(P16)적외선 신호 값 읽기",
    "gui.blocklyText.maqueen.numberOfLightsBright": "RGB조명 핀 P15 조명 총 수4 밝기[BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "RGB조명 핀 P15 조명 번호 [NUM1]에 색깔 [COLOR] 표시하기",
    "gui.blocklyText.maqueen.showGradientColor": "RGB조명 핀P15 조명 번호[NUM1] 부터[NUM2]까지 그라데이션 색[COLOR1]부터  [COLOR2]까지 표시하기",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "RGB조명 핀 P15 이동[NUM] 단위 ",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "RGB조명 핀P15 반복이동 [NUM] 단위",
    "gui.blocklyText.maqueen.setWs2812Brightness": "RGB조명 핀P15 밝기를 [BRIGHTNESS]로 설정하기",
    "gui.blocklyText.maqueen.showHistogram": "RGB조명 핀P15 조명번호[NUM1] 부터 [NUM2]까지 히스토그램 표시하기현재값[VALUE] 최대값 [MAX]",
    "gui.blocklyText.maqueen.clear": "RGB조명 핀 P15 모두 제거하기",
    "gui.blocklyText.maqueen.rgbColor": "빨강 [RED] 초록 [GREEN] 파랑[BLUE]",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC]이 데이터를 수신하였습니까？",
    "gui.blocklyText.obloq.initialParameter": "Obloq mqtt 초기화: [PARAMETER] 핀을  수신(초록선): [PINR] 발신(파란선): [PINT] [SERIALPORT]",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq사 데이터[MSG] 를  [TOPIC]로 발송합니다.",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq가 [TOPIC]의 데이터를 읽습니다.",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq가 데이터를 읽습니다.",
    "gui.blocklyText.obloq.httpInitialParameter": "Obloq http 초기화: [PARAMETER]핀을 수신(초록선): [PINR] 발신(파란선): [PINT] [SERIALPORT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] 초과시간(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL]컨텐츠: [CONTENT] 초과시간(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] 컨텐츠: [CONTENT] 초과시간(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "[TOPIC]구독하기 : [MSG]",
    "gui.blocklyText.obloq.username": "Wi-Fi이름",
    "gui.blocklyText.obloq.password": "Wi-Fi비밀번호",
    "gui.blocklyText.obloq.iotService": "IoT 플랫폼 변수",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "IP주소",
    "gui.blocklyText.obloq.port": "포트 넘버",
    "gui.blocklyText.obloq.addTopic": "Topic 추가하기, 최대 4개까지 추가가능",
    "gui.blocklyText.obloq.deleteTopic": "Topic 제거",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "서버",
    "gui.blocklyText.obloq.global": "글로벌",
    "gui.blocklyText.obloq.china": "중국",
    "gui.blocklyText.obloq.softwareSerial": "소프트웨어 시리어 포트",
    "gui.blocklyText.obloq.hardwareSerial": "하드웨어 시리어 포트",
    "gui.blocklyText.obloq.hardwareSerial1": "Hardware Serial1",
    "gui.blocklyText.obloq.hardwareSerial2": "Hardware Serial2",
    "gui.blocklyText.obloq.hardwareSerial3": "Hardware Serial3",
    "gui.blocklyText.obloq.WhenReceivedData": "[TOPIC]이 [MSG]를 수신했을 때",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq데이터",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq get [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Debug info",
    "gui.blocklyText.obloq.ERROR": "Error code",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "적외선 방출 [TYPE] 핀 [PIN] 숫자 [VALUE] 비트 [BIT]",
    "gui.blocklyText.infraredEmission.send": "Infrared send pin [PIN] Send message: address [ADDRESS] command [COMMAND]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64  [LINE] 번째 행에 [TEXT] 표시",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 좌표 X: [X] Y:16* [Y] 에 [TEXT] 표시",
    "gui.blocklyText.oled2864.screenRotation": "디스플레이를 [ROTATION]도 만큼 회전하기",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 디스플레이 비우기",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "[PIN]핀 [DHT] [TYPE] 읽기",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx pin [PIN] PM concentration value [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 ultrasonic initialization Name [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04 ultrasonic distance measurement unit [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx pin [PIN] 0.1 liters of air in [DIAMETER] number of particles",
    "gui.blocklyText.sen0170.readWindspeed": "핀 [PIN] 풍속 등급",
    "gui.blocklyText.sen0228.readAmbientLight": "조도(VEML7700) 읽기",
    "gui.blocklyText.dfr0026.readAmbientLight": "핀 [PIN] 조도 읽기",
    "gui.blocklyText.sen0177.readDustSensor": "read [TYPE] (ug/m3) interface [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "핀(D) [PIN] 핀(MCLR) [RST] 현재 상태가 [TYPE] 입니까？",
    "gui.blocklyText.sen0203.readHeartRate": "핀 [PIN] [TYPE]을 [SWITCH]로 바꾸기",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] 핀 [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "핀 [PIN] 그라데이션 값",
    "gui.blocklyText.dfr0552.init": "DA module init I2C address [ADDR] voltage reference [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "DA module output triangular wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "DA module output sine wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "DA module output voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "DA module memory voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0576.selectPort": "Select port [PORT]",
    "gui.blocklyText.dfr0576.begin": "Initialization address [ADDR]",
    "gui.blocklyText.sen0248.readBME680": "Get the BME680 sensor [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "Temperature (°C)",
    "gui.blocklyText.sen0248.Pressure": "Pressure (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Humidity (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0248.GasResistance": "가스 저항 (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "initialize the I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Change I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Read ultrasonic sensor distance(cm)",
    "gui.blocklyText.sen0304.readTemperature": "Read ultrasonic sensor temperature(°C)",
    "gui.blocklyText.sen0307.readDistance": "Read [PIN] analog ultrasonic wave(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 initialization address [ADDR] mode [MODE]",
    "gui.blocklyText.sen0250.Step": "Step",
    "gui.blocklyText.sen0250.Measure": "Measure",
    "gui.blocklyText.sen0250.readAccelerated": "BMI160 get the [AXIS] axis Acceleration(g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 get step number",
    "gui.blocklyText.sen0250.Gyro": "Gyro",
    "gui.blocklyText.sen0250.Acc": "Acceleration (g)",
    "gui.blocklyText.sen0224.readAccelerated": "Read acceleration value of LIS2DH sensor [AXIS] axis (mg)",
    "gui.blocklyText.dfr0023.readLM35": "핀[PIN] LM35 온도(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "핀 [PIN] 이 진동을 감지했을 때",
    "gui.blocklyText.dfr0028.readTiltSensor": "핀 [PIN] 기울기를 감지했습니까？",
    "gui.blocklyText.dfr0029.readPress": "핀 [PIN] 버튼이 눌러졌습니까？",
    "gui.blocklyText.dfr0030.readTouch": "핀 [PIN] 터치를 감지했습니까？",
    "gui.blocklyText.dfr0033.readMagnetic": "핀[PIN] 자기장을 감지했습니까？",
    "gui.blocklyText.dfr0034.readSound": "핀 [PIN] 사운드 강도 읽기",
    "gui.blocklyText.dfr0094.readIRReceived": "핀 [PIN] 적외선 값 읽기",
    "gui.blocklyText.dfr0094.onReceiveEvent": "When the infrared signal is received Pin [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "address",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "command",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "핀 [PIN] 일산화탄소 센서 읽기",
    "gui.blocklyText.dfr0051.readVoltage": "핀 [PIN] 전압 값(V) 읽기",
    "gui.blocklyText.dfr0052.readPiezoSensor": "핀 [PIN] 압전세라믹 진동센서 읽기",
    "gui.blocklyText.dfr0058.readRotation": "핀 [PIN] 로테이션 센서 읽기",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "핀 [PIN] 로커 [AXIS] 축 읽기",
    "gui.blocklyText.dfr0061.readJoyStickButton": "핀 [PIN] 조이스틱 버튼(Z)이 눌러졌습니까?？",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut init data pin [DATAPIN] RTC pin [RTCPIN] cls pin [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Digital tube display segment code 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Digital tube display number [NUM]",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Read lithium battery [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "voltage(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "remainingBattery(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD module init I2C address [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD module read [PIN] voltage (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD module get aisle [AISLE] voltage difference",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 and 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 and 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 and 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 and 3",
    "gui.blocklyText.dfr0076.readFlameSensor": "핀[PIN] 화염 센서 읽기",
    "gui.blocklyText.dfr0143.readAccelerometer": "핀 [PIN] 3축 가속도계 [AXIS] 축 읽기",
    "gui.blocklyText.sen0018.readInfraredMotion": "핀 [PIN] 인체 적외선 모션 센서 핀 읽기",
    "gui.blocklyText.sen0014.readDistance": "핀[PIN] Sharp 적외선 거리 측정 센서(mm) 읽기",
    "gui.blocklyText.sen0114.readMoisture": "핀[PIN] 토양수분센서 읽기",
    "gui.blocklyText.sen0121.readSteam": "핀 [PIN] 수분센서 읽기",
    "gui.blocklyText.sen0204.readLiquidLevel": "핀[PIN] 수위에 도달했습니까？",
    "gui.blocklyText.sen0203.heartRate": "심장 박동수(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "혈중 산소 포화도(HbO2)",
    "gui.blocklyText.sen0202.up": "위로",
    "gui.blocklyText.sen0202.down": "아래로",
    "gui.blocklyText.sen0202.left": "왼쪽으로",
    "gui.blocklyText.sen0202.right": "오른쪽으로 ",
    "gui.blocklyText.sen0202.cw": "시계 방향",
    "gui.blocklyText.sen0202.ccw": "시계 반대방향",
    "gui.blocklyText.dhtTHSensor.temperature": "온도(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "습도(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "RGB조명 핀[PIN] 조명번호 [NUM1] 부터 [NUM2]까지 색깔 [COLOR] 표시하기",
    "gui.blocklyText.ws2812.rgbColor": "빨강 [RED] 초록 [GREEN] 파랑 [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "RGB조명 핀 [PIN] 조명번호 [NUM1] 부터 [NUM2] 까지 그라데이션 색깔 [COLOR1]부터[COLOR2]까지 표시하기",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "RGB조명 핀 [PIN] 이동 [NUM]단위",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "RGB조명 핀 [PIN] 반복이동[NUM] 단위",
    "gui.blocklyText.ws2812.numberOfLightsBright": "RGB조명 핀 [PIN] 조명 총 수 [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "RGB조명 핀[PIN] 조명 총 수 밝기 [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "RGB조명 핀[PIN] 전부 제거하기",
    "gui.blocklyText.ws2812.showHistogram": "RGB조명 핀[PIN] 조명번호 [NUM1] 부터 [NUM2] 까지 히스토그램 표시하기현재값[VALUE] 최대값 [MAX] 표시하기",
    "gui.blocklyText.ws2812.setBrightness": "RGB조명 핀 [PIN] LED 스트립 밝기를 [BRIGHTNESS]로 설정하기",
    "gui.blocklyText.neopixel.init": "Lamp initialization name [NAME] pin [PIN] number [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Light strip [NAME] [INDEX] Color [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Light strip [NAME] [INDEX] Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.fillColor": "The light strip [NAME] is fully lit and the color is [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Light strip [NAME] All on Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.flush": "Light strip [NAME] settings take effect",
    "gui.blocklyText.neopixel.off": "Light strip [NAME] is off",
    "gui.blocklyText.neopixel.setBrightness": "Light strip [NAME] sets brightness to [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Rainbow light effect [NAME] Quantity [COUNT] Brightness [BRIGHTNESS] Offset [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7(mPython board)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9(mPython box)",
    "gui.blocklyText.dsTSensor.ds18b20": "[PIN] 핀 DS18B20 온도 (°C)를 읽습니다",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "BMP280센서[TYPE] 값 읽기",
    "gui.blocklyText.bmpTSensor.atmosPressure": "기압(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "해발(m)",
    "gui.blocklyText.bmpTSensor.temperature": "온도(℃)",
    "gui.blocklyText.LCD1602.initialIICAddr": "I2C LCD 디스플레이 주소를 [ADDR]로 초기화하기",
    "gui.blocklyText.LCD1602.displayInLine": "I2C LCD 디스플레이  [LINE] 번째 행에 [TEXT] 표시하기",
    "gui.blocklyText.LCD1602.displayInXY": "I2C LCD 디스플레이 좌표 X: [X] Y: [Y] 에  [TEXT] 표시하기",
    "gui.blocklyText.LCD1602.displayClear": "I2C LCD 디스플레이 비우기",
    "gui.blocklyText.IICScan.readICCAddr": "스캔한 I2C장치 주소 읽기",
    "gui.blocklyText.softSerialport.initialSerialport": "시리얼 포트[SERIALPORT] RX: [PINR] TX: [PINT]로 초기화하기",
    "gui.blocklyText.softSerialport.setBaud": "소프트웨어 시리얼 포트 [SERIALPORT] 전송률을 [BAUD]로 설정",
    "gui.blocklyText.softSerialport.printStr": "시리얼 포트 [SERIALPORT] [WAY] [TEXT] [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "시리얼 포트[SERIALPORT] 에 읽을 데이터가 있습니까？",
    "gui.blocklyText.softSerialport.readSerialportData": "소프트웨어 시리얼 포트[SERIALPORT] 데이터 읽기",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "소프트웨어 시리얼 포트 [SERIALPORT]에서  [TYPE] 읽기",
    "gui.blocklyText.bluetooth.connectSuccess": "블루투스가 성공적으로 연결되었을 때 운행하기",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "블루투스 연결이 끊어졌을 때 운행하기",
    "gui.blocklyText.bluetooth.receivedKey": "블루투스가 데이터를 수신했을 때 운행하기. key [KEY] value [TYPE]",
    "gui.blocklyText.bluetooth.string": "문자열 ",
    "gui.blocklyText.bluetooth.number": "숫자",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "블루투스가 데이터를 수신하고 [SYMBOL]을 만났을 때 운행하기",
    "gui.blocklyText.bluetooth.sendStr": "블루투스가 문자열 key [KEY] value [VALUE]을 발송합니다.",
    "gui.blocklyText.bluetooth.sendNumber": "블루투스가 숫자 key [KEY] value [VALUE]를 발송합니다.",
    "gui.blocklyText.bluetooth.openService": "블루투스 서비스 시작하기",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "블루투스가 데이터를 수신합니다.",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo 메인 프로그램 시작",
    "gui.blocklyText.leonardo.setBaud": "[SERIAL] 전송률을 [BAUD]로 설정하기",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "[SERIAL]로부터 [READTYPE] 읽기",
    "gui.blocklyText.leonardo.serialPort1": "시리얼 포트 0",
    "gui.blocklyText.leonardo.serialPort2": "시리얼 포트 1",
    "gui.blocklyText.leonardo.dataReadable": "[SERIAL] 에 읽을 데이터가 있습니까?",
    "gui.blocklyText.leonardo.readSerialData": "[SERIAL] 데이터 읽기",
    "gui.blocklyText.arduinounoR3.functionStarts": "uno키트 프로그램 시작",
    "gui.blocklyText.arduinounoR3.readdigital": " 핀[PIN] 모듈[MODULE] 값 읽기",
    "gui.blocklyText.arduinounoR3.digitalSensor": "숫자센서",
    "gui.blocklyText.arduinounoR3.buttonModule": "버튼 모듈",
    "gui.blocklyText.arduinounoR3.stickButton": "로커 버튼",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "진동 센서",
    "gui.blocklyText.arduinounoR3.readAnalog": "핀[PIN] [SENSOR]  값 읽기",
    "gui.blocklyText.arduinounoR3.anologSensor": "아날로그 센서",
    "gui.blocklyText.arduinounoR3.lightSensor": "라이트 센서",
    "gui.blocklyText.arduinounoR3.soundSensor": "사운드 센서",
    "gui.blocklyText.arduinounoR3.stickDirection": "로커 방향",
    "gui.blocklyText.arduinounoR3.angleSensor": "각도 센서",
    "gui.blocklyText.arduinounoR3.graySensor": "회색톤 센서",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "토양수분센서",
    "gui.blocklyText.arduinounoR3.open": "열기",
    "gui.blocklyText.arduinounoR3.close": "닫기",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] [PIN] 핀의 [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "디지털 액츄에이터",
    "gui.blocklyText.arduinounoR3.ledLights": "LED 조명",
    "gui.blocklyText.arduinounoR3.fanModule": "팬 모듈",
    "gui.blocklyText.arduinounoR3.recordModule": "녹음 모듈",
    "gui.blocklyText.arduinounoR3.relay": "릴레이",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "초음파 거리 (cm) 삼각 판독 [TRIG] 에코 [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "아날로그 액츄에이터",
    "gui.blocklyText.arduinounoR3.writeAnalog": "[PIN]핀 [MODULE]모듈 값을 [VALUE]로 설정하기",
    "gui.blocklyText.arduinounoR3.forward": "전진하기",
    "gui.blocklyText.arduinounoR3.backward": "후진하기",
    "gui.blocklyText.arduinounoR3.turnLeft": "좌회전하기",
    "gui.blocklyText.arduinounoR3.turnRight": "우회전하기",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "로봇을 [SPEED] 속도로 [DIR] ",
    "gui.blocklyText.arduinounoR3.motorForward": "앞으로",
    "gui.blocklyText.arduinounoR3.motorReverse": "반대로",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "모터[MOTOR]를 [SPEED] 속도로 [DIR] 설정하기",
    "gui.blocklyText.arduinounoR3.stopMotor": "로봇(MA와MB모터)정지",
    "gui.blocklyText.arduinounoR3.setServo": "[PIN]핀 서보모터를 [DEGREE] 도로 설정하기",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "적외선 방출 [TYPE] 핀 [PIN] 값 [VALUE] 비트 [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "적외선 핀 [PIN] 이 버튼[BUTTON] 이 눌러진 데이터를 수신했습니까？",
    "gui.blocklyText.arduinounoR3.noSound": "무음",
    "gui.blocklyText.arduinounoR3.half": "2분의 1",
    "gui.blocklyText.arduinounoR3.quarter": "4분의 1",
    "gui.blocklyText.arduinounoR3.oneInEighth": "8분의 1",
    "gui.blocklyText.arduinounoR3.wholeBeat": "전체 비트",
    "gui.blocklyText.arduinounoR3.doubleBeat": "더블 비트",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "핀[PIN]부저 톤을 [TONE]로, 리듬을 [BEAT]로 설정하기",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "핀 [PIN] MP3 모듈 볼륨을 [VOLUME]로 설정하기",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "[PIN]핀 MP3 모듈 재생 모드를 [MODE]로 설정하기",
    "gui.blocklyText.arduinounoR3.stop": "정지",
    "gui.blocklyText.arduinounoR3.play": "재생",
    "gui.blocklyText.arduinounoR3.loop": "반복재생",
    "gui.blocklyText.arduinounoR3.random": "임의로 재생",
    "gui.blocklyText.arduinounoR3.pause": "정지",
    "gui.blocklyText.arduinounoR3.previous": "이전 곡",
    "gui.blocklyText.arduinounoR3.next": "다음 곡",
    "gui.blocklyText.arduinounoR3.VolumePlus": "볼륨+",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "볼륨-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "[PIN]핀 MP3모듈 [NUM]번째 곡 재생 설정하기",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "판독 핀 [PIN] LM35 온도 (°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "[PIN]핀 DHT11 [TYPE] 읽기",
    "gui.blocklyText.arduinounoR3.red": "빨강색 값",
    "gui.blocklyText.arduinounoR3.blue": "파란색 값",
    "gui.blocklyText.arduinounoR3.green": "초록색 값",
    "gui.blocklyText.arduinounoR3.readColorSensor": "TCS34725 칼라 센서 [COLORTYPE] 읽기",
    "gui.blocklyText.arduinounoR3.fourTubeMode": " 4자리 닉시튜브_TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "열기",
    "gui.blocklyText.arduinounoR3.tm1650Close": "닫기",
    "gui.blocklyText.arduinounoR3.clearScreen": "디스플레이 비우기",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": " 4자리 닉시튜브_TM1650 문자열 [TEXT] 표시하기",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": " 4자리 닉시튜브_TM1650 [ORDER] 번째 소숫점 [STATUS]",
    "gui.blocklyText.arduinounoR3.bright": "밝게",
    "gui.blocklyText.arduinounoR3.dark": "어둡게",
    "gui.blocklyText.arduinounoR3.setLightColor": "RGB조명 핀[PIN] 조명번호 [NUM] [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "RGB조명 핀 [PIN] 조명번호 [NUM] R값:[R] G값:[G] B값:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "RGB조명 핀 [PIN] 조명수 [NUM]",
    "gui.blocklyText.arduinounoR3.ledControl": "LED 도트 매트릭스(max7219)  DIN핀을 [DIN]로 CS핀을 [CS]로 CLK핀을 [CLK]로 초기화하기",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "LED 도트 매트릭스(max7219)그림[PIC] 표시하기",
    "videoSensing.categoryName": "비디오 감지",
    "videoSensing.direction": "방향",
    "videoSensing.motion": "동작",
    "videoSensing.off": "끄기",
    "videoSensing.on": "작동하기",
    "videoSensing.onFlipped": "거울 작동시키기",
    "videoSensing.setVideoTransparency": "비디오 투명도를 [TRANSPARENCY] (으)로 정하기",
    "videoSensing.sprite": "스프라이트",
    "videoSensing.stage": "무대 ",
    "videoSensing.videoOn": "[SUBJECT]에 관한 동영상[ATTRIBUTE]",
    "videoSensing.videoToggle": " [VIDEO_STATE] 카메라",
    "videoSensing.whenMotionGreaterThan": "비디오 모션 > [REFERENCE]일 때 ",
    "gui.blocklyText.max.maxStart": "MAX메인 프로그램 시작",
    "gui.blocklyText.max.onboardButtonPressed": "온보드 버튼 [PRESSED]",
    "gui.blocklyText.max.pressed": "이미 눌러졌습니다.",
    "gui.blocklyText.max.notPressed": "안 눌러졌습니다.",
    "gui.blocklyText.max.all": "(-1)  전부",
    "gui.blocklyText.max.A": "0 오른쪽 뒤",
    "gui.blocklyText.max.B": "1  오른쪽 앞",
    "gui.blocklyText.max.C": "2  왼쪽 앞",
    "gui.blocklyText.max.D": "3  왼쪽 뒤",
    "gui.blocklyText.max.rgbLightsColor": "RGB조명 [TYPE] 색깔을  [COLOR]로 설정하기",
    "gui.blocklyText.max.readSoundSensor": "사운드 센서 값 읽기",
    "gui.blocklyText.max.playSoundEffect": "음악효과 [SOUND] 재생",
    "gui.blocklyText.max.setDPinValue": "디지털 핀 [PIN] 의 값을 [LEVEL]로 설정하기",
    "gui.blocklyText.max.readDPinValue": "디지털 핀 [PIN] 값 읽기",
    "gui.blocklyText.max.readAPinValue": "아날로그 핀[PIN] 값 읽기",
    "gui.blocklyText.max.setServo": "핀[PIN]서보 [DEGREE]도로 설정하기",
    "gui.blocklyText.max.readLinefindingSensor": "라인트래킹 센서 [DIR] 값 읽기",
    "gui.blocklyText.max.left": "왼쪽",
    "gui.blocklyText.max.middle": "가운데",
    "gui.blocklyText.max.right": "오른쪽",
    "gui.blocklyText.max.setLineFidingCarLights": "라인트래킹 차 조명[SWITCH] 설정",
    "gui.blocklyText.max.on": "밝게",
    "gui.blocklyText.max.off": "희미하게",
    "gui.blocklyText.max.readUltrasoundSensor": "초음파센서 거리 (cm) 읽기",
    "gui.blocklyText.max.readLightSensor": "빛 센서 값 [LEFTRIGHT] 읽기",
    "gui.blocklyText.max.maxSpeedMove": "MAX [SPEED]의 속도로 [DIR]",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] [SPEED]의 속도로 [DIR]",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] 정지",
    "gui.blocklyText.max.showUserDefineExpressions": "사용자 정의 이모티콘 [EXPRESSION] 색깔 [COLOR] 표시",
    "gui.blocklyText.max.clear": "비우기",
    "gui.blocklyText.max.do": "도",
    "gui.blocklyText.max.re": "레",
    "gui.blocklyText.max.mi": "미",
    "gui.blocklyText.max.fa": "파",
    "gui.blocklyText.max.sou": "솔",
    "gui.blocklyText.max.la": "라",
    "gui.blocklyText.max.xi": "시",
    "gui.blocklyText.max.highdo": "도",
    "gui.blocklyText.max.brake": "브레이크",
    "gui.blocklyText.max.rebound": "발탄 사운드",
    "gui.blocklyText.max.bullets": "2번 발탄 사운드",
    "gui.blocklyText.max.longecho": "긴 메아리",
    "gui.blocklyText.max.playfulending": "재밌는 엔딩 사운드",
    "gui.blocklyText.max.yo": "아싸",
    "gui.blocklyText.max.wo": "와우",
    "gui.blocklyText.max.robottalking": "로봇이 말을 하네요!",
    "gui.blocklyText.knowflow.readORP": "아날로그 핀 [PIN] 산화환원(ORP)센서 (mV) 읽기",
    "gui.blocklyText.knowflow.readEC": "아날로그 핀[PIN] 전도율(EC)센서(ms/cm) (V1) 읽기",
    "gui.blocklyText.knowflow.readPH": "아날로그 핀[PIN] PH센서(V1) 읽기",
    "gui.blocklyText.knowflow.readECV2": "아날로그 핀 [PIN] 전도율(EC)센서(ms/cm) (V2) 읽기",
    "gui.blocklyText.knowflow.readPHV2": "아날로그 핀[PIN] PH센서(V2)",
    "gui.blocklyText.knowflow.readDO": "아날로그 핀 [PIN] 용존산소DO 센서(mg/L) 읽기",
    "gui.blocklyText.peristalticPump.speedMove": "핀[PIN] 연동 펌프 속도[SPEED]로 [DIR] 설정하기",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] 핀 [PIN] 릴레이",
    "translate.translateBlock": "[WORDS]을(를) [LANGUAGE]로 번역하기",
    "translate.defaultTextToTranslate": "안녕 ",
    "translate.viewerLanguage": "게스트 언어",
    "gui.blocklyText.goble.readAxis": "GOBLE 조이스틱 [AXIS]축 읽기",
    "gui.blocklyText.goble.buttonIsPressed": "GOBLE [BUTTON] 버튼이 눌러졌습니까？",
    "gui.blocklyText.tds.readAnalogPin": "아날로그 핀 [PIN] TDS 센서(ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "스테퍼 모터 조인트 조절 X축 : [XSET] Y축: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "스테퍼 모터 [AXIS] 축 1 회전당 스텝수: [STEPS]회전속도(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "스테퍼모터를 [STEPS]보,  [ROTATEDIR]방향으로 이동하기",
    "gui.blocklyText.steppermotor.setJointSteps": "스테퍼 모터를 동시에 움직이기.X축으로 [XSTEPS] 보, [ROTATEDIRX]방향으로 이동; Y축으로 [YSTEPS] 보, [ROTATEDIRY] 방향으로 이동 ",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] 프로그램 시작",
    "gui.blocklyText.multithread.stopThread": "정지 [THREAD]",
    "gui.blocklyText.multithread.startThread": "시작 [THREAD]",
    "gui.blocklyText.multithread.loop1": "thread 1",
    "gui.blocklyText.multithread.loop2": "thread 2",
    "gui.blocklyText.multithread.loop3": "thread 3",
    "gui.blocklyText.multithread.loop4": "thread 4",
    "gui.blocklyText.multithread.loop5": "thread 5",
    "gui.blocklyText.multithread.loop6": "thread 6",
    "gui.blocklyText.multithread.loop7": "thread 7",
    "gui.blocklyText.multithread.loop8": "thread 8",
    "gui.blocklyText.multithread.all": "모든 하위 스레드",
    "text2speech.speakAndWaitBlock": "[WORDS] 읽기",
    "text2speech.setVoiceBlock": "[VOICE]목소리로 정하기",
    "text2speech.setLanguageBlock": "언어를 [LANGUAGE]로 설정하기",
    "text2speech.alto": "알토",
    "text2speech.tenor": "테너",
    "text2speech.squeak": "날카로운",
    "text2speech.giant": "거인",
    "text2speech.kitten": "고양이",
    "text2speech.defaultTextToSpeak": "안녕 ",
    "gui.blocklyText.DFR0151.adjustTime": "DS1307시간을 [YEAR]년[MONTH]월[DATE]일[HOUR]시[MINUTE]분[SECOND]초로 조정하기",
    "gui.blocklyText.DFR0151.getTime": "DS1307 시간[INDEX] 얻기",
    "gui.blocklyText.DFR0151.Year": "년",
    "gui.blocklyText.DFR0151.month": "월",
    "gui.blocklyText.DFR0151.date": "일",
    "gui.blocklyText.DFR0151.hour": "시",
    "gui.blocklyText.DFR0151.minute": "분",
    "gui.blocklyText.DFR0151.second": "초",
    "gui.blocklyText.DFR0151.week": "주",
    "gui.blocklyText.DFR0469.adjustTime": "SD2405시간을 [YEAR]년[MONTH]월[DATE]일 [HOUR]시[MINUTE]분[SECOND]초로 설정하기",
    "gui.blocklyText.DFR0469.getTime": "SD2405시간[INDEX] 얻기",
    "gui.blocklyText.DFR0126.setPin": "오디오 분석 모듈 핀 #S[SPIN] #R[RPIN] 아날로그 핀[APIN] 설정하기",
    "gui.blocklyText.DFR0126.readSoundFreq": "오디오 분석 모듈로 사운드 주파수 읽기분단 [FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Initialize the NFC module interface I2C",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Initialize the NFC module interface hardware serial port Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "시리얼 포트 모듈 초기화선택[SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "시리얼 포트 모듈 초기화  선택[SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "시리얼 포트 NFC 모듈 데이터 블록 [DATABLOCK] [BYTES]바이트 입력 [VALUE] ",
    "gui.blocklyText.DFR0126.readSerialNfc": "시리얼 포트 NFC모듈 데이터 블록[DATABLOCK][BYTES]번쨰 바이트(number) 읽기",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "시리얼 포트NFC 모듈 데이터 블록[DATABLOCK]모든데이터(string) 읽기",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "시리얼 포트NFC모듈 UID(srting) 읽기",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "카드가 발견되었습니다. UID:[TEXT]",
    "gui.blocklyText.DFR0126.scanSerialNfc": "카드가 발견되었습니다. ",
    "gui.blocklyText.DFR0126.serial1": "시리얼 포트 1",
    "gui.blocklyText.DFR0126.serial2": "시리얼 포트 2",
    "gui.blocklyText.DFR0126.serial3": "시리얼 포트 3",
    "gui.blocklyText.sen0245.setVL53L0X": "VL53L0X레이저 거리측정 모듈[PRECISION]측정 횟수[VALUE]로 설정하기",
    "gui.blocklyText.sen0245.readVL53L0X": "VL53L0X레이저 측정 거리(mm) 얻기",
    "gui.blocklyText.SEN0245.LOW": "저정밀도(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "고정밀도(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "단일측정",
    "gui.blocklyText.SEN0245.Continuous": "연속측정",
    "gui.blocklyText.serialMp3.initSerialMp3": "시리얼 포트MP3모듈 [SERIALPORT] #RX[RX] #TX[TX] 초기화 하기",
    "gui.blocklyText.serialMp3.controlSerialMp3": "시리얼 포트MP3模块 [CONTROL] 제어하기",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "시리얼 포트MP3모듈 재생트랙[VALUE]",
    "gui.blocklyText.serialMp3.setMP3Volume": "시리얼 포트MP3모듈 볼륨을 [VOLUME]로 설정하기",
    "gui.blocklyText.serialMp3.play": "재생하기",
    "gui.blocklyText.serialMp3.pause": "재생 중지",
    "gui.blocklyText.serialMp3.end": "재생 끝내기",
    "gui.blocklyText.serialMp3.prev": "이전 곡",
    "gui.blocklyText.serialMp3.next": "다음 곡",
    "gui.blocklyText.serialMp3.volumeUp": "볼륨+",
    "gui.blocklyText.serialMp3.volumeDown": "볼륨-",
    "gui.blocklyText.serialMp3.playlist": "재생리스트",
    "gui.blocklyText.serialMp3.insertTracks": "인서트 트랙",
    "gui.blocklyText.serialMp3.volume": "볼륨",
    "gui.blocklyText.servo360.setServo360": "핀 [PIN] 속도 [SPEED] 로 [DIR] 설정하기.",
    "gui.blocklyText.servo360.CW": "시계 방향으로 회전",
    "gui.blocklyText.servo360.CCW": "시계 반대 방향으로 회전",
    "gui.blocklyText.TCS34725.readTcs34725": "TCS34725칼라 센서[COLOR] [FORMAT] 읽기",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "GAMMA로 바꾸기",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "GAMMA로 바꾸기 않기",
    "gui.blocklyText.regEmotion8_16.showText": "문자[TEXT] [COLOR]표시하기",
    "gui.blocklyText.regEmotion8_16.scroll": "문자 [DIR] 스크롤 설정",
    "gui.blocklyText.regEmotion8_16.left": "왼쪽으로",
    "gui.blocklyText.regEmotion8_16.right": "오른쪽으로",
    "gui.blocklyText.regEmotion8_16.lightAll": "모든 도트매트릭스[COLOR] 밝히기",
    "gui.blocklyText.regEmotion8_16.controlLight": "좌표x:[XAXIS], y:[YXAXIS]를 [COLOR]로 나타내기",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "도트 매트릭스 비우기",
    "gui.codeLabel.dynamicVariables": "동적 변수",
    "gui.codeLabel.functionDeclaration": "함수 선언",
    "gui.codeLabel.staticConstants": "정적 상수",
    "gui.codeLabel.createObject": "객체 생성",
    "gui.codeLabel.mainProgramStart": "메인 프로그램 시작",
    "gui.codeLabel.SubthreadProgramStarts": "자식 스레드 %1 프로그램 시작",
    "gui.codeLabel.customFunction": "커스텀 기능",
    "gui.codeLabel.eventCallbackFunction": "이벤트 콜백 기능",
    "gui.codeLabel.staticFunctions": "정적 기능",
    "gui.mainHeader.err": "!오류：프로그램 머리말 [%1] 동시에 하나만 사용할 수 있습니다. 중복된 모듈을 삭제하십시오.",
    "gui.mainHeader.help": "!도움말：여러 프로그램을 동시에 실행해야하는 경우 \"Extension\" 의 \"multi-threading function\" 을 사용하십시오. 자세한 내용은 도움말 설명서를 참조하십시오.",
    "gui.threadHeader.err1": "!오류：프로그램 머리말 [%1] 동시에 사용할 수 없습니다. 각기 다른 키트 혹은 메인보드에 속합니다.",
    "gui.threadHeader.err2": "!오류：이벤트 머리말 [%1] 동시에 하나만 사용할 수 있습니다. 중복된 모듈을 삭제하십시오.",
    "gui.blocklyText.mqtt.whenReceivedData": "[TOPIC]이[MSG]를 수신할 때",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT데이터",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi가 핫스팟 계정[ACCOUNT] 비밀번호[PASSWORD] 에 연결되었습니다.",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi 연결에 성공하였습니까？",
    "gui.blocklyText.wifi.wifiDisConnected": "Wi-Fi 연결이 끊어졌습니다.",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP mode:[ACCOUNT2] password:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi get soft ap ip",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi get [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Error code",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "when udp server recv [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "set udp server port [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "udp server send broadcast [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "when udp client recv [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "set udp client IP [IPADDR] port [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "udp client send broadcast [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "message",
    "gui.blocklyText.udp.ClientRecvedMsg": "message",
    "gui.blocklyText.mqtt.mqttConnect": "MQTT가 연결을 시작합니다.",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT 연결에 성공하였습니까？",
    "gui.blocklyText.mqtt.acceptance.meaage": "[TOPIC]이[MSG]를 수신할 때",
    "gui.blocklyText.mqtt.mqttSend": "MQTT가 메시지[MSG]를[TOPIC]로 보냅니다.",
    "gui.blocklyText.mqtt.initial": "MQTT 초기화 변수 [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「IFLYTEK」synthetic audio [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「IFLYTEK」identify audio [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「IFLYTEK」identify result",
    "gui.blocklyText.iflytek.CommunicationText": "text",
    "gui.blocklyText.iflytek.CommunicationTransfer": "transfer to audio file",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "audio file to be identified",
    "gui.blocklyText.mqtt.mqttDisconnect": "MQTT 연결이 끊어졌습니다.",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 해발고도(m) 읽기",
    "gui.blocklyText.sen0251.readPressure": "BMP388 대기압 세기(Pa) 읽기",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 온도(℃) 읽기",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 measurement [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "object",
    "gui.blocklyText.sen0206.environment": "environment",
    "gui.blocklyText.sen0206.celsius": "celsius",
    "gui.blocklyText.sen0206.fahrenheit": "fahrenheit",
    "gui.blocklyText.sen0253.init": " 10DOF 'I2C_ADDR'핀 레벨을 [LEVEL]로 초기화",
    "gui.blocklyText.sen0253.reset": " 10DOF 센서 리셋",
    "gui.blocklyText.sen0253.power": " 10DOF 전원모드 [MODEL] 설정",
    "gui.blocklyText.sen0253.getEuler": " 10DOF 오일러 [EULER] 각 얻기",
    "gui.blocklyText.sen0253.getQua": " 10DOF 4원소 [AXIS] 축 얻기",
    "gui.blocklyText.sen0253.getAcc": " 10DOF 가속도 [AXIS] 축 얻기(mg)",
    "gui.blocklyText.sen0253.getMag": " 10DOF 자력계 [AXIS] 축 얻기(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": " 10DOF 선형 가속도 [AXIS] 축 읽기(mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": " 10DOF 중력 벡터[AXIS] 축 얻기(mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": " 10DOF 각속도 [AXIS] 축 얻기",
    "gui.blocklyText.sen0253.getBmp280": " 10DOF [PARAMETER] 얻기",
    "gui.blocklyText.sen0253.LOW": "저레벨",
    "gui.blocklyText.sen0253.HIGH": "고레벨",
    "gui.blocklyText.sen0253.normal": "정상",
    "gui.blocklyText.sen0253.lowPower": "저전력 소모",
    "gui.blocklyText.sen0253.suspend": "보류",
    "gui.blocklyText.sen0253.Roll": "롤각(°)",
    "gui.blocklyText.sen0253.Pitch": "피치 각(°)",
    "gui.blocklyText.sen0253.Yaw": "네비게이션 각(°)",
    "gui.blocklyText.sen0253.temperature": "온도(℃)",
    "gui.blocklyText.sen0253.elevation": "해발(m)",
    "gui.blocklyText.sen0253.airPressure": "기압(Pa)",
    "gui.blocklyText.sen0290.init": "SEN0290 set irq pin [IRQPIN] and i2c address [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "SEN0290 lightning sensor iic init",
    "gui.blocklyText.sen0290.manualCal": "SEN0290 set capacitance [CAPACITANCE] mode [MODE] dist [DIST]",
    "gui.blocklyText.sen0290.indoors": "indoors",
    "gui.blocklyText.sen0290.outdoors": "outdoors",
    "gui.blocklyText.sen0290.disturberDis": "disturber disable",
    "gui.blocklyText.sen0290.disturberEn": "disturber enable",
    "gui.blocklyText.sen0290.sen0290WhenRecvErrMsg": "SEN0290 When receive [ErrMsg]",
    "gui.blocklyText.sen0290.errMsg": "error msg",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "SEN0290 get interrupt src",
    "gui.blocklyText.sen0290.GetLightningDistKm": "SEN0290get lightning dist(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "SEN0290 get strike energy raw",
    "gui.blocklyText.weather.weatherGetWithCity": "도[COUNTRY] 시 [CITY]의 날씨[WEATHER]",
    "gui.blocklyText.weather.temperaturHigh": "최고온도(℃)",
    "gui.blocklyText.weather.temperaturLow": "최저온도(℃)",
    "gui.blocklyText.weather.weather": "날씨",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "서버 변수 [TINYWEBDBINIT] 설정",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "태그(tag) [TAG] 값(value) [VALUE] 재설정(update)",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "태그(tag) [TAG] 읽기",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "태그(tag) [TAG] 삭제(delete)",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "계수(count)",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "네트워크 연결 테스트",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "시작번호(no) [NUMS] 변수 갯수(count) [NUMV] 태그(tag) [TAG] 타입(type) [TAGTYPE] 검색",
    "gui.blocklyText.tinywebdb.tag": "태그",
    "gui.blocklyText.tinywebdb.value": "값",
    "gui.blocklyText.tinywebdb.both": "둘 다",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "HongKong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.whenIHear": "[PHRASE] 이(가) 들릴 때",
    "speech.listenAndWait": "듣고 기다리기",
    "speech.getSpeech": "식별 결과",
    "speech.setRecordTime": "매 번  [TIME] 초 듣기 설정",
    "speech.stopRecord": "음성식별 결과 듣기",
    "speech.controlSonogram": "[CONTROL] 음파",
    "speech.setServer": "음성식별 서버를 [SERVER]로 바꾸기",
    "speech.defaultWhenIHearValue": "시작",
    "speech.show": "보이기",
    "speech.hide": "숨기기",
    "speech.server1": "서버1",
    "speech.server2": "서버2",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 can not be placed in the interrupt event, may cause dht11 reading abnormal",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 can not be placed in the interrupt event, may cause dht22 reading abnormal",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 cannot be placed in an interrupt event, which may result in abnormal pm2.5 readings",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 reads data, which may cause led dot matrix initialization failure",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 reads data, which may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "the mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to be set to fail",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "the mp3 setting playback mode cannot be placed in an interrupt event, which may cause the playback mode to fail",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "Mp3 settings playback can not be placed in the interrupt event, may cause the settings to play failed",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "Serial port mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "The serial port mp3 playback mode cannot be placed in an interrupt event, which may cause the setup play mode to be lost",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "Serial port mp3 playback cannot be placed in an interrupt event, which may cause playback to fail",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "The serial port mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to fail",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloq's mqtt initialization soft serial port cannot be placed in an interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq sends a message that cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq read mqtt message can not be placed in the interrupt event, may cause the reception to fail",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "The initial soft serial port of http of obloq cannot be placed in the interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "The get request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "Obloq http post request cannot be placed in an interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "The put request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "Infrared reception cannot be placed in an interrupt event, which may cause reception failure",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "Infrared reception may cause the LED dot matrix initialization to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "Infrared reception may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "Infrared transmission cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause initialization to fail",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause setup pins to fail",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the pin to fail to fail",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause display color to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause color adjustment failure",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the move to fail",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause loop movement to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause the display histogram to fail",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "Led dot matrix can not be placed in the interrupt event, may cause initialization failure",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "The led dot matrix cannot be placed in an interrupt event, which may cause the display pattern to fail",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "The soft serial output cannot be placed in an interrupt event, which may cause the output to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "The data read by the soft serial port cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Soft serial port output data and read data colleague use, may cause data abnormalities",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "The soft serial port read integer cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "Soft serial port output data and reading integer colleague use may cause abnormal reading data",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "When dht11 and PM2.5 are in two threads, dht11 reads may fail if pm2.5 reads too frequently. It is recommended to reduce the pm2.5 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "When dht11 and ds18b20 are in two threads respectively, if ds18b20 reads too frequently, dht11 may fail to read, it is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "When dht11 and mp3 are initialized in two threads, mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "When dht11 and mp3 set volume are in two threads respectively, mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "When dht11 and mp3 setup modes are in two threads respectively, mp3 setup mode may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "When dht11 and mp3 player are in two threads, mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "When dht11 and serial mp3 are initialized in two threads, serial mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "When dht11 and serial mp3 control module are in two threads respectively, the mp3 control module may cause dht11 reading errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "When dht11 and serial mp3 player are in two threads, serial mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "When dht11 and serial mp3 set volume are in two threads respectively, serial mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "When dht11 and RGB are initialized in two threads, RGB initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "When dht11 and RGB set brightness in two threads, RGB set brightness may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "When dht11 and RGB are extinguished in two threads, RGB extinction may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "When dht11 and RGB display colors in two threads, RGB display colors may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "When dht11 and RGB display a gradient in two threads, respectively, RGB display a gradient may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "When dht11 and RGB move pixels in two threads, RGB move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "When dht11 and RGB loop move pixels in two threads, respectively, RGB loop move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "When dht11 and RGB display columns in two threads respectively, RGB display bars may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "When dht11 and soft serial send are in two threads respectively, soft serial send may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "When dht11 and obloq MQTT are initialized in two threads respectively, obloq MQTT initialization may cause dht11 to read errors. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "When dht11 and obloq MQTT send messages in two threads respectively, obloq MQTT send messages that may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "When dht11 and obloq HTTP put requests are in two threads, it can cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "When dht11 and obloq HTTP initialization are in two threads respectively, it may cause dht11 reading error. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "When dht11 and obloq HTTP get requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "When dht11 and obloq HTTP post requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "When dht11 and infrared emission are in two threads respectively, it may cause errors in reading dht11. It is suggested to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "When ds18b20 and PM2.5 are in two threads respectively, it may cause PM2.5 reading error, so it is suggested to reduce ds18b20 frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "When ds18b20 and obloq MQTT are initialized in two threads respectively, MQTT initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "When ds18b20 and obloq HQTT put requests are in two threads respectively, the put request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "When ds18b20 and obloq HTTP initialization are in two threads respectively, the HTTP initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "When ds18b20 and obloq HQTT get requests are in two threads respectively, the get request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "When ds18b20 and obloq HQTT post requests are in two threads respectively, the post request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Reading ds18b20 may result in no infrared data, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Reading ds18b20 may cause errors in receiving data by soft serial port, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "When PM2.5 read and obloq MQTT initialization are carried out in two threads respectively, MQTT initialization failure or PM2.5 read error may be caused. It is recommended to avoid simultaneous execution of MQTT initialization and PM2.5 read",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "When PM2.5 reading and obloq MQTT send messages in two threads respectively, PM2.5 reading errors may be caused. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "When PM2.5 read and obloq HTTP put requests are in two threads, it may cause put request failure or PM2.5 read error. It is recommended to reduce put request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "When PM2.5 reads and obloq HTTP initializations are carried out in two threads respectively, HTTP initializations may fail or PM2.5 reads may go wrong. It is recommended to avoid simultaneous execution of HTTP initializations and PM2.5 reads",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "When PM2.5 read and obloq HTTP get requests are in two threads respectively, it may cause get request failure or PM2.5 read error. It is recommended to reduce get request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "When PM2.5 read and obloq HTTP post request are in two threads respectively, it may cause post request failure or PM2.5 read error. It is recommended to reduce the frequency of post request or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "When PM2.5 reading and MP3 initialization are carried out in two threads respectively, PM2.5 reading may fail. It is recommended to avoid the simultaneous execution of PM2.5 reading and MP3 initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "When PM2.5 reading and MP3 setting volume are in two threads respectively, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "When PM2.5 reading and serial MP3 player are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "When PM2.5 reading and serial MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "When PM2.5 reading and serial MP3 setting volume are respectively in two threads, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "When PM2.5 reading may cause infrared reception failure, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "When PM2.5 reading and RGB initialization are carried out in two threads respectively, PM2.5 reading errors may be caused. It is recommended to avoid simultaneous execution of PM2.5 reading and RGB initialization",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "When PM2.5 reading and RGB setting brightness are respectively in two threads, PM2.5 reading error may be caused. It is recommended not to adjust brightness too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "When PM2.5 reading and RGB extinguishing are in two threads, PM2.5 reading errors may be caused. It is recommended not to extinguish RGB too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "When PM2.5 reading and RGB display colors are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to switch colors too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "When PM2.5 reading and RGB display the gradient in two threads respectively, PM2.5 reading error may be caused, it is recommended not to switch the gradient too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "When PM2.5 reading and RGB moving pixels are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to move pixels too frequently",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to be initialized abnormally",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 initialization to be abnormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "Using Wi-Fi may cause serial port mp3 to play track failed",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to play tracks failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "Using Wi-Fi may cause the serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "Using Wi-Fi may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp uses Wi-Fi, which may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "When PM2.5 read and RGB loop move pixels in two threads respectively, PM2.5 read errors may be caused. It is recommended not to execute loop move pixels too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "When PM2.5 reading and RGB display columns are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to execute RGB display columns too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "When PM2.5 reading and led dot array initialization are carried out in two threads respectively, led dot array initialization may go wrong. It is recommended to avoid the simultaneous execution of PM2.5 reading and led dot array initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "When PM2.5 reading and led dot array display are in two threads respectively, led dot array display may be wrong, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5 reading may cause errors in PM2.5 reading. It is recommended not to send data too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5 reading may cause soft serial port reading failure. It is recommended not to read PM2.5 too frequently",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 may not receive infrared data, it is recommended to reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "When MP3 and obloq MQTT initialization is in two threads, it may cause MQTT initialization to fail. It is recommended to avoid simultaneous MQTT initialization and MP3 initialization",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "When MP3 and obloq HTTP put requests are in two threads, it is possible to cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "When MP3 and obloq HTTP initializations are in two threads, HTTP initializations may fail. It is recommended to avoid both HTTP initializations and MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "When MP3 and obloq HTTP get requests are in two threads, it may cause get requests to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "When MP3 and obloq HTTP post requests are in two threads, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 may cause soft serial port read failure, it is recommended not to use MP3 too often",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "Serial MP3 may not receive infrared data, it is recommended to reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "When serial port MP3 and obloq MQTT initialization are in two threads respectively, MQTT initialization may fail. It is recommended to avoid simultaneous execution of MQTT initialization and serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "When serial MP3 and obloq HTTP put requests are in two threads, it may cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "HTTP initialization may fail when serial MP3 and obloq are initialized in two threads. It is recommended to avoid both HTTP initialization and serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "When the serial port MP3 and obloq HTTP get requests are in two threads respectively, it may cause the get request to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "When the serial port MP3 and obloq HTTP post requests are in two threads respectively, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of the serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "Serial port MP3 may cause soft serial port read failure, it is recommended not to use serial port MP3 too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving errors, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "Soft serial obloq may cause errors in infrared receiving data, so it is recommended to avoid sending messages frequently by obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "The initialization of RGB band may lead to the error of obloq receiving messages. It is recommended to avoid frequent use of RGB band",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Setting the brightness of the light belt may cause an error in obloq receiving messages. It is recommended to avoid frequent brightness adjustment",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB belt extinguish may lead to soft serial obloq receiving messages error, it is recommended to avoid frequent extinguish the belt",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB light band display may cause obloq to receive messages error, it is recommended to avoid frequent display of color",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "The display of gradient on the RGB band may cause errors in obloq receiving messages. It is recommended to avoid frequent display of gradient",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB band moving pixels may cause soft serial obloq to receive messages incorrectly",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "The RGB band loops the pixels around and may cause the soft serial obloq to receive messages out",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB ribbon display bar may cause soft serial obloq receive message error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "When soft serial obloq and led dot array initialization are carried out in two threads respectively, it may cause led dot array initialization error. It is recommended to avoid simultaneous execution of soft serial obloq and led dot array initialization",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "When soft serial obloq and led dot array display are respectively in two threads, it may cause led dot array display error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "Soft serial port sending may cause obloq reading error, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "Soft serial port obloq send may cause soft serial port read failure, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "Infrared emission may cause infrared reception of no data, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "When infrared emission may cause soft serial port reading failure, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "The initialization of RGB band may result in no infrared reception of data",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "The brightness setting of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent brightness setting of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "The RGB lamp belt is off, which may cause the infrared data can not be received",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "The moving pixel of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent moving pixel of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "Infrared reception may cause led initialization failure",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "Infrared reception may cause led display errors",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "Soft serial port sending may cause infrared data can not receive, it is recommended to avoid frequent sending soft serial port",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB band may cause soft serial port reading failure, so it is recommended to reduce the use frequency of RGB band",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Data received by soft serial port may cause led dot matrix display error",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "The sending of soft serial port may lead to the error of receiving data of soft serial port. It is recommended to avoid the execution of sending data and receiving data at the same time",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "The initialization of serial port MP3 may lead to errors in infrared receiving data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "MP3 serial port setting playing mode may cause errors in infrared receiving data, so it is recommended to avoid setting playing mode too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "Serial MP3 player may lead to infrared data errors, it is recommended to avoid switching songs too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "The volume setting of serial port MP3 may cause errors in infrared receiving data. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "Initialization of max7219 may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "Max7219 display images may cause errors in infrared received data, so it is recommended to avoid displaying images too frequently",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "The MP3 module of serial port may cause the pin interrupt cannot be triggered. It is recommended to avoid using MP3 module of serial port too often",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "The max7219 display module may cause pin interrupts not to be triggered. It is recommended to avoid using the max7219 display too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "DHT reading may lead to errors in infrared receiving messages. It is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "Ds18b20 reading may cause errors in soft external receiving messages, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5 reading may cause errors in receiving messages outside the software. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "Mp3 initialization read may cause infrared receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "The volume setting of mp3 may cause the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "Mp3 setting mode may cause errors in infrared receiving messages. It is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "Mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "Setting mode of serial port mp3 may cause error of infrared receiving message, it is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "Serial mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Setting the volume of serial port mp3 may lead to the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "Infrared emission may cause errors in infrared receiving messages, so it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "The initialization of the RGB band may lead to errors in receiving infrared messages. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving infrared messages. It is recommended to avoid setting the brightness too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB band display may cause errors in infrared receiving messages. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "Gradient discoloration of RGB lamp band display may lead to error of infrared receiving message. It is recommended to avoid displaying gradient discoloration too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Moving pixels with RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid moving pixels too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "The circular movement of pixels with the RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB ribbon display columnar may cause infrared receiving message error, it is recommended to avoid displaying columnar too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Infrared receiving messages may cause initialization failure of max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Infrared receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "Soft serial printing may cause errors in infrared receiving messages, so it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "Soft serial port obloq MQTT initialization may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "Soft serial obloq put request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "Soft serial port obloq HTTP initialization may lead to infrared receiving data errors, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "Soft serial obloq get request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "Soft serial obloq post request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "The clear display of RGB lamp band may cause errors in receiving messages in the soft infrared. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "DHT reading may lead to soft serial receiving message error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "Ds18b20 reading may cause the soft serial port to receive the message error, it is recommended to reduce the ds18b20 reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5 reading may cause errors in receiving messages by soft serial port. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "Mp3 initialization reads may result in a soft serial receiving message error, and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Setting the volume of mp3 may cause the error of receiving the message on the soft serial port. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "Mp3 setting mode may cause the soft serial port to receive the message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "Mp3 playback may lead to soft serial receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "Mp3 setting mode of serial port may cause error of receiving messages by soft serial port. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "Mp3 playing on serial port may cause errors in receiving messages on soft serial port. It is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message from soft serial port. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "Infrared emission may cause errors in receiving messages by soft serial port. It is recommended to avoid sending infrared messages too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "The initialization of the RGB band may result in an error in receiving messages from the soft serial port. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving messages from the soft serial port. It is recommended to avoid setting the brightness too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "Clear display of RGB light band may cause error of receiving message by soft serial port. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB band display may cause errors in receiving messages from the soft serial port. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB lamp tape display gradient may cause message error in soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause the soft serial port to receive messages incorrectly. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB light belt circular movement of pixels may cause the soft serial port to receive messages error, it is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB ribbon display columnar may cause the soft serial port to receive the message error, it is recommended to avoid performing display columnar too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "Soft serial receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "Soft serial receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "Soft serial printing may cause error in receiving messages from the soft serial port. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "DHT reads may cause pin interrupt triggering failure. It is recommended to reduce the DHT read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "Ds18b20 read may cause pin interrupt trigger failure, it is recommended to reduce ds18b20 read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5 reading may cause pin interrupt trigger failure. It is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Mp3 initialization reads may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "Mp3 setting volume may cause pin interrupt trigger failure. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "Mp3 setting mode may cause pin interrupt triggering failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "Mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "Serial port mp3 initialization read may cause pin interrupt trigger failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "Setting mode of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "Serial mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Setting the volume of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "Infrared emission may cause pin interrupt trigger failure, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB ribbon initialization may cause pin interrupt trigger failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Setting brightness of RGB band may cause pin interrupt trigger failure. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB strip clear display may cause pin interrupt trigger failure, it is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB tape display may cause pin interrupt trigger failure. It is recommended to avoid RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "The RGB tape display of the gradient may cause the pin interrupt trigger to fail. It is recommended to avoid displaying the gradient too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB band moving pixels may cause pin interrupt triggering failure and it is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB light belt loop move pixel may cause pin interrupt trigger failure, it is recommended to avoid too often loop move pixel",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB ribbon display bar may cause pin interrupt trigger failure. It is recommended to avoid displaying bar too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "Pin interruption may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "Pin interruption may cause max7219 to display incorrectly",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "Soft serial printing may cause pin interrupt trigger failure, it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "Soft serial port obloq MQTT initialization may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "Soft serial obloq put requests may cause pin interrupt triggering failure and it is recommended to avoid requesting data too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "Soft serial obloq HTTP initialization may cause pin interrupt triggering failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "Soft serial obloq get request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "Soft serial obloq post request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "DHT reading may lead to soft serial obloq receiving messages error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "Ds18b20 reading may cause obloq to receive messages incorrectly, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5 reading may cause error in obloq receiving messages. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "Mp3 initialization read may result in soft serial obloq receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Mp3 setting volume may result in soft serial obloq receiving message error. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "Mp3 setting mode may lead to soft serial obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "Mp3 playback may lead to soft serial obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port obloq receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "Serial port mp3 setting mode may cause soft serial port obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "Serial port mp3 player may lead to soft serial port obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message of soft serial port obloq. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving messages error, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Soft serial bloq sending messages may lead to infrared reception errors, it is recommended to avoid obloq sending messages too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB ribbon initialization may cause soft serial obloq to receive an error message, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Setting brightness of RGB light belt may cause error of obloq receiving messages. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Clear display of RGB light band may cause error of obloq receiving message. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB tape display may result in an error in obloq receiving messages. It is recommended to avoid performing RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB lamp tape display gradient may cause message error in obloq soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause an error in obloq receiving messages. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB light belt circular movement of pixels may lead to soft serial port obloq receive messages error, it is recommended to avoid too often loop movement of pixels",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB ribbon display columnar may cause soft serial obloq receive messages error, it is recommended to avoid too often display columnar",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "Soft serial obloq receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "Soft serial obloq receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "Soft serial printing may cause obloq to receive messages incorrectly. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "Soft serial port obloq sending data may cause errors in receiving data by soft serial port. It is recommended to avoid too frequent sending of data by obloq",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "Soft serial obloq sending data may cause pin interrupt cannot be triggered, it is recommended to avoid obloq sending data too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "Soft serial port obloq MQTT initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "Soft serial obloq put request may cause the soft serial port to receive data error, it is recommended to avoid too frequent requests for data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "Soft serial port obloq HTTP initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "Soft serial obloq get request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "Soft serial port obloq post request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Forward",
    "gui.blocklyText.maqueenPlus.Backward": "Backward",
    "gui.blocklyText.maqueenPlus.left": "Left",
    "gui.blocklyText.maqueenPlus.right": "Right",
    "gui.blocklyText.maqueenPlus.all": "All",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "set motor [DIR] move by [SPEED] speed [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "set motor [DIR] stop",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] speed compensation [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "read [DIR] motor speed",
    "gui.blocklyText.maqueenPlus.readMoterDir": "read [DIR] motor direction (0:stop 1:forward 2:back)",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "set [DIR] RGB show [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "read the sensation sensor [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "read the sensation sensor [INDEX] grayscale value",
    "gui.blocklyText.maqueenPlus.getVersion": "get version",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "read ultrasonic sensor trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "when received [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "infrared data",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "paint.paintEditor.hue": "색상",
    "paint.paintEditor.saturation": "채도",
    "paint.paintEditor.brightness": "명도",
    "gui.comingSoon.message1": "걱정 마세요, 작업 중입니다 {emoji}",
    "gui.comingSoon.message2": "곧 이용 가능합니다...",
    "gui.comingSoon.message3": "작업 중입니다 {emoji}",
    "paint.paintEditor.fill": "채우기 색",
    "paint.paintEditor.costume": "모양",
    "paint.paintEditor.group": "그룹화 적용",
    "paint.paintEditor.ungroup": "그룹화 해제",
    "paint.paintEditor.undo": "되돌리기",
    "paint.paintEditor.redo": "재시도",
    "paint.paintEditor.forward": "앞으로",
    "paint.paintEditor.backward": "뒤로",
    "paint.paintEditor.front": "맨 앞으로",
    "paint.paintEditor.back": "맨 뒤로",
    "paint.paintEditor.more": "기타",
    "paint.modeTools.brushSize": "크기",
    "paint.modeTools.eraserSize": "지우개 크기",
    "paint.modeTools.copy": "복사",
    "paint.modeTools.paste": "붙이기",
    "paint.modeTools.delete": "삭제",
    "paint.modeTools.curved": "굽은 모양",
    "paint.modeTools.pointed": "꺾인 모양",
    "paint.modeTools.thickness": "굵기",
    "paint.modeTools.flipHorizontal": "좌우 뒤집기",
    "paint.modeTools.flipVertical": "상하 뒤집기",
    "paint.modeTools.filled": "채우기",
    "paint.modeTools.outlined": "윤곽선",
    "paint.paintEditor.bitmap": "비트맵으로 바꾸기",
    "paint.paintEditor.vector": "벡터로 바꾸기",
    "paint.paintEditor.stroke": "윤곽선 색",
    "paint.brushMode.brush": "붓",
    "paint.eraserMode.eraser": "지우개",
    "paint.fillMode.fill": "채우기 색",
    "paint.lineMode.line": "선",
    "paint.ovalMode.oval": "원",
    "paint.rectMode.rect": "직사각형",
    "paint.reshapeMode.reshape": "형태 고치기",
    "paint.roundedRectMode.roundedRect": "모서리가 둥근 직사각형",
    "paint.selectMode.select": "선택",
    "paint.textMode.text": "텍스트",
    "paint.colorPicker.swap": "바꾸기",
    "gui.dialog.notConnectDevice": "연결된 장치가 없습니다, 메뉴에서 연결하고자 하는 장치를 선택해주세요. ",
    "gui.dialog.prompt": "공지사항",
    "gui.dialog.openPort": "포트 열기",
    "gui.dialog.error": "오류",
    "gui.dialog.notSelectDevice": "선택된 장치가 없습니다. 왼쪽 아래에 있는 \"확장\" 선택, </br>클릭 후 연결하고자 하는 장치를 선택해주세요.",
    "gui.dialog.connectDeviceError1": "장치 연결하기",
    "gui.dialog.connectDeviceError2": "실패하였습니다. 다음과 같은 방법으로 확인하세요: </br>\r\n*USB포트를 교체하고 USB케이블을 다시 연결하세요</br>\r\n*연결되었는지 확인하세요.",
    "gui.dialog.connectDeviceError3": "메인컨트롤보드(/br)\r\n*공식 커뮤니티(671877416)에서 의견을 남겨주세요.",
    "gui.dialog.calibration1": "</br>*회전하세요",
    "gui.dialog.calibration2": "메인컨트롤 보드를 조정하세요.</br>",
    "gui.dialog.runAsManager": "Please close Mind+ and right click on the software icon and select \"Run as administrator\". After starting, select this function again.",
    "gui.dialog.runErrorForVortex": "시스템 운영이 비정상적입니다. Vortex 연결을 해지합니다.",
    "gui.dialog.runError": "시스템 운영이 비정상적입니다. ",
    "gui.dialog.close": "닫기",
    "gui.dialog.variableNameSpecialCharacters": "변수명 %1은\r\n특수문자 [\\`~!@#$%^&*()+<>?:\"{},./;\\'[]\\] 를 포함할 수 없습니다.",
    "gui.dialog.funcNameSpecialCharacters": "함수명 %1은\r\n특수문자 [\\`~!@#$%^&*()+<>?:\"{},./;\\'[]\\] 를 포함할 수 없습니다.",
    "gui.dialog.funcArgsSpecialCharacters": "함수 변수 %1은 \r\n특수문자 [\\`~!@#$%^&*()+<>?:\"{},./;\\'[]\\] 를 포함할 수 없습니다.",
    "gui.dialog.and": "그리고",
    "gui.dialog.yes": "네",
    "gui.dialog.no": "아니오",
    "gui.dialog.maxReset": "편집기에서 코드를 삭제한 후, 생산자 설정 코드로 지정하시겠습니까?",
    "gui.dialog.feedbackCannotEmpty": "의견을 남겨주세요!",
    "gui.dialog.noInstallCompiler": "컴파일러가 설치되지 않았습니다. ",
    "gui.dialog.install": "온라인 설치",
    "gui.dialog.cancle": "취소",
    "gui.dialog.installingCompiler": "컴파일러를 설치하고 있습니다. ",
    "gui.dialog.uploading": "업로드 중입니다. ",
    "gui.dialog.changeLanuage": "언어를 바꾸면 현재 프로그램이 삭제됩니다. 계속 하시겠습니까?",
    "gui.dialog.boardNotSupportMpy": "현재 메인컨트롤러: %1은 microPython을 지원하지 않습니다.",
    "gui.dialog.loadBlankItem": "지원하지 않는 항목</br>\r\n빈 항목을 다시 로드하는 중입니다. \r\n",
    "gui.dialog.switchBaudError": "전송속도 전환중 오류가 발생하였습니다.</br>",
    "gui.dialog.turnMicrobitCompass": "Need to calibrate the electronic compass, please adjust the attitude of mciro: bit to move the blinking LED lights until all LED lights are on",
    "gui.dialog.operationShow": "데모 실행하기",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "컴퓨터",
    "gui.dialog.delete": "삭제",
    "gui.dialog.sureDeleteFile": "정말로 %1 의 문서 \"%2\" 를 삭제하시겠습니까? ",
    "gui.dialog.sureDeleteFolder": "정말로 %1 의 폴더 \"%2\" 를 삭제하시겠습니까? ",
    "gui.dialog.uncorrectPath": "정확한 경로를 선택하지 않으셨습니다. ",
    "gui.dialog.laterDownLoad": "나중에 다운로드하기",
    "gui.dialog.downLoadUpdate": "Download Update",
    "gui.dialog.dwnLoadWebsite": "공식 홈페이지에서 다운로드하기",
    "gui.dialog.laterUpdate": "나중에 업데이트 하기",
    "gui.dialog.update": "즉시 업데이트하기",
    "gui.dialog.getUpdateMsgError": "메시지를 업데이트 하는 중에 오류가 발생하였습니다. ",
    "gui.dialog.ok": "확인",
    "gui.dialog.closeCodeBoxes": "정말로 %1 코드박스를 삭제하시겠습니까?",
    "gui.dialog.emailError": "이메일 형식이 정확하지 않습니다!",
    "gui.dialog.emailEmpty": "이메일 주소가 정확하지 않습니다!",
    "gui.dialog.bigFilePrompt": "문서 용랑이 큽니다. 잠시만 기다려주세요. 감사합니다. ",
    "gui.dialog.successFeedbackPrompt1": "의견을 남겨주셔서 감사합니다. 3초 후 새로운 화면이 나타납니다. ",
    "gui.dialog.successFeedbackPrompt2": "이미 의견을 제출하셨습니다. 몇 초 후 다시 시도해주세요. ",
    "gui.dialog.failedFeedbackPrompt": "의견을 제출하는데 실패하였습니다. 불편을끼쳐드려 대단히 죄송합니다. 공식  QQ 커뮤니티를 추가하여 의견을 제출해주십시오. 감사합니다. ",
    "gui.dialog.successFeedbackPrompt3": "의견을 제출해 주셔서 감사합니다.",
    "gui.dialog.forcedCloseSoftWare": "저장한 문서에 문제가 발생했습니다. 강제로 닫으시겠습니까? ",
    "gui.dialog.saveSuccess": "저장하는데 성공했습니다.",
    "gui.dialog.noSelectTruePath": "정확한 경로를 선택하지 않으셨습니다. ",
    "gui.dialog.newItemContent": "새 프로젝트를 열어 현재 프로젝트를 덮어씁니다. 생성하시겠습니까?",
    "gui.dialog.deleteBlocksError": "%1 블록을 제거하는 데 실패하였습니다. 수동으로 삭제하십시오.",
    "gui.dialog.netIsConnect": "인터넷 연결을 확인해주세요.",
    "gui.dialog.needToUpdate": "컴파일러를 최신버전으로 업데이트 하십시오: %1",
    "gui.dialog.cantUseMpythonInArduinoC": "컴파일러가 설치되어있지 않습니다.  아두이노 C에서 mpython 을 사용할 수 없습니다.컴퓨터에 네트워크가 없으면 공식 웹 사이트에서 '컴파일러 오프라인 플러그인 패키지'를 다운로드 할 수 있습니다.",
    "gui.dialog.enterMpyMode": "micropython  모드로 들어가기",
    "gui.dialog.runHardware": "장치를 먼저 연결한 다음 하드웨어를 작동하십시오.",
    "gui.dialog.sureDelete": "정말로 제거하시겠습니까?",
    "gui.dialog.notSupportWebGL": "스테이지를 생성하는데 오류가 발생하였습니다. 귀하의 컴퓨터가  WebGL를 지원하지 않는 경우 발생할 수 있습니다. </br>%1</br>QQ문의: 671877416",
    "gui.dialog.boardIsnotMPython": "현재 연결된 메인컨트롤보드는  mPython 이 아닙니다. mPython을 연결해주십시오.",
    "gui.dialog.swdMicrobitUpload": "현재 사용하고계신 컴퓨터에서는 반드시 SWD를 사용하여 micro:bit를 복제해야합니다. 관리자 권한으로 Mind+를 작동시켜주세요. ",
    "gui.dialog.openPortError": "시리얼 포트열기 %1 오류: %2",
    "gui.dialog.pressedA": "버튼 A를 길게 누르고 손을 떼지 마십시오.",
    "gui.dialog.unpressedA": "버튼A에서 손을 떼십시오.",
    "gui.dialog.unsupportProject": "지원하지 않는 항목</br> 빈 항목을 다시 로드하는 중입니다. \r\n",
    "gui.dialog.openProjectError": "실시간 모드에서 만들어진 프로그램은 실시간 모드에서만 사용할 수 있습니다. </br> 현재 페이지가 실시간 모드인지 업로드 모드인지 확인하세요.",
    "gui.dialog.installCompilerError1": "컴파일러 다운로드에 실패했습니다. 오프라인 설치 프로그램을 다운로드하거나 다시 다운로드하세요.",
    "gui.dialog.installCompilerOffline": "오프라인 설치 프로그램 다운받기",
    "gui.dialog.installCompilerError2": "인증 오류가 발생했습니다. 오프라인 설치 프로그램을 다운로드하거나 다시 다운로드 하세요.",
    "gui.dialog.installCompilerError3": "설치하는데 실패했습니다. 오프라인 설치 프로그램을 다운로드하거나 다시 다운로드하세요.",
    "gui.dialog.installCompilerSuccess": "컴파일러가 이미 설치되었습니다. ArduinoC에서 ESP32를 사용할 수 있습니다.",
    "gui.dialog.sureAdapter": "어댑터를 찾지 못했습니다. 연결되었는지 확인하세요.",
    "gui.dialog.checkPort": "시리얼 포트를 여는데 실패했습니다. 시리얼 포트가 이미 사용중인지 확인하세요.",
    "gui.dialog.changeBoard": "메인컨트롤러를 바꾸시면 현재 화면의 프로그램이 모두 삭제됩니다. 계속하시겠습니까?",
    "gui.crashMessage.somethingWrong": "죄송합니다. 오류가 발생했습니다.",
    "gui.crashMessage.reload": "새로 고침",
    "gui.crashMessage.submit": "제출하기",
    "gui.crashMessage.attachment": "첨부하기",
    "gui.crashMessage.errFeedbackNote": "여기에 오류정보 혹은 건의사항을 적어주세요. Mind+에 가입해 더 많은 의견을 공유해보세요!",
    "gui.crashMessage.promptContent": "죄송합니다. Mind+에 오류가 발생하였습니다. 이 오류를 오른쪽의 Mind+팀에 보내주세요. 다시 시도하려면 페이지를 새로 고침하세요. ",
    "gui.dialog.isSaveProject": "현재 항목을 저장하시겠습니까?",
    "gui.dialog.save": "저장하기",
    "gui.dialog.notSave": "저장 안 하기",
    "gui.dialog.variableIsNull": "Variable name cannot be empty",
    "gui.dialog.listNameIsNull": "list name cannot be empty",
    "gui.dialog.pictureAINotNull": "cannot be null",
    "gui.dialog.pictureAIInitServer": "please initialize the server first",
    "gui.dialog.pictureAIInitServerError": "Error initializing the server, please check the account number and secret key",
    "gui.dialog.versionPrompt": "The current project version「%1」",
    "gui.dialog.versionGreater": "The project version is higher than the current software version, project version 「%1」",
    "BACKDROPS_ARCTIC": "Arctic",
    "BACKDROPS_BASEBALL": "Baseball",
    "BACKDROPS_BASEBALL_2": "Baseball 2",
    "BACKDROPS_BASKETBALL": "Basketball",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Beach Malibu",
    "BACKDROPS_BEACH_RIO": "Beach Rio",
    "BACKDROPS_BEDROOM": "Bedroom",
    "BACKDROPS_BEDROOM_2": "Bedroom 2",
    "BACKDROPS_BEDROOM_3": "Bedroom 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Bench With View",
    "BACKDROPS_BLUE_SKY": "Blue Sky",
    "BACKDROPS_BLUE_SKY_2": "Blue Sky 2",
    "BACKDROPS_BLUE_SKY3": "Blue Sky3",
    "BACKDROPS_BOARDWALK": "Boardwalk",
    "BACKDROPS_CANYON": "Canyon",
    "BACKDROPS_CASTLE_1": "Castle 1",
    "BACKDROPS_CASTLE_2": "Castle 2",
    "BACKDROPS_CASTLE_3": "Castle 3",
    "BACKDROPS_CASTLE_4": "Castle 4",
    "BACKDROPS_CHALKBOARD": "Chalkboard",
    "BACKDROPS_CIRCLES": "Circles",
    "BACKDROPS_CITY_WITH_WATER": "City With Water",
    "BACKDROPS_COLORFUL_CITY": "Colorful City",
    "BACKDROPS_CONCERT": "Concert",
    "BACKDROPS_DESERT": "Desert",
    "BACKDROPS_FARM": "Farm",
    "BACKDROPS_FIELD_AT_MIT": "Field At Mit",
    "BACKDROPS_FLOWERS": "Flowers",
    "BACKDROPS_FOREST": "Forest",
    "BACKDROPS_GALAXY": "Galaxy",
    "BACKDROPS_GARDEN-ROCK": "Garden-rock",
    "BACKDROPS_GREEK_THEATER": "Greek Theater",
    "BACKDROPS_HALL": "Hall",
    "BACKDROPS_HAY_FIELD": "Hay Field",
    "BACKDROPS_GRAFFITI": "Graffiti",
    "BACKDROPS_HEARTS1": "Hearts1",
    "BACKDROPS_HEARTS2": "Hearts2",
    "BACKDROPS_HILL": "Hill",
    "BACKDROPS_JUNGLE": "Jungle",
    "BACKDROPS_JURASSIC": "Jurassic",
    "BACKDROPS_LIGHT": "Light",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": "Moon",
    "BACKDROPS_MOUNTAIN": "Mountain",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "Nebula",
    "BACKDROPS_NEON_TUNNEL": "Neon Tunnel",
    "BACKDROPS_NIGHT_CITY": "Night City",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Night City With Street",
    "BACKDROPS_PARTY": "Party",
    "BACKDROPS_PATHWAY": "Pathway",
    "BACKDROPS_PARTY_ROOM": "Party Room",
    "BACKDROPS_PLAYGROUND": "Playground",
    "BACKDROPS_PLAYING_FIELD": "Playing Field",
    "BACKDROPS_POOL": "Pool",
    "BACKDROPS_PURPLE": "Purple",
    "BACKDROPS_RAYS": "Rays",
    "BACKDROPS_REFRIGERATOR": "Refrigerator",
    "BACKDROPS_ROOM_1": "Room 1",
    "BACKDROPS_ROOM_2": "Room 2",
    "BACKDROPS_SAVANNA": "Savanna",
    "BACKDROPS_SCHOOL": "School",
    "BACKDROPS_SLOPES": "Slopes",
    "BACKDROPS_SOCCER": "Soccer",
    "BACKDROPS_SOCCER_2": "Soccer 2",
    "BACKDROPS_SPACE": "Space",
    "BACKDROPS_SPACE_2": "Space 2",
    "BACKDROPS_SPACE_3": "Space 3",
    "BACKDROPS_SPACE_4": "Space 4",
    "BACKDROPS_SPOTLIGHT-STAGE": "Spotlight-stage",
    "BACKDROPS_STARS": "Stars",
    "BACKDROPS_STRIPES": "Stripes",
    "BACKDROPS_THEATER": "Theater",
    "BACKDROPS_THEATER_2": "Theater 2",
    "BACKDROPS_TREE": "Tree",
    "BACKDROPS_UNDERWATER_1": "Underwater 1",
    "BACKDROPS_UNDERWATER_2": "Underwater 2",
    "BACKDROPS_URBAN": "Urban",
    "BACKDROPS_WALL_1": "Wall 1",
    "BACKDROPS_WALL_2": "Wall 2",
    "BACKDROPS_WATER_AND_ROCKS": "Water And Rocks",
    "BACKDROPS_WETLAND": "Wetland",
    "BACKDROPS_WINTER": "Winter",
    "BACKDROPS_WINTER-LIGHTS": "Winter-lights",
    "BACKDROPS_WITCH_HOUSE": "Witch House",
    "BACKDROPS_WOODS": "Woods",
    "BACKDROPS_WOODS_AND_BENCH": "Woods And Bench",
    "BACKDROPS_XY-GRID": "Xy-grid",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "Abby-a",
    "COSTUMES_ABBY-B": "Abby-b",
    "COSTUMES_ABBY-C": "Abby-c",
    "COSTUMES_ABBY-D": "Abby-d",
    "COSTUMES_AMON": "Amon",
    "COSTUMES_ANDIE-A": "Andie-a",
    "COSTUMES_ANDIE-B": "Andie-b",
    "COSTUMES_ANDIE-C": "Andie-c",
    "COSTUMES_ANDIE-D": "Andie-d",
    "COSTUMES_ANINA_POP_DOWN": "Anina Pop Down",
    "COSTUMES_ANINA_POP_FRONT": "Anina Pop Front",
    "COSTUMES_ANINA_POP_L_ARM": "Anina Pop L Arm",
    "COSTUMES_ANINA_POP_LEFT": "Anina Pop Left",
    "COSTUMES_ANINA_POP_R_ARM": "Anina Pop R Arm",
    "COSTUMES_ANINA_POP_RIGHT": "Anina Pop Right",
    "COSTUMES_ANINA_POP_STAND": "Anina Pop Stand",
    "COSTUMES_ANINA_R_CROSS": "Anina R Cross",
    "COSTUMES_ANINA_STANCE": "Anina Stance",
    "COSTUMES_ANINA_TOP_FREEZE": "Anina Top Freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "Anina Top L Step",
    "COSTUMES_ANINA_TOP_R_STEP": "Anina Top R Step",
    "COSTUMES_ANINA_TOP_STAND": "Anina Top Stand",
    "COSTUMES_APPLE": "Apple",
    "COSTUMES_ARROW1-A": "Arrow1-a",
    "COSTUMES_ARROW1-B": "Arrow1-b",
    "COSTUMES_ARROW1-C": "Arrow1-c",
    "COSTUMES_ARROW1-D": "Arrow1-d",
    "COSTUMES_AVERY_WALKING-A": "Avery walking-a",
    "COSTUMES_AVERY_WALKING-B": "Avery walking-b",
    "COSTUMES_AVERY_WALKING-C": "Avery walking-c",
    "COSTUMES_AVERY_WALKING-D": "Avery walking-d",
    "COSTUMES_AVERY-A": "Avery-a",
    "COSTUMES_AVERY-B": "Avery-b",
    "COSTUMES_BALL-A": "Ball-a",
    "COSTUMES_BALL-B": "Ball-b",
    "COSTUMES_BALL-C": "Ball-c",
    "COSTUMES_BALL-D": "Ball-d",
    "COSTUMES_BALL-E": "Ball-e",
    "COSTUMES_BALLERINA-A": "Ballerina-a",
    "COSTUMES_BALLERINA-B": "Ballerina-b",
    "COSTUMES_BALLERINA-C": "Ballerina-c",
    "COSTUMES_BALLERINA-D": "Ballerina-d",
    "COSTUMES_BALLOON1-A": "Balloon1-a",
    "COSTUMES_BALLOON1-B": "Balloon1-b",
    "COSTUMES_BALLOON1-C": "Balloon1-c",
    "COSTUMES_BANANAS": "Bananas",
    "COSTUMES_BASEBALL": "Baseball",
    "COSTUMES_BASKETBALL": "Basketball",
    "COSTUMES_BAT-A": "Bat-a",
    "COSTUMES_BAT-B": "Bat-b",
    "COSTUMES_BAT-C": "Bat-c",
    "COSTUMES_BAT-D": "Bat-d",
    "COSTUMES_BATTER-A": "Batter-a",
    "COSTUMES_BATTER-B": "Batter-b",
    "COSTUMES_BATTER-C": "Batter-c",
    "COSTUMES_BATTER-D": "Batter-d",
    "COSTUMES_BEACHBALL": "Beachball",
    "COSTUMES_BEAR-A": "Bear-a",
    "COSTUMES_BEAR-B": "Bear-b",
    "COSTUMES_BEAR-WALK-A": "Bear-walk-a",
    "COSTUMES_BEAR-WALK-B": "Bear-walk-b",
    "COSTUMES_BEAR-WALK-C": "Bear-walk-c",
    "COSTUMES_BEAR-WALK-D": "Bear-walk-d",
    "COSTUMES_BEAR-WALK-E": "Bear-walk-e",
    "COSTUMES_BEAR-WALK-F": "Bear-walk-f",
    "COSTUMES_BEAR-WALK-G": "Bear-walk-g",
    "COSTUMES_BEAR-WALK-H": "Bear-walk-h",
    "COSTUMES_BEETLE": "Beetle",
    "COSTUMES_BELL1": "Bell1",
    "COSTUMES_BEN-A": "Ben-a",
    "COSTUMES_BEN-B": "Ben-b",
    "COSTUMES_BEN-C": "Ben-c",
    "COSTUMES_BEN-D": "Ben-d",
    "COSTUMES_BOWL-A": "Bowl-a",
    "COSTUMES_BOWTIE": "Bowtie",
    "COSTUMES_BREAD": "Bread",
    "COSTUMES_BROOM": "Broom",
    "COSTUMES_BUILDING-A": "Building-a",
    "COSTUMES_BUILDING-B": "Building-b",
    "COSTUMES_BUILDING-C": "Building-c",
    "COSTUMES_BUILDING-D": "Building-d",
    "COSTUMES_BUILDING-E": "Building-e",
    "COSTUMES_BUILDING-F": "Building-f",
    "COSTUMES_BUILDING-G": "Building-g",
    "COSTUMES_BUILDING-H": "Building-h",
    "COSTUMES_BUILDING-I": "Building-i",
    "COSTUMES_BUILDING-J": "Building-j",
    "COSTUMES_BUTTERFLY1-A": "Butterfly1-a",
    "COSTUMES_BUTTERFLY1-B": "Butterfly1-b",
    "COSTUMES_BUTTERFLY1-C": "Butterfly1-c",
    "COSTUMES_BUTTERFLY2-A": "Butterfly2-a",
    "COSTUMES_BUTTERFLY2-B": "Butterfly2-b",
    "COSTUMES_BUTTON1": "Button1",
    "COSTUMES_BUTTON2-A": "Button2-a",
    "COSTUMES_BUTTON2-B": "Button2-b",
    "COSTUMES_BUTTON3-A": "Button3-a",
    "COSTUMES_BUTTON3-B": "Button3-b",
    "COSTUMES_BUTTON4-A": "Button4-a",
    "COSTUMES_BUTTON4-B": "Button4-b",
    "COSTUMES_BUTTON5-A": "Button5-a",
    "COSTUMES_BUTTON5-B": "Button5-b",
    "COSTUMES_CAKE-A": "Cake-a",
    "COSTUMES_CAKE-B": "Cake-b",
    "COSTUMES_CALVRETT_JUMPING": "Calvrett Jumping",
    "COSTUMES_CALVRETT_THINKING": "Calvrett Thinking",
    "COSTUMES_CASEY-A": "Casey-a",
    "COSTUMES_CASEY-B": "Casey-b",
    "COSTUMES_CASEY-C": "Casey-c",
    "COSTUMES_CASEY-D": "casey-d",
    "COSTUMES_CASSY-A": "Cassy-a",
    "COSTUMES_CASSY-B": "Cassy-b",
    "COSTUMES_CASSY-C": "Cassy-c",
    "COSTUMES_CASSY-D": "Cassy-d",
    "COSTUMES_CAT_2": "Cat 2",
    "COSTUMES_CATCHER-A": "Catcher-a",
    "COSTUMES_CATCHER-B": "Catcher-b",
    "COSTUMES_CATCHER-C": "Catcher-c",
    "COSTUMES_CATCHER-D": "Catcher-d",
    "COSTUMES_CENTAUR-A": "Centaur-a",
    "COSTUMES_CENTAUR-B": "Centaur-b",
    "COSTUMES_CENTAUR-C": "centaur-c",
    "COSTUMES_CENTAUR-D": "centaur-d",
    "COSTUMES_CHAMP99-A": "Champ99-a",
    "COSTUMES_CHAMP99-B": "Champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "Cheesy Puffs",
    "COSTUMES_CHICK-A": "chick-a",
    "COSTUMES_CHICK-B": "chick-b",
    "COSTUMES_CHICK-C": "chick-c",
    "COSTUMES_CITY_BUS-A": "City Bus-a",
    "COSTUMES_CITY_BUS-B": "City Bus-b",
    "COSTUMES_CLOUD": "Cloud",
    "COSTUMES_CLOUD-A": "Cloud-a",
    "COSTUMES_CLOUD-B": "Cloud-b",
    "COSTUMES_CLOUD-C": "Cloud-c",
    "COSTUMES_CLOUD-D": "Cloud-d",
    "COSTUMES_CONVERTIBLE": "Convertible",
    "COSTUMES_CONVERTIBLE_3": "Convertible 2",
    "COSTUMES_CRAB-A": "Crab-a",
    "COSTUMES_CRAB-B": "Crab-b",
    "COSTUMES_CRYSTAL-A": "Crystal-a",
    "COSTUMES_CRYSTAL-B": "Crystal-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "Dee-a",
    "COSTUMES_DEE-B": "Dee-b",
    "COSTUMES_DEE-C": "Dee-c",
    "COSTUMES_DEE-D": "Dee-d",
    "COSTUMES_DEE-E": "Dee-e",
    "COSTUMES_DEVIN-A": "Devin-a",
    "COSTUMES_DEVIN-B": "Devin-b",
    "COSTUMES_DEVIN-C": "Devin-c",
    "COSTUMES_DEVIN-D": "Devin-d",
    "COSTUMES_DINOSAUR1-A": "Dinosaur1-a",
    "COSTUMES_DINOSAUR1-B": "Dinosaur1-b",
    "COSTUMES_DINOSAUR1-C": "Dinosaur1-c",
    "COSTUMES_DINOSAUR1-D": "Dinosaur1-d",
    "COSTUMES_DINOSAUR2-A": "Dinosaur2-a",
    "COSTUMES_DINOSAUR2-B": "Dinosaur2-b",
    "COSTUMES_DINOSAUR2-C": "Dinosaur2-c",
    "COSTUMES_DINOSAUR2-D": "Dinosaur2-d",
    "COSTUMES_DINOSAUR3-A": "Dinosaur3-a",
    "COSTUMES_DINOSAUR3-B": "Dinosaur3-b",
    "COSTUMES_DINOSAUR3-C": "Dinosaur3-c",
    "COSTUMES_DINOSAUR3-D": "Dinosaur3-d",
    "COSTUMES_DINOSAUR3-E": "Dinosaur3-e",
    "COSTUMES_DINOSAUR4-A": "Dinosaur4-a",
    "COSTUMES_DINOSAUR4-B": "Dinosaur4-b",
    "COSTUMES_DINOSAUR4-C": "Dinosaur4-c",
    "COSTUMES_DINOSAUR4-D": "Dinosaur4-d",
    "COSTUMES_DIVER1": "Diver1",
    "COSTUMES_DIVER2": "Diver2",
    "COSTUMES_DM_FREEZE": "dm freeze",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "dm stance",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": "Dog1-a",
    "COSTUMES_DOG1-B": "Dog1-b",
    "COSTUMES_DOG2-A": "Dog2-a",
    "COSTUMES_DOG2-B": "Dog2-b",
    "COSTUMES_DOG2-C": "Dog2-c",
    "COSTUMES_DONUT": "Donut",
    "COSTUMES_DORIAN-A": "Dorian-a",
    "COSTUMES_DORIAN-B": "Dorian-b",
    "COSTUMES_DORIAN-C": "Dorian-c",
    "COSTUMES_DORIAN-D": "Dorian-d",
    "COSTUMES_DOT-A": "Dot-a",
    "COSTUMES_DOT-B": "Dot-b",
    "COSTUMES_DOT-C": "Dot-c",
    "COSTUMES_DOT-D": "Dot-d",
    "COSTUMES_DOVE-A": "Dove-a",
    "COSTUMES_DOVE-B": "Dove-b",
    "COSTUMES_DRAGON-A": "Dragon-a",
    "COSTUMES_DRAGON-B": "Dragon-b",
    "COSTUMES_DRAGON-C": "Dragon-c",
    "COSTUMES_DRAGON1-A": "Dragon1-a",
    "COSTUMES_DRAGON1-B": "Dragon1-b",
    "COSTUMES_DRAGONFLY-A": "Dragonfly-a",
    "COSTUMES_DRAGONFLY-B": "Dragonfly-b",
    "COSTUMES_DRESS-A": "Dress-a",
    "COSTUMES_DRESS-B": "Dress-b",
    "COSTUMES_DRESS-C": "Dress-c",
    "COSTUMES_DRUM-A": "Drum-a",
    "COSTUMES_DRUM-B": "Drum-b",
    "COSTUMES_DRUM-CYMBAL-A": "Drum-cymbal-a",
    "COSTUMES_DRUM-CYMBAL-B": "Drum-cymbal-b",
    "COSTUMES_DRUM-HIGHHAT-A": "Drum-highhat-a",
    "COSTUMES_DRUM-HIGHHAT-B": "Drum-highhat-b",
    "COSTUMES_DRUM-KIT": "Drum-kit",
    "COSTUMES_DRUM-KIT-B": "Drum-kit-b",
    "COSTUMES_DRUM-SNARE-A": "Drum-snare-a",
    "COSTUMES_DRUM-SNARE-B": "Drum-snare-b",
    "COSTUMES_DRUMS_CONGA-A": "Drums Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Drums Conga-b",
    "COSTUMES_DUCK": "Duck",
    "COSTUMES_EARTH": "Earth",
    "COSTUMES_EASEL-A": "Easel-a",
    "COSTUMES_EASEL-B": "Easel-b",
    "COSTUMES_EASEL-C": "Easel-c",
    "COSTUMES_EGG-A": "Egg-a",
    "COSTUMES_EGG-B": "Egg-b",
    "COSTUMES_EGG-C": "Egg-c",
    "COSTUMES_EGG-D": "Egg-d",
    "COSTUMES_EGG-E": "Egg-e",
    "COSTUMES_EGG-F": "Egg-f",
    "COSTUMES_ELEPHANT-A": "Elephant-a",
    "COSTUMES_ELEPHANT-B": "Elephant-b",
    "COSTUMES_ELF-A": "Elf-a",
    "COSTUMES_ELF-B": "Elf-b",
    "COSTUMES_ELF-C": "Elf-c",
    "COSTUMES_ELF-D": "Elf-d",
    "COSTUMES_ELF-E": "Elf-e",
    "COSTUMES_FAIRY-A": "Fairy-a",
    "COSTUMES_FAIRY-B": "Fairy-b",
    "COSTUMES_FAIRY-C": "Fairy-c",
    "COSTUMES_FAIRY-D": "Fairy-d",
    "COSTUMES_FAIRY-E": "Fairy-e",
    "COSTUMES_FISH-A": "Fish-a",
    "COSTUMES_FISH-B": "Fish-b",
    "COSTUMES_FISH-C": "Fish-c",
    "COSTUMES_FISH-D": "Fish-d",
    "COSTUMES_FISHBOWL-A": "Fishbowl-a",
    "COSTUMES_FISHBOWL-B": "Fishbowl-b",
    "COSTUMES_FOOD_TRUCK-A": "Food Truck-a",
    "COSTUMES_FOOD_TRUCK-B": "Food Truck-b",
    "COSTUMES_FOOD_TRUCK-C": "Food Truck-c",
    "COSTUMES_FOOTBALL_RUNNING": "Football Running",
    "COSTUMES_FOOTBALL_STANDING": "Football Standing",
    "COSTUMES_FORTUNE_COOKIE": "Fortune Cookie",
    "COSTUMES_FOX-A": "Fox-a",
    "COSTUMES_FOX-B": "Fox-b",
    "COSTUMES_FOX-C": "Fox-c",
    "COSTUMES_FRANK-A": "Frank-a",
    "COSTUMES_FRANK-B": "Frank-b",
    "COSTUMES_FRANK-C": "Frank-c",
    "COSTUMES_FRANK-D": "Frank-d",
    "COSTUMES_FROG": "Frog",
    "COSTUMES_FROG_2-A": "Frog 2-a",
    "COSTUMES_FROG_2-B": "Frog 2-b",
    "COSTUMES_FROG_2-C": "Frog 2-c",
    "COSTUMES_FRUIT_PLATTER": "Fruit Platter",
    "COSTUMES_FRUITSALAD": "Fruitsalad",
    "COSTUMES_GHOST-A": "Ghost-a",
    "COSTUMES_GHOST-B": "Ghost-b",
    "COSTUMES_GHOST-C": "Ghost-c",
    "COSTUMES_GHOST-D": "Ghost-d",
    "COSTUMES_GIFT-A": "Gift-a",
    "COSTUMES_GIFT-B": "Gift-b",
    "COSTUMES_GIRAFFE-A": "Giraffe-a",
    "COSTUMES_GIRAFFE-B": "Giraffe-b",
    "COSTUMES_GIRAFFE-C": "Giraffe-c",
    "COSTUMES_GLASS_WATER-A": "Glass Water-a",
    "COSTUMES_GLASS_WATER-B": "Glass water-b",
    "COSTUMES_GLASSES-A": "Glasses-a",
    "COSTUMES_GLASSES-B": "Glasses-b",
    "COSTUMES_GLASSES-C": "Glasses-c",
    "COSTUMES_GLASSES-E": "Glasses-e",
    "COSTUMES_GOALIE-A": "Goalie-a",
    "COSTUMES_GOALIE-B": "Goalie-b",
    "COSTUMES_GOALIE-C": "goalie-c",
    "COSTUMES_GOALIE-D": "Goalie-d",
    "COSTUMES_GOALIE-E": "Goalie-e",
    "COSTUMES_GOBLIN-A": "Goblin-a",
    "COSTUMES_GOBLIN-B": "Goblin-b",
    "COSTUMES_GOBLIN-C": "Goblin-c",
    "COSTUMES_GOBLIN-D": "Goblin-d",
    "COSTUMES_GRASSHOPPER-A": "Grasshopper-a",
    "COSTUMES_GRASSHOPPER-B": "Grasshopper-b",
    "COSTUMES_GRASSHOPPER-C": "Grasshopper-c",
    "COSTUMES_GRASSHOPPER-D": "Grasshopper-d",
    "COSTUMES_GRASSHOPPER-E": "Grasshopper-e",
    "COSTUMES_GRASSHOPPER-F": "Grasshopper-f",
    "COSTUMES_GREEN_FLAG": "Green Flag",
    "COSTUMES_GRIFFIN-A": "Griffin-a",
    "COSTUMES_GRIFFIN-B": "Griffin-b",
    "COSTUMES_GRIFFIN-C": "Griffin-c",
    "COSTUMES_GRIFFIN-D": "Griffin-d",
    "COSTUMES_GUITAR-A": "guitar-a",
    "COSTUMES_GUITAR-B": "guitar-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "Guitar-electric1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "Guitar-electric1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "Guitar-electric2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "Guitar-electric2-b",
    "COSTUMES_HANNAH-A": "Hannah-a",
    "COSTUMES_HANNAH-B": "Hannah-b",
    "COSTUMES_HANNAH-C": "Hannah-c",
    "COSTUMES_HARE-A": "Hare-a",
    "COSTUMES_HARE-B": "Hare-b",
    "COSTUMES_HARE-C": "Hare-c",
    "COSTUMES_HARPER-A": "Harper-a",
    "COSTUMES_HARPER-B": "Harper-b",
    "COSTUMES_HARPER-C": "Harper-c",
    "COSTUMES_HAT-A": "Hat-a",
    "COSTUMES_HAT-B": "Hat-b",
    "COSTUMES_HAT-C": "Hat-c",
    "COSTUMES_HAT-D": "Hat-d",
    "COSTUMES_HATCHLING-A": "Hatchling-a",
    "COSTUMES_HATCHLING-B": "Hatchling-b",
    "COSTUMES_HATCHLING-C": "Hatchling-c",
    "COSTUMES_HEART_CODE": "Heart Code",
    "COSTUMES_HEART_FACE": "Heart Face",
    "COSTUMES_HEART_LOVE": "Heart Love",
    "COSTUMES_HEART_PURPLE": "Heart Purple",
    "COSTUMES_HEART_RED": "Heart Red",
    "COSTUMES_HEART_SMILE": "Heart Smile",
    "COSTUMES_HEART_SWEET": "Heart Sweet",
    "COSTUMES_HEDGEHOG-A": "Hedgehog-a",
    "COSTUMES_HEDGEHOG-B": "Hedgehog-b",
    "COSTUMES_HEDGEHOG-C": "Hedgehog-c",
    "COSTUMES_HEDGEHOG-D": "Hedgehog-d",
    "COSTUMES_HEDGEHOG-E": "Hedgehog-e",
    "COSTUMES_HEN-A": "Hen-a",
    "COSTUMES_HEN-B": "Hen-b",
    "COSTUMES_HEN-C": "Hen-c",
    "COSTUMES_HEN-D": "Hen-d",
    "COSTUMES_HIPPO1-A": "Hippo1-a",
    "COSTUMES_HIPPO1-B": "Hippo1-b",
    "COSTUMES_HOME_BUTTON": "Home Button",
    "COSTUMES_HORSE-A": "Horse-a",
    "COSTUMES_HORSE-B": "Horse-b",
    "COSTUMES_JAIME_WALKING-A": "Jaime Walking-a",
    "COSTUMES_JAIME_WALKING-B": "Jaime Walking-b",
    "COSTUMES_JAIME_WALKING-C": "Jaime Walking-c",
    "COSTUMES_JAIME_WALKING-D": "Jaime Walking-d",
    "COSTUMES_JAIME_WALKING-E": "Jaime Walking-e",
    "COSTUMES_JAIME-A": "Jaime-a",
    "COSTUMES_JAIME-B": "Jaime-b",
    "COSTUMES_JAMAL-A": "Jamal-a",
    "COSTUMES_JAMAL-B": "Jamal-b",
    "COSTUMES_JAMAL-C": "Jamal-c",
    "COSTUMES_JAMAL-D": "Jamal-d",
    "COSTUMES_JAR-A": "Jar-a",
    "COSTUMES_JAR-B": "Jar-b",
    "COSTUMES_JELLYFISH-A": "Jellyfish-a",
    "COSTUMES_JELLYFISH-B": "Jellyfish-b",
    "COSTUMES_JELLYFISH-C": "Jellyfish-c",
    "COSTUMES_JELLYFISH-D": "Jellyfish-d",
    "COSTUMES_JO_POP_DOWN": "Jo Pop Down",
    "COSTUMES_JO_POP_FRONT": "Jo Pop Front",
    "COSTUMES_JO_POP_L_ARM": "Jo Pop L Arm",
    "COSTUMES_JO_POP_LEFT": "Jo Pop Left",
    "COSTUMES_JO_POP_R_ARM": "Jo Pop R Arm",
    "COSTUMES_JO_POP_RIGHT": "Jo Pop Right",
    "COSTUMES_JO_POP_STAND": "Jo Pop Stand",
    "COSTUMES_JO_STANCE": "Jo Stance",
    "COSTUMES_JO_TOP_L_CROSS": "Jo Top L Cross",
    "COSTUMES_JO_TOP_L_LEG": "Jo Top L Leg",
    "COSTUMES_JO_TOP_R_CROSS": "Jo Top R Cross",
    "COSTUMES_JO_TOP_R_LEG": "Jo Top R Leg",
    "COSTUMES_JO_TOP_STAND": "Jo Top Stand",
    "COSTUMES_JORDYN-A": "Jordyn-a",
    "COSTUMES_JORDYN-B": "Jordyn-b",
    "COSTUMES_JORDYN-C": "Jordyn-c",
    "COSTUMES_JORDYN-D": "Jordyn-d",
    "COSTUMES_KAI-A": "Kai-a",
    "COSTUMES_KAI-B": "Kai-b",
    "COSTUMES_KEY": "Key",
    "COSTUMES_KEYBOARD-A": "Keyboard-a",
    "COSTUMES_KEYBOARD-B": "Keyboard-b",
    "COSTUMES_KIRAN-A": "Kiran-a",
    "COSTUMES_KIRAN-B": "Kiran-b",
    "COSTUMES_KIRAN-C": "Kiran-c",
    "COSTUMES_KIRAN-D": "Kiran-d",
    "COSTUMES_KIRAN-E": "Kiran-e",
    "COSTUMES_KIRAN-F": "Kiran-f",
    "COSTUMES_KNIGHT": "Knight",
    "COSTUMES_LADYBUG2": "Ladybug1",
    "COSTUMES_LADYBUG2-A": "Ladybug2-a",
    "COSTUMES_LADYBUG2-B": "Ladybug2-b",
    "COSTUMES_LAPTOP": "Laptop",
    "COSTUMES_LB_POP_DOWN": "Lb Pop Down",
    "COSTUMES_LB_POP_FRONT": "Lb Pop Front",
    "COSTUMES_LB_POP_L_ARM": "Lb Pop L Arm",
    "COSTUMES_LB_POP_LEFT": "Lb Pop Left",
    "COSTUMES_LB_POP_R_ARM": "Lb Pop R Arm",
    "COSTUMES_LB_POP_RIGHT": "Lb Pop Right",
    "COSTUMES_LB_POP_STAND": "Lb Pop Stand",
    "COSTUMES_LB_STANCE": "lb Stance",
    "COSTUMES_LB_TOP_L_CROSS": "Lb Top L Cross",
    "COSTUMES_LB_TOP_L_LEG": "Lb Top L Leg",
    "COSTUMES_LB_TOP_R_CROSS": "Lb Top R Cross",
    "COSTUMES_LB_TOP_R_LEG": "Lb Top R Leg",
    "COSTUMES_LB_TOP_STAND": "Lb Top Stand",
    "COSTUMES_LIGHTNING": "Lightning",
    "COSTUMES_LINE": "Line",
    "COSTUMES_LION-A": "Lion-a",
    "COSTUMES_LION-B": "Lion-b",
    "COSTUMES_LION-C": "Lion-c",
    "COSTUMES_LLAMA": "Llama",
    "COSTUMES_LLAMA-B": "Llama-b",
    "COSTUMES_LLAMA-C": "Llama-c",
    "COSTUMES_MAGICWAND": "Magicwand",
    "COSTUMES_MAX-A": "Max-a",
    "COSTUMES_MAX-B": "Max-b",
    "COSTUMES_MAX-C": "Max-c",
    "COSTUMES_MAX-D": "Max-d",
    "COSTUMES_MERMAID-A": "Mermaid-a",
    "COSTUMES_MERMAID-B": "Mermaid-b",
    "COSTUMES_MERMAID-C": "Mermaid-c",
    "COSTUMES_MERMAID-D": "Mermaid-d",
    "COSTUMES_MICROPHONE-A": "Microphone-a",
    "COSTUMES_MICROPHONE-B": "Microphone-b",
    "COSTUMES_MILK-A": "Milk-a",
    "COSTUMES_MILK-B": "Milk-b",
    "COSTUMES_MILK-C": "Milk-c",
    "COSTUMES_MILK-D": "Milk-d",
    "COSTUMES_MILK-E": "Milk-e",
    "COSTUMES_MONET-A": "Monet-a",
    "COSTUMES_MONET-B": "Monet-b",
    "COSTUMES_MONET-C": "Monet-c",
    "COSTUMES_MONET-D": "Monet-d",
    "COSTUMES_MONET-E": "Monet-e",
    "COSTUMES_MONKEY-A": "Monkey-a",
    "COSTUMES_MONKEY-B": "Monkey-b",
    "COSTUMES_MONKEY-C": "Monkey-c",
    "COSTUMES_MOTORCYCLE-A": "Motorcycle-a",
    "COSTUMES_MOTORCYCLE-B": "Motorcycle-b",
    "COSTUMES_MOTORCYCLE-C": "Motorcycle-c",
    "COSTUMES_MOTORCYCLE-D": "Motorcycle-d",
    "COSTUMES_MOUSE1-A": "Mouse1-a",
    "COSTUMES_MOUSE1-B": "Mouse1-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "Neigh Pony",
    "COSTUMES_OCTOPUS-A": "Octopus-a",
    "COSTUMES_OCTOPUS-B": "Octopus-b",
    "COSTUMES_OCTOPUS-C": "Octopus-c",
    "COSTUMES_OCTOPUS-D": "Octopus-d",
    "COSTUMES_OCTOPUS-E": "Octopus-e",
    "COSTUMES_ORANGE": "Orange",
    "COSTUMES_ORANGE2-A": "Orange2-a",
    "COSTUMES_ORANGE2-B": "Orange2-b",
    "COSTUMES_OUTFIELDER-A": "Outfielder-a",
    "COSTUMES_OUTFIELDER-B": "Outfielder-b",
    "COSTUMES_OUTFIELDER-C": "Outfielder-c",
    "COSTUMES_OUTFIELDER-D": "Outfielder-d",
    "COSTUMES_OWL-A": "Owl-a",
    "COSTUMES_OWL-B": "Owl-b",
    "COSTUMES_OWL-C": "Owl-c",
    "COSTUMES_PADDLE": "Paddle",
    "COSTUMES_PANTHER-A": "Panther-a",
    "COSTUMES_PANTHER-B": "Panther-b",
    "COSTUMES_PANTHER-C": "Panther-c",
    "COSTUMES_PANTS-A": "Pants-a",
    "COSTUMES_PANTS-B": "Pants-b",
    "COSTUMES_PARROT-A": "Parrot-a",
    "COSTUMES_PARROT-B": "Parrot-b",
    "COSTUMES_PARTY_HAT-A": "Party Hat-a",
    "COSTUMES_PARTY_HAT-B": "Party Hat-b",
    "COSTUMES_PARTY_HAT-E": "Party Hat-c",
    "COSTUMES_PENCIL-A": "Pencil-a",
    "COSTUMES_PENCIL-B": "Pencil-b",
    "COSTUMES_PENGUIN-A": "Penguin-a",
    "COSTUMES_PENGUIN-B": "Penguin-b",
    "COSTUMES_PENGUIN-C": "Penguin-c",
    "COSTUMES_PENGUIN2-A": "Penguin2-a",
    "COSTUMES_PENGUIN2-B": "Penguin2-b",
    "COSTUMES_PENGUIN2-C": "Penguin2-c",
    "COSTUMES_PENGUIN2-D": "Penguin2-d",
    "COSTUMES_PITCHER-A": "Pitcher-a",
    "COSTUMES_PITCHER-B": "Pitcher-b",
    "COSTUMES_PITCHER-C": "Pitcher-c",
    "COSTUMES_PITCHER-D": "Pitcher-d",
    "COSTUMES_PLANET2": "Planet2",
    "COSTUMES_POLAR_BEAR-A": "Polar Bear-a",
    "COSTUMES_POLAR_BEAR-B": "Polar Bear-b",
    "COSTUMES_POLAR_BEAR-C": "Polar Bear-c",
    "COSTUMES_POTION-A": "Potion-a",
    "COSTUMES_POTION-B": "Potion-b",
    "COSTUMES_POTION-C": "Potion-c",
    "COSTUMES_PRINCE": "Prince",
    "COSTUMES_PRINCESS-A": "Princess-a",
    "COSTUMES_PRINCESS-B": "Princess-b",
    "COSTUMES_PRINCESS-C": "Princess-c",
    "COSTUMES_PRINCESS-D": "Princess-d",
    "COSTUMES_PRINCESS-E": "Princess-e",
    "COSTUMES_PUFFERFISH-A": "Pufferfish-a",
    "COSTUMES_PUFFERFISH-B": "Pufferfish-b",
    "COSTUMES_PUFFERFISH-C": "Pufferfish-c",
    "COSTUMES_PUFFERFISH-D": "Pufferfish-d",
    "COSTUMES_PUPPY_BACK": "Puppy Back",
    "COSTUMES_PUPPY_RIGHT": "Puppy Right",
    "COSTUMES_PUPPY_SIDE": "Puppy Side",
    "COSTUMES_PUPPY_SIT": "Puppy Sit",
    "COSTUMES_RABBIT-A": "Rabbit-a",
    "COSTUMES_RABBIT-B": "Rabbit-b",
    "COSTUMES_RABBIT-C": "Rabbit-c",
    "COSTUMES_RABBIT-D": "Rabbit-d",
    "COSTUMES_RABBIT-E": "Rabbit-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": "Rainbow",
    "COSTUMES_REFEREE-A": "referee-a",
    "COSTUMES_REFEREE-B": "referee-b",
    "COSTUMES_REFEREE-C": "referee-c",
    "COSTUMES_REFEREE-D": "referee-d",
    "COSTUMES_REINDEER": "reindeer",
    "COSTUMES_RETRO_ROBOT_A": "Retro Robot a",
    "COSTUMES_RETRO_ROBOT_B": "Retro Robot b",
    "COSTUMES_RETRO_ROBOT_C": "Retro Robot c",
    "COSTUMES_RIPLEY-A": "Ripley-a",
    "COSTUMES_RIPLEY-B": "Ripley-b",
    "COSTUMES_RIPLEY-C": "Ripley-c",
    "COSTUMES_RIPLEY-D": "Ripley-d",
    "COSTUMES_RIPLEY-E": "Ripley-e",
    "COSTUMES_RIPLEY-F": "Ripley-f",
    "COSTUMES_ROBOT-A": "Robot-a",
    "COSTUMES_ROBOT-B": "Robot-b",
    "COSTUMES_ROBOT-C": "Robot-c",
    "COSTUMES_ROBOT-D": "Robot-d",
    "COSTUMES_ROCKETSHIP-A": "Rocketship-a",
    "COSTUMES_ROCKETSHIP-B": "Rocketship-b",
    "COSTUMES_ROCKETSHIP-C": "Rocketship-c",
    "COSTUMES_ROCKETSHIP-D": "Rocketship-d",
    "COSTUMES_ROCKETSHIP-E": "Rocketship-e",
    "COSTUMES_ROCKS": "Rocks",
    "COSTUMES_ROOSTER-A": "Rooster-a",
    "COSTUMES_ROOSTER-B": "Rooster-b",
    "COSTUMES_ROOSTER-C": "Rooster-c",
    "COSTUMES_RUBY-A": "Ruby-a",
    "COSTUMES_RUBY-B": "Ruby-b",
    "COSTUMES_SAILBOAT": "Sailboat",
    "COSTUMES_SAM": "Sam",
    "COSTUMES_SAXOPHONE-A": "Saxophone-a",
    "COSTUMES_SAXOPHONE-B": "Saxophone-b",
    "COSTUMES_SCARF-A": "Scarf-a",
    "COSTUMES_SCARF-B": "Scarf-b",
    "COSTUMES_SCARF-C": "Scarf-c",
    "COSTUMES_SHARK-A": "Shark-a",
    "COSTUMES_SHARK-B": "Shark-b",
    "COSTUMES_SHARK2-A": "Shark2-a",
    "COSTUMES_SHARK2-B": "Shark2-b",
    "COSTUMES_SHARK2-C": "Shark2-c",
    "COSTUMES_SHIRT-A": "Shirt-a",
    "COSTUMES_SHOES-A": "Shoes-a",
    "COSTUMES_SHOES-B": "Shoes-b",
    "COSTUMES_SHOES-C": "Shoes-c",
    "COSTUMES_SHOES-D": "Shoes-d",
    "COSTUMES_SHORTS-A": "Shorts-a",
    "COSTUMES_SHORTS-B": "Shorts-b",
    "COSTUMES_SHORTS-C": "Shorts-c",
    "COSTUMES_SINGER1": "Singer1",
    "COSTUMES_SKELETON-A": "Skeleton-a",
    "COSTUMES_SKELETON-B": "Skeleton-b",
    "COSTUMES_SKELETON-D": "Skeleton-d",
    "COSTUMES_SKELETON-E": "Skeleton-e",
    "COSTUMES_SNAKE-A": "Snake-a",
    "COSTUMES_SNAKE-B": "Snake-b",
    "COSTUMES_SNAKE-C": "Snake-c",
    "COSTUMES_SNOWFLAKE": "Snowflake",
    "COSTUMES_SNOWMAN": "Snowman",
    "COSTUMES_SOCCER_BALL": "Soccer Ball",
    "COSTUMES_SPEAKER": "Speaker",
    "COSTUMES_SQUIRREL": "Squirrel",
    "COSTUMES_STAR": "Star",
    "COSTUMES_STARFISH-A": "Starfish-a",
    "COSTUMES_STARFISH-B_": "Starfish-b ",
    "COSTUMES_STOP": "Stop",
    "COSTUMES_STRAWBERRY-A": "Strawberry-a",
    "COSTUMES_STRAWBERRY-B": "Strawberry-b",
    "COSTUMES_STRAWBERRY-C": "Strawberry-c",
    "COSTUMES_STRAWBERRY-D": "Strawberry-d",
    "COSTUMES_STRAWBERRY-E": "Strawberry-e",
    "COSTUMES_SUN": "Sun",
    "COSTUMES_SUNGLASSES-A": "Sunglasses-a",
    "COSTUMES_SUNGLASSES-B": "Sunglasses-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-wizard",
    "COSTUMES_TAKEOUT-A": "Takeout-a",
    "COSTUMES_TAKEOUT-B": "Takeout-b",
    "COSTUMES_TAKEOUT-C": "Takeout-c",
    "COSTUMES_TAKEOUT-D": "Takeout-d",
    "COSTUMES_TAKEOUT-E": "Takeout-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 Pop Down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 Pop Front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 Pop L Arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 Pop Left",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 Pop R Arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 Pop Right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 Pop Stand",
    "COSTUMES_TEN80_STANCE": "Ten80 stance",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 top freeze",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 top L step",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 top R cross",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 top R step",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 top stand",
    "COSTUMES_TENNISBALL": "Tennisball",
    "COSTUMES_TOUCAN-A": "Toucan-a",
    "COSTUMES_TOUCAN-B": "Toucan-b",
    "COSTUMES_TOUCAN-C": "Toucan-c",
    "COSTUMES_TRAMPOLINE": "Trampoline",
    "COSTUMES_TREE1": "Tree1",
    "COSTUMES_TREES-A": "Trees-a",
    "COSTUMES_TREES-B": "Trees-b",
    "COSTUMES_TRUCK-A": "Truck-a",
    "COSTUMES_TRUCK-B": "Truck-b",
    "COSTUMES_TRUCK-C": "Truck-c",
    "COSTUMES_TRUMPET-A": "Trumpet-a",
    "COSTUMES_TRUMPET-B": "Trumpet-b",
    "COSTUMES_UNICORN": "Unicorn",
    "COSTUMES_UNICORN_2": "Unicorn 2",
    "COSTUMES_UNICORN_RUNNING-A": "Unicorn Running-a",
    "COSTUMES_UNICORN_RUNNING-B": "Unicorn Running-b",
    "COSTUMES_UNICORN_RUNNING-C": "Unicorn Running-c",
    "COSTUMES_UNICORN_RUNNING-D": "Unicorn Running-d",
    "COSTUMES_UNICORN_RUNNING-E": "Unicorn Running-e",
    "COSTUMES_UNICORN_RUNNING-F": "Unicorn Running-f",
    "COSTUMES_WAND": "Wand",
    "COSTUMES_WANDA": "Wanda",
    "COSTUMES_WATERMELON-A": "Watermelon-a",
    "COSTUMES_WATERMELON-B": "Watermelon-b",
    "COSTUMES_WATERMELON-C": "Watermelon-c",
    "COSTUMES_WINTER_HAT": "Winter Hat",
    "COSTUMES_WITCH": "Witch",
    "COSTUMES_WITCH-A": "Witch-a",
    "COSTUMES_WITCH-B": "Witch-b",
    "COSTUMES_WITCH-C": "Witch-c",
    "COSTUMES_WITCH-D": "Witch-d",
    "COSTUMES_WIZARD_HAT": "Wizard Hat",
    "COSTUMES_WIZARD-A": "Wizard-a",
    "COSTUMES_WIZARD-B": "Wizard-b",
    "COSTUMES_WIZARD-C": "Wizard-c",
    "COSTUMES_WIZARD-TOAD-A": "Wizard-toad-a",
    "COSTUMES_WIZARD-TOAD-B": "Wizard-toad-b",
    "COSTUMES_ZEBRA-A": "Zebra-a",
    "COSTUMES_ZEBRA-B": "Zebra-b",
    "COSTUMES_BLOCK-A": "Block-a",
    "COSTUMES_BLOCK-B": "Block-b",
    "COSTUMES_BLOCK-C": "Block-c",
    "COSTUMES_BLOCK-D": "Block-d",
    "COSTUMES_BLOCK-E": "Block-e",
    "COSTUMES_BLOCK-F": "Block-f",
    "COSTUMES_BLOCK-G": "Block-g",
    "COSTUMES_BLOCK-H": "Block-h",
    "COSTUMES_BLOCK-I": "Block-i",
    "COSTUMES_BLOCK-J": "Block-j",
    "COSTUMES_BLOCK-K": "Block-k",
    "COSTUMES_BLOCK-L": "Block-l",
    "COSTUMES_BLOCK-M": "Block-m",
    "COSTUMES_BLOCK-N": "Block-n",
    "COSTUMES_BLOCK-O": "Block-o",
    "COSTUMES_BLOCK-P": "Block-p",
    "COSTUMES_BLOCK-Q": "Block-q",
    "COSTUMES_BLOCK-R": "Block-r",
    "COSTUMES_BLOCK-S": "Block-s",
    "COSTUMES_BLOCK-T": "Block-t",
    "COSTUMES_BLOCK-U": "Block-u",
    "COSTUMES_BLOCK-V": "Block-v",
    "COSTUMES_BLOCK-W": "Block-w",
    "COSTUMES_BLOCK-X": "Block-x",
    "COSTUMES_BLOCK-Y": "Block-y",
    "COSTUMES_BLOCK-Z": "Block-z",
    "COSTUMES_GLOW-0": "Glow-0",
    "COSTUMES_GLOW-1": "Glow-1",
    "COSTUMES_GLOW-2": "Glow-2",
    "COSTUMES_GLOW-3": "Glow-3",
    "COSTUMES_GLOW-4": "Glow-4",
    "COSTUMES_GLOW-5": "Glow-5",
    "COSTUMES_GLOW-6": "Glow-6",
    "COSTUMES_GLOW-7": "Glow-7",
    "COSTUMES_GLOW-8": "Glow-8",
    "COSTUMES_GLOW-9": "Glow-9",
    "COSTUMES_GLOW-A": "Glow-A",
    "COSTUMES_GLOW-B": "Glow-B",
    "COSTUMES_GLOW-C": "Glow-C",
    "COSTUMES_GLOW-D": "Glow-D",
    "COSTUMES_GLOW-E": "Glow-E",
    "COSTUMES_GLOW-F": "Glow-F",
    "COSTUMES_GLOW-G": "Glow-G",
    "COSTUMES_GLOW-H": "Glow-H",
    "COSTUMES_GLOW-I": "Glow-I",
    "COSTUMES_GLOW-J": "Glow-J",
    "COSTUMES_GLOW-K": "Glow-K",
    "COSTUMES_GLOW-L": "Glow-L",
    "COSTUMES_GLOW-M": "Glow-M",
    "COSTUMES_GLOW-N": "Glow-N",
    "COSTUMES_GLOW-O": "Glow-O",
    "COSTUMES_GLOW-P": "Glow-P",
    "COSTUMES_GLOW-Q": "Glow-Q",
    "COSTUMES_GLOW-R": "Glow-R",
    "COSTUMES_GLOW-S": "Glow-S",
    "COSTUMES_GLOW-T": "Glow-T",
    "COSTUMES_GLOW-U": "Glow-U",
    "COSTUMES_GLOW-V": "Glow-V",
    "COSTUMES_GLOW-W": "Glow-W",
    "COSTUMES_GLOW-X": "Glow-X",
    "COSTUMES_GLOW-Y": "Glow-Y",
    "COSTUMES_GLOW-Z": "Glow-Z",
    "COSTUMES_STORY-A-1": "Story-A-1",
    "COSTUMES_STORY-A-2": "Story-A-2",
    "COSTUMES_STORY-A-3": "Story-A-3",
    "COSTUMES_STORY-B-1": "Story-B-1",
    "COSTUMES_STORY-B-2": "Story-B-2",
    "COSTUMES_STORY-B-3": "Story-B-3",
    "COSTUMES_STORY-C-1": "Story-C-1",
    "COSTUMES_STORY-C-2": "Story-C-2",
    "COSTUMES_STORY-C-3": "Story-C-3",
    "COSTUMES_STORY-D-1": "Story-D-1",
    "COSTUMES_STORY-D-2": "Story-D-2",
    "COSTUMES_STORY-D-3": "Story-D-3",
    "COSTUMES_STORY-E-1": "Story-E-1",
    "COSTUMES_STORY-E-2": "Story-E-2",
    "COSTUMES_STORY-E-3": "Story-E-3",
    "COSTUMES_STORY-F-1": "Story-F-1",
    "COSTUMES_STORY-F-2": "Story-F-2",
    "COSTUMES_STORY-F-3": "Story-F-3",
    "COSTUMES_STORY-G-1": "Story-G-1",
    "COSTUMES_STORY-G-2": "Story-G-2",
    "COSTUMES_STORY-G-3": "Story-G-3",
    "COSTUMES_STORY-H-1": "Story-H-1",
    "COSTUMES_STORY-H-2": "Story-H-2",
    "COSTUMES_STORY-H-3": "Story-H-3",
    "COSTUMES_STORY-I-1": "Story-I-1",
    "COSTUMES_STORY-I-2": "Story-I-2",
    "COSTUMES_STORY-I-3": "Story-I-3",
    "COSTUMES_STORY-J-1": "Story-J-1",
    "COSTUMES_STORY-J-2": "Story-J-2",
    "COSTUMES_STORY-J-3": "Story-J-3",
    "COSTUMES_STORY-K-1": "Story-K-1",
    "COSTUMES_STORY-K-2": "Story-K-2",
    "COSTUMES_STORY-K-3": "story-K-3",
    "COSTUMES_STORY-L-1": "Story-L-1",
    "COSTUMES_STORY-L-2": "Story-L-2",
    "COSTUMES_STORY-L-3": "Story-L-3",
    "COSTUMES_STORY-M-1": "Story-M-1",
    "COSTUMES_STORY-M-2": "Story-M-2",
    "COSTUMES_STORY-M-3": "Story-M-3",
    "COSTUMES_STORY-N-1": "Story-N-1",
    "COSTUMES_STORY-N-2": "Story-N-2",
    "COSTUMES_STORY-N-3": "Story-N-3",
    "COSTUMES_STORY-O-1": "Story-O-1",
    "COSTUMES_STORY-O-2": "Story-O-2",
    "COSTUMES_STORY-O-3": "Story-O-3",
    "COSTUMES_STORY-P-1": "Story-P-1",
    "COSTUMES_STORY-P-2": "Story-P-2",
    "COSTUMES_STORY-P-3": "Story-P-3",
    "COSTUMES_STORY-Q-1": "Story-Q-1",
    "COSTUMES_STORY-Q-2": "Story-Q-2",
    "COSTUMES_STORY-Q-3": "Story-Q-3",
    "COSTUMES_STORY-R-1": "Story-R-1",
    "COSTUMES_STORY-R-2": "Story-R-2",
    "COSTUMES_STORY-R-3": "Story-R-3",
    "COSTUMES_STORY-S-1": "Story-S-1",
    "COSTUMES_STORY-S-2": "Story-S-2",
    "COSTUMES_STORY-S-3": "Story-S-3",
    "COSTUMES_STORY-T-1": "Story-T-1",
    "COSTUMES_STORY-T-2": "Story-T-2",
    "COSTUMES_STORY-T-3": "Story-T-3",
    "COSTUMES_STORY-U-1": "Story-U-1",
    "COSTUMES_STORY-U-2": "Story-U-2",
    "COSTUMES_STORY-U-3": "Story-U-3",
    "COSTUMES_STORY-V-1": "Story-V-1",
    "COSTUMES_STORY-V-2": "Story-V-2",
    "COSTUMES_STORY-V-3": "Story-V-3",
    "COSTUMES_STORY-W-1": "Story-W-1",
    "COSTUMES_STORY-W-2": "Story-W-2",
    "COSTUMES_STORY-W-3": "Story-W-3",
    "COSTUMES_STORY-X-1": "Story-X-1",
    "COSTUMES_STORY-X-2": "Story-X-2",
    "COSTUMES_STORY-X-3": "Story-X-3",
    "COSTUMES_STORY-Y-1": "Story-Y-1",
    "COSTUMES_STORY-Y-2": "Story-Y-2",
    "COSTUMES_STORY-Y-3": "Story-Y-3",
    "COSTUMES_STORY-Z-1": "Story-Z-1",
    "COSTUMES_STORY-Z-2": "Story-Z-2",
    "COSTUMES_STORY-Z-3": "Story-Z-3",
    "SOUNDS_A_BASS": "A Bass",
    "SOUNDS_A_ELEC_BASS": "A Elec Bass",
    "SOUNDS_A_ELEC_GUITAR": "A Elec Guitar",
    "SOUNDS_A_ELEC_PIANO": "A Elec Piano",
    "SOUNDS_A_GUITAR": "A Guitar",
    "SOUNDS_A_MINOR_UKULELE": "A Minor Ukulele",
    "SOUNDS_A_PIANO": "A Piano",
    "SOUNDS_A_SAX": "A Sax",
    "SOUNDS_A_TROMBONE": "A Trombone",
    "SOUNDS_A_TRUMPET": "A Trumpet",
    "SOUNDS_AFRO_STRING": "Afro String",
    "SOUNDS_ALERT": "Alert",
    "SOUNDS_ALIEN_CREAK1": "Alien Creak1",
    "SOUNDS_ALIEN_CREAK2": "Alien Creak2",
    "SOUNDS_B_BASS": "B Bass",
    "SOUNDS_B_ELEC_BASS": "B Elec Bass",
    "SOUNDS_B_ELEC_GUITAR": "B Elec Guitar",
    "SOUNDS_B_ELEC_PIANO": "B Elec Piano",
    "SOUNDS_B_GUITAR": "B Guitar",
    "SOUNDS_B_PIANO": "B Piano",
    "SOUNDS_B_SAX": "B Sax",
    "SOUNDS_B_TROMBONE": "B Trombone",
    "SOUNDS_B_TRUMPET": "B Trumpet",
    "SOUNDS_BAA": "Baa",
    "SOUNDS_BARK": "Bark",
    "SOUNDS_BASKETBALL_BOUNCE": "Basketball Bounce",
    "SOUNDS_BASS_BEATBOX": "Bass Beatbox",
    "SOUNDS_BEAT_BOX1": "Beat Box1",
    "SOUNDS_BEAT_BOX2": "Beat Box2",
    "SOUNDS_BELL_CYMBAL": "Bell Cymbal",
    "SOUNDS_BELL_TOLL": "Bell Toll",
    "SOUNDS_BIG_BOING": "Big Boing",
    "SOUNDS_BIRD": "Bird",
    "SOUNDS_BIRTHDAY": "Birthday",
    "SOUNDS_BITE": "Bite",
    "SOUNDS_BOING": "Boing",
    "SOUNDS_BONK": "Bonk",
    "SOUNDS_BOOM_CLOUD": "Boom Cloud",
    "SOUNDS_BOOP_BING_BOP": "Boop Bing Bop",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": "Bowling Strike",
    "SOUNDS_BUBBLES": "Bubbles",
    "SOUNDS_BUZZ_WHIR": "Buzz Whir",
    "SOUNDS_C_BASS": "C Bass",
    "SOUNDS_C_ELEC_BASS": "C Elec Bass",
    "SOUNDS_C_ELEC_GUITAR": "C Elec Guitar",
    "SOUNDS_C_ELEC_PIANO": "C Elec Piano",
    "SOUNDS_C_GUITAR": "C Guitar",
    "SOUNDS_C_MAJOR_UKULELE": "C Major Ukulele",
    "SOUNDS_C_PIANO": "C Piano",
    "SOUNDS_C_SAX": "C Sax",
    "SOUNDS_C_TROMBONE": "C Trombone",
    "SOUNDS_C_TRUMPET": "C Trumpet",
    "SOUNDS_C2_BASS": "C2 Bass",
    "SOUNDS_C2_ELEC_BASS": "C2 Elec Bass",
    "SOUNDS_C2_ELEC_GUITAR": "C2 Elec Guitar",
    "SOUNDS_C2_ELEC_PIANO": "C2 Elec Piano",
    "SOUNDS_C2_GUITAR": "C2 guitar",
    "SOUNDS_C2_PIANO": "C2 Piano",
    "SOUNDS_C2_SAX": "C2 Sax",
    "SOUNDS_C2_TROMBONE": "C2 Trombone",
    "SOUNDS_C2_TRUMPET": "C2 Trumpet",
    "SOUNDS_CAR_HORN": "Car Horn",
    "SOUNDS_CAR_PASSING": "Car Passing",
    "SOUNDS_CAR_VROOM": "Car Vroom",
    "SOUNDS_CAVE": "Cave",
    "SOUNDS_CHATTER": "Chatter",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "Cheer",
    "SOUNDS_CHILL": "Chill",
    "SOUNDS_CHIRP": "Chirp",
    "SOUNDS_CHOMP": "Chomp",
    "SOUNDS_CHORD": "Chord",
    "SOUNDS_CLANG": "Clang",
    "SOUNDS_CLAP_BEATBOX": "Clap Beatbox",
    "SOUNDS_CLAPPING": "Clapping",
    "SOUNDS_CLASSICAL_PIANO": "Classical Piano",
    "SOUNDS_CLOCK_TICKING": "Clock Ticking",
    "SOUNDS_CLOWN_HONK": "Clown Honk",
    "SOUNDS_COIN": "Coin",
    "SOUNDS_COLLECT": "Collect",
    "SOUNDS_COMPUTER_BEEP": "Computer Beep1",
    "SOUNDS_COMPUTER_BEEP2": "Computer Beep2",
    "SOUNDS_CONNECT": "Connect",
    "SOUNDS_COUGH1": "Cough1",
    "SOUNDS_COUGH2": "Cough2",
    "SOUNDS_CRANK": "Crank",
    "SOUNDS_CRASH_BEATBOX": "Crash Beatbox",
    "SOUNDS_CRASH_CYMBAL": "crash cymbal",
    "SOUNDS_CRAZY_LAUGH": "Crazy Laugh",
    "SOUNDS_CRICKET": "Cricket",
    "SOUNDS_CRICKETS": "Crickets",
    "SOUNDS_CROAK": "Croak",
    "SOUNDS_CROWD_GASP": "Crowd Gasp",
    "SOUNDS_CROWD_LAUGH": "Crowd Laugh",
    "SOUNDS_CRUNCH": "Crunch",
    "SOUNDS_CYMBAL": "Cymbal",
    "SOUNDS_CYMBAL_CRASH": "Cymbal Crash",
    "SOUNDS_CYMBAL_ECHO": "Cymbal Echo",
    "SOUNDS_D_BASS": "D Bass",
    "SOUNDS_D_ELEC_BASS": "D Elec Bass",
    "SOUNDS_D_ELEC_GUITAR": "D Elec Guitar",
    "SOUNDS_D_ELEC_PIANO": "D Elec Piano",
    "SOUNDS_D_GUITAR": "D Guitar",
    "SOUNDS_D_PIANO": "D Piano",
    "SOUNDS_D_SAX": "D Sax",
    "SOUNDS_D_TROMBONE": "D Trombone",
    "SOUNDS_D_TRUMPET": "D Trumpet",
    "SOUNDS_DANCE_AROUND": "Dance Around",
    "SOUNDS_DANCE_CELEBRATE": "Dance Celebrate",
    "SOUNDS_DANCE_CELEBRATE2": "Dance Celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": "Dance Chill Out",
    "SOUNDS_DANCE_ENERGETIC": "Dance Energetic",
    "SOUNDS_DANCE_FUNKY": "Dance Funky",
    "SOUNDS_DANCE_HEAD_NOD": "Dance Head Nod",
    "SOUNDS_DANCE_MAGIC": "Dance Magic",
    "SOUNDS_DANCE_SITAR": "Dance Sitar",
    "SOUNDS_DANCE_SLOW_MO": "Dance Slow Mo",
    "SOUNDS_DANCE_SNARE_BEAT": "Dance Snare Beat",
    "SOUNDS_DANCE_SPACE": "Dance Space",
    "SOUNDS_DISCONNECT": "Disconnect",
    "SOUNDS_DOG1": "Dog",
    "SOUNDS_DOG2": "Dog2",
    "SOUNDS_DOOR_CLOSING": "Door Closing",
    "SOUNDS_DOOR_CREAK": "Door Creak",
    "SOUNDS_DOORBELL": "Doorbell",
    "SOUNDS_DRIP_DROP": "Drip Drop",
    "SOUNDS_DRIVE_AROUND": "Drive Around",
    "SOUNDS_DRUM": "Drum",
    "SOUNDS_DRUM_BASS1": "Drum Bass1",
    "SOUNDS_DRUM_BASS2": "Drum Bass2",
    "SOUNDS_DRUM_BASS3": "Drum Bass3",
    "SOUNDS_DRUM_BOING": "Drum Boing",
    "SOUNDS_DRUM_BUZZ": "Drum Buzz",
    "SOUNDS_DRUM_FUNKY": "Drum Funky",
    "SOUNDS_DRUM_JAM": "Drum Jam",
    "SOUNDS_DRUM_MACHINE": "Drum Machine",
    "SOUNDS_DRUM_ROLL": "Drum Roll",
    "SOUNDS_DRUM_SATELLITE": "Drum Satellite",
    "SOUNDS_DRUM_SET1": "Drum Set1",
    "SOUNDS_DRUM_SET2": "Drum Set2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "Duck",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dunnn",
    "SOUNDS_E_BASS": "E Bass",
    "SOUNDS_E_ELEC_BASS": "E Elec Bass",
    "SOUNDS_E_ELEC_GUITAR": "E Elec Guitar",
    "SOUNDS_E_ELEC_PIANO": "E Elec Piano",
    "SOUNDS_E_GUITAR": "E Guitar",
    "SOUNDS_E_PIANO": "E Piano",
    "SOUNDS_E_SAX": "E Sax",
    "SOUNDS_E_TROMBONE": "E Trombone",
    "SOUNDS_E_TRUMPET": "E Trumpet",
    "SOUNDS_EGGS": "Eggs",
    "SOUNDS_ELEC_PIANO_A_MINOR": "Elec Piano A Minor",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "Elec Piano C Major",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "Elec Piano F Major",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "Elec Piano G Major",
    "SOUNDS_ELEC_PIANO_LOOP": "Elec Piano Loop",
    "SOUNDS_EMOTIONAL_PIANO": "Emotional Piano",
    "SOUNDS_ENGINE": "Engine",
    "SOUNDS_F_BASS": "F Bass",
    "SOUNDS_F_ELEC_BASS": "F Elec Bass",
    "SOUNDS_F_ELEC_GUITAR": "F Elec Guitar",
    "SOUNDS_F_ELEC_PIANO": "F Elec Piano",
    "SOUNDS_F_GUITAR": "F Guitar",
    "SOUNDS_F_MAJOR_UKULELE": "F Major Ukulele",
    "SOUNDS_F_PIANO": "F Piano",
    "SOUNDS_F_SAX": "F Sax",
    "SOUNDS_F_TROMBONE": "F Trombone",
    "SOUNDS_F_TRUMPET": "F Trumpet",
    "SOUNDS_FAIRYDUST": "Fairydust",
    "SOUNDS_FINGER_SNAP": "Finger Snap",
    "SOUNDS_FLAM_SNARE": "Flam Snare",
    "SOUNDS_FOOTSTEPS": "Footsteps",
    "SOUNDS_G_BASS": "G Bass",
    "SOUNDS_G_ELEC_BASS": "G Elec Bass",
    "SOUNDS_G_ELEC_GUITAR": "G Elec Guitar",
    "SOUNDS_G_ELEC_PIANO": "G Elec Piano",
    "SOUNDS_G_GUITAR": "G Guitar",
    "SOUNDS_G_PIANO": "G Piano",
    "SOUNDS_G_SAX": "G Sax",
    "SOUNDS_G_TROMBONE": "G Trombone",
    "SOUNDS_G_TRUMPET": "G Trumpet",
    "SOUNDS_G_UKULELE": "G Ukulele",
    "SOUNDS_GALLOP": "Gallop",
    "SOUNDS_GARDEN": "Garden",
    "SOUNDS_GLASS_BREAKING": "Glass Breaking",
    "SOUNDS_GLUG": "Glug",
    "SOUNDS_GOAL_CHEER": "Goal Cheer",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Goose",
    "SOUNDS_GROWL": "Growl",
    "SOUNDS_GRUNT": "Grunt",
    "SOUNDS_GUITAR_CHORDS1": "Guitar Chords1",
    "SOUNDS_GUITAR_CHORDS2": "Guitar Chords2",
    "SOUNDS_GUITAR_STRUM": "Guitar Strum",
    "SOUNDS_HAND_CLAP": "Hand Clap",
    "SOUNDS_HEAD_SHAKE": "Head Shake",
    "SOUNDS_HEY": "Hey",
    "SOUNDS_HI_BEATBOX": "Hi Beatbox",
    "SOUNDS_HI_NA_TABLA": "Hi Na Tabla",
    "SOUNDS_HI_TUN_TABLA": "Hi Tun Tabla",
    "SOUNDS_HIGH_CONGA": "High Conga",
    "SOUNDS_HIGH_HAT": "High Hat",
    "SOUNDS_HIGH_TOM": "High Tom",
    "SOUNDS_HIGH_WHOOSH": "High Whoosh",
    "SOUNDS_HIHAT_BEATBOX": "Hihat Beatbox",
    "SOUNDS_HIHAT_CYMBAL": "Hihat Cymbal",
    "SOUNDS_HIP_HOP": "Hip Hop",
    "SOUNDS_HORSE": "Horse",
    "SOUNDS_HORSE_GALLOP": "horse gallop",
    "SOUNDS_HUMAN_BEATBOX1": "Human Beatbox1",
    "SOUNDS_HUMAN_BEATBOX2": "Human Beatbox2",
    "SOUNDS_JUMP": "Jump",
    "SOUNDS_JUNGLE": "Jungle",
    "SOUNDS_JUNGLE_FROGS": "Jungle Frogs",
    "SOUNDS_KICK_BACK": "Kick Back",
    "SOUNDS_KICK_DRUM": "Kick Drum",
    "SOUNDS_LARGE_COWBELL": "Large Cowbell",
    "SOUNDS_LASER1": "laser1",
    "SOUNDS_LASER2": "laser2",
    "SOUNDS_LAUGH1": "Laugh1",
    "SOUNDS_LAUGH2": "Laugh2",
    "SOUNDS_LAUGH3": "Laugh3",
    "SOUNDS_LO_GEH_TABLA": "Lo Geh Tabla",
    "SOUNDS_LO_GLISS_TABLA": "Lo Gliss Tabla",
    "SOUNDS_LOSE": "Lose",
    "SOUNDS_LOW_BOING": "Low Boing",
    "SOUNDS_LOW_CONGA": "Low Conga",
    "SOUNDS_LOW_SQUEAK": "Low Squeak",
    "SOUNDS_LOW_TOM": "Low Tom",
    "SOUNDS_LOW_WHOOSH": "Low Whoosh",
    "SOUNDS_MACHINE": "Machine",
    "SOUNDS_MAGIC_SPELL": "Magic Spell",
    "SOUNDS_MEDIEVAL1": "Medieval1",
    "SOUNDS_MEDIEVAL2": "Medieval2",
    "SOUNDS_MEOW": "Meow",
    "SOUNDS_MEOW2": "meow2",
    "SOUNDS_MOO": "Moo",
    "SOUNDS_MOTORCYCLE_PASSING": "Motorcycle Passing",
    "SOUNDS_MOVIE_1": "Movie 1",
    "SOUNDS_MOVIE_2": "Movie 2",
    "SOUNDS_MUTED_CONGA": "Muted Conga",
    "SOUNDS_MYSTERY": "Mystery",
    "SOUNDS_OCEAN_WAVE": "Ocean Wave",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "Oops",
    "SOUNDS_ORCHESTRA_TUNING": "Orchestra Tuning",
    "SOUNDS_OWL": "Owl",
    "SOUNDS_PARTY_NOISE": "Party Noise",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": "Ping Pong Hit",
    "SOUNDS_PLUCK": "Pluck",
    "SOUNDS_PLUNGE": "Plunge",
    "SOUNDS_POLICE_SIREN": "Police Siren",
    "SOUNDS_POP": "Pop",
    "SOUNDS_RAIN": "Rain",
    "SOUNDS_RATTLE": "Rattle",
    "SOUNDS_REFEREE_WHISTLE": "Referee Whistle",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "Ricochet",
    "SOUNDS_RIDE_CYMBAL": "Ride Cymbal",
    "SOUNDS_RING_TONE": "Ring Tone",
    "SOUNDS_RIP": "Rip",
    "SOUNDS_RIPPLES": "Ripples",
    "SOUNDS_ROLL_CYMBAL": "Roll Cymbal",
    "SOUNDS_ROOSTER": "Rooster",
    "SOUNDS_SCRAMBLING_FEET": "Scrambling Feet",
    "SOUNDS_SCRATCH_BEATBOX": "scratch beatbox",
    "SOUNDS_SCRATCHY_BEAT": "Scratchy Beat",
    "SOUNDS_SCREAM1": "Scream1",
    "SOUNDS_SCREAM2": "Scream2",
    "SOUNDS_SCREECH": "Screech",
    "SOUNDS_SEAGULLS": "Seagulls",
    "SOUNDS_SEWING_MACHINE": "Sewing Machine",
    "SOUNDS_SHAKER": "Shaker",
    "SOUNDS_SHIP_BELL": "Ship Bell",
    "SOUNDS_SIDESTICK_SNARE": "Sidestick Snare",
    "SOUNDS_SINGER1": "Singer1",
    "SOUNDS_SINGER2": "Singer2",
    "SOUNDS_SIREN_WHISTLE": "Siren Whistle",
    "SOUNDS_SKID": "Skid",
    "SOUNDS_SLIDE_WHISTLE": "Slide Whistle",
    "SOUNDS_SMALL_COWBELL": "Small Cowbell",
    "SOUNDS_SNAP": "Snap",
    "SOUNDS_SNARE_BEATBOX": "Snare Beatbox",
    "SOUNDS_SNARE_BEATBOX2": "Snare Beatbox2",
    "SOUNDS_SNARE_DRUM": "Snare Drum",
    "SOUNDS_SNEAKER_SQUEAK": "Sneaker Squeak",
    "SOUNDS_SNEEZE1": "Sneeze1",
    "SOUNDS_SNEEZE2": "Sneeze2",
    "SOUNDS_SNORING": "Snoring",
    "SOUNDS_SNORT": "Snort",
    "SOUNDS_SPACE_AMBIENCE": "Space Ambience",
    "SOUNDS_SPACE_FLYBY": "Space Flyby",
    "SOUNDS_SPACE_NOISE": "Space Noise",
    "SOUNDS_SPACE_RIPPLE": "Space Ripple",
    "SOUNDS_SPIRAL": "Spiral",
    "SOUNDS_SPLASH": "Splash",
    "SOUNDS_SPLASH_CYMBAL": "Splash Cymbal",
    "SOUNDS_SPOOKY_STRING": "Spooky String",
    "SOUNDS_SQUAWK": "Squawk",
    "SOUNDS_SQUEAKS": "Squeaks",
    "SOUNDS_SQUEAKY_TOY": "Squeaky Toy",
    "SOUNDS_SQUISH_POP": "Squish Pop",
    "SOUNDS_STRING_ACCENT": "String Accent",
    "SOUNDS_STRING_PLUCK": "String Pluck",
    "SOUNDS_SUCTION_CUP": "Suction Cup",
    "SOUNDS_SUSPENSE": "Suspense",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura",
    "SOUNDS_TAP_CONGA": "Tap Conga",
    "SOUNDS_TAP_SNARE": "Tap Snare",
    "SOUNDS_TECHNO": "Techno",
    "SOUNDS_TECHNO2": "Techno2",
    "SOUNDS_TELEPHONE_RING": "Telephone Ring",
    "SOUNDS_TELEPHONE_RING2": "Telephone Ring2",
    "SOUNDS_TELEPORT": "Teleport",
    "SOUNDS_TELEPORT2": "Teleport2",
    "SOUNDS_TELEPORT3": "Teleport3",
    "SOUNDS_TENNIS_HIT": "Tennis Hit",
    "SOUNDS_THUNDER_STORM": "Thunder Storm",
    "SOUNDS_TOM_DRUM": "Tom Drum",
    "SOUNDS_TOY_HONK": "Toy Honk",
    "SOUNDS_TOY_ZING": "Toy Zing",
    "SOUNDS_TRAFFIC": "Traffic",
    "SOUNDS_TRAIN_WHISTLE": "Train Whistle",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_TRIUMPH": "Triumph",
    "SOUNDS_TROPICAL_BIRDS": "Tropical Birds",
    "SOUNDS_TRUMPET1": "Trumpet1",
    "SOUNDS_TRUMPET2": "Trumpet2",
    "SOUNDS_VIDEO_GAME_1": "Video Game 1",
    "SOUNDS_VIDEO_GAME_2": "Video Game 2",
    "SOUNDS_WAH_BEATBOX": "Wah Beatbox",
    "SOUNDS_WAND": "Wand",
    "SOUNDS_WATER_DROP": "Water drop",
    "SOUNDS_WHINNY": "Whinny",
    "SOUNDS_WHISTLE_THUMP": "Whistle Thump",
    "SOUNDS_WHIZ": "Whiz",
    "SOUNDS_WHOOP": "Whoop",
    "SOUNDS_WIN": "Win",
    "SOUNDS_WOBBLE": "Wobble",
    "SOUNDS_WOLF_HOWL": "Wolf Howl",
    "SOUNDS_WOOD_TAP": "Wood Tap",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "Xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_APPLE": "Apple",
    "SPRITES_ARROW1": "Arrow1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Walking",
    "SPRITES_BALL": "Ball",
    "SPRITES_BALLERINA": "Ballerina",
    "SPRITES_BALLOON1": "Balloon1",
    "SPRITES_BANANAS": "Bananas",
    "SPRITES_BASEBALL": "Baseball",
    "SPRITES_BASKETBALL": "Basketball",
    "SPRITES_BAT": "Bat",
    "SPRITES_BATTER": "Batter",
    "SPRITES_BEACHBALL": "Beachball",
    "SPRITES_BEAR": "Bear",
    "SPRITES_BEAR-WALKING": "Bear-walking",
    "SPRITES_BEETLE": "Beetle",
    "SPRITES_BELL": "Bell",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Bowl",
    "SPRITES_BOWTIE": "Bowtie",
    "SPRITES_BREAD": "Bread",
    "SPRITES_BROOM": "Broom",
    "SPRITES_BUILDINGS": "Buildings",
    "SPRITES_BUTTERFLY_1": "Butterfly 1",
    "SPRITES_BUTTERFLY_2": "Butterfly 2",
    "SPRITES_BUTTON1": "Button1",
    "SPRITES_BUTTON2": "Button2",
    "SPRITES_BUTTON3": "Button3",
    "SPRITES_BUTTON4": "Button4",
    "SPRITES_BUTTON5": "Button5",
    "SPRITES_CAKE": "Cake",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Cat 2",
    "SPRITES_CATCHER": "Catcher",
    "SPRITES_CENTAUR": "Centaur",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Chick",
    "SPRITES_CITY_BUS": "City Bus",
    "SPRITES_CLOUD": "Cloud",
    "SPRITES_CLOUDS": "Clouds",
    "SPRITES_CONVERTIBLE": "Convertible",
    "SPRITES_CONVERTIBLE_2": "Convertible 2",
    "SPRITES_CRAB": "Crab",
    "SPRITES_CRYSTAL": "Crystal",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "Dinosaur1",
    "SPRITES_DINOSAUR2": "Dinosaur2",
    "SPRITES_DINOSAUR3": "Dinosaur3",
    "SPRITES_DINOSAUR4": "Dinosaur4",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DIVER1": "Diver1",
    "SPRITES_DIVER2": "Diver2",
    "SPRITES_DOG1": "Dog1",
    "SPRITES_DOG2": "Dog2",
    "SPRITES_DONUT": "Donut",
    "SPRITES_DORIAN": "Dorian",
    "SPRITES_DOT": "Dot",
    "SPRITES_DOVE": "Dove",
    "SPRITES_DRAGON": "Dragon",
    "SPRITES_DRAGONFLY": "Dragonfly",
    "SPRITES_DRESS": "Dress",
    "SPRITES_DRUM": "Drum",
    "SPRITES_DRUM_KIT": "Drum Kit",
    "SPRITES_DRUM-CYMBAL": "Drum-cymbal",
    "SPRITES_DRUM-HIGHHAT": "Drum-highhat",
    "SPRITES_DRUM-SNARE": "Drum-snare",
    "SPRITES_DRUMS_CONGA": "Drums Conga",
    "SPRITES_DRUMS_TABLA": "Drums Tabla",
    "SPRITES_DUCK": "Duck",
    "SPRITES_EARTH": "Earth",
    "SPRITES_EASEL": "Easel",
    "SPRITES_EGG": "Egg",
    "SPRITES_ELEPHANT": "Elephant",
    "SPRITES_ELF": "Elf",
    "SPRITES_FAIRY": "Fairy",
    "SPRITES_FISH": "Fish",
    "SPRITES_FISHBOWL": "Fishbowl",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Football",
    "SPRITES_FORTUNE_COOKIE": "Fortune Cookie",
    "SPRITES_FOX": "Fox",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "Frog",
    "SPRITES_FROG_2_": "Frog 2 ",
    "SPRITES_FRUIT_PLATTER": "Fruit Platter",
    "SPRITES_FRUIT_SALAD": "Fruit Salad",
    "SPRITES_GHOST": "Ghost",
    "SPRITES_GIFT": "Gift",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GLASS_WATER": "Glass Water",
    "SPRITES_GLASSES": "Glasses",
    "SPRITES_GOALIE": "Goalie",
    "SPRITES_GOBLIN": "Goblin",
    "SPRITES_GRASSHOPPER": "Grasshopper",
    "SPRITES_GREEN_FLAG": "Green Flag",
    "SPRITES_GRIFFIN": "Griffin",
    "SPRITES_GUITAR": "Guitar",
    "SPRITES_GUITAR-ELECTRIC1": "Guitar-electric1",
    "SPRITES_GUITAR-ELECTRIC2": "Guitar-electric2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hare",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hat1 ",
    "SPRITES_HATCHLING": "Hatchling",
    "SPRITES_HEART": "Heart",
    "SPRITES_HEART_CANDY": "Heart Candy",
    "SPRITES_HEART_FACE": "Heart Face",
    "SPRITES_HEDGEHOG": "Hedgehog",
    "SPRITES_HEN": "Hen",
    "SPRITES_HIPPO1": "Hippo1",
    "SPRITES_HOME_BUTTON": "Home Button",
    "SPRITES_HORSE": "Horse",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "Jar",
    "SPRITES_JELLYFISH": "Jellyfish",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "Key",
    "SPRITES_KEYBOARD": "Keyboard",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Knight",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_LADYBUG1": "Ladybug1",
    "SPRITES_LADYBUG2": "Ladybug2",
    "SPRITES_LAPTOP": "Laptop",
    "SPRITES_LIGHTNING": "Lightning",
    "SPRITES_LINE": "Line",
    "SPRITES_LION": "Lion",
    "SPRITES_LLAMA": "Llama",
    "SPRITES_MAGIC_WAND": "Magic Wand",
    "SPRITES_MAX": "Max",
    "SPRITES_MERMAID": "Mermaid",
    "SPRITES_MICROPHONE": "Microphone",
    "SPRITES_MILK": "Milk",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Monkey",
    "SPRITES_MOTORCYCLE": "Motorcycle",
    "SPRITES_MOUSE1": "Mouse1",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": "Neigh Pony",
    "SPRITES_OCTOPUS": "Octopus",
    "SPRITES_ORANGE": "Orange",
    "SPRITES_ORANGE2": "Orange2",
    "SPRITES_OUTFIELDER": "Outfielder",
    "SPRITES_OWL": "Owl",
    "SPRITES_PADDLE": "Paddle",
    "SPRITES_PANTHER": "Panther",
    "SPRITES_PANTS": "Pants",
    "SPRITES_PARROT": "Parrot",
    "SPRITES_PARTY_HATS": "Party Hats",
    "SPRITES_PENCIL": "Pencil",
    "SPRITES_PENGUIN": "Penguin",
    "SPRITES_PENGUIN_2": "Penguin 2",
    "SPRITES_PITCHER": "Pitcher",
    "SPRITES_PLANET2": "Planet2",
    "SPRITES_POLAR_BEAR": "Polar Bear",
    "SPRITES_POTION": "Potion",
    "SPRITES_PRINCE": "Prince",
    "SPRITES_PRINCESS": "Princess",
    "SPRITES_PUFFERFISH": "Pufferfish",
    "SPRITES_PUPPY": "Puppy",
    "SPRITES_RABBIT": "Rabbit",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": "Rainbow",
    "SPRITES_REFEREE": "Referee",
    "SPRITES_REINDEER": "Reindeer",
    "SPRITES_RETRO_ROBOT": "Retro Robot",
    "SOUNDS_COMPUTER_BEEPS1": "computer beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "computer beeps2",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Robot",
    "SPRITES_ROCKETSHIP": "Rocketship",
    "SPRITES_ROCKS": "Rocks",
    "SPRITES_ROOSTER": "Rooster",
    "SPRITES_RUBY": "Ruby",
    "SPRITES_SAILBOAT": "Sailboat",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "Saxophone",
    "SPRITES_SCARF": "Scarf",
    "SPRITES_SHARK": "Shark",
    "SPRITES_SHARK_2": "Shark 2",
    "SPRITES_SHIRT": "Shirt",
    "SPRITES_SHOES": "Shoes",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "Singer1",
    "SPRITES_SKELETON": "Skeleton",
    "SPRITES_SNAKE": "Snake",
    "SPRITES_SNOWFLAKE": "Snowflake",
    "SPRITES_SNOWMAN": "Snowman",
    "SPRITES_SOCCER_BALL": "Soccer Ball",
    "SPRITES_SPEAKER": "Speaker",
    "SPRITES_SQUIRREL": "Squirrel",
    "SPRITES_STAR": "Star",
    "SPRITES_STARFISH": "Starfish",
    "SPRITES_STOP": "Stop",
    "SPRITES_STRAWBERRY": "Strawberry",
    "SPRITES_SUN": "Sun",
    "SPRITES_SUNGLASSES1": "Sunglasses1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Takeout",
    "SPRITES_TEN80_DANCE": "Ten80 Dance",
    "SPRITES_TENNIS_BALL": "Tennis Ball",
    "SPRITES_TOUCAN": "Toucan",
    "SPRITES_TRAMPOLINE": "Trampoline",
    "SPRITES_TREE1": "Tree1",
    "SPRITES_TREES": "Trees",
    "SPRITES_TRUCK": "Truck",
    "SPRITES_TRUMPET": "Trumpet",
    "SPRITES_UNICORN": "Unicorn",
    "SPRITES_UNICORN_2": "Unicorn 2",
    "SPRITES_UNICORN_RUNNING": "Unicorn Running",
    "SPRITES_WAND": "Wand",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Watermelon",
    "SPRITES_WINTER_HAT": "Winter Hat",
    "SPRITES_WITCH": "Witch",
    "SPRITES_WIZARD": "Wizard",
    "SPRITES_WIZARD_HAT": "Wizard Hat",
    "SPRITES_WIZARD-TOAD": "Wizard-toad",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "Block-A",
    "SPRITES_BLOCK-B": "Block-B",
    "SPRITES_BLOCK-C": "Block-C",
    "SPRITES_BLOCK-D": "Block-D",
    "SPRITES_BLOCK-E": "Block-E",
    "SPRITES_BLOCK-F": "Block-F",
    "SPRITES_BLOCK-G": "Block-G",
    "SPRITES_BLOCK-H": "Block-H",
    "SPRITES_BLOCK-I": "Block-I",
    "SPRITES_BLOCK-J": "Block-J",
    "SPRITES_BLOCK-K": "Block-K",
    "SPRITES_BLOCK-L": "Block-L",
    "SPRITES_BLOCK-M": "Block-M",
    "SPRITES_BLOCK-N": "Block-N",
    "SPRITES_BLOCK-O": "Block-O",
    "SPRITES_BLOCK-P": "Block-P",
    "SPRITES_BLOCK-Q": "Block-Q",
    "SPRITES_BLOCK-R": "Block-R",
    "SPRITES_BLOCK-S": "Block-S",
    "SPRITES_BLOCK-T": "Block-T",
    "SPRITES_BLOCK-U": "Block-U",
    "SPRITES_BLOCK-V": "Block-V",
    "SPRITES_BLOCK-W": "Block-W",
    "SPRITES_BLOCK-X": "Block-X",
    "SPRITES_BLOCK-Y": "Block-Y",
    "SPRITES_BLOCK-Z": "Block-Z",
    "SPRITES_GLOW-0": "Glow-0",
    "SPRITES_GLOW-1": "Glow-1",
    "SPRITES_GLOW-2": "Glow-2",
    "SPRITES_GLOW-3": "Glow-3",
    "SPRITES_GLOW-4": "Glow-4",
    "SPRITES_GLOW-5": "Glow-5",
    "SPRITES_GLOW-6": "Glow-6",
    "SPRITES_GLOW-7": "Glow-7",
    "SPRITES_GLOW-8": "Glow-8",
    "SPRITES_GLOW-9": "Glow-9",
    "SPRITES_GLOW-A": "Glow-A",
    "SPRITES_GLOW-B": "Glow-B",
    "SPRITES_GLOW-C": "Glow-C",
    "SPRITES_GLOW-D": "Glow-D",
    "SPRITES_GLOW-E": "Glow-E",
    "SPRITES_GLOW-F": "Glow-F",
    "SPRITES_GLOW-G": "Glow-G",
    "SPRITES_GLOW-H": "Glow-H",
    "SPRITES_GLOW-I": "Glow-I",
    "SPRITES_GLOW-J": "Glow-J",
    "SPRITES_GLOW-K": "Glow-K",
    "SPRITES_GLOW-L": "Glow-L",
    "SPRITES_GLOW-M": "Glow-M",
    "SPRITES_GLOW-N": "Glow-N",
    "SPRITES_GLOW-O": "Glow-O",
    "SPRITES_GLOW-P": "Glow-P",
    "SPRITES_GLOW-Q": "Glow-Q",
    "SPRITES_GLOW-R": "Glow-R",
    "SPRITES_GLOW-S": "Glow-S",
    "SPRITES_GLOW-T": "Glow-T",
    "SPRITES_GLOW-U": "Glow-U",
    "SPRITES_GLOW-V": "Glow-V",
    "SPRITES_GLOW-W": "Glow-W",
    "SPRITES_GLOW-X": "Glow-X",
    "SPRITES_GLOW-Y": "Glow-Y",
    "SPRITES_GLOW-Z": "Glow-Z",
    "SPRITES_STORY-A": "Story-A",
    "SPRITES_STORY-B": "Story-B",
    "SPRITES_STORY-C": "Story-C",
    "SPRITES_STORY-D": "Story-D",
    "SPRITES_STORY-E": "Story-E",
    "SPRITES_STORY-F": "Story-F",
    "SPRITES_STORY-G": "Story-G",
    "SPRITES_STORY-H": "Story-H",
    "SPRITES_STORY-I": "Story-I",
    "SPRITES_STORY-J": "Story-J",
    "SPRITES_STORY-K": "Story-K",
    "SPRITES_STORY-L": "Story-L",
    "SPRITES_STORY-M": "Story-M",
    "SPRITES_STORY-N": "Story-N",
    "SPRITES_STORY-O": "Story-O",
    "SPRITES_STORY-P": "Story-P",
    "SPRITES_STORY-Q": "Story-Q",
    "SPRITES_STORY-R": "Story-R",
    "SPRITES_STORY-S": "Story-S",
    "SPRITES_STORY-T": "Story-T",
    "SPRITES_STORY-U": "Story-U",
    "SPRITES_STORY-V": "Story-V",
    "SPRITES_STORY-W": "Story-W",
    "SPRITES_STORY-X": "Story-X",
    "SPRITES_STORY-Y": "Story-Y",
    "SPRITES_STORY-Z": "Story-Z"
  },
  "he": {
    "gui.backpack.header": "תרמיל",
    "gui.backpack.errorBackpack": "אירעה שגיאה בטעינת התרמיל",
    "gui.backpack.loadingBackpack": "טוען...",
    "gui.backpack.more": "עוד",
    "gui.backpack.emptyBackpack": "התרמיל ריק",
    "gui.gui.costumesTab": "תלבושות",
    "gui.gui.soundsTab": "צלילים",
    "gui.gui.backdropsTab": "רקעים",
    "gui.gui.addExtension": "הוסיפו הרחבה",
    "gui.costumeTab.addCostumeFromLibrary": "בחרו תלבושת",
    "gui.costumeLibrary.chooseACostume": "בחרו תלבושת",
    "gui.costumeTab.addBackdropFromLibrary": "בחרו רקע",
    "gui.costumeTab.addBlankCostume": "צייר",
    "gui.costumeTab.addSurpriseCostume": "הפתעות",
    "gui.costumeTab.addFileBackdrop": "העלו רקע",
    "gui.costumeTab.addFileCostume": "העלו תלבושת",
    "gui.costumeTab.addCameraCostume": "מצלמה",
    "gui.soundEditor.trim": "חיתוך",
    "gui.soundEditor.stop": "עצירה",
    "gui.soundEditor.sound": "צליל",
    "gui.soundEditor.play": "נגינה",
    "gui.soundEditor.save": "שמירה",
    "gui.soundEditor.undo": "ביטול",
    "gui.soundEditor.redo": "שחזור",
    "gui.soundEditor.faster": "מהר יותר",
    "gui.soundEditor.slower": "איטי יותר",
    "gui.soundEditor.echo": "הד",
    "gui.soundEditor.robot": "רובוט",
    "gui.soundEditor.louder": "חזק יותר",
    "gui.soundEditor.softer": "חלש יותר",
    "gui.soundEditor.reverse": "הפוך",
    "gui.soundTab.recordSound": "הקלטה",
    "gui.soundTab.addSoundFromLibrary": "בחרו צליל",
    "gui.soundTab.surpriseSound": "הפתעה",
    "gui.soundTab.fileUploadSound": "העלו צליל",
    "gui.controls.stop": "עצירה",
    "gui.controls.go": "הפעל",
    "gui.stageHeader.stageSizeUnFull": "יציאה ממסך מלא",
    "gui.SpriteInfo.show": "הצגה",
    "gui.SpriteInfo.size": "גודל",
    "gui.directionPicker.rotationStyles.allAround": "כל הכיוונים",
    "gui.directionPicker.rotationStyles.leftRight": "שמאל-ימין",
    "gui.directionPicker.rotationStyles.dontRotate": "לא לסובב",
    "gui.spriteSelectorItem.contextMenuDuplicate": "שכפול",
    "gui.spriteSelectorItem.contextMenuDelete": "מחיקה",
    "gui.spriteSelectorItem.contextMenuExport": "יצוא",
    "gui.spriteSelector.addSpriteFromPaint": "צייר",
    "gui.spriteSelector.addSpriteFromSurprise": "הפתעה",
    "gui.spriteSelector.addSpriteFromFile": "העלו דמות",
    "gui.stageSelector.stage": "במה",
    "gui.stageSelector.backdrops": "רקע",
    "gui.stageSelector.addBackdropFromPaint": "צייר",
    "gui.stageSelector.addBackdropFromSurprise": "הפתעה",
    "gui.stageSelector.addBackdropFromFile": "העלו רקע",
    "gui.modal.back": "אחורה",
    "gui.library.filterPlaceholder": "חיפוש",
    "gui.libraryTags.all": "הכל",
    "gui.libraryTags.animals": "חיות",
    "gui.libraryTags.dance": "ריקוד",
    "gui.libraryTags.effects": "אפקטים",
    "gui.libraryTags.fantasy": "פנטסיה",
    "gui.libraryTags.fashion": "אופנה",
    "gui.libraryTags.food": "אוכל",
    "gui.libraryTags.indoors": "בפנים",
    "gui.libraryTags.loops": "לולאות",
    "gui.libraryTags.music": "מוזיקה",
    "gui.libraryTags.notes": "רשימות",
    "gui.libraryTags.outdoors": "בחוץ",
    "gui.libraryTags.patterns": "תבניות",
    "gui.libraryTags.people": "אנשים",
    "gui.libraryTags.percussion": "כלי הקשה",
    "gui.libraryTags.space": "חלל",
    "gui.libraryTags.sports": "ספורט",
    "gui.libraryTags.underwater": "תת-מימי",
    "gui.libraryTags.voice": "קול",
    "gui.libraryTags.wacky": "משוגע",
    "gui.libraryTags.animation": "הנפשה",
    "gui.libraryTags.art": "אומנות",
    "gui.libraryTags.games": "משחקים",
    "gui.libraryTags.stories": "סיפורים",
    "gui.libraryTags.letters": "אותיות",
    "gui.soundLibrary.chooseASound": "בחרו צליל",
    "gui.SpriteInfo.spritePlaceholder": "שם",
    "gui.cards.more-things-to-try": "עוד דברים לנסות!",
    "gui.cards.see-more": "ראו עוד",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "סגירה",
    "gui.loader.message1": "יצירת לבנים …",
    "gui.loader.message2": "טוען דמויות …",
    "gui.loader.message3": "טוען צלילים …",
    "gui.loader.message4": "טוען הרחבות …",
    "gui.loader.message5": "מבריש את פרוות החתולים …",
    "gui.loader.message6": "מעביר ננואים ...",
    "gui.loader.message7": "מנפח גובואים …",
    "gui.loader.message8": "מכין אימוג'ים ...",
    "gui.loader.headline": "העלו פרוייקט",
    "gui.cameraModal.cameraModalTitle": "צלמו תמונה",
    "gui.cameraModal.loadingCameraMessage": "טוען מצלמה...",
    "gui.cameraModal.permissionRequest": "אנחנו צריכים את רשותך כדי להשתמש במצלמה שלך",
    "gui.cameraModal.retakePhoto": "צלמו תמונה מחדש",
    "gui.cameraModal.save": "שמירה",
    "gui.cameraModal.takePhoto": "צלמו תמונה",
    "gui.cameraModal.loadingCaption": "טוען...",
    "gui.cameraModal.enableCameraCaption": "אפשר מצלמה",
    "gui.recordModal.title": "הקליטו צליל",
    "gui.playbackStep.stopMsg": "עצירה",
    "gui.playbackStep.playMsg": "נגינה",
    "gui.playbackStep.loadingMsg": "טוען...",
    "gui.playbackStep.saveMsg": "שמירה",
    "gui.playbackStep.reRecordMsg": "הקליטו מחדש",
    "gui.webglModal.label": "הדפדפן שלך אינו תומך ב־WebGL",
    "gui.webglModal.webgllink": "אינו תומך ב־WebGL",
    "gui.prompt.cancel": "ביטול",
    "gui.prompt.ok": "אישור",
    "gui.customProcedures.addAnInputNumberText": "הוסיפו קלט",
    "gui.customProcedures.addAnInputBoolean": "הוסיפו קלט",
    "gui.customProcedures.numberTextType": "מספר או טקסט",
    "gui.customProcedures.booleanType": "בוליאני",
    "gui.customProcedures.addALabel": "הוסיפו תווית",
    "gui.customProcedures.runWithoutScreenRefresh": "הפעלה ללא רענון מסך",
    "gui.customProcedures.cancel": "ביטול",
    "gui.customProcedures.ok": "אישור",
    "gui.extension.microbit.description": "חברו את הפרויקטים שלכם לעולם",
    "gui.extension.music.name": "מוזיקה",
    "gui.extension.music.description": "נגנו בכלי נגינה ובתופים",
    "gui.extension.pen.name": "עט",
    "gui.extension.pen.description": "ציירו עם הדמות שלכם",
    "gui.gui.variableScopeOptionAllSprites": "לכל הדמויות",
    "gui.gui.variableScopeOptionSpriteOnly": "רק לדמות זו",
    "gui.gui.variablePromptAllSpritesMessage": "המשתנה הזה יהיה זמין לכל הדמויות",
    "gui.monitor.contextMenu.default": "תצוגה רגילה",
    "gui.monitor.contextMenu.large": "תצוגה גדולה",
    "gui.monitor.contextMenu.slider": "סרגל גרירה",
    "gui.monitor.contextMenu.import": "יבוא",
    "gui.monitor.contextMenu.export": "יצוא",
    "gui.opcodeLabels.direction": "כיוון",
    "gui.opcodeLabels.xposition": "מיקום על ציר x",
    "gui.opcodeLabels.yposition": "מיקום על ציר y",
    "gui.opcodeLabels.size": "גודל",
    "gui.opcodeLabels.costumename": "שם תלבושת",
    "gui.opcodeLabels.costumenumber": "מספר תלבושת",
    "gui.opcodeLabels.backdropname": "שם רקע",
    "gui.opcodeLabels.backdropnumber": "מספר רקע",
    "gui.opcodeLabels.volume": "עוצמה",
    "gui.opcodeLabels.tempo": "קצב",
    "gui.opcodeLabels.answer": "תשובה",
    "gui.opcodeLabels.loudness": "קולניות",
    "gui.opcodeLabels.year": "שנה",
    "gui.opcodeLabels.month": "חודש",
    "gui.opcodeLabels.date": "תאריך",
    "gui.opcodeLabels.dayofweek": "יום בשבוע",
    "gui.opcodeLabels.hour": "שעה",
    "gui.opcodeLabels.minute": "דקה",
    "gui.opcodeLabels.second": "שניה",
    "gui.opcodeLabels.timer": "שעון עצר",
    "music.categoryName": "מוזיקה",
    "translate.categoryName": "תרגם",
    "pen.categoryName": "עט",
    "pen.changeColorParam": "שנה [COLOR_PARAM] עט ב[VALUE]",
    "pen.changeHue": "שנה צבע עט ב[HUE]",
    "pen.changeShade": "שנה הצללת עט ב[SHADE]",
    "pen.changeSize": "שנה גודל עט ב[SIZE]",
    "pen.clear": "מחק הכל",
    "pen.colorMenu.brightness": "בהירות",
    "pen.colorMenu.color": "צבע",
    "pen.colorMenu.saturation": "רווי צבע",
    "pen.colorMenu.transparency": "שקיפות",
    "pen.penDown": "עט מטה",
    "pen.penUp": "עט מעלה",
    "pen.setColor": "קבע צבע עט ל[COLOR]",
    "pen.setColorParam": "קבע [COLOR_PARAM] עט ל[VALUE]",
    "pen.setHue": "קבע צבע עט ל[HUE]",
    "pen.setShade": "קבע הצללת עט ל[SHADE]",
    "pen.setSize": "קבע גודל עט ל[SIZE]",
    "pen.stamp": "חתום",
    "music.changeTempo": "שנה קצב ב [TEMPO]",
    "music.drumBass": "(2) תוף בס",
    "music.drumBongo": "(13) בונגו",
    "music.drumCabasa": "(15) קבסה",
    "music.drumClaves": "(9) מקלות הקשה",
    "music.drumClosedHiHat": "(6) מצילה סגורה",
    "music.drumConga": "(14) קונגה",
    "music.drumCowbell": "(11) פעמון פרה",
    "music.drumCrashCymbal": "(4) מצילתיים",
    "music.drumCuica": "(18) קויקה",
    "music.drumGuiro": "(16) גווירו",
    "music.drumHandClap": "(8) מחיאת כף",
    "music.drumOpenHiHat": "(5) מצילה פתוחה",
    "music.drumSideStick": "(3) מקל על צד התוף",
    "music.drumSnare": "(1) תוף סנר",
    "music.drumTambourine": "(7) תוף מרים",
    "music.drumTriangle": "(12) משולש",
    "music.drumVibraslap": "(17) ויברסלאפ",
    "music.drumWoodBlock": "(10) לבנת עץ",
    "music.getTempo": "קצב",
    "music.instrumentBass": "(6) בס",
    "music.instrumentBassoon": "(14) בסון",
    "music.instrumentCello": "(8) צ'לו",
    "music.instrumentChoir": "(15) מקהלה",
    "music.instrumentClarinet": "(10) קלרינט",
    "music.instrumentElectricGuitar": "(5) גיטרה חשמלית",
    "music.instrumentElectricPiano": "(2) פסנתר חשמלי",
    "music.instrumentFlute": "(12) חליל",
    "music.instrumentGuitar": "(4) גיטרה",
    "music.instrumentMarimba": "(19) מרימבה",
    "music.instrumentMusicBox": "(17) תיבת נגינה ",
    "music.instrumentOrgan": "(3) אורגן",
    "music.instrumentPiano": "(1) פסנתר",
    "music.instrumentPizzicato": "(7) פיציקטו",
    "music.instrumentSaxophone": "(11) סקסופון",
    "music.instrumentSteelDrum": "(18) תוף פלדה",
    "music.instrumentSynthLead": "(20) סינתיסייזר לד",
    "music.instrumentSynthPad": "(21) סינתיסייזר פאד",
    "music.instrumentTrombone": "(9) טרומבון",
    "music.instrumentVibraphone": "(16) ויברפון",
    "music.instrumentWoodenFlute": "(13) חליל עץ",
    "music.midiPlayDrumForBeats": "נגן בתוף [DRUM] למשך [BEATS] פעימות",
    "music.midiSetInstrument": "קביעת כלי נגינה ל[INSTRUMENT]",
    "music.playDrumForBeats": "נגן בתוף [DRUM] למשך [BEATS] פעימות",
    "music.playNoteForBeats": "נגן תו [NOTE] במשך [BEATS] פעימות",
    "music.restForBeats": "נוח למשך [BEATS] פעימות",
    "music.setInstrument": "קבע כלי נגינה ל [INSTRUMENT]",
    "music.setTempo": "קבע קצב ל [TEMPO]",
    "videoSensing.categoryName": "חיישן מצלמה",
    "videoSensing.direction": "כיוון",
    "videoSensing.motion": "תנועה",
    "videoSensing.off": "כבה",
    "videoSensing.on": "הפעל",
    "videoSensing.onFlipped": "הפעל כתמונת ראי",
    "videoSensing.setVideoTransparency": "קבע שקיפות מצלמה ל [TRANSPARENCY]",
    "videoSensing.sprite": "דמות",
    "videoSensing.stage": "במה",
    "videoSensing.videoOn": "[ATTRIBUTE] של המצלמה על [SUBJECT]",
    "videoSensing.videoToggle": "[VIDEO_STATE] את המצלמה",
    "videoSensing.whenMotionGreaterThan": "כאשר התנועה במצלמה > [REFERENCE]",
    "translate.translateBlock": "תרגם[WORDS] ל[LANGUAGE]",
    "translate.defaultTextToTranslate": "שלום",
    "translate.viewerLanguage": "שפה",
    "text2speech.speakAndWaitBlock": "אמור [WORDS]",
    "text2speech.setVoiceBlock": "קבע קול ל־[VOICE]",
    "text2speech.setLanguageBlock": "קביעת שפה ל[LANGUAGE]",
    "text2speech.alto": "אלט",
    "text2speech.tenor": "טנור",
    "text2speech.squeak": "ציוץ",
    "text2speech.giant": "ענק",
    "text2speech.kitten": "חתלתול",
    "text2speech.defaultTextToSpeak": "שלום",
    "speech.whenIHear": "כאשר אני שומע [PHRASE]",
    "speech.listenAndWait": "הקשב וחכה",
    "speech.defaultWhenIHearValue": "קדימה!",
    "paint.paintEditor.hue": "צבע",
    "paint.paintEditor.saturation": "רוויה",
    "paint.paintEditor.brightness": "בהירות",
    "gui.comingSoon.message1": "אל דאגה, אנחנו מטפלים בזה {emoji}",
    "gui.comingSoon.message2": "בקרוב...",
    "gui.comingSoon.message3": "אנחנו עובדים על זה {emoji}",
    "paint.paintEditor.fill": "מילוי",
    "paint.paintEditor.costume": "תלבושת",
    "paint.paintEditor.group": "קבץ",
    "paint.paintEditor.ungroup": "בטל קיבוץ",
    "paint.paintEditor.undo": "בטל",
    "paint.paintEditor.redo": "בצע שוב",
    "paint.paintEditor.forward": "קדימה",
    "paint.paintEditor.backward": "אחורה",
    "paint.paintEditor.front": "העבר לקידמה",
    "paint.paintEditor.back": "העבר לרקע",
    "paint.paintEditor.more": "עוד",
    "paint.modeTools.brushSize": "גודל",
    "paint.modeTools.eraserSize": "גודל מחק",
    "paint.modeTools.copy": "העתק",
    "paint.modeTools.paste": "הדבק",
    "paint.modeTools.delete": "מחק",
    "paint.modeTools.curved": "מעוקל",
    "paint.modeTools.pointed": "מחודד",
    "paint.modeTools.thickness": "עובי",
    "paint.modeTools.flipHorizontal": "הפוך אופקית",
    "paint.modeTools.flipVertical": "הפוך אנכית",
    "paint.modeTools.filled": "מלא",
    "paint.modeTools.outlined": "מסורטט",
    "paint.paintEditor.bitmap": "המרה למפת סיביות",
    "paint.paintEditor.vector": "המרה לוקטור",
    "paint.paintEditor.stroke": "מסגרת",
    "paint.brushMode.brush": "מברשת",
    "paint.eraserMode.eraser": "מחק",
    "paint.fillMode.fill": "מילוי",
    "paint.lineMode.line": "קו",
    "paint.ovalMode.oval": "עיגול",
    "paint.rectMode.rect": "מלבן",
    "paint.reshapeMode.reshape": "שנה צורה",
    "paint.roundedRectMode.roundedRect": "מלבן מעוגל",
    "paint.selectMode.select": "בחר",
    "paint.textMode.text": "טקסט",
    "paint.colorPicker.swap": "החלף",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "hr": {
    "gui.backpack.header": "Ruksak",
    "gui.backpack.errorBackpack": "Pogreška pri učitavanju",
    "gui.backpack.loadingBackpack": "Učitavanje...",
    "gui.backpack.more": "Više",
    "gui.backpack.emptyBackpack": "Ruksak je prazan",
    "gui.gui.costumesTab": "Kostimi",
    "gui.gui.soundsTab": "Zvukovi",
    "gui.gui.backdropsTab": "Pozadine",
    "gui.gui.addExtension": "Dodaj proširenje",
    "gui.costumeTab.addCostumeFromLibrary": "Odaberi kostim",
    "gui.costumeLibrary.chooseACostume": "Odaberi kostim",
    "gui.costumeTab.addBackdropFromLibrary": "Odaberi pozadinu",
    "gui.costumeTab.addBlankCostume": "Naslikaj",
    "gui.costumeTab.addSurpriseCostume": "Iznenađenje",
    "gui.costumeTab.addFileBackdrop": "Učitaj pozadinu",
    "gui.costumeTab.addFileCostume": "Prenesi kostim",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Izreži",
    "gui.soundEditor.stop": "Zaustavi",
    "gui.soundEditor.sound": "Zvuk",
    "gui.soundEditor.play": "Pokreni",
    "gui.soundEditor.save": "Spremi",
    "gui.soundEditor.undo": "Poništi",
    "gui.soundEditor.redo": "Ponovi",
    "gui.soundEditor.faster": "Brže",
    "gui.soundEditor.slower": "Sporije",
    "gui.soundEditor.echo": "Jeka",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Glasnije",
    "gui.soundEditor.softer": "Blaže",
    "gui.soundEditor.reverse": "Obrnuto",
    "gui.soundTab.recordSound": "Snimi",
    "gui.soundTab.addSoundFromLibrary": "Odaberi zvuk",
    "gui.soundTab.surpriseSound": "Iznenađenje",
    "gui.soundTab.fileUploadSound": "Učitaj zvuk",
    "gui.controls.stop": "Zaustavi",
    "gui.controls.go": "Počni",
    "gui.stageHeader.stageSizeUnFull": "Izađite iz cijelog zaslona",
    "gui.SpriteInfo.show": "Prikaži",
    "gui.SpriteInfo.size": "Veličina",
    "gui.directionPicker.rotationStyles.allAround": "Na sve strane",
    "gui.directionPicker.rotationStyles.leftRight": "Lijevo/Desno",
    "gui.directionPicker.rotationStyles.dontRotate": "Bez okretanja",
    "gui.spriteSelectorItem.contextMenuDuplicate": "Dupliciraj",
    "gui.spriteSelectorItem.contextMenuDelete": "obriši",
    "gui.spriteSelectorItem.contextMenuExport": "izvoz",
    "gui.spriteSelector.addSpriteFromPaint": "Naslikaj",
    "gui.spriteSelector.addSpriteFromSurprise": "Iznenađenje",
    "gui.spriteSelector.addSpriteFromFile": "Učitaj lik",
    "gui.stageSelector.stage": "Pozornica",
    "gui.stageSelector.backdrops": "Pozadine",
    "gui.stageSelector.addBackdropFromPaint": "Naslikaj",
    "gui.stageSelector.addBackdropFromSurprise": "Iznenađenje",
    "gui.stageSelector.addBackdropFromFile": "Učitaj pozadinu",
    "gui.modal.back": "Nazad",
    "gui.library.filterPlaceholder": "Traži",
    "gui.libraryTags.all": "Sve",
    "gui.libraryTags.animals": "Životinje",
    "gui.libraryTags.dance": "Ples",
    "gui.libraryTags.effects": "Efekti",
    "gui.libraryTags.fantasy": "Fantazija",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Hrana",
    "gui.libraryTags.indoors": "Unutra",
    "gui.libraryTags.loops": "Petlje",
    "gui.libraryTags.music": "Glazba",
    "gui.libraryTags.notes": "Bilješke",
    "gui.libraryTags.outdoors": "Vani",
    "gui.libraryTags.patterns": "Uzorak",
    "gui.libraryTags.people": "Ljudi",
    "gui.libraryTags.percussion": "Udaraljke",
    "gui.libraryTags.space": "Prostor",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Podvodni",
    "gui.libraryTags.voice": "Glas",
    "gui.libraryTags.wacky": "Ćaknut",
    "gui.libraryTags.animation": "Animacija",
    "gui.libraryTags.art": "Umjetnost",
    "gui.libraryTags.games": "Igre",
    "gui.libraryTags.stories": "Priče",
    "gui.libraryTags.letters": "Slova",
    "gui.soundLibrary.chooseASound": "Odaberi zvuk",
    "gui.SpriteInfo.spritePlaceholder": "Ime",
    "gui.cards.more-things-to-try": "Isprobaj i ovo!",
    "gui.cards.see-more": "Vidi više",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Zatvori",
    "gui.loader.message1": "Izrada blokova ...",
    "gui.loader.message2": "Učitavanje likova...",
    "gui.loader.message3": "Učitavanje zvuka...",
    "gui.loader.message4": "Učitavanje proširenja ...",
    "gui.loader.message5": "Udomljavanje mačaka ...",
    "gui.loader.message6": "Prijenos nano ...",
    "gui.loader.message7": "Napuhavanje gobova ...",
    "gui.loader.message8": "Pripremam smajliće ...",
    "gui.loader.headline": "Učitavanje projekta",
    "gui.cameraModal.cameraModalTitle": "Snimite fotografiju",
    "gui.cameraModal.loadingCameraMessage": "Učitavanje kamere...",
    "gui.cameraModal.permissionRequest": "Trebamo Vašu dozvolu za korištenje kamere",
    "gui.cameraModal.retakePhoto": "Ponovi snimanje fotografije",
    "gui.cameraModal.save": "Spremi",
    "gui.cameraModal.takePhoto": "Uslikaj",
    "gui.cameraModal.loadingCaption": "Učitavanje...",
    "gui.cameraModal.enableCameraCaption": "Omogućite kameru",
    "gui.recordModal.title": "Snimi zvuk",
    "gui.playbackStep.stopMsg": "Zaustavi",
    "gui.playbackStep.playMsg": "Pokreni",
    "gui.playbackStep.loadingMsg": "Učitavam...",
    "gui.playbackStep.saveMsg": "Spremi",
    "gui.playbackStep.reRecordMsg": "Snimi ponovno",
    "gui.webglModal.label": "Preglednik ne podržava WebGL",
    "gui.webglModal.webgllink": "ne podržava WebGL",
    "gui.prompt.cancel": "Otkaži",
    "gui.prompt.ok": "U redu",
    "gui.customProcedures.addAnInputNumberText": "Dodaj ulaz",
    "gui.customProcedures.addAnInputBoolean": "Dodaj ulaz",
    "gui.customProcedures.numberTextType": "broj ili tekst",
    "gui.customProcedures.booleanType": "bulov izraz",
    "gui.customProcedures.addALabel": "Dodajte natpis",
    "gui.customProcedures.runWithoutScreenRefresh": "Izvršite bez osvježavanja zaslona",
    "gui.customProcedures.cancel": "Otkaži",
    "gui.customProcedures.ok": "U redu",
    "gui.extension.microbit.description": "Poveži svoj projekt sa svijetom.",
    "gui.extension.music.name": "Glazba",
    "gui.extension.music.description": "Sviraj na instrumentima i bubnjevima.",
    "gui.extension.pen.name": "Olovka",
    "gui.extension.pen.description": "Crtaj pomoću likova.",
    "gui.gui.variableScopeOptionAllSprites": "Za sve likove",
    "gui.gui.variableScopeOptionSpriteOnly": "Samo za ovaj lik",
    "gui.gui.variablePromptAllSpritesMessage": "Ova će varijabla biti dostupna svim likovima.",
    "gui.monitor.contextMenu.default": "normalni prikaz",
    "gui.monitor.contextMenu.large": "veliki prikaz",
    "gui.monitor.contextMenu.slider": "klizač",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "smjer",
    "gui.opcodeLabels.xposition": "x položaj",
    "gui.opcodeLabels.yposition": "y položaj",
    "gui.opcodeLabels.size": "veličina",
    "gui.opcodeLabels.costumename": "naziv kostima",
    "gui.opcodeLabels.costumenumber": "Broj kostima",
    "gui.opcodeLabels.backdropname": "naziv pozadine",
    "gui.opcodeLabels.backdropnumber": "Broj pozadine",
    "gui.opcodeLabels.volume": "jačina zvuka",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "odgovor",
    "gui.opcodeLabels.loudness": "glasnoća",
    "gui.opcodeLabels.year": "godina",
    "gui.opcodeLabels.month": "mjesec",
    "gui.opcodeLabels.date": "dan",
    "gui.opcodeLabels.dayofweek": "dan u tjednu",
    "gui.opcodeLabels.hour": "sat",
    "gui.opcodeLabels.minute": "minuta",
    "gui.opcodeLabels.second": "sekunda",
    "gui.opcodeLabels.timer": "štoperica",
    "music.categoryName": "Glazba",
    "translate.categoryName": "Prevedi",
    "pen.categoryName": "Olovka",
    "pen.changeColorParam": "promijeni [COLOR_PARAM] olovke za [VALUE]",
    "pen.changeHue": "promijeni boju olovke za [HUE]",
    "pen.changeShade": "promjeni sjenu olovke za [SHADE]",
    "pen.changeSize": "promijeni debljinu olovke za [SIZE]",
    "pen.clear": "izbriši sve",
    "pen.colorMenu.brightness": "osvjetljenje",
    "pen.colorMenu.color": "boja",
    "pen.colorMenu.saturation": "saturacija",
    "pen.colorMenu.transparency": "providnost",
    "pen.penDown": "spusti olovku",
    "pen.penUp": "podigni olovku",
    "pen.setColor": "postavi boju olovke na [COLOR]",
    "pen.setColorParam": "postavi [COLOR_PARAM] olovke na [VALUE]",
    "pen.setHue": "postavi boju olovke na [HUE]",
    "pen.setShade": "postavi sjenu olovke na [SHADE]",
    "pen.setSize": "postavi debljinu olovke na [SIZE]",
    "pen.stamp": "žig",
    "music.changeTempo": "promijeni tempo za [TEMPO]",
    "music.drumBass": "(2) Bass bubanj",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Drveni štapići",
    "music.drumClosedHiHat": "(6) Zatvorene činele",
    "music.drumConga": "(14) Konga",
    "music.drumCowbell": "(11) Kravlje zvonce",
    "music.drumCrashCymbal": "(4) Crash činele",
    "music.drumCuica": "(18) Cuica bubanj",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Pljesak",
    "music.drumOpenHiHat": "(5) Otvorene činele",
    "music.drumSideStick": "(3) Side Stick zvuk",
    "music.drumSnare": "(1) Mali bubanj",
    "music.drumTambourine": "(7) Tamburin",
    "music.drumTriangle": "(12) Triangl",
    "music.drumVibraslap": "(17) Vibrafon",
    "music.drumWoodBlock": "(10) Drveni block",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bas",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Violončelo",
    "music.instrumentChoir": "(15) Zbor",
    "music.instrumentClarinet": "(10) Klarinet",
    "music.instrumentElectricGuitar": "(5) Električna gitara",
    "music.instrumentElectricPiano": "(2) Električni piano",
    "music.instrumentFlute": "(12) Flauta",
    "music.instrumentGuitar": "(4) Gitara",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Muzička kutijica",
    "music.instrumentOrgan": "(3) Orgulje",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saksofon",
    "music.instrumentSteelDrum": "(18) Čelični bubanj",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Drvena flauta",
    "music.midiPlayDrumForBeats": "bubnjaj [DRUM] [BEATS] puta",
    "music.midiSetInstrument": "postavi instrument na [INSTRUMENT]",
    "music.playDrumForBeats": "bubnjaj [DRUM] [BEATS] puta",
    "music.playNoteForBeats": "sviraj ton [NOTE] [BEATS] puta",
    "music.restForBeats": "preskoči [BEATS] puta",
    "music.setInstrument": "postavi instrument na [INSTRUMENT]",
    "music.setTempo": "postavi tempo na [TEMPO]",
    "videoSensing.categoryName": "Video",
    "videoSensing.direction": "smjer",
    "videoSensing.motion": "gibanje",
    "videoSensing.off": "isključeno",
    "videoSensing.on": "uključeno",
    "videoSensing.onFlipped": "uključeno zrcaljenje",
    "videoSensing.setVideoTransparency": "postavi providnost videa na [TRANSPARENCY]",
    "videoSensing.sprite": "lik",
    "videoSensing.stage": "pozornica",
    "videoSensing.videoOn": "video [ATTRIBUTE] na [SUBJECT]",
    "videoSensing.videoToggle": "postavi video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "kada je gibanje videa > [REFERENCE]",
    "translate.translateBlock": "prevedi [WORDS] na [LANGUAGE]",
    "translate.defaultTextToTranslate": "Bok",
    "translate.viewerLanguage": "jezik",
    "text2speech.speakAndWaitBlock": "govori [WORDS]",
    "text2speech.setVoiceBlock": "postavi glas na [VOICE]",
    "text2speech.setLanguageBlock": "postavi jezik na [LANGUAGE]",
    "text2speech.alto": "alt",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "cviljenje",
    "text2speech.giant": "div",
    "text2speech.kitten": "mačić",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "kada čujem [PHRASE]",
    "speech.listenAndWait": "slušaj i čekaj",
    "speech.defaultWhenIHearValue": "Krenimo",
    "paint.paintEditor.hue": "Boja",
    "paint.paintEditor.saturation": "Saturacija",
    "paint.paintEditor.brightness": "Osvjetljenje",
    "gui.comingSoon.message1": "Ne brini, radimo na tome{emoji}",
    "gui.comingSoon.message2": "Pričekajte trenutak...",
    "gui.comingSoon.message3": "Radimo na tome{emoji}",
    "paint.paintEditor.fill": "Ispuna",
    "paint.paintEditor.costume": "Kostim",
    "paint.paintEditor.group": "Grupiraj",
    "paint.paintEditor.ungroup": "Razgrupiraj",
    "paint.paintEditor.undo": "Poništi",
    "paint.paintEditor.redo": "Ponovi",
    "paint.paintEditor.forward": "Naprijed",
    "paint.paintEditor.backward": "Natrag",
    "paint.paintEditor.front": "prednji",
    "paint.paintEditor.back": "Stražnji",
    "paint.paintEditor.more": "Više",
    "paint.modeTools.brushSize": "Veličina",
    "paint.modeTools.eraserSize": "Debljina gumice",
    "paint.modeTools.copy": "Kopiraj",
    "paint.modeTools.paste": "Zalijepi",
    "paint.modeTools.delete": "Izbriši",
    "paint.modeTools.curved": "Zaobljen",
    "paint.modeTools.pointed": "Šiljast",
    "paint.modeTools.thickness": "Debljina",
    "paint.modeTools.flipHorizontal": "Obrni vodoravno",
    "paint.modeTools.flipVertical": "Obrni okomito",
    "paint.modeTools.filled": "Ispunjeno",
    "paint.modeTools.outlined": "Obrubljeno",
    "paint.paintEditor.bitmap": "Pretvori u mapu bitova",
    "paint.paintEditor.vector": "Pretvori u vektor",
    "paint.paintEditor.stroke": "Obrub",
    "paint.brushMode.brush": "Kist",
    "paint.eraserMode.eraser": "Gumica",
    "paint.fillMode.fill": "Ispuna",
    "paint.lineMode.line": "Linija",
    "paint.ovalMode.oval": "Kružnica",
    "paint.rectMode.rect": "Pravokutnik",
    "paint.reshapeMode.reshape": "Preoblikuj",
    "paint.roundedRectMode.roundedRect": "Pravokutnik",
    "paint.selectMode.select": "Označi",
    "paint.textMode.text": "Tekst",
    "paint.colorPicker.swap": "Zamijeni",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "zu": {
    "gui.backpack.header": "Isikhwama",
    "gui.backpack.errorBackpack": "Kukhona okungalungile ekufakeni ibackpack",
    "gui.backpack.loadingBackpack": "Kusangena...",
    "gui.backpack.more": "Okunye",
    "gui.backpack.emptyBackpack": "iBackpack ayinalutho",
    "gui.gui.costumesTab": "Izimpahla",
    "gui.gui.soundsTab": "Imisindo",
    "gui.gui.backdropsTab": "Izindawo",
    "gui.gui.addExtension": "Ukulula",
    "gui.costumeTab.addCostumeFromLibrary": "Khetha impahla",
    "gui.costumeLibrary.chooseACostume": "Khetha impahla",
    "gui.costumeTab.addBackdropFromLibrary": "Khetha Indawo",
    "gui.costumeTab.addBlankCostume": "Penda",
    "gui.costumeTab.addSurpriseCostume": "Isimanga",
    "gui.costumeTab.addFileBackdrop": "Faka Indawo",
    "gui.costumeTab.addFileCostume": "Faka Impahla",
    "gui.costumeTab.addCameraCostume": "Ikhamera",
    "gui.soundEditor.trim": "Cwecwa ngobunono",
    "gui.soundEditor.stop": "Ima",
    "gui.soundEditor.sound": "Umsindo",
    "gui.soundEditor.play": "Dlala",
    "gui.soundEditor.save": "Gcina",
    "gui.soundEditor.undo": "Yenza kabusha",
    "gui.soundEditor.redo": "Ukwenzakabusha",
    "gui.soundEditor.faster": "Shesha",
    "gui.soundEditor.slower": "Kacane",
    "gui.soundEditor.echo": "Ukunanela",
    "gui.soundEditor.robot": "Robhothi",
    "gui.soundEditor.louder": "Kakhulu",
    "gui.soundEditor.softer": "Kuthambile",
    "gui.soundEditor.reverse": "Hlehlisa emumva",
    "gui.soundTab.recordSound": "Qopha",
    "gui.soundTab.addSoundFromLibrary": "Khetha Umsindo",
    "gui.soundTab.surpriseSound": "Isimanga",
    "gui.soundTab.fileUploadSound": "Faka Umsindo",
    "gui.controls.stop": "Ima",
    "gui.controls.go": "Hamba",
    "gui.stageHeader.stageSizeUnFull": "Phma kwindlela elula iskhrini sibe sikhulu",
    "gui.SpriteInfo.show": "Khombisa",
    "gui.SpriteInfo.size": "Isisindo",
    "gui.directionPicker.rotationStyles.allAround": "Yonke indawo",
    "gui.directionPicker.rotationStyles.leftRight": "isinxele-nangakwesokudla",
    "gui.directionPicker.rotationStyles.dontRotate": "Ungayiphenduli",
    "gui.spriteSelectorItem.contextMenuDuplicate": "fanisa",
    "gui.spriteSelectorItem.contextMenuDelete": "khipha",
    "gui.spriteSelectorItem.contextMenuExport": "isithumela",
    "gui.spriteSelector.addSpriteFromPaint": "Penda",
    "gui.spriteSelector.addSpriteFromSurprise": "Isimanga",
    "gui.spriteSelector.addSpriteFromFile": "Faka Umlingisi",
    "gui.stageSelector.stage": "Isigaba",
    "gui.stageSelector.backdrops": "Izindawo",
    "gui.stageSelector.addBackdropFromPaint": "Penda",
    "gui.stageSelector.addBackdropFromSurprise": "Isimanga",
    "gui.stageSelector.addBackdropFromFile": "Faka Indawo",
    "gui.modal.back": "Emuva",
    "gui.library.filterPlaceholder": "Cinga",
    "gui.libraryTags.all": "Yonke",
    "gui.libraryTags.animals": "Izilwane",
    "gui.libraryTags.dance": "Dansa",
    "gui.libraryTags.effects": "Imithelela",
    "gui.libraryTags.fantasy": "Uphupha/Ubuhle",
    "gui.libraryTags.fashion": "Imfashini",
    "gui.libraryTags.food": "Ukudla",
    "gui.libraryTags.indoors": "Ngaphakathi",
    "gui.libraryTags.loops": "Amaluphu",
    "gui.libraryTags.music": "uMculo",
    "gui.libraryTags.notes": "Amanothi",
    "gui.libraryTags.outdoors": "Ngaphandle",
    "gui.libraryTags.patterns": "Iphethini",
    "gui.libraryTags.people": "Abantu",
    "gui.libraryTags.percussion": "Idilamu Lomculo",
    "gui.libraryTags.space": "Isikhala",
    "gui.libraryTags.sports": "Imidlalo",
    "gui.libraryTags.underwater": "Ngaphansi kwamanzi",
    "gui.libraryTags.voice": "Izwi",
    "gui.libraryTags.wacky": "Engasile ",
    "gui.libraryTags.animation": "Khathuni",
    "gui.libraryTags.art": "Imidwebo",
    "gui.libraryTags.games": "Umdlalo",
    "gui.libraryTags.stories": "Izindaba",
    "gui.libraryTags.letters": "Izinhlamvu",
    "gui.soundLibrary.chooseASound": "Khetha Umsindo",
    "gui.SpriteInfo.spritePlaceholder": "Igama",
    "gui.cards.more-things-to-try": "Okunye okuningi eningakuzama",
    "gui.cards.see-more": "Buka okunye",
    "gui.cards.shrink": "Nciphisa",
    "gui.cards.expand": "Kwandise ",
    "gui.cards.close": "Vala",
    "gui.loader.message1": "Asakheni amabhulokisi ...",
    "gui.loader.message2": "Kungena umlingisi ...",
    "gui.loader.message3": "Kungena umsindo ...",
    "gui.loader.message4": "Isaloda ukulula ...",
    "gui.loader.message5": "Herding cats ...",
    "gui.loader.message6": "Transmitting nanos ...",
    "gui.loader.message7": "Inflating gobos ...",
    "gui.loader.message8": "Silungisa amaEmojis ...",
    "gui.loader.headline": "Kungena iProject",
    "gui.cameraModal.cameraModalTitle": "Thatha isthombe",
    "gui.cameraModal.loadingCameraMessage": "Kusangena isithwebuli...",
    "gui.cameraModal.permissionRequest": "Sidinga imvume yakho ukusebenzisa ikhamera",
    "gui.cameraModal.retakePhoto": "Phinda uthathe isthombe",
    "gui.cameraModal.save": "Gcina",
    "gui.cameraModal.takePhoto": "Thatha Isthombe",
    "gui.cameraModal.loadingCaption": "Kusangena...",
    "gui.cameraModal.enableCameraCaption": "Vumela ikhamera",
    "gui.recordModal.title": "Rekhoda umsindo",
    "gui.playbackStep.stopMsg": "Ima",
    "gui.playbackStep.playMsg": "Dlala",
    "gui.playbackStep.loadingMsg": "Kusangena...",
    "gui.playbackStep.saveMsg": "Gcina",
    "gui.playbackStep.reRecordMsg": "Phinda urekhode",
    "gui.webglModal.label": "iBrowser yakho ayisekeli iWebGL",
    "gui.webglModal.webgllink": "Ayisekeli iWebGL",
    "gui.prompt.cancel": "Cima",
    "gui.prompt.ok": "Kulungile",
    "gui.customProcedures.addAnInputNumberText": "Nezezela ngegalelo",
    "gui.customProcedures.addAnInputBoolean": "Nezezela ngegalelo",
    "gui.customProcedures.numberTextType": "Inamba noma umbhalo",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Nezezela ngelabel",
    "gui.customProcedures.runWithoutScreenRefresh": "Baleka ngaphandle kokuvuselela isikrini",
    "gui.customProcedures.cancel": "Cima",
    "gui.customProcedures.ok": "Kulungile",
    "gui.extension.microbit.description": "Xhumanisa amaProject akho nomhlaba",
    "gui.extension.music.name": "uMculo",
    "gui.extension.music.description": "Dlala isikhali kanye nam dilamu",
    "gui.extension.pen.name": "Ipeni",
    "gui.extension.pen.description": "Dweba nabalingisibakho",
    "gui.gui.variableScopeOptionAllSprites": "Ngabo bonke omlingisi",
    "gui.gui.variableScopeOptionSpriteOnly": "Ngalomlingsi kuphela",
    "gui.gui.variablePromptAllSpritesMessage": "Lokuguquka kwenzeke kubo bonke",
    "gui.monitor.contextMenu.default": "okujwayelekile bomphumelo",
    "gui.monitor.contextMenu.large": "ubukhulu bomphumelo",
    "gui.monitor.contextMenu.slider": "shibilika",
    "gui.monitor.contextMenu.import": "ngenisa",
    "gui.monitor.contextMenu.export": "isithumela",
    "gui.opcodeLabels.direction": "indlela",
    "gui.opcodeLabels.xposition": "x indlela yokuma",
    "gui.opcodeLabels.yposition": "y indlela yokuma",
    "gui.opcodeLabels.size": "isisindo",
    "gui.opcodeLabels.costumename": "igama lempahla",
    "gui.opcodeLabels.costumenumber": "Inamba yempahla",
    "gui.opcodeLabels.backdropname": "igama lendawo",
    "gui.opcodeLabels.backdropnumber": "Inamba indawo",
    "gui.opcodeLabels.volume": "umsindo",
    "gui.opcodeLabels.tempo": "izinsimbi",
    "gui.opcodeLabels.answer": "phendula",
    "gui.opcodeLabels.loudness": "umsindo omkhulu",
    "gui.opcodeLabels.year": "unyaka",
    "gui.opcodeLabels.month": "inyanga",
    "gui.opcodeLabels.date": "usuku",
    "gui.opcodeLabels.dayofweek": "usuku lwesonto",
    "gui.opcodeLabels.hour": "ihora",
    "gui.opcodeLabels.minute": "umzuzu",
    "gui.opcodeLabels.second": "umzuzwana",
    "gui.opcodeLabels.timer": "iwashi elicushiwe",
    "music.categoryName": "uMculo",
    "translate.categoryName": "Humusha ",
    "pen.categoryName": "Ipeni",
    "pen.changeColorParam": "shintsha ipeni [COLOR_PARAM] nge [VALUE]",
    "pen.changeHue": "shintsha umbala wepeni nge [HUE]",
    "pen.changeShade": "shintsha ipeni usithe nge [SHADE]",
    "pen.changeSize": "shintsha usayizi wepeni ngo [SIZE]",
    "pen.clear": "kususe konke ",
    "pen.colorMenu.brightness": "ukukhanya",
    "pen.colorMenu.color": "umbala",
    "pen.colorMenu.saturation": "ukukhanya kombala ",
    "pen.colorMenu.transparency": "obala",
    "pen.penDown": "ipeni phansi",
    "pen.penUp": "ipeni phezulu",
    "pen.setColor": "hlela umbala wepeni [COLOR]",
    "pen.setColorParam": "hlela ipeni [COLOR_PARAM] uliyise kwi [VALUE]",
    "pen.setHue": "hlela umbala wepeni uwuyise [HUE]",
    "pen.setShade": "hlela ukusitha ngepeni ukuyise [SHADE]",
    "pen.setSize": "hlela ubukhulu bepeni ubuyise [SIZE]",
    "pen.stamp": "isitembu",
    "music.changeTempo": "shintsha iThempo nge [TEMPO]",
    "music.drumBass": "(2) Isigubhu esi nebhesi",
    "music.drumBongo": "(13) Bhongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Zinduku",
    "music.drumClosedHiHat": "(6) Ihi-Hat Evaliwe",
    "music.drumConga": "(14) Ikhonga",
    "music.drumCowbell": "(11) Insimbi yenkomo",
    "music.drumCrashCymbal": "(4) Crash Cymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Ihlombe",
    "music.drumOpenHiHat": "(5) Vula Hi-Hat",
    "music.drumSideStick": "(3) Induku eseceleni",
    "music.drumSnare": "(1) Idilamu Snare",
    "music.drumTambourine": "(7) Tambourine",
    "music.drumTriangle": "(12) Nxantathu",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Ikhuni amabhulokisi",
    "music.getTempo": "izinsimbi",
    "music.instrumentBass": "(6) Bhesi",
    "music.instrumentBassoon": "(14) Icilongo",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Ikwaya",
    "music.instrumentClarinet": "(10) Clarinet",
    "music.instrumentElectricGuitar": "(5) Isigingci sagesi",
    "music.instrumentElectricPiano": "(2) Upiyano wagesi",
    "music.instrumentFlute": "(12) Flute",
    "music.instrumentGuitar": "(4) Isiginci",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Ibhokisi lomculo",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) Upiyano",
    "music.instrumentPizzicato": "(7) Umculo osheshayo",
    "music.instrumentSaxophone": "(11) Saxophone",
    "music.instrumentSteelDrum": "(18) Isigubhu Sensimbi",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibraphone",
    "music.instrumentWoodenFlute": "(13) Ikhwela Flute",
    "music.midiPlayDrumForBeats": "dlala idilamu [DRUM] le [BEATS] ibhithi",
    "music.midiSetInstrument": "hlela isikhali sokusebenza [INSTRUMENT]",
    "music.playDrumForBeats": "dlala idilamu [DRUM] le [BEATS] ibhithi",
    "music.playNoteForBeats": "dlala inothi [NOTE] le [BEATS] bhithi",
    "music.restForBeats": "okuseleyo nge [BEATS] bhithi  ",
    "music.setInstrument": "hlela isikhali sokusebenza [INSTRUMENT]",
    "music.setTempo": "hlela ithempo ku [TEMPO]",
    "videoSensing.categoryName": "Ukubona ividiyo",
    "videoSensing.direction": "indlela",
    "videoSensing.motion": "ukunyakaza",
    "videoSensing.off": "cisha",
    "videoSensing.on": "ivulekile",
    "videoSensing.onFlipped": "kuphenyiwe ",
    "videoSensing.setVideoTransparency": "hlela ukubonakala kweVidiyo ukuyise [TRANSPARENCY]",
    "videoSensing.sprite": "umlingisi",
    "videoSensing.stage": "isigaba",
    "videoSensing.videoOn": "vidiyo [ATTRIBUTE] ku [SUBJECT]",
    "videoSensing.videoToggle": "phendula ividiyo [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "umakunyakaza ividiyo > [REFERENCE]",
    "translate.translateBlock": "humusha [WORDS] uyise [LANGUAGE]",
    "translate.defaultTextToTranslate": "sawubona",
    "translate.viewerLanguage": "ulimi",
    "text2speech.speakAndWaitBlock": "khuluma [WORDS]",
    "text2speech.setVoiceBlock": "hlela izwi uliyise [VOICE]",
    "text2speech.setLanguageBlock": "hlela ulimi uliyise [LANGUAGE]",
    "text2speech.alto": "I -altho",
    "text2speech.tenor": "I-thena",
    "text2speech.squeak": "Tswininiza",
    "text2speech.giant": "isiqhwaga ",
    "text2speech.kitten": "Ingane yekati",
    "text2speech.defaultTextToSpeak": "sawubona",
    "speech.whenIHear": "uma ngizwa [PHRASE]",
    "speech.listenAndWait": "lalela ulinde ",
    "speech.defaultWhenIHearValue": "asambe ",
    "paint.paintEditor.hue": "Umbala",
    "paint.paintEditor.saturation": "Ukukhanya kombala",
    "paint.paintEditor.brightness": "Ukukhanya",
    "gui.comingSoon.message1": "Ungakhazeni sesikhona siyasebenza {emoji}",
    "gui.comingSoon.message2": "Kuyeza Maduzane...",
    "gui.comingSoon.message3": "Sisebenza kona kuyimanje {emoji}",
    "paint.paintEditor.fill": "Gcwalisa",
    "paint.paintEditor.costume": "Impahla",
    "paint.paintEditor.group": "Iqembu",
    "paint.paintEditor.ungroup": "Hlukanisa",
    "paint.paintEditor.undo": "Yenza kabusha",
    "paint.paintEditor.redo": "Ukwenzakabusha",
    "paint.paintEditor.forward": "Phambili",
    "paint.paintEditor.backward": "Emuva",
    "paint.paintEditor.front": "Phambili",
    "paint.paintEditor.back": "Emuva",
    "paint.paintEditor.more": "Okunye",
    "paint.modeTools.brushSize": "Isisindo",
    "paint.modeTools.eraserSize": "Irabha ubude",
    "paint.modeTools.copy": "Khopisha",
    "paint.modeTools.paste": "Namathisela",
    "paint.modeTools.delete": "Susa",
    "paint.modeTools.curved": "lwalugobile",
    "paint.modeTools.pointed": "Iphoyinti",
    "paint.modeTools.thickness": "Ugqinsi",
    "paint.modeTools.flipHorizontal": "Phendula ngokuya emaceleni",
    "paint.modeTools.flipVertical": "Phendula ngokuya phansi naphezulu",
    "paint.modeTools.filled": "Kugcwele",
    "paint.modeTools.outlined": "Kugqamile",
    "paint.paintEditor.bitmap": "Shitsela kuBitmap",
    "paint.paintEditor.vector": "Shitsela kuVector",
    "paint.paintEditor.stroke": "Kugqamise",
    "paint.brushMode.brush": "Ibhrashi",
    "paint.eraserMode.eraser": "Irabha",
    "paint.fillMode.fill": "Gcwalisa",
    "paint.lineMode.line": "Umugqa",
    "paint.ovalMode.oval": "Indingiliza",
    "paint.rectMode.rect": "Irectangle",
    "paint.reshapeMode.reshape": "Ukubuyisele esimeni",
    "paint.roundedRectMode.roundedRect": "Indingiliza yerectangle",
    "paint.selectMode.select": "Khetha",
    "paint.textMode.text": "Umbhalo",
    "paint.colorPicker.swap": "Shitsha ",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "is": {
    "gui.backpack.header": "Bakpoki",
    "gui.backpack.errorBackpack": "Villa kom upp þegar reynt var að sækja bakpokann",
    "gui.backpack.loadingBackpack": "Hleð inn ...",
    "gui.backpack.more": "More",
    "gui.backpack.emptyBackpack": "Bakpokinn er tómur",
    "gui.gui.costumesTab": "Búningar",
    "gui.gui.soundsTab": "Hljóð",
    "gui.gui.backdropsTab": "Bakgrunnar",
    "gui.gui.addExtension": "Bæta við viðbót",
    "gui.costumeTab.addCostumeFromLibrary": "Velja búning",
    "gui.costumeLibrary.chooseACostume": "Velja búning",
    "gui.costumeTab.addBackdropFromLibrary": "Veldu bakgrunn",
    "gui.costumeTab.addBlankCostume": "Málning",
    "gui.costumeTab.addSurpriseCostume": "Koma á óvart",
    "gui.costumeTab.addFileBackdrop": "Sækja bakgrunn",
    "gui.costumeTab.addFileCostume": "Sækja búning",
    "gui.costumeTab.addCameraCostume": "Myndavél",
    "gui.soundEditor.trim": "Klippa",
    "gui.soundEditor.stop": "Stopp",
    "gui.soundEditor.sound": "Hljóð",
    "gui.soundEditor.play": "Spila",
    "gui.soundEditor.save": "Vista",
    "gui.soundEditor.undo": "Afgera",
    "gui.soundEditor.redo": "Endurgera",
    "gui.soundEditor.faster": "Hraðar",
    "gui.soundEditor.slower": "Hægar",
    "gui.soundEditor.echo": "Bergmál",
    "gui.soundEditor.robot": "Vélmenni",
    "gui.soundEditor.louder": "Hærra",
    "gui.soundEditor.softer": "Mýkja",
    "gui.soundEditor.reverse": "Snúa við",
    "gui.soundTab.recordSound": "Taka upp",
    "gui.soundTab.addSoundFromLibrary": "Veldu hljóð",
    "gui.soundTab.surpriseSound": "Koma á óvart",
    "gui.soundTab.fileUploadSound": "Sækja hljóð",
    "gui.controls.stop": "Stopp",
    "gui.controls.go": "Keyra",
    "gui.stageHeader.stageSizeUnFull": "Minnka skjáinn",
    "gui.SpriteInfo.show": "Sýna",
    "gui.SpriteInfo.size": "Stærð",
    "gui.directionPicker.rotationStyles.allAround": "Allt í kring",
    "gui.directionPicker.rotationStyles.leftRight": "Vinstri/hægri",
    "gui.directionPicker.rotationStyles.dontRotate": "Ekki snúast",
    "gui.spriteSelectorItem.contextMenuDuplicate": "tvöfalda",
    "gui.spriteSelectorItem.contextMenuDelete": "eyða",
    "gui.spriteSelectorItem.contextMenuExport": "flytja út",
    "gui.spriteSelector.addSpriteFromPaint": "Mála",
    "gui.spriteSelector.addSpriteFromSurprise": "Koma á óvart",
    "gui.spriteSelector.addSpriteFromFile": "Sækja teikningu",
    "gui.stageSelector.stage": "Svið",
    "gui.stageSelector.backdrops": "Bakgrunnar",
    "gui.stageSelector.addBackdropFromPaint": "Málning",
    "gui.stageSelector.addBackdropFromSurprise": "Koma á óvart",
    "gui.stageSelector.addBackdropFromFile": "Sækja bakgrunn",
    "gui.modal.back": "Til baka",
    "gui.library.filterPlaceholder": "Leita",
    "gui.libraryTags.all": "All",
    "gui.libraryTags.animals": "Animals",
    "gui.libraryTags.dance": "Dance",
    "gui.libraryTags.effects": "Effects",
    "gui.libraryTags.fantasy": "Fantasy",
    "gui.libraryTags.fashion": "Fashion",
    "gui.libraryTags.food": "Food",
    "gui.libraryTags.indoors": "Indoors",
    "gui.libraryTags.loops": "Loops",
    "gui.libraryTags.music": "Music",
    "gui.libraryTags.notes": "Notes",
    "gui.libraryTags.outdoors": "Outdoors",
    "gui.libraryTags.patterns": "Patterns",
    "gui.libraryTags.people": "People",
    "gui.libraryTags.percussion": "Percussion",
    "gui.libraryTags.space": "Space",
    "gui.libraryTags.sports": "Sports",
    "gui.libraryTags.underwater": "Underwater",
    "gui.libraryTags.voice": "Voice",
    "gui.libraryTags.wacky": "Wacky",
    "gui.libraryTags.animation": "Animation",
    "gui.libraryTags.art": "Art",
    "gui.libraryTags.games": "Games",
    "gui.libraryTags.stories": "Stories",
    "gui.libraryTags.letters": "Letters",
    "gui.soundLibrary.chooseASound": "Veldu hljóð",
    "gui.SpriteInfo.spritePlaceholder": "Nafn",
    "gui.cards.more-things-to-try": "Fleiri hlutir til að prófa!",
    "gui.cards.see-more": "Sjá fleiri",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Close",
    "gui.loader.message1": "Verið að búa til kubba ...",
    "gui.loader.message2": "Sæki teikningu ...",
    "gui.loader.message3": "Sæki hljóð ...",
    "gui.loader.message4": "Sæki viðbætur ...",
    "gui.loader.message5": "Safna saman köttunum ...",
    "gui.loader.message6": "Erum að vinna í þessu ...",
    "gui.loader.message7": "Erum að blása upp gobos ...",
    "gui.loader.message8": "Undirbúið broskalla ...",
    "gui.loader.headline": "Hleð inn verkefni ",
    "gui.cameraModal.cameraModalTitle": "Taka mynd",
    "gui.cameraModal.loadingCameraMessage": "Hleð inn myndavél ...",
    "gui.cameraModal.permissionRequest": "Við þurfum leyfi frá þér til að nota myndavélina þína",
    "gui.cameraModal.retakePhoto": "Endurtaka mynd",
    "gui.cameraModal.save": "Vista",
    "gui.cameraModal.takePhoto": "Taka mynd",
    "gui.cameraModal.loadingCaption": "Hleð inn ...",
    "gui.cameraModal.enableCameraCaption": "Leyfa myndavél",
    "gui.recordModal.title": "Taka upp hljóð",
    "gui.playbackStep.stopMsg": "Stopp",
    "gui.playbackStep.playMsg": "Spila",
    "gui.playbackStep.loadingMsg": "Hleð inn ...",
    "gui.playbackStep.saveMsg": "Vista",
    "gui.playbackStep.reRecordMsg": "Taka upp aftur",
    "gui.webglModal.label": "Vafrinn þinn styður ekki WebGL",
    "gui.webglModal.webgllink": "styður ekki WebGL",
    "gui.prompt.cancel": "Hætta við",
    "gui.prompt.ok": "Í lagi",
    "gui.customProcedures.addAnInputNumberText": "Bæta við inntaki",
    "gui.customProcedures.addAnInputBoolean": "Bæta við inntaki",
    "gui.customProcedures.numberTextType": "Tala eða texti",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Bæta við merki",
    "gui.customProcedures.runWithoutScreenRefresh": "Keyra án þess að uppfæra skjámynd",
    "gui.customProcedures.cancel": "Hætta við",
    "gui.customProcedures.ok": "Í lagi",
    "gui.extension.microbit.description": "Tengdu verkefnin þín við heiminn. ",
    "gui.extension.music.name": "Tónlist",
    "gui.extension.music.description": "Spila hljóðfæri og trommur. ",
    "gui.extension.pen.name": "Penni",
    "gui.extension.pen.description": "Teiknaðu með teikningunum þínum. ",
    "gui.gui.variableScopeOptionAllSprites": "Fyrir allar teikningar",
    "gui.gui.variableScopeOptionSpriteOnly": "Aðeins fyrir þessa teikningu",
    "gui.gui.variablePromptAllSpritesMessage": "Þessi breyta verður aðgengileg öllum teikningunum.",
    "gui.monitor.contextMenu.default": "venjuleg leturgerð",
    "gui.monitor.contextMenu.large": "Stór leturgerð",
    "gui.monitor.contextMenu.slider": "renna",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "direction",
    "gui.opcodeLabels.xposition": "x position",
    "gui.opcodeLabels.yposition": "y position",
    "gui.opcodeLabels.size": "size",
    "gui.opcodeLabels.costumename": "costume name",
    "gui.opcodeLabels.costumenumber": "costume number",
    "gui.opcodeLabels.backdropname": "backdrop name",
    "gui.opcodeLabels.backdropnumber": "backdrop number",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "answer",
    "gui.opcodeLabels.loudness": "loudness",
    "gui.opcodeLabels.year": "year",
    "gui.opcodeLabels.month": "month",
    "gui.opcodeLabels.date": "date",
    "gui.opcodeLabels.dayofweek": "day of week",
    "gui.opcodeLabels.hour": "hour",
    "gui.opcodeLabels.minute": "minute",
    "gui.opcodeLabels.second": "second",
    "gui.opcodeLabels.timer": "timer",
    "music.categoryName": "Tónlist",
    "translate.categoryName": "Translate",
    "pen.categoryName": "Penni",
    "pen.changeColorParam": "breyta lit penna [COLOR_PARAM] í [VALUE]",
    "pen.changeHue": "breyta lit penna um [HUE]",
    "pen.changeShade": "breyta litbrigði penna um [SHADE]",
    "pen.changeSize": "breyta stærð penna um [SIZE]",
    "pen.clear": "hreinsa allt",
    "pen.colorMenu.brightness": "birtustig",
    "pen.colorMenu.color": "litur",
    "pen.colorMenu.saturation": "mettun",
    "pen.colorMenu.transparency": "gegnsæi",
    "pen.penDown": "penni niður",
    "pen.penUp": "penni upp",
    "pen.setColor": "setja lit penna sem [COLOR]",
    "pen.setColorParam": "setja lit penna [COLOR_PARAM]í [VALUE]",
    "pen.setHue": "setja lit penna sem [HUE]",
    "pen.setShade": "setja skugga penna í [SHADE]",
    "pen.setSize": "setja stærð penna í [SIZE]",
    "pen.stamp": "stimpla",
    "music.changeTempo": "breyta hraða um  [TEMPO]",
    "music.drumBass": "(2) Bassa tromma",
    "music.drumBongo": "(13) Bongo tromma",
    "music.drumCabasa": "(15) Hrista",
    "music.drumClaves": "(9) Taktstangir",
    "music.drumClosedHiHat": "(6) Lokaður Hi-Hat",
    "music.drumConga": "(14) Konga tromma",
    "music.drumCowbell": "(11) Kúabjalla",
    "music.drumCrashCymbal": "(4) Glamrandi simball",
    "music.drumCuica": "(18) Cuica tromma",
    "music.drumGuiro": "(16) Guíró",
    "music.drumHandClap": "(8) Klapp",
    "music.drumOpenHiHat": "(5) Opinn Hi-Hat",
    "music.drumSideStick": "(3) Hliðar prik",
    "music.drumSnare": "(1) Sneriltromma",
    "music.drumTambourine": "(7) Tambúrína",
    "music.drumTriangle": "(12) Þríhyrningur",
    "music.drumVibraslap": "(17) Víbrastrengur",
    "music.drumWoodBlock": "(10) Viðarblokk",
    "music.getTempo": "taktur",
    "music.instrumentBass": "(6) Bassi",
    "music.instrumentBassoon": "(14) Fagott",
    "music.instrumentCello": "(8) Selló",
    "music.instrumentChoir": "(15) Kór",
    "music.instrumentClarinet": "(10) Klarinet",
    "music.instrumentElectricGuitar": "(5) Rafmagnsgítar",
    "music.instrumentElectricPiano": "(2) Rafmagnspíanó",
    "music.instrumentFlute": "(12) Flauta",
    "music.instrumentGuitar": "(4) Gítar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Spiladós",
    "music.instrumentOrgan": "(3) Orgel",
    "music.instrumentPiano": "(1) Píanó",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxófónn",
    "music.instrumentSteelDrum": "(18) Stál tromma",
    "music.instrumentSynthLead": "(20) Aðal hljóðgervill",
    "music.instrumentSynthPad": "(21) Hljóðgervill ",
    "music.instrumentTrombone": "(9) Básúna",
    "music.instrumentVibraphone": "(16) Víbrafónn",
    "music.instrumentWoodenFlute": "(13) Viðar flauta",
    "music.midiPlayDrumForBeats": "play drum [DRUM] for [BEATS] beats",
    "music.midiSetInstrument": "set instrument to [INSTRUMENT]",
    "music.playDrumForBeats": "spila trommu [DRUM] í [BEATS] slög",
    "music.playNoteForBeats": "spila nótu [NOTE] í [BEATS] slög",
    "music.restForBeats": "bíða í [BEATS] slög",
    "music.setInstrument": "setja hljóðfæri sem [INSTRUMENT]",
    "music.setTempo": "setja hraða sem [TEMPO]",
    "videoSensing.categoryName": "Vídeó mæling",
    "videoSensing.direction": "átt",
    "videoSensing.motion": "hreyfing",
    "videoSensing.off": "af",
    "videoSensing.on": "á",
    "videoSensing.onFlipped": "á flippi",
    "videoSensing.setVideoTransparency": "setja gegnsæi hreyfimyndar í [TRANSPARENCY]",
    "videoSensing.sprite": "teikning",
    "videoSensing.stage": "svið",
    "videoSensing.videoOn": "vídeó [ATTRIBUTE] á [SUBJECT]",
    "videoSensing.videoToggle": "breyta vídeó [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "þegar hreyfing í vídeó > [REFERENCE]",
    "translate.translateBlock": "þýða [WORDS] á [LANGUAGE]",
    "translate.defaultTextToTranslate": "halló",
    "translate.viewerLanguage": "tungumál",
    "text2speech.speakAndWaitBlock": "tala [WORDS]",
    "text2speech.setVoiceBlock": "setja rödd sem [VOICE]",
    "text2speech.setLanguageBlock": "set language to [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "tíst",
    "text2speech.giant": "giant",
    "text2speech.kitten": "kettlingur",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "þegar ég heyri [PHRASE]",
    "speech.listenAndWait": "hlustaðu og bíddu",
    "speech.defaultWhenIHearValue": "förum",
    "paint.paintEditor.hue": "Litur",
    "paint.paintEditor.saturation": "mettun",
    "paint.paintEditor.brightness": "Birtustig",
    "gui.comingSoon.message1": "Ekki hafa áhyggjur, við erum að vinna í þessu {emoji}",
    "gui.comingSoon.message2": "Kemur bráðlega...",
    "gui.comingSoon.message3": "Við erum að vinna í þessu {emoji}",
    "paint.paintEditor.fill": "Fylla",
    "paint.paintEditor.costume": "Búningur",
    "paint.paintEditor.group": "Hópur",
    "paint.paintEditor.ungroup": "Leysa upp hóp",
    "paint.paintEditor.undo": "Afgera",
    "paint.paintEditor.redo": "Endurgera",
    "paint.paintEditor.forward": "Áfram",
    "paint.paintEditor.backward": "Aftur á bak",
    "paint.paintEditor.front": "Fremst",
    "paint.paintEditor.back": "Til baka",
    "paint.paintEditor.more": "Meira",
    "paint.modeTools.brushSize": "Stærð",
    "paint.modeTools.eraserSize": "Stærð strokleðurs",
    "paint.modeTools.copy": "Afrita",
    "paint.modeTools.paste": "Líma",
    "paint.modeTools.delete": "Eyða",
    "paint.modeTools.curved": "Sveigja",
    "paint.modeTools.pointed": "Oddmjótt",
    "paint.modeTools.thickness": "Þykkt",
    "paint.modeTools.flipHorizontal": "Flippa lárétt",
    "paint.modeTools.flipVertical": "Flippa lóðrétt",
    "paint.modeTools.filled": "Fylla",
    "paint.modeTools.outlined": "Útlína",
    "paint.paintEditor.bitmap": "Umbreyta í Bitmap",
    "paint.paintEditor.vector": "Umbreyta í vektor",
    "paint.paintEditor.stroke": "Útlína",
    "paint.brushMode.brush": "Bursti",
    "paint.eraserMode.eraser": "Strokleður",
    "paint.fillMode.fill": "Fylla",
    "paint.lineMode.line": "Lína",
    "paint.ovalMode.oval": "Hringur",
    "paint.rectMode.rect": "Ferhyrningur",
    "paint.reshapeMode.reshape": "Ummynda",
    "paint.roundedRectMode.roundedRect": "Rúnaður ferhyrningur",
    "paint.selectMode.select": "Velja",
    "paint.textMode.text": "Texti",
    "paint.colorPicker.swap": "Skipta",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "it": {
    "gui.backpack.header": "Valigetta",
    "gui.backpack.errorBackpack": "Errore nel caricamento dello zaino",
    "gui.backpack.loadingBackpack": "Caricamento in corso...",
    "gui.backpack.more": "Altro",
    "gui.backpack.emptyBackpack": "Lo zaino è vuoto",
    "gui.gui.costumesTab": "Costumi",
    "gui.gui.soundsTab": "Suoni",
    "gui.gui.backdropsTab": "Sfondi",
    "gui.gui.addExtension": "Aggiungi un'Estensione",
    "gui.costumeTab.addCostumeFromLibrary": "Scegli un Costume",
    "gui.costumeLibrary.chooseACostume": "Scegli un Costume",
    "gui.costumeTab.addBackdropFromLibrary": "Scegli uno Sfondo",
    "gui.costumeTab.addBlankCostume": "Disegna un nuovo costume",
    "gui.costumeTab.addSurpriseCostume": "Scegli un costume a Sorpresa",
    "gui.costumeTab.addFileBackdrop": "Importa Sfondo",
    "gui.costumeTab.addFileCostume": "Importa Costume",
    "gui.costumeTab.addCameraCostume": "Webcam",
    "gui.soundEditor.trim": "Taglia",
    "gui.soundEditor.stop": "Arresta",
    "gui.soundEditor.sound": "Suono",
    "gui.soundEditor.play": "Riproduci",
    "gui.soundEditor.save": "Salva",
    "gui.soundEditor.undo": "Annulla",
    "gui.soundEditor.redo": "Ripristina",
    "gui.soundEditor.faster": "Accelera",
    "gui.soundEditor.slower": "Rallenta",
    "gui.soundEditor.echo": "Eco",
    "gui.soundEditor.robot": "Effetto Robot",
    "gui.soundEditor.louder": "Aumenta volume",
    "gui.soundEditor.softer": "Diminuisci volume",
    "gui.soundEditor.reverse": "Inverti",
    "gui.soundTab.recordSound": "Registra",
    "gui.soundTab.addSoundFromLibrary": "Scegli un Suono",
    "gui.soundTab.surpriseSound": "Aggiungi un suono a Sorpresa",
    "gui.soundTab.fileUploadSound": "Carica un Suono",
    "gui.controls.stop": "Ferma tutto",
    "gui.controls.go": "Vai",
    "gui.stageHeader.stageSizeUnFull": "Esci dalla modalità presentazione",
    "gui.SpriteInfo.show": "Mostra",
    "gui.SpriteInfo.size": "Dimensione",
    "gui.directionPicker.rotationStyles.allAround": "Può Ruotare",
    "gui.directionPicker.rotationStyles.leftRight": "Sinistra-Destra",
    "gui.directionPicker.rotationStyles.dontRotate": "Non ruotare",
    "gui.spriteSelectorItem.contextMenuDuplicate": "duplica",
    "gui.spriteSelectorItem.contextMenuDelete": "cancella",
    "gui.spriteSelectorItem.contextMenuExport": "esporta",
    "gui.spriteSelector.addSpriteFromPaint": "Disegna un nuovo sprite",
    "gui.spriteSelector.addSpriteFromSurprise": "Aggiungi uno sprite a Sorpresa",
    "gui.spriteSelector.addSpriteFromFile": "Importa Sprite",
    "gui.stageSelector.stage": "Stage",
    "gui.stageSelector.backdrops": "Sfondi",
    "gui.stageSelector.addBackdropFromPaint": "Disegna un nuovo sfondo",
    "gui.stageSelector.addBackdropFromSurprise": "Aggiungi uno sfondo a Sorpresa",
    "gui.stageSelector.addBackdropFromFile": "Importa Sfondo",
    "gui.modal.back": "Indietro",
    "gui.library.filterPlaceholder": "Cerca",
    "gui.libraryTags.all": "Tutti",
    "gui.libraryTags.animals": "Animali",
    "gui.libraryTags.dance": "Danza",
    "gui.libraryTags.effects": "Effetti",
    "gui.libraryTags.fantasy": "Fantasia",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Cibi",
    "gui.libraryTags.indoors": "Interni",
    "gui.libraryTags.loops": "Loop",
    "gui.libraryTags.music": "Musica",
    "gui.libraryTags.notes": "Note",
    "gui.libraryTags.outdoors": "Esterni",
    "gui.libraryTags.patterns": "Pattern",
    "gui.libraryTags.people": "Persone",
    "gui.libraryTags.percussion": "Percussioni",
    "gui.libraryTags.space": "Spazio",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Fondo del mare",
    "gui.libraryTags.voice": "Voce",
    "gui.libraryTags.wacky": "Strani",
    "gui.libraryTags.animation": "Animazioni",
    "gui.libraryTags.art": "Arte",
    "gui.libraryTags.games": "Giochi",
    "gui.libraryTags.stories": "Storie",
    "gui.libraryTags.letters": "Lettere",
    "gui.soundLibrary.chooseASound": "Scegli un Suono",
    "gui.SpriteInfo.spritePlaceholder": "Nome",
    "gui.cards.more-things-to-try": "Altre cose da provare!",
    "gui.cards.see-more": "Ulteriori informazioni",
    "gui.cards.shrink": "Riduci",
    "gui.cards.expand": "Espandi",
    "gui.cards.close": "Chiudi",
    "gui.loader.message1": "Creazione dei blocchi...",
    "gui.loader.message2": "Caricamento degli sprite...",
    "gui.loader.message3": "Caricamento dei suoni...",
    "gui.loader.message4": "Caricamento delle estensioni...",
    "gui.loader.message5": "Tranquillizzare i gatti...",
    "gui.loader.message6": "Trasmissione dei nano...",
    "gui.loader.message7": "Espansione dei gobo...",
    "gui.loader.message8": "Preparazione delle emoji...",
    "gui.loader.headline": "Caricamento del Progetto in corso",
    "gui.cameraModal.cameraModalTitle": "Scatta una Foto",
    "gui.cameraModal.loadingCameraMessage": "Caricamento Webcam in corso...",
    "gui.cameraModal.permissionRequest": "Ci occorre il tuo permesso per usare la tua webcam",
    "gui.cameraModal.retakePhoto": "Scatta una nuova Foto",
    "gui.cameraModal.save": "Salva",
    "gui.cameraModal.takePhoto": "Scatta una Foto",
    "gui.cameraModal.loadingCaption": "Caricamento in corso...",
    "gui.cameraModal.enableCameraCaption": "Abilita la webcam",
    "gui.recordModal.title": "Registra Suono",
    "gui.playbackStep.stopMsg": "Stop",
    "gui.playbackStep.playMsg": "Riproduci",
    "gui.playbackStep.loadingMsg": "Caricamento in corso...",
    "gui.playbackStep.saveMsg": "Salva",
    "gui.playbackStep.reRecordMsg": "Ripeti la registrazione",
    "gui.webglModal.label": "Il Tuo Browser Non Supporta WebGL",
    "gui.webglModal.webgllink": "non supporta WebGL",
    "gui.prompt.cancel": "Annulla",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Aggiungi un argomento",
    "gui.customProcedures.addAnInputBoolean": "Aggiungi un argomento",
    "gui.customProcedures.numberTextType": "numero o testo",
    "gui.customProcedures.booleanType": "booleano",
    "gui.customProcedures.addALabel": "Aggiungi una scritta",
    "gui.customProcedures.runWithoutScreenRefresh": "Esegui senza aggiornare lo schermo",
    "gui.customProcedures.cancel": "Annulla",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Collega il tuo progetto con il mondo che ti circonda",
    "gui.extension.music.name": "Musica",
    "gui.extension.music.description": "Suona strumenti e percussioni.",
    "gui.extension.pen.name": "Penna",
    "gui.extension.pen.description": "Disegna usando gli sprite.",
    "gui.gui.variableScopeOptionAllSprites": "Per tutti gli sprite",
    "gui.gui.variableScopeOptionSpriteOnly": "Solo per questo sprite",
    "gui.gui.variablePromptAllSpritesMessage": "Questa variabile potrà essere letta e modificata da tutti gli sprite.",
    "gui.monitor.contextMenu.default": "normale",
    "gui.monitor.contextMenu.large": "grande",
    "gui.monitor.contextMenu.slider": "cursore",
    "gui.monitor.contextMenu.import": "importa",
    "gui.monitor.contextMenu.export": "esporta",
    "gui.opcodeLabels.direction": "direzione",
    "gui.opcodeLabels.xposition": "posizione x",
    "gui.opcodeLabels.yposition": "posizione y",
    "gui.opcodeLabels.size": "dimensione",
    "gui.opcodeLabels.costumename": "nome del costume",
    "gui.opcodeLabels.costumenumber": "numero del costume",
    "gui.opcodeLabels.backdropname": "nome dello sfondo",
    "gui.opcodeLabels.backdropnumber": "numero dello sfondo",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "risposta",
    "gui.opcodeLabels.loudness": "volume microfono",
    "gui.opcodeLabels.year": "anno",
    "gui.opcodeLabels.month": "mese",
    "gui.opcodeLabels.date": "data",
    "gui.opcodeLabels.dayofweek": "giorno della settimana",
    "gui.opcodeLabels.hour": "ora",
    "gui.opcodeLabels.minute": "minuti",
    "gui.opcodeLabels.second": "secondi",
    "gui.opcodeLabels.timer": "cronometro",
    "music.categoryName": "Musica",
    "translate.categoryName": "Traduci",
    "pen.categoryName": "Penna",
    "pen.changeColorParam": "cambia [COLOR_PARAM] penna di [VALUE]",
    "pen.changeHue": "cambia colore penna di [HUE]",
    "pen.changeShade": "cambia luminosità penna di [SHADE]",
    "pen.changeSize": "cambia dimensione penna di [SIZE]",
    "pen.clear": "pulisci",
    "pen.colorMenu.brightness": "luminosità",
    "pen.colorMenu.color": "colore",
    "pen.colorMenu.saturation": "intensità colore",
    "pen.colorMenu.transparency": "trasparenza",
    "pen.penDown": "penna giù",
    "pen.penUp": "penna su",
    "pen.setColor": "porta colore penna a [COLOR]",
    "pen.setColorParam": "porta [COLOR_PARAM] penna a [VALUE]",
    "pen.setHue": "porta colore penna a [HUE]",
    "pen.setShade": "porta luminosità penna a [SHADE]",
    "pen.setSize": "porta dimensione penna a [SIZE]",
    "pen.stamp": "timbra",
    "music.changeTempo": "cambia tempo di [TEMPO]",
    "music.drumBass": "(2) Grancassa",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabassa",
    "music.drumClaves": "(9) Legnetti",
    "music.drumClosedHiHat": "(6) Charleston",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Campanaccio",
    "music.drumCrashCymbal": "(4) Piatto Crash",
    "music.drumCuica": "(18) Cuíca",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Battimano",
    "music.drumOpenHiHat": "(5) Hi-Hat aperto",
    "music.drumSideStick": "(3) Bacchette",
    "music.drumSnare": "(1) Rullante",
    "music.drumTambourine": "(7) Tamburello",
    "music.drumTriangle": "(12) Triangolo",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Blocchetto di legno",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Basso elettrico",
    "music.instrumentBassoon": "(14) Fagotto",
    "music.instrumentCello": "(8) Violoncello",
    "music.instrumentChoir": "(15) Coro",
    "music.instrumentClarinet": "(10) Clarinetto",
    "music.instrumentElectricGuitar": "(5) Chitarra elettrica",
    "music.instrumentElectricPiano": "(2) Piano elettrico",
    "music.instrumentFlute": "(12) Flauto",
    "music.instrumentGuitar": "(4) Chitarra",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Carillon",
    "music.instrumentOrgan": "(3) Organo",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Sassofono",
    "music.instrumentSteelDrum": "(18) Steel Drum",
    "music.instrumentSynthLead": "(20) Sintetizzatore",
    "music.instrumentSynthPad": "(21) Sintetizzatore (suoni di sottofondo)",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibrafono",
    "music.instrumentWoodenFlute": "(13) Flauto di legno",
    "music.midiPlayDrumForBeats": "suona tamburo [DRUM] per [BEATS] battute",
    "music.midiSetInstrument": "passa a strumento [INSTRUMENT]",
    "music.playDrumForBeats": "suona il tamburo [DRUM] per [BEATS] battute",
    "music.playNoteForBeats": "suona la nota [NOTE] per [BEATS] battute",
    "music.restForBeats": "pausa di [BEATS] battute",
    "music.setInstrument": "passa a strumento [INSTRUMENT]",
    "music.setTempo": "imposta tempo a [TEMPO] bpm",
    "videoSensing.categoryName": "Movimento Webcam",
    "videoSensing.direction": "direzione",
    "videoSensing.motion": "movimento",
    "videoSensing.off": "spegni",
    "videoSensing.on": "accendi",
    "videoSensing.onFlipped": "accendi e inverti orizzontalmente",
    "videoSensing.setVideoTransparency": "porta trasparenza webcam a [TRANSPARENCY]",
    "videoSensing.sprite": "sprite",
    "videoSensing.stage": "stage",
    "videoSensing.videoOn": "[ATTRIBUTE] del video su [SUBJECT]",
    "videoSensing.videoToggle": "[VIDEO_STATE] il video della webcam",
    "videoSensing.whenMotionGreaterThan": "quando movimento video è > [REFERENCE]",
    "translate.translateBlock": "traduci [WORDS] in [LANGUAGE]",
    "translate.defaultTextToTranslate": "ciao",
    "translate.viewerLanguage": "lingua",
    "text2speech.speakAndWaitBlock": "pronuncia [WORDS]",
    "text2speech.setVoiceBlock": "usa voce [VOICE]",
    "text2speech.setLanguageBlock": "usa lingua [LANGUAGE]",
    "text2speech.alto": "da contralto",
    "text2speech.tenor": "da tenore",
    "text2speech.squeak": "stridula",
    "text2speech.giant": "da gigante",
    "text2speech.kitten": "da gattino",
    "text2speech.defaultTextToSpeak": "ciao",
    "speech.whenIHear": "quando sento [PHRASE]",
    "speech.listenAndWait": "ascolta e attendi",
    "speech.defaultWhenIHearValue": "via!",
    "paint.paintEditor.hue": "Colore",
    "paint.paintEditor.saturation": "Saturazione",
    "paint.paintEditor.brightness": "Luminosità",
    "gui.comingSoon.message1": "Tranquillo, ci stiamo lavorando {emoji}",
    "gui.comingSoon.message2": "Disponibile a Breve...",
    "gui.comingSoon.message3": "Ci stiamo lavorando {emoji}",
    "paint.paintEditor.fill": "Riempimento",
    "paint.paintEditor.costume": "Costume",
    "paint.paintEditor.group": "Raggruppa",
    "paint.paintEditor.ungroup": "Separa",
    "paint.paintEditor.undo": "Annulla",
    "paint.paintEditor.redo": "Ripristina",
    "paint.paintEditor.forward": "Porta avanti",
    "paint.paintEditor.backward": "Porta indietro",
    "paint.paintEditor.front": "Primo piano",
    "paint.paintEditor.back": "Secondo piano",
    "paint.paintEditor.more": "Altro",
    "paint.modeTools.brushSize": "Dimensione",
    "paint.modeTools.eraserSize": "Dimensione gomma",
    "paint.modeTools.copy": "Copia",
    "paint.modeTools.paste": "Incolla",
    "paint.modeTools.delete": "Cancella",
    "paint.modeTools.curved": "Curva",
    "paint.modeTools.pointed": "Angolo",
    "paint.modeTools.thickness": "Spessore",
    "paint.modeTools.flipHorizontal": "Rifletti in Orizzontale",
    "paint.modeTools.flipVertical": "Capovolgi",
    "paint.modeTools.filled": "Riempito",
    "paint.modeTools.outlined": "Contorno",
    "paint.paintEditor.bitmap": "Converti in Bitmap",
    "paint.paintEditor.vector": "Converti in Vettoriale",
    "paint.paintEditor.stroke": "Contorno",
    "paint.brushMode.brush": "Pennello",
    "paint.eraserMode.eraser": "Gomma",
    "paint.fillMode.fill": "Riempimento",
    "paint.lineMode.line": "Linea",
    "paint.ovalMode.oval": "Cerchio",
    "paint.rectMode.rect": "Rettangolo",
    "paint.reshapeMode.reshape": "Modifica contorno",
    "paint.roundedRectMode.roundedRect": "Rettangolo Arrotondato",
    "paint.selectMode.select": "Seleziona",
    "paint.textMode.text": "Testo",
    "paint.colorPicker.swap": "Scambia",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "ckb": {
    "gui.backpack.header": "کۆڵەپشت",
    "gui.backpack.errorBackpack": "لە ئامادەکردنی کۆڵەپشت هەڵەڕوویدا",
    "gui.backpack.loadingBackpack": "ئامادەکردن...",
    "gui.backpack.more": "زیاتر",
    "gui.backpack.emptyBackpack": "کۆڵەپشت بەتاڵە",
    "gui.gui.costumesTab": "بەرگەکان",
    "gui.gui.soundsTab": "دەنگەکان",
    "gui.gui.backdropsTab": "پاشبنه‌ماکان",
    "gui.gui.addExtension": "زیادکردنی زیادکراوە",
    "gui.costumeTab.addCostumeFromLibrary": "هەڵبژاردنی بەرگێک",
    "gui.costumeLibrary.chooseACostume": "هەڵبژاردنی بەرگێک",
    "gui.costumeTab.addBackdropFromLibrary": "هەڵبژاردنی پاشبنەمایەک",
    "gui.costumeTab.addBlankCostume": "وێنەکێشان",
    "gui.costumeTab.addSurpriseCostume": "سەرسام بوون",
    "gui.costumeTab.addFileBackdrop": "بارکردنی پاشبنەما",
    "gui.costumeTab.addFileCostume": "بارکردنی بەرگ",
    "gui.costumeTab.addCameraCostume": "کامێڕا",
    "gui.soundEditor.trim": "بڕین",
    "gui.soundEditor.stop": "وەستان",
    "gui.soundEditor.sound": "دەنگ",
    "gui.soundEditor.play": "لێدان",
    "gui.soundEditor.save": "پاشەکەوتکردن",
    "gui.soundEditor.undo": "هه‌ڵوه‌شاندنه‌وه‌",
    "gui.soundEditor.redo": "کردنەوە",
    "gui.soundEditor.faster": "خێراتر",
    "gui.soundEditor.slower": "هێواشتر",
    "gui.soundEditor.echo": "دەنگ دانەوە",
    "gui.soundEditor.robot": "ڕۆبۆت",
    "gui.soundEditor.louder": "بەرزتر",
    "gui.soundEditor.softer": "نەرمتر",
    "gui.soundEditor.reverse": "پێچەوانە",
    "gui.soundTab.recordSound": "تۆمارکردن",
    "gui.soundTab.addSoundFromLibrary": "هەڵبژاردنی دەنگ",
    "gui.soundTab.surpriseSound": "سەرسام بوون",
    "gui.soundTab.fileUploadSound": "بارکردنی دەنگ",
    "gui.controls.stop": "وەستان",
    "gui.controls.go": "بڕۆ",
    "gui.stageHeader.stageSizeUnFull": "دەرچوون لە شێوازی پڕ بەشاشە",
    "gui.SpriteInfo.show": "بینین",
    "gui.SpriteInfo.size": "قەبارە",
    "gui.directionPicker.rotationStyles.allAround": "بەدەوری",
    "gui.directionPicker.rotationStyles.leftRight": "چەپ/ڕاست",
    "gui.directionPicker.rotationStyles.dontRotate": "مەیسوڕێنە",
    "gui.spriteSelectorItem.contextMenuDuplicate": "هاوشێوەکردنەوە",
    "gui.spriteSelectorItem.contextMenuDelete": "سڕینەوە",
    "gui.spriteSelectorItem.contextMenuExport": "ناردنەدەرەوە",
    "gui.spriteSelector.addSpriteFromPaint": "وێنەکێشان",
    "gui.spriteSelector.addSpriteFromSurprise": "سەرسام بوون",
    "gui.spriteSelector.addSpriteFromFile": "بارکردنی تەن",
    "gui.stageSelector.stage": "سەکۆ",
    "gui.stageSelector.backdrops": "پاشبنه‌ماکان",
    "gui.stageSelector.addBackdropFromPaint": "وێنەکێشان",
    "gui.stageSelector.addBackdropFromSurprise": "سەرسام بوون",
    "gui.stageSelector.addBackdropFromFile": "بارکردنی پاشبنەما",
    "gui.modal.back": "دواوە",
    "gui.library.filterPlaceholder": "گەڕان",
    "gui.libraryTags.all": "هەموو",
    "gui.libraryTags.animals": "ئاژەڵەکان",
    "gui.libraryTags.dance": "سەما",
    "gui.libraryTags.effects": "کاریگەرییەکان",
    "gui.libraryTags.fantasy": "خەیاڵاوی",
    "gui.libraryTags.fashion": "مۆدە",
    "gui.libraryTags.food": "خواردن",
    "gui.libraryTags.indoors": "ناوەوە",
    "gui.libraryTags.loops": "دووبارەبوونەوەکان",
    "gui.libraryTags.music": "میوزیک",
    "gui.libraryTags.notes": "تێبینییەکان",
    "gui.libraryTags.outdoors": "دەرەوە",
    "gui.libraryTags.patterns": "شێوەکان",
    "gui.libraryTags.people": "خەڵک",
    "gui.libraryTags.percussion": "ئاواز",
    "gui.libraryTags.space": "بۆشایی",
    "gui.libraryTags.sports": "یارییەکان",
    "gui.libraryTags.underwater": "لەژێر ئاو",
    "gui.libraryTags.voice": "دەنگ",
    "gui.libraryTags.wacky": "سەیر",
    "gui.libraryTags.animation": "وێنەی جوڵاو",
    "gui.libraryTags.art": "هونەر",
    "gui.libraryTags.games": "یارییەکان",
    "gui.libraryTags.stories": "چیرۆکەکان",
    "gui.libraryTags.letters": "پیتەکان",
    "gui.soundLibrary.chooseASound": "هەڵبژاردنی دەنگ",
    "gui.SpriteInfo.spritePlaceholder": "ناو",
    "gui.cards.more-things-to-try": "شتی زیاتر بۆ تاقیکردنەوە!",
    "gui.cards.see-more": "زیاتر ببینە",
    "gui.cards.shrink": "وێک هاتن",
    "gui.cards.expand": "فراوان کردن",
    "gui.cards.close": "داخستن",
    "gui.loader.message1": "دروستکردنی بلۆکەکان...",
    "gui.loader.message2": "ئامادەکردنی تەنەکان...",
    "gui.loader.message3": "ئامادەکردنی دەنگەکان...",
    "gui.loader.message4": "ئامادەکردنی زیادکراوەکان...",
    "gui.loader.message5": "شوانی پشیلەکان...",
    "gui.loader.message6": "پەخشکردنی نانۆ...",
    "gui.loader.message7": "گەورەکردنی داپۆشەر...",
    "gui.loader.message8": "ئامادەکردنی ئیمۆجیەکان...",
    "gui.loader.headline": "ئامادەکردنی پڕۆژە",
    "gui.cameraModal.cameraModalTitle": "گرتنی وێنە",
    "gui.cameraModal.loadingCameraMessage": "ئامادەکردنی کامێڕا...",
    "gui.cameraModal.permissionRequest": "پێویستمان بە مۆڵەتی تۆیە بۆ بەکارهێنانی کامێڕاکەت",
    "gui.cameraModal.retakePhoto": "دوبارە گرتنی وێنە",
    "gui.cameraModal.save": "پاشەکەوتکردن",
    "gui.cameraModal.takePhoto": "گرتنی وێنە",
    "gui.cameraModal.loadingCaption": "ئامادەکردن...",
    "gui.cameraModal.enableCameraCaption": "چالاککردنی کامێڕا",
    "gui.recordModal.title": "تۆمارکردنی دەنگ",
    "gui.playbackStep.stopMsg": "وەستان",
    "gui.playbackStep.playMsg": "لێدان",
    "gui.playbackStep.loadingMsg": "ئامادەکردن...",
    "gui.playbackStep.saveMsg": "پاشەکەوتکردن",
    "gui.playbackStep.reRecordMsg": "تۆمارکردنەوە",
    "gui.webglModal.label": "وێبگەڕەکەت پاڵپشتی WebGL ناکات",
    "gui.webglModal.webgllink": "پاڵپشتی WebGL ناکات",
    "gui.prompt.cancel": "پاشگەزبوونەوە",
    "gui.prompt.ok": "باشە",
    "gui.customProcedures.addAnInputNumberText": "زیادکردنی تێخستەیەک",
    "gui.customProcedures.addAnInputBoolean": "زیادکردنی تێخستەیەک",
    "gui.customProcedures.numberTextType": "ژمارە یان دەق",
    "gui.customProcedures.booleanType": "بوولی",
    "gui.customProcedures.addALabel": "زیادکردنی ناونیشان",
    "gui.customProcedures.runWithoutScreenRefresh": "جێبەجێی بکە بەبێ نوێبوونەوەی شاشە",
    "gui.customProcedures.cancel": "پاشگەزبوونەوە",
    "gui.customProcedures.ok": "باشە",
    "gui.extension.microbit.description": "پڕۆژەکانت ببەستەوە لەگەڵ جیهان.",
    "gui.extension.music.name": "میوزیک",
    "gui.extension.music.description": "لێدانی ئامرازەکان و تەپڵەکان.",
    "gui.extension.pen.name": "پێنووس",
    "gui.extension.pen.description": "بکێشە لەگەڵ تەنەکانت.",
    "gui.gui.variableScopeOptionAllSprites": "بۆ هەموو تەنەکان",
    "gui.gui.variableScopeOptionSpriteOnly": "تەنها بۆ ئەم تەنە",
    "gui.gui.variablePromptAllSpritesMessage": "ئەم گۆڕاوە بەردەستە بۆ هەموو تەنەکان.",
    "gui.monitor.contextMenu.default": "پیشاندانی ئاسایی",
    "gui.monitor.contextMenu.large": "پیشاندانی گەورە",
    "gui.monitor.contextMenu.slider": "خلیسکێنەر",
    "gui.monitor.contextMenu.import": "هێنان",
    "gui.monitor.contextMenu.export": "ناردنەدەرەوە",
    "gui.opcodeLabels.direction": "ئاڕاستە",
    "gui.opcodeLabels.xposition": "شوێنی x",
    "gui.opcodeLabels.yposition": "شوێنی y",
    "gui.opcodeLabels.size": "قەبارە",
    "gui.opcodeLabels.costumename": "ناوی بەرگ",
    "gui.opcodeLabels.costumenumber": "ژمارەی بەرگ",
    "gui.opcodeLabels.backdropname": "ناوی پاشبنەما",
    "gui.opcodeLabels.backdropnumber": "ژمارەی پاشبنەما",
    "gui.opcodeLabels.volume": "قەبارە",
    "gui.opcodeLabels.tempo": "خێرایی",
    "gui.opcodeLabels.answer": "وەڵام",
    "gui.opcodeLabels.loudness": "دەنگەدەنگ",
    "gui.opcodeLabels.year": "ساڵ",
    "gui.opcodeLabels.month": "مانگ",
    "gui.opcodeLabels.date": "بەروار",
    "gui.opcodeLabels.dayofweek": "ڕۆژی هەفتە",
    "gui.opcodeLabels.hour": "کاتژمێر",
    "gui.opcodeLabels.minute": "خولەک",
    "gui.opcodeLabels.second": "چرکە",
    "gui.opcodeLabels.timer": "کاتێنەر",
    "music.categoryName": "میوزیک",
    "translate.categoryName": "وەرگێڕان",
    "pen.categoryName": "پێنووس",
    "pen.changeColorParam": "گۆڕینی پێنووس [COLOR_PARAM] بە [VALUE]",
    "pen.changeHue": "گۆڕینی ڕەنگی پێنووس بە [HUE]",
    "pen.changeShade": "گۆڕینی سێبەری پێنووس بە [SHADE]",
    "pen.changeSize": "گۆڕینی قەبارەی پێنووس بە [SIZE]",
    "pen.clear": "سڕینەوەی هەموو",
    "pen.colorMenu.brightness": "ڕووناکی",
    "pen.colorMenu.color": "ڕەنگ",
    "pen.colorMenu.saturation": "تێری",
    "pen.colorMenu.transparency": "ڕۆشنایی",
    "pen.penDown": "پێنووس داگرتن",
    "pen.penUp": "پێنووس بەرزکردن",
    "pen.setColor": "دانانی ڕەنگی پێنووس بە [COLOR]",
    "pen.setColorParam": "دانانی پێنووس [COLOR_PARAM] بە [VALUE]",
    "pen.setHue": "دانانی ڕەنگی پێنووس بە [HUE]",
    "pen.setShade": "دانانی سێبەری پێنووس بە [SHADE]",
    "pen.setSize": "دانانی قەبارەی پێنووس بە [SIZE]",
    "pen.stamp": "مۆر",
    "music.changeTempo": "گۆڕینی خێرایی بە [TEMPO]",
    "music.drumBass": "(2) تەپڵی دەنگ قووڵ",
    "music.drumBongo": "(13) بۆنگۆ",
    "music.drumCabasa": "(15) کاباسا",
    "music.drumClaves": "(9) کڵاڤیس",
    "music.drumClosedHiHat": "(6) داخستنی Hi-Hat",
    "music.drumConga": "(14) کۆنگا",
    "music.drumCowbell": "(11) زەنگوڵە",
    "music.drumCrashCymbal": "(4) سنج لێدان",
    "music.drumCuica": "(18) کویکا",
    "music.drumGuiro": "(16) گویڕۆ",
    "music.drumHandClap": "(8) چەپڵە لێدان",
    "music.drumOpenHiHat": "(5) کردنەوەی Hi-Hat",
    "music.drumSideStick": "داری لا",
    "music.drumSnare": "(1) تەپڵی داو",
    "music.drumTambourine": "(7) تەپل",
    "music.drumTriangle": "(12) سێگۆشە",
    "music.drumVibraslap": "(17) ڤیبراسلاپ",
    "music.drumWoodBlock": "(10) بلۆکی تەختە",
    "music.getTempo": "خێرایی",
    "music.instrumentBass": "(6) دەنگ قووڵ",
    "music.instrumentBassoon": "(14) دووزەلە",
    "music.instrumentCello": "(8) چەلۆ",
    "music.instrumentChoir": "(15) دەستەی گۆرانی",
    "music.instrumentClarinet": "(10) کلارنێت",
    "music.instrumentElectricGuitar": "(5) گیتاری ئەلیکترۆنی",
    "music.instrumentElectricPiano": "(2) پیانۆی ئەلیکترۆنی",
    "music.instrumentFlute": "(12) فلوت",
    "music.instrumentGuitar": "(4) گیتار",
    "music.instrumentMarimba": "(19) ماريمبا",
    "music.instrumentMusicBox": "(17) قوتوی میوزیک",
    "music.instrumentOrgan": "(3) ئەندام",
    "music.instrumentPiano": "(1) پیانۆ",
    "music.instrumentPizzicato": "(7) پیزەکاتۆ",
    "music.instrumentSaxophone": "(11) ساکسۆفۆن",
    "music.instrumentSteelDrum": "(18) تەپڵی ئاسن",
    "music.instrumentSynthLead": "(20) ژەنینی پێشەنگ",
    "music.instrumentSynthPad": "(21) ژەنینی ناوەندی",
    "music.instrumentTrombone": "(9) ترۆمبۆن",
    "music.instrumentVibraphone": "(16) ڤیبرافۆن",
    "music.instrumentWoodenFlute": "(13) فلوتی دار",
    "music.midiPlayDrumForBeats": "لێدانی تەپڵ [DRUM] بۆ [BEATS] لێدان",
    "music.midiSetInstrument": "دانانی ئامێر بە [INSTRUMENT]",
    "music.playDrumForBeats": "لێدانی تەپڵ [DRUM] بۆ [BEATS] لیدان",
    "music.playNoteForBeats": "لێدانی ئاماژەی [NOTE] بۆ [BEATS] لێدان",
    "music.restForBeats": "پشوو بۆ [BEATS] لێدان",
    "music.setInstrument": "دانانی ئامێر بە [INSTRUMENT]",
    "music.setTempo": "دانانی خێرایی بە [TEMPO]",
    "videoSensing.categoryName": "هەستی ڤیدیۆیی",
    "videoSensing.direction": "ئاڕاستە",
    "videoSensing.motion": "جوڵان",
    "videoSensing.off": "ناکارا",
    "videoSensing.on": "کارا",
    "videoSensing.onFlipped": "لە هەڵگەڕانەوە",
    "videoSensing.setVideoTransparency": "دانانی ڕۆشنایی ڤیدیۆ بە [TRANSPARENCY]",
    "videoSensing.sprite": "تەن",
    "videoSensing.stage": "سەکۆ",
    "videoSensing.videoOn": "ڤیدیۆ [ATTRIBUTE] لەسەر [SUBJECT]",
    "videoSensing.videoToggle": "ڤیدیۆ بکە بە [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "کاتێک جوڵەی ڤیدیۆ > [REFERENCE]",
    "translate.translateBlock": "وەرگێڕانی [WORDS] بۆ [LANGUAGE]",
    "translate.defaultTextToTranslate": "سڵاو",
    "translate.viewerLanguage": "زمان",
    "text2speech.speakAndWaitBlock": "قسە بکە [WORDS]",
    "text2speech.setVoiceBlock": "دانانی دەنگ بە [VOICE]",
    "text2speech.setLanguageBlock": "دانانی زمان بە [LANGUAGE]",
    "text2speech.alto": "دەنگی مامناوەند",
    "text2speech.tenor": "دەنگی مامناوەند",
    "text2speech.squeak": "قیڕە",
    "text2speech.giant": "زل",
    "text2speech.kitten": "بێچوه پشیله",
    "text2speech.defaultTextToSpeak": "سڵاو",
    "speech.whenIHear": "کاتێک گوێم دەبێت لە [PHRASE]",
    "speech.listenAndWait": "گوێبگرە و چاوەڕێ بکە",
    "speech.defaultWhenIHearValue": "با بڕۆین",
    "paint.paintEditor.hue": "ڕەنگ",
    "paint.paintEditor.saturation": "تێری",
    "paint.paintEditor.brightness": "ڕووناکی",
    "gui.comingSoon.message1": "نیگەران مەبە، ئێمە خەریکین {emoji}",
    "gui.comingSoon.message2": "بەم زووانە...",
    "gui.comingSoon.message3": "ئێمە کاری لەسەر دەکەین {emoji}",
    "paint.paintEditor.fill": "پڕکردنەوە",
    "paint.paintEditor.costume": "بەرگ",
    "paint.paintEditor.group": "گروپ کردن",
    "paint.paintEditor.ungroup": "نا گروپ کردن",
    "paint.paintEditor.undo": "هه‌ڵوه‌شاندنه‌وه‌",
    "paint.paintEditor.redo": "کردنەوە",
    "paint.paintEditor.forward": "بۆ پێشەوە",
    "paint.paintEditor.backward": "بۆ دواوە",
    "paint.paintEditor.front": "پێشەوە",
    "paint.paintEditor.back": "دواوە",
    "paint.paintEditor.more": "زیاتر",
    "paint.modeTools.brushSize": "قەبارە",
    "paint.modeTools.eraserSize": "قەبارەی سڕەوە",
    "paint.modeTools.copy": "لەبەرگرتنەوە",
    "paint.modeTools.paste": "لکاندن",
    "paint.modeTools.delete": "سڕینەوە",
    "paint.modeTools.curved": "چەماوە",
    "paint.modeTools.pointed": "ئاماژە بۆکراو",
    "paint.modeTools.thickness": "ئەستوورایی",
    "paint.modeTools.flipHorizontal": "هەڵگەڕانەوەی ئاسۆیی",
    "paint.modeTools.flipVertical": "هەڵگەڕانەوەی ستوونی",
    "paint.modeTools.filled": "پڕکراوە",
    "paint.modeTools.outlined": "چوارچێوەکراو",
    "paint.paintEditor.bitmap": "گۆڕینی بۆ Bitmap",
    "paint.paintEditor.vector": "گۆڕینی بۆ Vector",
    "paint.paintEditor.stroke": "چوارچێوە",
    "paint.brushMode.brush": "فڵچە",
    "paint.eraserMode.eraser": "سڕەوە",
    "paint.fillMode.fill": "پڕکردنەوە",
    "paint.lineMode.line": "هێڵ",
    "paint.ovalMode.oval": "بازنە",
    "paint.rectMode.rect": "لاکێشە",
    "paint.reshapeMode.reshape": "شێوەکردنەوە",
    "paint.roundedRectMode.roundedRect": "لاکێشەی خڕ",
    "paint.selectMode.select": "دیاریکردن",
    "paint.textMode.text": "دەق",
    "paint.colorPicker.swap": "ئاڵوگۆڕ",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "lv": {
    "gui.backpack.header": "Mugursoma",
    "gui.backpack.errorBackpack": "Kļūda ielādējot mugursomu",
    "gui.backpack.loadingBackpack": "Ielādē ...",
    "gui.backpack.more": "Vairāk",
    "gui.backpack.emptyBackpack": "Mugursoma tukša",
    "gui.gui.costumesTab": "Tērpi",
    "gui.gui.soundsTab": "Skaņas",
    "gui.gui.backdropsTab": "Foni",
    "gui.gui.addExtension": "Pievienot paplašinājumu",
    "gui.costumeTab.addCostumeFromLibrary": "Izvēlēties tērpu",
    "gui.costumeLibrary.chooseACostume": "Izvēlēties tērpu",
    "gui.costumeTab.addBackdropFromLibrary": "Izvēlēties Fonu",
    "gui.costumeTab.addBlankCostume": "Zīmēt",
    "gui.costumeTab.addSurpriseCostume": "Pārsteigums",
    "gui.costumeTab.addFileBackdrop": "Augšupielādēt fonu",
    "gui.costumeTab.addFileCostume": "Augšupielādēt tērpu",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Apgriezt",
    "gui.soundEditor.stop": "Apturēt",
    "gui.soundEditor.sound": "Skaņa",
    "gui.soundEditor.play": "Atskaņot",
    "gui.soundEditor.save": "Saglabāt",
    "gui.soundEditor.undo": "Atsaukt",
    "gui.soundEditor.redo": "Atcelt atsaukšanu",
    "gui.soundEditor.faster": "Ātrāk",
    "gui.soundEditor.slower": "Lēnāk",
    "gui.soundEditor.echo": "Atbalss",
    "gui.soundEditor.robot": "Robots",
    "gui.soundEditor.louder": "Skaļāk",
    "gui.soundEditor.softer": "Klusāk",
    "gui.soundEditor.reverse": "Atpakaļgaitā",
    "gui.soundTab.recordSound": "Ierakstīt",
    "gui.soundTab.addSoundFromLibrary": "Izvēlēties skaņu",
    "gui.soundTab.surpriseSound": "Pārsteigums",
    "gui.soundTab.fileUploadSound": "Augšupielādēt skaņu",
    "gui.controls.stop": "Apturēt",
    "gui.controls.go": "Palaist",
    "gui.stageHeader.stageSizeUnFull": "Iziet no pilnekrāna režīma",
    "gui.SpriteInfo.show": "Rādīt",
    "gui.SpriteInfo.size": "Izmērs",
    "gui.directionPicker.rotationStyles.allAround": "Jebkurā virzienā",
    "gui.directionPicker.rotationStyles.leftRight": "Pa labi/Pa kreisi",
    "gui.directionPicker.rotationStyles.dontRotate": "Nerotēt",
    "gui.spriteSelectorItem.contextMenuDuplicate": "dublēt",
    "gui.spriteSelectorItem.contextMenuDelete": "dzēst",
    "gui.spriteSelectorItem.contextMenuExport": "eksportēt",
    "gui.spriteSelector.addSpriteFromPaint": "Zīmēt",
    "gui.spriteSelector.addSpriteFromSurprise": "Pārsteigums",
    "gui.spriteSelector.addSpriteFromFile": "Augšupielādēt gariņu",
    "gui.stageSelector.stage": "Skatuve",
    "gui.stageSelector.backdrops": "Foni",
    "gui.stageSelector.addBackdropFromPaint": "Zīmēt",
    "gui.stageSelector.addBackdropFromSurprise": "Pārsteigums",
    "gui.stageSelector.addBackdropFromFile": "Augšupielādēt fonu",
    "gui.modal.back": "Atpakaļ",
    "gui.library.filterPlaceholder": "Meklēt",
    "gui.libraryTags.all": "Viss",
    "gui.libraryTags.animals": "Dzīvnieki",
    "gui.libraryTags.dance": "Dejas",
    "gui.libraryTags.effects": "Efekti",
    "gui.libraryTags.fantasy": "Fantāzija",
    "gui.libraryTags.fashion": "Mode",
    "gui.libraryTags.food": "Ēdiens",
    "gui.libraryTags.indoors": "Iekštelpas",
    "gui.libraryTags.loops": "Loops",
    "gui.libraryTags.music": "Mūzika",
    "gui.libraryTags.notes": "Notis",
    "gui.libraryTags.outdoors": "Brīvā daba",
    "gui.libraryTags.patterns": "Patterns",
    "gui.libraryTags.people": "Cilvēki",
    "gui.libraryTags.percussion": "Perkusijas",
    "gui.libraryTags.space": "Kosmoss",
    "gui.libraryTags.sports": "Sports",
    "gui.libraryTags.underwater": "Zemūdens",
    "gui.libraryTags.voice": "Balss",
    "gui.libraryTags.wacky": "Wacky",
    "gui.libraryTags.animation": "Animācijas",
    "gui.libraryTags.art": "Māksla",
    "gui.libraryTags.games": "Spēles",
    "gui.libraryTags.stories": "Stāsti",
    "gui.libraryTags.letters": "Burti",
    "gui.soundLibrary.chooseASound": "Izvēlēties skaņu",
    "gui.SpriteInfo.spritePlaceholder": "Nosaukums",
    "gui.cards.more-things-to-try": "Izmēģināt citas lietas!",
    "gui.cards.see-more": "Skatīt vairāk",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Aizvērt",
    "gui.loader.message1": "Veido blokus...",
    "gui.loader.message2": "Ielādē gariņus ...",
    "gui.loader.message3": "Ielādē skaņas ...",
    "gui.loader.message4": "Ielādē paplašinājumus ...",
    "gui.loader.message5": "Sauc palīgā kaķus, lai ielādētu šo projektu ...",
    "gui.loader.message6": "Pārsūta nano ...",
    "gui.loader.message7": "Iefliltrē gobo ...",
    "gui.loader.message8": "Sagatavo emocijzīmes ...",
    "gui.loader.headline": "Ielādē projektu",
    "gui.cameraModal.cameraModalTitle": "Uzņemt foto",
    "gui.cameraModal.loadingCameraMessage": "Ielādē kameru ...",
    "gui.cameraModal.permissionRequest": "Lai izmantotu kameru, nepieciešama tava atļauja ",
    "gui.cameraModal.retakePhoto": "Uzņemt foto vēlreiz",
    "gui.cameraModal.save": "Saglabāt",
    "gui.cameraModal.takePhoto": "Uzņemt foto",
    "gui.cameraModal.loadingCaption": "Ielādē ...",
    "gui.cameraModal.enableCameraCaption": "Iespējot kameru",
    "gui.recordModal.title": "Ierakstīt skaņu",
    "gui.playbackStep.stopMsg": "Apturēt",
    "gui.playbackStep.playMsg": "Atskaņot",
    "gui.playbackStep.loadingMsg": "Ielādē ...",
    "gui.playbackStep.saveMsg": "Saglabāt",
    "gui.playbackStep.reRecordMsg": "Atkārtoti ierakstīt",
    "gui.webglModal.label": "Tavs Pārlūks Neatbalsta WebGL",
    "gui.webglModal.webgllink": "neatbalsta WebGL",
    "gui.prompt.cancel": "Atcelt",
    "gui.prompt.ok": "Labi",
    "gui.customProcedures.addAnInputNumberText": "Pievienot ievadi",
    "gui.customProcedures.addAnInputBoolean": "Pievienot ievadi",
    "gui.customProcedures.numberTextType": "skaitlis vai teksts",
    "gui.customProcedures.booleanType": "loģiskais lauks - jā/nē",
    "gui.customProcedures.addALabel": "Pievienot etiķeti",
    "gui.customProcedures.runWithoutScreenRefresh": "Palaist bez ekrāna atsvaidzināšanas",
    "gui.customProcedures.cancel": "Atcelt",
    "gui.customProcedures.ok": "Labi",
    "gui.extension.microbit.description": "Savieno savus projektus ar pasauli.",
    "gui.extension.music.name": "Mūzika",
    "gui.extension.music.description": "Spēlē instrumentus un bungas.",
    "gui.extension.pen.name": "Zīmulis",
    "gui.extension.pen.description": "Zīmē ar saviem gariņiem.",
    "gui.gui.variableScopeOptionAllSprites": "Visiem gariņiem",
    "gui.gui.variableScopeOptionSpriteOnly": "Tikai šim gariņam",
    "gui.gui.variablePromptAllSpritesMessage": "Šis mainīgais būs pieejams visiem gariņiem.",
    "gui.monitor.contextMenu.default": "normāls rādījums",
    "gui.monitor.contextMenu.large": "liels rādījums",
    "gui.monitor.contextMenu.slider": "slīdnis",
    "gui.monitor.contextMenu.import": "importēt",
    "gui.monitor.contextMenu.export": "eksportēt",
    "gui.opcodeLabels.direction": "virziens",
    "gui.opcodeLabels.xposition": "x pozīcija",
    "gui.opcodeLabels.yposition": "y pozīcija",
    "gui.opcodeLabels.size": "izmērs",
    "gui.opcodeLabels.costumename": "tērpa nosaukums",
    "gui.opcodeLabels.costumenumber": "tērpa numurs",
    "gui.opcodeLabels.backdropname": "fona nosaukums",
    "gui.opcodeLabels.backdropnumber": "fona numurs",
    "gui.opcodeLabels.volume": "skaļums",
    "gui.opcodeLabels.tempo": "temps",
    "gui.opcodeLabels.answer": "atbilde",
    "gui.opcodeLabels.loudness": "skaļums",
    "gui.opcodeLabels.year": "gads",
    "gui.opcodeLabels.month": "mēnesis",
    "gui.opcodeLabels.date": "datums",
    "gui.opcodeLabels.dayofweek": "nedēļas diena",
    "gui.opcodeLabels.hour": "stunda",
    "gui.opcodeLabels.minute": "minūte",
    "gui.opcodeLabels.second": "sekunde",
    "gui.opcodeLabels.timer": "taimeris",
    "music.categoryName": "Mūzika",
    "translate.categoryName": "Tulkot",
    "pen.categoryName": "Zīmulis",
    "pen.changeColorParam": "mainīt zīmuļa [COLOR_PARAM] par [VALUE]",
    "pen.changeHue": "mainīt zīmuļa krāsu par [HUE]",
    "pen.changeShade": "mainīt zīmuļa tumšumu par [SHADE]",
    "pen.changeSize": "mainīt zīmuļa izmēru par [SIZE]",
    "pen.clear": "notīrīt visu",
    "pen.colorMenu.brightness": "gaišums",
    "pen.colorMenu.color": "krāsa",
    "pen.colorMenu.saturation": "piesātinājums",
    "pen.colorMenu.transparency": "caurspīdīgums",
    "pen.penDown": "zīmulis piespiests",
    "pen.penUp": "zīmulis pacelts",
    "pen.setColor": "iestatīt zīmuļa krāsu uz [COLOR]",
    "pen.setColorParam": "iestatīt zīmuļa [COLOR_PARAM] uz [VALUE]",
    "pen.setHue": "iestatīt zīmuļa krāsu uz [HUE]",
    "pen.setShade": "iestatīt zīmuļa tumšumu uz [SHADE]",
    "pen.setSize": "iestatīt zīmuļa izmēru uz [SIZE]",
    "pen.stamp": "zīmogs",
    "music.changeTempo": "mainīt tempu par [TEMPO]",
    "music.drumBass": "(2) Basa bungas",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Kabasa",
    "music.drumClaves": "(9) Klaves",
    "music.drumClosedHiHat": "(6) Slēgtais Hi-Hat šķīvis",
    "music.drumConga": "(14) Konga",
    "music.drumCowbell": "(11) Zvans",
    "music.drumCrashCymbal": "(4) Crash šķīvji",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Roku plaukšķis",
    "music.drumOpenHiHat": "(5) Atvērts Hi-Hat šķīvis",
    "music.drumSideStick": "(3) Bungu malas piesitiens",
    "music.drumSnare": "(1) Solo bungas",
    "music.drumTambourine": "(7) Tamburīns",
    "music.drumTriangle": "(12) Trijstūris",
    "music.drumVibraslap": "(17) Vibra slaperis",
    "music.drumWoodBlock": "(10) Koka bloks",
    "music.getTempo": "temps",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Fagots",
    "music.instrumentCello": "(8) Čells",
    "music.instrumentChoir": "(15) Koris",
    "music.instrumentClarinet": "(10) Klarnete",
    "music.instrumentElectricGuitar": "(5) Elektriskā ģitāra",
    "music.instrumentElectricPiano": "(2) Elektriskās klavieres",
    "music.instrumentFlute": "(12) Flauta",
    "music.instrumentGuitar": "(4) Ģitāra",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Mūzikas lādīte",
    "music.instrumentOrgan": "(3) Ērģeles",
    "music.instrumentPiano": "(1)  Klavieres",
    "music.instrumentPizzicato": "(7) Pičkato",
    "music.instrumentSaxophone": "(11) Saksofons",
    "music.instrumentSteelDrum": "(18) Metāla bungas",
    "music.instrumentSynthLead": "(20) Galvenais sintezators",
    "music.instrumentSynthPad": "(21) Pavadošais sintezators",
    "music.instrumentTrombone": "(9) Trombons",
    "music.instrumentVibraphone": "(16) Ksilofons",
    "music.instrumentWoodenFlute": "(13) Koka flauta",
    "music.midiPlayDrumForBeats": "spēlēt bungas [DRUM] [BEATS] sitienus",
    "music.midiSetInstrument": "iestatīt instrumentu uz [INSTRUMENT]",
    "music.playDrumForBeats": "spēlēt bungas [DRUM] [BEATS] sitienus",
    "music.playNoteForBeats": "spēlēt noti [NOTE] [BEATS] sitienus",
    "music.restForBeats": "pauze [BEATS] sitienus",
    "music.setInstrument": "iestatīt instrumentu uz [INSTRUMENT]",
    "music.setTempo": "iestatīt tempu uz [TEMPO]",
    "videoSensing.categoryName": "Video uztveršana",
    "videoSensing.direction": "virziens",
    "videoSensing.motion": "kustība",
    "videoSensing.off": "izslēgt",
    "videoSensing.on": "ieslēgt",
    "videoSensing.onFlipped": "ieslēgt apgrieztu",
    "videoSensing.setVideoTransparency": "iestatīt video caurspīdīgumu uz [TRANSPARENCY]",
    "videoSensing.sprite": "gariņš",
    "videoSensing.stage": "skatuve",
    "videoSensing.videoOn": "video [ATTRIBUTE] uz [SUBJECT]",
    "videoSensing.videoToggle": "[VIDEO_STATE]video",
    "videoSensing.whenMotionGreaterThan": "kad video kustība > [REFERENCE]",
    "translate.translateBlock": "tulkot [WORDS] uz [LANGUAGE]",
    "translate.defaultTextToTranslate": "sveiki",
    "translate.viewerLanguage": "valoda",
    "text2speech.speakAndWaitBlock": "teikt [WORDS]",
    "text2speech.setVoiceBlock": "iestatīt balsi uz [VOICE]",
    "text2speech.setLanguageBlock": "iestatīt valodu uz [LANGUAGE]",
    "text2speech.alto": "alts",
    "text2speech.tenor": "tenors",
    "text2speech.squeak": "pīkstiens",
    "text2speech.giant": "milzis",
    "text2speech.kitten": "kaķēns",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "kad es dzirdu [PHRASE]",
    "speech.listenAndWait": "klausīties un gaidīt",
    "speech.defaultWhenIHearValue": "aiziet",
    "paint.paintEditor.hue": "Krāsa",
    "paint.paintEditor.saturation": "Piesātinājums",
    "paint.paintEditor.brightness": "Gaišums",
    "gui.comingSoon.message1": "Neuztraucies, mēs pie tā strādājam {emoji}",
    "gui.comingSoon.message2": "Drīzumā...",
    "gui.comingSoon.message3": "Mēs pie tā strādājam {emoji}",
    "paint.paintEditor.fill": "Aizpildīt",
    "paint.paintEditor.costume": "Tērps",
    "paint.paintEditor.group": "Grupēt",
    "paint.paintEditor.ungroup": "Atgrupēt",
    "paint.paintEditor.undo": "Atsaukt",
    "paint.paintEditor.redo": "Atcelt atsaukšanu",
    "paint.paintEditor.forward": "Uz priekšu",
    "paint.paintEditor.backward": "Atpakaļ",
    "paint.paintEditor.front": "Uz priekšpusi",
    "paint.paintEditor.back": "Uz aizmuguri",
    "paint.paintEditor.more": "Vairāk",
    "paint.modeTools.brushSize": "Izmērs",
    "paint.modeTools.eraserSize": "Dzēšgumijas izmērs",
    "paint.modeTools.copy": "Kopēt",
    "paint.modeTools.paste": "Ielīmēt",
    "paint.modeTools.delete": "Dzēst",
    "paint.modeTools.curved": "Izliekts",
    "paint.modeTools.pointed": "Lauzts",
    "paint.modeTools.thickness": "Biezums",
    "paint.modeTools.flipHorizontal": "Apmest horizontāli",
    "paint.modeTools.flipVertical": "Apmest vertikāli",
    "paint.modeTools.filled": "Aizpildīts",
    "paint.modeTools.outlined": "Neaizpildīts",
    "paint.paintEditor.bitmap": "Pārvērst rastra grafikā",
    "paint.paintEditor.vector": "Pārvērst vektorgrafikā",
    "paint.paintEditor.stroke": "Kontūra",
    "paint.brushMode.brush": "Ota",
    "paint.eraserMode.eraser": "Dzēšgumija",
    "paint.fillMode.fill": "Aizpildīt",
    "paint.lineMode.line": "Līnija",
    "paint.ovalMode.oval": "Aplis",
    "paint.rectMode.rect": "Taisnstūris",
    "paint.reshapeMode.reshape": "Pārveidot",
    "paint.roundedRectMode.roundedRect": "Noapaļots taisnstūris",
    "paint.selectMode.select": "Atlasīt",
    "paint.textMode.text": "Teksts",
    "paint.colorPicker.swap": "Mainīt",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "lt": {
    "gui.backpack.header": "Kuprinė",
    "gui.backpack.errorBackpack": "Klaida iškraustant kuprinę",
    "gui.backpack.loadingBackpack": "Kraunasi...",
    "gui.backpack.more": "Daugiau",
    "gui.backpack.emptyBackpack": "Kuprinė tuščia",
    "gui.gui.costumesTab": "Kaukės",
    "gui.gui.soundsTab": "Garsai",
    "gui.gui.backdropsTab": "Fonai",
    "gui.gui.addExtension": "Pridėti plėtinį",
    "gui.costumeTab.addCostumeFromLibrary": "Pasirink kaukę",
    "gui.costumeLibrary.chooseACostume": "Pasirink kaukę",
    "gui.costumeTab.addBackdropFromLibrary": "Pasirink foną",
    "gui.costumeTab.addBlankCostume": "Piešti",
    "gui.costumeTab.addSurpriseCostume": "Siurprizas",
    "gui.costumeTab.addFileBackdrop": "Įkelti foną",
    "gui.costumeTab.addFileCostume": "Įkelti kaukę",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Apkarpyti",
    "gui.soundEditor.stop": "Stop",
    "gui.soundEditor.sound": "Garsas",
    "gui.soundEditor.play": "Paleisti",
    "gui.soundEditor.save": "Išsaugoti",
    "gui.soundEditor.undo": "Atšaukti",
    "gui.soundEditor.redo": "Grąžinti",
    "gui.soundEditor.faster": "Greičiau",
    "gui.soundEditor.slower": "Lėčiau",
    "gui.soundEditor.echo": "Aidas",
    "gui.soundEditor.robot": "Robotas",
    "gui.soundEditor.louder": "Garsiau",
    "gui.soundEditor.softer": "Tyliau",
    "gui.soundEditor.reverse": "Atbulai",
    "gui.soundTab.recordSound": "Įrašyti",
    "gui.soundTab.addSoundFromLibrary": "Pasirink garsą",
    "gui.soundTab.surpriseSound": "Siurprizas",
    "gui.soundTab.fileUploadSound": "Įkelti garsą",
    "gui.controls.stop": "Stop",
    "gui.controls.go": "Pradėti",
    "gui.stageHeader.stageSizeUnFull": "Išjungti rodymą per visą ekraną",
    "gui.SpriteInfo.show": "Rodyti",
    "gui.SpriteInfo.size": "Dydis",
    "gui.directionPicker.rotationStyles.allAround": "Aplinkui",
    "gui.directionPicker.rotationStyles.leftRight": "kairėn/dešinėn",
    "gui.directionPicker.rotationStyles.dontRotate": "Nesisukti",
    "gui.spriteSelectorItem.contextMenuDuplicate": "padaryti kopiją",
    "gui.spriteSelectorItem.contextMenuDelete": "šalinti",
    "gui.spriteSelectorItem.contextMenuExport": "eksportuoti",
    "gui.spriteSelector.addSpriteFromPaint": "Piešti",
    "gui.spriteSelector.addSpriteFromSurprise": "Siurprizas",
    "gui.spriteSelector.addSpriteFromFile": "Įkelti veikėją",
    "gui.stageSelector.stage": "Scena",
    "gui.stageSelector.backdrops": "Fonai",
    "gui.stageSelector.addBackdropFromPaint": "Piešti",
    "gui.stageSelector.addBackdropFromSurprise": "Siurprizas",
    "gui.stageSelector.addBackdropFromFile": "Įkelti foną",
    "gui.modal.back": "Atgal",
    "gui.library.filterPlaceholder": "Paieška",
    "gui.libraryTags.all": "Viskas",
    "gui.libraryTags.animals": "Gyvūnai",
    "gui.libraryTags.dance": "Šokis",
    "gui.libraryTags.effects": "Efektai",
    "gui.libraryTags.fantasy": "Fantazija",
    "gui.libraryTags.fashion": "Mada",
    "gui.libraryTags.food": "Maistas",
    "gui.libraryTags.indoors": "Viduje",
    "gui.libraryTags.loops": "Ciklai",
    "gui.libraryTags.music": "Muzika",
    "gui.libraryTags.notes": "Natos",
    "gui.libraryTags.outdoors": "Lauke",
    "gui.libraryTags.patterns": "Raštai",
    "gui.libraryTags.people": "Žmonės",
    "gui.libraryTags.percussion": "Mušamieji",
    "gui.libraryTags.space": "Kosmosas",
    "gui.libraryTags.sports": "Sportas",
    "gui.libraryTags.underwater": "Povandeninis pasaulis",
    "gui.libraryTags.voice": "Balsas",
    "gui.libraryTags.wacky": "Pašėlę",
    "gui.libraryTags.animation": "Animacija",
    "gui.libraryTags.art": "Menas",
    "gui.libraryTags.games": "Žaidimai",
    "gui.libraryTags.stories": "Pasakojimai",
    "gui.libraryTags.letters": "Raidės",
    "gui.soundLibrary.chooseASound": "Pasirink garsą",
    "gui.SpriteInfo.spritePlaceholder": "Pavadinimas",
    "gui.cards.more-things-to-try": "Išbandyk daugiau dalykų!",
    "gui.cards.see-more": "Žiūrėti daugiau",
    "gui.cards.shrink": "Sumažinti",
    "gui.cards.expand": "Išskleisti",
    "gui.cards.close": "Uždaryti",
    "gui.loader.message1": "Kuriami blokai…",
    "gui.loader.message2": "Įkeliami veikėjai…",
    "gui.loader.message3": "Įkeliami garsai…",
    "gui.loader.message4": "Įkeliami plėtiniai…",
    "gui.loader.message5": "Gaudome kates…",
    "gui.loader.message6": "Nano kažkur kraustosi…",
    "gui.loader.message7": "Pripučiama Gobo…",
    "gui.loader.message8": "Ruošiame emoji…",
    "gui.loader.headline": "Kraunamas projektas",
    "gui.cameraModal.cameraModalTitle": "Nufotografuoti",
    "gui.cameraModal.loadingCameraMessage": "Įkeliama vaizdo kamera...",
    "gui.cameraModal.permissionRequest": "Mums reikia jūsų leidimo naudoti kamerą",
    "gui.cameraModal.retakePhoto": "Fotografuoti iš naujo",
    "gui.cameraModal.save": "Išsaugoti",
    "gui.cameraModal.takePhoto": "Fotografuoti",
    "gui.cameraModal.loadingCaption": "Kraunasi...",
    "gui.cameraModal.enableCameraCaption": "Įgalinti kamerą",
    "gui.recordModal.title": "Įrašyti garsą",
    "gui.playbackStep.stopMsg": "Stop",
    "gui.playbackStep.playMsg": "Paleisti",
    "gui.playbackStep.loadingMsg": "Kraunasi...",
    "gui.playbackStep.saveMsg": "Išsaugoti",
    "gui.playbackStep.reRecordMsg": "Įrašyti iš naujo",
    "gui.webglModal.label": "Ši naršyklė nepalaiko WebGL",
    "gui.webglModal.webgllink": "nepalaiko WebGL",
    "gui.prompt.cancel": "Atšaukti",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Pridėti įvestį",
    "gui.customProcedures.addAnInputBoolean": "Pridėti įvestį",
    "gui.customProcedures.numberTextType": "skaičius arba tekstas",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Pridėti etiketę",
    "gui.customProcedures.runWithoutScreenRefresh": "Vykdyti be ekrano atnaujinimo",
    "gui.customProcedures.cancel": "Atšaukti",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Connect your projects with the world.",
    "gui.extension.music.name": "Muzika",
    "gui.extension.music.description": "Groti instrumentais ir būgnais.",
    "gui.extension.pen.name": "Pieštukas",
    "gui.extension.pen.description": "Piešti savo veikėjus.",
    "gui.gui.variableScopeOptionAllSprites": "Visiems veikėjams",
    "gui.gui.variableScopeOptionSpriteOnly": "Tik šiam veikėjui",
    "gui.gui.variablePromptAllSpritesMessage": "Šis kintamasis bus prieinamas visiems veikėjams",
    "gui.monitor.contextMenu.default": "normal readout",
    "gui.monitor.contextMenu.large": "large readout",
    "gui.monitor.contextMenu.slider": "slinktis",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "kryptis",
    "gui.opcodeLabels.xposition": "x koordinatė",
    "gui.opcodeLabels.yposition": "y koordinatė",
    "gui.opcodeLabels.size": "dydis",
    "gui.opcodeLabels.costumename": "kaukės pavadinimas",
    "gui.opcodeLabels.costumenumber": "kaukės numeris",
    "gui.opcodeLabels.backdropname": "fono pavadinimas",
    "gui.opcodeLabels.backdropnumber": "fono numeris",
    "gui.opcodeLabels.volume": "garsas",
    "gui.opcodeLabels.tempo": "tempas",
    "gui.opcodeLabels.answer": "atsakymas",
    "gui.opcodeLabels.loudness": "garsumas",
    "gui.opcodeLabels.year": "metai",
    "gui.opcodeLabels.month": "mėnuo",
    "gui.opcodeLabels.date": "data",
    "gui.opcodeLabels.dayofweek": "savaitės diena",
    "gui.opcodeLabels.hour": "valanda",
    "gui.opcodeLabels.minute": "minutė",
    "gui.opcodeLabels.second": "sekundė",
    "gui.opcodeLabels.timer": "laikmatis",
    "music.categoryName": "Muzika",
    "translate.categoryName": "Išversti",
    "pen.categoryName": "Pieštukas",
    "pen.changeColorParam": "keisk pieštuko [COLOR_PARAM]per[VALUE]",
    "pen.changeHue": "keisk pieštuko spalvą per [HUE]",
    "pen.changeShade": "keisk pieštuko atspalvį per [SHADE]",
    "pen.changeSize": "keisk pieštuko dydį per [SIZE]",
    "pen.clear": "ištrinti viską",
    "pen.colorMenu.brightness": "šviesumas",
    "pen.colorMenu.color": "spalva",
    "pen.colorMenu.saturation": "sodrumas",
    "pen.colorMenu.transparency": "permatomumas",
    "pen.penDown": "pieštukas pieš",
    "pen.penUp": "pieštukas nebepieš",
    "pen.setColor": "keisti pieštuko spalvą į [COLOR]",
    "pen.setColorParam": "set pen [COLOR_PARAM] to [VALUE]",
    "pen.setHue": "set pen color to [HUE]",
    "pen.setShade": "keisti pieštuko atspalvį į [SHADE]",
    "pen.setSize": "keisti pieštuko dydį į [SIZE]",
    "pen.stamp": "antspaudas",
    "music.changeTempo": "tempą padidink [TEMPO]",
    "music.drumBass": "(2) Bosinis būgnas",
    "music.drumBongo": "(13) Bongo būgnas",
    "music.drumCabasa": "(15) Kabasa",
    "music.drumClaves": "(9)  Strypeliai",
    "music.drumClosedHiHat": "(6) Uždaras haihetas",
    "music.drumConga": "(14) Konga",
    "music.drumCowbell": "(11) Skrabalas",
    "music.drumCrashCymbal": "(4) „Crash“ lėkštės",
    "music.drumCuica": "(18) Kuika",
    "music.drumGuiro": "(16) Giras",
    "music.drumHandClap": "(8) Pliaukštelėjimas delnais",
    "music.drumOpenHiHat": "(5) Atviras haihetas",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Mažasis būgnas",
    "music.drumTambourine": "(7) Tambūrinas",
    "music.drumTriangle": "(12) Trikampis",
    "music.drumVibraslap": "(17) Vibraslapis",
    "music.drumWoodBlock": "(10) Trinkelė",
    "music.getTempo": "tempas",
    "music.instrumentBass": "(6) Bosas",
    "music.instrumentBassoon": "(14) Fagotas",
    "music.instrumentCello": "(8) Violončelė",
    "music.instrumentChoir": "(15) Choras",
    "music.instrumentClarinet": "(10) Klarnetas",
    "music.instrumentElectricGuitar": "(5) Elektrinė gitara",
    "music.instrumentElectricPiano": "(2) Sintezatorius",
    "music.instrumentFlute": "(12) Fleita",
    "music.instrumentGuitar": "(4) Gitara",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Muzikinė dėžutė",
    "music.instrumentOrgan": "(3) Vargonai",
    "music.instrumentPiano": "(1) Pianinas",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saksofonas",
    "music.instrumentSteelDrum": "(18) Plieno būgnas",
    "music.instrumentSynthLead": "(20) Solinis sintezatorius",
    "music.instrumentSynthPad": "(21) Sintezatorius",
    "music.instrumentTrombone": "(9) Trombonas",
    "music.instrumentVibraphone": "(16) Vibrafonas",
    "music.instrumentWoodenFlute": "(13) Medinė fleita",
    "music.midiPlayDrumForBeats": "groti būgnu [DRUM] tiek taktų: [BEATS]",
    "music.midiSetInstrument": "nustatyti instrumentą į [INSTRUMENT]",
    "music.playDrumForBeats": "groti būgnu [DRUM] tiek taktų: [BEATS]",
    "music.playNoteForBeats": "groti natą [NOTE] tiek taktų: [BEATS]",
    "music.restForBeats": "pauzė tiek taktų: [BEATS]",
    "music.setInstrument": "instrumentą nustatyti į [INSTRUMENT]",
    "music.setTempo": "keisti tempą į [TEMPO] ",
    "videoSensing.categoryName": "Vaizdo jutikliai",
    "videoSensing.direction": "kryptis",
    "videoSensing.motion": "judesys",
    "videoSensing.off": "Išjungti",
    "videoSensing.on": "Įjungti",
    "videoSensing.onFlipped": "on flipped",
    "videoSensing.setVideoTransparency": "nustatyk video permatomumą į[TRANSPARENCY]",
    "videoSensing.sprite": "veikėjas",
    "videoSensing.stage": "scena",
    "videoSensing.videoOn": "video [ATTRIBUTE]iš[SUBJECT]",
    "videoSensing.videoToggle": "perjungti video į [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "when video motion > [REFERENCE]",
    "translate.translateBlock": "išversti [WORDS] į [LANGUAGE]",
    "translate.defaultTextToTranslate": "sveiki",
    "translate.viewerLanguage": "kalba",
    "text2speech.speakAndWaitBlock": "sakyti [WORDS]",
    "text2speech.setVoiceBlock": "keisti balsą į [VOICE]",
    "text2speech.setLanguageBlock": "keisti kalbą į [LANGUAGE]",
    "text2speech.alto": "alto balsas",
    "text2speech.tenor": "tenoro balsas",
    "text2speech.squeak": "cyptelėjimas",
    "text2speech.giant": "milžino balsas",
    "text2speech.kitten": "kačiukas",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "kai išgirstu [PHRASE]",
    "speech.listenAndWait": "paklausyti ir laukti",
    "speech.defaultWhenIHearValue": "pirmyn",
    "paint.paintEditor.hue": "Spalva",
    "paint.paintEditor.saturation": "Sodrumas",
    "paint.paintEditor.brightness": "Šviesumas",
    "gui.comingSoon.message1": "Ramybės, mes šį bei tą tobuliname {emoji}",
    "gui.comingSoon.message2": "Netrukus...",
    "gui.comingSoon.message3": "Šį bei tą tobuliname {emoji}",
    "paint.paintEditor.fill": "Užpildymas",
    "paint.paintEditor.costume": "Kaukė",
    "paint.paintEditor.group": "Grupuoti",
    "paint.paintEditor.ungroup": "Ungroup",
    "paint.paintEditor.undo": "Atšaukti",
    "paint.paintEditor.redo": "Grąžinti",
    "paint.paintEditor.forward": "Forward",
    "paint.paintEditor.backward": "Backward",
    "paint.paintEditor.front": "Front",
    "paint.paintEditor.back": "Atgal",
    "paint.paintEditor.more": "Daugiau",
    "paint.modeTools.brushSize": "Dydis",
    "paint.modeTools.eraserSize": "Trintuko dydis",
    "paint.modeTools.copy": "Kopijuoti",
    "paint.modeTools.paste": "Įklijuoti",
    "paint.modeTools.delete": "Šalinti",
    "paint.modeTools.curved": "Lenktas",
    "paint.modeTools.pointed": "Tiesus",
    "paint.modeTools.thickness": "Storis",
    "paint.modeTools.flipHorizontal": "Apversti horizontaliai",
    "paint.modeTools.flipVertical": "Apversti vertikaliai",
    "paint.modeTools.filled": "Užpildyta",
    "paint.modeTools.outlined": "Su kontūru",
    "paint.paintEditor.bitmap": "Keisti į piešinį taškais",
    "paint.paintEditor.vector": "Keisti į piešinį linijomis",
    "paint.paintEditor.stroke": "Kontūrai",
    "paint.brushMode.brush": "Teptukas",
    "paint.eraserMode.eraser": "Trintukas",
    "paint.fillMode.fill": "Užpildyti",
    "paint.lineMode.line": "Linija",
    "paint.ovalMode.oval": "Apskritimas",
    "paint.rectMode.rect": "Stačiakampis",
    "paint.reshapeMode.reshape": "Pakeisti formą",
    "paint.roundedRectMode.roundedRect": "Užapvalintas stačiakampis",
    "paint.selectMode.select": "Pažymėti",
    "paint.textMode.text": "Tekstas",
    "paint.colorPicker.swap": "Apkeisti",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "hu": {
    "gui.gui.project": "Projekt",
    "gui.gui.newItem": "Új Projekt",
    "gui.gui.load": "Projekt betöltése",
    "gui.gui.save": "Projekt mentése",
    "gui.gui.saveAs": "Mentés másként",
    "gui.gui.cacheList": "Cache lista",
    "gui.gui.inCache": "Cacheben",
    "gui.gui.learn": "Tanulás",
    "gui.gui.clearRecord": "Rekord törlése",
    "gui.gui.onlineLearn": "Hivatalos dokumentáció",
    "gui.gui.onlineForum": "Online Fórum",
    "gui.gui.videoTutorial": "Oktató Videók",
    "gui.gui.offlineLearn": "Példaprogramok",
    "gui.menuBar.turboModeOff": "Turbó mód kikapcsolása",
    "gui.menuBar.turboModeOn": "Turbó mód bekapcsolása",
    "gui.menuBar.edit": "Szerkesztés",
    "gui.menuBar.restoreSprite": "Szereplő visszaállítása",
    "gui.menuBar.restoreSound": "Hang visszaállítása",
    "gui.menuBar.restoreCostume": "Jelmez visszaállítása",
    "gui.menuBar.restoreBackdrop": "Háttér visszállítása",
    "gui.editorMind.restore": "Visszaállítás",
    "gui.turboMode.active": "Turbó Mód",
    "gui.gui.connectDevice": "Eszköz csatlakoztatása",
    "gui.gui.disconnectDevice": "Eszköz leválasztása",
    "gui.gui.installSerialportDriver": "Soros port illesztőprogramjának telepítése",
    "gui.gui.openDM": "Eszközkezelő megnyitása",
    "gui.gui.restoreSetting": "Eszköz alapbeállításainak visszaállítása",
    "gui.gui.updateTips": "Frissítési tanácsok",
    "gui.gui.newVersion": "Legújabb verzió",
    "gui.gui.downloadUpdate": "Letöltés frissítéshez",
    "gui.gui.versionUpdate": "Verzió frissítése",
    "gui.gui.isCheckMd5": "Fájlok hitelesítése",
    "gui.gui.downloading": "Letöltés",
    "gui.gui.updateError": "Frissítési hiba",
    "gui.setting.feedbackMenu": "Visszajelzés",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.setting.officialWebsit": "Hivatalos weboldal",
    "gui.setting.officialQQGroup": "Hivatalos QQ csoport",
    "gui.setting.wechatOfficialAccounts": "Hivatalos WeChat Felhasználó",
    "gui.setting.currentVersion": "Jelenlegi verzió",
    "gui.setting.checkForUpdate": "Frissítések keresése",
    "gui.setting.remindWhenUpdate": "Automatikus frissítés",
    "gui.setting.currentIsLatest": "A jelenleg a legfrissebb verzió van telepítve",
    "gui.setting.latestVersion": "Legfrissebb verzió",
    "gui.setting.download": "Frissítés letöltése a weboldalról",
    "gui.setting.language": "Nyelv",
    "gui.setting.uploadCsv": "xlsx feltöltése",
    "gui.setting.theme": "Téma",
    "gui.setting.feedback": "Kapcsolat",
    "gui.setting.email": "E-mail",
    "gui.setting.opinionFeedback": "Visszajelzés",
    "gui.variableMc.variableName": "Változó név",
    "gui.variableMc.variableOff": "Mégsem",
    "gui.variableMc.variableOn": "Megerősít",
    "gui.variableMc.newVariable": "Új változó",
    "gui.setting.helpUsTranslate": "Segíts a fordításban",
    "gui.setting.checkUpdate": "Frissítés keresése",
    "gui.setting.ok": "Rendben",
    "gui.setting.languageSetting": "Nyelv",
    "gui.setting.themeSetting": "Téma",
    "gui.setting.cacheSetting": "Cache beállítások",
    "gui.setting.versionUpdate": "Verzió frissítése",
    "gui.setting.connectUsTitle": "Kapcsolatfelvétel",
    "gui.setting.uploadAttachment": "Melléklet feltöltése",
    "gui.setting.displayTitle": "Kijelző beállítása",
    "gui.setting.fontSizeSetting": "Betűméret",
    "gui.setting.restartEffect": "újraindítás után lép életbe",
    "gui.setting.fontSizeLarge": "Nagy",
    "gui.setting.fontSizeMiddle": "Közepes",
    "gui.setting.fontSizeSmall": "Kicsi",
    "gui.setting.onlineRepair": "Online javítóeszköz, megnyitáshoz kattins ide >",
    "gui.setting.cacheSwitch": "Cache (megnyitás/bezárás)",
    "gui.setting.freeCache": "Cache felszabadítása",
    "gui.setting.spaceCache": "Rögzített időközönként",
    "gui.setting.cacheWay": "Cache módja",
    "gui.setting.interval": "Intervallum",
    "gui.setting.freeTime": "Üresjárat",
    "gui.setting.second": "másodperc",
    "gui.setting.minute": "perc",
    "gui.backpack.header": "Tálca",
    "gui.backpack.errorBackpack": "Hiba a tálca betöltésekor",
    "gui.backpack.loadingBackpack": "Betöltés...",
    "gui.backpack.more": "Több",
    "gui.backpack.emptyBackpack": "A tálca üres",
    "gui.gui.costumesTab": "Jelmezek",
    "gui.gui.soundsTab": "Hangok",
    "gui.gui.backdropsTab": "Hátterek",
    "gui.gui.addExtension": "Bővítmény hozzáadása",
    "gui.costumeTab.addCostumeFromLibrary": "Válassz jelmezt",
    "gui.costumeLibrary.chooseACostume": "Válassz jelmezt",
    "gui.costumeTab.addBackdropFromLibrary": "Válassz hátteret",
    "gui.costumeTab.addBlankCostume": "Festés",
    "gui.costumeTab.addSurpriseCostume": "Meglepetés",
    "gui.costumeTab.addFileBackdrop": "Háttér feltöltése",
    "gui.costumeTab.addFileCostume": "Jelmez feltöltése",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Levágás",
    "gui.soundEditor.stop": "Állj",
    "gui.soundEditor.sound": "Hang",
    "gui.soundEditor.play": "Lejátszás",
    "gui.soundEditor.save": "Mentés",
    "gui.soundEditor.undo": "Visszavonás",
    "gui.soundEditor.redo": "Mégis",
    "gui.soundEditor.faster": "Gyorsabban",
    "gui.soundEditor.slower": "Lassabban",
    "gui.soundEditor.echo": "Visszhang",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Hangosabban",
    "gui.soundEditor.softer": "Lágyabban",
    "gui.soundEditor.reverse": "Visszafelé",
    "gui.soundTab.recordSound": "Felvétel",
    "gui.soundTab.addSoundFromLibrary": "Válassz hangot",
    "gui.soundTab.surpriseSound": "Meglepetés",
    "gui.soundTab.fileUploadSound": "Hang feltöltése",
    "gui.controls.stop": "Állj",
    "gui.controls.go": "Indulás",
    "gui.stageHeader.stageSizeUnFull": "Teljes képernyős üzemmód elhagyása",
    "gui.SpriteInfo.show": "Megjelenítés",
    "gui.SpriteInfo.size": "Méret",
    "gui.directionPicker.rotationStyles.allAround": "Mindenfelé",
    "gui.directionPicker.rotationStyles.leftRight": "Balra-jobbra",
    "gui.directionPicker.rotationStyles.dontRotate": "Nem foroghat",
    "gui.spriteSelectorItem.contextMenuDuplicate": "duplikálás",
    "gui.spriteSelectorItem.contextMenuDelete": "törlés",
    "gui.spriteSelectorItem.contextMenuExport": "exportálás",
    "gui.spriteSelector.addSpriteFromPaint": "Festés",
    "gui.spriteSelector.addSpriteFromSurprise": "Meglepetés",
    "gui.spriteSelector.addSpriteFromFile": "Szereplő feltöltés",
    "gui.stageSelector.stage": "Játéktér",
    "gui.stageSelector.backdrops": "Hátterek",
    "gui.stageSelector.addBackdropFromPaint": "Festés",
    "gui.stageSelector.addBackdropFromSurprise": "Meglepetés",
    "gui.stageSelector.addBackdropFromFile": "Háttér feltöltése",
    "gui.modal.back": "Vissza",
    "gui.library.filterPlaceholder": "Keresés",
    "gui.libraryTags.all": "Minden",
    "gui.libraryTags.animals": "Állatok",
    "gui.libraryTags.dance": "Tánc",
    "gui.libraryTags.effects": "Hatások",
    "gui.libraryTags.fantasy": "Fantázia",
    "gui.libraryTags.fashion": "Divat",
    "gui.libraryTags.food": "Étel",
    "gui.libraryTags.indoors": "Beltér",
    "gui.libraryTags.loops": "Ciklusok",
    "gui.libraryTags.music": "Zene",
    "gui.libraryTags.notes": "Hangjegyek",
    "gui.libraryTags.outdoors": "Kültér",
    "gui.libraryTags.patterns": "Minták",
    "gui.libraryTags.people": "Emberek",
    "gui.libraryTags.percussion": "Ütőhangszerek",
    "gui.libraryTags.space": "Űr",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Vízalatt",
    "gui.libraryTags.voice": "Hang",
    "gui.libraryTags.wacky": "Hóbortos",
    "gui.libraryTags.animation": "Animáció",
    "gui.libraryTags.art": "Művészet",
    "gui.libraryTags.games": "Játékok",
    "gui.libraryTags.stories": "Történetek",
    "gui.libraryTags.letters": "Betűk",
    "gui.soundLibrary.chooseASound": "Válassz hangot",
    "gui.SpriteInfo.spritePlaceholder": "Név",
    "gui.cards.more-things-to-try": "Még több minden kipróbálható!",
    "gui.cards.see-more": "Még több",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Bezár",
    "gui.loader.message1": "Blokkok létrhozása ...",
    "gui.loader.message2": "Szereplők betöltése ...",
    "gui.loader.message3": "Hangok betöltése ...",
    "gui.loader.message4": "Bővítmények betöltése ...",
    "gui.loader.message5": "Vadászó macskák...",
    "gui.loader.message6": "A nanók átvitele ...",
    "gui.loader.message7": "A gobók megnövelése ...",
    "gui.loader.message8": "Emojik előkészítése ...",
    "gui.loader.headline": "Projekt betöltése",
    "gui.cameraModal.cameraModalTitle": "Készíts egy fényképet ",
    "gui.cameraModal.loadingCameraMessage": "Kamera betöltése...",
    "gui.cameraModal.permissionRequest": "Az engedélyedre van szükségünk a kamerád használatához",
    "gui.cameraModal.retakePhoto": "Újra készítsd el a fotót",
    "gui.cameraModal.save": "Mentés",
    "gui.cameraModal.takePhoto": "Készíts egy képet",
    "gui.cameraModal.loadingCaption": "Betöltés...",
    "gui.cameraModal.enableCameraCaption": "Kamera engedélyezése",
    "gui.recordModal.title": "Hang felvétele",
    "gui.playbackStep.stopMsg": "Állj",
    "gui.playbackStep.playMsg": "Lejátszás",
    "gui.playbackStep.loadingMsg": "Betöltés...",
    "gui.playbackStep.saveMsg": "Mentés",
    "gui.playbackStep.reRecordMsg": "Újrafelvétel",
    "gui.webglModal.label": "A böngésződ nem támogatja a WebGL-t",
    "gui.webglModal.webgllink": "WebGL nem támogatott",
    "gui.prompt.cancel": "Mégsem",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Bemenet hozzáadása",
    "gui.customProcedures.addAnInputBoolean": "Bemenet hozzáadása",
    "gui.customProcedures.numberTextType": "szám vagy szöveg",
    "gui.customProcedures.booleanType": "logikai",
    "gui.customProcedures.addALabel": "Címke hozzáadás",
    "gui.customProcedures.runWithoutScreenRefresh": "Futtatás képernyőfrissítés nélkül",
    "gui.customProcedures.cancel": "Mégsem",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Kapcsold össze a programjaidat a világgal.",
    "gui.extension.music.name": "Zene",
    "gui.extension.music.description": "Játssz a hangszereken és a dobokon.",
    "gui.extension.pen.name": "Toll",
    "gui.extension.pen.description": "Rajzolj a szereplőkkel",
    "gui.gui.variableScopeOptionAllSprites": "Minden szereplőé",
    "gui.gui.variableScopeOptionSpriteOnly": "A kiválasztott szereplőé",
    "gui.gui.variablePromptAllSpritesMessage": "Ez a változó minden szereplőek elérhető lesz",
    "gui.monitor.contextMenu.default": "normál kijelző",
    "gui.monitor.contextMenu.large": "nagy kijelző",
    "gui.monitor.contextMenu.slider": "csúszka",
    "gui.monitor.contextMenu.import": "importálás",
    "gui.monitor.contextMenu.export": "exportálás",
    "gui.opcodeLabels.direction": "iránya",
    "gui.opcodeLabels.xposition": "x helyzete",
    "gui.opcodeLabels.yposition": "y helyzete",
    "gui.opcodeLabels.size": "mérete",
    "gui.opcodeLabels.costumename": "jelmez neve",
    "gui.opcodeLabels.costumenumber": "jelmez száma",
    "gui.opcodeLabels.backdropname": "háttér neve",
    "gui.opcodeLabels.backdropnumber": "háttér száma",
    "gui.opcodeLabels.volume": "hangereje",
    "gui.opcodeLabels.tempo": "tempó",
    "gui.opcodeLabels.answer": "válasz",
    "gui.opcodeLabels.loudness": "hangerő",
    "gui.opcodeLabels.year": "év",
    "gui.opcodeLabels.month": "hónap",
    "gui.opcodeLabels.date": "nap",
    "gui.opcodeLabels.dayofweek": "hét napja",
    "gui.opcodeLabels.hour": "óra",
    "gui.opcodeLabels.minute": "perc",
    "gui.opcodeLabels.second": "másodperc",
    "gui.opcodeLabels.timer": "időmérő",
    "music.categoryName": "Zene",
    "translate.categoryName": "Fordítás",
    "pen.categoryName": "Toll",
    "pen.changeColorParam": "toll [COLOR_PARAM] változzon [VALUE]",
    "pen.changeHue": "toll színe változzon [HUE]",
    "pen.changeShade": "toll árnyalata változzon [SHADE]",
    "pen.changeSize": "toll mérete változzon [SIZE]",
    "pen.clear": "töröld a rajzokat",
    "pen.colorMenu.brightness": "fényerő",
    "pen.colorMenu.color": "szín",
    "pen.colorMenu.saturation": "telítettség",
    "pen.colorMenu.transparency": "átlátszóság",
    "pen.penDown": "tollat tedd le",
    "pen.penUp": "tollat emeld fel",
    "pen.setColor": "toll színe legyen [COLOR]",
    "pen.setColorParam": "toll [COLOR_PARAM] legyen [VALUE]",
    "pen.setHue": "toll színe legyen [HUE]",
    "pen.setShade": "toll árnyalata legyen [SHADE]",
    "pen.setSize": "toll mérete legyen [SIZE]",
    "pen.stamp": "készíts lenyomatot",
    "music.changeTempo": "tempó változzon [TEMPO]",
    "music.drumBass": "(2) Nagydob",
    "music.drumBongo": "(13) Bongó",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Kasztanyetta",
    "music.drumClosedHiHat": "(6) Zárt Hi-Hat",
    "music.drumConga": "(14) Konga",
    "music.drumCowbell": "(11) Kolomp",
    "music.drumCrashCymbal": "(4) Cintányér",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Taps",
    "music.drumOpenHiHat": "(5) Nyitott Hi-Hat",
    "music.drumSideStick": "(3) Dob pereme",
    "music.drumSnare": "(1) Pergődob",
    "music.drumTambourine": "(7) Tamburin",
    "music.drumTriangle": "(12) Triangulum",
    "music.drumVibraslap": "(17) Kereplő",
    "music.drumWoodBlock": "(10) Fa kocka",
    "music.getTempo": "tempó",
    "music.instrumentBass": "(6) Basszus",
    "music.instrumentBassoon": "(14) Basszuskürt",
    "music.instrumentCello": "(8) Cselló",
    "music.instrumentChoir": "(15) Kórus",
    "music.instrumentClarinet": "(10) Klarinét",
    "music.instrumentElectricGuitar": "(5) Elektromos gitár",
    "music.instrumentElectricPiano": "(2) Elektromos zongora",
    "music.instrumentFlute": "(12) Furulya",
    "music.instrumentGuitar": "(4) Gitár",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Zenélő doboz",
    "music.instrumentOrgan": "(3) Orgona",
    "music.instrumentPiano": "(1) Zongora",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxofon",
    "music.instrumentSteelDrum": "(18) Acél dob",
    "music.instrumentSynthLead": "(20) Szóló szintetizátor",
    "music.instrumentSynthPad": "(21) Szintetizátor érintőlap",
    "music.instrumentTrombone": "(9) Harsona",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Fa furulya",
    "music.midiPlayDrumForBeats": "játssz [DRUM] dobon [BEATS] ütemig",
    "music.midiSetInstrument": "hangszer legyen [INSTRUMENT]",
    "music.playDrumForBeats": "játssz [DRUM] dobon [BEATS] ütemig",
    "music.playNoteForBeats": "játszd le [NOTE] hangjegyet [BEATS] ütemig",
    "music.restForBeats": "szünetelj [BEATS] ütemig",
    "music.setInstrument": "hangszer legyen [INSTRUMENT]",
    "music.setTempo": "tempó legyen [TEMPO]",
    "videoSensing.categoryName": "Videóérzékelés",
    "videoSensing.direction": "irány",
    "videoSensing.motion": "mozgás",
    "videoSensing.off": "ki",
    "videoSensing.on": "be",
    "videoSensing.onFlipped": "felpattintott",
    "videoSensing.setVideoTransparency": "videó átlátszósága legyen [TRANSPARENCY]",
    "videoSensing.sprite": "szereplő",
    "videoSensing.stage": "színpad",
    "videoSensing.videoOn": "video attribútum [ATTRIBUTE] [SUBJECT] tárgyban",
    "videoSensing.videoToggle": "videó kapcsolása [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "amikor a videó mozgása > [REFERENCE]",
    "translate.translateBlock": "fordítsd le azt, hogy [WORDS] erre a nyelvre:[LANGUAGE]",
    "translate.defaultTextToTranslate": "üdv",
    "translate.viewerLanguage": "nyelv",
    "text2speech.speakAndWaitBlock": "mondd [WORDS]",
    "text2speech.setVoiceBlock": "hang legyen [VOICE]",
    "text2speech.setLanguageBlock": "nyelv legyen [LANGUAGE]",
    "text2speech.alto": "alt",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "vinnyogás",
    "text2speech.giant": "óriás",
    "text2speech.kitten": "cica",
    "text2speech.defaultTextToSpeak": "üdv",
    "speech.whenIHear": "amikor hallom [PHRASE]",
    "speech.listenAndWait": "hallgass és várj",
    "speech.defaultWhenIHearValue": "gyerünk",
    "sensor.categoryName": "Sensor",
    "actuator.categoryName": "Actuator",
    "communicate.categoryName": "Communicate",
    "display.categoryName": "Display",
    "extendFunction.categoryName": "Function",
    "internetService.categoryName": "Internet",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "handpy",
    "telloesp32.categoryName": "RMTT",
    "spread.categoryName": "Expansion Board",
    "speechRecognition.categoryName": "Speech Recognition",
    "thirdExpansion.categoryName": "User Expansion",
    "gui.blocklyText.blockTitle.eventBlock": "event program",
    "gui.blocklyText.blockTitle.dotScreen": "dot screen",
    "gui.blocklyText.blockTitle.music": "music",
    "gui.blocklyText.blockTitle.onBoardSensor": "board's sensor",
    "gui.blocklyText.blockTitle.pinOperation": "pin operation",
    "gui.blocklyText.blockTitle.wirelessCommunication": "wireless communication",
    "gui.blocklyText.blockTitle.serialportOperation": "serialport operation",
    "gui.blocklyText.blockTitle.systemResource": "system resource",
    "gui.blocklyText.blockTitle.screenDisplay": "screen display",
    "gui.blocklyText.blockTitle.ledControl": "led control",
    "gui.blocklyText.blockTitle.functionalModule": "functional module",
    "gui.blocklyText.blockTitle.movement": "movement",
    "gui.blocklyText.blockTitle.expression": "expression",
    "gui.blocklyText.blockTitle.light": "light",
    "gui.blocklyText.blockTitle.dance": "dance",
    "gui.blocklyText.blockTitle.sensor": "sensor",
    "gui.blocklyText.blockTitle.motorControl": "motor control",
    "gui.blocklyText.blockTitle.kitSensor": "kit sensor",
    "gui.blocklyText.blockTitle.kitActuator": "kit actuator",
    "gui.blocklyText.blockTitle.commonModule": "common module",
    "gui.blocklyText.blockTitle.infraredCommunication": "infrared communication",
    "gui.blocklyText.blockTitle.mp3Module": "mp3 module",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 RGB light",
    "gui.blocklyText.blockTitle.LCDDisplay": "LCD display",
    "gui.blocklyText.blockTitle.digitalTube": "digital tube",
    "gui.blocklyText.blockTitle.oledDisplay": "OLED display",
    "gui.blocklyText.blockTitle.onBoardSource": "board resource",
    "gui.blocklyText.blockTitle.rgbControl": "RGB light control",
    "gui.blocklyText.blockTitle.maxbotResource": "max:bot resource",
    "gui.blocklyText.blockTitle.lineInspection": "line inspection",
    "gui.blocklyText.blockTitle.outerSensor": "outer sensor",
    "gui.blocklyText.blockTitle.consolePrint": "console output",
    "gui.blocklyText.blockTitle.radio": "radio",
    "gui.blocklyText.blockTitle.wifi": "wifi",
    "gui.blocklyText.romeo.d1ProgramStart": "D1 robot program starts",
    "gui.blocklyText.romeo.readdigital": "read value pin[PIN] [MODULE] ",
    "gui.blocklyText.romeo.buttonModule": "Button Module",
    "gui.blocklyText.romeo.digitalSensor": "Digital Sensor",
    "gui.blocklyText.romeo.collisionSensor": "Crash Sensor",
    "gui.blocklyText.romeo.lineSensor": "Line Sensor",
    "gui.blocklyText.romeo.infraredSwitch": "Infrared Switch",
    "gui.blocklyText.romeo.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.romeo.readAnalog": "read pin [PIN] [SENSOR] ",
    "gui.blocklyText.romeo.lightSensor": "Light Sensor",
    "gui.blocklyText.romeo.anologSensor": "Anolog Sensor",
    "gui.blocklyText.romeo.soundSensor": "Sound Sensor",
    "gui.blocklyText.romeo.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.romeo.frameSensor": "Frame Sensor",
    "gui.blocklyText.romeo.open": "Turn on",
    "gui.blocklyText.romeo.close": "Turn off",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] of pin [PIN] ",
    "gui.blocklyText.romeo.ledLights": "LED Lights",
    "gui.blocklyText.romeo.digitalActuator": "Digital Actuator",
    "gui.blocklyText.romeo.relay": "Relay",
    "gui.blocklyText.romeo.fan": "Fan",
    "gui.blocklyText.romeo.writeAnalog": "set pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.romeo.fanModule": "Fan Module",
    "gui.blocklyText.romeo.robotSpeedMove": "robot [DIR] at speed[SPEED]",
    "gui.blocklyText.romeo.forward": "move forward",
    "gui.blocklyText.romeo.backward": "move backward",
    "gui.blocklyText.romeo.turnLeft": "turn left",
    "gui.blocklyText.romeo.turnRight": "turn right",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "motor [MOTOR] [DIR] at speed[SPEED]",
    "gui.blocklyText.romeo.interface": "Interface",
    "gui.blocklyText.romeo.motorForward": "Forward",
    "gui.blocklyText.romeo.motorReverse": "Backward",
    "gui.blocklyText.romeo.stopMotor": "robot (M1 and M2 motors) stop moving",
    "gui.blocklyText.romeo.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "read pin [PIN] LM35 temperature(°C)",
    "gui.blocklyText.romeo.ds18b20": "read pin [PIN] DS18B20 temperature(°C)",
    "gui.blocklyText.romeo.pressInfrared": "infrared pin [PIN] received button [BUTTON] pressed signal？",
    "gui.blocklyText.romeo.volumePlus": "Volume+",
    "gui.blocklyText.romeo.switch": "Switch",
    "gui.blocklyText.romeo.volumeMinus": "Volume-",
    "gui.blocklyText.romeo.startPause": "Start/Pause",
    "gui.blocklyText.romeo.up": "Up",
    "gui.blocklyText.romeo.down": "Down",
    "gui.blocklyText.romeo.dataReadable": "Is there data to read on serial port?",
    "gui.blocklyText.romeo.readSerialData": "read serial data",
    "gui.blocklyText.romeo.timer": "system uptime(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.romeo.half": "Half",
    "gui.blocklyText.romeo.quarter": "Quarter",
    "gui.blocklyText.romeo.oneInEighth": "One-eighth",
    "gui.blocklyText.romeo.wholeBeat": "Whole Beat",
    "gui.blocklyText.romeo.doubleBeat": "Double Beat",
    "gui.blocklyText.romeo.stop": "Stop",
    "gui.blocklyText.romeo.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.romeo.segment": "eight-segment LED (D6-D13 pin) display [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "serial output [STRING] [WAY], [LINE]",
    "gui.blocklyText.romeo.originalOutput": "origin",
    "gui.blocklyText.romeo.stringOutput": "string",
    "gui.blocklyText.romeo.hexOutput": "HEX",
    "gui.blocklyText.romeo.wrap": "Wrap",
    "gui.blocklyText.romeo.noWrap": "No-Wrap",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "read serial data [READTYPE]",
    "gui.blocklyText.arduinounoR3.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.romeo.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.max.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.maxbot.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.microbit.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.arduino.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.arduinoNano.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.mega2560.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.firebeetleesp32.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.romeo.int": "int",
    "gui.blocklyText.romeo.float": "float",
    "gui.blocklyText.romeo.setBaudRate": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "read pin [PIN] pulse width under [LEVEL] mode, timeout [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "LOW",
    "gui.blocklyText.romeo.high": "HIGH",
    "gui.blocklyText.romeo.noSound": "no sound",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino starts",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno starts",
    "gui.blocklyText.esp32.displayInLine": "display [TEXT] in the [LINE] line",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.normal": "normal",
    "gui.blocklyText.esp32.rev": "rev",
    "gui.blocklyText.esp32.trans": "trans",
    "gui.blocklyText.esp32.XOR": "XOR",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "clear cache in the [LINE] line",
    "gui.blocklyText.esp32.displayClearInLine": "clear in the [LINE] line",
    "gui.blocklyText.esp32.buzzPinC": "P6  (Onboard buzzer)",
    "gui.blocklyText.esp32.buzzerRedirect": "Buzzer redirected to pin [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "All",
    "gui.blocklyText.esp32.setTouchThreshold": "Set button [PIN] touch value is [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "Read button [PIN] touch value",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 starts",
    "gui.blocklyText.esp32.btnIsPressed": "[BUTTON] button [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "when button [REFERENCE] [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "when pin [REFERENCE] is [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "When [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "pin [PIN] is [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "stop background playback",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "play note [NOTE] in background",
    "gui.blocklyText.esp32.setBuzzerFreq": "play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.esp32.getSoundIntensity": "read microphone sound intensity",
    "gui.blocklyText.esp32.readBrightness": "read ambient light brightness",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] show [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.esp32.acceleration": "read acceleration(m-g) [TYPE]",
    "gui.blocklyText.esp32.esp32_tiltAngle": "read tilt angle [TYPE]",
    "gui.blocklyText.esp32.esp32_boardShakeThrown": "the board was [ATTITUDE]?",
    "gui.blocklyText.esp32.esp32_boardTiltState": "the board tilt [GESTUREMPY]",
    "gui.blocklyText.esp32.forward": "forward",
    "gui.blocklyText.esp32.back": "back",
    "gui.blocklyText.esp32.left": "left",
    "gui.blocklyText.esp32.right": "right",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationRange": "set acceleration range [ACCRANGE]",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationResolution": "set acceleration resolution [ACCRESOLUTION]",
    "gui.blocklyText.esp32.esp32_mpyAccurateAccValue": "accurate acceleration value X-axis deviation [XAXIS] Y-axis deviation [YAXIS] Z-axis deviation [ZAXIS]",
    "gui.blocklyText.esp32.esp32_mpyAccurateCompass": "accurate compass",
    "gui.blocklyText.esp32.esp32_mpyCompassDirection": "compass direction",
    "gui.blocklyText.esp32.esp32_mpyAxisMagnetic": "[TYPE] axis magnetic",
    "gui.blocklyText.esp32.esp32_mpyMagneticStrength": "magnetic field strength",
    "gui.blocklyText.esp32.esp32_mpyRemoveMagnetic": "remove the current ambient magnetic field",
    "gui.blocklyText.esp32.esp32_mpyReturnRunTimeDiff": "return run time difference, start time [STARTTIME] end time [ENDTIME]",
    "gui.blocklyText.esp32.esp32_mpyRunTimer": "get run time [TIMETYPE]",
    "gui.blocklyText.esp32.millisecond": "millisecond",
    "gui.blocklyText.esp32.microsencond": "microsencond",
    "gui.blocklyText.esp32.esp32_mpyLocalTime": "get local time [LOCALTIME]",
    "gui.blocklyText.esp32.year": "year",
    "gui.blocklyText.esp32.month": "month",
    "gui.blocklyText.esp32.day": "day",
    "gui.blocklyText.esp32.hour": "hour",
    "gui.blocklyText.esp32.minute": "minute",
    "gui.blocklyText.esp32.sencond": "sencond",
    "gui.blocklyText.esp32.weeknumber": "week number",
    "gui.blocklyText.esp32.daysnumber": "days number",
    "gui.blocklyText.esp32.esp32_mpyReset": "reset",
    "gui.blocklyText.esp32.esp32_mpyMacAddr": "MAC address",
    "gui.blocklyText.esp32.displayInXY": "display [TEXT] at X: [X]  Y: [Y] axis, preview [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "screen display color [COLOR] (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "write cache screen color [COLORMPYTHON]",
    "gui.blocklyText.esp32.clear": "clear",
    "gui.blocklyText.esp32.fullBright": "full bright",
    "gui.blocklyText.esp32.blackBackground": "black background",
    "gui.blocklyText.esp32.whiteBackground": "white background",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": "all white",
    "gui.blocklyText.esp32.lineDrawing": "draw line from Point x1:[X1] y1:[Y1] to Point x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "connected",
    "gui.blocklyText.esp32.disconnected": "disconnected",
    "gui.blocklyText.esp32.rectangleDrawing": "draw rectangle [FILL] with top-left corner at x:[X] y:[Y], W:[WIDTH], H:[HEIGHT]",
    "gui.blocklyText.esp32.mpyrectangleDrawing": "[DRAWERASE] [FILL] rectangle drawing origin x:[X] y:[Y] width[WIDTH] height[HEIGHT]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCornerBorder": "[DRAWERASE] corner border x [X] y [Y] width [WIDTH] height [HEIGHT] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCircle": "[DRAWERASE] [FILL] circle x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseTriangle": "[DRAWERASE] [FILL] triangle x1 [X1] y1 [Y1] x2 [X2] y2 [Y2] x3 [X3] y3 [Y3]",
    "gui.blocklyText.esp32.esp32_mpyShowPixelCoordinate": "at coordinate x [X] y [Y] show [SHOWMSG] as [PIXEL] font [LINE]",
    "gui.blocklyText.esp32.Digiface_11px": "Digiface 11px",
    "gui.blocklyText.esp32.Digiface_16px": "Digiface 16px",
    "gui.blocklyText.esp32.Digiface_21px": "Digiface 21px",
    "gui.blocklyText.esp32.Digiface_30px": "Digiface 30px",
    "gui.blocklyText.esp32.Digiface_44px": "Digiface 44px",
    "gui.blocklyText.esp32.Digiface_ltalic_30px": "Digiface ltalic 30px",
    "gui.blocklyText.esp32.Digiface_ltalic_42px": "Digiface ltalic 42px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_12px": "DejaVu Sans Mono 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_16px": "DejaVu Sans Mono 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_21px": "DejaVu Sans Mono 21px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_12px": "DejaVu Sans Mono Bold 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_16px": "DejaVu Sans Mono Bold 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_21px": "DejaVu Sans Mono Bold 21px",
    "gui.blocklyText.esp32.esp32_mpyQRCode": "x [X] y [Y] draw QR code [QRCODE] size [IMGSIZE]",
    "gui.blocklyText.esp32.esp32_mpyInitClock": "init clock [CLOCKNAME] x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyClockReadTime": "clock [CLOCKNAME] read time",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseClock": "[DRAWERASE] clock [CLOCKNAME]",
    "gui.blocklyText.esp32.screenDisplay": "screen cache display takes effect",
    "gui.blocklyText.esp32.circleDrawing": "draw circle [FILL] with center point at x:[X] y:[Y], r:[RADIUS]",
    "gui.blocklyText.esp32.fill": "fill",
    "gui.blocklyText.esp32.notFill": "not fill",
    "gui.blocklyText.esp32.pointDrawing": "draw point at x:[X] y:[Y]",
    "gui.blocklyText.esp32.esp32_mpyProgressBar": "progress bar x [X] y [Y] width [WIDTH] height [HEIGHT] progress [PROG]",
    "gui.blocklyText.esp32.esp32_mpyColumnarProgressBar": "columnar progress bar [COLUMNARSTATE] x [X] y [Y] width [WIDTH] height [HEIGHT] progress [PROG]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseLine": "[DRAWERASE] [COLUMNARSTATE] line x [X] y [Y] length [LENGTH]",
    "gui.blocklyText.esp32.draw": "draw",
    "gui.blocklyText.esp32.erase": "erase",
    "gui.blocklyText.esp32.vertical": "vertical",
    "gui.blocklyText.esp32.horizontal": "horizontal",
    "gui.blocklyText.esp32.setLineWidth": "set line width to [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.esp32.analogWrite": "analog pin [PIN] output(PWM) [VALUE]",
    "gui.blocklyText.esp32.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.esp32.serialPort0": "serial0",
    "gui.blocklyText.esp32.serialPort1": "serial1",
    "gui.blocklyText.esp32.serialPort2": "serial2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX] the baud rate [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "screen display image [IMAGE] at x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "loading picture from image [IMAGE] show to x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "pressed",
    "gui.blocklyText.esp32.loosened": "released",
    "gui.blocklyText.esp32.all": "ALL(-1)",
    "gui.blocklyText.esp32.setBaud": "serialport0 baud rate [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.esp32.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.esp32.setBrightness": "set led brightness [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "get led lightness",
    "gui.blocklyText.esp32.print": "print [DATA]",
    "gui.blocklyText.esp32.TiltForward": "Tilt forward",
    "gui.blocklyText.esp32.TiltBack": "Tilt back",
    "gui.blocklyText.esp32.TiltLeft": "Tilt left",
    "gui.blocklyText.esp32.TiltRight": "Tilt right",
    "gui.blocklyText.esp32.ScreenUp": "Screen up",
    "gui.blocklyText.esp32.ScreenDown": "Face down",
    "gui.blocklyText.esp32.Shake": "Shake",
    "gui.blocklyText.esp32.isGesture": "is [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "Disable [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "All",
    "gui.blocklyText.esp32.rgbdisable.No.0": "No.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "No.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "No.2",
    "gui.blocklyText.esp32.playMusic": "Play music [MUSIC] repeating [REPEAT]",
    "gui.blocklyText.esp32.Once": "Once",
    "gui.blocklyText.esp32.Forever": "Forever",
    "gui.blocklyText.esp32.OnceInBackground": "Once in background",
    "gui.blocklyText.esp32.ForeverInBackground": "Forever in background",
    "gui.blocklyText.radio.radioSwitch": "turn [DATA] radio",
    "gui.blocklyText.radio.radioswitch.on": "on",
    "gui.blocklyText.radio.radioswitch.off": "off",
    "gui.blocklyText.radio.setChannel": "set radio channl [DATA]",
    "gui.blocklyText.radio.radioSend": "radio sends [DATA]",
    "gui.blocklyText.radio.radioreceived": "radio received",
    "gui.blocklyText.radio.receivingEven": "when received [DATA]",
    "gui.blocklyText.radio.Msg": "radio message",
    "gui.blocklyText.radio.receivingSpecificEven": "when receiving specific broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.radioSwitch": "turn [DATA] radio",
    "gui.blocklyText.esp32.radioswitch.on": "on",
    "gui.blocklyText.esp32.radioswitch.off": "off",
    "gui.blocklyText.esp32.setChannel": "set radio channl [DATA]",
    "gui.blocklyText.esp32.radioSend": "radio sends [DATA]",
    "gui.blocklyText.esp32.radioreceived": "radio received",
    "gui.blocklyText.esp32.receivingEven": "when receiving broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.receivingSpecificEven": "when receiving specific broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.connectWIFI": "connect to Wi-Fi with SSID [SSID] & Password [PASS]",
    "gui.blocklyText.esp32.disconnectWIFI": "disconnect Wi-Fi connection",
    "gui.blocklyText.esp32.checkWIFI": "Has connected to Wi-Fi",
    "gui.blocklyText.esp32.configurationInformation": "Wi-Fi configuration information",
    "gui.blocklyText.esp32.configurationInformationIP": "Wi-Fi configuration information [IP]",
    "gui.blocklyText.esp32.wifi.IP": "IP",
    "gui.blocklyText.esp32.wifi.netmask": "netmask",
    "gui.blocklyText.esp32.wifi.geteway": "geteway",
    "gui.blocklyText.esp32.wifi.DNS": "DNS",
    "gui.blocklyText.esp32.openAP": "open AP mode with with SSID [SSID] & Password [PASS] & Channel [CHANNEL]",
    "gui.blocklyText.esp32.closeAP": "close AP mode",
    "gui.blocklyText.esp32.synchronizeTime": "synchronize network time,timezone [TIMEZONE]& timing server [SERVER]",
    "gui.blocklyText.esp32.timezone.UTC+8": "UTC+8",
    "gui.blocklyText.esp32.timezone.UTC+9": "UTC+9",
    "gui.blocklyText.esp32.timezone.UTC+10": "UTC+10",
    "gui.blocklyText.esp32.timezone.UTC+11": "UTC+11",
    "gui.blocklyText.esp32.timezone.UTC+12": "UTC+12",
    "gui.blocklyText.esp32.timezone.UTC-12": "UTC-12",
    "gui.blocklyText.esp32.timezone.UTC-11": "UTC-11",
    "gui.blocklyText.esp32.timezone.UTC-10": "UTC-10",
    "gui.blocklyText.esp32.timezone.UTC-9": "UTC-9",
    "gui.blocklyText.esp32.timezone.UTC-8": "UTC-8",
    "gui.blocklyText.esp32.timezone.UTC-7": "UTC-7",
    "gui.blocklyText.esp32.timezone.UTC-6": "UTC-6",
    "gui.blocklyText.esp32.timezone.UTC-5": "UTC-5",
    "gui.blocklyText.esp32.timezone.UTC-4": "UTC-4",
    "gui.blocklyText.esp32.timezone.UTC-3": "UTC-3",
    "gui.blocklyText.esp32.timezone.UTC-2": "UTC-2",
    "gui.blocklyText.esp32.timezone.UTC-1": "UTC-1",
    "gui.blocklyText.esp32.timezone.UTC": "UTC",
    "gui.blocklyText.esp32.timezone.UTC+1": "UTC+1",
    "gui.blocklyText.esp32.timezone.UTC+2": "UTC+2",
    "gui.blocklyText.esp32.timezone.UTC+3": "UTC+3",
    "gui.blocklyText.esp32.timezone.UTC+4": "UTC+4",
    "gui.blocklyText.esp32.timezone.UTC+5": "UTC+5",
    "gui.blocklyText.esp32.timezone.UTC+6": "UTC+6",
    "gui.blocklyText.esp32.timezone.UTC+7": "UTC+7",
    "gui.blocklyText.telloesp32.arduinoUnoStarts": "RMTT ESP32 starts",
    "gui.blocklyText.telloesp32.ledControl": "led control",
    "gui.blocklyText.telloesp32.matrixControl": "matrix control",
    "gui.blocklyText.telloesp32.flyControl": "fly control",
    "gui.blocklyText.telloesp32.customCommand": "custom command",
    "gui.blocklyText.telloesp32.pinOperation": "pin operation",
    "gui.blocklyText.telloesp32.readDigital": "read digital pin [PIN]",
    "gui.blocklyText.telloesp32.readAnalog": "read Analog pin [PIN]",
    "gui.blocklyText.telloesp32.setPwm": "set PWM pin [PIN] to [VALUE]",
    "gui.blocklyText.telloesp32.setDigital": "set digital pin [PIN] to [LEVEL]",
    "gui.blocklyText.telloesp32.functionalModule": "functional module",
    "gui.blocklyText.telloesp32.telloesp32_matrix": "set matrix [MARK]",
    "gui.blocklyText.telloesp32.showPatternWhenStart": "show pattern[MARK] when start",
    "gui.blocklyText.telloesp32.setMatrixLight": "set matrix light [NUM]",
    "gui.blocklyText.telloesp32.matrixDisplayText": "matrix display text [TEXT] color [COLOR] light [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayTextMpy": "matrix display text [TEXT] color [COLOR]",
    "gui.blocklyText.telloesp32.matrixDisplayPreset": "matrix display preset pattern [PATTERN] color [COLOR] light [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayPresetMpy": "matrix display preset pattern [PATTERN] color [COLOR]",
    "gui.blocklyText.telloesp32.empty": "empty",
    "gui.blocklyText.telloesp32.WiFi": "WiFi",
    "gui.blocklyText.telloesp32.DownLoad": "DownLoad",
    "gui.blocklyText.telloesp32.Power": "Power",
    "gui.blocklyText.telloesp32.heart": "heart",
    "gui.blocklyText.telloesp32.heartOutline": "heart-outline",
    "gui.blocklyText.telloesp32.matrixR": "r",
    "gui.blocklyText.telloesp32.matrixB": "b",
    "gui.blocklyText.telloesp32.matrixP": "p",
    "gui.blocklyText.telloesp32.matrixDisplayRollPicture": "display mark direction [DIRECT] move frequency [SPEED] mark[MARK]",
    "gui.blocklyText.telloesp32.matrixDisplayRollText": "matrix display text direction [DIRECT] move frequency [SPEED] text[TEXT] color[COLOR]",
    "gui.blocklyText.telloesp32.telloSetWifiChannel": "set Wi-Fi channel [CHANNEL]",
    "gui.blocklyText.telloesp32.initTOF": "init TOF",
    "gui.blocklyText.telloesp32.readTOF": "read TOF (mm)",
    "gui.blocklyText.telloesp32.buttonIsPressed": "button is pressed?",
    "gui.blocklyText.telloesp32.setRgbColor": "lights cache color [COLOR]",
    "gui.blocklyText.telloesp32.rgbToColor": "set R: [RED] G: [GREEN] B: [BLUE]",
    "gui.blocklyText.telloesp32.rgbBreathWithColor": "breath LED with color[COLOR] frequency(Hz) [TIME]",
    "gui.blocklyText.telloesp32.rgbBreath": "set breath lights R: [RED] G: [GREEN] B: [BLUE] frequency(Hz): [SP]",
    "gui.blocklyText.telloesp32.rgbFlashingColor": "flashing lights color1 [COLOR1] and color2 [COLOR2] frequency(Hz) [SP]",
    "gui.blocklyText.telloesp32.rgbFlashing": "set flashing lights R1: [RED1] G1: [GREEN1] B1: [BLUE1] R2: [RED2] G2: [GREEN2] B2: [BLUE2] frequency(Hz): [SP]",
    "gui.blocklyText.telloesp32.ledCloseEffects": "LED close special effects",
    "gui.blocklyText.telloesp32.matrixCloseEffects": "matrix close special effects",
    "gui.blocklyText.telloesp32.sendCustomCommand": "send custom command [CMD]",
    "gui.blocklyText.telloesp32.recvCustomCommand": "receive custom command [MSG], timeout [TIMEOUT]",
    "gui.blocklyText.telloesp32.recvCustomCommandNum": "receive custom num, timeout [TIMEOUT]",
    "gui.blocklyText.telloesp32.serialportOperation": "serialport operation",
    "gui.blocklyText.telloesp32.dataReadable": "Is [SERIAL] data readable?",
    "gui.blocklyText.telloesp32.readSerialData": "read [SERIAL] data",
    "gui.blocklyText.telloesp32.setBaud": "set serial0 baud rate [BAUD]",
    "gui.blocklyText.telloesp32.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.telloesp32.seriaRemapping": "set [SERIAL] Rx [RX] Tx [TX] The baud rate [BAUD]",
    "gui.blocklyText.telloesp32.systemResource": "system resource",
    "gui.blocklyText.telloesp32.timer": "timer(ms)",
    "gui.blocklyText.telloesp32.consolePrint": "console output",
    "gui.blocklyText.telloesp32.print": "print [DATA]",
    "gui.blocklyText.telloesp32.telloRiseDown": "fly [RISEDOWN] [NUMS]cm",
    "gui.blocklyText.telloesp32.rise": "up",
    "gui.blocklyText.telloesp32.down": "down",
    "gui.blocklyText.telloesp32.telloTurnDirection": "fly [DIRECTION] [NUMS] cm",
    "gui.blocklyText.telloesp32.turnleft": "left",
    "gui.blocklyText.telloesp32.turnright": "right",
    "gui.blocklyText.telloesp32.turnForward": "forward",
    "gui.blocklyText.telloesp32.turnBack": "back",
    "gui.blocklyText.telloesp32.telloMotorOn": "motor on",
    "gui.blocklyText.telloesp32.telloMotorOff": "motor off",
    "gui.blocklyText.telloesp32.telloTakeOff": "take off",
    "gui.blocklyText.telloesp32.telloThrowFly": "throw fly",
    "gui.blocklyText.telloesp32.telloLanding": "land",
    "gui.blocklyText.telloesp32.telloClockwiseRotation": "rotate [CLOCKWISE] [NUMS] degree",
    "gui.blocklyText.telloesp32.clockwise": "clockwise",
    "gui.blocklyText.telloesp32.counterClockwise": "counterClockwise",
    "gui.blocklyText.telloesp32.telloStartControl": "start control",
    "gui.blocklyText.telloesp32.telloGetHeight": "get [HEIGHT],timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.relatively": "relatively height(dm)",
    "gui.blocklyText.telloesp32.barometer": "barometer height(m)",
    "gui.blocklyText.telloesp32.tof": "TOF height(mm)",
    "gui.blocklyText.telloesp32.telloTemp": "board temperature(°C),timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.lowest": "lowest",
    "gui.blocklyText.telloesp32.highest": "highest",
    "gui.blocklyText.telloesp32.telloAttitude": "attitude Pitch(°), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.pitch": "pitch",
    "gui.blocklyText.telloesp32.roll": "roll",
    "gui.blocklyText.telloesp32.translation": "translation",
    "gui.blocklyText.telloesp32.telloAcceleration": "acceleration(0.001g), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.xaxis": "x axis",
    "gui.blocklyText.telloesp32.yaxis": "y axis",
    "gui.blocklyText.telloesp32.zaxis": "z axis",
    "gui.blocklyText.telloesp32.telloSetSpeed": "set speed to [NUMS]cm/s",
    "gui.blocklyText.telloesp32.telloSpeed": "[XYZAXIS] speed(cm/s), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloPower": "battery( %), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.MissionPad": "MissionPad, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloMissionPadXyz": "MissionPad [XYZ] (cm), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCommandResponse": "command response, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloGetMissionPadNum": "get missionPad num, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCurrentSpeed": "Current speed, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloTime": "Motor running time (s), timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloWIFISign": "Wi-Fi sign, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSDKVersion": "Tello SDK Version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloBinVersion": "Tello firmware version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloHasHardware": "Tello get hardware version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.rmttWifiVersion": "RMTT Wi-Fi version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSN": "Tello SN code, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloFlyToXYZ": "fly to X[X]cm y[Y]cm z[Z]cm with speed [SPEED]cm/s",
    "gui.blocklyText.telloesp32.getSpeed": "get current seted speed (cm/s)",
    "gui.blocklyText.telloesp32.getBattery": "get the percentage value of the current remaining battery power",
    "gui.blocklyText.telloesp32.getTime": "get motor running time",
    "gui.blocklyText.telloesp32.getWifiNoise": "get Wi-Fi signal to noise ratio",
    "gui.blocklyText.telloesp32.getSdkVersion": "get sdk version",
    "gui.blocklyText.telloesp32.getSnNum": "get sn number",
    "gui.blocklyText.mpy.MQTTinit": "MQTT initial [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInit": "mqtt init [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInitPlatform": "platform",
    "gui.blocklyText.mpy.mpyMqttInitPlatformNormal": "normal",
    "gui.blocklyText.mpy.mpyMqttInitPlatformTencent": "tencent",
    "gui.blocklyText.mpy.MQTTclient_id": "client_id",
    "gui.blocklyText.mpy.MQTTserver": "server",
    "gui.blocklyText.mpy.MQTTport": "port",
    "gui.blocklyText.mpy.MQTTuser": "user",
    "gui.blocklyText.mpy.MQTTpassowrd": "passowrd",
    "gui.blocklyText.mpy.MQTTkeepalive": "keepalive",
    "gui.blocklyText.mpy.MQTTtencent": "MQTT Tencent IOT [SETTING]",
    "gui.blocklyText.mpy.TencentIOT": "TencentIOT",
    "gui.blocklyText.mpy.TencentServer": "server",
    "gui.blocklyText.mpy.TencentPort": "port",
    "gui.blocklyText.mpy.TencentAppld": "Appld",
    "gui.blocklyText.mpy.TencentDevice": "device",
    "gui.blocklyText.mpy.siotServer": "Server",
    "gui.blocklyText.mpy.siotServerUser": "User",
    "gui.blocklyText.mpy.siotServerPass": "Password",
    "gui.blocklyText.mpy.Tencentoutput": "output",
    "gui.blocklyText.mpy.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.mpy.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.mpy.mpy_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.mpy.EasyIOTServer": "server",
    "gui.blocklyText.mpy.EasyIOTServerChina": "China",
    "gui.blocklyText.mpy.EasyIOTServerGlobal": "Global",
    "gui.blocklyText.mpy.EasyIOTclient_id": "client_id",
    "gui.blocklyText.mpy.EasyIOT_id": "Iot_id",
    "gui.blocklyText.mpy.EasyIOT_password": "Iot_pwd",
    "gui.blocklyText.mpy.MQTTconnect": "connect to MQTT",
    "gui.blocklyText.mpy.mpy_MQTTAddSub": "subscribe [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublish": "publish [DATA] to topic [TOPIC]",
    "gui.blocklyText.mpy.mpy_waitMSG": "wait for topic message in [MODE] mode",
    "gui.blocklyText.mpy.disconnectMQTT": "disconnect from MQTT",
    "gui.blocklyText.mpy.MQTTlastwill": "set MQTT topic [TOPIC] last will message as [MSG]",
    "gui.blocklyText.mpy.MQTTreceive": "when received [DATA] from topic [TOPIC]",
    "gui.blocklyText.mpy.mqttMsg": "mqtt message",
    "gui.blocklyText.mpy.MQTTmode.block": "block",
    "gui.blocklyText.mpy.MQTTmode.unblock": "unblock",
    "gui.blocklyText.mpyWeather.init": "weather server set [DATA] as [SETTING]",
    "gui.blocklyText.mpyWeather.weatherFeild": "[DATA] weather server common fields [FIELD]",
    "gui.blocklyText.mpyWeather.weatherSituation": "[DATA] weather server Weather now [NOW]",
    "gui.blocklyText.mpyWeather.weatherForecast": "[DATA] weather server Weather forecast [FIELD] for [DATE]",
    "gui.blocklyText.mpyWeather.weatherLivingindex": "[DATA] weather server Living index [FIELD] index",
    "gui.blocklyText.mpyWeather.weatherFeild.name": "name",
    "gui.blocklyText.mpyWeather.weatherFeild.id": "id",
    "gui.blocklyText.mpyWeather.weatherFeild.code": "code",
    "gui.blocklyText.mpyWeather.weatherFeild.path": "path",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone": "timezone",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone_offset": "timezone_offset",
    "gui.blocklyText.mpyWeather.weatherFeild.Updated": "Updated",
    "gui.blocklyText.mpyWeather.weatherFeild.updatetime": "updatetime",
    "gui.blocklyText.mpyWeather.weatherFeild.original": "original",
    "gui.blocklyText.mpyWeather.weatherSituation.text": "text",
    "gui.blocklyText.mpyWeather.weatherSituation.code": "code",
    "gui.blocklyText.mpyWeather.weatherSituation.temperature": "temperature",
    "gui.blocklyText.mpyWeather.weatherdate.today": "today",
    "gui.blocklyText.mpyWeather.weatherdate.tomorrow": "tomorrow",
    "gui.blocklyText.mpyWeather.weatherdate.dayaftertomorrow": "dayaftertomorrow",
    "gui.blocklyText.mpyWeather.province": "province",
    "gui.blocklyText.mpyWeather.city": "city",
    "gui.blocklyText.mpyWeather.weatherforecast.highesttemperature": "highesttemperature",
    "gui.blocklyText.mpyWeather.weatherforecast.lowesttemperature": "lowesttemperature",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweather": "daytimeweather",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweathercode": "daytimeweathercode",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweather": "nightweather",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweathercode": "nightweathercode",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirection": "winddirection",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirectiondegree": "winddirectiondegree",
    "gui.blocklyText.mpyWeather.weatherforecast.windspeed": "windspeed",
    "gui.blocklyText.mpyWeather.weatherforecast.windscale": "windscale",
    "gui.blocklyText.mpyWeather.weatherforecast.date": "date",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Carwashindex": "Carwashindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Dressingindex": "Dressingindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Coldindex": "Coldindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Sportindex": "Sportindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Travelindex": "Travelindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.uvindex": "uvindex",
    "gui.blocklyText.mpyWeather.type": "data type",
    "gui.blocklyText.mpyWeather.temperature": "temperature",
    "gui.blocklyText.mpyWeather.APIkey": "APIkey",
    "gui.blocklyText.mpyWeather.language": "language",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 starts",
    "gui.blocklyText.mega2560.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.mega2560.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "pwm pin [PIN] output [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.mega2560.level.low": "LOW",
    "gui.blocklyText.mega2560.level.high": "HIGH",
    "gui.blocklyText.mega2560.irqRead": "read digital pin [PIN] of Infrared Receiver",
    "gui.blocklyText.mega2560.ultrasonicRead": "read ulrasonic sensor trig [TRIG], echo [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "pin [PIN] play buzzer tone [TONE] for [BEAT] beat",
    "gui.blocklyText.mega2560.harf": "Harf",
    "gui.blocklyText.mega2560.quarter": "Quarter",
    "gui.blocklyText.mega2560.oneInEighth": "One-eighth",
    "gui.blocklyText.mega2560.wholeBeat": "Whole Beat",
    "gui.blocklyText.mega2560.doubleBeat": "Double Beat",
    "gui.blocklyText.mega2560.stop": "Stop",
    "gui.blocklyText.mega2560.servoSet": "Set pin [PIN] servo to [DEGREE] degrees",
    "gui.blocklyText.mega2560.setBaud": "set [SERIAL] baud rate to [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "serial0",
    "gui.blocklyText.mega2560.serialPort1": "serial1",
    "gui.blocklyText.mega2560.serialPort2": "serial2",
    "gui.blocklyText.mega2560.serialPort3": "serial3",
    "gui.blocklyText.mega2560.dataReadable": "Is there data to read on [SERIAL] ?",
    "gui.blocklyText.mega2560.readSerialData": "read data on [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "in string",
    "gui.blocklyText.mega2560.originalOutput": "original output",
    "gui.blocklyText.mega2560.hexOutput": "in HEX",
    "gui.blocklyText.mega2560.wrap": "Wrap",
    "gui.blocklyText.mega2560.noWrap": "No-Wrap",
    "gui.blocklyText.mega2560.readPinLevel": "read pin [PIN] pulse width under [LEVEL] mode, timeout [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "system uptime(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini starts",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo starts",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 starts",
    "gui.blocklyText.arduino.setDigital": "digital pin [PIN] ouput [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "read digital pin [PIN]",
    "gui.blocklyText.arduino.readSimilation": "read analog pin [PIN]",
    "gui.blocklyText.arduino.setPwm": "pwm pin [PIN] output [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat ",
    "gui.blocklyText.arduino.serialSendStr": "serial output [STRING] [WAY], [LINE]",
    "gui.blocklyText.arduino.originalOutput": "in origin",
    "gui.blocklyText.arduino.stringOutput": "in string",
    "gui.blocklyText.arduino.hexOutput": "in HEX",
    "gui.blocklyText.arduino.wrap": "Wrap",
    "gui.blocklyText.arduino.noWrap": "No-Wrap",
    "gui.blocklyText.arduino.readInfrared": "read digital pin [PIN] of infrared Receiver",
    "gui.blocklyText.arduino.setBaud": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "read pin [PIN] pulse width pattern [LEVEL] timeout [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "no sound",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Arduino Nano starts",
    "gui.blocklyText.vortex.vortexStart": "Vortex robot program starts",
    "gui.blocklyText.vortex.vortexMoveSpeed": "vortex robot [DIRECTION] at speed[SPEED] ",
    "gui.blocklyText.vortex.forward": "move forward",
    "gui.blocklyText.vortex.backup": "move backward",
    "gui.blocklyText.vortex.turnLeft": "turn left",
    "gui.blocklyText.vortex.turnRight": "turn right",
    "gui.blocklyText.vortex.setMotor": "motor [MOTOR] [DIRECTION] at speed[SPEED]  ",
    "gui.blocklyText.vortex.leftWheel": "left",
    "gui.blocklyText.vortex.rightWheel": "right",
    "gui.blocklyText.vortex.setExpression": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.vortex.ledLights": "[DIRECTION][LIGHT] change to [COLOR] in [SECOND]secs.",
    "gui.blocklyText.vortex.all": "all",
    "gui.blocklyText.vortex.rightAhead": "front",
    "gui.blocklyText.vortex.leftRear": "left rear",
    "gui.blocklyText.vortex.rightRear": "right rear",
    "gui.blocklyText.vortex.rightAstern": "rear",
    "gui.blocklyText.vortex.topLights": "top lights",
    "gui.blocklyText.vortex.bottomLights": "bottom lights",
    "gui.blocklyText.vortex.vortexStop": "Vortex stops moving ",
    "gui.blocklyText.vortex.setVolume": "set volume to [VALUE]",
    "gui.blocklyText.vortex.playMusic": "play the [VALUE] music",
    "gui.blocklyText.vortex.stopMusic": "stop playing music",
    "gui.blocklyText.vortex.startDance": "start the [VALUE] dance",
    "gui.blocklyText.vortex.stopDance": "stop dancing",
    "gui.blocklyText.vortex.barrierDetection": "obstacle ahead?",
    "gui.blocklyText.vortex.readSensor": "read Grayscale Sensor [SENSOR]",
    "gui.blocklyText.vortex.setSensor": "set grayscale sensors sensitivity to [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "grayscale sensor meets black on its [SENSOR] position?",
    "gui.blocklyText.vortex.positiveFrontLeft": "straight left front",
    "gui.blocklyText.vortex.frontLeft": "left front",
    "gui.blocklyText.vortex.positiveFrontRight": "straight right front",
    "gui.blocklyText.vortex.frontRight": "right front",
    "gui.blocklyText.vortex.backRight": "right rear",
    "gui.blocklyText.vortex.backLeft": "left rear",
    "gui.blocklyText.vortex.initialOldPCB": "initialize to old version (blue PCB)",
    "gui.blocklyText.microbit.microbitStarts": "micro:bit starts",
    "gui.blocklyText.microbit.interruptExcute": "interrupt pin [PIN] mode [MODE] excute",
    "gui.blocklyText.microbit.cancleInterrupt": "cancel interrupt pin [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "when received wireless data",
    "gui.blocklyText.microbit.whenBtnPress": "when button [REFERENCE] pressed",
    "gui.blocklyText.microbit.whenPinConnected": "when microbit pin [REFERENCE] connected",
    "gui.blocklyText.microbit.whenPosChange": "when [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "display pattern [PIC]",
    "gui.blocklyText.microbit.showInput": "display [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "stop animation",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] coordinates of point （x:[XAXIS], y:[YXAXIS]）",
    "gui.blocklyText.microbit.hideAllLights": "clear all dot matrixes",
    "gui.blocklyText.microbit.microbitEnableLight": "Lattice Screen [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "brightness",
    "gui.blocklyText.microbit.showLightWithBrightness": "set brightness [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "show coordinates of point (x:[XAXIS], y:[YXAXIS]), brightness [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "pin [PIN] play sound [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "pin [PIN] play sound [SOUND] until done",
    "gui.blocklyText.microbit.playNote": "pin [PIN] play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.microbit.true": "enable",
    "gui.blocklyText.microbit.false": "disable",
    "gui.blocklyText.maqueen.playSound": "pin P0 play sound [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "pin P0 play sound [SOUND] until down",
    "gui.blocklyText.maqueen.playNote": "pin P0 play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.maqueen.RGB0": "RGB0  (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1  (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2  (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3  (3)",
    "gui.blocklyText.maqueen.All": "All  (-1)",
    "gui.blocklyText.microbit.changeTempo": "change tempo (bpm) by [VALUE]",
    "gui.blocklyText.microbit.setTempo": "set tempo (bpm) to [VALUE]",
    "gui.blocklyText.microbit.getTempo": "tempo (bpm)",
    "gui.blocklyText.microbit.stopSound": "stop background playback",
    "gui.blocklyText.microbit.btnIsPressed": "[BUTTON] button pressed?",
    "gui.blocklyText.microbit.pinIsConnected": "[PIN] pin connected?",
    "gui.blocklyText.microbit.isMove": "current state [TYPE]?",
    "gui.blocklyText.microbit.compass": "read compass orientation",
    "gui.blocklyText.microbit.temperature": "read temperature",
    "gui.blocklyText.microbit.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.microbit.analogWrite": "analog pin [PIN] output [VALUE]",
    "gui.blocklyText.microbit.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.microbit.up": "logo up",
    "gui.blocklyText.microbit.down": "logo down",
    "gui.blocklyText.microbit.left": "tilt to left",
    "gui.blocklyText.microbit.right": "tilt to right",
    "gui.blocklyText.microbit.faceUp": "face up",
    "gui.blocklyText.microbit.faceDown": "face down",
    "gui.blocklyText.microbit.freefall": "freefall",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "shake",
    "gui.blocklyText.microbit.show": "show",
    "gui.blocklyText.microbit.hide": "hide",
    "gui.blocklyText.microbit.low": "LOW",
    "gui.blocklyText.microbit.high": "HIGH",
    "gui.blocklyText.microbit.microbitReadBrightness": "read ambient light brightness",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] wireless communication",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "set wireless channel to [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "send string [TEXT] via wireless",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "receive data via wireless",
    "gui.blocklyText.microbit.strength": "strength",
    "gui.blocklyText.microbit.acceleration": "read acceleration(m-g)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "read magnetic force(µT)[TYPE]",
    "gui.blocklyText.microbit.microbitAccurateCompass": "accurate compass",
    "gui.blocklyText.microbit.print": "Print [DATA]",
    "gui.blocklyText.microbit.goUp": "up",
    "gui.blocklyText.microbit.goDown": "down",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "when received [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "wireless data",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot Starts",
    "gui.blocklyText.maxbot.playSound": "play sound [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "play sound [SOUND] until done",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "collision occurred on the [LEFTRIGHT] ?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "black line detected on the [LEFTRIGHT]  ?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "motor [MOTOR] [DIR] at speed [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "read (P1,P2) ulrasonic sensor [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "read ulrasonic sensor Unit [UNIT], trig [TRIG], echo [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "FORWARD",
    "gui.blocklyText.maxbot.BACKWARD": "BACKWARD",
    "gui.blocklyText.motorbit.CW": "CW",
    "gui.blocklyText.motorbit.CCW": "CCW",
    "gui.blocklyText.maxbot.CW": "CW",
    "gui.blocklyText.maxbot.CCW": "CCW",
    "gui.blocklyText.maxbot.ANGLE": "degrees",
    "gui.blocklyText.maxbot.CIRCLE": "revolutions",
    "gui.blocklyText.maxbot.Left": "left",
    "gui.blocklyText.maxbot.Right": "right",
    "gui.blocklyText.maxbot.cm": "cm",
    "gui.blocklyText.maxbot.inch": "inch",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit Starts",
    "gui.blocklyText.MotorBit.MotorRun": "motor [MOTOR] rotate [DIR] at speed [SPEED]",
    "gui.blocklyText.MotorBit.MotorStop": "motor [MOTOR] stops",
    "gui.blocklyText.MotorBit.Stepper": "stepper [STEPPER] rotate [ROTATEDIR] for [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "ALL",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen Starts",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "read (P1,P2)ultrasonic sensor (cm)",
    "gui.blocklyText.maqueen.motorStop": "robot (left and right motors) stops",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] LED light",
    "gui.blocklyText.maqueen.redLineSensor": "read [LEFTRIGHT] line tracking sensor",
    "gui.blocklyText.maqueen.robotSpeedMove": "robot [DIR] at speed [SPEED]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "motor [LEFTRIGHT] move [DIR] at speed [SPEED]",
    "gui.blocklyText.maqueen.ledLeft": "Left(P8)",
    "gui.blocklyText.maqueen.ledRight": "Right(P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "Right(P12) (not available)",
    "gui.blocklyText.maqueen.lineSensorLeft": "Left(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "Right(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "when (P16) received [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "infrared signal",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "read(P16) infrared signal",
    "gui.blocklyText.maqueen.numberOfLightsBright": "pin P15 4 RGB LEDs brightness [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "pin P15 the [NUM1] LED display color [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "pin P15 LED [NUM1] to [NUM2] show gradient color from [COLOR1] to [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "pin P15 shift pixels by [NUM]",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "pin P15 rotate pixels by [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "pin P15 LED brightness [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "pin P15 LED [NUM1] to [NUM2] show histogram, value [VALUE], max [MAX]",
    "gui.blocklyText.maqueen.clear": "pin P15 clear all LEDs",
    "gui.blocklyText.maqueen.rgbColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.tello.telloStarts": "Tello Starts",
    "gui.blocklyText.tello.telloControlPlane": "control plane",
    "gui.blocklyText.tello.telloTakeOff": "take off",
    "gui.blocklyText.tello.telloLanding": "land",
    "gui.blocklyText.tello.telloRise": "fly up [NUMS]cm",
    "gui.blocklyText.tello.telloDown": "fly down [NUMS]cm",
    "gui.blocklyText.tello.telloGoahead": "fly forward [NUMS]cm",
    "gui.blocklyText.tello.telloBack": "fly backward [NUMS]cm",
    "gui.blocklyText.tello.telloTurnLeft": "fly left [NUMS]cm",
    "gui.blocklyText.tello.telloTurnRight": "fly right [NUMS]cm",
    "gui.blocklyText.tello.telloClockwiseRotation": "rotate [NUMS] degree clockwise",
    "gui.blocklyText.tello.telloCounterclockwiseSelection": "rotate [NUMS] degree counter clockwise",
    "gui.blocklyText.tello.telloSpeed": "set speed to [NUMS]cm/s",
    "gui.blocklyText.tello.telloRoll": "flip [ROLL]",
    "gui.blocklyText.tello.telloPower": "power?",
    "gui.blocklyText.tello.telloRollForward": "forward(f)",
    "gui.blocklyText.tello.telloRollBack": "back(b)",
    "gui.blocklyText.tello.telloRollLeft": "left(l)",
    "gui.blocklyText.tello.telloRollRight": "right(r)",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] received data?",
    "gui.blocklyText.obloq.initialParameter": "obloq mqtt initialization: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq read the message from [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq read the message",
    "gui.blocklyText.obloq.httpInitialParameter": "obloq http initialization: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "add subscribe [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "Wi-Fi name",
    "gui.blocklyText.obloq.password": "password",
    "gui.blocklyText.obloq.iotService": "IOT Service",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "IP Address",
    "gui.blocklyText.obloq.port": "Port",
    "gui.blocklyText.obloq.addTopic": "Add Topic, only add up to 4",
    "gui.blocklyText.obloq.deleteTopic": "Delete Topic",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "Server",
    "gui.blocklyText.obloq.global": "Global",
    "gui.blocklyText.obloq.china": "China",
    "gui.blocklyText.obloq.softwareSerial": "Software Serial",
    "gui.blocklyText.obloq.hardwareSerial": "Hardware Serial",
    "gui.blocklyText.obloq.hardwareSerial1": "Hardware Serial1",
    "gui.blocklyText.obloq.hardwareSerial2": "Hardware Serial2",
    "gui.blocklyText.obloq.hardwareSerial3": "Hardware Serial3",
    "gui.blocklyText.obloq.WhenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq message",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq get [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Debug info",
    "gui.blocklyText.obloq.ERROR": "Error code",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.infraredEmission.send": "Infrared send pin [PIN] Send message: address [ADDRESS] command [COMMAND]",
    "gui.blocklyText.oled2864.displayInit": "OLED display init address [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 display [TEXT] in line [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 display [TEXT] at position X: [X] Y: 16*[Y]",
    "gui.blocklyText.oled2864.screenRotation": "rotate screen to [ROTATION] degrees",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 clear",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "read pin [PIN] [DHT] [TYPE]",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx pin [PIN] PM concentration value [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 ultrasonic initialization Name [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04 ultrasonic distance measurement unit [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx pin [PIN] 0.1 liters of air in [DIAMETER] number of particles",
    "gui.blocklyText.sen0170.readWindspeed": "read pin [PIN] wind scale",
    "gui.blocklyText.sen0228.init": "Initialize ambient light (VEML7700) sensor",
    "gui.blocklyText.sen0228.readAmbientLight": "read Ambient Light(VEML7700)",
    "gui.blocklyText.dfr0026.readAmbientLight": "read pin [PIN] Ambient light",
    "gui.blocklyText.sen0177.readDustSensor": "read [TYPE] (ug/m3) interface [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "pin(D) [PIN] pin(MCLR) [RST] current gesture [TYPE]?",
    "gui.blocklyText.sen0203.readHeartRate": "read pin [PIN] [TYPE] switch to [SWITCH]",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] pin [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "read pin [PIN] grayscale",
    "gui.blocklyText.motucamera.initIICSerial": "mu camera init [MU] interface [IICSERIAL] until init success",
    "gui.blocklyText.motucamera.resetConfig": "mu camera [MU] reset to default",
    "gui.blocklyText.motucamera.algorithmSwitch": "mu camera [MU] [SWITCH] algorithm [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "mu camera [MU] [LED] when detect [COLOR1] when undetect [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "mu camera [MU] set algorithm [ALGORITHM] level [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "mu camera [MU] digital zoom [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "mu camera [MU] set baudrate [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "mu camera [MU] set white balance [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "mu camera [MU] high FPS mode [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "mu camera [MU] is detect [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": "mu camera [MU] is detect color recogniza x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "mu camera [MU] is detect color detect color = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "mu camera get [MU] alogrithm [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "mu camera [MU] color recognize [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "mu camera [MU] shape card [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "mu camera [MU] traffic card [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "mu camera [MU] number card [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "mu camera [MU] color recognize color = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "Color Detect",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "Color Recognize",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "Ball Detect",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "Body Detect",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "Shape Card",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "Traffic Card",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "Number Card",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "enable",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "disable",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "white",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "white",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "Auto",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "Speed",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "Balance",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "Accuracy",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "Auto",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "Lock",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "white",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "yellow",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "Auto",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "Level1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "Level2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "Level3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "Level4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "Level5",
    "gui.blocklyText.motucamera.setHighFPSOff": "OFF",
    "gui.blocklyText.motucamera.setHighFPSOn": "ON",
    "gui.blocklyText.motucamera.colorDetectBlack": "black",
    "gui.blocklyText.motucamera.colorDetectWhite": "white",
    "gui.blocklyText.motucamera.colorDetectRed": "red",
    "gui.blocklyText.motucamera.colorDetectYellow": "yellow",
    "gui.blocklyText.motucamera.colorDetectGreem": "green",
    "gui.blocklyText.motucamera.colorDetectCayan": "cayan",
    "gui.blocklyText.motucamera.colorDetectBlue": "blue",
    "gui.blocklyText.motucamera.colorDetectPerple": "perple",
    "gui.blocklyText.motucamera.colorDetectOthers": "others",
    "gui.blocklyText.motucamera.colorDetectPositionX": "x position",
    "gui.blocklyText.motucamera.colorDetectPositionY": "y position",
    "gui.blocklyText.motucamera.colorDetectPositionW": "width",
    "gui.blocklyText.motucamera.colorDetectPositionH": "height",
    "gui.blocklyText.motucamera.colorDetectPositionL": "label",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "red channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "green channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "blue channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "label channel",
    "gui.blocklyText.motucamera.shapeCardTick": "tick",
    "gui.blocklyText.motucamera.shapeCardCross": "cross",
    "gui.blocklyText.motucamera.shapeCardCircle": "circle",
    "gui.blocklyText.motucamera.shapeCardSquare": "square",
    "gui.blocklyText.motucamera.shapeCardTriangle": "triangle",
    "gui.blocklyText.motucamera.trafficCardForward": "Forward",
    "gui.blocklyText.motucamera.trafficCardLeft": "Left",
    "gui.blocklyText.motucamera.trafficCardRight": "Right",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "Turn Around",
    "gui.blocklyText.motucamera.trafficCardStop": "Stop",
    "gui.blocklyText.motucamera.lightSensorUse": "mu camera [MU] light sensor enable [LIGHTFUNC] detection",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "mu camera [MU] light sensor sensitivity [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "mu camera [MU] light sensor read proximity detection",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "mu camera [MU] light sensor read ambient light detection",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "mu camera [MU] light sensor read once gesture and saved",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "mu camera [MU] light sensor gesture =  [GESTURE]",
    "gui.blocklyText.motucamera.upward": "upward",
    "gui.blocklyText.motucamera.downward": "downward",
    "gui.blocklyText.motucamera.leftward": "leftward",
    "gui.blocklyText.motucamera.rightward": "rightward",
    "gui.blocklyText.motucamera.pull": "pull",
    "gui.blocklyText.motucamera.push": "push",
    "gui.blocklyText.motucamera.none": "none",
    "gui.blocklyText.motucamera.default": "default",
    "gui.blocklyText.motucamera.low": "low",
    "gui.blocklyText.motucamera.middle": "middle",
    "gui.blocklyText.motucamera.high": "high",
    "gui.blocklyText.motucamera.proximity": "proximity",
    "gui.blocklyText.motucamera.ambientLight": "ambient light",
    "gui.blocklyText.motucamera.gesture": "gesture",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi initialize port [SERIALPORT] RX[RX] TX[TX] UART baudrate [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi set ssid [SSID] password [PASSWORD] mode [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi conection succeeded？",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi disconnect",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi set target IP [IP] port [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi read target IP",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi read local IP",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi read",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi Write [WRITE]",
    "gui.blocklyText.dfr0552.init": "DA module init I2C address [ADDR] voltage reference [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "DA module output triangular wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "DA module output sine wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "DA module output voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "DA module memory voltage [VOLTAGE]mV",
    "gui.blocklyText.sen0117.init": "Initialize module interface [SERIALPORT] Rx[RX] Tx[TX] Busy[BUSY]",
    "gui.blocklyText.sen0117.playText": "Plays text [TEXT] background music [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "Plays voice prompt [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "Plays chord prompts [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "Set up the [INDEX] to [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "Setting the speech rate [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "Get the speech synthesis module [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "Prospects volume",
    "gui.blocklyText.sen0117.Background": "Background volume",
    "gui.blocklyText.sen0117.Speed": "Speed",
    "gui.blocklyText.sen0117.playChordCueTone": "Plays chord ring tones [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.No": "No (0)",
    "gui.blocklyText.sen0117.setPlayMode": "Set the playback mode to [MODE]",
    "gui.blocklyText.sen0117.pause": "Pause",
    "gui.blocklyText.sen0117.resume": "Resume",
    "gui.blocklyText.sen0117.stop": "Stop",
    "gui.blocklyText.sen0117.isIdle": "Play finish?",
    "gui.blocklyText.dfr0576.selectPort": "Select port [PORT]",
    "gui.blocklyText.dfr0576.begin": "Initialize the I2C cascade module address is [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680 initializes",
    "gui.blocklyText.sen0248.readBME680": "Get the BME680 sensor [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "Temperature (°C)",
    "gui.blocklyText.sen0248.Pressure": "Pressure (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Humidity (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0248.GasResistance": "Gas Resistance (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "initialize the I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Change I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Read ultrasonic sensor distance(cm)",
    "gui.blocklyText.sen0304.readTemperature": "Read ultrasonic sensor temperature(°C)",
    "gui.blocklyText.sen0307.readDistance": "Read [PIN] analog ultrasonic wave(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 initialization address [ADDR] mode [MODE]",
    "gui.blocklyText.sen0250.Step": "Step",
    "gui.blocklyText.sen0250.Measure": "Measure",
    "gui.blocklyText.sen0250.readAccelerated": "Get the [AXIS] axis Acceleration(g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 get step number",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "Gyro",
    "gui.blocklyText.sen0250.Acc": "Acceleration (g)",
    "gui.blocklyText.sen0224.init": "Initialize the LIS2DH sensor",
    "gui.blocklyText.sen0224.readAccelerated": "Read acceleration value of LIS2DH sensor [AXIS] axis (mg)",
    "gui.blocklyText.dfr0023.readLM35": "read pin [PIN] LM35 temprature(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "when pin [PIN] detected vibration",
    "gui.blocklyText.dfr0028.readTiltSensor": "pin [PIN] detected tilt?",
    "gui.blocklyText.dfr0029.readPress": "pin [PIN] button pressed?",
    "gui.blocklyText.dfr0030.readTouch": "pin [PIN] detected touch?",
    "gui.blocklyText.dfr0033.readMagnetic": "pin [PIN] detected magnet?",
    "gui.blocklyText.dfr0034.readSound": "read pin [PIN] Loudness",
    "gui.blocklyText.dfr0094.readIRReceived": "read pin [PIN] IR data",
    "gui.blocklyText.dfr0094.onReceiveEvent": "When the infrared signal is received Pin [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "address",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "command",
    "gui.blocklyText.midi.midiInit": "Init MIDI module interface [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.midi.midiOutput": "MIDI output channel [CHANNEL] pitch [PITCH] speed [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "read pin [PIN] Carbon Monoxide (CO) sensor",
    "gui.blocklyText.dfr0051.readVoltage": "read pin [PIN] Voltage(V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "read pin [PIN] Piezo Disk Vibration Sensor",
    "gui.blocklyText.dfr0058.readRotation": "read pin [PIN] Rotation Sensor",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "read pin [PIN] JoyStick [AXIS] Axis",
    "gui.blocklyText.dfr0061.readJoyStickButton": "pin [PIN] JoyStick Button(Z) pressed?",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut init data pin [DATAPIN] RTC pin [RTCPIN] cls pin [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Digital tube display segment code 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Digital tube display number [NUM]",
    "gui.blocklyText.dfr0072.dfr0072Dot": "Digital tube display dot [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "true",
    "gui.blocklyText.dfr0072.dotFalse": "false",
    "gui.blocklyText.dfr0072.numOff": "off",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Read lithium battery [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "voltage(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "remainingBattery(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD module init I2C address [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD module read [PIN] voltage (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD module get aisle [AISLE] voltage difference",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 and 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 and 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 and 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 and 3",
    "gui.blocklyText.dfr0588.initPin": "Init temperature pin [TEM] humidity pin [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "get temperature unit [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "get humidity(%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "CELSIUS",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "FAHRENHEIT",
    "gui.blocklyText.dfr0076.readFlameSensor": "read pin [PIN] Flame Sensor",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom get [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom read text name [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom read number name [KEY]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom init i2c address [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom write text [VALUE] name [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom write number [VALUE] name [KEY]",
    "gui.blocklyText.dfr0117.text": "Text",
    "gui.blocklyText.dfr0117.number": "Number",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom delete name [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom serial print all names baud [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom name [KEY] exist?",
    "gui.blocklyText.dfr0117.eepromFormat": "i2c eeprom format",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom name [KEY] is [TYPE]?",
    "gui.blocklyText.dfr0117.nameCount": "Name count",
    "gui.blocklyText.dfr0117.memorySize": "Storage space(byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "Remaining storage space(byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": "read pin [PIN] Triple Axis Accelerometer [AXIS] Axis",
    "gui.blocklyText.sen0018.readInfraredMotion": "read pin [PIN] Digital Infrared motion sensor",
    "gui.blocklyText.sen0014.readDistance": "read pin [PIN] Sharp IR Distance Sensor(mm)",
    "gui.blocklyText.sen0114.readMoisture": "read pin [PIN] Soil Moisture Sensor",
    "gui.blocklyText.sen0121.readSteam": "read pin [PIN] Steam Sensor",
    "gui.blocklyText.sen0204.readLiquidLevel": "pin [PIN] liquid level reached a certain height ?",
    "gui.blocklyText.sen0203.heartRate": "heart rate(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "blood oxygen saturation(HbO2)",
    "gui.blocklyText.sen0202.up": "Up",
    "gui.blocklyText.sen0202.down": "Down",
    "gui.blocklyText.sen0202.left": "Left",
    "gui.blocklyText.sen0202.right": "Right",
    "gui.blocklyText.sen0202.cw": "CW",
    "gui.blocklyText.sen0202.ccw": "CCW",
    "gui.blocklyText.dhtTHSensor.temperature": "temperature(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "moisture(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "pin [PIN] RGB [NUM1] to [NUM2] show color [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "pin [PIN]  [NUM1] to [NUM2] RGBs show gradient color from [COLOR1] to [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "pin [PIN] shift pixels by [NUM]",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "pin [PIN] rotate pixels by [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "pin [PIN] [NUM] RGB LEDs",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "pin [PIN] brightness [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "pin [PIN] clear all LEDs",
    "gui.blocklyText.ws2812.showHistogram": "pin [PIN]  [NUM1] to [NUM2] RGB LEDs show histogram  current value: [VALUE]  max value: [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "pin [PIN] LED brightness [BRIGHTNESS]",
    "gui.blocklyText.neopixel.init": "Lamp initialization name [NAME] pin [PIN] number [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Light strip [NAME] [INDEX] Color [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Light strip [NAME] [INDEX] Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.fillColor": "The light strip [NAME] is fully lit and the color is [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Light strip [NAME] All on Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.flush": "Light strip [NAME] settings take effect",
    "gui.blocklyText.neopixel.off": "Light strip [NAME] is off",
    "gui.blocklyText.neopixel.setBrightness": "Light strip [NAME] sets brightness to [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Rainbow light effect [NAME] Quantity [COUNT] Brightness [BRIGHTNESS] Offset [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7(mPython board)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9(mPython box)",
    "gui.blocklyText.dsTSensor.ds18b20": "Read pin [PIN] DS18B20 temperature(°C)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280 initializes",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "read [TYPE] BMP280 sensor",
    "gui.blocklyText.bmpTSensor.atmosPressure": "barometric(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "altitude(m)",
    "gui.blocklyText.bmpTSensor.temperature": "temperature(℃)",
    "gui.blocklyText.huskylens.init": "HuskyLens initialize pin [SETTING] until success",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens switch algorithm to [ALGORITHM]",
    "gui.blocklyText.huskylens.requestFirst": "HuskyLens request data once and save into the result",
    "gui.blocklyText.huskylens.resultGetIsStudy": "HuskyLens check if ID [IDNUM] is learned from the result?",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "HuskyLens check if ID [IDNUM] [BOXARROW] is on screen from the result?",
    "gui.blocklyText.huskylens.resultGetIDParameter": "HuskyLens get [PARAMETER] of ID [IDNUM] [TYPE] from the result",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens check if [BOXARROW] is on screen from the result?",
    "gui.blocklyText.huskylens.resultGetParameter": "HuskyLens get [PARAMETER2] of the No. [IDNUM] [TYPE] from the result",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens get a total number of learned IDs from the result",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens get a total number of [BOXARROW] from the result",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens get a total number of ID [IDNUM] [BOXARROW] from the result",
    "gui.blocklyText.huskylens.resultGetIDofWhichParameter": "HuskyLens get [PARAMETER] of the ID [IDNUM] No. [BOXNUM] [TYPE] from the result",
    "gui.blocklyText.huskylens.setIDname": "HuskyLens name ID [ID] of the current algorithm as [NAME]",
    "gui.blocklyText.huskylens.TakeScreenshot": "HuskyLens take [TYPE] and save to SD card",
    "gui.blocklyText.huskylens.writeOSD": "HuskyLens show custom texts [NAME] at position x [X] y [Y] on screen",
    "gui.blocklyText.huskylens.clearOSD": "HuskyLens clear all custom texts on screen",
    "gui.blocklyText.huskylens.learnOnce": "HuskyLens learn ID [ID] once automatically",
    "gui.blocklyText.huskylens.clearLearn": "HuskyLens forget all learning data of the current algorithm",
    "gui.blocklyText.huskylens.loadAndSaveModel": "HuskyLens [TYPE] current algorithm data as No. [INDEX] model of SD card",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.A": "photo",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.B": "screenshot",
    "gui.blocklyText.huskylens.LOADSAVE.A": "save",
    "gui.blocklyText.huskylens.LOADSAVE.B": "load",
    "gui.blocklyText.huskylens.resultGetNearCenter": "HuskyLens get [PARAMETER2] of [TYPE] closest to the center of screen from the result",
    "gui.blocklyText.huskylens.box": "frame",
    "gui.blocklyText.huskylens.arrow": "arrow",
    "gui.blocklyText.huskylens.faceRecognition": "Face recognition",
    "gui.blocklyText.huskylens.objectTracking": "Object tracking",
    "gui.blocklyText.huskylens.objectRecognition": "Object recognition",
    "gui.blocklyText.huskylens.lineTracking": "Line tracking",
    "gui.blocklyText.huskylens.colorRecognition": "Color recognition",
    "gui.blocklyText.huskylens.tagRecognition": "Tag recognition",
    "gui.blocklyText.huskylens.objectClassification": "Object classification",
    "gui.blocklyText.huskylens.QRRecognition": "QR recognition (Edu only)",
    "gui.blocklyText.huskylens.barcodeRecognition": "Barcode recognition (Edu only)",
    "gui.blocklyText.huskylens.x": "X center",
    "gui.blocklyText.huskylens.y": "Y center",
    "gui.blocklyText.huskylens.w": "width",
    "gui.blocklyText.huskylens.h": "height",
    "gui.blocklyText.huskylens.x1": "X beginning",
    "gui.blocklyText.huskylens.y1": "Y beginning",
    "gui.blocklyText.huskylens.x2": "X endpoint",
    "gui.blocklyText.huskylens.y2": "Y endpoint",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "Communication Method",
    "gui.blocklyText.huskylens.address": "Address",
    "gui.blocklyText.huskylens.sdaGreen": "SDA(Green)",
    "gui.blocklyText.huskylens.sclBlue": "SCL(Blue)",
    "gui.blocklyText.huskylens.rxGreen": "Rx(Green)",
    "gui.blocklyText.huskylens.txBlue": "Tx(Blue)",
    "gui.blocklyText.ps2.initPs2Handle": "init PS2 handle DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] setRunmble [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2 open runmble [RUMBLE] strength [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "PS2 reflush states",
    "gui.blocklyText.ps2.ps2ButtonState": "PS2 button [BUTTON] state is [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2 joystick [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "ON",
    "gui.blocklyText.ps2.OFF": "OFF",
    "gui.blocklyText.ps2.Triangle": "Triangle",
    "gui.blocklyText.ps2.Circle": "Circle",
    "gui.blocklyText.ps2.Cross": "Cross",
    "gui.blocklyText.ps2.Square": "Square",
    "gui.blocklyText.ps2.Left1": "Left 1",
    "gui.blocklyText.ps2.Left2": "Left 2",
    "gui.blocklyText.ps2.Left3": "Left 3",
    "gui.blocklyText.ps2.Right1": "Right 1",
    "gui.blocklyText.ps2.Right2": "Right 2",
    "gui.blocklyText.ps2.Right3": "Right 3",
    "gui.blocklyText.ps2.Up": "Up",
    "gui.blocklyText.ps2.Right": "Right",
    "gui.blocklyText.ps2.Down": "Down",
    "gui.blocklyText.ps2.Left": "Left",
    "gui.blocklyText.ps2.Select": "Select",
    "gui.blocklyText.ps2.Start": "Start",
    "gui.blocklyText.ps2.Hold": "Hold",
    "gui.blocklyText.ps2.Pressed": "Pressed",
    "gui.blocklyText.ps2.Released": "Released",
    "gui.blocklyText.ps2.CHANGE": "CHANGE",
    "gui.blocklyText.ps2.RightX": "Right X",
    "gui.blocklyText.ps2.RightY": "Right Y",
    "gui.blocklyText.ps2.LeftX": "Left X",
    "gui.blocklyText.ps2.LeftY": "Left Y",
    "gui.blocklyText.sen0236.bme280Init": "BME280 initializes",
    "gui.blocklyText.sen0236.readbme280Sensor": "Read I2C pin BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "Temperature(℃)",
    "gui.blocklyText.sen0236.Humidity": "Humidity(%rh)",
    "gui.blocklyText.sen0236.Altitude": "Altitude(m)",
    "gui.blocklyText.sen0236.Pressure": "Pressure(Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "initialize I2C LCD screen address [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "display [TEXT] in LCD line [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "display [TEXT] at LCD position X: [X] Y: [Y] ",
    "gui.blocklyText.LCD1602.displayClear": "clear the screen ",
    "gui.blocklyText.IICScan.readICCAddr": "read scanned I2C device address",
    "gui.blocklyText.mpyfile.initFile": "init file [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsList": "read file [FILENAME] as list, use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.writeFile": "write to file [FILENAME] data [WRITEDATA],use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.enter": "enter",
    "gui.blocklyText.mpyfile.comma": "comma",
    "gui.blocklyText.mpyfile.semicolon": "semicolon",
    "gui.blocklyText.mpyfile.space": "space",
    "gui.blocklyText.sdcard.initCS": "SD film pin [CSPIN] initialization successful?",
    "gui.blocklyText.sdcard.readFileNLine": "SD reads file [FILE] line [LINE]",
    "gui.blocklyText.sdcard.readFileAll": "SD reads file [FILE] all content",
    "gui.blocklyText.sdcard.writeFile": "SD write file [FILE] content [MSG] mode [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD delete file [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD get file [FILE] info [INFO]",
    "gui.blocklyText.sdcard.NAME": "NAME",
    "gui.blocklyText.sdcard.PATH": "PATH",
    "gui.blocklyText.sdcard.TYPE": "TYPE",
    "gui.blocklyText.sdcard.LINE": "LINE",
    "gui.blocklyText.sdcard.SIZE": "SIZE",
    "gui.blocklyText.sdcard.APPEND": "APPEND",
    "gui.blocklyText.sdcard.REPLACE": "REPLACE",
    "gui.blocklyText.sdcard.WARP": "WARP",
    "gui.blocklyText.sdcard.NOWARP": "NOWARP",
    "gui.blocklyText.sdcard.lsFile": "SD serial port prints all file information baud [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "initialize software serial [SERIALPORT] RX: [PINR] TX: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "software serial [SERIALPORT] baud rate [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "software serial [SERIALPORT] output [TEXT] [WAY], [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "Is there data to read on software serial [SERIALPORT]?",
    "gui.blocklyText.softSerialport.readSerialportData": "read software serial[SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "read [TYPE] on software serial [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "on bluetooth connected",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "on bluetooth disconnected",
    "gui.blocklyText.bluetooth.receivedKey": "when bluetooth received key [KEY] [TYPE]",
    "gui.blocklyText.bluetooth.string": "String",
    "gui.blocklyText.bluetooth.number": "Number",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "when bluetooth received data and meet [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "send string key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "send number key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.openService": "open bluetooth service",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "received data from bluetooth",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo Starts",
    "gui.blocklyText.leonardo.setBaud": "set [SERIAL] baud rate to [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.leonardo.getNumOfFloat": "[TARGET] retain [NNUMS] decimal",
    "gui.blocklyText.leonardo.serialPort1": "SerialPort0",
    "gui.blocklyText.leonardo.serialPort2": "SerialPort1",
    "gui.blocklyText.leonardo.dataReadable": "Is there data to read on [SERIAL]?",
    "gui.blocklyText.leonardo.readSerialData": "read [SERIAL] data",
    "gui.blocklyText.arduinounoR3.initTcs34725": "Initializes the TCS34725 color sensor",
    "gui.blocklyText.arduinounoR3.functionStarts": "Uno kit program starts",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "Initializes the TMI1650 display",
    "gui.blocklyText.arduinounoR3.readdigital": "read pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "Digital Sensor",
    "gui.blocklyText.arduinounoR3.buttonModule": "Button Module",
    "gui.blocklyText.arduinounoR3.stickButton": "Rocker Button",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.arduinounoR3.readAnalog": "read pin [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "Anolog Sensor",
    "gui.blocklyText.arduinounoR3.lightSensor": "Light Sensor",
    "gui.blocklyText.arduinounoR3.soundSensor": "Sound Sensor",
    "gui.blocklyText.arduinounoR3.stickDirection": "Rocker Direction",
    "gui.blocklyText.arduinounoR3.angleSensor": "Angle Sensor",
    "gui.blocklyText.arduinounoR3.graySensor": "Grayscale Sensor",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.arduinounoR3.open": "turn on",
    "gui.blocklyText.arduinounoR3.close": "turn off",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "Digital Actuator",
    "gui.blocklyText.arduinounoR3.ledLights": "LED Lights",
    "gui.blocklyText.arduinounoR3.fanModule": "Fan Module",
    "gui.blocklyText.arduinounoR3.recordModule": "Record Module",
    "gui.blocklyText.arduinounoR3.relay": "Relay",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.arduinounoR3.writeAnalog": " set pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "move forward",
    "gui.blocklyText.arduinounoR3.backward": "move backward",
    "gui.blocklyText.arduinounoR3.turnLeft": "turn left",
    "gui.blocklyText.arduinounoR3.turnRight": "turn right",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "robot [DIR] at [SPEED] % speed ",
    "gui.blocklyText.arduinounoR3.motorForward": "move forward",
    "gui.blocklyText.arduinounoR3.motorReverse": "move backward",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "motor [MOTOR] [DIR] at [SPEED] % speed ",
    "gui.blocklyText.arduinounoR3.stopMotor": "robot (MA and MB motors) stop moving",
    "gui.blocklyText.arduinounoR3.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "Infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "infrared pin [PIN] received the [BUTTON] pressed signal？",
    "gui.blocklyText.arduinounoR3.noSound": "no sound",
    "gui.blocklyText.arduinounoR3.half": "Half",
    "gui.blocklyText.arduinounoR3.quarter": "Quarter",
    "gui.blocklyText.arduinounoR3.oneInEighth": "One-eighth",
    "gui.blocklyText.arduinounoR3.wholeBeat": "Whole Beat",
    "gui.blocklyText.arduinounoR3.doubleBeat": "Double Beat",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "set pin [PIN] MP3 module volume to [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "set pin [PIN] MP3 module playing mode to [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "Stop",
    "gui.blocklyText.arduinounoR3.play": "Play",
    "gui.blocklyText.arduinounoR3.loop": "Loop",
    "gui.blocklyText.arduinounoR3.random": "Random",
    "gui.blocklyText.arduinounoR3.pause": "Pause",
    "gui.blocklyText.arduinounoR3.previous": "Previous",
    "gui.blocklyText.arduinounoR3.next": "Next",
    "gui.blocklyText.arduinounoR3.VolumePlus": "Volume+",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "Volume-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "pin [PIN] MP3 module play the [NUM] song",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "read pin [PIN] LM35 temperature(°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "read pin [PIN] DHT11 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "red",
    "gui.blocklyText.arduinounoR3.blue": "blue",
    "gui.blocklyText.arduinounoR3.green": "green",
    "gui.blocklyText.arduinounoR3.readColorSensor": "read TCS34725 color sensor [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "four-bit nixie tube TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "turn on",
    "gui.blocklyText.arduinounoR3.tm1650Close": "turn off",
    "gui.blocklyText.arduinounoR3.clearScreen": "clear screen",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": " 4-bit nixie tube TM1650 display string [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "[STATUS] the [ORDER] decimal point of 4-bit nixie tube TM1650",
    "gui.blocklyText.arduinounoR3.bright": "brighten",
    "gui.blocklyText.arduinounoR3.dark": "darken",
    "gui.blocklyText.arduinounoR3.setLightColor": "pin [PIN] the [NUM] LED display [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "pin [PIN] the [NUM] LED R:[R] G:[G] B:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "pin [PIN] [NUM] LEDs",
    "gui.blocklyText.arduinounoR3.ledControl": "LED matrix (max7219) initial parameter DIN pin [DIN], CS pin [CS], CLK pin [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "LED matrix (max7219) show pattern [PIC]",
    "gui.blocklyText.max.maxStart": "Max starts",
    "gui.blocklyText.max.onboardButtonPressed": "onboard button [PRESSED]?",
    "gui.blocklyText.max.pressed": "pressed",
    "gui.blocklyText.max.notPressed": "not pressed",
    "gui.blocklyText.max.all": "-1 ALL",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "set RGB lights [TYPE] color [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "read sound sensor",
    "gui.blocklyText.max.playSoundEffect": "play sound effect[SOUND]",
    "gui.blocklyText.max.setDPinValue": "set digital pin [PIN] to [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "read digital pin [PIN]",
    "gui.blocklyText.max.readAPinValue": "read analog pin [PIN]",
    "gui.blocklyText.max.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.max.readLinefindingSensor": "read [DIR] line-tracking sensor",
    "gui.blocklyText.max.left": "Left",
    "gui.blocklyText.max.middle": "Middle",
    "gui.blocklyText.max.right": "Right",
    "gui.blocklyText.max.setLineFidingCarLights": "car LED lights [SWITCH]",
    "gui.blocklyText.max.on": "on",
    "gui.blocklyText.max.off": "off",
    "gui.blocklyText.max.readUltrasoundSensor": "read ulrasonic sensor(cm)",
    "gui.blocklyText.max.readLightSensor": "read [LEFTRIGHT] light sensor",
    "gui.blocklyText.max.maxSpeedMove": "MAX move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] stops",
    "gui.blocklyText.max.showUserDefineExpressions": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.max.clear": "clear",
    "gui.blocklyText.max.do": "Do",
    "gui.blocklyText.max.re": "Re",
    "gui.blocklyText.max.mi": "Mi",
    "gui.blocklyText.max.fa": "Fa",
    "gui.blocklyText.max.sou": "So",
    "gui.blocklyText.max.la": "La",
    "gui.blocklyText.max.xi": "Ti",
    "gui.blocklyText.max.highdo": "highdo",
    "gui.blocklyText.max.brake": "brake",
    "gui.blocklyText.max.rebound": "rebound",
    "gui.blocklyText.max.bullets": "bullets",
    "gui.blocklyText.max.longecho": "long echo",
    "gui.blocklyText.max.playfulending": "ending",
    "gui.blocklyText.max.yo": "yo",
    "gui.blocklyText.max.wo": "wo",
    "gui.blocklyText.max.robottalking": "robot talking",
    "gui.blocklyText.sen0160.init": "Set Hx711 weight sensor calibration value [VALUE] with Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.sen0160.readValue": "Read Hx711 weight sensor(g) Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.knowflow.readORP": "read pin [PIN] ORP sensor (mV)",
    "gui.blocklyText.knowflow.readEC": "read pin [PIN] EC sensor (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "read pin [PIN] PH sensor(V1)",
    "gui.blocklyText.knowflow.readECV2": "read pin [PIN] EC sensor (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "read pin [PIN] PH sensor(V2)",
    "gui.blocklyText.knowflow.readDO": "read pin [PIN] DO sensor (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "pin [PIN] Peristaltic Pump rotate [DIR] at [SPEED]% speed",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] pin [PIN] relay",
    "gui.blocklyText.goble.readAxis": "read GOBLE rocker [AXIS] axis",
    "gui.blocklyText.goble.buttonIsPressed": "GOBLE [BUTTON] button pressed?",
    "gui.blocklyText.eeprom.clear": "eeprom clear",
    "gui.blocklyText.eeprom.read": "eeprom read from address [ADDR]",
    "gui.blocklyText.eeprom.write": "eeprom write to address [ADDR] with data [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom get crc from address [ADDRA] to address [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom get length",
    "gui.blocklyText.tds.readAnalogPin": "read analog pin [PIN] TDS sensor(ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "two stepper motors run simultaneously   X axis : [XSET] y axis: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "stepper motor [AXIS] axis  [STEPS] steps per revolution  Speed(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "stepper motor move [ROTATEDIR] for [STEPS] steps",
    "gui.blocklyText.steppermotor.setJointSteps": "two stepper motors run simultaneously. Move [XSTEPS] steps on x-axis [ROTATEDIRX]  Move [YSTEPS] steps on y-axis [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] starts",
    "gui.blocklyText.multithread.stopThread": "stop [THREAD]",
    "gui.blocklyText.multithread.startThread": "start up [THREAD]",
    "gui.blocklyText.multithread.loop1": "thread 1",
    "gui.blocklyText.multithread.loop2": "thread 2",
    "gui.blocklyText.multithread.loop3": "thread 3",
    "gui.blocklyText.multithread.loop4": "thread 4",
    "gui.blocklyText.multithread.loop5": "thread 5",
    "gui.blocklyText.multithread.loop6": "thread 6",
    "gui.blocklyText.multithread.loop7": "thread 7",
    "gui.blocklyText.multithread.loop8": "thread 8",
    "gui.blocklyText.multithread.all": "all",
    "text2speech.setRequestSever": "set sever[SERVER]",
    "text2speech.setSever2Voice": "set server2's voic to [VOICE]",
    "text2speech.setSever2Language": "set server2's language to [LANGUAGE]",
    "text2speech.setSever2Account": "set server2's account[MODAL]",
    "text2speech.international": "server1(international)",
    "text2speech.china": "server2(China)",
    "gui.blocklyText.text2speech.server2voice0": "duxiaomei",
    "gui.blocklyText.text2speech.server2voice1": "duxiaoyu",
    "gui.blocklyText.text2speech.server2voice3": "duxiaoyao",
    "gui.blocklyText.text2speech.server2voice4": "duyaya",
    "gui.blocklyText.text2speech.server2voice106": "dubowen",
    "gui.blocklyText.text2speech.server2voice110": "duxiaotong",
    "gui.blocklyText.text2speech.server2voice111": "duxiaomeng",
    "gui.blocklyText.text2speech.server2voice103": "dumiduo",
    "gui.blocklyText.text2speech.server2voice5": "duxiaojiao",
    "gui.blocklyText.DFR0151.init": "DS1307 initialization",
    "gui.blocklyText.DFR0151.adjustTime": "Adjust the DS1307 time to [YEAR]Year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0151.getTime": "get DS1307 time [INDEX]",
    "gui.blocklyText.DFR0151.Year": "year",
    "gui.blocklyText.DFR0151.month": "month",
    "gui.blocklyText.DFR0151.date": "date",
    "gui.blocklyText.DFR0151.hour": "hour",
    "gui.blocklyText.DFR0151.minute": "minute",
    "gui.blocklyText.DFR0151.second": "second",
    "gui.blocklyText.DFR0151.week": "week",
    "gui.blocklyText.DFR0469.init": "SD2405 initialization",
    "gui.blocklyText.DFR0469.adjustTime": "adjust SD2405 time to [YEAR]year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0469.getTime": "get SD2405 time [INDEX]",
    "gui.blocklyText.DFR0126.setPin": "The audio analysis module  pins #S[SPIN] #R[RPIN] Analog pin[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "read sound frequency  band[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Initialize the NFC module interface I2C",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Initialize the NFC module interface hardware serial port Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "Initialize the NFC module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "Initialize the NFC module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "NFC module block [DATABLOCK] byte [BYTES] write [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "read NFC module block [DATABLOCK] the [BYTES] byte (number)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "read NFC module block [DATABLOCK] all data (string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "read NFC module block UID (srting)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "detected card UID: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "detected card?",
    "gui.blocklyText.DFR0126.serial1": "serial1",
    "gui.blocklyText.DFR0126.serial2": "serial2",
    "gui.blocklyText.DFR0126.serial3": "serial3",
    "gui.blocklyText.sen0245.init": "Initialize the VL53L0X laser ranging sensor",
    "gui.blocklyText.sen0245.setVL53L0X": "VL53L0X accuracy[PRECISION] measurement mode[VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "get VL53L0X laser ranging value(mm)",
    "gui.blocklyText.SEN0245.LOW": "Low(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "High(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "Single",
    "gui.blocklyText.SEN0245.Continuous": "Continuous",
    "gui.blocklyText.serialMp3.initSerialMp3": "Init serial MP3 module interface [SERIALPORT] RX(green) [RX] TX(blue) [TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "set serial MP3 module playing mode to [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "Set serial MP3 module playing [VALUE] song",
    "gui.blocklyText.serialMp3.setMP3Volume": "Set serial MP3 module volume to [VOLUME]%",
    "gui.blocklyText.serialMp3.play": "play",
    "gui.blocklyText.serialMp3.pause": "pause",
    "gui.blocklyText.serialMp3.end": "end",
    "gui.blocklyText.serialMp3.prev": "prev",
    "gui.blocklyText.serialMp3.next": "next",
    "gui.blocklyText.serialMp3.volumeUp": "volume up",
    "gui.blocklyText.serialMp3.volumeDown": "volume down",
    "gui.blocklyText.serialMp3.playlist": "playlist",
    "gui.blocklyText.serialMp3.insertTracks": "insert tracks",
    "gui.blocklyText.serialMp3.volume": "volume",
    "gui.blocklyText.servo360.setServo360": "pin [PIN] servo rotate [DIR] at [SPEED] % speed",
    "gui.blocklyText.servo360.CW": "CW",
    "gui.blocklyText.servo360.CCW": "CCW",
    "gui.blocklyText.TCS34725.initTcs34725": "Initializes the TCS34725 color sensor",
    "gui.blocklyText.TCS34725.readTcs34725": "read TCS34725 color sensor [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "Turn GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "Don\"t turn GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "show text[TEXT] in [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "text scroll [DIR]",
    "gui.blocklyText.regEmotion8_16.left": "left",
    "gui.blocklyText.regEmotion8_16.right": "right",
    "gui.blocklyText.regEmotion8_16.lightAll": "show all dot matrix in [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "show coordinates of point (x:[XAXIS], y:[YXAXIS]) in [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "clear screen",
    "gui.codeLabel.dynamicVariables": "Dynamic variables",
    "gui.codeLabel.functionDeclaration": "Function declaration",
    "gui.codeLabel.staticConstants": "Static constants",
    "gui.codeLabel.createObject": "Create an object",
    "gui.codeLabel.mainProgramStart": "Main program start",
    "gui.codeLabel.SubthreadProgramStarts": "Subthread %1 program starts",
    "gui.codeLabel.customFunction": "Custom function",
    "gui.codeLabel.eventCallbackFunction": "Event callback function",
    "gui.codeLabel.staticFunctions": "Static functions",
    "gui.mainHeader.err": "!Error: The same program headers [%1] cannot be used at the same time. Please delete the duplicated block.",
    "gui.mainHeader.help": "!Tip: If multiple programs need to be executed at the same time, use the \"multi-threading function\" in \"Extension\", refer to the help documentation for details.",
    "gui.threadHeader.err1": "!Error: The program headers  [%1] cannot be used at the same time. They belong to different kits or boards.",
    "gui.threadHeader.err2": "!Error Tip: The same event headers [%1] cannot be used at the same time. Please delete the duplicated block.",
    "gui.blocklyText.mqtt.whenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT message",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi connect to account:[ACCOUNT] password:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi connected?",
    "gui.blocklyText.wifi.wifiDisConnected": "disconnect Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP mode:[ACCOUNT2] password:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi get soft ap ip",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi get [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Error code",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "ntp set network time, time zone [TIMEZONE] timer server [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "ntp get local time [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "UTCWest12_t",
    "gui.blocklyText.ntp.UTCWest11_t": "UTCWest11_t",
    "gui.blocklyText.ntp.UTCWest10_t": "UTCWest10_t",
    "gui.blocklyText.ntp.UTCWest9_t": "UTCWest9_t",
    "gui.blocklyText.ntp.UTCWest8_t": "UTCWest8_t",
    "gui.blocklyText.ntp.UTCWest7_t": "UTCWest7_t",
    "gui.blocklyText.ntp.UTCWest6_t": "UTCWest6_t",
    "gui.blocklyText.ntp.UTCWest5_t": "UTCWest5_t",
    "gui.blocklyText.ntp.UTCWest4_t": "UTCWest4_t",
    "gui.blocklyText.ntp.UTCWest3_t": "UTCWest3_t",
    "gui.blocklyText.ntp.UTCWest2_t": "UTCWest2_t",
    "gui.blocklyText.ntp.UTCWest1_t": "UTCWest1_t",
    "gui.blocklyText.ntp.UTCMiddle_t": "UTCMiddle_t",
    "gui.blocklyText.ntp.UTCEast1_t": "UTCEast1_t",
    "gui.blocklyText.ntp.UTCEast2_t": "UTCEast2_t",
    "gui.blocklyText.ntp.UTCEast3_t": "UTCEast3_t",
    "gui.blocklyText.ntp.UTCEast4_t": "UTCEast4_t",
    "gui.blocklyText.ntp.UTCEast5_t": "UTCEast5_t",
    "gui.blocklyText.ntp.UTCEast6_t": "UTCEast6_t",
    "gui.blocklyText.ntp.UTCEast7_t": "UTCEast7_t",
    "gui.blocklyText.ntp.UTCEast8_t": "UTCEast8_t",
    "gui.blocklyText.ntp.UTCEast9_t": "UTCEast9_t",
    "gui.blocklyText.ntp.UTCEast10_t": "UTCEast10_t",
    "gui.blocklyText.ntp.UTCEast11_t": "UTCEast11_t",
    "gui.blocklyText.ntp.UTCEast12_t": "UTCEast12_t",
    "gui.blocklyText.ntp.year": "year",
    "gui.blocklyText.ntp.month": "month",
    "gui.blocklyText.ntp.date": "date",
    "gui.blocklyText.ntp.hour": "hour",
    "gui.blocklyText.ntp.minute": "minute",
    "gui.blocklyText.ntp.second": "second",
    "gui.blocklyText.ntp.week": "week",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "when udp server recv [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "set udp server port [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "udp server send broadcast [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "when udp client recv [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "set udp client IP [IPADDR] port [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "udp client send broadcast [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "message",
    "gui.blocklyText.udp.ClientRecvedMsg": "message",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] add key [KEY] value [VALUE]",
    "gui.blocklyText.http.requestHeader": "HTTP request header add [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] URL [URL] timeout [TIMEOUT] ms",
    "gui.blocklyText.http.readMessage": "HTTP read [LINE] line message",
    "gui.blocklyText.http.param": "PARAM",
    "gui.blocklyText.http.header": "HEADER",
    "gui.blocklyText.http.oneline": "ONE",
    "gui.blocklyText.http.allline": "ALL",
    "gui.blocklyText.mqtt.mqttConnect": "connect MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT connected?",
    "gui.blocklyText.mqtt.acceptance.meaage": "When [TOPIC] received the message as [MSG]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.mqtt.initial": "MQTT initial [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「IFLYTEK」synthetic audio [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「IFLYTEK」identify audio [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「IFLYTEK」identify result",
    "gui.blocklyText.iflytek.CommunicationText": "text",
    "gui.blocklyText.iflytek.CommunicationTransfer": "transfer to audio file",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "audio file to be identified",
    "gui.blocklyText.mqtt.mqttDisconnect": "disconnect MQTT",
    "gui.blocklyText.internet.initRequestsType": "requests type",
    "gui.blocklyText.internet.initRequestsAddr": "requests address",
    "gui.blocklyText.internet.initAddDict": "init dict",
    "gui.blocklyText.internet.postFileAddr": "post file address",
    "gui.blocklyText.internet.postFilePath": "post file path",
    "gui.blocklyText.internet.postFileMime": "post file mime type",
    "gui.blocklyText.internet.postFileAddDict": "init dict",
    "gui.blocklyText.internet.internetGetpostInit": "internet initial [SETTING]",
    "gui.blocklyText.internet.initTypeAddr": "internet requests set type [POST] address [ADDR] headers:[HEADERS] params:[URL] data:[DICT]",
    "gui.blocklyText.internet.internetPostFile": "internet post file [SETTING]",
    "gui.blocklyText.internet.postFileAddrType": "POST file requests address [ADDR] path [PATH] MIME type [TYPE]",
    "gui.blocklyText.internet.postFileDict": "POST file set dict data [DICT] and send file",
    "gui.blocklyText.internet.responseContent": "response content form [DATATYPE]",
    "gui.blocklyText.internet.responseAttributes": "response attributes [ATTRIBUTES]",
    "gui.blocklyText.internet.createSocket": "create socket [SOKHANDEL] with af [AFINET] and type [SOCKTYPE]",
    "gui.blocklyText.internet.setSocket": "create socket [SOKHANDEL] with af [SOCKTYPE] and type [TYPENUM]",
    "gui.blocklyText.internet.closeSocket": "socket [SOKHANDEL] close",
    "gui.blocklyText.internet.bindSocket": "socket [SOKHANDEL] bind with address [SOCKADDR] port [SOCKPORT]",
    "gui.blocklyText.internet.listenSocket": "listen socket [SOKHANDEL] backlog number [BACKLOGNUM]",
    "gui.blocklyText.internet.recvReturnTuple": "socket [SOKHANDEL] receive connect requests and return socket tuple",
    "gui.blocklyText.internet.parseReturnIp": "parse host [HOSTADDR] port [SOCKPORT] and return ip",
    "gui.blocklyText.internet.connectHost": "socket [SOKHANDEL] connect host [HOSTADDR] port [SOCKPORT]",
    "gui.blocklyText.internet.sendReturnBytes": "socket [SOKHANDEL] send [SOCKMSG] and return sended bytes",
    "gui.blocklyText.internet.continueSendReturnBytes": "socket [SOKHANDEL] continue send [SOCKMSG] and return sended bytes",
    "gui.blocklyText.internet.sendToAddr": "socket [SOKHANDEL] send [SOCKMSG] to address [SOCKADDR]",
    "gui.blocklyText.internet.recvReturnRecvedObject": "socket [SOKHANDEL] receive and return received object max data is [MAXNUM]",
    "gui.blocklyText.internet.recvReturnRecvedTuple": "socket [SOKHANDEL] receive and return received tuple max data is [MAXNUM]",
    "gui.blocklyText.internet.setTimeout": "socket [SOKHANDEL] set timeout [TIMEOUT]",
    "gui.blocklyText.internet.setMode": "socket [SOKHANDEL] set mode [SOCKMODE]",
    "gui.blocklyText.internet.readReturnObject": "socket [SOKHANDEL] read [READBYTE] and return bytes object",
    "gui.blocklyText.internet.text": "text",
    "gui.blocklyText.internet.binary": "binary",
    "gui.blocklyText.internet.dictionary": "dictionary",
    "gui.blocklyText.internet.block": "block",
    "gui.blocklyText.internet.nonblock": "non-block",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 initializes",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 read altitude(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 read pressure(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 read temperature(℃)",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 measurement [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "object",
    "gui.blocklyText.sen0206.environment": "environment",
    "gui.blocklyText.sen0206.celsius": "celsius",
    "gui.blocklyText.sen0206.fahrenheit": "fahrenheit",
    "gui.blocklyText.sen0253.init": "10DOF Initial pin I2C_ADDR level [LEVEL]",
    "gui.blocklyText.sen0253.reset": "10DOF Sensor reset",
    "gui.blocklyText.sen0253.power": "10DOF Set power mode [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF Get euler angles [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF Get qua [AXIS] axis",
    "gui.blocklyText.sen0253.getAcc": "10DOF Get accelerometer [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF Get magnetometer [AXIS] axis(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF Get linear acceleration [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF Get gravit capacity [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF Get angular speed [AXIS] axis",
    "gui.blocklyText.sen0253.getBmp280": "10DOF Get [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "Low",
    "gui.blocklyText.sen0253.HIGH": "High",
    "gui.blocklyText.sen0253.normal": "Normal",
    "gui.blocklyText.sen0253.lowPower": "Low power",
    "gui.blocklyText.sen0253.suspend": "Suspend",
    "gui.blocklyText.sen0253.Roll": "Roll(°)",
    "gui.blocklyText.sen0253.Pitch": "Pitch(°)",
    "gui.blocklyText.sen0253.Yaw": "Yaw(°)",
    "gui.blocklyText.sen0253.temperature": "Temperature",
    "gui.blocklyText.sen0253.elevation": "Elevation",
    "gui.blocklyText.sen0253.airPressure": "AirPressure",
    "gui.blocklyText.sen0290.init": "lighting sensor set irq pin [IRQPIN] and i2c address [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "lighting sensor lightning sensor iic init ok？",
    "gui.blocklyText.sen0290.manualCal": "lighting sensor set capacitance [CAPACITANCE] mode [MODE] dist [DIST]",
    "gui.blocklyText.sen0290.indoors": "indoors",
    "gui.blocklyText.sen0290.outdoors": "outdoors",
    "gui.blocklyText.sen0290.disturberDis": "disturber disable",
    "gui.blocklyText.sen0290.disturberEn": "disturber enable",
    "gui.blocklyText.sen0290.errMsg": "error msg",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "lighting sensor get status",
    "gui.blocklyText.sen0290.GetLightningDistKm": "lighting sensor get lightning dist(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "lighting sensor get strike energy raw",
    "gui.blocklyText.sen0291.sen0291Init": "The power meter initializes the I2C address as [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "The power meter reads the load [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "The actual measured current of the power meter calibration is [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "Voltage (mV)",
    "gui.blocklyText.sen0291.electric": "Electric (mA)",
    "gui.blocklyText.sen0291.power": "Power (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "Shunt Voltage (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.weather.weatherGetWithCity": "[WEATHER] in [CITY] city [COUNTRY] province",
    "gui.blocklyText.weather.temperaturHigh": "maximum temperature(℃)",
    "gui.blocklyText.weather.temperaturLow": "minimum temperature(℃)",
    "gui.blocklyText.weather.weather": "weather",
    "gui.blocklyText.weather.weatherInit": "weather server Initialize [SETTING]",
    "gui.blocklyText.weather.serverAddr": "server address",
    "gui.blocklyText.weather.appid": "appid",
    "gui.blocklyText.weather.appsecret": "appsecret",
    "gui.blocklyText.weather.serverAddrTianqiAPI": "tianqi API(www.tianqiapi.com)",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "set server parameter [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "update tag [TAG] value [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "read tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "delete tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "count tag",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "network connection test",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "search tag from start number [NUMS] variable count [NUMV] tag [TAG] type [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "tag",
    "gui.blocklyText.tinywebdb.value": "value",
    "gui.blocklyText.tinywebdb.both": "both",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "HongKong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.getSpeech": "recognition result",
    "speech.setRecordTime": "set record time to [TIME]",
    "speech.stopRecord": "stop speech recognition",
    "speech.controlSonogram": "[CONTROL] Sonogram",
    "speech.setServer": "switch speech recognition server to [SERVER]",
    "speech.show": "show",
    "speech.hide": "hide",
    "speech.server1": "server1",
    "speech.server2": "server2",
    "gui.blocklyText.mp3.initMp3": "Init MP3 module interface [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "set MP3 module volume to [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "set MP3 module playing mode to [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "set MP3 module playing [NUM] song",
    "gui.blocklyText.tel0094.GPSInit": "GPS initializes as [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS gets the location [LATLON](°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "GPS gets the location [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "Longitude",
    "gui.blocklyText.tel0094.LONGITUDE": "Latitude",
    "gui.blocklyText.tel0094.YEAR": "Year",
    "gui.blocklyText.tel0094.MONTH": "Month",
    "gui.blocklyText.tel0094.DAY": "Day",
    "gui.blocklyText.tel0094.HOUR": "Hour",
    "gui.blocklyText.tel0094.MINUTE": "Minute",
    "gui.blocklyText.tel0094.SECOND": "Second",
    "gui.blocklyText.tel0094.ALL": "All",
    "gui.blocklyText.tel0094.GPSGet": "GPS gets [GPS]",
    "gui.blocklyText.tel0094.getState": "Status",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "Latitude hemisphere",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "Longitude hemisphere",
    "gui.blocklyText.tel0094.getGroundSpeed": "Ground speed",
    "gui.blocklyText.tel0094.getSatellitesCount": "Number of satellites",
    "gui.blocklyText.tel0094.getAltitude": "Altitude",
    "gui.blocklyText.tel0094.getPdop": "Position accuracy",
    "gui.blocklyText.tel0094.getHdop": "Horizontal accuracy",
    "gui.blocklyText.tel0094.getVdop": "Vertical accuracy",
    "gui.blocklyText.tel0094.getPositioningMode": "Positioning mode",
    "gui.blocklyText.microIoT.displayInLine": "OLED show line [LINE] text [TEXT]",
    "gui.blocklyText.microIoT.displayInXY": "OLED show [TEXT] x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED show image [IMAGE] x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED clear",
    "gui.blocklyText.microIoT.pointDrawing": "OLED draw point x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED set line width [LINEWIDTH]",
    "gui.blocklyText.microIoT.lineDrawing": "OLED draw line start x [X1] y [Y1] end x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED draw circle [FILL] circle center x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED draw box [FILL] start x [X] y [Y] width [WIDTH] height [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB set brightness to [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "RGB lights are all off",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "RGB lights [NUM1] through [NUM2] show colors [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "RGB lights [NUM1] to [NUM2] show gradients hue from [COLOR1] to [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "RGB loop by [NUM] pixel",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "RGB lights [NUM1] through [NUM2] show colors [COLOR]",
    "gui.blocklyText.motorIot.all": "ALL",
    "gui.blocklyText.microIot.servo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "MQTT initial parameter [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.microIot.MQTTMsg": "MQTT message",
    "gui.blocklyText.microTouch.whenBtnPress": "when keys [KEY] press",
    "gui.blocklyText.microTouch.Any": "Any",
    "gui.blocklyText.microTouch.isButtonPressed": "Keys [KEY] is pressed?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "Normal mode read keys",
    "gui.blocklyText.microTouch.readKeyMathMode": "Math mode read [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "Number key(num)",
    "gui.blocklyText.microTouch.Function": "Function key(str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] vibration motor",
    "gui.blocklyText.microTouch.turnOn": "Turn on",
    "gui.blocklyText.microTouch.shutDown": "Shut down",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "Sound intensity",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "Natural light",
    "gui.blocklyText.microNaturalScience.readColour": "Read [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "Red value",
    "gui.blocklyText.microNaturalScience.G": "Green value",
    "gui.blocklyText.microNaturalScience.B": "Blue value",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "UV intensity (μW/cm²)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "Turn on",
    "gui.blocklyText.microNaturalScience.off": "Turn off",
    "gui.blocklyText.microNaturalScience.setTDSK": "Set the TDS electrode constant k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "Obtain the TDS electrode constant k",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS(ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "Get [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "Water temperature (°C)",
    "gui.blocklyText.microNaturalScience.Temperature": "Temperature(℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "Humidity(%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "Altitude(m)",
    "gui.blocklyText.microNaturalScience.Pressure": "Pressure(Pa)",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB Set matrix point X [X] Y [Y] show colors [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 can not be placed in the interrupt event, may cause dht11 reading abnormal",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 can not be placed in the interrupt event, may cause dht22 reading abnormal",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 cannot be placed in an interrupt event, which may result in abnormal pm2.5 readings",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 reads data, which may cause led dot matrix initialization failure",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 reads data, which may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "the mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to be set to fail",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "the mp3 setting playback mode cannot be placed in an interrupt event, which may cause the playback mode to fail",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "Mp3 settings playback can not be placed in the interrupt event, may cause the settings to play failed",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "Serial port mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "The serial port mp3 playback mode cannot be placed in an interrupt event, which may cause the setup play mode to be lost",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "Serial port mp3 playback cannot be placed in an interrupt event, which may cause playback to fail",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "The serial port mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to fail",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloq's mqtt initialization soft serial port cannot be placed in an interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq sends a message that cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq read mqtt message can not be placed in the interrupt event, may cause the reception to fail",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "The initial soft serial port of http of obloq cannot be placed in the interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "The get request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "Obloq http post request cannot be placed in an interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "The put request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "Infrared reception cannot be placed in an interrupt event, which may cause reception failure",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "Infrared reception may cause the LED dot matrix initialization to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "Infrared reception may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "Infrared transmission cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause initialization to fail",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause setup pins to fail",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the pin to fail to fail",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause display color to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause color adjustment failure",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the move to fail",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause loop movement to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause the display histogram to fail",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "Led dot matrix can not be placed in the interrupt event, may cause initialization failure",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "The led dot matrix cannot be placed in an interrupt event, which may cause the display pattern to fail",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "The soft serial output cannot be placed in an interrupt event, which may cause the output to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "The data read by the soft serial port cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Soft serial port output data and read data colleague use, may cause data abnormalities",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "The soft serial port read integer cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "Soft serial port output data and reading integer colleague use may cause abnormal reading data",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "When dht11 and PM2.5 are in two threads, dht11 reads may fail if pm2.5 reads too frequently. It is recommended to reduce the pm2.5 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "When dht11 and ds18b20 are in two threads respectively, if ds18b20 reads too frequently, dht11 may fail to read, it is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "When dht11 and mp3 are initialized in two threads, mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "When dht11 and mp3 set volume are in two threads respectively, mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "When dht11 and mp3 setup modes are in two threads respectively, mp3 setup mode may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "When dht11 and mp3 player are in two threads, mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "When dht11 and serial mp3 are initialized in two threads, serial mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "When dht11 and serial mp3 control module are in two threads respectively, the mp3 control module may cause dht11 reading errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "When dht11 and serial mp3 player are in two threads, serial mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "When dht11 and serial mp3 set volume are in two threads respectively, serial mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "When dht11 and RGB are initialized in two threads, RGB initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "When dht11 and RGB set brightness in two threads, RGB set brightness may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "When dht11 and RGB are extinguished in two threads, RGB extinction may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "When dht11 and RGB display colors in two threads, RGB display colors may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "When dht11 and RGB display a gradient in two threads, respectively, RGB display a gradient may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "When dht11 and RGB move pixels in two threads, RGB move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "When dht11 and RGB loop move pixels in two threads, respectively, RGB loop move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "When dht11 and RGB display columns in two threads respectively, RGB display bars may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "When dht11 and soft serial send are in two threads respectively, soft serial send may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "When dht11 and obloq MQTT are initialized in two threads respectively, obloq MQTT initialization may cause dht11 to read errors. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "When dht11 and obloq MQTT send messages in two threads respectively, obloq MQTT send messages that may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "When dht11 and obloq HTTP put requests are in two threads, it can cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "When dht11 and obloq HTTP initialization are in two threads respectively, it may cause dht11 reading error. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "When dht11 and obloq HTTP get requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "When dht11 and obloq HTTP post requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "When dht11 and infrared emission are in two threads respectively, it may cause errors in reading dht11. It is suggested to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "When ds18b20 and PM2.5 are in two threads respectively, it may cause PM2.5 reading error, so it is suggested to reduce ds18b20 frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "When ds18b20 and obloq MQTT are initialized in two threads respectively, MQTT initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "When ds18b20 and obloq HQTT put requests are in two threads respectively, the put request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "When ds18b20 and obloq HTTP initialization are in two threads respectively, the HTTP initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "When ds18b20 and obloq HQTT get requests are in two threads respectively, the get request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "When ds18b20 and obloq HQTT post requests are in two threads respectively, the post request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Reading ds18b20 may result in no infrared data, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Reading ds18b20 may cause errors in receiving data by soft serial port, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "When PM2.5 read and obloq MQTT initialization are carried out in two threads respectively, MQTT initialization failure or PM2.5 read error may be caused. It is recommended to avoid simultaneous execution of MQTT initialization and PM2.5 read",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "When PM2.5 reading and obloq MQTT send messages in two threads respectively, PM2.5 reading errors may be caused. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "When PM2.5 read and obloq HTTP put requests are in two threads, it may cause put request failure or PM2.5 read error. It is recommended to reduce put request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "When PM2.5 reads and obloq HTTP initializations are carried out in two threads respectively, HTTP initializations may fail or PM2.5 reads may go wrong. It is recommended to avoid simultaneous execution of HTTP initializations and PM2.5 reads",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "When PM2.5 read and obloq HTTP get requests are in two threads respectively, it may cause get request failure or PM2.5 read error. It is recommended to reduce get request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "When PM2.5 read and obloq HTTP post request are in two threads respectively, it may cause post request failure or PM2.5 read error. It is recommended to reduce the frequency of post request or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "When PM2.5 reading and MP3 initialization are carried out in two threads respectively, PM2.5 reading may fail. It is recommended to avoid the simultaneous execution of PM2.5 reading and MP3 initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "When PM2.5 reading and MP3 setting volume are in two threads respectively, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "When PM2.5 reading and serial MP3 player are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "When PM2.5 reading and serial MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "When PM2.5 reading and serial MP3 setting volume are respectively in two threads, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "When PM2.5 reading may cause infrared reception failure, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "When PM2.5 reading and RGB initialization are carried out in two threads respectively, PM2.5 reading errors may be caused. It is recommended to avoid simultaneous execution of PM2.5 reading and RGB initialization",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "When PM2.5 reading and RGB setting brightness are respectively in two threads, PM2.5 reading error may be caused. It is recommended not to adjust brightness too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "When PM2.5 reading and RGB extinguishing are in two threads, PM2.5 reading errors may be caused. It is recommended not to extinguish RGB too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "When PM2.5 reading and RGB display colors are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to switch colors too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "When PM2.5 reading and RGB display the gradient in two threads respectively, PM2.5 reading error may be caused, it is recommended not to switch the gradient too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "When PM2.5 reading and RGB moving pixels are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to move pixels too frequently",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "Using Wi-Fi may cause the onboard rgb to display an abnormal color",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "Udp uses Wi-Fi, may cause the onboard rgb display color anomaly",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "Using Wi-Fi may cause the onboard rgb to set a three-color exception",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "Udp uses Wi-Fi, may cause the onboard rgb to set a three-color exception",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "Using Wi-Fi may cause the onboard rgb to set abnormal brightness",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "Udp uses Wi-Fi, may cause the onboard rgb to set the brightness abnormally",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "Using Wi-Fi may cause the onboard rgb to get abnormal brightness",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "Udp uses Wi-Fi, which may cause the onboard rgb to get abnormal brightness",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to be initialized abnormally",
    "gui.blocklyText.prompt.telloesp32.rgbnomal_rgbeffects": "When using the breathing light special effect block program, the LED light can no longer be set separately, please remove one of the blocks.",
    "gui.blocklyText.prompt.telloesp32.matrixnomal_matrixeffects": "Special effects are implemented for background threads, which conflict with the direct setting of matrix.",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 initialization to be abnormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "Using Wi-Fi may cause serial port mp3 to play track failed",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to play tracks failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "Using Wi-Fi may cause the serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "Using Wi-Fi may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp uses Wi-Fi, which may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "When PM2.5 read and RGB loop move pixels in two threads respectively, PM2.5 read errors may be caused. It is recommended not to execute loop move pixels too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "When PM2.5 reading and RGB display columns are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to execute RGB display columns too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "When PM2.5 reading and led dot array initialization are carried out in two threads respectively, led dot array initialization may go wrong. It is recommended to avoid the simultaneous execution of PM2.5 reading and led dot array initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "When PM2.5 reading and led dot array display are in two threads respectively, led dot array display may be wrong, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5 reading may cause errors in PM2.5 reading. It is recommended not to send data too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5 reading may cause soft serial port reading failure. It is recommended not to read PM2.5 too frequently",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 may not receive infrared data, it is recommended to reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "When MP3 and obloq MQTT initialization is in two threads, it may cause MQTT initialization to fail. It is recommended to avoid simultaneous MQTT initialization and MP3 initialization",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "When MP3 and obloq HTTP put requests are in two threads, it is possible to cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "When MP3 and obloq HTTP initializations are in two threads, HTTP initializations may fail. It is recommended to avoid both HTTP initializations and MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "When MP3 and obloq HTTP get requests are in two threads, it may cause get requests to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "When MP3 and obloq HTTP post requests are in two threads, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 may cause soft serial port read failure, it is recommended not to use MP3 too often",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "Serial MP3 may not receive infrared data, it is recommended to reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "When serial port MP3 and obloq MQTT initialization are in two threads respectively, MQTT initialization may fail. It is recommended to avoid simultaneous execution of MQTT initialization and serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "When serial MP3 and obloq HTTP put requests are in two threads, it may cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "HTTP initialization may fail when serial MP3 and obloq are initialized in two threads. It is recommended to avoid both HTTP initialization and serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "When the serial port MP3 and obloq HTTP get requests are in two threads respectively, it may cause the get request to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "When the serial port MP3 and obloq HTTP post requests are in two threads respectively, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of the serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "Serial port MP3 may cause soft serial port read failure, it is recommended not to use serial port MP3 too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving errors, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "Soft serial obloq may cause errors in infrared receiving data, so it is recommended to avoid sending messages frequently by obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "The initialization of RGB band may lead to the error of obloq receiving messages. It is recommended to avoid frequent use of RGB band",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Setting the brightness of the light belt may cause an error in obloq receiving messages. It is recommended to avoid frequent brightness adjustment",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB belt extinguish may lead to soft serial obloq receiving messages error, it is recommended to avoid frequent extinguish the belt",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB light band display may cause obloq to receive messages error, it is recommended to avoid frequent display of color",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "The display of gradient on the RGB band may cause errors in obloq receiving messages. It is recommended to avoid frequent display of gradient",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB band moving pixels may cause soft serial obloq to receive messages incorrectly",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "The RGB band loops the pixels around and may cause the soft serial obloq to receive messages out",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB ribbon display bar may cause soft serial obloq receive message error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "When soft serial obloq and led dot array initialization are carried out in two threads respectively, it may cause led dot array initialization error. It is recommended to avoid simultaneous execution of soft serial obloq and led dot array initialization",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "When soft serial obloq and led dot array display are respectively in two threads, it may cause led dot array display error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "Soft serial port sending may cause obloq reading error, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "Soft serial port obloq send may cause soft serial port read failure, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "Infrared emission may cause infrared reception of no data, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "When infrared emission may cause soft serial port reading failure, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "The initialization of RGB band may result in no infrared reception of data",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "The brightness setting of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent brightness setting of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "The RGB lamp belt is off, which may cause the infrared data can not be received",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "The moving pixel of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent moving pixel of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "Infrared reception may cause led initialization failure",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "Infrared reception may cause led display errors",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "Soft serial port sending may cause infrared data can not receive, it is recommended to avoid frequent sending soft serial port",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB band may cause soft serial port reading failure, so it is recommended to reduce the use frequency of RGB band",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Data received by soft serial port may cause led dot matrix display error",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "The sending of soft serial port may lead to the error of receiving data of soft serial port. It is recommended to avoid the execution of sending data and receiving data at the same time",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "The initialization of serial port MP3 may lead to errors in infrared receiving data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "MP3 serial port setting playing mode may cause errors in infrared receiving data, so it is recommended to avoid setting playing mode too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "Serial MP3 player may lead to infrared data errors, it is recommended to avoid switching songs too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "The volume setting of serial port MP3 may cause errors in infrared receiving data. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "Initialization of max7219 may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "Max7219 display images may cause errors in infrared received data, so it is recommended to avoid displaying images too frequently",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "The MP3 module of serial port may cause the pin interrupt cannot be triggered. It is recommended to avoid using MP3 module of serial port too often",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "The max7219 display module may cause pin interrupts not to be triggered. It is recommended to avoid using the max7219 display too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "DHT reading may lead to errors in infrared receiving messages. It is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "Ds18b20 reading may cause errors in soft external receiving messages, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5 reading may cause errors in receiving messages outside the software. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "Mp3 initialization read may cause infrared receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "The volume setting of mp3 may cause the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "Mp3 setting mode may cause errors in infrared receiving messages. It is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "Mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "Setting mode of serial port mp3 may cause error of infrared receiving message, it is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "Serial mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Setting the volume of serial port mp3 may lead to the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "Infrared emission may cause errors in infrared receiving messages, so it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "The initialization of the RGB band may lead to errors in receiving infrared messages. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving infrared messages. It is recommended to avoid setting the brightness too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB band display may cause errors in infrared receiving messages. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "Gradient discoloration of RGB lamp band display may lead to error of infrared receiving message. It is recommended to avoid displaying gradient discoloration too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Moving pixels with RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid moving pixels too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "The circular movement of pixels with the RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB ribbon display columnar may cause infrared receiving message error, it is recommended to avoid displaying columnar too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Infrared receiving messages may cause initialization failure of max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Infrared receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "Soft serial printing may cause errors in infrared receiving messages, so it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "Soft serial port obloq MQTT initialization may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "Soft serial obloq put request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "Soft serial port obloq HTTP initialization may lead to infrared receiving data errors, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "Soft serial obloq get request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "Soft serial obloq post request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "The clear display of RGB lamp band may cause errors in receiving messages in the soft infrared. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "DHT reading may lead to soft serial receiving message error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "Ds18b20 reading may cause the soft serial port to receive the message error, it is recommended to reduce the ds18b20 reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5 reading may cause errors in receiving messages by soft serial port. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "Mp3 initialization reads may result in a soft serial receiving message error, and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Setting the volume of mp3 may cause the error of receiving the message on the soft serial port. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "Mp3 setting mode may cause the soft serial port to receive the message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "Mp3 playback may lead to soft serial receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "Mp3 setting mode of serial port may cause error of receiving messages by soft serial port. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "Mp3 playing on serial port may cause errors in receiving messages on soft serial port. It is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message from soft serial port. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "Infrared emission may cause errors in receiving messages by soft serial port. It is recommended to avoid sending infrared messages too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "The initialization of the RGB band may result in an error in receiving messages from the soft serial port. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving messages from the soft serial port. It is recommended to avoid setting the brightness too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "Clear display of RGB light band may cause error of receiving message by soft serial port. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB band display may cause errors in receiving messages from the soft serial port. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB lamp tape display gradient may cause message error in soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause the soft serial port to receive messages incorrectly. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB light belt circular movement of pixels may cause the soft serial port to receive messages error, it is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB ribbon display columnar may cause the soft serial port to receive the message error, it is recommended to avoid performing display columnar too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "Soft serial receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "Soft serial receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "Soft serial printing may cause error in receiving messages from the soft serial port. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "DHT reads may cause pin interrupt triggering failure. It is recommended to reduce the DHT read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "Ds18b20 read may cause pin interrupt trigger failure, it is recommended to reduce ds18b20 read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5 reading may cause pin interrupt trigger failure. It is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Mp3 initialization reads may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "Mp3 setting volume may cause pin interrupt trigger failure. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "Mp3 setting mode may cause pin interrupt triggering failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "Mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "Serial port mp3 initialization read may cause pin interrupt trigger failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "Setting mode of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "Serial mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Setting the volume of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "Infrared emission may cause pin interrupt trigger failure, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB ribbon initialization may cause pin interrupt trigger failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Setting brightness of RGB band may cause pin interrupt trigger failure. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB strip clear display may cause pin interrupt trigger failure, it is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB tape display may cause pin interrupt trigger failure. It is recommended to avoid RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "The RGB tape display of the gradient may cause the pin interrupt trigger to fail. It is recommended to avoid displaying the gradient too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB band moving pixels may cause pin interrupt triggering failure and it is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB light belt loop move pixel may cause pin interrupt trigger failure, it is recommended to avoid too often loop move pixel",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB ribbon display bar may cause pin interrupt trigger failure. It is recommended to avoid displaying bar too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "Pin interruption may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "Pin interruption may cause max7219 to display incorrectly",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "Soft serial printing may cause pin interrupt trigger failure, it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "Soft serial port obloq MQTT initialization may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "Soft serial obloq put requests may cause pin interrupt triggering failure and it is recommended to avoid requesting data too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "Soft serial obloq HTTP initialization may cause pin interrupt triggering failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "Soft serial obloq get request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "Soft serial obloq post request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "DHT reading may lead to soft serial obloq receiving messages error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "Ds18b20 reading may cause obloq to receive messages incorrectly, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5 reading may cause error in obloq receiving messages. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "Mp3 initialization read may result in soft serial obloq receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Mp3 setting volume may result in soft serial obloq receiving message error. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "Mp3 setting mode may lead to soft serial obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "Mp3 playback may lead to soft serial obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port obloq receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "Serial port mp3 setting mode may cause soft serial port obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "Serial port mp3 player may lead to soft serial port obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message of soft serial port obloq. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving messages error, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Soft serial bloq sending messages may lead to infrared reception errors, it is recommended to avoid obloq sending messages too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB ribbon initialization may cause soft serial obloq to receive an error message, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Setting brightness of RGB light belt may cause error of obloq receiving messages. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Clear display of RGB light band may cause error of obloq receiving message. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB tape display may result in an error in obloq receiving messages. It is recommended to avoid performing RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB lamp tape display gradient may cause message error in obloq soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause an error in obloq receiving messages. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB light belt circular movement of pixels may lead to soft serial port obloq receive messages error, it is recommended to avoid too often loop movement of pixels",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB ribbon display columnar may cause soft serial obloq receive messages error, it is recommended to avoid too often display columnar",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "Soft serial obloq receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "Soft serial obloq receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "Soft serial printing may cause obloq to receive messages incorrectly. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "Soft serial port obloq sending data may cause errors in receiving data by soft serial port. It is recommended to avoid too frequent sending of data by obloq",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "Soft serial obloq sending data may cause pin interrupt cannot be triggered, it is recommended to avoid obloq sending data too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "Soft serial port obloq MQTT initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "Soft serial obloq put request may cause the soft serial port to receive data error, it is recommended to avoid too frequent requests for data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "Soft serial port obloq HTTP initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "Soft serial obloq get request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "Soft serial port obloq post request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.compatible.shapedBlock": "The new version changes point: the event program building block with its own variables is replaced by the event program building block without variables.",
    "gui.blocklyText.compatible.shapedBlock.variable": "The new version change point: change from independent program building block to variable program building block in event program",
    "gui.blocklyText.compatible.booleanToRound": "New version change point: Changed from conditional program building block to numerical program building block.",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "The new version changes point: add soft and hard serial port switch drop-down option in the program building block.",
    "gui.blocklyText.compatible.mp3.deletePin": "New version change point: Remove the pin drop-down option in the program building block.",
    "gui.blocklyText.compatible.sen0206.addDropdown": "New version change point: Add drop-down options to the program building block.",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "New version change point: Increase the pin drop-down option in the program building block.",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "New version change point: Modify the serial port drop-down option in the program building block.",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "The new version changes point: changed from the event program building block to the conditional program building block.",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "The new version changes point: delete block",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "The new version changes point: modify the square drop-down box to a circular drop-down box",
    "gui.blocklyText.pictureai.initToken": "init AI of picture[SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "get picture from local file[IMAGE]",
    "gui.blocklyText.pictureai.cameraToggle": "switch to camera [CAMERA_LIST]",
    "gui.blocklyText.pictureai.getVideoImage": "get picture video shortcut",
    "gui.blocklyText.pictureai.webPicture": "get picture from website[TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH]save camera shortcut to local[TEXT]",
    "gui.blocklyText.pictureai.face": "identify picture[TEXT] contain face",
    "gui.blocklyText.pictureai.word": "identify picture[TEXT] contain [SELECT]",
    "gui.blocklyText.pictureai.picture": "identify picture[TEXT] contain[SELECT]",
    "gui.blocklyText.pictureai.gesture": "identify picture[TEXT] contain gestture",
    "gui.blocklyText.pictureai.TOPIC_W_A": "words",
    "gui.blocklyText.pictureai.TOPIC_W_B": "number",
    "gui.blocklyText.pictureai.TOPIC_W_C": "car number",
    "gui.blocklyText.pictureai.TOPIC_W_D": "handwriting",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "left ankle",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "left ear",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "left elbow",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "left eye",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "left hip",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "left knee",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "left mouth corner",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "left shoulder",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "left wrist",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "neck",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "nose",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "right ankle",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "right ear",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "right elbow",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "right eye",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "right hip",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "right knee",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "right mouth corner",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "right shoulder",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "right wrist",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "top head",
    "gui.blocklyText.pictureai.initUserToken": "switch to a separate account[MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "identify picture[TEXT] contain body point",
    "gui.blocklyText.pictureai.faceContrast": "contrast face in picture [TEXT] with picture [TEXT2]",
    "gui.blocklyText.pictureai.faceMerge": "merge face in picture [TEXT] and picture [TEXT2]",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "image body",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "general purpose objects and scenes",
    "gui.blocklyText.pictureai.TOPIC_P_A": "plant",
    "gui.blocklyText.pictureai.TOPIC_P_B": "animal",
    "gui.blocklyText.pictureai.TOPIC_P_C": "flower",
    "gui.blocklyText.pictureai.TOPIC_P_D": "vegetables",
    "gui.blocklyText.pictureai.bodyPointSuccess": "Successful identification of key points in the human body?",
    "gui.blocklyText.pictureai.bodyPointInfo": "get BodyPoint [BODY] [XY]coordinate",
    "gui.blocklyText.pictureai.noPower": "Please check if the account has opened the feature",
    "gui.blocklyText.pictureai.pictureError": "the picture is not in the correct format",
    "gui.blocklyText.pictureai.noPicture": "please select picture first",
    "gui.blocklyText.pictureai.noVideo": "Please make sure that the camera is connected properly and that no other software is occupied, and turn on the camera, or restart the software",
    "gui.blocklyText.pictureai.defaultTitleText": "Video window",
    "gui.blocklyText.pictureai.videoContainer": "use [CON] show camera sceen",
    "gui.blocklyText.pictureai.TOPIC_stage": "stage",
    "gui.blocklyText.pictureai.TOPIC_popup": "popup",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "New version change point: There are program building blocks removed.",
    "gui.blocklyText.pictureai.gestureList": "gesture[TEXT]",
    "gui.blocklyText.pictureai.networkError": "Network error, please check the network connection",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "Successful face recognition?",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "face recognition results [TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "face num",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "age",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "beauty",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "gender",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "glasses",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "type",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "expression",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "face probability",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "left",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "top",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "width",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "height",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "landmark",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "occlusion",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "blur",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "completeness",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "human",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "cartoon",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Forward",
    "gui.blocklyText.maqueenPlus.Backward": "Backward",
    "gui.blocklyText.maqueenPlus.left": "Left",
    "gui.blocklyText.maqueenPlus.right": "Right",
    "gui.blocklyText.maqueenPlus.all": "All",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "set motor [DIR] move by [SPEED] speed [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "set motor [DIR] stop",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] speed compensation [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "read [DIR] motor speed",
    "gui.blocklyText.maqueenPlus.readMoterDir": "read [DIR] motor direction (0:stop 1:forward 2:back)",
    "gui.blocklyText.maqueenPlus.clearMoterRunedCircle": "clear the revolutions of wheel [DIR]",
    "gui.blocklyText.maqueenPlus.getMoterRunedCircle": "get the revolutions of wheel [DIR]",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "set [DIR] RGB show [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "read the line tracking sensor [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "read the sensation sensor [INDEX] grayscale value",
    "gui.blocklyText.maqueenPlus.getVersion": "get version",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "read ultrasonic sensor trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "when received [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "infrared data",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "gui.blocklyText.esp32.audioRecognition": "get voice command within [TIME] seconds",
    "gui.blocklyText.esp32.audioRecordingFree": "trelease cache of recording",
    "gui.blocklyText.esp32.audioRecordingStart": "record audio with storage path [PATH] and duration [TIME] second(s)",
    "gui.blocklyText.esp32.audioRecordingInit": "initialize recording",
    "gui.blocklyText.esp32.audioPlay": "Audio play [URL]",
    "gui.blocklyText.esp32.audioControl": "Audio [CONTROL]",
    "gui.blocklyText.esp32.AudioControl.A": "PAUSE",
    "gui.blocklyText.esp32.AudioControl.B": "GO ON",
    "gui.blocklyText.esp32.AudioControl.C": "STOP",
    "gui.blocklyText.esp32.audioSetVolume": "set Audio volume as [VOLUME]",
    "gui.blocklyText.esp32.audioFree": "deinit Audio",
    "gui.blocklyText.esp32.audioInit": "initialize Audio",
    "gui.blocklyText.esp32.audio": "audio",
    "gui.blocklyText.esp32.musicGetTempo": "current beats",
    "gui.blocklyText.esp32.musicSetTempo": "set each beat equal to [TICKS] notes, the number of beats per minute is [TEMPO]",
    "gui.blocklyText.esp32.musicSetPlaySpeed": "set the playback speed to [SPEED] times",
    "gui.blocklyText.esp32.musicPlayMelodySet": "play music [MELODY] [WAIT] [LOOP] at pin [PIN]",
    "gui.blocklyText.esp32.musicPlayMelody": "play music [MELODY] pin [PIN]",
    "gui.blocklyText.esp32.musicPlayNoteFrom": "play tone from [MIN] to [AMX] step [STEP] duration [DURATION] ms pin [PIN]",
    "gui.blocklyText.esp32.musicPlayNote": "play tone [NOTE] delay [DELAY] millisecond(s) at pin [PIN]",
    "gui.blocklyText.esp32.musicTone": "tone [NOTE]",
    "gui.blocklyText.esp32.musicPlayNoteContinuous": "play continuous tone [NOTE] at pin [PIN]",
    "gui.blocklyText.esp32.musicPlayList": "play note list [NOTELIST] [WAIT] [LOOP] at pin [PIN]",
    "gui.blocklyText.esp32.IsWait.A": "wait",
    "gui.blocklyText.esp32.IsWait.B": "don't wait",
    "gui.blocklyText.esp32.IsLoop.A": "loop playback",
    "gui.blocklyText.esp32.IsLoop.B": "play once",
    "gui.blocklyText.esp32.getNote": "note [NOTE] beat [BEAT]",
    "gui.blocklyText.esp32.musicPlay": "play note [NOTE] at pin [PIN]",
    "gui.blocklyText.esp32.restore": "restore music settings",
    "gui.blocklyText.esp32.stopPlay": "stop playing music at pin [PIN]",
    "gui.blocklyText.esp32.stopPlay_default": "default",
    "gui.blocklyText.mpyUART.input": "get input text from serial post ,title [TITLE]",
    "gui.blocklyText.mpyUART.close": "close serial port [UART]",
    "gui.blocklyText.mpyUART.readLength": "serial port [UART] read data with length as [LENGTH]",
    "gui.blocklyText.mpyUART.read": "serial port [UART] read data",
    "gui.blocklyText.mpyUART.readline": "serial port [UART] read a lien of data",
    "gui.blocklyText.mpyUART.any": "serial port [UART] has readable data?",
    "gui.blocklyText.mpyUART.writeBytes": "serial port [UART] write bytes [BYTES]",
    "gui.blocklyText.mpyUART.writeByte": "serial port [UART] write a byte [BYTE]",
    "gui.blocklyText.mpyUART.init": "initialize serial port [UART] as baudrate [BAUDRATE] tx [TXPIN] rx [RXPIN]",
    "gui.blocklyText.mpyUART.initTello": "initialize serial port [UART] rx [RXPIN] tx [TXPIN] as baudrate [BAUDRATE]",
    "gui.blocklyText.mpyUART.writeStringTello": "serial port [UART] write string [TEXT] [ENDSYMBOL] wrap",
    "gui.blocklyText.mpyUART.anyTello": "serial port [UART] has readable data?",
    "gui.blocklyText.mpyUART.readTello": "serial port [UART] read data",
    "gui.blocklyText.mpyUART.closeTello": "close serial port [UART]",
    "gui.blocklyText.mpyUART.writeString": "serial port [UART] write string [TEXT] [ENDSYMBOL] wrap",
    "gui.blocklyText.mpyUART.no": "no",
    "gui.blocklyText.mpyUART.auto": "auto",
    "gui.blocklyText.mpyI2C.scan": "I2C scan result",
    "gui.blocklyText.mpyI2C.read": "I2C address [ADDRESS] read bytes number [BYTES]",
    "gui.blocklyText.mpyI2C.write": "I2C address [ADDRESS] is written to [DATA]",
    "gui.blocklyText.mpyI2C.init": "initialize I2C SCL [SCLPIN] SDA [SDAPIN] baudrate [BAUDRATE]",
    "gui.blocklyText.esp32.readPinLevel": "duration(us) of external [LEVEL] pulse level at [PIN]",
    "gui.blocklyText.esp32.noPull": "no pull",
    "gui.blocklyText.esp32.pullDown": "pull down",
    "gui.blocklyText.esp32.pullUp": "pull up",
    "gui.blocklyText.esp32.setPinMode": "set pin [PIN] as [MODE] mode [PULL] and set the value to [LEVEL]",
    "gui.blocklyText.esp32.leakyOutput": "leaky output",
    "gui.blocklyText.mpyServo.setServo": "set the servo [PIN] angle as [ANGLE] pulse width from [MIN] us to [MAX] us actuation range as [ANGLEMAX]",
    "gui.blocklyText.mpyServo.setAngle": "set the servo [PIN] angle as [ANGLE]",
    "gui.blocklyText.esp32.shaked": "shaked",
    "gui.blocklyText.esp32.thrown": "thrown",
    "gui.blocklyText.esp32.tilt_forward": "tilt forward",
    "gui.blocklyText.esp32.tilt_back": "tilt back",
    "gui.blocklyText.esp32.tilt_right": "tilt right",
    "gui.blocklyText.esp32.tilt_left": "tilt left",
    "gui.blocklyText.esp32.tilt_none": "flat",
    "gui.blocklyText.esp32.attitudeEvent": "when the board is [ATTITUDE]",
    "gui.blocklyText.esp32.to_high": "↗ low to high",
    "gui.blocklyText.esp32.to_low": "↘ high to low",
    "gui.blocklyText.esp32.pinLevelEvent": "when pin [PIN] level from [TRANSITION]",
    "gui.blocklyText.mpyTimer.setEvent": "Set timer [INDEX] [MODE] period [PERIOD] milliseconds",
    "gui.blocklyText.mpyTimer.repeated": "repeated",
    "gui.blocklyText.mpyTimer.delayed": "delayed",
    "gui.blocklyText.mpyTimer.conditionEvent": "Set timer [INDEX] :when [CONDITION]",
    "gui.blocklyText.mpyTimer.getCount": "Timer [INDEX] count value",
    "gui.blocklyText.mpyTimer.clearEvent": "Clear timer/event [INDEX]",
    "gui.blocklyText.mpyDictionary.init": "init dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.getValue": "dictionary [CONTENT] value of [KEY]",
    "gui.blocklyText.mpyDictionary.setValue": "set dictionary [CONTENT] value of key [KEY] to [VALUE]",
    "gui.blocklyText.mpyDictionary.deleteKey": "delete dictionary [CONTENT] key [KEY]",
    "gui.blocklyText.mpyDictionary.clear": "clear dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.isInclude": "dictionary [CONTENT] include key [KEY]?",
    "gui.blocklyText.mpyDictionary.getLength": "length of dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.list": "list of [TYPE] in dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.TypeIndex.A": "key",
    "gui.blocklyText.mpyDictionary.TypeIndex.B": "value",
    "gui.blocklyText.mpyList.join": "List [LIST] use separators [SYMBOL] to combine text",
    "gui.blocklyText.mpyList.split": "Text [TEXT] use separators [SYMBOL] to make lists",
    "gui.blocklyText.mpyList.init": "init list [CONTENT]",
    "gui.blocklyText.mpyList.clear": "clear list [CONTENT]",
    "gui.blocklyText.mpyList.getLength": "length of list [CONTENT]",
    "gui.blocklyText.mpyList.isEmpty": "[CONTENT] is empty?",
    "gui.blocklyText.mpyList.getValue": "get value at index [INDEX] in list [CONTENT]",
    "gui.blocklyText.mpyList.getValueRange": "return list [CONTENT] takes [TYPE1] [NUM1] items to [TYPE2] [NUM2] items",
    "gui.blocklyText.mpyList.insert": "insert [VALUE] at index [INDEX] in list [CONTENT]",
    "gui.blocklyText.mpyList.setValue": "set index [INDEX] to [VALUE] in list [CONTENT]",
    "gui.blocklyText.mpyList.delete": "delete value at index [INDEX] from list [CONTENT]",
    "gui.blocklyText.mpyList.append": "append [APPEND] into list [CONTENT]",
    "gui.blocklyText.mpyList.extend": "list [LIST1] append list [LIST2]",
    "gui.blocklyText.mpyList.findIndex": "find index of [VALUE] in list [CONTENT]",
    "gui.blocklyText.mpyList.sort": "List [LIST] sort by [TYPE] as [MODE]",
    "gui.blocklyText.mpyList.SortModeIndex.A": "ascending",
    "gui.blocklyText.mpyList.SortModeIndex.B": "descending",
    "gui.blocklyText.mpyList.SortTypeIndex.A": "number",
    "gui.blocklyText.mpyList.SortTypeIndex.B": "letter",
    "gui.blocklyText.mpyList.SortTypeIndex.C": "letter, ignore case",
    "gui.blocklyText.mpySet.init": "init set [CONTENT]",
    "gui.blocklyText.mpySet.update": "update set [CONTENT1] as the [TYPE] with set [CONTENT2]",
    "gui.blocklyText.mpySet.TypeIndex.A": "intersection",
    "gui.blocklyText.mpySet.TypeIndex.B": "union",
    "gui.blocklyText.mpySet.TypeIndex.C": "difference",
    "gui.blocklyText.mpySet.addValue": "update set [CONTENT] with [VALUE]",
    "gui.blocklyText.mpySet.isSubsetSuperset": "set [CONTENT1] is [TYPE] of set [CONTENT2]?",
    "gui.blocklyText.mpySet.SubsetSupersetType.A": "subset",
    "gui.blocklyText.mpySet.SubsetSupersetType.B": "superset",
    "gui.blocklyText.mpySet.intersectionUnionSetDifference": "[TYPE] of set [CONTENT1] and set [CONTENT2]",
    "gui.blocklyText.mpySet.length": "length of set [CONTENT]",
    "gui.blocklyText.mpySet.pop": "return a random item from the set [CONTENT] ,and remove it",
    "gui.blocklyText.mpyTuple.init": "init tuple [CONTENT]",
    "gui.blocklyText.mpyTuple.minMaxLength": "tuple [CONTENT] [TYPE]",
    "gui.blocklyText.mpyTuple.TypeIndex.A": "min value",
    "gui.blocklyText.mpyTuple.TypeIndex.B": "max value",
    "gui.blocklyText.mpyTuple.TypeIndex.C": "length",
    "gui.blocklyText.mpyTuple.isInclude": "tuple [CONTENT] include [STRING]?",
    "gui.blocklyText.mpyTuple.getValueRange": "return tuple [CONTENT] takes [TYPE1] [NUM1] items to [TYPE2] [NUM2] items",
    "gui.blocklyText.mpyTuple.IndexType.A": "#",
    "gui.blocklyText.mpyTuple.IndexType.B": "countdown #",
    "gui.blocklyText.mpyTuple.listToTulpe": "list [LIST] conversion to tuple",
    "gui.blocklyText.mpyTuple.getValue": "get value at index [INDEX] in tuple [CONTENT]",
    "gui.blocklyText.mpyTuple.tupleToList": "convert tuple [CONTENT] into list",
    "gui.blocklyText.esp32.DisplayTypeMenu.A": "normal",
    "gui.blocklyText.esp32.DisplayTypeMenu.B": "rev",
    "gui.blocklyText.esp32.displayInnerImageInXY": "display built-in image [IMAGE] to x:[X] y:[Y] pattern [TYPE]",
    "gui.blocklyText.esp32.displayFileSystemImageInXY": "loading image from file system [IMAGE] show to x:[X] y:[Y] pattern [TYPE]",
    "gui.blocklyText.weather.GetWeatherWithCityName": "get [WEATHER] in [CITY] city",
    "gui.blocklyText.pictureai.createFaceClass": "cretae face class [NAME]",
    "gui.blocklyText.pictureai.pictureaiAddFaceToClass": "named recognition result [RES] and added to the face class [CLASSNAME]",
    "gui.blocklyText.pictureai.pictureaiSelectResultsFromClass": "search recognition results in the face library [CLASSNAME] where reliability >= [NUMBER]",
    "gui.blocklyText.pictureai.pictureaiWhenSearchName": "When the search results were [NAME]",
    "gui.blocklyText.pictureai.pictureaiSearchedName": "name of search results",
    "gui.blocklyText.pictureai.pictureaiSelectResultReliability": "reliability of search results",
    "gui.blocklyText.pictureai.pleaseUseSelefAccount": "face class function switch to separate account",
    "gui.blocklyText.pictureai.addfaceclasserror": "Error creating face class",
    "gui.blocklyText.pictureai.faceclassnameerror": "the name of the face class is composed of Numbers, letters and underscores",
    "gui.blocklyText.pictureai.faceclassnameexist": "class name is already exist",
    "gui.blocklyText.pictureai.addfaceerror": "Error adding face",
    "gui.blocklyText.pictureai.selectfaceclasserror": "Error search face class ",
    "gui.blocklyText.pictureai.notfoundmatch": "match user is not found",
    "boost.color.any": "any color",
    "boost.color.black": "black",
    "boost.color.blue": "blue",
    "boost.color.green": "green",
    "boost.color.red": "red",
    "boost.color.white": "white",
    "boost.color.yellow": "yellow",
    "boost.getMotorPosition": "motor [MOTOR_REPORTER_ID] position",
    "boost.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "boost.motorDirection.backward": "that way",
    "boost.motorDirection.forward": "this way",
    "boost.motorDirection.reverse": "reverse",
    "boost.motorOff": "turn motor [MOTOR_ID] off",
    "boost.motorOn": "turn motor [MOTOR_ID] on",
    "boost.motorOnFor": "turn motor [MOTOR_ID] for [DURATION] seconds",
    "boost.motorOnForRotation": "turn motor [MOTOR_ID] for [ROTATION] rotations",
    "boost.seeingColor": "seeing [COLOR] brick?",
    "boost.setLightHue": "set light color to [HUE]",
    "boost.setMotorDirection": "set motor [MOTOR_ID] direction [MOTOR_DIRECTION]",
    "boost.setMotorPower": "set motor [MOTOR_ID] speed to [POWER] %",
    "boost.tiltDirection.any": "any",
    "boost.tiltDirection.down": "down",
    "boost.tiltDirection.left": "left",
    "boost.tiltDirection.right": "right",
    "boost.tiltDirection.up": "up",
    "boost.whenColor": "when [COLOR] brick seen",
    "boost.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "ev3.beepNote": "beep note [NOTE] for [TIME] secs",
    "ev3.buttonPressed": "button [PORT] pressed?",
    "ev3.getBrightness": "brightness",
    "ev3.getDistance": "distance",
    "ev3.getMotorPosition": "motor [PORT] position",
    "ev3.motorSetPower": "motor [PORT] set power [POWER] %",
    "ev3.motorTurnClockwise": "motor [PORT] turn this way for [TIME] seconds",
    "ev3.motorTurnCounterClockwise": "motor [PORT] turn that way for [TIME] seconds",
    "ev3.whenBrightnessLessThan": "when brightness < [DISTANCE]",
    "ev3.whenButtonPressed": "when button [PORT] pressed",
    "ev3.whenDistanceLessThan": "when distance < [DISTANCE]",
    "wedo2.getDistance": "distance",
    "wedo2.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "wedo2.isTilted": "tilted [TILT_DIRECTION_ANY]?",
    "wedo2.motorDirection.backward": "that way",
    "wedo2.motorDirection.forward": "this way",
    "wedo2.motorDirection.reverse": "reverse",
    "wedo2.motorId.a": "motor A",
    "wedo2.motorId.all": "all motors",
    "wedo2.motorId.b": "motor B",
    "wedo2.motorId.default": "motor",
    "wedo2.motorOff": "turn [MOTOR_ID] off",
    "wedo2.motorOn": "turn [MOTOR_ID] on",
    "wedo2.motorOnFor": "turn [MOTOR_ID] on for [DURATION] seconds",
    "wedo2.playNoteFor": "play note [NOTE] for [DURATION] seconds",
    "wedo2.setLightHue": "set light color to [HUE]",
    "wedo2.setMotorDirection": "set [MOTOR_ID] direction to [MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "set [MOTOR_ID] power to [POWER]",
    "wedo2.tiltDirection.any": "any",
    "wedo2.tiltDirection.down": "down",
    "wedo2.tiltDirection.left": "left",
    "wedo2.tiltDirection.right": "right",
    "wedo2.tiltDirection.up": "up",
    "wedo2.whenDistance": "when distance [OP] [REFERENCE]",
    "wedo2.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "gui.blocklyText.video.notfound": "not found",
    "gui.blocklyText.machinelearning.drawResults": "[TOGGLE]draw recognition results",
    "gui.blocklyText.machinelearning.initKNNImageClass": "PC KNN Classification",
    "gui.blocklyText.machinelearning.imageClassificationFromCamera": "KNN classify the camera image as[TEXT]",
    "gui.blocklyText.machinelearning.imageClassificationFromDir": "KNN classify the local folder image [DIR] as [TEXT]",
    "gui.blocklyText.machinelearning.startKNNTrain": "KNN start classification training",
    "gui.blocklyText.machinelearning.predictImageClass": "KNN [TOGGLE] recognizing the camera picture classification",
    "gui.blocklyText.machinelearning.getImageClassification": "KNN recognize the classification result",
    "gui.blocklyText.machinelearning.getImageClassificationReliability": "KNN recognizes the credibility",
    "gui.blocklyText.machinelearning.clearImageClassificationLabel": "KNN clear tag [LABEL]",
    "gui.blocklyText.machinelearning.clearImageClassificationModel": "KNN clear classification model data",
    "gui.blocklyText.machinelearning.loadImageClassificationModel": "KNN load the computer-side classification model from [PATH]",
    "gui.blocklyText.machinelearning.exportImageClassificationModel": "KNN save the classification model",
    "gui.blocklyText.machinelearning.traceFace": "FaceAPI initializes face tracking",
    "gui.blocklyText.machinelearning.drawFaceAPI": "FaceAPI[TOGGLE] mark face key points",
    "gui.blocklyText.machinelearning.traceFaceNumber": "FaceAPI number of faces",
    "gui.blocklyText.machinelearning.getFaceByIndex": "FaceAPI get [PART] of  [INDEX] face with coordinate [TYPE]",
    "gui.blocklyText.machinelearning.initfaceContrast": "FaceAPI Initialize specific face recognition",
    "gui.blocklyText.machinelearning.refFaceFromCamera": "FaceAPI collect face data from the camera marked as [NAME]",
    "gui.blocklyText.machinelearning.refFaceFromDir": "FaceAPI collect face data from the folder [DIR] marked as folder name",
    "gui.blocklyText.machinelearning.faceAPIFindFace": "FaceAPI identify the face in the camera once",
    "gui.blocklyText.machinelearning.allowFaceAPIResult": "FaceAPI face recognition results",
    "gui.blocklyText.machinelearning.allowFaceAPIResultScore": "FaceAPI face recognition results credibility",
    "gui.blocklyText.machinelearning.clearFaceAPIModel": "FaceAPI Clear face model data",
    "gui.blocklyText.machinelearning.ml5WhenSearchFaceName": "when searching label[NAME] credibility>=[VALUE]%",
    "gui.blocklyText.machinelearning.machinelearningTracePose": "PoseNet initialize the posture tracking",
    "gui.blocklyText.machinelearning.drawPoseNet": "PoseNet[TOGGLE] marking posture key points",
    "gui.blocklyText.machinelearning.getPoseByIndex": "PoseNet get [PART] of [INDEX] posture with coordinate [TYPE]",
    "gui.blocklyText.machinelearning.initImageClassify": "Initialize object recognition image classifier (mobileNet)",
    "gui.blocklyText.machinelearning.toggleImageClassify": "[TOGGLE] Object recognition image classifier Real-time recognition",
    "gui.blocklyText.machinelearning.imageClassifyResult": "Object recognition recognition results",
    "gui.blocklyText.machinelearning.imageClassifyResultScore": "Object recognition recognition credibility",
    "gui.blocklyText.huskylens.initVisualModal": "initialize AI visualization tool to [TYPE]",
    "gui.blocklyText.huskylens.classifyImageFromCamera": "add screen pictures of the computer camera to the classifier [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromHuskylens": "add screen pictures of HuskyLens camera to the classifier [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromDir": "add images of the local file folder [DIR] to the classifier [TEXT]",
    "gui.blocklyText.huskylens.visualModalClearLabel": "clear classification [LABEL]",
    "gui.blocklyText.huskylens.visualModalClearAllLabels": "clear all classification of classifier",
    "gui.blocklyText.huskylens.deployClassificationToHuskylens": "Train the model and deploy to HuskyLens",
    "gui.blocklyText.machinelearning.loadlmodel": "model path",
    "gui.blocklyText.pictureai.TOPIC_BODY_mouth": "mouth",
    "gui.blocklyText.pictureai.TOPIC_BODY_jaw_outline": "jaw",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye_brow": "left eye brow",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye_brow": "right eye brow",
    "gui.blocklyText.huskylens.videoContainer": "use [CON] to show computer camera screen",
    "gui.blocklyText.huskylens.videoToggle": "[VIDEO_STATE] computer camera",
    "gui.blocklyText.huskylens.cameraToggle": "switch to computer camera [CAMERA_LIST]",
    "gui.blocklyText.huskylens.exportImage": "The computer camera take photo and save to local folder [TEXT]",
    "gui.blocklyText.dialog.ml5.loadModel": "Loading the model, the page may appear stuck, please wait...",
    "gui.blocklyText.dialog.ml5.reInitPictureClassify": "the image data already exists in the classifier. Reinitialization will empty the existing data. Confirm to continue?",
    "gui.blocklyText.dialog.ml5.whenDeleteImageAfterTrain": "This image data has been trained, after deletion, need to be retrained to take effect",
    "gui.blocklyText.dialog.ml5.renameLabel": "The tag [LABEL] already exists, do you want to merge it?",
    "gui.blocklyText.dialog.ml5.modelIsEmpty": "There is no classified data in this model. Please train the model first",
    "gui.blocklyText.dialog.ml5.shouldInitKNN": "Please initialize the KNN classification first",
    "gui.blocklyText.dialog.huskylens.emptyAllImage": "This operation will delete all categories, are you sure to continue?",
    "gui.blocklyText.dialog.huskylens.HuskyLensTakePhotoError": "Taking photos from HuskyLens failed, please try again",
    "gui.blocklyText.dialog.huskylens.overImageMaxLen": "The maximum number of [TYPE] classifiers is [LEN], the limit has been exceeded, please sort it out and try again",
    "gui.blocklyText.dialog.huskylens.notAllowChinese": "The category name should be composed of numbers and letters with no more than 50 digits, and cannot contain Chinese and special characters",
    "gui.blocklyText.dialog.ml5.isNotKNN": "Face recognition type classifier cannot join KNN training",
    "gui.blocklyText.machinelearning.start": "start",
    "gui.blocklyText.machinelearning.stop": "stop",
    "gui.blocklyText.huskyLensPro.ML5MODEL.A": "KNN classification",
    "gui.blocklyText.huskyLensPro.ML5MODEL.B": "face recognition",
    "gui.blocklyText.huskyLensPro.changeAlgorithm": "HuskyLens switch algorithm to [ALGORITHM]",
    "gui.blocklyText.huskyLensPro.learndIDCount": "HuskyLens get a total number of learned IDs",
    "gui.blocklyText.huskyLensPro.isOnTheScreen": "HuskyLens check if [BOXARROW] is on screen?",
    "gui.blocklyText.huskyLensPro.parametersNearTheCenter": "HuskyLens get [PARAMETER] of [BOXARROW] closest to the center of screen",
    "gui.blocklyText.huskyLensPro.idIsLearned": "HuskyLens check if ID [ID] is learned?",
    "gui.blocklyText.huskyLensPro.idIsOnTheScreen": "HuskyLens check if ID [ID] [BOXARROW] is on screen?",
    "gui.blocklyText.huskyLensPro.idParametersNearTheCenter": "HuskyLens get [PARAMETER] of ID [ID] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.boxCount": "HuskyLens get a total number of [BOXARROW]",
    "gui.blocklyText.huskyLensPro.boxarrowParameter": "HuskyLens get [PARAMETER] of the No.[INDEX] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.idBoxCount": "HuskyLens get a total number of ID [ID] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.idBoxarrowParameter": "HuskyLens get [PARAMETER] of the ID [ID] No.[INDEX] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.learnOnce": "HuskyLens learn ID [ID] once automatically",
    "gui.blocklyText.huskyLensPro.clearLearn": "HuskyLens forget all learning data of the current algorithm",
    "gui.blocklyText.huskyLensPro.setIDname": "HuskyLens name ID [ID] of the current algorithm as [NAME]",
    "gui.blocklyText.huskyLensPro.writeOSD": "HuskyLens show custom texts [NAME] at position x [X] y [Y] on screen",
    "gui.blocklyText.huskyLensPro.clearOSD": "HuskyLens clear all custom texts on screen",
    "gui.blocklyText.huskyLensPro.requestPicture": "HuskyLens [TYPE] and save to a local folder [PATH]",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.A": "take a photo",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.B": "screenshot",
    "gui.blocklyText.huskyLensPro.TakeScreenshotToSDCard": "HuskyLens [TYPE] and save to the SD card",
    "gui.blocklyText.huskyLensPro.xCenter": "X-Center",
    "gui.blocklyText.huskyLensPro.yCenter": "Y-Center",
    "gui.blocklyText.huskyLensPro.width": "Width",
    "gui.blocklyText.huskyLensPro.height": "Height",
    "gui.blocklyText.huskyLensPro.xStart": "X-Start",
    "gui.blocklyText.huskyLensPro.yStart": "Y-Start",
    "gui.blocklyText.huskyLensPro.xEnd": "X-End",
    "gui.blocklyText.huskyLensPro.yEnd": "Y-End",
    "gui.blocklyText.huskyLensPro.QRRecognition": "QR code recognition",
    "gui.blocklyText.huskyLensPro.barcodeRecognition": "Bar code recognition",
    "gui.blocklyText.huskyLensPro.saveFailed": "Failed to save picture!",
    "gui.blocklyText.huskyLensPro.yes": "yes",
    "gui.blocklyText.huskyLensPro.cancel": "cancel",
    "gui.blocklyText.huskyLensPro.promptInitializeClassifier": "Please initialize the image classifier first",
    "gui.blocklyText.huskyLensPro.promptInitializeKNNClassifier": "Please initialize the KNN classifier first",
    "gui.blocklyText.huskyLensPro.promptAddClassificationData": "Please add classification data first",
    "gui.blocklyText.huskyLensPro.setTitleText": "HuskyLens AI-KNN Image classification",
    "gui.blocklyText.huskyLensPro.tooManyPictures": "There are too many pictures, only the first fifty pictures are extracted",
    "gui.blocklyText.huskyLensPro.checkPath": "Please check if there are pictures in the path [PATH]",
    "gui.blocklyText.huskyLensPro.success": "success",
    "gui.blocklyText.huskyLensPro.importImg": "[COUNT1] pictures successfully imported this time, [COUNT2] failed",
    "gui.blocklyText.huskyLensPro.recognitionResult": "The recognition result:",
    "gui.blocklyText.tello.telloSetPitchRollYaw": "Set the amount of the four channel sticks of the remote control: roll [ROLL] pitch [PITCH] throttle [STRENGTH] yaw [YAW]",
    "gui.blocklyText.tello.telloQuerySpeed": "Current Speed",
    "gui.blocklyText.tello.telloTime": "Motor running time(s)",
    "gui.blocklyText.tello.telloWIFISign": "Wi-Fi SNR",
    "gui.blocklyText.tello.telloSDKVersion": "Tello SDK Version",
    "gui.blocklyText.tello.telloSN": "Tello SN Code",
    "gui.blocklyText.tello.telloFlyToXY": "Fly to x[X]cm y[Y]cm z[Z]cm speed [SPEED]cm/s",
    "gui.blocklyText.tello.reset": "Reset",
    "gui.blocklyText.tello.telloFlyCurve": "Flying curve warp x1[X1]cm y1[Y1]cm z1[Z1]cm and x2[X2]cm y2[Y2]cm z2[Z2]cm Speed ​​[SPEED] cm/s",
    "gui.blocklyText.tello.telloStopEmerygency": "Emergency Stop",
    "gui.blocklyText.tello.telloGetHeight": "Relative Height (cm)",
    "gui.blocklyText.tello.telloBarometer": "Barometer Height (m)",
    "gui.blocklyText.tello.telloTofDistance": "TOF Height (cm)",
    "gui.blocklyText.tello.telloTempMin": "Motherboard minimum temperature (°C)",
    "gui.blocklyText.tello.telloTempMax": "Motherboard maximum temperature (°C)",
    "gui.blocklyText.tello.TelloAttitudePitch": "Pitch axis attitude angle (°C)",
    "gui.blocklyText.tello.TelloAttitudeRoll": "Roll axis attitude angle (°C)",
    "gui.blocklyText.tello.TelloAttitudeYaw": "Pan axis attitude angle (°C)",
    "gui.blocklyText.tello.telloAccelerationX": "X-axis acceleration (0.001g)",
    "gui.blocklyText.tello.telloAccelerationY": "Y-axis acceleration (0.001g)",
    "gui.blocklyText.tello.telloAccelerationZ": "Z-axis acceleration (0.001g)",
    "gui.blocklyText.tello.telloSpeedX": "X-axis speed (cm/s)",
    "gui.blocklyText.tello.telloSpeedY": "Y-axis speed (cm/s)",
    "gui.blocklyText.tello.telloSpeedZ": "Z-axis speed (cm/s)",
    "gui.blocklyText.tello.TelloFlyCurveMissionPad": "Flying arc line under challenge card x1[X1]cm y1[Y1]cm z1[Z1]cm and x2[X2]cm y2[Y2]cm z2[Z2]cm speed [SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.telloJumpInMissionPad": "Jump along challenge card x[X]cm y[Y]cm z[Z]cm speed [SPEED]cm/s yaw [YAW] degree from Mid1 [MID1] to Mid2 [MID2]",
    "gui.blocklyText.tello.telloStopAndHover": "Stop motion and hover",
    "gui.blocklyText.tello.MissionPad": "Challenge Card Number",
    "gui.blocklyText.tello.MissionPadX": "Challenge Card X(cm)",
    "gui.blocklyText.tello.MissionPadY": "Challenge Card Y(cm)",
    "gui.blocklyText.tello.telloMissionPadZ": "Challenge Card Z(cm)",
    "gui.blocklyText.tello.telloCommandResponse": "Reply to Command",
    "gui.blocklyText.tello.direction_front": "Front View",
    "gui.blocklyText.tello.direction_blow": "Down View",
    "gui.blocklyText.tello.direction_all": "All",
    "gui.blocklyText.tello.mid": "Challenge Card",
    "gui.blocklyText.tello.midfastest": "m-1",
    "gui.blocklyText.tello.midlatest": "m-2",
    "gui.blocklyText.tello.telloToggleMon": "[TOGGLE] Challenge Card Detection",
    "gui.blocklyText.tello.telloSetMDireaction": "Set the detection position [POSITION]",
    "gui.blocklyText.tello.telloSetWIFI": "Modify Tello's Wi-Fi as a hotspot [NAME] password [PASSWD]",
    "gui.blocklyText.tello.telloToggleGroup": "Switch to station mode, connect to the hotspot [NAME] password [PASSWD]",
    "gui.blocklyText.telloGroup.telloRise": "Rise",
    "gui.blocklyText.telloGroup.telloDown": "Down",
    "gui.blocklyText.telloGroup.telloGoahead": "Go",
    "gui.blocklyText.telloGroup.telloBack": "Back",
    "gui.blocklyText.telloGroup.telloTurnLeft": "Left",
    "gui.blocklyText.telloGroup.telloTurnRight": "Right",
    "gui.blocklyText.telloGroup.telloSetFly": "[DIRECTION] [NUMS]cm",
    "gui.blocklyText.telloGroup.telloClockwiseRotation": "Clockwise",
    "gui.blocklyText.telloGroup.telloCounterclockwiseSelection": "Counterclockwise",
    "gui.blocklyText.telloGroup.telloClockRotation": "[CLOCKWISE] Rotation [NUMS] degrees",
    "gui.blocklyText.telloGroup.setTelloIndexWithSN": "set Tello SN[SN] number as [INDEX]",
    "gui.blocklyText.telloGroup.setTelloIndexWithSSID": "set Tello SSID[SSID] number as [INDEX]",
    "gui.blocklyText.telloGroup.sanTelloInNetwork": "scan [NUM] Tello in network timeout [TIME]s",
    "gui.blocklyText.telloGroup.telloSynchronousCommand": "Synchronous command waiting time [TIME] seconds",
    "gui.blocklyText.tello.telloVideoToggle": "turn Tello`s video [VIDEO_STATE]",
    "gui.blocklyText.telloGroup.TEMPH": "Maximum temperature",
    "gui.blocklyText.telloGroup.TEMPL": "lowest temperature",
    "gui.blocklyText.telloGroup.NUM": "number ",
    "gui.blocklyText.telloGroup.pitch": "pitch",
    "gui.blocklyText.telloGroup.roll": "roll",
    "gui.blocklyText.telloGroup.translation": "translation",
    "gui.blocklyText.telloGroup.telloAccelerationXYZ": "Acceleration [XYZ] (0.001g)",
    "gui.blocklyText.telloGroup.telloSpeedXYZ": "Speed [XYZ] (cm/s)",
    "gui.blocklyText.telloGroup.TelloAttitudePitch": "[ANGEL] attitude angle",
    "gui.blocklyText.telloGroup.telloTempMinAndMax": "Main board [TEMP](°C)",
    "gui.blocklyText.tello.telloDisplayRollText": "Scroll display text direction [DIRECT] frequency [SPEED] text [TEXT] color [COLOR]",
    "gui.blocklyText.tello.telloDisplayText": "Display a single character [TEXT] color [COLOR]",
    "gui.blocklyText.tello.telloRollText_up": "Up",
    "gui.blocklyText.tello.telloRollText_down": "Down",
    "gui.blocklyText.tello.telloRollText_left": "Left",
    "gui.blocklyText.tello.telloRollText_right": "Right",
    "gui.blocklyText.telloGroup.initTelloGroup": "Disconnect",
    "gui.blocklyText.tello.shutdownForPower": "The battery is insufficient [POWER], Tello will shut down soon",
    "gui.blocklyText.tello.portCantUse": "Service error, port [PORT] access failed, please check if it is occupied by other programs",
    "gui.blocklyText.tello.telloReadESP32Version": "the firmware version of the expansion module",
    "gui.blocklyText.tello.telloSSID": "the SSID of the expansion module",
    "gui.blocklyText.tello.telloLightLEDWithColor": "LED color[COLOR]",
    "gui.blocklyText.tello.telloLightLEDWithColor_rgb": "LED color R:[RGBR] G:[RGBG] B:[RGBB]",
    "gui.blocklyText.tello.telloBreathLEDWithColor": "LED breathing light color [COLOR] frequency [TIME](Hz)",
    "gui.blocklyText.tello.telloBreathLEDWithColor_rgb": "LED breathing light R:[RGBR] G:[RGBG] B:[RGBB] frequency:[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor": "LED flashes color 1[COLOR1] and color 2[COLOR2] frequency [TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor_rgb": "LED flashing R1:[RGBR1] G1:[RGBG1] B1:[RGBB1] - R2:[RGBR2] G2:[RGBG2] B2:[RGBB2] frequency:[TIME](Hz)",
    "gui.blocklyText.tello.telloShowPattern": "Display pattern [MARK]",
    "gui.blocklyText.tello.telloShowPatternWhenStart": "Set the boot pattern [MARK]",
    "gui.blocklyText.tello.telloShowRollPattern": "Rolling display pattern direction [DIRECT] frequency [SPEED] pattern [MARK]",
    "gui.blocklyText.tello.telloSetLight": "Set screen brightness [NUM]",
    "gui.blocklyText.tello.telloSendCMD": "Send custom command [CMD]",
    "gui.blocklyText.tello.telloReceiveMsg": "Received string message [MSG]",
    "gui.blocklyText.tello.telloReceiveCustomNum": "Customized response value received",
    "gui.blocklyText.tello.connectTelloWIFIError": "Failed to connect to Tello Wi-Fi, please try to connect to Wi-Fi manually",
    "gui.blocklyText.tello.connectTelloError": "Failed to connect to Tello, please confirm the device status and reconnect",
    "gui.blocklyText.tello.tempIsHigh": "Tello temperature is too high and will shut down soon",
    "gui.blocklyText.tello.scanErrorWithOpenWlan": "An error occurred during scanning, please check whether Wi-Fi is turned on,Please try to connect to Wi-Fi manually",
    "gui.blocklyText.tello.noTelloFound": "No Tello device available, please try to connect to Wi-Fi manually",
    "gui.blocklyText.tello.connectIsBroke": "Tello connection has been disconnected, please check the device",
    "gui.blocklyText.tello.firstConnectTello": "Please connect to Tello first",
    "gui.blocklyText.tello.telloQueryWifiVersion": "the Wi-Fi version of the extension module",
    "gui.blocklyText.tello.telloQueryHardwareVersion": "Get Hardware Version",
    "gui.blocklyText.tello.telloToggleTOF": "[TOGGLE] TOF Module",
    "gui.blocklyText.tello.telloToggleTOF_enable": "Enable",
    "gui.blocklyText.tello.telloToggleTOF_disabled": "Disable",
    "gui.blocklyText.tello.activeTelloFirst": "Connection failed, the new aircraft is not activated, please follow the aircraft manual to activate",
    "gui.blocklyText.tello.startPaddle": "Enter propeller mode",
    "gui.blocklyText.tello.stopPaddle": "Exit the propeller mode",
    "gui.blocklyText.tello.togglePaddle": "[TOGGLE] Paddle Mode",
    "gui.blocklyText.tello.telloTogglePaddle_enable": "Enter",
    "gui.blocklyText.tello.telloTogglePaddle_disabled": "Exit",
    "gui.blocklyText.tello.telloThrowAway": "Throw away in 5 seconds",
    "gui.blocklyText.tello.telloResetPattern": "Restore the default boot pattern",
    "gui.blocklyText.tello.telloQueryTelloVersion": "Tello firmware version",
    "gui.blocklyText.tello.telloQueryBatActive": "Tello query battery level",
    "gui.blocklyText.tello.telloReadTOF": "Read TOF(mm)",
    "gui.blocklyText.telloGroup.allTellos": "all",
    "gui.blocklyText.tello.notAllowInput": "Please enter numbers or English characters, no other special characters",
    "gui.blocklyText.tello.checkFirewallAndTello": "The line is disconnected, Please check the tello connection status, and please add the software to the computer firewall whitelist",
    "gui.blocklyText.telloGroup.checkWlanNum": "Please make sure the machine is connected to the correct network, if it is connected to multiple networks, please disconnect other connections first",
    "gui.blocklyText.telloGroup.telloIsNotExist": "[INDEX]  tello does not exist",
    "paint.paintEditor.hue": "Szín",
    "paint.paintEditor.saturation": "Telítettség",
    "paint.paintEditor.brightness": "Fényerő",
    "gui.comingSoon.message1": "Ne aggódj, rajta vagyunk {emoji}",
    "gui.comingSoon.message2": "Hamarosan...",
    "gui.comingSoon.message3": "Dolgozunk rajta {emoji}",
    "paint.paintEditor.fill": "Kitöltés",
    "paint.paintEditor.costume": "Jelmez",
    "paint.paintEditor.group": "Csoportosítás",
    "paint.paintEditor.ungroup": "Csoport bontása",
    "paint.paintEditor.undo": "Visszavonás",
    "paint.paintEditor.redo": "Mégis",
    "paint.paintEditor.forward": "Előreküld",
    "paint.paintEditor.backward": "Hátraküld",
    "paint.paintEditor.front": "Legelőre",
    "paint.paintEditor.back": "Leghátra",
    "paint.paintEditor.more": "Több",
    "paint.modeTools.brushSize": "Méret",
    "paint.modeTools.eraserSize": "Radír méret",
    "paint.modeTools.copy": "Másolás",
    "paint.modeTools.paste": "Beillesztés",
    "paint.modeTools.delete": "Törlés",
    "paint.modeTools.curved": "Ívelt",
    "paint.modeTools.pointed": "Csúcsos",
    "paint.modeTools.thickness": "Vastagság",
    "paint.modeTools.flipHorizontal": "Vízszintes tükrözés",
    "paint.modeTools.flipVertical": "Függőleges tükrözés",
    "paint.modeTools.filled": "Kitöltött",
    "paint.modeTools.outlined": "Körvonalazott",
    "paint.paintEditor.bitmap": "Konvertálás Bitképpé",
    "paint.paintEditor.vector": "Konvertálás Vektorképpe",
    "paint.paintEditor.stroke": "Körvonal",
    "paint.brushMode.brush": "Ecset",
    "paint.eraserMode.eraser": "Radír",
    "paint.fillMode.fill": "Kitöltés",
    "paint.lineMode.line": "Vonal",
    "paint.ovalMode.oval": "Kör",
    "paint.rectMode.rect": "Téglalap",
    "paint.reshapeMode.reshape": "Újraformálás",
    "paint.roundedRectMode.roundedRect": "Kerekített téglalap",
    "paint.selectMode.select": "Kijelölés",
    "paint.textMode.text": "Szöveg",
    "paint.colorPicker.swap": "Csere",
    "gui.dialog.notConnectDevice": "Nincs eszköz csatlakoztatva",
    "gui.dialog.prompt": "Jegyzet",
    "gui.dialog.openPort": "Nyitott port",
    "gui.dialog.error": "Hiba",
    "gui.dialog.notSelectDevice": "Nincs kiválasztott eszköz, kérlek kattints a [Kiterjesztés] gombra a program bal alsó sarkában,</br>és válaszd ki a csatlakoztatni kívánt eszközt",
    "gui.dialog.connectDeviceError1": "Eszköz csatlakoztatása(",
    "gui.dialog.connectDeviceError2": ")Sikertelen, kérlek kövesd az alábbi lépéseket:</br> *Válassz másik USB portot és csatlakoztasd újra a kábelt</br> *Nézd meg, hogy csatlakoztatva van-e",
    "gui.dialog.connectDeviceError3": "Fővezérlő lap</br> *Csatlakozz a hivatalos kommunikációs csoporthoz (671877416) a problémák megoldása érdekében",
    "gui.dialog.calibration1": "</br> *Kérlek forgasd",
    "gui.dialog.calibration2": "Fővezérlő lap kalibrálása",
    "gui.dialog.runAsManager": "Kérlek zárd be a Mind+ programot, kattints jobb gombbal a program ikonjára és válaszd a \"Futtatás rendszergazdaként\" opciót. A program újraindítása után válaszd ki újra ezt a képletet.",
    "gui.dialog.runErrorForVortex": "A rendszer nem működik megfelelően, válastd le a Vortexxet",
    "gui.dialog.runError": "A rendszer nem működik megfelelően",
    "gui.dialog.close": "Bezár",
    "gui.dialog.variableNameSpecialCharacters": "A %1 változó név nem tartalmazhat speciális karaktereket:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "A %1 függvény név nem tartalmazhat speciális karaktereket:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "függvény képletek %1 nem tartalmazhat speciális karaktereket:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "és",
    "gui.dialog.yes": "Igen",
    "gui.dialog.no": "Nem",
    "gui.dialog.maxReset": "Törlöd a kódot a szerkesztő területen és betöltöd a alap beállítású kódot?",
    "gui.dialog.feedbackCannotEmpty": "Kérlek töltsd ki a visszajelzést",
    "gui.dialog.noInstallCompiler": "Nincs fordítóprogram telepítve",
    "gui.dialog.install": "Online telepítés",
    "gui.dialog.cancle": "Mégsem",
    "gui.dialog.installingCompiler": "Fordítóprogram telepítése",
    "gui.dialog.uploading": "Feltöltés",
    "gui.dialog.changeLanuage": "A nyelv váltása törölni fogja a jelenlegi programot. Biztos folytatni akarod?",
    "gui.dialog.boardNotSupportMpy": "Jelenlegi lap: %1 nem támogatja a MicroPythont",
    "gui.dialog.loadBlankItem": "Nem támogatott eszköz</br> újra betölt egy üres eszközt",
    "gui.dialog.switchBaudError": "Hiba a baud érték váltásánál</br>",
    "gui.dialog.turnMicrobitCompass": "Szükséges az elektronikus iránytű kalibrálása, kérlek addig igazítsd a micro:bit helyzetét a LED fények mozgatásához, amíg minden LED égő világít",
    "gui.dialog.operationShow": "Művelet bemutatása",
    "gui.dialog.mpython": "MicroPython",
    "gui.dialog.computer": "Számítógép",
    "gui.dialog.delete": "Törlés",
    "gui.dialog.sureDeleteFile": "Biztosan törölni szeretnéd a [%2] fájlt ezen: %1?",
    "gui.dialog.sureDeleteFolder": "Biztosan törölni szeretnéd a [%2] mappát ezen: %1?",
    "gui.dialog.uncorrectPath": "Helytelen elérési út",
    "gui.dialog.laterDownLoad": "Feltöltés később",
    "gui.dialog.dwnLoadWebsite": "Weboldal letöltése",
    "gui.dialog.laterUpdate": "Telepítés később",
    "gui.dialog.update": "Telepítés",
    "gui.dialog.getUpdateMsgError": "Hiba történt az üzenet frissítésekor",
    "gui.dialog.ok": "Rendben",
    "gui.dialog.closeCodeBoxes": "Biztosan be akarod zárni a %1 kód dobozt",
    "gui.dialog.emailError": "Helytelen email formátum!",
    "gui.dialog.emailEmpty": "Az email nem lehet üres!",
    "gui.dialog.bigFilePrompt": "A fájl nagyméretű, türelmedet kérem, köszönjük!",
    "gui.dialog.successFeedbackPrompt1": "Köszönjük a visszajelzést, az oldal 3 másodperc múlva újra be fog tölteni.",
    "gui.dialog.successFeedbackPrompt2": "A visszajelzésed el lett küldve. Kérlek csak néhány másodperc múlva küldd el.",
    "gui.dialog.failedFeedbackPrompt": "Küldés sikertelen. Elnézést kérünk a kellemetlenségért. Kérjük látogasd meg az online fórumunkat és jelentsd a hibát a https://www.dfrobot.com/forum/ oldalon. Köszönjük!",
    "gui.dialog.successFeedbackPrompt3": "Köszönjük a visszajelzésed!",
    "gui.dialog.forcedCloseSoftWare": "Hiba történt a fájl mentése közben, szeretnéd kényszerítve bezárni a programot?",
    "gui.dialog.saveSuccess": "A projekt el lett mentve",
    "gui.dialog.noSelectTruePath": "A kiválasztott elérési út helytelen",
    "gui.dialog.newItemContent": "Az új üres projekt létrehozásával felül fogod írni a jelenlegit, biztos vagy benne?",
    "gui.dialog.deleteBlocksError": "%1 blokk törlése sikertelen, kérlek manuálisan töröld",
    "gui.dialog.netIsConnect": "Kérlek ellenőrizd, hogy a hálózat csatlakoztatva van-e",
    "gui.dialog.needToUpdate": "Frissíted a fordítóprogramot a legfrissebb verzióra: %1?",
    "gui.dialog.cantUseMpythonInArduinoC": "Nincs fordítóprogram telepítve, a MicroPython nem használható Arduino C-ben. Ha a számítógép nem csatlakozik az ine",
    "gui.dialog.enterMpyMode": "Belépés MicroPython módba",
    "gui.dialog.runHardware": "Connect the device first, then operate the hardware",
    "gui.dialog.sureDelete": "Biztosan törölni szeretnéd?",
    "gui.dialog.notSupportWebGL": "An error occurred in loading the stage, this may happen when your computer cannot support WebGL,</br> Refer to the details shown below: </br> %1 </br>QQ group contact us: 671877416",
    "gui.dialog.boardIsnotMPython": "A jelenlegi fővezérlő lap nem MicroPythonban csatlakozik, kérlek csatlakozz a MicroPythonhoz",
    "gui.dialog.swdMicrobitUpload": "A számítógépednek használnia kell az SWD-t a micro:bit égetéséhez, kérlek futtasd a Mind+ programot adminisztrátori jogosultsággal",
    "gui.dialog.openPortError": "nyitott port %1 hiba: %2",
    "gui.dialog.pressedA": "Kérlek tarsd lenyomva az A gombot, de engedd fel",
    "gui.dialog.unpressedA": "Kérlek engedd fel az A gombot",
    "gui.dialog.unsupportProject": "Nem támogatott eszköz</br> újra betölt egy üres eszközt",
    "gui.dialog.openProjectError": "Scratch módban készített projekteket Scratch módban lehet csak megnyitni.</br> Kérlek ellenőrizd a jelenlegi módot",
    "gui.dialog.installCompilerError1": "Letöltés sikertelen, töltsd le az offline telepítőt vagy próbáld újra",
    "gui.dialog.installCompilerOffline": "Offline fordítóprogram letöltése",
    "gui.dialog.installCompilerError2": "Hitelesítési hiba, töltsd le az offline telepítőt vagy próbáld újra",
    "gui.dialog.installCompilerError3": "Telepítési hiba, töltsd le az offline telepítőt vagy próbáld újra",
    "gui.dialog.installCompilerSuccess": "A fordítóprogram telepítve lett, most már használhatod az Esp32-őt Arduino C alatt",
    "gui.dialog.sureAdapter": "Az adapter nem található, kérlek győződj meg róla, hogy az adapter csatlakoztatva van",
    "gui.dialog.checkPort": "Nem sikerült megnyitni a soros portot, kérlek nézd meg, hogy a soros port foglalt-e.",
    "gui.dialog.changeBoard": "A lap cseréje törölni fogja a jelenlegi programot. Biztosan szeretnéd folytatni?",
    "gui.crashMessage.somethingWrong": "Hoppá! Valami nem sikerült.",
    "gui.crashMessage.reload": "Újraindítás",
    "gui.crashMessage.submit": "Elküld",
    "gui.crashMessage.attachment": "Csatolmány",
    "gui.crashMessage.errFeedbackNote": "Hibaüzeneteket vagy javaslatokat írhatsz ide, így a Mind+ még jobbá válhat",
    "gui.crashMessage.promptContent": "Sajnáljuk, úgy tűnik a Mind+ összeomlott. Elküldheted ezt a hibaüzenetet a Mind+ csapatának a jobb oldalon. Kérlek frissítsd az oldalad, hogy újrapróbáld",
    "gui.dialog.isSaveProject": "Mented a jelenlegi projektet?",
    "gui.dialog.save": "Mentés",
    "gui.dialog.notSave": "Nincs mentés",
    "gui.dialog.continue": "Folytatás",
    "gui.dialog.downLoadUpdate": "Frissítés letöltése",
    "gui.crashMessage.cacheListPrompt": "A projektfájlokat a menüben a 「Projekt」> 「Cache Lista」pont alatt találod",
    "gui.dialog.variableIsNull": "A változó neve nem lehet üres",
    "gui.dialog.listNameIsNull": "a lista neve nem lehet üres",
    "gui.dialog.variableIsRepeat": "a változó nevek nem ismétlődhetnek, kérlek módosítsd",
    "gui.dialog.pictureAINotNull": "Nem lehet null",
    "gui.dialog.pictureAIInitServer": "kérjük először készítsd elő a szervert",
    "gui.dialog.pictureAIInitServerError": "Error initializing the server, please check the account number and secret key",
    "gui.dialog.versionPrompt": "jelenlegi sb3 fájl verzió 「%1」",
    "gui.dialog.versionGreater": "The project version is higher than the current software version, project version 「%1」",
    "gui.dialog.noModulePrompt": "Nem található kis modul a jelenlegi besorolás alatt. A részletekért nézz rá a segítségre",
    "gui.dialog.firstSelectBoard": "Kérlek először válassz egy \"Lapot\" vagy egy \"Készletet\"",
    "gui.dialog.downloadFileError": "Fájletöltés hiba",
    "gui.dialog.noSearchModules": "Nem található kapcsolódó modul, kérlek nézd meg a keresési útvonalat",
    "gui.dialog.networkError": "Hálózati hiba",
    "gui.dialog.saveThirdModulesError": "Hiba a felhasználó könyvtárának mentésében",
    "gui.dialog.coverUseExForLoadProject": "Egy könyvtár már található azonos névvel a felhasználói könyvtárban, mely felül lett írva az ebben a projektben szereplő felhasználói könyvtárral",
    "gui.dialog.isContinueUpdateThirdModule": "There are currently the same user extension libraries. Continue to update will overwrite the current user library. Do you want to continue?",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "Modified the field 「%2」 of %1, which needs to be manually import in the extension library. This refresh is canceled",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "Error parsing %1, this refresh will continue, but the content in 「%2」 will not be updated",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "The shape of the building block or the type of the input box is modified. Please manually delete the highlighted building block in the programming area, and then refresh it. This refresh is cancelled.",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh1": "To modify the shape of the block or the input box type, please manually delete the highlighted block in the programming area and check the sprite「%1」before refreshing.",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh2": "The shape of the block or the type of the input box is modified. Please check the sprite「%1」and refresh it again. This refresh is canceled.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "Modification of the shape of the building block or the type of the input box, please manually delete the highlighted building block in the programming area before loading, and the loading is canceled.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad1": "To modify the shape of the block or the type of the input box, please manually delete the highlighted block in the programming area and check the sprite「%1」before loading, the loading is canceled this time.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad2": "The shape of the block or the input box type is modified. Please check the sprite「%1」and load it again. This loading is canceled.",
    "gui.dialog.thirdex.filesEmpty": "The files field cannot be empty, which may cause related files to be lost. Please check the imported user library files!",
    "gui.dialog.thirdex.refreshing": "Frissítés…",
    "gui.dialog.thirdex.refreshSuccess": "Frissítés sikeres",
    "gui.dialog.isSaving": "Mentés…",
    "gui.dialog.isExporting": "Exportálás…",
    "gui.dialog.mpySb3Prompt": "「%1」has updated the micropython mode. If there is any error in the content of the project, you need to manually modify it",
    "gui.dialog.translate.fieldError": "The following error fields may cause errors in the display of building blocks",
    "gui.dialog.translate.startReadFile": "Fájlok olvasásának kezdése, türelmedet kérem…",
    "gui.dialog.translate.fileFormatError": "There is no relevant translation file content, the file content format does not match",
    "gui.dialog.connecting.requestCloseFW": "Check that the firewall of this machine is open, which will cause Tello to fail to connect normally, click OK to close the firewall",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "mi": {
    "gui.backpack.header": "Pīkau",
    "gui.backpack.errorBackpack": "Kua raru te tāuta pīkau",
    "gui.backpack.loadingBackpack": "Kei te utaina...",
    "gui.backpack.more": "Anō",
    "gui.backpack.emptyBackpack": "Kua kautahanga te Pīkau",
    "gui.gui.costumesTab": "Ngā Kākahu",
    "gui.gui.soundsTab": "Ngā oro",
    "gui.gui.backdropsTab": "Ngā Ārai Tuarongo",
    "gui.gui.addExtension": "Tāpirihia tētahi Torohanga",
    "gui.costumeTab.addCostumeFromLibrary": "Kōwhiria he Kākahu",
    "gui.costumeLibrary.chooseACostume": "Kōwhiria he Kākahu",
    "gui.costumeTab.addBackdropFromLibrary": "Kōwhiria tētahi Ārai Tuarongo",
    "gui.costumeTab.addBlankCostume": "Peita",
    "gui.costumeTab.addSurpriseCostume": "Ohorere",
    "gui.costumeTab.addFileBackdrop": "Tukuatu Ārai Tuarongo",
    "gui.costumeTab.addFileCostume": "Tukuatu Kākahu",
    "gui.costumeTab.addCameraCostume": "Kāmera",
    "gui.soundEditor.trim": "Poroa",
    "gui.soundEditor.stop": "Katia",
    "gui.soundEditor.sound": "Oro",
    "gui.soundEditor.play": "Whakatangihia",
    "gui.soundEditor.save": "Tiakina",
    "gui.soundEditor.undo": "Wetekia",
    "gui.soundEditor.redo": "Mahia anōtia",
    "gui.soundEditor.faster": "Tere ake",
    "gui.soundEditor.slower": "Pōturi ake",
    "gui.soundEditor.echo": "Pāorooro",
    "gui.soundEditor.robot": "Karetao",
    "gui.soundEditor.louder": "Whakakaha i te oro",
    "gui.soundEditor.softer": "Ririki",
    "gui.soundEditor.reverse": "Hurikōaro",
    "gui.soundTab.recordSound": "Rēkoata",
    "gui.soundTab.addSoundFromLibrary": "Kōwhiria tētahi Oro",
    "gui.soundTab.surpriseSound": "Ohorere",
    "gui.soundTab.fileUploadSound": "Tukuatu oro",
    "gui.controls.stop": "Katia",
    "gui.controls.go": "Haere",
    "gui.stageHeader.stageSizeUnFull": "Puta atu i te aratau mata katoa",
    "gui.SpriteInfo.show": "Whakaaturia",
    "gui.SpriteInfo.size": "Rahi",
    "gui.directionPicker.rotationStyles.allAround": "Huri Noa",
    "gui.directionPicker.rotationStyles.leftRight": "Mauī/Katau",
    "gui.directionPicker.rotationStyles.dontRotate": "Kaua e takahuri",
    "gui.spriteSelectorItem.contextMenuDuplicate": "tāruatia",
    "gui.spriteSelectorItem.contextMenuDelete": "mukua",
    "gui.spriteSelectorItem.contextMenuExport": "kaweake",
    "gui.spriteSelector.addSpriteFromPaint": "Peita",
    "gui.spriteSelector.addSpriteFromSurprise": "Ohorere",
    "gui.spriteSelector.addSpriteFromFile": "Tukuatu Parehe",
    "gui.stageSelector.stage": "Atamira",
    "gui.stageSelector.backdrops": "Ngā Ārai Tuarongo",
    "gui.stageSelector.addBackdropFromPaint": "Peita",
    "gui.stageSelector.addBackdropFromSurprise": "Ohorere",
    "gui.stageSelector.addBackdropFromFile": "Tukuatu Ārai Tuarongo",
    "gui.modal.back": "Hoki atu",
    "gui.library.filterPlaceholder": "Rapua",
    "gui.libraryTags.all": "Te Katoa",
    "gui.libraryTags.animals": "Ngā Kararehe",
    "gui.libraryTags.dance": "Kanikani",
    "gui.libraryTags.effects": "Ngā pānga",
    "gui.libraryTags.fantasy": "Wawata",
    "gui.libraryTags.fashion": "Kākahu",
    "gui.libraryTags.food": "Kai",
    "gui.libraryTags.indoors": "Rō-whare",
    "gui.libraryTags.loops": "Koromeke",
    "gui.libraryTags.music": "Puoro",
    "gui.libraryTags.notes": "Tuhipoka",
    "gui.libraryTags.outdoors": "O Waho",
    "gui.libraryTags.patterns": "Tauira",
    "gui.libraryTags.people": "Tāngata",
    "gui.libraryTags.percussion": "Taonga papā",
    "gui.libraryTags.space": "Ātea",
    "gui.libraryTags.sports": "Hākinakina",
    "gui.libraryTags.underwater": "Raro wai",
    "gui.libraryTags.voice": "Reo",
    "gui.libraryTags.wacky": "Pukuhohe",
    "gui.libraryTags.animation": "Hākoritanga",
    "gui.libraryTags.art": "Toi",
    "gui.libraryTags.games": "Ngā Kēmu",
    "gui.libraryTags.stories": "Ngā Pakiwaitara",
    "gui.libraryTags.letters": "Ngā reta",
    "gui.soundLibrary.chooseASound": "Kōwhiria tētahi Oro",
    "gui.SpriteInfo.spritePlaceholder": "Ingoa",
    "gui.cards.more-things-to-try": "Ētahi mahinga kē kia whakamātauria!",
    "gui.cards.see-more": "Tirohia anōtia",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Katia",
    "gui.loader.message1": "Kei te waihanga i ngā paraka … ",
    "gui.loader.message2": "Kei te utaina ngā parehe ...",
    "gui.loader.message3": "Kei te utaina ngā oro …",
    "gui.loader.message4": "Kei te utaina ngā torohanga ...",
    "gui.loader.message5": "Kei te āngia ngā ngeru …",
    "gui.loader.message6": "Kei te whakawhiti i ngā nano ...",
    "gui.loader.message7": "Kei te koeketia ngā gobo...",
    "gui.loader.message8": "Kei te whakarite i ngā ata pūāhua …",
    "gui.loader.headline": "Kei te Utaina te Kaupapa",
    "gui.cameraModal.cameraModalTitle": "Tangohia tētahi Whakaahua",
    "gui.cameraModal.loadingCameraMessage": "Kei te utaina te Kāmera...",
    "gui.cameraModal.permissionRequest": "Me whai whakaaetanga mātou i a koe kia whakamahia tō kāmera",
    "gui.cameraModal.retakePhoto": "Tangohia anōtia te Whakaahua",
    "gui.cameraModal.save": "Tiakina",
    "gui.cameraModal.takePhoto": "Tangohia he Whakaahua",
    "gui.cameraModal.loadingCaption": "Kei te utaina...",
    "gui.cameraModal.enableCameraCaption": "Whakaāheitia te Kāmera",
    "gui.recordModal.title": "Hopu Oro",
    "gui.playbackStep.stopMsg": "Katia",
    "gui.playbackStep.playMsg": "Whakatangihia",
    "gui.playbackStep.loadingMsg": "Kei te utaina...",
    "gui.playbackStep.saveMsg": "Tiakina",
    "gui.playbackStep.reRecordMsg": "Hopukina anōtia",
    "gui.webglModal.label": "Kāore a WebGL i te tautokona e tō pūtirotiro",
    "gui.webglModal.webgllink": "kāore e tautokona te WebGL",
    "gui.prompt.cancel": "Whakakore",
    "gui.prompt.ok": "Ka pai",
    "gui.customProcedures.addAnInputNumberText": "Tāpirihia tētahi tāuru",
    "gui.customProcedures.addAnInputBoolean": "Whakaurua tētahi tāuru",
    "gui.customProcedures.numberTextType": "he nama, he kupu rānei",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Tāpirihia tētahi kupu tapanga:",
    "gui.customProcedures.runWithoutScreenRefresh": "Whakahaeretia, kāore he whakahounga o te mata",
    "gui.customProcedures.cancel": "Whakakore",
    "gui.customProcedures.ok": "Ka pai",
    "gui.extension.microbit.description": "Honohonoa ō kaupapa ki te ao.",
    "gui.extension.music.name": "Puoro",
    "gui.extension.music.description": "Whakatangi i ngā tāonga puoro me ngā pahū.",
    "gui.extension.pen.name": "Pene",
    "gui.extension.pen.description": "Mā te parehe e tuhi.",
    "gui.gui.variableScopeOptionAllSprites": "Mō ngā parehe katoa",
    "gui.gui.variableScopeOptionSpriteOnly": "Mō tēnei parehe anake",
    "gui.gui.variablePromptAllSpritesMessage": "Ka wātea tēnei taurangi mō ngā parehe katoa.",
    "gui.monitor.contextMenu.default": "pānui māori",
    "gui.monitor.contextMenu.large": "pānui rahi",
    "gui.monitor.contextMenu.slider": "rēreti",
    "gui.monitor.contextMenu.import": "kaweake",
    "gui.monitor.contextMenu.export": "kaweake",
    "gui.opcodeLabels.direction": "ahunga",
    "gui.opcodeLabels.xposition": "tūnga x",
    "gui.opcodeLabels.yposition": "tūnga y",
    "gui.opcodeLabels.size": "rahi",
    "gui.opcodeLabels.costumename": "ingoa kākahu",
    "gui.opcodeLabels.costumenumber": "nama kākahu",
    "gui.opcodeLabels.backdropname": "ingoa ārai tuarongo",
    "gui.opcodeLabels.backdropnumber": "nama ārai tuarongo",
    "gui.opcodeLabels.volume": "kahaoro",
    "gui.opcodeLabels.tempo": "tere",
    "gui.opcodeLabels.answer": "whakautu",
    "gui.opcodeLabels.loudness": "hoihoi",
    "gui.opcodeLabels.year": "tau",
    "gui.opcodeLabels.month": "marama",
    "gui.opcodeLabels.date": "te rā",
    "gui.opcodeLabels.dayofweek": "te rangi o te wiki",
    "gui.opcodeLabels.hour": "haora",
    "gui.opcodeLabels.minute": "miniti",
    "gui.opcodeLabels.second": "hēkona",
    "gui.opcodeLabels.timer": "taima",
    "music.categoryName": "Puoro",
    "translate.categoryName": "Google Translate",
    "pen.categoryName": "Pene",
    "pen.changeColorParam": "panonitia te [COLOR_PARAM] o te pene mā te [VALUE]",
    "pen.changeHue": "panonitia te tae o te pene mā te [HUE]",
    "pen.changeShade": "panonitia te kauruku o te pene mā te [SHADE]",
    "pen.changeSize": "panonitia te nui o te pene mā te [SIZE]",
    "pen.clear": "Kōmurutia te katoa",
    "pen.colorMenu.brightness": "pīataata",
    "pen.colorMenu.color": "tae",
    "pen.colorMenu.saturation": "waiwai",
    "pen.colorMenu.transparency": "pūatatata",
    "pen.penDown": "pene ki raro",
    "pen.penUp": "pene ki runga",
    "pen.setColor": "tautuhia te tae o te pene kia [COLOR]",
    "pen.setColorParam": "tautuhia te [COLOR_PARAM] o te pene kia [VALUE]",
    "pen.setHue": "tautuhia te tae o te pene kia [HUE]",
    "pen.setShade": "tautuhia te kauruku o te pene kia [SHADE]",
    "pen.setSize": "tautuhia te nui o te pene kia [SIZE]",
    "pen.stamp": "waitohu",
    "music.changeTempo": "panonitia te tere mā te [TEMPO]",
    "music.drumBass": "(2) Pahū Nguru",
    "music.drumBongo": "(13) Pahū tamarua",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Poro rākau",
    "music.drumClosedHiHat": "(6) Hi-Hat kua Katia",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Pere-kau",
    "music.drumCrashCymbal": "(4) Tīwēwē Wheoro",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Pakipaki-ā-ringa",
    "music.drumOpenHiHat": "(5) Hi-Hat kua Huakina",
    "music.drumSideStick": "(3) Rākau Taha Pahū",
    "music.drumSnare": "(1) Pahū Rarā",
    "music.drumTambourine": "(7) Tatangi",
    "music.drumTriangle": "(12) Tapatoru",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Paraka Rākau",
    "music.getTempo": "tere",
    "music.instrumentBass": "(6) Reo Nguru",
    "music.instrumentBassoon": "(14) Pūhoru",
    "music.instrumentCello": "(8) Whiranui",
    "music.instrumentChoir": "(15) Tira Waiata",
    "music.instrumentClarinet": "(10) Rehu Matangi",
    "music.instrumentElectricGuitar": "(5) Kitā Hiko",
    "music.instrumentElectricPiano": "(2) Piana Hiko",
    "music.instrumentFlute": "(12) Pūtōrino",
    "music.instrumentGuitar": "(4) Kitā",
    "music.instrumentMarimba": "(19) Pakakau",
    "music.instrumentMusicBox": "(17) Pouaka Puoro",
    "music.instrumentOrgan": "(3) Ōkena",
    "music.instrumentPiano": "(1) Piana",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Pūtohe",
    "music.instrumentSteelDrum": "(18) Pahū Rino",
    "music.instrumentSynthLead": "(20) Kōtui Arataki",
    "music.instrumentSynthPad": "(21) Kōtui Papatū",
    "music.instrumentTrombone": "(9) Pūkumekume",
    "music.instrumentVibraphone": "(16) Vibraphone",
    "music.instrumentWoodenFlute": "(13) Pūtorino",
    "music.midiPlayDrumForBeats": "whakatangihia te pahū [DRUM] mō ngā taki [BEATS] ",
    "music.midiSetInstrument": "tautuhia te taonga puoro ki te [INSTRUMENT]",
    "music.playDrumForBeats": "whakatangihia te pahū [DRUM] mō ngā taki [BEATS] ",
    "music.playNoteForBeats": "whakatangihia te oro [NOTE] mō ngā taki [BEATS]",
    "music.restForBeats": "tatari mō ngā taki [BEATS]",
    "music.setInstrument": "tautuhia te taonga puoro ki te [INSTRUMENT]",
    "music.setTempo": "tautuhia te tere kia [TEMPO]",
    "videoSensing.categoryName": "He Tairongo Ataata",
    "videoSensing.direction": "ahunga",
    "videoSensing.motion": "nekenekehanga",
    "videoSensing.off": "weto",
    "videoSensing.on": "kā",
    "videoSensing.onFlipped": "ina whakawhitia",
    "videoSensing.setVideoTransparency": "tautuhia te pūatatata o te ataata kia [TRANSPARENCY]",
    "videoSensing.sprite": "parehe",
    "videoSensing.stage": "atamira",
    "videoSensing.videoOn": "ataata [ATTRIBUTE] kei [SUBJECT]",
    "videoSensing.videoToggle": "hurihia te ataata kia [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "ina te nekenekehanga o te ataata > [REFERENCE]",
    "translate.translateBlock": "whakawhitiwhitia [WORDS] ki te reo [LANGUAGE]",
    "translate.defaultTextToTranslate": "kia ora",
    "translate.viewerLanguage": "reo",
    "text2speech.speakAndWaitBlock": "kīia [WORDS]",
    "text2speech.setVoiceBlock": "tautuhia te reo ki [VOICE]",
    "text2speech.setLanguageBlock": "tautuhia te reo kia[LANGUAGE]",
    "text2speech.alto": "reo pekerangi",
    "text2speech.tenor": "reo iere",
    "text2speech.squeak": "koekoe",
    "text2speech.giant": "kākarepō",
    "text2speech.kitten": "punua ngeru",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "ina rongo ahau i [PHRASE]",
    "speech.listenAndWait": "whakarongo, tatari hoki",
    "speech.defaultWhenIHearValue": "me haere tātou",
    "paint.paintEditor.hue": "Tae",
    "paint.paintEditor.saturation": "Waiwai",
    "paint.paintEditor.brightness": "Pīataata",
    "gui.comingSoon.message1": "Kaua e āwangawanga, ka ekengia e mātou {emoji}",
    "gui.comingSoon.message2": "Ākuanei kei konei...",
    "gui.comingSoon.message3": "Kei te ngana mātou ki te whakaoti {emoji}",
    "paint.paintEditor.fill": "Whakakīia",
    "paint.paintEditor.costume": "Kākahu",
    "paint.paintEditor.group": "Whakarōpūngia",
    "paint.paintEditor.ungroup": "Wehea",
    "paint.paintEditor.undo": "Wetekia",
    "paint.paintEditor.redo": "Mahia anōtia",
    "paint.paintEditor.forward": "Ki mua",
    "paint.paintEditor.backward": "Ki muri",
    "paint.paintEditor.front": "Mua",
    "paint.paintEditor.back": "Hoki atu",
    "paint.paintEditor.more": "Anō",
    "paint.modeTools.brushSize": "Rahi",
    "paint.modeTools.eraserSize": "Te rahi kōmuru",
    "paint.modeTools.copy": "Tāruatia",
    "paint.modeTools.paste": "Whakapiri",
    "paint.modeTools.delete": "Mukua",
    "paint.modeTools.curved": "Ānau",
    "paint.modeTools.pointed": "Koi",
    "paint.modeTools.thickness": "Mātotoru",
    "paint.modeTools.flipHorizontal": "Pore whakapae",
    "paint.modeTools.flipVertical": "Pore poutū",
    "paint.modeTools.filled": "Kua kī",
    "paint.modeTools.outlined": "Kua whakahuahuatia",
    "paint.paintEditor.bitmap": "Whakawhitia hei Maheremoka",
    "paint.paintEditor.vector": "Whakawhitia hei Pere",
    "paint.paintEditor.stroke": "Whakahuahua",
    "paint.brushMode.brush": "Paraihe",
    "paint.eraserMode.eraser": "Kōmuru",
    "paint.fillMode.fill": "Whakakīia",
    "paint.lineMode.line": "Rārangi",
    "paint.ovalMode.oval": "Porowhita",
    "paint.rectMode.rect": "Tapawhā Roa",
    "paint.reshapeMode.reshape": "Tāraia anōtia",
    "paint.roundedRectMode.roundedRect": "Tapawhā Roa Tōpuku",
    "paint.selectMode.select": "Kōwhiria",
    "paint.textMode.text": "Kuputuhi",
    "paint.colorPicker.swap": "Whakawhitia",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "nl": {
    "gui.backpack.header": "Rugzak",
    "gui.backpack.errorBackpack": "Fout bij laden van de rugzak",
    "gui.backpack.loadingBackpack": "Laden ...",
    "gui.backpack.more": "Meer",
    "gui.backpack.emptyBackpack": "Rugzak is leeg",
    "gui.gui.costumesTab": "Uiterlijken",
    "gui.gui.soundsTab": "Geluiden",
    "gui.gui.backdropsTab": "Achtergronden",
    "gui.gui.addExtension": "Voeg een uitbreiding toe",
    "gui.costumeTab.addCostumeFromLibrary": "Kies een uiterlijk",
    "gui.costumeLibrary.chooseACostume": "Kies een uiterlijk",
    "gui.costumeTab.addBackdropFromLibrary": "Kies een achtergrond",
    "gui.costumeTab.addBlankCostume": "Tekenen",
    "gui.costumeTab.addSurpriseCostume": "Verrassing",
    "gui.costumeTab.addFileBackdrop": "Upload achtergrond",
    "gui.costumeTab.addFileCostume": "Upload uiterlijk",
    "gui.costumeTab.addCameraCostume": "Camera",
    "gui.soundEditor.trim": "Knippen",
    "gui.soundEditor.stop": "Stop",
    "gui.soundEditor.sound": "Geluid",
    "gui.soundEditor.play": "Spelen",
    "gui.soundEditor.save": "Opslaan",
    "gui.soundEditor.undo": "Ongedaan maken",
    "gui.soundEditor.redo": "Opnieuw",
    "gui.soundEditor.faster": "Sneller",
    "gui.soundEditor.slower": "Trager",
    "gui.soundEditor.echo": "Echo",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Luider",
    "gui.soundEditor.softer": "Zachter",
    "gui.soundEditor.reverse": "Keer om",
    "gui.soundTab.recordSound": "Opnemen",
    "gui.soundTab.addSoundFromLibrary": "Kies een geluid",
    "gui.soundTab.surpriseSound": "Verrassing",
    "gui.soundTab.fileUploadSound": "Upload geluid",
    "gui.controls.stop": "Stop",
    "gui.controls.go": "Ga",
    "gui.stageHeader.stageSizeUnFull": "Verlaat full screen mode",
    "gui.SpriteInfo.show": "Toon",
    "gui.SpriteInfo.size": "Grootte",
    "gui.directionPicker.rotationStyles.allAround": "Helemaal meedraaien",
    "gui.directionPicker.rotationStyles.leftRight": "Links/rechts",
    "gui.directionPicker.rotationStyles.dontRotate": "Niet meedraaien",
    "gui.spriteSelectorItem.contextMenuDuplicate": "dupliceren",
    "gui.spriteSelectorItem.contextMenuDelete": "verwijderen",
    "gui.spriteSelectorItem.contextMenuExport": "exporteren",
    "gui.spriteSelector.addSpriteFromPaint": "Teken",
    "gui.spriteSelector.addSpriteFromSurprise": "Verrassing",
    "gui.spriteSelector.addSpriteFromFile": "Upload sprite",
    "gui.stageSelector.stage": "Speelveld",
    "gui.stageSelector.backdrops": "Achtergronden",
    "gui.stageSelector.addBackdropFromPaint": "Tekenen",
    "gui.stageSelector.addBackdropFromSurprise": "Verrassing",
    "gui.stageSelector.addBackdropFromFile": "Upload achtergrond",
    "gui.modal.back": "Terug",
    "gui.library.filterPlaceholder": "Zoek",
    "gui.libraryTags.all": "Alles",
    "gui.libraryTags.animals": "Dieren",
    "gui.libraryTags.dance": "Dansen",
    "gui.libraryTags.effects": "Effecten",
    "gui.libraryTags.fantasy": "Fantasie",
    "gui.libraryTags.fashion": "Mode",
    "gui.libraryTags.food": "Voedsel",
    "gui.libraryTags.indoors": "Binnen",
    "gui.libraryTags.loops": "Lussen",
    "gui.libraryTags.music": "Muziek",
    "gui.libraryTags.notes": "Noten",
    "gui.libraryTags.outdoors": "Buiten",
    "gui.libraryTags.patterns": "Patronen",
    "gui.libraryTags.people": "Mensen",
    "gui.libraryTags.percussion": "Slagwerk",
    "gui.libraryTags.space": "Ruimte",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Onder water",
    "gui.libraryTags.voice": "Stem",
    "gui.libraryTags.wacky": "Gek",
    "gui.libraryTags.animation": "Animatie",
    "gui.libraryTags.art": "Kunst",
    "gui.libraryTags.games": "Spelletjes",
    "gui.libraryTags.stories": "Verhalen",
    "gui.libraryTags.letters": "Letters",
    "gui.soundLibrary.chooseASound": "Kies een geluid",
    "gui.SpriteInfo.spritePlaceholder": "Naam",
    "gui.cards.more-things-to-try": "Meer dingen om te proberen!",
    "gui.cards.see-more": "Bekijk meer",
    "gui.cards.shrink": "Verklein",
    "gui.cards.expand": "Vergroot",
    "gui.cards.close": "Sluiten",
    "gui.loader.message1": "Blokken maken ...",
    "gui.loader.message2": "Bezig sprites te laden ...",
    "gui.loader.message3": "Bezig geluiden te laden ...",
    "gui.loader.message4": "Uitbreiding laden ...",
    "gui.loader.message5": "Katten verzamelen ...",
    "gui.loader.message6": "Nano's doorgeven",
    "gui.loader.message7": "Gobo's opblazen ...",
    "gui.loader.message8": "Emojis voorbereiden …",
    "gui.loader.headline": "Project laden...",
    "gui.cameraModal.cameraModalTitle": "Neem een foto",
    "gui.cameraModal.loadingCameraMessage": "Camera laden...",
    "gui.cameraModal.permissionRequest": "We hebben jouw toestemming nodig om jouw camera te gebruiken",
    "gui.cameraModal.retakePhoto": "Foto opnieuw nemen",
    "gui.cameraModal.save": "Bewaar",
    "gui.cameraModal.takePhoto": "Foto nemen",
    "gui.cameraModal.loadingCaption": "Laden ...",
    "gui.cameraModal.enableCameraCaption": "Camera inschakelen",
    "gui.recordModal.title": "Geluid opnemen",
    "gui.playbackStep.stopMsg": "Stop",
    "gui.playbackStep.playMsg": "Spelen",
    "gui.playbackStep.loadingMsg": "Laden ...",
    "gui.playbackStep.saveMsg": "Bewaar",
    "gui.playbackStep.reRecordMsg": "Opnieuw opnemen",
    "gui.webglModal.label": "WebGL wordt niet door jouw browser ondersteund",
    "gui.webglModal.webgllink": "ondersteunt geen WebGL",
    "gui.prompt.cancel": " Annuleren",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Voeg een invoer toe",
    "gui.customProcedures.addAnInputBoolean": "Voeg een invoer toe",
    "gui.customProcedures.numberTextType": "getal of tekst",
    "gui.customProcedures.booleanType": "booleaan",
    "gui.customProcedures.addALabel": "Voeg een label toe",
    "gui.customProcedures.runWithoutScreenRefresh": "Voer uit zonder het scherm te verversen",
    "gui.customProcedures.cancel": " Annuleren",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Verbind je projecten met de wereld.",
    "gui.extension.music.name": "Muziek",
    "gui.extension.music.description": "Speel instrumenten en drums.",
    "gui.extension.pen.name": "Pen",
    "gui.extension.pen.description": "Teken met je sprites.",
    "gui.gui.variableScopeOptionAllSprites": "Voor alle sprites",
    "gui.gui.variableScopeOptionSpriteOnly": "Alleen voor deze sprite",
    "gui.gui.variablePromptAllSpritesMessage": "Deze variabele is beschikbaar in alle sprites.",
    "gui.monitor.contextMenu.default": "normaal uitlezen",
    "gui.monitor.contextMenu.large": "groot uitlezen",
    "gui.monitor.contextMenu.slider": "schuif",
    "gui.monitor.contextMenu.import": "importeren",
    "gui.monitor.contextMenu.export": "exporteren",
    "gui.opcodeLabels.direction": "richting",
    "gui.opcodeLabels.xposition": "x-positie",
    "gui.opcodeLabels.yposition": "y-positie",
    "gui.opcodeLabels.size": "grootte",
    "gui.opcodeLabels.costumename": "uiterlijk naam",
    "gui.opcodeLabels.costumenumber": "uiterlijk nummer",
    "gui.opcodeLabels.backdropname": "achtergrond naam",
    "gui.opcodeLabels.backdropnumber": "achtergrond nummer",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "antwoord",
    "gui.opcodeLabels.loudness": "volume",
    "gui.opcodeLabels.year": "jaar",
    "gui.opcodeLabels.month": "maand",
    "gui.opcodeLabels.date": "datum",
    "gui.opcodeLabels.dayofweek": "dag van de week",
    "gui.opcodeLabels.hour": "uur",
    "gui.opcodeLabels.minute": "minuut",
    "gui.opcodeLabels.second": "seconde",
    "gui.opcodeLabels.timer": "klok",
    "music.categoryName": "Muziek",
    "translate.categoryName": "Vertaal",
    "pen.categoryName": "Pen",
    "pen.changeColorParam": "verander pen [COLOR_PARAM]met [VALUE]",
    "pen.changeHue": "verander penkleur met [HUE]",
    "pen.changeShade": "verander penhelderheid met [SHADE]",
    "pen.changeSize": "verander pendikte met [SIZE]",
    "pen.clear": "wis alles",
    "pen.colorMenu.brightness": "helderheid",
    "pen.colorMenu.color": "kleur",
    "pen.colorMenu.saturation": "verzadiging",
    "pen.colorMenu.transparency": "doorzichtigheid",
    "pen.penDown": "pen neer",
    "pen.penUp": "pen op",
    "pen.setColor": "maak penkleur [COLOR]",
    "pen.setColorParam": "maak pen [COLOR_PARAM] [VALUE]",
    "pen.setHue": "maak penkleur [HUE]",
    "pen.setShade": "maak penhelderheid [SHADE]",
    "pen.setSize": "maak pendikte [SIZE]",
    "pen.stamp": "stempel",
    "music.changeTempo": "verander tempo met [TEMPO]",
    "music.drumBass": "(2) Basdrum",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Kabassa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Gesloten Hihat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Koebel",
    "music.drumCrashCymbal": "(4) Crashbekken",
    "music.drumCuica": "(18) Cuíca",
    "music.drumGuiro": "(16) Güiro",
    "music.drumHandClap": "(8) Handklap",
    "music.drumOpenHiHat": "(5) Open Hihat",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Snarentrom",
    "music.drumTambourine": "(7) Tamboerijn",
    "music.drumTriangle": "(12) Triangel",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Wood Block",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bas",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Koor",
    "music.instrumentClarinet": "(10) Klarinet",
    "music.instrumentElectricGuitar": "(5) Elektrische Gitaar",
    "music.instrumentElectricPiano": "(2) Elektrische Piano",
    "music.instrumentFlute": "(12) Fluit",
    "music.instrumentGuitar": "(4) Gitaar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Speeldoos",
    "music.instrumentOrgan": "(3) Orgel",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxofoon",
    "music.instrumentSteelDrum": "(18) Steeldrum",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibrafoon",
    "music.instrumentWoodenFlute": "(13) Blokfluit",
    "music.midiPlayDrumForBeats": "speel drum [DRUM]gedurende[BEATS] maten",
    "music.midiSetInstrument": "maak instrument [INSTRUMENT]",
    "music.playDrumForBeats": "speel drum [DRUM]gedurende[BEATS] maten",
    "music.playNoteForBeats": "speel noot [NOTE] gedurende [BEATS]maten",
    "music.restForBeats": "[BEATS]maten rust",
    "music.setInstrument": "maak instrument [INSTRUMENT]",
    "music.setTempo": "maak tempo [TEMPO]",
    "videoSensing.categoryName": "Video",
    "videoSensing.direction": "richting",
    "videoSensing.motion": "beweging",
    "videoSensing.off": "uit",
    "videoSensing.on": "aan",
    "videoSensing.onFlipped": "aan gespiegeld",
    "videoSensing.setVideoTransparency": "zet video transparantie op [TRANSPARENCY]",
    "videoSensing.sprite": "sprite",
    "videoSensing.stage": "speelveld",
    "videoSensing.videoOn": "video [ATTRIBUTE]van[SUBJECT]",
    "videoSensing.videoToggle": "zet video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "wanneer video beweging > [REFERENCE]",
    "translate.translateBlock": "vertaal [WORDS] naar [LANGUAGE]",
    "translate.defaultTextToTranslate": "hallo",
    "translate.viewerLanguage": "taal",
    "text2speech.speakAndWaitBlock": "zeg [WORDS]",
    "text2speech.setVoiceBlock": "zet stem op [VOICE]",
    "text2speech.setLanguageBlock": "stel de taal in op [LANGUAGE]",
    "text2speech.alto": "alt",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "piep",
    "text2speech.giant": "reus",
    "text2speech.kitten": "katje",
    "text2speech.defaultTextToSpeak": "hallo",
    "speech.whenIHear": "als ik [PHRASE]hoor",
    "speech.listenAndWait": "luister en wacht",
    "speech.defaultWhenIHearValue": "daar gaan we",
    "paint.paintEditor.hue": "Kleur",
    "paint.paintEditor.saturation": "Verzadiging",
    "paint.paintEditor.brightness": "Helderheid",
    "gui.comingSoon.message1": "Geen zorgen; we zijn er mee bezig {emoji}",
    "gui.comingSoon.message2": "Binnenkort beschikbaar...",
    "gui.comingSoon.message3": "We werken er aan {emoji}",
    "paint.paintEditor.fill": "Vulling",
    "paint.paintEditor.costume": "Uiterlijk",
    "paint.paintEditor.group": "Groeperen",
    "paint.paintEditor.ungroup": "Groep opheffen",
    "paint.paintEditor.undo": "Ongedaan maken",
    "paint.paintEditor.redo": "Opnieuw",
    "paint.paintEditor.forward": "Naar voren",
    "paint.paintEditor.backward": "Naar achteren",
    "paint.paintEditor.front": "Naar voorgrond",
    "paint.paintEditor.back": "Naar achtergrond",
    "paint.paintEditor.more": "Meer",
    "paint.modeTools.brushSize": "Grootte",
    "paint.modeTools.eraserSize": "Gum-breedte",
    "paint.modeTools.copy": "Kopie maken ",
    "paint.modeTools.paste": "Plakken",
    "paint.modeTools.delete": "Verwijder",
    "paint.modeTools.curved": "Gebogen",
    "paint.modeTools.pointed": "Puntig",
    "paint.modeTools.thickness": "Dikte",
    "paint.modeTools.flipHorizontal": "Links-rechts omdraaien",
    "paint.modeTools.flipVertical": "Boven-onder omdraaien",
    "paint.modeTools.filled": "Gevuld",
    "paint.modeTools.outlined": "Omtrek",
    "paint.paintEditor.bitmap": "Zet om naar bitmap",
    "paint.paintEditor.vector": "Zet om naar vector",
    "paint.paintEditor.stroke": "Omtrek",
    "paint.brushMode.brush": "Kwast",
    "paint.eraserMode.eraser": "Gum",
    "paint.fillMode.fill": "Vulling",
    "paint.lineMode.line": "Lijn",
    "paint.ovalMode.oval": "Cirkel",
    "paint.rectMode.rect": "Rechthoek",
    "paint.reshapeMode.reshape": "Opnieuw vormen",
    "paint.roundedRectMode.roundedRect": "Afgeronde rechthoek",
    "paint.selectMode.select": "Selecteren",
    "paint.textMode.text": "Tekst",
    "paint.colorPicker.swap": "Wissel",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "ja": {
    "gui.gui.project": "プロジェクト",
    "gui.gui.newItem": "新しいプロジェクト",
    "gui.gui.load": "プロジェクトを開く",
    "gui.gui.save": "プロジェクトを保存",
    "gui.gui.saveAs": "名前を付けて保存",
    "gui.gui.learn": "学習",
    "gui.gui.clearRecord": "履歴を削除",
    "gui.gui.onlineLearn": "オフィシャルドキュメント",
    "gui.gui.onlineForum": "オンラインフォーラム",
    "gui.gui.videoTutorial": "ビデオチュートリアル",
    "gui.gui.offlineLearn": "サンプルプログラム",
    "gui.menuBar.turboModeOff": "ターボモードを解除する",
    "gui.menuBar.turboModeOn": "ターボモードにする",
    "gui.menuBar.edit": "編集",
    "gui.menuBar.restoreSprite": "スプライトを復元",
    "gui.menuBar.restoreSound": "音を復元",
    "gui.menuBar.restoreCostume": "コスチュームを復元",
    "gui.menuBar.restoreBackdrop": "背景を復元",
    "gui.editorMind.restore": "元に戻す",
    "gui.turboMode.active": "ターボモード",
    "gui.gui.connectDevice": "デバイスを接続",
    "gui.gui.disconnectDevice": "デバイスを切断",
    "gui.gui.installSerialportDriver": "SerialPortドライバーをインストールする",
    "gui.gui.openDM": "デバイスマネージャーを開く",
    "gui.gui.restoreSetting": "デバイスを初期設定で復元",
    "gui.gui.updateTips": "更新情報",
    "gui.gui.newVersion": "最新バージョン",
    "gui.gui.downloadUpdate": "アップデートをダウンロードする",
    "gui.gui.versionUpdate": "バージョンを更新する",
    "gui.gui.isCheckMd5": "ファイルを確認しています",
    "gui.gui.downloading": "Checking Updator",
    "gui.gui.updateError": "更新エラー",
    "gui.setting.feedbackMenu": "フィードバック",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.setting.officialWebsit": "公式サイト",
    "gui.setting.officialQQGroup": "公式 QQ グループ",
    "gui.setting.wechatOfficialAccounts": "WeChat公式アカウント",
    "gui.setting.currentVersion": "現在のバージョン",
    "gui.setting.checkForUpdate": "更新チェック",
    "gui.setting.remindWhenUpdate": "更新を自動的にダウンロード",
    "gui.setting.currentIsLatest": "最新バージョンです",
    "gui.setting.latestVersion": "最新バージョン",
    "gui.setting.download": "ダウンロードページにジャンプ",
    "gui.setting.language": "言語",
    "gui.setting.uploadCsv": "xlsxをアップロード",
    "gui.setting.theme": "テーマ",
    "gui.setting.feedback": "コンタクト",
    "gui.setting.email": "E-mail",
    "gui.setting.opinionFeedback": "フィードバック",
    "gui.variableMc.variableName": "変数名",
    "gui.variableMc.variableOff": "キャンセル",
    "gui.variableMc.variableOn": "確認",
    "gui.variableMc.newVariable": "新しい変数",
    "gui.setting.helpUsTranslate": "翻訳にご協力ください",
    "gui.setting.checkUpdate": "更新チェック",
    "gui.setting.ok": "OK",
    "gui.setting.languageSetting": "言語",
    "gui.setting.themeSetting": "テーマ",
    "gui.setting.versionUpdate": "バージョンの更新",
    "gui.setting.connectUsTitle": "お問い合わせ",
    "gui.setting.uploadAttachment": "添付ファイル",
    "gui.setting.displayTitle": "表示設定",
    "gui.setting.fontSizeSetting": "フォントサイズ",
    "gui.setting.restartEffect": "再起動後に有効",
    "gui.setting.fontSizeLarge": "大",
    "gui.setting.fontSizeMiddle": "中",
    "gui.setting.fontSizeSmall": "小",
    "gui.setting.onlineRepair": "オンライン修復ツール、クリックして開きます>",
    "gui.backpack.header": "バックパック",
    "gui.backpack.errorBackpack": "バックパックの読み込み時にエラーが発生しました",
    "gui.backpack.loadingBackpack": "読み込み中...",
    "gui.backpack.more": "もっと",
    "gui.backpack.emptyBackpack": "バックパックは空です",
    "gui.gui.costumesTab": "コスチューム",
    "gui.gui.soundsTab": "音",
    "gui.gui.backdropsTab": "背景",
    "gui.gui.addExtension": "拡張機能を追加",
    "gui.costumeTab.addCostumeFromLibrary": "コスチュームを選ぶ",
    "gui.costumeLibrary.chooseACostume": "コスチュームを選ぶ",
    "gui.costumeTab.addBackdropFromLibrary": "背景を選ぶ",
    "gui.costumeTab.addBlankCostume": " 描く",
    "gui.costumeTab.addSurpriseCostume": "サプライズ",
    "gui.costumeTab.addFileBackdrop": "背景をアップロード",
    "gui.costumeTab.addFileCostume": "コスチュームをアップロード",
    "gui.costumeTab.addCameraCostume": "カメラ",
    "gui.soundEditor.trim": "カット",
    "gui.soundEditor.stop": "止める",
    "gui.soundEditor.sound": "音",
    "gui.soundEditor.play": "再生",
    "gui.soundEditor.save": "保存",
    "gui.soundEditor.undo": "元に戻す",
    "gui.soundEditor.redo": "やり直す",
    "gui.soundEditor.faster": "速く",
    "gui.soundEditor.slower": "遅く",
    "gui.soundEditor.echo": "エコー",
    "gui.soundEditor.robot": "ロボット",
    "gui.soundEditor.louder": "大きく",
    "gui.soundEditor.softer": "小さく",
    "gui.soundEditor.reverse": "逆向き",
    "gui.soundTab.recordSound": "録音する",
    "gui.soundTab.addSoundFromLibrary": " 音を選ぶ",
    "gui.soundTab.surpriseSound": "サプライズ",
    "gui.soundTab.fileUploadSound": "音をアップロードする",
    "gui.controls.stop": "止める",
    "gui.controls.go": "実行",
    "gui.stageHeader.stageSizeUnFull": "全画面表示をやめる",
    "gui.SpriteInfo.show": "表示する",
    "gui.SpriteInfo.size": "大きさ",
    "gui.directionPicker.rotationStyles.allAround": "自由に回転",
    "gui.directionPicker.rotationStyles.leftRight": "左右のみ",
    "gui.directionPicker.rotationStyles.dontRotate": "回転しない",
    "gui.spriteSelectorItem.contextMenuDuplicate": "複製",
    "gui.spriteSelectorItem.contextMenuDelete": "削除",
    "gui.spriteSelectorItem.contextMenuExport": "書き出し",
    "gui.spriteSelector.addSpriteFromPaint": "描く",
    "gui.spriteSelector.addSpriteFromSurprise": "サプライズ",
    "gui.spriteSelector.addSpriteFromFile": "スプライトをアップロード",
    "gui.stageSelector.stage": "ステージ",
    "gui.stageSelector.backdrops": "背景",
    "gui.stageSelector.addBackdropFromPaint": "描く",
    "gui.stageSelector.addBackdropFromSurprise": "サプライズ",
    "gui.stageSelector.addBackdropFromFile": "背景をアップロード",
    "gui.modal.back": "戻る",
    "gui.library.filterPlaceholder": "検索",
    "gui.libraryTags.all": "すべて",
    "gui.libraryTags.animals": "動物",
    "gui.libraryTags.dance": "ダンス",
    "gui.libraryTags.effects": "効果",
    "gui.libraryTags.fantasy": "ファンタジー",
    "gui.libraryTags.fashion": "ファッション",
    "gui.libraryTags.food": "食べ物",
    "gui.libraryTags.indoors": "屋内",
    "gui.libraryTags.loops": "ループ",
    "gui.libraryTags.music": "音楽",
    "gui.libraryTags.notes": "音符",
    "gui.libraryTags.outdoors": "屋外",
    "gui.libraryTags.patterns": "模様",
    "gui.libraryTags.people": "人",
    "gui.libraryTags.percussion": "打楽器",
    "gui.libraryTags.space": "宇宙",
    "gui.libraryTags.sports": "スポーツ",
    "gui.libraryTags.underwater": "海中",
    "gui.libraryTags.voice": "声",
    "gui.libraryTags.wacky": "奇妙な音",
    "gui.libraryTags.animation": "アニメーション",
    "gui.libraryTags.art": "アート",
    "gui.libraryTags.games": "ゲーム",
    "gui.libraryTags.stories": "物語",
    "gui.libraryTags.letters": "文字",
    "gui.soundLibrary.chooseASound": "音を選ぶ",
    "gui.SpriteInfo.spritePlaceholder": "名前",
    "gui.cards.more-things-to-try": "これも試してみよう！",
    "gui.cards.see-more": "もっと見る",
    "gui.cards.shrink": "縮小",
    "gui.cards.expand": "拡大",
    "gui.cards.close": "閉じる",
    "gui.loader.message1": "ブロックを作成中…",
    "gui.loader.message2": "スプライトを読み込み中…",
    "gui.loader.message3": "音を読み込み中…",
    "gui.loader.message4": "拡張機能を読み込み中…",
    "gui.loader.message5": "猫の群れを集めています…",
    "gui.loader.message6": "Nanoを送信中…",
    "gui.loader.message7": "Goboを膨らませています…",
    "gui.loader.message8": "絵文字を準備中…",
    "gui.loader.headline": "プロジェクトを読み込み中…",
    "gui.cameraModal.cameraModalTitle": "写真を撮る",
    "gui.cameraModal.loadingCameraMessage": "カメラを読み込み中...",
    "gui.cameraModal.permissionRequest": "カメラを使う許可が必要です",
    "gui.cameraModal.retakePhoto": "写真を撮り直す",
    "gui.cameraModal.save": "保存",
    "gui.cameraModal.takePhoto": "写真を撮る",
    "gui.cameraModal.loadingCaption": "読み込み中...",
    "gui.cameraModal.enableCameraCaption": "カメラを有効にする",
    "gui.recordModal.title": "録音",
    "gui.playbackStep.stopMsg": "停止",
    "gui.playbackStep.playMsg": "再生",
    "gui.playbackStep.loadingMsg": "読み込み中...",
    "gui.playbackStep.saveMsg": "保存",
    "gui.playbackStep.reRecordMsg": "再録音",
    "gui.webglModal.label": "ブラウザーはWebGLをサポートしていないようです",
    "gui.webglModal.webgllink": "WebGLをサポートしていない",
    "gui.prompt.cancel": "キャンセル",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "引数を追加",
    "gui.customProcedures.addAnInputBoolean": "引数を追加",
    "gui.customProcedures.numberTextType": "数値またはテキスト",
    "gui.customProcedures.booleanType": "真偽値",
    "gui.customProcedures.addALabel": "ラベルのテキストを追加",
    "gui.customProcedures.runWithoutScreenRefresh": "画面を再描画せずに実行する",
    "gui.customProcedures.cancel": "キャンセル",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "プロジェクトを現実の世界と接続する。",
    "gui.extension.music.name": "音楽",
    "gui.extension.music.description": "楽器やドラムを演奏する。",
    "gui.extension.pen.name": "ペン",
    "gui.extension.pen.description": "スプライトで絵を描く。",
    "gui.gui.variableScopeOptionAllSprites": "すべてのスプライト用",
    "gui.gui.variableScopeOptionSpriteOnly": "このスプライトのみ",
    "gui.gui.variablePromptAllSpritesMessage": "この変数はすべてのスプライトで利用できます。",
    "gui.monitor.contextMenu.default": "普通の表示",
    "gui.monitor.contextMenu.large": "大きな表示",
    "gui.monitor.contextMenu.slider": "スライダー",
    "gui.monitor.contextMenu.import": "読み込み",
    "gui.monitor.contextMenu.export": "書き出し",
    "gui.opcodeLabels.direction": "向き",
    "gui.opcodeLabels.xposition": "x座標",
    "gui.opcodeLabels.yposition": "y座標",
    "gui.opcodeLabels.size": "大きさ",
    "gui.opcodeLabels.costumename": "コスチューム名",
    "gui.opcodeLabels.costumenumber": "コスチュームの番号",
    "gui.opcodeLabels.backdropname": "背景の名前",
    "gui.opcodeLabels.backdropnumber": "背景の番号",
    "gui.opcodeLabels.volume": "音量",
    "gui.opcodeLabels.tempo": "テンポ",
    "gui.opcodeLabels.answer": "答え",
    "gui.opcodeLabels.loudness": "音量",
    "gui.opcodeLabels.year": "年",
    "gui.opcodeLabels.month": "月",
    "gui.opcodeLabels.date": "日",
    "gui.opcodeLabels.dayofweek": "曜日",
    "gui.opcodeLabels.hour": "時",
    "gui.opcodeLabels.minute": "分",
    "gui.opcodeLabels.second": "秒",
    "gui.opcodeLabels.timer": "タイマー",
    "gui.gui.blocks": "コード",
    "gui.soundTab.addSound": "サウンドを追加",
    "gui.gui.startPython": "Pythonを実行",
    "gui.gui.stopPython": "Pythonを停止",
    "gui.controls.fullScreenControl": "Full Screen Control",
    "gui.gui.stageSizeLarge": "ラージステージモード",
    "gui.gui.stageSizeSmall": "スモールステージモード",
    "gui.gui.stageSizeNostage": "ステージなしモード",
    "gui.gui.stageSizeFull": "全画面表示",
    "gui.sprite.sprite": "スプライト",
    "gui.sprite.direction": "向き",
    "gui.sprite.rotation": "角度",
    "gui.sprite.addSpriteFromLibrary": "スプライトを選ぶ",
    "gui.sprite.addSpriteFromCamera": "カメラ",
    "gui.stage.addBackdropFromLibrary": "背景を選ぶ",
    "gui.stage.addBackdropFromCamera": "カメラ",
    "gui.modal.help": "ヘルプ",
    "gui.extension.kit": "キット",
    "gui.extension.kitTitle": "キットを選ぶ",
    "gui.extension.board": "ボード",
    "gui.extension.boardTitle": "ボードを選ぶ",
    "gui.extension.spreadBoard": "シールド",
    "gui.extension.spreadBoardTitle": "シールドを選ぶ",
    "gui.extension.sensor": "センサー",
    "gui.extension.sensorTitle": "センサーを選ぶ",
    "gui.extension.actuator": "アクチュエーター",
    "gui.extension.actuatorTitle": "アクチュエーターを選ぶ",
    "gui.extension.communicationModule": "通信装置",
    "gui.extension.communicationModuleTitle": "通信装置を選ぶ",
    "gui.extension.display": "ディスプレイ",
    "gui.extension.displayTitle": "ディスプレイを選ぶ",
    "gui.extension.function": "機能モジュール",
    "gui.extension.functionTitle": "機能モジュールを選ぶ",
    "gui.extension.internetService": "ネットサービス",
    "gui.extension.internetServiceTitle": "ネットサービスを選ぶ",
    "gui.extension.thirdExpansion": "ユーザーライブラリ",
    "gui.extension.thirdExpansionTitle": "ユーザーライブラリを選ぶ",
    "gui.extension.arduinContentLabel": "デバイスを選択",
    "gui.extension.backdropLib": "背景を選ぶ",
    "gui.extension.spriteLib": "スプライトを選ぶ",
    "gui.gui.unselectedSerialport": "デバイスが接続されていません",
    "gui.gui.unConnectedDev": "デバイスが接続されていません",
    "gui.gui.pythonMode": "Pythonモード",
    "gui.gui.burnFirmware": "ファームウェアの再書き込み",
    "gui.gui.burnFirmwareError": "ファームウェア書き込みエラー",
    "gui.gui.connected": "接続成功",
    "gui.gui.failedConnected": "接続失敗",
    "gui.gui.connecting": "デバイスを接続しています...",
    "gui.cards.all-how-tos": "すべてのチュートリアル",
    "gui.cards.how-tos": "チュートリアル",
    "gui.cards.remove": "チュートリアルを終了",
    "gui.recordStep.recordByClickBtn": "下のボタンを押して録音する",
    "gui.recordStep.permissionMicrophone": "マイクを使用するには許可が必要です",
    "gui.recordStep.record": "録音する",
    "gui.recordStep.stopRecord": "録音をやめる",
    "gui.webglModal.descriptionMind": "残念ながら、お使いのコンピュータは{webGlLink}のようです。このテクノロジーは、Mind +を実行するために必要です。 {updateGpuDriver}",
    "gui.webglModal.updateGpuDriver": "グラフィックカードドライバを更新してみてください",
    "gui.webglModal.ok": "OK",
    "gui.extension.scratchExampleLib": "サンプルプログラム-Scratch",
    "gui.extension.pythonExampleLib": "サンプルプログラム-Python",
    "gui.extension.arduinoExampleLib": "サンプルプログラム-Arduino",
    "gui.extension.makeBlock": "ブロックを作る",
    "gui.customProcedures.textType": "テキスト",
    "gui.customProcedures.numberType": "数値",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "DFRobotの教育用ロボットVortex",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "DFRobotの教育用ロボットExplorer D1",
    "gui.extension.arduinounoR3.name": "Arduino Uno R3",
    "gui.extension.arduinounoR3.description": "武漢メーカーのカリキュラムとArduino Unoマザーボードを組み合わせたキットです。",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "micro:bitプラットフォームに基づくロボットカー",
    "gui.extension.max.name": "MAX",
    "gui.extension.max.description": "Arduinoプラットフォームに基づくロボットカー",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "モーター＆サーボ拡張ボード",
    "gui.extension.maqueen.name": "Maqueen",
    "gui.extension.maqueen.description": "micro:bitプラットフォームに基づくロボットカー",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.spread.name": "mpython extension",
    "gui.extension.microTouch.name": "TouchKey",
    "gui.extension.microNaturalScience.name": "micro:naturalScience",
    "gui.extension.microTouch.description": "統合された4×4キーボードおよびモーターサーボドライブ拡張ボード",
    "gui.extension.microNaturalScience.description": "複数の環境センサーを備えたMicro:bit拡張ボード",
    "gui.extension.microIoT.name": "micro:IoT",
    "gui.extension.microIoT.description": "統合されたディスプレイとIoT機能を備えたMicro:bit拡張ボード",
    "gui.extension.spreadMaqueen.name": "maqueen extension",
    "gui.extension.spread.description": "micro:bitおよび制御ボードを使用したI/O拡張ボード（モータードライブ付き）",
    "gui.extension.spreadmaqueen.description": "コントロールパネルを備えたエントリーレベルのミニロボット車",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "micro:bitとmPythonの両方をサポートする高度な教育用ロボット",
    "gui.extension.iot.name": "Internet of Things",
    "gui.extension.iot.description": "IoTベースの拡張バージョン",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Leonardoによるデバイス制御",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Unoメインコントロールボードモジュール",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Arduino Nanoによるデバイス制御",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "ESP32に基づくメイン制御ボード",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Mega2560によるデバイス制御",
    "gui.extension.firebeetleesp32.name": "FireBeetle ESP32",
    "gui.extension.firebeetleesp32.description": "FireBeetle ESP32によるデバイス制御",
    "gui.extension.dfr0299.name": "DFPlayer MP3 Module",
    "gui.extension.dfr0299.description": "MP3プレイヤーモジュール",
    "gui.extension.ser0006.name": "Micro Servo",
    "gui.extension.ser0006.description": "マイクロサーボ（0から180度の指定された角度位置に回転できます。）",
    "gui.extension.dfr0523.name": "Peristaltic Pump",
    "gui.extension.dfr0523.description": "弾性ポンプチューブを蠕動（絞って離して）して液体を送ります",
    "gui.extension.dfr0017.name": "Relay Module",
    "gui.extension.dfr0017.description": "高電流デバイスを制御する",
    "gui.extension.steppermotor.name": "Stepper Motor",
    "gui.extension.steppermotor.description": "パルス制御で精密な位置制御を行います",
    "gui.extension.dfr0534.name": "Serial MP3 module",
    "gui.extension.dfr0534.description": "シリアルMP3音声モジュール",
    "gui.extension.servo360.name": "360° Micro Servo",
    "gui.extension.servo360.description": "回転方向、速度を制御することができますが、角度を制御することはできません。",
    "gui.extension.tel0118.name": "OBLOQ IoT Module ",
    "gui.extension.tel0118.description": "Wi-Fi経由でIoT情報を送受信する",
    "gui.extension.dfr0095.name": "IR Transmitter Module",
    "gui.extension.dfr0095.description": "38KHz標準赤外線信号を送信",
    "gui.extension.dfr0094.name": "IR Receiver Module",
    "gui.extension.dfr0094.description": "38KHz標準赤外線信号を受信",
    "gui.extension.midi.name": "MIDI",
    "gui.extension.midi.description": "MIDIプレイヤー",
    "gui.extension.radio.name": "Wireless broadcasting",
    "gui.extension.radio.description": "複数の制御ボード間の無線ブロードキャスト通信",
    "gui.extension.tel0094.name": "GPS signal receiving module",
    "gui.extension.tel0094.description": "GPS信号受信モジュール",
    "gui.extension.tel0026.name": "Bluetooth module",
    "gui.extension.tel0026.description": "Bluetoothシリアルポートモジュール",
    "gui.extension.dfr0486.name": "OLED-2864 Display",
    "gui.extension.dfr0486.description": "I2C OLED-2864ディスプレイモジュール",
    "gui.extension.fit0352.name": "WS2812 RGB LED Strip",
    "gui.extension.fit0352.description": "WS2812ベースのLEDストリップモジュール制御",
    "gui.extension.dfr0063.name": "LCD1602 Display",
    "gui.extension.dfr0063.description": "LCDディスプレイ（1行あたり16文字を2行表示可能）",
    "gui.extension.dfr0021.name": "Digital LED Light Module",
    "gui.extension.dfr0021.description": "白、赤、緑、青のLEDモジュール",
    "gui.extension.tm1650.name": "TM1650 Four digit tube",
    "gui.extension.tm1650.description": "4 桁デジタル表示ディスプレイ",
    "gui.extension.matrix8_8.name": "MAX7219 8x8 dot matrix",
    "gui.extension.matrix8_8.description": "8x8 LEDマトリクスモジュール",
    "gui.extension.dfr0522.name": "8x16 RGB LED Matrix Panel",
    "gui.extension.dfr0522.description": "画像、数字を表示...スクロール表示とユーザー定義設定をサポート",
    "gui.extension.video-sensing.name": "ビデオモーションセンサー",
    "gui.extension.video-sensing.description": "カメラで動きを検知する。",
    "gui.extension.google-translate.name": "翻訳",
    "gui.extension.google-translate.description": "色々な言語にテキストを翻訳する。",
    "gui.extension.text-speech.name": "音声合成",
    "gui.extension.text-speech.description": "言葉をしゃべるプロジェクトを作ろう。",
    "gui.extension.softSerialport.name": "Software Serial Port",
    "gui.extension.softSerialport.description": "I/Oポートを使用してシリアル通信機能をシミュレートする",
    "gui.extension.IICScan.name": "I2C Scan",
    "gui.extension.IICScan.description": "I2Cインターフェイスに現在接続されているすべてのデバイスアドレスをスキャンします",
    "gui.extension.bluetooth.name": "Bluetooth",
    "gui.extension.bluetooth.description": "Bluetoothデバイス",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "GoBLE APP スマートフォンを介してメインコントロールボードを制御するのに役立ちます",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "EEPROMでデータの読み書きに使用します",
    "gui.extension.interrupt.name": "Interrupt",
    "gui.extension.interrupt.description": "割り込みピン",
    "gui.extension.mpyfile.name": "file",
    "gui.extension.mpyfile.description": "コントロールパネルでのファイルの読み取りと書き込み、データの保存とエクスポート",
    "gui.extension.sdcard.name": "SD card reader module",
    "gui.extension.sdcard.description": "MicroSDカードリーダーモジュール",
    "gui.extension.multithread.name": "MultiThread",
    "gui.extension.multithread.description": "複数のプログラムとメインプログラムを同時に実行できるようにする",
    "gui.extension.speech.name": "Speech",
    "gui.extension.speech.description": "あなたのプロジェクトについて語ってください",
    "gui.extension.sen0001.name": "Ultrasonic Sensor",
    "gui.extension.sen0001.description": "2〜800cmの範囲で正確な距離検出",
    "gui.extension.dfr0023.name": "Analog LM35 Temperature Sensor",
    "gui.extension.dfr0023.description": "LM35ベースの半導体温度センサー、範囲0〜100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22 Temperature and Humidity Sensor",
    "gui.extension.dhtTHSensor.description": "環境の温度と湿度を検出する",
    "gui.extension.dsTSensor.name": "DS18B20 Temperature Sensor",
    "gui.extension.dsTSensor.description": "-55〜+ 125℃の広い範囲で周囲温度を検出します",
    "gui.extension.sen0203.name": "Heart Rate Monitor Sensor",
    "gui.extension.sen0203.description": "アナログパルスおよびデジタル方形波出力モードを備えたミニ心拍数センサー",
    "gui.extension.sen0177.name": "Laser PM2.5 Air Quality Sensor",
    "gui.extension.sen0177.description": "PM1、PM2.5、PM10を測定します。シリアルポートを介したデータの転送",
    "gui.extension.sen0014.name": "GP2Y0A21 IR Distance Sensor",
    "gui.extension.sen0014.description": "GP2Y0A21に基づくIRセンサー、測定範囲10〜80cm",
    "gui.extension.sen0204.name": "Non-contact Liquid Level Sensor",
    "gui.extension.sen0204.description": "液体レベルを検出しますが、接触はありません",
    "gui.extension.sen0160.name": "Hx711 weight sensor",
    "gui.extension.sen0160.description": "Hx711重量センサーを測定する",
    "gui.extension.sen0161.name": "Analog pH Meter",
    "gui.extension.sen0161.description": "5V電源で液体pH値を測定",
    "gui.extension.sen0161-v2.name": "Analog pH Meter(V2)",
    "gui.extension.sen0161-v2.description": "3.3〜5.5V電源で液体pH値を測定",
    "gui.extension.sen0244.name": "Analog TDS Sensor",
    "gui.extension.sen0244.description": "水の清浄度を示唆する液体のTDS（Total Dissolved Solids）を測定します",
    "gui.extension.sen0165.name": "Analog ORP Meter",
    "gui.extension.sen0165.description": "液体のORP（酸化還元電位）を測定して、水質をテストします",
    "gui.extension.sen0237.name": "Analog Dissolved Oxygen Sensor",
    "gui.extension.sen0237.description": "液体のDO（溶存酸素）を測定し、水質を評価します",
    "gui.extension.dfr0300-H.name": "Analog EC Meter",
    "gui.extension.dfr0300-H.description": "液体のEC（電気伝導度）を測定して水質を評価する",
    "gui.extension.dfr0300.name": "Analog EC Meter(V2)",
    "gui.extension.dfr0300.description": "液体のEC（電気伝導度）を測定して水質を評価する",
    "gui.extension.sen0170.name": "Anemometer",
    "gui.extension.sen0170.description": "風速レベルと出力電圧信号を測定",
    "gui.extension.sen0226.name": "BMP280 Temperature Sensor",
    "gui.extension.sen0226.description": "温度および気圧測定用のBMP280気圧センサー",
    "gui.extension.sen0228.name": "I2C VEML7700 Ambient Light Sensor",
    "gui.extension.sen0228.description": "高精度環境光デジタル16ビット解像度センサー",
    "gui.extension.sen0236.name": "BME280 module",
    "gui.extension.sen0236.description": "環境センサー（温度、湿度、気圧）",
    "gui.extension.dfr0022.name": "Analog Grayscale Sensor",
    "gui.extension.dfr0022.description": "光密度を検出し、アナログ電圧信号を反射します。ライントラッキングに適用できます。",
    "gui.extension.motucamera.name": "MU sensor board",
    "gui.extension.motucamera.description": "「MUセンサーボード」拡張の説明",
    "gui.extension.dfr0552.name": "12-bit DA conversion module",
    "gui.extension.dfr0552.description": "デジタル量を対応するDC電圧信号に正確に変換（非PWM）",
    "gui.extension.sen0117.name": "Speech synthesis module",
    "gui.extension.sen0117.description": "中国語と英語の文字と数字を入力するだけで読み上げられます",
    "gui.extension.dfr0576.name": "I2C cascade extender",
    "gui.extension.dfr0576.description": "I2Cデバイスアドレスの競合を解決するために使用されます",
    "gui.extension.sen0248.name": "BME680 environmental sensor",
    "gui.extension.sen0248.description": "VOC（揮発性有機化合物）、温度、湿度、気圧を測定できます",
    "gui.extension.sen0304.name": "I2C ultrasonic ranging sensor",
    "gui.extension.sen0304.description": "高速測距または障害物回避アプリケーション用に設計された超音波センサー",
    "gui.extension.sen0307.name": "Analog ultrasonic ranging sensor",
    "gui.extension.sen0307.description": "オープンデュアルプローブアナログ超音波測距モジュール",
    "gui.extension.sen0250.name": "BMI160 6 axis inertial motion sensor",
    "gui.extension.sen0250.description": "16ビット3軸加速度+超低消費電力3軸ジャイロスコープ",
    "gui.extension.sen0224.name": "I2C LIS2DH triaxial accelerometer",
    "gui.extension.sen0224.description": "超低消費電力3軸加速度センサー",
    "gui.extension.dfr0151.name": "Real Time Clock DS1307",
    "gui.extension.dfr0151.description": "時間誤差は24時間で約1秒です",
    "gui.extension.dfr0469.name": "Real Time Clock SD2405",
    "gui.extension.dfr0469.description": "正確なI2Cリアルタイムクロック（RTC）、水晶補正、内蔵充電式バッテリー。",
    "gui.extension.dfr0126.name": "Spectrum Analysis Module",
    "gui.extension.dfr0126.description": "音の周波数を追跡する",
    "gui.extension.dfr0231.name": "NFC Module",
    "gui.extension.dfr0231.description": "近距離無線通信に最適",
    "gui.extension.sen0245.name": "VL53L0X Laser Ranging Sensor",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "Analog Ambient Light Sensor",
    "gui.extension.dfr0026.description": "周辺光密度を検出する",
    "gui.extension.dfr0027.name": "Digital Vibration Sensor",
    "gui.extension.dfr0027.description": "振動信号を検出してデジタル信号を出力",
    "gui.extension.dfr0028.name": "Digital Tilt Sensor",
    "gui.extension.dfr0028.description": "水銀スイッチの数に基づいて、単純な傾斜センサーとして使用できます",
    "gui.extension.dfr0029.name": "Digital Push Button",
    "gui.extension.dfr0029.description": "プッシュダウン：高レベルリリース：低レベル",
    "gui.extension.dfr0030.name": "Digital Capacitive Touch Sensor",
    "gui.extension.dfr0030.description": "金属と人体を感知する容量性タッチスイッチモジュール",
    "gui.extension.dfr0033.name": "Digital Magnetic Sensor",
    "gui.extension.dfr0033.description": "3cm以内の磁性物質を検出",
    "gui.extension.dfr0034.name": "Analog Sound Sensor",
    "gui.extension.dfr0034.description": "周囲のラウドネスを検出する",
    "gui.extension.sen0132.name": "Analog Carbon Monoxide Sensor",
    "gui.extension.sen0132.description": "20〜2000ppm以内のCOガス濃度を検出",
    "gui.extension.dfr0051.name": "Analog Voltage Divider",
    "gui.extension.dfr0051.description": "0.0245Vから25Vまでの検出電圧（DC）",
    "gui.extension.dfr0052.name": "Analog Piezo Disk Vibration Sensor",
    "gui.extension.dfr0052.description": "動的および準静的応力を検出し、アナログ信号を出力するには",
    "gui.extension.dfr0058.name": "Analog Rotation Potentiometer Sensor",
    "gui.extension.dfr0058.description": "最大回転数10の高精度ポテンショメータ",
    "gui.extension.dfr0061.name": "Joystick Module",
    "gui.extension.dfr0061.description": "（X、Y）アナログ出力、（Z）1​​デジタル出力、それはロモートコントローラーとして使用可能",
    "gui.extension.dfr0072.name": "Shiftout Sensor",
    "gui.extension.dfr0072.description": "「シフトアウトセンサー」拡張機能の説明",
    "gui.extension.dfr0563.name": "battery Sensor",
    "gui.extension.dfr0563.description": "「バッテリーセンサー」拡張機能の説明",
    "gui.extension.dfr0553.name": "16-bit AD conversion module",
    "gui.extension.dfr0553.description": "「16ビットAD変換モジュール」拡張の説明",
    "gui.extension.dfr0588.name": "Temperature and humidity sensor",
    "gui.extension.dfr0588.description": "温度と湿度",
    "gui.extension.dfr0076.name": "Analog Flame Sensor",
    "gui.extension.dfr0076.description": "波長760nm-1100nmの炎または光を検出",
    "gui.extension.dfr0117.name": "Eeprom iic Sensor",
    "gui.extension.dfr0117.description": "データストレージモジュール、32kb",
    "gui.extension.dfr0143.name": "Triple Axis Accelerometer MMA7361",
    "gui.extension.dfr0143.description": "MMA7361チップに基づいて、ジェスチャーと移動方向を検出",
    "gui.extension.sen0018.name": "Digital Infrared Motion Sensor",
    "gui.extension.sen0018.description": "人や動物が動く赤外線を放出する赤外線を検出する",
    "gui.extension.sen0114.name": "Analog Soil Moisture Sensor",
    "gui.extension.sen0114.description": "周囲の土壌に存在する水分量を読み取ります。",
    "gui.extension.sen0121.name": "Steam Sensor",
    "gui.extension.sen0121.description": "雨水、蒸気、水ミストを検出",
    "gui.extension.sen0212.name": "Color Identification Sensor",
    "gui.extension.sen0212.description": "オブジェクトの色を識別し、RGB値を出力する",
    "gui.extension.sen0253.name": "10DOF Attitude Sensor",
    "gui.extension.sen0253.description": "BBNO055は、インテリジェントな9軸絶対方位センサーを実装するための新しいセンサーICです。",
    "gui.extension.sen0290.name": "lightning sensor",
    "gui.extension.sen0290.description": "インテリジェント雷センサー",
    "gui.extension.sen0291.name": "I2C digital power meter",
    "gui.extension.huskylens.name": "HUSKYLENS AI Camera",
    "gui.extension.huskylens.description": "顔認識と学習をサポートする人工知能視覚センサー",
    "gui.extension.ps2.name": "PS2 handle",
    "gui.extension.ps2.description": "PS2ハンドル",
    "gui.extension.sen0291.description": "電圧、電流、電力の高精度測定",
    "gui.extension.sen0202.name": "3D Mini Gesture Sensor",
    "gui.extension.sen0202.description": "時計回りと反時計回りの回転と移動方向を検出",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "Micro:bit専用リチウム電池モーター拡張ボード",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "デバイスがWi-Fiネットワークに接続できるようにします",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "デバイスがローカル時間を取得できるようにします",
    "gui.extension.udp.name": "UDP",
    "gui.extension.udp.description": "デバイスがUDPネットワークに接続できるようにします",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "デバイスにHTTP経由で情報を要求させる",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "デバイスがMQTTプロトコルを使用して通信できるようにする",
    "gui.extension.internet.name": "Internet",
    "gui.extension.internet.description": "インターネット通信",
    "gui.extension.speechRecognition.name": "Speech Recognition",
    "gui.extension.speechRecognition.description": "音声認識",
    "gui.gui.loaded": "読み込み完了",
    "gui.gui.notLoaded": "読み込み失敗",
    "gui.gui.cantFindYouWant": "ご希望のものが見つかりませんか？",
    "gui.gui.clickHere": "ここをクリック",
    "gui.gui.viewHelp": "して更に検索",
    "gui.gui.uploadToDev": "アップロード",
    "gui.gui.codeArea": "コードエリア",
    "gui.gui.fileSys": "ファイルシステム",
    "gui.gui.compileAndUpload": "コンパイルしてアップロード",
    "gui.gui.compileOnly": "コンパイルのみ",
    "gui.gui.compileOpen": "コンパイル後に開く",
    "gui.gui.burnBootloader": "ワイヤレスブートローダーを書き込む",
    "gui.gui.arduinoRun": "実行",
    "gui.gui.arduinoSave": "保存",
    "gui.gui.autoGenerated": "自動生成",
    "gui.gui.manualEditing": "マニュアル編集",
    "gui.gui.codeBox": "コードボックス",
    "gui.gui.moduleCode": "モジュールコード",
    "gui.menu.edit.undo": "元に戻す",
    "gui.menu.edit.redo": "やり直し",
    "gui.menu.edit.selectAll": "すべて選択",
    "gui.menu.edit.cut": "切り取り",
    "gui.menu.edit.copy": "コピー",
    "gui.menu.edit.paste": "貼り付け",
    "gui.menu.edit.codeStyle": "コードスタイル",
    "gui.menu.edit.fontSize": "フォントサイズ",
    "gui.menu.edit.clearCache": "キャッシュの消去",
    "gui.menu.edit.maxReset": "Maxを工場出荷時設定をリセットします",
    "gui.gui.serial": "シリアル",
    "gui.gui.openSerial": "シリアルオープン",
    "gui.gui.closeSerial": "シリアルクローズ",
    "gui.gui.close": "閉じる",
    "gui.gui.open": "開く",
    "gui.gui.clearOutput": "出力を消去する",
    "gui.gui.scroll": "スクロール",
    "gui.gui.send": "送る",
    "gui.gui.baud": "ボー",
    "gui.gui.noLineEnd": "改行コードなし",
    "gui.gui.newLine": "LF",
    "gui.gui.carriageReturn": "CR",
    "gui.gui.bothNLandCR": "CRLF",
    "gui.gui.unavailableHexData": "無効な16進形式です！ 16進データは2文字（（0-9 / A-F））と空白で表します（例： \"00 01 A2 FF\"）。",
    "gui.gui.hexForm": "16進形式",
    "gui.gui.openHexForm": "16進形式を使用してデータを表示および送信する",
    "gui.gui.closeHexForm": "閉じる",
    "gui.progress.burning": "アップロード",
    "gui.progress.compiling": "コンパイル",
    "gui.burner.compileError": "コンパイルエラー",
    "gui.burner.linkError": "リンクエラー",
    "gui.burner.generateHexError": ".hexの生成に失敗しました",
    "gui.burner.generateBinError": ".binの生成に失敗しました",
    "gui.burner.burnError": "書き込みに失敗しました",
    "gui.burner.eraseError": "消去できませんでした",
    "gui.burner.findLeonardoPortError": "Leonardoのブートローダーポートが見つかりません",
    "gui.burner.noMicrobit": "micro:bitではありません。Micro:bitを接続してください",
    "gui.burner.swdCfgError": "cmsis-dap.cfgへの書き込みに失敗しました",
    "gui.burner.UDiskPopUp": "UDiskがポップアップします。数秒待ってから再試行してください",
    "gui.burner.UDiskWriteError": "Udiskへの書き込みエラー",
    "gui.burner.openPortError": "ポートを開けませんでした：アクセスが拒否されました",
    "gui.burner.printSizeError": "プログラムのサイズを特定できませんでした",
    "gui.burner.printSize": "プロジェクトは[%TEXTSIZE]バイトを使用し、プログラムメモリ領域を（[%TEXTSIZEPRE]）占有して、[%TEXTSIZESUR]バイト残りました。\r\n最大[%TEXTSIZEMAX]バイト。グローバル変数は[%DATASIZE]バイトを使用し、動的メモリ領域を([%DATASIZEPRE]) 占有して、[%DATASIZESUR]バイト残りました。最大[%DATASIZEMAX] バイト。",
    "gui.progress.compileSuccess": "正常にコンパイルされました",
    "gui.progress.compileProgress": "コンパイルの進行状況",
    "gui.progress.uploadProgress": "アップロードの進行状況",
    "gui.progress.uploadSuccess": "正常にアップロードされました",
    "gui.codeLabel.dynamicVariables": "動的変数",
    "gui.codeLabel.functionDeclaration": "関数宣言",
    "gui.codeLabel.staticConstants": "静的定数",
    "gui.codeLabel.createObject": "オブジェクトを作成する",
    "gui.codeLabel.mainProgramStart": "メインプログラム開始",
    "gui.codeLabel.SubthreadProgramStarts": "サブスレッド %1 プログラムが開始しました",
    "gui.codeLabel.customFunction": "カスタム関数",
    "gui.codeLabel.eventCallbackFunction": "イベントコールバック関数",
    "gui.codeLabel.globalCode": "グローバル変数",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "変数は許可されていません！",
    "gui.codeLabel.staticFunctions": "静的関数",
    "gui.progress.errMsg": "アップロードエラー。右下の黒いウィンドウで詳細を確認してください",
    "gui.howtos.spin-around.name": "スピン",
    "gui.howtos.spin.step_dragTurn": "[turn] ブロックをドラッグします",
    "gui.howtos.spin.step_clickTurn": "実行するブロックをクリックします",
    "gui.howtos.spin.step_clickControl": "[Control] カテゴリーをクリックします",
    "gui.howtos.spin.step_dragForever": "[forever] ブロックをドラッグします",
    "gui.howtos.spin.step_clickForever": "実行するブロックをクリックします",
    "gui.howtos.spin.step_changeColor": "[change color effect] ブロックを追加する",
    "gui.howtos.say-hello.name": "こんにちはと言う",
    "gui.howtos.say-hello.step_addSprite": "新しいスプライトを追加する",
    "gui.howtos.say-hello.step_clickLooks": "[Looks] カテゴリをクリックします",
    "gui.howtos.say-hello.step_dragSay": "[say] ブロックをドラッグします",
    "gui.howtos.say-hello.step_clickSay": "実行するブロックをクリックします",
    "gui.howtos.say-hello.step_anotherSay": "別の [say] ブロックをドラッグします",
    "gui.howtos.say-hello.step_editSay": "ブロックを編集",
    "gui.howtos.say-hello.step_clickStack": "実行するブロックをクリックします",
    "gui.howtos.run-away.name": "逃げる",
    "gui.howtos.run-away.step_dragGoTo": "[go to random position] ブロックをドラッグします",
    "gui.howtos.run-away.step_clickGoTo": "実行するブロックをクリックします",
    "gui.howtos.run-away.step3": "[Events] カテゴリをクリックします",
    "gui.howtos.run-away.step_addWhenClicked": "[when this sprite clicked] ブロックを追加する",
    "gui.howtos.run-away.step_clickSprite": "実行するスプライトをクリックします",
    "gui.howtos.run-away.step_addSound": "[start sound] ブロックを追加する",
    "gui.howtos.pick-up-apple.name": "りんごを捕まえるゲーム",
    "gui.howtos.pick-up-apple.step_1": "Scratchに切り替える",
    "gui.howtos.pick-up-apple.step_2": "ハードウェアに切り替える",
    "gui.howtos.pick-up-apple.step_3": "デバイスを接続する",
    "gui.howtos.pick-up-apple.step_4": "コンパスのキャリブレーション",
    "gui.howtos.pick-up-apple.step_5": "サンプルプログラムを開きます",
    "gui.howtos.pick-up-apple.step_6": "りんごを捕まえるゲーム",
    "gui.howtos.move-left-right.name": "micro:bit と 猫",
    "gui.howtos.move-left-right.step_1": "新しいプロジェクトを作成する",
    "gui.howtos.move-left-right.step_2": "block1をドラッグします",
    "gui.howtos.move-left-right.step_3": "block2をドラッグします",
    "gui.howtos.move-left-right.step_4": "左右に移動",
    "gui.howtos.install-driver.name": "ドライバーのインストール",
    "gui.howtos.transform-expression.name": "笑顔 or 泣き顔",
    "gui.howtos.transform-expression.step_1": "コードに切り替える",
    "gui.howtos.transform-expression.step_2": "ハードウェアに切り替える",
    "gui.howtos.transform-expression.step_3": "block1をドラッグします",
    "gui.howtos.transform-expression.step_4": "block2をドラッグします",
    "gui.howtos.transform-expression.step_5": "block3をドラッグします",
    "gui.howtos.transform-expression.step_6": "笑顔 or 泣き顔",
    "gui.howtos.lot.name": "IOT (Internet of Things)",
    "gui.howtos.lot.step_1": "サンプルプログラムを選択",
    "gui.howtos.lot.step_2": "プログラムを書き込む",
    "gui.howtos.lot.step_3": "IOT (Internet of Things)",
    "gui.howtos.touch-pin.name": "ピンタッチ",
    "gui.howtos.touch-pin.step_1": "コードに切り替える",
    "gui.howtos.touch-pin.step_2": "ルーティンを選択",
    "gui.howtos.touch-pin.step_3": "プログラムを書き込む",
    "gui.howtos.touch-pin.step_4": "ピンタッチ",
    "gui.howtos.save-witch.name": "レスキューウィッチ",
    "gui.howtos.save-witch.step_1": "Scratchに切り替える",
    "gui.howtos.save-witch.step_2": "拡張で[micro:bit]を選択します",
    "gui.howtos.save-witch.step_3": "サンプルプログラムで「レスキューウィッチ」を選択します",
    "gui.howtos.save-witch.step_4": "デバイスを接続し、[micro:bit]を選択して、接続が成功するまで待ちます",
    "gui.howtos.save-witch.step_5": "Note:[デバイスを調整する]が表示されたときはLEDが反転するまで、micro:bitを回転させます。",
    "gui.howtos.save-witch.step_6": "緑の旗をクリックし、腕を振ってアニメーションを操作します",
    "gui.howtos.microbit-touch.name": "micro:bitタッチスイッチ絵文字",
    "gui.howtos.microbit-touch.step_1": "コードに切り替える",
    "gui.howtos.microbit-touch.step_2": "サンプルプログラムを選択（ピンタッチ）",
    "gui.howtos.microbit-touch.step_3": "デバイスの対応するCOMポートを接続し、[デバイスにアップロード]をクリックします。",
    "gui.howtos.microbit-touch.step_4": "別のキーを押して別の表情を表示します",
    "gui.howtos.microbit-calibration.name": "micro:bitキャリブレーション",
    "gui.howtos.microbit-calibration.step_1": "micro:bitボードを回転させると、ボードのLEDライトが順番に点灯します。",
    "gui.howtos.microbit-calibration.step_2": "一番外側のLEDライトがすべてオンになるまで（下図を参照）繰り返します。キャリブレーションが完了すると笑顔が表示されます。",
    "gui.extension.sen0251.name": "BMP388 Barometric Pressure Sensor",
    "gui.extension.sen0251.description": "温度、気圧、高度検出機能",
    "gui.extension.sen0206.name": "Non-contact infrared temperature sensor",
    "gui.extension.sen0206.description": "測定対象物と接触せず、測定対象物の温度場に影響を与えません",
    "gui.gui.recentlyEdited": "最近編集したプロジェクト",
    "gui.extension.weather.name": "Get the Weather",
    "gui.extension.weather.description": "ここでは、天気情報を取得するためにWiFiモジュールが必要です",
    "gui.extension.weather.description.javascript": "インターネットを介して気象情報を取得するには、コンピューターがインターネットに接続されている必要があります",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "TinyWebDBネットワークデータベースを操作し、APP Inventorで使用できます",
    "gui.extension.pictureai.name": "AI Image recognition",
    "gui.extension.pictureai.description": "画像AIを使用すると、生活の中で興味深いものを特定できます。使用するにはインターネットに接続する必要があります",
    "gui.monitor.listMonitor.listLength": "長さ {length}",
    "gui.monitor.listMonitor.empty": "（空）",
    "gui.costumeTab.backdrop": "背景",
    "gui.costumeTab.costume": "コスチューム",
    "gui.loadProject.error": "プロジェクトを読み込めませんでした",
    "gui.fileSystem.fileCatalogue": "ファイルディレクトリ",
    "gui.fileSystem.boardFiles": "ダッシュボードのファイル",
    "gui.fileSystem.computerFiles": "コンピューターのファイル",
    "gui.fileSystem.userExFiles": "ユーザーライブラリ内のファイル",
    "gui.fileSystem.newFile": "新しいファイル",
    "gui.fileSystem.newFolder": "新しいフォルダ",
    "gui.fileSystem.deleteFolder": "フォルダを削除",
    "gui.fileSystem.deleteFile": "削除する",
    "gui.fileSystem.rename": "名前を変更",
    "gui.fileSystem.openFolder": "フォルダーの場所を開く",
    "gui.fileSystem.openFilePos": "開いているファイルの場所",
    "gui.fileSystem.openInEditor": "エディターで開く",
    "gui.fileSystem.runRightNow": "すぐに実行",
    "gui.fileSystem.stopRun": "実行を停止",
    "gui.fileSystem.setBoot": "起動するように設定",
    "gui.fileSystem.deleteForever": "完全に削除",
    "gui.fileSystem.saveAs": "名前を付けて保存",
    "gui.fileSystem.undoAllEdits": "すべての変更をキャンセル",
    "gui.fileSystem.copyToComputer": "コンピューターにコピー",
    "gui.fileSystem.uploadToBoard": "ボードにアップロード",
    "gui.fileSystem.uploadAndRun": "アップロードして実行",
    "gui.wifi.pwdNotLegal": "#Error: パスワードの形式が間違っています。 Wi-Fi APモードのパスワードは、8〜20桁または数字である必要があります。",
    "gui.spreadmaqueen.perror": "#Error:P12ピンLEDランプは使用できません。 P8ピンLEDランプを選択してください。",
    "gui.gui.viewConflict": "競合を表示",
    "gui.gui.clickViewHelp": "ヘルプを表示",
    "gui.gui.conflict": "競合",
    "gui.gui.conflictPrompt": "競合警告プロンプト",
    "gui.gui.clickPrompt": "クリックでブロックをハイライト表示、ダブルクリックでブロックを見つけます",
    "gui.extension.mpyUART.name": "Serial UART",
    "gui.extension.mpyUART.description": "UART通信",
    "gui.extension.mpyI2C.name": "I2C communication",
    "gui.extension.mpyI2C.description": "ソフトウェアを使用してI2Cプロトコルをシミュレートし、データを送信する",
    "gui.extension.mpyServo.name": "Servo module",
    "gui.extension.mpyServo.description": "サーボモジュール",
    "gui.extension.mpyTimer.name": "Timer",
    "gui.extension.mpyTimer.description": "プログラムを定期的に実行できます",
    "gui.extension.mpyList.name": "List",
    "gui.extension.mpyList.description": "リスト",
    "gui.extension.mpyDictionary.name": "Dictionary",
    "gui.extension.mpyDictionary.description": "辞書型",
    "gui.extension.mpySet.name": "Set",
    "gui.extension.mpySet.description": "セットする",
    "gui.extension.mpyTuple.name": "Tuple",
    "gui.extension.mpyTuple.description": "タプル",
    "gui.connection.auto-scanning.noPeripheralsFound": "デバイスが見つかりません",
    "gui.connection.auto-scanning.prescan": "デバイスを近くに置いて、検索を開始します。",
    "gui.connection.auto-scanning.pressbutton": "デバイスのボタンを押します。",
    "gui.connection.auto-scanning.start-search": "検索を開始",
    "gui.connection.connecting-searchbutton": "検索中...",
    "gui.connection.auto-scanning.try-again": "再試行",
    "gui.connection.connected": "接続完了",
    "gui.connection.disconnect": "切断",
    "gui.connection.go-to-editor": "エディターに移動",
    "gui.connection.connecting-cancelbutton": "接続中...",
    "gui.connection.error.errorMessage": "エラーが発生しました。",
    "gui.connection.error.tryagainbutton": "再試行",
    "gui.connection.error.helpbutton": "ヘルプ",
    "gui.connection.peripheral-name-label": "デバイス名",
    "gui.connection.connect": "接続",
    "gui.connection.scanning.lookingforperipherals": "デバイスを探しています",
    "gui.connection.scanning.noPeripheralsFound": "デバイスが見つかりません",
    "gui.connection.scanning.instructions": "上のリストからデバイスを選択してください。",
    "gui.connection.search": "リフレッシュ",
    "gui.connection.unavailable.installscratchlink": "Scratch Linkがインストールされ、実行されていることを確認してください",
    "gui.connection.unavailable.enablebluetooth": "Bluetoothが有効になっていることを確認する",
    "gui.connection.unavailable.tryagainbutton": "再試行",
    "gui.connection.unavailable.helpbutton": "ヘルプ",
    "gui.extension.ev3.description": "インタラクティブなロボットなどを構築する",
    "gui.extension.ev3.connectingMessage": "接続しています。 EV3の識別コード（PIN）が1234に設定されていることを確認します。",
    "gui.extension.boost.description": "鮮やかで興味深いロボットの作成",
    "gui.extension.boost.connectingMessage": "接続中...",
    "gui.extension.wedo2.description": "モーターとセンサーをサポートします。",
    "gui.extension.wedo2.connectingMessage": "接続中...",
    "gui.extension.huskyLensPro.name": "HUSKYLENS EDU",
    "gui.extension.huskyLensPro.description": "HUSKYLENSを使用してコンピューターに直接接続し、AIの基本を学ぶ",
    "gui.extension.huskyLensPro.connectingMessage": "HUSKYLENSは接続を要求しようとしています。お待ちください",
    "gui.extension.machinelearning.description": "機械学習アルゴリズムに基づいて、人工知能のアプリケーションをネットワークなしで実現できます",
    "gui.extension.huskylens.pcsetting": "PC 機能構成",
    "gui.extension.huskylens.visualmodal": "PC 視覚分類子",
    "gui.extension.huskylens.KNNText": "PC KNN構成",
    "gui.extension.huskylens.huskylensbasic": "基本機能",
    "gui.extension.huskylens.algorithmOperationLabel": "アルゴリズム操作",
    "gui.extension.huskylens.displayFunctionLabel": "表示機能",
    "gui.extension.huskylens.cameraFunctionLabel": "カメラ機能",
    "gui.huskyLensPro.toConnect": "最初にHUSKYLENS EDUを接続してください",
    "gui.huskyLensPro.toClassificationData": "最初に分類データを追加してください",
    "gui.huskyLensPro.startDeployment": "配置開始",
    "gui.huskyLensPro.switchingAlgorithmFailed": "「オブジェクト分類」アルゴリズムに切り替えられません！",
    "gui.huskyLensPro.forgettingFailure": "学習データを忘れることはできません！",
    "gui.huskyLensPro.failedToSetCategoryLabel": "「カテゴリラベル」の設定に失敗しました！",
    "gui.huskyLensPro.requestFailed": "「デプロイ」のリクエストに失敗しました！",
    "gui.huskyLensPro.deploymentFailed": "「データのデプロイ」に失敗しました！",
    "gui.huskyLensPro.completed": "完了しました",
    "gui.huskyLensPro.countdown": "、[TIME]に完了する予定です",
    "gui.huskyLensPro.minutes": "分",
    "gui.huskyLensPro.seconds": "秒",
    "gui.huskyLensPro.deploying": "モデルをデプロイしています",
    "gui.huskyLensPro.disconnected": "接続が切断され、展開が終了しました。 USB接続をご確認ください！",
    "gui.huskyLensPro.huskylenMaker": "この機能は教育版でのみ利用できます",
    "gui.huskyLensPro.updateVersion": "教育版に接続してください。ファームウェアバージョンは0.5.1以上で、詳細は「ヘルプ」を参照してください",
    "gui.huskyLensPro.failedRetry": "失敗しました。もう一度お試しください！",
    "gui.connection.auto-scanning.notFoundHuskylensTip": "接続がタイムアウトしました。マシンがHuskyLensに接続されていることを確認してください",
    "gui.extension.telloesp32.name": "RoboMaster TT(ESP32)",
    "gui.extension.telloesp32.description": "DJIのフライトコントロールアルゴリズムと安全性、安定性を兼ね備えた教育用ドローン",
    "gui.connection.scanning.instructionsForTello": "最初にコンピューターのWi-FiをTelloまたはRMTTに接続し、上記のデバイスを選択してください",
    "gui.connection.connect.instructionsForTello": "コンピューターのWi-FiリストよりTelloまたはRMTTを最初に接続し、上記のデバイスを選択してください。",
    "gui.connection.scanning.connectTelloHandle": "上記のデバイスを選択するか、手動で機体のWi-Fiに接続してください。",
    "gui.extension.tello.name": "RoboMaster TT(スタンドアローン)",
    "gui.extension.tello.description": "DJIのフライトコントロールアルゴリズムと安全性、安定性を兼ね備えた教育用ドローン",
    "gui.extension.tello.connectingMessage": "接続中... Telloの電源が入っているか確認してください。",
    "gui.extension.telloGroup.name": "RoboMaster TT(チーム)",
    "gui.extension.telloGroup.description": "DJIのフライトコントロールアルゴリズムと安全性、安定性を兼ね備えた教育用ドローン",
    "gui.connection.auto-scanning.notFoundTelloTip": "スキャニング タイムアウト。Telloの電源が入っているか確認してください。",
    "music.categoryName": "音楽",
    "translate.categoryName": "翻訳",
    "pen.categoryName": "ペン",
    "pen.changeColorParam": "ペンの[COLOR_PARAM]を[VALUE]ずつ変える",
    "pen.changeHue": "ペンの色を[HUE]ずつ変える",
    "pen.changeShade": "ペンの濃さを[SHADE]ずつ変える",
    "pen.changeSize": "ペンの太さを[SIZE]ずつ変える",
    "pen.clear": "全部消す",
    "pen.colorMenu.brightness": "明るさ",
    "pen.colorMenu.color": "色",
    "pen.colorMenu.saturation": "鮮やかさ",
    "pen.colorMenu.transparency": "透明度",
    "pen.penDown": "ペンを下ろす",
    "pen.penUp": "ペンを上げる",
    "pen.setColor": "ペンの色を[COLOR]にする",
    "pen.setColorParam": "ペンの[COLOR_PARAM]を[VALUE]にする",
    "pen.setHue": "ペンの色を[HUE]にする",
    "pen.setShade": "ペンの濃さを[SHADE]にする",
    "pen.setSize": "ペンの太さを[SIZE]にする",
    "pen.stamp": "スタンプ",
    "music.changeTempo": "テンポを[TEMPO]ずつ変える",
    "music.drumBass": "(2) バスドラム",
    "music.drumBongo": "(13) ボンゴ",
    "music.drumCabasa": "(15) カバサ",
    "music.drumClaves": "(9) クラーベ",
    "music.drumClosedHiHat": "(6) クローズハイハット",
    "music.drumConga": "(14) コンガ",
    "music.drumCowbell": "(11) カウベル",
    "music.drumCrashCymbal": "(4) クラッシュシンバル",
    "music.drumCuica": "(18) クイーカ",
    "music.drumGuiro": "(16) ギロ",
    "music.drumHandClap": "(8) 手拍子",
    "music.drumOpenHiHat": "(5) オープンハイハット",
    "music.drumSideStick": "(3) サイドスティック",
    "music.drumSnare": "(1) スネアドラム",
    "music.drumTambourine": "(7) タンバリン",
    "music.drumTriangle": "(12) トライアングル",
    "music.drumVibraslap": "(17) ビブラスラップ",
    "music.drumWoodBlock": "(10) ウッドブロック",
    "music.getTempo": "テンポ",
    "music.instrumentBass": "(6) ベース",
    "music.instrumentBassoon": "(14) バスーン",
    "music.instrumentCello": "(8) チェロ",
    "music.instrumentChoir": "(15) 合唱団",
    "music.instrumentClarinet": "(10) クラリネット",
    "music.instrumentElectricGuitar": "(5) エレキギター",
    "music.instrumentElectricPiano": "(2) 電子ピアノ",
    "music.instrumentFlute": "(12) フルート",
    "music.instrumentGuitar": "(4) ギター",
    "music.instrumentMarimba": "(19) マリンバ",
    "music.instrumentMusicBox": "(17) ミュージックボックス",
    "music.instrumentOrgan": "(3) オルガン",
    "music.instrumentPiano": "(1) ピアノ",
    "music.instrumentPizzicato": "(7) ピチカート",
    "music.instrumentSaxophone": "(11) サクソフォン",
    "music.instrumentSteelDrum": "(18) スチールドラム",
    "music.instrumentSynthLead": "(20) シンセリード",
    "music.instrumentSynthPad": "(21) シンセパッド",
    "music.instrumentTrombone": "(9) トロンボーン",
    "music.instrumentVibraphone": "(16) ビブラフォン",
    "music.instrumentWoodenFlute": "(13) 木管フルート",
    "music.midiPlayDrumForBeats": "[DRUM]のドラムを[BEATS]拍鳴らす",
    "music.midiSetInstrument": "楽器を[INSTRUMENT]にする",
    "music.playDrumForBeats": "[DRUM]のドラムを[BEATS]拍鳴らす",
    "music.playNoteForBeats": "[NOTE]の音符を[BEATS]拍鳴らす",
    "music.restForBeats": "[BEATS]拍休む",
    "music.setInstrument": "楽器を[INSTRUMENT]にする",
    "music.setTempo": "テンポを[TEMPO]にする",
    "videoSensing.categoryName": "ビデオモーションセンサー",
    "videoSensing.direction": "向き",
    "videoSensing.motion": "モーション",
    "videoSensing.off": "切",
    "videoSensing.on": "入",
    "videoSensing.onFlipped": "左右反転",
    "videoSensing.setVideoTransparency": "ビデオの透明度を[TRANSPARENCY]にする",
    "videoSensing.sprite": "スプライト",
    "videoSensing.stage": "ステージ",
    "videoSensing.videoOn": "[SUBJECT]のビデオの[ATTRIBUTE]",
    "videoSensing.videoToggle": "ビデオを[VIDEO_STATE]にする",
    "videoSensing.whenMotionGreaterThan": "ビデオモーション > [REFERENCE]のとき",
    "translate.translateBlock": "[WORDS]を[LANGUAGE]に翻訳する",
    "translate.defaultTextToTranslate": "こんにちは",
    "translate.viewerLanguage": "言語",
    "text2speech.speakAndWaitBlock": "[WORDS]としゃべる",
    "text2speech.setVoiceBlock": "声を[VOICE]にする",
    "text2speech.setLanguageBlock": "言語を[LANGUAGE]にする",
    "text2speech.alto": "アルト",
    "text2speech.tenor": "テノール",
    "text2speech.squeak": "ねずみ",
    "text2speech.giant": "巨人",
    "text2speech.kitten": "子猫",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "[PHRASE]を聞いたとき",
    "speech.listenAndWait": "聞いて",
    "speech.defaultWhenIHearValue": "行こう",
    "sensor.categoryName": "Sensor",
    "actuator.categoryName": "Actuator",
    "communicate.categoryName": "Communicate",
    "display.categoryName": "Display",
    "extendFunction.categoryName": "機能モジュール",
    "internetService.categoryName": "Internet",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "handpy",
    "spread.categoryName": "Expansion Board",
    "speechRecognition.categoryName": "Speech Recognition",
    "thirdExpansion.categoryName": "User Expansion",
    "gui.blocklyText.blockTitle.eventBlock": "イベント",
    "gui.blocklyText.blockTitle.dotScreen": "ドットスクリーン",
    "gui.blocklyText.blockTitle.music": "ミュージック",
    "gui.blocklyText.blockTitle.onBoardSensor": "ボードセンサー",
    "gui.blocklyText.blockTitle.pinOperation": "ピン操作",
    "gui.blocklyText.blockTitle.wirelessCommunication": "無線通信",
    "gui.blocklyText.blockTitle.serialportOperation": "シリアルポート操作",
    "gui.blocklyText.blockTitle.systemResource": "システムリソース",
    "gui.blocklyText.blockTitle.screenDisplay": "screen display",
    "gui.blocklyText.blockTitle.ledControl": "led control",
    "gui.blocklyText.blockTitle.functionalModule": "functional module",
    "gui.blocklyText.blockTitle.movement": "movement",
    "gui.blocklyText.blockTitle.expression": "expression",
    "gui.blocklyText.blockTitle.light": "light",
    "gui.blocklyText.blockTitle.dance": "dance",
    "gui.blocklyText.blockTitle.sensor": "sensor",
    "gui.blocklyText.blockTitle.motorControl": "モーターコントロール",
    "gui.blocklyText.blockTitle.kitSensor": "kit sensor",
    "gui.blocklyText.blockTitle.kitActuator": "kit actuator",
    "gui.blocklyText.blockTitle.commonModule": "common module",
    "gui.blocklyText.blockTitle.infraredCommunication": "infrared communication",
    "gui.blocklyText.blockTitle.mp3Module": "mp3 module",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 RGB light",
    "gui.blocklyText.blockTitle.LCDDisplay": "LCD display",
    "gui.blocklyText.blockTitle.digitalTube": "digital tube",
    "gui.blocklyText.blockTitle.oledDisplay": "OLED display",
    "gui.blocklyText.blockTitle.onBoardSource": "ボードリソース",
    "gui.blocklyText.blockTitle.rgbControl": "RGBライト操作",
    "gui.blocklyText.blockTitle.maxbotResource": "max:bot resource",
    "gui.blocklyText.blockTitle.lineInspection": "line inspection",
    "gui.blocklyText.blockTitle.outerSensor": "outer sensor",
    "gui.blocklyText.blockTitle.consolePrint": "console output",
    "gui.blocklyText.blockTitle.radio": "radio",
    "gui.blocklyText.blockTitle.wifi": "wifi",
    "gui.blocklyText.romeo.d1ProgramStart": "D1 robot program starts",
    "gui.blocklyText.romeo.readdigital": "read value pin[PIN] [MODULE] ",
    "gui.blocklyText.romeo.buttonModule": "Button Module",
    "gui.blocklyText.romeo.digitalSensor": "Digital Sensor",
    "gui.blocklyText.romeo.collisionSensor": "Crash Sensor",
    "gui.blocklyText.romeo.lineSensor": "Line Sensor",
    "gui.blocklyText.romeo.infraredSwitch": "Infrared Switch",
    "gui.blocklyText.romeo.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.romeo.readAnalog": "read pin [PIN] [SENSOR] ",
    "gui.blocklyText.romeo.lightSensor": "Light Sensor",
    "gui.blocklyText.romeo.anologSensor": "Anolog Sensor",
    "gui.blocklyText.romeo.soundSensor": "Sound Sensor",
    "gui.blocklyText.romeo.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.romeo.frameSensor": "Frame Sensor",
    "gui.blocklyText.romeo.open": "Turn on",
    "gui.blocklyText.romeo.close": "Turn off",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] of pin [PIN] ",
    "gui.blocklyText.romeo.ledLights": "LED Lights",
    "gui.blocklyText.romeo.digitalActuator": "Digital Actuator",
    "gui.blocklyText.romeo.relay": "Relay",
    "gui.blocklyText.romeo.fan": "Fan",
    "gui.blocklyText.romeo.writeAnalog": "set pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.romeo.fanModule": "Fan Module",
    "gui.blocklyText.romeo.robotSpeedMove": "robot [DIR] at speed[SPEED]",
    "gui.blocklyText.romeo.forward": "前進させる",
    "gui.blocklyText.romeo.backward": "後退させる",
    "gui.blocklyText.romeo.turnLeft": "左旋回させる",
    "gui.blocklyText.romeo.turnRight": "右旋回させる",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "motor [MOTOR] [DIR] at speed[SPEED]",
    "gui.blocklyText.romeo.interface": "Interface",
    "gui.blocklyText.romeo.motorForward": "前進",
    "gui.blocklyText.romeo.motorReverse": "後退",
    "gui.blocklyText.romeo.stopMotor": "robot (M1 and M2 motors) stop moving",
    "gui.blocklyText.romeo.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "read pin [PIN] LM35 temperature(°C)",
    "gui.blocklyText.romeo.ds18b20": "read pin [PIN] DS18B20 temperature(°C)",
    "gui.blocklyText.romeo.pressInfrared": "infrared pin [PIN] received button [BUTTON] pressed signal？",
    "gui.blocklyText.romeo.volumePlus": "Volume+",
    "gui.blocklyText.romeo.switch": "Switch",
    "gui.blocklyText.romeo.volumeMinus": "Volume-",
    "gui.blocklyText.romeo.startPause": "Start/Pause",
    "gui.blocklyText.romeo.up": "Up",
    "gui.blocklyText.romeo.down": "Down",
    "gui.blocklyText.romeo.dataReadable": "シリアルポートで読み取るデータがある",
    "gui.blocklyText.romeo.readSerialData": "シリアルデータの読み取り",
    "gui.blocklyText.romeo.timer": "システム稼働時間",
    "gui.blocklyText.romeo.setBuzzerSound": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.romeo.half": "Half",
    "gui.blocklyText.romeo.quarter": "Quarter",
    "gui.blocklyText.romeo.oneInEighth": "One-eighth",
    "gui.blocklyText.romeo.wholeBeat": "Whole Beat",
    "gui.blocklyText.romeo.doubleBeat": "Double Beat",
    "gui.blocklyText.romeo.stop": "Stop",
    "gui.blocklyText.romeo.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.romeo.segment": "eight-segment LED (D6-D13 pin) display [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "シリアルポート出力 [STRING] [WAY], [LINE]",
    "gui.blocklyText.romeo.originalOutput": "origin",
    "gui.blocklyText.romeo.stringOutput": "string",
    "gui.blocklyText.romeo.hexOutput": "HEX",
    "gui.blocklyText.romeo.wrap": "Wrap",
    "gui.blocklyText.romeo.noWrap": "No-Wrap",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "シリアルデータの読み取り [READTYPE]",
    "gui.blocklyText.romeo.int": "int",
    "gui.blocklyText.romeo.float": "float",
    "gui.blocklyText.romeo.setBaudRate": "シリアルポートのボーレートを [BAUD] に設定",
    "gui.blocklyText.maqueen.robotServo": "サーボ [INDEX] 角度 [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "read pin [PIN] pulse width under [LEVEL] mode, timeout [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "LOW",
    "gui.blocklyText.romeo.high": "HIGH",
    "gui.blocklyText.romeo.noSound": "no sound",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino starts",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno starts",
    "gui.blocklyText.esp32.displayInLine": "display [TEXT] in the [LINE] line",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.normal": "normal",
    "gui.blocklyText.esp32.rev": "rev",
    "gui.blocklyText.esp32.trans": "trans",
    "gui.blocklyText.esp32.XOR": "XOR",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "clear cache in the [LINE] line",
    "gui.blocklyText.esp32.displayClearInLine": "clear in the [LINE] line",
    "gui.blocklyText.esp32.buzzPinC": "P6  (Onboard buzzer)",
    "gui.blocklyText.esp32.buzzerRedirect": "Buzzer redirected to pin [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "All",
    "gui.blocklyText.esp32.setTouchThreshold": "Set button [PIN] touch value is [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "Read button [PIN] touch value",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 starts",
    "gui.blocklyText.esp32.btnIsPressed": "[BUTTON] button [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "when button [REFERENCE] [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "when pin [REFERENCE] is [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "When [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "pin [PIN] is [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "stop background playback",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "play note [NOTE] in background",
    "gui.blocklyText.esp32.setBuzzerFreq": "play note [NOTE] for [BEAT] beat",
    "gui.blocklyText.esp32.getSoundIntensity": "read microphone sound intensity",
    "gui.blocklyText.esp32.readBrightness": "read ambient light brightness",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] show [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.esp32.acceleration": "read acceleration(m-g) [TYPE]",
    "gui.blocklyText.esp32.esp32_tiltAngle": "read tilt angle [TYPE]",
    "gui.blocklyText.esp32.esp32_boardShakeThrown": "the board was [ATTITUDE]?",
    "gui.blocklyText.esp32.esp32_boardTiltState": "the board tilt [GESTUREMPY]",
    "gui.blocklyText.esp32.forward": "forward",
    "gui.blocklyText.esp32.back": "back",
    "gui.blocklyText.esp32.left": "left",
    "gui.blocklyText.esp32.right": "right",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationRange": "set acceleration range [ACCRANGE]",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationResolution": "set acceleration resolution [ACCRESOLUTION]",
    "gui.blocklyText.esp32.esp32_mpyAccurateAccValue": "accurate acceleration value X-axis deviation [XAXIS] Y-axis deviation [YAXIS] Z-axis deviation [ZAXIS]",
    "gui.blocklyText.esp32.esp32_mpyAccurateCompass": "accurate compass",
    "gui.blocklyText.esp32.esp32_mpyCompassDirection": "compass direction",
    "gui.blocklyText.esp32.esp32_mpyAxisMagnetic": "[TYPE] axis magnetic",
    "gui.blocklyText.esp32.esp32_mpyMagneticStrength": "magnetic field strength",
    "gui.blocklyText.esp32.esp32_mpyRemoveMagnetic": "remove the current ambient magnetic field",
    "gui.blocklyText.esp32.esp32_mpyReturnRunTimeDiff": "return run time difference, start time [STARTTIME] end time [ENDTIME]",
    "gui.blocklyText.esp32.esp32_mpyRunTimer": "get run time [TIMETYPE]",
    "gui.blocklyText.esp32.millisecond": "millisecond",
    "gui.blocklyText.esp32.microsencond": "microsencond",
    "gui.blocklyText.esp32.esp32_mpyLocalTime": "get local time [LOCALTIME]",
    "gui.blocklyText.esp32.year": "year",
    "gui.blocklyText.esp32.month": "month",
    "gui.blocklyText.esp32.day": "day",
    "gui.blocklyText.esp32.hour": "hour",
    "gui.blocklyText.esp32.minute": "minute",
    "gui.blocklyText.esp32.sencond": "sencond",
    "gui.blocklyText.esp32.weeknumber": "week number",
    "gui.blocklyText.esp32.daysnumber": "days number",
    "gui.blocklyText.esp32.esp32_mpyReset": "reset",
    "gui.blocklyText.esp32.esp32_mpyMacAddr": "MAC address",
    "gui.blocklyText.esp32.displayInXY": "display [TEXT] at X: [X]  Y: [Y] axis, preview [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "screen display color [COLOR] (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "write cache screen color [COLORMPYTHON]",
    "gui.blocklyText.esp32.clear": "clear",
    "gui.blocklyText.esp32.fullBright": "full bright",
    "gui.blocklyText.esp32.blackBackground": "black background",
    "gui.blocklyText.esp32.whiteBackground": "white background",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": "all white",
    "gui.blocklyText.esp32.lineDrawing": "draw line from Point x1:[X1] y1:[Y1] to Point x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "connected",
    "gui.blocklyText.esp32.disconnected": "disconnected",
    "gui.blocklyText.esp32.rectangleDrawing": "draw rectangle [FILL] with top-left corner at x:[X] y:[Y], W:[WIDTH], H:[HEIGHT]",
    "gui.blocklyText.esp32.mpyrectangleDrawing": "[DRAWERASE] [FILL] rectangle drawing origin x:[X] y:[Y] width[WIDTH] height[HEIGHT]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCornerBorder": "[DRAWERASE] corner border x [X] y [Y] width [WIDTH] height [HEIGHT] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCircle": "[DRAWERASE] [FILL] circle x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseTriangle": "[DRAWERASE] [FILL] triangle x1 [X1] y1 [Y1] x2 [X2] y2 [Y2] x3 [X3] y3 [Y3]",
    "gui.blocklyText.esp32.esp32_mpyShowPixelCoordinate": "at coordinate x [X] y [Y] show [SHOWMSG] as [PIXEL] font [LINE]",
    "gui.blocklyText.esp32.Digiface_11px": "Digiface 11px",
    "gui.blocklyText.esp32.Digiface_16px": "Digiface 16px",
    "gui.blocklyText.esp32.Digiface_21px": "Digiface 21px",
    "gui.blocklyText.esp32.Digiface_30px": "Digiface 30px",
    "gui.blocklyText.esp32.Digiface_44px": "Digiface 44px",
    "gui.blocklyText.esp32.Digiface_ltalic_30px": "Digiface ltalic 30px",
    "gui.blocklyText.esp32.Digiface_ltalic_42px": "Digiface ltalic 42px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_12px": "DejaVu Sans Mono 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_16px": "DejaVu Sans Mono 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_21px": "DejaVu Sans Mono 21px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_12px": "DejaVu Sans Mono Bold 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_16px": "DejaVu Sans Mono Bold 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_21px": "DejaVu Sans Mono Bold 21px",
    "gui.blocklyText.esp32.esp32_mpyQRCode": "x [X] y [Y] draw QR code [QRCODE] size [IMGSIZE]",
    "gui.blocklyText.esp32.esp32_mpyInitClock": "init clock [CLOCKNAME] x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyClockReadTime": "clock [CLOCKNAME] read time",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseClock": "[DRAWERASE] clock [CLOCKNAME]",
    "gui.blocklyText.esp32.screenDisplay": "screen cache display takes effect",
    "gui.blocklyText.esp32.circleDrawing": "draw circle [FILL] with center point at x:[X] y:[Y], r:[RADIUS]",
    "gui.blocklyText.esp32.fill": "fill",
    "gui.blocklyText.esp32.notFill": "not fill",
    "gui.blocklyText.esp32.pointDrawing": "draw point at x:[X] y:[Y]",
    "gui.blocklyText.esp32.esp32_mpyProgressBar": "progress bar x [X] y [Y] width [WIDTH] height [HEIGHT] progress [PROG]",
    "gui.blocklyText.esp32.esp32_mpyColumnarProgressBar": "columnar progress bar [COLUMNARSTATE] x [X] y [Y] width [WIDTH] height [HEIGHT] progress [PROG]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseLine": "[DRAWERASE] [COLUMNARSTATE] line x [X] y [Y] length [LENGTH]",
    "gui.blocklyText.esp32.draw": "draw",
    "gui.blocklyText.esp32.erase": "erase",
    "gui.blocklyText.esp32.vertical": "vertical",
    "gui.blocklyText.esp32.horizontal": "horizontal",
    "gui.blocklyText.esp32.setLineWidth": "set line width to [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.esp32.analogWrite": "analog pin [PIN] output(PWM) [VALUE]",
    "gui.blocklyText.esp32.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.esp32.serialPort0": "serial0",
    "gui.blocklyText.esp32.serialPort1": "serial1",
    "gui.blocklyText.esp32.serialPort2": "serial2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX] the baud rate [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "screen display image [IMAGE] at x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "loading picture from image [IMAGE] show to x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "pressed",
    "gui.blocklyText.esp32.loosened": "released",
    "gui.blocklyText.esp32.all": "ALL(-1)",
    "gui.blocklyText.esp32.setBaud": "serialport0 baud rate [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.esp32.setBrightness": "set led brightness [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "get led lightness",
    "gui.blocklyText.esp32.print": "print [DATA]",
    "gui.blocklyText.esp32.TiltForward": "Tilt forward",
    "gui.blocklyText.esp32.TiltBack": "Tilt back",
    "gui.blocklyText.esp32.TiltLeft": "Tilt left",
    "gui.blocklyText.esp32.TiltRight": "Tilt right",
    "gui.blocklyText.esp32.ScreenUp": "Screen up",
    "gui.blocklyText.esp32.ScreenDown": "Face down",
    "gui.blocklyText.esp32.Shake": "Shake",
    "gui.blocklyText.esp32.isGesture": "is [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "Disable [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "All",
    "gui.blocklyText.esp32.rgbdisable.No.0": "No.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "No.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "No.2",
    "gui.blocklyText.esp32.playMusic": "Play music [MUSIC] repeating [REPEAT]",
    "gui.blocklyText.esp32.Once": "Once",
    "gui.blocklyText.esp32.Forever": "Forever",
    "gui.blocklyText.esp32.OnceInBackground": "Once in background",
    "gui.blocklyText.esp32.ForeverInBackground": "Forever in background",
    "gui.blocklyText.radio.radioSwitch": "turn [DATA] radio",
    "gui.blocklyText.radio.radioswitch.on": "on",
    "gui.blocklyText.radio.radioswitch.off": "off",
    "gui.blocklyText.radio.setChannel": "set radio channl [DATA]",
    "gui.blocklyText.radio.radioSend": "radio sends [DATA]",
    "gui.blocklyText.radio.radioreceived": "radio received",
    "gui.blocklyText.radio.receivingEven": "when received [DATA]",
    "gui.blocklyText.radio.Msg": "radio message",
    "gui.blocklyText.radio.receivingSpecificEven": "when receiving specific broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.radioSwitch": "turn [DATA] radio",
    "gui.blocklyText.esp32.radioswitch.on": "on",
    "gui.blocklyText.esp32.radioswitch.off": "off",
    "gui.blocklyText.esp32.setChannel": "set radio channl [DATA]",
    "gui.blocklyText.esp32.radioSend": "radio sends [DATA]",
    "gui.blocklyText.esp32.radioreceived": "radio received",
    "gui.blocklyText.esp32.receivingEven": "when receiving broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.receivingSpecificEven": "when receiving specific broadcast message [DATA] from Radio do",
    "gui.blocklyText.esp32.connectWIFI": "connect to Wi-Fi with SSID [SSID] & Password [PASS]",
    "gui.blocklyText.esp32.disconnectWIFI": "disconnect Wi-Fi connection",
    "gui.blocklyText.esp32.checkWIFI": "Has connected to Wi-Fi",
    "gui.blocklyText.esp32.configurationInformation": "Wi-Fi configuration information",
    "gui.blocklyText.esp32.configurationInformationIP": "Wi-Fi configuration information [IP]",
    "gui.blocklyText.esp32.wifi.IP": "IP",
    "gui.blocklyText.esp32.wifi.netmask": "netmask",
    "gui.blocklyText.esp32.wifi.geteway": "geteway",
    "gui.blocklyText.esp32.wifi.DNS": "DNS",
    "gui.blocklyText.esp32.openAP": "open AP mode with with SSID [SSID] & Password [PASS] & Channel [CHANNEL]",
    "gui.blocklyText.esp32.closeAP": "close AP mode",
    "gui.blocklyText.esp32.synchronizeTime": "synchronize network time,timezone [TIMEZONE]& timing server [SERVER]",
    "gui.blocklyText.esp32.timezone.UTC+8": "UTC+8",
    "gui.blocklyText.esp32.timezone.UTC+9": "UTC+9",
    "gui.blocklyText.esp32.timezone.UTC+10": "UTC+10",
    "gui.blocklyText.esp32.timezone.UTC+11": "UTC+11",
    "gui.blocklyText.esp32.timezone.UTC+12": "UTC+12",
    "gui.blocklyText.esp32.timezone.UTC-12": "UTC-12",
    "gui.blocklyText.esp32.timezone.UTC-11": "UTC-11",
    "gui.blocklyText.esp32.timezone.UTC-10": "UTC-10",
    "gui.blocklyText.esp32.timezone.UTC-9": "UTC-9",
    "gui.blocklyText.esp32.timezone.UTC-8": "UTC-8",
    "gui.blocklyText.esp32.timezone.UTC-7": "UTC-7",
    "gui.blocklyText.esp32.timezone.UTC-6": "UTC-6",
    "gui.blocklyText.esp32.timezone.UTC-5": "UTC-5",
    "gui.blocklyText.esp32.timezone.UTC-4": "UTC-4",
    "gui.blocklyText.esp32.timezone.UTC-3": "UTC-3",
    "gui.blocklyText.esp32.timezone.UTC-2": "UTC-2",
    "gui.blocklyText.esp32.timezone.UTC-1": "UTC-1",
    "gui.blocklyText.esp32.timezone.UTC": "UTC",
    "gui.blocklyText.esp32.timezone.UTC+1": "UTC+1",
    "gui.blocklyText.esp32.timezone.UTC+2": "UTC+2",
    "gui.blocklyText.esp32.timezone.UTC+3": "UTC+3",
    "gui.blocklyText.esp32.timezone.UTC+4": "UTC+4",
    "gui.blocklyText.esp32.timezone.UTC+5": "UTC+5",
    "gui.blocklyText.esp32.timezone.UTC+6": "UTC+6",
    "gui.blocklyText.esp32.timezone.UTC+7": "UTC+7",
    "gui.blocklyText.mpy.MQTTinit": "MQTT initial [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInit": "mqtt init [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInitPlatform": "platform",
    "gui.blocklyText.mpy.mpyMqttInitPlatformNormal": "normal",
    "gui.blocklyText.mpy.mpyMqttInitPlatformTencent": "tencent",
    "gui.blocklyText.mpy.MQTTclient_id": "client_id",
    "gui.blocklyText.mpy.MQTTserver": "server",
    "gui.blocklyText.mpy.MQTTport": "port",
    "gui.blocklyText.mpy.MQTTuser": "user",
    "gui.blocklyText.mpy.MQTTpassowrd": "passowrd",
    "gui.blocklyText.mpy.MQTTkeepalive": "keepalive",
    "gui.blocklyText.mpy.MQTTtencent": "MQTT Tencent IOT [SETTING]",
    "gui.blocklyText.mpy.TencentIOT": "TencentIOT",
    "gui.blocklyText.mpy.TencentServer": "server",
    "gui.blocklyText.mpy.TencentPort": "port",
    "gui.blocklyText.mpy.TencentAppld": "Appld",
    "gui.blocklyText.mpy.TencentDevice": "device",
    "gui.blocklyText.mpy.siotServer": "Server",
    "gui.blocklyText.mpy.siotServerUser": "User",
    "gui.blocklyText.mpy.siotServerPass": "Password",
    "gui.blocklyText.mpy.Tencentoutput": "output",
    "gui.blocklyText.mpy.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.mpy.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.mpy.mpy_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.mpy.EasyIOTServer": "server",
    "gui.blocklyText.mpy.EasyIOTServerChina": "China",
    "gui.blocklyText.mpy.EasyIOTServerGlobal": "Global",
    "gui.blocklyText.mpy.EasyIOTclient_id": "client_id",
    "gui.blocklyText.mpy.EasyIOT_id": "Iot_id",
    "gui.blocklyText.mpy.EasyIOT_password": "Iot_pwd",
    "gui.blocklyText.mpy.MQTTconnect": "connect to MQTT",
    "gui.blocklyText.mpy.mpy_MQTTAddSub": "subscribe [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublish": "publish [DATA] to topic [TOPIC]",
    "gui.blocklyText.mpy.mpy_waitMSG": "wait for topic message in [MODE] mode",
    "gui.blocklyText.mpy.disconnectMQTT": "disconnect from MQTT",
    "gui.blocklyText.mpy.MQTTlastwill": "set MQTT topic [TOPIC] last will message as [MSG]",
    "gui.blocklyText.mpy.MQTTreceive": "when received [DATA] from topic [TOPIC]",
    "gui.blocklyText.mpy.mqttMsg": "mqtt message",
    "gui.blocklyText.mpy.MQTTmode.block": "block",
    "gui.blocklyText.mpy.MQTTmode.unblock": "unblock",
    "gui.blocklyText.mpyWeather.init": "weather server set [DATA] as [SETTING]",
    "gui.blocklyText.mpyWeather.weatherFeild": "[DATA] weather server common fields [FIELD]",
    "gui.blocklyText.mpyWeather.weatherSituation": "[DATA] weather server Weather now [NOW]",
    "gui.blocklyText.mpyWeather.weatherForecast": "[DATA] weather server Weather forecast [FIELD] for [DATE]",
    "gui.blocklyText.mpyWeather.weatherLivingindex": "[DATA] weather server Living index [FIELD] index",
    "gui.blocklyText.mpyWeather.weatherFeild.name": "name",
    "gui.blocklyText.mpyWeather.weatherFeild.id": "id",
    "gui.blocklyText.mpyWeather.weatherFeild.code": "code",
    "gui.blocklyText.mpyWeather.weatherFeild.path": "path",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone": "timezone",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone_offset": "timezone_offset",
    "gui.blocklyText.mpyWeather.weatherFeild.Updated": "Updated",
    "gui.blocklyText.mpyWeather.weatherFeild.updatetime": "updatetime",
    "gui.blocklyText.mpyWeather.weatherFeild.original": "original",
    "gui.blocklyText.mpyWeather.weatherSituation.text": "text",
    "gui.blocklyText.mpyWeather.weatherSituation.code": "code",
    "gui.blocklyText.mpyWeather.weatherSituation.temperature": "temperature",
    "gui.blocklyText.mpyWeather.weatherdate.today": "today",
    "gui.blocklyText.mpyWeather.weatherdate.tomorrow": "tomorrow",
    "gui.blocklyText.mpyWeather.weatherdate.dayaftertomorrow": "dayaftertomorrow",
    "gui.blocklyText.mpyWeather.province": "province",
    "gui.blocklyText.mpyWeather.city": "city",
    "gui.blocklyText.mpyWeather.weatherforecast.highesttemperature": "highesttemperature",
    "gui.blocklyText.mpyWeather.weatherforecast.lowesttemperature": "lowesttemperature",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweather": "daytimeweather",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweathercode": "daytimeweathercode",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweather": "nightweather",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweathercode": "nightweathercode",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirection": "winddirection",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirectiondegree": "winddirectiondegree",
    "gui.blocklyText.mpyWeather.weatherforecast.windspeed": "windspeed",
    "gui.blocklyText.mpyWeather.weatherforecast.windscale": "windscale",
    "gui.blocklyText.mpyWeather.weatherforecast.date": "date",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Carwashindex": "Carwashindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Dressingindex": "Dressingindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Coldindex": "Coldindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Sportindex": "Sportindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Travelindex": "Travelindex",
    "gui.blocklyText.mpyWeather.weatherlivingindex.uvindex": "uvindex",
    "gui.blocklyText.mpyWeather.type": "data type",
    "gui.blocklyText.mpyWeather.temperature": "temperature",
    "gui.blocklyText.mpyWeather.APIkey": "APIkey",
    "gui.blocklyText.mpyWeather.language": "language",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 starts",
    "gui.blocklyText.mega2560.digitalRead": "read digital pin [PIN]",
    "gui.blocklyText.mega2560.analogRead": "read analog pin [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "pwm pin [PIN] output [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "digital pin [PIN] output [VALUE]",
    "gui.blocklyText.mega2560.level.low": "LOW",
    "gui.blocklyText.mega2560.level.high": "HIGH",
    "gui.blocklyText.mega2560.irqRead": "read digital pin [PIN] of Infrared Receiver",
    "gui.blocklyText.mega2560.ultrasonicRead": "read ulrasonic sensor trig [TRIG], echo [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "pin [PIN] play buzzer tone [TONE] for [BEAT] beat",
    "gui.blocklyText.mega2560.harf": "Harf",
    "gui.blocklyText.mega2560.quarter": "Quarter",
    "gui.blocklyText.mega2560.oneInEighth": "One-eighth",
    "gui.blocklyText.mega2560.wholeBeat": "Whole Beat",
    "gui.blocklyText.mega2560.doubleBeat": "Double Beat",
    "gui.blocklyText.mega2560.stop": "Stop",
    "gui.blocklyText.mega2560.servoSet": "Set pin [PIN] servo to [DEGREE] degrees",
    "gui.blocklyText.mega2560.setBaud": "set [SERIAL] baud rate to [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "serial0",
    "gui.blocklyText.mega2560.serialPort1": "serial1",
    "gui.blocklyText.mega2560.serialPort2": "serial2",
    "gui.blocklyText.mega2560.serialPort3": "serial3",
    "gui.blocklyText.mega2560.dataReadable": "Is there data to read on [SERIAL] ?",
    "gui.blocklyText.mega2560.readSerialData": "read data on [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "in string",
    "gui.blocklyText.mega2560.originalOutput": "original output",
    "gui.blocklyText.mega2560.hexOutput": "in HEX",
    "gui.blocklyText.mega2560.wrap": "Wrap",
    "gui.blocklyText.mega2560.noWrap": "No-Wrap",
    "gui.blocklyText.mega2560.readPinLevel": "read pin [PIN] pulse width under [LEVEL] mode, timeout [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "system uptime(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini starts",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo starts",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 starts",
    "gui.blocklyText.arduino.setDigital": "digital pin [PIN] ouput [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "read digital pin [PIN]",
    "gui.blocklyText.arduino.readSimilation": "read analog pin [PIN]",
    "gui.blocklyText.arduino.setPwm": "pwm pin [PIN] output [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat ",
    "gui.blocklyText.arduino.serialSendStr": "serial output [STRING] [WAY], [LINE]",
    "gui.blocklyText.arduino.originalOutput": "in origin",
    "gui.blocklyText.arduino.stringOutput": "in string",
    "gui.blocklyText.arduino.hexOutput": "in HEX",
    "gui.blocklyText.arduino.wrap": "Wrap",
    "gui.blocklyText.arduino.noWrap": "No-Wrap",
    "gui.blocklyText.arduino.readInfrared": "read digital pin [PIN] of infrared Receiver",
    "gui.blocklyText.arduino.setBaud": "set serial-port baud rate to [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "read pin [PIN] pulse width pattern [LEVEL] timeout [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "no sound",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Arduino Nano starts",
    "gui.blocklyText.vortex.vortexStart": "Vortex robot program starts",
    "gui.blocklyText.vortex.vortexMoveSpeed": "vortex robot [DIRECTION] at speed[SPEED] ",
    "gui.blocklyText.vortex.forward": "move forward",
    "gui.blocklyText.vortex.backup": "move backward",
    "gui.blocklyText.vortex.turnLeft": "turn left",
    "gui.blocklyText.vortex.turnRight": "turn right",
    "gui.blocklyText.vortex.setMotor": "motor [MOTOR] [DIRECTION] at speed[SPEED]  ",
    "gui.blocklyText.vortex.leftWheel": "left",
    "gui.blocklyText.vortex.rightWheel": "right",
    "gui.blocklyText.vortex.setExpression": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.vortex.ledLights": "[DIRECTION][LIGHT] change to [COLOR] in [SECOND]secs.",
    "gui.blocklyText.vortex.all": "all",
    "gui.blocklyText.vortex.rightAhead": "front",
    "gui.blocklyText.vortex.leftRear": "left rear",
    "gui.blocklyText.vortex.rightRear": "right rear",
    "gui.blocklyText.vortex.rightAstern": "rear",
    "gui.blocklyText.vortex.topLights": "top lights",
    "gui.blocklyText.vortex.bottomLights": "bottom lights",
    "gui.blocklyText.vortex.vortexStop": "Vortex stops moving ",
    "gui.blocklyText.vortex.setVolume": "set volume to [VALUE]",
    "gui.blocklyText.vortex.playMusic": "play the [VALUE] music",
    "gui.blocklyText.vortex.stopMusic": "stop playing music",
    "gui.blocklyText.vortex.startDance": "start the [VALUE] dance",
    "gui.blocklyText.vortex.stopDance": "stop dancing",
    "gui.blocklyText.vortex.barrierDetection": "obstacle ahead?",
    "gui.blocklyText.vortex.readSensor": "read Grayscale Sensor [SENSOR]",
    "gui.blocklyText.vortex.setSensor": "set grayscale sensors sensitivity to [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "grayscale sensor meets black on its [SENSOR] position?",
    "gui.blocklyText.vortex.positiveFrontLeft": "straight left front",
    "gui.blocklyText.vortex.frontLeft": "left front",
    "gui.blocklyText.vortex.positiveFrontRight": "straight right front",
    "gui.blocklyText.vortex.frontRight": "right front",
    "gui.blocklyText.vortex.backRight": "right rear",
    "gui.blocklyText.vortex.backLeft": "left rear",
    "gui.blocklyText.vortex.initialOldPCB": "initialize to old version (blue PCB)",
    "gui.blocklyText.microbit.microbitStarts": "micro:bitスタート",
    "gui.blocklyText.microbit.interruptExcute": "interrupt pin [PIN] mode [MODE] excute",
    "gui.blocklyText.microbit.cancleInterrupt": "cancel interrupt pin [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "無線で受信したとき",
    "gui.blocklyText.microbit.whenBtnPress": "ボタン [REFERENCE] が押されたとき",
    "gui.blocklyText.microbit.whenPinConnected": "端子 [REFERENCE] がタッチされたとき",
    "gui.blocklyText.microbit.whenPosChange": "[REFERENCE] とき ",
    "gui.blocklyText.microbit.showFont": "LED画面に表示 [PIC]",
    "gui.blocklyText.microbit.showInput": "文字列を表示 [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "アニメーションを停止",
    "gui.blocklyText.microbit.controlLight": "座標（x：[XAXIS]、y：[YXAXIS]）のLEDを [CONTROL]",
    "gui.blocklyText.microbit.hideAllLights": "表示を消す",
    "gui.blocklyText.microbit.microbitEnableLight": "LED表示を有効にする [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "明るさ",
    "gui.blocklyText.microbit.showLightWithBrightness": "明るさを設定する [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "点灯 (x:[XAXIS], y:[YXAXIS]), 明るさ [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "端子 [PIN] [SOUND] の音を鳴らす",
    "gui.blocklyText.microbit.playSoundUntil": "端子 [PIN] 終わるまで [SOUND] の音を鳴らす",
    "gui.blocklyText.microbit.playNote": "端子 [PIN] 音を鳴らす（Hz） [NOTE] 長さ [BEAT] 拍",
    "gui.blocklyText.microbit.true": "真",
    "gui.blocklyText.microbit.false": "偽",
    "gui.blocklyText.maqueen.playSound": "pin P0 [SOUND] の音を鳴らす",
    "gui.blocklyText.maqueen.playSoundUntil": "pin P0 終わるまで [SOUND] の音を鳴らす",
    "gui.blocklyText.maqueen.playNote": "pin P0 音を鳴らす 高さ（Hz） [NOTE] 長さ [BEAT] 拍",
    "gui.blocklyText.maqueen.RGB0": "RGB0  (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1  (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2  (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3  (3)",
    "gui.blocklyText.maqueen.All": "全て (-1)",
    "gui.blocklyText.microbit.changeTempo": "テンポを上げる（bpm）  [VALUE]",
    "gui.blocklyText.microbit.setTempo": "テンポを設定する（bpm） [VALUE]",
    "gui.blocklyText.microbit.getTempo": "テンポ（bpm）",
    "gui.blocklyText.microbit.stopSound": "バックグラウンド再生を停止する",
    "gui.blocklyText.microbit.btnIsPressed": "ボタン [BUTTON] が押されている",
    "gui.blocklyText.microbit.pinIsConnected": "端子 [PIN] がタッチされている",
    "gui.blocklyText.microbit.isMove": "[TYPE] 動き",
    "gui.blocklyText.microbit.compass": "方角（°）",
    "gui.blocklyText.microbit.temperature": "温度（℃）",
    "gui.blocklyText.microbit.digitalWrite": "デジタルで出力する 端子 [PIN] 値 [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "デジタルで読み取る 端子 [PIN]",
    "gui.blocklyText.microbit.analogWrite": "アナログで出力する 端子 [PIN] 値 [VALUE]",
    "gui.blocklyText.microbit.analogRead": "アナログで読み取る 端子 [PIN]",
    "gui.blocklyText.microbit.up": "ロゴが上になった",
    "gui.blocklyText.microbit.down": "ロゴが下になった",
    "gui.blocklyText.microbit.left": "左に傾けた",
    "gui.blocklyText.microbit.right": "右に傾けた",
    "gui.blocklyText.microbit.faceUp": "画面が上になった",
    "gui.blocklyText.microbit.faceDown": "画面が下になった",
    "gui.blocklyText.microbit.freefall": "落とした",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "ゆさぶられた",
    "gui.blocklyText.microbit.show": "点灯",
    "gui.blocklyText.microbit.hide": "消灯",
    "gui.blocklyText.microbit.low": "LOW",
    "gui.blocklyText.microbit.high": "HIGH",
    "gui.blocklyText.microbit.microbitReadBrightness": "明るさ",
    "gui.blocklyText.microbit.microbitOpenWireless": "無線通信を [SWITCH]",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "無線のグループを設定 [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "無線で文字列を送信 [TEXT]",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "receive data via wireless",
    "gui.blocklyText.microbit.strength": "強さ",
    "gui.blocklyText.microbit.acceleration": "加速度 [TYPE]",
    "gui.blocklyText.microbit.magneticForce": "磁力（μT） [TYPE]",
    "gui.blocklyText.microbit.microbitAccurateCompass": "電子コンパスの調整",
    "gui.blocklyText.microbit.print": "印刷 [DATA]",
    "gui.blocklyText.microbit.goUp": "up",
    "gui.blocklyText.microbit.goDown": "down",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "無線で受信したとき [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "無線データ",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot Starts",
    "gui.blocklyText.maxbot.playSound": "play sound [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "play sound [SOUND] until done",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "collision occurred on the [LEFTRIGHT] ?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "black line detected on the [LEFTRIGHT]  ?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "motor [MOTOR] [DIR] at speed [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "read (P1,P2) ulrasonic sensor [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "read ulrasonic sensor Unit [UNIT], trig [TRIG], echo [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "FORWARD",
    "gui.blocklyText.maxbot.BACKWARD": "BACKWARD",
    "gui.blocklyText.motorbit.CW": "CW",
    "gui.blocklyText.motorbit.CCW": "CCW",
    "gui.blocklyText.maxbot.CW": "CW",
    "gui.blocklyText.maxbot.CCW": "CCW",
    "gui.blocklyText.maxbot.ANGLE": "degrees",
    "gui.blocklyText.maxbot.CIRCLE": "revolutions",
    "gui.blocklyText.maxbot.Left": "左",
    "gui.blocklyText.maxbot.Right": "右",
    "gui.blocklyText.maxbot.cm": "cm",
    "gui.blocklyText.maxbot.inch": "inch",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit Starts",
    "gui.blocklyText.MotorBit.MotorRun": "motor [MOTOR] rotate [DIR] at speed [SPEED]",
    "gui.blocklyText.MotorBit.MotorStop": "motor [MOTOR] stops",
    "gui.blocklyText.MotorBit.Stepper": "stepper [STEPPER] rotate [ROTATEDIR] for [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "ALL",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueenスタート",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "(pin P1,P2) 距離（cm）",
    "gui.blocklyText.maqueen.motorStop": "ロボット（左右のモーター）を停止する",
    "gui.blocklyText.maqueen.controlLeds": "LEDライト [LEFTRIGHT] を [SWITCH]",
    "gui.blocklyText.maqueen.redLineSensor": "[LEFTRIGHT] ライントラッキングセンサー",
    "gui.blocklyText.maqueen.robotSpeedMove": "ロボットを [DIR] 速度: [SPEED]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "[LEFTRIGHT] モーター を回転方向: [DIR]、 速度: [SPEED] にする",
    "gui.blocklyText.maqueen.ledLeft": "左（P8）",
    "gui.blocklyText.maqueen.ledRight": "右（P12）",
    "gui.blocklyText.spreadmaqueen.ledRight": "Right(P12) (not available)",
    "gui.blocklyText.maqueen.lineSensorLeft": "左（P13）",
    "gui.blocklyText.maqueen.lineSensorRight": "右（P14）",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "(pin P16) 赤外線信号を受信したとき [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "赤外線信号",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "(pin P16)赤外線信号の値を読み取る",
    "gui.blocklyText.maqueen.numberOfLightsBright": "(pin P15) RGBライトの明るさを [BRIGHT] にする",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "(pin P15) RGBライト [NUM1] の色を [COLOR] にする",
    "gui.blocklyText.maqueen.showGradientColor": "(pin P15) LED [NUM1] から [NUM2] に [COLOR1] から [COLOR2] のグラデーションカラーを表示",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "(pin P15) RGBライトを [NUM] 個分ずらす",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "(pin P15) RGBライトを [NUM] 個分回転する",
    "gui.blocklyText.maqueen.setWs2812Brightness": "(pin P15) RGBライトの明るさを [BRIGHTNESS] に変更する",
    "gui.blocklyText.maqueen.showHistogram": "(pin P15) LED [NUM1] から [NUM2] にヒストグラムを表示（値 [VALUE]、最大 [MAX]）",
    "gui.blocklyText.maqueen.clear": "(pin P15) RGBライトを消灯する",
    "gui.blocklyText.maqueen.rgbColor": "赤 [RED] 緑 [GREEN] 青 [BLUE]",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] received data?",
    "gui.blocklyText.obloq.initialParameter": "obloq mqtt initialization: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq read the message from [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq read the message",
    "gui.blocklyText.obloq.httpInitialParameter": "obloq http initialization: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] timeout (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "add subscribe [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "Wi-Fi name",
    "gui.blocklyText.obloq.password": "password",
    "gui.blocklyText.obloq.iotService": "IOT Service",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "IP Address",
    "gui.blocklyText.obloq.port": "Port",
    "gui.blocklyText.obloq.addTopic": "Add Topic, only add up to 4",
    "gui.blocklyText.obloq.deleteTopic": "Delete Topic",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "Server",
    "gui.blocklyText.obloq.global": "Global",
    "gui.blocklyText.obloq.china": "China",
    "gui.blocklyText.obloq.softwareSerial": "Software Serial",
    "gui.blocklyText.obloq.hardwareSerial": "Hardware Serial",
    "gui.blocklyText.obloq.hardwareSerial1": "Hardware Serial1",
    "gui.blocklyText.obloq.hardwareSerial2": "Hardware Serial2",
    "gui.blocklyText.obloq.hardwareSerial3": "Hardware Serial3",
    "gui.blocklyText.obloq.WhenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq message",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq get [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Debug info",
    "gui.blocklyText.obloq.ERROR": "Error code",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.infraredEmission.send": "Infrared send pin [PIN] Send message: address [ADDRESS] command [COMMAND]",
    "gui.blocklyText.oled2864.displayInit": "OLED display init address [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 display [TEXT] in line [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 display [TEXT] at position X: [X] Y: 16*[Y]",
    "gui.blocklyText.oled2864.screenRotation": "rotate screen to [ROTATION] degrees",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 clear",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "read pin [PIN] [DHT] [TYPE]",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx pin [PIN] PM concentration value [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 ultrasonic initialization Name [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04 ultrasonic distance measurement unit [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx pin [PIN] 0.1 liters of air in [DIAMETER] number of particles",
    "gui.blocklyText.sen0170.readWindspeed": "read pin [PIN] wind scale",
    "gui.blocklyText.sen0228.init": "Initialize ambient light (VEML7700) sensor",
    "gui.blocklyText.sen0228.readAmbientLight": "read Ambient Light(VEML7700)",
    "gui.blocklyText.dfr0026.readAmbientLight": "read pin [PIN] Ambient light",
    "gui.blocklyText.sen0177.readDustSensor": "read [TYPE] (ug/m3) interface [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "pin(D) [PIN] pin(MCLR) [RST] current gesture [TYPE]?",
    "gui.blocklyText.sen0203.readHeartRate": "read pin [PIN] [TYPE] switch to [SWITCH]",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] pin [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "read pin [PIN] grayscale",
    "gui.blocklyText.motucamera.initIICSerial": "mu camera init [MU] interface [IICSERIAL] until init success",
    "gui.blocklyText.motucamera.resetConfig": "mu camera [MU] reset to default",
    "gui.blocklyText.motucamera.algorithmSwitch": "mu camera [MU] [SWITCH] algorithm [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "mu camera [MU] [LED] when detect [COLOR1] when undetect [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "mu camera [MU] set algorithm [ALGORITHM] level [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "mu camera [MU] digital zoom [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "mu camera [MU] set baudrate [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "mu camera [MU] set white balance [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "mu camera [MU] high FPS mode [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "mu camera [MU] is detect [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": "mu camera [MU] is detect color recogniza x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "mu camera [MU] is detect color detect color = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "mu camera get [MU] alogrithm [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "mu camera [MU] color recognize [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "mu camera [MU] shape card [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "mu camera [MU] traffic card [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "mu camera [MU] number card [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "mu camera [MU] color recognize color = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "Color Detect",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "Color Recognize",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "Ball Detect",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "Body Detect",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "Shape Card",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "Traffic Card",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "Number Card",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "enable",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "disable",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "white",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "white",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "Auto",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "Speed",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "Balance",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "Accuracy",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "Auto",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "Lock",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "white",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "yellow",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "Auto",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "Level1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "Level2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "Level3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "Level4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "Level5",
    "gui.blocklyText.motucamera.setHighFPSOff": "OFF",
    "gui.blocklyText.motucamera.setHighFPSOn": "ON",
    "gui.blocklyText.motucamera.colorDetectBlack": "black",
    "gui.blocklyText.motucamera.colorDetectWhite": "white",
    "gui.blocklyText.motucamera.colorDetectRed": "red",
    "gui.blocklyText.motucamera.colorDetectYellow": "yellow",
    "gui.blocklyText.motucamera.colorDetectGreem": "green",
    "gui.blocklyText.motucamera.colorDetectCayan": "cayan",
    "gui.blocklyText.motucamera.colorDetectBlue": "blue",
    "gui.blocklyText.motucamera.colorDetectPerple": "perple",
    "gui.blocklyText.motucamera.colorDetectOthers": "others",
    "gui.blocklyText.motucamera.colorDetectPositionX": "x position",
    "gui.blocklyText.motucamera.colorDetectPositionY": "y position",
    "gui.blocklyText.motucamera.colorDetectPositionW": "width",
    "gui.blocklyText.motucamera.colorDetectPositionH": "height",
    "gui.blocklyText.motucamera.colorDetectPositionL": "label",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "red channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "green channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "blue channel",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "label channel",
    "gui.blocklyText.motucamera.shapeCardTick": "tick",
    "gui.blocklyText.motucamera.shapeCardCross": "cross",
    "gui.blocklyText.motucamera.shapeCardCircle": "circle",
    "gui.blocklyText.motucamera.shapeCardSquare": "square",
    "gui.blocklyText.motucamera.shapeCardTriangle": "triangle",
    "gui.blocklyText.motucamera.trafficCardForward": "Forward",
    "gui.blocklyText.motucamera.trafficCardLeft": "Left",
    "gui.blocklyText.motucamera.trafficCardRight": "Right",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "Turn Around",
    "gui.blocklyText.motucamera.trafficCardStop": "Stop",
    "gui.blocklyText.motucamera.lightSensorUse": "mu camera [MU] light sensor enable [LIGHTFUNC] detection",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "mu camera [MU] light sensor sensitivity [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "mu camera [MU] light sensor read proximity detection",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "mu camera [MU] light sensor read ambient light detection",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "mu camera [MU] light sensor read once gesture and saved",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "mu camera [MU] light sensor gesture =  [GESTURE]",
    "gui.blocklyText.motucamera.upward": "upward",
    "gui.blocklyText.motucamera.downward": "downward",
    "gui.blocklyText.motucamera.leftward": "leftward",
    "gui.blocklyText.motucamera.rightward": "rightward",
    "gui.blocklyText.motucamera.pull": "pull",
    "gui.blocklyText.motucamera.push": "push",
    "gui.blocklyText.motucamera.none": "none",
    "gui.blocklyText.motucamera.default": "default",
    "gui.blocklyText.motucamera.low": "low",
    "gui.blocklyText.motucamera.middle": "middle",
    "gui.blocklyText.motucamera.high": "high",
    "gui.blocklyText.motucamera.proximity": "proximity",
    "gui.blocklyText.motucamera.ambientLight": "ambient light",
    "gui.blocklyText.motucamera.gesture": "gesture",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi initialize port [SERIALPORT] RX[RX] TX[TX] UART baudrate [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi set ssid [SSID] password [PASSWORD] mode [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi conection succeeded？",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi disconnect",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi set target IP [IP] port [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi read target IP",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi read local IP",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi read",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi Write [WRITE]",
    "gui.blocklyText.dfr0552.init": "DA module init I2C address [ADDR] voltage reference [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "DA module output triangular wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "DA module output sine wave  amplitude [AMPLITUDE]mV freq [FREQ]Hz dc bias [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "DA module output voltage [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "DA module memory voltage [VOLTAGE]mV",
    "gui.blocklyText.sen0117.init": "Initialize module interface [SERIALPORT] Rx[RX] Tx[TX] Busy[BUSY]",
    "gui.blocklyText.sen0117.playText": "Plays text [TEXT] background music [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "Plays voice prompt [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "Plays chord prompts [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "Set up the [INDEX] to [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "Setting the speech rate [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "Get the speech synthesis module [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "Prospects volume",
    "gui.blocklyText.sen0117.Background": "Background volume",
    "gui.blocklyText.sen0117.Speed": "Speed",
    "gui.blocklyText.sen0117.playChordCueTone": "Plays chord ring tones [INDEX] waiting [WAIT]",
    "gui.blocklyText.sen0117.No": "No (0)",
    "gui.blocklyText.sen0117.setPlayMode": "Set the playback mode to [MODE]",
    "gui.blocklyText.sen0117.pause": "Pause",
    "gui.blocklyText.sen0117.resume": "Resume",
    "gui.blocklyText.sen0117.stop": "Stop",
    "gui.blocklyText.sen0117.isIdle": "Play finish?",
    "gui.blocklyText.dfr0576.selectPort": "Select port [PORT]",
    "gui.blocklyText.dfr0576.begin": "Initialize the I2C cascade module address is [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680 initializes",
    "gui.blocklyText.sen0248.readBME680": "Get the BME680 sensor [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "Temperature (°C)",
    "gui.blocklyText.sen0248.Pressure": "Pressure (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Humidity (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0248.GasResistance": "Gas Resistance (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "initialize the I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Change I2C ultrasonic sensor address [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Read ultrasonic sensor distance(cm)",
    "gui.blocklyText.sen0304.readTemperature": "Read ultrasonic sensor temperature(°C)",
    "gui.blocklyText.sen0307.readDistance": "Read [PIN] analog ultrasonic wave(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 initialization address [ADDR] mode [MODE]",
    "gui.blocklyText.sen0250.Step": "Step",
    "gui.blocklyText.sen0250.Measure": "Measure",
    "gui.blocklyText.sen0250.readAccelerated": "Get the [AXIS] axis Acceleration(g)",
    "gui.blocklyText.sen0250.getStepNumber": "MBI160 get step number",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "Gyro",
    "gui.blocklyText.sen0250.Acc": "Acceleration (g)",
    "gui.blocklyText.sen0224.init": "Initialize the LIS2DH sensor",
    "gui.blocklyText.sen0224.readAccelerated": "Read acceleration value of LIS2DH sensor [AXIS] axis (mg)",
    "gui.blocklyText.dfr0023.readLM35": "read pin [PIN] LM35 temprature(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "when pin [PIN] detected vibration",
    "gui.blocklyText.dfr0028.readTiltSensor": "pin [PIN] detected tilt?",
    "gui.blocklyText.dfr0029.readPress": "pin [PIN] button pressed?",
    "gui.blocklyText.dfr0030.readTouch": "pin [PIN] detected touch?",
    "gui.blocklyText.dfr0033.readMagnetic": "pin [PIN] detected magnet?",
    "gui.blocklyText.dfr0034.readSound": "read pin [PIN] Loudness",
    "gui.blocklyText.dfr0094.readIRReceived": "read pin [PIN] IR data",
    "gui.blocklyText.dfr0094.onReceiveEvent": "When the infrared signal is received Pin [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "address",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "command",
    "gui.blocklyText.midi.midiInit": "Init MIDI module interface [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.midi.midiOutput": "MIDI output channel [CHANNEL] pitch [PITCH] speed [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "read pin [PIN] Carbon Monoxide (CO) sensor",
    "gui.blocklyText.dfr0051.readVoltage": "read pin [PIN] Voltage(V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "read pin [PIN] Piezo Disk Vibration Sensor",
    "gui.blocklyText.dfr0058.readRotation": "read pin [PIN] Rotation Sensor",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "read pin [PIN] JoyStick [AXIS] Axis",
    "gui.blocklyText.dfr0061.readJoyStickButton": "pin [PIN] JoyStick Button(Z) pressed?",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut init data pin [DATAPIN] RTC pin [RTCPIN] cls pin [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Digital tube display segment code 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Digital tube display number [NUM]",
    "gui.blocklyText.dfr0072.dfr0072Dot": "Digital tube display dot [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "true",
    "gui.blocklyText.dfr0072.dotFalse": "false",
    "gui.blocklyText.dfr0072.numOff": "off",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Read lithium battery [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "voltage(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "remainingBattery(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD module init I2C address [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD module read [PIN] voltage (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD module get aisle [AISLE] voltage difference",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 and 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 and 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 and 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 and 3",
    "gui.blocklyText.dfr0588.initPin": "Init temperature pin [TEM] humidity pin [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "get temperature unit [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "get humidity(%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "CELSIUS",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "FAHRENHEIT",
    "gui.blocklyText.dfr0076.readFlameSensor": "read pin [PIN] Flame Sensor",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom get [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom read text name [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom read number name [KEY]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom init i2c address [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom write text [VALUE] name [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom write number [VALUE] name [KEY]",
    "gui.blocklyText.dfr0117.text": "Text",
    "gui.blocklyText.dfr0117.number": "Number",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom delete name [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom serial print all names baud [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom name [KEY] exist?",
    "gui.blocklyText.dfr0117.eepromFormat": "i2c eeprom format",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom name [KEY] is [TYPE]?",
    "gui.blocklyText.dfr0117.nameCount": "Name count",
    "gui.blocklyText.dfr0117.memorySize": "Storage space(byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "Remaining storage space(byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": "read pin [PIN] Triple Axis Accelerometer [AXIS] Axis",
    "gui.blocklyText.sen0018.readInfraredMotion": "read pin [PIN] Digital Infrared motion sensor",
    "gui.blocklyText.sen0014.readDistance": "read pin [PIN] Sharp IR Distance Sensor(mm)",
    "gui.blocklyText.sen0114.readMoisture": "read pin [PIN] Soil Moisture Sensor",
    "gui.blocklyText.sen0121.readSteam": "read pin [PIN] Steam Sensor",
    "gui.blocklyText.sen0204.readLiquidLevel": "pin [PIN] liquid level reached a certain height ?",
    "gui.blocklyText.sen0203.heartRate": "heart rate(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "blood oxygen saturation(HbO2)",
    "gui.blocklyText.sen0202.up": "Up",
    "gui.blocklyText.sen0202.down": "Down",
    "gui.blocklyText.sen0202.left": "Left",
    "gui.blocklyText.sen0202.right": "Right",
    "gui.blocklyText.sen0202.cw": "CW",
    "gui.blocklyText.sen0202.ccw": "CCW",
    "gui.blocklyText.dhtTHSensor.temperature": "temperature(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "moisture(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "pin [PIN] RGB [NUM1] to [NUM2] show color [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "red [RED] green [GREEN] blue [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "pin [PIN]  [NUM1] to [NUM2] RGBs show gradient color from [COLOR1] to [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "pin [PIN] shift pixels by [NUM]",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "pin [PIN] rotate pixels by [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "pin [PIN] [NUM] RGB LEDs",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "pin [PIN] brightness [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "pin [PIN] clear all LEDs",
    "gui.blocklyText.ws2812.showHistogram": "pin [PIN]  [NUM1] to [NUM2] RGB LEDs show histogram  current value: [VALUE]  max value: [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "pin [PIN] LED brightness [BRIGHTNESS]",
    "gui.blocklyText.neopixel.init": "Lamp initialization name [NAME] pin [PIN] number [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Light strip [NAME] [INDEX] Color [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Light strip [NAME] [INDEX] Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.fillColor": "The light strip [NAME] is fully lit and the color is [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Light strip [NAME] All on Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.flush": "Light strip [NAME] settings take effect",
    "gui.blocklyText.neopixel.off": "Light strip [NAME] is off",
    "gui.blocklyText.neopixel.setBrightness": "Light strip [NAME] sets brightness to [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Rainbow light effect [NAME] Quantity [COUNT] Brightness [BRIGHTNESS] Offset [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7(mPython board)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9(mPython box)",
    "gui.blocklyText.dsTSensor.ds18b20": "Read pin [PIN] DS18B20 temperature(°C)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280 initializes",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "read [TYPE] BMP280 sensor",
    "gui.blocklyText.bmpTSensor.atmosPressure": "barometric(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "altitude(m)",
    "gui.blocklyText.bmpTSensor.temperature": "temperature(℃)",
    "gui.blocklyText.huskylens.init": "【HuskyLens】ピン [SETTING] の初期化をします（成功するまで）",
    "gui.blocklyText.huskylens.changeAlgorithm": "【HuskyLens】 [ALGORITHM] アルゴリズムに変更する（成功するまで）",
    "gui.blocklyText.huskylens.requestFirst": "【HuskyLens】データデポジット結果をリクエスト",
    "gui.blocklyText.huskylens.resultGetIsStudy": "【HuskyLens】ID [IDNUM] は学習済み",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "【HuskyLens】ID [IDNUM] の [BOXARROW] が画面上にある",
    "gui.blocklyText.huskylens.resultGetIDParameter": "【HuskyLens】ID [IDNUM] の [TYPE] の [PARAMETER]",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "【HuskyLens】[BOXARROW] が画像上にある",
    "gui.blocklyText.huskylens.resultGetParameter": "【HuskyLens】(Direct)ID [IDNUM] の [TYPE] の [PARAMETER2]",
    "gui.blocklyText.huskylens.resultGetStudyedID": "【HuskyLens】学習IDの総数",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "【HuskyLens】[BOXARROW] の総数",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "【HuskyLens】ID [IDNUM] の [BOXARROW] の総数",
    "gui.blocklyText.huskylens.resultGetIDofWhichParameter": "【HuskyLens】ID [IDNUM] の [BOXNUM] 番目の [TYPE] の [PARAMETER]",
    "gui.blocklyText.huskylens.setIDname": "【HuskyLens】現在のアルゴリズムID [ID] に [NAME] という名前を付ける",
    "gui.blocklyText.huskylens.TakeScreenshot": "【HuskyLens】[TYPE] を撮ってメモリーカードに保存",
    "gui.blocklyText.huskylens.writeOSD": "【HuskyLens】画面 x [X] y [Y] に文字 [NAME] を重ね書き",
    "gui.blocklyText.huskylens.clearOSD": "【HuskyLens】画面に表示された文字を消去",
    "gui.blocklyText.huskylens.learnOnce": "【HuskyLens】ID [ID] を自動学習する（1回）",
    "gui.blocklyText.huskylens.clearLearn": "【HuskyLens】現在のアルゴリズムのすべての学習データを消去",
    "gui.blocklyText.huskylens.loadAndSaveModel": "HuskyLens [TYPE] current algorithm data as SD card No.[INDEX] model",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.A": "写真",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.B": "スクリーンショット",
    "gui.blocklyText.huskylens.LOADSAVE.A": "保存",
    "gui.blocklyText.huskylens.LOADSAVE.B": "読み込み",
    "gui.blocklyText.huskylens.resultGetNearCenter": "【HuskyLens】中心付近の [TYPE] の [PARAMETER2]",
    "gui.blocklyText.huskylens.box": "ボックス",
    "gui.blocklyText.huskylens.arrow": "アロー",
    "gui.blocklyText.huskylens.faceRecognition": "顔認識",
    "gui.blocklyText.huskylens.objectTracking": "オブジェクト追跡",
    "gui.blocklyText.huskylens.objectRecognition": "オブジェクト認識",
    "gui.blocklyText.huskylens.lineTracking": "ライントラッキング",
    "gui.blocklyText.huskylens.colorRecognition": "色認識",
    "gui.blocklyText.huskylens.tagRecognition": "ラベル認識",
    "gui.blocklyText.huskylens.objectClassification": "オブジェクト分類",
    "gui.blocklyText.huskylens.x": "X中心",
    "gui.blocklyText.huskylens.y": "Y中心",
    "gui.blocklyText.huskylens.w": "オブジェクトの幅",
    "gui.blocklyText.huskylens.h": "オブジェクトの高さ",
    "gui.blocklyText.huskylens.x1": "X始点",
    "gui.blocklyText.huskylens.y1": "Y始点",
    "gui.blocklyText.huskylens.x2": "X終点",
    "gui.blocklyText.huskylens.y2": "Y終点",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "通信方式",
    "gui.blocklyText.huskylens.address": "アドレス",
    "gui.blocklyText.huskylens.sdaGreen": "SDA(緑)",
    "gui.blocklyText.huskylens.sclBlue": "SCL(青)",
    "gui.blocklyText.huskylens.rxGreen": "Rx(緑)",
    "gui.blocklyText.huskylens.txBlue": "Tx(青)",
    "gui.blocklyText.ps2.initPs2Handle": "init PS2 handle DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] setRunmble [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2 open runmble [RUMBLE] strength [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "PS2 reflush states",
    "gui.blocklyText.ps2.ps2ButtonState": "PS2 button [BUTTON] state is [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2 joystick [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "ON",
    "gui.blocklyText.ps2.OFF": "OFF",
    "gui.blocklyText.ps2.Triangle": "Triangle",
    "gui.blocklyText.ps2.Circle": "Circle",
    "gui.blocklyText.ps2.Cross": "Cross",
    "gui.blocklyText.ps2.Square": "Square",
    "gui.blocklyText.ps2.Left1": "Left 1",
    "gui.blocklyText.ps2.Left2": "Left 2",
    "gui.blocklyText.ps2.Left3": "Left 3",
    "gui.blocklyText.ps2.Right1": "Right 1",
    "gui.blocklyText.ps2.Right2": "Right 2",
    "gui.blocklyText.ps2.Right3": "Right 3",
    "gui.blocklyText.ps2.Up": "Up",
    "gui.blocklyText.ps2.Right": "Right",
    "gui.blocklyText.ps2.Down": "Down",
    "gui.blocklyText.ps2.Left": "Left",
    "gui.blocklyText.ps2.Select": "Select",
    "gui.blocklyText.ps2.Start": "Start",
    "gui.blocklyText.ps2.Hold": "Hold",
    "gui.blocklyText.ps2.Pressed": "Pressed",
    "gui.blocklyText.ps2.Released": "Released",
    "gui.blocklyText.ps2.CHANGE": "CHANGE",
    "gui.blocklyText.ps2.RightX": "Right X",
    "gui.blocklyText.ps2.RightY": "Right Y",
    "gui.blocklyText.ps2.LeftX": "Left X",
    "gui.blocklyText.ps2.LeftY": "Left Y",
    "gui.blocklyText.sen0236.bme280Init": "BME280 initializes",
    "gui.blocklyText.sen0236.readbme280Sensor": "Read I2C pin BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "Temperature(℃)",
    "gui.blocklyText.sen0236.Humidity": "Humidity(%rh)",
    "gui.blocklyText.sen0236.Altitude": "Altitude(m)",
    "gui.blocklyText.sen0236.Pressure": "Pressure(Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "initialize I2C LCD screen address [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "display [TEXT] in LCD line [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "display [TEXT] at LCD position X: [X] Y: [Y] ",
    "gui.blocklyText.LCD1602.displayClear": "clear the screen ",
    "gui.blocklyText.IICScan.readICCAddr": "read scanned I2C device address",
    "gui.blocklyText.mpyfile.initFile": "init file [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsList": "read file [FILENAME] as list, use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.writeFile": "write to file [FILENAME] data [WRITEDATA],use [SEPARATE] Separate",
    "gui.blocklyText.mpyfile.enter": "enter",
    "gui.blocklyText.mpyfile.comma": "comma",
    "gui.blocklyText.mpyfile.semicolon": "semicolon",
    "gui.blocklyText.mpyfile.space": "space",
    "gui.blocklyText.sdcard.initCS": "SD film pin [CSPIN] initialization successful?",
    "gui.blocklyText.sdcard.readFileNLine": "SD reads file [FILE] line [LINE]",
    "gui.blocklyText.sdcard.readFileAll": "SD reads file [FILE] all content",
    "gui.blocklyText.sdcard.writeFile": "SD write file [FILE] content [MSG] mode [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD delete file [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD get file [FILE] info [INFO]",
    "gui.blocklyText.sdcard.NAME": "NAME",
    "gui.blocklyText.sdcard.PATH": "PATH",
    "gui.blocklyText.sdcard.TYPE": "TYPE",
    "gui.blocklyText.sdcard.LINE": "LINE",
    "gui.blocklyText.sdcard.SIZE": "SIZE",
    "gui.blocklyText.sdcard.APPEND": "APPEND",
    "gui.blocklyText.sdcard.REPLACE": "REPLACE",
    "gui.blocklyText.sdcard.WARP": "WARP",
    "gui.blocklyText.sdcard.NOWARP": "NOWARP",
    "gui.blocklyText.sdcard.lsFile": "SD serial port prints all file information baud [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "initialize software serial [SERIALPORT] RX: [PINR] TX: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "software serial [SERIALPORT] baud rate [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "software serial [SERIALPORT] output [TEXT] [WAY], [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "Is there data to read on software serial [SERIALPORT]?",
    "gui.blocklyText.softSerialport.readSerialportData": "read software serial[SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "read [TYPE] on software serial [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "on bluetooth connected",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "on bluetooth disconnected",
    "gui.blocklyText.bluetooth.receivedKey": "when bluetooth received key [KEY] [TYPE]",
    "gui.blocklyText.bluetooth.string": "String",
    "gui.blocklyText.bluetooth.number": "Number",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "when bluetooth received data and meet [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "send string key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "send number key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.openService": "open bluetooth service",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "received data from bluetooth",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo Starts",
    "gui.blocklyText.leonardo.setBaud": "set [SERIAL] baud rate to [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "from [SERIAL] read serial data int or float [READTYPE]",
    "gui.blocklyText.leonardo.serialPort1": "SerialPort0",
    "gui.blocklyText.leonardo.serialPort2": "SerialPort1",
    "gui.blocklyText.leonardo.dataReadable": "Is there data to read on [SERIAL]?",
    "gui.blocklyText.leonardo.readSerialData": "read [SERIAL] data",
    "gui.blocklyText.arduinounoR3.initTcs34725": "Initializes the TCS34725 color sensor",
    "gui.blocklyText.arduinounoR3.functionStarts": "Uno kit program starts",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "Initializes the TMI1650 display",
    "gui.blocklyText.arduinounoR3.readdigital": "read pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "Digital Sensor",
    "gui.blocklyText.arduinounoR3.buttonModule": "Button Module",
    "gui.blocklyText.arduinounoR3.stickButton": "Rocker Button",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "Vibration Sensor",
    "gui.blocklyText.arduinounoR3.readAnalog": "read pin [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "Anolog Sensor",
    "gui.blocklyText.arduinounoR3.lightSensor": "Light Sensor",
    "gui.blocklyText.arduinounoR3.soundSensor": "Sound Sensor",
    "gui.blocklyText.arduinounoR3.stickDirection": "Rocker Direction",
    "gui.blocklyText.arduinounoR3.angleSensor": "Angle Sensor",
    "gui.blocklyText.arduinounoR3.graySensor": "Grayscale Sensor",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "Soil Moisture Sensor",
    "gui.blocklyText.arduinounoR3.open": "オン",
    "gui.blocklyText.arduinounoR3.close": "オフ",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "Digital Actuator",
    "gui.blocklyText.arduinounoR3.ledLights": "LED Lights",
    "gui.blocklyText.arduinounoR3.fanModule": "Fan Module",
    "gui.blocklyText.arduinounoR3.recordModule": "Record Module",
    "gui.blocklyText.arduinounoR3.relay": "Relay",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "Reading ultrasonic distance(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "Simulation Actuator",
    "gui.blocklyText.arduinounoR3.writeAnalog": " set pin [PIN] [MODULE] to [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "move forward",
    "gui.blocklyText.arduinounoR3.backward": "move backward",
    "gui.blocklyText.arduinounoR3.turnLeft": "turn left",
    "gui.blocklyText.arduinounoR3.turnRight": "turn right",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "robot [DIR] at [SPEED] % speed ",
    "gui.blocklyText.arduinounoR3.motorForward": "move forward",
    "gui.blocklyText.arduinounoR3.motorReverse": "move backward",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "motor [MOTOR] [DIR] at [SPEED] % speed ",
    "gui.blocklyText.arduinounoR3.stopMotor": "robot (MA and MB motors) stop moving",
    "gui.blocklyText.arduinounoR3.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "Infrared emission [TYPE] pin [PIN] value [VALUE] bit [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "infrared pin [PIN] received the [BUTTON] pressed signal？",
    "gui.blocklyText.arduinounoR3.noSound": "no sound",
    "gui.blocklyText.arduinounoR3.half": "Half",
    "gui.blocklyText.arduinounoR3.quarter": "Quarter",
    "gui.blocklyText.arduinounoR3.oneInEighth": "One-eighth",
    "gui.blocklyText.arduinounoR3.wholeBeat": "Whole Beat",
    "gui.blocklyText.arduinounoR3.doubleBeat": "Double Beat",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "pin [PIN] play trumpet tone [TONE] for [BEAT] beat",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "set pin [PIN] MP3 module volume to [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "set pin [PIN] MP3 module playing mode to [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "Stop",
    "gui.blocklyText.arduinounoR3.play": "Play",
    "gui.blocklyText.arduinounoR3.loop": "Loop",
    "gui.blocklyText.arduinounoR3.random": "Random",
    "gui.blocklyText.arduinounoR3.pause": "Pause",
    "gui.blocklyText.arduinounoR3.previous": "Previous",
    "gui.blocklyText.arduinounoR3.next": "Next",
    "gui.blocklyText.arduinounoR3.VolumePlus": "Volume+",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "Volume-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "pin [PIN] MP3 module play the [NUM] song",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "read pin [PIN] LM35 temperature(°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "read pin [PIN] DHT11 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "red",
    "gui.blocklyText.arduinounoR3.blue": "blue",
    "gui.blocklyText.arduinounoR3.green": "green",
    "gui.blocklyText.arduinounoR3.readColorSensor": "read TCS34725 color sensor [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "four-bit nixie tube TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "turn on",
    "gui.blocklyText.arduinounoR3.tm1650Close": "turn off",
    "gui.blocklyText.arduinounoR3.clearScreen": "clear screen",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": " 4-bit nixie tube TM1650 display string [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "[STATUS] the [ORDER] decimal point of 4-bit nixie tube TM1650",
    "gui.blocklyText.arduinounoR3.bright": "brighten",
    "gui.blocklyText.arduinounoR3.dark": "darken",
    "gui.blocklyText.arduinounoR3.setLightColor": "pin [PIN] the [NUM] LED display [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "pin [PIN] the [NUM] LED R:[R] G:[G] B:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "pin [PIN] [NUM] LEDs",
    "gui.blocklyText.arduinounoR3.ledControl": "LED matrix (max7219) initial parameter DIN pin [DIN], CS pin [CS], CLK pin [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "LED matrix (max7219) show pattern [PIC]",
    "gui.blocklyText.max.maxStart": "Max starts",
    "gui.blocklyText.max.onboardButtonPressed": "onboard button [PRESSED]?",
    "gui.blocklyText.max.pressed": "pressed",
    "gui.blocklyText.max.notPressed": "not pressed",
    "gui.blocklyText.max.all": "-1 ALL",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "set RGB lights [TYPE] color [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "read sound sensor",
    "gui.blocklyText.max.playSoundEffect": "play sound effect[SOUND]",
    "gui.blocklyText.max.setDPinValue": "set digital pin [PIN] to [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "read digital pin [PIN]",
    "gui.blocklyText.max.readAPinValue": "read analog pin [PIN]",
    "gui.blocklyText.max.setServo": "set pin [PIN] servo to [DEGREE] degree",
    "gui.blocklyText.max.readLinefindingSensor": "read [DIR] line-tracking sensor",
    "gui.blocklyText.max.left": "Left",
    "gui.blocklyText.max.middle": "Middle",
    "gui.blocklyText.max.right": "Right",
    "gui.blocklyText.max.setLineFidingCarLights": "car LED lights [SWITCH]",
    "gui.blocklyText.max.on": "on",
    "gui.blocklyText.max.off": "off",
    "gui.blocklyText.max.readUltrasoundSensor": "read ulrasonic sensor(cm)",
    "gui.blocklyText.max.readLightSensor": "read [LEFTRIGHT] light sensor",
    "gui.blocklyText.max.maxSpeedMove": "MAX move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] move [DIR] at [SPEED] speed",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] stops",
    "gui.blocklyText.max.showUserDefineExpressions": "show pattern [EXPRESSION] in [COLOR]",
    "gui.blocklyText.max.clear": "clear",
    "gui.blocklyText.max.do": "Do",
    "gui.blocklyText.max.re": "Re",
    "gui.blocklyText.max.mi": "Mi",
    "gui.blocklyText.max.fa": "Fa",
    "gui.blocklyText.max.sou": "So",
    "gui.blocklyText.max.la": "La",
    "gui.blocklyText.max.xi": "Ti",
    "gui.blocklyText.max.highdo": "highdo",
    "gui.blocklyText.max.brake": "brake",
    "gui.blocklyText.max.rebound": "rebound",
    "gui.blocklyText.max.bullets": "bullets",
    "gui.blocklyText.max.longecho": "long echo",
    "gui.blocklyText.max.playfulending": "ending",
    "gui.blocklyText.max.yo": "yo",
    "gui.blocklyText.max.wo": "wo",
    "gui.blocklyText.max.robottalking": "robot talking",
    "gui.blocklyText.sen0160.init": "Set Hx711 weight sensor calibration value [VALUE] with Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.sen0160.readValue": "Read Hx711 weight sensor(g) Dout pin [DOUT] SCK pin [SCK]",
    "gui.blocklyText.knowflow.readORP": "read pin [PIN] ORP sensor (mV)",
    "gui.blocklyText.knowflow.readEC": "read pin [PIN] EC sensor (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "read pin [PIN] PH sensor(V1)",
    "gui.blocklyText.knowflow.readECV2": "read pin [PIN] EC sensor (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "read pin [PIN] PH sensor(V2)",
    "gui.blocklyText.knowflow.readDO": "read pin [PIN] DO sensor (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "pin [PIN] Peristaltic Pump rotate [DIR] at [SPEED]% speed",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] pin [PIN] relay",
    "gui.blocklyText.goble.readAxis": "read GOBLE rocker [AXIS] axis",
    "gui.blocklyText.goble.buttonIsPressed": "GOBLE [BUTTON] button pressed?",
    "gui.blocklyText.eeprom.clear": "eeprom clear",
    "gui.blocklyText.eeprom.read": "eeprom read from address [ADDR]",
    "gui.blocklyText.eeprom.write": "eeprom write to address [ADDR] with data [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom get crc from address [ADDRA] to address [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom get length",
    "gui.blocklyText.tds.readAnalogPin": "read analog pin [PIN] TDS sensor(ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "two stepper motors run simultaneously   X axis : [XSET] y axis: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "stepper motor [AXIS] axis  [STEPS] steps per revolution  Speed(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "stepper motor move [ROTATEDIR] for [STEPS] steps",
    "gui.blocklyText.steppermotor.setJointSteps": "two stepper motors run simultaneously. Move [XSTEPS] steps on x-axis [ROTATEDIRX]  Move [YSTEPS] steps on y-axis [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] starts",
    "gui.blocklyText.multithread.stopThread": "stop [THREAD]",
    "gui.blocklyText.multithread.startThread": "start up [THREAD]",
    "gui.blocklyText.multithread.loop1": "thread 1",
    "gui.blocklyText.multithread.loop2": "thread 2",
    "gui.blocklyText.multithread.loop3": "thread 3",
    "gui.blocklyText.multithread.loop4": "thread 4",
    "gui.blocklyText.multithread.loop5": "thread 5",
    "gui.blocklyText.multithread.loop6": "thread 6",
    "gui.blocklyText.multithread.loop7": "thread 7",
    "gui.blocklyText.multithread.loop8": "thread 8",
    "gui.blocklyText.multithread.all": "all",
    "text2speech.setRequestSever": "set sever[SERVER]",
    "text2speech.setSever2Voice": "set server2's voic to [VOICE]",
    "text2speech.setSever2Language": "set server2's language to [LANGUAGE]",
    "text2speech.setSever2Account": "set server2's account[MODAL]",
    "text2speech.international": "server1(international)",
    "text2speech.china": "server2(china)",
    "gui.blocklyText.text2speech.server2voice0": "duxiaomei",
    "gui.blocklyText.text2speech.server2voice1": "duxiaoyu",
    "gui.blocklyText.text2speech.server2voice3": "duxiaoyao",
    "gui.blocklyText.text2speech.server2voice4": "duyaya",
    "gui.blocklyText.text2speech.server2voice106": "dubowen",
    "gui.blocklyText.text2speech.server2voice110": "duxiaotong",
    "gui.blocklyText.text2speech.server2voice111": "duxiaomeng",
    "gui.blocklyText.text2speech.server2voice103": "dumiduo",
    "gui.blocklyText.text2speech.server2voice5": "duxiaojiao",
    "gui.blocklyText.DFR0151.init": "DS1307 initialization",
    "gui.blocklyText.DFR0151.adjustTime": "Adjust the DS1307 time to [YEAR]Year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0151.getTime": "get DS1307 time [INDEX]",
    "gui.blocklyText.DFR0151.Year": "year",
    "gui.blocklyText.DFR0151.month": "month",
    "gui.blocklyText.DFR0151.date": "date",
    "gui.blocklyText.DFR0151.hour": "hour",
    "gui.blocklyText.DFR0151.minute": "minute",
    "gui.blocklyText.DFR0151.second": "second",
    "gui.blocklyText.DFR0151.week": "week",
    "gui.blocklyText.DFR0469.init": "SD2405 initialization",
    "gui.blocklyText.DFR0469.adjustTime": "adjust SD2405 time to [YEAR]year [MONTH]month [DATE]date [HOUR]hour [MINUTE]minute [SECOND]second",
    "gui.blocklyText.DFR0469.getTime": "get SD2405 time [INDEX]",
    "gui.blocklyText.DFR0126.setPin": "The audio analysis module  pins #S[SPIN] #R[RPIN] Analog pin[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "read sound frequency  band[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Initialize the NFC module interface I2C",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Initialize the NFC module interface hardware serial port Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "Initialize the NFC module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "Initialize the NFC module interface [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "NFC module block [DATABLOCK] byte [BYTES] write [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "read NFC module block [DATABLOCK] the [BYTES] byte (number)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "read NFC module block [DATABLOCK] all data (string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "read NFC module block UID (srting)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "detected card UID: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "detected card?",
    "gui.blocklyText.DFR0126.serial1": "serial1",
    "gui.blocklyText.DFR0126.serial2": "serial2",
    "gui.blocklyText.DFR0126.serial3": "serial3",
    "gui.blocklyText.sen0245.init": "Initialize the VL53L0X laser ranging sensor",
    "gui.blocklyText.sen0245.setVL53L0X": "VL53L0X accuracy[PRECISION] measurement mode[VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "get VL53L0X laser ranging value(mm)",
    "gui.blocklyText.SEN0245.LOW": "Low(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "High(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "Single",
    "gui.blocklyText.SEN0245.Continuous": "Continuous",
    "gui.blocklyText.serialMp3.initSerialMp3": "Init serial MP3 module interface [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "set serial MP3 module playing mode to [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "Set serial MP3 module playing [VALUE] song",
    "gui.blocklyText.serialMp3.setMP3Volume": "Set serial MP3 module volume to [VOLUME]%",
    "gui.blocklyText.serialMp3.play": "play",
    "gui.blocklyText.serialMp3.pause": "pause",
    "gui.blocklyText.serialMp3.end": "end",
    "gui.blocklyText.serialMp3.prev": "prev",
    "gui.blocklyText.serialMp3.next": "next",
    "gui.blocklyText.serialMp3.volumeUp": "volume up",
    "gui.blocklyText.serialMp3.volumeDown": "volume down",
    "gui.blocklyText.serialMp3.playlist": "playlist",
    "gui.blocklyText.serialMp3.insertTracks": "insert tracks",
    "gui.blocklyText.serialMp3.volume": "volume",
    "gui.blocklyText.servo360.setServo360": "pin [PIN] servo rotate [DIR] at [SPEED] % speed",
    "gui.blocklyText.servo360.CW": "CW",
    "gui.blocklyText.servo360.CCW": "CCW",
    "gui.blocklyText.TCS34725.initTcs34725": "Initializes the TCS34725 color sensor",
    "gui.blocklyText.TCS34725.readTcs34725": "read TCS34725 color sensor [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "Turn GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "Don\"t turn GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "show text[TEXT] in [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "text scroll [DIR]",
    "gui.blocklyText.regEmotion8_16.left": "left",
    "gui.blocklyText.regEmotion8_16.right": "right",
    "gui.blocklyText.regEmotion8_16.lightAll": "show all dot matrix in [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "show coordinates of point (x:[XAXIS], y:[YXAXIS]) in [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "clear screen",
    "gui.mainHeader.err": "!Error: The same program headers [%1] cannot be used at the same time. Please delete the duplicated block.",
    "gui.mainHeader.help": "!Tip: If multiple programs need to be executed at the same time, use the \"multi-threading function\" in \"Extension\", refer to the help documentation for details.",
    "gui.threadHeader.err1": "!Error: The program headers  [%1] cannot be used at the same time. They belong to different kits or boards.",
    "gui.threadHeader.err2": "!Error Tip: The same event headers [%1] cannot be used at the same time. Please delete the duplicated block.",
    "gui.blocklyText.mqtt.whenReceivedData": "when [TOPIC] received [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT message",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi connect to account:[ACCOUNT] password:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi connected?",
    "gui.blocklyText.wifi.wifiDisConnected": "disconnect Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP mode:[ACCOUNT2] password:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi get soft ap ip",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi get [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Error code",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "ntp set network time, time zone [TIMEZONE] timer server [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "ntp get local time [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "UTCWest12_t",
    "gui.blocklyText.ntp.UTCWest11_t": "UTCWest11_t",
    "gui.blocklyText.ntp.UTCWest10_t": "UTCWest10_t",
    "gui.blocklyText.ntp.UTCWest9_t": "UTCWest9_t",
    "gui.blocklyText.ntp.UTCWest8_t": "UTCWest8_t",
    "gui.blocklyText.ntp.UTCWest7_t": "UTCWest7_t",
    "gui.blocklyText.ntp.UTCWest6_t": "UTCWest6_t",
    "gui.blocklyText.ntp.UTCWest5_t": "UTCWest5_t",
    "gui.blocklyText.ntp.UTCWest4_t": "UTCWest4_t",
    "gui.blocklyText.ntp.UTCWest3_t": "UTCWest3_t",
    "gui.blocklyText.ntp.UTCWest2_t": "UTCWest2_t",
    "gui.blocklyText.ntp.UTCWest1_t": "UTCWest1_t",
    "gui.blocklyText.ntp.UTCMiddle_t": "UTCMiddle_t",
    "gui.blocklyText.ntp.UTCEast1_t": "UTCEast1_t",
    "gui.blocklyText.ntp.UTCEast2_t": "UTCEast2_t",
    "gui.blocklyText.ntp.UTCEast3_t": "UTCEast3_t",
    "gui.blocklyText.ntp.UTCEast4_t": "UTCEast4_t",
    "gui.blocklyText.ntp.UTCEast5_t": "UTCEast5_t",
    "gui.blocklyText.ntp.UTCEast6_t": "UTCEast6_t",
    "gui.blocklyText.ntp.UTCEast7_t": "UTCEast7_t",
    "gui.blocklyText.ntp.UTCEast8_t": "UTCEast8_t",
    "gui.blocklyText.ntp.UTCEast9_t": "UTCEast9_t",
    "gui.blocklyText.ntp.UTCEast10_t": "UTCEast10_t",
    "gui.blocklyText.ntp.UTCEast11_t": "UTCEast11_t",
    "gui.blocklyText.ntp.UTCEast12_t": "UTCEast12_t",
    "gui.blocklyText.ntp.year": "year",
    "gui.blocklyText.ntp.month": "month",
    "gui.blocklyText.ntp.date": "date",
    "gui.blocklyText.ntp.hour": "hour",
    "gui.blocklyText.ntp.minute": "minute",
    "gui.blocklyText.ntp.second": "second",
    "gui.blocklyText.ntp.week": "week",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "when udp server recv [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "set udp server port [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "udp server send broadcast [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "when udp client recv [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "set udp client IP [IPADDR] port [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "udp client send broadcast [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "message",
    "gui.blocklyText.udp.ClientRecvedMsg": "message",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] add key [KEY] value [VALUE]",
    "gui.blocklyText.http.requestHeader": "HTTP request header add [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] URL [URL] timeout [TIMEOUT] ms",
    "gui.blocklyText.http.readMessage": "HTTP read [LINE] line message",
    "gui.blocklyText.http.param": "PARAM",
    "gui.blocklyText.http.header": "HEADER",
    "gui.blocklyText.http.oneline": "ONE",
    "gui.blocklyText.http.allline": "ALL",
    "gui.blocklyText.mqtt.mqttConnect": "connect MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT connected?",
    "gui.blocklyText.mqtt.acceptance.meaage": "When [TOPIC] received the message as [MSG]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.mqtt.initial": "MQTT initial [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「IFLYTEK」synthetic audio [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「IFLYTEK」identify audio [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「IFLYTEK」identify result",
    "gui.blocklyText.iflytek.CommunicationText": "text",
    "gui.blocklyText.iflytek.CommunicationTransfer": "transfer to audio file",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "audio file to be identified",
    "gui.blocklyText.mqtt.mqttDisconnect": "disconnect MQTT",
    "gui.blocklyText.internet.initRequestsType": "requests type",
    "gui.blocklyText.internet.initRequestsAddr": "requests address",
    "gui.blocklyText.internet.initAddDict": "init dict",
    "gui.blocklyText.internet.postFileAddr": "post file address",
    "gui.blocklyText.internet.postFilePath": "post file path",
    "gui.blocklyText.internet.postFileMime": "post file mime type",
    "gui.blocklyText.internet.postFileAddDict": "init dict",
    "gui.blocklyText.internet.internetGetpostInit": "internet initial [SETTING]",
    "gui.blocklyText.internet.initTypeAddr": "internet requests set type [POST] address [ADDR] headers:[HEADERS] params:[URL] data:[DICT]",
    "gui.blocklyText.internet.internetPostFile": "internet post file [SETTING]",
    "gui.blocklyText.internet.postFileAddrType": "POST file requests address [ADDR] path [PATH] MIME type [TYPE]",
    "gui.blocklyText.internet.postFileDict": "POST file set dict data [DICT] and send file",
    "gui.blocklyText.internet.responseContent": "response content form [DATATYPE]",
    "gui.blocklyText.internet.responseAttributes": "response attributes [ATTRIBUTES]",
    "gui.blocklyText.internet.createSocket": "create socket [SOKHANDEL] with af [AFINET] and type [SOCKTYPE]",
    "gui.blocklyText.internet.setSocket": "create socket [SOKHANDEL] with af [SOCKTYPE] and type [TYPENUM]",
    "gui.blocklyText.internet.closeSocket": "socket [SOKHANDEL] close",
    "gui.blocklyText.internet.bindSocket": "socket [SOKHANDEL] bind with address [SOCKADDR] port [SOCKPORT]",
    "gui.blocklyText.internet.listenSocket": "listen socket [SOKHANDEL] backlog number [BACKLOGNUM]",
    "gui.blocklyText.internet.recvReturnTuple": "socket [SOKHANDEL] receive connect requests and return socket tuple",
    "gui.blocklyText.internet.parseReturnIp": "parse host [HOSTADDR] port [SOCKPORT] and return ip",
    "gui.blocklyText.internet.connectHost": "socket [SOKHANDEL] connect host [HOSTADDR] port [SOCKPORT]",
    "gui.blocklyText.internet.sendReturnBytes": "socket [SOKHANDEL] send [SOCKMSG] and return sended bytes",
    "gui.blocklyText.internet.continueSendReturnBytes": "socket [SOKHANDEL] continue send [SOCKMSG] and return sended bytes",
    "gui.blocklyText.internet.sendToAddr": "socket [SOKHANDEL] send [SOCKMSG] to address [SOCKADDR]",
    "gui.blocklyText.internet.recvReturnRecvedObject": "socket [SOKHANDEL] receive and return received object max data is [MAXNUM]",
    "gui.blocklyText.internet.recvReturnRecvedTuple": "socket [SOKHANDEL] receive and return received tuple max data is [MAXNUM]",
    "gui.blocklyText.internet.setTimeout": "socket [SOKHANDEL] set timeout [TIMEOUT]",
    "gui.blocklyText.internet.setMode": "socket [SOKHANDEL] set mode [SOCKMODE]",
    "gui.blocklyText.internet.readReturnObject": "socket [SOKHANDEL] read [READBYTE] and return bytes object",
    "gui.blocklyText.internet.text": "text",
    "gui.blocklyText.internet.binary": "binary",
    "gui.blocklyText.internet.dictionary": "dictionary",
    "gui.blocklyText.internet.block": "block",
    "gui.blocklyText.internet.nonblock": "non-block",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 initializes",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 read altitude(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 read pressure(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 read temperature(℃)",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 measurement [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "object",
    "gui.blocklyText.sen0206.environment": "environment",
    "gui.blocklyText.sen0206.celsius": "celsius",
    "gui.blocklyText.sen0206.fahrenheit": "fahrenheit",
    "gui.blocklyText.sen0253.init": "10DOF Initial pin I2C_ADDR level [LEVEL]",
    "gui.blocklyText.sen0253.reset": "10DOF Sensor reset",
    "gui.blocklyText.sen0253.power": "10DOF Set power mode [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF Get euler angles [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF Get qua [AXIS] axis",
    "gui.blocklyText.sen0253.getAcc": "10DOF Get accelerometer [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF Get magnetometer [AXIS] axis(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF Get linear acceleration [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF Get gravit capacity [AXIS] axis(mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF Get angular speed [AXIS] axis",
    "gui.blocklyText.sen0253.getBmp280": "10DOF Get [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "Low",
    "gui.blocklyText.sen0253.HIGH": "High",
    "gui.blocklyText.sen0253.normal": "Normal",
    "gui.blocklyText.sen0253.lowPower": "Low power",
    "gui.blocklyText.sen0253.suspend": "Suspend",
    "gui.blocklyText.sen0253.Roll": "Roll(°)",
    "gui.blocklyText.sen0253.Pitch": "Pitch(°)",
    "gui.blocklyText.sen0253.Yaw": "Yaw(°)",
    "gui.blocklyText.sen0253.temperature": "Temperature",
    "gui.blocklyText.sen0253.elevation": "Elevation",
    "gui.blocklyText.sen0253.airPressure": "AirPressure",
    "gui.blocklyText.sen0290.init": "lighting sensor set irq pin [IRQPIN] and i2c address [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "lighting sensor lightning sensor iic init ok？",
    "gui.blocklyText.sen0290.manualCal": "lighting sensor set capacitance [CAPACITANCE] mode [MODE] dist [DIST]",
    "gui.blocklyText.sen0290.indoors": "indoors",
    "gui.blocklyText.sen0290.outdoors": "outdoors",
    "gui.blocklyText.sen0290.disturberDis": "disturber disable",
    "gui.blocklyText.sen0290.disturberEn": "disturber enable",
    "gui.blocklyText.sen0290.errMsg": "error msg",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "lighting sensor get status",
    "gui.blocklyText.sen0290.GetLightningDistKm": "lighting sensor get lightning dist(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "lighting sensor get strike energy raw",
    "gui.blocklyText.sen0291.sen0291Init": "The power meter initializes the I2C address as [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "The power meter reads the load [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "The actual measured current of the power meter calibration is [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "Voltage (mV)",
    "gui.blocklyText.sen0291.electric": "Electric (mA)",
    "gui.blocklyText.sen0291.power": "Power (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "Shunt Voltage (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.weather.weatherGetWithCity": "[WEATHER] in [CITY] city [COUNTRY] province",
    "gui.blocklyText.weather.temperaturHigh": "maximum temperature(℃)",
    "gui.blocklyText.weather.temperaturLow": "minimum temperature(℃)",
    "gui.blocklyText.weather.weather": "weather",
    "gui.blocklyText.weather.weatherInit": "weather server Initialize [SETTING]",
    "gui.blocklyText.weather.serverAddr": "server address",
    "gui.blocklyText.weather.appid": "appid",
    "gui.blocklyText.weather.appsecret": "appsecret",
    "gui.blocklyText.weather.serverAddrTianqiAPI": "tianqi API(www.tianqiapi.com)",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "set server parameter [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "update tag [TAG] value [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "read tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "delete tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "count tag",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "network connection test",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "search tag from start number [NUMS] variable count [NUMV] tag [TAG] type [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "tag",
    "gui.blocklyText.tinywebdb.value": "value",
    "gui.blocklyText.tinywebdb.both": "both",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "HongKong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.getSpeech": "recognition result",
    "speech.setRecordTime": "set record time to [TIME]",
    "speech.stopRecord": "stop speech recognition",
    "speech.controlSonogram": "[CONTROL] Sonogram",
    "speech.setServer": "switch speech recognition server to [SERVER]",
    "speech.show": "show",
    "speech.hide": "hide",
    "speech.server1": "server1",
    "speech.server2": "server2",
    "gui.blocklyText.mp3.initMp3": "Init DFPlayer MP3 module interface [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "set DFPlayer MP3 module volume to [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "set DFPlayer MP3 module playing mode to [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "set DFPlayer MP3 module playing [NUM] song",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "set pin [PIN] DFPlayer MP3 module volume to [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "set pin [PIN] DFPlayer MP3 module playing mode to [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "pin [PIN] DFPlayer MP3 module play the [NUM] song",
    "gui.blocklyText.tel0094.GPSInit": "GPS initializes as [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS gets the location [LATLON](°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "GPS gets the location [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "Longitude",
    "gui.blocklyText.tel0094.LONGITUDE": "Latitude",
    "gui.blocklyText.tel0094.YEAR": "Year",
    "gui.blocklyText.tel0094.MONTH": "Month",
    "gui.blocklyText.tel0094.DAY": "Day",
    "gui.blocklyText.tel0094.HOUR": "Hour",
    "gui.blocklyText.tel0094.MINUTE": "Minute",
    "gui.blocklyText.tel0094.SECOND": "Second",
    "gui.blocklyText.tel0094.ALL": "All",
    "gui.blocklyText.tel0094.GPSGet": "GPS gets [GPS]",
    "gui.blocklyText.tel0094.getState": "Status",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "Latitude hemisphere",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "Longitude hemisphere",
    "gui.blocklyText.tel0094.getGroundSpeed": "Ground speed",
    "gui.blocklyText.tel0094.getSatellitesCount": "Number of satellites",
    "gui.blocklyText.tel0094.getAltitude": "Altitude",
    "gui.blocklyText.tel0094.getPdop": "Position accuracy",
    "gui.blocklyText.tel0094.getHdop": "Horizontal accuracy",
    "gui.blocklyText.tel0094.getVdop": "Vertical accuracy",
    "gui.blocklyText.tel0094.getPositioningMode": "Positioning mode",
    "gui.blocklyText.microIoT.displayInLine": "OLED show line [LINE] text [TEXT]",
    "gui.blocklyText.microIoT.displayInXY": "OLED show [TEXT] x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED show image [IMAGE] x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED clear",
    "gui.blocklyText.microIoT.pointDrawing": "OLED draw point x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED set line width [LINEWIDTH]",
    "gui.blocklyText.microIoT.lineDrawing": "OLED draw line start x [X1] y [Y1] end x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED draw circle [FILL] circle center x [X] y [Y] radius [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED draw box [FILL] start x [X] y [Y] width [WIDTH] height [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB set brightness to [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "RGB lights are all off",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "RGB lights [NUM1] through [NUM2] show colors [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "RGB lights [NUM1] to [NUM2] show gradients hue from [COLOR1] to [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "RGB loop by [NUM] pixel",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "RGB lights [NUM1] through [NUM2] show colors [COLOR]",
    "gui.blocklyText.motorIot.all": "ALL",
    "gui.blocklyText.microIot.servo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "MQTT initial parameter [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT send message [MSG] to cloud platform [TOPIC]",
    "gui.blocklyText.microIot.MQTTMsg": "MQTT message",
    "gui.blocklyText.microTouch.whenBtnPress": "when keys [KEY] press",
    "gui.blocklyText.microTouch.Any": "Any",
    "gui.blocklyText.microTouch.isButtonPressed": "Keys [KEY] is pressed?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "Normal mode read keys",
    "gui.blocklyText.microTouch.readKeyMathMode": "Math mode read [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "Number key(num)",
    "gui.blocklyText.microTouch.Function": "Function key(str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] vibration motor",
    "gui.blocklyText.microTouch.turnOn": "Turn on",
    "gui.blocklyText.microTouch.shutDown": "Shut down",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "Sound intensity",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "Natural light",
    "gui.blocklyText.microNaturalScience.readColour": "Read [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "Red value",
    "gui.blocklyText.microNaturalScience.G": "Green value",
    "gui.blocklyText.microNaturalScience.B": "Blue value",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "UV intensity (μW/cm²)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "Turn on",
    "gui.blocklyText.microNaturalScience.off": "Turn off",
    "gui.blocklyText.microNaturalScience.setTDSK": "Set the TDS electrode constant k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "Obtain the TDS electrode constant k",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS(ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "Get [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "Water temperature (°C)",
    "gui.blocklyText.microNaturalScience.Temperature": "Temperature(℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "Humidity(%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "Altitude(m)",
    "gui.blocklyText.microNaturalScience.Pressure": "Pressure(Pa)",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB Set matrix point X [X] Y [Y] show colors [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 can not be placed in the interrupt event, may cause dht11 reading abnormal",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 can not be placed in the interrupt event, may cause dht22 reading abnormal",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 cannot be placed in an interrupt event, which may result in abnormal pm2.5 readings",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 reads data, which may cause led dot matrix initialization failure",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 reads data, which may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "the mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to be set to fail",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "the mp3 setting playback mode cannot be placed in an interrupt event, which may cause the playback mode to fail",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "Mp3 settings playback can not be placed in the interrupt event, may cause the settings to play failed",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "Serial port mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "The serial port mp3 playback mode cannot be placed in an interrupt event, which may cause the setup play mode to be lost",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "Serial port mp3 playback cannot be placed in an interrupt event, which may cause playback to fail",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "The serial port mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to fail",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloq's mqtt initialization soft serial port cannot be placed in an interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq sends a message that cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq read mqtt message can not be placed in the interrupt event, may cause the reception to fail",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "The initial soft serial port of http of obloq cannot be placed in the interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "The get request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "Obloq http post request cannot be placed in an interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "The put request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "Infrared reception cannot be placed in an interrupt event, which may cause reception failure",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "Infrared reception may cause the LED dot matrix initialization to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "Infrared reception may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "Infrared transmission cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause initialization to fail",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause setup pins to fail",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the pin to fail to fail",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause display color to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause color adjustment failure",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the move to fail",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause loop movement to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause the display histogram to fail",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "Led dot matrix can not be placed in the interrupt event, may cause initialization failure",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "The led dot matrix cannot be placed in an interrupt event, which may cause the display pattern to fail",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "The soft serial output cannot be placed in an interrupt event, which may cause the output to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "The data read by the soft serial port cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Soft serial port output data and read data colleague use, may cause data abnormalities",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "The soft serial port read integer cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "Soft serial port output data and reading integer colleague use may cause abnormal reading data",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "When dht11 and PM2.5 are in two threads, dht11 reads may fail if pm2.5 reads too frequently. It is recommended to reduce the pm2.5 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "When dht11 and ds18b20 are in two threads respectively, if ds18b20 reads too frequently, dht11 may fail to read, it is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "When dht11 and mp3 are initialized in two threads, mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "When dht11 and mp3 set volume are in two threads respectively, mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "When dht11 and mp3 setup modes are in two threads respectively, mp3 setup mode may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "When dht11 and mp3 player are in two threads, mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "When dht11 and serial mp3 are initialized in two threads, serial mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "When dht11 and serial mp3 control module are in two threads respectively, the mp3 control module may cause dht11 reading errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "When dht11 and serial mp3 player are in two threads, serial mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "When dht11 and serial mp3 set volume are in two threads respectively, serial mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "When dht11 and RGB are initialized in two threads, RGB initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "When dht11 and RGB set brightness in two threads, RGB set brightness may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "When dht11 and RGB are extinguished in two threads, RGB extinction may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "When dht11 and RGB display colors in two threads, RGB display colors may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "When dht11 and RGB display a gradient in two threads, respectively, RGB display a gradient may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "When dht11 and RGB move pixels in two threads, RGB move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "When dht11 and RGB loop move pixels in two threads, respectively, RGB loop move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "When dht11 and RGB display columns in two threads respectively, RGB display bars may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "When dht11 and soft serial send are in two threads respectively, soft serial send may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "When dht11 and obloq MQTT are initialized in two threads respectively, obloq MQTT initialization may cause dht11 to read errors. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "When dht11 and obloq MQTT send messages in two threads respectively, obloq MQTT send messages that may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "When dht11 and obloq HTTP put requests are in two threads, it can cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "When dht11 and obloq HTTP initialization are in two threads respectively, it may cause dht11 reading error. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "When dht11 and obloq HTTP get requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "When dht11 and obloq HTTP post requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "When dht11 and infrared emission are in two threads respectively, it may cause errors in reading dht11. It is suggested to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "When ds18b20 and PM2.5 are in two threads respectively, it may cause PM2.5 reading error, so it is suggested to reduce ds18b20 frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "When ds18b20 and obloq MQTT are initialized in two threads respectively, MQTT initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "When ds18b20 and obloq HQTT put requests are in two threads respectively, the put request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "When ds18b20 and obloq HTTP initialization are in two threads respectively, the HTTP initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "When ds18b20 and obloq HQTT get requests are in two threads respectively, the get request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "When ds18b20 and obloq HQTT post requests are in two threads respectively, the post request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Reading ds18b20 may result in no infrared data, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Reading ds18b20 may cause errors in receiving data by soft serial port, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "When PM2.5 read and obloq MQTT initialization are carried out in two threads respectively, MQTT initialization failure or PM2.5 read error may be caused. It is recommended to avoid simultaneous execution of MQTT initialization and PM2.5 read",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "When PM2.5 reading and obloq MQTT send messages in two threads respectively, PM2.5 reading errors may be caused. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "When PM2.5 read and obloq HTTP put requests are in two threads, it may cause put request failure or PM2.5 read error. It is recommended to reduce put request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "When PM2.5 reads and obloq HTTP initializations are carried out in two threads respectively, HTTP initializations may fail or PM2.5 reads may go wrong. It is recommended to avoid simultaneous execution of HTTP initializations and PM2.5 reads",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "When PM2.5 read and obloq HTTP get requests are in two threads respectively, it may cause get request failure or PM2.5 read error. It is recommended to reduce get request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "When PM2.5 read and obloq HTTP post request are in two threads respectively, it may cause post request failure or PM2.5 read error. It is recommended to reduce the frequency of post request or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "When PM2.5 reading and MP3 initialization are carried out in two threads respectively, PM2.5 reading may fail. It is recommended to avoid the simultaneous execution of PM2.5 reading and MP3 initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "When PM2.5 reading and MP3 setting volume are in two threads respectively, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "When PM2.5 reading and serial MP3 player are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "When PM2.5 reading and serial MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "When PM2.5 reading and serial MP3 setting volume are respectively in two threads, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "When PM2.5 reading may cause infrared reception failure, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "When PM2.5 reading and RGB initialization are carried out in two threads respectively, PM2.5 reading errors may be caused. It is recommended to avoid simultaneous execution of PM2.5 reading and RGB initialization",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "When PM2.5 reading and RGB setting brightness are respectively in two threads, PM2.5 reading error may be caused. It is recommended not to adjust brightness too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "When PM2.5 reading and RGB extinguishing are in two threads, PM2.5 reading errors may be caused. It is recommended not to extinguish RGB too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "When PM2.5 reading and RGB display colors are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to switch colors too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "When PM2.5 reading and RGB display the gradient in two threads respectively, PM2.5 reading error may be caused, it is recommended not to switch the gradient too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "When PM2.5 reading and RGB moving pixels are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to move pixels too frequently",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "Using Wi-Fi may cause the onboard rgb to display an abnormal color",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "Udp uses Wi-Fi, may cause the onboard rgb display color anomaly",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "Using Wi-Fi may cause the onboard rgb to set a three-color exception",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "Udp uses Wi-Fi, may cause the onboard rgb to set a three-color exception",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "Using Wi-Fi may cause the onboard rgb to set abnormal brightness",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "Udp uses Wi-Fi, may cause the onboard rgb to set the brightness abnormally",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "Using Wi-Fi may cause the onboard rgb to get abnormal brightness",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "Udp uses Wi-Fi, which may cause the onboard rgb to get abnormal brightness",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to be initialized abnormally",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 initialization to be abnormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "Using Wi-Fi may cause serial port mp3 to play track failed",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to play tracks failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "Using Wi-Fi may cause the serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "Using Wi-Fi may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp uses Wi-Fi, which may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "When PM2.5 read and RGB loop move pixels in two threads respectively, PM2.5 read errors may be caused. It is recommended not to execute loop move pixels too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "When PM2.5 reading and RGB display columns are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to execute RGB display columns too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "When PM2.5 reading and led dot array initialization are carried out in two threads respectively, led dot array initialization may go wrong. It is recommended to avoid the simultaneous execution of PM2.5 reading and led dot array initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "When PM2.5 reading and led dot array display are in two threads respectively, led dot array display may be wrong, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5 reading may cause errors in PM2.5 reading. It is recommended not to send data too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5 reading may cause soft serial port reading failure. It is recommended not to read PM2.5 too frequently",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 may not receive infrared data, it is recommended to reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "When MP3 and obloq MQTT initialization is in two threads, it may cause MQTT initialization to fail. It is recommended to avoid simultaneous MQTT initialization and MP3 initialization",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "When MP3 and obloq HTTP put requests are in two threads, it is possible to cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "When MP3 and obloq HTTP initializations are in two threads, HTTP initializations may fail. It is recommended to avoid both HTTP initializations and MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "When MP3 and obloq HTTP get requests are in two threads, it may cause get requests to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "When MP3 and obloq HTTP post requests are in two threads, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 may cause soft serial port read failure, it is recommended not to use MP3 too often",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "Serial MP3 may not receive infrared data, it is recommended to reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "When serial port MP3 and obloq MQTT initialization are in two threads respectively, MQTT initialization may fail. It is recommended to avoid simultaneous execution of MQTT initialization and serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "When serial MP3 and obloq HTTP put requests are in two threads, it may cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "HTTP initialization may fail when serial MP3 and obloq are initialized in two threads. It is recommended to avoid both HTTP initialization and serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "When the serial port MP3 and obloq HTTP get requests are in two threads respectively, it may cause the get request to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "When the serial port MP3 and obloq HTTP post requests are in two threads respectively, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of the serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "Serial port MP3 may cause soft serial port read failure, it is recommended not to use serial port MP3 too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving errors, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "Soft serial obloq may cause errors in infrared receiving data, so it is recommended to avoid sending messages frequently by obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "The initialization of RGB band may lead to the error of obloq receiving messages. It is recommended to avoid frequent use of RGB band",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Setting the brightness of the light belt may cause an error in obloq receiving messages. It is recommended to avoid frequent brightness adjustment",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB belt extinguish may lead to soft serial obloq receiving messages error, it is recommended to avoid frequent extinguish the belt",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB light band display may cause obloq to receive messages error, it is recommended to avoid frequent display of color",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "The display of gradient on the RGB band may cause errors in obloq receiving messages. It is recommended to avoid frequent display of gradient",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB band moving pixels may cause soft serial obloq to receive messages incorrectly",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "The RGB band loops the pixels around and may cause the soft serial obloq to receive messages out",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB ribbon display bar may cause soft serial obloq receive message error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "When soft serial obloq and led dot array initialization are carried out in two threads respectively, it may cause led dot array initialization error. It is recommended to avoid simultaneous execution of soft serial obloq and led dot array initialization",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "When soft serial obloq and led dot array display are respectively in two threads, it may cause led dot array display error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "Soft serial port sending may cause obloq reading error, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "Soft serial port obloq send may cause soft serial port read failure, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "Infrared emission may cause infrared reception of no data, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "When infrared emission may cause soft serial port reading failure, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "The initialization of RGB band may result in no infrared reception of data",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "The brightness setting of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent brightness setting of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "The RGB lamp belt is off, which may cause the infrared data can not be received",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "The moving pixel of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent moving pixel of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "Infrared reception may cause led initialization failure",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "Infrared reception may cause led display errors",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "Soft serial port sending may cause infrared data can not receive, it is recommended to avoid frequent sending soft serial port",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB band may cause soft serial port reading failure, so it is recommended to reduce the use frequency of RGB band",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Data received by soft serial port may cause led dot matrix display error",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "The sending of soft serial port may lead to the error of receiving data of soft serial port. It is recommended to avoid the execution of sending data and receiving data at the same time",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "The initialization of serial port MP3 may lead to errors in infrared receiving data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "MP3 serial port setting playing mode may cause errors in infrared receiving data, so it is recommended to avoid setting playing mode too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "Serial MP3 player may lead to infrared data errors, it is recommended to avoid switching songs too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "The volume setting of serial port MP3 may cause errors in infrared receiving data. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "Initialization of max7219 may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "Max7219 display images may cause errors in infrared received data, so it is recommended to avoid displaying images too frequently",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "The MP3 module of serial port may cause the pin interrupt cannot be triggered. It is recommended to avoid using MP3 module of serial port too often",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "The max7219 display module may cause pin interrupts not to be triggered. It is recommended to avoid using the max7219 display too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "DHT reading may lead to errors in infrared receiving messages. It is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "Ds18b20 reading may cause errors in soft external receiving messages, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5 reading may cause errors in receiving messages outside the software. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "Mp3 initialization read may cause infrared receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "The volume setting of mp3 may cause the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "Mp3 setting mode may cause errors in infrared receiving messages. It is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "Mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "Setting mode of serial port mp3 may cause error of infrared receiving message, it is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "Serial mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Setting the volume of serial port mp3 may lead to the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "Infrared emission may cause errors in infrared receiving messages, so it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "The initialization of the RGB band may lead to errors in receiving infrared messages. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving infrared messages. It is recommended to avoid setting the brightness too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB band display may cause errors in infrared receiving messages. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "Gradient discoloration of RGB lamp band display may lead to error of infrared receiving message. It is recommended to avoid displaying gradient discoloration too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Moving pixels with RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid moving pixels too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "The circular movement of pixels with the RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB ribbon display columnar may cause infrared receiving message error, it is recommended to avoid displaying columnar too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Infrared receiving messages may cause initialization failure of max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Infrared receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "Soft serial printing may cause errors in infrared receiving messages, so it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "Soft serial port obloq MQTT initialization may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "Soft serial obloq put request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "Soft serial port obloq HTTP initialization may lead to infrared receiving data errors, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "Soft serial obloq get request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "Soft serial obloq post request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "The clear display of RGB lamp band may cause errors in receiving messages in the soft infrared. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "DHT reading may lead to soft serial receiving message error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "Ds18b20 reading may cause the soft serial port to receive the message error, it is recommended to reduce the ds18b20 reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5 reading may cause errors in receiving messages by soft serial port. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "Mp3 initialization reads may result in a soft serial receiving message error, and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Setting the volume of mp3 may cause the error of receiving the message on the soft serial port. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "Mp3 setting mode may cause the soft serial port to receive the message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "Mp3 playback may lead to soft serial receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "Mp3 setting mode of serial port may cause error of receiving messages by soft serial port. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "Mp3 playing on serial port may cause errors in receiving messages on soft serial port. It is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message from soft serial port. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "Infrared emission may cause errors in receiving messages by soft serial port. It is recommended to avoid sending infrared messages too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "The initialization of the RGB band may result in an error in receiving messages from the soft serial port. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving messages from the soft serial port. It is recommended to avoid setting the brightness too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "Clear display of RGB light band may cause error of receiving message by soft serial port. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB band display may cause errors in receiving messages from the soft serial port. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB lamp tape display gradient may cause message error in soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause the soft serial port to receive messages incorrectly. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB light belt circular movement of pixels may cause the soft serial port to receive messages error, it is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB ribbon display columnar may cause the soft serial port to receive the message error, it is recommended to avoid performing display columnar too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "Soft serial receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "Soft serial receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "Soft serial printing may cause error in receiving messages from the soft serial port. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "DHT reads may cause pin interrupt triggering failure. It is recommended to reduce the DHT read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "Ds18b20 read may cause pin interrupt trigger failure, it is recommended to reduce ds18b20 read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5 reading may cause pin interrupt trigger failure. It is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Mp3 initialization reads may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "Mp3 setting volume may cause pin interrupt trigger failure. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "Mp3 setting mode may cause pin interrupt triggering failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "Mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "Serial port mp3 initialization read may cause pin interrupt trigger failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "Setting mode of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "Serial mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Setting the volume of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "Infrared emission may cause pin interrupt trigger failure, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB ribbon initialization may cause pin interrupt trigger failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Setting brightness of RGB band may cause pin interrupt trigger failure. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB strip clear display may cause pin interrupt trigger failure, it is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB tape display may cause pin interrupt trigger failure. It is recommended to avoid RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "The RGB tape display of the gradient may cause the pin interrupt trigger to fail. It is recommended to avoid displaying the gradient too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB band moving pixels may cause pin interrupt triggering failure and it is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB light belt loop move pixel may cause pin interrupt trigger failure, it is recommended to avoid too often loop move pixel",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB ribbon display bar may cause pin interrupt trigger failure. It is recommended to avoid displaying bar too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "Pin interruption may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "Pin interruption may cause max7219 to display incorrectly",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "Soft serial printing may cause pin interrupt trigger failure, it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "Soft serial port obloq MQTT initialization may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "Soft serial obloq put requests may cause pin interrupt triggering failure and it is recommended to avoid requesting data too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "Soft serial obloq HTTP initialization may cause pin interrupt triggering failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "Soft serial obloq get request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "Soft serial obloq post request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "DHT reading may lead to soft serial obloq receiving messages error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "Ds18b20 reading may cause obloq to receive messages incorrectly, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5 reading may cause error in obloq receiving messages. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "Mp3 initialization read may result in soft serial obloq receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Mp3 setting volume may result in soft serial obloq receiving message error. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "Mp3 setting mode may lead to soft serial obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "Mp3 playback may lead to soft serial obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port obloq receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "Serial port mp3 setting mode may cause soft serial port obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "Serial port mp3 player may lead to soft serial port obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message of soft serial port obloq. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving messages error, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Soft serial bloq sending messages may lead to infrared reception errors, it is recommended to avoid obloq sending messages too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB ribbon initialization may cause soft serial obloq to receive an error message, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Setting brightness of RGB light belt may cause error of obloq receiving messages. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Clear display of RGB light band may cause error of obloq receiving message. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB tape display may result in an error in obloq receiving messages. It is recommended to avoid performing RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB lamp tape display gradient may cause message error in obloq soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause an error in obloq receiving messages. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB light belt circular movement of pixels may lead to soft serial port obloq receive messages error, it is recommended to avoid too often loop movement of pixels",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB ribbon display columnar may cause soft serial obloq receive messages error, it is recommended to avoid too often display columnar",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "Soft serial obloq receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "Soft serial obloq receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "Soft serial printing may cause obloq to receive messages incorrectly. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "Soft serial port obloq sending data may cause errors in receiving data by soft serial port. It is recommended to avoid too frequent sending of data by obloq",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "Soft serial obloq sending data may cause pin interrupt cannot be triggered, it is recommended to avoid obloq sending data too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "Soft serial port obloq MQTT initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "Soft serial obloq put request may cause the soft serial port to receive data error, it is recommended to avoid too frequent requests for data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "Soft serial port obloq HTTP initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "Soft serial obloq get request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "Soft serial port obloq post request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.compatible.shapedBlock": "The new version changes point: the event program building block with its own variables is replaced by the event program building block without variables.",
    "gui.blocklyText.compatible.shapedBlock.variable": "The new version change point: change from independent program building block to variable program building block in event program",
    "gui.blocklyText.compatible.booleanToRound": "New version change point: Changed from conditional program building block to numerical program building block.",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "The new version changes point: add soft and hard serial port switch drop-down option in the program building block.",
    "gui.blocklyText.compatible.mp3.deletePin": "New version change point: Remove the pin drop-down option in the program building block.",
    "gui.blocklyText.compatible.sen0206.addDropdown": "New version change point: Add drop-down options to the program building block.",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "New version change point: Increase the pin drop-down option in the program building block.",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "New version change point: Modify the serial port drop-down option in the program building block.",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "The new version changes point: changed from the event program building block to the conditional program building block.",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "新しいバージョンの変更点：ブロックの削除",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "The new version changes point: modify the square drop-down box to a circular drop-down box",
    "gui.blocklyText.pictureai.initToken": "init AI of picture[SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "get picture from local file[IMAGE]",
    "gui.blocklyText.pictureai.cameraToggle": "switch to camera [CAMERA_LIST]",
    "gui.blocklyText.pictureai.getVideoImage": "get picture video shortcut",
    "gui.blocklyText.pictureai.webPicture": "get picture from website[TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH]save camera shortcut to local[TEXT]",
    "gui.blocklyText.pictureai.face": "identify picture[TEXT] contain face",
    "gui.blocklyText.pictureai.word": "identify picture[TEXT] contain [SELECT]",
    "gui.blocklyText.pictureai.picture": "identify picture[TEXT] contain[SELECT]",
    "gui.blocklyText.pictureai.gesture": "identify picture[TEXT] contain gestture",
    "gui.blocklyText.pictureai.TOPIC_W_A": "words",
    "gui.blocklyText.pictureai.TOPIC_W_B": "number",
    "gui.blocklyText.pictureai.TOPIC_W_C": "car number",
    "gui.blocklyText.pictureai.TOPIC_W_D": "handwriting",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "left ankle",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "left ear",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "left elbow",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "left eye",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "left hip",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "left knee",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "left mouth corner",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "left shoulder",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "left wrist",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "neck",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "nose",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "right ankle",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "right ear",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "right elbow",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "right eye",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "right hip",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "right knee",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "right mouth corner",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "right shoulder",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "right wrist",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "top head",
    "gui.blocklyText.pictureai.initUserToken": "switch to a separate account[MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "identify picture[TEXT] contain body point",
    "gui.blocklyText.pictureai.faceContrast": "contrast face in picture [TEXT] with picture [TEXT2]",
    "gui.blocklyText.pictureai.faceMerge": "merge face in picture [TEXT] and picture [TEXT2]",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "image body",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "general purpose objects and scenes",
    "gui.blocklyText.pictureai.TOPIC_P_A": "plant",
    "gui.blocklyText.pictureai.TOPIC_P_B": "animal",
    "gui.blocklyText.pictureai.TOPIC_P_C": "flower",
    "gui.blocklyText.pictureai.TOPIC_P_D": "vegetables",
    "gui.blocklyText.pictureai.bodyPointSuccess": "Successful identification of key points in the human body?",
    "gui.blocklyText.pictureai.bodyPointInfo": "get BodyPoint [BODY] [XY]coordinate",
    "gui.blocklyText.pictureai.noPower": "Please check if the account has opened the feature",
    "gui.blocklyText.pictureai.pictureError": "the picture is not in the correct format",
    "gui.blocklyText.pictureai.noPicture": "please select picture first",
    "gui.blocklyText.pictureai.noVideo": "Please make sure that the camera is connected properly and that no other software is occupied, and turn on the camera, or restart the software",
    "gui.blocklyText.pictureai.videoContainer": "use [CON] show camera sceen",
    "gui.blocklyText.pictureai.TOPIC_stage": "ステージ",
    "gui.blocklyText.pictureai.TOPIC_popup": "ポップアップ",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "New version change point: There are program building blocks removed.",
    "gui.blocklyText.pictureai.gestureList": "gesture[TEXT]",
    "gui.blocklyText.pictureai.networkError": "Network error, please check the network connection",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "Successful face recognition?",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "face recognition results [TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "face num",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "age",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "beauty",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "gender",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "glasses",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "type",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "expression",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "face probability",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "left",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "top",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "width",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "height",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "landmark",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "occlusion",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "blur",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "completeness",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "human",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "cartoon",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Forward",
    "gui.blocklyText.maqueenPlus.Backward": "Backward",
    "gui.blocklyText.maqueenPlus.left": "Left",
    "gui.blocklyText.maqueenPlus.right": "Right",
    "gui.blocklyText.maqueenPlus.all": "All",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "set motor [DIR] move by [SPEED] speed [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "set motor [DIR] stop",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] speed compensation [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "read [DIR] motor speed",
    "gui.blocklyText.maqueenPlus.readMoterDir": "read [DIR] motor direction (0:stop 1:forward 2:back)",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "set [DIR] RGB show [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "read the line tracking sensor [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "read the sensation sensor [INDEX] grayscale value",
    "gui.blocklyText.maqueenPlus.getVersion": "get version",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "read ultrasonic sensor trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "when received [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "infrared data",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "gui.blocklyText.esp32.audioRecognition": "get voice command within [TIME] seconds",
    "gui.blocklyText.esp32.audioRecordingFree": "trelease cache of recording",
    "gui.blocklyText.esp32.audioRecordingStart": "record audio with storage path [PATH] and duration [TIME] second(s)",
    "gui.blocklyText.esp32.audioRecordingInit": "initialize recording",
    "gui.blocklyText.esp32.audioPlay": "Audio play [URL]",
    "gui.blocklyText.esp32.audioControl": "Audio [CONTROL]",
    "gui.blocklyText.esp32.AudioControl.A": "PAUSE",
    "gui.blocklyText.esp32.AudioControl.B": "GO ON",
    "gui.blocklyText.esp32.AudioControl.C": "STOP",
    "gui.blocklyText.esp32.audioSetVolume": "set Audio volume as [VOLUME]",
    "gui.blocklyText.esp32.audioFree": "deinit Audio",
    "gui.blocklyText.esp32.audioInit": "initialize Audio",
    "gui.blocklyText.esp32.audio": "audio",
    "gui.blocklyText.esp32.musicGetTempo": "current beats",
    "gui.blocklyText.esp32.musicSetTempo": "set each beat equal to [TICKS] notes, the number of beats per minute is [TEMPO]",
    "gui.blocklyText.esp32.musicSetPlaySpeed": "set the playback speed to [SPEED] times",
    "gui.blocklyText.esp32.musicPlayMelodySet": "play music [MELODY] [WAIT] [LOOP] at pin [PIN]",
    "gui.blocklyText.esp32.musicPlayMelody": "play music [MELODY] pin [PIN]",
    "gui.blocklyText.esp32.musicPlayNoteFrom": "play tone from [MIN] to [AMX] step [STEP] duration [DURATION] ms pin [PIN]",
    "gui.blocklyText.esp32.musicPlayNote": "play tone [NOTE] delay [DELAY] millisecond(s) at pin [PIN]",
    "gui.blocklyText.esp32.musicTone": "tone [NOTE]",
    "gui.blocklyText.esp32.musicPlayNoteContinuous": "play continuous tone [NOTE] at pin [PIN]",
    "gui.blocklyText.esp32.musicPlayList": "play note list [NOTELIST] [WAIT] [LOOP] at pin [PIN]",
    "gui.blocklyText.esp32.IsWait.A": "wait",
    "gui.blocklyText.esp32.IsWait.B": "don't wait",
    "gui.blocklyText.esp32.IsLoop.A": "loop playback",
    "gui.blocklyText.esp32.IsLoop.B": "play once",
    "gui.blocklyText.esp32.getNote": "note [NOTE] beat [BEAT]",
    "gui.blocklyText.esp32.musicPlay": "play note [NOTE] at pin [PIN]",
    "gui.blocklyText.esp32.restore": "restore music settings",
    "gui.blocklyText.esp32.stopPlay": "stop playing music at pin [PIN]",
    "gui.blocklyText.esp32.stopPlay_default": "default",
    "gui.blocklyText.mpyUART.input": "get input text from serial post ,title [TITLE]",
    "gui.blocklyText.mpyUART.close": "close serial port [UART]",
    "gui.blocklyText.mpyUART.readLength": "serial port [UART] read data with length as [LENGTH]",
    "gui.blocklyText.mpyUART.read": "serial port [UART] read data",
    "gui.blocklyText.mpyUART.readline": "serial port [UART] read a lien of data",
    "gui.blocklyText.mpyUART.any": "serial port [UART] has readable data?",
    "gui.blocklyText.mpyUART.writeBytes": "serial port [UART] write bytes [BYTES]",
    "gui.blocklyText.mpyUART.writeByte": "serial port [UART] write a byte [BYTE]",
    "gui.blocklyText.mpyUART.init": "initialize serial port [UART] as baudrate [BAUDRATE] tx [TXPIN] rx [RXPIN]",
    "gui.blocklyText.mpyUART.writeString": "serial port [UART] write string [TEXT] [ENDSYMBOL] wrap",
    "gui.blocklyText.mpyUART.no": "no",
    "gui.blocklyText.mpyUART.auto": "auto",
    "gui.blocklyText.mpyI2C.scan": "I2C scan result",
    "gui.blocklyText.mpyI2C.read": "I2C address [ADDRESS] read bytes number [BYTES]",
    "gui.blocklyText.mpyI2C.write": "I2C address [ADDRESS] is written to [DATA]",
    "gui.blocklyText.mpyI2C.init": "initialize I2C SCL [SCLPIN] SDA [SDAPIN] baudrate [BAUDRATE]",
    "gui.blocklyText.esp32.readPinLevel": "duration(us) of external [LEVEL] pulse level at [PIN]",
    "gui.blocklyText.esp32.noPull": "no pull",
    "gui.blocklyText.esp32.pullDown": "pull down",
    "gui.blocklyText.esp32.pullUp": "pull up",
    "gui.blocklyText.esp32.setPinMode": "set pin [PIN] as [MODE] mode [PULL] and set the value to [LEVEL]",
    "gui.blocklyText.esp32.leakyOutput": "leaky output",
    "gui.blocklyText.mpyServo.setServo": "set the servo [PIN] angle as [ANGLE] pulse width from [MIN] us to [MAX] us actuation range as [ANGLEMAX]",
    "gui.blocklyText.mpyServo.setAngle": "set the servo [PIN] angle as [ANGLE]",
    "gui.blocklyText.esp32.shaked": "shaked",
    "gui.blocklyText.esp32.thrown": "thrown",
    "gui.blocklyText.esp32.tilt_forward": "tilt forward",
    "gui.blocklyText.esp32.tilt_back": "tilt back",
    "gui.blocklyText.esp32.tilt_right": "tilt right",
    "gui.blocklyText.esp32.tilt_left": "tilt left",
    "gui.blocklyText.esp32.tilt_none": "flat",
    "gui.blocklyText.esp32.attitudeEvent": "when the board is [ATTITUDE]",
    "gui.blocklyText.esp32.to_high": "↗ low to high",
    "gui.blocklyText.esp32.to_low": "↘ high to low",
    "gui.blocklyText.esp32.pinLevelEvent": "when pin [PIN] level from [TRANSITION]",
    "gui.blocklyText.mpyTimer.setEvent": "Set timer [INDEX] [MODE] period [PERIOD] milliseconds",
    "gui.blocklyText.mpyTimer.repeated": "repeated",
    "gui.blocklyText.mpyTimer.delayed": "delayed",
    "gui.blocklyText.mpyTimer.conditionEvent": "Set timer [INDEX] :when [CONDITION]",
    "gui.blocklyText.mpyTimer.getCount": "Timer [INDEX] count value",
    "gui.blocklyText.mpyTimer.clearEvent": "Clear timer/event [INDEX]",
    "gui.blocklyText.mpyDictionary.init": "init dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.getValue": "dictionary [CONTENT] value of [KEY]",
    "gui.blocklyText.mpyDictionary.setValue": "set dictionary [CONTENT] value of key [KEY] to [VALUE]",
    "gui.blocklyText.mpyDictionary.deleteKey": "delete dictionary [CONTENT] key [KEY]",
    "gui.blocklyText.mpyDictionary.clear": "clear dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.isInclude": "dictionary [CONTENT] include key [KEY]?",
    "gui.blocklyText.mpyDictionary.getLength": "length of dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.list": "list of [TYPE] in dictionary [CONTENT]",
    "gui.blocklyText.mpyDictionary.TypeIndex.A": "key",
    "gui.blocklyText.mpyDictionary.TypeIndex.B": "value",
    "gui.blocklyText.mpyList.join": "List [LIST] use separators [SYMBOL] to combine text",
    "gui.blocklyText.mpyList.split": "Text [TEXT] use separators [SYMBOL] to make lists",
    "gui.blocklyText.mpyList.init": "init list [CONTENT]",
    "gui.blocklyText.mpyList.clear": "clear list [CONTENT]",
    "gui.blocklyText.mpyList.getLength": "length of list [CONTENT]",
    "gui.blocklyText.mpyList.isEmpty": "[CONTENT] is empty?",
    "gui.blocklyText.mpyList.getValue": "get value at index [INDEX] in list [CONTENT]",
    "gui.blocklyText.mpyList.getValueRange": "return list [CONTENT] takes [TYPE1] [NUM1] items to [TYPE2] [NUM2] items",
    "gui.blocklyText.mpyList.insert": "insert [VALUE] at index [INDEX] in list [CONTENT]",
    "gui.blocklyText.mpyList.setValue": "set index [INDEX] to [VALUE] in list [CONTENT]",
    "gui.blocklyText.mpyList.delete": "delete value at index [INDEX] from list [CONTENT]",
    "gui.blocklyText.mpyList.append": "append [APPEND] into list [CONTENT]",
    "gui.blocklyText.mpyList.extend": "list [LIST1] append list [LIST2]",
    "gui.blocklyText.mpyList.findIndex": "find index of [VALUE] in list [CONTENT]",
    "gui.blocklyText.mpyList.sort": "List [LIST] sort by [TYPE] as [MODE]",
    "gui.blocklyText.mpyList.SortModeIndex.A": "ascending",
    "gui.blocklyText.mpyList.SortModeIndex.B": "descending",
    "gui.blocklyText.mpyList.SortTypeIndex.A": "number",
    "gui.blocklyText.mpyList.SortTypeIndex.B": "letter",
    "gui.blocklyText.mpyList.SortTypeIndex.C": "letter, ignore case",
    "gui.blocklyText.mpySet.init": "init set [CONTENT]",
    "gui.blocklyText.mpySet.update": "update set [CONTENT1] as the [TYPE] with set [CONTENT2]",
    "gui.blocklyText.mpySet.TypeIndex.A": "intersection",
    "gui.blocklyText.mpySet.TypeIndex.B": "union",
    "gui.blocklyText.mpySet.TypeIndex.C": "difference",
    "gui.blocklyText.mpySet.addValue": "update set [CONTENT] with [VALUE]",
    "gui.blocklyText.mpySet.isSubsetSuperset": "set [CONTENT1] is [TYPE] of set [CONTENT2]?",
    "gui.blocklyText.mpySet.SubsetSupersetType.A": "subset",
    "gui.blocklyText.mpySet.SubsetSupersetType.B": "superset",
    "gui.blocklyText.mpySet.intersectionUnionSetDifference": "[TYPE] of set [CONTENT1] and set [CONTENT2]",
    "gui.blocklyText.mpySet.length": "length of set [CONTENT]",
    "gui.blocklyText.mpySet.pop": "return a random item from the set [CONTENT] ,and remove it",
    "gui.blocklyText.mpyTuple.init": "init tuple [CONTENT]",
    "gui.blocklyText.mpyTuple.minMaxLength": "tuple [CONTENT] [TYPE]",
    "gui.blocklyText.mpyTuple.TypeIndex.A": "min value",
    "gui.blocklyText.mpyTuple.TypeIndex.B": "max value",
    "gui.blocklyText.mpyTuple.TypeIndex.C": "length",
    "gui.blocklyText.mpyTuple.isInclude": "tuple [CONTENT] include [STRING]?",
    "gui.blocklyText.mpyTuple.getValueRange": "return tuple [CONTENT] takes [TYPE1] [NUM1] items to [TYPE2] [NUM2] items",
    "gui.blocklyText.mpyTuple.IndexType.A": "#",
    "gui.blocklyText.mpyTuple.IndexType.B": "countdown #",
    "gui.blocklyText.mpyTuple.listToTulpe": "list [LIST] conversion to tuple",
    "gui.blocklyText.mpyTuple.getValue": "get value at index [INDEX] in tuple [CONTENT]",
    "gui.blocklyText.mpyTuple.tupleToList": "convert tuple [CONTENT] into list",
    "gui.blocklyText.esp32.DisplayTypeMenu.A": "normal",
    "gui.blocklyText.esp32.DisplayTypeMenu.B": "rev",
    "gui.blocklyText.esp32.displayInnerImageInXY": "display built-in image [IMAGE] to x:[X] y:[Y] pattern [TYPE]",
    "gui.blocklyText.esp32.displayFileSystemImageInXY": "loading image from file system [IMAGE] show to x:[X] y:[Y] pattern [TYPE]",
    "gui.blocklyText.weather.GetWeatherWithCityName": "get [WEATHER] in [CITY] city",
    "gui.blocklyText.pictureai.createFaceClass": "cretae face class [NAME]",
    "gui.blocklyText.pictureai.pictureaiAddFaceToClass": "named recognition result [RES] and added to the face class [CLASSNAME]",
    "gui.blocklyText.pictureai.pictureaiSelectResultsFromClass": "search recognition results in the face library [CLASSNAME] where reliability >= [NUMBER]",
    "gui.blocklyText.pictureai.pictureaiWhenSearchName": "When the search results were [NAME]",
    "gui.blocklyText.pictureai.pictureaiSearchedName": "name of search results",
    "gui.blocklyText.pictureai.pictureaiSelectResultReliability": "reliability of search results",
    "gui.blocklyText.pictureai.pleaseUseSelefAccount": "face class function switch to separate account",
    "gui.blocklyText.pictureai.addfaceclasserror": "Error creating face class",
    "gui.blocklyText.pictureai.faceclassnameerror": "the name of the face class is composed of Numbers, letters and underscores",
    "gui.blocklyText.pictureai.faceclassnameexist": "class name is already exist",
    "gui.blocklyText.pictureai.addfaceerror": "Error adding face",
    "gui.blocklyText.pictureai.selectfaceclasserror": "Error search face class ",
    "gui.blocklyText.pictureai.notfoundmatch": "match user is not found",
    "boost.color.any": "any color",
    "boost.color.black": "black",
    "boost.color.blue": "blue",
    "boost.color.green": "green",
    "boost.color.red": "red",
    "boost.color.white": "white",
    "boost.color.yellow": "yellow",
    "boost.getMotorPosition": "motor [MOTOR_REPORTER_ID] position",
    "boost.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "boost.motorDirection.backward": "that way",
    "boost.motorDirection.forward": "this way",
    "boost.motorDirection.reverse": "reverse",
    "boost.motorOff": "turn motor [MOTOR_ID] off",
    "boost.motorOn": "turn motor [MOTOR_ID] on",
    "boost.motorOnFor": "turn motor [MOTOR_ID] for [DURATION] seconds",
    "boost.motorOnForRotation": "turn motor [MOTOR_ID] for [ROTATION] rotations",
    "boost.seeingColor": "seeing [COLOR] brick?",
    "boost.setLightHue": "set light color to [HUE]",
    "boost.setMotorDirection": "set motor [MOTOR_ID] direction [MOTOR_DIRECTION]",
    "boost.setMotorPower": "set motor [MOTOR_ID] speed to [POWER] %",
    "boost.tiltDirection.any": "any",
    "boost.tiltDirection.down": "down",
    "boost.tiltDirection.left": "left",
    "boost.tiltDirection.right": "right",
    "boost.tiltDirection.up": "up",
    "boost.whenColor": "when [COLOR] brick seen",
    "boost.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "ev3.beepNote": "beep note [NOTE] for [TIME] secs",
    "ev3.buttonPressed": "button [PORT] pressed?",
    "ev3.getBrightness": "brightness",
    "ev3.getDistance": "distance",
    "ev3.getMotorPosition": "motor [PORT] position",
    "ev3.motorSetPower": "motor [PORT] set power [POWER] %",
    "ev3.motorTurnClockwise": "motor [PORT] turn this way for [TIME] seconds",
    "ev3.motorTurnCounterClockwise": "motor [PORT] turn that way for [TIME] seconds",
    "ev3.whenBrightnessLessThan": "when brightness < [DISTANCE]",
    "ev3.whenButtonPressed": "when button [PORT] pressed",
    "ev3.whenDistanceLessThan": "when distance < [DISTANCE]",
    "wedo2.getDistance": "distance",
    "wedo2.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "wedo2.isTilted": "tilted [TILT_DIRECTION_ANY]?",
    "wedo2.motorDirection.backward": "that way",
    "wedo2.motorDirection.forward": "this way",
    "wedo2.motorDirection.reverse": "reverse",
    "wedo2.motorId.a": "motor A",
    "wedo2.motorId.all": "all motors",
    "wedo2.motorId.b": "motor B",
    "wedo2.motorId.default": "motor",
    "wedo2.motorOff": "turn [MOTOR_ID] off",
    "wedo2.motorOn": "turn [MOTOR_ID] on",
    "wedo2.motorOnFor": "turn [MOTOR_ID] on for [DURATION] seconds",
    "wedo2.playNoteFor": "play note [NOTE] for [DURATION] seconds",
    "wedo2.setLightHue": "set light color to [HUE]",
    "wedo2.setMotorDirection": "set [MOTOR_ID] direction to [MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "set [MOTOR_ID] power to [POWER]",
    "wedo2.tiltDirection.any": "any",
    "wedo2.tiltDirection.down": "down",
    "wedo2.tiltDirection.left": "left",
    "wedo2.tiltDirection.right": "right",
    "wedo2.tiltDirection.up": "up",
    "wedo2.whenDistance": "when distance [OP] [REFERENCE]",
    "wedo2.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "gui.blocklyText.video.notfound": "not found",
    "gui.blocklyText.machinelearning.drawResults": "[TOGGLE]draw recognition results",
    "gui.blocklyText.machinelearning.initKNNImageClass": "KNN 分類器を初期化する",
    "gui.blocklyText.machinelearning.imageClassificationFromCamera": "KNN classify the camera image as[TEXT]",
    "gui.blocklyText.machinelearning.imageClassificationFromDir": "KNN classify the local folder image [DIR] as [TEXT]",
    "gui.blocklyText.machinelearning.startKNNTrain": "KNN 分類トレーニング開始",
    "gui.blocklyText.machinelearning.predictImageClass": "KNN [TOGGLE] カメラ画像の分類を認識",
    "gui.blocklyText.machinelearning.getImageClassification": "KNN 分類結果を認識",
    "gui.blocklyText.machinelearning.getImageClassificationReliability": "KNN 信頼性を認識",
    "gui.blocklyText.machinelearning.clearImageClassificationLabel": "KNN タグ [LABEL] を消去",
    "gui.blocklyText.machinelearning.clearImageClassificationModel": "KNN 分類モデルデータを消去",
    "gui.blocklyText.machinelearning.loadImageClassificationModel": "KNN load the computer-side classification model from [PATH]",
    "gui.blocklyText.machinelearning.exportImageClassificationModel": "KNN save the classification model",
    "gui.blocklyText.machinelearning.traceFace": "FaceAPI initializes face tracking",
    "gui.blocklyText.machinelearning.drawFaceAPI": "FaceAPI[TOGGLE] mark face key points",
    "gui.blocklyText.machinelearning.traceFaceNumber": "FaceAPI number of faces",
    "gui.blocklyText.machinelearning.getFaceByIndex": "FaceAPI get [PART] of  [INDEX] face with coordinate [TYPE]",
    "gui.blocklyText.machinelearning.initfaceContrast": "FaceAPI Initialize specific face recognition",
    "gui.blocklyText.machinelearning.refFaceFromCamera": "FaceAPI collect face data from the camera marked as [NAME]",
    "gui.blocklyText.machinelearning.refFaceFromDir": "FaceAPI collect face data from the folder [DIR] marked as folder name",
    "gui.blocklyText.machinelearning.faceAPIFindFace": "FaceAPI identify the face in the camera once",
    "gui.blocklyText.machinelearning.allowFaceAPIResult": "FaceAPI face recognition results",
    "gui.blocklyText.machinelearning.allowFaceAPIResultScore": "FaceAPI face recognition results credibility",
    "gui.blocklyText.machinelearning.ml5WhenSearchFaceName": "when searching label[NAME] credibility>=[VALUE]%",
    "gui.blocklyText.machinelearning.machinelearningTracePose": "PoseNet initialize the posture tracking",
    "gui.blocklyText.machinelearning.drawPoseNet": "PoseNet[TOGGLE] marking posture key points",
    "gui.blocklyText.machinelearning.getPoseByIndex": "PoseNet get [PART] of [INDEX] posture with coordinate [TYPE]",
    "gui.blocklyText.machinelearning.initImageClassify": "Initialize object recognition image classifier (mobileNet)",
    "gui.blocklyText.machinelearning.toggleImageClassify": "[TOGGLE] Object recognition image classifier Real-time recognition",
    "gui.blocklyText.machinelearning.imageClassifyResult": "Object recognition recognition results",
    "gui.blocklyText.machinelearning.imageClassifyResultScore": "Object recognition recognition credibility",
    "gui.blocklyText.huskylens.initVisualModal": "【HuskyLensEDU】分類子 [TYPE] を初期化します",
    "gui.blocklyText.huskylens.classifyImageFromCamera": "【HuskyLensEDU】コンピューターのカメラ画像を分類子に追加 [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromHuskylens": "【HuskyLensEDU】HuskyLensのカメラ画像を分類子に追加 [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromDir": "【HuskyLensEDU】ローカル画像 [DIR] を分類子に追加 [TEXT]",
    "gui.blocklyText.huskylens.visualModalClearLabel": "【HuskyLensEDU】分類子を消去 [LABEL]",
    "gui.blocklyText.huskylens.visualModalClearAllLabels": "【HuskyLensEDU】すべての分類子を消去",
    "gui.blocklyText.huskylens.deployClassificationToHuskylens": "【HuskyLensEDU】モデルをトレーニングしてHuskyLensにデプロイする",
    "gui.blocklyText.machinelearning.loadlmodel": "model path",
    "gui.blocklyText.pictureai.TOPIC_BODY_mouth": "mouth",
    "gui.blocklyText.pictureai.TOPIC_BODY_jaw_outline": "jaw",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye_brow": "left eye brow",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye_brow": "right eye brow",
    "gui.blocklyText.huskylens.videoContainer": "【HuskyLensEDU】[CON] を使用して、コンピューターのカメラ画面を表示します",
    "gui.blocklyText.huskylens.videoToggle": "【HuskyLensEDU】コンピューターのカメラを [VIDEO_STATE]",
    "gui.blocklyText.huskylens.cameraToggle": "【HuskyLensEDU】コンピューターのカメラに切り替える [CAMERA_LIST]",
    "gui.blocklyText.huskylens.exportImage": "The computer camera take photo and save to local folder [TEXT]",
    "gui.blocklyText.dialog.ml5.loadModel": "Loading the model, the page may appear stuck, please wait...",
    "gui.blocklyText.dialog.ml5.reInitPictureClassify": "the image data already exists in the classifier. Reinitialization will empty the existing data. Confirm to continue?",
    "gui.blocklyText.dialog.ml5.whenDeleteImageAfterTrain": "This image data has been trained, after deletion, need to be retrained to take effect",
    "gui.blocklyText.dialog.ml5.renameLabel": "The tag [LABEL] already exists, do you want to merge it?",
    "gui.blocklyText.dialog.ml5.modelIsEmpty": "There is no classified data in this model. Please train the model first",
    "gui.blocklyText.dialog.ml5.shouldInitKNN": "Please initialize the KNN classification first",
    "gui.blocklyText.dialog.huskylens.HuskyLensTakePhotoError": "HuskyLensからの写真の撮影に失敗しました。もう一度やり直してください",
    "gui.blocklyText.dialog.huskylens.overImageMaxLen": "[TYPE]分類子の最大数は[LEN]です。制限を超えています。並べ替えて、もう一度お試しください",
    "gui.blocklyText.dialog.huskylens.notAllowChinese": "カテゴリ名は50桁以下の数字と文字で構成する必要があり、日本語や特殊文字を含めることはできません",
    "gui.blocklyText.dialog.ml5.isNotKNN": "Face recognition type classifier cannot join KNN training",
    "gui.blocklyText.machinelearning.start": "start",
    "gui.blocklyText.machinelearning.stop": "stop",
    "gui.blocklyText.huskyLensPro.ML5MODEL.A": "KNN分類",
    "gui.blocklyText.huskyLensPro.ML5MODEL.B": "顔認識",
    "gui.blocklyText.huskyLensPro.changeAlgorithm": "【HuskyLensEDU】[ALGORITHM] アルゴリズムに変更する（成功するまで）",
    "gui.blocklyText.huskyLensPro.learndIDCount": "【HuskyLensEDU】学習したIDの総数",
    "gui.blocklyText.huskyLensPro.isOnTheScreen": "【HuskyLensEDU】[BOXARROW] が画面上にある",
    "gui.blocklyText.huskyLensPro.parametersNearTheCenter": "【HuskyLensEDU】中心付近の [BOXARROW] の [PARAMETER]",
    "gui.blocklyText.huskyLensPro.idIsLearned": "【HuskyLensEDU】ID [ID] は学習済み",
    "gui.blocklyText.huskyLensPro.idIsOnTheScreen": "【HuskyLensEDU】ID [ID] の [BOXARROW] が画面上にある",
    "gui.blocklyText.huskyLensPro.idParametersNearTheCenter": "【HuskyLensEDU】ID [ID] の [BOXARROW] の [PARAMETER]",
    "gui.blocklyText.huskyLensPro.boxCount": "【HuskyLensEDU】[BOXARROW] の総数",
    "gui.blocklyText.huskyLensPro.boxarrowParameter": "【HuskyLensEDU】[INDEX] 番目の [BOXARROW] の [PARAMETER]",
    "gui.blocklyText.huskyLensPro.idBoxCount": "【HuskyLensEDU】ID [ID] の [BOXARROW] の総数",
    "gui.blocklyText.huskyLensPro.idBoxarrowParameter": "【HuskyLensEDU】ID [ID] の [INDEX] 番目の [BOXARROW] の [PARAMETER]",
    "gui.blocklyText.huskyLensPro.learnOnce": "【HuskyLensEDU】ID [ID] を自動学習する（1回）",
    "gui.blocklyText.huskyLensPro.clearLearn": "【HuskyLensEDU】現在のアルゴリズムのすべての学習データを消去",
    "gui.blocklyText.huskyLensPro.setIDname": "【HuskyLensEDU】現在のアルゴリズムID [ID] に [NAME] という名前を付ける",
    "gui.blocklyText.huskyLensPro.writeOSD": "【HuskyLensEDU】画面 x [X] y [Y] に文字 [NAME] を重ね書き",
    "gui.blocklyText.huskyLensPro.clearOSD": "【HuskyLensEDU】画面に表示された文字を消去",
    "gui.blocklyText.huskyLensPro.requestPicture": "【HuskyLensEDU】[TYPE] を撮ってローカルフォルダーに保存 [PATH]",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.A": "写真",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.B": "スクリーンショット",
    "gui.blocklyText.huskyLensPro.TakeScreenshotToSDCard": "【HuskyLensEDU】[TYPE] を撮ってメモリーカードに保存",
    "gui.blocklyText.huskyLensPro.xCenter": "X中心",
    "gui.blocklyText.huskyLensPro.yCenter": "Y中心",
    "gui.blocklyText.huskyLensPro.width": "幅",
    "gui.blocklyText.huskyLensPro.height": "高さ",
    "gui.blocklyText.huskyLensPro.xStart": "X始点",
    "gui.blocklyText.huskyLensPro.yStart": "Y始点",
    "gui.blocklyText.huskyLensPro.xEnd": "X終点",
    "gui.blocklyText.huskyLensPro.yEnd": "Y終点",
    "telloesp32.categoryName": "RMTT",
    "gui.blocklyText.telloesp32.arduinoUnoStarts": "RMTT ESP32プログラム スタート",
    "gui.blocklyText.telloesp32.ledControl": "LED制御",
    "gui.blocklyText.telloesp32.matrixControl": "ドットマトリクス制御",
    "gui.blocklyText.telloesp32.flyControl": "飛行制御",
    "gui.blocklyText.telloesp32.customCommand": "カスタムコマンド",
    "gui.blocklyText.telloesp32.pinOperation": "ピン操作",
    "gui.blocklyText.telloesp32.readDigital": "デジタルピン 読込み [PIN]",
    "gui.blocklyText.telloesp32.readAnalog": "アナログピン 読込み [PIN]",
    "gui.blocklyText.telloesp32.setPwm": "PWM [PIN] を [VALUE]にセット",
    "gui.blocklyText.telloesp32.setDigital": "デジタルピン [PIN] を [LEVEL]にセット",
    "gui.blocklyText.telloesp32.functionalModule": "機能モジュール",
    "gui.blocklyText.telloesp32.telloesp32_matrix": "ドットマトリクスを[MARK]にセット",
    "gui.blocklyText.telloesp32.showPatternWhenStart": "起動時のドットマトリクスを[MARK]にセット",
    "gui.blocklyText.telloesp32.setMatrixLight": "ドットマトリクスの明るさ[NUM]にセット",
    "gui.blocklyText.telloesp32.matrixDisplayText": "ドットマトリクス 文字 [TEXT] カラー [COLOR] 明るさ [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayTextMpy": "ドットマトリクス 文字 [TEXT] カラー [COLOR] ",
    "gui.blocklyText.telloesp32.matrixDisplayPreset": "ドットマトリクス プリセット [PATTERN] カラー [COLOR] 明るさ [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayPresetMpy": "ドットマトリクス プリセット [PATTERN] カラー [COLOR] ",
    "gui.blocklyText.telloesp32.empty": "空",
    "gui.blocklyText.telloesp32.WiFi": "WiFi",
    "gui.blocklyText.telloesp32.DownLoad": "ダウンロード",
    "gui.blocklyText.telloesp32.Power": "電源",
    "gui.blocklyText.telloesp32.heart": "ハート",
    "gui.blocklyText.telloesp32.heartOutline": "ハート枠",
    "gui.blocklyText.telloesp32.matrixR": "赤",
    "gui.blocklyText.telloesp32.matrixB": "青",
    "gui.blocklyText.telloesp32.matrixP": "紫",
    "gui.blocklyText.telloesp32.matrixDisplayRollPicture": "マーク スクロール表示 方向[DIRECT] 速度 [SPEED] マーク[MARK]",
    "gui.blocklyText.telloesp32.matrixDisplayRollText": "文字スクロール表示 方向 [DIRECT] 速度 [SPEED] 文字[TEXT] カラー[COLOR]",
    "gui.blocklyText.telloesp32.telloSetWifiChannel": "Wi-Fiチャンネル設定 [CHANNEL]",
    "gui.blocklyText.telloesp32.initTOF": "TOF有効化",
    "gui.blocklyText.telloesp32.readTOF": "TOF (mm)読み取り",
    "gui.blocklyText.telloesp32.buttonIsPressed": "ボタンを押しましたか?",
    "gui.blocklyText.telloesp32.setRgbColor": "LEDカラー [COLOR]",
    "gui.blocklyText.telloesp32.rgbToColor": "LEDカラー R: [RED] G: [GREEN] B: [BLUE]",
    "gui.blocklyText.telloesp32.rgbBreathWithColor": "ふんわり点滅 カラー[COLOR] 周波数(Hz) [TIME]",
    "gui.blocklyText.telloesp32.rgbBreath": "ふんわり点滅 カラーセット R: [RED] G: [GREEN] B: [BLUE] 周波数(Hz): [SP]",
    "gui.blocklyText.telloesp32.rgbFlashingColor": "LED点滅 カラー1 [COLOR1] & カラー2 [COLOR2] 周波数(Hz) [SP]",
    "gui.blocklyText.telloesp32.rgbFlashing": "LED点滅 カラーセット R1: [RED1] G1: [GREEN1] B1: [BLUE1] R2: [RED2] G2: [GREEN2] B2: [BLUE2] 周波数(Hz): [SP]",
    "gui.blocklyText.telloesp32.ledCloseEffects": "LEDエフェクト終了",
    "gui.blocklyText.telloesp32.matrixCloseEffects": "マトリクス エフェクト終了",
    "gui.blocklyText.telloesp32.sendCustomCommand": "カスタムコマンド送信 [CMD]",
    "gui.blocklyText.telloesp32.recvCustomCommand": "カスタムコマンド受信 [MSG], タイムアウト [TIMEOUT]",
    "gui.blocklyText.telloesp32.recvCustomCommandNum": "カスタム値受信, タイムアウト [TIMEOUT]",
    "gui.blocklyText.telloesp32.serialportOperation": "シリアルポート操作",
    "gui.blocklyText.telloesp32.dataReadable": "[SERIAL] 読み込み可能データはありますか?",
    "gui.blocklyText.telloesp32.readSerialData": "[SERIAL] データ 読み込み",
    "gui.blocklyText.telloesp32.setBaud": "シリアル0のボーレート設定[BAUD]",
    "gui.blocklyText.telloesp32.readSerialDataIntFloat": "[SERIAL] より [READTYPE] 型データを読み込み",
    "gui.blocklyText.telloesp32.getNumOfFloat": "[TARGET] の小数点以下の桁数 [NNUMS] ",
    "gui.blocklyText.telloesp32.seriaRemapping": "セット [SERIAL] Rx [RX] Tx [TX] ボーレート [BAUD]",
    "gui.blocklyText.telloesp32.systemResource": "システムリソース",
    "gui.blocklyText.telloesp32.timer": "システム実行時間(ms)",
    "gui.blocklyText.telloesp32.consolePrint": "コンソール出力",
    "gui.blocklyText.telloesp32.print": "プリント [DATA]",
    "gui.blocklyText.telloesp32.telloRiseDown": "[RISEDOWN] [NUMS]cm",
    "gui.blocklyText.telloesp32.rise": "上昇",
    "gui.blocklyText.telloesp32.down": "下降",
    "gui.blocklyText.telloesp32.telloTurnDirection": "方向 [DIRECTION] [NUMS] cm",
    "gui.blocklyText.telloesp32.turnleft": "左",
    "gui.blocklyText.telloesp32.turnright": "右",
    "gui.blocklyText.telloesp32.turnForward": "前方",
    "gui.blocklyText.telloesp32.turnBack": "後方",
    "gui.blocklyText.telloesp32.telloMotorOn": "モーターON",
    "gui.blocklyText.telloesp32.telloMotorOff": "モーターOFF",
    "gui.blocklyText.telloesp32.telloTakeOff": "離陸",
    "gui.blocklyText.telloesp32.telloThrowFly": "Throw & Go (5秒以内にそっとトス)",
    "gui.blocklyText.telloesp32.telloLanding": "着陸",
    "gui.blocklyText.telloesp32.telloClockwiseRotation": "回転 [CLOCKWISE] [NUMS] 度",
    "gui.blocklyText.telloesp32.clockwise": "時計回り",
    "gui.blocklyText.telloesp32.counterClockwise": "半時計回り",
    "gui.blocklyText.telloesp32.telloStartControl": "成功するまで飛行制御をオンにする(LED緑が点灯したらボタンを押す)",
    "gui.blocklyText.telloesp32.telloGetHeight": "取得 [HEIGHT],タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.relatively": "相対高度(dm)",
    "gui.blocklyText.telloesp32.barometer": "気圧計高度(m)",
    "gui.blocklyText.telloesp32.tof": "TOF距離(mm)",
    "gui.blocklyText.telloesp32.telloTemp": "基盤温度(°C),タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.lowest": "最低",
    "gui.blocklyText.telloesp32.highest": "最高",
    "gui.blocklyText.telloesp32.telloAttitude": "IMU 3軸姿勢データ(°), タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.pitch": "ピッチ軸",
    "gui.blocklyText.telloesp32.roll": "ロール軸",
    "gui.blocklyText.telloesp32.translation": "並進軸",
    "gui.blocklyText.telloesp32.telloAcceleration": "加速度(0.001g), タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.xaxis": "X軸",
    "gui.blocklyText.telloesp32.yaxis": "Y軸",
    "gui.blocklyText.telloesp32.zaxis": "Z軸",
    "gui.blocklyText.telloesp32.telloSetSpeed": "速度を [NUMS]cm/s にセット",
    "gui.blocklyText.telloesp32.telloSpeed": "[XYZAXIS] 速度(cm/s), タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloPower": "バッテリーエラー( %), タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.MissionPad": "ミッションパッド, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloMissionPadXyz": "ミッションパッド [XYZ] (cm), タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCommandResponse": "コマンド応答, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSetyawXMid": "Mid[MID] ヨー設定 [YAW] mid",
    "gui.blocklyText.telloesp32.telloGetMissionPadNum": "ミッションパッドID取得, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCurrentSpeed": "現在速度, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloTime": "モーター動作時間 (s), タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloWIFISign": "Wi-Fiサイン, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSDKVersion": "Tello SDKバージョン, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloBinVersion": "Telloファームウェアバージョン, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloHasHardware": "Telloハードウェアバージョン取得, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.rmttWifiVersion": "RMTT Wi-Fiバージョン, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSN": "Tello SNコード, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloFlyToXYZ": "飛行(機体相対) x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s",
    "gui.blocklyText.telloesp32.getSpeed": "現在の設定速度 (cm/s)",
    "gui.blocklyText.telloesp32.getBattery": "バッテリー残量のパーセンテージ取得",
    "gui.blocklyText.telloesp32.getTime": "モーター動作時間を取得",
    "gui.blocklyText.telloesp32.getWifiNoise": "Wi-FiのSNR(信号対雑音比)を取得",
    "gui.blocklyText.telloesp32.getSdkVersion": "SDKバージョンを取得",
    "gui.blocklyText.telloesp32.getSnNum": "SNナンバーを取得",
    "gui.blocklyText.tello.telloStarts": "Tello スタート",
    "gui.blocklyText.tello.telloControlPlane": "制御機体",
    "gui.blocklyText.tello.telloTakeOff": "離陸",
    "gui.blocklyText.tello.telloLanding": "着陸",
    "gui.blocklyText.tello.telloRise": "上昇 [NUMS]cm",
    "gui.blocklyText.tello.telloDown": "下降 [NUMS]cm",
    "gui.blocklyText.tello.telloGoahead": "前方 [NUMS]cm",
    "gui.blocklyText.tello.telloBack": "後方 [NUMS]cm",
    "gui.blocklyText.tello.telloTurnLeft": "左 [NUMS]cm",
    "gui.blocklyText.tello.telloTurnRight": "右 [NUMS]cm",
    "gui.blocklyText.tello.telloClockwiseRotation": "時計回り [NUMS] 度",
    "gui.blocklyText.tello.telloCounterclockwiseSelection": "反時計回り [NUMS] 度",
    "gui.blocklyText.tello.telloSpeed": "速さ [NUMS]cm/s にセット",
    "gui.blocklyText.tello.telloRoll": "宙返り [ROLL]",
    "gui.blocklyText.tello.telloPower": "バッテリー残量( %)",
    "gui.blocklyText.tello.telloRollForward": "前方(f)",
    "gui.blocklyText.tello.telloRollBack": "後方(b)",
    "gui.blocklyText.tello.telloRollLeft": "左(l)",
    "gui.blocklyText.tello.telloRollRight": "右(r)",
    "gui.blocklyText.prompt.telloesp32.rgbnomal_rgbeffects": "ふんわり点滅とLED点滅のブロックを使用する場合、LEDを個別に設定することができないのでブロックを1つ外してください。",
    "gui.blocklyText.prompt.telloesp32.matrixnomal_matrixeffects": "特殊効果はバックグラウンドのスレッドに実装されているため、ドットマトリクスの直接設定と競合します。いずれかのブロックを削除してください。",
    "gui.blocklyText.mpyUART.initTello": "シリアルポート [UART] 初期化 rx [RXPIN] tx [TXPIN] ボーレート [BAUDRATE]",
    "gui.blocklyText.mpyUART.writeStringTello": "シリアルポート [UART] 文字列書込み [TEXT] [ENDSYMBOL]",
    "gui.blocklyText.mpyUART.anyTello": "シリアルポート [UART] は読込み可能なデータを持っていますか?",
    "gui.blocklyText.mpyUART.readTello": "シリアルポート [UART] データ読込み",
    "gui.blocklyText.mpyUART.closeTello": "シリアルポート [UART] を閉じる",
    "gui.blocklyText.tello.telloSetPitchRollYaw": "送信機のスティック量を設定: ロール [ROLL] ピッチ [PITCH] スロットル [STRENGTH] ヨー [YAW]",
    "gui.blocklyText.tello.telloQuerySpeed": "現在の速度",
    "gui.blocklyText.tello.telloTime": "モーター駆動時間(s)",
    "gui.blocklyText.tello.telloWIFISign": "Wi-Fi SNR",
    "gui.blocklyText.tello.telloSDKVersion": "Tello SDKバージョン",
    "gui.blocklyText.tello.telloSN": "Tello SNコード",
    "gui.blocklyText.tello.reset": "リセット",
    "gui.blocklyText.tello.telloFlyCurve": "カーブ x1[X1]cm y1[Y1]cm z1[Z1]cm → x2[X2]cm y2[Y2]cm z2[Z2]cm 速さ ​​[SPEED] cm/s",
    "gui.blocklyText.tello.telloStopEmerygency": "緊急停止",
    "gui.blocklyText.tello.telloGetHeight": "相対高度(m)",
    "gui.blocklyText.tello.telloBarometer": "気圧計高度(m)",
    "gui.blocklyText.tello.telloTofDistance": "TOF高度(cm)",
    "gui.blocklyText.tello.telloTempMin": "最低温度 (°C)",
    "gui.blocklyText.tello.telloTempMax": "最高温度 (°C)",
    "gui.blocklyText.tello.TelloAttitudePitch": "姿勢ピッチ (°)",
    "gui.blocklyText.tello.TelloAttitudeRoll": "姿勢ロール (°)",
    "gui.blocklyText.tello.TelloAttitudeYaw": "姿勢ヨー (°)",
    "gui.blocklyText.tello.telloAccelerationX": "加速度X (0.001g)",
    "gui.blocklyText.tello.telloAccelerationY": "加速度Y (0.001g)",
    "gui.blocklyText.tello.telloAccelerationZ": "加速度Z (0.001g)",
    "gui.blocklyText.tello.telloSpeedX": "速度X (cm/s)",
    "gui.blocklyText.tello.telloSpeedY": "速度Y (cm/s)",
    "gui.blocklyText.tello.telloSpeedZ": "速度Z (cm/s)",
    "gui.blocklyText.tello.telloFlyToMissionPad": "飛行 x[X]cm y[Y]cm z[Z]cm  速度 [SPEED] cm/s Mid [MID]",
    "gui.blocklyText.tello.telloFlyToXY": "飛行(機体相対) x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s",
    "gui.blocklyText.tello.TelloFlyCurveMissionPad": "カーブ x1[X1]cm y1[Y1]cm z1[Z1]cm → x2[X2]cm y2[Y2]cm z2[Z2]cm 速度[SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.telloJumpInMissionPad": "ジャンプ x[X]cm y[Y]cm z[Z]cm 速度 [SPEED]cm/s ヨー [YAW] °  Mid1 [MID1] → Mid2 [MID2]",
    "gui.blocklyText.telloesp32.telloGetStatus": "ステータス確認, タイムアウト [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloReturnStatusChallenge": "結果より [STATUSCHALLENGE] を取得",
    "gui.blocklyText.telloesp32.statusChallengeX": "X座標（チャレンジカード相対）",
    "gui.blocklyText.telloesp32.statusChallengeY": "Y座標（チャレンジカード相対）",
    "gui.blocklyText.telloesp32.statusChallengeZ": "Z座標（チャレンジカード相対）",
    "gui.blocklyText.telloesp32.statusChallengePitch": "ピッチ角（チャレンジカード相対）",
    "gui.blocklyText.telloesp32.statusChallengeYaw": "ヨー角（チャレンジカード相対）",
    "gui.blocklyText.telloesp32.statusChallengeRoll": "ロール角（チャレンジカード相対）",
    "gui.blocklyText.telloesp32.telloReturnStatusAttitude": "結果より [STATUSATTITUDE] を取得",
    "gui.blocklyText.telloesp32.statusAttitudePitch": "ピッチ角",
    "gui.blocklyText.telloesp32.statusAttitudeYaw": "ヨー角",
    "gui.blocklyText.telloesp32.statusAttitudeRoll": "ロール角",
    "gui.blocklyText.telloesp32.statusAttitudeXSpeed": "X軸 速度",
    "gui.blocklyText.telloesp32.statusAttitudeYSpeed": "Y軸 速度",
    "gui.blocklyText.telloesp32.statusAttitudeZSpeed": "Z軸 速度",
    "gui.blocklyText.telloesp32.statusAttitudeXAcc": "X軸 加速度",
    "gui.blocklyText.telloesp32.statusAttitudeYAcc": "Y軸 加速度",
    "gui.blocklyText.telloesp32.statusAttitudeZAcc": "Z軸 加速度",
    "gui.blocklyText.telloesp32.statusChallengeId": "チャレンジカードID",
    "gui.blocklyText.telloesp32.telloReturnStatusNormal": "[STATUSNORMAL] を取得",
    "gui.blocklyText.telloesp32.statusNormalMaxTemp": "最高温度",
    "gui.blocklyText.telloesp32.statusNormalLowTemp": "最低温度",
    "gui.blocklyText.telloesp32.statusNormalTof": "TOF距離",
    "gui.blocklyText.telloesp32.statusNormalHeight": "相対高度",
    "gui.blocklyText.telloesp32.statusNormalBat": "バッテリー残量",
    "gui.blocklyText.telloesp32.statusNormalBaro": "気圧高度",
    "gui.blocklyText.telloesp32.statusNormalMotor": "モーター駆動時間",
    "gui.blocklyText.tello.telloRotateWithYaw": "[MID]に対し機体回転 [YAW] °",
    "gui.blocklyText.tello.telloStopAndHover": "ストップ&ホバリング",
    "gui.blocklyText.tello.MissionPad": "チャレンジカードID",
    "gui.blocklyText.tello.MissionPadX": "チャレンジカード X(cm)",
    "gui.blocklyText.tello.MissionPadY": "チャレンジカード Y(cm)",
    "gui.blocklyText.tello.telloMissionPadZ": "チャレンジカード Z(cm)",
    "gui.blocklyText.tello.telloCommandResponse": "コマンド応答",
    "gui.blocklyText.tello.direction_front": "前方ビジョン",
    "gui.blocklyText.tello.direction_blow": "下方ビジョン",
    "gui.blocklyText.tello.direction_all": "両方",
    "gui.blocklyText.tello.mid": "チャレンジカード",
    "gui.blocklyText.tello.midfastest": "m-1",
    "gui.blocklyText.tello.midlatest": "m-2",
    "gui.blocklyText.tello.telloToggleMon": "[TOGGLE] チャレンジカード検出",
    "gui.blocklyText.tello.telloSetMDireaction": "チャレンジカード検出位置 [POSITION]",
    "gui.blocklyText.tello.telloSetWIFI": "TelloのWi-Fi名変更 SSID [NAME] PASS [PASSWD]",
    "gui.blocklyText.tello.telloToggleGroup": "ステーションモードに移行 SSID [NAME] PASS [PASSWD]",
    "gui.blocklyText.telloGroup.telloRise": "上昇",
    "gui.blocklyText.telloGroup.telloDown": "下降",
    "gui.blocklyText.telloGroup.telloGoahead": "前",
    "gui.blocklyText.telloGroup.telloBack": "後",
    "gui.blocklyText.telloGroup.telloTurnLeft": "左",
    "gui.blocklyText.telloGroup.telloTurnRight": "右",
    "gui.blocklyText.telloGroup.telloSetFly": "[DIRECTION] [NUMS]cm",
    "gui.blocklyText.telloGroup.telloClockwiseRotation": "時計回り",
    "gui.blocklyText.telloGroup.telloCounterclockwiseSelection": "反時計回り",
    "gui.blocklyText.telloGroup.telloClockRotation": "[CLOCKWISE] に回転 [NUMS] 度",
    "gui.blocklyText.telloGroup.setTelloIndexWithSN": "Tello SN[SN] を [INDEX] に設定",
    "gui.blocklyText.telloGroup.setTelloIndexWithSSID": "Tello SSID[SSID] を [INDEX] に設定",
    "gui.blocklyText.telloGroup.telloSynchronousCommand": "同期コマンド 待機時間 [TIME] 秒",
    "gui.blocklyText.tello.telloVideoToggle": "Telloビデオ [VIDEO_STATE]",
    "gui.blocklyText.telloGroup.TEMPH": "最高温度",
    "gui.blocklyText.telloGroup.TEMPL": "最低温度",
    "gui.blocklyText.telloGroup.NUM": "ナンバー",
    "gui.blocklyText.telloGroup.pitch": "ピッチ軸",
    "gui.blocklyText.telloGroup.roll": "ロール軸",
    "gui.blocklyText.telloGroup.translation": "並進軸",
    "gui.blocklyText.telloGroup.telloAccelerationXYZ": "加速度 [XYZ] (0.001g)",
    "gui.blocklyText.telloGroup.telloSpeedXYZ": "速さ [XYZ] (cm/s)",
    "gui.blocklyText.telloGroup.TelloAttitudePitch": "[ANGEL] 姿勢角(°)",
    "gui.blocklyText.telloGroup.telloTempMinAndMax": "メインボード [TEMP](°C)",
    "gui.blocklyText.tello.telloDisplayRollText": "文字スクロール表示 方向 [DIRECT] 速度 [SPEED] 文字列 [TEXT] カラー [COLOR]",
    "gui.blocklyText.tello.telloDisplayText": "1文字表示 [TEXT] カラー [COLOR]",
    "gui.blocklyText.tello.telloRollText_up": "上",
    "gui.blocklyText.tello.telloRollText_down": "下",
    "gui.blocklyText.tello.telloRollText_left": "左",
    "gui.blocklyText.tello.telloRollText_right": "右",
    "gui.blocklyText.telloGroup.initTelloGroup": "接続解除",
    "gui.blocklyText.tello.shutdownForPower": "極度のローバッテリーです [POWER], まもなくシャットダウンします。",
    "gui.blocklyText.tello.portCantUse": "サービスエラー, ポート [PORT] アスセス失敗, 他のプログラムで使用されてないかご確認ください",
    "gui.blocklyText.tello.telloReadESP32Version": "拡張モジュール(ESP32)ファームウェアバージョン",
    "gui.blocklyText.tello.telloSSID": "拡張モジュールSSID",
    "gui.blocklyText.tello.telloLightLEDWithColor": "LED点灯 カラー[COLOR]",
    "gui.blocklyText.tello.telloLightLEDWithColor_rgb": "LED点灯 R:[RGBR] G:[RGBG] B:[RGBB]",
    "gui.blocklyText.tello.telloBreathLEDWithColor": "LEDふんわり点滅 カラー [COLOR] 周波数 [TIME](Hz)",
    "gui.blocklyText.tello.telloBreathLEDWithColor_rgb": "LEDふんわり点滅 カラー R:[RGBR] G:[RGBG] B:[RGBB] 周波数:[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor": "LED点滅 カラー1[COLOR1] & カラー2[COLOR2] 周波数:[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor_rgb": "LED点滅 R1:[RGBR1] G1:[RGBG1] B1:[RGBB1] - R2:[RGBR2] G2:[RGBG2] B2:[RGBB2] 周波数:[TIME](Hz)",
    "gui.blocklyText.tello.telloShowPattern": "パターン表示 [MARK]",
    "gui.blocklyText.tello.telloShowPatternWhenStart": "起動時のパターン [MARK]",
    "gui.blocklyText.tello.telloShowRollPattern": "パターンスクロール 方向 [DIRECT] 速度 [SPEED] パターン [MARK]",
    "gui.blocklyText.tello.telloSetLight": "明るさ設定 [NUM]",
    "gui.blocklyText.tello.telloSendCMD": "カスタムコマンド送信 [CMD]",
    "gui.blocklyText.tello.telloSendDirective": "メッセージ送信 [CMD]",
    "gui.blocklyText.tello.telloReceiveMsg": "受信メッセージ(文字列) [MSG]",
    "gui.blocklyText.tello.telloReceiveCustomNum": "カスタム値の受信",
    "gui.blocklyText.tello.connectTelloWIFIError": "Tello Wi-Fiへの接続に失敗しました。手動でWi-Fiに接続してください",
    "gui.blocklyText.tello.connectTelloError": "Telloへの接続に失敗しました。デバイスの状態を確認して再接続してください。",
    "gui.blocklyText.tello.tempIsHigh": "機体が高温のため、まもなくシャットダウンします",
    "gui.blocklyText.tello.scanErrorWithOpenWlan": "スキャン中にエラーが発生しました。Wi-Fiがオンになっているか確認してください。",
    "gui.blocklyText.tello.noTelloFound": "利用可能なTelloがありません、手動でWi-Fiに接続してみてください。",
    "gui.blocklyText.tello.connectIsBroke": "Telloとの接続が切断されました、デバイスを確認してください",
    "gui.blocklyText.tello.firstConnectTello": "最初にTelloと接続してください",
    "gui.blocklyText.tello.telloQueryWifiVersion": "拡張モジュールのWi-Fiバージョン",
    "gui.blocklyText.tello.telloQueryHardwareVersion": "ハードウェアバージョン",
    "gui.blocklyText.tello.telloToggleTOF": "[TOGGLE] TOFモジュール",
    "gui.blocklyText.tello.telloToggleTOF_enable": "有効",
    "gui.blocklyText.tello.telloToggleTOF_disabled": "無効",
    "gui.blocklyText.tello.activeTelloFirst": "接続に失敗しました。新しい機体はアクティベートされていません。機体のマニュアルに従ってアクティベートしてください",
    "gui.blocklyText.tello.startPaddle": "パドルモードON",
    "gui.blocklyText.tello.stopPaddle": "パドルモードOFF",
    "gui.blocklyText.tello.togglePaddle": "[TOGGLE] パドルモード",
    "gui.blocklyText.tello.telloTogglePaddle_enable": "ON",
    "gui.blocklyText.tello.telloTogglePaddle_disabled": "OFF",
    "gui.blocklyText.tello.telloThrowAway": "Throw & Go (5秒以内にそっとトス)",
    "gui.blocklyText.tello.telloResetPattern": "標準の起動パターンに戻す",
    "gui.blocklyText.tello.telloQueryTelloVersion": "Telloファームウェアバージョン",
    "gui.blocklyText.tello.telloQueryBatActive": "バッテリー残量",
    "gui.blocklyText.tello.telloReadTOF": "TOF測距(mm)",
    "gui.blocklyText.telloGroup.allTellos": "すべての",
    "gui.blocklyText.tello.notAllowInput": "半角英数字を入力してください。その他の特殊文字は入力できません。",
    "gui.blocklyText.tello.checkFirewallAndTello": "回線が切断されています。Telloの接続状態を確認してください。また、コンピュータのファイアウォールのホワイトリストにソフトウェアを追加してください。",
    "gui.blocklyText.telloGroup.checkWlanNum": "本機が正しいネットワークに接続されていることを確認してください。複数のネットワークに接続されている場合は、まず他の接続を解除してください。",
    "gui.blocklyText.telloGroup.telloIsNotExist": "[INDEX] Telloが存在しません",
    "gui.blocklyText.telloGroup.findTelloWithLost": "[FOUND] を発見, [NOTFOUND] は未発見, ip:[IP]",
    "gui.blocklyText.telloGroup.findTello": "[FOUND] を発見, ip:[IP]",
    "gui.blocklyText.telloGroup.cantRepeatScan": "スキャン中, 再スキャンしないで下さい...",
    "gui.blocklyText.telloGroup.setIndexForTelloFirst": "最初に番号をセットしてください",
    "paint.paintEditor.hue": "色",
    "paint.paintEditor.saturation": "鮮やかさ",
    "paint.paintEditor.brightness": "明るさ",
    "gui.comingSoon.message1": "安心してください。開発中です {emoji}",
    "gui.comingSoon.message2": "近日公開",
    "gui.comingSoon.message3": "開発中です {emoji}",
    "paint.paintEditor.fill": "塗りつぶし",
    "paint.paintEditor.costume": "コスチューム",
    "paint.paintEditor.group": "グループ化",
    "paint.paintEditor.ungroup": "グループ解除",
    "paint.paintEditor.undo": "取り消し",
    "paint.paintEditor.redo": "やり直し",
    "paint.paintEditor.forward": "手前に出す",
    "paint.paintEditor.backward": "奥に下げる",
    "paint.paintEditor.front": "最前面",
    "paint.paintEditor.back": "下げる",
    "paint.paintEditor.more": "もっと",
    "paint.modeTools.brushSize": "大きさ",
    "paint.modeTools.eraserSize": "消しゴムの大きさ",
    "paint.modeTools.copy": "コピー",
    "paint.modeTools.paste": "貼り付け",
    "paint.modeTools.delete": "削除",
    "paint.modeTools.curved": "滑らか",
    "paint.modeTools.pointed": "角ばった",
    "paint.modeTools.thickness": "太さ",
    "paint.modeTools.flipHorizontal": "左右反転",
    "paint.modeTools.flipVertical": "上下反転",
    "paint.modeTools.filled": "塗りつぶし",
    "paint.modeTools.outlined": "輪郭",
    "paint.paintEditor.bitmap": "ビットマップに変換",
    "paint.paintEditor.vector": "ベクターに変換",
    "paint.paintEditor.stroke": "枠線",
    "paint.brushMode.brush": "筆",
    "paint.eraserMode.eraser": "消しゴム",
    "paint.fillMode.fill": "塗りつぶし",
    "paint.lineMode.line": "直線",
    "paint.ovalMode.oval": "円",
    "paint.rectMode.rect": "四角形",
    "paint.reshapeMode.reshape": "形を変える",
    "paint.roundedRectMode.roundedRect": "角丸の長方形",
    "paint.selectMode.select": "選択",
    "paint.textMode.text": "テキスト",
    "paint.colorPicker.swap": "入れ替え",
    "gui.dialog.notConnectDevice": "デバイスが接続されていません",
    "gui.dialog.prompt": "ノート",
    "gui.dialog.openPort": "ポートを開く",
    "gui.dialog.error": "エラー",
    "gui.dialog.notSelectDevice": "デバイスが選択されていません。ソフトウェアの左下にある[拡張機能]をクリックしてください。</br>接続するデバイスをクリックして選択してください",
    "gui.dialog.connectDeviceError1": "デバイスの接続(",
    "gui.dialog.connectDeviceError2": "）に失敗しました。以下の手順に従ってください：</br> * USBポートを交換し、USBケーブルを再度差し込みます</br> *接続されているかどうかを確認します",
    "gui.dialog.connectDeviceError3": "メインコントロールボード</br> *問題を報告するには、公式コミュニケーショングループ（671877416）に参加してください",
    "gui.dialog.calibration1": "</br> *回してください",
    "gui.dialog.calibration2": "メインコントロールボードを校正する</br>",
    "gui.dialog.runAsManager": "Mind+を閉じ、ソフトウェアアイコンを右クリックして、[管理者として実行]を選択してください。起動後、再度この機能を選択してください。",
    "gui.dialog.runErrorForVortex": "システムが正常に動作していません。Vortexを切断してください。",
    "gui.dialog.runError": "システムが正常に動作していません。",
    "gui.dialog.close": "閉じる",
    "gui.dialog.variableNameSpecialCharacters": "変数名 %1 に特殊文字を含めることはできません：[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "関数名 %1 に特殊文字を含めることはできません：[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "関数の引数 %1 に特殊文字を含めることはできません：[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "そして",
    "gui.dialog.yes": "はい",
    "gui.dialog.no": "いいえ",
    "gui.dialog.maxReset": "編集エリアのコードを削除し、工場出荷時の設定コードをロードしますか？",
    "gui.dialog.feedbackCannotEmpty": "フィードバックを記入してください。",
    "gui.dialog.noInstallCompiler": "コンパイラがインストールされていません。",
    "gui.dialog.install": "インストール",
    "gui.dialog.cancle": "キャンセル",
    "gui.dialog.installingCompiler": "コンパイラのインストール",
    "gui.dialog.uploading": "アップロード中",
    "gui.dialog.changeLanuage": "言語を切り替えると、現在のプログラムが消去されます。続行してもよろしいですか？",
    "gui.dialog.boardNotSupportMpy": "現在のボード：%1 はmicopythonをサポートしていません。",
    "gui.dialog.loadBlankItem": "サポートされていないプロジェクトです。</br>空のプロジェクトをリロードします。",
    "gui.dialog.switchBaudError": "ボーレートの切り替え中にエラーが発生しました</br>",
    "gui.dialog.turnMicrobitCompass": "電子コンパスを校正する必要があります。すべてのLEDライトがオンになるまで、点滅するLEDライトを移動するようにmciro:bitを傾けて調整してください。",
    "gui.dialog.operationShow": "動作デモ",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "コンピューター",
    "gui.dialog.delete": "削除",
    "gui.dialog.sureDeleteFile": "%1 のファイル [%2] を削除してもよろしいですか？",
    "gui.dialog.sureDeleteFolder": "%1 のフォルダ [%2] を削除してもよろしいですか？",
    "gui.dialog.uncorrectPath": "正しいパスが選択されていません",
    "gui.dialog.laterDownLoad": "後でダウンロード",
    "gui.dialog.dwnLoadWebsite": "ダウンロードするには公式ウェブサイトにアクセスしてください",
    "gui.dialog.laterUpdate": "後でインストール",
    "gui.dialog.update": "インストール",
    "gui.dialog.getUpdateMsgError": "更新されたメッセージの取得中にエラーが発生しました",
    "gui.dialog.ok": "OK",
    "gui.dialog.closeCodeBoxes": "%1 コードボックスを閉じてもよろしいですか？",
    "gui.dialog.emailError": "電子メールの形式が正しくありません！",
    "gui.dialog.emailEmpty": "メールは空にできません！",
    "gui.dialog.bigFilePrompt": "ファイルが大きいです。しばらくお待ちください。ありがとうございます。",
    "gui.dialog.successFeedbackPrompt1": "フィードバックありがとうございます。ページは3秒後にリロードされます。",
    "gui.dialog.successFeedbackPrompt2": "フィードバックが送信されました。数秒後に送信してください。",
    "gui.dialog.failedFeedbackPrompt": "フィードバックを送信できませんでした。ご不便をおかけして申し訳ありません。フィードバック用の公式QQ交換グループを追加することもできます。ありがとうございます",
    "gui.dialog.successFeedbackPrompt3": "フィードバックありがとうございます",
    "gui.dialog.forcedCloseSoftWare": "ファイルの保存中にエラーが発生しました。ソフトウェアを強制終了しますか？",
    "gui.dialog.saveSuccess": "プロジェクトが保存されました",
    "gui.dialog.noSelectTruePath": "正しいパスが選択されていません",
    "gui.dialog.newItemContent": "空のプロジェクトを作成して現在のプロジェクトを上書きします。作成しますか？",
    "gui.dialog.deleteBlocksError": "ブロック %1 を削除できませんでした。手動で削除してください",
    "gui.dialog.netIsConnect": "ネットワークが接続されているか確認してください",
    "gui.dialog.needToUpdate": "コンパイラを最新バージョンに更新します：%1",
    "gui.dialog.cantUseMpythonInArduinoC": "コンパイラがインストールされていないと、Arduino Cが使用できません。コンピュータにネットワークがない場合は、公式ウェブサイトから「コンパイラオフラインプラグインパッケージ」をダウンロードして使用できます。",
    "gui.dialog.enterMpyMode": "micropythonモードにします",
    "gui.dialog.runHardware": "ハードウェアを操作するには、まずデバイスを接続します。",
    "gui.dialog.sureDelete": "消去してもよろしいですか？",
    "gui.dialog.notSupportWebGL": "ステージのロード中にエラーが発生しました。WebGLをサポートしていない可能性があります。</br>詳細なエラーメッセージは次のとおりです：</br> %1 </br> QQグループお問い合わせ：671877416",
    "gui.dialog.boardIsnotMPython": "現在接続されているメインコントロールボードはmPythonではありません。mPythonに接続してください",
    "gui.dialog.swdMicrobitUpload": "コンピューターはSWDを使用してmicro:bitを書き込む必要があります。Mind+を管理者権限で実行してください。",
    "gui.dialog.openPortError": "シリアルポート %1 を開くときにエラーが発生しました：%2",
    "gui.dialog.pressedA": "ボタンAを押したまま、離さないでください。",
    "gui.dialog.unpressedA": "ボタンAを離してください",
    "gui.dialog.unsupportProject": "サポートされていないプロジェクトです。</br>空のプロジェクトをリロードします。",
    "gui.dialog.openProjectError": "スクラッチモードで作成されたプロジェクトは、スクラッチモードで開く必要があります</br>現在のモードを確認してください。",
    "gui.dialog.installCompilerError1": "ダウンロードに失敗しました。オフラインインストーラーをダウンロードするか、もう一度お試しください",
    "gui.dialog.installCompilerOffline": "オフラインインストーラーをダウンロードする",
    "gui.dialog.installCompilerError2": "検証エラー。オフラインインストーラーをダウンロードするか、もう一度ダウンロードしてください。",
    "gui.dialog.installCompilerError3": "インストールエラー、オフラインインストーラーをダウンロードするか、もう一度ダウンロードしてください",
    "gui.dialog.installCompilerSuccess": "コンパイラがインストールされました。ArduinoCでEsp32を使用できます",
    "gui.dialog.sureAdapter": "アダプターが見つかりませんでした。アダプターが接続されているかどうかを確認してください",
    "gui.dialog.checkPort": "シリアルポートを開けませんでした。シリアルポートが使用されているかどうかを確認してください。",
    "gui.dialog.changeBoard": "メインコントロールボードを切り替えると、現在のプログラムが消去されます。続行しますか？",
    "gui.crashMessage.somethingWrong": "おっとっと！問題が発生しました。",
    "gui.crashMessage.reload": "再読み込み",
    "gui.crashMessage.submit": "送信",
    "gui.crashMessage.attachment": "添付",
    "gui.crashMessage.errFeedbackNote": "ここにエラーメッセージや提案を書き込むことができます。Mind+はあなたの参加により改善されます。",
    "gui.crashMessage.promptContent": "すみません、Mind+がクラッシュしたようです。このエラーをMind+チームに送信できます。ありがとうございます。ページを更新してもう一度お試しください。",
    "gui.dialog.isSaveProject": "現在のプロジェクトを保存しますか？",
    "gui.dialog.save": "保存",
    "gui.dialog.notSave": "保存しない",
    "gui.dialog.continue": "継続",
    "gui.dialog.downLoadUpdate": "アップデートをダウンロードする",
    "gui.dialog.variableIsNull": "変数名は空にできません",
    "gui.dialog.listNameIsNull": "リスト名は空にできません",
    "gui.dialog.variableIsRepeat": "変数名を繰り返すことはできません。変更してください",
    "gui.dialog.pictureAINotNull": "nullにすることはできません",
    "gui.dialog.pictureAIInitServer": "最初にサーバーを初期化してください",
    "gui.dialog.pictureAIInitServerError": "サーバーの初期化中にエラーが発生しました。アカ​​ウントナンバーとシークレットキーを確認してください",
    "gui.dialog.versionPrompt": "現在のsb3ファイルバージョンは「%1」です",
    "gui.dialog.versionGreater": "sb3ファイルのバージョンが現在のソフトウェアバージョンより高いです。sb3ファイルのバージョン「%1」",
    "gui.dialog.noModulePrompt": "選択されたボードに対応するモジュールはありません。詳細はヘルプを参照してください",
    "gui.dialog.firstSelectBoard": "最初に「ボード」または「キット」を選択してください。",
    "gui.dialog.downloadFileError": "ファイルのダウンロード中にエラーが発生しました",
    "gui.dialog.noSearchModules": "関連するモジュールが見つかりませんでした。検索パスを確認してください",
    "gui.dialog.networkError": "ネットワークエラー",
    "gui.dialog.saveThirdModulesError": "ユーザーライブラリの保存中にエラーが発生しました",
    "gui.dialog.isContinueUpdateThirdModule": "現在、同じユーザー拡張ライブラリがあります。更新を続行すると、現在のユーザーライブラリが上書きされます。続行しますか？",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "フィールドを更新しました。%2／%1。拡張ライブラリに手動で再インポートする必要があります。この更新はキャンセルされます",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "解析エラー %1。更新は続行されますが、 \"%2\" のコンテンツは更新されません",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "ブロックの形状または入力ボックスのタイプを変更するには、プログラミングエリアで協調表示されているブロックを手動で削除してから、更新してください。更新はキャンセルされました",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh1": "ブロックの形状または入力ボックスのタイプを変更するには、更新する前に、プログラミングエリアで強調表示されているブロックを手動で削除し、スプライト \"%1\" を確認してください。この更新はキャンセルされます。",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh2": "ブロックの形状または入力ボックスのタイプを変更するには、スプライト「%1」を確認して、もう一度更新してください。この更新はキャンセルされました。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "ブロックの形状または入力ボックスのタイプを変更するには、プログラムエリアで強調表示されているブロックを手動で削除してから、ロードしてください。このロードはキャンセルされます。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad1": "ブロックの形状または入力ボックスのタイプを変更するには、プログラミングエリアで強調表示されているブロックを手動で削除し、ロードする前にスプライト \"%1\" を確認してください。このロードはキャンセルされます。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad2": "ブロックの形状または入力ボックスのタイプが変更されます。スプライト「%1」を確認して再度ロードしてください。この読み込みはキャンセルされました。",
    "gui.dialog.thirdex.filesEmpty": "ファイルフィールドを空にすることはできません。関連ファイルが失われる可能性があります。インポートされたユーザーライブラリファイルを確認してください！",
    "gui.dialog.thirdex.refreshing": "更新しています...",
    "gui.dialog.thirdex.refreshSuccess": "更新完了",
    "gui.dialog.isSaving": "保存しています...",
    "gui.dialog.isExporting": "エクスポートしています...",
    "gui.dialog.mpySb3Prompt": "\"%1\"はmicropythonモードを更新しました。プロジェクトのコンテンツにエラーがある場合は、手動で変更する必要があります",
    "gui.dialog.translate.startReadFile": "ファイルを読み込んでいます。しばらくお待ちください...",
    "gui.dialog.connecting.requestCloseFW": "このコンピューターのファイアウォールが有効なままだと、Telloが正常に接続できなくなるので、OKをクリックしてファイアウォールの設定を変更してください。",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "ja-Hira": {
    "gui.backpack.header": "バックパック",
    "gui.backpack.errorBackpack": "バックパックのよみこみじにエラーがはっせいしました",
    "gui.backpack.loadingBackpack": "よみこみちゅう...",
    "gui.backpack.more": "もっと",
    "gui.backpack.emptyBackpack": "バックパックはからです",
    "gui.gui.costumesTab": "コスチューム",
    "gui.gui.soundsTab": "おと",
    "gui.gui.backdropsTab": "はいけい",
    "gui.gui.addExtension": "かくちょうきのうをついか",
    "gui.costumeTab.addCostumeFromLibrary": "コスチュームをえらぶ",
    "gui.costumeLibrary.chooseACostume": "コスチュームをえらぶ",
    "gui.costumeTab.addBackdropFromLibrary": "はいけいをえらぶ",
    "gui.costumeTab.addBlankCostume": " えがく",
    "gui.costumeTab.addSurpriseCostume": "サプライズ",
    "gui.costumeTab.addFileBackdrop": "はいけいをアップロード",
    "gui.costumeTab.addFileCostume": "コスチュームをアップロード",
    "gui.costumeTab.addCameraCostume": "カメラ",
    "gui.soundEditor.trim": "カット",
    "gui.soundEditor.stop": "とめる",
    "gui.soundEditor.sound": "おと",
    "gui.soundEditor.play": "さいせい",
    "gui.soundEditor.save": "ほぞん",
    "gui.soundEditor.undo": "もとにもどす",
    "gui.soundEditor.redo": "やりなおす",
    "gui.soundEditor.faster": "はやく",
    "gui.soundEditor.slower": "おそく",
    "gui.soundEditor.echo": "エコー",
    "gui.soundEditor.robot": "ロボット",
    "gui.soundEditor.louder": "おおきく",
    "gui.soundEditor.softer": "ちいさく",
    "gui.soundEditor.reverse": "ぎゃくむき",
    "gui.soundTab.recordSound": "ろくおんする",
    "gui.soundTab.addSoundFromLibrary": " おとをえらぶ",
    "gui.soundTab.surpriseSound": "サプライズ",
    "gui.soundTab.fileUploadSound": "おとをアップロードする",
    "gui.controls.stop": "とめる",
    "gui.controls.go": "じっこう",
    "gui.stageHeader.stageSizeUnFull": "ぜんがめんひょうじをやめる",
    "gui.SpriteInfo.show": "ひょうじする",
    "gui.SpriteInfo.size": "おおきさ",
    "gui.directionPicker.rotationStyles.allAround": "じゆうにかいてん",
    "gui.directionPicker.rotationStyles.leftRight": "さゆうのみ",
    "gui.directionPicker.rotationStyles.dontRotate": "かいてんしない",
    "gui.spriteSelectorItem.contextMenuDuplicate": "ふくせい",
    "gui.spriteSelectorItem.contextMenuDelete": "さくじょ",
    "gui.spriteSelectorItem.contextMenuExport": "かきだし",
    "gui.spriteSelector.addSpriteFromPaint": "えがく",
    "gui.spriteSelector.addSpriteFromSurprise": "サプライズ",
    "gui.spriteSelector.addSpriteFromFile": "スプライトをアップロード",
    "gui.stageSelector.stage": "ステージ",
    "gui.stageSelector.backdrops": "はいけい",
    "gui.stageSelector.addBackdropFromPaint": "えがく",
    "gui.stageSelector.addBackdropFromSurprise": "サプライズ",
    "gui.stageSelector.addBackdropFromFile": "はいけいをアップロード",
    "gui.modal.back": "もどる",
    "gui.library.filterPlaceholder": "けんさく",
    "gui.libraryTags.all": "すべて",
    "gui.libraryTags.animals": "どうぶつ",
    "gui.libraryTags.dance": "ダンス",
    "gui.libraryTags.effects": "効果（こうか）",
    "gui.libraryTags.fantasy": "ファンタジー",
    "gui.libraryTags.fashion": "ファッション",
    "gui.libraryTags.food": "たべもの",
    "gui.libraryTags.indoors": "おくない",
    "gui.libraryTags.loops": "ループ",
    "gui.libraryTags.music": "おんがく",
    "gui.libraryTags.notes": "ノート",
    "gui.libraryTags.outdoors": "おくがい",
    "gui.libraryTags.patterns": "もよう",
    "gui.libraryTags.people": "ひと",
    "gui.libraryTags.percussion": "だがっき",
    "gui.libraryTags.space": "うちゅう",
    "gui.libraryTags.sports": "スポーツ",
    "gui.libraryTags.underwater": "かいちゅう",
    "gui.libraryTags.voice": "こえ",
    "gui.libraryTags.wacky": "きみょうなおと",
    "gui.libraryTags.animation": "アニメーション",
    "gui.libraryTags.art": "アート",
    "gui.libraryTags.games": "ゲーム",
    "gui.libraryTags.stories": "物語（ものがたり）",
    "gui.libraryTags.letters": "もじ",
    "gui.soundLibrary.chooseASound": "おとをえらぶ",
    "gui.SpriteInfo.spritePlaceholder": "なまえ",
    "gui.cards.more-things-to-try": "もっとやってみる!",
    "gui.cards.see-more": "もっとみる",
    "gui.cards.shrink": "しゅくしょう",
    "gui.cards.expand": "かくだい",
    "gui.cards.close": "とじる",
    "gui.loader.message1": "ブロックをさくせいちゅう…",
    "gui.loader.message2": "スプライトをよみこみちゅう…",
    "gui.loader.message3": "おとをよみこみちゅう…",
    "gui.loader.message4": "かくちょうきのうをよみこみちゅう…",
    "gui.loader.message5": "ねこのむれをあつめています…",
    "gui.loader.message6": "Nanoをそうしんちゅう…",
    "gui.loader.message7": "Goboをふくらませています…",
    "gui.loader.message8": "えもじをじゅんびちゅう…",
    "gui.loader.headline": "プロジェクトをよみこみちゅう…",
    "gui.cameraModal.cameraModalTitle": "しゃしんをとる",
    "gui.cameraModal.loadingCameraMessage": "カメラをよみこみちゅう...",
    "gui.cameraModal.permissionRequest": "カメラをつかうきょかがひつようです",
    "gui.cameraModal.retakePhoto": "しゃしんをとりなおす",
    "gui.cameraModal.save": "ほぞん",
    "gui.cameraModal.takePhoto": "しゃしんをとる",
    "gui.cameraModal.loadingCaption": "よみこみちゅう...",
    "gui.cameraModal.enableCameraCaption": "カメラをゆうこうにする",
    "gui.recordModal.title": "ろくおん",
    "gui.playbackStep.stopMsg": "ていし",
    "gui.playbackStep.playMsg": "さいせい",
    "gui.playbackStep.loadingMsg": "よみこみちゅう...",
    "gui.playbackStep.saveMsg": "ほぞん",
    "gui.playbackStep.reRecordMsg": "さいろくおん",
    "gui.webglModal.label": "ブラウザーはWebGLをサポートしていないようです",
    "gui.webglModal.webgllink": "WebGLをサポートしていない",
    "gui.prompt.cancel": "キャンセル",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "ひきすうをついか",
    "gui.customProcedures.addAnInputBoolean": "ひきすうをついか",
    "gui.customProcedures.numberTextType": "すうちまたはテキスト",
    "gui.customProcedures.booleanType": "しんぎち",
    "gui.customProcedures.addALabel": "ラベルのテキストをついか",
    "gui.customProcedures.runWithoutScreenRefresh": "がめんをさいびょうがせずにじっこうする",
    "gui.customProcedures.cancel": "キャンセル",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "あなたのプロジェクトを、ほんとうのせかいと、つなぎます。",
    "gui.extension.music.name": "おんがく",
    "gui.extension.music.description": "がっきやドラムをえんそうする。",
    "gui.extension.pen.name": "ペン",
    "gui.extension.pen.description": "スプライトで、えをかく。",
    "gui.gui.variableScopeOptionAllSprites": "すべてのスプライトよう",
    "gui.gui.variableScopeOptionSpriteOnly": "このスプライトのみ",
    "gui.gui.variablePromptAllSpritesMessage": "このへんすうは、すべてのスプライトでつかえます。",
    "gui.monitor.contextMenu.default": "ふつうのひょうじ",
    "gui.monitor.contextMenu.large": "おおきなひょうじ",
    "gui.monitor.contextMenu.slider": "スライダー",
    "gui.monitor.contextMenu.import": "よみこみ",
    "gui.monitor.contextMenu.export": "かきだし",
    "gui.opcodeLabels.direction": "むき",
    "gui.opcodeLabels.xposition": "xざひょう",
    "gui.opcodeLabels.yposition": "yざひょう",
    "gui.opcodeLabels.size": "おおきさ",
    "gui.opcodeLabels.costumename": "コスチュームめい",
    "gui.opcodeLabels.costumenumber": "コスチュームのばんごう",
    "gui.opcodeLabels.backdropname": "はいけいのなまえ",
    "gui.opcodeLabels.backdropnumber": "はいけいのばんごう",
    "gui.opcodeLabels.volume": "おんりょう",
    "gui.opcodeLabels.tempo": "テンポ",
    "gui.opcodeLabels.answer": "こたえ",
    "gui.opcodeLabels.loudness": "おんりょう",
    "gui.opcodeLabels.year": "とし",
    "gui.opcodeLabels.month": "つき",
    "gui.opcodeLabels.date": "ひ",
    "gui.opcodeLabels.dayofweek": "ようび",
    "gui.opcodeLabels.hour": "とき",
    "gui.opcodeLabels.minute": "ぶん",
    "gui.opcodeLabels.second": "びょう",
    "gui.opcodeLabels.timer": "タイマー",
    "music.categoryName": "おんがく",
    "translate.categoryName": "ほんやく",
    "pen.categoryName": "ペン",
    "pen.changeColorParam": "ペンの[COLOR_PARAM]を[VALUE]ずつかえる",
    "pen.changeHue": "ペンのいろを[HUE]ずつかえる",
    "pen.changeShade": "ペンのこさを[SHADE]ずつかえる",
    "pen.changeSize": "ペンのふとさを[SIZE]ずつかえる",
    "pen.clear": "ぜんぶけす",
    "pen.colorMenu.brightness": "あかるさ",
    "pen.colorMenu.color": "いろ",
    "pen.colorMenu.saturation": "あざやかさ",
    "pen.colorMenu.transparency": "とうめいど",
    "pen.penDown": "ペンをおろす",
    "pen.penUp": "ペンをあげる",
    "pen.setColor": "ペンのいろを[COLOR]にする",
    "pen.setColorParam": "ペンの[COLOR_PARAM]を[VALUE]にする",
    "pen.setHue": "ペンのいろを[HUE]にする",
    "pen.setShade": "ペンのこさを[SHADE]にする",
    "pen.setSize": "ペンのふとさを[SIZE]にする",
    "pen.stamp": "スタンプ",
    "music.changeTempo": "テンポを[TEMPO]ずつかえる",
    "music.drumBass": "(2) バスドラム",
    "music.drumBongo": "(13) ボンゴ",
    "music.drumCabasa": "(15) カバサ",
    "music.drumClaves": "(9) クラーベ",
    "music.drumClosedHiHat": "(6) クローズハイハット",
    "music.drumConga": "(14) コンガ",
    "music.drumCowbell": "(11) カウベル",
    "music.drumCrashCymbal": "(4) クラッシュシンバル",
    "music.drumCuica": "(18) クイーカ",
    "music.drumGuiro": "(16) ギロ",
    "music.drumHandClap": "(8) てびょうし",
    "music.drumOpenHiHat": "(5) オープンハイハット",
    "music.drumSideStick": "(3) サイドスティック",
    "music.drumSnare": "(1) スネアドラム",
    "music.drumTambourine": "(7) タンバリン",
    "music.drumTriangle": "(12) トライアングル",
    "music.drumVibraslap": "(17) ビブラスラップ",
    "music.drumWoodBlock": "(10) ウッドブロック",
    "music.getTempo": "テンポ",
    "music.instrumentBass": "(6) ベース",
    "music.instrumentBassoon": "(14) バスーン",
    "music.instrumentCello": "(8) チェロ",
    "music.instrumentChoir": "(15) がっしょうだん",
    "music.instrumentClarinet": "(10) クラリネット",
    "music.instrumentElectricGuitar": "(5) エレキギター",
    "music.instrumentElectricPiano": "(2) でんしピアノ",
    "music.instrumentFlute": "(12) フルート",
    "music.instrumentGuitar": "(4) ギター",
    "music.instrumentMarimba": "(19) マリンバ",
    "music.instrumentMusicBox": "(17) ミュージックボックス",
    "music.instrumentOrgan": "(3) オルガン",
    "music.instrumentPiano": "(1) ピアノ",
    "music.instrumentPizzicato": "(7) ピチカート",
    "music.instrumentSaxophone": "(11) サクソフォン",
    "music.instrumentSteelDrum": "(18) スチールドラム",
    "music.instrumentSynthLead": "(20) シンセリード",
    "music.instrumentSynthPad": "(21) シンセパッド",
    "music.instrumentTrombone": "(9) トロンボーン",
    "music.instrumentVibraphone": "(16) ビブラフォン",
    "music.instrumentWoodenFlute": "(13) もっかんフルート",
    "music.midiPlayDrumForBeats": "[DRUM]のドラムを[BEATS]はくならす",
    "music.midiSetInstrument": "がっきを[INSTRUMENT]にする",
    "music.playDrumForBeats": "[DRUM]のドラムを[BEATS]はくならす",
    "music.playNoteForBeats": "[NOTE]のおんぷを[BEATS]はくならす",
    "music.restForBeats": "[BEATS]はくやすむ",
    "music.setInstrument": "がっきを[INSTRUMENT]にする",
    "music.setTempo": "テンポを[TEMPO]にする",
    "videoSensing.categoryName": "ビデオモーションセンサー",
    "videoSensing.direction": "むき",
    "videoSensing.motion": "モーション",
    "videoSensing.off": "きり",
    "videoSensing.on": "いり",
    "videoSensing.onFlipped": "さゆうはんてん",
    "videoSensing.setVideoTransparency": "ビデオのとうめいどを[TRANSPARENCY]にする",
    "videoSensing.sprite": "スプライト",
    "videoSensing.stage": "ステージ",
    "videoSensing.videoOn": "[SUBJECT]のビデオの[ATTRIBUTE]",
    "videoSensing.videoToggle": "ビデオを[VIDEO_STATE]にする",
    "videoSensing.whenMotionGreaterThan": "ビデオモーション > [REFERENCE]のとき",
    "translate.translateBlock": "[WORDS]を[LANGUAGE]にほんやくする",
    "translate.defaultTextToTranslate": "こんにちは",
    "translate.viewerLanguage": "げんご",
    "text2speech.speakAndWaitBlock": "[WORDS]としゃべる",
    "text2speech.setVoiceBlock": "こえを[VOICE]にする",
    "text2speech.setLanguageBlock": "げんごを[LANGUAGE]にする",
    "text2speech.alto": "アルト",
    "text2speech.tenor": "テノール",
    "text2speech.squeak": "ねずみ",
    "text2speech.giant": "きょじん",
    "text2speech.kitten": "こねこ",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "[PHRASE]をきいたとき",
    "speech.listenAndWait": "きいて",
    "speech.defaultWhenIHearValue": "いこう",
    "paint.paintEditor.hue": "いろ",
    "paint.paintEditor.saturation": "あざやかさ",
    "paint.paintEditor.brightness": "あかるさ",
    "gui.comingSoon.message1": "あんしんしてください。かいはつちゅうです {emoji}",
    "gui.comingSoon.message2": "きんじつこうかい",
    "gui.comingSoon.message3": "かいはつちゅうです {emoji}",
    "paint.paintEditor.fill": "ぬりつぶし",
    "paint.paintEditor.costume": "コスチューム",
    "paint.paintEditor.group": "ぐるーぷか",
    "paint.paintEditor.ungroup": "グループかいじょ",
    "paint.paintEditor.undo": "とりけし",
    "paint.paintEditor.redo": "やりなおし",
    "paint.paintEditor.forward": "てまえにだす",
    "paint.paintEditor.backward": "おくにさげる",
    "paint.paintEditor.front": "さいぜんめん",
    "paint.paintEditor.back": "さげる",
    "paint.paintEditor.more": "もっと",
    "paint.modeTools.brushSize": "おおきさ",
    "paint.modeTools.eraserSize": "けしごむのおおきさ",
    "paint.modeTools.copy": "コピー",
    "paint.modeTools.paste": "はりつけ",
    "paint.modeTools.delete": "さくじょ",
    "paint.modeTools.curved": "なめらか",
    "paint.modeTools.pointed": "かどばった",
    "paint.modeTools.thickness": "ふとさ",
    "paint.modeTools.flipHorizontal": "さゆうはんてん",
    "paint.modeTools.flipVertical": "じょうげはんてん",
    "paint.modeTools.filled": "ぬりつぶし",
    "paint.modeTools.outlined": "りんかく",
    "paint.paintEditor.bitmap": "ビットマップにへんかん",
    "paint.paintEditor.vector": "ベクターにへんかん",
    "paint.paintEditor.stroke": "わくせん",
    "paint.brushMode.brush": "ふで",
    "paint.eraserMode.eraser": "けしごむ",
    "paint.fillMode.fill": "ぬりつぶし",
    "paint.lineMode.line": "ちょくせん",
    "paint.ovalMode.oval": "えん",
    "paint.rectMode.rect": "しかくけい",
    "paint.reshapeMode.reshape": "かたちをかえる",
    "paint.roundedRectMode.roundedRect": "かくまるのちょうほうけい",
    "paint.selectMode.select": "せんたく",
    "paint.textMode.text": "テキスト",
    "paint.colorPicker.swap": "いれかえ",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "nb": {
    "gui.backpack.header": "Ryggsekk",
    "gui.backpack.errorBackpack": "Noe gikk feil med ryggsekken",
    "gui.backpack.loadingBackpack": "Laster...",
    "gui.backpack.more": "Mer",
    "gui.backpack.emptyBackpack": "Ryggsekken er tom",
    "gui.gui.costumesTab": "Drakter",
    "gui.gui.soundsTab": "Lyder",
    "gui.gui.backdropsTab": "Bakgrunner",
    "gui.gui.addExtension": "Hent tilleggsfunksjon",
    "gui.costumeTab.addCostumeFromLibrary": "Velg en drakt",
    "gui.costumeLibrary.chooseACostume": "Velg en drakt",
    "gui.costumeTab.addBackdropFromLibrary": "Velg et bakgrunnsbilde",
    "gui.costumeTab.addBlankCostume": "Tegn",
    "gui.costumeTab.addSurpriseCostume": "Overraskelse",
    "gui.costumeTab.addFileBackdrop": "Last opp bakgrunn",
    "gui.costumeTab.addFileCostume": "Last opp drakt",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Klipp",
    "gui.soundEditor.stop": "Stopp",
    "gui.soundEditor.sound": "Lyd",
    "gui.soundEditor.play": "Spill av",
    "gui.soundEditor.save": "Lagre",
    "gui.soundEditor.undo": "Angre",
    "gui.soundEditor.redo": "Gjør igjen",
    "gui.soundEditor.faster": "Raskere",
    "gui.soundEditor.slower": "Saktere",
    "gui.soundEditor.echo": "Ekko",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Høyere lyd",
    "gui.soundEditor.softer": "Svakere lyd",
    "gui.soundEditor.reverse": "Baklengs",
    "gui.soundTab.recordSound": "Spill inn lyd",
    "gui.soundTab.addSoundFromLibrary": "Velg en lyd",
    "gui.soundTab.surpriseSound": "Overraskelse",
    "gui.soundTab.fileUploadSound": "Last opp en lyd",
    "gui.controls.stop": "Stopp",
    "gui.controls.go": "Start",
    "gui.stageHeader.stageSizeUnFull": "Avslutt fullskjerm",
    "gui.SpriteInfo.show": "Vis",
    "gui.SpriteInfo.size": "Størrelse",
    "gui.directionPicker.rotationStyles.allAround": "Fri rotasjon",
    "gui.directionPicker.rotationStyles.leftRight": "Vend sideveis",
    "gui.directionPicker.rotationStyles.dontRotate": "Ikke roter",
    "gui.spriteSelectorItem.contextMenuDuplicate": "lag en kopi",
    "gui.spriteSelectorItem.contextMenuDelete": "slett",
    "gui.spriteSelectorItem.contextMenuExport": "eksport",
    "gui.spriteSelector.addSpriteFromPaint": "Tegn",
    "gui.spriteSelector.addSpriteFromSurprise": "Overraskelse",
    "gui.spriteSelector.addSpriteFromFile": "Last opp figur",
    "gui.stageSelector.stage": "Scene",
    "gui.stageSelector.backdrops": "Bakgrunner",
    "gui.stageSelector.addBackdropFromPaint": "Tegn",
    "gui.stageSelector.addBackdropFromSurprise": "Overraskelse",
    "gui.stageSelector.addBackdropFromFile": "Last opp bakgrunn",
    "gui.modal.back": "Tilbake",
    "gui.library.filterPlaceholder": "Søk",
    "gui.libraryTags.all": "Alt",
    "gui.libraryTags.animals": "Dyr",
    "gui.libraryTags.dance": "Dans",
    "gui.libraryTags.effects": "Effekter",
    "gui.libraryTags.fantasy": "Fantasi",
    "gui.libraryTags.fashion": "Mote",
    "gui.libraryTags.food": "Mat",
    "gui.libraryTags.indoors": "Innendørs",
    "gui.libraryTags.loops": "Musikkloop",
    "gui.libraryTags.music": "Musikk",
    "gui.libraryTags.notes": "Noter",
    "gui.libraryTags.outdoors": "Utendørs",
    "gui.libraryTags.patterns": "Mønstre",
    "gui.libraryTags.people": "Mennesker",
    "gui.libraryTags.percussion": "Slaginstrumenter",
    "gui.libraryTags.space": "Romfart",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Under vann",
    "gui.libraryTags.voice": "Stemme",
    "gui.libraryTags.wacky": "Rart",
    "gui.libraryTags.animation": "Animasjon",
    "gui.libraryTags.art": "Kunst",
    "gui.libraryTags.games": "Spill",
    "gui.libraryTags.stories": "Fortellinger",
    "gui.libraryTags.letters": "Bokstaver",
    "gui.soundLibrary.chooseASound": "Velg en lyd",
    "gui.SpriteInfo.spritePlaceholder": "Figurens navn",
    "gui.cards.more-things-to-try": "Andre ting du kan prøve",
    "gui.cards.see-more": "Vis flere",
    "gui.cards.shrink": "Skjul",
    "gui.cards.expand": "Åpne",
    "gui.cards.close": "Lukk",
    "gui.loader.message1": "Lager klosser...",
    "gui.loader.message2": "Laster figurer...",
    "gui.loader.message3": "Laster inn lyder...",
    "gui.loader.message4": "Laster inn tilleggsfunksjoner...",
    "gui.loader.message5": "Gjeter katter...",
    "gui.loader.message6": "Sender nanoer...",
    "gui.loader.message7": "Setter opp goboer...",
    "gui.loader.message8": "Henter emoji...",
    "gui.loader.headline": "Laster prosjekt",
    "gui.cameraModal.cameraModalTitle": "Ta bilde",
    "gui.cameraModal.loadingCameraMessage": "Starter kamera...",
    "gui.cameraModal.permissionRequest": "Du må gi Scratch tillatelse til å bruke kameraet",
    "gui.cameraModal.retakePhoto": "Ta bilde igjen",
    "gui.cameraModal.save": "Lagre",
    "gui.cameraModal.takePhoto": "Ta bilde",
    "gui.cameraModal.loadingCaption": "Laster...",
    "gui.cameraModal.enableCameraCaption": "Skru på kamera",
    "gui.recordModal.title": "Spill inn lyd",
    "gui.playbackStep.stopMsg": "Stopp",
    "gui.playbackStep.playMsg": "Spill av",
    "gui.playbackStep.loadingMsg": "Laster...",
    "gui.playbackStep.saveMsg": "Lagre",
    "gui.playbackStep.reRecordMsg": "Spill inn igjen",
    "gui.webglModal.label": "Nettleseren støtter ikke WebGL",
    "gui.webglModal.webgllink": "ikke støtter WebGL",
    "gui.prompt.cancel": "Avbryt",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Legg til et felt",
    "gui.customProcedures.addAnInputBoolean": "Legg til et felt",
    "gui.customProcedures.numberTextType": "tall eller tekst",
    "gui.customProcedures.booleanType": "logisk verdi",
    "gui.customProcedures.addALabel": "Legg til tekst",
    "gui.customProcedures.runWithoutScreenRefresh": "Kjør uten å oppdatere skjermen",
    "gui.customProcedures.cancel": "Avbryt",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Koble prosjektet ditt til den fysiske verden",
    "gui.extension.music.name": "Musikk",
    "gui.extension.music.description": "Spill instrumenter",
    "gui.extension.pen.name": "Penn",
    "gui.extension.pen.description": "Få figurene til å tegne",
    "gui.gui.variableScopeOptionAllSprites": "For alle figurer",
    "gui.gui.variableScopeOptionSpriteOnly": "For denne figuren",
    "gui.gui.variablePromptAllSpritesMessage": "Denne variabelen kan brukes av alle figurer",
    "gui.monitor.contextMenu.default": "vanlig visning",
    "gui.monitor.contextMenu.large": "stor visning",
    "gui.monitor.contextMenu.slider": "skyveknapp",
    "gui.monitor.contextMenu.import": "importer",
    "gui.monitor.contextMenu.export": "Eksporter",
    "gui.opcodeLabels.direction": "retning",
    "gui.opcodeLabels.xposition": "x-posisjon",
    "gui.opcodeLabels.yposition": "y-posisjon",
    "gui.opcodeLabels.size": "størrelse",
    "gui.opcodeLabels.costumename": "draktnavn",
    "gui.opcodeLabels.costumenumber": "draktnummer",
    "gui.opcodeLabels.backdropname": "navn på bakgrunn",
    "gui.opcodeLabels.backdropnumber": "bakgrunnsnummer",
    "gui.opcodeLabels.volume": "volum",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "svar",
    "gui.opcodeLabels.loudness": "lydnivå",
    "gui.opcodeLabels.year": "år",
    "gui.opcodeLabels.month": "måned",
    "gui.opcodeLabels.date": "dato",
    "gui.opcodeLabels.dayofweek": "ukedag",
    "gui.opcodeLabels.hour": "time",
    "gui.opcodeLabels.minute": "minutt",
    "gui.opcodeLabels.second": "sekund",
    "gui.opcodeLabels.timer": "sekundklokke",
    "music.categoryName": "Musikk",
    "translate.categoryName": "Oversette",
    "pen.categoryName": "Penn",
    "pen.changeColorParam": "endre pennens [COLOR_PARAM] med [VALUE]",
    "pen.changeHue": "endre pennfarge med [HUE]",
    "pen.changeShade": "endre pennens lysstyrke med [SHADE]",
    "pen.changeSize": "endre pennens bredde med [SIZE]",
    "pen.clear": "slett alt",
    "pen.colorMenu.brightness": "lysstyrke",
    "pen.colorMenu.color": "farge",
    "pen.colorMenu.saturation": "fargemetning",
    "pen.colorMenu.transparency": "gjennomsiktighet",
    "pen.penDown": "penn på",
    "pen.penUp": "penn av",
    "pen.setColor": "sett pennfarge til [COLOR]",
    "pen.setColorParam": "sett pennens [COLOR_PARAM] til [VALUE]",
    "pen.setHue": "sett pennens farge til [HUE]",
    "pen.setShade": "sett pennens lysstyrke til [SHADE]",
    "pen.setSize": "sett pennbredde til [SIZE]",
    "pen.stamp": "stemple avtrykk",
    "music.changeTempo": "endre tempo med [TEMPO]",
    "music.drumBass": "(2) Stortromme",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Pinner",
    "music.drumClosedHiHat": "(6) Lukket hi-hat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Kubjelle",
    "music.drumCrashCymbal": "(4) Crash cymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Håndklapp",
    "music.drumOpenHiHat": "(5) Åpen hi-hat",
    "music.drumSideStick": "(3) Kantslag",
    "music.drumSnare": "(1) Skarptromme",
    "music.drumTambourine": "(7) Tamburin",
    "music.drumTriangle": "(12) Triangel",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Treblokk",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Kontrabass",
    "music.instrumentBassoon": "(14) Fagott",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Kor",
    "music.instrumentClarinet": "(10) Klarinett",
    "music.instrumentElectricGuitar": "(5) Elektrisk gitar",
    "music.instrumentElectricPiano": "(2) Elektrisk piano",
    "music.instrumentFlute": "(12) Fløyte",
    "music.instrumentGuitar": "(4) Gitar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Spilledåse",
    "music.instrumentOrgan": "(3) Orgel",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saksofon",
    "music.instrumentSteelDrum": "(18) Steel drum",
    "music.instrumentSynthLead": "(20) Synth",
    "music.instrumentSynthPad": "(21) Synth-akkord",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Blokkfløyte",
    "music.midiPlayDrumForBeats": "trommeslag [DRUM] som varer [BEATS] takter",
    "music.midiSetInstrument": "velg instrument [INSTRUMENT]",
    "music.playDrumForBeats": "trommeslag [DRUM] som varer [BEATS] takter",
    "music.playNoteForBeats": "spill tone [NOTE] som varer [BEATS] takter",
    "music.restForBeats": "pause i [BEATS] takter",
    "music.setInstrument": "velg instrument [INSTRUMENT]",
    "music.setTempo": "sett tempo til [TEMPO]",
    "videoSensing.categoryName": "Sansing med kamera",
    "videoSensing.direction": "retning",
    "videoSensing.motion": "bevegelse",
    "videoSensing.off": "av",
    "videoSensing.on": "på",
    "videoSensing.onFlipped": "på speilvendt",
    "videoSensing.setVideoTransparency": "sett [TRANSPARENCY] gjennomsiktighet av video",
    "videoSensing.sprite": "figur",
    "videoSensing.stage": "scene",
    "videoSensing.videoOn": "video [ATTRIBUTE] på [SUBJECT]",
    "videoSensing.videoToggle": "sett video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "når videobevegelse > [REFERENCE]",
    "translate.translateBlock": "oversett [WORDS] til [LANGUAGE]",
    "translate.defaultTextToTranslate": "hei",
    "translate.viewerLanguage": "språk",
    "text2speech.speakAndWaitBlock": "si [WORDS]",
    "text2speech.setVoiceBlock": "velg stemme [VOICE]",
    "text2speech.setLanguageBlock": "sett språk til [LANGUAGE]",
    "text2speech.alto": "alt",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "knirk",
    "text2speech.giant": "kjempe",
    "text2speech.kitten": "kattunge",
    "text2speech.defaultTextToSpeak": "hei",
    "speech.whenIHear": "når jeg hører [PHRASE]",
    "speech.listenAndWait": "lytt og vent",
    "speech.defaultWhenIHearValue": "hør her",
    "paint.paintEditor.hue": "Farge",
    "paint.paintEditor.saturation": "Fargemetning",
    "paint.paintEditor.brightness": "Lysstyrke",
    "gui.comingSoon.message1": "Ingen fare, vi arbeider med dette {emoji}",
    "gui.comingSoon.message2": "Kommer snart...",
    "gui.comingSoon.message3": "Vi arbeider med dette {emoji}",
    "paint.paintEditor.fill": "Fyll",
    "paint.paintEditor.costume": "Drakt",
    "paint.paintEditor.group": "Gruppér",
    "paint.paintEditor.ungroup": "Del opp gruppe",
    "paint.paintEditor.undo": "Angre",
    "paint.paintEditor.redo": "Gjør igjen",
    "paint.paintEditor.forward": "Flytt frem",
    "paint.paintEditor.backward": "Flytt tilbake",
    "paint.paintEditor.front": "Foran alt",
    "paint.paintEditor.back": "Tilbake",
    "paint.paintEditor.more": "Flere",
    "paint.modeTools.brushSize": "Størrelse",
    "paint.modeTools.eraserSize": "Viskelærbredde",
    "paint.modeTools.copy": "Kopier",
    "paint.modeTools.paste": "Lim inn",
    "paint.modeTools.delete": "Slett",
    "paint.modeTools.curved": "Glatt",
    "paint.modeTools.pointed": "Skarp",
    "paint.modeTools.thickness": "Tykkelse",
    "paint.modeTools.flipHorizontal": "Speilvend",
    "paint.modeTools.flipVertical": "Opp ned",
    "paint.modeTools.filled": "Fylt",
    "paint.modeTools.outlined": "Omriss",
    "paint.paintEditor.bitmap": "Gå til pikselgrafikk",
    "paint.paintEditor.vector": "Gjør om til vektorgrafikk",
    "paint.paintEditor.stroke": "Kant",
    "paint.brushMode.brush": "Malepensel",
    "paint.eraserMode.eraser": "Viskelær",
    "paint.fillMode.fill": "Fyll",
    "paint.lineMode.line": "Linje",
    "paint.ovalMode.oval": "Sirkel",
    "paint.rectMode.rect": "Rektangel",
    "paint.reshapeMode.reshape": "Bøy",
    "paint.roundedRectMode.roundedRect": "Avrundet rektangel",
    "paint.selectMode.select": "Velg",
    "paint.textMode.text": "Tekst",
    "paint.colorPicker.swap": "Bytt",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "nn": {
    "gui.backpack.header": "Ryggsekk",
    "gui.backpack.errorBackpack": "Feil ved lasting av ryggsekk",
    "gui.backpack.loadingBackpack": "Lastar …",
    "gui.backpack.more": "Fleire",
    "gui.backpack.emptyBackpack": "Ryggsekken er tom",
    "gui.gui.costumesTab": "Drakter",
    "gui.gui.soundsTab": "Lydar",
    "gui.gui.backdropsTab": "Bakgrunnar",
    "gui.gui.addExtension": "Hent tilleggsfunksjon",
    "gui.costumeTab.addCostumeFromLibrary": "Vel ei drakt",
    "gui.costumeLibrary.chooseACostume": "Vel ei drakt",
    "gui.costumeTab.addBackdropFromLibrary": "Vel eit bakgrunnsbilete",
    "gui.costumeTab.addBlankCostume": "Teikn",
    "gui.costumeTab.addSurpriseCostume": "Overrasking",
    "gui.costumeTab.addFileBackdrop": "Last opp bakgrunn",
    "gui.costumeTab.addFileCostume": "Last opp drakt",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Klipp",
    "gui.soundEditor.stop": "Stopp",
    "gui.soundEditor.sound": "Lyd",
    "gui.soundEditor.play": "Spel av",
    "gui.soundEditor.save": "Lagra",
    "gui.soundEditor.undo": "Angra",
    "gui.soundEditor.redo": "Gjer om",
    "gui.soundEditor.faster": "Raskare",
    "gui.soundEditor.slower": "Saktare",
    "gui.soundEditor.echo": "Ekko",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Høgare",
    "gui.soundEditor.softer": "Lågare",
    "gui.soundEditor.reverse": "Baklengs",
    "gui.soundTab.recordSound": "Spel inn",
    "gui.soundTab.addSoundFromLibrary": "Vel ein lyd",
    "gui.soundTab.surpriseSound": "Overrasking",
    "gui.soundTab.fileUploadSound": "Last opp ein lyd",
    "gui.controls.stop": "Stopp",
    "gui.controls.go": "Gå til",
    "gui.stageHeader.stageSizeUnFull": "Gå ut av fullskjerm",
    "gui.SpriteInfo.show": "Vis",
    "gui.SpriteInfo.size": "Storleik",
    "gui.directionPicker.rotationStyles.allAround": "Roter fritt",
    "gui.directionPicker.rotationStyles.leftRight": "Venstre–høgre",
    "gui.directionPicker.rotationStyles.dontRotate": "Ikkje roter",
    "gui.spriteSelectorItem.contextMenuDuplicate": "lag ein kopi",
    "gui.spriteSelectorItem.contextMenuDelete": "slett",
    "gui.spriteSelectorItem.contextMenuExport": "eksporter",
    "gui.spriteSelector.addSpriteFromPaint": "Teikn",
    "gui.spriteSelector.addSpriteFromSurprise": "Overrasking",
    "gui.spriteSelector.addSpriteFromFile": "Last opp figur",
    "gui.stageSelector.stage": "Scene",
    "gui.stageSelector.backdrops": "Bakgrunnar",
    "gui.stageSelector.addBackdropFromPaint": "Teikn",
    "gui.stageSelector.addBackdropFromSurprise": "Overrasking",
    "gui.stageSelector.addBackdropFromFile": "Last opp bakgrunn",
    "gui.modal.back": "Tilbake",
    "gui.library.filterPlaceholder": "Søk",
    "gui.libraryTags.all": "Alle",
    "gui.libraryTags.animals": "Dyr",
    "gui.libraryTags.dance": "Dans",
    "gui.libraryTags.effects": "Effektar",
    "gui.libraryTags.fantasy": "Fantasi",
    "gui.libraryTags.fashion": "Mote",
    "gui.libraryTags.food": "Mat",
    "gui.libraryTags.indoors": "Innandørs",
    "gui.libraryTags.loops": "Musikksløyfer",
    "gui.libraryTags.music": "Musikk",
    "gui.libraryTags.notes": "Tonar",
    "gui.libraryTags.outdoors": "Utandørs",
    "gui.libraryTags.patterns": "Mønster",
    "gui.libraryTags.people": "Menneske",
    "gui.libraryTags.percussion": "Slaginstrument",
    "gui.libraryTags.space": "Romfart",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Under vatnet",
    "gui.libraryTags.voice": "Stemmer",
    "gui.libraryTags.wacky": "Merkeleg",
    "gui.libraryTags.animation": "Animasjonar",
    "gui.libraryTags.art": "Kunst",
    "gui.libraryTags.games": "Spel",
    "gui.libraryTags.stories": "Forteljingar",
    "gui.libraryTags.letters": "Bokstavar",
    "gui.soundLibrary.chooseASound": "Vel ein lyd",
    "gui.SpriteInfo.spritePlaceholder": "Namn",
    "gui.cards.more-things-to-try": "Fleire ting å prøva!",
    "gui.cards.see-more": "Vis fleire",
    "gui.cards.shrink": "Krymp",
    "gui.cards.expand": "Utvid",
    "gui.cards.close": "Lukk",
    "gui.loader.message1": "Lagar klossar …",
    "gui.loader.message2": "Les inn figurar …",
    "gui.loader.message3": "Les inn lydar …",
    "gui.loader.message4": "Lastar inn tilleggsfunksjonar …",
    "gui.loader.message5": "Samlar kattar …",
    "gui.loader.message6": "Sender nanoar …",
    "gui.loader.message7": "Blåser opp goboar …",
    "gui.loader.message8": "Hentar emojiar …",
    "gui.loader.headline": "Les inn prosjekt",
    "gui.cameraModal.cameraModalTitle": "Ta eit bilete",
    "gui.cameraModal.loadingCameraMessage": "Startar kamera …",
    "gui.cameraModal.permissionRequest": "Du må gje Scratch løyve til å bruka kameraet",
    "gui.cameraModal.retakePhoto": "Ta bilete på nytt",
    "gui.cameraModal.save": "Lagra",
    "gui.cameraModal.takePhoto": "Ta eit bilete",
    "gui.cameraModal.loadingCaption": "Lastar …",
    "gui.cameraModal.enableCameraCaption": "Slå på kamera",
    "gui.recordModal.title": "Spel inn lyd",
    "gui.playbackStep.stopMsg": "Stopp",
    "gui.playbackStep.playMsg": "Spel av",
    "gui.playbackStep.loadingMsg": "Les inn …",
    "gui.playbackStep.saveMsg": "Lagra",
    "gui.playbackStep.reRecordMsg": "Spel inn på nytt",
    "gui.webglModal.label": "Nettlesaren din støttar ikkje WebGL",
    "gui.webglModal.webgllink": "ikkje støttar WebGL",
    "gui.prompt.cancel": "Avbryt",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Legg til eit felt",
    "gui.customProcedures.addAnInputBoolean": "Legg til eit felt",
    "gui.customProcedures.numberTextType": "tal eller tekst",
    "gui.customProcedures.booleanType": "logisk verdi",
    "gui.customProcedures.addALabel": "Legg til tekst",
    "gui.customProcedures.runWithoutScreenRefresh": "Køyr utan å oppdatera skjermen",
    "gui.customProcedures.cancel": "Avbryt",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Kopla prosjekta til den fysiske verda.",
    "gui.extension.music.name": "Musikk",
    "gui.extension.music.description": "Spel musikkinstrument.",
    "gui.extension.pen.name": "Penn",
    "gui.extension.pen.description": "Få figurane til å teikna.",
    "gui.gui.variableScopeOptionAllSprites": "For alle figurar",
    "gui.gui.variableScopeOptionSpriteOnly": "For denne figuren",
    "gui.gui.variablePromptAllSpritesMessage": "Variabelen vert tilgjengelege for alle figurane.",
    "gui.monitor.contextMenu.default": "vanleg skrift",
    "gui.monitor.contextMenu.large": "stor skrift",
    "gui.monitor.contextMenu.slider": "glidebrytar",
    "gui.monitor.contextMenu.import": "importer",
    "gui.monitor.contextMenu.export": "eksporter",
    "gui.opcodeLabels.direction": "retning",
    "gui.opcodeLabels.xposition": "x-posisjon",
    "gui.opcodeLabels.yposition": "y-posisjon",
    "gui.opcodeLabels.size": "storleik",
    "gui.opcodeLabels.costumename": "draktnamn",
    "gui.opcodeLabels.costumenumber": "draktnummer",
    "gui.opcodeLabels.backdropname": "bakgrunnsnamn",
    "gui.opcodeLabels.backdropnumber": "bakgrunnsnummer",
    "gui.opcodeLabels.volume": "lydstyrke",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "svar",
    "gui.opcodeLabels.loudness": "lydstyrke",
    "gui.opcodeLabels.year": "år",
    "gui.opcodeLabels.month": "månad",
    "gui.opcodeLabels.date": "dato",
    "gui.opcodeLabels.dayofweek": "vekedag",
    "gui.opcodeLabels.hour": "time",
    "gui.opcodeLabels.minute": "minutt",
    "gui.opcodeLabels.second": "sekund",
    "gui.opcodeLabels.timer": "sekundklokke",
    "music.categoryName": "Musikk",
    "translate.categoryName": "Omsetjing",
    "pen.categoryName": "Penn",
    "pen.changeColorParam": "endra pennens [COLOR_PARAM] med [VALUE]",
    "pen.changeHue": "endra pennefarge med [HUE]",
    "pen.changeShade": "endra penneskugge med [SHADE]",
    "pen.changeSize": "endra pennebreidd med [SIZE]",
    "pen.clear": "visk vekk alt",
    "pen.colorMenu.brightness": "lysstyrke",
    "pen.colorMenu.color": "farge",
    "pen.colorMenu.saturation": "fargemetting",
    "pen.colorMenu.transparency": "gjennomsikt",
    "pen.penDown": "penn ned",
    "pen.penUp": "penn opp",
    "pen.setColor": "bruk pennefargen [COLOR]",
    "pen.setColorParam": "set [COLOR_PARAM] til [VALUE]",
    "pen.setHue": "bruk pennefargen [HUE]",
    "pen.setShade": "set penneskugge til [SHADE]",
    "pen.setSize": "set pennebreidd til [SIZE]",
    "pen.stamp": "lag avtrykk",
    "music.changeTempo": "endra tempo med [TEMPO]",
    "music.drumBass": "(2) Stortromme",
    "music.drumBongo": "(13) Bongotromme",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Trepinnar",
    "music.drumClosedHiHat": "(6) Lukka hihat",
    "music.drumConga": "(14) Congatrommer",
    "music.drumCowbell": "(11) Kubjøller",
    "music.drumCrashCymbal": "(4) Krasjcymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Handklapping",
    "music.drumOpenHiHat": "(5) Open hihat",
    "music.drumSideStick": "(3) Kantslag",
    "music.drumSnare": "(1) Skarptromme",
    "music.drumTambourine": "(7) Tamburin",
    "music.drumTriangle": "(12) Triangel",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Trekloss",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Fagott",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Kor",
    "music.instrumentClarinet": "(10) Klarinett",
    "music.instrumentElectricGuitar": "(5) Elektrisk gitar",
    "music.instrumentElectricPiano": "(2) Elektrisk piano",
    "music.instrumentFlute": "(12) Fløyte",
    "music.instrumentGuitar": "(4) Gitar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Speledåse",
    "music.instrumentOrgan": "(3) Orgel",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saksofon",
    "music.instrumentSteelDrum": "(18) Oljefat",
    "music.instrumentSynthLead": "(20) Synthesizer (lead)",
    "music.instrumentSynthPad": "(21) Synthesizer (pad)",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Trefløyte",
    "music.midiPlayDrumForBeats": "trommeslag [DRUM] som varer [BEATS] takter",
    "music.midiSetInstrument": "bruk instrumentet [INSTRUMENT]",
    "music.playDrumForBeats": "trommeslag [DRUM] som varer [BEATS] takter",
    "music.playNoteForBeats": "spel tonen [NOTE] i [BEATS] takter",
    "music.restForBeats": "pause i [BEATS] takter",
    "music.setInstrument": "bruk instrumentet [INSTRUMENT]",
    "music.setTempo": "set tempo til [TEMPO]",
    "videoSensing.categoryName": "Sansing med kamera",
    "videoSensing.direction": "retning",
    "videoSensing.motion": "rørsle",
    "videoSensing.off": "av",
    "videoSensing.on": "på",
    "videoSensing.onFlipped": "spegelvend",
    "videoSensing.setVideoTransparency": "set gjennomsikt av video til [TRANSPARENCY]",
    "videoSensing.sprite": "figur",
    "videoSensing.stage": "scene",
    "videoSensing.videoOn": "video-[ATTRIBUTE] på [SUBJECT]",
    "videoSensing.videoToggle": "set video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "når videorørsle > [REFERENCE]",
    "translate.translateBlock": "set om [WORDS] til [LANGUAGE]",
    "translate.defaultTextToTranslate": "hei",
    "translate.viewerLanguage": "språk",
    "text2speech.speakAndWaitBlock": "les opp [WORDS]",
    "text2speech.setVoiceBlock": "bruk stemmen [VOICE]",
    "text2speech.setLanguageBlock": "set språket til [LANGUAGE]",
    "text2speech.alto": "alt",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "pipestemme",
    "text2speech.giant": "kjempe",
    "text2speech.kitten": "kattunge",
    "text2speech.defaultTextToSpeak": "hei",
    "speech.whenIHear": "når eg høyrer [PHRASE]",
    "speech.listenAndWait": "lytt og vent",
    "speech.defaultWhenIHearValue": "kom igjen",
    "paint.paintEditor.hue": "Farge",
    "paint.paintEditor.saturation": "Fargemetting",
    "paint.paintEditor.brightness": "Lysstyrke",
    "gui.comingSoon.message1": "Slapp av – me er på saka {emoji}",
    "gui.comingSoon.message2": "Kjem snart …",
    "gui.comingSoon.message3": "Me arbeider med det {emoji}",
    "paint.paintEditor.fill": "Fyll",
    "paint.paintEditor.costume": "Drakt",
    "paint.paintEditor.group": "Lag gruppe",
    "paint.paintEditor.ungroup": "Løys opp gruppe",
    "paint.paintEditor.undo": "Angra",
    "paint.paintEditor.redo": "Gjer om",
    "paint.paintEditor.forward": "Framover",
    "paint.paintEditor.backward": "Bakover",
    "paint.paintEditor.front": "Heilt fram",
    "paint.paintEditor.back": "Heilt bak",
    "paint.paintEditor.more": "Fleire",
    "paint.modeTools.brushSize": "Storleik",
    "paint.modeTools.eraserSize": "Viskelêrstorleik",
    "paint.modeTools.copy": "Kopier",
    "paint.modeTools.paste": "Lim inn",
    "paint.modeTools.delete": "Slett",
    "paint.modeTools.curved": "Glatt",
    "paint.modeTools.pointed": "Spiss",
    "paint.modeTools.thickness": "Strekbreidd",
    "paint.modeTools.flipHorizontal": "Spegelvend",
    "paint.modeTools.flipVertical": "Opp ned",
    "paint.modeTools.filled": "Fylt",
    "paint.modeTools.outlined": "Omriss",
    "paint.paintEditor.bitmap": "Gjer om til punktgrafikk",
    "paint.paintEditor.vector": "Gjer om til vektorgrafikk",
    "paint.paintEditor.stroke": "Kantlinje",
    "paint.brushMode.brush": "Målarpensel",
    "paint.eraserMode.eraser": "Viskelêr",
    "paint.fillMode.fill": "Fyll",
    "paint.lineMode.line": "Linje",
    "paint.ovalMode.oval": "Sirkel",
    "paint.rectMode.rect": "Rektangel",
    "paint.reshapeMode.reshape": "Endra form",
    "paint.roundedRectMode.roundedRect": "Avrunda rektangel",
    "paint.selectMode.select": "Vel",
    "paint.textMode.text": "Tekst",
    "paint.colorPicker.swap": "Byt",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "th": {
    "gui.gui.project": "โปรเจค",
    "gui.gui.newItem": "โปรเจคใหม่",
    "gui.gui.load": "เปิดโปรเตค",
    "gui.gui.save": "จัดเก็บโปรเจค",
    "gui.gui.saveAs": "จัดเก็บโปรเจคเป็น",
    "gui.gui.learn": "กำลังโหลด",
    "gui.gui.clearRecord": "ทำความสะอาดบันทึก",
    "gui.gui.onlineLearn": "เอกสารทางราชการ",
    "gui.gui.onlineForum": "ออนไลน์เว็บบอรด์",
    "gui.gui.videoTutorial": "วิดีโอการเรียนการสอน",
    "gui.gui.offlineLearn": "ตัวอย่างโปรแกรม",
    "gui.menuBar.turboModeOff": "ปิดโหมดเทอร์โบ",
    "gui.menuBar.turboModeOn": "เปิดโหมดเทอร์โบ",
    "gui.menuBar.edit": "แก้ไข",
    "gui.menuBar.restoreSprite": "คืนค่าสไปรต์",
    "gui.menuBar.restoreSound": "คืนค่าเสียง",
    "gui.menuBar.restoreCostume": "คืนค่ารูปแบบ",
    "gui.editorMind.restore": "คืนค่า",
    "gui.turboMode.active": "โหมดเทอร์โบ",
    "gui.gui.connectDevice": "เชื่อมต่ออุปกรณ์",
    "gui.gui.disconnectDevice": "ตัดการเชื่อมต่ออุปกรณ์",
    "gui.gui.installSerialportDriver": "ติดตั้งอุปกรณ์พอร์ตอนุกรม",
    "gui.gui.openDM": "เปิดตัวจัดการอุปกรณ์",
    "gui.gui.updateTips": "อัพเดทหมายเหตุ",
    "gui.gui.newVersion": "เวอร์ชั่นล่าสุด",
    "gui.gui.downloadUpdate": "ดาวน์โหลดอัพเดท",
    "gui.gui.versionUpdate": "อัพเดทเวอร์ชั่น",
    "gui.gui.downloading": "Checking Updator",
    "gui.gui.scratch": "ออนไลน์",
    "gui.gui.arduino": "ออฟไลน์",
    "gui.setting.feedbackMenu": "ฟีดแบค",
    "gui.setting.officialWebsit": "เว็บไซต์ทางการ",
    "gui.setting.officialQQGroup": "กรุ๊ปทางการQQ",
    "gui.setting.wechatOfficialAccounts": "บัญชีทางการวีแชท",
    "gui.setting.currentVersion": "เวอร์ชั่นปัจจุบัน",
    "gui.setting.checkForUpdate": "เช็คอัพเดท",
    "gui.setting.remindWhenUpdate": "เมื่อมีอัพเดทดาวน์โหลดอัตโนมัติ",
    "gui.setting.currentIsLatest": "เวอร์ชั่นปัจจุบันคือเวอร์ชั่นล่าสุดแล้ว",
    "gui.setting.latestVersion": "เวอร์ชั่นล่าสุด",
    "gui.setting.download": "ข้ามไปหน้าดาวน์โหลด",
    "gui.setting.language": "ภาษา",
    "gui.setting.uploadCsv": "อัพโหลดxlsx",
    "gui.setting.theme": "ธีม",
    "gui.setting.feedback": "ติดต่อ",
    "gui.setting.email": "อีเมล",
    "gui.setting.opinionFeedback": "ฟีดแบค",
    "gui.variableMc.variableName": "ชื่อตัวแปร",
    "gui.variableMc.variableOff": "ยกเลิก",
    "gui.variableMc.variableOn": "ยืนยัน",
    "gui.variableMc.newVariable": "ตัวแปรใหม่",
    "gui.setting.helpUsTranslate": "ช่วยพวกเราแปล",
    "gui.setting.checkUpdate": "เช็คอัพเดท",
    "gui.setting.ok": "ส่ง",
    "gui.setting.languageSetting": "ภาษา",
    "gui.setting.themeSetting": "หัวข้อ",
    "gui.setting.versionUpdate": "อัพเดทเวอร์ชั่น",
    "gui.setting.connectUsTitle": "ติดต่อพวกเรา",
    "gui.setting.uploadAttachment": "อัพโหลดสิ่งที่แนบมา",
    "gui.setting.displayTitle": "แสดงตั้งค่า",
    "gui.setting.fontSizeSetting": "ขนาดตัวอักษร",
    "gui.setting.restartEffect": "จะมีผลเมื่อเริ่มต้นใหม่",
    "gui.setting.fontSizeLarge": "ใหญ่",
    "gui.setting.fontSizeMiddle": "กลาง",
    "gui.setting.fontSizeSmall": "เล็ก",
    "gui.backpack.header": "กระเป๋าเป้",
    "gui.backpack.errorBackpack": "เกิดความผิดพลาดขณะโหลดกระเป๋าเป้",
    "gui.backpack.loadingBackpack": "กำลังโหลด...",
    "gui.backpack.more": "เพิ่มเติม",
    "gui.backpack.emptyBackpack": "กระเป๋าเป้ว่างเปล่า",
    "gui.gui.blocks": "บล็อก",
    "gui.gui.costumesTab": "คอสตูม",
    "gui.gui.soundsTab": "เสียง",
    "gui.gui.backdropsTab": "ฉากหลัง",
    "gui.gui.addExtension": "ขยาย",
    "gui.costumeTab.addCostumeFromLibrary": "เลือกคอสตูม",
    "gui.costumeLibrary.chooseACostume": "เลือกคอสตูม",
    "gui.costumeTab.addBackdropFromLibrary": "เลือกฉากหลัง",
    "gui.costumeTab.addBlankCostume": "วาดรูป",
    "gui.costumeTab.addSurpriseCostume": "เซอร์ไพรส์",
    "gui.costumeTab.addFileBackdrop": "อัปโหลดฉากหลัง",
    "gui.costumeTab.addFileCostume": "อัปโหลดคอสตูม",
    "gui.costumeTab.addCameraCostume": "กล้อง",
    "gui.soundEditor.trim": "ตัดแต่ง",
    "gui.soundEditor.stop": "หยุด",
    "gui.soundEditor.sound": "เสียง",
    "gui.soundEditor.play": "เล่น",
    "gui.soundEditor.save": "บันทึก",
    "gui.soundEditor.undo": "ย้อนกลับ",
    "gui.soundEditor.redo": "ทำซ้ำ",
    "gui.soundEditor.faster": "เร็วขึ้น",
    "gui.soundEditor.slower": "ช้าลง",
    "gui.soundEditor.echo": "สะท้อน",
    "gui.soundEditor.robot": "หุ่นยนต์",
    "gui.soundEditor.louder": "ดัง",
    "gui.soundEditor.softer": "เบา",
    "gui.soundEditor.reverse": "กลับด้าน",
    "gui.soundTab.recordSound": "บันทึก",
    "gui.soundTab.addSoundFromLibrary": "เลือกเสียง",
    "gui.soundTab.surpriseSound": "เซอร์ไพรส์",
    "gui.soundTab.fileUploadSound": "อัปโหลดเสียง",
    "gui.soundTab.addSound": "เพิ่มเสียง",
    "gui.controls.stop": "หยุด",
    "gui.controls.go": "ไป",
    "gui.gui.startPython": "เริ่มใช้งาน Python",
    "gui.gui.stopPython": "หยุดการใช้งาน Python",
    "gui.controls.fullScreenControl": "แบบเต็มจอ",
    "gui.gui.stageSizeLarge": "แบบจอใหญ่",
    "gui.gui.stageSizeSmall": "แบบจอเล็ก",
    "gui.gui.stageSizeNostage": "ปรับโหมดการแสดงขนาดเป็นไม่มีสถานะ",
    "gui.gui.stageSizeFull": "ปรับโหมดการแสดงขนาดเป็นเต็มจอ",
    "gui.stageHeader.stageSizeUnFull": "ออกจากโหมดเต็มจอ",
    "gui.sprite.sprite": "สไปรต์",
    "gui.SpriteInfo.show": "แสดง",
    "gui.SpriteInfo.size": "ขนาด",
    "gui.sprite.direction": "ทิศทาง",
    "gui.sprite.rotation": "หมุน",
    "gui.directionPicker.rotationStyles.allAround": "รอบด้าน",
    "gui.directionPicker.rotationStyles.leftRight": "ซ้าย/ขวา",
    "gui.directionPicker.rotationStyles.dontRotate": "อย่าหมุน",
    "gui.spriteSelectorItem.contextMenuDuplicate": "ทำซ้ำ",
    "gui.spriteSelectorItem.contextMenuDelete": "ลบ",
    "gui.spriteSelectorItem.contextMenuExport": "ส่งออก",
    "gui.sprite.addSpriteFromLibrary": "ห้องสมุดสไปรต์",
    "gui.spriteSelector.addSpriteFromPaint": "วาดรูป",
    "gui.spriteSelector.addSpriteFromSurprise": "เซอร์ไพรส์",
    "gui.spriteSelector.addSpriteFromFile": "อัปโหลดสไปรต์",
    "gui.sprite.addSpriteFromCamera": "กล้อง",
    "gui.stageSelector.stage": "เวที",
    "gui.stageSelector.backdrops": "ฉากหลัง",
    "gui.stage.addBackdropFromLibrary": "เลือกฉากหลัง",
    "gui.stageSelector.addBackdropFromPaint": "วาดรูป",
    "gui.stageSelector.addBackdropFromSurprise": "เซอร์ไพรส์",
    "gui.stageSelector.addBackdropFromFile": "อัปโหลดฉากหลัง",
    "gui.stage.addBackdropFromCamera": "กล้อง",
    "gui.modal.back": "ย้อนกลับ",
    "gui.extension.kit": "ชุด",
    "gui.extension.kitTitle": "เลือกชุด",
    "gui.extension.board": "บอร์ด",
    "gui.extension.boardTitle": "เลือกบอร์ด",
    "gui.extension.spreadBoard": "ส่วนขยาย",
    "gui.extension.spreadBoardTitle": "เลือกส่วนขยาย",
    "gui.extension.sensor": "เซนเซอร์",
    "gui.extension.sensorTitle": "เลือกเซนเซอร์",
    "gui.extension.actuator": "ตัวกระตุ้นการทำงาน",
    "gui.extension.actuatorTitle": "เลือกตัวกระตุ้น",
    "gui.extension.communicationModule": "โมดูลสื่อสาร",
    "gui.extension.communicationModuleTitle": "เลือกโมดูลสื่อสาร",
    "gui.extension.display": "จอแสดงผล",
    "gui.extension.displayTitle": "เลือกจอแสดงผล",
    "gui.extension.function": "ฟังก์ชั่นบล๊อก",
    "gui.extension.functionTitle": "เลือกฟังก์ชั่นบล๊อก",
    "gui.extension.internetService": "อินเทอร์เน็ต",
    "gui.extension.internetServiceTitle": "เลือกอินเทอร์เน็ต",
    "gui.extension.arduinContentLabel": "เลือกอุปกรณ์",
    "gui.library.filterPlaceholder": "ค้นหา",
    "gui.libraryTags.all": "ทั้งหมด",
    "gui.libraryTags.animals": "สัตว์",
    "gui.libraryTags.dance": "การเต้นรำ",
    "gui.libraryTags.effects": "เอฟเฟกต์",
    "gui.libraryTags.fantasy": "ความเพ้อฝัน",
    "gui.libraryTags.fashion": "แฟชั่น",
    "gui.libraryTags.food": "อาหาร",
    "gui.libraryTags.indoors": "ในบ้าน",
    "gui.libraryTags.loops": "ลูป",
    "gui.libraryTags.music": "ดนตรี",
    "gui.libraryTags.notes": "โน้ต",
    "gui.libraryTags.outdoors": "นอกบ้าน",
    "gui.libraryTags.patterns": "ลวดลาย",
    "gui.libraryTags.people": "ผู้คน",
    "gui.libraryTags.percussion": "เครื่องเคาะ",
    "gui.libraryTags.space": "อวกาศ",
    "gui.libraryTags.sports": "กีฬา",
    "gui.libraryTags.underwater": "ใต้น้ำ",
    "gui.libraryTags.voice": "เสียงคน",
    "gui.libraryTags.wacky": "ประหลาด",
    "gui.libraryTags.animation": "แอนิเมชัน",
    "gui.libraryTags.art": "ศิลปะ",
    "gui.libraryTags.games": "เกม",
    "gui.libraryTags.stories": "เรื่องราว",
    "gui.libraryTags.letters": "ตัวอักษร",
    "gui.extension.backdropLib": "เลือกพื้นหลัง",
    "gui.soundLibrary.chooseASound": "เลือกเสียง",
    "gui.SpriteInfo.spritePlaceholder": "ชื่อ",
    "gui.extension.spriteLib": "เลือกสไปรต์",
    "gui.gui.unselectedSerialport": "ไม่ได้เชื่อมต่ออุปกรณ์",
    "gui.gui.unConnectedDev": "ไม่ได้เชื่อมต่ออุปกรณ์",
    "gui.gui.pythonMode": "โหมด Python",
    "gui.gui.burnFirmware": "เขียนเฟิร์มแวร์อีกครั้ง",
    "gui.gui.burnFirmwareError": "เขีียนเฟิร์มแวร์ไม่สำเร็จ",
    "gui.gui.connected": "เชื่อมต่ออุปกรณ์สำเร็จ",
    "gui.gui.failedConnected": "เชื่อมต่ออุปกรณ์ล้มเหลว",
    "gui.gui.connecting": "กำลังเชื่อมต่ออุปกรณ์",
    "gui.cards.all-how-tos": "How-Tos ทั้งหมด",
    "gui.cards.how-tos": "เข้าการเรียนการสอน",
    "gui.cards.remove": "ออกจากการเรียนการสอน",
    "gui.cards.more-things-to-try": "มีอีกเยอะให้ลอง!",
    "gui.cards.see-more": "ดูเพิ่มเติม",
    "gui.cards.shrink": "หด",
    "gui.cards.expand": "คลี่ออก",
    "gui.cards.close": "ปิด",
    "gui.loader.message1": "กำลังสร้างบล็อก ...",
    "gui.loader.message2": "กำลังโหลดสไปรต์ …",
    "gui.loader.message3": "กำลังโหลดเสียง ...",
    "gui.loader.message4": "กำลังโหลดส่วนขยาย ...",
    "gui.loader.message5": "จับแมวใส่กระด้ง …",
    "gui.loader.message6": "กำลังส่ง Nano …",
    "gui.loader.message7": "กำลังเป่า Gobo …",
    "gui.loader.message8": "กำลังเตรียมอิโมจิ ...",
    "gui.loader.headline": "กำลังโหลดโครงงาน",
    "gui.cameraModal.cameraModalTitle": "ถ่ายรูป",
    "gui.cameraModal.loadingCameraMessage": "กำลังโหลดกล้อง...",
    "gui.cameraModal.permissionRequest": "เราต้องได้รับอนุญาตจากคุณเพื่อใช้กล้อง",
    "gui.cameraModal.retakePhoto": "ถ่ายรูปใหม่",
    "gui.cameraModal.save": "บันทึก",
    "gui.cameraModal.takePhoto": "ถ่ายรูป",
    "gui.cameraModal.loadingCaption": "กำลังโหลด...",
    "gui.cameraModal.enableCameraCaption": "เปิดใช้งานกล้อง",
    "gui.recordModal.title": "บันทึกเสียง",
    "gui.recordStep.recordByClickBtn": "กดปุ่มด้านล่างเพื่อเริ่มบันทึกเสียง",
    "gui.recordStep.permissionMicrophone": "พวกเราต้องการการอนุญาตจากคุณเพื่อใช้ไมโครโฟน",
    "gui.recordStep.record": "บันทึก",
    "gui.recordStep.stopRecord": "หยุดบันทึก",
    "gui.playbackStep.stopMsg": "หยุด",
    "gui.playbackStep.playMsg": "เล่น",
    "gui.playbackStep.loadingMsg": "กำลังโหลด...",
    "gui.playbackStep.saveMsg": "บันทึก",
    "gui.playbackStep.reRecordMsg": "บันทึกใหม่",
    "gui.webglModal.label": "เบราเซอร์ของคุณไม่รองรับ WebGL",
    "gui.webglModal.descriptionMind": "เสียใจด้วย ดูเหมือนว่าคอมพิวเตอร์ของคุณต้องการ {webG|Link}. ในการใช้โปรแกรม Mind+. {updateGpuDriver}",
    "gui.webglModal.webgllink": "ไม่รองรับ WebGL",
    "gui.webglModal.updateGpuDriver": "กรุณาอัพเดทไดรเวอร์ของการ์ดจอของคุณ",
    "gui.webglModal.ok": "OK",
    "gui.extension.scratchExampleLib": "ห้องสมุดที่เก็บรายตัวอย่างการของ Scratch",
    "gui.extension.pythonExampleLib": "ห้้องสมุดที่เก็บรายการตัวอย่างของ Python",
    "gui.extension.arduinoExampleLib": "ห้้องสมุดที่เก็บรายการตัวอย่างของ Arduino",
    "gui.prompt.cancel": "ยกเลิก",
    "gui.prompt.ok": "ตกลง",
    "gui.extension.makeBlock": "สร้างบล็อกใหม่",
    "gui.customProcedures.addAnInputNumberText": "เพิ่มช่องรับ",
    "gui.customProcedures.addAnInputBoolean": "เพิ่มช่องรับ",
    "gui.customProcedures.numberTextType": "ตัวเลขหรือข้อความ",
    "gui.customProcedures.booleanType": "บูลีน",
    "gui.customProcedures.addALabel": "เพิ่มข้อความ",
    "gui.customProcedures.runWithoutScreenRefresh": "รันโดยไม่รีเฟรชหน้าจอ ",
    "gui.customProcedures.cancel": "ยกเลิก",
    "gui.customProcedures.ok": "ตกลง",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "Vortex เป็นหุ่นยนต์ที่สร้างขึ้นเพื่อการศึกษาจากบริษัท DFRobot",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "Explorer D1 หุ่นยนต์เพื่อการศึกษาที่สร้างขึ้นโดย DFRobot",
    "gui.extension.arduinounoR3.name": "ArduinounoR3",
    "gui.extension.arduinounoR3.description": "อุปกรณ์แผงบอร์ดควบคุมหลักของ Arduino Uno ที่ผลิตขึ้นเฉพาะโดย Wuhan Maker Course",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "Max:bot หุ่นยนต์ที่สร้างขึ้นจากหลักการพื้นฐานของ micro:bit",
    "gui.extension.max.name": "MAX",
    "gui.extension.max.description": "MAX（ROB0137）หุ่นยนต์ที่สร้างขึ้นจากหลักการพื้นฐานของ Arduino",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "Motor:bit กระดานเพื่อการขยายมอเตอร์และเซอร์โว",
    "gui.extension.maqueen.name": "micro:Maqueen",
    "gui.extension.maqueen.description": "micro:Maqueen หุ่นยนต์ที่สร้างขึ้นจากหลักการพื้นฐานของ micro:bit",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.microbit.description": "เชื่อมต่อโครงงานของคุณกับโลก",
    "gui.extension.spread.name": "micro:bit & mPython expansion board",
    "gui.extension.spreadMaqueen.name": "maqueen extension",
    "gui.extension.spread.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadmaqueen.description": "รถขนาดเล็กกลุ่มสำหรับผู้เริ่มต้นจากกลุ่มควบคุม",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "The advanced educational robot that supports both micro:bit and mPython",
    "gui.extension.iot.name": "IoT",
    "gui.extension.iot.description": "ขึ้นอยู่กับ IoT",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "อุปกรณ์ควบคุมหลักของ Leonardo",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "อุปกรณ์ควบคุมหลักของ Arduino Uno",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "อุปกรณ์ควบคุมหลักของ Arduino Nano",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "กรุะดานควบคุมหลักสร้างจากหลักการพื้นฐานของ ESP32",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "อุุปกรณ์ที่ถูกควบคุมโดย Mega2560",
    "gui.extension.dfr0299.name": "โมดูลMP3 DFPlayer",
    "gui.extension.dfr0299.description": "โมดูลเครื่องเล่น MP3",
    "gui.extension.ser0006.name": "โมดูลเซอร์โว",
    "gui.extension.ser0006.description": "เซอร์โวขนาดเล็ก",
    "gui.extension.dfr0523.name": "ปั๊มดูดจ่าย",
    "gui.extension.dfr0523.description": "ขนถ่ายของเหลวด้วยการบีบรัดและคลายปั๊มสลับกันไปมา",
    "gui.extension.dfr0017.name": "โมดูลรีเลย์",
    "gui.extension.dfr0017.description": "ควบคุุมอุปกรณ์ที่มีกระแสไฟสูง",
    "gui.extension.steppermotor.name": "สเต็ปปิ้งมอเตอร์",
    "gui.extension.steppermotor.description": "ตรวจจับตำแหน่งได้อย่างแม่นยำด้วยระบบควบคุมชีพจร",
    "gui.extension.dfr0534.name": "พอร์ตโมดูลMP3",
    "gui.extension.dfr0534.description": "พอร์ตโมดูลเสียงMP3",
    "gui.extension.servo360.name": "360° Micro Servo",
    "gui.extension.servo360.description": "ควบคุมความเร็วและทิศทาง",
    "gui.extension.tel0118.name": "โมดููล OBLOQ IoT",
    "gui.extension.tel0118.description": "เครื่องแปลง WiFi เป็น Serial",
    "gui.extension.dfr0095.name": "โมดูลส่งสัญญาณอินฟาเรด",
    "gui.extension.dfr0095.description": "ส่งสัญญาณพื้นฐานอินฟาเรดที่คลื่นความถี่ 38KHz",
    "gui.extension.dfr0094.name": "โมดูลรับสัญญาณอินฟาเรด",
    "gui.extension.dfr0094.description": "รับสัญญาณพื้นฐานอินฟาเรดที่คลื่นความถี่ 38KHz",
    "gui.extension.tel0094.name": "โมดูลรับสัญญาณ GPS",
    "gui.extension.tel0094.description": "โมดูลรับสัญญาณ GPS",
    "gui.extension.tel0026.name": "โมดูลบลูทูธ",
    "gui.extension.tel0026.description": "โมดูลพอร์ท Bluetooth แบบ serial",
    "gui.extension.dfr0486.name": "จอแสดงผลชนิด OLED-12864",
    "gui.extension.dfr0486.description": "โมดูลแสดงผล I2C OLED-12864",
    "gui.extension.fit0352.name": "สาย WS2812 RGB LED",
    "gui.extension.fit0352.description": "สาย LED ที่ใช้หลักการพื้นฐานจาก WS2812",
    "gui.extension.dfr0063.name": "หน้าจอแสดงผลแบบ LCD1602",
    "gui.extension.dfr0063.description": "โมดูล LCD ที่สามารถแสดงผลได้ 2 บรรทัดโดยแต่ละบรรทัดมี 16 ตัวอักษร",
    "gui.extension.dfr0021.name": "โมดูลไฟ LED แบบดิจิทัล",
    "gui.extension.dfr0021.description": "โมดูล LED สีขาว, แดง, เขียว, และฟ้า",
    "gui.extension.tm1650.name": "หลอดแสดงทศนิยม 4 หลัก แบบ TM1650",
    "gui.extension.tm1650.description": "หลอดแสดงทศนิยม 4 หลัก",
    "gui.extension.matrix8_8.name": "MAX7219 8x8ดอทเมทริกซ์",
    "gui.extension.matrix8_8.description": "โมดูลแสดงมินิดอทเมทริกซ์",
    "gui.extension.dfr0522.name": "8×16 RGB LEDแผงแสดงดอทเมทริกซ์",
    "gui.extension.dfr0522.description": "สามารถใช้ทำแผงหน้าหุ่นยนต์、วิเคราะห์สเปกตรัมสี\r\r\n",
    "gui.extension.music.name": "ดนตรี",
    "gui.extension.music.description": "เล่นเครื่องดนตรีและกลอง",
    "gui.extension.pen.name": "ปากกา",
    "gui.extension.pen.description": "วาดด้วยสไปรต์ของคุณ",
    "gui.extension.video-sensing.name": "ตรวจจับวิดีโอ",
    "gui.extension.video-sensing.description": "ใช้กล้องตรวจจับการเคลื่อนไหว.",
    "gui.extension.google-translate.name": "Google Translate",
    "gui.extension.google-translate.description": "แปลข้อความเป็นหลากหลายภาษา",
    "gui.extension.text-speech.name": "อ่านข้อความ",
    "gui.extension.text-speech.description": "ให้โครงการของคุณพูด",
    "gui.extension.softSerialport.name": "ซอฟต์แวร์พอร์ตอนุกรม",
    "gui.extension.softSerialport.description": "ใช้I/Oพอร์ตจำลองฟังก์ชั่นการสื่อสารแบบอนุกรม\r\r\n",
    "gui.extension.IICScan.name": "I2C สแกรน",
    "gui.extension.IICScan.description": "สแกนสิ่งที่รับจากที่อยู่อุปกรณ์พอร์ตI2Cทั้งหมด",
    "gui.extension.bluetooth.name": "บลูทูธ",
    "gui.extension.bluetooth.description": "อุปกรณ์บลูทูธ",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "GoBle APP，สามารถช่วยคุณควบคุมบอร์ดควบคุมหลักของมือถือ\r\r\n",
    "gui.extension.interrupt.name": "pin อินเตอร์รัพท์",
    "gui.extension.interrupt.description": "pin อินเตอร์รัพท์",
    "gui.extension.multithread.name": "MultiThread",
    "gui.extension.multithread.description": "อนุญาตให้ระบบหลายโปรแกรมทำงานพร้อมกับโปรแกรมหลัก",
    "gui.extension.speech.name": "Speech",
    "gui.extension.speech.description": "พูดกับโปรเจคของคุณ",
    "gui.extension.sen0001.name": "อัลตร้าโซนิคเซ็นเซอร์",
    "gui.extension.sen0001.description": "ระยะที่แม่นยำ，ระยะ2～800cm",
    "gui.extension.dfr0023.name": "LM35เซ็นเซอร์อุณหภูมิแบบอะนาล็อกเชิงเส้น",
    "gui.extension.dfr0023.description": "วัดอุณหภูมิ，ขอบเขต0～100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22เซ็นเซอร์อุณหภูมิและความชื้น\r\r\n",
    "gui.extension.dhtTHSensor.description": "วัดอุณหภูมิโดยรอบ、ความชื้น",
    "gui.extension.dsTSensor.name": "DS18B20เซ็นเซอร์อุณหภูมิ",
    "gui.extension.dsTSensor.description": "วัดอุณหภูมิ，ของเขต－55～＋125℃\r\r\n",
    "gui.extension.sen0203.name": "เซ็นเซอร์วัดอัตราการเต้นของหัวใจ",
    "gui.extension.sen0203.description": "การวัดอัตราการเต้นหัวใจของร่างกาย\r\r\n",
    "gui.extension.sen0177.name": "เลเซอร์เซ็นเซอร์คุณภาพอากาศ PM2.5",
    "gui.extension.sen0177.description": "การวัดความเข้มข้นของฝุ่นละอองในอากาศ，เช่นPM2.6\r\r\n",
    "gui.extension.sen0014.name": "เซ็นเซอร์วัดช่วงอินฟราเรด\r\r\n",
    "gui.extension.sen0014.description": "ระยะตรวจจับ，ขอบเขต10～80cm\r\r\n",
    "gui.extension.sen0204.name": "เซ็นเซอร์ระดับ (แบบไร้สัมผัส)\r\n",
    "gui.extension.sen0204.description": "เซนเซอร์วัดระดับน้ำ แบบไร้สัมผัส",
    "gui.extension.sen0161.name": "เครื่องวัดค่า pH แบบอะนาล็อก\r\n",
    "gui.extension.sen0161.description": "ค่าระดับน้ำpH（pH）,แหล่งจ่ายไฟ5V",
    "gui.extension.sen0161-v2.name": "เครื่องวัดค่า pH แบบอะนาล็อกpH计(V2)",
    "gui.extension.sen0161-v2.description": "ค่าระดับน้ำpH（pH）,แหล่งจ่ายไฟ3.3～5.5V",
    "gui.extension.sen0244.name": "เซนเซอร์TDSอะนาล็อก",
    "gui.extension.sen0244.description": "วัดระดับน้ำของTDS（ปริมาณของแข็งที่ละลายทั้งหมด）,ทำปฏิกิริยาความสะอาดของน้ำ",
    "gui.extension.sen0165.name": "เครื่องวัดค่า ORP แบบอะนาล็อก",
    "gui.extension.sen0165.description": "วัดระดับน้ำของORP（ศักยภาพในการลดการเกิดออกซิเดชัน），ประเมินคุณภาพน้ำ",
    "gui.extension.sen0237.name": "เครื่องวัดค่า DO แบบอะนาล็อก",
    "gui.extension.sen0237.description": "วัดปริมาณออกซิเจนในน้ำ，ประเมินคุณภาพน้ำ",
    "gui.extension.dfr0300-H.name": "เครื่องวัดค่า EC แบบอะนาล็อก",
    "gui.extension.dfr0300-H.description": "วัดค่าการนำไฟฟ้าของสารละลาย，ประเมินคุณภาพน้ำ",
    "gui.extension.dfr0300.name": "เครื่องวัดค่า EC แบบอะนาล็อก(V2)",
    "gui.extension.dfr0300.description": "วัดค่าการนำไฟฟ้าของสารละลาย，ประเมินคุณภาพน้ำ",
    "gui.extension.sen0170.name": "เซ็นเซอร์ความเร็วลม",
    "gui.extension.sen0170.description": "วัดความเร็วลม",
    "gui.extension.sen0226.name": "BMP280เซนเซอร์อุณหภูมิ\r\r\n",
    "gui.extension.sen0226.description": "วัดอุณหภูมิ, ความดันอากาศ",
    "gui.extension.sen0228.name": "เซ็นเซอร์วัดแสงรอบข้าง",
    "gui.extension.sen0228.description": "ความแม่นยำของเซ็นเซอร์วัดความเข้มแสงรอบข้าง",
    "gui.extension.dfr0022.name": "เซ็นเซอร์ระดับสีเทาแบบอะนาล็อก",
    "gui.extension.dfr0022.description": "ตรวจจับสีต่างๆ, สามารถใช้สำหรับการตรวจสอบ",
    "gui.extension.dfr0552.name": "12บิทDAสลับโมดูล",
    "gui.extension.dfr0552.description": "ความแม่นยำในการแปลเป็นสัญญาณแรงดันด้วยระบบดิจิตอล（ไม่ใช่PWM）",
    "gui.extension.dfr0576.name": "I2 ขยายน้ำตก",
    "gui.extension.dfr0576.description": "ใช้ในการแก้ไขที่อยู่ที่ขัดข้องในเครื่องI2C",
    "gui.extension.sen0248.name": "BME680เซนเซอร์ตรวจจับสภาพแวดล้อม",
    "gui.extension.sen0248.description": "สามารถวัดVOC（สารประกอบอินทรีย์ระเหยง่าย）อุณหภูมิ, ความชื้น,ความกดอากาศ,พารามิเตอร์สี่ตัวนี้",
    "gui.extension.sen0304.name": "I2Cอัลตร้าโซนิคเซ็นเซอร์",
    "gui.extension.sen0304.description": "เซ็นเซอร์อัลตราโซนิกที่ออกแบบมาสำหรับการใช้งานที่รวดเร็วหรือหลีกเลี่ยงสิ่งกีดขวาง",
    "gui.extension.sen0307.name": "อัลตร้าโซนิคเซ็นเซอร์แบบอะนาล็อก",
    "gui.extension.sen0307.description": "เปิดเซ็นเซอร์อัลตราโซนิกแบบอะนาล็อกคู่",
    "gui.extension.sen0250.name": "BMI160 เซ็นเซอร์ตรวจจับความเคลื่อนไหวเฉื่อย 6แกน",
    "gui.extension.sen0250.description": "16บิท3แกน เครื่องวัดการหมุนวน เพิ่มความเร็ว+ใช้พลังงานต่ำ3แกน",
    "gui.extension.sen0224.name": "I2C LIS2DH เครื่องเร่งสามแกน",
    "gui.extension.sen0224.description": "เซ็นเซอร์วัดความเร็วใช้พลังงานต่ำ3แกน",
    "gui.extension.dfr0151.name": "เวลาจริงDS1307",
    "gui.extension.dfr0151.description": "เอ้าท์พุต, ปี、เดือน、วัน、สัปดาห์、ชั่วโมง、นาที、วินาที",
    "gui.extension.dfr0469.name": "เวลาจริงSD2405",
    "gui.extension.dfr0469.description": "เข้าถึง ปี、เดือน、วัน、สัปดาห์、ชั่วโมง、นาที、วินาที",
    "gui.extension.dfr0126.name": "โมดูลการวิเคราะห์สเปกตรัม",
    "gui.extension.dfr0126.description": "วิเคราะห์7ความถี่เสียง",
    "gui.extension.dfr0231.name": "โมดูล NFC",
    "gui.extension.dfr0231.description": "ใช้สำหรับสื่อสารไร้สายระยะใกล้, สามารถใช้อ่านบัตรเขียนบัตร",
    "gui.extension.sen0245.name": "VL53L0Xเซ็นเซอร์วัดระยะด้วยแสงเลเซอร์",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "เซ็นเซอร์วัดแสงรอบข้างแบบอะนาล็อก",
    "gui.extension.dfr0026.description": "วัดความเข้มของแสงโดยรอบ",
    "gui.extension.dfr0027.name": "เซ็นเซอร์สั่นสะเทือนดิจิตอล",
    "gui.extension.dfr0027.description": "ตรวจจับสัญญาณการสั่นสะเทือน, ผลลัพธ์สัญญาณดิจิตอล",
    "gui.extension.dfr0028.name": "เซ็นเซอร์เอียงลูกเหล็กดิจิตอล",
    "gui.extension.dfr0028.description": "สามารถใช้เป็นสวิตช์ดิจิตอล，ไม่สามารถวัดมุมเอียงได้",
    "gui.extension.dfr0029.name": "โมดูลปุ่มดิจิตอลขนาดใหญ่",
    "gui.extension.dfr0029.description": "กดไฟฟ้าระดับสูง กดไฟฟ้าระดับต่ำ",
    "gui.extension.dfr0030.name": "เซ็นเซอร์สัมผัสดิจิตอล",
    "gui.extension.dfr0030.description": "สวิตช์แบสัมผัส, สามารถสัมผัสร่างกายมนุษย์, โลหะ อื่นๆ",
    "gui.extension.dfr0033.name": "เซ็นเซอร์แม่เหล็กดิจิตอล",
    "gui.extension.dfr0033.description": "ตรวจจับวัตถุแม่เหล็ก ภายใน3cm",
    "gui.extension.dfr0034.name": "เซ็นเซอร์ตรวจจับเสียงแบบอะนาล็อก",
    "gui.extension.dfr0034.description": "การวัดความเข้มของเสียงในสภาพแวดล้อม",
    "gui.extension.sen0132.name": "เซ็นเซอร์วัดก๊าซคาร์บอนมอนอกไซด์แบบอะนาล็อก",
    "gui.extension.sen0132.description": "วัดความเข้มข้นของก๊าซคาร์บอนมอนอกไซด์，ขอบเขต20～2000ppm",
    "gui.extension.dfr0051.name": "โมดูลแรงดันไฟฟ้าแบบอะนาล็อก",
    "gui.extension.dfr0051.description": "วัดDC 25Vลงไป（DC）แรงดันไฟฟ้า",
    "gui.extension.dfr0052.name": "เซ็นเซอร์วัดความสั่นสะเทือนPiezo Diskแบบอะนาล็อก",
    "gui.extension.dfr0052.description": "ตรวจจับสัญญาณการสั่นสะเทือน, ส่งออกสัญญาณอะนาล็อก",
    "gui.extension.dfr0058.name": "เซ็นเซอร์วัดการหมุนแบบอะนาล็อก",
    "gui.extension.dfr0058.description": "ปรับแรงดันไฟฟ้าตามโพเทนชิออมิเตอร์ความแม่นยำสูง",
    "gui.extension.dfr0061.name": "โมดูลJoyStick",
    "gui.extension.dfr0061.description": "สามารถใช้จอยสติ๊ก，อะนาล็อก2แกน，ดิจิตอล1สาย",
    "gui.extension.dfr0072.name": "Shiftout เซนเซอร์",
    "gui.extension.dfr0072.description": "ใช้SPIสื่อสารสามารถขยายโมดูลหลอดดิจิตอล",
    "gui.extension.dfr0563.name": "เซ็นเซอร์แบตเตอรี่",
    "gui.extension.dfr0563.description": "วัดแรงดังไฟและไฟที่ใช้ไปแล้วของแบตเตอรี่",
    "gui.extension.dfr0553.name": "16บิทADสลับโมดูล",
    "gui.extension.dfr0553.description": "16บิทADสลับโมดูลสามารถรับสัญญาณอะนาล็อกได้อย่างแม่นยำและแปลง",
    "gui.extension.dfr0076.name": "เซนเซอร์เปลวไฟ",
    "gui.extension.dfr0076.description": "ตรวจจับแหล่งที่มาของไฟหรือความยาวคลื่นที่760～1100คลื่นนาโน",
    "gui.extension.dfr0143.name": "เซ็นเซอร์เร่งสามแกน",
    "gui.extension.dfr0143.description": "ตรวจจับท่าทาง และทิศทางของวัตถุ",
    "gui.extension.sen0018.name": "เซนเซอร์ตรวจจับความเคลื่อนไหวดิจิตอลอินฟราเรด",
    "gui.extension.sen0018.description": "ตรวจจับความเคลื่อนไหวของคน และสัตว์โดยรังสีอินฟราเรด",
    "gui.extension.sen0114.name": "เซ็นเซอร์ความชื้นในดิน",
    "gui.extension.sen0114.description": "ตรวจจับความชื้นในดิน，เมื่อไม่มีน้ำค่าเซนเซอร์จะลดต่ำลง",
    "gui.extension.sen0121.name": "เซนเซอร์ไอน้ำ",
    "gui.extension.sen0121.description": "ค้นพบน้ำฝน, ไอน้ำและละอองน้ำ",
    "gui.extension.sen0212.name": "เซนเซอร์วิเคราะห์สี",
    "gui.extension.sen0212.description": "วิเคราะห์สีของวัตถุ，ค่าผลลัพธ์เป็นRGB",
    "gui.extension.sen0253.name": "10DOF เซนเซอร์ท่าทาง",
    "gui.extension.sen0253.description": "BNO055+BMP280อัจฉริยะ10DOFเซนเซอร์ที่มีความแม่นยำแน่นอน",
    "gui.extension.sen0290.name": "เซนเซอร์ฟ้าผ่า",
    "gui.extension.sen0290.description": "เซนเซอร์ฟ้าผ่า",
    "gui.extension.sen0202.name": "มินิเซนเซอร์ตรวจจับท่าทาง3D",
    "gui.extension.sen0202.description": "ระบุทิศทางของการเคลื่อนไหวของนิ้วมือและการหมุนนิ้ว、ทวนเข็มนาฬิกา",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "micro:bitใช้ส่วนขยายแบตเตอลี่มอเตอร์",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "ให้อุปกรณ์เชื่อมต่อเครือช่ายWi-Fi",
    "gui.extension.udp.name": "UDP broadcast",
    "gui.extension.udp.description": "Let devices and devices in the local area network realize multi-machine broadcast communication through UDP protocol",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "ให้อุปกรณ์สามารถใช้MQTTการสื่อสารโปรโตคอล",
    "gui.extension.speechRecognition.name": "วิเคราะห์เสียงพูด",
    "gui.extension.speechRecognition.description": "วิเคราะห์เสียงพูด",
    "gui.gui.loaded": "ดาวน์โหลดแล้ว",
    "gui.gui.notLoaded": "ยังไม่ได้ดาวน์โหลด",
    "gui.gui.cantFindYouWant": "หาสิ่งที่คุณต้องการไม่พบ?",
    "gui.gui.clickHere": "กดที่นี่",
    "gui.gui.viewHelp": "หาความช่วยเหลือ",
    "gui.gui.uploadToDev": "อัปโหลด",
    "gui.gui.compileAndUpload": "หลังจากคอมไพรเลอร์แล้วอัพโหลด",
    "gui.gui.compileOnly": "คอมไพรเลอร์อย่างเดียว",
    "gui.gui.compileOpen": "หลังจากคอมไพรเลอร์แล้วเปิด",
    "gui.gui.burnBootloader": "เขียน bootloader ไร้สาย",
    "gui.gui.arduinoRun": "ทำงาน",
    "gui.gui.arduinoSave": "จัดเก็บ",
    "gui.gui.autoGenerated": "สร้างอัตโนมัติ",
    "gui.gui.manualEditing": "แก้ไขด้วยมือ",
    "gui.gui.codeBox": "กล่องรหัส",
    "gui.gui.moduleCode": "รหัสโมดูล",
    "gui.menu.edit.undo": "ยกเลิก",
    "gui.menu.edit.redo": "ทำใหม่",
    "gui.menu.edit.selectAll": "เลือกทั้งหมด",
    "gui.menu.edit.cut": "ตัด",
    "gui.menu.edit.copy": "คัดลอก",
    "gui.menu.edit.paste": "วาง",
    "gui.menu.edit.codeStyle": "สไตล์รหัส",
    "gui.menu.edit.fontSize": "ขนาดตัวอักษร",
    "gui.menu.edit.clearCache": "ทำความสะอาดแคช",
    "gui.menu.edit.maxReset": "คืนค่าMaxเป็นตั้งค่าจากโรงงาน",
    "gui.gui.serial": "ซีเรียล",
    "gui.gui.openSerial": "เปิดซีเรียล",
    "gui.gui.closeSerial": "ปิดซีเรียล",
    "gui.gui.close": "ปิด",
    "gui.gui.open": "เปิด",
    "gui.gui.clearOutput": "ทำความสะอาดเอาต์พุต",
    "gui.gui.scroll": "เลื่อน",
    "gui.gui.send": "ส่ง",
    "gui.gui.baud": "บอด",
    "gui.gui.noLineEnd": "ไม่มีจุดสิ้นสุด",
    "gui.gui.newLine": "ขึ้นบรรทัดใหม่",
    "gui.gui.carriageReturn": "ปัดแคร่",
    "gui.gui.bothNLandCR": "LF & CR",
    "gui.gui.unavailableHexData": "Invalid hex form! Please represent hex data with two characters ((0-9/A-F) and a blank, e.g. \"00 01 A2 FF\".",
    "gui.gui.hexForm": "Hex Form",
    "gui.gui.openHexForm": "Use hex form to show and send data",
    "gui.gui.closeHexForm": "Close",
    "gui.progress.burning": "เขียน",
    "gui.progress.compiling": "กำลังรวบรวม",
    "gui.burner.printObjectTooBig": "The project is too large; please click the \"help\" in the right top to reduce its size. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooBig": "Not enough memory; please click the \"help\" in the right top to reduce memory occupancy. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Low memory available, stability problems may occur. ",
    "gui.burner.printSizeError": "ไม่สามารถระบุขนาดของโปรแกรม ",
    "gui.burner.UDiskWriteError": "Write to UDisk error",
    "gui.burner.openPortError": "Could not open port: Access is denied",
    "gui.burner.UDiskPopUp": "UDisk pops up, please wait for a while and try again",
    "gui.burner.swdCfgError": "Cannot write to cmsis-dap.cfg",
    "gui.burner.burnError": "Burn error",
    "gui.burner.linkError": "Link error",
    "gui.burner.compileError": "Compile error",
    "gui.burner.generateHexError": "Generate .hex error",
    "gui.burner.generateBinError": "Generate .bin error",
    "gui.burner.eraseError": "Erase error",
    "gui.burner.noMicrobit": "Sorry it is not micro: bit, please connect micro: bit",
    "gui.burner.findLeonardoPortError": "Cannot find bootlaoder port of Leonardo",
    "gui.burner.printSize": "โครงการใช้ [%TEXTSIZE] ไบต์ใช้พื้นที่ ([%TEXTSIZEPRE]) หน่วยความจำของโปรแกรมโดยปล่อยให้เหลือ [%TEXTSIZESUR] ไบต์ถึง [%TEXTSIZEMAX] ไบต์ ตัวแปรโกลบอล \nใช้ [%DATASIZE] ไบต์, ([%DATASIZEPRE]) ของหน่วยความจำแบบไดนามิกโดยปล่อยให้ตัวแปรโลคอลไบต์ [%DATASIZESUR] เหลือไบต์ [%DATASIZEMAX] ",
    "gui.progress.compileSuccess": "รวบรวมสำเร็จ",
    "gui.progress.compileProgress": "ความก้าวหน้าในการรวบรวม",
    "gui.progress.uploadProgress": "ความก้าวหน้าในการอัพโหลด",
    "gui.progress.uploadSuccess": "อัพโหลดสำเร็จ",
    "gui.progress.errMsg": "อัพโหลดผิดพลาด, กรุณาเช็คข้อมูลความผิดพลาดได้ที่หน้าต่างสีดำด้านขวาล่าง",
    "gui.howtos.spin-around.name": "หมุนรอบ",
    "gui.howtos.spin.step_dragTurn": "ลาก “หมุนขวา” บล็อก",
    "gui.howtos.spin.step_clickTurn": "กดเพื่อใช้งานบล็อก",
    "gui.howtos.spin.step_clickControl": "กดด้านซ้ายโมดูล\"ควบคุม\"",
    "gui.howtos.spin.step_dragForever": "ลาก “ทำซ้ำ” บล็อก",
    "gui.howtos.spin.step_clickForever": "กดเพื่อใช้งานบล็อก",
    "gui.howtos.spin.step_changeColor": "เพิ่ม “เปลี่ยนเอฟเฟคสี” โมดูล",
    "gui.howtos.say-hello.name": "พูด hello",
    "gui.howtos.say-hello.step_addSprite": "เพิ่มสไปรต์",
    "gui.howtos.say-hello.step_clickLooks": "กดปุ่มโมดูลด้านซ้าย “มอง” ",
    "gui.howtos.say-hello.step_dragSay": "ลาก “พูด” บล็อก",
    "gui.howtos.say-hello.step_clickSay": "กดเพื่อใช้งานบล็อก",
    "gui.howtos.say-hello.step_anotherSay": "ลาก “พูด” บล็อกอันอื่น",
    "gui.howtos.say-hello.step_editSay": "แก้ไขบล็อก",
    "gui.howtos.say-hello.step_clickStack": "กดเพื่อใช้งานบล็อก",
    "gui.howtos.run-away.name": "วิ่งหนี",
    "gui.howtos.run-away.step_dragGoTo": "ลาก “ไปที่สุ่มตำแหน่ง” โมดูล",
    "gui.howtos.run-away.step_clickGoTo": "กดบล็อกเพื่อใช้งาน",
    "gui.howtos.run-away.step3": "กดที่หมวด “อีเว้นท์” ",
    "gui.howtos.run-away.step_addWhenClicked": "เพิ่่ม\"เมื่อสไปรต์ถูกกด\"ในบล็อก",
    "gui.howtos.run-away.step_clickSprite": "กดเพื่ื่อใช้งานสไปรต์",
    "gui.howtos.run-away.step_addSound": "เพิ่ม\"เล่นเสียง\"ในบล็อก",
    "gui.howtos.pick-up-apple.name": "เกมส์จับแอปเปิ้ล",
    "gui.howtos.pick-up-apple.step_1": "สลับเป็นสแครช",
    "gui.howtos.pick-up-apple.step_2": "สลับฮาร์ดแวร์",
    "gui.howtos.pick-up-apple.step_3": "เชื่อมต่ออุปกรณ์",
    "gui.howtos.pick-up-apple.step_4": "ปรับเทียบเข็มทิศ",
    "gui.howtos.pick-up-apple.step_5": "เปิดรายการ",
    "gui.howtos.pick-up-apple.step_6": "เกมส์จับแอปเปิ้ล",
    "gui.howtos.move-left-right.name": "micro:bitจอยสติ๊กแมว",
    "gui.howtos.move-left-right.step_1": "สร้างโครงการใหม่",
    "gui.howtos.move-left-right.step_2": "ลากบล็อก1",
    "gui.howtos.move-left-right.step_3": "ลากบล็อก2",
    "gui.howtos.move-left-right.step_4": "หมุนซ้ายและขวา",
    "gui.howtos.install-driver.name": "ติดตั้งอุปกรณ์",
    "gui.howtos.transform-expression.name": "ปุ่มแสดงอารมณ์",
    "gui.howtos.transform-expression.step_1": "สลับเป็นโค้ด",
    "gui.howtos.transform-expression.step_2": "สลับฮาร์ดแวร์",
    "gui.howtos.transform-expression.step_3": "ลากบล็อก1",
    "gui.howtos.transform-expression.step_4": "ลากบล็อก1",
    "gui.howtos.transform-expression.step_5": "ลากบล็อก3",
    "gui.howtos.transform-expression.step_6": "ปุ่มแสดงอารมณ์",
    "gui.howtos.lot.name": "IOT (Internet of Things)",
    "gui.howtos.lot.step_1": "เลือกรายการ",
    "gui.howtos.lot.step_2": "เขียนโปรแกรม",
    "gui.howtos.lot.step_3": "IOT (Internet of Things)",
    "gui.howtos.touch-pin.name": "แตะpin",
    "gui.howtos.touch-pin.step_1": "สลับเป็นโค้ด",
    "gui.howtos.touch-pin.step_2": "เลือกรายการ",
    "gui.howtos.touch-pin.step_3": "เขีียนโปรแกรม",
    "gui.howtos.touch-pin.step_4": "แตะpin",
    "gui.howtos.save-witch.name": "Rescue Witch",
    "gui.howtos.save-witch.step_1": "สลับเป็นสแครช",
    "gui.howtos.save-witch.step_2": "ในส่วนขยายเลือก“micro:bit”",
    "gui.howtos.save-witch.step_3": "เลือก“โปรแกรมตัวอย่าง”ใน“Rescue Witch”",
    "gui.howtos.save-witch.step_4": "เชื่อมต่ออุปกรณ์，เลือกmicrobit，รอเชื่อมต่อสำเร็จ",
    "gui.howtos.save-witch.step_5": "ถ้าแจ้งเตือน“เทียบอุปกรณ์”หมุนบอร์ดหลัก1รอบจนไฟLEDดับ",
    "gui.howtos.save-witch.step_6": "กดที่ธงสีเขียว，โบกแขนของคุณเพื่อโต้ตอบกับภาพเคลื่อนไหว",
    "gui.howtos.microbit-touch.name": "microbitแตะเพื่อนเปลี่ยนอิโม",
    "gui.howtos.microbit-touch.step_1": "สลับเป็นโหมดรหัส",
    "gui.howtos.microbit-touch.step_2": "เปิด“เรียน”ข้างล่างเมนู“โปรแกรมตัวอย่าง”，เลือก“pin แตะ”",
    "gui.howtos.microbit-touch.step_3": "เชื่อมต่อพอร์ต COM ที่ตอบรับกับกับอุปกรณ์，กด“อัพโหลดไปที่อุปกรณ์”",
    "gui.howtos.microbit-touch.step_4": "กดปุ่มที่ต่างกันสามารถแสดงอิโมที่ต่างกัน按",
    "gui.howtos.microbit-calibration.name": "micro:bitเทียบ",
    "gui.howtos.microbit-calibration.step_1": "หมุน micro: bit บอร์ด,ไฟ LED บนบอร์ดจะสว่างขึ้นตามลำดับ",
    "gui.howtos.microbit-calibration.step_2": "ไฟLEDทั้งหมดสว่างขึ้นจนถึงข้างนอก（ดังภาพข้างล่าง），หลังจากนั้นจะแสดงหน้ายิ้มและเสร็จสิ้น",
    "gui.extension.sen0251.name": "BMP388เซ็นเซอร์ความดันอากาศ",
    "gui.extension.sen0251.description": "มีฟังชั้นตรวจจับอุณหภูมิ、ความกดอากาศ、ระดับความสูง",
    "gui.extension.sen0206.name": "ตรวจจับรังอุณหภูอินฟราเรดแบบไร้สัมผัส",
    "gui.extension.sen0206.description": "Far-infrared non-contact measurement of object or ambient temperature",
    "gui.gui.recentlyEdited": "การแก้ไขช่วงนี้",
    "gui.extension.weather.name": "รับอากาศ",
    "gui.extension.weather.description": "รับข้อมูลเกี่ยวกับาภาพอากาศ, ต้องใช้Wi-Fiโมดูล",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "ดำเนินการTinyWebDBฐานข้อมูลเครือข่าย,，สามารถใช้กับ APP Inventor",
    "gui.gui.variableScopeOptionAllSprites": "สำหรับสไปรต์ทั้งหมด",
    "gui.gui.variableScopeOptionSpriteOnly": "สำหรับสไปรต์นี้เท่านั้น",
    "gui.gui.variablePromptAllSpritesMessage": "ตัวแปรนี้จะมีให้ใช้สำหรับสไปร์ททั้งหมด",
    "gui.monitor.contextMenu.default": "เปลี่ยนเป็นจอปกติ",
    "gui.monitor.contextMenu.large": "เปลี่ยนเป็นจอใหญ่",
    "gui.monitor.contextMenu.slider": "ตัวเลื่อน",
    "gui.monitor.contextMenu.import": "นำเข้า",
    "gui.monitor.contextMenu.export": "ส่งออก",
    "gui.monitor.listMonitor.listLength": "ความยาว {length}",
    "gui.monitor.listMonitor.empty": "(ว่างเปล่า)",
    "gui.costumeTab.backdrop": "พื้นหลัง",
    "gui.costumeTab.costume": "ชุดตัวละคร",
    "gui.opcodeLabels.direction": "ทิศทาง",
    "gui.opcodeLabels.xposition": "ตำแหน่ง x ",
    "gui.opcodeLabels.yposition": "ตำแหน่ง y",
    "gui.opcodeLabels.size": "ขนาด",
    "gui.opcodeLabels.costumename": "ชื่อคอสตูม",
    "gui.opcodeLabels.costumenumber": "หมายเลขคอสตูม",
    "gui.opcodeLabels.backdropname": "ชื่อฉากหลัง",
    "gui.opcodeLabels.backdropnumber": "หมายเลขฉากหลัง",
    "gui.opcodeLabels.volume": "ระดับเสียง",
    "gui.opcodeLabels.tempo": "จังหวะ",
    "gui.opcodeLabels.answer": "คำตอบ",
    "gui.opcodeLabels.loudness": "ระดับความดัง",
    "gui.opcodeLabels.year": "ปี",
    "gui.opcodeLabels.month": "เดือน",
    "gui.opcodeLabels.date": "วันที่",
    "gui.opcodeLabels.dayofweek": "วันของสัปดาห์",
    "gui.opcodeLabels.hour": "ชั่วโมง",
    "gui.opcodeLabels.minute": "นาที",
    "gui.opcodeLabels.second": "วินาที",
    "gui.opcodeLabels.timer": "ตัวจับเวลา",
    "gui.loadProject.error": "โหลดโครงการล้มเหลว",
    "gui.fileSystem.boardFiles": "ไฟล์ในบอร์ด",
    "gui.fileSystem.computerFiles": "ไฟล์ในคอมพิวเตอร์",
    "gui.fileSystem.newFile": "ไฟล์ใหม่",
    "gui.fileSystem.newFolder": "โฟลเดอร์ใหม่",
    "gui.fileSystem.deleteFolder": "ลบโฟลเดอร์",
    "gui.fileSystem.openFolder": "เปิดโฟลเดอร์",
    "gui.fileSystem.openInEditor": "เปิดในโปรแกรมจัดการข้อมูล",
    "gui.fileSystem.runRightNow": "ใช้งานทันที",
    "gui.fileSystem.stopRun": "หยุดการใช้งาน",
    "gui.fileSystem.setBoot": "ตั้งค่าบูท",
    "gui.fileSystem.deleteForever": "ลบตลอดไป",
    "gui.fileSystem.saveAs": "จัดเก็บเป็น",
    "gui.fileSystem.undoAllEdits": "ยกเลิกการแก้ไขทั้งหมด",
    "gui.fileSystem.copyToComputer": "คัดลอกลงคอมพิวเตอร์",
    "gui.fileSystem.uploadToBoard": "อัพโหลดไปที่บอร์ด",
    "gui.fileSystem.uploadAndRun": "อัพโหลดและใช้งานทันที",
    "gui.extension.motucamera.name": "กล้องจับภาพ",
    "gui.extension.motucamera.description": "โมดูลกล้องจับภาพอัจฉริยะ",
    "gui.fileSystem.fileCatalogue": "แค็ตตาล็อก",
    "gui.wifi.pwdNotLegal": "! ข้อผิดพลาด: รหัสผ่านอยู่ในรูปแบบที่ไม่ถูกต้อง รหัสผ่าน Wi-Fi จะต้องเป็น 8-20 หลักหรือตัวเลข",
    "gui.spreadmaqueen.perror": "! แจ้งเตือนข้อผิดพลาด：P12pin ไฟ ledไม่สามารถใช้ได้, กรุณาเลือกP8 pin ไฟled",
    "gui.gui.viewConflict": "กดเพื่อดูความขัดแย้ง",
    "music.categoryName": "ดนตรี",
    "translate.categoryName": "แปลภาษา",
    "sensor.categoryName": "เซนเซอร์",
    "actuator.categoryName": "ตัวกระตุ้นการทำงาน",
    "communicate.categoryName": "โมดูลสื่อสาร",
    "display.categoryName": "จอแสดงผล",
    "extendFunction.categoryName": "ฟังชั่น",
    "internetService.categoryName": "อินเตอร์เน็ต",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "handpy",
    "spread.categoryName": "บอร์ดส่วนขยาย",
    "speechRecognition.categoryName": "วิเคราะห์เสียงพูด",
    "gui.blocklyText.romeo.d1ProgramStart": "D1โปรแกรมหุ่นยนต์เริ่ม",
    "gui.blocklyText.romeo.readdigital": "อ่านค่าpin [PIN][MODULE] ",
    "gui.blocklyText.romeo.buttonModule": "ปุ่มโมดูล",
    "gui.blocklyText.romeo.digitalSensor": "เซ็นเซอร์แบบดิจิตอล",
    "gui.blocklyText.romeo.collisionSensor": "เซ็นเซอร์ตรวจจับการชน",
    "gui.blocklyText.romeo.lineSensor": "เซ็นเซอร์ตรวจจับเส้น",
    "gui.blocklyText.romeo.infraredSwitch": "สวิตช์อินฟราเรด",
    "gui.blocklyText.romeo.vibrationSensor": "เซ็นเซอร์ตรวจจับการสั่นสะเทือน",
    "gui.blocklyText.romeo.readAnalog": "อ่านค่าpin[PIN] [SENSOR]",
    "gui.blocklyText.romeo.lightSensor": "เซ็นเซอร์ตรวจวัดความเข้มแสง",
    "gui.blocklyText.romeo.anologSensor": "เซ็นเซอร์แบบแอนะล็อก",
    "gui.blocklyText.romeo.soundSensor": "เซ็นเซอร์ตรวจจับเสียง",
    "gui.blocklyText.romeo.soilMoistureSensor": "เซ็นเซอร์วัดความชื้นในดิน",
    "gui.blocklyText.romeo.frameSensor": "เซ็นเซอร์เปลวไฟ",
    "gui.blocklyText.romeo.open": "เปิด",
    "gui.blocklyText.romeo.close": "ปิด",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [PIN] pinของ [MODULE]",
    "gui.blocklyText.romeo.ledLights": "ไฟ LED",
    "gui.blocklyText.romeo.digitalActuator": "ตัวกระตุ้นดิจิตอล",
    "gui.blocklyText.romeo.relay": "รีเลย์",
    "gui.blocklyText.romeo.fan": "พัดลม",
    "gui.blocklyText.romeo.writeAnalog": "ตั้งค่าpin[PIN][MODULE] เป็น[VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "ตัวกระตุ้นการจำลอง",
    "gui.blocklyText.romeo.fanModule": "โมดูลพัดลม",
    "gui.blocklyText.romeo.robotSpeedMove": "หุ่นยนต์ [SPEED] ความเร็ว [DIR]",
    "gui.blocklyText.romeo.forward": "ไปข้างหน้า",
    "gui.blocklyText.romeo.backward": "ไปข้างหลัง",
    "gui.blocklyText.romeo.turnLeft": "หมุนซ้าย",
    "gui.blocklyText.romeo.turnRight": "หมุนขวา",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "ตั้งค่ามอเตอร์ [MOTOR] [SPEED] ที่ความเร็ว [DIR]",
    "gui.blocklyText.romeo.interface": "อินเตอร์เฟส",
    "gui.blocklyText.romeo.motorForward": "ข้างหน้า",
    "gui.blocklyText.romeo.motorReverse": "ย้อนกลับ",
    "gui.blocklyText.romeo.stopMotor": "หุ่นยนต์(M1และM2มอเตอร์)หยุด",
    "gui.blocklyText.romeo.readUltrasonic": "Хэт авианы зай (см) унших [TRIG] цуурай [ECHO]",
    "gui.blocklyText.romeo.lm35": "อ่านพิน [PIN] อุณหภูมิ LM35 (°C)",
    "gui.blocklyText.romeo.ds18b20": "อ่านพิน [PIN] อุณหภูมิ DS18B20 (°C)",
    "gui.blocklyText.romeo.pressInfrared": "อินฟราเรดpin [PIN]รับรู้ถึง [BUTTON] กด？",
    "gui.blocklyText.romeo.volumePlus": "ความดังเสียง+",
    "gui.blocklyText.romeo.switch": "สวิทซ์",
    "gui.blocklyText.romeo.volumeMinus": "ความดังเสียง-",
    "gui.blocklyText.romeo.startPause": "เริ่ม/หยุด",
    "gui.blocklyText.romeo.up": "ขึ้น",
    "gui.blocklyText.romeo.down": "ลง",
    "gui.blocklyText.romeo.dataReadable": "พอร์ตมีข้อมูลให้อ่าน?",
    "gui.blocklyText.romeo.readSerialData": "อ่านข้อมูลพอร์ต",
    "gui.blocklyText.romeo.timer": "เวลาทำงานของระบบ(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "ตั้งค่าpin[PIN] เล่นเสียงทรัมเป็ตเป็น [TONE] บีท [BEAT]",
    "gui.blocklyText.romeo.half": "Half",
    "gui.blocklyText.romeo.quarter": "Quarter",
    "gui.blocklyText.romeo.oneInEighth": "One-eighth",
    "gui.blocklyText.romeo.wholeBeat": "เต็มจังหวะ",
    "gui.blocklyText.romeo.doubleBeat": "2 จังหวะ",
    "gui.blocklyText.romeo.stop": "คลุม",
    "gui.blocklyText.romeo.setServo": "ตั้งค่าpin [PIN] เซอร์โวเป็น [DEGREE] องศา",
    "gui.blocklyText.romeo.segment": "ตั้งค่าLED 8หลัก(D6-D13pin)แสดง [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "พอร์ต [WAY] [STRING] [LINE]",
    "gui.blocklyText.romeo.originalOutput": "ดั่งเดิม",
    "gui.blocklyText.romeo.stringOutput": "เอ้าท์พุตของสตริง",
    "gui.blocklyText.romeo.hexOutput": "เอ้าท์พุตของHEX",
    "gui.blocklyText.romeo.wrap": "คลุม",
    "gui.blocklyText.romeo.noWrap": "ไม่คุลม",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "อ่านข้อมูลจากพอร์ต [READTYPE]",
    "gui.blocklyText.romeo.int": "จำนวนเต็ม",
    "gui.blocklyText.romeo.float": "ทศนิยม",
    "gui.blocklyText.romeo.setBaudRate": "ตั้งค่าพอร์ตอัตราบอดเป็น [BAUD]",
    "gui.blocklyText.romeo.readPinWidth": "อ่านpin [PIN] pulse width โหมดเป็น [LEVEL] เกินเวลา [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "ต่ำ",
    "gui.blocklyText.romeo.high": "สูง",
    "gui.blocklyText.romeo.noSound": "ไม่มีเสียง",
    "gui.blocklyText.arduino.arduinoStarts": "Arduinoเริ่ม",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno เริ่ม",
    "gui.blocklyText.esp32.displayInLine": "display [TEXT] in the [LINE] line",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "clear cache in the [LINE] line",
    "gui.blocklyText.esp32.displayClearInLine": "clear in the [LINE] line",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 เริ่ม",
    "gui.blocklyText.esp32.btnIsPressed": "กดปุ่ม [BUTTON]ถูก [BUTTONSTATE]？",
    "gui.blocklyText.esp32.whenBtnPress": "เมื่อ [REFERENCE] กดปุ่ม [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "เมื่อสัมผัสปุ่ม [REFERENCE] ถูก [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "เมื่อ [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "สัมผัสปุ่ม [PIN] ถูก [TOUCHSTATE]？",
    "gui.blocklyText.esp32.buzzerSwitch": "หยุดการเล่นด้านหลัง",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "เล่นโน๊ตด้ายหลัง [NOTE]",
    "gui.blocklyText.esp32.setBuzzerFreq": "เล่นโน๊ต [NOTE] [BEAT] บีท",
    "gui.blocklyText.esp32.getSoundIntensity": "อ่านระดับเสียงไมโครโฟน",
    "gui.blocklyText.esp32.readBrightness": "อ่านความสว่างของแสงรอบๆ",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] แสดง [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "แดง [RED] เขียง [GREEN] ฟ้า [BLUE]",
    "gui.blocklyText.esp32.acceleration": "อ่านค่าความเร่ง(mg) [TYPE]",
    "gui.blocklyText.esp32.displayInXY": "แสดง [TEXT] ที่ตำแหน่ง X: [X] Y: [Y] ตัวอย่าง [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "หน้าจอแสดงผลเป็น [COLOR] (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "เขียนแคชหน้าจอเป็น [COLORMPYTHON] (clear screen)",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": "ขาวทั้งหมด",
    "gui.blocklyText.esp32.lineDrawing": "เขียนเว้นจากจุดx1:[X1] y1:[Y1] ถึงจุดx2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "เชื่อมต่อ",
    "gui.blocklyText.esp32.disconnected": "ตัดการเชื่อมต่อ",
    "gui.blocklyText.esp32.rectangleDrawing": "วาดสี่เหลี่ยมผืนผ้า[FILL] จากจุด x:[X] y:[Y] กว้าง:[WIDTH] สูง:[HEIGHT]",
    "gui.blocklyText.esp32.screenDisplay": "จอแคชแสดงผลลัพธ์",
    "gui.blocklyText.esp32.circleDrawing": "วาดวงกลม[FILL] ตรงกลางที่ x:[X] y:[Y] รัศมี:[RADIUS]",
    "gui.blocklyText.esp32.fill": "เติม",
    "gui.blocklyText.esp32.notFill": "ไม่เติม",
    "gui.blocklyText.esp32.pointDrawing": "วาดจุด x:[X] y:[Y]",
    "gui.blocklyText.esp32.setLineWidth": "ตั้งค่าความกว้างของเส้น [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "ตั้งค่าดิจิตอลpin [PIN] เอาท์พุต [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "อ่านดิจิตอลpin [PIN]",
    "gui.blocklyText.esp32.analogWrite": "ตั้งค่าขาอะนาล็อก [PIN] (PWM) [VALUE]",
    "gui.blocklyText.esp32.analogRead": "อ่านอะนาล็อกpin [PIN]",
    "gui.blocklyText.esp32.serialPort0": "serial0",
    "gui.blocklyText.esp32.serialPort1": "serial1",
    "gui.blocklyText.esp32.serialPort2": "serial2",
    "gui.blocklyText.esp32.seriaRemapping": "ตั้งค่า [SERIAL] Rx [RX] Tx [TX] อัตราบอดเป็น [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "แสดงรูปบนหน้าจอ [IMAGE] ที่ตำแหน่ง x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "เขียนแคชรูป [IMAGE] ที่ตำแหน่ง x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "กด",
    "gui.blocklyText.esp32.loosened": "ปล่อย",
    "gui.blocklyText.esp32.all": "ทั้งหมด",
    "gui.blocklyText.esp32.setBaud": "ตั้งค่าพอร์ต0 อัตราบอด[BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "จาก [SERIAL] อ่านข้อมูล [READTYPE]",
    "gui.blocklyText.esp32.setBrightness": "ตั้งค่าLEDความสว่างของไฟเป็น [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "อ่านLEDระดับความสว่างของไฟ",
    "gui.blocklyText.esp32.print": "พิมพ์ [DATA]",
    "gui.blocklyText.esp32.TiltForward": "เอียงไปข้างหน้า",
    "gui.blocklyText.esp32.TiltBack": "เอียงไปข้างหลัง",
    "gui.blocklyText.esp32.TiltLeft": "เอียงไปด้านซ้าย",
    "gui.blocklyText.esp32.TiltRight": "เอียงไปด้านขวา",
    "gui.blocklyText.esp32.ScreenUp": "หน้าจอขึ้น",
    "gui.blocklyText.esp32.ScreenDown": "หน้าจอลง",
    "gui.blocklyText.esp32.Shake": "เขย่า",
    "gui.blocklyText.esp32.isGesture": "ปัจจุบันเป็น [GESTURE]?",
    "gui.blocklyText.mega2560.functionStarts": "Mege2560 เริ่ม",
    "gui.blocklyText.mega2560.digitalRead": "อ่านดิจิตอลpin [PIN]",
    "gui.blocklyText.mega2560.analogRead": "อ่านอะนาล็อกpin [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "ตั้งค่าpwmpin [PIN] เอาท์พุต [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "ตั้งค่าดิจิตอลpin [PIN] เอาท์พุต [VALUE]",
    "gui.blocklyText.mega2560.level.low": "ต่ำ",
    "gui.blocklyText.mega2560.level.high": "สูง",
    "gui.blocklyText.mega2560.irqRead": "อ่านดิจิตอลpinที่อินฟราเรดได้รับ [PIN]",
    "gui.blocklyText.mega2560.ultrasonicRead": "อ่านอัลตราโซนิคเซนเซอร์ trigเป็น [TRIG] echoเป็น [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "ตั้งค่าpin [PIN] เสียงลำโพงบัซเซอร์เป็น [TONE] บีท [BEAT]",
    "gui.blocklyText.mega2560.harf": "Harf",
    "gui.blocklyText.mega2560.quarter": "Quarter",
    "gui.blocklyText.mega2560.oneInEighth": "One-eighth",
    "gui.blocklyText.mega2560.wholeBeat": "เต็มจังหวะ",
    "gui.blocklyText.mega2560.doubleBeat": "2 จังหวะ",
    "gui.blocklyText.mega2560.stop": "หยุด",
    "gui.blocklyText.mega2560.servoSet": "ตั้งค่าหมุด [PIN] servo เป็น [DEGREE] องศา",
    "gui.blocklyText.mega2560.setBaud": "ตั้งค่า [SERIAL] อัตราบอดเป็น [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "จาก [SERIAL]อ่านข้อมูล [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "serial0",
    "gui.blocklyText.mega2560.serialPort1": "serial1",
    "gui.blocklyText.mega2560.serialPort2": "serial2",
    "gui.blocklyText.mega2560.serialPort3": "serial3",
    "gui.blocklyText.mega2560.dataReadable": "มีข้อมูลให้อ่าน[SERIAL]？",
    "gui.blocklyText.mega2560.readSerialData": "อ่านข้อมูล [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "เอ้าท์พุตของสตริง",
    "gui.blocklyText.mega2560.hexOutput": "เอ้าท์พุตของHEX",
    "gui.blocklyText.mega2560.wrap": "คลุม",
    "gui.blocklyText.mega2560.noWrap": "ไม่คุลม",
    "gui.blocklyText.mega2560.readPinLevel": "อ่านpin [PIN] pulse width โหมดเป็น [LEVEL] เกินเวลา [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "เวลาที่เครื่องทำงาน(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini เริ่ม",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo เริ่ม",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560เริ่ม",
    "gui.blocklyText.arduino.setDigital": "ตั้งค่าดิจิตอลpin [PIN] เอาท์พุตเป็น [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "อ่านดิจิตอลpin [PIN]",
    "gui.blocklyText.arduino.readSimilation": "อ่านอะนาล็อกpin [PIN]",
    "gui.blocklyText.arduino.setPwm": "ตั้งค่า pin pwm [PIN] เอ้าท์พุต [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "การอ่านค่าระยะทางอัลตราโซนิก (ซม.) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "ตั้งค่าpin [PIN] เล่นเสียงทรัมเป็ตเป็น [TONE] บีท [BEAT]",
    "gui.blocklyText.arduino.serialSendStr": "พอร์ต [WAY] [STRING] [LINE]",
    "gui.blocklyText.arduino.originalOutput": "ดั้งเดิม",
    "gui.blocklyText.arduino.stringOutput": "เอ้าท์พุตของสตริง",
    "gui.blocklyText.arduino.hexOutput": "เอ้าท์พุตของHEX",
    "gui.blocklyText.arduino.wrap": "คลุม",
    "gui.blocklyText.arduino.noWrap": "ไม่คุลม",
    "gui.blocklyText.arduino.readInfrared": "อ่านดิจิตอลpinที่อินฟราเรดได้รับ [PIN]",
    "gui.blocklyText.arduino.setBaud": "ตั้งค่าพอร์ตอัตราบอดเป็น [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "อ่านพิน [PIN] รูปแบบความกว้างพัลส์ [LEVEL] ระดับ หมดเวลา [TIMEOUT] (us)",
    "gui.blocklyText.arduino.noSound": "ไม่มีเสียง",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Nano เริ่ม",
    "gui.blocklyText.vortex.vortexStart": "Vortexเริ่มโปรแกรมหุ่นยนต์",
    "gui.blocklyText.vortex.vortexMoveSpeed": "Vortexหุ่นยนต์ [SPEED] ความเร็ว [DIRECTION]",
    "gui.blocklyText.vortex.forward": "ไปข้างหน้า",
    "gui.blocklyText.vortex.backup": "ถอยไปข้างหลัง",
    "gui.blocklyText.vortex.turnLeft": "หมุนซ้าย",
    "gui.blocklyText.vortex.turnRight": "หมุนขวา",
    "gui.blocklyText.vortex.setMotor": "ตั้งค่า电机 [MOTOR] 以 [SPEED] 速度 [DIRECTION]",
    "gui.blocklyText.vortex.leftWheel": "ซ้าย",
    "gui.blocklyText.vortex.rightWheel": "ขวา",
    "gui.blocklyText.vortex.setExpression": "ตั้งค่า [EXPRESSION]แสดงสีเป็น [COLOR]",
    "gui.blocklyText.vortex.ledLights": "ตั้งค่า [DIRECTION][LIGHT] เปลี่ยน [COLOR]ภายใน[SECOND]วินาที",
    "gui.blocklyText.vortex.all": "ทั้งหมด",
    "gui.blocklyText.vortex.rightAhead": "ด้านหน้า",
    "gui.blocklyText.vortex.leftRear": "ซ้ายหลัง",
    "gui.blocklyText.vortex.rightRear": "ขวาหลัง",
    "gui.blocklyText.vortex.rightAstern": "ด้านหลัง",
    "gui.blocklyText.vortex.topLights": "ไฟด้านบน",
    "gui.blocklyText.vortex.bottomLights": "ไฟด้านล่าง",
    "gui.blocklyText.vortex.vortexStop": "vortexหยุดการเคลื่อนไหว",
    "gui.blocklyText.vortex.setVolume": "ตั้งค่าความดังเสียงเป็น [VALUE]",
    "gui.blocklyText.vortex.playMusic": "เล่น [VALUE] เพลงแรก",
    "gui.blocklyText.vortex.stopMusic": "หยุดเพลง",
    "gui.blocklyText.vortex.startDance": "เริ่ม [VALUE] เต้น",
    "gui.blocklyText.vortex.stopDance": "หยุดเต้น",
    "gui.blocklyText.vortex.barrierDetection": "มีข้างหน้าอุปสรรค？",
    "gui.blocklyText.vortex.readSensor": "อ่าน [SENSOR] เซนเซอร์วัดระดับสีเทา",
    "gui.blocklyText.vortex.setSensor": "ตั้งค่าเซนเซอร์วัดระดับสีเทาความไวต่อสีเป็น [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "เซนเซอร์วัดระดับสีเทา [SENSOR] ตำแหน่งที่พบสีดำ？",
    "gui.blocklyText.vortex.positiveFrontLeft": "ด้านหน้าซ้าย",
    "gui.blocklyText.vortex.frontLeft": "หน้าซ้าย",
    "gui.blocklyText.vortex.positiveFrontRight": "ด้านหน้าขวา",
    "gui.blocklyText.vortex.frontRight": "หน้าขวา",
    "gui.blocklyText.vortex.backRight": "ขวาหลัง",
    "gui.blocklyText.vortex.backLeft": "ซ้ายหลัง",
    "gui.blocklyText.vortex.initialOldPCB": "เริ่มต้นเวอร์ชั่นเก่า(สีฟ้าPCB)",
    "pen.categoryName": "ปากกา",
    "pen.changeColorParam": "เปลี่ยน [COLOR_PARAM] ปากกาทีละ [VALUE]",
    "pen.changeHue": "เปลี่ยนสีปากกาทีละ [HUE]",
    "pen.changeShade": "เปลี่ยนเฉดสีปากกาทีละ [SHADE]",
    "pen.changeSize": "เปลี่ยนขนาดปากกาทีละ [SIZE]",
    "pen.clear": "ลบทั้งหมด",
    "pen.colorMenu.brightness": "ความสว่าง",
    "pen.colorMenu.color": "สี",
    "pen.colorMenu.saturation": "ความอิ่มตัวของสี",
    "pen.colorMenu.transparency": "ความโปร่งใส",
    "pen.penDown": "จรดปากกา",
    "pen.penUp": "ยกปากกาขึ้น",
    "pen.setColor": "ตั้งค่าสีปากกาเป็น [COLOR]",
    "pen.setColorParam": "ตั้งค่า [COLOR_PARAM] ปากกาเป็น[VALUE]",
    "pen.setHue": "ตั้งสีปากกาเป็น [HUE]",
    "pen.setShade": "ตั้งค่าเฉดสีเป็น [SHADE]",
    "pen.setSize": "ตั้งขนาดปากกาเป็น [SIZE]",
    "pen.stamp": "ประทับ",
    "music.changeTempo": "เปลี่ยนความเร็วทีละ [TEMPO]",
    "music.drumBass": "(2) กลองใหญ่",
    "music.drumBongo": "(13) บองโก",
    "music.drumCabasa": "(15) คาบาซา",
    "music.drumClaves": "(9) เคลฟ",
    "music.drumClosedHiHat": "(6) ฉาบไฮแฮทปิด",
    "music.drumConga": "(14) คองกา",
    "music.drumCowbell": "(11) คาวเบล",
    "music.drumCrashCymbal": "(4) ฉาบ",
    "music.drumCuica": "(18) คุยกา",
    "music.drumGuiro": "(16) กวีโร",
    "music.drumHandClap": "(8) ตบมือ",
    "music.drumOpenHiHat": "(5) ฉาบไฮแฮทเปิด",
    "music.drumSideStick": "(3) ไซด์สติค",
    "music.drumSnare": "(1) กลองเล็ก",
    "music.drumTambourine": "(7) แทมบูรีน",
    "music.drumTriangle": "(12) กิ๋ง",
    "music.drumVibraslap": "(17) ไวบราสแลป",
    "music.drumWoodBlock": "(10) วูดบล็อก",
    "music.getTempo": "จังหวะ",
    "music.instrumentBass": "(6) เบส",
    "music.instrumentBassoon": "(14) บาสซูน",
    "music.instrumentCello": "(8) เชลโล",
    "music.instrumentChoir": "(15) เสียงประสาน",
    "music.instrumentClarinet": "(10) แคลริเน็ต",
    "music.instrumentElectricGuitar": "(5) กีต้าร์ไฟฟ้า ",
    "music.instrumentElectricPiano": "(2) เปียโนไฟฟ้า",
    "music.instrumentFlute": "(12) ฟลูต",
    "music.instrumentGuitar": "(4) กีตาร์",
    "music.instrumentMarimba": "(19) มาริมบา",
    "music.instrumentMusicBox": "(17) กล่องดนตรี ",
    "music.instrumentOrgan": "(3) ออร์แกน",
    "music.instrumentPiano": "(1) เปียโน",
    "music.instrumentPizzicato": "(7) พิซซิคาโต",
    "music.instrumentSaxophone": "(11) แซกโซโฟน",
    "music.instrumentSteelDrum": "(18) กลองเหล็ก",
    "music.instrumentSynthLead": "(20) ซินธ์ลีด",
    "music.instrumentSynthPad": "(21) ซินธ์แพด",
    "music.instrumentTrombone": "(9) ทรอมโบน",
    "music.instrumentVibraphone": "(16) ไวปราโฟน",
    "music.instrumentWoodenFlute": "(13) ฟลูตไม้",
    "music.midiPlayDrumForBeats": "เล่น [DRUM] [BEATS] จังหวะ",
    "music.midiSetInstrument": "ตั้งเครื่องดนตรีเป็น [INSTRUMENT]",
    "music.playDrumForBeats": "เล่น [DRUM] [BEATS] จังหวะ",
    "music.playNoteForBeats": "เล่นโน๊ต [NOTE] [BEATS] จังหวะ",
    "music.restForBeats": "หยุด [BEATS] จังหวะ",
    "music.setInstrument": "ตั้งเครื่องดนตรีเป็น [INSTRUMENT]",
    "music.setTempo": "ตั้งค่าความเร็วเป็น [TEMPO]จังหวะต่อนาที ",
    "gui.blocklyText.microbit.microbitStarts": "micro:bitเริ่ม",
    "gui.blocklyText.microbit.interruptExcute": "อินเตอร์รัพท์ pin [PIN] โหมด [MODE]ทำการ",
    "gui.blocklyText.microbit.cancleInterrupt": "ยกเลิกการอินเตอร์รัพท์ [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "เมื่อรับข้อมูลแบบไร้สาย",
    "gui.blocklyText.microbit.whenBtnPress": "เมื่อ [REFERENCE] กดปุ่ม",
    "gui.blocklyText.microbit.whenPinConnected": "เมื่อไมโครบิท [REFERENCE] ถูกเชื่อมต่อ",
    "gui.blocklyText.microbit.whenPosChange": "เมื่อ [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "แสดงรูปแบบ [PIC]",
    "gui.blocklyText.microbit.showInput": "แสดงข้อความ [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "หยุดภาพเคื่อนไหว",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] พิกัด x:[XAXIS], y:[YXAXIS]",
    "gui.blocklyText.microbit.hideAllLights": "ดับดอทเมทริกซ์ทั้งหมด",
    "gui.blocklyText.microbit.microbitEnableLight": "จอแสดงเมทริกซ์ [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "ระดับแสง",
    "gui.blocklyText.microbit.showLightWithBrightness": "ตั้งค่าความสว่าง [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "สว่าง ตำแหน่งx:[XAXIS], y:[YXAXIS], สว่าง [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "pin [PIN] เล่นเสียง [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "pin [PIN] เล่นเสียง [SOUND] จนจบ",
    "gui.blocklyText.microbit.playNote": "pin [PIN] เล่นโน๊ต [NOTE] [BEAT] บีท",
    "gui.blocklyText.maqueen.playSound": "pin P0 เล่นเสียง [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "pin P0 เล่นเสียง [SOUND] จนจบ",
    "gui.blocklyText.maqueen.playNote": "pin P0 เล่นโน๊ต [NOTE] [BEAT] บีท",
    "gui.blocklyText.maqueen.RGB0": "RGB0  (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1  (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2  (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3  (3)",
    "gui.blocklyText.maqueen.All": "All  (-1)",
    "gui.blocklyText.microbit.changeTempo": "เทมโป(bpm)เพิ่ม [VALUE]",
    "gui.blocklyText.microbit.setTempo": "เทมโป(bpm)ตั้งค่าเป็น [VALUE]",
    "gui.blocklyText.microbit.getTempo": "ความเร็วเสียง (bpm)",
    "gui.blocklyText.microbit.stopSound": "หยุดการเล่นเบื้องหลัง",
    "gui.blocklyText.microbit.btnIsPressed": " [BUTTON] กดปุ่ม？",
    "gui.blocklyText.microbit.pinIsConnected": "ปักหมุด [PIN] ถูกเชื่อมต่อ？",
    "gui.blocklyText.microbit.isMove": "สภาพปัจจุบัน [TYPE]？",
    "gui.blocklyText.microbit.compass": "การอ่านทิศของเข็มทิศ",
    "gui.blocklyText.microbit.temperature": "อ่านอุณหภูมิ(℃)",
    "gui.blocklyText.microbit.digitalWrite": "ตั้งค่าดิจิตอลpin [PIN] เอาท์พุต [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "อ่านดิจิตอลpin [PIN]",
    "gui.blocklyText.microbit.analogWrite": "ตั้งค่าอะนาล็อกpin [PIN] เอาท์พุต [VALUE]",
    "gui.blocklyText.microbit.analogRead": "อ่านอะนาล็อกpin [PIN]",
    "gui.blocklyText.microbit.up": "โลโก้ขึ้น",
    "gui.blocklyText.microbit.down": "โลโก้ลง",
    "gui.blocklyText.microbit.left": "เอียงซ้าย",
    "gui.blocklyText.microbit.right": "เอียงขวา",
    "gui.blocklyText.microbit.faceUp": "หน้าจอขึ้น",
    "gui.blocklyText.microbit.faceDown": "หน้าจอลง",
    "gui.blocklyText.microbit.freefall": "เคลื่อวไหวอิสระ",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "เขย่า",
    "gui.blocklyText.microbit.show": "แสดง",
    "gui.blocklyText.microbit.hide": "ซ่อน",
    "gui.blocklyText.microbit.low": "ต่ำ",
    "gui.blocklyText.microbit.high": "สูง",
    "gui.blocklyText.microbit.microbitReadBrightness": "อ่านความสว่างของแสงรอบๆ",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] การสื่อสารไร้สาย",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "ตั้งค่าช่องสัญญาณไร้สายเป็น [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "ส่งโดนสัญญาณไร้สาย [TEXT]",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "รับข้อมูลไร้สาย",
    "gui.blocklyText.microbit.strength": "ความแข็งแรง",
    "gui.blocklyText.microbit.acceleration": "อ่านค่าความเร่ง(mg)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "อ่านค่าแรงแม่เหล็ก(µT)[TYPE]",
    "gui.blocklyText.microbit.print": "พิมพ์ [DATA]",
    "gui.blocklyText.microbit.goUp": "ขึ้น",
    "gui.blocklyText.microbit.goDown": "ลง",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "เมื่อรับ[WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "ข้อมูลไร้สาย",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot เริ่ม",
    "gui.blocklyText.maxbot.playSound": "เล่นเสียง [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "เล่นเสียง [SOUND] จนจบ",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "[LEFTRIGHT]เกิดการปะทะ",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "[LEFTRIGHT]ตรวจพบเส้นสีดำ",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "มอเตอร์ [MOTOR] [DIR]ที่ความเร็ว [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "อ่าน(P1,P2)อัลตร้าโซนิคเซ็นเซอร์ หน่วย[UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "อ่านอัลตร้าโซนิคเซ็นเซอร์ หน่วย [UNIT] trigเป็น [TRIG] echoเป็น [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "ถัดไป",
    "gui.blocklyText.maxbot.BACKWARD": "ถอยกลับ",
    "gui.blocklyText.motorbit.CW": "ข้างหน้า",
    "gui.blocklyText.motorbit.CCW": "ย้อนกลับ",
    "gui.blocklyText.maxbot.CW": "ข้างหน้า",
    "gui.blocklyText.maxbot.CCW": "ย้อนกลับ",
    "gui.blocklyText.maxbot.ANGLE": "องศา",
    "gui.blocklyText.maxbot.CIRCLE": "จำนวนรอบ",
    "gui.blocklyText.maxbot.Left": "ซ้าย",
    "gui.blocklyText.maxbot.Right": "ขวา",
    "gui.blocklyText.maxbot.cm": "เซนติเมตร",
    "gui.blocklyText.maxbot.inch": "นิ้ว",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit เริ่ม",
    "gui.blocklyText.MotorBit.MotorRun": "ตั้งค่ามอเตอร์ [MOTOR] หมุน [SPEED] ความเร็ว [DIR]",
    "gui.blocklyText.MotorBit.MotorStop": "มอเตอร์ [MOTOR] หยุด",
    "gui.blocklyText.MotorBit.Stepper": "ตั้งค่ามอเตอร์สเต็ปเปอร์ [STEPPER] [ROTATEDIR] [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "ทั้งหมด",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen เริ่ม",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "อ่าน(P1 P2)อัลตร้าโซนิคเซ็นเซอร์(cm)",
    "gui.blocklyText.maqueen.motorStop": "หุ่นยนต์(มอเตอร์ซ้ายและขวา)หยุด",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] ไฟLed",
    "gui.blocklyText.maqueen.redLineSensor": "อ่าน [LEFTRIGHT] เซ็นเซอร์ตรวจจับเส้น",
    "gui.blocklyText.maqueen.robotSpeedMove": "หุ่นยนต์ [SPEED] ที่ความเร็ว [DIR]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "ตั้งค่ามอเตอร์ [LEFTRIGHT]เดิน [DIR] ที่ความเร็ว[SPEED]",
    "gui.blocklyText.maqueen.ledLeft": "ซ้าย(P8)",
    "gui.blocklyText.maqueen.ledRight": "ขาว(P12) (ฮาร์ดแวร์ไม่พร้อมใช้งาน)",
    "gui.blocklyText.spreadmaqueen.ledRight": "ขาว(P12)",
    "gui.blocklyText.maqueen.lineSensorLeft": "ซ้าย(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "ขวา(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "เมื่อ(P16)ได้รับ[WirelessData]",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "อ่าน(P16)ค่าสัญญาณอินฟราเรด",
    "gui.blocklyText.maqueen.numberOfLightsBright": "ไฟRGB pin P15 จำนวนไฟ4 เปิด [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "ไฟRGB pin P15 เลขไฟ [NUM1] แสดงสี [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "ไฟRGB pin P15 เลขไฟ [NUM1] ถึง [NUM2] แสดงเปลี่ยนสีไฟ จากสี [COLOR1] ถึง [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "ไฟRGB pin P15 ย้ายพิกเซล [NUM] ",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "ไฟRGB pin P15 หมุนเวียนพิกเซล [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "ไฟRGB pin P15 ตั้งค่าแสงไฟ [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "ไฟRGB pin P15 เลขไฟ [NUM1] ถึง [NUM2] แสดงฮิสโตแกรม ค่าปัจจุบัน [VALUE] ค่าสูงสุด [MAX]",
    "gui.blocklyText.maqueen.clear": "ไฟRGB pin P15 ดับไฟทั้งหมด",
    "gui.blocklyText.maqueen.rgbColor": "แดง [RED] เขียง [GREEN] ฟ้า [BLUE]",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] ได้รับข้อความ？",
    "gui.blocklyText.obloq.initialParameter": "Obloq mqttขั้นต้น [PARAMETER] พอร์ต [SERIALPORT] Rx(เขียว) [PINR] Tx(ฟ้า) [PINT]",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq ส่งข้อความ [MSG] ถึง [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq อ่านข้อความจาก [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq อ่านข้อความ",
    "gui.blocklyText.obloq.httpInitialParameter": "Obloq httpขั้นต้น [PARAMETER] พอร์ต [SERIALPORT] Rx(เขียว) [PINR] Tx(ฟ้า) [PINT] ",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] เกินเวลา(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL]content: [CONTENT] เกินเวลา(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] เกินเวลา(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "เพิ่มสมาชิก [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "ชื่อWi-Fi",
    "gui.blocklyText.obloq.password": "รหัสWi-Fi",
    "gui.blocklyText.obloq.iotService": "บริการ IOT",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "ที่อยู่ IP",
    "gui.blocklyText.obloq.port": "เลขพอร์ต",
    "gui.blocklyText.obloq.addTopic": "เพิ่มTopic, มากที่สุดสามารถเพิ่มได้4อัน",
    "gui.blocklyText.obloq.deleteTopic": "ลบ Topic",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "เซิร์ฟเวอร์",
    "gui.blocklyText.obloq.global": "ทั่วโลก",
    "gui.blocklyText.obloq.china": "ประเทศจีน",
    "gui.blocklyText.obloq.softwareSerial": "ซอฟต์แวร์อนุกรม",
    "gui.blocklyText.obloq.hardwareSerial": "ฮาร์ดแวร์อนุกรม",
    "gui.blocklyText.obloq.hardwareSerial1": "ฮาร์ดแวร์อนุกรม1",
    "gui.blocklyText.obloq.hardwareSerial2": "ฮาร์ดแวร์อนุกรม2",
    "gui.blocklyText.obloq.hardwareSerial3": "ฮาร์ดแวร์อนุกรม3",
    "gui.blocklyText.obloq.WhenReceivedData": "เมื่อ [TOPIC] ได้รับ [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "ข่าวสาร Obloq",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq ได้รับ [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "ข้อมูลดีบั๊ก",
    "gui.blocklyText.obloq.ERROR": "รหัสผิดพลาด",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "ปล่อยอินฟราเรด [TYPE] pin [PIN] ค่า [VALUE] บิต [BIT]",
    "gui.blocklyText.infraredEmission.send": "Infrared send pin [PIN] Send message: address [ADDRESS] command [COMMAND]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 แสดง[TEXT]ในแถว [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 แสดง[TEXT]ในตำแหน่ง X: [X] Y:16* [Y] ",
    "gui.blocklyText.oled2864.screenRotation": "หมุนหน้าจอไป[ROTATION]องศา",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 ทำความสะอาด",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "อ่าน pin [PIN] [DHT] [TYPE]",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx pin [PIN] PM concentration value [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 ultrasonic initialization Name [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04 ultrasonic distance measurement unit [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx pin [PIN] 0.1 liters of air in [DIAMETER] number of particles",
    "gui.blocklyText.sen0170.readWindspeed": "อ่านpin [PIN] ระดับลม",
    "gui.blocklyText.sen0228.readAmbientLight": "อ่านแสงรอบๆ(VEML7700)",
    "gui.blocklyText.dfr0026.readAmbientLight": "อ่านpin [PIN] แสงล้อมรอบ",
    "gui.blocklyText.sen0177.readDustSensor": "อ่าน [TYPE] (ug/m3) อินเตอร์เฟส [SERIALPORT] Rx [PINR] Tx [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "pin(D) [PIN] pin(MCLR) [RST] ท่าทางปัจจุบัน [TYPE]？",
    "gui.blocklyText.sen0203.readHeartRate": "อ่านpin [PIN] [TYPE] สลับเป็น [SWITCH]",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] pin [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "อ่านpin [PIN] ระดับสีเทา ",
    "gui.blocklyText.dfr0552.init": "โมดูลDA  ขั้นต้นIICที่อยู่ [ADDR] ค่าแรงดันอ้างอิง [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "โมดูลDA ผลลัพธ์ด้วยวิธีคลื่น 3 เหลี่ยมโดยใช้  ความสูงของคลื่น [AMPLITUDE]mV ความถี่ [FREQ]Hz dc ไบอัส [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "โมดูลDA ผลลัพธ์คลื่นไซน์  ความสูงของคลื่น [AMPLITUDE]mV ความถี่ [FREQ]Hz dc ไบอัส [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "โมดูลDA  ผลลัพธ์แรงดันไฟฟ้า [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "โมดูลDA  หน่วยความจำแรงดันไฟฟ้า [VOLTAGE]mV",
    "gui.blocklyText.dfr0576.selectPort": "เลือกพอร์ต [PORT]",
    "gui.blocklyText.dfr0576.begin": "ที่อยู่ในการเก็บข้อมูลเริ่มต้น [ADDR]",
    "gui.blocklyText.sen0248.readBME680": "ได้รับBME680เซนเซอร์ [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "อุณหภูมิ",
    "gui.blocklyText.sen0248.Pressure": "ความกดอากาศ",
    "gui.blocklyText.sen0248.Humidity": "ความชื้น",
    "gui.blocklyText.sen0248.Altitude": "ความสูงจากระดับน้ำทะเล",
    "gui.blocklyText.sen0248.GasResistance": "ความต้านทานต่อก๊าซ (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "ขั้นต้นI2Cที่อยู่อัลตร้าโซนิคเซ็นเซอร์ [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "เปลี่ยนI2Cที่อยู่อัลตร้าโซนิคเซ็นเซอร์ [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "อ่านI2Cระยะอัลตร้าโซนิคเซ็นเซอร์ (cm)",
    "gui.blocklyText.sen0304.readTemperature": "อ่านI2Cอุณหภูมิอัลตร้าโซนิคเซ็นเซอร์(°C)",
    "gui.blocklyText.sen0307.readDistance": "การอ่าน [PIN] คลื่นความถี่เหนือการได้ยินของมนุษย์แบบอนาลอก(cm) (เป็นคำสั่งวัดระยะทาง)",
    "gui.blocklyText.sen0250.setMode": "module BMI160 คำสั่งเรียกการสร้างข้อมูลเบื้องต้นโดยใช้ ที่อยู่ในการเก็บข้อมูล [ADDR] และโหมด [MODE]",
    "gui.blocklyText.sen0250.Step": "ขั้นตอน",
    "gui.blocklyText.sen0250.Measure": "วัด",
    "gui.blocklyText.sen0250.readAccelerated": "module BMI160 คำสั่งวัดความเร่ง [AXIS] โดยใช้ความเร่งของแกน(g)",
    "gui.blocklyText.sen0250.getStepNumber": "module BMI160 คำสั่งเอาเลขของลำดับขั้น",
    "gui.blocklyText.sen0250.Gyro": "ไจโรสโคป",
    "gui.blocklyText.sen0250.Acc": "ความเร่ง",
    "gui.blocklyText.sen0224.readAccelerated": "อ่านค่าความเร่งของLIS2DH เซนเซอร์ [AXIS] แกน (mg)",
    "gui.blocklyText.dfr0023.readLM35": "อ่านpin [PIN] LM35 อุณหภูมิ(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "เมื่อpin [PIN] ตรวจพบการสั่นสะเทือน",
    "gui.blocklyText.dfr0028.readTiltSensor": "pin [PIN] ตรวจพบความเอียง？",
    "gui.blocklyText.dfr0029.readPress": "pin [PIN] กดปุ่ม？",
    "gui.blocklyText.dfr0030.readTouch": "pin [PIN] ตรวจพบการสัมผัส？",
    "gui.blocklyText.dfr0033.readMagnetic": "pin [PIN] ตรวจพบแม่เหล็ก？",
    "gui.blocklyText.dfr0034.readSound": "อ่านpin [PIN] ระดับความดัง",
    "gui.blocklyText.dfr0094.readIRReceived": "อ่านpin [PIN] ข้อมูลIR",
    "gui.blocklyText.dfr0094.onReceiveEvent": "When the infrared signal is received Pin [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "address",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "command",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "อ่านpin [PIN] เซ็นเซอร์วัดก๊าซคาร์บอนมอนอกไซด์",
    "gui.blocklyText.dfr0051.readVoltage": "อ่านpin [PIN] แรงดันไฟฟ้า(V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "อ่านpin [PIN] เซ็นเซอร์วัดความสั่นสะเทือนPiezo Disk",
    "gui.blocklyText.dfr0058.readRotation": "อ่านpin [PIN] เซ็นเซอร์วัดการหมุน",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "อ่านpin [PIN] จอยสติ๊ก [AXIS] แกน",
    "gui.blocklyText.dfr0061.readJoyStickButton": "pin [PIN] ปุ่มจอยสติ๊ก(Z)กด？",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOutข้อมูลขั้นต้นpin [DATAPIN] RTC pin [RTCPIN] cls pin [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "module Digital tube คำสั่งแสดงโค้ดของส่วนโดยใช้รหัส 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "module Digital tube คำสั่งเรียกแสดงเลข [NUM]",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "คำสั่งอ่านค่าของ lithium battery โดยใช้ประเภทของแบตเตอรี่ [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "แรงดันไฟฟ้า",
    "gui.blocklyText.dfr0563.remainingBattery": "คำสั่งวัดเปอร์เซนต์ของแบตเตอรี่ที่เหลือ(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "module AD คำสั่งสร้าง I2C address [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "module AD คำสั่งอ่าน [PIN] แรงดันไฟฟ้า (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "module AD คำสั่งอ่านความต่าง [AISLE] ของช่องว่างระหว่างแรงดันไฟฟ้า",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 and 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 and 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 and 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 and 3",
    "gui.blocklyText.dfr0076.readFlameSensor": "อ่านpin[PIN] เซ็นเซอร์เปลวไฟ",
    "gui.blocklyText.dfr0143.readAccelerometer": "อ่านpin [PIN] เครื่องวัดความเร่งแบบสามแกน [AXIS] แกน",
    "gui.blocklyText.sen0018.readInfraredMotion": "อ่านpin [PIN] เซ็นเซอร์จับความเคลื่อนไหวดิจิตอลอินฟาเรด",
    "gui.blocklyText.sen0014.readDistance": "อ่านpin [PIN] Sharp เซ็นเซอร์วัดระยะทางอินฟาเรด(mm)",
    "gui.blocklyText.sen0114.readMoisture": "อ่านpin [PIN] เซ็นเซอร์วัดความชื้นในดิน",
    "gui.blocklyText.sen0121.readSteam": "อ่านpin [PIN] เซ็นเซอร์ไอน้ำ",
    "gui.blocklyText.sen0204.readLiquidLevel": "pin [PIN] ถึงระดับของเหลว？",
    "gui.blocklyText.sen0203.heartRate": "อัตราการเต้นของหัวใจ(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "ความอิ่มตัวของออกซิเจนในเลือด(HbO2)",
    "gui.blocklyText.sen0202.up": "ขึ้น",
    "gui.blocklyText.sen0202.down": "ลง",
    "gui.blocklyText.sen0202.left": "ซ้าย",
    "gui.blocklyText.sen0202.right": "ขวา",
    "gui.blocklyText.sen0202.cw": "ตามเข็มนาฬิกา",
    "gui.blocklyText.sen0202.ccw": "ทวนเข็มนาฬิกา",
    "gui.blocklyText.dhtTHSensor.temperature": "อุณหภูมิ(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "ความชื้น(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "RGไฟB pin [PIN] เลขไฟ [NUM1] ถึง [NUM2] แสดงสี [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "แดง [RED] เขียว [GREEN] ฟ้า [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "ไฟRGB pin [PIN] เลขไฟ [NUM1] ถึง [NUM2] แสดงการเปลี่ยนสีไฟ จากสี [COLOR1] ถึง [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "ไฟRGB pin [PIN] ย้ายพิเซล [NUM] ",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "ไฟRGB pin [PIN] หมุนวนพิเซล [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": " pin [PIN] จำนวนไฟ [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "ไฟRGB pin [PIN] สว่าง [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "ไฟRGB pin [PIN] ดับทั้งหมด",
    "gui.blocklyText.ws2812.showHistogram": "ไฟRGB pin [PIN] ไฟเลข [NUM1] ถึง [NUM2] แสดงฮิสโตแกรม ค่าปัจจุบัน [VALUE] มากที่สุด [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "ไฟRGB ตั้งค่าpin [PIN] แสงสว่างของไฟเป็น [BRIGHTNESS]",
    "gui.blocklyText.neopixel.init": "Lamp initialization name [NAME] pin [PIN] number [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Light strip [NAME] [INDEX] Color [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Light strip [NAME] [INDEX] Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.fillColor": "The light strip [NAME] is fully lit and the color is [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Light strip [NAME] All on Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.flush": "Light strip [NAME] settings take effect",
    "gui.blocklyText.neopixel.off": "Light strip [NAME] is off",
    "gui.blocklyText.neopixel.setBrightness": "Light strip [NAME] sets brightness to [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Rainbow light effect [NAME] Quantity [COUNT] Brightness [BRIGHTNESS] Offset [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7(mPython board)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9(mPython box)",
    "gui.blocklyText.dsTSensor.ds18b20": "อ่าน [PIN] พินอุณหภูมิ DS18B20 (°C)",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "อ่านBMP280ค่าเซนเซอร์ [TYPE]",
    "gui.blocklyText.bmpTSensor.atmosPressure": "ความกดอากาศ(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "ระดับความสูง(m)",
    "gui.blocklyText.bmpTSensor.temperature": "อุณหภูมิ(℃)",
    "gui.blocklyText.LCD1602.initialIICAddr": "คำสั่งสร้างที่เก็บข้อมูลของ I2C LCD screen [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "คำสั่งแสดง [TEXT] ในแถวของจอ LCD [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "คำสั่งแสดง [TEXT] ในหน้าจอ LCDที่ตำแหน่ง X: [X] Y: [Y]",
    "gui.blocklyText.LCD1602.displayClear": "ล้างหน้าจอ",
    "gui.blocklyText.IICScan.readICCAddr": "อ่านเลขระบุตัวตนของเครื่อง I2C ที่ถูกสแกน",
    "gui.blocklyText.softSerialport.initialSerialport": "เริ่มพอร์ต [SERIALPORT] Rx: [PINR] Tx: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "ตั้งค่าซอฟต์แวร์อนุกรม [SERIALPORT] อัตราบอดเป็น [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "ซอฟต์แวร์อนุกรม [SERIALPORT] [WAY] [TEXT] [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "มีข้อมูลให้อ่านในซอฟต์แวร์อนุกรม [SERIALPORT]？",
    "gui.blocklyText.softSerialport.readSerialportData": "อ่านข้อมูลของซอฟต์แวร์อนุกรม [SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "อ่าน[TYPE]จากซอฟต์แวร์อนุกรม [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "ทำงานเมื่อเชื่อมต่อบลูทูธสำเร็จ",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "ทำงานเมื่อตัดการเชื่อมต่อบลูทูธ",
    "gui.blocklyText.bluetooth.receivedKey": "ทำงานเมื่อบลูทูลรับข้อมูล key [KEY] value [TYPE]",
    "gui.blocklyText.bluetooth.string": "สตริง",
    "gui.blocklyText.bluetooth.number": "ตัวเลข",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "เมื่อบลูทูธรับข้อมูลและพบ [SYMBOL] ทำงาน",
    "gui.blocklyText.bluetooth.sendStr": "เมื่อบลูทูธส่งสตริง key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "บลูทูธส่งตัวเลข key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.openService": "เปิดการบริการบลูทูธ",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "บลูทูธรับข้อมูล",
    "gui.blocklyText.leonardo.functionStarts": "Leonardoเริ่ม",
    "gui.blocklyText.leonardo.setBaud": "ตั้งค่า [SERIAL]อัตราบอดเป็น [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "จาก [SERIAL] อ่าน [READTYPE]",
    "gui.blocklyText.leonardo.serialPort1": "พอร์ตอนุกรม0",
    "gui.blocklyText.leonardo.serialPort2": "พอร์ตอนุกรม1",
    "gui.blocklyText.leonardo.dataReadable": "มีข้อมูลให้อ่าน[SERIAL]？",
    "gui.blocklyText.leonardo.readSerialData": "อ่าน [SERIAL] ข้อมูล",
    "gui.blocklyText.arduinounoR3.functionStarts": "คำสั่งเริ่มโปรแกรมผ่าน Uno kit",
    "gui.blocklyText.arduinounoR3.readdigital": "อ่านpin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "เซ็นเซอร์ดิจิตอล",
    "gui.blocklyText.arduinounoR3.buttonModule": "ปุ่มโมดูล",
    "gui.blocklyText.arduinounoR3.stickButton": "ปุ่มจอยสติ๊ก",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "เซ็นเซอร์ตรวจจับการสั่นสะเทือน",
    "gui.blocklyText.arduinounoR3.readAnalog": "อ่านpin [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "เซ็นเซอร์แบบแอนะล็อก",
    "gui.blocklyText.arduinounoR3.lightSensor": "เซ็นเซอร์ตรวจวัดความเข้มแสง",
    "gui.blocklyText.arduinounoR3.soundSensor": "เซ็นเซอร์ตรวจจับเสียง",
    "gui.blocklyText.arduinounoR3.stickDirection": "ทิศทางจอยสติ๊ก",
    "gui.blocklyText.arduinounoR3.angleSensor": "เซ็นเซอรมุม",
    "gui.blocklyText.arduinounoR3.graySensor": "เซนเซอร์วัดระดับสีเทา",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "เซ็นเซอร์วัดความชื้นในดิน",
    "gui.blocklyText.arduinounoR3.open": "เปิด",
    "gui.blocklyText.arduinounoR3.close": "ปิด",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] pin [PIN]  [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "ตัวกระตุ้นดิจิตอล",
    "gui.blocklyText.arduinounoR3.ledLights": "ไฟ LED",
    "gui.blocklyText.arduinounoR3.fanModule": "โมดูลพัดลม",
    "gui.blocklyText.arduinounoR3.recordModule": "โมดูลบันทึก",
    "gui.blocklyText.arduinounoR3.relay": "รีเลย์",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "การอ่านค่าระยะทางอัลตราโซนิก (ซม.) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "ตัวกระตุ้นการจำลอง",
    "gui.blocklyText.arduinounoR3.writeAnalog": "ตั้งค่าpin [PIN] [MODULE] เป็น [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "ไปข้างหน้า",
    "gui.blocklyText.arduinounoR3.backward": "ถอยหลัง",
    "gui.blocklyText.arduinounoR3.turnLeft": "หมุนซ้าย",
    "gui.blocklyText.arduinounoR3.turnRight": "หมุนขวา",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "หุ่นยนต์[DIR] ที่ความเร็ว [SPEED]%",
    "gui.blocklyText.arduinounoR3.motorForward": "ไปข้างหน้า",
    "gui.blocklyText.arduinounoR3.motorReverse": "ย้อนกลับ",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "ตั้งค่ามอเตอร์[MOTOR]  [DIR]ที่ความเร็ว [SPEED]",
    "gui.blocklyText.arduinounoR3.stopMotor": "หุ่นยนต์(MAและMBมอเตอร์)หยุด",
    "gui.blocklyText.arduinounoR3.setServo": "ตั้งค่าpin [PIN] เซอร์โวเป็น [DEGREE] องศา",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "ปล่อยอินฟราเรด [TYPE] pin [PIN] ค่า [VALUE] บิต [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "อินฟราเรด pin [PIN]ได้รับ [BUTTON] กดปุ่ม？",
    "gui.blocklyText.arduinounoR3.noSound": "ไม่มีเสียง",
    "gui.blocklyText.arduinounoR3.half": "Half",
    "gui.blocklyText.arduinounoR3.quarter": "Quarter",
    "gui.blocklyText.arduinounoR3.oneInEighth": "One-eighth",
    "gui.blocklyText.arduinounoR3.wholeBeat": "เต็็มจังหวะ",
    "gui.blocklyText.arduinounoR3.doubleBeat": "2 จังหวะ",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "ตั้งค่าpin [PIN] เล่นเสียงทรัมเป็ต [TONE] บีท [BEAT]",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "ตั้งค่าpin [PIN] MP3 โมดูลเสียงเป็น [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "ตั้งค่าpin [PIN] MP3โมดูลโหมดการเล่นเป็น [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "หยุด",
    "gui.blocklyText.arduinounoR3.play": "เล่น",
    "gui.blocklyText.arduinounoR3.loop": "ห่วง",
    "gui.blocklyText.arduinounoR3.random": "สุ่ม",
    "gui.blocklyText.arduinounoR3.pause": "หยุด",
    "gui.blocklyText.arduinounoR3.previous": "เพลงก่อนหน้า",
    "gui.blocklyText.arduinounoR3.next": "เพลงถัดไป",
    "gui.blocklyText.arduinounoR3.VolumePlus": "เพิ่มเสียง",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "ลดเสียง",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "ตั้งค่าpin [PIN] MP3โมดูลการเล่น [NUM] เพงแรก",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "อ่านพิน [PIN] อุณหภูมิ LM35 (° C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "อ่านpin [PIN] DHT11ของ [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "แดง",
    "gui.blocklyText.arduinounoR3.blue": "ฟ้า",
    "gui.blocklyText.arduinounoR3.green": "เขียว",
    "gui.blocklyText.arduinounoR3.readColorSensor": "อ่านTCS34725สีของเซนเซอร์ [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "4 บิท ท่อ nixie TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "เปิด",
    "gui.blocklyText.arduinounoR3.tm1650Close": "ปิด",
    "gui.blocklyText.arduinounoR3.clearScreen": "ทำความสะอาดหน้าจอ",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": "หลอด nixie 4หลัก_TM1650แสดงสตริง [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "[STATUS] [ORDER] ตำแหน่งทศนิยมของ 4 บิท nixie tube TM1650",
    "gui.blocklyText.arduinounoR3.bright": "สว่าง",
    "gui.blocklyText.arduinounoR3.dark": "มืด",
    "gui.blocklyText.arduinounoR3.setLightColor": "ไฟRGB pin [PIN] ไฟเลข [NUM] [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "ไฟRGB pin [PIN] ไฟเลข [NUM] ค่าR:[R] ค่าG:[G] Bค่า:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "ไฟRGB pin [PIN]  [NUM] LEDs",
    "gui.blocklyText.arduinounoR3.ledControl": "Led เมทริกซ์(max7219)เริ่มพารามิเตอร์ DIN pinเป็น [DIN] CSpin [CS] CLKpin [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "Ledเมทริกซ(max7219)แสดงรูปแบบ [PIC]",
    "videoSensing.categoryName": "การตรวจจับวิดีโอ",
    "videoSensing.direction": "ทิศทาง",
    "videoSensing.motion": "การเคลื่อนไหว",
    "videoSensing.off": "ปิด",
    "videoSensing.on": "เปิด",
    "videoSensing.onFlipped": "พลิก",
    "videoSensing.setVideoTransparency": "ตั้งค่าความโปร่งใสของวิดีโอเป็น [TRANSPARENCY]",
    "videoSensing.sprite": "สไปรต์",
    "videoSensing.stage": "เวที",
    "videoSensing.videoOn": "[ATTRIBUTE] วิดีโอ บน [SUBJECT]",
    "videoSensing.videoToggle": "[VIDEO_STATE] วิดีโอ",
    "videoSensing.whenMotionGreaterThan": "เมื่อวิดีโอเคลื่อนไหว > [REFERENCE]",
    "gui.blocklyText.max.maxStart": "MAXเริ่ม",
    "gui.blocklyText.max.onboardButtonPressed": "ปุ่มที่มากับตัวเครื่อง [PRESSED]?",
    "gui.blocklyText.max.pressed": "กดแล้ว",
    "gui.blocklyText.max.notPressed": "ยังไม่กด",
    "gui.blocklyText.max.all": "-1 ALL",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "ตั้งค่าไฟRGB [TYPE] สีเป็น [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "อ่านค่าเซนเซอร์เสียง",
    "gui.blocklyText.max.playSoundEffect": "เล่นเสียง [SOUND]",
    "gui.blocklyText.max.setDPinValue": "ตั้งค่าดิจิตอลpin [PIN] เป็น [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "อ่านดิจิตอล pin [PIN]",
    "gui.blocklyText.max.readAPinValue": "อ่านอะนาล็อกpin [PIN]",
    "gui.blocklyText.max.setServo": "ตั้งค่าpin [PIN] เซอร์โวเป็น [DEGREE] องศา",
    "gui.blocklyText.max.readLinefindingSensor": "อ่านเซนเซอร์ตรวจจับเส้น [DIR] ",
    "gui.blocklyText.max.left": "ซ้าย",
    "gui.blocklyText.max.middle": "กลาง",
    "gui.blocklyText.max.right": "ขวา",
    "gui.blocklyText.max.setLineFidingCarLights": "ตั้งค่าไฟLEDi5 [SWITCH]",
    "gui.blocklyText.max.on": "สว่าง",
    "gui.blocklyText.max.off": "ดับ",
    "gui.blocklyText.max.readUltrasoundSensor": "อ่านอัลตร้าโซนิคเซ็นเซอร์ (cm)",
    "gui.blocklyText.max.readLightSensor": "อ่านเซนเซอร์วัดความเข้มแสง[LEFTRIGHT]",
    "gui.blocklyText.max.maxSpeedMove": "MAXใช้[DIR]ที่ความเร็ว [SPEED]",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] ใช้ [DIR]ที่ความเร็ว[SPEED]",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] หยุด",
    "gui.blocklyText.max.showUserDefineExpressions": "แสดงรูปแบบ [EXPRESSION] สี [COLOR]",
    "gui.blocklyText.max.clear": "จอชัด",
    "gui.blocklyText.max.do": "Do",
    "gui.blocklyText.max.re": "Re",
    "gui.blocklyText.max.mi": "Mi",
    "gui.blocklyText.max.fa": "Fa",
    "gui.blocklyText.max.sou": "So",
    "gui.blocklyText.max.la": "La",
    "gui.blocklyText.max.xi": "Ti",
    "gui.blocklyText.max.highdo": "highdo",
    "gui.blocklyText.max.brake": "brake",
    "gui.blocklyText.max.rebound": "rebound",
    "gui.blocklyText.max.bullets": "bullets",
    "gui.blocklyText.max.longecho": "long echo",
    "gui.blocklyText.max.playfulending": "ending",
    "gui.blocklyText.max.yo": "yo",
    "gui.blocklyText.max.wo": "wo",
    "gui.blocklyText.max.robottalking": "robot talking",
    "gui.blocklyText.knowflow.readORP": "อ่านอะนาล็อกpin [PIN] (ORP)เซนเซอร์ (mV)",
    "gui.blocklyText.knowflow.readEC": "อ่านอะนาล็อกpin [PIN] (EC)เซนเซอร์ (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "อ่านอะนาล็อกpin [PIN] PHเซนเซอร์(V1)",
    "gui.blocklyText.knowflow.readECV2": "อ่านอะนาล็อกpin [PIN] (EC)เซนเซอร์ (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "อ่านอะนาล็อกpin [PIN] PHเซนเซอร์(V2)",
    "gui.blocklyText.knowflow.readDO": "อ่านอะนาล็อกpin [PIN] DOเซนเซอร์ (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "ตั้งค่าpin [PIN] ปั๊มสูบจ่ายของเหลวแบบหมุนเวียน[DIR] ที่ความเร็ว [SPEED]% ",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] pin [PIN] รีเลย์",
    "translate.translateBlock": "แปล [WORDS] เป็น [LANGUAGE]",
    "translate.defaultTextToTranslate": "สวัสดี",
    "translate.viewerLanguage": "ภาษา",
    "gui.blocklyText.goble.readAxis": "อ่าน GOBLE จอยสติ๊ก [AXIS] แกน",
    "gui.blocklyText.goble.buttonIsPressed": "GOBLE [BUTTON] กดปุ่ม？",
    "gui.blocklyText.tds.readAnalogPin": "อ่านอะนาล็อกpin [PIN] TDS เซนเซอร์(ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "มอเตอร์ 2 จังหวะวิ่งแบบต่อเนื่อง X axis : [XSET] y axis: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "มอเตอร์ที่ช่วยเพิ่มจังหวะ [AXIS] แกน[STEPS] จังหวะต่อการหมุน 1 รอบ(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "มอเตอร์ที่ช่วยเพิ่มจังหวะ ขยับ [ROTATEDIR] สำหรับ [STEPS] จำนวนขั้น",
    "gui.blocklyText.steppermotor.setJointSteps": "มอเตอร์ 2 จัังหวะวิ่งแบบต่อเนื่อง. ขยับ [XSTEPS] ไปบนแกน x [ROTATEDIRX] ขั้น ขยับ [YSTEPS] ไปบนแกน y [ROTATEDIRY] ขั้น",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] เริ่ม",
    "gui.blocklyText.multithread.stopThread": "หยุด [THREAD]",
    "gui.blocklyText.multithread.startThread": "เริ่มใช้งาน [THREAD]",
    "gui.blocklyText.multithread.loop1": "thread 1",
    "gui.blocklyText.multithread.loop2": "thread 2",
    "gui.blocklyText.multithread.loop3": "thread 3",
    "gui.blocklyText.multithread.loop4": "thread 4",
    "gui.blocklyText.multithread.loop5": "thread 5",
    "gui.blocklyText.multithread.loop6": "thread 6",
    "gui.blocklyText.multithread.loop7": "thread 7",
    "gui.blocklyText.multithread.loop8": "thread 8",
    "gui.blocklyText.multithread.all": "all",
    "text2speech.speakAndWaitBlock": "พูด [WORDS]",
    "text2speech.setVoiceBlock": "ตั้งเสียงเป็น  [VOICE]",
    "text2speech.setLanguageBlock": "ตั้งภาษาเป็น [LANGUAGE]",
    "text2speech.alto": "อัลโต",
    "text2speech.tenor": "เทเนอร์",
    "text2speech.squeak": "เอี๊ยด ๆ",
    "text2speech.giant": "ไจแอนท์",
    "text2speech.kitten": "ลูกแมว",
    "text2speech.defaultTextToSpeak": "สวัสดี",
    "gui.blocklyText.DFR0151.adjustTime": "ปรับปรุงDS1307เวลาเป็น[YEAR]ปี[MONTH]เดือน[DATE]วัน[HOUR]ชั่วโมง[MINUTE]นาที[SECOND]วินาที",
    "gui.blocklyText.DFR0151.getTime": "ได้รับDS1307เวลา[INDEX]",
    "gui.blocklyText.DFR0151.Year": "year",
    "gui.blocklyText.DFR0151.month": "month",
    "gui.blocklyText.DFR0151.date": "date",
    "gui.blocklyText.DFR0151.hour": "hour",
    "gui.blocklyText.DFR0151.minute": "minute",
    "gui.blocklyText.DFR0151.second": "second",
    "gui.blocklyText.DFR0151.week": "week",
    "gui.blocklyText.DFR0469.adjustTime": "ปรับปรุงSD2405เวลาเป็น[YEAR]ปี[MONTH]เดือน[DATE]วัน[HOUR]ชั่วโมง[MINUTE]นาที[SECOND]วินาที",
    "gui.blocklyText.DFR0469.getTime": "ได้รับSD2405ชั่วโมง[INDEX]",
    "gui.blocklyText.DFR0126.setPin": "ตั้งค่าpinโมดูลวิเคราะห์คลื่นเสียง #S[SPIN] #R[RPIN] อะนาล็อกpin[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "อ่่านค่าความถี่ของเสียง [FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "เริ่มต้นNFCโมดูลอินเตอร์เฟส I2C",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "เริ่มต้นNFCโมดูลอินเตอร์เฟส ฮาร์ดแวร์พอร์ตอนุกรม Rx [RX] Tx [TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "เริ่มต้นNFCโมดูลอินเตอร์เฟส [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "เริ่มต้นNFCโมดูลอินเตอร์เฟส [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "module NFC คำสั่งสร้างบล๊อค [DATABLOCK] ขนาดจำนวนไบท์ [BYTES] และเขียนข้อมูลลงไปด้วยค่าที่กำหนด [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "อ่านบล็อกข้อมูลโมดูลNFC [DATABLOCK]จำนวน[BYTES]ไบต์(number)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "อ่านบล็อกข้อมูลโมดูลNFC [DATABLOCK]ข้อมูลทั้งหมด(string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "อ่านโมดูNFC UID(srting)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "ตรวจหาบัตร UID:[TEXT]？",
    "gui.blocklyText.DFR0126.scanSerialNfc": "ตรวจหาบัตร?",
    "gui.blocklyText.DFR0126.serial1": "serial1",
    "gui.blocklyText.DFR0126.serial2": "serial2",
    "gui.blocklyText.DFR0126.serial3": "serial3",
    "gui.blocklyText.sen0245.setVL53L0X": "VL53L0X ความแม่นยำ[PRECISION] วิธีในการวัด[VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "เอาระยะของเลเซอร์ VL53L0X (mm)",
    "gui.blocklyText.SEN0245.LOW": "ต่ำ(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "สูง(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "เดี่ยว",
    "gui.blocklyText.SEN0245.Continuous": "ต่อเนื่อง",
    "gui.blocklyText.serialMp3.initSerialMp3": "เริ่มต้นอนุกรมMP3โมดูลอินเตอร์เฟส[SERIALPORT] Rx [RX] Tx [TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "ตั้งค่าอนุกรมMP3โมดูลโหมดการเล่นเป็น [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "ตั้งค่าอนุกรมMP3โมดูลการเล้น[VALUE]เพลงแรก",
    "gui.blocklyText.serialMp3.setMP3Volume": "ตั้งค่าอนุกรมMP3โมดูลความดังเสียงเป็น[VOLUME]%",
    "gui.blocklyText.serialMp3.play": "เล่น",
    "gui.blocklyText.serialMp3.pause": "หยุด",
    "gui.blocklyText.serialMp3.end": "สิ้นสุด",
    "gui.blocklyText.serialMp3.prev": "ก่อนหน้า",
    "gui.blocklyText.serialMp3.next": "ถัดไป",
    "gui.blocklyText.serialMp3.volumeUp": "เพิ่มเสียง",
    "gui.blocklyText.serialMp3.volumeDown": "ลดเสียง",
    "gui.blocklyText.serialMp3.playlist": "เล่นแทร็ก",
    "gui.blocklyText.serialMp3.insertTracks": "แทรกแทร็ก",
    "gui.blocklyText.serialMp3.volume": "ความดังเสียง",
    "gui.blocklyText.servo360.setServo360": "ตั้งค่าpin [PIN] หมุนเวียนเซอร์โว [DIR] ที่ความเร็ว [SPEED] %",
    "gui.blocklyText.servo360.CW": "ข้างหน้า",
    "gui.blocklyText.servo360.CCW": "ย้อนกลับ",
    "gui.blocklyText.TCS34725.readTcs34725": "อ่านTCS34725เซนเซอร์สี[COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "หมุนGAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "ไม่หมุนGAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "แสดงข้อความ[TEXT] [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "ตั้งค่ารายการ[DIR]",
    "gui.blocklyText.regEmotion8_16.left": "ซ้าย",
    "gui.blocklyText.regEmotion8_16.right": "ขวา",
    "gui.blocklyText.regEmotion8_16.lightAll": "เปิดดอตแมทริกซ์ทั้งหมด[COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "พิกัด x:[XAXIS], y:[YXAXIS]แสดงเป็น[COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "ทำความสะอาดหน้าจอ",
    "gui.mainHeader.err": "#ตรวจพบความผิดปรกติ: หัวโปรแกรมที่เหมือนกัน [%1] ใช้พร้อมกันไม่ได้. กรุณาลบบล๊แคที่ซ้ำ.",
    "gui.mainHeader.help": "#เคล็ดลับ: ถ้าหลายๆโปรแกรมต้องทำงานพร้อมๆกัน, ให้ใช้ \"multi-threading function\" ใน \"Extension\", ดูเอกสารกำกับเพื่อขอข้อมูลเพิ่มเติมและความช่วยเหลือเบื้อต้น.",
    "gui.threadHeader.err1": "#ตรวจพบความผิดปรกติ: หัวโปรแกรม  [%1] ไม่สามารถใช้พร้อมกันได้. มันเป็นของเครื่องมือคนละชิ้นบนกระดาน.",
    "gui.threadHeader.err2": "#เคล็ดลับสำหรับเวลาตรวจเจอข้อผิดพลาด: หัวของ event เดียวกัน [%1] ไม่สามารถใช้พน้อมกันได้. กรุณาลบบล๊อคที่ซ้ำกัน.",
    "gui.blocklyText.mqtt.whenReceivedData": "เมื่อ[TOPIC]ได้รับ[MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTTข่าวสาร",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi เชื่อมต่อ:[ACCOUNT] พาสเวิร์ด:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi เชื่อมต่อสำเร็จ？",
    "gui.blocklyText.wifi.wifiDisConnected": "ตัดการเชื่อมต่อ Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi ตั้งค่าAPโหมด:[ACCOUNT2] รหัส:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi รับ soft ap IP",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi get [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Error code",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "เมื่อUDPเซิร์ฟเวอร์ได้รับ [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "ตั้งค่าUDPพอร์ตเซิร์ฟเวอร์ [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "UDPเซิร์ฟเวอร์ส่งข่างสาร [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "เมื่อUDPไคลเอ็นท์ได้รับ [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "ตั้งค่าUDP ไคลเอนต์IP [IPADDR] พอร์ต [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "UPDไคลเอ็นท์ส่งข่าวสาร [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "ประกาศข่าวสาร",
    "gui.blocklyText.udp.ClientRecvedMsg": "ประกาศข่าวสาร",
    "gui.blocklyText.mqtt.mqttConnect": "เชื่อมต่อ MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT เชื่อมต่อสำเร็จ？",
    "gui.blocklyText.mqtt.acceptance.meaage": "เมื่อ[TOPIC]ได้รับ[MSG]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT ส่งข่าวสาร [MSG]ถึง[TOPIC]",
    "gui.blocklyText.mqtt.initial": "MQTT ขั้นเริ่มต้น [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「IFLYTEK」synthetic audio [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「IFLYTEK」identify audio [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「IFLYTEK」identify result",
    "gui.blocklyText.iflytek.CommunicationText": "text",
    "gui.blocklyText.iflytek.CommunicationTransfer": "transfer to audio file",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "audio file to be identified",
    "gui.blocklyText.mqtt.mqttDisconnect": "ตัดการเชื่อมต่อMQTT",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 อ่านระดับความสูง(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 อ่านความกดอากาศ(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 อ่านอุณหภูมิ(℃)",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 วัด [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "วัตถุ",
    "gui.blocklyText.sen0206.environment": "สิ่งแวดล้อม",
    "gui.blocklyText.sen0206.celsius": "เซลเซียส",
    "gui.blocklyText.sen0206.fahrenheit": "ฟาเรนไฮต์",
    "gui.blocklyText.sen0253.init": " 10DOF ขั้นต้นpin 'I2C_ADDRระดับไฟ [LEVEL]",
    "gui.blocklyText.sen0253.reset": " 10DOF รีเซ็ตเซนเซอร์",
    "gui.blocklyText.sen0253.power": " 10DOF ตั้งค่าโหมดพลังงาน [MODEL]",
    "gui.blocklyText.sen0253.getEuler": " 10DOF ได้รับมุมออยเลอร์ [EULER]",
    "gui.blocklyText.sen0253.getQua": " 10DOF ได้รับธาตุหลัก [AXIS] แกน",
    "gui.blocklyText.sen0253.getAcc": " 10DOF ได้รับความเร็ว [AXIS] แกน(mg)",
    "gui.blocklyText.sen0253.getMag": " 10DOFได้รับวัดค่าของแม่เหล็ก [AXIS] แกน(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": " 10DOF ได้รับอัตราเร่ง [AXIS] แกน(mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": " 10DOF ได้รับการวัดน้ำหนัก [AXIS] แกน(mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": " 10DOF ได้รับความเร็วเชิงมุม [AXIS] แกน",
    "gui.blocklyText.sen0253.getBmp280": " 10DOF ได้รับ [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "ต่ำ",
    "gui.blocklyText.sen0253.HIGH": "สูง",
    "gui.blocklyText.sen0253.normal": "ปกติ",
    "gui.blocklyText.sen0253.lowPower": "ใช้พลังงานต่ำ",
    "gui.blocklyText.sen0253.suspend": "แขวน",
    "gui.blocklyText.sen0253.Roll": "ม้วน(°)",
    "gui.blocklyText.sen0253.Pitch": "ขว้าง(°)",
    "gui.blocklyText.sen0253.Yaw": "หันเห(°)",
    "gui.blocklyText.sen0253.temperature": "อุณหภูมิ",
    "gui.blocklyText.sen0253.elevation": "ความสูงเหนือน้ำทะเล",
    "gui.blocklyText.sen0253.airPressure": "ความกดอากาศ",
    "gui.blocklyText.sen0290.init": "เซนเซอร์แสงไฟ ตั้งค่าirq pin [IRQPIN] และi2cที่อยู่ [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "module lighting sensor lightning sensor iic สร้างค่าเบื้องต้นสำเร็จไหม？",
    "gui.blocklyText.sen0290.manualCal": "module lighting sensor เซ้ทค่าความจุ [CAPACITANCE] โหมด [MODE] ระยะทาง[DIST]",
    "gui.blocklyText.sen0290.indoors": "ในอาคาร",
    "gui.blocklyText.sen0290.outdoors": "นอกอาคาร",
    "gui.blocklyText.sen0290.disturberDis": "ห้ามรบกวน",
    "gui.blocklyText.sen0290.disturberEn": "รบกวนได้",
    "gui.blocklyText.sen0290.errMsg": "ข่าวสารผิดพลาด",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "module lighting sensor เอาข้อมูลสถานะ",
    "gui.blocklyText.sen0290.GetLightningDistKm": "module lighting sensor เอาระยะทางของแสง(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "module lighting sensor เอาความแรงของการปะทะแบบสดๆ",
    "gui.blocklyText.weather.weatherGetWithCity": "จังหวัด [COUNTRY] เมือง [CITY] ของ [WEATHER]",
    "gui.blocklyText.weather.temperaturHigh": "อุณหภูมิสูงสุด(℃)",
    "gui.blocklyText.weather.temperaturLow": "อุณหภูมิต่ำสุด(℃)",
    "gui.blocklyText.weather.weather": "อากาศ",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "ตั้งค่าตัวแปรของเซิฟเวอร์[TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "อัพเดท(update)แท็ก(tag) [TAG] มูลค่า(value) [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "อ่าน(get)แท็ก(tag) [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "ลบ(delete)แท็ก(tag) [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "นับ(count)",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "ทดสอบการเชื่อมต่อเครือข่าย",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "ค้าหา(search) หมายเลขเริ่มต้น(no) [NUMS] นับตัวแปร(count) [NUMV]แทรก(tag) [TAG] ประเภท(type) [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "tag",
    "gui.blocklyText.tinywebdb.value": "value",
    "gui.blocklyText.tinywebdb.both": "both",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "HongKong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.whenIHear": "เมื่อฉันได้ยิน [PHRASE]",
    "speech.listenAndWait": "ฟังและรอ",
    "speech.getSpeech": "วิเคราะห์ผลลัพธ์",
    "speech.setRecordTime": "ตั้งค่าบันทึกแต่ละครั้ง [TIME] วินาที",
    "speech.stopRecord": "วิเคราะห์เสียงพูดเสร็จสิ้น",
    "speech.controlSonogram": "[CONTROL] Sonogram",
    "speech.setServer": "สลับเซิร์ฟเวอร์วิเคราะห์เสียงพูดเป็น [SERVER]",
    "speech.defaultWhenIHearValue": "เริ่มเลย!",
    "speech.show": "แสดง",
    "speech.hide": "ซ่อน",
    "speech.server1": "เซิร์ฟเวอร์1",
    "speech.server2": "เซิร์ฟเวอร์2",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 can not be placed in the interrupt event, may cause dht11 reading abnormal",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 can not be placed in the interrupt event, may cause dht22 reading abnormal",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 cannot be placed in an interrupt event, which may result in abnormal pm2.5 readings",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 reads data, which may cause led dot matrix initialization failure",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 reads data, which may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "the mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to be set to fail",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "the mp3 setting playback mode cannot be placed in an interrupt event, which may cause the playback mode to fail",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "Mp3 settings playback can not be placed in the interrupt event, may cause the settings to play failed",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "Serial port mp3 initialization cannot be placed in an interrupt event, which may cause initialization failure",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "The serial port mp3 playback mode cannot be placed in an interrupt event, which may cause the setup play mode to be lost",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "Serial port mp3 playback cannot be placed in an interrupt event, which may cause playback to fail",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "The serial port mp3 setting volume cannot be placed in the interrupt event, which may cause the volume to fail",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloq's mqtt initialization soft serial port cannot be placed in an interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq sends a message that cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq read mqtt message can not be placed in the interrupt event, may cause the reception to fail",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "The initial soft serial port of http of obloq cannot be placed in the interrupt event, which may cause the soft serial port communication to fail",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "The get request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "Obloq http post request cannot be placed in an interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "The put request of obloq http cannot be placed in the interrupt event, which may cause the request to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "Infrared reception cannot be placed in an interrupt event, which may cause reception failure",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "Infrared reception may cause the LED dot matrix initialization to fail",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "Infrared reception may cause the LED dot matrix display pattern to fail",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "Infrared transmission cannot be placed in an interrupt event, which may cause the transmission to fail",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause initialization to fail",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause setup pins to fail",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the pin to fail to fail",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause display color to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause color adjustment failure",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event, which may cause the move to fail",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause loop movement to fail",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB strips cannot be placed in an interrupt event and may cause the display histogram to fail",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "Led dot matrix can not be placed in the interrupt event, may cause initialization failure",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "The led dot matrix cannot be placed in an interrupt event, which may cause the display pattern to fail",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "The soft serial output cannot be placed in an interrupt event, which may cause the output to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "The data read by the soft serial port cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Soft serial port output data and read data colleague use, may cause data abnormalities",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "The soft serial port read integer cannot be placed in the interrupt event, which may cause the read content to be abnormal",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "Soft serial port output data and reading integer colleague use may cause abnormal reading data",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "When dht11 and PM2.5 are in two threads, dht11 reads may fail if pm2.5 reads too frequently. It is recommended to reduce the pm2.5 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "When dht11 and ds18b20 are in two threads respectively, if ds18b20 reads too frequently, dht11 may fail to read, it is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "When dht11 and mp3 are initialized in two threads, mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "When dht11 and mp3 set volume are in two threads respectively, mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "When dht11 and mp3 setup modes are in two threads respectively, mp3 setup mode may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "When dht11 and mp3 player are in two threads, mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "When dht11 and serial mp3 are initialized in two threads, serial mp3 initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "When dht11 and serial mp3 control module are in two threads respectively, the mp3 control module may cause dht11 reading errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "When dht11 and serial mp3 player are in two threads, serial mp3 player may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "When dht11 and serial mp3 set volume are in two threads respectively, serial mp3 set volume may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "When dht11 and RGB are initialized in two threads, RGB initialization can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "When dht11 and RGB set brightness in two threads, RGB set brightness may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "When dht11 and RGB are extinguished in two threads, RGB extinction may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "When dht11 and RGB display colors in two threads, RGB display colors may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "When dht11 and RGB display a gradient in two threads, respectively, RGB display a gradient may cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "When dht11 and RGB move pixels in two threads, RGB move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "When dht11 and RGB loop move pixels in two threads, respectively, RGB loop move pixels can cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "When dht11 and RGB display columns in two threads respectively, RGB display bars may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "When dht11 and soft serial send are in two threads respectively, soft serial send may cause dht11 read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "When dht11 and obloq MQTT are initialized in two threads respectively, obloq MQTT initialization may cause dht11 to read errors. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "When dht11 and obloq MQTT send messages in two threads respectively, obloq MQTT send messages that may cause dht11 to read errors",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "When dht11 and obloq HTTP put requests are in two threads, it can cause a dht11 read error",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "When dht11 and obloq HTTP initialization are in two threads respectively, it may cause dht11 reading error. It is recommended to wait for the initialization to complete before reading dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "When dht11 and obloq HTTP get requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "When dht11 and obloq HTTP post requests are in two threads, it may cause dht11 to read incorrectly",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "When dht11 and infrared emission are in two threads respectively, it may cause errors in reading dht11. It is suggested to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "When ds18b20 and PM2.5 are in two threads respectively, it may cause PM2.5 reading error, so it is suggested to reduce ds18b20 frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "When ds18b20 and obloq MQTT are initialized in two threads respectively, MQTT initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "When ds18b20 and obloq HQTT put requests are in two threads respectively, the put request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "When ds18b20 and obloq HTTP initialization are in two threads respectively, the HTTP initialization may fail. It is recommended to wait for the initialization to complete before reading ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "When ds18b20 and obloq HQTT get requests are in two threads respectively, the get request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "When ds18b20 and obloq HQTT post requests are in two threads respectively, the post request may fail. It is recommended to reduce the ds18b20 read frequency",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Reading ds18b20 may result in no infrared data, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Reading ds18b20 may cause errors in receiving data by soft serial port, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "When PM2.5 read and obloq MQTT initialization are carried out in two threads respectively, MQTT initialization failure or PM2.5 read error may be caused. It is recommended to avoid simultaneous execution of MQTT initialization and PM2.5 read",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "When PM2.5 reading and obloq MQTT send messages in two threads respectively, PM2.5 reading errors may be caused. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "When PM2.5 read and obloq HTTP put requests are in two threads, it may cause put request failure or PM2.5 read error. It is recommended to reduce put request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "When PM2.5 reads and obloq HTTP initializations are carried out in two threads respectively, HTTP initializations may fail or PM2.5 reads may go wrong. It is recommended to avoid simultaneous execution of HTTP initializations and PM2.5 reads",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "When PM2.5 read and obloq HTTP get requests are in two threads respectively, it may cause get request failure or PM2.5 read error. It is recommended to reduce get request frequency or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "When PM2.5 read and obloq HTTP post request are in two threads respectively, it may cause post request failure or PM2.5 read error. It is recommended to reduce the frequency of post request or PM2.5 read frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "When PM2.5 reading and MP3 initialization are carried out in two threads respectively, PM2.5 reading may fail. It is recommended to avoid the simultaneous execution of PM2.5 reading and MP3 initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "When PM2.5 reading and MP3 setting volume are in two threads respectively, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "When PM2.5 reading and MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "When PM2.5 reading and serial MP3 player are in two threads respectively, PM2.5 reading may fail. It is recommended not to play the device too often",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "When PM2.5 reading and serial MP3 playing are in two threads respectively, PM2.5 reading may fail. It is recommended not to switch songs too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "When PM2.5 reading and serial MP3 setting volume are respectively in two threads, PM2.5 reading may fail. It is recommended not to adjust volume too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "When PM2.5 reading may cause infrared reception failure, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "When PM2.5 reading and RGB initialization are carried out in two threads respectively, PM2.5 reading errors may be caused. It is recommended to avoid simultaneous execution of PM2.5 reading and RGB initialization",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "When PM2.5 reading and RGB setting brightness are respectively in two threads, PM2.5 reading error may be caused. It is recommended not to adjust brightness too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "When PM2.5 reading and RGB extinguishing are in two threads, PM2.5 reading errors may be caused. It is recommended not to extinguish RGB too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "When PM2.5 reading and RGB display colors are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to switch colors too frequently",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "When PM2.5 reading and RGB display the gradient in two threads respectively, PM2.5 reading error may be caused, it is recommended not to switch the gradient too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "When PM2.5 reading and RGB moving pixels are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to move pixels too frequently",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_readIRReceived": "Infrared reception may cause the onboard rgb to set a three-color anomaly, which can be solved by calling rgb display color multiple times",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to be initialized abnormally",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 initialization to be abnormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "Using Wi-Fi may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp uses Wi-Fi, which may cause the serial port mp3 to play abnormally",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "Using Wi-Fi may cause serial port mp3 to play track failed",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to play tracks failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "Using Wi-Fi may cause the serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp uses Wi-Fi, may cause serial port mp3 to set the volume failed",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "Using Wi-Fi may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp uses Wi-Fi, which may cause 360 steering gear to set abnormal speed",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "When PM2.5 read and RGB loop move pixels in two threads respectively, PM2.5 read errors may be caused. It is recommended not to execute loop move pixels too often",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "When PM2.5 reading and RGB display columns are in two threads respectively, PM2.5 reading errors may be caused. It is recommended not to execute RGB display columns too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "When PM2.5 reading and led dot array initialization are carried out in two threads respectively, led dot array initialization may go wrong. It is recommended to avoid the simultaneous execution of PM2.5 reading and led dot array initialization",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "When PM2.5 reading and led dot array display are in two threads respectively, led dot array display may be wrong, it is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5 reading may cause errors in PM2.5 reading. It is recommended not to send data too frequently",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5 reading may cause soft serial port reading failure. It is recommended not to read PM2.5 too frequently",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 may not receive infrared data, it is recommended to reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "When MP3 and obloq MQTT initialization is in two threads, it may cause MQTT initialization to fail. It is recommended to avoid simultaneous MQTT initialization and MP3 initialization",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "When MP3 and obloq HTTP put requests are in two threads, it is possible to cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "When MP3 and obloq HTTP initializations are in two threads, HTTP initializations may fail. It is recommended to avoid both HTTP initializations and MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "When MP3 and obloq HTTP get requests are in two threads, it may cause get requests to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "When MP3 and obloq HTTP post requests are in two threads, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of MP3 usage",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 may cause soft serial port read failure, it is recommended not to use MP3 too often",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "Serial MP3 may not receive infrared data, it is recommended to reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "When serial port MP3 and obloq MQTT initialization are in two threads respectively, MQTT initialization may fail. It is recommended to avoid simultaneous execution of MQTT initialization and serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "When serial MP3 and obloq HTTP put requests are in two threads, it may cause a put request to fail. It is recommended to reduce the frequency of put requests or reduce the frequency of serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "HTTP initialization may fail when serial MP3 and obloq are initialized in two threads. It is recommended to avoid both HTTP initialization and serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "When the serial port MP3 and obloq HTTP get requests are in two threads respectively, it may cause the get request to fail. It is recommended to reduce the frequency of get requests or reduce the frequency of serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "When the serial port MP3 and obloq HTTP post requests are in two threads respectively, it may cause the post request to fail. It is recommended to reduce the frequency of post requests or reduce the frequency of the serial port MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "Serial port MP3 may cause soft serial port read failure, it is recommended not to use serial port MP3 too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving errors, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "Soft serial obloq may cause errors in infrared receiving data, so it is recommended to avoid sending messages frequently by obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "The initialization of RGB band may lead to the error of obloq receiving messages. It is recommended to avoid frequent use of RGB band",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Setting the brightness of the light belt may cause an error in obloq receiving messages. It is recommended to avoid frequent brightness adjustment",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB belt extinguish may lead to soft serial obloq receiving messages error, it is recommended to avoid frequent extinguish the belt",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB light band display may cause obloq to receive messages error, it is recommended to avoid frequent display of color",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "The display of gradient on the RGB band may cause errors in obloq receiving messages. It is recommended to avoid frequent display of gradient",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB band moving pixels may cause soft serial obloq to receive messages incorrectly",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "The RGB band loops the pixels around and may cause the soft serial obloq to receive messages out",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB ribbon display bar may cause soft serial obloq receive message error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "When soft serial obloq and led dot array initialization are carried out in two threads respectively, it may cause led dot array initialization error. It is recommended to avoid simultaneous execution of soft serial obloq and led dot array initialization",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "When soft serial obloq and led dot array display are respectively in two threads, it may cause led dot array display error",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "Soft serial port sending may cause obloq reading error, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "Soft serial port obloq send may cause soft serial port read failure, it is recommended not to send data too often",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "Infrared emission may cause infrared reception of no data, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "When infrared emission may cause soft serial port reading failure, it is recommended to reduce the infrared emission frequency",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "The initialization of RGB band may result in no infrared reception of data",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "The brightness setting of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent brightness setting of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "The RGB lamp belt is off, which may cause the infrared data can not be received",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "The moving pixel of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent moving pixel of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "The display color of RGB light belt may cause the infrared data cannot be received, so it is recommended to avoid frequent display color of RGB light belt",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "Infrared reception may cause led initialization failure",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "Infrared reception may cause led display errors",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "Soft serial port sending may cause infrared data can not receive, it is recommended to avoid frequent sending soft serial port",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB band may cause soft serial port reading failure, so it is recommended to reduce the use frequency of RGB band",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Data received by soft serial port may cause led dot matrix display error",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "The sending of soft serial port may lead to the error of receiving data of soft serial port. It is recommended to avoid the execution of sending data and receiving data at the same time",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "The initialization of serial port MP3 may lead to errors in infrared receiving data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "MP3 serial port setting playing mode may cause errors in infrared receiving data, so it is recommended to avoid setting playing mode too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "Serial MP3 player may lead to infrared data errors, it is recommended to avoid switching songs too frequently",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "The volume setting of serial port MP3 may cause errors in infrared receiving data. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "Initialization of max7219 may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "Max7219 display images may cause errors in infrared received data, so it is recommended to avoid displaying images too frequently",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "The MP3 module of serial port may cause the pin interrupt cannot be triggered. It is recommended to avoid using MP3 module of serial port too often",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "The max7219 display module may cause pin interrupts not to be triggered. It is recommended to avoid using the max7219 display too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "DHT reading may lead to errors in infrared receiving messages. It is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "Ds18b20 reading may cause errors in soft external receiving messages, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5 reading may cause errors in receiving messages outside the software. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "Mp3 initialization read may cause infrared receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "The volume setting of mp3 may cause the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "Mp3 setting mode may cause errors in infrared receiving messages. It is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "Mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "Setting mode of serial port mp3 may cause error of infrared receiving message, it is recommended to avoid setting mode too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "Serial mp3 player may cause errors in infrared receiving messages, so it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Setting the volume of serial port mp3 may lead to the error of infrared receiving messages. It is recommended to avoid adjusting the volume too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "Infrared emission may cause errors in infrared receiving messages, so it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "The initialization of the RGB band may lead to errors in receiving infrared messages. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving infrared messages. It is recommended to avoid setting the brightness too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB band display may cause errors in infrared receiving messages. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "Gradient discoloration of RGB lamp band display may lead to error of infrared receiving message. It is recommended to avoid displaying gradient discoloration too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Moving pixels with RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid moving pixels too frequently",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "The circular movement of pixels with the RGB light belt may cause errors in infrared receiving messages. It is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB ribbon display columnar may cause infrared receiving message error, it is recommended to avoid displaying columnar too often",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Infrared receiving messages may cause initialization failure of max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Infrared receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "Soft serial printing may cause errors in infrared receiving messages, so it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "Soft serial port obloq MQTT initialization may cause errors in infrared received data, so it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "Soft serial obloq put request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "Soft serial port obloq HTTP initialization may lead to infrared receiving data errors, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "Soft serial obloq get request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "Soft serial obloq post request may lead to infrared receiving data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "The clear display of RGB lamp band may cause errors in receiving messages in the soft infrared. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "DHT reading may lead to soft serial receiving message error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "Ds18b20 reading may cause the soft serial port to receive the message error, it is recommended to reduce the ds18b20 reading frequency",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5 reading may cause errors in receiving messages by soft serial port. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "Mp3 initialization reads may result in a soft serial receiving message error, and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Setting the volume of mp3 may cause the error of receiving the message on the soft serial port. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "Mp3 setting mode may cause the soft serial port to receive the message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "Mp3 playback may lead to soft serial receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "Mp3 setting mode of serial port may cause error of receiving messages by soft serial port. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "Mp3 playing on serial port may cause errors in receiving messages on soft serial port. It is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message from soft serial port. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "Infrared emission may cause errors in receiving messages by soft serial port. It is recommended to avoid sending infrared messages too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "The initialization of the RGB band may result in an error in receiving messages from the soft serial port. It is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Setting the brightness of the RGB band may cause errors in receiving messages from the soft serial port. It is recommended to avoid setting the brightness too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "Clear display of RGB light band may cause error of receiving message by soft serial port. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB band display may cause errors in receiving messages from the soft serial port. It is recommended to avoid too frequent RGB band display",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB lamp tape display gradient may cause message error in soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause the soft serial port to receive messages incorrectly. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB light belt circular movement of pixels may cause the soft serial port to receive messages error, it is recommended to avoid too frequently executing the circular movement of pixels",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB ribbon display columnar may cause the soft serial port to receive the message error, it is recommended to avoid performing display columnar too often",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "Soft serial receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "Soft serial receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "Soft serial printing may cause error in receiving messages from the soft serial port. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "DHT reads may cause pin interrupt triggering failure. It is recommended to reduce the DHT read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "Ds18b20 read may cause pin interrupt trigger failure, it is recommended to reduce ds18b20 read frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5 reading may cause pin interrupt trigger failure. It is recommended to reduce PM2.5 reading frequency",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Mp3 initialization reads may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "Mp3 setting volume may cause pin interrupt trigger failure. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "Mp3 setting mode may cause pin interrupt triggering failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "Mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "Serial port mp3 initialization read may cause pin interrupt trigger failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "Setting mode of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "Serial mp3 player may cause pin interrupt trigger failure, it is recommended to avoid switching music too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Setting the volume of serial port mp3 may cause pin interrupt trigger failure. It is recommended to avoid adjusting the volume too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "Infrared emission may cause pin interrupt trigger failure, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB ribbon initialization may cause pin interrupt trigger failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Setting brightness of RGB band may cause pin interrupt trigger failure. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB strip clear display may cause pin interrupt trigger failure, it is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB tape display may cause pin interrupt trigger failure. It is recommended to avoid RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "The RGB tape display of the gradient may cause the pin interrupt trigger to fail. It is recommended to avoid displaying the gradient too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB band moving pixels may cause pin interrupt triggering failure and it is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB light belt loop move pixel may cause pin interrupt trigger failure, it is recommended to avoid too often loop move pixel",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB ribbon display bar may cause pin interrupt trigger failure. It is recommended to avoid displaying bar too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "Pin interruption may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "Pin interruption may cause max7219 to display incorrectly",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "Soft serial printing may cause pin interrupt trigger failure, it is recommended to avoid too frequent soft serial printing",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "Soft serial port obloq MQTT initialization may cause pin interrupt triggering failure and it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "Soft serial obloq put requests may cause pin interrupt triggering failure and it is recommended to avoid requesting data too often",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "Soft serial obloq HTTP initialization may cause pin interrupt triggering failure, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "Soft serial obloq get request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "Soft serial obloq post request may cause pin interrupt trigger failure, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "DHT reading may lead to soft serial obloq receiving messages error, it is recommended to reduce the DHT reading frequency",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "Ds18b20 reading may cause obloq to receive messages incorrectly, so it is recommended to reduce the reading frequency of ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5 reading may cause error in obloq receiving messages. It is recommended to reduce the reading frequency of PM2.5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "Mp3 initialization read may result in soft serial obloq receiving message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Mp3 setting volume may result in soft serial obloq receiving message error. It is recommended to avoid adjusting volume too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "Mp3 setting mode may lead to soft serial obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "Mp3 playback may lead to soft serial obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "Serial port mp3 initialization read may cause soft serial port obloq receive message error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "Serial port mp3 setting mode may cause soft serial port obloq receiving message error, it is recommended to avoid setting mode too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "Serial port mp3 player may lead to soft serial port obloq receiving messages error, it is recommended to avoid switching music too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Setting volume of serial port mp3 may cause error of receiving message of soft serial port obloq. It is recommended to avoid adjusting volume too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "Infrared emission may lead to soft serial obloq receiving messages error, it is recommended to avoid sending infrared too frequently",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Soft serial bloq sending messages may lead to infrared reception errors, it is recommended to avoid obloq sending messages too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB ribbon initialization may cause soft serial obloq to receive an error message, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Setting brightness of RGB light belt may cause error of obloq receiving messages. It is recommended to avoid setting brightness too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Clear display of RGB light band may cause error of obloq receiving message. It is recommended to avoid too frequent clear display",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB tape display may result in an error in obloq receiving messages. It is recommended to avoid performing RGB tape display too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB lamp tape display gradient may cause message error in obloq soft serial port. It is recommended to avoid displaying gradient too frequently.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Moving pixels with the RGB light belt may cause an error in obloq receiving messages. It is recommended to avoid moving pixels too often",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB light belt circular movement of pixels may lead to soft serial port obloq receive messages error, it is recommended to avoid too often loop movement of pixels",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB ribbon display columnar may cause soft serial obloq receive messages error, it is recommended to avoid too often display columnar",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "Soft serial obloq receiving messages may cause max7219 initialization failure",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "Soft serial obloq receiving message may cause max7219 display error",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "Soft serial printing may cause obloq to receive messages incorrectly. It is recommended to avoid performing soft serial printing too frequently",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "Soft serial port obloq sending data may cause errors in receiving data by soft serial port. It is recommended to avoid too frequent sending of data by obloq",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "Soft serial obloq sending data may cause pin interrupt cannot be triggered, it is recommended to avoid obloq sending data too frequently",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "Soft serial port obloq MQTT initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "Soft serial obloq put request may cause the soft serial port to receive data error, it is recommended to avoid too frequent requests for data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "Soft serial port obloq HTTP initialization may cause the soft serial port to receive data error, it is recommended to avoid simultaneous execution",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "Soft serial obloq get request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "Soft serial port obloq post request may cause the soft serial port to receive data error, it is recommended to avoid too frequent request of data",
    "gui.blocklyText.maqueen.robotServo": "เซอร์โว [INDEX] มุม [ANGLE]",
    "gui.blocklyText.esp32.rgbDisable": "ปิด [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "ทั้งหมด",
    "gui.blocklyText.esp32.rgbdisable.No.0": "No.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "No.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "No.2",
    "gui.blocklyText.microbit.true": "ใช้งาน",
    "gui.blocklyText.microbit.false": "ยกเลิกการใช้งาน",
    "gui.blocklyText.maqueen.wirelessSignalData": "สัญญาณอินฟราเรด",
    "gui.blocklyText.motucamera.initIICSerial": "ขั้นแรกกล้อง [MU] อินเตอร์เฟซ [IICSERIAL]",
    "gui.blocklyText.motucamera.resetConfig": " [MU] คืนค่าไปการตั้งค่าเริ่มต้น",
    "gui.blocklyText.motucamera.algorithmSwitch": " [MU] [SWITCH] ขั้นตอน [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": " [MU] led [LED] วิเคราะห์สี [COLOR1] ยังไม่ได้วิเคราะห์สี [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": " [MU] ตั้งค่าขั้นตอน [ALGORITHM] ระดับ [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": " [MU] ดิจิตอลซูม [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": " [MU] ตั้งค่าพอร์ตอัตราบอด [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": " [MU] ตั้งค่าความสมดุลแสงสีขาว [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": " [MU] ตั้งค่าความสูงของโหมดFPS [OPENED]",
    "gui.blocklyText.motucamera.isDetect": " [MU] ตรวจพบ [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": " [MU] ตรวจพบ การวิเคราะห์สี x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": " [MU] ตรวจพบสี ตรวจพบสี= [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "ได้รับ [MU] ขั้นตอน [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "ได้รับ [MU] การวิเคราะห์สี [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": " [MU] รูปทรงบัตร [CARD]",
    "gui.blocklyText.motucamera.trafficCard": " [MU] บัตรโดยสาร [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": " [MU] เลขบัตร [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": " [MU]สี การวิเคราะห์ สี = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "พบสี",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "วิเคระาห์สี",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "พบลูกบอล",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "พบร่างกาย",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "รูปทรงบัตร",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "บัตรโดยสาร",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "เลขบัตร",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "ใช้งาน",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "ปิดการใช้งาน",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "ปิด",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "white",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "red",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "green",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "blue",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "yellow",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "purple",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "cayan",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "white",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "อัตโนมัติ",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "ความเร็ว",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "ความสมดุล",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "ความถูกต้อง",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "อัตโนมัติ",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "ล็อค",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "ขาว",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "เหลือง",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "อัตโนมัติ",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "ระดับ1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "ระดับ2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "ระดับ3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "ระดับ4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "ระดับ5",
    "gui.blocklyText.motucamera.setHighFPSOff": "ปิด",
    "gui.blocklyText.motucamera.setHighFPSOn": "เปิด",
    "gui.blocklyText.motucamera.colorDetectBlack": "black",
    "gui.blocklyText.motucamera.colorDetectWhite": "white",
    "gui.blocklyText.motucamera.colorDetectRed": "red",
    "gui.blocklyText.motucamera.colorDetectYellow": "yellow",
    "gui.blocklyText.motucamera.colorDetectGreem": "green",
    "gui.blocklyText.motucamera.colorDetectCayan": "cayan",
    "gui.blocklyText.motucamera.colorDetectBlue": "blue",
    "gui.blocklyText.motucamera.colorDetectPerple": "perple",
    "gui.blocklyText.motucamera.colorDetectOthers": "อื่นๆ",
    "gui.blocklyText.motucamera.colorDetectPositionX": "ตำแหน่ง x",
    "gui.blocklyText.motucamera.colorDetectPositionY": "ตำแหน่ง y",
    "gui.blocklyText.motucamera.colorDetectPositionW": "สูง",
    "gui.blocklyText.motucamera.colorDetectPositionH": "กว้าง",
    "gui.blocklyText.motucamera.colorDetectPositionL": "แท็ก",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "ช่องสีแดง",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "ช่องสีเขียว",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "ช่องสีฟ้า",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "ช่องแท็ก",
    "gui.blocklyText.motucamera.shapeCardTick": "ขีด",
    "gui.blocklyText.motucamera.shapeCardCross": "ข้าม",
    "gui.blocklyText.motucamera.shapeCardCircle": "วงกลม",
    "gui.blocklyText.motucamera.shapeCardSquare": "สี่เหลี่ยม",
    "gui.blocklyText.motucamera.shapeCardTriangle": "สามเหลี่ยม",
    "gui.blocklyText.motucamera.trafficCardForward": "ด้านหน้า",
    "gui.blocklyText.motucamera.trafficCardLeft": "ด้านซ้าย",
    "gui.blocklyText.motucamera.trafficCardRight": "ด้านขวา",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "กลับหัว",
    "gui.blocklyText.motucamera.trafficCardStop": "จอดรถ",
    "gui.blocklyText.dfr0072.dfr0072Dot": "module Digital tube คำสั่งแสดงจุด [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "ใช่",
    "gui.blocklyText.dfr0072.dotFalse": "ไม่",
    "gui.blocklyText.dfr0072.numOff": "ปิด",
    "gui.blocklyText.mp3.initMp3": "สร้างหน้าตอของ DFPlayer MP3 โมดูล [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "ตั้งค่าDFPlayer MP3โมดูลเสียงเป็น [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "ตั้งค่าDFPlayer MP3โมดูลโหมดการเล่นเป็น [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "ตั้งค่าDFPlayer MP3โมดูลการเล่น [NUM] เพลงแรก",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "ตั้งค่าpin [PIN] DFPlayer MP3 โมดูลเสียงเป็น [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "ตั้งค่าpin [PIN] DFPlayer MP3โมดูลโหมดการเล่นเป็น [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "ตั้งค่าpin [PIN] DFPlayer MP3โมดูลการเล่น [NUM] เพงแรก",
    "gui.blocklyText.compatible.shapedBlock": "The new version changes point: the event program building block with its own variables is replaced by the event program building block without variables.",
    "gui.blocklyText.compatible.shapedBlock.variable": "The new version change point: change from independent program building block to variable program building block in event program",
    "gui.blocklyText.compatible.booleanToRound": "New version change point: Changed from conditional program building block to numerical program building block.",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "The new version changes point: add soft and hard serial port switch drop-down option in the program building block.",
    "gui.blocklyText.compatible.mp3.deletePin": "New version change point: Remove the pin drop-down option in the program building block.",
    "gui.blocklyText.compatible.sen0206.addDropdown": "New version change point: Add drop-down options to the program building block.",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "New version change point: Increase the pin drop-down option in the program building block.",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "New version change point: Modify the serial port drop-down option in the program building block.",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "The new version changes point: changed from the event program building block to the conditional program building block.",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Forward",
    "gui.blocklyText.maqueenPlus.Backward": "Backward",
    "gui.blocklyText.maqueenPlus.left": "Left",
    "gui.blocklyText.maqueenPlus.right": "Right",
    "gui.blocklyText.maqueenPlus.all": "All",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "set motor [DIR] move by [SPEED] speed [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "set motor [DIR] stop",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] speed compensation [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "read [DIR] motor speed",
    "gui.blocklyText.maqueenPlus.readMoterDir": "read [DIR] motor direction (0:stop 1:forward 2:back)",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "set [DIR] RGB show [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "read the sensation sensor [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "read the sensation sensor [INDEX] grayscale value",
    "gui.blocklyText.maqueenPlus.getVersion": "get version",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "read ultrasonic sensor trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "when received [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "infrared data",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "paint.paintEditor.hue": "สี",
    "paint.paintEditor.saturation": "ความอิ่มตัว",
    "paint.paintEditor.brightness": "ความสว่าง",
    "gui.comingSoon.message1": "ไม่ต้องกังวล เรากำลังทำอยู่ {emoji}",
    "gui.comingSoon.message2": "เร็วๆ นี้...",
    "gui.comingSoon.message3": "เรากำลังทำอยู่ {emoji}",
    "paint.paintEditor.fill": "สีพื้น",
    "paint.paintEditor.costume": "คอสตูม",
    "paint.paintEditor.group": "กลุ่ม",
    "paint.paintEditor.ungroup": "แยกกลุ่ม",
    "paint.paintEditor.undo": "ย้อนกลับ",
    "paint.paintEditor.redo": "ทำซ้ำ",
    "paint.paintEditor.forward": "มาข้างหน้า",
    "paint.paintEditor.backward": "ไปข้างหลัง",
    "paint.paintEditor.front": "หน้าสุด",
    "paint.paintEditor.back": "หลังสุด",
    "paint.paintEditor.more": "เพิ่มเติม",
    "paint.modeTools.brushSize": "ขนาด",
    "paint.modeTools.eraserSize": "ขนาดยางลบ",
    "paint.modeTools.copy": "คัดลอก",
    "paint.modeTools.paste": "วาง",
    "paint.modeTools.delete": "ลบ",
    "paint.modeTools.curved": "โค้ง",
    "paint.modeTools.pointed": "แหลม",
    "paint.modeTools.thickness": "ความหนา",
    "paint.modeTools.flipHorizontal": "พลิกแนวนอน",
    "paint.modeTools.flipVertical": "พลิกแนวตั้ง",
    "paint.modeTools.filled": "เติมสี",
    "paint.modeTools.outlined": "สีขอบ",
    "paint.paintEditor.bitmap": "แปลงเป็นบิตแมป",
    "paint.paintEditor.vector": "แปลงเป็นเวกเตอร์",
    "paint.paintEditor.stroke": "สีขอบ",
    "paint.brushMode.brush": "แปรง",
    "paint.eraserMode.eraser": "ยางลบ",
    "paint.fillMode.fill": "สีพื้น",
    "paint.lineMode.line": "เส้น",
    "paint.ovalMode.oval": "วงกลม",
    "paint.rectMode.rect": "สี่เหลี่ยม",
    "paint.reshapeMode.reshape": "จัดรูปร่างใหม่",
    "paint.roundedRectMode.roundedRect": "สี่เหลี่ยมขอบมน",
    "paint.selectMode.select": "เลือก",
    "paint.textMode.text": "ข้อความ",
    "paint.colorPicker.swap": "สลับสี",
    "gui.dialog.notConnectDevice": "ไม่ได้เชื่อมต่ออุปกรณ์,กรุณาเลือกอุปกรณ์ที่ต้องการเชื่อมต่อจากเมนู",
    "gui.dialog.prompt": "หมายเหตุ",
    "gui.dialog.openPort": "เปิดพอร์ต",
    "gui.dialog.error": "ผิดพลาด",
    "gui.dialog.notSelectDevice": "ไม่มีอุปกรณ์ที่เลือก,กรุณากดปุ่ม”ส่วนขยาย“ด้านล่านซ้ายมือของซอฟต์แวร์,</br>กดเพื่อเลือกอุปกรณ์ที่คุณต้องการเชื่อมต่อ",
    "gui.dialog.connectDeviceError1": "เชื่อมต่ออุปกรณ์(",
    "gui.dialog.connectDeviceError2": ")ล้มเหลว，กรุณาทำตามขั้นตอนด้านล่าง：</br> *เปลี่ยนusbพอร์ท เสียบสายusbใหม่อีกครั้ง</br> *เช็คว่าเชื่อมต่อแล้วหรือไม่",
    "gui.dialog.connectDeviceError3": "บอร์ดควบคุมหลัก</br> *เข้าร่วมกลุ่มพูดคุยทางการ(671877416)เพื่อส่งฟีดแบ็ค",
    "gui.dialog.calibration1": "</br> *กรุณาหมุน",
    "gui.dialog.calibration2": "ตรวจปรับบอร์ดควบคุมหลัก</br>",
    "gui.dialog.runAsManager": "Please close Mind+ and right click on the software icon and select \"Run as administrator\". After starting, select this function again.",
    "gui.dialog.runErrorForVortex": "ระบบทำงานไม่ปกติ，ออกจากVortex",
    "gui.dialog.runError": "ระบบทำงานไม่ปกติ",
    "gui.dialog.close": "ปิด",
    "gui.dialog.variableNameSpecialCharacters": "ตัวแปรชื่อ %1 ไม่สามารถรวมกับอักขระพิเศษได้:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "ชื่อฟังชั่น %1 ไม่สามารถรวมกับอักขระพิเศษได้:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "ฟังชั่นอาร์กิวเมนต์  %1 ไม่สามารถรวมกับอักขระพิเศษได้:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "และ",
    "gui.dialog.yes": "ใช่",
    "gui.dialog.no": "ไม่",
    "gui.dialog.maxReset": "ต้องการลบรหัสในพื้นที่แก้ไขและโหลดรหัสตั้งค่าโรงงาน",
    "gui.dialog.feedbackCannotEmpty": "ฟีดแบ็ค/ไม่สามารถเว้นว่างได้!",
    "gui.dialog.noInstallCompiler": "ไม่ได้ติดตั้งคอมไพรเลอร์",
    "gui.dialog.install": "การติดตั้งออนไลน์",
    "gui.dialog.cancle": "ยกเลิก",
    "gui.dialog.installingCompiler": "กำลังติดตั้งคอมไพรเลอร์",
    "gui.dialog.uploading": "กำลังอัพโหลด",
    "gui.dialog.changeLanuage": "การเปลี่ยนภาษาจะทำให้โปรแกรมปัจจุบันถูกลบออกหมด，ทำต่อหรือไม่？",
    "gui.dialog.boardNotSupportMpy": "บอร์ดปัจจุบัน: %1 ไม่สนุบสนุนmicroPython",
    "gui.dialog.loadBlankItem": "ไม่สนับสนุนโครงการ</br> กำลังดาวน์โหลดโครงการเปล่าใหม่",
    "gui.dialog.switchBaudError": "การเปลี่ยนอัตราบอดผิดพลาด</br>",
    "gui.dialog.turnMicrobitCompass": "Need to calibrate the electronic compass, please adjust the attitude of mciro: bit to move the blinking LED lights until all LED lights are on",
    "gui.dialog.operationShow": "สาธิตการดำเนินการ",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "คอมพิวเตอร์",
    "gui.dialog.delete": "ลบ",
    "gui.dialog.sureDeleteFile": "คุณแน่ใจหรือไม่ที่จะลบไฟล์[%2]บน %1？",
    "gui.dialog.sureDeleteFolder": "คุณแน่ใจหรือไม่ที่จะลบโฟลเดอร์์[%2]บน %1？",
    "gui.dialog.uncorrectPath": "เลือกแพทไม่ถูกต้อง",
    "gui.dialog.laterDownLoad": "ดาวน์โหลดภายหลัง",
    "gui.dialog.downLoadUpdate": "Download Update",
    "gui.dialog.dwnLoadWebsite": "ดาวน์โหลด",
    "gui.dialog.laterUpdate": "อัพเดทภายหลัง",
    "gui.dialog.update": "อัพเดท",
    "gui.dialog.getUpdateMsgError": "การได้รับอัพเดทข่าวสารผิดพลาด",
    "gui.dialog.ok": "OK",
    "gui.dialog.closeCodeBoxes": "แน่ใจหรือไม่ว่าคุณต้องการปิด %1 กล่องรหัส？",
    "gui.dialog.emailError": "รูปแบบอีเมลไม่ถูกต้อง!",
    "gui.dialog.emailEmpty": "อีเมลไม่สามารถว่างได้!",
    "gui.dialog.bigFilePrompt": "ขนาดไฟล์ใหญ่เกินไป, กรุณาอดทนรอสักครู่, ขอบคุณ!",
    "gui.dialog.successFeedbackPrompt1": "ขอบคุณสำหรับฟีดแบ็คของคุณ, จะโหลดหน้าใหม่ภายใน3วินาทีหลัง",
    "gui.dialog.successFeedbackPrompt2": "ฟีดแบ็คของคุณส่งสำเร็จแล้ว, กรุณาส่งอีกครั้งภายไม่กี่วินาทีหลัง",
    "gui.dialog.failedFeedbackPrompt": "ส่งฟีดแบ็คล้มเหลว, พวกเราขอแสดงความเสียใจในความไม่สะดวกสบาย, คุ๊สามารถเข้าร่วมกลุ่มQQทางการเพื่อส่งฟีดแบ็ค, ขอบคุณ",
    "gui.dialog.successFeedbackPrompt3": "ขอบคุณสำหรับฟีดแบ็คของคุณ",
    "gui.dialog.forcedCloseSoftWare": "การจัดเก็บไฟล์เกิดความผิดพลาด, ต้องการบังคับปิดซอฟต์แวร์หรือไม่",
    "gui.dialog.saveSuccess": "จัดเก็บสำเร็จ",
    "gui.dialog.noSelectTruePath": "เลือกแพทไม่ถูกต้อง",
    "gui.dialog.newItemContent": "คุณจะสร้างโปรเจคว่างใหม่ทับโปรเจคปัจจุบัน, คุณแน่ใจที่จะสร้างหรือไม่？",
    "gui.dialog.deleteBlocksError": "ลบ %1 บล็อกล้มเหลว，กรุณาลบด้วยตัวเอง",
    "gui.dialog.netIsConnect": "กรุณาเช็คว่าได้เชื่อมต่อเครือข่ายหรือไม่",
    "gui.dialog.needToUpdate": "ต้องการอัพเดทคอมไพรเลอร์เป็นเวอร์ชั่นล่าสุดหรือไม่: %1",
    "gui.dialog.cantUseMpythonInArduinoC": "ไม่ได้ติดตั้งคอมไพรเลอร์,  ภายใต้ Arduino C คุณไม่สามรถใช้งาน mpython ได้.หากคอมพิวเตอร์ไม่มีเครือข่ายคุณสามารถดาวน์โหลด 'แพ็คเกจปลั๊กอินออฟไลน์คอมไพเลอร์' บนเว็บไซต์ทางการ",
    "gui.dialog.enterMpyMode": "เข้าmicropythonโหมด",
    "gui.dialog.runHardware": "ต้องการจัดการฮาร์ดแวร์,กรุณาเชื่อมต่ออุปกรณ์ก่อน",
    "gui.dialog.sureDelete": "คุณแน่ใจว่าต้องการลบหรือไม่?",
    "gui.dialog.notSupportWebGL": "การดาวน์โหลดเวทีมีความผิดพลาด，อาจจะไม่สนับสนุนWebGL，</br>ด้านล่างคือข้อมูลอย่างระเอียดเกี่ยวกับความผิดพลาด: </br> %1 </br>กลุ่มQQสำหรับติดต่อพวกเรา群联系我们：671877417",
    "gui.dialog.boardIsnotMPython": "ปัจจุบันเชื่อมต่อกับบอร์ดควบคุมหลับไม่ใช่mPython，กรุณาเชื่อมต่อกับmPython",
    "gui.dialog.swdMicrobitUpload": "คอมพิวเตอร์ของคุณต้องSWDในการเขียนmicro:bit, กรุณาใช้สิทะิ์ของแอดมินในการใช้Mind+",
    "gui.dialog.openPortError": "เปิดพอร์ต %1 ผิดพลาด: %2",
    "gui.dialog.pressedA": "กรุณากดปุ่มและค้างปุ่ม Aไว้，อย่าปล่อย",
    "gui.dialog.unpressedA": "กรุณาปล่อยปุ่มA",
    "gui.dialog.unsupportProject": "ไม่สนับสนุนโครงการ</br>กำลังดาวน์โหลดโครงการเปล่าใหม่",
    "gui.dialog.openProjectError": "โปรเจคที่ทำโดยโหมดScratchต้องเปิดในโหมดScratch</br>กรุณาเช็คโหมดปัจจุบัน",
    "gui.dialog.installCompilerError1": "ดาวน์โหลดล้มเหลว, คุณสามารถดาวน์โหลดตัวติดตั้งออฟไลน์หรือดาวน์โหลดใหม่",
    "gui.dialog.installCompilerOffline": "ดาวน์โหลดคอมไพรเลอร์ออฟไลน์",
    "gui.dialog.installCompilerError2": "ตรวจสอบผิดพลาด, คุณสามารถดาวน์โหลดตัวติดตั้งออฟไลน์หรือดาวน์โหลดใหม่",
    "gui.dialog.installCompilerError3": "ติดตั้งผิดพลาด, คุณสามารถดาวน์โหลดตัวติดตั้งออฟไลน์หรือดาวน์โหลดใหม่",
    "gui.dialog.installCompilerSuccess": "ติดตั้งคอมไพรเลอร์เสร็จสมบูรณ์，คุณสามารถใช้Esp33ได้ภายใต้ArduinoC",
    "gui.dialog.sureAdapter": "หาอะแดปเตอร์ไม่พบ, กรุณาเช็คว่าเชื่อมต่ออะแดปเตอร์แล้วหรือไม่",
    "gui.dialog.checkPort": "เปิดพอร์ตล้มเหลว，กรุณาตรวจสอบพอร์ตว่าถูกใช้อยู่หรือไม่",
    "gui.dialog.changeBoard": "การเปลี่ยนบอร์ดจะทำให้โปรแกรมปัจจุบันถูกลบออกหมด，ทำต่อหรือไม่？",
    "gui.crashMessage.somethingWrong": "ไอ๊หยา！ เกิดความผิดพลาด",
    "gui.crashMessage.reload": "ดาวน์โหลดใหม่",
    "gui.crashMessage.submit": "ส่ง",
    "gui.crashMessage.attachment": "สิ่งที่แนบมาด้วย",
    "gui.crashMessage.errFeedbackNote": "คุณสามารถเขียนข้อมูลข้อผิดพลาดหรือคำแนะนำที่นี่, Mind+จะดีขึ้นด้วยความร่วมมือของคุณ",
    "gui.crashMessage.promptContent": "ขอแสดงความเสียใจ, เหมือนว่า Mind +พัง,  คุณสามารถส่งข้อผิดพลาดนี้ให้ทีมMind +ได้ที่ด้านขวา, ขอบพระคุณอย่างยิ่ง! กรุณารีเซ็ทหน้าใหม่เพื่อลองอีกครั้ง",
    "gui.dialog.isSaveProject": "จัดเก็บโปรเจ็คปัจจุบันหรือไม่",
    "gui.dialog.save": "จัดเก็บ",
    "gui.dialog.notSave": "ไม่จัดเก็บ",
    "gui.dialog.variableIsNull": "Variable name cannot be empty",
    "gui.dialog.listNameIsNull": "list name cannot be empty",
    "gui.dialog.pictureAINotNull": "cannot be null",
    "gui.dialog.pictureAIInitServer": "please initialize the server first",
    "gui.dialog.pictureAIInitServerError": "Error initializing the server, please check the account number and secret key",
    "gui.dialog.versionPrompt": "The current project version「%1」",
    "gui.dialog.versionGreater": "The project version is higher than the current software version, project version 「%1」",
    "BACKDROPS_ARCTIC": "Arctic",
    "BACKDROPS_BASEBALL": "Baseball",
    "BACKDROPS_BASEBALL_2": "Baseball 2",
    "BACKDROPS_BASKETBALL": "Basketball",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Beach Malibu",
    "BACKDROPS_BEACH_RIO": "Beach Rio",
    "BACKDROPS_BEDROOM": "Bedroom",
    "BACKDROPS_BEDROOM_2": "Bedroom 2",
    "BACKDROPS_BEDROOM_3": "Bedroom 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Bench With View",
    "BACKDROPS_BLUE_SKY": "Blue Sky",
    "BACKDROPS_BLUE_SKY_2": "Blue Sky 2",
    "BACKDROPS_BLUE_SKY3": "Blue Sky3",
    "BACKDROPS_BOARDWALK": "Boardwalk",
    "BACKDROPS_CANYON": "Canyon",
    "BACKDROPS_CASTLE_1": "Castle 1",
    "BACKDROPS_CASTLE_2": "Castle 2",
    "BACKDROPS_CASTLE_3": "Castle 3",
    "BACKDROPS_CASTLE_4": "Castle 4",
    "BACKDROPS_CHALKBOARD": "Chalkboard",
    "BACKDROPS_CIRCLES": "Circles",
    "BACKDROPS_CITY_WITH_WATER": "City With Water",
    "BACKDROPS_COLORFUL_CITY": "Colorful City",
    "BACKDROPS_CONCERT": "Concert",
    "BACKDROPS_DESERT": "Desert",
    "BACKDROPS_FARM": "Farm",
    "BACKDROPS_FIELD_AT_MIT": "Field At Mit",
    "BACKDROPS_FLOWERS": "Flowers",
    "BACKDROPS_FOREST": "Forest",
    "BACKDROPS_GALAXY": "Galaxy",
    "BACKDROPS_GARDEN-ROCK": "Garden-rock",
    "BACKDROPS_GREEK_THEATER": "Greek Theater",
    "BACKDROPS_HALL": "Hall",
    "BACKDROPS_HAY_FIELD": "Hay Field",
    "BACKDROPS_GRAFFITI": "Graffiti",
    "BACKDROPS_HEARTS1": "Hearts1",
    "BACKDROPS_HEARTS2": "Hearts2",
    "BACKDROPS_HILL": "Hill",
    "BACKDROPS_JUNGLE": "Jungle",
    "BACKDROPS_JURASSIC": "Jurassic",
    "BACKDROPS_LIGHT": "Light",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": "Moon",
    "BACKDROPS_MOUNTAIN": "Mountain",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "Nebula",
    "BACKDROPS_NEON_TUNNEL": "Neon Tunnel",
    "BACKDROPS_NIGHT_CITY": "Night City",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Night City With Street",
    "BACKDROPS_PARTY": "Party",
    "BACKDROPS_PATHWAY": "Pathway",
    "BACKDROPS_PARTY_ROOM": "Party Room",
    "BACKDROPS_PLAYGROUND": "Playground",
    "BACKDROPS_PLAYING_FIELD": "Playing Field",
    "BACKDROPS_POOL": "Pool",
    "BACKDROPS_PURPLE": "Purple",
    "BACKDROPS_RAYS": "Rays",
    "BACKDROPS_REFRIGERATOR": "Refrigerator",
    "BACKDROPS_ROOM_1": "Room 1",
    "BACKDROPS_ROOM_2": "Room 2",
    "BACKDROPS_SAVANNA": "Savanna",
    "BACKDROPS_SCHOOL": "School",
    "BACKDROPS_SLOPES": "Slopes",
    "BACKDROPS_SOCCER": "Soccer",
    "BACKDROPS_SOCCER_2": "Soccer 2",
    "BACKDROPS_SPACE": "Space",
    "BACKDROPS_SPACE_2": "Space 2",
    "BACKDROPS_SPACE_3": "Space 3",
    "BACKDROPS_SPACE_4": "Space 4",
    "BACKDROPS_SPOTLIGHT-STAGE": "Spotlight-stage",
    "BACKDROPS_STARS": "Stars",
    "BACKDROPS_STRIPES": "Stripes",
    "BACKDROPS_THEATER": "Theater",
    "BACKDROPS_THEATER_2": "Theater 2",
    "BACKDROPS_TREE": "Tree",
    "BACKDROPS_UNDERWATER_1": "Underwater 1",
    "BACKDROPS_UNDERWATER_2": "Underwater 2",
    "BACKDROPS_URBAN": "Urban",
    "BACKDROPS_WALL_1": "Wall 1",
    "BACKDROPS_WALL_2": "Wall 2",
    "BACKDROPS_WATER_AND_ROCKS": "Water And Rocks",
    "BACKDROPS_WETLAND": "Wetland",
    "BACKDROPS_WINTER": "Winter",
    "BACKDROPS_WINTER-LIGHTS": "Winter-lights",
    "BACKDROPS_WITCH_HOUSE": "Witch House",
    "BACKDROPS_WOODS": "Woods",
    "BACKDROPS_WOODS_AND_BENCH": "Woods And Bench",
    "BACKDROPS_XY-GRID": "Xy-grid",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "Abby-a",
    "COSTUMES_ABBY-B": "Abby-b",
    "COSTUMES_ABBY-C": "Abby-c",
    "COSTUMES_ABBY-D": "Abby-d",
    "COSTUMES_AMON": "Amon",
    "COSTUMES_ANDIE-A": "Andie-a",
    "COSTUMES_ANDIE-B": "Andie-b",
    "COSTUMES_ANDIE-C": "Andie-c",
    "COSTUMES_ANDIE-D": "Andie-d",
    "COSTUMES_ANINA_POP_DOWN": "Anina Pop Down",
    "COSTUMES_ANINA_POP_FRONT": "Anina Pop Front",
    "COSTUMES_ANINA_POP_L_ARM": "Anina Pop L Arm",
    "COSTUMES_ANINA_POP_LEFT": "Anina Pop Left",
    "COSTUMES_ANINA_POP_R_ARM": "Anina Pop R Arm",
    "COSTUMES_ANINA_POP_RIGHT": "Anina Pop Right",
    "COSTUMES_ANINA_POP_STAND": "Anina Pop Stand",
    "COSTUMES_ANINA_R_CROSS": "Anina R Cross",
    "COSTUMES_ANINA_STANCE": "Anina Stance",
    "COSTUMES_ANINA_TOP_FREEZE": "Anina Top Freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "Anina Top L Step",
    "COSTUMES_ANINA_TOP_R_STEP": "Anina Top R Step",
    "COSTUMES_ANINA_TOP_STAND": "Anina Top Stand",
    "COSTUMES_APPLE": "Apple",
    "COSTUMES_ARROW1-A": "Arrow1-a",
    "COSTUMES_ARROW1-B": "Arrow1-b",
    "COSTUMES_ARROW1-C": "Arrow1-c",
    "COSTUMES_ARROW1-D": "Arrow1-d",
    "COSTUMES_AVERY_WALKING-A": "Avery walking-a",
    "COSTUMES_AVERY_WALKING-B": "Avery walking-b",
    "COSTUMES_AVERY_WALKING-C": "Avery walking-c",
    "COSTUMES_AVERY_WALKING-D": "Avery walking-d",
    "COSTUMES_AVERY-A": "Avery-a",
    "COSTUMES_AVERY-B": "Avery-b",
    "COSTUMES_BALL-A": "Ball-a",
    "COSTUMES_BALL-B": "Ball-b",
    "COSTUMES_BALL-C": "Ball-c",
    "COSTUMES_BALL-D": "Ball-d",
    "COSTUMES_BALL-E": "Ball-e",
    "COSTUMES_BALLERINA-A": "Ballerina-a",
    "COSTUMES_BALLERINA-B": "Ballerina-b",
    "COSTUMES_BALLERINA-C": "Ballerina-c",
    "COSTUMES_BALLERINA-D": "Ballerina-d",
    "COSTUMES_BALLOON1-A": "Balloon1-a",
    "COSTUMES_BALLOON1-B": "Balloon1-b",
    "COSTUMES_BALLOON1-C": "Balloon1-c",
    "COSTUMES_BANANAS": "Bananas",
    "COSTUMES_BASEBALL": "Baseball",
    "COSTUMES_BASKETBALL": "Basketball",
    "COSTUMES_BAT-A": "Bat-a",
    "COSTUMES_BAT-B": "Bat-b",
    "COSTUMES_BAT-C": "Bat-c",
    "COSTUMES_BAT-D": "Bat-d",
    "COSTUMES_BATTER-A": "Batter-a",
    "COSTUMES_BATTER-B": "Batter-b",
    "COSTUMES_BATTER-C": "Batter-c",
    "COSTUMES_BATTER-D": "Batter-d",
    "COSTUMES_BEACHBALL": "Beachball",
    "COSTUMES_BEAR-A": "Bear-a",
    "COSTUMES_BEAR-B": "Bear-b",
    "COSTUMES_BEAR-WALK-A": "Bear-walk-a",
    "COSTUMES_BEAR-WALK-B": "Bear-walk-b",
    "COSTUMES_BEAR-WALK-C": "Bear-walk-c",
    "COSTUMES_BEAR-WALK-D": "Bear-walk-d",
    "COSTUMES_BEAR-WALK-E": "Bear-walk-e",
    "COSTUMES_BEAR-WALK-F": "Bear-walk-f",
    "COSTUMES_BEAR-WALK-G": "Bear-walk-g",
    "COSTUMES_BEAR-WALK-H": "Bear-walk-h",
    "COSTUMES_BEETLE": "Beetle",
    "COSTUMES_BELL1": "Bell1",
    "COSTUMES_BEN-A": "Ben-a",
    "COSTUMES_BEN-B": "Ben-b",
    "COSTUMES_BEN-C": "Ben-c",
    "COSTUMES_BEN-D": "Ben-d",
    "COSTUMES_BOWL-A": "Bowl-a",
    "COSTUMES_BOWTIE": "Bowtie",
    "COSTUMES_BREAD": "Bread",
    "COSTUMES_BROOM": "Broom",
    "COSTUMES_BUILDING-A": "Building-a",
    "COSTUMES_BUILDING-B": "Building-b",
    "COSTUMES_BUILDING-C": "Building-c",
    "COSTUMES_BUILDING-D": "Building-d",
    "COSTUMES_BUILDING-E": "Building-e",
    "COSTUMES_BUILDING-F": "Building-f",
    "COSTUMES_BUILDING-G": "Building-g",
    "COSTUMES_BUILDING-H": "Building-h",
    "COSTUMES_BUILDING-I": "Building-i",
    "COSTUMES_BUILDING-J": "Building-j",
    "COSTUMES_BUTTERFLY1-A": "Butterfly1-a",
    "COSTUMES_BUTTERFLY1-B": "Butterfly1-b",
    "COSTUMES_BUTTERFLY1-C": "Butterfly1-c",
    "COSTUMES_BUTTERFLY2-A": "Butterfly2-a",
    "COSTUMES_BUTTERFLY2-B": "Butterfly2-b",
    "COSTUMES_BUTTON1": "Button1",
    "COSTUMES_BUTTON2-A": "Button2-a",
    "COSTUMES_BUTTON2-B": "Button2-b",
    "COSTUMES_BUTTON3-A": "Button3-a",
    "COSTUMES_BUTTON3-B": "Button3-b",
    "COSTUMES_BUTTON4-A": "Button4-a",
    "COSTUMES_BUTTON4-B": "Button4-b",
    "COSTUMES_BUTTON5-A": "Button5-a",
    "COSTUMES_BUTTON5-B": "Button5-b",
    "COSTUMES_CAKE-A": "Cake-a",
    "COSTUMES_CAKE-B": "Cake-b",
    "COSTUMES_CALVRETT_JUMPING": "Calvrett Jumping",
    "COSTUMES_CALVRETT_THINKING": "Calvrett Thinking",
    "COSTUMES_CASEY-A": "Casey-a",
    "COSTUMES_CASEY-B": "Casey-b",
    "COSTUMES_CASEY-C": "Casey-c",
    "COSTUMES_CASEY-D": "casey-d",
    "COSTUMES_CASSY-A": "Cassy-a",
    "COSTUMES_CASSY-B": "Cassy-b",
    "COSTUMES_CASSY-C": "Cassy-c",
    "COSTUMES_CASSY-D": "Cassy-d",
    "COSTUMES_CAT_2": "Cat 2",
    "COSTUMES_CATCHER-A": "Catcher-a",
    "COSTUMES_CATCHER-B": "Catcher-b",
    "COSTUMES_CATCHER-C": "Catcher-c",
    "COSTUMES_CATCHER-D": "Catcher-d",
    "COSTUMES_CENTAUR-A": "Centaur-a",
    "COSTUMES_CENTAUR-B": "Centaur-b",
    "COSTUMES_CENTAUR-C": "centaur-c",
    "COSTUMES_CENTAUR-D": "centaur-d",
    "COSTUMES_CHAMP99-A": "Champ99-a",
    "COSTUMES_CHAMP99-B": "Champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "Cheesy Puffs",
    "COSTUMES_CHICK-A": "chick-a",
    "COSTUMES_CHICK-B": "chick-b",
    "COSTUMES_CHICK-C": "chick-c",
    "COSTUMES_CITY_BUS-A": "City Bus-a",
    "COSTUMES_CITY_BUS-B": "City Bus-b",
    "COSTUMES_CLOUD": "Cloud",
    "COSTUMES_CLOUD-A": "Cloud-a",
    "COSTUMES_CLOUD-B": "Cloud-b",
    "COSTUMES_CLOUD-C": "Cloud-c",
    "COSTUMES_CLOUD-D": "Cloud-d",
    "COSTUMES_CONVERTIBLE": "Convertible",
    "COSTUMES_CONVERTIBLE_3": "Convertible 2",
    "COSTUMES_CRAB-A": "Crab-a",
    "COSTUMES_CRAB-B": "Crab-b",
    "COSTUMES_CRYSTAL-A": "Crystal-a",
    "COSTUMES_CRYSTAL-B": "Crystal-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "Dee-a",
    "COSTUMES_DEE-B": "Dee-b",
    "COSTUMES_DEE-C": "Dee-c",
    "COSTUMES_DEE-D": "Dee-d",
    "COSTUMES_DEE-E": "Dee-e",
    "COSTUMES_DEVIN-A": "Devin-a",
    "COSTUMES_DEVIN-B": "Devin-b",
    "COSTUMES_DEVIN-C": "Devin-c",
    "COSTUMES_DEVIN-D": "Devin-d",
    "COSTUMES_DINOSAUR1-A": "Dinosaur1-a",
    "COSTUMES_DINOSAUR1-B": "Dinosaur1-b",
    "COSTUMES_DINOSAUR1-C": "Dinosaur1-c",
    "COSTUMES_DINOSAUR1-D": "Dinosaur1-d",
    "COSTUMES_DINOSAUR2-A": "Dinosaur2-a",
    "COSTUMES_DINOSAUR2-B": "Dinosaur2-b",
    "COSTUMES_DINOSAUR2-C": "Dinosaur2-c",
    "COSTUMES_DINOSAUR2-D": "Dinosaur2-d",
    "COSTUMES_DINOSAUR3-A": "Dinosaur3-a",
    "COSTUMES_DINOSAUR3-B": "Dinosaur3-b",
    "COSTUMES_DINOSAUR3-C": "Dinosaur3-c",
    "COSTUMES_DINOSAUR3-D": "Dinosaur3-d",
    "COSTUMES_DINOSAUR3-E": "Dinosaur3-e",
    "COSTUMES_DINOSAUR4-A": "Dinosaur4-a",
    "COSTUMES_DINOSAUR4-B": "Dinosaur4-b",
    "COSTUMES_DINOSAUR4-C": "Dinosaur4-c",
    "COSTUMES_DINOSAUR4-D": "Dinosaur4-d",
    "COSTUMES_DIVER1": "Diver1",
    "COSTUMES_DIVER2": "Diver2",
    "COSTUMES_DM_FREEZE": "dm freeze",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "dm stance",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": "Dog1-a",
    "COSTUMES_DOG1-B": "Dog1-b",
    "COSTUMES_DOG2-A": "Dog2-a",
    "COSTUMES_DOG2-B": "Dog2-b",
    "COSTUMES_DOG2-C": "Dog2-c",
    "COSTUMES_DONUT": "Donut",
    "COSTUMES_DORIAN-A": "Dorian-a",
    "COSTUMES_DORIAN-B": "Dorian-b",
    "COSTUMES_DORIAN-C": "Dorian-c",
    "COSTUMES_DORIAN-D": "Dorian-d",
    "COSTUMES_DOT-A": "Dot-a",
    "COSTUMES_DOT-B": "Dot-b",
    "COSTUMES_DOT-C": "Dot-c",
    "COSTUMES_DOT-D": "Dot-d",
    "COSTUMES_DOVE-A": "Dove-a",
    "COSTUMES_DOVE-B": "Dove-b",
    "COSTUMES_DRAGON-A": "Dragon-a",
    "COSTUMES_DRAGON-B": "Dragon-b",
    "COSTUMES_DRAGON-C": "Dragon-c",
    "COSTUMES_DRAGON1-A": "Dragon1-a",
    "COSTUMES_DRAGON1-B": "Dragon1-b",
    "COSTUMES_DRAGONFLY-A": "Dragonfly-a",
    "COSTUMES_DRAGONFLY-B": "Dragonfly-b",
    "COSTUMES_DRESS-A": "Dress-a",
    "COSTUMES_DRESS-B": "Dress-b",
    "COSTUMES_DRESS-C": "Dress-c",
    "COSTUMES_DRUM-A": "Drum-a",
    "COSTUMES_DRUM-B": "Drum-b",
    "COSTUMES_DRUM-CYMBAL-A": "Drum-cymbal-a",
    "COSTUMES_DRUM-CYMBAL-B": "Drum-cymbal-b",
    "COSTUMES_DRUM-HIGHHAT-A": "Drum-highhat-a",
    "COSTUMES_DRUM-HIGHHAT-B": "Drum-highhat-b",
    "COSTUMES_DRUM-KIT": "Drum-kit",
    "COSTUMES_DRUM-KIT-B": "Drum-kit-b",
    "COSTUMES_DRUM-SNARE-A": "Drum-snare-a",
    "COSTUMES_DRUM-SNARE-B": "Drum-snare-b",
    "COSTUMES_DRUMS_CONGA-A": "Drums Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Drums Conga-b",
    "COSTUMES_DUCK": "Duck",
    "COSTUMES_EARTH": "Earth",
    "COSTUMES_EASEL-A": "Easel-a",
    "COSTUMES_EASEL-B": "Easel-b",
    "COSTUMES_EASEL-C": "Easel-c",
    "COSTUMES_EGG-A": "Egg-a",
    "COSTUMES_EGG-B": "Egg-b",
    "COSTUMES_EGG-C": "Egg-c",
    "COSTUMES_EGG-D": "Egg-d",
    "COSTUMES_EGG-E": "Egg-e",
    "COSTUMES_EGG-F": "Egg-f",
    "COSTUMES_ELEPHANT-A": "Elephant-a",
    "COSTUMES_ELEPHANT-B": "Elephant-b",
    "COSTUMES_ELF-A": "Elf-a",
    "COSTUMES_ELF-B": "Elf-b",
    "COSTUMES_ELF-C": "Elf-c",
    "COSTUMES_ELF-D": "Elf-d",
    "COSTUMES_ELF-E": "Elf-e",
    "COSTUMES_FAIRY-A": "Fairy-a",
    "COSTUMES_FAIRY-B": "Fairy-b",
    "COSTUMES_FAIRY-C": "Fairy-c",
    "COSTUMES_FAIRY-D": "Fairy-d",
    "COSTUMES_FAIRY-E": "Fairy-e",
    "COSTUMES_FISH-A": "Fish-a",
    "COSTUMES_FISH-B": "Fish-b",
    "COSTUMES_FISH-C": "Fish-c",
    "COSTUMES_FISH-D": "Fish-d",
    "COSTUMES_FISHBOWL-A": "Fishbowl-a",
    "COSTUMES_FISHBOWL-B": "Fishbowl-b",
    "COSTUMES_FOOD_TRUCK-A": "Food Truck-a",
    "COSTUMES_FOOD_TRUCK-B": "Food Truck-b",
    "COSTUMES_FOOD_TRUCK-C": "Food Truck-c",
    "COSTUMES_FOOTBALL_RUNNING": "Football Running",
    "COSTUMES_FOOTBALL_STANDING": "Football Standing",
    "COSTUMES_FORTUNE_COOKIE": "Fortune Cookie",
    "COSTUMES_FOX-A": "Fox-a",
    "COSTUMES_FOX-B": "Fox-b",
    "COSTUMES_FOX-C": "Fox-c",
    "COSTUMES_FRANK-A": "Frank-a",
    "COSTUMES_FRANK-B": "Frank-b",
    "COSTUMES_FRANK-C": "Frank-c",
    "COSTUMES_FRANK-D": "Frank-d",
    "COSTUMES_FROG": "Frog",
    "COSTUMES_FROG_2-A": "Frog 2-a",
    "COSTUMES_FROG_2-B": "Frog 2-b",
    "COSTUMES_FROG_2-C": "Frog 2-c",
    "COSTUMES_FRUIT_PLATTER": "Fruit Platter",
    "COSTUMES_FRUITSALAD": "Fruitsalad",
    "COSTUMES_GHOST-A": "Ghost-a",
    "COSTUMES_GHOST-B": "Ghost-b",
    "COSTUMES_GHOST-C": "Ghost-c",
    "COSTUMES_GHOST-D": "Ghost-d",
    "COSTUMES_GIFT-A": "Gift-a",
    "COSTUMES_GIFT-B": "Gift-b",
    "COSTUMES_GIRAFFE-A": "Giraffe-a",
    "COSTUMES_GIRAFFE-B": "Giraffe-b",
    "COSTUMES_GIRAFFE-C": "Giraffe-c",
    "COSTUMES_GLASS_WATER-A": "Glass Water-a",
    "COSTUMES_GLASS_WATER-B": "Glass water-b",
    "COSTUMES_GLASSES-A": "Glasses-a",
    "COSTUMES_GLASSES-B": "Glasses-b",
    "COSTUMES_GLASSES-C": "Glasses-c",
    "COSTUMES_GLASSES-E": "Glasses-e",
    "COSTUMES_GOALIE-A": "Goalie-a",
    "COSTUMES_GOALIE-B": "Goalie-b",
    "COSTUMES_GOALIE-C": "goalie-c",
    "COSTUMES_GOALIE-D": "Goalie-d",
    "COSTUMES_GOALIE-E": "Goalie-e",
    "COSTUMES_GOBLIN-A": "Goblin-a",
    "COSTUMES_GOBLIN-B": "Goblin-b",
    "COSTUMES_GOBLIN-C": "Goblin-c",
    "COSTUMES_GOBLIN-D": "Goblin-d",
    "COSTUMES_GRASSHOPPER-A": "Grasshopper-a",
    "COSTUMES_GRASSHOPPER-B": "Grasshopper-b",
    "COSTUMES_GRASSHOPPER-C": "Grasshopper-c",
    "COSTUMES_GRASSHOPPER-D": "Grasshopper-d",
    "COSTUMES_GRASSHOPPER-E": "Grasshopper-e",
    "COSTUMES_GRASSHOPPER-F": "Grasshopper-f",
    "COSTUMES_GREEN_FLAG": "Green Flag",
    "COSTUMES_GRIFFIN-A": "Griffin-a",
    "COSTUMES_GRIFFIN-B": "Griffin-b",
    "COSTUMES_GRIFFIN-C": "Griffin-c",
    "COSTUMES_GRIFFIN-D": "Griffin-d",
    "COSTUMES_GUITAR-A": "guitar-a",
    "COSTUMES_GUITAR-B": "guitar-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "Guitar-electric1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "Guitar-electric1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "Guitar-electric2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "Guitar-electric2-b",
    "COSTUMES_HANNAH-A": "Hannah-a",
    "COSTUMES_HANNAH-B": "Hannah-b",
    "COSTUMES_HANNAH-C": "Hannah-c",
    "COSTUMES_HARE-A": "Hare-a",
    "COSTUMES_HARE-B": "Hare-b",
    "COSTUMES_HARE-C": "Hare-c",
    "COSTUMES_HARPER-A": "Harper-a",
    "COSTUMES_HARPER-B": "Harper-b",
    "COSTUMES_HARPER-C": "Harper-c",
    "COSTUMES_HAT-A": "Hat-a",
    "COSTUMES_HAT-B": "Hat-b",
    "COSTUMES_HAT-C": "Hat-c",
    "COSTUMES_HAT-D": "Hat-d",
    "COSTUMES_HATCHLING-A": "Hatchling-a",
    "COSTUMES_HATCHLING-B": "Hatchling-b",
    "COSTUMES_HATCHLING-C": "Hatchling-c",
    "COSTUMES_HEART_CODE": "Heart Code",
    "COSTUMES_HEART_FACE": "Heart Face",
    "COSTUMES_HEART_LOVE": "Heart Love",
    "COSTUMES_HEART_PURPLE": "Heart Purple",
    "COSTUMES_HEART_RED": "Heart Red",
    "COSTUMES_HEART_SMILE": "Heart Smile",
    "COSTUMES_HEART_SWEET": "Heart Sweet",
    "COSTUMES_HEDGEHOG-A": "Hedgehog-a",
    "COSTUMES_HEDGEHOG-B": "Hedgehog-b",
    "COSTUMES_HEDGEHOG-C": "Hedgehog-c",
    "COSTUMES_HEDGEHOG-D": "Hedgehog-d",
    "COSTUMES_HEDGEHOG-E": "Hedgehog-e",
    "COSTUMES_HEN-A": "Hen-a",
    "COSTUMES_HEN-B": "Hen-b",
    "COSTUMES_HEN-C": "Hen-c",
    "COSTUMES_HEN-D": "Hen-d",
    "COSTUMES_HIPPO1-A": "Hippo1-a",
    "COSTUMES_HIPPO1-B": "Hippo1-b",
    "COSTUMES_HOME_BUTTON": "Home Button",
    "COSTUMES_HORSE-A": "Horse-a",
    "COSTUMES_HORSE-B": "Horse-b",
    "COSTUMES_JAIME_WALKING-A": "Jaime Walking-a",
    "COSTUMES_JAIME_WALKING-B": "Jaime Walking-b",
    "COSTUMES_JAIME_WALKING-C": "Jaime Walking-c",
    "COSTUMES_JAIME_WALKING-D": "Jaime Walking-d",
    "COSTUMES_JAIME_WALKING-E": "Jaime Walking-e",
    "COSTUMES_JAIME-A": "Jaime-a",
    "COSTUMES_JAIME-B": "Jaime-b",
    "COSTUMES_JAMAL-A": "Jamal-a",
    "COSTUMES_JAMAL-B": "Jamal-b",
    "COSTUMES_JAMAL-C": "Jamal-c",
    "COSTUMES_JAMAL-D": "Jamal-d",
    "COSTUMES_JAR-A": "Jar-a",
    "COSTUMES_JAR-B": "Jar-b",
    "COSTUMES_JELLYFISH-A": "Jellyfish-a",
    "COSTUMES_JELLYFISH-B": "Jellyfish-b",
    "COSTUMES_JELLYFISH-C": "Jellyfish-c",
    "COSTUMES_JELLYFISH-D": "Jellyfish-d",
    "COSTUMES_JO_POP_DOWN": "Jo Pop Down",
    "COSTUMES_JO_POP_FRONT": "Jo Pop Front",
    "COSTUMES_JO_POP_L_ARM": "Jo Pop L Arm",
    "COSTUMES_JO_POP_LEFT": "Jo Pop Left",
    "COSTUMES_JO_POP_R_ARM": "Jo Pop R Arm",
    "COSTUMES_JO_POP_RIGHT": "Jo Pop Right",
    "COSTUMES_JO_POP_STAND": "Jo Pop Stand",
    "COSTUMES_JO_STANCE": "Jo Stance",
    "COSTUMES_JO_TOP_L_CROSS": "Jo Top L Cross",
    "COSTUMES_JO_TOP_L_LEG": "Jo Top L Leg",
    "COSTUMES_JO_TOP_R_CROSS": "Jo Top R Cross",
    "COSTUMES_JO_TOP_R_LEG": "Jo Top R Leg",
    "COSTUMES_JO_TOP_STAND": "Jo Top Stand",
    "COSTUMES_JORDYN-A": "Jordyn-a",
    "COSTUMES_JORDYN-B": "Jordyn-b",
    "COSTUMES_JORDYN-C": "Jordyn-c",
    "COSTUMES_JORDYN-D": "Jordyn-d",
    "COSTUMES_KAI-A": "Kai-a",
    "COSTUMES_KAI-B": "Kai-b",
    "COSTUMES_KEY": "Key",
    "COSTUMES_KEYBOARD-A": "Keyboard-a",
    "COSTUMES_KEYBOARD-B": "Keyboard-b",
    "COSTUMES_KIRAN-A": "Kiran-a",
    "COSTUMES_KIRAN-B": "Kiran-b",
    "COSTUMES_KIRAN-C": "Kiran-c",
    "COSTUMES_KIRAN-D": "Kiran-d",
    "COSTUMES_KIRAN-E": "Kiran-e",
    "COSTUMES_KIRAN-F": "Kiran-f",
    "COSTUMES_KNIGHT": "Knight",
    "COSTUMES_LADYBUG2": "Ladybug1",
    "COSTUMES_LADYBUG2-A": "Ladybug2-a",
    "COSTUMES_LADYBUG2-B": "Ladybug2-b",
    "COSTUMES_LAPTOP": "Laptop",
    "COSTUMES_LB_POP_DOWN": "Lb Pop Down",
    "COSTUMES_LB_POP_FRONT": "Lb Pop Front",
    "COSTUMES_LB_POP_L_ARM": "Lb Pop L Arm",
    "COSTUMES_LB_POP_LEFT": "Lb Pop Left",
    "COSTUMES_LB_POP_R_ARM": "Lb Pop R Arm",
    "COSTUMES_LB_POP_RIGHT": "Lb Pop Right",
    "COSTUMES_LB_POP_STAND": "Lb Pop Stand",
    "COSTUMES_LB_STANCE": "lb Stance",
    "COSTUMES_LB_TOP_L_CROSS": "Lb Top L Cross",
    "COSTUMES_LB_TOP_L_LEG": "Lb Top L Leg",
    "COSTUMES_LB_TOP_R_CROSS": "Lb Top R Cross",
    "COSTUMES_LB_TOP_R_LEG": "Lb Top R Leg",
    "COSTUMES_LB_TOP_STAND": "Lb Top Stand",
    "COSTUMES_LIGHTNING": "Lightning",
    "COSTUMES_LINE": "Line",
    "COSTUMES_LION-A": "Lion-a",
    "COSTUMES_LION-B": "Lion-b",
    "COSTUMES_LION-C": "Lion-c",
    "COSTUMES_LLAMA": "Llama",
    "COSTUMES_LLAMA-B": "Llama-b",
    "COSTUMES_LLAMA-C": "Llama-c",
    "COSTUMES_MAGICWAND": "Magicwand",
    "COSTUMES_MAX-A": "Max-a",
    "COSTUMES_MAX-B": "Max-b",
    "COSTUMES_MAX-C": "Max-c",
    "COSTUMES_MAX-D": "Max-d",
    "COSTUMES_MERMAID-A": "Mermaid-a",
    "COSTUMES_MERMAID-B": "Mermaid-b",
    "COSTUMES_MERMAID-C": "Mermaid-c",
    "COSTUMES_MERMAID-D": "Mermaid-d",
    "COSTUMES_MICROPHONE-A": "Microphone-a",
    "COSTUMES_MICROPHONE-B": "Microphone-b",
    "COSTUMES_MILK-A": "Milk-a",
    "COSTUMES_MILK-B": "Milk-b",
    "COSTUMES_MILK-C": "Milk-c",
    "COSTUMES_MILK-D": "Milk-d",
    "COSTUMES_MILK-E": "Milk-e",
    "COSTUMES_MONET-A": "Monet-a",
    "COSTUMES_MONET-B": "Monet-b",
    "COSTUMES_MONET-C": "Monet-c",
    "COSTUMES_MONET-D": "Monet-d",
    "COSTUMES_MONET-E": "Monet-e",
    "COSTUMES_MONKEY-A": "Monkey-a",
    "COSTUMES_MONKEY-B": "Monkey-b",
    "COSTUMES_MONKEY-C": "Monkey-c",
    "COSTUMES_MOTORCYCLE-A": "Motorcycle-a",
    "COSTUMES_MOTORCYCLE-B": "Motorcycle-b",
    "COSTUMES_MOTORCYCLE-C": "Motorcycle-c",
    "COSTUMES_MOTORCYCLE-D": "Motorcycle-d",
    "COSTUMES_MOUSE1-A": "Mouse1-a",
    "COSTUMES_MOUSE1-B": "Mouse1-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "Neigh Pony",
    "COSTUMES_OCTOPUS-A": "Octopus-a",
    "COSTUMES_OCTOPUS-B": "Octopus-b",
    "COSTUMES_OCTOPUS-C": "Octopus-c",
    "COSTUMES_OCTOPUS-D": "Octopus-d",
    "COSTUMES_OCTOPUS-E": "Octopus-e",
    "COSTUMES_ORANGE": "Orange",
    "COSTUMES_ORANGE2-A": "Orange2-a",
    "COSTUMES_ORANGE2-B": "Orange2-b",
    "COSTUMES_OUTFIELDER-A": "Outfielder-a",
    "COSTUMES_OUTFIELDER-B": "Outfielder-b",
    "COSTUMES_OUTFIELDER-C": "Outfielder-c",
    "COSTUMES_OUTFIELDER-D": "Outfielder-d",
    "COSTUMES_OWL-A": "Owl-a",
    "COSTUMES_OWL-B": "Owl-b",
    "COSTUMES_OWL-C": "Owl-c",
    "COSTUMES_PADDLE": "Paddle",
    "COSTUMES_PANTHER-A": "Panther-a",
    "COSTUMES_PANTHER-B": "Panther-b",
    "COSTUMES_PANTHER-C": "Panther-c",
    "COSTUMES_PANTS-A": "Pants-a",
    "COSTUMES_PANTS-B": "Pants-b",
    "COSTUMES_PARROT-A": "Parrot-a",
    "COSTUMES_PARROT-B": "Parrot-b",
    "COSTUMES_PARTY_HAT-A": "Party Hat-a",
    "COSTUMES_PARTY_HAT-B": "Party Hat-b",
    "COSTUMES_PARTY_HAT-E": "Party Hat-c",
    "COSTUMES_PENCIL-A": "Pencil-a",
    "COSTUMES_PENCIL-B": "Pencil-b",
    "COSTUMES_PENGUIN-A": "Penguin-a",
    "COSTUMES_PENGUIN-B": "Penguin-b",
    "COSTUMES_PENGUIN-C": "Penguin-c",
    "COSTUMES_PENGUIN2-A": "Penguin2-a",
    "COSTUMES_PENGUIN2-B": "Penguin2-b",
    "COSTUMES_PENGUIN2-C": "Penguin2-c",
    "COSTUMES_PENGUIN2-D": "Penguin2-d",
    "COSTUMES_PITCHER-A": "Pitcher-a",
    "COSTUMES_PITCHER-B": "Pitcher-b",
    "COSTUMES_PITCHER-C": "Pitcher-c",
    "COSTUMES_PITCHER-D": "Pitcher-d",
    "COSTUMES_PLANET2": "Planet2",
    "COSTUMES_POLAR_BEAR-A": "Polar Bear-a",
    "COSTUMES_POLAR_BEAR-B": "Polar Bear-b",
    "COSTUMES_POLAR_BEAR-C": "Polar Bear-c",
    "COSTUMES_POTION-A": "Potion-a",
    "COSTUMES_POTION-B": "Potion-b",
    "COSTUMES_POTION-C": "Potion-c",
    "COSTUMES_PRINCE": "Prince",
    "COSTUMES_PRINCESS-A": "Princess-a",
    "COSTUMES_PRINCESS-B": "Princess-b",
    "COSTUMES_PRINCESS-C": "Princess-c",
    "COSTUMES_PRINCESS-D": "Princess-d",
    "COSTUMES_PRINCESS-E": "Princess-e",
    "COSTUMES_PUFFERFISH-A": "Pufferfish-a",
    "COSTUMES_PUFFERFISH-B": "Pufferfish-b",
    "COSTUMES_PUFFERFISH-C": "Pufferfish-c",
    "COSTUMES_PUFFERFISH-D": "Pufferfish-d",
    "COSTUMES_PUPPY_BACK": "Puppy Back",
    "COSTUMES_PUPPY_RIGHT": "Puppy Right",
    "COSTUMES_PUPPY_SIDE": "Puppy Side",
    "COSTUMES_PUPPY_SIT": "Puppy Sit",
    "COSTUMES_RABBIT-A": "Rabbit-a",
    "COSTUMES_RABBIT-B": "Rabbit-b",
    "COSTUMES_RABBIT-C": "Rabbit-c",
    "COSTUMES_RABBIT-D": "Rabbit-d",
    "COSTUMES_RABBIT-E": "Rabbit-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": "Rainbow",
    "COSTUMES_REFEREE-A": "referee-a",
    "COSTUMES_REFEREE-B": "referee-b",
    "COSTUMES_REFEREE-C": "referee-c",
    "COSTUMES_REFEREE-D": "referee-d",
    "COSTUMES_REINDEER": "reindeer",
    "COSTUMES_RETRO_ROBOT_A": "Retro Robot a",
    "COSTUMES_RETRO_ROBOT_B": "Retro Robot b",
    "COSTUMES_RETRO_ROBOT_C": "Retro Robot c",
    "COSTUMES_RIPLEY-A": "Ripley-a",
    "COSTUMES_RIPLEY-B": "Ripley-b",
    "COSTUMES_RIPLEY-C": "Ripley-c",
    "COSTUMES_RIPLEY-D": "Ripley-d",
    "COSTUMES_RIPLEY-E": "Ripley-e",
    "COSTUMES_RIPLEY-F": "Ripley-f",
    "COSTUMES_ROBOT-A": "Robot-a",
    "COSTUMES_ROBOT-B": "Robot-b",
    "COSTUMES_ROBOT-C": "Robot-c",
    "COSTUMES_ROBOT-D": "Robot-d",
    "COSTUMES_ROCKETSHIP-A": "Rocketship-a",
    "COSTUMES_ROCKETSHIP-B": "Rocketship-b",
    "COSTUMES_ROCKETSHIP-C": "Rocketship-c",
    "COSTUMES_ROCKETSHIP-D": "Rocketship-d",
    "COSTUMES_ROCKETSHIP-E": "Rocketship-e",
    "COSTUMES_ROCKS": "Rocks",
    "COSTUMES_ROOSTER-A": "Rooster-a",
    "COSTUMES_ROOSTER-B": "Rooster-b",
    "COSTUMES_ROOSTER-C": "Rooster-c",
    "COSTUMES_RUBY-A": "Ruby-a",
    "COSTUMES_RUBY-B": "Ruby-b",
    "COSTUMES_SAILBOAT": "Sailboat",
    "COSTUMES_SAM": "Sam",
    "COSTUMES_SAXOPHONE-A": "Saxophone-a",
    "COSTUMES_SAXOPHONE-B": "Saxophone-b",
    "COSTUMES_SCARF-A": "Scarf-a",
    "COSTUMES_SCARF-B": "Scarf-b",
    "COSTUMES_SCARF-C": "Scarf-c",
    "COSTUMES_SHARK-A": "Shark-a",
    "COSTUMES_SHARK-B": "Shark-b",
    "COSTUMES_SHARK2-A": "Shark2-a",
    "COSTUMES_SHARK2-B": "Shark2-b",
    "COSTUMES_SHARK2-C": "Shark2-c",
    "COSTUMES_SHIRT-A": "Shirt-a",
    "COSTUMES_SHOES-A": "Shoes-a",
    "COSTUMES_SHOES-B": "Shoes-b",
    "COSTUMES_SHOES-C": "Shoes-c",
    "COSTUMES_SHOES-D": "Shoes-d",
    "COSTUMES_SHORTS-A": "Shorts-a",
    "COSTUMES_SHORTS-B": "Shorts-b",
    "COSTUMES_SHORTS-C": "Shorts-c",
    "COSTUMES_SINGER1": "Singer1",
    "COSTUMES_SKELETON-A": "Skeleton-a",
    "COSTUMES_SKELETON-B": "Skeleton-b",
    "COSTUMES_SKELETON-D": "Skeleton-d",
    "COSTUMES_SKELETON-E": "Skeleton-e",
    "COSTUMES_SNAKE-A": "Snake-a",
    "COSTUMES_SNAKE-B": "Snake-b",
    "COSTUMES_SNAKE-C": "Snake-c",
    "COSTUMES_SNOWFLAKE": "Snowflake",
    "COSTUMES_SNOWMAN": "Snowman",
    "COSTUMES_SOCCER_BALL": "Soccer Ball",
    "COSTUMES_SPEAKER": "Speaker",
    "COSTUMES_SQUIRREL": "Squirrel",
    "COSTUMES_STAR": "Star",
    "COSTUMES_STARFISH-A": "Starfish-a",
    "COSTUMES_STARFISH-B_": "Starfish-b ",
    "COSTUMES_STOP": "Stop",
    "COSTUMES_STRAWBERRY-A": "Strawberry-a",
    "COSTUMES_STRAWBERRY-B": "Strawberry-b",
    "COSTUMES_STRAWBERRY-C": "Strawberry-c",
    "COSTUMES_STRAWBERRY-D": "Strawberry-d",
    "COSTUMES_STRAWBERRY-E": "Strawberry-e",
    "COSTUMES_SUN": "Sun",
    "COSTUMES_SUNGLASSES-A": "Sunglasses-a",
    "COSTUMES_SUNGLASSES-B": "Sunglasses-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-wizard",
    "COSTUMES_TAKEOUT-A": "Takeout-a",
    "COSTUMES_TAKEOUT-B": "Takeout-b",
    "COSTUMES_TAKEOUT-C": "Takeout-c",
    "COSTUMES_TAKEOUT-D": "Takeout-d",
    "COSTUMES_TAKEOUT-E": "Takeout-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 Pop Down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 Pop Front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 Pop L Arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 Pop Left",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 Pop R Arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 Pop Right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 Pop Stand",
    "COSTUMES_TEN80_STANCE": "Ten80 stance",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 top freeze",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 top L step",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 top R cross",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 top R step",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 top stand",
    "COSTUMES_TENNISBALL": "Tennisball",
    "COSTUMES_TOUCAN-A": "Toucan-a",
    "COSTUMES_TOUCAN-B": "Toucan-b",
    "COSTUMES_TOUCAN-C": "Toucan-c",
    "COSTUMES_TRAMPOLINE": "Trampoline",
    "COSTUMES_TREE1": "Tree1",
    "COSTUMES_TREES-A": "Trees-a",
    "COSTUMES_TREES-B": "Trees-b",
    "COSTUMES_TRUCK-A": "Truck-a",
    "COSTUMES_TRUCK-B": "Truck-b",
    "COSTUMES_TRUCK-C": "Truck-c",
    "COSTUMES_TRUMPET-A": "Trumpet-a",
    "COSTUMES_TRUMPET-B": "Trumpet-b",
    "COSTUMES_UNICORN": "Unicorn",
    "COSTUMES_UNICORN_2": "Unicorn 2",
    "COSTUMES_UNICORN_RUNNING-A": "Unicorn Running-a",
    "COSTUMES_UNICORN_RUNNING-B": "Unicorn Running-b",
    "COSTUMES_UNICORN_RUNNING-C": "Unicorn Running-c",
    "COSTUMES_UNICORN_RUNNING-D": "Unicorn Running-d",
    "COSTUMES_UNICORN_RUNNING-E": "Unicorn Running-e",
    "COSTUMES_UNICORN_RUNNING-F": "Unicorn Running-f",
    "COSTUMES_WAND": "Wand",
    "COSTUMES_WANDA": "Wanda",
    "COSTUMES_WATERMELON-A": "Watermelon-a",
    "COSTUMES_WATERMELON-B": "Watermelon-b",
    "COSTUMES_WATERMELON-C": "Watermelon-c",
    "COSTUMES_WINTER_HAT": "Winter Hat",
    "COSTUMES_WITCH": "Witch",
    "COSTUMES_WITCH-A": "Witch-a",
    "COSTUMES_WITCH-B": "Witch-b",
    "COSTUMES_WITCH-C": "Witch-c",
    "COSTUMES_WITCH-D": "Witch-d",
    "COSTUMES_WIZARD_HAT": "Wizard Hat",
    "COSTUMES_WIZARD-A": "Wizard-a",
    "COSTUMES_WIZARD-B": "Wizard-b",
    "COSTUMES_WIZARD-C": "Wizard-c",
    "COSTUMES_WIZARD-TOAD-A": "Wizard-toad-a",
    "COSTUMES_WIZARD-TOAD-B": "Wizard-toad-b",
    "COSTUMES_ZEBRA-A": "Zebra-a",
    "COSTUMES_ZEBRA-B": "Zebra-b",
    "COSTUMES_BLOCK-A": "Block-a",
    "COSTUMES_BLOCK-B": "Block-b",
    "COSTUMES_BLOCK-C": "Block-c",
    "COSTUMES_BLOCK-D": "Block-d",
    "COSTUMES_BLOCK-E": "Block-e",
    "COSTUMES_BLOCK-F": "Block-f",
    "COSTUMES_BLOCK-G": "Block-g",
    "COSTUMES_BLOCK-H": "Block-h",
    "COSTUMES_BLOCK-I": "Block-i",
    "COSTUMES_BLOCK-J": "Block-j",
    "COSTUMES_BLOCK-K": "Block-k",
    "COSTUMES_BLOCK-L": "Block-l",
    "COSTUMES_BLOCK-M": "Block-m",
    "COSTUMES_BLOCK-N": "Block-n",
    "COSTUMES_BLOCK-O": "Block-o",
    "COSTUMES_BLOCK-P": "Block-p",
    "COSTUMES_BLOCK-Q": "Block-q",
    "COSTUMES_BLOCK-R": "Block-r",
    "COSTUMES_BLOCK-S": "Block-s",
    "COSTUMES_BLOCK-T": "Block-t",
    "COSTUMES_BLOCK-U": "Block-u",
    "COSTUMES_BLOCK-V": "Block-v",
    "COSTUMES_BLOCK-W": "Block-w",
    "COSTUMES_BLOCK-X": "Block-x",
    "COSTUMES_BLOCK-Y": "Block-y",
    "COSTUMES_BLOCK-Z": "Block-z",
    "COSTUMES_GLOW-0": "Glow-0",
    "COSTUMES_GLOW-1": "Glow-1",
    "COSTUMES_GLOW-2": "Glow-2",
    "COSTUMES_GLOW-3": "Glow-3",
    "COSTUMES_GLOW-4": "Glow-4",
    "COSTUMES_GLOW-5": "Glow-5",
    "COSTUMES_GLOW-6": "Glow-6",
    "COSTUMES_GLOW-7": "Glow-7",
    "COSTUMES_GLOW-8": "Glow-8",
    "COSTUMES_GLOW-9": "Glow-9",
    "COSTUMES_GLOW-A": "Glow-A",
    "COSTUMES_GLOW-B": "Glow-B",
    "COSTUMES_GLOW-C": "Glow-C",
    "COSTUMES_GLOW-D": "Glow-D",
    "COSTUMES_GLOW-E": "Glow-E",
    "COSTUMES_GLOW-F": "Glow-F",
    "COSTUMES_GLOW-G": "Glow-G",
    "COSTUMES_GLOW-H": "Glow-H",
    "COSTUMES_GLOW-I": "Glow-I",
    "COSTUMES_GLOW-J": "Glow-J",
    "COSTUMES_GLOW-K": "Glow-K",
    "COSTUMES_GLOW-L": "Glow-L",
    "COSTUMES_GLOW-M": "Glow-M",
    "COSTUMES_GLOW-N": "Glow-N",
    "COSTUMES_GLOW-O": "Glow-O",
    "COSTUMES_GLOW-P": "Glow-P",
    "COSTUMES_GLOW-Q": "Glow-Q",
    "COSTUMES_GLOW-R": "Glow-R",
    "COSTUMES_GLOW-S": "Glow-S",
    "COSTUMES_GLOW-T": "Glow-T",
    "COSTUMES_GLOW-U": "Glow-U",
    "COSTUMES_GLOW-V": "Glow-V",
    "COSTUMES_GLOW-W": "Glow-W",
    "COSTUMES_GLOW-X": "Glow-X",
    "COSTUMES_GLOW-Y": "Glow-Y",
    "COSTUMES_GLOW-Z": "Glow-Z",
    "COSTUMES_STORY-A-1": "Story-A-1",
    "COSTUMES_STORY-A-2": "Story-A-2",
    "COSTUMES_STORY-A-3": "Story-A-3",
    "COSTUMES_STORY-B-1": "Story-B-1",
    "COSTUMES_STORY-B-2": "Story-B-2",
    "COSTUMES_STORY-B-3": "Story-B-3",
    "COSTUMES_STORY-C-1": "Story-C-1",
    "COSTUMES_STORY-C-2": "Story-C-2",
    "COSTUMES_STORY-C-3": "Story-C-3",
    "COSTUMES_STORY-D-1": "Story-D-1",
    "COSTUMES_STORY-D-2": "Story-D-2",
    "COSTUMES_STORY-D-3": "Story-D-3",
    "COSTUMES_STORY-E-1": "Story-E-1",
    "COSTUMES_STORY-E-2": "Story-E-2",
    "COSTUMES_STORY-E-3": "Story-E-3",
    "COSTUMES_STORY-F-1": "Story-F-1",
    "COSTUMES_STORY-F-2": "Story-F-2",
    "COSTUMES_STORY-F-3": "Story-F-3",
    "COSTUMES_STORY-G-1": "Story-G-1",
    "COSTUMES_STORY-G-2": "Story-G-2",
    "COSTUMES_STORY-G-3": "Story-G-3",
    "COSTUMES_STORY-H-1": "Story-H-1",
    "COSTUMES_STORY-H-2": "Story-H-2",
    "COSTUMES_STORY-H-3": "Story-H-3",
    "COSTUMES_STORY-I-1": "Story-I-1",
    "COSTUMES_STORY-I-2": "Story-I-2",
    "COSTUMES_STORY-I-3": "Story-I-3",
    "COSTUMES_STORY-J-1": "Story-J-1",
    "COSTUMES_STORY-J-2": "Story-J-2",
    "COSTUMES_STORY-J-3": "Story-J-3",
    "COSTUMES_STORY-K-1": "Story-K-1",
    "COSTUMES_STORY-K-2": "Story-K-2",
    "COSTUMES_STORY-K-3": "story-K-3",
    "COSTUMES_STORY-L-1": "Story-L-1",
    "COSTUMES_STORY-L-2": "Story-L-2",
    "COSTUMES_STORY-L-3": "Story-L-3",
    "COSTUMES_STORY-M-1": "Story-M-1",
    "COSTUMES_STORY-M-2": "Story-M-2",
    "COSTUMES_STORY-M-3": "Story-M-3",
    "COSTUMES_STORY-N-1": "Story-N-1",
    "COSTUMES_STORY-N-2": "Story-N-2",
    "COSTUMES_STORY-N-3": "Story-N-3",
    "COSTUMES_STORY-O-1": "Story-O-1",
    "COSTUMES_STORY-O-2": "Story-O-2",
    "COSTUMES_STORY-O-3": "Story-O-3",
    "COSTUMES_STORY-P-1": "Story-P-1",
    "COSTUMES_STORY-P-2": "Story-P-2",
    "COSTUMES_STORY-P-3": "Story-P-3",
    "COSTUMES_STORY-Q-1": "Story-Q-1",
    "COSTUMES_STORY-Q-2": "Story-Q-2",
    "COSTUMES_STORY-Q-3": "Story-Q-3",
    "COSTUMES_STORY-R-1": "Story-R-1",
    "COSTUMES_STORY-R-2": "Story-R-2",
    "COSTUMES_STORY-R-3": "Story-R-3",
    "COSTUMES_STORY-S-1": "Story-S-1",
    "COSTUMES_STORY-S-2": "Story-S-2",
    "COSTUMES_STORY-S-3": "Story-S-3",
    "COSTUMES_STORY-T-1": "Story-T-1",
    "COSTUMES_STORY-T-2": "Story-T-2",
    "COSTUMES_STORY-T-3": "Story-T-3",
    "COSTUMES_STORY-U-1": "Story-U-1",
    "COSTUMES_STORY-U-2": "Story-U-2",
    "COSTUMES_STORY-U-3": "Story-U-3",
    "COSTUMES_STORY-V-1": "Story-V-1",
    "COSTUMES_STORY-V-2": "Story-V-2",
    "COSTUMES_STORY-V-3": "Story-V-3",
    "COSTUMES_STORY-W-1": "Story-W-1",
    "COSTUMES_STORY-W-2": "Story-W-2",
    "COSTUMES_STORY-W-3": "Story-W-3",
    "COSTUMES_STORY-X-1": "Story-X-1",
    "COSTUMES_STORY-X-2": "Story-X-2",
    "COSTUMES_STORY-X-3": "Story-X-3",
    "COSTUMES_STORY-Y-1": "Story-Y-1",
    "COSTUMES_STORY-Y-2": "Story-Y-2",
    "COSTUMES_STORY-Y-3": "Story-Y-3",
    "COSTUMES_STORY-Z-1": "Story-Z-1",
    "COSTUMES_STORY-Z-2": "Story-Z-2",
    "COSTUMES_STORY-Z-3": "Story-Z-3",
    "SOUNDS_A_BASS": "A Bass",
    "SOUNDS_A_ELEC_BASS": "A Elec Bass",
    "SOUNDS_A_ELEC_GUITAR": "A Elec Guitar",
    "SOUNDS_A_ELEC_PIANO": "A Elec Piano",
    "SOUNDS_A_GUITAR": "A Guitar",
    "SOUNDS_A_MINOR_UKULELE": "A Minor Ukulele",
    "SOUNDS_A_PIANO": "A Piano",
    "SOUNDS_A_SAX": "A Sax",
    "SOUNDS_A_TROMBONE": "A Trombone",
    "SOUNDS_A_TRUMPET": "A Trumpet",
    "SOUNDS_AFRO_STRING": "Afro String",
    "SOUNDS_ALERT": "Alert",
    "SOUNDS_ALIEN_CREAK1": "Alien Creak1",
    "SOUNDS_ALIEN_CREAK2": "Alien Creak2",
    "SOUNDS_B_BASS": "B Bass",
    "SOUNDS_B_ELEC_BASS": "B Elec Bass",
    "SOUNDS_B_ELEC_GUITAR": "B Elec Guitar",
    "SOUNDS_B_ELEC_PIANO": "B Elec Piano",
    "SOUNDS_B_GUITAR": "B Guitar",
    "SOUNDS_B_PIANO": "B Piano",
    "SOUNDS_B_SAX": "B Sax",
    "SOUNDS_B_TROMBONE": "B Trombone",
    "SOUNDS_B_TRUMPET": "B Trumpet",
    "SOUNDS_BAA": "Baa",
    "SOUNDS_BARK": "Bark",
    "SOUNDS_BASKETBALL_BOUNCE": "Basketball Bounce",
    "SOUNDS_BASS_BEATBOX": "Bass Beatbox",
    "SOUNDS_BEAT_BOX1": "Beat Box1",
    "SOUNDS_BEAT_BOX2": "Beat Box2",
    "SOUNDS_BELL_CYMBAL": "Bell Cymbal",
    "SOUNDS_BELL_TOLL": "Bell Toll",
    "SOUNDS_BIG_BOING": "Big Boing",
    "SOUNDS_BIRD": "Bird",
    "SOUNDS_BIRTHDAY": "Birthday",
    "SOUNDS_BITE": "Bite",
    "SOUNDS_BOING": "Boing",
    "SOUNDS_BONK": "Bonk",
    "SOUNDS_BOOM_CLOUD": "Boom Cloud",
    "SOUNDS_BOOP_BING_BOP": "Boop Bing Bop",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": "Bowling Strike",
    "SOUNDS_BUBBLES": "Bubbles",
    "SOUNDS_BUZZ_WHIR": "Buzz Whir",
    "SOUNDS_C_BASS": "C Bass",
    "SOUNDS_C_ELEC_BASS": "C Elec Bass",
    "SOUNDS_C_ELEC_GUITAR": "C Elec Guitar",
    "SOUNDS_C_ELEC_PIANO": "C Elec Piano",
    "SOUNDS_C_GUITAR": "C Guitar",
    "SOUNDS_C_MAJOR_UKULELE": "C Major Ukulele",
    "SOUNDS_C_PIANO": "C Piano",
    "SOUNDS_C_SAX": "C Sax",
    "SOUNDS_C_TROMBONE": "C Trombone",
    "SOUNDS_C_TRUMPET": "C Trumpet",
    "SOUNDS_C2_BASS": "C2 Bass",
    "SOUNDS_C2_ELEC_BASS": "C2 Elec Bass",
    "SOUNDS_C2_ELEC_GUITAR": "C2 Elec Guitar",
    "SOUNDS_C2_ELEC_PIANO": "C2 Elec Piano",
    "SOUNDS_C2_GUITAR": "C2 guitar",
    "SOUNDS_C2_PIANO": "C2 Piano",
    "SOUNDS_C2_SAX": "C2 Sax",
    "SOUNDS_C2_TROMBONE": "C2 Trombone",
    "SOUNDS_C2_TRUMPET": "C2 Trumpet",
    "SOUNDS_CAR_HORN": "Car Horn",
    "SOUNDS_CAR_PASSING": "Car Passing",
    "SOUNDS_CAR_VROOM": "Car Vroom",
    "SOUNDS_CAVE": "Cave",
    "SOUNDS_CHATTER": "Chatter",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "Cheer",
    "SOUNDS_CHILL": "Chill",
    "SOUNDS_CHIRP": "Chirp",
    "SOUNDS_CHOMP": "Chomp",
    "SOUNDS_CHORD": "Chord",
    "SOUNDS_CLANG": "Clang",
    "SOUNDS_CLAP_BEATBOX": "Clap Beatbox",
    "SOUNDS_CLAPPING": "Clapping",
    "SOUNDS_CLASSICAL_PIANO": "Classical Piano",
    "SOUNDS_CLOCK_TICKING": "Clock Ticking",
    "SOUNDS_CLOWN_HONK": "Clown Honk",
    "SOUNDS_COIN": "Coin",
    "SOUNDS_COLLECT": "Collect",
    "SOUNDS_COMPUTER_BEEP": "Computer Beep1",
    "SOUNDS_COMPUTER_BEEP2": "Computer Beep2",
    "SOUNDS_CONNECT": "Connect",
    "SOUNDS_COUGH1": "Cough1",
    "SOUNDS_COUGH2": "Cough2",
    "SOUNDS_CRANK": "Crank",
    "SOUNDS_CRASH_BEATBOX": "Crash Beatbox",
    "SOUNDS_CRASH_CYMBAL": "crash cymbal",
    "SOUNDS_CRAZY_LAUGH": "Crazy Laugh",
    "SOUNDS_CRICKET": "Cricket",
    "SOUNDS_CRICKETS": "Crickets",
    "SOUNDS_CROAK": "Croak",
    "SOUNDS_CROWD_GASP": "Crowd Gasp",
    "SOUNDS_CROWD_LAUGH": "Crowd Laugh",
    "SOUNDS_CRUNCH": "Crunch",
    "SOUNDS_CYMBAL": "Cymbal",
    "SOUNDS_CYMBAL_CRASH": "Cymbal Crash",
    "SOUNDS_CYMBAL_ECHO": "Cymbal Echo",
    "SOUNDS_D_BASS": "D Bass",
    "SOUNDS_D_ELEC_BASS": "D Elec Bass",
    "SOUNDS_D_ELEC_GUITAR": "D Elec Guitar",
    "SOUNDS_D_ELEC_PIANO": "D Elec Piano",
    "SOUNDS_D_GUITAR": "D Guitar",
    "SOUNDS_D_PIANO": "D Piano",
    "SOUNDS_D_SAX": "D Sax",
    "SOUNDS_D_TROMBONE": "D Trombone",
    "SOUNDS_D_TRUMPET": "D Trumpet",
    "SOUNDS_DANCE_AROUND": "Dance Around",
    "SOUNDS_DANCE_CELEBRATE": "Dance Celebrate",
    "SOUNDS_DANCE_CELEBRATE2": "Dance Celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": "Dance Chill Out",
    "SOUNDS_DANCE_ENERGETIC": "Dance Energetic",
    "SOUNDS_DANCE_FUNKY": "Dance Funky",
    "SOUNDS_DANCE_HEAD_NOD": "Dance Head Nod",
    "SOUNDS_DANCE_MAGIC": "Dance Magic",
    "SOUNDS_DANCE_SITAR": "Dance Sitar",
    "SOUNDS_DANCE_SLOW_MO": "Dance Slow Mo",
    "SOUNDS_DANCE_SNARE_BEAT": "Dance Snare Beat",
    "SOUNDS_DANCE_SPACE": "Dance Space",
    "SOUNDS_DISCONNECT": "Disconnect",
    "SOUNDS_DOG1": "Dog",
    "SOUNDS_DOG2": "Dog2",
    "SOUNDS_DOOR_CLOSING": "Door Closing",
    "SOUNDS_DOOR_CREAK": "Door Creak",
    "SOUNDS_DOORBELL": "Doorbell",
    "SOUNDS_DRIP_DROP": "Drip Drop",
    "SOUNDS_DRIVE_AROUND": "Drive Around",
    "SOUNDS_DRUM": "Drum",
    "SOUNDS_DRUM_BASS1": "Drum Bass1",
    "SOUNDS_DRUM_BASS2": "Drum Bass2",
    "SOUNDS_DRUM_BASS3": "Drum Bass3",
    "SOUNDS_DRUM_BOING": "Drum Boing",
    "SOUNDS_DRUM_BUZZ": "Drum Buzz",
    "SOUNDS_DRUM_FUNKY": "Drum Funky",
    "SOUNDS_DRUM_JAM": "Drum Jam",
    "SOUNDS_DRUM_MACHINE": "Drum Machine",
    "SOUNDS_DRUM_ROLL": "Drum Roll",
    "SOUNDS_DRUM_SATELLITE": "Drum Satellite",
    "SOUNDS_DRUM_SET1": "Drum Set1",
    "SOUNDS_DRUM_SET2": "Drum Set2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "Duck",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dunnn",
    "SOUNDS_E_BASS": "E Bass",
    "SOUNDS_E_ELEC_BASS": "E Elec Bass",
    "SOUNDS_E_ELEC_GUITAR": "E Elec Guitar",
    "SOUNDS_E_ELEC_PIANO": "E Elec Piano",
    "SOUNDS_E_GUITAR": "E Guitar",
    "SOUNDS_E_PIANO": "E Piano",
    "SOUNDS_E_SAX": "E Sax",
    "SOUNDS_E_TROMBONE": "E Trombone",
    "SOUNDS_E_TRUMPET": "E Trumpet",
    "SOUNDS_EGGS": "Eggs",
    "SOUNDS_ELEC_PIANO_A_MINOR": "Elec Piano A Minor",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "Elec Piano C Major",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "Elec Piano F Major",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "Elec Piano G Major",
    "SOUNDS_ELEC_PIANO_LOOP": "Elec Piano Loop",
    "SOUNDS_EMOTIONAL_PIANO": "Emotional Piano",
    "SOUNDS_ENGINE": "Engine",
    "SOUNDS_F_BASS": "F Bass",
    "SOUNDS_F_ELEC_BASS": "F Elec Bass",
    "SOUNDS_F_ELEC_GUITAR": "F Elec Guitar",
    "SOUNDS_F_ELEC_PIANO": "F Elec Piano",
    "SOUNDS_F_GUITAR": "F Guitar",
    "SOUNDS_F_MAJOR_UKULELE": "F Major Ukulele",
    "SOUNDS_F_PIANO": "F Piano",
    "SOUNDS_F_SAX": "F Sax",
    "SOUNDS_F_TROMBONE": "F Trombone",
    "SOUNDS_F_TRUMPET": "F Trumpet",
    "SOUNDS_FAIRYDUST": "Fairydust",
    "SOUNDS_FINGER_SNAP": "Finger Snap",
    "SOUNDS_FLAM_SNARE": "Flam Snare",
    "SOUNDS_FOOTSTEPS": "Footsteps",
    "SOUNDS_G_BASS": "G Bass",
    "SOUNDS_G_ELEC_BASS": "G Elec Bass",
    "SOUNDS_G_ELEC_GUITAR": "G Elec Guitar",
    "SOUNDS_G_ELEC_PIANO": "G Elec Piano",
    "SOUNDS_G_GUITAR": "G Guitar",
    "SOUNDS_G_PIANO": "G Piano",
    "SOUNDS_G_SAX": "G Sax",
    "SOUNDS_G_TROMBONE": "G Trombone",
    "SOUNDS_G_TRUMPET": "G Trumpet",
    "SOUNDS_G_UKULELE": "G Ukulele",
    "SOUNDS_GALLOP": "Gallop",
    "SOUNDS_GARDEN": "Garden",
    "SOUNDS_GLASS_BREAKING": "Glass Breaking",
    "SOUNDS_GLUG": "Glug",
    "SOUNDS_GOAL_CHEER": "Goal Cheer",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Goose",
    "SOUNDS_GROWL": "Growl",
    "SOUNDS_GRUNT": "Grunt",
    "SOUNDS_GUITAR_CHORDS1": "Guitar Chords1",
    "SOUNDS_GUITAR_CHORDS2": "Guitar Chords2",
    "SOUNDS_GUITAR_STRUM": "Guitar Strum",
    "SOUNDS_HAND_CLAP": "Hand Clap",
    "SOUNDS_HEAD_SHAKE": "Head Shake",
    "SOUNDS_HEY": "Hey",
    "SOUNDS_HI_BEATBOX": "Hi Beatbox",
    "SOUNDS_HI_NA_TABLA": "Hi Na Tabla",
    "SOUNDS_HI_TUN_TABLA": "Hi Tun Tabla",
    "SOUNDS_HIGH_CONGA": "High Conga",
    "SOUNDS_HIGH_HAT": "High Hat",
    "SOUNDS_HIGH_TOM": "High Tom",
    "SOUNDS_HIGH_WHOOSH": "High Whoosh",
    "SOUNDS_HIHAT_BEATBOX": "Hihat Beatbox",
    "SOUNDS_HIHAT_CYMBAL": "Hihat Cymbal",
    "SOUNDS_HIP_HOP": "Hip Hop",
    "SOUNDS_HORSE": "Horse",
    "SOUNDS_HORSE_GALLOP": "horse gallop",
    "SOUNDS_HUMAN_BEATBOX1": "Human Beatbox1",
    "SOUNDS_HUMAN_BEATBOX2": "Human Beatbox2",
    "SOUNDS_JUMP": "Jump",
    "SOUNDS_JUNGLE": "Jungle",
    "SOUNDS_JUNGLE_FROGS": "Jungle Frogs",
    "SOUNDS_KICK_BACK": "Kick Back",
    "SOUNDS_KICK_DRUM": "Kick Drum",
    "SOUNDS_LARGE_COWBELL": "Large Cowbell",
    "SOUNDS_LASER1": "laser1",
    "SOUNDS_LASER2": "laser2",
    "SOUNDS_LAUGH1": "Laugh1",
    "SOUNDS_LAUGH2": "Laugh2",
    "SOUNDS_LAUGH3": "Laugh3",
    "SOUNDS_LO_GEH_TABLA": "Lo Geh Tabla",
    "SOUNDS_LO_GLISS_TABLA": "Lo Gliss Tabla",
    "SOUNDS_LOSE": "Lose",
    "SOUNDS_LOW_BOING": "Low Boing",
    "SOUNDS_LOW_CONGA": "Low Conga",
    "SOUNDS_LOW_SQUEAK": "Low Squeak",
    "SOUNDS_LOW_TOM": "Low Tom",
    "SOUNDS_LOW_WHOOSH": "Low Whoosh",
    "SOUNDS_MACHINE": "Machine",
    "SOUNDS_MAGIC_SPELL": "Magic Spell",
    "SOUNDS_MEDIEVAL1": "Medieval1",
    "SOUNDS_MEDIEVAL2": "Medieval2",
    "SOUNDS_MEOW": "Meow",
    "SOUNDS_MEOW2": "meow2",
    "SOUNDS_MOO": "Moo",
    "SOUNDS_MOTORCYCLE_PASSING": "Motorcycle Passing",
    "SOUNDS_MOVIE_1": "Movie 1",
    "SOUNDS_MOVIE_2": "Movie 2",
    "SOUNDS_MUTED_CONGA": "Muted Conga",
    "SOUNDS_MYSTERY": "Mystery",
    "SOUNDS_OCEAN_WAVE": "Ocean Wave",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "Oops",
    "SOUNDS_ORCHESTRA_TUNING": "Orchestra Tuning",
    "SOUNDS_OWL": "Owl",
    "SOUNDS_PARTY_NOISE": "Party Noise",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": "Ping Pong Hit",
    "SOUNDS_PLUCK": "Pluck",
    "SOUNDS_PLUNGE": "Plunge",
    "SOUNDS_POLICE_SIREN": "Police Siren",
    "SOUNDS_POP": "Pop",
    "SOUNDS_RAIN": "Rain",
    "SOUNDS_RATTLE": "Rattle",
    "SOUNDS_REFEREE_WHISTLE": "Referee Whistle",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "Ricochet",
    "SOUNDS_RIDE_CYMBAL": "Ride Cymbal",
    "SOUNDS_RING_TONE": "Ring Tone",
    "SOUNDS_RIP": "Rip",
    "SOUNDS_RIPPLES": "Ripples",
    "SOUNDS_ROLL_CYMBAL": "Roll Cymbal",
    "SOUNDS_ROOSTER": "Rooster",
    "SOUNDS_SCRAMBLING_FEET": "Scrambling Feet",
    "SOUNDS_SCRATCH_BEATBOX": "scratch beatbox",
    "SOUNDS_SCRATCHY_BEAT": "Scratchy Beat",
    "SOUNDS_SCREAM1": "Scream1",
    "SOUNDS_SCREAM2": "Scream2",
    "SOUNDS_SCREECH": "Screech",
    "SOUNDS_SEAGULLS": "Seagulls",
    "SOUNDS_SEWING_MACHINE": "Sewing Machine",
    "SOUNDS_SHAKER": "Shaker",
    "SOUNDS_SHIP_BELL": "Ship Bell",
    "SOUNDS_SIDESTICK_SNARE": "Sidestick Snare",
    "SOUNDS_SINGER1": "Singer1",
    "SOUNDS_SINGER2": "Singer2",
    "SOUNDS_SIREN_WHISTLE": "Siren Whistle",
    "SOUNDS_SKID": "Skid",
    "SOUNDS_SLIDE_WHISTLE": "Slide Whistle",
    "SOUNDS_SMALL_COWBELL": "Small Cowbell",
    "SOUNDS_SNAP": "Snap",
    "SOUNDS_SNARE_BEATBOX": "Snare Beatbox",
    "SOUNDS_SNARE_BEATBOX2": "Snare Beatbox2",
    "SOUNDS_SNARE_DRUM": "Snare Drum",
    "SOUNDS_SNEAKER_SQUEAK": "Sneaker Squeak",
    "SOUNDS_SNEEZE1": "Sneeze1",
    "SOUNDS_SNEEZE2": "Sneeze2",
    "SOUNDS_SNORING": "Snoring",
    "SOUNDS_SNORT": "Snort",
    "SOUNDS_SPACE_AMBIENCE": "Space Ambience",
    "SOUNDS_SPACE_FLYBY": "Space Flyby",
    "SOUNDS_SPACE_NOISE": "Space Noise",
    "SOUNDS_SPACE_RIPPLE": "Space Ripple",
    "SOUNDS_SPIRAL": "Spiral",
    "SOUNDS_SPLASH": "Splash",
    "SOUNDS_SPLASH_CYMBAL": "Splash Cymbal",
    "SOUNDS_SPOOKY_STRING": "Spooky String",
    "SOUNDS_SQUAWK": "Squawk",
    "SOUNDS_SQUEAKS": "Squeaks",
    "SOUNDS_SQUEAKY_TOY": "Squeaky Toy",
    "SOUNDS_SQUISH_POP": "Squish Pop",
    "SOUNDS_STRING_ACCENT": "String Accent",
    "SOUNDS_STRING_PLUCK": "String Pluck",
    "SOUNDS_SUCTION_CUP": "Suction Cup",
    "SOUNDS_SUSPENSE": "Suspense",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura",
    "SOUNDS_TAP_CONGA": "Tap Conga",
    "SOUNDS_TAP_SNARE": "Tap Snare",
    "SOUNDS_TECHNO": "Techno",
    "SOUNDS_TECHNO2": "Techno2",
    "SOUNDS_TELEPHONE_RING": "Telephone Ring",
    "SOUNDS_TELEPHONE_RING2": "Telephone Ring2",
    "SOUNDS_TELEPORT": "Teleport",
    "SOUNDS_TELEPORT2": "Teleport2",
    "SOUNDS_TELEPORT3": "Teleport3",
    "SOUNDS_TENNIS_HIT": "Tennis Hit",
    "SOUNDS_THUNDER_STORM": "Thunder Storm",
    "SOUNDS_TOM_DRUM": "Tom Drum",
    "SOUNDS_TOY_HONK": "Toy Honk",
    "SOUNDS_TOY_ZING": "Toy Zing",
    "SOUNDS_TRAFFIC": "Traffic",
    "SOUNDS_TRAIN_WHISTLE": "Train Whistle",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_TRIUMPH": "Triumph",
    "SOUNDS_TROPICAL_BIRDS": "Tropical Birds",
    "SOUNDS_TRUMPET1": "Trumpet1",
    "SOUNDS_TRUMPET2": "Trumpet2",
    "SOUNDS_VIDEO_GAME_1": "Video Game 1",
    "SOUNDS_VIDEO_GAME_2": "Video Game 2",
    "SOUNDS_WAH_BEATBOX": "Wah Beatbox",
    "SOUNDS_WAND": "Wand",
    "SOUNDS_WATER_DROP": "Water drop",
    "SOUNDS_WHINNY": "Whinny",
    "SOUNDS_WHISTLE_THUMP": "Whistle Thump",
    "SOUNDS_WHIZ": "Whiz",
    "SOUNDS_WHOOP": "Whoop",
    "SOUNDS_WIN": "Win",
    "SOUNDS_WOBBLE": "Wobble",
    "SOUNDS_WOLF_HOWL": "Wolf Howl",
    "SOUNDS_WOOD_TAP": "Wood Tap",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "Xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_APPLE": "Apple",
    "SPRITES_ARROW1": "Arrow1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Walking",
    "SPRITES_BALL": "Ball",
    "SPRITES_BALLERINA": "Ballerina",
    "SPRITES_BALLOON1": "Balloon1",
    "SPRITES_BANANAS": "Bananas",
    "SPRITES_BASEBALL": "Baseball",
    "SPRITES_BASKETBALL": "Basketball",
    "SPRITES_BAT": "Bat",
    "SPRITES_BATTER": "Batter",
    "SPRITES_BEACHBALL": "Beachball",
    "SPRITES_BEAR": "Bear",
    "SPRITES_BEAR-WALKING": "Bear-walking",
    "SPRITES_BEETLE": "Beetle",
    "SPRITES_BELL": "Bell",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Bowl",
    "SPRITES_BOWTIE": "Bowtie",
    "SPRITES_BREAD": "Bread",
    "SPRITES_BROOM": "Broom",
    "SPRITES_BUILDINGS": "Buildings",
    "SPRITES_BUTTERFLY_1": "Butterfly 1",
    "SPRITES_BUTTERFLY_2": "Butterfly 2",
    "SPRITES_BUTTON1": "Button1",
    "SPRITES_BUTTON2": "Button2",
    "SPRITES_BUTTON3": "Button3",
    "SPRITES_BUTTON4": "Button4",
    "SPRITES_BUTTON5": "Button5",
    "SPRITES_CAKE": "Cake",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Cat 2",
    "SPRITES_CATCHER": "Catcher",
    "SPRITES_CENTAUR": "Centaur",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Chick",
    "SPRITES_CITY_BUS": "City Bus",
    "SPRITES_CLOUD": "Cloud",
    "SPRITES_CLOUDS": "Clouds",
    "SPRITES_CONVERTIBLE": "Convertible",
    "SPRITES_CONVERTIBLE_2": "Convertible 2",
    "SPRITES_CRAB": "Crab",
    "SPRITES_CRYSTAL": "Crystal",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "Dinosaur1",
    "SPRITES_DINOSAUR2": "Dinosaur2",
    "SPRITES_DINOSAUR3": "Dinosaur3",
    "SPRITES_DINOSAUR4": "Dinosaur4",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DIVER1": "Diver1",
    "SPRITES_DIVER2": "Diver2",
    "SPRITES_DOG1": "Dog1",
    "SPRITES_DOG2": "Dog2",
    "SPRITES_DONUT": "Donut",
    "SPRITES_DORIAN": "Dorian",
    "SPRITES_DOT": "Dot",
    "SPRITES_DOVE": "Dove",
    "SPRITES_DRAGON": "Dragon",
    "SPRITES_DRAGONFLY": "Dragonfly",
    "SPRITES_DRESS": "Dress",
    "SPRITES_DRUM": "Drum",
    "SPRITES_DRUM_KIT": "Drum Kit",
    "SPRITES_DRUM-CYMBAL": "Drum-cymbal",
    "SPRITES_DRUM-HIGHHAT": "Drum-highhat",
    "SPRITES_DRUM-SNARE": "Drum-snare",
    "SPRITES_DRUMS_CONGA": "Drums Conga",
    "SPRITES_DRUMS_TABLA": "Drums Tabla",
    "SPRITES_DUCK": "Duck",
    "SPRITES_EARTH": "Earth",
    "SPRITES_EASEL": "Easel",
    "SPRITES_EGG": "Egg",
    "SPRITES_ELEPHANT": "Elephant",
    "SPRITES_ELF": "Elf",
    "SPRITES_FAIRY": "Fairy",
    "SPRITES_FISH": "Fish",
    "SPRITES_FISHBOWL": "Fishbowl",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Football",
    "SPRITES_FORTUNE_COOKIE": "Fortune Cookie",
    "SPRITES_FOX": "Fox",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "Frog",
    "SPRITES_FROG_2_": "Frog 2 ",
    "SPRITES_FRUIT_PLATTER": "Fruit Platter",
    "SPRITES_FRUIT_SALAD": "Fruit Salad",
    "SPRITES_GHOST": "Ghost",
    "SPRITES_GIFT": "Gift",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GLASS_WATER": "Glass Water",
    "SPRITES_GLASSES": "Glasses",
    "SPRITES_GOALIE": "Goalie",
    "SPRITES_GOBLIN": "Goblin",
    "SPRITES_GRASSHOPPER": "Grasshopper",
    "SPRITES_GREEN_FLAG": "Green Flag",
    "SPRITES_GRIFFIN": "Griffin",
    "SPRITES_GUITAR": "Guitar",
    "SPRITES_GUITAR-ELECTRIC1": "Guitar-electric1",
    "SPRITES_GUITAR-ELECTRIC2": "Guitar-electric2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hare",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hat1 ",
    "SPRITES_HATCHLING": "Hatchling",
    "SPRITES_HEART": "Heart",
    "SPRITES_HEART_CANDY": "Heart Candy",
    "SPRITES_HEART_FACE": "Heart Face",
    "SPRITES_HEDGEHOG": "Hedgehog",
    "SPRITES_HEN": "Hen",
    "SPRITES_HIPPO1": "Hippo1",
    "SPRITES_HOME_BUTTON": "Home Button",
    "SPRITES_HORSE": "Horse",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "Jar",
    "SPRITES_JELLYFISH": "Jellyfish",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "Key",
    "SPRITES_KEYBOARD": "Keyboard",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Knight",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_LADYBUG1": "Ladybug1",
    "SPRITES_LADYBUG2": "Ladybug2",
    "SPRITES_LAPTOP": "Laptop",
    "SPRITES_LIGHTNING": "Lightning",
    "SPRITES_LINE": "Line",
    "SPRITES_LION": "Lion",
    "SPRITES_LLAMA": "Llama",
    "SPRITES_MAGIC_WAND": "Magic Wand",
    "SPRITES_MAX": "Max",
    "SPRITES_MERMAID": "Mermaid",
    "SPRITES_MICROPHONE": "Microphone",
    "SPRITES_MILK": "Milk",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Monkey",
    "SPRITES_MOTORCYCLE": "Motorcycle",
    "SPRITES_MOUSE1": "Mouse1",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": "Neigh Pony",
    "SPRITES_OCTOPUS": "Octopus",
    "SPRITES_ORANGE": "Orange",
    "SPRITES_ORANGE2": "Orange2",
    "SPRITES_OUTFIELDER": "Outfielder",
    "SPRITES_OWL": "Owl",
    "SPRITES_PADDLE": "Paddle",
    "SPRITES_PANTHER": "Panther",
    "SPRITES_PANTS": "Pants",
    "SPRITES_PARROT": "Parrot",
    "SPRITES_PARTY_HATS": "Party Hats",
    "SPRITES_PENCIL": "Pencil",
    "SPRITES_PENGUIN": "Penguin",
    "SPRITES_PENGUIN_2": "Penguin 2",
    "SPRITES_PITCHER": "Pitcher",
    "SPRITES_PLANET2": "Planet2",
    "SPRITES_POLAR_BEAR": "Polar Bear",
    "SPRITES_POTION": "Potion",
    "SPRITES_PRINCE": "Prince",
    "SPRITES_PRINCESS": "Princess",
    "SPRITES_PUFFERFISH": "Pufferfish",
    "SPRITES_PUPPY": "Puppy",
    "SPRITES_RABBIT": "Rabbit",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": "Rainbow",
    "SPRITES_REFEREE": "Referee",
    "SPRITES_REINDEER": "Reindeer",
    "SPRITES_RETRO_ROBOT": "Retro Robot",
    "SOUNDS_COMPUTER_BEEPS1": "computer beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "computer beeps2",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Robot",
    "SPRITES_ROCKETSHIP": "Rocketship",
    "SPRITES_ROCKS": "Rocks",
    "SPRITES_ROOSTER": "Rooster",
    "SPRITES_RUBY": "Ruby",
    "SPRITES_SAILBOAT": "Sailboat",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "Saxophone",
    "SPRITES_SCARF": "Scarf",
    "SPRITES_SHARK": "Shark",
    "SPRITES_SHARK_2": "Shark 2",
    "SPRITES_SHIRT": "Shirt",
    "SPRITES_SHOES": "Shoes",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "Singer1",
    "SPRITES_SKELETON": "Skeleton",
    "SPRITES_SNAKE": "Snake",
    "SPRITES_SNOWFLAKE": "Snowflake",
    "SPRITES_SNOWMAN": "Snowman",
    "SPRITES_SOCCER_BALL": "Soccer Ball",
    "SPRITES_SPEAKER": "Speaker",
    "SPRITES_SQUIRREL": "Squirrel",
    "SPRITES_STAR": "Star",
    "SPRITES_STARFISH": "Starfish",
    "SPRITES_STOP": "Stop",
    "SPRITES_STRAWBERRY": "Strawberry",
    "SPRITES_SUN": "Sun",
    "SPRITES_SUNGLASSES1": "Sunglasses1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Takeout",
    "SPRITES_TEN80_DANCE": "Ten80 Dance",
    "SPRITES_TENNIS_BALL": "Tennis Ball",
    "SPRITES_TOUCAN": "Toucan",
    "SPRITES_TRAMPOLINE": "Trampoline",
    "SPRITES_TREE1": "Tree1",
    "SPRITES_TREES": "Trees",
    "SPRITES_TRUCK": "Truck",
    "SPRITES_TRUMPET": "Trumpet",
    "SPRITES_UNICORN": "Unicorn",
    "SPRITES_UNICORN_2": "Unicorn 2",
    "SPRITES_UNICORN_RUNNING": "Unicorn Running",
    "SPRITES_WAND": "Wand",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Watermelon",
    "SPRITES_WINTER_HAT": "Winter Hat",
    "SPRITES_WITCH": "Witch",
    "SPRITES_WIZARD": "Wizard",
    "SPRITES_WIZARD_HAT": "Wizard Hat",
    "SPRITES_WIZARD-TOAD": "Wizard-toad",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "Block-A",
    "SPRITES_BLOCK-B": "Block-B",
    "SPRITES_BLOCK-C": "Block-C",
    "SPRITES_BLOCK-D": "Block-D",
    "SPRITES_BLOCK-E": "Block-E",
    "SPRITES_BLOCK-F": "Block-F",
    "SPRITES_BLOCK-G": "Block-G",
    "SPRITES_BLOCK-H": "Block-H",
    "SPRITES_BLOCK-I": "Block-I",
    "SPRITES_BLOCK-J": "Block-J",
    "SPRITES_BLOCK-K": "Block-K",
    "SPRITES_BLOCK-L": "Block-L",
    "SPRITES_BLOCK-M": "Block-M",
    "SPRITES_BLOCK-N": "Block-N",
    "SPRITES_BLOCK-O": "Block-O",
    "SPRITES_BLOCK-P": "Block-P",
    "SPRITES_BLOCK-Q": "Block-Q",
    "SPRITES_BLOCK-R": "Block-R",
    "SPRITES_BLOCK-S": "Block-S",
    "SPRITES_BLOCK-T": "Block-T",
    "SPRITES_BLOCK-U": "Block-U",
    "SPRITES_BLOCK-V": "Block-V",
    "SPRITES_BLOCK-W": "Block-W",
    "SPRITES_BLOCK-X": "Block-X",
    "SPRITES_BLOCK-Y": "Block-Y",
    "SPRITES_BLOCK-Z": "Block-Z",
    "SPRITES_GLOW-0": "Glow-0",
    "SPRITES_GLOW-1": "Glow-1",
    "SPRITES_GLOW-2": "Glow-2",
    "SPRITES_GLOW-3": "Glow-3",
    "SPRITES_GLOW-4": "Glow-4",
    "SPRITES_GLOW-5": "Glow-5",
    "SPRITES_GLOW-6": "Glow-6",
    "SPRITES_GLOW-7": "Glow-7",
    "SPRITES_GLOW-8": "Glow-8",
    "SPRITES_GLOW-9": "Glow-9",
    "SPRITES_GLOW-A": "Glow-A",
    "SPRITES_GLOW-B": "Glow-B",
    "SPRITES_GLOW-C": "Glow-C",
    "SPRITES_GLOW-D": "Glow-D",
    "SPRITES_GLOW-E": "Glow-E",
    "SPRITES_GLOW-F": "Glow-F",
    "SPRITES_GLOW-G": "Glow-G",
    "SPRITES_GLOW-H": "Glow-H",
    "SPRITES_GLOW-I": "Glow-I",
    "SPRITES_GLOW-J": "Glow-J",
    "SPRITES_GLOW-K": "Glow-K",
    "SPRITES_GLOW-L": "Glow-L",
    "SPRITES_GLOW-M": "Glow-M",
    "SPRITES_GLOW-N": "Glow-N",
    "SPRITES_GLOW-O": "Glow-O",
    "SPRITES_GLOW-P": "Glow-P",
    "SPRITES_GLOW-Q": "Glow-Q",
    "SPRITES_GLOW-R": "Glow-R",
    "SPRITES_GLOW-S": "Glow-S",
    "SPRITES_GLOW-T": "Glow-T",
    "SPRITES_GLOW-U": "Glow-U",
    "SPRITES_GLOW-V": "Glow-V",
    "SPRITES_GLOW-W": "Glow-W",
    "SPRITES_GLOW-X": "Glow-X",
    "SPRITES_GLOW-Y": "Glow-Y",
    "SPRITES_GLOW-Z": "Glow-Z",
    "SPRITES_STORY-A": "Story-A",
    "SPRITES_STORY-B": "Story-B",
    "SPRITES_STORY-C": "Story-C",
    "SPRITES_STORY-D": "Story-D",
    "SPRITES_STORY-E": "Story-E",
    "SPRITES_STORY-F": "Story-F",
    "SPRITES_STORY-G": "Story-G",
    "SPRITES_STORY-H": "Story-H",
    "SPRITES_STORY-I": "Story-I",
    "SPRITES_STORY-J": "Story-J",
    "SPRITES_STORY-K": "Story-K",
    "SPRITES_STORY-L": "Story-L",
    "SPRITES_STORY-M": "Story-M",
    "SPRITES_STORY-N": "Story-N",
    "SPRITES_STORY-O": "Story-O",
    "SPRITES_STORY-P": "Story-P",
    "SPRITES_STORY-Q": "Story-Q",
    "SPRITES_STORY-R": "Story-R",
    "SPRITES_STORY-S": "Story-S",
    "SPRITES_STORY-T": "Story-T",
    "SPRITES_STORY-U": "Story-U",
    "SPRITES_STORY-V": "Story-V",
    "SPRITES_STORY-W": "Story-W",
    "SPRITES_STORY-X": "Story-X",
    "SPRITES_STORY-Y": "Story-Y",
    "SPRITES_STORY-Z": "Story-Z"
  },
  "pl": {
    "gui.backpack.header": "Plecak",
    "gui.backpack.errorBackpack": "Błąd wczytywania plecaka",
    "gui.backpack.loadingBackpack": "Ładowanie...",
    "gui.backpack.more": "Więcej",
    "gui.backpack.emptyBackpack": "Plecak jest pusty",
    "gui.gui.costumesTab": "Kostiumy",
    "gui.gui.soundsTab": "Dźwięki",
    "gui.gui.backdropsTab": "Tła",
    "gui.gui.addExtension": "Dodaj rozszerzenie",
    "gui.costumeTab.addCostumeFromLibrary": "Wybierz kostium",
    "gui.costumeLibrary.chooseACostume": "Wybierz kostium",
    "gui.costumeTab.addBackdropFromLibrary": "Wybierz tło",
    "gui.costumeTab.addBlankCostume": "Maluj",
    "gui.costumeTab.addSurpriseCostume": "Niespodzianka",
    "gui.costumeTab.addFileBackdrop": "Prześlij tło",
    "gui.costumeTab.addFileCostume": "Prześlij kostium",
    "gui.costumeTab.addCameraCostume": "Kamera/ aparat",
    "gui.soundEditor.trim": "Przytnij",
    "gui.soundEditor.stop": "Zatrzymaj",
    "gui.soundEditor.sound": "Dźwięk",
    "gui.soundEditor.play": "Zagraj",
    "gui.soundEditor.save": "Zapisz",
    "gui.soundEditor.undo": "Cofnij",
    "gui.soundEditor.redo": "Ponów",
    "gui.soundEditor.faster": "Szybciej",
    "gui.soundEditor.slower": "Wolniej",
    "gui.soundEditor.echo": "Echo",
    "gui.soundEditor.robot": "Robotyczny głos",
    "gui.soundEditor.louder": "Głośniej",
    "gui.soundEditor.softer": "Zmiękczenie",
    "gui.soundEditor.reverse": "Odwróć",
    "gui.soundTab.recordSound": "Nagraj",
    "gui.soundTab.addSoundFromLibrary": "Wybierz dźwięk",
    "gui.soundTab.surpriseSound": "Niespodzianka",
    "gui.soundTab.fileUploadSound": "Prześlij dźwięk",
    "gui.controls.stop": "Zatrzymaj",
    "gui.controls.go": "Idź",
    "gui.stageHeader.stageSizeUnFull": "Wyjdź z trybu pełnoekranowego",
    "gui.SpriteInfo.show": "Pokaż",
    "gui.SpriteInfo.size": "Rozmiar",
    "gui.directionPicker.rotationStyles.allAround": "Dookoła",
    "gui.directionPicker.rotationStyles.leftRight": "lewo/prawo",
    "gui.directionPicker.rotationStyles.dontRotate": "nie obracaj",
    "gui.spriteSelectorItem.contextMenuDuplicate": "duplikuj",
    "gui.spriteSelectorItem.contextMenuDelete": "usuń",
    "gui.spriteSelectorItem.contextMenuExport": "eksportuj",
    "gui.spriteSelector.addSpriteFromPaint": "Maluj",
    "gui.spriteSelector.addSpriteFromSurprise": "Niespodzianka",
    "gui.spriteSelector.addSpriteFromFile": "Wczytaj duszka",
    "gui.stageSelector.stage": "Scena",
    "gui.stageSelector.backdrops": "Tła",
    "gui.stageSelector.addBackdropFromPaint": "Maluj",
    "gui.stageSelector.addBackdropFromSurprise": "Niespodzianka",
    "gui.stageSelector.addBackdropFromFile": "Wczytaj tło",
    "gui.modal.back": "Powrót",
    "gui.library.filterPlaceholder": "Szukaj",
    "gui.libraryTags.all": "Wszystko",
    "gui.libraryTags.animals": "Zwierzęta",
    "gui.libraryTags.dance": "Taniec",
    "gui.libraryTags.effects": "Efekty",
    "gui.libraryTags.fantasy": "Fikcja",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Jedzenie",
    "gui.libraryTags.indoors": "W pomieszczeniu",
    "gui.libraryTags.loops": "Pętle",
    "gui.libraryTags.music": "Muzyka",
    "gui.libraryTags.notes": "Notatki",
    "gui.libraryTags.outdoors": "Na zewnątrz",
    "gui.libraryTags.patterns": "Wzory",
    "gui.libraryTags.people": "Ludzie",
    "gui.libraryTags.percussion": "Perkusja",
    "gui.libraryTags.space": "Kosmos",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Pod wodą",
    "gui.libraryTags.voice": "Głos",
    "gui.libraryTags.wacky": "Zwariowany",
    "gui.libraryTags.animation": "Animacja",
    "gui.libraryTags.art": "Sztuka",
    "gui.libraryTags.games": "Gry",
    "gui.libraryTags.stories": "Opowiadania",
    "gui.libraryTags.letters": "Litery",
    "gui.soundLibrary.chooseASound": "Wybierz dźwięk",
    "gui.SpriteInfo.spritePlaceholder": "Imię",
    "gui.cards.more-things-to-try": "Więcej rzeczy do spróbowania!",
    "gui.cards.see-more": "Zobacz więcej",
    "gui.cards.shrink": "Zmniejsz",
    "gui.cards.expand": "Rozwiń",
    "gui.cards.close": "Zamknij",
    "gui.loader.message1": "Tworzenie bloków...",
    "gui.loader.message2": "Ładowanie duszków...",
    "gui.loader.message3": "Ładowanie dźwięków",
    "gui.loader.message4": "Ładowanie rozszerzeń...",
    "gui.loader.message5": "Hodowanie kotów...",
    "gui.loader.message6": "Transmitowanie Nano...",
    "gui.loader.message7": "Pompowanie Gobo...",
    "gui.loader.message8": "Przygotowywanie emotikon...",
    "gui.loader.headline": "Ładowanie projektu",
    "gui.cameraModal.cameraModalTitle": "Zrób zdjęcie",
    "gui.cameraModal.loadingCameraMessage": "Wczytywanie kamery...",
    "gui.cameraModal.permissionRequest": "Potrzebujemy Twojego zezwolenia na korzystanie z kamery",
    "gui.cameraModal.retakePhoto": "Zrób zdjęcie ponownie",
    "gui.cameraModal.save": "Zapisz",
    "gui.cameraModal.takePhoto": "Zrób zdjęcie",
    "gui.cameraModal.loadingCaption": "Ładowanie...",
    "gui.cameraModal.enableCameraCaption": "Włącz kamerę",
    "gui.recordModal.title": "Nagraj dźwięk",
    "gui.playbackStep.stopMsg": "Zatrzymaj",
    "gui.playbackStep.playMsg": "Zagraj",
    "gui.playbackStep.loadingMsg": "Ładowanie...",
    "gui.playbackStep.saveMsg": "Zapisz",
    "gui.playbackStep.reRecordMsg": "Nagraj ponownie",
    "gui.webglModal.label": "Twoja przeglądarka nie wspiera WebGL",
    "gui.webglModal.webgllink": "nie wspiera WebG",
    "gui.prompt.cancel": "Anuluj",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Dodaj dane wejściowe",
    "gui.customProcedures.addAnInputBoolean": "Dodaj dane wejściowe",
    "gui.customProcedures.numberTextType": "liczba lub tekst",
    "gui.customProcedures.booleanType": "Boolean",
    "gui.customProcedures.addALabel": "Dodaj etykietę",
    "gui.customProcedures.runWithoutScreenRefresh": "Wykonaj bez odświeżania ekranu",
    "gui.customProcedures.cancel": "Anuluj",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Podziel się projektami ze światem.",
    "gui.extension.music.name": "Muzyka",
    "gui.extension.music.description": "Graj na instrumentach i bębnach.",
    "gui.extension.pen.name": "Pióro",
    "gui.extension.pen.description": "Narysuj za pomocą swoich duszków",
    "gui.gui.variableScopeOptionAllSprites": "Dla wszystkich duszków",
    "gui.gui.variableScopeOptionSpriteOnly": "Tylko dla tego duszka",
    "gui.gui.variablePromptAllSpritesMessage": "Ta zmienna będzie dostępna dla wszystkich duszków.",
    "gui.monitor.contextMenu.default": "normalny widok",
    "gui.monitor.contextMenu.large": "powiększony widok",
    "gui.monitor.contextMenu.slider": "suwak",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "Kierunek",
    "gui.opcodeLabels.xposition": "Pozycja x",
    "gui.opcodeLabels.yposition": "Pozycja y",
    "gui.opcodeLabels.size": "rozmiar",
    "gui.opcodeLabels.costumename": "Nazwa kostiumu",
    "gui.opcodeLabels.costumenumber": "numer kostiumu",
    "gui.opcodeLabels.backdropname": "Tło: nazwa",
    "gui.opcodeLabels.backdropnumber": "numer tła",
    "gui.opcodeLabels.volume": "Głośność",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "Odpowiedź",
    "gui.opcodeLabels.loudness": "Głośność",
    "gui.opcodeLabels.year": "Rok",
    "gui.opcodeLabels.month": "Miesiąc",
    "gui.opcodeLabels.date": "Data",
    "gui.opcodeLabels.dayofweek": "Dzień tygodnia",
    "gui.opcodeLabels.hour": "Godzina",
    "gui.opcodeLabels.minute": "Minuta",
    "gui.opcodeLabels.second": "Sekunda",
    "gui.opcodeLabels.timer": "Stoper",
    "music.categoryName": "Muzyka",
    "translate.categoryName": "Tłumacz",
    "pen.categoryName": "Pióro",
    "pen.changeColorParam": "Zmień [COLOR_PARAM]pisaka o[VALUE]",
    "pen.changeHue": "Zmień kolor pisaka o [HUE]",
    "pen.changeShade": "Zmień odcień pisaka o [SHADE]",
    "pen.changeSize": "Zmień rozmiar pisaka o [SIZE]",
    "pen.clear": "wyczyść wszystko ",
    "pen.colorMenu.brightness": "Jasność",
    "pen.colorMenu.color": "Kolor",
    "pen.colorMenu.saturation": "nasycenie",
    "pen.colorMenu.transparency": "przezroczystość",
    "pen.penDown": "Przyłóż pisak",
    "pen.penUp": "Podnieś pisak ",
    "pen.setColor": "Ustaw kolor pisaka na [COLOR]",
    "pen.setColorParam": "Ustaw [COLOR_PARAM]pisaka na [VALUE]",
    "pen.setHue": "Ustaw kolor pisaka na [HUE]",
    "pen.setShade": "Ustaw odcień pisaka na [SHADE]",
    "pen.setSize": "Ustaw rozmiar pisaka na [SIZE]",
    "pen.stamp": "Stempluj",
    "music.changeTempo": "zmień tempo na [TEMPO]",
    "music.drumBass": "(2) Bęben basowy",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Klawesy",
    "music.drumClosedHiHat": "(6) Zamknięty hi-hat",
    "music.drumConga": "(14) bęben Konga",
    "music.drumCowbell": "(11) Krowi dzwonek",
    "music.drumCrashCymbal": "(4) talerz uderzany pałeczką",
    "music.drumCuica": "(18) Cuíca",
    "music.drumGuiro": "(16) Güiro",
    "music.drumHandClap": "(8) Klaśnięcie",
    "music.drumOpenHiHat": "(5) Otwarty hi-hat",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Werbel",
    "music.drumTambourine": "(7) Tamburyn",
    "music.drumTriangle": "(12) Trójkąt",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Drewniany klocek",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bas",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Wiolonczela",
    "music.instrumentChoir": "(15) Chór",
    "music.instrumentClarinet": "(10) Klarnet",
    "music.instrumentElectricGuitar": "(5) Gitara elektryczna",
    "music.instrumentElectricPiano": "(2) Pianino elektroniczne ",
    "music.instrumentFlute": "(12) Flet",
    "music.instrumentGuitar": "(4) Gitara",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Pozytywka",
    "music.instrumentOrgan": "(3) Organy",
    "music.instrumentPiano": "(1) Pianino",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saksofon",
    "music.instrumentSteelDrum": "(18) Stalowy bęben",
    "music.instrumentSynthLead": "(26) Wiodący dźwięk syntezatora",
    "music.instrumentSynthPad": "(21) Pad syntezatora",
    "music.instrumentTrombone": "(9) Puzon",
    "music.instrumentVibraphone": "(16) Wibrafon",
    "music.instrumentWoodenFlute": "(13) Drewniany flet",
    "music.midiPlayDrumForBeats": "graj na [DRUM] przez [BEATS] taktów",
    "music.midiSetInstrument": "ustaw instrument na [INSTRUMENT]",
    "music.playDrumForBeats": "graj na [DRUM] przez [BEATS] taktów",
    "music.playNoteForBeats": "zagraj nutę [NOTE] przez [BEATS] taktów",
    "music.restForBeats": "pauzuj przez [BEATS] taktów",
    "music.setInstrument": "ustaw instrument na [INSTRUMENT]",
    "music.setTempo": "ustaw tempo na [TEMPO]",
    "videoSensing.categoryName": "Czujniki wideo",
    "videoSensing.direction": "Kierunek",
    "videoSensing.motion": "ruch",
    "videoSensing.off": "wyłączony",
    "videoSensing.on": "włączony",
    "videoSensing.onFlipped": "on flipped",
    "videoSensing.setVideoTransparency": "ustaw transparentność wideo na [TRANSPARENCY]",
    "videoSensing.sprite": "duszek",
    "videoSensing.stage": "scena",
    "videoSensing.videoOn": "nagranie wideo [ATTRIBUTE] na [SUBJECT]",
    "videoSensing.videoToggle": " [VIDEO_STATE] nagranie wideo ",
    "videoSensing.whenMotionGreaterThan": "kiedy ruch wideo > [REFERENCE]",
    "translate.translateBlock": "tłumacz [WORDS] na [LANGUAGE]",
    "translate.defaultTextToTranslate": "cześć",
    "translate.viewerLanguage": "język",
    "text2speech.speakAndWaitBlock": "Powiedz [WORDS]",
    "text2speech.setVoiceBlock": "Ustaw głos na [VOICE]",
    "text2speech.setLanguageBlock": "ustaw język na [LANGUAGE]",
    "text2speech.alto": "kobiecy",
    "text2speech.tenor": "męski",
    "text2speech.squeak": "dziecięcy",
    "text2speech.giant": "zmodyfikowany",
    "text2speech.kitten": "kociątko",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "kiedy wykryję dźwięk [PHRASE]",
    "speech.listenAndWait": "posłuchaj i czekaj",
    "speech.defaultWhenIHearValue": "chodźmy",
    "paint.paintEditor.hue": "Kolor",
    "paint.paintEditor.saturation": "Nasycenie",
    "paint.paintEditor.brightness": "Jasność",
    "gui.comingSoon.message1": "Nie martw się, pracujemy nad tym {emoji}",
    "gui.comingSoon.message2": "Już wkrótce...",
    "gui.comingSoon.message3": "Pracujemy nad tym {emoji}",
    "paint.paintEditor.fill": "Wypełnij",
    "paint.paintEditor.costume": "Kostium",
    "paint.paintEditor.group": "Grupuj",
    "paint.paintEditor.ungroup": "Rozgrupuj",
    "paint.paintEditor.undo": "Cofnij",
    "paint.paintEditor.redo": "Ponów",
    "paint.paintEditor.forward": "Do przodu",
    "paint.paintEditor.backward": "Do tyłu",
    "paint.paintEditor.front": "Przód",
    "paint.paintEditor.back": "Powrót",
    "paint.paintEditor.more": "Więcej",
    "paint.modeTools.brushSize": "Rozmiar",
    "paint.modeTools.eraserSize": "Wielkość gumki",
    "paint.modeTools.copy": "Kopiuj",
    "paint.modeTools.paste": "Wklej",
    "paint.modeTools.delete": "Usuń",
    "paint.modeTools.curved": "Zakrzywiony",
    "paint.modeTools.pointed": "Wskazany",
    "paint.modeTools.thickness": "Grubość",
    "paint.modeTools.flipHorizontal": "Odwróć w płaszczyźnie poziomej",
    "paint.modeTools.flipVertical": "Odwróć w płaszczyźnie pionowej",
    "paint.modeTools.filled": "Wypełniony",
    "paint.modeTools.outlined": "Kontur",
    "paint.paintEditor.bitmap": "Przekształć w bitmapę",
    "paint.paintEditor.vector": "Konwertuj na wektor",
    "paint.paintEditor.stroke": "Zarys",
    "paint.brushMode.brush": "Pędzel",
    "paint.eraserMode.eraser": "Gumka",
    "paint.fillMode.fill": "Wypełnij",
    "paint.lineMode.line": "Linia",
    "paint.ovalMode.oval": "Okrąg",
    "paint.rectMode.rect": "Prostokąt",
    "paint.reshapeMode.reshape": "Przekształć",
    "paint.roundedRectMode.roundedRect": "Zaokrąglony prostokąt",
    "paint.selectMode.select": "Wybierz",
    "paint.textMode.text": "Tekst",
    "paint.colorPicker.swap": "Zamień",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "pt": {
    "gui.gui.project": "Projeto",
    "gui.gui.newItem": "Novo Projeto",
    "gui.gui.load": "Carregar Projeto",
    "gui.gui.save": "Guardar Projeto",
    "gui.gui.saveAs": "Guardar Como",
    "gui.gui.learn": "Aprender",
    "gui.gui.clearRecord": "Limpar Registo",
    "gui.gui.onlineLearn": "Documentos Oficiais",
    "gui.gui.onlineForum": "Forum Online",
    "gui.gui.videoTutorial": "Tutoriais Vídeo ",
    "gui.gui.offlineLearn": "Programas de Exemplo ",
    "gui.menuBar.turboModeOff": "Desligar Turbo Mode",
    "gui.menuBar.turboModeOn": "Ligar Turbo Mode",
    "gui.menuBar.edit": "Editar",
    "gui.menuBar.restoreSprite": "Restaurar Ator",
    "gui.menuBar.restoreSound": "Restaurar Som",
    "gui.menuBar.restoreCostume": "Restaurar Traje",
    "gui.menuBar.restoreBackdrop": "Restaurar Cenário",
    "gui.editorMind.restore": "Restaurar",
    "gui.turboMode.active": "Turbo Mode",
    "gui.gui.connectDevice": "Ligar Dispositivo",
    "gui.gui.disconnectDevice": "Desligar Dispositivo",
    "gui.gui.installSerialportDriver": "Instalar SerialPort Driver",
    "gui.gui.openDM": "Abrir Administrador de Dispositivos",
    "gui.gui.restoreSetting": "Restaurar os parâmetros iniciais do Dispositivo",
    "gui.gui.updateTips": "Aviso de Actualização",
    "gui.gui.newVersion": "A Última Versão",
    "gui.gui.downloadUpdate": "Descarregar para atualizar",
    "gui.gui.versionUpdate": "Atualizar a versão",
    "gui.gui.isCheckMd5": "A verificar o ficheiro",
    "gui.gui.downloading": "Checking Updator",
    "gui.gui.updateError": "Erro de atualização",
    "gui.setting.feedbackMenu": "Comentário",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.setting.officialWebsit": "Website Oficial",
    "gui.setting.officialQQGroup": " Grupo QQ Oficial",
    "gui.setting.wechatOfficialAccounts": "Conta Oficial de WeChat",
    "gui.setting.currentVersion": "Versão Atual",
    "gui.setting.checkForUpdate": "Verificar Atualizações",
    "gui.setting.remindWhenUpdate": "Atualização Automática",
    "gui.setting.currentIsLatest": "A sua versão é a mais recente",
    "gui.setting.latestVersion": "A versão mais recente",
    "gui.setting.download": "Descarregar actualização",
    "gui.setting.language": "Linguagem",
    "gui.setting.uploadCsv": "Carregar xlsx",
    "gui.setting.theme": "Tema",
    "gui.setting.feedback": "Contacto",
    "gui.setting.email": "E-mail",
    "gui.setting.opinionFeedback": "Comentário",
    "gui.variableMc.variableName": "Nome da Variável",
    "gui.variableMc.variableOff": "cancelar",
    "gui.variableMc.variableOn": "confirmar",
    "gui.variableMc.newVariable": "nova variável",
    "gui.setting.helpUsTranslate": "Ajude-nos a traduzir",
    "gui.setting.checkUpdate": "Procurar atualização",
    "gui.setting.ok": "Ok",
    "gui.setting.languageSetting": "Língua",
    "gui.setting.themeSetting": "Tema",
    "gui.setting.versionUpdate": "Atualizar Versão",
    "gui.setting.connectUsTitle": "Contacte-nos",
    "gui.setting.uploadAttachment": "Carregar anexo",
    "gui.setting.displayTitle": "Definições de Texto",
    "gui.setting.fontSizeSetting": "Tamanho de letra",
    "gui.setting.restartEffect": "recomeçar efeito",
    "gui.setting.fontSizeLarge": "Grande",
    "gui.setting.fontSizeMiddle": "Média",
    "gui.setting.fontSizeSmall": "Pequena",
    "gui.setting.onlineRepair": "Ferramenta de reparação online, clique abrir >",
    "gui.backpack.header": "Mochila",
    "gui.backpack.errorBackpack": "Erro ao carregar a mochila",
    "gui.backpack.loadingBackpack": "Carregando…",
    "gui.backpack.more": "Mais",
    "gui.backpack.emptyBackpack": "A mochila está vazia",
    "gui.gui.costumesTab": "Trajes",
    "gui.gui.soundsTab": "Sons",
    "gui.gui.backdropsTab": "Cenários",
    "gui.gui.addExtension": "Adicionar Extensão",
    "gui.costumeTab.addCostumeFromLibrary": "Escolher um Traje",
    "gui.costumeLibrary.chooseACostume": "Escolher um Traje",
    "gui.costumeTab.addBackdropFromLibrary": "Escolher um Cenário",
    "gui.costumeTab.addBlankCostume": "Pintar",
    "gui.costumeTab.addSurpriseCostume": "Surpresa",
    "gui.costumeTab.addFileBackdrop": "Carregar Cenário",
    "gui.costumeTab.addFileCostume": "Carregar Traje",
    "gui.costumeTab.addCameraCostume": "Câmara",
    "gui.soundEditor.trim": "Aparar",
    "gui.soundEditor.stop": "Parar",
    "gui.soundEditor.sound": "Som",
    "gui.soundEditor.play": "Tocar",
    "gui.soundEditor.save": "Guardar",
    "gui.soundEditor.undo": "Desfazer",
    "gui.soundEditor.redo": "Refazer",
    "gui.soundEditor.faster": "Acelerar",
    "gui.soundEditor.slower": "Retardar",
    "gui.soundEditor.echo": "Eco",
    "gui.soundEditor.robot": "Robô",
    "gui.soundEditor.louder": "Maior Volume",
    "gui.soundEditor.softer": "Menor Volume",
    "gui.soundEditor.reverse": "Inverter",
    "gui.soundTab.recordSound": "Gravar",
    "gui.soundTab.addSoundFromLibrary": "Escolher um Som",
    "gui.soundTab.surpriseSound": "Surpresa",
    "gui.soundTab.fileUploadSound": "Carregar Som",
    "gui.controls.stop": "Parar",
    "gui.controls.go": "Começar",
    "gui.stageHeader.stageSizeUnFull": "Sair do modo de ecrã completo",
    "gui.SpriteInfo.show": "Mostrar",
    "gui.SpriteInfo.size": "Tamanho",
    "gui.directionPicker.rotationStyles.allAround": "Girar a Toda a Volta",
    "gui.directionPicker.rotationStyles.leftRight": "Olhar apenas para a esquerda e para a direita",
    "gui.directionPicker.rotationStyles.dontRotate": "Não rodar",
    "gui.spriteSelectorItem.contextMenuDuplicate": "duplicar",
    "gui.spriteSelectorItem.contextMenuDelete": "remover",
    "gui.spriteSelectorItem.contextMenuExport": "exportar",
    "gui.spriteSelector.addSpriteFromPaint": "Pintar",
    "gui.spriteSelector.addSpriteFromSurprise": "Surpresa",
    "gui.spriteSelector.addSpriteFromFile": "Carregar Actor",
    "gui.stageSelector.stage": "Palco",
    "gui.stageSelector.backdrops": "Cenários",
    "gui.stageSelector.addBackdropFromPaint": "Pintar",
    "gui.stageSelector.addBackdropFromSurprise": "Surpresa",
    "gui.stageSelector.addBackdropFromFile": "Carregar Cenário",
    "gui.modal.back": "Voltar",
    "gui.library.filterPlaceholder": "Procurar",
    "gui.libraryTags.all": "Tudo",
    "gui.libraryTags.animals": "Animais",
    "gui.libraryTags.dance": "Dança",
    "gui.libraryTags.effects": "Efeitos",
    "gui.libraryTags.fantasy": "Fantasia",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Comida",
    "gui.libraryTags.indoors": "Interior",
    "gui.libraryTags.loops": "Ciclos de som",
    "gui.libraryTags.music": "Música",
    "gui.libraryTags.notes": "Notas",
    "gui.libraryTags.outdoors": "Exterior",
    "gui.libraryTags.patterns": "Padrões",
    "gui.libraryTags.people": "Pessoas",
    "gui.libraryTags.percussion": "Percussão",
    "gui.libraryTags.space": "Espaço",
    "gui.libraryTags.sports": "Desporto",
    "gui.libraryTags.underwater": "Debaixo de água",
    "gui.libraryTags.voice": "Vozes",
    "gui.libraryTags.wacky": "Maluquices",
    "gui.libraryTags.animation": "Animações",
    "gui.libraryTags.art": "Arte",
    "gui.libraryTags.games": "Jogos",
    "gui.libraryTags.stories": "Estórias",
    "gui.libraryTags.letters": "Letras",
    "gui.soundLibrary.chooseASound": "Escolher um Som",
    "gui.SpriteInfo.spritePlaceholder": "Nome",
    "gui.cards.more-things-to-try": "Mais coisas a experimentar!",
    "gui.cards.see-more": "Ver mais",
    "gui.cards.shrink": "Reduzir",
    "gui.cards.expand": "Expandir",
    "gui.cards.close": "Fechar",
    "gui.loader.message1": "A criar blocos …",
    "gui.loader.message2": "A carregar os actores …",
    "gui.loader.message3": "A carregar os sons …",
    "gui.loader.message4": "A carregar extensões …",
    "gui.loader.message5": "A apascentar gatos …",
    "gui.loader.message6": "A transmitir nanos …",
    "gui.loader.message7": "A soprar balões …",
    "gui.loader.message8": "A preparar emojis …",
    "gui.loader.headline": "A Carregar o Projecto",
    "gui.cameraModal.cameraModalTitle": "Tirar uma Fotografia",
    "gui.cameraModal.loadingCameraMessage": "Carregando a Câmara…",
    "gui.cameraModal.permissionRequest": "Precisamos da sua permissão para usar a sua câmara",
    "gui.cameraModal.retakePhoto": "Tirar Fotografia de Novo",
    "gui.cameraModal.save": "Guardar",
    "gui.cameraModal.takePhoto": "Tirar Fotografia",
    "gui.cameraModal.loadingCaption": "Carregando…",
    "gui.cameraModal.enableCameraCaption": "Activar a Câmara",
    "gui.recordModal.title": "Gravar Som",
    "gui.playbackStep.stopMsg": "Parar",
    "gui.playbackStep.playMsg": "Tocar",
    "gui.playbackStep.loadingMsg": "Carregando…",
    "gui.playbackStep.saveMsg": "Guardar",
    "gui.playbackStep.reRecordMsg": "Voltar a Gravar",
    "gui.webglModal.label": "O Seu Navegador Não Suporta WebGL",
    "gui.webglModal.webgllink": "não suporta WebGL",
    "gui.prompt.cancel": "Cancelar",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Adicionar uma entrada",
    "gui.customProcedures.addAnInputBoolean": "Adicionar uma entrada",
    "gui.customProcedures.numberTextType": "numérica ou textual",
    "gui.customProcedures.booleanType": "booleana",
    "gui.customProcedures.addALabel": "Adicionar uma etiqueta",
    "gui.customProcedures.runWithoutScreenRefresh": "Executar sem actualizar o ecrã",
    "gui.customProcedures.cancel": "Cancelar",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Ligue os seus projectos ao mundo.",
    "gui.extension.music.name": "Música",
    "gui.extension.music.description": "Toque instrumentos e percussão.",
    "gui.extension.pen.name": "Caneta",
    "gui.extension.pen.description": "Desenhe com os seus actores.",
    "gui.gui.variableScopeOptionAllSprites": "Para todos os actores",
    "gui.gui.variableScopeOptionSpriteOnly": "Apenas para este actor",
    "gui.gui.variablePromptAllSpritesMessage": "Esta variável estará disponível para todos os actores.",
    "gui.monitor.contextMenu.default": "visor normal",
    "gui.monitor.contextMenu.large": "visor grande",
    "gui.monitor.contextMenu.slider": "deslizador",
    "gui.monitor.contextMenu.import": "importar",
    "gui.monitor.contextMenu.export": "exportar",
    "gui.opcodeLabels.direction": "direcção",
    "gui.opcodeLabels.xposition": "x da posição",
    "gui.opcodeLabels.yposition": "y da posição",
    "gui.opcodeLabels.size": "tamanho",
    "gui.opcodeLabels.costumename": "nome do traje",
    "gui.opcodeLabels.costumenumber": "número do traje",
    "gui.opcodeLabels.backdropname": "nome do cenário",
    "gui.opcodeLabels.backdropnumber": "número do cenário",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "andamento",
    "gui.opcodeLabels.answer": "resposta",
    "gui.opcodeLabels.loudness": "volume no microfone",
    "gui.opcodeLabels.year": "ano actual",
    "gui.opcodeLabels.month": "mês actual",
    "gui.opcodeLabels.date": "data actual",
    "gui.opcodeLabels.dayofweek": "dia da semana actual",
    "gui.opcodeLabels.hour": "hora actual",
    "gui.opcodeLabels.minute": "minuto actual",
    "gui.opcodeLabels.second": "segundo actual",
    "gui.opcodeLabels.timer": "valor do cronómetro",
    "gui.gui.blocks": "Blocos",
    "gui.soundTab.addSound": "Adicionar som",
    "gui.gui.startPython": "Iniciar Python",
    "gui.gui.stopPython": "Parar Python",
    "gui.controls.fullScreenControl": "Controlo de ecrã completo",
    "gui.gui.stageSizeLarge": "Controlo de ecrã grande",
    "gui.gui.stageSizeSmall": "Controlo de ecrã pequeno",
    "gui.gui.stageSizeNostage": "Mudar tamanho do palco - Sem Palco ",
    "gui.gui.stageSizeFull": "Mudar tamanho do palco - ecrã total",
    "gui.sprite.sprite": "Ator",
    "gui.sprite.direction": "Direção",
    "gui.sprite.rotation": "Rotação",
    "gui.sprite.addSpriteFromLibrary": "Biblioteca de atores",
    "gui.sprite.addSpriteFromCamera": "Câmera",
    "gui.stage.addBackdropFromLibrary": "Escolhe um cenário",
    "gui.stage.addBackdropFromCamera": "Câmera",
    "gui.extension.kit": "Kit",
    "gui.extension.kitTitle": "Seleciona um Kit",
    "gui.extension.board": "Placa",
    "gui.extension.boardTitle": "Selecionar Placa",
    "gui.extension.spreadBoard": "Shield",
    "gui.extension.spreadBoardTitle": "Selecionar Shield",
    "gui.extension.sensor": "Sensor",
    "gui.extension.sensorTitle": "Selecionar Sensor",
    "gui.extension.actuator": "Atuador",
    "gui.extension.actuatorTitle": "Escolhe Atuador",
    "gui.extension.communicationModule": "Comunicação",
    "gui.extension.communicationModuleTitle": "Selecionar Comunicação",
    "gui.extension.display": "Ecrã",
    "gui.extension.displayTitle": "Selecionar Ecrã",
    "gui.extension.function": "Função",
    "gui.extension.functionTitle": "Selecionar Função",
    "gui.extension.internetService": "Internet",
    "gui.extension.internetServiceTitle": "Seleciona serviço de Internete",
    "gui.extension.thirdExpansion": "Utilizador-Ext",
    "gui.extension.thirdExpansionTitle": "Seleciona expansão de utilizador",
    "gui.extension.arduinContentLabel": "Seleciona Dispositivo",
    "gui.extension.unavailable": "Indisponível",
    "gui.extension.thirdModuleSearch": "Procura ou insere URL do projeto ",
    "gui.extension.backdropLib": "Escolhe um cenário",
    "gui.extension.spriteLib": "Escolhe um ator",
    "gui.gui.unselectedSerialport": "Nenhum dispositivo ligado",
    "gui.gui.unConnectedDev": "Nenhum dispositivo ligado",
    "gui.gui.pythonMode": " Modo Python",
    "gui.gui.burnFirmware": "Gravar Firmware Novamente",
    "gui.gui.burnFirmwareError": "Erro de gravação de Firmware",
    "gui.gui.connected": "Ligado",
    "gui.gui.failedConnected": "Falhou a ligação",
    "gui.gui.connecting": "A ligar...",
    "gui.cards.all-how-tos": "Todos Tutoriais",
    "gui.cards.how-tos": "Tutoriais",
    "gui.cards.remove": "Sair",
    "gui.recordStep.recordByClickBtn": "Começa a gravar clicando no botão em baixo ",
    "gui.recordStep.permissionMicrophone": "Precisamos da sua autorização para usar o seu microfone",
    "gui.recordStep.record": "Gravar",
    "gui.recordStep.stopRecord": "Parar Gravação",
    "gui.webglModal.descriptionMind": "Infelizmente o teu computador {webGlLink}. Esta tecnologia é necessária para correr Mind+. {updateGpuDriver}",
    "gui.webglModal.updateGpuDriver": "Por favor, tente atualizar o driver da placa gráfica",
    "gui.webglModal.ok": "Ok",
    "gui.extension.scratchExampleLib": "Biblioteca de exemplos Scratch ",
    "gui.extension.pythonExampleLib": "Biblioteca de exemplos Python",
    "gui.extension.arduinoExampleLib": "Biblioteca de exemplos Arduino",
    "gui.extension.makeBlock": "Cria um Bloco",
    "gui.customProcedures.textType": "texto",
    "gui.customProcedures.numberType": "número",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "Vortex robot educacional da DFRobot",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "Explorer D1 robot educacional da DFRobot",
    "gui.extension.arduinounoR3.name": "ArduinounoR3",
    "gui.extension.arduinounoR3.description": "Curso do kit da placa de controlo principal baseada no arduino uno elaborado por Wuhan Maker.",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "Max:bot Robot baseado no micro:bit",
    "gui.extension.max.name": "MAX",
    "gui.extension.max.description": "MAX（ROB0137）robot baseado no Arduino",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "Motor:bit motor and servo Placa de expansão",
    "gui.extension.maqueen.name": "Maqueen",
    "gui.extension.maqueen.description": "micro:Maqueen robot baseado no micro:bit",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.spread.name": "extensão mpython ",
    "gui.extension.microTouch.name": "TouchKey",
    "gui.extension.microNaturalScience.name": "micro:naturalScience",
    "gui.extension.microTouch.description": " Placa de expansão integrada 4×4 de controlo de teclado, servo e motor ",
    "gui.extension.microNaturalScience.description": "Micro:bit placa de expansão com diversos sensores de ambiente",
    "gui.extension.microIoT.name": "micro:IoT",
    "gui.extension.microIoT.description": "Ecrã Integrado e Internet of things micro:bit Placa de Extensão",
    "gui.extension.spreadMaqueen.name": "extensão maqueen ",
    "gui.extension.spread.description": "placa de extensão mpython",
    "gui.extension.spreadmaqueen.description": "Mini-carro para iniciante baseado na versão de controlo. ",
    "gui.extension.maqueenPlus.name": "Maqueen Plus (China)",
    "gui.extension.maqueenPlus.description": "Robot educacional avançado que suporta micro:bit e mPython",
    "gui.extension.iot.name": "Internet of Things",
    "gui.extension.iot.description": "Edição Expandida Baseada na Internet of Things",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Dispositivo controlado pelo Leonardo",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Uno Placa principal de controlo",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Dispositivo controlado pelo Arduino Nano",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "Placa principal de contolo baseada na ESP32",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Dispositivo controlado pelo Mega2560",
    "gui.extension.dfr0299.name": "DFPlayer Módulo MP3",
    "gui.extension.dfr0299.description": "Módulo Leitor de MP3",
    "gui.extension.ser0006.name": "Micro Servo",
    "gui.extension.ser0006.description": "Micro Servo",
    "gui.extension.dfr0523.name": "bomba peristáltica",
    "gui.extension.dfr0523.description": "Distribui liquido através de alternadamente aperta e soltar a bomba ",
    "gui.extension.dfr0017.name": " Módulo Relay",
    "gui.extension.dfr0017.description": "Para controlar dispositivos de corrente alta ",
    "gui.extension.steppermotor.name": "Motor de passo",
    "gui.extension.steppermotor.description": "Realiza um controlo apurado de posição através de pulse control",
    "gui.extension.dfr0534.name": "Módulo MP3 de Série",
    "gui.extension.dfr0534.description": "Módulo de voz MP3 de série",
    "gui.extension.servo360.name": "360° Micro Servo",
    "gui.extension.servo360.description": "Controlo de velocidade e direção",
    "gui.extension.tel0118.name": "Módulo OBLOQ IoT  ",
    "gui.extension.tel0118.description": " Dispositivo Wi-Fi to Serial",
    "gui.extension.dfr0095.name": "Módulo IR Transmissor",
    "gui.extension.dfr0095.description": "Transmite sinal de infravermelhos de 38KHz",
    "gui.extension.dfr0094.name": "Módulo IR Recetor",
    "gui.extension.dfr0094.description": "Recebe sinal de infravermelhos de 38KHz",
    "gui.extension.midi.name": "MIDI",
    "gui.extension.midi.description": "Leitor MIDI",
    "gui.extension.tel0094.name": "Módulo de receção de sinal GPS ",
    "gui.extension.tel0094.description": "Módulo de receção de sinal GPS ",
    "gui.extension.tel0026.name": "Módulo Bluetooth",
    "gui.extension.tel0026.description": "Módulo Bluetooth serial port ",
    "gui.extension.dfr0486.name": "Ecrã OLED-12864 ",
    "gui.extension.dfr0486.description": "Módulo de Ecrã I2C OLED-12864 ",
    "gui.extension.fit0352.name": "Fita Led WS2812 RGB",
    "gui.extension.fit0352.description": "Control WS2812-based LED strip modules",
    "gui.extension.dfr0063.name": "Ecrã LCD1602 ",
    "gui.extension.dfr0063.description": "Módulo LCD que consegue mostra 2 linhas e 16 caráteres ",
    "gui.extension.dfr0021.name": "Módulo Digital de Luz LED ",
    "gui.extension.dfr0021.description": "Módulos de LED Brancos, vermelhos, verdes e azuis ",
    "gui.extension.tm1650.name": "Tubo de 4 dígitos TM1650",
    "gui.extension.tm1650.description": "Tubo de 4 dígitos",
    "gui.extension.matrix8_8.name": "Matriz MAX7219 8x8 pontos",
    "gui.extension.matrix8_8.description": "Módulo 8x8 de LED'S",
    "gui.extension.dfr0522.name": "Painel de Matriz Led RGB 8x16",
    "gui.extension.dfr0522.description": "Mostra imagens, números... Suport visionamento em arrasto e definições de utilizador ",
    "gui.extension.video-sensing.name": "Deteção Vídeo",
    "gui.extension.video-sensing.description": "Detetar movimento com a câmera.",
    "gui.extension.google-translate.name": "Tradução",
    "gui.extension.google-translate.description": "Traduzir texto em várias línguas.",
    "gui.extension.text-speech.name": "Texto para Fala",
    "gui.extension.text-speech.description": "Faz com que os teus projetos falem",
    "gui.extension.softSerialport.name": "Software Serial Port",
    "gui.extension.softSerialport.description": "Use porta I/O para simular função de comunicação por série ",
    "gui.extension.IICScan.name": "I2C Scan",
    "gui.extension.IICScan.description": "Procure todas as moradas de dispositivos ligados à interface I2C ",
    "gui.extension.bluetooth.name": "Bluetooth",
    "gui.extension.bluetooth.description": "Dispositivo Bluetooth ",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "GoBLE APP pode ajudar a controlar a placa principal de controlo através de smart phone",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "Use para ler ou escrever informação com a eprom",
    "gui.extension.interrupt.name": "Interrupção",
    "gui.extension.interrupt.description": "Pin de Interrupção",
    "gui.extension.sdcard.name": "Módulo de leitura de SD card ",
    "gui.extension.sdcard.description": "Módulo de leitura de MicroSD ",
    "gui.extension.multithread.name": "MultiThread",
    "gui.extension.multithread.description": "Permite que vários programas e o programa principla funcionem em simultâneo",
    "gui.extension.speech.name": "Fala",
    "gui.extension.speech.description": "Fala para os teus projetos.",
    "gui.extension.sen0001.name": "Sensor Ultrasonic ",
    "gui.extension.sen0001.description": "Deteção precisa de distância com o intervalo 2~800cm ",
    "gui.extension.dfr0023.name": "Sensor de Temperatura Analógico LM35",
    "gui.extension.dfr0023.description": "Sensor de Temperatura baseado no LM35 com o intervalo de 0~100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22  Sensor de Temperatura e Humidade",
    "gui.extension.dhtTHSensor.description": "Deteçao de temperatura ambiente e humidade",
    "gui.extension.dsTSensor.name": "DS18B20 Sensor de  Temperatura",
    "gui.extension.dsTSensor.description": "Deteção de temperatura ambiente no intervalo largo de -55~+125℃ ",
    "gui.extension.sen0203.name": "Sensor de ritmo cardíaco  ",
    "gui.extension.sen0203.description": "Mini sensor de ritmo cardíaco com os modos de saída analog pulse e digital square wave",
    "gui.extension.sen0177.name": "Sensor de Qualidade do Ar Laser PM2.5",
    "gui.extension.sen0177.description": "Medir PM1, PM2.5 and PM10. Transferir dados via porta de série.",
    "gui.extension.sen0014.name": "Sensor de Distancia GP2Y0A21 ",
    "gui.extension.sen0014.description": "sensor IR basedo no GP2Y0A21 com intervalo de 10~80cm",
    "gui.extension.sen0204.name": " Sensor de nível de líquido sem contacto ",
    "gui.extension.sen0204.description": "Deteta nível de líquido sem qualquer contacto ",
    "gui.extension.sen0160.name": "sensor de peso Hx711 ",
    "gui.extension.sen0160.description": "Medição através de sensor de peso Hx711",
    "gui.extension.sen0161.name": "Medidor Analógico de pH ",
    "gui.extension.sen0161.description": "Medir o valor de ph do líquido com alimentação de 5V ",
    "gui.extension.sen0161-v2.name": "Medidor Analógico de pH(V2)",
    "gui.extension.sen0161-v2.description": "Medir o valor de ph do líquido com alimentação de entre 3.3 a 5.5V ",
    "gui.extension.sen0244.name": " Sensor Analógico TDS",
    "gui.extension.sen0244.description": "Medir o TDS de líquido, mostra a limpeza da água",
    "gui.extension.sen0165.name": "Medidor Analógico ORP",
    "gui.extension.sen0165.description": "Medir ORP de líquido, para avaliar a qualidade da água",
    "gui.extension.sen0237.name": "Sensor Analógico de Oxigénio Dissolvido",
    "gui.extension.sen0237.description": "Medir a quantidade de oxígenio na água para avaliar a qualidade da água",
    "gui.extension.dfr0300-H.name": "Medidor Analógico EC ",
    "gui.extension.dfr0300-H.description": "Medir a condutividade eléctrica do líquidopara avaliar a qualidade da água",
    "gui.extension.dfr0300.name": "Medidor Analógico EC(V2)",
    "gui.extension.dfr0300.description": "Medir EC(a condutividade eléctrica) de líquido para avaliar a qualidade da água",
    "gui.extension.sen0170.name": "Anemometro",
    "gui.extension.sen0170.description": "Medir a velocidade do vento e enviar sinal ",
    "gui.extension.sen0226.name": "Sensor de temperatura BMP280 ",
    "gui.extension.sen0226.description": "Sensor de pressão barimétrico BMP280 para temperatura e medição barométrica",
    "gui.extension.sen0228.name": "Sensor de Luz Ambiente I2C VEML7700 ",
    "gui.extension.sen0228.description": "Sensor de Luz Ambiente de alta precisão e resolução digital de 16-bit",
    "gui.extension.sen0236.name": "Módulo BME280 ",
    "gui.extension.sen0236.description": "Módulo BME280 ",
    "gui.extension.dfr0022.name": "Sensor Analógico de escala de cinzentos  ",
    "gui.extension.dfr0022.description": "Detecta a densidade da luz e reflete o sinal de tensão analógico, que pode ser aplicado ao line-tracking",
    "gui.extension.motucamera.name": "Placa de Sensor MU ",
    "gui.extension.motucamera.description": "Módulo de câmera que suporta vários reconhecimentso de imagem inteligente",
    "gui.extension.dfr0552.name": "12-bit módulo de conversão DA digital analógico",
    "gui.extension.dfr0552.description": "Converta com precisão quantidades digitais em sinais de tensão CC correspondentes (não PWM)",
    "gui.extension.sen0117.name": "Módulo de síntese de fala",
    "gui.extension.sen0117.description": "Contando que você digite os caracteres e números correspondentes em chinês e inglês, ele lerá essas palavras",
    "gui.extension.dfr0576.name": "Expansor em cascata I2C",
    "gui.extension.dfr0576.description": "Usado para resolver conflitos de endereço de dispositivo I2C",
    "gui.extension.sen0248.name": "Sensor ambiental BME680",
    "gui.extension.sen0248.description": "Pode medir os quatro parâmetros de VOC (compostos orgânicos voláteis), temperatura, humidade e pressão do ar",
    "gui.extension.sen0304.name": "Sensores ultrassônicos projetados para aplicações de medição de distância rápida ou prevenção de obstáculos",
    "gui.extension.sen0304.description": "Ultrasonic sensors designed for rapid ranging or obstacle avoidance applications",
    "gui.extension.sen0307.name": "Sensor ultrassônico analógico",
    "gui.extension.sen0307.description": "Sensor ultrassônico analógico de alcance duplo aberto",
    "gui.extension.sen0250.name": "Sensor de movimento inercial de 6 eixos BMI160",
    "gui.extension.sen0250.description": "Aceleração de 3 eixos de 16 bits + giroscópio de 3 eixos de potência ultra baixa",
    "gui.extension.sen0224.name": "Acelerômetro de três eixos I2C LIS2DH",
    "gui.extension.sen0224.description": "Sensor de aceleração de 3 eixos com consumo de energia ultrabaixo",
    "gui.extension.dfr0151.name": "Relógio em tempo real DS1307",
    "gui.extension.dfr0151.description": "Saída, ano, mês, dia, semana, hora, minuto, segundo",
    "gui.extension.dfr0469.name": "Acesso ano, mês, dia, semana, hora, minuto, segundo",
    "gui.extension.dfr0469.description": "An accurate I2C real-time clock (RTC) with crystal compensation, inner chargeable battery. ",
    "gui.extension.dfr0126.name": "Módulo de análise de espectro",
    "gui.extension.dfr0126.description": "Analise as 7 frequências de som",
    "gui.extension.dfr0231.name": "Módulo NFC ",
    "gui.extension.dfr0231.description": "Usado para comunicação wireless de curto alcance, pode ler e escrever cartões",
    "gui.extension.sen0245.name": "Sensor de alcance a laser VL53L0X",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "Sensor analógico de luz ambiente",
    "gui.extension.dfr0026.description": "Medir a intensidade da luz ambiente",
    "gui.extension.dfr0027.name": "Sensor digital de vibração",
    "gui.extension.dfr0027.description": "Detectar sinais de vibração e emitir sinais digitais",
    "gui.extension.dfr0028.name": "Sensor digital de inclinação ",
    "gui.extension.dfr0028.description": "Pode ser usado como um interruptor digital, não pode medir o ângulo de inclinação",
    "gui.extension.dfr0029.name": "Módulo de botão grande digital",
    "gui.extension.dfr0029.description": "Pressione para emitir um nível alto, solte para emitir um nível baixo",
    "gui.extension.dfr0030.name": "Sensor de toque digital",
    "gui.extension.dfr0030.description": "Interruptor de toque, pode detectar corpo humano, metal, etc.",
    "gui.extension.dfr0033.name": "Sensor digital magnético",
    "gui.extension.dfr0033.description": "Detectar material magnético (íman), o alcance de detecção é de cerca de 3 cm",
    "gui.extension.dfr0034.name": "sensor analógico de som",
    "gui.extension.dfr0034.description": "Medir a intensidade do som no ambiente",
    "gui.extension.sen0132.name": "Sensor analógico de monóxido de carbono ",
    "gui.extension.sen0132.description": "Medir a concentração de gás monóxido de carbono, o intervalo é de 20 ～ 2000ppm",
    "gui.extension.dfr0051.name": "Módulo de detecção de tensão analógica",
    "gui.extension.dfr0051.description": "Medir a tensão DC abaixo de 25V",
    "gui.extension.dfr0052.name": "Sensor de vibração piezoelétrico analógico",
    "gui.extension.dfr0052.description": "Detectar sinais de vibração e emitir sinais analógicos",
    "gui.extension.dfr0058.name": "Sensor analógico de ângulo de rotação ",
    "gui.extension.dfr0058.description": "Regulação de tensão, baseada em potenciômetro de alta precisão",
    "gui.extension.dfr0061.name": "Módulo de Joystick ",
    "gui.extension.dfr0061.description": "(X,Y) saída analógica, (Z) 1 saída digitral e pode ser utilizado como controlo remoto ",
    "gui.extension.dfr0072.name": "Sensor Shiftout",
    "gui.extension.dfr0072.description": "Módulo de tubo Nixie que pode ser ligado em cascata usando a comunicação SPI",
    "gui.extension.dfr0563.name": "Sensor de Bateria",
    "gui.extension.dfr0563.description": "descrição da extensão 'sensor de bateria'",
    "gui.extension.dfr0553.name": "Módulo de conversão AD de 16 bits",
    "gui.extension.dfr0553.description": "O módulo de conversão de AD de 16 bits pode recolher e converter com precisão sinais analógicos",
    "gui.extension.dfr0588.name": "Sensor de temperatura e humidade SHT30",
    "gui.extension.dfr0588.description": "Sensor de temperatura e humidade SHT30",
    "gui.extension.dfr0076.name": "Sensor de chama",
    "gui.extension.dfr0076.description": "Detectar fontes de fogo ou de luz com comprimentos de onda entre 760 e 1100 nanômetros",
    "gui.extension.dfr0117.name": " Sensor Eeprom iic",
    "gui.extension.dfr0117.description": "Capacidade 32kb",
    "gui.extension.dfr0143.name": "Sensor de aceleração triaxial",
    "gui.extension.dfr0143.description": "Detecta a postura e a direção do movimento do objeto",
    "gui.extension.sen0018.name": "Sensor Digital por infravermelhos de deteção de movimento",
    "gui.extension.sen0018.description": "Detectar raios infravermelhos térmicos emitidos por pessoas e animais em movimento",
    "gui.extension.sen0114.name": "Sensor analógico de humidade do solo",
    "gui.extension.sen0114.description": "Detecta a humidade do solo, o valor de saída do sensor diminui quando há falta de água",
    "gui.extension.sen0121.name": "Sensor de vapor",
    "gui.extension.sen0121.description": "Detecta chuva, névoa, vapor de água",
    "gui.extension.sen0212.name": "Sensor de identificação de cor ",
    "gui.extension.sen0212.description": "Identifique a cor da superfície do objeto e faça a saída como valor RGB",
    "gui.extension.sen0253.name": "Sensor de atitude 10DOF",
    "gui.extension.sen0253.description": "Sensor de orientação absoluta inteligente BDO055 + BMP280 10DOF",
    "gui.extension.sen0290.name": "sensor de luminosidade",
    "gui.extension.sen0290.description": "Sensor de luminosidade inteligente",
    "gui.extension.sen0291.name": "Medidor de energia digital I2C",
    "gui.extension.huskylens.name": "Camera AI HUSKYLENS  ",
    "gui.extension.huskylens.description": "Sensor visual de inteligência artificial, que suporta funções de reconhecimento de rosto e aprendizagem",
    "gui.extension.ps2.name": "Controlador PS2",
    "gui.extension.ps2.description": "Controlador PS2",
    "gui.extension.sen0291.description": "Medição de alta precisão de tensão, corrente e potência",
    "gui.extension.sen0202.name": "Mini sensor 3D de reconhecimento de gestos ",
    "gui.extension.sen0202.description": "Identifique a direção do movimento do dedo e a rotação no sentido horário e anti-horário",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "Micro:bit placa de expansão de motor de bateria de lítio ",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "Deixe o dispositivo conectar-se à rede Wi-Fi",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "Deixe o dispositivo obter tempo de rede",
    "gui.extension.udp.name": "UDP",
    "gui.extension.udp.description": "Deixe o dispositivo comunicar com o dispositivo via UDP",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "Deixe o dispositivo solicitar informações via HTTP",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "Permitir que os dispositivos se comuniquem usando o protocolo MQTT",
    "gui.extension.speechRecognition.name": "Reconhecimento de voz",
    "gui.extension.speechRecognition.description": "Reconhecimento de voz",
    "gui.gui.loaded": "Carregado",
    "gui.gui.notLoaded": "Não Carregado",
    "gui.gui.cantFindYouWant": "Não encontras o que desejas?",
    "gui.gui.clickHere": "Clique aqui",
    "gui.gui.viewHelp": "Ver ajuda",
    "gui.gui.uploadToDev": "Carregar",
    "gui.gui.compileAndUpload": "Carregar após a compilação",
    "gui.gui.compileOnly": "Só compilado",
    "gui.gui.compileOpen": "Aberto após a compilação",
    "gui.gui.burnBootloader": "Grave o carregador de inicialização wireless",
    "gui.gui.arduinoRun": "Executar",
    "gui.gui.arduinoSave": "Gravar",
    "gui.gui.autoGenerated": "Gerar automaticamente",
    "gui.gui.manualEditing": "Edição manual",
    "gui.gui.codeBox": "Caixa de código",
    "gui.gui.moduleCode": "Código de módulo",
    "gui.menu.edit.undo": "Desfazer",
    "gui.menu.edit.redo": "Refazer",
    "gui.menu.edit.selectAll": "Selecionar tiudo",
    "gui.menu.edit.cut": "Cortar",
    "gui.menu.edit.copy": "Copiar",
    "gui.menu.edit.paste": "Colar",
    "gui.menu.edit.codeStyle": "Estilo de código",
    "gui.menu.edit.fontSize": "Tamanho de letra",
    "gui.menu.edit.clearCache": "Limpar Cache",
    "gui.menu.edit.maxReset": "Reiniciar Max para parâmetros de fábrica",
    "gui.gui.serial": "Serial",
    "gui.gui.openSerial": "Abrir Serial",
    "gui.gui.closeSerial": "Fechar Serial",
    "gui.gui.close": "Fechar",
    "gui.gui.open": "Abrir",
    "gui.gui.clearOutput": "Limpaída",
    "gui.gui.scroll": "Deslizar pela página",
    "gui.gui.send": "Enviar",
    "gui.gui.baud": "Baud",
    "gui.gui.noLineEnd": "Sem fim de linha",
    "gui.gui.newLine": "Quebra de linha",
    "gui.gui.carriageReturn": "Retorno de carro",
    "gui.gui.bothNLandCR": "Retorno de carro e avanço de linha",
    "gui.progress.burning": "Enviando",
    "gui.progress.compiling": "Compilando",
    "gui.burner.compileError": "Falha na compilação",
    "gui.burner.linkError": "Falha no link",
    "gui.burner.generateHexError": "Falha ao gerar .hex",
    "gui.burner.generateBinError": "Falha ao gerar .bin",
    "gui.burner.burnError": "Falha na gravação",
    "gui.burner.eraseError": "Falha ao apagar",
    "gui.burner.findLeonardoPortError": "Não é possível encontrar a porta de série do bootlaoder do Leonardo",
    "gui.burner.noMicrobit": "O dispositivo atualmente conectado não é o micro:bit, conecte micro:bit",
    "gui.burner.swdCfgError": "Falha ao gravar no cmsis-dap.cfg",
    "gui.burner.UDiskPopUp": "UDIsk pops up, aguarde alguns segundos e tente novamente",
    "gui.burner.UDiskWriteError": "Erro de gravação do Udisk",
    "gui.burner.printSizeError": "Não foi possível determinar o tamanho do programa",
    "gui.burner.printSize": "O projeto usa [% TEXTSIZE] bytes e ocupa ([% TEXTSIZEPRE]) espaço de armazenamento do programa, deixando [% TEXTSIZESUR] bytes, sendo o máximo [% TEXTSIZEMAX] bytes.\r\nAs variáveis ​​globais usam [% DATASIZE] bytes, ([% DATASIZEPRE]) memória dinâmica, deixando [% DATASIZESUR] bytes para variáveis ​​locais, sendo o máximo [% DATASIZEMAX] bytes.\r\n",
    "gui.progress.compileSuccess": "compilação bem sucedida",
    "gui.progress.compileProgress": "Progresso da compilação",
    "gui.progress.uploadProgress": "Progresso do carregamento",
    "gui.progress.uploadSuccess": "Sucesso no carregamento",
    "gui.progress.errMsg": "!Erro de upload, veja a pequena janela preta no canto inferior direito da mensagem de erro",
    "gui.howtos.spin-around.name": "Girar",
    "gui.howtos.spin.step_dragTurn": "Arraste e solteo bloco [gira] ",
    "gui.howtos.spin.step_clickTurn": "Clicar no Bloco para executar",
    "gui.howtos.spin.step_clickControl": "Clicar na categoria [Controlo] ",
    "gui.howtos.spin.step_dragForever": "Arraste e solte o bloco [para sempre] ",
    "gui.howtos.spin.step_clickForever": "Clicar no Bloco para executar",
    "gui.howtos.spin.step_changeColor": "Adiciona um bloco [altera efeito de cor] ",
    "gui.howtos.say-hello.name": "Diz Olá",
    "gui.howtos.say-hello.step_addSprite": "Adiciona novo ator",
    "gui.howtos.say-hello.step_clickLooks": "Clicar na categoria  [Aparência] ",
    "gui.howtos.say-hello.step_dragSay": "Aarraste e solte um bloco [diz] ",
    "gui.howtos.say-hello.step_clickSay": "Clicar no Bloco para executar",
    "gui.howtos.say-hello.step_anotherSay": "Arraste e solte outro bloco [diz] ",
    "gui.howtos.say-hello.step_editSay": "Diz algo diferente",
    "gui.howtos.say-hello.step_clickStack": "Clicar no Bloco para executar",
    "gui.howtos.run-away.name": "Fugir",
    "gui.howtos.run-away.step_dragGoTo": "Arraste e solte o bloco [ir para posição aleatória] ",
    "gui.howtos.run-away.step_clickGoTo": "Clicar no Bloco para executar",
    "gui.howtos.run-away.step3": "Clicar na categoria [Eventos] ",
    "gui.howtos.run-away.step_addWhenClicked": "Adicionar um bloco “quando este ator for clicado”",
    "gui.howtos.run-away.step_clickSprite": "Clicar no ator para executar",
    "gui.howtos.run-away.step_addSound": "Adicionar um bloco “começar som” ",
    "gui.howtos.pick-up-apple.name": "Jogo de apanhar maças ",
    "gui.howtos.pick-up-apple.step_1": "Mudar para a interface de interpretação e execução",
    "gui.howtos.pick-up-apple.step_2": "Mudar Hardware",
    "gui.howtos.pick-up-apple.step_3": "Ligar Dispositivo",
    "gui.howtos.pick-up-apple.step_4": "Calibração da Bússola",
    "gui.howtos.pick-up-apple.step_5": "Abrir programas de demonstração ",
    "gui.howtos.pick-up-apple.step_6": "Jogo de apanhar maças ",
    "gui.howtos.move-left-right.name": "micro:bit e Meow",
    "gui.howtos.move-left-right.step_1": "Criar novo projeto",
    "gui.howtos.move-left-right.step_2": "Largar Bloco1",
    "gui.howtos.move-left-right.step_3": "Largar Bloco2",
    "gui.howtos.move-left-right.step_4": "Mover esquerda e direita",
    "gui.howtos.install-driver.name": "Instalar Driver",
    "gui.howtos.transform-expression.name": "Sorrir ou Chorar ",
    "gui.howtos.transform-expression.step_1": "Alterne para a interface de execução de upload",
    "gui.howtos.transform-expression.step_2": "Mudar hardware",
    "gui.howtos.transform-expression.step_3": "Arrastar Bloco1",
    "gui.howtos.transform-expression.step_4": "Arrastar Bloco2",
    "gui.howtos.transform-expression.step_5": "Arrastar Bloco3",
    "gui.howtos.transform-expression.step_6": "Transformarpara Sorrir ou Chorar",
    "gui.howtos.lot.name": "IOT (Internet of Things)",
    "gui.howtos.lot.step_1": "Selecionar programas de exemplo ",
    "gui.howtos.lot.step_2": "Programa de gravação",
    "gui.howtos.lot.step_3": "Internet Of Things (IOT)",
    "gui.howtos.touch-pin.name": "Pin touch",
    "gui.howtos.touch-pin.step_1": "Alterne para a interface de execução de upload",
    "gui.howtos.touch-pin.step_2": "Seleciona a rotina",
    "gui.howtos.touch-pin.step_3": "Programa de gravação",
    "gui.howtos.touch-pin.step_4": "Pin touch",
    "gui.howtos.save-witch.name": "Salvar Bruxa",
    "gui.howtos.save-witch.step_1": "Mudar para a execução em tempo real",
    "gui.howtos.save-witch.step_2": "Selecione 'micro:bit'nas expansões",
    "gui.howtos.save-witch.step_3": "Selecione  'Salvar Bruxa' nos programas de exemplo",
    "gui.howtos.save-witch.step_4": "Ligue o dispositivo, selecione micro:bit e espere por uma ligação bem sucedida",
    "gui.howtos.save-witch.step_5": "Gire micro:bit até que o LED apague quando a informação [calibrar o dispositivo] aparecer.",
    "gui.howtos.save-witch.step_6": "Clique na bandeira verde e abane o braço para interagir com a animação ",
    "gui.howtos.microbit-touch.name": "Prima Para Sorrir ou Chorar",
    "gui.howtos.microbit-touch.step_1": "Mudar para Modo de Código",
    "gui.howtos.microbit-touch.step_2": "Clique em 'Learning' para abrir os programas de exemplo e selecione 'Pin touch'",
    "gui.howtos.microbit-touch.step_3": "Ligue a porta COM correspondente do dispositivo e clique em 'Upload to Device'",
    "gui.howtos.microbit-touch.step_4": "Pressione diferentes teclas para mostra expressões diferentes ",
    "gui.howtos.microbit-calibration.name": "Calibração do micro:bit ",
    "gui.howtos.microbit-calibration.step_1": "Gire a placa micro: bit i, e as luzes LED internas acenderão sucessivamente.",
    "gui.howtos.microbit-calibration.step_2": "Até que as luzes LED mais externas estejam acesas (como mostrado abaixo), um sorriso será exibido depois que a calibração esteja concluída.",
    "gui.extension.sen0251.name": "Sensor de Pressão Barométrica BMP388",
    "gui.extension.sen0251.description": "Função de deteção de Temperatura, Pressão Atmosférica e Altitude",
    "gui.extension.sen0206.name": "Sensor de temperatura por Infravermelhos Sem Contacto",
    "gui.extension.sen0206.description": "Medição sem contato por infravermelho distante do objeto ou da temperatura ambiente",
    "gui.gui.recentlyEdited": "Recentemente editada",
    "gui.extension.weather.name": "Obter informação meteorológica",
    "gui.extension.weather.description": "Aqui é necessário um módulo Wi-Fi para obter informação meteorológica",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "Operar a base a rede de dados TinyWebDB , pode ser usado com APP Inventor ",
    "gui.extension.pictureai.name": "AI reconhecimento de imagem",
    "gui.extension.pictureai.description": "Fazer qualquer coisa maravilhosa com imagem AI",
    "gui.monitor.listMonitor.listLength": "comprimento {comprimento}",
    "gui.monitor.listMonitor.empty": "(vazio)",
    "gui.costumeTab.backdrop": "fundo",
    "gui.costumeTab.costume": "traje",
    "gui.loadProject.error": "Carregar erro de projeto",
    "gui.fileSystem.fileCatalogue": "catálogo",
    "gui.fileSystem.boardFiles": "Arquivos da placa",
    "gui.fileSystem.computerFiles": "Computador de bordo",
    "gui.fileSystem.newFile": "Novo ficheiro",
    "gui.fileSystem.newFolder": "Nova pasta",
    "gui.fileSystem.deleteFolder": "Apagar pasta",
    "gui.fileSystem.openFolder": "Abrir pasta",
    "gui.fileSystem.openInEditor": "Abrir no editor",
    "gui.fileSystem.runRightNow": "executar agora",
    "gui.fileSystem.stopRun": "parar execução",
    "gui.fileSystem.setBoot": "inicializar",
    "gui.fileSystem.deleteForever": "Apagar para sempre",
    "gui.fileSystem.saveAs": "Gravar como",
    "gui.fileSystem.undoAllEdits": "cancelar todas as edições",
    "gui.fileSystem.copyToComputer": "copiar para o computador",
    "gui.fileSystem.uploadToBoard": "Carregue para a placa",
    "gui.fileSystem.uploadAndRun": "Carregue e execute",
    "gui.wifi.pwdNotLegal": "! Erro: A palavra passe está no formato errado. A palavra passe de Wi-Fidve conter 8-20 digitos ou números",
    "gui.spreadmaqueen.perror": "!Erro: a lâmpada LED de Pin P12 não está disponível. Por favor, selecione a Lâmpada Led do Pin P8.",
    "gui.gui.viewConflict": "Ver conflito",
    "gui.gui.clickViewHelp": "Ajuda",
    "gui.gui.conflict": "Conflito",
    "gui.gui.conflictPrompt": "Aviso de conflito",
    "gui.gui.clickPrompt": "Clique para destacar blocos, Clique duas vezes para localizar blocos",
    "music.categoryName": "Música",
    "translate.categoryName": "Traduzir",
    "pen.categoryName": "Caneta",
    "pen.changeColorParam": "adiciona a [COLOR_PARAM] da tua caneta o valor [VALUE]",
    "pen.changeHue": "adiciona [HUE] à cor da tua caneta",
    "pen.changeShade": "adiciona [SHADE] ao tom da tua caneta",
    "pen.changeSize": "adiciona [SIZE] à espessura da tua caneta",
    "pen.clear": "apaga tudo do palco",
    "pen.colorMenu.brightness": "o brilho",
    "pen.colorMenu.color": "o matiz",
    "pen.colorMenu.saturation": "a saturação",
    "pen.colorMenu.transparency": "a transparência",
    "pen.penDown": "baixa a tua caneta",
    "pen.penUp": "levanta a tua caneta",
    "pen.setColor": "altera a cor da tua caneta para [COLOR]",
    "pen.setColorParam": "altera [COLOR_PARAM] da tua caneta para [VALUE]",
    "pen.setHue": "altera a cor da tua caneta para [HUE]",
    "pen.setShade": "altera o tom da tua caneta para [SHADE]",
    "pen.setSize": "altera a espessura da tua caneta para [SIZE]",
    "pen.stamp": "carimba-te",
    "music.changeTempo": "adiciona [TEMPO] bpm ao teu andamento",
    "music.drumBass": "(2) Bombo",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabaça",
    "music.drumClaves": "(9) Clavas",
    "music.drumClosedHiHat": "(6) Pratos de Choque Fechados",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Caneca",
    "music.drumCrashCymbal": "(4) Prato de Ataque",
    "music.drumCuica": "(18) Cuíca",
    "music.drumGuiro": "(16) Reco-Reco",
    "music.drumHandClap": "(8) Palmas",
    "music.drumOpenHiHat": "(5) Pratos de Choque Abertos",
    "music.drumSideStick": "(3) Golpe no Fuste da Tarola",
    "music.drumSnare": "(1) Caixa",
    "music.drumTambourine": "(7) Pandeireta",
    "music.drumTriangle": "(12) Triângulo",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Bloco Sonoro",
    "music.getTempo": "o andamento",
    "music.instrumentBass": "(6) Baixo",
    "music.instrumentBassoon": "(14) Fagote",
    "music.instrumentCello": "(8) Violoncelo",
    "music.instrumentChoir": "(15) Coro",
    "music.instrumentClarinet": "(10) Clarinete",
    "music.instrumentElectricGuitar": "(5) Guitarra Eléctrica",
    "music.instrumentElectricPiano": "(2) Piano Eléctrico",
    "music.instrumentFlute": "(12) Flauta Transversal",
    "music.instrumentGuitar": "(4) Guitarra",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Caixa de Música",
    "music.instrumentOrgan": "(3) Órgão",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxofone",
    "music.instrumentSteelDrum": "(18) Tambor de Aço",
    "music.instrumentSynthLead": "(20) Sintetizador Melódico",
    "music.instrumentSynthPad": "(21) Sintetizador de Fundo",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibrafone",
    "music.instrumentWoodenFlute": "(13) Flauta de Madeira",
    "music.midiPlayDrumForBeats": "toca a percussão [DRUM] durante [BEATS] tempos",
    "music.midiSetInstrument": "altera o teu instrumento para [INSTRUMENT]",
    "music.playDrumForBeats": "toca a percussão [DRUM] durante [BEATS] tempos",
    "music.playNoteForBeats": "toca a nota [NOTE] durante [BEATS] tempos",
    "music.restForBeats": "faz uma pausa de [BEATS] tempos",
    "music.setInstrument": "altera a teu instrumento para [INSTRUMENT]",
    "music.setTempo": "altera o teu andamento para [TEMPO] bpm",
    "videoSensing.categoryName": "Sensores de Vídeo",
    "videoSensing.direction": "a direcção",
    "videoSensing.motion": "o movimento",
    "videoSensing.off": "desligado",
    "videoSensing.on": "ligado",
    "videoSensing.onFlipped": "ligado e espelhado",
    "videoSensing.setVideoTransparency": "altera a transparência do vídeo para [TRANSPARENCY] %",
    "videoSensing.sprite": "ti mesmo",
    "videoSensing.stage": "o palco",
    "videoSensing.videoOn": "[ATTRIBUTE] do vídeo em [SUBJECT]",
    "videoSensing.videoToggle": "altera o estado do vídeo para [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "Quando o movimento do vídeo exceder [REFERENCE]",
    "translate.translateBlock": "a tradução de [WORDS] para [LANGUAGE]",
    "translate.defaultTextToTranslate": "olá",
    "translate.viewerLanguage": "a língua",
    "text2speech.speakAndWaitBlock": "fala [WORDS]",
    "text2speech.setVoiceBlock": "altera a voz para [VOICE]",
    "text2speech.setLanguageBlock": "altera a língua para [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "guincho",
    "text2speech.giant": "gigante",
    "text2speech.kitten": "gatinho",
    "text2speech.defaultTextToSpeak": "Olá!",
    "speech.whenIHear": "Quando eu ouvir [PHRASE]",
    "speech.listenAndWait": "ouve e espera",
    "speech.defaultWhenIHearValue": "vamos a isso",
    "sensor.categoryName": "Sensor",
    "actuator.categoryName": "Atuador ",
    "communicate.categoryName": "Comunicação",
    "display.categoryName": "Ecrã",
    "extendFunction.categoryName": "Função",
    "internetService.categoryName": "Internet",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "handpy",
    "spread.categoryName": "Placa de Expansão",
    "speechRecognition.categoryName": "Reconhecimento de Voz",
    "thirdExpansion.categoryName": "Biblioteca do utilizador",
    "gui.blocklyText.blockTitle.eventBlock": "Programa de eventos",
    "gui.blocklyText.blockTitle.dotScreen": "Ecrã de matriz de pontos",
    "gui.blocklyText.blockTitle.music": "musica",
    "gui.blocklyText.blockTitle.onBoardSensor": "Sensor na placa",
    "gui.blocklyText.blockTitle.pinOperation": "Operação de Pins",
    "gui.blocklyText.blockTitle.wirelessCommunication": "comunicação wireless ",
    "gui.blocklyText.blockTitle.serialportOperation": "operação de serialport",
    "gui.blocklyText.blockTitle.systemResource": "recurso de sistema",
    "gui.blocklyText.blockTitle.screenDisplay": "Ecrâ de exibição",
    "gui.blocklyText.blockTitle.ledControl": "controlo de led ",
    "gui.blocklyText.blockTitle.functionalModule": " módulo funcional",
    "gui.blocklyText.blockTitle.movement": "movimento",
    "gui.blocklyText.blockTitle.expression": "expressão",
    "gui.blocklyText.blockTitle.light": "Luz",
    "gui.blocklyText.blockTitle.dance": "dança",
    "gui.blocklyText.blockTitle.sensor": "sensor",
    "gui.blocklyText.blockTitle.motorControl": "controlo de motor ",
    "gui.blocklyText.blockTitle.kitSensor": "kit sensor",
    "gui.blocklyText.blockTitle.kitActuator": "kit atuador",
    "gui.blocklyText.blockTitle.commonModule": "módulo comum",
    "gui.blocklyText.blockTitle.infraredCommunication": "Comunicação de infravermelhos",
    "gui.blocklyText.blockTitle.mp3Module": "módulo mp3 ",
    "gui.blocklyText.blockTitle.ws2812RGB": "Luz RGB WS2812 ",
    "gui.blocklyText.blockTitle.LCDDisplay": "Ecrã LCD ",
    "gui.blocklyText.blockTitle.digitalTube": "Tubo Digital",
    "gui.blocklyText.blockTitle.oledDisplay": "ecrã OLED ",
    "gui.blocklyText.blockTitle.onBoardSource": "Recursos da placa",
    "gui.blocklyText.blockTitle.rgbControl": "Controlo de Luz RGB ",
    "gui.blocklyText.blockTitle.maxbotResource": "Recurso max:bot ",
    "gui.blocklyText.blockTitle.lineInspection": "Inspeção de linha",
    "gui.blocklyText.blockTitle.outerSensor": "Sensor externo",
    "gui.blocklyText.blockTitle.consolePrint": "Saída da consola ",
    "gui.blocklyText.romeo.d1ProgramStart": "Inicia o programa do robô D1",
    "gui.blocklyText.romeo.readdigital": "Leia o valor de [PIN] [MODULE]",
    "gui.blocklyText.romeo.buttonModule": "Módulo de botão",
    "gui.blocklyText.romeo.digitalSensor": "Sensor Digital ",
    "gui.blocklyText.romeo.collisionSensor": "Sensor de Colisão",
    "gui.blocklyText.romeo.lineSensor": "Sensor de linha",
    "gui.blocklyText.romeo.infraredSwitch": "Interruptor infravermelho",
    "gui.blocklyText.romeo.vibrationSensor": "Sensor de vibração",
    "gui.blocklyText.romeo.readAnalog": "Ler pin [PIN] [SENSOR] ",
    "gui.blocklyText.romeo.lightSensor": "Sensor de Luz",
    "gui.blocklyText.romeo.anologSensor": "Sensor Analógico",
    "gui.blocklyText.romeo.soundSensor": "Sensor de som",
    "gui.blocklyText.romeo.soilMoistureSensor": "Sensor de humidade do solo",
    "gui.blocklyText.romeo.frameSensor": "Sensor de chama",
    "gui.blocklyText.romeo.open": "Ligar",
    "gui.blocklyText.romeo.close": "Desligar",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] do pino [PIN]",
    "gui.blocklyText.romeo.ledLights": "Luzes LED ",
    "gui.blocklyText.romeo.digitalActuator": "Atuador digital",
    "gui.blocklyText.romeo.relay": "Relé",
    "gui.blocklyText.romeo.fan": "Ventoinha",
    "gui.blocklyText.romeo.writeAnalog": "Defina o pino [PIN] [MODULE] como [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "Atuador analógico",
    "gui.blocklyText.romeo.fanModule": "Módulo do ventoinha",
    "gui.blocklyText.romeo.robotSpeedMove": "O robô tem velocidade [SPEED] [DIR]",
    "gui.blocklyText.romeo.forward": "mover em frente",
    "gui.blocklyText.romeo.backward": "mover para trás",
    "gui.blocklyText.romeo.turnLeft": "Virar à esquerda",
    "gui.blocklyText.romeo.turnRight": "Virar à direita",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "Defina o motor [MOTOR] para a velocidade [SPEED] [DIR]",
    "gui.blocklyText.romeo.interface": "Interface",
    "gui.blocklyText.romeo.motorForward": "Em frente",
    "gui.blocklyText.romeo.motorReverse": "Para trás",
    "gui.blocklyText.romeo.stopMotor": "Robô (motores M1 e M2) parad.",
    "gui.blocklyText.romeo.readUltrasonic": "Leitura da distância ultrassônica (cm) dispara o eco [TRIG] [ECHO]",
    "gui.blocklyText.romeo.lm35": "Leia a temperatura do pino [PIN] LM35 (℃)",
    "gui.blocklyText.romeo.ds18b20": "Leia [PIN] Temperatura do pino DS18B20 (℃)",
    "gui.blocklyText.romeo.pressInfrared": "O PIN infravermelho [PIN] recebeu uma pressão [BUTTON]?",
    "gui.blocklyText.romeo.volumePlus": "Volume+",
    "gui.blocklyText.romeo.switch": "Interruptor",
    "gui.blocklyText.romeo.volumeMinus": "Volume-",
    "gui.blocklyText.romeo.startPause": "Começar/Pausa",
    "gui.blocklyText.romeo.up": "Cima",
    "gui.blocklyText.romeo.down": "Baixo",
    "gui.blocklyText.romeo.dataReadable": "Existem dados para ler na porta de série?",
    "gui.blocklyText.romeo.readSerialData": "Ler dados de série",
    "gui.blocklyText.romeo.timer": "Tempo de execução do sistema (ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "pin [PIN] toca no de trumpete [TONE] durante [BEAT] pulsação",
    "gui.blocklyText.romeo.half": "Mínima",
    "gui.blocklyText.romeo.quarter": "Semínima",
    "gui.blocklyText.romeo.oneInEighth": "Colcheia",
    "gui.blocklyText.romeo.wholeBeat": "Semibreve",
    "gui.blocklyText.romeo.doubleBeat": "Breve",
    "gui.blocklyText.romeo.stop": "Parar",
    "gui.blocklyText.romeo.setServo": "Ajuste o Pin servo [PIN] para o grau [DEGREE]",
    "gui.blocklyText.romeo.segment": "Defina o LED digital de oito segmentos (pinos D6-D13) para exibir [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "Série [WAY] [STRING] [LINE]",
    "gui.blocklyText.romeo.originalOutput": "Saída original",
    "gui.blocklyText.romeo.stringOutput": "string",
    "gui.blocklyText.romeo.hexOutput": "HEX",
    "gui.blocklyText.romeo.wrap": "Wrap",
    "gui.blocklyText.romeo.noWrap": "No-Wrap",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "Ler dados de série [READTYPE]",
    "gui.blocklyText.romeo.int": "int",
    "gui.blocklyText.romeo.float": "float",
    "gui.blocklyText.romeo.setBaudRate": "Defina a taxa de transmissão da porta de série [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "Servo [INDEX] Ângulo [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "Len Pin [PIN] Modo pulse width [LEVEL] Tempo limite [TIMEOUT] (us)",
    "gui.blocklyText.romeo.low": "BAIXO",
    "gui.blocklyText.romeo.high": "ALTO",
    "gui.blocklyText.romeo.noSound": "Sem Som",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino começa",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno começa",
    "gui.blocklyText.esp32.displayInLine": "O ecrã exibe o texto [TEXT] na linha [LINE]",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "limpar cache na linha [LINE]",
    "gui.blocklyText.esp32.displayClearInLine": "limpar na linha [LINE]",
    "gui.blocklyText.esp32.buzzPinC": "P6  (Onboard buzzer)",
    "gui.blocklyText.esp32.buzzerRedirect": "Buzzer redirecionado para pin [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "TUDO",
    "gui.blocklyText.esp32.setTouchThreshold": "Defina o limite de toque da tecla [PIN] para [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "Ler valor de toque da tecla [PIN]  ",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 começa",
    "gui.blocklyText.esp32.btnIsPressed": "[BUTTON] botão [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "quando o botão [REFERENCE] [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "Quando a tecla [REFERENCE] é tocada por [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "Quando [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "O botão de toque [PIN] é [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "Parar a reprodução em segundo plano",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "toca nota [NOTE] em segundo plano",
    "gui.blocklyText.esp32.setBuzzerFreq": "Tocar nota [NOTE] [BEAT] pulsação",
    "gui.blocklyText.esp32.getSoundIntensity": "Ler a intensidade do som do microfone",
    "gui.blocklyText.esp32.readBrightness": "Ler a intensidade da luz ambiente",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] exibe [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "vermelho [RED] verde [GREEN] azul [BLUE]",
    "gui.blocklyText.esp32.acceleration": "Leia o valor da aceleração (mg)[TYPE]",
    "gui.blocklyText.esp32.displayInXY": "exibir [TEXT] em X: [X] Y: eixo [Y], visualizar [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "O ecrã exibe [COLOR] (Clear Screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "write cache screen color [COLORMPYTHON]",
    "gui.blocklyText.esp32.black": "preto completo",
    "gui.blocklyText.esp32.white": "branco completo",
    "gui.blocklyText.esp32.lineDrawing": "Desenhar linha Ponto inicial x1: [X1] y1: [Y1] Ponto final x2: [X2] y2: [Y2]",
    "gui.blocklyText.esp32.connected": "ligado",
    "gui.blocklyText.esp32.disconnected": "desligado",
    "gui.blocklyText.esp32.rectangleDrawing": "Desenha um retângulo [FILL] Ponto inicial x: [X] y: [Y] Largura: [WIDTH] Altura: [HEIGHT]",
    "gui.blocklyText.esp32.screenDisplay": "A exibição da cache do ecrã tem efeito",
    "gui.blocklyText.esp32.circleDrawing": "Desenhe um círculo [FILL] Centro x: [X] y: [Y] Raio: [RADIUS]",
    "gui.blocklyText.esp32.fill": "preencher",
    "gui.blocklyText.esp32.notFill": "não preencher",
    "gui.blocklyText.esp32.pointDrawing": "desenhar ponto em x:[X] y:[Y]",
    "gui.blocklyText.esp32.setLineWidth": "definir espessura de linha para [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": " pin digital [PIN] saída [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "ler pin digital [PIN]",
    "gui.blocklyText.esp32.analogWrite": "Pin analógico [PIN] saída [VALUE]",
    "gui.blocklyText.esp32.analogRead": "ler Pin analógico [PIN]",
    "gui.blocklyText.esp32.serialPort0": "serial0",
    "gui.blocklyText.esp32.serialPort1": "serial1",
    "gui.blocklyText.esp32.serialPort2": "serial2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX] a taxa de transmissão [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "Ecrã exibe imagem [IMAGE] em x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "escreve a imagem da cache [IMAGE] em x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "pressionado",
    "gui.blocklyText.esp32.loosened": "solto",
    "gui.blocklyText.esp32.all": "TUDO(-1)",
    "gui.blocklyText.esp32.setBaud": "Defina a taxa de transmissão da porta de série 0 [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "Leia float [READTYPE] em [SERIAL]",
    "gui.blocklyText.esp32.setBrightness": "define brilho do LED [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "luminosidade do LED",
    "gui.blocklyText.esp32.print": "imprimir [DATA]",
    "gui.blocklyText.esp32.TiltForward": "Inclinar para a frente",
    "gui.blocklyText.esp32.TiltBack": "Inclinar para trás",
    "gui.blocklyText.esp32.TiltLeft": "Inclinar para a esquerda",
    "gui.blocklyText.esp32.TiltRight": "Inclinar para a direita",
    "gui.blocklyText.esp32.ScreenUp": "ecrã para cima",
    "gui.blocklyText.esp32.ScreenDown": "face para baixo",
    "gui.blocklyText.esp32.Shake": "Abanar",
    "gui.blocklyText.esp32.isGesture": "O gesto atual é [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "Desligar [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "TUDO",
    "gui.blocklyText.esp32.rgbdisable.No.0": "No.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "No.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "No.2",
    "gui.blocklyText.esp32.playMusic": "tocar música [MUSIC] repetindo [REPEAT]",
    "gui.blocklyText.esp32.Once": "Tocar uma vez",
    "gui.blocklyText.esp32.Forever": "Tocar para sempre",
    "gui.blocklyText.esp32.OnceInBackground": "Tocar uma vez em segundo plano",
    "gui.blocklyText.esp32.ForeverInBackground": "Tocar em segundo plano Tocar uma vez em segundo plano",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 começa",
    "gui.blocklyText.mega2560.digitalRead": "Ler Pin digital [PIN]",
    "gui.blocklyText.mega2560.analogRead": "ler Pin analógico [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "pwm pin [PIN] saída [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "Pin digital [PIN] saída [VALUE]",
    "gui.blocklyText.mega2560.level.low": "Nível baixo",
    "gui.blocklyText.mega2560.level.high": "Nível alto",
    "gui.blocklyText.mega2560.irqRead": "Ler Pin digital do módulo receptor infravermelho [PIN]",
    "gui.blocklyText.mega2560.ultrasonicRead": "Leia o sensor ultrassônico trig como [TRIG] echo  [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "Defina o tom da campainha da buzina pin [PIN] para [TONE] batida para [BEAT]",
    "gui.blocklyText.mega2560.harf": "Mínima",
    "gui.blocklyText.mega2560.quarter": "Semínima",
    "gui.blocklyText.mega2560.oneInEighth": "Colcheia",
    "gui.blocklyText.mega2560.wholeBeat": "Semibreve",
    "gui.blocklyText.mega2560.doubleBeat": "Breve",
    "gui.blocklyText.mega2560.stop": "Parar",
    "gui.blocklyText.mega2560.servoSet": "Defina o pin [PIN] servo para grau [DEGREE]",
    "gui.blocklyText.mega2560.setBaud": "Definir taxa de transmissão [SERIAL] [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "Leia um [READTYPE] de [SERIAL]",
    "gui.blocklyText.mega2560.serialPort0": "serial0",
    "gui.blocklyText.mega2560.serialPort1": "serial1",
    "gui.blocklyText.mega2560.serialPort2": "serial2",
    "gui.blocklyText.mega2560.serialPort3": "serial3",
    "gui.blocklyText.mega2560.dataReadable": "[SERIAL] Há dados para ler?",
    "gui.blocklyText.mega2560.readSerialData": "Ler dados [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "Saída de string",
    "gui.blocklyText.mega2560.originalOutput": "saída original ",
    "gui.blocklyText.mega2560.hexOutput": "Saída HEX",
    "gui.blocklyText.mega2560.wrap": "Wrap",
    "gui.blocklyText.mega2560.noWrap": "No-Wrap",
    "gui.blocklyText.mega2560.readPinLevel": "Ler pin [PIN] Modo pulse widt é [LEVEL] Tempo limite [TIMEOUT] (us)",
    "gui.blocklyText.mega2560.readTimer": "Tempo de funcionamento do sistema (ms)",
    "gui.blocklyText.beetleProMini.Starts": "Programa principal do Beetle Pro Mini",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo começa",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 começa",
    "gui.blocklyText.arduino.setDigital": "Defina a saída do pin digital [PIN] para [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "Ler pin digital [PIN]",
    "gui.blocklyText.arduino.readSimilation": "Ler pin analógico [PIN]",
    "gui.blocklyText.arduino.setPwm": "Definir saída pwm pin [PIN] [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "Ler distância ultrassônica (cm) trig [TRIG] eco [ECHO]",
    "gui.blocklyText.arduino.trumpet": "Defina o tom da buzina pin [PIN] para [TONE] batida para [BEAT]",
    "gui.blocklyText.arduino.serialSendStr": "Porta de sériel [WAY] [STRING] [LINE]",
    "gui.blocklyText.arduino.originalOutput": "Saída original",
    "gui.blocklyText.arduino.stringOutput": "Saída de string",
    "gui.blocklyText.arduino.hexOutput": "Saída HEX",
    "gui.blocklyText.arduino.wrap": "Wrap",
    "gui.blocklyText.arduino.noWrap": "No-Wrap",
    "gui.blocklyText.arduino.readInfrared": "Ler pin digital do módulo receptor infravermelho [PIN]",
    "gui.blocklyText.arduino.setBaud": "Defina a taxa de transmissão da porta de série [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "Ler pin [PIN] Modo pulse width [LEVEL] Timeout [TIMEOUT] (us)",
    "gui.blocklyText.arduino.noSound": "sem som",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Arduino Nano começa",
    "gui.blocklyText.vortex.vortexStart": "programa do Vortex robot começa",
    "gui.blocklyText.vortex.vortexMoveSpeed": "Robôs Vortex na velocidade [SPEED] [DIRECTION]",
    "gui.blocklyText.vortex.forward": "mover em frente",
    "gui.blocklyText.vortex.backup": "mover para trás",
    "gui.blocklyText.vortex.turnLeft": "Virar à esquerda",
    "gui.blocklyText.vortex.turnRight": "Virar à direita",
    "gui.blocklyText.vortex.setMotor": "Defina o motor [MOTOR] para a velocidade [SPEED] [DIRECTION]",
    "gui.blocklyText.vortex.leftWheel": "esquerda",
    "gui.blocklyText.vortex.rightWheel": "direita",
    "gui.blocklyText.vortex.setExpression": "Defina a cor da expressão [EXPRESSION] para [COLOR]",
    "gui.blocklyText.vortex.ledLights": "Defina [DIRECTION] [LIGHT] [SECOND] para mudar para [COLOR] em segundos",
    "gui.blocklyText.vortex.all": "Tudo",
    "gui.blocklyText.vortex.rightAhead": "Em frente",
    "gui.blocklyText.vortex.leftRear": "Esquerda traseira",
    "gui.blocklyText.vortex.rightRear": "Direita traseira",
    "gui.blocklyText.vortex.rightAstern": "Trás",
    "gui.blocklyText.vortex.topLights": "luz superior",
    "gui.blocklyText.vortex.bottomLights": "luz inferior",
    "gui.blocklyText.vortex.vortexStop": "Vortex deixa de mover ",
    "gui.blocklyText.vortex.setVolume": "define volume para [VALUE]",
    "gui.blocklyText.vortex.playMusic": "toca a música [VALUE] ",
    "gui.blocklyText.vortex.stopMusic": "para de tocar a música ",
    "gui.blocklyText.vortex.startDance": "começar a dança [VALUE] ",
    "gui.blocklyText.vortex.stopDance": "para de dançar",
    "gui.blocklyText.vortex.barrierDetection": "obstáculo em frente?",
    "gui.blocklyText.vortex.readSensor": "Leia o valor do sensor de escala de cinzas [SENSOR]",
    "gui.blocklyText.vortex.setSensor": "Defina a alteração do monitor do sensor de tons de cinza para [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "O lado do sensor de tons de cinza [SENSOR] encontra o preto?",
    "gui.blocklyText.vortex.positiveFrontLeft": "frente esquerda",
    "gui.blocklyText.vortex.frontLeft": "frente esquerda",
    "gui.blocklyText.vortex.positiveFrontRight": "frente direita",
    "gui.blocklyText.vortex.frontRight": "frente direita",
    "gui.blocklyText.vortex.backRight": "trazeira direita",
    "gui.blocklyText.vortex.backLeft": "trazeira esquerda ",
    "gui.blocklyText.vortex.initialOldPCB": "inicializar para versão antiga (blue PCB)",
    "gui.blocklyText.microbit.microbitStarts": "micro:bit começa",
    "gui.blocklyText.microbit.interruptExcute": "Interromper execução do modo pin [PIN] [MODE]",
    "gui.blocklyText.microbit.cancleInterrupt": "Cancelar interrupção pin [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "quando receber informação wireless ",
    "gui.blocklyText.microbit.whenBtnPress": "quando o botão [REFERENCE] for premido",
    "gui.blocklyText.microbit.whenPinConnected": "Quando a interface [REFERENCE] é conectada",
    "gui.blocklyText.microbit.whenPosChange": "quando [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "Padrão de exibição ou padrão integrado [PIC]",
    "gui.blocklyText.microbit.showInput": "exibir [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "parar animação",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] Coordenadas x: [XAXIS], y: [YXAXIS]",
    "gui.blocklyText.microbit.hideAllLights": "Limpar todos os pontos da matriz",
    "gui.blocklyText.microbit.microbitEnableLight": "Lattice Screen [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "Brilho",
    "gui.blocklyText.microbit.showLightWithBrightness": "define brilho [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "pin [PIN] toca som [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "pin [PIN] toca som [SOUND] até ao fim",
    "gui.blocklyText.microbit.playNote": "pin [PIN] toca nota [NOTE] para [BEAT] pulsação",
    "gui.blocklyText.microbit.true": "ativar",
    "gui.blocklyText.microbit.false": "desativar",
    "gui.blocklyText.maqueen.playSound": "pin P0 toca som [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "pin P0  toca som [SOUND] até ao fim",
    "gui.blocklyText.maqueen.playNote": "pin P0  toca nota [NOTE] para [BEAT] pulsação",
    "gui.blocklyText.maqueen.RGB0": "RGB0  (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1  (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2  (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3  (3)",
    "gui.blocklyText.maqueen.All": "Tudo  (-1)",
    "gui.blocklyText.microbit.changeTempo": "altera tempo (bpm) em [VALUE]",
    "gui.blocklyText.microbit.setTempo": "define tempo (bpm) para [VALUE]",
    "gui.blocklyText.microbit.getTempo": "tempo (bpm)",
    "gui.blocklyText.microbit.stopSound": "Para reprodução de fundo",
    "gui.blocklyText.microbit.btnIsPressed": "[BUTTON] botão pressionado?",
    "gui.blocklyText.microbit.pinIsConnected": "[PIN] pin ligado?",
    "gui.blocklyText.microbit.isMove": "estado atual [TYPE]?",
    "gui.blocklyText.microbit.compass": "ler orientação da bússola",
    "gui.blocklyText.microbit.temperature": "ler temperatura",
    "gui.blocklyText.microbit.digitalWrite": "digital pin [PIN] saída [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "ler digital pin [PIN]",
    "gui.blocklyText.microbit.analogWrite": "analog pin [PIN] saída [VALUE]",
    "gui.blocklyText.microbit.analogRead": "ler analog pin [PIN]",
    "gui.blocklyText.microbit.up": "logo para cima",
    "gui.blocklyText.microbit.down": "logo para baixo",
    "gui.blocklyText.microbit.left": "virar para a esquerda ",
    "gui.blocklyText.microbit.right": "virar para a direita",
    "gui.blocklyText.microbit.faceUp": "frente para cima",
    "gui.blocklyText.microbit.faceDown": "frente para baixo",
    "gui.blocklyText.microbit.freefall": "queda livre",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "agitar",
    "gui.blocklyText.microbit.show": "mostrar",
    "gui.blocklyText.microbit.hide": "esconder",
    "gui.blocklyText.microbit.low": "BAIXO",
    "gui.blocklyText.microbit.high": "ALTO",
    "gui.blocklyText.microbit.microbitReadBrightness": "ler brilho da luz ambiente ",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] comunicação wireless ",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "Define canal wireless para [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "envia string [TEXT] via wireless",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "recebe dados via wireless",
    "gui.blocklyText.microbit.strength": "força",
    "gui.blocklyText.microbit.acceleration": "ler aceleração(mg)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "ler força magnetica(µT)[TYPE]",
    "gui.blocklyText.microbit.print": "Imprimir [DATA]",
    "gui.blocklyText.microbit.goUp": "Para cima",
    "gui.blocklyText.microbit.goDown": "Para baixo",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "quando recebido [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "dados wireless ",
    "gui.blocklyText.maxbot.microbitStarts": "iniciar Maxbot ",
    "gui.blocklyText.maxbot.playSound": "toca som [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "toca som [SOUND] até ao fim",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "colisão ocorre à [LEFTRIGHT] ?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "linha preta detetada à [LEFTRIGHT]  ?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "motor [MOTOR] [DIR] à velocidade [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "ler (P1,P2)  sensor ultrasonico [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "ler unidade de sensor ultrasonico [UNIT], trig [TRIG], echo [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "Para a frente",
    "gui.blocklyText.maxbot.BACKWARD": "Para trás",
    "gui.blocklyText.motorbit.CW": "CW",
    "gui.blocklyText.motorbit.CCW": "CCW",
    "gui.blocklyText.maxbot.CW": "CW",
    "gui.blocklyText.maxbot.CCW": "CCW",
    "gui.blocklyText.maxbot.ANGLE": "ângulo",
    "gui.blocklyText.maxbot.CIRCLE": "círculo",
    "gui.blocklyText.maxbot.Left": "esquerda",
    "gui.blocklyText.maxbot.Right": "direita",
    "gui.blocklyText.maxbot.cm": "cm",
    "gui.blocklyText.maxbot.inch": "inch",
    "gui.blocklyText.MotorBit.microbitStarts": "Iniciar MotorBit ",
    "gui.blocklyText.MotorBit.MotorRun": "motor [MOTOR] roda [DIR] à velocidade [SPEED]",
    "gui.blocklyText.MotorBit.MotorStop": "motor [MOTOR] para",
    "gui.blocklyText.MotorBit.Stepper": "Definir motor de passo [STEPPER] [ROTATEDIR] [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "TUDO",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen Começa",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "ler (P1,P2)sensor de ultrasons (cm)",
    "gui.blocklyText.maqueen.motorStop": "robot para (motor esquerdo e direito) ",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] Luz LED ",
    "gui.blocklyText.maqueen.redLineSensor": "Leia o sensor de rastreamento de linha [LEFTRIGHT]",
    "gui.blocklyText.maqueen.robotSpeedMove": "robot [DIR] à velocidade [SPEED]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "motor [LEFTRIGHT] move-se [DIR] à velocidade [SPEED]",
    "gui.blocklyText.maqueen.ledLeft": "Esquerda(P8)",
    "gui.blocklyText.maqueen.ledRight": "Direita(P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "Esquerda(P12) (não disponível)",
    "gui.blocklyText.maqueen.lineSensorLeft": "Esquerda(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "Direita(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "Quando (P16) receber [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "Sinal de infravermelhos",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "ler(P16) sinal de infravermelhos",
    "gui.blocklyText.maqueen.numberOfLightsBright": "pin P15 4 RGB LEDs brilho [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "pin P15 o [NUM1] LED exibe cor [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "O pin de luz RGB P15 número de luz [NUM1] a [NUM2] exibe a cor gradual, matiz de [COLOR1] a [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "O pin de luz RGB P15 move a unidade [NUM]",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "O pino de luz RGB P15 move ciclicamente a unidade [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "pin P15 LED brilho [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "Pino de luz RGB P15 [NUM1] a [NUM2] exibe o valor atual do histograma [VALUE] valor máximo [MAX]",
    "gui.blocklyText.maqueen.clear": "pin P15 limpar todos os LEDs",
    "gui.blocklyText.maqueen.rgbColor": "vermelho [RED] verde [GREEN] azul [BLUE]",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] dados recebidos?",
    "gui.blocklyText.obloq.initialParameter": "Obloq mqtt inicializar [PARAMETER] interface [SERIALPORT] Rx (verde) [PINR] Tx (azul) [PINT]",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq envia uma mensagem [MSG] para [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq lê a mensagem de [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq lê a mensagem",
    "gui.blocklyText.obloq.httpInitialParameter": "Inicialização do Obloq http [PARAMETER] interface [SERIALPORT] Rx (verde) [PINR] Tx (azul) [PINT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http (get) url: [URL] Tempo limite (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "URL obloq http (post): [URL] conteúdo: [CONTENT] Tempo limite (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "URL obloq http (colocar): [URL] conteúdo: [CONTENT] Tempo limite (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "adiciona subscrever [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "Nome Wi-Fi ",
    "gui.blocklyText.obloq.password": "password",
    "gui.blocklyText.obloq.iotService": "IOT Service",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "IP Address",
    "gui.blocklyText.obloq.port": "Port",
    "gui.blocklyText.obloq.addTopic": "Adicionar Topic, adicionar até 4",
    "gui.blocklyText.obloq.deleteTopic": "Eliminar Topic",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "Server",
    "gui.blocklyText.obloq.global": "Global",
    "gui.blocklyText.obloq.china": "China",
    "gui.blocklyText.obloq.softwareSerial": "Software Serial",
    "gui.blocklyText.obloq.hardwareSerial": "Hardware Serial",
    "gui.blocklyText.obloq.hardwareSerial1": "Hardware Serial1",
    "gui.blocklyText.obloq.hardwareSerial2": "Hardware Serial2",
    "gui.blocklyText.obloq.hardwareSerial3": "Hardware Serial3",
    "gui.blocklyText.obloq.WhenReceivedData": "Quando [TOPIC] recebe [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq messagem",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq obtém [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Informações de depuração",
    "gui.blocklyText.obloq.ERROR": "Erro de código",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "Emissão de infravermelho [TYPE] Pin [PIN] Número [VALUE] Número de bits [BIT]",
    "gui.blocklyText.oled2864.displayInit": "OLED 128 * 64 inicializa endereço IIC [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128 * 64 linha [LINE] exibe [TEXT]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128 * 64 exibe [TEXT] nas coordenadas X: [X] Y * 16: [Y]",
    "gui.blocklyText.oled2864.screenRotation": "Rodar ecrã em [ROTATION] graus",
    "gui.blocklyText.oled2864.clear": "limpar OLED 128*64 ",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "Ler [PIN] pin [DHT] [TYPE]",
    "gui.blocklyText.sen0170.readWindspeed": "Ler o pin [PIN] Nível de velocidade do vento",
    "gui.blocklyText.sen0228.init": "Inicialize o sensor de luz ambiente (VEML7700)",
    "gui.blocklyText.sen0228.readAmbientLight": "Leia a luz ambiente (VEML7700)",
    "gui.blocklyText.dfr0026.readAmbientLight": "Leia o Pin [PIN] Luz ambiente",
    "gui.blocklyText.sen0177.readDustSensor": "Leia [TYPE] (ug / m3) interface [SERIALPORT] Rx [PINR] Tx [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "Pin (D) [PIN] Pin (MCLR) [RST] O estado atual é [TYPE]?",
    "gui.blocklyText.sen0203.readHeartRate": "Leia o Pin [PIN] [TYPE] alterar para [SWITCH]",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] Pin [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "Leia o valor de escala de cinzas do pino [PIN]",
    "gui.blocklyText.motucamera.initIICSerial": "Inicialize a porta [MU] [IICSERIAL] até obter sucesso",
    "gui.blocklyText.motucamera.resetConfig": "[MU] Restaurar configurações padrão",
    "gui.blocklyText.motucamera.algorithmSwitch": "mu camera [MU] [SWITCH] algoritmo [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "[MU] led [LED] Cor reconhecida [COLOR1] Cor não reconhecida [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "[MU] Definir algoritmo [ALGORITHM] Desempenho [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "mu camera [MU] digital zoom [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "[MU] Definir a taxa de transmissão da porta serial [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "[MU] Definir equilíbrio de branco da câmera [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "[MU] Definir modo de fps [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "[MU] [ALGORITHM] detectado",
    "gui.blocklyText.motucamera.colorRecognizeXY": "[MU] Reconhecimento de cor detectado x = [NUMX] y = [NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "[MU] Cor de detecção de bloco de cor detectada = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "Obter [MU] Algoritmo [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "Obtenha [MU] reconhecimento de cor [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "[MU] Cartão de forma de algoritmo [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "[MU] Cartão de tráfego do algoritmo [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "[MU] Cartão digital de algoritmo [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "[MU] Algoritmo de reconhecimento de cor = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "Deteção de Cor",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "Reconhecimento de Cor",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "Deteção de Bola",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "Deteção de Corpo",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "Cartão de forma",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "Carta de condução",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "Cartão digital",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "ativar",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "desativar",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "vermelho",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "verde",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "azul",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "amarelo",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "roxo",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "ciano",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "branco",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "off",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "vermelho",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "verde",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "azul",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "amarelo",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "roxo",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "ciano",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "branco",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "Auto",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "Velocidade",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "Equilíbrio",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "Precisão",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "Auto",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "Trancar",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "branco",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "amarelo",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "Auto",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "Nível1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "Nível2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "Nível3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "Nível4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "Nível5",
    "gui.blocklyText.motucamera.setHighFPSOff": "OFF",
    "gui.blocklyText.motucamera.setHighFPSOn": "ON",
    "gui.blocklyText.motucamera.colorDetectBlack": "preto",
    "gui.blocklyText.motucamera.colorDetectWhite": "branco",
    "gui.blocklyText.motucamera.colorDetectRed": "vermelho",
    "gui.blocklyText.motucamera.colorDetectYellow": "amarelo",
    "gui.blocklyText.motucamera.colorDetectGreem": "verde",
    "gui.blocklyText.motucamera.colorDetectCayan": "ciano",
    "gui.blocklyText.motucamera.colorDetectBlue": "azul",
    "gui.blocklyText.motucamera.colorDetectPerple": "roxo",
    "gui.blocklyText.motucamera.colorDetectOthers": "outros",
    "gui.blocklyText.motucamera.colorDetectPositionX": "posição de x",
    "gui.blocklyText.motucamera.colorDetectPositionY": "posição de y",
    "gui.blocklyText.motucamera.colorDetectPositionW": "largura",
    "gui.blocklyText.motucamera.colorDetectPositionH": "altura",
    "gui.blocklyText.motucamera.colorDetectPositionL": "rótulo",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "canal vermelho",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "canal verde",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "canal azul",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "Reconhecer canal do rótulo",
    "gui.blocklyText.motucamera.shapeCardTick": "tick",
    "gui.blocklyText.motucamera.shapeCardCross": "cruz",
    "gui.blocklyText.motucamera.shapeCardCircle": "círculo",
    "gui.blocklyText.motucamera.shapeCardSquare": "quadrado",
    "gui.blocklyText.motucamera.shapeCardTriangle": "triângulo",
    "gui.blocklyText.motucamera.trafficCardForward": "em frente",
    "gui.blocklyText.motucamera.trafficCardLeft": "esquerda",
    "gui.blocklyText.motucamera.trafficCardRight": "direita",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "inversão de marcha",
    "gui.blocklyText.motucamera.trafficCardStop": "Stop",
    "gui.blocklyText.motucamera.lightSensorUse": "[MU] Sensor de luz ativado [LIGHTFUNC]",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "[MU] Sensibilidade de configuração do sensor de luz [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "[MU] Sensor de luz lendo detecção de proximidade",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "[MU] Leitura do sensor de luz Detecção de luz ambiente",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "[MU] Sensor de luz Lê o resultado de um gesto",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "[MU] Gesto do sensor de luz = [GESTURE]",
    "gui.blocklyText.motucamera.upward": "para cima",
    "gui.blocklyText.motucamera.downward": "para baixo",
    "gui.blocklyText.motucamera.leftward": "para a esquerda",
    "gui.blocklyText.motucamera.rightward": "para a direita",
    "gui.blocklyText.motucamera.pull": "puxar",
    "gui.blocklyText.motucamera.push": "empurrar",
    "gui.blocklyText.motucamera.none": "nada",
    "gui.blocklyText.motucamera.default": "por defeito",
    "gui.blocklyText.motucamera.low": "baixo",
    "gui.blocklyText.motucamera.middle": "meio",
    "gui.blocklyText.motucamera.high": "alto",
    "gui.blocklyText.motucamera.proximity": "proximidade",
    "gui.blocklyText.motucamera.ambientLight": "luz ambiente",
    "gui.blocklyText.motucamera.gesture": "gesto",
    "gui.blocklyText.motucamera.MUWifiInit": "Porta de inicialização MU Wi-Fi [SERIALPORT] RX [RX] TX [TX] Taxa de transmissão da porta serial [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi Configura ssid [SSID] Senha [PASSWORD] Modo [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "A conexão Wi-Fi MU foi bem-sucedida?",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "desligar MU Wi-Fi",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi definir IP alvo [IP] porta [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "IP de destino de leitura de MU Wi-Fi",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi lê IP local",
    "gui.blocklyText.motucamera.MUWifiRead": "ler MU Wi-Fi ",
    "gui.blocklyText.motucamera.MUWifiWrite": "Gravação de MU Wi-Fi [WRITE]",
    "gui.blocklyText.dfr0552.init": "Módulo DA Inicializa endereço IIC [ADDR] Tensão de referência [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "Amplitude da onda do triângulo de saída do módulo DA [AMPLITUDE] frequência mV [FREQ] Hz DC bias [DCBIAS] ciclo de trabalho mV [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "Amplitude da onda senoidal de saída do módulo DA [AMPLITUDE] frequência mV [FREQ] Hz DC bias [DCBIAS] mV",
    "gui.blocklyText.dfr0552.outputVoltage": "Tensão de saída do módulo DA [VOLTAGE] mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "Tensão da memória do módulo DA [VOLTAGE] mV",
    "gui.blocklyText.sen0117.init": "Interface do módulo inicial [SERIALPORT] Rx [RX] Tx [TX] Ocupado [BUSY]",
    "gui.blocklyText.sen0117.playText": "Tocar texto [TEXT] Música de fundo [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "Tocar prompt de som [INDEX] Aguarde o fim [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "Toque o tom do acorde [INDEX] Aguarde o fim [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "Defina [INDEX] para [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "Defina a taxa de fala para [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "Obter módulo de síntese de voz [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "Volume de primeiro plano",
    "gui.blocklyText.sen0117.Background": "Volume de fundo",
    "gui.blocklyText.sen0117.Speed": "Velocidade",
    "gui.blocklyText.sen0117.playChordCueTone": "Tocar toque polifônico [INDEX] Aguarde o fim [WAIT]",
    "gui.blocklyText.sen0117.No": "Não (0)",
    "gui.blocklyText.sen0117.setPlayMode": "Defina o modo de reprodução para [MODE]",
    "gui.blocklyText.sen0117.pause": "Pausa",
    "gui.blocklyText.sen0117.resume": "Voltar",
    "gui.blocklyText.sen0117.stop": "Stop",
    "gui.blocklyText.sen0117.isIdle": "Reprodução terminada?",
    "gui.blocklyText.dfr0576.selectPort": "Seleciona porta [PORT]",
    "gui.blocklyText.dfr0576.begin": "Inicialize o endereço do módulo IIC em cascata para [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680 initializa",
    "gui.blocklyText.sen0248.readBME680": "Obtenha o sensor BME680 [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "Temperatura (°C)",
    "gui.blocklyText.sen0248.Pressure": "Pressão (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Humidade (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0248.GasResistance": "Valor da resistência do gás (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "Inicialize o endereço do sensor ultrassônico I2C como [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Altere o endereço do sensor ultrassônico I2C para [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Distância de leitura do sensor ultrassônico I2C (cm)",
    "gui.blocklyText.sen0304.readTemperature": "Ler a temperatura do sensor ultrassônico I2C (° C)",
    "gui.blocklyText.sen0307.readDistance": "Leia [PIN] Ultrassônico analógico (cm)",
    "gui.blocklyText.sen0250.setMode": "Endereço de inicialização BMI160 [ADDR] modo [MODE]",
    "gui.blocklyText.sen0250.Step": "Passo",
    "gui.blocklyText.sen0250.Measure": "Medida",
    "gui.blocklyText.sen0250.readAccelerated": "MBI160 Obtenha o valor de aceleração do eixo [AXIS] (g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 Obtenha o número de etapas",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "Gyro",
    "gui.blocklyText.sen0250.Acc": "Acelaração (g)",
    "gui.blocklyText.sen0224.init": "Inicialize o sensor LIS2DH",
    "gui.blocklyText.sen0224.readAccelerated": "Ler o valor de aceleração do eixo do sensor LIS2DH [AXIS] (mg)",
    "gui.blocklyText.dfr0023.readLM35": "Leia a temperatura do Pin [PIN] LM35 (℃)",
    "gui.blocklyText.dfr0027.whenVibration": "Quando o Pin [PIN] detecta vibração",
    "gui.blocklyText.dfr0028.readTiltSensor": "Pin [PIN] detectou inclinação?",
    "gui.blocklyText.dfr0029.readPress": "O botão Pin [PIN] foi pressionado?",
    "gui.blocklyText.dfr0030.readTouch": "Pin [PIN] foi tocado?",
    "gui.blocklyText.dfr0033.readMagnetic": "Pin [PIN] detectou íman?",
    "gui.blocklyText.dfr0034.readSound": "Leia a intensidade do som do Pin [PIN]",
    "gui.blocklyText.dfr0094.readIRReceived": "Ler o valor de recepção de infravermelho do Pin [PIN]",
    "gui.blocklyText.midi.midiInit": "Inicialização MIDI [SERIALPORT] Pin RX [RX] Pin TX [TX]",
    "gui.blocklyText.midi.midiOutput": "Canal de saída MIDI [CHANNEL] Pitch [PITCH] Velocidade [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "Leia o Pin [PIN] Sensor de gás monóxido de carbono",
    "gui.blocklyText.dfr0051.readVoltage": "Leiaa voltagem do Pin [PIN] (V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "Ler sensor de vibração piezo do pino [PIN]",
    "gui.blocklyText.dfr0058.readRotation": "Ler Pin [PIN] Sensor de ângulo de rotação multi-voltas",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "Ler eixo do joystick [AXIS] do Pin [PIN]",
    "gui.blocklyText.dfr0061.readJoyStickButton": "Pin [PIN] O botão do joystick (Z) foi pressionado?",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut inicializar Pin de dados [DATAPIN] Pin de relógio [RTCPIN] Pin de seleção de chip [CLSPIN] ordem de byte [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Código de segmento de exibição de tubo digital 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Número do display LED [NUM]",
    "gui.blocklyText.dfr0072.dfr0072Dot": "Ponto de exibição do tubo digital [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "verdadeiro",
    "gui.blocklyText.dfr0072.dotFalse": "falso",
    "gui.blocklyText.dfr0072.numOff": "off",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Ler bateria lithium [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "voltagem(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "Bateriarestante(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "Módulo AD inicializa endereço IIC [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "Tensão de leitura do módulo AD [PIN] (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "Módulo AD Obter canal [AISLE] Diferença de tensão",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 e 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 e 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 e 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 e 3",
    "gui.blocklyText.dfr0588.initPin": "inicializar Pin de temperatura [TEM] Pin de humidade [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "Obter a unidade de temperatura [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "obter humidade(%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "CELSIUS",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "FAHRENHEIT",
    "gui.blocklyText.dfr0076.readFlameSensor": "Ler sensor de chama do Pin [PIN]",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom obtém [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom lê o nome do texto [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom lê nome de número [KEY]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom inicializa endereço i2c [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom escreve texto [VALUE] nome [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom escreve número [VALUE] nome [KEY]",
    "gui.blocklyText.dfr0117.text": "Texto",
    "gui.blocklyText.dfr0117.number": "Número",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom apaga nome [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "A porta serial i2c eeprom imprime todos os nomes Taxa de Baud [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "O nome do eeprom i2c [KEY] existe?",
    "gui.blocklyText.dfr0117.eepromFormat": "formato i2c eeprom",
    "gui.blocklyText.dfr0117.eepromGetType": "O valor de i2c eeprom name [KEY] [TYPE] é do tipo?",
    "gui.blocklyText.dfr0117.nameCount": "Contabilização de nomes",
    "gui.blocklyText.dfr0117.memorySize": "Espaço de armazenamento (byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "Espaço de armazenamento restante (byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": "Leia o pin [PIN] Eixo do acelerômetro de três eixos [AXIS]",
    "gui.blocklyText.sen0018.readInfraredMotion": "Ler pin [PIN] Sensor de movimento piroelétrico infravermelho do corpo humano",
    "gui.blocklyText.sen0014.readDistance": "Ler pin [PIN] Sensor de humidade do solo",
    "gui.blocklyText.sen0114.readMoisture": "ler sensor de humidade do solo pin [PIN] ",
    "gui.blocklyText.sen0121.readSteam": "Ler o pino [PIN] do sensor de umidade",
    "gui.blocklyText.sen0204.readLiquidLevel": "Pin [PIN] O nível do líquido foi atingido?",
    "gui.blocklyText.sen0203.heartRate": "batimento cardiaco(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "saturação de oxigénio no sangue (HbO2)",
    "gui.blocklyText.sen0202.up": "Cima",
    "gui.blocklyText.sen0202.down": "Abaixo",
    "gui.blocklyText.sen0202.left": "Esquerda",
    "gui.blocklyText.sen0202.right": "Direita",
    "gui.blocklyText.sen0202.cw": "CW",
    "gui.blocklyText.sen0202.ccw": "CCW",
    "gui.blocklyText.dhtTHSensor.temperature": "temperatura(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "humidade(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "pin [PIN] RGB [NUM1] to [NUM2] show color [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "Vermelho [RED] Verde [GREEN] Azul [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "Pin de luz RGB [PIN] número de luz [NUM1] a [NUM2] mostra a cor gradual, matiz de [COLOR1] a [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "Pin de luz RGB [PIN] move [NUM] unidade",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "Pin de luz RGB [PIN] unidade de movimento cíclico [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "pin [PIN] [NUM] RGB LEDs",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "pin [PIN] brilho [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "pin [PIN] limpar todos os LEDs",
    "gui.blocklyText.ws2812.showHistogram": "Pino de luz RGB [PIN] número de luz [NUM1] a [NUM2] exibe o valor atual do histograma [VALUE] valor máximo [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "Pino de configuração de luz RGB [PIN] O brilho da faixa de luz é [BRIGHTNESS]",
    "gui.blocklyText.dsTSensor.ds18b20": "Leia [PIN] Temperatura do pino DS18B20 (℃)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280 inicializa",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "ler sensor BMP280 [TYPE] ",
    "gui.blocklyText.bmpTSensor.atmosPressure": "Pressão do ar(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "altitude(m)",
    "gui.blocklyText.bmpTSensor.temperature": "temperatura(℃)",
    "gui.blocklyText.huskylens.init": "O pino de inicialização do HuskyLens é [SETTING] até ser bem-sucedido",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens muda para algoritmo [ALGORITHM] até ter sucesso",
    "gui.blocklyText.huskylens.requestFirst": "HuskyLens solicita dados uma vez e salva o resultado",
    "gui.blocklyText.huskylens.resultGetIsStudy": "O HuskyLens obtém o ID [IDNUM] do resultado. Ele foi aprendido?",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "O HuskyLens obtém o ID do resultado [IDNUM] [BOXARROW] Está no ecrã?",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens obtém o número total de IDs aprendidos a partir dos resultados",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens obtém o número total de [BOXARROW] a partir dos resultados",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens obtém ID [IDNUM] [BOXARROW] total do resultado",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens obtém do resultado [BOXARROW] Está no ecrã?",
    "gui.blocklyText.huskylens.box": "caixa",
    "gui.blocklyText.huskylens.arrow": "seta",
    "gui.blocklyText.huskylens.faceRecognition": "Reconhecimento Facial",
    "gui.blocklyText.huskylens.objectTracking": "Rastreamento de objetos",
    "gui.blocklyText.huskylens.objectRecognition": "Reconhecimento de objetos",
    "gui.blocklyText.huskylens.lineTracking": "Rastreamento de linha",
    "gui.blocklyText.huskylens.colorRecognition": "Reconhecimento de cor",
    "gui.blocklyText.huskylens.tagRecognition": "Reconhecimento de TaG",
    "gui.blocklyText.huskylens.x": "X coordenadas",
    "gui.blocklyText.huskylens.y": "Y coordenadas",
    "gui.blocklyText.huskylens.w": "Largura",
    "gui.blocklyText.huskylens.h": "Altura",
    "gui.blocklyText.huskylens.x1": "X1 Ponto de começo",
    "gui.blocklyText.huskylens.y1": "Y1 Ponto de começo",
    "gui.blocklyText.huskylens.x2": "X2 Ponto de Final",
    "gui.blocklyText.huskylens.y2": "Y2  Ponto de Final",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "Método de Comunicação",
    "gui.blocklyText.huskylens.address": "endereço",
    "gui.blocklyText.huskylens.sdaGreen": "SDA(Green)",
    "gui.blocklyText.huskylens.sclBlue": "SCL(Blue)",
    "gui.blocklyText.huskylens.rxGreen": "Rx(Green)",
    "gui.blocklyText.huskylens.txBlue": "Tx(Blue)",
    "gui.blocklyText.ps2.initPs2Handle": "Inicializar controlador PS2 DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] Feedback de vibração [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "Vibração PS2 [RUMBLE] Força [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "Status de atualização do PS2 uma vez",
    "gui.blocklyText.ps2.ps2ButtonState": "O estado do botão PS2 [BUTTON] é [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2 joystick [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "ON",
    "gui.blocklyText.ps2.OFF": "OFF",
    "gui.blocklyText.ps2.Triangle": "Triângulo",
    "gui.blocklyText.ps2.Circle": "Círculo",
    "gui.blocklyText.ps2.Cross": "Cruz",
    "gui.blocklyText.ps2.Square": "Quadrado",
    "gui.blocklyText.ps2.Left1": "Esquerda 1",
    "gui.blocklyText.ps2.Left2": "Esquerda 2",
    "gui.blocklyText.ps2.Left3": "Esquerda 3",
    "gui.blocklyText.ps2.Right1": "Direita 1",
    "gui.blocklyText.ps2.Right2": "Direita 2",
    "gui.blocklyText.ps2.Right3": "Direita 3",
    "gui.blocklyText.ps2.Up": "Cima",
    "gui.blocklyText.ps2.Right": "Direita",
    "gui.blocklyText.ps2.Down": "Abaixo",
    "gui.blocklyText.ps2.Left": "Esquerda",
    "gui.blocklyText.ps2.Select": "Seleciona",
    "gui.blocklyText.ps2.Start": "Começa",
    "gui.blocklyText.ps2.Hold": "Espera",
    "gui.blocklyText.ps2.Pressed": "Pressionado",
    "gui.blocklyText.ps2.Released": "Libertar",
    "gui.blocklyText.ps2.CHANGE": "MUDAR",
    "gui.blocklyText.ps2.RightX": "Direita X",
    "gui.blocklyText.ps2.RightY": "Direita Y",
    "gui.blocklyText.ps2.LeftX": "Esquerda X",
    "gui.blocklyText.ps2.LeftY": "Esquerda Y",
    "gui.blocklyText.sen0236.bme280Init": "inicializar BME280",
    "gui.blocklyText.sen0236.readbme280Sensor": "ler sensor BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "Temperatura(℃)",
    "gui.blocklyText.sen0236.Humidity": "Humidade(%rh)",
    "gui.blocklyText.sen0236.Altitude": "Altitude(m)",
    "gui.blocklyText.sen0236.Pressure": "Pressão(Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "Inicialize o endereço da tela LCD IIC é [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "O display IIC LCD mostra [TEXT] on-line [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "A tela LCD IIC exibe [TEXT] nas coordenadas X: [X] Y: [Y]",
    "gui.blocklyText.LCD1602.displayClear": "Limpar o ecrã",
    "gui.blocklyText.IICScan.readICCAddr": "Leia o endereço do dispositivo IIC verificado",
    "gui.blocklyText.sdcard.initCS": "O pino de seleção do chip SD [CSPIN] foi inicializado com sucesso?",
    "gui.blocklyText.sdcard.readFileNLine": "Arquivo de leitura SD [FILE] linha [LINE]",
    "gui.blocklyText.sdcard.readFileAll": "Arquivo de leitura SD [FILE] todo o conteúdo",
    "gui.blocklyText.sdcard.writeFile": "Modo de gravação SD [MSG] para arquivo [FILE] [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "Excluir arquivo SD [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "Obter informações do arquivo SD [FILE] [INFO]",
    "gui.blocklyText.sdcard.NAME": "NOME",
    "gui.blocklyText.sdcard.PATH": "CAMINHO",
    "gui.blocklyText.sdcard.TYPE": "TIPO",
    "gui.blocklyText.sdcard.LINE": "LINHA",
    "gui.blocklyText.sdcard.SIZE": "TAMANHO",
    "gui.blocklyText.sdcard.APPEND": "ACRESCENTAR",
    "gui.blocklyText.sdcard.REPLACE": "SUBSTITUIR",
    "gui.blocklyText.sdcard.WARP": "WARP",
    "gui.blocklyText.sdcard.NOWARP": "NOWARP",
    "gui.blocklyText.sdcard.lsFile": "A porta serial SD imprime todas as informações do arquivo Taxa Baud [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "Inicialize a porta serial soft [SERIALPORT] Rx: [PINR] Tx: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "Definir a taxa de transmissão da porta serial suave [SERIALPORT] [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "Porta serial soft [SERIALPORT] [WAY] [TEXT] [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "Porta serial soft [SERIALPORT] Há dados para ler?",
    "gui.blocklyText.softSerialport.readSerialportData": "Leia os dados da porta serial soft [SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "Leia um [TYPE] da porta serial soft [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "Executar quando a conexão Bluetooth for bem-sucedida",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "Executar quando o Bluetooth estiver desconectado",
    "gui.blocklyText.bluetooth.receivedKey": "Executar quando o Bluetooth receber uma chave de mensagem [KEY] valor [TYPE]",
    "gui.blocklyText.bluetooth.string": "String",
    "gui.blocklyText.bluetooth.number": "Número",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "Executar quando o Bluetooth receber dados e encontrar [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "Bluetooth enviar chave de string [KEY] valor [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "Bluetooth envia chave número [KEY] valor [VALUE]",
    "gui.blocklyText.bluetooth.openService": "Ligue o serviço Bluetooth",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "Bluetooth recebe mensagens",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo começa",
    "gui.blocklyText.leonardo.setBaud": "Definir taxa de transmissão [SERIAL] [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "Leia um [READTYPE] de [SERIAL]",
    "gui.blocklyText.leonardo.serialPort1": "SerialPort0",
    "gui.blocklyText.leonardo.serialPort2": "SerialPort1",
    "gui.blocklyText.leonardo.dataReadable": "[SERIAL] Há dados para ler?",
    "gui.blocklyText.leonardo.readSerialData": "Ler dados [SERIAL]",
    "gui.blocklyText.arduinounoR3.initTcs34725": "Inicialize o sensor de cor TCS34725",
    "gui.blocklyText.arduinounoR3.functionStarts": "Começar programa de Uno kit ",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "Inicialize o display TMI1650",
    "gui.blocklyText.arduinounoR3.readdigital": "Leia o valor do pino [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "Sensor Digital",
    "gui.blocklyText.arduinounoR3.buttonModule": "Módulo de Botão",
    "gui.blocklyText.arduinounoR3.stickButton": "Botão do joystick",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "Sensor de Vibração",
    "gui.blocklyText.arduinounoR3.readAnalog": "Ler Pin [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "Sensor analógico",
    "gui.blocklyText.arduinounoR3.lightSensor": "Sensor de Luz",
    "gui.blocklyText.arduinounoR3.soundSensor": "Sensor de som",
    "gui.blocklyText.arduinounoR3.stickDirection": "Direção do joystick",
    "gui.blocklyText.arduinounoR3.angleSensor": "Sensor de ângulo",
    "gui.blocklyText.arduinounoR3.graySensor": "Sensor de escala de cinzas",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "Sensor de Humidade do Solo",
    "gui.blocklyText.arduinounoR3.open": "ligar",
    "gui.blocklyText.arduinounoR3.close": "desligar",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] pin [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "Atuador digital",
    "gui.blocklyText.arduinounoR3.ledLights": "Luzes LED ",
    "gui.blocklyText.arduinounoR3.fanModule": "Módulo de ventoinha",
    "gui.blocklyText.arduinounoR3.recordModule": "Módulo de gravação",
    "gui.blocklyText.arduinounoR3.relay": "Relé/ Retransmissão",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "Leitura da distância ultrassônica (cm) dispara [TRIG] o eco [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "Atuador analógico",
    "gui.blocklyText.arduinounoR3.writeAnalog": "define Pin [PIN] [MODULE] para [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "Frente",
    "gui.blocklyText.arduinounoR3.backward": "Reverter",
    "gui.blocklyText.arduinounoR3.turnLeft": "Virar à esuqerda",
    "gui.blocklyText.arduinounoR3.turnRight": "Virar à direita",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "robot [DIR] à velocidade [SPEED] %",
    "gui.blocklyText.arduinounoR3.motorForward": "frente",
    "gui.blocklyText.arduinounoR3.motorReverse": "Reverter",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "Defina o motor [MOTOR] para [SPEED]% de velocidade [DIR]",
    "gui.blocklyText.arduinounoR3.stopMotor": "robot (MA and MB motors) para de se mover",
    "gui.blocklyText.arduinounoR3.setServo": "Defina o servo do Pin [PIN] no grau [DEGREE]",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "Emissão de infravermelho [TYPE] Pin [PIN] Valor [VALUE] Número de bits [BIT] ",
    "gui.blocklyText.arduinounoR3.pressInfrared": "O Pin infravermelho [PIN] recebeu o botão [BUTTON] pressionado?",
    "gui.blocklyText.arduinounoR3.noSound": "Sem som",
    "gui.blocklyText.arduinounoR3.half": "Mínima",
    "gui.blocklyText.arduinounoR3.quarter": "Semínima",
    "gui.blocklyText.arduinounoR3.oneInEighth": "Colcheia",
    "gui.blocklyText.arduinounoR3.wholeBeat": "Semibreve",
    "gui.blocklyText.arduinounoR3.doubleBeat": "Breve",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "pin [PIN] toca som de BUZZER [TONE] durante [BEAT] pulsações",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "Defina o volume do módulo MP3 Pin [PIN] para [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "Defina o modo de reprodução do módulo MP3 Pin [PIN] para [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "Stop",
    "gui.blocklyText.arduinounoR3.play": "Play",
    "gui.blocklyText.arduinounoR3.loop": "Loop",
    "gui.blocklyText.arduinounoR3.random": "Aleatório",
    "gui.blocklyText.arduinounoR3.pause": "Pausa",
    "gui.blocklyText.arduinounoR3.previous": "Anterior",
    "gui.blocklyText.arduinounoR3.next": "Próximo",
    "gui.blocklyText.arduinounoR3.VolumePlus": "Volume+",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "Volume-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "Pin [PIN] módulo MP3 toca música [NUM] ",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "Leia a temperatura do Pin [PIN] LM35 (℃)",
    "gui.blocklyText.arduinounoR3.readDHT11": "ler Pin [PIN] DHT11 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "vermelho",
    "gui.blocklyText.arduinounoR3.blue": "azul",
    "gui.blocklyText.arduinounoR3.green": "verde",
    "gui.blocklyText.arduinounoR3.readColorSensor": "Leia [COLORTYPE] do sensor de cores TCS34725",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "Tubo nixie de 4-bit TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "Ligar",
    "gui.blocklyText.arduinounoR3.tm1650Close": "desligar",
    "gui.blocklyText.arduinounoR3.clearScreen": "limpar ecrã",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": "Tubo NIXIE de 4-bit exibe string [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "Tubo Nixie de quatro bits_TM1650 [ORDER] ponto decimal [STATUS] ",
    "gui.blocklyText.arduinounoR3.bright": "brilhante",
    "gui.blocklyText.arduinounoR3.dark": "Sombrio",
    "gui.blocklyText.arduinounoR3.setLightColor": "Luz RGB Pin [PIN] Número da luz [NUM] [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "Luz RGB Pin [PIN] Número da luz [NUM] Valor R: [R] Valor G: [G] Valor B: [B]       ",
    "gui.blocklyText.arduinounoR3.numberOfLights": "Luz RGB Pin [PIN] Número de luzes [NUM]",
    "gui.blocklyText.arduinounoR3.ledControl": "Parâmetros de inicialização da matriz LED (max7219) O Pin DIN é [DIN] O Pin CS é [CS] O Pin CLK é [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "Matriz LED (max7219) exibe padrão [PIC]",
    "gui.blocklyText.max.maxStart": "Max começa",
    "gui.blocklyText.max.onboardButtonPressed": "Botão integrado [PRESSED]",
    "gui.blocklyText.max.pressed": "pressionado",
    "gui.blocklyText.max.notPressed": "não pressionado",
    "gui.blocklyText.max.all": " -1 tudo",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "Configurar a cor [COLOR] da luz RGB [TYPE]",
    "gui.blocklyText.max.readSoundSensor": "Ler o valor do sensor de som",
    "gui.blocklyText.max.playSoundEffect": "Toca o efeito sonoro[SOUND]",
    "gui.blocklyText.max.setDPinValue": "define Pin digital [PIN] para [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "Ler digital pin [PIN]",
    "gui.blocklyText.max.readAPinValue": "Ler analog pin [PIN]",
    "gui.blocklyText.max.setServo": "definir pin [PIN] servo para [DEGREE] grau",
    "gui.blocklyText.max.readLinefindingSensor": "Leia o valor do sensor de linha [DIR]",
    "gui.blocklyText.max.left": "Esquerda",
    "gui.blocklyText.max.middle": "Meio",
    "gui.blocklyText.max.right": "Direita",
    "gui.blocklyText.max.setLineFidingCarLights": "Definir luzes de pesquisa de linha do carro [SWITCH]",
    "gui.blocklyText.max.on": "on",
    "gui.blocklyText.max.off": "off",
    "gui.blocklyText.max.readUltrasoundSensor": "ler valor do sensor de ultrasons (cm)",
    "gui.blocklyText.max.readLightSensor": "Ler o valor do sensor de luz [LEFTRIGHT]",
    "gui.blocklyText.max.maxSpeedMove": "MAX move-se [DIR] à velocidade [SPEED] ",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] move-se [DIR] à velocidade [SPEED] ",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] para",
    "gui.blocklyText.max.showUserDefineExpressions": "Mostrar emoji personalizado [EXPRESSION] color [COLOR]",
    "gui.blocklyText.max.clear": "Limpar",
    "gui.blocklyText.max.do": "Do",
    "gui.blocklyText.max.re": "Re",
    "gui.blocklyText.max.mi": "Mi",
    "gui.blocklyText.max.fa": "Fa",
    "gui.blocklyText.max.sou": "So",
    "gui.blocklyText.max.la": "La",
    "gui.blocklyText.max.xi": "Si",
    "gui.blocklyText.max.highdo": "som agudo",
    "gui.blocklyText.max.brake": "Travão",
    "gui.blocklyText.max.rebound": "rebound",
    "gui.blocklyText.max.bullets": "Balas",
    "gui.blocklyText.max.longecho": "ecos longos",
    "gui.blocklyText.max.playfulending": "Final divertido",
    "gui.blocklyText.max.yo": "yo",
    "gui.blocklyText.max.wo": "wo",
    "gui.blocklyText.max.robottalking": "robôt a falar",
    "gui.blocklyText.sen0160.init": "Definir valor de calibração do sensor de peso Hx711 [VALUE] pino Dout [DOUT] SCK [SCK]",
    "gui.blocklyText.sen0160.readValue": "Leia o sensor de peso Hx711 (g) pino Dout [DOUT] SCK [SCK]",
    "gui.blocklyText.knowflow.readORP": "Leia o pino analógico [PIN] do sensor Redox (ORP) (mV)",
    "gui.blocklyText.knowflow.readEC": "Ler o pino analógico [PIN] Sensor de condutividade (EC) (ms / cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "Ler o pino analógico [PIN] do sensor de pH (V1)",
    "gui.blocklyText.knowflow.readECV2": "Ler o pino analógico [PIN] Sensor de condutividade (EC) (ms / cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "Leia o pino analógico [PIN] do sensor de pH (V2)",
    "gui.blocklyText.knowflow.readDO": "Ler pino analógico [PIN] Sensor OD de oxigênio dissolvido (mg / L)",
    "gui.blocklyText.peristalticPump.speedMove": "Defina o pino [PIN] da bomba peristáltica em [SPEED]% da velocidade [DIR]",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] Pin [PIN] relay",
    "gui.blocklyText.goble.readAxis": "Leia o eixo do GOBLE rock [AXIS] ",
    "gui.blocklyText.goble.buttonIsPressed": "O botão GOBLE [BUTTON] foi pressionado?",
    "gui.blocklyText.eeprom.clear": "Limpar eeprom ",
    "gui.blocklyText.eeprom.read": "eeprom lê a partir do endereço [ADDR]",
    "gui.blocklyText.eeprom.write": "eeprom escreve para o endereço [ADDR] com dados [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom obtém o valor de verificação do endereço [ADDRA] para o endereço [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom obtém comprimento",
    "gui.blocklyText.tds.readAnalogPin": "Ler sensor TDS de pino analógico [PIN] (ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "Ajuste da junta do motor de passo eixo X: [XSET] Eixo Y: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "Motor de passo [AXIS] Etapas do eixo por rotação: [STEPS] Velocidade de rotação (r / min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "Número de etapas móveis do motor de passo [STEPS] Direção [ROTATEDIR]",
    "gui.blocklyText.steppermotor.setJointSteps": "Ajuste da junta do motor de passo Movimento do eixo X [XSTEPS] Direção da etapa [ROTATEDIRX] Movimento do eixo Y [YSTEPS] Direção da etapa [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] começa",
    "gui.blocklyText.multithread.stopThread": "para [THREAD]",
    "gui.blocklyText.multithread.startThread": "ativar [THREAD]",
    "gui.blocklyText.multithread.loop1": "loop1",
    "gui.blocklyText.multithread.loop2": "loop2",
    "gui.blocklyText.multithread.loop3": "loop3",
    "gui.blocklyText.multithread.loop4": "loop4",
    "gui.blocklyText.multithread.loop5": "loop5",
    "gui.blocklyText.multithread.loop6": "loop6",
    "gui.blocklyText.multithread.loop7": "loop7",
    "gui.blocklyText.multithread.loop8": "loop8",
    "gui.blocklyText.multithread.all": "Tudo",
    "gui.blocklyText.DFR0151.init": "Inicialização DS1307",
    "gui.blocklyText.DFR0151.adjustTime": "Ajuste o tempo DS1307 para [YEAR] ano [MONTH] mês [DATE] dia [HOUR] hora [MINUTE] minuto [SECOND] segundo",
    "gui.blocklyText.DFR0151.getTime": "Obter tempo DS1307 [INDEX]",
    "gui.blocklyText.DFR0151.Year": "ano",
    "gui.blocklyText.DFR0151.month": "mês",
    "gui.blocklyText.DFR0151.date": "data ",
    "gui.blocklyText.DFR0151.hour": "hora",
    "gui.blocklyText.DFR0151.minute": "minuto",
    "gui.blocklyText.DFR0151.second": "segundo",
    "gui.blocklyText.DFR0151.week": "semana",
    "gui.blocklyText.DFR0469.init": "SD2405 inicialização",
    "gui.blocklyText.DFR0469.adjustTime": "Ajuste SD2405 hora para [YEAR] ano [MONTH] mês [DATE] dia [HOUR] hora [MINUTE] minuto [SECOND] segundo",
    "gui.blocklyText.DFR0469.getTime": "Obter hora SD2405 [INDEX]",
    "gui.blocklyText.DFR0126.setPin": "Módulo de análise de áudio Definir pino #S [SPIN] #R [RPIN] Pino analógico [APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "ler a banda de frequências do som[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Inicialize a interface do módulo NFC IIC",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Inicialize a interface do módulo NFC Hard serial port Rx [RX] Tx [TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "Inicialize a interface do módulo NFC [SERIALPORT] Rx [PINR] Tx [PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "Inicialize a interface do módulo NFC [SERIALPORT] Rx [PINR] Tx [PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "Bloco de dados do módulo NFC [DATABLOCK] [BYTES] gravação de byte [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "Ler bloco de dados do módulo NFC [DATABLOCK] [BYTES] byte (número) ",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "Ler bloco de dados do módulo NFC [DATABLOCK] todos os dados (string) ",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "Ler UID do módulo NFC (string)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "UID do cartão detectado: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "Cartão detectado?",
    "gui.blocklyText.DFR0126.serial1": "serial1",
    "gui.blocklyText.DFR0126.serial2": "serial2",
    "gui.blocklyText.DFR0126.serial3": "serial3",
    "gui.blocklyText.sen0245.init": "Inicialize o sensor de alcance a laser VL53L0X",
    "gui.blocklyText.sen0245.setVL53L0X": "Defina os tempos de medição do módulo de alcance do laser VL53L0X [PRECISION] [VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "Obtenha o valor de variação do laser VL53L0X (mm)",
    "gui.blocklyText.SEN0245.LOW": "Baixa precisão (1 mm)",
    "gui.blocklyText.SEN0245.HIGH": "Alta precisão (0,25 mm)",
    "gui.blocklyText.SEN0245.Single": "Medição única",
    "gui.blocklyText.SEN0245.Continuous": "Medição contínua",
    "gui.blocklyText.serialMp3.initSerialMp3": "Inicializar a interface do módulo MP3 da porta serial [SERIALPORT] Rx [RX] Tx [TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "Defina o modo de reprodução do módulo MP3 da porta serial para [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "Defina o módulo MP3 da porta serial para tocar a música [VALUE]",
    "gui.blocklyText.serialMp3.setMP3Volume": "Defina o volume do módulo MP3 da porta serial para [VOLUME]%",
    "gui.blocklyText.serialMp3.play": "toca",
    "gui.blocklyText.serialMp3.pause": "pausa",
    "gui.blocklyText.serialMp3.end": "fim",
    "gui.blocklyText.serialMp3.prev": "anterior",
    "gui.blocklyText.serialMp3.next": "próximo",
    "gui.blocklyText.serialMp3.volumeUp": "aumentar volume",
    "gui.blocklyText.serialMp3.volumeDown": "diminuir volume",
    "gui.blocklyText.serialMp3.playlist": "playlist",
    "gui.blocklyText.serialMp3.insertTracks": "inserir pista",
    "gui.blocklyText.serialMp3.volume": "volume",
    "gui.blocklyText.servo360.setServo360": "Defina o pino [PIN] em [SPEED]% velocidade [DIR]",
    "gui.blocklyText.servo360.CW": "CW",
    "gui.blocklyText.servo360.CCW": "CCW",
    "gui.blocklyText.TCS34725.initTcs34725": "inicializar sensor de cor TCS34725 ",
    "gui.blocklyText.TCS34725.readTcs34725": "ler sensor de cor TCS34725  [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "Converte em GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "Não Converte em GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "Exibir texto [TEXT] [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "Definir scroll de texto [DIR] ",
    "gui.blocklyText.regEmotion8_16.left": "esquerda",
    "gui.blocklyText.regEmotion8_16.right": "direita",
    "gui.blocklyText.regEmotion8_16.lightAll": "Ilumine todas as matrizes de pontos [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "Coordenadas x: [XAXIS], y: [YXAXIS] são exibidas como [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "Limpar ecrã",
    "gui.codeLabel.dynamicVariables": "Variáveis dinâmicas",
    "gui.codeLabel.functionDeclaration": "Declaração da função",
    "gui.codeLabel.staticConstants": "Constantes estáticas",
    "gui.codeLabel.createObject": "Cria um objeto",
    "gui.codeLabel.mainProgramStart": "O programa princípal começa",
    "gui.codeLabel.SubthreadProgramStarts": "Rotina secundária % 1 programa iniciado",
    "gui.codeLabel.customFunction": "Função personalizada",
    "gui.codeLabel.eventCallbackFunction": "Função de retorno de chamada de evento",
    "gui.codeLabel.staticFunctions": "Função estática",
    "gui.mainHeader.err": "! Mensagem de erro: Apenas um cabeçalho de programa [% 1] pode ser usado ao mesmo tempo, exclua o módulo definido repetidamente.",
    "gui.mainHeader.help": "! Dica de ajuda: se você precisar executar vários programas ao mesmo tempo, use a \"multi-threading function\" em \"Extension\".",
    "gui.threadHeader.err1": "! Mensagem de erro: o cabeçalho do programa [% 1] não pode ser usado ao mesmo tempo, eles pertencem a kits ou placas-mãe diferentes.",
    "gui.threadHeader.err2": "! Mensagem de erro: Apenas um cabeçalho de evento [% 1] pode ser usado ao mesmo tempo. Exclua o módulo definido repetidamente.",
    "gui.blocklyText.mqtt.whenReceivedData": "Ao receber [MSG] de [TOPIC]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT mensagem",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Conexão Wi-Fi ao ponto de acesso: [ACCOUNT] Senha: [PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi ligado?",
    "gui.blocklyText.wifi.wifiDisConnected": "desligar Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Obtenha a configuração de Wi-Fi [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP mode:[ACCOUNT2] password:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Acesso Wi-Fi ao IP local do modo AP",
    "gui.blocklyText.wifi.whenDebugInfo": "Acesso Wi-Fi [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Código de erro",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "NTP definir hora da rede, fuso horário [TIMEZONE] servidor de hora [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "Obtenha a placa de desenvolvimento atual [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "UTCWest12_t",
    "gui.blocklyText.ntp.UTCWest11_t": "UTCWest11_t",
    "gui.blocklyText.ntp.UTCWest10_t": "UTCWest10_t",
    "gui.blocklyText.ntp.UTCWest9_t": "UTCWest9_t",
    "gui.blocklyText.ntp.UTCWest8_t": "UTCWest8_t",
    "gui.blocklyText.ntp.UTCWest7_t": "UTCWest7_t",
    "gui.blocklyText.ntp.UTCWest6_t": "UTCWest6_t",
    "gui.blocklyText.ntp.UTCWest5_t": "UTCWest5_t",
    "gui.blocklyText.ntp.UTCWest4_t": "UTCWest4_t",
    "gui.blocklyText.ntp.UTCWest3_t": "UTCWest3_t",
    "gui.blocklyText.ntp.UTCWest2_t": "UTCWest2_t",
    "gui.blocklyText.ntp.UTCWest1_t": "UTCWest1_t",
    "gui.blocklyText.ntp.UTCMiddle_t": "UTCMiddle_t",
    "gui.blocklyText.ntp.UTCEast1_t": "UTCEast1_t",
    "gui.blocklyText.ntp.UTCEast2_t": "UTCEast2_t",
    "gui.blocklyText.ntp.UTCEast3_t": "UTCEast3_t",
    "gui.blocklyText.ntp.UTCEast4_t": "UTCEast4_t",
    "gui.blocklyText.ntp.UTCEast5_t": "UTCEast5_t",
    "gui.blocklyText.ntp.UTCEast6_t": "UTCEast6_t",
    "gui.blocklyText.ntp.UTCEast7_t": "UTCEast7_t",
    "gui.blocklyText.ntp.UTCEast8_t": "UTCEast8_t",
    "gui.blocklyText.ntp.UTCEast9_t": "UTCEast9_t",
    "gui.blocklyText.ntp.UTCEast10_t": "UTCEast10_t",
    "gui.blocklyText.ntp.UTCEast11_t": "UTCEast11_t",
    "gui.blocklyText.ntp.UTCEast12_t": "UTCEast12_t",
    "gui.blocklyText.ntp.year": "ano",
    "gui.blocklyText.ntp.month": "mês",
    "gui.blocklyText.ntp.date": "data",
    "gui.blocklyText.ntp.hour": "hora",
    "gui.blocklyText.ntp.minute": "minuto",
    "gui.blocklyText.ntp.second": "segundo",
    "gui.blocklyText.ntp.week": "semana",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "Quando o servidor UDP recebe [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "Definir a porta do servidor UDP [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "Servidor UDP enviar mensagem [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "Quando o cliente UDP recebe [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "Defina o cliente UDP para se conectar ao servidor IP [IPADDR] Porta [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "Cliente UPD envia mensagem [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "mensagem",
    "gui.blocklyText.udp.ClientRecvedMsg": "mensagem",
    "gui.blocklyText.http.paramHeader": "Adicionar chave [KEY] valor [VALUE] em HTTP [PARAMHEADER]",
    "gui.blocklyText.http.requestHeader": "Adicione [TEXT] ao corpo da solicitação HTTP",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] solicitação de URL [URL] tempo limite [TIMEOUT] milissegundos",
    "gui.blocklyText.http.readMessage": "HTTP ler conteúdo [LINE]",
    "gui.blocklyText.http.param": "PARAM",
    "gui.blocklyText.http.header": "HEADER",
    "gui.blocklyText.http.oneline": "UM",
    "gui.blocklyText.http.allline": "TUDO",
    "gui.blocklyText.mqtt.mqttConnect": "ligar MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT ligado?",
    "gui.blocklyText.mqtt.acceptance.meaage": "Quando a mensagem de [TOPIC] é recebida como [MSG]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT envia mensagem [MSG] para [TOPIC]",
    "gui.blocklyText.mqtt.initial": " inicializar MQTT [SETTING]",
    "gui.blocklyText.mqtt.mqttDisconnect": "desligar MQTT",
    "gui.blocklyText.sen0251.bmp388Init": "inicializa BMP388 ",
    "gui.blocklyText.sen0251.readAltitude": "ler altitude BMP388 (m)",
    "gui.blocklyText.sen0251.readPressure": "ler pressão BMP388 (Pa)",
    "gui.blocklyText.sen0251.readTemperature": "ler temperatura BMP388 (℃)",
    "gui.blocklyText.sen0206.sen0206Measuring": "medição MLX90614 [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "objeto",
    "gui.blocklyText.sen0206.environment": "ambiente",
    "gui.blocklyText.sen0206.celsius": "celsius",
    "gui.blocklyText.sen0206.fahrenheit": "fahrenheit",
    "gui.blocklyText.sen0253.init": "10DOF inicializa o nível de pin de 'I2C_ADDR' para [LEVEL]",
    "gui.blocklyText.sen0253.reset": "10DOF reinicialização de Sensor ",
    "gui.blocklyText.sen0253.power": "10DOF Definir modo de energia [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF Obter Ângulo de Euler [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF Obter eixo do quaternion [AXIS]",
    "gui.blocklyText.sen0253.getAcc": "10DOF Obtenha aceleração [AXIS] Eixo (mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF Obter eixo do magnetômetro [AXIS] (μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF Obtenha aceleração linear [AXIS] Eixo (mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF Obter eixo do vetor de gravidade [AXIS] (mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF Adquirir eixo de velocidade angular [AXIS]",
    "gui.blocklyText.sen0253.getBmp280": "Aquisição 10DOF [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "Baixo",
    "gui.blocklyText.sen0253.HIGH": "Alto",
    "gui.blocklyText.sen0253.normal": "Normal",
    "gui.blocklyText.sen0253.lowPower": "Baixo consumo de energia",
    "gui.blocklyText.sen0253.suspend": "Suspender",
    "gui.blocklyText.sen0253.Roll": "Ângulo de rotação (°)",
    "gui.blocklyText.sen0253.Pitch": "Ângulo de inclinação (°)",
    "gui.blocklyText.sen0253.Yaw": "Ângulo de guinada (°)",
    "gui.blocklyText.sen0253.temperature": "Temperatura",
    "gui.blocklyText.sen0253.elevation": "Elevação",
    "gui.blocklyText.sen0253.airPressure": "Pressão de Ar",
    "gui.blocklyText.sen0290.init": "Pino de interrupção de configuração do sensor de relâmpago [IRQPIN] e endereço I2C [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "A inicialização do sensor de relâmpago I2C foi concluída?",
    "gui.blocklyText.sen0290.manualCal": "Configuração da capacitância do sensor de relâmpago [CAPACITANCE] Modo [MODE] Perturbação [DIST]",
    "gui.blocklyText.sen0290.indoors": "interior",
    "gui.blocklyText.sen0290.outdoors": "exterior",
    "gui.blocklyText.sen0290.disturberDis": "Desativar interferência",
    "gui.blocklyText.sen0290.disturberEn": "Ativar interferência",
    "gui.blocklyText.sen0290.errMsg": "Mensagem de erro",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "Leitura do status do sensor de raio",
    "gui.blocklyText.sen0290.GetLightningDistKm": "Sensor de raio obtém distância de raio (Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "Sensor de relâmpago obtém intensidade de energia de relâmpago",
    "gui.blocklyText.sen0291.sen0291Init": "O endereço I2C inicial do medidor de energia é [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "Carga de leitura do medidor de potência [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "A corrente real medida da calibração do medidor de energia é [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "Voltagem (mV)",
    "gui.blocklyText.sen0291.electric": "Corrente (mA)",
    "gui.blocklyText.sen0291.power": "Potência (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "Tensão de derivação (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.weather.weatherGetWithCity": "[WEATHER] na província [COUNTRY] cidade [CITY]",
    "gui.blocklyText.weather.temperaturHigh": "temperatura máxima(℃)",
    "gui.blocklyText.weather.temperaturLow": "temperatura mínima(℃)",
    "gui.blocklyText.weather.weather": "clima",
    "gui.blocklyText.weather.weatherInit": "Inicialização do servidor meteorológico [SETTING]",
    "gui.blocklyText.weather.serverAddr": "server address",
    "gui.blocklyText.weather.appid": "appid",
    "gui.blocklyText.weather.appsecret": "appsecret",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "Defina os parâmetros do servidor [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "Atualizar (tag) [TAG] Valor (valor) [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "ler tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "apagar tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "contar tag",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "Teste de ligação de rede ",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "pesquisar tag a partir do número inicial [NUMS] contabilização de variável [NUMV] tag [TAG] tipo [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "tag",
    "gui.blocklyText.tinywebdb.value": "valor",
    "gui.blocklyText.tinywebdb.both": "ambos",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "Hangkong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.getSpeech": "resultado do reconhecimento",
    "speech.setRecordTime": "define tempo de gravação para [TIME]",
    "speech.stopRecord": "parar reconhecimento auditivo ",
    "speech.controlSonogram": "[CONTROL] Sonograma",
    "speech.setServer": "alterar servidor do reconhecimento de discurso para [SERVER]",
    "speech.show": "mostrar",
    "speech.hide": "esconder",
    "speech.server1": "server1",
    "speech.server2": "server2",
    "gui.blocklyText.mp3.initMp3": "Inicialize a interface do módulo DFPlayer MP3 [SERIALPORT] Rx [RX] Tx [TX]",
    "gui.blocklyText.mp3.setMP3Volume": "Defina o volume do módulo DFPlayer MP3 para [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "Defina o modo de reprodução do módulo DFPlayer MP3 para [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "Defina o módulo DFPlayer MP3 para tocar a música [NUM]",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "Defina o volume do módulo DFPlayer MP3 Pin [PIN] para [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "Defina o modo de reprodução do módulo DFPlayer MP3 Pin [PIN] para [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "Pin [PIN] módulo DFPlayer MP3 toca música [NUM] ",
    "gui.blocklyText.tel0094.GPSInit": "GPS é inicializado para [SERIALPORT] Rx [RX] Tx [TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS obter localização geográfica [LATLON] (°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "Tempo de satélite GPS [TIME] ",
    "gui.blocklyText.tel0094.LATITUDE": "Longitude",
    "gui.blocklyText.tel0094.LONGITUDE": "Latitude",
    "gui.blocklyText.tel0094.YEAR": "Ano",
    "gui.blocklyText.tel0094.MONTH": "Mês",
    "gui.blocklyText.tel0094.DAY": "Dia",
    "gui.blocklyText.tel0094.HOUR": "Hora",
    "gui.blocklyText.tel0094.MINUTE": "Minuto",
    "gui.blocklyText.tel0094.SECOND": "Segundo",
    "gui.blocklyText.tel0094.ALL": "TUDO",
    "gui.blocklyText.tel0094.GPSGet": "Aquisição GPS [GPS]",
    "gui.blocklyText.tel0094.getState": "Estado",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "Hemisfério latitude",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "Hemisfério de longitude",
    "gui.blocklyText.tel0094.getGroundSpeed": "Velocidade no solo",
    "gui.blocklyText.tel0094.getSatellitesCount": "Número de satélites",
    "gui.blocklyText.tel0094.getAltitude": "Altitude",
    "gui.blocklyText.tel0094.getPdop": "Precisão de posição",
    "gui.blocklyText.tel0094.getHdop": "Precisão horizontal",
    "gui.blocklyText.tel0094.getVdop": "Precisão vertical",
    "gui.blocklyText.tel0094.getPositioningMode": "Modo de posicionamento",
    "gui.blocklyText.microIoT.displayInLine": "OLED exibe [TEXT] na linha [LINE] ",
    "gui.blocklyText.microIoT.displayInXY": "Exibir texto OLED [TEXT] nas coordenadas x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "Exibição de imagem OLED [IMAGE] nas coordenadas x [X] y [Y] ",
    "gui.blocklyText.microIoT.clear": "Limpar OLED",
    "gui.blocklyText.microIoT.pointDrawing": "Ponto de desenho OLED x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED definiu a largura da linha para [LINEWIDTH] pixels",
    "gui.blocklyText.microIoT.lineDrawing": "Desenho de linha OLED Ponto inicial x [X1] y [Y1] Ponto final x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "Desenho de círculo OLED [FILL] Centro x [X] y [Y] Raio [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "Quadro OLED [FILL] Ponto inicial x [X] y [Y] Largura [WIDTH] Altura [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB define brilho para [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "Luzes RGB apagadas",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "Luz RGB [NUM1] a [NUM2] exibe cores [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "Luzes RGB [NUM1] a [NUM2] mostram cores graduais, matiz de [COLOR1] a [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "Luzes RGB movem ciclicamente [NUM] pixels",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "Luz RGB [NUM1] a [NUM2] mostra cor [COLOR]",
    "gui.blocklyText.motorIot.all": "TUDO",
    "gui.blocklyText.microIot.servo": "Ângulo do Servo [INDEX] [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "Parâmetro de inicialização MQTT [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT envia mensagem [MSG] para [TOPIC]",
    "gui.blocklyText.microIot.MQTTMsg": "Mensagem MQTT",
    "gui.blocklyText.microTouch.whenBtnPress": "Quando a tecla [KEY] é pressionada",
    "gui.blocklyText.microTouch.Any": "Qualquer",
    "gui.blocklyText.microTouch.isButtonPressed": "A tecla [KEY] está pressionada?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "Botão de leitura do modo básico",
    "gui.blocklyText.microTouch.readKeyMathMode": "Ler modo matemático [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "Botões numéricos (num)",
    "gui.blocklyText.microTouch.Function": "Botão de função (str)",
    "gui.blocklyText.microTouch.vibrationMotor": " Motor de vibração [SWITCH]",
    "gui.blocklyText.microTouch.turnOn": "Ligar",
    "gui.blocklyText.microTouch.shutDown": "Desligar",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "Intensidade do som",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "Luz Natural",
    "gui.blocklyText.microNaturalScience.readColour": "Ler [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "Valor de vermelho",
    "gui.blocklyText.microNaturalScience.G": "Valor de verde",
    "gui.blocklyText.microNaturalScience.B": "Valor de azul",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "Intensidade UV (μW/cm²)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "Ligar",
    "gui.blocklyText.microNaturalScience.off": "Desligar",
    "gui.blocklyText.microNaturalScience.setTDSK": "Obtenha a constante k[NUM] da célula TDS",
    "gui.blocklyText.microNaturalScience.readTDSK": "Obtenha a constante k do elétrodo TDS",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS(ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "Obter [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "Temperatura da água (°C)",
    "gui.blocklyText.microNaturalScience.Temperature": "Temperatura(℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "Humidade(%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "Altitude(m)",
    "gui.blocklyText.microNaturalScience.Pressure": "Pressão(Pa)",
    "gui.blocklyText.micoiot.coordinateDisplay": " Definir ponto da matriz da Luz RGB X [X] Y [Y] exibir cor [COLOR] ",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 não pode ser colocado no evento de interrupção, o que pode fazer com que a leitura de dht11 seja diferente",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 não pode ser colocado no evento de interrupção, o que pode causar leituras anormais de dht22",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 não pode ser colocado em um evento de interrupção, o que pode causar leituras de PM2.5 anormais",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 lê dados, o que pode causar a falha na inicialização da matriz de pontos de LED",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "Dados de leitura de PM2.5 podem fazer com que o padrão de exibição de matriz de pontos LED falhe",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "A inicialização de mp3 não pode ser colocada no evento de interrupção, o que pode causar falha de inicialização",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "O volume de configuração de mp3 não pode ser colocado no evento de interrupção, o que pode fazer com que a configuração do volume falhe",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "O modo de reprodução de configuração de mp3 não pode ser colocado no evento de interrupção, o que pode causar falha no modo de reprodução de configuração",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "A reprodução da configuração de mp3 não pode ser colocada no evento de interrupção, o que pode causar falha na configuração da reprodução",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "A inicialização de mp3 da porta de série não pode ser colocada no evento de interrupção, o que pode causar falha de inicialização",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "O modo de reprodução de série de mp3 não pode ser colocado no evento de interrupção, o que pode causar falha ao definir o modo de reprodução",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "A reprodução de série de mp3 não pode ser colocada no evento de interrupção, o que pode causar falha na reprodução",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "A configuração do volume do mp3 da porta de série não pode ser colocada no evento de interrupção, o que pode causar falha na configuração do volume",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "A porta de série de inicialização mqtt de obloq não pode ser colocada no evento de interrupção, o que pode causar a falha de comunicação da porta serial de software",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq enviando mensagens não pode ser colocado em eventos de interrupção, o que pode causar falha de envio",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq lê a mensagem mqtt e não pode ser colocado no evento de interrupção, o que pode causar falha na recepção",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "A porta de série soft de inicialização HTTP da Obloq não pode ser colocada no evento de interrupção, o que pode causar a falha de comunicação da porta de sériel soft",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "A solicitação Obloq http get não pode ser colocada no evento de interrupção, o que pode fazer com que a solicitação falhe",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "A pós-solicitação de HTTP Obloq não pode ser colocada no evento de interrupção, o que pode fazer com que a solicitação falhe",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "A solicitação de colocação de obloq http não pode ser colocada no evento de interrupção, o que pode fazer com que a solicitação falhe",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "A recepção de infravermelho não pode ser colocada em um evento de interrupção, o que pode causar falha na recepção",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "A recepção de infravermelho pode causar falha na inicialização da matriz de pontos LED",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "A recepção de infravermelho pode causar a falha do padrão de exibição de matriz de pontos LED",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "A transmissão infravermelha não pode ser colocada em um evento de interrupção, o que pode causar falha na transmissão",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "As faixas de luz RGB não podem ser colocadas em eventos de interrupção, o que pode causar falha de inicialização",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "A faixa de luz RGB não pode ser colocada no evento de interrupção, o que pode fazer com que a configuração do Pin falhe",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "As faixas de luz RGB não podem ser colocadas em eventos de interrupção, o que pode fazer com que os Pins não desliguem",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "As faixas de luz RGB não podem ser colocadas no evento de interrupção, o que pode causar falha na exibição de cores",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "As faixas de luz RGB não podem ser colocadas no evento de interrupção, o que pode causar falha no ajuste de cor",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "As faixas de luz RGB não podem ser colocadas em eventos de interrupção, o que pode causar falha de movimento",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "As faixas de luz RGB não podem ser colocadas no evento de interrupção, o que pode fazer com que o movimento do loop falhe",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "As faixas de luz RGB não podem ser colocadas no evento de interrupção, o que pode fazer com que a exibição do histograma falhe",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "A matriz de pontos led não pode ser colocada em um evento de interrupção, o que pode causar falha de inicialização",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "A matriz de pontos do led não pode ser colocada no evento de interrupção, o que pode fazer com que o padrão de exibição falhe",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "A saída da porta de série soft não pode ser colocada no evento de interrupção, o que pode fazer com que o conteúdo da saída seja anormal",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "Os dados lidos pela porta de série soft não podem ser colocados no evento de interrupção, o que pode fazer com que o conteúdo lido seja anormal",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "Os dados de saída da porta serial do software e os dados lidos são usados ​​ao mesmo tempo, o que pode causar anormalidade nos dados",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "O int lido pela porta de sériel soft não pode ser colocado no evento de interrupção, o que pode fazer com que o conteúdo lido seja anormal",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "O uso de dados de saída de porta de série soft e leitura devários int ao mesmo tempo pode causar leitura anormal de dados",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "Quando dht11 e PM2.5 estão em dois threads respectivamente, se pm2.5 for lido com muita frequência, dht11 pode falhar ao ler. É recomendado reduzir a frequência de leitura de pm2.5",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "Quando dht11 e ds18b20 estão em dois encadeamentos respectivamente, se ds18b20 for lido com muita frequência, pode causar falha na leitura de dht11. Recomenda-se reduzir a frequência de leitura de ds18b20",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "Quando dht11 e mp3 são inicializados em dois threads, a inicialização de mp3 pode fazer com que o dht11 leia erros",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "Quando dht11 e mp3 definem o volume em dois threads respectivamente, a configuração de volume de mp3 pode causar erro de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "Quando o modo de configuração de dht11 e mp3 estão em dois threads respectivamente, o modo de configuração de mp3 pode causar erro de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "Quando a reprodução de dht11 e mp3 estão em dois segmentos, a reprodução de mp3 pode causar erros de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "Quando dht11 e mp3 de série são inicializados em dois threads respectivamente, a inicialização mp3 de série pode fazer com que dht11 leia erros",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "Quando os módulos de controle dht11 e mp3 de série estão em dois threads respectivamente, o módulo de controle mp3 de série pode causar erros de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "Quando a reprodução de dht11 e mp3 de série estão em dois segmentos, respectivamente, a reprodução de mp3 de sériel pode causar erros de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "Quando dht11 e mp3 de série configuram o volume em dois threads respectivamente, o volume de configuração de mp3 de série pode causar erro de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "Quando dht11 e RGB são inicializados em dois threads, a inicialização RGB pode fazer com que dht11 leia erros",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "Quando dht11 e RGB definem o brilho em dois fios respectivamente, a configuração de brilho RGB pode causar erro de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "Quando dht11 e RGB estão desligados em duas linhas, RGB desligado pode causar erros de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "Quando as cores de exibição dht11 e RGB estão em dois segmentos, respectivamente, as cores de exibição RGB podem causar erros de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "Quando dht11 e RGB exibem cores de gradiente em dois threads respectivamente, as cores de gradiente de exibição RGB podem causar erros de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "Quando os pixeis em movimento dht11 e RGB estão em duas linhas respectivamente, os pixils em movimento RGB podem causar erro de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "Quando os pixeis movidos ciclicamente dht11 e RGB estão em dois fios respectivamente, os pixeis movidos ciclicamente RGB podem fazer com que o dht11 leia erros",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "Quando as colunas de exibição dht11 e RGB estão em dois threads respectivamente, a coluna de exibição RGB pode causar erros de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "Quando dht11 e a porta de série soft são enviados em dois threads respectivamente, a transmissão da porta de série soft pode causar erro de leitura dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "Quando dht11 e obloq mqtt são inicializados em dois threads respectivamente, a inicialização de obloq mqtt pode causar erros de leitura de dht11. Recomenda-se aguardar a conclusão da inicialização antes de ler dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "Quando dht11 e obloq mqtt enviam mensagens em dois threads, obloq mqtt pode causar erros de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "Quando os pedidos dht11 e obloq http put estão em dois threads respectivamente, isso pode causar erros de leitura do dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "Quando dht11 e obloq http são inicializados em dois threads, isso pode causar um erro de leitura de dht11. Recomenda-se aguardar a conclusão da inicialização antes de ler dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "Quando as solicitações dht11 e obloq http get estão em dois threads respectivamente, pode fazer com que o dht11 leia erros",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "Quando as solicitações de postagem dht11 e obloq http estão em dois threads respectivamente, isso pode causar erros de leitura de dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "Quando dht11 e a emissão de infravermelho estão em dois fios separadamente, pode causar erro de leitura de dht11, é recomendado reduzir a frequência da emissão de infravermelho",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "Quando ds18b20 e PM2.5 estão em dois encadeamentos, respectivamente, pode causar erros de leitura de PM2.5. Recomenda-se reduzir a frequência de ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "Quando ds18b20 e obloq mqtt são inicializados em dois encadeamentos, respectivamente, pode fazer com que a inicialização mqtt falhe. É recomendável aguardar a conclusão da inicialização antes de ler ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "Quando as solicitações put ds18b20 e obloq hqtt put estão em dois encadeamentos, respectivamente, a solicitação put pode falhar. Recomenda-se reduzir a frequência de leitura de ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "Quando ds18b20 e obloq http são inicializados em dois encadeamentos respectivamente, pode causar falha na inicialização do http. Recomenda-se aguardar a conclusão da inicialização antes de ler ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "Quando as solicitações ds18b20 e obloq hqtt get estão em dois encadeamentos, respectivamente, a solicitação get pode falhar. É recomendável reduzir a frequência de leitura de ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "Quando as solicitações de exibição ds18b20 e obloq hqtt estão em dois encadeamentos respectivamente, isso pode fazer com que a solicitação de exibição falhe. Recomenda-se reduzir a frequência de leitura de ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "Ler ds18b20 pode fazer com que o infravermelho não receba dados, é recomendado reduzir a frequência de leitura de ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "Ler ds18b20 pode causar erros no recebimento de dados da porta de série Soft. Recomenda-se reduzir a frequência de leitura de ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "Quando a leitura PM2.5 e a inicialização obloq mqtt estão em dois threads separadamente, pode causar falha de inicialização mqtt ou erro de leitura PM2.5. Recomenda-se evitar a execução simultânea de inicialização mqtt e leitura PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "Quando a leitura de PM2.5 e obloq mqtt enviam mensagens em dois threads, isso pode causar erros de leitura de PM2.5. É recomendado reduzir a frequência de leitura de PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "Quando PM2.5 read e obloq http put request estão em dois threads separadamente, pode fazer com que a put request falhe ou PM2.5 leia o erro. Recomenda-se reduzir a frequência de put request ou reduzir a frequência de put PM2.5 read",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "Quando a leitura de PM2.5 e a inicialização obloq de http estão em dois threads, isso pode causar falha de inicialização de http ou erro de leitura de PM2.5. Recomenda-se evitar a execução simultânea de inicialização de http e leitura de PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "Quando as solicitações de leitura de PM2.5 e obloq http get estão em dois threads separadamente, isso pode causar falha na solicitação de obtenção ou erro de leitura de PM2.5. É recomendado reduzir a frequência de solicitações de obtenção ou reduzir a frequência de leitura de PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "Quando a leitura dePM2.5 e obloq após solicitações de http estão em dois threads, isso pode causar falha de solicitação de exibição ou erro de leitura de PM2.5. É recomendado reduzir a frequência de solicitações de exibição ou reduzir a frequência de leitura de PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "Quando a leitura de PM2.5 e a inicialização de MP3 estão em dois threads separadamente, a leitura de PM2.5 pode falhar. Recomenda-se evitar a leitura de PM2.5 e a inicialização de MP3 ao mesmo tempo.",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "Quando a leitura de PM2.5 e o volume de configuração de MP3 estão em dois segmentos, pode causar falha na leitura de PM2.5. Recomenda-se não ajustar o volume com muita frequência",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "Quando a leitura de PM2.5 e a reprodução de MP3 estão em dois threads, isso pode fazer com que a leitura de PM2.5 falhe. Recomenda-se não tocar com muita frequência no modo de dispositivo",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "Quando a leitura de PM2.5 e a reprodução de MP3 estão em dois threads separadamente, a leitura de PM2.5 pode falhar. Recomenda-se não alternar as músicas com muita frequência",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "Quando a leitura de PM2.5 e a reprodução de série de MP3 estão em dois threads separadamente, pode causar falha na leitura de PM2.5. Recomenda-se não tocar com muita frequência no modo de dispositivo",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "Quando a leitura de PM2.5 e a reprodução de série de MP3 estão em dois threads separadamente, a leitura de PM2.5 pode falhar. Recomenda-se não alternar as músicas com muita frequência",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "Quando a leitura de PM2.5 e o volume de configuração de MP3 de série estão em dois threads separadamente, a leitura de PM2.5 pode falhar. Recomenda-se não ajustar o volume com muita frequência",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "Quando a leitura de PM2,5 puder causar falha na recepção de infravermelho, é recomendado reduzir a frequência de leitura de PM2,5",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "Quando a leitura de PM2.5 e a inicialização de RGB estão em dois threads separadamente, isso pode causar erros de leitura de PM2.5. Recomenda-se evitar a leitura de PM2.5 e a inicialização de RGB ao mesmo tempo.",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "Quando a leitura de PM2.5 e a configuração de brilho de RGB estão em dois fios separados, pode causar erro de leitura de PM2.5. Recomenda-se não ajustar o brilho com muita freqüência",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "Quando a leitura de PM2.5 e RGB desligado estão em dois fios, isso pode causar erros de leitura de PM2.5. É recomendado não apagar RGB com muita frequência",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "Quando a leitura de PM2.5 e as cores de exibição RGB estão em duas linhas, isso pode causar erros de leitura de PM2.5. Recomenda-se não trocar as cores com muita freqüência",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "Quando a leitura de PM2.5 e a cor do gradiente de exibição RGB estão em dois fios separadamente, pode causar erro de leitura de PM2.5, é recomendado não trocar a cor do gradiente com muita frequência",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "Quando a leitura de PM2.5 e pixels em movimento RGB estão em dois fios separadamente, isso pode causar erros de leitura de PM2.5. É recomendado não mover pixels com muita frequência",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "O uso de Wi-Fi pode fazer com que o rgb integrado exiba cores anormais",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "udp usa Wi-Fi, o que pode fazer com que o rgb integrado exiba cores anormais",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "O uso de Wi-Fi pode fazer com que as configurações de rgb on-board sejam anormais em três cores",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "Wi-Fi é usado em udp, o que pode fazer com que a configuração de rgb on-board seja anormal em três cores",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "O uso de Wi-Fi pode causar brilho anormal das configurações de rgb onboard",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "udp usa Wi-Fi, o que pode causar brilho anormal das configurações rgb onboard",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "O uso de Wi-Fi pode fazer com que o rgb integrado obtenha um brilho anormal",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "udp usa Wi-Fi, o que pode causar brilho anormal no rgb integrado",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "Usar Wi-Fi pode causar inicialização anormal do mp3 da porta de série",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "udp usa Wi-Fi, o que pode fazer com que a inicialização do mp3 da porta de sériel seja anormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "O uso de Wi-Fi pode fazer com que a reprodução de mp3 da porta de série seja anormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "udp usa Wi-Fi, o que pode fazer com que a reprodução de mp3 da porta de sériel seja anormal",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "O uso de Wi-Fi pode causar falha na reprodução de mp3 na porta de série",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "udp usa Wi-Fi, o que pode causar falha na reprodução de mp3 da porta de série",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "O uso de Wi-Fi pode causar falha na configuração do volume do mp3 da porta de série",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "udp usa Wi-Fi, o que pode causar falha na configuração do volume do mp3 da porta de série",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "O uso de Wi-Fi pode causar velocidade de rotação anormal do servo 360",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "udp usa Wi-Fi, o que pode fazer com que o servo 360 defina a velocidade de maneira anormal",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "Quando a leitura de PM2.5 e pixeis de movimento circular RGB estão em duas linhas, isso pode causar erros de leitura de PM2.5. Recomenda-se não executar pixeis de movimento circular com muita frequência",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "Quando a leitura de PM2.5 e a coluna de exibição RGB estão em dois threads separadamente, isso pode causar um erro de leitura de PM2.5. É recomendado não executar a coluna de exibição RGB com muita frequência",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "Quando a leitura de PM2.5 e a inicialização da matriz de pontos do led estão em dois threads separadamente, pode causar erros de inicialização da matriz de pontos do LED. Recomenda-se evitar a leitura de PM2.5 e a inicialização da matriz de pontos do led ao mesmo tempo.",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "Quando a leitura de PM2.5 e a exibição de matriz de pontos de led estão em dois fios separadamente, pode causar erro de exibição de matriz de pontos de led, é recomendado reduzir a frequência de leitura de PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "A leitura de PM2.5 pode causar erros de leitura de PM2.5, é recomendado não enviar dados com muita frequência",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "A leitura de PM2.5 pode causar falha na leitura da porta de série do software. Recomenda-se não ler PM2.5 com muita frequência",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 pode fazer com que o infravermelho não receba dados, é recomendado reduzir a frequência de uso de MP3",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "Quando MP3 e obloq mqtt são inicializados em dois threads respectivamente, a inicialização mqtt pode falhar. Recomenda-se evitar a inicialização de mqtt e a execução de MP3 ao mesmo tempo",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "Quando as solicitações put MP3 e obloq http estão em dois threads respectivamente, a solicitação put pode falhar. Recomenda-se reduzir a frequência das solicitações put ou reduzir a frequência de uso de MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "Quando a inicialização de MP3 e obloq http estão em dois threads separadamente, a inicialização de http pode falhar. Recomenda-se evitar a inicialização de http e a execução de MP3 ao mesmo tempo",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "Quando as solicitações get de MP3 e obloq http estão em dois threads separadamente, a solicitação get pode falhar. Recomenda-se reduzir a frequência das solicitações get ou reduzir a frequência de uso de MP3",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "Quando as solicitações de exibição de MP3 e obloq http estão em dois threads separadamente, a solicitação de exibição pode falhar. Recomenda-se reduzir a frequência das solicitações de exibição ou reduzir a frequência de uso de MP3",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 pode fazer com que a porta de série do software falhe ao ler, é recomendado não usar MP3 com muita frequência",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "A porta de série MP3 pode fazer com que o infravermelho não receba dados, é recomendado reduzir a frequência de uso do MP3 pela porta de série",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "Quando a porta serial MP3 e obloq mqtt são inicializados em dois threads respectivamente, a inicialização mqtt pode falhar. Recomenda-se evitar a execução simultânea da inicialização mqtt e da porta serial MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "Quando as solicitações put da porta de sériel MP3 e obloq http estão em dois threads, respectivamente, a solicitação put pode falhar. Recomenda-se reduzir a frequência das solicitações put ou reduzir a frequência de uso da porta de série MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "Quando a porta de sériel MP3 e obloq http são inicializados em dois threads separadamente, a inicialização do http pode falhar. Recomenda-se evitar a execução simultânea da inicialização do http e da porta de série MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "Quando as solicitações get da porta de série MP3 e obloq http estão em dois threads respectivamente, a solicitação get pode falhar. Recomenda-se reduzir a frequência das solicitações get ou reduzir a frequência de uso da porta de série MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "Quando as solicitações de exibição da porta de série MP3 e obloq http estão em dois threads respectivamente, a solicitação de exibição pode falhar. Recomenda-se reduzir a frequência das solicitações de exibição ou reduzir a frequência de uso da porta de série MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "A porta de série MP3 pode causar falha na leitura da porta de sériel do software, é recomendado não usar a porta de série MP3 com muita frequência",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "A emissão infravermelha pode causar erros no recebimento do obloq da porta de série soft, é recomendado reduzir a frequência de emissão infravermelha",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "O obloq da porta de série soft pode causar erros no recebimento de dados por infravermelho. Recomenda-se evitar o obloq de enviar mensagens com freqüência",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "A inicialização de faixas de luz RGB pode causar erros no recebimento de mensagens da porta de série soft obloq. Recomenda-se evitar o uso frequente de faixas de luz RGB",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "Definir o brilho da faixa de luz pode causar erros na recepção de mensagens da porta de série soft obloq. Recomenda-se evitar ajustes de brilho frequentes",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "Desligar a faixa de luz RGB pode causar erros no recebimento de mensagens da porta de série soft obloq. Recomenda-se evitar faixas de luz frequentes",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "A exibição de faixa de luz RGB pode causar erros no recebimento de mensagens da porta de série soft obloq. Recomenda-se evitar exibições coloridas frequentes",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "Faixas de luz RGB mostrando cores de gradiente podem causar erros no recebimento de mensagens da porta de série soft obloq. Recomenda-se evitar a exibição frequente de cores de gradiente",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "Mover pixels de faixas de luz RGB pode causar erros no recebimento de mensagens da porta de série soft obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "Faixas de luz RGB que circulam continuamente pixels podem causar erros no recebimento de mensagens da porta de série soft obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "O formato da coluna do display de faixa de luz RGB pode causar o erro de recebimento da mensagem da porta de série do software",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "Quando o obloq da porta de série soft e a inicialização da matriz de pontos de led estão em dois threads respectivamente, pode causar um erro de inicialização da matriz de pontos de LED. Recomenda-se evitar a execução simultânea de obloq de porta de série soft e inicialização de matriz de pontos de led",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "Quando o obloq da porta de série soft e o ecrã da matriz de pontos de led estão em dois fios respectivamente, pode causar um erro de ecrã da matriz de pontos de led",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "O enviode dados da porta de série soft pode causar a obloq a leitura de erros. Recomenda-se não enviar dados com muita frequência",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "O envio da porta de série soft pode causar falha na leitura da porta de série soft, é recomendado não enviar dados com muita frequência",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "A emissão infravermelha pode fazer com que o infravermelho não receba dados, é recomendável reduzir a frequência de emissão infravermelha",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "Quando a emissão infravermelha pode causar falha na leitura da porta de série do software, é recomendado reduzir a frequência de emissão infravermelha",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "A inicialização de faixas de luz RGB pode fazer com que o infravermelho não receba dados",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "Definir o brilho da faixa de luz RGB pode fazer com que o infravermelho não receba dados, é recomendado evitar a configuração frequente do brilho da faixa de luz RGB",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "Se a faixa de luz RGB estiver desligada, o infravermelho pode não receber dados",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "As cores de exibição das faixas de luz RGB podem fazer com que o infravermelho não receba dados, é recomendado evitar cores de exibição frequentes das faixas de luz RGB",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "A exibição gradual da cor da faixa de luz RGB pode fazer com que o infravermelho não receba dados. Recomenda-se evitar a exibição frequente da cor gradual da faixa de luz RGB",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "Mover pixeis da faixa de luz RGB pode fazer com que o infravermelho não receba dados. Recomenda-se evitar o movimento frequente de pixeis",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "Pixeis da faixas de luz RGB que se movem ciclicamente podem fazer com que o infravermelho não receba dados, é recomendado evitar movimento cíclico frequente de pixeis de faixas de luz RGB",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "A exibição em coluna da faixa de luz RGB pode fazer com que o infravermelho não receba dados. Recomenda-se evitar a exibição frequente da faixa de luz RGB  em coluna",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "A recepção de infravermelho pode causar falha de inicialização do led",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "A recepção infravermelha pode causar erro de exibição de LED",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "O envio através da porta serial soft pode fazer com que o infravermelho não receba dados. Recomenda-se evitar o envio frequente da porta serial soft",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "As faixas de luz RGB podem causar falha na leitura da porta de série do software, é recomendado reduzir a frequência das faixas de luz RGB",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Dados de receção de porta de série de software podem causar erro de exibição da matriz de pontos de LED",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "O envio da porta de série soft pode causar erros na receção de dados pela porta serial soft. Recomenda-se evitar o envio e a receção de dados ao mesmo tempo.",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "A inicialização da porta de série do MP3 pode causar erros na recepção de dados por infravermelho, é recomendável evitar a execução simultânea",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "Definir o modo de reprodução da porta de série MP3 pode causar erros na recepção de dados por infravermelho. Recomenda-se evitar definir o modo de reprodução com muita freqüência",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "A reprodução de MP3 de série pode causar erros na recepção de dados por infravermelho, é recomendado evitar a troca de músicas com muita frequência",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "Definir o volume da porta de série MP3 pode causar erros na recepção de dados infravermelhos. Recomenda-se evitar ajustar o volume com muita frequência",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "A inicialização de max7219 pode causar erros na recepção de dados infravermelhos. Recomenda-se evitar a execução simultânea",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "exibição de imagens de imagens Max7219  pode causar erros na recepção de dados infravermelhos, é recomendado evitar a exibição de imagens com muita frequência",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "O módulo MP3 da porta de série pode fazer com que a interrupção do Pin não seja acionada. Recomenda-se evitar o uso do módulo MP3 da porta de série com muita frequência",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "O módulo de exibição max7219 pode fazer com que a interrupção do Pin não seja acionada. Recomenda-se evitar o uso do visor max7219 com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "A leitura de dht pode causar erro de recebimento de mensagem infravermelha, é recomendado reduzir a frequência de leitura de dht",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "A leitura de ds18b20 pode fazer com que o software receba mensagens de erro, é recomendado reduzir a frequência de leitura de ds18b20",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "A leitura de PM2.5 pode fazer com que o software receba mensagens de erro, é recomendado reduzir a frequência de leitura de PM2.5",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "A leitura de inicialização de mp3 pode causar erro de receção de mensagem infravermelho, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "Definir o volume de mp3 pode causar erros na recepção de mensagens infravermelhas. Recomenda-se evitar ajustar o volume com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "O modo de configuração de mp3 pode causar o erro de receção de mensagem por infravermelho, é recomendado evitar configurar o modo com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "A reprodução de MP3 pode causar erros na recepção de mensagens infravermelhas. Recomenda-se evitar trocar de música com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "A leitura de inicialização de mp3 da porta de série pode causar erro de receção de mensagem infravermelho, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "A reprodução de mp3 na porta de série pode causar erros de receção de mensagens por infravermelho; é recomendável evitar trocar de música com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "Definir o volume da porta de série mp3 pode causar um erro na mensagem de recepção de infravermelho. Recomenda-se evitar ajustar o volume com muita freqüência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "A emissão de infravermelho pode causar o erro de receção de mensagem infravermelha, é recomendado evitar o envio de infravermelho com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "A inicialização de faixas de luz RGB pode causar erros na recepção de mensagens infravermelhas. Recomenda-se evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "Definir o brilho da faixa de luz RGB pode causar um erro na mensagem de receção de infravermelho. Recomenda-se evitar definir o brilho com muita freqüência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "A exibição de faixa de luz RGB pode causar erro de receção de mensagem infravermelho, é recomendado evitar a exibição de faixa de luz RGB com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "A exibição gradual de cores da faixa de luz RGB pode causar erros na mensagem de receção de infravermelho. Recomenda-se evitar a exibição de cores graduais com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Mover pixeis de faixas de luz RGB pode causar erros na recepção de mensagens infravermelhas. Recomenda-se evitar mover pixeis com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "Faixas de luz RGB movendo pixeis continuamente podem causar erros na recepção de mensagens infravermelhas. Recomenda-se evitar pixels em movimento contínuo excessivamente frequentes",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "A barra de exibição de faixa de luz RGB pode causar erro de receção de mensagem infravermelho, é recomendado evitar a exibição de barra com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "A receção de mensagens infravermelhas pode causar falha de inicialização max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "A receção de mensagem de infravermelho pode causar erro de exibição max7219",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "A impressão da porta de série soft pode causar erros na receção de mensagens por infravermelho. Recomenda-se evitar a impressão da porta  de série soft com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "A inicialização da porta de série soft obloq mqtt pode causar erros na recepção de dados infravermelhos, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "A solicitação obloq put da porta de série soft pode causar erros na recepção de dados infravermelhos. Recomenda-se evitar a solicitação de dados com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "A inicialização da porta de série soft obloq http pode causar erros na recepção de dados infravermelhos. Recomenda-se evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "A solicitação obloq get da porta de série soft pode causar erros na recepção de dados por infravermelho. Recomenda-se evitar solicitações de dados muito frequentes",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "A solicitação post obloq da porta serial suave pode causar erros na recepção de dados infravermelhos. Recomenda-se evitar solicitar dados com muita frequência",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "Limpar a exibição de faixas de luz RGB pode causar erros na recepção de mensagens de infravermelho. É recomendado evitar limpar a exibição com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "A leitura de DHT pode levar a um erro de mensagem de receção de série soft, é recomendado reduzir a frequência de leitura de DHT",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "A leitura de ds18b20 pode fazer com que a porta de série do software receba uma mensagem de erro. Recomenda-se reduzir a frequência de leitura de ds18b20",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "A leitura de PM2.5 pode fazer com que a porta de série do software receba uma mensagem de erro, é recomendado reduzir a frequência de leitura de PM2.5",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "A leitura da inicialização do mp3 pode fazer com que a porta de série do software receba erros de mensagem, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "Definir o volume de mp3 pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar ajustar o volume com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "O modo de configuração de mp3 pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar configurar o modo com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "A reprodução de mp3 pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar trocar de música com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "A leitura da inicialização do mp3 da porta de série pode fazer com que a porta de série do software receba mensagens incorretamente, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "O modo de configuração de mp3 da porta de série pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar configurar o modo com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "A reprodução de mp3 na porta de série pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar trocar de música com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "Definir o volume da porta de série mp3 pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar ajustar o volume com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "A emissão de infravermelho pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar o envio de infravermelho com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "A inicialização de faixas de luz RGB pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "Definir o brilho da faixa de luz RGB pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar definir o brilho com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "A exibição clara da faixa de luz RGB pode fazer com que a porta de série do software receba uma mensagem de erro. Recomenda-se evitar limpar a exibição com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "A exibição de faixa de luz RGB pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar a exibição de faixa de luz RGB com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "A exibição gradual de cores da faixa de luz RGB pode fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar a exibição de cores graduais com muita freqüência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Mover pixeis da faixa de luz RGB pode causar erros na recepção de mensagens da porta de série. Recomenda-se evitar mover pixeis com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "Faixas de luz RGB movendo pixels continuamente podem fazer com que a porta de série do software receba mensagens incorretamente. Recomenda-se evitar a execução de pixels em movimento contínuo com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "A barra de exibição de faixa de luz RGB pode fazer com que a porta de série do software receba mensagens incorretamente, é recomendado evitar a exibição da barra com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "A receção de mensagens da porta de série do software pode causar falha na inicialização do max7219",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "A porta de série do software que recebe mensagens pode causar erro de exibição em max7219",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "A impressão da porta de série soft pode causar erros na receção de mensagens na porta de série soft. Recomenda-se evitar a impressão da porta de série soft com muita freqüência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "A leitura de dht pode causar falha do gatilho de interrupção do Pin, é recomendado reduzir a frequência de leitura de dht",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "A leitura de ds18b20 pode causar a falha do gatilho de interrupção do Pin, é recomendado reduzir a frequência de leitura de ds18b20",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "A leitura de PM2.5 pode causar falha do gatilho de interrupção do Pin, é recomendado reduzir a frequência de leitura de PM2.5",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "A leitura da inicialização do mp3 pode causar falha no gatilho de interrupção do Pin, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "definir o volume do mp3 pode causar falha no gatilho de interrupção do Pin, é recomendado evitar ajustar o volume com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "O modo de configuração de mp3 pode causar falha no gatilho de interrupção do Pin, é recomendado evitar configurar o modo com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "A reprodução de mp3 pode causar falha no gatilho de interrupção do Pin, é recomendado evitar trocar de música com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "A leitura da inicialização do mp3 da porta de série pode causar falha no gatilho de interrupção do Pin, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "O modo de configuração de mp3 da porta de série pode fazer com que o gatilho de interrupção do Pin falhe. Recomenda-se evitar configurar o modo com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "A reprodução do mp3 de série pode fazer com que a interrupção do Pin não seja acionada, é recomendado evitar trocar de música com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "Definir o volume da porta de série mp3 pode fazer com que o gatilho de interrupção do Pin falhe. Recomenda-se evitar ajustar o volume com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "A emissão de infravermelho pode causar falha no gatilho de interrupção do Pin, é recomendado evitar o envio de infravermelho com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "A inicialização da faixa de luz RGB pode causar a falha do gatilho de interrupção do Pin, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "Definir o brilho da faixa de luz RGB pode fazer com que o gatilho de interrupção do Pin falhe. Recomenda-se evitar definir o brilho com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "Limpar o ecrã da faixa de luz RGB pode fazer com que o gatilho de interrupção do Pin falhe. Recomenda-se evitar limpar o ecrã com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "A exibição de faixa de luz RGB pode causar falha do gatilho de interrupção do Pin, é recomendado evitar a exibição de faixa de luz RGB com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "Faixas de luz RGB que mostram cores de gradiente podem fazer com que o gatilho de interrupção do Pin falhe. Recomenda-se evitar a exibição de cores de gradiente com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "Faixas de luz RGB com movimento de pixeis podem fazer com que a interrupção do Pin não seja acionada. Recomenda-se evitar mover pixeis com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "Faixas de luz RGB que movem pixeis continuamente podem fazer com que a interrupção do Pin não seja acionada. Recomenda-se evitar a execução excessiva de pixeis em movimento contínuo",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "A barra de exibição de faixa de luz RGB pode fazer com que o gatilho de interrupção do Pin falhe, é recomendado evitar a exibição da barra com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "A interrupção do Pin pode causar falha de inicialização max7219",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "A interrupção do Pin pode causar erro de exibição max7219",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "A impressão da porta de série soft pode causar falha no gatilho de interrupção do Pin. Recomenda-se evitar a execução muito frequente de impressão da porta de série soft",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "A inicialização da porta de série soft obloq mqtt pode fazer com que o gatilho de interrupção do Pin falhe. Recomenda-se evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "A solicitação obloq put da porta de série soft pode fazer com que o gatilho de interrupção do Pin falhe. Recomenda-se evitar a solicitação de dados com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "A inicialização de http obloq da porta de série soft pode fazer com que o gatilho de interrupção do Pin falhe, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "A solicitação de obtenção do obloq da porta de série pode fazer com que o gatilho de interrupção do Pin falhe. Recomenda-se evitar solicitar dados com muita frequência",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "A solicitação post obloq da porta de série soft pode fazer com que o gatilho de interrupção do Pin falhe. Recomenda-se evitar a solicitação de dados com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "A leitura de dht pode fazer com que a porta de série soft obloq receba mensagens incorretamente, é recomendado reduzir a frequência de leitura de dht",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "A leitura de ds18b20 pode causar erros na receção de mensagens da porta de série soft obloq. Recomenda-se reduzir a frequência de leitura de ds18b20",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "A leitura de PM2.5 pode fazer com que a porta de série do software obloq receba erros de mensagem. Recomenda-se reduzir a frequência de leitura de PM2.5",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "A leitura da inicialização do mp3 pode fazer com que a porta de série do software obloq receba mensagens incorretamente, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "Definir o volume de mp3 pode fazer com que a porta de série do software obloq receba mensagens incorretamente. Recomenda-se evitar ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "O modo de configuração de mp3 pode fazer com que a porta de série do software obloq receba mensagens incorretamente. Recomenda-se evitar configurar o modo com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "A reprodução de mp3 pode fazer com que a porta de série do software obloq receba mensagens incorretamente. Recomenda-se evitar trocar de música com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "A leitura da inicialização do mp3 da porta de série pode fazer com que a porta de série do software obloq receba mensagens incorretamente, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "O modo de configuração de mp3 da porta de série pode fazer com que a porta de série soft obloq receba mensagens incorretamente. Recomenda-se evitar configurar o modo com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "A reprodução de mp3 na porta de série pode fazer com que a porta de série do software obloq receba mensagens incorretamente. Recomenda-se evitar trocar de música com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "Definir o volume da porta de série mp3 pode fazer com que a porta serial soft obloq receba mensagens incorretamente. Recomenda-se evitar ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "A emissão de infravermelho pode fazer com que a porta de série do software obloq receba mensagens incorretamente. Recomenda-se evitar o envio de infravermelho com muita frequência",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "Enviar mensagens via bloq de porta de série soft pode causar erros de receção de infravermelho. Recomenda-se evitar obstruir o envio de mensagens com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "A inicialização da faixa de luz RGB pode fazer com que a porta de série do software obloq receba mensagens incorretamente. Recomenda-se evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "Definir o brilho da faixa de luz RGB pode fazer com que a porta de série do software obloq receba mensagens incorretamente. Recomenda-se evitar definir o brilho com muita freqüência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "Limpar o ecrã da faixa de luz RGB pode fazer com que a porta de série do software seja bloqueada para receber mensagens incorretamente. Recomenda-se evitar limpar o ecrã com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "A exibição de faixa de luz RGB pode fazer com que a porta de série do software obloq receba mensagens incorretamente. É recomendável evitar a exibição de faixa de luz RGB com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "Faixas de luz RGB mostrando cores de gradiente podem fazer com que a porta de série do software obloq receba mensagens incorretamente. Recomenda-se evitar a exibição de cores de gradiente com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Faixas de luz RGB em movimento de pixeis podem fazer com que a porta de série do software obloq receba mensagens incorretamente. É recomendado evitar mover pixeis com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "Faixas de luz RGB pixeis em movimento contínuo podem fazer com que a porta de série do software obloq receba mensagens incorretamente. Recomenda-se evitar pixeis em movimento contínuo com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "A barra de exibição de faixa de luz RGB pode fazer com que a porta de série soft obloq receba mensagens incorretamente, é recomendado evitar a exibição da barra com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "O obloq da porta de série soft que recebe mensagens pode causar falha de inicialização em max7219",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "A obloq da porta de série do software que recebe mensagens pode causar erro de exibição em max7219",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "A impressão da porta de série soft pode fazer com que a porta de série soft receba mensagens incorretamente. Recomenda-se evitar a impressão da porta de série soft com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "O envio de dados do obloq da porta de série soft pode fazer com que a porta de série do software receba erros de dados, é recomendado evitar o envio de dados obloq com muita frequência",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "O obloq da portade série soft enviando dados pode fazer com que a interrupção do Pin não seja acionada. Recomenda-se evitar o envio de dados obloq com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "A inicialização da porta de série soft obloq mqtt pode causar erros na receção de dados da porta de série soft, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "A solicitação obloq put da porta de série soft pode causar erros nos dados recebidos pela porta de série soft. Recomenda-se evitar a solicitação de dados com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "A inicialização da porta de série soft obloq http pode causar erros na receção de dados da porta de série soft, é recomendado evitar a execução simultânea",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "A solicitação obloq get da porta de série de software pode causar erros na receção de dados da porta de série de software. Recomenda-se evitar a solicitação de dados com muita frequência",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "A pós-solicitação obloq da porta de série de software pode causar erros na receção de dados da porta de série de software. Recomenda-se evitar a solicitação de dados com muita frequência",
    "gui.blocklyText.compatible.shapedBlock": "Mudanças na nova versão: Os blocos do programa de eventos sem variáveis ​​são substituídos pelos blocos do programa de eventos com variáveis.",
    "gui.blocklyText.compatible.shapedBlock.variable": "Mudanças na nova versão: substituíção de blocos de programas independentes por blocos de programas variáveis ​​em programas de eventos",
    "gui.blocklyText.compatible.booleanToRound": "Mudanças na nova versão: Mudança de blocos de programa condicionais para blocos de programa numéricos.",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "Mudanças na nova versão: Adicionar opções suspensas de chave de porta de série soft e hard nos blocos de programa.",
    "gui.blocklyText.compatible.mp3.deletePin": "Mudanças na nova versão: Remover a opção drop-down Pin nos blocos de programa.",
    "gui.blocklyText.compatible.sen0206.addDropdown": "Mudanças na nova versão: adicione opções suspensas nos blocos do programa.",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "Mudanças na nova versão: adicionar opções de Pin pull-down nos blocos de programa.",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "Mudanças na nova versão: Modifique a opção suspensa da porta de série nos blocos de programa.",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "Mudanças na nova versão: Mudança de blocos de programa de evento para blocos de programa condicionais.",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "Mudanças na nova versão: blocos removidos",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "Mudanças na nova versão: Modifique a caixa suspensa quadrada para uma caixa suspensa redonda.",
    "gui.blocklyText.pictureai.initToken": "Inicializar imagem AI [SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "Obtenha fotos de arquivos locais [IMAGE] ",
    "gui.blocklyText.pictureai.getVideoImage": "Tire uma foto do ecrã da câmera",
    "gui.blocklyText.pictureai.webPicture": "Obter imagem de URL [TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH]save camera shortcut to local[TEXT]",
    "gui.blocklyText.pictureai.face": "Reconhecer as informações do rosto de [TEXT] na imagem",
    "gui.blocklyText.pictureai.word": "Identifique [SELECT] contido em [TEXT] na figura",
    "gui.blocklyText.pictureai.picture": "Identifique [SELECT] contido em [TEXT] na figura",
    "gui.blocklyText.pictureai.gesture": "Reconhecer os gestos contidos em [TEXT] na imagem",
    "gui.blocklyText.pictureai.TOPIC_W_A": "palavras",
    "gui.blocklyText.pictureai.TOPIC_W_B": "números",
    "gui.blocklyText.pictureai.TOPIC_W_C": "número de carro",
    "gui.blocklyText.pictureai.TOPIC_W_D": "escrita manual",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "tornozelo_esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "orelha_esquerda",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "cotovelo_esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "olho_esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "quadril_esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "joelho_esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "canto_da_boca_esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "ombro_esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "pulso_esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "pescoço",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "nariz",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "tornozelo_direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "orelha_direita",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "cotovelo_direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "olho_direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "quadril_direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "joelho_direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "canto_da_boca_direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "ombro_direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "punho_direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "topo_da_cabeça",
    "gui.blocklyText.pictureai.initUserToken": "mudar para uma conta separada[MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "Identifique os pontos principais do corpo humano em [TEXT]",
    "gui.blocklyText.pictureai.faceContrast": "Compare os rostos na imagem [TEXT] e na imagem [TEXT2]",
    "gui.blocklyText.pictureai.faceMerge": "Combine os rostos na imagem [TEXT] e na imagem [TEXT2]",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "Corpo da imagem",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "Objetos e cenas de uso geral ",
    "gui.blocklyText.pictureai.TOPIC_P_A": "plantar",
    "gui.blocklyText.pictureai.TOPIC_P_B": "animal",
    "gui.blocklyText.pictureai.TOPIC_P_C": "flor",
    "gui.blocklyText.pictureai.TOPIC_P_D": "vegetais",
    "gui.blocklyText.pictureai.bodyPointSuccess": "Reconhecimento bem sucedido de pontos-chave do corpo humano?",
    "gui.blocklyText.pictureai.bodyPointInfo": "[XY] coordena o resultado da obtenção dos pontos-chave do corpo humano [BODY]",
    "gui.blocklyText.pictureai.noPower": "Verifique se a conta está ativada",
    "gui.blocklyText.pictureai.pictureError": "O formato da imagem está incorreto",
    "gui.blocklyText.pictureai.noPicture": "Selecionar fotografia primeiro ",
    "gui.blocklyText.pictureai.noVideo": "Verifique se a câmera está ligada normalmente sem outro software e ligue a câmera ou reinicie o software",
    "gui.blocklyText.pictureai.videoContainer": "usar [CON] morstrar ecrã de câmera",
    "gui.blocklyText.pictureai.TOPIC_stage": "Palco",
    "gui.blocklyText.pictureai.TOPIC_popup": "popup",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "Alterações na nova versão: Alguns blocos de programas foram excluídos.",
    "gui.blocklyText.pictureai.gestureList": "gesto[TEXT]",
    "gui.blocklyText.pictureai.networkError": "Erro de rede, verifique a ligação de rede ",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "Reconhecimento de faces bem sucedido?",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "Resultados do reconhecimento de faces  [TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "Número de faces",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "idade",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "beleza",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "género",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "óculos",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "tipo",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "expressão",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "probabilidade de face",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "Esquerda",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "topo",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "largura",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "altura",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "Ponto de referência",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "oclusão",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "blur",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "plenitude",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "humano",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "cartoon",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Para a frente",
    "gui.blocklyText.maqueenPlus.Backward": "Para trás",
    "gui.blocklyText.maqueenPlus.left": "Esquerda",
    "gui.blocklyText.maqueenPlus.right": "Direita",
    "gui.blocklyText.maqueenPlus.all": "Tudo",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "Motor [DIR] na velocidade [SPEED] [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "Motor [DIR] Stop",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "Motor [DIR] Compensação de velocidade [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "Leia [DIR] Velocidade do motor",
    "gui.blocklyText.maqueenPlus.readMoterDir": "Leia [DIR] Direção do motor (0: parada 1: frente 2: trás)",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] Ângulo [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "Defina a exibição de luz RGB [DIR] [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "Ler sensor de rastreamento de linha [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "Ler o valor de cinza do sensor de rastreamento de linha [INDEX]",
    "gui.blocklyText.maqueenPlus.getVersion": "obter versão",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "Ler sensor de ultrasons trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "quando recebidos [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "Dados de infravermelhos ",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "paint.paintEditor.hue": "Matiz",
    "paint.paintEditor.saturation": "Saturação",
    "paint.paintEditor.brightness": "Brilho",
    "gui.comingSoon.message1": "Não se preocupe, estamos a trabalhar afincadamente nisto {emoji}",
    "gui.comingSoon.message2": "Em Breve…",
    "gui.comingSoon.message3": "Estamos a trabalhar afincadamente nisto {emoji}",
    "paint.paintEditor.fill": "Interior",
    "paint.paintEditor.costume": "Traje",
    "paint.paintEditor.group": "Agrupar",
    "paint.paintEditor.ungroup": "Desagrupar",
    "paint.paintEditor.undo": "Desfazer",
    "paint.paintEditor.redo": "Refazer",
    "paint.paintEditor.forward": "Avançar",
    "paint.paintEditor.backward": "Recuar",
    "paint.paintEditor.front": "Para a Frente",
    "paint.paintEditor.back": "Para Trás",
    "paint.paintEditor.more": "Mais",
    "paint.modeTools.brushSize": "Tamanho",
    "paint.modeTools.eraserSize": "Tamanho da borracha",
    "paint.modeTools.copy": "Copiar",
    "paint.modeTools.paste": "Colar",
    "paint.modeTools.delete": "Remover",
    "paint.modeTools.curved": "Arredondado",
    "paint.modeTools.pointed": "Em Ângulo",
    "paint.modeTools.thickness": "Espessura",
    "paint.modeTools.flipHorizontal": "Inverter Horizontalmente",
    "paint.modeTools.flipVertical": "Inverter Verticalmente",
    "paint.modeTools.filled": "A cheio",
    "paint.modeTools.outlined": "Com contorno",
    "paint.paintEditor.bitmap": "Converter para Bitmap",
    "paint.paintEditor.vector": "Converter para Vectorial",
    "paint.paintEditor.stroke": "Contorno",
    "paint.brushMode.brush": "Pincel",
    "paint.eraserMode.eraser": "Borracha",
    "paint.fillMode.fill": "Preencher",
    "paint.lineMode.line": "Linha",
    "paint.ovalMode.oval": "Círculo",
    "paint.rectMode.rect": "Rectângulo",
    "paint.reshapeMode.reshape": "Remodelar",
    "paint.roundedRectMode.roundedRect": "Rectângulo Arredondado",
    "paint.selectMode.select": "Seleccionar",
    "paint.textMode.text": "Texto",
    "paint.colorPicker.swap": "Trocar",
    "gui.dialog.notConnectDevice": "Nenhum Dispositivo Ligado ",
    "gui.dialog.prompt": "Aviso",
    "gui.dialog.openPort": "Abrir Port",
    "gui.dialog.error": "Erro",
    "gui.dialog.notSelectDevice": "Nenhum dispositivo selecionado, clique [Extensões] no canto inferior esquerdo do software,</br>clique para selecionar o dispositivo que quer ligar",
    "gui.dialog.connectDeviceError1": "Ligar Dispositivo",
    "gui.dialog.connectDeviceError2": ")Falha, siga os seguintes passos:</br> *Mude de Porta Usb e volte a ligar o dispositivo</br> *Verifique se está ligado",
    "gui.dialog.connectDeviceError3": "Placa de Controle Principal</br> *Junta-te ao Grupo de comunicação oficial (671877416) para reportares problemas ",
    "gui.dialog.calibration1": "</br> *Girar por favor",
    "gui.dialog.calibration2": "Calibrate the main control board</br>",
    "gui.dialog.runAsManager": "Please close Mind+ and right click on the software icon and select \"Run as administrator\". After starting, select this function again.",
    "gui.dialog.runErrorForVortex": "O Sistema não funciona corretamente, desligar Vortex",
    "gui.dialog.runError": "O Sistema não funciona corretamente",
    "gui.dialog.close": "Fechar",
    "gui.dialog.variableNameSpecialCharacters": "nome da variável %1 não pode conter caratéres especiais:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "nome da função %1 não pode conter caratéres especiais:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "Os argumentos da função %1 não podem conter caratéres especiais:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "E",
    "gui.dialog.yes": "Sim",
    "gui.dialog.no": "Não",
    "gui.dialog.maxReset": "Eliminar códigos da área de edição e carregar as definições de fábrica?",
    "gui.dialog.feedbackCannotEmpty": "Por favor preencher nos comentários.",
    "gui.dialog.noInstallCompiler": "Compilador não instalado",
    "gui.dialog.install": "Instalar online",
    "gui.dialog.cancle": "Cancelar",
    "gui.dialog.installingCompiler": "Instalando compilador",
    "gui.dialog.uploading": "A Carregar",
    "gui.dialog.changeLanuage": "Ao trocar a linguagem vai apagar o programa atual. Tem a certeza que quer continuar?",
    "gui.dialog.boardNotSupportMpy": "A placa atual: %1 não suporta micopython",
    "gui.dialog.loadBlankItem": "Item não suportado</br>está a recarregar um item em branco ",
    "gui.dialog.switchBaudError": "Ocorreu um erro ao alterar a taxa de transmissão",
    "gui.dialog.turnMicrobitCompass": "Segure o micro:bit horizontalmente e abane-o na face para encher o ecrâ e calibrar a bússola ",
    "gui.dialog.operationShow": "Demonstração de operação",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "computador",
    "gui.dialog.delete": "Eliminar",
    "gui.dialog.sureDeleteFile": "Tem a certeza que pretende eliminar o ficheiro [%2] no %1?",
    "gui.dialog.sureDeleteFolder": "Tem a certeza que pretende eliminar a Pasta [%2] no %1?",
    "gui.dialog.uncorrectPath": "Caminho incorretamente selecionado ",
    "gui.dialog.laterDownLoad": "Atualizar mais tarde",
    "gui.dialog.dwnLoadWebsite": "Descarregar Website",
    "gui.dialog.laterUpdate": "Instalar mais tarde",
    "gui.dialog.update": "Instalar",
    "gui.dialog.getUpdateMsgError": "Ocorreu um erro ao obter mensagem atualizada ",
    "gui.dialog.ok": "Ok",
    "gui.dialog.closeCodeBoxes": "Tem a certeza que pretende fechar %1 caixas de código?",
    "gui.dialog.emailError": "O formato de email está incorreto ",
    "gui.dialog.emailEmpty": "O E-mail não pode estar vazio!",
    "gui.dialog.bigFilePrompt": "O ficheiro é grande, por favor aguarde, obrigado! ",
    "gui.dialog.successFeedbackPrompt1": "Obrigado pelo seu comentário, a página recarregará em 3 segundos.",
    "gui.dialog.successFeedbackPrompt2": "O seu comentário foi submetido. Por favor, submeta-o após alguns segundos ",
    "gui.dialog.failedFeedbackPrompt": "Submissão falhada. Por favor, visite o forum online para deixar o seu comentário  - https://www.dfrobot.com/forum/, obrigado!",
    "gui.dialog.successFeedbackPrompt3": "Obrigado pelo seu comentário",
    "gui.dialog.forcedCloseSoftWare": "Ocorreu um erro ao gravar o ficheiro, forçar o fecho do software? ",
    "gui.dialog.saveSuccess": "O projeto está guardado",
    "gui.dialog.noSelectTruePath": "Caminho incorretamente selecionado ",
    "gui.dialog.newItemContent": "Irá criar um documento em branco para substituir o projeto atual, tem a certeza? ",
    "gui.dialog.deleteBlocksError": "Falha ao eliminar %1 bloco, elimine manualmente",
    "gui.dialog.netIsConnect": "Confirma se a internet está ligada ",
    "gui.dialog.needToUpdate": "Atualizar o compilador para a última versão: %1?",
    "gui.dialog.cantUseMpythonInArduinoC": "Nenhum compilador instalado, não podes utilizar o mpython com o Arduino C. Se o computador não tiver ligação à internet podes descarregar o \"Plug-in de compilador offline\" no website oficial. ",
    "gui.dialog.enterMpyMode": "Entrar em modo micropython ",
    "gui.dialog.runHardware": "Liga primeiro o dispositivo, depois utiliza o hardware ",
    "gui.dialog.sureDelete": "Tem a certeza que deseja eliminar?",
    "gui.dialog.notSupportWebGL": "Ocorreu um erro ao carregar o palco, isto pode acontecer quando o teu computador não suporta o WebGL,</br> Observa os detalhes em baixo espostos: </br> %1 </br>QQ grupo contacta-nos: 671877416",
    "gui.dialog.boardIsnotMPython": "A placa de controlo principal que está ligada não é mPython, por favor liga a mPython ",
    "gui.dialog.swdMicrobitUpload": "O computador deve utilizar SWD para gravar micro:bit, abre o Mind+ com privilégios de administrador.",
    "gui.dialog.openPortError": "Abrir port %1 erro: %2",
    "gui.dialog.pressedA": "Prima o botão A, aguarde sem o soltar",
    "gui.dialog.unpressedA": "Soltar butão A",
    "gui.dialog.unsupportProject": "Item não suportado</br> está a recarregar um item em brancos.",
    "gui.dialog.openProjectError": "Projetos criados em Modo Scratch devem abertos em Modo Scratch</br> Verifique o modo atual..",
    "gui.dialog.installCompilerError1": "O Download falhou. Pode descarregar o instalador offline ou voltar a tentar.",
    "gui.dialog.installCompilerOffline": "Descarregar Compilador offline ",
    "gui.dialog.installCompilerError2": "Erro de verificação. Pode descarregar o instalador offline ou tentar descarregar novamente. ",
    "gui.dialog.installCompilerError3": "Erro de instalação. Pode descarregar o instalador offline ou tentar descarregar novamente. ",
    "gui.dialog.installCompilerSuccess": "O compilador está instalado, pode utilizar  Esp32 sob o ArduinoC. ",
    "gui.dialog.sureAdapter": "Adaptador não encontrado, por favor confirmar se o adaptador está ligado. ",
    "gui.dialog.checkPort": "Falha ao abrir a porta de série, por favor verifique se a porta de série está ocupada. ",
    "gui.dialog.changeBoard": "Mudar a placa irá limpar o programa atual. Tem a certeza de que pretende continuar?",
    "gui.crashMessage.somethingWrong": "Oops! Alguma coisa correu mal.",
    "gui.crashMessage.reload": "Recarregar",
    "gui.crashMessage.submit": "Submeter",
    "gui.crashMessage.attachment": "Anexo",
    "gui.crashMessage.errFeedbackNote": "Pode escrever mensagens de erro ou sugestões aqui, Mind+ will irá melhorar com o seu contributo.",
    "gui.crashMessage.promptContent": "Pedimos desculpa ma parece que o Mind + teve um problema. Pode enviar o erro para a equipa do  Mind + no lado direito, obrigado! Atualize a sua página.",
    "gui.dialog.isSaveProject": "Guardar o projeto atual?",
    "gui.dialog.save": "Guardar",
    "gui.dialog.notSave": "Não Guardar",
    "gui.dialog.continue": "Continuar",
    "gui.dialog.downLoadUpdate": "Descarregar Atualização",
    "gui.dialog.variableIsNull": "O nome da variável não pode estar vazio ",
    "gui.dialog.listNameIsNull": "O nome da lista não pode estar vazio ",
    "gui.dialog.variableIsRepeat": "o nome das variáveis não se podem repetir, modifique por favor ",
    "gui.dialog.pictureAINotNull": "cannot be null",
    "gui.dialog.pictureAIInitServer": "Inicialize o servidor primeiro",
    "gui.dialog.pictureAIInitServerError": "Erro ao inicilaizar o servidor, verifique o número de conta e a chave secreta",
    "gui.dialog.versionPrompt": "Versão atual do ficheiro sb3 「%1」",
    "gui.dialog.versionGreater": "A versão do ficheiro Sb3 é maior do que a versão atual do software, versão do ficheiro sb3「%1」",
    "gui.dialog.noModulePrompt": "Não existem módulos pequenos na classificação atual.ver ajuda para detalhes.",
    "gui.dialog.firstSelectBoard": "Por favor primeiro selecione \"Placa\" ou 'Kit'.",
    "gui.dialog.downloadFileError": "Erro de descarga de ficheiro",
    "gui.dialog.noSearchModules": "Não foram encontrados módulos relacionados, verificar o caminho de pesquisa",
    "gui.dialog.networkError": "Erro de Internet",
    "gui.dialog.saveThirdModulesError": "Erroa ao gravar Biblioteca de utilizador",
    "gui.dialog.isContinueUpdateThirdModule": "Atualmete existem as mesmas bibliotecas de extensões. A continuidade da atualizaçãoo irá apagar a biblioteca de utilizador atual, deseja continuar?",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "Modificado o campo「%2」 de %1, que necessita de ser recarregado manualmente na libraria de extensões. Este atualizar está cancelado",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "Erro de análise %1, a atualização vai continuar, mas o conteúdo 「%2」 não vai ser atualizado",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "A forma do bloco de construção ou o tipo de caixa de entrada é modificado. Apague manualmente o bloco de construção destacado na área de programação, e então  atualiza. Esta atualização foi cancelada.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "Modificação da forma do bloco de construção ou o tipo de caixa de entrada , Apague manualmente o bloco de construção destacado na área de programação antes de carregar, e o carregamento é cancelado.",
    "gui.dialog.thirdex.filesEmpty": "O campo dos ficheiros não pode estar vazio, pode levar a que se percam ficheiros relacionados. Verifique os ficheiros da biblioteca de utilizador.",
    "BACKDROPS_ARCTIC": "Arctic",
    "BACKDROPS_BASEBALL": "Baseball",
    "BACKDROPS_BASEBALL_2": "Baseball 3",
    "BACKDROPS_BASKETBALL": "Basketball",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Beach Malibu",
    "BACKDROPS_BEACH_RIO": "Beach Rio",
    "BACKDROPS_BEDROOM": "Bedroom",
    "BACKDROPS_BEDROOM_1": "Bedroom 1",
    "BACKDROPS_BEDROOM_2": "Bedroom 2",
    "BACKDROPS_BEDROOM_3": "Bedroom 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Bench With View",
    "BACKDROPS_BLUE_SKY": "Blue Sky",
    "BACKDROPS_BLUE_SKY_2": "Blue Sky 2",
    "BACKDROPS_BLUE_SKY3": "Blue Sky3",
    "BACKDROPS_BOARDWALK": "Boardwalk",
    "BACKDROPS_CANYON": "Canyon",
    "BACKDROPS_CASTLE_1": "Castle 1",
    "BACKDROPS_CASTLE_2": "Castle 2",
    "BACKDROPS_CASTLE_3": "Castle 3",
    "BACKDROPS_CASTLE_4": "Castle 4",
    "BACKDROPS_CHALKBOARD": "Chalkboard",
    "BACKDROPS_CIRCLES": "Circles",
    "BACKDROPS_CITY_WITH_WATER": "City With Water",
    "BACKDROPS_COLORFUL_CITY": "Colorful City",
    "BACKDROPS_CONCERT": "Concert",
    "BACKDROPS_DESERT": "Desert",
    "BACKDROPS_FARM": "Farm",
    "BACKDROPS_FIELD_AT_MIT": "Field At Mit",
    "BACKDROPS_FLOWERS": "Flowers",
    "BACKDROPS_FOREST": "Forest",
    "BACKDROPS_GALAXY": "Galaxy",
    "BACKDROPS_GARDEN-ROCK": "Garden-rock",
    "BACKDROPS_GREEK_THEATER": "Greek Theater",
    "BACKDROPS_HALL": "Hall",
    "BACKDROPS_HAY_FIELD": "Hay Field",
    "BACKDROPS_GRAFFITI": "Graffiti",
    "BACKDROPS_HEARTS1": "Hearts2",
    "BACKDROPS_HEARTS2": "Hearts3",
    "BACKDROPS_HILL": "Hill",
    "BACKDROPS_JUNGLE": "丛林",
    "BACKDROPS_JURASSIC": "Jurassic",
    "BACKDROPS_LIGHT": "Light",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": "Moon",
    "BACKDROPS_MOUNTAIN": "Mountain",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "Nebula",
    "BACKDROPS_NEON_TUNNEL": "Neon Tunnel",
    "BACKDROPS_NIGHT_CITY": "Night City",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Night City With Street",
    "BACKDROPS_PARTY": "Party",
    "BACKDROPS_PATHWAY": "Pathway",
    "BACKDROPS_PARTY_ROOM": "Party Room",
    "BACKDROPS_PLAYGROUND": "Playground",
    "BACKDROPS_PLAYING_FIELD": "Playing Field",
    "BACKDROPS_POOL": "Pool",
    "BACKDROPS_PURPLE": "Purple",
    "BACKDROPS_RAYS": "Rays",
    "BACKDROPS_REFRIGERATOR": "Refrigerator",
    "BACKDROPS_ROOM_1": "Room 1",
    "BACKDROPS_ROOM_2": "Room 2",
    "BACKDROPS_SAVANNA": "Savanna",
    "BACKDROPS_SCHOOL": "School",
    "BACKDROPS_SLOPES": "Slopes",
    "BACKDROPS_SOCCER": "Soccer",
    "BACKDROPS_SOCCER_2": "Soccer 3",
    "BACKDROPS_SPACE": "Space",
    "BACKDROPS_SPACE_2": "Space 3",
    "BACKDROPS_SPACE_3": "Space 4",
    "BACKDROPS_SPACE_4": "Space 5",
    "BACKDROPS_SPOTLIGHT-STAGE": "Spotlight-stage",
    "BACKDROPS_STARS": "Stars",
    "BACKDROPS_STRIPES": "Stripes",
    "BACKDROPS_THEATER": "Theater",
    "BACKDROPS_THEATER_2": "Theater 2",
    "BACKDROPS_TREE": "Tree",
    "BACKDROPS_UNDERWATER_1": "Underwater 2",
    "BACKDROPS_UNDERWATER_2": "Underwater 3",
    "BACKDROPS_URBAN": "Urban",
    "BACKDROPS_WALL_1": "Wall 1",
    "BACKDROPS_WALL_2": "Wall 2",
    "BACKDROPS_WATER_AND_ROCKS": "Water And Rocks",
    "BACKDROPS_WETLAND": "Wetland",
    "BACKDROPS_WINTER": "Winter",
    "BACKDROPS_WINTER-LIGHTS": "Winter-lights",
    "BACKDROPS_WITCH_HOUSE": "Witch House",
    "BACKDROPS_WOODS": "Woods",
    "BACKDROPS_WOODS_AND_BENCH": "Woods And Bench",
    "BACKDROPS_XY-GRID": "Xy-grid",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "Abby-a",
    "COSTUMES_ABBY-B": "Abby-b",
    "COSTUMES_ABBY-C": "Abby-c",
    "COSTUMES_ABBY-D": "Abby-d",
    "COSTUMES_AMON": "amon",
    "COSTUMES_ANDIE-A": "andie-a",
    "COSTUMES_ANDIE-B": "andie-b",
    "COSTUMES_ANDIE-C": "andie-c",
    "COSTUMES_ANDIE-D": "andie-d",
    "COSTUMES_ANINA_POP_DOWN": "anina pop down",
    "COSTUMES_ANINA_POP_FRONT": "anina pop front",
    "COSTUMES_ANINA_POP_L_ARM": "anina pop L arm",
    "COSTUMES_ANINA_POP_LEFT": "anina pop left",
    "COSTUMES_ANINA_POP_R_ARM": "anina pop R arm",
    "COSTUMES_ANINA_POP_RIGHT": "anina pop right",
    "COSTUMES_ANINA_POP_STAND": "anina pop stand",
    "COSTUMES_ANINA_R_CROSS": "anina R cross",
    "COSTUMES_ANINA_STANCE": "anina stance",
    "COSTUMES_ANINA_TOP_FREEZE": "anina top freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "anina top L step",
    "COSTUMES_ANINA_TOP_R_STEP": "anina top R step",
    "COSTUMES_ANINA_TOP_STAND": "anina top stand",
    "COSTUMES_APPLE": "Apple",
    "COSTUMES_ARROW1-A": "Arrow1-a",
    "COSTUMES_ARROW1-B": "Arrow1-b",
    "COSTUMES_ARROW1-C": "Arrow1-c",
    "COSTUMES_ARROW1-D": "Arrow1-d",
    "COSTUMES_AVERY_WALKING-A": "Avery walking-a",
    "COSTUMES_AVERY_WALKING-B": "Avery walking-b",
    "COSTUMES_AVERY_WALKING-C": "Avery walking-c",
    "COSTUMES_AVERY_WALKING-D": "Avery walking-d",
    "COSTUMES_AVERY-A": "Avery-a",
    "COSTUMES_AVERY-B": "Avery-b",
    "COSTUMES_BALL-A": "Ball-a",
    "COSTUMES_BALL-B": "Ball-b",
    "COSTUMES_BALL-C": "Ball-c",
    "COSTUMES_BALL-D": "Ball-d",
    "COSTUMES_BALL-E": "Ball-e",
    "COSTUMES_BALLERINA-A": "Ballerina-a",
    "COSTUMES_BALLERINA-B": "Ballerina-b",
    "COSTUMES_BALLERINA-C": "Ballerina-c",
    "COSTUMES_BALLERINA-D": "Ballerina-d",
    "COSTUMES_BALLOON1-A": "Balloon2-a",
    "COSTUMES_BALLOON1-B": "Balloon2-b",
    "COSTUMES_BALLOON1-C": "Balloon2-c",
    "COSTUMES_BANANAS": "Bananas",
    "COSTUMES_BASEBALL": "Baseball",
    "COSTUMES_BASKETBALL": "Basketball",
    "COSTUMES_BAT-A": "bat-a",
    "COSTUMES_BAT-B": "bat-b",
    "COSTUMES_BAT-C": "bat-c",
    "COSTUMES_BAT-D": "bat-d",
    "COSTUMES_BATTER-A": "batter-a",
    "COSTUMES_BATTER-B": "batter-b",
    "COSTUMES_BATTER-C": "batter-c",
    "COSTUMES_BATTER-D": "batter-d",
    "COSTUMES_BEACHBALL": "Beachball",
    "COSTUMES_BEAR-A": "Bear-a",
    "COSTUMES_BEAR-B": "Bear-b",
    "COSTUMES_BEAR-WALK-A": "Bear-walk-a",
    "COSTUMES_BEAR-WALK-B": "Bear-walk-b",
    "COSTUMES_BEAR-WALK-C": "Bear-walk-c",
    "COSTUMES_BEAR-WALK-D": "Bear-walk-d",
    "COSTUMES_BEAR-WALK-E": "Bear-walk-e",
    "COSTUMES_BEAR-WALK-F": "Bear-walk-f",
    "COSTUMES_BEAR-WALK-G": "Bear-walk-g",
    "COSTUMES_BEAR-WALK-H": "Bear-walk-h",
    "COSTUMES_BEETLE": "Beetle",
    "COSTUMES_BELL1": "Bell2",
    "COSTUMES_BEN-A": "Ben-a",
    "COSTUMES_BEN-B": "Ben-b",
    "COSTUMES_BEN-C": "Ben-c",
    "COSTUMES_BEN-D": "Ben-d",
    "COSTUMES_BOWL-A": "Bowl-a",
    "COSTUMES_BOWTIE": "bowtie",
    "COSTUMES_BREAD": "Bread",
    "COSTUMES_BROOM": "Broom",
    "COSTUMES_BUILDING-A": "Building-a",
    "COSTUMES_BUILDING-B": "Building-b",
    "COSTUMES_BUILDING-C": "Building-c",
    "COSTUMES_BUILDING-D": "Building-d",
    "COSTUMES_BUILDING-E": "Building-e",
    "COSTUMES_BUILDING-F": "Building-f",
    "COSTUMES_BUILDING-G": "Building-g",
    "COSTUMES_BUILDING-H": "Building-h",
    "COSTUMES_BUILDING-I": "Building-i",
    "COSTUMES_BUILDING-J": "Building-j",
    "COSTUMES_BUTTERFLY1-A": "Butterfly2-a",
    "COSTUMES_BUTTERFLY1-B": "butterfly1-b",
    "COSTUMES_BUTTERFLY1-C": "butterfly1-c",
    "COSTUMES_BUTTERFLY2-A": "butterfly2-a",
    "COSTUMES_BUTTERFLY2-B": "butterfly2-b",
    "COSTUMES_BUTTON1": "Button2",
    "COSTUMES_BUTTON2-A": "Button3-a",
    "COSTUMES_BUTTON2-B": "Button3-b",
    "COSTUMES_BUTTON3-A": "Button4-a",
    "COSTUMES_BUTTON3-B": "Button4-b",
    "COSTUMES_BUTTON4-A": "Button5-a",
    "COSTUMES_BUTTON4-B": "Button5-b",
    "COSTUMES_BUTTON5-A": "Button6-a",
    "COSTUMES_BUTTON5-B": "Button6-b",
    "COSTUMES_CAKE-A": "Cake-a",
    "COSTUMES_CAKE-B": "Cake-b",
    "COSTUMES_CALVRETT_JUMPING": "calvrett jumping",
    "COSTUMES_CALVRETT_THINKING": "calvrett thinking",
    "COSTUMES_CASEY-A": "Casey-a",
    "COSTUMES_CASEY-B": "Casey-b",
    "COSTUMES_CASEY-C": "Casey-c",
    "COSTUMES_CASEY-D": "casey-d",
    "COSTUMES_CASSY-A": "cassy-a",
    "COSTUMES_CASSY-B": "cassy-b",
    "COSTUMES_CASSY-C": "cassy-c",
    "COSTUMES_CASSY-D": "cassy-d",
    "COSTUMES_CAT_2": "cat 2",
    "COSTUMES_CATCHER-A": "catcher-a",
    "COSTUMES_CATCHER-B": "catcher-b",
    "COSTUMES_CATCHER-C": "catcher-c",
    "COSTUMES_CATCHER-D": "catcher-d",
    "COSTUMES_CENTAUR-A": "Centaur-a",
    "COSTUMES_CENTAUR-B": "Centaur-b",
    "COSTUMES_CENTAUR-C": "centaur-c",
    "COSTUMES_CENTAUR-D": "centaur-d",
    "COSTUMES_CHAMP99-A": "champ99-a",
    "COSTUMES_CHAMP99-B": "champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "cheesy puffs",
    "COSTUMES_CHICK-A": "chick-a",
    "COSTUMES_CHICK-B": "chick-b",
    "COSTUMES_CHICK-C": "chick-c",
    "COSTUMES_CITY_BUS-A": "City Bus-a",
    "COSTUMES_CITY_BUS-B": "City Bus-b",
    "COSTUMES_CLOUD": "Cloud",
    "COSTUMES_CLOUD-A": "Cloud-a",
    "COSTUMES_CLOUD-B": "Cloud-b",
    "COSTUMES_CLOUD-C": "Cloud-c",
    "COSTUMES_CLOUD-D": "Cloud-d",
    "COSTUMES_CONVERTIBLE": "convertible",
    "COSTUMES_CONVERTIBLE_3": "convertible 3",
    "COSTUMES_CRAB-A": "Crab-a",
    "COSTUMES_CRAB-B": "Crab-b",
    "COSTUMES_CRYSTAL-A": "Crystal-a",
    "COSTUMES_CRYSTAL-B": "Crystal-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "Dee-a",
    "COSTUMES_DEE-B": "Dee-b",
    "COSTUMES_DEE-C": "Dee-c",
    "COSTUMES_DEE-D": "Dee-d",
    "COSTUMES_DEE-E": "Dee-e",
    "COSTUMES_DEVIN-A": "Devin-a",
    "COSTUMES_DEVIN-B": "Devin-b",
    "COSTUMES_DEVIN-C": "Devin-c",
    "COSTUMES_DEVIN-D": "Devin-d",
    "COSTUMES_DINOSAUR1-A": "Dinosaur2-a",
    "COSTUMES_DINOSAUR1-B": "Dinosaur2-b",
    "COSTUMES_DINOSAUR1-C": "Dinosaur2-c",
    "COSTUMES_DINOSAUR1-D": "Dinosaur2-d",
    "COSTUMES_DINOSAUR2-A": "Dinosaur3-a",
    "COSTUMES_DINOSAUR2-B": "Dinosaur3-b",
    "COSTUMES_DINOSAUR2-C": "Dinosaur3-c",
    "COSTUMES_DINOSAUR2-D": "Dinosaur3-d",
    "COSTUMES_DINOSAUR3-A": "Dinosaur4-a",
    "COSTUMES_DINOSAUR3-B": "Dinosaur4-b",
    "COSTUMES_DINOSAUR3-C": "Dinosaur4-c",
    "COSTUMES_DINOSAUR3-D": "Dinosaur4-d",
    "COSTUMES_DINOSAUR3-E": "Dinosaur4-e",
    "COSTUMES_DINOSAUR4-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR4-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR4-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR4-D": "Dinosaur5-d",
    "COSTUMES_DIVER1": "Diver2",
    "COSTUMES_DIVER2": "Diver3",
    "COSTUMES_DM_FREEZE": "dm freeze",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "dm stance",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": "Dog2-a",
    "COSTUMES_DOG1-B": "Dog2-b",
    "COSTUMES_DOG2-A": "Dog3-a",
    "COSTUMES_DOG2-B": "Dog3-b",
    "COSTUMES_DOG2-C": "Dog3-c",
    "COSTUMES_DONUT": "Donut",
    "COSTUMES_DORIAN-A": "Dorian-a",
    "COSTUMES_DORIAN-B": "Dorian-b",
    "COSTUMES_DORIAN-C": "Dorian-c",
    "COSTUMES_DORIAN-D": "Dorian-d",
    "COSTUMES_DOT-A": "Dot-a",
    "COSTUMES_DOT-B": "Dot-b",
    "COSTUMES_DOT-C": "Dot-c",
    "COSTUMES_DOT-D": "Dot-d",
    "COSTUMES_DOVE-A": "Dove-a",
    "COSTUMES_DOVE-B": "Dove-b",
    "COSTUMES_DRAGON-A": "Dragon-a",
    "COSTUMES_DRAGON-B": "Dragon-b",
    "COSTUMES_DRAGON-C": "Dragon-c",
    "COSTUMES_DRAGON1-A": "Dragon2-a",
    "COSTUMES_DRAGON1-B": "Dragon2-b",
    "COSTUMES_DRAGONFLY-A": "Dragonfly-a",
    "COSTUMES_DRAGONFLY-B": "Dragonfly-b",
    "COSTUMES_DRESS-A": "dress-a",
    "COSTUMES_DRESS-B": "dress-b",
    "COSTUMES_DRESS-C": "dress-c",
    "COSTUMES_DRUM-A": "Drum-a",
    "COSTUMES_DRUM-B": "Drum-b",
    "COSTUMES_DRUM-CYMBAL-A": "Drum-cymbal-a",
    "COSTUMES_DRUM-CYMBAL-B": "Drum-cymbal-b",
    "COSTUMES_DRUM-HIGHHAT-A": "Drum-highhat-a",
    "COSTUMES_DRUM-HIGHHAT-B": "Drum-highhat-b",
    "COSTUMES_DRUM-KIT": "Drum-kit",
    "COSTUMES_DRUM-KIT-B": "Drum-kit-b",
    "COSTUMES_DRUM-SNARE-A": "Drum-snare-a",
    "COSTUMES_DRUM-SNARE-B": "Drum-snare-b",
    "COSTUMES_DRUMS_CONGA-A": "Drums Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Drums Conga-b",
    "COSTUMES_DUCK": "Duck",
    "COSTUMES_EARTH": "Earth",
    "COSTUMES_EASEL-A": "Easel-a",
    "COSTUMES_EASEL-B": "Easel-b",
    "COSTUMES_EASEL-C": "Easel-c",
    "COSTUMES_EGG-A": "Egg-a",
    "COSTUMES_EGG-B": "Egg-b",
    "COSTUMES_EGG-C": "Egg-c",
    "COSTUMES_EGG-D": "Egg-d",
    "COSTUMES_EGG-E": "Egg-e",
    "COSTUMES_EGG-F": "egg-f",
    "COSTUMES_ELEPHANT-A": "elephant-a",
    "COSTUMES_ELEPHANT-B": "elephant-b",
    "COSTUMES_ELF-A": "Elf-a",
    "COSTUMES_ELF-B": "Elf-b",
    "COSTUMES_ELF-C": "Elf-c",
    "COSTUMES_ELF-D": "Elf-d",
    "COSTUMES_ELF-E": "Elf-e",
    "COSTUMES_FAIRY-A": "Fairy-a",
    "COSTUMES_FAIRY-B": "Fairy-b",
    "COSTUMES_FAIRY-C": "Fairy-c",
    "COSTUMES_FAIRY-D": "Fairy-d",
    "COSTUMES_FAIRY-E": "Fairy-e",
    "COSTUMES_FISH-A": "Fish-a",
    "COSTUMES_FISH-B": "Fish-b",
    "COSTUMES_FISH-C": "Fish-c",
    "COSTUMES_FISH-D": "Fish-d",
    "COSTUMES_FISHBOWL-A": "Fishbowl-a",
    "COSTUMES_FISHBOWL-B": "Fishbowl-b",
    "COSTUMES_FOOD_TRUCK-A": "Food Truck-a",
    "COSTUMES_FOOD_TRUCK-B": "Food Truck-b",
    "COSTUMES_FOOD_TRUCK-C": "Food Truck-c",
    "COSTUMES_FOOTBALL_RUNNING": "football running",
    "COSTUMES_FOOTBALL_STANDING": "football standing",
    "COSTUMES_FORTUNE_COOKIE": "fortune cookie",
    "COSTUMES_FOX-A": "Fox-a",
    "COSTUMES_FOX-B": "Fox-b",
    "COSTUMES_FOX-C": "Fox-c",
    "COSTUMES_FRANK-A": "frank-a",
    "COSTUMES_FRANK-B": "frank-b",
    "COSTUMES_FRANK-C": "frank-c",
    "COSTUMES_FRANK-D": "frank-d",
    "COSTUMES_FROG": "Frog",
    "COSTUMES_FROG_2-A": "Frog 2-a",
    "COSTUMES_FROG_2-B": "Frog 2-b",
    "COSTUMES_FROG_2-C": "Frog 2-c",
    "COSTUMES_FRUIT_PLATTER": "fruit platter",
    "COSTUMES_FRUITSALAD": "Fruitsalad",
    "COSTUMES_GHOST-A": "ghost-a",
    "COSTUMES_GHOST-B": "ghost-b",
    "COSTUMES_GHOST-C": "ghost-c",
    "COSTUMES_GHOST-D": "ghost-d",
    "COSTUMES_GIFT-A": "Gift-a",
    "COSTUMES_GIFT-B": "Gift-b",
    "COSTUMES_GIRAFFE-A": "giraffe-a",
    "COSTUMES_GIRAFFE-B": "giraffe-b",
    "COSTUMES_GIRAFFE-C": "giraffe-c",
    "COSTUMES_GLASS_WATER-A": "Glass Water-a",
    "COSTUMES_GLASS_WATER-B": "Glass water-b",
    "COSTUMES_GLASSES-A": "glasses-a",
    "COSTUMES_GLASSES-B": "glasses-b",
    "COSTUMES_GLASSES-C": "glasses-c",
    "COSTUMES_GLASSES-E": "glasses-e",
    "COSTUMES_GOALIE-A": "Goalie-a",
    "COSTUMES_GOALIE-B": "Goalie-b",
    "COSTUMES_GOALIE-C": "goalie-c",
    "COSTUMES_GOALIE-D": "Goalie-d",
    "COSTUMES_GOALIE-E": "Goalie-e",
    "COSTUMES_GOBLIN-A": "Goblin-a",
    "COSTUMES_GOBLIN-B": "Goblin-b",
    "COSTUMES_GOBLIN-C": "Goblin-c",
    "COSTUMES_GOBLIN-D": "Goblin-d",
    "COSTUMES_GRASSHOPPER-A": "Grasshopper-a",
    "COSTUMES_GRASSHOPPER-B": "Grasshopper-b",
    "COSTUMES_GRASSHOPPER-C": "Grasshopper-c",
    "COSTUMES_GRASSHOPPER-D": "Grasshopper-d",
    "COSTUMES_GRASSHOPPER-E": "Grasshopper-e",
    "COSTUMES_GRASSHOPPER-F": "Grasshopper-f",
    "COSTUMES_GREEN_FLAG": "Green Flag",
    "COSTUMES_GRIFFIN-A": "Griffin-a",
    "COSTUMES_GRIFFIN-B": "Griffin-b",
    "COSTUMES_GRIFFIN-C": "Griffin-c",
    "COSTUMES_GRIFFIN-D": "Griffin-d",
    "COSTUMES_GUITAR-A": "guitar-a",
    "COSTUMES_GUITAR-B": "guitar-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "Guitar-electric2-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "Guitar-electric2-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "Guitar-electric3-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "Guitar-electric3-b",
    "COSTUMES_HANNAH-A": "hannah-a",
    "COSTUMES_HANNAH-B": "hannah-b",
    "COSTUMES_HANNAH-C": "hannah-c",
    "COSTUMES_HARE-A": "hare-a",
    "COSTUMES_HARE-B": "hare-b",
    "COSTUMES_HARE-C": "hare-c",
    "COSTUMES_HARPER-A": "harper-a",
    "COSTUMES_HARPER-B": "harper-b",
    "COSTUMES_HARPER-C": "harper-c",
    "COSTUMES_HAT-A": "hat-a",
    "COSTUMES_HAT-B": "hat-b",
    "COSTUMES_HAT-C": "hat-c",
    "COSTUMES_HAT-D": "hat-d",
    "COSTUMES_HATCHLING-A": "hatchling-a",
    "COSTUMES_HATCHLING-B": "hatchling-b",
    "COSTUMES_HATCHLING-C": "hatchling-c",
    "COSTUMES_HEART_CODE": "Heart Code",
    "COSTUMES_HEART_FACE": "Heart Face",
    "COSTUMES_HEART_LOVE": "heart love",
    "COSTUMES_HEART_PURPLE": "Heart Purple",
    "COSTUMES_HEART_RED": "Heart Red",
    "COSTUMES_HEART_SMILE": "Heart Smile",
    "COSTUMES_HEART_SWEET": "Heart Sweet",
    "COSTUMES_HEDGEHOG-A": "Hedgehog-a",
    "COSTUMES_HEDGEHOG-B": "Hedgehog-b",
    "COSTUMES_HEDGEHOG-C": "Hedgehog-c",
    "COSTUMES_HEDGEHOG-D": "Hedgehog-d",
    "COSTUMES_HEDGEHOG-E": "Hedgehog-e",
    "COSTUMES_HEN-A": "hen-a",
    "COSTUMES_HEN-B": "hen-b",
    "COSTUMES_HEN-C": "hen-c",
    "COSTUMES_HEN-D": "hen-d",
    "COSTUMES_HIPPO1-A": "Hippo2-a",
    "COSTUMES_HIPPO1-B": "Hippo2-b",
    "COSTUMES_HOME_BUTTON": "Home Button",
    "COSTUMES_HORSE-A": "horse-a",
    "COSTUMES_HORSE-B": "horse-b",
    "COSTUMES_JAIME_WALKING-A": "jaime walking-a",
    "COSTUMES_JAIME_WALKING-B": "jaime walking-b",
    "COSTUMES_JAIME_WALKING-C": "jaime walking-c",
    "COSTUMES_JAIME_WALKING-D": "jaime walking-d",
    "COSTUMES_JAIME_WALKING-E": "jaime walking-e",
    "COSTUMES_JAIME-A": "jaime-a",
    "COSTUMES_JAIME-B": "jaime-b",
    "COSTUMES_JAMAL-A": "jamal-a",
    "COSTUMES_JAMAL-B": "jamal-b",
    "COSTUMES_JAMAL-C": "jamal-c",
    "COSTUMES_JAMAL-D": "jamal-d",
    "COSTUMES_JAR-A": "Jar-a",
    "COSTUMES_JAR-B": "Jar-b",
    "COSTUMES_JELLYFISH-A": "Jellyfish-a",
    "COSTUMES_JELLYFISH-B": "Jellyfish-b",
    "COSTUMES_JELLYFISH-C": "Jellyfish-c",
    "COSTUMES_JELLYFISH-D": "Jellyfish-d",
    "COSTUMES_JO_POP_DOWN": "jo pop down",
    "COSTUMES_JO_POP_FRONT": "jo pop front",
    "COSTUMES_JO_POP_L_ARM": "jo pop L arm",
    "COSTUMES_JO_POP_LEFT": "jo pop left",
    "COSTUMES_JO_POP_R_ARM": "jo pop R arm",
    "COSTUMES_JO_POP_RIGHT": "jo pop right",
    "COSTUMES_JO_POP_STAND": "jo pop stand",
    "COSTUMES_JO_STANCE": "jo stance",
    "COSTUMES_JO_TOP_L_CROSS": "jo top L cross",
    "COSTUMES_JO_TOP_L_LEG": "jo top L leg",
    "COSTUMES_JO_TOP_R_CROSS": "jo top R cross",
    "COSTUMES_JO_TOP_R_LEG": "jo top R leg",
    "COSTUMES_JO_TOP_STAND": "jo top stand",
    "COSTUMES_JORDYN-A": "Jordyn-a",
    "COSTUMES_JORDYN-B": "Jordyn-b",
    "COSTUMES_JORDYN-C": "Jordyn-c",
    "COSTUMES_JORDYN-D": "Jordyn-d",
    "COSTUMES_KAI-A": "kai-a",
    "COSTUMES_KAI-B": "kai-b",
    "COSTUMES_KEY": "Key",
    "COSTUMES_KEYBOARD-A": "Keyboard-a",
    "COSTUMES_KEYBOARD-B": "Keyboard-b",
    "COSTUMES_KIRAN-A": "Kiran-a",
    "COSTUMES_KIRAN-B": "Kiran-b",
    "COSTUMES_KIRAN-C": "Kiran-c",
    "COSTUMES_KIRAN-D": "Kiran-d",
    "COSTUMES_KIRAN-E": "Kiran-e",
    "COSTUMES_KIRAN-F": "Kiran-f",
    "COSTUMES_KNIGHT": "Knight",
    "COSTUMES_LADYBUG2": "Ladybug2",
    "COSTUMES_LADYBUG2-A": "Ladybug3-a",
    "COSTUMES_LADYBUG2-B": "Ladybug3-b",
    "COSTUMES_LAPTOP": "Laptop",
    "COSTUMES_LB_POP_DOWN": "lb pop down",
    "COSTUMES_LB_POP_FRONT": "lb pop front",
    "COSTUMES_LB_POP_L_ARM": "lb pop L arm",
    "COSTUMES_LB_POP_LEFT": "lb pop left",
    "COSTUMES_LB_POP_R_ARM": "lb pop R arm",
    "COSTUMES_LB_POP_RIGHT": "lb pop right",
    "COSTUMES_LB_POP_STAND": "lb pop stand",
    "COSTUMES_LB_STANCE": "lb stance",
    "COSTUMES_LB_TOP_L_CROSS": "lb top L cross",
    "COSTUMES_LB_TOP_L_LEG": "lb top L leg",
    "COSTUMES_LB_TOP_R_CROSS": "lb top R cross",
    "COSTUMES_LB_TOP_R_LEG": "lb top R leg",
    "COSTUMES_LB_TOP_STAND": "lb top stand",
    "COSTUMES_LIGHTNING": "Lightning",
    "COSTUMES_LINE": "Line",
    "COSTUMES_LION-A": "Lion-a",
    "COSTUMES_LION-B": "Lion-b",
    "COSTUMES_LION-C": "lion-c",
    "COSTUMES_LLAMA": "Llama",
    "COSTUMES_LLAMA-B": "Llama-b",
    "COSTUMES_LLAMA-C": "Llama-c",
    "COSTUMES_MAGICWAND": "Magicwand",
    "COSTUMES_MAX-A": "Max-a",
    "COSTUMES_MAX-B": "Max-b",
    "COSTUMES_MAX-C": "Max-c",
    "COSTUMES_MAX-D": "Max-d",
    "COSTUMES_MERMAID-A": "Mermaid-a",
    "COSTUMES_MERMAID-B": "Mermaid-b",
    "COSTUMES_MERMAID-C": "mermaid-c",
    "COSTUMES_MERMAID-D": "mermaid-d",
    "COSTUMES_MICROPHONE-A": "Microphone-a",
    "COSTUMES_MICROPHONE-B": "Microphone-b",
    "COSTUMES_MILK-A": "Milk-a",
    "COSTUMES_MILK-B": "Milk-b",
    "COSTUMES_MILK-C": "Milk-c",
    "COSTUMES_MILK-D": "Milk-d",
    "COSTUMES_MILK-E": "Milk-e",
    "COSTUMES_MONET-A": "Monet-a",
    "COSTUMES_MONET-B": "Monet-b",
    "COSTUMES_MONET-C": "Monet-c",
    "COSTUMES_MONET-D": "Monet-d",
    "COSTUMES_MONET-E": "Monet-e",
    "COSTUMES_MONKEY-A": "Monkey-a",
    "COSTUMES_MONKEY-B": "Monkey-b",
    "COSTUMES_MONKEY-C": "Monkey-c",
    "COSTUMES_MOTORCYCLE-A": "Motorcycle-a",
    "COSTUMES_MOTORCYCLE-B": "Motorcycle-b",
    "COSTUMES_MOTORCYCLE-C": "Motorcycle-c",
    "COSTUMES_MOTORCYCLE-D": "Motorcycle-d",
    "COSTUMES_MOUSE1-A": "Mouse2-a",
    "COSTUMES_MOUSE1-B": "Mouse2-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "Neigh Pony",
    "COSTUMES_OCTOPUS-A": "Octopus-a",
    "COSTUMES_OCTOPUS-B": "Octopus-b",
    "COSTUMES_OCTOPUS-C": "Octopus-c",
    "COSTUMES_OCTOPUS-D": "Octopus-d",
    "COSTUMES_OCTOPUS-E": "Octopus-e",
    "COSTUMES_ORANGE": "Orange",
    "COSTUMES_ORANGE2-A": "Orange3-a",
    "COSTUMES_ORANGE2-B": "Orange3-b",
    "COSTUMES_OUTFIELDER-A": "outfielder-a",
    "COSTUMES_OUTFIELDER-B": "outfielder-b",
    "COSTUMES_OUTFIELDER-C": "outfielder-c",
    "COSTUMES_OUTFIELDER-D": "outfielder-d",
    "COSTUMES_OWL-A": "Owl-a",
    "COSTUMES_OWL-B": "Owl-b",
    "COSTUMES_OWL-C": "Owl-c",
    "COSTUMES_PADDLE": "Paddle",
    "COSTUMES_PANTHER-A": "Panther-a",
    "COSTUMES_PANTHER-B": "Panther-b",
    "COSTUMES_PANTHER-C": "Panther-c",
    "COSTUMES_PANTS-A": "pants-a",
    "COSTUMES_PANTS-B": "pants-b",
    "COSTUMES_PARROT-A": "Parrot-a",
    "COSTUMES_PARROT-B": "Parrot-b",
    "COSTUMES_PARTY_HAT-A": "Party Hat-a",
    "COSTUMES_PARTY_HAT-B": "Party Hat-b",
    "COSTUMES_PARTY_HAT-E": "Party Hat-e",
    "COSTUMES_PENCIL-A": "Pencil-a",
    "COSTUMES_PENCIL-B": "Pencil-b",
    "COSTUMES_PENGUIN-A": "penguin-a",
    "COSTUMES_PENGUIN-B": "penguin-b",
    "COSTUMES_PENGUIN-C": "penguin-c",
    "COSTUMES_PENGUIN2-A": "Penguin3-a",
    "COSTUMES_PENGUIN2-B": "Penguin3-b",
    "COSTUMES_PENGUIN2-C": "Penguin3-c",
    "COSTUMES_PENGUIN2-D": "penguin2-d",
    "COSTUMES_PITCHER-A": "pitcher-a",
    "COSTUMES_PITCHER-B": "pitcher-b",
    "COSTUMES_PITCHER-C": "pitcher-c",
    "COSTUMES_PITCHER-D": "pitcher-d",
    "COSTUMES_PLANET2": "Planet2",
    "COSTUMES_POLAR_BEAR-A": "polar bear-a",
    "COSTUMES_POLAR_BEAR-B": "polar bear-b",
    "COSTUMES_POLAR_BEAR-C": "polar bear-c",
    "COSTUMES_POTION-A": "Potion-a",
    "COSTUMES_POTION-B": "Potion-b",
    "COSTUMES_POTION-C": "Potion-c",
    "COSTUMES_PRINCE": "Prince",
    "COSTUMES_PRINCESS-A": "Princess-a",
    "COSTUMES_PRINCESS-B": "Princess-b",
    "COSTUMES_PRINCESS-C": "Princess-c",
    "COSTUMES_PRINCESS-D": "Princess-d",
    "COSTUMES_PRINCESS-E": "Princess-e",
    "COSTUMES_PUFFERFISH-A": "Pufferfish-a",
    "COSTUMES_PUFFERFISH-B": "Pufferfish-b",
    "COSTUMES_PUFFERFISH-C": "Pufferfish-c",
    "COSTUMES_PUFFERFISH-D": "Pufferfish-d",
    "COSTUMES_PUPPY_BACK": "puppy back",
    "COSTUMES_PUPPY_RIGHT": "puppy right",
    "COSTUMES_PUPPY_SIDE": "puppy side",
    "COSTUMES_PUPPY_SIT": "puppy sit",
    "COSTUMES_RABBIT-A": "Rabbit-a",
    "COSTUMES_RABBIT-B": "Rabbit-b",
    "COSTUMES_RABBIT-C": "Rabbit-c",
    "COSTUMES_RABBIT-D": "Rabbit-d",
    "COSTUMES_RABBIT-E": "Rabbit-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": "Rainbow",
    "COSTUMES_REFEREE-A": "referee-a",
    "COSTUMES_REFEREE-B": "referee-b",
    "COSTUMES_REFEREE-C": "referee-c",
    "COSTUMES_REFEREE-D": "referee-d",
    "COSTUMES_REINDEER": "reindeer",
    "COSTUMES_RETRO_ROBOT_A": "Retro Robot a",
    "COSTUMES_RETRO_ROBOT_B": "Retro Robot b",
    "COSTUMES_RETRO_ROBOT_C": "Retro Robot c",
    "COSTUMES_RIPLEY-A": "Ripley-a",
    "COSTUMES_RIPLEY-B": "Ripley-b",
    "COSTUMES_RIPLEY-C": "Ripley-c",
    "COSTUMES_RIPLEY-D": "Ripley-d",
    "COSTUMES_RIPLEY-E": "Ripley-e",
    "COSTUMES_RIPLEY-F": "Ripley-f",
    "COSTUMES_ROBOT-A": "Robot-a",
    "COSTUMES_ROBOT-B": "Robot-b",
    "COSTUMES_ROBOT-C": "Robot-c",
    "COSTUMES_ROBOT-D": "Robot-d",
    "COSTUMES_ROCKETSHIP-A": "rocketship-a",
    "COSTUMES_ROCKETSHIP-B": "rocketship-b",
    "COSTUMES_ROCKETSHIP-C": "rocketship-c",
    "COSTUMES_ROCKETSHIP-D": "rocketship-d",
    "COSTUMES_ROCKETSHIP-E": "rocketship-e",
    "COSTUMES_ROCKS": "Rocks",
    "COSTUMES_ROOSTER-A": "rooster-a",
    "COSTUMES_ROOSTER-B": "rooster-b",
    "COSTUMES_ROOSTER-C": "rooster-c",
    "COSTUMES_RUBY-A": "ruby-a",
    "COSTUMES_RUBY-B": "ruby-b",
    "COSTUMES_SAILBOAT": "sailboat",
    "COSTUMES_SAM": "sam",
    "COSTUMES_SAXOPHONE-A": "Saxophone-a",
    "COSTUMES_SAXOPHONE-B": "Saxophone-b",
    "COSTUMES_SCARF-A": "Scarf-a",
    "COSTUMES_SCARF-B": "Scarf-b",
    "COSTUMES_SCARF-C": "Scarf-c",
    "COSTUMES_SHARK-A": "Shark-a",
    "COSTUMES_SHARK-B": "Shark-b",
    "COSTUMES_SHARK2-A": "shark2-a",
    "COSTUMES_SHARK2-B": "shark2-b",
    "COSTUMES_SHARK2-C": "shark2-c",
    "COSTUMES_SHIRT-A": "shirt-a",
    "COSTUMES_SHOES-A": "shoes-a",
    "COSTUMES_SHOES-B": "shoes-b",
    "COSTUMES_SHOES-C": "shoes-c",
    "COSTUMES_SHOES-D": "shoes-d",
    "COSTUMES_SHORTS-A": "shorts-a",
    "COSTUMES_SHORTS-B": "shorts-b",
    "COSTUMES_SHORTS-C": "shorts-c",
    "COSTUMES_SINGER1": "Singer1",
    "COSTUMES_SKELETON-A": "skeleton-a",
    "COSTUMES_SKELETON-B": "skeleton-b",
    "COSTUMES_SKELETON-D": "skeleton-d",
    "COSTUMES_SKELETON-E": "skeleton-e",
    "COSTUMES_SNAKE-A": "Snake-a",
    "COSTUMES_SNAKE-B": "Snake-b",
    "COSTUMES_SNAKE-C": "Snake-c",
    "COSTUMES_SNOWFLAKE": "Snowflake",
    "COSTUMES_SNOWMAN": "Snowman",
    "COSTUMES_SOCCER_BALL": "Soccer Ball",
    "COSTUMES_SPEAKER": "Speaker",
    "COSTUMES_SQUIRREL": "squirrel",
    "COSTUMES_STAR": "Star",
    "COSTUMES_STARFISH-A": "Starfish-a",
    "COSTUMES_STARFISH-B_": "Starfish-b ",
    "COSTUMES_STOP": "Stop",
    "COSTUMES_STRAWBERRY-A": "Strawberry-a",
    "COSTUMES_STRAWBERRY-B": "Strawberry-b",
    "COSTUMES_STRAWBERRY-C": "Strawberry-c",
    "COSTUMES_STRAWBERRY-D": "Strawberry-d",
    "COSTUMES_STRAWBERRY-E": "Strawberry-e",
    "COSTUMES_SUN": "Sun",
    "COSTUMES_SUNGLASSES-A": "sunglasses-a",
    "COSTUMES_SUNGLASSES-B": "sunglasses-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-wizard",
    "COSTUMES_TAKEOUT-A": "Takeout-a",
    "COSTUMES_TAKEOUT-B": "Takeout-b",
    "COSTUMES_TAKEOUT-C": "Takeout-c",
    "COSTUMES_TAKEOUT-D": "Takeout-d",
    "COSTUMES_TAKEOUT-E": "Takeout-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 pop down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 pop front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 pop L arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 pop left",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 pop R arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 pop right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 pop stand",
    "COSTUMES_TEN80_STANCE": "Ten80 stance",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 top freeze",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 top L step",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 top R cross",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 top R step",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 top stand",
    "COSTUMES_TENNISBALL": "tennisball",
    "COSTUMES_TOUCAN-A": "Toucan-a",
    "COSTUMES_TOUCAN-B": "Toucan-b",
    "COSTUMES_TOUCAN-C": "Toucan-c",
    "COSTUMES_TRAMPOLINE": "trampoline",
    "COSTUMES_TREE1": "Tree1",
    "COSTUMES_TREES-A": "trees-a",
    "COSTUMES_TREES-B": "trees-b",
    "COSTUMES_TRUCK-A": "Truck-a",
    "COSTUMES_TRUCK-B": "Truck-b",
    "COSTUMES_TRUCK-C": "Truck-c",
    "COSTUMES_TRUMPET-A": "trumpet-a",
    "COSTUMES_TRUMPET-B": "trumpet-b",
    "COSTUMES_UNICORN": "Unicorn",
    "COSTUMES_UNICORN_2": "unicorn 2",
    "COSTUMES_UNICORN_RUNNING-A": "unicorn running-a",
    "COSTUMES_UNICORN_RUNNING-B": "unicorn running-b",
    "COSTUMES_UNICORN_RUNNING-C": "unicorn running-c",
    "COSTUMES_UNICORN_RUNNING-D": "unicorn running-d",
    "COSTUMES_UNICORN_RUNNING-E": "unicorn running-e",
    "COSTUMES_UNICORN_RUNNING-F": "unicorn running-f",
    "COSTUMES_WAND": "Wand",
    "COSTUMES_WANDA": "Wanda",
    "COSTUMES_WATERMELON-A": "Watermelon-a",
    "COSTUMES_WATERMELON-B": "Watermelon-b",
    "COSTUMES_WATERMELON-C": "Watermelon-c",
    "COSTUMES_WINTER_HAT": "Winter Hat",
    "COSTUMES_WITCH": "Witch",
    "COSTUMES_WITCH-A": "Witch-a",
    "COSTUMES_WITCH-B": "Witch-b",
    "COSTUMES_WITCH-C": "Witch-c",
    "COSTUMES_WITCH-D": "Witch-d",
    "COSTUMES_WIZARD_HAT": "Wizard Hat",
    "COSTUMES_WIZARD-A": "Wizard-a",
    "COSTUMES_WIZARD-B": "Wizard-b",
    "COSTUMES_WIZARD-C": "Wizard-c",
    "COSTUMES_WIZARD-TOAD-A": "Wizard-toad-a",
    "COSTUMES_WIZARD-TOAD-B": "Wizard-toad-b",
    "COSTUMES_ZEBRA-A": "zebra-a",
    "COSTUMES_ZEBRA-B": "zebra-b",
    "COSTUMES_BLOCK-A": "Block-a",
    "COSTUMES_BLOCK-B": "Block-b",
    "COSTUMES_BLOCK-C": "Block-c",
    "COSTUMES_BLOCK-D": "Block-d",
    "COSTUMES_BLOCK-E": "Block-e",
    "COSTUMES_BLOCK-F": "Block-f",
    "COSTUMES_BLOCK-G": "Block-g",
    "COSTUMES_BLOCK-H": "Block-h",
    "COSTUMES_BLOCK-I": "Block-i",
    "COSTUMES_BLOCK-J": "Block-j",
    "COSTUMES_BLOCK-K": "Block-k",
    "COSTUMES_BLOCK-L": "Block-l",
    "COSTUMES_BLOCK-M": "Block-m",
    "COSTUMES_BLOCK-N": "Block-n",
    "COSTUMES_BLOCK-O": "Block-o",
    "COSTUMES_BLOCK-P": "Block-p",
    "COSTUMES_BLOCK-Q": "Block-q",
    "COSTUMES_BLOCK-R": "Block-r",
    "COSTUMES_BLOCK-S": "Block-s",
    "COSTUMES_BLOCK-T": "Block-t",
    "COSTUMES_BLOCK-U": "Block-u",
    "COSTUMES_BLOCK-V": "Block-v",
    "COSTUMES_BLOCK-W": "Block-w",
    "COSTUMES_BLOCK-X": "Block-x",
    "COSTUMES_BLOCK-Y": "Block-y",
    "COSTUMES_BLOCK-Z": "Block-z",
    "COSTUMES_GLOW-0": "Glow-1",
    "COSTUMES_GLOW-1": "Glow-2",
    "COSTUMES_GLOW-2": "Glow-3",
    "COSTUMES_GLOW-3": "Glow-4",
    "COSTUMES_GLOW-4": "Glow-5",
    "COSTUMES_GLOW-5": "Glow-6",
    "COSTUMES_GLOW-6": "Glow-7",
    "COSTUMES_GLOW-7": "Glow-8",
    "COSTUMES_GLOW-8": "Glow-9",
    "COSTUMES_GLOW-9": "Glow-10",
    "COSTUMES_GLOW-A": "Glow-A",
    "COSTUMES_GLOW-B": "Glow-B",
    "COSTUMES_GLOW-C": "Glow-C",
    "COSTUMES_GLOW-D": "Glow-D",
    "COSTUMES_GLOW-E": "Glow-E",
    "COSTUMES_GLOW-F": "Glow-F",
    "COSTUMES_GLOW-G": "Glow-G",
    "COSTUMES_GLOW-H": "Glow-H",
    "COSTUMES_GLOW-I": "Glow-I",
    "COSTUMES_GLOW-J": "Glow-J",
    "COSTUMES_GLOW-K": "Glow-K",
    "COSTUMES_GLOW-L": "Glow-L",
    "COSTUMES_GLOW-M": "Glow-M",
    "COSTUMES_GLOW-N": "Glow-N",
    "COSTUMES_GLOW-O": "Glow-O",
    "COSTUMES_GLOW-P": "Glow-P",
    "COSTUMES_GLOW-Q": "Glow-Q",
    "COSTUMES_GLOW-R": "Glow-R",
    "COSTUMES_GLOW-S": "Glow-S",
    "COSTUMES_GLOW-T": "Glow-T",
    "COSTUMES_GLOW-U": "Glow-U",
    "COSTUMES_GLOW-V": "Glow-V",
    "COSTUMES_GLOW-W": "Glow-W",
    "COSTUMES_GLOW-X": "Glow-X",
    "COSTUMES_GLOW-Y": "Glow-Y",
    "COSTUMES_GLOW-Z": "Glow-Z",
    "COSTUMES_STORY-A-1": "Story-A-2",
    "COSTUMES_STORY-A-2": "Story-A-3",
    "COSTUMES_STORY-A-3": "Story-A-4",
    "COSTUMES_STORY-B-1": "Story-B-2",
    "COSTUMES_STORY-B-2": "Story-B-3",
    "COSTUMES_STORY-B-3": "Story-B-4",
    "COSTUMES_STORY-C-1": "Story-C-2",
    "COSTUMES_STORY-C-2": "Story-C-3",
    "COSTUMES_STORY-C-3": "Story-C-4",
    "COSTUMES_STORY-D-1": "Story-D-2",
    "COSTUMES_STORY-D-2": "Story-D-3",
    "COSTUMES_STORY-D-3": "Story-D-4",
    "COSTUMES_STORY-E-1": "Story-E-2",
    "COSTUMES_STORY-E-2": "Story-E-3",
    "COSTUMES_STORY-E-3": "Story-E-4",
    "COSTUMES_STORY-F-1": "Story-F-2",
    "COSTUMES_STORY-F-2": "Story-F-3",
    "COSTUMES_STORY-F-3": "Story-F-4",
    "COSTUMES_STORY-G-1": "Story-G-2",
    "COSTUMES_STORY-G-2": "Story-G-3",
    "COSTUMES_STORY-G-3": "Story-G-4",
    "COSTUMES_STORY-H-1": "Story-H-2",
    "COSTUMES_STORY-H-2": "Story-H-3",
    "COSTUMES_STORY-H-3": "Story-H-4",
    "COSTUMES_STORY-I-1": "Story-I-2",
    "COSTUMES_STORY-I-2": "Story-I-3",
    "COSTUMES_STORY-I-3": "Story-I-4",
    "COSTUMES_STORY-J-1": "Story-J-2",
    "COSTUMES_STORY-J-2": "Story-J-3",
    "COSTUMES_STORY-J-3": "Story-J-4",
    "COSTUMES_STORY-K-1": "Story-K-2",
    "COSTUMES_STORY-K-2": "Story-K-3",
    "COSTUMES_STORY-K-3": "story-K-4",
    "COSTUMES_STORY-L-1": "Story-L-2",
    "COSTUMES_STORY-L-2": "Story-L-3",
    "COSTUMES_STORY-L-3": "Story-L-4",
    "COSTUMES_STORY-M-1": "Story-M-2",
    "COSTUMES_STORY-M-2": "Story-M-3",
    "COSTUMES_STORY-M-3": "Story-M-4",
    "COSTUMES_STORY-N-1": "Story-N-2",
    "COSTUMES_STORY-N-2": "Story-N-3",
    "COSTUMES_STORY-N-3": "Story-N-4",
    "COSTUMES_STORY-O-1": "Story-O-2",
    "COSTUMES_STORY-O-2": "Story-O-3",
    "COSTUMES_STORY-O-3": "Story-O-4",
    "COSTUMES_STORY-P-1": "Story-P-2",
    "COSTUMES_STORY-P-2": "Story-P-3",
    "COSTUMES_STORY-P-3": "Story-P-4",
    "COSTUMES_STORY-Q-1": "Story-Q-2",
    "COSTUMES_STORY-Q-2": "Story-Q-3",
    "COSTUMES_STORY-Q-3": "Story-Q-4",
    "COSTUMES_STORY-R-1": "Story-R-2",
    "COSTUMES_STORY-R-2": "Story-R-3",
    "COSTUMES_STORY-R-3": "Story-R-4",
    "COSTUMES_STORY-S-1": "Story-S-2",
    "COSTUMES_STORY-S-2": "Story-S-3",
    "COSTUMES_STORY-S-3": "Story-S-4",
    "COSTUMES_STORY-T-1": "Story-T-2",
    "COSTUMES_STORY-T-2": "Story-T-3",
    "COSTUMES_STORY-T-3": "Story-T-4",
    "COSTUMES_STORY-U-1": "Story-U-2",
    "COSTUMES_STORY-U-2": "Story-U-3",
    "COSTUMES_STORY-U-3": "Story-U-4",
    "COSTUMES_STORY-V-1": "Story-V-2",
    "COSTUMES_STORY-V-2": "Story-V-3",
    "COSTUMES_STORY-V-3": "Story-V-4",
    "COSTUMES_STORY-W-1": "Story-W-2",
    "COSTUMES_STORY-W-2": "Story-W-3",
    "COSTUMES_STORY-W-3": "Story-W-4",
    "COSTUMES_STORY-X-1": "Story-X-2",
    "COSTUMES_STORY-X-2": "Story-X-3",
    "COSTUMES_STORY-X-3": "Story-X-4",
    "COSTUMES_STORY-Y-1": "Story-Y-2",
    "COSTUMES_STORY-Y-2": "Story-Y-3",
    "COSTUMES_STORY-Y-3": "Story-Y-4",
    "COSTUMES_STORY-Z-1": "Story-Z-2",
    "COSTUMES_STORY-Z-2": "Story-Z-3",
    "COSTUMES_STORY-Z-3": "Story-Z-4",
    "COSTUMES_STEAMED-STUFFED-BUN": "Steamed Stuffed Bun",
    "COSTUMES_LUCKY-BAG": "Lucky Bag",
    "COSTUMES_DUMPLINGS": "Dumplings",
    "COSTUMES_GOLD-COINS": "Gold Coins",
    "COSTUMES_A-GIFT": "A Gift-A",
    "COSTUMES_STEAMED-BREAD": "Steamed Bread",
    "COSTUMES_PLATES": "Plates",
    "COSTUMES_SILVER-INGOT": "Silver Ingot",
    "COSTUMES_FIRECRACKERS": "Firecrackers",
    "COSTUMES_FIRECRACKERS-1": "Firecrackers 1",
    "COSTUMES_FIRECRACKERS-2": "Firecrackers 2",
    "COSTUMES_FIRECRACKERS-3": "Firecrackers 3",
    "COSTUMES_LANTERN": "Lantern",
    "COSTUMES_LANTERN-1": "Lantern 1",
    "COSTUMES_LANTERN-2": "Lantern 2",
    "COSTUMES_DRUM": "Drum-A",
    "COSTUMES_DRUM-1": "Drum 1",
    "COSTUMES_DRUM-2": "Drum 2",
    "COSTUMES_DRUM-3": "Drum 3",
    "COSTUMES_RED-ENVELOPE": "Red Envelope",
    "COSTUMES_RED-ENVELOPE-1": "Red Envelope 1",
    "COSTUMES_RED-ENVELOPE-2": "Red Envelope 2",
    "COSTUMES_SCROLL": "Scroll",
    "COSTUMES_SCROLL-1": "Scroll 1",
    "COSTUMES_SCROLL-2": "Scroll 2",
    "COSTUMES_SCROLL-3": "Scroll 3",
    "COSTUMES_YETI": "Yeti-A",
    "COSTUMES_YETI-1": "Yeti 1",
    "COSTUMES_YETI-2": "Yeti 2",
    "COSTUMES_FIREWORKS": "Fireworks",
    "COSTUMES_FIREWORKS-1": "Fireworks 1",
    "COSTUMES_FIREWORKS-2": "Fireworks 2",
    "COSTUMES_FIREWORKS-3": "Fireworks 3",
    "COSTUMES_FIREWORKS-4": "Fireworks 4",
    "COSTUMES_FIREWORKS-5": "Fireworks 5",
    "COSTUMES_FIREWORKS-6": "Fireworks 6",
    "COSTUMES_FIREWORKS-7": "Fireworks 7",
    "COSTUMES_FIREWORKS-8": "Fireworks 8",
    "COSTUMES_FIREWORKS-9": "Fireworks 9",
    "SOUNDS_A_BASS": "A Bass",
    "SOUNDS_A_ELEC_BASS": "A Elec Bass",
    "SOUNDS_A_ELEC_GUITAR": "A Elec Guitar",
    "SOUNDS_A_ELEC_PIANO": "A Elec Piano",
    "SOUNDS_A_GUITAR": "A Guitar",
    "SOUNDS_A_MINOR_UKULELE": "A Minor Ukulele",
    "SOUNDS_A_PIANO": "A Piano",
    "SOUNDS_A_SAX": "A Sax",
    "SOUNDS_A_TROMBONE": "A Trombone",
    "SOUNDS_A_TRUMPET": "A Trumpet",
    "SOUNDS_AFRO_STRING": "Afro String",
    "SOUNDS_ALERT": "Alert",
    "SOUNDS_ALIEN_CREAK1": "Alien Creak1",
    "SOUNDS_ALIEN_CREAK2": "Alien Creak2",
    "SOUNDS_B_BASS": "B Bass",
    "SOUNDS_B_ELEC_BASS": "B Elec Bass",
    "SOUNDS_B_ELEC_GUITAR": "B Elec Guitar",
    "SOUNDS_B_ELEC_PIANO": "B Elec Piano",
    "SOUNDS_B_GUITAR": "B Guitar",
    "SOUNDS_B_PIANO": "B Piano",
    "SOUNDS_B_SAX": "B Sax",
    "SOUNDS_B_TROMBONE": "B Trombone",
    "SOUNDS_B_TRUMPET": "B Trumpet",
    "SOUNDS_BAA": "Baa",
    "SOUNDS_BARK": "Bark",
    "SOUNDS_BASKETBALL_BOUNCE": "Basketball Bounce",
    "SOUNDS_BASS_BEATBOX": "Bass Beatbox",
    "SOUNDS_BEAT_BOX1": "Beat Box1",
    "SOUNDS_BEAT_BOX2": "Beat Box2",
    "SOUNDS_BELL_CYMBAL": "Bell Cymbal",
    "SOUNDS_BELL_TOLL": "Bell Toll",
    "SOUNDS_BIG_BOING": "Big Boing",
    "SOUNDS_BIRD": "Bird",
    "SOUNDS_BIRTHDAY": "Birthday",
    "SOUNDS_BITE": "Bite",
    "SOUNDS_BOING": "Boing",
    "SOUNDS_BONK": "Bonk",
    "SOUNDS_BOOM_CLOUD": "Boom Cloud",
    "SOUNDS_BOOP_BING_BOP": "Boop Bing Bop",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_CHILL": "Chill",
    "SOUNDS_CLASSICAL_PIANO": "Classical Piano",
    "SOUNDS_DANCE_CELEBRATE2": "dance celebrate2",
    "SOUNDS_DANCE_ENERGETIC": "Dance Energetic",
    "SOUNDS_DANCE_SITAR": "Dance Sitar",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_EMOTIONAL_PIANO": "Emotional Piano",
    "SOUNDS_FOOTSTEPS": "Footsteps",
    "SOUNDS_MOVIE_1": "Movie 1",
    "SOUNDS_MOVIE_2": "Movie 2",
    "SOUNDS_MYSTERY": "Mystery",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_PEW": "piu",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_SQUAWK": "Squawk",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_VIDEO_GAME_1": "Video Game 1",
    "SOUNDS_VIDEO_GAME_2": "Video Game 2",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_BAT": "Bat",
    "SPRITES_BATTER": "Batter",
    "SPRITES_BUTTERFLY_1": "Butterfly 1",
    "SPRITES_BUTTERFLY_2": "Butterfly 2",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Cat 2",
    "SPRITES_CATCHER": "Catcher",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Chick",
    "SPRITES_CITY_BUS": "City Bus",
    "SPRITES_CONVERTIBLE": "Convertible",
    "SPRITES_CONVERTIBLE_2": "Convertible 2",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DRAGONFLY": "Dragonfly",
    "SPRITES_DRESS": "Dress",
    "SPRITES_DRUMS_CONGA": "Drums Conga",
    "SPRITES_DRUMS_TABLA": "Drums Tabla",
    "SPRITES_EASEL": "Easel",
    "SPRITES_FISHBOWL": "Fishbowl",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Football",
    "SPRITES_FORTUNE_COOKIE": "Fortune Cookie",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG_2_": "Frog 2 ",
    "SPRITES_FRUIT_PLATTER": "Fruit Platter",
    "SPRITES_GHOST": "Ghost",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GRASSHOPPER": "Grasshopper",
    "SPRITES_GUITAR-ELECTRIC1": "Guitar-electric1",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hare",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hat1 ",
    "SPRITES_HATCHLING": "Hatchling",
    "SPRITES_HEN": "Hen",
    "SPRITES_HORSE": "Horse",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MOTORCYCLE": "Motorcycle",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_OUTFIELDER": "Outfielder",
    "SPRITES_PANTS": "Pants",
    "SPRITES_PARTY_HATS": "Party Hats",
    "SPRITES_PENGUIN": "Penguin",
    "SPRITES_PENGUIN_2": "Penguin 2",
    "SPRITES_PITCHER": "Pitcher",
    "SPRITES_POLAR_BEAR": "Polar Bear",
    "SPRITES_PUPPY": "Puppy",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RETRO_ROBOT": "Retro Robot",
    "SOUNDS_COMPUTER_BEEPS1": "computer beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "computer beeps2",
    "SPRITES_ROCKETSHIP": "Rocketship",
    "SPRITES_ROOSTER": "Rooster",
    "SPRITES_RUBY": "Ruby",
    "SPRITES_SAILBOAT": "Sailboat",
    "SPRITES_SAM": "Sam",
    "SPRITES_SCARF": "Scarf",
    "SPRITES_SHARK_2": "Shark 2",
    "SPRITES_SHIRT": "Shirt",
    "SPRITES_SHOES": "Shoes",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SKELETON": "Skeleton",
    "SPRITES_SQUIRREL": "Squirrel",
    "SPRITES_TEN80_DANCE": "Ten80 Dance",
    "SPRITES_TENNIS_BALL": "Tennis Ball",
    "SPRITES_TRAMPOLINE": "Trampoline",
    "SPRITES_TRUCK": "Truck",
    "SPRITES_UNICORN_2": "Unicorn 2",
    "SPRITES_UNICORN_RUNNING": "Unicorn Running",
    "SPRITES_WINTER_HAT": "Winter Hat",
    "SPRITES_WIZARD_HAT": "Wizard Hat",
    "SPRITES_ZEBRA": "Zebra",
    "SOUNDS_BOWLING_STRIKE": "Bowling Strike",
    "SOUNDS_BUBBLES": "Bubbles",
    "SOUNDS_BUZZ_WHIR": "Buzz Whir",
    "SOUNDS_C_BASS": "C Bass",
    "SOUNDS_C_ELEC_BASS": "C Elec Bass",
    "SOUNDS_C_ELEC_GUITAR": "C Elec Guitar",
    "SOUNDS_C_ELEC_PIANO": "C Elec Piano",
    "SOUNDS_C_GUITAR": "C Guitar",
    "SOUNDS_C_MAJOR_UKULELE": "C Major Ukulele",
    "SOUNDS_C_PIANO": "C Piano",
    "SOUNDS_C_SAX": "C Sax",
    "SOUNDS_C_TROMBONE": "C Trombone",
    "SOUNDS_C_TRUMPET": "C Trumpet",
    "SOUNDS_C2_BASS": "C2 Bass",
    "SOUNDS_C2_ELEC_BASS": "C2 Elec Bass",
    "SOUNDS_C2_ELEC_GUITAR": "C2 Elec Guitar",
    "SOUNDS_C2_ELEC_PIANO": "C2 Elec Piano",
    "SOUNDS_C2_GUITAR": "C2 guitar",
    "SOUNDS_C2_PIANO": "C2 Piano",
    "SOUNDS_C2_SAX": "C2 Sax",
    "SOUNDS_C2_TROMBONE": "C2 Trombone",
    "SOUNDS_C2_TRUMPET": "C2 Trumpet",
    "SOUNDS_CAR_HORN": "Car Horn",
    "SOUNDS_CAR_PASSING": "Car Passing",
    "SOUNDS_CAR_VROOM": "Car Vroom",
    "SOUNDS_CAVE": "Cave",
    "SOUNDS_CHATTER": "Chatter",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "Cheer",
    "SOUNDS_CHIRP": "Chirp",
    "SOUNDS_CHOMP": "Chomp",
    "SOUNDS_CHORD": "Chord",
    "SOUNDS_CLANG": "Clang",
    "SOUNDS_CLAP_BEATBOX": "Clap Beatbox",
    "SOUNDS_CLAPPING": "Clapping",
    "SOUNDS_CLOCK_TICKING": "Clock Ticking",
    "SOUNDS_CLOWN_HONK": "Clown Honk",
    "SOUNDS_COIN": "Coin",
    "SOUNDS_COLLECT": "Collect",
    "SOUNDS_COMPUTER_BEEP": "Computer Beep1",
    "SOUNDS_COMPUTER_BEEP2": "Computer Beep2",
    "SOUNDS_CONNECT": "Connect",
    "SOUNDS_COUGH1": "Cough1",
    "SOUNDS_COUGH2": "Cough2",
    "SOUNDS_CRANK": "Crank",
    "SOUNDS_CRASH_BEATBOX": "Crash Beatbox",
    "SOUNDS_CRASH_CYMBAL": "crash cymbal",
    "SOUNDS_CRAZY_LAUGH": "Crazy Laugh",
    "SOUNDS_CRICKET": "Cricket",
    "SOUNDS_CRICKETS": "Crickets",
    "SOUNDS_CROAK": "Croak",
    "SOUNDS_CROWD_GASP": "Crowd Gasp",
    "SOUNDS_CROWD_LAUGH": "Crowd Laugh",
    "SOUNDS_CRUNCH": "Crunch",
    "SOUNDS_CYMBAL": "Cymbal",
    "SOUNDS_CYMBAL_CRASH": "Cymbal Crash",
    "SOUNDS_CYMBAL_ECHO": "Cymbal Echo",
    "SOUNDS_D_BASS": "D Bass",
    "SOUNDS_D_ELEC_BASS": "D Elec Bass",
    "SOUNDS_D_ELEC_GUITAR": "D Elec Guitar",
    "SOUNDS_D_ELEC_PIANO": "D Elec Piano",
    "SOUNDS_D_GUITAR": "D Guitar",
    "SOUNDS_D_PIANO": "D Piano",
    "SOUNDS_D_SAX": "D Sax",
    "SOUNDS_D_TROMBONE": "D Trombone",
    "SOUNDS_D_TRUMPET": "D Trumpet",
    "SOUNDS_DANCE_AROUND": "Dance Around",
    "SOUNDS_DANCE_CELEBRATE": "Dance Celebrate",
    "SOUNDS_DANCE_CHILL_OUT": "Dance Chill Out",
    "SOUNDS_DANCE_FUNKY": "Dance Funky",
    "SOUNDS_DANCE_HEAD_NOD": "Dance Head Nod",
    "SOUNDS_DANCE_MAGIC": "Dance Magic",
    "SOUNDS_DANCE_SLOW_MO": "Dance Slow Mo",
    "SOUNDS_DANCE_SNARE_BEAT": "Dance Snare Beat",
    "SOUNDS_DANCE_SPACE": "Dance Space",
    "SOUNDS_DISCONNECT": "Disconnect",
    "SOUNDS_DOG1": "Dog",
    "SOUNDS_DOG2": "Dog2",
    "SOUNDS_DOOR_CLOSING": "Door Closing",
    "SOUNDS_DOOR_CREAK": "Door Creak",
    "SOUNDS_DOORBELL": "Doorbell",
    "SOUNDS_DRIP_DROP": "Drip Drop",
    "SOUNDS_DRIVE_AROUND": "Drive Around",
    "SOUNDS_DRUM": "Drum",
    "SOUNDS_DRUM_BASS1": "Drum Bass1",
    "SOUNDS_DRUM_BASS2": "Drum Bass2",
    "SOUNDS_DRUM_BASS3": "Drum Bass3",
    "SOUNDS_DRUM_BOING": "Drum Boing",
    "SOUNDS_DRUM_BUZZ": "Drum Buzz",
    "SOUNDS_DRUM_FUNKY": "Drum Funky",
    "SOUNDS_DRUM_JAM": "Drum Jam",
    "SOUNDS_DRUM_MACHINE": "Drum Machine",
    "SOUNDS_DRUM_ROLL": "Drum Roll",
    "SOUNDS_DRUM_SATELLITE": "Drum Satellite",
    "SOUNDS_DRUM_SET1": "Drum Set1",
    "SOUNDS_DRUM_SET2": "Drum Set2",
    "SOUNDS_DUCK": "Duck",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dunnn",
    "SOUNDS_E_BASS": "E Bass",
    "SOUNDS_E_ELEC_BASS": "E Elec Bass",
    "SOUNDS_E_ELEC_GUITAR": "E Elec Guitar",
    "SOUNDS_E_ELEC_PIANO": "E Elec Piano",
    "SOUNDS_E_GUITAR": "E Guitar",
    "SOUNDS_E_PIANO": "E Piano",
    "SOUNDS_E_SAX": "E Sax",
    "SOUNDS_E_TROMBONE": "E Trombone",
    "SOUNDS_E_TRUMPET": "E Trumpet",
    "SOUNDS_EGGS": "Eggs",
    "SOUNDS_ELEC_PIANO_A_MINOR": "Elec Piano A Minor",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "Elec Piano C Major",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "Elec Piano F Major",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "Elec Piano G Major",
    "SOUNDS_ELEC_PIANO_LOOP": "Elec Piano Loop",
    "SOUNDS_ENGINE": "Engine",
    "SOUNDS_F_BASS": "F Bass",
    "SOUNDS_F_ELEC_BASS": "F Elec Bass",
    "SOUNDS_F_ELEC_GUITAR": "F Elec Guitar",
    "SOUNDS_F_ELEC_PIANO": "F Elec Piano",
    "SOUNDS_F_GUITAR": "F Guitar",
    "SOUNDS_F_MAJOR_UKULELE": "F Major Ukulele",
    "SOUNDS_F_PIANO": "F Piano",
    "SOUNDS_F_SAX": "F Sax",
    "SOUNDS_F_TROMBONE": "F Trombone",
    "SOUNDS_F_TRUMPET": "F Trumpet",
    "SOUNDS_FAIRYDUST": "Fairydust",
    "SOUNDS_FINGER_SNAP": "Finger Snap",
    "SOUNDS_FLAM_SNARE": "Flam Snare",
    "SOUNDS_G_BASS": "G Bass",
    "SOUNDS_G_ELEC_BASS": "G Elec Bass",
    "SOUNDS_G_ELEC_GUITAR": "G Elec Guitar",
    "SOUNDS_G_ELEC_PIANO": "G Elec Piano",
    "SOUNDS_G_GUITAR": "G Guitar",
    "SOUNDS_G_PIANO": "G Piano",
    "SOUNDS_G_SAX": "G Sax",
    "SOUNDS_G_TROMBONE": "G Trombone",
    "SOUNDS_G_TRUMPET": "G Trumpet",
    "SOUNDS_G_UKULELE": "G Ukulele",
    "SOUNDS_GALLOP": "Gallop",
    "SOUNDS_GARDEN": "Garden",
    "SOUNDS_GLASS_BREAKING": "Glass Breaking",
    "SOUNDS_GLUG": "Glug",
    "SOUNDS_GOAL_CHEER": "Goal Cheer",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Goose",
    "SOUNDS_GROWL": "Growl",
    "SOUNDS_GRUNT": "Grunt",
    "SOUNDS_GUITAR_CHORDS1": "Guitar Chords1",
    "SOUNDS_GUITAR_CHORDS2": "Guitar Chords2",
    "SOUNDS_GUITAR_STRUM": "Guitar Strum",
    "SOUNDS_HAND_CLAP": "Hand Clap",
    "SOUNDS_HEAD_SHAKE": "Head Shake",
    "SOUNDS_HEY": "Hey",
    "SOUNDS_HI_BEATBOX": "Hi Beatbox",
    "SOUNDS_HI_NA_TABLA": "Hi Na Tabla",
    "SOUNDS_HI_TUN_TABLA": "Hi Tun Tabla",
    "SOUNDS_HIGH_CONGA": "High Conga",
    "SOUNDS_HIGH_HAT": "High Hat",
    "SOUNDS_HIGH_TOM": "High Tom",
    "SOUNDS_HIGH_WHOOSH": "High Whoosh",
    "SOUNDS_HIHAT_BEATBOX": "Hihat Beatbox",
    "SOUNDS_HIHAT_CYMBAL": "Hihat Cymbal",
    "SOUNDS_HIP_HOP": "Hip Hop",
    "SOUNDS_HORSE": "Horse",
    "SOUNDS_HORSE_GALLOP": "horse gallop",
    "SOUNDS_HUMAN_BEATBOX1": "Human Beatbox1",
    "SOUNDS_HUMAN_BEATBOX2": "Human Beatbox2",
    "SOUNDS_JUMP": "Jump",
    "SOUNDS_JUNGLE": "Jungle",
    "SOUNDS_JUNGLE_FROGS": "Jungle Frogs",
    "SOUNDS_KICK_BACK": "Kick Back",
    "SOUNDS_KICK_DRUM": "Kick Drum",
    "SOUNDS_LARGE_COWBELL": "Large Cowbell",
    "SOUNDS_LASER1": "laser1",
    "SOUNDS_LASER2": "laser2",
    "SOUNDS_LAUGH1": "Laugh1",
    "SOUNDS_LAUGH2": "Laugh2",
    "SOUNDS_LAUGH3": "Laugh3",
    "SOUNDS_LO_GEH_TABLA": "Lo Geh Tabla",
    "SOUNDS_LO_GLISS_TABLA": "Lo Gliss Tabla",
    "SOUNDS_LOSE": "Lose",
    "SOUNDS_LOW_BOING": "Low Boing",
    "SOUNDS_LOW_CONGA": "Low Conga",
    "SOUNDS_LOW_SQUEAK": "Low Squeak",
    "SOUNDS_LOW_TOM": "Low Tom",
    "SOUNDS_LOW_WHOOSH": "Low Whoosh",
    "SOUNDS_MACHINE": "Machine",
    "SOUNDS_MAGIC_SPELL": "Magic Spell",
    "SOUNDS_MEDIEVAL1": "Medieval1",
    "SOUNDS_MEDIEVAL2": "Medieval2",
    "SOUNDS_MEOW": "Meow",
    "SOUNDS_MEOW2": "meow2",
    "SOUNDS_MOO": "Moo",
    "SOUNDS_MOTORCYCLE_PASSING": "Motorcycle Passing",
    "SOUNDS_MUTED_CONGA": "Muted Conga",
    "SOUNDS_OCEAN_WAVE": "Ocean Wave",
    "SOUNDS_OOPS": "Oops",
    "SOUNDS_ORCHESTRA_TUNING": "Orchestra Tuning",
    "SOUNDS_OWL": "Owl",
    "SOUNDS_PARTY_NOISE": "Party Noise",
    "SOUNDS_PING_PONG_HIT": "Ping Pong Hit",
    "SOUNDS_PLUCK": "Pluck",
    "SOUNDS_PLUNGE": "Plunge",
    "SOUNDS_POLICE_SIREN": "Police Siren",
    "SOUNDS_POP": "Pop",
    "SOUNDS_RAIN": "Rain",
    "SOUNDS_RATTLE": "Rattle",
    "SOUNDS_REFEREE_WHISTLE": "Referee Whistle",
    "SOUNDS_RICOCHET": "Ricochet",
    "SOUNDS_RIDE_CYMBAL": "Ride Cymbal",
    "SOUNDS_RING_TONE": "Ring Tone",
    "SOUNDS_RIP": "Rip",
    "SOUNDS_RIPPLES": "Ripples",
    "SOUNDS_ROLL_CYMBAL": "Roll Cymbal",
    "SOUNDS_ROOSTER": "Rooster",
    "SOUNDS_SCRAMBLING_FEET": "Scrambling Feet",
    "SOUNDS_SCRATCH_BEATBOX": "scratch beatbox",
    "SOUNDS_SCRATCHY_BEAT": "Scratchy Beat",
    "SOUNDS_SCREAM1": "Scream1",
    "SOUNDS_SCREAM2": "Scream2",
    "SOUNDS_SCREECH": "Screech",
    "SOUNDS_SEAGULLS": "Seagulls",
    "SOUNDS_SEWING_MACHINE": "Sewing Machine",
    "SOUNDS_SHAKER": "Shaker",
    "SOUNDS_SHIP_BELL": "Ship Bell",
    "SOUNDS_SIDESTICK_SNARE": "Sidestick Snare",
    "SOUNDS_SINGER1": "Singer1",
    "SOUNDS_SINGER2": "Singer2",
    "SOUNDS_SIREN_WHISTLE": "Siren Whistle",
    "SOUNDS_SKID": "Skid",
    "SOUNDS_SLIDE_WHISTLE": "Slide Whistle",
    "SOUNDS_SMALL_COWBELL": "Small Cowbell",
    "SOUNDS_SNAP": "Snap",
    "SOUNDS_SNARE_BEATBOX": "Snare Beatbox",
    "SOUNDS_SNARE_BEATBOX2": "Snare Beatbox2",
    "SOUNDS_SNARE_DRUM": "Snare Drum",
    "SOUNDS_SNEAKER_SQUEAK": "Sneaker Squeak",
    "SOUNDS_SNEEZE1": "Sneeze1",
    "SOUNDS_SNEEZE2": "Sneeze2",
    "SOUNDS_SNORING": "Snoring",
    "SOUNDS_SNORT": "Snort",
    "SOUNDS_SPACE_AMBIENCE": "Space Ambience",
    "SOUNDS_SPACE_FLYBY": "Space Flyby",
    "SOUNDS_SPACE_NOISE": "Space Noise",
    "SOUNDS_SPACE_RIPPLE": "Space Ripple",
    "SOUNDS_SPIRAL": "Spiral",
    "SOUNDS_SPLASH": "Splash",
    "SOUNDS_SPLASH_CYMBAL": "Splash Cymbal",
    "SOUNDS_SPOOKY_STRING": "Spooky String",
    "SOUNDS_SQUEAKS": "Squeaks",
    "SOUNDS_SQUEAKY_TOY": "Squeaky Toy",
    "SOUNDS_SQUISH_POP": "Squish Pop",
    "SOUNDS_STRING_ACCENT": "String Accent",
    "SOUNDS_STRING_PLUCK": "String Pluck",
    "SOUNDS_SUCTION_CUP": "Suction Cup",
    "SOUNDS_SUSPENSE": "Suspense",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura",
    "SOUNDS_TAP_CONGA": "Tap Conga",
    "SOUNDS_TAP_SNARE": "Tap Snare",
    "SOUNDS_TECHNO": "Techno",
    "SOUNDS_TECHNO2": "Techno2",
    "SOUNDS_TELEPHONE_RING": "Telephone Ring",
    "SOUNDS_TELEPHONE_RING2": "Telephone Ring2",
    "SOUNDS_TELEPORT": "Teleport",
    "SOUNDS_TELEPORT2": "Teleport2",
    "SOUNDS_TELEPORT3": "Teleport3",
    "SOUNDS_TENNIS_HIT": "Tennis Hit",
    "SOUNDS_THUNDER_STORM": "Thunder Storm",
    "SOUNDS_TOM_DRUM": "Tom Drum",
    "SOUNDS_TOY_HONK": "Toy Honk",
    "SOUNDS_TOY_ZING": "Toy Zing",
    "SOUNDS_TRAFFIC": "Traffic",
    "SOUNDS_TRAIN_WHISTLE": "Train Whistle",
    "SOUNDS_TRIUMPH": "Triumph",
    "SOUNDS_TROPICAL_BIRDS": "Tropical Birds",
    "SOUNDS_TRUMPET1": "Trumpet1",
    "SOUNDS_TRUMPET2": "Trumpet2",
    "SOUNDS_WAH_BEATBOX": "Wah Beatbox",
    "SOUNDS_WAND": "Wand",
    "SOUNDS_WATER_DROP": "Water drop",
    "SOUNDS_WHINNY": "Whinny",
    "SOUNDS_WHISTLE_THUMP": "Whistle Thump",
    "SOUNDS_WHIZ": "Whiz",
    "SOUNDS_WHOOP": "Whoop",
    "SOUNDS_WIN": "Win",
    "SOUNDS_WOBBLE": "Wobble",
    "SOUNDS_WOLF_HOWL": "Wolf Howl",
    "SOUNDS_WOOD_TAP": "Wood Tap",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "Xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_APPLE": "Apple",
    "SPRITES_ARROW1": "Arrow1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Walking",
    "SPRITES_BALL": "Ball",
    "SPRITES_BALLERINA": "Ballerina",
    "SPRITES_BALLOON1": "Balloon1",
    "SPRITES_BANANAS": "Bananas",
    "SPRITES_BASEBALL": "Baseball",
    "SPRITES_BASKETBALL": "Basketball",
    "SPRITES_BEACHBALL": "Beachball",
    "SPRITES_BEAR": "Bear",
    "SPRITES_BEAR-WALKING": "Bear-walking",
    "SPRITES_BEETLE": "Beetle",
    "SPRITES_BELL": "Bell",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Bowl",
    "SPRITES_BOWTIE": "Bowtie",
    "SPRITES_BREAD": "Bread",
    "SPRITES_BROOM": "Broom",
    "SPRITES_BUILDINGS": "Buildings",
    "SPRITES_BUTTON1": "Button1",
    "SPRITES_BUTTON2": "Button2",
    "SPRITES_BUTTON3": "Button3",
    "SPRITES_BUTTON4": "Button4",
    "SPRITES_BUTTON5": "Button5",
    "SPRITES_CAKE": "Cake",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CENTAUR": "Centaur",
    "SPRITES_CLOUD": "Cloud",
    "SPRITES_CLOUDS": "Clouds",
    "SPRITES_CRAB": "Crab",
    "SPRITES_CRYSTAL": "Crystal",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "Dinosaur1",
    "SPRITES_DINOSAUR2": "Dinosaur2",
    "SPRITES_DINOSAUR3": "Dinosaur3",
    "SPRITES_DINOSAUR4": "Dinosaur4",
    "SPRITES_DIVER1": "Diver1",
    "SPRITES_DIVER2": "Diver2",
    "SPRITES_DOG1": "Dog1",
    "SPRITES_DOG2": "Dog2",
    "SPRITES_DONUT": "Donut",
    "SPRITES_DORIAN": "Dorian",
    "SPRITES_DOT": "Dot",
    "SPRITES_DOVE": "Dove",
    "SPRITES_DRAGON": "Dragon",
    "SPRITES_DRUM": "Drum",
    "SPRITES_DRUM_KIT": "Drum Kit",
    "SPRITES_DRUM-CYMBAL": "Drum-cymbal",
    "SPRITES_DRUM-HIGHHAT": "Drum-highhat",
    "SPRITES_DRUM-SNARE": "Drum-snare",
    "SPRITES_DUCK": "Duck",
    "SPRITES_EARTH": "Earth",
    "SPRITES_EGG": "Egg",
    "SPRITES_ELEPHANT": "Elephant",
    "SPRITES_ELF": "Elf",
    "SPRITES_FAIRY": "Fairy",
    "SPRITES_FISH": "Fish",
    "SPRITES_FOX": "Fox",
    "SPRITES_FROG": "Frog",
    "SPRITES_FRUIT_SALAD": "Fruit Salad",
    "SPRITES_GIFT": "Gift",
    "SPRITES_GLASS_WATER": "Glass Water",
    "SPRITES_GLASSES": "Glasses",
    "SPRITES_GOALIE": "Goalie",
    "SPRITES_GOBLIN": "Goblin",
    "SPRITES_GREEN_FLAG": "Green Flag",
    "SPRITES_GRIFFIN": "Griffin",
    "SPRITES_GUITAR": "Guitar",
    "SPRITES_GUITAR-ELECTRIC2": "Guitar-electric2",
    "SPRITES_HEART": "Heart",
    "SPRITES_HEART_CANDY": "Heart Candy",
    "SPRITES_HEART_FACE": "Heart Face",
    "SPRITES_HEDGEHOG": "Hedgehog",
    "SPRITES_HIPPO1": "Hippo1",
    "SPRITES_HOME_BUTTON": "Home Button",
    "SPRITES_JAR": "Jar",
    "SPRITES_JELLYFISH": "Jellyfish",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_KEY": "Key",
    "SPRITES_KEYBOARD": "Keyboard",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Knight",
    "SPRITES_LADYBUG1": "Ladybug1",
    "SPRITES_LADYBUG2": "Ladybug2",
    "SPRITES_LAPTOP": "Laptop",
    "SPRITES_LIGHTNING": "Lightning",
    "SPRITES_LINE": "Line",
    "SPRITES_LION": "Lion",
    "SPRITES_LLAMA": "Llama",
    "SPRITES_MAGIC_WAND": "Magic Wand",
    "SPRITES_MAX": "Max",
    "SPRITES_MERMAID": "Mermaid",
    "SPRITES_MICROPHONE": "Microphone",
    "SPRITES_MILK": "Milk",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Monkey",
    "SPRITES_MOUSE1": "Mouse1",
    "SPRITES_NEIGH_PONY": "Neigh Pony",
    "SPRITES_OCTOPUS": "Octopus",
    "SPRITES_ORANGE": "Orange",
    "SPRITES_ORANGE2": "Orange2",
    "SPRITES_OWL": "Owl",
    "SPRITES_PADDLE": "Paddle",
    "SPRITES_PANTHER": "Panther",
    "SPRITES_PARROT": "Parrot",
    "SPRITES_PENCIL": "Pencil",
    "SPRITES_PLANET2": "Planet2",
    "SPRITES_POTION": "Potion",
    "SPRITES_PRINCE": "Prince",
    "SPRITES_PRINCESS": "Princess",
    "SPRITES_PUFFERFISH": "Pufferfish",
    "SPRITES_RABBIT": "Rabbit",
    "SPRITES_RAINBOW": "Rainbow",
    "SPRITES_REFEREE": "Referee",
    "SPRITES_REINDEER": "Reindeer",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Robot",
    "SPRITES_ROCKS": "Rocks",
    "SPRITES_SAXOPHONE": "Saxophone",
    "SPRITES_SHARK": "Shark",
    "SPRITES_SINGER1": "Singer1",
    "SPRITES_SNAKE": "Snake",
    "SPRITES_SNOWFLAKE": "Snowflake",
    "SPRITES_SNOWMAN": "Snowman",
    "SPRITES_SOCCER_BALL": "Soccer Ball",
    "SPRITES_SPEAKER": "Speaker",
    "SPRITES_STAR": "Star",
    "SPRITES_STARFISH": "Starfish",
    "SPRITES_STOP": "Stop",
    "SPRITES_STRAWBERRY": "Strawberry",
    "SPRITES_SUN": "Sun",
    "SPRITES_SUNGLASSES1": "Sunglasses1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Takeout",
    "SPRITES_TOUCAN": "Toucan",
    "SPRITES_TREE1": "Tree1",
    "SPRITES_TREES": "Trees",
    "SPRITES_TRUMPET": "Trumpet",
    "SPRITES_UNICORN": "Unicorn",
    "SPRITES_WAND": "Wand",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Watermelon",
    "SPRITES_WITCH": "Witch",
    "SPRITES_WIZARD": "Wizard",
    "SPRITES_WIZARD-TOAD": "Wizard-toad",
    "SPRITES_BLOCK-A": "Block-A",
    "SPRITES_BLOCK-B": "Block-B",
    "SPRITES_BLOCK-C": "Block-C",
    "SPRITES_BLOCK-D": "Block-D",
    "SPRITES_BLOCK-E": "Block-E",
    "SPRITES_BLOCK-F": "Block-F",
    "SPRITES_BLOCK-G": "Block-G",
    "SPRITES_BLOCK-H": "Block-H",
    "SPRITES_BLOCK-I": "Block-I",
    "SPRITES_BLOCK-J": "Block-J",
    "SPRITES_BLOCK-K": "Block-K",
    "SPRITES_BLOCK-L": "Block-L",
    "SPRITES_BLOCK-M": "Block-M",
    "SPRITES_BLOCK-N": "Block-N",
    "SPRITES_BLOCK-O": "Block-O",
    "SPRITES_BLOCK-P": "Block-P",
    "SPRITES_BLOCK-Q": "Block-Q",
    "SPRITES_BLOCK-R": "Block-R",
    "SPRITES_BLOCK-S": "Block-S",
    "SPRITES_BLOCK-T": "Block-T",
    "SPRITES_BLOCK-U": "Block-U",
    "SPRITES_BLOCK-V": "Block-V",
    "SPRITES_BLOCK-W": "Block-W",
    "SPRITES_BLOCK-X": "Block-X",
    "SPRITES_BLOCK-Y": "Block-Y",
    "SPRITES_BLOCK-Z": "Block-Z",
    "SPRITES_GLOW-0": "Glow-0",
    "SPRITES_GLOW-1": "Glow-1",
    "SPRITES_GLOW-2": "Glow-2",
    "SPRITES_GLOW-3": "Glow-3",
    "SPRITES_GLOW-4": "Glow-4",
    "SPRITES_GLOW-5": "Glow-5",
    "SPRITES_GLOW-6": "Glow-6",
    "SPRITES_GLOW-7": "Glow-7",
    "SPRITES_GLOW-8": "Glow-8",
    "SPRITES_GLOW-9": "Glow-9",
    "SPRITES_GLOW-A": "Glow-A",
    "SPRITES_GLOW-B": "Glow-B",
    "SPRITES_GLOW-C": "Glow-C",
    "SPRITES_GLOW-D": "Glow-D",
    "SPRITES_GLOW-E": "Glow-E",
    "SPRITES_GLOW-F": "Glow-F",
    "SPRITES_GLOW-G": "Glow-G",
    "SPRITES_GLOW-H": "Glow-H",
    "SPRITES_GLOW-I": "Glow-I",
    "SPRITES_GLOW-J": "Glow-J",
    "SPRITES_GLOW-K": "Glow-K",
    "SPRITES_GLOW-L": "Glow-L",
    "SPRITES_GLOW-M": "Glow-M",
    "SPRITES_GLOW-N": "Glow-N",
    "SPRITES_GLOW-O": "Glow-O",
    "SPRITES_GLOW-P": "Glow-P",
    "SPRITES_GLOW-Q": "Glow-Q",
    "SPRITES_GLOW-R": "Glow-R",
    "SPRITES_GLOW-S": "Glow-S",
    "SPRITES_GLOW-T": "Glow-T",
    "SPRITES_GLOW-U": "Glow-U",
    "SPRITES_GLOW-V": "Glow-V",
    "SPRITES_GLOW-W": "Glow-W",
    "SPRITES_GLOW-X": "Glow-X",
    "SPRITES_GLOW-Y": "Glow-Y",
    "SPRITES_GLOW-Z": "Glow-Z",
    "SPRITES_STORY-A": "Story-A",
    "SPRITES_STORY-B": "Story-B",
    "SPRITES_STORY-C": "Story-C",
    "SPRITES_STORY-D": "Story-D",
    "SPRITES_STORY-E": "Story-E",
    "SPRITES_STORY-F": "Story-F",
    "SPRITES_STORY-G": "Story-G",
    "SPRITES_STORY-H": "Story-H",
    "SPRITES_STORY-I": "Story-I",
    "SPRITES_STORY-J": "Story-J",
    "SPRITES_STORY-K": "Story-K",
    "SPRITES_STORY-L": "Story-L",
    "SPRITES_STORY-M": "Story-M",
    "SPRITES_STORY-N": "Story-N",
    "SPRITES_STORY-O": "Story-O",
    "SPRITES_STORY-P": "Story-P",
    "SPRITES_STORY-Q": "Story-Q",
    "SPRITES_STORY-R": "Story-R",
    "SPRITES_STORY-S": "Story-S",
    "SPRITES_STORY-T": "Story-T",
    "SPRITES_STORY-U": "Story-U",
    "SPRITES_STORY-V": "Story-V",
    "SPRITES_STORY-W": "Story-W",
    "SPRITES_STORY-X": "Story-X",
    "SPRITES_STORY-Y": "Story-Y",
    "SPRITES_STORY-Z": "Story-Z",
    "SPRITES_MIND+2022_01_CIVIL-AIRCRAFT": "Civil Aviation Aircraft",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT1": "Civil Aviation Aircraft-Front",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT2": "Civil Aviation - Side",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT3": "Civil Aviation Aircraft-Top",
    "SPRITES_MIND+2022_02_AEROSPACEPLANE": "Space Shuttle",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE1": "Space Shuttle - Front",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE2": "Space Shuttle-Side",
    "SPRITES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM": "Space Shuttle Cabin",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM1": "Space Shuttle Cabin-Front",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM2": "Space Shuttle Cabin-Side",
    "SPRITES_MIND+2022_04_TRAFFIC-LIGHT": "Expression traffic lights",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT1": "Expression traffic light-red",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT2": "Expression traffic light-yellow",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT3": "Expression traffic light-green",
    "SPRITES_MIND+2022_05_FLYBROW": "Airship",
    "COSTUMES_MIND+2022_05_FLYBROW1": "Airship-Side",
    "COSTUMES_MIND+2022_05_FLYBROW2": "Airship-Look Up",
    "SPRITES_MIND+2022_06_HIGHSPEED-RAIL": "High Speed ​​Rail",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL1": "High Speed ​​Rail-Front",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL2": "High Speed ​​Rail-Side",
    "SPRITES_MIND+2022_07_POLICECAR": "Police Car",
    "COSTUMES_MIND+2022_07_POLICECAR1": "Police Car-Sport 1",
    "COSTUMES_MIND+2022_07_POLICECAR2": "Police Car-Sport 2",
    "COSTUMES_MIND+2022_07_POLICECAR3": "Police Car - Sports 3",
    "COSTUMES_MIND+2022_07_POLICECAR4": "Police Car - Sports 4",
    "COSTUMES_MIND+2022_07_POLICECAR5": "Police Car-Light 1",
    "COSTUMES_MIND+2022_07_POLICECAR6": "Police Car-Lights 2",
    "COSTUMES_MIND+2022_07_POLICECAR7": "Police Car - Stationary",
    "SPRITES_MIND+2022_08_CAR": "Car",
    "COSTUMES_MIND+2022_08_CAR1": "Car-1",
    "COSTUMES_MIND+2022_08_CAR2": "Car-2",
    "COSTUMES_MIND+2022_08_CAR3": "Car-3",
    "COSTUMES_MIND+2022_08_CAR4": "Car-4",
    "COSTUMES_MIND+2022_08_CAR5": "Car-5",
    "COSTUMES_MIND+2022_08_CAR6": "Car-6",
    "SPRITES_MIND+2022_09_DININGCAR": "Dining Car",
    "SPRITES_MIND+2022_10_BUS": "Bus",
    "SPRITES_MIND+2022_11_GO-KART": "Kart",
    "SPRITES_MIND+2022_12_MOTORBIKE": "Motorcycle",
    "SPRITES_MIND+2022_13_TAXI": "Taxi",
    "COSTUMES_MIND+2022_13_TAXI01": "Taxi - Front Side",
    "COSTUMES_MIND+2022_13_TAXI02": "Taxi-Side",
    "COSTUMES_MIND+2022_13_TAXI03": "Taxi - Turn off the lights on the front and side",
    "COSTUMES_MIND+2022_13_TAXI04": "Taxi - Front side lights on 1",
    "COSTUMES_MIND+2022_13_TAXI05": "Taxi - Lights on the front side 2",
    "COSTUMES_MIND+2022_13_TAXI06": "Taxi-Front",
    "SPRITES_MIND+2022_14_PUMPKINCAR": "Pumpkin Car",
    "COSTUMES_MIND+2022_14_PUMPKINCAR1": "Pumpkin Car-1",
    "COSTUMES_MIND+2022_14_PUMPKINCAR2": "Pumpkin Car-2",
    "COSTUMES_MIND+2022_14_PUMPKINCAR3": "Pumpkin Car-3",
    "COSTUMES_MIND+2022_14_PUMPKINCAR4": "Pumpkin Car - Door Open",
    "COSTUMES_MIND+2022_14_PUMPKINCAR5": "Pumpkin Car-Night",
    "SPRITES_MIND+2022_15_LITTLESAILBOAT": "Little Sailing",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT1": "Little Sailing-Side",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT2": "Little Sailing-Top Side",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT3": "Sailboat-Sloping Top Side",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT4": "Sailboat-Sloping Top Side 1",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT5": "Sailboat-Sloping Top Side 2",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT6": "Sailboat-Sloping Top Side 3",
    "SPRITES_MIND+2022_16_RUBBLERACER": "Small spaceship",
    "COSTUMES_MIND+2022_16_RUBBLERACER1": "Little Spaceship-01",
    "COSTUMES_MIND+2022_16_RUBBLERACER2": "Little Spaceship-02",
    "COSTUMES_MIND+2022_16_RUBBLERACER3": "Little Spaceship-03",
    "SPRITES_MIND+2022_17_BARNEY": "Small cart",
    "COSTUMES_MIND+2022_17_BARNEY1": "Mini Cart - Side Full",
    "COSTUMES_MIND+2022_17_BARNEY2": "Mining Cart - Top Side",
    "COSTUMES_MIND+2022_17_BARNEY3": "Mining Cart - Top Side 2",
    "COSTUMES_MIND+2022_17_BARNEY4": "Mining Cart - Top Side 3",
    "COSTUMES_MIND+2022_17_BARNEY5": "Small minecart - top side empty 1",
    "SPRITES_MIND+2022_18_UTILITYCART": "Cart",
    "COSTUMES_MIND+2022_18_UTILITYCART1": "Cart-Side",
    "COSTUMES_MIND+2022_18_UTILITYCART2": "Cart - Top Side",
    "COSTUMES_MIND+2022_18_UTILITYCART3": "Cart-top side full 1",
    "COSTUMES_MIND+2022_18_UTILITYCART4": "Cart - Top Side Full 2",
    "COSTUMES_MIND+2022_18_UTILITYCART5": "Cart-top side full 3",
    "SPRITES_MIND+2022_19_BIKE": "Bike",
    "COSTUMES_MIND+2022_19_BIKE1": "Bike-Side 1",
    "COSTUMES_MIND+2022_19_BIKE2": "Bike-Side 2",
    "COSTUMES_MIND+2022_19_BIKE3": "Bike-Side 3",
    "COSTUMES_MIND+2022_19_BIKE4": "Bike-Side 4",
    "COSTUMES_MIND+2022_19_BIKE5": "Bike-Front",
    "SPRITES_MIND+2022_20_RACECAR": "Race Car",
    "COSTUMES_MIND+2022_20_RACECAR1": "Race-Side",
    "COSTUMES_MIND+2022_20_RACECAR2": "Blue Race Car-Top 1",
    "COSTUMES_MIND+2022_20_RACECAR3": "Blue Race Car - Top 2",
    "COSTUMES_MIND+2022_20_RACECAR4": "Blue Race Car - Top 3",
    "COSTUMES_MIND+2022_20_RACECAR5": "Yellow Race Car - Top 1",
    "COSTUMES_MIND+2022_20_RACECAR6": "Yellow Race Car - Top 2",
    "COSTUMES_MIND+2022_20_RACECAR7": "Yellow Race Car - Top 3",
    "COSTUMES_MIND+2022_20_RACECAR8": "Pink Race Car-Top 1",
    "COSTUMES_MIND+2022_20_RACECAR9": "Pink Race Car - Top 2",
    "COSTUMES_MIND+2022_20_RACECAR10": "Pink Race Car-Top 3",
    "SPRITES_MIND+2022_21_METEORITES1": "Meteorite",
    "COSTUMES_MIND+2022_21_METEORITES1_1": "Meteorite 1-01",
    "COSTUMES_MIND+2022_21_METEORITES1_2": "Meteorite 1-02",
    "COSTUMES_MIND+2022_21_METEORITES1_3": "Meteorite 1-03",
    "SPRITES_MIND+2022_22_METEORITES2": "Meteorite 2",
    "COSTUMES_MIND+2022_22_METEORITES2_1": "Meteorite 2-01",
    "COSTUMES_MIND+2022_22_METEORITES2_2": "Meteorite 2-02",
    "COSTUMES_MIND+2022_22_METEORITES2_3": "Meteorite 2-03",
    "SPRITES_MIND+2022_23_METEORITES3": "Meteorite 3",
    "COSTUMES_MIND+2022_23_METEORITES3_1": "Meteorite 3-01",
    "COSTUMES_MIND+2022_23_METEORITES3_2": "Meteorite 3-02",
    "COSTUMES_MIND+2022_23_METEORITES3_3": "Meteorite 3-03",
    "SPRITES_MIND+2022_24_METEORITES4": "Meteorite 4",
    "COSTUMES_MIND+2022_24_METEORITES4_1": "Meteorite 4-01",
    "COSTUMES_MIND+2022_24_METEORITES4_2": "Meteorite 4-02",
    "COSTUMES_MIND+2022_24_METEORITES4_3": "Meteorite 4-03",
    "COSTUMES_MIND+2022_24_METEORITES4_4": "Meteorite 4-04",
    "SPRITES_MIND+2022_25_METEORITES5": "Meteorite 5",
    "COSTUMES_MIND+2022_25_METEORITES5_1": "Meteorite 5-01",
    "COSTUMES_MIND+2022_25_METEORITES5_2": "Meteorite 5-02",
    "COSTUMES_MIND+2022_25_METEORITES5_3": "Meteorite 5-03",
    "COSTUMES_MIND+2022_25_METEORITES5_4": "Meteorite 5-04",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_01": "Meteorite Adventure A-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_02": "Meteorite Adventure A-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_01": "Meteorite Adventure B-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_02": "Meteorite Adventure B-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_03": "Meteorite Adventure B-3",
    "MIND+_FACE_SIDE": "Mind+ facial expression - side",
    "MIND+_FACE_SIDE-1": "Mind+ facial expression - side-1",
    "MIND+_FACE_SIDE-2": "Mind+ facial expression - side-2",
    "MIND+_FACE_SIDE-3": "Mind+ facial expression - side-3",
    "MIND+_FACE_SIDE-4": "Mind+ facial expression - side-4",
    "MIND+_FACE_SIDE-5": "Mind+ facial expression - side-5",
    "MIND+_FACE_SIDE-6": "Mind+ facial expression - side-6",
    "MIND+_FACE_SIDE-7": "Mind+ facial expression - side-7",
    "MIND+_FACE_SIDE-8": "Mind+ facial expression - side-8",
    "MIND+_FACE_SIDE-9": "Mind+ facial expression - side-9",
    "MIND+_FACE_SIDE-10": "Mind+ facial expression - side-10",
    "MIND+_FACE_POSITIVE": "Mind+ facial expression - front",
    "MIND+_FACE_POSITIVE-1": "Mind+ facial expression - front-1",
    "MIND+_FACE_POSITIVE-2": "Mind+ facial expression - front-2",
    "MIND+_FACE_POSITIVE-3": "Mind+ facial expression - front-3",
    "MIND+_FACE_POSITIVE-4": "Mind+ facial expression - front-4",
    "MIND+_FACE_POSITIVE-5": "Mind+ facial expression - front-5",
    "MIND+_FACE_POSITIVE-6": "Mind+ facial expression - front-6",
    "MIND+_FACE_POSITIVE-7": "Mind+ facial expression - front-7",
    "MIND+_FACE_POSITIVE-8": "Mind+ facial expression - front-8",
    "MIND+_FACE_POSITIVE-9": "Mind+ facial expression - front-9",
    "MIND+_FACE_POSITIVE-10": "Mind+ facial expression - front-10",
    "MIND+_DOZE": "doze",
    "MIND+_DOZE-1": "doze-1",
    "MIND+_DOZE-2": "doze-2",
    "MIND+_DOZE-3": "doze-3",
    "MIND+_SQUAT": "squat & rise",
    "MIND+_SQUAT-1": "squat & rise-1",
    "MIND+_SQUAT-2": "squat & rise-2",
    "MIND+_SQUAT-3": "squat & rise-3",
    "MIND+_ARCHITECT": "Mind+ architect",
    "MIND+_ARCHITECT-1": "Mind+ architect-1",
    "MIND+_ARCHITECT-2": "Mind+ architect-2",
    "MIND+_ARCHITECT-3": "Mind+ architect-3",
    "MIND+_TRAFFIC_POLICE": "Mind+ traffic officer",
    "MIND+_TRAFFIC_POLICE-1": "Mind+ traffic officer-1",
    "MIND+_TRAFFIC_POLICE-2": "Mind+ traffic officer-2",
    "MIND+_TRAFFIC_POLICE-3": "Mind+ traffic officer-3",
    "MIND+_TRAFFIC_POLICE-4": "Mind+ traffic officer-4",
    "MIND+_TRAFFIC_POLICE-5": "Mind+ traffic officer-5",
    "MIND+_TRAFFIC_POLICE-6": "Mind+ traffic officer-6",
    "MIND+_POLICE": "Mind+ police officer",
    "MIND+_POLICE-1": "Mind+ police officer-1",
    "MIND+_POLICE-2": "Mind+ police officer-2",
    "MIND+_POLICE-3": "Mind+ police officer-3",
    "MIND+_POLICE-4": "Mind+ police officer-4",
    "MIND+_POLICE-5": "Mind+ police officer-5",
    "MIND+_POLICE-6": "Mind+ police officer-trousers-1",
    "MIND+_POLICE-7": "Mind+ police officer-trousers-2",
    "MIND+_SCIENTIST": "Mind+ scientist",
    "MIND+_SCIENTIST-1": "Mind+ scientist-1",
    "MIND+_SCIENTIST-2": "Mind+ scientist-2",
    "MIND+_SCIENTIST-3": "Mind+ scientist-3",
    "MIND+_SANTA_CLAUS": "Mind+ Santa Claus",
    "MIND+_SANTA_CLAUS-1": "Mind+ Santa Claus-wave hand",
    "MIND+_SANTA_CLAUS-2": "Mind+ Santa Claus-give presents",
    "MIND+_SANTA_CLAUS-3": "Mind+ Santa Claus-dance",
    "MIND+_FIREMAN": "Mind+ fireman",
    "MIND+_FIREMAN-1": "Mind+ fireman-1",
    "MIND+_FIREMAN-2": "Mind+ fireman-2",
    "MIND+_FIREMAN-3": "Mind+ fireman-3",
    "MIND+_FIREMAN-4": "Mind+ fireman-4",
    "MIND+_DOCTOR": "Mind+ doctor",
    "MIND+_DOCTOR-1": "Mind+ doctor-1",
    "MIND+_DOCTOR-2": "Mind+ doctor-2",
    "MIND+_DOCTOR-3": "Mind+ doctor-3",
    "MIND+_ASTRONAUT_SPACECRAFT": "Mind+ astronaut - spacecraft",
    "MIND+_ASTRONAUT_SPACECRAFT-1": "Mind+ astronaut - spacecraft-1",
    "MIND+_ASTRONAUT_SPACECRAFT-2": "Mind+ astronaut - spacecraft-2",
    "MIND+_ASTRONAUT_SPACECRAFT-3": "Mind+ astronaut - spacecraft-3",
    "MIND+_ASTRONAUT_SPACECRAFT-4": "Mind+ astronaut - spacecraft-4",
    "MIND+_ASTRONAUT_SPACECRAFT-5": "Mind+ astronaut - spacecraft-5",
    "MIND+_ASTRONAUT_STANDING": "Mind+ astronaut - standing",
    "MIND+_ASTRONAUT_STANDING-1": "Mind+ astronaut - standing-1",
    "MIND+_ASTRONAUT_STANDING-2": "Mind+ astronaut - standing-2",
    "BRUSHWOOD": "tussock",
    "BRUSHWOOD-1": "tussock-1",
    "BRUSHWOOD-2": "tussock-2",
    "BRUSHWOOD-3": "tussock-3",
    "BRUSHWOOD-4": "tussock-4",
    "STONE": "stone",
    "STONE-1": "stone-1",
    "STONE-2": "stone-2",
    "STONE-3": "stone-3",
    "STONE-4": "stone-4",
    "TREE": "tree",
    "TREE-1": "tree-1",
    "TREE-2": "tree-2",
    "TREE-3": "tree-3",
    "TREE-4": "tree-4",
    "COPSE": "copse",
    "COPSE-1": "copse-1",
    "COPSE-2": "copse-2",
    "COPSE-3": "copse-3",
    "COPSE-4": "copse-4",
    "SNOW_MOUNTAIN": "snow mountain",
    "SNOW_MOUNTAIN-1": "snow mountain-1",
    "SNOW_MOUNTAIN-2": "snow mountain-2",
    "SNOW_MOUNTAIN-3": "snow mountain-3",
    "SNOW_MOUNTAIN-4": "snow mountain-4",
    "CLOUD": "cloud",
    "CLOUD-1": "cloud-1",
    "CLOUD-2": "cloud-2",
    "CLOUD-3": "cloud-3",
    "CLOUD-4": "cloud-4",
    "CARTOON_PINK_BLUE_BUTTON_GROUP": "cartoon pink & blue button group",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-1": "button-loading",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-2": "button-play",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-3": "button-turn off",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-4": "button-continue",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-5": "button-mute",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-6": "button-start",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-7": "button-power on",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-8": "button-settings",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-9": "button-next level",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-10": "button-volume",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-11": "button-pause",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-12": "button-repeat",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-13": "arrow-up",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-14": "arrow-down",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-15": "arrow-right",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-16": "arrow-left",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP": "tech purple button group",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-1": "button-empty",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-2": "play",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-3": "share",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-4": "check",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-5": "turn off",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-6": "continue",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-7": "mute",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-8": "start game",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-9": "up",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-10": "settings",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-11": "down",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-12": "music",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-13": "game settings",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-14": "right",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-15": "pause",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-16": "rectangular button-empty",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-17": "restart",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-18": "left",
    "DIALOG-1": "dialog-1",
    "DIALOG-2": "dialog-2",
    "DIALOG-3": "dialog-3",
    "DIALOG-4": "dialog-4",
    "DIALOG-5": "dialog-5",
    "DIALOG-6": "dialog-6",
    "DIALOG-7": "dialog-7",
    "DIALOG-8": "dialog-8",
    "DIALOG-9": "dialog-9",
    "DIALOG-10": "dialog-10",
    "Epidemic_Prevention_MIND+": "Mind+ wearing protective coveralls",
    "Epidemic_Prevention_MIND+-1": "Mind+ wearing protective coveralls1",
    "Epidemic_Prevention_MIND+-2": "Mind+ wearing protective coveralls2",
    "VIRUS": "virus",
    "VIRUS-1": "virus-a",
    "VIRUS-2": "virus-b",
    "VIRUS-3": "virus-c",
    "ANTIGEN_REAGENT": "antigen test",
    "ANTIGEN_REAGENT-1": "antigen test-horizontal",
    "ANTIGEN_REAGENT-2": "antigen test-vertical",
    "MASK": "mask",
    "MASK-1": "mask-1",
    "MASK-2": "mask-2",
    "MASK-3": "mask-3",
    "MASK-4": "mask-4",
    "MASK-5": "mask-5",
    "MASK-6": "mask-6",
    "TEMPERATURE_MEASURING_GUN": "thermometer gun",
    "STETHOSCOPE": "stethoscope",
    "DISINFECTANT": "disinfectant",
    "NUMBER-0": "0",
    "NUMBER-1": "1",
    "NUMBER-2": "2",
    "NUMBER-3": "3",
    "NUMBER-4": "4",
    "NUMBER-5": "5",
    "NUMBER-6": "6",
    "NUMBER-7": "7",
    "NUMBER-8": "8",
    "NUMBER-9": "9",
    "LETTER-A": "A",
    "LETTER-B": "B",
    "LETTER-C": "C",
    "LETTER-D": "D",
    "LETTER-E": "E",
    "LETTER-F": "F",
    "LETTER-G": "G",
    "LETTER-H": "H",
    "LETTER-I": "I",
    "LETTER-J": "J",
    "LETTER-K": "K",
    "LETTER-L": "L",
    "LETTER-M": "M",
    "LETTER-N": "N",
    "LETTER-O": "O",
    "LETTER-P": "P",
    "LETTER-Q": "Q",
    "LETTER-R": "R",
    "LETTER-S": "S",
    "LETTER-T": "T",
    "LETTER-U": "U",
    "LETTER-V": "V",
    "LETTER-W": "W",
    "LETTER-X": "X",
    "LETTER-Y": "Y",
    "LETTER-Z": "Z",
    "EXCLAMATORY_MARK": "exclamation mark",
    "EXCLAMATORY_MARK-1": "exclamation mark-1",
    "EXCLAMATORY_MARK-2": "exclamation mark-2",
    "EXCLAMATORY_MARK-3": "exclamation mark-3",
    "YELLOW_PROGRESS_BAR": "yellow progress bar",
    "YELLOW_PROGRESS_BAR-1": "yellow progress bar-1",
    "YELLOW_PROGRESS_BAR-2": "yellow progress bar-2",
    "YELLOW_PROGRESS_BAR-3": "yellow progress bar-3",
    "PROGRESS_BAR": "progress bar",
    "PROGRESS_BAR-1": "progress bar 30",
    "PROGRESS_BAR-2": "progress bar 60",
    "PROGRESS_BAR-3": "progress bar 100",
    "BLUE_PROGRESS_BAR": "blue progress bar",
    "BLUE_PROGRESS_BAR-1": "blue progress bar-1",
    "BLUE_PROGRESS_BAR-2": "blue progress bar-2",
    "BLUE_PROGRESS_BAR-3": "blue progress bar-3",
    "STEREOSCOPIC_QUESTION_MARK": "3D question mark",
    "STEREOSCOPIC_QUESTION_MARK-1": "question mark-gray",
    "STEREOSCOPIC_QUESTION_MARK-2": "question mark-gold",
    "BUBBLE_BOX": "popover",
    "BUBBLE_BOX-1": "popover-1",
    "BUBBLE_BOX-2": "popover-2",
    "VICTORY_FAIL": "victory & failure",
    "VICTORY_FAIL-1": "sheng",
    "VICTORY_FAIL-2": "li",
    "VICTORY_FAIL-3": "shi",
    "VICTORY_FAIL-4": "bai",
    "REFERENCE_NO": "quotation mark",
    "REFERENCE_NO-1": "quotation mark-1",
    "REFERENCE_NO-2": "quotation mark-2",
    "RED_STRIPE": "red bar",
    "GOLD_COIN1": "gold coin 1",
    "BLUE_STRIPE": "blue bar",
    "LIFE": "life",
    "QUADRANGULAR_STAR": "four-angle star",
    "QUESTION_MARK": "question mark",
    "FIVE-POINTED_STAR": "pentagram",
    "AIRSHIP": "airship1",
    "METEORITE": "meterorite",
    "AIRCRAFT": "aircraft",
    "AIRCRAFT-1": "aircraft-1",
    "AIRCRAFT-2": "aircraft-2",
    "AIRCRAFT-3": "aircraft-3",
    "RETRO_AIRCRAFT": "retro aircraft",
    "RETRO_AIRCRAFT-1": "retro aircraft-1",
    "RETRO_AIRCRAFT-2": "retro aircraft-2",
    "RETRO_AIRCRAFT-3": "retro aircraft-3",
    "JEEP": "Jeep",
    "JEEP-1": "Jeep-1",
    "JEEP-2": "Jeep-2",
    "JEEP-3": "Jeep-3",
    "MOTORBOAT": "motor boat",
    "MOTORBOAT-1": "motor boat_side view",
    "MOTORBOAT-2": "motor boat_top view",
    "MOTORBOAT-3": "motor boat_back view",
    "MOTORBOAT-4": "motor boat_front view",
    "SUBMARINE": "submarine",
    "SUBMARINE-1": "submarine_side view",
    "SUBMARINE-2": "submarine_top view",
    "SUBMARINE-3": "submarine_back view",
    "SUBMARINE-4": "submarine_front view",
    "SMALL_SAILBOAT": "sailboat1",
    "SMALL_SAILBOAT-1": "sailboat_side view",
    "SMALL_SAILBOAT-2": "sailboat_top view",
    "SMALL_SAILBOAT-3": "sailboat_back view",
    "SMALL_SAILBOAT-4": "sailboat_front view",
    "VAN": "van",
    "VAN-1": "van_side view",
    "VAN-2": "van_top view",
    "VAN-3": "van_back view",
    "VAN-4": "van_front view",
    "HELICOPTER": "helicopter",
    "HELICOPTER-1": "helicopter_side view",
    "HELICOPTER-2": "helicopter_top view - 1",
    "HELICOPTER-3": "helicopter_top view - 2",
    "HELICOPTER-4": "helicopter_top view - 3",
    "HELICOPTER-5": "helicopter_back view",
    "HELICOPTER-6": "helicopter_front view",
    "BICYCLE": "bicycle - 1",
    "FIRECRACKERS": "firecrackers",
    "FIRECRACKERS-1": "firecrackers - 1",
    "FIRECRACKERS-2": "firecrackers - 2",
    "FIRECRACKERS-3": "firecrackers - 3",
    "COLORFUL_FLAGS": "colorful flag",
    "COLORFUL_FLAGS-1": "colorful flag - 1",
    "COLORFUL_FLAGS-2": "colorful flag - 2",
    "HAPPY_KNOT": "blessing knot",
    "HAPPY_KNOT-1": "blessing knot - 1",
    "HAPPY_KNOT-2": "blessing knot - 2",
    "HAPPY_KNOT-3": "blessing knot - 3",
    "HAPPY_KNOT-4": "blessing knot - 4",
    "HAPPY_KNOT-5": "blessing knot - 5",
    "HAPPY_KNOT-6": "blessing knot - 6",
    "RED_ENVELOPE_ANIMATION": "red-envelope GIF",
    "RED_ENVELOPE_ANIMATION-1": "red-envelope GIF - 1",
    "RED_ENVELOPE_ANIMATION-2": "red-envelope GIF - 2",
    "RED_ENVELOPE_ANIMATION-3": "red-envelope GIF - 3",
    "RED_ENVELOPE_ANIMATION-4": "red-envelope GIF - 4",
    "RED_ENVELOPE_ANIMATION-5": "red-envelope GIF - 5",
    "RED_ENVELOPE_ANIMATION-6": "red-envelope GIF - 6",
    "GOLD_COIN": "gold coin 2",
    "GOLD_COIN-1": "gold coin - 1",
    "GOLD_COIN-2": "gold coin - 2",
    "GOLD_COIN-3": "gold coin - 3",
    "ELK_CART": "elk sled",
    "ELK_CART-1": "elk sled - static",
    "ELK_CART-2": "elk sled - moving",
    "CALENDAR": "calendar",
    "CHRISTMAS_CANDY": "Christmas candy cane",
    "CHRISTMAS_CANDY-1": "Christmas candy cane - 1",
    "CHRISTMAS_CANDY-2": "Christmas candy cane - 2",
    "CHRISTMAS_CANDY-3": "Christmas candy cane - 3",
    "SANTA_CLAUS": "Santa Claus",
    "SANTA_CLAUS-1": "Santa Claus - 1",
    "SANTA_CLAUS-2": "Santa Claus - 2",
    "SANTA_CLAUS-3": "Santa Claus - 3",
    "SANTA_CLAUS2": "Santa Claus 2",
    "JINGLING_BELL": "jingle bell",
    "JINGLING_BELL-1": "jingle bell - 1",
    "JINGLING_BELL-2": "jingle bell - 2",
    "JINGLING_BELL-3": "jingle bell - gold",
    "JINGLING_BELL-4": "jingle bell - silver",
    "CHRISTMAS_TREE": "Christmas tree",
    "CHRISTMAS_TREE-1": "Christmas tree - 1",
    "CHRISTMAS_TREE-2": "Christmas tree - 2",
    "CHRISTMAS_TREE_DYNAMIC": "Christmas tree - GIF",
    "CHRISTMAS_TREE_DYNAMIC-1": "Christmas tree - red lights",
    "CHRISTMAS_TREE_DYNAMIC-2": "Christmas tree - yellow lights",
    "CHRISTMAS_TREE_DYNAMIC-3": "Christmas tree - lights off",
    "CHRISTMAS_TREE_DYNAMIC-4": "Christmas tree - lights on",
    "CHRISTMAS_STOCKING": "Christmas stocking",
    "CHRISTMAS_STOCKING-1": "Christmas stocking - green stars on red background",
    "CHRISTMAS_STOCKING-2": "Christmas stocking - red stars on green background",
    "CHRISTMAS_STOCKING-3": "Christmas stocking - red stripes",
    "CHRISTMAS_STOCKING-4": "Christmas stocking - green stripes",
    "CANDY": "candy",
    "SOCKS": "socks",
    "SMALL_WINDMILL": "small windmill",
    "LITTLE_WOODEN_HORSE": "small wooden horse",
    "SNOWFLAKE": "snowflake",
    "SNOWMAN1": "snowman 1",
    "SNOWMAN1-1": "snowman - initial",
    "SNOWMAN1-2": "snowman - with hat",
    "SNOWMAN1-3": "snowman - with gloves",
    "SNOWMAN1-4": "snowman - with scarf",
    "SNOWMAN2": "snowman 2",
    "SNOWMAN2-1": "snowman - 1",
    "SNOWMAN2-2": "snowman - 2",
    "SNOWMAN2-3": "snowman head",
    "REINDEER": "reindeer",
    "REINDEER-1": "reindeer - 1",
    "REINDEER-2": "reindeer - 2",
    "REINDEER_CART": "reindeer sled",
    "REINDEER_CART-1": "reindeer sled - 1",
    "REINDEER_CART-2": "reindeer sled - 2",
    "ROUND_LANTERN": "round lantern",
    "ROUND_LANTERN-1": "round lantern - 1",
    "ROUND_LANTERN-2": "round lantern - 2",
    "LONG_LANTERN": "long lantern",
    "LONG_LANTERN-1": "long lantern - 1",
    "LONG_LANTERN-2": "long lantern - 2",
    "PAPER_AIRPLANE": "paper plane",
    "PAPER_AIRPLANE-1": "paper plane - red",
    "PAPER_AIRPLANE-2": "paper plane - yellow",
    "PAPER_AIRPLANE-3": "paper plane - blue",
    "PAPER_AIRPLANE-4": "paper plane - green",
    "CHINESE_KNOT": "Chinese knot",
    "CHINESE_KNOT-1": "Chinese knot - 1",
    "CHINESE_KNOT-2": "Chinese knot - 2",
    "SKIING_PEOPLE": "skiing - people",
    "SKIING_PEOPLE-1": "skiing - people - jumping 1",
    "SKIING_PEOPLE-2": "skiing - people - jumping 2",
    "SKIING_PEOPLE-3": "skiing - people - sliding 1",
    "SKIING_PEOPLE-4": "skiing - people - sliding 2",
    "BOY": "boy",
    "BOY-1": "boy - 1",
    "BOY-2": "boy - 2",
    "BOY-3": "boy - 3",
    "BOY-4": "boy - 4",
    "AVATAR_BOY": "boy avatar",
    "AVATAR_GIRL": "girl avatar",
    "GIRL": "girl",
    "GIRL-1": "girl - 1",
    "GIRL-2": "girl - 2",
    "GIRL-3": "girl - 3",
    "GIRL-4": "girl - 4",
    "TURKEY": "turkey chicken",
    "GINGERBREAD_MAN": "gingerbread man",
    "SKI_MARK": "ski mark",
    "SKI_MARK-1": "ski mark - 1",
    "SKI_MARK-2": "ski mark - 2",
    "SKI_MARK-3": "ski mark - 3",
    "SKI_MARK-4": "ski mark - 4",
    "BACKDROPS_2022_SKI-FIELD-1": "ski slope - 1",
    "BACKDROPS_2022_SKI-FIELD-2": "ski slope - 2",
    "BACKDROPS_2022_AIRPORT-RUNWAY": "airport runway",
    "BACKDROPS_2022_INTERSECTION": "intersection",
    "BACKDROPS_2022_SKY-1": "sky - 1",
    "BACKDROPS_2022_SKY-2": "sky - 2",
    "BACKDROPS_2022_SETTING-SUN-1": "sunset - 1",
    "BACKDROPS_2022_SETTING-SUN-2": "sunset - 2",
    "BACKDROPS_2022_SETTING-SUN-3": "sunset - 3",
    "BACKDROPS_2022_MARS": "Mars",
    "BACKDROPS_2022_STARRY-SKY-1": "starry sky - 1",
    "BACKDROPS_2022_STARRY-SKY-2": "starry sky - 2",
    "BACKDROPS_2022_STARRY-SKY-3": "starry sky - 3",
    "BACKDROPS_2022_MOON-1": "Moon - 1",
    "BACKDROPS_2022_MOON-2": "Moon - 2",
    "BACKDROPS_2022_SANTA-BACKGROUND": "Santa Claus background",
    "BACKDROPS_2022_CHRISTMAS-THEME-BACKGROUND": "Christmas background",
    "BACKDROPS_2022_SNOWMAN-BACKGROUND": "snowman background",
    "WHITE-MOON": "White Moon",
    "GRASSLAND": "Grassland",
    "YELLOW-MOON": "Yellow Moon",
    "SKATEBOARD-1": "Skateboard - Side",
    "SKATEBOARD-2": "Skateboard - Bottom",
    "SKATEBOARD-3": "Skateboard - Top",
    "SKATEBOARD-4": "Skateboard - Front",
    "SKATEBOARD": "Skateboard",
    "CARTOON-ARTPLANES-1": "Cartoon Artplans - Front",
    "CARTOON-ARTPLANES-2": "Cartoon Artplans - Bottom",
    "CARTOON-ARTPLANES-3": "Cartoon Artplans - Right",
    "CARTOON-ARTPLANES-4": "Cartoon Artplans - Left",
    "CARTOON-ARTPLANES-5": "Cartoon Artplans - Top",
    "CARTOON-ARTPLANES-6": "Cartoon Artplans - Rear",
    "CARTOON-ARTPLANES": "Cartoon Artplans",
    "BICYCLE-GRAY-1": "Bicycle-Gray - Side",
    "BICYCLE-GRAY-2": "Bicycle-Gray - Top",
    "BICYCLE-GRAY-3": "Bicycle-Gray - Front",
    "BICYCLE-GRAY-4": "Bicycle-Gray - Rear",
    "BICYCLE-GRAY": "Bicycle-Gray",
    "BATS-1": "Bats-1",
    "BATS-2": "Bats-2",
    "BATS-3": "Bats-3",
    "BATS-4": "Bats-4",
    "BATS": "Bats ",
    "CASTLE-1": "Castle-1",
    "CASTLE-2": "Castle-2",
    "CASTLE": "Castle",
    "JACK-o'-LANTERN-1": "Jack-o'-lantern1-1",
    "JACK-o'-LANTERN-2": "Jack-o'-lantern1-2",
    "JACK-o'-LANTERN-3": "Jack-o'-lantern1-3",
    "JACK-o'-LANTERN-4": "Jack-o'-lantern2",
    "JACK-o'-LANTERN-5": "Jack-o'-lantern3",
    "JACK-o'-LANTERN-6": "Jack-o'-lantern4",
    "JACK-o'-LANTERN": "Jack-o'-lantern",
    "WOODS-1": "woods-1",
    "WOODS-2": "woods-2",
    "WOODS": "woods",
    "DANCING-LION-RED-1": "Dancing Lion-Red-1",
    "DANCING-LION-RED-2": "Dancing Lion-Red-2",
    "DANCING-LION-RED-3": "Dancing Lion-Red-3",
    "DANCING-LION-RED-4": "Dancing Lion-Red-4",
    "DANCING-LION-RED-5": "Dancing Lion-Red-5",
    "DANCING-LION-RED-6": "Dancing Lion-Red-6",
    "DANCING-LION-RED": "Dancing Lion-Red",
    "DANCING-LION-BLUE-1": "Dancing Lion-Blue-1",
    "DANCING-LION-BLUE-2": "Dancing Lion-Blue-2",
    "DANCING-LION-BLUE-3": "Dancing Lion-Blue-3",
    "DANCING-LION-BLUE": "Dancing Lion-Blue",
    "DANCING-LION-GREEN-1": "Dancing Lion-Green-1",
    "DANCING-LION-GREEN-2": "Dancing Lion-Green-2",
    "DANCING-LION-GREEN-3": "Dancing Lion-Green-3",
    "DANCING-LION-GREEN": "Dancing Lion-Green",
    "SPECTRE-1": "Specter-1",
    "SPECTRE-2": "Specter-2",
    "SPECTRE-3": "Specter-3",
    "SPECTRE-4": "Specter-4",
    "SPECTRE": "Specter",
    "SPIDER-1": "Spider-1",
    "SPIDER-2": "Spider-2",
    "SPIDER-3": "Spider-3",
    "SPIDER": "Spider",
    "GOLF-PEOPLE1-1": "Golf-People1-1",
    "GOLF-PEOPLE1-2": "Golf-People1-2",
    "GOLF-PEOPLE1-3": "Golf-People1-3",
    "GOLF-PEOPLE1": "Golf-People1",
    "GOLF-PEOPLE2-1": "Golf-People2-1",
    "GOLF-PEOPLE2-2": "Golf-People2-2",
    "GOLF-PEOPLE2-3": "Golf-People2-3",
    "GOLF-PEOPLE2": "Golf-People2",
    "GOLF": "Golf",
    "GOLF-HOLES": "Golf-Holes",
    "GOLF-SEATS": "Golf-Seats",
    "GREEN-LEAVES-1": "Green-Leaves-1",
    "GREEN-LEAVES-2": "Green-Leaves-2",
    "GREEN-LEAVES": "Green-Leaves",
    "CACTUS-1": "Cactus-1",
    "CACTUS-2": "Cactus-2",
    "CACTUS": "Cactus",
    "FLORETS-1": "Florets-1",
    "FLORETS-2": "Florets-2",
    "FLORETS-3": "Florets-3",
    "FLORETS-4": "Florets-4",
    "FLORETS-5": "Florets-5",
    "FLORETS-6": "Florets-6",
    "FLORETS-7": "Florets-7",
    "FLORETS-8": "Florets-8",
    "FLORETS": "Florets"
  },
  "pt-br": {
    "gui.gui.project": "Projeto",
    "gui.gui.newItem": "Novo Projeto",
    "gui.gui.load": "Carregar Projeto",
    "gui.gui.save": "Salvar Projeto",
    "gui.gui.saveAs": "Salvar Como",
    "gui.gui.cacheList": "Lista de Cache",
    "gui.gui.inCache": "No Cache",
    "gui.gui.learn": "Aprendizado",
    "gui.gui.clearRecord": "Limpar Registro",
    "gui.gui.onlineLearn": "Documentos Oficiais",
    "gui.gui.onlineForum": "Fórum Online",
    "gui.gui.videoTutorial": "Tutoriais em Vídeo",
    "gui.gui.offlineLearn": "Programas de Exemplo",
    "gui.menuBar.turboModeOff": "Desligar Modo Turbo",
    "gui.menuBar.turboModeOn": "Ligar Modo Turbo",
    "gui.menuBar.edit": "Editar",
    "gui.menuBar.restoreSprite": "Restaurar Sprite",
    "gui.menuBar.restoreSound": "Restaurar Som",
    "gui.menuBar.restoreCostume": "Restaurar Traje",
    "gui.menuBar.restoreBackdrop": "Restaurar Pano de Fundo",
    "gui.editorMind.restore": "Restaurar",
    "gui.turboMode.active": "Modo Turbo",
    "gui.gui.connectDevice": "Conectar Dispositivo",
    "gui.gui.disconnectDevice": "Desconectar Dispositivo",
    "gui.gui.installSerialportDriver": "Instalar Driver SerialPort",
    "gui.gui.openInstallSerialportDriverCourse": "Visitar o tutorial para instalação do driver",
    "gui.gui.openDM": "Abrir Gerenciador de Dispositivos",
    "gui.gui.restoreSetting": "Restaurar configurações iniciais do dispositivo",
    "gui.gui.updateTips": "Dicas de Atualização",
    "gui.gui.newVersion": "A Última Versão",
    "gui.gui.downloadUpdate": "Baixar para atualizar",
    "gui.gui.versionUpdate": "Atualizar a versão",
    "gui.gui.isCheckMd5": "Verificando arquivo",
    "gui.gui.downloading": "Verificando Atualizador",
    "gui.gui.updateError": "Erro de Atualização",
    "gui.setting.feedbackMenu": "Feedback",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.gui.python": "Python",
    "gui.setting.officialWebsit": "Site Oficial",
    "gui.setting.officialQQGroup": "Grupo Oficial no QQ",
    "gui.setting.wechatOfficialAccounts": "Contas Oficiais no WeChat",
    "gui.setting.currentVersion": "Versão Atual",
    "gui.setting.checkForUpdate": "Verificar Atualizações",
    "gui.setting.remindWhenUpdate": "Baixar quando atualizado",
    "gui.setting.currentIsLatest": "A versão atual é a mais recente",
    "gui.setting.latestVersion": "A última versão",
    "gui.setting.download": "Ir para o site de download",
    "gui.setting.language": "Idioma",
    "gui.setting.uploadCsv": "enviar xlsx",
    "gui.setting.theme": "Tema",
    "gui.setting.feedback": "Contato",
    "gui.setting.email": "E-mail",
    "gui.setting.opinionFeedback": "Feedback",
    "gui.variableMc.variableName": "nome da variável",
    "gui.variableMc.variableOff": "cancelar",
    "gui.variableMc.variableOn": "confirmar",
    "gui.variableMc.newVariable": "nova variável",
    "gui.setting.helpUsTranslate": "Ajude-nos a Traduzir",
    "gui.setting.checkUpdate": "Verificar Atualização",
    "gui.setting.ok": "Ok",
    "gui.setting.languageSetting": "Língua",
    "gui.setting.themeSetting": "Tema",
    "gui.setting.cacheSetting": "Configuração de Cache",
    "gui.setting.versionUpdate": "Atualização de Versão",
    "gui.setting.connectUsTitle": "Contate-nos",
    "gui.setting.uploadAttachment": "Enviar Anexo",
    "gui.setting.displayTitle": "Configuração de Exibição",
    "gui.setting.fontSizeSetting": "tamanho da fonte",
    "gui.setting.restartEffect": "efeito de reinicialização",
    "gui.setting.fontSizeLarge": "Grande",
    "gui.setting.fontSizeMiddle": "Médio",
    "gui.setting.fontSizeSmall": "Pequeno",
    "gui.setting.onlineRepair": "ferramenta de reparo online, clique para abrir >",
    "gui.setting.cacheSwitch": "Cache (abrir/fechar)",
    "gui.setting.freeCache": "Limpar cache",
    "gui.setting.spaceCache": "Intervalo fixo",
    "gui.setting.cacheWay": "Modo de Cache",
    "gui.setting.interval": "intervalo",
    "gui.setting.freeTime": "Tempo Livre",
    "gui.setting.second": "segundo",
    "gui.setting.minute": "minuto",
    "gui.gui.code": "Código",
    "gui.menubar.cloudProject": "Projeto na Nuvem",
    "gui.menubar.login": "Login",
    "gui.menubar.signout": "Sair",
    "gui.menubar.saveToCloud": "Salvar na Nuvem",
    "gui.menubar.cloudServer": "Nuvem",
    "gui.setting.cleanCache": "Limpeza de Dados",
    "gui.setting.programCache": "Cache do Projeto",
    "gui.setting.configJson": "Arquivo de Configuração",
    "gui.setting.pythonEnv": "Ambiente Python",
    "gui.setting.backpack": "Arquivo da Mochila",
    "gui.setting.compile": "Informações de Compilação",
    "gui.setting.thirdExt": "Biblioteca de Usuário Estendida",
    "gui.setting.cleanCacheBtn": "Limpar Seleção",
    "gui.setting.cleanCachePrompt": "Os dados não podem ser restaurados após a limpeza, tem certeza de que deseja limpar?",
    "gui.setting.cleanPythonEnvPrompt": "Para evitar a exceção de outras janelas causada pela limpeza do ambiente Python, certifique-se de que apenas uma janela do software Mind+ está aberta antes de limpar.",
    "gui.setting.cleanPyEnvOnPythonPrompt": "No modo Python, é necessário redefinir o ambiente após a limpeza dos recursos. Esse processo precisa aguardar um pouco. E para evitar que a limpeza dos recursos cause exceções em outras janelas, certifique-se de que apenas uma janela do software Mind+ está aberta antes da limpeza.",
    "gui.setting.expVersion": "Versão Experimental",
    "gui.setting.expUpdateTips": "[Update Notes]: Esta versão é uma versão para usuários adiantados que ainda não foi oficialmente lançada. Algumas funções podem ser instáveis. Por favor, confirme antes de baixar;",
    "gui.setting.cleanConfigSuccess": "A limpeza de dados está completa, por favor, reinicie o Mind+.",
    "gui.setting.cleanConfigError": "Ocorreu um erro durante a limpeza de dados, por favor, tente novamente.",
    "gui.setting.cleanPartError": "Erro ao limpar [PART]...",
    "gui.setting.cleaningPyEnv": "Limpando o ambiente Python...",
    "gui.setting.cleanPythonEnvError": "A liberação de recursos do ambiente Python falhou. Você pode fechar o software, excluir manualmente a pasta [CAMINHO] e, em seguida, reabrir o software.",
    "gui.setting.multiWindowSetting": "Múltiplas Instâncias do Software",
    "gui.setting.multiWindowSettingTips": "Recomenda-se desativar a função para computadores com desempenho mais fraco para evitar atrasos no sistema causados pela abertura de vários aplicativos ao mesmo tempo.",
    "gui.setting.multiWindowTrue": "Abrir",
    "gui.setting.multiWindowFalse": "Fechar",
    "gui.setting.systemSetting": "Configurações do Sistema",
    "gui.backpack.header": "Mochila",
    "gui.backpack.errorBackpack": "Erro ao carregar a mochila",
    "gui.backpack.loadingBackpack": "Carregando...",
    "gui.backpack.more": "Mais",
    "gui.backpack.emptyBackpack": "A mochila está vazia",
    "gui.gui.costumesTab": "Fantasias",
    "gui.gui.soundsTab": "Sons",
    "gui.gui.backdropsTab": "Cenários",
    "gui.gui.addExtension": "Adicionar uma Extensão",
    "gui.costumeTab.addCostumeFromLibrary": "Escolher Fantasia",
    "gui.costumeLibrary.chooseACostume": "Escolher Fantasia",
    "gui.costumeTab.addBackdropFromLibrary": "Selecionar Cenário",
    "gui.costumeTab.addBlankCostume": "Pintar",
    "gui.costumeTab.addSurpriseCostume": "Surpresa",
    "gui.costumeTab.addFileBackdrop": "Carregar Cenário",
    "gui.costumeTab.addFileCostume": "Carregar Fantasia",
    "gui.costumeTab.addCameraCostume": "Câmera",
    "gui.soundEditor.trim": "Cortar Trecho",
    "gui.soundEditor.stop": "Parar",
    "gui.soundEditor.sound": "Som",
    "gui.soundEditor.play": "Jogar",
    "gui.soundEditor.save": "Salvar",
    "gui.soundEditor.undo": "Desfazer",
    "gui.soundEditor.redo": "Refazer",
    "gui.soundEditor.faster": "Mais Rápido",
    "gui.soundEditor.slower": "Mais Devagar",
    "gui.soundEditor.echo": "Eco",
    "gui.soundEditor.robot": "Robô",
    "gui.soundEditor.louder": "Mais Alto",
    "gui.soundEditor.softer": "Mais Baixo",
    "gui.soundEditor.reverse": "Inverter",
    "gui.soundTab.recordSound": "Gravar",
    "gui.soundTab.addSoundFromLibrary": "Selecionar um Som",
    "gui.soundTab.surpriseSound": "Surpresa",
    "gui.soundTab.fileUploadSound": "Carregar Som",
    "gui.controls.stop": "Pare",
    "gui.controls.go": "Ir",
    "gui.stageHeader.stageSizeUnFull": "Sair do modo de tela cheia",
    "gui.SpriteInfo.show": "Mostrar",
    "gui.SpriteInfo.size": "Tamanho",
    "gui.directionPicker.rotationStyles.allAround": "Rotação Completa",
    "gui.directionPicker.rotationStyles.leftRight": "Esquerda/Direita",
    "gui.directionPicker.rotationStyles.dontRotate": "Não gire",
    "gui.spriteSelectorItem.contextMenuDuplicate": "Duplicar",
    "gui.spriteSelectorItem.contextMenuDelete": "Apagar",
    "gui.spriteSelectorItem.contextMenuExport": "Exportar",
    "gui.spriteSelector.addSpriteFromPaint": "Pintar",
    "gui.spriteSelector.addSpriteFromSurprise": "Surpresa",
    "gui.spriteSelector.addSpriteFromFile": "Enviar Ator",
    "gui.stageSelector.stage": "Palco",
    "gui.stageSelector.backdrops": "Cenários",
    "gui.stageSelector.addBackdropFromPaint": "Pintar",
    "gui.stageSelector.addBackdropFromSurprise": "Surpresa",
    "gui.stageSelector.addBackdropFromFile": "Carregar Cenário",
    "gui.modal.back": "Voltar",
    "gui.library.filterPlaceholder": "Buscar",
    "gui.libraryTags.all": "Tudo",
    "gui.libraryTags.animals": "Animais",
    "gui.libraryTags.dance": "Dança",
    "gui.libraryTags.effects": "Efeitos",
    "gui.libraryTags.fantasy": "Imaginários",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Comida",
    "gui.libraryTags.indoors": "Interior",
    "gui.libraryTags.loops": "Loops",
    "gui.libraryTags.music": "Música",
    "gui.libraryTags.notes": "Notas",
    "gui.libraryTags.outdoors": "Exterior",
    "gui.libraryTags.patterns": "Padrões",
    "gui.libraryTags.people": "Pessoas",
    "gui.libraryTags.percussion": "Percussão",
    "gui.libraryTags.space": "Espaço",
    "gui.libraryTags.sports": "Esportes",
    "gui.libraryTags.underwater": "Embaixo d'água",
    "gui.libraryTags.voice": "Voz",
    "gui.libraryTags.wacky": "Maluco",
    "gui.libraryTags.animation": "Animações",
    "gui.libraryTags.art": "Arte",
    "gui.libraryTags.games": "Jogos",
    "gui.libraryTags.stories": "Histórias",
    "gui.libraryTags.letters": "Letras",
    "gui.soundLibrary.chooseASound": "Selecione um Som",
    "gui.SpriteInfo.spritePlaceholder": "Nome",
    "gui.cards.more-things-to-try": "Mais coisas para experimentar!",
    "gui.cards.see-more": "Veja mais",
    "gui.cards.shrink": "Reduzir",
    "gui.cards.expand": "Expandir",
    "gui.cards.close": "Fechar",
    "gui.loader.message1": "Criando blocos...",
    "gui.loader.message2": "Carregando atores...",
    "gui.loader.message3": "Carregando sons...",
    "gui.loader.message4": "Carregando extensões...",
    "gui.loader.message5": "Reunindo a gataria...",
    "gui.loader.message6": "Transmitindo nanos...",
    "gui.loader.message7": "Inflando gobos...",
    "gui.loader.message8": "Preparando emojis...",
    "gui.loader.headline": "Carregando Projeto",
    "gui.cameraModal.cameraModalTitle": "Tirar uma Foto",
    "gui.cameraModal.loadingCameraMessage": "Carregando a Câmera...",
    "gui.cameraModal.permissionRequest": "Nós precisamos da sua permissão para usar sua câmera",
    "gui.cameraModal.retakePhoto": "Retomar a Foto ",
    "gui.cameraModal.save": "Salvar",
    "gui.cameraModal.takePhoto": "Tirar Foto",
    "gui.cameraModal.loadingCaption": "Carregando...",
    "gui.cameraModal.enableCameraCaption": "Habilitar Câmera",
    "gui.recordModal.title": "Gravar Som",
    "gui.playbackStep.stopMsg": "Parar",
    "gui.playbackStep.playMsg": "Tocar",
    "gui.playbackStep.loadingMsg": "Carregando...",
    "gui.playbackStep.saveMsg": "Salvar",
    "gui.playbackStep.reRecordMsg": "Regravar",
    "gui.webglModal.label": "Seu navegador não suporta WebGL",
    "gui.webglModal.webgllink": "não suporta WebGL",
    "gui.prompt.cancel": "Cancelar",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Adicionar uma entrada",
    "gui.customProcedures.addAnInputBoolean": "Adicionar uma entrada",
    "gui.customProcedures.numberTextType": "número ou texto",
    "gui.customProcedures.booleanType": "booleano",
    "gui.customProcedures.addALabel": "Adicionar um rótulo",
    "gui.customProcedures.runWithoutScreenRefresh": "Executar sem atualização de tela",
    "gui.customProcedures.cancel": "Cancelar",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Conecte seus projetos ao mundo.",
    "gui.extension.music.name": "Música",
    "gui.extension.music.description": "Toque instrumentos e tambores.",
    "gui.extension.pen.name": "Caneta",
    "gui.extension.pen.description": "Desenhe com os seus atores.",
    "gui.gui.variableScopeOptionAllSprites": "Para todos os atores",
    "gui.gui.variableScopeOptionSpriteOnly": "Apenas para este ator",
    "gui.gui.variablePromptAllSpritesMessage": "Esta variável ficará disponível para todos os atores.",
    "gui.monitor.contextMenu.default": "letras tamanho normal",
    "gui.monitor.contextMenu.large": "letras grandes",
    "gui.monitor.contextMenu.slider": "controle deslizante",
    "gui.monitor.contextMenu.import": "importar",
    "gui.monitor.contextMenu.export": "Exportar",
    "gui.opcodeLabels.direction": "direção",
    "gui.opcodeLabels.xposition": "posição x",
    "gui.opcodeLabels.yposition": "posição y",
    "gui.opcodeLabels.size": "tamanho",
    "gui.opcodeLabels.costumename": "nome da fantasia",
    "gui.opcodeLabels.costumenumber": "número da fantasia",
    "gui.opcodeLabels.backdropname": "nome do cenário",
    "gui.opcodeLabels.backdropnumber": "cenário número",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "ritmo",
    "gui.opcodeLabels.answer": "resposta",
    "gui.opcodeLabels.loudness": "nível de ruído",
    "gui.opcodeLabels.year": "ano",
    "gui.opcodeLabels.month": "mês",
    "gui.opcodeLabels.date": "data",
    "gui.opcodeLabels.dayofweek": "dia da semana",
    "gui.opcodeLabels.hour": "hora",
    "gui.opcodeLabels.minute": "minuto",
    "gui.opcodeLabels.second": "segundo",
    "gui.opcodeLabels.timer": "cronômetro",
    "gui.gui.blocks": "Blocos",
    "gui.sliderModal.min": "Valor Mínimo",
    "gui.sliderModal.max": "Valor Máximo",
    "gui.sliderModal.title": "Alterar a Faixa do Controle Deslizante",
    "gui.soundTab.addSound": "Adicionar Som",
    "gui.controls.customFirmware": "Firmware Personalizado",
    "gui.controls.defaultFirmware": "Firmware Oficial",
    "gui.controls.clearLocaleFirmware": "Limpar Registros",
    "gui.controls.checkForUpdates": "Verificar Atualizações",
    "gui.controls.alreadyLastVersion": "Já é a versão mais recente",
    "gui.controls.uploadFirmware": "Carregar",
    "gui.gui.startPython": "Iniciar Python",
    "gui.gui.stopPython": "Parar Python",
    "gui.controls.fullScreenControl": "Controle em Tela Cheia",
    "gui.gui.stageSizeLarge": "Controle em Tela Grande",
    "gui.gui.stageSizeSmall": "Controle em Tela Pequena",
    "gui.gui.stageSizeNostage": "Alternar Tamanho do Palco - Sem Palco",
    "gui.gui.stageSizeFull": "Alternar Tamanho do Palco - Tela Cheia",
    "gui.sprite.sprite": "Sprite",
    "gui.sprite.direction": "Direção",
    "gui.sprite.rotation": "Rotação",
    "gui.sprite.addSpriteFromLibrary": "Biblioteca de Sprites",
    "gui.sprite.addSpriteFromCamera": "Câmera",
    "gui.stage.addBackdropFromLibrary": "Escolher um Plano de Fundo",
    "gui.stage.addBackdropFromCamera": "Câmera",
    "gui.modal.help": "Ajuda",
    "gui.modal.save": "Salvar",
    "gui.modal.run": "Executar",
    "gui.modal.continue": "Continuar",
    "gui.modal.paused": "Pausado",
    "gui.extension.kit": "Kit",
    "gui.extension.kitTitle": "Selecionar Kit",
    "gui.extension.board": "Placa",
    "gui.extension.boardTitle": "Selecionar Placa",
    "gui.extension.spreadBoard": "Escudo",
    "gui.extension.spreadBoardTitle": "Selecionar Escudo",
    "gui.extension.sensor": "Sensor",
    "gui.extension.sensorTitle": "Selecionar Sensor",
    "gui.extension.actuator": "Atuador",
    "gui.extension.actuatorTitle": "Selecionar Atuador",
    "gui.extension.communicationModule": "Comunicação",
    "gui.extension.communicationModuleTitle": "Selecionar Comunicação",
    "gui.extension.display": "Display",
    "gui.extension.displayTitle": "Selecionar Display",
    "gui.extension.function": "Função",
    "gui.extension.functionTitle": "Selecionar Função",
    "gui.extension.internetService": "Internet",
    "gui.extension.internetServiceTitle": "Selecionar Internet",
    "gui.extension.thirdExpansion": "User-Ext",
    "gui.extension.thirdExpansionTitle": "Selecionar Expansão do Usuário",
    "gui.extension.arduinContentLabel": "Selecionar Dispositivo",
    "gui.extension.openUserExFile": "Abrir o arquivo User-Ex no seu computador",
    "gui.extension.importUserEx": "Importar User-Ex",
    "gui.extension.unavailable": "Indisponível",
    "gui.extension.thirdModuleSearch": "Pesquisar ou inserir URL do projeto",
    "gui.extension.thirdModulesHelp": "Observação: Os módulos na biblioteca do usuário são feitos por entusiastas do Mind+, {clickHelp} veja {tutorial} e {listagem}",
    "gui.extension.thirdTutorial": "Tutorial de Desenvolvimento",
    "gui.extension.thirdList": "Lista de Expansões do Usuário",
    "gui.extension.libaryOffice": "Biblioteca Oficial",
    "gui.extension.libaryPinpong": "Pinpong",
    "gui.libraryTags.festival": "Festival",
    "gui.libraryTags.traffic": "Tráfego",
    "gui.libraryTags.mind": "Mind+",
    "gui.libraryTags.natural": "Natureza",
    "gui.libraryTags.button": "Botão",
    "gui.libraryTags.dialog": "Diálogo",
    "gui.libraryTags.epidemicPrevention": "Prevenção de Epidemias",
    "gui.libraryTags.numberAndLetter": "Números e Letras",
    "gui.libraryTags.gameComponents": "Componentes do jogo",
    "gui.extension.backdropLib": "Escolha um Plano de Fundo",
    "gui.extension.spriteLib": "Escolha um Sprite",
    "gui.gui.unselectedSerialport": "Nenhum Dispositivo Conectado",
    "gui.gui.unConnectedDev": "Nenhum Dispositivo Conectado",
    "gui.gui.pythonMode": "Modo Python",
    "gui.gui.burnFirmware": "Gravando Firmware Novamente",
    "gui.gui.burnFirmwareError": "Erro de Gravação de Firmware",
    "gui.gui.connected": "Conectado",
    "gui.gui.failedConnected": "Falha na Conexão",
    "gui.gui.connecting": "Conectando...",
    "gui.cards.all-how-tos": "Todos os Tutoriais",
    "gui.cards.how-tos": "Tutoriais",
    "gui.cards.remove": "Remover",
    "gui.recordStep.recordByClickBtn": "Comece a gravar clicando no botão abaixo",
    "gui.recordStep.permissionMicrophone": "Precisamos da sua permissão para usar seu microfone",
    "gui.recordStep.record": "Gravar",
    "gui.recordStep.stopRecord": "Parar de gravar",
    "gui.webglModal.descriptionMind": "Infelizmente, seu computador está {webGlLink}. Mind+ precisa do WebGL para funcionar, por favor, tente {restartMindplus}({clickToRestart}), se o problema persistir, {updateGpuDriver}",
    "gui.webglModal.restartMindplus": "Reiniciar Mind+",
    "gui.webglModal.clickToRestart": "Clique aqui para reiniciar",
    "gui.webglModal.updateGpuDriver": "Por favor, tente atualizar o driver da placa de vídeo",
    "gui.webglModal.ok": "Ok",
    "gui.extension.scratchExampleLib": "Biblioteca de Exemplos Scratch",
    "gui.extension.pythonExampleLib": "Biblioteca de Exemplos Python",
    "gui.extension.arduinoExampleLib": "Biblioteca de Exemplos Arduino",
    "gui.extension.makeBlock": "Criar um Bloco",
    "gui.customProcedures.textType": "texto",
    "gui.customProcedures.numberType": "número",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "Robô educacional Vortex da DFRobot",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "Robô educacional Explorer D1 da DFRobot",
    "gui.extension.arduinounoR3.name": "ArduinounoR3",
    "gui.extension.arduinounoR3.description": "Kit de placa principal personalizada Arduino Uno do Curso Maker de Wuhan.",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "Robô Max:bot baseado no micro:bit",
    "gui.extension.max.name": "MAX",
    "gui.extension.max.description": "Robô MAX(ROB0137)baseado em Arduino",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "Placa de expansão de motor:bit e servo",
    "gui.extension.maqueen.name": "Maqueen",
    "gui.extension.maqueen.description": "Robô micro:Maqueen baseado no micro:bit",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.calliope.description": "Seja criativo e comece a codificar!",
    "gui.extension.calliope.name": "CALLIOPE mini",
    "gui.extension.spread.name": "Placa de expansão micro:bit & mPython",
    "gui.extension.spreadboson.name": "micro:bit & mPython",
    "gui.extension.microTouch.name": "TouchKey",
    "gui.extension.microNaturalScience.name": "micro:naturalScience",
    "gui.extension.microTouch.description": "Placa de expansão integrada com teclado 4x4 e motor servo",
    "gui.extension.microNaturalScience.description": "Placa de expansão micro:bit com múltiplos sensores ambientais",
    "gui.extension.microNaturalScienceV2.description": "micro:IoT",
    "gui.extension.microIoT.name": "Placa de expansão integrada com display e Internet das Coisas para micro:bit",
    "gui.extension.microIoT.description": "micro:IoT Board for Cloud",
    "gui.extension.microIoTCloud.name": "A placa de expansão micro:bit suporta interconexão com cartão WiFi, proporcionando uma excelente solução para o ensino em sala de aula de Internet das Coisas",
    "gui.extension.microIoTCloud.description": "Maqueen extension",
    "gui.extension.spreadMaqueen.name": "Placa de expansão PinPong",
    "gui.extension.pinpongBread.name": "Placa de expansão para aprendizado de Python no ArduinoUNO",
    "gui.extension.pinpongBread.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spread.description": "Placa de expansão I/O com micro:bit e placa de controle (com driver de motor)",
    "gui.extension.spreadboson.description": "Carro de entrada baseado na versão de controle",
    "gui.extension.spreadmaqueen.description": "Maqueen Plus",
    "gui.extension.maqueenPlus.name": "O robô educacional avançado que suporta tanto micro:bit quanto mPython",
    "gui.extension.maqueenPlus.description": "Internet das Coisas",
    "gui.extension.iot.name": "Edição Estendida Baseada na Internet das Coisas",
    "gui.extension.iot.description": "Leonardo",
    "gui.extension.leonardo.name": "Dispositivo controlado por Leonardo",
    "gui.extension.leonardo.description": "Arduino Uno",
    "gui.extension.arduinouno.name": "Módulo da placa principal Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Nano",
    "gui.extension.arduinonano.name": "Dispositivo controlado por Arduino Nano",
    "gui.extension.arduinonano.description": "handpy",
    "gui.extension.mpython.name": "Placa de controle principal baseada em ESP32",
    "gui.extension.mpython.description": "RoboMaster TT(ESP32)",
    "gui.extension.telloesp32.name": "Drone educacional com algoritmos de controle de voo de alto nível da DJI, seguro e estável",
    "gui.extension.telloesp32.description": "Mega2560",
    "gui.extension.mega2560.name": "Dispositivo controlado por Mega2560",
    "gui.extension.mega2560.description": "FireBeetle ESP32",
    "gui.extension.firebeetleesp32.name": "FireBeetle ESP32-E",
    "gui.extension.firebeetleesp32e.name": "Dispositivo controlado por FireBeetle ESP32",
    "gui.extension.firebeetleesp32.description": "Módulo de Reprodução MP3",
    "gui.extension.dfr0299.name": "Módulo de Reprodutor MP3",
    "gui.extension.dfr0299.description": "Micro Servo",
    "gui.extension.ser0006.name": "Micro Servo",
    "gui.extension.ser0006.description": "Bomba Peristáltica",
    "gui.extension.dfr0523.name": "Entrega de fluido apertando e liberando a bomba alternadamente",
    "gui.extension.dfr0523.description": "Módulo de Relé",
    "gui.extension.dfr0017.name": "Controle de dispositivo de corrente alta",
    "gui.extension.dfr0017.description": "Motor de Passo",
    "gui.extension.steppermotor.name": "Realize o controle preciso de posição por controle de pulso",
    "gui.extension.steppermotor.description": "Módulo Serial MP3",
    "gui.extension.dfr0534.name": "Módulo de voz MP3 serial",
    "gui.extension.dfr0534.description": "Micro Servo 360°",
    "gui.extension.servo360.name": "Controle de velocidade e direção",
    "gui.extension.servo360.description": "Módulo OBLOQ IoT",
    "gui.extension.tel0118.name": "Um dispositivo Wi-Fi para Serial",
    "gui.extension.tel0118.description": "Módulo Transmissor IR",
    "gui.extension.dfr0095.name": "Transmite sinal infravermelho padrão de 38KHz",
    "gui.extension.dfr0095.description": "Módulo Receptor IR",
    "gui.extension.dfr0094.name": "Recebe sinal infravermelho padrão de 38KHz",
    "gui.extension.dfr0094.description": "MIDI",
    "gui.extension.midi.name": "Reprodutor MIDI",
    "gui.extension.midi.description": "Transmissão Sem Fio",
    "gui.extension.radio.name": "Comunicação de transmissão sem fio entre várias placas de controle",
    "gui.extension.radio.description": "Módulo Receptor de Sinal GPS",
    "gui.extension.tel0094.name": "Módulo receptor de sinal GPS",
    "gui.extension.tel0094.description": "Módulo Bluetooth",
    "gui.extension.tel0026.name": "Módulo serial Bluetooth",
    "gui.extension.tel0026.description": "Módulo Bluetooth Serial Port",
    "gui.extension.dfr0486.name": "Display OLED-12864",
    "gui.extension.dfr0647.name": "Display OLED-12832",
    "gui.extension.dfr0486.description": "Módulo de Display I2C OLED-12864",
    "gui.extension.dfr0647.description": "Módulo de Display I2C OLED-12832",
    "gui.extension.fit0352.name": "Fita de LED RGB WS2812",
    "gui.extension.fit0352.description": "Controle de módulos de fita de LED baseados em WS2812",
    "gui.extension.dfr0063.name": "Display LCD1602",
    "gui.extension.dfr0063.description": "Módulo LCD que pode mostrar 2 linhas e 16 caracteres em cada linha",
    "gui.extension.dfr0021.name": "Módulo de Luz LED Digital",
    "gui.extension.dfr0021.description": "Módulos de LED branco, vermelho, verde e azul",
    "gui.extension.tm1650.name": "Tubo de quatro dígitos TM1650",
    "gui.extension.tm1650.description": "Tubo de quatro dígitos",
    "gui.extension.matrix8_8.name": "Matriz de pontos 8x8 MAX7219",
    "gui.extension.matrix8_8.description": "Módulo de matriz de pontos 8x8",
    "gui.extension.dfr0522.name": "Painel de Matriz de LED RGB 8x16",
    "gui.extension.dfr0522.description": "Exibe imagens, números... suporta rolagem e configurações definidas pelo usuário",
    "gui.extension.video-sensing.name": "Sensor de Sensação de Vídeo",
    "gui.extension.video-sensing.description": "Sinta o movimento com a câmera.",
    "gui.extension.google-translate.name": "Tradução",
    "gui.extension.google-translate.description": "Traduza texto para muitos idiomas.",
    "gui.extension.bd-translate.name": "Baidu Translate",
    "gui.extension.bd-translate.description": "Use o serviço de tradução Baidu para traduzir texto para outros idiomas.",
    "gui.extension.text-speech.name": "Texto para Fala",
    "gui.extension.text-speech.description": "Faça seus projetos falarem",
    "gui.extension.softSerialport.name": "Porta Serial de Software",
    "gui.extension.softSerialport.description": "Use a porta de E/S para simular a função de comunicação serial",
    "gui.extension.IICScan.name": "Varredura I2C",
    "gui.extension.IICScan.description": "Varre todos os endereços de dispositivos conectados à interface I2C",
    "gui.extension.bluetooth.name": "Bluetooth",
    "gui.extension.bluetooth.description": "Dispositivo Bluetooth",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "O aplicativo GoBLE pode ajudar você a controlar a placa de controle principal via smartphone",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "Use para ler, gravar e salvar dados com EEPROM",
    "gui.extension.interrupt.name": "Interrupção",
    "gui.extension.interrupt.description": "Pino de Interrupção",
    "gui.extension.serialPort.description": "Realize convenientemente a função de envio de vários conjuntos de dados no modo de upload e receba dados através da porta serial no modo em tempo real",
    "gui.extension.mpyfile.name": "Arquivo",
    "gui.extension.mpyfile.description": "Leia e escreva arquivos no painel de controle, armazene dados e exporte",
    "gui.extension.sdcard.name": "Módulo Leitor de Cartão SD",
    "gui.extension.sdcard.description": "Módulo leitor de cartão MicroSD",
    "gui.extension.multithread.name": "MultiThread",
    "gui.extension.multithread.description": "Permite que vários programas e o programa principal sejam executados simultaneamente",
    "gui.extension.speech.name": "Fala",
    "gui.extension.speech.description": "Fale com seus projetos.",
    "gui.extension.sen0001.name": "Sensor Ultrassônico",
    "gui.extension.bos0001.name": "Sensor de Rotação",
    "gui.extension.bos0002.name": "Botão de Pressão",
    "gui.extension.bos0003.name": "Interruptor Autotravante",
    "gui.extension.bos0004.name": "Sensor de Luz",
    "gui.extension.bos0006.name": "Sensor de Vapor",
    "gui.extension.bos0007.name": "Sensor de Chama",
    "gui.extension.bos0008.name": "Sensor de Toque",
    "gui.extension.bos0009.name": "Sensor de Som",
    "gui.extension.bos0010.name": "Sensor de Proximidade Infravermelho",
    "gui.extension.bos0012.name": "Interruptor de Condutividade",
    "gui.extension.bos0013.name": "Sensor de Movimento",
    "gui.extension.bos0038.name": "Sensor de Umidade do Solo",
    "gui.extension.bos0045.name": "Sensor de Distância Ultrassônica",
    "gui.extension.bos0016.name": "LED Ultra Brilhante",
    "gui.extension.bos0017.name": "Módulo de LED",
    "gui.extension.bos0019.name": "Luzes de Corda de LED",
    "gui.extension.bos0021.name": "Módulo de Ventilador",
    "gui.extension.bos0022.name": "Módulo Gravador de Voz",
    "gui.extension.bos0001.description": "Sensor analógico, pode detectar a posição de rotação",
    "gui.extension.bos0002.description": "Pressione para gerar sinal alto, solte para gerar sinal baixo",
    "gui.extension.bos0003.description": "Sinal alto quando pressionado, sinal baixo quando liberado",
    "gui.extension.bos0004.description": "Detecção de intensidade de luz ambiente",
    "gui.extension.bos0006.description": "Detecta chuva e neblina, não submerso em água",
    "gui.extension.bos0007.description": "Detecta fonte de fogo ou luz com comprimento de onda de 760~1100nm",
    "gui.extension.bos0008.description": "Interruptor tátil, pode detectar corpo humano, metal, etc",
    "gui.extension.bos0009.description": "Detecta intensidade sonora",
    "gui.extension.bos0010.description": "Detecta sinais gerados por diferentes cores",
    "gui.extension.bos0012.description": "Verifica se o objeto é condutivo",
    "gui.extension.bos0013.description": "Detecta raios infravermelhos emitidos por pessoas ou animais em movimento",
    "gui.extension.bos0038.description": "Detecta umidade do solo, quanto menor a umidade, menor o valor de saída",
    "gui.extension.bos0045.description": "Sensor de distância analógico, faixa de detecção de 2cm a 1m",
    "gui.extension.bos0016.description": "Módulo emissor de luz branca",
    "gui.extension.bos0017.description": "Diodos emissores de luz podem emitir luz correspondente à cor das contas da lâmpada",
    "gui.extension.bos0019.description": "Fita de luz colorida, o comprimento é de 3 metros e a cor não pode ser alterada",
    "gui.extension.bos0021.description": "Módulo para acionar a rotação da lâmina do ventilador",
    "gui.extension.bos0022.description": "Pode gravar um som de 10 segundos e reproduzir a gravação",
    "gui.extension.sen0001.description": "Detecção precisa de distância com alcance de 2 a 800 cm, compatível com ultrassônico urm e SR04",
    "gui.extension.dfr0023.name": "Sensor de temperatura analógico LM35",
    "gui.extension.dfr0023.description": "Sensor de temperatura semicondutor baseado em LM35 com faixa de 0 a 100?",
    "gui.extension.dhtTHSensor.name": "Sensor de temperatura e umidade DHT11/22",
    "gui.extension.dhtTHSensor.description": "Detecta temperatura e umidade do ambiente",
    "gui.extension.dsTSensor.name": "Sensor de temperatura DS18B20",
    "gui.extension.dsTSensor.description": "Detecta temperatura ambiente com ampla faixa de -55 a +125?",
    "gui.extension.sen0203.name": "Sensor de monitoramento de frequência cardíaca",
    "gui.extension.sen0203.description": "Mini sensor de frequência cardíaca com saída analógica de pulso e onda quadrada digital",
    "gui.extension.sen0177.name": "Sensor de qualidade do ar a laser PM2.5",
    "gui.extension.sen0177.description": "Mede PM1, PM2.5 e PM10. Transferência de dados via porta serial",
    "gui.extension.sen0014.name": "Sensor de distância IR GP2Y0A21",
    "gui.extension.sen0014.description": "Sensor IR baseado em GP2Y0A21 com alcance de medição de 10 a 80 cm",
    "gui.extension.sen0204.name": "Sensor de nível de líquido sem contato",
    "gui.extension.sen0204.description": "Detecta o nível do líquido sem contato direto",
    "gui.extension.sen0160.name": "Sensor de peso Hx711",
    "gui.extension.sen0160.description": "Mede o peso com o sensor Hx711",
    "gui.extension.sen0161.name": "Medidor de pH analógico",
    "gui.extension.sen0161.description": "Mede o valor de pH do líquido com alimentação de 5V",
    "gui.extension.sen0161-v2.name": "Medidor de pH analógico (V2)",
    "gui.extension.sen0161-v2.description": "Mede o valor de pH do líquido com alimentação de 3,3 a 5,5V",
    "gui.extension.sen0244.name": "Sensor TDS analógico",
    "gui.extension.sen0244.description": "Mede TDS (Sólidos Totais Dissolvidos) de líquidos, indicando a limpeza da água",
    "gui.extension.sen0165.name": "Medidor ORP analógico",
    "gui.extension.sen0165.description": "Mede o ORP (Potencial de Oxidação-Redução) de líquidos para testar a qualidade da água",
    "gui.extension.sen0237.name": "Sensor de oxigênio dissolvido analógico",
    "gui.extension.sen0237.description": "Mede o DO (Oxigênio Dissolvido) de líquidos para avaliar a qualidade da água",
    "gui.extension.dfr0300-H.name": "Medidor EC analógico",
    "gui.extension.dfr0300-H.description": "Mede a CE (Condutividade Elétrica) de líquidos para avaliar a qualidade da água",
    "gui.extension.dfr0300.name": "Medidor EC analógico (V2)",
    "gui.extension.dfr0300.description": "Mede a CE (Condutividade Elétrica) de líquidos para avaliar a qualidade da água",
    "gui.extension.sen0170.name": "Anemômetro",
    "gui.extension.sen0170.description": "Mede a velocidade do vento e gera um sinal de saída de voltagem",
    "gui.extension.sen0226.name": "Sensor de temperatura BMP280",
    "gui.extension.sen0226.description": "Sensor de pressão barométrica BMP280 para medição de temperatura e pressão barométrica",
    "gui.extension.sen0228.name": "Sensor de luz ambiente I2C VEML7700",
    "gui.extension.sen0228.description": "Um sensor de luz ambiente digital de alta precisão com resolução de 16 bits",
    "gui.extension.sen0236.name": "Módulo BME280",
    "gui.extension.sen0236.description": "Sensores ambientais (temperatura, umidade, pressão do ar)",
    "gui.extension.sen0517.name": "Sensor de pressão ICP10111",
    "gui.extension.sen0517.description": "Sensor de pressão do ar de alta precisão e estabilidade com precisão de ±1Pa",
    "gui.extension.dfr0022.name": "Sensor de escala de cinza analógico",
    "gui.extension.dfr0022.description": "Detecta a densidade de luz e reflete um sinal analógico de voltagem, que pode ser aplicado ao rastreamento de linhas",
    "gui.extension.motucamera.name": "Placa de sensor MU",
    "gui.extension.motucamera.description": "Descrição para a extensão 'Placa de sensor MU'",
    "gui.extension.dfr0552.name": "Módulo de conversão DA de 12 bits",
    "gui.extension.dfr0552.description": "Converte a quantidade digital para o sinal de voltagem DC correspondente com precisão (não PWM)",
    "gui.extension.sen0117.name": "Módulo de síntese de voz",
    "gui.extension.sen0117.description": "Você só precisa digitar caracteres chineses e ingleses e números, e ele irá lê-los",
    "gui.extension.dfr0576.name": "Extensor de cascata I2C",
    "gui.extension.dfr0576.description": "Usado para resolver conflitos de endereço de dispositivo I2C",
    "gui.extension.sen0248.name": "Sensor ambiental BME680",
    "gui.extension.sen0248.description": "VOC (compostos orgânicos voláteis), temperatura, umidade e pressão do ar podem ser medidos",
    "gui.extension.sen0304.name": "Sensor ultrassônico I2C",
    "gui.extension.sen0304.description": "Sensores ultrassônicos projetados para medição rápida ou aplicações de evitar obstáculos",
    "gui.extension.sen0307.name": "Sensor ultrassônico analógico",
    "gui.extension.sen0307.description": "Módulo de sensor ultrassônico analógico de sonda dupla aberta para medição ultrassônica",
    "gui.extension.sen0250.name": "Sensor de movimento inercial de 6 eixos BMI160",
    "gui.extension.sen0250.description": "Acelerômetro de 16 bits em 3 eixos + giroscópio de 3 eixos com consumo ultra baixo de energia",
    "gui.extension.sen0224.name": "Acelerômetro triaxial I2C LIS2DH",
    "gui.extension.sen0224.description": "Sensor de aceleração de 3 eixos com consumo ultra baixo de energia",
    "gui.extension.dfr0151.name": "Relógio em tempo real DS1307",
    "gui.extension.dfr0151.description": "O erro de tempo é de apenas cerca de 1 segundo em 24 horas",
    "gui.extension.dfr0469.name": "Relógio em tempo real SD2405",
    "gui.extension.dfr0469.description": "Relógio de tempo real (RTC) preciso I2C com compensação de cristal, bateria interna recarregável.",
    "gui.extension.dfr0126.name": "Módulo de Análise de Espectro",
    "gui.extension.dfr0126.description": "Acompanhe a frequência sonora",
    "gui.extension.dfr0231.name": "Módulo NFC",
    "gui.extension.dfr0231.description": "Apropriado para comunicação sem fio de curta distância",
    "gui.extension.sen0245.name": "Sensor de Distância a Laser VL53L0X",
    "gui.extension.sen0245.description": "Usa tecnologia TOF para medir com precisão a distância, até 2 metros",
    "gui.extension.dfr0026.name": "Sensor de Luz Ambiente Analógico",
    "gui.extension.dfr0026.description": "Detecta a densidade de luz ambiente",
    "gui.extension.dfr0027.name": "Sensor Digital de Vibração",
    "gui.extension.dfr0027.description": "Detecta sinal de vibração e gera sinal digital de saída",
    "gui.extension.dfr0028.name": "Sensor Digital de Inclinação",
    "gui.extension.dfr0028.description": "Com base no número de interruptores de mercúrio, pode ser usado como um sensor de inclinação simples",
    "gui.extension.dfr0029.name": "Botão Digital de Pressão",
    "gui.extension.dfr0029.description": "Pressionar: nível alto Liberar: nível baixo",
    "gui.extension.dfr0030.name": "Sensor de Toque Capacitivo Digital",
    "gui.extension.dfr0030.description": "Módulo de interruptor de toque capacitivo para detectar metal e corpo humano",
    "gui.extension.sen0318.name": "Sensor de Qualidade do Ar CCS811",
    "gui.extension.sen0318.description": "CCS811 mede CO2, TVOC",
    "gui.extension.sen0315.name": "Sensor de Gestos PAJ7620U2",
    "gui.extension.sen0315.description": "Comunicação IIC, na faixa de até 20 cm, pode reconhecer até 13 gestos",
    "gui.extension.sen0497.name": "Sensor de Temperatura e Umidade",
    "gui.extension.sen0497.description": "Um novo sensor de temperatura e umidade para comunicação I2C, um produto aprimorado do DHT11",
    "gui.extension.sen0514.name": "Sensor de Qualidade do Ar ENS160",
    "gui.extension.sen0514.description": "Mede TVOC (Compostos Orgânicos Voláteis Totais), eCO2 (Dióxido de Carbono Relativo), IAQ (Índice de Qualidade do Ar)",
    "gui.extension.sen0518.name": "Sensor de Frequência Cardíaca e Oxigênio no Sangue MAX30102",
    "gui.extension.sen0518.description": "Mede a frequência cardíaca em repouso e a saturação de oxigênio no sangue",
    "gui.extension.dfr0991.name": "Módulo de Botão com RGB",
    "gui.extension.dfr0991.description": "Lê o estado do botão para controlar a cor e ligar/desligar a luz RGB",
    "gui.extension.sen0376.name": "Sensor de Álcool",
    "gui.extension.sen0376.description": "Calibração de fábrica, sem calibração necessária, 0-5 ppm",
    "gui.extension.sen0321.name": "Sensor de Ozônio",
    "gui.extension.sen0321.description": "Medição da concentração de ozônio no ambiente",
    "gui.extension.sen0364.name": "Sensor de Espectro Visível",
    "gui.extension.sen0364.description": "Mede o espectro visível e identifica a cor espectral",
    "gui.extension.sen0359.name": "Sensor de Impressão Digital Capacitivo",
    "gui.extension.sen0359.description": "Sensor de Impressão Digital Capacitivo",
    "gui.extension.kit0176.name": "Sensor de Peso I2C",
    "gui.extension.kit0176.description": "O peso do objeto pode ser medido, a faixa é de 1 kg",
    "gui.extension.dfr0033.name": "Sensor Digital de Magnetismo",
    "gui.extension.dfr0033.description": "Detecta materiais magnéticos dentro de 3 cm",
    "gui.extension.dfr0034.name": "Sensor de Som Analógico",
    "gui.extension.dfr0034.description": "Detecta a intensidade do som ambiente",
    "gui.extension.sen0132.name": "Sensor de Monóxido de Carbono Analógico",
    "gui.extension.sen0132.description": "Detecta concentrações de CO-gás entre 20 e 2000 ppm",
    "gui.extension.dfr0051.name": "Divisor de Tensão Analógico",
    "gui.extension.dfr0051.description": "Detecta tensão (DC) de 0,0245V a 25V",
    "gui.extension.dfr0052.name": "Sensor Analógico de Vibração de Disco Piezo",
    "gui.extension.dfr0052.description": "Para detectar estresse dinâmico e quase estático e gerar sinal analógico de saída",
    "gui.extension.dfr0058.name": "Sensor de Potenciômetro de Rotação Analógico",
    "gui.extension.dfr0058.description": "Potenciômetro de alta precisão com contagem máxima de rotação de 10",
    "gui.extension.dfr0061.name": "Módulo de Joystick",
    "gui.extension.dfr0061.description": "Saída analógica (X,Y), saída digital (Z) e pode ser usado como controle remoto",
    "gui.extension.dfr0072.name": "Sensor de Deslocamento",
    "gui.extension.dfr0072.description": "Descrição para a extensão 'Sensor de Deslocamento'",
    "gui.extension.dfr0563.name": "Sensor de Bateria",
    "gui.extension.dfr0563.description": "Descrição para a extensão 'Sensor de Bateria'",
    "gui.extension.dfr0553.name": "Módulo de Conversão AD de 16 bits",
    "gui.extension.dfr0553.description": "Descrição para a extensão 'Módulo de Conversão AD de 16 bits'",
    "gui.extension.dfr0588.name": "Sensor de Temperatura e Umidade",
    "gui.extension.dfr0588.description": "Sensor de Temperatura e Umidade",
    "gui.extension.dfr0076.name": "Sensor de Chama Analógico",
    "gui.extension.dfr0076.description": "Detecta chama ou luz com comprimento de onda de 760nm-1100nm",
    "gui.extension.dfr0117.name": "Sensor I2C de Memória EEPROM",
    "gui.extension.dfr0117.description": "Módulo de armazenamento de dados, 32 KB",
    "gui.extension.dfr0143.name": "Acelerômetro de Três Eixos MMA7361",
    "gui.extension.dfr0143.description": "Baseado no chip MMA7361, detecta gestos e direção de movimento",
    "gui.extension.sen0018.name": "Sensor Digital de Movimento Infravermelho",
    "gui.extension.sen0018.description": "Detecta infravermelho liberado por seres humanos ou animais em movimento",
    "gui.extension.sen0114.name": "Sensor Analógico de Umidade do Solo",
    "gui.extension.sen0114.description": "Lê a quantidade de umidade presente no solo ao redor",
    "gui.extension.sen0121.name": "Sensor de Vapor",
    "gui.extension.sen0121.description": "Detecta água da chuva, vapor e névoa d'água",
    "gui.extension.sen0212.name": "Sensor de Identificação de Cor",
    "gui.extension.sen0212.description": "Identifica as cores dos objetos e gera o valor RGB de saída",
    "gui.extension.sen0253.name": "Sensor de Atitude de 10DOF",
    "gui.extension.sen0253.description": "O BBNO055 é um novo CI de sensor para implementar um sensor de orientação absoluta inteligente de 9 eixos.",
    "gui.extension.sen0290.name": "Sensor de Raios",
    "gui.extension.sen0290.description": "Sensor de raios inteligente",
    "gui.extension.sen0291.name": "Medidor de Energia Digital I2C",
    "gui.extension.huskylens.name": "Câmera de IA HUSKYLENS",
    "gui.extension.huskylens.description": "Sensor de visão de inteligência artificial que suporta reconhecimento facial e aprendizado",
    "gui.extension.ps2.name": "Controle PS2",
    "gui.extension.ps2.description": "Módulo receptor de controle universal PS2",
    "gui.extension.sen0291.description": "Medição de voltagem, corrente e potência de alta precisão",
    "gui.extension.sen0202.name": "Sensor de Gestos 3D Mini",
    "gui.extension.sen0202.description": "Detecta rotação no sentido horário e anti-horário e direções de movimento",
    "gui.extension.iobox.name": "Micro:IO-BOX",
    "gui.extension.iobox.description": "Placa de expansão de motor de bateria de lítio dedicada ao Micro:bit",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "Permite que o dispositivo se conecte à rede Wi-Fi",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "Permite que o dispositivo obtenha a hora local",
    "gui.extension.udp.name": "Transmissão UDP",
    "gui.extension.udp.description": "Permite que dispositivos na rede local realizem comunicação de transmissão multi-máquina através do protocolo UDP",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "Permite que o dispositivo solicite informações via HTTP",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "Permite que dispositivos se comuniquem usando o protocolo MQTT",
    "gui.extension.internet.name": "Internet",
    "gui.extension.internet.description": "Comunicação pela Internet",
    "gui.extension.speechRecognition.name": "Reconhecimento de Fala",
    "gui.extension.speechRecognition.description": "Reconhecimento de Fala",
    "gui.extension.pictureai.defaultTitleText": "Janela de Vídeo",
    "gui.extension.pictureai.cameraPermissionsTitle": "Necessita de permissão de câmera",
    "gui.extension.pictureai.cameraPermissionsMessage": "Por favor, vá para as configurações e marque a autorização. Se já autorizado, mas ainda não puder usar, verifique se a câmera está conectada corretamente e sem outros softwares a utilizando, ou reinicie o software.",
    "gui.extension.pictureai.cameraPermissionsCancel": "Cancelar",
    "gui.extension.pictureai.cameraPermissionsGoToSettings": "Ir para Configurações",
    "gui.gui.loaded": "Carregado",
    "gui.gui.notLoaded": "Não Carregado",
    "gui.gui.cantFindYouWant": "Não consegue encontrar o que procura?",
    "gui.gui.clickHere": "Clique aqui",
    "gui.gui.viewHelp": "para encontrar mais",
    "gui.gui.uploadToDev": "Enviar",
    "gui.gui.codeArea": "Código",
    "gui.gui.fileSys": "Sistema de arquivos",
    "gui.gui.compileAndUpload": "Compilar e carregar",
    "gui.gui.compileOnly": "Apenas Compilar",
    "gui.gui.compileOpen": "Compilar e Abrir",
    "gui.gui.burnBootloader": "Queimar bootloader sem fio",
    "gui.gui.arduinoRun": "Executar",
    "gui.gui.arduinoSave": "Salvar",
    "gui.gui.autoGenerated": "Gerar Automaticamente",
    "gui.gui.manualEditing": "Edição Manual",
    "gui.gui.codeBox": "Caixa de Código",
    "gui.gui.moduleCode": "Código do Módulo",
    "gui.menu.edit.undo": "Desfazer",
    "gui.menu.edit.redo": "Refazer",
    "gui.menu.edit.selectAll": "Selecionar Tudo",
    "gui.menu.edit.cut": "Recortar",
    "gui.menu.edit.copy": "Copiar",
    "gui.menu.edit.paste": "Colar",
    "gui.menu.edit.codeStyle": "Estilo de Código",
    "gui.menu.edit.fontSize": "Tamanho da Fonte",
    "gui.menu.edit.clearCache": "Limpar Cache",
    "gui.menu.edit.maxReset": "Restaurar Max para as configurações de fábrica",
    "gui.gui.serial": "Serial",
    "gui.gui.openSerial": "Abrir Serial",
    "gui.gui.closeSerial": "Fechar Serial",
    "gui.gui.close": "Fechar",
    "gui.gui.open": "Abrir",
    "gui.gui.clearOutput": "Limpar Saída",
    "gui.gui.scroll": "Rolar",
    "gui.gui.send": "Enviar",
    "gui.gui.baud": "Baud Rate",
    "gui.gui.noLineEnd": "Nenhum Terminador de Linha",
    "gui.gui.newLine": "Alimentação de Linha",
    "gui.gui.carriageReturn": "Retorno de Carro",
    "gui.gui.bothNLandCR": "LF & CR",
    "gui.gui.unavailableHexData": "Formato hexadecimal inválido! Represente os dados hexadecimais com dois caracteres (0-9/A-F) e um espaço em branco, por exemplo, \"00 01 A2 FF\".",
    "gui.gui.hexForm": "Hex Formulário",
    "gui.gui.openHexForm": "Use o formato hexadecimal para mostrar e enviar dados",
    "gui.gui.closeHexForm": "Fechar",
    "gui.progress.burning": "Gravando",
    "gui.progress.burning1": "Gravando",
    "gui.progress.compiling": "Compilando",
    "gui.burner.compileError": "Erro de compilação",
    "gui.burner.linkError": "Erro de ligação",
    "gui.burner.generateHexError": "Erro ao gerar .hex",
    "gui.burner.generateBinError": "Erro ao gerar .bin",
    "gui.burner.burnError": "Erro de gravação",
    "gui.burner.eraseError": "Erro de apagamento",
    "gui.burner.findLeonardoPortError": "Não é possível encontrar a porta do bootloader do Leonardo",
    "gui.burner.noMicrobit": "Desculpe, não é o micro: bit, por favor, conecte o micro: bit",
    "gui.burner.swdCfgError": "Não é possível gravar em cmsis-dap.cfg",
    "gui.burner.UDiskPopUp": "UDisk aparece, por favor, espere um pouco e tente novamente",
    "gui.burner.UDiskWriteError": "Erro ao escrever no UDisk",
    "gui.burner.openPortError": "Não foi possível abrir a porta: Acesso negado",
    "gui.burner.firmwareError": "Erro de firmware, por favor, restaure e reconecte",
    "gui.burner.uploadDone": "Upload concluído",
    "gui.burner.connectPort": "Conectando",
    "gui.burner.burnFirmware": "Gravar firmware",
    "gui.burner.writeLibFiles": "Gravando arquivos de biblioteca",
    "gui.burner.connectError": "Erro de conexão, por favor, reconecte. Consulte o documento de ajuda para soluções",
    "gui.burner.connectPico": "Firmware Pico baixado com sucesso, por favor, re-selecione a conexão da porta serial",
    "gui.burner.printObjectTooBig": "O projeto é muito grande; por favor, clique em \"ajuda\" no canto superior direito para reduzir seu tamanho.\r\r\nErro de compilação para a placa [%BOARD].",
    "gui.burner.printFlashTooBig": "Memória insuficiente; por favor, clique em \"ajuda\" no canto superior direito para reduzir a ocupação de memória.\r\r\nErro de compilação para a placa [%BOARD].",
    "gui.burner.printFlashTooLow": "Pouca memória disponível, podem ocorrer problemas de estabilidade.",
    "gui.burner.printSizeError": "Não foi possível determinar o tamanho do programa.",
    "gui.burner.printSize": "O projeto usa [%TEXTSIZE] bytes, ocupa ([%TEXTSIZEPRE]) espaço de memória do programa, deixando [%TEXTSIZESUR] bytes, até [%TEXTSIZEMAX] bytes.\r\r\nAs variáveis globais usam [%DATASIZE] bytes, ([%DATASIZEPRE]) de memória dinâmica, deixando [%DATASIZESUR] bytes para variáveis locais, até [%DATASIZEMAX] bytes.",
    "gui.progress.compileSuccess": "compilação bem-sucedida",
    "gui.progress.compileProgress": "Progresso da Compilação",
    "gui.progress.uploadProgress": "Progresso do Upload",
    "gui.progress.uploadSuccess": "upload bem-sucedido",
    "gui.progress.uploadSuccessVm": "upload bem-sucedido",
    "gui.progress.networkInterfaceCard": "NIC",
    "gui.codeLabel.globalCode": "Variáveis Globais",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "Nenhuma variável permitida!",
    "gui.progress.errMsg": "!Erro de Upload, por favor, verifique os detalhes na janela preta na parte inferior direita",
    "gui.progress.errMsg1": "!Erro de Upload, por favor, verifique os detalhes na janela preta na parte inferior direita",
    "gui.progress.errMsg2": "!Erro de Upload, por favor, verifique os detalhes na janela preta na parte inferior direita",
    "gui.howtos.spin-around.name": "Girar em Torno",
    "gui.howtos.spin.step_dragTurn": "Arraste um bloco [turn]",
    "gui.howtos.spin.step_clickTurn": "Clique no bloco para executar",
    "gui.howtos.spin.step_clickControl": "Clique na categoria [Control]",
    "gui.howtos.spin.step_dragForever": "Arraste um bloco [forever]",
    "gui.howtos.spin.step_clickForever": "Clique no bloco para executar",
    "gui.howtos.spin.step_changeColor": "Adicione um bloco [change color effect]",
    "gui.howtos.say-hello.name": "Dizer Olá",
    "gui.howtos.say-hello.step_addSprite": "Adicione um novo sprite",
    "gui.howtos.say-hello.step_clickLooks": "Clique na categoria [Looks]",
    "gui.howtos.say-hello.step_dragSay": "Arraste um bloco [say]",
    "gui.howtos.say-hello.step_clickSay": "Clique no bloco para executar",
    "gui.howtos.say-hello.step_anotherSay": "Arraste outro bloco [say]",
    "gui.howtos.say-hello.step_editSay": "Dizer algo mais",
    "gui.howtos.say-hello.step_clickStack": "Clique no bloco para executar",
    "gui.howtos.run-away.name": "Fugir",
    "gui.howtos.run-away.step_dragGoTo": "Arraste um bloco [go to random position]",
    "gui.howtos.run-away.step_clickGoTo": "Clique no bloco para executar",
    "gui.howtos.run-away.step3": "Clique na categoria [Events]",
    "gui.howtos.run-away.step_addWhenClicked": "Adicione um bloco “quando este sprite for clicado”",
    "gui.howtos.run-away.step_clickSprite": "Clique no sprite para executar",
    "gui.howtos.run-away.step_addSound": "Adicione um bloco “iniciar som”",
    "gui.howtos.pick-up-apple.name": "Jogo de Pegar Maçãs",
    "gui.howtos.pick-up-apple.step_1": "Mude para o Scratch",
    "gui.howtos.pick-up-apple.step_2": "Mude o hardware",
    "gui.howtos.pick-up-apple.step_3": "Conectar dispositivo",
    "gui.howtos.pick-up-apple.step_4": "Calibração de bússola",
    "gui.howtos.pick-up-apple.step_5": "Abra programas de exemplo do Scratch",
    "gui.howtos.pick-up-apple.step_6": "Jogo de Pegar Maçãs",
    "gui.howtos.move-left-right.name": "micro:bit e Meow",
    "gui.howtos.move-left-right.step_1": "Crie um novo projeto",
    "gui.howtos.move-left-right.step_2": "Arraste o bloco1",
    "gui.howtos.move-left-right.step_3": "Arraste o bloco2",
    "gui.howtos.move-left-right.step_4": "Mova para a esquerda e para a direita",
    "gui.howtos.install-driver.name": "Instalar Driver",
    "gui.howtos.transform-expression.name": "Sorria ou Chore",
    "gui.howtos.transform-expression.step_1": "Mude para o Código",
    "gui.howtos.transform-expression.step_2": "Mude o hardware",
    "gui.howtos.transform-expression.step_3": "Arraste o bloco1",
    "gui.howtos.transform-expression.step_4": "Arraste o bloco2",
    "gui.howtos.transform-expression.step_5": "Arraste o bloco3",
    "gui.howtos.transform-expression.step_6": "Transforme em sorriso ou choro",
    "gui.howtos.lot.name": "IOT (Internet das Coisas)",
    "gui.howtos.lot.step_1": "Selecione programas de exemplo",
    "gui.howtos.lot.step_2": "Gravando programa",
    "gui.howtos.lot.step_3": "Diagrama de recurso",
    "gui.howtos.touch-pin.name": "Toque de pinos",
    "gui.howtos.touch-pin.step_1": "Mude para o Código",
    "gui.howtos.touch-pin.step_2": "Selecione a rotina",
    "gui.howtos.touch-pin.step_3": "Gravando programa",
    "gui.howtos.touch-pin.step_4": "Toque de pinos",
    "gui.howtos.save-witch.name": "Bruxa de Resgate",
    "gui.howtos.save-witch.step_1": "Mude para o Scratch",
    "gui.howtos.save-witch.step_2": "Selecione 'micro:bit' na expansão",
    "gui.howtos.save-witch.step_3": "Selecione 'Rescue Witch' nos programas de exemplo",
    "gui.howtos.save-witch.step_4": "Conecte o dispositivo, selecione micro:bit e aguarde uma conexão bem-sucedida",
    "gui.howtos.save-witch.step_5": "Gire o micro:bit ao redor até que o LED se apague quando a nota [calibrar o dispositivo] aparecer.",
    "gui.howtos.save-witch.step_6": "Clique na bandeira verde e balance o braço para interagir com a animação",
    "gui.howtos.microbit-touch.name": "Pressione para sorrir ou chorar",
    "gui.howtos.microbit-touch.step_1": "Mude para o modo de código",
    "gui.howtos.microbit-touch.step_2": "Clique em 'Aprendizado' para abrir os programas de exemplo e selecione 'Toque de pino'",
    "gui.howtos.microbit-touch.step_3": "Conecte a porta COM correspondente do dispositivo e clique em 'Enviar para o Dispositivo'",
    "gui.howtos.microbit-touch.step_4": "Pressione teclas diferentes para mostrar diferentes expressões",
    "gui.howtos.microbit-calibration.name": "Calibração do micro:bit",
    "gui.howtos.calliope-calibration.name": "Calibração do calliope",
    "gui.howtos.microbit-calibration.step_1": "Gire a placa do micro:bit i, e os LEDs onboard acenderão sequencialmente.",
    "gui.howtos.microbit-calibration.step_2": "Até que todos os LEDs externos estejam acesos (como mostrado abaixo), um sorriso será exibido após a calibração ser concluída.",
    "gui.extension.sen0251.name": "BMP388 Sensor de Pressão Barométrica",
    "gui.extension.sen0251.description": "Função de detecção de temperatura, pressão atmosférica, altitude",
    "gui.extension.sen0206.name": "Sensor de Temperatura Infravermelho Não Contato",
    "gui.extension.sen0206.description": "Medição infravermelha não contatada de temperatura de objeto ou ambiente",
    "gui.gui.recentlyEdited": "Recentemente Editado",
    "gui.extension.weather.name": "Obter o Clima",
    "gui.extension.weather.description": "Aqui é necessário um módulo Wi-Fi para obter informações meteorológicas",
    "gui.extension.weather.description.javascript": "Para obter informações meteorológicas pela Internet, o computador precisa estar conectado à Internet",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "Operar o banco de dados de rede TinyWebDB, pode ser usado com o APP Inventor",
    "gui.extension.pictureai.name": "Reconhecimento de Imagens de IA",
    "gui.extension.pictureai.description": "faça algo maravilhoso com IA de imagem, precisa estar conectado à Internet",
    "gui.monitor.contextMenu.sliderRange": "mudar a faixa do controle deslizante",
    "gui.monitor.listMonitor.listLength": "comprimento {length}",
    "gui.monitor.listMonitor.empty": "(vazio)",
    "gui.costumeTab.backdrop": "Pano de fundo",
    "gui.costumeTab.costume": "Fantasia",
    "gui.loadProject.error": "erro ao carregar o projeto",
    "gui.fileSystem.fileCatalogue": "Catálogo",
    "gui.fileSystem.boardFiles": "Arquivos da Placa",
    "gui.fileSystem.boardFilesPico": "Arquivos Pico",
    "gui.fileSystem.boardFiles2": "Arquivos da Placa",
    "gui.fileSystem.boardFilesMaixduino": "Arquivos da Placa",
    "gui.fileSystem.boardFilesBoson": "BOSON MC1",
    "gui.fileSystem.computerFiles": "Arquivos do Computador",
    "gui.fileSystem.userExFiles": "Arquivos de Uso",
    "gui.fileSystem.newFile": "novo arquivo",
    "gui.fileSystem.newFolder": "nova pasta",
    "gui.fileSystem.deleteFolder": "excluir pasta",
    "gui.fileSystem.deleteFile": "excluir",
    "gui.fileSystem.rename": "renomear",
    "gui.fileSystem.openFolder": "abrir posição da pasta",
    "gui.fileSystem.openFilePos": "abrir posição do arquivo",
    "gui.fileSystem.openInEditor": "abrir no editor",
    "gui.fileSystem.runRightNow": "executar agora",
    "gui.fileSystem.stopRun": "parar execução",
    "gui.fileSystem.setBoot": "execução de inicialização",
    "gui.fileSystem.deleteForever": "excluir para sempre",
    "gui.fileSystem.saveAs": "salvar como",
    "gui.fileSystem.undoAllEdits": "cancelar todas as edições",
    "gui.fileSystem.copyToComputer": "copiar para o computador",
    "gui.fileSystem.uploadToBoard": "enviar para a placa",
    "gui.fileSystem.uploadAndRun": "enviar e executar",
    "gui.wifi.pwdNotLegal": "! Erro: A senha está no formato errado. A senha Wi-Fi deve ter 8-20 dígitos ou números",
    "gui.spreadmaqueen.perror": "! Erro: A lâmpada de LED do pino P12 não está disponível. Por favor, selecione a lâmpada de LED do pino P8.",
    "gui.gui.viewConflict": "visualizar conflito",
    "gui.gui.clickViewHelp": "Como resolver?",
    "gui.gui.conflict": "Conflito",
    "gui.gui.conflictPrompt": "Aviso de conflito",
    "gui.gui.clickPrompt": "Clique para destacar blocos, clique duas vezes para localizar blocos",
    "gui.extension.mpyUART.name": "UART Serial",
    "gui.extension.mpyUART.description": "Comunicação UART",
    "gui.extension.mpyI2C.name": "Comunicação I2C",
    "gui.extension.mpyI2C.description": "Use software para simular o protocolo I2C para transmitir dados",
    "gui.extension.mpyServo.name": "Módulo Servo",
    "gui.extension.mpyServo.description": "Módulo Servo",
    "gui.extension.mpyTimer.name": "Temporizador",
    "gui.extension.mpyTimer.description": "Pode executar o programa regularmente",
    "gui.extension.mpyList.name": "Lista",
    "gui.extension.mpyList.description": "Lista",
    "gui.extension.mpyDictionary.name": "Dicionário",
    "gui.extension.mpyDictionary.description": "Dicionário",
    "gui.extension.mpySet.name": "Conjunto",
    "gui.extension.mpySet.description": "Conjunto",
    "gui.extension.mpyTuple.name": "Tupla",
    "gui.extension.mpyTuple.description": "Tupla",
    "gui.connection.auto-scanning.noPeripheralsFound": "Nenhum dispositivo encontrado",
    "gui.connection.auto-scanning.prescan": "Tenha seu dispositivo por perto, então comece a procurar.",
    "gui.connection.auto-scanning.pressbutton": "Pressione o botão no seu dispositivo.",
    "gui.connection.auto-scanning.start-search": "Comece a procurar",
    "gui.connection.connecting-searchbutton": "Procurando...",
    "gui.connection.auto-scanning.try-again": "Tente novamente",
    "gui.connection.connected": "Conectado",
    "gui.connection.disconnect": "Desconectar",
    "gui.connection.go-to-editor": "Ir para o Editor",
    "gui.connection.connecting-cancelbutton": "Conectando...",
    "gui.connection.error.errorMessage": "Ops, parece que algo deu errado.",
    "gui.connection.error.tryagainbutton": "Tente novamente",
    "gui.connection.error.helpbutton": "Ajuda",
    "gui.connection.peripheral-name-label": "Nome do dispositivo",
    "gui.connection.connect": "Conectar",
    "gui.connection.scanning.lookingforperipherals": "Procurando dispositivos",
    "gui.connection.scanning.noPeripheralsFound": "Nenhum dispositivo encontrado",
    "gui.connection.scanning.instructions": "Selecione seu dispositivo na lista acima.",
    "gui.connection.scanning.instructionsForTello": "Conecte o WiFi do seu computador ao Tello ou RMTT primeiro e, em seguida, selecione os dispositivos listados acima.",
    "gui.connection.connect.instructionsForTello": "Conecte o Tello ou RMTT na lista de WiFi do computador primeiro e, em seguida, selecione os dispositivos listados acima.",
    "gui.connection.scanning.connectTelloHandle": "Selecione o dispositivo listado acima ou conecte manualmente ao Wi-Fi da aeronave.",
    "gui.connection.search": "Atualizar",
    "gui.connection.unavailable.installscratchlink": "Certifique-se de que o Scratch Link está instalado e em execução",
    "gui.connection.unavailable.enablebluetooth": "Verifique se o Bluetooth está ativado",
    "gui.connection.unavailable.tryagainbutton": "Tente novamente",
    "gui.connection.unavailable.helpbutton": "Ajuda",
    "gui.extension.ev3.description": "Construa robôs interativos e muito mais.",
    "gui.extension.ev3.connectingMessage": "Conectando. Certifique-se de que o pino no seu EV3 está configurado para 1234.",
    "gui.extension.boost.description": "Dê vida a criações robóticas.",
    "gui.extension.boost.connectingMessage": "Conectando",
    "gui.extension.wedo2.description": "Construa com motores e sensores.",
    "gui.extension.wedo2.connectingMessage": "Conectando",
    "gui.extension.huskyLensPro.name": "HUSKYLENS EDU",
    "gui.extension.huskyLensPro.description": "Use o HUSKYLENS para se conectar diretamente a um computador para aprender o básico de IA",
    "gui.extension.huskyLensPro.connectingMessage": "huskyLens está tentando solicitar uma conexão, por favor, espere",
    "gui.extension.machinelearning.name": "aprendizado de máquina (ML5)",
    "gui.extension.machinelearning.description": "baseado em algoritmo de aprendizado de máquina, a aplicação de inteligência artificial pode ser realizada sem rede",
    "gui.extension.huskylens.pcsetting": "Configurar câmera do computador",
    "gui.extension.huskylens.visualmodal": "Ferramentas de visualização de IA",
    "gui.extension.huskylens.KNNText": "Classificação KNN de simulação de câmera do lado do computador",
    "gui.extension.huskylens.huskylensbasic": "Funções Básicas",
    "gui.extension.huskylens.algorithmOperationLabel": "Operação Algorítmica",
    "gui.extension.huskylens.displayFunctionLabel": "Exibição",
    "gui.extension.huskylens.cameraFunctionLabel": "Tirar Fotos",
    "gui.extension.huskylens.modelOperationLabel": "Operação do Modelo",
    "gui.huskyLensPro.toConnect": "Por favor, conecte primeiro o HUSKYLENS EDU",
    "gui.huskyLensPro.toClassificationData": "Por favor, adicione dados de classificação primeiro",
    "gui.huskyLensPro.startDeployment": "Iniciar implantação",
    "gui.huskyLensPro.switchingAlgorithmFailed": "Não é possível alternar para o algoritmo \"classificação de objeto\"!",
    "gui.huskyLensPro.forgettingFailure": "Os dados de aprendizado não podem ser esquecidos!",
    "gui.huskyLensPro.failedToSetCategoryLabel": "Falha ao definir \"rótulo de categoria\"!",
    "gui.huskyLensPro.requestFailed": "Falha na solicitação de \"Implantação\"!",
    "gui.huskyLensPro.deploymentFailed": "Falha na \"Implantação de Dados\"!",
    "gui.huskyLensPro.completed": "Concluído",
    "gui.huskyLensPro.countdown": ", esperado para ser concluído em [TIME]",
    "gui.huskyLensPro.minutes": "minutos",
    "gui.huskyLensPro.seconds": "segundos",
    "gui.huskyLensPro.deploying": "O modelo está sendo implantado",
    "gui.huskyLensPro.disconnected": "A conexão foi desconectada e a implantação foi encerrada. Por favor, verifique a conexão USB!",
    "gui.huskyLensPro.huskylenMaker": "Essa funcionalidade está disponível apenas na versão educacional",
    "gui.huskyLensPro.updateVersion": "Por favor, conecte-se à versão educacional e a versão do firmware não seja inferior a 0.5.1, veja \"Ajuda\" para mais detalhes",
    "gui.huskyLensPro.failedRetry": "Falhou, por favor, tente novamente!",
    "gui.connection.auto-scanning.notFoundHuskylensTip": "Tempo de conexão esgotado, por favor, confirme que a máquina está conectada ao HuskyLens",
    "gui.extension.tello.name": "RoboMaster TT (individual)",
    "gui.extension.tello.description": "Drone educacional com algoritmos de controle de voo DJI de ponta, seguro e estável",
    "gui.extension.tello.connectingMessage": "Conectando... Certifique-se de que o Tello está ligado.",
    "gui.extension.telloGroup.name": "RoboMaster TT (time)",
    "gui.extension.telloGroup.description": "Drone educacional com algoritmos de controle de voo DJI de ponta, seguro e estável",
    "gui.connection.auto-scanning.notFoundTelloTip": "Tempo de varredura esgotado, por favor, certifique-se de que o Tello está ligado",
    "gui.extension.AIVisualInterface.lable": "Interface Visual de IA",
    "gui.extension.AIVisualInterface.title": "Sem Dados",
    "gui.extension.AIVisualInterface.imgCount": "[COUNT] fotos",
    "gui.extension.AIVisualInterface.delete": "excluir",
    "gui.extension.AIVisualInterface.classificationCount": "[COUNT] categorias no total",
    "gui.extension.AIVisualInterface.recognitionResult": "O resultado do reconhecimento de tela é:",
    "gui.extension.AIVisualInterface.possibility": "As possibilidades são:",
    "gui.extension.AIVisualInterface.addClass": "Adicionar classificação",
    "gui.extension.AIVisualInterface.startTraining": "Iniciar treinamento",
    "gui.extension.AIVisualInterface.prediction": "Prever fotos de vídeo",
    "gui.extension.AIVisualInterface.retrain": "Retreinar",
    "gui.extension.AIVisualInterface.export": "Exportar modelo",
    "gui.dialog.baiduServer.togglePersonalAccount": "O número atual de vocais disponíveis atingiu o limite máximo, mude para sua conta pessoal e faça login no console para verificar a cota",
    "gui.extension.ai.name": "IA",
    "gui.extension.AI.description": "Suporte a chamada e treinamento de modelo",
    "gui.extension.machineVision.name": "Visão de Máquina",
    "gui.extension.machineVision.description": "Processamento de imagem, desenho, filtragem, reconhecimento de forma, cor, código de dimensão, característica",
    "gui.extension.machineHearing.name": "Escuta de Máquina",
    "gui.extension.machineHearing.description": "Reconhecimento de fala por meio de treinamento",
    "gui.extension.maixduino.name": "Maixduino",
    "gui.extension.maixduino.description": "Placa de controle principal baseada no chip K210",
    "gui.extension.boson.name": "Boson MC1",
    "gui.extension.boson.description": "Placa de controle principal baseada no BOSON",
    "gui.extension.pico.name": "Raspberry Pi Pico",
    "gui.extension.pico.description": "Placa de controle principal Raspberry Pi Pico",
    "gui.extension.pyTurtle.name": "Desenho de Tartaruga",
    "gui.extension.pyTurtle.description": "Existem milhares de ideias criativas, entre os tamanhos",
    "gui.extension.pyGenerator.name": "Gerador de Código",
    "gui.extension.pyGenerator.description": "Escreva código diretamente em blocos de imagem para alcançar uma mistura gráfica e de código",
    "gui.extension.unihiker.name": "UNIHIKER",
    "gui.extension.unihiker.description": "Placa principal de nova geração para aprendizado completo de Python",
    "gui.extension.python.save": "Salvar",
    "gui.extension.python.execute": "Executar",
    "gui.extension.python.pipmanger": "Gerenciamento de Biblioteca",
    "gui.extension.python.filesystem": "Sistema de Arquivos",
    "gui.extension.python.fileinprogram": "Arquivo no projeto",
    "gui.extension.python.fileinpc": "Arquivo no computador",
    "gui.extension.python.terminal": "Terminal",
    "gui.extension.python.clearterminal": "Limpar saída",
    "gui.extension.python.tabcode": "Código",
    "gui.extension.python.piplist": "Lista de Bibliotecas",
    "gui.extension.python.commendatory": "Biblioteca recomendada",
    "gui.extension.python.handleinstall": "Modo PIP",
    "gui.extension.python.selectall": "Selecionar todos [COUNT]",
    "gui.extension.python.update": "Atualizar",
    "gui.extension.python.uninstall": "Desinstalar",
    "gui.extension.python.install": "Instalar",
    "gui.extension.python.installing": "Instalando...",
    "gui.extension.python.installed": "Instalado",
    "gui.extension.python.piphandleinstall": "Instalação manual do PIP",
    "gui.extension.python.loadmore": "Carregar mais...",
    "gui.extension.python.hidemore": "Ocultar mais...",
    "gui.extension.python.noavailable": "Não há biblioteca disponível, por favor, baixe primeiro",
    "gui.extension.python.enterCommand": "Por favor, insira o nome da biblioteca primeiro",
    "gui.extension.python.finishedtips": "O comando terminou de ser executado",
    "gui.extension.python.stop": "Parar",
    "gui.extension.python.executing": "Executando",
    "gui.extension.python.editorArea": "Área de Código",
    "gui.extension.python.toolbox": "Área Gráfica",
    "gui.extension.python.autoCreate": "Auto Gerar",
    "gui.extension.python.emptyEditor": "Clique no <b>Botão Novo Arquivo</b> atrás de <b>Arquivos no Projeto</b> à direita para criar um arquivo .py que pertence a você",
    "gui.extension.python.userDocument": "Documento de Ajuda",
    "gui.extension.xiamiboard.name": "Placa de Expansão Xia Mi",
    "gui.extension.xiamiboard.description": "Placa de expansão multifuncional baseada em micro: bit para educação em programação",
    "gui.gui.saveSb3ToCloud": "Salvar na nuvem",
    "gui.extension.pyMqtt.name": "MQTT-py",
    "gui.extension.pyMqtt.description": "Comunicação via MQTT pela Internet do PC",
    "gui.extension.pyGame.name": "Pygame",
    "gui.extension.pyGame.description": "Crie seus próprios jogos e animações com Python",
    "gui.extension.pinpongBase.name": "inicializar pinpong",
    "gui.extension.pinpongBase.description": "inicializar biblioteca pinpong selecionar função da placa e GPIO",
    "gui.extension.pinpongServo.name": "Micro Servo",
    "gui.extension.pinpongServo.description": "Pode ser girado de 0 a 180 graus para um ângulo específico",
    "gui.extension.pinpongIrq.name": "Interrupção",
    "gui.extension.pinpongIrq.description": "Pino de interrupção pinpong",
    "gui.extension.pinpongTone.name": "buzzer",
    "gui.extension.pinpongTone.description": "Módulo de buzina pinpong",
    "gui.extension.pinpongSr04_urm10.name": "Sensor Ultrassônico",
    "gui.extension.pinpongSr04_urm10.description": "Detecção precisa de distância com alcance de 2 a 800 cm, compatível com ultrassônico urm e SR04",
    "gui.extension.pinpongDht.name": "Sensor de Temperatura e Umidade DHT11/22",
    "gui.extension.pinpongDht.description": "Detecta temperatura e umidade do ambiente",
    "gui.extension.pinpongNeopixel.name": "Tira de LED RGB WS2812",
    "gui.extension.pinpongNeopixel.description": "Controla mudanças na fita LED para produzir efeitos como piscar, fluir, pular, etc.",
    "gui.extension.pinpongOled12864.name": "Display OLED",
    "gui.extension.pinpongOled12864.description": "Pequeno display, adequado para dispositivos vestíveis inteligentes",
    "gui.extension.pinpongDS18B20.name": "Sensor de Temperatura DS18B20",
    "gui.extension.pinpongDS18B20.description": "Detecta temperatura ambiente com ampla faixa de -55~+125?",
    "gui.extension.pinpongMlx90614.name": "Sensor de Temperatura Infravermelho Não Contato",
    "gui.extension.pinpongMlx90614.description": "Medição infravermelha não contata da temperatura de objetos ou ambiente",
    "gui.extension.pinpongBmp388.name": "Sensor de Pressão Barométrica BMP388",
    "gui.extension.pinpongBmp388.description": "Função de detecção de temperatura, pressão atmosférica, altitude",
    "gui.extension.pinpongIRRecv.name": "Módulo Receptor de Infravermelho IR",
    "gui.extension.pinpongIRRecv.description": "Recebe sinal infravermelho padrão de 38KHz",
    "gui.extension.pythonsen0334.name": "Sensor de Temperatura e Umidade Digital SHT31-F",
    "gui.extension.pythonsen0334.description": "Medição de temperatura e umidade",
    "gui.extension.pythonsen0303.name": "Sensor UV VEML6075",
    "gui.extension.pythonsen0303.description": "Capacidade de detectar força UVA e UVB",
    "gui.extension.pythonsen0377.name": "Sensor de Gás MEMS",
    "gui.extension.pythonsen0377.description": "Suporte à detecção de concentrações de CO, C2H5OH (álcool) H2, NO2, NH3 e outros gases",
    "gui.extension.pythonsen0460.name": "Sensor de Qualidade do Ar",
    "gui.extension.pythonsen0460.description": "EN0460: Sensor de qualidade do ar com interface Gravity para medir o valor PM2.5",
    "gui.extension.obloqMp.name": "Módulo IoT OBLOQ",
    "gui.extension.obloqMp.description": "Um dispositivo Wi-Fi para Serial",
    "gui.extension.pyAutoGui.name": "PyAutoGui",
    "gui.extension.pyAutoGui.description": "PyAutoGUI permite que seus scripts Python controlem o mouse e o teclado para automatizar interações com outras aplicações.",
    "gui.extension.pyRequests.name": "Requests",
    "gui.extension.pyRequests.description": "HTTP para Humanos",
    "gui.extension.pyJson.name": "JSON",
    "gui.extension.pyJson.description": "Codificador e decodificador JSON",
    "gui.extension.pySchedule.name": "Agendar",
    "gui.extension.pySchedule.description": "Agendamento de tarefas Python para humanos.",
    "gui.extension.pyXlrd.name": "xlrd",
    "gui.extension.pyXlrd.description": "leitura execl, suporte .xls",
    "gui.extension.pyXlwt.name": "xlwt",
    "gui.extension.pyXlwt.description": "escrita execl, suporte .xls",
    "gui.extension.pyOpenCV.name": "OpenCV",
    "gui.extension.pyOpenCV.description": "OpenCV é uma biblioteca de código aberto que inclui centenas de algoritmos de visão computacional.",
    "gui.extension.pyBaiDuAi.name": "Reconhecimento Facial Baidu",
    "gui.extension.pyBaiDuAi.description": "reconhecer rosto com interface de IA da Baidu",
    "gui.extension.pyBaiDuImage.name": "Reconhecimento de Imagem Baidu",
    "gui.extension.pyBaiDuImage.description": "reconhecer imagem com interface de IA da Baidu",
    "gui.extension.pyBaiDuSpeech.name": "Voz Baidu",
    "gui.extension.pyBaiDuSpeech.description": "A tecnologia de voz da Baidu fornece aos desenvolvedores vários serviços inteligentes, incluindo conversão de fala para texto, texto para fala, etc.",
    "gui.account.dialog.prompt": "Prompt",
    "gui.account.dialog.tips": "Prompt",
    "gui.account.dialog.signoutAlert": "Você não poderá usar o armazenamento em nuvem e outras funções depois de sair. Você tem certeza de que deseja sair?",
    "gui.account.dialog.signoutSuccess": "Saiu",
    "gui.account.dialog.sure": "OK",
    "gui.account.dialog.cancel": "Cancelar",
    "gui.account.dialog.error": "Erro",
    "gui.account.dialog.save": "Salvar",
    "gui.account.dialog.getCloudFileFailed": "Erro ao obter o arquivo em nuvem [NAME], por favor, verifique se o arquivo existe",
    "gui.account.dialog.getCloudError": "Erro ao obter o arquivo em nuvem [NAME]",
    "gui.account.dialog.loadCloudSuccess": "Carregamento do arquivo em nuvem [NAME] bem-sucedido",
    "gui.account.dialog.saveCloudError": "Falha ao salvar [NAME] na nuvem, por favor, tente mais tarde.",
    "gui.account.dialog.saveCloudSuccess": "Salvar [NAME] na nuvem com sucesso",
    "gui.account.dialog.saveCloudCheckError": "Falha ao salvar, erro de verificação do arquivo em nuvem, por favor, tente novamente mais tarde",
    "gui.account.dialog.saveCloudRepeatPrompt": "Um arquivo [NAME] com o mesmo nome já existe na nuvem. Deseja substituí-lo?",
    "gui.account.dialog.saveCloudRepeatAlert": "Um arquivo com o mesmo nome ?[NAME]? já existe na nuvem, por favor, mude o nome",
    "gui.account.dialog.saveCover": "Substituir",
    "gui.account.dialog.rename": "Renomear",
    "gui.account.dialog.newProject": "Novo Arquivo",
    "gui.account.dialog.projectName": "Nome do Projeto",
    "gui.account.dialog.enterName": "Por favor, insira o nome do arquivo",
    "gui.account.dialog.nameWithSymbol": "O nome do arquivo não pode conter caracteres especiais, por favor, altere",
    "gui.account.dialog.nameBeyondLength": "O comprimento do nome do arquivo não pode exceder 30",
    "gui.account.dialog.renameSuccess": "Renomear o arquivo [NAME1] para [NAME2] com sucesso",
    "gui.account.dialog.renameFailed": "Falha ao renomear o arquivo",
    "gui.account.dialog.downloadSuccess": "Download de [NAME] concluído",
    "gui.account.dialog.cancelDownload": "Cancelar Download",
    "gui.account.dialog.deleteSucess": "A exclusão do arquivo em nuvem [NAME] foi bem-sucedida",
    "gui.account.dialog.saveAs": "Salvar Como",
    "gui.account.dialog.deleteFailed": "Erro ao excluir o arquivo em nuvem [NAME]",
    "gui.account.dialog.deletePrompt": "Tem certeza de que deseja excluir o arquivo em nuvem [NAME]?",
    "gui.account.open": "Aberto",
    "gui.account.upload": "Upload",
    "gui.account.delete": "Excluir",
    "gui.account.usersSpace": "Projeto em Nuvem de [NAME]",
    "gui.account.cloudDataLoading": "Carregamento de dados em nuvem, por favor, aguarde",
    "gui.account.emptyUserSpace": "Isso está vazio, vá e faça o upload do seu primeiro projeto.",
    "gui.account.dialog.login": "Login",
    "gui.account.dialog.unsupportProject": "Erro ao abrir o projeto, projeto não suportado",
    "gui.account.dialog.shouldLoginFirst": "O serviço em nuvem precisa das informações da sua conta, por favor, faça o login primeiro",
    "gui.account.dialog.loginTimeout": "Tempo limite de login",
    "gui.account.account": "Conta",
    "gui.account.serverAddr": "Endereço do Servidor",
    "gui.account.thirdPartyAccount": "Terceiros",
    "gui.account.dialog.saveCloudRenamePrompt": "O nome do arquivo [NAME] já existe, você deseja renomear?",
    "gui.account.dialog.signup": "Registrar",
    "gui.account.dialog.phoneNumber": "Número de celular",
    "gui.account.dialog.password": "Senha",
    "gui.account.dialog.autoLogin": "Lembrar a senha",
    "gui.account.dialog.forgotPasswd": "Esqueceu a senha?",
    "gui.account.dialog.getCheckCode": "Obter Código de Verificação",
    "gui.account.dialog.reSend": "Reenviar",
    "gui.account.dialog.checkCode": "Código de Verificação",
    "gui.account.dialog.readAndAgree": "Li e aceito",
    "gui.account.dialog.userAgreement": "Acordo do Usuário",
    "gui.account.dialog.loginSuccess": "Login bem-sucedido",
    "gui.account.dialog.passwordError": "A conta ou senha está incorreta, por favor, verifique se está correta",
    "gui.account.dialog.checkNumberSended": "O código de verificação foi enviado, por favor, verifique com cuidado",
    "gui.account.dialog.checkedAgree": "Concordar com o Acordo",
    "gui.account.dialog.networkError": "Erro de rede, por favor, verifique a conexão e tente novamente",
    "gui.account.dialog.readProtocolAndCheck": "Por favor, leia o acordo do usuário e marque concordo",
    "gui.account.dialog.signupError": "Erro no registro",
    "gui.account.dialog.logininErrorWithNetwork": "Erro de conexão com o servidor, falha no login, por favor, verifique a rede",
    "gui.account.dialog.signupErrorDefault": "Falha no registro, por favor, tente novamente",
    "gui.account.dialog.accountExist": "A conta já existe, por favor, faça login diretamente",
    "gui.account.dialog.phoneCodeError": "Falha no registro, erro no código de verificação",
    "gui.account.dialog.signupSuccess": "Registro bem-sucedido, por favor, retorne à interface de login para continuar a operação",
    "gui.account.dialog.inputEmpty": "Não pode estar vazio",
    "gui.account.dialog.phoneFormatIllegal": "Erro no formato do nome de usuário",
    "gui.python.remoteSSH.connectSuccess": "Conectado com sucesso em [ADDRESS]",
    "gui.python.remoteSSH.connectFailed": "Conexão com [ADDRESS] falhou",
    "gui.python.remoteSSH.shouleReconnect": "Desconectado de [ADDRESS], deseja reconectar?",
    "gui.python.remoteSSH.reconnect": "Reconectar",
    "gui.python.remoteSSH.disconnected": "Desconectado de [ADDRESS]",
    "gui.python.remoteSSH.accountCheckFailed": "As informações da conta estão incorretas, a conexão com [ADDRESS] falhou, por favor, verifique e insira",
    "gui.python.remoteSSH.connectError": "Erro de conexão com [ADDRESS], [MSG], consulte o documento de ajuda para solução",
    "gui.python.remoteSSH.sshLogin": "Login SSH",
    "gui.python.remoteSSH.address": "Endereço",
    "gui.python.remoteSSH.addressCantEmpty": "Endereço não pode estar vazio",
    "gui.python.remoteSSH.user": "Nome de usuário",
    "gui.python.remoteSSH.userCantEmpty": "Nome de usuário não pode estar vazio",
    "gui.python.remoteSSH.passwd": "Senha",
    "gui.python.remoteSSH.passwdCantEmpty": "A senha não pode estar vazia",
    "gui.python.remoteSSH.areYouSureDisconnect": "Atualmente conectado ao Remoto [ADDRESS], tem certeza de que deseja desconectar?",
    "gui.python.remoteSSH.statusConnecting": "Conectando",
    "gui.python.remoteSSH.disconnectDevice": "Desconectar Terminal Remoto",
    "gui.python.remoteSSH.connectDevice": "Conectar Terminal Remoto",
    "gui.python.remoteSSH.statusError": "Erro de conexão",
    "gui.python.remoteSSH.statusDisconnect": "A conexão foi desconectada",
    "gui.python.remoteSSH.handleInput": "Entrada Manual",
    "gui.python.remoteSSH.openNetNetWork": "Abrir Centro de Rede",
    "gui.python.remoteSSH.goHelp": "Documento de Ajuda",
    "gui.python.remoteSSH.waitForConnecting": "Conectando a [ADDRESS], por favor, aguarde...",
    "gui.python.remoteSSH.cancelConnect": "Cancelar conexão",
    "gui.python.remoteSSH.connectTimeout": "Tempo de conexão esgotado",
    "gui.python.remoteSSH.addressIsLost": "Endereço não existe",
    "gui.python.remoteSSH.connectRefused": "O pedido de conexão foi rejeitado, por favor, verifique as configurações do sistema remoto e tente novamente",
    "gui.python.remoteSSH.runGUIProgramTips": "Ao executar um programa gráfico, se desejar visualizar o efeito na área de trabalho remota, você precisa usar o nome de usuário atualmente logado no sistema remoto",
    "gui.python.remoteSSH.executeFileError": "Erro ao executar o projeto",
    "gui.python.remoteSSH.waitForProjectUpload": "Projeto [NAME] está sendo enviado, por favor, aguarde...",
    "gui.python.remoteSSH.projectUploadSuccess": "O projeto foi enviado com sucesso, começou a ser executado",
    "gui.python.remoteSSH.projectUploadError": "Erro no envio do projeto",
    "gui.python.filetree.boardNameInFT": "Arquivo em [BOARD]",
    "gui.python.filetree.replaceFilePrompt": "Um arquivo ou pasta chamado [NAME] já existe na pasta de destino. Você deseja substituí-lo?",
    "gui.python.filetree.fileUploading": "Enviar arquivo [NAME]...",
    "gui.python.filetree.fileSaving": "Salvar arquivo [NAME]...",
    "gui.python.filetree.dirSaving": "Salvar pasta [NAME]...",
    "gui.python.filetree.saveAs": "Salvar como",
    "gui.python.filetree.chooseDir": "Selecionar Pasta",
    "gui.python.filetree.getDirList": "Obter Diretório...",
    "gui.python.filetree.getDirListWithPath": "Ler diretório [PATH]",
    "gui.python.filetree.getRemoteDirList": "Ler diretório remoto [PATH]",
    "gui.python.filetree.renameFile": "Renomear de [FROM] para [TO]",
    "gui.python.filetree.deleteDirPrompt": "Tem certeza de que deseja excluir a pasta [NAME] e os arquivos que ela contém?",
    "gui.python.filetree.deleteFilePrompt": "Tem certeza de que deseja excluir o arquivo [NAME]?",
    "gui.python.filetree.downloadToPC": "Baixar arquivo para o computador",
    "gui.python.filetree.uploadToRemote": "Enviar arquivo para",
    "gui.python.filetree.exportFileTo": "Exportar Arquivo [EXT]",
    "gui.python.filetree.rmFromeFileSystem": "Remover do sistema de arquivos",
    "gui.python.filetree.rmDirPrompt": "Tem certeza de que deseja remover o caminho local [NAME] e seus subarquivos?",
    "gui.python.filetree.rmAllDirPrompt": "Tem certeza de que deseja remover todos os caminhos locais?",
    "gui.python.filetree.addLocalDir": "Adicionar uma pasta local",
    "gui.python.filetree.removeActionDirWithAll": "Remover todas as pastas locais",
    "gui.python.filetree.fileNameIsRequired": "Nome do arquivo ou pasta deve ser fornecido",
    "gui.python.filetree.fileNameIsRepeat": "O arquivo ou pasta [NAME] já existe neste local. Por favor, escolha outro nome.",
    "gui.python.filetree.dragParentNodeToChild": "A pasta pai não pode ser movida para a subpasta",
    "gui.python.filetree.dragPathIsConsistent": "O caminho de arrastar do arquivo é consistente",
    "gui.python.filetree.fileOperateError": "Erro na operação do arquivo",
    "gui.python.filetree.fileTreeMore": "Mais",
    "gui.python.filetree.copy": "copiar",
    "gui.python.filetree.paste": "colar",
    "gui.python.worker.initPythonHomeDir": "Inicializando pasta...",
    "gui.python.worker.copyPythonSource": "Inicialização da pasta concluída, copiando arquivos de recursos do Python...",
    "gui.python.worker.copyPythonSourceError": "Erro na operação do arquivo, falha ao inicializar o ambiente Python, por favor, reinsira o modo Python ou reinicie o software",
    "gui.python.worker.unzipPythonSource": "Cópia de arquivos de recursos concluída, descompactando...",
    "gui.python.worker.checkPythonSource": "Extração concluída, o arquivo está sendo verificado...",
    "gui.python.worker.unzipPythonSourceError": "Erro ao descompactar, falha ao inicializar o ambiente Python, por favor, reinsira o modo Python ou reinicie o software",
    "gui.python.xterm.local": "Local",
    "gui.python.xterm.detectPythonEnv": "Detectando o ambiente, por favor, aguarde...",
    "gui.python.xterm.installingPythonEnv": "Instalando o ambiente, por favor, aguarde...",
    "gui.python.xterm.installPythonEnvError": "Falha na inicialização do ambiente Python, por favor, entre em contato com o suporte técnico",
    "gui.python.xterm.detectPythonEnvFinished": "Detecção de ambiente concluída, iniciando...",
    "gui.python.xterm.installPythonEnvFinished": "A instalação do ambiente está completa, iniciando...",
    "gui.python.xterm.toRestartXterm": "Clique duas vezes para reiniciar o terminal",
    "gui.python.xterm.showRemoteConnect": "Abrir terminal de conexão remota",
    "gui.python.xterm.remoteConnectShowed": "O terminal de conexão remota está aberto",
    "gui.dialog.thirdExt.openPipManger": "Abrir Gerenciamento de Bibliotecas",
    "music.categoryName": "Música",
    "translate.categoryName": "Traduzir",
    "pen.categoryName": "Caneta",
    "pen.changeColorParam": "adicione [VALUE]ao parâmetro [COLOR_PARAM] da caneta",
    "pen.changeHue": "adicione [HUE]à cor da caneta ",
    "pen.changeShade": "adicione [SHADE]à tonalidade da caneta ",
    "pen.changeSize": "adicione [SIZE]ao tamanho da caneta",
    "pen.clear": "apague tudo",
    "pen.colorMenu.brightness": "brilho",
    "pen.colorMenu.color": "cor",
    "pen.colorMenu.saturation": "saturação",
    "pen.colorMenu.transparency": "transparência",
    "pen.penDown": "use a caneta",
    "pen.penUp": "levante a caneta",
    "pen.setColor": "mude a cor da caneta para [COLOR]",
    "pen.setColorParam": "Mude o parâmetro [COLOR_PARAM] da caneta para [VALUE]",
    "pen.setHue": "mude a cor da caneta para [HUE]",
    "pen.setShade": "mude a tonalidade da caneta para [SHADE]",
    "pen.setSize": "mude o tamanho da caneta para [SIZE]",
    "pen.stamp": "carimbe",
    "music.changeTempo": "alterar ritmo por [TEMPO]",
    "music.drumBass": "(2) Bumbo",
    "music.drumBongo": "(13) Bongô",
    "music.drumCabasa": "(15) Afoxé",
    "music.drumClaves": "(9) Claves Cubanas",
    "music.drumClosedHiHat": "(6) Prato Chimbau",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Chocalho",
    "music.drumCrashCymbal": "(4) Prato",
    "music.drumCuica": "(18) Cuíca",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Palmas",
    "music.drumOpenHiHat": "(5) Prato Hi-Hat",
    "music.drumSideStick": "(3) Bastão Lateral",
    "music.drumSnare": "(1) Tarol",
    "music.drumTambourine": "(7) Pandeiro",
    "music.drumTriangle": "(12) Triângulo",
    "music.drumVibraslap": "(17) Queixada",
    "music.drumWoodBlock": "(10) Bloco Sonoro",
    "music.getTempo": "ritmo",
    "music.instrumentBass": "(6) Baixo",
    "music.instrumentBassoon": "(14) Fagote",
    "music.instrumentCello": "(8) Violoncelo",
    "music.instrumentChoir": "(15) Coral",
    "music.instrumentClarinet": "(10) Clarinete",
    "music.instrumentElectricGuitar": "(5) Guitarra Elétrica",
    "music.instrumentElectricPiano": "(2) Teclado",
    "music.instrumentFlute": "(12) Flauta",
    "music.instrumentGuitar": "(4) Violão",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Caixa de Música",
    "music.instrumentOrgan": "(3) Órgão",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxofone",
    "music.instrumentSteelDrum": "(18) Tambor de Aço",
    "music.instrumentSynthLead": "(20) Sintetizador principal",
    "music.instrumentSynthPad": "(21) Bloco Sintetizador",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibrafone",
    "music.instrumentWoodenFlute": "(13) Flauta de madeira",
    "music.midiPlayDrumForBeats": "toque instrumento [DRUM] por [BEATS] batidas",
    "music.midiSetInstrument": "mudar instrumento para [INSTRUMENT]",
    "music.playDrumForBeats": "toque instrumento [DRUM] por [BEATS] batidas",
    "music.playNoteForBeats": "toque a nota [NOTE] por [BEATS] batidas",
    "music.restForBeats": "pausa por [BEATS] batidas",
    "music.setInstrument": "mudar instrumento para [INSTRUMENT]",
    "music.setTempo": "ajuste ritmo para [TEMPO]",
    "videoSensing.categoryName": "Detecção de Vídeo",
    "videoSensing.direction": "direção",
    "videoSensing.motion": "movimento",
    "videoSensing.off": "desligado",
    "videoSensing.on": "ligado",
    "videoSensing.onFlipped": "inverter",
    "videoSensing.setVideoTransparency": "ajuste transparência do vídeo para [TRANSPARENCY]",
    "videoSensing.sprite": "ator",
    "videoSensing.stage": "palco",
    "videoSensing.videoOn": "vídeo [ATTRIBUTE] para [SUBJECT]",
    "videoSensing.videoToggle": "mude vídeo [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "quando detectar sensor de vídeo> [REFERENCE]",
    "translate.translateBlock": "traduzir [WORDS] para [LANGUAGE]",
    "translate.defaultTextToTranslate": "olá",
    "translate.viewerLanguage": "idioma",
    "text2speech.speakAndWaitBlock": "falar [WORDS]",
    "text2speech.setVoiceBlock": "definir voz para [VOICE]",
    "text2speech.setLanguageBlock": "defina o idioma como [LANGUAGE]",
    "text2speech.alto": "loud",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "rangido",
    "text2speech.giant": "gigante",
    "text2speech.kitten": "gatinho",
    "text2speech.defaultTextToSpeak": "olá",
    "speech.whenIHear": "quando eu escutar [PHRASE]",
    "speech.listenAndWait": "escute e espere",
    "speech.defaultWhenIHearValue": "vamos lá",
    "sensor.categoryName": "Sensor",
    "actuator.categoryName": "Atuador",
    "communicate.categoryName": "Comunicar",
    "display.categoryName": "Exibir",
    "extendFunction.categoryName": "Função",
    "internetService.categoryName": "Internet",
    "arduinounoR3.categoryName": "Kit UNO",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "calliope.categoryName": "CALLIOPE",
    "esp32.categoryName": "handpy",
    "telloesp32.categoryName": "RMTT",
    "spread.categoryName": "Placa de Expansão",
    "speechRecognition.categoryName": "Reconhecimento de Fala",
    "thirdExpansion.categoryName": "Expansão do Usuário",
    "gui.blocklyText.blockTitle.eventBlock": "Programa de Eventos",
    "gui.blocklyText.blockTitle.dotScreen": "Tela de Pontos",
    "gui.blocklyText.blockTitle.music": "Música",
    "gui.blocklyText.blockTitle.onBoardSensor": "Sensor da placa",
    "gui.blocklyText.blockTitle.onBoardFunc": "Função da placa",
    "gui.blocklyText.blockTitle.pinOperation": "Operação de pino",
    "gui.blocklyText.blockTitle.wirelessCommunication": "Comunicação sem fio",
    "gui.blocklyText.blockTitle.serialportOperation": "Operação de porta serial",
    "gui.blocklyText.blockTitle.systemResource": "Recurso do sistema",
    "gui.blocklyText.blockTitle.screenDisplay": "Exibição de tela",
    "gui.blocklyText.blockTitle.ledControl": "Controle de LED",
    "gui.blocklyText.blockTitle.functionalModule": "Módulo funcional",
    "gui.blocklyText.blockTitle.movement": "Movimento",
    "gui.blocklyText.blockTitle.expression": "Expressão",
    "gui.blocklyText.blockTitle.light": "Luz",
    "gui.blocklyText.blockTitle.dance": "Dança",
    "gui.blocklyText.blockTitle.sensor": "Sensor",
    "gui.blocklyText.blockTitle.motorControl": "Controle de motor",
    "gui.blocklyText.blockTitle.kitSensor": "Sensor do kit",
    "gui.blocklyText.blockTitle.kitActuator": "Atuador do kit",
    "gui.blocklyText.blockTitle.commonModule": "Módulo comum",
    "gui.blocklyText.blockTitle.infraredCommunication": "Comunicação infravermelha",
    "gui.blocklyText.blockTitle.mp3Module": "Módulo MP3",
    "gui.blocklyText.blockTitle.ws2812RGB": "Luz RGB WS2812",
    "gui.blocklyText.blockTitle.LCDDisplay": "Display LCD",
    "gui.blocklyText.blockTitle.digitalTube": "Tubo digital",
    "gui.blocklyText.blockTitle.oledDisplay": "Display OLED",
    "gui.blocklyText.blockTitle.onBoardSource": "Recurso da placa",
    "gui.blocklyText.blockTitle.rgbControl": "Controle de luz RGB",
    "gui.blocklyText.blockTitle.maxbotResource": "Recurso max:bot",
    "gui.blocklyText.blockTitle.lineInspection": "Inspeção de linha",
    "gui.blocklyText.blockTitle.outerSensor": "Sensor externo",
    "gui.blocklyText.blockTitle.consolePrint": "Saída do console",
    "gui.blocklyText.blockTitle.radio": "Rádio",
    "gui.blocklyText.blockTitle.wifi": "Wifi",
    "gui.blocklyText.blockTitle.motor": "Motor",
    "gui.blocklyText.blockTitle.rgblightcontrol": "LED da placa",
    "gui.blocklyText.blockTitle.vibrationMotor": "Motor de vibração",
    "gui.blocklyText.romeo.d1ProgramStart": "Programa do robô D1 inicia",
    "gui.blocklyText.romeo.readdigital": "ler valor do pino[PIN] [MODULE]",
    "gui.blocklyText.romeo.buttonModule": "Módulo de Botão",
    "gui.blocklyText.romeo.digitalSensor": "Sensor Digital",
    "gui.blocklyText.romeo.collisionSensor": "Sensor de Colisão",
    "gui.blocklyText.romeo.lineSensor": "Sensor de Linha",
    "gui.blocklyText.romeo.infraredSwitch": "Chave Infravermelha",
    "gui.blocklyText.romeo.vibrationSensor": "Sensor de Vibração",
    "gui.blocklyText.romeo.readAnalog": "ler pino [PIN] [SENSOR]",
    "gui.blocklyText.romeo.lightSensor": "Sensor de Luz",
    "gui.blocklyText.romeo.anologSensor": "Sensor Analógico",
    "gui.blocklyText.romeo.soundSensor": "Sensor de Som",
    "gui.blocklyText.romeo.soilMoistureSensor": "Sensor de Umidade do Solo",
    "gui.blocklyText.romeo.frameSensor": "Sensor de Quadro",
    "gui.blocklyText.romeo.open": "Ligar",
    "gui.blocklyText.romeo.close": "Desligar",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] do pino [PIN]",
    "gui.blocklyText.romeo.ledLights": "Luzes de LED",
    "gui.blocklyText.romeo.digitalActuator": "Atuador Digital",
    "gui.blocklyText.romeo.relay": "Relé",
    "gui.blocklyText.romeo.fan": "Ventilador",
    "gui.blocklyText.romeo.writeAnalog": "configurar pino [PIN] [MODULE] para [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "Atuador de Simulação",
    "gui.blocklyText.romeo.fanModule": "Módulo de Ventilador",
    "gui.blocklyText.romeo.robotSpeedMove": "robô [DIR] na velocidade[SPEED]",
    "gui.blocklyText.romeo.forward": "mover para frente",
    "gui.blocklyText.romeo.backward": "mover para trás",
    "gui.blocklyText.romeo.turnLeft": "virar à esquerda",
    "gui.blocklyText.romeo.turnRight": "virar à direita",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "motor [MOTOR] [DIR] na velocidade[SPEED]",
    "gui.blocklyText.romeo.interface": "Interface",
    "gui.blocklyText.romeo.motorForward": "Para Frente",
    "gui.blocklyText.romeo.motorReverse": "Para Trás",
    "gui.blocklyText.romeo.stopMotor": "robô (motores M1 e M2) para de se mover",
    "gui.blocklyText.romeo.readUltrasonic": "Ler distância ultrassônica (cm) gatilho [TRIG] eco [ECHO]",
    "gui.blocklyText.romeo.lm35": "ler pino [PIN] temperatura LM35 (°C)",
    "gui.blocklyText.romeo.ds18b20": "ler pino [PIN] temperatura DS18B20 (°C)",
    "gui.blocklyText.romeo.pressInfrared": "pino infravermelho [PIN] recebeu sinal de botão [BUTTON] pressionado?",
    "gui.blocklyText.romeo.volumePlus": "Volume+",
    "gui.blocklyText.romeo.switch": "Interruptor",
    "gui.blocklyText.romeo.volumeMinus": "Volume-",
    "gui.blocklyText.romeo.startPause": "Iniciar/Pausar",
    "gui.blocklyText.romeo.up": "Cima",
    "gui.blocklyText.romeo.down": "Baixo",
    "gui.blocklyText.romeo.dataReadable": "Há dados para ler na porta serial?",
    "gui.blocklyText.romeo.readSerialData": "ler dados da porta serial",
    "gui.blocklyText.romeo.timer": "tempo de atividade do sistema (ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "pino [PIN] tocar tom de trompete [TONE] por [BEAT] batida",
    "gui.blocklyText.romeo.half": "Meio",
    "gui.blocklyText.romeo.quarter": "Quarto",
    "gui.blocklyText.romeo.oneInEighth": "Oitavo",
    "gui.blocklyText.romeo.wholeBeat": "Batida Inteira",
    "gui.blocklyText.romeo.doubleBeat": "Dupla Batida",
    "gui.blocklyText.romeo.stop": "Parar",
    "gui.blocklyText.romeo.setServo": "configurar pino [PIN] servo para [DEGREE] grau",
    "gui.blocklyText.pythondfr0548.setServo": "configurar pino [PIN] servo para [DEGREE] grau",
    "gui.blocklyText.romeo.segment": "LED de oito segmentos (pino D6-D13) exibe [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "saída serial [STRING] [WAY], [LINE]",
    "gui.blocklyText.romeo.originalOutput": "origem",
    "gui.blocklyText.romeo.stringOutput": "string",
    "gui.blocklyText.romeo.hexOutput": "HEX",
    "gui.blocklyText.romeo.wrap": "Embrulhar",
    "gui.blocklyText.romeo.noWrap": "Sem Embrulhar",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "ler dados seriais [READTYPE]",
    "gui.blocklyText.arduinounoR3.getNumOfFloat": "[TARGET] reter [NNUMS] decimal",
    "gui.blocklyText.romeo.getNumOfFloat": "[TARGET] reter [NNUMS] decimal",
    "gui.blocklyText.max.getNumOfFloat": "[TARGET] reter [NNUMS] decimal",
    "gui.blocklyText.maxbot.getNumOfFloat": "[TARGET] reter [NNUMS] decimal",
    "gui.blocklyText.microbit.getNumOfFloat": "[TARGET] reter [NNUMS] decimal",
    "gui.blocklyText.arduino.getNumOfFloat": "[TARGET] reter [NNUMS] decimal",
    "gui.blocklyText.arduinoNano.getNumOfFloat": "[TARGET] reter [NNUMS] decimal",
    "gui.blocklyText.mega2560.getNumOfFloat": "[TARGET] reter [NNUMS] decimal",
    "gui.blocklyText.firebeetleesp32.getNumOfFloat": "[TARGET] reter [NNUMS] decimal",
    "gui.blocklyText.romeo.int": "int",
    "gui.blocklyText.romeo.float": "float",
    "gui.blocklyText.romeo.setBaudRate": "configurar taxa de baud da porta serial para [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "Servo [INDEX] ângulo [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "ler pino [PIN] largura do pulso em [LEVEL] modo, tempo limite [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "BAIXO",
    "gui.blocklyText.romeo.high": "ALTO",
    "gui.blocklyText.romeo.noSound": "sem som",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino inicia",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno inicia",
    "gui.blocklyText.boson.starts": "BOSON MC1 inicia",
    "gui.blocklyText.pico.starts": "Pico inicia",
    "gui.blocklyText.boson.mpyAttitudeEvent": "quando a placa BOSON MC1 o que [ATTITUDE]",
    "gui.blocklyText.boson.mpyBoardShakeThrown": "Se a placa BOSON MC1 estiver [ATTITUDE]?",
    "gui.blocklyText.boson.readAcceleration": "Ler o valor da aceleração (m-g) [TYPE]",
    "gui.blocklyText.boson.setAccelerationRange": "Definir a faixa de aceleração para [ACCRANGE]",
    "gui.blocklyText.boson.vibrationMotor": "[SWITCH] motor de vibração",
    "gui.blocklyText.boson.vibrationMotorTime": "vibração contínua por [TIME] segundo",
    "gui.blocklyText.boson.displayClear.js": "Limpar a tela para [COLOR]",
    "gui.blocklyText.boson.displayClear": "Limpar a tela para [COLOR] [PUSH]",
    "gui.blocklyText.boson.displaySetLineWidth": "Definir a largura da linha para [LINE] direção [DIRECTION]",
    "gui.blocklyText.boson.displayText": "exibir texto na tela [TEXT] coordenada x[X] y[Y] cor[COLOR] fonte[FONT] fundo[BACKGROUND] [NOWRAP]",
    "gui.blocklyText.boson.displayText.js": "exibir texto na tela [TEXT] coordenada x[X] y[Y] cor[COLOR] [NOWRAP]",
    "gui.blocklyText.boson.displayTextLineshow": "Exibir texto na linha da tela [RAW] [TEXT] cor[COLOR] fonte[FONT] fundo[BACKGROUND] [NOWRAP] [MIDDLE]",
    "gui.blocklyText.boson.displayPixel": "desenhar pontos x[X] y[Y] cor [COLOR]",
    "gui.blocklyText.boson.displayLine": "desenhar linha começando x[X1] y[Y1] terminando x[X2] y[Y2] cor [COLOR]",
    "gui.blocklyText.boson.displayHline": "desenhar linha horizontal começando x[X] y[Y] len [LEN] cor [COLOR]",
    "gui.blocklyText.boson.displayVline": "desenhar linha vertical começando x[X] y[Y] len [LEN] cor [COLOR]",
    "gui.blocklyText.boson.displayRect": "desenhar retângulo começando x[X] y[Y] largura[W] altura[H] cor [COLOR] posição [POSITION] [FILL]",
    "gui.blocklyText.boson.displayRect.js": "desenhar retângulo começando x[X] y[Y] largura[W] altura[H] cor [COLOR] [FILL]",
    "gui.blocklyText.boson.displayCircle": "desenhar um círculo centro do círculo x[X] y[Y] raio[R] cor [COLOR] posição [POSITION] [FILL]",
    "gui.blocklyText.boson.displayCircle.js": "desenhar um círculo centro do círculo x[X] y[Y] raio[R] cor [COLOR] [FILL]",
    "gui.blocklyText.boson.displayTriangle": "desenhar triângulos x1[X1] y1[Y1] x2[X2] y2[Y2] x3[X3] y3[Y3] cor [COLOR] posição [POSITION] [FILL]",
    "gui.blocklyText.boson.displayRoundRect": "desenhar retângulo arredondado começando x[X] y[Y] largura[WIDTH] altura[HEIGTH] raio do canto[R] cor [COLOR] posição [POSITION]",
    "gui.blocklyText.boson.displayThreePointArc": "arco de três pontos x1[X1] y1[Y1] x2[X2] y2[Y2] x3[X3] y3[Y3] cor [COLOR] espaçamento discreto [SPACE] posição [POSITION]",
    "gui.blocklyText.boson.mpyDisplayComputerImage": "Carregar imagens do computador [IMAGE] Exibir em tons de cinza para x[X] y[Y] posição [POSITION] modo[TYPE]",
    "gui.blocklyText.boson.mpyDisplayComputerRgbImage": "Carregar imagem do computador [IMAGE] exibir para x[X] y[Y] posição [POSITION]",
    "gui.blocklyText.boson.boson_mpyDisplayFileSystemImage": "Carregar imagem do sistema de arquivos [PATH]",
    "gui.blocklyText.boson.showPixelCoordinate": "na coordenada x [X] y [Y] mostrar [SHOWMSG] fonte [PIXEL] cor [COLOR] fundo [BGCOLOR]",
    "gui.blocklyText.boson.displayQRCode": "Desenhar código QR [QRCODE] começando x[X] y[Y] tamanho [QRSIZE] cor [COLOR]",
    "gui.blocklyText.boson.mpyDisplayClockInit": "Inicializar o relógio [NAME] começando x[X] y[Y] raio [RADIUS] cor [COLOR] cor de fundo [BACKCOLOR]",
    "gui.blocklyText.boson.mpyDisplayClockUpdate": "Atualizar o relógio [NAME]",
    "gui.blocklyText.boson.displayClockInit": "Inicializar o relógio [ID] começando x[X] y[Y] raio [RADIUS] cor [COLOR] cor de fundo [BACKCOLOR]",
    "gui.blocklyText.boson.displayClockUpdate": "Atualizar o relógio [ID]",
    "gui.blocklyText.boson.mpyDisplayProgressInit": "Inicializar barra de progresso [NAME] começando x[X] y[Y] largura [WIDTH] altura [HEIGTH] taxa de progresso [PROGRESS]% cor de progresso [COLOR1] cor de fundo de progresso [COLOR2] cor de fundo [BACKCOLOR]",
    "gui.blocklyText.boson.mpyDisplayProgressUpdate": "atualizar barra de progresso [NAME] taxa de progresso [PROGRESS]%",
    "gui.blocklyText.boson.displayProgressInit": "Inicializar barra de progresso [ID] começando x[X] y[Y] largura [WIDTH] altura [HEIGTH] taxa de progresso [PROGRESS] cor de progresso [COLOR1] cor de fundo de progresso [COLOR2] cor de fundo [BACKCOLOR]",
    "gui.blocklyText.boson.displayProgressUpdate": "atualizar barra de progresso [ID] taxa de progresso [PROGRESS]",
    "gui.blocklyText.boson.displayBrightness": "a luminosidade da tela é [VALUE]%",
    "gui.blocklyText.boson.font16_16": "fonte16_16",
    "gui.blocklyText.boson.font20_20": "fonte20_20",
    "gui.blocklyText.boson.middleMenu.true": "Centro",
    "gui.blocklyText.boson.middleMenu.false": "comum",
    "gui.blocklyText.boson.directionMenu.false": "normal",
    "gui.blocklyText.boson.directionMenu.true": "inverso",
    "gui.blocklyText.boson.positionMenu.customize": "personalizado",
    "gui.blocklyText.boson.positionMenu.horizontalCenter": "Centralizado horizontalmente",
    "gui.blocklyText.boson.positionMenu.leftCenter": "centralizar à esquerda",
    "gui.blocklyText.boson.positionMenu.rightCenter": "centralizar à direita",
    "gui.blocklyText.boson.positionMenu.upCenter": "superior centralizado",
    "gui.blocklyText.boson.positionMenu.downCenter": "inferior centralizado",
    "gui.blocklyText.boson.positionMenu.leftTop": "superior esquerdo",
    "gui.blocklyText.boson.positionMenu.leftDown": "inferior esquerdo",
    "gui.blocklyText.boson.positionMenu.rightTop": "superior direito",
    "gui.blocklyText.boson.positionMenu.rightDown": "inferior direito",
    "gui.blocklyText.boson.strength": "força",
    "gui.blocklyText.boson.switchMenu.on": "Abrir",
    "gui.blocklyText.boson.switchMenu.off": "fechar",
    "gui.blocklyText.boson.nowrapMenu.true": "nova linha",
    "gui.blocklyText.boson.nowrapMenu.false": "sem quebra de linha",
    "gui.blocklyText.boson.fillMenu.false": "oco",
    "gui.blocklyText.boson.fillMenu.true": "Solid",
    "gui.blocklyText.boson.push.false": "Limpar cache",
    "gui.blocklyText.boson.push.true": "imediatamente efetivo",
    "gui.blocklyText.esp8266.starts": "ESP8266 inicia",
    "gui.blocklyText.esp32.displayInLine": "exibir [TEXT] na linha [LINE]",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] na linha [LINE] modo [SHOWMODE]",
    "gui.blocklyText.esp32.normal": "normal",
    "gui.blocklyText.esp32.rev": "rev",
    "gui.blocklyText.esp32.trans": "trans",
    "gui.blocklyText.esp32.XOR": "XOR",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "limpar cache na linha [LINE]",
    "gui.blocklyText.esp32.displayClearInLine": "limpar na linha [LINE]",
    "gui.blocklyText.esp32.buzzPinC": "P6 (Buzzer embutido)",
    "gui.blocklyText.esp32.buzzerRedirect": "Buzzer redirecionado para pino [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "Todos",
    "gui.blocklyText.esp32.setTouchThreshold": "Configurar botão [PIN] valor de toque é [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "Ler valor de toque do botão [PIN]",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 inicia",
    "gui.blocklyText.esp32.btnIsPressed": "Botão [BUTTON] botão [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "quando botão [REFERENCE] [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "quando pino [REFERENCE] está [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "Quando [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "pino [PIN] está [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "parar reprodução em segundo plano",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "tocar nota [NOTE] em segundo plano",
    "gui.blocklyText.esp32.setBuzzerFreq": "tocar nota [NOTE] por [BEAT] batida",
    "gui.blocklyText.esp32.getSoundIntensity": "ler intensidade sonora do microfone",
    "gui.blocklyText.esp32.readBrightness": "ler brilho da luz ambiente",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] mostrar [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "vermelho [RED] verde [GREEN] azul [BLUE]",
    "gui.blocklyText.esp32.accelerationCalibration": "Calibração do desvio do eixo X da aceleração [XOFFSET] desvio do eixo Y [YOFFSET] desvio do eixo Z [ZOFFSET] (m-g)",
    "gui.blocklyText.esp32.acceleration": "ler aceleração (m-g) [TYPE]",
    "gui.blocklyText.esp32.esp32_tiltAngle": "ler ângulo de inclinação [TYPE]",
    "gui.blocklyText.esp32.esp32_boardShakeThrown": "a placa estava [ATTITUDE]?",
    "gui.blocklyText.esp32.esp32_boardTiltState": "a placa inclinação [GESTUREMPY]",
    "gui.blocklyText.esp32.forward": "frente",
    "gui.blocklyText.esp32.back": "trás",
    "gui.blocklyText.esp32.left": "esquerda",
    "gui.blocklyText.esp32.right": "direita",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationRange": "configurar faixa de aceleração [ACCRANGE]",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationResolution": "configurar resolução de aceleração [ACCRESOLUTION]",
    "gui.blocklyText.esp32.esp32_mpyAccurateAccValue": "valor de aceleração precisa desvio do eixo X [XAXIS] desvio do eixo Y [YAXIS] desvio do eixo Z [ZAXIS]",
    "gui.blocklyText.esp32.esp32_mpyAccurateCompass": "Accurate compass",
    "gui.blocklyText.esp32.esp32_mpyCompassDirection": "Compass direction",
    "gui.blocklyText.esp32.esp32_mpyAxisMagnetic": "[TYPE] eixo magnético",
    "gui.blocklyText.esp32.esp32_mpyMagneticStrength": "Intensidade do campo magnético",
    "gui.blocklyText.esp32.esp32_mpyRemoveMagnetic": "Remover o campo magnético ambiente atual",
    "gui.blocklyText.esp32.esp32_mpyReturnRunTimeDiff": "Retornar diferença de tempo de execução, hora de início [STARTTIME] hora de término [ENDTIME]",
    "gui.blocklyText.esp32.esp32_mpyRunTimer": "Obter tempo de execução [TIMETYPE]",
    "gui.blocklyText.esp32.millisecond": "Milissegundo",
    "gui.blocklyText.esp32.microsencond": "Microsegundo",
    "gui.blocklyText.esp32.esp32_mpyLocalTime": "Obter hora local [LOCALTIME]",
    "gui.blocklyText.esp32.year": "Ano",
    "gui.blocklyText.esp32.month": "Mês",
    "gui.blocklyText.esp32.day": "Dia",
    "gui.blocklyText.esp32.hour": "Hora",
    "gui.blocklyText.esp32.minute": "Minuto",
    "gui.blocklyText.esp32.sencond": "Segundo",
    "gui.blocklyText.esp32.weeknumber": "Número da semana",
    "gui.blocklyText.esp32.daysnumber": "Número de dias",
    "gui.blocklyText.esp32.esp32_mpyReset": "Resetar",
    "gui.blocklyText.esp32.esp32_mpyMacAddr": "Endereço MAC",
    "gui.blocklyText.esp32.displayInXY": "Exibir [TEXT] no eixo X: [X] Y: [Y], visualização [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "Escrever cache [TEXT] em X: [X] Y: [Y], modo [SHOWMODE] visualização [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "Exibir cor de tela [COLOR] (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "Escrever cor de tela em cache [COLORMPYTHON]",
    "gui.blocklyText.esp32.clear": "Limpar",
    "gui.blocklyText.esp32.fullBright": "Brilho total",
    "gui.blocklyText.esp32.blackBackground": "Fundo preto",
    "gui.blocklyText.esp32.whiteBackground": "Fundo branco",
    "gui.blocklyText.esp32.black": "Tudo preto",
    "gui.blocklyText.esp32.white": "Tudo branco",
    "gui.blocklyText.esp32.lineDrawing": "Desenhar linha de Point x1:[X1] y1:[Y1] para Point x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "Conectado",
    "gui.blocklyText.esp32.disconnected": "Desconectado",
    "gui.blocklyText.esp32.rectangleDrawing": "Desenhar retângulo [FILL] com canto superior esquerdo em x:[X] y:[Y], L:[WIDTH], A:[HEIGHT]",
    "gui.blocklyText.esp32.mpyrectangleDrawing": "[DRAWERASE] desenhar origem do retângulo [FILL] x:[X] y:[Y] largura[WIDTH] altura[HEIGHT]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCornerBorder": "[DRAWERASE] borda de canto x [X] y [Y] largura [WIDTH] altura [HEIGHT] raio [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCircle": "[DRAWERASE] [FILL] círculo x [X] y [Y] raio [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseTriangle": "[DRAWERASE] [FILL] triângulo x1 [X1] y1 [Y1] x2 [X2] y2 [Y2] x3 [X3] y3 [Y3]",
    "gui.blocklyText.esp32.esp32_mpyShowPixelCoordinate": "Na coordenada x [X] y [Y] mostrar [SHOWMSG] como [PIXEL] fonte [LINE]",
    "gui.blocklyText.esp32.Digiface_11px": "Digiface 11px",
    "gui.blocklyText.esp32.Digiface_16px": "Digiface 16px",
    "gui.blocklyText.esp32.Digiface_21px": "Digiface 21px",
    "gui.blocklyText.esp32.Digiface_30px": "Digiface 30px",
    "gui.blocklyText.esp32.Digiface_44px": "Digiface 44px",
    "gui.blocklyText.esp32.Digiface_ltalic_30px": "Digiface itálico 30px",
    "gui.blocklyText.esp32.Digiface_ltalic_42px": "Digiface itálico 42px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_12px": "DejaVu Sans Mono 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_16px": "DejaVu Sans Mono 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_21px": "DejaVu Sans Mono 21px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_12px": "DejaVu Sans Mono Bold 12px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_16px": "DejaVu Sans Mono Bold 16px",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_21px": "DejaVu Sans Mono Bold 21px",
    "gui.blocklyText.esp32.esp32_mpyQRCode": "x [X] y [Y] desenhar código QR [QRCODE] tamanho [IMGSIZE]",
    "gui.blocklyText.esp32.esp32_mpyInitClock": "Inicializar relógio [CLOCKNAME] x [X] y [Y] raio [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyClockReadTime": "Relógio [CLOCKNAME] ler hora",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseClock": "[DRAWERASE] relógio [CLOCKNAME]",
    "gui.blocklyText.esp32.screenDisplay": "Exibição de cache de tela tem efeito",
    "gui.blocklyText.esp32.circleDrawing": "Desenhar círculo [FILL] com ponto central em x:[X] y:[Y], r:[RADIUS]",
    "gui.blocklyText.esp32.fill": "preencher",
    "gui.blocklyText.esp32.notFill": "não preencher",
    "gui.blocklyText.esp32.pointDrawing": "Desenhar ponto em x:[X] y:[Y]",
    "gui.blocklyText.esp32.esp32_mpyProgressBar": "Barra de progresso x [X] y [Y] largura [WIDTH] altura [HEIGHT] progresso [PROG]",
    "gui.blocklyText.esp32.esp32_mpyColumnarProgressBar": "Barra de progresso colunar [COLUMNARSTATE] x [X] y [Y] largura [WIDTH] altura [HEIGHT] progresso [PROG]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseLine": "[DRAWERASE] [COLUMNARSTATE] linha x [X] y [Y] comprimento [LENGTH]",
    "gui.blocklyText.esp32.draw": "Desenhar",
    "gui.blocklyText.esp32.erase": "Apagar",
    "gui.blocklyText.esp32.vertical": "Vertical",
    "gui.blocklyText.esp32.horizontal": "Horizontal",
    "gui.blocklyText.esp32.setLineWidth": "Definir largura da linha para [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "Pino digital [PIN] saída [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "Ler pino digital [PIN]",
    "gui.blocklyText.esp32.analogWrite": "Pino analógico [PIN] saída (PWM) [VALUE]",
    "gui.blocklyText.esp32.analogRead": "Ler pino analógico [PIN]",
    "gui.blocklyText.esp32.serialPort0": "Serial0",
    "gui.blocklyText.esp32.serialPort1": "Serial1",
    "gui.blocklyText.esp32.serialPort2": "Serial2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX] taxa de transmissão [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "Exibir imagem da tela [IMAGE] em x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "Carregar imagem da imagem [IMAGE] mostrar para x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "Pressionado",
    "gui.blocklyText.esp32.loosened": "Liberado",
    "gui.blocklyText.esp32.all": "Tudo(-1)",
    "gui.blocklyText.esp32.setBaud": "Baudrate da serialport0 [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "Ler dados seriais [READTYPE] de [SERIAL]",
    "gui.blocklyText.esp32.getNumOfFloat": "[TARGET] manter [NNUMS] decimal",
    "gui.blocklyText.esp32.setBrightness": "Definir brilho do LED [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "Obter luminosidade do LED",
    "gui.blocklyText.esp32.print": "Imprimir [DATA]",
    "gui.blocklyText.esp32.TiltForward": "Inclinação para frente",
    "gui.blocklyText.esp32.TiltBack": "Inclinação para trás",
    "gui.blocklyText.esp32.TiltLeft": "Inclinação para a esquerda",
    "gui.blocklyText.esp32.TiltRight": "Inclinação para a direita",
    "gui.blocklyText.esp32.ScreenUp": "Tela para cima",
    "gui.blocklyText.esp32.ScreenDown": "Face para baixo",
    "gui.blocklyText.esp32.Shake": "Agitar",
    "gui.blocklyText.esp32.isGesture": "Está [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "Desativar LED [INDEX]",
    "gui.blocklyText.esp32.rgbdisable.all": "Tudo",
    "gui.blocklyText.esp32.rgbdisable.No.0": "No.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "No.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "No.2",
    "gui.blocklyText.esp32.playMusic": "Reproduzir música [MUSIC] repetindo [REPEAT]",
    "gui.blocklyText.esp32.Once": "Uma vez",
    "gui.blocklyText.esp32.Forever": "Sempre",
    "gui.blocklyText.esp32.OnceInBackground": "Uma vez em segundo plano",
    "gui.blocklyText.esp32.ForeverInBackground": "Sempre em segundo plano",
    "gui.blocklyText.radio.radioSwitch": "Ligar [DATA] rádio",
    "gui.blocklyText.radio.radioswitch.on": "Ligar",
    "gui.blocklyText.radio.radioswitch.off": "Desligar",
    "gui.blocklyText.radio.setChannel": "Definir canal do rádio [DATA]",
    "gui.blocklyText.radio.radioSend": "Rádio envia [DATA]",
    "gui.blocklyText.radio.radioreceived": "Rádio recebido",
    "gui.blocklyText.radio.receivingEven": "Quando recebe [DATA]",
    "gui.blocklyText.radio.Msg": "Mensagem de rádio",
    "gui.blocklyText.radio.receivingSpecificEven": "Quando receber mensagem de transmissão específica [DATA] do Rádio faça",
    "gui.blocklyText.esp32.radioSwitch": "Ligar [DATA] rádio",
    "gui.blocklyText.esp32.radioswitch.on": "Ligar",
    "gui.blocklyText.esp32.radioswitch.off": "Desligar",
    "gui.blocklyText.esp32.setChannel": "Definir canal do rádio [DATA]",
    "gui.blocklyText.esp32.radioSend": "Rádio envia [DATA]",
    "gui.blocklyText.esp32.radioreceived": "Rádio recebido",
    "gui.blocklyText.esp32.receivingEven": "Quando receber mensagem de transmissão [DATA] do Rádio faça",
    "gui.blocklyText.esp32.receivingSpecificEven": "Quando receber mensagem de transmissão específica [DATA] do Rádio faça",
    "gui.blocklyText.esp32.connectWIFI": "Conectar ao Wi-Fi com SSID [SSID] e Senha [PASS]",
    "gui.blocklyText.esp32.disconnectWIFI": "Desconectar a conexão Wi-Fi",
    "gui.blocklyText.esp32.checkWIFI": "Está conectado ao Wi-Fi",
    "gui.blocklyText.esp32.configurationInformation": "Informações de configuração do Wi-Fi",
    "gui.blocklyText.esp32.configurationInformationIP": "Informações de configuração do Wi-Fi [IP]",
    "gui.blocklyText.esp32.wifi.IP": "IP",
    "gui.blocklyText.esp32.wifi.netmask": "Máscara de sub-rede",
    "gui.blocklyText.esp32.wifi.geteway": "Gateway",
    "gui.blocklyText.esp32.wifi.DNS": "DNS",
    "gui.blocklyText.esp32.openAP": "Abrir modo AP com SSID [SSID] & Senha [PASS] & Canal [CHANNEL]",
    "gui.blocklyText.esp32.closeAP": "Fechar modo AP",
    "gui.blocklyText.esp32.synchronizeTime": "Sincronizar hora de rede, fuso horário [TIMEZONE] e servidor de sincronização [SERVER]",
    "gui.blocklyText.esp32.timezone.UTC+8": "UTC+8",
    "gui.blocklyText.esp32.timezone.UTC+9": "UTC+9",
    "gui.blocklyText.esp32.timezone.UTC+10": "UTC+10",
    "gui.blocklyText.esp32.timezone.UTC+11": "UTC+11",
    "gui.blocklyText.esp32.timezone.UTC+12": "UTC+12",
    "gui.blocklyText.esp32.timezone.UTC-12": "UTC-12",
    "gui.blocklyText.esp32.timezone.UTC-11": "UTC-11",
    "gui.blocklyText.esp32.timezone.UTC-10": "UTC-10",
    "gui.blocklyText.esp32.timezone.UTC-9": "UTC-9",
    "gui.blocklyText.esp32.timezone.UTC-8": "UTC-8",
    "gui.blocklyText.esp32.timezone.UTC-7": "UTC-7",
    "gui.blocklyText.esp32.timezone.UTC-6": "UTC-6",
    "gui.blocklyText.esp32.timezone.UTC-5": "UTC-5",
    "gui.blocklyText.esp32.timezone.UTC-4": "UTC-4",
    "gui.blocklyText.esp32.timezone.UTC-3": "UTC-3",
    "gui.blocklyText.esp32.timezone.UTC-2": "UTC-2",
    "gui.blocklyText.esp32.timezone.UTC-1": "UTC-1",
    "gui.blocklyText.esp32.timezone.UTC": "UTC",
    "gui.blocklyText.esp32.timezone.UTC+1": "UTC+1",
    "gui.blocklyText.esp32.timezone.UTC+2": "UTC+2",
    "gui.blocklyText.esp32.timezone.UTC+3": "UTC+3",
    "gui.blocklyText.esp32.timezone.UTC+4": "UTC+4",
    "gui.blocklyText.esp32.timezone.UTC+5": "UTC+5",
    "gui.blocklyText.esp32.timezone.UTC+6": "UTC+6",
    "gui.blocklyText.esp32.timezone.UTC+7": "UTC+7",
    "gui.blocklyText.telloesp32.arduinoUnoStarts": "RMTT ESP32 starts",
    "gui.blocklyText.telloesp32.ledControl": "Controle de LED",
    "gui.blocklyText.telloesp32.matrixControl": "Controle de matriz",
    "gui.blocklyText.telloesp32.flyControl": "Controle de voo",
    "gui.blocklyText.telloesp32.customCommand": "Comando personalizado",
    "gui.blocklyText.telloesp32.pinOperation": "Operação de pino",
    "gui.blocklyText.telloesp32.readDigital": "Ler pino digital [PIN]",
    "gui.blocklyText.telloesp32.readAnalog": "Ler pino analógico [PIN]",
    "gui.blocklyText.telloesp32.setPwm": "Definir pino PWM [PIN] como [VALUE]",
    "gui.blocklyText.telloesp32.setDigital": "Definir pino digital [PIN] como [LEVEL]",
    "gui.blocklyText.telloesp32.functionalModule": "Módulo funcional",
    "gui.blocklyText.telloesp32.telloesp32_matrix": "Definir matriz [MARK]",
    "gui.blocklyText.telloesp32.showPatternWhenStart": "Mostrar padrão [MARK] ao iniciar",
    "gui.blocklyText.telloesp32.setMatrixLight": "Definir luz da matriz [NUM]",
    "gui.blocklyText.telloesp32.matrixDisplayText": "Matriz exibir texto [TEXT] cor [COLOR] luz [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayTextMpy": "Matriz exibir texto [TEXT] cor [COLOR]",
    "gui.blocklyText.telloesp32.matrixDisplayPreset": "Matriz exibir padrão predefinido [PATTERN] cor [COLOR] luz [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayPresetMpy": "Matriz exibir padrão predefinido [PATTERN] cor [COLOR]",
    "gui.blocklyText.telloesp32.empty": "Vazio",
    "gui.blocklyText.telloesp32.WiFi": "WiFi",
    "gui.blocklyText.telloesp32.DownLoad": "Download",
    "gui.blocklyText.telloesp32.Power": "Energia",
    "gui.blocklyText.telloesp32.heart": "Coração",
    "gui.blocklyText.telloesp32.heartOutline": "Contorno do coração",
    "gui.blocklyText.telloesp32.matrixR": "R",
    "gui.blocklyText.telloesp32.matrixB": "B",
    "gui.blocklyText.telloesp32.matrixP": "P",
    "gui.blocklyText.telloesp32.matrixDisplayRollPicture": "Direção de marcação de exibição [DIRECT] frequência de movimento [SPEED] marca [MARK]",
    "gui.blocklyText.telloesp32.matrixDisplayRollText": "Matriz exibir direção de texto [DIRECT] frequência de movimento [SPEED] texto [TEXT] cor [COLOR]",
    "gui.blocklyText.telloesp32.telloSetWifiChannel": "Definir canal Wi-Fi [CHANNEL]",
    "gui.blocklyText.telloesp32.initTOF": "Iniciar TOF",
    "gui.blocklyText.telloesp32.readTOF": "Ler TOF (mm)",
    "gui.blocklyText.telloesp32.buttonIsPressed": "O botão está pressionado?",
    "gui.blocklyText.telloesp32.setRgbColor": "Cores da matriz em cache [COLOR]",
    "gui.blocklyText.telloesp32.rgbToColor": "Definir R: [RED] G: [GREEN] B: [BLUE]",
    "gui.blocklyText.telloesp32.rgbBreathWithColor": "LED de respiração com cor [COLOR] frequência(Hz) [TIME]",
    "gui.blocklyText.telloesp32.rgbBreath": "Definir luzes de respiração R: [RED] G: [GREEN] B: [BLUE] frequência(Hz): [SP]",
    "gui.blocklyText.telloesp32.rgbFlashingColor": "Luzes piscando cor1 [COLOR1] e cor2 [COLOR2] frequência(Hz) [SP]",
    "gui.blocklyText.telloesp32.rgbFlashing": "Definir luzes piscando R1: [RED1] G1: [GREEN1] B1: [BLUE1] R2: [RED2] G2: [GREEN2] B2: [BLUE2] frequência(Hz): [SP]",
    "gui.blocklyText.telloesp32.ledCloseEffects": "Efeitos especiais de LED fechados",
    "gui.blocklyText.telloesp32.matrixCloseEffects": "Efeitos especiais de matriz fechados",
    "gui.blocklyText.telloesp32.sendCustomCommand": "Enviar comando personalizado [CMD]",
    "gui.blocklyText.telloesp32.recvCustomCommand": "Receber comando personalizado [MSG], tempo limite [TIMEOUT]",
    "gui.blocklyText.telloesp32.recvCustomCommandNum": "Receber número personalizado, tempo limite [TIMEOUT]",
    "gui.blocklyText.telloesp32.serialportOperation": "Operação de porta serial",
    "gui.blocklyText.telloesp32.dataReadable": "Os dados da [SERIAL] são legíveis?",
    "gui.blocklyText.telloesp32.readSerialData": "Ler dados da [SERIAL]",
    "gui.blocklyText.telloesp32.setBaud": "Definir taxa de transmissão serial0 [BAUD]",
    "gui.blocklyText.telloesp32.readSerialDataIntFloat": "De [SERIAL] ler dados seriais int ou float [READTYPE]",
    "gui.blocklyText.telloesp32.getNumOfFloat": "[TARGET] manter [NNUMS] decimais",
    "gui.blocklyText.telloesp32.seriaRemapping": "Definir [SERIAL] Rx [RX] Tx [TX] A taxa de transmissão [BAUD]",
    "gui.blocklyText.telloesp32.systemResource": "Recursos do sistema",
    "gui.blocklyText.telloesp32.timer": "Temporizador (ms)",
    "gui.blocklyText.telloesp32.consolePrint": "Saída do console",
    "gui.blocklyText.telloesp32.print": "Imprimir [DATA]",
    "gui.blocklyText.telloesp32.telloRiseDown": "Voar [RISEDOWN] [NUMS]cm",
    "gui.blocklyText.telloesp32.rise": "Cima",
    "gui.blocklyText.telloesp32.down": "Baixo",
    "gui.blocklyText.telloesp32.telloTurnDirection": "Voar [DIRECTION] [NUMS] cm",
    "gui.blocklyText.telloesp32.turnleft": "Esquerda",
    "gui.blocklyText.telloesp32.turnright": "Direita",
    "gui.blocklyText.telloesp32.turnForward": "Para frente",
    "gui.blocklyText.telloesp32.turnBack": "Para trás",
    "gui.blocklyText.telloesp32.telloMotorOn": "Motor ligado",
    "gui.blocklyText.telloesp32.telloMotorOff": "Motor desligado",
    "gui.blocklyText.telloesp32.telloTakeOff": "Decolar",
    "gui.blocklyText.telloesp32.telloThrowFly": "Lançar voo",
    "gui.blocklyText.telloesp32.telloLanding": "Pousar",
    "gui.blocklyText.telloesp32.telloClockwiseRotation": "Girar [CLOCKWISE] [NUMS] grau",
    "gui.blocklyText.telloesp32.clockwise": "Horário",
    "gui.blocklyText.telloesp32.counterClockwise": "Antihorário",
    "gui.blocklyText.telloesp32.telloStartControl": "Iniciar controle",
    "gui.blocklyText.telloesp32.telloGetHeight": "Obter [HEIGHT], tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.relatively": "Altura relativa(dm)",
    "gui.blocklyText.telloesp32.barometer": "Altitude do barômetro(m)",
    "gui.blocklyText.telloesp32.tof": "Altura TOF(mm)",
    "gui.blocklyText.telloesp32.telloTemp": "Temperatura da placa(°C), tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.lowest": "Mais baixa",
    "gui.blocklyText.telloesp32.highest": "Mais alta",
    "gui.blocklyText.telloesp32.telloAttitude": "Atitude de arremesso(°), tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.pitch": "Arremesso",
    "gui.blocklyText.telloesp32.roll": "Rolamento",
    "gui.blocklyText.telloesp32.translation": "Tradução",
    "gui.blocklyText.telloesp32.telloAcceleration": "Aceleração(0.001g), tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.xaxis": "Eixo x",
    "gui.blocklyText.telloesp32.yaxis": "Eixo y",
    "gui.blocklyText.telloesp32.zaxis": "Eixo z",
    "gui.blocklyText.telloesp32.telloSetSpeed": "Definir velocidade para [NUMS]cm/s",
    "gui.blocklyText.telloesp32.telloSpeed": "[XYZAXIS] velocidade(cm/s), tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloPower": "Bateria( %), tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.MissionPad": "MissionPad, tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloMissionPadXyz": "MissionPad [XYZ] (cm), tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCommandResponse": "Resposta do comando, tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSetyawXMid": "Meio[MID] setyaw [YAW] médio",
    "gui.blocklyText.telloesp32.telloGetMissionPadNum": "Obter número do MissionPad, tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloCurrentSpeed": "Velocidade atual, tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloTime": "Tempo de funcionamento do motor (s), tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloWIFISign": "Sinal Wi-Fi, tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSDKVersion": "Tello SDK Version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloBinVersion": "Tello firmware version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloHasHardware": "Tello get hardware version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.rmttWifiVersion": "RMTT Wi-Fi version, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloSN": "Tello SN code, timeout [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloFlyToXYZ": "Voar (relativo à aeronave) X[X]cm y[Y]cm z[Z]cm com velocidade [SPEED]cm/s",
    "gui.blocklyText.telloesp32.getSpeed": "Obter velocidade definida atual (cm/s)",
    "gui.blocklyText.telloesp32.getBattery": "Obter o valor percentual da potência da bateria restante atual",
    "gui.blocklyText.telloesp32.getTime": "Obter tempo de funcionamento do motor",
    "gui.blocklyText.telloesp32.getWifiNoise": "Obter a relação sinal-ruído do sinal Wi-Fi",
    "gui.blocklyText.telloesp32.getSdkVersion": "Obter versão SDK",
    "gui.blocklyText.telloesp32.getSnNum": "Obter número de série",
    "gui.blocklyText.mpy.MQTTinit": "MQTT inicial [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInit": "mqtt init [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInitPlatform": "plataforma",
    "gui.blocklyText.mpy.mpyMqttInitPlatformNormal": "normal",
    "gui.blocklyText.mpy.mpyMqttInitPlatformTencent": "tencent",
    "gui.blocklyText.mpy.MQTTclient_id": "client_id",
    "gui.blocklyText.mpy.MQTTserver": "server",
    "gui.blocklyText.mpy.MQTTport": "porta",
    "gui.blocklyText.mpy.MQTTuser": "usuário",
    "gui.blocklyText.mpy.MQTTpassowrd": "senha",
    "gui.blocklyText.mpy.MQTTkeepalive": "keepalive",
    "gui.blocklyText.mpy.MQTTtencent": "MQTT Tencent IOT [SETTING]",
    "gui.blocklyText.mpy.TencentIOT": "TencentIOT",
    "gui.blocklyText.mpy.TencentServer": "server",
    "gui.blocklyText.mpy.TencentPort": "porta",
    "gui.blocklyText.mpy.TencentAppld": "Appld",
    "gui.blocklyText.mpy.TencentDevice": "dispositivo",
    "gui.blocklyText.mpy.siotServer": "Servidor",
    "gui.blocklyText.mpy.siotServerUser": "Usuário",
    "gui.blocklyText.mpy.siotServerPass": "Senha",
    "gui.blocklyText.mpy.Tencentoutput": "saída",
    "gui.blocklyText.mpy.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.mpy.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.mpy.mpy_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.mpy.EasyIOTServer": "servidor",
    "gui.blocklyText.mpy.EasyIOTServerChina": "China",
    "gui.blocklyText.mpy.EasyIOTServerGlobal": "Global",
    "gui.blocklyText.mpy.EasyIOTclient_id": "client_id",
    "gui.blocklyText.mpy.EasyIOT_id": "Iot_id",
    "gui.blocklyText.mpy.EasyIOT_password": "Iot_pwd",
    "gui.blocklyText.mpy.MQTTconnect": "conectar ao MQTT",
    "gui.blocklyText.mpy.mpy_MQTTAddSub": "inscrever-se [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublish": "publicar [DATA] no tópico [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublishQos1": "publicar [DATA] no tópico [TOPIC] e salvar no banco de dados",
    "gui.blocklyText.mpy.mpy_waitMSG": "esperar por mensagem de tópico no modo [MODE]",
    "gui.blocklyText.mpy.disconnectMQTT": "desconectar do MQTT",
    "gui.blocklyText.mpy.MQTTlastwill": "definir o último teste de tópico MQTT [TOPIC] como [MSG]",
    "gui.blocklyText.mpy.MQTTreceive": "quando receber [DATA] do tópico [TOPIC]",
    "gui.blocklyText.mpy.mqttMsg": "mensagem MQTT",
    "gui.blocklyText.mpy.MQTTmode.block": "bloquear",
    "gui.blocklyText.mpy.MQTTmode.unblock": "desbloquear",
    "gui.blocklyText.mpyWeather.init": "configurar servidor meteorológico [DATA] como [SETTING]",
    "gui.blocklyText.mpyWeather.weatherFeild": "[DATA] Campos comuns do servidor meteorológico [FIELD]",
    "gui.blocklyText.mpyWeather.weatherSituation": "Condições meteorológicas atuais [NOW] do servidor meteorológico [DATA]",
    "gui.blocklyText.mpyWeather.weatherForecast": "Previsão do tempo [FIELD] do servidor meteorológico [DATA] para [DATE]",
    "gui.blocklyText.mpyWeather.weatherLivingindex": "Índice de vida [FIELD] do servidor meteorológico [DATA]",
    "gui.blocklyText.mpyWeather.weatherFeild.name": "nome",
    "gui.blocklyText.mpyWeather.weatherFeild.id": "id",
    "gui.blocklyText.mpyWeather.weatherFeild.code": "código",
    "gui.blocklyText.mpyWeather.weatherFeild.path": "caminho",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone": "fuso horário",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone_offset": "offset de fuso horário",
    "gui.blocklyText.mpyWeather.weatherFeild.Updated": "Atualizado",
    "gui.blocklyText.mpyWeather.weatherFeild.updatetime": "atualizar hora",
    "gui.blocklyText.mpyWeather.weatherFeild.original": "original",
    "gui.blocklyText.mpyWeather.weatherSituation.text": "texto",
    "gui.blocklyText.mpyWeather.weatherSituation.code": "código",
    "gui.blocklyText.mpyWeather.weatherSituation.temperature": "temperatura",
    "gui.blocklyText.mpyWeather.weatherdate.today": "hoje",
    "gui.blocklyText.mpyWeather.weatherdate.tomorrow": "amanhã",
    "gui.blocklyText.mpyWeather.weatherdate.dayaftertomorrow": "depois de amanhã",
    "gui.blocklyText.mpyWeather.province": "província",
    "gui.blocklyText.mpyWeather.city": "cidade",
    "gui.blocklyText.mpyWeather.weatherforecast.highesttemperature": "temperaturamáxima",
    "gui.blocklyText.mpyWeather.weatherforecast.lowesttemperature": "temperaturamínima",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweather": "climadiurno",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweathercode": "códigoclimadiurno",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweather": "climanoite",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweathercode": "códigoclimanoite",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirection": "direçãodovento",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirectiondegree": "grau dedireçãodovento",
    "gui.blocklyText.mpyWeather.weatherforecast.windspeed": "velocidadedov ento",
    "gui.blocklyText.mpyWeather.weatherforecast.windscale": "escala devento",
    "gui.blocklyText.mpyWeather.weatherforecast.date": "data",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Carwashindex": "ÍndicedeLavagemdeCarro",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Dressingindex": "ÍndicedeVestir",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Coldindex": "ÍndicedeFrio",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Sportindex": "ÍndicedeEsporte",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Travelindex": "ÍndicedeViagem",
    "gui.blocklyText.mpyWeather.weatherlivingindex.uvindex": "ÍndicedeUV",
    "gui.blocklyText.mpyWeather.type": "tipo de dados",
    "gui.blocklyText.mpyWeather.temperature": "temperatura",
    "gui.blocklyText.mpyWeather.APIkey": "chave de API",
    "gui.blocklyText.mpyWeather.language": "idioma",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 começa",
    "gui.blocklyText.mega2560.digitalRead": "ler pino digital [PIN]",
    "gui.blocklyText.mega2560.analogRead": "ler pino analógico [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "pino PWM [PIN] saída [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "pino digital [PIN] saída [VALUE]",
    "gui.blocklyText.mega2560.level.low": "BAIXO",
    "gui.blocklyText.mega2560.level.high": "ALTO",
    "gui.blocklyText.mega2560.irqRead": "ler pino digital [PIN] do receptor de infravermelho",
    "gui.blocklyText.mega2560.ultrasonicRead": "ler sensor ultrasônico trig [TRIG], echo [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "pino [PIN] tocar tom de buzina [TONE] por [BEAT] batida",
    "gui.blocklyText.mega2560.harf": "Meio",
    "gui.blocklyText.mega2560.quarter": "Quarto",
    "gui.blocklyText.mega2560.oneInEighth": "Oitavo",
    "gui.blocklyText.mega2560.wholeBeat": "Batida Inteira",
    "gui.blocklyText.mega2560.doubleBeat": "Batida Dupla",
    "gui.blocklyText.mega2560.stop": "Parar",
    "gui.blocklyText.mega2560.servoSet": "Definir pino [PIN] servo para [DEGREE] graus",
    "gui.blocklyText.mega2560.setBaud": "definir [SERIAL] taxa de transmissão para [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "de [SERIAL] ler dados seriais int ou float [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "serial0",
    "gui.blocklyText.mega2560.serialPort1": "serial1",
    "gui.blocklyText.mega2560.serialPort2": "serial2",
    "gui.blocklyText.mega2560.serialPort3": "serial3",
    "gui.blocklyText.mega2560.dataReadable": "Há dados para ler em [SERIAL]?",
    "gui.blocklyText.mega2560.readSerialData": "ler dados em [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "em string",
    "gui.blocklyText.mega2560.originalOutput": "saída original",
    "gui.blocklyText.mega2560.hexOutput": "em HEX",
    "gui.blocklyText.mega2560.wrap": "Embrulhar",
    "gui.blocklyText.mega2560.noWrap": "Sem embrulho",
    "gui.blocklyText.mega2560.readPinLevel": "ler largura de pulso do pino [PIN] no modo [LEVEL], tempo limite [TIMEOUT] (us)",
    "gui.blocklyText.mega2560.readTimer": "tempo de atividade do sistema (ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini começa",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo começa",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 começa",
    "gui.blocklyText.arduino.setDigital": "pino digital [PIN] saída [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "ler pino digital [PIN]",
    "gui.blocklyText.arduino.readSimilation": "ler pino analógico [PIN]",
    "gui.blocklyText.arduino.setPwm": "pino PWM [PIN] saída [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "Ler distância ultrassônica [TRIG] [ECHO] (cm)",
    "gui.blocklyText.arduino.trumpet": "pino [PIN] tocar tom de trombeta [TONE] por [BEAT] batida",
    "gui.blocklyText.arduino.serialSendStr": "saída serial [STRING] [WAY], [LINE]",
    "gui.blocklyText.arduino.originalOutput": "em origem",
    "gui.blocklyText.arduino.stringOutput": "em string",
    "gui.blocklyText.arduino.hexOutput": "em HEX",
    "gui.blocklyText.arduino.wrap": "Embrulhar",
    "gui.blocklyText.arduino.noWrap": "Sem embrulho",
    "gui.blocklyText.arduino.readInfrared": "ler pino digital [PIN] do receptor infravermelho",
    "gui.blocklyText.arduino.setBaud": "definir taxa de transmissão da porta serial [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "ler padrão de largura de pulso do pino [PIN] [LEVEL] tempo limite [TIMEOUT] (us)",
    "gui.blocklyText.arduino.noSound": "sem som",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Arduino Nano começa",
    "gui.blocklyText.vortex.vortexStart": "Vortex robot programa começa",
    "gui.blocklyText.vortex.vortexMoveSpeed": "robô vortex [DIRECTION] com velocidade [SPEED]",
    "gui.blocklyText.vortex.forward": "mover para a frente",
    "gui.blocklyText.vortex.backup": "mover para trás",
    "gui.blocklyText.vortex.turnLeft": "virar à esquerda",
    "gui.blocklyText.vortex.turnRight": "virar à direita",
    "gui.blocklyText.vortex.setMotor": "motor [MOTOR] [DIRECTION] com velocidade [SPEED]",
    "gui.blocklyText.vortex.leftWheel": "esquerda",
    "gui.blocklyText.vortex.rightWheel": "direita",
    "gui.blocklyText.vortex.setExpression": "mostrar padrão [EXPRESSION] em [COLOR]",
    "gui.blocklyText.vortex.ledLights": "[DIRECTION][LIGHT] mudar para [COLOR] em [SECOND] segs.",
    "gui.blocklyText.vortex.all": "todos",
    "gui.blocklyText.vortex.rightAhead": "frente",
    "gui.blocklyText.vortex.leftRear": "traseira esquerda",
    "gui.blocklyText.vortex.rightRear": "traseira direita",
    "gui.blocklyText.vortex.rightAstern": "traseira",
    "gui.blocklyText.vortex.topLights": "luzes superiores",
    "gui.blocklyText.vortex.bottomLights": "luzes inferiores",
    "gui.blocklyText.vortex.vortexStop": "Vortex para de se mover",
    "gui.blocklyText.vortex.setVolume": "definir volume para [VALUE]",
    "gui.blocklyText.vortex.playMusic": "tocar a música [VALUE]",
    "gui.blocklyText.vortex.stopMusic": "parar de tocar música",
    "gui.blocklyText.vortex.startDance": "iniciar a dança [VALUE]",
    "gui.blocklyText.vortex.stopDance": "parar de dançar",
    "gui.blocklyText.vortex.barrierDetection": "obstáculo à frente?",
    "gui.blocklyText.vortex.readSensor": "ler sensor Grayscale [SENSOR]",
    "gui.blocklyText.vortex.setSensor": "definir sensibilidade do sensor Grayscale para [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "o sensor Grayscale encontra preto em sua posição [SENSOR]?",
    "gui.blocklyText.vortex.positiveFrontLeft": "frente esquerda reta",
    "gui.blocklyText.vortex.frontLeft": "frente esquerda",
    "gui.blocklyText.vortex.positiveFrontRight": "frente direita",
    "gui.blocklyText.vortex.frontRight": "frente direita",
    "gui.blocklyText.vortex.backRight": "traseira direita",
    "gui.blocklyText.vortex.backLeft": "traseira esquerda",
    "gui.blocklyText.vortex.initialOldPCB": "inicializar para a versão antiga (placa de circuito impresso azul)",
    "gui.blocklyText.microbitV2.logo": "LOGO(V2)",
    "gui.blocklyText.microbitV2.ls": "LS(V2)",
    "gui.blocklyText.microbit.microbitStarts": "micro:bit inicia",
    "gui.blocklyText.microbit.interruptExcute": "modo de pino de interrupção [MODE] executar pino [PIN]",
    "gui.blocklyText.microbit.cancleInterrupt": "cancelar interrupção de pino [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "quando receber dados sem fio",
    "gui.blocklyText.microbit.whenBtnPress": "quando o botão [REFERENCE] for pressionado",
    "gui.blocklyText.microbit.whenPinConnected": "quando o pino do micro:bit [REFERENCE] estiver conectado",
    "gui.blocklyText.microbit.whenPosChange": "quando [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "exibir padrão [PIC]",
    "gui.blocklyText.microbit.showInput": "exibir [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "parar animação",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] coordenadas do ponto (x:[XAXIS], y:[YXAXIS])",
    "gui.blocklyText.microbit.hideAllLights": "limpar todas as matrizes de pontos",
    "gui.blocklyText.microbit.microbitEnableLight": "Tela de ponto [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "brilho",
    "gui.blocklyText.microbit.showLightWithBrightness": "definir brilho [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "mostrar coordenadas do ponto (x:[XAXIS], y:[YXAXIS]), brilho [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "pino [PIN] reproduzir som [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "pino [PIN] reproduzir som [SOUND] até o fim",
    "gui.blocklyText.microbit.playNote": "pino [PIN] reproduzir nota [NOTE] por [BEAT] batida",
    "gui.blocklyText.microbit.true": "ativar",
    "gui.blocklyText.microbit.false": "desativar",
    "gui.blocklyText.maqueen.playSound": "pino P0 reproduzir som [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "pino P0 reproduzir som [SOUND] até o fim",
    "gui.blocklyText.maqueen.playNote": "pino P0 reproduzir nota [NOTE] por [BEAT] batida",
    "gui.blocklyText.maqueen.RGB0": "RGB0 (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1 (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2 (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3 (3)",
    "gui.blocklyText.maqueen.All": "Todos (-1)",
    "gui.blocklyText.microbit.changeTempo": "alterar o tempo (bpm) por [VALUE]",
    "gui.blocklyText.microbit.setTempo": "definir o tempo (bpm) para [VALUE]",
    "gui.blocklyText.microbit.getTempo": "tempo (bpm)",
    "gui.blocklyText.microbit.stopSound": "parar reprodução em segundo plano",
    "gui.blocklyText.microbit.btnIsPressed": "botão [BUTTON] pressionado?",
    "gui.blocklyText.microbit.pinIsConnected": "pino [PIN] conectado?",
    "gui.blocklyText.microbit.isMove": "estado atual [TYPE]?",
    "gui.blocklyText.microbit.compass": "ler orientação da bússola",
    "gui.blocklyText.microbit.temperature": "ler temperatura",
    "gui.blocklyText.microbit.digitalWrite": "pino digital [PIN] saída [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "ler pino digital [PIN]",
    "gui.blocklyText.microbit.analogWrite": "pino analógico [PIN] saída [VALUE]",
    "gui.blocklyText.microbit.analogRead": "ler pino analógico [PIN]",
    "gui.blocklyText.microbit.up": "logo para cima",
    "gui.blocklyText.microbit.down": "logo para baixo",
    "gui.blocklyText.microbit.left": "inclinado para a esquerda",
    "gui.blocklyText.microbit.right": "inclinado para a direita",
    "gui.blocklyText.microbit.faceUp": "para cima",
    "gui.blocklyText.microbit.faceDown": "para baixo",
    "gui.blocklyText.microbit.freefall": "queda livre",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "agitar",
    "gui.blocklyText.microbit.show": "mostrar",
    "gui.blocklyText.microbit.start": "abrir",
    "gui.blocklyText.microbit.stop": "fechar",
    "gui.blocklyText.microbit.hide": "ocultar",
    "gui.blocklyText.microbit.low": "BAIXO",
    "gui.blocklyText.microbit.high": "ALTO",
    "gui.blocklyText.microbit.microbitReadBrightness": "ler brilho da luz ambiente",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] comunicação sem fio",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "definir canal sem fio para [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "enviar texto [TEXT] via sem fio",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "receber dados via sem fio",
    "gui.blocklyText.microbit.strength": "força",
    "gui.blocklyText.microbit.acceleration": "ler aceleração (m-g)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "ler força magnética (µT)[TYPE]",
    "gui.blocklyText.microbit.microbitAccurateCompass": "bússola precisa",
    "gui.blocklyText.microbit.microbitGetSoundLevel": "obter nível de som (V2)",
    "gui.blocklyText.microbit.print": "Imprimir [DATA]",
    "gui.blocklyText.microbit.goUp": "cima",
    "gui.blocklyText.microbit.goDown": "baixo",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "quando receber [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "dados sem fio",
    "gui.blocklyText.calliope.whenBtnPress": "quando botão [REFERENCE] pressionado",
    "gui.blocklyText.calliope.calliopeReadBrightness": "ler brilho",
    "gui.blocklyText.calliope.playNote": "[PIN] tocar nota [NOTE] por [BEAT] batida",
    "gui.blocklyText.calliope.playSoundUntil": "[PIN] reproduzir som [SOUND] até terminar",
    "gui.blocklyText.calliope.playSound": "[PIN] reproduzir som [SOUND]",
    "gui.blocklyText.calliope.calliopeEnableLight": "tela [ENABLE]",
    "gui.blocklyText.calliope.hideAllLights": "limpar tela",
    "gui.blocklyText.calliope.whenPinConnected": "quando pino [REFERENCE] conectado",
    "gui.blocklyText.calliope.loudness": "ler volume",
    "gui.blocklyText.calliope.rotation": "ler ângulo de rotação (°) [TYPE]",
    "gui.blocklyText.calliope.calliopeControlSinglelMotor": "controle único do motor [RANGE]%",
    "gui.blocklyText.calliope.calliopeSingleMotorStop": "parar motor [STOPTYPE]",
    "gui.blocklyText.calliope.calliopeDoubleMotor": "controle de motor [MOTORTYPE] [RANGE]%",
    "gui.blocklyText.calliope.calliopeDoubleMotorStop": "parar motor [MOTORTYPE]",
    "gui.blocklyText.calliope.calliopeLight": "definir led RGB [COLOR]",
    "gui.blocklyText.calliope.setRGBColor": "vermelho [RED] verde [GREEN] azul [BLUE] branco [WHITE]",
    "gui.blocklyText.calliope.calliopeCloseRGB": "desligar led RGB",
    "gui.blocklyText.calliope.pitch": "arremesso",
    "gui.blocklyText.calliope.roll": "rolo",
    "gui.blocklyText.calliope.boardbuzzer": "alto-falante",
    "gui.blocklyText.calliope.motorbreak": "quebrar",
    "gui.blocklyText.calliope.motorcoast": "costa",
    "gui.blocklyText.calliope.motorsleep": "dormir",
    "gui.blocklyText.calliope.open": "ligar",
    "gui.blocklyText.calliope.close": "desligar",
    "gui.blocklyText.bos0001.readRotary": "ler valor do módulo de botão [PIN]",
    "gui.blocklyText.bos0002.IsPINPress": "botão [PIN] pressionado?",
    "gui.blocklyText.bos0003.IsPINPress": "interruptor de trava automática [PIN] pressionado?",
    "gui.blocklyText.bos0004.readLight": "ler valor do módulo de luz [PIN]",
    "gui.blocklyText.bos0006.readMoisture": "ler valor do módulo de umidade [PIN]",
    "gui.blocklyText.bos0007.readFlame": "ler valor do módulo de chama [PIN]",
    "gui.blocklyText.bos0008.IsPINTouch": "[PIN] toque para conectar?",
    "gui.blocklyText.bos0009.readSound": "ler valor do módulo de som [PIN]",
    "gui.blocklyText.bos0010.readSmartGray": "ler valor do módulo cinza [PIN]",
    "gui.blocklyText.bos0012.IsConductivitySwitch": "[PIN] módulo de condutividade ligado?",
    "gui.blocklyText.bos0013.IsMotionSensor": "[PIN] gatilho do módulo de movimento?",
    "gui.blocklyText.bos0038.readSoilHumidity": "ler valor do módulo de umidade do solo [PIN]",
    "gui.blocklyText.bos0045.readUltrasonicDistance": "ler valor do módulo de ultrassom [PIN]",
    "gui.blocklyText.bos0016.lightBrightLED": "[CONTROL][PIN] destacar luz LED",
    "gui.blocklyText.bos0017.LEDModule": "[CONTROL][PIN] luz LED",
    "gui.blocklyText.bos0019.LEDStringLight": "[CONTROL][PIN] faixa de luz de cor fixa",
    "gui.blocklyText.bos0021.fanModule": "[CONTROL][PIN] ventilador",
    "gui.blocklyText.bos0022.voiceRecorderModule": "[PIN] para reproduzir uma gravação",
    "gui.blocklyText.maxbot.microbitStarts": "Início do Maxbot",
    "gui.blocklyText.maxbot.playSound": "reproduzir som [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "reproduzir som [SOUND] até terminar",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "colisão ocorreu à [LEFTRIGHT]?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "linha preta detectada à [LEFTRIGHT]?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "motor [MOTOR] [DIR] na velocidade [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "ler (P1,P2) sensor ultrasônico [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "ler sensor ultrasônico unidade [UNIT], trig [TRIG], eco [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "PARA FRENTE",
    "gui.blocklyText.maxbot.BACKWARD": "PARA TRÁS",
    "gui.blocklyText.motorbit.CW": "CW ",
    "gui.blocklyText.motorbit.CCW": "CCW ",
    "gui.blocklyText.maxbot.CW": "CW ",
    "gui.blocklyText.maxbot.CCW": "CCW ",
    "gui.blocklyText.maxbot.ANGLE": "graus",
    "gui.blocklyText.maxbot.CIRCLE": "revoluções",
    "gui.blocklyText.maxbot.Left": "esquerda",
    "gui.blocklyText.maxbot.Right": "direita",
    "gui.blocklyText.maxbot.cm": "cm",
    "gui.blocklyText.maxbot.inch": "polegada",
    "gui.blocklyText.MotorBit.microbitStarts": "Início do MotorBit",
    "gui.blocklyText.MotorBit.MotorRun": "motor [MOTOR] rotacionar [DIR] na velocidade [SPEED]",
    "gui.blocklyText.MotorBit.MotorStop": "motor [MOTOR] parar",
    "gui.blocklyText.MotorBit.Stepper": "motor de passo [STEPPER] rotacionar [ROTATEDIR] por [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.pythondfr0548.MotorRun": "motor [MOTOR] rotacionar [DIR] na velocidade [SPEED]",
    "gui.blocklyText.pythondfr0548.MotorStop": "motor [MOTOR] parar",
    "gui.blocklyText.pythondfr0548.Stepper": "motor de passo [STEPPER] rotacionar [ROTATEDIR] por [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "TODOS",
    "gui.blocklyText.maqueen.maqueenStarts": "Início do Maqueen",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "ler (P1,P2) sensor ultrassônico (cm)",
    "gui.blocklyText.maqueen.motorStop": "robô (motores esquerdo e direito) para",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] luz LED",
    "gui.blocklyText.maqueen.redLineSensor": "ler sensor de rastreamento de linha [LEFTRIGHT]",
    "gui.blocklyText.maqueen.robotSpeedMove": "robô [DIR] na velocidade [SPEED]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "motor [LEFTRIGHT] move [DIR] na velocidade [SPEED]",
    "gui.blocklyText.maqueen.ledLeft": "Esquerda (P8)",
    "gui.blocklyText.maqueen.ledRight": "Direita (P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "Direita (P12) (não disponível)",
    "gui.blocklyText.maqueen.lineSensorLeft": "Esquerda (P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "Direita (P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "quando (P16) receber [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "sinal infravermelho",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "ler sinal infravermelho (P16)",
    "gui.blocklyText.maqueen.numberOfLightsBright": "pino P15 4 LEDs RGB brilho [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "pino P15 o LED [NUM1] exibe cor [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "pino P15 LED [NUM1] para [NUM2] mostrar cor degradê de [COLOR1] para [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "pino P15 deslocar pixels por [NUM]",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "pino P15 rotacionar pixels por [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "pino P15 brilho do LED [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "pino P15 LED [NUM1] para [NUM2] mostrar histograma, valor [VALUE], máximo [MAX]",
    "gui.blocklyText.maqueen.clear": "pino P15 limpar todos os LEDs",
    "gui.blocklyText.maqueen.rgbColor": "vermelho [RED] verde [GREEN] azul [BLUE]",
    "gui.blocklyText.tello.telloStarts": "Início do Tello",
    "gui.blocklyText.tello.telloControlPlane": "controlar avião",
    "gui.blocklyText.tello.telloTakeOff": "decolar",
    "gui.blocklyText.tello.telloLanding": "pousar",
    "gui.blocklyText.tello.telloRise": "voar para cima [NUMS]cm",
    "gui.blocklyText.tello.telloDown": "voar para baixo [NUMS]cm",
    "gui.blocklyText.tello.telloGoahead": "voar para frente [NUMS]cm",
    "gui.blocklyText.tello.telloBack": "voar para trás [NUMS]cm",
    "gui.blocklyText.tello.telloTurnLeft": "voar para a esquerda [NUMS]cm",
    "gui.blocklyText.tello.telloTurnRight": "voar para a direita [NUMS]cm",
    "gui.blocklyText.tello.telloClockwiseRotation": "rotacionar [NUMS] graus no sentido horário",
    "gui.blocklyText.tello.telloCounterclockwiseSelection": "rotacionar [NUMS] graus no sentido anti-horário",
    "gui.blocklyText.tello.telloSpeed": "definir velocidade para [NUMS]cm/s",
    "gui.blocklyText.tello.telloRoll": "girar [ROLL]",
    "gui.blocklyText.tello.telloPower": "energia?",
    "gui.blocklyText.tello.telloRollForward": "para frente (f)",
    "gui.blocklyText.tello.telloRollBack": "para trás (b)",
    "gui.blocklyText.tello.telloRollLeft": "para a esquerda (l)",
    "gui.blocklyText.tello.telloRollRight": "para a direita (r)",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] recebeu dados?",
    "gui.blocklyText.obloq.initialParameter": "obloq mqtt inicialização: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.initialI2CParameter": "Parâmetro inicial obloq mqtt [PARAMETER] interface I2C endereço 0x16",
    "gui.blocklyText.obloq.httpInitialI2CParameter": "Parâmetro inicial obloq http [PARAMETER] interface I2C endereço 0x16",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq enviar mensagem [MSG] para plataforma de nuvem [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq ler a mensagem de [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq ler a mensagem",
    "gui.blocklyText.obloq.httpInitialParameter": "obloq http inicialização: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] tempo limite (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL] conteúdo: [CONTENT] tempo limite (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] conteúdo: [CONTENT] tempo limite (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "adicionar inscrição [TOPIC]: [MSG]",
    "gui.blocklyText.obloq.username": "Nome do Wi-Fi",
    "gui.blocklyText.obloq.password": "senha",
    "gui.blocklyText.obloq.iotService": "Serviço IOT",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Tópico_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "Endereço IP",
    "gui.blocklyText.obloq.port": "Porta",
    "gui.blocklyText.obloq.addTopic": "Adicionar Tópico, apenas adicione até 4",
    "gui.blocklyText.obloq.deleteTopic": "Excluir Tópico",
    "gui.blocklyText.obloq.moreTopic": "Tópico",
    "gui.blocklyText.obloq.server": "Servidor",
    "gui.blocklyText.obloq.global": "Global",
    "gui.blocklyText.obloq.china": "China",
    "gui.blocklyText.obloq.softwareSerial": "Software Serial",
    "gui.blocklyText.obloq.hardwareSerial": "Hardware Serial",
    "gui.blocklyText.obloq.hardwareSerial1": "Hardware Serial1",
    "gui.blocklyText.obloq.hardwareSerial2": "Hardware Serial2",
    "gui.blocklyText.obloq.hardwareSerial3": "Hardware Serial3",
    "gui.blocklyText.obloq.WhenReceivedData": "quando [TOPIC] recebeu [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "mensagem Obloq",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq obter [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "Informações de depuração",
    "gui.blocklyText.obloq.ERROR": "Código de erro",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "emissão infravermelha [TYPE] pino [PIN] valor [VALUE] bit [BIT]",
    "gui.blocklyText.infraredEmission.send": "Enviar sinal infravermelho pino [PIN] Enviar mensagem: endereço [ADDRESS] comando [COMMAND]",
    "gui.blocklyText.oled2864.displayInit": "OLED inicializar endereço [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 exibir [TEXT] na linha [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 exibir [TEXT] na posição X: [X] Y: 16*[Y]",
    "gui.blocklyText.oled2864.screenRotation": "rotacionar tela para [ROTATION] graus",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 limpar",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "ler pino [PIN] [DHT] [TYPE]",
    "gui.blocklyText.mpythonMpyPm.readLevel": "pino rx [PIN] valor de concentração de PM [LEVEL]",
    "gui.blocklyText.hcsr04.init": "Iniciar inicialização do ultrassom HC-SR04 Nome [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] unidade de medida de distância ultrassônica HC-SR04 [UNIT]",
    "gui.blocklyText.hcsr04.readData": "ler HC-SRO4 Trig [PINT] Echo [PINE] unidade [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "pino rx [PIN] 0,1 litros de ar no número de partículas [DIAMETER]",
    "gui.blocklyText.sen0170.readWindspeed": "ler pino [PIN] escala de vento",
    "gui.blocklyText.sen0228.init": "Inicializar sensor de luz ambiente (VEML7700) I2C endereço 0x10",
    "gui.blocklyText.sen0228.readAmbientLight": "ler Luz Ambiente(VEML7700)",
    "gui.blocklyText.pythonsen0228.init": "Inicializar sensor de luz ambiente (VEML7700) [NAME] I2C endereço 0x10",
    "gui.blocklyText.pythonsen0228.readAmbientLight": "ler [NAME] Luz Ambiente(VEML7700)",
    "gui.blocklyText.sen0097.init": "Inicializar sensor de luz ambiente (BH1750) I2C endereço 0x23",
    "gui.blocklyText.sen0097.readAmbientLight": "ler Luz Ambiente(BH1750)",
    "gui.blocklyText.dfr0026.readAmbientLight": "ler pino [PIN] luz ambiente",
    "gui.blocklyText.sen0177.readDustSensor": "ler [TYPE] (ug/m3) interface [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "pino (D) [PIN] pino (MCLR) [RST] gesto atual [TYPE]?",
    "gui.blocklyText.sen0203.readHeartRate": "ler pino [PIN] [TYPE] mudar para [SWITCH]",
    "gui.blocklyText.pythonsen0203.init": "inicializar sensor de frequência cardíaca [NAME] pino [PIN] modo [MODE]",
    "gui.blocklyText.pythonsen0203.update": "[NAME] obter dados uma vez",
    "gui.blocklyText.pythonsen0203.readHeartRate": "[NAME] analisar frequência cardíaca a partir dos dados (vezes por minuto)",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] pino [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "ler pino [PIN] escala de cinza",
    "gui.blocklyText.motucamera.initIICSerial": "mu câmera init [MU] interface [IICSERIAL] até inicializar com sucesso endereço I2C 0x60&0x61&0x62&0x63",
    "gui.blocklyText.motucamera.resetConfig": "mu câmera [MU] redefinir para o padrão",
    "gui.blocklyText.motucamera.algorithmSwitch": "mu câmera [MU] [SWITCH] algoritmo [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "mu câmera [MU] [LED] quando detectar [COLOR1] quando não detectar [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "mu câmera [MU] definir algoritmo [ALGORITHM] nível [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "mu câmera [MU] zoom digital [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "mu câmera [MU] definir taxa de transmissão [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "mu câmera [MU] definir balanço de branco [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "mu câmera [MU] modo de alta FPS [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "mu câmera [MU] está detectando [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": "mu câmera [MU] está detectando reconhecimento de cor x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "mu câmera [MU] está detectando cor detectar cor = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "mu câmera obter [MU] algoritmo [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "mu câmera [MU] reconhecimento de cor [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "mu câmera [MU] cartão de forma [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "mu câmera [MU] cartão de tráfego [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "mu câmera [MU] cartão de número [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "mu câmera [MU] reconhecimento de cor cor = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "Detectar Cor",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "Reconhecer Cor",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "Detectar Bola",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "Detectar Corpo",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "Forma de Cartão",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "Cartão de Tráfego",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "Cartão de Número",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "habilitar",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "desabilitar",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "desligado",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "vermelho",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "verde",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "azul",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "amarelo",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "roxo",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "ciano",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "branco",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "desligado",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "vermelho",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "verde",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "azul",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "amarelo",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "roxo",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "ciano",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "branco",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "Automático",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "Velocidade",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "Equilíbrio",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "Precisão",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "Automático",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "Travar",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "branco",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "amarelo",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "Automático",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "Nível1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "Nível2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "Nível3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "Nível4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "Nível5",
    "gui.blocklyText.motucamera.setHighFPSOff": "DESLIGADO",
    "gui.blocklyText.motucamera.setHighFPSOn": "LIGADO",
    "gui.blocklyText.motucamera.colorDetectBlack": "preto",
    "gui.blocklyText.motucamera.colorDetectWhite": "branco",
    "gui.blocklyText.motucamera.colorDetectRed": "vermelho",
    "gui.blocklyText.motucamera.colorDetectYellow": "amarelo",
    "gui.blocklyText.motucamera.colorDetectGreem": "verde",
    "gui.blocklyText.motucamera.colorDetectCayan": "ciano",
    "gui.blocklyText.motucamera.colorDetectBlue": "azul",
    "gui.blocklyText.motucamera.colorDetectPerple": "roxo",
    "gui.blocklyText.motucamera.colorDetectOthers": "outros",
    "gui.blocklyText.motucamera.colorDetectPositionX": "posição X",
    "gui.blocklyText.motucamera.colorDetectPositionY": "posição Y",
    "gui.blocklyText.motucamera.colorDetectPositionW": "largura",
    "gui.blocklyText.motucamera.colorDetectPositionH": "altura",
    "gui.blocklyText.motucamera.colorDetectPositionL": "rótulo",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "canal vermelho",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "canal verde",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "canal azul",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "canal do rótulo",
    "gui.blocklyText.motucamera.shapeCardTick": "marca de seleção",
    "gui.blocklyText.motucamera.shapeCardCross": "cruz",
    "gui.blocklyText.motucamera.shapeCardCircle": "círculo",
    "gui.blocklyText.motucamera.shapeCardSquare": "quadrado",
    "gui.blocklyText.motucamera.shapeCardTriangle": "triângulo",
    "gui.blocklyText.motucamera.trafficCardForward": "Para Frente",
    "gui.blocklyText.motucamera.trafficCardLeft": "Para a Esquerda",
    "gui.blocklyText.motucamera.trafficCardRight": "Para a Direita",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "Dê a Volta",
    "gui.blocklyText.motucamera.trafficCardStop": "Parar",
    "gui.blocklyText.motucamera.lightSensorUse": "mu câmera [MU] sensor de luz habilitar [LIGHTFUNC] detecção",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "mu câmera [MU] sensibilidade do sensor de luz [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "mu câmera [MU] ler detecção de proximidade do sensor de luz",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "mu câmera [MU] ler detecção de luz ambiente do sensor de luz",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "mu câmera [MU] ler uma vez gesto e salvar",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "mu câmera [MU] detecção de gesto =  [GESTURE]",
    "gui.blocklyText.motucamera.upward": "para cima",
    "gui.blocklyText.motucamera.downward": "para baixo",
    "gui.blocklyText.motucamera.leftward": "esquerda",
    "gui.blocklyText.motucamera.rightward": "direita",
    "gui.blocklyText.motucamera.pull": "puxar",
    "gui.blocklyText.motucamera.push": "empurrar",
    "gui.blocklyText.motucamera.none": "nenhum",
    "gui.blocklyText.motucamera.default": "padrão",
    "gui.blocklyText.motucamera.low": "baixo",
    "gui.blocklyText.motucamera.middle": "meio",
    "gui.blocklyText.motucamera.high": "alto",
    "gui.blocklyText.motucamera.proximity": "proximidade",
    "gui.blocklyText.motucamera.ambientLight": "luz ambiente",
    "gui.blocklyText.motucamera.gesture": "gesto",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi inicializar porta [SERIALPORT] RX[RX] TX[TX] UART taxa de transmissão [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi definir ssid [SSID] senha [PASSWORD] modo [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi conexão bem-sucedida?",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi desconectar",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi definir IP de destino [IP] porta [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi ler IP de destino",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi ler IP local",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi ler",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi Escrever [WRITE]",
    "gui.blocklyText.dfr0552.init": "módulo DA inicializar endereço I2C [ADDR] referência de voltagem [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "módulo DA saída de onda triangular amplitude [AMPLITUDE]mV freq [FREQ]Hz viés DC [DCBIAS]mV duty [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "módulo DA saída de onda senoidal amplitude [AMPLITUDE]mV freq [FREQ]Hz viés DC [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "módulo DA saída de voltagem [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "módulo DA memória voltagem [VOLTAGE]mV",
    "gui.blocklyText.pythonsen0334.SHT31Init": "SHT31 Inicializar [ADDRESS]",
    "gui.blocklyText.pythonsen0334.SHT31GetData": "ler dados SHT31 [DATA]",
    "gui.blocklyText.pythonsen0334.TemperC": "TemperaturaC",
    "gui.blocklyText.pythonsen0334.TemperF": "TemperaturaF",
    "gui.blocklyText.pythonsen0334.HumidRH": "UmidadeRH",
    "gui.blocklyText.pythonsen0334.Number": "Número",
    "gui.blocklyText.pythonsen0460.begin": "Inicializar dispositivo até o sucesso, definir o endereço I2C 0x19",
    "gui.blocklyText.pythonsen0460.readConcentration": "ler concentração [KAIGUAN] em [ENVIRONMENT] (ug/m3)",
    "gui.blocklyText.pythonsen0460.readCount": "ler o número de partículas com tamanho de [PARTICLEDIAMETER] em 0,1L de volume de ar",
    "gui.blocklyText.pythonsen0460.readDeviceVersion": "obter versão",
    "gui.blocklyText.pythonsen0460.PM2_5": "PM2.5",
    "gui.blocklyText.pythonsen0460.PM1_0": "PM1.0",
    "gui.blocklyText.pythonsen0460.PM10": "PM10",
    "gui.blocklyText.pythonsen0460.standard": "partículas padrão",
    "gui.blocklyText.pythonsen0460.atmospheric": "ambiente atmosférico",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_0_3": "0,3µm",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_0_5": "0,5µm",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_1_0": "1,0µm",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_2_5": "2,5µm",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_5_0": "5,0µm",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_10": "10µm",
    "gui.blocklyText.pythonsen0303.VEML6075Init": "Inicializar sensor UV VEML6075 aguarde até o sucesso",
    "gui.blocklyText.pythonsen0303.VEML6075ReadyUV": "Ler força VEML6075 [UV]",
    "gui.blocklyText.pythonsen0303.VEML6075Ready": "Ler Índice UV VEML6075",
    "gui.blocklyText.pythonsen0303.VEML6075ReadyME": "Ler Emissão de Radiação VEML6075 Mw/cm2",
    "gui.blocklyText.pythonsen0303.UVA": "UV de onda longa (UVA)",
    "gui.blocklyText.pythonsen0303.UVB": "UV de onda média (UVB)",
    "gui.blocklyText.pythonsen0377.MEMSgas_Iint": "MEMSgas começar A0[A0] A1[A1]",
    "gui.blocklyText.pythonsen0377.MEMSgaswarm_up_time": "MEMSgas tempo de aquecimento [MIN] minuto",
    "gui.blocklyText.pythonsen0377.MEMSgas_get_power_mode": "MEMSgas obter modo de alimentação ?",
    "gui.blocklyText.pythonsen0377.MEMSgasset_mode": "MEMSgas definir modo [MODE]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_adc_data": "MEMSgas obter dados adc [DATA]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_gas_exist": "MEMSgas obter existência de gás [TYPE]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_gas_ppm": "MEMSgas obter gás ppm [TYPE]",
    "gui.blocklyText.pythonsen0377.wakeup_mode": "modo de despertar",
    "gui.blocklyText.pythonsen0377.sleep_mode": "modo de dormir",
    "gui.blocklyText.pythonsen0377.OX_MODE": "OX_MODE",
    "gui.blocklyText.pythonsen0377.RED_MODE": "RED_MODE",
    "gui.blocklyText.pythonsen0377.CO": "Monóxido de Carbono",
    "gui.blocklyText.pythonsen0377.CH4": "Metano",
    "gui.blocklyText.pythonsen0377.C2H5OH": "Etanol",
    "gui.blocklyText.pythonsen0377.C3H8": "Propano",
    "gui.blocklyText.pythonsen0377.C4H10": "Iso Butano",
    "gui.blocklyText.pythonsen0377.H2": "Hidrogênio",
    "gui.blocklyText.pythonsen0377.H2S": "Hidrotion",
    "gui.blocklyText.pythonsen0377.NH3": "Amônia",
    "gui.blocklyText.pythonsen0377.NO": "Óxido Nítrico",
    "gui.blocklyText.pythonsen0377.NO2": "Dióxido de Nitrogênio",
    "gui.blocklyText.sen0117.init": "Inicializar módulo interface [SERIALPORT] Rx[RX] Tx[TX] Busy[BUSY]",
    "gui.blocklyText.sen0117.playText": "Reproduzir texto [TEXT] música de fundo [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "Reproduzir prompt de voz [INDEX] esperando [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "Reproduzir prompts de acordes [INDEX] esperando [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "Configurar o [INDEX] para [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "Configurar a taxa de síntese de fala [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "Obter [TYPE] do módulo de síntese de fala",
    "gui.blocklyText.sen0117.Prospects": "Volume de perspectivas",
    "gui.blocklyText.sen0117.Background": "Volume de fundo",
    "gui.blocklyText.sen0117.Speed": "Velocidade",
    "gui.blocklyText.sen0117.playChordCueTone": "Reproduzir tons de acordes [INDEX] esperando [WAIT]",
    "gui.blocklyText.sen0117.No": "Não (0)",
    "gui.blocklyText.sen0117.setPlayMode": "Definir o modo de reprodução para [MODE]",
    "gui.blocklyText.sen0117.pause": "Pausar",
    "gui.blocklyText.sen0117.resume": "Continuar",
    "gui.blocklyText.sen0117.stop": "Parar",
    "gui.blocklyText.sen0117.isIdle": "Reprodução concluída?",
    "gui.blocklyText.dfr0576.selectPort": "Selecionar porta [PORT]",
    "gui.blocklyText.dfr0576.begin": "Inicializar o módulo de cascata I2C com endereço [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680 inicializa o endereço I2C 0x77",
    "gui.blocklyText.sen0248.readBME680": "Obter o sensor BME680 [TYPE]",
    "gui.blocklyText.pythonsen0248.bme680Init": "BME680 inicializa o endereço I2C 0x77",
    "gui.blocklyText.pythonsen0248.updateBme680": "obter dados do sensor BME680 uma vez",
    "gui.blocklyText.pythonsen0248.readBME680": "Analisar [TYPE] dos dados",
    "gui.blocklyText.sen0248.Temperature": "Temperatura (°C)",
    "gui.blocklyText.sen0248.Pressure": "Pressão (Pa)",
    "gui.blocklyText.sen0248.Humidity": "Umidade (%rh)",
    "gui.blocklyText.sen0248.Altitude": "Altitude (m)",
    "gui.blocklyText.sen0248.GasResistance": "Resistência de Gás (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "inicializar o endereço do sensor ultrassônico I2C [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "Alterar o endereço do sensor ultrassônico I2C [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "Ler distância do sensor ultrassônico (cm)",
    "gui.blocklyText.sen0304.readTemperature": "Ler temperatura do sensor ultrassônico (°C)",
    "gui.blocklyText.pythonsen0304.initialIICAddr": "inicializar o I2C sensor ultrassônico [NAME] endereço [ADDR]",
    "gui.blocklyText.pythonsen0304.changeI2CAddr": "Alterar o endereço do sensor ultrassônico [NAME] I2C [ADDR] (Reinicialização eficaz com desligamento)",
    "gui.blocklyText.pythonsen0304.readDistance": "Ler distância do sensor ultrassônico [NAME] (cm)",
    "gui.blocklyText.pythonsen0304.readTemperature": "Ler temperatura do sensor ultrassônico [NAME] (°C)",
    "gui.blocklyText.sen0307.readDistance": "Ler onda ultrassônica analógica do pino [PIN] (cm)",
    "gui.blocklyText.pythonsen0307.init": "analog ultrassônico [NAME] inicial [PIN]",
    "gui.blocklyText.pythonsen0307.readDistance": "[NAME] Ler onda ultrassônica analógica (cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 inicialização endereço [ADDR] modo [MODE]",
    "gui.blocklyText.sen0250.Step": "Passo",
    "gui.blocklyText.sen0250.Measure": "Medida",
    "gui.blocklyText.sen0250.readAccelerated": "Obter a aceleração do eixo [AXIS] (g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 obter número de passos",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "Giroscópio",
    "gui.blocklyText.sen0250.Acc": "Aceleração (g)",
    "gui.blocklyText.sen0224.init": "Inicializar o sensor LIS2DH I2C endereço 0x18",
    "gui.blocklyText.sen0224.readAccelerated": "Ler valor de aceleração do sensor LIS2DH [AXIS] axis (mg)",
    "gui.blocklyText.dfr0023.readLM35": "ler pino [PIN] temperatura do LM35 (?)",
    "gui.blocklyText.dfr0027.whenVibration": "quando o pino [PIN] detectar vibração",
    "gui.blocklyText.dfr0028.readTiltSensor": "pino [PIN] detectou inclinação?",
    "gui.blocklyText.dfr0029.readPress": "pino [PIN] botão pressionado?",
    "gui.blocklyText.dfr0030.readTouch": "pino [PIN] detectou toque?",
    "gui.blocklyText.dfr0033.readMagnetic": "pino [PIN] detectou ímã?",
    "gui.blocklyText.dfr0034.readSound": "ler pino [PIN] Loudness",
    "gui.blocklyText.dfr0094.readIRReceived": "ler pino [PIN] dados IR",
    "gui.blocklyText.dfr0094.onReceiveEvent": "Quando o sinal infravermelho é recebido Pino [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "endereço",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "comando",
    "gui.blocklyText.midi.midiInit": "Iniciar módulo MIDI interface [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.midi.midiOutput": "Canal de saída MIDI [CHANNEL] tom [PITCH] velocidade [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "ler pino [PIN] sensor de monóxido de carbono (CO)",
    "gui.blocklyText.dfr0051.readVoltage": "ler pino [PIN] Tensão (V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "ler pino [PIN] Sensor de Vibração de Disco Piezo",
    "gui.blocklyText.dfr0058.readRotation": "ler pino [PIN] Sensor de Rotação",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "ler pino [PIN] Joystick Eixo [AXIS]",
    "gui.blocklyText.dfr0061.readJoyStickButton": "pino [PIN] Botão Joystick(Z) pressionado?",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut inicializar pino de dados [DATAPIN] pino RTC [RTCPIN] pino cls [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "Tubo digital exibir código de segmento 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "Tubo digital exibir número [NUM]",
    "gui.blocklyText.dfr0072.dfr0072Dot": "Tubo digital exibir ponto [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "true",
    "gui.blocklyText.dfr0072.numOff": "desligado",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "Ler bateria de lítio [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "voltagem (mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "bateria restante (%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD módulo inicializar endereço I2C [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD módulo ler voltagem [PIN] (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD módulo obter diferença de voltagem no corredor [AISLE]",
    "gui.blocklyText.pythondfr0553.dfr0553InitIICAddr": "AD módulo [NAME] inicializar endereço I2C [ADDR]",
    "gui.blocklyText.pythondfr0553.dfr0553ReadVoltage": "AD módulo [NAME] ler voltagem [PIN] (mV)",
    "gui.blocklyText.pythondfr0553.dfr0553GetAisleVoltageDifference": "AD módulo [NAME] obter diferença de voltagem no corredor [AISLE]",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 e 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 e 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 e 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 e 3",
    "gui.blocklyText.dfr0588.initPin": "Inicializar pino de temperatura [TEM] pino de umidade [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "obter unidade de temperatura [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "obter umidade (%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "CELSIUS",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "FAHRENHEIT",
    "gui.blocklyText.dfr0076.readFlameSensor": "ler pino [PIN] Sensor de Chama",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom obter [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom ler texto nome [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom ler número nome [KEY]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom inicializar i2c endereço [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom escrever texto [VALUE] nome [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom escrever número [VALUE] nome [KEY]",
    "gui.blocklyText.dfr0117.text": "Texto",
    "gui.blocklyText.dfr0117.number": "Número",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom excluir nome [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom impressão serial de todos os nomes baud [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom nome [KEY] existe?",
    "gui.blocklyText.dfr0117.eepromFormat": "i2c eeprom formato",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom nome [KEY] é [TYPE]?",
    "gui.blocklyText.dfr0117.nameCount": "Contagem de nomes",
    "gui.blocklyText.dfr0117.memorySize": "Espaço de armazenamento (byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "Espaço de armazenamento restante (byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": "ler pino [PIN] Acelerômetro Triaxial [AXIS] Axis",
    "gui.blocklyText.sen0018.readInfraredMotion": "ler pino [PIN] Sensor Digital de Movimento Infravermelho",
    "gui.blocklyText.sen0014.readDistance": "ler pino [PIN] Sensor de Distância IR Sharp(mm)",
    "gui.blocklyText.sen0114.readMoisture": "ler pino [PIN] Sensor de Umidade do Solo",
    "gui.blocklyText.sen0121.readSteam": "ler pino [PIN] Sensor de Vapor",
    "gui.blocklyText.sen0204.readLiquidLevel": "pino [PIN] nível de líquido atingiu uma certa altura?",
    "gui.blocklyText.sen0203.heartRate": "frequência cardíaca(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "saturação de oxigênio no sangue(HbO2)",
    "gui.blocklyText.sen0202.up": "Para Cima",
    "gui.blocklyText.sen0202.down": "Para Baixo",
    "gui.blocklyText.sen0202.left": "Para a Esquerda",
    "gui.blocklyText.sen0202.right": "Para a Direita",
    "gui.blocklyText.sen0202.cw": "CW",
    "gui.blocklyText.sen0202.ccw": "CCW",
    "gui.blocklyText.dhtTHSensor.temperature": "temperatura(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "umidade(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "pino [PIN] RGB [NUM1] até [NUM2] mostra cor [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "vermelho [RED] verde [GREEN] azul [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "pino [PIN]  [NUM1] até [NUM2] LEDs RGB mostram cor degradê de [COLOR1] até [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "pino [PIN] deslocar pixels por [NUM]",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "pino [PIN] rotacionar pixels por [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "pino [PIN] [NUM] LEDs RGB",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "pino [PIN] brilho [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "pino [PIN] limpar todos os LEDs",
    "gui.blocklyText.ws2812.showHistogram": "pino [PIN]  [NUM1] até [NUM2] LEDs RGB mostram histograma  valor atual: [VALUE]  valor máximo: [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "pino [PIN] brilho do LED [BRIGHTNESS]",
    "gui.blocklyText.neopixel.init": "Inicialização da lâmpada nome [NAME] pino [PIN] número [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Faixa de luz [NAME] [INDEX] Cor [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Faixa de luz [NAME] [INDEX] Vermelho [R] Verde [G] Azul [B]",
    "gui.blocklyText.neopixel.fillColor": "A faixa de luz [NAME] está totalmente acesa e a cor é [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Faixa de luz [NAME] Tudo ligado Vermelho [R] Verde [G] Azul [B]",
    "gui.blocklyText.neopixel.flush": "Faixa de luz [NAME] configurações entram em vigor",
    "gui.blocklyText.neopixel.off": "Faixa de luz [NAME] está desligada",
    "gui.blocklyText.neopixel.setBrightness": "Faixa de luz [NAME] define o brilho para [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Efeito de luz arco-íris [NAME] Quantidade [COUNT] Brilho [BRIGHTNESS] Deslocamento [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7(placa mPython)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9(caixa mPython)",
    "gui.blocklyText.dsTSensor.ds18b20": "Ler pino [PIN] temperatura DS18B20(°C)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280 inicializa endereço I2C 0x77",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "ler sensor BMP280 [TYPE] pino I2C",
    "gui.blocklyText.pythonsen0372.bmp280Init": "BMP280 [NAME] inicializa endereço I2C 0x77 [ADDRESS]",
    "gui.blocklyText.pythonsen0372.readBmpSensor": "[NAME] ler sensor BMP280 [TYPE] pino I2C",
    "gui.blocklyText.bmpTSensor.atmosPressure": "Barométrico(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "Altitude(m)",
    "gui.blocklyText.bmpTSensor.temperature": "Temperatura(?)",
    "gui.blocklyText.huskylens.init": "HuskyLens inicializa pino [SETTING] até o sucesso",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens muda algoritmo para [ALGORITHM]",
    "gui.blocklyText.huskylens.requestFirst": "HuskyLens solicita dados uma vez e salva no resultado",
    "gui.blocklyText.huskylens.resultGetIsStudy": "HuskyLens verifica se o ID [IDNUM] foi aprendido no resultado?",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "HuskyLens verifica se o ID [IDNUM] [BOXARROW] está na tela a partir do resultado?",
    "gui.blocklyText.huskylens.resultGetIDParameter": "HuskyLens obtém [PARAMETER] do ID [IDNUM] [TYPE] do resultado",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens verifica se [BOXARROW] está na tela a partir do resultado?",
    "gui.blocklyText.huskylens.resultGetParameter": "HuskyLens obtém [PARAMETER2] do No. [IDNUM] [TYPE] do resultado",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens obtém o número total de IDs aprendidos do resultado",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens obtém o número total de [BOXARROW] do resultado",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens obtém o número total de ID [IDNUM] [BOXARROW] do resultado",
    "gui.blocklyText.huskylens.resultGetIDofWhichParameter": "HuskyLens obtém [PARAMETER] do ID [IDNUM] No. [BOXNUM] [TYPE] do resultado",
    "gui.blocklyText.huskylens.setIDname": "HuskyLens nomeia o ID [ID] do algoritmo atual como [NAME]",
    "gui.blocklyText.huskylens.TakeScreenshot": "HuskyLens captura [TYPE] e salva no cartão SD",
    "gui.blocklyText.huskylens.writeOSD": "HuskyLens mostra textos personalizados [NAME] na posição x [X] y [Y] na tela",
    "gui.blocklyText.huskylens.clearOSD": "HuskyLens limpa todos os textos personalizados na tela",
    "gui.blocklyText.huskylens.learnOnce": "HuskyLens aprende o ID [ID] automaticamente uma vez",
    "gui.blocklyText.huskylens.clearLearn": "HuskyLens esquece todos os dados de aprendizado do algoritmo atual",
    "gui.blocklyText.huskylens.loadAndSaveModel": "HuskyLens [TYPE] dados atuais do algoritmo como No. [INDEX] modelo do cartão SD",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.A": "foto",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.B": "captura de tela",
    "gui.blocklyText.huskylens.LOADSAVE.A": "salvar",
    "gui.blocklyText.huskylens.LOADSAVE.B": "carregar",
    "gui.blocklyText.huskylens.resultGetNearCenter": "HuskyLens obtém [PARAMETER2] de [TYPE] mais próximo ao centro da tela a partir do resultado",
    "gui.blocklyText.huskylens.box": "quadro",
    "gui.blocklyText.huskylens.arrow": "seta",
    "gui.blocklyText.huskylens.faceRecognition": "Reconhecimento facial",
    "gui.blocklyText.huskylens.objectTracking": "Rastreamento de objeto",
    "gui.blocklyText.huskylens.objectRecognition": "Reconhecimento de objeto",
    "gui.blocklyText.huskylens.lineTracking": "Rastreamento de linha",
    "gui.blocklyText.huskylens.colorRecognition": "Reconhecimento de cor",
    "gui.blocklyText.huskylens.tagRecognition": "Reconhecimento de tag",
    "gui.blocklyText.huskylens.objectClassification": "Classificação de objeto",
    "gui.blocklyText.huskylens.QRRecognition": "Reconhecimento de QR (apenas Edu)",
    "gui.blocklyText.huskylens.barcodeRecognition": "Reconhecimento de código de barras (apenas Edu)",
    "gui.blocklyText.huskylens.x": "Centro X",
    "gui.blocklyText.huskylens.y": "Centro Y",
    "gui.blocklyText.huskylens.w": "largura",
    "gui.blocklyText.huskylens.h": "altura",
    "gui.blocklyText.huskylens.x1": "Início X",
    "gui.blocklyText.huskylens.y1": "Início Y",
    "gui.blocklyText.huskylens.x2": "Final X",
    "gui.blocklyText.huskylens.y2": "Final Y",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "Método de Comunicação",
    "gui.blocklyText.huskylens.address": "Endereço",
    "gui.blocklyText.huskylens.sdaGreen": "SDA(Verde)",
    "gui.blocklyText.huskylens.sclBlue": "SCL(Azul)",
    "gui.blocklyText.huskylens.rxGreen": "Rx(Verde)",
    "gui.blocklyText.huskylens.txBlue": "Tx(Azul)",
    "gui.blocklyText.pythonhuskylens.extensionName": "HuskyLens",
    "gui.blocklyText.pythonhuskylens.init": "HuskyLens inicializa o endereço I2C como 0x32",
    "gui.blocklyText.pythonhuskylens.changeAlgorithm": "HuskyLens muda algoritmo para [ALGORITHM]",
    "gui.blocklyText.pythonhuskylens.requestFirst": "HuskyLens solicita dados uma vez e salva no resultado",
    "gui.blocklyText.pythonhuskylens.resultGetIsStudy": "HuskyLens verifica se o ID [IDNUM] foi aprendido no resultado?",
    "gui.blocklyText.pythonhuskylens.resultGetIDIsInPicture": "HuskyLens verifica se o ID [IDNUM] [BOXARROW] está na tela a partir do resultado?",
    "gui.blocklyText.pythonhuskylens.resultGetIDParameter": "HuskyLens obtém [PARAMETER] do ID [IDNUM] [TYPE] do resultado",
    "gui.blocklyText.pythonhuskylens.resultGetIsInPicture": "HuskyLens verifica se [BOXARROW] está na tela a partir do resultado?",
    "gui.blocklyText.pythonhuskylens.resultGetParameter": "HuskyLens obtém [PARAMETER2] do No. [IDNUM] [TYPE] do resultado",
    "gui.blocklyText.pythonhuskylens.resultGetStudyedID": "HuskyLens obtém o número total de IDs aprendidos do resultado",
    "gui.blocklyText.pythonhuskylens.resultGetIsBoxArrowTotal": "HuskyLens obtém o número total de [BOXARROW] do resultado",
    "gui.blocklyText.pythonhuskylens.resultGetIDsBoxArrowTotal": "HuskyLens obtém o número total de ID [IDNUM] [BOXARROW] do resultado",
    "gui.blocklyText.pythonhuskylens.resultGetIDofWhichParameter": "HuskyLens obtém [PARAMETER] do ID [IDNUM] No. [BOXNUM] [TYPE] do resultado",
    "gui.blocklyText.pythonhuskylens.setIDname": "HuskyLens nomeia o ID [ID] do algoritmo atual como [IDNAME]",
    "gui.blocklyText.pythonhuskylens.TakeScreenshot": "HuskyLens captura [TYPE] e salva no cartão SD",
    "gui.blocklyText.pythonhuskylens.writeOSD": "HuskyLens mostra textos personalizados [TEXT] na posição x [X] y [Y] na tela",
    "gui.blocklyText.pythonhuskylens.clearOSD": "HuskyLens limpa todos os textos personalizados na tela",
    "gui.blocklyText.pythonhuskylens.learnOnce": "HuskyLens aprende o ID [ID] automaticamente uma vez",
    "gui.blocklyText.pythonhuskylens.clearLearn": "HuskyLens esquece todos os dados de aprendizado do algoritmo atual",
    "gui.blocklyText.pythonhuskylens.loadAndSaveModel": "HuskyLens [TYPE] dados atuais do algoritmo como No. [INDEX] modelo do cartão SD",
    "gui.blocklyText.ps2.initPs2Handle": "inicializar alça PS2 DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] setRunmble [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2 abrir runmble [RUMBLE] força [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "PS2 atualizar estados",
    "gui.blocklyText.ps2.ps2ButtonState": "PS2 botão [BUTTON] estado é [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2 joystick [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "LIGADO",
    "gui.blocklyText.ps2.OFF": "DESLIGADO",
    "gui.blocklyText.ps2.Triangle": "Triângulo",
    "gui.blocklyText.ps2.Circle": "Círculo",
    "gui.blocklyText.ps2.Cross": "Cruz",
    "gui.blocklyText.ps2.Square": "Quadrado",
    "gui.blocklyText.ps2.Left1": "Esquerda 1",
    "gui.blocklyText.ps2.Left2": "Esquerda 2",
    "gui.blocklyText.ps2.Left3": "Esquerda 3",
    "gui.blocklyText.ps2.Right1": "Direita 1",
    "gui.blocklyText.ps2.Right2": "Direita 2",
    "gui.blocklyText.ps2.Right3": "Direita 3",
    "gui.blocklyText.ps2.Up": "Cima",
    "gui.blocklyText.ps2.Right": "Direita",
    "gui.blocklyText.ps2.Down": "Baixo",
    "gui.blocklyText.ps2.Left": "Esquerda",
    "gui.blocklyText.ps2.Select": "Selecionar",
    "gui.blocklyText.ps2.Start": "Iniciar",
    "gui.blocklyText.ps2.Hold": "Segurar",
    "gui.blocklyText.ps2.Pressed": "Pressionado",
    "gui.blocklyText.ps2.Released": "Liberado",
    "gui.blocklyText.ps2.CHANGE": "MUDAR",
    "gui.blocklyText.ps2.RightX": "Direita X",
    "gui.blocklyText.ps2.RightY": "Direita Y",
    "gui.blocklyText.ps2.LeftX": "Esquerda X",
    "gui.blocklyText.ps2.LeftY": "Esquerda Y",
    "gui.blocklyText.sen0236.bme280Init": "BME280 inicializa endereço I2C 0x77",
    "gui.blocklyText.sen0236.readbme280Sensor": "Ler pino I2C BME280 [TYPE]",
    "gui.blocklyText.pythonsen0236.bme280Init": "BME280 inicializa endereço I2C 0x77",
    "gui.blocklyText.pythonsen0236.readbme280Sensor": "Ler pino I2C BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "Temperatura(?)",
    "gui.blocklyText.sen0236.Humidity": "Umidade(%rh)",
    "gui.blocklyText.sen0236.Altitude": "Altitude(m)",
    "gui.blocklyText.sen0236.Pressure": "Pressão(Pa)",
    "gui.blocklyText.sen0517.icp10111Init": "ICP10111 inicializa endereço I2C 0x63",
    "gui.blocklyText.sen0517.readicp10111Sensor": "Obter o sensor ICP10111 [TYPE]",
    "gui.blocklyText.sen0517.Temperature": "Temperatura(?)",
    "gui.blocklyText.sen0517.Altitude": "Altitude(m)",
    "gui.blocklyText.sen0517.Pressure": "Pressão(Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "inicializar endereço I2C da tela LCD [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "exibir [TEXT] na linha LCD [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "exibir [TEXT] na posição LCD X: [X] Y: [Y]",
    "gui.blocklyText.LCD1602.displayClear": "limpar a tela",
    "gui.blocklyText.pythonLCD1602.initialIICAddr": "inicializar tela LCD I2C [NAME] endereço [ADDR]",
    "gui.blocklyText.pythonLCD1602.displayInLine": "exibir [TEXT] na linha LCD [NAME] [LINE]",
    "gui.blocklyText.pythonLCD1602.displayInXY": "exibir [TEXT] na posição LCD [NAME] X: [X] Y: [Y]",
    "gui.blocklyText.pythonLCD1602.displayClear": "limpar a tela [NAME]",
    "gui.blocklyText.IICScan.readICCAddr": "ler endereço de dispositivo I2C escaneado",
    "gui.blocklyText.mpyfile.initFile": "inicializar arquivo [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsList": "ler arquivo [FILENAME] como lista, usar [SEPARATE] Separar",
    "gui.blocklyText.mpyfile.writeFile": "gravar no arquivo [FILENAME] dados [WRITEDATA], usar [SEPARATE] Separar",
    "gui.blocklyText.mpyfile.initFileMaixduino": "inicializar arquivo [ROOT] [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsListMaixduino": "ler arquivo [ROOT] [FILENAME] como lista, usar [SEPARATE] Separar",
    "gui.blocklyText.mpyfile.writeFileMaixduino": "gravar no arquivo [ROOT] [FILENAME] dados [WRITEDATA], usar [SEPARATE] Separar",
    "gui.blocklyText.mpyfile.enter": "entrar",
    "gui.blocklyText.mpyfile.comma": "vírgula",
    "gui.blocklyText.mpyfile.semicolon": "ponto e vírgula",
    "gui.blocklyText.mpyfile.space": "espaço",
    "gui.blocklyText.pythondfr0760.extensionName": "Síntese de fala",
    "gui.blocklyText.pythondfr0760.init": "inicializar módulo de síntese de fala I2C modo [VERSION] endereço I2C 0x40",
    "gui.blocklyText.pythondfr0760.syntheticVoice": "síntese de fala [VOICEVALUE]",
    "gui.blocklyText.pythondfr0760.flashStorage": "salvar em flash [FALSH]",
    "gui.blocklyText.pythondfr0760.speechSynthesisSettings": "definir volume [VOLUME]  velocidade [SPEED]  tom [INTONATION]  alto-falante [SPEEKER]",
    "gui.blocklyText.pythondfr0760.setEnglishPron": "definir modo de pronúncia em inglês [Pron]",
    "gui.blocklyText.pythondfr0760.setNumberPron": "Definir a pronúncia do número [Pron]",
    "gui.blocklyText.pythondfr0760.manvoice1": "masculino 1(V1)",
    "gui.blocklyText.pythondfr0760.manvoice2": "masculino 2(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice1": "feminino 1(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice2": "feminino 2(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice3": "feminino 3(V1)",
    "gui.blocklyText.pythondfr0760.donaldDuck": "Pato Donald(V1)",
    "gui.blocklyText.pythondfr0760.auto": "Autojulgado",
    "gui.blocklyText.pythondfr0760.number": "Número",
    "gui.blocklyText.pythondfr0760.value": "Numérico",
    "gui.blocklyText.dfr0760.letter": "letra",
    "gui.blocklyText.dfr0760.word": "palavra",
    "gui.blocklyText.dfr0760.manvoice1": "masculino 1(V1)",
    "gui.blocklyText.dfr0760.manvoice2": "masculino 2(V1)",
    "gui.blocklyText.dfr0760.womanvoice1": "feminino 1(V1)",
    "gui.blocklyText.dfr0760.womanvoice2": "feminino 2(V1)",
    "gui.blocklyText.dfr0760.womanvoice3": "feminino 3(V1)",
    "gui.blocklyText.dfr0760.donaldDuck": "Pato Donald(V1)",
    "gui.blocklyText.dfr0760.softwareSerial": "Inicializar a interface do módulo de síntese de fala [VERSION] [SERIALPORT] Rx(Verde)[PINR] Tx(Azul)[PINT]",
    "gui.blocklyText.dfr0760.init": "inicializar módulo de síntese de fala I2C modo [VERSION] endereço I2C 0x40",
    "gui.blocklyText.dfr0760.syntheticVoice": "síntese de fala [VOICEVALUE]",
    "gui.blocklyText.dfr0760.flashStorage": "salvar em flash [FALSH]",
    "gui.blocklyText.dfr0760.speechSynthesisSettings": "definir volume [VOLUME]  velocidade [SPEED]  tom [INTONATION]  alto-falante [SPEEKER]",
    "gui.blocklyText.dfr0760.setEnglishPron": "definir modo de pronúncia em inglês [Pron]",
    "gui.blocklyText.dfr0715.ASRInit": "módulo de reconhecimento de fala  inicializar  modo de reconhecimento [MODE]  modo de microfone [PHONEMODE] endereço I2C 0x4F",
    "gui.blocklyText.dfr0715.addKeywords": "adicionar palavras-chave [KEYWORD] número [NUMBER]",
    "gui.blocklyText.dfr0715.beginToIdentify": "configuração concluída   começar a reconhecer",
    "gui.blocklyText.dfr0715.recognitionOfASpeech": "reconhecer a fala uma vez, obter número",
    "gui.blocklyText.dfr0646.tmi1650Init": "Inicializa o display TMI1650 I2C endereço [ADDRESS]",
    "gui.blocklyText.dfr0646.eightTubeMode": "oito tubos digitais TM1650 [TYPE]",
    "gui.blocklyText.dfr0646.eightTubeShowInput": "oito tubos digitais TM1650 mostram string [TEXT]",
    "gui.blocklyText.dfr0646.eightTubeControlPoint": "oito tubos digitais TM1650 [ORDER] ponto decimal [STATUS]",
    "gui.blocklyText.pythondfr0715.extensionName": "Reconhecimento de fala",
    "gui.blocklyText.pythondfr0715.ASRInit": "módulo de reconhecimento de fala inicializa modo de reconhecimento [MODE] modo de microfone [PHONEMODE] endereço I2C 0x4F",
    "gui.blocklyText.pythondfr0715.addKeywords": "adicionar palavras-chave [KEYWORD] número [NUMBER]",
    "gui.blocklyText.pythondfr0715.beginToIdentify": "configuração concluída   começar a reconhecer",
    "gui.blocklyText.pythondfr0715.recognitionOfASpeech": "reconhecer a fala uma vez, obter número",
    "gui.blocklyText.dfr0715.loop": "loop",
    "gui.blocklyText.dfr0715.password": "comando",
    "gui.blocklyText.dfr0715.button": "botão",
    "gui.blocklyText.dfr0715.mic": "padrão",
    "gui.blocklyText.dfr0715.word": "áudio externo",
    "gui.blocklyText.sdcard.initCS": "pino do filme SD [CSPIN] inicialização bem-sucedida?",
    "gui.blocklyText.sdcard.readFileNLine": "SD lê arquivo [FILE] linha [LINE]",
    "gui.blocklyText.sdcard.readFileAll": "SD lê arquivo [FILE] todo conteúdo",
    "gui.blocklyText.sdcard.writeFile": "SD escreve arquivo [FILE] conteúdo [MSG] modo [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD exclui arquivo [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD obtém informações do arquivo [FILE] [INFO]",
    "gui.blocklyText.sdcard.NAME": "NOME",
    "gui.blocklyText.sdcard.PATH": "CAMINHO",
    "gui.blocklyText.sdcard.TYPE": "TIPO",
    "gui.blocklyText.sdcard.LINE": "LINHA",
    "gui.blocklyText.sdcard.SIZE": "TAMANHO",
    "gui.blocklyText.sdcard.APPEND": "ANEXAR",
    "gui.blocklyText.sdcard.REPLACE": "SUBSTITUIR",
    "gui.blocklyText.sdcard.WARP": "WARP",
    "gui.blocklyText.sdcard.NOWARP": "NOWARP",
    "gui.blocklyText.sdcard.lsFile": "Porta serial SD imprime todas as informações do arquivo baud [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "inicializar serial de software [SERIALPORT] RX: [PINR] TX: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "serial de software [SERIALPORT] taxa de transmissão [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "serial de software [SERIALPORT] saída [TEXT] [WAY], [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "Há dados para ler no serial de software [SERIALPORT]?",
    "gui.blocklyText.softSerialport.readSerialportData": "ler serial de software [SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "ler [TYPE] no serial de software [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "conectado ao Bluetooth",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "desconectado do Bluetooth",
    "gui.blocklyText.bluetooth.receivedKey": "quando chave recebida do Bluetooth [KEY] [TYPE]",
    "gui.blocklyText.bluetooth.string": "String",
    "gui.blocklyText.bluetooth.number": "Número",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "quando dados recebidos pelo Bluetooth e encontram [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "enviar chave de string [KEY] valor [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "enviar chave de número [KEY] valor [VALUE]",
    "gui.blocklyText.bluetooth.openService": "abrir serviço Bluetooth",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "dados recebidos do Bluetooth",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo começa",
    "gui.blocklyText.leonardo.setBaud": "definir taxa de transmissão [SERIAL] para [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "ler dados int ou float [READTYPE] do [SERIAL]",
    "gui.blocklyText.leonardo.getNumOfFloat": "[TARGET] reter [NNUMS] decimais",
    "gui.blocklyText.leonardo.serialPort1": "PortaSerial0",
    "gui.blocklyText.leonardo.serialPort2": "PortaSerial1",
    "gui.blocklyText.leonardo.dataReadable": "Há dados para ler no [SERIAL]?",
    "gui.blocklyText.leonardo.readSerialData": "ler dados [SERIAL]",
    "gui.blocklyText.arduinounoR3.initTcs34725": "Inicializa o sensor de cor TCS34725 endereço I2C 0x29",
    "gui.blocklyText.arduinounoR3.functionStarts": "Início do programa do kit Uno",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "Inicializa o display TMI1650 I2C endereço 0x34",
    "gui.blocklyText.arduinounoR3.readdigital": "ler pino [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "Sensor Digital",
    "gui.blocklyText.arduinounoR3.buttonModule": "Módulo de Botão",
    "gui.blocklyText.arduinounoR3.stickButton": "Botão de Alavanca",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "Sensor de Vibração",
    "gui.blocklyText.arduinounoR3.readAnalog": "ler pino [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "Sensor Analógico",
    "gui.blocklyText.arduinounoR3.lightSensor": "Sensor de Luz",
    "gui.blocklyText.arduinounoR3.soundSensor": "Sensor de Som",
    "gui.blocklyText.arduinounoR3.stickDirection": "Direção da Alavanca",
    "gui.blocklyText.arduinounoR3.angleSensor": "Sensor de Ângulo",
    "gui.blocklyText.arduinounoR3.graySensor": "Sensor de Tons de Cinza",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "Sensor de Umidade do Solo",
    "gui.blocklyText.arduinounoR3.open": "ligar",
    "gui.blocklyText.arduinounoR3.close": "desligar",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] pino [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "Atuador Digital",
    "gui.blocklyText.arduinounoR3.ledLights": "Luzes de LED",
    "gui.blocklyText.arduinounoR3.fanModule": "Módulo de Ventilador",
    "gui.blocklyText.arduinounoR3.recordModule": "Módulo de Gravação",
    "gui.blocklyText.arduinounoR3.relay": "Relé",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "Lendo distância ultrassônica(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "Atuador de Simulação",
    "gui.blocklyText.arduinounoR3.writeAnalog": " definir pino [PIN] [MODULE] para [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "mover para frente",
    "gui.blocklyText.arduinounoR3.backward": "mover para trás",
    "gui.blocklyText.arduinounoR3.turnLeft": "virar à esquerda",
    "gui.blocklyText.arduinounoR3.turnRight": "virar à direita",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "robô [DIR] a [SPEED] % de velocidade",
    "gui.blocklyText.arduinounoR3.motorForward": "mover para frente",
    "gui.blocklyText.arduinounoR3.motorReverse": "mover para trás",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "motor [MOTOR] [DIR] a [SPEED] % de velocidade",
    "gui.blocklyText.arduinounoR3.stopMotor": "robô (motores MA e MB) parar de se mover",
    "gui.blocklyText.arduinounoR3.setServo": "definir pino [PIN] servo para [DEGREE] grau",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "Emissão infravermelha [TYPE] pino [PIN] valor [VALUE] bit [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "o pino infravermelho [PIN] recebeu o sinal pressionado do [BUTTON]?",
    "gui.blocklyText.arduinounoR3.noSound": "sem som",
    "gui.blocklyText.arduinounoR3.half": "Meia",
    "gui.blocklyText.arduinounoR3.quarter": "Quarto",
    "gui.blocklyText.arduinounoR3.oneInEighth": "Oitavo",
    "gui.blocklyText.arduinounoR3.wholeBeat": "Batida Inteira",
    "gui.blocklyText.arduinounoR3.doubleBeat": "Batida Dupla",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "pino [PIN] toca tom de trombeta [TONE] por [BEAT] batida",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "definir volume do módulo MP3 no pino [PIN] para [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "definir modo de reprodução do módulo MP3 no pino [PIN] para [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "Parar",
    "gui.blocklyText.arduinounoR3.play": "Reproduzir",
    "gui.blocklyText.arduinounoR3.loop": "Loop",
    "gui.blocklyText.arduinounoR3.random": "Aleatório",
    "gui.blocklyText.arduinounoR3.pause": "Pausar",
    "gui.blocklyText.arduinounoR3.previous": "Anterior",
    "gui.blocklyText.arduinounoR3.next": "Próximo",
    "gui.blocklyText.arduinounoR3.VolumePlus": "Volume+",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "Volume-",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "pino [PIN] Módulo MP3 reproduz a música [NUM]",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "ler pino [PIN] temperatura LM35(°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "ler pino [PIN] DHT11 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "vermelho",
    "gui.blocklyText.arduinounoR3.blue": "azul",
    "gui.blocklyText.arduinounoR3.green": "verde",
    "gui.blocklyText.arduinounoR3.readColorSensor": "ler sensor de cor TCS34725 [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "tubo nixie de quatro bits TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "ligar",
    "gui.blocklyText.arduinounoR3.tm1650Close": "desligar",
    "gui.blocklyText.arduinounoR3.clearScreen": "limpar tela",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": " 4-bit nixie tube TM1650 exibe string [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "[STATUS] o ponto decimal [ORDER] do tubo nixie de quatro bits TM1650",
    "gui.blocklyText.arduinounoR3.bright": "clarear",
    "gui.blocklyText.arduinounoR3.dark": "escurecer",
    "gui.blocklyText.arduinounoR3.setLightColor": "pino [PIN] o LED [NUM] exibe [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "pino [PIN] o LED [NUM] R:[R] G:[G] B:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "pino [PIN] [NUM] LEDs",
    "gui.blocklyText.arduinounoR3.ledControl": "matriz de LEDs (max7219) parâmetro inicial DIN pino [DIN], CS pino [CS], CLK pino [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "matriz de LEDs (max7219) mostra padrão [PIC]",
    "gui.blocklyText.max.maxStart": "Max começa",
    "gui.blocklyText.max.onboardButtonPressed": "botão onboard [PRESSED]?",
    "gui.blocklyText.max.pressed": "pressionado",
    "gui.blocklyText.max.notPressed": "não pressionado",
    "gui.blocklyText.max.all": "-1 TODOS",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "definir luzes RGB [TYPE] cor [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "ler sensor de som",
    "gui.blocklyText.max.playSoundEffect": "tocar efeito sonoro [SOUND]",
    "gui.blocklyText.max.setDPinValue": "definir pino digital [PIN] para [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "ler pino digital [PIN]",
    "gui.blocklyText.max.readAPinValue": "ler pino analógico [PIN]",
    "gui.blocklyText.max.setServo": "definir pino [PIN] servo para [DEGREE] grau",
    "gui.blocklyText.max.readLinefindingSensor": "ler sensor de rastreamento de linha [DIR]",
    "gui.blocklyText.max.left": "Esquerda",
    "gui.blocklyText.max.middle": "Meio",
    "gui.blocklyText.max.right": "Direita",
    "gui.blocklyText.max.setLineFidingCarLights": "luzes de LED do carro [SWITCH]",
    "gui.blocklyText.max.on": "ligado",
    "gui.blocklyText.max.off": "desligado",
    "gui.blocklyText.max.readUltrasoundSensor": "ler sensor ultrassônico (cm)",
    "gui.blocklyText.max.readLightSensor": "ler sensor de luz [LEFTRIGHT]",
    "gui.blocklyText.max.maxSpeedMove": "MAX move [DIR] a [SPEED] velocidade",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] move [DIR] a [SPEED] velocidade",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] pára",
    "gui.blocklyText.max.showUserDefineExpressions": "mostrar padrão [EXPRESSION] em [COLOR]",
    "gui.blocklyText.max.clear": "limpar",
    "gui.blocklyText.max.do": "Fa",
    "gui.blocklyText.max.re": "Mi",
    "gui.blocklyText.max.mi": "Mi",
    "gui.blocklyText.max.fa": "Fa",
    "gui.blocklyText.max.sou": "Sol",
    "gui.blocklyText.max.la": "Mi",
    "gui.blocklyText.max.xi": "Re",
    "gui.blocklyText.max.highdo": "Dó",
    "gui.blocklyText.max.brake": "freio",
    "gui.blocklyText.max.rebound": "ressalto",
    "gui.blocklyText.max.bullets": "balas",
    "gui.blocklyText.max.longecho": "eco longo",
    "gui.blocklyText.max.playfulending": "final",
    "gui.blocklyText.max.yo": "yo",
    "gui.blocklyText.max.wo": "wo",
    "gui.blocklyText.max.robottalking": "robô falando",
    "gui.blocklyText.sen0160.init": "Definir o valor de calibração do sensor de peso Hx711 [VALUE] com pino Dout [DOUT] pino SCK [SCK]",
    "gui.blocklyText.sen0160.readValue": "Ler sensor de peso Hx711(g) pino Dout [DOUT] pino SCK [SCK]",
    "gui.blocklyText.knowflow.readORP": "ler pino [PIN] sensor ORP (mV)",
    "gui.blocklyText.knowflow.readEC": "ler pino [PIN] sensor EC (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "ler pino [PIN] sensor de pH(V1)",
    "gui.blocklyText.knowflow.readECV2": "ler pino [PIN] sensor EC (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "ler pino [PIN] sensor de pH(V2)",
    "gui.blocklyText.knowflow.readDO": "ler pino [PIN] sensor de DO (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "pino [PIN] bomba peristáltica girar [DIR] a [SPEED]% de velocidade",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] pino [PIN] relé",
    "gui.blocklyText.goble.readAxis": "ler eixo [AXIS] do botão GOBLE",
    "gui.blocklyText.goble.buttonIsPressed": "botão GOBLE [BUTTON] pressionado?",
    "gui.blocklyText.eeprom.clear": "limpar eeprom",
    "gui.blocklyText.eeprom.read": "ler da eeprom do endereço [ADDR]",
    "gui.blocklyText.eeprom.write": "escrever na eeprom no endereço [ADDR] com dados [DATA]",
    "gui.blocklyText.eeprom.getCrc": "obter crc da eeprom do endereço [ADDRA] ao endereço [ADDRB]",
    "gui.blocklyText.eeprom.length": "obter comprimento da eeprom",
    "gui.blocklyText.tds.readAnalogPin": "ler pino analógico [PIN] sensor TDS (ppm)",
    "gui.blocklyText.pythonsen0244.tdsInit": "TDS [NAME] inicial [PIN]",
    "gui.blocklyText.pythonsen0244.readAnalogPin": "ler valor TDS [NAME] (ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "dois motores de passo funcionam simultaneamente   Eixo X: [XSET] eixo Y: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "motor de passo [AXIS] eixo [STEPS] passos por revolução  Velocidade(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "mover motor de passo [ROTATEDIR] por [STEPS] passos",
    "gui.blocklyText.steppermotor.setJointSteps": "dois motores de passo funcionam simultaneamente. Mover [XSTEPS] passos no eixo x [ROTATEDIRX]  Mover [YSTEPS] passos no eixo y [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] começa",
    "gui.blocklyText.multithread.stopThread": "parar [THREAD]",
    "gui.blocklyText.multithread.startThread": "iniciar [THREAD]",
    "gui.blocklyText.multithread.loop1": "thread 1",
    "gui.blocklyText.multithread.loop2": "thread 2",
    "gui.blocklyText.multithread.loop3": "thread 3",
    "gui.blocklyText.multithread.loop4": "thread 4",
    "gui.blocklyText.multithread.loop5": "thread 5",
    "gui.blocklyText.multithread.loop6": "thread 6",
    "gui.blocklyText.multithread.loop7": "thread 7",
    "gui.blocklyText.multithread.loop8": "thread 8",
    "gui.blocklyText.multithread.all": "todos",
    "text2speech.setRequestSever": "definir servidor [SERVER]",
    "text2speech.setSever2Voice": "definir voz do servidor2 para [VOICE]",
    "text2speech.setSever2Language": "definir idioma do servidor2 para [LANGUAGE]",
    "text2speech.setSever2Account": "definir conta do servidor2 [MODAL]",
    "text2speech.international": "servidor1(internacional)",
    "text2speech.china": "servidor2(China)",
    "gui.blocklyText.text2speech.server2voice0": "duxiaomei",
    "gui.blocklyText.text2speech.server2voice1": "duxiaoyu",
    "gui.blocklyText.text2speech.server2voice3": "duxiaoyao",
    "gui.blocklyText.text2speech.server2voice4": "duyaya",
    "gui.blocklyText.text2speech.server2voice106": "dubowen",
    "gui.blocklyText.text2speech.server2voice110": "duxiaotong",
    "gui.blocklyText.text2speech.server2voice111": "duxiaomeng",
    "gui.blocklyText.text2speech.server2voice103": "dumiduo",
    "gui.blocklyText.text2speech.server2voice5": "duxiaojiao",
    "gui.blocklyText.DFR0151.init": "inicialização DS1307 endereço I2C 0x68",
    "gui.blocklyText.DFR0151.adjustTime": "ajustar a hora do DS1307 para [YEAR]ano [MONTH]mês [DATE]dia [HOUR]hora [MINUTE]minuto [SECOND]segundo",
    "gui.blocklyText.DFR0151.getTime": "obter hora do DS1307 [INDEX]",
    "gui.blocklyText.DFR0151.Year": "ano",
    "gui.blocklyText.DFR0151.month": "mês",
    "gui.blocklyText.DFR0151.date": "dia",
    "gui.blocklyText.DFR0151.hour": "hora",
    "gui.blocklyText.DFR0151.minute": "minuto",
    "gui.blocklyText.DFR0151.second": "segundo",
    "gui.blocklyText.DFR0151.week": "semana",
    "gui.blocklyText.pythondfr0151.init": "inicialização DS1307 endereço I2C 0x68",
    "gui.blocklyText.pythondfr0151.adjustTime": "ajustar a hora do DS1307 para [YEAR]ano [MONTH]mês [DATE]dia [WEEK]semana [HOUR]hora [MINUTE]minuto [SECOND]segundo",
    "gui.blocklyText.pythondfr0151.upTime": "Obter os dados da hora uma vez",
    "gui.blocklyText.pythondfr0151.getTime": "Analisar [INDEX] a partir dos dados da hora",
    "gui.blocklyText.DFR0469.init": "inicialização SD2405 endereço I2C 0x32",
    "gui.blocklyText.DFR0469.adjustTime": "ajustar a hora do SD2405 para [YEAR]ano [MONTH]mês [DATE]dia [HOUR]hora [MINUTE]minuto [SECOND]segundo",
    "gui.blocklyText.DFR0469.getTime": "obter hora do SD2405 [INDEX]",
    "gui.blocklyText.pythondfr0469.init": "inicialização SD2405 endereço I2C 0x32",
    "gui.blocklyText.pythondfr0469.adjustTime": "ajustar a hora do SD2405 para [YEAR]ano [MONTH]mês [DATE]dia [WEEK]semana [HOUR]hora [MINUTE]minuto [SECOND]segundo",
    "gui.blocklyText.pythondfr0469.upTime": "Obter os dados da hora uma vez",
    "gui.blocklyText.pythondfr0469.getTime": "Analisar [INDEX] a partir dos dados da hora",
    "gui.blocklyText.DFR0126.setPin": "O módulo de análise de áudio pinos #S[SPIN] #R[RPIN] Pino analógico[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "ler frequência sonora  banda[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "Inicializar a interface do módulo NFC endereço I2C 0x24",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "Inicializar a interface do módulo NFC porta serial de hardware Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "Inicializar a interface do módulo NFC [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "Inicializar a interface do módulo NFC [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "Bloco NFC [DATABLOCK] byte [BYTES] escrever [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "ler bloco NFC [DATABLOCK] o byte [BYTES] (número)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "ler bloco NFC [DATABLOCK] todos os dados (string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "ler bloco NFC UID (string)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "cartão UID detectado: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "cartão detectado?",
    "gui.blocklyText.DFR0126.serial1": "serial1",
    "gui.blocklyText.DFR0126.serial2": "serial2",
    "gui.blocklyText.DFR0126.serial3": "serial3",
    "gui.blocklyText.pythondfr0231.extensionName": "RFID/NFC",
    "gui.blocklyText.pythondfr0231.initSerialNfcIIC": "Inicializar a interface do módulo NFC endereço I2C 0x24",
    "gui.blocklyText.pythondfr0231.writeSerialNfc": "Bloco NFC [DATABLOCK] byte [BYTES] escrever [VALUE]",
    "gui.blocklyText.pythondfr0231.readSerialNfcAll": "ler bloco NFC [DATABLOCK] todos os dados (string)",
    "gui.blocklyText.pythondfr0231.readSerialNfc": "ler bloco NFC [DATABLOCK] o byte [BYTES] (número)",
    "gui.blocklyText.pythondfr0231.readSerialNfcUid": "ler bloco NFC UID (string)",
    "gui.blocklyText.pythondfr0231.scanSerialNfcUid": "cartão UID detectado: [TEXT]?",
    "gui.blocklyText.pythondfr0231.scanSerialNfc": "cartão detectado?",
    "gui.blocklyText.sen0245.init": "Inicializar o sensor de distância a laser VL53L0X endereço I2C 0x29",
    "gui.blocklyText.sen0245.setVL53L0X": "VL53L0X precisão [PRECISION] modo de medição [VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "obter valor de distância a laser VL53L0X (mm)",
    "gui.blocklyText.pythonsen0315.extensionName": "PAJ7620U2",
    "gui.blocklyText.pythonspread.extensionName": "Placa de expansão dupla",
    "gui.blocklyText.pythonsen0460.extensionName": "Concentração de poeira",
    "gui.blocklyText.pythonsen0304.extensionName": "Ultrassônico (I2C)",
    "gui.blocklyText.pythonsen0307.extensionName": "Ultrassônico (analógico)",
    "gui.blocklyText.pythonsen0245.extensionName": "VL53L0X",
    "gui.blocklyText.pythonsen0245.init": "Inicializar o sensor de distância a laser VL53L0X endereço I2C 0x29",
    "gui.blocklyText.pythonsen0245.setVL53L0X": "VL53L0X precisão [PRECISION] modo de medição [VALUE]",
    "gui.blocklyText.pythonsen0245.readVL53L0X": "obter valor de distância a laser VL53L0X (mm)",
    "gui.blocklyText.SEN0245.LOW": "Baixa(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "Alta(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "Único",
    "gui.blocklyText.SEN0245.Continuous": "Contínuo",
    "gui.blocklyText.serialMp3.initSerialMp3": "Iniciar a interface serial do módulo MP3 [SERIALPORT] RX(verde) [RX] TX(azul) [TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "definir modo de reprodução do módulo MP3 serial para [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "Definir módulo MP3 serial para reproduzir [VALUE] música",
    "gui.blocklyText.serialMp3.setMP3Volume": "Definir volume do módulo MP3 serial para [VOLUME]%",
    "gui.blocklyText.serialMp3.play": "reproduzir",
    "gui.blocklyText.serialMp3.pause": "pausar",
    "gui.blocklyText.serialMp3.end": "fim",
    "gui.blocklyText.serialMp3.prev": "anterior",
    "gui.blocklyText.serialMp3.next": "próxima",
    "gui.blocklyText.serialMp3.volumeUp": "aumentar volume",
    "gui.blocklyText.serialMp3.volumeDown": "diminuir volume",
    "gui.blocklyText.serialMp3.playlist": "lista de reprodução",
    "gui.blocklyText.serialMp3.insertTracks": "inserir faixas",
    "gui.blocklyText.serialMp3.volume": "volume",
    "gui.blocklyText.servo360.setServo360": "pino [PIN] servo girar [DIR] a [SPEED] % de velocidade",
    "gui.blocklyText.servo360.CW": "CW",
    "gui.blocklyText.servo360.CCW": "CCW",
    "gui.blocklyText.TCS34725.initTcs34725": "Inicializa o sensor de cor TCS34725 endereço I2C 0x29",
    "gui.blocklyText.TCS34725.readTcs34725": "ler sensor de cor TCS34725 [COLOR] [FORMAT]",
    "gui.blocklyText.pythonsen0212.extensionName": "TCS34725",
    "gui.blocklyText.pythonsen0212.initTcs34725": "Inicializa o sensor de cor TCS34725 endereço I2C 0x29",
    "gui.blocklyText.pythonsen0212.updateTcs34725": "obter dados TCS34725 uma vez",
    "gui.blocklyText.pythonsen0212.readTcs34725": "Analisar o sensor de cor TCS34725 [COLOR] [FORMAT] a partir dos dados",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "Ligar GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "Não ligar GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "mostrar texto [TEXT] em [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "rolar texto [DIR]",
    "gui.blocklyText.regEmotion8_16.left": "esquerda",
    "gui.blocklyText.regEmotion8_16.right": "direita",
    "gui.blocklyText.regEmotion8_16.lightAll": "mostrar todos os pontos da matriz em [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "mostrar coordenadas do ponto (x:[XAXIS], y:[YXAXIS]) em [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "limpar tela",
    "gui.codeLabel.dynamicVariables": "Variáveis dinâmicas",
    "gui.codeLabel.functionDeclaration": "Declaração de função",
    "gui.codeLabel.staticConstants": "Constantes estáticas",
    "gui.codeLabel.createObject": "Criar um objeto",
    "gui.codeLabel.mainProgramStart": "Início do programa principal",
    "gui.codeLabel.SubthreadProgramStarts": "Subthread %1 começa",
    "gui.codeLabel.customFunction": "Função personalizada",
    "gui.codeLabel.eventCallbackFunction": "Função de retorno de chamada de evento",
    "gui.codeLabel.staticFunctions": "Funções estáticas",
    "gui.mainHeader.err": "!Erro: Os mesmos cabeçalhos de programa [%1] não podem ser usados ao mesmo tempo. Por favor, exclua o bloco duplicado.",
    "gui.mainHeader.help": "!Dica: Se vários programas precisarem ser executados ao mesmo tempo, use a \"multi-threading function\" em \"Extension\", consulte a documentação de ajuda para obter detalhes.",
    "gui.threadHeader.err1": "!Erro: Os cabeçalhos de programa [%1] não podem ser usados ao mesmo tempo. Eles pertencem a kits ou placas diferentes.",
    "gui.threadHeader.err2": "!Erro Dica: Os mesmos cabeçalhos de evento [%1] não podem ser usados ao mesmo tempo. Por favor, exclua o bloco duplicado.",
    "gui.blocklyText.mqtt.whenReceivedData": "quando [TOPIC] receber [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "mensagem MQTT",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi conectar à conta:[ACCOUNT] senha:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi conectado?",
    "gui.blocklyText.wifi.wifiDisConnected": "desconectar Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Obter configuração Wi-Fi [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "Endereço IP",
    "gui.blocklyText.wifi.getWiFiNetmask": "Máscara de sub-rede",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.getDNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi modo ponto de acesso suave:[ACCOUNT2] senha:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Obter IP do ponto de acesso suave Wi-Fi",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi obter [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Informações de depuração",
    "gui.blocklyText.wifi.whenDebugInfoError": "Código de erro",
    "gui.blocklyText.wifi.ping": "Wi-Fi ping [HOST]",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "ntp definir hora da rede, fuso horário [TIMEZONE] servidor de horário [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "ntp obter hora local [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "UTCWest12_t",
    "gui.blocklyText.ntp.UTCWest11_t": "UTCWest11_t",
    "gui.blocklyText.ntp.UTCWest10_t": "UTCWest10_t",
    "gui.blocklyText.ntp.UTCWest9_t": "UTCWest9_t",
    "gui.blocklyText.ntp.UTCWest8_t": "UTCWest8_t",
    "gui.blocklyText.ntp.UTCWest7_t": "UTCWest7_t",
    "gui.blocklyText.ntp.UTCWest6_t": "UTCWest6_t",
    "gui.blocklyText.ntp.UTCWest5_t": "UTCWest5_t",
    "gui.blocklyText.ntp.UTCWest4_t": "UTCWest4_t",
    "gui.blocklyText.ntp.UTCWest3_t": "UTCWest3_t",
    "gui.blocklyText.ntp.UTCWest2_t": "UTCWest2_t",
    "gui.blocklyText.ntp.UTCWest1_t": "UTCWest1_t",
    "gui.blocklyText.ntp.UTCMiddle_t": "UTCMiddle_t",
    "gui.blocklyText.ntp.UTCEast1_t": "UTCEast1_t",
    "gui.blocklyText.ntp.UTCEast2_t": "UTCEast2_t",
    "gui.blocklyText.ntp.UTCEast3_t": "UTCEast3_t",
    "gui.blocklyText.ntp.UTCEast4_t": "UTCEast4_t",
    "gui.blocklyText.ntp.UTCEast5_t": "UTCEast5_t",
    "gui.blocklyText.ntp.UTCEast6_t": "UTCEast6_t",
    "gui.blocklyText.ntp.UTCEast7_t": "UTCEast7_t",
    "gui.blocklyText.ntp.UTCEast8_t": "UTCEast8_t",
    "gui.blocklyText.ntp.UTCEast9_t": "UTCEast9_t",
    "gui.blocklyText.ntp.UTCEast10_t": "UTCEast10_t",
    "gui.blocklyText.ntp.UTCEast11_t": "UTCEast11_t",
    "gui.blocklyText.ntp.UTCEast12_t": "UTCEast12_t",
    "gui.blocklyText.ntp.year": "ano",
    "gui.blocklyText.ntp.month": "mês",
    "gui.blocklyText.ntp.date": "dia",
    "gui.blocklyText.ntp.hour": "hora",
    "gui.blocklyText.ntp.minute": "minuto",
    "gui.blocklyText.ntp.second": "segundo",
    "gui.blocklyText.ntp.week": "semana",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "quando o servidor UDP receber [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "definir porta do servidor UDP [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "servidor UDP enviar broadcast [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "quando o cliente UDP receber [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "definir IP do cliente UDP [IPADDR] porta [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "cliente UDP enviar broadcast [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "mensagem",
    "gui.blocklyText.udp.ClientRecvedMsg": "mensagem",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] adicionar chave [KEY] valor [VALUE]",
    "gui.blocklyText.http.requestHeader": "cabeçalho da solicitação HTTP adicionar [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] URL [URL] tempo limite [TIMEOUT] ms",
    "gui.blocklyText.http.readMessage": "HTTP ler [LINE] mensagem de linha",
    "gui.blocklyText.http.param": "PARAM",
    "gui.blocklyText.http.header": "CABEÇALHO",
    "gui.blocklyText.http.oneline": "UM",
    "gui.blocklyText.http.allline": "TODOS",
    "gui.blocklyText.mqtt.mqttConnect": "conectar MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT conectado?",
    "gui.blocklyText.mqtt.acceptance.meaage": "Quando [TOPIC] receber a mensagem como [MSG]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT enviar mensagem [MSG] para a plataforma de nuvem [TOPIC]",
    "gui.blocklyText.mqtt.mqttSendSave": "MQTT enviar mensagem [MSG] para a plataforma de nuvem [TOPIC] e salvar no banco de dados",
    "gui.blocklyText.mqtt.initial": "MQTT inicial [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "sintetizar áudio 「IFLYTEK」 [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "identificar áudio 「IFLYTEK」 [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "resultado de identificação 「IFLYTEK」",
    "gui.blocklyText.iflytek.CommunicationText": "texto",
    "gui.blocklyText.iflytek.CommunicationTransfer": "transferir para arquivo de áudio",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "arquivo de áudio a ser identificado",
    "gui.blocklyText.mqtt.mqttDisconnect": "desconectar MQTT",
    "gui.blocklyText.internet.initRequestsType": "tipo de solicitações",
    "gui.blocklyText.internet.initRequestsAddr": "endereço de solicitações",
    "gui.blocklyText.internet.initAddDict": "iniciar dicionário",
    "gui.blocklyText.internet.postFileAddr": "endereço do arquivo de postagem",
    "gui.blocklyText.internet.postFilePath": "caminho do arquivo de postagem",
    "gui.blocklyText.internet.postFileMime": "tipo MIME do arquivo de postagem",
    "gui.blocklyText.internet.postFileAddDict": "iniciar dicionário",
    "gui.blocklyText.internet.internetGetpostInit": "internet inicial [SETTING]",
    "gui.blocklyText.internet.initTypeAddr": "solicitações de internet definir tipo [POST] endereço [ADDR] cabeçalhos:[HEADERS] parâmetros:[URL] dados:[DICT]",
    "gui.blocklyText.internet.internetPostFile": "internet postar arquivo [SETTING]",
    "gui.blocklyText.internet.postFileAddrType": "POSTAR endereço de solicitações de arquivo [ADDR] caminho [PATH] tipo MIME [TYPE]",
    "gui.blocklyText.internet.postFileDict": "POSTAR arquivo definir dados do dicionário [DICT] e enviar arquivo",
    "gui.blocklyText.internet.responseContent": "conteúdo da resposta em [DATATYPE]",
    "gui.blocklyText.internet.responseAttributes": "atributos de resposta [ATTRIBUTES]",
    "gui.blocklyText.internet.createSocket": "criar soquete [SOKHANDEL] com af [AFINET] e tipo [SOCKTYPE]",
    "gui.blocklyText.internet.setSocket": "criar soquete [SOKHANDEL] com af [SOCKTYPE] e tipo [TYPENUM]",
    "gui.blocklyText.internet.closeSocket": "soquete [SOKHANDEL] fechar",
    "gui.blocklyText.internet.bindSocket": "soquete [SOKHANDEL] associar com endereço [SOCKADDR] porta [SOCKPORT]",
    "gui.blocklyText.internet.listenSocket": "ouvir soquete [SOKHANDEL] número de conexões em espera [BACKLOGNUM]",
    "gui.blocklyText.internet.recvReturnTuple": "soquete [SOKHANDEL] receber solicitações de conexão e retornar tupla de soquetes",
    "gui.blocklyText.internet.parseReturnIp": "analisar host [HOSTADDR] porta [SOCKPORT] e retornar IP",
    "gui.blocklyText.internet.connectHost": "soquete [SOKHANDEL] conectar host [HOSTADDR] porta [SOCKPORT]",
    "gui.blocklyText.internet.sendReturnBytes": "soquete [SOKHANDEL] enviar [SOCKMSG] e retornar bytes enviados",
    "gui.blocklyText.internet.continueSendReturnBytes": "soquete [SOKHANDEL] continuar enviando [SOCKMSG] e retornar bytes enviados",
    "gui.blocklyText.internet.sendToAddr": "soquete [SOKHANDEL] enviar [SOCKMSG] para o endereço [SOCKADDR]",
    "gui.blocklyText.internet.recvReturnRecvedObject": "soquete [SOKHANDEL] receber e retornar objeto recebido máx. de dados é [MAXNUM]",
    "gui.blocklyText.internet.recvReturnRecvedTuple": "soquete [SOKHANDEL] receber e retornar tupla recebida máx. de dados é [MAXNUM]",
    "gui.blocklyText.internet.setTimeout": "soquete [SOKHANDEL] definir tempo limite [TIMEOUT]",
    "gui.blocklyText.internet.setMode": "soquete [SOKHANDEL] definir modo [SOCKMODE]",
    "gui.blocklyText.internet.readReturnObject": "soquete [SOKHANDEL] ler [READBYTE] e retornar objeto de bytes",
    "gui.blocklyText.internet.text": "texto",
    "gui.blocklyText.internet.binary": "binário",
    "gui.blocklyText.internet.dictionary": "dicionário",
    "gui.blocklyText.internet.block": "bloco",
    "gui.blocklyText.internet.nonblock": "não-bloqueio",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 inicializa endereço I2C 0x77",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 ler altitude(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 ler pressão(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 ler temperatura(?)",
    "gui.blocklyText.sen0206.sen0206Measuring": "Medição MLX90614 [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "objeto",
    "gui.blocklyText.sen0206.environment": "ambiente",
    "gui.blocklyText.sen0206.celsius": "celsius",
    "gui.blocklyText.sen0206.fahrenheit": "fahrenheit",
    "gui.blocklyText.sen0253.init": "10DOF inicial pin I2C_ADDR nível [LEVEL]",
    "gui.blocklyText.sen0253.reset": "10DOF Sensor reset",
    "gui.blocklyText.sen0253.power": "10DOF Definir modo de energia [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF Obter ângulos de Euler [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF Obter qua eixo [AXIS]",
    "gui.blocklyText.sen0253.getAcc": "10DOF Obter acelerômetro eixo [AXIS](mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF Obter magnetômetro eixo [AXIS](µT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF Obter aceleração linear eixo [AXIS](mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF Obter capacidade de gravidade eixo [AXIS](mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF Obter velocidade angular eixo [AXIS]",
    "gui.blocklyText.sen0253.getBmp280": "10DOF Obter [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "Baixo",
    "gui.blocklyText.sen0253.HIGH": "Alto",
    "gui.blocklyText.sen0253.normal": "Normal",
    "gui.blocklyText.sen0253.lowPower": "Economia de energia",
    "gui.blocklyText.sen0253.suspend": "Suspender",
    "gui.blocklyText.sen0253.Roll": "Rolamento(°)",
    "gui.blocklyText.sen0253.Pitch": "Arremesso(°)",
    "gui.blocklyText.sen0253.Yaw": "Guinada(°)",
    "gui.blocklyText.sen0253.temperature": "Temperatura",
    "gui.blocklyText.sen0253.elevation": "Elevação",
    "gui.blocklyText.sen0253.airPressure": "Pressão atmosférica",
    "gui.blocklyText.sen0290.init": "sensor de iluminação definir pino irq [IRQPIN] e endereço i2c [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "sensor de iluminação sensor de raios sensor iic init ok?",
    "gui.blocklyText.sen0290.manualCal": "sensor de iluminação definir capacitância [CAPACITANCE] modo [MODE] distância [DIST]",
    "gui.blocklyText.sen0290.indoors": "internos",
    "gui.blocklyText.sen0290.outdoors": "ao ar livre",
    "gui.blocklyText.sen0290.disturberDis": "perturbador desabilitar",
    "gui.blocklyText.sen0290.disturberEn": "perturbador habilitar",
    "gui.blocklyText.sen0290.errMsg": "mensagem de erro",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "sensor de iluminação obter status",
    "gui.blocklyText.sen0290.GetLightningDistKm": "sensor de iluminação obter distância do raio (Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "sensor de iluminação obter energia de raio bruto",
    "gui.blocklyText.sen0291.sen0291Init": "O medidor de energia inicializa o endereço I2C como [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "O medidor de energia lê a carga [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "A corrente medida real do medidor de energia é [EXT] mA",
    "gui.blocklyText.pythonsen0291.sen0291Init": "O medidor de energia [NAME] inicializa o endereço I2C como [IICADDR]",
    "gui.blocklyText.pythonsen0291.sen0291Read": "O medidor de energia [NAME] lê a carga [LOAD]",
    "gui.blocklyText.pythonsen0291.sen0291Calibration": "Medidor de energia [NAME] calibrar corrente medida real para [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "Tensão (mV)",
    "gui.blocklyText.sen0291.electric": "Corrente (mA)",
    "gui.blocklyText.sen0291.power": "Potência (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "Tensão de shunt (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.dfr0991.rgbButtonInit": "RGBButton Inicializar [ADDR]",
    "gui.blocklyText.dfr0991.setRgbButtonColor1": "definir cor RGBButton vermelha [RED] verde [GREEN] azul [BLUE] ",
    "gui.blocklyText.dfr0991.setRgbButtonColor": "definir cor RGBButton [COLOR]",
    "gui.blocklyText.dfr0991.getRgbButtonStatus": "obter status RGBButton",
    "gui.blocklyText.weather.weatherGetWithCity": "[WEATHER] na cidade [CITY] província [COUNTRY]",
    "gui.blocklyText.weather.temperaturHigh": "temperatura máxima(?)",
    "gui.blocklyText.weather.temperaturLow": "temperatura mínima(?)",
    "gui.blocklyText.weather.weather": "tempo",
    "gui.blocklyText.weather.weatherInit": "inicializar servidor de tempo [SETTING]",
    "gui.blocklyText.weather.serverAddr": "endereço do servidor",
    "gui.blocklyText.weather.appid": "ID do aplicativo",
    "gui.blocklyText.weather.appsecret": "Segredo do aplicativo",
    "gui.blocklyText.weather.serverAddrTianqiAPI": "API do tianqi(www.tianqiapi.com)",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "definir parâmetro do servidor [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "atualizar tag [TAG] valor [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "ler tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "excluir tag [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "contar tag",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "teste de conexão de rede",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "pesquisar tag a partir do número inicial [NUMS] contar variável [NUMV] tag [TAG] tipo [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "tag",
    "gui.blocklyText.tinywebdb.value": "valor",
    "gui.blocklyText.tinywebdb.both": "ambos",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "Hong Kong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.getSpeech": "resultado do reconhecimento",
    "speech.setRecordTime": "definir tempo de gravação para [TIME]",
    "speech.stopRecord": "parar reconhecimento de fala",
    "speech.controlSonogram": "[CONTROL] Sonograma",
    "speech.setServer": "alternar servidor de reconhecimento de fala para [SERVER]",
    "speech.show": "mostrar",
    "speech.hide": "ocultar",
    "speech.server1": "servidor1",
    "speech.server2": "servidor2",
    "gui.blocklyText.mp3.initMp3": "Inicializar interface do módulo DFPlayer MP3 [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "definir volume do módulo DFPlayer MP3 para [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "definir modo de reprodução do módulo DFPlayer MP3 para [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "definir reprodução do módulo DFPlayer MP3 para a música [NUM]",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "definir pino [PIN] do volume do módulo DFPlayer MP3 para [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "Definir o pino [PIN] do módulo DFPlayer MP3 para o modo [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "O módulo DFPlayer MP3 no pino [PIN] reproduz a música [NUM]",
    "gui.blocklyText.tel0094.GPSInit": "GPS inicializado como Rx[RX] Tx[TX] em [SERIALPORT]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "O GPS obteve a localização [LATLON] (°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "O GPS obteve a localização [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "Longitude",
    "gui.blocklyText.tel0094.LONGITUDE": "Latitude",
    "gui.blocklyText.tel0094.YEAR": "Ano",
    "gui.blocklyText.tel0094.MONTH": "Mês",
    "gui.blocklyText.tel0094.DAY": "Dia",
    "gui.blocklyText.tel0094.HOUR": "Hora",
    "gui.blocklyText.tel0094.MINUTE": "Minuto",
    "gui.blocklyText.tel0094.SECOND": "Segundo",
    "gui.blocklyText.tel0094.ALL": "Todos",
    "gui.blocklyText.tel0094.GPSGet": "GPS obteve [GPS]",
    "gui.blocklyText.tel0094.getState": "Status",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "Hemisfério de latitude",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "Hemisfério de longitude",
    "gui.blocklyText.tel0094.getGroundSpeed": "Velocidade no solo",
    "gui.blocklyText.tel0094.getSatellitesCount": "Número de satélites",
    "gui.blocklyText.tel0094.getAltitude": "Altitude",
    "gui.blocklyText.tel0094.getPdop": "Precisão da posição",
    "gui.blocklyText.tel0094.getHdop": "Precisão horizontal",
    "gui.blocklyText.tel0094.getVdop": "Precisão vertical",
    "gui.blocklyText.tel0094.getPositioningMode": "Modo de posicionamento",
    "gui.blocklyText.microIoT.displayInLine": "OLED mostra linha [LINE] texto [TEXT]",
    "gui.blocklyText.microIoT.displayInXY": "OLED mostra [TEXT] x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED mostra imagem [IMAGE] x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED limpa",
    "gui.blocklyText.microIoT.pointDrawing": "OLED desenha ponto x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED define largura da linha [LINEWIDTH]",
    "gui.blocklyText.microIoT.lineDrawing": "OLED desenha linha começando em x [X1] y [Y1] terminando em x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED desenha círculo [FILL] centro x [X] y [Y] raio [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED desenha caixa [FILL] começando em x [X] y [Y] largura [WIDTH] altura [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB define brilho para [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "As luzes RGB estão todas desligadas",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "As luzes RGB de [NUM1] a [NUM2] mostram cores [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "As luzes RGB de [NUM1] a [NUM2] mostram gradientes de matiz de [COLOR1] a [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "Loop RGB por [NUM] pixel",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "As luzes RGB de [NUM1] a [NUM2] mostram cores [COLOR]",
    "gui.blocklyText.motorIot.all": "TODAS",
    "gui.blocklyText.microIot.servo": "Servo [INDEX] ângulo [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "Parâmetro inicial MQTT [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT envia mensagem [MSG] para a plataforma em nuvem [TOPIC]",
    "gui.blocklyText.microIot.MQTTMsg": "Mensagem MQTT",
    "gui.blocklyText.microIoTCloud.MotorRun": "Motor rotaciona [DIR] com velocidade [SPEED]",
    "gui.blocklyText.microIoTCloud.MotorStop": "Motor parado",
    "gui.blocklyText.microIoTCloud.setTime": "Definir ano [Y] mês [MON] dia [D] hora [H] minuto [MIN] segundo [S]",
    "gui.blocklyText.microIoTCloud.setYear": "Definir ano [Y]",
    "gui.blocklyText.microIoTCloud.setMonth": "Definir mês [MON]",
    "gui.blocklyText.microIoTCloud.setDay": "Definir dia [D]",
    "gui.blocklyText.microIoTCloud.setHour": "Definir hora [H]",
    "gui.blocklyText.microIoTCloud.setMinute": "Definir minuto [MIN]",
    "gui.blocklyText.microIoTCloud.setSecond": "Definir segundo [S]",
    "gui.blocklyText.microIoTCloud.getTime": "Obter [TIME]",
    "gui.blocklyText.microTouch.whenBtnPress": "Quando as teclas [KEY] são pressionadas",
    "gui.blocklyText.microTouch.Any": "Qualquer",
    "gui.blocklyText.microTouch.isButtonPressed": "Teclas [KEY] estão pressionadas?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "Modo normal lê teclas",
    "gui.blocklyText.microTouch.readKeyMathMode": "Modo matemático lê [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "Tecla numérica (num)",
    "gui.blocklyText.microTouch.Function": "Tecla de função (str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] motor de vibração",
    "gui.blocklyText.microTouch.turnOn": "Ligar",
    "gui.blocklyText.microTouch.shutDown": "Desligar",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "Intensidade sonora",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "Luz natural",
    "gui.blocklyText.microNaturalScience.readColour": "Ler [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "Valor vermelho",
    "gui.blocklyText.microNaturalScience.G": "Valor verde",
    "gui.blocklyText.microNaturalScience.B": "Valor azul",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "Intensidade UV (µW/cm²)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "Ligar",
    "gui.blocklyText.microNaturalScience.off": "Desligar",
    "gui.blocklyText.microNaturalScience.setTDSK": "Definir constante eletrodo TDS k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "Obter constante eletrodo TDS k",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS (ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "Obter [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "Temperatura da água (°C)",
    "gui.blocklyText.microNaturalScience.Temperature": "Temperatura (?)",
    "gui.blocklyText.microNaturalScience.Humidity": "Umidade (%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "Altitude (m)",
    "gui.blocklyText.microNaturalScience.Pressure": "Pressão (Pa)",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB Definir ponto da matriz X [X] Y [Y] mostrar cores [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "O dht11 não pode ser colocado no evento de interrupção, pode causar leituras anormais do dht11",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "O dht22 não pode ser colocado no evento de interrupção, pode causar leituras anormais do dht22",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "O PM2.5 não pode ser colocado em um evento de interrupção, o que pode resultar em leituras anormais do PM2.5",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "O PM2.5 lê dados, o que pode causar falha na inicialização do LED da matriz de pontos",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "O PM2.5 lê dados, o que pode causar falha no padrão de exibição da matriz de pontos LED",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "A inicialização do Mp3 não pode ser colocada em um evento de interrupção, o que pode causar falha na inicialização",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "A configuração de volume do Mp3 não pode ser colocada em um evento de interrupção, o que pode causar falha na configuração do volume",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "A configuração do modo de reprodução do Mp3 não pode ser colocada em um evento de interrupção, o que pode causar falha no modo de reprodução",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "As configurações de reprodução do Mp3 não podem ser colocadas no evento de interrupção, o que pode causar falha nas configurações de reprodução",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "A inicialização do Mp3 pela porta serial não pode ser colocada em um evento de interrupção, o que pode causar falha na inicialização",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "O modo de reprodução do Mp3 pela porta serial não pode ser colocado em um evento de interrupção, o que pode causar a perda do modo de reprodução configurado",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "A reprodução do Mp3 pela porta serial não pode ser colocada em um evento de interrupção, o que pode causar falha na reprodução",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "A configuração de volume do Mp3 pela porta serial não pode ser colocada em um evento de interrupção, o que pode causar falha no volume",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "A inicialização do Mqtt do Obloq pela porta serial soft não pode ser colocada em um evento de interrupção, o que pode causar falha na comunicação pela porta serial soft",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "A mensagem de envio do Obloq que não pode ser colocada em um evento de interrupção, o que pode causar falha na transmissão",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "A leitura de mensagens MQTT da Obloq não pode ser realizada em um evento de interrupção, o que pode resultar em falha na recepção.",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "A porta serial inicial do HTTP da Obloq não pode ser colocada em um evento de interrupção, o que pode resultar em falha na comunicação da porta serial.",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "A solicitação GET HTTP da Obloq não pode ser colocada em um evento de interrupção, o que pode resultar em falha na solicitação.",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "A solicitação POST HTTP da Obloq não pode ser colocada em um evento de interrupção, o que pode resultar em falha na solicitação.",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "A solicitação PUT HTTP da Obloq não pode ser colocada em um evento de interrupção, o que pode resultar em falha na solicitação.",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "A recepção por infravermelho não pode ser colocada em um evento de interrupção, o que pode resultar em falha na recepção.",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "A recepção por infravermelho pode causar falha na inicialização do LED de matriz de pontos.",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "A recepção por infravermelho pode causar falha no padrão de exibição do LED de matriz de pontos.",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "A transmissão por infravermelho não pode ser colocada em um evento de interrupção, o que pode resultar em falha na transmissão.",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "As tiras RGB não podem ser colocadas em um evento de interrupção e podem causar falha na inicialização.",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "As tiras RGB não podem ser colocadas em um evento de interrupção e podem causar falha na configuração dos pinos.",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "As tiras RGB não podem ser colocadas em um evento de interrupção, o que pode causar falha nos pinos.",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "As tiras RGB não podem ser colocadas em um evento de interrupção e podem causar falha na exibição de cores.",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "As tiras RGB não podem ser colocadas em um evento de interrupção e podem causar falha no ajuste de cor.",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "As tiras RGB não podem ser colocadas em um evento de interrupção, o que pode causar falha no movimento.",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "As tiras RGB não podem ser colocadas em um evento de interrupção e podem causar falha no movimento em loop.",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "As tiras RGB não podem ser colocadas em um evento de interrupção e podem causar falha na exibição do histograma.",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "A matriz de LED não pode ser colocada em um evento de interrupção, o que pode resultar em falha na inicialização.",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "A matriz de LED não pode ser colocada em um evento de interrupção, o que pode resultar em falha no padrão de exibição.",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "A saída serial suave não pode ser colocada em um evento de interrupção, o que pode resultar em saída anormal.",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "A leitura de dados pela porta serial suave não pode ser colocada em um evento de interrupção, o que pode resultar em conteúdo de leitura anormal.",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "A saída e a leitura de dados pela porta serial suave podem causar anomalias nos dados.",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "A leitura inteira da porta serial suave não pode ser colocada em um evento de interrupção, o que pode resultar em conteúdo de leitura anormal.",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "A saída e a leitura da porta serial suave podem causar anomalias na leitura de dados.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "Quando o DHT11 e o PM2.5 estão em dois threads, a leitura do DHT11 pode falhar se o PM2.5 for lido com muita frequência. Recomenda-se reduzir a frequência de leitura do PM2.5.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "Quando o DHT11 e o DS18B20 estão em dois threads, se o DS18B20 for lido com muita frequência, o DHT11 pode falhar na leitura. Recomenda-se reduzir a frequência de leitura do DS18B20.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "Quando o DHT11 e o MP3 são inicializados em dois threads, a inicialização do MP3 pode causar erros na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "Quando o DHT11 e o ajuste de volume do MP3 são realizados em dois threads, o ajuste de volume do MP3 pode causar leitura incorreta do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "Quando o DHT11 e os modos de configuração do MP3 são realizados em dois threads, o modo de configuração do MP3 pode causar leitura incorreta do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "Quando o DHT11 e o reprodutor de MP3 estão em dois threads, o reprodutor de MP3 pode causar leitura incorreta do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "Quando o DHT11 e a inicialização do MP3 pela porta serial estão em dois threads, a inicialização do MP3 pela porta serial pode causar erros na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "Quando o DHT11 e o módulo de controle do MP3 pela porta serial estão em dois threads, o módulo de controle do MP3 pode causar erros na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "Quando o DHT11 e o reprodutor de MP3 pela porta serial estão em dois threads, o reprodutor de MP3 pela porta serial pode causar leitura incorreta do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "Quando o DHT11 e o ajuste de volume do MP3 pela porta serial estão em dois threads, o ajuste de volume do MP3 pela porta serial pode causar leitura incorreta do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "Quando o DHT11 e o RGB são inicializados em dois threads, a inicialização do RGB pode causar erros na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "Quando o DHT11 e o ajuste de brilho do RGB estão em dois threads, o ajuste de brilho do RGB pode causar leitura incorreta do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "Quando o DHT11 e o RGB são apagados em dois threads, o apagamento do RGB pode causar erro na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "Quando o DHT11 e o RGB exibem cores em dois threads, a exibição de cores do RGB pode causar erros na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "Quando o DHT11 e o RGB exibem um gradiente em dois threads, a exibição de um gradiente do RGB pode causar erro na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "Quando o DHT11 e o RGB movem pixels em dois threads, o movimento de pixels do RGB pode causar erros na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "Quando o DHT11 e o RGB movem pixels em loop em dois threads, o movimento em loop de pixels do RGB pode causar erros na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "Quando o DHT11 e o RGB exibem colunas em dois threads, a exibição de colunas do RGB pode causar erros na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "Quando o DHT11 e o envio suave de dados pela porta serial estão em dois threads, o envio suave pode causar erros na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "Quando o DHT11 e a inicialização da Obloq MQTT estão em dois threads, a inicialização da Obloq MQTT pode causar erros na leitura do DHT11. Recomenda-se aguardar a inicialização ser concluída antes de ler o DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "Quando o DHT11 e o envio de mensagens pela Obloq MQTT estão em dois threads, o envio de mensagens pela Obloq MQTT pode causar erros na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "Quando o DHT11 e as solicitações PUT HTTP da Obloq estão em dois threads, isso pode causar erro na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "Quando o DHT11 e a inicialização HTTP da Obloq estão em dois threads, isso pode causar erro na leitura do DHT11. Recomenda-se aguardar a inicialização ser concluída antes de ler o DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "Quando o DHT11 e as solicitações GET HTTP da Obloq estão em dois threads, isso pode causar erro na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "Quando o DHT11 e as solicitações POST HTTP da Obloq estão em dois threads, isso pode causar erro na leitura do DHT11.",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "Quando o DHT11 e a emissão de infravermelho estão em dois threads, isso pode causar erro na leitura do DHT11. Recomenda-se reduzir a frequência de emissão de infravermelho.",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "Quando o DS18B20 e o PM2.5 estão em dois threads, isso pode causar erro na leitura do PM2.5. Recomenda-se reduzir a frequência do DS18B20.",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "Quando o DS18B20 e a inicialização da Obloq MQTT estão em dois threads, a inicialização da MQTT pode falhar. Recomenda-se aguardar a inicialização ser concluída antes de ler o DS18B20.",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "Quando o DS18B20 e as solicitações PUT HTTP da Obloq estão em dois threads, a solicitação PUT pode falhar. Recomenda-se reduzir a frequência de leitura do DS18B20.",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "Quando o DS18B20 e a inicialização HTTP da Obloq estão em dois threads, a inicialização HTTP pode falhar. Recomenda-se aguardar a inicialização ser concluída antes de ler o DS18B20.",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "Quando o DS18B20 e as solicitações GET HTTP da Obloq estão em dois threads, a solicitação GET pode falhar. Recomenda-se reduzir a frequência de leitura do DS18B20.",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "Quando o DS18B20 e as solicitações POST HTTP da Obloq estão em dois threads, a solicitação POST pode falhar. Recomenda-se reduzir a frequência de leitura do DS18B20.",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "A leitura do DS18B20 pode resultar em falta de dados de infravermelho, portanto, recomenda-se reduzir a frequência de leitura do DS18B20.",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "A leitura do DS18B20 pode causar erro na recepção de dados pela porta serial suave, portanto, recomenda-se reduzir a frequência de leitura do DS18B20.",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "Quando a leitura do PM2.5 e a inicialização da Obloq MQTT estão em dois threads, pode ocorrer falha na inicialização da MQTT ou erro na leitura do PM2.5. Recomenda-se evitar a execução simultânea da inicialização da MQTT e leitura do PM2.5.",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "Quando a leitura do PM2.5 e o envio de mensagens pela Obloq MQTT estão em dois threads, pode ocorrer erro na leitura do PM2.5. Recomenda-se reduzir a frequência de leitura do PM2.5.",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "Quando a leitura do PM2.5 e as solicitações PUT HTTP da Obloq estão em dois threads, pode ocorrer falha na solicitação PUT ou erro na leitura do PM2.5. Recomenda-se reduzir a frequência da solicitação PUT ou leitura do PM2.5.",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "Quando a leitura do PM2.5 e a inicialização HTTP da Obloq estão em dois threads, pode ocorrer falha na inicialização ou erro na leitura do PM2.5. Recomenda-se evitar a execução simultânea da inicialização HTTP e leitura do PM2.5.",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "Quando a leitura do PM2.5 e as solicitações GET HTTP da Obloq estão em dois threads, pode ocorrer falha na solicitação GET ou erro na leitura do PM2.5. Recomenda-se reduzir a frequência da solicitação GET ou leitura do PM2.5.",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "Quando a leitura do PM2.5 e a solicitação POST HTTP da Obloq estão em dois threads, pode ocorrer falha na solicitação POST ou erro na leitura do PM2.5. Recomenda-se reduzir a frequência da solicitação POST ou leitura do PM2.5.",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "Quando a leitura do PM2.5 e a inicialização do MP3 estão em dois threads, a leitura do PM2.5 pode falhar. Recomenda-se evitar a execução simultânea da leitura do PM2.5 e inicialização do MP3.",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "Quando a leitura do PM2.5 e o ajuste de volume do MP3 estão em dois threads, a leitura do PM2.5 pode falhar. Recomenda-se não ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "Quando a leitura do PM2.5 e a reprodução do MP3 estão em dois threads, a leitura do PM2.5 pode falhar. Recomenda-se não reproduzir o dispositivo com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "Quando a leitura de PM2.5 e a reprodução de MP3 ocorrem em duas threads distintas, a leitura de PM2.5 pode falhar. Recomenda-se não trocar de faixa com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "Quando a leitura de PM2.5 e o player de MP3 serial estão em duas threads distintas, a leitura de PM2.5 pode falhar. Recomenda-se não usar o dispositivo com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "Quando a leitura de PM2.5 e a reprodução de MP3 serial estão em duas threads distintas, a leitura de PM2.5 pode falhar. Recomenda-se não trocar de faixa com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "Quando a leitura de PM2.5 e o ajuste de volume do MP3 serial ocorrem em duas threads distintas, a leitura de PM2.5 pode falhar. Recomenda-se não ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "Quando a leitura de PM2.5 pode causar falha na recepção por infravermelho, recomenda-se reduzir a frequência de leitura de PM2.5.",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "Quando a leitura de PM2.5 e a inicialização do RGB ocorrem em duas threads distintas, podem ocorrer erros na leitura de PM2.5. Recomenda-se evitar a execução simultânea da leitura de PM2.5 e da inicialização do RGB.",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "Quando a leitura de PM2.5 e o ajuste de brilho do RGB ocorrem em duas threads distintas, pode ocorrer um erro na leitura de PM2.5. Recomenda-se não ajustar o brilho com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "Quando a leitura de PM2.5 e a extinção do RGB ocorrem em duas threads distintas, podem ocorrer erros na leitura de PM2.5. Recomenda-se não extinguir o RGB com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "Quando a leitura de PM2.5 e a exibição de cores do RGB ocorrem em duas threads distintas, podem ocorrer erros na leitura de PM2.5. Recomenda-se não trocar de cores com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "Quando a leitura de PM2.5 e a exibição de um gradiente do RGB ocorrem em duas threads distintas, pode ocorrer um erro na leitura de PM2.5. Recomenda-se não trocar de gradiente com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "Quando a leitura de PM2.5 e o movimento de pixels do RGB ocorrem em duas threads distintas, podem ocorrer erros na leitura de PM2.5. Recomenda-se não mover os pixels com muita frequência.",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "O uso de Wi-Fi pode causar uma exibição anormal de cores no RGB embarcado.",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "Uso de UDP com Wi-Fi pode causar uma exibição anormal de cores no RGB embarcado.",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "O uso de Wi-Fi pode causar uma configuração anormal de três cores no RGB embarcado.",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "O uso de UDP com Wi-Fi pode causar uma configuração anormal de três cores no RGB embarcado.",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "O uso de Wi-Fi pode causar um brilho anormal no RGB embarcado.",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "O uso de UDP com Wi-Fi pode causar um brilho anormal no RGB embarcado.",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "O uso de Wi-Fi pode resultar em um brilho anormalmente alto no RGB embarcado.",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "O uso de UDP com Wi-Fi pode resultar em um brilho anormalmente alto no RGB embarcado.",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "O uso de Wi-Fi pode levar à inicialização anormal do serial MP3.",
    "gui.blocklyText.prompt.telloesp32.emissionPinAndBit_telloWIFI": "O uso de Wi-Fi pode causar uma anormalidade no módulo transmissor infravermelho.",
    "gui.blocklyText.prompt.telloesp32.rgbnomal_rgbeffects": "Ao usar o programa de efeito de luz respiratória, a luz LED não pode mais ser definida separadamente. Por favor, remova um dos blocos.",
    "gui.blocklyText.prompt.telloesp32.matrixnomal_matrixeffects": "Efeitos especiais são implementados para threads de fundo, o que conflita com a configuração direta da matriz.",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "O uso de UDP com Wi-Fi pode resultar em uma inicialização anormal do serial MP3.",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "O uso de Wi-Fi pode causar uma reprodução anormal do serial MP3.",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "O uso de UDP com Wi-Fi pode causar uma reprodução anormal do serial MP3.",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "O uso de Wi-Fi pode resultar em falha na reprodução de faixas no serial MP3.",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "O uso de UDP com Wi-Fi pode resultar em falha na reprodução de faixas no serial MP3.",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "O uso de Wi-Fi pode resultar em falha no ajuste de volume do serial MP3.",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "O uso de UDP com Wi-Fi pode resultar em falha no ajuste de volume do serial MP3.",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "O uso de Wi-Fi pode resultar em uma velocidade anormalmente alta para o servomotor de 360 graus.",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "O uso de UDP com Wi-Fi pode resultar em uma velocidade anormalmente alta para o servomotor de 360 graus.",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "Quando a leitura de PM2.5 e o loop de movimento de pixels do RGB ocorrem em duas threads distintas, podem ocorrer erros na leitura de PM2.5. Recomenda-se não executar o loop de movimento de pixels com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "Quando a leitura de PM2.5 e as colunas de exibição RGB estão em dois threads distintos, podem ocorrer erros na leitura de PM2.5. Recomenda-se não executar as colunas de exibição RGB com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "Quando a leitura de PM2.5 e a inicialização do array de pontos de LED são realizadas em dois threads distintos, a inicialização do array de pontos de LED pode falhar. Recomenda-se evitar a execução simultânea da leitura de PM2.5 e da inicialização do array de pontos de LED.",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "Quando a leitura de PM2.5 e a exibição do array de pontos de LED estão em dois threads distintos, a exibição do array de pontos de LED pode estar incorreta. Recomenda-se reduzir a frequência da leitura de PM2.5.",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "A leitura de PM2.5 pode causar erros na própria leitura de PM2.5. Recomenda-se não enviar dados com muita frequência.",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "A leitura de PM2.5 pode causar falha na leitura da porta serial soft. Recomenda-se não ler PM2.5 com muita frequência.",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "O MP3 pode não receber dados infravermelhos, sendo recomendado reduzir a frequência de uso do MP3.",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "Quando a inicialização do MP3 e do MQTT do obloq estão em dois threads, pode causar falha na inicialização do MQTT. Recomenda-se evitar a inicialização simultânea do MQTT e do MP3.",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "Quando as solicitações de colocação HTTP do MP3 e do obloq estão em dois threads, é possível causar falha em uma solicitação de colocação. Recomenda-se reduzir a frequência das solicitações ou a frequência de uso do MP3.",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "Quando as inicializações HTTP do MP3 e do obloq estão em dois threads, as inicializações HTTP podem falhar. Recomenda-se evitar ambas as inicializações HTTP e do MP3.",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "Quando as solicitações de obtenção HTTP do MP3 e do obloq estão em dois threads, pode causar falha na solicitação de obtenção. Recomenda-se reduzir a frequência das solicitações ou a frequência de uso do MP3.",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "Quando as solicitações de postagem HTTP do MP3 e do obloq estão em dois threads, pode causar falha na solicitação de postagem. Recomenda-se reduzir a frequência das solicitações ou a frequência de uso do MP3.",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "O MP3 pode causar falha na leitura da porta serial soft, sendo recomendado não usar o MP3 com muita frequência.",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "A porta serial do MP3 pode não receber dados infravermelhos, sendo recomendado reduzir a frequência de uso.",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "Quando a inicialização da porta serial MP3 e a inicialização do MQTT do obloq estão em dois threads, a inicialização do MQTT pode falhar. Recomenda-se evitar a execução simultânea da inicialização do MQTT e da porta serial MP3.",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "Quando as solicitações de colocação HTTP da porta serial MP3 e do obloq estão em dois threads, pode causar falha em uma solicitação de colocação. Recomenda-se reduzir a frequência das solicitações ou a frequência de uso da porta serial MP3.",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "A inicialização HTTP pode falhar quando a porta serial MP3 e o obloq são inicializados em dois threads. Recomenda-se evitar tanto a inicialização HTTP quanto a porta serial MP3.",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "Quando as solicitações de obtenção HTTP da porta serial MP3 e do obloq estão em dois threads, pode causar falha na solicitação de obtenção. Recomenda-se reduzir a frequência das solicitações ou a frequência de uso da porta serial MP3.",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "Quando as solicitações de postagem HTTP da porta serial MP3 e do obloq estão em dois threads, pode causar falha na solicitação de postagem. Recomenda-se reduzir a frequência das solicitações ou a frequência da porta serial MP3.",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "A porta serial MP3 pode causar falha na leitura da porta serial soft, sendo recomendado não usar a porta serial MP3 com muita frequência.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "A emissão infravermelha pode levar a erros na recepção de dados pela porta serial soft do obloq, sendo recomendado reduzir a frequência da emissão infravermelha.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "A porta serial soft do obloq pode causar erros na recepção de dados infravermelhos, sendo recomendado evitar o envio frequente de mensagens pelo obloq.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "A inicialização da banda RGB pode levar ao erro na recepção de mensagens pelo obloq, sendo recomendado evitar o uso frequente da banda RGB.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "A configuração do brilho da faixa de luz RGB pode causar erro na recepção de mensagens pelo obloq. Recomenda-se evitar ajustes frequentes de brilho.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "O desligamento da faixa RGB pode levar a erros na recepção de mensagens pela porta serial soft do obloq, sendo recomendado evitar o desligamento frequente da faixa.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "A exibição da faixa de luz RGB pode causar erro na recepção de mensagens pelo obloq, sendo recomendado evitar a exibição frequente de cores.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "A exibição do gradiente na faixa RGB pode causar erros na recepção de mensagens pelo obloq. Recomenda-se evitar a exibição frequente do gradiente.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "O movimento de pixels na faixa RGB pode causar erros na recepção de mensagens pela porta serial soft do obloq.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "A faixa RGB que move os pixels ao redor pode causar erros na recepção de mensagens pela porta serial soft do obloq.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "A exibição da barra de faixa RGB pode causar erro na recepção de mensagens pela porta serial soft do obloq.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "Quando a inicialização da porta serial soft do obloq e a inicialização da exibição do array de pontos de LED são realizadas em dois threads distintos, pode causar erro na inicialização do array de pontos de LED. Recomenda-se evitar a execução simultânea da inicialização da porta serial soft do obloq e da inicialização do array de pontos de LED.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "Quando a porta serial soft do obloq e a exibição do array de pontos de LED estão em dois threads distintos, pode causar erro na exibição do array de pontos de LED.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "O envio da porta serial soft pode causar erro na leitura da porta serial soft do obloq, sendo recomendado não enviar dados com muita frequência.",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "O envio da porta serial soft do obloq pode causar falha na leitura da porta serial soft, sendo recomendado não enviar dados com muita frequência.",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "A emissão infravermelha pode causar erros na recepção de dados infravermelhos, sendo recomendado reduzir a frequência da emissão infravermelha.",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "Quando a emissão infravermelha pode causar falha na leitura da porta serial soft, é recomendado reduzir a frequência da emissão infravermelha.",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "A inicialização da banda RGB pode resultar na falta de recepção de dados infravermelhos.",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "A configuração de brilho da faixa de luz RGB pode resultar na incapacidade de receber dados infravermelhos, sendo recomendado evitar ajustes frequentes de brilho.",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "O desligamento da faixa RGB pode resultar na incapacidade de receber dados infravermelhos.",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "A exibição da cor da faixa de luz RGB pode impedir a recepção de dados infravermelhos, portanto, é recomendado evitar alterações frequentes na cor da faixa de luz RGB.",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "A mudança da cor da faixa de luz RGB pode resultar na incapacidade de receber dados infravermelhos, então é aconselhável evitar mudanças frequentes na cor da faixa de luz RGB.",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "O movimento de pixels na faixa de luz RGB pode resultar na incapacidade de receber dados infravermelhos, então é recomendado evitar movimentos frequentes de pixels na faixa de luz RGB.",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "A exibição da cor da faixa de luz RGB pode impedir a recepção de dados infravermelhos, então é recomendado evitar alterações frequentes na cor da faixa de luz RGB.",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "A exibição da cor da faixa de luz RGB pode impedir a recepção de dados infravermelhos, então é recomendado evitar alterações frequentes na cor da faixa de luz RGB.",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "A recepção infravermelha pode causar falha na inicialização do LED.",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "A recepção infravermelha pode causar erros na exibição do LED.",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "O envio pela porta serial soft pode impedir a recepção de dados infravermelhos; portanto, é recomendado evitar o envio frequente pela porta serial soft.",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "A banda RGB pode causar falha na leitura da porta serial soft, então é recomendado reduzir a frequência de uso da banda RGB.",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "Dados recebidos pela porta serial soft podem causar erro na exibição do LED de matriz de pontos.",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "O envio pela porta serial soft pode levar ao erro na recepção de dados pela porta serial soft. Recomenda-se evitar a execução simultânea do envio e recebimento de dados.",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "A inicialização da porta serial MP3 pode resultar em erros na recepção de dados infravermelhos, então é recomendado evitar a execução simultânea.",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "A configuração do modo de reprodução da porta serial MP3 pode causar erros na recepção de dados infravermelhos, então é recomendado evitar configurar o modo de reprodução com muita frequência.",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "O player serial MP3 pode causar erros nos dados infravermelhos, sendo recomendado evitar a troca frequente de músicas.",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "A configuração de volume da porta serial MP3 pode causar erros na recepção de dados infravermelhos. Recomenda-se evitar ajustes frequentes de volume.",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "A inicialização do max7219 pode causar erros nos dados infravermelhos, então é recomendado evitar a execução simultânea.",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "A exibição de imagens com o max7219 pode causar erros nos dados infravermelhos, e é aconselhável evitar exibições frequentes de imagens.",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "O módulo MP3 da porta serial pode impedir que a interrupção de pinos seja acionada. Recomenda-se evitar o uso excessivo do módulo MP3 da porta serial.",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "O módulo de exibição max7219 pode impedir que as interrupções de pinos sejam acionadas. Recomenda-se evitar o uso excessivo da exibição max7219.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "A leitura DHT pode resultar em erros nas mensagens infravermelhas, sendo recomendado reduzir a frequência de leitura DHT.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "A leitura Ds18b20 pode causar erros na recepção de mensagens externas soft, então é recomendado reduzir a frequência de leitura do Ds18b20.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "A leitura PM2.5 pode causar erros na recepção de mensagens fora do software. Recomenda-se reduzir a frequência de leitura do PM2.5.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "A inicialização do leitor MP3 pode causar erro na leitura de mensagens infravermelhas, é recomendável evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "A configuração do volume do MP3 pode resultar em erro na recepção de mensagens infravermelhas. Recomenda-se evitar ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "O modo de configuração do MP3 pode gerar erros na recepção de mensagens infravermelhas. Evite configurar o modo com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "O reprodutor MP3 pode causar erros na recepção de mensagens infravermelhas, portanto, evite trocar de música com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "O modo de configuração do MP3 na porta serial pode causar erros na recepção de mensagens infravermelhas. Evite configurar o modo com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "O reprodutor MP3 na porta serial pode gerar erros na recepção de mensagens infravermelhas, evite trocar de música com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "A configuração de volume na porta serial do MP3 pode levar a erros na recepção de mensagens infravermelhas. Evite ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "A emissão infravermelha pode causar erros na recepção de mensagens, portanto, evite enviar infravermelho com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "A inicialização da banda RGB pode resultar em erros na recepção de mensagens infravermelhas. Evite a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "A configuração de brilho na banda RGB pode gerar erros na recepção de mensagens infravermelhas. Evite ajustar o brilho com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "A exibição da banda RGB pode causar erros na recepção de mensagens infravermelhas. Recomenda-se evitar exibir a banda RGB com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "A discoloração gradiente na exibição da banda de lâmpadas RGB pode levar a erros na recepção de mensagens infravermelhas. Evite exibir a discoloração gradiente com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "Mover pixels com a faixa de luz RGB pode causar erros na recepção de mensagens infravermelhas. Evite mover pixels com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "O movimento circular de pixels com a faixa de luz RGB pode causar erros na recepção de mensagens infravermelhas. Evite executar o movimento circular de pixels com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "A exibição colunar da faixa de luz RGB pode causar erro na recepção de mensagens infravermelhas. Evite exibir colunas com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "Mensagens infravermelhas podem causar falha na inicialização do max7219.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "Mensagens infravermelhas podem causar erro na exibição do max7219.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "A impressão serial suave pode causar erros na recepção de mensagens infravermelhas, portanto, evite imprimir serial suave com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "A inicialização da porta serial suave do obloq MQTT pode causar erros nos dados recebidos por infravermelho, portanto, evite a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "A solicitação de put do obloq via porta serial suave pode resultar em erro nos dados recebidos por infravermelho, é recomendável evitar solicitar dados com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "A inicialização da porta serial suave do obloq HTTP pode levar a erros nos dados recebidos por infravermelho, é recomendável evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "A solicitação get do obloq via porta serial suave pode levar a erro nos dados recebidos por infravermelho, é recomendável evitar solicitar dados com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "A solicitação post do obloq via porta serial suave pode levar a erro nos dados recebidos por infravermelho, é recomendável evitar solicitar dados com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "A limpeza da exibição da banda de lâmpadas RGB pode causar erro na recepção de mensagens na porta serial suave. Evite limpar a exibição com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readMoistureTemperature": "A leitura do DHT pode levar a erro na recepção de mensagens por porta serial suave, é recomendável reduzir a frequência de leitura do DHT.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "A leitura do DS18B20 pode causar erro na recepção de mensagens por porta serial suave, é recomendável reduzir a frequência de leitura do DS18B20.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "A leitura do PM2.5 pode causar erros na recepção de mensagens por porta serial suave. É recomendável reduzir a frequência de leitura do PM2.5.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "A inicialização da leitura do MP3 pode resultar em erro na recepção de mensagens por porta serial suave, é recomendável evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "A configuração de volume do MP3 pode causar erro na recepção de mensagens na porta serial suave. Evite ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "O modo de configuração do MP3 pode gerar erro na recepção de mensagens por porta serial suave, é recomendável evitar configurar o modo com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "A reprodução do MP3 pode levar a erro na recepção de mensagens por porta serial suave, é recomendável evitar trocar de música com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "A inicialização da leitura do MP3 na porta serial pode causar erro na recepção de mensagens por porta serial suave, é recomendável evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "O modo de configuração do MP3 na porta serial pode gerar erro na recepção de mensagens por porta serial suave, é recomendável evitar configurar o modo com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "A reprodução do MP3 na porta serial pode causar erro na recepção de mensagens por porta serial suave, é recomendável evitar trocar de música com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "A configuração de volume na porta serial do MP3 pode causar erro na recepção de mensagens por porta serial suave. Evite ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "A emissão infravermelha pode causar erros na recepção de mensagens por porta serial suave. Evite enviar mensagens infravermelhas com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "A inicialização da banda RGB pode resultar em erro na recepção de mensagens por porta serial suave, é recomendável evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "A configuração de brilho na banda RGB pode gerar erro na recepção de mensagens por porta serial suave. Evite ajustar o brilho com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "A limpeza da exibição da banda de luz RGB pode causar erro na recepção de mensagens por porta serial suave. Evite limpar a exibição com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "A exibição da banda RGB pode causar erro na recepção de mensagens por porta serial suave. Evite exibir a banda RGB com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "A exibição de gradiente na fita de lâmpada RGB pode causar erro na mensagem na porta serial suave. Recomenda-se evitar exibir gradientes com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "Movimentar pixels com a faixa de luz RGB pode fazer com que a porta serial suave receba mensagens incorretas. Recomenda-se evitar mover pixels com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "O movimento circular de pixels na faixa de luz RGB pode causar erro na recepção de mensagens pela porta serial suave. Recomenda-se evitar a execução frequente do movimento circular de pixels.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "A exibição de colunas na fita de luz RGB pode causar erro na recepção de mensagens pela porta serial suave. Recomenda-se evitar a exibição de colunas com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "A recepção de mensagens pela porta serial suave pode causar falha na inicialização do max7219.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "A recepção de mensagens pela porta serial suave pode causar erro na exibição do max7219.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "A impressão pela porta serial suave pode causar erro na recepção de mensagens. Recomenda-se evitar a impressão frequente pela porta serial suave.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "Leituras DHT podem causar falha na ativação do pino de interrupção. Recomenda-se reduzir a frequência das leituras DHT.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "A leitura do Ds18b20 pode causar falha no acionamento do pino de interrupção. Recomenda-se reduzir a frequência da leitura do Ds18b20.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "A leitura de PM2.5 pode causar falha no acionamento do pino de interrupção. Recomenda-se reduzir a frequência da leitura de PM2.5.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "Leituras de inicialização do Mp3 podem causar falha no acionamento do pino de interrupção, sendo recomendável evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "A configuração de volume do Mp3 pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "A configuração do modo do Mp3 pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a configuração frequente do modo.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "O player Mp3 pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a troca frequente de músicas.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "A leitura de inicialização do Mp3 pela porta serial pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "A configuração de modo do Mp3 pela porta serial pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a configuração frequente do modo.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "O player Mp3 pela porta serial pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a troca frequente de músicas.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "A configuração de volume pela porta serial do Mp3 pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "A emissão infravermelha pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar enviar infravermelho com muita frequência.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "A inicialização da fita RGB pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "A configuração de brilho da faixa RGB pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar ajustar o brilho com muita frequência.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "A limpeza da exibição na fita RGB pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a limpeza da exibição com muita frequência.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "A exibição da fita RGB pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a exibição frequente da fita RGB.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "A exibição de gradiente na fita RGB pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a exibição frequente de gradientes.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "O movimento de pixels na faixa de luz RGB pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar o movimento frequente de pixels.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "O movimento de loop de pixels na faixa de luz RGB pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar o loop de movimento de pixels com muita frequência.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "A exibição de barras na fita RGB pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a exibição frequente de barras.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "A interrupção de pino pode causar falha na inicialização do max7219.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "A interrupção de pino pode causar exibição incorreta no max7219.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "A impressão pela porta serial suave pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a impressão frequente pela porta serial suave.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "A inicialização do MQTT da porta serial suave do Obloq pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "As solicitações PUT do Obloq pela porta serial suave podem causar falha no acionamento do pino de interrupção. Recomenda-se evitar solicitar dados com muita frequência.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "A inicialização HTTP do Obloq pela porta serial suave pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "As solicitações GET do Obloq pela porta serial suave podem causar falha no acionamento do pino de interrupção. Recomenda-se evitar solicitar dados com muita frequência.",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "As solicitações POST do Obloq pela porta serial suave podem causar falha no acionamento do pino de interrupção. Recomenda-se evitar solicitar dados com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "A leitura DHT pode resultar em erro na recepção de mensagens pelo Obloq pela porta serial suave. Recomenda-se reduzir a frequência das leituras DHT.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "A leitura do Ds18b20 pode resultar em recepção incorreta de mensagens pelo Obloq, sendo recomendável reduzir a frequência da leitura do Ds18b20.",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "A leitura de PM2.5 pode resultar em erro na recepção de mensagens pelo Obloq. Recomenda-se reduzir a frequência da leitura de PM2.5.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "A leitura de inicialização do Mp3 pode resultar em erro na recepção de mensagens pelo Obloq pela porta serial suave. Recomenda-se evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "A configuração de volume do Mp3 pode resultar em erro na recepção de mensagens pelo Obloq pela porta serial suave. Recomenda-se evitar ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "A configuração de modo do Mp3 pode resultar em erro na recepção de mensagens pelo Obloq pela porta serial suave. Recomenda-se evitar a configuração frequente do modo.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "A reprodução do Mp3 pode resultar em erro na recepção de mensagens pelo Obloq pela porta serial suave. Recomenda-se evitar a troca frequente de músicas.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "A leitura de inicialização do Mp3 pela porta serial pode resultar em erro na recepção de mensagens pelo Obloq pela porta serial suave. Recomenda-se evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "A configuração de modo do Mp3 pela porta serial pode resultar em erro na recepção de mensagens pelo Obloq pela porta serial suave. Recomenda-se evitar a configuração frequente do modo.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "A reprodução do Mp3 pela porta serial pode resultar em erro na recepção de mensagens pelo Obloq pela porta serial suave. Recomenda-se evitar a troca frequente de músicas.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "A configuração de volume pela porta serial do Mp3 pode resultar em erro na recepção de mensagens pelo Obloq pela porta serial suave. Recomenda-se evitar ajustar o volume com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "A emissão infravermelha pode resultar em erro na recepção de mensagens pelo Obloq pela porta serial suave. Recomenda-se evitar enviar infravermelho com muita frequência.",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "O envio de mensagens pelo Obloq pela porta serial suave pode resultar em erros na recepção infravermelha, sendo recomendável evitar o envio frequente de mensagens pelo Obloq.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "A inicialização da fita RGB pelo Obloq pela porta serial suave pode resultar em erro na recepção de mensagens, sendo recomendável evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "A configuração de brilho da faixa de luz RGB pelo Obloq pode resultar em erro na recepção de mensagens. Recomenda-se evitar ajustar o brilho com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "A limpeza da exibição da faixa de luz RGB pode causar erro na recepção de mensagens pelo Obloq. Recomenda-se evitar a limpeza frequente da exibição.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "A exibição da fita RGB pode resultar em erro na recepção de mensagens pelo Obloq. Recomenda-se evitar a exibição frequente da fita RGB.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "A exibição de gradiente na fita de lâmpada RGB pode causar erro na mensagem na porta serial suave do Obloq. Recomenda-se evitar exibir gradientes com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "Movimentar pixels com a faixa de luz RGB pode causar erro na recepção de mensagens pelo Obloq. Recomenda-se evitar mover pixels com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "O movimento circular de pixels na faixa de luz RGB pode resultar em erro na recepção de mensagens pela porta serial suave do Obloq. Recomenda-se evitar o movimento circular de pixels com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "A exibição de colunas na faixa de luz RGB pode causar erro na recepção de mensagens pelo Obloq. Recomenda-se evitar a exibição de colunas com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "A recepção de mensagens pela porta serial suave do Obloq pode causar falha na inicialização do max7219.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "A recepção de mensagem pela porta serial suave do Obloq pode causar erro na exibição do max7219.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "A impressão pela porta serial suave pode causar erro na recepção de mensagens pelo Obloq. Recomenda-se evitar a impressão frequente pela porta serial suave.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "O envio de dados pela porta serial suave do Obloq pode causar erros na recepção de dados pela porta serial suave. Recomenda-se evitar o envio frequente de dados pelo Obloq.",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "O envio de dados pela porta serial suave do Obloq pode causar falha no acionamento do pino de interrupção. Recomenda-se evitar o envio frequente de dados pelo Obloq.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "A inicialização MQTT pela porta serial suave do Obloq pode causar erro na recepção de dados pela porta serial suave. Recomenda-se evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "A solicitação PUT pelo Obloq pela porta serial suave pode causar erro na recepção de dados pela porta serial suave. Recomenda-se evitar solicitar dados com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "A inicialização HTTP pelo Obloq pela porta serial suave pode causar erro na recepção de dados pela porta serial suave. Recomenda-se evitar a execução simultânea.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "A solicitação GET pelo Obloq pela porta serial suave pode causar erro na recepção de dados pela porta serial suave. Recomenda-se evitar solicitar dados com muita frequência.",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "A solicitação POST pelo Obloq pela porta serial suave pode causar erro na recepção de dados pela porta serial suave. Recomenda-se evitar solicitar dados com muita frequência.",
    "gui.blocklyText.compatible.shapedBlock": "Mudança na nova versão: o bloco de construção do programa de eventos com suas próprias variáveis foi substituído pelo bloco de construção do programa de eventos sem variáveis.",
    "gui.blocklyText.compatible.shapedBlock.variable": "Mudança na nova versão: mudança de bloco de construção de programa independente para bloco de construção de programa variável em programa de eventos.",
    "gui.blocklyText.compatible.booleanToRound": "Mudança na nova versão: mudança de bloco de construção de programa condicional para bloco de construção de programa numérico.",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "Mudança na nova versão: adição de opção de alternância de porta serial suave e dura no bloco de construção do programa.",
    "gui.blocklyText.compatible.mp3.deletePin": "Mudança na nova versão: remoção da opção de pino no bloco de construção do programa.",
    "gui.blocklyText.compatible.sen0206.addDropdown": "Mudança na nova versão: adição de opções de menu suspenso no bloco de construção do programa.",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "Mudança na nova versão: aumento da opção de pino no bloco de construção do programa.",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "Mudança na nova versão: modificação da opção de porta serial no bloco de construção do programa.",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "Mudança na nova versão: mudança do bloco de construção de programa de eventos para o bloco de construção de programa condicional.",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "Mudança na nova versão: exclusão de bloco.",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "Mudança na nova versão: modificação da caixa de seleção quadrada para uma caixa de seleção circular.",
    "gui.blocklyText.compatible.shouldBlockInitFirst": "A pequena modificação do módulo foi feita, por favor, redesenhe o programa.",
    "gui.blocklyText.pictureai.initToken": "Inicializar AI da imagem [SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "Obter imagem do arquivo local [IMAGE]",
    "gui.blocklyText.pictureai.cameraToggle": "Alternar para a câmera [CAMERA_LIST]",
    "gui.blocklyText.pictureai.getVideoImage": "Atalho de vídeo da imagem",
    "gui.blocklyText.pictureai.webPicture": "Obter imagem do site [TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH] salvar atalho de câmera local [TEXT]",
    "gui.blocklyText.pictureai.face": "Identificar imagem [TEXT] que contém rosto",
    "gui.blocklyText.pictureai.word": "Identificar imagem [TEXT] que contém [SELECT]",
    "gui.blocklyText.pictureai.picture": "Identificar imagem [TEXT] que contém [SELECT]",
    "gui.blocklyText.pictureai.gesture": "Identificar imagem [TEXT] que contém gesto",
    "gui.blocklyText.pictureai.TOPIC_W_A": "Palavras",
    "gui.blocklyText.pictureai.TOPIC_W_B": "Número",
    "gui.blocklyText.pictureai.TOPIC_W_C": "Número do carro",
    "gui.blocklyText.pictureai.TOPIC_W_D": "Caligrafia",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "Tornozelo esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "Orelha esquerda",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "Cotovelo esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "Olho esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "Quadril esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "Joelho esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "Canto da boca esquerda",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "Ombro esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "Pulso esquerdo",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "Pescoço",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "Nariz",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "Tornozelo direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "Orelha direita",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "Cotovelo direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "Olho direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "Quadril direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "Joelho direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "Canto da boca direita",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "Ombro direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "Pulso direito",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "Topo da cabeça",
    "gui.blocklyText.pictureai.initUserToken": "Alternar para uma conta separada [MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "Identificar imagem [TEXT] que contém ponto do corpo",
    "gui.blocklyText.pictureai.faceContrast": "Contrastar rosto na imagem [TEXT] com imagem [TEXT2]",
    "gui.blocklyText.pictureai.faceMerge": "Mesclar rosto na imagem [TEXT] e imagem [TEXT2]",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "Corpo de imagem",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "Objetos e cenas de propósito geral",
    "gui.blocklyText.pictureai.TOPIC_P_A": "Planta",
    "gui.blocklyText.pictureai.TOPIC_P_B": "Animal",
    "gui.blocklyText.pictureai.TOPIC_P_C": "Flor",
    "gui.blocklyText.pictureai.TOPIC_P_D": "Vegetais",
    "gui.blocklyText.pictureai.bodyPointSuccess": "Identificação bem-sucedida de pontos-chave no corpo humano?",
    "gui.blocklyText.pictureai.bodyPointInfo": "Obter coordenada XY do ponto do corpo [BODY] [XY]",
    "gui.blocklyText.pictureai.noPower": "Por favor, verifique se a conta abriu a funcionalidade",
    "gui.blocklyText.pictureai.pictureError": "A imagem não está no formato correto",
    "gui.blocklyText.pictureai.noPicture": "Por favor, selecione a imagem primeiro",
    "gui.blocklyText.pictureai.noVideo": "Certifique-se de que a câmera está conectada corretamente e que nenhum outro software está ocupado, e ligue a câmera ou reinicie o software",
    "gui.blocklyText.pictureai.defaultTitleText": "Janela de vídeo",
    "gui.blocklyText.pictureai.videoContainer": "Use [CON] mostrar cena da câmera",
    "gui.blocklyText.pictureai.TOPIC_stage": "Palco",
    "gui.blocklyText.pictureai.TOPIC_popup": "Popup",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "Mudança na nova versão: Alguns blocos de construção de programas foram removidos.",
    "gui.blocklyText.compatible.shapeChanged": "Mudança na nova versão: a forma do bloco mudou, por favor, arraste novamente.",
    "gui.blocklyText.pictureai.gestureList": "Gestos [TEXT]",
    "gui.blocklyText.pictureai.networkError": "Erro de rede, por favor, verifique a conexão de rede",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "Reconhecimento facial bem-sucedido?",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "Resultados de reconhecimento facial [TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "Número de rostos",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "Idade",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "Beleza",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "Gênero",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "Óculos",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "Tipo",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "Expressão",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "Probabilidade facial",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "Esquerda",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "Topo",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "Largura",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "Altura",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "Marco de referência",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "Oclusão",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "Desfoque",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "Completeness",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "Humano",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "Desenho animado",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "LIGAR",
    "gui.blocklyText.maqueenPlus.off": "DESLIGAR",
    "gui.blocklyText.maqueenPlus.Forward": "Para Frente",
    "gui.blocklyText.maqueenPlus.Backward": "Para Trás",
    "gui.blocklyText.maqueenPlus.left": "Esquerda",
    "gui.blocklyText.maqueenPlus.right": "Direita",
    "gui.blocklyText.maqueenPlus.all": "Tudo",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "Configurar motor [DIR] mover por [SPEED] velocidade [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "Configurar motor [DIR] parar",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "Compensação de velocidade do motor [DIR] [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "Ler velocidade do motor [DIR]",
    "gui.blocklyText.maqueenPlus.readMoterDir": "Ler direção do motor [DIR] (0: parado, 1: para frente, 2: para trás)",
    "gui.blocklyText.maqueenPlus.clearMoterRunedCircle": "Zerar as rotações da roda [DIR]",
    "gui.blocklyText.maqueenPlus.getMoterRunedCircle": "Obter as rotações da roda [DIR]",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] ângulo [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "Configurar [DIR] RGB mostrar [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "Ler o sensor de rastreamento de linha [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "Ler o valor de escala de cinza do sensor de sensação [INDEX]",
    "gui.blocklyText.maqueenPlus.getVersion": "Obter versão",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "Ler o sensor ultrassônico trigger [TRIG] echo [ECHO] unidade [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "Em IR recebido [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "Dados infravermelhos",
    "gui.blocklyText.maqueenPlus.cm": "CM ",
    "gui.blocklyText.maqueenPlus.inch": "POLEGADA",
    "gui.blocklyText.maqueenPlusV2.setMotorStop": "Configurar [DIR] motor parar",
    "gui.blocklyText.maqueenPlusV2.setMotorSpeedMove": "Configurar [DIR] motor direção [SPEEDDIR] velocidade [SPEED]",
    "gui.blocklyText.maqueenPlusV2.rotateForward": "Girar para frente",
    "gui.blocklyText.maqueenPlusV2.rotateBackward": "Girar para trás",
    "gui.blocklyText.maqueenPlusV2.on": "Abrir",
    "gui.blocklyText.maqueenPlusV2.off": "Fechar",
    "gui.blocklyText.maqueenPlusV2.leftLED": "Esquerda",
    "gui.blocklyText.maqueenPlusV2.rightLED": "Direita",
    "gui.blocklyText.maqueenPlusV2.allLED": "Tudo",
    "gui.blocklyText.maqueenPlusV2.left": "Esquerda",
    "gui.blocklyText.maqueenPlusV2.right": "Direita",
    "gui.blocklyText.maqueenPlusV2.all": "Tudo",
    "gui.blocklyText.maqueenPlusV2.setRgb": "Controlar [DIR] luz do LED [COLOR]",
    "gui.blocklyText.maqueenPlusV2.readSensationSensor": "Ler estado do sensor de linha [INDEX]",
    "gui.blocklyText.maqueenPlusV2.readSensationSensorYscaleValue": "Ler dados ADC do sensor de linha [INDEX]",
    "gui.blocklyText.maqueenPlusV2.readUltrasonic": "Configurar pino TRIG do sensor ultrassônico [TRIG] pino ECHO [ECHO] leia dados em cm",
    "gui.blocklyText.maqueenPlusV2.getVersion": "Ler versão",
    "gui.blocklyText.esp32.audioRecognition": "Obter comando de voz dentro de [TIME] segundos",
    "gui.blocklyText.esp32.audioRecordingFree": "Liberar cache de gravação",
    "gui.blocklyText.esp32.audioRecordingStart": "Gravar áudio com caminho de armazenamento [PATH] e duração de [TIME] segundo(s)",
    "gui.blocklyText.esp32.audioRecordingInit": "Inicializar gravação",
    "gui.blocklyText.esp32.audioPlay": "Áudio reprodução [URL]",
    "gui.blocklyText.esp32.audioControl": "Áudio [CONTROL]",
    "gui.blocklyText.esp32.AudioControl.A": "PAUSAR",
    "gui.blocklyText.esp32.AudioControl.B": "CONTINUAR",
    "gui.blocklyText.esp32.AudioControl.C": "PARAR",
    "gui.blocklyText.esp32.audioSetVolume": "Configurar volume de áudio como [VOLUME]",
    "gui.blocklyText.esp32.audioFree": "Desinicializar áudio",
    "gui.blocklyText.esp32.audioInit": "Inicializar áudio",
    "gui.blocklyText.esp32.audio": "Áudio",
    "gui.blocklyText.esp32.musicGetTempo": "Batidas atuais",
    "gui.blocklyText.esp32.musicSetTempo": "Configurar cada batida igual a [TICKS] notas, o número de batidas por minuto é [TEMPO]",
    "gui.blocklyText.esp32.musicSetPlaySpeed": "Configurar a velocidade de reprodução para [SPEED] vezes",
    "gui.blocklyText.esp32.musicPlayMelodySet": "Reproduzir música [MELODY] [WAIT] [LOOP] no pino [PIN]",
    "gui.blocklyText.esp32.musicPlayMelody": "Reproduzir música [MELODY] no pino [PIN]",
    "gui.blocklyText.esp32.musicPlayNoteFrom": "Tocar tom de [MIN] a [AMX] passo [STEP] duração [DURATION] ms no pino [PIN]",
    "gui.blocklyText.esp32.musicPlayNote": "Tocar tom [NOTE] atraso [DELAY] milissegundo(s) no pino [PIN]",
    "gui.blocklyText.esp32.musicTone": "Tom [NOTE]",
    "gui.blocklyText.esp32.musicPlayNoteContinuous": "Tocar tom contínuo [NOTE] no pino [PIN]",
    "gui.blocklyText.esp32.musicPlayList": "Tocar lista de notas [NOTELIST] [WAIT] [LOOP] no pino [PIN]",
    "gui.blocklyText.esp32.IsWait.A": "Esperar",
    "gui.blocklyText.esp32.IsWait.B": "Não esperar",
    "gui.blocklyText.esp32.IsLoop.A": "Reprodução em loop",
    "gui.blocklyText.esp32.IsLoop.B": "Reproduzir uma vez",
    "gui.blocklyText.esp32.getNote": "Nota [NOTE] batida [BEAT]",
    "gui.blocklyText.esp32.musicPlay": "Tocar nota [NOTE] no pino [PIN]",
    "gui.blocklyText.esp32.restore": "Restaurar configurações de música",
    "gui.blocklyText.esp32.stopPlay": "Parar de tocar música no pino [PIN]",
    "gui.blocklyText.esp32.stopPlay_default": "Padrão",
    "gui.blocklyText.mpyUART.input": "Obter texto de entrada do post serial, título [TITLE]",
    "gui.blocklyText.mpyUART.close": "Fechar porta serial [UART]",
    "gui.blocklyText.mpyUART.readLength": "Porta serial [UART] ler dados com comprimento [LENGTH]",
    "gui.blocklyText.mpyUART.read": "Porta serial [UART] ler dados",
    "gui.blocklyText.mpyUART.readline": "Porta serial [UART] ler uma linha de dados",
    "gui.blocklyText.mpyUART.any": "Porta serial [UART] tem dados legíveis?",
    "gui.blocklyText.mpyUART.writeBytes": "Porta serial [UART] escrever bytes [BYTES]",
    "gui.blocklyText.mpyUART.writeByte": "Porta serial [UART] escrever um byte [BYTE]",
    "gui.blocklyText.mpyUART.init": "Inicializar porta serial [UART] como baudrate [BAUDRATE] tx [TXPIN] rx [RXPIN]",
    "gui.blocklyText.mpyUART.initTello": "Inicializar porta serial [UART] rx [RXPIN] tx [TXPIN] como baudrate [BAUDRATE]",
    "gui.blocklyText.mpyUART.writeStringTello": "Porta serial [UART] escrever string [TEXT] [ENDSYMBOL] envolver",
    "gui.blocklyText.mpyUART.anyTello": "Porta serial [UART] tem dados legíveis?",
    "gui.blocklyText.mpyUART.readTello": "Porta serial [UART] ler dados",
    "gui.blocklyText.mpyUART.closeTello": "Fechar porta serial [UART]",
    "gui.blocklyText.mpyUART.writeString": "Porta serial [UART] escrever string [TEXT] [ENDSYMBOL] envolver",
    "gui.blocklyText.mpyUART.no": "Não",
    "gui.blocklyText.mpyUART.auto": "Automático",
    "gui.blocklyText.mpyI2C.scan": "Resultado da verificação I2C",
    "gui.blocklyText.mpyI2C.read": "Endereço I2C [ADDRESS] ler número de bytes [BYTES]",
    "gui.blocklyText.mpyI2C.write": "Endereço I2C [ADDRESS] é escrito para [DATA]",
    "gui.blocklyText.mpyI2C.init": "Inicializar I2C SCL [SCLPIN] SDA [SDAPIN] taxa de transmissão [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoInitBoard": "Inicializar a frequência I2C embarcada [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoUniversal": "Inicializar I2C geral SCL [SCLPIN] SDA [SDAPIN] frequência [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoWrite": "[OBJ] Escrever [DATA] para o endereço [ADDRESS]",
    "gui.blocklyText.mpyI2C.maixduinoRead": "[OBJ] Ler [BYTES] dados do endereço [ADDRESS]",
    "gui.blocklyText.mpyI2C.maixduinoScan": "[OBJ] Resultado da verificação",
    "gui.blocklyText.esp32.readPinLevel": "Duração (us) do pulso externo [LEVEL] em [PIN]",
    "gui.blocklyText.esp32.noPull": "Sem pull (nenhum pull)",
    "gui.blocklyText.esp32.pullDown": "Pull down (puxar para baixo)",
    "gui.blocklyText.esp32.pullUp": "Pull up (puxar para cima)",
    "gui.blocklyText.esp32.setPinMode": "Configurar o pino [PIN] como modo [MODE] [PULL] e definir o valor como [LEVEL]",
    "gui.blocklyText.esp32.leakyOutput": "Saída vazante",
    "gui.blocklyText.mpyServo.setServo": "Configurar o servo [PIN] ângulo como [ANGLE] largura de pulso de [MIN] us a [MAX] us faixa de atuação como [ANGLEMAX]",
    "gui.blocklyText.servo.init": "Configurar o servo [PIN] ângulo como [ANGLE] temporizador [TIMER]",
    "gui.blocklyText.mpyServo.setAngle": "Configurar o servo [PIN] ângulo como [ANGLE]",
    "gui.blocklyText.esp32.shaked": "Agitado",
    "gui.blocklyText.esp32.thrown": "Lançado",
    "gui.blocklyText.esp32.tilt_forward": "Inclinado para frente",
    "gui.blocklyText.esp32.tilt_back": "Inclinado para trás",
    "gui.blocklyText.esp32.tilt_right": "Inclinado para a direita",
    "gui.blocklyText.esp32.tilt_left": "Inclinado para a esquerda",
    "gui.blocklyText.esp32.tilt_none": "Plano",
    "gui.blocklyText.esp32.attitudeEvent": "Quando a placa está [ATTITUDE]",
    "gui.blocklyText.esp32.to_high": "? Baixo para alto",
    "gui.blocklyText.esp32.to_low": "? Alto para baixo",
    "gui.blocklyText.esp32.pinLevelEvent": "Quando o nível do pino [PIN] muda de [TRANSITION]",
    "gui.blocklyText.mpyTimer.setEvent": "Configurar temporizador [INDEX] [MODE] período [PERIOD] milissegundos",
    "gui.blocklyText.mpyTimer.repeated": "Repetido",
    "gui.blocklyText.mpyTimer.delayed": "Atrasado",
    "gui.blocklyText.mpyTimer.conditionEvent": "Configurar temporizador [INDEX]: quando [CONDITION]",
    "gui.blocklyText.mpyTimer.getCount": "Valor de contagem do temporizador/evento [INDEX]",
    "gui.blocklyText.mpyTimer.clearEvent": "Limpar temporizador/evento [INDEX]",
    "gui.blocklyText.mpyDictionary.init": "Inicializar dicionário [CONTENT]",
    "gui.blocklyText.mpyDictionary.getValue": "Valor do dicionário [CONTENT] da chave [KEY]",
    "gui.blocklyText.mpyDictionary.setValue": "Configurar valor da chave [KEY] do dicionário [CONTENT] para [VALUE]",
    "gui.blocklyText.mpyDictionary.deleteKey": "Excluir chave [KEY] do dicionário [CONTENT]",
    "gui.blocklyText.mpyDictionary.clear": "Limpar dicionário [CONTENT]",
    "gui.blocklyText.mpyDictionary.isInclude": "Dicionário [CONTENT] inclui chave [KEY]?",
    "gui.blocklyText.mpyDictionary.getLength": "Comprimento do dicionário [CONTENT]",
    "gui.blocklyText.mpyDictionary.list": "Lista de [TYPE] no dicionário [CONTENT]",
    "gui.blocklyText.mpyDictionary.TypeIndex.A": "Chave",
    "gui.blocklyText.mpyDictionary.TypeIndex.B": "Valor",
    "gui.blocklyText.mpyList.join": "Lista [LIST] usa separadores [SYMBOL] para combinar texto",
    "gui.blocklyText.mpyList.split": "Texto [TEXT] usa separadores [SYMBOL] para fazer listas",
    "gui.blocklyText.mpyList.init": "Inicializar lista [CONTENT]",
    "gui.blocklyText.mpyList.clear": "Limpar lista [CONTENT]",
    "gui.blocklyText.mpyList.getLength": "Comprimento da lista [CONTENT]",
    "gui.blocklyText.mpyList.isEmpty": "[CONTENT] está vazio?",
    "gui.blocklyText.mpyList.getValue": "Obter valor no índice [INDEX] na lista [CONTENT]",
    "gui.blocklyText.mpyList.getValueRange": "Retornar lista [CONTENT] de [TYPE1] [NUM1] itens para [TYPE2] [NUM2] itens",
    "gui.blocklyText.mpyList.insert": "Inserir [VALUE] no índice [INDEX] na lista [CONTENT]",
    "gui.blocklyText.mpyList.setValue": "Configurar índice [INDEX] para [VALUE] na lista [CONTENT]",
    "gui.blocklyText.mpyList.delete": "Excluir valor no índice [INDEX] da lista [CONTENT]",
    "gui.blocklyText.mpyList.append": "Anexar [APPEND] à lista [CONTENT]",
    "gui.blocklyText.mpyList.extend": "Lista [LIST1] anexar lista [LIST2]",
    "gui.blocklyText.mpyList.findIndex": "Encontrar índice de [VALUE] na lista [CONTENT]",
    "gui.blocklyText.mpyList.sort": "Lista [LIST] ordenar por [TYPE] como [MODE]",
    "gui.blocklyText.mpyList.SortModeIndex.A": "Crescente",
    "gui.blocklyText.mpyList.SortModeIndex.B": "Decrescente",
    "gui.blocklyText.mpyList.SortTypeIndex.A": "Número",
    "gui.blocklyText.mpyList.SortTypeIndex.B": "Letra",
    "gui.blocklyText.mpyList.SortTypeIndex.C": "Letra, ignorar maiúsculas/minúsculas",
    "gui.blocklyText.mpySet.init": "Inicializar conjunto [CONTENT]",
    "gui.blocklyText.mpySet.update": "Atualizar conjunto [CONTENT1] como o [TYPE] com o conjunto [CONTENT2]",
    "gui.blocklyText.mpySet.TypeIndex.A": "Interseção",
    "gui.blocklyText.mpySet.TypeIndex.B": "União",
    "gui.blocklyText.mpySet.TypeIndex.C": "Diferença",
    "gui.blocklyText.mpySet.addValue": "Atualizar conjunto [CONTENT] com [VALUE]",
    "gui.blocklyText.mpySet.isSubsetSuperset": "Conjunto [CONTENT1] é [TYPE] de conjunto [CONTENT2]?",
    "gui.blocklyText.mpySet.SubsetSupersetType.A": "Subconjunto",
    "gui.blocklyText.mpySet.SubsetSupersetType.B": "Superconjunto",
    "gui.blocklyText.mpySet.intersectionUnionSetDifference": "[TYPE] do conjunto [CONTENT1] e conjunto [CONTENT2]",
    "gui.blocklyText.mpySet.length": "Comprimento do conjunto [CONTENT]",
    "gui.blocklyText.mpySet.pop": "Retornar um item aleatório do conjunto [CONTENT] e removê-lo",
    "gui.blocklyText.mpyTuple.init": "Inicializar tupla [CONTENT]",
    "gui.blocklyText.mpyTuple.minMaxLength": "Tupla [CONTENT] [TYPE]",
    "gui.blocklyText.mpyTuple.TypeIndex.A": "Valor mínimo",
    "gui.blocklyText.mpyTuple.TypeIndex.B": "Valor máximo",
    "gui.blocklyText.mpyTuple.TypeIndex.C": "Comprimento",
    "gui.blocklyText.mpyTuple.isInclude": "Tupla [CONTENT] inclui [STRING]?",
    "gui.blocklyText.mpyTuple.getValueRange": "Retornar tupla [CONTENT] de [TYPE1] [NUM1] itens para [TYPE2] [NUM2] itens",
    "gui.blocklyText.mpyTuple.IndexType.A": "#",
    "gui.blocklyText.mpyTuple.IndexType.B": "contagem regressiva #",
    "gui.blocklyText.mpyTuple.listToTulpe": "lista [LIST] conversão para tupla",
    "gui.blocklyText.mpyTuple.getValue": "obter valor no índice [INDEX] na tupla [CONTENT]",
    "gui.blocklyText.mpyTuple.tupleToList": "converter tupla [CONTENT] em lista",
    "gui.blocklyText.esp32.DisplayTypeMenu.A": "normal",
    "gui.blocklyText.esp32.DisplayTypeMenu.B": "rev",
    "gui.blocklyText.esp32.displayInnerImageInXY": "exibir imagem incorporada [IMAGE] em x:[X] y:[Y] padrão [TYPE]",
    "gui.blocklyText.esp32.displayFileSystemImageInXY": "carregar imagem do sistema de arquivos [IMAGE] exibir em x:[X] y:[Y] padrão [TYPE]",
    "gui.blocklyText.weather.GetWeatherWithCityName": "obter [WEATHER] na cidade [CITY]",
    "gui.blocklyText.pictureai.createFaceClass": "criar classe de rosto [NAME]",
    "gui.blocklyText.pictureai.pictureaiAddFaceToClass": "resultado de reconhecimento nomeado [RES] e adicionado à classe de rosto [CLASSNAME]",
    "gui.blocklyText.pictureai.pictureaiSelectResultsFromClass": "pesquisar resultados de reconhecimento na biblioteca de rostos [CLASSNAME] onde a confiabilidade >= [NUMBER]",
    "gui.blocklyText.pictureai.pictureaiWhenSearchName": "Quando os resultados da pesquisa foram [NAME]",
    "gui.blocklyText.pictureai.pictureaiSearchedName": "nome dos resultados da pesquisa",
    "gui.blocklyText.pictureai.pictureaiSelectResultReliability": "confiabilidade dos resultados da pesquisa",
    "gui.blocklyText.pictureai.pleaseUseSelefAccount": "interruptor de função da classe de rosto para conta separada",
    "gui.blocklyText.pictureai.addfaceclasserror": "Erro ao criar classe de face",
    "gui.blocklyText.pictureai.faceclassnameerror": "O nome da classe de face é composto por números, letras e sublinhados",
    "gui.blocklyText.pictureai.faceclassnameexist": "O nome da classe já existe",
    "gui.blocklyText.pictureai.addfaceerror": "Erro ao adicionar face",
    "gui.blocklyText.pictureai.selectfaceclasserror": "Erro ao pesquisar classe de face",
    "gui.blocklyText.pictureai.notfoundmatch": "Usuário correspondente não encontrado",
    "gui.blocklyText.pictureai.facenameerror": "O nome da face é composto por números, letras e sublinhados",
    "boost.color.any": "qualquer cor",
    "boost.color.black": "preto",
    "boost.color.blue": "azul",
    "boost.color.green": "verde",
    "boost.color.red": "vermelho",
    "boost.color.white": "branco",
    "boost.color.yellow": "amarelo",
    "boost.getMotorPosition": "posição do motor [MOTOR_REPORTER_ID]",
    "boost.getTiltAngle": "ângulo de inclinação [TILT_DIRECTION]",
    "boost.motorDirection.backward": "para lá",
    "boost.motorDirection.forward": "para cá",
    "boost.motorDirection.reverse": "inverter",
    "boost.motorOff": "desligar o motor [MOTOR_ID]",
    "boost.motorOn": "ligar o motor [MOTOR_ID]",
    "boost.motorOnFor": "ligar o motor [MOTOR_ID] por [DURATION] segundos",
    "boost.motorOnForRotation": "ligar o motor [MOTOR_ID] por [ROTATION] rotações",
    "boost.seeingColor": "ver tijolo [COLOR]?",
    "boost.setLightHue": "definir cor da luz para [HUE]",
    "boost.setMotorDirection": "definir direção do motor [MOTOR_ID] como [MOTOR_DIRECTION]",
    "boost.setMotorPower": "definir velocidade do motor [MOTOR_ID] para [POWER] %",
    "boost.tiltDirection.any": "qualquer",
    "boost.tiltDirection.down": "para baixo",
    "boost.tiltDirection.left": "esquerda",
    "boost.tiltDirection.right": "direita",
    "boost.tiltDirection.up": "para cima",
    "boost.whenColor": "quando ver tijolo [COLOR]",
    "boost.whenTilted": "quando inclinado [TILT_DIRECTION_ANY]",
    "ev3.beepNote": "bipe nota [NOTE] por [TIME] segs",
    "ev3.buttonPressed": "botão [PORT] pressionado?",
    "ev3.getBrightness": "brilho",
    "ev3.getDistance": "distância",
    "ev3.getMotorPosition": "posição do motor [PORT]",
    "ev3.motorSetPower": "definir potência do motor [PORT] para [POWER] %",
    "ev3.motorTurnClockwise": "girar motor [PORT] para cá por [TIME] segundos",
    "ev3.motorTurnCounterClockwise": "girar motor [PORT] para lá por [TIME] segundos",
    "ev3.whenBrightnessLessThan": "quando brilho < [DISTANCE]",
    "ev3.whenButtonPressed": "quando botão [PORT] pressionado",
    "ev3.whenDistanceLessThan": "quando distância < [DISTANCE]",
    "wedo2.getDistance": "distância",
    "wedo2.getTiltAngle": "ângulo de inclinação [TILT_DIRECTION]",
    "wedo2.isTilted": "inclinado [TILT_DIRECTION_ANY]?",
    "wedo2.motorDirection.backward": "para lá",
    "wedo2.motorDirection.forward": "para cá",
    "wedo2.motorDirection.reverse": "inverter",
    "wedo2.motorId.a": "motor A",
    "wedo2.motorId.all": "todos os motores",
    "wedo2.motorId.b": "motor B",
    "wedo2.motorId.default": "motor",
    "wedo2.motorOff": "desligar [MOTOR_ID]",
    "wedo2.motorOn": "ligar [MOTOR_ID]",
    "wedo2.motorOnFor": "ligar [MOTOR_ID] por [DURATION] segundos",
    "wedo2.playNoteFor": "tocar nota [NOTE] por [DURATION] segundos",
    "wedo2.setLightHue": "definir cor da luz para [HUE]",
    "wedo2.setMotorDirection": "definir direção de [MOTOR_ID] como [MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "definir potência de [MOTOR_ID] para [POWER]",
    "wedo2.tiltDirection.any": "qualquer",
    "wedo2.tiltDirection.down": "para baixo",
    "wedo2.tiltDirection.left": "esquerda",
    "wedo2.tiltDirection.right": "direita",
    "wedo2.tiltDirection.up": "para cima",
    "wedo2.whenDistance": "quando distância [OP] [REFERENCE]",
    "wedo2.whenTilted": "quando inclinado [TILT_DIRECTION_ANY]",
    "gui.blocklyText.video.notfound": "não encontrado",
    "gui.blocklyText.machinelearning.drawResults": "[TOGGLE] alternar desenho dos resultados de reconhecimento",
    "gui.blocklyText.machinelearning.initKNNImageClass": "Classificação KNN no PC",
    "gui.blocklyText.machinelearning.imageClassificationFromCamera": "Classificar a imagem da câmera como [TEXT]",
    "gui.blocklyText.machinelearning.imageClassificationFromDir": "Classificar a imagem da pasta local [DIR] como [TEXT]",
    "gui.blocklyText.machinelearning.startKNNTrain": "Iniciar treinamento de classificação KNN",
    "gui.blocklyText.machinelearning.predictImageClass": "KNN [TOGGLE] reconhecendo a classificação da imagem da câmera",
    "gui.blocklyText.machinelearning.getImageClassification": "KNN reconhecer o resultado da classificação",
    "gui.blocklyText.machinelearning.getImageClassificationReliability": "KNN reconhecer a credibilidade",
    "gui.blocklyText.machinelearning.clearImageClassificationLabel": "KNN limpar rótulo [LABEL]",
    "gui.blocklyText.machinelearning.clearImageClassificationModel": "KNN limpar dados do modelo de classificação",
    "gui.blocklyText.machinelearning.loadImageClassificationModel": "KNN carregar o modelo de classificação do lado do computador a partir de [PATH]",
    "gui.blocklyText.machinelearning.exportImageClassificationModel": "KNN salvar o modelo de classificação",
    "gui.blocklyText.machinelearning.traceFace": "FaceAPI inicializa o rastreamento facial",
    "gui.blocklyText.machinelearning.drawFaceAPI": "FaceAPI [TOGGLE] marcar pontos-chave do rosto",
    "gui.blocklyText.machinelearning.traceFaceNumber": "Número de rostos FaceAPI",
    "gui.blocklyText.machinelearning.getFaceByIndex": "FaceAPI obter [PART] do rosto [INDEX] com coordenada [TYPE]",
    "gui.blocklyText.machinelearning.initfaceContrast": "FaceAPI inicializar reconhecimento de rosto específico",
    "gui.blocklyText.machinelearning.refFaceFromCamera": "FaceAPI coletar dados faciais da câmera marcados como [NAME]",
    "gui.blocklyText.machinelearning.refFaceFromDir": "FaceAPI coletar dados faciais da pasta [DIR] marcados como nome da pasta",
    "gui.blocklyText.machinelearning.faceAPIFindFace": "FaceAPI identificar o rosto na câmera uma vez",
    "gui.blocklyText.machinelearning.allowFaceAPIResult": "FaceAPI resultados de reconhecimento facial",
    "gui.blocklyText.machinelearning.allowFaceAPIResultScore": "FaceAPI credibilidade dos resultados de reconhecimento facial",
    "gui.blocklyText.machinelearning.clearFaceAPIModel": "FaceAPI Limpar dados do modelo facial",
    "gui.blocklyText.machinelearning.ml5WhenSearchFaceName": "quando pesquisar rótulo [NAME] credibilidade >= [VALUE]%",
    "gui.blocklyText.machinelearning.machinelearningTracePose": "PoseNet inicializar o rastreamento de postura",
    "gui.blocklyText.machinelearning.drawPoseNet": "PoseNet [TOGGLE] marcar pontos-chave da postura",
    "gui.blocklyText.machinelearning.getPoseByIndex": "PoseNet obter [PART] da postura [INDEX] com coordenada [TYPE]",
    "gui.blocklyText.machinelearning.initImageClassify": "Inicializar classificador de imagem de reconhecimento de objeto (mobileNet)",
    "gui.blocklyText.machinelearning.toggleImageClassify": "[TOGGLE] Classificação em tempo real do reconhecimento de imagem de objeto",
    "gui.blocklyText.machinelearning.imageClassifyResult": "Resultados de reconhecimento de objeto",
    "gui.blocklyText.machinelearning.imageClassifyResultScore": "Credibilidade do reconhecimento de objeto",
    "gui.blocklyText.huskylens.initVisualModal": "inicializar ferramenta de visualização de IA para [TYPE]",
    "gui.blocklyText.huskylens.classifyImageFromCamera": "adicionar imagens da tela da câmera do computador ao classificador [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromHuskylens": "adicionar imagens da tela da HuskyLens à câmera ao classificador [TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromDir": "adicionar imagens da pasta local [DIR] ao classificador [TEXT]",
    "gui.blocklyText.huskylens.visualModalClearLabel": "limpar classificação [LABEL]",
    "gui.blocklyText.huskylens.visualModalClearAllLabels": "limpar toda a classificação do classificador",
    "gui.blocklyText.huskylens.deployClassificationToHuskylens": "Treinar o modelo e implantar na HuskyLens",
    "gui.blocklyText.machinelearning.loadlmodel": "caminho do modelo",
    "gui.blocklyText.pictureai.TOPIC_BODY_mouth": "boca",
    "gui.blocklyText.pictureai.TOPIC_BODY_jaw_outline": "maxilar",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye_brow": "sobrancelha esquerda",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye_brow": "sobrancelha direita",
    "gui.blocklyText.huskylens.videoContainer": "usar [CON] para mostrar a tela da câmera do computador",
    "gui.blocklyText.huskylens.videoToggle": "estado da câmera do computador [VIDEO_STATE]",
    "gui.blocklyText.huskylens.cameraToggle": "alternar para a câmera do computador [CAMERA_LIST]",
    "gui.blocklyText.huskylens.exportImage": "A câmera do computador tira uma foto e salva na pasta local [TEXT]",
    "gui.blocklyText.dialog.ml5.loadModel": "Carregando o modelo, a página pode parecer travada, por favor, aguarde...",
    "gui.blocklyText.dialog.ml5.reInitPictureClassify": "os dados da imagem já existem no classificador. A reinitialização esvaziará os dados existentes. Confirmar para continuar?",
    "gui.blocklyText.dialog.ml5.whenDeleteImageAfterTrain": "Esses dados de imagem foram treinados, após a exclusão, é necessário treiná-los novamente para ter efeito.",
    "gui.blocklyText.dialog.ml5.renameLabel": "A etiqueta [LABEL] já existe, você deseja mesclá-la?",
    "gui.blocklyText.dialog.ml5.modelIsEmpty": "Não há dados classificados neste modelo. Por favor, treine o modelo primeiro.",
    "gui.blocklyText.dialog.ml5.shouldInitKNN": "Por favor, inicialize a classificação KNN primeiro.",
    "gui.blocklyText.dialog.huskylens.emptyAllImage": "Esta operação excluirá todas as categorias, tem certeza de que deseja continuar?",
    "gui.blocklyText.dialog.huskylens.HuskyLensTakePhotoError": "Tirar fotos da HuskyLens falhou, por favor, tente novamente",
    "gui.blocklyText.dialog.huskylens.overImageMaxLen": "O número máximo de classificadores [TYPE] é [LEN], o limite foi excedido, por favor, organize e tente novamente",
    "gui.blocklyText.dialog.huskylens.notAllowChinese": "O nome da categoria deve ser composto por números e letras com no máximo 50 dígitos e não pode conter caracteres chineses e especiais.",
    "gui.blocklyText.dialog.ml5.isNotKNN": "O classificador de tipo de reconhecimento facial não pode participar do treinamento KNN",
    "gui.blocklyText.machinelearning.start": "iniciar",
    "gui.blocklyText.machinelearning.stop": "parar",
    "gui.blocklyText.huskyLensPro.ML5MODEL.A": "classificação KNN",
    "gui.blocklyText.huskyLensPro.ML5MODEL.B": "reconhecimento facial",
    "gui.blocklyText.huskyLensPro.changeAlgorithm": "alternar algoritmo HuskyLens para [ALGORITHM]",
    "gui.blocklyText.huskyLensPro.learndIDCount": "HuskyLens obter um número total de IDs aprendidos",
    "gui.blocklyText.huskyLensPro.isOnTheScreen": "HuskyLens verificar se [BOXARROW] está na tela?",
    "gui.blocklyText.huskyLensPro.parametersNearTheCenter": "HuskyLens obter [PARAMETER] de [BOXARROW] mais próxima do centro da tela",
    "gui.blocklyText.huskyLensPro.idIsLearned": "HuskyLens verificar se o ID [ID] está aprendido?",
    "gui.blocklyText.huskyLensPro.idIsOnTheScreen": "HuskyLens verificar se o ID [ID] [BOXARROW] está na tela?",
    "gui.blocklyText.huskyLensPro.idParametersNearTheCenter": "HuskyLens obter [PARAMETER] do ID [ID] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.boxCount": "HuskyLens obter um número total de [BOXARROW]",
    "gui.blocklyText.huskyLensPro.boxarrowParameter": "HuskyLens obter [PARAMETER] do No.[INDEX] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.idBoxCount": "HuskyLens obter um número total de ID [ID] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.idBoxarrowParameter": "HuskyLens obter [PARAMETER] do ID [ID] No.[INDEX] [BOXARROW]",
    "gui.blocklyText.huskyLensPro.learnOnce": "HuskyLens aprender ID [ID] uma vez automaticamente",
    "gui.blocklyText.huskyLensPro.clearLearn": "HuskyLens esquecer todos os dados de aprendizado do algoritmo atual",
    "gui.blocklyText.huskyLensPro.setIDname": "HuskyLens nomear ID [ID] do algoritmo atual como [NAME]",
    "gui.blocklyText.huskyLensPro.writeOSD": "HuskyLens mostrar textos personalizados [NAME] na posição x [X] y [Y] na tela",
    "gui.blocklyText.huskyLensPro.clearOSD": "HuskyLens limpar todos os textos personalizados na tela",
    "gui.blocklyText.huskyLensPro.requestPicture": "HuskyLens [TYPE] e salvar em uma pasta local [PATH]",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.A": "tirar uma foto",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.B": "captura de tela",
    "gui.blocklyText.huskyLensPro.TakeScreenshotToSDCard": "HuskyLens [TYPE] e salvar no cartão SD",
    "gui.blocklyText.huskyLensPro.xCenter": "Centro-X",
    "gui.blocklyText.huskyLensPro.yCenter": "Centro-Y",
    "gui.blocklyText.huskyLensPro.width": "Largura",
    "gui.blocklyText.huskyLensPro.height": "Altura",
    "gui.blocklyText.huskyLensPro.xStart": "Início-X",
    "gui.blocklyText.huskyLensPro.yStart": "Início-Y",
    "gui.blocklyText.huskyLensPro.xEnd": "Fim-X",
    "gui.blocklyText.huskyLensPro.yEnd": "Fim-Y",
    "gui.blocklyText.huskyLensPro.QRRecognition": "reconhecimento de código QR",
    "gui.blocklyText.huskyLensPro.barcodeRecognition": "reconhecimento de código de barras",
    "gui.blocklyText.huskyLensPro.saveFailed": "Falha ao salvar a imagem!",
    "gui.blocklyText.huskyLensPro.yes": "sim",
    "gui.blocklyText.huskyLensPro.cancel": "cancelar",
    "gui.blocklyText.huskyLensPro.promptInitializeClassifier": "Por favor, inicialize o classificador de imagem primeiro",
    "gui.blocklyText.huskyLensPro.promptInitializeKNNClassifier": "Por favor, inicialize o classificador KNN primeiro",
    "gui.blocklyText.huskyLensPro.promptAddClassificationData": "Por favor, adicione dados de classificação primeiro",
    "gui.blocklyText.huskyLensPro.setTitleText": "Classificador de imagem de IA-KNN da HuskyLens",
    "gui.blocklyText.huskyLensPro.tooManyPictures": "Há muitas imagens, apenas as cinquenta primeiras imagens são extraídas",
    "gui.blocklyText.huskyLensPro.checkPath": "Por favor, verifique se há imagens no caminho [PATH]",
    "gui.blocklyText.huskyLensPro.success": "sucesso",
    "gui.blocklyText.huskyLensPro.importImg": "[COUNT1] imagens importadas com sucesso desta vez, [COUNT2] falharam",
    "gui.blocklyText.huskyLensPro.recognitionResult": "O resultado do reconhecimento:",
    "gui.blocklyText.tello.telloSetPitchRollYaw": "Defina a quantidade dos quatro bastões do controle remoto: rotação [ROLL] inclinação [PITCH] aceleração [STRENGTH] guinada [YAW]",
    "gui.blocklyText.tello.telloQuerySpeed": "Velocidade Atual",
    "gui.blocklyText.tello.telloTime": "Tempo de funcionamento do motor (s)",
    "gui.blocklyText.tello.telloWIFISign": "Wi-Fi SNR",
    "gui.blocklyText.tello.telloSDKVersion": "Versão do SDK Tello",
    "gui.blocklyText.tello.telloSN": "Código SN do Tello",
    "gui.blocklyText.tello.reset": "Redefinir",
    "gui.blocklyText.tello.telloFlyCurve": "Curva de voo distorcida x1[X1]cm y1[Y1]cm z1[Z1]cm e x2[X2]cm y2[Y2]cm z2[Z2]cm Velocidade ??[SPEED] cm/s",
    "gui.blocklyText.tello.telloStopEmerygency": "Parada de emergência",
    "gui.blocklyText.tello.telloGetHeight": "Altura relativa (cm)",
    "gui.blocklyText.tello.telloBarometer": "Altura do barômetro (m)",
    "gui.blocklyText.tello.telloTofDistance": "Altura TOF (cm)",
    "gui.blocklyText.tello.telloTempMin": "Temperatura mínima da placa-mãe (°C)",
    "gui.blocklyText.tello.telloTempMax": "Temperatura máxima da placa-mãe (°C)",
    "gui.blocklyText.tello.TelloAttitudePitch": "Ângulo de atitude do eixo de inclinação (°)",
    "gui.blocklyText.tello.TelloAttitudeRoll": "Ângulo de atitude do eixo de rotação (°)",
    "gui.blocklyText.tello.TelloAttitudeYaw": "Ângulo de atitude do eixo de panorâmica (°)",
    "gui.blocklyText.tello.telloAccelerationX": "Aceleração no eixo X (0,001g)",
    "gui.blocklyText.tello.telloAccelerationY": "Aceleração no eixo Y (0,001g)",
    "gui.blocklyText.tello.telloAccelerationZ": "Aceleração no eixo Z (0,001g)",
    "gui.blocklyText.tello.telloSpeedX": "Velocidade no eixo X (cm/s)",
    "gui.blocklyText.tello.telloSpeedY": "Velocidade no eixo Y (cm/s)",
    "gui.blocklyText.tello.telloSpeedZ": "Velocidade no eixo Z (cm/s)",
    "gui.blocklyText.tello.telloFlyToXY": "Voar (em relação à aeronave) x[X]cm y[Y]cm z[Z]cm velocidade [SPEED]cm/s",
    "gui.blocklyText.tello.telloFlyToMissionPad": "voar para o MissionPad x[X]cm y[Y]cm z[Z]cm com velocidade [SPEED] cm/s Mid [MID]",
    "gui.blocklyText.tello.TelloFlyCurveMissionPad": "Curva de voo distorcida sob o cartão de desafio x1[X1]cm y1[Y1]cm z1[Z1]cm e x2[X2]cm y2[Y2]cm z2[Z2]cm velocidade [SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.telloJumpInMissionPad": "Pular ao longo do cartão de desafio x[X]cm y[Y]cm z[Z]cm velocidade [SPEED]cm/s guinada [YAW] grau de Mid1 [MID1] para Mid2 [MID2]",
    "gui.blocklyText.telloesp32.telloGetStatus": "Obter status uma vez, tempo limite [TIMEOUT] ms",
    "gui.blocklyText.telloesp32.telloReturnStatusChallenge": "Obter do resultado [STATUSCHALLENGE]",
    "gui.blocklyText.telloesp32.statusChallengeX": "Coordenada X relativa do cartão desafio",
    "gui.blocklyText.telloesp32.statusChallengeY": "Coordenada Y relativa do cartão desafio",
    "gui.blocklyText.telloesp32.statusChallengeZ": "Coordenada Z relativa do cartão desafio",
    "gui.blocklyText.telloesp32.statusChallengePitch": "Ângulo de inclinação relativo do cartão desafio",
    "gui.blocklyText.telloesp32.statusChallengeYaw": "Ângulo de guinada relativo do cartão desafio",
    "gui.blocklyText.telloesp32.statusChallengeRoll": "Ângulo de rotação relativo do cartão desafio",
    "gui.blocklyText.telloesp32.telloReturnStatusAttitude": "Obter do resultado [STATUSATTITUDE]",
    "gui.blocklyText.telloesp32.statusAttitudePitch": "Ângulo de inclinação",
    "gui.blocklyText.telloesp32.statusAttitudeYaw": "Ângulo de guinada",
    "gui.blocklyText.telloesp32.statusAttitudeRoll": "Ângulo de rotação",
    "gui.blocklyText.telloesp32.statusAttitudeXSpeed": "Velocidade do eixo X",
    "gui.blocklyText.telloesp32.statusAttitudeYSpeed": "Velocidade do eixo Y",
    "gui.blocklyText.telloesp32.statusAttitudeZSpeed": "Velocidade do eixo Z",
    "gui.blocklyText.telloesp32.statusAttitudeXAcc": "Aceleração do eixo X",
    "gui.blocklyText.telloesp32.statusAttitudeYAcc": "Aceleração do eixo Y",
    "gui.blocklyText.telloesp32.statusAttitudeZAcc": "Aceleração do eixo Z",
    "gui.blocklyText.telloesp32.statusChallengeId": "ID do Cartão Desafio",
    "gui.blocklyText.telloesp32.telloReturnStatusNormal": "Obter do resultado [STATUSNORMAL]",
    "gui.blocklyText.telloesp32.statusNormalMaxTemp": "Temperatura máxima",
    "gui.blocklyText.telloesp32.statusNormalLowTemp": "Temperatura mais baixa",
    "gui.blocklyText.telloesp32.statusNormalTof": "Distância TOF",
    "gui.blocklyText.telloesp32.statusNormalHeight": "Altura relativa",
    "gui.blocklyText.telloesp32.statusNormalBat": "Bateria atual",
    "gui.blocklyText.telloesp32.statusNormalBaro": "Altitude barométrica",
    "gui.blocklyText.telloesp32.statusNormalMotor": "Tempo do motor",
    "gui.blocklyText.tello.telloRotateWithYaw": "A aeronave gira para o ângulo de guinada [YAW] relativo ao cartão desafio [MID]",
    "gui.blocklyText.tello.telloStopAndHover": "Parar movimento e pairar",
    "gui.blocklyText.tello.MissionPad": "Número do Cartão Desafio",
    "gui.blocklyText.tello.MissionPadX": "Cartão Desafio X(cm)",
    "gui.blocklyText.tello.MissionPadY": "Cartão Desafio Y(cm)",
    "gui.blocklyText.tello.telloMissionPadZ": "Cartão Desafio Z(cm)",
    "gui.blocklyText.tello.telloCommandResponse": "Responder ao Comando",
    "gui.blocklyText.tello.direction_front": "Vista Frontal",
    "gui.blocklyText.tello.direction_blow": "Vista Inferior",
    "gui.blocklyText.tello.direction_all": "Todos",
    "gui.blocklyText.tello.mid": "Cartão Desafio",
    "gui.blocklyText.tello.midfastest": "m-1",
    "gui.blocklyText.tello.midlatest": "m-2",
    "gui.blocklyText.tello.telloToggleMon": "[TOGGLE] Detecção do Cartão Desafio",
    "gui.blocklyText.tello.telloSetMDireaction": "Configurar a posição de detecção [POSITION]",
    "gui.blocklyText.tello.telloSetWIFI": "Modificar o Wi-Fi do Tello como um hotspot [NAME] senha [PASSWD]",
    "gui.blocklyText.tello.telloToggleGroup": "Alternar para o modo estação, conectar ao hotspot [NAME] senha [PASSWD]",
    "gui.blocklyText.telloGroup.telloRise": "Subir",
    "gui.blocklyText.telloGroup.telloDown": "Descer",
    "gui.blocklyText.telloGroup.telloGoahead": "Ir",
    "gui.blocklyText.telloGroup.telloBack": "Voltar",
    "gui.blocklyText.telloGroup.telloTurnLeft": "Esquerda",
    "gui.blocklyText.telloGroup.telloTurnRight": "Direita",
    "gui.blocklyText.telloGroup.telloSetFly": "[DIRECTION] [NUMS]cm",
    "gui.blocklyText.telloGroup.telloClockwiseRotation": "No sentido horário",
    "gui.blocklyText.telloGroup.telloCounterclockwiseSelection": "Anti-horário",
    "gui.blocklyText.telloGroup.telloClockRotation": "Rotação [CLOCKWISE] [NUMS] graus",
    "gui.blocklyText.telloGroup.setTelloIndexWithSN": "Configurar o número de série do Tello SN[SN] como [INDEX]",
    "gui.blocklyText.telloGroup.setTelloIndexWithSSID": "Configurar o SSID[SSID] do Tello como [INDEX]",
    "gui.blocklyText.telloGroup.scanTelloInNetwork": "Digitalizar [NUM] Tello na rede, tempo limite [TIME]s",
    "gui.blocklyText.telloGroup.telloSynchronousCommand": "Tempo de espera por comando síncrono [TIME] segundos",
    "gui.blocklyText.tello.telloVideoToggle": "Ligar/desligar o vídeo do Tello [VIDEO_STATE]",
    "gui.blocklyText.telloGroup.TEMPH": "Temperatura máxima",
    "gui.blocklyText.telloGroup.TEMPL": "Temperatura mais baixa",
    "gui.blocklyText.telloGroup.NUM": "Número",
    "gui.blocklyText.telloGroup.pitch": "Inclinação",
    "gui.blocklyText.telloGroup.roll": "Rotação",
    "gui.blocklyText.telloGroup.translation": "Tradução",
    "gui.blocklyText.telloGroup.telloAccelerationXYZ": "Aceleração [XYZ] (0.001g)",
    "gui.blocklyText.telloGroup.telloSpeedXYZ": "Velocidade [XYZ] (cm/s)",
    "gui.blocklyText.telloGroup.TelloAttitudePitch": "[ANGEL] Ângulo de atitude (°)",
    "gui.blocklyText.telloGroup.telloTempMinAndMax": "Placa principal [TEMP]",
    "gui.blocklyText.tello.telloDisplayRollText": "Rolar direção de exibição de texto [DIRECT] frequência [SPEED] texto [TEXT] cor [COLOR]",
    "gui.blocklyText.tello.telloDisplayText": "Exibir um único caractere [TEXT] cor [COLOR]",
    "gui.blocklyText.tello.telloRollText_up": "Cima",
    "gui.blocklyText.tello.telloRollText_down": "Baixo",
    "gui.blocklyText.tello.telloRollText_left": "Esquerda",
    "gui.blocklyText.tello.telloRollText_right": "Direita",
    "gui.blocklyText.telloGroup.initTelloGroup": "Desconectar",
    "gui.blocklyText.tello.shutdownForPower": "A bateria está insuficiente [POWER], o Tello será desligado em breve",
    "gui.blocklyText.tello.portCantUse": "Erro de serviço, porta [PORT] acesso falhou, por favor, verifique se está ocupada por outros programas",
    "gui.blocklyText.tello.telloReadESP32Version": "A versão do firmware do módulo de expansão",
    "gui.blocklyText.tello.telloSSID": "O SSID do módulo de expansão",
    "gui.blocklyText.tello.telloLightLEDWithColor": "Cor do LED [COLOR]",
    "gui.blocklyText.tello.telloLightLEDWithColor_rgb": "Cor do LED R:[RGBR] G:[RGBG] B:[RGBB]",
    "gui.blocklyText.tello.telloBreathLEDWithColor": "Cor da luz de respiração do LED [COLOR] frequência [TIME]",
    "gui.blocklyText.tello.telloBreathLEDWithColor_rgb": "Luz de respiração do LED R:[RGBR] G:[RGBG] B:[RGBB] frequência:[TIME]",
    "gui.blocklyText.tello.telloFlickerLEDWithColor": "Cor do LED piscante 1[COLOR1] e cor 2[COLOR2] frequência [TIME]",
    "gui.blocklyText.tello.telloFlickerLEDWithColor_rgb": "LED piscante R1:[RGBR1] G1:[RGBG1] B1:[RGBB1] - R2:[RGBR2] G2:[RGBG2] B2:[RGBB2] frequência:[TIME]",
    "gui.blocklyText.tello.telloShowPattern": "Padrão de exibição [MARK]",
    "gui.blocklyText.tello.telloShowPatternWhenStart": "Configurar o padrão de inicialização [MARK]",
    "gui.blocklyText.tello.telloShowRollPattern": "Rolar direção de exibição do padrão [DIRECT] frequência [SPEED] padrão [MARK]",
    "gui.blocklyText.tello.telloSetLight": "Configurar o brilho da tela [NUM]",
    "gui.blocklyText.tello.telloSendCMD": "Enviar comando personalizado [CMD]",
    "gui.blocklyText.tello.telloSendDirective": "Enviar uma mensagem clara [CMD]",
    "gui.blocklyText.tello.telloReceiveMsg": "Mensagem recebida [MSG]",
    "gui.blocklyText.tello.telloReceiveCustomNum": "Valor de resposta personalizado recebido",
    "gui.blocklyText.tello.connectTelloWIFIError": "Falha ao conectar ao Wi-Fi do Tello, por favor, tente conectar ao Wi-Fi manualmente",
    "gui.blocklyText.tello.connectTelloError": "Falha ao conectar ao Tello, por favor, confirme o status do dispositivo e reconecte",
    "gui.blocklyText.tello.tempIsHigh": "A temperatura do Tello está muito alta e será desligado em breve",
    "gui.blocklyText.tello.scanErrorWithOpenWlan": "Ocorreu um erro durante a digitalização, por favor, verifique se o Wi-Fi está ligado. Por favor, tente conectar ao Wi-Fi manualmente",
    "gui.blocklyText.tello.noTelloFound": "Nenhum dispositivo Tello disponível, por favor, tente conectar ao Wi-Fi manualmente",
    "gui.blocklyText.tello.connectIsBroke": "A conexão do Tello foi desconectada, por favor, verifique o dispositivo",
    "gui.blocklyText.tello.firstConnectTello": "Por favor, conecte-se ao Tello primeiro",
    "gui.blocklyText.tello.telloQueryWifiVersion": "A versão do Wi-Fi do módulo de extensão",
    "gui.blocklyText.tello.telloQueryHardwareVersion": "Obter Versão de Hardware",
    "gui.blocklyText.tello.telloToggleTOF": "[TOGGLE] Módulo TOF",
    "gui.blocklyText.tello.telloToggleTOF_enable": "Ativar",
    "gui.blocklyText.tello.telloToggleTOF_disabled": "Desativar",
    "gui.blocklyText.tello.activeTelloFirst": "Falha na conexão, a nova aeronave não está ativada, siga o manual da aeronave para ativar",
    "gui.blocklyText.tello.startPaddle": "Entrar no modo de hélice",
    "gui.blocklyText.tello.stopPaddle": "Sair do modo de hélice",
    "gui.blocklyText.tello.togglePaddle": "[TOGGLE] Modo de Pás",
    "gui.blocklyText.tello.telloTogglePaddle_enable": "Entrar",
    "gui.blocklyText.tello.telloTogglePaddle_disabled": "Sair",
    "gui.blocklyText.tello.telloThrowAway": "Jogar fora em 5 segundos",
    "gui.blocklyText.tello.telloResetPattern": "Restaurar o padrão de inicialização padrão",
    "gui.blocklyText.tello.telloQueryTelloVersion": "Versão do firmware do Tello",
    "gui.blocklyText.tello.telloQueryBatActive": "Consultar nível da bateria do Tello",
    "gui.blocklyText.tello.telloReadTOF": "Ler TOF(mm)",
    "gui.blocklyText.telloGroup.allTellos": "todos",
    "gui.blocklyText.tello.notAllowInput": "Por favor, insira números ou caracteres em inglês, sem outros caracteres especiais",
    "gui.blocklyText.tello.checkFirewallAndTello": "A linha está desconectada. Por favor, verifique o status de conexão do Tello e adicione o software à lista de permissões do firewall do computador",
    "gui.blocklyText.telloGroup.checkWlanNum": "Certifique-se de que a máquina está conectada à rede correta. Se estiver conectada a várias redes, desconecte outras conexões primeiro",
    "gui.blocklyText.telloGroup.telloIsNotExist": "[INDEX] Tello não existe",
    "gui.blocklyText.telloGroup.findTelloWithLost": "[FOUND] encontrado, [NOTFOUND] não encontrado, ip:[IP]",
    "gui.blocklyText.telloGroup.findTello": "[FOUND] encontrado, ip:[IP]",
    "gui.blocklyText.telloGroup.cantRepeatScan": "Digitalizando, por favor, não repita a digitalização...",
    "gui.blocklyText.telloGroup.setIndexForTelloFirst": "Por favor, defina o número primeiro",
    "gui.blocklyText.maixduino.programMain": "K120 Maixduino inicia",
    "gui.blocklyText.maixduino.LCDInit": "Exibir inicialização freq [FREQ] Hz e fundo [COLOR] invertido [INVERT]",
    "gui.blocklyText.maixduino.LCDShowImageFromPath": "Caminho da imagem exibida: [PATH]",
    "gui.blocklyText.maixduino.LCDShowImageFromImage": "Exibir imagem: [IMAGE]",
    "gui.blocklyText.maixduino.LCDShowText": "Exibir palavras [TEXT] na posição x [X] y [Y] em [TEXT_COLOR] fundo [BACKGROUND_COLOR]",
    "gui.blocklyText.maixduino.LCDFill": "Limpar a exibição para fundo [COLOR]",
    "gui.blocklyText.maixduino.LCDRotation": "Exibir rotação [ANGLE]",
    "gui.blocklyText.maixduino.LCDMirror": "O espelho da tela está [MIRROR]",
    "gui.blocklyText.maixduino.LCDSize": "Resolução de exibição [SIZE]",
    "gui.blocklyText.maixduino.LCDRGB888": "Vermelho RGB888 [RED] verde [GREEN] azul [BLUE]",
    "gui.blocklyText.maixduino.LCDColor": "Cor [COLOR]",
    "gui.blocklyText.blockTitle.maixduino.Camera": "Câmera",
    "gui.blocklyText.maixduino.CameraInit": "Inicializar câmera [MODE] parâmetros [SETTINGS]",
    "gui.blocklyText.maixduino.CameraGetImage": "Obter imagem da câmera",
    "gui.blocklyText.maixduino.CameraControl": "Escolher câmera dupla [CONTROL]",
    "gui.blocklyText.maixduino.CameraHorizontalMirroring": "O espelho horizontal da câmera está [CONTROL]",
    "gui.blocklyText.maixduino.CameraVerticalMirroring": "O espelho vertical da câmera está [CONTROL]",
    "gui.blocklyText.maixduino.CameraColorBarMode": "As faixas coloridas da câmera estão [CONTROL]",
    "gui.blocklyText.maixduino.CameraSettingParameters": "Definir câmera [PARAMETERS] como [VALUE]",
    "gui.blocklyText.maixduino.CameraSettingWindow": "Definir largura da janela da câmera [WIDTH] altura [HEIGHT]",
    "gui.blocklyText.maixduino.CameraGetParameters": "Obter [PARAMETERS] da câmera",
    "gui.blocklyText.blockTitle.audioAndVideo": "Áudio e Vídeo",
    "gui.blocklyText.maixduino.AudioPlay": "Reproduzir áudio [PATH] [WAIT]",
    "gui.blocklyText.maixduino.AudioVolume": "Volume de áudio [VOLUME] %",
    "gui.blocklyText.maixduino.AudioState": "Obter status de reprodução de áudio",
    "gui.blocklyText.maixduino.AudioRecord": "Gravar áudio [PATH] por [TIME] s e [WAIT]",
    "gui.blocklyText.maixduino.VideoPlay": "Reproduzir vídeo [PATH] [WAIT]",
    "gui.blocklyText.maixduino.VideoVolume": "Volume de vídeo [VOLUME] %",
    "gui.blocklyText.maixduino.VideoState": "Obter status de reprodução de vídeo",
    "gui.blocklyText.maixduino.VideoRecord": "Gravar vídeo [PATH] por [TIME] s e [WAIT]",
    "gui.blocklyText.maixduino.digitalRead": "Ler pino digital [PIN]",
    "gui.blocklyText.maixduino.analogRead": "Ler pino analógico [PIN]",
    "gui.blocklyText.maixduino.digitalWrite": "Pino digital [PIN] saída [LEVEL]",
    "gui.blocklyText.maixduino.PWMInit": "Inicializar pino PWM [PIN] freq [FREQ] Hz temporizador [TIMER]",
    "gui.blocklyText.maixduino.PWMWrite": "Pino PWM [PIN] saída ciclo de trabalho [DUTY]",
    "gui.blocklyText.maixduino.ticksDiff": "Diferença de tempo de execução, começando em [STARTTIME] e terminando em [ENDTIME]",
    "gui.blocklyText.maixduino.runTimer": "Tempo de execução [TIMETYPE]",
    "gui.blocklyText.maixduino.gc": "[GC] coleta de lixo",
    "gui.blocklyText.maixduino.systemReset": "Redefinir",
    "gui.blocklyText.maixduino.print": "Imprimir [DATA]",
    "gui.blocklyText.maixduino.LevelMenu.A": "alto",
    "gui.blocklyText.maixduino.LevelMenu.B": "baixo",
    "gui.blocklyText.maixduino.MirrorMenu.A": "LIGADO",
    "gui.blocklyText.maixduino.MirrorMenu.B": "DESLIGADO",
    "gui.blocklyText.maixduino.SelectMenu.A": "olho 0",
    "gui.blocklyText.maixduino.SelectMenu.B": "olho 1",
    "gui.blocklyText.maixduino.SettingParametersMenu.A": "contraste",
    "gui.blocklyText.maixduino.SettingParametersMenu.B": "brilho",
    "gui.blocklyText.maixduino.SettingParametersMenu.C": "valor de ganho",
    "gui.blocklyText.maixduino.SettingParametersMenu.D": "saturação",
    "gui.blocklyText.maixduino.GetParametersMenu.A": "largura de resolução",
    "gui.blocklyText.maixduino.GetParametersMenu.B": "largura e altura de resolução",
    "gui.blocklyText.maixduino.GetParametersMenu.C": "valor de ganho",
    "gui.blocklyText.maixduino.GetParametersMenu.D": "cache de imagem do quadro",
    "gui.blocklyText.maixduino.GetParametersMenu.E": "ID de hardware",
    "gui.blocklyText.maixduino.TimeTypeMenu.A": "s",
    "gui.blocklyText.maixduino.TimeTypeMenu.B": "ms",
    "gui.blocklyText.maixduino.TimeTypeMenu.C": "µs",
    "gui.blocklyText.maixduino.GcMenu.A": "automático",
    "gui.blocklyText.maixduino.GcMenu.B": "iniciar",
    "gui.blocklyText.maixduino.LcdSizeMenu.A": "largura",
    "gui.blocklyText.maixduino.LcdSizeMenu.B": "altura",
    "gui.blocklyText.maixduino.WaitMenu.A": "esperar",
    "gui.blocklyText.maixduino.WaitMenu.B": "fundo",
    "gui.blocklyText.maixduino.CameraModeMenu.A": "monocular",
    "gui.blocklyText.maixduino.CameraModeMenu.B": "binocular",
    "gui.blocklyText.maixduino.AngleMenu.A": "0°",
    "gui.blocklyText.maixduino.AngleMenu.B": "90°",
    "gui.blocklyText.maixduino.AngleMenu.C": "180°",
    "gui.blocklyText.maixduino.AngleMenu.D": "270°",
    "gui.blocklyText.maixduino.camerainit.frameformat": "Formato do Quadro",
    "gui.blocklyText.maixduino.camerainit.frameformat.RGB565": "cor",
    "gui.blocklyText.maixduino.camerainit.frameformat.GRAYSCALE": "cinza",
    "gui.blocklyText.maixduino.camerainit.framesize": "Tamanho do Quadro",
    "gui.blocklyText.maixduino.camerainit.imagecapture": "Captura de tela",
    "gui.blocklyText.maixduino.camerainit.imagecapture.on": "LIGADO",
    "gui.blocklyText.maixduino.camerainit.imagecapture.off": "DESLIGADO",
    "gui.blocklyText.maixduino.camerainit.skipframes": "Pular Quadro Nº:",
    "gui.extension.AI.name": "IA",
    "gui.blocklyText.AI.anchorPoint": "Parâmetros de âncora [POINT]",
    "gui.blocklyText.AI.classNames": "Nome do objeto [NAMES]",
    "gui.blocklyText.AI.modelLoadingAddress": "Carregar modelo [ADDRESS]",
    "gui.blocklyText.AI.modelLoadingPath": "Caminho do modelo [PATH]",
    "gui.blocklyText.AI.yolo2Init": "Inicializar o modelo de rede yolo2 [MODEL], limiar de probabilidade [PT], limiar de caixa_iou [BT], âncora [APC] e parâmetro de âncora [AP]",
    "gui.blocklyText.AI.yolo2Run": "Executar modelo de rede yolo2 [MODEL] imagem [IMAGE]",
    "gui.blocklyText.AI.yolo2forward": "Operar modelo yolo2 [MODEL] imagem [IMAGE]",
    "gui.blocklyText.AI.yolo2Parsing": "Analisar objeto do modelo yolo2 [OBJ] e obter [TYPE]",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.A": "Coordenada X",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.B": "Coordenada Y",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.C": "IP",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.D": "Confiança",
    "gui.blocklyText.machineHearing.newModel": "ARS criar modelo em branco",
    "gui.blocklyText.machineHearing.training": "ASR iniciar treinamento de palavras [CORPUS] para adicionar ao modelo [MODEL]",
    "gui.blocklyText.machineHearing.run": "ARS executar modelo [MODEL]",
    "gui.blocklyText.machineHearing.recognize": "Resultado de reconhecimento de fala ASR",
    "gui.blocklyText.machineHearing.save": "Salvar modelo ASR [MODEL] em [PATH]",
    "gui.blocklyText.machineHearing.load": "Carregar modelo ASR [PATH]",
    "gui.extension.machineVision.imageBasis.name": "Visão de Máquina · Noções Básicas de Imagem",
    "gui.blocklyText.machineVision.openEmptyImage": "Abrir imagem em branco",
    "gui.blocklyText.machineVision.openPathImage": "Abrir caminho [PATH]",
    "gui.blocklyText.machineVision.clearImage": "Limpar imagem [IMAGE]",
    "gui.blocklyText.machineVision.saveImage": "Salvar imagem [IMAGE] no caminho [PATH]",
    "gui.blocklyText.machineVision.modifyPixelColor": "Modificar [COORDINATE] xy da imagem [IMAGE] em [COLOR]",
    "gui.blocklyText.machineVision.getPixelColor": "Obter cor da imagem [IMAGE] coordenada xy [COORDINATE]",
    "gui.blocklyText.machineVision.captureImage": "Interceptar área-xywh [AREA] da imagem [IMAGE]'",
    "gui.blocklyText.machineVision.compressedImage": "Comprimir imagem [IMAGE] com taxa [RATIO]",
    "gui.blocklyText.machineVision.getImageInfo": "Obter [INFO] da imagem [IMAGE]",
    "gui.blocklyText.machineVision.conversion": "Converter imagem [IMAGE] para [TYPE]",
    "gui.extension.machineVision.imageDrawing.name": "Visão de Máquina · Desenho de Imagem",
    "gui.blocklyText.machineVision.drawText": "Desenhar texto [IMAGE] ponto de início-xy [COORDINATE] conteúdo [CONTENT] em [COLOR] tamanho da fonte [SIZE]",
    "gui.blocklyText.machineVision.drawImage": "Desenhar [IMAGE] -xy [COORDINATE] para uma imagem de escala -xy [ZOOM]",
    "gui.blocklyText.machineVision.drawLine": "Desenhar [IMAGE] -xyxy para uma imagem de linha [COORDINATE] em [COLOR] peso [SIZE]",
    "gui.blocklyText.machineVision.drawArrows": "Desenhar [IMAGE] -xyxy uma imagem de seta [COORDINATE] em [COLOR] tamanho [SIZE]",
    "gui.blocklyText.machineVision.drawCross": "Desenhar [IMAGE] -xy uma cruz [COORDINATE] em [COLOR] estender [EXTEND] peso [SIZE]",
    "gui.blocklyText.machineVision.drawRound": "Desenhar [IMAGE] -xyr uma imagem de círculo [COORDINATE] em [COLOR] peso [SIZE] preenchido [FILLING]",
    "gui.blocklyText.machineVision.drawRectangle": "Desenhar [IMAGE] -xywh um retângulo [COORDINATE] em [COLOR] peso [SIZE] preenchido [FILLING]",
    "gui.blocklyText.machineVision.drawFeaturePoints": "Desenhar [IMAGE] um ponto de destaque objeto [OBJ] em [COLOR] tamanho [SIZE] peso [THICKNESS] preenchido [FILLING]",
    "gui.extension.machineVision.imageFiltering.name": "Visão de Máquina · Filtragem de Imagem",
    "gui.blocklyText.machineVision.correctionZoom": "Retificação e dimensionamento de imagem [IMAGE] com valor de correção [CORRECTION] e valor de zoom [ZOOM]",
    "gui.blocklyText.machineVision.histogramEqualization": "Equalização de histograma de imagem [IMAGE] autoadaptação [ADAPTIVE] no contraste [CONTRAST]",
    "gui.blocklyText.machineVision.fuzzyFilter": "Filtro de desfoque [IMAGE] núcleo de convolução [CONVOLUTIONKERNEL] autoadaptação [ADAPTIVE] compensação [MAKEUP]",
    "gui.blocklyText.machineVision.cartoonFilter": "Filtro de desenho animado [IMAGE] diferença original [ORIGINALDIFFERENCE] diferença vizinha [ADJACENTDIFFERENCE]",
    "gui.blocklyText.machineVision.imageCorrosion": "Erodir [IMAGE] núcleo de convolução [CONVOLUTIONKERNEL] valor de thread [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageExpansion": "Dilatar [IMAGE] núcleo de convolução [CONVOLUTIONKERNEL] valor de thread [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageFill": "Preencher imagem [IMAGE] coordenada-xy [COORDINATE] em [COLOR] diferença original [ORIGINALDIFFERENCE] diferença vizinha [ADJACENTDIFFERENCE] inverter [REVERSE] limpar [CLEAR]",
    "gui.blocklyText.machineVision.ImageProjection": "Projeção vertical de imagem [IMAGE] é [VERTICALPROJECTION]",
    "gui.blocklyText.machineVision.ImageInversion": "Inversão de imagem [IMAGE]",
    "gui.extension.machineVision.shapeRecognition.name": "Visão de Máquina · Reconhecimento de Formas",
    "gui.blocklyText.machineVision.straightLineAnalysis": "Objeto de análise de linha [OBJ] para obter [INFO]",
    "gui.blocklyText.machineVision.circularAnalysis": "Objeto de análise de círculo [OBJ] para obter [INFO]",
    "gui.blocklyText.machineVision.rectangleAnalysis": "Objeto de análise de retângulo [OBJ] para obter [INFO]",
    "gui.blocklyText.machineVision.lineRecognition": "Reconhecimento de linha [IMAGE] área-xywh [AREA] valor de thread [THRESHOLD] inclui ângulo [MERGEANGLE] e inclinação [MERGESLOPE]",
    "gui.blocklyText.machineVision.lineSegmentRecognition": "Reconhecimento de segmento de linha [IMAGE] área-xywh [AREA] espaço [SPACING] incluir ângulo [MERGEANGLE]",
    "gui.blocklyText.machineVision.circleRecognition": "Reconhecimento de círculo [IMAGE] área-xywh [AREA] valor de thread [THRESHOLD] raio mínimo [MINIMUM] raio máximo [MAXIMUM] passo de raio [STRIDE] incluir x [COMBINEDX] e y [COMBINEDY] e raio [COMBINEDR]",
    "gui.blocklyText.machineVision.rectangleRecognition": "Reconhecimento de retângulo [IMAGE] área-xywh [AREA] valor de thread [THRESHOLD]",
    "gui.extension.machineVision.colorRecognition.name": "Visão de Máquina · Reconhecimento de Cor",
    "gui.blocklyText.machineVision.LABFromRGB888": "Valor LAB depende de RGB888 [RGB888]",
    "gui.blocklyText.machineVision.RGB888FromLAB": "Valor RGB888 depende de valor LAB [LAB]",
    "gui.blocklyText.machineVision.grayscaleFromRGB888": "Valor de cinza depende de RGB888 [RGB888]",
    "gui.blocklyText.machineVision.GRB888FromGrayscale": "Valor RGB888 depende de valor de cinza [GRAYSCALE]",
    "gui.blocklyText.machineVision.trackingAnalysis": "Objeto de análise de rastreamento [OBJ] para obter [INFO]",
    "gui.blocklyText.machineVision.colorAnalysis": "Objeto de análise de cor [OBJ] CDF [CDF] para obter [INFO]",
    "gui.blocklyText.machineVision.colorPatchTracking": "Rastreamento de bloco de cor [IMAGE] valor de limiar LAB [THRESHOLDLAB] área-xywh [AREA] área filtrada [FILTEROUTAREA] pixels filtrados [FILTEROUTPRIMENUMBERS] incluir [MERGE] sobreposição é [OVERLAPPING]",
    "gui.blocklyText.machineVision.colorRecognition": "Reconhecimento de Cor [IMAGE] área-xywh [AREA]",
    "gui.extension.machineVision.dimensionCodeRecognition.name": "Visão de Máquina · Reconhecimento de Código",
    "gui.blocklyText.machineVision.barcodeAnalysis": "Objeto de análise de código de barras [OBJ] para obter [INFO]",
    "gui.blocklyText.machineVision.QRCodeAnalysis": "Objeto de análise de código QR [OBJ] para obter [INFO]",
    "gui.blocklyText.machineVision.aprilTagAnalysis": "Objeto de análise de ApriTag [OBJ] para obter [INFO]",
    "gui.blocklyText.machineVision.BarCodeRecognition": "Reconhecer código de barras [IMAGE] área-xywh [AREA]",
    "gui.blocklyText.machineVision.QRCodeRecognition": "Reconhecer código QR [IMAGE] área-xywh [AREA]",
    "gui.blocklyText.machineVision.AprilTagRecognition": "Reconhecer ApriTag [IMAGE] área-xywh [AREA]",
    "gui.extension.machineVision.featureRecognition.name": "Visão de Máquina · Reconhecimento de Características",
    "gui.blocklyText.machineVision.featureInformation": "Objeto de informação de características [OBJ] obter [INFO]",
    "gui.blocklyText.machineVision.directionalGradienMap": "Imagem de gradiente direcional [IMAGE] área-xywh [AREA] comprimento [LINELENGTH]",
    "gui.blocklyText.machineVision.featureRecognition": "Reconhecimento de características [IMAGE] área-xywh [AREA] pontos-chave [KEYPOINTS] pontos de ângulo [CORNERPOINTS] fator de escala [SCALEFACTOR] escala múltipla é [MULTIRATIO]",
    "gui.blocklyText.machineVision.featureComparison": "Contraste de características Objeto1 [OBJ1] Objeto2 [OBJ2] similaridade [SIMILARITY]",
    "gui.blocklyText.machineVision.FeatureInformationMenu.A": "coordenada-xywh",
    "gui.blocklyText.machineVision.FeatureInformationMenu.B": "x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.C": "y",
    "gui.blocklyText.machineVision.FeatureInformationMenu.D": "w",
    "gui.blocklyText.machineVision.FeatureInformationMenu.E": "h",
    "gui.blocklyText.machineVision.FeatureInformationMenu.F": "centro-x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.G": "centro-y",
    "gui.blocklyText.machineVision.FeatureInformationMenu.H": "valor de correspondência",
    "gui.blocklyText.machineVision.FeatureInformationMenu.I": "radiano",
    "gui.blocklyText.machineVision.FeatureInformationMenu.J": "ponto de correspondência-xy",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.H": "número",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.I": "família",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.J": "radiano",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.K": "distância x",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.L": "distância y",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.M": "distância z",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.N": "radiano x",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.O": "radiano y",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.P": "radiano z",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.F": "conteúdo",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.G": "versão",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.H": "máscara",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.I": "tipo",
    "gui.blocklyText.machineVision.BarcodeAnalysisMenu.H": "ângulo",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.A": "valor de cinza",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.B": "LAB L",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.C": "LAB A",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.D": "LAB B",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.H": "pixels",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.J": "número",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.K": "inclui",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.L": "área",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.M": "razão de densidade",
    "gui.blocklyText.machineVision.CircularInformationMenu.A": "coordenada-xyr",
    "gui.blocklyText.machineVision.CircularInformationMenu.D": "r",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.B": "x1",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.C": "y1",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.D": "x2",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.E": "y2",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.F": "comprimento",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.H": "inclinação",
    "gui.blocklyText.machineVision.ImageInfoMenu.A": "largura",
    "gui.blocklyText.machineVision.ImageInfoMenu.B": "altura",
    "gui.blocklyText.machineVision.ImageInfoMenu.C": "formato",
    "gui.blocklyText.machineVision.ImageInfoMenu.D": "tamanho",
    "gui.blocklyText.machineVision.ConversionTypeMenu.A": "Cinza",
    "gui.blocklyText.machineVision.ConversionTypeMenu.B": "Cor",
    "gui.blocklyText.machineVision.ConversionTypeMenu.C": "Arco-íris",
    "gui.blocklyText.machineVision.ConversionTypeMenu.D": "IA",
    "gui.extension.mpyirq.name": "Interrupção",
    "gui.blocklyText.mpyirq.event": "pino de interrupção [PIN] modo [MODE] executar",
    "gui.blocklyText.mpyirq.clean": "cancelar interrupção no pino [PIN]",
    "gui.blocklyText.pyTurtle.init": "Criar um plotador de tartaruga [NAME]",
    "gui.blocklyText.pyTurtle.turtleSetShape": "Forma de [NAME] é definida como [SHAPE]",
    "gui.blocklyText.pyTurtle.turtleDone": "O desenho está concluído, continue mantendo a janela aberta",
    "gui.blocklyText.pyTurtle.turtleExitClick": "Clique para sair do desenho",
    "gui.blocklyText.pyTurtle.turtleMove": "[NAME] Mover [DIRECTION] [DISTANCE]",
    "gui.blocklyText.pyTurtle.turtleRotate": "[NAME] Rotação [DIRECTION] [ANGLE]",
    "gui.blocklyText.pyTurtle.turtleFaceTo": "[NAME] enfrenta [ANGLE] graus",
    "gui.blocklyText.pyTurtle.turtleMoveToPos": "[NAME] move-se para a coordenada X [X] Y [Y]",
    "gui.blocklyText.pyTurtle.turtleSetPos": "Coordenada [XY] do [NAME] é definida como [POS]",
    "gui.blocklyText.pyTurtle.turtleGetStatus": "[NAME] obtém o [STATUS] atual",
    "gui.blocklyText.pyTurtle.turtleClearAll": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleClone": "[NAME] Copiar Tartaruga",
    "gui.blocklyText.pyTurtle.turtleVisible": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleSetThickness": "[NAME] Definir espessura do pincel [THICK]",
    "gui.blocklyText.pyTurtle.turtleSetScale": "[NAME] Definir o tamanho do ícone da tartaruga. Múltiplo de estiramento vertical [VERTICAL] Múltiplo de estiramento horizontal [HOR] Largura da linha [OUTLINE]",
    "gui.blocklyText.pyTurtle.turtleSetSpeed": "[NAME] define a velocidade do pincel para [SPEED]",
    "gui.blocklyText.pyTurtle.turtleSetBackground": "[NAME] define a cor de fundo [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetPenColor": "[NAME] Define a cor da caneta [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetFillColor": "[NAME] define a cor de preenchimento do pincel [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetColor": "[NAME] Define a cor do pincel [COLOR1] Define a cor de preenchimento do pincel [COLOR2]",
    "gui.blocklyText.pyTurtle.turtleDrawAction": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleDrawCircle": "[NAME] desenha gráficos [CIRCLE] com raio [RADIUS]",
    "gui.blocklyText.pyTurtle.turtleDrawCircleWithAngle": "[NAME] desenha um círculo com raio [RADIUS] e um ângulo de [DEGREE]",
    "gui.blocklyText.pyTurtle.turtleInitScreen": "[NAME] Inicializa a tela da tartaruga [SCREEN]",
    "gui.blocklyText.pyTurtle.turtleSaveDraw": "[NAME] salva a imagem desenhada como uma imagem [PICTURES]",
    "gui.blocklyText.pyTurtle.turtleSetAnimationInterval": "[NAME] define o intervalo de reprodução da animação para [TIME] milissegundos",
    "gui.blocklyText.pyTurtle.turtleDrawText": "[NAME] escreve [TEXT]",
    "gui.blocklyText.pyTurtle.turtleDrawTextWithAttr": "[NAME] Escrevendo [TEXT] A caneta se move com o texto [JUDGE] Direção de alinhamento do texto [ALIGN] Fonte [FONT] Tamanho da fonte [FONTSIZE] Estilo da fonte [TYPE]",
    "gui.blocklyText.pyTurtle.turtleGetFloat": "Tartaruga obtém o número de ponto flutuante inserido. O título é [TITLE] e a dica é [TIP] valor padrão [VALUE] valor mínimo [MIN] valor máximo [MAX]",
    "gui.blocklyText.pyTurtle.turtleGetString": "Tartaruga obtém a string de entrada, o título é [TITLE] e a dica é [TIP]",
    "gui.blocklyText.pyTurtle.roll_forward": "para frente",
    "gui.blocklyText.pyTurtle.roll_backward": "Para trás",
    "gui.blocklyText.pyTurtle.roll_l_left": "Esquerda",
    "gui.blocklyText.pyTurtle.roll_l_right": "Para a direita",
    "gui.blocklyText.pyTurtle.xy_x": "X",
    "gui.blocklyText.pyTurtle.xy_y": "Y",
    "gui.blocklyText.pyTurtle.status_pos": "Posição",
    "gui.blocklyText.pyTurtle.status_shape": "Forma",
    "gui.blocklyText.pyTurtle.status_heading": "Orientação",
    "gui.blocklyText.pyTurtle.status_width": "Largura do pincel",
    "gui.blocklyText.pyTurtle.status_shapesize": "Tamanho do ícone da tartaruga",
    "gui.blocklyText.pyTurtle.status_speed": "Velocidade",
    "gui.blocklyText.pyTurtle.action_clear": "Limpar tela",
    "gui.blocklyText.pyTurtle.action_reset": "Redefinir",
    "gui.blocklyText.pyTurtle.action_home": "Retornar à origem",
    "gui.blocklyText.pyTurtle.pen_action_penup": "Caneta levantada",
    "gui.blocklyText.pyTurtle.pen_action_pendown": "Caneta baixada",
    "gui.blocklyText.pyTurtle.visible_hideturtle": "Esconder tartaruga",
    "gui.blocklyText.pyTurtle.visible_showturtle": "Mostrar tartaruga",
    "gui.blocklyText.pyTurtle.visible_turtle": "Tartaruga",
    "gui.blocklyText.pyTurtle.visible_arrow": "Seta",
    "gui.blocklyText.pyTurtle.visible_circle": "Círculo",
    "gui.blocklyText.pyTurtle.visible_square": "Bloco",
    "gui.blocklyText.pyTurtle.visible_triangle": "Triângulo",
    "gui.blocklyText.pyTurtle.visible_classic": "Padrão",
    "gui.blocklyText.pyTurtle.drawaction_begin": "Iniciar o preenchimento",
    "gui.blocklyText.pyTurtle.drawaction_end": "Terminar Preenchimento",
    "gui.blocklyText.pyTurtle.circle_circle": "Círculo",
    "gui.blocklyText.pyTurtle.circle_dot": "ponto",
    "gui.blocklyText.pyTurtle.flag_False": "Não",
    "gui.blocklyText.pyTurtle.flag_True": "Sim",
    "gui.blocklyText.pyTurtle.align_left": "Alinhar à esquerda",
    "gui.blocklyText.pyTurtle.align_center": "Centralizado",
    "gui.blocklyText.pyTurtle.align_right": "Alinhar à direita",
    "gui.blocklyText.pyTurtle.align_normal": "Padrão",
    "gui.blocklyText.pyTurtle.align_bold": "Negrito",
    "gui.blocklyText.pyTurtle.align_italic": "Inclinar",
    "gui.blocklyText.pyTurtle.align_bi": "Inclinado e negrito",
    "gui.blocklyText.pyTurtle.turtleScreenSetup": "Inicializar o tamanho da janela da tartaruga largura [WIDTH] altura [HEIGHT] offset X [LEFT] Y [TOP]",
    "gui.blocklyText.pyTurtle.turtleScreenSize": "Inicializar a largura do canvas da tartaruga [WIDTH] altura [HEIGHT] cor de fundo [COLOR]",
    "gui.blocklyText.pyGenerator.importModule": "importar [NAME]",
    "gui.blocklyText.pyGenerator.importAs": "importar [MOUDLE] como [NAME]",
    "gui.blocklyText.pyGenerator.fromImport": "de [FROM] importar [MOUDLE]",
    "gui.blocklyText.pyGenerator.annotation": "# [TEXT]",
    "gui.blocklyText.pyGenerator.defineFunction": "def [FUNCTION]([ARGUMENT]):",
    "gui.blocklyText.pyGenerator.callFunction": "Chamar função [FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.callFunctionWithValue": "Chamar função para obter o valor de retorno [FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.defineClass": "classe [CLASS]:",
    "gui.blocklyText.pyGenerator.instanceClass": "[CLASS] [INSTANCE]",
    "gui.blocklyText.pyGenerator.reporterType": "Código [CODE]",
    "gui.blocklyText.pyGenerator.typeBoolean": "Código [CODE]",
    "gui.blocklyText.pyGenerator.annotationBlock": "Comentário de bloco",
    "gui.blocklyText.pyGenerator.originCode": "Código [CODE]",
    "gui.extension.xiamiboard.title": "Placa de Expansão Xia Mi",
    "gui.extension.xiamiboard.setMotor": "motor [Select] mover [Direction] com velocidade [Speed]",
    "gui.extension.xiamiboard.stopMotor": "parar motor [Select]",
    "gui.extension.xiamiboard.setTrafficLights": "definir luz de trânsito vermelha [Red] amarela [Yellow] verde [Green]",
    "gui.extension.xiamiboard.readAngle": "ler sensor de ângulo de rotação",
    "gui.extension.xiamiboard.readFlame": "ler sensor de chama",
    "gui.extension.xiamiboard.readMotorState": "ler estado do motor [Select] [State]",
    "gui.extension.xiamiboard.readTrafficLights": "ler estado do semáforo [State]",
    "gui.extension.xiamiboard.readRelay": "definir relé [State]",
    "gui.extension.xiamiboard.readUltrasonicDistance": "ler sensor ultrassônico (cm)",
    "gui.extension.xiamiboard.initializationScreen": "inicializar display OLED",
    "gui.extension.xiamiboard.screenDisplay": "mostrar texto no display OLED [Text] nas coordenadas x [Row] y [Column]",
    "gui.extension.xiamiboard.clearScreenAll": "limpar display OLED",
    "gui.extension.xiamiboard.clearScreen": "limpar linha do display OLED [Row] da coluna [ColumnFrom] até [ColumnTo]",
    "gui.extension.xiamiboard.readInfrared": "ler valor da tecla IR",
    "gui.extension.xiamiboard.receiveData": "quando receber IR [Data]",
    "gui.extension.xiamiboard.wirelessData": "dados infravermelhos",
    "gui.extension.xiamiboard.xiamiRGB": "vermelho [Red] verde [Green] azul [Blue]",
    "gui.extension.xiamiboard.setRGBLightsRange": "Configurar lâmpada RGB para iluminar [Range] exibir cor [Color]",
    "gui.extension.xiamiboard.setLightsColor": "LED RGB [Num] mostrar cor [Color]",
    "gui.extension.xiamiboard.setLightsBrightness": "definir brilho do LED RGB para [Brightness]",
    "gui.extension.xiamiboard.coloseLightsAll": "desligar todos os LEDs RGB",
    "gui.extension.xiamiboard.initializeST": "inicializar [TYPE] sensor de temperatura",
    "gui.extension.xiamiboard.readSTState": "ler [TYPE] estado [State]",
    "gui.blocklyText.xiamiboard.all": "todos",
    "gui.blocklyText.xiamiboard.cw": "para frente",
    "gui.blocklyText.xiamiboard.ccw": "para trás",
    "gui.blocklyText.xiamiboard.speed": "velocidade",
    "gui.blocklyText.xiamiboard.dir": "direção",
    "gui.blocklyText.xiamiboard.temp": "temperatura",
    "gui.blocklyText.xiamiboard.hum": "umidade",
    "gui.blocklyText.xiamiboard.colorRed": "vermelho",
    "gui.blocklyText.xiamiboard.colorYellow": "amarelo",
    "gui.blocklyText.xiamiboard.colorGreen": "verde",
    "gui.blocklyText.xiamiboard.close": "conectado",
    "gui.blocklyText.xiamiboard.disconnect": "desconectado",
    "gui.blocklyText.xiamiboard.lightAll": "todos",
    "gui.extension.serialPort.name": "enviar dados para modo em tempo real",
    "gui.extension.serialPortToAC.name": "enviar dados para modo de upload",
    "gui.extension.serialPortjJS.name": "receber dados do modo de upload",
    "gui.extension.pyMqtt.initMqtt": "MQTT inicializar [SETTING]",
    "gui.extension.pyMqtt.connect": "conectar MQTT",
    "gui.extension.pyMqtt.loop_forever": "MQTT permanecer conectado para sempre",
    "gui.extension.pyMqtt.loopNeverForever": "MQTT permanecer conectado, tempo limite [Time] seg",
    "gui.extension.pyMqtt.disconnect": "desconectar MQTT",
    "gui.extension.pyMqtt.pushlish": "MQTT publicar [INFO] em [Topic]",
    "gui.extension.pyMqtt.pushlish_save": "MQTT publicar [INFO] em [Topic] e salvar no banco de dados",
    "gui.extension.pyMqtt.subscribe": "MQTT assinar [Topic]",
    "gui.extension.pyMqtt.received": "quando receber MQTT [MqttInfo] de [Topic]",
    "gui.extension.pyMqtt.topic": "Tópico MQTT",
    "gui.extension.pyMqtt.mqttInfo": "Mensagem MQTT",
    "gui.blocklyText.pyMqtt.pyMqttInitPlatform": "Plataforma MQTT",
    "gui.blocklyText.pyMqtt.EasyIOT_Server": "Servidor EasyIoT",
    "gui.blocklyText.pyMqtt.EasyIOTServerChina": "EasyIoT China",
    "gui.blocklyText.pyMqtt.EasyIOTServerGlobal": "EasyIoT Global",
    "gui.blocklyText.pyMqtt.EasyIOT_Port": "Porta EasyIoT",
    "gui.blocklyText.pyMqtt.siotServer": "Servidor SIoT",
    "gui.blocklyText.pyMqtt.siotServerPort": "Porta SIoT",
    "gui.blocklyText.pyMqtt.siotServerUser": "Nome de usuário SIoT",
    "gui.blocklyText.pyMqtt.siotServerPass": "Senha SIoT",
    "gui.extension.pyGame.pygameModule": "Módulo Pygame",
    "gui.extension.pyGame.pygameInit": "inicializar todos os módulos pygame importados",
    "gui.extension.pyGame.pygameQuit": "desinstalar todos os módulos pygame inicializados",
    "gui.extension.pyGame.displayModule": "Módulo de Exibição",
    "gui.extension.pyGame.displayInit": "inicializar módulo de exibição",
    "gui.extension.pyGame.displayQuit": "desinstalar módulo de exibição inicializado",
    "gui.extension.pyGame.windowInit": "inicializar janela [Win] de [Width] x [Height] [Flags]",
    "gui.extension.pyGame.windowUpdate": "carregar interface da janela (alguns softwares)",
    "gui.extension.pyGame.windowSetIcon": "definir ícone da janela [Value]",
    "gui.extension.pyGame.windowSetTitle": "definir título da janela [Value]",
    "gui.extension.pyGame.windowFlip": "atualizar objeto de superfície na tela",
    "gui.extension.pyGame.windowDraw": "desenhar [Suf] em [Postion] da janela [Win]",
    "gui.extension.pyGame.surfaceObject": "Objeto de Superfície",
    "gui.extension.pyGame.createSurface": "criar objeto de superfície [Surface] com [Width] x [Height]",
    "gui.extension.pyGame.blitSurface": "desenhar [Obj] em [Pos] do objeto de superfície [Surface]",
    "gui.extension.pyGame.fillSurface": "preencher objeto de superfície [Surface] com [Color]",
    "gui.extension.pyGame.surfaceSetAt": "definir pixels [Pos] do objeto de superfície [Surface] para [Color]",
    "gui.extension.pyGame.surfaceGetAt": "obter cor dos pixels [Pos] do objeto de superfície [Surface]",
    "gui.extension.pyGame.surfaceGetWidth": "obter largura do objeto de superfície [Surface]",
    "gui.extension.pyGame.surfaceGetHeight": "obter altura do objeto de superfície [Surface]",
    "gui.extension.pyGame.surfaceGetRect": "obter área retangular do objeto de superfície [Surface]",
    "gui.extension.pyGame.surfaceMove": "Superfície[Surface] coordenadas de deslocamento [CONTENT]",
    "paint.paintEditor.hue": "Cor",
    "paint.paintEditor.saturation": "Saturação",
    "paint.paintEditor.brightness": "Brilho",
    "gui.comingSoon.message1": "Não se preocupe, estamos trabalhando nisso {emoji}",
    "gui.comingSoon.message2": "Em breve...",
    "gui.comingSoon.message3": "Estamos trabalhando nisso {emoji}",
    "paint.paintEditor.fill": "Preencher",
    "paint.paintEditor.costume": "Fantasia",
    "paint.paintEditor.group": "Agrupar",
    "paint.paintEditor.ungroup": "Desagrupar",
    "paint.paintEditor.undo": "Desfazer",
    "paint.paintEditor.redo": "Refazer",
    "paint.paintEditor.forward": "Para a Frente",
    "paint.paintEditor.backward": "Para Trás",
    "paint.paintEditor.front": "Frente",
    "paint.paintEditor.back": "Atrás",
    "paint.paintEditor.more": "Mais",
    "paint.modeTools.brushSize": "Tamanho",
    "paint.modeTools.eraserSize": "Tamanho da borracha",
    "paint.modeTools.copy": "Copiar",
    "paint.modeTools.paste": "Colar",
    "paint.modeTools.delete": "Apagar",
    "paint.modeTools.curved": "Curvado",
    "paint.modeTools.pointed": "Apontado",
    "paint.modeTools.thickness": "Espessura",
    "paint.modeTools.flipHorizontal": "Espelhar Horizontalmente",
    "paint.modeTools.flipVertical": "Espelhar Verticalmente",
    "paint.modeTools.filled": "Preenchido",
    "paint.modeTools.outlined": "Contornar",
    "paint.paintEditor.bitmap": "Converter para Bitmap",
    "paint.paintEditor.vector": "Converter pra Vetor",
    "paint.paintEditor.stroke": "Contornar",
    "paint.brushMode.brush": "Pincel",
    "paint.eraserMode.eraser": "Apagador",
    "paint.fillMode.fill": "Preencher",
    "paint.lineMode.line": "Linha",
    "paint.ovalMode.oval": "Círculo",
    "paint.rectMode.rect": "Retângulo",
    "paint.reshapeMode.reshape": "Remodelar",
    "paint.roundedRectMode.roundedRect": "Retângulo Arredondado",
    "paint.selectMode.select": "Selecionar",
    "paint.textMode.text": "Texto",
    "paint.colorPicker.swap": "Troque",
    "gui.dialog.notConnectDevice": "Sem dispositivo conectado",
    "gui.dialog.prompt": "Aviso",
    "gui.dialog.openPort": "Abrir Porta",
    "gui.dialog.error": "Erro",
    "gui.dialog.notSelectDevice": "Nenhum dispositivo selecionado, por favor clique em [Extensão] no canto inferior esquerdo do software,</br>clique para selecionar o dispositivo que deseja conectar",
    "gui.dialog.connectDeviceError1": "Conectar dispositivo(",
    "gui.dialog.connectDeviceError2": ")Falha, por favor, siga as etapas abaixo:</br> Substitua a porta USB e reconecte o cabo USB</br> Verifique se está conectado",
    "gui.dialog.connectDeviceError3": "Placa de Controle Principal</br> * Junte-se ao Grupo Oficial de Comunicações (671877416) para Relatar Problemas",
    "gui.dialog.calibration1": "</br> Por favor, vire",
    "gui.dialog.calibration2": "Calibrar a placa de controle principal</br>",
    "gui.dialog.runAsManager": "Por favor, feche o Mind+ e clique com o botão direito no ícone do software e selecione \"Executar como administrador\". Depois de iniciar, selecione esta função novamente.",
    "gui.dialog.runErrorForVortex": "Sistema não funcionando corretamente, desconecte o Vortex",
    "gui.dialog.runError": "Sistema não funcionando corretamente",
    "gui.dialog.close": "Fechar",
    "gui.dialog.variableNameSpecialCharacters": "nome da variável %1 não pode conter caracteres especiais:[`~!@#$%^&()+<>?:{},./;'[]]",
    "gui.dialog.funcNameSpecialCharacters": "nome da função %1 não pode conter caracteres especiais:[`~!@#$%^&()+<>?:{},./;'[]]",
    "gui.dialog.funcArgsSpecialCharacters": "argumentos da função %1 não podem conter caracteres especiais:[`~!@#$%^&()+<>?:{},./;'[]]",
    "gui.dialog.and": "e",
    "gui.dialog.yes": "Sim",
    "gui.dialog.no": "Não",
    "gui.dialog.maxReset": "Excluir os códigos na área de edição e carregar os códigos de configuração de fábrica?",
    "gui.dialog.feedbackCannotEmpty": "Por favor, preencha o feedback.",
    "gui.dialog.noInstallCompiler": "Nenhum compilador instalado",
    "gui.dialog.install": "Instalar online",
    "gui.dialog.cancle": "Cancelar",
    "gui.dialog.installingCompiler": "instalando compilador",
    "gui.dialog.uploading": "carregando",
    "gui.dialog.changeLanuage": "Trocar o idioma irá limpar o programa atual. Tem certeza de que deseja continuar?",
    "gui.dialog.boardNotSupportMpy": "placa atual: %1 não suporta micropython",
    "gui.dialog.loadBlankItem": "Item não suportado</br> está recarregando um item em branco",
    "gui.dialog.switchBaudError": "Erro ocorrido ao mudar a taxa de baud</br>",
    "gui.dialog.turnMicrobitCompass": "Precisa calibrar a bússola eletrônica, por favor ajuste a atitude do mciro: bit para mover as luzes LED piscando até que todas as luzes LED estejam acesas",
    "gui.dialog.operationShow": "Demonstração de Operação",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "computador",
    "gui.dialog.delete": "excluir",
    "gui.dialog.sureDeleteFile": "Tem certeza de que deseja excluir o arquivo [%2] em %1?",
    "gui.dialog.sureDeleteFolder": "Tem certeza de que deseja excluir a pasta [%2] em %1?",
    "gui.dialog.uncorrectPath": "Não selecionar o caminho correto",
    "gui.dialog.laterDownLoad": "Atualizar Mais Tarde",
    "gui.dialog.dwnLoadWebsite": "Site de Download",
    "gui.dialog.laterUpdate": "Instalar Mais Tarde",
    "gui.dialog.update": "Instalar",
    "gui.dialog.getUpdateMsgError": "Erro ocorrido ao obter a mensagem de atualização",
    "gui.dialog.ok": "Ok",
    "gui.dialog.closeCodeBoxes": "Tem certeza de que deseja fechar %1 caixas de código?",
    "gui.dialog.emailError": "Formato de e-mail incorreto!",
    "gui.dialog.emailEmpty": "E-mail não pode estar vazio!",
    "gui.dialog.bigFilePrompt": "O arquivo é grande, por favor, tenha paciência e espere, obrigado!",
    "gui.dialog.successFeedbackPrompt1": "Obrigado pelo seu feedback, a página será recarregada após 3 segundos.",
    "gui.dialog.successFeedbackPrompt2": "Seu feedback foi enviado. Por favor, envie-o novamente após alguns segundos.",
    "gui.dialog.failedFeedbackPrompt": "Falha no envio. Pedimos desculpas pela inconveniência causada. Por favor, visite nosso fórum online para relatar seu feedback em https://www.dfrobot.com/forum/, obrigado!",
    "gui.dialog.successFeedbackPrompt3": "Obrigado pelo seu feedback",
    "gui.dialog.forcedCloseSoftWare": "Ocorreu um erro ao salvar o arquivo, forçar o fechamento do software?",
    "gui.dialog.saveSuccess": "O projeto está salvo",
    "gui.dialog.noSelectTruePath": "Não selecione o caminho correto",
    "gui.dialog.newItemContent": "Você criará um projeto em branco para substituir o projeto atual, tem certeza de que deseja criar?",
    "gui.dialog.deleteBlocksError": "Falha ao excluir o bloco %1, por favor, exclua-o manualmente",
    "gui.dialog.netIsConnect": "Por favor, verifique se a rede está conectada",
    "gui.dialog.needToUpdate": "Atualizar o compilador para a versão mais recente: %1?",
    "gui.dialog.cantUseMpythonInArduinoC": "Nenhum compilador instalado, você não pode usar mpython sob Arduino C. Se o computador não tiver uma rede, você pode baixar o 'Pacote de Plug-in Offline do Compilador' no site oficial.",
    "gui.dialog.enterMpyMode": "entrar no modo micropython",
    "gui.dialog.runHardware": "Conecte o dispositivo primeiro, depois opere o hardware",
    "gui.dialog.sureDelete": "Tem certeza de que deseja excluir isso?",
    "gui.dialog.notSupportWebGL": "Ocorreu um erro ao carregar o palco, isso pode acontecer quando o seu computador não suporta o WebGL,</br> Consulte os detalhes mostrados abaixo: </br> %1 </br>Contate-nos pelo grupo QQ: 671877416",
    "gui.dialog.boardIsnotMPython": "O controle principal conectado atualmente não é mPython, por favor, conecte ao mPython",
    "gui.dialog.swdMicrobitUpload": "Seu computador deve usar o SWD para gravar o micro: bit, por favor, execute o Mind+ com privilégios de administrador.",
    "gui.dialog.openPortError": "erro ao abrir a porta serial %1: %2",
    "gui.dialog.pressedA": "Por favor, pressione e segure o botão A, não solte.",
    "gui.dialog.unpressedA": "Por favor, solte o botão A",
    "gui.dialog.unsupportProject": "Item não suportado</br> está recarregando um item em branco.",
    "gui.dialog.openProjectError": "Projetos feitos no modo Scratch devem ser abertos no modo Scratch</br> Por favor, verifique o modo atual.",
    "gui.dialog.installCompilerError1": "Falha no download, você pode baixar o instalador offline ou tentar novamente",
    "gui.dialog.installCompilerOffline": "Baixar Compilador Offline",
    "gui.dialog.installCompilerError2": "Erro de verificação, você pode baixar o instalador offline ou tentar baixar novamente",
    "gui.dialog.installCompilerError3": "Erro de instalação, você pode baixar o instalador offline ou tentar baixar novamente",
    "gui.dialog.installCompilerSuccess": "O compilador foi instalado, você pode usar o Esp32 sob ArduinoC",
    "gui.dialog.sureAdapter": "O adaptador não foi encontrado, por favor, confirme se o adaptador está conectado",
    "gui.dialog.checkPort": "Falha ao abrir a porta serial, por favor, tente reconectar a porta serial, ou verifique se a porta serial está ocupada.",
    "gui.dialog.changeBoard": "Mudar de placa irá apagar o programa atual. Tem certeza de que deseja continuar?",
    "gui.crashMessage.somethingWrong": "Oops! Algo deu errado.",
    "gui.crashMessage.reload": "Reiniciar",
    "gui.crashMessage.submit": "Enviar",
    "gui.crashMessage.attachment": "Anexo",
    "gui.crashMessage.errFeedbackNote": "Você pode escrever mensagens de erro ou sugestões aqui, o Mind+ ficará melhor com a sua participação.",
    "gui.crashMessage.promptContent": "Pedimos desculpas, parece que o Mind+ travou. Você pode enviar este erro para a equipe do Mind+ no lado direito, muito obrigado! Por favor, atualize a página para tentar novamente.",
    "gui.dialog.isSaveProject": "Salvar o projeto atual?",
    "gui.dialog.save": "Salvar",
    "gui.dialog.notSave": "Não Salvar",
    "gui.dialog.continue": "Continuar",
    "gui.dialog.turnCalliopeCompass": "Precisa calibrar a bússola eletrônica, por favor ajuste a atitude do calliope para mover as luzes LED piscando até que todas as luzes LED estejam acesas",
    "gui.dialog.downLoadUpdate": "Baixar Atualização",
    "gui.dialog.downLoading": "Baixando...",
    "gui.crashMessage.cacheListPrompt": "Você pode encontrar o arquivo do projeto no menu ?Projeto?> ?Lista de Cache?",
    "gui.dialog.cancelSave": "Cancelar Salvar",
    "gui.dialog.saveMPProjectToSb3": "O formato atual selecionado é .sb3, apenas alguns blocos de construção básicos no modo de tempo real são salvos, você deseja continuar salvando?",
    "gui.dialog.variableIsNull": "O nome da variável não pode estar vazio",
    "gui.dialog.listNameIsNull": "o nome da lista não pode estar vazio",
    "gui.dialog.variableIsRepeat": "os nomes das variáveis não podem ser repetidos, por favor, modifique",
    "gui.dialog.pictureAINotNull": "não pode ser nulo",
    "gui.dialog.pictureAIInitServer": "por favor, inicialize o servidor primeiro",
    "gui.dialog.pictureAIInitServerError": "Erro ao inicializar o servidor, por favor, verifique o número da conta e a chave secreta",
    "gui.dialog.versionPrompt": "versão atual do arquivo sb3?%1?",
    "gui.dialog.versionGreater": "A versão do arquivo sb3 é superior à versão atual do software, versão do arquivo sb3?%1?",
    "gui.dialog.noModulePrompt": "Não há módulos pequenos na classificação atual. Veja a ajuda para mais detalhes.",
    "gui.dialog.firstSelectBoard": "Por favor, selecione 'Placa' ou 'Kit' primeiro.",
    "gui.dialog.downloadFileError": "Erro ao baixar o arquivo",
    "gui.dialog.noSearchModules": "Nenhum módulo relacionado encontrado, por favor, verifique o caminho de busca",
    "gui.dialog.networkError": "Erro de rede",
    "gui.dialog.saveThirdModulesError": "Erro ao salvar a biblioteca do usuário",
    "gui.dialog.coverUseExForLoadProject": "Uma biblioteca com o mesmo nome existe na biblioteca do usuário, que foi substituída pela biblioteca do usuário no projeto",
    "gui.dialog.isContinueUpdateThirdModule": "Atualmente existem as mesmas bibliotecas de extensão do usuário. Continuar a atualização irá sobrescrever a biblioteca do usuário atual. Deseja continuar?",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "Modificou o campo ?%2? de %1, que precisa ser importado manualmente na biblioteca de extensão. Este refresh foi cancelado",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "Erro ao analisar %1, este refresh continuará, mas o conteúdo em ?%2? não será atualizado",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "A forma do bloco de construção ou o tipo da caixa de entrada foi modificado. Por favor, exclua manualmente o bloco de construção destacado na área de programação e, em seguida, atualize-o. Este refresh está cancelado.",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh1": "Para modificar a forma do bloco ou o tipo da caixa de entrada, exclua manualmente o bloco destacado na área de programação e verifique o sprite?%1?antes de atualizar.",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh2": "A forma do bloco ou o tipo da caixa de entrada foi modificado. Por favor, verifique o sprite?%1?e atualize-o novamente. Este refresh está cancelado.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "Modificação da forma do bloco de construção ou do tipo da caixa de entrada, exclua manualmente o bloco de construção destacado na área de programação antes de carregar, e o carregamento é cancelado.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad1": "Para modificar a forma do bloco ou o tipo da caixa de entrada, exclua manualmente o bloco destacado na área de programação e verifique o sprite?%1?antes de carregar, o carregamento é cancelado desta vez.",
    "gui.dialog.thirdex.blockAlterAndCancleLoad2": "A forma do bloco ou o tipo da caixa de entrada foi modificado. Por favor, verifique o sprite?%1?e carregue-o novamente. Este carregamento está cancelado.",
    "gui.dialog.thirdex.filesEmpty": "Os arquivos não podem ficar vazios, o que pode causar a perda de arquivos relacionados. Por favor, verifique os arquivos da biblioteca do usuário importados!",
    "gui.dialog.thirdex.refreshing": "atualizando...",
    "gui.dialog.thirdex.refreshSuccess": "atualização bem-sucedida",
    "gui.dialog.isSaving": "Salvando...",
    "gui.dialog.isExporting": "Exportando...",
    "gui.dialog.mpySb3Prompt": "「%1」atualizou o modo micropython. Se houver algum erro no conteúdo do projeto, você precisará modificá-lo manualmente",
    "gui.dialog.translate.fieldError": "Os seguintes campos de erro podem causar erros na exibição dos blocos de construção",
    "gui.dialog.translate.startReadFile": "Iniciando a leitura de arquivos, por favor, tenha paciência...",
    "gui.dialog.translate.fileFormatError": "Não há conteúdo de arquivo de tradução relevante, o formato do conteúdo do arquivo não corresponde",
    "gui.dialog.connecting.requestCloseFW": "Verifique se o firewall desta máquina está aberto, o que fará com que o Tello não consiga se conectar normalmente, clique em OK para fechar o firewall",
    "gui.dialog.cache.crash": "Detectado desligamento anormal do software, e o projeto pode ser restaurado através do arquivo de cache. Abra o caminho \"projeto\"> \"lista de cache\"",
    "gui.dialog.python.detectPip": "O ambiente Pip está inicializando, por favor, aguarde",
    "gui.dialog.python.detectPipSucccess": "Inicialização do ambiente pip concluída",
    "gui.dialog.python.detectPipError": "a inicialização do pip falhou, a função de gerenciamento de bibliotecas não está disponível. Por favor, verifique a conexão de rede, você também pode executar \"[PYTHON]\" -m pip install --upgrade pip no prompt de comando e, em seguida, reiniciar Use esta função.",
    "gui.dialog.python.installPipreqsError": "a instalação de [nome] falhou, a biblioteca do usuário não será capaz de exportar normalmente a lista de dependências da biblioteca personalizada, por favor, instale manualmente.",
    "gui.dialog.screenshoting": "Captura de tela em andamento",
    "gui.dialog.saveScreenshot": "Salvar Captura de Tela",
    "gui.dialog.saveScreenshotSuccess": "Salvar a captura de tela com sucesso",
    "gui.dialog.serialDriverInstalling": "Instalação do Driver Serial...",
    "gui.dialog.resetDeviceSuccess": "O dispositivo foi restaurado com sucesso",
    "gui.dialog.resetDeviceFailed": "Falha ao restaurar o dispositivo",
    "gui.dialog.exportSuccess": "Exportação Bem-sucedida",
    "gui.dialog.exportFailed": "Falha na exportação",
    "gui.dialog.exportCostume": "Exportar Função",
    "gui.dialog.loadProjectError": "Erro ao carregar projeto",
    "gui.dialog.readFileError": "Erro ao ler arquivo",
    "gui.dialog.projectIsDeleted": "Projeto foi deletado",
    "gui.dialog.projectIsNotExist": "Falha ao abrir projeto, arquivo não existe",
    "gui.dialog.checkNetwork": "A rede atual não está disponível, por favor, verifique se o computador pode acessar a Internet",
    "gui.dialog.checkIotNetworkAndParameter": "A conexão com a Internet das Coisas falhou, por favor, verifique se os parâmetros foram preenchidos corretamente",
    "gui.dialog.knownError": "Problema conhecido:",
    "gui.dialog.unknownError": "Problema desconhecido:",
    "gui.dialog.cancelInstall": "Cancelar Instalação",
    "gui.dialog.cannotFindDriver": "O arquivo do driver não foi encontrado, por favor, verifique o caminho:",
    "gui.dialog.programError": "Erro de programa",
    "gui.dialog.pleaseHandleInstall": "Por favor, instale a ferramenta manualmente:",
    "gui.dialog.installSerialDriverError": "Erro ao instalar o driver serial",
    "gui.dialog.installSerialDriverSuccess": "Instalação do Driver Serial Concluída",
    "gui.dialog.hadInstall": "Instalado",
    "gui.dialog.cache.sameAsCacheDeleteBlock": "A interface de cache possui a mesma biblioteca de extensão do usuário, o bloco relacionado será excluído, se deseja continuar",
    "gui.dialog.cache.saveAs": "Salvar Como",
    "gui.dialog.cache.exportExtError": "Erro ao exportar extensão:",
    "gui.dialog.cache.exportExtSuccess": "Exportar extensão com sucesso",
    "gui.dialog.thirdExt.lostVersionKeyWithThird": "O campo de versão está ausente, por favor, verifique o campo de versão, ele deve estar no mesmo nível que o campo de id",
    "gui.dialog.thirdExt.notFoundRelatedFile": "Nenhum arquivo relacionado encontrado",
    "gui.dialog.thirdExt.requestFileError": "Erro ao solicitar arquivo",
    "gui.dialog.thirdExt.unzipLibrariesFailed": "Falha ao descompactar libraries.zip",
    "gui.dialog.thirdExt.pipreqsLostAndFillConfig": "A biblioteca pipreqs não está instalada corretamente, e há um erro na exportação automática da lista de pacotes dependentes. Para a conveniência dos usuários, configure manualmente as informações da biblioteca dependente em config.json",
    "gui.dialog.thirdExt.exportExtToFolderSuccess": "Extended com sucesso exportado para o diretório",
    "gui.dialog.thirdExt.configJsonKeyLost": "'O campo [KEY] em config.json não pode ficar vazio!'",
    "gui.dialog.thirdExt.configJsonKeyLost_ID": "O id em config.json é o ID do módulo e não pode ficar vazio!",
    "gui.dialog.thirdExt.configJsonKeyLost_platform": "plataforma define a plataforma suportada, pelo menos uma plataforma (win, mac, web) é suportada! Atualmente, apenas a plataforma win (winddowns) é suportada",
    "gui.dialog.thirdExt.configJsonKeyLost_asset": "O modo de idioma suportado pela definição de ativos em config.json, não pode ficar vazio!",
    "gui.dialog.thirdExt.configJsonError_asset": "O conteúdo do ativo em config.json está definido incorretamente. Pelo menos um modo de idioma é suportado",
    "gui.dialog.thirdExt.configJsonLost_version": "O campo de versão está ausente, por favor, verifique o campo de versão, ele deve estar no mesmo nível que o campo de id",
    "gui.dialog.thirdExt.configJsonLost_asset_mode": "O campo principal do modo de idioma do ativo [MODE] em config.json não pode ficar vazio!",
    "gui.dialog.thirdExt.configJsonError_mode_board": "O campo de placa do modo de idioma [MODE] define o status da placa suportada e pelo menos uma placa é suportada",
    "gui.dialog.stage.microphoneLost": "Não é possível ouvir nenhum som, por favor, verifique se o microfone está conectado",
    "gui.dialog.ml5.closeMl5Modal": "Fechar a janela perderá os dados de treinamento, por favor, salve-o a tempo, deseja continuar a fechar",
    "gui.dialog.ml5.restartMl5Train": "Essa operação excluirá todas as categorias, tem certeza de que deseja continuar?",
    "gui.dialog.ml5.ml5DefaultLabel": "Categoria 1",
    "gui.dialog.account.getCloudListError": "Erro ao obter lista de arquivos, se deseja recarregar",
    "gui.dialog.account.requestCloudList": "Recarregar",
    "gui.dialog.python.getPIPListForUpdateError": "Tempo limite obtendo dados de atualização do PIP",
    "gui.dialog.python.pipHasNoUpdate": "Nenhuma atualização disponível",
    "gui.dialog.python.pipUpdateSucess": "[SUCESSO] atualizado com sucesso, [FALHA] falhou, respectivamente [INFO]",
    "gui.dialog.python.pipUpdateAllSucess": "A atualização foi bem-sucedida, [SUCESSO] no total",
    "gui.dialog.python.pipUpdateError": "Atualizando [NOME] falhou. Detalhes podem ser visualizados na página do modo PIP",
    "gui.dialog.python.pipInstallError": "Falha ao instalar [NOME] [VERSÃO], por favor, verifique os detalhes na página do modo PIP",
    "gui.dialog.python.pipUninstallSucess": "[SUCESSO] desinstalado com sucesso, [FALHA] falhou, respectivamente [INFO]",
    "gui.dialog.python.pipUninstallAllSucess": "Desinstalação bem-sucedida, [CONTAGEM] no total",
    "gui.dialog.python.pipUninstallError": "Desinstalação de [NOME] falhou. Detalhes podem ser visualizados na página do modo PIP",
    "gui.dialog.python.sourceUnknow": "Fonte desconhecida",
    "gui.dialog.python.sourceTsinghuaUniversity": "Fonte da Universidade Tsinghua",
    "gui.dialog.python.sourceDouban": "Fonte do Douban",
    "gui.dialog.python.sourceAli": "Fonte da Ali",
    "gui.dialog.python.sourceNeteasy": "Fonte da Neteasy",
    "gui.dialog.python.sourceZGKJDX": "Universidade de Ciência e Tecnologia da China",
    "gui.dialog.python.sourceOfficial": "Fonte oficial do Python",
    "gui.dialog.python.rebootSoftForVsEditor": "A tradução das funções relacionadas ao editor de código precisa ser reiniciada para entrar em vigor.",
    "gui.dialog.procedure.procedureNameIsRepeated": "A função 「[NAME]」 já está definida, por favor, renomeie-a",
    "gui.dialog.procedure.procedureNameIsEmpty": "O nome da função não pode ficar vazio",
    "gui.dialog.project.saveProjectCheck": "Verificação Secundária",
    "gui.dialog.project.trySaveProjectAgain": "Desculpe, ocorreu um erro ao analisar o arquivo salvo, por favor, tente salvar novamente",
    "gui.dialog.software.secondInstanceTips": "A configuração atual do software não permite aberturas múltiplas. Se precisar modificar, por favor, atualize as configurações em [Configurações - Configurações do Sistema - Aberturas Múltiplas do Software]",
    "gui.dialog.project.saveFailed": "Erro ao salvar",
    "gui.dialog.pythonRemote.saveMPProjectToSb3": "O formato atualmente selecionado é .sb3, apenas alguns blocos de construção básicos no modo de tempo real são salvos, deseja continuar salvando?",
    "gui.dialog.thirdExt.downloadNow": "Baixar Agora",
    "gui.dialog.thirdExt.downloadingLib": "Baixando",
    "gui.dialog.thirdExt.downloadFailed": "Falha no Download",
    "gui.dialog.thirdExt.detectThirdExtLibrariesInfo": "Detectado que esta biblioteca do usuário requer [NEED] bibliotecas dependentes, [INSTALLED] já existem, deseja baixar as [REST] imediatamente?",
    "gui.dialog.thirdExt.downloadFinishFailed": "Falha ao baixar [LENGTH] biblioteca, é [INFO], por favor, vá para o gerenciamento de bibliotecas para ver as informações específicas",
    "gui.dialog.thirdExt.downloadFinishSuccess": "[LENGTH] bibliotecas foram baixadas com sucesso, para detalhes, por favor, vá para o gerenciamento de bibliotecas para ver",
    "gui.dialog.pythonRemote.detectingRemoteLibraries": "Detectando versões de bibliotecas dependentes em [NAME], por favor, aguarde...",
    "gui.dialog.pythonRemote.detectRemoteLibrariesFinish": "Detecção concluída, todas as novas versões",
    "gui.dialog.pythonRemote.detectRemoteLibrariesInfo": "Detectado que [LENGTH] bibliotecas em <b>[NAME]</b> podem ser atualizadas, deseja baixar agora?",
    "gui.dialog.pythonRemote.detectRemotePipError": "O PIP não está disponível no sistema remoto, por favor, confirme que o ambiente Python existe antes de usar a função de gerenciamento de bibliotecas",
    "gui.dialog.thirdExt.supportedModesAndMotherboards": "Modos e placas suportadas na biblioteca do usuário:",
    "gui.dialog.thirdExt.offlineArduinoC": "Arduino C offline",
    "gui.dialog.thirdExt.offlineMicroPython": "MicroPython offline",
    "gui.dialog.thirdExt.online": "Online",
    "gui.dialog.thirdExt.python": "Python",
    "gui.dialog.thirdExt.currentModeAndMotherboard": "Modo e placa atuais do Mind+",
    "BACKDROPS_ARCTIC": "Ártico",
    "BACKDROPS_BASEBALL": "Beisebol",
    "BACKDROPS_BASEBALL_2": "Beisebol 2",
    "BACKDROPS_BASKETBALL": "Basquete",
    "BACKDROPS_BASKETBALL_2": "Basquete 2",
    "BACKDROPS_BEACH_MALIBU": "Praia Malibu",
    "BACKDROPS_BEACH_RIO": "Praia Rio",
    "BACKDROPS_BEDROOM": "Quarto",
    "BACKDROPS_BEDROOM_2": "Quarto 2",
    "BACKDROPS_BEDROOM_3": "Quarto 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Banco com vista",
    "BACKDROPS_BLUE_SKY": "Céu azul",
    "BACKDROPS_BLUE_SKY_2": "Céu azul 2",
    "BACKDROPS_BLUE_SKY3": "Céu azul 3",
    "BACKDROPS_BOARDWALK": "Calçadão",
    "BACKDROPS_CANYON": "Cânion",
    "BACKDROPS_CASTLE_1": "Castelo 1",
    "BACKDROPS_CASTLE_2": "Castelo 2",
    "BACKDROPS_CASTLE_3": "Castelo 3",
    "BACKDROPS_CASTLE_4": "Castelo 4",
    "BACKDROPS_CHALKBOARD": "Quadro negro",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "Círculos",
    "BACKDROPS_CITY_WITH_WATER": "Cidade com água",
    "BACKDROPS_COLORFUL_CITY": "Cidade colorida",
    "BACKDROPS_CONCERT": "Concerto",
    "BACKDROPS_DESERT": "Deserto",
    "BACKDROPS_FARM": "Fazenda",
    "BACKDROPS_FIELD_AT_MIT": "Campo em MIT",
    "BACKDROPS_FLOWERS": "Flores",
    "BACKDROPS_FOREST": "Floresta",
    "BACKDROPS_GALAXY": "Galáxia",
    "BACKDROPS_GARDEN-ROCK": "Jardim de pedras",
    "BACKDROPS_GREEK_THEATER": "Teatro grego",
    "BACKDROPS_HALL": "Salão",
    "BACKDROPS_HAY_FIELD": "Campo de feno",
    "BACKDROPS_GRAFFITI": "Grafite",
    "BACKDROPS_HEARTS1": "Corações 1",
    "BACKDROPS_HEARTS2": "Corações 2",
    "BACKDROPS_HILL": "Colina",
    "BACKDROPS_JUNGLE": "Selva",
    "BACKDROPS_JURASSIC": "Jurássico",
    "BACKDROPS_LIGHT": "Luz",
    "BACKDROPS_METRO": "Metrô",
    "BACKDROPS_MOON": "Lua",
    "BACKDROPS_MOUNTAIN": "Montanha",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "Nebulosa",
    "BACKDROPS_NEON_TUNNEL": "Túnel de néon",
    "BACKDROPS_NIGHT_CITY": "Cidade noturna",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Cidade noturna com rua",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "Festa",
    "BACKDROPS_PATHWAY": "Caminho",
    "BACKDROPS_PARTY_ROOM": "Sala de festas",
    "BACKDROPS_PLAYGROUND": "Playground",
    "BACKDROPS_PLAYING_FIELD": "Campo de jogo",
    "BACKDROPS_POOL": "Piscina",
    "BACKDROPS_PURPLE": "Roxo",
    "BACKDROPS_RAYS": "Raios",
    "BACKDROPS_REFRIGERATOR": "Geladeira",
    "BACKDROPS_ROOM_1": "Quarto 1",
    "BACKDROPS_ROOM_2": "Quarto 2",
    "BACKDROPS_SAVANNA": "Savana",
    "BACKDROPS_SCHOOL": "Escola",
    "BACKDROPS_SLOPES": "Encostas",
    "BACKDROPS_SOCCER": "Futebol",
    "BACKDROPS_SOCCER_2": "Futebol 2",
    "BACKDROPS_SPACE": "Espaço",
    "BACKDROPS_SPACE_2": "Espaço 2",
    "BACKDROPS_SPACE_3": "Espaço 3",
    "BACKDROPS_SPACE_4": "Espaço 4",
    "BACKDROPS_SPOTLIGHT-STAGE": "Palco de destaque",
    "BACKDROPS_STARS": "Estrelas",
    "BACKDROPS_STRIPES": "Listras",
    "BACKDROPS_THEATER": "Teatro",
    "BACKDROPS_THEATER_2": "Teatro 2",
    "BACKDROPS_TREE": "Árvore",
    "BACKDROPS_UNDERWATER_1": "Subaquático 1",
    "BACKDROPS_UNDERWATER_2": "Subaquático 2",
    "BACKDROPS_URBAN": "Urbano",
    "BACKDROPS_WALL_1": "Parede 1",
    "BACKDROPS_WALL_2": "Parede 2",
    "BACKDROPS_WATER_AND_ROCKS": "Água e rochas",
    "BACKDROPS_WETLAND": "Área úmida",
    "BACKDROPS_WINTER": "Inverno",
    "BACKDROPS_WINTER-LIGHTS": "Luzes de inverno",
    "BACKDROPS_WITCH_HOUSE": "Casa da bruxa",
    "BACKDROPS_WOODS": "Bosques",
    "BACKDROPS_WOODS_AND_BENCH": "Bosques e banco",
    "BACKDROPS_XY-GRID": "Xy-grid",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "Abby-a",
    "COSTUMES_ABBY-B": "Abby-b",
    "COSTUMES_ABBY-C": "Abby-c",
    "COSTUMES_ABBY-D": "Abby-d",
    "COSTUMES_AMON": "Amon",
    "COSTUMES_ANDIE-A": "Andie-a",
    "COSTUMES_ANDIE-B": "Andie-b",
    "COSTUMES_ANDIE-C": "Andie-c",
    "COSTUMES_ANDIE-D": "Andie-d",
    "COSTUMES_ANINA_POP_DOWN": "Anina Pop Down",
    "COSTUMES_ANINA_POP_FRONT": "Anina Pop Front",
    "COSTUMES_ANINA_POP_L_ARM": "Anina Pop L Arm",
    "COSTUMES_ANINA_POP_LEFT": "Anina Pop Left",
    "COSTUMES_ANINA_POP_R_ARM": "Anina Pop R Arm",
    "COSTUMES_ANINA_POP_RIGHT": "Anina Pop Right",
    "COSTUMES_ANINA_POP_STAND": "Anina Pop Stand",
    "COSTUMES_ANINA_R_CROSS": "Anina R Cross",
    "COSTUMES_ANINA_STANCE": "Anina Stance",
    "COSTUMES_ANINA_TOP_FREEZE": "Anina Top Freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "Anina Top L Step",
    "COSTUMES_ANINA_TOP_R_STEP": "Anina Top R Step",
    "COSTUMES_ANINA_TOP_STAND": "Anina Top Stand",
    "COSTUMES_APPLE": "Maçã",
    "COSTUMES_ARROW1-A": "Seta1-a",
    "COSTUMES_ARROW1-B": "Seta1-b",
    "COSTUMES_ARROW1-C": "Seta1-c",
    "COSTUMES_ARROW1-D": "Seta1-d",
    "COSTUMES_AVERY_WALKING-A": "Avery andando-a",
    "COSTUMES_AVERY_WALKING-B": "Avery andando-b",
    "COSTUMES_AVERY_WALKING-C": "Avery andando-c",
    "COSTUMES_AVERY_WALKING-D": "Avery andando-d",
    "COSTUMES_AVERY-A": "Avery-a",
    "COSTUMES_AVERY-B": "Avery-b",
    "COSTUMES_BALL-A": "Bola-a",
    "COSTUMES_BALL-B": "Bola-b",
    "COSTUMES_BALL-C": "Bola-c",
    "COSTUMES_BALL-D": "Bola-d",
    "COSTUMES_BALL-E": "Bola-e",
    "COSTUMES_BALLERINA-A": "Bailarina-a",
    "COSTUMES_BALLERINA-B": "Bailarina-b",
    "COSTUMES_BALLERINA-C": "Bailarina-c",
    "COSTUMES_BALLERINA-D": "Bailarina-d",
    "COSTUMES_BALLOON1-A": "Balão1-a",
    "COSTUMES_BALLOON1-B": "Balão1-b",
    "COSTUMES_BALLOON1-C": "Balão1-c",
    "COSTUMES_BANANAS": "Bananas",
    "COSTUMES_BASEBALL": "Beisebol",
    "COSTUMES_BASKETBALL": "Basquete",
    "COSTUMES_BAT-A": "Morcego-a",
    "COSTUMES_BAT-B": "Morcego-b",
    "COSTUMES_BAT-C": "Morcego-c",
    "COSTUMES_BAT-D": "Morcego-d",
    "COSTUMES_BATTER-A": "Rebatedor-a",
    "COSTUMES_BATTER-B": "Rebatedor-b",
    "COSTUMES_BATTER-C": "Rebatedor-c",
    "COSTUMES_BATTER-D": "Rebatedor-d",
    "COSTUMES_BEACHBALL": "Bola de praia",
    "COSTUMES_BEAR-A": "Urso-a",
    "COSTUMES_BEAR-B": "Urso-b",
    "COSTUMES_BEAR-WALK-A": "Urso-andando-a",
    "COSTUMES_BEAR-WALK-B": "Urso-andando-b",
    "COSTUMES_BEAR-WALK-C": "Urso-andando-c",
    "COSTUMES_BEAR-WALK-D": "Urso-andando-d",
    "COSTUMES_BEAR-WALK-E": "Urso-andando-e",
    "COSTUMES_BEAR-WALK-F": "Urso-andando-f",
    "COSTUMES_BEAR-WALK-G": "Urso-andando-g",
    "COSTUMES_BEAR-WALK-H": "Urso-andando-h",
    "COSTUMES_BEETLE": "Besouro",
    "COSTUMES_BELL1": "Sino1",
    "COSTUMES_BEN-A": "Ben-a",
    "COSTUMES_BEN-B": "Ben-b",
    "COSTUMES_BEN-C": "Ben-c",
    "COSTUMES_BEN-D": "Ben-d",
    "COSTUMES_BOWL-A": "Tigela-a",
    "COSTUMES_BOWTIE": "Laço",
    "COSTUMES_BREAD": "Pão",
    "COSTUMES_BROOM": "Vassoura",
    "COSTUMES_BUILDING-A": "Prédio-a",
    "COSTUMES_BUILDING-B": "Prédio-b",
    "COSTUMES_BUILDING-C": "Prédio-c",
    "COSTUMES_BUILDING-D": "Prédio-d",
    "COSTUMES_BUILDING-E": "Prédio-e",
    "COSTUMES_BUILDING-F": "Prédio-f",
    "COSTUMES_BUILDING-G": "Prédio-g",
    "COSTUMES_BUILDING-H": "Prédio-h",
    "COSTUMES_BUILDING-I": "Prédio-i",
    "COSTUMES_BUILDING-J": "Prédio-j",
    "COSTUMES_BUTTERFLY1-A": "Borboleta1-a",
    "COSTUMES_BUTTERFLY1-B": "Borboleta1-b",
    "COSTUMES_BUTTERFLY1-C": "Borboleta1-c",
    "COSTUMES_BUTTERFLY2-A": "Borboleta2-a",
    "COSTUMES_BUTTERFLY2-B": "Borboleta2-b",
    "COSTUMES_BUTTON1": "Botão1",
    "COSTUMES_BUTTON2-A": "Botão2-a",
    "COSTUMES_BUTTON2-B": "Botão2-b",
    "COSTUMES_BUTTON3-A": "Botão3-a",
    "COSTUMES_BUTTON3-B": "Botão3-b",
    "COSTUMES_BUTTON4-A": "Botão4-a",
    "COSTUMES_BUTTON4-B": "Botão4-b",
    "COSTUMES_BUTTON5-A": "Botão5-a",
    "COSTUMES_BUTTON5-B": "Botão5-b",
    "COSTUMES_CAKE-A": "Bolo-a",
    "COSTUMES_CAKE-B": "Bolo-b",
    "COSTUMES_CALVRETT_JUMPING": "Calvrett Saltando",
    "COSTUMES_CALVRETT_THINKING": "Calvrett Pensando",
    "COSTUMES_CASEY-A": "Casey-a",
    "COSTUMES_CASEY-B": "Casey-b",
    "COSTUMES_CASEY-C": "Casey-c",
    "COSTUMES_CASEY-D": "Casey-d",
    "COSTUMES_CASSY-A": "Cassy-a",
    "COSTUMES_CASSY-B": "Cassy-b",
    "COSTUMES_CASSY-C": "Cassy-c",
    "COSTUMES_CASSY-D": "Cassy-d",
    "COSTUMES_CAT_2": "Gato 2",
    "COSTUMES_CATCHER-A": "Apanhador-a",
    "COSTUMES_CATCHER-B": "Apanhador-b",
    "COSTUMES_CATCHER-C": "Apanhador-c",
    "COSTUMES_CATCHER-D": "Apanhador-d",
    "COSTUMES_CENTAUR-A": "Centauro-a",
    "COSTUMES_CENTAUR-B": "Centauro-b",
    "COSTUMES_CENTAUR-C": "Centauro-c",
    "COSTUMES_CENTAUR-D": "Centauro-d",
    "COSTUMES_CHAMP99-A": "Champ99-a",
    "COSTUMES_CHAMP99-B": "Champ99-b",
    "COSTUMES_CHAMP99-C": "Champ99-c",
    "COSTUMES_CHAMP99-D": "Champ99-d",
    "COSTUMES_CHAMP99-E": "Champ99-e",
    "COSTUMES_CHAMP99-F": "Champ99-f",
    "COSTUMES_CHAMP99-G": "Champ99-g",
    "COSTUMES_CHEESY_PUFFS": "Salgadinhos",
    "COSTUMES_CHICK-A": "Pintinho-a",
    "COSTUMES_CHICK-B": "Pintinho-b",
    "COSTUMES_CHICK-C": "Pintinho-c",
    "COSTUMES_CITY_BUS-A": "Ônibus da cidade-a",
    "COSTUMES_CITY_BUS-B": "Ônibus da cidade-b",
    "COSTUMES_CLOUD": "Nuvem",
    "COSTUMES_CLOUD-A": "Nuvem-a",
    "COSTUMES_CLOUD-B": "Nuvem-b",
    "COSTUMES_CLOUD-C": "Nuvem-c",
    "COSTUMES_CLOUD-D": "Nuvem-d",
    "COSTUMES_CONVERTIBLE": "Conversível",
    "COSTUMES_CONVERTIBLE_3": "Conversível 2",
    "COSTUMES_CRAB-A": "Caranguejo-a",
    "COSTUMES_CRAB-B": "Caranguejo-b",
    "COSTUMES_CRYSTAL-A": "Cristal-a",
    "COSTUMES_CRYSTAL-B": "Cristal-b",
    "COSTUMES_DAN-A": "Dan-a",
    "COSTUMES_DAN-B": "Dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "Dee-a",
    "COSTUMES_DEE-B": "Dee-b",
    "COSTUMES_DEE-C": "Dee-c",
    "COSTUMES_DEE-D": "Dee-d",
    "COSTUMES_DEE-E": "Dee-e",
    "COSTUMES_DEVIN-A": "Devin-a",
    "COSTUMES_DEVIN-B": "Devin-b",
    "COSTUMES_DEVIN-C": "Devin-c",
    "COSTUMES_DEVIN-D": "Devin-d",
    "COSTUMES_DINOSAUR1-A": "Dinossauro 1-a",
    "COSTUMES_DINOSAUR1-B": "Dinossauro 1-b",
    "COSTUMES_DINOSAUR1-C": "Dinossauro 1-c",
    "COSTUMES_DINOSAUR1-D": "Dinossauro 1-d",
    "COSTUMES_DINOSAUR2-A": "Dinossauro 2-a",
    "COSTUMES_DINOSAUR2-B": "Dinossauro 2-b",
    "COSTUMES_DINOSAUR2-C": "Dinossauro 2-c",
    "COSTUMES_DINOSAUR2-D": "Dinossauro 2-d",
    "COSTUMES_DINOSAUR3-A": "Dinossauro 3-a",
    "COSTUMES_DINOSAUR3-B": "Dinossauro 3-b",
    "COSTUMES_DINOSAUR3-C": "Dinossauro 3-c",
    "COSTUMES_DINOSAUR3-D": "Dinossauro 3-d",
    "COSTUMES_DINOSAUR3-E": "Dinossauro 3-e",
    "COSTUMES_DINOSAUR4-A": "Dinossauro 4-a",
    "COSTUMES_DINOSAUR4-B": "Dinossauro 4-b",
    "COSTUMES_DINOSAUR4-C": "Dinossauro 4-c",
    "COSTUMES_DINOSAUR4-D": "Dinossauro 4-d",
    "COSTUMES_DIVER1": "Mergulhador 1",
    "COSTUMES_DIVER2": "Mergulhador 2",
    "COSTUMES_DM_FREEZE": "dm congelar",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "dm stance",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": "Cachorro 1-a",
    "COSTUMES_DOG1-B": "Cachorro 1-b",
    "COSTUMES_DOG2-A": "Cachorro 2-a",
    "COSTUMES_DOG2-B": "Cachorro 2-b",
    "COSTUMES_DOG2-C": "Cachorro 2-c",
    "COSTUMES_DONUT": "Rosquinha",
    "COSTUMES_DORIAN-A": "Dorian-a",
    "COSTUMES_DORIAN-B": "Dorian-b",
    "COSTUMES_DORIAN-C": "Dorian-c",
    "COSTUMES_DORIAN-D": "Dorian-d",
    "COSTUMES_DOT-A": "Ponto-a",
    "COSTUMES_DOT-B": "Ponto-b",
    "COSTUMES_DOT-C": "Ponto-c",
    "COSTUMES_DOT-D": "Ponto-d",
    "COSTUMES_DOVE-A": "Pombo-a",
    "COSTUMES_DOVE-B": "Pombo-b",
    "COSTUMES_DRAGON-A": "Dragão-a",
    "COSTUMES_DRAGON-B": "Dragão-b",
    "COSTUMES_DRAGON-C": "Dragão-c",
    "COSTUMES_DRAGON1-A": "Dragão 1-a",
    "COSTUMES_DRAGON1-B": "Dragão 1-b",
    "COSTUMES_DRAGONFLY-A": "Libélula-a",
    "COSTUMES_DRAGONFLY-B": "Libélula-b",
    "COSTUMES_DRESS-A": "Vestido-a",
    "COSTUMES_DRESS-B": "Vestido-b",
    "COSTUMES_DRESS-C": "Vestido-c",
    "COSTUMES_DRUM-A": "Tambor-a",
    "COSTUMES_DRUM-B": "Tambor-b",
    "COSTUMES_DRUM-CYMBAL-A": "Prato de bateria-a",
    "COSTUMES_DRUM-CYMBAL-B": "Prato de bateria-b",
    "COSTUMES_DRUM-HIGHHAT-A": "Chimbal de bateria-a",
    "COSTUMES_DRUM-HIGHHAT-B": "Chimbal de bateria-b",
    "COSTUMES_DRUM-KIT": "Kit de bateria",
    "COSTUMES_DRUM-KIT-B": "Kit de bateria-b",
    "COSTUMES_DRUM-SNARE-A": "Caixa de bateria-a",
    "COSTUMES_DRUM-SNARE-B": "Caixa de bateria-b",
    "COSTUMES_DRUMS_CONGA-A": "Congas de bateria-a",
    "COSTUMES_DRUMS_CONGA-B": "Congas de bateria-b",
    "COSTUMES_DUCK": "Pato",
    "COSTUMES_EARTH": "Terra",
    "COSTUMES_EASEL-A": "Cavalete-a",
    "COSTUMES_EASEL-B": "Cavalete-b",
    "COSTUMES_EASEL-C": "Cavalete-c",
    "COSTUMES_EGG-A": "Ovo-a",
    "COSTUMES_EGG-B": "Ovo-b",
    "COSTUMES_EGG-C": "Ovo-c",
    "COSTUMES_EGG-D": "Ovo-d",
    "COSTUMES_EGG-E": "Ovo-e",
    "COSTUMES_EGG-F": "Ovo-f",
    "COSTUMES_ELEPHANT-A": "Elefante-a",
    "COSTUMES_ELEPHANT-B": "Elefante-b",
    "COSTUMES_ELF-A": "Elfo-a",
    "COSTUMES_ELF-B": "Elfo-b",
    "COSTUMES_ELF-C": "Elfo-c",
    "COSTUMES_ELF-D": "Elfo-d",
    "COSTUMES_ELF-E": "Elfo-e",
    "COSTUMES_FAIRY-A": "Fada-a",
    "COSTUMES_FAIRY-B": "Fada-b",
    "COSTUMES_FAIRY-C": "Fada-c",
    "COSTUMES_FAIRY-D": "Fada-d",
    "COSTUMES_FAIRY-E": "Fada-e",
    "COSTUMES_FISH-A": "Peixe-a",
    "COSTUMES_FISH-B": "Peixe-b",
    "COSTUMES_FISH-C": "Peixe-c",
    "COSTUMES_FISH-D": "Peixe-d",
    "COSTUMES_FISHBOWL-A": "Aquário-a",
    "COSTUMES_FISHBOWL-B": "Aquário-b",
    "COSTUMES_FOOD_TRUCK-A": "Caminhão de comida-a",
    "COSTUMES_FOOD_TRUCK-B": "Caminhão de comida-b",
    "COSTUMES_FOOD_TRUCK-C": "Caminhão de comida-c",
    "COSTUMES_FOOTBALL_RUNNING": "Futebol Correndo",
    "COSTUMES_FOOTBALL_STANDING": "Futebol Parado",
    "COSTUMES_FORTUNE_COOKIE": "Biscoito da sorte",
    "COSTUMES_FOX-A": "Raposa-a",
    "COSTUMES_FOX-B": "Raposa-b",
    "COSTUMES_FOX-C": "Raposa-c",
    "COSTUMES_FRANK-A": "Frank-a",
    "COSTUMES_FRANK-B": "Frank-b",
    "COSTUMES_FRANK-C": "Frank-c",
    "COSTUMES_FRANK-D": "Frank-d",
    "COSTUMES_FROG": "Sapo",
    "COSTUMES_FROG_2-A": "Sapo 2-a",
    "COSTUMES_FROG_2-B": "Sapo 2-b",
    "COSTUMES_FROG_2-C": "Sapo 2-c",
    "COSTUMES_FRUIT_PLATTER": "Prato de frutas",
    "COSTUMES_FRUITSALAD": "Salada de frutas",
    "COSTUMES_GHOST-A": "Fantasma-a",
    "COSTUMES_GHOST-B": "Fantasma-b",
    "COSTUMES_GHOST-C": "Fantasma-c",
    "COSTUMES_GHOST-D": "Fantasma-d",
    "COSTUMES_GIFT-A": "Presente-a",
    "COSTUMES_GIFT-B": "Presente-b",
    "COSTUMES_GIRAFFE-A": "Girafa-a",
    "COSTUMES_GIRAFFE-B": "Girafa-b",
    "COSTUMES_GIRAFFE-C": "Girafa-c",
    "COSTUMES_GLASS_WATER-A": "Água de vidro-a",
    "COSTUMES_GLASS_WATER-B": "Água de vidro-b",
    "COSTUMES_GLASSES-A": "Óculos-a",
    "COSTUMES_GLASSES-B": "Óculos-b",
    "COSTUMES_GLASSES-C": "Óculos-c",
    "COSTUMES_GLASSES-E": "Óculos-e",
    "COSTUMES_GOALIE-A": "Goleiro-a",
    "COSTUMES_GOALIE-B": "Goleiro-b",
    "COSTUMES_GOALIE-C": "Goleiro-c",
    "COSTUMES_GOALIE-D": "Goleiro-d",
    "COSTUMES_GOALIE-E": "Goleiro-e",
    "COSTUMES_GOBLIN-A": "Goblin-a",
    "COSTUMES_GOBLIN-B": "Goblin-b",
    "COSTUMES_GOBLIN-C": "Goblin-c",
    "COSTUMES_GOBLIN-D": "Goblin-d",
    "COSTUMES_GRASSHOPPER-A": "Gafanhoto-a",
    "COSTUMES_GRASSHOPPER-B": "Gafanhoto-b",
    "COSTUMES_GRASSHOPPER-C": "Gafanhoto-c",
    "COSTUMES_GRASSHOPPER-D": "Gafanhoto-d",
    "COSTUMES_GRASSHOPPER-E": "Gafanhoto-e",
    "COSTUMES_GRASSHOPPER-F": "Gafanhoto-f",
    "COSTUMES_GREEN_FLAG": "Bandeira Verde",
    "COSTUMES_GRIFFIN-A": "Grifo-a",
    "COSTUMES_GRIFFIN-B": "Grifo-b",
    "COSTUMES_GRIFFIN-C": "Grifo-c",
    "COSTUMES_GRIFFIN-D": "Grifo-d",
    "COSTUMES_GUITAR-A": "Guitarra-a",
    "COSTUMES_GUITAR-B": "Guitarra-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "Guitarra Elétrica 1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "Guitarra Elétrica 1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "Guitarra Elétrica 2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "Guitarra Elétrica 2-b",
    "COSTUMES_HANNAH-A": "Hannah-a",
    "COSTUMES_HANNAH-B": "Hannah-b",
    "COSTUMES_HANNAH-C": "Hannah-c",
    "COSTUMES_HARE-A": "Lebre-a",
    "COSTUMES_HARE-B": "Lebre-b",
    "COSTUMES_HARE-C": "Lebre-c",
    "COSTUMES_HARPER-A": "Harper-a",
    "COSTUMES_HARPER-B": "Harper-b",
    "COSTUMES_HARPER-C": "Harper-c",
    "COSTUMES_HAT-A": "Chapéu-a",
    "COSTUMES_HAT-B": "Chapéu-b",
    "COSTUMES_HAT-C": "Chapéu-c",
    "COSTUMES_HAT-D": "Chapéu-d",
    "COSTUMES_HATCHLING-A": "Filhote-a",
    "COSTUMES_HATCHLING-B": "Filhote-b",
    "COSTUMES_HATCHLING-C": "Filhote-c",
    "COSTUMES_HEART_CODE": "Coração Código",
    "COSTUMES_HEART_FACE": "Coração Rosto",
    "COSTUMES_HEART_LOVE": "Coração Amor",
    "COSTUMES_HEART_PURPLE": "Coração Roxo",
    "COSTUMES_HEART_RED": "Coração Vermelho",
    "COSTUMES_HEART_SMILE": "Coração Sorriso",
    "COSTUMES_HEART_SWEET": "Coração Doce",
    "COSTUMES_HEDGEHOG-A": "Ouriço-a",
    "COSTUMES_HEDGEHOG-B": "Ouriço-b",
    "COSTUMES_HEDGEHOG-C": "Ouriço-c",
    "COSTUMES_HEDGEHOG-D": "Ouriço-d",
    "COSTUMES_HEDGEHOG-E": "Ouriço-e",
    "COSTUMES_HEN-A": "Galinha-a",
    "COSTUMES_HEN-B": "Galinha-b",
    "COSTUMES_HEN-C": "Galinha-c",
    "COSTUMES_HEN-D": "Galinha-d",
    "COSTUMES_HIPPO1-A": "Hipopótamo 1-a",
    "COSTUMES_HIPPO1-B": "Hipopótamo 1-b",
    "COSTUMES_HOME_BUTTON": "Botão Início",
    "COSTUMES_HORSE-A": "Cavalo-a",
    "COSTUMES_HORSE-B": "Cavalo-b",
    "COSTUMES_JAIME_WALKING-A": "Jaime Andando-a",
    "COSTUMES_JAIME_WALKING-B": "Jaime Andando-b",
    "COSTUMES_JAIME_WALKING-C": "Jaime Andando-c",
    "COSTUMES_JAIME_WALKING-D": "Jaime Andando-d",
    "COSTUMES_JAIME_WALKING-E": "Jaime Andando-e",
    "COSTUMES_JAIME-A": "Jaime-a",
    "COSTUMES_JAIME-B": "Jaime-b",
    "COSTUMES_JAMAL-A": "Jamal-a",
    "COSTUMES_JAMAL-B": "Jamal-b",
    "COSTUMES_JAMAL-C": "Jamal-c",
    "COSTUMES_JAMAL-D": "Jamal-d",
    "COSTUMES_JAR-A": "Jarra-a",
    "COSTUMES_JAR-B": "Jarra-b",
    "COSTUMES_JELLYFISH-A": "Água-viva-a",
    "COSTUMES_JELLYFISH-B": "Água-viva-b",
    "COSTUMES_JELLYFISH-C": "Água-viva-c",
    "COSTUMES_JELLYFISH-D": "Água-viva-d",
    "COSTUMES_JO_POP_DOWN": "Jo Pop Down",
    "COSTUMES_JO_POP_FRONT": "Jo Pop Front",
    "COSTUMES_JO_POP_L_ARM": "Jo Pop L Arm",
    "COSTUMES_JO_POP_LEFT": "Jo Pop Left",
    "COSTUMES_JO_POP_R_ARM": "Jo Pop R Arm",
    "COSTUMES_JO_POP_RIGHT": "Jo Pop Right",
    "COSTUMES_JO_POP_STAND": "Jo Pop Stand",
    "COSTUMES_JO_STANCE": "Jo Stance",
    "COSTUMES_JO_TOP_L_CROSS": "Jo Top L Cross",
    "COSTUMES_JO_TOP_L_LEG": "Jo Top L Leg",
    "COSTUMES_JO_TOP_R_CROSS": "Jo Top R Cross",
    "COSTUMES_JO_TOP_R_LEG": "Jo Top R Leg",
    "COSTUMES_JO_TOP_STAND": "Jo Top Stand",
    "COSTUMES_JORDYN-A": "Jordyn-a",
    "COSTUMES_JORDYN-B": "Jordyn-b",
    "COSTUMES_JORDYN-C": "Jordyn-c",
    "COSTUMES_JORDYN-D": "Jordyn-d",
    "COSTUMES_KAI-A": "Kai-a",
    "COSTUMES_KAI-B": "Kai-b",
    "COSTUMES_KEY": "Chave",
    "COSTUMES_KEYBOARD-A": "Teclado-a",
    "COSTUMES_KEYBOARD-B": "Teclado-b",
    "COSTUMES_KIRAN-A": "Kiran-a",
    "COSTUMES_KIRAN-B": "Kiran-b",
    "COSTUMES_KIRAN-C": "Kiran-c",
    "COSTUMES_KIRAN-D": "Kiran-d",
    "COSTUMES_KIRAN-E": "Kiran-e",
    "COSTUMES_KIRAN-F": "Kiran-f",
    "COSTUMES_KNIGHT": "Cavaleiro",
    "COSTUMES_LADYBUG2": "Joaninha 1",
    "COSTUMES_LADYBUG2-A": "Joaninha 2-a",
    "COSTUMES_LADYBUG2-B": "Joaninha 2-b",
    "COSTUMES_LAPTOP": "Laptop",
    "COSTUMES_LB_POP_DOWN": "Lb Pop Down",
    "COSTUMES_LB_POP_FRONT": "Lb Pop Front",
    "COSTUMES_LB_POP_L_ARM": "Lb Pop L Arm",
    "COSTUMES_LB_POP_LEFT": "Lb Pop Left",
    "COSTUMES_LB_POP_R_ARM": "Lb Pop R Arm",
    "COSTUMES_LB_POP_RIGHT": "Lb Pop Right",
    "COSTUMES_LB_POP_STAND": "Lb Pop Stand",
    "COSTUMES_LB_STANCE": "Lb Stance",
    "COSTUMES_LB_TOP_L_CROSS": "Lb Top L Cross",
    "COSTUMES_LB_TOP_L_LEG": "Lb Top L Leg",
    "COSTUMES_LB_TOP_R_CROSS": "Lb Top R Cross",
    "COSTUMES_LB_TOP_R_LEG": "Lb Top R Leg",
    "COSTUMES_LB_TOP_STAND": "Lb Top Stand",
    "COSTUMES_LIGHTNING": "Relâmpago",
    "COSTUMES_LINE": "Linha",
    "COSTUMES_LION-A": "Leão-a",
    "COSTUMES_LION-B": "Leão-b",
    "COSTUMES_LION-C": "Leão-c",
    "COSTUMES_LLAMA": "Lhama",
    "COSTUMES_LLAMA-B": "Lhama-b",
    "COSTUMES_LLAMA-C": "Lhama-c",
    "COSTUMES_MAGICWAND": "Varinha Mágica",
    "COSTUMES_MAX-A": "Max-a",
    "COSTUMES_MAX-B": "Max-b",
    "COSTUMES_MAX-C": "Max-c",
    "COSTUMES_MAX-D": "Max-d",
    "COSTUMES_MERMAID-A": "Sereia-a",
    "COSTUMES_MERMAID-B": "Sereia-b",
    "COSTUMES_MERMAID-C": "Sereia-c",
    "COSTUMES_MERMAID-D": "Sereia-d",
    "COSTUMES_MICROPHONE-A": "Microfone-a",
    "COSTUMES_MICROPHONE-B": "Microfone-b",
    "COSTUMES_MILK-A": "Leite-a",
    "COSTUMES_MILK-B": "Leite-b",
    "COSTUMES_MILK-C": "Leite-c",
    "COSTUMES_MILK-D": "Leite-d",
    "COSTUMES_MILK-E": "Leite-e",
    "COSTUMES_MONET-A": "Monet-a",
    "COSTUMES_MONET-B": "Monet-b",
    "COSTUMES_MONET-C": "Monet-c",
    "COSTUMES_MONET-D": "Monet-d",
    "COSTUMES_MONET-E": "Monet-e",
    "COSTUMES_MONKEY-A": "Macaco-a",
    "COSTUMES_MONKEY-B": "Macaco-b",
    "COSTUMES_MONKEY-C": "Macaco-c",
    "COSTUMES_MOTORCYCLE-A": "Motocicleta-a",
    "COSTUMES_MOTORCYCLE-B": "Motocicleta-b",
    "COSTUMES_MOTORCYCLE-C": "Motocicleta-c",
    "COSTUMES_MOTORCYCLE-D": "Motocicleta-d",
    "COSTUMES_MOUSE1-A": "Mouse 1-a",
    "COSTUMES_MOUSE1-B": "Mouse 1-b",
    "COSTUMES_MUFFIN-A": "Muffin-a",
    "COSTUMES_MUFFIN-B": "Muffin-b",
    "COSTUMES_NEIGH_PONY": "Neigh Pônei",
    "COSTUMES_OCTOPUS-A": "Polvo-a",
    "COSTUMES_OCTOPUS-B": "Polvo-b",
    "COSTUMES_OCTOPUS-C": "Polvo-c",
    "COSTUMES_OCTOPUS-D": "Polvo-d",
    "COSTUMES_OCTOPUS-E": "Polvo-e",
    "COSTUMES_ORANGE": "Laranja",
    "COSTUMES_ORANGE2-A": "Laranja 2-a",
    "COSTUMES_ORANGE2-B": "Laranja 2-b",
    "COSTUMES_OUTFIELDER-A": "Jogador no Campo-a",
    "COSTUMES_OUTFIELDER-B": "Jogador no Campo-b",
    "COSTUMES_OUTFIELDER-C": "Jogador no Campo-c",
    "COSTUMES_OUTFIELDER-D": "Jogador no Campo-d",
    "COSTUMES_OWL-A": "Coruja-a",
    "COSTUMES_OWL-B": "Coruja-b",
    "COSTUMES_OWL-C": "Coruja-c",
    "COSTUMES_PADDLE": "Remo",
    "COSTUMES_PANTHER-A": "Pantera-a",
    "COSTUMES_PANTHER-B": "Pantera-b",
    "COSTUMES_PANTHER-C": "Pantera-c",
    "COSTUMES_PANTS-A": "Calça-a",
    "COSTUMES_PANTS-B": "Calça-b",
    "COSTUMES_PARROT-A": "Papagaio-a",
    "COSTUMES_PARROT-B": "Papagaio-b",
    "COSTUMES_PARTY_HAT-A": "Chapéu de Festa-a",
    "COSTUMES_PARTY_HAT-B": "Chapéu de Festa-b",
    "COSTUMES_PARTY_HAT-E": "Chapéu de Festa-c",
    "COSTUMES_PENCIL-A": "Lápis-a",
    "COSTUMES_PENCIL-B": "Lápis-b",
    "COSTUMES_PENGUIN-A": "Pinguim-a",
    "COSTUMES_PENGUIN-B": "Pinguim-b",
    "COSTUMES_PENGUIN-C": "Pinguim-c",
    "COSTUMES_PENGUIN2-A": "Pinguim 2-a",
    "COSTUMES_PENGUIN2-B": "Pinguim 2-b",
    "COSTUMES_PENGUIN2-C": "Pinguim 2-c",
    "COSTUMES_PENGUIN2-D": "Pinguim 2-d",
    "COSTUMES_PITCHER-A": "Arremessador-a",
    "COSTUMES_PITCHER-B": "Arremessador-b",
    "COSTUMES_PITCHER-C": "Arremessador-c",
    "COSTUMES_PITCHER-D": "Arremessador-d",
    "COSTUMES_PLANET2": "Planeta 2",
    "COSTUMES_POLAR_BEAR-A": "Urso Polar-a",
    "COSTUMES_POLAR_BEAR-B": "Urso Polar-b",
    "COSTUMES_POLAR_BEAR-C": "Urso Polar-c",
    "COSTUMES_POTION-A": "Poção-a",
    "COSTUMES_POTION-B": "Poção-b",
    "COSTUMES_POTION-C": "Poção-c",
    "COSTUMES_PRINCE": "Príncipe",
    "COSTUMES_PRINCESS-A": "Princesa-a",
    "COSTUMES_PRINCESS-B": "Princesa-b",
    "COSTUMES_PRINCESS-C": "Princesa-c",
    "COSTUMES_PRINCESS-D": "Princesa-d",
    "COSTUMES_PRINCESS-E": "Princesa-e",
    "COSTUMES_PUFFERFISH-A": "Bola de Ouriço-a",
    "COSTUMES_PUFFERFISH-B": "Bola de Ouriço-b",
    "COSTUMES_PUFFERFISH-C": "Bola de Ouriço-c",
    "COSTUMES_PUFFERFISH-D": "Bola de Ouriço-d",
    "COSTUMES_PUPPY_BACK": "Filhote nas Costas",
    "COSTUMES_PUPPY_RIGHT": "Filhote à Direita",
    "COSTUMES_PUPPY_SIDE": "Filhote de Lado",
    "COSTUMES_PUPPY_SIT": "Filhote Sentado",
    "COSTUMES_RABBIT-A": "Coelho-a",
    "COSTUMES_RABBIT-B": "Coelho-b",
    "COSTUMES_RABBIT-C": "Coelho-c",
    "COSTUMES_RABBIT-D": "Coelho-d",
    "COSTUMES_RABBIT-E": "Coelho-e",
    "COSTUMES_RADIO-A": "Rádio-a",
    "COSTUMES_RADIO-B": "Rádio-b",
    "COSTUMES_RAINBOW": "Arco-íris",
    "COSTUMES_REFEREE-A": "Árbitro-a",
    "COSTUMES_REFEREE-B": "Árbitro-b",
    "COSTUMES_REFEREE-C": "Árbitro-c",
    "COSTUMES_REFEREE-D": "Árbitro-d",
    "COSTUMES_REINDEER": "Rena",
    "COSTUMES_RETRO_ROBOT_A": "Robô Retrô a",
    "COSTUMES_RETRO_ROBOT_B": "Robô Retrô b",
    "COSTUMES_RETRO_ROBOT_C": "Robô Retrô c",
    "COSTUMES_RIPLEY-A": "Ripley-a",
    "COSTUMES_RIPLEY-B": "Ripley-b",
    "COSTUMES_RIPLEY-C": "Ripley-c",
    "COSTUMES_RIPLEY-D": "Ripley-d",
    "COSTUMES_RIPLEY-E": "Ripley-e",
    "COSTUMES_RIPLEY-F": "Ripley-f",
    "COSTUMES_ROBOT-A": "Robô-a",
    "COSTUMES_ROBOT-B": "Robô-b",
    "COSTUMES_ROBOT-C": "Robô-c",
    "COSTUMES_ROBOT-D": "Robô-d",
    "COSTUMES_ROCKETSHIP-A": "Nave Espacial-a",
    "COSTUMES_ROCKETSHIP-B": "Nave Espacial-b",
    "COSTUMES_ROCKETSHIP-C": "Nave Espacial-c",
    "COSTUMES_ROCKETSHIP-D": "Nave Espacial-d",
    "COSTUMES_ROCKETSHIP-E": "Nave Espacial-e",
    "COSTUMES_ROCKS": "Pedras",
    "COSTUMES_ROOSTER-A": "Galo-a",
    "COSTUMES_ROOSTER-B": "Galo-b",
    "COSTUMES_ROOSTER-C": "Galo-c",
    "COSTUMES_RUBY-A": "Ruby-a",
    "COSTUMES_RUBY-B": "Ruby-b",
    "COSTUMES_SAILBOAT": "Barco a Vela",
    "COSTUMES_SAM": "Sam",
    "COSTUMES_SAXOPHONE-A": "Saxofone-a",
    "COSTUMES_SAXOPHONE-B": "Saxofone-b",
    "COSTUMES_SCARF-A": "Cachecol-a",
    "COSTUMES_SCARF-B": "Cachecol-b",
    "COSTUMES_SCARF-C": "Cachecol-c",
    "COSTUMES_SHARK-A": "Tubarão-a",
    "COSTUMES_SHARK-B": "Tubarão-b",
    "COSTUMES_SHARK2-A": "Tubarão2-a",
    "COSTUMES_SHARK2-B": "Tubarão2-b",
    "COSTUMES_SHARK2-C": "Tubarão2-c",
    "COSTUMES_SHIRT-A": "Camisa-a",
    "COSTUMES_SHOES-A": "Sapatos-a",
    "COSTUMES_SHOES-B": "Sapatos-b",
    "COSTUMES_SHOES-C": "Sapatos-c",
    "COSTUMES_SHOES-D": "Sapatos-d",
    "COSTUMES_SHORTS-A": "Shorts-a",
    "COSTUMES_SHORTS-B": "Shorts-b",
    "COSTUMES_SHORTS-C": "Shorts-c",
    "COSTUMES_SINGER1": "Cantora1",
    "COSTUMES_SKELETON-A": "Esqueleto-a",
    "COSTUMES_SKELETON-B": "Esqueleto-b",
    "COSTUMES_SKELETON-D": "Esqueleto-d",
    "COSTUMES_SKELETON-E": "Esqueleto-e",
    "COSTUMES_SNAKE-A": "Cobra-a",
    "COSTUMES_SNAKE-B": "Cobra-b",
    "COSTUMES_SNAKE-C": "Cobra-c",
    "COSTUMES_SNOWFLAKE": "Flocos de Neve",
    "COSTUMES_SNOWMAN": "Boneco de Neve",
    "COSTUMES_SOCCER_BALL": "Bola de Futebol",
    "COSTUMES_SPEAKER": "Alto-falante",
    "COSTUMES_SQUIRREL": "Esquilo",
    "COSTUMES_STAR": "Estrela",
    "COSTUMES_STARFISH-A": "Estrela do Mar-a",
    "COSTUMES_STARFISH-B_": "Estrela do Mar-b",
    "COSTUMES_STOP": "Pare",
    "COSTUMES_STRAWBERRY-A": "Morango-a",
    "COSTUMES_STRAWBERRY-B": "Morango-b",
    "COSTUMES_STRAWBERRY-C": "Morango-c",
    "COSTUMES_STRAWBERRY-D": "Morango-d",
    "COSTUMES_STRAWBERRY-E": "Morango-e",
    "COSTUMES_SUN": "Sol",
    "COSTUMES_SUNGLASSES-A": "Óculos de Sol-a",
    "COSTUMES_SUNGLASSES-B": "Óculos de Sol-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Tacos",
    "COSTUMES_TACO-WIZARD": "Tacos Mágicos",
    "COSTUMES_TAKEOUT-A": "Levar para Casa-a",
    "COSTUMES_TAKEOUT-B": "Levar para Casa-b",
    "COSTUMES_TAKEOUT-C": "Levar para Casa-c",
    "COSTUMES_TAKEOUT-D": "Levar para Casa-d",
    "COSTUMES_TAKEOUT-E": "Levar para Casa-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 Pop Down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 Pop Front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 Pop L Arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 Pop Left",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 Pop R Arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 Pop Right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 Pop Stand",
    "COSTUMES_TEN80_STANCE": "Postura Ten80",
    "COSTUMES_TEN80_TOP_FREEZE": "Congelar Superior Ten80",
    "COSTUMES_TEN80_TOP_L_STEP": "Passo Esquerdo Superior Ten80",
    "COSTUMES_TEN80_TOP_R_CROSS": "Cruz Direita Superior Ten80",
    "COSTUMES_TEN80_TOP_R_STEP": "Passo Direito Superior Ten80",
    "COSTUMES_TEN80_TOP_STAND": "Postura Superior Ten80",
    "COSTUMES_TENNISBALL": "Bola de Tênis",
    "COSTUMES_TOUCAN-A": "Tucano-a",
    "COSTUMES_TOUCAN-B": "Tucano-b",
    "COSTUMES_TOUCAN-C": "Tucano-c",
    "COSTUMES_TRAMPOLINE": "Trampolim",
    "COSTUMES_TREE1": "Árvore1",
    "COSTUMES_TREES-A": "Árvores-a",
    "COSTUMES_TREES-B": "Árvores-b",
    "COSTUMES_TRUCK-A": "Caminhão-a",
    "COSTUMES_TRUCK-B": "Caminhão-b",
    "COSTUMES_TRUCK-C": "Caminhão-c",
    "COSTUMES_TRUMPET-A": "Trompete-a",
    "COSTUMES_TRUMPET-B": "Trompete-b",
    "COSTUMES_UNICORN": "Unicórnio",
    "COSTUMES_UNICORN_2": "Unicórnio 2",
    "COSTUMES_UNICORN_RUNNING-A": "Unicórnio Correndo-a",
    "COSTUMES_UNICORN_RUNNING-B": "Unicórnio Correndo-b",
    "COSTUMES_UNICORN_RUNNING-C": "Unicórnio Correndo-c",
    "COSTUMES_UNICORN_RUNNING-D": "Unicórnio Correndo-d",
    "COSTUMES_UNICORN_RUNNING-E": "Unicórnio Correndo-e",
    "COSTUMES_UNICORN_RUNNING-F": "Unicórnio Correndo-f",
    "COSTUMES_WAND": "Varinha",
    "COSTUMES_WANDA": "Wanda",
    "COSTUMES_WATERMELON-A": "Melancia-a",
    "COSTUMES_WATERMELON-B": "Melancia-b",
    "COSTUMES_WATERMELON-C": "Melancia-c",
    "COSTUMES_WINTER_HAT": "Chapéu de Inverno",
    "COSTUMES_WITCH": "Bruxa",
    "COSTUMES_WITCH-A": "Bruxa-a",
    "COSTUMES_WITCH-B": "Bruxa-b",
    "COSTUMES_WITCH-C": "Bruxa-c",
    "COSTUMES_WITCH-D": "Bruxa-d",
    "COSTUMES_WIZARD_HAT": "Chapéu de Bruxo",
    "COSTUMES_WIZARD-A": "Mago-a",
    "COSTUMES_WIZARD-B": "Mago-b",
    "COSTUMES_WIZARD-C": "Mago-c",
    "COSTUMES_WIZARD-TOAD-A": "Sapo Mago-a",
    "COSTUMES_WIZARD-TOAD-B": "Sapo Mago-b",
    "COSTUMES_ZEBRA-A": "Zebra-a",
    "COSTUMES_ZEBRA-B": "Zebra-b",
    "COSTUMES_BLOCK-A": "Bloco-a",
    "COSTUMES_BLOCK-B": "Bloco-b",
    "COSTUMES_BLOCK-C": "Bloco-c",
    "COSTUMES_BLOCK-D": "Bloco-d",
    "COSTUMES_BLOCK-E": "Bloco-e",
    "COSTUMES_BLOCK-F": "Bloco-f",
    "COSTUMES_BLOCK-G": "Bloco-g",
    "COSTUMES_BLOCK-H": "Bloco-h",
    "COSTUMES_BLOCK-I": "Bloco-i",
    "COSTUMES_BLOCK-J": "Bloco-j",
    "COSTUMES_BLOCK-K": "Bloco-k",
    "COSTUMES_BLOCK-L": "Bloco-l",
    "COSTUMES_BLOCK-M": "Bloco-m",
    "COSTUMES_BLOCK-N": "Bloco-n",
    "COSTUMES_BLOCK-O": "Bloco-o",
    "COSTUMES_BLOCK-P": "Bloco-p",
    "COSTUMES_BLOCK-Q": "Bloco-q",
    "COSTUMES_BLOCK-R": "Bloco-r",
    "COSTUMES_BLOCK-S": "Bloco-s",
    "COSTUMES_BLOCK-T": "Bloco-t",
    "COSTUMES_BLOCK-U": "Bloco-u",
    "COSTUMES_BLOCK-V": "Bloco-v",
    "COSTUMES_BLOCK-W": "Bloco-w",
    "COSTUMES_BLOCK-X": "Bloco-x",
    "COSTUMES_BLOCK-Y": "Bloco-y",
    "COSTUMES_BLOCK-Z": "Bloco-z",
    "COSTUMES_GLOW-0": "Brilho-0",
    "COSTUMES_GLOW-1": "Brilho-1",
    "COSTUMES_GLOW-2": "Brilho-2",
    "COSTUMES_GLOW-3": "Brilho-3",
    "COSTUMES_GLOW-4": "Brilho-4",
    "COSTUMES_GLOW-5": "Brilho-5",
    "COSTUMES_GLOW-6": "Brilho-6",
    "COSTUMES_GLOW-7": "Brilho-7",
    "COSTUMES_GLOW-8": "Brilho-8",
    "COSTUMES_GLOW-9": "Brilho-9",
    "COSTUMES_GLOW-A": "Brilho-A",
    "COSTUMES_GLOW-B": "Brilho-B",
    "COSTUMES_GLOW-C": "Brilho-C",
    "COSTUMES_GLOW-D": "Brilho-D",
    "COSTUMES_GLOW-E": "Brilho-E",
    "COSTUMES_GLOW-F": "Brilho-F",
    "COSTUMES_GLOW-G": "Brilho-G",
    "COSTUMES_GLOW-H": "Brilho-H",
    "COSTUMES_GLOW-I": "Brilho-I",
    "COSTUMES_GLOW-J": "Brilho-J",
    "COSTUMES_GLOW-K": "Brilho-K",
    "COSTUMES_GLOW-L": "Brilho-L",
    "COSTUMES_GLOW-M": "Brilho-M",
    "COSTUMES_GLOW-N": "Brilho-N",
    "COSTUMES_GLOW-O": "Brilho-O",
    "COSTUMES_GLOW-P": "Brilho-P",
    "COSTUMES_GLOW-Q": "Brilho-Q",
    "COSTUMES_GLOW-R": "Brilho-R",
    "COSTUMES_GLOW-S": "Brilho-S",
    "COSTUMES_GLOW-T": "Brilho-T",
    "COSTUMES_GLOW-U": "Brilho-U",
    "COSTUMES_GLOW-V": "Brilho-V",
    "COSTUMES_GLOW-W": "Brilho-W",
    "COSTUMES_GLOW-X": "Brilho-X",
    "COSTUMES_GLOW-Y": "Brilho-Y",
    "COSTUMES_GLOW-Z": "Brilho-Z",
    "COSTUMES_STORY-A-1": "História-A-1",
    "COSTUMES_STORY-A-2": "História-A-2",
    "COSTUMES_STORY-A-3": "História-A-3",
    "COSTUMES_STORY-B-1": "História-B-1",
    "COSTUMES_STORY-B-2": "História-B-2",
    "COSTUMES_STORY-B-3": "História-B-3",
    "COSTUMES_STORY-C-1": "História-C-1",
    "COSTUMES_STORY-C-2": "História-C-2",
    "COSTUMES_STORY-C-3": "História-C-3",
    "COSTUMES_STORY-D-1": "História-D-1",
    "COSTUMES_STORY-D-2": "História-D-2",
    "COSTUMES_STORY-D-3": "História-D-3",
    "COSTUMES_STORY-E-1": "História-E-1",
    "COSTUMES_STORY-E-2": "História-E-2",
    "COSTUMES_STORY-E-3": "História-E-3",
    "COSTUMES_STORY-F-1": "História-F-1",
    "COSTUMES_STORY-F-2": "História-F-2",
    "COSTUMES_STORY-F-3": "História-F-3",
    "COSTUMES_STORY-G-1": "História-G-1",
    "COSTUMES_STORY-G-2": "História-G-2",
    "COSTUMES_STORY-G-3": "História-G-3",
    "COSTUMES_STORY-H-1": "História-H-1",
    "COSTUMES_STORY-H-2": "História-H-2",
    "COSTUMES_STORY-H-3": "História-H-3",
    "COSTUMES_STORY-I-1": "História-I-1",
    "COSTUMES_STORY-I-2": "História-I-2",
    "COSTUMES_STORY-I-3": "História-I-3",
    "COSTUMES_STORY-J-1": "História-J-1",
    "COSTUMES_STORY-J-2": "História-J-2",
    "COSTUMES_STORY-J-3": "História-J-3",
    "COSTUMES_STORY-K-1": "História-K-1",
    "COSTUMES_STORY-K-2": "História-K-2",
    "COSTUMES_STORY-K-3": "História-K-3",
    "COSTUMES_STORY-L-1": "História-L-1",
    "COSTUMES_STORY-L-2": "História-L-2",
    "COSTUMES_STORY-L-3": "História-L-3",
    "COSTUMES_STORY-M-1": "História-M-1",
    "COSTUMES_STORY-M-2": "História-M-2",
    "COSTUMES_STORY-M-3": "História-M-3",
    "COSTUMES_STORY-N-1": "História-N-1",
    "COSTUMES_STORY-N-2": "História-N-2",
    "COSTUMES_STORY-N-3": "História-N-3",
    "COSTUMES_STORY-O-1": "História-O-1",
    "COSTUMES_STORY-O-2": "História-O-2",
    "COSTUMES_STORY-O-3": "História-O-3",
    "COSTUMES_STORY-P-1": "História-P-1",
    "COSTUMES_STORY-P-2": "História-P-2",
    "COSTUMES_STORY-P-3": "História-P-3",
    "COSTUMES_STORY-Q-1": "História-Q-1",
    "COSTUMES_STORY-Q-2": "História-Q-2",
    "COSTUMES_STORY-Q-3": "História-Q-3",
    "COSTUMES_STORY-R-1": "História-R-1",
    "COSTUMES_STORY-R-2": "História-R-2",
    "COSTUMES_STORY-R-3": "História-R-3",
    "COSTUMES_STORY-S-1": "História-S-1",
    "COSTUMES_STORY-S-2": "História-S-2",
    "COSTUMES_STORY-S-3": "História-S-3",
    "COSTUMES_STORY-T-1": "História-T-1",
    "COSTUMES_STORY-T-2": "História-T-2",
    "COSTUMES_STORY-T-3": "História-T-3",
    "COSTUMES_STORY-U-1": "História-U-1",
    "COSTUMES_STORY-U-2": "História-U-2",
    "COSTUMES_STORY-U-3": "História-U-3",
    "COSTUMES_STORY-V-1": "História-V-1",
    "COSTUMES_STORY-V-2": "História-V-2",
    "COSTUMES_STORY-V-3": "História-V-3",
    "COSTUMES_STORY-W-1": "História-W-1",
    "COSTUMES_STORY-W-2": "História-W-2",
    "COSTUMES_STORY-W-3": "História-W-3",
    "COSTUMES_STORY-X-1": "História-X-1",
    "COSTUMES_STORY-X-2": "História-X-2",
    "COSTUMES_STORY-X-3": "História-X-3",
    "COSTUMES_STORY-Y-1": "História-Y-1",
    "COSTUMES_STORY-Y-2": "História-Y-2",
    "COSTUMES_STORY-Y-3": "História-Y-3",
    "COSTUMES_STORY-Z-1": "História-Z-1",
    "COSTUMES_STORY-Z-2": "História-Z-2",
    "COSTUMES_STORY-Z-3": "História-Z-3",
    "SOUNDS_A_BASS": "Um Baixo",
    "SOUNDS_A_ELEC_BASS": "Um Baixo Elétrico",
    "SOUNDS_A_ELEC_GUITAR": "Uma Guitarra Elétrica",
    "SOUNDS_A_ELEC_PIANO": "Um Piano Elétrico",
    "SOUNDS_A_GUITAR": "Uma Guitarra",
    "SOUNDS_A_MINOR_UKULELE": "Um Piano",
    "SOUNDS_A_PIANO": "Um Saxofone",
    "SOUNDS_A_SAX": "Um Trombone",
    "SOUNDS_A_TROMBONE": "Uma Trombeta",
    "SOUNDS_A_TRUMPET": "Cordas Afro",
    "SOUNDS_AFRO_STRING": "Alerta",
    "SOUNDS_ALERT": "Ranger Alienígena 1",
    "SOUNDS_ALIEN_CREAK1": "Ranger Alienígena 2",
    "SOUNDS_ALIEN_CREAK2": "Baixo B",
    "SOUNDS_B_BASS": "Baixo Elétrico B",
    "SOUNDS_B_ELEC_BASS": "Guitarra Elétrica B",
    "SOUNDS_B_ELEC_GUITAR": "Piano Elétrico B",
    "SOUNDS_B_ELEC_PIANO": "Guitarra B",
    "SOUNDS_B_GUITAR": "Piano B",
    "SOUNDS_B_PIANO": "Saxofone B",
    "SOUNDS_B_SAX": "Trombone B",
    "SOUNDS_B_TROMBONE": "Baa",
    "SOUNDS_B_TRUMPET": "Latido",
    "SOUNDS_BAA": "Bola de Basquete Quicando",
    "SOUNDS_BARK": "Ritmo de Baixo",
    "SOUNDS_BASKETBALL_BOUNCE": "Beat Box 1",
    "SOUNDS_BASS_BEATBOX": "Beat Box 2",
    "SOUNDS_BEAT_BOX1": "Címbalo de Sino",
    "SOUNDS_BEAT_BOX2": "Sino Tocando",
    "SOUNDS_BELL_CYMBAL": "Boing Grande",
    "SOUNDS_BELL_TOLL": "Pássaro",
    "SOUNDS_BIG_BOING": "Aniversário",
    "SOUNDS_BIRD": "Mordida",
    "SOUNDS_BIRTHDAY": "Boing",
    "SOUNDS_BITE": "Bonk",
    "SOUNDS_BOING": "Nuvem de Explosão",
    "SOUNDS_BONK": "Boop Bing Bop",
    "SOUNDS_BOOM_CLOUD": "Bossa Nova",
    "SOUNDS_BOOP_BING_BOP": "Strike de Boliche",
    "SOUNDS_BOSSA_NOVA": "Bolhas",
    "SOUNDS_BOWLING_STRIKE": "Zumbido",
    "SOUNDS_BUBBLES": "Baixo C",
    "SOUNDS_BUZZ_WHIR": "Baixo Elétrico C",
    "SOUNDS_C_BASS": "Guitarra Elétrica C",
    "SOUNDS_C_ELEC_BASS": "Piano Elétrico C",
    "SOUNDS_C_ELEC_GUITAR": "Guitarra C",
    "SOUNDS_C_ELEC_PIANO": "Ukulele Maior C",
    "SOUNDS_C_GUITAR": "Piano C",
    "SOUNDS_C_MAJOR_UKULELE": "Saxofone C",
    "SOUNDS_C_PIANO": "Trombone C",
    "SOUNDS_C_SAX": "Baixo C2",
    "SOUNDS_C_TROMBONE": "Baixo Elétrico C2",
    "SOUNDS_C_TRUMPET": "Guitarra Elétrica C2",
    "SOUNDS_C2_BASS": "Piano Elétrico C2",
    "SOUNDS_C2_ELEC_BASS": "Guitarra C2",
    "SOUNDS_C2_ELEC_GUITAR": "Piano C2",
    "SOUNDS_C2_ELEC_PIANO": "Saxofone C2",
    "SOUNDS_C2_GUITAR": "Trombone C2",
    "SOUNDS_C2_PIANO": "Buzina de Carro",
    "SOUNDS_C2_SAX": "Carro Passando",
    "SOUNDS_C2_TROMBONE": "C2 Trombone",
    "SOUNDS_C2_TRUMPET": "C2 Trompete",
    "SOUNDS_CAR_HORN": "Buzina de Carro",
    "SOUNDS_CAR_PASSING": "Carro Passando",
    "SOUNDS_CAR_VROOM": "Ruido de Carro",
    "SOUNDS_CAVE": "Caverna",
    "SOUNDS_CHATTER": "Tagarelice",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "Torcida",
    "SOUNDS_CHILL": "Relaxar",
    "SOUNDS_CHIRP": "Piar",
    "SOUNDS_CHOMP": "Mordida",
    "SOUNDS_CHORD": "Acorde",
    "SOUNDS_CLANG": "Estrondo",
    "SOUNDS_CLAP_BEATBOX": "Batida de Palmas Beatbox",
    "SOUNDS_CLAPPING": "Aplaudir",
    "SOUNDS_CLASSICAL_PIANO": "Piano Clássico",
    "SOUNDS_CLOCK_TICKING": "Relógio Tique-Taque",
    "SOUNDS_CLOWN_HONK": "Nariz de Palhaço",
    "SOUNDS_COIN": "Moeda",
    "SOUNDS_COLLECT": "Coletar",
    "SOUNDS_COMPUTER_BEEP": "Beep do Computador 1",
    "SOUNDS_COMPUTER_BEEP2": "Beep do Computador 2",
    "SOUNDS_CONNECT": "Conectar",
    "SOUNDS_COUGH1": "Tosse 1",
    "SOUNDS_COUGH2": "Tosse 2",
    "SOUNDS_CRANK": "Manivela",
    "SOUNDS_CRASH_BEATBOX": "Batida de Palmas Beatbox",
    "SOUNDS_CRASH_CYMBAL": "Prato Crash",
    "SOUNDS_CRAZY_LAUGH": "Risada Louca",
    "SOUNDS_CRICKET": "Grilo",
    "SOUNDS_CRICKETS": "Grilos",
    "SOUNDS_CROAK": "Coaxar",
    "SOUNDS_CROWD_GASP": "Gasp do Público",
    "SOUNDS_CROWD_LAUGH": "Risada do Público",
    "SOUNDS_CRUNCH": "Triturar",
    "SOUNDS_CYMBAL": "Prato",
    "SOUNDS_CYMBAL_CRASH": "Crash de Prato",
    "SOUNDS_CYMBAL_ECHO": "Eco do Prato",
    "SOUNDS_D_BASS": "D Baixo",
    "SOUNDS_D_ELEC_BASS": "Baixo Elétrico D",
    "SOUNDS_D_ELEC_GUITAR": "Guitarra Elétrica D",
    "SOUNDS_D_ELEC_PIANO": "Piano Elétrico D",
    "SOUNDS_D_GUITAR": "Guitarra D",
    "SOUNDS_D_PIANO": "Piano D",
    "SOUNDS_D_SAX": "Saxofone D",
    "SOUNDS_D_TROMBONE": "Trombone D",
    "SOUNDS_D_TRUMPET": "Trompete D",
    "SOUNDS_DANCE_AROUND": "Dançar por aí",
    "SOUNDS_DANCE_CELEBRATE": "Dançar Celebrar",
    "SOUNDS_DANCE_CELEBRATE2": "Dançar Celebrar 2",
    "SOUNDS_DANCE_CHILL_OUT": "Dançar Relaxar",
    "SOUNDS_DANCE_ENERGETIC": "Dançar Energético",
    "SOUNDS_DANCE_FUNKY": "Dançar Funky",
    "SOUNDS_DANCE_HEAD_NOD": "Dançar Nod Head",
    "SOUNDS_DANCE_MAGIC": "Dançar Mágica",
    "SOUNDS_DANCE_SITAR": "Dançar Sitar",
    "SOUNDS_DANCE_SLOW_MO": "Dançar Câmera Lenta",
    "SOUNDS_DANCE_SNARE_BEAT": "Batida de Caixa Dança",
    "SOUNDS_DANCE_SPACE": "Dançar Espacial",
    "SOUNDS_DISCONNECT": "Desconectar",
    "SOUNDS_DOG1": "Cachorro",
    "SOUNDS_DOG2": "Cachorro 2",
    "SOUNDS_DOOR_CLOSING": "Porta Fechando",
    "SOUNDS_DOOR_CREAK": "Ranger da Porta",
    "SOUNDS_DOORBELL": "Campainha",
    "SOUNDS_DRIP_DROP": "Gotejar",
    "SOUNDS_DRIVE_AROUND": "Dirigir por aí",
    "SOUNDS_DRUM": "Tambor",
    "SOUNDS_DRUM_BASS1": "Tambor Baixo 1",
    "SOUNDS_DRUM_BASS2": "Tambor Baixo 2",
    "SOUNDS_DRUM_BASS3": "Tambor Baixo 3",
    "SOUNDS_DRUM_BOING": "Tambor Boing",
    "SOUNDS_DRUM_BUZZ": "Zumbido de Tambor",
    "SOUNDS_DRUM_FUNKY": "Tambor Funky",
    "SOUNDS_DRUM_JAM": "Jam de Tambor",
    "SOUNDS_DRUM_MACHINE": "Máquina de Tambor",
    "SOUNDS_DRUM_ROLL": "Rolo de Tambor",
    "SOUNDS_DRUM_SATELLITE": "Satélite de Tambor",
    "SOUNDS_DRUM_SET1": "Configuração de Bateria 1",
    "SOUNDS_DRUM_SET2": "Configuração de Bateria 2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "Pato",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dunnn",
    "SOUNDS_E_BASS": "E Baixo",
    "SOUNDS_E_ELEC_BASS": "Baixo Elétrico E",
    "SOUNDS_E_ELEC_GUITAR": "Guitarra Elétrica E",
    "SOUNDS_E_ELEC_PIANO": "Piano Elétrico E",
    "SOUNDS_E_GUITAR": "Guitarra E",
    "SOUNDS_E_PIANO": "Piano E",
    "SOUNDS_E_SAX": "Saxofone E",
    "SOUNDS_E_TROMBONE": "Trombone E",
    "SOUNDS_E_TRUMPET": "Trompete E",
    "SOUNDS_EGGS": "Ovos",
    "SOUNDS_ELEC_PIANO_A_MINOR": "Teclado Lá Menor",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "Teclado Dó Menor",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "Teclado Fá Menor",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "Teclado Sol Menor",
    "SOUNDS_ELEC_PIANO_LOOP": "Loop de Piano Elétrico",
    "SOUNDS_EMOTIONAL_PIANO": "Piano Emocional",
    "SOUNDS_ENGINE": "Motor",
    "SOUNDS_F_BASS": "F Baixo",
    "SOUNDS_F_ELEC_BASS": "Baixo Elétrico F",
    "SOUNDS_F_ELEC_GUITAR": "Guitarra Elétrica F",
    "SOUNDS_F_ELEC_PIANO": "Piano Elétrico F",
    "SOUNDS_F_GUITAR": "Guitarra F",
    "SOUNDS_F_MAJOR_UKULELE": "Ukulele F Maior",
    "SOUNDS_F_PIANO": "Piano F",
    "SOUNDS_F_SAX": "Saxofone F",
    "SOUNDS_F_TROMBONE": "Trombone F",
    "SOUNDS_F_TRUMPET": "Trompete F",
    "SOUNDS_FAIRYDUST": "Pó de Fada",
    "SOUNDS_FINGER_SNAP": "Estalar de Dedos",
    "SOUNDS_FLAM_SNARE": "Tambor de Chama",
    "SOUNDS_FOOTSTEPS": "Passos",
    "SOUNDS_G_BASS": "G Baixo",
    "SOUNDS_G_ELEC_BASS": "Baixo Elétrico G",
    "SOUNDS_G_ELEC_GUITAR": "Guitarra Elétrica G",
    "SOUNDS_G_ELEC_PIANO": "Piano Elétrico G",
    "SOUNDS_G_GUITAR": "Guitarra G",
    "SOUNDS_G_PIANO": "Piano G",
    "SOUNDS_G_SAX": "Saxofone G",
    "SOUNDS_G_TROMBONE": "Trombone G",
    "SOUNDS_G_TRUMPET": "Trompete G",
    "SOUNDS_G_UKULELE": "Ukulele G",
    "SOUNDS_GALLOP": "Galope",
    "SOUNDS_GARDEN": "Jardim",
    "SOUNDS_GLASS_BREAKING": "Vidro Quebrando",
    "SOUNDS_GLUG": "Glug",
    "SOUNDS_GOAL_CHEER": "Torcida Aplaudindo",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Ganso",
    "SOUNDS_GROWL": "Rosnar",
    "SOUNDS_GRUNT": "Grunhido",
    "SOUNDS_GUITAR_CHORDS1": "Acordes de Guitarra 1",
    "SOUNDS_GUITAR_CHORDS2": "Acordes de Guitarra 2",
    "SOUNDS_GUITAR_STRUM": "Dedilhar de Guitarra",
    "SOUNDS_HAND_CLAP": "Palma da Mão",
    "SOUNDS_HEAD_SHAKE": "Balançar de Cabeça",
    "SOUNDS_HEY": "Oi",
    "SOUNDS_HI_BEATBOX": "Batida de Caixa Humana 1",
    "SOUNDS_HI_NA_TABLA": "Tabla Hi Na",
    "SOUNDS_HI_TUN_TABLA": "Tabla Tun Tun",
    "SOUNDS_HIGH_CONGA": "Conga Alta",
    "SOUNDS_HIGH_HAT": "Chimbal Aberto",
    "SOUNDS_HIGH_TOM": "Tom Alto",
    "SOUNDS_HIGH_WHOOSH": "Sopro Alto",
    "SOUNDS_HIHAT_BEATBOX": "Beatbox de Chimbal",
    "SOUNDS_HIHAT_CYMBAL": "Prato de Chimbal",
    "SOUNDS_HIP_HOP": "Hip Hop",
    "SOUNDS_HORSE": "Cavalo",
    "SOUNDS_HORSE_GALLOP": "Galope de Cavalo",
    "SOUNDS_HUMAN_BEATBOX1": "Beatbox Humano 1",
    "SOUNDS_HUMAN_BEATBOX2": "Beatbox Humano 2",
    "SOUNDS_JUMP": "Salto",
    "SOUNDS_JUNGLE": "Selva",
    "SOUNDS_JUNGLE_FROGS": "Sapos da Selva",
    "SOUNDS_KICK_BACK": "Chute de Volta",
    "SOUNDS_KICK_DRUM": "Bumbo",
    "SOUNDS_LARGE_COWBELL": "Cowbell Grande",
    "SOUNDS_LASER1": "Laser 1",
    "SOUNDS_LASER2": "Laser 2",
    "SOUNDS_LAUGH1": "Risada 1",
    "SOUNDS_LAUGH2": "Risada 2",
    "SOUNDS_LAUGH3": "Risada 3",
    "SOUNDS_LO_GEH_TABLA": "Gliss Tabla Lo",
    "SOUNDS_LO_GLISS_TABLA": "Geh Tabla Lo",
    "SOUNDS_LOSE": "Perder",
    "SOUNDS_LOW_BOING": "Boing Baixo",
    "SOUNDS_LOW_CONGA": "Conga Baixa",
    "SOUNDS_LOW_SQUEAK": "Chiado Baixo",
    "SOUNDS_LOW_TOM": "Tom Baixo",
    "SOUNDS_LOW_WHOOSH": "Whoosh Baixo",
    "SOUNDS_MACHINE": "Máquina",
    "SOUNDS_MAGIC_SPELL": "Feitiço Mágico",
    "SOUNDS_MEDIEVAL1": "Medieval 1",
    "SOUNDS_MEDIEVAL2": "Medieval 2",
    "SOUNDS_MEOW": "Miado",
    "SOUNDS_MEOW2": "Miado 2",
    "SOUNDS_MOO": "Muu",
    "SOUNDS_MOTORCYCLE_PASSING": "Moto Passando",
    "SOUNDS_MOVIE_1": "Filme 1",
    "SOUNDS_MOVIE_2": "Filme 2",
    "SOUNDS_MUTED_CONGA": "Conga Muda",
    "SOUNDS_MYSTERY": "Mistério",
    "SOUNDS_OCEAN_WAVE": "Onda do Oceano",
    "SOUNDS_ODESONG": "Canção Ode",
    "SOUNDS_OOPS": "Ops",
    "SOUNDS_ORCHESTRA_TUNING": "Afinação da Orquestra",
    "SOUNDS_OWL": "Coruja",
    "SOUNDS_PARTY_NOISE": "Barulho de Festa",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": "Pong do Pingue-Pongue",
    "SOUNDS_PLUCK": "Beliscar",
    "SOUNDS_PLUNGE": "Mergulho",
    "SOUNDS_POLICE_SIREN": "Sirene da Polícia",
    "SOUNDS_POP": "Estourar",
    "SOUNDS_RAIN": "Chuva",
    "SOUNDS_RATTLE": "Chocalhar",
    "SOUNDS_REFEREE_WHISTLE": "Apito do Árbitro",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "Ricochete",
    "SOUNDS_RIDE_CYMBAL": "Prato de Passeio",
    "SOUNDS_RING_TONE": "Toque de Celular",
    "SOUNDS_RIP": "Rasgar",
    "SOUNDS_RIPPLES": "Ondulações",
    "SOUNDS_ROLL_CYMBAL": "Prato Rolante",
    "SOUNDS_ROOSTER": "Galo",
    "SOUNDS_SCRAMBLING_FEET": "Pés Raspando",
    "SOUNDS_SCRATCH_BEATBOX": "Batida com Arranhão",
    "SOUNDS_SCRATCHY_BEAT": "Batida Arranhada",
    "SOUNDS_SCREAM1": "Grito 1",
    "SOUNDS_SCREAM2": "Grito 2",
    "SOUNDS_SCREECH": "Guincho",
    "SOUNDS_SEAGULLS": "Gaivotas",
    "SOUNDS_SEWING_MACHINE": "Máquina de Costura",
    "SOUNDS_SHAKER": "Chocalho",
    "SOUNDS_SHIP_BELL": "Sino de Navio",
    "SOUNDS_SIDESTICK_SNARE": "Palma da Mão na Lateral da Caixa (Snare Sidestick)",
    "SOUNDS_SINGER1": "Cantor 1",
    "SOUNDS_SINGER2": "Cantor 2",
    "SOUNDS_SIREN_WHISTLE": "Sirene Apito",
    "SOUNDS_SKID": "Derrapagem",
    "SOUNDS_SLIDE_WHISTLE": "Apito Deslizante",
    "SOUNDS_SMALL_COWBELL": "Pequena Campainha",
    "SOUNDS_SNAP": "Estalo",
    "SOUNDS_SNARE_BEATBOX": "Batida de Caixa (beatbox)",
    "SOUNDS_SNARE_BEATBOX2": "Batida de Caixa 2 (beatbox)",
    "SOUNDS_SNARE_DRUM": "Caixa (de bateria)",
    "SOUNDS_SNEAKER_SQUEAK": "Sola de Tênis Rangendo",
    "SOUNDS_SNEEZE1": "Espirro 1",
    "SOUNDS_SNEEZE2": "Espirro 2",
    "SOUNDS_SNORING": "Ronco",
    "SOUNDS_SNORT": "Resfolegar",
    "SOUNDS_SPACE_AMBIENCE": "Ambiente Espacial",
    "SOUNDS_SPACE_FLYBY": "Passagem Rápida pelo Espaço",
    "SOUNDS_SPACE_NOISE": "Ruído Espacial",
    "SOUNDS_SPACE_RIPPLE": "Ondulação Espacial",
    "SOUNDS_SPIRAL": "Espiral",
    "SOUNDS_SPLASH": "Esplashe",
    "SOUNDS_SPLASH_CYMBAL": "Prato de Choque",
    "SOUNDS_SPOOKY_STRING": "Cordas Assustadoras",
    "SOUNDS_SQUAWK": "Grito",
    "SOUNDS_SQUEAKS": "Pios",
    "SOUNDS_SQUEAKY_TOY": "Brinquedo Chirriante",
    "SOUNDS_SQUISH_POP": "Estouro Molhado",
    "SOUNDS_STRING_ACCENT": "Acento de Cordas",
    "SOUNDS_STRING_PLUCK": "Pinçar de Cordas",
    "SOUNDS_SUCTION_CUP": "Ventosa",
    "SOUNDS_SUSPENSE": "Suspense",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura ",
    "SOUNDS_TAP_CONGA": "Toque de Conga",
    "SOUNDS_TAP_SNARE": "Toque de Caixa",
    "SOUNDS_TECHNO": "Techno",
    "SOUNDS_TECHNO2": "Techno 2",
    "SOUNDS_TELEPHONE_RING": "Toque de Telefone",
    "SOUNDS_TELEPHONE_RING2": "Toque de Telefone 2",
    "SOUNDS_TELEPORT": "Teleporte",
    "SOUNDS_TELEPORT2": "Teleporte 2",
    "SOUNDS_TELEPORT3": "Teleporte 3",
    "SOUNDS_TENNIS_HIT": "Batida de Tênis",
    "SOUNDS_THUNDER_STORM": "Tempestade de Trovões",
    "SOUNDS_TOM_DRUM": "Tambor Tom",
    "SOUNDS_TOY_HONK": "Brinquedo de Buzina",
    "SOUNDS_TOY_ZING": "Zumbido de Brinquedo",
    "SOUNDS_TRAFFIC": "Tráfego",
    "SOUNDS_TRAIN_WHISTLE": "Apito de Trem",
    "SOUNDS_TRAP_BEAT": "Batida de Armadilha (gênero musical)",
    "SOUNDS_TRIUMPH": "Triunfo",
    "SOUNDS_TROPICAL_BIRDS": "Pássaros Tropicais",
    "SOUNDS_TRUMPET1": "Trombeta 1",
    "SOUNDS_TRUMPET2": "Trombeta 2",
    "SOUNDS_VIDEO_GAME_1": "Jogo de Vídeo 1",
    "SOUNDS_VIDEO_GAME_2": "Jogo de Vídeo 2",
    "SOUNDS_WAH_BEATBOX": "Batida de Wah ",
    "SOUNDS_WAND": "Varinha",
    "SOUNDS_WATER_DROP": "Gota de Água",
    "SOUNDS_WHINNY": "Relincho",
    "SOUNDS_WHISTLE_THUMP": "Assobio com Pancada",
    "SOUNDS_WHIZ": "Zumbido",
    "SOUNDS_WHOOP": "Whoop ",
    "SOUNDS_WIN": "Vitória",
    "SOUNDS_WOBBLE": "Wobble",
    "SOUNDS_WOLF_HOWL": "Uivo de Lobo",
    "SOUNDS_WOOD_TAP": "Toque de Madeira",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "Xilofone 1",
    "SOUNDS_XYLO2": "Xilofone 2",
    "SOUNDS_XYLO3": "Xilofone 3",
    "SOUNDS_XYLO4": "Xilofone 4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zíper",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_APPLE": "Maçã",
    "SPRITES_ARROW1": "Setas 1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Caminhando",
    "SPRITES_BALL": "Bola",
    "SPRITES_BALLERINA": "Bailarina",
    "SPRITES_BALLOON1": "Balão 1",
    "SPRITES_BANANAS": "Bananas",
    "SPRITES_BASEBALL": "Beisebol",
    "SPRITES_BASKETBALL": "Basquete",
    "SPRITES_BAT": "Morcego",
    "SPRITES_BATTER": "Batedor",
    "SPRITES_BEACHBALL": "Bola de Praia",
    "SPRITES_BEAR": "Urso",
    "SPRITES_BEAR-WALKING": "Urso Caminhando",
    "SPRITES_BEETLE": "Besouro",
    "SPRITES_BELL": "Sino",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Tigela",
    "SPRITES_BOWTIE": "Laço de Gravata",
    "SPRITES_BREAD": "Pão",
    "SPRITES_BROOM": "Vassoura",
    "SPRITES_BUILDINGS": "Edifícios",
    "SPRITES_BUTTERFLY_1": "Borboleta 1",
    "SPRITES_BUTTERFLY_2": "Borboleta 2",
    "SPRITES_BUTTON1": "Botão 1",
    "SPRITES_BUTTON2": "Botão 2",
    "SPRITES_BUTTON3": "Botão 3",
    "SPRITES_BUTTON4": "Botão 4",
    "SPRITES_BUTTON5": "Botão 5",
    "SPRITES_CAKE": "Bolo",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Gato 2",
    "SPRITES_CATCHER": "Apanhador",
    "SPRITES_CENTAUR": "Centauro",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Pintinho",
    "SPRITES_CITY_BUS": "Ônibus da Cidade",
    "SPRITES_CLOUD": "Nuvem",
    "SPRITES_CLOUDS": "Nuvens",
    "SPRITES_CONVERTIBLE": "Conversível",
    "SPRITES_CONVERTIBLE_2": "Conversível 2",
    "SPRITES_CRAB": "Caranguejo",
    "SPRITES_CRYSTAL": "Cristal",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "Dinossauro 1",
    "SPRITES_DINOSAUR2": "Dinossauro 2",
    "SPRITES_DINOSAUR3": "Dinossauro 3",
    "SPRITES_DINOSAUR4": "Dinossauro 4",
    "SPRITES_DINOSAUR5": "Dinossauro 5",
    "COSTUMES_DINOSAUR5-A": "Dinossauro 5-a",
    "COSTUMES_DINOSAUR5-B": "Dinossauro 5-b",
    "COSTUMES_DINOSAUR5-C": "Dinossauro 5-c",
    "COSTUMES_DINOSAUR5-D": "Dinossauro 5-d",
    "COSTUMES_DINOSAUR5-E": "Dinossauro 5-e",
    "COSTUMES_DINOSAUR5-F": "Dinossauro 5-f",
    "COSTUMES_DINOSAUR5-G": "Dinossauro 5-g",
    "COSTUMES_DINOSAUR5-H": "Dinossauro 5-h",
    "SPRITES_DIVER1": "Mergulhador 1",
    "SPRITES_DIVER2": "Mergulhador 2",
    "SPRITES_DOG1": "Cachorro 1",
    "SPRITES_DOG2": "Cachorro 2",
    "SPRITES_DONUT": "Donut",
    "SPRITES_DORIAN": "Dorian",
    "SPRITES_DOT": "Ponto",
    "SPRITES_DOVE": "Pomba",
    "SPRITES_DRAGON": "Dragão",
    "SPRITES_DRAGONFLY": "Libélula",
    "SPRITES_DRESS": "Vestido",
    "SPRITES_DRUM": "Tambor",
    "SPRITES_DRUM_KIT": "Kit de Bateria",
    "SPRITES_DRUM-CYMBAL": "Címbalo de Bateria",
    "SPRITES_DRUM-HIGHHAT": "Chimbal de Bateria",
    "SPRITES_DRUM-SNARE": "Caixa de Bateria",
    "SPRITES_DRUMS_CONGA": "Tambores Conga",
    "SPRITES_DRUMS_TABLA": "Tambores Tabla",
    "SPRITES_DUCK": "Pato",
    "SPRITES_EARTH": "Terra",
    "SPRITES_EASEL": "Cavalete",
    "SPRITES_EGG": "Ovo",
    "SPRITES_ELEPHANT": "Elefante",
    "SPRITES_ELF": "Elfo",
    "SPRITES_FAIRY": "Fada",
    "SPRITES_FISH": "Peixe",
    "SPRITES_FISHBOWL": "Aquário",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Futebol",
    "SPRITES_FORTUNE_COOKIE": "Biscoito da Sorte",
    "SPRITES_FOX": "Raposa",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "Sapo",
    "SPRITES_FROG_2_": "Sapo 2",
    "SPRITES_FRUIT_PLATTER": "Prato de Frutas",
    "SPRITES_FRUIT_SALAD": "Salada de Frutas",
    "SPRITES_GHOST": "Fantasma",
    "SPRITES_GIFT": "Presente",
    "SPRITES_GIRAFFE": "Girafa",
    "SPRITES_GLASS_WATER": "Água de Vidro",
    "SPRITES_GLASSES": "Óculos",
    "SPRITES_GOALIE": "Goleiro",
    "SPRITES_GOBLIN": "Goblin",
    "SPRITES_GRASSHOPPER": "Gafanhoto",
    "SPRITES_GREEN_FLAG": "Bandeira Verde",
    "SPRITES_GRIFFIN": "Griffin",
    "SPRITES_GUITAR": "Guitarra",
    "SPRITES_GUITAR-ELECTRIC1": "Guitarra Elétrica 1",
    "SPRITES_GUITAR-ELECTRIC2": "Guitarra Elétrica 2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Lebre",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Chapéu 1",
    "SPRITES_HATCHLING": "Filhote",
    "SPRITES_HEART": "Coração",
    "SPRITES_HEART_CANDY": "Coração doce",
    "SPRITES_HEART_FACE": "Face do Coração",
    "SPRITES_HEDGEHOG": "Ouriço",
    "SPRITES_HEN": "Galo",
    "SPRITES_HIPPO1": "Hipopótamo 1",
    "SPRITES_HOME_BUTTON": "Botão Início",
    "SPRITES_HORSE": "Cavalo",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "Jarra",
    "SPRITES_JELLYFISH": "Água-viva",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "Chave",
    "SPRITES_KEYBOARD": "Teclado",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Cavaleiro",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_LADYBUG1": "Joaninha 1",
    "SPRITES_LADYBUG2": "Joaninha 2",
    "SPRITES_LAPTOP": "Laptop",
    "SPRITES_LIGHTNING": "Relâmpago",
    "SPRITES_LINE": "Linha",
    "SPRITES_LION": "Leão",
    "SPRITES_LLAMA": "Lhama",
    "SPRITES_MAGIC_WAND": "Varinha Mágica",
    "SPRITES_MAX": "Max",
    "SPRITES_MERMAID": "Sereia",
    "SPRITES_MICROPHONE": "Microfone",
    "SPRITES_MILK": "Leite",
    "SPRITES_MIND+": "Mente +",
    "COSTUMES_MIND+1": "Mente + 1",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Macaco",
    "SPRITES_MOTORCYCLE": "Motocicleta",
    "SPRITES_MOUSE1": "Mouse 1",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": "Relincho de Pônei",
    "SPRITES_OCTOPUS": "Polvo",
    "SPRITES_ORANGE": "Laranja",
    "SPRITES_ORANGE2": "Laranja 2",
    "SPRITES_OUTFIELDER": "Jogador no Campo",
    "SPRITES_OWL": "Coruja",
    "SPRITES_PADDLE": "Pá",
    "SPRITES_PANTHER": "Pantera",
    "SPRITES_PANTS": "Calças",
    "SPRITES_PARROT": "Papagaio",
    "SPRITES_PARTY_HATS": "Chapéus de Festa",
    "SPRITES_PENCIL": "Lápis",
    "SPRITES_PENGUIN": "Pinguim",
    "SPRITES_PENGUIN_2": "Pinguim 2",
    "SPRITES_PITCHER": "Jarro",
    "SPRITES_PLANET2": "Planeta 2",
    "SPRITES_POLAR_BEAR": "Urso Polar",
    "SPRITES_POTION": "Poção",
    "SPRITES_PRINCE": "Príncipe",
    "SPRITES_PRINCESS": "Princesa",
    "SPRITES_PUFFERFISH": "Pufferfish",
    "SPRITES_PUPPY": "Filhote",
    "SPRITES_RABBIT": "Coelho",
    "SPRITES_RADIO": "Rádio",
    "SPRITES_RAINBOW": "Arco-íris",
    "SPRITES_REFEREE": "Árbitro",
    "SPRITES_REINDEER": "Rena",
    "SPRITES_RETRO_ROBOT": "Robô Retrô",
    "SOUNDS_COMPUTER_BEEPS1": "Computador Beeps 1",
    "SOUNDS_COMPUTER_BEEPS2": "Computador Beeps 2",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Robô",
    "SPRITES_ROCKETSHIP": "Foguete",
    "SPRITES_ROCKS": "Pedras",
    "SPRITES_ROOSTER": "Galo",
    "SPRITES_RUBY": "Rubi",
    "SPRITES_SAILBOAT": "Barco a Vela",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "Saxofone",
    "SPRITES_SCARF": "Cachecol",
    "SPRITES_SHARK": "Tubarão",
    "SPRITES_SHARK_2": "Tubarão 2",
    "SPRITES_SHIRT": "Camisa",
    "SPRITES_SHOES": "Sapatos",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "Cantora 1",
    "SPRITES_SKELETON": "Esqueleto",
    "SPRITES_SNAKE": "Cobra",
    "SPRITES_SNOWFLAKE": "Flocos de Neve",
    "SPRITES_SNOWMAN": "Boneco de Neve",
    "SPRITES_SOCCER_BALL": "Bola de Futebol",
    "SPRITES_SPEAKER": "Alto-falante",
    "SPRITES_SQUIRREL": "Esquilo",
    "SPRITES_STAR": "Estrela",
    "SPRITES_STARFISH": "Estrela-do-mar",
    "SPRITES_STOP": "Pare",
    "SPRITES_STRAWBERRY": "Morango",
    "SPRITES_SUN": "Sol",
    "SPRITES_SUNGLASSES1": "Óculos de Sol 1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Comida para Viagem",
    "SPRITES_TEN80_DANCE": "Dança Ten80",
    "SPRITES_TENNIS_BALL": "Bola de Tênis",
    "SPRITES_TOUCAN": "Tucano",
    "SPRITES_TRAMPOLINE": "Cama Elástica",
    "SPRITES_TREE1": "Árvore 1",
    "SPRITES_TREES": "Árvores",
    "SPRITES_TRUCK": "Caminhão",
    "SPRITES_TRUMPET": "Trompete",
    "SPRITES_UNICORN": "Unicórnio",
    "SPRITES_UNICORN_2": "Unicórnio 2",
    "SPRITES_UNICORN_RUNNING": "Unicórnio Correndo",
    "SPRITES_WAND": "Varinha",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Melancia",
    "SPRITES_WINTER_HAT": "Chapéu de Inverno",
    "SPRITES_WITCH": "Bruxa",
    "SPRITES_WIZARD": "Mago",
    "SPRITES_WIZARD_HAT": "Chapéu de Bruxa",
    "SPRITES_WIZARD-TOAD": "Sapo Mágico",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "Bloco-A",
    "SPRITES_BLOCK-B": "Bloco-B",
    "SPRITES_BLOCK-C": "Bloco-C",
    "SPRITES_BLOCK-D": "Bloco-D",
    "SPRITES_BLOCK-E": "Bloco-E",
    "SPRITES_BLOCK-F": "Bloco-F",
    "SPRITES_BLOCK-G": "Bloco-G",
    "SPRITES_BLOCK-H": "Bloco-H",
    "SPRITES_BLOCK-I": "Bloco-I",
    "SPRITES_BLOCK-J": "Bloco-J",
    "SPRITES_BLOCK-K": "Bloco-K",
    "SPRITES_BLOCK-L": "Bloco-L",
    "SPRITES_BLOCK-M": "Bloco-M",
    "SPRITES_BLOCK-N": "Bloco-N",
    "SPRITES_BLOCK-O": "Bloco-O",
    "SPRITES_BLOCK-P": "Bloco-P",
    "SPRITES_BLOCK-Q": "Bloco-Q",
    "SPRITES_BLOCK-R": "Bloco-R",
    "SPRITES_BLOCK-S": "Bloco-S",
    "SPRITES_BLOCK-T": "Bloco-T",
    "SPRITES_BLOCK-U": "Bloco-U",
    "SPRITES_BLOCK-V": "Bloco-V",
    "SPRITES_BLOCK-W": "Bloco-W",
    "SPRITES_BLOCK-X": "Bloco-X",
    "SPRITES_BLOCK-Y": "Bloco-Y",
    "SPRITES_BLOCK-Z": "Bloco-Z",
    "SPRITES_GLOW-0": "Brilho-0",
    "SPRITES_GLOW-1": "Brilho-1",
    "SPRITES_GLOW-2": "Brilho-2",
    "SPRITES_GLOW-3": "Brilho-3",
    "SPRITES_GLOW-4": "Brilho-4",
    "SPRITES_GLOW-5": "Brilho-5",
    "SPRITES_GLOW-6": "Brilho-6",
    "SPRITES_GLOW-7": "Brilho-7",
    "SPRITES_GLOW-8": "Brilho-8",
    "SPRITES_GLOW-9": "Brilho-9",
    "SPRITES_GLOW-A": "Brilho-A",
    "SPRITES_GLOW-B": "Brilho-B",
    "SPRITES_GLOW-C": "Brilho-C",
    "SPRITES_GLOW-D": "Brilho-D",
    "SPRITES_GLOW-E": "Brilho-E",
    "SPRITES_GLOW-F": "Brilho-F",
    "SPRITES_GLOW-G": "Brilho-G",
    "SPRITES_GLOW-H": "Brilho-H",
    "SPRITES_GLOW-I": "Brilho-I",
    "SPRITES_GLOW-J": "Brilho-J",
    "SPRITES_GLOW-K": "Brilho-K",
    "SPRITES_GLOW-L": "Brilho-L",
    "SPRITES_GLOW-M": "Brilho-M",
    "SPRITES_GLOW-N": "Brilho-N",
    "SPRITES_GLOW-O": "Brilho-O",
    "SPRITES_GLOW-P": "Brilho-P",
    "SPRITES_GLOW-Q": "Brilho-Q",
    "SPRITES_GLOW-R": "Brilho-R",
    "SPRITES_GLOW-S": "Brilho-S",
    "SPRITES_GLOW-T": "Brilho-T",
    "SPRITES_GLOW-U": "Brilho-U",
    "SPRITES_GLOW-V": "Brilho-V",
    "SPRITES_GLOW-W": "Brilho-W",
    "SPRITES_GLOW-X": "Brilho-X",
    "SPRITES_GLOW-Y": "Brilho-Y",
    "SPRITES_GLOW-Z": "Brilho-Z",
    "SPRITES_STORY-A": "História-A",
    "SPRITES_STORY-B": "História-B",
    "SPRITES_STORY-C": "História-C",
    "SPRITES_STORY-D": "História-D",
    "SPRITES_STORY-E": "História-E",
    "SPRITES_STORY-F": "História-F",
    "SPRITES_STORY-G": "História-G",
    "SPRITES_STORY-H": "História-H",
    "SPRITES_STORY-I": "História-I",
    "SPRITES_STORY-J": "História-J",
    "SPRITES_STORY-K": "História-K",
    "SPRITES_STORY-L": "História-L",
    "SPRITES_STORY-M": "História-M",
    "SPRITES_STORY-N": "História-N",
    "SPRITES_STORY-O": "História-O",
    "SPRITES_STORY-P": "História-P",
    "SPRITES_STORY-Q": "História-Q",
    "SPRITES_STORY-R": "História-R",
    "SPRITES_STORY-S": "História-S",
    "SPRITES_STORY-T": "História-T",
    "SPRITES_STORY-U": "História-U",
    "SPRITES_STORY-V": "História-V",
    "SPRITES_STORY-W": "História-W",
    "SPRITES_STORY-X": "História-X",
    "SPRITES_STORY-Y": "História-Y",
    "SPRITES_STORY-Z": "História-Z",
    "BACKDROPS_BEDROOM_1": "Quarto 1",
    "COSTUMES_STEAMED-STUFFED-BUN": "Pão no Vapor",
    "COSTUMES_LUCKY-BAG": "Saco da Sorte",
    "COSTUMES_DUMPLINGS": "Bolinhos",
    "COSTUMES_GOLD-COINS": "Moedas de Ouro",
    "COSTUMES_A-GIFT": "Um Presente-A",
    "COSTUMES_STEAMED-BREAD": "Pão no Vapor",
    "COSTUMES_PLATES": "Pratos",
    "COSTUMES_SILVER-INGOT": "Lingote de Prata",
    "COSTUMES_FIRECRACKERS": "Fogos de Artifício",
    "COSTUMES_FIRECRACKERS-1": "Fogos de Artifício 1",
    "COSTUMES_FIRECRACKERS-2": "Fogos de Artifício 2",
    "COSTUMES_FIRECRACKERS-3": "Fogos de Artifício 3",
    "COSTUMES_LANTERN": "Lanterna",
    "COSTUMES_LANTERN-1": "Lanterna 1",
    "COSTUMES_LANTERN-2": "Lanterna 2",
    "COSTUMES_DRUM": "Tambor-A",
    "COSTUMES_DRUM-1": "Tambor 1",
    "COSTUMES_DRUM-2": "Tambor 2",
    "COSTUMES_DRUM-3": "Tambor 3",
    "COSTUMES_RED-ENVELOPE": "Envelope Vermelho",
    "COSTUMES_RED-ENVELOPE-1": "Envelope Vermelho 1",
    "COSTUMES_RED-ENVELOPE-2": "Envelope Vermelho 2",
    "COSTUMES_SCROLL": "Pergaminho",
    "COSTUMES_SCROLL-1": "Pergaminho 1",
    "COSTUMES_SCROLL-2": "Pergaminho 2",
    "COSTUMES_SCROLL-3": "Pergaminho 3",
    "COSTUMES_YETI": "Yeti-A",
    "COSTUMES_YETI-1": "Yeti 1",
    "COSTUMES_YETI-2": "Yeti 2",
    "COSTUMES_FIREWORKS": "Fogos de Artifício",
    "COSTUMES_FIREWORKS-1": "Fogos de Artifício 1",
    "COSTUMES_FIREWORKS-2": "Fogos de Artifício 2",
    "COSTUMES_FIREWORKS-3": "Fogos de Artifício 3",
    "COSTUMES_FIREWORKS-4": "Fogos de Artifício 4",
    "COSTUMES_FIREWORKS-5": "Fogos de Artifício 5",
    "COSTUMES_FIREWORKS-6": "Fogos de Artifício 6",
    "COSTUMES_FIREWORKS-7": "Fogos de Artifício 7",
    "COSTUMES_FIREWORKS-8": "Fogos de Artifício 8",
    "COSTUMES_FIREWORKS-9": "Fogos de Artifício 9",
    "SPRITES_MIND+2022_01_CIVIL-AIRCRAFT": "Aeronave Civil",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT1": "Aeronave Civil - Frente",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT2": "Aviação Civil - Lado",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT3": "Aeronave Civil - Topo",
    "SPRITES_MIND+2022_02_AEROSPACEPLANE": "Ônibus Espacial",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE1": "Ônibus Espacial - Frente",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE2": "Ônibus Espacial - Lado",
    "SPRITES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM": "Cabine do Ônibus Espacial",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM1": "Cabine do Ônibus Espacial - Frente",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM2": "Cabine do Ônibus Espacial - Lado",
    "SPRITES_MIND+2022_04_TRAFFIC-LIGHT": "Sinalização de Semáforo Expressivo",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT1": "Semáforo Expressivo - Vermelho",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT2": "Semáforo Expressivo - Amarelo",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT3": "Semáforo Expressivo - Verde",
    "SPRITES_MIND+2022_05_FLYBROW": "Dirigível",
    "COSTUMES_MIND+2022_05_FLYBROW1": "Dirigível - Lado",
    "COSTUMES_MIND+2022_05_FLYBROW2": "Dirigível - Olhando para Cima",
    "SPRITES_MIND+2022_06_HIGHSPEED-RAIL": "Trem de Alta Velocidade",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL1": "Trem de Alta Velocidade - Frente",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL2": "Trem de Alta Velocidade - Lado",
    "SPRITES_MIND+2022_07_POLICECAR": "Carro de Polícia",
    "COSTUMES_MIND+2022_07_POLICECAR1": "Carro de Polícia - Esportivo 1",
    "COSTUMES_MIND+2022_07_POLICECAR2": "Carro de Polícia - Esportivo 2",
    "COSTUMES_MIND+2022_07_POLICECAR3": "Carro de Polícia - Esportivo 3",
    "COSTUMES_MIND+2022_07_POLICECAR4": "Carro de Polícia - Luzes 1",
    "COSTUMES_MIND+2022_07_POLICECAR5": "Carro de Polícia - Luzes 2",
    "COSTUMES_MIND+2022_07_POLICECAR6": "Carro de Polícia - Parado",
    "COSTUMES_MIND+2022_07_POLICECAR7": "Carro de Polícia - Estacionário",
    "SPRITES_MIND+2022_08_CAR": "Carro",
    "COSTUMES_MIND+2022_08_CAR1": "Carro-1",
    "COSTUMES_MIND+2022_08_CAR2": "Carro-2",
    "COSTUMES_MIND+2022_08_CAR3": "Carro-3",
    "COSTUMES_MIND+2022_08_CAR4": "Carro-4",
    "COSTUMES_MIND+2022_08_CAR5": "Carro-5",
    "COSTUMES_MIND+2022_08_CAR6": "Carro-6",
    "SPRITES_MIND+2022_09_DININGCAR": "Carro de Jantar",
    "SPRITES_MIND+2022_10_BUS": "Ônibus",
    "SPRITES_MIND+2022_11_GO-KART": "Kart",
    "SPRITES_MIND+2022_12_MOTORBIKE": "Motocicleta",
    "SPRITES_MIND+2022_13_TAXI": "Táxi",
    "COSTUMES_MIND+2022_13_TAXI01": "Táxi - Lado Frontal",
    "COSTUMES_MIND+2022_13_TAXI02": "Táxi - Lado",
    "COSTUMES_MIND+2022_13_TAXI03": "Táxi - Desligue as luzes da frente e do lado",
    "COSTUMES_MIND+2022_13_TAXI04": "Táxi - Luzes da frente do lado 1",
    "COSTUMES_MIND+2022_13_TAXI05": "Táxi - Luzes da frente do lado 2",
    "COSTUMES_MIND+2022_13_TAXI06": "Táxi-Frente",
    "SPRITES_MIND+2022_14_PUMPKINCAR": "Carro Abóbora",
    "COSTUMES_MIND+2022_14_PUMPKINCAR1": "Carro Abóbora-1",
    "COSTUMES_MIND+2022_14_PUMPKINCAR2": "Carro Abóbora-2",
    "COSTUMES_MIND+2022_14_PUMPKINCAR3": "Carro Abóbora-3",
    "COSTUMES_MIND+2022_14_PUMPKINCAR4": "Carro Abóbora - Porta Aberta",
    "COSTUMES_MIND+2022_14_PUMPKINCAR5": "Carro Abóbora-Noite",
    "SPRITES_MIND+2022_15_LITTLESAILBOAT": "Pequeno Veleiro",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT1": "Pequeno Veleiro-Lado",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT2": "Pequeno Veleiro-Topo Lado",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT3": "Barco a Vela-Inclinado Topo Lado",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT4": "Barco a Vela-Inclinado Topo Lado 1",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT5": "Barco a Vela-Inclinado Topo Lado 2",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT6": "Barco a Vela-Inclinado Topo Lado 3",
    "SPRITES_MIND+2022_16_RUBBLERACER": "Nave Espacial Pequena",
    "COSTUMES_MIND+2022_16_RUBBLERACER1": "Nave Espacial Pequena-01",
    "COSTUMES_MIND+2022_16_RUBBLERACER2": "Nave Espacial Pequena-02",
    "COSTUMES_MIND+2022_16_RUBBLERACER3": "Nave Espacial Pequena-03",
    "SPRITES_MIND+2022_17_BARNEY": "Carrinho Pequeno",
    "COSTUMES_MIND+2022_17_BARNEY1": "Mini Carrinho - Lado Completo",
    "COSTUMES_MIND+2022_17_BARNEY2": "Carrinho de Mineração - Topo Lado",
    "COSTUMES_MIND+2022_17_BARNEY3": "Carrinho de Mineração - Topo Lado 2",
    "COSTUMES_MIND+2022_17_BARNEY4": "Carrinho de Mineração - Topo Lado 3",
    "COSTUMES_MIND+2022_17_BARNEY5": "Carrinho Pequeno - Topo Lado Vazio 1",
    "SPRITES_MIND+2022_18_UTILITYCART": "Carrinho",
    "COSTUMES_MIND+2022_18_UTILITYCART1": "Carrinho-Lado",
    "COSTUMES_MIND+2022_18_UTILITYCART2": "Carrinho - Topo Lado",
    "COSTUMES_MIND+2022_18_UTILITYCART3": "Carrinho-Topo Lado Completo 1",
    "COSTUMES_MIND+2022_18_UTILITYCART4": "Carrinho - Topo Lado Completo 2",
    "COSTUMES_MIND+2022_18_UTILITYCART5": "Carrinho-Topo Lado Completo 3",
    "SPRITES_MIND+2022_19_BIKE": "Bicicleta",
    "COSTUMES_MIND+2022_19_BIKE1": "Bicicleta-Lado 1",
    "COSTUMES_MIND+2022_19_BIKE2": "Bicicleta - Lado 2",
    "COSTUMES_MIND+2022_19_BIKE3": "Bicicleta - Lado 3",
    "COSTUMES_MIND+2022_19_BIKE4": "Bicicleta - Lado 4",
    "COSTUMES_MIND+2022_19_BIKE5": "Bicicleta - Frente",
    "SPRITES_MIND+2022_20_RACECAR": "Carro de Corrida",
    "COSTUMES_MIND+2022_20_RACECAR1": "Carro de Corrida - Lado",
    "COSTUMES_MIND+2022_20_RACECAR2": "Carro de Corrida Azul - Topo 1",
    "COSTUMES_MIND+2022_20_RACECAR3": "Carro de Corrida Azul - Topo 2",
    "COSTUMES_MIND+2022_20_RACECAR4": "Carro de Corrida Azul - Topo 3",
    "COSTUMES_MIND+2022_20_RACECAR5": "Carro de Corrida Amarelo - Topo 1",
    "COSTUMES_MIND+2022_20_RACECAR6": "Carro de Corrida Amarelo - Topo 2",
    "COSTUMES_MIND+2022_20_RACECAR7": "Carro de Corrida Amarelo - Topo 3",
    "COSTUMES_MIND+2022_20_RACECAR8": "Carro de Corrida Rosa - Topo 1",
    "COSTUMES_MIND+2022_20_RACECAR9": "Carro de Corrida Rosa - Topo 2",
    "COSTUMES_MIND+2022_20_RACECAR10": "Carro de Corrida Rosa - Topo 3",
    "SPRITES_MIND+2022_21_METEORITES1": "Meteorito",
    "COSTUMES_MIND+2022_21_METEORITES1_1": "Meteorito 1-01",
    "COSTUMES_MIND+2022_21_METEORITES1_2": "Meteorito 1-02",
    "COSTUMES_MIND+2022_21_METEORITES1_3": "Meteorito 1-03",
    "SPRITES_MIND+2022_22_METEORITES2": "Meteorito 2",
    "COSTUMES_MIND+2022_22_METEORITES2_1": "Meteorito 2-01",
    "COSTUMES_MIND+2022_22_METEORITES2_2": "Meteorito 2-02",
    "COSTUMES_MIND+2022_22_METEORITES2_3": "Meteorito 2-03",
    "SPRITES_MIND+2022_23_METEORITES3": "Meteorito 3",
    "COSTUMES_MIND+2022_23_METEORITES3_1": "Meteorito 3-01",
    "COSTUMES_MIND+2022_23_METEORITES3_2": "Meteorito 3-02",
    "COSTUMES_MIND+2022_23_METEORITES3_3": "Meteorito 3-03",
    "SPRITES_MIND+2022_24_METEORITES4": "Meteorito 4",
    "COSTUMES_MIND+2022_24_METEORITES4_1": "Meteorito 4-01",
    "COSTUMES_MIND+2022_24_METEORITES4_2": "Meteorito 4-02",
    "COSTUMES_MIND+2022_24_METEORITES4_3": "Meteorito 4-03",
    "COSTUMES_MIND+2022_24_METEORITES4_4": "Meteorito 4-04",
    "SPRITES_MIND+2022_25_METEORITES5": "Meteorito 5",
    "COSTUMES_MIND+2022_25_METEORITES5_1": "Meteorito 5-01",
    "COSTUMES_MIND+2022_25_METEORITES5_2": "Meteorito 5-02",
    "COSTUMES_MIND+2022_25_METEORITES5_3": "Meteorito 5-03",
    "COSTUMES_MIND+2022_25_METEORITES5_4": "Meteorito 5-04",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_01": "Aventura do Meteorito A-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_02": "Aventura do Meteorito A-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_01": "Aventura do Meteorito B-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_02": "Aventura do Meteorito B-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_03": "Aventura do Meteorito B-3",
    "MIND+_FACE_SIDE": "Expressão facial do Mind+ - lado",
    "MIND+_FACE_SIDE-1": "Expressão facial do Mind+ - lado-1",
    "MIND+_FACE_SIDE-2": "Expressão facial do Mind+ - lado-2",
    "MIND+_FACE_SIDE-3": "Expressão facial do Mind+ - lado-3",
    "MIND+_FACE_SIDE-4": "Expressão facial do Mind+ - lado-4",
    "MIND+_FACE_SIDE-5": "Expressão facial do Mind+ - lado-5",
    "MIND+_FACE_SIDE-6": "Expressão facial do Mind+ - lado-6",
    "MIND+_FACE_SIDE-7": "Expressão facial do Mind+ - lado-7",
    "MIND+_FACE_SIDE-8": "Expressão facial do Mind+ - lado-8",
    "MIND+_FACE_SIDE-9": "Expressão facial do Mind+ - lado-9",
    "MIND+_FACE_SIDE-10": "Expressão facial do Mind+ - lado-10",
    "MIND+_FACE_POSITIVE": "Expressão facial Mind+ - frente",
    "MIND+_FACE_POSITIVE-1": "Expressão facial Mind+ - frente-1",
    "MIND+_FACE_POSITIVE-2": "Expressão facial Mind+ - frente-2",
    "MIND+_FACE_POSITIVE-3": "Expressão facial Mind+ - frente-3",
    "MIND+_FACE_POSITIVE-4": "Expressão facial Mind+ - frente-4",
    "MIND+_FACE_POSITIVE-5": "Expressão facial Mind+ - frente-5",
    "MIND+_FACE_POSITIVE-6": "Expressão facial Mind+ - frente-6",
    "MIND+_FACE_POSITIVE-7": "Expressão facial Mind+ - frente-7",
    "MIND+_FACE_POSITIVE-8": "Expressão facial Mind+ - frente-8",
    "MIND+_FACE_POSITIVE-9": "Expressão facial Mind+ - frente-9",
    "MIND+_FACE_POSITIVE-10": "Expressão facial Mind+ - frente-10",
    "MIND+_DOZE": "Cochilar",
    "MIND+_DOZE-1": "Cochilar-1",
    "MIND+_DOZE-2": "Cochilar-2",
    "MIND+_DOZE-3": "Cochilar-3",
    "MIND+_SQUAT": "Agachar e levantar",
    "MIND+_SQUAT-1": "Agachar e levantar-1",
    "MIND+_SQUAT-2": "Agachar e levantar-2",
    "MIND+_SQUAT-3": "Agachar e levantar-3",
    "MIND+_ARCHITECT": "Mind+ arquiteto",
    "MIND+_ARCHITECT-1": "Mind+ arquiteto-1",
    "MIND+_ARCHITECT-2": "Mind+ arquiteto-2",
    "MIND+_ARCHITECT-3": "Mind+ arquiteto-3",
    "MIND+_TRAFFIC_POLICE": "Mind+ oficial de trânsito",
    "MIND+_TRAFFIC_POLICE-1": "Mind+ oficial de trânsito-1",
    "MIND+_TRAFFIC_POLICE-2": "Mind+ oficial de trânsito-2",
    "MIND+_TRAFFIC_POLICE-3": "Mind+ oficial de trânsito-3",
    "MIND+_TRAFFIC_POLICE-4": "Mind+ oficial de trânsito-4",
    "MIND+_TRAFFIC_POLICE-5": "Mind+ oficial de trânsito-5",
    "MIND+_TRAFFIC_POLICE-6": "Mind+ oficial de trânsito-6",
    "MIND+_POLICE": "Mind+ policial",
    "MIND+_POLICE-1": "Mind+ policial-1",
    "MIND+_POLICE-2": "Mind+ policial-2",
    "MIND+_POLICE-3": "Mind+ policial-3",
    "MIND+_POLICE-4": "Mind+ policial-4",
    "MIND+_POLICE-5": "Mind+ policial-5",
    "MIND+_POLICE-6": "Mind+ policial-calça-1",
    "MIND+_POLICE-7": "Mind+ policial-calça-2",
    "MIND+_SCIENTIST": "Mind+ cientista",
    "MIND+_SCIENTIST-1": "Mind+ cientista-1",
    "MIND+_SCIENTIST-2": "Mind+ cientista-2",
    "MIND+_SCIENTIST-3": "Mind+ cientista-3",
    "MIND+_SANTA_CLAUS": "Mind+ Papai Noel",
    "MIND+_SANTA_CLAUS-1": "Mind+ Papai Noel-acenar com a mão",
    "MIND+_SANTA_CLAUS-2": "Mind+ Papai Noel-presentes",
    "MIND+_SANTA_CLAUS-3": "Mind+ Papai Noel-dança",
    "MIND+_FIREMAN": "Mind+ bombeiro",
    "MIND+_FIREMAN-1": "Mind+ bombeiro-1",
    "MIND+_FIREMAN-2": "Mind+ bombeiro-2",
    "MIND+_FIREMAN-3": "Mind+ bombeiro-3",
    "MIND+_FIREMAN-4": "Mind+ bombeiro-4",
    "MIND+_DOCTOR": "Mind+ médico",
    "MIND+_DOCTOR-1": "Mind+ médico-1",
    "MIND+_DOCTOR-2": "Mind+ médico-2",
    "MIND+_DOCTOR-3": "Mind+ médico-3",
    "MIND+_ASTRONAUT_SPACECRAFT": "Mind+ astronauta - nave espacial",
    "MIND+_ASTRONAUT_SPACECRAFT-1": "Mind+ astronauta - nave espacial-1",
    "MIND+_ASTRONAUT_SPACECRAFT-2": "Mind+ astronauta - nave espacial-2",
    "MIND+_ASTRONAUT_SPACECRAFT-3": "Mind+ astronauta - nave espacial-3",
    "MIND+_ASTRONAUT_SPACECRAFT-4": "Mind+ astronauta - nave espacial-4",
    "MIND+_ASTRONAUT_SPACECRAFT-5": "Mind+ astronauta - nave espacial-5",
    "MIND+_ASTRONAUT_STANDING": "Mind+ astronauta - em pé",
    "MIND+_ASTRONAUT_STANDING-1": "Mind+ astronauta - em pé-1",
    "MIND+_ASTRONAUT_STANDING-2": "Mind+ astronauta - em pé-2",
    "BRUSHWOOD": "Tufão",
    "BRUSHWOOD-1": "Tufão-1",
    "BRUSHWOOD-2": "Tufão-2",
    "BRUSHWOOD-3": "Tufão-3",
    "BRUSHWOOD-4": "Tufão-4",
    "STONE": "Pedra",
    "STONE-1": "Pedra-1",
    "STONE-2": "Pedra-2",
    "STONE-3": "Pedra-3",
    "STONE-4": "Pedra-4",
    "TREE": "Árvore",
    "TREE-1": "Árvore-1",
    "TREE-2": "Árvore-2",
    "TREE-3": "Árvore-3",
    "TREE-4": "Árvore-4",
    "COPSE": "Bosque",
    "COPSE-1": "Bosque-1",
    "COPSE-2": "Bosque-2",
    "COPSE-3": "Bosque-3",
    "COPSE-4": "Bosque-4",
    "SNOW_MOUNTAIN": "Montanha de neve",
    "SNOW_MOUNTAIN-1": "Montanha de neve-1",
    "SNOW_MOUNTAIN-2": "Montanha de neve-2",
    "SNOW_MOUNTAIN-3": "Montanha de neve-3",
    "SNOW_MOUNTAIN-4": "Montanha de neve-4",
    "CLOUD": "Nuvem",
    "CLOUD-1": "Nuvem-1",
    "CLOUD-2": "Nuvem-2",
    "CLOUD-3": "Nuvem-3",
    "CLOUD-4": "Nuvem-4",
    "CARTOON_PINK_BLUE_BUTTON_GROUP": "Grupo de botões rosa e azul cartoon",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-1": "Botão de carregamento",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-2": "Botão de reprodução",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-3": "Botão de desligar",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-4": "Botão de continuar",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-5": "Botão de silenciar",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-6": "Botão de iniciar",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-7": "Botão de ligar",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-8": "Botão de configurações",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-9": "Botão de próximo nível",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-10": "Botão de volume",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-11": "Botão de pausa",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-12": "Botão de repetição",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-13": "Setas para cima",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-14": "Setas para baixo",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-15": "Setas para a direita",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-16": "Setas para a esquerda",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP": "Grupo de botões roxos de tecnologia",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-1": "Botão vazio",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-2": "Reproduzir",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-3": "Compartilhar",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-4": "Marcar",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-5": "Desligar",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-6": "Continuar",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-7": "Silenciar",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-8": "Iniciar jogo",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-9": "Cima",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-10": "Configurações",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-11": "Baixo",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-12": "Música",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-13": "Configurações do jogo",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-14": "Direita",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-15": "Pausar",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-16": "Botão retangular vazio",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-17": "Reiniciar",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-18": "Esquerda",
    "DIALOG-1": "Diálogo-1",
    "DIALOG-2": "Diálogo-2",
    "DIALOG-3": "Diálogo-3",
    "DIALOG-4": "Diálogo-4",
    "DIALOG-5": "Diálogo-5",
    "DIALOG-6": "Diálogo-6",
    "DIALOG-7": "Diálogo-7",
    "DIALOG-8": "Diálogo-8",
    "DIALOG-9": "Diálogo-9",
    "DIALOG-10": "Diálogo-10",
    "Epidemic_Prevention_MIND+": "Mind+ vestindo macacão protetor",
    "Epidemic_Prevention_MIND+-1": "Mind+ vestindo macacão protetor-1",
    "Epidemic_Prevention_MIND+-2": "Mind+ vestindo macacão protetor-2",
    "VIRUS": "Vírus",
    "VIRUS-1": "Vírus-a",
    "VIRUS-2": "Vírus-b",
    "VIRUS-3": "Vírus-c",
    "ANTIGEN_REAGENT": "Teste de antígeno",
    "ANTIGEN_REAGENT-1": "Teste de antígeno-horizontal",
    "ANTIGEN_REAGENT-2": "Teste de antígeno-vertical",
    "MASK": "Máscara",
    "MASK-1": "Máscara-1",
    "MASK-2": "Máscara-2",
    "MASK-3": "Máscara-3",
    "MASK-4": "Máscara-4",
    "MASK-5": "Máscara-5",
    "MASK-6": "Máscara-6",
    "TEMPERATURE_MEASURING_GUN": "Termômetro infravermelho",
    "STETHOSCOPE": "Estetoscópio",
    "DISINFECTANT": "Desinfetante",
    "NUMBER-1": 1,
    "NUMBER-2": 2,
    "NUMBER-3": 3,
    "NUMBER-4": 4,
    "NUMBER-5": 5,
    "NUMBER-6": 6,
    "NUMBER-7": 7,
    "NUMBER-8": 8,
    "NUMBER-9": 9,
    "LETTER-A": "A",
    "LETTER-B": "B",
    "LETTER-C": "C",
    "LETTER-D": "D",
    "LETTER-E": "E",
    "LETTER-F": "F",
    "LETTER-G": "G",
    "LETTER-H": "H",
    "LETTER-I": "I",
    "LETTER-J": "J",
    "LETTER-K": "K",
    "LETTER-L": "L",
    "LETTER-M": "M",
    "LETTER-N": "N",
    "LETTER-O": "O",
    "LETTER-P": "P",
    "LETTER-Q": "Q",
    "LETTER-R": "R",
    "LETTER-S": "S",
    "LETTER-T": "T",
    "LETTER-U": "U",
    "LETTER-V": "V",
    "LETTER-W": "W",
    "LETTER-X": "X",
    "LETTER-Y": "Y",
    "LETTER-Z": "Z",
    "EXCLAMATORY_MARK": "Ponto de exclamação",
    "EXCLAMATORY_MARK-1": "Ponto de exclamação-1",
    "EXCLAMATORY_MARK-2": "Ponto de exclamação-2",
    "EXCLAMATORY_MARK-3": "Ponto de exclamação-3",
    "YELLOW_PROGRESS_BAR": "Barra de progresso amarela",
    "YELLOW_PROGRESS_BAR-1": "Barra de progresso amarela-1",
    "YELLOW_PROGRESS_BAR-2": "Barra de progresso amarela-2",
    "YELLOW_PROGRESS_BAR-3": "Barra de progresso amarela-3",
    "PROGRESS_BAR": "Barra de progresso",
    "PROGRESS_BAR-1": "Barra de progresso 30",
    "PROGRESS_BAR-2": "Barra de progresso 60",
    "PROGRESS_BAR-3": "Barra de progresso 100",
    "BLUE_PROGRESS_BAR": "Barra de progresso azul",
    "BLUE_PROGRESS_BAR-1": "Barra de progresso azul-1",
    "BLUE_PROGRESS_BAR-2": "Barra de progresso azul-2",
    "BLUE_PROGRESS_BAR-3": "Barra de progresso azul-3",
    "STEREOSCOPIC_QUESTION_MARK": "Ponto de interrogação 3D",
    "STEREOSCOPIC_QUESTION_MARK-1": "Ponto de interrogação cinza",
    "STEREOSCOPIC_QUESTION_MARK-2": "Ponto de interrogação dourado",
    "BUBBLE_BOX": "Popover",
    "BUBBLE_BOX-1": "Popover-1",
    "BUBBLE_BOX-2": "Popover-2",
    "VICTORY_FAIL": "Vitória e falha",
    "VICTORY_FAIL-1": "Sheng",
    "VICTORY_FAIL-2": "Li",
    "VICTORY_FAIL-3": "Shi",
    "VICTORY_FAIL-4": "Bai",
    "REFERENCE_NO": "Aspas",
    "REFERENCE_NO-1": "Aspas-1",
    "REFERENCE_NO-2": "Aspas-2",
    "RED_STRIPE": "Barra vermelha",
    "GOLD_COIN1": "Moeda de ouro 1",
    "BLUE_STRIPE": "Barra azul",
    "LIFE": "Vida",
    "QUADRANGULAR_STAR": "Estrela de quatro ângulos",
    "QUESTION_MARK": "Ponto de interrogação",
    "FIVE-POINTED_STAR": "Pentagrama",
    "AIRSHIP": "Dirigível 1",
    "METEORITE": "Meteorito",
    "AIRCRAFT": "Avião",
    "AIRCRAFT-1": "Avião-1",
    "AIRCRAFT-2": "Avião-2",
    "AIRCRAFT-3": "Avião-3",
    "RETRO_AIRCRAFT": "Avião retrô",
    "RETRO_AIRCRAFT-1": "Avião retrô-1",
    "RETRO_AIRCRAFT-2": "Avião retrô-2",
    "RETRO_AIRCRAFT-3": "Avião retrô-3",
    "JEEP": "Jeep",
    "JEEP-1": "Jeep-1",
    "JEEP-2": "Jeep-2",
    "JEEP-3": "Jeep-3",
    "MOTORBOAT": "Lancha",
    "MOTORBOAT-1": "Lancha_visão lateral",
    "MOTORBOAT-2": "Lancha_visão superior",
    "MOTORBOAT-3": "Lancha_visão traseira",
    "MOTORBOAT-4": "Lancha_visão frontal",
    "SUBMARINE": "Submarino",
    "SUBMARINE-1": "Submarino_visão lateral",
    "SUBMARINE-2": "Submarino_visão superior",
    "SUBMARINE-3": "Submarino_visão traseira",
    "SUBMARINE-4": "Submarino_visão frontal",
    "SMALL_SAILBOAT": "Veleiro 1",
    "SMALL_SAILBOAT-1": "Veleiro_visão lateral",
    "SMALL_SAILBOAT-2": "Veleiro_visão superior",
    "SMALL_SAILBOAT-3": "Veleiro_visão traseira",
    "SMALL_SAILBOAT-4": "Veleiro_visão frontal",
    "VAN": "Van",
    "VAN-1": "Van_visão lateral",
    "VAN-2": "Van_visão superior",
    "VAN-3": "Van_visão traseira",
    "VAN-4": "Van_visão frontal",
    "HELICOPTER": "Helicóptero",
    "HELICOPTER-1": "Helicóptero_visão lateral",
    "HELICOPTER-2": "Helicóptero_visão superior - 1",
    "HELICOPTER-3": "Helicóptero_visão superior - 2",
    "HELICOPTER-4": "Helicóptero_visão superior - 3",
    "HELICOPTER-5": "Helicóptero_visão traseira",
    "HELICOPTER-6": "Helicóptero_visão frontal",
    "BICYCLE": "Bicicleta - 1",
    "FIRECRACKERS": "Fogos de artifício",
    "FIRECRACKERS-1": "Fogos de artifício - 1",
    "FIRECRACKERS-2": "Fogos de artifício - 2",
    "FIRECRACKERS-3": "Fogos de artifício - 3",
    "COLORFUL_FLAGS": "Bandeira colorida",
    "COLORFUL_FLAGS-1": "Bandeira colorida - 1",
    "COLORFUL_FLAGS-2": "Bandeira colorida - 2",
    "HAPPY_KNOT": "Nó de bênção",
    "HAPPY_KNOT-1": "Nó de bênção - 1",
    "HAPPY_KNOT-2": "Nó de bênção - 2",
    "HAPPY_KNOT-3": "Nó de bênção - 3",
    "HAPPY_KNOT-4": "Nó de bênção - 4",
    "HAPPY_KNOT-5": "Nó de bênção - 5",
    "HAPPY_KNOT-6": "Nó de bênção - 6",
    "RED_ENVELOPE_ANIMATION": "GIF de envelope vermelho",
    "RED_ENVELOPE_ANIMATION-1": "GIF de envelope vermelho - 1",
    "RED_ENVELOPE_ANIMATION-2": "GIF de envelope vermelho - 2",
    "RED_ENVELOPE_ANIMATION-3": "GIF de envelope vermelho - 3",
    "RED_ENVELOPE_ANIMATION-4": "GIF de envelope vermelho - 4",
    "RED_ENVELOPE_ANIMATION-5": "GIF de envelope vermelho - 5",
    "RED_ENVELOPE_ANIMATION-6": "GIF de envelope vermelho - 6",
    "GOLD_COIN": "Moeda de ouro 2",
    "GOLD_COIN-1": "Moeda de ouro - 1",
    "GOLD_COIN-2": "Moeda de ouro - 2",
    "GOLD_COIN-3": "Moeda de ouro - 3",
    "ELK_CART": "Trenó de alce",
    "ELK_CART-1": "Trenó de alce - estático",
    "ELK_CART-2": "Trenó de alce - em movimento",
    "CALENDAR": "Calendário",
    "CHRISTMAS_CANDY": "Bastão de doce de Natal",
    "CHRISTMAS_CANDY-1": "Bastão de doce de Natal - 1",
    "CHRISTMAS_CANDY-2": "Bastão de doce de Natal - 2",
    "CHRISTMAS_CANDY-3": "Bastão de doce de Natal - 3",
    "SANTA_CLAUS": "Papai Noel",
    "SANTA_CLAUS-1": "Papai Noel - 1",
    "SANTA_CLAUS-2": "Papai Noel - 2",
    "SANTA_CLAUS-3": "Papai Noel - 3",
    "SANTA_CLAUS2": "Papai Noel 2",
    "JINGLING_BELL": "Sino de trenó",
    "JINGLING_BELL-1": "Sino de trenó - 1",
    "JINGLING_BELL-2": "Sino de trenó - 2",
    "JINGLING_BELL-3": "Sino de trenó - dourado",
    "JINGLING_BELL-4": "Sino de trenó - prateado",
    "CHRISTMAS_TREE": "Árvore de Natal",
    "CHRISTMAS_TREE-1": "Árvore de Natal - 1",
    "CHRISTMAS_TREE-2": "Árvore de Natal - 2",
    "CHRISTMAS_TREE_DYNAMIC": "Árvore de Natal - GIF",
    "CHRISTMAS_TREE_DYNAMIC-1": "Árvore de Natal - luzes vermelhas",
    "CHRISTMAS_TREE_DYNAMIC-2": "Árvore de Natal - luzes amarelas",
    "CHRISTMAS_TREE_DYNAMIC-3": "Árvore de Natal - luzes apagadas",
    "CHRISTMAS_TREE_DYNAMIC-4": "Árvore de Natal - luzes acesas",
    "CHRISTMAS_STOCKING": "Meia de Natal",
    "CHRISTMAS_STOCKING-1": "Meia de Natal - estrelas verdes em fundo vermelho",
    "CHRISTMAS_STOCKING-2": "Meia de Natal - estrelas vermelhas em fundo verde",
    "CHRISTMAS_STOCKING-3": "Meia de Natal - listras vermelhas",
    "CHRISTMAS_STOCKING-4": "Meia de Natal - listras verdes",
    "CANDY": "Doce",
    "SOCKS": "Meias",
    "SMALL_WINDMILL": "Pequeno catavento",
    "LITTLE_WOODEN_HORSE": "Pequeno cavalo de madeira",
    "SNOWFLAKE": "Floco de neve",
    "SNOWMAN1": "Boneco de neve 1",
    "SNOWMAN1-1": "Boneco de neve - inicial",
    "SNOWMAN1-2": "Boneco de neve - com chapéu",
    "SNOWMAN1-3": "Boneco de neve - com luvas",
    "SNOWMAN1-4": "Boneco de neve - com cachecol",
    "SNOWMAN2": "Boneco de neve 2",
    "SNOWMAN2-1": "Boneco de neve - 1",
    "SNOWMAN2-2": "Boneco de neve - 2",
    "SNOWMAN2-3": "Cabeça de boneco de neve",
    "REINDEER": "Rena",
    "REINDEER-1": "Rena - 1",
    "REINDEER-2": "Rena - 2",
    "REINDEER_CART": "Trenó de rena",
    "REINDEER_CART-1": "Trenó de rena - 1",
    "REINDEER_CART-2": "Trenó de rena - 2",
    "ROUND_LANTERN": "Lanterna redonda",
    "ROUND_LANTERN-1": "Lanterna redonda - 1",
    "ROUND_LANTERN-2": "Lanterna redonda - 2",
    "LONG_LANTERN": "Lanterna longa",
    "LONG_LANTERN-1": "Lanterna longa - 1",
    "LONG_LANTERN-2": "Lanterna longa - 2",
    "PAPER_AIRPLANE": "Avião de papel",
    "PAPER_AIRPLANE-1": "Avião de papel - vermelho",
    "PAPER_AIRPLANE-2": "Avião de papel - amarelo",
    "PAPER_AIRPLANE-3": "Avião de papel - azul",
    "PAPER_AIRPLANE-4": "Avião de papel - verde",
    "CHINESE_KNOT": "Nó chinês",
    "CHINESE_KNOT-1": "Nó chinês - 1",
    "CHINESE_KNOT-2": "Nó chinês - 2",
    "SKIING_PEOPLE": "Esquiando - pessoas",
    "SKIING_PEOPLE-1": "Esquiando - pessoas - saltando 1",
    "SKIING_PEOPLE-2": "Esquiando - pessoas - saltando 2",
    "SKIING_PEOPLE-3": "Esquiando - pessoas - deslizando 1",
    "SKIING_PEOPLE-4": "Esquiando - pessoas - deslizando 2",
    "BOY": "Menino",
    "BOY-1": "Menino - 1",
    "BOY-2": "Menino - 2",
    "BOY-3": "Menino - 3",
    "BOY-4": "Menino - 4",
    "AVATAR_BOY": "Avatar de menino",
    "AVATAR_GIRL": "Avatar de menina",
    "GIRL": "Menina",
    "GIRL-1": "Menina - 1",
    "GIRL-2": "Menina - 2",
    "GIRL-3": "Menina - 3",
    "GIRL-4": "Menina - 4",
    "TURKEY": "Peru",
    "GINGERBREAD_MAN": "Biscoito de gengibre",
    "SKI_MARK": "Marcas de esqui",
    "SKI_MARK-1": "Marcas de esqui - 1",
    "SKI_MARK-2": "Marcas de esqui - 2",
    "SKI_MARK-3": "Marcas de esqui - 3",
    "SKI_MARK-4": "Marcas de esqui - 4",
    "BACKDROPS_2022_SKI-FIELD-1": "Pista de esqui - 1",
    "BACKDROPS_2022_SKI-FIELD-2": "Pista de esqui - 2",
    "BACKDROPS_2022_AIRPORT-RUNWAY": "Pista do aeroporto",
    "BACKDROPS_2022_INTERSECTION": "Interseção",
    "BACKDROPS_2022_SKY-1": "Céu - 1",
    "BACKDROPS_2022_SKY-2": "Céu - 2",
    "BACKDROPS_2022_SETTING-SUN-1": "Pôr do sol - 1",
    "BACKDROPS_2022_SETTING-SUN-2": "Pôr do sol - 2",
    "BACKDROPS_2022_SETTING-SUN-3": "Pôr do sol - 3",
    "BACKDROPS_2022_MARS": "Marte",
    "BACKDROPS_2022_STARRY-SKY-1": "Céu estrelado - 1",
    "BACKDROPS_2022_STARRY-SKY-2": "Céu estrelado - 2",
    "BACKDROPS_2022_STARRY-SKY-3": "Céu estrelado - 3",
    "BACKDROPS_2022_MOON-1": "Lua - 1",
    "BACKDROPS_2022_MOON-2": "Lua - 2",
    "BACKDROPS_2022_SANTA-BACKGROUND": "Fundo do Papai Noel",
    "BACKDROPS_2022_CHRISTMAS-THEME-BACKGROUND": "Fundo de Natal",
    "BACKDROPS_2022_SNOWMAN-BACKGROUND": "Fundo de boneco de neve",
    "WHITE-MOON": "Lua Branca",
    "GRASSLAND": "Campo ",
    "YELLOW-MOON": "Lua Amarela",
    "SKATEBOARD-1": "Skateboard - Lado",
    "SKATEBOARD-2": "Skateboard - Parte Inferior",
    "SKATEBOARD-3": "Skateboard - Parte Superior",
    "SKATEBOARD-4": "Skateboard - Frente",
    "SKATEBOARD": "Skateboard",
    "CARTOON-ARTPLANES-1": "Desenhos Animados - Frente",
    "CARTOON-ARTPLANES-2": "Desenhos Animados - Parte Inferior",
    "CARTOON-ARTPLANES-3": "Desenhos Animados - Direita",
    "CARTOON-ARTPLANES-4": "Desenhos Animados - Esquerda",
    "CARTOON-ARTPLANES-5": "Desenhos Animados - Parte Superior",
    "CARTOON-ARTPLANES-6": "Desenhos Animados - Traseira",
    "CARTOON-ARTPLANES": "Desenhos Animados",
    "BICYCLE-GRAY-1": "Bicicleta-Cinza - Lado",
    "BICYCLE-GRAY-2": "Bicicleta-Cinza - Topo",
    "BICYCLE-GRAY-3": "Bicicleta-Cinza - Frente",
    "BICYCLE-GRAY-4": "Bicicleta-Cinza - Traseira",
    "BICYCLE-GRAY": "Bicicleta-Cinza",
    "BATS-1": "Morcegos-1",
    "BATS-2": "Morcegos-2",
    "BATS-3": "Morcegos-3",
    "BATS-4": "Morcegos-4",
    "BATS": "Morcegos",
    "CASTLE-1": "Castelo-1",
    "CASTLE-2": "Castelo-2",
    "CASTLE": "Castelo",
    "JACK-o'-LANTERN-1": "Abóbora1-1",
    "JACK-o'-LANTERN-2": "Abóbora1-2",
    "JACK-o'-LANTERN-3": "Abóbora1-3",
    "JACK-o'-LANTERN-4": "Abóbora2",
    "JACK-o'-LANTERN-5": "Abóbora3",
    "JACK-o'-LANTERN-6": "Abóbora4",
    "JACK-o'-LANTERN": "Abóbora",
    "WOODS-1": "Bosque-1",
    "WOODS-2": "Bosque-2",
    "WOODS": "Bosque",
    "DANCING-LION-RED-1": "Leão Dançante-Vermelho-1",
    "DANCING-LION-RED-2": "Leão Dançante-Vermelho-2",
    "DANCING-LION-RED-3": "Leão Dançante-Vermelho-3",
    "DANCING-LION-RED-4": "Leão Dançante-Vermelho-4",
    "DANCING-LION-RED-5": "Leão Dançante-Vermelho-5",
    "DANCING-LION-RED-6": "Leão Dançante-Vermelho-6",
    "DANCING-LION-RED": "Leão Dançante-Vermelho",
    "DANCING-LION-BLUE-1": "Leão Dançante-Azul-1",
    "DANCING-LION-BLUE-2": "Leão Dançante-Azul-2",
    "DANCING-LION-BLUE-3": "Leão Dançante-Azul-3",
    "DANCING-LION-BLUE": "Leão Dançante-Azul",
    "DANCING-LION-GREEN-1": "Leão Dançante-Verde-1",
    "DANCING-LION-GREEN-2": "Leão Dançante-Verde-2",
    "DANCING-LION-GREEN-3": "Leão Dançante-Verde-3",
    "DANCING-LION-GREEN": "Leão Dançante-Verde",
    "SPECTRE-1": "Espectro-1",
    "SPECTRE-2": "Espectro-2",
    "SPECTRE-3": "Espectro-3",
    "SPECTRE-4": "Espectro-4",
    "SPECTRE": "Espectro",
    "SPIDER-1": "Aranha-1",
    "SPIDER-2": "Aranha-2",
    "SPIDER-3": "Aranha-3",
    "SPIDER": "Aranha",
    "GOLF-PEOPLE1-1": "Golfe-Pessoas1-1",
    "GOLF-PEOPLE1-2": "Golfe-Pessoas1-2",
    "GOLF-PEOPLE1-3": "Golfe-Pessoas1-3",
    "GOLF-PEOPLE1": "Golfe-Pessoas1",
    "GOLF-PEOPLE2-1": "Golfe-Pessoas2-1",
    "GOLF-PEOPLE2-2": "Golfe-Pessoas2-2",
    "GOLF-PEOPLE2-3": "Golfe-Pessoas2-3",
    "GOLF-PEOPLE2": "Golfe-Pessoas2",
    "GOLF": "Golfe",
    "GOLF-HOLES": "Buracos de Golfe",
    "GOLF-SEATS": "Assentos de Golfe",
    "GREEN-LEAVES-1": "Folhas Verdes-1",
    "GREEN-LEAVES-2": "Folhas Verdes-2",
    "GREEN-LEAVES": "Folhas Verdes",
    "CACTUS-1": "Cacto-1",
    "CACTUS-2": "Cacto-2",
    "CACTUS": "Cacto",
    "FLORETS-1": "Florzinhas-1",
    "FLORETS-2": "Florzinhas-2",
    "FLORETS-3": "Florzinhas-3",
    "FLORETS-4": "Florzinhas-4",
    "FLORETS-5": "Florzinhas-5",
    "FLORETS-6": "Florzinhas-6",
    "FLORETS-7": "Florzinhas-7",
    "FLORETS-8": "Florzinhas-8",
    "FLORETS": "Florzinhas"
  },
  "ro": {
    "gui.backpack.header": "Rucsac",
    "gui.backpack.errorBackpack": "Eroare încărcare rucsac",
    "gui.backpack.loadingBackpack": "Se încarcă...",
    "gui.backpack.more": "Altele",
    "gui.backpack.emptyBackpack": "Rucsacul este gol",
    "gui.gui.costumesTab": "Costume",
    "gui.gui.soundsTab": "Sunete",
    "gui.gui.backdropsTab": "Decoruri",
    "gui.gui.addExtension": "Adaugă o extensie",
    "gui.costumeTab.addCostumeFromLibrary": "Alege un costum",
    "gui.costumeLibrary.chooseACostume": "Alege un costum",
    "gui.costumeTab.addBackdropFromLibrary": "Alege un decor",
    "gui.costumeTab.addBlankCostume": "Desenează",
    "gui.costumeTab.addSurpriseCostume": "Surpriză",
    "gui.costumeTab.addFileBackdrop": "Încarcă decor",
    "gui.costumeTab.addFileCostume": "Încarcă un costum",
    "gui.costumeTab.addCameraCostume": "Cameră",
    "gui.soundEditor.trim": "Decupează",
    "gui.soundEditor.stop": "Stop",
    "gui.soundEditor.sound": "Sunet",
    "gui.soundEditor.play": "Redare",
    "gui.soundEditor.save": "Salvează",
    "gui.soundEditor.undo": "Anulează ultima modificare",
    "gui.soundEditor.redo": "Refă acțiunea anulată",
    "gui.soundEditor.faster": "Mai rapid",
    "gui.soundEditor.slower": "Mai lent",
    "gui.soundEditor.echo": "Ecou",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Mai puternic",
    "gui.soundEditor.softer": "Mai încet",
    "gui.soundEditor.reverse": "Inversează",
    "gui.soundTab.recordSound": "Înregistrare",
    "gui.soundTab.addSoundFromLibrary": "Alege un sunet",
    "gui.soundTab.surpriseSound": "Surpriză",
    "gui.soundTab.fileUploadSound": "Încarcă sunet",
    "gui.controls.stop": "Stop",
    "gui.controls.go": "Mergi",
    "gui.stageHeader.stageSizeUnFull": "Ieși din mod ecran complet",
    "gui.SpriteInfo.show": "Arată",
    "gui.SpriteInfo.size": "Dimensiune",
    "gui.directionPicker.rotationStyles.allAround": "de jur împrejur",
    "gui.directionPicker.rotationStyles.leftRight": "stânga-dreapta",
    "gui.directionPicker.rotationStyles.dontRotate": "nu roti",
    "gui.spriteSelectorItem.contextMenuDuplicate": "duplică",
    "gui.spriteSelectorItem.contextMenuDelete": "șterge",
    "gui.spriteSelectorItem.contextMenuExport": "exportă",
    "gui.spriteSelector.addSpriteFromPaint": "Desenează",
    "gui.spriteSelector.addSpriteFromSurprise": "Surpriză",
    "gui.spriteSelector.addSpriteFromFile": "Încarcă personaj",
    "gui.stageSelector.stage": "Scenă",
    "gui.stageSelector.backdrops": "Decoruri",
    "gui.stageSelector.addBackdropFromPaint": "Desenează",
    "gui.stageSelector.addBackdropFromSurprise": "Surpriză",
    "gui.stageSelector.addBackdropFromFile": "Încarcă decor",
    "gui.modal.back": "Înapoi",
    "gui.library.filterPlaceholder": "Caută",
    "gui.libraryTags.all": "Tot",
    "gui.libraryTags.animals": "Animale",
    "gui.libraryTags.dance": "Dans",
    "gui.libraryTags.effects": "Efecte",
    "gui.libraryTags.fantasy": "Fantezie",
    "gui.libraryTags.fashion": "Modă",
    "gui.libraryTags.food": "Mâncare",
    "gui.libraryTags.indoors": "Interioare",
    "gui.libraryTags.loops": "Repetitive",
    "gui.libraryTags.music": "Muzica",
    "gui.libraryTags.notes": "Note",
    "gui.libraryTags.outdoors": "Exterioare",
    "gui.libraryTags.patterns": "Șabloane",
    "gui.libraryTags.people": "Oameni",
    "gui.libraryTags.percussion": "Percuție",
    "gui.libraryTags.space": "Spațiu",
    "gui.libraryTags.sports": "Sporturi",
    "gui.libraryTags.underwater": "Subacvatic",
    "gui.libraryTags.voice": "Voce",
    "gui.libraryTags.wacky": "Trăznite",
    "gui.libraryTags.animation": "Animații",
    "gui.libraryTags.art": "Arta",
    "gui.libraryTags.games": "Jocuri",
    "gui.libraryTags.stories": "Povești",
    "gui.libraryTags.letters": "Litere",
    "gui.soundLibrary.chooseASound": "Alege un sunet",
    "gui.SpriteInfo.spritePlaceholder": "Numele",
    "gui.cards.more-things-to-try": "Mai multe lucruri de încercat!",
    "gui.cards.see-more": "Vezi mai multe",
    "gui.cards.shrink": "Micșorează",
    "gui.cards.expand": "Extinde",
    "gui.cards.close": "Închide",
    "gui.loader.message1": "Creare blocuri ...",
    "gui.loader.message2": "Se încarcă personaje...",
    "gui.loader.message3": "Se încarcă sunete...",
    "gui.loader.message4": "Se încarcă extensii...",
    "gui.loader.message5": "Adunăm pisici ...",
    "gui.loader.message6": "Îl pieptănăm pe Nano...",
    "gui.loader.message7": "Îl umflăm pe Gobo...",
    "gui.loader.message8": "Pregătim emoji-urile...",
    "gui.loader.headline": "Se încarcă proiectul",
    "gui.cameraModal.cameraModalTitle": "Fă o poză",
    "gui.cameraModal.loadingCameraMessage": "Încărcare cameră...",
    "gui.cameraModal.permissionRequest": "Avem nevoie de permisiunea ta pentru folosirea camerei tale",
    "gui.cameraModal.retakePhoto": "Refă poza",
    "gui.cameraModal.save": "Salvează",
    "gui.cameraModal.takePhoto": "Fă o poză",
    "gui.cameraModal.loadingCaption": "Se încarcă...",
    "gui.cameraModal.enableCameraCaption": "Activează camera",
    "gui.recordModal.title": "Înregistrare sunet",
    "gui.playbackStep.stopMsg": "Stop",
    "gui.playbackStep.playMsg": "Redare",
    "gui.playbackStep.loadingMsg": "Se încarcă...",
    "gui.playbackStep.saveMsg": "Salvează",
    "gui.playbackStep.reRecordMsg": "Re-înregistrare",
    "gui.webglModal.label": "Browser-ul tău nu e compatibil cu WebGL",
    "gui.webglModal.webgllink": "nu e compatibil cu WebGL",
    "gui.prompt.cancel": "Renunţă",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Adaugă o intrare",
    "gui.customProcedures.addAnInputBoolean": "Adaugă o intrare",
    "gui.customProcedures.numberTextType": "număr sau text",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Adaugă o etichetă",
    "gui.customProcedures.runWithoutScreenRefresh": "Rulează fără împrospătarea ecranului",
    "gui.customProcedures.cancel": "Renunţă",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Conectează-ți proiectele cu lumea.",
    "gui.extension.music.name": "Muzica",
    "gui.extension.music.description": "Cântă la instrumente și tobe",
    "gui.extension.pen.name": "Stilou",
    "gui.extension.pen.description": "Desenează cu personajele tale.",
    "gui.gui.variableScopeOptionAllSprites": "Pentru toate personajele",
    "gui.gui.variableScopeOptionSpriteOnly": "Doar pentru acest personaj",
    "gui.gui.variablePromptAllSpritesMessage": "Această variabilă va fi accesibilă tuturor personajelor.",
    "gui.monitor.contextMenu.default": "afișaj normal",
    "gui.monitor.contextMenu.large": "afișare in format mare",
    "gui.monitor.contextMenu.slider": "indicator",
    "gui.monitor.contextMenu.import": "importă",
    "gui.monitor.contextMenu.export": "exportă",
    "gui.opcodeLabels.direction": "direcția",
    "gui.opcodeLabels.xposition": "poziția x",
    "gui.opcodeLabels.yposition": "poziția y",
    "gui.opcodeLabels.size": "mărime",
    "gui.opcodeLabels.costumename": "denumire costum",
    "gui.opcodeLabels.costumenumber": "numărul costumului",
    "gui.opcodeLabels.backdropname": "denumire decor",
    "gui.opcodeLabels.backdropnumber": "nr. decor",
    "gui.opcodeLabels.volume": "volum",
    "gui.opcodeLabels.tempo": "ritm",
    "gui.opcodeLabels.answer": "răspuns",
    "gui.opcodeLabels.loudness": "intensitate sunet",
    "gui.opcodeLabels.year": "anul",
    "gui.opcodeLabels.month": "luna",
    "gui.opcodeLabels.date": "data",
    "gui.opcodeLabels.dayofweek": "ziua săptămânii",
    "gui.opcodeLabels.hour": "ora",
    "gui.opcodeLabels.minute": "minutul",
    "gui.opcodeLabels.second": "secunda",
    "gui.opcodeLabels.timer": "cronometru",
    "music.categoryName": "Muzica",
    "translate.categoryName": "Traducere",
    "pen.categoryName": "Stilou",
    "pen.changeColorParam": "schimbă [COLOR_PARAM] stiloului cu [VALUE]",
    "pen.changeHue": "schimbă culoarea stiloului cu [HUE]",
    "pen.changeShade": "schimbă nuanța stiloului cu [SHADE]",
    "pen.changeSize": "schimbă grosimea stiloului cu [SIZE]",
    "pen.clear": "șterge tot",
    "pen.colorMenu.brightness": "strălucirea",
    "pen.colorMenu.color": "culoarea",
    "pen.colorMenu.saturation": "saturația",
    "pen.colorMenu.transparency": "transparența",
    "pen.penDown": "stilou jos",
    "pen.penUp": "stilou sus",
    "pen.setColor": "setează culoarea stiloului la [COLOR]",
    "pen.setColorParam": "setează [COLOR_PARAM] stiloului la [VALUE]",
    "pen.setHue": "setează culoarea stiloului la [HUE]",
    "pen.setShade": "setează nuanța stiloului la [SHADE]",
    "pen.setSize": "setează grosimea stiloului la [SIZE]",
    "pen.stamp": "ștampila",
    "music.changeTempo": "schimbă ritmul cu [TEMPO]",
    "music.drumBass": "(2) Tobă mare",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Bețe",
    "music.drumClosedHiHat": "(6) Hi-Hat închis",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Cowbell",
    "music.drumCrashCymbal": "(4) Cinel Crash ",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Aplauze",
    "music.drumOpenHiHat": "(5) Hi-Hat deschis",
    "music.drumSideStick": "(3) Bătaie pe margine",
    "music.drumSnare": "(1) Tobă Snare ",
    "music.drumTambourine": "(7) Tamburină",
    "music.drumTriangle": "(12) Trianglu",
    "music.drumVibraslap": " (17) Vibraslap",
    "music.drumWoodBlock": "(10) Bloc de lemn",
    "music.getTempo": "ritm",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Violoncel",
    "music.instrumentChoir": "(15) Cor",
    "music.instrumentClarinet": "(10) Clarinet",
    "music.instrumentElectricGuitar": "(5) Chitară electrică",
    "music.instrumentElectricPiano": "(2) Pian electric",
    "music.instrumentFlute": "(12) Flaut",
    "music.instrumentGuitar": "(4) Chitară",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Cutie muzicală",
    "music.instrumentOrgan": "(3) Orgă",
    "music.instrumentPiano": "(1) Pian",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxofon",
    "music.instrumentSteelDrum": "(18) Tobă din oțel",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Flaut de lemn",
    "music.midiPlayDrumForBeats": "cântă la tobă [DRUM] pentru [BEATS] bătăi",
    "music.midiSetInstrument": "setează instrumentul la [INSTRUMENT]",
    "music.playDrumForBeats": "cântă la tobă [DRUM] pentru [BEATS] bătăi",
    "music.playNoteForBeats": "cântă nota  [NOTE] pentru [BEATS]  bătăi",
    "music.restForBeats": "ia o pauză pentru  [BEATS] bătăi",
    "music.setInstrument": "setează instrument la [INSTRUMENT]",
    "music.setTempo": "setează viteaza la [TEMPO]",
    "videoSensing.categoryName": "Detectare video",
    "videoSensing.direction": "direcția",
    "videoSensing.motion": "mișcarea",
    "videoSensing.off": "oprit",
    "videoSensing.on": "pornit",
    "videoSensing.onFlipped": "pornit în oglindă",
    "videoSensing.setVideoTransparency": "setează transparența video la [TRANSPARENCY]",
    "videoSensing.sprite": "personaj",
    "videoSensing.stage": "scenă",
    "videoSensing.videoOn": "[ATTRIBUTE] video față de [SUBJECT]",
    "videoSensing.videoToggle": "setează video ca [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "când mișcarea video > [REFERENCE]",
    "translate.translateBlock": "tradu [WORDS] în [LANGUAGE]",
    "translate.defaultTextToTranslate": "salut",
    "translate.viewerLanguage": "limbă",
    "text2speech.speakAndWaitBlock": "spune [WORDS]",
    "text2speech.setVoiceBlock": "setează vocea  [VOICE]",
    "text2speech.setLanguageBlock": "setează limba [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "chiţăit",
    "text2speech.giant": "uriaș",
    "text2speech.kitten": "pisicuță",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "când aud [PHRASE]",
    "speech.listenAndWait": "ascultă și așteaptă",
    "speech.defaultWhenIHearValue": "să începem",
    "paint.paintEditor.hue": "Culoare",
    "paint.paintEditor.saturation": "Saturație",
    "paint.paintEditor.brightness": "Strălucire",
    "gui.comingSoon.message1": "Nu-ți face griji, ne ocupăm de asta {emoji}",
    "gui.comingSoon.message2": "În curând...",
    "gui.comingSoon.message3": "Lucrăm la asta {emoji}",
    "paint.paintEditor.fill": "Umplere",
    "paint.paintEditor.costume": "Costum",
    "paint.paintEditor.group": "Grupează",
    "paint.paintEditor.ungroup": "Degrupează",
    "paint.paintEditor.undo": "Anulează ultima modificare",
    "paint.paintEditor.redo": "Refă acțiunea anulată",
    "paint.paintEditor.forward": "În față",
    "paint.paintEditor.backward": "În spate",
    "paint.paintEditor.front": "Primul",
    "paint.paintEditor.back": "Înapoi",
    "paint.paintEditor.more": "Altele",
    "paint.modeTools.brushSize": "Dimensiune",
    "paint.modeTools.eraserSize": "Grosimea radierei",
    "paint.modeTools.copy": "Copiază",
    "paint.modeTools.paste": "Lipește",
    "paint.modeTools.delete": "Șterge",
    "paint.modeTools.curved": "Curbat",
    "paint.modeTools.pointed": "Unghi",
    "paint.modeTools.thickness": "Grosime",
    "paint.modeTools.flipHorizontal": "Întoarce orizontal",
    "paint.modeTools.flipVertical": "Întoarce vertical",
    "paint.modeTools.filled": "Plin",
    "paint.modeTools.outlined": "Contur",
    "paint.paintEditor.bitmap": "Convertește la bitmap",
    "paint.paintEditor.vector": "Convertește în format vectorial",
    "paint.paintEditor.stroke": "Contur",
    "paint.brushMode.brush": "Pensulă",
    "paint.eraserMode.eraser": "Radieră",
    "paint.fillMode.fill": "Umplere",
    "paint.lineMode.line": "Linie",
    "paint.ovalMode.oval": "Cerc",
    "paint.rectMode.rect": "Dreptunghi",
    "paint.reshapeMode.reshape": "Remodelează",
    "paint.roundedRectMode.roundedRect": "Dreptunghi rotunjit",
    "paint.selectMode.select": "Selectează",
    "paint.textMode.text": "Text",
    "paint.colorPicker.swap": "Schimbă",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "ru": {
    "gui.backpack.header": "Рюкзак",
    "gui.backpack.errorBackpack": "Ошибка при загрузке рюкзака",
    "gui.backpack.loadingBackpack": "Загрузка…",
    "gui.backpack.more": "Ещё",
    "gui.backpack.emptyBackpack": "Рюкзак пуст",
    "gui.gui.costumesTab": "Костюмы",
    "gui.gui.soundsTab": "Звуки",
    "gui.gui.backdropsTab": "Фоны",
    "gui.gui.addExtension": "Добавить расширение",
    "gui.costumeTab.addCostumeFromLibrary": "Выбрать костюм",
    "gui.costumeLibrary.chooseACostume": "Выбрать костюм",
    "gui.costumeTab.addBackdropFromLibrary": "Выбрать фон",
    "gui.costumeTab.addBlankCostume": "Рисовать",
    "gui.costumeTab.addSurpriseCostume": "Сюрприз",
    "gui.costumeTab.addFileBackdrop": "Загрузить фон",
    "gui.costumeTab.addFileCostume": "Загрузить костюм",
    "gui.costumeTab.addCameraCostume": "Камера",
    "gui.soundEditor.trim": "Обрезать",
    "gui.soundEditor.stop": "Остановить",
    "gui.soundEditor.sound": "Звук",
    "gui.soundEditor.play": "Играть",
    "gui.soundEditor.save": "Сохранить",
    "gui.soundEditor.undo": "Отменить",
    "gui.soundEditor.redo": "Вернуть",
    "gui.soundEditor.faster": "Быстрее",
    "gui.soundEditor.slower": "Медленнее",
    "gui.soundEditor.echo": "Эхо",
    "gui.soundEditor.robot": "Робот",
    "gui.soundEditor.louder": "Громче",
    "gui.soundEditor.softer": "Тише",
    "gui.soundEditor.reverse": "Развернуть",
    "gui.soundTab.recordSound": "Записать",
    "gui.soundTab.addSoundFromLibrary": "Выбрать звук",
    "gui.soundTab.surpriseSound": "Сюрприз",
    "gui.soundTab.fileUploadSound": "Загрузить звук",
    "gui.controls.stop": "Остановить",
    "gui.controls.go": "Вперед",
    "gui.stageHeader.stageSizeUnFull": "Выйти из полноэкранного режима",
    "gui.SpriteInfo.show": "Показать",
    "gui.SpriteInfo.size": "Размер",
    "gui.directionPicker.rotationStyles.allAround": "Кругом",
    "gui.directionPicker.rotationStyles.leftRight": "Влево/направо",
    "gui.directionPicker.rotationStyles.dontRotate": "Не вращать",
    "gui.spriteSelectorItem.contextMenuDuplicate": "дублировать",
    "gui.spriteSelectorItem.contextMenuDelete": "удалить",
    "gui.spriteSelectorItem.contextMenuExport": "экспорт",
    "gui.spriteSelector.addSpriteFromPaint": "Нарисовать",
    "gui.spriteSelector.addSpriteFromSurprise": "Сюрприз",
    "gui.spriteSelector.addSpriteFromFile": "Загрузить спрайт",
    "gui.stageSelector.stage": "Сцена",
    "gui.stageSelector.backdrops": "Фоны",
    "gui.stageSelector.addBackdropFromPaint": "Нарисовать",
    "gui.stageSelector.addBackdropFromSurprise": "Сюрприз",
    "gui.stageSelector.addBackdropFromFile": "Загрузить фон",
    "gui.modal.back": "Назад",
    "gui.library.filterPlaceholder": "Поиск",
    "gui.libraryTags.all": "Все",
    "gui.libraryTags.animals": "Животные",
    "gui.libraryTags.dance": "Танец",
    "gui.libraryTags.effects": "Эффекты",
    "gui.libraryTags.fantasy": "Фантастика",
    "gui.libraryTags.fashion": "Мода",
    "gui.libraryTags.food": "Еда",
    "gui.libraryTags.indoors": "В помещении",
    "gui.libraryTags.loops": "Циклы",
    "gui.libraryTags.music": "Музыка",
    "gui.libraryTags.notes": "Заметки",
    "gui.libraryTags.outdoors": "На открытом воздухе",
    "gui.libraryTags.patterns": "Узоры",
    "gui.libraryTags.people": "Люди",
    "gui.libraryTags.percussion": "Перкуссия",
    "gui.libraryTags.space": "Космос",
    "gui.libraryTags.sports": "Спорт",
    "gui.libraryTags.underwater": "Подводный мир",
    "gui.libraryTags.voice": "Голос",
    "gui.libraryTags.wacky": "Безумцы",
    "gui.libraryTags.animation": "Анимация",
    "gui.libraryTags.art": "Искусство",
    "gui.libraryTags.games": "Игры",
    "gui.libraryTags.stories": "Истории",
    "gui.libraryTags.letters": "Буквы",
    "gui.soundLibrary.chooseASound": "Выбрать звук",
    "gui.SpriteInfo.spritePlaceholder": "Название",
    "gui.cards.more-things-to-try": "Дополнительные возможности!",
    "gui.cards.see-more": "Узнать больше",
    "gui.cards.shrink": "Свернуть",
    "gui.cards.expand": "Развернуть",
    "gui.cards.close": "Закрыть",
    "gui.loader.message1": "Создание блоков…",
    "gui.loader.message2": "Загрузка спрайтов…",
    "gui.loader.message3": "Загрузка звуков…",
    "gui.loader.message4": "Загрузка расширений…",
    "gui.loader.message5": "Пасём котов…",
    "gui.loader.message6": "Передача наночастиц…",
    "gui.loader.message7": "Надувание гобо…",
    "gui.loader.message8": "Подготовка смайликов…",
    "gui.loader.headline": "Загрузка проекта",
    "gui.cameraModal.cameraModalTitle": "Снять Фото",
    "gui.cameraModal.loadingCameraMessage": "Загрузка камеры…",
    "gui.cameraModal.permissionRequest": "Нам необходимо ваше разрешение для доступа к камере",
    "gui.cameraModal.retakePhoto": "Переснять",
    "gui.cameraModal.save": "Сохранить",
    "gui.cameraModal.takePhoto": "Сделать Фото",
    "gui.cameraModal.loadingCaption": "Загрузка…",
    "gui.cameraModal.enableCameraCaption": "Включите камеру",
    "gui.recordModal.title": "Записать звук",
    "gui.playbackStep.stopMsg": "Остановить",
    "gui.playbackStep.playMsg": "Проиграть",
    "gui.playbackStep.loadingMsg": "Загрузка…",
    "gui.playbackStep.saveMsg": "Сохранить",
    "gui.playbackStep.reRecordMsg": "Перезаписать",
    "gui.webglModal.label": "Ваш браузер не поддерживает WebGL",
    "gui.webglModal.webgllink": "не поддерживает WebGL",
    "gui.prompt.cancel": "Отменить",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Добавить значение",
    "gui.customProcedures.addAnInputBoolean": "Добавить значение",
    "gui.customProcedures.numberTextType": "число или текст",
    "gui.customProcedures.booleanType": "логическое",
    "gui.customProcedures.addALabel": "Добавить подпись",
    "gui.customProcedures.runWithoutScreenRefresh": "Запустить без обновления экрана",
    "gui.customProcedures.cancel": "Отменить",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Свяжи свои проекты с миром",
    "gui.extension.music.name": "Музыка",
    "gui.extension.music.description": "Играть на инструментах и барабанах.",
    "gui.extension.pen.name": "Перо",
    "gui.extension.pen.description": "Рисуй своими спрайтами.",
    "gui.gui.variableScopeOptionAllSprites": "Для всех спрайтов",
    "gui.gui.variableScopeOptionSpriteOnly": "Только для этого спрайта",
    "gui.gui.variablePromptAllSpritesMessage": "Эта переменная будет доступна для всех спрайтов.",
    "gui.monitor.contextMenu.default": "стандартный вид",
    "gui.monitor.contextMenu.large": "крупный вид",
    "gui.monitor.contextMenu.slider": "рычажок",
    "gui.monitor.contextMenu.import": "импорт",
    "gui.monitor.contextMenu.export": "экспорт",
    "gui.opcodeLabels.direction": "направление",
    "gui.opcodeLabels.xposition": "положение x",
    "gui.opcodeLabels.yposition": "положение y",
    "gui.opcodeLabels.size": "размер",
    "gui.opcodeLabels.costumename": "имя костюма",
    "gui.opcodeLabels.costumenumber": "номер костюма",
    "gui.opcodeLabels.backdropname": "имя фона",
    "gui.opcodeLabels.backdropnumber": "номер фона",
    "gui.opcodeLabels.volume": "громкость",
    "gui.opcodeLabels.tempo": "темп",
    "gui.opcodeLabels.answer": "ответ",
    "gui.opcodeLabels.loudness": "громкость",
    "gui.opcodeLabels.year": "год",
    "gui.opcodeLabels.month": "месяц",
    "gui.opcodeLabels.date": "дата",
    "gui.opcodeLabels.dayofweek": "день недели",
    "gui.opcodeLabels.hour": "час",
    "gui.opcodeLabels.minute": "минута",
    "gui.opcodeLabels.second": "секунда",
    "gui.opcodeLabels.timer": "таймер",
    "music.categoryName": "Музыка",
    "translate.categoryName": "Перевести",
    "pen.categoryName": "Перо",
    "pen.changeColorParam": "изменить [COLOR_PARAM] пера на [VALUE]",
    "pen.changeHue": "изменить цвет пера на [HUE]",
    "pen.changeShade": "изменить оттенок пера на [SHADE]",
    "pen.changeSize": "изменить размер пера на [SIZE]",
    "pen.clear": "стереть всё",
    "pen.colorMenu.brightness": "яркость",
    "pen.colorMenu.color": "цвет",
    "pen.colorMenu.saturation": "насыщенность",
    "pen.colorMenu.transparency": "прозрачность",
    "pen.penDown": "опустить перо",
    "pen.penUp": "поднять перо",
    "pen.setColor": "установить для пера цвет [COLOR]",
    "pen.setColorParam": "установить перо [COLOR_PARAM] в [VALUE]",
    "pen.setHue": "установить цвет пера [HUE]",
    "pen.setShade": "установить тень пера [SHADE]",
    "pen.setSize": "установить размер пера [SIZE]",
    "pen.stamp": "печать",
    "music.changeTempo": "изменить темп на [TEMP]",
    "music.drumBass": "(2) Большой барабан",
    "music.drumBongo": "(13) Бонго",
    "music.drumCabasa": "(15) Кабаса",
    "music.drumClaves": "(9) Клаве",
    "music.drumClosedHiHat": "(16) Закрытый Хай-Хэт",
    "music.drumConga": "(14) Конга",
    "music.drumCowbell": "(11) Ковбелл",
    "music.drumCrashCymbal": "(4) Крэш-тарелка",
    "music.drumCuica": "(18) Куика",
    "music.drumGuiro": "(16) Гуиро",
    "music.drumHandClap": "(8) Хлопок",
    "music.drumOpenHiHat": "(5) Открытый хай-хет",
    "music.drumSideStick": "(3) Римшот",
    "music.drumSnare": "(1) Малый барабан",
    "music.drumTambourine": "(7) Бубен",
    "music.drumTriangle": "(12) Треугольник",
    "music.drumVibraslap": "(17) Вибраслэп",
    "music.drumWoodBlock": "(10) Коробочка",
    "music.getTempo": "темп",
    "music.instrumentBass": "(6) Бас",
    "music.instrumentBassoon": "(14) Фагот",
    "music.instrumentCello": "(8) Виолончель",
    "music.instrumentChoir": "(15) Хор",
    "music.instrumentClarinet": "(10) Кларнет",
    "music.instrumentElectricGuitar": "(5) Электрогитара",
    "music.instrumentElectricPiano": "(2) Электрическое пианино",
    "music.instrumentFlute": "(12) Флейта",
    "music.instrumentGuitar": "(4) Гитара",
    "music.instrumentMarimba": "(19) Маримба",
    "music.instrumentMusicBox": "(17) Музыкальная шкатулка",
    "music.instrumentOrgan": "(3) Орган",
    "music.instrumentPiano": "(1) Фортепиано",
    "music.instrumentPizzicato": "(7) Пиццикато",
    "music.instrumentSaxophone": "(11) Саксофон",
    "music.instrumentSteelDrum": "(18) Стальной барабан",
    "music.instrumentSynthLead": "(20) Ведущий синтезатор",
    "music.instrumentSynthPad": "(21) Синтезатор с функциями драм-машины",
    "music.instrumentTrombone": "(9) Тромбон",
    "music.instrumentVibraphone": "(16) Вибрафон",
    "music.instrumentWoodenFlute": "(13) Деревянная флейта",
    "music.midiPlayDrumForBeats": "барабану играть [DRUM]  [BEATS] бита",
    "music.midiSetInstrument": "изменить инструмент на [INSTRUMENT]",
    "music.playDrumForBeats": "барабану играть [DRUM]  [BEATS] бита",
    "music.playNoteForBeats": "играйте ноту [NOTE] в течение [BEATS] бита",
    "music.restForBeats": "пауза в течение [BEATS] бит",
    "music.setInstrument": "изменить инструмент на [INSTRUMENT]",
    "music.setTempo": "изменить темп на [TEMPO]",
    "videoSensing.categoryName": "Видео Распознавание",
    "videoSensing.direction": "направление",
    "videoSensing.motion": "движение",
    "videoSensing.off": "выключить",
    "videoSensing.on": "включить",
    "videoSensing.onFlipped": "включить в обратную",
    "videoSensing.setVideoTransparency": "установить прозрачность видео  [TRANSPARENCY]",
    "videoSensing.sprite": "спрайт",
    "videoSensing.stage": "сцена",
    "videoSensing.videoOn": "видео [ATTRIBUTE]  на [SUBJECT]",
    "videoSensing.videoToggle": "включить видео [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "когда скорость видео > [REFERENCE]",
    "translate.translateBlock": "перевести [WORDS] на [LANGUAGE]",
    "translate.defaultTextToTranslate": "привет",
    "translate.viewerLanguage": "язык",
    "text2speech.speakAndWaitBlock": "сказать [WORDS] ",
    "text2speech.setVoiceBlock": "установить [VOICE] голос",
    "text2speech.setLanguageBlock": "установить [LANGUAGE] язык",
    "text2speech.alto": "альт",
    "text2speech.tenor": "тенор",
    "text2speech.squeak": "писк",
    "text2speech.giant": "гигант",
    "text2speech.kitten": "котенок",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "когда я слышу [PHRASE]",
    "speech.listenAndWait": "слушать и ждать",
    "speech.defaultWhenIHearValue": "начать движение",
    "paint.paintEditor.hue": "Цвет",
    "paint.paintEditor.saturation": "Насыщенность",
    "paint.paintEditor.brightness": "Яркость",
    "gui.comingSoon.message1": "Не волнуйтесь, мы работаем над этим {emoji}",
    "gui.comingSoon.message2": "Скоро…",
    "gui.comingSoon.message3": "Мы работаем над этим {emoji}",
    "paint.paintEditor.fill": "Заливка",
    "paint.paintEditor.costume": "Костюм",
    "paint.paintEditor.group": "Группировать",
    "paint.paintEditor.ungroup": "Разгруппировать",
    "paint.paintEditor.undo": "Отменить",
    "paint.paintEditor.redo": "Переделать",
    "paint.paintEditor.forward": "Вперёд",
    "paint.paintEditor.backward": "Назад",
    "paint.paintEditor.front": "На передний план",
    "paint.paintEditor.back": "Назад",
    "paint.paintEditor.more": "Ещё",
    "paint.modeTools.brushSize": "Размер",
    "paint.modeTools.eraserSize": "Ширина ластика",
    "paint.modeTools.copy": "Копировать",
    "paint.modeTools.paste": "Вставить",
    "paint.modeTools.delete": "Удалить",
    "paint.modeTools.curved": "В кривые",
    "paint.modeTools.pointed": "Заострённые",
    "paint.modeTools.thickness": "Толщина",
    "paint.modeTools.flipHorizontal": "Отразить по горизонтали",
    "paint.modeTools.flipVertical": "Отразить по вертикали",
    "paint.modeTools.filled": "Залитый",
    "paint.modeTools.outlined": "Обведённый",
    "paint.paintEditor.bitmap": "Конвертировать в растровую графику",
    "paint.paintEditor.vector": "Конвертировать в векторную графику",
    "paint.paintEditor.stroke": "Контур",
    "paint.brushMode.brush": "Кисть",
    "paint.eraserMode.eraser": "Ластик",
    "paint.fillMode.fill": "Заливка",
    "paint.lineMode.line": "Линия",
    "paint.ovalMode.oval": "Круг",
    "paint.rectMode.rect": "Прямоугольник",
    "paint.reshapeMode.reshape": "Изменение формы",
    "paint.roundedRectMode.roundedRect": "Скруглённый Прямоугольник",
    "paint.selectMode.select": "Выбрать",
    "paint.textMode.text": "Текст",
    "paint.colorPicker.swap": "Заменить",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "sr": {
    "gui.backpack.header": "Остава",
    "gui.backpack.errorBackpack": "Грешка при учитавању оставе",
    "gui.backpack.loadingBackpack": "Учитавање...",
    "gui.backpack.more": "Више",
    "gui.backpack.emptyBackpack": "Остава је празна",
    "gui.gui.costumesTab": "Костими",
    "gui.gui.soundsTab": "Звукови",
    "gui.gui.backdropsTab": "Позадине",
    "gui.gui.addExtension": "Додај проширење",
    "gui.costumeTab.addCostumeFromLibrary": "Изабери костим",
    "gui.costumeLibrary.chooseACostume": "Изабери костим",
    "gui.costumeTab.addBackdropFromLibrary": "Одабери позадину",
    "gui.costumeTab.addBlankCostume": "Насликај",
    "gui.costumeTab.addSurpriseCostume": "Изненађење",
    "gui.costumeTab.addFileBackdrop": "Пошаљи позадину",
    "gui.costumeTab.addFileCostume": "Пошаљи костим",
    "gui.costumeTab.addCameraCostume": "Камера",
    "gui.soundEditor.trim": "Опсеци",
    "gui.soundEditor.stop": "Заустави",
    "gui.soundEditor.sound": "Звук",
    "gui.soundEditor.play": "Репродукуј",
    "gui.soundEditor.save": "Сачувај",
    "gui.soundEditor.undo": "Опозови",
    "gui.soundEditor.redo": "Понови",
    "gui.soundEditor.faster": "Брже",
    "gui.soundEditor.slower": "Спорије",
    "gui.soundEditor.echo": "Одјек",
    "gui.soundEditor.robot": "Робот",
    "gui.soundEditor.louder": "Гласније",
    "gui.soundEditor.softer": "Мекше",
    "gui.soundEditor.reverse": "Окрени",
    "gui.soundTab.recordSound": "Сними",
    "gui.soundTab.addSoundFromLibrary": "Изабери звук",
    "gui.soundTab.surpriseSound": "Изненађење",
    "gui.soundTab.fileUploadSound": "Пошаљи звук",
    "gui.controls.stop": "Заустави",
    "gui.controls.go": "Крени",
    "gui.stageHeader.stageSizeUnFull": "Изађи из приказа преко целог екрана",
    "gui.SpriteInfo.show": "Прикажи",
    "gui.SpriteInfo.size": "Величина",
    "gui.directionPicker.rotationStyles.allAround": "На све стране",
    "gui.directionPicker.rotationStyles.leftRight": "Лево/десно",
    "gui.directionPicker.rotationStyles.dontRotate": "Без окретања",
    "gui.spriteSelectorItem.contextMenuDuplicate": "умножи",
    "gui.spriteSelectorItem.contextMenuDelete": "обриши",
    "gui.spriteSelectorItem.contextMenuExport": "извези",
    "gui.spriteSelector.addSpriteFromPaint": "Насликај",
    "gui.spriteSelector.addSpriteFromSurprise": "Изненађење",
    "gui.spriteSelector.addSpriteFromFile": "Пошаљи лик",
    "gui.stageSelector.stage": "Позорница",
    "gui.stageSelector.backdrops": "Позадине",
    "gui.stageSelector.addBackdropFromPaint": "Насликај",
    "gui.stageSelector.addBackdropFromSurprise": "Изненађење",
    "gui.stageSelector.addBackdropFromFile": "Пошаљи позадину",
    "gui.modal.back": "Назад",
    "gui.library.filterPlaceholder": "Тражи",
    "gui.libraryTags.all": "Сви",
    "gui.libraryTags.animals": "Животиње",
    "gui.libraryTags.dance": "Плес",
    "gui.libraryTags.effects": "Ефекти",
    "gui.libraryTags.fantasy": "Фантазија",
    "gui.libraryTags.fashion": "Мода",
    "gui.libraryTags.food": "Храна",
    "gui.libraryTags.indoors": "Унутра",
    "gui.libraryTags.loops": "Петље",
    "gui.libraryTags.music": "Музика",
    "gui.libraryTags.notes": "Белешке",
    "gui.libraryTags.outdoors": "Природа",
    "gui.libraryTags.patterns": "Шаблони",
    "gui.libraryTags.people": "Људи",
    "gui.libraryTags.percussion": "Удараљке",
    "gui.libraryTags.space": "Свемир",
    "gui.libraryTags.sports": "Спортови",
    "gui.libraryTags.underwater": "Под водом",
    "gui.libraryTags.voice": "Глас",
    "gui.libraryTags.wacky": "Откачено",
    "gui.libraryTags.animation": "Анимација",
    "gui.libraryTags.art": "Уметност",
    "gui.libraryTags.games": "Игре",
    "gui.libraryTags.stories": "Приче",
    "gui.libraryTags.letters": "Слова",
    "gui.soundLibrary.chooseASound": "Изабери звук",
    "gui.SpriteInfo.spritePlaceholder": "Име",
    "gui.cards.more-things-to-try": "Више ствари за испробавање!",
    "gui.cards.see-more": "Погледај више",
    "gui.cards.shrink": "Умањи",
    "gui.cards.expand": "Увећај",
    "gui.cards.close": "Затвори",
    "gui.loader.message1": "Стварање блокова ...",
    "gui.loader.message2": "Учитавам ликове...",
    "gui.loader.message3": "Учитавам звукове...",
    "gui.loader.message4": "Учитавам проширења...",
    "gui.loader.message5": "Маце у стаду...",
    "gui.loader.message6": "Преносим наное...",
    "gui.loader.message7": "Проширујем гобое...",
    "gui.loader.message8": "Припремам смајлије...",
    "gui.loader.headline": "Учитавам пројекат",
    "gui.cameraModal.cameraModalTitle": "Направи фотографију",
    "gui.cameraModal.loadingCameraMessage": "Учитавам камеру",
    "gui.cameraModal.permissionRequest": "Потребна нам је твоја дозвола да би користили твоју камеру",
    "gui.cameraModal.retakePhoto": "Поново направи фотографију",
    "gui.cameraModal.save": "Сачувај",
    "gui.cameraModal.takePhoto": "Направи фотографију",
    "gui.cameraModal.loadingCaption": "Учитавање...",
    "gui.cameraModal.enableCameraCaption": "Омогући камеру",
    "gui.recordModal.title": "Сними звук",
    "gui.playbackStep.stopMsg": "Заустави",
    "gui.playbackStep.playMsg": "Репродукуј",
    "gui.playbackStep.loadingMsg": "Учитавање...",
    "gui.playbackStep.saveMsg": "Сачувај",
    "gui.playbackStep.reRecordMsg": "Поново сними",
    "gui.webglModal.label": "Твој прегледач интернета не подржава WebGL",
    "gui.webglModal.webgllink": "не подржава WebGL",
    "gui.prompt.cancel": "Откажи",
    "gui.prompt.ok": "У реду",
    "gui.customProcedures.addAnInputNumberText": "Додај улаз",
    "gui.customProcedures.addAnInputBoolean": "Додај унос",
    "gui.customProcedures.numberTextType": "број или текст",
    "gui.customProcedures.booleanType": "булов израз",
    "gui.customProcedures.addALabel": "Додај натпис",
    "gui.customProcedures.runWithoutScreenRefresh": "Изврши без освежавања екрана",
    "gui.customProcedures.cancel": "Откажи",
    "gui.customProcedures.ok": "У реду",
    "gui.extension.microbit.description": "Повежи своје пројекте са светом.",
    "gui.extension.music.name": "Музика",
    "gui.extension.music.description": "Свирај инструменте и бубњеве.",
    "gui.extension.pen.name": "Оловка",
    "gui.extension.pen.description": "Цртај помоћу својих ликова.",
    "gui.gui.variableScopeOptionAllSprites": "За све ликове",
    "gui.gui.variableScopeOptionSpriteOnly": "Само за овај лик",
    "gui.gui.variablePromptAllSpritesMessage": "Променљива ће да буде доступна свим ликовима.",
    "gui.monitor.contextMenu.default": "нормалан приказ",
    "gui.monitor.contextMenu.large": "велики приказ",
    "gui.monitor.contextMenu.slider": "клизач",
    "gui.monitor.contextMenu.import": "увези",
    "gui.monitor.contextMenu.export": "извези",
    "gui.opcodeLabels.direction": "смер",
    "gui.opcodeLabels.xposition": "x положај",
    "gui.opcodeLabels.yposition": "y положај",
    "gui.opcodeLabels.size": "величина",
    "gui.opcodeLabels.costumename": "име костима",
    "gui.opcodeLabels.costumenumber": "број костима",
    "gui.opcodeLabels.backdropname": "име позадине",
    "gui.opcodeLabels.backdropnumber": "број позадине",
    "gui.opcodeLabels.volume": "јачина звука",
    "gui.opcodeLabels.tempo": "темпо",
    "gui.opcodeLabels.answer": "одговор",
    "gui.opcodeLabels.loudness": "јачина звука",
    "gui.opcodeLabels.year": "година",
    "gui.opcodeLabels.month": "месец",
    "gui.opcodeLabels.date": "датум",
    "gui.opcodeLabels.dayofweek": "дан у недељи",
    "gui.opcodeLabels.hour": "сат",
    "gui.opcodeLabels.minute": "минут",
    "gui.opcodeLabels.second": "секунда",
    "gui.opcodeLabels.timer": "тајмер",
    "music.categoryName": "Музика",
    "translate.categoryName": "Преведи",
    "pen.categoryName": "Оловка",
    "pen.changeColorParam": "промени [COLOR_PARAM] оловке за [VALUE]",
    "pen.changeHue": "промени боју оловке за [HUE]",
    "pen.changeShade": "промени сенку оловке за [SHADE]",
    "pen.changeSize": "промени дебљину оловке за [SIZE]",
    "pen.clear": "обриши све",
    "pen.colorMenu.brightness": "осветљеност",
    "pen.colorMenu.color": "боја",
    "pen.colorMenu.saturation": "засићење",
    "pen.colorMenu.transparency": "провидност",
    "pen.penDown": "спусти оловку",
    "pen.penUp": "подигни оловку",
    "pen.setColor": "нека боја оловке буде [COLOR]",
    "pen.setColorParam": "нека [COLOR_PARAM] оловке буде [VALUE]",
    "pen.setHue": "нека боја оловке буде [HUE]",
    "pen.setShade": "нека сенка оловке буде [SHADE]",
    "pen.setSize": "нека дебљина оловке буде [SIZE]",
    "pen.stamp": "печат",
    "music.changeTempo": "промени темпо за [TEMPO]",
    "music.drumBass": "(2) Бас бубањ",
    "music.drumBongo": "(13) Бонго",
    "music.drumCabasa": "(15) Кабаса",
    "music.drumClaves": "(9) Клавес",
    "music.drumClosedHiHat": "(6) Затворена контра-чинела",
    "music.drumConga": "(14) Конга",
    "music.drumCowbell": "(11) Звоно за краве",
    "music.drumCrashCymbal": "(4) Креш чинела",
    "music.drumCuica": "(18) Куика",
    "music.drumGuiro": "(16) Гиро",
    "music.drumHandClap": "(8) Пљесак дланом",
    "music.drumOpenHiHat": "(5) Отворена контра-чинела",
    "music.drumSideStick": "(3) Ивица добоша",
    "music.drumSnare": "(1) Добош",
    "music.drumTambourine": "(7) Даире",
    "music.drumTriangle": "(12) Троугао",
    "music.drumVibraslap": "(17) Вибраслап",
    "music.drumWoodBlock": "(10) Дрвени блок",
    "music.getTempo": "темпо",
    "music.instrumentBass": "(6) Бас",
    "music.instrumentBassoon": "(14) Фагот",
    "music.instrumentCello": "(8) Виолончело",
    "music.instrumentChoir": "(15) Хор",
    "music.instrumentClarinet": "(10) Кларинет",
    "music.instrumentElectricGuitar": "(5) Електрична гитара",
    "music.instrumentElectricPiano": "(2) Електрични клавир",
    "music.instrumentFlute": "(12) Флаута",
    "music.instrumentGuitar": "(4) Гитара",
    "music.instrumentMarimba": "(19) Маримба",
    "music.instrumentMusicBox": "(17) Музичка кутија",
    "music.instrumentOrgan": "(3) Оргуље",
    "music.instrumentPiano": "(1) Клавир",
    "music.instrumentPizzicato": "(7) Пицикато",
    "music.instrumentSaxophone": "(11) Саксофон",
    "music.instrumentSteelDrum": "(18) Метални бубањ",
    "music.instrumentSynthLead": "(20) Синти вођица",
    "music.instrumentSynthPad": "(21) Синти педала",
    "music.instrumentTrombone": "(9) Тромбон",
    "music.instrumentVibraphone": "(16) Вибрафон",
    "music.instrumentWoodenFlute": "(13) Дрвена флаута",
    "music.midiPlayDrumForBeats": "удараљка [DRUM] током [BEATS] откуцаја",
    "music.midiSetInstrument": "нека инструмент буде [INSTRUMENT]",
    "music.playDrumForBeats": "удараљка [DRUM] током [BEATS] откуцаја",
    "music.playNoteForBeats": "свирај ноту [NOTE] током [BEATS] откуцаја",
    "music.restForBeats": "пауза [BEATS] откуцаја",
    "music.setInstrument": "нека инструмент буде [INSTRUMENT]",
    "music.setTempo": "нека темпо буде [TEMPO]",
    "videoSensing.categoryName": "Видео детекција",
    "videoSensing.direction": "смер",
    "videoSensing.motion": "кретање",
    "videoSensing.off": "искључено",
    "videoSensing.on": "укључено",
    "videoSensing.onFlipped": "измени укљученост",
    "videoSensing.setVideoTransparency": "нека провидност видеа буде  [TRANSPARENCY]",
    "videoSensing.sprite": "лик",
    "videoSensing.stage": "позорница",
    "videoSensing.videoOn": "видео [ATTRIBUTE] на [SUBJECT]",
    "videoSensing.videoToggle": "нека видео буде [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "када је кретање видеа > [REFERENCE]",
    "translate.translateBlock": "преведи [WORDS] на [LANGUAGE]",
    "translate.defaultTextToTranslate": "здраво",
    "translate.viewerLanguage": "језик",
    "text2speech.speakAndWaitBlock": "изговори [WORDS]",
    "text2speech.setVoiceBlock": "нека глас буде [VOICE]",
    "text2speech.setLanguageBlock": "нека језик буде [LANGUAGE]",
    "text2speech.alto": "алт",
    "text2speech.tenor": "тенор",
    "text2speech.squeak": "писак",
    "text2speech.giant": "џин",
    "text2speech.kitten": "маца",
    "text2speech.defaultTextToSpeak": "здраво",
    "speech.whenIHear": "када чујем [PHRASE]",
    "speech.listenAndWait": "слушај и чекај",
    "speech.defaultWhenIHearValue": "хајдемо",
    "paint.paintEditor.hue": "Боја",
    "paint.paintEditor.saturation": "Засићење",
    "paint.paintEditor.brightness": "Осветљеност",
    "gui.comingSoon.message1": "Не брини, радимо на томе  {emoji}",
    "gui.comingSoon.message2": "Очекуј убрзо",
    "gui.comingSoon.message3": "Радимо на томе {emoji}",
    "paint.paintEditor.fill": "Попуни",
    "paint.paintEditor.costume": "Костим",
    "paint.paintEditor.group": "Групиши",
    "paint.paintEditor.ungroup": "Разгрупиши",
    "paint.paintEditor.undo": "Опозови",
    "paint.paintEditor.redo": "Понови",
    "paint.paintEditor.forward": "Напред",
    "paint.paintEditor.backward": "Назад",
    "paint.paintEditor.front": "Напред",
    "paint.paintEditor.back": "Назад",
    "paint.paintEditor.more": "Више",
    "paint.modeTools.brushSize": "Величина",
    "paint.modeTools.eraserSize": "Величина брисача",
    "paint.modeTools.copy": "Умножи",
    "paint.modeTools.paste": "Уметни",
    "paint.modeTools.delete": "Обриши",
    "paint.modeTools.curved": "Заобљено",
    "paint.modeTools.pointed": "Шиљаст",
    "paint.modeTools.thickness": "Дебљина",
    "paint.modeTools.flipHorizontal": "Обрни хоризонтално",
    "paint.modeTools.flipVertical": "Обрни вертикално",
    "paint.modeTools.filled": "Попуњено",
    "paint.modeTools.outlined": "Контура",
    "paint.paintEditor.bitmap": "Преведи у растерски облик",
    "paint.paintEditor.vector": "Преведи у векторски облик",
    "paint.paintEditor.stroke": "Контура",
    "paint.brushMode.brush": "Четкица",
    "paint.eraserMode.eraser": "Брисач",
    "paint.fillMode.fill": "Попуни",
    "paint.lineMode.line": "Линија",
    "paint.ovalMode.oval": "Круг",
    "paint.rectMode.rect": "Правоугаоник",
    "paint.reshapeMode.reshape": "Преобликуј",
    "paint.roundedRectMode.roundedRect": "Заобљени правоугаоник",
    "paint.selectMode.select": "Изабери",
    "paint.textMode.text": "Текст",
    "paint.colorPicker.swap": "Замени",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "sk": {
    "gui.backpack.header": "Batoh",
    "gui.backpack.errorBackpack": "Chyba pri otváraní batohu",
    "gui.backpack.loadingBackpack": "Otváram...",
    "gui.backpack.more": "Viac",
    "gui.backpack.emptyBackpack": "Batoh je prázdny",
    "gui.gui.costumesTab": "Kostýmy",
    "gui.gui.soundsTab": "Zvuky",
    "gui.gui.backdropsTab": "Pozadia",
    "gui.gui.addExtension": "Pridaj rozšírenie",
    "gui.costumeTab.addCostumeFromLibrary": "Vyber si kostým",
    "gui.costumeLibrary.chooseACostume": "Vyber si kostým",
    "gui.costumeTab.addBackdropFromLibrary": "Vyber si pozadie",
    "gui.costumeTab.addBlankCostume": "Nakresli",
    "gui.costumeTab.addSurpriseCostume": "Náhodne",
    "gui.costumeTab.addFileBackdrop": "Zo súboru",
    "gui.costumeTab.addFileCostume": "Zo súboru",
    "gui.costumeTab.addCameraCostume": "Odfoť",
    "gui.soundEditor.trim": "Strih",
    "gui.soundEditor.stop": "Zastav",
    "gui.soundEditor.sound": "zvuk",
    "gui.soundEditor.play": "Zahraj",
    "gui.soundEditor.save": "Ulož",
    "gui.soundEditor.undo": "Naspäť",
    "gui.soundEditor.redo": "Znova",
    "gui.soundEditor.faster": "Rýchlejšie",
    "gui.soundEditor.slower": "Pomalšie",
    "gui.soundEditor.echo": "Ozvena",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Hlasnejšie",
    "gui.soundEditor.softer": "Tichšie",
    "gui.soundEditor.reverse": "Odzadu",
    "gui.soundTab.recordSound": "Nahraj",
    "gui.soundTab.addSoundFromLibrary": "Vyber si zvuk",
    "gui.soundTab.surpriseSound": "Náhodne",
    "gui.soundTab.fileUploadSound": "Zo súboru",
    "gui.controls.stop": "Zastav",
    "gui.controls.go": "Spusti",
    "gui.stageHeader.stageSizeUnFull": "Ukonči režim celej obrazovky",
    "gui.SpriteInfo.show": "Ukáž",
    "gui.SpriteInfo.size": "Veľkosť",
    "gui.directionPicker.rotationStyles.allAround": "dokola",
    "gui.directionPicker.rotationStyles.leftRight": "vľavo-vpravo",
    "gui.directionPicker.rotationStyles.dontRotate": "neotáčaj",
    "gui.spriteSelectorItem.contextMenuDuplicate": "duplikuj",
    "gui.spriteSelectorItem.contextMenuDelete": "zruš",
    "gui.spriteSelectorItem.contextMenuExport": "ulož",
    "gui.spriteSelector.addSpriteFromPaint": "Nakresli",
    "gui.spriteSelector.addSpriteFromSurprise": "Náhodne",
    "gui.spriteSelector.addSpriteFromFile": "Zo súboru",
    "gui.stageSelector.stage": "Scéna",
    "gui.stageSelector.backdrops": "Pozadia",
    "gui.stageSelector.addBackdropFromPaint": "Nakresli",
    "gui.stageSelector.addBackdropFromSurprise": "Náhodne",
    "gui.stageSelector.addBackdropFromFile": "Zo súboru",
    "gui.modal.back": "Späť",
    "gui.library.filterPlaceholder": "Hľadať",
    "gui.libraryTags.all": "Všetko",
    "gui.libraryTags.animals": "Zvieratá",
    "gui.libraryTags.dance": "Tanec",
    "gui.libraryTags.effects": "Efekty",
    "gui.libraryTags.fantasy": "Fantázia",
    "gui.libraryTags.fashion": "Móda",
    "gui.libraryTags.food": "Jedlo",
    "gui.libraryTags.indoors": "Vnútri",
    "gui.libraryTags.loops": "Slučky",
    "gui.libraryTags.music": "Hudba",
    "gui.libraryTags.notes": "Noty",
    "gui.libraryTags.outdoors": "Vonku",
    "gui.libraryTags.patterns": "Vzory",
    "gui.libraryTags.people": "Ľudia",
    "gui.libraryTags.percussion": "Bicie",
    "gui.libraryTags.space": "Vesmír",
    "gui.libraryTags.sports": "Šport",
    "gui.libraryTags.underwater": "Pod vodou",
    "gui.libraryTags.voice": "Hlasy",
    "gui.libraryTags.wacky": "Šašoviny",
    "gui.libraryTags.animation": "Animácie",
    "gui.libraryTags.art": "Umenie",
    "gui.libraryTags.games": "Hry",
    "gui.libraryTags.stories": "Príbehy",
    "gui.libraryTags.letters": "Písmená",
    "gui.soundLibrary.chooseASound": "Vyber si zvuk",
    "gui.SpriteInfo.spritePlaceholder": "Meno",
    "gui.cards.more-things-to-try": "Vyskúšaj aj iné",
    "gui.cards.see-more": "Ukáž viac",
    "gui.cards.shrink": "Zbaľ",
    "gui.cards.expand": "Rozbaľ",
    "gui.cards.close": "Ukonči",
    "gui.loader.message1": "Vytváram bloky...",
    "gui.loader.message2": "Otváram postavy...",
    "gui.loader.message3": "Otváram zvuky...",
    "gui.loader.message4": "Otváram rozšírenie...",
    "gui.loader.message5": "Hľadám procesor...",
    "gui.loader.message6": "Prenášam debny...",
    "gui.loader.message7": "Vypúšťam prebytočný vzduch...",
    "gui.loader.message8": "Otváram emoji...",
    "gui.loader.headline": "Otváram projekt",
    "gui.cameraModal.cameraModalTitle": "Odfoť",
    "gui.cameraModal.loadingCameraMessage": "Pripájam kameru...",
    "gui.cameraModal.permissionRequest": "Na použitie kamery potrebujeme váš súhlas",
    "gui.cameraModal.retakePhoto": "Odfoť znova",
    "gui.cameraModal.save": "Ulož",
    "gui.cameraModal.takePhoto": "Odfoť",
    "gui.cameraModal.loadingCaption": "Načítavam...",
    "gui.cameraModal.enableCameraCaption": "Povoľ kameru",
    "gui.recordModal.title": "Nahraj zvuk",
    "gui.playbackStep.stopMsg": "Zastav",
    "gui.playbackStep.playMsg": "Zahraj",
    "gui.playbackStep.loadingMsg": "Otváram...",
    "gui.playbackStep.saveMsg": "Ulož",
    "gui.playbackStep.reRecordMsg": "Nahraj znova",
    "gui.webglModal.label": "Váš prehliadač nepodporuje WebGL.",
    "gui.webglModal.webgllink": "nepodporuje WebGL",
    "gui.prompt.cancel": "Odmietni",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Pridaj vstup",
    "gui.customProcedures.addAnInputBoolean": "Pridaj vstup",
    "gui.customProcedures.numberTextType": "číslo alebo text",
    "gui.customProcedures.booleanType": "logický",
    "gui.customProcedures.addALabel": "Pridaj nápis",
    "gui.customProcedures.runWithoutScreenRefresh": "Vykonaj bez obnovenia obrazovky",
    "gui.customProcedures.cancel": "Odmietni",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Programujme fyzické zariadenia.",
    "gui.extension.music.name": "Hudba",
    "gui.extension.music.description": "Hráme na nástroje a bicie.",
    "gui.extension.pen.name": "Pero",
    "gui.extension.pen.description": "Kreslíme perom.",
    "gui.gui.variableScopeOptionAllSprites": "Pre všetky postavy",
    "gui.gui.variableScopeOptionSpriteOnly": "Len pre túto postavu",
    "gui.gui.variablePromptAllSpritesMessage": "Táto premenná bude dostupná pre všetky postavy",
    "gui.monitor.contextMenu.default": "normálne",
    "gui.monitor.contextMenu.large": "iba hodnota",
    "gui.monitor.contextMenu.slider": "ako posúvač",
    "gui.monitor.contextMenu.import": "načítaj",
    "gui.monitor.contextMenu.export": "ulož",
    "gui.opcodeLabels.direction": "smer",
    "gui.opcodeLabels.xposition": "pozícia x",
    "gui.opcodeLabels.yposition": "pozícia y",
    "gui.opcodeLabels.size": "veľkosť",
    "gui.opcodeLabels.costumename": "meno kostýmu",
    "gui.opcodeLabels.costumenumber": "číslo kostýmu",
    "gui.opcodeLabels.backdropname": "meno pozadia",
    "gui.opcodeLabels.backdropnumber": "číslo pozadia",
    "gui.opcodeLabels.volume": "hlasitosť",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "odpoveď",
    "gui.opcodeLabels.loudness": "hlasitosť",
    "gui.opcodeLabels.year": "rok",
    "gui.opcodeLabels.month": "mesiac",
    "gui.opcodeLabels.date": "deň v mesiaci",
    "gui.opcodeLabels.dayofweek": "deň v týždni",
    "gui.opcodeLabels.hour": "hodina",
    "gui.opcodeLabels.minute": "minúta",
    "gui.opcodeLabels.second": "sekunda",
    "gui.opcodeLabels.timer": "časovač",
    "music.categoryName": "Hudba",
    "translate.categoryName": "prelož",
    "pen.categoryName": "Pero",
    "pen.changeColorParam": "zmeň [COLOR_PARAM] pera o [VALUE]",
    "pen.changeHue": "zmeň farbu pera o [HUE]",
    "pen.changeShade": "zmeň odtieň pera o [SHADE]",
    "pen.changeSize": "zmeň hrúbku pera o [SIZE]",
    "pen.clear": "zmaž",
    "pen.colorMenu.brightness": "jas",
    "pen.colorMenu.color": "farba",
    "pen.colorMenu.saturation": "sýtosť",
    "pen.colorMenu.transparency": "priehľadnosť",
    "pen.penDown": "pero zapni",
    "pen.penUp": "pero vypni",
    "pen.setColor": "nastav farbu pera na [COLOR]",
    "pen.setColorParam": "nastav [COLOR_PARAM] pera na [VALUE]",
    "pen.setHue": "nastav farbu pera na[HUE]",
    "pen.setShade": "nastav odtieň pera na [SHADE]",
    "pen.setSize": "nastav hrúbku pera na [SIZE]",
    "pen.stamp": "opečiatkuj",
    "music.changeTempo": "zmeň tempo o [TEMPO]",
    "music.drumBass": "(2) bubon",
    "music.drumBongo": "(13) bongo",
    "music.drumCabasa": "(15) cabasa",
    "music.drumClaves": "(9) claves",
    "music.drumClosedHiHat": "(6) zatvorená hajka",
    "music.drumConga": "(14) konga",
    "music.drumCowbell": "(11) kravský zvonec",
    "music.drumCrashCymbal": "(4) činely",
    "music.drumCuica": "(18) netlmená cuica",
    "music.drumGuiro": "(16) guiro",
    "music.drumHandClap": "(8) tlesknutie",
    "music.drumOpenHiHat": "(5) otvorená hajka",
    "music.drumSideStick": "(3) okraj bubna",
    "music.drumSnare": "(1) bubienok",
    "music.drumTambourine": "(7) tamburína",
    "music.drumTriangle": "(12) triangel",
    "music.drumVibraslap": "(17) vibraslap",
    "music.drumWoodBlock": "(10) drevený blok",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) kontrabas",
    "music.instrumentBassoon": "(14) fagot",
    "music.instrumentCello": "(8) violončelo",
    "music.instrumentChoir": "(15) zbor",
    "music.instrumentClarinet": "(10) klarinet",
    "music.instrumentElectricGuitar": "(5) elektrická gitara",
    "music.instrumentElectricPiano": "(2) elektrické klávesy",
    "music.instrumentFlute": "(12) flauta",
    "music.instrumentGuitar": "(4) gitara",
    "music.instrumentMarimba": "(19) marimba",
    "music.instrumentMusicBox": "(17) hracia skrinka",
    "music.instrumentOrgan": "(3) organ",
    "music.instrumentPiano": "(1) klavír",
    "music.instrumentPizzicato": "(7) brnkanie prstom",
    "music.instrumentSaxophone": "(11) saxofón",
    "music.instrumentSteelDrum": "(18) plechový bubienok",
    "music.instrumentSynthLead": "(20) syntetizátor sólo",
    "music.instrumentSynthPad": "(21) syntetizátor pozadie",
    "music.instrumentTrombone": "(9) trombón",
    "music.instrumentVibraphone": "(16) vibrafón",
    "music.instrumentWoodenFlute": "(13) zobcová flauta",
    "music.midiPlayDrumForBeats": "zabubnuj [DRUM] taktov [BEATS]",
    "music.midiSetInstrument": "nastav nástroj na [INSTRUMENT]",
    "music.playDrumForBeats": "zabubnuj [DRUM] taktov [BEATS]",
    "music.playNoteForBeats": "zahraj tón [NOTE] taktov [BEATS]",
    "music.restForBeats": "nehraj taktov [BEATS]",
    "music.setInstrument": "nastav nástroj na [INSTRUMENT]",
    "music.setTempo": "nastav tempo na [TEMPO]",
    "videoSensing.categoryName": "Video snímanie",
    "videoSensing.direction": "smer",
    "videoSensing.motion": "pohyb",
    "videoSensing.off": "vypni",
    "videoSensing.on": "zapni",
    "videoSensing.onFlipped": "prepni",
    "videoSensing.setVideoTransparency": "nastav priehľadnosť snímania na [TRANSPARENCY]",
    "videoSensing.sprite": "postava",
    "videoSensing.stage": "scéna",
    "videoSensing.videoOn": "snímaný [ATTRIBUTE] na [SUBJECT]",
    "videoSensing.videoToggle": "[VIDEO_STATE] snímanie",
    "videoSensing.whenMotionGreaterThan": "pri snímanom pohybe > [REFERENCE]",
    "translate.translateBlock": "prelož [WORDS] do [LANGUAGE]",
    "translate.defaultTextToTranslate": "ahoj",
    "translate.viewerLanguage": "jazyk",
    "text2speech.speakAndWaitBlock": "povedz [WORDS]",
    "text2speech.setVoiceBlock": "nastav hlas na [VOICE]",
    "text2speech.setLanguageBlock": "nastav jazyk na [LANGUAGE]",
    "text2speech.alto": "alt",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "pisklavo",
    "text2speech.giant": "obor",
    "text2speech.kitten": "mačiatko",
    "text2speech.defaultTextToSpeak": "ahoj",
    "speech.whenIHear": "pri vete [PHRASE]",
    "speech.listenAndWait": "vypočuj a rozpoznaj",
    "speech.defaultWhenIHearValue": "idem na to",
    "paint.paintEditor.hue": "farba",
    "paint.paintEditor.saturation": "sýtosť",
    "paint.paintEditor.brightness": "jas",
    "gui.comingSoon.message1": "Na tomto ešte pracujeme {emoji}",
    "gui.comingSoon.message2": "Už čoskoro...",
    "gui.comingSoon.message3": "Na tomto ešte pracujeme {emoji}",
    "paint.paintEditor.fill": "výplň",
    "paint.paintEditor.costume": "kostým",
    "paint.paintEditor.group": "Spoj",
    "paint.paintEditor.ungroup": "Rozdeľ",
    "paint.paintEditor.undo": "Naspäť",
    "paint.paintEditor.redo": "Znova",
    "paint.paintEditor.forward": "Dopredu",
    "paint.paintEditor.backward": "Dozadu",
    "paint.paintEditor.front": "Navrch",
    "paint.paintEditor.back": "Naspodok",
    "paint.paintEditor.more": "Viac",
    "paint.modeTools.brushSize": "hrúbka",
    "paint.modeTools.eraserSize": "hrúbka gumy",
    "paint.modeTools.copy": "Kopíruj",
    "paint.modeTools.paste": "Vlož",
    "paint.modeTools.delete": "Zmaž",
    "paint.modeTools.curved": "Krivky",
    "paint.modeTools.pointed": "Hroty",
    "paint.modeTools.thickness": "Hrúbka",
    "paint.modeTools.flipHorizontal": "prevráť vodorovne",
    "paint.modeTools.flipVertical": "prevráť zvislo",
    "paint.modeTools.filled": "výplň",
    "paint.modeTools.outlined": "obrys",
    "paint.paintEditor.bitmap": "zmeň na raster",
    "paint.paintEditor.vector": "zmeň na vektory",
    "paint.paintEditor.stroke": "obrys",
    "paint.brushMode.brush": "štetec",
    "paint.eraserMode.eraser": "guma",
    "paint.fillMode.fill": "výplň",
    "paint.lineMode.line": "úsečka",
    "paint.ovalMode.oval": "elipsa (Shift: Kružnica)",
    "paint.rectMode.rect": "obdĺžnik (Shift: štvorec)",
    "paint.reshapeMode.reshape": "uprav tvar",
    "paint.roundedRectMode.roundedRect": "zaoblený obdĺžnik",
    "paint.selectMode.select": "označ",
    "paint.textMode.text": "text",
    "paint.colorPicker.swap": "zameň farby",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "sl": {
    "gui.backpack.header": "Shramba",
    "gui.backpack.errorBackpack": "Napaka pri nalaganju shrambe",
    "gui.backpack.loadingBackpack": "Nalagam...",
    "gui.backpack.more": "Več",
    "gui.backpack.emptyBackpack": "Shramba je prazna",
    "gui.gui.costumesTab": "Videzi",
    "gui.gui.soundsTab": "Zvoki",
    "gui.gui.backdropsTab": "Ozadja",
    "gui.gui.addExtension": "Dodaj razširitev",
    "gui.costumeTab.addCostumeFromLibrary": "Izberi videz",
    "gui.costumeLibrary.chooseACostume": "Izberi videz",
    "gui.costumeTab.addBackdropFromLibrary": "Izberi ozadje",
    "gui.costumeTab.addBlankCostume": "Nariši",
    "gui.costumeTab.addSurpriseCostume": "Presenečenje",
    "gui.costumeTab.addFileBackdrop": "Naloži ozadje",
    "gui.costumeTab.addFileCostume": "Naloži videz",
    "gui.costumeTab.addCameraCostume": "Spletna kamera",
    "gui.soundEditor.trim": "Odstrani",
    "gui.soundEditor.stop": "Ustavi",
    "gui.soundEditor.sound": "Zvok",
    "gui.soundEditor.play": "Predvajaj",
    "gui.soundEditor.save": "Shrani",
    "gui.soundEditor.undo": "Razveljavi",
    "gui.soundEditor.redo": "Uveljavi",
    "gui.soundEditor.faster": "Hitreje",
    "gui.soundEditor.slower": "Počasneje",
    "gui.soundEditor.echo": "Odmev",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Glasneje",
    "gui.soundEditor.softer": "Mehkeje",
    "gui.soundEditor.reverse": "Obrni",
    "gui.soundTab.recordSound": "Snemaj",
    "gui.soundTab.addSoundFromLibrary": "Izberi zvok",
    "gui.soundTab.surpriseSound": "Presenečenje",
    "gui.soundTab.fileUploadSound": "Naloži zvočni posnetek...",
    "gui.controls.stop": "Ustavi",
    "gui.controls.go": "Pojdi",
    "gui.stageHeader.stageSizeUnFull": "Izhod iz celozaslonskega načina",
    "gui.SpriteInfo.show": "Pokaži",
    "gui.SpriteInfo.size": "Velikost",
    "gui.directionPicker.rotationStyles.allAround": "Vsepovsod",
    "gui.directionPicker.rotationStyles.leftRight": "Levo/Desno",
    "gui.directionPicker.rotationStyles.dontRotate": "Ne obračaj",
    "gui.spriteSelectorItem.contextMenuDuplicate": "podvoji",
    "gui.spriteSelectorItem.contextMenuDelete": "zbriši",
    "gui.spriteSelectorItem.contextMenuExport": "izvozi",
    "gui.spriteSelector.addSpriteFromPaint": "Nariši",
    "gui.spriteSelector.addSpriteFromSurprise": "Presenečenje",
    "gui.spriteSelector.addSpriteFromFile": "Naloži figuro",
    "gui.stageSelector.stage": "Oder",
    "gui.stageSelector.backdrops": "Ozadja",
    "gui.stageSelector.addBackdropFromPaint": "Paint",
    "gui.stageSelector.addBackdropFromSurprise": "Presenečenje",
    "gui.stageSelector.addBackdropFromFile": "Naloži ozadje",
    "gui.modal.back": "Nazaj",
    "gui.library.filterPlaceholder": "Išči",
    "gui.libraryTags.all": "Vse",
    "gui.libraryTags.animals": "Živali",
    "gui.libraryTags.dance": "Pleši",
    "gui.libraryTags.effects": "Učinki",
    "gui.libraryTags.fantasy": "Domišljija",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Hrana",
    "gui.libraryTags.indoors": "Notranjost",
    "gui.libraryTags.loops": "Zanke",
    "gui.libraryTags.music": "Glasba",
    "gui.libraryTags.notes": "Zapiski",
    "gui.libraryTags.outdoors": "Zunanjost",
    "gui.libraryTags.patterns": "Vzorci",
    "gui.libraryTags.people": "Osebe",
    "gui.libraryTags.percussion": "Tolkala",
    "gui.libraryTags.space": "Vesolje",
    "gui.libraryTags.sports": "Šport",
    "gui.libraryTags.underwater": "Pod vodo",
    "gui.libraryTags.voice": "Glas",
    "gui.libraryTags.wacky": "Wacky",
    "gui.libraryTags.animation": "Animacije",
    "gui.libraryTags.art": "Umetnost",
    "gui.libraryTags.games": "Igre",
    "gui.libraryTags.stories": "Zgodbe",
    "gui.libraryTags.letters": "Črke",
    "gui.soundLibrary.chooseASound": "Izberi zvok",
    "gui.SpriteInfo.spritePlaceholder": "Ime",
    "gui.cards.more-things-to-try": "Poskusi še več stvari!",
    "gui.cards.see-more": "Oglej si še",
    "gui.cards.shrink": "Skrči",
    "gui.cards.expand": "Razširi",
    "gui.cards.close": "Zapri",
    "gui.loader.message1": "Ustvarjanje blokov",
    "gui.loader.message2": "Nalagam figure ...",
    "gui.loader.message3": "Nalagam zvoke ...",
    "gui.loader.message4": "Nalagam razširitve ...",
    "gui.loader.message5": "Krotenje mačk ...",
    "gui.loader.message6": "Uf si me pa namučil s to zahtevo ...",
    "gui.loader.message7": "Ja, jaz pa naj delam in delam ... Še malo ...",
    "gui.loader.message8": "Priprava smeškov ...",
    "gui.loader.headline": "Nalagam projekt",
    "gui.cameraModal.cameraModalTitle": "Fotografiraj",
    "gui.cameraModal.loadingCameraMessage": "Nalaganje kamere...",
    "gui.cameraModal.permissionRequest": "Za uporabo tvoje kamere potrebujemo tvoje soglasje ",
    "gui.cameraModal.retakePhoto": "Ponovno fotografiraj",
    "gui.cameraModal.save": "Shrani",
    "gui.cameraModal.takePhoto": "Fotografiraj",
    "gui.cameraModal.loadingCaption": "Nalagam...",
    "gui.cameraModal.enableCameraCaption": "Omogoči kamero",
    "gui.recordModal.title": "Posnemi zvok",
    "gui.playbackStep.stopMsg": "Ustavi",
    "gui.playbackStep.playMsg": "Predvajaj",
    "gui.playbackStep.loadingMsg": "Nalagam ...",
    "gui.playbackStep.saveMsg": "Shrani",
    "gui.playbackStep.reRecordMsg": "Ponovno posnemi",
    "gui.webglModal.label": "Tvoj brskalnik ne podpira WebGL",
    "gui.webglModal.webgllink": "ne podpira WebGL",
    "gui.prompt.cancel": "Prekliči",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Dodaj vnos",
    "gui.customProcedures.addAnInputBoolean": "Dodaj vnos",
    "gui.customProcedures.numberTextType": "število ali besedilo",
    "gui.customProcedures.booleanType": "logične vrednosti",
    "gui.customProcedures.addALabel": "Dodaj oznako",
    "gui.customProcedures.runWithoutScreenRefresh": "Izvajaj brez osveževanja",
    "gui.customProcedures.cancel": "Prekliči",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Poveži svoje projekte s svetom.",
    "gui.extension.music.name": "Glasba",
    "gui.extension.music.description": "Igraj glasbila.",
    "gui.extension.pen.name": "Svinčnik",
    "gui.extension.pen.description": "Riši s figurami.",
    "gui.gui.variableScopeOptionAllSprites": "Za vse figure",
    "gui.gui.variableScopeOptionSpriteOnly": "Samo za to figuro",
    "gui.gui.variablePromptAllSpritesMessage": "Ta spremenljivka bo na voljo vsem figuram.",
    "gui.monitor.contextMenu.default": "navaden prikaz",
    "gui.monitor.contextMenu.large": "velik prikaz",
    "gui.monitor.contextMenu.slider": "drsnik",
    "gui.monitor.contextMenu.import": "uvozi",
    "gui.monitor.contextMenu.export": "izvozi",
    "gui.opcodeLabels.direction": "smer",
    "gui.opcodeLabels.xposition": "položaj x",
    "gui.opcodeLabels.yposition": "položaj y",
    "gui.opcodeLabels.size": "velikost",
    "gui.opcodeLabels.costumename": "ime videza",
    "gui.opcodeLabels.costumenumber": "številka preobleke",
    "gui.opcodeLabels.backdropname": "ime ozadja",
    "gui.opcodeLabels.backdropnumber": "številka ozadja",
    "gui.opcodeLabels.volume": "glasnost",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "odgovor",
    "gui.opcodeLabels.loudness": "glasnost",
    "gui.opcodeLabels.year": "leto",
    "gui.opcodeLabels.month": "mesec",
    "gui.opcodeLabels.date": "datum",
    "gui.opcodeLabels.dayofweek": "dan v tednu",
    "gui.opcodeLabels.hour": "ura",
    "gui.opcodeLabels.minute": "minuta",
    "gui.opcodeLabels.second": "sekunda",
    "gui.opcodeLabels.timer": "štoparica",
    "music.categoryName": "Glasba",
    "translate.categoryName": "Prevedi",
    "pen.categoryName": "Svinčnik",
    "pen.changeColorParam": "spremeni pero [COLOR_PARAM] za [VALUE]",
    "pen.changeHue": "spremeni barvo peresa za [HUE]",
    "pen.changeShade": "spremeni senco peresa za [SHADE]",
    "pen.changeSize": "spremeni velikost peresa za [SIZE]",
    "pen.clear": "izbriši vse",
    "pen.colorMenu.brightness": "svetlost",
    "pen.colorMenu.color": "barva",
    "pen.colorMenu.saturation": "nasičenost",
    "pen.colorMenu.transparency": "prosojnost",
    "pen.penDown": "spusti pero",
    "pen.penUp": "dvigni pero",
    "pen.setColor": "nastavi barvo peresa na [COLOR]",
    "pen.setColorParam": "nastavi pero [COLOR_PARAM] na [VALUE]",
    "pen.setHue": "nastavi barvo peresa na [HUE]",
    "pen.setShade": "nastavi senco peresa na [SHADE]",
    "pen.setSize": "nastavi velikost peresa na [SIZE]",
    "pen.stamp": "žig",
    "music.changeTempo": "spremeni tempo za [TEMPO]",
    "music.drumBass": "(2) Veliki boben",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Ropotulja kabasa",
    "music.drumClaves": "(9) Palčke claves",
    "music.drumClosedHiHat": "(6) zaprte činele",
    "music.drumConga": "(14) Konga bobni",
    "music.drumCowbell": "(11) Kravji zvonec",
    "music.drumCrashCymbal": "(4) Crash činela",
    "music.drumCuica": "(18) Boben Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Plosk",
    "music.drumOpenHiHat": "(5) Enojna činela",
    "music.drumSideStick": "(3) zvok Side Stick",
    "music.drumSnare": "(1) Mali boben",
    "music.drumTambourine": "(7) Tamburin",
    "music.drumTriangle": "(12) Triangel",
    "music.drumVibraslap": "(17) Udarec na vibrafon",
    "music.drumWoodBlock": "(10) Leseni blok",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bas",
    "music.instrumentBassoon": "(14) fagot",
    "music.instrumentCello": "(8) Čelo",
    "music.instrumentChoir": "(15) Zbor",
    "music.instrumentClarinet": "(10) Klarinet",
    "music.instrumentElectricGuitar": "(5) Električna kitara",
    "music.instrumentElectricPiano": "(2) Električni klavir",
    "music.instrumentFlute": "(12) Piščal",
    "music.instrumentGuitar": "(4) Kitara",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Lajna",
    "music.instrumentOrgan": "(3) Orgle",
    "music.instrumentPiano": "(1) Klavir",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saksofon",
    "music.instrumentSteelDrum": "(18) Kovinski boben",
    "music.instrumentSynthLead": "(20) sintetizator - vodilni zvok",
    "music.instrumentSynthPad": "(21) Sintesajzer",
    "music.instrumentTrombone": "(9) Trombon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) lesena piščal",
    "music.midiPlayDrumForBeats": "na bobnu [DRUM] zaigraj [BEATS] udarcev",
    "music.midiSetInstrument": "nastavi inštrument na [INSTRUMENT]",
    "music.playDrumForBeats": "zaigraj na boben [DRUM] za [BEATS] udarcev",
    "music.playNoteForBeats": "predvajaj noto [NOTE] za[BEATS] udarce",
    "music.restForBeats": "pavza za [BEATS] udarcev",
    "music.setInstrument": "nastavi inštrument na [INSTRUMENT]",
    "music.setTempo": "nastavi tempo na [TEMPO]",
    "videoSensing.categoryName": "Video zaznavanje",
    "videoSensing.direction": "smer",
    "videoSensing.motion": "gibanje",
    "videoSensing.off": "izključi",
    "videoSensing.on": "vključi",
    "videoSensing.onFlipped": "vključeno zrcaljenje",
    "videoSensing.setVideoTransparency": "nastavi prosojnost videa na [TRANSPARENCY]",
    "videoSensing.sprite": "figura",
    "videoSensing.stage": "oder",
    "videoSensing.videoOn": "video [ATTRIBUTE] pri [SUBJECT]",
    "videoSensing.videoToggle": "nastavi video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "ko je video premik > [REFERENCE]",
    "translate.translateBlock": "prevedi [WORDS] v [LANGUAGE]",
    "translate.defaultTextToTranslate": "Zdravo",
    "translate.viewerLanguage": "jezik",
    "text2speech.speakAndWaitBlock": "reci [WORDS]",
    "text2speech.setVoiceBlock": "nastavi glas na [VOICE]",
    "text2speech.setLanguageBlock": "nastavi jezik na [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "cviljenje",
    "text2speech.giant": "velikan",
    "text2speech.kitten": "mucek",
    "text2speech.defaultTextToSpeak": "Zdravo",
    "speech.whenIHear": "ko slišim [PHRASE]",
    "speech.listenAndWait": "poslušaj in čakaj",
    "speech.defaultWhenIHearValue": "začnimo",
    "paint.paintEditor.hue": "Barva",
    "paint.paintEditor.saturation": "nasičenost",
    "paint.paintEditor.brightness": "Svetlost",
    "gui.comingSoon.message1": "Ne skrbi, delamo na tem {emoji}",
    "gui.comingSoon.message2": "Kmalu ...",
    "gui.comingSoon.message3": "Delamo na tem {emoji}",
    "paint.paintEditor.fill": "Zapolni",
    "paint.paintEditor.costume": "Videz",
    "paint.paintEditor.group": "Združi",
    "paint.paintEditor.ungroup": "Razdruži",
    "paint.paintEditor.undo": "Razveljavi",
    "paint.paintEditor.redo": "Uveljavi",
    "paint.paintEditor.forward": "naprej",
    "paint.paintEditor.backward": "nazaj",
    "paint.paintEditor.front": "Spredaj",
    "paint.paintEditor.back": "Nazaj",
    "paint.paintEditor.more": "Več",
    "paint.modeTools.brushSize": "Velikost",
    "paint.modeTools.eraserSize": "Širina radirke",
    "paint.modeTools.copy": "Kopiraj",
    "paint.modeTools.paste": "prilepi",
    "paint.modeTools.delete": "Izbriši",
    "paint.modeTools.curved": "Zaobljeno",
    "paint.modeTools.pointed": "Točkovno",
    "paint.modeTools.thickness": "debelina",
    "paint.modeTools.flipHorizontal": "Obrni vodoravno",
    "paint.modeTools.flipVertical": "Obrni navpično",
    "paint.modeTools.filled": "polnitev",
    "paint.modeTools.outlined": "očrtano",
    "paint.paintEditor.bitmap": "Pretvori v bitno sliko",
    "paint.paintEditor.vector": "Pretvori v vektorsko sliko",
    "paint.paintEditor.stroke": "Obroba",
    "paint.brushMode.brush": "Čopič",
    "paint.eraserMode.eraser": "Radirka",
    "paint.fillMode.fill": "Zapolni",
    "paint.lineMode.line": "Črta",
    "paint.ovalMode.oval": "Krog",
    "paint.rectMode.rect": "Pravokotnik",
    "paint.reshapeMode.reshape": "Preoblikuj",
    "paint.roundedRectMode.roundedRect": "Zaokrožen pravokotnik",
    "paint.selectMode.select": "Izberi",
    "paint.textMode.text": "Besedilo",
    "paint.colorPicker.swap": "zamenjaj",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "fi": {
    "gui.backpack.header": "Reppu",
    "gui.backpack.errorBackpack": "Virhe reppua ladattaessa",
    "gui.backpack.loadingBackpack": "Ladataan...",
    "gui.backpack.more": "Enemmän",
    "gui.backpack.emptyBackpack": "Reppu on tyhjä",
    "gui.gui.costumesTab": "Asusteet",
    "gui.gui.soundsTab": "Äänet",
    "gui.gui.backdropsTab": "Taustat",
    "gui.gui.addExtension": "Lisää laajennus",
    "gui.costumeTab.addCostumeFromLibrary": "Valitse asuste",
    "gui.costumeLibrary.chooseACostume": "Valitse asuste",
    "gui.costumeTab.addBackdropFromLibrary": "Valitse tausta",
    "gui.costumeTab.addBlankCostume": "Piirrä",
    "gui.costumeTab.addSurpriseCostume": "Yllätä",
    "gui.costumeTab.addFileBackdrop": "Lataa tausta",
    "gui.costumeTab.addFileCostume": "Lataa asuste",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Leikkaa",
    "gui.soundEditor.stop": "Pysäytä",
    "gui.soundEditor.sound": "Ääni",
    "gui.soundEditor.play": "Toista",
    "gui.soundEditor.save": "Tallenna",
    "gui.soundEditor.undo": "Kumoa",
    "gui.soundEditor.redo": "Tee uudelleen",
    "gui.soundEditor.faster": "Nopeammin",
    "gui.soundEditor.slower": "Hitaammin",
    "gui.soundEditor.echo": "Kaiku",
    "gui.soundEditor.robot": "Robotti",
    "gui.soundEditor.louder": "Voimakkaammin",
    "gui.soundEditor.softer": "Pehmeämmin",
    "gui.soundEditor.reverse": "Vaihda suuntaa",
    "gui.soundTab.recordSound": "Äänitä",
    "gui.soundTab.addSoundFromLibrary": "Valitse ääni",
    "gui.soundTab.surpriseSound": "Yllätä",
    "gui.soundTab.fileUploadSound": "Lataa ääni",
    "gui.controls.stop": "Pysäytä",
    "gui.controls.go": "Mene",
    "gui.stageHeader.stageSizeUnFull": "Poistu koko ruudun tilasta",
    "gui.SpriteInfo.show": "Näytä",
    "gui.SpriteInfo.size": "Koko",
    "gui.directionPicker.rotationStyles.allAround": "Joka suuntaan",
    "gui.directionPicker.rotationStyles.leftRight": "Vasen/Oikea",
    "gui.directionPicker.rotationStyles.dontRotate": "Älä kierrä",
    "gui.spriteSelectorItem.contextMenuDuplicate": "monista",
    "gui.spriteSelectorItem.contextMenuDelete": "poista",
    "gui.spriteSelectorItem.contextMenuExport": "vie",
    "gui.spriteSelector.addSpriteFromPaint": "Piirrä",
    "gui.spriteSelector.addSpriteFromSurprise": "Yllätä",
    "gui.spriteSelector.addSpriteFromFile": "Lataa hahmo",
    "gui.stageSelector.stage": "Esiintymislava",
    "gui.stageSelector.backdrops": "Taustat",
    "gui.stageSelector.addBackdropFromPaint": "Piirrä",
    "gui.stageSelector.addBackdropFromSurprise": "Yllätä",
    "gui.stageSelector.addBackdropFromFile": "Lataa tausta",
    "gui.modal.back": "Takaisin",
    "gui.library.filterPlaceholder": "Haku",
    "gui.libraryTags.all": "Kaikki",
    "gui.libraryTags.animals": "Eläimet",
    "gui.libraryTags.dance": "Tanssi",
    "gui.libraryTags.effects": "Efektit",
    "gui.libraryTags.fantasy": "Fantasia",
    "gui.libraryTags.fashion": "Muoti",
    "gui.libraryTags.food": "Ruoka",
    "gui.libraryTags.indoors": "Sisällä",
    "gui.libraryTags.loops": "Toistorakenteet",
    "gui.libraryTags.music": "Musiikki",
    "gui.libraryTags.notes": "Nuotit",
    "gui.libraryTags.outdoors": "Ulkona",
    "gui.libraryTags.patterns": "Kuviot",
    "gui.libraryTags.people": "Ihmiset",
    "gui.libraryTags.percussion": "Rummut",
    "gui.libraryTags.space": "Avaruus",
    "gui.libraryTags.sports": "Urheilu",
    "gui.libraryTags.underwater": "Vedenalainen",
    "gui.libraryTags.voice": "Ääni",
    "gui.libraryTags.wacky": "Outo",
    "gui.libraryTags.animation": "Animaatio",
    "gui.libraryTags.art": "Taide",
    "gui.libraryTags.games": "Pelit",
    "gui.libraryTags.stories": "Tarinat",
    "gui.libraryTags.letters": "Kirjaimet",
    "gui.soundLibrary.chooseASound": "Valitse ääni",
    "gui.SpriteInfo.spritePlaceholder": "Nimi",
    "gui.cards.more-things-to-try": "Lisää kokeiltavia asioita!",
    "gui.cards.see-more": "Lue lisää",
    "gui.cards.shrink": "Pienennä",
    "gui.cards.expand": "Laajenna",
    "gui.cards.close": "Sulje",
    "gui.loader.message1": "Luodaan lohkoja...",
    "gui.loader.message2": "Hahmoja ladataan...",
    "gui.loader.message3": "Ääniä ladataan...",
    "gui.loader.message4": "Laajennuksia ladataan...",
    "gui.loader.message5": "Paimennetaan kissoja...",
    "gui.loader.message6": "Siirretään nanoja...",
    "gui.loader.message7": "Kasvatetaan goboja...",
    "gui.loader.message8": "Valmistellaan hymiöitä...",
    "gui.loader.headline": "Projektia ladataan",
    "gui.cameraModal.cameraModalTitle": "Ota kuva",
    "gui.cameraModal.loadingCameraMessage": "Kameraa ladataan...",
    "gui.cameraModal.permissionRequest": "Tarvitsemme lupasi, jotta kameraa voidaan käyttää",
    "gui.cameraModal.retakePhoto": "Ota kuva uudelleen",
    "gui.cameraModal.save": "Tallenna",
    "gui.cameraModal.takePhoto": "Ota kuva",
    "gui.cameraModal.loadingCaption": "Ladataan...",
    "gui.cameraModal.enableCameraCaption": "Salli kamera",
    "gui.recordModal.title": "Äänitä ääni",
    "gui.playbackStep.stopMsg": "Pysäytä",
    "gui.playbackStep.playMsg": "Toista",
    "gui.playbackStep.loadingMsg": "Ladataan...",
    "gui.playbackStep.saveMsg": "Tallenna",
    "gui.playbackStep.reRecordMsg": "Äänitä uudelleen",
    "gui.webglModal.label": "Selaimesi ei tue WebGL:ää",
    "gui.webglModal.webgllink": "ei tue WebGL:ää",
    "gui.prompt.cancel": "Peru",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Lisää syöte",
    "gui.customProcedures.addAnInputBoolean": "Lisää syöte",
    "gui.customProcedures.numberTextType": "luku tai teksti",
    "gui.customProcedures.booleanType": "totuusarvo",
    "gui.customProcedures.addALabel": "Lisää nimike",
    "gui.customProcedures.runWithoutScreenRefresh": "Suorita ilman ruudunpäivitystä",
    "gui.customProcedures.cancel": "Peruuta",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Yhdistä projektisi maailman kanssa.",
    "gui.extension.music.name": "Musiikki",
    "gui.extension.music.description": "Soita soittimia ja rumpuja.",
    "gui.extension.pen.name": "Kynä",
    "gui.extension.pen.description": "Piirrä hahmoillasi.",
    "gui.gui.variableScopeOptionAllSprites": "Kaikille hahmoille",
    "gui.gui.variableScopeOptionSpriteOnly": "Vain tälle hahmolle",
    "gui.gui.variablePromptAllSpritesMessage": "Tämä muuttuja on saatavilla kaikille hahmoille.",
    "gui.monitor.contextMenu.default": "normaali näyttö",
    "gui.monitor.contextMenu.large": "suuri näyttö",
    "gui.monitor.contextMenu.slider": "säädin",
    "gui.monitor.contextMenu.import": "tuo",
    "gui.monitor.contextMenu.export": "vie",
    "gui.opcodeLabels.direction": "suunta",
    "gui.opcodeLabels.xposition": "x-sijainti",
    "gui.opcodeLabels.yposition": "y-sijainti",
    "gui.opcodeLabels.size": "koko",
    "gui.opcodeLabels.costumename": "asusteen nimi",
    "gui.opcodeLabels.costumenumber": "asusteen numero",
    "gui.opcodeLabels.backdropname": "taustan nimi",
    "gui.opcodeLabels.backdropnumber": "taustan numero",
    "gui.opcodeLabels.volume": "äänenvoimakkuus",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "vastaus",
    "gui.opcodeLabels.loudness": "äänentaso",
    "gui.opcodeLabels.year": "vuosi",
    "gui.opcodeLabels.month": "kuukausi",
    "gui.opcodeLabels.date": "päiväys",
    "gui.opcodeLabels.dayofweek": "viikonpäivä",
    "gui.opcodeLabels.hour": "tunti",
    "gui.opcodeLabels.minute": "minuutti",
    "gui.opcodeLabels.second": "sekunti",
    "gui.opcodeLabels.timer": "ajastin",
    "music.categoryName": "Musiikki",
    "translate.categoryName": "Käännä",
    "pen.categoryName": "Kynä",
    "pen.changeColorParam": "lisää kynän väriä [COLOR_PARAM] arvolla [VALUE]",
    "pen.changeHue": "lisää kynän väriä arvolla [HUE]",
    "pen.changeShade": "lisää kynän tummuutta arvolla [SHADE]",
    "pen.changeSize": "lisää kynän kokoa arvolla [SIZE]",
    "pen.clear": "pyyhi kaikki",
    "pen.colorMenu.brightness": "kirkkaus",
    "pen.colorMenu.color": "väri",
    "pen.colorMenu.saturation": "värikylläisyys",
    "pen.colorMenu.transparency": "läpinäkyvyys",
    "pen.penDown": "kynä alas",
    "pen.penUp": "kynä ylös",
    "pen.setColor": "aseta kynän väriksi [COLOR]",
    "pen.setColorParam": "aseta kynän värin [COLOR_PARAM] arvoksi [VALUE]",
    "pen.setHue": "aseta kynän väriksi [HUE]",
    "pen.setShade": "aseta kynän tummuudeksi [SHADE]",
    "pen.setSize": "aseta kynän kooksi [SIZE]",
    "pen.stamp": "leimaa",
    "music.changeTempo": "lisää tempoa arvolla [TEMPO]",
    "music.drumBass": "(2) Bassorumpu",
    "music.drumBongo": "(13) Bongo-rumpu",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Rytmikapulat",
    "music.drumClosedHiHat": "(6) Suljettu hi-hat",
    "music.drumConga": "(14) Conga-rumpu",
    "music.drumCowbell": "(11) Lehmänkello",
    "music.drumCrashCymbal": "(4) Crash-lautanen",
    "music.drumCuica": "(18) Cuica-rumpu",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Taputus",
    "music.drumOpenHiHat": "(5) Avoin hi-hat",
    "music.drumSideStick": "(3) Sivuisku rumpuun",
    "music.drumSnare": "(1) Virvelirumpu",
    "music.drumTambourine": "(7) Tamburiini",
    "music.drumTriangle": "(12) Triangeli",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Puupalikka",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Basso",
    "music.instrumentBassoon": "(14) Fagotti",
    "music.instrumentCello": "(8) Sello",
    "music.instrumentChoir": "(15) Kuoro",
    "music.instrumentClarinet": "(10) Klarinetti",
    "music.instrumentElectricGuitar": "(5) Sähkökitara",
    "music.instrumentElectricPiano": "(2) Sähköpiano",
    "music.instrumentFlute": "(12) Huilu",
    "music.instrumentGuitar": "(4) Kitara",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Soittorasia",
    "music.instrumentOrgan": "(3) Urut",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Näppäily",
    "music.instrumentSaxophone": "(11) Saksofoni",
    "music.instrumentSteelDrum": "(18) Tynnyrirumpu",
    "music.instrumentSynthLead": "(20) Syntetisaattori (lead-efekti)",
    "music.instrumentSynthPad": "(21) Syntetisaattori (pad-efekti)",
    "music.instrumentTrombone": "(9) Pasuuna",
    "music.instrumentVibraphone": "(16) Vibrafoni",
    "music.instrumentWoodenFlute": "(13) Puuhuilu",
    "music.midiPlayDrumForBeats": "soita rumpua [DRUM] [BEATS] iskun ajan",
    "music.midiSetInstrument": "aseta soittimeksi [INSTRUMENT]",
    "music.playDrumForBeats": "soita rumpua [DRUM] [BEATS] iskun ajan",
    "music.playNoteForBeats": "soita nuottia [NOTE] [BEATS] iskun ajan",
    "music.restForBeats": "tauko [BEATS] iskun ajan",
    "music.setInstrument": "aseta soittimeksi [INSTRUMENT]",
    "music.setTempo": "aseta tempoksi [TEMPO]",
    "videoSensing.categoryName": "Videohavainnointi",
    "videoSensing.direction": "suunta",
    "videoSensing.motion": "liike",
    "videoSensing.off": "pois",
    "videoSensing.on": "päälle",
    "videoSensing.onFlipped": "käännettäessä",
    "videoSensing.setVideoTransparency": "aseta videon läpinäkyvyys arvoon [TRANSPARENCY]",
    "videoSensing.sprite": "hahmo",
    "videoSensing.stage": "esiintymislava",
    "videoSensing.videoOn": "[SUBJECT]n [ATTRIBUTE] videolla",
    "videoSensing.videoToggle": "kytke video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "kun videon liike > [REFERENCE]",
    "translate.translateBlock": "käännä [WORDS] kielelle [LANGUAGE]",
    "translate.defaultTextToTranslate": "hei",
    "translate.viewerLanguage": "kieli",
    "text2speech.speakAndWaitBlock": "puhu [WORDS]",
    "text2speech.setVoiceBlock": "aseta ääneksi [VOICE]",
    "text2speech.setLanguageBlock": "aseta kieleksi [LANGUAGE]",
    "text2speech.alto": "altto",
    "text2speech.tenor": "tenori",
    "text2speech.squeak": "vingahdus",
    "text2speech.giant": "jätti",
    "text2speech.kitten": "kissanpentu",
    "text2speech.defaultTextToSpeak": "hei",
    "speech.whenIHear": "kun kuulen [PHRASE]",
    "speech.listenAndWait": "kuuntele ja odota",
    "speech.defaultWhenIHearValue": "mennään",
    "paint.paintEditor.hue": "Väri",
    "paint.paintEditor.saturation": "Värikylläisyys",
    "paint.paintEditor.brightness": "Kirkkaus",
    "gui.comingSoon.message1": "Älä huoli, toteutamme sitä {emoji}",
    "gui.comingSoon.message2": "Tulossa pian...",
    "gui.comingSoon.message3": "Toteutamme sitä {emoji}",
    "paint.paintEditor.fill": "Täytä",
    "paint.paintEditor.costume": "Asuste",
    "paint.paintEditor.group": "Ryhmitä",
    "paint.paintEditor.ungroup": "Pura ryhmitys",
    "paint.paintEditor.undo": "Kumoa",
    "paint.paintEditor.redo": "Tee uudelleen",
    "paint.paintEditor.forward": "Eteenpäin",
    "paint.paintEditor.backward": "Taaksepäin",
    "paint.paintEditor.front": "Eteen",
    "paint.paintEditor.back": "Taakse",
    "paint.paintEditor.more": "Enemmän",
    "paint.modeTools.brushSize": "Koko",
    "paint.modeTools.eraserSize": "Kumin koko",
    "paint.modeTools.copy": "Kopioi",
    "paint.modeTools.paste": "Liitä",
    "paint.modeTools.delete": "Poista",
    "paint.modeTools.curved": "Kaartuva",
    "paint.modeTools.pointed": "Teräväkärkinen",
    "paint.modeTools.thickness": "Paksuus",
    "paint.modeTools.flipHorizontal": "Käännä vaakasuoraan",
    "paint.modeTools.flipVertical": "Käännä pystysuoraan",
    "paint.modeTools.filled": "Täytetty",
    "paint.modeTools.outlined": "Ääriviivallinen",
    "paint.paintEditor.bitmap": "Muunna bittikartaksi",
    "paint.paintEditor.vector": "Muunna vektoriksi",
    "paint.paintEditor.stroke": "Ääriviiva",
    "paint.brushMode.brush": "Sivellin",
    "paint.eraserMode.eraser": "Pyyhekumi",
    "paint.fillMode.fill": "Täytä",
    "paint.lineMode.line": "Viiva",
    "paint.ovalMode.oval": "Ympyrä",
    "paint.rectMode.rect": "Suorakulmio",
    "paint.reshapeMode.reshape": "Muotoile uudelleen",
    "paint.roundedRectMode.roundedRect": "Pyöristetty suorakulmio",
    "paint.selectMode.select": "Valitse",
    "paint.textMode.text": "Teksti",
    "paint.colorPicker.swap": "Vaihda",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "sv": {
    "gui.backpack.header": "Ryggsäck",
    "gui.backpack.errorBackpack": "Problem att ladda ryggsäcken",
    "gui.backpack.loadingBackpack": "Laddar...",
    "gui.backpack.more": "Mer",
    "gui.backpack.emptyBackpack": "Ryggsäcken är tom",
    "gui.gui.costumesTab": "Klädslar",
    "gui.gui.soundsTab": "Ljud",
    "gui.gui.backdropsTab": "Bakgrunder",
    "gui.gui.addExtension": "Lägg till ett tillägg",
    "gui.costumeTab.addCostumeFromLibrary": "Välj en klädsel",
    "gui.costumeLibrary.chooseACostume": "Välj en klädsel",
    "gui.costumeTab.addBackdropFromLibrary": "Välj en bakgrund",
    "gui.costumeTab.addBlankCostume": "Måla",
    "gui.costumeTab.addSurpriseCostume": "Överraska",
    "gui.costumeTab.addFileBackdrop": "Ladda upp bakgrund",
    "gui.costumeTab.addFileCostume": "Ladda upp klädsel",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Trimma",
    "gui.soundEditor.stop": "Stoppa",
    "gui.soundEditor.sound": "Ljud",
    "gui.soundEditor.play": "Spela upp",
    "gui.soundEditor.save": "Spara",
    "gui.soundEditor.undo": "Ångra",
    "gui.soundEditor.redo": "Gör om",
    "gui.soundEditor.faster": "Snabbare",
    "gui.soundEditor.slower": "Långsammare",
    "gui.soundEditor.echo": "Eko",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Högre",
    "gui.soundEditor.softer": "Lägre",
    "gui.soundEditor.reverse": "Baklänges",
    "gui.soundTab.recordSound": "Spela in",
    "gui.soundTab.addSoundFromLibrary": "Välj ett ljud",
    "gui.soundTab.surpriseSound": "Överraska",
    "gui.soundTab.fileUploadSound": "Ladda upp ljud från fil",
    "gui.controls.stop": "Stoppa",
    "gui.controls.go": "Starta",
    "gui.stageHeader.stageSizeUnFull": "Gå ur fullskärmsläge",
    "gui.SpriteInfo.show": "Visa",
    "gui.SpriteInfo.size": "Storlek",
    "gui.directionPicker.rotationStyles.allAround": "Rotera",
    "gui.directionPicker.rotationStyles.leftRight": "Vänster/Höger",
    "gui.directionPicker.rotationStyles.dontRotate": "Rotera inte",
    "gui.spriteSelectorItem.contextMenuDuplicate": "kopiera",
    "gui.spriteSelectorItem.contextMenuDelete": "radera",
    "gui.spriteSelectorItem.contextMenuExport": "exportera",
    "gui.spriteSelector.addSpriteFromPaint": "Måla",
    "gui.spriteSelector.addSpriteFromSurprise": "Överraska",
    "gui.spriteSelector.addSpriteFromFile": "Ladda upp sprajt",
    "gui.stageSelector.stage": "Scen",
    "gui.stageSelector.backdrops": "Bakgrunder",
    "gui.stageSelector.addBackdropFromPaint": "Måla",
    "gui.stageSelector.addBackdropFromSurprise": "Överraska",
    "gui.stageSelector.addBackdropFromFile": "Ladda upp bakgrund",
    "gui.modal.back": "Tillbaka",
    "gui.library.filterPlaceholder": "Sök",
    "gui.libraryTags.all": "Alla",
    "gui.libraryTags.animals": "Djur",
    "gui.libraryTags.dance": "Dansa",
    "gui.libraryTags.effects": "Effekter",
    "gui.libraryTags.fantasy": "Fantasy",
    "gui.libraryTags.fashion": "Mode",
    "gui.libraryTags.food": "Mat",
    "gui.libraryTags.indoors": "Inomhus",
    "gui.libraryTags.loops": "Loopar",
    "gui.libraryTags.music": "Musik",
    "gui.libraryTags.notes": "Toner",
    "gui.libraryTags.outdoors": "Utomhus",
    "gui.libraryTags.patterns": "Mönster",
    "gui.libraryTags.people": "Människor",
    "gui.libraryTags.percussion": "Slagverk",
    "gui.libraryTags.space": "Rymden",
    "gui.libraryTags.sports": "Sport",
    "gui.libraryTags.underwater": "Under vatten",
    "gui.libraryTags.voice": "Röst",
    "gui.libraryTags.wacky": "Knäppt",
    "gui.libraryTags.animation": "Animation",
    "gui.libraryTags.art": "Konst",
    "gui.libraryTags.games": "Spel",
    "gui.libraryTags.stories": "Berättelser",
    "gui.libraryTags.letters": "Bokstäver",
    "gui.soundLibrary.chooseASound": "Välj ett ljud",
    "gui.SpriteInfo.spritePlaceholder": "Namn",
    "gui.cards.more-things-to-try": "Fler saker att pröva!",
    "gui.cards.see-more": "Läs mera",
    "gui.cards.shrink": "Förminska",
    "gui.cards.expand": "Förstora",
    "gui.cards.close": "Stäng",
    "gui.loader.message1": "Skapa block ...",
    "gui.loader.message2": "Laddar sprajtar...",
    "gui.loader.message3": "Laddar ljud...",
    "gui.loader.message4": "Laddar tillägg...",
    "gui.loader.message5": "Samlar katter...",
    "gui.loader.message6": "Överför nanos...",
    "gui.loader.message7": "Förstorar gobos...",
    "gui.loader.message8": "Förbereder emojis ...",
    "gui.loader.headline": "Laddar projekt",
    "gui.cameraModal.cameraModalTitle": "Ta ett foto",
    "gui.cameraModal.loadingCameraMessage": "Laddar kamera...",
    "gui.cameraModal.permissionRequest": "Vi behöver ditt tillstånd för att använda din kamera",
    "gui.cameraModal.retakePhoto": "Ta om foto",
    "gui.cameraModal.save": "Spara",
    "gui.cameraModal.takePhoto": "Ta foto",
    "gui.cameraModal.loadingCaption": "Laddar...",
    "gui.cameraModal.enableCameraCaption": "Aktivera kameran",
    "gui.recordModal.title": "Spela in nytt ljud",
    "gui.playbackStep.stopMsg": "Stoppa",
    "gui.playbackStep.playMsg": "Spela upp",
    "gui.playbackStep.loadingMsg": "Laddar...",
    "gui.playbackStep.saveMsg": "Spara",
    "gui.playbackStep.reRecordMsg": "Spela in igen",
    "gui.webglModal.label": "Din webbläsare stödjer inte WebGL",
    "gui.webglModal.webgllink": "stödjer inte webGL",
    "gui.prompt.cancel": "Avbryt",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Lägg till en indata",
    "gui.customProcedures.addAnInputBoolean": "Lägg till en indata",
    "gui.customProcedures.numberTextType": "siffror eller text",
    "gui.customProcedures.booleanType": "boolesk",
    "gui.customProcedures.addALabel": "Lägg till en etikett",
    "gui.customProcedures.runWithoutScreenRefresh": "Kör utan skärmuppdatering",
    "gui.customProcedures.cancel": "Avbryt",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Koppla upp dina projekt med världen.",
    "gui.extension.music.name": "Musik",
    "gui.extension.music.description": "Spela instrument och trummor.",
    "gui.extension.pen.name": "Penna",
    "gui.extension.pen.description": "Låt dina sprajts rita.",
    "gui.gui.variableScopeOptionAllSprites": "För alla sprajtar",
    "gui.gui.variableScopeOptionSpriteOnly": "Enbart för denna sprajt",
    "gui.gui.variablePromptAllSpritesMessage": "Variabeln blir tillgänglig för alla sprajtar.",
    "gui.monitor.contextMenu.default": "normal avläsning",
    "gui.monitor.contextMenu.large": "stor avläsning",
    "gui.monitor.contextMenu.slider": "reglage",
    "gui.monitor.contextMenu.import": "importera",
    "gui.monitor.contextMenu.export": "exportera",
    "gui.opcodeLabels.direction": "riktning",
    "gui.opcodeLabels.xposition": "x position",
    "gui.opcodeLabels.yposition": "y position",
    "gui.opcodeLabels.size": "storlek",
    "gui.opcodeLabels.costumename": "klädselnamn",
    "gui.opcodeLabels.costumenumber": "klädselnummer",
    "gui.opcodeLabels.backdropname": "bakgrundsnamn",
    "gui.opcodeLabels.backdropnumber": "bakgrundsnummer",
    "gui.opcodeLabels.volume": "volym ",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "svar",
    "gui.opcodeLabels.loudness": "ljudstyrka",
    "gui.opcodeLabels.year": "år ",
    "gui.opcodeLabels.month": "månad ",
    "gui.opcodeLabels.date": "dag ",
    "gui.opcodeLabels.dayofweek": "veckodag ",
    "gui.opcodeLabels.hour": "timmar ",
    "gui.opcodeLabels.minute": "minuter ",
    "gui.opcodeLabels.second": "sekunder ",
    "gui.opcodeLabels.timer": "timer",
    "music.categoryName": "Musik",
    "translate.categoryName": "Översätta",
    "pen.categoryName": "Penna",
    "pen.changeColorParam": "ändra pennans [COLOR_PARAM]med [VALUE]",
    "pen.changeHue": "ändra pennans färg med [HUE]",
    "pen.changeShade": "ändra pennans skugga med [SHADE]",
    "pen.changeSize": "ändra pennans storlek med [SIZE]",
    "pen.clear": "radera allt",
    "pen.colorMenu.brightness": "ljusstyrka",
    "pen.colorMenu.color": "färg",
    "pen.colorMenu.saturation": "mättnad",
    "pen.colorMenu.transparency": "genomskinlighet",
    "pen.penDown": "penna ned",
    "pen.penUp": "penna upp",
    "pen.setColor": "sätt pennans färg [COLOR]",
    "pen.setColorParam": "sätt penna [COLOR_PARAM]till [VALUE]",
    "pen.setHue": "sätt pennans färg till [HUE]",
    "pen.setShade": "sätt pennans skugga till [SHADE]",
    "pen.setSize": "sätt pennans storlek [SIZE]",
    "pen.stamp": "stämpla",
    "music.changeTempo": "ändra tempo med [TEMPO]",
    "music.drumBass": "(2) Bastrumma",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Stängd Hi-Hat",
    "music.drumConga": "(14) Congas",
    "music.drumCowbell": "(11) Koskälla",
    "music.drumCrashCymbal": "(4) Crashcymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Handklapp",
    "music.drumOpenHiHat": "(5) Öppen Hi-Hat",
    "music.drumSideStick": "(3) Kantslag",
    "music.drumSnare": "(1) Virveltrumma",
    "music.drumTambourine": "(7) Tamburin",
    "music.drumTriangle": "(12) Triangel",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Träblock",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bas",
    "music.instrumentBassoon": "(14) Fagott",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Kör",
    "music.instrumentClarinet": "(10) Klarinett",
    "music.instrumentElectricGuitar": "(5) Elgitarr",
    "music.instrumentElectricPiano": "(2) Elpiano",
    "music.instrumentFlute": "(12) Flöjt",
    "music.instrumentGuitar": "(4) Gitarr",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Speldosa",
    "music.instrumentOrgan": "(3) Orgel",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxofon",
    "music.instrumentSteelDrum": "(18) Steel Drum",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Träflöjt",
    "music.midiPlayDrumForBeats": "spela trumma [DRUM]i [BEATS] takter",
    "music.midiSetInstrument": "sätt instrument till [INSTRUMENT]",
    "music.playDrumForBeats": "spela trumma [DRUM]i [BEATS] takter",
    "music.playNoteForBeats": "spela not [NOTE]i [BEATS]takter",
    "music.restForBeats": "pausa i [BEATS]takter",
    "music.setInstrument": "sätt instrument till [INSTRUMENT]",
    "music.setTempo": "sätt tempo till [TEMPO]",
    "videoSensing.categoryName": "Video - Känna av",
    "videoSensing.direction": "riktning",
    "videoSensing.motion": "rörelse",
    "videoSensing.off": "av",
    "videoSensing.on": "på",
    "videoSensing.onFlipped": "vid förändring",
    "videoSensing.setVideoTransparency": "sätt videotransparens till [TRANSPARENCY]",
    "videoSensing.sprite": "sprajt",
    "videoSensing.stage": "scen",
    "videoSensing.videoOn": "video [ATTRIBUTE]på [SUBJECT]",
    "videoSensing.videoToggle": "sätt video till [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "när videorörelse > [REFERENCE]",
    "translate.translateBlock": "översätt [WORDS] till [LANGUAGE]",
    "translate.defaultTextToTranslate": "hej",
    "translate.viewerLanguage": "språk",
    "text2speech.speakAndWaitBlock": "säg [WORDS]",
    "text2speech.setVoiceBlock": "sätt röst till [VOICE]",
    "text2speech.setLanguageBlock": "sätt språk till [LANGUAGE]",
    "text2speech.alto": "alt",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "pip",
    "text2speech.giant": "jätte",
    "text2speech.kitten": "kattunge",
    "text2speech.defaultTextToSpeak": "hej",
    "speech.whenIHear": "när jag hör [PHRASE]",
    "speech.listenAndWait": "lyssna och vänta",
    "speech.defaultWhenIHearValue": "kör igång",
    "paint.paintEditor.hue": "Färg",
    "paint.paintEditor.saturation": "Mättnad",
    "paint.paintEditor.brightness": "Ljusstyrka",
    "gui.comingSoon.message1": "Det är lugnt, vi jobbar på det {emoji}",
    "gui.comingSoon.message2": "Kommer snart...",
    "gui.comingSoon.message3": "Vi jobbar på det {emoji}",
    "paint.paintEditor.fill": "Fyll",
    "paint.paintEditor.costume": "Klädsel",
    "paint.paintEditor.group": "Gruppera",
    "paint.paintEditor.ungroup": "Dela upp grupp",
    "paint.paintEditor.undo": "Ångra",
    "paint.paintEditor.redo": "Gör om",
    "paint.paintEditor.forward": "Framåt",
    "paint.paintEditor.backward": "Bakåt",
    "paint.paintEditor.front": "Placera längst fram",
    "paint.paintEditor.back": "Placera längst bak",
    "paint.paintEditor.more": "Mer",
    "paint.modeTools.brushSize": "Storlek",
    "paint.modeTools.eraserSize": "Suddgummi storlek",
    "paint.modeTools.copy": "Kopiera",
    "paint.modeTools.paste": "Klistra in",
    "paint.modeTools.delete": "Radera",
    "paint.modeTools.curved": "Böjd",
    "paint.modeTools.pointed": "Spetsig",
    "paint.modeTools.thickness": "Tjocklek",
    "paint.modeTools.flipHorizontal": "Vänd vågrätt",
    "paint.modeTools.flipVertical": "Vänd lodrätt",
    "paint.modeTools.filled": "Fylld",
    "paint.modeTools.outlined": "Ofylld",
    "paint.paintEditor.bitmap": "Gör till bitmapp",
    "paint.paintEditor.vector": "Konvertera till vektor",
    "paint.paintEditor.stroke": "Kontur",
    "paint.brushMode.brush": "Pensel",
    "paint.eraserMode.eraser": "Radergummi",
    "paint.fillMode.fill": "Fyll",
    "paint.lineMode.line": "Linje",
    "paint.ovalMode.oval": "Cirkel",
    "paint.rectMode.rect": "Rektangel",
    "paint.reshapeMode.reshape": "Omforma",
    "paint.roundedRectMode.roundedRect": "Rektangel rundade hörn",
    "paint.selectMode.select": "Välj",
    "paint.textMode.text": "Text",
    "paint.colorPicker.swap": "Växla färg",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "vi": {
    "gui.backpack.header": "Ba lô",
    "gui.backpack.errorBackpack": "Không thể tải lên ba lô",
    "gui.backpack.loadingBackpack": "Đang tải ...",
    "gui.backpack.more": "Nhiều hơn",
    "gui.backpack.emptyBackpack": "Ba lô trống",
    "gui.gui.costumesTab": "Thiết kế",
    "gui.gui.soundsTab": "Âm thanh",
    "gui.gui.backdropsTab": "Phông nền",
    "gui.gui.addExtension": "Thêm phần mở rộng",
    "gui.costumeTab.addCostumeFromLibrary": "thay đổi trang phục ",
    "gui.costumeLibrary.chooseACostume": "Chọn một thiết kế",
    "gui.costumeTab.addBackdropFromLibrary": "Chọn một Phông nền",
    "gui.costumeTab.addBlankCostume": "Vẽ",
    "gui.costumeTab.addSurpriseCostume": "Bất ngờ",
    "gui.costumeTab.addFileBackdrop": "Tải lên ảnh nề",
    "gui.costumeTab.addFileCostume": "tải lên thiết kế",
    "gui.costumeTab.addCameraCostume": "Máy ảnh",
    "gui.soundEditor.trim": "thu gọn ",
    "gui.soundEditor.stop": "Dừng lại",
    "gui.soundEditor.sound": "Âm thanh",
    "gui.soundEditor.play": "Chơi",
    "gui.soundEditor.save": "Lưu",
    "gui.soundEditor.undo": "Hoàn tác",
    "gui.soundEditor.redo": "Làm lại ",
    "gui.soundEditor.faster": "Nhanh hơn",
    "gui.soundEditor.slower": "chậm hơn",
    "gui.soundEditor.echo": "Tiếng vang",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "To hơn",
    "gui.soundEditor.softer": "nhẹ hơn",
    "gui.soundEditor.reverse": "Đảo ngược",
    "gui.soundTab.recordSound": "Ghi lại",
    "gui.soundTab.addSoundFromLibrary": "chọn âm thanh ",
    "gui.soundTab.surpriseSound": "Bất ngờ",
    "gui.soundTab.fileUploadSound": "Tải âm thanh lên",
    "gui.controls.stop": "Dừng lại",
    "gui.controls.go": "Đi",
    "gui.stageHeader.stageSizeUnFull": "Thoát toàn màn hình",
    "gui.SpriteInfo.show": "Xuất hiện",
    "gui.SpriteInfo.size": "Kích thước ",
    "gui.directionPicker.rotationStyles.allAround": "Xung quanh",
    "gui.directionPicker.rotationStyles.leftRight": "Trái/Phải",
    "gui.directionPicker.rotationStyles.dontRotate": "Không quay",
    "gui.spriteSelectorItem.contextMenuDuplicate": "nhân bản",
    "gui.spriteSelectorItem.contextMenuDelete": "xoá",
    "gui.spriteSelectorItem.contextMenuExport": "xuất ra",
    "gui.spriteSelector.addSpriteFromPaint": "Vẽ",
    "gui.spriteSelector.addSpriteFromSurprise": "Bất ngờ",
    "gui.spriteSelector.addSpriteFromFile": "Tải đối tượng lên",
    "gui.stageSelector.stage": "Sân khấu",
    "gui.stageSelector.backdrops": "Phông nền",
    "gui.stageSelector.addBackdropFromPaint": "Vẽ",
    "gui.stageSelector.addBackdropFromSurprise": "Bất ngờ",
    "gui.stageSelector.addBackdropFromFile": "Tải lên ảnh nền",
    "gui.modal.back": "Trở về",
    "gui.library.filterPlaceholder": "Tìm kiếm",
    "gui.libraryTags.all": "Tất cả",
    "gui.libraryTags.animals": "Động vật",
    "gui.libraryTags.dance": "Khiêu vũ",
    "gui.libraryTags.effects": "Hiệu ứng",
    "gui.libraryTags.fantasy": "Tưởng tượng",
    "gui.libraryTags.fashion": "Thời trang",
    "gui.libraryTags.food": "Food",
    "gui.libraryTags.indoors": "Trong nhà",
    "gui.libraryTags.loops": "Loops",
    "gui.libraryTags.music": "Âm nhạc",
    "gui.libraryTags.notes": "Các lưu ý",
    "gui.libraryTags.outdoors": "Ngoài trời",
    "gui.libraryTags.patterns": "Patterns",
    "gui.libraryTags.people": "Con người",
    "gui.libraryTags.percussion": "Bộ gõ",
    "gui.libraryTags.space": "Khoảng trắng",
    "gui.libraryTags.sports": "Thể thao",
    "gui.libraryTags.underwater": "Dưới nước",
    "gui.libraryTags.voice": "Voice",
    "gui.libraryTags.wacky": "Wacky",
    "gui.libraryTags.animation": "Phim hoạt hình",
    "gui.libraryTags.art": "Nghệ thuật",
    "gui.libraryTags.games": "Trò chơi",
    "gui.libraryTags.stories": "Những câu chuyện",
    "gui.libraryTags.letters": "Ký tự",
    "gui.soundLibrary.chooseASound": "chọn âm thanh ",
    "gui.SpriteInfo.spritePlaceholder": "Tên",
    "gui.cards.more-things-to-try": "Thêm nhiều thứ nữa để thử!",
    "gui.cards.see-more": "Xem thêm",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Mở rộng",
    "gui.cards.close": "Đóng",
    "gui.loader.message1": "Tạo ra các khối ...",
    "gui.loader.message2": "Đang tải đối tượng",
    "gui.loader.message3": "Tải âm thanh ...",
    "gui.loader.message4": "Đang tải phần mở rộng",
    "gui.loader.message5": "bầy mèo ...",
    "gui.loader.message6": "Đang tải trí tuệ của bạn ...",
    "gui.loader.message7": "Đang tải nhân vật ...",
    "gui.loader.message8": "Chuân bị emoji...",
    "gui.loader.headline": "Đang tải dự án",
    "gui.cameraModal.cameraModalTitle": "Chụp ảnh ",
    "gui.cameraModal.loadingCameraMessage": "Đang tải Máy ảnh",
    "gui.cameraModal.permissionRequest": "Chúng tôi cần được cấp quyền sử dụng máy ảnh",
    "gui.cameraModal.retakePhoto": "Chụp lại ảnh",
    "gui.cameraModal.save": "Lưu",
    "gui.cameraModal.takePhoto": "Chụp ảnh ",
    "gui.cameraModal.loadingCaption": "Đang tải ...",
    "gui.cameraModal.enableCameraCaption": "cho phép sử dụng Máy ảnh",
    "gui.recordModal.title": "Thu lại âm thanh ",
    "gui.playbackStep.stopMsg": "Dừng lại",
    "gui.playbackStep.playMsg": "Chơi",
    "gui.playbackStep.loadingMsg": "Đang tải ...",
    "gui.playbackStep.saveMsg": "Lưu",
    "gui.playbackStep.reRecordMsg": "thu âm lại",
    "gui.webglModal.label": "Trình duyệt của bạn không hỗ trợ WebGL",
    "gui.webglModal.webgllink": "không hỗ trợ WebGL",
    "gui.prompt.cancel": "Hủy",
    "gui.prompt.ok": "OK",
    "gui.customProcedures.addAnInputNumberText": "Thêm dữ liệu đầu vào",
    "gui.customProcedures.addAnInputBoolean": "Thêm dữ liệu đầu vào",
    "gui.customProcedures.numberTextType": "số hay chữ",
    "gui.customProcedures.booleanType": "điều kiện",
    "gui.customProcedures.addALabel": "Thêm nhãn",
    "gui.customProcedures.runWithoutScreenRefresh": "Chạy mà không làm mới màn hình",
    "gui.customProcedures.cancel": "Hủy",
    "gui.customProcedures.ok": "OK",
    "gui.extension.microbit.description": "Kết nối dự án của bạn với thế giới.",
    "gui.extension.music.name": "Âm nhạc",
    "gui.extension.music.description": "Chơi nhạc cụ và trống",
    "gui.extension.pen.name": "Bút vẽ",
    "gui.extension.pen.description": "Vẽ bằng đối tượng của bạn.",
    "gui.gui.variableScopeOptionAllSprites": "Cho tất cả đối tượng",
    "gui.gui.variableScopeOptionSpriteOnly": "Chỉ đối tượng này",
    "gui.gui.variablePromptAllSpritesMessage": "Biến này sẽ có sẵn cho tất cả đối tượng.",
    "gui.monitor.contextMenu.default": "chế độ đọc bình thường",
    "gui.monitor.contextMenu.large": "chế độ đọc lớn",
    "gui.monitor.contextMenu.slider": "thanh trượt",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.opcodeLabels.direction": "hướng",
    "gui.opcodeLabels.xposition": "hoành độ",
    "gui.opcodeLabels.yposition": "tung độ",
    "gui.opcodeLabels.size": "kích thước",
    "gui.opcodeLabels.costumename": "tên thiết kế",
    "gui.opcodeLabels.costumenumber": "costume number",
    "gui.opcodeLabels.backdropname": "tên phông nền",
    "gui.opcodeLabels.backdropnumber": "backdrop number",
    "gui.opcodeLabels.volume": "âm lượng",
    "gui.opcodeLabels.tempo": "nhịp độ",
    "gui.opcodeLabels.answer": "trả lời",
    "gui.opcodeLabels.loudness": "độ ồn",
    "gui.opcodeLabels.year": "năm",
    "gui.opcodeLabels.month": "tháng",
    "gui.opcodeLabels.date": "ngày",
    "gui.opcodeLabels.dayofweek": "ngày trong tuần",
    "gui.opcodeLabels.hour": "giờ",
    "gui.opcodeLabels.minute": "phút",
    "gui.opcodeLabels.second": "giây",
    "gui.opcodeLabels.timer": "đồng hồ bấm giờ",
    "music.categoryName": "Âm nhạc",
    "translate.categoryName": "Dịch",
    "pen.categoryName": "Bút vẽ",
    "pen.changeColorParam": "chỉnh màu bút [COLOR_PARAM] thành màu [VALUE]",
    "pen.changeHue": "thay đổi màu bút vẽ bằng [HUE]",
    "pen.changeShade": "đổi độ đậm bút vẽ bằng [SHADE]",
    "pen.changeSize": "thay đổi độ dày của nét bút [SIZE]",
    "pen.clear": "Xóa tất cả",
    "pen.colorMenu.brightness": "độ sáng",
    "pen.colorMenu.color": "màu",
    "pen.colorMenu.saturation": "độ bão hòa",
    "pen.colorMenu.transparency": "độ trong suốt",
    "pen.penDown": "đặt bút",
    "pen.penUp": "nhấc bút",
    "pen.setColor": "chọn bút màu [COLOR]",
    "pen.setColorParam": "chỉnh màu bút [COLOR_PARAM] thành màu [VALUE]",
    "pen.setHue": "chọn bút màu [HUE]",
    "pen.setShade": "đặt độ đậm của bút vẽ thành [SHADE]",
    "pen.setSize": "đặt kích thước bút vẽ bằng [SIZE]",
    "pen.stamp": "in hình",
    "music.changeTempo": "thay đổi nhịp độ một lượng [TEMPO]",
    "music.drumBass": "(2) Trống Bass",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Đôi dùi gỗ",
    "music.drumClosedHiHat": "(6) Chũm chọe",
    "music.drumConga": "(14)Conga",
    "music.drumCowbell": "(11) Cowbel",
    "music.drumCrashCymbal": "(4) Chũm choẹ trung",
    "music.drumCuica": "(18) Culca",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Hand Clap",
    "music.drumOpenHiHat": "(5) Chũm chọe ",
    "music.drumSideStick": "(3) Side stick",
    "music.drumSnare": "(1) Trống lẫy ",
    "music.drumTambourine": "(7) Tambourine",
    "music.drumTriangle": "(12) Hình tam giác ",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Mảnh ghép gỗ",
    "music.getTempo": "nhịp độ",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Bassoon",
    "music.instrumentCello": "(8) Đàn viô-lông-xen",
    "music.instrumentChoir": "(15) Đội đồng ca ",
    "music.instrumentClarinet": "(10)",
    "music.instrumentElectricGuitar": "(5) Guitar điện",
    "music.instrumentElectricPiano": "(2) Đàn Piano điện",
    "music.instrumentFlute": "(12) Sáo",
    "music.instrumentGuitar": "(4) Đàn guitar",
    "music.instrumentMarimba": "(19) Mộc cầm",
    "music.instrumentMusicBox": "(17) Hộp nhạc",
    "music.instrumentOrgan": "(3) Đàn organ",
    "music.instrumentPiano": "(1) đàn Piano",
    "music.instrumentPizzicato": "(7) Móc dây",
    "music.instrumentSaxophone": "(11) Kèn Saxophone ",
    "music.instrumentSteelDrum": "(18) Steel Drum",
    "music.instrumentSynthLead": "(20) Synth Lea",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibraphone",
    "music.instrumentWoodenFlute": "(13) thanh sáo gỗ",
    "music.midiPlayDrumForBeats": "chơi trống [DRUM] trong [BEATS] nhịp",
    "music.midiSetInstrument": "đặt nhạc cụ là [INSTRUMENT]",
    "music.playDrumForBeats": "chơi trống [DRUM] trong [BEATS] nhịp",
    "music.playNoteForBeats": "chơi trống [NOTE] trong [BEATS] nhịp",
    "music.restForBeats": "nghỉ trong [BEATS] nhịp",
    "music.setInstrument": "đặt nhạc cụ là [INSTRUMENT]",
    "music.setTempo": "thay đổi nhịp độ thành [TEMPO]",
    "videoSensing.categoryName": "Cảm biến Video",
    "videoSensing.direction": "hướng",
    "videoSensing.motion": "chuyển động",
    "videoSensing.off": "tắt",
    "videoSensing.on": "bật",
    "videoSensing.onFlipped": "khi lật",
    "videoSensing.setVideoTransparency": "đặt độ trong suốt của video thành [TRANSPARENCY]",
    "videoSensing.sprite": "đối tượng",
    "videoSensing.stage": "Sân khấu",
    "videoSensing.videoOn": "mở [ATTRIBUTE] trên [SUBJECT]",
    "videoSensing.videoToggle": "mở video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "khi chuyển động của video > [REFERENCE]",
    "translate.translateBlock": "dịch [WORDS] sang ngôn ngữ [LANGUAGE]",
    "translate.defaultTextToTranslate": "xin chào",
    "translate.viewerLanguage": "language",
    "text2speech.speakAndWaitBlock": "nói [WORDS]",
    "text2speech.setVoiceBlock": "set voice to [VOICE]",
    "text2speech.setLanguageBlock": "set language to [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "squeak",
    "text2speech.giant": "giant",
    "text2speech.kitten": "kitten",
    "text2speech.defaultTextToSpeak": "hello",
    "speech.whenIHear": "when I hear [PHRASE]",
    "speech.listenAndWait": "listen and wait",
    "speech.defaultWhenIHearValue": "let''s go",
    "paint.paintEditor.hue": "Màu",
    "paint.paintEditor.saturation": "Độ bão hòa",
    "paint.paintEditor.brightness": "Độ sáng",
    "gui.comingSoon.message1": "Đừng lo, chúng tôi đang phát triển tính năng này {emoji}",
    "gui.comingSoon.message2": "Sắp có...",
    "gui.comingSoon.message3": "Chúng tôi đang tiếp tục phát triển tính năng này {emoji}",
    "paint.paintEditor.fill": "Tô",
    "paint.paintEditor.costume": "Thiết kế",
    "paint.paintEditor.group": "Nhóm",
    "paint.paintEditor.ungroup": "Hủy nhóm",
    "paint.paintEditor.undo": "Hoàn tác",
    "paint.paintEditor.redo": "Làm lại ",
    "paint.paintEditor.forward": "Đưa lên phía trên",
    "paint.paintEditor.backward": "Đưa ra phía sau",
    "paint.paintEditor.front": "Đưa lên phía trên cùng",
    "paint.paintEditor.back": "Đưa ra phía sau cùng",
    "paint.paintEditor.more": "Nhiều hơn",
    "paint.modeTools.brushSize": "Kích thước ",
    "paint.modeTools.eraserSize": "Kích thước tẩy",
    "paint.modeTools.copy": "Sao chép",
    "paint.modeTools.paste": "Dán",
    "paint.modeTools.delete": "Xóa",
    "paint.modeTools.curved": "Uốn cong",
    "paint.modeTools.pointed": "Góc nhọn",
    "paint.modeTools.thickness": "Độ dày ngòi bút",
    "paint.modeTools.flipHorizontal": "Lật ngang",
    "paint.modeTools.flipVertical": "Lật dọc",
    "paint.modeTools.filled": "Hình đầy",
    "paint.modeTools.outlined": "Hình rỗng",
    "paint.paintEditor.bitmap": "Chuyển thành Bitmap",
    "paint.paintEditor.vector": "Chuyển thành Vector",
    "paint.paintEditor.stroke": "Viền",
    "paint.brushMode.brush": "Cọ vẽ",
    "paint.eraserMode.eraser": "Tẩy",
    "paint.fillMode.fill": "Tô",
    "paint.lineMode.line": "Dòng",
    "paint.ovalMode.oval": "Hình tròn",
    "paint.rectMode.rect": "Hình chữ nhật",
    "paint.reshapeMode.reshape": "Đổi hình dạng",
    "paint.roundedRectMode.roundedRect": "Hình vuông góc tròn",
    "paint.selectMode.select": "Chọn",
    "paint.textMode.text": "Văn bản",
    "paint.colorPicker.swap": "Đổi màu",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "tr": {
    "gui.gui.project": "Projeler",
    "gui.gui.newItem": "Yeni Proje",
    "gui.gui.load": "Proje Yükle",
    "gui.gui.save": "Proje Kaydet",
    "gui.gui.saveAs": "Farklı Kaydet",
    "gui.gui.learn": "Eğitim",
    "gui.gui.clearRecord": "Kaydı Temizle",
    "gui.gui.onlineLearn": "Belgeler",
    "gui.gui.onlineForum": "Çevrimiçi Forum",
    "gui.gui.videoTutorial": "Video Öğreticileri",
    "gui.gui.offlineLearn": "Örnek Programlar",
    "gui.menuBar.turboModeOff": "Turbo Modu KAPAT",
    "gui.menuBar.turboModeOn": "Turbo Modu AÇ",
    "gui.menuBar.edit": "Düzenle",
    "gui.menuBar.restoreSprite": "Kukla'yı Geri Yükle",
    "gui.menuBar.restoreSound": "Sesi Geri Yükle",
    "gui.menuBar.restoreCostume": "Kostümü Geri Yükle",
    "gui.editorMind.restore": "Geri Yükle",
    "gui.turboMode.active": "Turbo Mod",
    "gui.gui.connectDevice": "Cihazı Bağla",
    "gui.gui.disconnectDevice": "Cihazı Ayır",
    "gui.gui.installSerialportDriver": "SeriPort Sürücüsünü Kur",
    "gui.gui.openDM": "Aygıt Yöneticisini Aç",
    "gui.gui.updateTips": "Güncelleme İpuçları",
    "gui.gui.newVersion": "en son sürüm",
    "gui.gui.downloadUpdate": "Güncelleme için İndir",
    "gui.gui.versionUpdate": "Sürüm Güncellemesi",
    "gui.gui.downloading": "Checking Updator",
    "gui.setting.feedbackMenu": "Geri Bildirim",
    "gui.gui.arduino": "Çevrimdışı",
    "gui.gui.scratch": "Çevrimiçi",
    "gui.setting.officialWebsit": "İnternet Sitesi",
    "gui.setting.officialQQGroup": "QQ Grubu",
    "gui.setting.wechatOfficialAccounts": "WeChat Hesapları",
    "gui.setting.currentVersion": "Güncel Sürüm",
    "gui.setting.checkForUpdate": "Güncellemeleri Kontrol Et",
    "gui.setting.remindWhenUpdate": "Güncellendiğinde indir",
    "gui.setting.currentIsLatest": "Bu sürüm en yenisidir",
    "gui.setting.latestVersion": "En Son Sürüm",
    "gui.setting.download": "Web'den İndirmeye geç",
    "gui.setting.language": "Dil",
    "gui.setting.uploadCsv": "XLSX yükle",
    "gui.setting.theme": "Tema",
    "gui.setting.feedback": "İletişim",
    "gui.setting.email": "e-Posta",
    "gui.setting.opinionFeedback": "Geri Bildirim",
    "gui.variableMc.variableName": "değişken adı",
    "gui.variableMc.variableOff": "iptal et",
    "gui.variableMc.variableOn": "onayla",
    "gui.variableMc.newVariable": "yeni değişken",
    "gui.setting.helpUsTranslate": "Tercüme Etmeye Yardımcı Ol",
    "gui.setting.checkUpdate": "Güncellemeyi Kontrol Et",
    "gui.setting.ok": "Tamam",
    "gui.setting.languageSetting": "Dil",
    "gui.setting.themeSetting": "Tema",
    "gui.setting.versionUpdate": "Yeni Sürüm",
    "gui.setting.connectUsTitle": "İletişim",
    "gui.setting.uploadAttachment": "Eki Yükle",
    "gui.setting.displayTitle": "Ekran Ayarları",
    "gui.setting.fontSizeSetting": "yazı tipi boyutu",
    "gui.setting.restartEffect": "yeniden başlatma gerektirir",
    "gui.setting.fontSizeLarge": "büyük",
    "gui.setting.fontSizeMiddle": "orta",
    "gui.setting.fontSizeSmall": "küçük",
    "gui.backpack.header": "Sırt Çantası",
    "gui.backpack.errorBackpack": "Sırt çantası yüklenirken hata oluştu",
    "gui.backpack.loadingBackpack": "Yükleniyor...",
    "gui.backpack.more": "Daha Fazla",
    "gui.backpack.emptyBackpack": "Sırt Çantası boş",
    "gui.gui.blocks": "Bloklar",
    "gui.gui.costumesTab": "Kostümler",
    "gui.gui.soundsTab": "Sesler",
    "gui.gui.backdropsTab": "Dekorlar",
    "gui.gui.addExtension": "Eklenti Ekle",
    "gui.costumeTab.addCostumeFromLibrary": "Bir Kostüm Seç",
    "gui.costumeLibrary.chooseACostume": "Bir Kostüm Seç",
    "gui.costumeTab.addBackdropFromLibrary": "Bir Dekor Seç",
    "gui.costumeTab.addBlankCostume": "Çizim",
    "gui.costumeTab.addSurpriseCostume": "Sürpriz",
    "gui.costumeTab.addFileBackdrop": "Dekor Yükle",
    "gui.costumeTab.addFileCostume": "Kostüm Yükle",
    "gui.costumeTab.addCameraCostume": "Kamera",
    "gui.soundEditor.trim": "Kırp",
    "gui.soundEditor.stop": "Durdur",
    "gui.soundEditor.sound": "Ses",
    "gui.soundEditor.play": "Çal",
    "gui.soundEditor.save": "Kaydet",
    "gui.soundEditor.undo": "Geri Al",
    "gui.soundEditor.redo": "Geri Al",
    "gui.soundEditor.faster": "Daha hızlı",
    "gui.soundEditor.slower": "Daha Yavaş",
    "gui.soundEditor.echo": "Yankı",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Daha sesli",
    "gui.soundEditor.softer": "Daha Yumuşak",
    "gui.soundEditor.reverse": "Tersi",
    "gui.soundTab.recordSound": "Kaydet",
    "gui.soundTab.addSoundFromLibrary": "Bir Ses Seç",
    "gui.soundTab.surpriseSound": "Sürpriz",
    "gui.soundTab.fileUploadSound": "Ses Yükle",
    "gui.soundTab.addSound": "Ses Ekle",
    "gui.controls.stop": "Durdur",
    "gui.controls.go": "Git",
    "gui.gui.startPython": "Python'u başlat",
    "gui.gui.stopPython": "Python'u durdur",
    "gui.controls.fullScreenControl": "Tam Ekran Kontrolü",
    "gui.gui.stageSizeLarge": "Sahne - Büyük",
    "gui.gui.stageSizeSmall": "Sahne - Küçük",
    "gui.gui.stageSizeNostage": "Sahneyi sakla",
    "gui.gui.stageSizeFull": "Sahne - Tam Ekran",
    "gui.stageHeader.stageSizeUnFull": "Tam ekran modundan çık",
    "gui.sprite.sprite": "Kukla:",
    "gui.SpriteInfo.show": "Göster",
    "gui.SpriteInfo.size": "Büyüklük",
    "gui.sprite.direction": "Yön:",
    "gui.sprite.rotation": "Dönme",
    "gui.directionPicker.rotationStyles.allAround": "her yöne dön",
    "gui.directionPicker.rotationStyles.leftRight": "sağa-sola",
    "gui.directionPicker.rotationStyles.dontRotate": "döndürme",
    "gui.spriteSelectorItem.contextMenuDuplicate": "kopyasını çıkart",
    "gui.spriteSelectorItem.contextMenuDelete": "sil",
    "gui.spriteSelectorItem.contextMenuExport": "Dışarıya aktar",
    "gui.sprite.addSpriteFromLibrary": "Kukla Kütüphanesi",
    "gui.spriteSelector.addSpriteFromPaint": "Çizim",
    "gui.spriteSelector.addSpriteFromSurprise": "Sürpriz",
    "gui.spriteSelector.addSpriteFromFile": "Kuklayı yükle",
    "gui.sprite.addSpriteFromCamera": "Kamera",
    "gui.stageSelector.stage": "Sahne",
    "gui.stageSelector.backdrops": "Dekorlar",
    "gui.stage.addBackdropFromLibrary": "Dekor Kütüphanesi",
    "gui.stageSelector.addBackdropFromPaint": "Çizim",
    "gui.stageSelector.addBackdropFromSurprise": "Sürpriz",
    "gui.stageSelector.addBackdropFromFile": "Dekor Yükle",
    "gui.stage.addBackdropFromCamera": "Kamera",
    "gui.modal.back": "Geri",
    "gui.extension.kit": "Kit",
    "gui.extension.kitTitle": "Kit Seç",
    "gui.extension.board": "Kart",
    "gui.extension.boardTitle": "Kart Seç",
    "gui.extension.spreadBoard": "Shield",
    "gui.extension.spreadBoardTitle": "Shield Seç",
    "gui.extension.sensor": "Sensör",
    "gui.extension.sensorTitle": "Sensör Seç",
    "gui.extension.actuator": "Aktuatör",
    "gui.extension.actuatorTitle": "Aktuatör Seç",
    "gui.extension.communicationModule": "İletişim",
    "gui.extension.communicationModuleTitle": "İletişim Seç",
    "gui.extension.display": "Ekran",
    "gui.extension.displayTitle": "Ekran Seç",
    "gui.extension.function": "Fonksiyon",
    "gui.extension.functionTitle": "Fonksiyon Seç",
    "gui.extension.internetService": "İnternet",
    "gui.extension.internetServiceTitle": "İnternet Seç",
    "gui.extension.arduinContentLabel": "Aygıt Seç",
    "gui.library.filterPlaceholder": "Ara",
    "gui.libraryTags.all": "Tümü",
    "gui.libraryTags.animals": "Hayvanlar",
    "gui.libraryTags.dance": "Dans",
    "gui.libraryTags.effects": "Etkiler",
    "gui.libraryTags.fantasy": "Fantezi",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Yiyecek",
    "gui.libraryTags.indoors": "Bina içi",
    "gui.libraryTags.loops": "Döngüler",
    "gui.libraryTags.music": "Müzik",
    "gui.libraryTags.notes": "Notalar",
    "gui.libraryTags.outdoors": "Bina dışı",
    "gui.libraryTags.patterns": "Desenler",
    "gui.libraryTags.people": "İnsanlar",
    "gui.libraryTags.percussion": "Vurmalılar",
    "gui.libraryTags.space": "Uzay",
    "gui.libraryTags.sports": "Sporlar",
    "gui.libraryTags.underwater": "Su altı",
    "gui.libraryTags.voice": "Ses",
    "gui.libraryTags.wacky": "Tuhaf",
    "gui.libraryTags.animation": "Animasyon",
    "gui.libraryTags.art": "Sanat",
    "gui.libraryTags.games": "Oyunlar",
    "gui.libraryTags.stories": "Hikayeler",
    "gui.libraryTags.letters": "Harfler",
    "gui.extension.backdropLib": "Dekor Kütüphanesi",
    "gui.soundLibrary.chooseASound": "Bir Ses Seç",
    "gui.SpriteInfo.spritePlaceholder": "İsim",
    "gui.extension.spriteLib": "Kukla Kütüphanesi",
    "gui.gui.unselectedSerialport": "Bağlı Değil",
    "gui.gui.unConnectedDev": "Bağlı Değil",
    "gui.gui.pythonMode": "Python Modu",
    "gui.gui.burnFirmware": "Bellenim Yüklemesi",
    "gui.gui.burnFirmwareError": "Bellenim Yükleme Hatası",
    "gui.gui.connected": "Bağlandı",
    "gui.gui.failedConnected": "Bağlanmadı",
    "gui.gui.connecting": "Bağlanıyor...",
    "gui.cards.all-how-tos": "Tüm Nasıl Yapılırlar",
    "gui.cards.how-tos": "Nasıl Yapılırlar",
    "gui.cards.remove": "Kaldır",
    "gui.cards.more-things-to-try": "Denemek için daha fazla şey!",
    "gui.cards.see-more": "Daha fazla görüntüle",
    "gui.cards.shrink": "Küçült",
    "gui.cards.expand": "Açıkla",
    "gui.cards.close": "Kapat",
    "gui.loader.message1": "Bloklar yaratılıyor ...",
    "gui.loader.message2": "Kuklalar yükleniyor ...",
    "gui.loader.message3": "Sesler yükleniyor ...",
    "gui.loader.message4": "Eklentiler yükleniyor ...",
    "gui.loader.message5": "Kediler toplanıyor...",
    "gui.loader.message6": "Nanolar iletiliyor ...",
    "gui.loader.message7": "Gobolar şişiriliyor ...",
    "gui.loader.message8": "Emojiler hazırlanıyor ...",
    "gui.loader.headline": "Proje Yükleniyor",
    "gui.cameraModal.cameraModalTitle": "Bir fotoğraf çek",
    "gui.cameraModal.loadingCameraMessage": "Kamera yükleniyor...",
    "gui.cameraModal.permissionRequest": "Kameranızı kullanmak için izninize ihtiyacımız var",
    "gui.cameraModal.retakePhoto": "Yeniden fotoğraf çek",
    "gui.cameraModal.save": "Kaydet",
    "gui.cameraModal.takePhoto": "Fotoğraf çek",
    "gui.cameraModal.loadingCaption": "Yükleniyor...",
    "gui.cameraModal.enableCameraCaption": "Kamerayı etkinleştir",
    "gui.recordModal.title": "Ses Kaydet",
    "gui.recordStep.recordByClickBtn": "Aşağıdaki düğmeyi tıklayarak kayda başlayın",
    "gui.recordStep.permissionMicrophone": "Mikrofonu kullanmak için izin gerekiyor",
    "gui.recordStep.record": "Kaydet",
    "gui.recordStep.stopRecord": "Kaydı Durdur",
    "gui.playbackStep.stopMsg": "Durdur",
    "gui.playbackStep.playMsg": "Çal",
    "gui.playbackStep.loadingMsg": "Yükleniyor...",
    "gui.playbackStep.saveMsg": "Kaydet",
    "gui.playbackStep.reRecordMsg": "Yeniden kaydet",
    "gui.webglModal.label": "Web Tarayıcınızın WebGL Desteği Bulunmamaktadır",
    "gui.webglModal.descriptionMind": "Galiba bilgisayarınız {webGlLink}. Mind+'ın çalışması için bu teknoloji gereklidir. {updateGpuDriver}",
    "gui.webglModal.webgllink": "WebGL desteğiniz bulunmamaktadır",
    "gui.webglModal.updateGpuDriver": "Lütfen grafik kartı sürücüsünü güncellemeyi deneyin",
    "gui.webglModal.ok": "Tamam",
    "gui.extension.scratchExampleLib": "Scratch Örnekleri Kütüphanesi",
    "gui.extension.pythonExampleLib": "Python Örnekleri Kütüphanesi",
    "gui.extension.arduinoExampleLib": "Arduino Örnekleri Kütüphanesi",
    "gui.prompt.cancel": "Vazgeç",
    "gui.prompt.ok": "OK",
    "gui.extension.makeBlock": "Blok yap",
    "gui.customProcedures.addAnInputNumberText": "Bir girdi ekle",
    "gui.customProcedures.addAnInputBoolean": "Bir girdi ekle",
    "gui.customProcedures.numberTextType": "sayı veya metin",
    "gui.customProcedures.booleanType": "mantıksal",
    "gui.customProcedures.addALabel": "Bir etiket ekle",
    "gui.customProcedures.runWithoutScreenRefresh": "Ekranı yenilemeden çalışsın",
    "gui.customProcedures.cancel": "Vazgeç",
    "gui.customProcedures.ok": "Tamam",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "DFRobot'tan Vortex Eğitim robotu",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "DFRobot'tan Explorer D1 Eğitim robotu",
    "gui.extension.arduinounoR3.name": "ArduinoUnoR3",
    "gui.extension.arduinounoR3.description": "Arduino Uno temelli Wuhan Maker Kursuyla birlikte.",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "Max:bot micro:bit temelli robot",
    "gui.extension.max.name": "MAX (ROB0137)",
    "gui.extension.max.description": "MAX（ROB0137）Arduino temelli robot",
    "gui.extension.motorbit.name": "Motor:bit",
    "gui.extension.motorbit.description": "Motor:bit motor ve servo genişletme  kartı",
    "gui.extension.maqueen.name": "Maqueen",
    "gui.extension.maqueen.description": "micro:Maqueen micro:bit temelli robot",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.microbit.description": "Projelerini dünya ile paylaş.",
    "gui.extension.spread.name": "micro:bit & mPython expansion board",
    "gui.extension.spreadMaqueen.name": "maqueen eklentisi",
    "gui.extension.spread.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadmaqueen.description": "giriş seviyesi bir minicar",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "The advanced educational robot that supports both micro:bit and mPython",
    "gui.extension.iot.name": "Nesnelerin İnterneti",
    "gui.extension.iot.description": "Nesnelerin İnterneti'ne Dayalı Genişletilmiş Sürüm",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Leonardo ana kontrol kart modülü.",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Uno ana kontrol kart modülü",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Arduino Nano ana kontrol kart modülü",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "ESP32-temelli ana kontrol kart modülü",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Mega 2560 kontrolünde aygıt",
    "gui.extension.dfr0299.name": "DFPlayer MP3 Modülü",
    "gui.extension.dfr0299.description": "Mp3 Çalma Modülü",
    "gui.extension.ser0006.name": "Mikro Servo",
    "gui.extension.ser0006.description": "Mikro Servo",
    "gui.extension.dfr0523.name": "Peristaltik pompa",
    "gui.extension.dfr0523.description": "Pompayı dönüşümlü olarak sıkarak ve serbest bırakarak sıvı verebilir",
    "gui.extension.dfr0017.name": "Röle modülü",
    "gui.extension.dfr0017.description": "Yüksek akım ve gerilim cihazlarını kontrol edin",
    "gui.extension.steppermotor.name": "Step Motor",
    "gui.extension.steppermotor.description": "Darbe kontrolü ile doğru pozisyon kontrolünü gerçekleştirin",
    "gui.extension.dfr0534.name": "Seri MP3 modülü",
    "gui.extension.dfr0534.description": "Seri MP3 ses modülü",
    "gui.extension.servo360.name": "360 derece mikro servo",
    "gui.extension.servo360.description": "Sadece hız ve yön kontrolü. ",
    "gui.extension.tel0118.name": "OBLOQ – IoT Modülü ",
    "gui.extension.tel0118.description": "Wi-Fi den SERİ ye aygıt",
    "gui.extension.dfr0095.name": "IR Verici Modülü",
    "gui.extension.dfr0095.description": "38KHz standart IR sinyal yolla",
    "gui.extension.dfr0094.name": "IR Alıcı Modülü",
    "gui.extension.dfr0094.description": "38KHz standart IR sinyal al",
    "gui.extension.tel0094.name": "GPS Sinyal Alıcı Modülü",
    "gui.extension.tel0094.description": "GPS Sinyal Alıcı Modülü",
    "gui.extension.tel0026.name": "Bluetooth modülü",
    "gui.extension.tel0026.description": "Bluetooth seri port modülü",
    "gui.extension.dfr0486.name": "OLED-12864 Ekranı",
    "gui.extension.dfr0486.description": "I2C OLED-12864 Ekran Modülü",
    "gui.extension.fit0352.name": "WS2812 RGB LED Şeriti",
    "gui.extension.fit0352.description": "WS2812-temelli LED şerit modüllerini kontrol için",
    "gui.extension.dfr0063.name": "LCD1602 Ekranı",
    "gui.extension.dfr0063.description": "2 satır ve her satırda 16 harf gösteren LCD modülü",
    "gui.extension.dfr0021.name": "Dijital LED Işık Modülü",
    "gui.extension.dfr0021.description": "Beyaz, Kırmızı, Yeşil ve Mavi LED Modülleri",
    "gui.extension.tm1650.name": "TM1650 dört basamaklı tüp",
    "gui.extension.tm1650.description": "Dört basamaklı tüp",
    "gui.extension.matrix8_8.name": "MAX7219 8x8 nokta matris",
    "gui.extension.matrix8_8.description": "8x8 kafes modülü",
    "gui.extension.dfr0522.name": "8x16 RGB LED matris paneli",
    "gui.extension.dfr0522.description": "Görüntüleri, sayıları gösterme ... ekran kaydırma ve kullanıcı tanımlı ayar desteği\r\n",
    "gui.extension.music.name": "Müzik",
    "gui.extension.music.description": "Çalgıları ve davulları çal.",
    "gui.extension.pen.name": "Kalem",
    "gui.extension.pen.description": "Kuklan ile çiz.",
    "gui.extension.video-sensing.name": "Video Algılama",
    "gui.extension.video-sensing.description": "Kamerayla hareket algılama.",
    "gui.extension.google-translate.name": "Tercüme",
    "gui.extension.google-translate.description": "Metinleri değişik dillere çevir.",
    "gui.extension.text-speech.name": "Metinden Konuşmaya",
    "gui.extension.text-speech.description": "Projelerini Konuştur.",
    "gui.extension.softSerialport.name": "Yazılım Seri Portu",
    "gui.extension.softSerialport.description": "Seri iletişim işlevini simüle etmek için G / Ç bağlantı noktasını kullanın",
    "gui.extension.IICScan.name": "I2C Tarama",
    "gui.extension.IICScan.description": "I2C arayüzüne bağlı tüm cihaz adreslerini taramak için",
    "gui.extension.bluetooth.name": "Bluetooth",
    "gui.extension.bluetooth.description": "Bluetooth aygıtı",
    "gui.extension.goble.name": "GoBLE",
    "gui.extension.goble.description": "Cep telefonuyla ana kontrol panelini kontrol etmek için GoBLE APP ile kullanın",
    "gui.extension.interrupt.name": "Kesme",
    "gui.extension.interrupt.description": "Kesme Pini",
    "gui.extension.multithread.name": "Çoklu Kullanım",
    "gui.extension.multithread.description": "Birden fazla programın ve ana programın aynı anda çalışması",
    "gui.extension.speech.name": "Konuşma",
    "gui.extension.speech.description": "Projelerinizle konuşun.",
    "gui.extension.sen0001.name": "Ultrason Sensör",
    "gui.extension.sen0001.description": "2 - 800cm mesafe algılaması",
    "gui.extension.dfr0023.name": "Analog LM35 Sıcaklık  Sensörü",
    "gui.extension.dfr0023.description": "LM35-temelli semikondüktör ısı sensörü 0-100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22 Sıcaklık ve Rutubet Sensörü",
    "gui.extension.dhtTHSensor.description": "Ortamın sıcaklık ve rutubetini algılayın",
    "gui.extension.dsTSensor.name": "DS18B20 Sıcaklık Sensörü",
    "gui.extension.dsTSensor.description": "Ortam sıcaklığı algılayın -55 - +125℃",
    "gui.extension.sen0203.name": "Kalp atış hızı monitörü sensörü",
    "gui.extension.sen0203.description": "Analog nabız ve dijital kare dalga çıkış modları ile mini kalp atış hızı sensörü",
    "gui.extension.sen0177.name": "Laser PM2.5 Hava Kalite Sensörü",
    "gui.extension.sen0177.description": "PM1, PM2.5 and PM10 ölç. Seri port üzerinden veri aktarımı.",
    "gui.extension.sen0014.name": "GP2Y0A21 IR Mesafe sensörü",
    "gui.extension.sen0014.description": "10-80 cm ölçüm aralığına sahip GP2Y0A21 tabanlı IR Sensörü",
    "gui.extension.sen0204.name": "Temassız Sıvı Seviye Sensörü",
    "gui.extension.sen0204.description": "Temassız Sıvı Seviye algılaması",
    "gui.extension.sen0161.name": "Analog pH Metre",
    "gui.extension.sen0161.description": "Sıvının pH değerini ölçün 5V güç",
    "gui.extension.sen0161-v2.name": "Analog pH Metre(V2)",
    "gui.extension.sen0161-v2.description": "Sıvının pH değerini ölçün 3.3-5.5V güç",
    "gui.extension.sen0244.name": "Analog TDS Sensörü",
    "gui.extension.sen0244.description": "Sıvının TDS'sini (Toplam Çözünmüş Katılar) ölçün",
    "gui.extension.sen0165.name": "Analog ORP Metre",
    "gui.extension.sen0165.description": "Sıvının ORP'sini (Yükseltgenme-İndirgenme Potansiyeli) ölçün",
    "gui.extension.sen0237.name": "Analog Çözünmüş Oksijen Sensörü",
    "gui.extension.sen0237.description": "Sıvının DO (Çözünmüş Oksijeni) ölçün",
    "gui.extension.dfr0300-H.name": "Analog EC Metre",
    "gui.extension.dfr0300-H.description": "Sıvının EC değerini (Elektriksel İletkenlik) ölçün",
    "gui.extension.dfr0300.name": "Analog EC Metre(V2)",
    "gui.extension.dfr0300.description": "Sıvının EC değerini (Elektriksel İletkenlik) ölçün",
    "gui.extension.sen0170.name": "Anemometre",
    "gui.extension.sen0170.description": "Rüzgar hızı seviyesini ve çıkış voltaj sinyalini ölçün",
    "gui.extension.sen0226.name": "BMP280 Sıcaklık Sensörü",
    "gui.extension.sen0226.description": "BMP280 sıcaklık ve barometrik ölçüm için barometrik basınç sensörü",
    "gui.extension.sen0228.name": "I2C VEML7700 Ortam Işığı Sensörü",
    "gui.extension.sen0228.description": "Yüksek hassasiyetli ortam ışığı dijital 16 bit çözünürlük sensörü",
    "gui.extension.dfr0022.name": "Analog Gri Tonlama Sensörü",
    "gui.extension.dfr0022.description": "Işık yoğunluğunu algıla ve analog voltaj sinyalini yansıt, çizgi izlemeye uygulanabilir.\r\n",
    "gui.extension.dfr0552.name": "12-bir DAC modülü",
    "gui.extension.dfr0552.description": "Dijital miktarı doğru şekilde ilgili dc voltaj sinyaline dönüştürün (pwm olmayan)",
    "gui.extension.dfr0576.name": "I2C kaskad genişletici",
    "gui.extension.dfr0576.description": "I2C cihaz adres çakışmalarını çözmek için kullanılır",
    "gui.extension.sen0248.name": "BME680 çevre sensörü",
    "gui.extension.sen0248.description": "VOC (uçucu organik bileşikler), sıcaklık, nem ve hava basıncı ölçülebilir",
    "gui.extension.sen0304.name": "I2C ultrasonik ölçüm sensörü",
    "gui.extension.sen0304.description": "Hızlı değişen veya engellerden kaçınma uygulamaları için tasarlanmış ultrasonik sensörler",
    "gui.extension.sen0307.name": "Analog ultrasonik aralık sensörü",
    "gui.extension.sen0307.description": "Açık çift prob analog ultrasonik aralık modülü",
    "gui.extension.sen0250.name": "BMI160 6 eksenli atalet hareket sensörü",
    "gui.extension.sen0250.description": "16-bit 3-eksenli ivme + ultra-düşük güç tüketimi 3-eksenli jiroskop",
    "gui.extension.sen0224.name": "I2C LIS2DH üç eksenli ivmeölçer",
    "gui.extension.sen0224.description": "Ultra düşük güç tüketimi 3 eksenli ivme sensörü",
    "gui.extension.dfr0151.name": "Gerçek Zamanlı Saat DS1307",
    "gui.extension.dfr0151.description": "24 saatte 1 saniye hatalı",
    "gui.extension.dfr0469.name": "Gerçek zaman saati SD2405",
    "gui.extension.dfr0469.description": "\r\nKristal telafili, dahili şarj edilebilir pil ile doğru bir I2C gerçek zamanlı saat (RTC).",
    "gui.extension.dfr0126.name": "Spektrum analizi modülü",
    "gui.extension.dfr0126.description": "Müzik frekansını takip edin ",
    "gui.extension.dfr0231.name": "NFC modülü",
    "gui.extension.dfr0231.description": "Yakın alan kablosuz iletişime uygun",
    "gui.extension.sen0245.name": "VL53L0X lazer ölçüm sensörü",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "Analog Ortam Işık Sensörü",
    "gui.extension.dfr0026.description": "Ortamdaki ışık yoğunluğunu algıla",
    "gui.extension.dfr0027.name": "Dijital Titreşim Sensörü",
    "gui.extension.dfr0027.description": "Titreşim sinyalini algıla dijital çıktılı",
    "gui.extension.dfr0028.name": "Dijital Eğim Sensörü",
    "gui.extension.dfr0028.description": "Cıva anahtarlarının sayısına bağlı olarak, basit bir eğim sensörü olarak kullanılabilir\r\n",
    "gui.extension.dfr0029.name": "Dijital Buton",
    "gui.extension.dfr0029.description": "Basılı:yüksek sinyal  Basılmamış: düşük sinyal",
    "gui.extension.dfr0030.name": "Dijital Kapasitif Dokunmatik Sensör",
    "gui.extension.dfr0030.description": "Metal ve insan vücudunu algılamak için kapasitif dokunmatik anahtar modülü",
    "gui.extension.dfr0033.name": "Dijital Magnetic Sensor",
    "gui.extension.dfr0033.description": "3 cm içindeki manyetik malzemeleri algıla",
    "gui.extension.dfr0034.name": "Analog Ses Sensörü",
    "gui.extension.dfr0034.description": "Ortamdaki gürültüyü algıla",
    "gui.extension.sen0132.name": "Analog Karbon Monoksit Sensörü",
    "gui.extension.sen0132.description": "20 ila 2000ppm arasındaki CO-gaz konsantrasyonlarını tespit edin",
    "gui.extension.dfr0051.name": "Analog Gerilim Bölücü",
    "gui.extension.dfr0051.description": "(DC) 0,0245V ile 25V arası gerilimi tespit edin",
    "gui.extension.dfr0052.name": "Analog Piezo Disk Titreşim Sensörü",
    "gui.extension.dfr0052.description": "Dinamik ve yarı-statik gerilmeyi tespit etmek",
    "gui.extension.dfr0058.name": "Analog Rotasyon Potansiyometre Sensörü",
    "gui.extension.dfr0058.description": "Maksimum dönüş sayısı 10 olan yüksek hassasiyetli potansiyometre",
    "gui.extension.dfr0061.name": "Joystick Modülü",
    "gui.extension.dfr0061.description": "(X,Y) analog çıktı, (Z) 1 dijital çıktı. Uzaktan kumanda olarak kullanılabilir",
    "gui.extension.dfr0072.name": "Çıkış Kaydırma Sensörü",
    "gui.extension.dfr0563.name": "pil sensörü",
    "gui.extension.dfr0553.name": "16-bit ADC modülü",
    "gui.extension.dfr0076.name": "Analog alev sensörü",
    "gui.extension.dfr0076.description": "760nm-1100nm dalga boyunda alev veya ışığı algıla",
    "gui.extension.dfr0143.name": "Üç Eksenli İvmeölçer  MMA7361",
    "gui.extension.dfr0143.description": "MMA7361 yonga temelli. Hareket ve hareket yönü tespiti",
    "gui.extension.sen0018.name": "Dijital Kızılötesi Hareket Sensörü",
    "gui.extension.sen0018.description": "İnsan veya hayvan hareketini tespit etme",
    "gui.extension.sen0114.name": "Analog Toprak Nemi Sensörü",
    "gui.extension.sen0114.description": "Topraktaki nemi tespit etme",
    "gui.extension.sen0121.name": "Buhar Sensörü",
    "gui.extension.sen0121.description": "Yağmur suyu, buhar ve su sisi tespit edin",
    "gui.extension.sen0212.name": "Renk tanımlama sensörü",
    "gui.extension.sen0212.description": "Nesnelerin renklerini tanımlar ve RGB değerini verir",
    "gui.extension.sen0253.name": "10DOF tutum sensörü",
    "gui.extension.sen0253.description": "BBNO055, akıllı 9 eksenli mutlak yönlendirme sensörünü uygulamak için yeni bir sensör IC'sidir.",
    "gui.extension.sen0202.name": "3D Hareket Mini Sensörü",
    "gui.extension.sen0202.description": "Saat yönünde / saat yönünün tersine dönme ve hareket yönlerini tespit etme",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "Micro:bit özel lityum pil motor genişletme kartı",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "Cihazın Wi-Fi ağına bağlanmasını sağlayın",
    "gui.extension.udp.name": "UDP broadcast",
    "gui.extension.udp.description": "Let devices and devices in the local area network realize multi-machine broadcast communication through UDP protocol",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "Cihazların MQTT protokolünü kullanarak iletişim kurmasını sağlayın",
    "gui.extension.speechRecognition.name": "Konuşma tanıma",
    "gui.extension.speechRecognition.description": "Konuşma tanıma",
    "gui.gui.loaded": "yüklü",
    "gui.gui.notLoaded": "yüklü değil",
    "gui.gui.cantFindYouWant": "Ne istediğini bulamadın mı?",
    "gui.gui.clickHere": "Yardım için",
    "gui.gui.viewHelp": "buraya TIKLAYIN",
    "gui.gui.uploadToDev": "Yükleme",
    "gui.gui.compileAndUpload": "Derlenmiş Yükleme",
    "gui.gui.compileOnly": "Salt derlenmiş",
    "gui.gui.compileOpen": "Derlenmiş Aç",
    "gui.gui.burnBootloader": "Kablosuz bootloader yaz",
    "gui.gui.arduinoRun": "Çalıştır",
    "gui.gui.arduinoSave": "Kaydet",
    "gui.gui.autoGenerated": "Oluşturulan kod",
    "gui.gui.manualEditing": "Kendin Düzenle",
    "gui.gui.codeBox": "Kod Kutuları",
    "gui.gui.moduleCode": "Modül Kodu",
    "gui.menu.edit.undo": "Geri Al",
    "gui.menu.edit.redo": "Yeniden Yap",
    "gui.menu.edit.selectAll": "Tümünü Seç",
    "gui.menu.edit.cut": "Kes",
    "gui.menu.edit.copy": "Kopyala",
    "gui.menu.edit.paste": "Yapıştır",
    "gui.menu.edit.codeStyle": "Kod Stili",
    "gui.menu.edit.fontSize": "Yazı boyutu",
    "gui.menu.edit.clearCache": "Önbelleği temizle",
    "gui.menu.edit.maxReset": "Maksimumu fabrika ayarlarına geri yükleme",
    "gui.gui.serial": "Seri",
    "gui.gui.openSerial": "Seri aç",
    "gui.gui.closeSerial": "Seri kapa",
    "gui.gui.close": "Kapa",
    "gui.gui.open": "Aç",
    "gui.gui.clearOutput": "Mesajları Sil",
    "gui.gui.scroll": "Kaydır",
    "gui.gui.send": "Yolla",
    "gui.gui.baud": "Baud",
    "gui.gui.noLineEnd": "Satırsonu yok",
    "gui.gui.newLine": "Yeni satır",
    "gui.gui.carriageReturn": "Satır başı",
    "gui.gui.bothNLandCR": "LF & CR",
    "gui.gui.unavailableHexData": "Invalid hex form! Please represent hex data with two characters ((0-9/A-F) and a blank, e.g. \"00 01 A2 FF\".",
    "gui.gui.hexForm": "Hex Form",
    "gui.gui.openHexForm": "Use hex form to show and send data",
    "gui.gui.closeHexForm": "Close",
    "gui.progress.burning": "Yazıyor",
    "gui.progress.compiling": "Derleniyor",
    "gui.burner.printObjectTooBig": "The project is too large; please click the \"help\" in the right top to reduce its size. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooBig": "Not enough memory; please click the \"help\" in the right top to reduce memory occupancy. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Low memory available, stability problems may occur. ",
    "gui.burner.printSizeError": "Program boyutu belirlenemedi ",
    "gui.burner.UDiskWriteError": "Write to UDisk error",
    "gui.burner.openPortError": "Could not open port: Access is denied",
    "gui.burner.UDiskPopUp": "UDisk pops up, please wait for a while and try again",
    "gui.burner.swdCfgError": "Cannot write to cmsis-dap.cfg",
    "gui.burner.burnError": "Burn error",
    "gui.burner.linkError": "Link error",
    "gui.burner.compileError": "Compile error",
    "gui.burner.generateHexError": "Generate .hex error",
    "gui.burner.generateBinError": "Generate .bin error",
    "gui.burner.eraseError": "Erase error",
    "gui.burner.noMicrobit": "Sorry it is not micro: bit, please connect micro: bit",
    "gui.burner.findLeonardoPortError": "Cannot find bootlaoder port of Leonardo",
    "gui.burner.printSize": "Proje, [%TEXTSIZE] bayt kullanıyor, [%TEXTSIZEPRE]) program bellek alanını kullanıyor, [%TEXTSIZESUR] bayt, [% TEXTSIZEMAX] bayta kadar bırakıyor. \nGlobal değişkeni, [%DATASIZE] bayt, ([%DATASIZEPRE]) dinamik bellek kullanır, [%DATASIZESUR] bayt yerel değişkenleri [%DATASIZEMAX] bayta kadar bırakır. ",
    "gui.progress.compileSuccess": "derleme başarılı",
    "gui.progress.compileProgress": "Derleme Durumu",
    "gui.progress.uploadProgress": "Yükleme Durumu",
    "gui.progress.uploadSuccess": "Yükleme başarılı",
    "gui.progress.errMsg": "Yükleme hatası, hata mesajını konsolun sağ alt köşesinde görebilirsiniz",
    "gui.mainHeader.err": "#Error: [%1]  program başlığı yalnızca bir kere kullanılabilir. Lütfen kopyalanan modülü silin.",
    "gui.mainHeader.help": "#Yardım İpucu: Aynı anda birden fazla programın yürütülmesi gerekiyorsa, lütfen \" Eklentiler \" deki \" çoklu Kullanım işlevi \" kullanın, lütfen yardım belgelerine bakın.",
    "gui.threadHeader.err1": "#Error: [%1]  program başlığı aynı anda kullanılamaz. Farklı kitlere veya kartlara aittirler.",
    "gui.threadHeader.err2": "#Hata İpucu: [%1]  olay başlığı yalnızca bir kere  kullanılabilir. Lütfen kopyalanan modülü silin",
    "gui.howtos.spin-around.name": "Etrafında dön",
    "gui.howtos.spin.step_dragTurn": "Bir [dönüş] bloğu sürükleyin",
    "gui.howtos.spin.step_clickTurn": "çalıştırmak için bloğu tıklayın",
    "gui.howtos.spin.step_clickControl": "[Kontrol] kategorisini tıklayın",
    "gui.howtos.spin.step_dragForever": "Bir [devamlı] bloğu sürükleyin",
    "gui.howtos.spin.step_clickForever": "Çalıştırmak için blok yığınını  tıklayın",
    "gui.howtos.spin.step_changeColor": "[Renk etkisini değiştir] bloğu ekleyin",
    "gui.howtos.say-hello.name": "Merhaba de",
    "gui.howtos.say-hello.step_addSprite": "Yeni bir kukla ekle",
    "gui.howtos.say-hello.step_clickLooks": "[Görünüm] kategorisini tıklayın",
    "gui.howtos.say-hello.step_dragSay": "Bir [de] bloğunu sürükleyin",
    "gui.howtos.say-hello.step_clickSay": "Çalıştırmak için bloğu tıklayın",
    "gui.howtos.say-hello.step_anotherSay": "Başka bir [de] bloğunu sürükleyin",
    "gui.howtos.say-hello.step_editSay": "Başka bir şey söyleyin",
    "gui.howtos.say-hello.step_clickStack": "Çalıştırmak için blok yığınını  tıklayın",
    "gui.howtos.run-away.name": "Kaç",
    "gui.howtos.run-away.step_dragGoTo": "Bir [rasgele konuma git] bloğunu sürükleyin",
    "gui.howtos.run-away.step_clickGoTo": "Çalıştırmak için bloğu tıklayın",
    "gui.howtos.run-away.step3": "Bir [Olaylar] kategorisini tıklayın",
    "gui.howtos.run-away.step_addWhenClicked": "Bir [bu kukla tıkalndığında] bloğunu ekleyin",
    "gui.howtos.run-away.step_clickSprite": "Çalıştırmak için kuklayı tıklayın",
    "gui.howtos.run-away.step_addSound": "Bir [ses başlat] bloğunu ekleyin",
    "gui.howtos.pick-up-apple.name": "Elma Topla Oyunu",
    "gui.howtos.pick-up-apple.step_1": "SCRATCH Arayüzüne Geç",
    "gui.howtos.pick-up-apple.step_2": "Donanım Değiştir",
    "gui.howtos.pick-up-apple.step_3": "Cihazı bağlayın",
    "gui.howtos.pick-up-apple.step_4": "Pusula kalibrasyonu",
    "gui.howtos.pick-up-apple.step_5": "SCRATCH örnek programlarını açın",
    "gui.howtos.pick-up-apple.step_6": "Elma Topla Oyunu",
    "gui.howtos.move-left-right.name": "micro:bit ve Meow",
    "gui.howtos.move-left-right.step_1": "Yeni bir proje yaratın",
    "gui.howtos.move-left-right.step_2": "Blok1 i sürükleyin",
    "gui.howtos.move-left-right.step_3": "Blok2 yi sürükleyin",
    "gui.howtos.move-left-right.step_4": "sola sağa gidin",
    "gui.howtos.install-driver.name": "Sürücüyü yükleyin",
    "gui.howtos.transform-expression.name": "Gülümse veya Ağla",
    "gui.howtos.transform-expression.step_1": "Koda geçin",
    "gui.howtos.transform-expression.step_2": "Donanaım değiştirin",
    "gui.howtos.transform-expression.step_3": "Blok 1 i sürükleyin",
    "gui.howtos.transform-expression.step_4": "Blok 2 yi sürükleyin",
    "gui.howtos.transform-expression.step_5": "Blok 3 ü sürükleyin",
    "gui.howtos.transform-expression.step_6": "gülme veya ağlamaya dönüştür",
    "gui.howtos.lot.name": "IOT (Nesnelerin İnterneti)",
    "gui.howtos.lot.step_1": "Örnek program seçin",
    "gui.howtos.lot.step_2": "Programı yükleyin",
    "gui.howtos.lot.step_3": "Özellik şeması",
    "gui.howtos.touch-pin.name": "Pine dokun",
    "gui.howtos.touch-pin.step_1": "Koda geçin",
    "gui.howtos.touch-pin.step_2": "İşlev Seçin",
    "gui.howtos.touch-pin.step_3": "Programı yükleyin",
    "gui.howtos.touch-pin.step_4": "Özellik şeması",
    "gui.howtos.save-witch.name": "Cadıyı Kurtar",
    "gui.howtos.save-witch.step_1": "SCRATCH e geçin",
    "gui.howtos.save-witch.step_2": "micro:bit' seçin",
    "gui.howtos.save-witch.step_3": "Örnek Programda 'Cadıyı Kurtarın'ı seçin",
    "gui.howtos.save-witch.step_4": "Cihazı bağlayın, micro:bit'i seçin, bağlantının başarılı olmasını bekleyin",
    "gui.howtos.save-witch.step_5": "Cihazı kalibre etmeniz' istenirse, ana kontrol kartını LED sönene kadar çevirin",
    "gui.howtos.save-witch.step_6": "Yeşil bayrak üzerine tıklayın ve animasyonla etkileşime geçmek için kolunuzu sallayın",
    "gui.howtos.microbit-touch.name": "Gülümsemek veya Ağlamak için Tıkla",
    "gui.howtos.microbit-touch.step_1": "Kod moduna geçin",
    "gui.howtos.microbit-touch.step_2": "Öğrenme' menüsü altındaki 'Örnek programı' açın ve 'Pin e dokun'u seçin",
    "gui.howtos.microbit-touch.step_3": "Cihazın ilgili COM portunu bağlayın ve 'Cihaza Yükle'yi tıklayın",
    "gui.howtos.microbit-touch.step_4": "Farklı ifadeleri göstermek için farklı tuşlara basın",
    "gui.howtos.microbit-calibration.name": "micro:bit Kalibrasyonu",
    "gui.howtos.microbit-calibration.step_1": "micro:bit kartını yere dik olarak döndürün ve yerleşik LED ışıkları sırayla yanacaktır.",
    "gui.howtos.microbit-calibration.step_2": "LED ışıkların tümü yanana kadar döndürün (aşağıda gösterildiği gibi), kalibrasyon tamamlandıktan sonra bir gülümseme görüntülenecektir",
    "gui.extension.sen0251.name": "BMP388 hava basıncı sıcaklık sensörü",
    "gui.extension.sen0251.description": "Sıcaklık, atmosferik basınç, yükseklik algılama fonksiyonu",
    "gui.extension.sen0206.name": "Temassız kızılötesi sıcaklık sensörü",
    "gui.extension.sen0206.description": "Far-infrared non-contact measurement of object or ambient temperature",
    "gui.gui.recentlyEdited": "Son düzenlenmiş",
    "gui.extension.weather.name": "Hava durumunu al",
    "gui.extension.weather.description": "Hava raporunu almak için bir Wi-Fi aygıtı gerekmektedir.",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "TinyWebDB ağ veri tabanı kullan. APP Inventor ile de uygundur.",
    "gui.gui.variableScopeOptionAllSprites": "Tüm kuklalar için",
    "gui.gui.variableScopeOptionSpriteOnly": "Sadece bu kukla için",
    "gui.gui.variablePromptAllSpritesMessage": "Bu değişken tüm kuklalar için geçerli olacak.",
    "gui.monitor.contextMenu.default": "normal görünüm",
    "gui.monitor.contextMenu.large": "geniş görünüm",
    "gui.monitor.contextMenu.slider": "slider",
    "gui.monitor.contextMenu.import": "Dışarıdan al",
    "gui.monitor.contextMenu.export": "Dışarıya aktar",
    "gui.monitor.listMonitor.listLength": "uzunluğu {length}",
    "gui.monitor.listMonitor.empty": "(boş)",
    "gui.costumeTab.backdrop": "Dekor",
    "gui.costumeTab.costume": "Kostüm",
    "gui.opcodeLabels.direction": "yön",
    "gui.opcodeLabels.xposition": "x konumu",
    "gui.opcodeLabels.yposition": "y konumu",
    "gui.opcodeLabels.size": "büyüklük",
    "gui.opcodeLabels.costumename": "kostüm ismi",
    "gui.opcodeLabels.costumenumber": "kostüm numarası",
    "gui.opcodeLabels.backdropname": "dekorun adı",
    "gui.opcodeLabels.backdropnumber": "dekor numarası",
    "gui.opcodeLabels.volume": "ses düzeyi",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "cevap",
    "gui.opcodeLabels.loudness": "ses yüksekliği",
    "gui.opcodeLabels.year": "yıl",
    "gui.opcodeLabels.month": "ay",
    "gui.opcodeLabels.date": "tarih",
    "gui.opcodeLabels.dayofweek": "haftanın günü",
    "gui.opcodeLabels.hour": "saat",
    "gui.opcodeLabels.minute": "dakika",
    "gui.opcodeLabels.second": "saniye",
    "gui.opcodeLabels.timer": "zamanlayıcı",
    "gui.loadProject.error": "proje yükleme hatası",
    "gui.fileSystem.boardFiles": "Kart Dosyaları",
    "gui.fileSystem.computerFiles": "Kart Bilgisayar",
    "gui.fileSystem.newFile": "yeni dosya",
    "gui.fileSystem.newFolder": "yeni dosya",
    "gui.fileSystem.deleteFolder": "dosyayı sil",
    "gui.fileSystem.openFolder": "dıosyayı aç",
    "gui.fileSystem.openInEditor": "düzenleyicide aç",
    "gui.fileSystem.runRightNow": "hemen çalıştır",
    "gui.fileSystem.stopRun": "durdur",
    "gui.fileSystem.setBoot": "boot yap",
    "gui.fileSystem.deleteForever": "kalıcı olarak sil",
    "gui.fileSystem.saveAs": "farklı kaydet",
    "gui.fileSystem.undoAllEdits": "tüm düzenlemeleri iptal et",
    "gui.fileSystem.copyToComputer": "bilgisayara kopyala",
    "gui.fileSystem.uploadToBoard": "karta yükle",
    "gui.fileSystem.uploadAndRun": "yükle ve çalıştır",
    "gui.extension.motucamera.name": "MU Sensör kartı",
    "gui.fileSystem.fileCatalogue": "Katalog",
    "gui.wifi.pwdNotLegal": "! Hata: Şifre yanlış formatta. Wi-Fi şifresi 8-20 basamak veya rakam olmalıdır",
    "gui.spreadmaqueen.perror": "! Error: P12ye bağlı led lamba mevcut değil. Lütfen P8e bağlı olanı seçiniz.\r\n",
    "gui.gui.viewConflict": "çatışmayı görüntüle",
    "music.categoryName": "Müzik",
    "translate.categoryName": "Çeviri",
    "sensor.categoryName": "Sensör",
    "actuator.categoryName": "Aktüatör",
    "communicate.categoryName": "İletişim",
    "display.categoryName": "Ekran",
    "extendFunction.categoryName": "Fonksiyon",
    "internetService.categoryName": "İnternet",
    "arduinounoR3.categoryName": "UNO Kiti",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "handpy",
    "spread.categoryName": "Genişletme Kartı",
    "speechRecognition.categoryName": "Konuşma tanıma",
    "gui.blocklyText.romeo.d1ProgramStart": "D1 robot programı başlıyor",
    "gui.blocklyText.romeo.readdigital": "dijital değer oku pin[PIN] [MODULE] ",
    "gui.blocklyText.romeo.buttonModule": "Buton Modülü",
    "gui.blocklyText.romeo.digitalSensor": "Dijital Sensör",
    "gui.blocklyText.romeo.collisionSensor": "Çarpışma Sensörü",
    "gui.blocklyText.romeo.lineSensor": "Çizgi Sensörü",
    "gui.blocklyText.romeo.infraredSwitch": "Kızılötesi Anahtar",
    "gui.blocklyText.romeo.vibrationSensor": "Titreşim Sensörü",
    "gui.blocklyText.romeo.readAnalog": "analog değer oku [PIN] [SENSOR] ",
    "gui.blocklyText.romeo.lightSensor": "İşik Sensörü",
    "gui.blocklyText.romeo.anologSensor": "Analog Sensör",
    "gui.blocklyText.romeo.soundSensor": "Ses Sensörü",
    "gui.blocklyText.romeo.soilMoistureSensor": "Toprak Nem Sensörü",
    "gui.blocklyText.romeo.frameSensor": "Gövde Senörü",
    "gui.blocklyText.romeo.open": "Aç",
    "gui.blocklyText.romeo.close": "Kapat",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] bu pinin: [PIN] ",
    "gui.blocklyText.romeo.ledLights": "LED ışıklar",
    "gui.blocklyText.romeo.digitalActuator": "Dijital Aktüatör",
    "gui.blocklyText.romeo.relay": "Röle",
    "gui.blocklyText.romeo.fan": "Fan",
    "gui.blocklyText.romeo.writeAnalog": "pin [PIN] [MODULE] değerini [VALUE] yap",
    "gui.blocklyText.romeo.simulationActuator": "Simülasyon Aktüatörü\r\n",
    "gui.blocklyText.romeo.fanModule": "Fan Modülü",
    "gui.blocklyText.romeo.robotSpeedMove": "robot [DIR] hız: [SPEED]",
    "gui.blocklyText.romeo.forward": "ileri git",
    "gui.blocklyText.romeo.backward": "geri git",
    "gui.blocklyText.romeo.turnLeft": "sola dön",
    "gui.blocklyText.romeo.turnRight": "sağa dön",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "motor [MOTOR] [DIR] hız: [SPEED]",
    "gui.blocklyText.romeo.interface": "Arayüz",
    "gui.blocklyText.romeo.motorForward": "İleri",
    "gui.blocklyText.romeo.motorReverse": "Geri",
    "gui.blocklyText.romeo.stopMotor": "robot (M1 ve M2 motorları) durdur",
    "gui.blocklyText.romeo.readUltrasonic": "Ultrasonik mesafe okuma (cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "pin [PIN] LM35 sıcaklığını oku (°C)",
    "gui.blocklyText.romeo.ds18b20": "pimi [PIN] DS18B20 sıcaklığını (°C) oku",
    "gui.blocklyText.romeo.pressInfrared": "kızılötesi pin [PIN] buton [BUTTON] basıldı mı？",
    "gui.blocklyText.romeo.volumePlus": "Ses Seviyesi+",
    "gui.blocklyText.romeo.switch": "Anahtar",
    "gui.blocklyText.romeo.volumeMinus": "Ses Seviyesi-",
    "gui.blocklyText.romeo.startPause": "Başla/Bekle",
    "gui.blocklyText.romeo.up": "Yukarı",
    "gui.blocklyText.romeo.down": "Aşağı",
    "gui.blocklyText.romeo.dataReadable": "Seri portta okunacak veri var mı?",
    "gui.blocklyText.romeo.readSerialData": "seri veri oku",
    "gui.blocklyText.romeo.timer": "sistem çalışma zamanı(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "pin [PIN] trampet tone [TONE] vurgu [BEAT] çal",
    "gui.blocklyText.romeo.half": "Yarım",
    "gui.blocklyText.romeo.quarter": "Çeyrek",
    "gui.blocklyText.romeo.oneInEighth": "Sekizde biri",
    "gui.blocklyText.romeo.wholeBeat": "Bütün Vuruş",
    "gui.blocklyText.romeo.doubleBeat": "Çift Vuruş",
    "gui.blocklyText.romeo.stop": "Dur",
    "gui.blocklyText.romeo.setServo": "pin [PIN] servoyu  [DEGREE] dereceye ayarla",
    "gui.blocklyText.romeo.segment": "sekiz bölümlü LED (D6-D13 pin) ekran [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "seri yolla  [STRING] [WAY], [LINE]",
    "gui.blocklyText.romeo.originalOutput": "başnokta",
    "gui.blocklyText.romeo.stringOutput": "dizi",
    "gui.blocklyText.romeo.hexOutput": "HEX",
    "gui.blocklyText.romeo.wrap": "Kaydır",
    "gui.blocklyText.romeo.noWrap": "Kaydırma",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "seri veri oku [READTYPE]",
    "gui.blocklyText.romeo.int": "tamsayı",
    "gui.blocklyText.romeo.float": "kayan noktalı",
    "gui.blocklyText.romeo.setBaudRate": "seri-port baud değerini  [BAUD] yap",
    "gui.blocklyText.romeo.readPinWidth": "pin [PIN] darbe genişlik [LEVEL] modunda oku, zamanaşımı [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "ALÇAK",
    "gui.blocklyText.romeo.high": "YÜKSEK",
    "gui.blocklyText.romeo.noSound": "sessiz",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino başlıyor",
    "gui.blocklyText.arduino.arduinoUnoStarts": "UNO başlıyor",
    "gui.blocklyText.esp32.displayInLine": "display [TEXT] in the [LINE] line",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "cache [TEXT] in the [LINE] line mode [SHOWMODE]",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "clear cache in the [LINE] line",
    "gui.blocklyText.esp32.displayClearInLine": "clear in the [LINE] line",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 başlıyor",
    "gui.blocklyText.esp32.btnIsPressed": "[BUTTON] butonu [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "buton [REFERENCE] [BUTTONSTATE] olduğunda",
    "gui.blocklyText.esp32.whenPinConnected": "pin [REFERENCE]  [TOUCHSTATE] olduğunda",
    "gui.blocklyText.esp32.onGesture": "[GESTURE] olunca",
    "gui.blocklyText.esp32.pinIsConnected": "pin [PIN] durumu [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "arka planda çalmayı durdur",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "[NOTE] notasını arka planda çal",
    "gui.blocklyText.esp32.setBuzzerFreq": "[NOTE] notasını [BEAT] vuruş çal",
    "gui.blocklyText.esp32.getSoundIntensity": "mikrofon ses ayarını oku",
    "gui.blocklyText.esp32.readBrightness": "ortam ışığı parlaklığını oku",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] [COLOR] göster",
    "gui.blocklyText.esp32.rgbToColor": "kırmızı [RED] yeşil [GREEN] mavi [BLUE]",
    "gui.blocklyText.esp32.acceleration": "ivme oku(mg): [TYPE]",
    "gui.blocklyText.esp32.displayInXY": "ekranda göster [TEXT] konum X: [X]  Y: [Y] , öngörüntü [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "ekranı [COLOR] renk yap (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "önbelleğe [COLORMPYTHON] ekran rengini kaydet (clear screen)",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": "beyaz",
    "gui.blocklyText.esp32.lineDrawing": "çizgi çiz başlangıç x1:[X1] y1:[Y1] son x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "bağlı",
    "gui.blocklyText.esp32.disconnected": "bağlı değil",
    "gui.blocklyText.esp32.rectangleDrawing": "dikdörtgen çiz  [FILL] üst sol köşe x:[X] y:[Y], Genişlik:[WIDTH], Yükseklik:[HEIGHT]",
    "gui.blocklyText.esp32.screenDisplay": "önbellekteki ekran ayarları geçerlidir",
    "gui.blocklyText.esp32.circleDrawing": "daire çiz [FILL] merkez x:[X] y:[Y], yarıçap:[RADIUS]",
    "gui.blocklyText.esp32.fill": "doldur",
    "gui.blocklyText.esp32.notFill": "doldurma",
    "gui.blocklyText.esp32.pointDrawing": "nokta çiz konum  x:[X] y:[Y]",
    "gui.blocklyText.esp32.setLineWidth": "çizgi kalınlığını [LINEWIDTH] yap",
    "gui.blocklyText.esp32.digitalWrite": "dijital pin [PIN] değer [VALUE] yaz",
    "gui.blocklyText.esp32.digitalRead": "dijital pin [PIN] oku",
    "gui.blocklyText.esp32.analogWrite": "analog pin [PIN] değer(PWM) [VALUE] yaz",
    "gui.blocklyText.esp32.analogRead": "analog pin [PIN] oku",
    "gui.blocklyText.esp32.serialPort0": "seri0",
    "gui.blocklyText.esp32.serialPort1": "seri1",
    "gui.blocklyText.esp32.serialPort2": "seri2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX]  baud [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "ekrana [IMAGE] görüntüsünü yaz konum x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "write cache image [IMAGE] at x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "basılı",
    "gui.blocklyText.esp32.loosened": "bırakıldı",
    "gui.blocklyText.esp32.all": "tümü",
    "gui.blocklyText.esp32.setBaud": "seriport0 baud değeri [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "[SERIAL] dan seri veri oku tamsayı veya kayan noktalı [READTYPE]",
    "gui.blocklyText.esp32.setBrightness": "LED parlaklığını [BRIGHTVALUE] yap",
    "gui.blocklyText.esp32.getLightness": "LED parlaklığını oku",
    "gui.blocklyText.esp32.print": "[DATA] yazdır",
    "gui.blocklyText.esp32.TiltForward": "Öne eğ",
    "gui.blocklyText.esp32.TiltBack": "Arkaya eğ",
    "gui.blocklyText.esp32.TiltLeft": "Sola eğ",
    "gui.blocklyText.esp32.TiltRight": "Sağa eğ",
    "gui.blocklyText.esp32.ScreenUp": "Ekran yukarı",
    "gui.blocklyText.esp32.ScreenDown": "Ekran aşağı",
    "gui.blocklyText.esp32.Shake": "Salla",
    "gui.blocklyText.esp32.isGesture": "[GESTURE] mı?",
    "gui.blocklyText.mega2560.functionStarts": "Mege2560 başlıyor",
    "pen.categoryName": "Kalem",
    "pen.changeColorParam": "kalem [COLOR_PARAM][VALUE]artır",
    "pen.changeHue": "kalem rengini [HUE]artır",
    "pen.changeShade": "kalem tonunu [SHADE] artır",
    "pen.changeSize": "kalem kalınlığını [SIZE] artır",
    "pen.clear": "tümünü sil",
    "pen.colorMenu.brightness": "parlaklık",
    "pen.colorMenu.color": "rengi",
    "pen.colorMenu.saturation": "doygunluğunu",
    "pen.colorMenu.transparency": "saydamlık",
    "pen.penDown": "kalemi bastır",
    "pen.penUp": "kalemi kaldır",
    "pen.setColor": "kalem rengini [COLOR] yap",
    "pen.setColorParam": "kalem [COLOR_PARAM] [VALUE] yap",
    "pen.setHue": "kalem rengini [HUE] yap",
    "pen.setShade": "kalem tonunu [SHADE] yap",
    "pen.setSize": "kalem kalınlığını [SIZE] yap",
    "pen.stamp": "damgala",
    "music.changeTempo": "tempoyu [TEMPO] yap",
    "music.drumBass": "(2) Bas Davul",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Bagetler",
    "music.drumClosedHiHat": "(6) Kapalı Hi-Hat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Çan",
    "music.drumCrashCymbal": "(4) Çarpışma Zili",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Alkışla",
    "music.drumOpenHiHat": "(5) Açık Hi-Hat",
    "music.drumSideStick": "(3) yan Çubuk",
    "music.drumSnare": "(1) Trampet",
    "music.drumTambourine": "(7) Tef",
    "music.drumTriangle": "(12) Üçgen",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Fagot",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bas",
    "music.instrumentBassoon": "(14) Fagot",
    "music.instrumentCello": "(8) Çello",
    "music.instrumentChoir": "(15) Koro",
    "music.instrumentClarinet": "(10) Klarnet",
    "music.instrumentElectricGuitar": "(5) Elektro Gitar",
    "music.instrumentElectricPiano": "(2) Elektro Piyano",
    "music.instrumentFlute": "(12) Flüt",
    "music.instrumentGuitar": "(4) Gitar",
    "music.instrumentMarimba": "(19) Klisifon",
    "music.instrumentMusicBox": "(17) Müzik Kutusu",
    "music.instrumentOrgan": "(3) Org",
    "music.instrumentPiano": "(1) Piyano",
    "music.instrumentPizzicato": "(7) Parmak Hareketi",
    "music.instrumentSaxophone": "(11) Saksafon",
    "music.instrumentSteelDrum": "(18) Çelik Tambur",
    "music.instrumentSynthLead": "(20) Synth Kurşun",
    "music.instrumentSynthPad": "(21) Synth Padi",
    "music.instrumentTrombone": "(9) Trombon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Tahta Flüt",
    "music.midiPlayDrumForBeats": "[DRUM] davulunu [BEATS] vuruş çal",
    "music.midiSetInstrument": "Enstrümanı [INSTRUMENT] yap",
    "music.playDrumForBeats": "[DRUM] davulunu [BEATS] vuruş çal",
    "music.playNoteForBeats": "[NOTE] notasını [BEATS] vuruşunda çal",
    "music.restForBeats": "[BEATS] vuruş durakla",
    "music.setInstrument": "Enstrümanı [INSTRUMENT] yap",
    "music.setTempo": "tempoyu [TEMPO] yap",
    "gui.blocklyText.microbit.microbitStarts": "micro:bit başladığında",
    "gui.blocklyText.microbit.interruptExcute": "kesme pin [PIN] mod [MODE]  uygula",
    "gui.blocklyText.microbit.cancleInterrupt": "kesme pin [PIN] iptal et",
    "gui.blocklyText.microbit.wirelessReceiveData": "radyo verisi alındığında",
    "gui.blocklyText.microbit.whenBtnPress": "buton [REFERENCE] basıldığında",
    "gui.blocklyText.microbit.whenPinConnected": "microbit pin [REFERENCE] bağlandığında",
    "gui.blocklyText.microbit.whenPosChange": "[REFERENCE] olduğunda",
    "gui.blocklyText.microbit.showFont": "ekranda [PIC] göster",
    "gui.blocklyText.microbit.showInput": "ekranda [TEXT] göster",
    "gui.blocklyText.microbit.stopAnimation": "animasyonu durdur",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] nokta konumu（x:[XAXIS], y:[YXAXIS]）",
    "gui.blocklyText.microbit.hideAllLights": "ekranı sil",
    "gui.blocklyText.microbit.microbitEnableLight": "ekranı [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "parlaklık",
    "gui.blocklyText.microbit.showLightWithBrightness": "parlaklığı [BRIGHTVALUE] yap",
    "gui.blocklyText.microbit.controlLightBrightness": "nokta konumu (x:[XAXIS], y:[YXAXIS]), parlaklık [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "pin [PIN]  [SOUND] sesini çal",
    "gui.blocklyText.microbit.playSoundUntil": "pin [PIN]  [SOUND] sesini bitene kadar çal",
    "gui.blocklyText.microbit.playNote": "pin [PIN]  [NOTE] notasını [BEAT] vuruş çal",
    "gui.blocklyText.maqueen.playSound": "pin P0  [SOUND] sesini çal",
    "gui.blocklyText.maqueen.playSoundUntil": "pin P0  [SOUND] sesini bitene kadar çal",
    "gui.blocklyText.maqueen.playNote": "pin P0 [NOTE] notasını [BEAT] vuruş çal",
    "gui.blocklyText.maqueen.RGB0": "RGB0  (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1  (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2  (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3  (3)",
    "gui.blocklyText.maqueen.All": "Tümü  (-1)",
    "gui.blocklyText.microbit.changeTempo": "tempoyu (bpm) [VALUE] değiştir",
    "gui.blocklyText.microbit.setTempo": "tempoyu (bpm) [VALUE] yap",
    "gui.blocklyText.microbit.getTempo": "tempo (bpm)",
    "gui.blocklyText.microbit.stopSound": "arka planda çalmayı durdur",
    "gui.blocklyText.microbit.btnIsPressed": "[BUTTON] butonu basılı mı?",
    "gui.blocklyText.microbit.pinIsConnected": "[PIN] pini bağlı mı?",
    "gui.blocklyText.microbit.isMove": "şimdiki durum [TYPE]?",
    "gui.blocklyText.microbit.compass": "pusula yönünü oku",
    "gui.blocklyText.microbit.temperature": "sıcaklığı oku",
    "gui.blocklyText.microbit.digitalWrite": "dijital pin [PIN] değerini [VALUE] yap",
    "gui.blocklyText.microbit.digitalRead": "dijital pin [PIN] oku",
    "gui.blocklyText.microbit.analogWrite": "analog pin [PIN] değerini [VALUE] yap",
    "gui.blocklyText.microbit.analogRead": "analog pin [PIN] oku",
    "gui.blocklyText.microbit.up": "simge yukarı",
    "gui.blocklyText.microbit.down": "simge aşağı",
    "gui.blocklyText.microbit.left": "sola eğ",
    "gui.blocklyText.microbit.right": "sağa eğ",
    "gui.blocklyText.microbit.faceUp": "ekran yukarı",
    "gui.blocklyText.microbit.faceDown": "ekran aşağı",
    "gui.blocklyText.microbit.freefall": "serbest düşüş",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "salla",
    "gui.blocklyText.microbit.show": "göster",
    "gui.blocklyText.microbit.hide": "sakla",
    "gui.blocklyText.microbit.low": "ALÇAK",
    "gui.blocklyText.microbit.high": "YÜKSEK",
    "gui.blocklyText.microbit.microbitReadBrightness": "ortam ışığı parlaklığını oku",
    "gui.blocklyText.microbit.microbitOpenWireless": "Radyoyu [SWITCH]",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "Radyo kanalını [NUM] yap",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "Radyo ile [TEXT] dizisini yolla",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "Radyo verisi al",
    "gui.blocklyText.microbit.strength": "kuvvet",
    "gui.blocklyText.microbit.acceleration": "ivme [TYPE] oku(mg)",
    "gui.blocklyText.microbit.magneticForce": "manyetik kuvvet [TYPE] oku(µT)",
    "gui.blocklyText.microbit.print": "[DATA] yaz",
    "gui.blocklyText.microbit.goUp": "yukarı",
    "gui.blocklyText.microbit.goDown": "aşağı",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "[WirelessData] alındığında",
    "gui.blocklyText.microbit.wirelessData": "radyo verisi",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot Başlıyor",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen Başlıyor",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "(P1,P2) ultrason sensör oku (cm)",
    "gui.blocklyText.maqueen.motorStop": "robot (sol ve sağ motorları) durdur",
    "gui.blocklyText.maqueen.controlLeds": "[LEFTRIGHT] LED ışığı [SWITCH] ",
    "gui.blocklyText.maqueen.redLineSensor": "[LEFTRIGHT] çizgi takip sensörünü oku",
    "gui.blocklyText.maqueen.robotSpeedMove": "robot [DIR] hız: [SPEED]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "motor [LEFTRIGHT] yön: [DIR] hız: [SPEED]",
    "gui.blocklyText.maqueen.ledLeft": "Sol(P8)",
    "gui.blocklyText.maqueen.ledRight": "Sağ(P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "Sağ(P12) (Donanım mevcut değil)",
    "gui.blocklyText.maqueen.lineSensorLeft": "Sol(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "Sağ(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "(P16) [WirelessData] aldığında",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "(P16) kızılötesi sinyal oku",
    "gui.blocklyText.maqueen.numberOfLightsBright": "pin P15 4 RGB LEDin parlaklığını [BRIGHT] yap",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "pin P15 [NUM1] LED rengini [COLOR] yap",
    "gui.blocklyText.maqueen.showGradientColor": "pin P15 LED [NUM1] - [NUM2] renklerini [COLOR1] - [COLOR2] gradyan yap",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "pin P15 pikselleri [NUM] kaydır",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "pin P15 pikselleri [NUM] konum döndür",
    "gui.blocklyText.maqueen.setWs2812Brightness": "pin P15 LED parlaklığı [BRIGHTNESS] yap",
    "gui.blocklyText.maqueen.showHistogram": "pin P15 LED [NUM1] - [NUM2] histogramı göster, değer [VALUE] , max [MAX]",
    "gui.blocklyText.maqueen.clear": "pin P15 LED leri söndür",
    "gui.blocklyText.maqueen.rgbColor": "kırmızı [RED] yeşil [GREEN] mavi [BLUE]",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] veri aldı  mı?",
    "videoSensing.categoryName": "Video Algılama",
    "videoSensing.direction": "yön",
    "videoSensing.motion": "hareket",
    "videoSensing.off": "kapat",
    "videoSensing.on": "aç",
    "videoSensing.onFlipped": "ters çevrilmiş",
    "videoSensing.setVideoTransparency": "videonun saydamlığını [TRANSPARENCY] yap",
    "videoSensing.sprite": "kukla",
    "videoSensing.stage": "sahne",
    "videoSensing.videoOn": "[SUBJECT] konusunda [ATTRIBUTE] videosu",
    "videoSensing.videoToggle": "videoyu aç [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "video hareketi > [REFERENCE] olduğunda",
    "translate.translateBlock": "[WORDS] cümlesini [LANGUAGE] 'ye çevir",
    "translate.defaultTextToTranslate": "merhaba",
    "translate.viewerLanguage": "dil",
    "text2speech.speakAndWaitBlock": "konuş [WORDS]",
    "text2speech.setVoiceBlock": "sesi [VOICE]e ayarla",
    "text2speech.setLanguageBlock": "dili [LANGUAGE]'ye ayarla",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "ciyak",
    "text2speech.giant": "dev",
    "text2speech.kitten": "kedi yavrusu",
    "text2speech.defaultTextToSpeak": "merhaba",
    "speech.whenIHear": "[PHRASE] ifadesini duyduğunda",
    "speech.listenAndWait": "dinle ve bekle",
    "speech.defaultWhenIHearValue": "hadi başlayalım",
    "gui.blocklyText.maqueen.robotServo": "Servo [INDEX] açı [ANGLE]",
    "gui.blocklyText.esp32.rgbDisable": "[INDEX] LEDi söndür",
    "gui.blocklyText.esp32.rgbdisable.all": "Tümü",
    "gui.blocklyText.esp32.rgbdisable.No.0": "No.0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "No.1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "No.2",
    "gui.blocklyText.microbit.true": "aç",
    "gui.blocklyText.microbit.false": "kapa",
    "gui.blocklyText.maqueen.wirelessSignalData": "kızılötesi sinyal",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "ON",
    "gui.blocklyText.maqueenPlus.off": "OFF",
    "gui.blocklyText.maqueenPlus.Forward": "Forward",
    "gui.blocklyText.maqueenPlus.Backward": "Backward",
    "gui.blocklyText.maqueenPlus.left": "Left",
    "gui.blocklyText.maqueenPlus.right": "Right",
    "gui.blocklyText.maqueenPlus.all": "All",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "set motor [DIR] move by [SPEED] speed [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "set motor [DIR] stop",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "motor [DIR] speed compensation [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "read [DIR] motor speed",
    "gui.blocklyText.maqueenPlus.readMoterDir": "read [DIR] motor direction (0:stop 1:forward 2:back)",
    "gui.blocklyText.maqueenPlus.setServo": "Servo [INDEX] angle [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "set [DIR] RGB show [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "read the sensation sensor [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "read the sensation sensor [INDEX] grayscale value",
    "gui.blocklyText.maqueenPlus.getVersion": "get version",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "read ultrasonic sensor trig [TRIG] echo [ECHO] unit [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "when received [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "infrared data",
    "gui.blocklyText.maqueenPlus.cm": "CM",
    "gui.blocklyText.maqueenPlus.inch": "INCH",
    "paint.paintEditor.hue": "Renk",
    "paint.paintEditor.saturation": "Doygunluk",
    "paint.paintEditor.brightness": "Parlaklık",
    "gui.comingSoon.message1": "Endişelenmeyin, üzerinde çalışıyoruz {emoji}",
    "gui.comingSoon.message2": "Çok Yakında...",
    "gui.comingSoon.message3": "Üzerinde çalışıyoruz {emoji}",
    "paint.paintEditor.fill": "Doldur",
    "paint.paintEditor.costume": "Kostüm",
    "paint.paintEditor.group": "Grup",
    "paint.paintEditor.ungroup": "Grubu Dağıt",
    "paint.paintEditor.undo": "Geri Al",
    "paint.paintEditor.redo": "İleri Al",
    "paint.paintEditor.forward": "Öne",
    "paint.paintEditor.backward": "Geriye",
    "paint.paintEditor.front": "En Öne",
    "paint.paintEditor.back": "En Geriye",
    "paint.paintEditor.more": "Daha Fazla",
    "paint.modeTools.brushSize": "Büyüklük",
    "paint.modeTools.eraserSize": "Silgi boyutu",
    "paint.modeTools.copy": "Kopyala",
    "paint.modeTools.paste": "Yapıştır",
    "paint.modeTools.delete": "Sil",
    "paint.modeTools.curved": "Kavisli",
    "paint.modeTools.pointed": "işaretlendi",
    "paint.modeTools.thickness": "Kalınlık",
    "paint.modeTools.flipHorizontal": "Yatay Çevir",
    "paint.modeTools.flipVertical": "Dikey Çevir",
    "paint.modeTools.filled": "Dolmuş",
    "paint.modeTools.outlined": "Ana Hatlarıyla Çizilen ",
    "paint.paintEditor.bitmap": "Bitmap'e Dönüştür",
    "paint.paintEditor.vector": "Vektöre Dönüştür",
    "paint.paintEditor.stroke": "Kontur",
    "paint.brushMode.brush": "Fırça",
    "paint.eraserMode.eraser": "silgi",
    "paint.fillMode.fill": "Doldur",
    "paint.lineMode.line": "Çizgi",
    "paint.ovalMode.oval": "Çember",
    "paint.rectMode.rect": "Dikdörtgen",
    "paint.reshapeMode.reshape": "Şekli değiştir",
    "paint.roundedRectMode.roundedRect": "Yuvarlatılmış dikdörtgen",
    "paint.selectMode.select": "Seç",
    "paint.textMode.text": "Metin",
    "paint.colorPicker.swap": "Değiştir",
    "gui.dialog.notConnectDevice": "Cihaz bağlı değil",
    "gui.dialog.prompt": "İleti",
    "gui.dialog.openPort": "Port Aç",
    "gui.dialog.error": "Hata",
    "gui.dialog.notSelectDevice": "Hiçbir cihaz seçilmemiş. Lütfen yazılımın sol alt köşesindeki [Eklentiler'e]  tıklayın ve </br>bağlantı kurmak istediğiniz cihazı seçin.",
    "gui.dialog.connectDeviceError1": "Bağlantı başarısız: (",
    "gui.dialog.connectDeviceError2": ")</br>Lütfen aşağıdakileri kontrol edin:</br> * USB bağlantı noktasını değiştirin ve USB kablosunu yeniden takın </br> * Cihaz bağlantısını kontrol edin: ",
    "gui.dialog.connectDeviceError3": "</br>Ana Kontrol Paneli:</br>* Sorularınız için İletişim Grubu'na katılın (671877416) ",
    "gui.dialog.calibration1": "</br> *Kalibrasyon için ana kontrol",
    "gui.dialog.calibration2": "kartını çevirin</br>",
    "gui.dialog.runAsManager": "Please close Mind+ and right click on the software icon and select \"Run as administrator\". After starting, select this function again.",
    "gui.dialog.runErrorForVortex": "Sistem düzgün çalışmıyor, Vortex bağlantısından çıkın",
    "gui.dialog.runError": "Sistem normal çalışmıyor",
    "gui.dialog.close": "Kapat",
    "gui.dialog.variableNameSpecialCharacters": "%1 değişken adı bu özel karakterleri içeremez:[\\`~!@#$%^&*()+<>?:\"{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "%1 fonksiyon adı bu özel karakterleri içeremez:[\\`~!@#$%^&*()+<>?:\"{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "%1 fonksiyon parametreleri bu özel karakterleri içeremez:[\\`~!@#$%^&*()+<>?:\"{},./;\\'[]\\]",
    "gui.dialog.and": "ve",
    "gui.dialog.yes": "Evet",
    "gui.dialog.no": "Hayır",
    "gui.dialog.maxReset": "Düzenleme alanındaki kodu silip fabrika ayar kodu yüklensin mi?",
    "gui.dialog.feedbackCannotEmpty": "Geri Bildirim boş bırakılamaz!",
    "gui.dialog.noInstallCompiler": "Derleyici kurulmamış",
    "gui.dialog.install": "Kur Çevrimiçi",
    "gui.dialog.cancle": "İptal",
    "gui.dialog.installingCompiler": "derleyici kuruluyor",
    "gui.dialog.uploading": "yükleniyor",
    "gui.dialog.changeLanuage": "Dilin değiştirilmesi mevcut programı silecek, devam edilsin mi?",
    "gui.dialog.boardNotSupportMpy": "%1 kartı micropython desteklemiyor",
    "gui.dialog.loadBlankItem": "Desteklenmeyen öğe </br> boş bir öğeyi yeniden yüklüyor",
    "gui.dialog.switchBaudError": "baud hızını değiştirme hatası</br>",
    "gui.dialog.turnMicrobitCompass": "Need to calibrate the electronic compass, please adjust the attitude of mciro: bit to move the blinking LED lights until all LED lights are on",
    "gui.dialog.operationShow": "Operasyonel gösteri",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "bilgisayar",
    "gui.dialog.delete": "sil",
    "gui.dialog.sureDeleteFile": "%1'deki [%2] dosyasını silmek istediğinize emin misiniz?",
    "gui.dialog.sureDeleteFolder": "%1'deki [%2] klasörünü silmek istediğinize emin misiniz?",
    "gui.dialog.uncorrectPath": "doğru yol seçilmedi",
    "gui.dialog.laterDownLoad": "Sonra İndir",
    "gui.dialog.downLoadUpdate": "Download Update",
    "gui.dialog.dwnLoadWebsite": "İndir",
    "gui.dialog.laterUpdate": "Sonra Yükle",
    "gui.dialog.update": "Yükle",
    "gui.dialog.getUpdateMsgError": "Güncelleme hatası mesajı",
    "gui.dialog.ok": "Tamam",
    "gui.dialog.closeCodeBoxes": "%1 kod bloklarını kapatmak istediğinizden emin misiniz?",
    "gui.dialog.emailError": "E-posta formatı yanlış!",
    "gui.dialog.emailEmpty": "E-posta boş bırakılamaz!",
    "gui.dialog.bigFilePrompt": "Dosya biraz büyük, lütfen sabırlı olun, tşk.",
    "gui.dialog.successFeedbackPrompt1": "Geri bildiriminiz için teşekkür ederiz, sayfa 3 saniye sonra tekrar yüklenecektir.",
    "gui.dialog.successFeedbackPrompt2": "Geri bildiriminiz gönderildi. ",
    "gui.dialog.failedFeedbackPrompt": "Geri bildirim gönderdi hatası için özür dileriz. Lütfen görüşlerinizi https://www.dfrobot.com/forum/ adresinde bildirmek için çevrimiçi forumumuzu ziyaret edin. Tşk.\r\n",
    "gui.dialog.successFeedbackPrompt3": "Geri bildirimleriniz için tşk.",
    "gui.dialog.forcedCloseSoftWare": "Dosyayı kaydederken bir hata oluştu. Yazılımı zorla kapatalım mı?",
    "gui.dialog.saveSuccess": "Proje kaydedildi",
    "gui.dialog.noSelectTruePath": "dosya yol hatası",
    "gui.dialog.newItemContent": "Projenizi silerek üzerine boş bir proje oluşturacaksınız, emin misiniz?",
    "gui.dialog.deleteBlocksError": "%1 bloğu silinemedi, lütfen manuel olarak silin",
    "gui.dialog.netIsConnect": "Lütfen ağa bağlı olup olmadığınızı kontrol edin",
    "gui.dialog.needToUpdate": "Derleyiciyi en son sürüm %1'e güncellensin mi? ",
    "gui.dialog.cantUseMpythonInArduinoC": "Hiçbir derleyici yüklü değil, Arduino C altında mPython kullanamazsınız.Bilgisayarda ağ yoksa, resmi web sitesindeki 'Derleyici çevrimdışı eklenti paketini' indirebilirsiniz.",
    "gui.dialog.enterMpyMode": "micropython moduna geçin",
    "gui.dialog.runHardware": "Donanımı ayarlamak için önce cihazı bağlayın",
    "gui.dialog.sureDelete": "Bunu silmek istediğinizden emin misiniz?",
    "gui.dialog.notSupportWebGL": "Sahne yüklenirken bir hata oldu, WebGL'yi desteklemeyebilir.</br> Aşağıdaki ayrıntıları kontrol edin:</br>%1 </br> QQ grubundan bize ulaşın: 671877416",
    "gui.dialog.boardIsnotMPython": "Geçerli kart mPython'a bağlı değil, lütfen mPython'a bağlanın",
    "gui.dialog.swdMicrobitUpload": "Bilgisayarınız micro:bit yazmak için SWD kullanmalıdır. Lütfen Mind + uygulamasını yönetici ayrıcalıklarıyla çalıştırın",
    "gui.dialog.openPortError": "%1 portunu açarken hata: %2",
    "gui.dialog.pressedA": "Lütfen A düğmesini basılı tutun ve bırakmayın",
    "gui.dialog.unpressedA": "Lütfen A düğmesini bırakın",
    "gui.dialog.unsupportProject": "Desteklenmeyen öğe </br> boş bir öğeyi yeniden yüklüyor",
    "gui.dialog.openProjectError": "SCRATCH modundaki projeler SCRATCH modunda açılmalıdır</br>Lütfen arayüzün hangi modda olduğunu kontrol edin",
    "gui.dialog.installCompilerError1": "Derleyici kodu indiremedi, çevrimdışı yükleyiciyi indirebilir veya tekrar indirmeyi deneyebilirsiniz",
    "gui.dialog.installCompilerOffline": "Çevrimdışı derleyiciyi  yükle",
    "gui.dialog.installCompilerError2": "Doğrulama hatası, çevrimdışı yükleyiciyi indirebilir veya yeniden indirmeyi deneyebilirsiniz",
    "gui.dialog.installCompilerError3": "Yükleme hatası, çevrimdışı yükleyiciyi indirebilir veya yeniden indirmeyi deneyebilirsiniz",
    "gui.dialog.installCompilerSuccess": "Derleyici kuruldu, ArduinoC altında Esp32'yi kullanabilirsiniz",
    "gui.dialog.sureAdapter": "Adaptör bulunamadı, lütfen adaptörün bağlı olup olmadığını kontrol edin",
    "gui.dialog.checkPort": "Seri portu açılamadı, lütfen seri portun boş olup olmadığını kontrol edin.",
    "gui.dialog.changeBoard": "Kart seçimini değiştirme mevcut programı siler. Devam etmek istediğine emin misin?",
    "gui.crashMessage.somethingWrong": "Bir hata oldu!",
    "gui.crashMessage.reload": "Yeniden yükle",
    "gui.crashMessage.submit": "Yolla",
    "gui.crashMessage.attachment": "Ek",
    "gui.crashMessage.errFeedbackNote": "Burada hata mesajları veya öneriler yazabilirsiniz. Mind + üyeliğinizle daha iyi hale gelecektir.",
    "gui.crashMessage.promptContent": "Maalesef Mind+ çöktü. Bu hatayı sağ taraftaki Mind+ ekibine gönderebilirsin, tşk!</br>Lütfen tekrar denemek için sayfanızı yenileyin",
    "gui.dialog.isSaveProject": "Projeniz kaydedilsin mi?",
    "gui.dialog.save": "Save",
    "gui.dialog.notSave": "Kaydetme",
    "gui.dialog.variableIsNull": "Variable name cannot be empty",
    "gui.dialog.listNameIsNull": "list name cannot be empty",
    "gui.dialog.pictureAINotNull": "cannot be null",
    "gui.dialog.pictureAIInitServer": "please initialize the server first",
    "gui.dialog.pictureAIInitServerError": "Error initializing the server, please check the account number and secret key",
    "gui.dialog.versionPrompt": "The current project version「%1」",
    "gui.dialog.versionGreater": "The project version is higher than the current software version, project version 「%1」",
    "BACKDROPS_ARCTIC": "Arctic",
    "BACKDROPS_BASEBALL": "Baseball",
    "BACKDROPS_BASEBALL_2": "Baseball 2",
    "BACKDROPS_BASKETBALL": "Basketball",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Beach Malibu",
    "BACKDROPS_BEACH_RIO": "Beach Rio",
    "BACKDROPS_BEDROOM": "Bedroom",
    "BACKDROPS_BEDROOM_2": "Bedroom 2",
    "BACKDROPS_BEDROOM_3": "Bedroom 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Bench With View",
    "BACKDROPS_BLUE_SKY": "Blue Sky",
    "BACKDROPS_BLUE_SKY_2": "Blue Sky 2",
    "BACKDROPS_BLUE_SKY3": "Blue Sky3",
    "BACKDROPS_BOARDWALK": "Boardwalk",
    "BACKDROPS_CANYON": "Canyon",
    "BACKDROPS_CASTLE_1": "Castle 1",
    "BACKDROPS_CASTLE_2": "Castle 2",
    "BACKDROPS_CASTLE_3": "Castle 3",
    "BACKDROPS_CASTLE_4": "Castle 4",
    "BACKDROPS_CHALKBOARD": "Chalkboard",
    "BACKDROPS_CIRCLES": "Circles",
    "BACKDROPS_CITY_WITH_WATER": "City With Water",
    "BACKDROPS_COLORFUL_CITY": "Colorful City",
    "BACKDROPS_CONCERT": "Concert",
    "BACKDROPS_DESERT": "Desert",
    "BACKDROPS_FARM": "Farm",
    "BACKDROPS_FIELD_AT_MIT": "Field At Mit",
    "BACKDROPS_FLOWERS": "Flowers",
    "BACKDROPS_FOREST": "Forest",
    "BACKDROPS_GALAXY": "Galaxy",
    "BACKDROPS_GARDEN-ROCK": "Garden-rock",
    "BACKDROPS_GREEK_THEATER": "Greek Theater",
    "BACKDROPS_HALL": "Hall",
    "BACKDROPS_HAY_FIELD": "Hay Field",
    "BACKDROPS_GRAFFITI": "Graffiti",
    "BACKDROPS_HEARTS1": "Hearts1",
    "BACKDROPS_HEARTS2": "Hearts2",
    "BACKDROPS_HILL": "Hill",
    "BACKDROPS_JUNGLE": "Jungle",
    "BACKDROPS_JURASSIC": "Jurassic",
    "BACKDROPS_LIGHT": "Light",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": "Moon",
    "BACKDROPS_MOUNTAIN": "Mountain",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "Nebula",
    "BACKDROPS_NEON_TUNNEL": "Neon Tunnel",
    "BACKDROPS_NIGHT_CITY": "Night City",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Night City With Street",
    "BACKDROPS_PARTY": "Party",
    "BACKDROPS_PATHWAY": "Pathway",
    "BACKDROPS_PARTY_ROOM": "Party Room",
    "BACKDROPS_PLAYGROUND": "Playground",
    "BACKDROPS_PLAYING_FIELD": "Playing Field",
    "BACKDROPS_POOL": "Pool",
    "BACKDROPS_PURPLE": "Purple",
    "BACKDROPS_RAYS": "Rays",
    "BACKDROPS_REFRIGERATOR": "Refrigerator",
    "BACKDROPS_ROOM_1": "Room 1",
    "BACKDROPS_ROOM_2": "Room 2",
    "BACKDROPS_SAVANNA": "Savanna",
    "BACKDROPS_SCHOOL": "School",
    "BACKDROPS_SLOPES": "Slopes",
    "BACKDROPS_SOCCER": "Soccer",
    "BACKDROPS_SOCCER_2": "Soccer 2",
    "BACKDROPS_SPACE": "Space",
    "BACKDROPS_SPACE_2": "Space 2",
    "BACKDROPS_SPACE_3": "Space 3",
    "BACKDROPS_SPACE_4": "Space 4",
    "BACKDROPS_SPOTLIGHT-STAGE": "Spotlight-stage",
    "BACKDROPS_STARS": "Stars",
    "BACKDROPS_STRIPES": "Stripes",
    "BACKDROPS_THEATER": "Theater",
    "BACKDROPS_THEATER_2": "Theater 2",
    "BACKDROPS_TREE": "Tree",
    "BACKDROPS_UNDERWATER_1": "Underwater 1",
    "BACKDROPS_UNDERWATER_2": "Underwater 2",
    "BACKDROPS_URBAN": "Urban",
    "BACKDROPS_WALL_1": "Wall 1",
    "BACKDROPS_WALL_2": "Wall 2",
    "BACKDROPS_WATER_AND_ROCKS": "Water And Rocks",
    "BACKDROPS_WETLAND": "Wetland",
    "BACKDROPS_WINTER": "Winter",
    "BACKDROPS_WINTER-LIGHTS": "Winter-lights",
    "BACKDROPS_WITCH_HOUSE": "Witch House",
    "BACKDROPS_WOODS": "Woods",
    "BACKDROPS_WOODS_AND_BENCH": "Woods And Bench",
    "BACKDROPS_XY-GRID": "Xy-grid",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "Abby-a",
    "COSTUMES_ABBY-B": "Abby-b",
    "COSTUMES_ABBY-C": "Abby-c",
    "COSTUMES_ABBY-D": "Abby-d",
    "COSTUMES_AMON": "Amon",
    "COSTUMES_ANDIE-A": "Andie-a",
    "COSTUMES_ANDIE-B": "Andie-b",
    "COSTUMES_ANDIE-C": "Andie-c",
    "COSTUMES_ANDIE-D": "Andie-d",
    "COSTUMES_ANINA_POP_DOWN": "Anina Pop Down",
    "COSTUMES_ANINA_POP_FRONT": "Anina Pop Front",
    "COSTUMES_ANINA_POP_L_ARM": "Anina Pop L Arm",
    "COSTUMES_ANINA_POP_LEFT": "Anina Pop Left",
    "COSTUMES_ANINA_POP_R_ARM": "Anina Pop R Arm",
    "COSTUMES_ANINA_POP_RIGHT": "Anina Pop Right",
    "COSTUMES_ANINA_POP_STAND": "Anina Pop Stand",
    "COSTUMES_ANINA_R_CROSS": "Anina R Cross",
    "COSTUMES_ANINA_STANCE": "Anina Stance",
    "COSTUMES_ANINA_TOP_FREEZE": "Anina Top Freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "Anina Top L Step",
    "COSTUMES_ANINA_TOP_R_STEP": "Anina Top R Step",
    "COSTUMES_ANINA_TOP_STAND": "Anina Top Stand",
    "COSTUMES_APPLE": "Apple",
    "COSTUMES_ARROW1-A": "Arrow1-a",
    "COSTUMES_ARROW1-B": "Arrow1-b",
    "COSTUMES_ARROW1-C": "Arrow1-c",
    "COSTUMES_ARROW1-D": "Arrow1-d",
    "COSTUMES_AVERY_WALKING-A": "Avery walking-a",
    "COSTUMES_AVERY_WALKING-B": "Avery walking-b",
    "COSTUMES_AVERY_WALKING-C": "Avery walking-c",
    "COSTUMES_AVERY_WALKING-D": "Avery walking-d",
    "COSTUMES_AVERY-A": "Avery-a",
    "COSTUMES_AVERY-B": "Avery-b",
    "COSTUMES_BALL-A": "Ball-a",
    "COSTUMES_BALL-B": "Ball-b",
    "COSTUMES_BALL-C": "Ball-c",
    "COSTUMES_BALL-D": "Ball-d",
    "COSTUMES_BALL-E": "Ball-e",
    "COSTUMES_BALLERINA-A": "Ballerina-a",
    "COSTUMES_BALLERINA-B": "Ballerina-b",
    "COSTUMES_BALLERINA-C": "Ballerina-c",
    "COSTUMES_BALLERINA-D": "Ballerina-d",
    "COSTUMES_BALLOON1-A": "Balloon1-a",
    "COSTUMES_BALLOON1-B": "Balloon1-b",
    "COSTUMES_BALLOON1-C": "Balloon1-c",
    "COSTUMES_BANANAS": "Bananas",
    "COSTUMES_BASEBALL": "Baseball",
    "COSTUMES_BASKETBALL": "Basketball",
    "COSTUMES_BAT-A": "Bat-a",
    "COSTUMES_BAT-B": "Bat-b",
    "COSTUMES_BAT-C": "Bat-c",
    "COSTUMES_BAT-D": "Bat-d",
    "COSTUMES_BATTER-A": "Batter-a",
    "COSTUMES_BATTER-B": "Batter-b",
    "COSTUMES_BATTER-C": "Batter-c",
    "COSTUMES_BATTER-D": "Batter-d",
    "COSTUMES_BEACHBALL": "Beachball",
    "COSTUMES_BEAR-A": "Bear-a",
    "COSTUMES_BEAR-B": "Bear-b",
    "COSTUMES_BEAR-WALK-A": "Bear-walk-a",
    "COSTUMES_BEAR-WALK-B": "Bear-walk-b",
    "COSTUMES_BEAR-WALK-C": "Bear-walk-c",
    "COSTUMES_BEAR-WALK-D": "Bear-walk-d",
    "COSTUMES_BEAR-WALK-E": "Bear-walk-e",
    "COSTUMES_BEAR-WALK-F": "Bear-walk-f",
    "COSTUMES_BEAR-WALK-G": "Bear-walk-g",
    "COSTUMES_BEAR-WALK-H": "Bear-walk-h",
    "COSTUMES_BEETLE": "Beetle",
    "COSTUMES_BELL1": "Bell1",
    "COSTUMES_BEN-A": "Ben-a",
    "COSTUMES_BEN-B": "Ben-b",
    "COSTUMES_BEN-C": "Ben-c",
    "COSTUMES_BEN-D": "Ben-d",
    "COSTUMES_BOWL-A": "Bowl-a",
    "COSTUMES_BOWTIE": "Bowtie",
    "COSTUMES_BREAD": "Bread",
    "COSTUMES_BROOM": "Broom",
    "COSTUMES_BUILDING-A": "Building-a",
    "COSTUMES_BUILDING-B": "Building-b",
    "COSTUMES_BUILDING-C": "Building-c",
    "COSTUMES_BUILDING-D": "Building-d",
    "COSTUMES_BUILDING-E": "Building-e",
    "COSTUMES_BUILDING-F": "Building-f",
    "COSTUMES_BUILDING-G": "Building-g",
    "COSTUMES_BUILDING-H": "Building-h",
    "COSTUMES_BUILDING-I": "Building-i",
    "COSTUMES_BUILDING-J": "Building-j",
    "COSTUMES_BUTTERFLY1-A": "Butterfly1-a",
    "COSTUMES_BUTTERFLY1-B": "Butterfly1-b",
    "COSTUMES_BUTTERFLY1-C": "Butterfly1-c",
    "COSTUMES_BUTTERFLY2-A": "Butterfly2-a",
    "COSTUMES_BUTTERFLY2-B": "Butterfly2-b",
    "COSTUMES_BUTTON1": "Button1",
    "COSTUMES_BUTTON2-A": "Button2-a",
    "COSTUMES_BUTTON2-B": "Button2-b",
    "COSTUMES_BUTTON3-A": "Button3-a",
    "COSTUMES_BUTTON3-B": "Button3-b",
    "COSTUMES_BUTTON4-A": "Button4-a",
    "COSTUMES_BUTTON4-B": "Button4-b",
    "COSTUMES_BUTTON5-A": "Button5-a",
    "COSTUMES_BUTTON5-B": "Button5-b",
    "COSTUMES_CAKE-A": "Cake-a",
    "COSTUMES_CAKE-B": "Cake-b",
    "COSTUMES_CALVRETT_JUMPING": "Calvrett Jumping",
    "COSTUMES_CALVRETT_THINKING": "Calvrett Thinking",
    "COSTUMES_CASEY-A": "Casey-a",
    "COSTUMES_CASEY-B": "Casey-b",
    "COSTUMES_CASEY-C": "Casey-c",
    "COSTUMES_CASEY-D": "casey-d",
    "COSTUMES_CASSY-A": "Cassy-a",
    "COSTUMES_CASSY-B": "Cassy-b",
    "COSTUMES_CASSY-C": "Cassy-c",
    "COSTUMES_CASSY-D": "Cassy-d",
    "COSTUMES_CAT_2": "Cat 2",
    "COSTUMES_CATCHER-A": "Catcher-a",
    "COSTUMES_CATCHER-B": "Catcher-b",
    "COSTUMES_CATCHER-C": "Catcher-c",
    "COSTUMES_CATCHER-D": "Catcher-d",
    "COSTUMES_CENTAUR-A": "Centaur-a",
    "COSTUMES_CENTAUR-B": "Centaur-b",
    "COSTUMES_CENTAUR-C": "centaur-c",
    "COSTUMES_CENTAUR-D": "centaur-d",
    "COSTUMES_CHAMP99-A": "Champ99-a",
    "COSTUMES_CHAMP99-B": "Champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "Cheesy Puffs",
    "COSTUMES_CHICK-A": "chick-a",
    "COSTUMES_CHICK-B": "chick-b",
    "COSTUMES_CHICK-C": "chick-c",
    "COSTUMES_CITY_BUS-A": "City Bus-a",
    "COSTUMES_CITY_BUS-B": "City Bus-b",
    "COSTUMES_CLOUD": "Cloud",
    "COSTUMES_CLOUD-A": "Cloud-a",
    "COSTUMES_CLOUD-B": "Cloud-b",
    "COSTUMES_CLOUD-C": "Cloud-c",
    "COSTUMES_CLOUD-D": "Cloud-d",
    "COSTUMES_CONVERTIBLE": "Convertible",
    "COSTUMES_CONVERTIBLE_3": "Convertible 2",
    "COSTUMES_CRAB-A": "Crab-a",
    "COSTUMES_CRAB-B": "Crab-b",
    "COSTUMES_CRYSTAL-A": "Crystal-a",
    "COSTUMES_CRYSTAL-B": "Crystal-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "Dee-a",
    "COSTUMES_DEE-B": "Dee-b",
    "COSTUMES_DEE-C": "Dee-c",
    "COSTUMES_DEE-D": "Dee-d",
    "COSTUMES_DEE-E": "Dee-e",
    "COSTUMES_DEVIN-A": "Devin-a",
    "COSTUMES_DEVIN-B": "Devin-b",
    "COSTUMES_DEVIN-C": "Devin-c",
    "COSTUMES_DEVIN-D": "Devin-d",
    "COSTUMES_DINOSAUR1-A": "Dinosaur1-a",
    "COSTUMES_DINOSAUR1-B": "Dinosaur1-b",
    "COSTUMES_DINOSAUR1-C": "Dinosaur1-c",
    "COSTUMES_DINOSAUR1-D": "Dinosaur1-d",
    "COSTUMES_DINOSAUR2-A": "Dinosaur2-a",
    "COSTUMES_DINOSAUR2-B": "Dinosaur2-b",
    "COSTUMES_DINOSAUR2-C": "Dinosaur2-c",
    "COSTUMES_DINOSAUR2-D": "Dinosaur2-d",
    "COSTUMES_DINOSAUR3-A": "Dinosaur3-a",
    "COSTUMES_DINOSAUR3-B": "Dinosaur3-b",
    "COSTUMES_DINOSAUR3-C": "Dinosaur3-c",
    "COSTUMES_DINOSAUR3-D": "Dinosaur3-d",
    "COSTUMES_DINOSAUR3-E": "Dinosaur3-e",
    "COSTUMES_DINOSAUR4-A": "Dinosaur4-a",
    "COSTUMES_DINOSAUR4-B": "Dinosaur4-b",
    "COSTUMES_DINOSAUR4-C": "Dinosaur4-c",
    "COSTUMES_DINOSAUR4-D": "Dinosaur4-d",
    "COSTUMES_DIVER1": "Diver1",
    "COSTUMES_DIVER2": "Diver2",
    "COSTUMES_DM_FREEZE": "dm freeze",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "dm stance",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": "Dog1-a",
    "COSTUMES_DOG1-B": "Dog1-b",
    "COSTUMES_DOG2-A": "Dog2-a",
    "COSTUMES_DOG2-B": "Dog2-b",
    "COSTUMES_DOG2-C": "Dog2-c",
    "COSTUMES_DONUT": "Donut",
    "COSTUMES_DORIAN-A": "Dorian-a",
    "COSTUMES_DORIAN-B": "Dorian-b",
    "COSTUMES_DORIAN-C": "Dorian-c",
    "COSTUMES_DORIAN-D": "Dorian-d",
    "COSTUMES_DOT-A": "Dot-a",
    "COSTUMES_DOT-B": "Dot-b",
    "COSTUMES_DOT-C": "Dot-c",
    "COSTUMES_DOT-D": "Dot-d",
    "COSTUMES_DOVE-A": "Dove-a",
    "COSTUMES_DOVE-B": "Dove-b",
    "COSTUMES_DRAGON-A": "Dragon-a",
    "COSTUMES_DRAGON-B": "Dragon-b",
    "COSTUMES_DRAGON-C": "Dragon-c",
    "COSTUMES_DRAGON1-A": "Dragon1-a",
    "COSTUMES_DRAGON1-B": "Dragon1-b",
    "COSTUMES_DRAGONFLY-A": "Dragonfly-a",
    "COSTUMES_DRAGONFLY-B": "Dragonfly-b",
    "COSTUMES_DRESS-A": "Dress-a",
    "COSTUMES_DRESS-B": "Dress-b",
    "COSTUMES_DRESS-C": "Dress-c",
    "COSTUMES_DRUM-A": "Drum-a",
    "COSTUMES_DRUM-B": "Drum-b",
    "COSTUMES_DRUM-CYMBAL-A": "Drum-cymbal-a",
    "COSTUMES_DRUM-CYMBAL-B": "Drum-cymbal-b",
    "COSTUMES_DRUM-HIGHHAT-A": "Drum-highhat-a",
    "COSTUMES_DRUM-HIGHHAT-B": "Drum-highhat-b",
    "COSTUMES_DRUM-KIT": "Drum-kit",
    "COSTUMES_DRUM-KIT-B": "Drum-kit-b",
    "COSTUMES_DRUM-SNARE-A": "Drum-snare-a",
    "COSTUMES_DRUM-SNARE-B": "Drum-snare-b",
    "COSTUMES_DRUMS_CONGA-A": "Drums Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Drums Conga-b",
    "COSTUMES_DUCK": "Duck",
    "COSTUMES_EARTH": "Earth",
    "COSTUMES_EASEL-A": "Easel-a",
    "COSTUMES_EASEL-B": "Easel-b",
    "COSTUMES_EASEL-C": "Easel-c",
    "COSTUMES_EGG-A": "Egg-a",
    "COSTUMES_EGG-B": "Egg-b",
    "COSTUMES_EGG-C": "Egg-c",
    "COSTUMES_EGG-D": "Egg-d",
    "COSTUMES_EGG-E": "Egg-e",
    "COSTUMES_EGG-F": "Egg-f",
    "COSTUMES_ELEPHANT-A": "Elephant-a",
    "COSTUMES_ELEPHANT-B": "Elephant-b",
    "COSTUMES_ELF-A": "Elf-a",
    "COSTUMES_ELF-B": "Elf-b",
    "COSTUMES_ELF-C": "Elf-c",
    "COSTUMES_ELF-D": "Elf-d",
    "COSTUMES_ELF-E": "Elf-e",
    "COSTUMES_FAIRY-A": "Fairy-a",
    "COSTUMES_FAIRY-B": "Fairy-b",
    "COSTUMES_FAIRY-C": "Fairy-c",
    "COSTUMES_FAIRY-D": "Fairy-d",
    "COSTUMES_FAIRY-E": "Fairy-e",
    "COSTUMES_FISH-A": "Fish-a",
    "COSTUMES_FISH-B": "Fish-b",
    "COSTUMES_FISH-C": "Fish-c",
    "COSTUMES_FISH-D": "Fish-d",
    "COSTUMES_FISHBOWL-A": "Fishbowl-a",
    "COSTUMES_FISHBOWL-B": "Fishbowl-b",
    "COSTUMES_FOOD_TRUCK-A": "Food Truck-a",
    "COSTUMES_FOOD_TRUCK-B": "Food Truck-b",
    "COSTUMES_FOOD_TRUCK-C": "Food Truck-c",
    "COSTUMES_FOOTBALL_RUNNING": "Football Running",
    "COSTUMES_FOOTBALL_STANDING": "Football Standing",
    "COSTUMES_FORTUNE_COOKIE": "Fortune Cookie",
    "COSTUMES_FOX-A": "Fox-a",
    "COSTUMES_FOX-B": "Fox-b",
    "COSTUMES_FOX-C": "Fox-c",
    "COSTUMES_FRANK-A": "Frank-a",
    "COSTUMES_FRANK-B": "Frank-b",
    "COSTUMES_FRANK-C": "Frank-c",
    "COSTUMES_FRANK-D": "Frank-d",
    "COSTUMES_FROG": "Frog",
    "COSTUMES_FROG_2-A": "Frog 2-a",
    "COSTUMES_FROG_2-B": "Frog 2-b",
    "COSTUMES_FROG_2-C": "Frog 2-c",
    "COSTUMES_FRUIT_PLATTER": "Fruit Platter",
    "COSTUMES_FRUITSALAD": "Fruitsalad",
    "COSTUMES_GHOST-A": "Ghost-a",
    "COSTUMES_GHOST-B": "Ghost-b",
    "COSTUMES_GHOST-C": "Ghost-c",
    "COSTUMES_GHOST-D": "Ghost-d",
    "COSTUMES_GIFT-A": "Gift-a",
    "COSTUMES_GIFT-B": "Gift-b",
    "COSTUMES_GIRAFFE-A": "Giraffe-a",
    "COSTUMES_GIRAFFE-B": "Giraffe-b",
    "COSTUMES_GIRAFFE-C": "Giraffe-c",
    "COSTUMES_GLASS_WATER-A": "Glass Water-a",
    "COSTUMES_GLASS_WATER-B": "Glass water-b",
    "COSTUMES_GLASSES-A": "Glasses-a",
    "COSTUMES_GLASSES-B": "Glasses-b",
    "COSTUMES_GLASSES-C": "Glasses-c",
    "COSTUMES_GLASSES-E": "Glasses-e",
    "COSTUMES_GOALIE-A": "Goalie-a",
    "COSTUMES_GOALIE-B": "Goalie-b",
    "COSTUMES_GOALIE-C": "goalie-c",
    "COSTUMES_GOALIE-D": "Goalie-d",
    "COSTUMES_GOALIE-E": "Goalie-e",
    "COSTUMES_GOBLIN-A": "Goblin-a",
    "COSTUMES_GOBLIN-B": "Goblin-b",
    "COSTUMES_GOBLIN-C": "Goblin-c",
    "COSTUMES_GOBLIN-D": "Goblin-d",
    "COSTUMES_GRASSHOPPER-A": "Grasshopper-a",
    "COSTUMES_GRASSHOPPER-B": "Grasshopper-b",
    "COSTUMES_GRASSHOPPER-C": "Grasshopper-c",
    "COSTUMES_GRASSHOPPER-D": "Grasshopper-d",
    "COSTUMES_GRASSHOPPER-E": "Grasshopper-e",
    "COSTUMES_GRASSHOPPER-F": "Grasshopper-f",
    "COSTUMES_GREEN_FLAG": "Green Flag",
    "COSTUMES_GRIFFIN-A": "Griffin-a",
    "COSTUMES_GRIFFIN-B": "Griffin-b",
    "COSTUMES_GRIFFIN-C": "Griffin-c",
    "COSTUMES_GRIFFIN-D": "Griffin-d",
    "COSTUMES_GUITAR-A": "guitar-a",
    "COSTUMES_GUITAR-B": "guitar-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "Guitar-electric1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "Guitar-electric1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "Guitar-electric2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "Guitar-electric2-b",
    "COSTUMES_HANNAH-A": "Hannah-a",
    "COSTUMES_HANNAH-B": "Hannah-b",
    "COSTUMES_HANNAH-C": "Hannah-c",
    "COSTUMES_HARE-A": "Hare-a",
    "COSTUMES_HARE-B": "Hare-b",
    "COSTUMES_HARE-C": "Hare-c",
    "COSTUMES_HARPER-A": "Harper-a",
    "COSTUMES_HARPER-B": "Harper-b",
    "COSTUMES_HARPER-C": "Harper-c",
    "COSTUMES_HAT-A": "Hat-a",
    "COSTUMES_HAT-B": "Hat-b",
    "COSTUMES_HAT-C": "Hat-c",
    "COSTUMES_HAT-D": "Hat-d",
    "COSTUMES_HATCHLING-A": "Hatchling-a",
    "COSTUMES_HATCHLING-B": "Hatchling-b",
    "COSTUMES_HATCHLING-C": "Hatchling-c",
    "COSTUMES_HEART_CODE": "Heart Code",
    "COSTUMES_HEART_FACE": "Heart Face",
    "COSTUMES_HEART_LOVE": "Heart Love",
    "COSTUMES_HEART_PURPLE": "Heart Purple",
    "COSTUMES_HEART_RED": "Heart Red",
    "COSTUMES_HEART_SMILE": "Heart Smile",
    "COSTUMES_HEART_SWEET": "Heart Sweet",
    "COSTUMES_HEDGEHOG-A": "Hedgehog-a",
    "COSTUMES_HEDGEHOG-B": "Hedgehog-b",
    "COSTUMES_HEDGEHOG-C": "Hedgehog-c",
    "COSTUMES_HEDGEHOG-D": "Hedgehog-d",
    "COSTUMES_HEDGEHOG-E": "Hedgehog-e",
    "COSTUMES_HEN-A": "Hen-a",
    "COSTUMES_HEN-B": "Hen-b",
    "COSTUMES_HEN-C": "Hen-c",
    "COSTUMES_HEN-D": "Hen-d",
    "COSTUMES_HIPPO1-A": "Hippo1-a",
    "COSTUMES_HIPPO1-B": "Hippo1-b",
    "COSTUMES_HOME_BUTTON": "Home Button",
    "COSTUMES_HORSE-A": "Horse-a",
    "COSTUMES_HORSE-B": "Horse-b",
    "COSTUMES_JAIME_WALKING-A": "Jaime Walking-a",
    "COSTUMES_JAIME_WALKING-B": "Jaime Walking-b",
    "COSTUMES_JAIME_WALKING-C": "Jaime Walking-c",
    "COSTUMES_JAIME_WALKING-D": "Jaime Walking-d",
    "COSTUMES_JAIME_WALKING-E": "Jaime Walking-e",
    "COSTUMES_JAIME-A": "Jaime-a",
    "COSTUMES_JAIME-B": "Jaime-b",
    "COSTUMES_JAMAL-A": "Jamal-a",
    "COSTUMES_JAMAL-B": "Jamal-b",
    "COSTUMES_JAMAL-C": "Jamal-c",
    "COSTUMES_JAMAL-D": "Jamal-d",
    "COSTUMES_JAR-A": "Jar-a",
    "COSTUMES_JAR-B": "Jar-b",
    "COSTUMES_JELLYFISH-A": "Jellyfish-a",
    "COSTUMES_JELLYFISH-B": "Jellyfish-b",
    "COSTUMES_JELLYFISH-C": "Jellyfish-c",
    "COSTUMES_JELLYFISH-D": "Jellyfish-d",
    "COSTUMES_JO_POP_DOWN": "Jo Pop Down",
    "COSTUMES_JO_POP_FRONT": "Jo Pop Front",
    "COSTUMES_JO_POP_L_ARM": "Jo Pop L Arm",
    "COSTUMES_JO_POP_LEFT": "Jo Pop Left",
    "COSTUMES_JO_POP_R_ARM": "Jo Pop R Arm",
    "COSTUMES_JO_POP_RIGHT": "Jo Pop Right",
    "COSTUMES_JO_POP_STAND": "Jo Pop Stand",
    "COSTUMES_JO_STANCE": "Jo Stance",
    "COSTUMES_JO_TOP_L_CROSS": "Jo Top L Cross",
    "COSTUMES_JO_TOP_L_LEG": "Jo Top L Leg",
    "COSTUMES_JO_TOP_R_CROSS": "Jo Top R Cross",
    "COSTUMES_JO_TOP_R_LEG": "Jo Top R Leg",
    "COSTUMES_JO_TOP_STAND": "Jo Top Stand",
    "COSTUMES_JORDYN-A": "Jordyn-a",
    "COSTUMES_JORDYN-B": "Jordyn-b",
    "COSTUMES_JORDYN-C": "Jordyn-c",
    "COSTUMES_JORDYN-D": "Jordyn-d",
    "COSTUMES_KAI-A": "Kai-a",
    "COSTUMES_KAI-B": "Kai-b",
    "COSTUMES_KEY": "Key",
    "COSTUMES_KEYBOARD-A": "Keyboard-a",
    "COSTUMES_KEYBOARD-B": "Keyboard-b",
    "COSTUMES_KIRAN-A": "Kiran-a",
    "COSTUMES_KIRAN-B": "Kiran-b",
    "COSTUMES_KIRAN-C": "Kiran-c",
    "COSTUMES_KIRAN-D": "Kiran-d",
    "COSTUMES_KIRAN-E": "Kiran-e",
    "COSTUMES_KIRAN-F": "Kiran-f",
    "COSTUMES_KNIGHT": "Knight",
    "COSTUMES_LADYBUG2": "Ladybug1",
    "COSTUMES_LADYBUG2-A": "Ladybug2-a",
    "COSTUMES_LADYBUG2-B": "Ladybug2-b",
    "COSTUMES_LAPTOP": "Laptop",
    "COSTUMES_LB_POP_DOWN": "Lb Pop Down",
    "COSTUMES_LB_POP_FRONT": "Lb Pop Front",
    "COSTUMES_LB_POP_L_ARM": "Lb Pop L Arm",
    "COSTUMES_LB_POP_LEFT": "Lb Pop Left",
    "COSTUMES_LB_POP_R_ARM": "Lb Pop R Arm",
    "COSTUMES_LB_POP_RIGHT": "Lb Pop Right",
    "COSTUMES_LB_POP_STAND": "Lb Pop Stand",
    "COSTUMES_LB_STANCE": "lb Stance",
    "COSTUMES_LB_TOP_L_CROSS": "Lb Top L Cross",
    "COSTUMES_LB_TOP_L_LEG": "Lb Top L Leg",
    "COSTUMES_LB_TOP_R_CROSS": "Lb Top R Cross",
    "COSTUMES_LB_TOP_R_LEG": "Lb Top R Leg",
    "COSTUMES_LB_TOP_STAND": "Lb Top Stand",
    "COSTUMES_LIGHTNING": "Lightning",
    "COSTUMES_LINE": "Line",
    "COSTUMES_LION-A": "Lion-a",
    "COSTUMES_LION-B": "Lion-b",
    "COSTUMES_LION-C": "Lion-c",
    "COSTUMES_LLAMA": "Llama",
    "COSTUMES_LLAMA-B": "Llama-b",
    "COSTUMES_LLAMA-C": "Llama-c",
    "COSTUMES_MAGICWAND": "Magicwand",
    "COSTUMES_MAX-A": "Max-a",
    "COSTUMES_MAX-B": "Max-b",
    "COSTUMES_MAX-C": "Max-c",
    "COSTUMES_MAX-D": "Max-d",
    "COSTUMES_MERMAID-A": "Mermaid-a",
    "COSTUMES_MERMAID-B": "Mermaid-b",
    "COSTUMES_MERMAID-C": "Mermaid-c",
    "COSTUMES_MERMAID-D": "Mermaid-d",
    "COSTUMES_MICROPHONE-A": "Microphone-a",
    "COSTUMES_MICROPHONE-B": "Microphone-b",
    "COSTUMES_MILK-A": "Milk-a",
    "COSTUMES_MILK-B": "Milk-b",
    "COSTUMES_MILK-C": "Milk-c",
    "COSTUMES_MILK-D": "Milk-d",
    "COSTUMES_MILK-E": "Milk-e",
    "COSTUMES_MONET-A": "Monet-a",
    "COSTUMES_MONET-B": "Monet-b",
    "COSTUMES_MONET-C": "Monet-c",
    "COSTUMES_MONET-D": "Monet-d",
    "COSTUMES_MONET-E": "Monet-e",
    "COSTUMES_MONKEY-A": "Monkey-a",
    "COSTUMES_MONKEY-B": "Monkey-b",
    "COSTUMES_MONKEY-C": "Monkey-c",
    "COSTUMES_MOTORCYCLE-A": "Motorcycle-a",
    "COSTUMES_MOTORCYCLE-B": "Motorcycle-b",
    "COSTUMES_MOTORCYCLE-C": "Motorcycle-c",
    "COSTUMES_MOTORCYCLE-D": "Motorcycle-d",
    "COSTUMES_MOUSE1-A": "Mouse1-a",
    "COSTUMES_MOUSE1-B": "Mouse1-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "Neigh Pony",
    "COSTUMES_OCTOPUS-A": "Octopus-a",
    "COSTUMES_OCTOPUS-B": "Octopus-b",
    "COSTUMES_OCTOPUS-C": "Octopus-c",
    "COSTUMES_OCTOPUS-D": "Octopus-d",
    "COSTUMES_OCTOPUS-E": "Octopus-e",
    "COSTUMES_ORANGE": "Orange",
    "COSTUMES_ORANGE2-A": "Orange2-a",
    "COSTUMES_ORANGE2-B": "Orange2-b",
    "COSTUMES_OUTFIELDER-A": "Outfielder-a",
    "COSTUMES_OUTFIELDER-B": "Outfielder-b",
    "COSTUMES_OUTFIELDER-C": "Outfielder-c",
    "COSTUMES_OUTFIELDER-D": "Outfielder-d",
    "COSTUMES_OWL-A": "Owl-a",
    "COSTUMES_OWL-B": "Owl-b",
    "COSTUMES_OWL-C": "Owl-c",
    "COSTUMES_PADDLE": "Paddle",
    "COSTUMES_PANTHER-A": "Panther-a",
    "COSTUMES_PANTHER-B": "Panther-b",
    "COSTUMES_PANTHER-C": "Panther-c",
    "COSTUMES_PANTS-A": "Pants-a",
    "COSTUMES_PANTS-B": "Pants-b",
    "COSTUMES_PARROT-A": "Parrot-a",
    "COSTUMES_PARROT-B": "Parrot-b",
    "COSTUMES_PARTY_HAT-A": "Party Hat-a",
    "COSTUMES_PARTY_HAT-B": "Party Hat-b",
    "COSTUMES_PARTY_HAT-E": "Party Hat-c",
    "COSTUMES_PENCIL-A": "Pencil-a",
    "COSTUMES_PENCIL-B": "Pencil-b",
    "COSTUMES_PENGUIN-A": "Penguin-a",
    "COSTUMES_PENGUIN-B": "Penguin-b",
    "COSTUMES_PENGUIN-C": "Penguin-c",
    "COSTUMES_PENGUIN2-A": "Penguin2-a",
    "COSTUMES_PENGUIN2-B": "Penguin2-b",
    "COSTUMES_PENGUIN2-C": "Penguin2-c",
    "COSTUMES_PENGUIN2-D": "Penguin2-d",
    "COSTUMES_PITCHER-A": "Pitcher-a",
    "COSTUMES_PITCHER-B": "Pitcher-b",
    "COSTUMES_PITCHER-C": "Pitcher-c",
    "COSTUMES_PITCHER-D": "Pitcher-d",
    "COSTUMES_PLANET2": "Planet2",
    "COSTUMES_POLAR_BEAR-A": "Polar Bear-a",
    "COSTUMES_POLAR_BEAR-B": "Polar Bear-b",
    "COSTUMES_POLAR_BEAR-C": "Polar Bear-c",
    "COSTUMES_POTION-A": "Potion-a",
    "COSTUMES_POTION-B": "Potion-b",
    "COSTUMES_POTION-C": "Potion-c",
    "COSTUMES_PRINCE": "Prince",
    "COSTUMES_PRINCESS-A": "Princess-a",
    "COSTUMES_PRINCESS-B": "Princess-b",
    "COSTUMES_PRINCESS-C": "Princess-c",
    "COSTUMES_PRINCESS-D": "Princess-d",
    "COSTUMES_PRINCESS-E": "Princess-e",
    "COSTUMES_PUFFERFISH-A": "Pufferfish-a",
    "COSTUMES_PUFFERFISH-B": "Pufferfish-b",
    "COSTUMES_PUFFERFISH-C": "Pufferfish-c",
    "COSTUMES_PUFFERFISH-D": "Pufferfish-d",
    "COSTUMES_PUPPY_BACK": "Puppy Back",
    "COSTUMES_PUPPY_RIGHT": "Puppy Right",
    "COSTUMES_PUPPY_SIDE": "Puppy Side",
    "COSTUMES_PUPPY_SIT": "Puppy Sit",
    "COSTUMES_RABBIT-A": "Rabbit-a",
    "COSTUMES_RABBIT-B": "Rabbit-b",
    "COSTUMES_RABBIT-C": "Rabbit-c",
    "COSTUMES_RABBIT-D": "Rabbit-d",
    "COSTUMES_RABBIT-E": "Rabbit-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": "Rainbow",
    "COSTUMES_REFEREE-A": "referee-a",
    "COSTUMES_REFEREE-B": "referee-b",
    "COSTUMES_REFEREE-C": "referee-c",
    "COSTUMES_REFEREE-D": "referee-d",
    "COSTUMES_REINDEER": "reindeer",
    "COSTUMES_RETRO_ROBOT_A": "Retro Robot a",
    "COSTUMES_RETRO_ROBOT_B": "Retro Robot b",
    "COSTUMES_RETRO_ROBOT_C": "Retro Robot c",
    "COSTUMES_RIPLEY-A": "Ripley-a",
    "COSTUMES_RIPLEY-B": "Ripley-b",
    "COSTUMES_RIPLEY-C": "Ripley-c",
    "COSTUMES_RIPLEY-D": "Ripley-d",
    "COSTUMES_RIPLEY-E": "Ripley-e",
    "COSTUMES_RIPLEY-F": "Ripley-f",
    "COSTUMES_ROBOT-A": "Robot-a",
    "COSTUMES_ROBOT-B": "Robot-b",
    "COSTUMES_ROBOT-C": "Robot-c",
    "COSTUMES_ROBOT-D": "Robot-d",
    "COSTUMES_ROCKETSHIP-A": "Rocketship-a",
    "COSTUMES_ROCKETSHIP-B": "Rocketship-b",
    "COSTUMES_ROCKETSHIP-C": "Rocketship-c",
    "COSTUMES_ROCKETSHIP-D": "Rocketship-d",
    "COSTUMES_ROCKETSHIP-E": "Rocketship-e",
    "COSTUMES_ROCKS": "Rocks",
    "COSTUMES_ROOSTER-A": "Rooster-a",
    "COSTUMES_ROOSTER-B": "Rooster-b",
    "COSTUMES_ROOSTER-C": "Rooster-c",
    "COSTUMES_RUBY-A": "Ruby-a",
    "COSTUMES_RUBY-B": "Ruby-b",
    "COSTUMES_SAILBOAT": "Sailboat",
    "COSTUMES_SAM": "Sam",
    "COSTUMES_SAXOPHONE-A": "Saxophone-a",
    "COSTUMES_SAXOPHONE-B": "Saxophone-b",
    "COSTUMES_SCARF-A": "Scarf-a",
    "COSTUMES_SCARF-B": "Scarf-b",
    "COSTUMES_SCARF-C": "Scarf-c",
    "COSTUMES_SHARK-A": "Shark-a",
    "COSTUMES_SHARK-B": "Shark-b",
    "COSTUMES_SHARK2-A": "Shark2-a",
    "COSTUMES_SHARK2-B": "Shark2-b",
    "COSTUMES_SHARK2-C": "Shark2-c",
    "COSTUMES_SHIRT-A": "Shirt-a",
    "COSTUMES_SHOES-A": "Shoes-a",
    "COSTUMES_SHOES-B": "Shoes-b",
    "COSTUMES_SHOES-C": "Shoes-c",
    "COSTUMES_SHOES-D": "Shoes-d",
    "COSTUMES_SHORTS-A": "Shorts-a",
    "COSTUMES_SHORTS-B": "Shorts-b",
    "COSTUMES_SHORTS-C": "Shorts-c",
    "COSTUMES_SINGER1": "Singer1",
    "COSTUMES_SKELETON-A": "Skeleton-a",
    "COSTUMES_SKELETON-B": "Skeleton-b",
    "COSTUMES_SKELETON-D": "Skeleton-d",
    "COSTUMES_SKELETON-E": "Skeleton-e",
    "COSTUMES_SNAKE-A": "Snake-a",
    "COSTUMES_SNAKE-B": "Snake-b",
    "COSTUMES_SNAKE-C": "Snake-c",
    "COSTUMES_SNOWFLAKE": "Snowflake",
    "COSTUMES_SNOWMAN": "Snowman",
    "COSTUMES_SOCCER_BALL": "Soccer Ball",
    "COSTUMES_SPEAKER": "Speaker",
    "COSTUMES_SQUIRREL": "Squirrel",
    "COSTUMES_STAR": "Star",
    "COSTUMES_STARFISH-A": "Starfish-a",
    "COSTUMES_STARFISH-B_": "Starfish-b ",
    "COSTUMES_STOP": "Stop",
    "COSTUMES_STRAWBERRY-A": "Strawberry-a",
    "COSTUMES_STRAWBERRY-B": "Strawberry-b",
    "COSTUMES_STRAWBERRY-C": "Strawberry-c",
    "COSTUMES_STRAWBERRY-D": "Strawberry-d",
    "COSTUMES_STRAWBERRY-E": "Strawberry-e",
    "COSTUMES_SUN": "Sun",
    "COSTUMES_SUNGLASSES-A": "Sunglasses-a",
    "COSTUMES_SUNGLASSES-B": "Sunglasses-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-wizard",
    "COSTUMES_TAKEOUT-A": "Takeout-a",
    "COSTUMES_TAKEOUT-B": "Takeout-b",
    "COSTUMES_TAKEOUT-C": "Takeout-c",
    "COSTUMES_TAKEOUT-D": "Takeout-d",
    "COSTUMES_TAKEOUT-E": "Takeout-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 Pop Down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 Pop Front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 Pop L Arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 Pop Left",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 Pop R Arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 Pop Right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 Pop Stand",
    "COSTUMES_TEN80_STANCE": "Ten80 stance",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 top freeze",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 top L step",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 top R cross",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 top R step",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 top stand",
    "COSTUMES_TENNISBALL": "Tennisball",
    "COSTUMES_TOUCAN-A": "Toucan-a",
    "COSTUMES_TOUCAN-B": "Toucan-b",
    "COSTUMES_TOUCAN-C": "Toucan-c",
    "COSTUMES_TRAMPOLINE": "Trampoline",
    "COSTUMES_TREE1": "Tree1",
    "COSTUMES_TREES-A": "Trees-a",
    "COSTUMES_TREES-B": "Trees-b",
    "COSTUMES_TRUCK-A": "Truck-a",
    "COSTUMES_TRUCK-B": "Truck-b",
    "COSTUMES_TRUCK-C": "Truck-c",
    "COSTUMES_TRUMPET-A": "Trumpet-a",
    "COSTUMES_TRUMPET-B": "Trumpet-b",
    "COSTUMES_UNICORN": "Unicorn",
    "COSTUMES_UNICORN_2": "Unicorn 2",
    "COSTUMES_UNICORN_RUNNING-A": "Unicorn Running-a",
    "COSTUMES_UNICORN_RUNNING-B": "Unicorn Running-b",
    "COSTUMES_UNICORN_RUNNING-C": "Unicorn Running-c",
    "COSTUMES_UNICORN_RUNNING-D": "Unicorn Running-d",
    "COSTUMES_UNICORN_RUNNING-E": "Unicorn Running-e",
    "COSTUMES_UNICORN_RUNNING-F": "Unicorn Running-f",
    "COSTUMES_WAND": "Wand",
    "COSTUMES_WANDA": "Wanda",
    "COSTUMES_WATERMELON-A": "Watermelon-a",
    "COSTUMES_WATERMELON-B": "Watermelon-b",
    "COSTUMES_WATERMELON-C": "Watermelon-c",
    "COSTUMES_WINTER_HAT": "Winter Hat",
    "COSTUMES_WITCH": "Witch",
    "COSTUMES_WITCH-A": "Witch-a",
    "COSTUMES_WITCH-B": "Witch-b",
    "COSTUMES_WITCH-C": "Witch-c",
    "COSTUMES_WITCH-D": "Witch-d",
    "COSTUMES_WIZARD_HAT": "Wizard Hat",
    "COSTUMES_WIZARD-A": "Wizard-a",
    "COSTUMES_WIZARD-B": "Wizard-b",
    "COSTUMES_WIZARD-C": "Wizard-c",
    "COSTUMES_WIZARD-TOAD-A": "Wizard-toad-a",
    "COSTUMES_WIZARD-TOAD-B": "Wizard-toad-b",
    "COSTUMES_ZEBRA-A": "Zebra-a",
    "COSTUMES_ZEBRA-B": "Zebra-b",
    "COSTUMES_BLOCK-A": "Block-a",
    "COSTUMES_BLOCK-B": "Block-b",
    "COSTUMES_BLOCK-C": "Block-c",
    "COSTUMES_BLOCK-D": "Block-d",
    "COSTUMES_BLOCK-E": "Block-e",
    "COSTUMES_BLOCK-F": "Block-f",
    "COSTUMES_BLOCK-G": "Block-g",
    "COSTUMES_BLOCK-H": "Block-h",
    "COSTUMES_BLOCK-I": "Block-i",
    "COSTUMES_BLOCK-J": "Block-j",
    "COSTUMES_BLOCK-K": "Block-k",
    "COSTUMES_BLOCK-L": "Block-l",
    "COSTUMES_BLOCK-M": "Block-m",
    "COSTUMES_BLOCK-N": "Block-n",
    "COSTUMES_BLOCK-O": "Block-o",
    "COSTUMES_BLOCK-P": "Block-p",
    "COSTUMES_BLOCK-Q": "Block-q",
    "COSTUMES_BLOCK-R": "Block-r",
    "COSTUMES_BLOCK-S": "Block-s",
    "COSTUMES_BLOCK-T": "Block-t",
    "COSTUMES_BLOCK-U": "Block-u",
    "COSTUMES_BLOCK-V": "Block-v",
    "COSTUMES_BLOCK-W": "Block-w",
    "COSTUMES_BLOCK-X": "Block-x",
    "COSTUMES_BLOCK-Y": "Block-y",
    "COSTUMES_BLOCK-Z": "Block-z",
    "COSTUMES_GLOW-0": "Glow-0",
    "COSTUMES_GLOW-1": "Glow-1",
    "COSTUMES_GLOW-2": "Glow-2",
    "COSTUMES_GLOW-3": "Glow-3",
    "COSTUMES_GLOW-4": "Glow-4",
    "COSTUMES_GLOW-5": "Glow-5",
    "COSTUMES_GLOW-6": "Glow-6",
    "COSTUMES_GLOW-7": "Glow-7",
    "COSTUMES_GLOW-8": "Glow-8",
    "COSTUMES_GLOW-9": "Glow-9",
    "COSTUMES_GLOW-A": "Glow-A",
    "COSTUMES_GLOW-B": "Glow-B",
    "COSTUMES_GLOW-C": "Glow-C",
    "COSTUMES_GLOW-D": "Glow-D",
    "COSTUMES_GLOW-E": "Glow-E",
    "COSTUMES_GLOW-F": "Glow-F",
    "COSTUMES_GLOW-G": "Glow-G",
    "COSTUMES_GLOW-H": "Glow-H",
    "COSTUMES_GLOW-I": "Glow-I",
    "COSTUMES_GLOW-J": "Glow-J",
    "COSTUMES_GLOW-K": "Glow-K",
    "COSTUMES_GLOW-L": "Glow-L",
    "COSTUMES_GLOW-M": "Glow-M",
    "COSTUMES_GLOW-N": "Glow-N",
    "COSTUMES_GLOW-O": "Glow-O",
    "COSTUMES_GLOW-P": "Glow-P",
    "COSTUMES_GLOW-Q": "Glow-Q",
    "COSTUMES_GLOW-R": "Glow-R",
    "COSTUMES_GLOW-S": "Glow-S",
    "COSTUMES_GLOW-T": "Glow-T",
    "COSTUMES_GLOW-U": "Glow-U",
    "COSTUMES_GLOW-V": "Glow-V",
    "COSTUMES_GLOW-W": "Glow-W",
    "COSTUMES_GLOW-X": "Glow-X",
    "COSTUMES_GLOW-Y": "Glow-Y",
    "COSTUMES_GLOW-Z": "Glow-Z",
    "COSTUMES_STORY-A-1": "Story-A-1",
    "COSTUMES_STORY-A-2": "Story-A-2",
    "COSTUMES_STORY-A-3": "Story-A-3",
    "COSTUMES_STORY-B-1": "Story-B-1",
    "COSTUMES_STORY-B-2": "Story-B-2",
    "COSTUMES_STORY-B-3": "Story-B-3",
    "COSTUMES_STORY-C-1": "Story-C-1",
    "COSTUMES_STORY-C-2": "Story-C-2",
    "COSTUMES_STORY-C-3": "Story-C-3",
    "COSTUMES_STORY-D-1": "Story-D-1",
    "COSTUMES_STORY-D-2": "Story-D-2",
    "COSTUMES_STORY-D-3": "Story-D-3",
    "COSTUMES_STORY-E-1": "Story-E-1",
    "COSTUMES_STORY-E-2": "Story-E-2",
    "COSTUMES_STORY-E-3": "Story-E-3",
    "COSTUMES_STORY-F-1": "Story-F-1",
    "COSTUMES_STORY-F-2": "Story-F-2",
    "COSTUMES_STORY-F-3": "Story-F-3",
    "COSTUMES_STORY-G-1": "Story-G-1",
    "COSTUMES_STORY-G-2": "Story-G-2",
    "COSTUMES_STORY-G-3": "Story-G-3",
    "COSTUMES_STORY-H-1": "Story-H-1",
    "COSTUMES_STORY-H-2": "Story-H-2",
    "COSTUMES_STORY-H-3": "Story-H-3",
    "COSTUMES_STORY-I-1": "Story-I-1",
    "COSTUMES_STORY-I-2": "Story-I-2",
    "COSTUMES_STORY-I-3": "Story-I-3",
    "COSTUMES_STORY-J-1": "Story-J-1",
    "COSTUMES_STORY-J-2": "Story-J-2",
    "COSTUMES_STORY-J-3": "Story-J-3",
    "COSTUMES_STORY-K-1": "Story-K-1",
    "COSTUMES_STORY-K-2": "Story-K-2",
    "COSTUMES_STORY-K-3": "story-K-3",
    "COSTUMES_STORY-L-1": "Story-L-1",
    "COSTUMES_STORY-L-2": "Story-L-2",
    "COSTUMES_STORY-L-3": "Story-L-3",
    "COSTUMES_STORY-M-1": "Story-M-1",
    "COSTUMES_STORY-M-2": "Story-M-2",
    "COSTUMES_STORY-M-3": "Story-M-3",
    "COSTUMES_STORY-N-1": "Story-N-1",
    "COSTUMES_STORY-N-2": "Story-N-2",
    "COSTUMES_STORY-N-3": "Story-N-3",
    "COSTUMES_STORY-O-1": "Story-O-1",
    "COSTUMES_STORY-O-2": "Story-O-2",
    "COSTUMES_STORY-O-3": "Story-O-3",
    "COSTUMES_STORY-P-1": "Story-P-1",
    "COSTUMES_STORY-P-2": "Story-P-2",
    "COSTUMES_STORY-P-3": "Story-P-3",
    "COSTUMES_STORY-Q-1": "Story-Q-1",
    "COSTUMES_STORY-Q-2": "Story-Q-2",
    "COSTUMES_STORY-Q-3": "Story-Q-3",
    "COSTUMES_STORY-R-1": "Story-R-1",
    "COSTUMES_STORY-R-2": "Story-R-2",
    "COSTUMES_STORY-R-3": "Story-R-3",
    "COSTUMES_STORY-S-1": "Story-S-1",
    "COSTUMES_STORY-S-2": "Story-S-2",
    "COSTUMES_STORY-S-3": "Story-S-3",
    "COSTUMES_STORY-T-1": "Story-T-1",
    "COSTUMES_STORY-T-2": "Story-T-2",
    "COSTUMES_STORY-T-3": "Story-T-3",
    "COSTUMES_STORY-U-1": "Story-U-1",
    "COSTUMES_STORY-U-2": "Story-U-2",
    "COSTUMES_STORY-U-3": "Story-U-3",
    "COSTUMES_STORY-V-1": "Story-V-1",
    "COSTUMES_STORY-V-2": "Story-V-2",
    "COSTUMES_STORY-V-3": "Story-V-3",
    "COSTUMES_STORY-W-1": "Story-W-1",
    "COSTUMES_STORY-W-2": "Story-W-2",
    "COSTUMES_STORY-W-3": "Story-W-3",
    "COSTUMES_STORY-X-1": "Story-X-1",
    "COSTUMES_STORY-X-2": "Story-X-2",
    "COSTUMES_STORY-X-3": "Story-X-3",
    "COSTUMES_STORY-Y-1": "Story-Y-1",
    "COSTUMES_STORY-Y-2": "Story-Y-2",
    "COSTUMES_STORY-Y-3": "Story-Y-3",
    "COSTUMES_STORY-Z-1": "Story-Z-1",
    "COSTUMES_STORY-Z-2": "Story-Z-2",
    "COSTUMES_STORY-Z-3": "Story-Z-3",
    "SOUNDS_A_BASS": "A Bass",
    "SOUNDS_A_ELEC_BASS": "A Elec Bass",
    "SOUNDS_A_ELEC_GUITAR": "A Elec Guitar",
    "SOUNDS_A_ELEC_PIANO": "A Elec Piano",
    "SOUNDS_A_GUITAR": "A Guitar",
    "SOUNDS_A_MINOR_UKULELE": "A Minor Ukulele",
    "SOUNDS_A_PIANO": "A Piano",
    "SOUNDS_A_SAX": "A Sax",
    "SOUNDS_A_TROMBONE": "A Trombone",
    "SOUNDS_A_TRUMPET": "A Trumpet",
    "SOUNDS_AFRO_STRING": "Afro String",
    "SOUNDS_ALERT": "Alert",
    "SOUNDS_ALIEN_CREAK1": "Alien Creak1",
    "SOUNDS_ALIEN_CREAK2": "Alien Creak2",
    "SOUNDS_B_BASS": "B Bass",
    "SOUNDS_B_ELEC_BASS": "B Elec Bass",
    "SOUNDS_B_ELEC_GUITAR": "B Elec Guitar",
    "SOUNDS_B_ELEC_PIANO": "B Elec Piano",
    "SOUNDS_B_GUITAR": "B Guitar",
    "SOUNDS_B_PIANO": "B Piano",
    "SOUNDS_B_SAX": "B Sax",
    "SOUNDS_B_TROMBONE": "B Trombone",
    "SOUNDS_B_TRUMPET": "B Trumpet",
    "SOUNDS_BAA": "Baa",
    "SOUNDS_BARK": "Bark",
    "SOUNDS_BASKETBALL_BOUNCE": "Basketball Bounce",
    "SOUNDS_BASS_BEATBOX": "Bass Beatbox",
    "SOUNDS_BEAT_BOX1": "Beat Box1",
    "SOUNDS_BEAT_BOX2": "Beat Box2",
    "SOUNDS_BELL_CYMBAL": "Bell Cymbal",
    "SOUNDS_BELL_TOLL": "Bell Toll",
    "SOUNDS_BIG_BOING": "Big Boing",
    "SOUNDS_BIRD": "Bird",
    "SOUNDS_BIRTHDAY": "Birthday",
    "SOUNDS_BITE": "Bite",
    "SOUNDS_BOING": "Boing",
    "SOUNDS_BONK": "Bonk",
    "SOUNDS_BOOM_CLOUD": "Boom Cloud",
    "SOUNDS_BOOP_BING_BOP": "Boop Bing Bop",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": "Bowling Strike",
    "SOUNDS_BUBBLES": "Bubbles",
    "SOUNDS_BUZZ_WHIR": "Buzz Whir",
    "SOUNDS_C_BASS": "C Bass",
    "SOUNDS_C_ELEC_BASS": "C Elec Bass",
    "SOUNDS_C_ELEC_GUITAR": "C Elec Guitar",
    "SOUNDS_C_ELEC_PIANO": "C Elec Piano",
    "SOUNDS_C_GUITAR": "C Guitar",
    "SOUNDS_C_MAJOR_UKULELE": "C Major Ukulele",
    "SOUNDS_C_PIANO": "C Piano",
    "SOUNDS_C_SAX": "C Sax",
    "SOUNDS_C_TROMBONE": "C Trombone",
    "SOUNDS_C_TRUMPET": "C Trumpet",
    "SOUNDS_C2_BASS": "C2 Bass",
    "SOUNDS_C2_ELEC_BASS": "C2 Elec Bass",
    "SOUNDS_C2_ELEC_GUITAR": "C2 Elec Guitar",
    "SOUNDS_C2_ELEC_PIANO": "C2 Elec Piano",
    "SOUNDS_C2_GUITAR": "C2 guitar",
    "SOUNDS_C2_PIANO": "C2 Piano",
    "SOUNDS_C2_SAX": "C2 Sax",
    "SOUNDS_C2_TROMBONE": "C2 Trombone",
    "SOUNDS_C2_TRUMPET": "C2 Trumpet",
    "SOUNDS_CAR_HORN": "Car Horn",
    "SOUNDS_CAR_PASSING": "Car Passing",
    "SOUNDS_CAR_VROOM": "Car Vroom",
    "SOUNDS_CAVE": "Cave",
    "SOUNDS_CHATTER": "Chatter",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "Cheer",
    "SOUNDS_CHILL": "Chill",
    "SOUNDS_CHIRP": "Chirp",
    "SOUNDS_CHOMP": "Chomp",
    "SOUNDS_CHORD": "Chord",
    "SOUNDS_CLANG": "Clang",
    "SOUNDS_CLAP_BEATBOX": "Clap Beatbox",
    "SOUNDS_CLAPPING": "Clapping",
    "SOUNDS_CLASSICAL_PIANO": "Classical Piano",
    "SOUNDS_CLOCK_TICKING": "Clock Ticking",
    "SOUNDS_CLOWN_HONK": "Clown Honk",
    "SOUNDS_COIN": "Coin",
    "SOUNDS_COLLECT": "Collect",
    "SOUNDS_COMPUTER_BEEP": "Computer Beep1",
    "SOUNDS_COMPUTER_BEEP2": "Computer Beep2",
    "SOUNDS_CONNECT": "Connect",
    "SOUNDS_COUGH1": "Cough1",
    "SOUNDS_COUGH2": "Cough2",
    "SOUNDS_CRANK": "Crank",
    "SOUNDS_CRASH_BEATBOX": "Crash Beatbox",
    "SOUNDS_CRASH_CYMBAL": "crash cymbal",
    "SOUNDS_CRAZY_LAUGH": "Crazy Laugh",
    "SOUNDS_CRICKET": "Cricket",
    "SOUNDS_CRICKETS": "Crickets",
    "SOUNDS_CROAK": "Croak",
    "SOUNDS_CROWD_GASP": "Crowd Gasp",
    "SOUNDS_CROWD_LAUGH": "Crowd Laugh",
    "SOUNDS_CRUNCH": "Crunch",
    "SOUNDS_CYMBAL": "Cymbal",
    "SOUNDS_CYMBAL_CRASH": "Cymbal Crash",
    "SOUNDS_CYMBAL_ECHO": "Cymbal Echo",
    "SOUNDS_D_BASS": "D Bass",
    "SOUNDS_D_ELEC_BASS": "D Elec Bass",
    "SOUNDS_D_ELEC_GUITAR": "D Elec Guitar",
    "SOUNDS_D_ELEC_PIANO": "D Elec Piano",
    "SOUNDS_D_GUITAR": "D Guitar",
    "SOUNDS_D_PIANO": "D Piano",
    "SOUNDS_D_SAX": "D Sax",
    "SOUNDS_D_TROMBONE": "D Trombone",
    "SOUNDS_D_TRUMPET": "D Trumpet",
    "SOUNDS_DANCE_AROUND": "Dance Around",
    "SOUNDS_DANCE_CELEBRATE": "Dance Celebrate",
    "SOUNDS_DANCE_CELEBRATE2": "Dance Celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": "Dance Chill Out",
    "SOUNDS_DANCE_ENERGETIC": "Dance Energetic",
    "SOUNDS_DANCE_FUNKY": "Dance Funky",
    "SOUNDS_DANCE_HEAD_NOD": "Dance Head Nod",
    "SOUNDS_DANCE_MAGIC": "Dance Magic",
    "SOUNDS_DANCE_SITAR": "Dance Sitar",
    "SOUNDS_DANCE_SLOW_MO": "Dance Slow Mo",
    "SOUNDS_DANCE_SNARE_BEAT": "Dance Snare Beat",
    "SOUNDS_DANCE_SPACE": "Dance Space",
    "SOUNDS_DISCONNECT": "Disconnect",
    "SOUNDS_DOG1": "Dog",
    "SOUNDS_DOG2": "Dog2",
    "SOUNDS_DOOR_CLOSING": "Door Closing",
    "SOUNDS_DOOR_CREAK": "Door Creak",
    "SOUNDS_DOORBELL": "Doorbell",
    "SOUNDS_DRIP_DROP": "Drip Drop",
    "SOUNDS_DRIVE_AROUND": "Drive Around",
    "SOUNDS_DRUM": "Drum",
    "SOUNDS_DRUM_BASS1": "Drum Bass1",
    "SOUNDS_DRUM_BASS2": "Drum Bass2",
    "SOUNDS_DRUM_BASS3": "Drum Bass3",
    "SOUNDS_DRUM_BOING": "Drum Boing",
    "SOUNDS_DRUM_BUZZ": "Drum Buzz",
    "SOUNDS_DRUM_FUNKY": "Drum Funky",
    "SOUNDS_DRUM_JAM": "Drum Jam",
    "SOUNDS_DRUM_MACHINE": "Drum Machine",
    "SOUNDS_DRUM_ROLL": "Drum Roll",
    "SOUNDS_DRUM_SATELLITE": "Drum Satellite",
    "SOUNDS_DRUM_SET1": "Drum Set1",
    "SOUNDS_DRUM_SET2": "Drum Set2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "Duck",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dunnn",
    "SOUNDS_E_BASS": "E Bass",
    "SOUNDS_E_ELEC_BASS": "E Elec Bass",
    "SOUNDS_E_ELEC_GUITAR": "E Elec Guitar",
    "SOUNDS_E_ELEC_PIANO": "E Elec Piano",
    "SOUNDS_E_GUITAR": "E Guitar",
    "SOUNDS_E_PIANO": "E Piano",
    "SOUNDS_E_SAX": "E Sax",
    "SOUNDS_E_TROMBONE": "E Trombone",
    "SOUNDS_E_TRUMPET": "E Trumpet",
    "SOUNDS_EGGS": "Eggs",
    "SOUNDS_ELEC_PIANO_A_MINOR": "Elec Piano A Minor",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "Elec Piano C Major",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "Elec Piano F Major",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "Elec Piano G Major",
    "SOUNDS_ELEC_PIANO_LOOP": "Elec Piano Loop",
    "SOUNDS_EMOTIONAL_PIANO": "Emotional Piano",
    "SOUNDS_ENGINE": "Engine",
    "SOUNDS_F_BASS": "F Bass",
    "SOUNDS_F_ELEC_BASS": "F Elec Bass",
    "SOUNDS_F_ELEC_GUITAR": "F Elec Guitar",
    "SOUNDS_F_ELEC_PIANO": "F Elec Piano",
    "SOUNDS_F_GUITAR": "F Guitar",
    "SOUNDS_F_MAJOR_UKULELE": "F Major Ukulele",
    "SOUNDS_F_PIANO": "F Piano",
    "SOUNDS_F_SAX": "F Sax",
    "SOUNDS_F_TROMBONE": "F Trombone",
    "SOUNDS_F_TRUMPET": "F Trumpet",
    "SOUNDS_FAIRYDUST": "Fairydust",
    "SOUNDS_FINGER_SNAP": "Finger Snap",
    "SOUNDS_FLAM_SNARE": "Flam Snare",
    "SOUNDS_FOOTSTEPS": "Footsteps",
    "SOUNDS_G_BASS": "G Bass",
    "SOUNDS_G_ELEC_BASS": "G Elec Bass",
    "SOUNDS_G_ELEC_GUITAR": "G Elec Guitar",
    "SOUNDS_G_ELEC_PIANO": "G Elec Piano",
    "SOUNDS_G_GUITAR": "G Guitar",
    "SOUNDS_G_PIANO": "G Piano",
    "SOUNDS_G_SAX": "G Sax",
    "SOUNDS_G_TROMBONE": "G Trombone",
    "SOUNDS_G_TRUMPET": "G Trumpet",
    "SOUNDS_G_UKULELE": "G Ukulele",
    "SOUNDS_GALLOP": "Gallop",
    "SOUNDS_GARDEN": "Garden",
    "SOUNDS_GLASS_BREAKING": "Glass Breaking",
    "SOUNDS_GLUG": "Glug",
    "SOUNDS_GOAL_CHEER": "Goal Cheer",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Goose",
    "SOUNDS_GROWL": "Growl",
    "SOUNDS_GRUNT": "Grunt",
    "SOUNDS_GUITAR_CHORDS1": "Guitar Chords1",
    "SOUNDS_GUITAR_CHORDS2": "Guitar Chords2",
    "SOUNDS_GUITAR_STRUM": "Guitar Strum",
    "SOUNDS_HAND_CLAP": "Hand Clap",
    "SOUNDS_HEAD_SHAKE": "Head Shake",
    "SOUNDS_HEY": "Hey",
    "SOUNDS_HI_BEATBOX": "Hi Beatbox",
    "SOUNDS_HI_NA_TABLA": "Hi Na Tabla",
    "SOUNDS_HI_TUN_TABLA": "Hi Tun Tabla",
    "SOUNDS_HIGH_CONGA": "High Conga",
    "SOUNDS_HIGH_HAT": "High Hat",
    "SOUNDS_HIGH_TOM": "High Tom",
    "SOUNDS_HIGH_WHOOSH": "High Whoosh",
    "SOUNDS_HIHAT_BEATBOX": "Hihat Beatbox",
    "SOUNDS_HIHAT_CYMBAL": "Hihat Cymbal",
    "SOUNDS_HIP_HOP": "Hip Hop",
    "SOUNDS_HORSE": "Horse",
    "SOUNDS_HORSE_GALLOP": "horse gallop",
    "SOUNDS_HUMAN_BEATBOX1": "Human Beatbox1",
    "SOUNDS_HUMAN_BEATBOX2": "Human Beatbox2",
    "SOUNDS_JUMP": "Jump",
    "SOUNDS_JUNGLE": "Jungle",
    "SOUNDS_JUNGLE_FROGS": "Jungle Frogs",
    "SOUNDS_KICK_BACK": "Kick Back",
    "SOUNDS_KICK_DRUM": "Kick Drum",
    "SOUNDS_LARGE_COWBELL": "Large Cowbell",
    "SOUNDS_LASER1": "laser1",
    "SOUNDS_LASER2": "laser2",
    "SOUNDS_LAUGH1": "Laugh1",
    "SOUNDS_LAUGH2": "Laugh2",
    "SOUNDS_LAUGH3": "Laugh3",
    "SOUNDS_LO_GEH_TABLA": "Lo Geh Tabla",
    "SOUNDS_LO_GLISS_TABLA": "Lo Gliss Tabla",
    "SOUNDS_LOSE": "Lose",
    "SOUNDS_LOW_BOING": "Low Boing",
    "SOUNDS_LOW_CONGA": "Low Conga",
    "SOUNDS_LOW_SQUEAK": "Low Squeak",
    "SOUNDS_LOW_TOM": "Low Tom",
    "SOUNDS_LOW_WHOOSH": "Low Whoosh",
    "SOUNDS_MACHINE": "Machine",
    "SOUNDS_MAGIC_SPELL": "Magic Spell",
    "SOUNDS_MEDIEVAL1": "Medieval1",
    "SOUNDS_MEDIEVAL2": "Medieval2",
    "SOUNDS_MEOW": "Meow",
    "SOUNDS_MEOW2": "meow2",
    "SOUNDS_MOO": "Moo",
    "SOUNDS_MOTORCYCLE_PASSING": "Motorcycle Passing",
    "SOUNDS_MOVIE_1": "Movie 1",
    "SOUNDS_MOVIE_2": "Movie 2",
    "SOUNDS_MUTED_CONGA": "Muted Conga",
    "SOUNDS_MYSTERY": "Mystery",
    "SOUNDS_OCEAN_WAVE": "Ocean Wave",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "Oops",
    "SOUNDS_ORCHESTRA_TUNING": "Orchestra Tuning",
    "SOUNDS_OWL": "Owl",
    "SOUNDS_PARTY_NOISE": "Party Noise",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": "Ping Pong Hit",
    "SOUNDS_PLUCK": "Pluck",
    "SOUNDS_PLUNGE": "Plunge",
    "SOUNDS_POLICE_SIREN": "Police Siren",
    "SOUNDS_POP": "Pop",
    "SOUNDS_RAIN": "Rain",
    "SOUNDS_RATTLE": "Rattle",
    "SOUNDS_REFEREE_WHISTLE": "Referee Whistle",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "Ricochet",
    "SOUNDS_RIDE_CYMBAL": "Ride Cymbal",
    "SOUNDS_RING_TONE": "Ring Tone",
    "SOUNDS_RIP": "Rip",
    "SOUNDS_RIPPLES": "Ripples",
    "SOUNDS_ROLL_CYMBAL": "Roll Cymbal",
    "SOUNDS_ROOSTER": "Rooster",
    "SOUNDS_SCRAMBLING_FEET": "Scrambling Feet",
    "SOUNDS_SCRATCH_BEATBOX": "scratch beatbox",
    "SOUNDS_SCRATCHY_BEAT": "Scratchy Beat",
    "SOUNDS_SCREAM1": "Scream1",
    "SOUNDS_SCREAM2": "Scream2",
    "SOUNDS_SCREECH": "Screech",
    "SOUNDS_SEAGULLS": "Seagulls",
    "SOUNDS_SEWING_MACHINE": "Sewing Machine",
    "SOUNDS_SHAKER": "Shaker",
    "SOUNDS_SHIP_BELL": "Ship Bell",
    "SOUNDS_SIDESTICK_SNARE": "Sidestick Snare",
    "SOUNDS_SINGER1": "Singer1",
    "SOUNDS_SINGER2": "Singer2",
    "SOUNDS_SIREN_WHISTLE": "Siren Whistle",
    "SOUNDS_SKID": "Skid",
    "SOUNDS_SLIDE_WHISTLE": "Slide Whistle",
    "SOUNDS_SMALL_COWBELL": "Small Cowbell",
    "SOUNDS_SNAP": "Snap",
    "SOUNDS_SNARE_BEATBOX": "Snare Beatbox",
    "SOUNDS_SNARE_BEATBOX2": "Snare Beatbox2",
    "SOUNDS_SNARE_DRUM": "Snare Drum",
    "SOUNDS_SNEAKER_SQUEAK": "Sneaker Squeak",
    "SOUNDS_SNEEZE1": "Sneeze1",
    "SOUNDS_SNEEZE2": "Sneeze2",
    "SOUNDS_SNORING": "Snoring",
    "SOUNDS_SNORT": "Snort",
    "SOUNDS_SPACE_AMBIENCE": "Space Ambience",
    "SOUNDS_SPACE_FLYBY": "Space Flyby",
    "SOUNDS_SPACE_NOISE": "Space Noise",
    "SOUNDS_SPACE_RIPPLE": "Space Ripple",
    "SOUNDS_SPIRAL": "Spiral",
    "SOUNDS_SPLASH": "Splash",
    "SOUNDS_SPLASH_CYMBAL": "Splash Cymbal",
    "SOUNDS_SPOOKY_STRING": "Spooky String",
    "SOUNDS_SQUAWK": "Squawk",
    "SOUNDS_SQUEAKS": "Squeaks",
    "SOUNDS_SQUEAKY_TOY": "Squeaky Toy",
    "SOUNDS_SQUISH_POP": "Squish Pop",
    "SOUNDS_STRING_ACCENT": "String Accent",
    "SOUNDS_STRING_PLUCK": "String Pluck",
    "SOUNDS_SUCTION_CUP": "Suction Cup",
    "SOUNDS_SUSPENSE": "Suspense",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura",
    "SOUNDS_TAP_CONGA": "Tap Conga",
    "SOUNDS_TAP_SNARE": "Tap Snare",
    "SOUNDS_TECHNO": "Techno",
    "SOUNDS_TECHNO2": "Techno2",
    "SOUNDS_TELEPHONE_RING": "Telephone Ring",
    "SOUNDS_TELEPHONE_RING2": "Telephone Ring2",
    "SOUNDS_TELEPORT": "Teleport",
    "SOUNDS_TELEPORT2": "Teleport2",
    "SOUNDS_TELEPORT3": "Teleport3",
    "SOUNDS_TENNIS_HIT": "Tennis Hit",
    "SOUNDS_THUNDER_STORM": "Thunder Storm",
    "SOUNDS_TOM_DRUM": "Tom Drum",
    "SOUNDS_TOY_HONK": "Toy Honk",
    "SOUNDS_TOY_ZING": "Toy Zing",
    "SOUNDS_TRAFFIC": "Traffic",
    "SOUNDS_TRAIN_WHISTLE": "Train Whistle",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_TRIUMPH": "Triumph",
    "SOUNDS_TROPICAL_BIRDS": "Tropical Birds",
    "SOUNDS_TRUMPET1": "Trumpet1",
    "SOUNDS_TRUMPET2": "Trumpet2",
    "SOUNDS_VIDEO_GAME_1": "Video Game 1",
    "SOUNDS_VIDEO_GAME_2": "Video Game 2",
    "SOUNDS_WAH_BEATBOX": "Wah Beatbox",
    "SOUNDS_WAND": "Wand",
    "SOUNDS_WATER_DROP": "Water drop",
    "SOUNDS_WHINNY": "Whinny",
    "SOUNDS_WHISTLE_THUMP": "Whistle Thump",
    "SOUNDS_WHIZ": "Whiz",
    "SOUNDS_WHOOP": "Whoop",
    "SOUNDS_WIN": "Win",
    "SOUNDS_WOBBLE": "Wobble",
    "SOUNDS_WOLF_HOWL": "Wolf Howl",
    "SOUNDS_WOOD_TAP": "Wood Tap",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "Xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_APPLE": "Apple",
    "SPRITES_ARROW1": "Arrow1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Walking",
    "SPRITES_BALL": "Ball",
    "SPRITES_BALLERINA": "Ballerina",
    "SPRITES_BALLOON1": "Balloon1",
    "SPRITES_BANANAS": "Bananas",
    "SPRITES_BASEBALL": "Baseball",
    "SPRITES_BASKETBALL": "Basketball",
    "SPRITES_BAT": "Bat",
    "SPRITES_BATTER": "Batter",
    "SPRITES_BEACHBALL": "Beachball",
    "SPRITES_BEAR": "Bear",
    "SPRITES_BEAR-WALKING": "Bear-walking",
    "SPRITES_BEETLE": "Beetle",
    "SPRITES_BELL": "Bell",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Bowl",
    "SPRITES_BOWTIE": "Bowtie",
    "SPRITES_BREAD": "Bread",
    "SPRITES_BROOM": "Broom",
    "SPRITES_BUILDINGS": "Buildings",
    "SPRITES_BUTTERFLY_1": "Butterfly 1",
    "SPRITES_BUTTERFLY_2": "Butterfly 2",
    "SPRITES_BUTTON1": "Button1",
    "SPRITES_BUTTON2": "Button2",
    "SPRITES_BUTTON3": "Button3",
    "SPRITES_BUTTON4": "Button4",
    "SPRITES_BUTTON5": "Button5",
    "SPRITES_CAKE": "Cake",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Cat 2",
    "SPRITES_CATCHER": "Catcher",
    "SPRITES_CENTAUR": "Centaur",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Chick",
    "SPRITES_CITY_BUS": "City Bus",
    "SPRITES_CLOUD": "Cloud",
    "SPRITES_CLOUDS": "Clouds",
    "SPRITES_CONVERTIBLE": "Convertible",
    "SPRITES_CONVERTIBLE_2": "Convertible 2",
    "SPRITES_CRAB": "Crab",
    "SPRITES_CRYSTAL": "Crystal",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "Dinosaur1",
    "SPRITES_DINOSAUR2": "Dinosaur2",
    "SPRITES_DINOSAUR3": "Dinosaur3",
    "SPRITES_DINOSAUR4": "Dinosaur4",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DIVER1": "Diver1",
    "SPRITES_DIVER2": "Diver2",
    "SPRITES_DOG1": "Dog1",
    "SPRITES_DOG2": "Dog2",
    "SPRITES_DONUT": "Donut",
    "SPRITES_DORIAN": "Dorian",
    "SPRITES_DOT": "Dot",
    "SPRITES_DOVE": "Dove",
    "SPRITES_DRAGON": "Dragon",
    "SPRITES_DRAGONFLY": "Dragonfly",
    "SPRITES_DRESS": "Dress",
    "SPRITES_DRUM": "Drum",
    "SPRITES_DRUM_KIT": "Drum Kit",
    "SPRITES_DRUM-CYMBAL": "Drum-cymbal",
    "SPRITES_DRUM-HIGHHAT": "Drum-highhat",
    "SPRITES_DRUM-SNARE": "Drum-snare",
    "SPRITES_DRUMS_CONGA": "Drums Conga",
    "SPRITES_DRUMS_TABLA": "Drums Tabla",
    "SPRITES_DUCK": "Duck",
    "SPRITES_EARTH": "Earth",
    "SPRITES_EASEL": "Easel",
    "SPRITES_EGG": "Egg",
    "SPRITES_ELEPHANT": "Elephant",
    "SPRITES_ELF": "Elf",
    "SPRITES_FAIRY": "Fairy",
    "SPRITES_FISH": "Fish",
    "SPRITES_FISHBOWL": "Fishbowl",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Football",
    "SPRITES_FORTUNE_COOKIE": "Fortune Cookie",
    "SPRITES_FOX": "Fox",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "Frog",
    "SPRITES_FROG_2_": "Frog 2 ",
    "SPRITES_FRUIT_PLATTER": "Fruit Platter",
    "SPRITES_FRUIT_SALAD": "Fruit Salad",
    "SPRITES_GHOST": "Ghost",
    "SPRITES_GIFT": "Gift",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GLASS_WATER": "Glass Water",
    "SPRITES_GLASSES": "Glasses",
    "SPRITES_GOALIE": "Goalie",
    "SPRITES_GOBLIN": "Goblin",
    "SPRITES_GRASSHOPPER": "Grasshopper",
    "SPRITES_GREEN_FLAG": "Green Flag",
    "SPRITES_GRIFFIN": "Griffin",
    "SPRITES_GUITAR": "Guitar",
    "SPRITES_GUITAR-ELECTRIC1": "Guitar-electric1",
    "SPRITES_GUITAR-ELECTRIC2": "Guitar-electric2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hare",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hat1 ",
    "SPRITES_HATCHLING": "Hatchling",
    "SPRITES_HEART": "Heart",
    "SPRITES_HEART_CANDY": "Heart Candy",
    "SPRITES_HEART_FACE": "Heart Face",
    "SPRITES_HEDGEHOG": "Hedgehog",
    "SPRITES_HEN": "Hen",
    "SPRITES_HIPPO1": "Hippo1",
    "SPRITES_HOME_BUTTON": "Home Button",
    "SPRITES_HORSE": "Horse",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "Jar",
    "SPRITES_JELLYFISH": "Jellyfish",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "Key",
    "SPRITES_KEYBOARD": "Keyboard",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Knight",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_LADYBUG1": "Ladybug1",
    "SPRITES_LADYBUG2": "Ladybug2",
    "SPRITES_LAPTOP": "Laptop",
    "SPRITES_LIGHTNING": "Lightning",
    "SPRITES_LINE": "Line",
    "SPRITES_LION": "Lion",
    "SPRITES_LLAMA": "Llama",
    "SPRITES_MAGIC_WAND": "Magic Wand",
    "SPRITES_MAX": "Max",
    "SPRITES_MERMAID": "Mermaid",
    "SPRITES_MICROPHONE": "Microphone",
    "SPRITES_MILK": "Milk",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Monkey",
    "SPRITES_MOTORCYCLE": "Motorcycle",
    "SPRITES_MOUSE1": "Mouse1",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": "Neigh Pony",
    "SPRITES_OCTOPUS": "Octopus",
    "SPRITES_ORANGE": "Orange",
    "SPRITES_ORANGE2": "Orange2",
    "SPRITES_OUTFIELDER": "Outfielder",
    "SPRITES_OWL": "Owl",
    "SPRITES_PADDLE": "Paddle",
    "SPRITES_PANTHER": "Panther",
    "SPRITES_PANTS": "Pants",
    "SPRITES_PARROT": "Parrot",
    "SPRITES_PARTY_HATS": "Party Hats",
    "SPRITES_PENCIL": "Pencil",
    "SPRITES_PENGUIN": "Penguin",
    "SPRITES_PENGUIN_2": "Penguin 2",
    "SPRITES_PITCHER": "Pitcher",
    "SPRITES_PLANET2": "Planet2",
    "SPRITES_POLAR_BEAR": "Polar Bear",
    "SPRITES_POTION": "Potion",
    "SPRITES_PRINCE": "Prince",
    "SPRITES_PRINCESS": "Princess",
    "SPRITES_PUFFERFISH": "Pufferfish",
    "SPRITES_PUPPY": "Puppy",
    "SPRITES_RABBIT": "Rabbit",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": "Rainbow",
    "SPRITES_REFEREE": "Referee",
    "SPRITES_REINDEER": "Reindeer",
    "SPRITES_RETRO_ROBOT": "Retro Robot",
    "SOUNDS_COMPUTER_BEEPS1": "computer beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "computer beeps2",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Robot",
    "SPRITES_ROCKETSHIP": "Rocketship",
    "SPRITES_ROCKS": "Rocks",
    "SPRITES_ROOSTER": "Rooster",
    "SPRITES_RUBY": "Ruby",
    "SPRITES_SAILBOAT": "Sailboat",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "Saxophone",
    "SPRITES_SCARF": "Scarf",
    "SPRITES_SHARK": "Shark",
    "SPRITES_SHARK_2": "Shark 2",
    "SPRITES_SHIRT": "Shirt",
    "SPRITES_SHOES": "Shoes",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "Singer1",
    "SPRITES_SKELETON": "Skeleton",
    "SPRITES_SNAKE": "Snake",
    "SPRITES_SNOWFLAKE": "Snowflake",
    "SPRITES_SNOWMAN": "Snowman",
    "SPRITES_SOCCER_BALL": "Soccer Ball",
    "SPRITES_SPEAKER": "Speaker",
    "SPRITES_SQUIRREL": "Squirrel",
    "SPRITES_STAR": "Star",
    "SPRITES_STARFISH": "Starfish",
    "SPRITES_STOP": "Stop",
    "SPRITES_STRAWBERRY": "Strawberry",
    "SPRITES_SUN": "Sun",
    "SPRITES_SUNGLASSES1": "Sunglasses1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Takeout",
    "SPRITES_TEN80_DANCE": "Ten80 Dance",
    "SPRITES_TENNIS_BALL": "Tennis Ball",
    "SPRITES_TOUCAN": "Toucan",
    "SPRITES_TRAMPOLINE": "Trampoline",
    "SPRITES_TREE1": "Tree1",
    "SPRITES_TREES": "Trees",
    "SPRITES_TRUCK": "Truck",
    "SPRITES_TRUMPET": "Trumpet",
    "SPRITES_UNICORN": "Unicorn",
    "SPRITES_UNICORN_2": "Unicorn 2",
    "SPRITES_UNICORN_RUNNING": "Unicorn Running",
    "SPRITES_WAND": "Wand",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Watermelon",
    "SPRITES_WINTER_HAT": "Winter Hat",
    "SPRITES_WITCH": "Witch",
    "SPRITES_WIZARD": "Wizard",
    "SPRITES_WIZARD_HAT": "Wizard Hat",
    "SPRITES_WIZARD-TOAD": "Wizard-toad",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "Block-A",
    "SPRITES_BLOCK-B": "Block-B",
    "SPRITES_BLOCK-C": "Block-C",
    "SPRITES_BLOCK-D": "Block-D",
    "SPRITES_BLOCK-E": "Block-E",
    "SPRITES_BLOCK-F": "Block-F",
    "SPRITES_BLOCK-G": "Block-G",
    "SPRITES_BLOCK-H": "Block-H",
    "SPRITES_BLOCK-I": "Block-I",
    "SPRITES_BLOCK-J": "Block-J",
    "SPRITES_BLOCK-K": "Block-K",
    "SPRITES_BLOCK-L": "Block-L",
    "SPRITES_BLOCK-M": "Block-M",
    "SPRITES_BLOCK-N": "Block-N",
    "SPRITES_BLOCK-O": "Block-O",
    "SPRITES_BLOCK-P": "Block-P",
    "SPRITES_BLOCK-Q": "Block-Q",
    "SPRITES_BLOCK-R": "Block-R",
    "SPRITES_BLOCK-S": "Block-S",
    "SPRITES_BLOCK-T": "Block-T",
    "SPRITES_BLOCK-U": "Block-U",
    "SPRITES_BLOCK-V": "Block-V",
    "SPRITES_BLOCK-W": "Block-W",
    "SPRITES_BLOCK-X": "Block-X",
    "SPRITES_BLOCK-Y": "Block-Y",
    "SPRITES_BLOCK-Z": "Block-Z",
    "SPRITES_GLOW-0": "Glow-0",
    "SPRITES_GLOW-1": "Glow-1",
    "SPRITES_GLOW-2": "Glow-2",
    "SPRITES_GLOW-3": "Glow-3",
    "SPRITES_GLOW-4": "Glow-4",
    "SPRITES_GLOW-5": "Glow-5",
    "SPRITES_GLOW-6": "Glow-6",
    "SPRITES_GLOW-7": "Glow-7",
    "SPRITES_GLOW-8": "Glow-8",
    "SPRITES_GLOW-9": "Glow-9",
    "SPRITES_GLOW-A": "Glow-A",
    "SPRITES_GLOW-B": "Glow-B",
    "SPRITES_GLOW-C": "Glow-C",
    "SPRITES_GLOW-D": "Glow-D",
    "SPRITES_GLOW-E": "Glow-E",
    "SPRITES_GLOW-F": "Glow-F",
    "SPRITES_GLOW-G": "Glow-G",
    "SPRITES_GLOW-H": "Glow-H",
    "SPRITES_GLOW-I": "Glow-I",
    "SPRITES_GLOW-J": "Glow-J",
    "SPRITES_GLOW-K": "Glow-K",
    "SPRITES_GLOW-L": "Glow-L",
    "SPRITES_GLOW-M": "Glow-M",
    "SPRITES_GLOW-N": "Glow-N",
    "SPRITES_GLOW-O": "Glow-O",
    "SPRITES_GLOW-P": "Glow-P",
    "SPRITES_GLOW-Q": "Glow-Q",
    "SPRITES_GLOW-R": "Glow-R",
    "SPRITES_GLOW-S": "Glow-S",
    "SPRITES_GLOW-T": "Glow-T",
    "SPRITES_GLOW-U": "Glow-U",
    "SPRITES_GLOW-V": "Glow-V",
    "SPRITES_GLOW-W": "Glow-W",
    "SPRITES_GLOW-X": "Glow-X",
    "SPRITES_GLOW-Y": "Glow-Y",
    "SPRITES_GLOW-Z": "Glow-Z",
    "SPRITES_STORY-A": "Story-A",
    "SPRITES_STORY-B": "Story-B",
    "SPRITES_STORY-C": "Story-C",
    "SPRITES_STORY-D": "Story-D",
    "SPRITES_STORY-E": "Story-E",
    "SPRITES_STORY-F": "Story-F",
    "SPRITES_STORY-G": "Story-G",
    "SPRITES_STORY-H": "Story-H",
    "SPRITES_STORY-I": "Story-I",
    "SPRITES_STORY-J": "Story-J",
    "SPRITES_STORY-K": "Story-K",
    "SPRITES_STORY-L": "Story-L",
    "SPRITES_STORY-M": "Story-M",
    "SPRITES_STORY-N": "Story-N",
    "SPRITES_STORY-O": "Story-O",
    "SPRITES_STORY-P": "Story-P",
    "SPRITES_STORY-Q": "Story-Q",
    "SPRITES_STORY-R": "Story-R",
    "SPRITES_STORY-S": "Story-S",
    "SPRITES_STORY-T": "Story-T",
    "SPRITES_STORY-U": "Story-U",
    "SPRITES_STORY-V": "Story-V",
    "SPRITES_STORY-W": "Story-W",
    "SPRITES_STORY-X": "Story-X",
    "SPRITES_STORY-Y": "Story-Y",
    "SPRITES_STORY-Z": "Story-Z"
  },
  "uk": {
    "gui.backpack.header": "Сховок",
    "gui.backpack.errorBackpack": "Збій завантаження сховку",
    "gui.backpack.loadingBackpack": "Завантаження",
    "gui.backpack.more": "Більше",
    "gui.backpack.emptyBackpack": "Сховок порожній",
    "gui.gui.costumesTab": "Образи",
    "gui.gui.soundsTab": "Звуки",
    "gui.gui.backdropsTab": "Тло",
    "gui.gui.addExtension": "Додати розширення",
    "gui.costumeTab.addCostumeFromLibrary": "Обрати образ",
    "gui.costumeLibrary.chooseACostume": "Обрати образ",
    "gui.costumeTab.addBackdropFromLibrary": "Обрати тло",
    "gui.costumeTab.addBlankCostume": "Малювати",
    "gui.costumeTab.addSurpriseCostume": "Сюрприз",
    "gui.costumeTab.addFileBackdrop": "Вивантажити тло",
    "gui.costumeTab.addFileCostume": "Вивантажити образ",
    "gui.costumeTab.addCameraCostume": "Камера",
    "gui.soundEditor.trim": "Обрізати",
    "gui.soundEditor.stop": "Зупинити",
    "gui.soundEditor.sound": "Звук",
    "gui.soundEditor.play": "Відтворити",
    "gui.soundEditor.save": "Зберегти",
    "gui.soundEditor.undo": "Скасувати",
    "gui.soundEditor.redo": "Повернути",
    "gui.soundEditor.faster": "Швидше",
    "gui.soundEditor.slower": "Повільніше",
    "gui.soundEditor.echo": "Відлуння",
    "gui.soundEditor.robot": "Робот",
    "gui.soundEditor.louder": "Голосніше",
    "gui.soundEditor.softer": "Тихіше",
    "gui.soundEditor.reverse": "Обернути",
    "gui.soundTab.recordSound": "Записати",
    "gui.soundTab.addSoundFromLibrary": "Обрати звук",
    "gui.soundTab.surpriseSound": "Сюрприз",
    "gui.soundTab.fileUploadSound": "Вивантажити звук",
    "gui.controls.stop": "Зупинити",
    "gui.controls.go": "Виконати",
    "gui.stageHeader.stageSizeUnFull": "Вийти з повноекранного режиму",
    "gui.SpriteInfo.show": "Показати",
    "gui.SpriteInfo.size": "Розмір",
    "gui.directionPicker.rotationStyles.allAround": "Навколо",
    "gui.directionPicker.rotationStyles.leftRight": "Зліва-направо",
    "gui.directionPicker.rotationStyles.dontRotate": "Не обертати",
    "gui.spriteSelectorItem.contextMenuDuplicate": "дублювати",
    "gui.spriteSelectorItem.contextMenuDelete": "вилучити",
    "gui.spriteSelectorItem.contextMenuExport": "експорт",
    "gui.spriteSelector.addSpriteFromPaint": "Малювати",
    "gui.spriteSelector.addSpriteFromSurprise": "Сюрприз",
    "gui.spriteSelector.addSpriteFromFile": "Вивантажити спрайт",
    "gui.stageSelector.stage": "Сцена",
    "gui.stageSelector.backdrops": "Тло",
    "gui.stageSelector.addBackdropFromPaint": "Намалювати",
    "gui.stageSelector.addBackdropFromSurprise": "Сюрприз",
    "gui.stageSelector.addBackdropFromFile": "Вивантажити тло",
    "gui.modal.back": "Назад",
    "gui.library.filterPlaceholder": "Шукати",
    "gui.libraryTags.all": "Усі",
    "gui.libraryTags.animals": "Тварини",
    "gui.libraryTags.dance": "Танцювальна музика",
    "gui.libraryTags.effects": "Ефекти",
    "gui.libraryTags.fantasy": "Фантазії",
    "gui.libraryTags.fashion": "Мода",
    "gui.libraryTags.food": "Їжа",
    "gui.libraryTags.indoors": "У приміщенні",
    "gui.libraryTags.loops": "Фрагменти",
    "gui.libraryTags.music": "Музика",
    "gui.libraryTags.notes": "Примітки",
    "gui.libraryTags.outdoors": "Поза приміщенням",
    "gui.libraryTags.patterns": "Візерунки",
    "gui.libraryTags.people": "Люди",
    "gui.libraryTags.percussion": "Перкусія",
    "gui.libraryTags.space": "Космос",
    "gui.libraryTags.sports": "Спорт",
    "gui.libraryTags.underwater": "Під водою",
    "gui.libraryTags.voice": "Голос",
    "gui.libraryTags.wacky": "Дивне",
    "gui.libraryTags.animation": "Анімації",
    "gui.libraryTags.art": "Арт",
    "gui.libraryTags.games": "Ігри",
    "gui.libraryTags.stories": "Історії",
    "gui.libraryTags.letters": "Літери",
    "gui.soundLibrary.chooseASound": "Оберіть звук",
    "gui.SpriteInfo.spritePlaceholder": "Назва",
    "gui.cards.more-things-to-try": "Спробуй більше!",
    "gui.cards.see-more": "Дивитись ще",
    "gui.cards.shrink": "Зменшити",
    "gui.cards.expand": "Розгорнути",
    "gui.cards.close": "Закрити",
    "gui.loader.message1": "Створення блоків ...",
    "gui.loader.message2": "Завантаження спрайтів...",
    "gui.loader.message3": "Завантаження звуків ...",
    "gui.loader.message4": "Завантаження розширень ...",
    "gui.loader.message5": "А ми вигулюємо котів …",
    "gui.loader.message6": "Передавання нано …",
    "gui.loader.message7": "Надування гобо …",
    "gui.loader.message8": "Готуємо емоджі ...",
    "gui.loader.headline": "Завантаження проекту",
    "gui.cameraModal.cameraModalTitle": "Зробити фото",
    "gui.cameraModal.loadingCameraMessage": "Камера завантажується...",
    "gui.cameraModal.permissionRequest": "Вам потрібен дозвіл на користування камерою",
    "gui.cameraModal.retakePhoto": "Перезняти фото",
    "gui.cameraModal.save": "Зберегти",
    "gui.cameraModal.takePhoto": "Зняти фото",
    "gui.cameraModal.loadingCaption": "Завантаження",
    "gui.cameraModal.enableCameraCaption": "Ввімкнути камеру",
    "gui.recordModal.title": "Запис звуку",
    "gui.playbackStep.stopMsg": "Зупинити",
    "gui.playbackStep.playMsg": "Відтворити",
    "gui.playbackStep.loadingMsg": "Завантаження",
    "gui.playbackStep.saveMsg": "Зберегти",
    "gui.playbackStep.reRecordMsg": "Перезаписати",
    "gui.webglModal.label": "Ваш оглядач не підтримує WebGL",
    "gui.webglModal.webgllink": "не підтримує WebGL",
    "gui.prompt.cancel": "Скасувати",
    "gui.prompt.ok": "Гаразд",
    "gui.customProcedures.addAnInputNumberText": "Отримати",
    "gui.customProcedures.addAnInputBoolean": "Отримувати",
    "gui.customProcedures.numberTextType": "число або текст",
    "gui.customProcedures.booleanType": "логічне значення",
    "gui.customProcedures.addALabel": "Додати напис",
    "gui.customProcedures.runWithoutScreenRefresh": "Виконання без оновлення екрану",
    "gui.customProcedures.cancel": "Скасувати",
    "gui.customProcedures.ok": "Гаразд",
    "gui.extension.microbit.description": "Під'єднати ваші проекти до навколишнього світу",
    "gui.extension.music.name": "Музика",
    "gui.extension.music.description": "Грати на інструментах та барабанах.",
    "gui.extension.pen.name": "Олівець",
    "gui.extension.pen.description": "Малювати за допомогою спрайтів.",
    "gui.gui.variableScopeOptionAllSprites": "Для усіх спрайтів",
    "gui.gui.variableScopeOptionSpriteOnly": "Тільки для цього спрайту",
    "gui.gui.variablePromptAllSpritesMessage": "Ця змінна буде доступна для всіх спрайтів.",
    "gui.monitor.contextMenu.default": "з назвою",
    "gui.monitor.contextMenu.large": "тільки дані",
    "gui.monitor.contextMenu.slider": "слайдер",
    "gui.monitor.contextMenu.import": "імпорт",
    "gui.monitor.contextMenu.export": "експорт",
    "gui.opcodeLabels.direction": "напрям",
    "gui.opcodeLabels.xposition": "значення x",
    "gui.opcodeLabels.yposition": "значення y",
    "gui.opcodeLabels.size": "розмір",
    "gui.opcodeLabels.costumename": "ім’я образу",
    "gui.opcodeLabels.costumenumber": "образ #",
    "gui.opcodeLabels.backdropname": "ім’я тла",
    "gui.opcodeLabels.backdropnumber": "тло #",
    "gui.opcodeLabels.volume": "гучність",
    "gui.opcodeLabels.tempo": "темп",
    "gui.opcodeLabels.answer": "відповідь",
    "gui.opcodeLabels.loudness": "гучність",
    "gui.opcodeLabels.year": "рік",
    "gui.opcodeLabels.month": "місяць",
    "gui.opcodeLabels.date": "дата",
    "gui.opcodeLabels.dayofweek": "день тижня",
    "gui.opcodeLabels.hour": "година",
    "gui.opcodeLabels.minute": "хвилина ",
    "gui.opcodeLabels.second": "секунда",
    "gui.opcodeLabels.timer": "таймер",
    "music.categoryName": "Музика",
    "translate.categoryName": "Перекласти",
    "pen.categoryName": "Олівець",
    "pen.changeColorParam": "змінити [COLOR_PARAM] олівця на [VALUE]",
    "pen.changeHue": "змінити колір олівця на [HUE]",
    "pen.changeShade": "змінити тінь олівця на [SHADE]",
    "pen.changeSize": "змінити розмір олівця на [SIZE]",
    "pen.clear": "очистити все",
    "pen.colorMenu.brightness": "яскравість",
    "pen.colorMenu.color": "колір",
    "pen.colorMenu.saturation": "насиченість",
    "pen.colorMenu.transparency": "прозорість",
    "pen.penDown": "опустити олівець",
    "pen.penUp": "підняти олівець",
    "pen.setColor": "надати олівцю колір [COLOR]",
    "pen.setColorParam": "надати [COLOR_PARAM] олівця значення [VALUE]",
    "pen.setHue": "задати колір олівця [HUE]",
    "pen.setShade": "задати тінь олівця [SHADE]",
    "pen.setSize": "задати розмір олівця [SIZE]",
    "pen.stamp": "штамп",
    "music.changeTempo": "змінити темп на [TEMPO]",
    "music.drumBass": "(2) Великий басовий барабан",
    "music.drumBongo": "(13) Бонго",
    "music.drumCabasa": "(15) Кабаса",
    "music.drumClaves": "(9) Клавесин",
    "music.drumClosedHiHat": "(6) Закритий хет",
    "music.drumConga": "(14) Конга",
    "music.drumCowbell": "(11) Дзвіночок",
    "music.drumCrashCymbal": "(4) Удар тарілок",
    "music.drumCuica": "(18) Куїка",
    "music.drumGuiro": "(16) Гуїро",
    "music.drumHandClap": "(8) Плескіт долонь",
    "music.drumOpenHiHat": "(5) Відкритий високий хет",
    "music.drumSideStick": "(3) Удар по ободу",
    "music.drumSnare": "(1) Малий барабан",
    "music.drumTambourine": "(7) Тамбурин",
    "music.drumTriangle": "(12) Трикутник",
    "music.drumVibraslap": "(17) Вібруючий слеп",
    "music.drumWoodBlock": "(10) Дерев’яна коробка",
    "music.getTempo": "темп",
    "music.instrumentBass": "(6) Бас",
    "music.instrumentBassoon": "(14) Фагот",
    "music.instrumentCello": "(8) Віолончель",
    "music.instrumentChoir": "(15) Хор",
    "music.instrumentClarinet": "(10) Кларнет",
    "music.instrumentElectricGuitar": "(5) Електрогітара",
    "music.instrumentElectricPiano": "(2) Електропіаніно",
    "music.instrumentFlute": "(12) Флейта",
    "music.instrumentGuitar": "(4) Гітара",
    "music.instrumentMarimba": "(19) Марімба",
    "music.instrumentMusicBox": "(17) Музична скринька",
    "music.instrumentOrgan": "(3) Орган",
    "music.instrumentPiano": "(1) Піаніно",
    "music.instrumentPizzicato": "(7) Піцикато",
    "music.instrumentSaxophone": "(11) Саксофон",
    "music.instrumentSteelDrum": "(18) Стальний барабан",
    "music.instrumentSynthLead": "(20) Ведучий синтезатор",
    "music.instrumentSynthPad": "(21) Синтезатор Pad",
    "music.instrumentTrombone": "(9) Тромбон",
    "music.instrumentVibraphone": "(16) Вібрафон",
    "music.instrumentWoodenFlute": "(13) Дерев’яна флейта",
    "music.midiPlayDrumForBeats": "програти на барабані [DRUM] [BEATS] ударів",
    "music.midiSetInstrument": "грати інструментом [INSTRUMENT]",
    "music.playDrumForBeats": "програти на барабані [DRUM] [BEATS] ударів",
    "music.playNoteForBeats": "програти ноту [NOTE] [BEATS] тактів",
    "music.restForBeats": "пауза [BEATS] тактів",
    "music.setInstrument": "грати інструментом [INSTRUMENT]",
    "music.setTempo": "встановити темп в [TEMPO]",
    "videoSensing.categoryName": "Відеоспостереження",
    "videoSensing.direction": "напрям",
    "videoSensing.motion": "рух",
    "videoSensing.off": "Вимк",
    "videoSensing.on": "Увімкн",
    "videoSensing.onFlipped": "перевернуто",
    "videoSensing.setVideoTransparency": "встановити прозорість відео в [TRANSPARENCY]",
    "videoSensing.sprite": "спрайт",
    "videoSensing.stage": "сцена",
    "videoSensing.videoOn": "відео [ATTRIBUTE] на [SUBJECT]",
    "videoSensing.videoToggle": "перемкнути відео [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "коли рух відео > [REFERENCE]",
    "translate.translateBlock": "перекласти [WORDS] на [LANGUAGE]",
    "translate.defaultTextToTranslate": "привіт",
    "translate.viewerLanguage": "мова",
    "text2speech.speakAndWaitBlock": "говорити [WORDS]",
    "text2speech.setVoiceBlock": "встановити голос як [VOICE]",
    "text2speech.setLanguageBlock": "встановити мову [LANGUAGE]",
    "text2speech.alto": "альт",
    "text2speech.tenor": "тенор",
    "text2speech.squeak": "писк",
    "text2speech.giant": "гігант",
    "text2speech.kitten": "котик",
    "text2speech.defaultTextToSpeak": "привіт",
    "speech.whenIHear": "коли я чую [PHRASE]",
    "speech.listenAndWait": "слухати і чекати",
    "speech.defaultWhenIHearValue": "вперед",
    "paint.paintEditor.hue": "Колір",
    "paint.paintEditor.saturation": "Насиченість",
    "paint.paintEditor.brightness": "Яскравість",
    "gui.comingSoon.message1": "Не хвилюйтеся, ми тут {emoji}",
    "gui.comingSoon.message2": "Незабаром...",
    "gui.comingSoon.message3": "Ми над цим працюємо {emoji}",
    "paint.paintEditor.fill": "Заповнення",
    "paint.paintEditor.costume": "Образ",
    "paint.paintEditor.group": "Групувати",
    "paint.paintEditor.ungroup": "Розгрупувати",
    "paint.paintEditor.undo": "Скасувати",
    "paint.paintEditor.redo": "Повернути",
    "paint.paintEditor.forward": "Вперед",
    "paint.paintEditor.backward": "Назад",
    "paint.paintEditor.front": "Попереду",
    "paint.paintEditor.back": "Позаду",
    "paint.paintEditor.more": "Більше",
    "paint.modeTools.brushSize": "Розмір",
    "paint.modeTools.eraserSize": "Розмір зтирачки",
    "paint.modeTools.copy": "Копія",
    "paint.modeTools.paste": "Вставити",
    "paint.modeTools.delete": "Вилучити",
    "paint.modeTools.curved": "Крива",
    "paint.modeTools.pointed": "Кути",
    "paint.modeTools.thickness": "Товщина",
    "paint.modeTools.flipHorizontal": "Право-ліво",
    "paint.modeTools.flipVertical": "Верх-низ",
    "paint.modeTools.filled": "Заповнення",
    "paint.modeTools.outlined": "Межа",
    "paint.paintEditor.bitmap": "У растрове",
    "paint.paintEditor.vector": "У векторне",
    "paint.paintEditor.stroke": "Межа",
    "paint.brushMode.brush": "Пензель",
    "paint.eraserMode.eraser": "Гумка",
    "paint.fillMode.fill": "Заповнення",
    "paint.lineMode.line": "Лінія",
    "paint.ovalMode.oval": "Коло",
    "paint.rectMode.rect": "Прямокутник",
    "paint.reshapeMode.reshape": "Реформувати",
    "paint.roundedRectMode.roundedRect": "Закруглений прямокутник",
    "paint.selectMode.select": "Обрати",
    "paint.textMode.text": "Текст",
    "paint.colorPicker.swap": "Поміняти",
    "gui.dialog.notConnectDevice": "",
    "gui.dialog.prompt": "",
    "gui.dialog.openPort": "",
    "gui.dialog.error": "",
    "gui.dialog.notSelectDevice": "",
    "gui.dialog.connectDeviceError1": "",
    "gui.dialog.connectDeviceError2": "",
    "gui.dialog.connectDeviceError3": "",
    "gui.dialog.calibration1": "",
    "gui.dialog.calibration2": "",
    "gui.dialog.runAsManager": "",
    "gui.dialog.runErrorForVortex": "",
    "gui.dialog.runError": "",
    "gui.dialog.close": "",
    "gui.dialog.variableNameSpecialCharacters": "",
    "gui.dialog.funcNameSpecialCharacters": "",
    "gui.dialog.funcArgsSpecialCharacters": "",
    "gui.dialog.and": "",
    "gui.dialog.yes": "",
    "gui.dialog.no": "",
    "gui.dialog.maxReset": "",
    "gui.dialog.feedbackCannotEmpty": "",
    "gui.dialog.noInstallCompiler": "",
    "gui.dialog.install": "",
    "gui.dialog.cancle": "",
    "gui.dialog.installingCompiler": "",
    "gui.dialog.uploading": "",
    "gui.dialog.changeLanuage": "",
    "gui.dialog.boardNotSupportMpy": "",
    "gui.dialog.loadBlankItem": "",
    "gui.dialog.switchBaudError": "",
    "gui.dialog.turnMicrobitCompass": "",
    "gui.dialog.operationShow": "",
    "gui.dialog.mpython": "",
    "gui.dialog.computer": "",
    "gui.dialog.delete": "",
    "gui.dialog.sureDeleteFile": "",
    "gui.dialog.sureDeleteFolder": "",
    "gui.dialog.uncorrectPath": "",
    "gui.dialog.laterDownLoad": "",
    "gui.dialog.dwnLoadWebsite": "",
    "gui.dialog.laterUpdate": "",
    "gui.dialog.update": "",
    "gui.dialog.getUpdateMsgError": "",
    "gui.dialog.ok": "",
    "gui.dialog.closeCodeBoxes": "",
    "gui.dialog.emailError": "",
    "gui.dialog.emailEmpty": "",
    "gui.dialog.bigFilePrompt": "",
    "gui.dialog.successFeedbackPrompt1": "",
    "gui.dialog.successFeedbackPrompt2": "",
    "gui.dialog.failedFeedbackPrompt": "",
    "gui.dialog.successFeedbackPrompt3": "",
    "gui.dialog.forcedCloseSoftWare": "",
    "gui.dialog.saveSuccess": "",
    "gui.dialog.noSelectTruePath": "",
    "gui.dialog.newItemContent": "",
    "gui.dialog.deleteBlocksError": "",
    "gui.dialog.netIsConnect": "",
    "gui.dialog.needToUpdate": "",
    "gui.dialog.cantUseMpythonInArduinoC": "",
    "gui.dialog.enterMpyMode": "",
    "gui.dialog.runHardware": "",
    "gui.dialog.sureDelete": "",
    "gui.dialog.notSupportWebGL": "",
    "gui.dialog.boardIsnotMPython": "",
    "gui.dialog.swdMicrobitUpload": "",
    "gui.dialog.openPortError": "",
    "gui.dialog.pressedA": "",
    "gui.dialog.unpressedA": "",
    "gui.dialog.unsupportProject": "",
    "gui.dialog.openProjectError": "",
    "gui.dialog.installCompilerError1": "",
    "gui.dialog.installCompilerOffline": "",
    "gui.dialog.installCompilerError2": "",
    "gui.dialog.installCompilerError3": "",
    "gui.dialog.installCompilerSuccess": "",
    "gui.dialog.sureAdapter": "",
    "gui.dialog.checkPort": "",
    "gui.dialog.changeBoard": "",
    "gui.crashMessage.somethingWrong": "",
    "gui.crashMessage.reload": "",
    "gui.crashMessage.submit": "",
    "gui.crashMessage.attachment": "",
    "gui.crashMessage.errFeedbackNote": "",
    "gui.crashMessage.promptContent": "",
    "gui.dialog.isSaveProject": "",
    "gui.dialog.save": "",
    "gui.dialog.notSave": "",
    "BACKDROPS_ARCTIC": "",
    "BACKDROPS_BASEBALL": "",
    "BACKDROPS_BASEBALL_2": "",
    "BACKDROPS_BASKETBALL": "",
    "BACKDROPS_BASKETBALL_2": "",
    "BACKDROPS_BEACH_MALIBU": "",
    "BACKDROPS_BEACH_RIO": "",
    "BACKDROPS_BEDROOM": "",
    "BACKDROPS_BEDROOM_2": "",
    "BACKDROPS_BEDROOM_3": "",
    "BACKDROPS_BENCH_WITH_VIEW": "",
    "BACKDROPS_BLUE_SKY": "",
    "BACKDROPS_BLUE_SKY_2": "",
    "BACKDROPS_BLUE_SKY3": "",
    "BACKDROPS_BOARDWALK": "",
    "BACKDROPS_CANYON": "",
    "BACKDROPS_CASTLE_1": "",
    "BACKDROPS_CASTLE_2": "",
    "BACKDROPS_CASTLE_3": "",
    "BACKDROPS_CASTLE_4": "",
    "BACKDROPS_CHALKBOARD": "",
    "BACKDROPS_BRICK_WALL": "",
    "BACKDROPS_BRICK_WALL_2": "",
    "BACKDROPS_CIRCLES": "",
    "BACKDROPS_CITY_WITH_WATER": "",
    "BACKDROPS_COLORFUL_CITY": "",
    "BACKDROPS_CONCERT": "",
    "BACKDROPS_DESERT": "",
    "BACKDROPS_FARM": "",
    "BACKDROPS_FIELD_AT_MIT": "",
    "BACKDROPS_FLOWERS": "",
    "BACKDROPS_FOREST": "",
    "BACKDROPS_GALAXY": "",
    "BACKDROPS_GARDEN-ROCK": "",
    "BACKDROPS_GREEK_THEATER": "",
    "BACKDROPS_HALL": "",
    "BACKDROPS_HAY_FIELD": "",
    "BACKDROPS_GRAFFITI": "",
    "BACKDROPS_HEARTS1": "",
    "BACKDROPS_HEARTS2": "",
    "BACKDROPS_HILL": "",
    "BACKDROPS_JUNGLE": "",
    "BACKDROPS_JURASSIC": "",
    "BACKDROPS_LIGHT": "",
    "BACKDROPS_METRO": "",
    "BACKDROPS_MOON": "",
    "BACKDROPS_MOUNTAIN": "",
    "BACKDROPS_MURAL": "",
    "BACKDROPS_NEBULA": "",
    "BACKDROPS_NEON_TUNNEL": "",
    "BACKDROPS_NIGHT_CITY": "",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "",
    "BACKDROPS_OCEAN": "",
    "BACKDROPS_OCEAN_2": "",
    "BACKDROPS_PARTY": "",
    "BACKDROPS_PATHWAY": "",
    "BACKDROPS_PARTY_ROOM": "",
    "BACKDROPS_PLAYGROUND": "",
    "BACKDROPS_PLAYING_FIELD": "",
    "BACKDROPS_POOL": "",
    "BACKDROPS_PURPLE": "",
    "BACKDROPS_RAYS": "",
    "BACKDROPS_REFRIGERATOR": "",
    "BACKDROPS_ROOM_1": "",
    "BACKDROPS_ROOM_2": "",
    "BACKDROPS_SAVANNA": "",
    "BACKDROPS_SCHOOL": "",
    "BACKDROPS_SLOPES": "",
    "BACKDROPS_SOCCER": "",
    "BACKDROPS_SOCCER_2": "",
    "BACKDROPS_SPACE": "",
    "BACKDROPS_SPACE_2": "",
    "BACKDROPS_SPACE_3": "",
    "BACKDROPS_SPACE_4": "",
    "BACKDROPS_SPOTLIGHT-STAGE": "",
    "BACKDROPS_STARS": "",
    "BACKDROPS_STRIPES": "",
    "BACKDROPS_THEATER": "",
    "BACKDROPS_THEATER_2": "",
    "BACKDROPS_TREE": "",
    "BACKDROPS_UNDERWATER_1": "",
    "BACKDROPS_UNDERWATER_2": "",
    "BACKDROPS_URBAN": "",
    "BACKDROPS_WALL_1": "",
    "BACKDROPS_WALL_2": "",
    "BACKDROPS_WATER_AND_ROCKS": "",
    "BACKDROPS_WETLAND": "",
    "BACKDROPS_WINTER": "",
    "BACKDROPS_WINTER-LIGHTS": "",
    "BACKDROPS_WITCH_HOUSE": "",
    "BACKDROPS_WOODS": "",
    "BACKDROPS_WOODS_AND_BENCH": "",
    "BACKDROPS_XY-GRID": "",
    "BACKDROPS_XY-GRID-20PX": "",
    "BACKDROPS_XY-GRID-30PX": "",
    "COSTUMES_ABBY-A": "",
    "COSTUMES_ABBY-B": "",
    "COSTUMES_ABBY-C": "",
    "COSTUMES_ABBY-D": "",
    "COSTUMES_AMON": "",
    "COSTUMES_ANDIE-A": "",
    "COSTUMES_ANDIE-B": "",
    "COSTUMES_ANDIE-C": "",
    "COSTUMES_ANDIE-D": "",
    "COSTUMES_ANINA_POP_DOWN": "",
    "COSTUMES_ANINA_POP_FRONT": "",
    "COSTUMES_ANINA_POP_L_ARM": "",
    "COSTUMES_ANINA_POP_LEFT": "",
    "COSTUMES_ANINA_POP_R_ARM": "",
    "COSTUMES_ANINA_POP_RIGHT": "",
    "COSTUMES_ANINA_POP_STAND": "",
    "COSTUMES_ANINA_R_CROSS": "",
    "COSTUMES_ANINA_STANCE": "",
    "COSTUMES_ANINA_TOP_FREEZE": "",
    "COSTUMES_ANINA_TOP_L_STEP": "",
    "COSTUMES_ANINA_TOP_R_STEP": "",
    "COSTUMES_ANINA_TOP_STAND": "",
    "COSTUMES_APPLE": "",
    "COSTUMES_ARROW1-A": "",
    "COSTUMES_ARROW1-B": "",
    "COSTUMES_ARROW1-C": "",
    "COSTUMES_ARROW1-D": "",
    "COSTUMES_AVERY_WALKING-A": "",
    "COSTUMES_AVERY_WALKING-B": "",
    "COSTUMES_AVERY_WALKING-C": "",
    "COSTUMES_AVERY_WALKING-D": "",
    "COSTUMES_AVERY-A": "",
    "COSTUMES_AVERY-B": "",
    "COSTUMES_BALL-A": "",
    "COSTUMES_BALL-B": "",
    "COSTUMES_BALL-C": "",
    "COSTUMES_BALL-D": "",
    "COSTUMES_BALL-E": "",
    "COSTUMES_BALLERINA-A": "",
    "COSTUMES_BALLERINA-B": "",
    "COSTUMES_BALLERINA-C": "",
    "COSTUMES_BALLERINA-D": "",
    "COSTUMES_BALLOON1-A": "",
    "COSTUMES_BALLOON1-B": "",
    "COSTUMES_BALLOON1-C": "",
    "COSTUMES_BANANAS": "",
    "COSTUMES_BASEBALL": "",
    "COSTUMES_BASKETBALL": "",
    "COSTUMES_BAT-A": "",
    "COSTUMES_BAT-B": "",
    "COSTUMES_BAT-C": "",
    "COSTUMES_BAT-D": "",
    "COSTUMES_BATTER-A": "",
    "COSTUMES_BATTER-B": "",
    "COSTUMES_BATTER-C": "",
    "COSTUMES_BATTER-D": "",
    "COSTUMES_BEACHBALL": "",
    "COSTUMES_BEAR-A": "",
    "COSTUMES_BEAR-B": "",
    "COSTUMES_BEAR-WALK-A": "",
    "COSTUMES_BEAR-WALK-B": "",
    "COSTUMES_BEAR-WALK-C": "",
    "COSTUMES_BEAR-WALK-D": "",
    "COSTUMES_BEAR-WALK-E": "",
    "COSTUMES_BEAR-WALK-F": "",
    "COSTUMES_BEAR-WALK-G": "",
    "COSTUMES_BEAR-WALK-H": "",
    "COSTUMES_BEETLE": "",
    "COSTUMES_BELL1": "",
    "COSTUMES_BEN-A": "",
    "COSTUMES_BEN-B": "",
    "COSTUMES_BEN-C": "",
    "COSTUMES_BEN-D": "",
    "COSTUMES_BOWL-A": "",
    "COSTUMES_BOWTIE": "",
    "COSTUMES_BREAD": "",
    "COSTUMES_BROOM": "",
    "COSTUMES_BUILDING-A": "",
    "COSTUMES_BUILDING-B": "",
    "COSTUMES_BUILDING-C": "",
    "COSTUMES_BUILDING-D": "",
    "COSTUMES_BUILDING-E": "",
    "COSTUMES_BUILDING-F": "",
    "COSTUMES_BUILDING-G": "",
    "COSTUMES_BUILDING-H": "",
    "COSTUMES_BUILDING-I": "",
    "COSTUMES_BUILDING-J": "",
    "COSTUMES_BUTTERFLY1-A": "",
    "COSTUMES_BUTTERFLY1-B": "",
    "COSTUMES_BUTTERFLY1-C": "",
    "COSTUMES_BUTTERFLY2-A": "",
    "COSTUMES_BUTTERFLY2-B": "",
    "COSTUMES_BUTTON1": "",
    "COSTUMES_BUTTON2-A": "",
    "COSTUMES_BUTTON2-B": "",
    "COSTUMES_BUTTON3-A": "",
    "COSTUMES_BUTTON3-B": "",
    "COSTUMES_BUTTON4-A": "",
    "COSTUMES_BUTTON4-B": "",
    "COSTUMES_BUTTON5-A": "",
    "COSTUMES_BUTTON5-B": "",
    "COSTUMES_CAKE-A": "",
    "COSTUMES_CAKE-B": "",
    "COSTUMES_CALVRETT_JUMPING": "",
    "COSTUMES_CALVRETT_THINKING": "",
    "COSTUMES_CASEY-A": "",
    "COSTUMES_CASEY-B": "",
    "COSTUMES_CASEY-C": "",
    "COSTUMES_CASEY-D": "",
    "COSTUMES_CASSY-A": "",
    "COSTUMES_CASSY-B": "",
    "COSTUMES_CASSY-C": "",
    "COSTUMES_CASSY-D": "",
    "COSTUMES_CAT_2": "",
    "COSTUMES_CATCHER-A": "",
    "COSTUMES_CATCHER-B": "",
    "COSTUMES_CATCHER-C": "",
    "COSTUMES_CATCHER-D": "",
    "COSTUMES_CENTAUR-A": "",
    "COSTUMES_CENTAUR-B": "",
    "COSTUMES_CENTAUR-C": "",
    "COSTUMES_CENTAUR-D": "",
    "COSTUMES_CHAMP99-A": "",
    "COSTUMES_CHAMP99-B": "",
    "COSTUMES_CHAMP99-C": "",
    "COSTUMES_CHAMP99-D": "",
    "COSTUMES_CHAMP99-E": "",
    "COSTUMES_CHAMP99-F": "",
    "COSTUMES_CHAMP99-G": "",
    "COSTUMES_CHEESY_PUFFS": "",
    "COSTUMES_CHICK-A": "",
    "COSTUMES_CHICK-B": "",
    "COSTUMES_CHICK-C": "",
    "COSTUMES_CITY_BUS-A": "",
    "COSTUMES_CITY_BUS-B": "",
    "COSTUMES_CLOUD": "",
    "COSTUMES_CLOUD-A": "",
    "COSTUMES_CLOUD-B": "",
    "COSTUMES_CLOUD-C": "",
    "COSTUMES_CLOUD-D": "",
    "COSTUMES_CONVERTIBLE": "",
    "COSTUMES_CONVERTIBLE_3": "",
    "COSTUMES_CRAB-A": "",
    "COSTUMES_CRAB-B": "",
    "COSTUMES_CRYSTAL-A": "",
    "COSTUMES_CRYSTAL-B": "",
    "COSTUMES_DAN-A": "",
    "COSTUMES_DAN-B": "",
    "COSTUMES_DANI-A": "",
    "COSTUMES_DANI-B": "",
    "COSTUMES_DANI-C": "",
    "COSTUMES_DEE-A": "",
    "COSTUMES_DEE-B": "",
    "COSTUMES_DEE-C": "",
    "COSTUMES_DEE-D": "",
    "COSTUMES_DEE-E": "",
    "COSTUMES_DEVIN-A": "",
    "COSTUMES_DEVIN-B": "",
    "COSTUMES_DEVIN-C": "",
    "COSTUMES_DEVIN-D": "",
    "COSTUMES_DINOSAUR1-A": "",
    "COSTUMES_DINOSAUR1-B": "",
    "COSTUMES_DINOSAUR1-C": "",
    "COSTUMES_DINOSAUR1-D": "",
    "COSTUMES_DINOSAUR2-A": "",
    "COSTUMES_DINOSAUR2-B": "",
    "COSTUMES_DINOSAUR2-C": "",
    "COSTUMES_DINOSAUR2-D": "",
    "COSTUMES_DINOSAUR3-A": "",
    "COSTUMES_DINOSAUR3-B": "",
    "COSTUMES_DINOSAUR3-C": "",
    "COSTUMES_DINOSAUR3-D": "",
    "COSTUMES_DINOSAUR3-E": "",
    "COSTUMES_DINOSAUR4-A": "",
    "COSTUMES_DINOSAUR4-B": "",
    "COSTUMES_DINOSAUR4-C": "",
    "COSTUMES_DINOSAUR4-D": "",
    "COSTUMES_DIVER1": "",
    "COSTUMES_DIVER2": "",
    "COSTUMES_DM_FREEZE": "",
    "COSTUMES_DM_POP_DOWN": "",
    "COSTUMES_DM_POP_FRONT": "",
    "COSTUMES_DM_POP_L_ARM": "",
    "COSTUMES_DM_POP_LEFT": "",
    "COSTUMES_DM_POP_R_ARM": "",
    "COSTUMES_DM_POP_RIGHT": "",
    "COSTUMES_DM_POP_STAND": "",
    "COSTUMES_DM_STANCE": "",
    "COSTUMES_DM_TOP_L_LEG": "",
    "COSTUMES_DM_TOP_R_LEG": "",
    "COSTUMES_DM_TOP_R_LEG2": "",
    "COSTUMES_DM_TOP_STAND": "",
    "COSTUMES_DOG1-A": "",
    "COSTUMES_DOG1-B": "",
    "COSTUMES_DOG2-A": "",
    "COSTUMES_DOG2-B": "",
    "COSTUMES_DOG2-C": "",
    "COSTUMES_DONUT": "",
    "COSTUMES_DORIAN-A": "",
    "COSTUMES_DORIAN-B": "",
    "COSTUMES_DORIAN-C": "",
    "COSTUMES_DORIAN-D": "",
    "COSTUMES_DOT-A": "",
    "COSTUMES_DOT-B": "",
    "COSTUMES_DOT-C": "",
    "COSTUMES_DOT-D": "",
    "COSTUMES_DOVE-A": "",
    "COSTUMES_DOVE-B": "",
    "COSTUMES_DRAGON-A": "",
    "COSTUMES_DRAGON-B": "",
    "COSTUMES_DRAGON-C": "",
    "COSTUMES_DRAGON1-A": "",
    "COSTUMES_DRAGON1-B": "",
    "COSTUMES_DRAGONFLY-A": "",
    "COSTUMES_DRAGONFLY-B": "",
    "COSTUMES_DRESS-A": "",
    "COSTUMES_DRESS-B": "",
    "COSTUMES_DRESS-C": "",
    "COSTUMES_DRUM-A": "",
    "COSTUMES_DRUM-B": "",
    "COSTUMES_DRUM-CYMBAL-A": "",
    "COSTUMES_DRUM-CYMBAL-B": "",
    "COSTUMES_DRUM-HIGHHAT-A": "",
    "COSTUMES_DRUM-HIGHHAT-B": "",
    "COSTUMES_DRUM-KIT": "",
    "COSTUMES_DRUM-KIT-B": "",
    "COSTUMES_DRUM-SNARE-A": "",
    "COSTUMES_DRUM-SNARE-B": "",
    "COSTUMES_DRUMS_CONGA-A": "",
    "COSTUMES_DRUMS_CONGA-B": "",
    "COSTUMES_DUCK": "",
    "COSTUMES_EARTH": "",
    "COSTUMES_EASEL-A": "",
    "COSTUMES_EASEL-B": "",
    "COSTUMES_EASEL-C": "",
    "COSTUMES_EGG-A": "",
    "COSTUMES_EGG-B": "",
    "COSTUMES_EGG-C": "",
    "COSTUMES_EGG-D": "",
    "COSTUMES_EGG-E": "",
    "COSTUMES_EGG-F": "",
    "COSTUMES_ELEPHANT-A": "",
    "COSTUMES_ELEPHANT-B": "",
    "COSTUMES_ELF-A": "",
    "COSTUMES_ELF-B": "",
    "COSTUMES_ELF-C": "",
    "COSTUMES_ELF-D": "",
    "COSTUMES_ELF-E": "",
    "COSTUMES_FAIRY-A": "",
    "COSTUMES_FAIRY-B": "",
    "COSTUMES_FAIRY-C": "",
    "COSTUMES_FAIRY-D": "",
    "COSTUMES_FAIRY-E": "",
    "COSTUMES_FISH-A": "",
    "COSTUMES_FISH-B": "",
    "COSTUMES_FISH-C": "",
    "COSTUMES_FISH-D": "",
    "COSTUMES_FISHBOWL-A": "",
    "COSTUMES_FISHBOWL-B": "",
    "COSTUMES_FOOD_TRUCK-A": "",
    "COSTUMES_FOOD_TRUCK-B": "",
    "COSTUMES_FOOD_TRUCK-C": "",
    "COSTUMES_FOOTBALL_RUNNING": "",
    "COSTUMES_FOOTBALL_STANDING": "",
    "COSTUMES_FORTUNE_COOKIE": "",
    "COSTUMES_FOX-A": "",
    "COSTUMES_FOX-B": "",
    "COSTUMES_FOX-C": "",
    "COSTUMES_FRANK-A": "",
    "COSTUMES_FRANK-B": "",
    "COSTUMES_FRANK-C": "",
    "COSTUMES_FRANK-D": "",
    "COSTUMES_FROG": "",
    "COSTUMES_FROG_2-A": "",
    "COSTUMES_FROG_2-B": "",
    "COSTUMES_FROG_2-C": "",
    "COSTUMES_FRUIT_PLATTER": "",
    "COSTUMES_FRUITSALAD": "",
    "COSTUMES_GHOST-A": "",
    "COSTUMES_GHOST-B": "",
    "COSTUMES_GHOST-C": "",
    "COSTUMES_GHOST-D": "",
    "COSTUMES_GIFT-A": "",
    "COSTUMES_GIFT-B": "",
    "COSTUMES_GIRAFFE-A": "",
    "COSTUMES_GIRAFFE-B": "",
    "COSTUMES_GIRAFFE-C": "",
    "COSTUMES_GLASS_WATER-A": "",
    "COSTUMES_GLASS_WATER-B": "",
    "COSTUMES_GLASSES-A": "",
    "COSTUMES_GLASSES-B": "",
    "COSTUMES_GLASSES-C": "",
    "COSTUMES_GLASSES-E": "",
    "COSTUMES_GOALIE-A": "",
    "COSTUMES_GOALIE-B": "",
    "COSTUMES_GOALIE-C": "",
    "COSTUMES_GOALIE-D": "",
    "COSTUMES_GOALIE-E": "",
    "COSTUMES_GOBLIN-A": "",
    "COSTUMES_GOBLIN-B": "",
    "COSTUMES_GOBLIN-C": "",
    "COSTUMES_GOBLIN-D": "",
    "COSTUMES_GRASSHOPPER-A": "",
    "COSTUMES_GRASSHOPPER-B": "",
    "COSTUMES_GRASSHOPPER-C": "",
    "COSTUMES_GRASSHOPPER-D": "",
    "COSTUMES_GRASSHOPPER-E": "",
    "COSTUMES_GRASSHOPPER-F": "",
    "COSTUMES_GREEN_FLAG": "",
    "COSTUMES_GRIFFIN-A": "",
    "COSTUMES_GRIFFIN-B": "",
    "COSTUMES_GRIFFIN-C": "",
    "COSTUMES_GRIFFIN-D": "",
    "COSTUMES_GUITAR-A": "",
    "COSTUMES_GUITAR-B": "",
    "COSTUMES_GUITAR-ELECTRIC1-A": "",
    "COSTUMES_GUITAR-ELECTRIC1-B": "",
    "COSTUMES_GUITAR-ELECTRIC2-A": "",
    "COSTUMES_GUITAR-ELECTRIC2-B": "",
    "COSTUMES_HANNAH-A": "",
    "COSTUMES_HANNAH-B": "",
    "COSTUMES_HANNAH-C": "",
    "COSTUMES_HARE-A": "",
    "COSTUMES_HARE-B": "",
    "COSTUMES_HARE-C": "",
    "COSTUMES_HARPER-A": "",
    "COSTUMES_HARPER-B": "",
    "COSTUMES_HARPER-C": "",
    "COSTUMES_HAT-A": "",
    "COSTUMES_HAT-B": "",
    "COSTUMES_HAT-C": "",
    "COSTUMES_HAT-D": "",
    "COSTUMES_HATCHLING-A": "",
    "COSTUMES_HATCHLING-B": "",
    "COSTUMES_HATCHLING-C": "",
    "COSTUMES_HEART_CODE": "",
    "COSTUMES_HEART_FACE": "",
    "COSTUMES_HEART_LOVE": "",
    "COSTUMES_HEART_PURPLE": "",
    "COSTUMES_HEART_RED": "",
    "COSTUMES_HEART_SMILE": "",
    "COSTUMES_HEART_SWEET": "",
    "COSTUMES_HEDGEHOG-A": "",
    "COSTUMES_HEDGEHOG-B": "",
    "COSTUMES_HEDGEHOG-C": "",
    "COSTUMES_HEDGEHOG-D": "",
    "COSTUMES_HEDGEHOG-E": "",
    "COSTUMES_HEN-A": "",
    "COSTUMES_HEN-B": "",
    "COSTUMES_HEN-C": "",
    "COSTUMES_HEN-D": "",
    "COSTUMES_HIPPO1-A": "",
    "COSTUMES_HIPPO1-B": "",
    "COSTUMES_HOME_BUTTON": "",
    "COSTUMES_HORSE-A": "",
    "COSTUMES_HORSE-B": "",
    "COSTUMES_JAIME_WALKING-A": "",
    "COSTUMES_JAIME_WALKING-B": "",
    "COSTUMES_JAIME_WALKING-C": "",
    "COSTUMES_JAIME_WALKING-D": "",
    "COSTUMES_JAIME_WALKING-E": "",
    "COSTUMES_JAIME-A": "",
    "COSTUMES_JAIME-B": "",
    "COSTUMES_JAMAL-A": "",
    "COSTUMES_JAMAL-B": "",
    "COSTUMES_JAMAL-C": "",
    "COSTUMES_JAMAL-D": "",
    "COSTUMES_JAR-A": "",
    "COSTUMES_JAR-B": "",
    "COSTUMES_JELLYFISH-A": "",
    "COSTUMES_JELLYFISH-B": "",
    "COSTUMES_JELLYFISH-C": "",
    "COSTUMES_JELLYFISH-D": "",
    "COSTUMES_JO_POP_DOWN": "",
    "COSTUMES_JO_POP_FRONT": "",
    "COSTUMES_JO_POP_L_ARM": "",
    "COSTUMES_JO_POP_LEFT": "",
    "COSTUMES_JO_POP_R_ARM": "",
    "COSTUMES_JO_POP_RIGHT": "",
    "COSTUMES_JO_POP_STAND": "",
    "COSTUMES_JO_STANCE": "",
    "COSTUMES_JO_TOP_L_CROSS": "",
    "COSTUMES_JO_TOP_L_LEG": "",
    "COSTUMES_JO_TOP_R_CROSS": "",
    "COSTUMES_JO_TOP_R_LEG": "",
    "COSTUMES_JO_TOP_STAND": "",
    "COSTUMES_JORDYN-A": "",
    "COSTUMES_JORDYN-B": "",
    "COSTUMES_JORDYN-C": "",
    "COSTUMES_JORDYN-D": "",
    "COSTUMES_KAI-A": "",
    "COSTUMES_KAI-B": "",
    "COSTUMES_KEY": "",
    "COSTUMES_KEYBOARD-A": "",
    "COSTUMES_KEYBOARD-B": "",
    "COSTUMES_KIRAN-A": "",
    "COSTUMES_KIRAN-B": "",
    "COSTUMES_KIRAN-C": "",
    "COSTUMES_KIRAN-D": "",
    "COSTUMES_KIRAN-E": "",
    "COSTUMES_KIRAN-F": "",
    "COSTUMES_KNIGHT": "",
    "COSTUMES_LADYBUG2": "",
    "COSTUMES_LADYBUG2-A": "",
    "COSTUMES_LADYBUG2-B": "",
    "COSTUMES_LAPTOP": "",
    "COSTUMES_LB_POP_DOWN": "",
    "COSTUMES_LB_POP_FRONT": "",
    "COSTUMES_LB_POP_L_ARM": "",
    "COSTUMES_LB_POP_LEFT": "",
    "COSTUMES_LB_POP_R_ARM": "",
    "COSTUMES_LB_POP_RIGHT": "",
    "COSTUMES_LB_POP_STAND": "",
    "COSTUMES_LB_STANCE": "",
    "COSTUMES_LB_TOP_L_CROSS": "",
    "COSTUMES_LB_TOP_L_LEG": "",
    "COSTUMES_LB_TOP_R_CROSS": "",
    "COSTUMES_LB_TOP_R_LEG": "",
    "COSTUMES_LB_TOP_STAND": "",
    "COSTUMES_LIGHTNING": "",
    "COSTUMES_LINE": "",
    "COSTUMES_LION-A": "",
    "COSTUMES_LION-B": "",
    "COSTUMES_LION-C": "",
    "COSTUMES_LLAMA": "",
    "COSTUMES_LLAMA-B": "",
    "COSTUMES_LLAMA-C": "",
    "COSTUMES_MAGICWAND": "",
    "COSTUMES_MAX-A": "",
    "COSTUMES_MAX-B": "",
    "COSTUMES_MAX-C": "",
    "COSTUMES_MAX-D": "",
    "COSTUMES_MERMAID-A": "",
    "COSTUMES_MERMAID-B": "",
    "COSTUMES_MERMAID-C": "",
    "COSTUMES_MERMAID-D": "",
    "COSTUMES_MICROPHONE-A": "",
    "COSTUMES_MICROPHONE-B": "",
    "COSTUMES_MILK-A": "",
    "COSTUMES_MILK-B": "",
    "COSTUMES_MILK-C": "",
    "COSTUMES_MILK-D": "",
    "COSTUMES_MILK-E": "",
    "COSTUMES_MONET-A": "",
    "COSTUMES_MONET-B": "",
    "COSTUMES_MONET-C": "",
    "COSTUMES_MONET-D": "",
    "COSTUMES_MONET-E": "",
    "COSTUMES_MONKEY-A": "",
    "COSTUMES_MONKEY-B": "",
    "COSTUMES_MONKEY-C": "",
    "COSTUMES_MOTORCYCLE-A": "",
    "COSTUMES_MOTORCYCLE-B": "",
    "COSTUMES_MOTORCYCLE-C": "",
    "COSTUMES_MOTORCYCLE-D": "",
    "COSTUMES_MOUSE1-A": "",
    "COSTUMES_MOUSE1-B": "",
    "COSTUMES_MUFFIN-A": "",
    "COSTUMES_MUFFIN-B": "",
    "COSTUMES_NEIGH_PONY": "",
    "COSTUMES_OCTOPUS-A": "",
    "COSTUMES_OCTOPUS-B": "",
    "COSTUMES_OCTOPUS-C": "",
    "COSTUMES_OCTOPUS-D": "",
    "COSTUMES_OCTOPUS-E": "",
    "COSTUMES_ORANGE": "",
    "COSTUMES_ORANGE2-A": "",
    "COSTUMES_ORANGE2-B": "",
    "COSTUMES_OUTFIELDER-A": "",
    "COSTUMES_OUTFIELDER-B": "",
    "COSTUMES_OUTFIELDER-C": "",
    "COSTUMES_OUTFIELDER-D": "",
    "COSTUMES_OWL-A": "",
    "COSTUMES_OWL-B": "",
    "COSTUMES_OWL-C": "",
    "COSTUMES_PADDLE": "",
    "COSTUMES_PANTHER-A": "",
    "COSTUMES_PANTHER-B": "",
    "COSTUMES_PANTHER-C": "",
    "COSTUMES_PANTS-A": "",
    "COSTUMES_PANTS-B": "",
    "COSTUMES_PARROT-A": "",
    "COSTUMES_PARROT-B": "",
    "COSTUMES_PARTY_HAT-A": "",
    "COSTUMES_PARTY_HAT-B": "",
    "COSTUMES_PARTY_HAT-E": "",
    "COSTUMES_PENCIL-A": "",
    "COSTUMES_PENCIL-B": "",
    "COSTUMES_PENGUIN-A": "",
    "COSTUMES_PENGUIN-B": "",
    "COSTUMES_PENGUIN-C": "",
    "COSTUMES_PENGUIN2-A": "",
    "COSTUMES_PENGUIN2-B": "",
    "COSTUMES_PENGUIN2-C": "",
    "COSTUMES_PENGUIN2-D": "",
    "COSTUMES_PITCHER-A": "",
    "COSTUMES_PITCHER-B": "",
    "COSTUMES_PITCHER-C": "",
    "COSTUMES_PITCHER-D": "",
    "COSTUMES_PLANET2": "",
    "COSTUMES_POLAR_BEAR-A": "",
    "COSTUMES_POLAR_BEAR-B": "",
    "COSTUMES_POLAR_BEAR-C": "",
    "COSTUMES_POTION-A": "",
    "COSTUMES_POTION-B": "",
    "COSTUMES_POTION-C": "",
    "COSTUMES_PRINCE": "",
    "COSTUMES_PRINCESS-A": "",
    "COSTUMES_PRINCESS-B": "",
    "COSTUMES_PRINCESS-C": "",
    "COSTUMES_PRINCESS-D": "",
    "COSTUMES_PRINCESS-E": "",
    "COSTUMES_PUFFERFISH-A": "",
    "COSTUMES_PUFFERFISH-B": "",
    "COSTUMES_PUFFERFISH-C": "",
    "COSTUMES_PUFFERFISH-D": "",
    "COSTUMES_PUPPY_BACK": "",
    "COSTUMES_PUPPY_RIGHT": "",
    "COSTUMES_PUPPY_SIDE": "",
    "COSTUMES_PUPPY_SIT": "",
    "COSTUMES_RABBIT-A": "",
    "COSTUMES_RABBIT-B": "",
    "COSTUMES_RABBIT-C": "",
    "COSTUMES_RABBIT-D": "",
    "COSTUMES_RABBIT-E": "",
    "COSTUMES_RADIO-A": "",
    "COSTUMES_RADIO-B": "",
    "COSTUMES_RAINBOW": "",
    "COSTUMES_REFEREE-A": "",
    "COSTUMES_REFEREE-B": "",
    "COSTUMES_REFEREE-C": "",
    "COSTUMES_REFEREE-D": "",
    "COSTUMES_REINDEER": "",
    "COSTUMES_RETRO_ROBOT_A": "",
    "COSTUMES_RETRO_ROBOT_B": "",
    "COSTUMES_RETRO_ROBOT_C": "",
    "COSTUMES_RIPLEY-A": "",
    "COSTUMES_RIPLEY-B": "",
    "COSTUMES_RIPLEY-C": "",
    "COSTUMES_RIPLEY-D": "",
    "COSTUMES_RIPLEY-E": "",
    "COSTUMES_RIPLEY-F": "",
    "COSTUMES_ROBOT-A": "",
    "COSTUMES_ROBOT-B": "",
    "COSTUMES_ROBOT-C": "",
    "COSTUMES_ROBOT-D": "",
    "COSTUMES_ROCKETSHIP-A": "",
    "COSTUMES_ROCKETSHIP-B": "",
    "COSTUMES_ROCKETSHIP-C": "",
    "COSTUMES_ROCKETSHIP-D": "",
    "COSTUMES_ROCKETSHIP-E": "",
    "COSTUMES_ROCKS": "",
    "COSTUMES_ROOSTER-A": "",
    "COSTUMES_ROOSTER-B": "",
    "COSTUMES_ROOSTER-C": "",
    "COSTUMES_RUBY-A": "",
    "COSTUMES_RUBY-B": "",
    "COSTUMES_SAILBOAT": "",
    "COSTUMES_SAM": "",
    "COSTUMES_SAXOPHONE-A": "",
    "COSTUMES_SAXOPHONE-B": "",
    "COSTUMES_SCARF-A": "",
    "COSTUMES_SCARF-B": "",
    "COSTUMES_SCARF-C": "",
    "COSTUMES_SHARK-A": "",
    "COSTUMES_SHARK-B": "",
    "COSTUMES_SHARK2-A": "",
    "COSTUMES_SHARK2-B": "",
    "COSTUMES_SHARK2-C": "",
    "COSTUMES_SHIRT-A": "",
    "COSTUMES_SHOES-A": "",
    "COSTUMES_SHOES-B": "",
    "COSTUMES_SHOES-C": "",
    "COSTUMES_SHOES-D": "",
    "COSTUMES_SHORTS-A": "",
    "COSTUMES_SHORTS-B": "",
    "COSTUMES_SHORTS-C": "",
    "COSTUMES_SINGER1": "",
    "COSTUMES_SKELETON-A": "",
    "COSTUMES_SKELETON-B": "",
    "COSTUMES_SKELETON-D": "",
    "COSTUMES_SKELETON-E": "",
    "COSTUMES_SNAKE-A": "",
    "COSTUMES_SNAKE-B": "",
    "COSTUMES_SNAKE-C": "",
    "COSTUMES_SNOWFLAKE": "",
    "COSTUMES_SNOWMAN": "",
    "COSTUMES_SOCCER_BALL": "",
    "COSTUMES_SPEAKER": "",
    "COSTUMES_SQUIRREL": "",
    "COSTUMES_STAR": "",
    "COSTUMES_STARFISH-A": "",
    "COSTUMES_STARFISH-B_": "",
    "COSTUMES_STOP": "",
    "COSTUMES_STRAWBERRY-A": "",
    "COSTUMES_STRAWBERRY-B": "",
    "COSTUMES_STRAWBERRY-C": "",
    "COSTUMES_STRAWBERRY-D": "",
    "COSTUMES_STRAWBERRY-E": "",
    "COSTUMES_SUN": "",
    "COSTUMES_SUNGLASSES-A": "",
    "COSTUMES_SUNGLASSES-B": "",
    "COSTUMES_TABLA-A": "",
    "COSTUMES_TABLA-B": "",
    "COSTUMES_TACO": "",
    "COSTUMES_TACO-WIZARD": "",
    "COSTUMES_TAKEOUT-A": "",
    "COSTUMES_TAKEOUT-B": "",
    "COSTUMES_TAKEOUT-C": "",
    "COSTUMES_TAKEOUT-D": "",
    "COSTUMES_TAKEOUT-E": "",
    "COSTUMES_TEN80_POP_DOWN": "",
    "COSTUMES_TEN80_POP_FRONT": "",
    "COSTUMES_TEN80_POP_L_ARM": "",
    "COSTUMES_TEN80_POP_LEFT": "",
    "COSTUMES_TEN80_POP_R_ARM": "",
    "COSTUMES_TEN80_POP_RIGHT": "",
    "COSTUMES_TEN80_POP_STAND": "",
    "COSTUMES_TEN80_STANCE": "",
    "COSTUMES_TEN80_TOP_FREEZE": "",
    "COSTUMES_TEN80_TOP_L_STEP": "",
    "COSTUMES_TEN80_TOP_R_CROSS": "",
    "COSTUMES_TEN80_TOP_R_STEP": "",
    "COSTUMES_TEN80_TOP_STAND": "",
    "COSTUMES_TENNISBALL": "",
    "COSTUMES_TOUCAN-A": "",
    "COSTUMES_TOUCAN-B": "",
    "COSTUMES_TOUCAN-C": "",
    "COSTUMES_TRAMPOLINE": "",
    "COSTUMES_TREE1": "",
    "COSTUMES_TREES-A": "",
    "COSTUMES_TREES-B": "",
    "COSTUMES_TRUCK-A": "",
    "COSTUMES_TRUCK-B": "",
    "COSTUMES_TRUCK-C": "",
    "COSTUMES_TRUMPET-A": "",
    "COSTUMES_TRUMPET-B": "",
    "COSTUMES_UNICORN": "",
    "COSTUMES_UNICORN_2": "",
    "COSTUMES_UNICORN_RUNNING-A": "",
    "COSTUMES_UNICORN_RUNNING-B": "",
    "COSTUMES_UNICORN_RUNNING-C": "",
    "COSTUMES_UNICORN_RUNNING-D": "",
    "COSTUMES_UNICORN_RUNNING-E": "",
    "COSTUMES_UNICORN_RUNNING-F": "",
    "COSTUMES_WAND": "",
    "COSTUMES_WANDA": "",
    "COSTUMES_WATERMELON-A": "",
    "COSTUMES_WATERMELON-B": "",
    "COSTUMES_WATERMELON-C": "",
    "COSTUMES_WINTER_HAT": "",
    "COSTUMES_WITCH": "",
    "COSTUMES_WITCH-A": "",
    "COSTUMES_WITCH-B": "",
    "COSTUMES_WITCH-C": "",
    "COSTUMES_WITCH-D": "",
    "COSTUMES_WIZARD_HAT": "",
    "COSTUMES_WIZARD-A": "",
    "COSTUMES_WIZARD-B": "",
    "COSTUMES_WIZARD-C": "",
    "COSTUMES_WIZARD-TOAD-A": "",
    "COSTUMES_WIZARD-TOAD-B": "",
    "COSTUMES_ZEBRA-A": "",
    "COSTUMES_ZEBRA-B": "",
    "COSTUMES_BLOCK-A": "",
    "COSTUMES_BLOCK-B": "",
    "COSTUMES_BLOCK-C": "",
    "COSTUMES_BLOCK-D": "",
    "COSTUMES_BLOCK-E": "",
    "COSTUMES_BLOCK-F": "",
    "COSTUMES_BLOCK-G": "",
    "COSTUMES_BLOCK-H": "",
    "COSTUMES_BLOCK-I": "",
    "COSTUMES_BLOCK-J": "",
    "COSTUMES_BLOCK-K": "",
    "COSTUMES_BLOCK-L": "",
    "COSTUMES_BLOCK-M": "",
    "COSTUMES_BLOCK-N": "",
    "COSTUMES_BLOCK-O": "",
    "COSTUMES_BLOCK-P": "",
    "COSTUMES_BLOCK-Q": "",
    "COSTUMES_BLOCK-R": "",
    "COSTUMES_BLOCK-S": "",
    "COSTUMES_BLOCK-T": "",
    "COSTUMES_BLOCK-U": "",
    "COSTUMES_BLOCK-V": "",
    "COSTUMES_BLOCK-W": "",
    "COSTUMES_BLOCK-X": "",
    "COSTUMES_BLOCK-Y": "",
    "COSTUMES_BLOCK-Z": "",
    "COSTUMES_GLOW-0": "",
    "COSTUMES_GLOW-1": "",
    "COSTUMES_GLOW-2": "",
    "COSTUMES_GLOW-3": "",
    "COSTUMES_GLOW-4": "",
    "COSTUMES_GLOW-5": "",
    "COSTUMES_GLOW-6": "",
    "COSTUMES_GLOW-7": "",
    "COSTUMES_GLOW-8": "",
    "COSTUMES_GLOW-9": "",
    "COSTUMES_GLOW-A": "",
    "COSTUMES_GLOW-B": "",
    "COSTUMES_GLOW-C": "",
    "COSTUMES_GLOW-D": "",
    "COSTUMES_GLOW-E": "",
    "COSTUMES_GLOW-F": "",
    "COSTUMES_GLOW-G": "",
    "COSTUMES_GLOW-H": "",
    "COSTUMES_GLOW-I": "",
    "COSTUMES_GLOW-J": "",
    "COSTUMES_GLOW-K": "",
    "COSTUMES_GLOW-L": "",
    "COSTUMES_GLOW-M": "",
    "COSTUMES_GLOW-N": "",
    "COSTUMES_GLOW-O": "",
    "COSTUMES_GLOW-P": "",
    "COSTUMES_GLOW-Q": "",
    "COSTUMES_GLOW-R": "",
    "COSTUMES_GLOW-S": "",
    "COSTUMES_GLOW-T": "",
    "COSTUMES_GLOW-U": "",
    "COSTUMES_GLOW-V": "",
    "COSTUMES_GLOW-W": "",
    "COSTUMES_GLOW-X": "",
    "COSTUMES_GLOW-Y": "",
    "COSTUMES_GLOW-Z": "",
    "COSTUMES_STORY-A-1": "",
    "COSTUMES_STORY-A-2": "",
    "COSTUMES_STORY-A-3": "",
    "COSTUMES_STORY-B-1": "",
    "COSTUMES_STORY-B-2": "",
    "COSTUMES_STORY-B-3": "",
    "COSTUMES_STORY-C-1": "",
    "COSTUMES_STORY-C-2": "",
    "COSTUMES_STORY-C-3": "",
    "COSTUMES_STORY-D-1": "",
    "COSTUMES_STORY-D-2": "",
    "COSTUMES_STORY-D-3": "",
    "COSTUMES_STORY-E-1": "",
    "COSTUMES_STORY-E-2": "",
    "COSTUMES_STORY-E-3": "",
    "COSTUMES_STORY-F-1": "",
    "COSTUMES_STORY-F-2": "",
    "COSTUMES_STORY-F-3": "",
    "COSTUMES_STORY-G-1": "",
    "COSTUMES_STORY-G-2": "",
    "COSTUMES_STORY-G-3": "",
    "COSTUMES_STORY-H-1": "",
    "COSTUMES_STORY-H-2": "",
    "COSTUMES_STORY-H-3": "",
    "COSTUMES_STORY-I-1": "",
    "COSTUMES_STORY-I-2": "",
    "COSTUMES_STORY-I-3": "",
    "COSTUMES_STORY-J-1": "",
    "COSTUMES_STORY-J-2": "",
    "COSTUMES_STORY-J-3": "",
    "COSTUMES_STORY-K-1": "",
    "COSTUMES_STORY-K-2": "",
    "COSTUMES_STORY-K-3": "",
    "COSTUMES_STORY-L-1": "",
    "COSTUMES_STORY-L-2": "",
    "COSTUMES_STORY-L-3": "",
    "COSTUMES_STORY-M-1": "",
    "COSTUMES_STORY-M-2": "",
    "COSTUMES_STORY-M-3": "",
    "COSTUMES_STORY-N-1": "",
    "COSTUMES_STORY-N-2": "",
    "COSTUMES_STORY-N-3": "",
    "COSTUMES_STORY-O-1": "",
    "COSTUMES_STORY-O-2": "",
    "COSTUMES_STORY-O-3": "",
    "COSTUMES_STORY-P-1": "",
    "COSTUMES_STORY-P-2": "",
    "COSTUMES_STORY-P-3": "",
    "COSTUMES_STORY-Q-1": "",
    "COSTUMES_STORY-Q-2": "",
    "COSTUMES_STORY-Q-3": "",
    "COSTUMES_STORY-R-1": "",
    "COSTUMES_STORY-R-2": "",
    "COSTUMES_STORY-R-3": "",
    "COSTUMES_STORY-S-1": "",
    "COSTUMES_STORY-S-2": "",
    "COSTUMES_STORY-S-3": "",
    "COSTUMES_STORY-T-1": "",
    "COSTUMES_STORY-T-2": "",
    "COSTUMES_STORY-T-3": "",
    "COSTUMES_STORY-U-1": "",
    "COSTUMES_STORY-U-2": "",
    "COSTUMES_STORY-U-3": "",
    "COSTUMES_STORY-V-1": "",
    "COSTUMES_STORY-V-2": "",
    "COSTUMES_STORY-V-3": "",
    "COSTUMES_STORY-W-1": "",
    "COSTUMES_STORY-W-2": "",
    "COSTUMES_STORY-W-3": "",
    "COSTUMES_STORY-X-1": "",
    "COSTUMES_STORY-X-2": "",
    "COSTUMES_STORY-X-3": "",
    "COSTUMES_STORY-Y-1": "",
    "COSTUMES_STORY-Y-2": "",
    "COSTUMES_STORY-Y-3": "",
    "COSTUMES_STORY-Z-1": "",
    "COSTUMES_STORY-Z-2": "",
    "COSTUMES_STORY-Z-3": "",
    "SOUNDS_A_BASS": "",
    "SOUNDS_A_ELEC_BASS": "",
    "SOUNDS_A_ELEC_GUITAR": "",
    "SOUNDS_A_ELEC_PIANO": "",
    "SOUNDS_A_GUITAR": "",
    "SOUNDS_A_MINOR_UKULELE": "",
    "SOUNDS_A_PIANO": "",
    "SOUNDS_A_SAX": "",
    "SOUNDS_A_TROMBONE": "",
    "SOUNDS_A_TRUMPET": "",
    "SOUNDS_AFRO_STRING": "",
    "SOUNDS_ALERT": "",
    "SOUNDS_ALIEN_CREAK1": "",
    "SOUNDS_ALIEN_CREAK2": "",
    "SOUNDS_B_BASS": "",
    "SOUNDS_B_ELEC_BASS": "",
    "SOUNDS_B_ELEC_GUITAR": "",
    "SOUNDS_B_ELEC_PIANO": "",
    "SOUNDS_B_GUITAR": "",
    "SOUNDS_B_PIANO": "",
    "SOUNDS_B_SAX": "",
    "SOUNDS_B_TROMBONE": "",
    "SOUNDS_B_TRUMPET": "",
    "SOUNDS_BAA": "",
    "SOUNDS_BARK": "",
    "SOUNDS_BASKETBALL_BOUNCE": "",
    "SOUNDS_BASS_BEATBOX": "",
    "SOUNDS_BEAT_BOX1": "",
    "SOUNDS_BEAT_BOX2": "",
    "SOUNDS_BELL_CYMBAL": "",
    "SOUNDS_BELL_TOLL": "",
    "SOUNDS_BIG_BOING": "",
    "SOUNDS_BIRD": "",
    "SOUNDS_BIRTHDAY": "",
    "SOUNDS_BITE": "",
    "SOUNDS_BOING": "",
    "SOUNDS_BONK": "",
    "SOUNDS_BOOM_CLOUD": "",
    "SOUNDS_BOOP_BING_BOP": "",
    "SOUNDS_BOSSA_NOVA": "",
    "SOUNDS_BOWLING_STRIKE": "",
    "SOUNDS_BUBBLES": "",
    "SOUNDS_BUZZ_WHIR": "",
    "SOUNDS_C_BASS": "",
    "SOUNDS_C_ELEC_BASS": "",
    "SOUNDS_C_ELEC_GUITAR": "",
    "SOUNDS_C_ELEC_PIANO": "",
    "SOUNDS_C_GUITAR": "",
    "SOUNDS_C_MAJOR_UKULELE": "",
    "SOUNDS_C_PIANO": "",
    "SOUNDS_C_SAX": "",
    "SOUNDS_C_TROMBONE": "",
    "SOUNDS_C_TRUMPET": "",
    "SOUNDS_C2_BASS": "",
    "SOUNDS_C2_ELEC_BASS": "",
    "SOUNDS_C2_ELEC_GUITAR": "",
    "SOUNDS_C2_ELEC_PIANO": "",
    "SOUNDS_C2_GUITAR": "",
    "SOUNDS_C2_PIANO": "",
    "SOUNDS_C2_SAX": "",
    "SOUNDS_C2_TROMBONE": "",
    "SOUNDS_C2_TRUMPET": "",
    "SOUNDS_CAR_HORN": "",
    "SOUNDS_CAR_PASSING": "",
    "SOUNDS_CAR_VROOM": "",
    "SOUNDS_CAVE": "",
    "SOUNDS_CHATTER": "",
    "SOUNDS_CHEE_CHEE": "",
    "SOUNDS_CHEER": "",
    "SOUNDS_CHILL": "",
    "SOUNDS_CHIRP": "",
    "SOUNDS_CHOMP": "",
    "SOUNDS_CHORD": "",
    "SOUNDS_CLANG": "",
    "SOUNDS_CLAP_BEATBOX": "",
    "SOUNDS_CLAPPING": "",
    "SOUNDS_CLASSICAL_PIANO": "",
    "SOUNDS_CLOCK_TICKING": "",
    "SOUNDS_CLOWN_HONK": "",
    "SOUNDS_COIN": "",
    "SOUNDS_COLLECT": "",
    "SOUNDS_COMPUTER_BEEP": "",
    "SOUNDS_COMPUTER_BEEP2": "",
    "SOUNDS_CONNECT": "",
    "SOUNDS_COUGH1": "",
    "SOUNDS_COUGH2": "",
    "SOUNDS_CRANK": "",
    "SOUNDS_CRASH_BEATBOX": "",
    "SOUNDS_CRASH_CYMBAL": "",
    "SOUNDS_CRAZY_LAUGH": "",
    "SOUNDS_CRICKET": "",
    "SOUNDS_CRICKETS": "",
    "SOUNDS_CROAK": "",
    "SOUNDS_CROWD_GASP": "",
    "SOUNDS_CROWD_LAUGH": "",
    "SOUNDS_CRUNCH": "",
    "SOUNDS_CYMBAL": "",
    "SOUNDS_CYMBAL_CRASH": "",
    "SOUNDS_CYMBAL_ECHO": "",
    "SOUNDS_D_BASS": "",
    "SOUNDS_D_ELEC_BASS": "",
    "SOUNDS_D_ELEC_GUITAR": "",
    "SOUNDS_D_ELEC_PIANO": "",
    "SOUNDS_D_GUITAR": "",
    "SOUNDS_D_PIANO": "",
    "SOUNDS_D_SAX": "",
    "SOUNDS_D_TROMBONE": "",
    "SOUNDS_D_TRUMPET": "",
    "SOUNDS_DANCE_AROUND": "",
    "SOUNDS_DANCE_CELEBRATE": "",
    "SOUNDS_DANCE_CELEBRATE2": "",
    "SOUNDS_DANCE_CHILL_OUT": "",
    "SOUNDS_DANCE_ENERGETIC": "",
    "SOUNDS_DANCE_FUNKY": "",
    "SOUNDS_DANCE_HEAD_NOD": "",
    "SOUNDS_DANCE_MAGIC": "",
    "SOUNDS_DANCE_SITAR": "",
    "SOUNDS_DANCE_SLOW_MO": "",
    "SOUNDS_DANCE_SNARE_BEAT": "",
    "SOUNDS_DANCE_SPACE": "",
    "SOUNDS_DISCONNECT": "",
    "SOUNDS_DOG1": "",
    "SOUNDS_DOG2": "",
    "SOUNDS_DOOR_CLOSING": "",
    "SOUNDS_DOOR_CREAK": "",
    "SOUNDS_DOORBELL": "",
    "SOUNDS_DRIP_DROP": "",
    "SOUNDS_DRIVE_AROUND": "",
    "SOUNDS_DRUM": "",
    "SOUNDS_DRUM_BASS1": "",
    "SOUNDS_DRUM_BASS2": "",
    "SOUNDS_DRUM_BASS3": "",
    "SOUNDS_DRUM_BOING": "",
    "SOUNDS_DRUM_BUZZ": "",
    "SOUNDS_DRUM_FUNKY": "",
    "SOUNDS_DRUM_JAM": "",
    "SOUNDS_DRUM_MACHINE": "",
    "SOUNDS_DRUM_ROLL": "",
    "SOUNDS_DRUM_SATELLITE": "",
    "SOUNDS_DRUM_SET1": "",
    "SOUNDS_DRUM_SET2": "",
    "SOUNDS_DUBSTEP": "",
    "SOUNDS_DUCK": "",
    "SOUNDS_DUN_DUN_DUNNN": "",
    "SOUNDS_E_BASS": "",
    "SOUNDS_E_ELEC_BASS": "",
    "SOUNDS_E_ELEC_GUITAR": "",
    "SOUNDS_E_ELEC_PIANO": "",
    "SOUNDS_E_GUITAR": "",
    "SOUNDS_E_PIANO": "",
    "SOUNDS_E_SAX": "",
    "SOUNDS_E_TROMBONE": "",
    "SOUNDS_E_TRUMPET": "",
    "SOUNDS_EGGS": "",
    "SOUNDS_ELEC_PIANO_A_MINOR": "",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "",
    "SOUNDS_ELEC_PIANO_LOOP": "",
    "SOUNDS_EMOTIONAL_PIANO": "",
    "SOUNDS_ENGINE": "",
    "SOUNDS_F_BASS": "",
    "SOUNDS_F_ELEC_BASS": "",
    "SOUNDS_F_ELEC_GUITAR": "",
    "SOUNDS_F_ELEC_PIANO": "",
    "SOUNDS_F_GUITAR": "",
    "SOUNDS_F_MAJOR_UKULELE": "",
    "SOUNDS_F_PIANO": "",
    "SOUNDS_F_SAX": "",
    "SOUNDS_F_TROMBONE": "",
    "SOUNDS_F_TRUMPET": "",
    "SOUNDS_FAIRYDUST": "",
    "SOUNDS_FINGER_SNAP": "",
    "SOUNDS_FLAM_SNARE": "",
    "SOUNDS_FOOTSTEPS": "",
    "SOUNDS_G_BASS": "",
    "SOUNDS_G_ELEC_BASS": "",
    "SOUNDS_G_ELEC_GUITAR": "",
    "SOUNDS_G_ELEC_PIANO": "",
    "SOUNDS_G_GUITAR": "",
    "SOUNDS_G_PIANO": "",
    "SOUNDS_G_SAX": "",
    "SOUNDS_G_TROMBONE": "",
    "SOUNDS_G_TRUMPET": "",
    "SOUNDS_G_UKULELE": "",
    "SOUNDS_GALLOP": "",
    "SOUNDS_GARDEN": "",
    "SOUNDS_GLASS_BREAKING": "",
    "SOUNDS_GLUG": "",
    "SOUNDS_GOAL_CHEER": "",
    "SOUNDS_GONG": "",
    "SOUNDS_GOOSE": "",
    "SOUNDS_GROWL": "",
    "SOUNDS_GRUNT": "",
    "SOUNDS_GUITAR_CHORDS1": "",
    "SOUNDS_GUITAR_CHORDS2": "",
    "SOUNDS_GUITAR_STRUM": "",
    "SOUNDS_HAND_CLAP": "",
    "SOUNDS_HEAD_SHAKE": "",
    "SOUNDS_HEY": "",
    "SOUNDS_HI_BEATBOX": "",
    "SOUNDS_HI_NA_TABLA": "",
    "SOUNDS_HI_TUN_TABLA": "",
    "SOUNDS_HIGH_CONGA": "",
    "SOUNDS_HIGH_HAT": "",
    "SOUNDS_HIGH_TOM": "",
    "SOUNDS_HIGH_WHOOSH": "",
    "SOUNDS_HIHAT_BEATBOX": "",
    "SOUNDS_HIHAT_CYMBAL": "",
    "SOUNDS_HIP_HOP": "",
    "SOUNDS_HORSE": "",
    "SOUNDS_HORSE_GALLOP": "",
    "SOUNDS_HUMAN_BEATBOX1": "",
    "SOUNDS_HUMAN_BEATBOX2": "",
    "SOUNDS_JUMP": "",
    "SOUNDS_JUNGLE": "",
    "SOUNDS_JUNGLE_FROGS": "",
    "SOUNDS_KICK_BACK": "",
    "SOUNDS_KICK_DRUM": "",
    "SOUNDS_LARGE_COWBELL": "",
    "SOUNDS_LASER1": "",
    "SOUNDS_LASER2": "",
    "SOUNDS_LAUGH1": "",
    "SOUNDS_LAUGH2": "",
    "SOUNDS_LAUGH3": "",
    "SOUNDS_LO_GEH_TABLA": "",
    "SOUNDS_LO_GLISS_TABLA": "",
    "SOUNDS_LOSE": "",
    "SOUNDS_LOW_BOING": "",
    "SOUNDS_LOW_CONGA": "",
    "SOUNDS_LOW_SQUEAK": "",
    "SOUNDS_LOW_TOM": "",
    "SOUNDS_LOW_WHOOSH": "",
    "SOUNDS_MACHINE": "",
    "SOUNDS_MAGIC_SPELL": "",
    "SOUNDS_MEDIEVAL1": "",
    "SOUNDS_MEDIEVAL2": "",
    "SOUNDS_MEOW": "",
    "SOUNDS_MEOW2": "",
    "SOUNDS_MOO": "",
    "SOUNDS_MOTORCYCLE_PASSING": "",
    "SOUNDS_MOVIE_1": "",
    "SOUNDS_MOVIE_2": "",
    "SOUNDS_MUTED_CONGA": "",
    "SOUNDS_MYSTERY": "",
    "SOUNDS_OCEAN_WAVE": "",
    "SOUNDS_ODESONG": "",
    "SOUNDS_OOPS": "",
    "SOUNDS_ORCHESTRA_TUNING": "",
    "SOUNDS_OWL": "",
    "SOUNDS_PARTY_NOISE": "",
    "SOUNDS_PEW": "",
    "SOUNDS_PING_PONG_HIT": "",
    "SOUNDS_PLUCK": "",
    "SOUNDS_PLUNGE": "",
    "SOUNDS_POLICE_SIREN": "",
    "SOUNDS_POP": "",
    "SOUNDS_RAIN": "",
    "SOUNDS_RATTLE": "",
    "SOUNDS_REFEREE_WHISTLE": "",
    "SOUNDS_REGGAE": "",
    "SOUNDS_RICOCHET": "",
    "SOUNDS_RIDE_CYMBAL": "",
    "SOUNDS_RING_TONE": "",
    "SOUNDS_RIP": "",
    "SOUNDS_RIPPLES": "",
    "SOUNDS_ROLL_CYMBAL": "",
    "SOUNDS_ROOSTER": "",
    "SOUNDS_SCRAMBLING_FEET": "",
    "SOUNDS_SCRATCH_BEATBOX": "",
    "SOUNDS_SCRATCHY_BEAT": "",
    "SOUNDS_SCREAM1": "",
    "SOUNDS_SCREAM2": "",
    "SOUNDS_SCREECH": "",
    "SOUNDS_SEAGULLS": "",
    "SOUNDS_SEWING_MACHINE": "",
    "SOUNDS_SHAKER": "",
    "SOUNDS_SHIP_BELL": "",
    "SOUNDS_SIDESTICK_SNARE": "",
    "SOUNDS_SINGER1": "",
    "SOUNDS_SINGER2": "",
    "SOUNDS_SIREN_WHISTLE": "",
    "SOUNDS_SKID": "",
    "SOUNDS_SLIDE_WHISTLE": "",
    "SOUNDS_SMALL_COWBELL": "",
    "SOUNDS_SNAP": "",
    "SOUNDS_SNARE_BEATBOX": "",
    "SOUNDS_SNARE_BEATBOX2": "",
    "SOUNDS_SNARE_DRUM": "",
    "SOUNDS_SNEAKER_SQUEAK": "",
    "SOUNDS_SNEEZE1": "",
    "SOUNDS_SNEEZE2": "",
    "SOUNDS_SNORING": "",
    "SOUNDS_SNORT": "",
    "SOUNDS_SPACE_AMBIENCE": "",
    "SOUNDS_SPACE_FLYBY": "",
    "SOUNDS_SPACE_NOISE": "",
    "SOUNDS_SPACE_RIPPLE": "",
    "SOUNDS_SPIRAL": "",
    "SOUNDS_SPLASH": "",
    "SOUNDS_SPLASH_CYMBAL": "",
    "SOUNDS_SPOOKY_STRING": "",
    "SOUNDS_SQUAWK": "",
    "SOUNDS_SQUEAKS": "",
    "SOUNDS_SQUEAKY_TOY": "",
    "SOUNDS_SQUISH_POP": "",
    "SOUNDS_STRING_ACCENT": "",
    "SOUNDS_STRING_PLUCK": "",
    "SOUNDS_SUCTION_CUP": "",
    "SOUNDS_SUSPENSE": "",
    "SOUNDS_TADA": "",
    "SOUNDS_TAMBURA": "",
    "SOUNDS_TAP_CONGA": "",
    "SOUNDS_TAP_SNARE": "",
    "SOUNDS_TECHNO": "",
    "SOUNDS_TECHNO2": "",
    "SOUNDS_TELEPHONE_RING": "",
    "SOUNDS_TELEPHONE_RING2": "",
    "SOUNDS_TELEPORT": "",
    "SOUNDS_TELEPORT2": "",
    "SOUNDS_TELEPORT3": "",
    "SOUNDS_TENNIS_HIT": "",
    "SOUNDS_THUNDER_STORM": "",
    "SOUNDS_TOM_DRUM": "",
    "SOUNDS_TOY_HONK": "",
    "SOUNDS_TOY_ZING": "",
    "SOUNDS_TRAFFIC": "",
    "SOUNDS_TRAIN_WHISTLE": "",
    "SOUNDS_TRAP_BEAT": "",
    "SOUNDS_TRIUMPH": "",
    "SOUNDS_TROPICAL_BIRDS": "",
    "SOUNDS_TRUMPET1": "",
    "SOUNDS_TRUMPET2": "",
    "SOUNDS_VIDEO_GAME_1": "",
    "SOUNDS_VIDEO_GAME_2": "",
    "SOUNDS_WAH_BEATBOX": "",
    "SOUNDS_WAND": "",
    "SOUNDS_WATER_DROP": "",
    "SOUNDS_WHINNY": "",
    "SOUNDS_WHISTLE_THUMP": "",
    "SOUNDS_WHIZ": "",
    "SOUNDS_WHOOP": "",
    "SOUNDS_WIN": "",
    "SOUNDS_WOBBLE": "",
    "SOUNDS_WOLF_HOWL": "",
    "SOUNDS_WOOD_TAP": "",
    "SOUNDS_WUB_BEATBOX": "",
    "SOUNDS_XYLO1": "",
    "SOUNDS_XYLO2": "",
    "SOUNDS_XYLO3": "",
    "SOUNDS_XYLO4": "",
    "SOUNDS_YA": "",
    "SOUNDS_ZIP": "",
    "SOUNDS_ZOOP": "",
    "SPRITES_ABBY": "",
    "SPRITES_AMON": "",
    "SPRITES_ANDIE": "",
    "SPRITES_ANINA_DANCE": "",
    "SPRITES_APPLE": "",
    "SPRITES_ARROW1": "",
    "SPRITES_AVERY": "",
    "SPRITES_AVERY_WALKING": "",
    "SPRITES_BALL": "",
    "SPRITES_BALLERINA": "",
    "SPRITES_BALLOON1": "",
    "SPRITES_BANANAS": "",
    "SPRITES_BASEBALL": "",
    "SPRITES_BASKETBALL": "",
    "SPRITES_BAT": "",
    "SPRITES_BATTER": "",
    "SPRITES_BEACHBALL": "",
    "SPRITES_BEAR": "",
    "SPRITES_BEAR-WALKING": "",
    "SPRITES_BEETLE": "",
    "SPRITES_BELL": "",
    "SPRITES_BEN": "",
    "SPRITES_BOWL": "",
    "SPRITES_BOWTIE": "",
    "SPRITES_BREAD": "",
    "SPRITES_BROOM": "",
    "SPRITES_BUILDINGS": "",
    "SPRITES_BUTTERFLY_1": "",
    "SPRITES_BUTTERFLY_2": "",
    "SPRITES_BUTTON1": "",
    "SPRITES_BUTTON2": "",
    "SPRITES_BUTTON3": "",
    "SPRITES_BUTTON4": "",
    "SPRITES_BUTTON5": "",
    "SPRITES_CAKE": "",
    "SPRITES_CALVRETT": "",
    "SPRITES_CASEY": "",
    "SPRITES_CASSY_DANCE": "",
    "SPRITES_CAT_2": "",
    "SPRITES_CATCHER": "",
    "SPRITES_CENTAUR": "",
    "SPRITES_CHAMP99": "",
    "SPRITES_CHEESY_PUFFS": "",
    "SPRITES_CHICK": "",
    "SPRITES_CITY_BUS": "",
    "SPRITES_CLOUD": "",
    "SPRITES_CLOUDS": "",
    "SPRITES_CONVERTIBLE": "",
    "SPRITES_CONVERTIBLE_2": "",
    "SPRITES_CRAB": "",
    "SPRITES_CRYSTAL": "",
    "SPRITES_D-MONEY_DANCE": "",
    "SPRITES_DAN": "",
    "SPRITES_DANI": "",
    "SPRITES_DEE": "",
    "SPRITES_DEVIN": "",
    "SPRITES_DINOSAUR1": "",
    "SPRITES_DINOSAUR2": "",
    "SPRITES_DINOSAUR3": "",
    "SPRITES_DINOSAUR4": "",
    "SPRITES_DINOSAUR5": "",
    "COSTUMES_DINOSAUR5-A": "",
    "COSTUMES_DINOSAUR5-B": "",
    "COSTUMES_DINOSAUR5-C": "",
    "COSTUMES_DINOSAUR5-D": "",
    "COSTUMES_DINOSAUR5-E": "",
    "COSTUMES_DINOSAUR5-F": "",
    "COSTUMES_DINOSAUR5-G": "",
    "COSTUMES_DINOSAUR5-H": "",
    "SPRITES_DIVER1": "",
    "SPRITES_DIVER2": "",
    "SPRITES_DOG1": "",
    "SPRITES_DOG2": "",
    "SPRITES_DONUT": "",
    "SPRITES_DORIAN": "",
    "SPRITES_DOT": "",
    "SPRITES_DOVE": "",
    "SPRITES_DRAGON": "",
    "SPRITES_DRAGONFLY": "",
    "SPRITES_DRESS": "",
    "SPRITES_DRUM": "",
    "SPRITES_DRUM_KIT": "",
    "SPRITES_DRUM-CYMBAL": "",
    "SPRITES_DRUM-HIGHHAT": "",
    "SPRITES_DRUM-SNARE": "",
    "SPRITES_DRUMS_CONGA": "",
    "SPRITES_DRUMS_TABLA": "",
    "SPRITES_DUCK": "",
    "SPRITES_EARTH": "",
    "SPRITES_EASEL": "",
    "SPRITES_EGG": "",
    "SPRITES_ELEPHANT": "",
    "SPRITES_ELF": "",
    "SPRITES_FAIRY": "",
    "SPRITES_FISH": "",
    "SPRITES_FISHBOWL": "",
    "SPRITES_FOOD_TRUCK": "",
    "SPRITES_FOOTBALL": "",
    "SPRITES_FORTUNE_COOKIE": "",
    "SPRITES_FOX": "",
    "SPRITES_FRANK": "",
    "SPRITES_FROG": "",
    "SPRITES_FROG_2_": "",
    "SPRITES_FRUIT_PLATTER": "",
    "SPRITES_FRUIT_SALAD": "",
    "SPRITES_GHOST": "",
    "SPRITES_GIFT": "",
    "SPRITES_GIRAFFE": "",
    "SPRITES_GLASS_WATER": "",
    "SPRITES_GLASSES": "",
    "SPRITES_GOALIE": "",
    "SPRITES_GOBLIN": "",
    "SPRITES_GRASSHOPPER": "",
    "SPRITES_GREEN_FLAG": "",
    "SPRITES_GRIFFIN": "",
    "SPRITES_GUITAR": "",
    "SPRITES_GUITAR-ELECTRIC1": "",
    "SPRITES_GUITAR-ELECTRIC2": "",
    "SPRITES_HANNAH": "",
    "SPRITES_HARE": "",
    "SPRITES_HARPER": "",
    "SPRITES_HAT1_": "",
    "SPRITES_HATCHLING": "",
    "SPRITES_HEART": "",
    "SPRITES_HEART_CANDY": "",
    "SPRITES_HEART_FACE": "",
    "SPRITES_HEDGEHOG": "",
    "SPRITES_HEN": "",
    "SPRITES_HIPPO1": "",
    "SPRITES_HOME_BUTTON": "",
    "SPRITES_HORSE": "",
    "SPRITES_JAIME": "",
    "SPRITES_JAMAL": "",
    "SPRITES_JAR": "",
    "SPRITES_JELLYFISH": "",
    "SPRITES_JORDYN": "",
    "SPRITES_JOUVI_DANCE": "",
    "SPRITES_KAI": "",
    "SPRITES_KEY": "",
    "SPRITES_KEYBOARD": "",
    "SPRITES_KIRAN": "",
    "SPRITES_KNIGHT": "",
    "SPRITES_LB_DANCE": "",
    "SPRITES_LADYBUG1": "",
    "SPRITES_LADYBUG2": "",
    "SPRITES_LAPTOP": "",
    "SPRITES_LIGHTNING": "",
    "SPRITES_LINE": "",
    "SPRITES_LION": "",
    "SPRITES_LLAMA": "",
    "SPRITES_MAGIC_WAND": "",
    "SPRITES_MAX": "",
    "SPRITES_MERMAID": "",
    "SPRITES_MICROPHONE": "",
    "SPRITES_MILK": "",
    "SPRITES_MIND+": "",
    "COSTUMES_MIND+1": "",
    "SPRITES_MONET": "",
    "SPRITES_MONKEY": "",
    "SPRITES_MOTORCYCLE": "",
    "SPRITES_MOUSE1": "",
    "SPRITES_MUFFIN": "",
    "SPRITES_NEIGH_PONY": "",
    "SPRITES_OCTOPUS": "",
    "SPRITES_ORANGE": "",
    "SPRITES_ORANGE2": "",
    "SPRITES_OUTFIELDER": "",
    "SPRITES_OWL": "",
    "SPRITES_PADDLE": "",
    "SPRITES_PANTHER": "",
    "SPRITES_PANTS": "",
    "SPRITES_PARROT": "",
    "SPRITES_PARTY_HATS": "",
    "SPRITES_PENCIL": "",
    "SPRITES_PENGUIN": "",
    "SPRITES_PENGUIN_2": "",
    "SPRITES_PITCHER": "",
    "SPRITES_PLANET2": "",
    "SPRITES_POLAR_BEAR": "",
    "SPRITES_POTION": "",
    "SPRITES_PRINCE": "",
    "SPRITES_PRINCESS": "",
    "SPRITES_PUFFERFISH": "",
    "SPRITES_PUPPY": "",
    "SPRITES_RABBIT": "",
    "SPRITES_RADIO": "",
    "SPRITES_RAINBOW": "",
    "SPRITES_REFEREE": "",
    "SPRITES_REINDEER": "",
    "SPRITES_RETRO_ROBOT": "",
    "SOUNDS_COMPUTER_BEEPS1": "",
    "SOUNDS_COMPUTER_BEEPS2": "",
    "SPRITES_RIPLEY": "",
    "SPRITES_ROBOT": "",
    "SPRITES_ROCKETSHIP": "",
    "SPRITES_ROCKS": "",
    "SPRITES_ROOSTER": "",
    "SPRITES_RUBY": "",
    "SPRITES_SAILBOAT": "",
    "SPRITES_SAM": "",
    "SPRITES_SAXOPHONE": "",
    "SPRITES_SCARF": "",
    "SPRITES_SHARK": "",
    "SPRITES_SHARK_2": "",
    "SPRITES_SHIRT": "",
    "SPRITES_SHOES": "",
    "SPRITES_SHORTS": "",
    "SPRITES_SINGER1": "",
    "SPRITES_SKELETON": "",
    "SPRITES_SNAKE": "",
    "SPRITES_SNOWFLAKE": "",
    "SPRITES_SNOWMAN": "",
    "SPRITES_SOCCER_BALL": "",
    "SPRITES_SPEAKER": "",
    "SPRITES_SQUIRREL": "",
    "SPRITES_STAR": "",
    "SPRITES_STARFISH": "",
    "SPRITES_STOP": "",
    "SPRITES_STRAWBERRY": "",
    "SPRITES_SUN": "",
    "SPRITES_SUNGLASSES1": "",
    "SPRITES_TACO": "",
    "SPRITES_TAKEOUT": "",
    "SPRITES_TEN80_DANCE": "",
    "SPRITES_TENNIS_BALL": "",
    "SPRITES_TOUCAN": "",
    "SPRITES_TRAMPOLINE": "",
    "SPRITES_TREE1": "",
    "SPRITES_TREES": "",
    "SPRITES_TRUCK": "",
    "SPRITES_TRUMPET": "",
    "SPRITES_UNICORN": "",
    "SPRITES_UNICORN_2": "",
    "SPRITES_UNICORN_RUNNING": "",
    "SPRITES_WAND": "",
    "SPRITES_WANDA": "",
    "SPRITES_WATERMELON": "",
    "SPRITES_WINTER_HAT": "",
    "SPRITES_WITCH": "",
    "SPRITES_WIZARD": "",
    "SPRITES_WIZARD_HAT": "",
    "SPRITES_WIZARD-TOAD": "",
    "SPRITES_ZEBRA": "",
    "SPRITES_BLOCK-A": "",
    "SPRITES_BLOCK-B": "",
    "SPRITES_BLOCK-C": "",
    "SPRITES_BLOCK-D": "",
    "SPRITES_BLOCK-E": "",
    "SPRITES_BLOCK-F": "",
    "SPRITES_BLOCK-G": "",
    "SPRITES_BLOCK-H": "",
    "SPRITES_BLOCK-I": "",
    "SPRITES_BLOCK-J": "",
    "SPRITES_BLOCK-K": "",
    "SPRITES_BLOCK-L": "",
    "SPRITES_BLOCK-M": "",
    "SPRITES_BLOCK-N": "",
    "SPRITES_BLOCK-O": "",
    "SPRITES_BLOCK-P": "",
    "SPRITES_BLOCK-Q": "",
    "SPRITES_BLOCK-R": "",
    "SPRITES_BLOCK-S": "",
    "SPRITES_BLOCK-T": "",
    "SPRITES_BLOCK-U": "",
    "SPRITES_BLOCK-V": "",
    "SPRITES_BLOCK-W": "",
    "SPRITES_BLOCK-X": "",
    "SPRITES_BLOCK-Y": "",
    "SPRITES_BLOCK-Z": "",
    "SPRITES_GLOW-0": "",
    "SPRITES_GLOW-1": "",
    "SPRITES_GLOW-2": "",
    "SPRITES_GLOW-3": "",
    "SPRITES_GLOW-4": "",
    "SPRITES_GLOW-5": "",
    "SPRITES_GLOW-6": "",
    "SPRITES_GLOW-7": "",
    "SPRITES_GLOW-8": "",
    "SPRITES_GLOW-9": "",
    "SPRITES_GLOW-A": "",
    "SPRITES_GLOW-B": "",
    "SPRITES_GLOW-C": "",
    "SPRITES_GLOW-D": "",
    "SPRITES_GLOW-E": "",
    "SPRITES_GLOW-F": "",
    "SPRITES_GLOW-G": "",
    "SPRITES_GLOW-H": "",
    "SPRITES_GLOW-I": "",
    "SPRITES_GLOW-J": "",
    "SPRITES_GLOW-K": "",
    "SPRITES_GLOW-L": "",
    "SPRITES_GLOW-M": "",
    "SPRITES_GLOW-N": "",
    "SPRITES_GLOW-O": "",
    "SPRITES_GLOW-P": "",
    "SPRITES_GLOW-Q": "",
    "SPRITES_GLOW-R": "",
    "SPRITES_GLOW-S": "",
    "SPRITES_GLOW-T": "",
    "SPRITES_GLOW-U": "",
    "SPRITES_GLOW-V": "",
    "SPRITES_GLOW-W": "",
    "SPRITES_GLOW-X": "",
    "SPRITES_GLOW-Y": "",
    "SPRITES_GLOW-Z": "",
    "SPRITES_STORY-A": "",
    "SPRITES_STORY-B": "",
    "SPRITES_STORY-C": "",
    "SPRITES_STORY-D": "",
    "SPRITES_STORY-E": "",
    "SPRITES_STORY-F": "",
    "SPRITES_STORY-G": "",
    "SPRITES_STORY-H": "",
    "SPRITES_STORY-I": "",
    "SPRITES_STORY-J": "",
    "SPRITES_STORY-K": "",
    "SPRITES_STORY-L": "",
    "SPRITES_STORY-M": "",
    "SPRITES_STORY-N": "",
    "SPRITES_STORY-O": "",
    "SPRITES_STORY-P": "",
    "SPRITES_STORY-Q": "",
    "SPRITES_STORY-R": "",
    "SPRITES_STORY-S": "",
    "SPRITES_STORY-T": "",
    "SPRITES_STORY-U": "",
    "SPRITES_STORY-V": "",
    "SPRITES_STORY-W": "",
    "SPRITES_STORY-X": "",
    "SPRITES_STORY-Y": "",
    "SPRITES_STORY-Z": ""
  },
  "zh-cn": {
    "gui.gui.project": "项目",
    "gui.gui.newItem": "新建项目",
    "gui.gui.load": "打开项目",
    "gui.gui.iotDashboard": "可视化面板",
    "gui.gui.save": "保存项目",
    "gui.gui.saveAs": "另存项目",
    "gui.gui.saveAsMp": "另存为mp项目",
    "gui.gui.saveAsSb3": "另存为sb3项目",
    "gui.gui.cacheList": "缓存列表",
    "gui.gui.inCache": "缓存中",
    "gui.gui.learn": "教程",
    "gui.gui.clearRecord": "清空记录",
    "gui.gui.onlineLearn": "官方文档",
    "gui.gui.onlineForum": "在线论坛",
    "gui.gui.videoTutorial": "视频教程",
    "gui.gui.offlineLearn": "示例程序",
    "gui.menuBar.turboModeOff": "关闭加速模式",
    "gui.menuBar.turboModeOn": "打开加速模式",
    "gui.menuBar.edit": "编辑",
    "gui.menuBar.restoreSprite": "复原删除的角色",
    "gui.menuBar.restoreSound": "复原删除的声音",
    "gui.menuBar.restoreCostume": "复原删除的造型",
    "gui.menuBar.restoreBackdrop": "复原删除的背景",
    "gui.editorMind.restore": "恢复删除",
    "gui.turboMode.active": "加速模式",
    "gui.gui.connectDevice": "连接设备",
    "gui.gui.disconnectDevice": "断开设备",
    "gui.gui.installSerialportDriver": "一键安装串口驱动",
    "gui.gui.openInstallSerialportDriverCourse": "查看驱动安装教程",
    "gui.gui.openDM": "打开设备管理器",
    "gui.gui.restoreSetting": "恢复设备初始设置",
    "gui.gui.updateTips": "更新提示",
    "gui.gui.newVersion": "新版本",
    "gui.gui.downloadUpdate": "下载更新",
    "gui.gui.versionUpdate": "版本更新",
    "gui.gui.isCheckMd5": "正在校验",
    "gui.gui.downloading": "检查更新中",
    "gui.gui.updateError": "更新错误",
    "gui.setting.feedbackMenu": "意见反馈",
    "gui.gui.arduino": "上传模式",
    "gui.gui.scratch": "实时模式",
    "gui.gui.python": "Python模式",
    "gui.setting.officialWebsit": "官方网址",
    "gui.setting.officialQQGroup": "官方QQ交流群",
    "gui.setting.wechatOfficialAccounts": "官方微信公众号",
    "gui.setting.currentVersion": "当前版本",
    "gui.setting.checkForUpdate": "检查更新",
    "gui.setting.remindWhenUpdate": "有更新时自动下载",
    "gui.setting.currentIsLatest": "当前已是最新版本",
    "gui.setting.latestVersion": "最新版本",
    "gui.setting.download": "跳转到下载页面",
    "gui.setting.language": "语言(Language)",
    "gui.setting.uploadCsv": "上传xlsx",
    "gui.setting.theme": "主题",
    "gui.setting.feedback": "反馈意见",
    "gui.setting.email": "电子邮箱(E-mail)",
    "gui.setting.opinionFeedback": "意见/反馈",
    "gui.variableMc.variableName": "变量名称",
    "gui.variableMc.variableOff": "取消",
    "gui.variableMc.variableOn": "确定",
    "gui.variableMc.newVariable": "新建变量",
    "gui.setting.helpUsTranslate": "帮助我们翻译",
    "gui.setting.checkUpdate": "检查更新",
    "gui.setting.ok": "提交",
    "gui.setting.languageSetting": "语言",
    "gui.setting.themeSetting": "主题",
    "gui.setting.cacheSetting": "缓存",
    "gui.setting.versionUpdate": "版本更新",
    "gui.setting.connectUsTitle": "联系我们",
    "gui.setting.uploadAttachment": "上传附件",
    "gui.setting.displayTitle": "显示设置",
    "gui.setting.fontSizeSetting": "字体字号",
    "gui.setting.restartEffect": "重启生效",
    "gui.setting.fontSizeLarge": "大",
    "gui.setting.fontSizeMiddle": "中",
    "gui.setting.fontSizeSmall": "小",
    "gui.setting.onlineRepair": "在线修复工具，点击打开 >",
    "gui.setting.cacheSwitch": "缓存(打开/关闭)",
    "gui.setting.freeCache": "空闲缓存",
    "gui.setting.spaceCache": "固定间隔时间",
    "gui.setting.cacheWay": "缓存方式",
    "gui.setting.interval": "时间间隔",
    "gui.setting.freeTime": "空闲",
    "gui.setting.second": "秒",
    "gui.setting.minute": "分钟",
    "gui.gui.code": "代码",
    "gui.menubar.cloudProject": "云端项目",
    "gui.menubar.login": "登录",
    "gui.menubar.signout": "退出登录",
    "gui.menubar.saveToCloud": "保存到云端",
    "gui.menubar.cloudServer": "云端",
    "gui.setting.cleanCache": "数据清理",
    "gui.setting.programCache": "项目缓存",
    "gui.setting.configJson": "配置文件",
    "gui.setting.pythonEnv": "Python环境",
    "gui.setting.backpack": "书包文件",
    "gui.setting.compile": "编译信息",
    "gui.setting.thirdExt": "扩展用户库",
    "gui.setting.dashboard": "可视化面板",
    "gui.setting.cleanCacheBtn": "清除选择项",
    "gui.setting.cleanCachePrompt": "数据清除后无法恢复，确定清除吗？",
    "gui.setting.cleanPythonEnvPrompt": "为避免Python环境清除引起其他窗口异常，清除前请保证仅打开了一个Mind+软件窗口。",
    "gui.setting.cleanPyEnvOnPythonPrompt": "Python模式下，清理资源后需重置环境，此过程需要等待一段时间。且为避免清除资源引起其他窗口异常，清除前请保证仅打开了一个Mind+软件窗口。",
    "gui.setting.cleanDashboardPrompt": "将清除可视化面板中的所有项目，清除后不可恢复。",
    "gui.setting.expVersion": "体验版",
    "gui.setting.expUpdateTips": "【更新须知】: 该版本为尚未正式发布的尝鲜版，部分功能可能不稳定，请确认后再下载;",
    "gui.setting.cleanConfigSuccess": "数据清理完成，请重启Mind+。",
    "gui.setting.cleanConfigError": "数据清理过程中出现错误，请重新尝试一次",
    "gui.setting.cleanPartError": "清理[PART]出错......",
    "gui.setting.cleaningPyEnv": "正在清理Python环境.....",
    "gui.setting.cleanPythonEnvError": "Python环境资源释放失败，你可以关闭软件，手动删除文件夹 [PATH] 后，再重新打开软件",
    "gui.setting.multiWindowSetting": "软件多开",
    "gui.setting.multiWindowSettingTips": "为避免同时打开多个软件导致系统卡顿，性能较弱的电脑推荐关闭该功能",
    "gui.setting.multiWindowTrue": "开",
    "gui.setting.multiWindowFalse": "关",
    "gui.setting.gpu": "GPU加速",
    "gui.setting.gpu.tips": "如无异常，保持默认打开即可。",
    "gui.setting.gpu.true": "开",
    "gui.setting.gpu.false": "关",
    "gui.setting.systemSetting": "系统设置",
    "gui.backpack.header": "书包",
    "gui.backpack.errorBackpack": "载入书包时出错了",
    "gui.backpack.loadingBackpack": "载入中……",
    "gui.backpack.more": "更多",
    "gui.backpack.emptyBackpack": "书包是空的，尝试拖入一些积木程序到书包收藏。",
    "gui.gui.blocks": "模块",
    "gui.gui.costumesTab": "造型",
    "gui.gui.soundsTab": "声音",
    "gui.gui.backdropsTab": "背景",
    "gui.gui.addExtension": "扩展",
    "gui.costumeTab.addCostumeFromLibrary": "选择一个造型",
    "gui.costumeLibrary.chooseACostume": "选择一个造型",
    "gui.costumeTab.addBackdropFromLibrary": "选择一个背景",
    "gui.costumeTab.addBlankCostume": "绘制",
    "gui.costumeTab.addSurpriseCostume": "随机",
    "gui.costumeTab.addFileBackdrop": "上传背景",
    "gui.costumeTab.addFileCostume": "上传造型",
    "gui.costumeTab.addCameraCostume": "摄像头",
    "gui.sliderModal.min": "最小值",
    "gui.sliderModal.max": "最大值",
    "gui.sliderModal.title": "改变滑块范围",
    "gui.soundEditor.sound": "声音",
    "gui.soundEditor.play": "播放",
    "gui.soundEditor.stop": "停止",
    "gui.soundEditor.trim": "修剪",
    "gui.soundEditor.save": "保存",
    "gui.soundEditor.undo": "撤销",
    "gui.soundEditor.redo": "重做",
    "gui.soundEditor.faster": "快一点",
    "gui.soundEditor.slower": "慢一点",
    "gui.soundEditor.echo": "回声",
    "gui.soundEditor.robot": "机械化",
    "gui.soundEditor.louder": "响一点",
    "gui.soundEditor.softer": "轻一点",
    "gui.soundEditor.reverse": "反转",
    "gui.soundEditor.copy": "复制",
    "gui.soundEditor.paste": "粘贴",
    "gui.soundEditor.copyToNew": "新拷贝",
    "gui.soundEditor.delete": "删除",
    "gui.soundEditor.fadeOut": "渐弱",
    "gui.soundEditor.fadeIn": "渐强",
    "gui.soundEditor.mute": "静音",
    "gui.soundTab.recordSound": "录制",
    "gui.soundTab.addSoundFromLibrary": "选择一个声音",
    "gui.soundTab.surpriseSound": "随机",
    "gui.soundTab.fileUploadSound": "上传声音",
    "gui.soundTab.addSound": "添加声音",
    "gui.controls.stop": "停止",
    "gui.controls.go": "运行",
    "gui.controls.customFirmware": "自定义固件",
    "gui.controls.defaultFirmware": "官方固件",
    "gui.controls.clearLocaleFirmware": "清空记录",
    "gui.controls.checkForUpdates": "检查更新",
    "gui.controls.alreadyLastVersion": "已经是最新了",
    "gui.controls.uploadFirmware": "本地加载",
    "gui.gui.startPython": "开始运行python",
    "gui.gui.stopPython": "停止运行python",
    "gui.controls.fullScreenControl": "舞台全屏",
    "gui.gui.stageSizeLarge": "大舞台模式",
    "gui.gui.stageSizeSmall": "小舞台模式",
    "gui.gui.stageSizeNostage": "无舞台模式",
    "gui.gui.stageSizeFull": "舞台全屏",
    "gui.stageHeader.stageSizeUnFull": "退出全屏模式",
    "gui.sprite.sprite": "精灵",
    "gui.SpriteInfo.show": "显示",
    "gui.SpriteInfo.size": "大小",
    "gui.sprite.direction": "方向",
    "gui.sprite.rotation": "角度",
    "gui.directionPicker.rotationStyles.allAround": "任意旋转",
    "gui.directionPicker.rotationStyles.leftRight": "左右翻转",
    "gui.directionPicker.rotationStyles.dontRotate": "不旋转",
    "gui.spriteSelectorItem.contextMenuDuplicate": "复制",
    "gui.spriteSelectorItem.contextMenuDelete": "删除",
    "gui.spriteSelectorItem.contextMenuExport": "导出",
    "gui.sprite.addSpriteFromLibrary": "角色库",
    "gui.spriteSelector.addSpriteFromPaint": "绘制",
    "gui.spriteSelector.addSpriteFromSurprise": "随机",
    "gui.spriteSelector.addSpriteFromFile": "上传角色",
    "gui.sprite.addSpriteFromCamera": "摄像",
    "gui.stageSelector.stage": "舞台",
    "gui.stageSelector.backdrops": "背景",
    "gui.stage.addBackdropFromLibrary": "背景库",
    "gui.stageSelector.addBackdropFromPaint": "绘制",
    "gui.stageSelector.addBackdropFromSurprise": "随机",
    "gui.stageSelector.addBackdropFromFile": "上传背景",
    "gui.stage.addBackdropFromCamera": "相机",
    "gui.modal.help": "帮助",
    "gui.modal.save": "保存",
    "gui.modal.run": "运行",
    "gui.modal.paused": "暂停",
    "gui.modal.continue": "继续",
    "gui.modal.back": "返回",
    "gui.extension.kit": "套件",
    "gui.extension.kitTitle": "选择套件",
    "gui.extension.board": "主控板",
    "gui.extension.boardTitle": "选择主控板",
    "gui.extension.spreadBoard": "扩展板",
    "gui.extension.spreadBoardTitle": "选择扩展板",
    "gui.extension.sensor": "传感器",
    "gui.extension.sensorTitle": "选择传感器",
    "gui.extension.actuator": "执行器",
    "gui.extension.actuatorTitle": "选择执行器",
    "gui.extension.communicationModule": "通信模块",
    "gui.extension.communicationModuleTitle": "选择通信模块",
    "gui.extension.display": "显示器",
    "gui.extension.displayTitle": "选择显示器",
    "gui.extension.function": "功能模块",
    "gui.extension.functionTitle": "选择功能模块",
    "gui.extension.internetService": "网络服务",
    "gui.extension.internetServiceTitle": "选择网络服务",
    "gui.extension.thirdExpansion": "用户库",
    "gui.extension.thirdExpansionTitle": "选择用户库",
    "gui.extension.arduinContentLabel": "选择设备",
    "gui.extension.openUserExFile": "打开你计算机上的用户库文件",
    "gui.extension.importUserEx": "导入用户库",
    "gui.extension.unavailable": "不可用",
    "gui.extension.thirdModulesHelp": "注意：用户库中的模块由Mind+爱好者制作，{clickHelp}查看{tutorial}以及{list}",
    "gui.extension.thirdTutorial": "开发教程",
    "gui.extension.thirdList": "用户库列表",
    "gui.extension.thirdModuleSearch": "搜索或输入项目网址",
    "gui.extension.libaryOffice": "官方库",
    "gui.extension.libaryPinpong": "pinpong库",
    "gui.library.filterPlaceholder": "搜索",
    "gui.libraryTags.all": "所有",
    "gui.libraryTags.animals": "动物",
    "gui.libraryTags.dance": "舞蹈",
    "gui.libraryTags.effects": "效果",
    "gui.libraryTags.fantasy": "奇幻",
    "gui.libraryTags.fashion": "时尚",
    "gui.libraryTags.food": "食物",
    "gui.libraryTags.festival": "节日",
    "gui.libraryTags.traffic": "交通",
    "gui.libraryTags.indoors": "室内",
    "gui.libraryTags.loops": "可循环",
    "gui.libraryTags.music": "音乐",
    "gui.libraryTags.notes": "音符",
    "gui.libraryTags.outdoors": "户外",
    "gui.libraryTags.patterns": "图案",
    "gui.libraryTags.people": "人物",
    "gui.libraryTags.percussion": "打击乐器",
    "gui.libraryTags.space": "太空",
    "gui.libraryTags.sports": "运动",
    "gui.libraryTags.underwater": "水下",
    "gui.libraryTags.voice": "人声",
    "gui.libraryTags.wacky": "古怪",
    "gui.libraryTags.animation": "动画",
    "gui.libraryTags.art": "艺术",
    "gui.libraryTags.games": "游戏",
    "gui.libraryTags.stories": "故事",
    "gui.libraryTags.letters": "字母",
    "gui.libraryTags.mind": "Mind+",
    "gui.libraryTags.natural": "自然",
    "gui.libraryTags.button": "按钮",
    "gui.libraryTags.dialog": "对话框",
    "gui.libraryTags.epidemicPrevention": "防疫",
    "gui.libraryTags.numberAndLetter": "数字&字母",
    "gui.libraryTags.gameComponents": "游戏组件",
    "gui.extension.backdropLib": "选择背景",
    "gui.soundLibrary.chooseASound": "选择一个声音",
    "gui.SpriteInfo.spritePlaceholder": "名字",
    "gui.extension.spriteLib": "选择角色",
    "gui.gui.unselectedSerialport": "未连接设备",
    "gui.gui.unConnectedDev": "未连接设备",
    "gui.gui.pythonMode": "Python程序模式",
    "gui.gui.burnFirmware": "重新烧录固件",
    "gui.gui.burnFirmwareError": "烧录固件出错",
    "gui.gui.connected": "连接设备成功",
    "gui.gui.failedConnected": "连接设备失败",
    "gui.gui.connecting": "正在连接设备...",
    "gui.cards.all-how-tos": "全部教程",
    "gui.cards.how-tos": "入门教程",
    "gui.cards.remove": "退出教程",
    "gui.cards.expand": "展开",
    "gui.cards.shrink": "收起",
    "gui.cards.more-things-to-try": "再试试这些！",
    "gui.cards.see-more": "更多技巧",
    "gui.loader.message1": "正在创建积木……",
    "gui.loader.message2": "载入角色……",
    "gui.loader.message3": "载入声音……",
    "gui.loader.message4": "加载扩展……",
    "gui.loader.message5": "呼唤Mind+……",
    "gui.loader.message6": "传送Nano……",
    "gui.loader.message7": "给Gobo充气 …",
    "gui.loader.message8": "准备表情……",
    "gui.loader.headline": "载入作品",
    "gui.cameraModal.cameraModalTitle": "拍摄",
    "gui.cameraModal.loadingCameraMessage": "摄像头加载中……",
    "gui.cameraModal.permissionRequest": "需要你的同意才能使用摄像头",
    "gui.cameraModal.retakePhoto": "重新拍摄",
    "gui.cameraModal.save": "保存",
    "gui.cameraModal.takePhoto": "拍摄照片",
    "gui.cameraModal.loadingCaption": "载入中……",
    "gui.cameraModal.enableCameraCaption": "启用摄像头",
    "gui.recordModal.title": "录制声音",
    "gui.recordStep.recordByClickBtn": "点击下面的按钮开始录制声音",
    "gui.recordStep.permissionMicrophone": "我们需要您的许可才能使用您的麦克风",
    "gui.recordStep.record": "录制",
    "gui.recordStep.stopRecord": "停止录制",
    "gui.playbackStep.stopMsg": "停止",
    "gui.playbackStep.playMsg": "播放",
    "gui.playbackStep.loadingMsg": "载入中……",
    "gui.playbackStep.saveMsg": "保存",
    "gui.playbackStep.reRecordMsg": "重新录制",
    "gui.webglModal.label": "你的浏览器不支持WebGL",
    "gui.webglModal.descriptionMind": "很不幸你的电脑 {webGlLink}. Mind+需要运行WebGL, 请尝试 {restartMindplus}（{clickToRestart}），如果问题依旧{updateGpuDriver} 或 {gotoSettingGPU}",
    "gui.webglModal.restartMindplus": "重启Mind+",
    "gui.webglModal.clickToRestart": "点此重启",
    "gui.webglModal.webgllink": "不支持WebGL",
    "gui.webglModal.updateGpuDriver": "请更新显卡驱动",
    "gui.webglModal.gotoSettingGPU": "尝试开关设置中的GPU加速",
    "gui.webglModal.ok": "确定",
    "gui.extension.scratchExampleLib": "示例程序-实时执行",
    "gui.extension.pythonExampleLib": "示例程序-Python",
    "gui.extension.arduinoExampleLib": "示例程序-上传执行",
    "gui.prompt.cancel": "取消",
    "gui.prompt.ok": "确定",
    "gui.extension.makeBlock": "添加一个自定义模块",
    "gui.customProcedures.addAnInputNumberText": "添加输入项",
    "gui.customProcedures.addAnInputBoolean": "添加输入项",
    "gui.customProcedures.numberTextType": "数字或文本",
    "gui.customProcedures.textType": "文本值",
    "gui.customProcedures.numberType": "数字值",
    "gui.customProcedures.booleanType": "布尔值",
    "gui.customProcedures.addALabel": "添加文本标签：",
    "gui.customProcedures.runWithoutScreenRefresh": "运行时不刷新屏幕",
    "gui.customProcedures.cancel": "取消",
    "gui.customProcedures.ok": "完成",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "DFRobot出品的Vortex教育机器人",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "DFRobot出品的探索者D1教育机器人",
    "gui.extension.arduinounoR3.name": "arduino uno开源硬件套件",
    "gui.extension.arduinounoR3.description": "与武汉创客课程结合基于arduino uno主控板的套件。",
    "gui.extension.maxbot.name": "Max:bot小车",
    "gui.extension.maxbot.description": "基于micro:bit平台的机器人小车",
    "gui.extension.max.name": "MAX小车",
    "gui.extension.max.description": "基于Arduino平台机器人小车",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "micro:bit 电机舵机专用扩展板",
    "gui.extension.maqueen.name": "micro:Maqueen（麦昆）",
    "gui.extension.maqueen.description": "基于micro:bit平台的入门级迷你机器人小车",
    "gui.extension.spreadEsp32e.name": "firebeetle esp32-e扩展板",
    "gui.extension.spreadEsp32e.description": "firebeetle esp32-e扩展板",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.microbit.description": "把作品连接到实体世界。",
    "gui.extension.calliope.description": "把作品连接到实体世界。",
    "gui.extension.calliope.name": "CALLIOPE mini",
    "gui.extension.spread.name": "micro:bit&掌控扩展板",
    "gui.extension.spreadboson.name": "micro:bit&掌控扩展板",
    "gui.extension.microTouch.name": "键盘扩展板",
    "gui.extension.microNaturalScience.name": "自然主题扩展板",
    "gui.extension.microNaturalScienceV2.name": "自然主题扩展板V2.0",
    "gui.extension.microNaturalScienceV2.description": "集成丰富自然环境相关传感器的microbit学习产品。",
    "gui.extension.microNaturalScienceV2.request": "请求数据",
    "gui.extension.microNaturalScienceV2.readSoilValue": "[PIN] 引脚获取土壤湿度",
    "gui.extension.microNaturalScienceV2.readCO2TVOC": "[TYPE]",
    "gui.extension.microNaturalScienceV2.setBme280": "设置TVOC和CO2的基线[NUM]值",
    "gui.extension.microNaturalScienceV2.OLEDShowString": "OLED从 [NUM1] 到 [NUM2] 列在第 [NUM3] 行显示字符串 [STR]",
    "gui.extension.microNaturalScienceV2.OLEDShowNumber": "OLED从 [NUM1] 到 [NUM2] 列在第 [NUM3] 行显示数字 [STR]",
    "gui.extension.microNaturalScienceV2.OLEDClearXY": "清除OLED从 [NUM1] 到 [NUM2] 列在第 [NUM3] 行",
    "gui.extension.microNaturalScienceV2.OLEDClear": "清除OLED第 [NUM3] 行",
    "gui.extension.microNaturalScienceV2.MotorRun": "控制电机方向 [DIR] 速度[SPEED]",
    "gui.extension.microNaturalScienceV2.MotorStop": "控制停止",
    "gui.extension.microNaturalScienceV2.RGBSetBackground": "设置RGB亮度为 [NUM]",
    "gui.extension.microNaturalScienceV2.RGBShowColorFromWith": "RGB显示颜色 [COLOR]",
    "gui.extension.microNaturalScienceV2.RGBShowNumColorFromWith": "RGB灯[NUM]显示颜色 [COLOR]",
    "gui.extension.microNaturalScienceV2.RGBRange": "RGB范围从 [NUM1] 到 [NUM2]",
    "gui.extension.microNaturalScienceV2.RGBShowMulticolor": "设置RGB显示彩虹色从 [NUM1] 到 [NUM2]",
    "gui.extension.microNaturalScienceV2.RGBclear": "关闭全部RGB",
    "gui.extension.microNaturalScienceV2.IFTTTConnect": "IFTTT 配置 事件: [EVENT] 密码: [PASSWORD]",
    "gui.extension.microNaturalScienceV2.WIFIConnect": "Wi-Fi 配置 名称: [SSID] 密码: [PASSWORD] 启动连接",
    "gui.extension.microNaturalScienceV2.IFTTTSend": "IFTTT 发送 值1: [VAL1] 值2: [VAL2] 值3: [VAL3]",
    "gui.extension.microNaturalScienceV2.ThingSpeakConfigure": "ThingSpeak 配置 密钥: [SSID]",
    "gui.extension.microNaturalScienceV2.ThingSpeakSend": "ThingSpeak 发送 值1:[VALUE1]值2:[VALUE2]值3:[VALUE3]值4:[VALUE4]值5:[VALUE5]值6:[VALUE6]值7:[VALUE7]值8:[VALUE8]",
    "gui.extension.microNaturalScienceV2.ThingSpeakPrepareSend": "ThingSpeak 预发送 值[VALUE1] ： [VALUE2]",
    "gui.extension.microNaturalScienceV2.ThingSpeakRealSend": "ThingSpeak 发送",
    "gui.extension.microNaturalScienceV2.obloq_initialParameter": "MQTT 初始化 [PARAMETER]",
    "gui.extension.microNaturalScienceV2.sendmsgToCloudPlatform": "发送消息 [MSG] 到 [TOPIC]",
    "gui.extension.microNaturalScienceV2.addToCloudPlatform": "新增订阅 [TOPIC] : [MSG]",
    "gui.extension.microTouch.description": "集成4×4键盘和电机舵机驱动的扩展板",
    "gui.extension.microNaturalScience.description": "集成多种环境传感器的micro:bit扩展板",
    "gui.extension.microIoT.name": "物联网主题扩展板",
    "gui.extension.microIoT.description": "集成显示屏和物联网功能的micro:bit扩展板",
    "gui.extension.microIoTCloud.name": "物联网主题扩展板",
    "gui.extension.microIoTCloud.description": "支持插WiFi卡联网的micro:bit扩展板，为物联网课堂教学提供了出色的解决方案",
    "gui.extension.spreadmaqueen.name": "麦昆扩展板",
    "gui.extension.spread.description": "使用micro:bit和掌控板搭配的I/O扩展板（带电机驱动）",
    "gui.extension.spreadboson.description": "使用micro:bit和掌控板搭配的I/O扩展板（带电机驱动）",
    "gui.extension.spreadmaqueen.description": "使用掌控板搭配的入门级迷你机器人小车",
    "gui.extension.maqueenPlus.name": "麦昆Plus",
    "gui.extension.maqueenPlus.description": "支持micro:bit和掌控两种主板的进阶款教育机器人",
    "gui.extension.maqueenPlusV2.name": "麦昆Plus V2",
    "gui.extension.maqueenPlusV2.description": "支持micro:bit的新版麦昆plus款教育机器人",
    "gui.extension.pinpongBread.name": "PinPong扩展板",
    "gui.extension.pinpongBread.description": "在ArduinoUNO上进行Python学习的扩展板",
    "gui.extension.iot.name": "物联网主题扩展板",
    "gui.extension.iot.description": "基于物联网的扩展版",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Leonardo主控板控制的设备",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Uno主控板控制的设备",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Arduino Nano主控板控制的设备",
    "gui.extension.mpython.name": "掌控板",
    "gui.extension.mpython.description": "基于ESP32的主控板",
    "gui.extension.telloesp32.name": "RoboMaster TT(ESP32)",
    "gui.extension.telloesp32.description": "搭载大疆顶尖飞控算法的教育无人机，极强的安全性与稳定性",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Mega 2560主控板控制的设备",
    "gui.extension.firebeetleesp32.name": "FireBeetle ESP32",
    "gui.extension.firebeetleesp32e.name": "FireBeetle ESP32-E",
    "gui.extension.firebeetleesp32.description": "FireBeetle ESP32 主控板控制的设备",
    "gui.extension.dfr0299.name": "DFPlayer MP3模块",
    "gui.extension.dfr0299.description": "播放MP3、WAV格式音频",
    "gui.extension.ser0006.name": "舵机模块",
    "gui.extension.ser0006.description": "可以在0～180度间转动到指定角度位置",
    "gui.extension.dfr0523.name": "蠕动泵",
    "gui.extension.dfr0523.description": "对弹性泵管交替挤压、释放来输送流体",
    "gui.extension.dfr0017.name": "继电器",
    "gui.extension.dfr0017.description": "用于控制大电流设备",
    "gui.extension.steppermotor.name": "步进电机",
    "gui.extension.steppermotor.description": "应用于3D打印机、移动机器人、中型移动平台等",
    "gui.extension.dfr0534.name": "串口MP3模块",
    "gui.extension.dfr0534.description": "通过串口指令播放音乐，支持MP3、WAV、WMA",
    "gui.extension.servo360.name": "360度微型舵机",
    "gui.extension.servo360.description": "可控制旋转方向、速度，不能控制角度",
    "gui.extension.tel0118.name": "OBLOQ物联网模块",
    "gui.extension.tel0118.description": "通过Wi-Fi接收和发送物联网信息",
    "gui.extension.dfr0095.name": "红外发射模块",
    "gui.extension.dfr0095.description": "可发射标准38KHz调制的红外信号",
    "gui.extension.dfr0094.name": "红外接收模块",
    "gui.extension.dfr0094.description": "可接收标准38KHz调制的红外信号",
    "gui.extension.midi.name": "MIDI",
    "gui.extension.midi.description": "MIDI播放器",
    "gui.extension.radio.name": "无线广播",
    "gui.extension.radio.description": "多个主板之间进行无线广播通讯",
    "gui.extension.tel0094.name": "GPS信号接收模块",
    "gui.extension.tel0094.description": "GPS信号接收模块",
    "gui.extension.tel0026.name": "蓝牙模块",
    "gui.extension.tel0026.description": "蓝牙串口模块",
    "gui.extension.dfr0486.name": "OLED-12864显示屏",
    "gui.extension.dfr0647.name": "OLED-12832显示屏",
    "gui.extension.dfr0486.description": "小体积显示屏，可用于智能穿戴设备的显示器",
    "gui.extension.dfr0647.description": "小体积显示屏，可用于智能穿戴设备的显示器",
    "gui.extension.fit0352.name": "WS2812 RGB灯",
    "gui.extension.fit0352.description": "控制LED灯条变化产生如闪烁、流水、跳变等效果",
    "gui.extension.dfr0063.name": "LCD1602模块（I2C接口）",
    "gui.extension.dfr0063.description": "液晶显示器，可显示2行，每行16个字符",
    "gui.extension.dfr0021.name": "数字LED发光模块",
    "gui.extension.dfr0021.description": "高电平灯亮，低电平灯灭",
    "gui.extension.tm1650.name": "TM1650四位数码管",
    "gui.extension.tm1650.description": "显示模块，I2C接口，可用于计时器、仪表控制等",
    "gui.extension.dfr0646.name": "TM1650八位数码管",
    "gui.extension.dfr0646.description": "显示模块，I2C接口，可用于计时器、仪表控制等",
    "gui.extension.matrix8_8.name": "MAX7219 8x8点阵",
    "gui.extension.matrix8_8.description": "迷你点阵显示模块",
    "gui.extension.dfr0522.name": "8×16 RGB LED点阵表情板",
    "gui.extension.dfr0522.description": "可用做机器人表情面板、彩色频谱仪等",
    "gui.extension.music.name": "音乐",
    "gui.extension.music.description": "演奏乐器，敲锣打鼓。",
    "gui.extension.pen.name": "画笔",
    "gui.extension.pen.description": "绘制角色。",
    "gui.extension.video-sensing.name": "视频侦测",
    "gui.extension.video-sensing.description": "用相机检测动作.",
    "gui.extension.google-translate.name": "谷歌翻译",
    "gui.extension.google-translate.description": "将文字翻译成多种语言.",
    "gui.extension.bd-translate.name": "百度翻译",
    "gui.extension.bd-translate.description": "使用百度翻译服务将文字翻译成其他语言。",
    "gui.extension.text-speech.name": "文字朗读",
    "gui.extension.text-speech.description": "让你的项目开口说话",
    "gui.extension.softSerialport.name": "软串口",
    "gui.extension.softSerialport.description": "使用I/O口模拟串口通讯功能",
    "gui.extension.IICScan.name": "I2C地址扫描",
    "gui.extension.IICScan.description": "扫描当前所有接到I2C接口上设备地址",
    "gui.extension.bluetooth.name": "蓝牙",
    "gui.extension.bluetooth.description": "蓝牙设备",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "搭配GoBle（走你）APP使用，可实现手机APP控制主控板",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "将数据保存到eeprom",
    "gui.extension.interrupt.name": "引脚中断",
    "gui.extension.interrupt.description": "引脚中断",
    "gui.extension.mpyfile.name": "板载文件",
    "gui.extension.mpyfile.description": "读写板载文件，可以存储数据和导出",
    "gui.extension.sdcard.name": "SD读卡器模块",
    "gui.extension.sdcard.description": "MicroSD卡 读卡器模块",
    "gui.extension.multithread.name": "多线程",
    "gui.extension.multithread.description": "允许多个程序与主程序同时运行",
    "gui.extension.speech.name": "Speech",
    "gui.extension.speech.description": "与你的项目交谈.",
    "gui.extension.sen0001.name": "超声波测距传感器",
    "gui.extension.bos0001.name": "旋钮模块",
    "gui.extension.bos0002.name": "按钮模块",
    "gui.extension.bos0003.name": "自锁开关",
    "gui.extension.bos0004.name": "环境光传感器",
    "gui.extension.bos0006.name": "水分传感器",
    "gui.extension.bos0007.name": "火焰传感器",
    "gui.extension.bos0008.name": "触摸传感器",
    "gui.extension.bos0009.name": "声音传感器",
    "gui.extension.bos0010.name": "智能灰度传感器",
    "gui.extension.bos0012.name": "电导开关",
    "gui.extension.bos0013.name": "运动传感器",
    "gui.extension.bos0038.name": "土壤湿度传感器",
    "gui.extension.bos0045.name": "超声波距离传感器",
    "gui.extension.bos0016.name": "高亮LED模块",
    "gui.extension.bos0017.name": "LED模块",
    "gui.extension.bos0019.name": "彩色灯带",
    "gui.extension.bos0021.name": "风扇模块",
    "gui.extension.bos0022.name": "录音模块",
    "gui.extension.bos0001.description": "模拟传感器，可检测旋转位置",
    "gui.extension.bos0002.description": "按下输出高电平，松开输出低电平",
    "gui.extension.bos0003.description": "按下状态输出高电平，弹起状态输出低电平",
    "gui.extension.bos0004.description": "检测环境光强度",
    "gui.extension.bos0006.description": "检测雨水、雾水，不可整体浸入水中",
    "gui.extension.bos0007.description": "探测火源或波长760~1100nm的光源",
    "gui.extension.bos0008.description": "触摸开关，可感应人体，金属等",
    "gui.extension.bos0009.description": "检测声音强度",
    "gui.extension.bos0010.description": "检测不同颜色产生的信号",
    "gui.extension.bos0012.description": "检测物体是否导体",
    "gui.extension.bos0013.description": "检测运动的人或动物身上发出的红外线",
    "gui.extension.bos0038.description": "检测土壤水分，水分越少输出值越小",
    "gui.extension.bos0045.description": "模拟距离传感器，检测范围2cm~1m",
    "gui.extension.bos0016.description": "白色发光模块",
    "gui.extension.bos0017.description": "发光二极管，能发出对应灯珠颜色的灯光",
    "gui.extension.bos0019.description": "七彩灯带，灯带长3米，颜色不能改变",
    "gui.extension.bos0021.description": "驱动扇叶旋转的模块",
    "gui.extension.bos0022.description": "可以录制一段10s的声音和播放录音",
    "gui.extension.sen0001.description": "准确测距，量程2～800cm，兼容urm及SR04超声波",
    "gui.extension.dfr0023.name": "LM35线性模拟温度传感器",
    "gui.extension.dfr0023.description": "测量温度，范围0～100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22温湿度传感器",
    "gui.extension.dhtTHSensor.description": "测量环境温度、湿度",
    "gui.extension.dsTSensor.name": "DS18B20温度传感器",
    "gui.extension.dsTSensor.description": "测量温度，范围－55～＋125℃",
    "gui.extension.sen0203.name": "心率传感器",
    "gui.extension.sen0203.description": "测量人体静态心率",
    "gui.extension.sen0177.name": "PM2.5激光粉尘传感器",
    "gui.extension.sen0177.description": "测量空气中颗粒物浓度，如PM2.5",
    "gui.extension.sen0014.name": "红外测距传感器",
    "gui.extension.sen0014.description": "探测距离，范围10～80cm",
    "gui.extension.sen0204.name": "液位传感器(非接触式)",
    "gui.extension.sen0204.description": "非接触检测密闭容器的液位高度",
    "gui.extension.sen0160.name": "Hx711重量传感器",
    "gui.extension.sen0160.description": "测量重量（小于1kg）",
    "gui.extension.sen0161.name": "模拟pH计",
    "gui.extension.sen0161.description": "测量溶液的pH值（酸碱度）,供电5V",
    "gui.extension.sen0161-v2.name": "模拟pH计(V2)",
    "gui.extension.sen0161-v2.description": "测量溶液的pH值（酸碱度）,供电3.3～5.5V",
    "gui.extension.sen0244.name": "模拟TDS传感器",
    "gui.extension.sen0244.description": "测量溶液的TDS（溶解性固体总量），反应水洁净度",
    "gui.extension.sen0165.name": "模拟ORP计",
    "gui.extension.sen0165.description": "测量溶液的ORP（氧化还原电位），评估水质",
    "gui.extension.sen0237.name": "模拟DO计",
    "gui.extension.sen0237.description": "测量水中的氧含量，评估水质",
    "gui.extension.dfr0300-H.name": "模拟EC计",
    "gui.extension.dfr0300-H.description": "测量溶液电导率，评估水质",
    "gui.extension.dfr0300.name": "模拟EC计(V2)",
    "gui.extension.dfr0300.description": "测量溶液电导率，评估水质",
    "gui.extension.sen0170.name": "风速传感器",
    "gui.extension.sen0170.description": "测量风速",
    "gui.extension.sen0226.name": "BMP280温度传感器",
    "gui.extension.sen0226.description": "检测温度、大气压",
    "gui.extension.sen0228.name": "环境光传感器",
    "gui.extension.sen0228.description": "精确测量环境光强度",
    "gui.extension.sen0236.name": "BME280模块",
    "gui.extension.sen0236.description": "环境传感器 (温度，湿度，气压)",
    "gui.extension.sen0517.name": "ICP10111气压温度传感器",
    "gui.extension.sen0517.description": "精度±1Pa，高精度高稳定性气压传感器",
    "gui.extension.dfr0022.name": "模拟灰度传感器",
    "gui.extension.dfr0022.description": "检测不同的颜色，可用于巡线",
    "gui.extension.motucamera.name": "视觉识别摄像头",
    "gui.extension.motucamera.description": "支持各种智能图像识别的摄像头模块",
    "gui.extension.dfr0552.name": "12位数模DA转换模块",
    "gui.extension.dfr0552.description": "将数字量准确地转换成对应的直流电压信号（非PWM）",
    "gui.extension.dfr0699.name": "音频录放EDU",
    "gui.extension.dfr0699.description": "单片机控制录放音、可以储存 10 段 100s 时长音频",
    "gui.extension.dfr0760.name": "语音合成模块",
    "gui.extension.dfr0760.description": "Gravity接口的语音合成模块，无需联网即可合成语音",
    "gui.extension.dfr0715.name": "语音识别模块",
    "gui.extension.dfr0715.description": "Gravity接口的语音识别模块，无需联网",
    "gui.extension.sen0117.name": "语音合成模块",
    "gui.extension.sen0117.description": "你只要输入相应的中英文字符和数字，它就会读出这些文字",
    "gui.extension.dfr0576.name": "I2C级联扩展器",
    "gui.extension.dfr0576.description": "用于解决I2C器件地址的冲突",
    "gui.extension.sen0248.name": "BME680环境传感器",
    "gui.extension.sen0248.description": "可测量VOC（挥发性有机物）、温度、湿度、气压这四个参数",
    "gui.extension.sen0304.name": "I2C超声波测距传感器",
    "gui.extension.sen0304.description": "专为快速测距或避障应用设计的超声波传感器",
    "gui.extension.sen0307.name": "模拟超声波测距传感器",
    "gui.extension.sen0307.description": "开放式双探头模拟超声波测距传感器",
    "gui.extension.sen0250.name": "BMI160 6轴惯性运动传感器",
    "gui.extension.sen0250.description": "16位3轴加速度+超低功耗3轴陀螺仪",
    "gui.extension.sen0224.name": "I2C LIS2DH 三轴加速度计",
    "gui.extension.sen0224.description": "超低功耗3轴加速度传感器",
    "gui.extension.dfr0151.name": "实时时钟DS1307",
    "gui.extension.dfr0151.description": "输出, 年、月、日、星期、时、分、秒",
    "gui.extension.dfr0469.name": "实时时钟SD2405",
    "gui.extension.dfr0469.description": "存取年、月、日、星期、时、分、秒",
    "gui.extension.dfr0126.name": "频谱分析模块",
    "gui.extension.dfr0126.description": "分析声音的7个频率",
    "gui.extension.dfr0231.name": "RFID/NFC模块",
    "gui.extension.dfr0231.description": "用于近距离无线通讯，可以实现读卡写卡",
    "gui.extension.sen0245.name": "VL53L0X激光测距传感器",
    "gui.extension.sen0245.description": "使用TOF技术精准测量距离，最高测距2米",
    "gui.extension.dfr0026.name": "模拟环境光线传感器",
    "gui.extension.dfr0026.description": "测量环境光强度",
    "gui.extension.dfr0027.name": "数字震动传感器",
    "gui.extension.dfr0027.description": "检测振动信号，输出数字信号",
    "gui.extension.dfr0028.name": "数字钢球倾角传感器",
    "gui.extension.dfr0028.description": "可作为数字开关，不能测量倾斜角度",
    "gui.extension.dfr0029.name": "数字大按钮模块",
    "gui.extension.dfr0029.description": "按下输出高电平，松开输出低电平，有抖动",
    "gui.extension.dfr0030.name": "数字触摸传感器",
    "gui.extension.dfr0030.description": "触摸开关，可感应人体、金属等",
    "gui.extension.sen0318.name": "CCS811空气质量传感器",
    "gui.extension.sen0318.description": "可测CO2、TVOC, 适用于空气测量应用",
    "gui.extension.sen0315.name": "PAJ7620U2手势识别传感器",
    "gui.extension.sen0315.description": "IIC通信，在最远20cm范围内，可以识别的手势多达13种",
    "gui.extension.sen0497.name": "DHT20温湿度传感器",
    "gui.extension.sen0497.description": "I2C通信的新一代温湿度传感器，是DHT11的升级产品",
    "gui.extension.sen0514.name": "ENS160空气质量传感器",
    "gui.extension.sen0514.description": "测量TVOC（总挥发性有机化合物）、eCO2（相对二氧化碳）、AQI（空气质量指数）",
    "gui.extension.sen0518.name": "MAX30102心率血氧传感器",
    "gui.extension.sen0518.description": "测量人体静态心率、血氧饱和度",
    "gui.extension.dfr0991.name": "I2C-RGB灯按钮模块",
    "gui.extension.dfr0991.description": "读取按钮的状态，控制RGB灯的颜色和亮灭",
    "gui.extension.sen0376.name": "酒精传感器",
    "gui.extension.sen0376.description": "出厂标定，无需校准，接线方便，0-5ppm",
    "gui.extension.sen0321.name": "臭氧传感器",
    "gui.extension.sen0321.description": "测量环境中臭氧的浓度",
    "gui.extension.sen0364.name": "可见光谱传感器",
    "gui.extension.sen0364.description": "测量可见光光谱，识别光谱颜色",
    "gui.extension.sen0359.name": "指纹传感器",
    "gui.extension.sen0359.description": "一款方便易用的指纹模块",
    "gui.extension.kit0176.name": "I2C重量传感器",
    "gui.extension.kit0176.description": "可测量物体重量，量程1Kg内",
    "gui.extension.bos0063.name": "ADC转I2C模块",
    "gui.extension.bos0063.description": "采集模拟数据转换为I2C信号输出",
    "gui.extension.dfr0033.name": "数字贴片磁感应传感器",
    "gui.extension.dfr0033.description": "探测磁性材料（磁铁），探测范围约3cm",
    "gui.extension.dfr0034.name": "模拟声音传感器",
    "gui.extension.dfr0034.description": "测量环境中声音强度",
    "gui.extension.sen0132.name": "模拟一氧化碳气体传感器",
    "gui.extension.sen0132.description": "测量一氧化碳气体浓度，范围20～2000ppm",
    "gui.extension.dfr0051.name": "模拟电压检测模块",
    "gui.extension.dfr0051.description": "测量25V以下的直流（DC）电压",
    "gui.extension.dfr0052.name": "模拟压电陶瓷震动传感器",
    "gui.extension.dfr0052.description": "检测振动信号，输出模拟信号",
    "gui.extension.dfr0058.name": "模拟多圈旋转角度传感器",
    "gui.extension.dfr0058.description": "调节电压，基于高精度电位器",
    "gui.extension.dfr0061.name": "JoyStick摇杆模块",
    "gui.extension.dfr0061.description": "可制作遥控器，2轴模拟输出，1路数字输出",
    "gui.extension.dfr0072.name": "Shiftout数码管",
    "gui.extension.dfr0072.description": "使用SPI通信可以级联的数码管模块",
    "gui.extension.dfr0563.name": "锂电池电量计",
    "gui.extension.dfr0563.description": "测量锂电池的当前电压和已用电量",
    "gui.extension.dfr0553.name": "16位AD转换模块",
    "gui.extension.dfr0553.description": "16位AD转换模块可对模拟量信号进行精确的采集与转换",
    "gui.extension.dfr0588.name": "温湿度传感器SHT30",
    "gui.extension.dfr0588.description": "温湿度传感器SHT30",
    "gui.extension.dfr0076.name": "火焰传感器",
    "gui.extension.dfr0076.description": "探测火源或波长在760～1100纳米的光源",
    "gui.extension.dfr0117.name": "I2C EEPROM数据存储模块",
    "gui.extension.dfr0117.description": "容量32kb",
    "gui.extension.dfr0143.name": "三轴加速度传感器",
    "gui.extension.dfr0143.description": "检测物体的姿态、运动方向",
    "gui.extension.sen0018.name": "人体红外热释电运动传感器",
    "gui.extension.sen0018.description": "检测运动的人、动物发出的热红外线",
    "gui.extension.sen0114.name": "土壤湿度传感器",
    "gui.extension.sen0114.description": "检测土壤水分，缺水时传感器输出值减小",
    "gui.extension.sen0121.name": "水分传感器",
    "gui.extension.sen0121.description": "检测雨水、雾水、水蒸气",
    "gui.extension.sen0212.name": "颜色识别传感器",
    "gui.extension.sen0212.description": "识别物体表面颜色，输出为RGB值",
    "gui.extension.sen0253.name": "10DOF姿态传感器",
    "gui.extension.sen0253.description": "BNO055+BMP280智能10DOF绝对定向传感器",
    "gui.extension.sen0290.name": "闪电传感器",
    "gui.extension.sen0290.description": "闪电传感器",
    "gui.extension.sen0291.name": "I2C数字功率计",
    "gui.extension.huskylens.name": "HUSKYLENS AI摄像头",
    "gui.extension.huskylens.description": "人工智能视觉传感器，支持人脸识别和学习功能",
    "gui.extension.ps2.name": "PS2 手柄",
    "gui.extension.ps2.description": "PS2通用手柄接收模块",
    "gui.extension.sen0291.description": "高精度测量电压、电流、功率",
    "gui.extension.sen0202.name": "3D手势识别迷你传感器",
    "gui.extension.sen0202.description": "识别手指运动方向以及手指转动的顺、逆时针",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "micro:bit专用锂电池电机扩展板",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "让设备连接Wi-Fi网络",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "让设备获取网络时间",
    "gui.extension.udp.name": "UDP广播",
    "gui.extension.udp.description": "让局域网内的设备与设备通过UDP协议实现多机广播通信",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "让设备通过HTTP请求信息",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "让设备可以使用MQTT协议进行通讯",
    "gui.extension.internet.name": "网络",
    "gui.extension.internet.description": "网络通信",
    "gui.extension.speechRecognition.name": "语音识别",
    "gui.extension.speechRecognition.description": "语音识别",
    "gui.extension.pictureai.defaultTitleText": "视频窗口",
    "gui.extension.pictureai.cameraPermissionsTitle": "需要相机权限",
    "gui.extension.pictureai.cameraPermissionsMessage": "请前往设置中勾选授权。如已授权但仍无法使用，请确认摄像头已正常连接无其它软件占用，或重启软件。",
    "gui.extension.pictureai.cameraPermissionsCancel": "取消",
    "gui.extension.pictureai.cameraPermissionsGoToSettings": "前往设置",
    "gui.gui.loaded": "已加载",
    "gui.gui.notLoaded": "未加载",
    "gui.gui.cantFindYouWant": "找不到你想要的？数量很少？",
    "gui.gui.clickHere": "点击这里",
    "gui.gui.viewHelp": "查看帮助",
    "gui.gui.uploadToDev": "上传到设备",
    "gui.gui.codeArea": "代码区",
    "gui.gui.fileSys": "文件系统",
    "gui.gui.compileAndUpload": "编译后上传",
    "gui.gui.compileOnly": "编译",
    "gui.gui.compileOpen": "编译后打开",
    "gui.gui.burnBootloader": "烧录无线引导程序",
    "gui.gui.arduinoRun": "运行",
    "gui.gui.arduinoSave": "保存",
    "gui.gui.autoGenerated": "自动生成",
    "gui.gui.manualEditing": "手动编辑",
    "gui.gui.codeBox": "个代码框",
    "gui.gui.moduleCode": "模块代码",
    "gui.menu.edit.undo": "撤销",
    "gui.menu.edit.redo": "恢复",
    "gui.menu.edit.selectAll": "全选",
    "gui.menu.edit.cut": "剪切",
    "gui.menu.edit.copy": "复制",
    "gui.menu.edit.paste": "粘贴",
    "gui.menu.edit.codeStyle": "代码风格",
    "gui.menu.edit.fontSize": "字体大小",
    "gui.menu.edit.clearCache": "清除缓存",
    "gui.menu.edit.maxReset": "Max恢复出厂设置",
    "gui.gui.serial": "串口",
    "gui.gui.openSerial": "打开串口",
    "gui.gui.closeSerial": "关闭串口",
    "gui.gui.close": "关闭",
    "gui.gui.open": "打开",
    "gui.gui.clearOutput": "清除输出",
    "gui.gui.scroll": "滚屏",
    "gui.gui.send": "发送",
    "gui.gui.baud": "波特率",
    "gui.gui.noLineEnd": "没有结束符",
    "gui.gui.newLine": "换行符",
    "gui.gui.carriageReturn": "回车",
    "gui.gui.bothNLandCR": "回车加换行",
    "gui.gui.unavailableHexData": "不是有效的HEX格式，请以两个字符(0-9/A-F)表示一个HEX数据，以空格分隔，如: \"00 01 A2 FF\"。",
    "gui.gui.hexForm": "16进制",
    "gui.gui.openHexForm": "采用16进制格式显示和发送数据",
    "gui.gui.closeHexForm": "关闭",
    "gui.progress.burning": "正在上传",
    "gui.progress.compiling": "正在编译",
    "gui.burner.compileError": "编译失败",
    "gui.burner.linkError": "链接失败",
    "gui.burner.generateHexError": "生成.hex失败",
    "gui.burner.generateBinError": "生成.bin失败",
    "gui.burner.burnError": "烧录失败",
    "gui.burner.eraseError": "擦除失败",
    "gui.burner.findLeonardoPortError": "无法找到Leonardo的bootlaoder串口",
    "gui.burner.noMicrobit": "当前连接的设备不是micro: bit, 请连接micro: bit",
    "gui.burner.swdCfgError": "写入cmsis-dap.cfg失败",
    "gui.burner.UDiskPopUp": "U盘设备弹出, 请等待几秒再试一下",
    "gui.burner.UDiskWriteError": "U盘设备写入出错",
    "gui.burner.openPortError": "打开串口失败: 串口已经被占用",
    "gui.burner.firmwareError": "固件错误, 请初始化设备后重新连接",
    "gui.burner.uploadDone": "上传完毕",
    "gui.burner.connectPort": "连接串口",
    "gui.burner.writeLibFiles": "正在写入库文件",
    "gui.burner.burnFirmware": "烧录固件",
    "gui.burner.connectError": "连接设备失败，请重新插拔设备再次下载",
    "gui.burner.connectPico": "Pico固件下载成功，请重新选择串口连接",
    "gui.burner.printObjectTooBig": "项目太大了；请点击右上角“帮助”按钮减小文件大小。\n为开发板[%BOARD]编译时出错。 ",
    "gui.burner.printFlashTooBig": "没有足够的内存；请点击右上角“帮助”按钮减小内存的用量。\n为开发板[%BOARD]编译时出错。 ",
    "gui.burner.printFlashTooLow": "可用内存偏低，可能出现稳定性问题。 ",
    "gui.burner.printSizeError": "无法确定程序大小。 ",
    "gui.burner.printSize": "项目使用了[%TEXTSIZE]字节，占用了([%TEXTSIZEPRE])程序存储空间，余留[%TEXTSIZESUR]字节，最大为[%TEXTSIZEMAX]字节。\n全局变量使用了[%DATASIZE]字节，([%DATASIZEPRE])的动态内存，余留[%DATASIZESUR]字节局部变量，最大为[%DATASIZEMAX]字节。 ",
    "gui.progress.compileSuccess": "编译成功",
    "gui.progress.compileProgress": "编译进度",
    "gui.progress.uploadProgress": "上传进度",
    "gui.progress.uploadSuccess": "上传成功",
    "gui.progress.uploadSuccessVm": "上传成功",
    "gui.progress.networkInterfaceCard": "网卡",
    "gui.codeLabel.dynamicVariables": "动态变量",
    "gui.codeLabel.functionDeclaration": "函数声明",
    "gui.codeLabel.staticConstants": "静态常量",
    "gui.codeLabel.createObject": "创建对象",
    "gui.codeLabel.mainProgramStart": "主程序开始",
    "gui.codeLabel.SubthreadProgramStarts": "子线程%1程序开始",
    "gui.codeLabel.customFunction": "自定义函数",
    "gui.codeLabel.eventCallbackFunction": "事件回调函数",
    "gui.codeLabel.globalCode": "全局变量",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "不允许拖入变量！",
    "gui.progress.errMsg": "上传出错，详细信息请打开「代码区」小黑窗查看",
    "gui.progress.errMsg1": "上传出错，详细信息请打开「代码区」小黑窗查看",
    "gui.progress.errMsg2": "上传出错，详细信息请打开「代码区」小黑窗查看",
    "gui.howtos.spin-around.name": "旋转",
    "gui.howtos.spin.step_dragTurn": "拖拽出一个 “向右旋转” 积木块",
    "gui.howtos.spin.step_clickTurn": "点击积木块运行",
    "gui.howtos.spin.step_clickControl": "点击左侧的 “控制” 模块",
    "gui.howtos.spin.step_dragForever": "拖拽出一个 “循环执行” 积木块",
    "gui.howtos.spin.step_clickForever": "点击积木块运行",
    "gui.howtos.spin.step_changeColor": "添加一个 “将颜色特效增加” 积木块",
    "gui.howtos.say-hello.name": "说 hello",
    "gui.howtos.say-hello.step_addSprite": "添加一个精灵",
    "gui.howtos.say-hello.step_clickLooks": "点击左侧 “外观” 模块",
    "gui.howtos.say-hello.step_dragSay": "拖拽出一个 “说” 积木块",
    "gui.howtos.say-hello.step_clickSay": "点击积木块运行",
    "gui.howtos.say-hello.step_anotherSay": "拖拽出另一个 “说” 积木块",
    "gui.howtos.say-hello.step_editSay": "编辑积木块",
    "gui.howtos.say-hello.step_clickStack": "点击积木块运行",
    "gui.howtos.run-away.name": "逃跑",
    "gui.howtos.run-away.step_dragGoTo": "拖拽出一个 “移到随机位置” 积木块",
    "gui.howtos.run-away.step_clickGoTo": "点击积木块运行",
    "gui.howtos.run-away.step3": "点击左侧 “事件” 模块",
    "gui.howtos.run-away.step_addWhenClicked": "添加一个 “当角色被点击” 积木块",
    "gui.howtos.run-away.step_clickSprite": "点击角色运行",
    "gui.howtos.run-away.step_addSound": "添加一个 “开始播放声音” 积木块",
    "gui.howtos.pick-up-apple.name": "双人接苹果",
    "gui.howtos.pick-up-apple.step_1": "切换到解释执行界面",
    "gui.howtos.pick-up-apple.step_2": "切换产品",
    "gui.howtos.pick-up-apple.step_3": "连接设备",
    "gui.howtos.pick-up-apple.step_4": "校准指南针",
    "gui.howtos.pick-up-apple.step_5": "打开例程",
    "gui.howtos.pick-up-apple.step_6": "双人接苹果",
    "gui.howtos.move-left-right.name": "micro:bit遥控小猫",
    "gui.howtos.move-left-right.step_1": "新建项目",
    "gui.howtos.move-left-right.step_2": "拖动积木块1",
    "gui.howtos.move-left-right.step_3": "拖动积木块2",
    "gui.howtos.move-left-right.step_4": "micro:bit遥控小猫",
    "gui.howtos.install-driver.name": "安装驱动",
    "gui.howtos.transform-expression.name": "按键显示表情",
    "gui.howtos.transform-expression.step_1": "切换到上传执行界面",
    "gui.howtos.transform-expression.step_2": "切换产品",
    "gui.howtos.transform-expression.step_3": "拖动积木块1",
    "gui.howtos.transform-expression.step_4": "拖动积木块2",
    "gui.howtos.transform-expression.step_5": "拖动积木块3",
    "gui.howtos.transform-expression.step_6": "按键显示表情",
    "gui.howtos.lot.name": "物联网(IOT)",
    "gui.howtos.lot.step_1": "选择例程",
    "gui.howtos.lot.step_2": "烧录程序",
    "gui.howtos.lot.step_3": "物联网(IOT)",
    "gui.howtos.touch-pin.name": "引脚触摸",
    "gui.howtos.touch-pin.step_1": "切换到上传执行界面",
    "gui.howtos.touch-pin.step_2": "选择例程",
    "gui.howtos.touch-pin.step_3": "烧录程序",
    "gui.howtos.touch-pin.step_4": "引脚触摸",
    "gui.howtos.save-witch.name": "拯救女巫",
    "gui.howtos.save-witch.step_1": "切换到“实时执行”",
    "gui.howtos.save-witch.step_2": "扩展中选择“micro:bit”主控板",
    "gui.howtos.save-witch.step_3": "选择“示例程序”中的“拯救女巫”",
    "gui.howtos.save-witch.step_4": "连接设备，选择microbit，等待连接成功",
    "gui.howtos.save-witch.step_5": "如果提示“校准设备”则旋转主控板一圈至LED灯全灭",
    "gui.howtos.save-witch.step_6": "点击绿旗，挥动手臂即可与动画互动啦",
    "gui.howtos.microbit-touch.name": "microbit触摸切换表情",
    "gui.howtos.microbit-touch.step_1": "切换到“上传执行”界面",
    "gui.howtos.microbit-touch.step_2": "打开“学习”菜单下的“示例程序”，选择“引脚触摸”",
    "gui.howtos.microbit-touch.step_3": "连接设备对应的COM口，点击“上传到设备”",
    "gui.howtos.microbit-touch.step_4": "按下不同按键即可显示不同表情",
    "gui.howtos.microbit-calibration.name": "micro:bit校准",
    "gui.howtos.calliope-calibration.name": "calliope校准",
    "gui.howtos.microbit-calibration.step_1": "将micro:bit板子垂直于地面进行旋转，板载LED灯会随着旋转依次点亮",
    "gui.howtos.microbit-calibration.step_2": "直到最外侧LED灯全部点亮（如下图），之后会显示一个笑脸即为校准完成",
    "gui.extension.sen0251.name": "BMP388气压温度传感器",
    "gui.extension.sen0251.description": "具有温度、大气压、海拔检测功能",
    "gui.extension.sen0206.name": "非接触式红外温度传感器",
    "gui.extension.sen0206.description": "远程红外非接触式测量物体或环境温度",
    "gui.gui.recentlyEdited": "最近编辑",
    "gui.extension.weather.name": "获取天气",
    "gui.extension.weather.description": "获取天气信息，需与Wi-Fi模块一起使用",
    "gui.extension.weather.description.javascript": "通过网络获取天气信息，电脑需要联网",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "操作TinyWebDB网络数据库，可配合APP Inventor使用",
    "gui.extension.pictureai.name": "AI图像识别",
    "gui.extension.pictureai.description": "使用图像AI，可以识别生活中有趣的东西，需要连接网络使用",
    "gui.extension.iflytek.description": "IFLYTEK",
    "gui.gui.variableScopeOptionAllSprites": "适用于所有角色",
    "gui.gui.variableScopeOptionSpriteOnly": "仅适用于当前角色",
    "gui.gui.variablePromptAllSpritesMessage": "所有角色都可使用该变量。",
    "gui.monitor.contextMenu.default": "正常显示",
    "gui.monitor.contextMenu.large": "大字显示",
    "gui.monitor.contextMenu.slider": "滑杆",
    "gui.monitor.contextMenu.sliderRange": "改变滑块范围",
    "gui.monitor.contextMenu.import": "导入",
    "gui.monitor.contextMenu.export": "导出",
    "gui.monitor.listMonitor.listLength": "长度{length}",
    "gui.monitor.listMonitor.empty": "(空)",
    "gui.costumeTab.backdrop": "背景",
    "gui.costumeTab.costume": "造型",
    "gui.opcodeLabels.direction": "方向",
    "gui.opcodeLabels.xposition": "x 坐标",
    "gui.opcodeLabels.yposition": "y 坐标",
    "gui.opcodeLabels.size": "大小",
    "gui.opcodeLabels.costumename": "造型名称",
    "gui.opcodeLabels.costumenumber": "造型编号",
    "gui.opcodeLabels.backdropname": "背景名称",
    "gui.opcodeLabels.backdropnumber": "背景编号",
    "gui.opcodeLabels.volume": "音量",
    "gui.opcodeLabels.tempo": "演奏速度",
    "gui.opcodeLabels.answer": "回答",
    "gui.opcodeLabels.loudness": "响度",
    "gui.opcodeLabels.year": "年",
    "gui.opcodeLabels.month": "月",
    "gui.opcodeLabels.date": "日",
    "gui.opcodeLabels.dayofweek": "星期",
    "gui.opcodeLabels.hour": "时",
    "gui.opcodeLabels.minute": "分",
    "gui.opcodeLabels.second": "秒",
    "gui.opcodeLabels.timer": "计时器",
    "gui.loadProject.error": "加载项目失败",
    "gui.fileSystem.fileCatalogue": "文件目录",
    "gui.fileSystem.boardFiles": "掌控板中的文件",
    "gui.fileSystem.boardFilesPico": "Pico中的文件",
    "gui.fileSystem.boardFiles2": "RMTT中的文件",
    "gui.fileSystem.boardFilesMaixduino": "Maixduino中的文件",
    "gui.fileSystem.boardFilesBoson": "BOSON MC1",
    "gui.fileSystem.computerFiles": "用户空间",
    "gui.fileSystem.userExFiles": "用户库中的文件",
    "gui.fileSystem.newFile": "新建文件",
    "gui.fileSystem.newFolder": "新建文件夹",
    "gui.fileSystem.deleteFolder": "删除文件夹",
    "gui.fileSystem.deleteFile": "删除",
    "gui.fileSystem.rename": "重命名",
    "gui.fileSystem.openFolder": "打开文件夹位置",
    "gui.fileSystem.openFilePos": "打开文件位置",
    "gui.fileSystem.openInEditor": "在编辑区打开",
    "gui.fileSystem.runRightNow": "立即运行",
    "gui.fileSystem.stopRun": "停止运行",
    "gui.fileSystem.setBoot": "设为开机运行",
    "gui.fileSystem.deleteForever": "永久删除",
    "gui.fileSystem.saveAs": "另存为",
    "gui.fileSystem.undoAllEdits": "取消所有修改",
    "gui.fileSystem.copyToComputer": "复制到电脑",
    "gui.fileSystem.uploadToBoard": "上传到主控板",
    "gui.fileSystem.uploadAndRun": "上传到主控板立即运行",
    "gui.wifi.pwdNotLegal": "！错误提示：密码格式错误，Wi-Fi密码必须是8-20位的字母或数字",
    "gui.spreadmaqueen.perror": "！错误提示：掌控麦昆扩展版P12引脚led灯不可使用，请选择P8引脚led灯。",
    "gui.huskylens.perror": "！错误提示：huskylens只支持microbit V2",
    "gui.infrared.perror": "！错误提示：红外发送只支持microbit V2",
    "gui.gui.viewConflict": "点击查看冲突",
    "gui.gui.clickViewHelp": "如何解决？",
    "gui.gui.conflict": "冲突",
    "gui.gui.conflictPrompt": "冲突警告提示",
    "gui.gui.clickPrompt": "单击高亮积木块，双击定位积木块",
    "gui.extension.mpyUART.name": "串口UART",
    "gui.extension.mpyUART.description": "使用硬串口进行通信",
    "gui.extension.mpyI2C.name": "I2C通信",
    "gui.extension.mpyI2C.description": "使用软件模拟I2C协议传输数据",
    "gui.extension.mpyServo.name": "舵机模块",
    "gui.extension.mpyServo.description": "可以在0～180度间转动到指定角度位置",
    "gui.extension.mpyTimer.name": "定时器",
    "gui.extension.mpyTimer.description": "可以定时执行程序",
    "gui.extension.mpyList.name": "列表",
    "gui.extension.mpyList.description": "列表",
    "gui.extension.mpyDictionary.name": "字典",
    "gui.extension.mpyDictionary.description": "字典",
    "gui.extension.mpySet.name": "集合",
    "gui.extension.mpySet.description": "集合",
    "gui.extension.mpyTuple.name": "元组",
    "gui.extension.mpyTuple.description": "元组",
    "gui.connection.auto-scanning.noPeripheralsFound": "没有发现任何设备",
    "gui.connection.auto-scanning.prescan": "把设备放在边上，然后开始搜索",
    "gui.connection.auto-scanning.pressbutton": "按下设备上的按钮",
    "gui.connection.auto-scanning.start-search": "开始搜索",
    "gui.connection.connecting-searchbutton": "正在搜索……",
    "gui.connection.auto-scanning.try-again": "重试",
    "gui.connection.connected": "已连接",
    "gui.connection.disconnect": "断开连接",
    "gui.connection.go-to-editor": "返回编辑器",
    "gui.connection.connecting-cancelbutton": "正在连接……",
    "gui.connection.error.errorMessage": "呀，好像出错了。",
    "gui.connection.error.tryagainbutton": "重试",
    "gui.connection.error.helpbutton": "帮助",
    "gui.connection.peripheral-name-label": "设备名称",
    "gui.connection.connect": "连接",
    "gui.connection.scanning.lookingforperipherals": "正在查找设备",
    "gui.connection.scanning.noPeripheralsFound": "没有发现任何设备",
    "gui.connection.scanning.instructions": "选择上面列出的设备。",
    "gui.connection.scanning.instructionsForTello": "请先将电脑WiFi连接Tello或RMTT，再选择上面列出的设备。",
    "gui.connection.connect.instructionsForTello": "请先在电脑WiFi列表中连接Tello或RMTT，再选择上面列出的设备。",
    "gui.connection.scanning.connectTelloHandle": "选择上面列出的设备，或手动连接飞机Wi-Fi。",
    "gui.connection.search": "刷新",
    "gui.connection.unavailable.installscratchlink": "确保Scratch Link已安装并正在运行",
    "gui.connection.unavailable.enablebluetooth": "请检查蓝牙已经开启",
    "gui.connection.unavailable.tryagainbutton": "重试",
    "gui.connection.unavailable.helpbutton": "帮助",
    "gui.extension.ev3.description": "搭建交互机器人等。",
    "gui.extension.ev3.connectingMessage": "正在连接。请确保EV3上的识别码(PIN)已经设置为1234。",
    "gui.extension.boost.description": "生动有趣的机器人创作",
    "gui.extension.boost.connectingMessage": "正在连接",
    "gui.extension.wedo2.description": "支持马达和传感器。",
    "gui.extension.wedo2.connectingMessage": "正在连接",
    "gui.extension.huskyLensPro.name": "HUSKYLENS教育版",
    "gui.extension.huskyLensPro.description": "使用HUSKYLENS直连电脑，学习AI人工智能基础知识",
    "gui.extension.huskyLensPro.connectingMessage": "HUSKYLENS正在努力请求连接中，请稍候",
    "gui.extension.machinelearning.name": "机器学习 (ML5)",
    "gui.extension.machinelearning.description": "基于机器学习算法，可以脱离网络实现人工智能应用",
    "gui.extension.huskylens.pcsetting": "配置电脑端摄像头",
    "gui.extension.huskylens.visualmodal": "AI可视化工具",
    "gui.extension.huskylens.KNNText": "电脑端摄像头模拟KNN分类",
    "gui.extension.huskylens.huskylensbasic": "基础功能",
    "gui.extension.huskylens.algorithmOperationLabel": "算法操作",
    "gui.extension.huskylens.displayFunctionLabel": "显示功能",
    "gui.extension.huskylens.cameraFunctionLabel": "拍照功能",
    "gui.extension.huskylens.modelOperationLabel": "模型操作",
    "gui.huskyLensPro.toConnect": "请先连接HUSKYLENS教育版",
    "gui.huskyLensPro.toClassificationData": "请先添加分类数据",
    "gui.huskyLensPro.startDeployment": "开始部署",
    "gui.huskyLensPro.switchingAlgorithmFailed": "无法切换到“物体分类”算法！",
    "gui.huskyLensPro.forgettingFailure": "无法遗忘学习数据！",
    "gui.huskyLensPro.failedToSetCategoryLabel": "设置“分类标签”失败！",
    "gui.huskyLensPro.requestFailed": "请求“部署”失败！",
    "gui.huskyLensPro.deploymentFailed": "“部署数据”失败！",
    "gui.huskyLensPro.completed": "完成",
    "gui.huskyLensPro.countdown": "，预计[TIME]后完成",
    "gui.huskyLensPro.minutes": "分",
    "gui.huskyLensPro.seconds": "秒",
    "gui.huskyLensPro.deploying": "正在部署模型",
    "gui.huskyLensPro.disconnected": "连接已断开，终止部署，请检查USB连线！",
    "gui.huskyLensPro.huskylenMaker": "此功能只有教育版可用",
    "gui.huskyLensPro.updateVersion": "请连接教育版且固件版本不低于0.5.1，详情见\"帮助\"",
    "gui.huskyLensPro.failedRetry": "失败，请重试！",
    "gui.connection.auto-scanning.notFoundHuskylensTip": "连接超时，请确认本机已连接到HuskyLens",
    "gui.extension.tello.name": "RoboMaster TT(单机)",
    "gui.extension.tello.description": "搭载大疆顶尖飞控算法的教育无人机，极强的安全性与稳定性",
    "gui.extension.tello.connectingMessage": "正在连接...请确认已打开Tello",
    "gui.extension.telloGroup.name": "RoboMaster TT(组队)",
    "gui.extension.telloGroup.description": "搭载大疆顶尖飞控算法的教育无人机，极强的安全性与稳定性",
    "gui.connection.auto-scanning.notFoundTelloTip": "扫描超时，请确认已打开Tello",
    "gui.extension.AIVisualInterface.lable": "AI可视化工具",
    "gui.extension.AIVisualInterface.title": "暂无数据",
    "gui.extension.AIVisualInterface.imgCount": "[COUNT]张图片",
    "gui.extension.AIVisualInterface.delete": "删除",
    "gui.extension.AIVisualInterface.classificationCount": "共计[COUNT]个分类",
    "gui.extension.AIVisualInterface.recognitionResult": "画面识别结果为:",
    "gui.extension.AIVisualInterface.possibility": "可能性为:",
    "gui.extension.AIVisualInterface.addClass": "添加分类",
    "gui.extension.AIVisualInterface.startTraining": "开始训练",
    "gui.extension.AIVisualInterface.prediction": "预测视频图片",
    "gui.extension.AIVisualInterface.retrain": "重新训练",
    "gui.extension.AIVisualInterface.export": "导出模型",
    "gui.dialog.baiduServer.togglePersonalAccount": "当前人声的可用次数已到达上限，请切换至个人账户使用，并登录控制台检查额度",
    "gui.extension.ai.name": "人工智能",
    "gui.extension.AI.description": "支持模型调用与训练",
    "gui.extension.machineVision.name": "机器视觉",
    "gui.extension.machineVision.description": "图像处理、绘制、滤波、识别形状、颜色、维码、特征",
    "gui.extension.machineHearing.description": "通过训练实现语音识别",
    "gui.extension.maixduino.name": "Maixduino",
    "gui.extension.maixduino.description": "基于K210芯片的主控板",
    "gui.extension.boson.name": "Boson MC1",
    "gui.extension.boson.description": "Boson MC1主控板控制的设备",
    "gui.extension.esp8266.name": "FireBeetle ESP8266",
    "gui.extension.esp8266.description": "FireBeetle ESP8266主控板控制的设备",
    "gui.extension.pico.name": "Raspberry Pi Pico",
    "gui.extension.pico.description": "Raspberry Pi Pico主控板控制的设备",
    "gui.extension.pyTurtle.name": "海龟绘图",
    "gui.extension.pyTurtle.description": "脑洞万千，尺幅之间",
    "gui.extension.pyGenerator.name": "代码生成器",
    "gui.extension.pyGenerator.description": "直接在图像积木上书写代码实现图形化与代码混编",
    "gui.extension.unihiker.name": "行空板",
    "gui.extension.unihiker.description": "可以运行完整python的新一代学习板",
    "gui.extension.python.save": "保存",
    "gui.extension.python.execute": "运行",
    "gui.extension.python.pipmanger": "库管理",
    "gui.extension.python.filesystem": "文件系统",
    "gui.extension.python.fileinprogram": "项目中的文件",
    "gui.extension.python.fileinpc": "电脑中的文件",
    "gui.extension.python.terminal": "终端",
    "gui.extension.python.clearterminal": "清除输出",
    "gui.extension.python.tabcode": "代码",
    "gui.extension.python.piplist": "库列表",
    "gui.extension.python.commendatory": "推荐库",
    "gui.extension.python.handleinstall": "PIP模式",
    "gui.extension.python.selectall": "选择所有[COUNT]",
    "gui.extension.python.update": "更新",
    "gui.extension.python.uninstall": "卸载",
    "gui.extension.python.install": "安装",
    "gui.extension.python.installing": "安装中..",
    "gui.extension.python.installed": "已安装",
    "gui.extension.python.piphandleinstall": "PIP手动安装",
    "gui.extension.python.loadmore": "加载更多...",
    "gui.extension.python.hidemore": "隐藏更多...",
    "gui.extension.python.noavailable": "暂无可用库，快先去下载吧",
    "gui.extension.python.enterCommand": "请先输入库名称",
    "gui.extension.python.finishedtips": "命令运行完成",
    "gui.extension.python.stop": "停止",
    "gui.extension.python.executing": "运行中",
    "gui.extension.python.editorArea": "代码区",
    "gui.extension.python.toolbox": "图形区",
    "gui.extension.python.autoCreate": "自动生成",
    "gui.extension.python.emptyEditor": "点击右侧<b>项目中的文件</b>后面的<b>新建文件按钮</b>，创建一个属于你的.py文件",
    "gui.extension.python.userDocument": "帮助文档",
    "gui.extension.xiamiboard.name": "虾米扩展板",
    "gui.extension.xiamiboard.description": "基于microbit开发的多功能编程教育扩展板",
    "gui.gui.saveSb3ToCloud": "保存到云端",
    "gui.extension.pyMqtt.name": "MQTT-py",
    "gui.extension.pyMqtt.description": "使用电脑的网络，通过MQTT协议进行通讯",
    "gui.extension.pyGame.name": "Pygame",
    "gui.extension.pyGame.description": "用Python编写自己的游戏和动画",
    "gui.extension.pinpongBase.name": "pinpong初始化",
    "gui.extension.pinpongBase.description": "pinpong库初始化选择主控板功能和gpio",
    "gui.extension.pinpongServo.name": "舵机",
    "gui.extension.pinpongServo.description": "可以在0 ~ 180度间转动到指定角",
    "gui.extension.pinpongIrq.name": "引脚中断",
    "gui.extension.pinpongIrq.description": "pinpong库引脚中断功能",
    "gui.extension.pinpongTone.name": "蜂鸣器",
    "gui.extension.pinpongTone.description": "pinpong库蜂鸣器模块",
    "gui.extension.pinpongSr04_urm10.name": "超声波测距传感器",
    "gui.extension.pinpongSr04_urm10.description": "准确测距，量程2～800cm，兼容urm及SR04超声波",
    "gui.extension.pinpongDht.name": "DHT11/22温湿度传感器",
    "gui.extension.pinpongDht.description": "测量环境温度、湿度",
    "gui.extension.pinpongNeopixel.name": "WS2812 RGB灯",
    "gui.extension.pinpongNeopixel.description": "控制LED灯条变化产生如闪烁、流水、跳变等效果",
    "gui.extension.pinpongOled12864.name": "OLED显示屏",
    "gui.extension.pinpongOled12864.description": "小体积显示屏，可用于智能穿戴设备的显示器",
    "gui.extension.pinpongDS18B20.name": "DS18B20温度传感器",
    "gui.extension.pinpongDS18B20.description": "测量温度，范围－55～＋125℃",
    "gui.extension.pinpongMlx90614.name": "非接触式红外温度传感器",
    "gui.extension.pinpongMlx90614.description": "远程红外非接触式测量物体或环境温度",
    "gui.extension.pinpongBmp388.name": "BMP388气压温度传感器",
    "gui.extension.pinpongBmp388.description": "具有温度、大气压、海拔检测功能",
    "gui.extension.pinpongIRRecv.name": "红外接收模块",
    "gui.extension.pinpongIRRecv.description": "可接收标准38KHz调制的红外信号",
    "gui.extension.PinpongBoard.description": "pinpong board扩展板，xxxxxxx",
    "gui.extension.pythonsen0334.name": "SHT31-F数字温湿度传感器",
    "gui.extension.pythonsen0334.description": "可测量温湿度，低功耗、响应速度快、抗干扰能力强",
    "gui.extension.pythonsen0303.name": "VEML6075 紫外线传感器",
    "gui.extension.pythonsen0303.description": "能够检测出UVA和UVB强度",
    "gui.extension.pythonsen0377.name": "MEMS 气体传感器",
    "gui.extension.pythonsen0377.description": "支持CO、C2H5OH(Alcohol)H2、NO2、NH3等多种气体浓度的检测",
    "gui.extension.pythonsen0460.name": "空气质量传感器",
    "gui.extension.pythonsen0460.description": "Gravity接口的空气质量传感器，可测量PM2.5值",
    "gui.extension.obloqMp.name": "OBLOQ物联网模块",
    "gui.extension.obloqMp.description": "通过Wi-Fi接收和发送物联网信息",
    "gui.extension.pyAutoGui.name": "PyAutoGui",
    "gui.extension.pyAutoGui.description": "PyAutoGUI允许Python脚本控制鼠标和键盘，以自动化与其他应用程序的交互",
    "gui.extension.pyRequests.name": "Requests",
    "gui.extension.pyRequests.description": "让 HTTP 服务人类",
    "gui.extension.pyJson.name": "JSON",
    "gui.extension.pyJson.description": "JSON 编码和解码器",
    "gui.extension.pySchedule.name": "Schedule",
    "gui.extension.pySchedule.description": "面向人类的 Python 作业调度",
    "gui.extension.pyXlrd.name": "xlrd",
    "gui.extension.pyXlrd.description": "execl读取, 只支持xls文件",
    "gui.extension.pyXlwt.name": "xlwt",
    "gui.extension.pyXlwt.description": "execl写入, 只支持xls文件",
    "gui.extension.pyOpenCV.name": "OpenCV",
    "gui.extension.pyOpenCV.description": "OpenCV是一个包含数百种计算机视觉算法的开源库",
    "gui.extension.pyBaiDuAi.name": "百度人脸识别",
    "gui.extension.pyBaiDuAi.description": "使用百度AI提供的接口实现人脸识别",
    "gui.extension.pyBaiDuImage.name": "百度图像识别",
    "gui.extension.pyBaiDuImage.description": "使用百度AI提供的接口实现图像识别",
    "gui.extension.pyBaiDuSpeech.name": "百度语音",
    "gui.extension.pyBaiDuSpeech.description": "百度语音提供语音识别、语音合成等多种智能服务",
    "gui.extension.pyXfSpeech.name": "讯飞语音",
    "gui.extension.pyXfSpeech.description": "讯飞语音提供语音合成和语音识别功能",
    "gui.account.dialog.prompt": "提示",
    "gui.account.dialog.tips": "提示",
    "gui.account.dialog.signoutAlert": "退出后将无法使用云存储等功能，确定退出吗？",
    "gui.account.dialog.signoutSuccess": "已退出登录",
    "gui.account.dialog.sure": "确定",
    "gui.account.dialog.cancel": "取消",
    "gui.account.dialog.error": "错误",
    "gui.account.dialog.save": "保存",
    "gui.account.dialog.getCloudFileFailed": "获取云文件「[NAME]」出错，请检查文件是否存在",
    "gui.account.dialog.getCloudError": "获取云文件「[NAME]」出错",
    "gui.account.dialog.loadCloudSuccess": "加载云文件「[NAME]」成功",
    "gui.account.dialog.saveCloudError": "保存「[NAME]」到云端失败，请稍后尝试。",
    "gui.account.dialog.saveCloudSuccess": "保存「[NAME]」到云端成功",
    "gui.account.dialog.saveCloudCheckError": "保存失败，校验云端文件出错，请稍后再试",
    "gui.account.dialog.saveCloudRepeatPrompt": "云端已存在同名文件「[NAME]」，是否覆盖？",
    "gui.account.dialog.saveCloudRepeatAlert": "云端已存在同名文件「[NAME]」，请换个名字",
    "gui.account.dialog.saveCover": "覆盖",
    "gui.account.dialog.rename": "重命名",
    "gui.account.dialog.newProject": "新建文件",
    "gui.account.dialog.projectName": "项目名称",
    "gui.account.dialog.enterName": "请输入文件名",
    "gui.account.dialog.nameWithSymbol": "文件名不能包含特殊字符, 请更改",
    "gui.account.dialog.nameBeyondLength": "文件名长度不能超过30",
    "gui.account.dialog.renameSuccess": "重命名文件「[NAME1]」为「[NAME2]」成功",
    "gui.account.dialog.renameFailed": "重命名文件失败",
    "gui.account.dialog.downloadSuccess": "「[NAME]」下载完成",
    "gui.account.dialog.cancelDownload": "取消下载",
    "gui.account.dialog.deleteSucess": "删除云文件「[NAME]」成功",
    "gui.account.dialog.saveAs": "另存为",
    "gui.account.dialog.deleteFailed": "删除云文件「[NAME]」出错",
    "gui.account.dialog.deletePrompt": "确认删除云文件「[NAME]」吗？",
    "gui.account.open": "打开",
    "gui.account.upload": "上传",
    "gui.account.delete": "删除",
    "gui.account.usersSpace": "[NAME]的云端项目",
    "gui.account.cloudDataLoading": "云端数据加载中，请稍候",
    "gui.account.emptyUserSpace": "这里是空的，快去上传你的第一个项目吧。",
    "gui.account.dialog.login": "登录",
    "gui.account.dialog.signup": "注册",
    "gui.account.dialog.phoneNumber": "手机号",
    "gui.account.dialog.password": "密码",
    "gui.account.dialog.autoLogin": "记住密码，自动登录",
    "gui.account.dialog.forgotPasswd": "忘记密码?",
    "gui.account.dialog.getCheckCode": "获取验证码",
    "gui.account.dialog.reSend": "重新发送",
    "gui.account.dialog.checkCode": "验证码",
    "gui.account.dialog.readAndAgree": "我已阅读并接受",
    "gui.account.dialog.userAgreement": "用户协议",
    "gui.account.dialog.loginSuccess": "登录成功",
    "gui.account.dialog.passwordError": "账号或密码错误，请检查填写是否正确",
    "gui.account.dialog.checkNumberSended": "验证码已发送，请注意查收",
    "gui.account.dialog.checkedAgree": "同意协议",
    "gui.account.dialog.networkError": "网络错误，请检查连接后重试",
    "gui.account.dialog.readProtocolAndCheck": "请先阅读用户协议并勾选同意",
    "gui.account.dialog.signupError": "注册出错，",
    "gui.account.dialog.logininErrorWithNetwork": "服务器连接出错，登录失败，请检查网络",
    "gui.account.dialog.signupErrorDefault": "注册失败，请重新操作",
    "gui.account.dialog.accountExist": "账号已存在，请直接登录",
    "gui.account.dialog.phoneCodeError": "注册失败，验证码错误",
    "gui.account.dialog.signupSuccess": "注册成功，请返回登录界面继续操作",
    "gui.account.dialog.inputEmpty": "不能为空",
    "gui.account.dialog.phoneFormatIllegal": "用户名格式错误",
    "gui.account.dialog.unsupportProject": "打开项目出错，不受支持的项目",
    "gui.account.dialog.shouldLoginFirst": "云服务需要你的账号信息，请先登录",
    "gui.account.dialog.loginTimeout": "登录超时",
    "gui.account.account": "账号",
    "gui.account.serverAddr": "服务器地址",
    "gui.account.thirdPartyAccount": "第三方账号",
    "gui.account.dialog.saveCloudRenamePrompt": "文件名[NAME]已存在，是否重命名?",
    "gui.python.remoteSSH.connectSuccess": "已成功连接到[ADDRESS]",
    "gui.python.remoteSSH.connectFailed": "连接[ADDRESS]失败",
    "gui.python.remoteSSH.shouleReconnect": "已与[ADDRESS]断开连接, 是否重新连接？",
    "gui.python.remoteSSH.reconnect": "重新连接",
    "gui.python.remoteSSH.disconnected": "已与[ADDRESS]断开连接",
    "gui.python.remoteSSH.accountCheckFailed": "账号信息错误，与[ADDRESS]连接失败，请检查后输入",
    "gui.python.remoteSSH.connectError": "与[ADDRESS]连接出错，[MSG]，解决办法请查看帮助文档",
    "gui.python.remoteSSH.sshLogin": "SSH登录",
    "gui.python.remoteSSH.address": "地址",
    "gui.python.remoteSSH.addressCantEmpty": "地址不能为空",
    "gui.python.remoteSSH.user": "用户名",
    "gui.python.remoteSSH.userCantEmpty": "用户名不能为空",
    "gui.python.remoteSSH.passwd": "密码",
    "gui.python.remoteSSH.passwdCantEmpty": "密码不能为空",
    "gui.python.remoteSSH.areYouSureDisconnect": "当前已连接到远程[ADDRESS]，确认断开吗？",
    "gui.python.remoteSSH.statusConnecting": "连接中",
    "gui.python.remoteSSH.disconnectDevice": "断开远程终端",
    "gui.python.remoteSSH.connectDevice": "连接远程终端",
    "gui.python.remoteSSH.statusError": "连接出错",
    "gui.python.remoteSSH.statusDisconnect": "连接已断开",
    "gui.python.remoteSSH.handleInput": "手动输入",
    "gui.python.remoteSSH.openNetNetWork": "打开网络中心",
    "gui.python.remoteSSH.goHelp": "帮助文档",
    "gui.python.remoteSSH.waitForConnecting": "正在连接到[ADDRESS], 请稍候...",
    "gui.python.remoteSSH.cancelConnect": "取消连接",
    "gui.python.remoteSSH.connectTimeout": "连接超时",
    "gui.python.remoteSSH.addressIsLost": "地址不存在",
    "gui.python.remoteSSH.connectRefused": "请求被拒绝，请检查远程系统设置后重试",
    "gui.python.remoteSSH.runGUIProgramTips": "运行图形化程序时，如果想在远程桌面查看效果，需使用远程系统当前登录的用户名",
    "gui.python.remoteSSH.executeFileError": "运行项目出错",
    "gui.python.remoteSSH.waitForProjectUpload": "项目[NAME]正在上传中，请稍后...",
    "gui.python.remoteSSH.projectUploadSuccess": "项目上传成功，开始运行",
    "gui.python.remoteSSH.projectUploadError": "项目上传出错",
    "gui.python.filetree.boardNameInFT": "[BOARD]中的文件",
    "gui.python.filetree.replaceFilePrompt": "目标文件夹中已经存在名称为[NAME]的文件或文件夹。是否要替换它？",
    "gui.python.filetree.fileUploading": "上传[NAME]文件...",
    "gui.python.filetree.fileSaving": "保存[NAME]文件...",
    "gui.python.filetree.dirSaving": "保存[NAME]文件夹...",
    "gui.python.filetree.saveAs": "另存为",
    "gui.python.filetree.chooseDir": "选择文件夹",
    "gui.python.filetree.getDirList": "获取目录...",
    "gui.python.filetree.getDirListWithPath": "读取[PATH]目录",
    "gui.python.filetree.getRemoteDirList": "读取远程[PATH]目录",
    "gui.python.filetree.renameFile": "重命名[FROM]为[TO]",
    "gui.python.filetree.deleteDirPrompt": "确定删除文件夹[NAME]及其包含的文件吗？",
    "gui.python.filetree.deleteFilePrompt": "确定删除文件[NAME]吗？",
    "gui.python.filetree.downloadToPC": "下载文件到电脑",
    "gui.python.filetree.uploadToRemote": "上传文件到",
    "gui.python.filetree.exportFileTo": "导出[EXT]文件",
    "gui.python.filetree.rmFromeFileSystem": "从文件系统中移除",
    "gui.python.filetree.rmDirPrompt": "确定移除本地路径[NAME]及其子文件吗？",
    "gui.python.filetree.rmAllDirPrompt": "确定移除所有本地路径吗？",
    "gui.python.filetree.addLocalDir": "添加本地文件夹",
    "gui.python.filetree.removeActionDirWithAll": "移除所有本地文件夹",
    "gui.python.filetree.fileNameIsRequired": "必须提供文件或文件夹名",
    "gui.python.filetree.fileNameIsRepeat": "此位置已经存在文件或文件夹[NAME]。请选择其他名称。",
    "gui.python.filetree.dragParentNodeToChild": "父文件夹无法移动到子文件夹",
    "gui.python.filetree.dragPathIsConsistent": "文件拖动路径前后一致",
    "gui.python.filetree.fileOperateError": "文件操作出错",
    "gui.python.filetree.fileTreeMore": "更多",
    "gui.python.filetree.copy": "复制",
    "gui.python.filetree.paste": "粘贴",
    "gui.python.worker.initPythonHomeDir": "正在初始化文件夹...",
    "gui.python.worker.copyPythonSource": "初始化文件夹完成，正在复制Python资源文件...",
    "gui.python.worker.copyPythonSourceError": "文件操作出错，初始化Python环境失败，请重新进入Python模式或重启软件",
    "gui.python.worker.unzipPythonSource": "复制资源文件完成，正在解压...",
    "gui.python.worker.checkPythonSource": "解压完成，正在验证文件...",
    "gui.python.worker.unzipPythonSourceError": "解压出错，初始化Python环境失败，请重新进入Python模式或重启软件",
    "gui.python.xterm.local": "本地",
    "gui.python.xterm.detectPythonEnv": "正在检测环境，请稍后....",
    "gui.python.xterm.installingPythonEnv": "\r\n正在安装环境，请稍后....",
    "gui.python.xterm.installPythonEnvError": "Python环境初始化失败，请联系技术支持",
    "gui.python.xterm.detectPythonEnvFinished": "\r\n环境检测完成，启动中...\r\n",
    "gui.python.xterm.installPythonEnvFinished": "\r\n环境安装完成，启动中...\r\n",
    "gui.python.xterm.toRestartXterm": "双击重启终端",
    "gui.python.xterm.showRemoteConnect": "开启远程连接终端",
    "gui.python.xterm.remoteConnectShowed": "远程连接终端已打开",
    "gui.dialog.thirdExt.openPipManger": "打开库管理",
    "gui.python.editor.undo": "撤销",
    "gui.python.editor.redo": "重做",
    "gui.python.editor.search": "搜索",
    "gui.python.editor.boldFont": "加粗字体",
    "gui.python.editor.zoomInt": "加大字体",
    "gui.python.editor.zoomOut": "缩小字体",
    "gui.python.editor.toTop": "返回顶部",
    "music.categoryName": "音乐",
    "translate.categoryName": "翻译",
    "sensor.categoryName": "传感器",
    "actuator.categoryName": "执行器",
    "communicate.categoryName": "通信模块",
    "display.categoryName": "显示器",
    "extendFunction.categoryName": "功能模块",
    "internetService.categoryName": "网络服务",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "calliope.categoryName": "CALLIOPE",
    "esp32.categoryName": "掌控",
    "telloesp32.categoryName": "RMTT",
    "spread.categoryName": "扩展板",
    "speechRecognition.categoryName": "语音识别",
    "thirdExpansion.categoryName": "用户库",
    "gui.blocklyText.blockTitle.eventBlock": "事件程序",
    "gui.blocklyText.blockTitle.dotScreen": "点阵屏",
    "gui.blocklyText.blockTitle.music": "音乐",
    "gui.blocklyText.blockTitle.onBoardSensor": "板载传感器",
    "gui.blocklyText.blockTitle.onBoardFunc": "板载功能",
    "gui.blocklyText.blockTitle.pinOperation": "引脚操作",
    "gui.blocklyText.blockTitle.wirelessCommunication": "无线通信",
    "gui.blocklyText.blockTitle.serialportOperation": "串口操作",
    "gui.blocklyText.blockTitle.systemResource": "系统资源",
    "gui.blocklyText.blockTitle.screenDisplay": "屏幕显示",
    "gui.blocklyText.blockTitle.ledControl": "LED控制",
    "gui.blocklyText.blockTitle.functionalModule": "功能模块",
    "gui.blocklyText.blockTitle.movement": "运动",
    "gui.blocklyText.blockTitle.expression": "表情",
    "gui.blocklyText.blockTitle.light": "灯光",
    "gui.blocklyText.blockTitle.dance": "舞蹈",
    "gui.blocklyText.blockTitle.sensor": "传感器",
    "gui.blocklyText.blockTitle.motorControl": "电机",
    "gui.blocklyText.blockTitle.kitSensor": "套件传感器",
    "gui.blocklyText.blockTitle.kitActuator": "套件执行器",
    "gui.blocklyText.blockTitle.commonModule": "常用模块",
    "gui.blocklyText.blockTitle.infraredCommunication": "红外通信",
    "gui.blocklyText.blockTitle.mp3Module": "mp3模块",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 RGB灯",
    "gui.blocklyText.blockTitle.LCDDisplay": "LCD显示屏",
    "gui.blocklyText.blockTitle.digitalTube": "数码管",
    "gui.blocklyText.blockTitle.oledDisplay": "OLED显示屏",
    "gui.blocklyText.blockTitle.onBoardSource": "板载资源",
    "gui.blocklyText.blockTitle.rgbControl": "RGB灯控制",
    "gui.blocklyText.blockTitle.maxbotResource": "max:bot资源",
    "gui.blocklyText.blockTitle.lineInspection": "巡线",
    "gui.blocklyText.blockTitle.outerSensor": "外置传感器",
    "gui.blocklyText.blockTitle.consolePrint": "控制台输出",
    "gui.blocklyText.blockTitle.radio": "无线广播",
    "gui.blocklyText.blockTitle.wifi": "wifi",
    "gui.blocklyText.blockTitle.motor": "电机控制",
    "gui.blocklyText.blockTitle.rgblightcontrol": "板载led",
    "gui.blocklyText.blockTitle.vibrationMotor": "振动马达",
    "gui.blocklyText.romeo.d1ProgramStart": "D1机器人程序开始",
    "gui.blocklyText.romeo.readdigital": "读取 [PIN] 引脚 [MODULE] 的值",
    "gui.blocklyText.romeo.buttonModule": "按钮模块",
    "gui.blocklyText.romeo.digitalSensor": "数字传感器",
    "gui.blocklyText.romeo.collisionSensor": "碰撞传感器",
    "gui.blocklyText.romeo.lineSensor": "巡线传感器",
    "gui.blocklyText.romeo.infraredSwitch": "红外开关",
    "gui.blocklyText.romeo.vibrationSensor": "震动传感器",
    "gui.blocklyText.romeo.readAnalog": "读 [PIN] 引脚 [SENSOR] 的值",
    "gui.blocklyText.romeo.lightSensor": "光线传感器",
    "gui.blocklyText.romeo.anologSensor": "模拟传感器",
    "gui.blocklyText.romeo.soundSensor": "声音传感器",
    "gui.blocklyText.romeo.soilMoistureSensor": "土壤湿度传感器",
    "gui.blocklyText.romeo.frameSensor": "火焰传感器",
    "gui.blocklyText.romeo.open": "打开",
    "gui.blocklyText.romeo.close": "关闭",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [PIN] 引脚的 [MODULE]",
    "gui.blocklyText.romeo.ledLights": "LED灯",
    "gui.blocklyText.romeo.digitalActuator": "数字执行器",
    "gui.blocklyText.romeo.relay": "继电器",
    "gui.blocklyText.romeo.fan": "风扇",
    "gui.blocklyText.romeo.writeAnalog": "设置 [PIN] 引脚 [MODULE] 值为 [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "模拟执行器",
    "gui.blocklyText.romeo.fanModule": "风扇模块",
    "gui.blocklyText.romeo.robotSpeedMove": "机器人以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.romeo.forward": "前进",
    "gui.blocklyText.romeo.backward": "后退",
    "gui.blocklyText.romeo.turnLeft": "左转",
    "gui.blocklyText.romeo.turnRight": "右转",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "设置电机 [MOTOR] 以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.romeo.interface": "接口",
    "gui.blocklyText.romeo.motorForward": "正转",
    "gui.blocklyText.romeo.motorReverse": "反转",
    "gui.blocklyText.romeo.stopMotor": "机器人(M1和M2电机)停止",
    "gui.blocklyText.romeo.readUltrasonic": "读取超声波距离(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "读取 [PIN] 引脚LM35温度(℃)",
    "gui.blocklyText.romeo.ds18b20": "读取 [PIN] 引脚DS18B20温度(℃)",
    "gui.blocklyText.romeo.pressInfrared": "红外 [PIN] 引脚是否接收到 [BUTTON] 按下？",
    "gui.blocklyText.romeo.volumePlus": "音量+",
    "gui.blocklyText.romeo.switch": "开关",
    "gui.blocklyText.romeo.volumeMinus": "音量-",
    "gui.blocklyText.romeo.startPause": "播放/暂停",
    "gui.blocklyText.romeo.up": "上",
    "gui.blocklyText.romeo.down": "下",
    "gui.blocklyText.romeo.dataReadable": "串口有数据可读？",
    "gui.blocklyText.romeo.readSerialData": "读取串口数据",
    "gui.blocklyText.romeo.timer": "系统运行时间(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "设置 [PIN] 引脚喇叭音调为 [TONE] 节拍为 [BEAT]",
    "gui.blocklyText.romeo.half": "1/2",
    "gui.blocklyText.romeo.quarter": "1/4",
    "gui.blocklyText.romeo.oneInEighth": "1/8",
    "gui.blocklyText.romeo.wholeBeat": "整拍",
    "gui.blocklyText.romeo.doubleBeat": "双拍",
    "gui.blocklyText.romeo.stop": "停止",
    "gui.blocklyText.romeo.setServo": "设置 [PIN] 引脚伺服舵机为 [DEGREE] 度",
    "gui.blocklyText.pythondfr0548.setServo": " 设置 [PIN] 引脚伺服舵机为 [DEGREE] 度",
    "gui.blocklyText.romeo.segment": "设置八段数码管(D6-D13引脚)显示 [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "串口 [WAY] [STRING] [LINE]",
    "gui.blocklyText.romeo.originalOutput": "原始输出",
    "gui.blocklyText.romeo.stringOutput": "字符串输出",
    "gui.blocklyText.romeo.hexOutput": "十六进制输出",
    "gui.blocklyText.romeo.wrap": "换行",
    "gui.blocklyText.romeo.noWrap": "不换行",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "从串口读一个 [READTYPE]",
    "gui.blocklyText.arduinounoR3.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.romeo.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.max.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.maxbot.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.microbit.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.arduino.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.arduinoNano.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.mega2560.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.firebeetleesp32.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.romeo.int": "整数",
    "gui.blocklyText.romeo.float": "小数",
    "gui.blocklyText.romeo.setBaudRate": "设置串口波特率为 [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "舵机 [INDEX] 角度 [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "读取引脚 [PIN] 脉冲宽度 模式 [LEVEL] 超时 [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "低电平",
    "gui.blocklyText.romeo.high": "高电平",
    "gui.blocklyText.romeo.noSound": "无音",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino主程序",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno 主程序",
    "gui.blocklyText.boson.starts": "BOSON MC1主程序",
    "gui.blocklyText.pico.starts": "Pico主程序",
    "gui.blocklyText.boson.mpyAttitudeEvent": "当BOSON MC1板 [ATTITUDE]",
    "gui.blocklyText.boson.mpyBoardShakeThrown": "BOSON MC1板 [ATTITUDE]?",
    "gui.blocklyText.boson.readAcceleration": "读取加速度的值(m-g) [TYPE]",
    "gui.blocklyText.boson.setAccelerationRange": "设置加速度范围为 [ACCRANGE]",
    "gui.blocklyText.boson.vibrationMotor": "[SWITCH] 振动马达",
    "gui.blocklyText.boson.vibrationMotorTime": "持续振动 [TIME] 秒",
    "gui.blocklyText.boson.displayClear.js": "屏幕清屏为 [COLOR]",
    "gui.blocklyText.boson.displayClear": "屏幕清屏为 [COLOR] [PUSH]",
    "gui.blocklyText.boson.displaySetLineWidth": "设置线宽为 [LINE] 方向 [DIRECTION]",
    "gui.blocklyText.boson.displayText": "屏幕显示文字 [TEXT] 在坐标x[X] y[Y] 颜色[COLOR] 字体[FONT] 背景色[BACKGROUND] [NOWRAP] ",
    "gui.blocklyText.boson.displayText.js": "屏幕显示文字 [TEXT] 在坐标x[X] y[Y] 颜色[COLOR] [NOWRAP]",
    "gui.blocklyText.boson.displayTextLineshow": "在屏幕第[RAW]行显示文字 [TEXT] 颜色[COLOR] 字体[FONT] 背景色[BACKGROUND] [NOWRAP] [MIDDLE] ",
    "gui.blocklyText.boson.displayPixel": "画点 x[X] y[Y] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayLine": "画线 起点x[X1] y[Y1] 终点x[X2] y[Y2] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayHline": "画水平线 起点x[X] y[Y] 长度 [LEN] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayVline": "画垂直线 起点x[X] y[Y] 长度 [LEN] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayRect": "画矩形 起点x[X] y[Y] 宽度[W] 高度[H] 颜色 [COLOR] 位置 [POSITION] [FILL]",
    "gui.blocklyText.boson.displayRect.js": "画矩形 起点x[X] y[Y] 宽度[W] 高度[H] 颜色 [COLOR] [FILL]",
    "gui.blocklyText.boson.displayCircle": "画圆 圆心x[X] y[Y] 半径[R] 颜色 [COLOR] 位置 [POSITION] [FILL]",
    "gui.blocklyText.boson.displayCircle.js": "画圆 圆心x[X] y[Y] 半径[R] 颜色 [COLOR] [FILL]",
    "gui.blocklyText.boson.displayTriangle": "画三角形 x1[X1] y1[Y1] x2[X2] y2[Y2] x3[X3] y3[Y3] 颜色 [COLOR] 位置 [POSITION] [FILL]",
    "gui.blocklyText.boson.displayRoundRect": "画圆角矩形 起点x[X] y[Y] 宽度[WIDTH] 高度[HEIGTH] 圆角半径[R] 颜色 [COLOR] 位置 [POSITION]",
    "gui.blocklyText.boson.displayThreePointArc": "三点画弧 x1[X1] y1[Y1] x2[X2] y2[Y2] x3[X3] y3[Y3] 颜色 [COLOR] 离散间距 [SPACE] 位置 [POSITION]",
    "gui.blocklyText.boson.mpyDisplayComputerImage": "从电脑加载图片 [IMAGE] 以灰度显示到 x[X] y[Y] 位置 [POSITION] 模式[TYPE] ",
    "gui.blocklyText.boson.mpyDisplayComputerRgbImage": "从电脑加载图片 [IMAGE] 显示到 x[X] y[Y] 位置 [POSITION]",
    "gui.blocklyText.boson.boson_mpyDisplayFileSystemImage": "从文件系统加载图像 [PATH]",
    "gui.blocklyText.boson.showPixelCoordinate": "在坐标x [X] y [Y] 显示 [SHOWMSG] 字体 [PIXEL] 颜色 [COLOR] 背景 [BGCOLOR]",
    "gui.blocklyText.boson.displayQRCode": "绘制二维码 [QRCODE] 起点x[X] y[Y] 大小 [QRSIZE] 顏色 [COLOR]",
    "gui.blocklyText.boson.mpyDisplayClockInit": "初始化时钟 [NAME] 起点x[X] y[Y] 半径 [RADIUS] 颜色 [COLOR] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.mpyDisplayClockUpdate": "更新时钟 [NAME]",
    "gui.blocklyText.boson.displayClockInit": "初始化时钟 [ID] 起点x[X] y[Y] 半径 [RADIUS] 颜色 [COLOR] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.displayClockUpdate": "更新时钟 [ID]",
    "gui.blocklyText.boson.mpyDisplayProgressInit": "初始化进度条 [NAME] 起点x[X] y[Y] 宽度 [WIDTH] 高度 [HEIGTH] 进度 [PROGRESS]% 进度颜色 [COLOR1] 进度背景色 [COLOR2] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.mpyDisplayProgressUpdate": "更新进度条 [NAME] 进度为 [PROGRESS]%",
    "gui.blocklyText.boson.displayProgressInit": "初始化进度条 [ID] 起点x[X] y[Y] 宽度 [WIDTH] 高度 [HEIGTH] 进度 [PROGRESS] 进度颜色 [COLOR1] 进度背景色 [COLOR2] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.displayProgressUpdate": "更新进度条 [ID] 进度为 [PROGRESS]",
    "gui.blocklyText.boson.displayBrightness": "屏幕亮度为 [VALUE]%",
    "gui.blocklyText.boson.font16_16": "字体font16_16",
    "gui.blocklyText.boson.font20_20": "字体font20_20",
    "gui.blocklyText.boson.middleMenu.true": "居中",
    "gui.blocklyText.boson.middleMenu.false": "正常",
    "gui.blocklyText.boson.directionMenu.false": "正常",
    "gui.blocklyText.boson.directionMenu.true": "反向",
    "gui.blocklyText.boson.positionMenu.customize": "自定义",
    "gui.blocklyText.boson.positionMenu.horizontalCenter": "水平居中",
    "gui.blocklyText.boson.positionMenu.leftCenter": "左居中",
    "gui.blocklyText.boson.positionMenu.rightCenter": "右居中",
    "gui.blocklyText.boson.positionMenu.upCenter": "上居中",
    "gui.blocklyText.boson.positionMenu.downCenter": "下居中",
    "gui.blocklyText.boson.positionMenu.leftTop": "左上角",
    "gui.blocklyText.boson.positionMenu.leftDown": "左下角",
    "gui.blocklyText.boson.positionMenu.rightTop": "右上角",
    "gui.blocklyText.boson.positionMenu.rightDown": "右下角",
    "gui.blocklyText.boson.strength": "强度",
    "gui.blocklyText.boson.switchMenu.on": "打开",
    "gui.blocklyText.boson.switchMenu.off": "关闭",
    "gui.blocklyText.boson.nowrapMenu.true": "换行",
    "gui.blocklyText.boson.nowrapMenu.false": "不换行",
    "gui.blocklyText.boson.fillMenu.false": "空心",
    "gui.blocklyText.boson.fillMenu.true": "实心",
    "gui.blocklyText.boson.push.false": "清除缓存",
    "gui.blocklyText.boson.push.true": "立刻生效",
    "gui.blocklyText.esp8266.starts": "ESP8266 主程序开始",
    "gui.blocklyText.esp32.displayInLine": "屏幕显示文字 [TEXT] 在第 [LINE] 行",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "屏幕写入缓存文字 [TEXT] 在第 [LINE] 行 模式 [SHOWMODE]",
    "gui.blocklyText.esp32.normal": "普通",
    "gui.blocklyText.esp32.rev": "反转",
    "gui.blocklyText.esp32.trans": "透明",
    "gui.blocklyText.esp32.XOR": "XOR",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "屏幕清除缓存第 [LINE] 行",
    "gui.blocklyText.esp32.displayClearInLine": "屏幕显示清除第 [LINE] 行",
    "gui.blocklyText.esp32.buzzPinC": "P6  (板载蜂鸣器)",
    "gui.blocklyText.esp32.buzzerRedirect": "蜂鸣器重定向到引脚 [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "全部",
    "gui.blocklyText.esp32.setTouchThreshold": "设置按键 [PIN] 触摸阈值为 [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "读取按键 [PIN] 触摸值",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 主程序",
    "gui.blocklyText.esp32.btnIsPressed": "按钮 [BUTTON] 被 [BUTTONSTATE]？",
    "gui.blocklyText.esp32.whenBtnPress": "当 [REFERENCE] 按钮 [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "当触摸按键 [REFERENCE] 被 [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "当 [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "触摸按键 [PIN] 被 [TOUCHSTATE]？",
    "gui.blocklyText.esp32.buzzerSwitch": "停止后台播放",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "后台播放音符 [NOTE]",
    "gui.blocklyText.esp32.setBuzzerFreq": "播放音符 [NOTE] [BEAT] 拍",
    "gui.blocklyText.esp32.getSoundIntensity": "读取麦克风声音强度",
    "gui.blocklyText.esp32.readBrightness": "读取环境光强度",
    "gui.blocklyText.esp32.setLightsColor": "灯号 [LIGHTS] 显示颜色 [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "红 [RED] 绿 [GREEN] 蓝 [BLUE]",
    "gui.blocklyText.esp32.accelerationCalibration": "加速度校准 X轴偏移 [XOFFSET] Y轴偏移 [YOFFSET] Z轴偏移 [ZOFFSET] (m-g)",
    "gui.blocklyText.esp32.acceleration": "读取加速度的值(m-g) [TYPE]",
    "gui.blocklyText.esp32.esp32_tiltAngle": "读取倾斜角 [TYPE]",
    "gui.blocklyText.esp32.esp32_boardShakeThrown": "掌控板 [ATTITUDE]？",
    "gui.blocklyText.esp32.esp32_boardTiltState": "掌控板倾斜 [GESTUREMPY]",
    "gui.blocklyText.esp32.forward": "向前倾斜",
    "gui.blocklyText.esp32.back": "向后倾斜",
    "gui.blocklyText.esp32.left": "向左倾斜",
    "gui.blocklyText.esp32.right": "向右倾斜",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationRange": "设置加速度范围为 [ACCRANGE]",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationResolution": "设置加速度分辨率为 [ACCRESOLUTION]",
    "gui.blocklyText.esp32.esp32_mpyAccurateAccValue": "校准加速度值 X轴偏差 [XAXIS] Y轴偏差 [YAXIS] Z轴偏差 [ZAXIS]",
    "gui.blocklyText.esp32.esp32_mpyAccurateCompass": "校准指南针",
    "gui.blocklyText.esp32.esp32_mpyCompassDirection": "指南针方向",
    "gui.blocklyText.esp32.esp32_mpyAxisMagnetic": "[TYPE] 轴 磁力",
    "gui.blocklyText.esp32.esp32_mpyMagneticStrength": "磁场强度",
    "gui.blocklyText.esp32.esp32_mpyRemoveMagnetic": "去除当前环境磁场",
    "gui.blocklyText.esp32.esp32_mpyReturnRunTimeDiff": "返回运行时间差, 开始时间 [STARTTIME] 结束时间 [ENDTIME]",
    "gui.blocklyText.esp32.esp32_mpyRunTimer": "运行时间 [TIMETYPE]",
    "gui.blocklyText.esp32.millisecond": "毫秒",
    "gui.blocklyText.esp32.microsencond": "微秒",
    "gui.blocklyText.esp32.esp32_mpyLocalTime": "本地时间 [LOCALTIME]",
    "gui.blocklyText.esp32.year": "年",
    "gui.blocklyText.esp32.month": "月",
    "gui.blocklyText.esp32.day": "日",
    "gui.blocklyText.esp32.hour": "时",
    "gui.blocklyText.esp32.minute": "分",
    "gui.blocklyText.esp32.sencond": "秒",
    "gui.blocklyText.esp32.weeknumber": "星期数",
    "gui.blocklyText.esp32.daysnumber": "天数",
    "gui.blocklyText.esp32.esp32_mpyReset": "复位",
    "gui.blocklyText.esp32.esp32_mpyMacAddr": "MAC 地址",
    "gui.blocklyText.esp32.displayInXY": "屏幕显示文字 [TEXT] 在坐标 X: [X] Y: [Y] 预览 [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "屏幕写入缓存文字 [TEXT] 在坐标 X: [X] Y: [Y] 模式 [SHOWMODE] 预览 [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "屏幕显示为 [COLOR] (清屏)",
    "gui.blocklyText.esp32.fillScreenForMpy": "屏幕写入缓存为 [COLORMPYTHON]",
    "gui.blocklyText.esp32.clear": "清屏",
    "gui.blocklyText.esp32.fullBright": "全亮",
    "gui.blocklyText.esp32.blackBackground": "黑底",
    "gui.blocklyText.esp32.whiteBackground": "白底",
    "gui.blocklyText.esp32.black": "全黑",
    "gui.blocklyText.esp32.white": "全白",
    "gui.blocklyText.esp32.lineDrawing": "画线 起点x1:[X1] y1:[Y1] 终点x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "接通",
    "gui.blocklyText.esp32.disconnected": "断开",
    "gui.blocklyText.esp32.rectangleDrawing": "画矩形[FILL] 起点 x:[X] y:[Y] 宽:[WIDTH] 高:[HEIGHT]",
    "gui.blocklyText.esp32.mpyrectangleDrawing": "[DRAWERASE] [FILL] 画矩形 x:[X] y:[Y] 宽:[WIDTH] 高:[HEIGHT]",
    "gui.blocklyText.esp32.screenDisplay": "屏幕缓存显示生效",
    "gui.blocklyText.esp32.circleDrawing": "画圆[FILL] 圆心 x:[X] y:[Y] 半径:[RADIUS]",
    "gui.blocklyText.esp32.fill": "填充",
    "gui.blocklyText.esp32.notFill": "不填充",
    "gui.blocklyText.esp32.pointDrawing": "画点 x:[X] y:[Y]",
    "gui.blocklyText.esp32.esp32_mpyProgressBar": "进度条 x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT] 进度 [PROG]",
    "gui.blocklyText.esp32.esp32_mpyColumnarProgressBar": "柱状条 [COLUMNARSTATE] x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT] 进度 [PROG]",
    "gui.blocklyText.esp32.vertical": "垂直",
    "gui.blocklyText.esp32.horizontal": "水平",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseLine": "[DRAWERASE] [COLUMNARSTATE] 线 x [X] y [Y] 长度 [LENGTH]",
    "gui.blocklyText.esp32.draw": "绘制",
    "gui.blocklyText.esp32.erase": "擦除",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCornerBorder": "[DRAWERASE] 弧角边框 x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT] 半径 [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCircle": "[DRAWERASE] [FILL] 圆 x [X] y [Y] 半径 [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseTriangle": "[DRAWERASE] [FILL] 三角形 x1 [X1] y1 [Y1] x2 [X2] y2 [Y2] x3 [X3] y3 [Y3]",
    "gui.blocklyText.esp32.esp32_mpyShowPixelCoordinate": "在坐标 x [X] y [Y] 显示 [SHOWMSG] 以 [PIXEL] 字体 [LINE]",
    "gui.blocklyText.esp32.Digiface_11px": "仿数码管 11像素",
    "gui.blocklyText.esp32.Digiface_16px": "仿数码管 16像素",
    "gui.blocklyText.esp32.Digiface_21px": "仿数码管 21像素",
    "gui.blocklyText.esp32.Digiface_30px": "仿数码管 30像素",
    "gui.blocklyText.esp32.Digiface_44px": "仿数码管 44像素",
    "gui.blocklyText.esp32.Digiface_ltalic_30px": "仿数码管 斜体 30像素",
    "gui.blocklyText.esp32.Digiface_ltalic_42px": "仿数码管 斜体 42像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_12px": "DejaVu Sans Mono 12像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_16px": "DejaVu Sans Mono 16像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_21px": "DejaVu Sans Mono 21像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_12px": "DejaVu Sans Mono 黑体 12像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_16px": "DejaVu Sans Mono 黑体 16像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_21px": "DejaVu Sans Mono 黑体 21像素",
    "gui.blocklyText.esp32.esp32_mpyQRCode": "x [X] y [Y] 绘制二维码 [QRCODE] 尺寸 [IMGSIZE]",
    "gui.blocklyText.esp32.esp32_mpyInitClock": "初始化时钟 [CLOCKNAME] x [X] y [Y] 半径 [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyClockReadTime": "时钟 [CLOCKNAME] 读取时间",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseClock": "[DRAWERASE] 时钟 [CLOCKNAME]",
    "gui.blocklyText.esp32.setLineWidth": "设置线宽为 [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "设置数字引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "读取数字引脚 [PIN]",
    "gui.blocklyText.esp32.analogWrite": "设置模拟引脚 [PIN] 输出(PWM) [VALUE]",
    "gui.blocklyText.esp32.analogRead": "读取模拟引脚 [PIN]",
    "gui.blocklyText.esp32.serialPort0": "串口0",
    "gui.blocklyText.esp32.serialPort1": "串口1",
    "gui.blocklyText.esp32.serialPort2": "串口2",
    "gui.blocklyText.esp32.seriaRemapping": "设置 [SERIAL] Rx [RX] Tx [TX] 波特率为 [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "屏幕显示图片 [IMAGE] 在坐标 x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "从电脑加载图像 [IMAGE] 显示到 x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "按下",
    "gui.blocklyText.esp32.loosened": "松开",
    "gui.blocklyText.esp32.all": "全部(-1)",
    "gui.blocklyText.esp32.setBaud": "设置 串口0 波特率[BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "从 [SERIAL] 读取一个 [READTYPE]",
    "gui.blocklyText.esp32.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.esp32.setBrightness": "设置LED灯亮度为 [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "读取LED灯亮度",
    "gui.blocklyText.esp32.print": "打印 [DATA]",
    "gui.blocklyText.esp32.TiltForward": "向前倾斜",
    "gui.blocklyText.esp32.TiltBack": "向后倾斜",
    "gui.blocklyText.esp32.TiltLeft": "向左倾斜",
    "gui.blocklyText.esp32.TiltRight": "向右倾斜",
    "gui.blocklyText.esp32.ScreenUp": "屏幕朝上",
    "gui.blocklyText.esp32.ScreenDown": "屏幕朝下",
    "gui.blocklyText.esp32.Shake": "摇一摇",
    "gui.blocklyText.esp32.isGesture": "当前姿态为 [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "关闭 [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "全部",
    "gui.blocklyText.esp32.rgbdisable.No.0": "0号",
    "gui.blocklyText.esp32.rgbdisable.No.1": "1号",
    "gui.blocklyText.esp32.rgbdisable.No.2": "2号",
    "gui.blocklyText.esp32.playMusic": "播放音乐 [MUSIC] 重复 [REPEAT]",
    "gui.blocklyText.esp32.Once": "播放一次",
    "gui.blocklyText.esp32.Forever": "无限循环",
    "gui.blocklyText.esp32.OnceInBackground": "在后台播放一次",
    "gui.blocklyText.esp32.ForeverInBackground": "在后台无限循环",
    "gui.blocklyText.radio.radioSwitch": "[DATA] 无线广播",
    "gui.blocklyText.radio.radioswitch.on": "打开",
    "gui.blocklyText.radio.radioswitch.off": "关闭",
    "gui.blocklyText.radio.setChannel": "设置无线广播 频道为 [DATA]",
    "gui.blocklyText.radio.radioSend": "无线广播 发送 [DATA]",
    "gui.blocklyText.radio.radioreceived": "无线广播 接收消息",
    "gui.blocklyText.radio.receivingEven": "当接收到 [DATA]",
    "gui.blocklyText.radio.Msg": "无线消息",
    "gui.blocklyText.radio.receivingSpecificEven": "当接收到特定消息 [DATA] ",
    "gui.blocklyText.esp32.radioSwitch": "[DATA] 无线广播",
    "gui.blocklyText.esp32.radioswitch.on": "打开",
    "gui.blocklyText.esp32.radioswitch.off": "关闭",
    "gui.blocklyText.esp32.setChannel": "设置无线广播 频道为 [DATA]",
    "gui.blocklyText.esp32.radioSend": "无线广播 发送 [DATA]",
    "gui.blocklyText.esp32.radioreceived": "无线广播 接收消息",
    "gui.blocklyText.esp32.receivingEven": "当 收到无线广播消息[DATA]时 执行",
    "gui.blocklyText.esp32.receivingSpecificEven": "当 收到特定无线广播消息 [DATA] 时 执行",
    "gui.blocklyText.esp32.connectWIFI": "连接Wi-Fi 名称 [SSID] 密码 [PASS]",
    "gui.blocklyText.esp32.disconnectWIFI": "断开Wi-Fi连接",
    "gui.blocklyText.esp32.checkWIFI": "已连接到Wi-Fi",
    "gui.blocklyText.esp32.configurationInformation": "Wi-Fi配置信息",
    "gui.blocklyText.esp32.configurationInformationIP": "Wi-Fi配置信息[IP]",
    "gui.blocklyText.esp32.wifi.IP": "IP",
    "gui.blocklyText.esp32.wifi.netmask": "netmask",
    "gui.blocklyText.esp32.wifi.geteway": "geteway",
    "gui.blocklyText.esp32.wifi.DNS": "DNS",
    "gui.blocklyText.esp32.openAP": "开启AP模式 名称 [SSID] 密码 [PASS] 信道 [CHANNEL]",
    "gui.blocklyText.esp32.closeAP": "关闭AP模式",
    "gui.blocklyText.esp32.synchronizeTime": "同步网络时间 时区 [TIMEZONE] 授时服务器 [SERVER]",
    "gui.blocklyText.esp32.timezone.UTC+8": "东8区",
    "gui.blocklyText.esp32.timezone.UTC+9": "东9区",
    "gui.blocklyText.esp32.timezone.UTC+10": "东10区",
    "gui.blocklyText.esp32.timezone.UTC+11": "东11区",
    "gui.blocklyText.esp32.timezone.UTC+12": "东12区",
    "gui.blocklyText.esp32.timezone.UTC-12": "西12区",
    "gui.blocklyText.esp32.timezone.UTC-11": "西11区",
    "gui.blocklyText.esp32.timezone.UTC-10": "西10区",
    "gui.blocklyText.esp32.timezone.UTC-9": "西9区",
    "gui.blocklyText.esp32.timezone.UTC-8": "西8区",
    "gui.blocklyText.esp32.timezone.UTC-7": "西7区",
    "gui.blocklyText.esp32.timezone.UTC-6": "西6区",
    "gui.blocklyText.esp32.timezone.UTC-5": "西5区",
    "gui.blocklyText.esp32.timezone.UTC-4": "西4区",
    "gui.blocklyText.esp32.timezone.UTC-3": "西3区",
    "gui.blocklyText.esp32.timezone.UTC-2": "西2区",
    "gui.blocklyText.esp32.timezone.UTC-1": "西1区",
    "gui.blocklyText.esp32.timezone.UTC": "中时区",
    "gui.blocklyText.esp32.timezone.UTC+1": "东1区",
    "gui.blocklyText.esp32.timezone.UTC+2": "东2区",
    "gui.blocklyText.esp32.timezone.UTC+3": "东3区",
    "gui.blocklyText.esp32.timezone.UTC+4": "东4区",
    "gui.blocklyText.esp32.timezone.UTC+5": "东5区",
    "gui.blocklyText.esp32.timezone.UTC+6": "东6区",
    "gui.blocklyText.esp32.timezone.UTC+7": "东7区",
    "gui.blocklyText.pico.pico_led": "LED输出 [STATE]",
    "gui.blocklyText.pico.pico_readTemp": "读取温度(℃)",
    "gui.blocklyText.pico.pico_pinMode": "设置 [PIN] 引脚模式为 [MODE]",
    "gui.blocklyText.pico.serialPort0": "USB",
    "gui.blocklyText.pico.serialPort1": "硬串口1",
    "gui.blocklyText.pico.serialPort2": "硬串口2",
    "gui.blocklyText.pico.seriaRemapping": "设置 [SERIALPORT] Rx [PINR] Tx [PINT] 波特率为 [BAUD]",
    "gui.blocklyText.telloesp32.arduinoUnoStarts": "RMTT ESP32主程序开始",
    "gui.blocklyText.telloesp32.ledControl": "灯光控制",
    "gui.blocklyText.telloesp32.matrixControl": "点阵控制",
    "gui.blocklyText.telloesp32.flyControl": "飞行控制",
    "gui.blocklyText.telloesp32.customCommand": "自定义命令",
    "gui.blocklyText.telloesp32.pinOperation": "引脚操作",
    "gui.blocklyText.telloesp32.readDigital": "读取数字引脚 [PIN]",
    "gui.blocklyText.telloesp32.readAnalog": "读取模拟引脚 [PIN]",
    "gui.blocklyText.telloesp32.setPwm": "设置PWM引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.telloesp32.setDigital": "设置数字引脚 [PIN] 输出为 [LEVEL]",
    "gui.blocklyText.telloesp32.functionalModule": "功能模块",
    "gui.blocklyText.telloesp32.telloesp32_matrix": "显示图案[MARK]",
    "gui.blocklyText.telloesp32.showPatternWhenStart": "点阵屏开机显示图案[MARK]",
    "gui.blocklyText.telloesp32.setMatrixLight": "设置屏幕亮度[NUM]",
    "gui.blocklyText.telloesp32.matrixDisplayText": "显示单个文字 [TEXT] 颜色 [COLOR] 亮度 [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayPreset": "显示预设图案 [PATTERN] 颜色 [COLOR] 亮度 [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayTextMpy": "显示单个文字 [TEXT] 颜色 [COLOR]",
    "gui.blocklyText.telloesp32.matrixDisplayPresetMpy": "显示预设图案 [PATTERN] 颜色 [COLOR]",
    "gui.blocklyText.telloesp32.empty": "空",
    "gui.blocklyText.telloesp32.WiFi": "WiFi",
    "gui.blocklyText.telloesp32.DownLoad": "DownLoad",
    "gui.blocklyText.telloesp32.Power": "Power",
    "gui.blocklyText.telloesp32.heart": "heart",
    "gui.blocklyText.telloesp32.heartOutline": "heart-outline",
    "gui.blocklyText.telloesp32.matrixR": "红色",
    "gui.blocklyText.telloesp32.matrixB": "蓝色",
    "gui.blocklyText.telloesp32.matrixP": "紫色",
    "gui.blocklyText.telloesp32.matrixDisplayRollPicture": "滚动显示图案 方向 [DIRECT] 移动频率 [SPEED] 图案[MARK]",
    "gui.blocklyText.telloesp32.matrixDisplayRollText": "滚动显示文字 方向 [DIRECT] 移动频率 [SPEED] 文字 [TEXT] 颜色 [COLOR]",
    "gui.blocklyText.telloesp32.telloSetWifiChannel": "设置Wi-Fi信道 [CHANNEL]",
    "gui.blocklyText.telloesp32.initTOF": "启用TOF模组",
    "gui.blocklyText.telloesp32.readTOF": "读取TOF测距 (毫米)",
    "gui.blocklyText.telloesp32.buttonIsPressed": "按键是否按下？",
    "gui.blocklyText.telloesp32.setRgbColor": "LED灯颜色 [COLOR]",
    "gui.blocklyText.telloesp32.rgbToColor": "LED灯颜色 红: [RED] 绿: [GREEN] 蓝: [BLUE]",
    "gui.blocklyText.telloesp32.rgbBreathWithColor": "LED 呼吸灯 颜色[COLOR] 频率(Hz) [TIME]",
    "gui.blocklyText.telloesp32.rgbBreath": "LED呼吸灯 红: [RED] 绿: [GREEN] 蓝: [BLUE] 频率(Hz): [SP]",
    "gui.blocklyText.telloesp32.rgbFlashingColor": "LED闪烁 颜色1 [COLOR1] 和 颜色2 [COLOR2] 频率(Hz) [SP]",
    "gui.blocklyText.telloesp32.rgbFlashing": "LED闪烁 红1: [RED1] 绿1: [GREEN1] 蓝1: [BLUE1] - 红2: [RED2] 绿2: [GREEN2] 蓝2: [BLUE2] - 频率(Hz): [SP]",
    "gui.blocklyText.telloesp32.ledCloseEffects": "LED关闭特效",
    "gui.blocklyText.telloesp32.matrixCloseEffects": "屏幕关闭特效",
    "gui.blocklyText.telloesp32.sendCustomCommand": "发送自定义命令 [CMD]",
    "gui.blocklyText.telloesp32.recvCustomCommand": "收到自定义回应字符串 [MSG], 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.recvCustomCommandNum": "收到自定义回应数值, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.serialportOperation": "串口操作",
    "gui.blocklyText.telloesp32.dataReadable": "[SERIAL] 有数据可读?",
    "gui.blocklyText.telloesp32.readSerialData": "读取 [SERIAL] 数据",
    "gui.blocklyText.telloesp32.setBaud": "设置串口0波特率为 [BAUD]",
    "gui.blocklyText.telloesp32.readSerialDataIntFloat": "从 [SERIAL] 读取一个 [READTYPE]",
    "gui.blocklyText.telloesp32.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.telloesp32.seriaRemapping": "设置 [SERIAL] Rx [RX] Tx [TX] 波特率为 [BAUD]",
    "gui.blocklyText.telloesp32.systemResource": "系统资源",
    "gui.blocklyText.telloesp32.timer": "系统运行时间(ms)",
    "gui.blocklyText.telloesp32.consolePrint": "控制台输出",
    "gui.blocklyText.telloesp32.print": "打印 [DATA]",
    "gui.blocklyText.telloesp32.telloRiseDown": "[RISEDOWN] [NUMS] 厘米",
    "gui.blocklyText.telloesp32.rise": "上升",
    "gui.blocklyText.telloesp32.down": "下降",
    "gui.blocklyText.telloesp32.telloTurnDirection": "向 [DIRECTION] 飞 [NUMS] 厘米",
    "gui.blocklyText.telloesp32.turnleft": "左",
    "gui.blocklyText.telloesp32.turnright": "右",
    "gui.blocklyText.telloesp32.turnForward": "前",
    "gui.blocklyText.telloesp32.turnBack": "后",
    "gui.blocklyText.telloesp32.telloMotorOn": "进入起桨模式",
    "gui.blocklyText.telloesp32.telloMotorOff": "退出起桨模式",
    "gui.blocklyText.telloesp32.telloTakeOff": "起飞",
    "gui.blocklyText.telloesp32.telloThrowFly": "5秒内抛飞",
    "gui.blocklyText.telloesp32.telloLanding": "降落",
    "gui.blocklyText.telloesp32.telloClockwiseRotation": "[CLOCKWISE] 旋转 [NUMS] 度",
    "gui.blocklyText.telloesp32.clockwise": "顺时针",
    "gui.blocklyText.telloesp32.counterClockwise": "逆时针",
    "gui.blocklyText.telloesp32.telloStartControl": "开启飞行控制（绿灯亮起时按下按钮）直到成功",
    "gui.blocklyText.telloesp32.telloGetHeight": "获取 [HEIGHT],超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.relatively": "相对高度(dm)",
    "gui.blocklyText.telloesp32.barometer": "气压计高度(m)",
    "gui.blocklyText.telloesp32.tof": "TOF高度(mm)",
    "gui.blocklyText.telloesp32.telloTemp": "主板温度(°C),超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.lowest": "最低",
    "gui.blocklyText.telloesp32.highest": "最高",
    "gui.blocklyText.telloesp32.telloAttitude": "获取IMU三轴姿态数据, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.pitch": "俯仰轴",
    "gui.blocklyText.telloesp32.roll": "横滚轴",
    "gui.blocklyText.telloesp32.translation": "平移轴",
    "gui.blocklyText.telloesp32.telloAcceleration": "获取IMU三轴加速度数据(0.001g), 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.xaxis": "x轴",
    "gui.blocklyText.telloesp32.yaxis": "y轴",
    "gui.blocklyText.telloesp32.zaxis": "z轴",
    "gui.blocklyText.telloesp32.telloSetSpeed": "设置速度为 [NUMS]cm/s",
    "gui.blocklyText.telloesp32.telloSpeed": "[XYZAXIS] 速度(cm/s), 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloPower": "电池电量( %), 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.MissionPad": "挑战卡编号, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloMissionPadXyz": "挑战卡 [XYZ] (cm), 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloCommandResponse": "回复指令, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloSetyawXMid": "飞机相对挑战卡[MID]旋转至[YAW]度",
    "gui.blocklyText.telloesp32.telloGetMissionPadNum": "获取挑战卡编号, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloCurrentSpeed": "当前速度, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloTime": "电机运行时间 (s), 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloWIFISign": "Wi-Fi 信噪比, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloSDKVersion": "Tello SDK 版本, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloBinVersion": "Tello固件版本, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloHasHardware": "获取硬件版本, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.rmttWifiVersion": "扩展模块Wi-Fi版本, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloSN": "Tello SN 码, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloFlyToXYZ": "飞往(相对于飞机的) x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s",
    "gui.blocklyText.telloesp32.getSpeed": "获取当前设置速度(cm/s)",
    "gui.blocklyText.telloesp32.getBattery": "获取当前电池剩余电量的百分比值",
    "gui.blocklyText.telloesp32.getTime": "获取电机运转时间",
    "gui.blocklyText.telloesp32.getWifiNoise": "获取Wi-Fi信噪比",
    "gui.blocklyText.telloesp32.getSdkVersion": "获取sdk版本号",
    "gui.blocklyText.telloesp32.getSnNum": "获取SN码",
    "gui.blocklyText.firebeetleesp32.Starts": "FireBeetle ESP32主程序开始",
    "gui.blocklyText.firebeetleesp32e.Starts": "FireBeetle ESP32-E 主程序开始",
    "gui.blocklyText.firebeetleesp32.pinOperation": "引脚操作",
    "gui.blocklyText.firebeetleesp32.readDigital": "读取数字引脚 [PIN]",
    "gui.blocklyText.firebeetleesp32.readAnalog": "读取模拟引脚 [PIN]",
    "gui.blocklyText.firebeetleesp32.setPwm": "设置PWM引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.firebeetleesp32.setDigital": "设置数字引脚 [PIN] 输出为 [LEVEL]",
    "gui.blocklyText.firebeetleesp32.functionalModule": "功能模块",
    "gui.blocklyText.firebeetleesp32.serialportOperation": "串口操作",
    "gui.blocklyText.firebeetleesp32.setBaud": "设置串口波特率为 [BAUD]",
    "gui.blocklyText.firebeetleesp32.serialSendStr": "串口 [WAY] [STRING] [LINE]",
    "gui.blocklyText.firebeetleesp32.dataReadable": "串口有数据可读？",
    "gui.blocklyText.firebeetleesp32.readSerialData": "读取串口数据",
    "gui.blocklyText.firebeetleesp32.timer": "系统运行时间(ms)",
    "gui.blocklyText.firebeetleesp32.originalOutput": "原始输出",
    "gui.blocklyText.firebeetleesp32.stringOutput": "字符串输出",
    "gui.blocklyText.firebeetleesp32.hexOutput": "十六进制输出",
    "gui.blocklyText.firebeetleesp32.wrap": "换行",
    "gui.blocklyText.firebeetleesp32.noWrap": "不换行",
    "gui.blocklyText.firebeetleesp32.high": "高电平",
    "gui.blocklyText.firebeetleesp32.low": "低电平",
    "gui.blocklyText.firebeetleesp32.readSerialDataIntFloat": "从串口读一个 [READTYPE]",
    "gui.blocklyText.firebeetleesp32.int": "整数",
    "gui.blocklyText.firebeetleesp32.float": "小数",
    "gui.blocklyText.firebeetleesp32.readUlrasonic": "读取超声波距离(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.firebeetleesp32.trumpet": "设置引脚 [PIN] 喇叭蜂鸣器音调为 [TONE] 节拍为 [BEAT]",
    "gui.blocklyText.firebeetleesp32.readInfrared": "读取红外接收模块 数字引脚 [PIN]",
    "gui.blocklyText.firebeetleesp32.readPinLevel": "读取引脚 [PIN] 脉冲宽度 模式 [LEVEL] 超时 [TIMEOUT](us)",
    "gui.blocklyText.firebeetleesp32.half": "1/2",
    "gui.blocklyText.firebeetleesp32.quarter": "1/4",
    "gui.blocklyText.firebeetleesp32.oneInEighth": "1/8",
    "gui.blocklyText.firebeetleesp32.wholeBeat": "整拍",
    "gui.blocklyText.firebeetleesp32.doubleBeat": "双拍",
    "gui.blocklyText.firebeetleesp32.noSound": "无音",
    "gui.blocklyText.firebeetleesp32.stop": "停止",
    "gui.blocklyText.firebeetleesp32.pwm1": "D5",
    "gui.blocklyText.firebeetleesp32.pwm2": "D9",
    "gui.blocklyText.firebeetleesp32.pwm3": "D8",
    "gui.blocklyText.firebeetleesp32.pwm4": "D7",
    "gui.blocklyText.firebeetleesp32.pwm5": "A4",
    "gui.blocklyText.firebeetleesp32.pwm6": "D2",
    "gui.blocklyText.firebeetleesp32.pwm7": "D3",
    "gui.blocklyText.firebeetleesp32.pwm8": "D4",
    "gui.blocklyText.firebeetleesp32.pwm9": "D6",
    "gui.blocklyText.mpy.MQTTinit": "MQTT初始化参数 [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInit": "MQTT初始化参数 [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInitPlatform": "平台",
    "gui.blocklyText.mpy.mpyMqttInitPlatformNormal": "通用",
    "gui.blocklyText.mpy.mpyMqttInitPlatformTencent": "腾讯物联网",
    "gui.blocklyText.mpy.MQTTclient_id": "客户端id",
    "gui.blocklyText.mpy.MQTTserver": "服务器地址",
    "gui.blocklyText.mpy.MQTTport": "端口",
    "gui.blocklyText.mpy.MQTTuser": "用户名",
    "gui.blocklyText.mpy.MQTTpassowrd": "密码",
    "gui.blocklyText.mpy.MQTTkeepalive": "keepalive",
    "gui.blocklyText.mpy.MQTTtencent": "MQTT腾讯物联网 [SETTING]",
    "gui.blocklyText.mpy.TencentIOT": "腾讯IOT",
    "gui.blocklyText.mpy.TencentServer": "服务器",
    "gui.blocklyText.mpy.TencentPort": "端口",
    "gui.blocklyText.mpy.TencentAppld": "Appld",
    "gui.blocklyText.mpy.TencentDevice": "设备",
    "gui.blocklyText.mpy.siotServer": "服务器地址",
    "gui.blocklyText.mpy.siotServerUser": "账号",
    "gui.blocklyText.mpy.siotServerPass": "密码",
    "gui.blocklyText.mpy.Tencentoutput": "传出参数",
    "gui.blocklyText.mpy.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.mpy.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.mpy.mpy_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.mpy.EasyIOTServer": "服务器",
    "gui.blocklyText.mpy.EasyIOTServerChina": "中国",
    "gui.blocklyText.mpy.EasyIOTServerGlobal": "全球",
    "gui.blocklyText.mpy.EasyIOTclient_id": "客户端id",
    "gui.blocklyText.mpy.EasyIOT_id": "Iot_id",
    "gui.blocklyText.mpy.EasyIOT_password": "Iot_pwd",
    "gui.blocklyText.mpy.MQTTconnect": "连接MQTT",
    "gui.blocklyText.mpy.mpy_MQTTAddSub": "订阅主题 [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublish": "发布 [DATA] 至 主题 [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublishQos1": "发布 [DATA] 至 主题 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.mpy.mpy_waitMSG": "等待主题消息 以 [MODE] 模式",
    "gui.blocklyText.mpy.disconnectMQTT": "MQTT 断开连接",
    "gui.blocklyText.mpy.MQTTlastwill": "设置MQTT主题 [TOPIC] 离线消息 [MSG]",
    "gui.blocklyText.mpy.MQTTreceive": "当主题 [TOPIC] 接收到 [DATA]",
    "gui.blocklyText.mpy.mqttMsg": "MQTT消息",
    "gui.blocklyText.mpy.MQTTmode.block": "阻塞",
    "gui.blocklyText.mpy.MQTTmode.unblock": "非阻塞",
    "gui.blocklyText.mpyWeather.init": "「心知天气」 设定 [DATA] 为 [SETTING]",
    "gui.blocklyText.mpyWeather.weatherFeild": "[DATA] 「心知天气」 通用字段 [FIELD]",
    "gui.blocklyText.mpyWeather.weatherSituation": "[DATA] 「心知天气」 天气实况 [NOW]",
    "gui.blocklyText.mpyWeather.weatherForecast": "[DATA] 「心知天气」 3天天气预报 [DATE] 的 [FIELD]",
    "gui.blocklyText.mpyWeather.weatherLivingindex": "[DATA] 「心知天气」 6项生活指数 [FIELD]",
    "gui.blocklyText.mpyWeather.weatherFeild.name": "城市名称",
    "gui.blocklyText.mpyWeather.weatherFeild.id": "城市ID",
    "gui.blocklyText.mpyWeather.weatherFeild.code": "国家代码",
    "gui.blocklyText.mpyWeather.weatherFeild.path": "行政归属",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone": "时区",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone_offset": "UTC偏移量",
    "gui.blocklyText.mpyWeather.weatherFeild.Updated": "数据更新日期",
    "gui.blocklyText.mpyWeather.weatherFeild.updatetime": "数据更新时间",
    "gui.blocklyText.mpyWeather.weatherFeild.original": "原始字典对象",
    "gui.blocklyText.mpyWeather.weatherSituation.text": "天气现象",
    "gui.blocklyText.mpyWeather.weatherSituation.code": "天气现象代码",
    "gui.blocklyText.mpyWeather.weatherSituation.temperature": "温度",
    "gui.blocklyText.mpyWeather.weatherdate.today": "今天",
    "gui.blocklyText.mpyWeather.weatherdate.tomorrow": "明天",
    "gui.blocklyText.mpyWeather.weatherdate.dayaftertomorrow": "后天",
    "gui.blocklyText.mpyWeather.province": "省份",
    "gui.blocklyText.mpyWeather.city": "城市",
    "gui.blocklyText.mpyWeather.weatherforecast.highesttemperature": "当天最高温度",
    "gui.blocklyText.mpyWeather.weatherforecast.lowesttemperature": "当天最低温度",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweather": "白天天气现象",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweathercode": "白天天气现象代码",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweather": "晚间天气现象",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweathercode": "晚间天气现象代码",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirection": "风向",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirectiondegree": "风向角度",
    "gui.blocklyText.mpyWeather.weatherforecast.windspeed": "风速",
    "gui.blocklyText.mpyWeather.weatherforecast.windscale": "风力等级",
    "gui.blocklyText.mpyWeather.weatherforecast.date": "日期",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Carwashindex": "洗车指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Dressingindex": "穿衣指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Coldindex": "感冒指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Sportindex": "运动指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Travelindex": "旅游指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.uvindex": "紫外线指数",
    "gui.blocklyText.mpyWeather.type": "数据类型",
    "gui.blocklyText.mpyWeather.temperature": "温度单位",
    "gui.blocklyText.mpyWeather.APIkey": "API私钥",
    "gui.blocklyText.mpyWeather.language": "语言",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 主程序",
    "gui.blocklyText.esp32.MQTTinit": "MQTT初始化参数 [SETTING]",
    "gui.blocklyText.esp32.MQTTclient_id": "客户端id",
    "gui.blocklyText.esp32.MQTTserver": "服务器地址",
    "gui.blocklyText.esp32.MQTTport": "端口",
    "gui.blocklyText.esp32.MQTTuser": "用户名",
    "gui.blocklyText.esp32.MQTTpassowrd": "密码",
    "gui.blocklyText.esp32.MQTTkeepalive": "keepalive",
    "gui.blocklyText.esp32.MQTTtencent": "MQTT腾讯物联网 [SETTING]",
    "gui.blocklyText.esp32.TencentIOT": "腾讯IOT",
    "gui.blocklyText.esp32.TencentServer": "服务器",
    "gui.blocklyText.esp32.TencentPort": "端口",
    "gui.blocklyText.esp32.TencentAppld": "Appld",
    "gui.blocklyText.esp32.TencentDevice": "设备",
    "gui.blocklyText.esp32.Tencentoutput": "传出参数",
    "gui.blocklyText.esp32.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.esp32.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.esp32.esp32_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.esp32.EasyIOTServer": "服务器",
    "gui.blocklyText.esp32.EasyIOTclient_id": "客户端id",
    "gui.blocklyText.esp32.EasyIOT_id": "IoT_id",
    "gui.blocklyText.esp32.EasyIOT_password": "密码",
    "gui.blocklyText.esp32.MQTTconnect": "连接MQTT",
    "gui.blocklyText.esp32.MQTTpublish": "发布 [DATA] 至 主题 [TOPIC]",
    "gui.blocklyText.esp32.esp32_waitMSG": "等待主题消息 以 [MODE] 模式",
    "gui.blocklyText.esp32.disconnectMQTT": "MQTT 断开连接",
    "gui.blocklyText.esp32.MQTTlastwill": "设置MQTT主题 [TOPIC] 离线消息 [MSG]",
    "gui.blocklyText.esp32.MQTTreceive_1": "当主题 [TOPIC] 接收到消息为 [DATA] 时",
    "gui.blocklyText.esp32.MQTTreceive_2": "当主题 [TOPIC] 接收到消息为 [DATA] 时",
    "gui.blocklyText.weather.weatherFeild": "[DATA] 心知天气 通用字段 [FIELD]",
    "gui.blocklyText.weather.weatherSituation": "[DATA] 心知天气 天气实况 [FIELD]",
    "gui.blocklyText.weather.Weatherforecast": "[DATA] 心知天气 3天天气预报 [DATE] 的 [FIELD]",
    "gui.blocklyText.weather.Weatherlivingindex": "[DATA] 心知天气 6项生活指数 [FIELD]",
    "gui.blocklyText.esp32.MQTTmode.block": "阻塞",
    "gui.blocklyText.esp32.MQTTmode.unblock": "非阻塞",
    "gui.blocklyText.esp32.weatherfeild.name": "城市名称",
    "gui.blocklyText.esp32.weatherfeild.id": "城市ID",
    "gui.blocklyText.esp32.weatherfeild.code": "国家代码",
    "gui.blocklyText.esp32.weatherfeild.path": "行政归属",
    "gui.blocklyText.esp32.weatherfeild.timezone": "时区",
    "gui.blocklyText.esp32.weatherfeild.timezone_offset": "UTC偏移量",
    "gui.blocklyText.esp32.weatherfeild.Updated": "数据更新日期",
    "gui.blocklyText.esp32.weatherfeild.updatetime": "数据更新时间",
    "gui.blocklyText.esp32.weatherfeild.original": "原始字典对象",
    "gui.blocklyText.esp32.weatherSituation.text": "天气现象",
    "gui.blocklyText.esp32.weatherSituation.code": "天气现象代码",
    "gui.blocklyText.esp32.weatherSituation.temperature": "温度",
    "gui.blocklyText.esp32.weatherdate.today": "今天",
    "gui.blocklyText.esp32.weatherdate.tomorrow": "明天",
    "gui.blocklyText.esp32.weatherdate.dayaftertomorrow": "后天",
    "gui.blocklyText.esp32.weatherforecast.highesttemperature": "当天最高温度",
    "gui.blocklyText.esp32.weatherforecast.lowesttemperature": "当天最低温度",
    "gui.blocklyText.esp32.weatherforecast.daytimeweather": "白天天气现象",
    "gui.blocklyText.esp32.weatherforecast.daytimeweathercode": "白天天气现象代码",
    "gui.blocklyText.esp32.weatherforecast.nightweather": "晚间天气现象",
    "gui.blocklyText.esp32.weatherforecast.nightweathercode": "晚间天气现象代码",
    "gui.blocklyText.esp32.weatherforecast.winddirection": "风向",
    "gui.blocklyText.esp32.weatherforecast.winddirectiondegree": "风向角度",
    "gui.blocklyText.esp32.weatherforecast.windspeed": "风速",
    "gui.blocklyText.esp32.weatherforecast.windscale": "风力等级",
    "gui.blocklyText.esp32.weatherforecast.date": "日期",
    "gui.blocklyText.esp32.weatherlivingindex.Carwashindex": "洗车指数",
    "gui.blocklyText.esp32.weatherlivingindex.Dressingindex": "穿衣指数",
    "gui.blocklyText.esp32.weatherlivingindex.Coldindex": "感冒指数",
    "gui.blocklyText.esp32.weatherlivingindex.Sportindex": "运动指数",
    "gui.blocklyText.esp32.weatherlivingindex.Travelindex": "旅游指数",
    "gui.blocklyText.esp32.weatherlivingindex.uvindex": "紫外线指数",
    "gui.blocklyText.mega2560.digitalRead": "读取数字引脚 [PIN]",
    "gui.blocklyText.mega2560.analogRead": "读取模拟引脚 [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "设置pwm引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "设置数字引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.mega2560.level.low": "低电平",
    "gui.blocklyText.mega2560.level.high": "高电平",
    "gui.blocklyText.mega2560.irqRead": "读取红外接收模块 数字引脚 [PIN]",
    "gui.blocklyText.mega2560.ultrasonicRead": "读取超声波传感器trig为 [TRIG] echo为 [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "设置引脚 [PIN] 喇叭蜂鸣器音调为 [TONE] 节拍为 [BEAT]",
    "gui.blocklyText.mega2560.harf": "1/2",
    "gui.blocklyText.mega2560.quarter": "1/4",
    "gui.blocklyText.mega2560.oneInEighth": "1/8",
    "gui.blocklyText.mega2560.wholeBeat": "整拍",
    "gui.blocklyText.mega2560.doubleBeat": "双拍",
    "gui.blocklyText.mega2560.stop": "停止",
    "gui.blocklyText.mega2560.servoSet": "设置引脚 [PIN] 伺服舵机为 [DEGREE] 度",
    "gui.blocklyText.mega2560.setBaud": "设置 [SERIAL] 波特率为 [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "从 [SERIAL] 读取一个 [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "串口0",
    "gui.blocklyText.mega2560.serialPort1": "串口1",
    "gui.blocklyText.mega2560.serialPort2": "串口2",
    "gui.blocklyText.mega2560.serialPort3": "串口3",
    "gui.blocklyText.mega2560.dataReadable": "[SERIAL] 有数据可读？",
    "gui.blocklyText.mega2560.readSerialData": "读取 [SERIAL] 数据",
    "gui.blocklyText.mega2560.stringOutput": "字符串输出",
    "gui.blocklyText.mega2560.originalOutput": "原始输出",
    "gui.blocklyText.mega2560.hexOutput": "十六进制输出",
    "gui.blocklyText.mega2560.wrap": "换行",
    "gui.blocklyText.mega2560.noWrap": "不换行",
    "gui.blocklyText.mega2560.readPinLevel": "读取引脚 [PIN] 脉冲宽度 模式为 [LEVEL] 超时时间 [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "系统运行时间(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini 主程序",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo 主程序",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 主程序",
    "gui.blocklyText.arduino.setDigital": "设置数字引脚 [PIN] 输出为 [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "读取数字引脚 [PIN]",
    "gui.blocklyText.arduino.readSimilation": "读取模拟引脚 [PIN]",
    "gui.blocklyText.arduino.setPwm": "设置pwm引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "读取超声波距离(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "设置引脚 [PIN] 喇叭蜂鸣器音调为 [TONE] 节拍为 [BEAT]",
    "gui.blocklyText.arduino.serialSendStr": "串口 [WAY] [STRING] [LINE]",
    "gui.blocklyText.arduino.originalOutput": "原始输出",
    "gui.blocklyText.arduino.stringOutput": "字符串输出",
    "gui.blocklyText.arduino.hexOutput": "十六进制输出",
    "gui.blocklyText.arduino.wrap": "换行",
    "gui.blocklyText.arduino.noWrap": "不换行",
    "gui.blocklyText.arduino.readInfrared": "读取红外接收模块 数字引脚 [PIN]",
    "gui.blocklyText.arduino.setBaud": "设置串口波特率为 [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "读取引脚 [PIN] 脉冲宽度 模式 [LEVEL] 超时 [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "无音",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Nano 主程序开始",
    "gui.blocklyText.vortex.vortexStart": "Vortex机器人程序开始",
    "gui.blocklyText.vortex.vortexMoveSpeed": "Vortex机器人以 [SPEED] 速度 [DIRECTION]",
    "gui.blocklyText.vortex.forward": "前进",
    "gui.blocklyText.vortex.backup": "后退",
    "gui.blocklyText.vortex.turnLeft": "左转",
    "gui.blocklyText.vortex.turnRight": "右转",
    "gui.blocklyText.vortex.setMotor": "设置电机 [MOTOR] 以 [SPEED] 速度 [DIRECTION]",
    "gui.blocklyText.vortex.leftWheel": "左轮",
    "gui.blocklyText.vortex.rightWheel": "右轮",
    "gui.blocklyText.vortex.setExpression": "设置 [EXPRESSION] 表情的颜色为 [COLOR]",
    "gui.blocklyText.vortex.ledLights": "设置 [DIRECTION][LIGHT][SECOND] 秒内变化为 [COLOR]",
    "gui.blocklyText.vortex.all": "全部",
    "gui.blocklyText.vortex.rightAhead": "正前",
    "gui.blocklyText.vortex.leftRear": "左后",
    "gui.blocklyText.vortex.rightRear": "右后",
    "gui.blocklyText.vortex.rightAstern": "正后",
    "gui.blocklyText.vortex.topLights": "顶灯",
    "gui.blocklyText.vortex.bottomLights": "底灯",
    "gui.blocklyText.vortex.vortexStop": "vortex停止运动",
    "gui.blocklyText.vortex.setVolume": "设置音量大小为 [VALUE]",
    "gui.blocklyText.vortex.playMusic": "播放第 [VALUE] 首音乐",
    "gui.blocklyText.vortex.stopMusic": "停止音乐",
    "gui.blocklyText.vortex.startDance": "开始跳第 [VALUE] 支舞蹈",
    "gui.blocklyText.vortex.stopDance": "停止跳舞",
    "gui.blocklyText.vortex.barrierDetection": "前方有障碍物？",
    "gui.blocklyText.vortex.readSensor": "读取 [SENSOR] 灰度传感器的值",
    "gui.blocklyText.vortex.setSensor": "设置灰度传感器监控变化量为 [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "灰度传感器 [SENSOR] 方遇到 黑色？",
    "gui.blocklyText.vortex.positiveFrontLeft": "正左前",
    "gui.blocklyText.vortex.frontLeft": "左前",
    "gui.blocklyText.vortex.positiveFrontRight": "正右前",
    "gui.blocklyText.vortex.frontRight": "右前",
    "gui.blocklyText.vortex.backRight": "右后",
    "gui.blocklyText.vortex.backLeft": "左后",
    "gui.blocklyText.vortex.initialOldPCB": "初始化为老版本(蓝色PCB)",
    "pen.categoryName": "画笔",
    "pen.changeColorParam": "将笔的 [COLOR_PARAM] 增加[VALUE]",
    "pen.changeHue": "将笔的颜色增加[HUE]",
    "pen.changeShade": "将笔的亮度增加[SHADE]",
    "pen.changeSize": "将笔的粗细增加[SIZE]",
    "pen.clear": "全部擦除",
    "pen.colorMenu.brightness": "亮度",
    "pen.colorMenu.color": "颜色",
    "pen.colorMenu.saturation": "饱和度",
    "pen.colorMenu.transparency": "透明度",
    "pen.penDown": "落笔",
    "pen.penUp": "抬笔",
    "pen.setColor": "将笔的颜色设为[COLOR]",
    "pen.setColorParam": "将笔的 [COLOR_PARAM] 设为[VALUE]",
    "pen.setHue": "将笔的颜色设为[HUE]",
    "pen.setShade": "将笔的亮度设为[SHADE]",
    "pen.setSize": "将笔的粗细设为[SIZE]",
    "pen.stamp": "图章",
    "music.changeTempo": "将演奏速度增加[TEMPO]",
    "music.drumBass": "(2) 低音鼓",
    "music.drumBongo": "(13) 邦戈鼓",
    "music.drumCabasa": "(15) 卡巴萨",
    "music.drumClaves": "(9) 音棒",
    "music.drumClosedHiHat": "(6) 闭击踩镲",
    "music.drumConga": "(14) 康加鼓",
    "music.drumCowbell": "(11) 牛铃",
    "music.drumCrashCymbal": "(4) 碎音钹",
    "music.drumCuica": "(18) 锯加鼓",
    "music.drumGuiro": "(16) 刮瓜",
    "music.drumHandClap": "(8) 手掌",
    "music.drumOpenHiHat": "(5) 开击踩镲",
    "music.drumSideStick": "(3) 敲鼓边",
    "music.drumSnare": "(1) 小军鼓",
    "music.drumTambourine": "(7) 铃鼓",
    "music.drumTriangle": "(12) 三角铁",
    "music.drumVibraslap": "(17) 颤音器",
    "music.drumWoodBlock": "(10) 木鱼",
    "music.getTempo": "演奏速度",
    "music.instrumentBass": "(6) 贝斯",
    "music.instrumentBassoon": "(14) 巴松管",
    "music.instrumentCello": "(8) 大提琴",
    "music.instrumentChoir": "(15) 唱诗班",
    "music.instrumentClarinet": "(10) 单簧管",
    "music.instrumentElectricGuitar": "(5) 电吉他",
    "music.instrumentElectricPiano": "(2) 电钢琴",
    "music.instrumentFlute": "(12) 长笛",
    "music.instrumentGuitar": "(4) 吉他",
    "music.instrumentMarimba": "(19) 马林巴琴",
    "music.instrumentMusicBox": "(17) 八音盒",
    "music.instrumentOrgan": "(3) 风琴",
    "music.instrumentPiano": "(1) 钢琴",
    "music.instrumentPizzicato": "(7) 拨弦",
    "music.instrumentSaxophone": "(11) 萨克斯管",
    "music.instrumentSteelDrum": "(18) 钢鼓",
    "music.instrumentSynthLead": "(20) 合成主音",
    "music.instrumentSynthPad": "(21) 合成柔音",
    "music.instrumentTrombone": "(9) 长号",
    "music.instrumentVibraphone": "(16) 颤音琴",
    "music.instrumentWoodenFlute": "(13) 木长笛",
    "music.midiPlayDrumForBeats": "击打 [DRUM] [BEATS] 拍",
    "music.midiSetInstrument": "将乐器设为 [INSTRUMENT]",
    "music.playDrumForBeats": "击打 [DRUM] [BEATS] 拍",
    "music.playNoteForBeats": "演奏音符[NOTE][BEATS]拍",
    "music.restForBeats": "休止[BEATS]拍",
    "music.setInstrument": "将乐器设为 [INSTRUMENT]",
    "music.setTempo": "将演奏速度设定为[TEMPO]",
    "gui.blocklyText.microbitV2.logo": "徽标(V2)",
    "gui.blocklyText.microbitV2.ls": "蜂鸣器(V2)",
    "gui.blocklyText.microbit.microbitStarts": "micro:bit主程序开始",
    "gui.blocklyText.microbit.interruptExcute": "中断引脚 [PIN] 模式 [MODE]执行",
    "gui.blocklyText.microbit.cancleInterrupt": "取消中断引脚 [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "当无线接收到数据",
    "gui.blocklyText.microbit.whenBtnPress": "当 [REFERENCE] 按钮按下",
    "gui.blocklyText.microbit.whenPinConnected": "当接口 [REFERENCE] 被接通",
    "gui.blocklyText.microbit.whenPosChange": "当 [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "显示图案或内置图案 [PIC]",
    "gui.blocklyText.microbit.showInput": "显示文字 [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "停止滚动动画",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] 坐标 x:[XAXIS], y:[YXAXIS]",
    "gui.blocklyText.microbit.hideAllLights": "熄灭所有点阵",
    "gui.blocklyText.microbit.microbitEnableLight": "点阵屏 [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "亮度",
    "gui.blocklyText.microbit.showLightWithBrightness": "设置亮度 [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "点亮 坐标x:[XAXIS], y:[YXAXIS], 亮度 [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "接口 [PIN] 后台播放声音 [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "接口 [PIN] 播放声音 [SOUND] 直到结束",
    "gui.blocklyText.microbit.playNote": "接口 [PIN] 播放音符 [NOTE] [BEAT] 拍",
    "gui.blocklyText.microbit.true": "启用",
    "gui.blocklyText.microbit.false": "释放",
    "gui.blocklyText.maqueen.playSound": "接口 P0 后台播放声音 [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "接口 P0 播放声音 [SOUND] 直到结束",
    "gui.blocklyText.maqueen.playNote": "接口 P0 播放音符 [NOTE] [BEAT] 拍",
    "gui.blocklyText.maqueen.RGB0": "RGB0 (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1 (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2 (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3 (3)",
    "gui.blocklyText.maqueen.All": "全部 (-1)",
    "gui.blocklyText.microbit.changeTempo": "将声音速度(bpm)增加 [VALUE]",
    "gui.blocklyText.microbit.setTempo": "将声音速度(bpm)设置为 [VALUE]",
    "gui.blocklyText.microbit.getTempo": "声音速度 (bpm)",
    "gui.blocklyText.microbit.stopSound": "停止后台播放",
    "gui.blocklyText.microbit.btnIsPressed": "按钮 [BUTTON] 被按下？",
    "gui.blocklyText.microbit.pinIsConnected": "接口 [PIN] 被接通？",
    "gui.blocklyText.microbit.isMove": "当前姿态为 [TYPE]？",
    "gui.blocklyText.microbit.compass": "读取指南针朝向(°)",
    "gui.blocklyText.microbit.temperature": "读取温度值(℃)",
    "gui.blocklyText.microbit.digitalWrite": "设置数字引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "读取数字引脚 [PIN]",
    "gui.blocklyText.microbit.analogWrite": "设置模拟引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.microbit.analogRead": "读取模拟引脚 [PIN]",
    "gui.blocklyText.microbit.up": "徽标朝上",
    "gui.blocklyText.microbit.down": "徽标朝下",
    "gui.blocklyText.microbit.left": "向左倾斜",
    "gui.blocklyText.microbit.right": "向右倾斜",
    "gui.blocklyText.microbit.faceUp": "屏幕朝上",
    "gui.blocklyText.microbit.faceDown": "屏幕朝下",
    "gui.blocklyText.microbit.freefall": "自由落体运动",
    "gui.blocklyText.microbit.3g": "3g",
    "gui.blocklyText.microbit.6g": "6g",
    "gui.blocklyText.microbit.8g": "8g",
    "gui.blocklyText.microbit.shake": "摇一摇",
    "gui.blocklyText.microbit.show": "点亮",
    "gui.blocklyText.microbit.start": "打开",
    "gui.blocklyText.microbit.stop": "关闭",
    "gui.blocklyText.microbit.hide": "熄灭",
    "gui.blocklyText.microbit.low": "低电平",
    "gui.blocklyText.microbit.high": "高电平",
    "gui.blocklyText.microbit.microbitReadBrightness": "读取环境光强度",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] 无线通信",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "设置无线频道为 [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "通过无线发送 [TEXT]",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "无线接收数据",
    "gui.blocklyText.microbit.strength": "强度",
    "gui.blocklyText.microbit.acceleration": "读取加速度的值(m-g)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "读取磁力计的值(μT)[TYPE]",
    "gui.blocklyText.microbit.microbitAccurateCompass": "校准电子罗盘",
    "gui.blocklyText.microbit.microbitGetSoundLevel": "读取声音强度(V2)",
    "gui.blocklyText.microbit.print": "打印 [DATA]",
    "gui.blocklyText.microbit.goUp": "上升",
    "gui.blocklyText.microbit.goDown": "下降",
    "gui.blocklyText.microbit.change": "电平变化",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "当接收到[WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "无线数据",
    "gui.blocklyText.calliope.whenBtnPress": "当 [REFERENCE] 按钮按下",
    "gui.blocklyText.calliope.calliopeEnableLight": "点阵屏 [ENABLE]",
    "gui.blocklyText.calliope.hideAllLights": "熄灭所有点阵",
    "gui.blocklyText.calliope.playSound": "接口 [PIN] 后台播放声音 [SOUND]",
    "gui.blocklyText.calliope.playSoundUntil": "接口 [PIN] 播放声音 [SOUND] 直到结束",
    "gui.blocklyText.calliope.playNote": "接口 [PIN] 播放音符 [NOTE] [BEAT] 拍",
    "gui.blocklyText.calliope.calliopeReadBrightness": "读取环境光强度",
    "gui.blocklyText.calliope.whenPinConnected": "当接口 [REFERENCE] 被接通",
    "gui.blocklyText.calliope.loudness": "读取响度值",
    "gui.blocklyText.calliope.rotation": "读取旋转角度(°) [TYPE]",
    "gui.blocklyText.calliope.calliopeControlSinglelMotor": "单电机控制 [RANGE]%",
    "gui.blocklyText.calliope.calliopeSingleMotorStop": "单电机 [STOPTYPE]",
    "gui.blocklyText.calliope.calliopeDoubleMotor": "控制电机 [MOTORTYPE] [RANGE]%",
    "gui.blocklyText.calliope.calliopeDoubleMotorStop": "停止电机 [MOTORTYPE]",
    "gui.blocklyText.calliope.calliopeLight": "设置LED为 [COLOR]",
    "gui.blocklyText.calliope.setRGBColor": "红 [RED] 绿 [GREEN] 蓝 [BLUE] 白 [WHITE]",
    "gui.blocklyText.calliope.calliopeCloseRGB": "关闭内置LED",
    "gui.blocklyText.calliope.pitch": "旋转",
    "gui.blocklyText.calliope.roll": "横滚",
    "gui.blocklyText.calliope.boardbuzzer": "板载蜂鸣器",
    "gui.blocklyText.calliope.motorbreak": "停止",
    "gui.blocklyText.calliope.motorcoast": "滑行",
    "gui.blocklyText.calliope.motorsleep": "睡眠",
    "gui.blocklyText.calliope.open": "打开",
    "gui.blocklyText.calliope.close": "关闭",
    "gui.blocklyText.bos0001.readRotary": "读取[PIN]旋钮模块值",
    "gui.blocklyText.bos0002.IsPINPress": "[PIN]按钮按下?",
    "gui.blocklyText.bos0003.IsPINPress": "[PIN]自锁开关按下？",
    "gui.blocklyText.bos0004.readLight": "读取[PIN]光线模块值",
    "gui.blocklyText.bos0006.readMoisture": "读取[PIN]水分模块值",
    "gui.blocklyText.bos0007.readFlame": "读取[PIN]火焰模块值",
    "gui.blocklyText.bos0008.IsPINTouch": "[PIN]触摸接通?",
    "gui.blocklyText.bos0009.readSound": "读取[PIN]声音模块值",
    "gui.blocklyText.bos0010.readSmartGray": "读取[PIN]灰度模块值",
    "gui.blocklyText.bos0012.IsConductivitySwitch": "[PIN]电导模块接通?",
    "gui.blocklyText.bos0013.IsMotionSensor": "[PIN]运动模块触发?",
    "gui.blocklyText.bos0038.readSoilHumidity": "读取[PIN]土壤湿度值",
    "gui.blocklyText.bos0045.readUltrasonicDistance": "读取[PIN]超声波测距值",
    "gui.blocklyText.bos0016.lightBrightLED": "[CONTROL][PIN]高亮LED灯",
    "gui.blocklyText.bos0017.LEDModule": "[CONTROL][PIN]LED灯",
    "gui.blocklyText.bos0019.LEDStringLight": "[CONTROL][PIN]固定色灯带",
    "gui.blocklyText.bos0021.fanModule": "[CONTROL][PIN]风扇",
    "gui.blocklyText.bos0022.voiceRecorderModule": "[PIN]播放一次录音",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot 主程序开始",
    "gui.blocklyText.maxbot.playSound": "播放声音 [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "播放声音 [SOUND] 直到结束",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "[LEFTRIGHT]发生碰撞",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "[LEFTRIGHT]检测到黑线",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "[MOTOR] 电机以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "读取(P1,P2)超声波传感器距离 单位 [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "读取超声波传感器距离 单位 [UNIT] trig为 [TRIG] echo为 [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "向前",
    "gui.blocklyText.maxbot.BACKWARD": "向后",
    "gui.blocklyText.motorbit.CW": "正转",
    "gui.blocklyText.motorbit.CCW": "反转",
    "gui.blocklyText.maxbot.CW": "正转",
    "gui.blocklyText.maxbot.CCW": "反转",
    "gui.blocklyText.maxbot.ANGLE": "角度",
    "gui.blocklyText.maxbot.CIRCLE": "圈数",
    "gui.blocklyText.maxbot.Left": "左侧",
    "gui.blocklyText.maxbot.Right": "右侧",
    "gui.blocklyText.maxbot.cm": "厘米",
    "gui.blocklyText.maxbot.inch": "英寸",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit 主程序开始",
    "gui.blocklyText.MotorBit.MotorRun": "电机 [MOTOR] 以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.MotorBit.MotorStop": "电机 [MOTOR] 停止",
    "gui.blocklyText.MotorBit.Stepper": "设置步进电机 [STEPPER] [ROTATEDIR] [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.pythondfr0548.MotorRun": "电机 [MOTOR] 以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.pythondfr0548.MotorStop": "电机 [MOTOR] 停止",
    "gui.blocklyText.pythondfr0548.Stepper": "设置步进电机 [STEPPER] [ROTATEDIR] [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "全部",
    "gui.blocklyText.pythonspread.extensionName": "两用扩展板",
    "gui.blocklyText.pythonMotorBit.MotorRun": "电机 [MOTOR] 以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.pythonMotorBit.MotorStop": "电机 [MOTOR] 停止",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen 主程序开始",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "读取(P1 P2)超声波距离(cm)",
    "gui.blocklyText.maqueen.motorStop": "机器人(左侧和右侧电机)停止",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] Led灯",
    "gui.blocklyText.maqueen.redLineSensor": "读取 [LEFTRIGHT] 巡线传感器",
    "gui.blocklyText.maqueen.robotSpeedMove": "机器人以 [SPEED] 的速度 [DIR]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "设置电机 [LEFTRIGHT] 以 [SPEED] 的速度 [DIR]",
    "gui.blocklyText.maqueen.ledLeft": "左侧(P8)",
    "gui.blocklyText.maqueen.ledRight": "右侧(P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "右侧(P12) (硬件不可用)",
    "gui.blocklyText.maqueen.lineSensorLeft": "左侧(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "右侧(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "当(P16)接收到[WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "红外消息",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "读取(P16)红外信号的值",
    "gui.blocklyText.maqueen.numberOfLightsBright": "RGB灯 引脚 P15 灯总数4 亮度 [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "RGB灯 引脚 P15 灯号 [NUM1] 显示颜色 [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "RGB灯 引脚 P15 灯号 [NUM1] 到 [NUM2] 显示渐变颜色 色调从 [COLOR1] 到 [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "RGB灯 引脚 P15 移动 [NUM] 单位",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "RGB灯 引脚 P15 循环移动 [NUM] 单位",
    "gui.blocklyText.maqueen.setWs2812Brightness": "RGB灯 引脚 P15 设置亮度 [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "RGB灯 引脚 P15 灯号 [NUM1] 到 [NUM2] 显示柱状图 当前值 [VALUE] 最大值 [MAX]",
    "gui.blocklyText.maqueen.clear": "RGB灯 引脚 P15 全部熄灭",
    "gui.blocklyText.maqueen.rgbColor": "红 [RED] 绿 [GREEN] 蓝 [BLUE]",
    "gui.blocklyText.tello.telloStarts": "Tello 主程序开始",
    "gui.blocklyText.tello.telloControlPlane": "控制飞机",
    "gui.blocklyText.tello.telloTakeOff": "起飞",
    "gui.blocklyText.tello.telloLanding": "降落",
    "gui.blocklyText.tello.telloRise": "上升 [NUMS]cm",
    "gui.blocklyText.tello.telloDown": "下降 [NUMS]cm",
    "gui.blocklyText.tello.telloGoahead": "向前飞 [NUMS]cm",
    "gui.blocklyText.tello.telloBack": "向后飞 [NUMS]cm",
    "gui.blocklyText.tello.telloTurnLeft": "向左飞 [NUMS]cm",
    "gui.blocklyText.tello.telloTurnRight": "向右飞 [NUMS]cm",
    "gui.blocklyText.tello.telloClockwiseRotation": "顺时针旋转 [NUMS]度",
    "gui.blocklyText.tello.telloCounterclockwiseSelection": "逆时针旋转 [NUMS]度",
    "gui.blocklyText.tello.telloSpeed": "设置速度为 [NUMS]cm/s",
    "gui.blocklyText.tello.telloRoll": "翻滚[ROLL] ",
    "gui.blocklyText.tello.telloPower": "电池电量( %)",
    "gui.blocklyText.tello.telloRollForward": "向前(f)",
    "gui.blocklyText.tello.telloRollBack": "向后(b)",
    "gui.blocklyText.tello.telloRollLeft": "向左(l)",
    "gui.blocklyText.tello.telloRollRight": "向右(r)",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] 接收到消息？",
    "gui.blocklyText.obloq.initialParameter": "Obloq mqtt初始化 [PARAMETER] 接口 [SERIALPORT] Rx(绿) [PINR] Tx(蓝) [PINT]",
    "gui.blocklyText.obloq.initialI2CParameter": "Obloq mqtt 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.obloq.httpInitialI2CParameter": "Obloq http 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq 发送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.obloq.sendmsgSaveToCloudPlatform": "Obloq 发送消息 [MSG] 至 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq 读取来自 [TOPIC] 的消息",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq 读取消息",
    "gui.blocklyText.obloq.httpInitialParameter": "Obloq http初始化 [PARAMETER] 接口 [SERIALPORT] Rx(绿) [PINR] Tx(蓝) [PINT] ",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL]content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "新增订阅 [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "Wi-Fi名称",
    "gui.blocklyText.obloq.password": "Wi-Fi密码",
    "gui.blocklyText.obloq.iotService": "物联网平台参数",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP配置",
    "gui.blocklyText.obloq.ip": "IP地址",
    "gui.blocklyText.obloq.port": "端口号",
    "gui.blocklyText.obloq.addTopic": "添加Topic, 最多只能添加4个",
    "gui.blocklyText.obloq.deleteTopic": "删除 Topic",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "服务器",
    "gui.blocklyText.obloq.global": "全球",
    "gui.blocklyText.obloq.china": "中国",
    "gui.blocklyText.obloq.softwareSerial": "软串口",
    "gui.blocklyText.obloq.hardwareSerial": "硬串口",
    "gui.blocklyText.obloq.hardwareSerial1": "硬串口1",
    "gui.blocklyText.obloq.hardwareSerial2": "硬串口2",
    "gui.blocklyText.obloq.hardwareSerial3": "硬串口3",
    "gui.blocklyText.obloq.WhenReceivedData": "当 [TOPIC] 接收到 [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq消息",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq 获取 [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "调试信息",
    "gui.blocklyText.obloq.ERROR": "错误码",
    "gui.blocklyText.pythonobloq.whenDebugInfo": "[NAME] 获取 [DEBUG] [ERROR]",
    "gui.blocklyText.pythonobloq.WhenReceivedData": "当 [EVENT] [TOPIC] 接收到 [MSG]",
    "gui.blocklyText.pythonobloq.DEBUG": "调试信息",
    "gui.blocklyText.pythonobloq.ERROR": "错误码",
    "gui.blocklyText.pythonobloq.ObloqMsg": "obloq消息",
    "gui.blocklyText.pythonobloq.initialI2CParameter": "[NAME] mqtt 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.pythonobloq.sendmsgToCloudPlatform": "[NAME] 发送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.pythonobloq.isReceivedData": "[NAME] [TOPIC] 接收到消息？",
    "gui.blocklyText.pythonobloq.readMsgFromCloudPlatformUno": "[NAME] 读取来自 [TOPIC] 的消息",
    "gui.blocklyText.pythonobloq.httpInitialI2CParameter": "[NAME] http 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.pythonobloq.httpGet": "[NAME] http(get) url: [URL] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.pythonobloq.httpPost": "[NAME] http(post) url: [URL]content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.pythonobloq.httpPut": "[NAME] http(put) url: [URL] content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "红外发射 [TYPE] 管脚 [PIN] 数字 [VALUE] 比特数 [BIT]",
    "gui.blocklyText.infraredEmission.send": "红外发送 引脚 [PIN] 发送消息：地址 [ADDRESS] 命令 [COMMAND]",
    "gui.blocklyText.oled2864.displayInit": "OLED 128*64初始化I2C地址 [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 第 [LINE] 行 显示 [TEXT]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 在坐标 X: [X] Y: 16*[Y] 显示 [TEXT]",
    "gui.blocklyText.oled2864.screenRotation": "屏幕旋转到[ROTATION]度",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 清屏",
    "gui.blocklyText.dfr0647.displayInit": "OLED 128*32初始化I2C地址 [ADDR]",
    "gui.blocklyText.dfr0647.displayShowTextInXY": "OLED 128*32 显示文字 [TEXT] 在坐标X:[X] Y:[Y]",
    "gui.blocklyText.dfr0647.clear": "OLED 128*32 清屏",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "读取引脚 [PIN] [DHT] [TYPE]",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx引脚 [PIN] PM浓度值 [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 超声波初始化 名称 [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04超声波距离 测量单位 [UNIT]",
    "gui.blocklyText.hcsr04.readData": "读取HC-SRO4 Trig [PINT] Echo [PINE] 单位 [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx引脚 [PIN] 0.1升空气中直径在 [DIAMETER] 颗粒物个数",
    "gui.blocklyText.sen0170.readWindspeed": "读取引脚 [PIN] 风速等级",
    "gui.blocklyText.sen0228.init": "初始化环境光(VEML7700)传感器 I2C地址0x10",
    "gui.blocklyText.sen0228.readAmbientLight": "读取环境光(VEML7700)",
    "gui.blocklyText.pythonsen0228.init": "初始化环境光(VEML7700)传感器[NAME] I2C地址0x10",
    "gui.blocklyText.pythonsen0228.readAmbientLight": "读取[NAME]环境光(VEML7700)",
    "gui.blocklyText.sen0097.init": "初始化环境光(BH1750)传感器 I2C地址0x23",
    "gui.blocklyText.sen0097.readAmbientLight": "读取环境光(BH1750)",
    "gui.blocklyText.dfr0026.readAmbientLight": "读取引脚 [PIN] 环境光",
    "gui.blocklyText.pythondfr0026.extensionName": "环境光",
    "gui.blocklyText.pythondfr0026.readAmbientLight": "读取引脚 [PIN] 环境光",
    "gui.blocklyText.sen0177.readDustSensor": "读取 [TYPE] (ug/m3) 接口 [SERIALPORT] Rx [PINR] Tx [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "引脚(D) [PIN] 引脚(MCLR) [RST] 当前姿态为 [TYPE]？",
    "gui.blocklyText.sen0203.readHeartRate": "读取引脚 [PIN] [TYPE] 拨向 [SWITCH]",
    "gui.blocklyText.pythonsen0203.init": "初始化心率传感器 [NAME] 引脚 [PIN] 模式 [MODE]",
    "gui.blocklyText.pythonsen0203.update": "[NAME] 获取一次数据",
    "gui.blocklyText.pythonsen0203.readHeartRate": "[NAME] 从数据中解析心率值（次/分）",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] 引脚 [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "读取引脚 [PIN] 灰度值",
    "gui.blocklyText.pythondfr0022.extensionName": "灰度",
    "gui.blocklyText.pythondfr0022.readGrayscale": "读取引脚 [PIN] 灰度值",
    "gui.blocklyText.motucamera.initIICSerial": "初始化 [MU] 端口 [IICSERIAL] 直到成功 I2C地址0x60&0x61&0x62&0x63",
    "gui.blocklyText.motucamera.resetConfig": "[MU] 恢复默认设置",
    "gui.blocklyText.motucamera.algorithmSwitch": "[MU] [SWITCH] 算法 [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "[MU] led [LED] 识别到颜色 [COLOR1] 未识别到颜色 [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "[MU] 设置算法 [ALGORITHM] 性能 [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "[MU] 数码变焦 [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "[MU] 设置串口波特率 [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "[MU] 设置摄像头白平衡 [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "[MU] 设置高帧率模式 [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "[MU] 检测到 [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": "[MU] 检测到 颜色识别 x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "[MU] 检测到 色块检测 颜色= [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "获取 [MU] 算法 [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "获取 [MU] 颜色识别 [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "[MU] 算法 形状卡片 [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "[MU] 算法 交通卡片 [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "[MU] 算法 数字卡片 [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "[MU] 算法 颜色识别 颜色 = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "色块检测",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "颜色识别",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "球体检测",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "人体检测",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "形状卡片",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "交通卡片",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "数字卡片",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "启用",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "关闭",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "关",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "红",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "绿",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "蓝",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "黄",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "紫",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "青",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "白",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "关",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "红",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "绿",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "蓝",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "黄",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "紫",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "青",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "白",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "自动",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "速度优先",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "性能均衡",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "准确率优先",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "自动",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "锁定白平衡",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "白光模式",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "黄光模式",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "自动",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "等级1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "等级2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "等级3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "等级4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "等级5",
    "gui.blocklyText.motucamera.setHighFPSOff": "关",
    "gui.blocklyText.motucamera.setHighFPSOn": "开",
    "gui.blocklyText.motucamera.colorDetectBlack": "黑",
    "gui.blocklyText.motucamera.colorDetectWhite": "白",
    "gui.blocklyText.motucamera.colorDetectRed": "红",
    "gui.blocklyText.motucamera.colorDetectYellow": "黄",
    "gui.blocklyText.motucamera.colorDetectGreem": "绿",
    "gui.blocklyText.motucamera.colorDetectCayan": "青",
    "gui.blocklyText.motucamera.colorDetectBlue": "蓝",
    "gui.blocklyText.motucamera.colorDetectPerple": "紫",
    "gui.blocklyText.motucamera.colorDetectOthers": "其他",
    "gui.blocklyText.motucamera.colorDetectPositionX": "横向坐标",
    "gui.blocklyText.motucamera.colorDetectPositionY": "纵向坐标",
    "gui.blocklyText.motucamera.colorDetectPositionW": "宽度",
    "gui.blocklyText.motucamera.colorDetectPositionH": "高度",
    "gui.blocklyText.motucamera.colorDetectPositionL": "标签",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "红色通道",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "绿色通道",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "蓝色通道",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "标签",
    "gui.blocklyText.motucamera.shapeCardTick": "钩",
    "gui.blocklyText.motucamera.shapeCardCross": "叉",
    "gui.blocklyText.motucamera.shapeCardCircle": "圆形",
    "gui.blocklyText.motucamera.shapeCardSquare": "方形",
    "gui.blocklyText.motucamera.shapeCardTriangle": "三角形",
    "gui.blocklyText.motucamera.trafficCardForward": "向前",
    "gui.blocklyText.motucamera.trafficCardLeft": "向左",
    "gui.blocklyText.motucamera.trafficCardRight": "向右",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "掉头",
    "gui.blocklyText.motucamera.trafficCardStop": "停车",
    "gui.blocklyText.motucamera.lightSensorUse": "[MU] 光线传感器 启用 [LIGHTFUNC]",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "[MU] 光线传感器 设置 灵敏度 [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "[MU] 光线传感器 读取 接近检测",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "[MU] 光线传感器 读取 环境光检测",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "[MU] 光线传感器 读取一次手势存入结果",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "[MU] 光线传感器 手势= [GESTURE]",
    "gui.blocklyText.motucamera.upward": "上划",
    "gui.blocklyText.motucamera.downward": "下划",
    "gui.blocklyText.motucamera.leftward": "左划",
    "gui.blocklyText.motucamera.rightward": "右划",
    "gui.blocklyText.motucamera.pull": "前进",
    "gui.blocklyText.motucamera.push": "后退",
    "gui.blocklyText.motucamera.none": "未知",
    "gui.blocklyText.motucamera.default": "默认",
    "gui.blocklyText.motucamera.low": "低",
    "gui.blocklyText.motucamera.middle": "中",
    "gui.blocklyText.motucamera.high": "高",
    "gui.blocklyText.motucamera.proximity": "接近检测",
    "gui.blocklyText.motucamera.ambientLight": "环境光检测",
    "gui.blocklyText.motucamera.gesture": "手势检测",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi 初始化 端口 [SERIALPORT] RX[RX] TX[TX] 串口波特率 [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi 设置 名称 [SSID] 密码 [PASSWORD] 模式 [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi 连接成功？",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi 断开连接",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi 设置目标 IP [IP] 端口 [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi 读取 目标IP",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi 读取 本地IP",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi 读取",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi 写入 [WRITE]",
    "gui.blocklyText.motucamera.client": "客户端",
    "gui.blocklyText.motucamera.hot-spot": "热点",
    "gui.blocklyText.dfr0552.init": "DA模块 初始化I2C地址 [ADDR] 参考电压 [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "DA模块 输出三角波 幅度 [AMPLITUDE]mV 频率 [FREQ]Hz 直流偏置 [DCBIAS]mV 占空比 [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "DA模块 输出正弦波 幅度 [AMPLITUDE]mV 频率 [FREQ]Hz 直流偏置 [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "DA模块 输出电压 [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "DA模块 记忆电压 [VOLTAGE]mV",
    "gui.blocklyText.dfr0699.init": "音频录放模块传感器初始化地址 [ADDR] 等待直到成功",
    "gui.blocklyText.dfr0699.chooseAudioNum": "选择 [AUDIO] 号音频",
    "gui.blocklyText.dfr0699.getAudioNum": "获取当前录音号",
    "gui.blocklyText.dfr0699.reRecord": "重新录音",
    "gui.blocklyText.dfr0699.startRecording": "开始录音",
    "gui.blocklyText.dfr0699.recordingEnd": "录音结束",
    "gui.blocklyText.dfr0699.recordingIsExist": "录音是否存在?",
    "gui.blocklyText.dfr0699.recordingPlay": "播放录音",
    "gui.blocklyText.dfr0699.waitRecordingPlay": "等待直到录音播放结束",
    "gui.blocklyText.dfr0699.fingerprintSpeechSynthesisMode": "语音合成语音 [VALUE]  文本 [STR] 数字 [MODE]",
    "gui.blocklyText.dfr0699.china": "中文",
    "gui.blocklyText.dfr0699.english": "英文",
    "gui.blocklyText.dfr0699.fingerprintSpeechSynthesis": "语音合成语音 [VALUE]  数字 [NUM]",
    "gui.blocklyText.dfr0699.alternately": "语音替换模式",
    "gui.blocklyText.dfr0699.synthesis": "语音合成模式",
    "gui.blocklyText.pythonsen0334.SHT31Init": "SHT31-F数字温湿度传感器初始化I2C地址[ADDRESS]等待直到成功",
    "gui.blocklyText.pythonsen0334.SHT31GetData": "读取SHT31-F的数据：[DATA]",
    "gui.blocklyText.pythonsen0334.TemperC": "温度（摄氏度℃）",
    "gui.blocklyText.pythonsen0334.TemperF": "温度（华氏度℉）",
    "gui.blocklyText.pythonsen0334.HumidRH": "湿  度 (%RH)",
    "gui.blocklyText.pythonsen0334.Number": "芯片序列号",
    "gui.blocklyText.pythonsen0460.extensionName": "粉尘浓度",
    "gui.blocklyText.pythonsen0460.begin": "初始化设备直到成功 I2C地址为0x19",
    "gui.blocklyText.pythonsen0460.readConcentration": "读取 [ENVIRONMENT] 环境下 [KAIGUAN] 颗粒物浓度（ug/m³）",
    "gui.blocklyText.pythonsen0460.readCount": "读取每0.1L空气中粒径为 [PARTICLEDIAMETER] 的颗粒物个数",
    "gui.blocklyText.pythonsen0460.readDeviceVersion": "获取传感器版本号",
    "gui.blocklyText.pythonsen0460.PM2_5": "PM2.5",
    "gui.blocklyText.pythonsen0460.PM1_0": "PM1.0",
    "gui.blocklyText.pythonsen0460.PM10": "PM10",
    "gui.blocklyText.pythonsen0460.standard": "标准颗粒物",
    "gui.blocklyText.pythonsen0460.atmospheric": "大气",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_0_3": "0.3um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_0_5": "0.5um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_1_0": "1.0um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_2_5": "2.5um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_5_0": "5.0um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_10": "10um",
    "gui.blocklyText.pythonsen0303.VEML6075Init": "初始化 VEML6075 紫外线传感器等待直到成功",
    "gui.blocklyText.pythonsen0303.VEML6075ReadyUV": "读取 VEML6075 [UV]强度",
    "gui.blocklyText.pythonsen0303.VEML6075Ready": "读取 VEML6075 紫外线指数",
    "gui.blocklyText.pythonsen0303.VEML6075ReadyME": "读取 VEML6075 辐射出射度mw/cm^2",
    "gui.blocklyText.pythonsen0303.UVA": "长波紫外线(UVA)",
    "gui.blocklyText.pythonsen0303.UVB": "中波紫外线(UVB)",
    "gui.blocklyText.pythonsen0377.MEMSgas_Iint": "MEMS气体传感器初始化 地址A0[A0] A1[A1]",
    "gui.blocklyText.pythonsen0377.MEMSgaswarm_up_time": "MEMS气体传感器设置唤醒时间[MIN]分钟",
    "gui.blocklyText.pythonsen0377.MEMSgas_get_power_mode": "MEMS气体传感器获取传感器是否为睡眠模式？",
    "gui.blocklyText.pythonsen0377.MEMSgasset_mode": "MEMS气体传感器设置模式[MODE]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_adc_data": "MEMS气体传感器获取传感器ADC数据[DATA]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_gas_exist": "MEMS气体传感器判断是否存在[TYPE]?",
    "gui.blocklyText.pythonsen0377.MEMSgasget_gas_ppm": "MEMS气体传感器获取[TYPE]数据",
    "gui.blocklyText.pythonsen0377.wakeup_mode": "唤醒模式",
    "gui.blocklyText.pythonsen0377.sleep_mode": "睡眠模式",
    "gui.blocklyText.pythonsen0377.OX_MODE": "氧化模式",
    "gui.blocklyText.pythonsen0377.RED_MODE": "还原模式",
    "gui.blocklyText.pythonsen0377.CO": "一氧化碳",
    "gui.blocklyText.pythonsen0377.CH4": "甲烷",
    "gui.blocklyText.pythonsen0377.C2H5OH": "乙醇",
    "gui.blocklyText.pythonsen0377.C3H8": "丙烷",
    "gui.blocklyText.pythonsen0377.C4H10": "异丁烷",
    "gui.blocklyText.pythonsen0377.H2": "氢气",
    "gui.blocklyText.pythonsen0377.H2S": "硫化氢",
    "gui.blocklyText.pythonsen0377.NH3": "氨气",
    "gui.blocklyText.pythonsen0377.NO": "一氧化氮",
    "gui.blocklyText.pythonsen0377.NO2": "二氧化氮",
    "gui.blocklyText.sen0117.init": "初始模块接口 [SERIALPORT] Rx[RX] Tx[TX] Busy[BUSY]",
    "gui.blocklyText.sen0117.playText": "播放文字 [TEXT] 背景音乐 [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "播放声音提示音 [INDEX] 等待结束 [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "播放和弦提示音 [INDEX] 等待结束 [WAIT]",
    "gui.blocklyText.sen0117.playChordCueTone": "播放和弦铃声 [INDEX] 等待结束 [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "设置 [INDEX] 为 [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "设置语速为 [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "获取语音合成模块 [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "前景音量",
    "gui.blocklyText.sen0117.Background": "背景音量",
    "gui.blocklyText.sen0117.Speed": "语速",
    "gui.blocklyText.sen0117.No": "无 (0)",
    "gui.blocklyText.sen0117.setPlayMode": "设置播放模式为 [MODE]",
    "gui.blocklyText.sen0117.pause": "暂停",
    "gui.blocklyText.sen0117.resume": "继续",
    "gui.blocklyText.sen0117.stop": "停止",
    "gui.blocklyText.dfr0760.init": "初始化语音合成模块 I2C模式 [VERSION] I2C地址0x40",
    "gui.blocklyText.dfr0760.syntheticVoice": "合成语音 [VOICEVALUE]",
    "gui.blocklyText.dfr0760.flashStorage": "使用flash存储 [FALSH]",
    "gui.blocklyText.dfr0760.softwareSerial": "初始化 [VERSION] 语音合成模块接口 [SERIALPORT] Rx(绿)[PINR] Tx(蓝)[PINT]",
    "gui.blocklyText.dfr0760.speechSynthesisSettings": "设置 音量 [VOLUME]语速 [SPEED]语调 [INTONATION]发音人 [SPEEKER]",
    "gui.blocklyText.dfr0760.setEnglishPron": "设置单词发音方式 [Pron]",
    "gui.blocklyText.dfr0760.letter": "字母",
    "gui.blocklyText.dfr0760.word": "单词",
    "gui.blocklyText.dfr0760.manvoice1": "男声1(V1)",
    "gui.blocklyText.dfr0760.manvoice2": "男声2(V1)",
    "gui.blocklyText.dfr0760.womanvoice1": "女声1(V1)",
    "gui.blocklyText.dfr0760.womanvoice2": "女声2(V1)",
    "gui.blocklyText.dfr0760.womanvoice3": "女声3(V1)",
    "gui.blocklyText.dfr0760.donaldDuck": "唐老鸭(V1)",
    "gui.blocklyText.pythondfr0760.extensionName": "语音合成",
    "gui.blocklyText.pythondfr0760.init": "初始化语音合成模块 I2C模式 [VERSION] I2C地址0x40",
    "gui.blocklyText.pythondfr0760.syntheticVoice": "合成语音 [VOICEVALUE]",
    "gui.blocklyText.pythondfr0760.flashStorage": "使用flash存储 [FALSH]",
    "gui.blocklyText.pythondfr0760.speechSynthesisSettings": "设置 音量 [VOLUME]语速 [SPEED]语调 [INTONATION]发音人 [SPEEKER]",
    "gui.blocklyText.pythondfr0760.setEnglishPron": "设置单词发音方式 [Pron]",
    "gui.blocklyText.pythondfr0760.setNumberPron": "设置数字发音方式 [Pron]",
    "gui.blocklyText.pythondfr0760.manvoice1": "男声1(V1)",
    "gui.blocklyText.pythondfr0760.manvoice2": "男声2(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice1": "女声1(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice2": "女声2(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice3": "女声3(V1)",
    "gui.blocklyText.pythondfr0760.donaldDuck": "唐老鸭(V1)",
    "gui.blocklyText.pythondfr0760.auto": "自动",
    "gui.blocklyText.pythondfr0760.number": "号码",
    "gui.blocklyText.pythondfr0760.value": "数值",
    "gui.blocklyText.dfr0715.loop": "循环模式",
    "gui.blocklyText.dfr0715.password": "指令模式",
    "gui.blocklyText.dfr0715.button": "按钮模式",
    "gui.blocklyText.dfr0715.mic": "默认",
    "gui.blocklyText.dfr0715.word": "外部音频",
    "gui.blocklyText.dfr0715.ASRInit": "语音识别模块 初始化 识别模式为 [MODE]麦克风模式为 [PHONEMODE] I2C地址为0x4F",
    "gui.blocklyText.dfr0715.addKeywords": "添加关键词 [KEYWORD]编号为 [NUMBER]",
    "gui.blocklyText.dfr0715.beginToIdentify": "设置完成 开始识别",
    "gui.blocklyText.dfr0715.recognitionOfASpeech": "识别一次语音，获取编号",
    "gui.blocklyText.dfr0646.tmi1650Init": "初始化TMI1650显示器 I2C地址 [ADDRESS]",
    "gui.blocklyText.dfr0646.eightTubeMode": "八位数码管_TM1650 [TYPE]",
    "gui.blocklyText.dfr0646.eightTubeShowInput": "八位数码管_TM1650显示字符串 [TEXT]",
    "gui.blocklyText.dfr0646.eightTubeControlPoint": "八位数码管_TM1650第[ORDER] 个小数点 [STATUS]",
    "gui.blocklyText.pythondfr0715.extensionName": "语音识别",
    "gui.blocklyText.pythondfr0715.ASRInit": "语音识别模块 初始化 识别模式为 [MODE]麦克风模式为 [PHONEMODE] I2C地址为0x4F",
    "gui.blocklyText.pythondfr0715.addKeywords": "添加关键词 [KEYWORD]编号为 [NUMBER]",
    "gui.blocklyText.pythondfr0715.beginToIdentify": "设置完成 开始识别",
    "gui.blocklyText.pythondfr0715.recognitionOfASpeech": "识别一次语音，获取编号",
    "gui.blocklyText.sen0117.isIdle": "播放完成?",
    "gui.blocklyText.dfr0576.selectPort": "选通端口 [PORT]",
    "gui.blocklyText.dfr0576.begin": "初始化I2C级联模块地址为 [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680初始化 I2C地址0x77",
    "gui.blocklyText.sen0248.readBME680": "获取BME680传感器 [TYPE]",
    "gui.blocklyText.pythonsen0248.bme680Init": "BME680初始化 I2C地址0x77",
    "gui.blocklyText.pythonsen0248.updateBme680": "获取一次BME680传感器数据",
    "gui.blocklyText.pythonsen0248.readBME680": "从数据中解析 [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "温度 (°C)",
    "gui.blocklyText.sen0248.Pressure": "气压 (Pa)",
    "gui.blocklyText.sen0248.Humidity": "湿度 (%rh)",
    "gui.blocklyText.sen0248.Altitude": "海拔 (m)",
    "gui.blocklyText.sen0248.GasResistance": "气体电阻值 (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "初始化I2C超声波传感器地址为 [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "更改I2C超声波传感器地址为 [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "读取I2C超声波传感器距离(cm)",
    "gui.blocklyText.sen0304.readTemperature": "读取I2C超声波传感器温度(°C)",
    "gui.blocklyText.pythonsen0304.extensionName": "超声波（I2C）",
    "gui.blocklyText.pythonsen0304.initialIICAddr": "初始化I2C超声波传感器 [NAME] 地址为 [ADDR]",
    "gui.blocklyText.pythonsen0304.changeI2CAddr": "更改I2C超声波传感器 [NAME] 地址为 [ADDR] (断电重启生效)",
    "gui.blocklyText.pythonsen0304.readDistance": "读取I2C超声波传感器 [NAME] 距离(cm)",
    "gui.blocklyText.pythonsen0304.readTemperature": "读取I2C超声波传感器 [NAME] 温度(°C)",
    "gui.blocklyText.sen0307.readDistance": "读取 [PIN] 模拟量超声波(cm)",
    "gui.blocklyText.pythonsen0307.extensionName": "超声波(模拟量)",
    "gui.blocklyText.pythonsen0307.init": "模拟量超声波[NAME] 初始化 引脚[PIN] ",
    "gui.blocklyText.pythonsen0307.readDistance": "[NAME] 读取模拟量超声波(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 初始化 地址 [ADDR] 模式 [MODE]",
    "gui.blocklyText.sen0250.Step": "计步",
    "gui.blocklyText.sen0250.Measure": "测量",
    "gui.blocklyText.sen0250.readAccelerated": "BMI160 获取 [AXIS] 轴加速度值(g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 获取步数量",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "陀螺仪",
    "gui.blocklyText.sen0250.Acc": "加速度 (g)",
    "gui.blocklyText.sen0224.init": "初始化LIS2DH传感器 I2C地址0x18",
    "gui.blocklyText.sen0224.readAccelerated": "读取LIS2DH传感器 [AXIS] 轴加速度值(mg)",
    "gui.blocklyText.dfr0023.readLM35": "读取引脚 [PIN] LM35温度(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "当引脚 [PIN] 检测到震动时",
    "gui.blocklyText.dfr0028.readTiltSensor": "引脚 [PIN] 检测到倾斜？",
    "gui.blocklyText.pythondfr0028.extensionName": "倾斜",
    "gui.blocklyText.pythondfr0028.readTiltSensor": "引脚 [PIN] 检测到倾斜？",
    "gui.blocklyText.dfr0029.readPress": "引脚 [PIN] 按钮被按下？",
    "gui.blocklyText.pythondfr0029.extensionName": "按钮",
    "gui.blocklyText.pythondfr0029.readPress": "引脚 [PIN] 按钮被按下？",
    "gui.blocklyText.dfr0030.readTouch": "引脚 [PIN] 检测到触摸？",
    "gui.blocklyText.pythondfr0030.extensionName": "触摸",
    "gui.blocklyText.pythondfr0030.readTouch": "引脚 [PIN] 检测到触摸？",
    "gui.blocklyText.dfr0033.readMagnetic": "引脚 [PIN] 检测到磁铁？",
    "gui.blocklyText.pythondfr0033.extensionName": "磁力",
    "gui.blocklyText.pythondfr0033.readMagnetic": "引脚 [PIN] 检测到磁铁？",
    "gui.blocklyText.dfr0034.readSound": "读取引脚 [PIN] 声音强度",
    "gui.blocklyText.pythondfr0034.extensionName": "声音强度",
    "gui.blocklyText.pythondfr0034.readSound": "读取引脚 [PIN] 声音强度",
    "gui.blocklyText.dfr0094.readIRReceived": "读取引脚 [PIN] 红外接收值",
    "gui.blocklyText.dfr0094.onReceiveEvent": "当红外接收到信号 引脚 [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "地址",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "命令",
    "gui.blocklyText.midi.midiInit": "MIDI初始化 [SERIALPORT] 引脚RX [RX] 引脚TX [TX]",
    "gui.blocklyText.midi.midiOutput": "MIDI 输出 通道 [CHANNEL] 音高 [PITCH] 速度 [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "读取引脚 [PIN] 一氧化碳气体传感器",
    "gui.blocklyText.pythonsen0132.extensionName": "CO气体",
    "gui.blocklyText.pythonsen0132.readCarbonMonoxide": "读取引脚 [PIN] 一氧化碳气体传感器",
    "gui.blocklyText.dfr0051.readVoltage": "读取引脚 [PIN] 电压值(V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "读取引脚 [PIN] 压电陶瓷震动传感器",
    "gui.blocklyText.pythondfr0052.extensionName": "震动",
    "gui.blocklyText.pythondfr0052.readPiezoSensor": "读取引脚 [PIN] 压电陶瓷震动传感器",
    "gui.blocklyText.dfr0058.readRotation": "读取引脚 [PIN] 多圈旋转角度传感器",
    "gui.blocklyText.pythondfr0058.extensionName": "旋转角度",
    "gui.blocklyText.pythondfr0058.readRotation": "读取引脚 [PIN] 多圈旋转角度传感器",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "读取引脚 [PIN] 摇杆 [AXIS] 轴",
    "gui.blocklyText.dfr0061.readJoyStickButton": "引脚 [PIN] 摇杆按钮(Z)被按下？",
    "gui.blocklyText.pythondfr0061.extensionName": "摇杆",
    "gui.blocklyText.pythondfr0061.readJoyStickAxis": "读取引脚 [PIN] 摇杆 [AXIS] 轴",
    "gui.blocklyText.pythondfr0061.readJoyStickButton": "引脚 [PIN] 摇杆按钮(Z)被按下？",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut初始化数据引脚 [DATAPIN] 时钟引脚 [RTCPIN] 片选引脚 [CLSPIN] 字节序 [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "数码管显示段码0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "数码管显示数字 [NUM]",
    "gui.blocklyText.dfr0072.numOff": "关闭",
    "gui.blocklyText.dfr0072.dfr0072Dot": "数码管显示点 [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "是",
    "gui.blocklyText.dfr0072.dotFalse": "否",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "读取锂电池 [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "电压(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "剩余电量(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD模块 初始化I2C地址 [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD模块 读取 [PIN] 电压 (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD模块 获取通道 [AISLE] 电压差",
    "gui.blocklyText.pythondfr0553.dfr0553InitIICAddr": "AD模块[NAME] 初始化I2C地址 [ADDR]",
    "gui.blocklyText.pythondfr0553.dfr0553ReadVoltage": "AD模块[NAME] 读取 [PIN] 电压 (mV)",
    "gui.blocklyText.pythondfr0553.dfr0553GetAisleVoltageDifference": "AD模块[NAME] 获取通道 [AISLE] 电压差",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 和 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 和 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 和 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 和 3",
    "gui.blocklyText.dfr0588.initPin": "初始化 温度引脚 [TEM] 湿度引脚 [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "获取温度 单位 [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "获取湿度(%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "摄氏度",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "华氏度",
    "gui.blocklyText.dfr0076.readFlameSensor": "读取引脚 [PIN] 火焰传感器",
    "gui.blocklyText.pythondfr0076.extensionName": "火焰",
    "gui.blocklyText.pythondfr0076.readFlameSensor": "读取引脚 [PIN] 火焰传感器",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom 获取 [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom 初始化I2C地址 [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom 写入文本 [VALUE] 名称 [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom 写入数字 [VALUE] 名称 [KEY] ",
    "gui.blocklyText.dfr0117.text": "文本",
    "gui.blocklyText.dfr0117.number": "数字",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom 读取文本 名称 [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom 读取数字 名称 [KEY]",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom 删除 名称 [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom 串口打印全部名称 波特率 [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom 名称 [KEY] 存在?",
    "gui.blocklyText.dfr0117.eepromFormat": "i2c eeprom 格式化",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom 名称 [KEY] 的值是 [TYPE] 类型?",
    "gui.blocklyText.dfr0117.nameCount": "名称个数",
    "gui.blocklyText.dfr0117.memorySize": "储存空间(byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "剩余储存空间(byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": "读取引脚 [PIN] 三轴加速度计 [AXIS] 轴",
    "gui.blocklyText.sen0018.readInfraredMotion": "读取引脚 [PIN] 人体红外热释电运动传感器",
    "gui.blocklyText.pythonsen0018.extensionName": "运动",
    "gui.blocklyText.pythonsen0018.readInfraredMotion": "读取引脚 [PIN] 人体红外热释电运动传感器?",
    "gui.blocklyText.sen0014.readDistance": "读取引脚 [PIN] Sharp红外测距传感器(mm)",
    "gui.blocklyText.sen0114.readMoisture": "读取引脚 [PIN] 土壤湿度传感器",
    "gui.blocklyText.pythonsen0114.extensionName": "土壤湿度",
    "gui.blocklyText.pythonsen0114.readMoisture": "读取引脚 [PIN] 土壤湿度传感器",
    "gui.blocklyText.sen0121.readSteam": "读取引脚 [PIN] 水分传感器",
    "gui.blocklyText.pythonsen0121.extensionName": "水分",
    "gui.blocklyText.pythonsen0121.readSteam": "读取引脚 [PIN] 水分传感器",
    "gui.blocklyText.sen0204.readLiquidLevel": "引脚 [PIN] 液位到达？",
    "gui.blocklyText.pythonsen0204.extensionName": "液位",
    "gui.blocklyText.pythonsen0204.readLiquidLevel": "引脚 [PIN] 液位到达？",
    "gui.blocklyText.sen0203.heartRate": "心率(次/分)",
    "gui.blocklyText.sen0203.bloodOxygen": "血氧饱和度(HbO2)",
    "gui.blocklyText.sen0202.up": "向上",
    "gui.blocklyText.sen0202.down": "向下",
    "gui.blocklyText.sen0202.left": "向左",
    "gui.blocklyText.sen0202.right": "向右",
    "gui.blocklyText.sen0202.cw": "顺时针",
    "gui.blocklyText.sen0202.ccw": "逆时针",
    "gui.blocklyText.dhtTHSensor.temperature": "温度(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "湿度(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "RGB灯 引脚 [PIN] 灯号 [NUM1] 到 [NUM2] 显示颜色 [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "红 [RED] 绿 [GREEN] 蓝 [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "RGB灯 引脚 [PIN] 灯号 [NUM1] 到 [NUM2] 显示渐变颜色 色调从 [COLOR1] 到 [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "RGB灯 引脚 [PIN] 移动 [NUM] 单位",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "RGB灯 引脚 [PIN] 循环移动 [NUM] 单位",
    "gui.blocklyText.ws2812.numberOfLightsBright": "初始化 RGB灯 引脚 [PIN] 灯总数 [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "RGB灯 引脚 [PIN] 灯总数 亮度 [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "RGB灯 引脚 [PIN] 全部熄灭",
    "gui.blocklyText.ws2812.showHistogram": "RGB灯 引脚 [PIN] 灯号 [NUM1] 到 [NUM2] 显示柱状图 当前值 [VALUE] 最大值 [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "RGB灯 设置引脚 [PIN] 灯带亮度为 [BRIGHTNESS]",
    "gui.blocklyText.neopixel.init": "灯带初始化 名称 [NAME] 引脚 [PIN] 数量 [COUNT]",
    "gui.blocklyText.neopixel.setColor": "灯带 [NAME] [INDEX] 号 颜色为 [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "灯带 [NAME] [INDEX] 号 红 [R] 绿 [G] 蓝 [B]",
    "gui.blocklyText.neopixel.fillColor": "灯带 [NAME] 全亮 颜色为 [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "灯带 [NAME] 全亮 红 [R] 绿 [G] 蓝 [B]",
    "gui.blocklyText.neopixel.flush": "灯带 [NAME] 设置生效",
    "gui.blocklyText.neopixel.off": "灯带 [NAME] 关闭",
    "gui.blocklyText.neopixel.setBrightness": "灯带 [NAME] 设置亮度为 [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "彩虹灯效 灯带 [NAME] 数量 [COUNT] 亮度 [BRIGHTNESS] 偏移 [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7（掌控板）",
    "gui.blocklyText.neopixel.neopixel_P9": "P9（掌控实验箱）",
    "gui.blocklyText.dsTSensor.ds18b20": "读取引脚 [PIN] DS18B20温度(℃)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280初始化 I2C地址0x77",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "读取BMP280传感器 [TYPE] 的值",
    "gui.blocklyText.pythonsen0372.bmp280Init": "BMP280 [NAME] 初始化 I2C地址[ADDRESS]",
    "gui.blocklyText.pythonsen0372.readBmpSensor": "读取BMP280传感器[NAME] [TYPE] 的值",
    "gui.blocklyText.bmpTSensor.atmosPressure": "气压(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "海拔(m)",
    "gui.blocklyText.bmpTSensor.temperature": "温度(℃)",
    "gui.blocklyText.huskylens.init": "HuskyLens 初始化引脚为 [SETTING] 直到成功",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens 切换到 [ALGORITHM] 算法 直到成功",
    "gui.blocklyText.huskylens.requestFirst": "HuskyLens 请求一次数据 存入结果",
    "gui.blocklyText.huskylens.resultGetIsStudy": "HuskyLens 从结果中获取ID [IDNUM] 是否已学习？",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 是否在画面中？",
    "gui.blocklyText.huskylens.resultGetIDParameter": "HuskyLens 从结果中获取ID [IDNUM] [TYPE] 的参数 [PARAMETER]",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens 从结果中获取 已学ID总数",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens 从结果中获取 [BOXARROW] 总数",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 总数",
    "gui.blocklyText.huskylens.resultGetIDofWhichParameter": "HuskyLens 从结果中获取ID [IDNUM] 第 [BOXNUM] 个[TYPE]的参数 [PARAMETER]",
    "gui.blocklyText.huskylens.setIDname": "HuskyLens 设置当前算法ID [ID] 名字为 [NAME]",
    "gui.blocklyText.huskylens.TakeScreenshot": "HuskyLens 触发 [TYPE] 保存到SD卡",
    "gui.blocklyText.huskylens.writeOSD": "HuskyLens 屏幕叠加显示文字 [NAME] 在x [X] y [Y]",
    "gui.blocklyText.huskylens.clearOSD": "HuskyLens 清除屏幕显示的文字",
    "gui.blocklyText.huskylens.learnOnce": "HuskyLens 自动学习一次 ID [ID]",
    "gui.blocklyText.huskylens.clearLearn": "HuskyLens 遗忘当前算法的所有学习数据",
    "gui.blocklyText.huskylens.loadAndSaveModel": "HuskyLens [TYPE] 当前算法数据为SD卡 [INDEX] 号模型",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.A": "拍照",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.B": "截屏",
    "gui.blocklyText.huskylens.LOADSAVE.A": "保存",
    "gui.blocklyText.huskylens.LOADSAVE.B": "加载",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens 从结果中获取 [BOXARROW] 是否在画面中？",
    "gui.blocklyText.huskylens.resultGetNearCenter": "HuskyLens 从结果中获取靠近中心[TYPE]的 [PARAMETER2] 参数",
    "gui.blocklyText.huskylens.resultGetParameter": "HuskyLens 从结果中获取第 [IDNUM] 个 [TYPE] 的 [PARAMETER2] 参数",
    "gui.blocklyText.huskylens.box": "方框",
    "gui.blocklyText.huskylens.arrow": "箭头",
    "gui.blocklyText.huskylens.faceRecognition": "人脸识别",
    "gui.blocklyText.huskylens.objectTracking": "物体追踪",
    "gui.blocklyText.huskylens.objectRecognition": "物体识别",
    "gui.blocklyText.huskylens.lineTracking": "巡线",
    "gui.blocklyText.huskylens.colorRecognition": "颜色识别",
    "gui.blocklyText.huskylens.tagRecognition": "标签识别",
    "gui.blocklyText.huskylens.objectClassification": "物体分类",
    "gui.blocklyText.huskylens.QRRecognition": "二维码识别 (教育版独有)",
    "gui.blocklyText.huskylens.barcodeRecognition": "条形码识别 (教育版独有)",
    "gui.blocklyText.huskylens.ID": "ID",
    "gui.blocklyText.huskylens.x": "X中心",
    "gui.blocklyText.huskylens.y": "Y中心",
    "gui.blocklyText.huskylens.w": "宽度",
    "gui.blocklyText.huskylens.h": "高度",
    "gui.blocklyText.huskylens.x1": "X起点",
    "gui.blocklyText.huskylens.y1": "Y起点",
    "gui.blocklyText.huskylens.x2": "X终点",
    "gui.blocklyText.huskylens.y2": "Y终点",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "通讯方式",
    "gui.blocklyText.huskylens.address": "地址",
    "gui.blocklyText.huskylens.sdaGreen": "SDA(绿)",
    "gui.blocklyText.huskylens.sclBlue": "SCL(蓝)",
    "gui.blocklyText.huskylens.rxGreen": "Rx(绿)",
    "gui.blocklyText.huskylens.txBlue": "Tx(蓝)",
    "gui.blocklyText.pythonhuskylens.extensionName": "二哈识图",
    "gui.blocklyText.pythonhuskylens.init": "HuskyLens 初始化I2C地址为0x32",
    "gui.blocklyText.pythonhuskylens.changeAlgorithm": "HuskyLens 切换到 [ALGORITHM] 算法 直到成功",
    "gui.blocklyText.pythonhuskylens.requestFirst": "HuskyLens 请求一次数据 存入结果",
    "gui.blocklyText.pythonhuskylens.resultGetIsStudy": "HuskyLens 从结果中获取ID [IDNUM] 是否已学习？",
    "gui.blocklyText.pythonhuskylens.resultGetIDIsInPicture": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 是否在画面中？",
    "gui.blocklyText.pythonhuskylens.resultGetIDParameter": "HuskyLens 从结果中获取ID [IDNUM] [TYPE] 的参数 [PARAMETER]",
    "gui.blocklyText.pythonhuskylens.resultGetStudyedID": "HuskyLens 从结果中获取 已学ID总数",
    "gui.blocklyText.pythonhuskylens.resultGetIsBoxArrowTotal": "HuskyLens 从结果中获取 [BOXARROW] 总数",
    "gui.blocklyText.pythonhuskylens.resultGetIDsBoxArrowTotal": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 总数",
    "gui.blocklyText.pythonhuskylens.resultGetIDofWhichParameter": "HuskyLens 从结果中获取ID [IDNUM] 第 [BOXNUM] 个[TYPE]的参数 [PARAMETER]",
    "gui.blocklyText.pythonhuskylens.setIDname": "HuskyLens 设置当前算法ID [ID] 名字为 [IDNAME]",
    "gui.blocklyText.pythonhuskylens.TakeScreenshot": "HuskyLens 触发 [TYPE] 保存到SD卡",
    "gui.blocklyText.pythonhuskylens.writeOSD": "HuskyLens 屏幕叠加显示文字 [TEXT] 在x [X] y [Y]",
    "gui.blocklyText.pythonhuskylens.clearOSD": "HuskyLens 清除屏幕显示的文字",
    "gui.blocklyText.pythonhuskylens.learnOnce": "HuskyLens 自动学习一次 ID [ID]",
    "gui.blocklyText.pythonhuskylens.clearLearn": "HuskyLens 遗忘当前算法的所有学习数据",
    "gui.blocklyText.pythonhuskylens.loadAndSaveModel": "HuskyLens [TYPE] 当前算法数据为SD卡 [INDEX] 号模型",
    "gui.blocklyText.pythonhuskylens.resultGetIsInPicture": "HuskyLens 从结果中获取 [BOXARROW] 是否在画面中？",
    "gui.blocklyText.pythonhuskylens.resultGetNearCenter": "HuskyLens 从结果中获取靠近中心[TYPE]的 [PARAMETER2] 参数",
    "gui.blocklyText.pythonhuskylens.resultGetParameter": "HuskyLens 从结果中获取第 [IDNUM] 个 [TYPE] 的 [PARAMETER2] 参数",
    "gui.blocklyText.ps2.initPs2Handle": "初始化PS2手柄 DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] 振动反馈 [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2振动 [RUMBLE] 强度 [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "PS2刷新一次状态",
    "gui.blocklyText.ps2.ps2ButtonState": "PS2按键 [BUTTON] 状态为 [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2摇杆 [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "开",
    "gui.blocklyText.ps2.OFF": "关",
    "gui.blocklyText.ps2.Triangle": "三角形键",
    "gui.blocklyText.ps2.Circle": "圆形键",
    "gui.blocklyText.ps2.Cross": "x键",
    "gui.blocklyText.ps2.Square": "方形键",
    "gui.blocklyText.ps2.Left1": "左侧1",
    "gui.blocklyText.ps2.Left2": "左侧2",
    "gui.blocklyText.ps2.Left3": "左侧3",
    "gui.blocklyText.ps2.Right1": "右侧1",
    "gui.blocklyText.ps2.Right2": "右侧2",
    "gui.blocklyText.ps2.Right3": "右侧3",
    "gui.blocklyText.ps2.Up": "方向键上",
    "gui.blocklyText.ps2.Right": "方向键右",
    "gui.blocklyText.ps2.Down": "方向键下",
    "gui.blocklyText.ps2.Left": "方向键左",
    "gui.blocklyText.ps2.Select": "选择键",
    "gui.blocklyText.ps2.Start": "开始键",
    "gui.blocklyText.ps2.Hold": "按住",
    "gui.blocklyText.ps2.Pressed": "按下",
    "gui.blocklyText.ps2.Released": "松开",
    "gui.blocklyText.ps2.CHANGE": "改变",
    "gui.blocklyText.ps2.RightX": "右侧X值",
    "gui.blocklyText.ps2.RightY": "右侧Y值",
    "gui.blocklyText.ps2.LeftX": "左侧X值",
    "gui.blocklyText.ps2.LeftY": "左侧Y值",
    "gui.blocklyText.sen0236.bme280Init": "BME280初始化 I2C地址0x77",
    "gui.blocklyText.sen0236.readbme280Sensor": "读取I2C引脚BME280 [TYPE]",
    "gui.blocklyText.pythonsen0236.bme280Init": "BME280 初始化 I2C地址0x77",
    "gui.blocklyText.pythonsen0236.readbme280Sensor": "读取I2C引脚BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "温度(℃)",
    "gui.blocklyText.sen0236.Humidity": "湿度(%rh)",
    "gui.blocklyText.sen0236.Altitude": "海拔(m)",
    "gui.blocklyText.sen0236.Pressure": "气压(Pa)",
    "gui.blocklyText.sen0517.icp10111Init": "ICP10111初始化 I2C地址0x63",
    "gui.blocklyText.sen0517.readicp10111Sensor": "获取ICP10111传感器 [TYPE] 的值",
    "gui.blocklyText.sen0517.Temperature": "温度(℃)",
    "gui.blocklyText.sen0517.Altitude": "海拔(m)",
    "gui.blocklyText.sen0517.Pressure": "气压(Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "初始化I2C液晶显示屏 地址为[ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "I2C液晶显示屏在第 [LINE] 行显示 [TEXT]",
    "gui.blocklyText.LCD1602.displayInXY": "I2C液晶显示屏在坐标 X: [X] Y: [Y] 显示 [TEXT]",
    "gui.blocklyText.LCD1602.displayClear": "I2C液晶显示屏清屏",
    "gui.blocklyText.pythonLCD1602.initialIICAddr": "初始化I2C液晶显示屏 [NAME] 地址为[ADDR]",
    "gui.blocklyText.pythonLCD1602.displayInLine": "I2C液晶显示屏 [NAME] 在第 [LINE] 行显示 [TEXT]",
    "gui.blocklyText.pythonLCD1602.displayInXY": "I2C液晶显示屏 [NAME] 在坐标 X: [X] Y: [Y] 显示 [TEXT]",
    "gui.blocklyText.pythonLCD1602.displayClear": "I2C液晶显示屏 [NAME] 清屏",
    "gui.blocklyText.IICScan.readICCAddr": "读取扫描到的I2C设备地址",
    "gui.blocklyText.mpyfile.initFile": "初始化文本文件 [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsList": "读取文本文件 [FILENAME] 为列表, 以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.writeFile": "向文本文件 [FILENAME] 写入内容 [WRITEDATA] ,以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.initFileMaixduino": "初始化 [ROOT] 文件 [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsListMaixduino": "读取 [ROOT] 文件 [FILENAME] 为列表, 以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.writeFileMaixduino": "向 [ROOT] 文件 [FILENAME] 写入内容 [WRITEDATA],以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.enter": "回车",
    "gui.blocklyText.mpyfile.comma": "逗号",
    "gui.blocklyText.mpyfile.semicolon": "分号",
    "gui.blocklyText.mpyfile.space": "空格",
    "gui.blocklyText.sdcard.initCS": "SD 片选引脚 [CSPIN] 初始化成功？",
    "gui.blocklyText.sdcard.readFileNLine": "SD 读取文件 [FILE] 第 [LINE] 行",
    "gui.blocklyText.sdcard.readFileAll": "SD 读取文件 [FILE] 全部内容",
    "gui.blocklyText.sdcard.writeFile": "SD 向文件 [FILE] 写入 [MSG] 模式 [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD 删除文件 [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD 获取文件 [FILE] 信息 [INFO]",
    "gui.blocklyText.sdcard.NAME": "名称",
    "gui.blocklyText.sdcard.PATH": "路径",
    "gui.blocklyText.sdcard.TYPE": "类型",
    "gui.blocklyText.sdcard.LINE": "行数",
    "gui.blocklyText.sdcard.SIZE": "大小",
    "gui.blocklyText.sdcard.APPEND": "追加",
    "gui.blocklyText.sdcard.REPLACE": "替换",
    "gui.blocklyText.sdcard.WARP": "换行",
    "gui.blocklyText.sdcard.NOWARP": "不换行",
    "gui.blocklyText.sdcard.lsFile": "SD 串口打印全部文件信息 波特率 [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "初始化软串口 [SERIALPORT] Rx: [PINR] Tx: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "设置软串口 [SERIALPORT] 波特率为 [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "软串口 [SERIALPORT] [WAY] [TEXT] [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "软串口 [SERIALPORT] 有数据可读？",
    "gui.blocklyText.softSerialport.readSerialportData": "读取软串口 [SERIALPORT] 的数据",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "从软串口 [SERIALPORT] 读一个 [TYPE]",
    "gui.blocklyText.bluetooth.connectSuccess": "当蓝牙连接成功时运行",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "当蓝牙断开连接时运行",
    "gui.blocklyText.bluetooth.receivedKey": "当蓝牙接收到消息时运行 key [KEY] value [TYPE]",
    "gui.blocklyText.bluetooth.string": "字符串",
    "gui.blocklyText.bluetooth.number": "数字",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "当蓝牙接收到数据并遇到 [SYMBOL] 时运行",
    "gui.blocklyText.bluetooth.sendStr": "蓝牙发送字符串 key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "蓝牙发送数字 key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.openService": "打开蓝牙服务",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "蓝牙接收消息",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo主程序开始",
    "gui.blocklyText.leonardo.setBaud": "设置 [SERIAL] 波特率为 [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "从 [SERIAL] 读取一个 [READTYPE]",
    "gui.blocklyText.leonardo.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.leonardo.serialPort1": "串口0",
    "gui.blocklyText.leonardo.serialPort2": "串口1",
    "gui.blocklyText.leonardo.dataReadable": "[SERIAL] 有数据可读？",
    "gui.blocklyText.leonardo.readSerialData": "读取 [SERIAL] 数据",
    "gui.blocklyText.arduinounoR3.initTcs34725": "初始化TCS34725颜色传感器 I2C地址0x29",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "初始化TMI1650显示器 I2C地址0x34",
    "gui.blocklyText.arduinounoR3.functionStarts": "uno套件程序开始",
    "gui.blocklyText.arduinounoR3.readdigital": "读取 [PIN] 引脚 [MODULE] 的值",
    "gui.blocklyText.arduinounoR3.digitalSensor": "数字传感器",
    "gui.blocklyText.arduinounoR3.buttonModule": "按钮模块",
    "gui.blocklyText.arduinounoR3.stickButton": "摇杆按钮",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "震动传感器",
    "gui.blocklyText.arduinounoR3.readAnalog": "读取 [PIN] 引脚 [SENSOR] 的值",
    "gui.blocklyText.arduinounoR3.anologSensor": "模拟传感器",
    "gui.blocklyText.arduinounoR3.lightSensor": "光线传感器",
    "gui.blocklyText.arduinounoR3.soundSensor": "声音传感器",
    "gui.blocklyText.arduinounoR3.stickDirection": "摇杆方向",
    "gui.blocklyText.arduinounoR3.angleSensor": "角度传感器",
    "gui.blocklyText.arduinounoR3.graySensor": "灰度传感器",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "土壤湿度传感器",
    "gui.blocklyText.arduinounoR3.open": "打开",
    "gui.blocklyText.arduinounoR3.close": "关闭",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] [PIN] 引脚的 [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "数字执行器",
    "gui.blocklyText.arduinounoR3.ledLights": "LED灯",
    "gui.blocklyText.arduinounoR3.fanModule": "风扇模块",
    "gui.blocklyText.arduinounoR3.recordModule": "录音模块",
    "gui.blocklyText.arduinounoR3.relay": "继电器",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "读取超声波距离(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "模拟执行器",
    "gui.blocklyText.arduinounoR3.writeAnalog": "设置 [PIN] 引脚 [MODULE] 的值为 [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "前进",
    "gui.blocklyText.arduinounoR3.backward": "后退",
    "gui.blocklyText.arduinounoR3.turnLeft": "左转",
    "gui.blocklyText.arduinounoR3.turnRight": "右转",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "机器人以 [SPEED] %速度 [DIR]",
    "gui.blocklyText.arduinounoR3.motorForward": "正转",
    "gui.blocklyText.arduinounoR3.motorReverse": "反转",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "设置电机 [MOTOR] 以 [SPEED] % 速度 [DIR]",
    "gui.blocklyText.arduinounoR3.stopMotor": "机器人(MA和MB电机)停止",
    "gui.blocklyText.arduinounoR3.setServo": "设置 [PIN] 引脚伺服舵机为 [DEGREE] 度",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "红外发射 [TYPE] 引脚 [PIN] 数值 [VALUE] 比特数 [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "红外 [PIN] 引脚是否接收到 [BUTTON] 按钮按下？",
    "gui.blocklyText.arduinounoR3.noSound": "无声",
    "gui.blocklyText.arduinounoR3.half": "1/2",
    "gui.blocklyText.arduinounoR3.quarter": "1/4",
    "gui.blocklyText.arduinounoR3.oneInEighth": "1/8",
    "gui.blocklyText.arduinounoR3.wholeBeat": "整拍",
    "gui.blocklyText.arduinounoR3.doubleBeat": "双拍",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "设置 [PIN] 引脚喇叭音调为 [TONE] 节拍为 [BEAT]",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "设置 [PIN] 引脚MP3模块的音量为 [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "设置 [PIN] 引脚MP3模块的播放模式为 [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "停止",
    "gui.blocklyText.arduinounoR3.play": "播放",
    "gui.blocklyText.arduinounoR3.loop": "循环播放",
    "gui.blocklyText.arduinounoR3.random": "随机播放",
    "gui.blocklyText.arduinounoR3.pause": "暂停",
    "gui.blocklyText.arduinounoR3.previous": "上一首",
    "gui.blocklyText.arduinounoR3.next": "下一首",
    "gui.blocklyText.arduinounoR3.VolumePlus": "音量加",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "音量减",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "设置 [PIN] 引脚MP3模块播放第 [NUM] 首歌曲",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "读取 [PIN] 引脚LM35温度(℃)",
    "gui.blocklyText.arduinounoR3.readDHT11": "读取 [PIN] 引脚DHT11的 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "红色值",
    "gui.blocklyText.arduinounoR3.blue": "蓝色值",
    "gui.blocklyText.arduinounoR3.green": "绿色值",
    "gui.blocklyText.arduinounoR3.readColorSensor": "读取TCS34725颜色传感器的 [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "四位数码管_TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "开",
    "gui.blocklyText.arduinounoR3.tm1650Close": "关",
    "gui.blocklyText.arduinounoR3.clearScreen": "清屏",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": "四位数码管_TM1650显示字符串 [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "四位数码管_TM1650第[ORDER] 个小数点 [STATUS]",
    "gui.blocklyText.arduinounoR3.bright": "亮",
    "gui.blocklyText.arduinounoR3.dark": "暗",
    "gui.blocklyText.arduinounoR3.setLightColor": "RGB灯 引脚 [PIN] 灯号 [NUM] [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "RGB灯 引脚 [PIN] 灯号 [NUM] R值:[R] G值:[G] B值:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "RGB灯 引脚 [PIN] 灯数 [NUM]",
    "gui.blocklyText.arduinounoR3.ledControl": "Led点阵(max7219)初始化参数 DIN引脚为 [DIN] CS管脚为 [CS] CLK管脚为 [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "Led点阵(max7219)显示图案 [PIC]",
    "videoSensing.categoryName": "视频侦测",
    "videoSensing.direction": "方向",
    "videoSensing.motion": "运动",
    "videoSensing.off": "关闭",
    "videoSensing.on": "开启",
    "videoSensing.onFlipped": "镜像开启",
    "videoSensing.setVideoTransparency": "将视频透明度设为[TRANSPARENCY]",
    "videoSensing.sprite": "角色",
    "videoSensing.stage": "舞台",
    "videoSensing.videoOn": "相对于[SUBJECT]的视频[ATTRIBUTE]",
    "videoSensing.videoToggle": "[VIDEO_STATE]摄像头",
    "videoSensing.whenMotionGreaterThan": "当视频运动 > [REFERENCE]",
    "gui.blocklyText.max.maxStart": "MAX主程序开始",
    "gui.blocklyText.max.onboardButtonPressed": "板载按键 [PRESSED]",
    "gui.blocklyText.max.pressed": "已按下",
    "gui.blocklyText.max.notPressed": "未按下",
    "gui.blocklyText.max.all": "-1  全部",
    "gui.blocklyText.max.A": "0 右后",
    "gui.blocklyText.max.B": "1  右前",
    "gui.blocklyText.max.C": "2  左前",
    "gui.blocklyText.max.D": "3  左后",
    "gui.blocklyText.max.rgbLightsColor": "设置RGB灯 [TYPE] 的颜色为 [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "读取声音传感器的值",
    "gui.blocklyText.max.playSoundEffect": "播放音效 [SOUND]",
    "gui.blocklyText.max.setDPinValue": "设置数字引脚 [PIN] 的值为 [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "读取数字口 [PIN] 的值",
    "gui.blocklyText.max.readAPinValue": "读取模拟口 [PIN] 的值",
    "gui.blocklyText.max.setServo": "设置 [PIN] 引脚舵机为 [DEGREE] 度",
    "gui.blocklyText.max.readLinefindingSensor": "读取寻线传感器 [DIR] 的值",
    "gui.blocklyText.max.left": "左",
    "gui.blocklyText.max.middle": "中",
    "gui.blocklyText.max.right": "右",
    "gui.blocklyText.max.setLineFidingCarLights": "设置寻线车灯 [SWITCH]",
    "gui.blocklyText.max.on": "亮",
    "gui.blocklyText.max.off": "灭",
    "gui.blocklyText.max.readUltrasoundSensor": "读取超声波传感器的距离 (cm)",
    "gui.blocklyText.max.readLightSensor": "读取光线传感器的值 [LEFTRIGHT]",
    "gui.blocklyText.max.maxSpeedMove": "MAX以 [SPEED] 的速度 [DIR]",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] 以 [SPEED] 的速度 [DIR]",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] 停止",
    "gui.blocklyText.max.showUserDefineExpressions": "显示自定义表情 [EXPRESSION] 颜色 [COLOR]",
    "gui.blocklyText.max.clear": "清屏",
    "gui.blocklyText.max.do": "1  乐谱do",
    "gui.blocklyText.max.re": "2  乐谱re",
    "gui.blocklyText.max.mi": "3  乐谱mi",
    "gui.blocklyText.max.fa": "4  乐谱fa",
    "gui.blocklyText.max.sou": "5  乐谱sou",
    "gui.blocklyText.max.la": "6  乐谱la",
    "gui.blocklyText.max.xi": "7  乐谱xi",
    "gui.blocklyText.max.highdo": "8  乐谱高音节do",
    "gui.blocklyText.max.brake": "9  刹车声",
    "gui.blocklyText.max.rebound": "10 回弹的声音",
    "gui.blocklyText.max.bullets": "11 两次子弹的声音",
    "gui.blocklyText.max.longecho": "12 拖长音的回声",
    "gui.blocklyText.max.playfulending": "14 俏皮的结束音",
    "gui.blocklyText.max.yo": "13 哟嚯",
    "gui.blocklyText.max.wo": "15 喔噢",
    "gui.blocklyText.max.robottalking": "16 机器人说话",
    "gui.blocklyText.sen0160.init": "设置Hx711重量传感器校准值 [VALUE] 引脚 Dout [DOUT] SCK [SCK]",
    "gui.blocklyText.sen0160.readValue": "读取Hx711重量传感器(g) 引脚 Dout [DOUT] SCK [SCK]",
    "gui.blocklyText.knowflow.readORP": "读取模拟引脚 [PIN] 氧化还原(ORP)传感器 (mV)",
    "gui.blocklyText.knowflow.readEC": "读取模拟引脚 [PIN] 电导率(EC)传感器 (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "读取模拟引脚 [PIN] 酸碱度PH传感器(V1)",
    "gui.blocklyText.knowflow.readECV2": "读取模拟引脚 [PIN] 电导率(EC)传感器 (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "读取模拟引脚 [PIN] 酸碱度PH传感器(V2)",
    "gui.blocklyText.knowflow.readDO": "读取模拟引脚 [PIN] 溶解氧DO传感器 (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "设置引脚 [PIN] 蠕动泵以 [SPEED]% 的速度 [DIR]",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] 引脚 [PIN] 的继电器",
    "translate.translateBlock": "将 [WORDS] 译为 [LANGUAGE]",
    "translate.defaultTextToTranslate": "你好",
    "translate.viewerLanguage": "访客语言",
    "gui.blocklyText.goble.readAxis": "读取 GOBLE 摇杆 [AXIS] 轴",
    "gui.blocklyText.goble.buttonIsPressed": "GOBLE [BUTTON] 按钮被按下？",
    "gui.blocklyText.eeprom.clear": "eeprom 清除",
    "gui.blocklyText.eeprom.read": "eeprom 读取地址 [ADDR] 的数据",
    "gui.blocklyText.eeprom.write": "eeprom 给地址 [ADDR] 写数据 [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom 获取校验值 从地址 [ADDRA] 到地址 [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom 获取长度(byte)",
    "gui.blocklyText.tds.readAnalogPin": "读取模拟引脚 [PIN] TDS 传感器(ppm)",
    "gui.blocklyText.pythonsen0244.tdsInit": "TDS传感器[NAME]初始化 引脚[PIN]",
    "gui.blocklyText.pythonsen0244.readAnalogPin": "读取TDS传感器(ppm)[NAME]",
    "gui.blocklyText.steppermotor.initialJointXYParam": "双步进电机联调 X轴 : [XSET] Y轴: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "单步进电机 [AXIS] 轴 每转步数: [STEPS] 转速(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "单步进电机移动步数 [STEPS] 方向 [ROTATEDIR]",
    "gui.blocklyText.steppermotor.setJointSteps": "双步进电机联调 X轴移动 [XSTEPS] 步 方向 [ROTATEDIRX] Y轴移动 [YSTEPS] 步 方向 [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] 程序开始",
    "gui.blocklyText.multithread.stopThread": "停止 [THREAD]",
    "gui.blocklyText.multithread.startThread": "启动 [THREAD]",
    "gui.blocklyText.multithread.loop1": "子线程1",
    "gui.blocklyText.multithread.loop2": "子线程2",
    "gui.blocklyText.multithread.loop3": "子线程3",
    "gui.blocklyText.multithread.loop4": "子线程4",
    "gui.blocklyText.multithread.loop5": "子线程5",
    "gui.blocklyText.multithread.loop6": "子线程6",
    "gui.blocklyText.multithread.loop7": "子线程7",
    "gui.blocklyText.multithread.loop8": "子线程8",
    "gui.blocklyText.multithread.all": "全部子线程",
    "text2speech.speakAndWaitBlock": "朗读 [WORDS]",
    "text2speech.setVoiceBlock": "设置服务器1使用 [VOICE] 嗓音",
    "text2speech.setLanguageBlock": "设置服务器1的朗读语言为[LANGUAGE]",
    "text2speech.setRequestSever": "切换为[SERVER]",
    "text2speech.setSever2Voice": "设置服务器2使用[VOICE]嗓音",
    "text2speech.setSever2Language": "设置服务器2的朗读语言[LANGUAGE]",
    "text2speech.setSever2Account": "设置服务器2账号[MODAL]",
    "text2speech.international": "服务器1(国际)",
    "text2speech.china": "服务器2(中国)",
    "text2speech.alto": "中音",
    "text2speech.tenor": "高音",
    "text2speech.squeak": "尖细",
    "text2speech.giant": "巨人",
    "text2speech.kitten": "小猫",
    "text2speech.defaultTextToSpeak": "你好",
    "gui.blocklyText.text2speech.server2voice0": "度小美",
    "gui.blocklyText.text2speech.server2voice1": "度小宇",
    "gui.blocklyText.text2speech.server2voice3": "度逍遥",
    "gui.blocklyText.text2speech.server2voice4": "度丫丫",
    "gui.blocklyText.text2speech.server2voice106": "度博文",
    "gui.blocklyText.text2speech.server2voice110": "度小童",
    "gui.blocklyText.text2speech.server2voice111": "度小萌",
    "gui.blocklyText.text2speech.server2voice103": "度米多",
    "gui.blocklyText.text2speech.server2voice5": "度小娇",
    "gui.blocklyText.DFR0151.init": "DS1307 初始化 I2C地址0x68",
    "gui.blocklyText.DFR0151.adjustTime": "调整DS1307时间为[YEAR]年[MONTH]月[DATE]日[HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.DFR0151.getTime": "获取DS1307时间[INDEX]",
    "gui.blocklyText.DFR0151.Year": "年",
    "gui.blocklyText.DFR0151.month": "月",
    "gui.blocklyText.DFR0151.date": "日",
    "gui.blocklyText.DFR0151.hour": "时",
    "gui.blocklyText.DFR0151.minute": "分",
    "gui.blocklyText.DFR0151.second": "秒",
    "gui.blocklyText.DFR0151.week": "周",
    "gui.blocklyText.pythondfr0151.init": "DS1307 初始化 I2C地址0x68",
    "gui.blocklyText.pythondfr0151.adjustTime": "调整DS1307 时间为[YEAR]年[MONTH]月[DATE]日 周[WEEK] [HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.pythondfr0151.upTime": "获取一次时间数据",
    "gui.blocklyText.pythondfr0151.getTime": "从时间数据中解析[INDEX]",
    "gui.blocklyText.DFR0469.init": "SD2405 初始化 I2C地址0x32",
    "gui.blocklyText.DFR0469.adjustTime": "调整SD2405时间为[YEAR]年[MONTH]月[DATE]日[HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.DFR0469.getTime": "获取SD2405时间[INDEX]",
    "gui.blocklyText.pythondfr0469.init": "SD2405初始化 I2C地址0x32",
    "gui.blocklyText.pythondfr0469.adjustTime": "调整SD2405时间为[YEAR]年[MONTH]月[DATE]日 周[WEEK] [HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.pythondfr0469.upTime": "获取一次时间数据",
    "gui.blocklyText.pythondfr0469.getTime": "从时间数据中解析[INDEX]",
    "gui.blocklyText.DFR0126.setPin": "音频分析模块 设置引脚 #S[SPIN] #R[RPIN] 模拟引脚[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "音频分析模块 读取声音频率 频段[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "初始化RFID/NFC模块接口 I2C 地址0x24",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "初始化RFID/NFC模块接口 硬串口 Rx [RX] Tx [TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "初始化RFID/NFC模块接口 [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "初始化RFID/NFC模块接口 [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "RFID/NFC模块 数据块[DATABLOCK]第[BYTES]字节写入[VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "读取RFID/NFC模块 数据块[DATABLOCK]第[BYTES]字节(number)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "读取RFID/NFC模块 数据块[DATABLOCK]所有数据(string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "读取RFID/NFC模块 UID(srting)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "检测到卡片 UID:[TEXT]？",
    "gui.blocklyText.DFR0126.scanSerialNfc": "检测到卡片？",
    "gui.blocklyText.pythondfr0231.extensionName": "RFID/NFC",
    "gui.blocklyText.pythondfr0231.initSerialNfcIIC": "初始化RFID/NFC模块接口 I2C 地址0x24",
    "gui.blocklyText.pythondfr0231.writeSerialNfc": "RFID/NFC模块 数据块[DATABLOCK]第[BYTES]字节写入[VALUE]",
    "gui.blocklyText.pythondfr0231.readSerialNfcAll": "读取RFID/NFC模块 数据块[DATABLOCK]所有数据(string)",
    "gui.blocklyText.pythondfr0231.readSerialNfc": "读取RFID/NFC模块 数据块[DATABLOCK]第[BYTES]字节(number)",
    "gui.blocklyText.pythondfr0231.readSerialNfcUid": "读取RFID/NFC模块 UID(srting)",
    "gui.blocklyText.pythondfr0231.scanSerialNfcUid": "检测到卡片 UID:[TEXT]？",
    "gui.blocklyText.pythondfr0231.scanSerialNfc": "检测到卡片？",
    "gui.blocklyText.sen0245.init": "初始化VL53L0X激光测距传感器 I2C地址0x29",
    "gui.blocklyText.sen0245.setVL53L0X": "设置VL53L0X激光测距模块[PRECISION]测量次数[VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "获取VL53L0X激光测距值(mm)",
    "gui.blocklyText.pythonsen0245.extensionName": "激光测距",
    "gui.blocklyText.pythonsen0245.init": "初始化VL53L0X激光测距传感器I2C地址0x29",
    "gui.blocklyText.pythonsen0245.setVL53L0X": "设置VL53L0X激光测距模块[PRECISION]测量次数[VALUE]",
    "gui.blocklyText.pythonsen0245.readVL53L0X": "获取VL53L0X激光测距值(mm)",
    "gui.blocklyText.SEN0245.LOW": "低精度(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "高精度(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "单次测量",
    "gui.blocklyText.SEN0245.Continuous": "连续测量",
    "gui.blocklyText.sen0318.readValueBool": "读取CCS811空气质量是否有数据?",
    "gui.blocklyText.sen0318.readCO2TVCO": "获取空气质量 [TYPE] 的值",
    "gui.blocklyText.sen0318.CCS811init": "初始化CCS811数字空气质量传感器等待直到成功 获取数据时间:[TIME] I2C地址0x5A",
    "gui.blocklyText.sen0318.writeBaseline": "设置CCS811空气质量基数[BASE]",
    "gui.blocklyText.sen0318.CCS811SetBase": "设置基数:[BASE]",
    "gui.blocklyText.sen0318.250ms": "250ms",
    "gui.blocklyText.sen0318.1s": "1s",
    "gui.blocklyText.sen0318.10s": "10s",
    "gui.blocklyText.sen0318.60s": "60s",
    "gui.blocklyText.sen0318.close": "空闲",
    "gui.blocklyText.sen0318.CCS811ReadBaseline": "获取CCS811空气质量基数数值",
    "gui.blocklyText.pythonsen0318.readValueBool": "读取CCS811空气质量是否有数据?",
    "gui.blocklyText.pythonsen0318.readCO2TVCO": "获取空气质量 [TYPE] 的值",
    "gui.blocklyText.pythonsen0318.CCS811init": "初始化CCS811数字空气质量传感器 等待直到成功 获取数据时间:[TIME] I2C地址0x5A",
    "gui.blocklyText.pythonsen0318.writeBaseline": "设置CCS811空气质量基数[BASE]",
    "gui.blocklyText.pythonsen0318.CCS811ReadBaseline": "获取CCS811 空气质量基数数值",
    "gui.blocklyText.sen0315.PAJ7620U2init": "设置PAJ7620U2手势传感器 [MODE] 模式 I2C地址0x73",
    "gui.blocklyText.sen0315.high": "高速模式",
    "gui.blocklyText.sen0315.low": "低速模式",
    "gui.blocklyText.sen0315.PAJ7620U2GetValue": "PAJ7620U2手势传感器 获取一次数据",
    "gui.blocklyText.sen0315.PAJ7620U2IsTriggered": "PAJ7620U2手势传感器 [GESTURE] 被触发",
    "gui.blocklyText.sen0315.Right": "向右挥手",
    "gui.blocklyText.sen0315.Left": "向左挥手",
    "gui.blocklyText.sen0315.Up": "向上挥手",
    "gui.blocklyText.sen0315.Down": "向下挥手",
    "gui.blocklyText.sen0315.Forward": "靠近挥手",
    "gui.blocklyText.sen0315.Backward": "远离挥手",
    "gui.blocklyText.sen0315.Clockwise": "顺时针转动挥手",
    "gui.blocklyText.sen0315.Anti-Clockwise": "逆时针转动挥手",
    "gui.blocklyText.sen0315.Wave": "快速挥手",
    "gui.blocklyText.sen0315.WaveSlowlyDisorder": "低速混乱挥手",
    "gui.blocklyText.sen0315.WaveSlowlyLeftRight": "低速从左往右",
    "gui.blocklyText.sen0315.WaveSlowlyUpDown": "低速从上往下",
    "gui.blocklyText.sen0315.WaveSlowlyForwardBackward": "低速靠近再远离",
    "gui.blocklyText.pythonsen0315.extensionName": "手势识别",
    "gui.blocklyText.pythonsen0315.PAJ7620U2init": "设置PAJ7620U2手势传感器 [MODE] 模式直到成功 I2C地址0x73",
    "gui.blocklyText.pythonsen0315.PAJ7620U2GetValue": "PAJ7620U2手势传感器获取一次数据",
    "gui.blocklyText.pythonsen0315.PAJ7620U2IsTriggered": "PAJ7620U2手势传感器 [GESTURE] 被触发",
    "gui.blocklyText.sen0376.alcoholInit": "初始化电学酒精传感器直到成功 地址: [ADDR] 模式: [MODE]",
    "gui.blocklyText.sen0376.auto": "主动上传",
    "gui.blocklyText.sen0376.passive": "被动应答",
    "gui.blocklyText.sen0376.alcoholGetValue": "取[TIME]次酒精值的平均值(ppm)",
    "gui.blocklyText.sen0321.auto": "自动模式",
    "gui.blocklyText.sen0321.passive": "手动模式",
    "gui.blocklyText.sen0321.ozoneInit": "初始化I2C地址 [ADDR] 选择模式 [MODE]",
    "gui.blocklyText.sen0321.ozoneGetValue": "读取[TIME]次取一次平均值",
    "gui.blocklyText.sen0364.visibleSpectrumInit": "初始化光谱传感器 I2C地址0x39",
    "gui.blocklyText.sen0364.LEDswitch": "使能LED [SWITCH]",
    "gui.blocklyText.sen0364.LEDBrightness": "设置LED亮度为 [BRIGHTNESS]",
    "gui.blocklyText.sen0364.off": "关闭",
    "gui.blocklyText.sen0364.on": "打开",
    "gui.blocklyText.sen0364.spectrumGetValue": "读取传感器 [CHANNEL] 通道的值",
    "gui.blocklyText.sen0364.spectrumGetFreq": "光源的闪烁频率",
    "gui.blocklyText.sen0364.visibleSpectrumSet": "设置通道映射模式为 [MODE] (模式1可读取通道(F1-F4)，模式2可读取通道(F5-NIR))",
    "gui.blocklyText.kit0176.weightInit": "初始化重量传感器直到成功 地址:[ADDR]",
    "gui.blocklyText.kit0176.weightCal": "设置校准重量 [CAL]",
    "gui.blocklyText.kit0176.weightAutoCal": "设置重量传感器模块自动校准时的触发阈值 [CAL]",
    "gui.blocklyText.kit0176.weightStartUpCal": "启动自动校准",
    "gui.blocklyText.kit0176.IsSucesse": "校准是否成功?",
    "gui.blocklyText.kit0176.setCal": "设置/更新校准值",
    "gui.blocklyText.kit0176.getWeightValue": "获取物体重量",
    "gui.blocklyText.kit0176.getCalValue": "获取校准值",
    "gui.blocklyText.kit0176.setCalValue": "设置/更新校准值 [CAL] (手动模式)",
    "gui.blocklyText.sen0497.DHT20Init": "等待直到DHT20温湿度传感器模块I2C连接成功 I2C地址0x38",
    "gui.blocklyText.sen0497.DHT20Read": "读取DHT20 [INDEX]",
    "gui.blocklyText.sen${name}gui.blocklyText.pythonsen0303.VEML6075Ready0497.DHT20Read": "读取DHT20[INDEX]",
    "gui.blocklyText.pythonsen0497.DHT20Init": "等待直到DHT20温湿度传感器模块 I2C连接成功 I2C地址0x38",
    "gui.blocklyText.pythonsen0497.DHT20Read": "读取DHT20 [INDEX]",
    "gui.blocklyText.sen0497.temp": "温度(℃)",
    "gui.blocklyText.sen0497.humi": "湿度(%RH)",
    "gui.blocklyText.sen0514.ens160Init": "初始化ENS160 I2C地址 [ADDR]",
    "gui.blocklyText.sen0514.ens160SetMode": "ENS160设置模式[MODE]",
    "gui.blocklyText.sen0514.standard": "标准模式",
    "gui.blocklyText.sen0514.idle": "空闲模式",
    "gui.blocklyText.sen0514.sleep": "睡眠模式",
    "gui.blocklyText.sen0514.ens160CalComp": "ENS160校准和补偿温度 [TEMP]℃ 湿度[HUMI]%rh",
    "gui.blocklyText.sen0514.ens160GetValue": "ENS160读取 [VALUE]",
    "gui.blocklyText.pythonsen0514.ens160Init": "初始化ENS160 I2C地址 [ADDR]",
    "gui.blocklyText.pythonsen0514.ens160SetMode": "ENS160 设置模式[MODE]",
    "gui.blocklyText.pythonsen0514.ens160CalComp": "ENS160校准和补偿温度 [TEMP]℃ 湿度[HUMI]%rh",
    "gui.blocklyText.pythonsen0514.ens160GetValue": "ENS160读取 [VALUE]",
    "gui.blocklyText.sen0514.eco2": "CO2浓度",
    "gui.blocklyText.sen0514.tvoc": "TVOC浓度",
    "gui.blocklyText.sen0514.aqi": "空气质量指数",
    "gui.blocklyText.sen0514.status": "运行状态",
    "gui.blocklyText.dfr0991.rgbButtonInit": "初始化I2C RGB灯按钮模块等待直到成功[ADDR]",
    "gui.blocklyText.dfr0991.setRgbButtonColor1": "设置显示颜色 红[RED]绿[GREEN]蓝[BLUE]",
    "gui.blocklyText.dfr0991.setRgbButtonColor": "设置显示颜色[COLOR]",
    "gui.blocklyText.dfr0991.getRgbButtonStatus": "按键按下？",
    "gui.blocklyText.sen0518.max30102Init": "初始化MAX30102 I2C地址0x57",
    "gui.blocklyText.sen0518.initSerialMax30102": "初始化MAX30102模块接口 [SERIALPORT] Rx(绿)[PINR] Tx(蓝)[PINT]",
    "gui.blocklyText.sen0518.max30102StartCollect": "MAX30102采集一次数据",
    "gui.blocklyText.sen0518.max30102GetValue": "MAX30102读取[VALUE]",
    "gui.blocklyText.pythonsen0518.max30102Init": "初始化MAX30102 I2C地址0x57",
    "gui.blocklyText.pythonsen0518.max30102StartCollect": "MAX30102 开始收集",
    "gui.blocklyText.pythonsen0518.max30102GetValue": "MAX30102 读取[VALUE]",
    "gui.blocklyText.sen0518.spo2": "血氧%",
    "gui.blocklyText.sen0518.heartbeat": "心率Times/min",
    "gui.blocklyText.sen0518.temp": "传感器的温度℃",
    "gui.blocklyText.sen0359.fingerprintInit": "初始化指纹识别模块 I2C地址0x1F",
    "gui.blocklyText.sen0359.fingerprintSuccess": "等待直到指纹模块连接成功",
    "gui.blocklyText.sen0359.green": "绿色",
    "gui.blocklyText.sen0359.red": "红色",
    "gui.blocklyText.sen0359.yellow": "黄色",
    "gui.blocklyText.sen0359.blue": "蓝色",
    "gui.blocklyText.sen0359.blue1": "青色",
    "gui.blocklyText.sen0359.magenta": "洋红",
    "gui.blocklyText.sen0359.white": "白色",
    "gui.blocklyText.sen0359.breathe": "呼吸",
    "gui.blocklyText.sen0359.flash": "快闪",
    "gui.blocklyText.sen0359.noropen": "常开",
    "gui.blocklyText.sen0359.norclose": "常闭",
    "gui.blocklyText.sen0359.slowopen": "渐开",
    "gui.blocklyText.sen0359.slowclose": "渐关",
    "gui.blocklyText.sen0359.slowflash": "慢闪",
    "gui.blocklyText.sen0359.fingerprintSetLED": "设置LED灯 颜色[COLOR]模式 [MODE]次数 [TIMES]",
    "gui.blocklyText.sen0359.fingerTouch": "手指触碰?",
    "gui.blocklyText.sen0359.fingerprintCollection": "采集一次指纹是否成功? 超时时间 [TIME] 秒",
    "gui.blocklyText.sen0359.fingerprintMatch": "将采集的指纹与所有ID匹配",
    "gui.blocklyText.sen0359.fingerprintMatchID": "将采集的指纹与ID[ID]是否匹配？",
    "gui.blocklyText.sen0359.getFingerprintID": "获取一个可注册的ID",
    "gui.blocklyText.sen0359.fingerprintIDRegistrable": "ID [ID] 已注册?",
    "gui.blocklyText.sen0359.getRegisterNum": "获取注册用户数量",
    "gui.blocklyText.sen0359.saveID": "保存指纹到ID [ID] 是否成功?",
    "gui.blocklyText.sen0359.deleteID": "删除指纹ID [ID]",
    "gui.blocklyText.sen0359.deleteIDAll": "删除全部指纹",
    "gui.blocklyText.pythonsen0359.extensionName": "电容指纹",
    "gui.blocklyText.pythonsen0359.fingerprintInit": "初始化指纹识别模块 I2C地址0x1F",
    "gui.blocklyText.pythonsen0359.fingerprintSuccess": "等待直到指纹模块连接成功",
    "gui.blocklyText.pythonsen0359.fingerprintSetLED": "设置LED灯 颜色[COLOR]模式 [MODE]次数 [TIMES]",
    "gui.blocklyText.pythonsen0359.fingerTouch": "手指触碰?",
    "gui.blocklyText.pythonsen0359.fingerprintCollection": "采集一次指纹是否成功? 超时时间 [TIME] 秒",
    "gui.blocklyText.pythonsen0359.fingerprintMatch": "将采集的指纹与所有ID匹配",
    "gui.blocklyText.pythonsen0359.fingerprintMatchID": "将采集的指纹与ID[ID]是否匹配？",
    "gui.blocklyText.pythonsen0359.getFingerprintID": "获取一个可注册的ID",
    "gui.blocklyText.pythonsen0359.fingerprintIDRegistrable": "ID [ID] 已注册?",
    "gui.blocklyText.pythonsen0359.getRegisterNum": "获取注册用户数量",
    "gui.blocklyText.pythonsen0359.saveID": "保存指纹到ID [ID] 是否成功?",
    "gui.blocklyText.pythonsen0359.deleteID": "删除指纹ID [ID]",
    "gui.blocklyText.pythonsen0359.deleteIDAll": "删除全部指纹",
    "gui.blocklyText.serialMp3.initSerialMp3": "初始化串口MP3模块接口 [SERIALPORT] Rx(绿) [RX] Tx(蓝) [TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "设置串口MP3模块播放模式为 [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "设置串口MP3模块播放第[VALUE]首歌曲",
    "gui.blocklyText.serialMp3.setMP3Volume": "设置串口MP3模块的音量为[VOLUME]%",
    "gui.blocklyText.serialMp3.play": "播放",
    "gui.blocklyText.serialMp3.pause": "暂停播放",
    "gui.blocklyText.serialMp3.end": "结束播放",
    "gui.blocklyText.serialMp3.prev": "上一曲",
    "gui.blocklyText.serialMp3.next": "下一曲",
    "gui.blocklyText.serialMp3.volumeUp": "音量加",
    "gui.blocklyText.serialMp3.volumeDown": "音量减",
    "gui.blocklyText.serialMp3.playlist": "播放曲目",
    "gui.blocklyText.serialMp3.insertTracks": "插播曲目",
    "gui.blocklyText.serialMp3.volume": "音量",
    "gui.blocklyText.servo360.setServo360": "设置引脚 [PIN] 以 [SPEED] % 的速度 [DIR]",
    "gui.blocklyText.servo360.CW": "正转",
    "gui.blocklyText.servo360.CCW": "反转",
    "gui.blocklyText.TCS34725.initTcs34725": "初始化TCS34725颜色传感器 I2C地址0x29",
    "gui.blocklyText.TCS34725.readTcs34725": "读取TCS34725颜色传感器[COLOR] [FORMAT]",
    "gui.blocklyText.pythonsen0212.extensionName": "颜色识别",
    "gui.blocklyText.pythonsen0212.initTcs34725": "初始化TCS34725颜色传感器 I2C地址0x29",
    "gui.blocklyText.pythonsen0212.updateTcs34725": "获取一次TCS34725数据",
    "gui.blocklyText.pythonsen0212.readTcs34725": "从数据中解析TCS34725颜色传感器 [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "转GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "不转GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "显示文本[TEXT] [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "设置文字[DIR]滚动",
    "gui.blocklyText.regEmotion8_16.left": "向左",
    "gui.blocklyText.regEmotion8_16.right": "向右",
    "gui.blocklyText.regEmotion8_16.lightAll": "点亮所有点阵[COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "坐标 x:[XAXIS], y:[YXAXIS]显示为[COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "清空点阵",
    "gui.mainHeader.err": "！错误提示：程序头[%1]仅能同时使用一个，请删除重复定义的模块。",
    "gui.mainHeader.help": "！帮助提示：若需多个程序同时执行，请使用\"扩展\"中的\"多线程功能\"，详见帮助文档。",
    "gui.threadHeader.err1": "！错误提示：程序头[%1] 不能同时使用，它们属于不同的套件或主板。",
    "gui.threadHeader.err2": "！错误提示：事件头[%1]仅能同时使用一个，请删除重复定义的模块。",
    "gui.blocklyText.mqtt.whenReceivedData": "当接收到[TOPIC]的[MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT消息",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi 连接到 热点:[ACCOUNT] 密码:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi 连接成功？",
    "gui.blocklyText.wifi.wifiDisConnected": "Wi-Fi 断开连接",
    "gui.blocklyText.wifi.wifiGetLocalIP": "获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP 地址",
    "gui.blocklyText.wifi.getWiFiNetmask": "子网掩码",
    "gui.blocklyText.wifi.getGateway": "网关",
    "gui.blocklyText.wifi.getDwifiNS": "DNS",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi 设置AP模式:[ACCOUNT2] 密码:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi 获取AP模式本机IP",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi 获取 [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "调试信息",
    "gui.blocklyText.wifi.whenDebugInfoError": "错误码",
    "gui.blocklyText.wifi.ping": "Wi-Fi ping [HOST]",
    "gui.blocklyText.wifi.scan": "Wi-Fi 扫描",
    "gui.blocklyText.pythonwifi.wifiGetLocalIP": "[NAME] 获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.pythonobloq.wifiGetLocalIP": "[NAME] 获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "NTP设置网络时间, 时区 [TIMEZONE] 授时服务器 [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "获取开发板当前 [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "西12区",
    "gui.blocklyText.ntp.UTCWest11_t": "西11区",
    "gui.blocklyText.ntp.UTCWest10_t": "西10区",
    "gui.blocklyText.ntp.UTCWest9_t": "西9区",
    "gui.blocklyText.ntp.UTCWest8_t": "西8区",
    "gui.blocklyText.ntp.UTCWest7_t": "西7区",
    "gui.blocklyText.ntp.UTCWest6_t": "西6区",
    "gui.blocklyText.ntp.UTCWest5_t": "西5区",
    "gui.blocklyText.ntp.UTCWest4_t": "西4区",
    "gui.blocklyText.ntp.UTCWest3_t": "西3区",
    "gui.blocklyText.ntp.UTCWest2_t": "西2区",
    "gui.blocklyText.ntp.UTCWest1_t": "西1区",
    "gui.blocklyText.ntp.UTCMiddle_t": "中时区",
    "gui.blocklyText.ntp.UTCEast1_t": "东1区",
    "gui.blocklyText.ntp.UTCEast2_t": "东2区",
    "gui.blocklyText.ntp.UTCEast3_t": "东3区",
    "gui.blocklyText.ntp.UTCEast4_t": "东4区",
    "gui.blocklyText.ntp.UTCEast5_t": "东5区",
    "gui.blocklyText.ntp.UTCEast6_t": "东6区",
    "gui.blocklyText.ntp.UTCEast7_t": "东7区",
    "gui.blocklyText.ntp.UTCEast8_t": "东8区",
    "gui.blocklyText.ntp.UTCEast9_t": "东9区",
    "gui.blocklyText.ntp.UTCEast10_t": "东10区",
    "gui.blocklyText.ntp.UTCEast11_t": "东11区",
    "gui.blocklyText.ntp.UTCEast12_t": "东12区",
    "gui.blocklyText.ntp.year": "年",
    "gui.blocklyText.ntp.month": "月",
    "gui.blocklyText.ntp.date": "日",
    "gui.blocklyText.ntp.hour": "时",
    "gui.blocklyText.ntp.minute": "分",
    "gui.blocklyText.ntp.second": "秒",
    "gui.blocklyText.ntp.week": "周",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "当UDP服务器收到 [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "设置UDP服务器端口 [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "UDP服务器发送消息 [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "当UDP客户端收到 [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "设置UDP客户端连接到服务器IP [IPADDR] 端口 [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "UDP客户端发送消息 [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "广播消息",
    "gui.blocklyText.udp.ClientRecvedMsg": "广播消息",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] 中添加 key [KEY] value [VALUE]",
    "gui.blocklyText.http.requestHeader": "HTTP 请求体中添加 [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] 请求 URL [URL] 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.http.readMessage": "HTTP 读取 [LINE] 内容",
    "gui.blocklyText.http.param": "参数",
    "gui.blocklyText.http.header": "头部",
    "gui.blocklyText.http.oneline": "一行",
    "gui.blocklyText.http.allline": "全部",
    "gui.blocklyText.mqtt.mqttConnect": "MQTT 发起连接",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT 连接成功？",
    "gui.blocklyText.mqtt.acceptance.meaage": "当接收到[TOPIC]的消息为[MSG]",
    "gui.blocklyText.mqtt.mqttSubscribe": "MQTT 订阅[TOPIC]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT 发送消息[MSG]至[TOPIC]",
    "gui.blocklyText.mqtt.mqttSendSave": "MQTT 发送消息[MSG]至[TOPIC]并存数据库 (SIoT V2)",
    "gui.blocklyText.mqtt.acceptance.topic.meaage": "当接收到[TOPIC]的消息为[MSG]",
    "gui.blocklyText.mqtt.whenReceivedTopicData": "当接收到[TOPIC]的[MSG]",
    "gui.blocklyText.mqtt.mqttSendToTopic": "MQTT 发送消息[MSG]至[TOPIC]",
    "gui.blocklyText.mqtt.mqttSendToTopicSave": "MQTT 发送消息[MSG]至[TOPIC]并存数据库 (SIoT V2)",
    "gui.blocklyText.mqtt.initial": "MQTT 初始化参数 [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「讯飞语音」合成音频 [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「讯飞语音」识别音频 [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「讯飞语音」识别结果",
    "gui.blocklyText.iflytek.CommunicationText": "文字内容",
    "gui.blocklyText.iflytek.CommunicationTransfer": "转存为音频文件",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "待识别音频文件",
    "gui.blocklyText.mqtt.mqttDisconnect": "MQTT 断开连接",
    "gui.blocklyText.internet.internetGetpostInit": "网络请求初始化 [SETTING]",
    "gui.blocklyText.internet.initTypeAddr": "网络请求 设置请求类型 [POST] 地址 [ADDR] headers：[HEADERS] params：[URL] data：[DICT]",
    "gui.blocklyText.internet.internetPostFile": "Post提交文件 [SETTING]",
    "gui.blocklyText.internet.postFileAddrType": "POST提交文件 设置参数 网址 [ADDR] 文件路径 [PATH] MIME类型 [TYPE]",
    "gui.blocklyText.internet.postFileDict": "POST提交文件 添加字段为字典 [DICT] 并提交文件",
    "gui.blocklyText.internet.responseContent": "响应内容 形式[DATATYPE]",
    "gui.blocklyText.internet.responseAttributes": "响应属性 [ATTRIBUTES]",
    "gui.blocklyText.internet.createSocket": "创建 socket [SOKHANDEL] 地址 [AFINET] 类型 [SOCKTYPE]",
    "gui.blocklyText.internet.setSocket": "创建 socket [SOKHANDEL] 地址 [SOCKTYPE] 类型 [TYPENUM]",
    "gui.blocklyText.internet.closeSocket": "socket [SOKHANDEL] 关闭",
    "gui.blocklyText.internet.bindSocket": "socket [SOKHANDEL] 绑定地址 [SOCKADDR] 端口 [SOCKPORT]",
    "gui.blocklyText.internet.listenSocket": "监听 socket [SOKHANDEL] backlog 个数 [BACKLOGNUM]",
    "gui.blocklyText.internet.recvReturnTuple": "socket [SOKHANDEL] 接收链接请求并返回socket元组",
    "gui.blocklyText.internet.parseReturnIp": "解析 host [HOSTADDR] 端口 [SOCKPORT] 并返回 ip",
    "gui.blocklyText.internet.connectHost": "socket [SOKHANDEL] 连接 host [HOSTADDR] 端口 [SOCKPORT]",
    "gui.blocklyText.internet.sendReturnBytes": "socket [SOKHANDEL] 发送 [SOCKMSG] 并返回发送的字节数",
    "gui.blocklyText.internet.continueSendReturnBytes": "socket [SOKHANDEL] 连续发送 [SOCKMSG] 并返回发送的字节数",
    "gui.blocklyText.internet.sendToAddr": "socket [SOKHANDEL] 发送 [SOCKMSG] 到地址 [SOCKADDR]",
    "gui.blocklyText.internet.recvReturnRecvedObject": "socket [SOKHANDEL] 接收并返回接收到的数据对象 最大数据量为 [MAXNUM]",
    "gui.blocklyText.internet.recvReturnRecvedTuple": "socket [SOKHANDEL] 接收并返回接收到的数据元组 最大数据量为 [MAXNUM]",
    "gui.blocklyText.internet.setTimeout": "socket [SOKHANDEL] 设置超时时间为 [TIMEOUT] 秒",
    "gui.blocklyText.internet.setMode": "设置 socket [SOKHANDEL] 为 [SOCKMODE] 模式",
    "gui.blocklyText.internet.readReturnObject": "从 socket [SOKHANDEL] 读取 [READBYTE] 字节并返回字节对象",
    "gui.blocklyText.internet.text": "文本",
    "gui.blocklyText.internet.binary": "二进制",
    "gui.blocklyText.internet.dictionary": "字典",
    "gui.blocklyText.internet.block": "阻塞",
    "gui.blocklyText.internet.nonblock": "非阻塞",
    "gui.blocklyText.internet.initRequestsType": "请求类型",
    "gui.blocklyText.internet.initRequestsAddr": "请求地址",
    "gui.blocklyText.internet.initAddDict": "初始化字典",
    "gui.blocklyText.internet.postFileAddr": "请求网址",
    "gui.blocklyText.internet.postFilePath": "文件路径",
    "gui.blocklyText.internet.postFileMime": "MIME类型",
    "gui.blocklyText.internet.postFileAddDict": "初始化字典",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 初始化 I2C地址0x77",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 读取海拔高度(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 读取大气压强(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 读取温度(℃)",
    "gui.blocklyText.sen0206.init": "初始化MLX90614地址为 [VALUE]",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 测量 [TYPE] [UINT]",
    "gui.blocklyText.sen0206.setEmissivityCorrectionCoefficient": "设置发射率校准系数 [VALUE]((范围0-1))",
    "gui.blocklyText.sen0206.setI2CAddress": "更改MLX90614地址为 [VALUE]",
    "gui.blocklyText.sen0206.object": "物体",
    "gui.blocklyText.sen0206.environment": "环境",
    "gui.blocklyText.sen0206.celsius": "摄氏温度",
    "gui.blocklyText.sen0206.fahrenheit": "华氏温度",
    "gui.blocklyText.sen0253.init": "10DOF 初始化 'I2C_ADDR' 引脚电平为 [LEVEL] I2C地址0x28&0x29",
    "gui.blocklyText.sen0253.reset": "10DOF 传感器复位",
    "gui.blocklyText.sen0253.power": "10DOF 设置电源模式 [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF 获取欧拉角 [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF 获取四元数 [AXIS] 轴",
    "gui.blocklyText.sen0253.getAcc": "10DOF 获取加速度 [AXIS] 轴(mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF 获取磁力计 [AXIS] 轴(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF 获取线性加速度 [AXIS] 轴(mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF 获取重力矢量 [AXIS] 轴(mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF 获取角速度 [AXIS] 轴",
    "gui.blocklyText.sen0253.getBmp280": "10DOF 获取 [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "低电平",
    "gui.blocklyText.sen0253.HIGH": "高电平",
    "gui.blocklyText.sen0253.normal": "正常",
    "gui.blocklyText.sen0253.lowPower": "低功耗",
    "gui.blocklyText.sen0253.suspend": "挂起",
    "gui.blocklyText.sen0253.Roll": "横滚角(°)",
    "gui.blocklyText.sen0253.Pitch": "俯仰角(°)",
    "gui.blocklyText.sen0253.Yaw": "航偏角(°)",
    "gui.blocklyText.sen0253.temperature": "温度(℃)",
    "gui.blocklyText.sen0253.elevation": "海拔(m)",
    "gui.blocklyText.sen0253.airPressure": "气压(Pa)",
    "gui.blocklyText.sen0290.init": "闪电传感器设置中断引脚 [IRQPIN] 和I2C地址 [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "闪电传感器初始化I2C完成？",
    "gui.blocklyText.sen0290.manualCal": "闪电传感器设置电容 [CAPACITANCE] 模式 [MODE] 干扰 [DIST]",
    "gui.blocklyText.sen0290.indoors": "室内",
    "gui.blocklyText.sen0290.outdoors": "室外",
    "gui.blocklyText.sen0290.disturberDis": "干扰禁止",
    "gui.blocklyText.sen0290.disturberEn": "干扰使能",
    "gui.blocklyText.sen0290.errMsg": "错误信息",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "读取闪电传感器的状态",
    "gui.blocklyText.sen0290.GetLightningDistKm": "闪电传感器获取闪电距离(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "闪电传感器获取闪电能量强度",
    "gui.blocklyText.sen0291.sen0291Init": "功率计 初始化I2C地址为 [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "功率计 读取负载 [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "功率计校准 实际测量电流为 [EXT] mA",
    "gui.blocklyText.pythonsen0291.sen0291Init": "功率计[NAME] 初始化I2C地址为 [IICADDR]",
    "gui.blocklyText.pythonsen0291.sen0291Read": "功率计[NAME] 读取负载 [LOAD]",
    "gui.blocklyText.pythonsen0291.sen0291Calibration": "功率计[NAME] 校准实际测量电流为 [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "电压 (mV)",
    "gui.blocklyText.sen0291.electric": "电流 (mA)",
    "gui.blocklyText.sen0291.power": "功率 (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "分流电压 (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.weather.weatherGetWithCity": "省份 [COUNTRY] 城市 [CITY] 的 [WEATHER]",
    "gui.blocklyText.weather.temperaturHigh": "最高温度(℃)",
    "gui.blocklyText.weather.temperaturLow": "最低温度(℃)",
    "gui.blocklyText.weather.weather": "天气",
    "gui.blocklyText.weather.weatherInit": "天气服务器初始化 [SETTING]",
    "gui.blocklyText.weather.serverAddr": "服务器地址",
    "gui.blocklyText.weather.appid": "账户ID(appid)",
    "gui.blocklyText.weather.appsecret": "账户密钥(appsecret)",
    "gui.blocklyText.weather.serverAddrTianqiAPI": "天气API网(www.tianqiapi.com)",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "设置服务器参数 [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "更新(update)标签(tag) [TAG] 值(value) [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "读取(get)标签(tag) [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "删除(delete)标签(tag) [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "计数(count)",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "测试网络连接",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "查询(search) 起始编号(no) [NUMS] 变量个数(count) [NUMV] 标签(tag) [TAG] 类型(type) [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "tag",
    "gui.blocklyText.tinywebdb.value": "value",
    "gui.blocklyText.tinywebdb.both": "both",
    "gui.blocklyText.weather.anhui": "安徽",
    "gui.blocklyText.weather.aomen": "澳门",
    "gui.blocklyText.weather.beijing": "北京",
    "gui.blocklyText.weather.chongqing": "重庆",
    "gui.blocklyText.weather.fujian": "福建",
    "gui.blocklyText.weather.gansu": "甘肃",
    "gui.blocklyText.weather.guangdong": "广东",
    "gui.blocklyText.weather.guangxi": "广西",
    "gui.blocklyText.weather.guizhou": "贵州",
    "gui.blocklyText.weather.hainan": "海南",
    "gui.blocklyText.weather.hebei": "河北",
    "gui.blocklyText.weather.henan": "河南",
    "gui.blocklyText.weather.heilongjiang": "黑龙江",
    "gui.blocklyText.weather.hubei": "湖北",
    "gui.blocklyText.weather.hunan": "湖南",
    "gui.blocklyText.weather.jiangxi": "江西",
    "gui.blocklyText.weather.jiangsu": "江苏",
    "gui.blocklyText.weather.jilin": "吉林",
    "gui.blocklyText.weather.liaoning": "辽宁",
    "gui.blocklyText.weather.neimenggu": "内蒙古",
    "gui.blocklyText.weather.ningxia": "宁夏",
    "gui.blocklyText.weather.qinghai": "青海",
    "gui.blocklyText.weather.sichuan": "四川",
    "gui.blocklyText.weather.shandong": "山东",
    "gui.blocklyText.weather.shanxi": "山西",
    "gui.blocklyText.weather.shan3xi": "陕西",
    "gui.blocklyText.weather.shanghai": "上海",
    "gui.blocklyText.weather.tianjin": "天津",
    "gui.blocklyText.weather.taiwan": "台湾",
    "gui.blocklyText.weather.xinjiang": "新疆",
    "gui.blocklyText.weather.xizang": "西藏",
    "gui.blocklyText.weather.xianggang": "香港",
    "gui.blocklyText.weather.yunnan": "云南",
    "gui.blocklyText.weather.zhejiang": "浙江",
    "speech.whenIHear": "当听到[PHRASE]",
    "speech.listenAndWait": "听候语音输入",
    "speech.getSpeech": "识别结果",
    "speech.setRecordTime": "设置每次听 [TIME] 秒",
    "speech.stopRecord": "语音识别结束听",
    "speech.controlSonogram": "[CONTROL] 声波图",
    "speech.setServer": "切换语音识别服务器为 [SERVER]",
    "speech.defaultWhenIHearValue": "开始",
    "speech.show": "显示",
    "speech.hide": "隐藏",
    "speech.server1": "服务器1",
    "speech.server2": "服务器2",
    "gui.blocklyText.mp3.initMp3": "初始化DFPlayer MP3模块接口 [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "设置DFPlayer MP3模块音量为 [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "设置DFPlayer MP3模块播放模式为 [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "设置DFPlayer MP3模块播放第 [NUM] 首歌曲",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "设置 [PIN] 引脚DFPlayer MP3模块的音量为 [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "设置 [PIN] 引脚DFPlayer MP3模块的播放模式为 [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "设置 [PIN] 引脚DFPlayer MP3模块播放第 [NUM] 首歌曲",
    "gui.blocklyText.tel0094.GPSInit": "GPS 初始化为 [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS 获取地理位置 [LATLON](°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "GPS 获取卫星时间 [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "经度",
    "gui.blocklyText.tel0094.LONGITUDE": "纬度",
    "gui.blocklyText.tel0094.YEAR": "年",
    "gui.blocklyText.tel0094.MONTH": "月",
    "gui.blocklyText.tel0094.DAY": "日",
    "gui.blocklyText.tel0094.HOUR": "时",
    "gui.blocklyText.tel0094.MINUTE": "分",
    "gui.blocklyText.tel0094.SECOND": "秒",
    "gui.blocklyText.tel0094.ALL": "全部",
    "gui.blocklyText.tel0094.GPSGet": "GPS 获取 [GPS]",
    "gui.blocklyText.tel0094.getState": "状态",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "纬度半球",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "经度半球",
    "gui.blocklyText.tel0094.getGroundSpeed": "地面速度",
    "gui.blocklyText.tel0094.getSatellitesCount": "卫星数量",
    "gui.blocklyText.tel0094.getAltitude": "海拔",
    "gui.blocklyText.tel0094.getPdop": "位置精度",
    "gui.blocklyText.tel0094.getHdop": "水平精度",
    "gui.blocklyText.tel0094.getVdop": "垂直精度",
    "gui.blocklyText.tel0094.getPositioningMode": "定位模式",
    "gui.blocklyText.microIoT.displayInLine": "OLED 显示文字 [TEXT] 在第 [LINE] 行",
    "gui.blocklyText.microIoT.displayInXY": "OLED 显示文字 [TEXT] 在坐标x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED 显示图片 [IMAGE] 在坐标x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED 清屏",
    "gui.blocklyText.microIoT.pointDrawing": "OLED 画点x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED 设置线宽为 [LINEWIDTH] 个像素",
    "gui.blocklyText.microIoT.lineDrawing": "OLED 画线 起点x [X1] y [Y1] 终点x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED 画圆 [FILL] 圆心x [X] y [Y] 半径 [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED 画框 [FILL] 起点x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB灯 设置亮度为 [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "RGB灯 全部熄灭",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "RGB灯 灯号 [NUM1] 到 [NUM2] 显示颜色 [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "RGB灯 灯号 [NUM1] 到 [NUM2] 显示渐变色 色调从 [COLOR1] 到 [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "RGB灯 循环移动 [NUM] 个像素",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "RGB灯 灯号 [NUM1] 到 [NUM2] 显示颜色 [COLOR]",
    "gui.blocklyText.motorIot.all": "全部",
    "gui.blocklyText.microIot.servo": "舵机 [INDEX] 角度 [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "MQTT 初始化参数 [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT 发送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.microIot.mqttSendmsgSave": "MQTT 发送消息 [MSG] 至 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.microIot.MQTTMsg": "MQTT消息",
    "gui.blocklyText.microIoTCloud.MotorRun": "电机以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.microIoTCloud.MotorStop": "电机停止",
    "gui.blocklyText.microIoTCloud.setTime": "设置年 [Y] 月 [MON] 日 [D] 时 [H] 分 [MIN] 秒 [S]",
    "gui.blocklyText.microIoTCloud.setYear": "设置年 [Y]",
    "gui.blocklyText.microIoTCloud.setMonth": "设置月 [MON]",
    "gui.blocklyText.microIoTCloud.setDay": "设置日 [D]",
    "gui.blocklyText.microIoTCloud.setHour": "设置时 [H]",
    "gui.blocklyText.microIoTCloud.setMinute": "设置分 [MIN]",
    "gui.blocklyText.microIoTCloud.setSecond": "设置秒 [S]",
    "gui.blocklyText.microIoTCloud.getTime": "获取 [TIME]",
    "gui.blocklyText.microIoTCloud.sendmsgSaveToCloudPlatform": "发送消息 [MSG] 到 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.microTouch.whenBtnPress": "当按键 [KEY] 被按下时",
    "gui.blocklyText.microTouch.Any": "任意",
    "gui.blocklyText.microTouch.isButtonPressed": "按键 [KEY] 被按下?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "基础模式读取按键",
    "gui.blocklyText.microTouch.readKeyMathMode": "数学模式读取 [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "数字按键(num)",
    "gui.blocklyText.microTouch.Function": "功能按键(str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] 震动电机",
    "gui.blocklyText.microTouch.turnOn": "打开",
    "gui.blocklyText.microTouch.shutDown": "关闭",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "声音强度",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "自然光线",
    "gui.blocklyText.microNaturalScience.readColour": "读取 [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "红色值",
    "gui.blocklyText.microNaturalScience.G": "绿色值",
    "gui.blocklyText.microNaturalScience.B": "蓝色值",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "紫外线强度(μW/cm2)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "打开",
    "gui.blocklyText.microNaturalScience.off": "关闭",
    "gui.blocklyText.microNaturalScience.setTDSK": "设置TDS电极常数k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "获取TDS电极常数k",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS(ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "获取 [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "水温(℃)",
    "gui.blocklyText.microNaturalScience.Temperature": "环境温度(℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "环境湿度(%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "海拔高度(m)",
    "gui.blocklyText.microNaturalScience.Pressure": "大气压强(Pa)",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB灯 设置矩阵点 X [X] Y [Y] 显示颜色 [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11不能放在中断事件中，可能导致dht11读数异",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22不能放在中断事件中，可能导致dht22读数异常",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5不能放在中断事件中，可能导致pm2.5读数异常",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5读取数据，可能导致led点阵初始化失败",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5读取数据，可能导致led点阵显示图案失败",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "mp3初始化不能放在中断事件中，可能导致初始化失败",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "mp3设置音量不能放在中断事件中，可能导致设置音量失败",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "mp3设置播放模式不能放在中断事件中，可能导致设置播放模式失败",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "mp3设置播放不能放在中断事件中，可能导致设置播放失败",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "串口mp3初始化不能放在中断事件中，可能导致初始化失败",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "串口mp3播放模式不能放在中断事件中，可能导致设置播放模式失败",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "串口mp3播放不能放在中断事件中，可能导致播放失败",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "串口mp3设置音量不能放在中断事件中，可能导致设置音量失败",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "obloq的mqtt初始化软串口不能放在中断事件中，可能导致软串口通信失败",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "obloq发送消息不能放在中断事件中，可能导致发送失败",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "obloq读取mqtt消息不能放在中断事件中，可能导致接收失败",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "obloq的http的初始化软串口不能放在中断事件中，可能导致软串口通信失败",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "obloq http的get请求不能放在中断事件中，可能导致请求失败",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "obloq http的post请求不能放在中断事件中，可能导致请求失败",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "obloq http的put请求不能放在中断事件中，可能导致请求失败",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "红外接收不能放在中断事件中，可能导致接收失败",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "红外接收，可能导致led点阵初始化失败",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "红外接收，可能导致led点阵显示图案失败",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "红外发送不能放在中断事件中，可能导致发送失败",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB灯带不能放在中断事件中，可能导致初始化失败",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB灯带不能放在中断事件中，可能导致设置引脚失败",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB灯带不能放在中断事件中，可能导致引脚熄灭失败",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB灯带不能放在中断事件中，可能导致显示颜色失败",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB灯带不能放在中断事件中，可能导致调整颜色失败",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB灯带不能放在中断事件中，可能导致移动失败",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB灯带不能放在中断事件中，可能导致循环移动失败",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB灯带不能放在中断事件中，可能导致显示柱状图失败",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "led点阵不能放在中断事件中，可能导致初始化失败",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "led点阵不能放在中断事件中，可能导致显示图案失败",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "软串口输出不能放在中断事件中，可能导致输出内容异常",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "软串口读取数据不能放在中断事件中，可能导致读取内容异常",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "软串口输出数据与读取数据同时使用，可能导致数据异常",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "软串口读取整数不能放在中断事件中，可能导致读取内容异常",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "软串口输出数据与读取整数同时使用，可能导致读取数据异常",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "当dht11和PM2.5分别位于两个线程中时，如果pm2.5读取太频繁，dht11读取可能会失败，建议降低pm2.5读取频率",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "当dht11和ds18b20分别在两个线程中，如果ds18b20读取过于频繁，可能导致dht11读取失败，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "当dht11和mp3初始化分别在两个线程中，mp3初始化可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "当dht11和mp3设置音量分别在两个线程中，mp3设置音量可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "当dht11和mp3设置模式分别在两个线程中，mp3设置模式可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "当dht11和mp3播放分别在两个线程中，mp3播放可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "当dht11和串口mp3初始化分别在两个线程中，串口mp3初始化可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "当dht11和串口mp3控制模块分别在两个线程中，串口mp3控制模块可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "当dht11和串口mp3播放分别在两个线程中，串口mp3播放可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "当dht11和串口mp3设置音量分别在两个线程中，串口mp3设置音量可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "当dht11和RGB初始化分别在两个线程中，RGB初始化可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "当dht11和RGB设置亮度分别在两个线程中，RGB设置亮度可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "当dht11和RGB熄灭分别在两个线程中，RGB熄灭可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "当dht11和RGB显示颜色分别在两个线程中，RGB显示颜色可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "当dht11和RGB显示渐变色分别在两个线程中，RGB显示渐变色可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "当dht11和RGB移动像素分别在两个线程中，RGB移动像素可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "当dht11和RGB循环移动像素分别在两个线程中，RGB循环移动像素可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "当dht11和RGB显示柱状分别在两个线程中，RGB显示柱状可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "当dht11和软串口发送分别在两个线程中，软串口发送可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "当dht11和obloq mqtt初始化分别在两个线程中，obloq mqtt初始化可能导致dht11读取错误，建议等待初始化完成再读取dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "当dht11和obloq mqtt发送消息分别在两个线程中，obloq mqtt发送消息可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "当dht11和obloq http put请求分别在两个线程中，可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "当dht11和obloq http初始化分别在两个线程中，可能导致dht11读取错误，建议等待初始化完成再读取dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "当dht11和obloq http get请求分别在两个线程中，可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "当dht11和obloq http post请求分别在两个线程中，可能导致dht11读取错误",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "当dht11和红外发射分别在两个线程中，可能导致dht11读取错误，建议减小红外发射频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "当ds18b20和PM2.5分别在两个线程中，可能导致PM2.5读取错误，建议减ds18b20频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "当ds18b20和obloq mqtt初始化分别在两个线程中，可能导致mqtt初始化失败，建议等待初始化完成再读取ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "当ds18b20和obloq hqtt put请求分别在两个线程中，可能导致put请求失败，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "当ds18b20和obloq http初始化分别在两个线程中，可能导致http初始化失败，建议等待初始化完成再读取ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "当ds18b20和obloq hqtt get请求分别在两个线程中，可能导致get请求失败，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "当ds18b20和obloq hqtt post请求分别在两个线程中，可能导致post请求失败，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "读取ds18b20可能导致红外接收不到数据，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "读取ds18b20可能导致软串口接收数据出错，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "当PM2.5读取和obloq mqtt初始化分别在两个线程中，可能导致mqtt初始化失败或PM2.5读取出错，建议避免mqtt初始化和PM2.5读取同时执行",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "当PM2.5读取和obloq mqtt发送消息分别在两个线程中，可能导致PM2.5读取出错，建议降低PM2.5读取读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "当PM2.5读取和obloq http put请求分别在两个线程中，可能导致put请求失败或PM2.5读取出错，建议降低put请求频率或者降低PM2.5读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "当PM2.5读取和obloq http初始化分别在两个线程中，可能导致http初始化失败或PM2.5读取出错，建议避免http初始化和PM2.5读取同时执行",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "当PM2.5读取和obloq http get请求分别在两个线程中，可能导致get请求失败或PM2.5读取出错，建议降低get请求频率或者降低PM2.5读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "当PM2.5读取和obloq http post请求分别在两个线程中，可能导致post请求失败或PM2.5读取出错，建议降低post请求频率或者降低PM2.5读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "当PM2.5读取和MP3初始化分别在两个线程中，可能致PM2.5读取失败，建议避免PM2.5读取和MP3初始化同时执行",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "当PM2.5读取和MP3设置音量分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁调节音量",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "当PM2.5读取和MP3播放分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁设备播放模式",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "当PM2.5读取和MP3播放分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁切换歌曲",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "当PM2.5读取和串口MP3播放分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁设备播放模式",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "当PM2.5读取和串口MP3播放分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁切换歌曲",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "当PM2.5读取和串口MP3设置音量分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁调节音量",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "当PM2.5读取可能导致红外接收失败，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "当PM2.5读取和RGB初始化分别在两个线程中，可能导致PM2.5读取错误，建议避免PM2.5读取和RGB初始化同时执行",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "当PM2.5读取和RGB设置亮度分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁调节亮度",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "当PM2.5读取和RGB熄灭分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁熄灭RGB",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "当PM2.5读取和RGB显示颜色分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁切换颜色",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "当PM2.5读取和RGB显示渐变色分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁切换渐变色",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "当PM2.5读取和RGB移动像素分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁移动像素",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "使用Wi-Fi可能导致板载rgb显示颜色异常",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "udp使用了Wi-Fi，可能导致板载rgb显示颜色异常",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "使用Wi-Fi可能导致板载rgb设置三色异常",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "udp使用了Wi-Fi，可能导致板载rgb设置三色异常",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "使用Wi-Fi可能导致板载rgb设置亮度异常",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "udp使用了Wi-Fi，可能导致板载rgb设置亮度异常",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "使用Wi-Fi可能导致板载rgb获取亮度异常",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "udp使用了Wi-Fi，可能导致板载rgb获取亮度异常",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "使用Wi-Fi可能导致串口mp3初始化异常",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "udp使用了Wi-Fi，可能导致串口mp3初始化异常",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "使用Wi-Fi可能导致串口mp3播放异常",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "udp使用了Wi-Fi，可能导致串口mp3播放异常",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "使用Wi-Fi可能导致串口mp3播放曲目失败",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "udp使用了Wi-Fi，可能导致串口mp3播放曲目失败",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "使用Wi-Fi可能导致串口mp3设置音量失败",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "udp使用了Wi-Fi，可能导致串口mp3设置音量失败",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "使用Wi-Fi可能导致360舵机设置转速异常",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "udp使用了Wi-Fi，可能导致360舵机设置转速异常",
    "gui.blocklyText.prompt.telloesp32.emissionPinAndBit_telloWIFI": "Wi-Fi的使用，可能引起红外发射模块异常",
    "gui.blocklyText.prompt.telloesp32.rgbnomal_rgbeffects": "使用呼吸灯和闪烁特效积木程序时不可再单独设置LED灯，请移除其中一种积木",
    "gui.blocklyText.prompt.telloesp32.matrixnomal_matrixeffects": "使用点阵屏特效积木程序时不可再单独设置点阵屏，请移除其中一种积木",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "当PM2.5读取和RGB循环移动像素分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁执行循环移动像素",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "当PM2.5读取和RGB显示柱状分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁执行RGB显示柱状",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "当PM2.5读取和led点阵初始化分别在两个线程中，可能导致led点阵初始化出错，建议避免PM2.5读取和led点阵初始化同时执行",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "当PM2.5读取和led点阵显示分别在两个线程中，可能导致led点阵显示出错，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5读取可能导致PM2.5读取出错，建议不要过于频繁发送数据",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5读取可能导致软串口读取失败，建议不要过于频繁读取PM2.5",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3可能导致红外接收不到数据，建议降低MP3使用频率",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "当MP3和obloq mqtt初始化分别在两个线程中，可能导致mqtt初始化失败，建议避免mqtt初始化和MP3同时执行",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "当MP3和obloq http put请求分别在两个线程中，可能导致put请求失败，建议降低put请求频率或者降低MP3使用频率",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "当MP3和obloq http初始化分别在两个线程中，可能导致http初始化失败，建议避免http初始化和MP3同时执行",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "当MP3和obloq http get请求分别在两个线程中，可能导致get请求失败，建议降低get请求频率或者降低MP3使用频率",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "当MP3和obloq http post请求分别在两个线程中，可能导致post请求失败，建议降低post请求频率或者降低MP3使用频率",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3可能导致软串口读取失败，建议不要过于频繁使用MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "串口MP3可能导致红外接收不到数据，建议降低串口MP3使用频率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "当串口MP3和obloq mqtt初始化分别在两个线程中，可能导致mqtt初始化失败，建议避免mqtt初始化和串口MP3同时执行",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "当串口MP3和obloq http put请求分别在两个线程中，可能导致put请求失败，建议降低put请求频率或者降低串口MP3使用频率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "当串口MP3和obloq http初始化分别在两个线程中，可能导致http初始化失败，建议避免http初始化和串口MP3同时执行",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "当串口MP3和obloq http get请求分别在两个线程中，可能导致get请求失败，建议降低get请求频率或者降低串口MP3使用频率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "当串口MP3和obloq http post请求分别在两个线程中，可能导致post请求失败，建议降低post请求频率或者降低串口MP3使用频率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "串口MP3可能导致软串口读取失败，建议不要过于频繁使用串口MP3",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "红外发射可能导致软串口obloq接收出错，建议降低红外发射频率",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "软串口obloq可能导致红外接收数据出错，建议避免obloq频繁发送消息",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "RGB灯带初始化可能导致软串口obloq接收消息出错，建议避免频繁使用RGB灯带",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "灯带设置亮度可能导致软串口obloq接收消息出错，建议避免频繁调节亮度",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB灯带熄灭可能导致软串口obloq接收消息出错，建议避免频繁熄灭灯带",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB灯带显可能导致软串口obloq接收消息出错，建议避免频繁显示颜色",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "RGB灯带显示渐变色可能导致软串口obloq接收消息出错，建议避免频繁显示渐变色",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB灯带移动像素可能导致软串口obloq接收消息出错",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "RGB灯带循环移动像素可能导致软串口obloq接收消息出错",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB灯带显示柱状可能导致软串口obloq接收消息出错",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "当软串口obloq和led点阵初始化分别在两个线程中，可能导致led点阵初始化出错，建议避免软串口obloq和led点阵初始化同时执行",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "当软串口obloq和led点阵显示分别在两个线程中，可能导致led点阵显示出错",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "软串口发送可能导致软串口obloq读取出错，建议不要过于频繁发送数据",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "软串口obloq发送可能导致软串口读取失败，建议不要过于频繁发送数据",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "红外发射可能导致红外接收不到数据，建议降低红外发射频率",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "当红外发射可能导致软串口读取失败，建议降低红外发射频率",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "RGB灯带初始化可能导致红外接收不到数据",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "RGB灯带设置亮度可能导致红外接收不到数据，建议避免RGB灯带频繁设置亮度",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "RGB灯带熄灭可能导致红外接收不到数据",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "RGB灯带显示颜色可能导致红外接收不到数据，建议避免RGB灯带频繁显示颜色",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "RGB灯带显示渐变色可能导致红外接收不到数据，建议避免RGB灯带频繁显示渐变色",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "RGB灯带移动像素可能导致红外接收不到数据，建议避免RGB灯带频繁移动像素",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "RGB灯带循环移动像素可能导致红外接收不到数据，建议避免RGB灯带频繁循环移动像素",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "RGB灯带显示柱状可能导致红外接收不到数据，建议避免RGB灯带频繁显示柱状",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "红外接收可能导致led初始化失败",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "红外接收可能导致led显示错误",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "软串口发送可能导致红外接收不到数据，建议避免软串口频繁发送",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB灯带可能导致软串口读取失败，建议降低RGB灯带使用频率",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "软串口接收数据可能导致led点阵显示出错",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "软串口发送可能导致软串口接收数据出错，建议避免发送数据和接收数据同时执行",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "串口MP3初始化可能导致红外接收数据出错，建议尽量避免同时执行",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "串口MP3设置播放模式可能导致红外接收数据出错，建议避免过于频繁设置播放模式",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "串口MP3播放可能导致红外接收数据出错，建议避免过于频繁切换歌曲",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "串口MP3设置音量可能导致红外接收数据出错，建议避免过于频繁调节音量",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "max7219初始化可能导致红外接收数据出错，建议尽量避免同时执行",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "max7219显示图像可能导致红外接收数据出错，建议避免过于频繁显示图像",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "串口MP3模块可能导致引脚中断不能触发，建议避免过于频繁使用串口MP3模块",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "max7219显示模块可能导致引脚中断不能触发，建议避免过于频繁使用max7219显示",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "dht读取可能导致红外接收消息错误，建议降低dht读取频率",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "ds18b20读取可能导致软外接收消息错误，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5读取可能导致软外接收消息错误，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "mp3初始化读取可能导致红外接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "mp3设置音量可能导致红外接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "mp3设置模式可能导致红外接收消息错误，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "mp3播放可能导致红外接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "串口mp3初始化读取可能导致红外接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "串口mp3播放可能导致红外接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "串口mp3设置音量可能导致红外接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "红外发射可能导致红外接收消息错误，建议避免过于频繁发送红外",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "RGB灯带初始化可能导致红外接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "RGB灯带设置亮度可能导致红外接收消息错误，建议避免过于频繁设置亮度",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB灯带显示可能导致红外接收消息错误，建议避免过于频繁执行RGB灯带显示",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "RGB灯带显示渐变色可能导致红外接收消息错误，建议避免过于频繁显示渐变色",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "RGB灯带移动像素可能导致红外接收消息错误，建议避免过于频繁移动像素",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "RGB灯带循环移动像素可能导致红外接收消息错误，建议避免过于频繁执行循环移动像素",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB灯带显示柱状可能导致红外接收消息错误，建议避免过于频繁执行显示柱状",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "红外接收消息可能导致max7219初始化失败",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "红外接收消息可能导致max7219显示出错",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "软串口打印可能导致红外接收消息错误，建议避免过于频繁执行软串口打印",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "软串口obloq mqtt初始化可能导致红外接收数据出错，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "软串口obloq put请求可能导致红外接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "软串口obloq http初始化可能导致红外接收数据出错，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "软串口obloq get请求可能导致红外接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "软串口obloq post请求可能导致红外接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "RGB灯带清除显示可能导致软红外接收消息错误，建议避免过于频繁清除显示",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "ds18b20读取可能导致软串口接收消息错误，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5读取可能导致软串口接收消息错误，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "mp3初始化读取可能导致软串口接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "mp3设置音量可能导致软串口接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "mp3设置模式可能导致软串口接收消息错误，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "mp3播放可能导致软串口接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "串口mp3初始化读取可能导致软串口接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "串口mp3设置模式可能导致软串口接收消息错误，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "串口mp3播放可能导致软串口接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "串口mp3设置音量可能导致软串口接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "红外发射可能导致软串口接收消息错误，建议避免过于频繁发送红外",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "RGB灯带初始化可能导致软串口接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "RGB灯带设置亮度可能导致软串口接收消息错误，建议避免过于频繁设置亮度",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "RGB灯带清除显示可能导致软串口接收消息错误，建议避免过于频繁清除显示",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB灯带显示可能导致软串口接收消息错误，建议避免过于频繁执行RGB灯带显示",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB灯带显示渐变色可能导致软串口接收消息错误，建议避免过于频繁显示渐变色",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "RGB灯带移动像素可能导致软串口接收消息错误，建议避免过于频繁移动像素",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB灯带循环移动像素可能导致软串口接收消息错误，建议避免过于频繁执行循环移动像素",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB灯带显示柱状可能导致软串口接收消息错误，建议避免过于频繁执行显示柱状",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "软串口接收消息可能导致max7219初始化失败",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "软串口接收消息可能导致max7219显示出错",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "软串口打印可能导致软串口接收消息错误，建议避免过于频繁执行软串口打印",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "dht读取可能导致引脚中断触发失败，建议降低dht读取频率",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "ds18b20读取可能导致引脚中断触发失败，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5读取可能导致引脚中断触发失败，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "mp3初始化读取可能导致引脚中断触发失败，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "mp3设置音量可能导致引脚中断触发失败，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "mp3设置模式可能导致引脚中断触发失败，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "mp3播放可能导致引脚中断触发失败，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "串口mp3初始化读取可能导致引脚中断触发失败，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "串口mp3设置模式可能导致引脚中断触发失败，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "串口mp3播放可能导致引脚中断触发失败，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "串口mp3设置音量可能导致引脚中断触发失败，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "红外发射可能导致引脚中断触发失败，建议避免过于频繁发送红外",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB灯带初始化可能导致引脚中断触发失败，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "RGB灯带设置亮度可能导致引脚中断触发失败，建议避免过于频繁设置亮度",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB灯带清除显示可能导致引脚中断触发失败，建议避免过于频繁清除显示",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB灯带显示可能导致引脚中断触发失败，建议避免过于频繁执行RGB灯带显示",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "RGB灯带显示渐变色可能导致引脚中断触发失败，建议避免过于频繁显示渐变色",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB灯带移动像素可能导致引脚中断触发失败，建议避免过于频繁移动像素",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB灯带循环移动像素可能导致引脚中断触发失败，建议避免过于频繁执行循环移动像素",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB灯带显示柱状可能导致引脚中断触发失败，建议避免过于频繁执行显示柱状",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "引脚中断可能导致max7219初始化失败",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "引脚中断可能导致max7219显示出错",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "软串口打印可能导致引脚中断触发失败，建议避免过于频繁执行软串口打印",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "软串口obloq mqtt初始化可能导致引脚中断触发失败，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "软串口obloq put请求可能导致引脚中断触发失败，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "软串口obloq http初始化可能导致引脚中断触发失败，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "软串口obloq get请求可能导致引脚中断触发失败，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "软串口obloq post请求可能导致引脚中断触发失败，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "dht读取可能导致软串口obloq接收消息错误，建议降低dht读取频率",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "ds18b20读取可能导致软串口obloq接收消息错误，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5读取可能导致软串口obloq接收消息错误，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "mp3初始化读取可能导致软串口obloq接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "mp3设置音量可能导致软串口obloq接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "mp3设置模式可能导致软串口obloq接收消息错误，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "mp3播放可能导致软串口obloq接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "串口mp3初始化读取可能导致软串口obloq接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "串口mp3设置模式可能导致软串口obloq接收消息错误，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "串口mp3播放可能导致软串口obloq接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "串口mp3设置音量可能导致软串口obloq接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "红外发射可能导致软串口obloq接收消息错误，建议避免过于频繁发送红外",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "软串口bloq发送消息可能导致红外接收出错，建议避免obloq过于频繁发送消息",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB灯带初始化可能导致软串口obloq接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "RGB灯带设置亮度可能导致软串口obloq接收消息错误，建议避免过于频繁设置亮度",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "RGB灯带清除显示可能导致软串口obloq接收消息错误，建议避免过于频繁清除显示",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB灯带显示可能导致软串口obloq接收消息错误，建议避免过于频繁执行RGB灯带显示",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB灯带显示渐变色可能导致软串口obloq接收消息错误，建议避免过于频繁显示渐变色",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "RGB灯带移动像素可能导致软串口obloq接收消息错误，建议避免过于频繁移动像素",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB灯带循环移动像素可能导致软串口obloq接收消息错误，建议避免过于频繁执行循环移动像素",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB灯带显示柱状可能导致软串口obloq接收消息错误，建议避免过于频繁执行显示柱状",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "软串口obloq接收消息可能导致max7219初始化失败",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "软串口obloq接收消息可能导致max7219显示出错",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "软串口打印可能导致软串口obloq接收消息错误，建议避免过于频繁执行软串口打印",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "软串口obloq发送数据可能导致软串口接收数据出错，建议避免obloq过于频繁发送数据",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "软串口obloq发送数据可能导致引脚中断不能触发，建议避免obloq过于频繁发送数据",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "软串口obloq mqtt初始化可能导致软串口接收数据出错，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "软串口obloq put请求可能导致软串口接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "软串口obloq http初始化可能导致软串口接收数据出错，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "软串口obloq get请求可能导致软串口接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "软串口obloq post请求可能导致软串口接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.compatible.shapedBlock": "新版本变动点：由不带变量的事件程序积木更换成自带变量的事件程序积木。",
    "gui.blocklyText.compatible.shapedBlock.variable": "新版本变动点：由独立程序积木更换成事件程序中的变量程序积木",
    "gui.blocklyText.compatible.booleanToRound": "新版本变动点：由条件程序积木变更为数值程序积木。",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "新版本变动点：程序积木中增加软硬串口切换下拉选项。",
    "gui.blocklyText.compatible.mp3.deletePin": "新版本变动点：程序积木中删除引脚下拉选项。",
    "gui.blocklyText.compatible.sen0206.addDropdown": "新版本变动点：程序积木中增加下拉选项。",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "新版本变动点：程序积木中增加引脚下拉选项。",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "新版本变动点：程序积木中修改串口下拉选项。",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "新版本变动点：由事件程序积木变更成条件程序积木。",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "新版本更新点：删除了积木块",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "新版本变动点：将方形下拉框修改为圆形下拉框。",
    "gui.blocklyText.compatible.shouldBlockInitFirst": "小模块存在修改，请重新设计程序",
    "gui.blocklyText.compatible.shapeChanged": "新版本变动点：积木形状发生改变，请重新拖动。",
    "gui.blocklyText.compatible.CCS811init": "新版本变动点：积木参数减少，拆分成2条积木，请重新拖动。",
    "gui.blocklyText.compatible.obloq": "新版本更新点：删除了积木块",
    "gui.blocklyText.pictureai.initToken": "初始化图像AI[SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "从本地文件获取图片[IMAGE]",
    "gui.blocklyText.pictureai.cameraToggle": "切换至摄像头[CAMERA_LIST]",
    "gui.blocklyText.pictureai.getVideoImage": "从摄像头画面截取图片",
    "gui.blocklyText.pictureai.webPicture": "从网址获取图片[TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH]保存视频截图到本地[TEXT]",
    "gui.blocklyText.pictureai.face": "识别图中[TEXT]的人脸信息",
    "gui.blocklyText.pictureai.picture": "识别图中[TEXT]包含的[SELECT]",
    "gui.blocklyText.pictureai.word": "识别图中[TEXT]包含的[SELECT]",
    "gui.blocklyText.pictureai.gesture": "识别图中[TEXT]包含的手势",
    "gui.blocklyText.pictureai.TOPIC_W_A": "文字",
    "gui.blocklyText.pictureai.TOPIC_W_B": "数字",
    "gui.blocklyText.pictureai.TOPIC_W_C": "车牌号",
    "gui.blocklyText.pictureai.TOPIC_W_D": "手写字",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "左脚踝",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "左耳",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "左手肘",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "左眼",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "左臀",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "左膝",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "左嘴角",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "左肩",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "左手腕",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "脖子",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "鼻子",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "右脚踝",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "右耳",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "右手肘",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "右眼",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "右臀",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "右膝",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "右嘴角",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "右肩",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "右手腕",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "头部",
    "gui.blocklyText.pictureai.initUserToken": "切换至独立账户[MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "识别图中[TEXT]的人体关键点",
    "gui.blocklyText.pictureai.faceContrast": "对比图片[TEXT]与图片[TEXT2]中的人脸",
    "gui.blocklyText.pictureai.faceMerge": "融合图片[TEXT]与图片[TEXT2]中的人脸",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "图像主体",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "通用物体和场景",
    "gui.blocklyText.pictureai.TOPIC_P_A": "植物",
    "gui.blocklyText.pictureai.TOPIC_P_B": "动物",
    "gui.blocklyText.pictureai.TOPIC_P_C": "花卉",
    "gui.blocklyText.pictureai.TOPIC_P_D": "果蔬",
    "gui.blocklyText.pictureai.TOPIC_P_E": "货币",
    "gui.blocklyText.pictureai.bodyPointSuccess": "人体关键点识别成功？",
    "gui.blocklyText.pictureai.bodyPointInfo": "人体关键点获取结果 [BODY]的[XY]坐标",
    "gui.blocklyText.pictureai.noPower": "请检查账号是否已开启该功能",
    "gui.blocklyText.pictureai.noPicture": "请先选择图片",
    "gui.blocklyText.pictureai.pictureError": "图片格式不正确",
    "gui.blocklyText.pictureai.noVideo": "请确认摄像头已正常连接，无其它软件占用，并开启摄像头，或重启软件",
    "gui.blocklyText.pictureai.defaultTitleText": "视频窗口",
    "gui.blocklyText.pictureai.videoContainer": "使用[CON]显示摄像头画面",
    "gui.blocklyText.pictureai.TOPIC_stage": "舞台",
    "gui.blocklyText.pictureai.TOPIC_popup": "弹窗",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "新版本变动点：有程序积木被删除。",
    "gui.blocklyText.pictureai.gestureList": "手势[TEXT]",
    "gui.blocklyText.pictureai.networkError": "网络出错，请检查网络连接",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "人脸识别成功？",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "人脸识别获取结果[TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "人脸数目",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "年龄",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "颜值",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "性别",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "眼镜",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "脸型",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "表情",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "人种可信度",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "距离左边",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "距离顶部",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "宽度",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "高度",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "眼耳口鼻坐标",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "人脸遮挡概率",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "模糊度",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "人脸完整度",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "真人",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "卡通人物可能性",
    "gui.blocklyText.pictureai.TOPIC_FACE_emotion": "情绪",
    "gui.blocklyText.pictureai.TOPIC_FACE_mask": "戴口罩",
    "gui.blocklyText.pictureai.TOPIC_FACE_left_eye": "左眼张开度",
    "gui.blocklyText.pictureai.TOPIC_FACE_right_eye": "右眼张开度",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "打开",
    "gui.blocklyText.maqueenPlus.off": "关闭",
    "gui.blocklyText.maqueenPlus.Forward": "前进",
    "gui.blocklyText.maqueenPlus.Backward": "后退",
    "gui.blocklyText.maqueenPlus.left": "左侧",
    "gui.blocklyText.maqueenPlus.right": "右侧",
    "gui.blocklyText.maqueenPlus.all": "全部",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "电机 [DIR] 以 [SPEED] 的速度 [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "电机 [DIR] 停止",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "电机 [DIR] 速度补偿 [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "读取 [DIR] 电机速度",
    "gui.blocklyText.maqueenPlus.readMoterDir": "读取 [DIR] 电机方向 (0:停止 1:前进 2:后退)",
    "gui.blocklyText.maqueenPlus.clearMoterRunedCircle": "清除车轮圈数 [DIR]",
    "gui.blocklyText.maqueenPlus.getMoterRunedCircle": "获取车轮圈数 [DIR]",
    "gui.blocklyText.maqueenPlus.setServo": "舵机 [INDEX] 角度 [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "设置 [DIR] RGB灯显示 [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "读取巡线传感器 [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "读取巡线传感器 [INDEX] 灰度值",
    "gui.blocklyText.maqueenPlus.getVersion": "获取版本",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "读取超声波传感器 trig [TRIG] echo [ECHO] 单位 [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "当接收到 [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "红外数据",
    "gui.blocklyText.maqueenPlus.cm": "厘米",
    "gui.blocklyText.maqueenPlus.inch": "英寸",
    "gui.blocklyText.maqueenPlusV2.setMotorSpeedMove": "设置 [DIR] 方向 [SPEEDDIR] 速度 [SPEED]",
    "gui.blocklyText.maqueenPlusV2.setMotorStop": "设置 [DIR] 停止",
    "gui.blocklyText.maqueenPlusV2.readIR": "读红外线键值",
    "gui.blocklyText.maqueenPlusV2.left": "左侧电机",
    "gui.blocklyText.maqueenPlusV2.right": "右侧电机",
    "gui.blocklyText.maqueenPlusV2.all": "全部电机",
    "gui.blocklyText.maqueenPlusV2.leftLED": "左侧LED灯",
    "gui.blocklyText.maqueenPlusV2.rightLED": "右侧LED灯",
    "gui.blocklyText.maqueenPlusV2.allLED": "全部LED灯",
    "gui.blocklyText.maqueenPlusV2.on": "打开",
    "gui.blocklyText.maqueenPlusV2.off": "关闭",
    "gui.blocklyText.maqueenPlusV2.rotateForward": "前进",
    "gui.blocklyText.maqueenPlusV2.rotateBackward": "后退",
    "gui.blocklyText.maqueenPlusV2.readSensationSensor": "读取巡线传感器 [INDEX] 状态",
    "gui.blocklyText.maqueenPlusV2.setRgb": "设置 [DIR] [COLOR]",
    "gui.blocklyText.maqueenPlusV2.readSensationSensorYscaleValue": "读取巡线传感器 [INDEX] ADC数据",
    "gui.blocklyText.maqueenPlusV2.readUltrasonic": "读取超声波传感器 TRIG脚 [TRIG] ECHO脚 [ECHO] 单位：cm",
    "gui.blocklyText.maqueenPlusV2.getVersion": "读取版本号",
    "gui.blocklyText.esp32.audioRecognition": "识别 [TIME] 秒语音指令",
    "gui.blocklyText.esp32.audioRecordingFree": "录音 释放资源",
    "gui.blocklyText.esp32.audioRecordingStart": "录制音频 存储路径 [PATH] 时长 [TIME] 秒",
    "gui.blocklyText.esp32.audioRecordingInit": "录音 初始化",
    "gui.blocklyText.esp32.audioPlay": "音频 播放 [URL]",
    "gui.blocklyText.esp32.audioControl": "音频 [CONTROL]",
    "gui.blocklyText.esp32.AudioControl.A": "暂停",
    "gui.blocklyText.esp32.AudioControl.B": "继续播放",
    "gui.blocklyText.esp32.AudioControl.C": "停止",
    "gui.blocklyText.esp32.audioSetVolume": "音频 设置音量 [VOLUME]",
    "gui.blocklyText.esp32.audioFree": "音频 释放缓存",
    "gui.blocklyText.esp32.audioInit": "音频 初始化",
    "gui.blocklyText.esp32.audio": "音频",
    "gui.blocklyText.esp32.musicGetTempo": "获取当前节拍",
    "gui.blocklyText.esp32.musicSetTempo": "设置每一拍等同 [TICKS] 分音符，每分钟节拍数 [TEMPO]",
    "gui.blocklyText.esp32.musicSetPlaySpeed": "设置播放速度为 [SPEED] 倍",
    "gui.blocklyText.esp32.musicPlayMelodySet": "播放音乐 [MELODY] [WAIT] [LOOP] 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayMelody": "播放音乐 [MELODY] 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayNoteFrom": "播放音调 从 [MIN] 到 [AMX] 步长 [STEP] 时长 [DURATION] 毫秒 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayNote": "播放音调 [NOTE] 延时 [DELAY] 毫秒 引脚 [PIN]",
    "gui.blocklyText.esp32.musicTone": "音调 [NOTE]",
    "gui.blocklyText.esp32.musicPlayNoteContinuous": "播放连续音调 [NOTE] 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayList": "播放音符列表 [NOTELIST] [WAIT] [LOOP] 引脚 [PIN]",
    "gui.blocklyText.esp32.IsWait.A": "等待",
    "gui.blocklyText.esp32.IsWait.B": "不等待",
    "gui.blocklyText.esp32.IsLoop.A": "循环播放",
    "gui.blocklyText.esp32.IsLoop.B": "播放一次",
    "gui.blocklyText.esp32.getNote": "音符 [NOTE] 节拍 [BEAT]",
    "gui.blocklyText.esp32.musicPlay": "播放音符 [NOTE] 引脚 [PIN]",
    "gui.blocklyText.esp32.restore": "恢复音乐设置",
    "gui.blocklyText.esp32.stopPlay": "停止播放音乐 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPin_default": "默认",
    "gui.blocklyText.mpyUART.input": "从串口读取文本 提示语句 [TITLE]",
    "gui.blocklyText.mpyUART.close": "关闭串口 [UART]",
    "gui.blocklyText.mpyUART.readLength": "串口 [UART] 读取数据 长度 [LENGTH]",
    "gui.blocklyText.mpyUART.read": "串口 [UART] 读取数据",
    "gui.blocklyText.mpyUART.readline": "串口 [UART] 读取一行数据",
    "gui.blocklyText.mpyUART.any": "串口 [UART] 有可读数据？",
    "gui.blocklyText.mpyUART.writeBytes": "串口 [UART] 写入字节数组 [BYTES]",
    "gui.blocklyText.mpyUART.writeByte": "串口 [UART] 写入单字节[BYTE]",
    "gui.blocklyText.mpyUART.init": "串口 [UART] 初始化 波特率 [BAUDRATE] tx [TXPIN] rx [RXPIN]",
    "gui.blocklyText.mpyUART.initTello": "串口 [UART] 初始化 rx [RXPIN] tx [TXPIN] 波特率 [BAUDRATE]",
    "gui.blocklyText.mpyUART.writeStringTello": "串口 [UART] 写入文本 [TEXT] [ENDSYMBOL]",
    "gui.blocklyText.mpyUART.anyTello": "串口 [UART] 有可读数据？",
    "gui.blocklyText.mpyUART.readTello": "串口 [UART] 读取数据",
    "gui.blocklyText.mpyUART.closeTello": "关闭串口 [UART]",
    "gui.blocklyText.mpyUART.writeString": "串口 [UART] 写入文本 [TEXT] [ENDSYMBOL]",
    "gui.blocklyText.mpyUART.no": "不换行",
    "gui.blocklyText.mpyUART.auto": "自动换行",
    "gui.blocklyText.mpyI2C.scan": "I2C 扫描结果",
    "gui.blocklyText.mpyI2C.read": "I2C 地址 [ADDRESS] 读取bytes数量 [BYTES]",
    "gui.blocklyText.mpyI2C.write": "I2C 地址 [ADDRESS] 写入 [DATA]",
    "gui.blocklyText.mpyI2C.init": "初始化I2C SCL [SCLPIN] SDA [SDAPIN] 波特率 [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoInitBoard": "初始化板载I2C 频率 [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoUniversal": "初始化通用I2C SCL [SCLPIN] SDA [SDAPIN] 频率 [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoWrite": "[OBJ] 地址 [ADDRESS] 写入 [DATA]",
    "gui.blocklyText.mpyI2C.maixduinoRead": "[OBJ] 地址 [ADDRESS] 读取bytes数量 [BYTES]",
    "gui.blocklyText.mpyI2C.maixduinoScan": "[OBJ] 扫描结果",
    "gui.blocklyText.esp32.readPinLevel": "[PIN] 外部 [LEVEL] 持续时间",
    "gui.blocklyText.esp32.noPull": "悬空",
    "gui.blocklyText.esp32.pullDown": "下拉",
    "gui.blocklyText.esp32.pullUp": "上拉",
    "gui.blocklyText.esp32.setPinMode": "设置引脚 [PIN] 为 [MODE] 模式 [PULL] 并设置为 [LEVEL]",
    "gui.blocklyText.esp32.leakyOutput": "开漏输出",
    "gui.blocklyText.mpyServo.setServo": "设置舵机 [PIN] 角度为 [ANGLE] 脉宽从 [MIN] 到 [MAX] 最大角度 [ANGLEMAX]",
    "gui.blocklyText.servo.init": "设置引脚 [PIN] 舵机角度为 [ANGLE] 度 定时器 [TIMER]",
    "gui.blocklyText.mpyServo.setAngle": "设置舵机 [PIN] 角度为 [ANGLE]",
    "gui.blocklyText.esp32.shaked": "被摇晃",
    "gui.blocklyText.esp32.thrown": "被抛起",
    "gui.blocklyText.esp32.tilt_forward": "向前倾斜",
    "gui.blocklyText.esp32.tilt_back": "向后倾斜",
    "gui.blocklyText.esp32.tilt_right": "向右倾斜",
    "gui.blocklyText.esp32.tilt_left": "向左倾斜",
    "gui.blocklyText.esp32.tilt_none": "平放",
    "gui.blocklyText.esp32.attitudeEvent": "当掌控板 [ATTITUDE]",
    "gui.blocklyText.esp32.to_high": "↗ 从低到高",
    "gui.blocklyText.esp32.to_low": "↘ 从高到低",
    "gui.blocklyText.esp32.pinLevelEvent": "当引脚 [PIN] 电平 [TRANSITION]",
    "gui.blocklyText.mpyTimer.setEvent": "设置定时器 [INDEX] [MODE] 周期 [PERIOD] 毫秒",
    "gui.blocklyText.mpyTimer.repeated": "重复执行",
    "gui.blocklyText.mpyTimer.delayed": "延时执行",
    "gui.blocklyText.mpyTimer.conditionEvent": "设置定时器 [INDEX] :当 [CONDITION]",
    "gui.blocklyText.mpyTimer.getCount": "定时器 [INDEX] 的计数值",
    "gui.blocklyText.mpyTimer.clearEvent": "清除 定时器/事件 [INDEX]",
    "gui.blocklyText.mpyDictionary.init": "初始化字典 [CONTENT]",
    "gui.blocklyText.mpyDictionary.getValue": "字典 [CONTENT] 键 [KEY] 的值",
    "gui.blocklyText.mpyDictionary.setValue": "字典 [CONTENT] 键 [KEY] 的值设置为 [VALUE]",
    "gui.blocklyText.mpyDictionary.deleteKey": "字典 [CONTENT] 删除键 [KEY]",
    "gui.blocklyText.mpyDictionary.clear": "清空字典 [CONTENT]",
    "gui.blocklyText.mpyDictionary.isInclude": "字典 [CONTENT] 包含键 [KEY]？",
    "gui.blocklyText.mpyDictionary.getLength": "字典 [CONTENT] 长度",
    "gui.blocklyText.mpyDictionary.list": "字典 [CONTENT] [TYPE] 的列表",
    "gui.blocklyText.mpyDictionary.TypeIndex.A": "键",
    "gui.blocklyText.mpyDictionary.TypeIndex.B": "值",
    "gui.blocklyText.mpyList.join": "列表 [LIST] 使用分隔符 [SYMBOL] 组合文本",
    "gui.blocklyText.mpyList.split": "文本 [TEXT] 使用分隔符 [SYMBOL] 制作列表",
    "gui.blocklyText.mpyList.init": "初始化列表 [CONTENT]",
    "gui.blocklyText.mpyList.clear": "清空列表 [CONTENT]",
    "gui.blocklyText.mpyList.getLength": "列表 [CONTENT] 的长度",
    "gui.blocklyText.mpyList.isEmpty": "列表 [CONTENT] 是空的？",
    "gui.blocklyText.mpyList.getValue": "列表 [CONTENT] 索引 [INDEX] 的值",
    "gui.blocklyText.mpyList.getValueRange": "返回列表 [CONTENT] 取 [TYPE1] [NUM1] 项到 [TYPE2] [NUM2] 项",
    "gui.blocklyText.mpyList.insert": "列表 [CONTENT] 在索引 [INDEX] 处插入 [VALUE]",
    "gui.blocklyText.mpyList.setValue": "列表 [CONTENT] 设置索引 [INDEX] 的值为 [VALUE]",
    "gui.blocklyText.mpyList.delete": "列表 [CONTENT] 删除索引 [INDEX] 的值",
    "gui.blocklyText.mpyList.append": "列表 [CONTENT] 将 [APPEND] 添加到末尾",
    "gui.blocklyText.mpyList.extend": "列表 [LIST1] 追加列表 [LIST2]",
    "gui.blocklyText.mpyList.findIndex": "列表 [CONTENT] 查找 [VALUE] 的索引",
    "gui.blocklyText.mpyList.sort": "列表 [LIST] 按 [TYPE] 排序为 [MODE]",
    "gui.blocklyText.mpyList.SortModeIndex.A": "升序",
    "gui.blocklyText.mpyList.SortModeIndex.B": "降序",
    "gui.blocklyText.mpyList.SortTypeIndex.A": "数字",
    "gui.blocklyText.mpyList.SortTypeIndex.B": "字母",
    "gui.blocklyText.mpyList.SortTypeIndex.C": "字母，忽略大小写",
    "gui.blocklyText.mpySet.init": "初始化集合 [CONTENT]",
    "gui.blocklyText.mpySet.update": "集合 [CONTENT1] 更新为与集合 [CONTENT2] 的 [TYPE]",
    "gui.blocklyText.mpySet.TypeIndex.A": "交集",
    "gui.blocklyText.mpySet.TypeIndex.B": "并集",
    "gui.blocklyText.mpySet.TypeIndex.C": "差集",
    "gui.blocklyText.mpySet.addValue": "集合 [CONTENT] 添加 [VALUE]",
    "gui.blocklyText.mpySet.isSubsetSuperset": "集合 [CONTENT1] 为集合 [CONTENT2] 的 [TYPE]？",
    "gui.blocklyText.mpySet.SubsetSupersetType.A": "子集",
    "gui.blocklyText.mpySet.SubsetSupersetType.B": "超集",
    "gui.blocklyText.mpySet.intersectionUnionSetDifference": "取 [TYPE] 集合 [CONTENT1] 集合 [CONTENT2]",
    "gui.blocklyText.mpySet.length": "集合 [CONTENT] 长度",
    "gui.blocklyText.mpySet.pop": "返回随机项 并从集合 [CONTENT] 移除",
    "gui.blocklyText.mpyTuple.init": "初始化元组 [CONTENT]",
    "gui.blocklyText.mpyTuple.minMaxLength": "元组 [CONTENT] [TYPE]",
    "gui.blocklyText.mpyTuple.TypeIndex.A": "最小值",
    "gui.blocklyText.mpyTuple.TypeIndex.B": "最大值",
    "gui.blocklyText.mpyTuple.TypeIndex.C": "长度",
    "gui.blocklyText.mpyTuple.isInclude": "元组 [CONTENT] 包含 [STRING]？",
    "gui.blocklyText.mpyTuple.getValueRange": "返回元组 [CONTENT] 取 [TYPE1] [NUM1] 项到 [TYPE2] [NUM2] 项",
    "gui.blocklyText.mpyTuple.IndexType.A": "第",
    "gui.blocklyText.mpyTuple.IndexType.B": "倒数第",
    "gui.blocklyText.mpyTuple.listToTulpe": "列表 [LIST] 转元组",
    "gui.blocklyText.mpyTuple.getValue": "元组 [CONTENT] 索引 [INDEX] 的值",
    "gui.blocklyText.mpyTuple.tupleToList": "元组 [CONTENT] 转变为列表",
    "gui.blocklyText.esp32.DisplayTypeMenu.A": "普通",
    "gui.blocklyText.esp32.DisplayTypeMenu.B": "反转",
    "gui.blocklyText.esp32.displayInnerImageInXY": "显示内置图像 [IMAGE] 到 x:[X] y:[Y] 模式 [TYPE]",
    "gui.blocklyText.esp32.displayFileSystemImageInXY": "从文件系统加载图像 [IMAGE] 显示到 x:[X] y:[Y] 模式 [TYPE]",
    "gui.blocklyText.weather.GetWeatherWithCityName": "获取[CITY]的[WEATHER]",
    "gui.blocklyText.pictureai.createFaceClass": "创建人脸库 [NAME]",
    "gui.blocklyText.pictureai.pictureaiAddFaceToClass": "命名识别结果为[RES]并添加至人脸库 [CLASSNAME]",
    "gui.blocklyText.pictureai.pictureaiSelectResultsFromClass": "在人脸库[CLASSNAME]中搜索识别结果可信度>=[NUMBER]",
    "gui.blocklyText.pictureai.pictureaiWhenSearchName": "当搜索到名字[NAME]时",
    "gui.blocklyText.pictureai.pictureaiSearchedName": "搜索结果名字",
    "gui.blocklyText.pictureai.pictureaiSelectResultReliability": "搜索结果可信度",
    "gui.blocklyText.pictureai.pleaseUseSelefAccount": "人脸库功能须切换至独立账户",
    "gui.blocklyText.pictureai.addfaceclasserror": "新建人脸库出错",
    "gui.blocklyText.pictureai.faceclassnameerror": "人脸库名称由数字、字母、下划线组成",
    "gui.blocklyText.pictureai.faceclassnameexist": "该人脸库名称已存在",
    "gui.blocklyText.pictureai.addfaceerror": "添加人脸出错",
    "gui.blocklyText.pictureai.selectfaceclasserror": "人脸库搜索出错",
    "gui.blocklyText.pictureai.notfoundmatch": "未找到匹配的人脸",
    "gui.blocklyText.pictureai.facenameerror": "人脸名称由数字、字母、下划线组成",
    "boost.color.any": "任何颜色",
    "boost.color.black": "黑色",
    "boost.color.blue": "蓝色",
    "boost.color.green": "绿色",
    "boost.color.red": "黄色",
    "boost.color.white": "白色",
    "boost.color.yellow": "黄色",
    "boost.getMotorPosition": "马达 [MOTOR_REPORTER_ID] 位置",
    "boost.getTiltAngle": "向[TILT_DIRECTION]倾角",
    "boost.motorDirection.backward": "逆时针",
    "boost.motorDirection.forward": "顺时针",
    "boost.motorDirection.reverse": "反转",
    "boost.motorOff": "关闭马达 [MOTOR_ID]",
    "boost.motorOn": "开启马达 [MOTOR_ID]",
    "boost.motorOnFor": "使马达 [MOTOR_ID]转动 [DURATION] 秒",
    "boost.motorOnForRotation": "使马达 [MOTOR_ID]转动 [ROTATION] 圈",
    "boost.seeingColor": "看到 [COLOR]积木 ?",
    "boost.setLightHue": "将灯光颜色设为[HUE]",
    "boost.setMotorDirection": "将马达[MOTOR_ID] 方向设为 [MOTOR_DIRECTION]",
    "boost.setMotorPower": "将马达 [MOTOR_ID] 速度设为 [POWER]%",
    "boost.tiltDirection.any": "任意方向",
    "boost.tiltDirection.down": "下",
    "boost.tiltDirection.left": "左",
    "boost.tiltDirection.right": "右",
    "boost.tiltDirection.up": "上",
    "boost.whenColor": "当看到[COLOR]积木",
    "boost.whenTilted": "当向[TILT_DIRECTION_ANY]倾斜",
    "ev3.beepNote": "鸣笛[NOTE][TIME]秒",
    "ev3.buttonPressed": "按下按钮[PORT]？",
    "ev3.getBrightness": "亮度",
    "ev3.getDistance": "距离",
    "ev3.getMotorPosition": "马达[PORT]的位置",
    "ev3.motorSetPower": "马达[PORT]功率设为[POWER]%",
    "ev3.motorTurnClockwise": "马达[PORT]正转[TIME]秒",
    "ev3.motorTurnCounterClockwise": "马达[PORT]反转[TIME]秒",
    "ev3.whenBrightnessLessThan": "当亮度 < [DISTANCE]",
    "ev3.whenButtonPressed": "当按下按钮[PORT]",
    "ev3.whenDistanceLessThan": "当距离 < [DISTANCE]",
    "wedo2.getDistance": "距离",
    "wedo2.getTiltAngle": "向[TILT_DIRECTION]倾角",
    "wedo2.isTilted": "向[TILT_DIRECTION_ANY]倾斜？",
    "wedo2.motorDirection.backward": "逆时针",
    "wedo2.motorDirection.forward": "顺时针",
    "wedo2.motorDirection.reverse": "反转",
    "wedo2.motorId.a": "马达A",
    "wedo2.motorId.all": "所有马达",
    "wedo2.motorId.b": "马达B",
    "wedo2.motorId.default": "马达",
    "wedo2.motorOff": "关闭[MOTOR_ID]",
    "wedo2.motorOn": "开启[MOTOR_ID]",
    "wedo2.motorOnFor": "开启[MOTOR_ID][DURATION]秒",
    "wedo2.playNoteFor": "演奏音符[NOTE][DURATION]秒",
    "wedo2.setLightHue": "将灯光颜色设为[HUE]",
    "wedo2.setMotorDirection": "将[MOTOR_ID]转向设为[MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "将[MOTOR_ID]功率设为[POWER]",
    "wedo2.tiltDirection.any": "任意方向",
    "wedo2.tiltDirection.down": "下",
    "wedo2.tiltDirection.left": "左",
    "wedo2.tiltDirection.right": "右",
    "wedo2.tiltDirection.up": "上",
    "wedo2.whenDistance": "当距离[OP][REFERENCE]",
    "wedo2.whenTilted": "当向[TILT_DIRECTION_ANY]倾斜",
    "gui.blocklyText.video.notfound": "无设备",
    "gui.blocklyText.machinelearning.drawResults": "[TOGGLE]绘制识别结果",
    "gui.blocklyText.machinelearning.initKNNImageClass": "初始化KNN分类器",
    "gui.blocklyText.machinelearning.imageClassificationFromCamera": "KNN将摄像头画面分类为[TEXT]",
    "gui.blocklyText.machinelearning.imageClassificationFromDir": "KNN将本地文件夹图片[DIR]分类为[TEXT]",
    "gui.blocklyText.machinelearning.startKNNTrain": "KNN开始分类训练",
    "gui.blocklyText.machinelearning.predictImageClass": "KNN[TOGGLE]识别摄像头画面分类",
    "gui.blocklyText.machinelearning.getImageClassification": "KNN识别分类结果",
    "gui.blocklyText.machinelearning.getImageClassificationReliability": "KNN识别可信度",
    "gui.blocklyText.machinelearning.clearImageClassificationLabel": "KNN清除标签[LABEL]",
    "gui.blocklyText.machinelearning.clearImageClassificationModel": "KNN清除分类模型数据",
    "gui.blocklyText.machinelearning.loadImageClassificationModel": "KNN从[PATH]加载电脑端分类模型",
    "gui.blocklyText.machinelearning.exportImageClassificationModel": "KNN保存分类模型",
    "gui.blocklyText.machinelearning.traceFace": "FaceAPI初始化人脸追踪",
    "gui.blocklyText.machinelearning.drawFaceAPI": "FaceAPI[TOGGLE]标记人脸关键点",
    "gui.blocklyText.machinelearning.traceFaceNumber": "FaceAPI人脸数目",
    "gui.blocklyText.machinelearning.getFaceByIndex": "FaceAPI获取第[INDEX]个人脸的[PART]坐标[TYPE]",
    "gui.blocklyText.machinelearning.initfaceContrast": "FaceAPI初始化特定人脸识别",
    "gui.blocklyText.machinelearning.refFaceFromCamera": "FaceAPI从摄像头采集人脸数据标记为[NAME]",
    "gui.blocklyText.machinelearning.refFaceFromDir": "FaceAPI从本地文件夹[DIR]采集人脸数据以文件夹名标记",
    "gui.blocklyText.machinelearning.faceAPIFindFace": "FaceAPI识别一次摄像头中的人脸",
    "gui.blocklyText.machinelearning.allowFaceAPIResult": "FaceAPI人脸识别识别结果",
    "gui.blocklyText.machinelearning.allowFaceAPIResultScore": "FaceAPI人脸识别识别结果可信度",
    "gui.blocklyText.machinelearning.clearFaceAPIModel": "FaceAPI清除人脸模型数据",
    "gui.blocklyText.machinelearning.ml5WhenSearchFaceName": "当搜索到标签[NAME]可信度>=[VALUE]%时",
    "gui.blocklyText.machinelearning.machinelearningTracePose": "PoseNet初始化姿态追踪",
    "gui.blocklyText.machinelearning.drawPoseNet": "PoseNet[TOGGLE]标记姿态关键点",
    "gui.blocklyText.machinelearning.getPoseByIndex": "PoseNet获取第[INDEX]个姿态的[PART]坐标[TYPE]",
    "gui.blocklyText.machinelearning.initImageClassify": "初始化物体识别图像分类器(mobileNet)",
    "gui.blocklyText.machinelearning.toggleImageClassify": "[TOGGLE]物体识别图像分类器 实时识别",
    "gui.blocklyText.machinelearning.imageClassifyResult": "物体识别识别结果",
    "gui.blocklyText.machinelearning.imageClassifyResultScore": "物体识别识别可信度",
    "gui.blocklyText.huskylens.initVisualModal": "初始化AI可视化工具为[TYPE]",
    "gui.blocklyText.huskylens.classifyImageFromCamera": "将电脑摄像头画面加入分类器[TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromHuskylens": "将HuskyLens摄像头画面加入分类器[TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromDir": "将本地文件夹图片[DIR]加入分类器[TEXT]",
    "gui.blocklyText.huskylens.visualModalClearLabel": "清除分类[LABEL]",
    "gui.blocklyText.huskylens.visualModalClearAllLabels": "清除分类器所有分类",
    "gui.blocklyText.huskylens.deployClassificationToHuskylens": "训练模型并部署到HuskyLens",
    "gui.blocklyText.machinelearning.loadlmodel": "模型路径",
    "gui.blocklyText.pictureai.TOPIC_BODY_mouth": "嘴巴",
    "gui.blocklyText.pictureai.TOPIC_BODY_jaw_outline": "下颌",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye_brow": "左眼眉",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye_brow": "右眼眉",
    "gui.blocklyText.huskylens.videoContainer": "使用[CON]显示电脑摄像头画面",
    "gui.blocklyText.huskylens.videoToggle": "[VIDEO_STATE]电脑摄像头",
    "gui.blocklyText.huskylens.cameraToggle": "切换至电脑摄像头[CAMERA_LIST]",
    "gui.blocklyText.huskylens.exportImage": "电脑摄像头拍照一张保存到本地文件夹 [TEXT]",
    "gui.blocklyText.dialog.ml5.loadModel": "加载模型中，页面可能会出现卡顿，请稍候...",
    "gui.blocklyText.dialog.ml5.ifLagTryEnableGPUInSettings": "如运行卡顿，可尝试打开设置中的GPU加速功能",
    "gui.blocklyText.dialog.ml5.reInitPictureClassify": "分类器中已存在图片数据，重新初始化会清空现有数据，确认继续吗？",
    "gui.blocklyText.dialog.ml5.whenDeleteImageAfterTrain": "该图片数据已经经过训练，删除后，需要重新训练才能生效",
    "gui.blocklyText.dialog.ml5.renameLabel": "标签[LABEL]已存在，是否合并?",
    "gui.blocklyText.dialog.ml5.modelIsEmpty": "该模型中无分类数据，请先训练模型",
    "gui.blocklyText.dialog.ml5.shouldInitKNN": "请先初始化KNN分类器",
    "gui.blocklyText.dialog.huskylens.emptyAllImage": "该操作会删除所有分类，确认继续吗?",
    "gui.blocklyText.dialog.huskylens.HuskyLensTakePhotoError": "从HuskyLens拍照失败，请重新尝试",
    "gui.blocklyText.dialog.huskylens.overImageMaxLen": "[TYPE]分类器数量上限为[LEN]张，已超出上限，请整理后重试",
    "gui.blocklyText.dialog.huskylens.notAllowChinese": "分类名称应由不超过50位的数字和字母组成，不能含有中文及特殊字符",
    "gui.blocklyText.dialog.ml5.isNotKNN": "人脸识别类型的分类器无法加入KNN训练",
    "gui.blocklyText.machinelearning.start": "开始",
    "gui.blocklyText.machinelearning.stop": "停止",
    "gui.blocklyText.huskyLensPro.ML5MODEL.A": "KNN分类",
    "gui.blocklyText.huskyLensPro.ML5MODEL.B": "人脸识别",
    "gui.blocklyText.huskyLensPro.changeAlgorithm": "HuskyLens 切换到 [ALGORITHM] 算法 直到成功",
    "gui.blocklyText.huskyLensPro.learndIDCount": "HuskyLens 获取已学习ID总数",
    "gui.blocklyText.huskyLensPro.isOnTheScreen": "HuskyLens [BOXARROW] 在画面中？",
    "gui.blocklyText.huskyLensPro.parametersNearTheCenter": "HuskyLens 获取靠近中心 [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.idIsLearned": "HuskyLens ID [ID] 已经学习？",
    "gui.blocklyText.huskyLensPro.idIsOnTheScreen": "HuskyLens ID [ID] [BOXARROW] 在画面中？",
    "gui.blocklyText.huskyLensPro.idParametersNearTheCenter": "HuskyLens ID [ID] [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.boxCount": "HuskyLens 获取 [BOXARROW] 的总数",
    "gui.blocklyText.huskyLensPro.boxarrowParameter": "HuskyLens 获取第 [INDEX] 个 [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.idBoxCount": "HuskyLens 获取ID [ID] [BOXARROW] 的总数",
    "gui.blocklyText.huskyLensPro.idBoxarrowParameter": "HuskyLens 获取ID [ID] 第 [INDEX] 个 [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.learnOnce": "HuskyLens 当前算法自动学习一次ID [ID]",
    "gui.blocklyText.huskyLensPro.clearLearn": "HuskyLens 遗忘当前算法的所有学习数据",
    "gui.blocklyText.huskyLensPro.setIDname": "HuskyLens 设置当前算法ID [ID] 名字为 [NAME]",
    "gui.blocklyText.huskyLensPro.writeOSD": "HuskyLens 屏幕叠加显示文字 [NAME] 在x [X] y [Y]",
    "gui.blocklyText.huskyLensPro.clearOSD": "HuskyLens 清除屏幕显示的文字",
    "gui.blocklyText.huskyLensPro.requestPicture": "HuskyLens [TYPE] 并保存到本地文件夹 [PATH]",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.A": "拍照",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.B": "截屏",
    "gui.blocklyText.huskyLensPro.TakeScreenshotToSDCard": "HuskyLens 触发 [TYPE] 保存到SD卡",
    "gui.blocklyText.huskyLensPro.xCenter": "X中心",
    "gui.blocklyText.huskyLensPro.yCenter": "Y中心",
    "gui.blocklyText.huskyLensPro.width": "宽度",
    "gui.blocklyText.huskyLensPro.height": "高度",
    "gui.blocklyText.huskyLensPro.xStart": "X起点",
    "gui.blocklyText.huskyLensPro.yStart": "Y起点",
    "gui.blocklyText.huskyLensPro.xEnd": "X终点",
    "gui.blocklyText.huskyLensPro.yEnd": "Y终点",
    "gui.blocklyText.tello.telloSetPitchRollYaw": "设置遥控器四个通道杆量 横滚[ROLL] 俯仰[PITCH] 油门[STRENGTH] 偏航[YAW]",
    "gui.blocklyText.tello.telloQuerySpeed": "当前速度",
    "gui.blocklyText.tello.telloTime": "电机运行时间(s)",
    "gui.blocklyText.tello.telloWIFISign": "Wi-Fi信噪比",
    "gui.blocklyText.tello.telloSDKVersion": "Tello SDK版本",
    "gui.blocklyText.tello.telloSN": "Tello SN码",
    "gui.blocklyText.tello.telloFlyToXY": "飞往(相对于飞机的) x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s",
    "gui.blocklyText.tello.reset": "重置",
    "gui.blocklyText.tello.telloFlyCurve": "飞弧线经 x1[X1]cm y1[Y1]cm z1[Z1]cm 和 x2[X2]cm y2[Y2]cm z2[Z2]cm 速度[SPEED]cm/s",
    "gui.blocklyText.tello.telloStopEmerygency": "紧急停机",
    "gui.blocklyText.tello.telloGetHeight": "相对高度(cm)",
    "gui.blocklyText.tello.telloBarometer": "气压计高度(m)",
    "gui.blocklyText.tello.telloTofDistance": "TOF高度(cm)",
    "gui.blocklyText.tello.telloTempMin": "主板最低温度(°C)",
    "gui.blocklyText.tello.telloTempMax": "主板最高温度(°C)",
    "gui.blocklyText.tello.TelloAttitudePitch": "俯仰轴姿态角(°)",
    "gui.blocklyText.tello.TelloAttitudeRoll": "横滚轴姿态角(°)",
    "gui.blocklyText.tello.TelloAttitudeYaw": "平移轴姿态角(°)",
    "gui.blocklyText.tello.telloAccelerationX": "X轴加速度(0.001g)",
    "gui.blocklyText.tello.telloAccelerationY": "Y轴加速度(0.001g)",
    "gui.blocklyText.tello.telloAccelerationZ": "Z轴加速度(0.001g)",
    "gui.blocklyText.tello.telloSpeedX": "X轴速度(cm/s)",
    "gui.blocklyText.tello.telloSpeedY": "Y轴速度(cm/s)",
    "gui.blocklyText.tello.telloSpeedZ": "Z轴速度(cm/s)",
    "gui.blocklyText.tello.telloFlyToMissionPad": "飞往挑战卡的 x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.TelloFlyCurveMissionPad": "飞弧线经挑战卡下 x1[X1]cm y1[Y1]cm z1[Z1]cm 和 x2[X2]cm y2[Y2]cm z2[Z2]cm 速度[SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.telloJumpInMissionPad": "跳跃沿挑战卡 x[X]cm y[Y]cm z[Z]cm 速度 [SPEED]cm/s 偏航 [YAW] 度 从 Mid1 [MID1] 到 Mid2 [MID2]",
    "gui.blocklyText.tello.telloRotateWithYaw": "飞机相对挑战卡[MID]旋转至[YAW]角度",
    "gui.blocklyText.tello.telloStopAndHover": "停止运动并悬停",
    "gui.blocklyText.telloesp32.telloGetStatus": "获取一次状态码存入结果, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloReturnStatusChallenge": "从结果中取 [STATUSCHALLENGE]",
    "gui.blocklyText.telloesp32.statusChallengeX": "相对挑战卡X坐标",
    "gui.blocklyText.telloesp32.statusChallengeY": "相对挑战卡Y坐标",
    "gui.blocklyText.telloesp32.statusChallengeZ": "相对挑战卡Z坐标",
    "gui.blocklyText.telloesp32.statusChallengePitch": "相对挑战卡俯仰角",
    "gui.blocklyText.telloesp32.statusChallengeYaw": "相对挑战卡偏航角",
    "gui.blocklyText.telloesp32.statusChallengeRoll": "相对挑战卡横滚角",
    "gui.blocklyText.telloesp32.statusChallengeId": "挑战卡ID",
    "gui.blocklyText.telloesp32.telloReturnStatusAttitude": "从结果中取 [STATUSATTITUDE]",
    "gui.blocklyText.telloesp32.statusAttitudePitch": "俯仰角",
    "gui.blocklyText.telloesp32.statusAttitudeYaw": "偏航角",
    "gui.blocklyText.telloesp32.statusAttitudeRoll": "横滚角",
    "gui.blocklyText.telloesp32.statusAttitudeXSpeed": "X轴速度",
    "gui.blocklyText.telloesp32.statusAttitudeYSpeed": "Y轴速度",
    "gui.blocklyText.telloesp32.statusAttitudeZSpeed": "Z轴速度",
    "gui.blocklyText.telloesp32.statusAttitudeXAcc": "X轴加速度",
    "gui.blocklyText.telloesp32.statusAttitudeYAcc": "Y轴加速度",
    "gui.blocklyText.telloesp32.statusAttitudeZAcc": "Z轴加速度",
    "gui.blocklyText.telloesp32.telloReturnStatusNormal": "从结果中取 [STATUSNORMAL]",
    "gui.blocklyText.telloesp32.statusNormalMaxTemp": "最高温度",
    "gui.blocklyText.telloesp32.statusNormalLowTemp": "最低温度",
    "gui.blocklyText.telloesp32.statusNormalTof": "ToF距离",
    "gui.blocklyText.telloesp32.statusNormalHeight": "相对高度",
    "gui.blocklyText.telloesp32.statusNormalBat": "当前电量",
    "gui.blocklyText.telloesp32.statusNormalBaro": "气压高度",
    "gui.blocklyText.telloesp32.statusNormalMotor": "电机时间",
    "gui.blocklyText.tello.MissionPad": "挑战卡编号",
    "gui.blocklyText.tello.MissionPadX": "挑战卡X(cm)",
    "gui.blocklyText.tello.MissionPadY": "挑战卡Y(cm)",
    "gui.blocklyText.tello.telloMissionPadZ": "挑战卡Z(cm)",
    "gui.blocklyText.tello.telloCommandResponse": "回复指令",
    "gui.blocklyText.tello.direction_front": "前视",
    "gui.blocklyText.tello.direction_blow": "下视",
    "gui.blocklyText.tello.direction_all": "所有",
    "gui.blocklyText.tello.mid": "挑战卡",
    "gui.blocklyText.tello.midfastest": "m-1",
    "gui.blocklyText.tello.midlatest": "m-2",
    "gui.blocklyText.tello.telloToggleMon": "[TOGGLE]挑战卡探测",
    "gui.blocklyText.tello.telloSetMDireaction": "设置探测位置[POSITION]",
    "gui.blocklyText.tello.telloSetWIFI": "修改Tello的Wi-Fi为热点[NAME] 密码[PASSWD]",
    "gui.blocklyText.tello.telloToggleGroup": "切换为station模式，连入热点[NAME] 密码[PASSWD]",
    "gui.blocklyText.huskyLensPro.QRRecognition": "二维码识别",
    "gui.blocklyText.huskyLensPro.barcodeRecognition": "条形码识别",
    "gui.blocklyText.huskyLensPro.saveFailed": "保存图片失败",
    "gui.blocklyText.huskyLensPro.yes": "是的",
    "gui.blocklyText.huskyLensPro.cancel": "取消",
    "gui.blocklyText.huskyLensPro.promptInitializeClassifier": "请先初始化图片分类器",
    "gui.blocklyText.huskyLensPro.promptInitializeKNNClassifier": "请先初始化KNN分类器",
    "gui.blocklyText.huskyLensPro.promptAddClassificationData": "请先添加分类数据",
    "gui.blocklyText.huskyLensPro.setTitleText": "HuskyLens AI-KNN图像分类",
    "gui.blocklyText.huskyLensPro.tooManyPictures": "图片数目太多，仅提取前五十张",
    "gui.blocklyText.huskyLensPro.checkPath": "请检查路径[PATH]下是否包含图片",
    "gui.blocklyText.huskyLensPro.success": "成功",
    "gui.blocklyText.huskyLensPro.importImg": "本次成功导入[COUNT1]张图片，[COUNT2]张失败",
    "gui.blocklyText.huskyLensPro.recognitionResult": "识别结果为：",
    "gui.blocklyText.telloGroup.telloRise": "上升",
    "gui.blocklyText.telloGroup.telloDown": "下降",
    "gui.blocklyText.telloGroup.telloGoahead": "前进",
    "gui.blocklyText.telloGroup.telloBack": "后退",
    "gui.blocklyText.telloGroup.telloTurnLeft": "向左",
    "gui.blocklyText.telloGroup.telloTurnRight": "向右",
    "gui.blocklyText.telloGroup.telloSetFly": "[DIRECTION]  [NUMS]cm",
    "gui.blocklyText.telloGroup.telloClockwiseRotation": "顺时针",
    "gui.blocklyText.telloGroup.telloCounterclockwiseSelection": "逆时针",
    "gui.blocklyText.telloGroup.telloClockRotation": "[CLOCKWISE] 旋转 [NUMS]度",
    "gui.blocklyText.telloGroup.setTelloIndexWithSN": "设置Tello SN码[SN] 编号为 [INDEX]",
    "gui.blocklyText.telloGroup.setTelloIndexWithSSID": "设置Tello SSID [SSID] 编号为 [INDEX]",
    "gui.blocklyText.telloGroup.scanTelloInNetwork": "在网络中扫描 [NUM] 台Tello 超时[TIME]秒",
    "gui.blocklyText.telloGroup.telloSynchronousCommand": "同步指令 等待时间[TIME]秒",
    "gui.blocklyText.tello.telloVideoToggle": "[VIDEO_STATE] Tello 摄像头",
    "gui.blocklyText.telloGroup.TEMPH": "最高温度",
    "gui.blocklyText.telloGroup.TEMPL": "最低温度",
    "gui.blocklyText.telloGroup.NUM": "编号",
    "gui.blocklyText.telloGroup.pitch": "俯仰轴",
    "gui.blocklyText.telloGroup.roll": "横滚轴",
    "gui.blocklyText.telloGroup.translation": "平移轴",
    "gui.blocklyText.telloGroup.telloAccelerationXYZ": "[XYZ]轴加速度(0.001g)",
    "gui.blocklyText.telloGroup.telloSpeedXYZ": "[XYZ]轴速度(cm/s)",
    "gui.blocklyText.telloGroup.TelloAttitudePitch": "[ANGEL]姿态角(°)",
    "gui.blocklyText.telloGroup.telloTempMinAndMax": "主板[TEMP](°C)",
    "gui.blocklyText.tello.telloDisplayRollText": "滚动显示文字 方向[DIRECT] 移动频率[SPEED] 文字[TEXT] 颜色[COLOR]",
    "gui.blocklyText.tello.telloDisplayText": "显示单个字符 [TEXT] 颜色[COLOR]",
    "gui.blocklyText.tello.telloRollText_up": "向上",
    "gui.blocklyText.tello.telloRollText_down": "向下",
    "gui.blocklyText.tello.telloRollText_left": "向左",
    "gui.blocklyText.tello.telloRollText_right": "向右",
    "gui.blocklyText.telloGroup.initTelloGroup": "断开连接",
    "gui.blocklyText.tello.shutdownForPower": "电量已不足[POWER]，Tello即将关机",
    "gui.blocklyText.tello.portCantUse": "服务出错，端口[PORT]访问失败, 请检查是否被其他程序占用",
    "gui.blocklyText.tello.telloReadESP32Version": "扩展模块固件版本",
    "gui.blocklyText.tello.telloSSID": "扩展模块SSID",
    "gui.blocklyText.tello.telloLightLEDWithColor": "LED颜色[COLOR]",
    "gui.blocklyText.tello.telloLightLEDWithColor_rgb": "LED颜色 红:[RGBR] 绿:[RGBG] 蓝:[RGBB]",
    "gui.blocklyText.tello.telloBreathLEDWithColor": "LED呼吸灯 颜色[COLOR]频率[TIME](Hz)",
    "gui.blocklyText.tello.telloBreathLEDWithColor_rgb": "LED呼吸灯 红:[RGBR] 绿:[RGBG]  蓝:[RGBB] 频率:[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor": "LED闪烁 颜色1[COLOR1]和颜色2[COLOR2]频率[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor_rgb": "LED闪烁 红1:[RGBR1] 绿1:[RGBG1] 蓝1:[RGBB1] - 红2:[RGBR2] 绿2:[RGBG2] 蓝2:[RGBB2] 频率:[TIME](Hz)",
    "gui.blocklyText.tello.telloShowPattern": "显示图案[MARK]",
    "gui.blocklyText.tello.telloShowPatternWhenStart": "设定开机图案[MARK]",
    "gui.blocklyText.tello.telloShowRollPattern": "滚动显示图案 方向[DIRECT] 移动频率[SPEED] 图案[MARK]",
    "gui.blocklyText.tello.telloSetLight": "设置屏幕亮度[NUM]",
    "gui.blocklyText.tello.telloSendCMD": "发送自定义命令[CMD]",
    "gui.blocklyText.tello.telloSendDirective": "发送明码消息[CMD]",
    "gui.blocklyText.tello.telloReceiveMsg": "接收到字符串消息[MSG]",
    "gui.blocklyText.tello.telloReceiveCustomNum": "接收到自定义回复数值",
    "gui.blocklyText.tello.connectTelloWIFIError": "连接Tello Wi-Fi失败，请尝试手动连接Wi-Fi",
    "gui.blocklyText.tello.connectTelloError": "连接Tello失败，请确认设备状态，重新连接。你也可以尝试手动连接Wi-Fi",
    "gui.blocklyText.tello.tempIsHigh": "Tello温度过高，即将关机",
    "gui.blocklyText.tello.scanErrorWithOpenWlan": "扫描出错了，请检查是否打开Wi-Fi,你也可以尝试手动连接Wi-Fi",
    "gui.blocklyText.tello.noTelloFound": "暂无可用的Tello设备，你也可以尝试手动连接Wi-Fi",
    "gui.blocklyText.tello.connectIsBroke": "Tello连接已断开，请检查设备",
    "gui.blocklyText.tello.firstConnectTello": "请先连接Tello",
    "gui.blocklyText.tello.telloQueryWifiVersion": "扩展模块Wi-Fi版本",
    "gui.blocklyText.tello.telloQueryHardwareVersion": "获取硬件版本",
    "gui.blocklyText.tello.telloToggleTOF": "[TOGGLE] TOF模组",
    "gui.blocklyText.tello.telloToggleTOF_enable": "启用",
    "gui.blocklyText.tello.telloToggleTOF_disabled": "停用",
    "gui.blocklyText.tello.activeTelloFirst": "连接失败，新飞机未激活，请按照飞机说明书进行激活",
    "gui.blocklyText.tello.startPaddle": "进入起桨模式",
    "gui.blocklyText.tello.stopPaddle": "退出起桨模式",
    "gui.blocklyText.tello.togglePaddle": "[TOGGLE]起桨模式",
    "gui.blocklyText.tello.telloTogglePaddle_enable": "进入",
    "gui.blocklyText.tello.telloTogglePaddle_disabled": "退出",
    "gui.blocklyText.tello.telloThrowAway": "5秒内抛飞",
    "gui.blocklyText.tello.telloResetPattern": "恢复默认开机图案",
    "gui.blocklyText.tello.telloQueryTelloVersion": "Tello固件版本",
    "gui.blocklyText.tello.telloQueryBatActive": "Tello 查询电池电量",
    "gui.blocklyText.tello.telloReadTOF": "读取TOF测距 (mm)",
    "gui.blocklyText.telloGroup.allTellos": "所有的",
    "gui.blocklyText.tello.notAllowInput": "请输入数字或英文字符,不能包含其它特殊字符",
    "gui.blocklyText.tello.checkFirewallAndTello": "连接已断开，请检查tello连接状态，同时请将软件加入电脑防火墙白名单",
    "gui.blocklyText.telloGroup.checkWlanNum": "请确认本机连接到正确的网络，若已连接到多个网络，请先断开其他连接",
    "gui.blocklyText.telloGroup.telloIsNotExist": "[INDEX]号Tello不存在",
    "gui.blocklyText.telloGroup.findTelloWithLost": "找到[FOUND]个,[NOTFOUND]未找到,ip:[IP]",
    "gui.blocklyText.telloGroup.findTello": "找到[FOUND]个,ip:[IP]",
    "gui.blocklyText.telloGroup.cantRepeatScan": "正在扫描中，请勿重复扫描..",
    "gui.blocklyText.telloGroup.setIndexForTelloFirst": "请先设置编号",
    "gui.blocklyText.maixduino.programMain": "K210 Maixduino 主程序",
    "gui.blocklyText.maixduino.LCDInit": "屏幕初始化为频率 [FREQ] 背景 [COLOR] 反色 [INVERT]",
    "gui.blocklyText.maixduino.LCDShowImageFromPath": "屏幕显示图像 path:[PATH]",
    "gui.blocklyText.maixduino.LCDShowImageFromImage": "屏幕显示图像 image:[IMAGE]",
    "gui.blocklyText.maixduino.LCDShowText": "屏幕显示文字 [TEXT] 在坐标X [X] Y [Y] 文字 [TEXT_COLOR] 背景 [BACKGROUND_COLOR]",
    "gui.blocklyText.maixduino.LCDFill": "屏幕显示为 [COLOR] (清屏)",
    "gui.blocklyText.maixduino.LCDRotation": "屏幕旋转 [ANGLE]",
    "gui.blocklyText.maixduino.LCDMirror": "屏幕镜像 [MIRROR]",
    "gui.blocklyText.maixduino.LCDSize": "屏幕分辨率 [SIZE]",
    "gui.blocklyText.maixduino.LCDRGB888": "RGB888 红 [RED] 绿 [GREEN] 蓝 [BLUE]",
    "gui.blocklyText.maixduino.LCDColor": "颜色 [COLOR]",
    "gui.blocklyText.blockTitle.maixduino.Camera": "摄像头",
    "gui.blocklyText.maixduino.CameraInit": "初始化为 [MODE] 参数 [SETTINGS]",
    "gui.blocklyText.maixduino.CameraGetImage": "相机获取图像",
    "gui.blocklyText.maixduino.CameraControl": "选择 [CONTROL] 摄像头(双目)",
    "gui.blocklyText.maixduino.CameraHorizontalMirroring": "相机 [CONTROL] 水平镜像",
    "gui.blocklyText.maixduino.CameraVerticalMirroring": "相机 [CONTROL] 垂直镜像",
    "gui.blocklyText.maixduino.CameraColorBarMode": "相机 [CONTROL] 彩条模式",
    "gui.blocklyText.maixduino.CameraSettingParameters": "相机设置 [PARAMETERS] 为 [VALUE]",
    "gui.blocklyText.maixduino.CameraSettingWindow": "相机设置窗口 宽为[WIDTH] 高为[HEIGHT]",
    "gui.blocklyText.maixduino.CameraGetParameters": "相机获取 [PARAMETERS]",
    "gui.blocklyText.blockTitle.audioAndVideo": "音视频",
    "gui.blocklyText.maixduino.AudioPlay": "音频播放 [PATH] [WAIT]",
    "gui.blocklyText.maixduino.AudioVolume": "音频音量 [VOLUME] %",
    "gui.blocklyText.maixduino.AudioState": "获取音频播放状态",
    "gui.blocklyText.maixduino.AudioRecord": "音频录制 [PATH] 时长 [TIME] s [WAIT]",
    "gui.blocklyText.maixduino.VideoPlay": "视频播放 [PATH] [WAIT]",
    "gui.blocklyText.maixduino.VideoVolume": "视频音量 [VOLUME] %",
    "gui.blocklyText.maixduino.VideoState": "获取视频播放状态",
    "gui.blocklyText.maixduino.VideoRecord": "视频录制 [PATH] 时长 [TIME] s [WAIT]",
    "gui.blocklyText.maixduino.digitalRead": "读取数字引脚 [PIN]",
    "gui.blocklyText.maixduino.analogRead": "读取模拟引脚 [PIN]",
    "gui.blocklyText.maixduino.digitalWrite": "设置数字引脚 [PIN] 输出 [LEVEL]",
    "gui.blocklyText.maixduino.PWMInit": "初始化PWM引脚 [PIN] 频率 [FREQ] Hz 定时器 [TIMER]",
    "gui.blocklyText.maixduino.PWMWrite": "设置PWM引脚 [PIN] 输出占空比 [DUTY]",
    "gui.blocklyText.maixduino.ticksDiff": "返回运行时间差，开始时间 [STARTTIME] 结束时间 [ENDTIME]",
    "gui.blocklyText.maixduino.runTimer": "运行时间 [TIMETYPE]",
    "gui.blocklyText.maixduino.gc": "[GC] 垃圾回收",
    "gui.blocklyText.maixduino.systemReset": "复位",
    "gui.blocklyText.maixduino.print": "打印 [DATA]",
    "gui.blocklyText.maixduino.LevelMenu.A": "高电平",
    "gui.blocklyText.maixduino.LevelMenu.B": "低电平",
    "gui.blocklyText.maixduino.MirrorMenu.A": "开启",
    "gui.blocklyText.maixduino.MirrorMenu.B": "关闭",
    "gui.blocklyText.maixduino.SelectMenu.A": "0号",
    "gui.blocklyText.maixduino.SelectMenu.B": "1号",
    "gui.blocklyText.maixduino.SettingParametersMenu.A": "对比度",
    "gui.blocklyText.maixduino.SettingParametersMenu.B": "亮度值",
    "gui.blocklyText.maixduino.SettingParametersMenu.C": "增益值",
    "gui.blocklyText.maixduino.SettingParametersMenu.D": "饱和度",
    "gui.blocklyText.maixduino.GetParametersMenu.A": "分辨率宽度",
    "gui.blocklyText.maixduino.GetParametersMenu.B": "分辨率高度",
    "gui.blocklyText.maixduino.GetParametersMenu.C": "当前增益值",
    "gui.blocklyText.maixduino.GetParametersMenu.D": "帧缓存图像",
    "gui.blocklyText.maixduino.GetParametersMenu.E": "硬件型号ID",
    "gui.blocklyText.maixduino.TimeTypeMenu.A": "秒",
    "gui.blocklyText.maixduino.TimeTypeMenu.B": "毫秒",
    "gui.blocklyText.maixduino.TimeTypeMenu.C": "微秒",
    "gui.blocklyText.maixduino.GcMenu.A": "自动",
    "gui.blocklyText.maixduino.GcMenu.B": "运行",
    "gui.blocklyText.maixduino.LcdSizeMenu.A": "宽度",
    "gui.blocklyText.maixduino.LcdSizeMenu.B": "高度",
    "gui.blocklyText.maixduino.WaitMenu.A": "等待",
    "gui.blocklyText.maixduino.WaitMenu.B": "后台",
    "gui.blocklyText.maixduino.CameraModeMenu.A": "单目",
    "gui.blocklyText.maixduino.CameraModeMenu.B": "双目",
    "gui.blocklyText.maixduino.AngleMenu.A": "0°",
    "gui.blocklyText.maixduino.AngleMenu.B": "90°",
    "gui.blocklyText.maixduino.AngleMenu.C": "180°",
    "gui.blocklyText.maixduino.AngleMenu.D": "270°",
    "gui.blocklyText.maixduino.camerainit.frameformat": "帧格式",
    "gui.blocklyText.maixduino.camerainit.frameformat.RGB565": "彩色图",
    "gui.blocklyText.maixduino.camerainit.frameformat.GRAYSCALE": "灰度图",
    "gui.blocklyText.maixduino.camerainit.framesize": "帧大小",
    "gui.blocklyText.maixduino.camerainit.imagecapture": "图像捕捉",
    "gui.blocklyText.maixduino.camerainit.imagecapture.on": "开启",
    "gui.blocklyText.maixduino.camerainit.imagecapture.off": "关闭",
    "gui.blocklyText.maixduino.camerainit.skipframes": "跳帧数",
    "gui.extension.AI.name": "人工智能",
    "gui.blocklyText.AI.anchorPoint": "锚点参数 [POINT]",
    "gui.blocklyText.AI.classNames": "物品名称 [NAMES]",
    "gui.blocklyText.AI.modelLoadingAddress": "模型加载 [ADDRESS]",
    "gui.blocklyText.AI.modelLoadingPath": "模型路径 [PATH]",
    "gui.blocklyText.AI.yolo2Init": "yolo2 初始化 网络模型 [MODEL] 概率阈值 [PT] box_iou门限 [BT] 锚点数[APC] 锚点参数 [AP]",
    "gui.blocklyText.AI.yolo2Run": "yolo2 运行网络 模型 [MODEL] 图像 [IMAGE]",
    "gui.blocklyText.AI.yolo2forward": "yolo2 向前运算 模型 [MODEL] 图像 [IMAGE]",
    "gui.blocklyText.AI.yolo2Parsing": "yolo2 模型解析 对象 [OBJ] 获取 [TYPE]",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.A": "X坐标",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.B": "Y坐标",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.C": "标识号",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.D": "置信度",
    "gui.extension.machineHearing.name": "机器听觉",
    "gui.blocklyText.machineHearing.newModel": "ASR 新建空白模型",
    "gui.blocklyText.machineHearing.training": "ASR 开始训练 语料 [CORPUS] 添加到模型 [MODEL]",
    "gui.blocklyText.machineHearing.run": "ASR 运行模型 [MODEL]",
    "gui.blocklyText.machineHearing.recognize": "ASR 语音识别结果",
    "gui.blocklyText.machineHearing.save": "ASR 保存模型 [MODEL] 到 [PATH]",
    "gui.blocklyText.machineHearing.load": "ASR 加载模型 [PATH]",
    "gui.extension.machineVision.imageBasis.name": "机器视觉 · 图像基础",
    "gui.blocklyText.machineVision.openEmptyImage": "打开空图",
    "gui.blocklyText.machineVision.openPathImage": "打开路径 [PATH]",
    "gui.blocklyText.machineVision.clearImage": "清除图像 [IMAGE]",
    "gui.blocklyText.machineVision.saveImage": "保存图像 [IMAGE] 路径 [PATH]",
    "gui.blocklyText.machineVision.modifyPixelColor": "修改图像 [IMAGE] 坐标xy [COORDINATE] 颜色 [COLOR]",
    "gui.blocklyText.machineVision.getPixelColor": "获取图像 [IMAGE] 坐标-xy [COORDINATE] 颜色",
    "gui.blocklyText.machineVision.captureImage": "截取图像 [IMAGE] 区域-xywh [AREA]",
    "gui.blocklyText.machineVision.compressedImage": "压缩图像 [IMAGE] 压缩率 [RATIO]",
    "gui.blocklyText.machineVision.getImageInfo": "获取图像 [IMAGE] [INFO]",
    "gui.blocklyText.machineVision.conversion": "将图像 [IMAGE] 转换为 [TYPE]",
    "gui.extension.machineVision.imageDrawing.name": "机器视觉 · 图像绘制",
    "gui.blocklyText.machineVision.drawText": "绘制文本 图像 [IMAGE] 起点-xy [COORDINATE] 内容 [CONTENT] 颜色 [COLOR] 字号 [SIZE]",
    "gui.blocklyText.machineVision.drawImage": "绘制图像 图像 [IMAGE] 坐标-xy [COORDINATE] 缩放-xy [ZOOM]",
    "gui.blocklyText.machineVision.drawLine": "绘制直线 图像 [IMAGE] 坐标-xyxy [COORDINATE] 颜色 [COLOR] 粗细 [SIZE]",
    "gui.blocklyText.machineVision.drawArrows": "绘制箭头 图像 [IMAGE] 坐标-xyxy [COORDINATE] 颜色 [COLOR] 大小 [SIZE]",
    "gui.blocklyText.machineVision.drawCross": "绘制十字 图像 [IMAGE] 坐标-xy [COORDINATE] 颜色 [COLOR] 延伸 [EXTEND] 粗细 [SIZE]",
    "gui.blocklyText.machineVision.drawRound": "绘制圆形 图像 [IMAGE] 坐标-xyr [COORDINATE] 颜色 [COLOR] 粗细 [SIZE] 填充 [FILLING]",
    "gui.blocklyText.machineVision.drawRectangle": "绘制矩形 图像 [IMAGE] 坐标-xywh [COORDINATE] 颜色 [COLOR] 粗细 [SIZE] 填充 [FILLING]",
    "gui.blocklyText.machineVision.drawFeaturePoints": "绘制特征点 图像 [IMAGE] 对象 [OBJ] 颜色 [COLOR] 大小 [SIZE] 粗细 [THICKNESS] 填充 [FILLING]",
    "gui.extension.machineVision.imageFiltering.name": "机器视觉 · 图像滤波",
    "gui.blocklyText.machineVision.correctionZoom": "校正缩放 图像 [IMAGE] 校正值 [CORRECTION] 缩放值 [ZOOM]",
    "gui.blocklyText.machineVision.histogramEqualization": "直方图均衡 图像 [IMAGE] 自适应 [ADAPTIVE] 对比度 [CONTRAST]",
    "gui.blocklyText.machineVision.fuzzyFilter": "模糊滤波 图像 [IMAGE] 卷积核 [CONVOLUTIONKERNEL] 自适应 [ADAPTIVE] 补偿 [MAKEUP]",
    "gui.blocklyText.machineVision.cartoonFilter": "卡通滤波 图像 [IMAGE] 原始差异 [ORIGINALDIFFERENCE] 相邻差异 [ADJACENTDIFFERENCE]",
    "gui.blocklyText.machineVision.imageCorrosion": "图像腐蚀 图像 [IMAGE] 卷积核 [CONVOLUTIONKERNEL] 阈值 [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageExpansion": "图像膨胀 图像 [IMAGE] 卷积核 [CONVOLUTIONKERNEL] 阈值 [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageFill": "图像填充 图像 [IMAGE] 坐标-xy [COORDINATE] 颜色 [COLOR] 原始差异 [ORIGINALDIFFERENCE] 相邻差异 [ADJACENTDIFFERENCE] 反转 [REVERSE] 清除 [CLEAR]",
    "gui.blocklyText.machineVision.ImageProjection": "图像投影 图像 [IMAGE] 垂直投影 [VERTICALPROJECTION]",
    "gui.blocklyText.machineVision.ImageInversion": "图像反转 图像 [IMAGE]",
    "gui.extension.machineVision.shapeRecognition.name": "机器视觉 · 形状识别",
    "gui.blocklyText.machineVision.straightLineAnalysis": "直线解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.circularAnalysis": "圆形解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.rectangleAnalysis": "矩形解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.lineRecognition": "直线识别 图像 [IMAGE] 区域-xywh [AREA] 阈值 [THRESHOLD] 合并角度 [MERGEANGLE] 合并斜率 [MERGESLOPE]",
    "gui.blocklyText.machineVision.lineSegmentRecognition": "线段识别 图像 [IMAGE] 区域-xywh [AREA] 间距 [SPACING] 合并角度 [MERGEANGLE]",
    "gui.blocklyText.machineVision.circleRecognition": "圆形识别 图像 [IMAGE] 区域-xywh [AREA] 阈值 [THRESHOLD] 最小r [MINIMUM] 最大r [MAXIMUM] 步长r [STRIDE] 合并x值 [COMBINEDX] 合并y值 [COMBINEDY] 合并r值 [COMBINEDR]",
    "gui.blocklyText.machineVision.rectangleRecognition": "矩形识别 图像 [IMAGE] 区域-xywh [AREA] 阈值 [THRESHOLD]",
    "gui.extension.machineVision.colorRecognition.name": "机器视觉 · 颜色识别",
    "gui.blocklyText.machineVision.LABFromRGB888": "LAB值 由RGB888 [RGB888]",
    "gui.blocklyText.machineVision.RGB888FromLAB": "RGB888值 由LAB [LAB]",
    "gui.blocklyText.machineVision.grayscaleFromRGB888": "灰度值 由RGB888 [RGB888]",
    "gui.blocklyText.machineVision.GRB888FromGrayscale": "RGB888 由灰度值 [GRAYSCALE]",
    "gui.blocklyText.machineVision.trackingAnalysis": "追踪解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.colorAnalysis": "颜色解析 对象 [OBJ] CDF [CDF] 获取 [INFO]",
    "gui.blocklyText.machineVision.colorPatchTracking": "色块追踪 图像 [IMAGE] 阈值LAB [THRESHOLDLAB] 区域-xywh [AREA] 滤掉区域 [FILTEROUTAREA] 滤掉素数 [FILTEROUTPRIMENUMBERS] 合并 [MERGE] 重叠 [OVERLAPPING]",
    "gui.blocklyText.machineVision.colorRecognition": "颜色识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.extension.machineVision.dimensionCodeRecognition.name": "机器视觉 · 维码识别",
    "gui.blocklyText.machineVision.barcodeAnalysis": "条形码解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.QRCodeAnalysis": "二维码解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.aprilTagAnalysis": "AprilTag解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.BarCodeRecognition": "条形码识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.blocklyText.machineVision.QRCodeRecognition": "二维码识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.blocklyText.machineVision.AprilTagRecognition": "AprilTag识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.extension.machineVision.featureRecognition.name": "机器视觉 · 特征识别",
    "gui.blocklyText.machineVision.featureInformation": "特征信息 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.directionalGradienMap": "定向梯度图 图像 [IMAGE] 区域-xywh [AREA] 线长 [LINELENGTH]",
    "gui.blocklyText.machineVision.featureRecognition": "特征识别 图像 [IMAGE] 区域-xywh [AREA] 关键点数 [KEYPOINTS] 角点数 [CORNERPOINTS] 比例因子 [SCALEFACTOR] 多比例 [MULTIRATIO]",
    "gui.blocklyText.machineVision.featureComparison": "特征比较 对象1 [OBJ1] 对象2 [OBJ2] 相似度 [SIMILARITY]",
    "gui.blocklyText.machineVision.FeatureInformationMenu.A": "坐标-xywh",
    "gui.blocklyText.machineVision.FeatureInformationMenu.B": "x分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.C": "y分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.D": "w分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.E": "h分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.F": "中心-x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.G": "中心-y",
    "gui.blocklyText.machineVision.FeatureInformationMenu.H": "匹配量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.I": "弧度",
    "gui.blocklyText.machineVision.FeatureInformationMenu.J": "匹配点-xy",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.H": "号码",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.I": "家族",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.J": "弧度",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.K": "x向距离",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.L": "y向距离",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.M": "z向距离",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.N": "x向弧度",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.O": "y向弧度",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.P": "z向弧度",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.F": "内容",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.G": "版本",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.H": "掩码",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.I": "类型",
    "gui.blocklyText.machineVision.BarcodeAnalysisMenu.H": "角度",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.A": "灰度图值",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.B": "LAB的L值",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.C": "LAB的A值",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.D": "LAB的B值",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.H": "像素量",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.J": "号码数",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.K": "合并量",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.L": "面积",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.M": "密度比",
    "gui.blocklyText.machineVision.CircularInformationMenu.A": "坐标-xyr",
    "gui.blocklyText.machineVision.CircularInformationMenu.D": "r分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.B": "x1分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.C": "y1分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.D": "x2分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.E": "y2分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.F": "长度",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.H": "斜率",
    "gui.blocklyText.machineVision.ImageInfoMenu.A": "宽度",
    "gui.blocklyText.machineVision.ImageInfoMenu.B": "高度",
    "gui.blocklyText.machineVision.ImageInfoMenu.C": "格式",
    "gui.blocklyText.machineVision.ImageInfoMenu.D": "大小",
    "gui.blocklyText.machineVision.ConversionTypeMenu.A": "灰度图",
    "gui.blocklyText.machineVision.ConversionTypeMenu.B": "彩色图",
    "gui.blocklyText.machineVision.ConversionTypeMenu.C": "彩虹图",
    "gui.blocklyText.machineVision.ConversionTypeMenu.D": "AI格式",
    "gui.extension.mpyirq.name": "引脚中断",
    "gui.blocklyText.mpyirq.event": "中断引脚 [PIN] 模式 [MODE] 执行",
    "gui.blocklyText.mpyirq.clean": "取消引脚中断 [PIN]",
    "gui.blocklyText.pyTurtle.init": "创建海龟绘图器[NAME]",
    "gui.blocklyText.pyTurtle.turtleSetShape": "[NAME] 形状设置为 [SHAPE]",
    "gui.blocklyText.pyTurtle.turtleDone": "绘制结束，继续保持窗口",
    "gui.blocklyText.pyTurtle.turtleExitClick": "点击退出绘制",
    "gui.blocklyText.pyTurtle.turtleMove": "[NAME]移动[DIRECTION][DISTANCE]",
    "gui.blocklyText.pyTurtle.turtleRotate": "[NAME]旋转[DIRECTION][ANGLE]",
    "gui.blocklyText.pyTurtle.turtleFaceTo": "[NAME]面向[ANGLE]度",
    "gui.blocklyText.pyTurtle.turtleMoveToPos": "[NAME]移动到坐标X[X] Y[Y]位置处",
    "gui.blocklyText.pyTurtle.turtleSetPos": "[NAME] [XY]坐标设置为[POS]",
    "gui.blocklyText.pyTurtle.turtleGetStatus": "[NAME] 获取当前[STATUS]",
    "gui.blocklyText.pyTurtle.turtleClearAll": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleClone": "[NAME] 复制海龟",
    "gui.blocklyText.pyTurtle.turtleVisible": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleSetThickness": "[NAME] 设置画笔粗细 [THICK]",
    "gui.blocklyText.pyTurtle.turtleSetScale": "[NAME] 设置海龟图标大小 垂直拉伸倍数[VERTICAL] 水平拉伸倍数[HOR] 轮廓宽度[OUTLINE]",
    "gui.blocklyText.pyTurtle.turtleSetSpeed": "[NAME] 设置画笔速度为[SPEED]",
    "gui.blocklyText.pyTurtle.turtleSetBackground": "[NAME] 设置背景颜色[COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetPenColor": "[NAME] 设置画笔颜色 [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetFillColor": "[NAME]设置画笔填充颜色[COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetColor": "[NAME] 设置画笔颜色 [COLOR1] 设置画笔填充颜色[COLOR2]",
    "gui.blocklyText.pyTurtle.turtleDrawAction": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleDrawCircle": "[NAME]绘制图形[CIRCLE] 半径为[RADIUS]",
    "gui.blocklyText.pyTurtle.turtleDrawCircleWithAngle": "[NAME]绘制圆形 半径为[RADIUS] 角度为[DEGREE]",
    "gui.blocklyText.pyTurtle.turtleInitScreen": "[NAME] 初始化海龟屏幕[SCREEN]",
    "gui.blocklyText.pyTurtle.turtleSaveDraw": "[NAME] 将绘制的图像保存为图片 [PICTURES]",
    "gui.blocklyText.pyTurtle.turtleSetAnimationInterval": "[NAME]设置动画播放时间间隔为[TIME]毫秒",
    "gui.blocklyText.pyTurtle.turtleDrawText": "[NAME] 书写 [TEXT]",
    "gui.blocklyText.pyTurtle.turtleDrawTextWithAttr": "[NAME] 书写 [TEXT] 画笔随文字移动[JUDGE] 文字对齐方向[ALIGN] 字体[FONT] 字号[FONTSIZE] 字形[TYPE]",
    "gui.blocklyText.pyTurtle.turtleGetFloat": "海龟获取输入的浮点数 标题为[TITLE] 提示语为[TIP] 默认值[VALUE] 最小值[MIN] 最大值[MAX]",
    "gui.blocklyText.pyTurtle.turtleGetString": "海龟获取输入的字符串 标题为[TITLE] 提示语为[TIP]",
    "gui.blocklyText.pyTurtle.roll_forward": "向前",
    "gui.blocklyText.pyTurtle.roll_backward": "向后",
    "gui.blocklyText.pyTurtle.roll_l_left": "向左",
    "gui.blocklyText.pyTurtle.roll_l_right": "向右",
    "gui.blocklyText.pyTurtle.xy_x": "X",
    "gui.blocklyText.pyTurtle.xy_y": "Y",
    "gui.blocklyText.pyTurtle.status_pos": "位置",
    "gui.blocklyText.pyTurtle.status_shape": "形状",
    "gui.blocklyText.pyTurtle.status_heading": "朝向",
    "gui.blocklyText.pyTurtle.status_width": "画笔宽度",
    "gui.blocklyText.pyTurtle.status_shapesize": "海龟图标大小",
    "gui.blocklyText.pyTurtle.status_speed": "速度",
    "gui.blocklyText.pyTurtle.action_clear": "清屏",
    "gui.blocklyText.pyTurtle.action_reset": "重置",
    "gui.blocklyText.pyTurtle.action_home": "返回原点",
    "gui.blocklyText.pyTurtle.pen_action_penup": "抬笔",
    "gui.blocklyText.pyTurtle.pen_action_pendown": "落笔",
    "gui.blocklyText.pyTurtle.visible_hideturtle": "隐藏海龟",
    "gui.blocklyText.pyTurtle.visible_showturtle": "显示海龟",
    "gui.blocklyText.pyTurtle.visible_turtle": "海龟",
    "gui.blocklyText.pyTurtle.visible_arrow": "箭头",
    "gui.blocklyText.pyTurtle.visible_circle": "圆形",
    "gui.blocklyText.pyTurtle.visible_square": "方块",
    "gui.blocklyText.pyTurtle.visible_triangle": "三角形",
    "gui.blocklyText.pyTurtle.visible_classic": "默认",
    "gui.blocklyText.pyTurtle.drawaction_begin": "开始填充",
    "gui.blocklyText.pyTurtle.drawaction_end": "结束填充",
    "gui.blocklyText.pyTurtle.circle_circle": "圆",
    "gui.blocklyText.pyTurtle.circle_dot": "点",
    "gui.blocklyText.pyTurtle.flag_False": "否",
    "gui.blocklyText.pyTurtle.flag_True": "是",
    "gui.blocklyText.pyTurtle.align_left": "居左",
    "gui.blocklyText.pyTurtle.align_center": "居中",
    "gui.blocklyText.pyTurtle.align_right": "居右",
    "gui.blocklyText.pyTurtle.align_normal": "标准",
    "gui.blocklyText.pyTurtle.align_bold": "加粗",
    "gui.blocklyText.pyTurtle.align_italic": "倾斜",
    "gui.blocklyText.pyTurtle.align_bi": "倾斜加粗",
    "gui.blocklyText.pyTurtle.turtleScreenSetup": "初始化海龟窗口大小 宽度[WIDTH] 高度[HEIGHT] 偏移 X [LEFT] Y [TOP]",
    "gui.blocklyText.pyTurtle.turtleScreenSize": "初始化海龟画布 宽度[WIDTH] 高度[HEIGHT] 背景颜色[COLOR]",
    "gui.blocklyText.pyGenerator.importModule": "import [NAME]",
    "gui.blocklyText.pyGenerator.importAs": "import [MOUDLE] as [NAME]",
    "gui.blocklyText.pyGenerator.fromImport": "from [FROM] import [MOUDLE]",
    "gui.blocklyText.pyGenerator.annotation": "# [TEXT]",
    "gui.blocklyText.pyGenerator.defineFunction": "def [FUNCTION]([ARGUMENT]):",
    "gui.blocklyText.pyGenerator.callFunction": "调用函数[FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.callFunctionWithValue": "调用函数获取返回值[FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.defineClass": "class [CLASS]:",
    "gui.blocklyText.pyGenerator.instanceClass": "[CLASS] [INSTANCE]",
    "gui.blocklyText.pyGenerator.reporterType": "Code [CODE]",
    "gui.blocklyText.pyGenerator.typeBoolean": "Code [CODE]",
    "gui.blocklyText.pyGenerator.annotationBlock": "块注释",
    "gui.blocklyText.pyGenerator.originCode": "Code [CODE]",
    "gui.extension.xiamiboard.title": "虾米扩展板",
    "gui.extension.xiamiboard.setMotor": "设置电机[Select]方向[Direction]速度[Speed]",
    "gui.extension.xiamiboard.stopMotor": "停止电机[Select]",
    "gui.extension.xiamiboard.setTrafficLights": "设置交通灯 红[Red] 黄[Yellow] 绿[Green]",
    "gui.extension.xiamiboard.readAngle": "读取角度传感器数据",
    "gui.extension.xiamiboard.readFlame": "读取火焰传感器数据",
    "gui.extension.xiamiboard.readMotorState": "读取电机[Select]状态[State]",
    "gui.extension.xiamiboard.readTrafficLights": "读取交通灯状态[State]",
    "gui.extension.xiamiboard.readRelay": "设置继电器[State]",
    "gui.extension.xiamiboard.readUltrasonicDistance": "读取超声波距离(cm)",
    "gui.extension.xiamiboard.initializationScreen": "OLED 初始化",
    "gui.extension.xiamiboard.screenDisplay": "OLED 显示文字[Text]在坐标x[Row]y[Column]",
    "gui.extension.xiamiboard.clearScreenAll": "OLED 清屏",
    "gui.extension.xiamiboard.clearScreen": "OLED 清除第[Row]行 从列[ColumnFrom]到列[ColumnTo]",
    "gui.extension.xiamiboard.readInfrared": "读取红外键值",
    "gui.extension.xiamiboard.receiveData": "当接收到[Data]",
    "gui.extension.xiamiboard.wirelessData": "红外数据",
    "gui.extension.xiamiboard.xiamiRGB": "红[Red]绿[Green]蓝[Blue]",
    "gui.extension.xiamiboard.setRGBLightsRange": "设置RGB灯点亮[Range] 显示颜色[Color]",
    "gui.extension.xiamiboard.setLightsColor": "设置RGB灯[Num]显示颜色[Color]",
    "gui.extension.xiamiboard.setLightsBrightness": "设置RGB灯亮度[Brightness]",
    "gui.extension.xiamiboard.coloseLightsAll": "关闭全部RGB灯",
    "gui.extension.xiamiboard.initializeST": "初始化 [TYPE] 温湿度传感器",
    "gui.extension.xiamiboard.readSTState": "读取 [TYPE] [State]",
    "gui.blocklyText.xiamiboard.all": "全部",
    "gui.blocklyText.xiamiboard.cw": "正转",
    "gui.blocklyText.xiamiboard.ccw": "反转",
    "gui.blocklyText.xiamiboard.speed": "速度",
    "gui.blocklyText.xiamiboard.dir": "方向",
    "gui.blocklyText.xiamiboard.temp": "温度",
    "gui.blocklyText.xiamiboard.hum": "湿度",
    "gui.blocklyText.xiamiboard.colorRed": "红色",
    "gui.blocklyText.xiamiboard.colorYellow": "黄色",
    "gui.blocklyText.xiamiboard.colorGreen": "绿色",
    "gui.blocklyText.xiamiboard.close": "闭合",
    "gui.blocklyText.xiamiboard.disconnect": "断开",
    "gui.blocklyText.xiamiboard.lightAll": "全部",
    "gui.extension.pyMqtt.initMqtt": "初始化MQTT[SETTING]",
    "gui.extension.pyMqtt.connect": "MQTT发起连接",
    "gui.extension.pyMqtt.loop_forever": "MQTT保持连接,永久",
    "gui.extension.pyMqtt.loopNeverForever": "MQTT保持连接,超时时间[Time]秒",
    "gui.extension.pyMqtt.disconnect": "MQTT断开连接",
    "gui.extension.pyMqtt.pushlish": "MQTT发布[INFO]到[Topic]",
    "gui.extension.pyMqtt.pushlish_save": "MQTT发布[INFO]到[Topic]并存数据库 (SIoT V2)",
    "gui.extension.pyMqtt.subscribe": "MQTT订阅[Topic]",
    "gui.extension.pyMqtt.received": "当MQTT从[Topic]接收到[MqttInfo]",
    "gui.extension.pyMqtt.topic": "MQTT主题",
    "gui.extension.pyMqtt.mqttInfo": "MQTT信息",
    "gui.blocklyText.pyMqtt.pyMqttInitPlatform": "MQTT平台",
    "gui.blocklyText.pyMqtt.EasyIOT_Server": "EasyIoT服务器",
    "gui.blocklyText.pyMqtt.EasyIOTServerChina": "EasyIoT 中国",
    "gui.blocklyText.pyMqtt.EasyIOTServerGlobal": "EasyIoT 全球",
    "gui.blocklyText.pyMqtt.EasyIOT_Port": "EasyIoT Port",
    "gui.blocklyText.pyMqtt.EasyIOT_id": "EasyIoT ID",
    "gui.blocklyText.pyMqtt.siotServer": "SIoT服务器",
    "gui.blocklyText.pyMqtt.siotServerPort": "SIoT Port",
    "gui.blocklyText.pyMqtt.siotServerUser": "SIoT 用户名",
    "gui.blocklyText.pyMqtt.siotServerPass": "SIoT 密码",
    "gui.blocklyText.microNaturalScienceV2.OLED": "OLED",
    "gui.blocklyText.microNaturalScienceV2.Motor": "Motor",
    "gui.blocklyText.microNaturalScienceV2.RGB": "RGB",
    "gui.blocklyText.microNaturalScienceV2.IoT": "IoT",
    "gui.blocklyText.microNaturalScienceV2.IOT_Username": "IOT_ID(用户)",
    "gui.blocklyText.microNaturalScienceV2.IOT_Password": "IOT_PWD(密码)",
    "gui.blocklyText.microNaturalScienceV2.IOT_topic": "Topic(默认topic_0)",
    "gui.extension.pyGame.pygameModule": "Pygame 模块",
    "gui.extension.pyGame.pygameInit": "初始化所有导入的 pygame 模块",
    "gui.extension.pyGame.pygameQuit": "卸载所有已初始化的 pygame 模块",
    "gui.extension.pyGame.displayModule": "Display 模块",
    "gui.extension.pyGame.displayInit": "初始化display模块",
    "gui.extension.pyGame.displayQuit": "卸载已初始化的display模块",
    "gui.extension.pyGame.windowInit": "初始化[Width]x[Height] [Flags]的窗口[Win]",
    "gui.extension.pyGame.windowUpdate": "更新窗口(部分软件)界面显示",
    "gui.extension.pyGame.windowSetIcon": "设置窗口图标[Value]",
    "gui.extension.pyGame.windowSetTitle": "设置窗口标题[Value]",
    "gui.extension.pyGame.windowFlip": "更新Surface对象至屏幕",
    "gui.extension.pyGame.windowDraw": "将[Suf]绘制到[Win]窗口的[Postion]",
    "gui.extension.pyGame.surfaceObject": "Surface对象",
    "gui.extension.pyGame.createSurface": "创建[Width]x[Height]的Surface对象[Surface]",
    "gui.extension.pyGame.blitSurface": "将[Obj]绘制到Surface对象[Surface]的[Pos]",
    "gui.extension.pyGame.fillSurface": "用[Color]填充Surface对象[Surface]",
    "gui.extension.pyGame.surfaceSetAt": "将Surface对象[Surface]的[Pos]像素点设置成[Color]",
    "gui.extension.pyGame.surfaceGetAt": "获取Surface对象[Surface]的[Pos]像素点的颜色",
    "gui.extension.pyGame.surfaceGetWidth": "获取Surface对象[Surface]的宽度",
    "gui.extension.pyGame.surfaceGetHeight": "获取Surface对象[Surface]的高度",
    "gui.extension.pyGame.surfaceGetRect": "获取Surface对象[Surface]的矩形区域",
    "gui.extension.pyGame.surfaceMove": "Surface对象[Surface]的偏移速度为[CONTENT]",
    "gui.extension.pyGame.eventModule": "Event 模块",
    "gui.extension.pyGame.windowEvent": "获取窗口事件",
    "gui.extension.pyGame.eventType": "[Event]事件类型",
    "gui.extension.pyGame.eventKey": "[Event][Mod]",
    "gui.extension.pyGame.event": "[Event]事件",
    "gui.extension.pyGame.eventConstant": "[Constant]键",
    "gui.extension.pyGame.eventModKey": "[Constant]组合键",
    "gui.extension.pyGame.eventMod": "检测[Keys]组合键是否被按下",
    "gui.extension.pyGame.eventOr": "[Key1]|[Key2]",
    "gui.extension.pyGame.fontModule": "Font 模块",
    "gui.extension.pyGame.fontInit": "初始化font模块",
    "gui.extension.pyGame.fontQuit": "卸载已初始化的font模块",
    "gui.extension.pyGame.createFont": "从[Font]创建[Size][Source]字体对象",
    "gui.extension.pyGame.getDefaultFont": "获取系统字体的文件名",
    "gui.extension.pyGame.getCanUsedFont": "获取系统可使用的字体列表",
    "gui.extension.pyGame.generationFont": "通过字体对象[Font]创建抗锯齿[Antialias]颜色[Color]透明度[Opacity]的文本[Text]",
    "gui.extension.pyGame.renderStyle": "设置字体[Font]样式 [Bold] [Italics] [Underline]",
    "gui.extension.pyGame.imageModule": "Image 模块",
    "gui.extension.pyGame.imageLoad": "从[FileName]加载图片",
    "gui.extension.pyGame.supportExtension": "判断加载的图片是不是支持的扩展?",
    "gui.extension.pyGame.imageSave": "保存[Img]为[FileName]图片",
    "gui.extension.pyGame.drawModule": "Draw 模块",
    "gui.extension.pyGame.drawRect": "绘制颜色为[Color]配置[Rect]边框粗细[Width]的矩形到[Win]",
    "gui.extension.pyGame.drawCircle": "绘制颜色为[Color]位置[Rect]半径[Size]边框粗细[Width]的圆形到[Win]",
    "gui.extension.pyGame.drawLine": "绘制颜色为[Color]起始位置[start_pos]结束位置[end_pos]粗细[Width]的线到[Win]",
    "gui.extension.pyGame.drawLines": "绘制颜色为[Color]闭合[Closed]位置[Pointlist]粗细[Width]的多条线到[Win]",
    "gui.extension.pyGame.mixerModule": "Mixer 模块",
    "gui.extension.pyGame.mixerInit": "初始化mixer模块",
    "gui.extension.pyGame.mixerQuit": "卸载已初始化的mixer模块",
    "gui.extension.pyGame.createSound": "从[FileName]加载声音对象",
    "gui.extension.pyGame.soundPlay": "声音[Sound]播放",
    "gui.extension.pyGame.soundStop": "声音[Sound]停止",
    "gui.extension.pyGame.soundFadeout": "设置声音[Sound]淡出(逐渐减弱直到消失)的时间[Time](ms)",
    "gui.extension.pyGame.soundSetVolume": "设置声音[Sound]音量[Value]%",
    "gui.extension.pyGame.soundGetbusy": "声音是否还在播放?",
    "gui.extension.pyGame.timeModule": "Time 模块",
    "gui.extension.pyGame.getRuntime": "获取运行时间(毫秒)",
    "gui.extension.pyGame.pauseWay": "用[Way]暂停[Time](毫秒)",
    "gui.extension.pyGame.setTimer": "创建[Time](毫秒)后[Once][Event]事件的定时器",
    "gui.extension.pyGame.clockInit": "初始化时钟[Clock]",
    "gui.extension.pyGame.updateClock": "更新时钟[Clock]",
    "gui.extension.pyGame.getClockUp": "获取[Clock]上一个刻度中使用的时间",
    "gui.extension.pyGame.getClockUpRaw": "获取[Clock]上一个刻度中使用的实际时间",
    "gui.extension.pyGame.getClockFps": "获取[Clock]计算时钟帧率",
    "gui.extension.pyGame.musicModule": "Music 模块",
    "gui.extension.pyGame.musicLoad": "从[FileName]加载音乐对象",
    "gui.extension.pyGame.setVolume": "设置音乐音量[Value]%",
    "gui.extension.pyGame.musicPlay": "音乐播放",
    "gui.extension.pyGame.musicRewind": "音乐重新播放",
    "gui.extension.pyGame.musicPause": "音乐暂停",
    "gui.extension.pyGame.musicUnpause": "音乐恢复播放",
    "gui.extension.pyGame.musicStop": "音乐停止",
    "gui.extension.pyGame.musicFadeout": "设置音乐淡出(逐渐减弱直到消失)的时间[Time](ms)",
    "gui.extension.pyGame.musicGetbusy": "音乐是否还在播放?",
    "gui.extension.pyGame.colorModule": "Color 模块",
    "gui.extension.pyGame.color": "[Color]转RGB",
    "gui.blocklyText.pyGame.gain": "焦点状态",
    "gui.blocklyText.pyGame.state": "程序状态",
    "gui.blocklyText.pyGame.unicode": "按键名称",
    "gui.blocklyText.pyGame.key": "按键值",
    "gui.blocklyText.pyGame.mod": "按键模式",
    "gui.blocklyText.pyGame.pos": "鼠标定位",
    "gui.blocklyText.pyGame.rel": "鼠标移动的相对位置",
    "gui.blocklyText.pyGame.buttons": "鼠标按键",
    "gui.blocklyText.pyGame.size": "窗口大小",
    "gui.blocklyText.pyGame.w": "窗口宽度",
    "gui.blocklyText.pyGame.h": "窗口高度",
    "gui.blocklyText.pyGame.once": "运行一次",
    "gui.blocklyText.pyGame.repeat": "重复运行",
    "gui.blocklyText.pyGame.close": "闭合",
    "gui.blocklyText.pyGame.noclose": "不闭合",
    "gui.blocklyText.pyGame.fullscreen": "全屏显示",
    "gui.blocklyText.pyGame.doublebuf": "双缓冲",
    "gui.blocklyText.pyGame.hwsurface": "硬件加速",
    "gui.blocklyText.pyGame.opengl": "渲染显示",
    "gui.blocklyText.pyGame.resizable": "窗口适应",
    "gui.blocklyText.pyGame.noframe": "无框架",
    "gui.blocklyText.pyGame.none": "无",
    "gui.blocklyText.pyGame.external": "自定义",
    "gui.blocklyText.pyGame.system": "系统",
    "gui.blocklyText.pyGame.normal": "正常",
    "gui.blocklyText.pyGame.italics": "斜体",
    "gui.blocklyText.pyGame.bold": "加粗",
    "gui.blocklyText.pyGame.underline": "下划线",
    "gui.blocklyText.pyGame.wait": "等待",
    "gui.blocklyText.pyGame.delay": "延迟",
    "gui.blocklyText.pyGame.false": "False",
    "gui.blocklyText.pyGame.true": "True",
    "gui.blocklyText.pyGame.quit": "窗口关闭",
    "gui.blocklyText.pyGame.activeevent": "窗口被激活或者隐藏",
    "gui.blocklyText.pyGame.keydown": "键盘按下",
    "gui.blocklyText.pyGame.keyup": "键盘松开",
    "gui.blocklyText.pyGame.mousemotion": "鼠标移动",
    "gui.blocklyText.pyGame.mousebuttonup": "鼠标松开",
    "gui.blocklyText.pyGame.mousebuttondown": "鼠标按下",
    "gui.blocklyText.pyGame.videoresize": "窗口缩放",
    "gui.blocklyText.pyGame.videoexpose": "窗口部分公开(expose)",
    "gui.blocklyText.pyGame.insert": "插入",
    "gui.blocklyText.pyGame.home": "起始",
    "gui.blocklyText.pyGame.end": "结束",
    "gui.blocklyText.pyGame.pageup": "上一页",
    "gui.blocklyText.pyGame.pagedown": "下一页",
    "gui.blocklyText.pyGame.numlock": "数字键盘锁定",
    "gui.blocklyText.pyGame.capslock": "大写锁定",
    "gui.blocklyText.pyGame.scrollock": "滚动锁",
    "gui.blocklyText.pyGame.rightshift": "右Shift",
    "gui.blocklyText.pyGame.leftshift": "左Shift",
    "gui.blocklyText.pyGame.rightcontrol": "右Control",
    "gui.blocklyText.pyGame.leftcontrol": "左Control",
    "gui.blocklyText.pyGame.rightalt": "右Alt",
    "gui.blocklyText.pyGame.leftalt": "左Alt",
    "gui.blocklyText.pyGame.rightmeta": "Right Meta",
    "gui.blocklyText.pyGame.leftmeta": "Left Meta",
    "gui.blocklyText.pyGame.leftWindowskey": "左Windows",
    "gui.blocklyText.pyGame.rightWindowskey": "右Windows",
    "gui.blocklyText.pyGame.modeshift": "mode Shift",
    "gui.blocklyText.pyGame.help": "帮助",
    "gui.blocklyText.pyGame.printscreen": "屏幕截图",
    "gui.blocklyText.pyGame.sysrq": "sysrq",
    "gui.blocklyText.pyGame.break": "中断暂停",
    "gui.blocklyText.pyGame.menu": "功能调节菜单",
    "gui.blocklyText.pyGame.power": "电源",
    "gui.blocklyText.pyGame.Euro": "Euro",
    "gui.blocklyText.pyGame.delete": "删除",
    "gui.blocklyText.pyGame.backspace": "回格",
    "gui.blocklyText.pyGame.tab": "制表",
    "gui.blocklyText.pyGame.clear": "清除",
    "gui.blocklyText.pyGame.return": "回车",
    "gui.blocklyText.pyGame.pause": "暂停",
    "gui.blocklyText.pyGame.escape": "ESC",
    "gui.blocklyText.pyGame.space": "空格",
    "gui.blocklyText.pyGame.quotedbl": "双引号",
    "gui.blocklyText.pyGame.quote": "单引号",
    "gui.blocklyText.pyGame.NONE": "无",
    "gui.blocklyText.pyGame.LSHIFT": "左Shift",
    "gui.blocklyText.pyGame.RSHIFT": "右Shift",
    "gui.blocklyText.pyGame.SHIFT": "Shift",
    "gui.blocklyText.pyGame.LCTRL": "左Ctrl",
    "gui.blocklyText.pyGame.RCTRL": "右Ctrl",
    "gui.blocklyText.pyGame.CTRL": "Ctrl",
    "gui.blocklyText.pyGame.LALT": "左Alt",
    "gui.blocklyText.pyGame.RALT": "右Alt",
    "gui.blocklyText.pyGame.ALT": "Alt",
    "gui.blocklyText.pyGame.LMETA": "左Meta",
    "gui.blocklyText.pyGame.RMETA": "右Meta",
    "gui.blocklyText.pyGame.META": "Meta",
    "gui.blocklyText.pyGame.CAPS": "大写锁定",
    "gui.blocklyText.pyGame.NUM": "数字键盘锁定",
    "gui.blocklyText.pyGame.MODE": "AltGr",
    "gui.extension.pinpongBase.pinpongInit": "pinpong初始化 板型[Board]端口[Port]",
    "gui.extension.pinpongBase.pinpongUpdateFirmware": "pinpong更新固件 板型[Board]端口[Port]",
    "gui.extension.pinpongBase.pinInit": "[Instance]pin初始化 引脚号[Pin] 模式[Mod]",
    "gui.extension.pinpongBase.readPinValue": "[Instance]读数字值",
    "gui.extension.pinpongBase.setPinValue": "[Instance]设置数字输出 值[Value]",
    "gui.extension.pinpongBase.readAnalogValue": "[Instance]读模拟值",
    "gui.extension.pinpongBase.setAnalogValue": "[Instance]设置模拟输出(PWM) 值[Value]",
    "gui.blocklyText.pinpongBase.uno": "Arduino UNO",
    "gui.blocklyText.pinpongBase.leonardo": "Arduino Leonardo",
    "gui.blocklyText.pinpongBase.nano": "Arduino Nano",
    "gui.blocklyText.pinpongBase.mega1280": "Arduino Mega1280",
    "gui.blocklyText.pinpongBase.mega2560": "Arduino Mega2560",
    "gui.blocklyText.pinpongBase.microbit": "micro:bit",
    "gui.blocklyText.pinpongBase.handpy": "掌控板",
    "gui.blocklyText.pinpongBase.xugu": "虚谷号",
    "gui.blocklyText.pinpongBase.auto": "自动识别",
    "gui.blocklyText.pinpongBase.unihiker": "行空板",
    "gui.blocklyText.pinpongBase.rpi": "树莓派",
    "gui.blocklyText.pinpongBase.firebeetle_esp32": "Firebeetle ESP32",
    "gui.blocklyText.pinpongBase.firebeetle_esp8266": "Firebeetle ESP8266",
    "gui.blocklyText.pinpongBase.lp": "LattePanda",
    "gui.blocklyText.pinpongBase.esp32": "ESP32",
    "gui.blocklyText.pinpongBase.esp8266": "ESP8266",
    "gui.blocklyText.pinpongBase.nezha": "哪吒板",
    "gui.blocklyText.pinpongBase.JH7100": "JH7100",
    "gui.blocklyText.pinpongBase.win": "win",
    "gui.blocklyText.pinpongBase.out": "数字输出",
    "gui.blocklyText.pinpongBase.in": "数字输入",
    "gui.blocklyText.pinpongBase.analog": "模拟输入",
    "gui.blocklyText.pinpongBase.pwm": "模拟输出",
    "gui.blocklyText.pinpongBase.null": "无",
    "gui.blocklyText.pinpongServo.name": "舵机",
    "gui.extension.pinpongServo.servoInit": "舵机[Servo] 初始化引脚[Pin]",
    "gui.extension.pinpongServo.setServo": "舵机[Servo]转动到[Deg]度位置",
    "gui.blocklyText.pinpongIrq.name": "中断",
    "gui.extension.pinpongIrq.setIrq": "设置引脚[Pin]中断[Trigger]回调函数[Handler]",
    "gui.extension.pinpongIrq.INTevent": "当回调函数[Event]触发 参数[Pin]",
    "gui.extension.pinpongIrq.pinInfo": "pin",
    "gui.blocklyText.pinpongIrq.rising": "上升沿",
    "gui.blocklyText.pinpongIrq.falling": "下降沿",
    "gui.blocklyText.pinpongIrq.change": "电平变化",
    "gui.blocklyText.pinpongTone.name": "蜂鸣器",
    "gui.extension.pinpongTone.toneInit": "蜂鸣器[Tone] 初始化引脚[Pin]",
    "gui.extension.pinpongTone.setToneFreq": "设置蜂鸣器[Tone]频率[Freq]",
    "gui.extension.pinpongTone.readToneFreq": "读取蜂鸣器[Tone]频率",
    "gui.extension.pinpongTone.onTone": "打开蜂鸣器[Tone]",
    "gui.extension.pinpongTone.offTone": "关闭蜂鸣器[Tone]",
    "gui.extension.pinpongTone.keep": "蜂鸣器[Tone]以[Freq]频率持续运行[Time]毫秒",
    "gui.blocklyText.pinpongSr04_urm10.name": "超声波",
    "gui.extension.pinpongSr04_urm10.sr04_urm10Init": "超声波传感器[Sr04_urm10]初始化 trig引脚[Triger] echo引脚[Echo]",
    "gui.extension.pinpongSr04_urm10.readSr04_urm10": "读取超声波传感器[Sr04_urm10]距离(cm)",
    "gui.extension.pinpongDht.dhtInit": "初始化温湿度传感器[Dht]型号[DhtMod] 引脚[Pin]",
    "gui.extension.pinpongDht.read": "[Dht]读取[Type]",
    "gui.extension.pinpongDht.Temperature": "温度(℃)",
    "gui.extension.pinpongDht.Humidity": "湿度(%rh)",
    "gui.blocklyText.pinpongNeopixel.name": "灯带",
    "gui.extension.pinpongNeopixel.neopixelInit": "初始化 RGB灯[Np] 引脚[Pin]灯数[Num]",
    "gui.extension.pinpongNeopixel.brightness": "RGB灯[Np] 设置亮度[BRIGHTNESS]",
    "gui.extension.pinpongNeopixel.neopixelClear": "RGB灯[Np] 全部熄灭",
    "gui.extension.pinpongNeopixel.setNeopixel": "[Np]设置第[No]个灯的颜色[Color]",
    "gui.extension.pinpongNeopixel.showColorFromWith": "RGB灯[Np] 灯号 [NUM1] 到 [NUM2] 显示颜色 [COLOR]",
    "gui.extension.pinpongNeopixel.color": "红[Red]绿[Green]蓝[Blue]",
    "gui.extension.pinpongNeopixel.showGradientColor": "RGB灯[Np] 灯号 [NUM1] 到 [NUM2] 显示渐变颜色 色调从 [COLOR1] 到 [COLOR2]",
    "gui.extension.pinpongNeopixel.shiftPixelsToShow": "RGB灯[Np] 移动 [NUM] 单位",
    "gui.extension.pinpongNeopixel.rotatePixelsToShow": "RGB灯[Np] 循环移动 [NUM] 单位",
    "gui.extension.pinpongNeopixel.showHistogram": "RGB灯[Np] 灯号 [NUM1] 到 [NUM2] 显示柱状图 当前值 [VALUE] 最大值 [MAX]",
    "gui.extension.pinpongOled12864.oled12864Init": "OLED显示屏[Oled]初始化 宽度[Width]高度：8×[Height]",
    "gui.extension.pinpongOled12864.oled12864Fill": "[Oled]全屏[Option]",
    "gui.extension.pinpongOled12864.oled12864ShowString": "[Oled]在坐标x[X]y[Y] 显示文本[String]",
    "gui.extension.pinpongOled12864.show": "[Oled]显示生效",
    "gui.blocklyText.pinpongOled12864.display": "填充",
    "gui.blocklyText.pinpongOled12864.hide": "熄灭",
    "gui.extension.pinpongDS18B20.ds18b20Init": "温度传感器[Ds18b20]初始化 引脚[Pin]",
    "gui.extension.pinpongDS18B20.readCelsius": "读取[Ds18b20]温度(℃)",
    "gui.extension.pinpongMlx90614.mlx90614Init": "红外测温传感器[IRT]初始化 地址[ADDR]",
    "gui.extension.pinpongMlx90614.read": "红外测温传感器[OBJ]读取[TYPE] [UINT]",
    "gui.extension.pinpongBmp388.BMP388Init": "BMP388[BMP]初始化 I2C地址0x77",
    "gui.extension.pinpongBmp388.read": "BMP388[BMP]读取[TYPE]",
    "gui.blocklyText.pinpongBmp388.altitude": "海拔高度(m)",
    "gui.blocklyText.pinpongBmp388.pressure": "大气压强(Pa)",
    "gui.blocklyText.pinpongBmp388.temperature": "温度(℃)",
    "gui.blocklyText.pinpongIRRecv.name": "红外接收",
    "gui.extension.pinpongIRRecv.iRRecvInit": "红外接收[IRRecv]初始化 引脚[Pin] 回调函数[Event]",
    "gui.extension.pinpongIRRecv.onReceive": "当回调函数[Event]接收到[Data]",
    "gui.extension.pinpongIRRecv.data": "红外信号",
    "gui.blocklyText.PinpongBoard.name": "PinPong 扩展板",
    "gui.extension.PinpongBoard.connectWifi": "PinPong扩展板连接热点[ssid]密码[password]直到成功",
    "gui.extension.PinpongBoard.getIp": "获取Wi-Fi配置IP地址",
    "gui.extension.PinpongBoard.TCPInit": "PinPong扩展板初始化WIFI[ip] 端口8081",
    "gui.extension.PinpongBoard.USBInit": "PinPong扩展板初始化USB COM口[port]",
    "gui.extension.PinpongBoard.LEDInit": "初始化板载LED灯(D2)",
    "gui.extension.PinpongBoard.LEDSetValue": "设置LED灯输出为[value]",
    "gui.blocklyText.PinpongBoard.high": "高电平",
    "gui.blocklyText.PinpongBoard.low": "低电平",
    "gui.blocklyText.PinpongBoard.temp": "温度(℃)",
    "gui.blocklyText.PinpongBoard.humi": "湿度(%RH)",
    "gui.blocklyText.PinpongBoard.ledControl": "LED灯控制",
    "gui.blocklyText.PinpongBoard.toneControl": "蜂鸣器控制",
    "gui.extension.PinpongBoard.toneInit": "初始化蜂鸣器(D4)",
    "gui.extension.PinpongBoard.toneSetFreq": "蜂鸣器设置频率[Freq]Hz",
    "gui.extension.PinpongBoard.toneSwitch": "蜂鸣器[switch]",
    "gui.blocklyText.PinpongBoard.on": "打开",
    "gui.blocklyText.PinpongBoard.off": "关闭",
    "gui.extension.PinpongBoard.toneGetFreq": "蜂鸣器获取频率",
    "gui.extension.PinpongBoard.keep": "蜂鸣器以[Freq]频率(Hz)持续运行[Time]毫秒",
    "gui.blocklyText.PinpongBoard.buttonControl": "按键控制",
    "gui.extension.PinpongBoard.buttonInit": "初始化引脚号[PIN]模式数字输入",
    "gui.extension.PinpongBoard.buttonGetVlue": "读取[PIN]引脚电平",
    "gui.blocklyText.PinpongBoard.RGBControl": "RGB灯控制",
    "gui.extension.PinpongBoard.RGBInit": "初始化RGB灯(D9)",
    "gui.extension.PinpongBoard.setRGB": "设置RGB灯颜色[Color]",
    "gui.extension.PinpongBoard.setRGBBrightness": "设置RGB灯亮度为 [BRIGHTNESS]",
    "gui.extension.PinpongBoard.closeRGB": "关闭RGB灯",
    "gui.blocklyText.PinpongBoard.irControl": "红外接收",
    "gui.extension.PinpongBoard.irInit": "初始化红外接收回调函数[Event]",
    "gui.blocklyText.PinpongBoard.angleSensor": "角度传感器",
    "gui.extension.PinpongBoard.angleInit": "初始化角度传感器(A3)",
    "gui.extension.PinpongBoard.getAngleValue": "读取角度传感器的值",
    "gui.blocklyText.PinpongBoard.lightSensor": "光线传感器",
    "gui.extension.PinpongBoard.lightInit": "初始化光线传感器(A2)",
    "gui.extension.PinpongBoard.getLightValue": "读取光线传感器的值",
    "gui.blocklyText.PinpongBoard.OLEDscreen": "OLED显示屏",
    "gui.extension.PinpongBoard.oled12864Init": "初始化OLED显示屏",
    "gui.extension.PinpongBoard.oled12864Fill": "OLED屏幕显示为[Option](清屏)",
    "gui.extension.PinpongBoard.oled12864ShowString": "OLED在第[X]行 显示[String]",
    "gui.extension.PinpongBoard.oled12864ShowStringXY": "OLED在坐标X:[X]Y:16*[Y] 显示[String]",
    "gui.extension.PinpongBoard.oledCal": "校准数据 [TEXT] [BIT]位",
    "gui.extension.PinpongBoard.show": "OLED显示生效",
    "gui.blocklyText.PinpongBoard.black": "黑色",
    "gui.blocklyText.PinpongBoard.white": "白色",
    "gui.blocklyText.PinpongBoard.aht20": "温湿度传感器",
    "gui.extension.PinpongBoard.aht20Init": "初始化温湿度传感器",
    "gui.extension.PinpongBoard.getTempValue": "读取温度值(°C)",
    "gui.extension.PinpongBoard.getHumiValue": "读取湿度值(%rh)",
    "gui.blocklyText.PinpongBoard.mpu6050": "MPU6050传感器",
    "gui.extension.PinpongBoard.mpu6050Init": "初始化MPU6050传感器",
    "gui.extension.PinpongBoard.getAccValue": "读取加速计[Option]的值(a/g)",
    "gui.extension.PinpongBoard.getGyroValue": "读取陀螺仪[Option]的值(a/g)",
    "gui.blocklyText.PinpongBoard.motor": "电机控制",
    "gui.extension.PinpongBoard.motorInit": "初始化电机",
    "gui.extension.PinpongBoard.getIrValue": "读取红外传感器的值",
    "gui.extension.PinpongBoard.LightsBright": "RGB灯设置亮度为[BRIGHTNESS]",
    "gui.extension.PinpongBoard.clear": "RGB灯熄灭",
    "gui.extension.PinpongBoard.RGBShowColorFromWith": "RGB灯显示颜色[COLOR]",
    "gui.extension.PinpongBoard.readSHTC3": "读取SHTC3[VALUE]值",
    "gui.extension.PinpongBoard.readPitch": "读取俯仰角的值",
    "gui.extension.PinpongBoard.readYaw": "读取偏航角的值",
    "gui.extension.PinpongBoard.readRoll": "读取翻滚角的值",
    "gui.blocklyText.obloqMp.WhenReceivedData": "当接收到 [EventType] 的 [MSG]",
    "gui.blocklyText.obloqMp.ObloqEventType": "Obloq事件类型",
    "gui.blocklyText.obloqMp.ObloqMsg": "Obloq信息",
    "gui.blocklyText.obloqMp.initialParameter": "Obloq mqtt初始化 [PARAMETER] Rx(绿) [PINR] Tx(蓝) [PINT]",
    "gui.blocklyText.obloqMp.initialI2CParameter": "Obloq mqtt 初始化 [PARAMETER] 接口I2C 回调函数[Event]",
    "gui.blocklyText.obloqMp.subscribe": "Obloq 订阅 [TOPIC]",
    "gui.blocklyText.obloqMp.sendmsgToCloudPlatform": "Obloq 发送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.obloqMp.whenDebugInfo": "Obloq 获取 [DEBUG] [ERROR]",
    "gui.blocklyText.obloqMp.DEBUG": "调试信息",
    "gui.blocklyText.obloqMp.ERROR": "错误码",
    "gui.blocklyText.obloqMp.httpInitialParameter": "Obloq http初始化 [PARAMETER] Rx(绿) [PINR] Tx(蓝) [PINT]",
    "gui.blocklyText.obloqMp.httpInitialI2CParameter": "Obloq http 初始化 [PARAMETER] 接口I2C",
    "gui.blocklyText.obloqMp.httpGet": "Obloq http(get) url: [URL] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.wifiGetLocalIP": "获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.obloqMp.getWiFiLocalIP": "IP地址",
    "gui.blocklyText.obloqMp.wifi": "Wi-Fi",
    "gui.blocklyText.obloqMp.SSID": "Wi-Fi名称",
    "gui.blocklyText.obloqMp.PWD": "Wi-Fi密码",
    "gui.blocklyText.obloqMp.iot": "平台",
    "gui.blocklyText.obloqMp.EasyIOT_Server": "Easy物联平台",
    "gui.blocklyText.obloqMp.EasyIOTServerChina": "中国",
    "gui.blocklyText.obloqMp.EasyIOTServerGlobal": "全球",
    "gui.blocklyText.obloqMp.Port": "端口",
    "gui.blocklyText.obloqMp.Username": "用户名",
    "gui.blocklyText.obloqMp.Password": "密码",
    "gui.blocklyText.obloqMp.siotServer": "Siot物联平台",
    "gui.blocklyText.serialPort.whenReceivedData": "当接收到串口消息 [MESSAGE] [VALUE]",
    "gui.blocklyText.serialPort.value": "值",
    "gui.blocklyText.serialPort.readSerialData": "读取串口 [MESSAGE] 的值",
    "gui.blocklyText.serialPort.serialPortSendMessage": "发送串口消息 [MESSAGE] 值 [VALUE]",
    "gui.extension.pyAutoGui.pyAutoGuiModule": "pyautogui 快捷功能",
    "gui.extension.pyAutoGui.init": "初始化pyautogui",
    "gui.extension.pyAutoGui.getPosition": "获取当前鼠标的坐标([X], [Y])",
    "gui.extension.pyAutoGui.getSize": "获取当前屏幕分辨率([Width], [Height])",
    "gui.extension.pyAutoGui.height": "高度",
    "gui.extension.pyAutoGui.width": "宽度",
    "gui.extension.pyAutoGui.onScreen": "判断坐标([X], [Y])是否在屏幕内",
    "gui.extension.pyAutoGui.setDelay": "设置PyAutoGUI调用延迟为[Delay]",
    "gui.extension.pyAutoGui.setFailSafe": "[Switch]故障安全模式",
    "gui.extension.pyAutoGui.mouse": "鼠标功能区",
    "gui.extension.pyAutoGui.mouseMoveToOrRel": "将鼠标移动到[Attribute]([X],[Y]) 延迟[Seconds]秒 设置样式[Style]",
    "gui.extension.pyAutoGui.mouseDragToOrRel": "将鼠标拖拽到[Attribute]([X],[Y]) 延迟[Seconds]秒 点击鼠标[Key]",
    "gui.extension.pyAutoGui.mouseClick": "将鼠标移动到([X], [Y]), 以间隔[Seconds]秒的速度鼠标[Key]点击[Frequency]次",
    "gui.extension.pyAutoGui.mouseDownOrUp": "将鼠标移动到([X], [Y]), 并[Action]鼠标[Key]",
    "gui.extension.pyAutoGui.mouseScroll": "鼠标滚动[NUM]个刻度",
    "gui.extension.pyAutoGui.keyboard": "键盘功能区",
    "gui.extension.pyAutoGui.keyboardWrite": "键盘以[NUM]秒的间隔输出[String]",
    "gui.extension.pyAutoGui.keyboardDownOrUp": "键盘点击[NUM]次[Key]键",
    "gui.extension.pyAutoGui.keyboardPress": "[Button][Key]",
    "gui.extension.pyAutoGui.keyboardHold": "保持按下[Key]",
    "gui.extension.pyAutoGui.keyboardHotkey": "按下快捷键(组合键)[Key]",
    "gui.extension.pyAutoGui.message": "信息框功能区",
    "gui.extension.pyAutoGui.messageSetting": "显示一个[SETTING]的消息框",
    "gui.extension.pyAutoGui.image": "图片功能区",
    "gui.extension.pyAutoGui.cbImg": "获取整个屏幕[Pos]的图像数据保存为图片[Screen]并返回对象",
    "gui.extension.pyAutoGui.getRgb": "获取[Obj]([X],[Y])的RGB颜色",
    "gui.extension.pyAutoGui.getPixel": "获取整个屏幕([X],[Y])的RGB颜色",
    "gui.extension.pyAutoGui.pixelMatchesColor": "屏幕上([X],[Y])的RGB颜色和[Rgb]是否相同 匹配范围+-[Tolerance]",
    "gui.extension.pyAutoGui.locateOnScreen": "获取屏幕中图片对象[Image][Setting]坐标 灰度匹配[Grayscale]",
    "gui.extension.pyAutoGui.locateOn": "获取[Screen]中图片对象[Image][Setting]坐标 灰度匹配[Grayscale]",
    "gui.extension.pyAutoGui.imgCenter": "获取图片对象[Image]的中心坐标",
    "gui.blocklyText.pyAutoGui.messageSetting": "显示一个[SETTING]的消息框",
    "gui.blocklyText.pyAutoGui.platform": "消息框类型",
    "gui.blocklyText.pyAutoGui.simpleMessageBox": "简单消息框",
    "gui.blocklyText.pyAutoGui.messageBox": "消息框",
    "gui.blocklyText.pyAutoGui.inputMessageBox": "输入消息框",
    "gui.blocklyText.pyAutoGui.inputPasswordMessageBox": "密码消息框",
    "gui.blocklyText.pyAutoGui.title": "标题",
    "gui.blocklyText.pyAutoGui.text": "文本",
    "gui.blocklyText.pyAutoGui.okButton": "确认按钮名称",
    "gui.blocklyText.pyAutoGui.cancelButton": "取消按钮名称",
    "gui.blocklyText.pyAutoGui.inputDefault": "输入框默认值",
    "gui.blocklyText.pyAutoGui.encrypted": "加密",
    "gui.blocklyText.pyAutoGui.false": "关闭",
    "gui.blocklyText.pyAutoGui.true": "开启",
    "gui.blocklyText.pyAutoGui.None": "无",
    "gui.blocklyText.pyAutoGui.mouseLeft": "左键",
    "gui.blocklyText.pyAutoGui.mouseMiddle": "中间键",
    "gui.blocklyText.pyAutoGui.mouseRight": "右键",
    "gui.blocklyText.pyAutoGui.INQUAD": "缓慢开始",
    "gui.blocklyText.pyAutoGui.OUTQUAD": "缓慢结束",
    "gui.blocklyText.pyAutoGui.INOUTQUAD": "缓慢开始结束",
    "gui.blocklyText.pyAutoGui.INBOUNCE": "小幅度橡皮筋",
    "gui.blocklyText.pyAutoGui.OUTELASTIC": "大幅度橡皮筋",
    "gui.blocklyText.pyAutoGui.ActionDown": "按下",
    "gui.blocklyText.pyAutoGui.ActionUp": "松开",
    "gui.blocklyText.pyAutoGui.DirDown": "向下",
    "gui.blocklyText.pyAutoGui.DirUp": "向上",
    "gui.blocklyText.pyAutoGui.ABS": "绝对位置",
    "gui.blocklyText.pyAutoGui.REL": "相对位置",
    "gui.extension.pyRequests.request": "request",
    "gui.extension.pyRequests.requestGet": "发起一个GET请求[Url]",
    "gui.extension.pyRequests.requestPost": "发起一个[Data]POST请求[Url]",
    "gui.extension.pyRequests.getStatusCode": "获取[ReqObj]请求代码",
    "gui.extension.pyRequests.raiseForStatus": "检查[ReqObj]请求是否成功",
    "gui.extension.pyRequests.apparentEncoding": "获取[ReqObj]的真实编码",
    "gui.extension.pyRequests.setEncoding": "设置[ReqObj]的编码为[Code]",
    "gui.extension.pyRequests.handleHTML": "解析[ReqObj]请求内容为[Option]",
    "gui.extension.pyRequests.handleHeader": "获取[ReqObj]头部",
    "gui.extension.pyRequests.getValue": "获取[Item]的[Key]的值",
    "gui.blocklyText.pyRequests.text": "文本格式",
    "gui.blocklyText.pyRequests.json": "JSON格式",
    "gui.blocklyText.pyRequests.content": "二进制格式",
    "gui.extension.pyJson.dumpName": "编码",
    "gui.extension.pyJson.jsonDump": "编码JSON数据[Data]并保存到[FileObj] ASCII码转义[Switch]",
    "gui.extension.pyJson.jsonDumps": "编码JSON数据[Data]成字符串 ASCII码转义[Switch]",
    "gui.extension.pyJson.loadName": "解码",
    "gui.extension.pyJson.jsonload": "解码JSON文件对象[FileObj]成Python对象[PyObj]",
    "gui.extension.pyJson.jsonloads": "解码JSON数据[Data]成Python对象[PyObj]",
    "gui.extension.pyJson.valueName": "值的操作",
    "gui.extension.pyJson.get": "获取[PyObj][Key]的值",
    "gui.extension.pyJson.set": "设置[PyObj][Key]的值为[Value]",
    "gui.extension.pySchedule.createJobTitle": "任务创建",
    "gui.extension.pySchedule.createJob": "创建每间隔[Num][TimeType]运行一次的任务[JobName]",
    "gui.extension.pySchedule.createOneJob": "创建每[WEEKTYPES]运行一次的任务[JobName]",
    "gui.extension.pySchedule.createRandomJob": "创建每间隔[Num]-[LastNum][TimeType]之间随机运行一次的任务[JobName]",
    "gui.extension.pySchedule.setJobExactTime": "设置任务[JobName]的准确时间[Time]",
    "gui.extension.pySchedule.setJobTags": "设置任务[JobName]的标签[Tags]",
    "gui.extension.pySchedule.setJobUntil": "设置任务[JobName]的截至时间[Until]",
    "gui.extension.pySchedule.setJobFunction": "设置任务[JobName]的运行方法[Function](参数[Args])",
    "gui.extension.pySchedule.taskManageTitle": "任务管理",
    "gui.extension.pySchedule.cancelJob": "取消任务[JobName]",
    "gui.extension.pySchedule.getAllJob": "获取所有符合条件[Tags]的任务",
    "gui.extension.pySchedule.cancelAllJob": "取消所有符合条件[Tag]的任务",
    "gui.extension.pySchedule.getNextJobTime": "获取下一次任务运行的时间(秒)",
    "gui.extension.pySchedule.runAllJob": "运行所有任务",
    "gui.extension.pySchedule.runAllJobToo": "立刻获取所有任务的结果, 设置任务间隔[Num]秒",
    "gui.blocklyText.pySchedule.WEEKS": "周",
    "gui.blocklyText.pySchedule.DAYS": "天",
    "gui.blocklyText.pySchedule.HOURS": "时",
    "gui.blocklyText.pySchedule.MINUTES": "分",
    "gui.blocklyText.pySchedule.SECONDS": "秒",
    "gui.blocklyText.pySchedule.monday": "周一",
    "gui.blocklyText.pySchedule.tuesday": "周二",
    "gui.blocklyText.pySchedule.wednesday": "周三",
    "gui.blocklyText.pySchedule.thursday": "周四",
    "gui.blocklyText.pySchedule.friday": "周五",
    "gui.blocklyText.pySchedule.saturday": "周六",
    "gui.blocklyText.pySchedule.sunday": "周天",
    "gui.extension.pyXlrd.init": "文件读取",
    "gui.extension.pyXlrd.openWorkbook": "打开Excel工作簿[File]为[FileObj]",
    "gui.extension.pyXlrd.tableTitle": "工作表",
    "gui.extension.pyXlrd.getSheets": "获取[FileObj]的所有工作表",
    "gui.extension.pyXlrd.getSheetsName": "获取[FileObj]的所有工作表名称",
    "gui.extension.pyXlrd.selectSheet": "获取[FileObj]的[Screen]为[Value]的工作表对象[TableObj]",
    "gui.extension.pyXlrd.rowTitle": "行的操作",
    "gui.extension.pyXlrd.getRowNum": "获取[TableObj]的行数",
    "gui.extension.pyXlrd.getRow": "获取[TableObj]第[Num]行的从第[StartNum]列到第[EndNum]列的所有信息",
    "gui.extension.pyXlrd.getGenerator": "返回一个迭代[TableObj]的每一行信息的生成器",
    "gui.extension.pyXlrd.getRowType": "获取[TableObj]第[Num]行的从第[StartNum]列到第[EndNum]列的数据类型",
    "gui.extension.pyXlrd.getRowValue": "获取[TableObj]第[Num]行的从第[StartNum]列到第[EndNum]列的数据值",
    "gui.extension.pyXlrd.getRowLen": "获取[TableObj]第[Num]行有多少列",
    "gui.extension.pyXlrd.colTitle": "列的操作",
    "gui.extension.pyXlrd.getColNum": "获取[TableObj]的列数",
    "gui.extension.pyXlrd.getCol": "获取[TableObj]第[Num]列的从第[StartNum]行到第[EndNum]行的所有信息",
    "gui.extension.pyXlrd.getColType": "获取[TableObj]第[Num]列的从第[StartNum]行到第[EndNum]行的数据类型",
    "gui.extension.pyXlrd.getColValue": "获取[TableObj]第[Num]列的从第[StartNum]行到第[EndNum]行的数据值",
    "gui.extension.pyXlrd.cellTitle": "单元格的操作",
    "gui.extension.pyXlrd.getCell": "获取[TableObj]第[RowNum]行第[ColNum]列的单元格对象[CellObj]",
    "gui.extension.pyXlrd.getCellType": "获取[TableObj]第[RowNum]行第[ColNum]列的单元格类型",
    "gui.extension.pyXlrd.getCellValue": "获取[TableObj]第[RowNum]行第[ColNum]列的单元格值",
    "gui.extension.pyXlrd.optionIndex": "索引",
    "gui.extension.pyXlrd.optionName": "名称",
    "gui.extension.pyXlwt.init": "文件编辑",
    "gui.extension.pyXlwt.createWorkbook": "创建Excel文件[FileObj]并设置文件编码[Encoding]",
    "gui.extension.pyXlwt.addSheet": "为Excel文件[FileObj]添加工作表[SheetName]设置覆盖[Bool]并返回[SheetObj]",
    "gui.extension.pyXlwt.saveWorkbook": "保存Excel文件[FileObj]到[Path]",
    "gui.extension.pyXlwt.editTable": "编辑Excel工作表",
    "gui.extension.pyXlwt.createFontStyle": "创建字体样式[FontObj]",
    "gui.extension.pyXlwt.setFontBaseStyle": "设置字体[FontObj]基础样式[Style][Value]",
    "gui.extension.pyXlwt.setFontSpecialStyle": "设置字体[FontObj]特殊样式[Style]",
    "gui.extension.pyXlwt.createBorderStyle": "创建边框样式[BorderObj]",
    "gui.extension.pyXlwt.setBorderBaseStyle": "设置边框[BorderObj]基础样式[Style][Value]",
    "gui.extension.pyXlwt.createAlignmentStyle": "创建对齐样式[AlignmentObj]",
    "gui.extension.pyXlwt.setAlignmentHorzStyle": "设置对齐样式[AlignmentObj]水平方向[HorzStyle]",
    "gui.extension.pyXlwt.setAlignmentVertStyle": "设置对齐样式[AlignmentObj]垂直方向[VertStyle]",
    "gui.extension.pyXlwt.setAlignmentWrap": "设置对齐样式[AlignmentObj]自动换行[Bool]",
    "gui.extension.pyXlwt.createStyle": "创建总样式[StyleObj]",
    "gui.extension.pyXlwt.setStyle": "设置总样式[StyleObj]的[Type][Value]",
    "gui.extension.pyXlwt.easyStyle": "创建简单样式[StyleObj]样式[StyleString]数据格式[NumFormat]",
    "gui.extension.pyXlwt.eadit": "将数据[Label]以[Style]的样式写入工作表[SheetObj]的第[Row]行第[Col]列的单元格中",
    "gui.blocklyText.pyXlwt.font": "字体样式",
    "gui.blocklyText.pyXlwt.border": "边框样式",
    "gui.blocklyText.pyXlwt.alignment": "对齐样式",
    "gui.blocklyText.pyXlwt.alignLeft": "左端对齐",
    "gui.blocklyText.pyXlwt.alignRight": "右端对齐",
    "gui.blocklyText.pyXlwt.alignTop": "上端对齐",
    "gui.blocklyText.pyXlwt.centerAlign": "居中对齐",
    "gui.blocklyText.pyXlwt.alignBotton": "底端对齐",
    "gui.blocklyText.pyXlwt.top": "上边框",
    "gui.blocklyText.pyXlwt.botton": "下边框",
    "gui.blocklyText.pyXlwt.left": "左边框",
    "gui.blocklyText.pyXlwt.right": "右边框",
    "gui.blocklyText.pyXlwt.name": "字体名称",
    "gui.blocklyText.pyXlwt.height": "字体大小",
    "gui.blocklyText.pyXlwt.color_index": "字体颜色索引",
    "gui.blocklyText.pyXlwt.bold": "加粗",
    "gui.blocklyText.pyXlwt.underline": "下划线",
    "gui.blocklyText.pyXlwt.italic": "斜体",
    "gui.extension.pyOpenCV.base": "基础操作",
    "gui.extension.pyOpenCV.imread": "读取图片文件[FileName]设置[Option]并返回[ImgObj]",
    "gui.extension.pyOpenCV.imwrite": "保存图片[ImgObj]到[FileName]",
    "gui.extension.pyOpenCV.imdecode": "以[Option]模式读取[Buff]中的数据 返回图像[ImgObj]",
    "gui.extension.pyOpenCV.imencode": "将[Ext]图片[ImgObj]写入内存缓冲区[Buff]并返回是否成功写入[Ret]",
    "gui.extension.pyOpenCV.windowShow": "窗口显示",
    "gui.extension.pyOpenCV.imshow": "显示图片[ImgObj]到窗口[WinName]",
    "gui.extension.pyOpenCV.fullWindow": "设置窗口[WinName]全屏显示",
    "gui.extension.pyOpenCV.namedWindow": "创建一个名为[WinName]的窗口",
    "gui.extension.pyOpenCV.destroyWindow": "销毁一个名为[WinName]的窗口",
    "gui.extension.pyOpenCV.destroyAllWindows": "销毁全部窗口",
    "gui.extension.pyOpenCV.moveWindow": "移动一个名为[WinName]的窗口到坐标([X],[Y])",
    "gui.extension.pyOpenCV.resizeWindow": "调整一个名为[WinName]的窗口的大小([Width],[Height])",
    "gui.extension.pyOpenCV.resizeImg": "调整图像[ImgObj]尺寸为宽[Width]高[Height] 输出[OutImg]",
    "gui.extension.pyOpenCV.intercept": "截取图像[ImgObj]中第[Start]到第[End][Type]的图像 输出[OutImg]",
    "gui.extension.pyOpenCV.copyImg": "复制图片[ImgObj]到新对象[CopyImg]",
    "gui.extension.pyOpenCV.rotate": "将图片[ImgObj][RotateType]",
    "gui.extension.pyOpenCV.waitKey": "等待按键[Key]时间[Second]毫秒",
    "gui.extension.pyOpenCV.key": "按键[Key]",
    "gui.extension.pyOpenCV.selectROIs": "对图片[ImgObj]创建鼠标截图交互窗[WindowsName] [SwitchCenter]中心矩形 [SwitchCrosshair]交叉线  输出到坐标[OutImg]",
    "gui.extension.pyOpenCV.imgAttr": "图片属性",
    "gui.extension.pyOpenCV.imgShape": "获取图片[ImgObj]的行数、列数和通道数",
    "gui.extension.pyOpenCV.imgSize": "获取图片[ImgObj]的大小(像素总数)",
    "gui.extension.pyOpenCV.imgDtype": "获取图片[ImgObj]的数据类型",
    "gui.extension.pyOpenCV.copyMakeBorder": "在图片[ImgObj]上用[BorderType]添加宽度为上[TopNum]下[BottomNum]左[LeftNum]右[RightNum]的边框 输出[OutImg]",
    "gui.extension.pyOpenCV.video": "视频操作",
    "gui.extension.pyOpenCV.VideoCapture": "创建VideoCapture对象[VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureOpen": "使用VideoCapture对象[VideoObj]打开视频\\设备[VideoId]",
    "gui.extension.pyOpenCV.SetVideoCaptureSize": "设置VideoCapture对象[VideoObj]抓取分辨率为[Width]×[Height]",
    "gui.extension.pyOpenCV.VideoCaptureOpenVideo": "使用VideoCapture对象[VideoObj]打开视频文件[Video]",
    "gui.extension.pyOpenCV.VideoCaptureIsOpened": "使用VideoCapture对象[VideoObj]是否初始化完成",
    "gui.extension.pyOpenCV.VideoCaptureRelease": "关闭VideoCapture对象[VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureGrab": "VideoCapture对象[VideoObj]中是否存在下一帧",
    "gui.extension.pyOpenCV.VideoCaptureRead": "从VideoCapture对象[VideoObj]中抓取下一帧[VideoFrame]以及状态[VideoRet]",
    "gui.extension.pyOpenCV.VideoCaptureGet": "获取VideoCapture对象[VideoObj]属性[Properties]的值",
    "gui.extension.pyOpenCV.VideoCaptureSet": "设置VideoCapture对象[VideoObj]属性[Properties]的值为[Value]",
    "gui.extension.pyOpenCV.imgConvert": "图片转换",
    "gui.extension.pyOpenCV.adaptiveThreshold": "使用[Method]并配置最大值[MaxNum]阙值类型[Type]邻域大小[Size]常数[Constant]来处理图片[ImgObj]输出为图片[OutImg]",
    "gui.extension.pyOpenCV.cvtColor": "将图片[ImgObj]使用[Flags]进行转换成[OutImg]",
    "gui.extension.pyOpenCV.distanceTransform": "计算图片[ImgObj]中每个非零像素到最近零像素的[distanceType] 掩码[maskSize] 输出[Distance]",
    "gui.extension.pyOpenCV.threshold": "对图片[ImgObj]进行阈值[ThreshValue]最大值[MaxNum]阈值类型[ThresholdTypes]处理输出阈值[Ret]图片[OutImg]",
    "gui.extension.pyOpenCV.ObjectDetection": "对象检测",
    "gui.extension.pyOpenCV.CascadeClassifier": "创建级联分类器[CascadeClassifierObj]",
    "gui.extension.pyOpenCV.CascadeClassifierLoad": "级联分类器[CascadeClassifierObj]加载文件[FileName]",
    "gui.extension.pyOpenCV.CascadeClassifierEmpty": "级联分类器[CascadeClassifierObj]是否已加载完成",
    "gui.extension.pyOpenCV.detectMultiScale": "使用级联分类器[CascadeClassifierObj]通过[Method]缩放比例[ScaleFactor]构成目标的最小相邻个数[MinNeighbors]检测输入图像[ImgObj]中不同大小的对象并返回 方法3额外参数输出[OutputRejectLevels]",
    "gui.extension.pyOpenCV.Draws": "绘图工具",
    "gui.extension.pyOpenCV.putText": "在图片[ImgObj]上绘制文字[Text]左下角坐标X[PositionX]Y[PositionY]颜色[Color]字体大小[FontSize]线宽[Thickness]",
    "gui.extension.pyOpenCV.arrowedLine": "在图片[ImgObj]上绘制一条开始点[StartP]结束点[EndP]颜色[Color]粗细[Thickness]线条类型[LineType]的箭头线",
    "gui.extension.pyOpenCV.circle": "在图片[ImgObj]上绘制一条圆心[CenterP]半径[Radius]颜色[Color]粗细[Thickness]线条类型[LineType]的圆圈",
    "gui.extension.pyOpenCV.clipLine": "根据大小为[ImgSize]的矩形上裁剪一条开始点[StartP]结束点[EndP]的线并返回是否在于指定矩形[Ret]开始点[P1]结束点[P2]",
    "gui.extension.pyOpenCV.drawMarker": "在图片[ImgObj]上绘制一条位置[P]颜色[Color]标记类型[MarkerType]粗细[Thickness]标记大小[MarkerSize]线型[LineType]的标记",
    "gui.extension.pyOpenCV.ellipse": "在图片[ImgObj]上绘制一个圆心点[CenterP]轴[Axes]角度[Angle]起始角度[StartAngle]结束角度[EndAngle]颜色[Color]粗细[Thickness]线型[LineType]的椭圆",
    "gui.extension.pyOpenCV.line": "在图片[ImgObj]上绘制一个起始点[StartP]结束点[EndP]颜色[Color]粗细[Thickness]线型[LineType]的线",
    "gui.extension.pyOpenCV.rectangle": "在图片[ImgObj]上绘制一个首部顶点[TopP]尾部顶点[BottomP]颜色[Color]粗细[Thickness]线型[LineType]的矩形",
    "gui.extension.pyOpenCV.gesture": "图片[Img]中的手势是[Gesture]?",
    "gui.extension.pyOpenCV.gestureByVideo": "摄像头中的手势是[Gesture]?",
    "gui.extension.pyOpenCV.gaussian": "对图片[ImgObj]进行高斯模糊平滑处理，输出为图像[OutImg]",
    "gui.extension.pyOpenCV.canny": "对图片[ImgObj]进行边缘检测，阈值1[ThreshValue1]、阈值2[ThreshValue2]，输出边缘二值化图像[OutImg]",
    "gui.extension.pyOpenCV.contours": "在图片[ImgObj]中查找轮廓点，[Mode]模式、[Method]方法，返回结果到序列[Contours]",
    "gui.extension.pyOpenCV.drawContours": "在图片[ImgObj]上绘制轮廓线[Contours][Color]线宽[Thickness]，输出带轮廓线图像[OutImg]",
    "gui.blocklyText.pyOpenCV.retr_external": "仅外轮廓",
    "gui.blocklyText.pyOpenCV.retr_list": "无等级",
    "gui.blocklyText.pyOpenCV.retr_ccomp": "两等级树",
    "gui.blocklyText.pyOpenCV.retr_tree": "一等级树",
    "gui.blocklyText.pyOpenCV.chain_approx_none": "所有轮廓点",
    "gui.blocklyText.pyOpenCV.chain_approx_simple": "仅终点坐标",
    "gui.blocklyText.pyOpenCV.chain_approx_tc89_l1": "近似算法",
    "gui.blocklyText.pyOpenCV.imread_unchanged": "原图",
    "gui.blocklyText.pyOpenCV.imread_grayscale": "灰度图",
    "gui.blocklyText.pyOpenCV.imread_color": "BGR图",
    "gui.blocklyText.pyOpenCV.border_replicate": "复刻法",
    "gui.blocklyText.pyOpenCV.border_reflect": "反射法",
    "gui.blocklyText.pyOpenCV.border_reflect101": "反射法101",
    "gui.blocklyText.pyOpenCV.border_wrap": "外包装法",
    "gui.blocklyText.pyOpenCV.border_constant": "常量法",
    "gui.blocklyText.pyOpenCV.column": "列",
    "gui.blocklyText.pyOpenCV.row": "行",
    "gui.blocklyText.pyOpenCV.rotate_90_clockwise": "顺时针旋转90度",
    "gui.blocklyText.pyOpenCV.rotate_180": "旋转180度",
    "gui.blocklyText.pyOpenCV.rotate_90_counterclockwise": "逆时针旋转90度",
    "gui.blocklyText.pyOpenCV.cap_prop_buffersize": "图像缓冲区大小",
    "gui.blocklyText.pyOpenCV.cap_prop_pos_msec": "当前视频播放到的位置",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_width": "当前视频宽度",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_height": "当前视频高度",
    "gui.blocklyText.pyOpenCV.cap_prop_fps": "当前视频帧率",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_count": "当前视频帧数",
    "gui.blocklyText.pyOpenCV.adaptive_thresh_mean_c": "平均值算法",
    "gui.blocklyText.pyOpenCV.adaptive_thresh_gaussian_c": "高斯均值算法",
    "gui.blocklyText.pyOpenCV.thresh_binary": "超过阙值操作取最大值, 除此之外取0",
    "gui.blocklyText.pyOpenCV.thresh_binary_inv": "超过阙值操作取0, 除此之外取最大值",
    "gui.blocklyText.pyOpenCV.thresh_trunc": "超过阙值操作取临界点, 除此之外不变",
    "gui.blocklyText.pyOpenCV.thresh_tozero": "超过阙值操作不变, 除此之外取0",
    "gui.blocklyText.pyOpenCV.thresh_tozero_inv": "超过阙值操作取0, 除此之外不变",
    "gui.blocklyText.pyOpenCV.color_bgr2bgra": "将 Alpha 通道添加到 BGR",
    "gui.blocklyText.pyOpenCV.color_rgb2rgba": "将 Alpha 通道添加到 RGB",
    "gui.blocklyText.pyOpenCV.color_bgra2bgr": "从 BGR 图像中删除 alpha 通道",
    "gui.blocklyText.pyOpenCV.color_rgba2rgb": "从 RGB 图像中删除 alpha 通道",
    "gui.blocklyText.pyOpenCV.color_bgr2rgba": "在 BGR 和 RGB 颜色空间之间转换",
    "gui.blocklyText.pyOpenCV.color_rgb2bgra": "在 RGB 和 BGR 颜色空间之间转换",
    "gui.blocklyText.pyOpenCV.color_bgr2gray": "在 BGR 和灰度之间转换",
    "gui.blocklyText.pyOpenCV.color_rgb2gray": "在 RGB 和灰度之间转换",
    "gui.blocklyText.pyOpenCV.color_gray2bgr": "从灰度空间转换到 BGR",
    "gui.blocklyText.pyOpenCV.color_bgr2hsv": "在 BGR 与 HSV 颜色空间之间相互转换",
    "gui.blocklyText.pyOpenCV.color_rgb2hsv": "在 RGB 与 HSV 颜色空间之间相互转换",
    "gui.blocklyText.pyOpenCV.dist_l1": "曼哈顿距离",
    "gui.blocklyText.pyOpenCV.dist_l2": "简单欧几里得的距离",
    "gui.blocklyText.pyOpenCV.dist_c": "切比雪夫距离",
    "gui.blocklyText.pyOpenCV.detectmultiscale": "常用检测方法",
    "gui.blocklyText.pyOpenCV.detectmultiscale2": "检测方法2",
    "gui.blocklyText.pyOpenCV.detectmultiscale3": "检测方法3",
    "gui.blocklyText.pyOpenCV.filled": "填充线",
    "gui.blocklyText.pyOpenCV.line_4": "4连线",
    "gui.blocklyText.pyOpenCV.line_8": "8连线",
    "gui.blocklyText.pyOpenCV.line_aa": "抗锯齿线",
    "gui.blocklyText.pyOpenCV.marker_cross": " 十字线标记形状",
    "gui.blocklyText.pyOpenCV.marker_tilted_cross": " x准线标记形状",
    "gui.blocklyText.pyOpenCV.marker_star": " 星形标记形状",
    "gui.blocklyText.pyOpenCV.marker_diamond": " 菱形标记形状",
    "gui.blocklyText.pyOpenCV.marker_square": " 方形标记形状",
    "gui.blocklyText.pyOpenCV.marker_triangle_up": " 向上指向的三角形标记形状",
    "gui.blocklyText.pyOpenCV.marker_triangle_down": " 向下指向的三角形标记形状",
    "gui.blocklyText.pyOpenCV.haarcascade_eye": "眼睛数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_eye_tree_eyeglasses": "是否带墨镜数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalcatface": "猫脸数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalcatface_extended": "猫脸延伸数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt": "人脸(alt)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt_tree": "人脸(alt_tree)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt2": "人脸(alt2)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_default": "人脸(默认)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_fullbody": "身体数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_lefteye_2splits": "眼部(左眼)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_licence_plate_rus_16stages": "证件数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_lowerbody": "下半身数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_profileface": "人脸(侧视)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_righteye_2splits": "眼部(右眼)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_russian_plate_number": "俄罗斯字母车牌号数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_smile": "嘴部数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_upperbody": "上半身数据模型",
    "gui.blocklyText.pyOpenCV.gesture1": "手势1",
    "gui.blocklyText.pyOpenCV.gesture2": "手势2",
    "gui.blocklyText.pyOpenCV.gesture3": "手势3",
    "gui.blocklyText.pyOpenCV.gesture4": "手势4",
    "gui.blocklyText.pyOpenCV.gesture5": "手势5",
    "gui.blocklyText.unihiker.updateParamsMenu_width": "宽",
    "gui.blocklyText.unihiker.updateParamsMenu_height": "高",
    "gui.blocklyText.unihiker.updateParamsMenu_radial": "半径",
    "gui.blocklyText.unihiker.updateParamsMenu_linewidth": "线宽",
    "gui.blocklyText.unihiker.updateParamsMenu_start": "起始点",
    "gui.blocklyText.unihiker.updateParamsMenu_end": "终止点",
    "gui.blocklyText.unihiker.standardPointMenu_center": "中心",
    "gui.blocklyText.unihiker.standardPointMenu_top": "上",
    "gui.blocklyText.unihiker.standardPointMenu_bottom": "下",
    "gui.blocklyText.unihiker.standardPointMenu_left": "左",
    "gui.blocklyText.unihiker.standardPointMenu_right": "右",
    "gui.blocklyText.unihiker.standardPointMenu_topleft": "左上角",
    "gui.blocklyText.unihiker.standardPointMenu_topright": "右上角",
    "gui.blocklyText.unihiker.standardPointMenu_bottomleft": "左下角",
    "gui.blocklyText.unihiker.standardPointMenu_bottomright": "右下角",
    "gui.blocklyText.unihiker.GESTURE_gesture1": "手势1",
    "gui.blocklyText.unihiker.GESTURE_gesture2": "手势2",
    "gui.blocklyText.unihiker.GESTURE_gesture3": "手势3",
    "gui.blocklyText.unihiker.GESTURE_gesture4": "手势4",
    "gui.blocklyText.unihiker.GESTURE_gesture5": "手势5",
    "gui.blocklyText.unihiker.xyzsMenu_S": "强度",
    "gui.blocklyText.unihiker.expressionMenu_Angry": "愤怒",
    "gui.blocklyText.unihiker.expressionMenu_Nerve": "紧张",
    "gui.blocklyText.unihiker.expressionMenu_Peace": "平静",
    "gui.blocklyText.unihiker.expressionMenu_Shock": "惊讶",
    "gui.blocklyText.unihiker.expressionMenu_Sleep": "睡觉",
    "gui.blocklyText.unihiker.expressionMenu_Smile": "微笑",
    "gui.blocklyText.unihiker.expressionMenu_Sweat": "冒汗",
    "gui.blocklyText.unihiker.expressionMenu_Think": "思考",
    "gui.blocklyText.unihiker.expressionMenu_Wink": "眨眼",
    "gui.blocklyText.unihiker.unihikerPinMenu_button": "键",
    "gui.blocklyText.unihiker.blockTitle.screenDisplay": "屏幕显示",
    "gui.blocklyText.unihiker.showText": "对象名[NAME]显示文字[TEXT]在X[X] Y[Y] 字号[FONTSIZE]颜色[COLOR]",
    "gui.blocklyText.unihiker.showTextWithDigitalTubeStyle": "对象名[NAME]显示仿数码管字体[TEXT]在:X[X] Y[Y] 字号[FONTSIZE]颜色[COLOR]",
    "gui.blocklyText.unihiker.showImage": "对象名[NAME]显示图片[IMG]在X[X] Y[Y]",
    "gui.blocklyText.unihiker.showInsetLiveExpression": "对象名[NAME]显示内置动态表情[EXPRESSION]在X[X] Y[Y] 间隔[DURATION]秒",
    "gui.blocklyText.unihiker.addButtonClickFun": " 对象名[NAME]增加按钮[BUTTON]在X[X] Y[Y] 宽[WIDTH]高[HEIGHT] 点击回调函数[FUN]",
    "gui.blocklyText.unihiker.showClock": "对象名[NAME]显示时钟在X[X] Y[Y] 半径[RADIUS] 颜色[COLOR]",
    "gui.blocklyText.unihiker.showClockWithFillStyle": "对象名[NAME]显示填充时钟在X[X] Y[Y] 半径[RADIUS]边框颜色[COLOR1]填充颜色[COLOR2]",
    "gui.blocklyText.unihiker.showQrCode": "对象名[NAME]显示二维码 内容[VALUE]在X[X] Y[Y]边长[WIDTH]",
    "gui.blocklyText.unihiker.drawLine": "对象名[NAME]显示线段 起点X[X1] Y[Y1] 终点X[X2] Y[Y2] 线宽[LINEWIDTH] 颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRectangle": "对象名[NAME]显示矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]线宽[LINEWIDTH]边框颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRectangleWithFillStyle": "对象名[NAME]显示填充矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]填充颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRoundRectangle": "对象名[NAME]显示圆角矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]圆角半径[RADIUS]线宽[LINEWIDTH]边框颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRoundRectangleWithFillStyle": "对象名[NAME]显示圆角填充矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]圆角半径[RADIUS]填充颜色[COLOR]",
    "gui.blocklyText.unihiker.drawCircle": "对象名[NAME]显示圆形在X[X] Y[Y] 半径[RADIUS] 线宽[LINEWIDTH] 边框颜色[COLOR]",
    "gui.blocklyText.unihiker.drawCircleWithFillStyle": "对象名[NAME]显示填充圆形在X[X] Y[Y] 半径[RADIUS] 填充颜色[COLOR]",
    "gui.blocklyText.unihiker.updateObjectNumberParams": "更新对象名[NAME]的数字参数[PARAMS]为[VALUE]",
    "gui.blocklyText.unihiker.updateObjectNumbersParams": "更新对象名[NAME]的数字参数[PARAMS1]为[VALUE1] [PARAMS2]为[VALUE2]",
    "gui.blocklyText.unihiker.updateObjectStringParams": "更新对象名[NAME]的文本内容参数为[VALUE]",
    "gui.blocklyText.unihiker.updateObjectColorPalette": "更新对象名[NAME]的颜色为[COLOR]",
    "gui.blocklyText.unihiker.updateObjectColorRGB": "更新对象名[NAME]的颜色为 红[RED]绿[GREEN]蓝[BLUE]",
    "gui.blocklyText.unihiker.updateObjectColorName": "更新对象名[NAME]的颜色为[COLOR]",
    "gui.blocklyText.unihiker.updateObjectStandardPoint": "更新对象名[NAME]的基准点为[POSITION]",
    "gui.blocklyText.unihiker.updateTimeObjectValue": "更新时钟对象[NAME]的时间为[HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.unihiker.setObjClickFun": "对象名[NAME]的点击回调函数为[FUNCTIONNAME]",
    "gui.blocklyText.unihiker.whenClickTriggerFun": "当点击回调函数[FUNCTIONNAME]被触发",
    "gui.blocklyText.unihiker.updateImgObjSrc": "更新图片对象[IMG]图片源为[SRC]",
    "gui.blocklyText.unihiker.updateExpressionObjSrc": "更新表情对象[NAME]表情源为[EXPRESSION]",
    "gui.blocklyText.unihiker.blockTitle.mouseAndKeyboardEvent": "鼠标键盘事件",
    "gui.blocklyText.unihiker.whenMouseMove": "当接收到鼠标移动事件 返回坐标[X] [Y]",
    "gui.blocklyText.unihiker.whenButtonABPress": "当行空板按键[BUTTONAB]被按下",
    "gui.blocklyText.unihiker.whenKeyboardPress": "当键盘按键[KEYBOARD]被按下",
    "gui.blocklyText.unihiker.numberKeyboard": "数字及字母键[KEYBOARD]",
    "gui.blocklyText.unihiker.otherKeyboard": "其他键盘[KEYBOARD]",
    "gui.blocklyText.unihiker.waitForWhenButtonABPress": "等待直到行空板按键[BUTTONAB]被按下",
    "gui.blocklyText.unihiker.blockTitle.multithreading": "多线程",
    "gui.blocklyText.unihiker.startThread": "线程对象[THREAD]启动",
    "gui.blocklyText.unihiker.quitThread": "线程对象[THREAD]停止",
    "gui.blocklyText.unihiker.whenThreadStart": "当线程对象[THREAD]启动后执行",
    "gui.blocklyText.unihiker.blockTitle.lmSensors": "板载传感器",
    "gui.blocklyText.unihiker.isButtonABPressed": "按钮[BUTTONAB]被按下？",
    "gui.blocklyText.unihiker.readAmbientLight": "读取环境光强度",
    "gui.blocklyText.unihiker.readMicrophoneSound": "读取麦克风声音强度",
    "gui.blocklyText.unihiker.readXYZSAcceleratedSpeed": "读取加速度的值[XYZS]",
    "gui.blocklyText.unihiker.readGyroscopeXYZ": "读取陀螺仪的值[XYZ]",
    "gui.blocklyText.unihiker.blockTitle.onboardBuzzer": "板载蜂鸣器",
    "gui.blocklyText.unihiker.playMusic": "播放音乐[MUSIC]重复[REPEAT]",
    "gui.blocklyText.unihiker.musicSetTempo": "设置每拍等同[TICKS]分音符， 每分钟节拍数[TEMPO]",
    "gui.blocklyText.unihiker.setBuzzerFreq": "播放音符[NOTE] [BEAT]拍",
    "gui.blocklyText.unihiker.backSetBuzzerFreq": "后台播放音符[NOTE]",
    "gui.blocklyText.unihiker.buzzerSwitch": "停止后台播放",
    "gui.blocklyText.unihiker.buzzerRedirect": "蜂鸣器重定向到引脚[PIN]",
    "gui.blocklyText.unihiker.blockTitle.pinOperation": "引脚操作",
    "gui.blocklyText.unihiker.unihikerPin": "行空板引脚[PIN]",
    "gui.blocklyText.unihiker.digitalRead": "读取数字引脚 [PIN]",
    "gui.blocklyText.unihiker.analogRead": "读取模拟引脚(ADC 0-4095)[PIN]",
    "gui.blocklyText.unihiker.digitalWrite": "设置数字引脚[PIN]输出[VALUE]",
    "gui.blocklyText.unihiker.analogWrite": "设置模拟引脚[PIN] 输出(PWM)[VALUE]",
    "gui.blocklyText.unihiker.blockTitle.audioPlayback": "音频录放",
    "gui.blocklyText.unihiker.getRecord": "录音[TIME]秒直到结束，文件名[NAME]",
    "gui.blocklyText.unihiker.getRecordBackground": "开始后台录音，文件名[NAME]",
    "gui.blocklyText.unihiker.stopRecordBackground": "结束后台录音",
    "gui.blocklyText.unihiker.playAudio": "播放音频文件[NAME]直到结束",
    "gui.blocklyText.unihiker.startPlayAudioBackground": "开始后台播放音频文件[NAME]",
    "gui.blocklyText.unihiker.getBgAudioRemainingDuration": "获取后台播放音频的剩余时长(秒)",
    "gui.blocklyText.unihiker.pauseBgAudio": "暂停后台播放",
    "gui.blocklyText.unihiker.continueBgAudio": "重新开始后台播放",
    "gui.blocklyText.unihiker.stopBgAudio": "结束后台播放",
    "gui.extension.unihiker.input.button": "按钮",
    "gui.extension.unihiker.input.hello": "你好",
    "gui.blocklyText.unihiker.updateButtonStatus": "更新按钮对象[BUTTON]为[STATUS]",
    "gui.blocklyText.unihiker.updateObjectParamWithInput": "更新对象[OBJECT]的参数[KEY]为[VALUE]",
    "gui.blocklyText.unihiker.updateObjectParamsWithInput": "更新对象[OBJECT]的参数1[KEY1]为[VALUE1] 参数2[KEY2]为[VALUE2]",
    "gui.blocklyText.unihiker.removeObject": "删除对象[OBJECT]",
    "gui.blocklyText.unihiker.removeAllObject": "删除所有对象",
    "gui.blocklyText.unihiker.buttonStatusMenu_normal": "启用",
    "gui.blocklyText.unihiker.buttonStatusMenu_disable": "禁用",
    "gui.blocklyText.unihiker.updateParamsMenu_fontSize": "字体大小",
    "gui.blocklyText.unihiker.generate.keyboardWithButtonConflict": "！错误提示：键盘按键[AB]与行空板按键[AB]事件仅能同时使用一个，请删除重复定义的模块",
    "gui.blocklyText.pythonsen0291.extensionName": "功率计",
    "gui.blocklyText.pythondfr0553.extensionName": "AD 模块",
    "gui.blocklyText.pythondfr0563.extensionName": "电量计",
    "gui.blocklyText.pyBaiDuAi.app_id": "AppID",
    "gui.blocklyText.pyBaiDuAi.api_key": "API Key",
    "gui.blocklyText.pyBaiDuAi.secret_key": "Secret Key",
    "gui.blocklyText.pyBaiDuAi.age": "年龄",
    "gui.blocklyText.pyBaiDuAi.gender": "性别",
    "gui.blocklyText.pyBaiDuAi.beauty": "颜值",
    "gui.blocklyText.pyBaiDuAi.face_shape": "脸型",
    "gui.blocklyText.pyBaiDuAi.glasses": "眼镜",
    "gui.blocklyText.pyBaiDuAi.expression": "表情",
    "gui.blocklyText.pyBaiDuAi.face_probability": "真人",
    "gui.blocklyText.pyBaiDuAi.Width": "宽度",
    "gui.blocklyText.pyBaiDuAi.Height": "高度",
    "gui.blocklyText.pyBaiDuAi.mask": "口罩",
    "gui.blocklyText.pyBaiDuAi.objectDetect": "图像单主体检测",
    "gui.blocklyText.pyBaiDuAi.advancedGeneral": "通用物体和场景",
    "gui.blocklyText.pyBaiDuAi.plantDetect": "植物",
    "gui.blocklyText.pyBaiDuAi.animalDetect": "动物",
    "gui.blocklyText.pyBaiDuAi.currency": "货币",
    "gui.blocklyText.pyBaiDuAi.basicGeneral": "通用文字",
    "gui.blocklyText.pyBaiDuAi.numbers": "数字",
    "gui.blocklyText.pyBaiDuAi.licensePlate": "车牌",
    "gui.blocklyText.pyBaiDuAi.handwriting": "手写文字",
    "gui.blocklyText.pyBaiDuAi.init": "初始化",
    "gui.blocklyText.pyBaiDuAi.AipFaceInit": "初始化百度人脸识别 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipImageClassifyInit": "初始化百度图像识别 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipOcrInit": "初始化百度文字识别 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipBodyAnalysisInit": "初始化百度人体分析 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.getFace": "获取人脸对象",
    "gui.blocklyText.pyBaiDuAi.getImage": "获取图片对象",
    "gui.blocklyText.pyBaiDuAi.face": "人脸识别",
    "gui.blocklyText.pyBaiDuAi.generationFaceFormBase64": "从本地生成人脸图片对象 [IMG]",
    "gui.blocklyText.pyBaiDuAi.generationFaceFormUrl": "从网络生成人脸图片对象 [URL]",
    "gui.blocklyText.pyBaiDuAi.detect": "识别 [Image] 人脸信息并存入结果",
    "gui.blocklyText.pyBaiDuAi.detectIsOk": "人脸识别是否成功 ?",
    "gui.blocklyText.pyBaiDuAi.detectGet": "获取人脸信息的 [TYPE]",
    "gui.blocklyText.pyBaiDuAi.match": "对比 [Face1] 和 [Face2] 的人脸相似度 ",
    "gui.blocklyText.pyBaiDuAi.groupAdd": "创建人脸库 [GroupName]",
    "gui.blocklyText.pyBaiDuAi.addUser": "添加名为 [FaceName] 的用户的人脸 [Face] 到人脸库 [GroupName]",
    "gui.blocklyText.pyBaiDuAi.img": "图像识别",
    "gui.blocklyText.pyBaiDuAi.imageDetectType": "识别图中 [IMG] 包含的 [DetectType]",
    "gui.blocklyText.pyBaiDuAi.ocr": "文字识别",
    "gui.blocklyText.pyBaiDuAi.body": "人体分析",
    "gui.blocklyText.pyBaiDuAi.gesture": "识别图中 [IMG] 包含的手势",
    "gui.blocklyText.pyBaiDuAi.gestureis": "手势[Gesture] 是 [GestureType]?",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysis": "识别图中 [IMG] 包含的人体关键点",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysisIsOk": "人体关键点结果是否识别成功",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysisGet": "获取人体关键点结果的[BODY]的[XY]坐标",
    "gui.blocklyText.pyBaiDuAi.BASE64": "BASE64",
    "gui.blocklyText.pyBaiDuAi.URL": "URL",
    "gui.blocklyText.pyBaiDuAi.FACE_TOKEN": "FACE_TOKEN",
    "gui.blocklyText.pyBaiDuAi.readImage": "读取图片 [IMG]",
    "gui.blocklyText.pyBaiDuAi.search": "从人脸库 [GroupName] 中搜索与 [Face] 相似度 >= [Score] 的名字",
    "gui.blocklyText.pyBaiDuSpeech.init": "初始化百度语音客户端 [SETTING]",
    "gui.blocklyText.pyBaiDuSpeech.asr": "识别一个 采样率 [Rate] [Format] 音频文件 [Speech] ",
    "gui.blocklyText.pyBaiDuSpeech.synthesisOption": "设置语音合成参数 格式[Format] 嗓音[Per] 语速[Spd] 音调[Pit] 音量[Vol]",
    "gui.blocklyText.pyBaiDuSpeech.synthesis": "请求将 [Text] 转换成语音",
    "gui.blocklyText.pyBaiDuSpeech.isOk": "语音合成成功?",
    "gui.blocklyText.pyBaiDuSpeech.saveAudio": "将合成结果保存 文件名[Audio]",
    "gui.blocklyText.pyBaiDuSpeech.pcm": "pcm文件格式",
    "gui.blocklyText.pyBaiDuSpeech.wav": "wav文件格式",
    "gui.blocklyText.pyBaiDuSpeech.amr": "amr文件格式",
    "gui.blocklyText.pyBaiDuSpeech.mp3": "mp3文件格式",
    "gui.extension.pyXfSpeech.init": "初始化",
    "gui.extension.pyXfSpeech.speechInit": "讯飞语音初始化 [SETTING]",
    "gui.extension.pyXfSpeech.speechSynthesis": "语音合成",
    "gui.extension.pyXfSpeech.speechSynthesisOption": "设置语音合成参数 语速[SPEED] 音调[PITCH] 音量[VOLUME] [SETTING]",
    "gui.extension.pyXfSpeech.speechSynthesisSend": "通过语音合成[Text]并保存到[File]中",
    "gui.extension.pyXfSpeech.speechRecognition": "语音识别",
    "gui.extension.pyXfSpeech.speechRecognitionSend": "识别[Audio]音频文件",
    "gui.blocklyText.pyXfSpeech.noBackgroundsound": "无",
    "gui.blocklyText.pyXfSpeech.withBackgroundsound": "有",
    "gui.blocklyText.pyXfSpeech.xiaoyan": "讯飞小燕",
    "gui.blocklyText.pyXfSpeech.aisjiuxu": "讯飞许久",
    "gui.blocklyText.pyXfSpeech.aisxping": "讯飞小萍",
    "gui.blocklyText.pyXfSpeech.aisjinger": "讯飞小婧",
    "gui.blocklyText.pyXfSpeech.aisbabyxu": "讯飞许小宝",
    "gui.blocklyText.pyXfSpeech.vcn": "发音人",
    "gui.blocklyText.pyXfSpeech.bgs": "背景音",
    "gui.blocklyText.pythondfr0095.extensionName": "红外发送",
    "gui.blocklyText.pythondfr0095.pinpongIRSend": "红外发送 引脚 [PIN] 发送 [DATA]",
    "gui.extension.pictureai.fist": "拳头",
    "gui.extension.pictureai.pray": "祈祷",
    "gui.extension.pictureai.bow": "作揖",
    "gui.extension.pictureai.farewell": "作别",
    "gui.extension.pictureai.one_heart": "单手比心",
    "gui.extension.pictureai.thumbs_up": "点赞",
    "gui.extension.pictureai.love_you": "我爱你",
    "gui.extension.pictureai.palm_up": "掌心向上",
    "gui.extension.pictureai.two_hearts": "双手比心",
    "gui.extension.pictureai.middle_finger": "竖中指",
    "gui.extension.pictureai.face": "脸",
    "gui.extension.pictureai.switch_account_failed": "切换独立账户失败",
    "gui.extension.pictureai.id_not_exist": "该id不存在，请检查后重试",
    "gui.extension.pictureai.error": "错误",
    "gui.extension.pictureai.file_not_exist_in_path": "该路径下不存在文件,请检查路径是否正确",
    "gui.extension.pictureai.path_not_exist": "该路径不存在,请检查路径是否正确",
    "gui.extension.pictureai.save_image_failed": "保存图片失败",
    "gui.extension.pictureai.image_recognition": "图像识别",
    "gui.extension.pictureai.no_stage_video_stream": "未获取到舞台视频流",
    "gui.extension.pictureai.please_fill_image_address": "请先填写图片地址",
    "gui.extension.pictureai.image_address_format_error": "图片地址格式错误",
    "gui.extension.pictureai.face_recognition": "人脸识别",
    "gui.extension.pictureai.main_character_in_image": "图中主要人物为",
    "gui.extension.pictureai.one_gender_age": "1个{gender}，大约{age}岁",
    "gui.extension.pictureai.male": "男生",
    "gui.extension.pictureai.female": "女生",
    "gui.extension.pictureai.wearing_glasses": ",还戴着眼镜",
    "gui.extension.pictureai.smiling": ", 在微笑",
    "gui.extension.pictureai.no_face_in_image": "图片中没有人脸，换一张再试试",
    "gui.extension.pictureai.app_authentication_failed": "应用鉴权失败，请至控制台检查是否开放人脸识别功能",
    "gui.extension.pictureai.app_usage_exceeded": "应用使用次数超出额度，请至控制台检查",
    "gui.extension.pictureai.square_face": "方脸",
    "gui.extension.pictureai.cone_face": "锥子脸",
    "gui.extension.pictureai.oval_face": "鹅蛋脸",
    "gui.extension.pictureai.heart_face": "心形脸",
    "gui.extension.pictureai.round_face": "圆脸",
    "gui.extension.pictureai.unknown_face": "未知脸",
    "gui.extension.pictureai.glasses": "眼镜",
    "gui.extension.pictureai.sunglasses": "墨镜",
    "gui.extension.pictureai.smile": "微笑",
    "gui.extension.pictureai.normal": "正常",
    "gui.extension.pictureai.angry": "愤怒",
    "gui.extension.pictureai.disgust": "厌恶",
    "gui.extension.pictureai.fear": "恐惧",
    "gui.extension.pictureai.happy": "高兴",
    "gui.extension.pictureai.sad": "悲伤",
    "gui.extension.pictureai.surprised": "惊讶",
    "gui.extension.pictureai.expressionless": "无表情",
    "gui.extension.pictureai.pouting": "撅嘴",
    "gui.extension.pictureai.making_faces": "鬼脸",
    "gui.extension.pictureai.no_mask": "没戴口罩",
    "gui.extension.pictureai.wearing_mask": "戴口罩",
    "gui.extension.pictureai.please_do_face_recognition_first": "请先进行人脸信息识别",
    "gui.extension.pictureai.face_comparison": "人脸对比",
    "gui.extension.pictureai.face_similarity": "两张图中人脸相似度为",
    "gui.extension.pictureai.ensure_faces_in_both_images": "请确定两张图片中都包含人脸",
    "gui.extension.pictureai.check_image": "请检查图片",
    "gui.extension.pictureai.text_recognition": "文字识别",
    "gui.extension.pictureai.handwriting": "手写字",
    "gui.extension.pictureai.license_plate": "车牌号",
    "gui.extension.pictureai.number": "数字",
    "gui.extension.pictureai.no_text_in_image": "图片中不包含文字",
    "gui.extension.pictureai.produced_in": ", 生产于",
    "gui.extension.pictureai.face_value": ", 面值为",
    "gui.extension.pictureai.possible_non_currency": "图中可能是非货币",
    "gui.extension.pictureai.possible_object": "图中可能是",
    "gui.extension.pictureai.gesture_recognition": "手势识别",
    "gui.extension.pictureai.someone_thumbs_up_in_image": "图片里有人为你点赞",
    "gui.extension.pictureai.someone_makes_heart_with_single_hands_in_image": "图片里有人为你单手比心",
    "gui.extension.pictureai.someone_booing_in_image": "图片里有人喝倒彩，要加油了",
    "gui.extension.pictureai.someone_says_congratulations_in_image": "图片里有人说恭喜",
    "gui.extension.pictureai.someone_says_ok_in_image": "图片里有人说OK",
    "gui.extension.pictureai.someone_prays_in_image": "图片里有人祈祷",
    "gui.extension.pictureai.someone_makes_heart_with_both_hands_in_image": "图片里有人为你双手比心",
    "gui.extension.pictureai.someone_says_goodbye_in_image": "图片里有人对你说在下告辞",
    "gui.extension.pictureai.fist_in_image": "图片里有一个拳头",
    "gui.extension.pictureai.rock_on": "摇滚起来",
    "gui.extension.pictureai.someone_despises_you_in_image": "图里有人鄙视你！",
    "gui.extension.pictureai.cannot_understand_gesture_in_image": "看不懂图片里的手势，换一张试试",
    "gui.extension.pictureai.face_in_image_no_gesture": "图里是人脸，没有手势",
    "gui.extension.pictureai.recognition_failed": "识别失败",
    "gui.extension.pictureai.human_keypoints_recognition": "人体关键点识别",
    "gui.extension.pictureai.check_image_no_valid_human": "请检查图片，图中没有找到有效人体，换一张再试试",
    "gui.extension.pictureai.result_as_shown": "结果如图",
    "gui.extension.pictureai.please_do_human_keypoints_analysis_first": "请先进行人体关键点分析",
    "gui.extension.serialPort.name": "发送数据至实时模式",
    "gui.extension.serialPortjJS.name": "接收上传模式数据",
    "gui.extension.serialPort.description": "便捷实现上传模式发送多组数据，实时模式通过串口接收数据功能",
    "paint.paintEditor.hue": "颜色",
    "paint.paintEditor.saturation": "饱和度",
    "paint.paintEditor.brightness": "亮度",
    "gui.comingSoon.message1": "别急，我们还在开发 {emoji}",
    "gui.comingSoon.message2": "很快就能用了……",
    "gui.comingSoon.message3": "我们还在开发这个功能 {emoji}",
    "paint.paintEditor.fill": "填充",
    "paint.paintEditor.costume": "造型",
    "paint.paintEditor.group": "组合",
    "paint.paintEditor.ungroup": "拆散",
    "paint.paintEditor.undo": "撤销",
    "paint.paintEditor.redo": "重做",
    "paint.paintEditor.forward": "往前放",
    "paint.paintEditor.backward": "往后放",
    "paint.paintEditor.front": "放最前面",
    "paint.paintEditor.back": "放最后面",
    "paint.paintEditor.more": "更多",
    "paint.modeTools.brushSize": "粗细",
    "paint.modeTools.eraserSize": "橡皮擦大小",
    "paint.modeTools.copy": "复制",
    "paint.modeTools.paste": "粘贴",
    "paint.modeTools.delete": "删除",
    "paint.modeTools.curved": "曲线",
    "paint.modeTools.pointed": "折线",
    "paint.modeTools.thickness": "粗细",
    "paint.modeTools.flipHorizontal": "水平翻转",
    "paint.modeTools.flipVertical": "垂直翻转",
    "paint.modeTools.filled": "实心",
    "paint.modeTools.outlined": "轮廓",
    "paint.paintEditor.bitmap": "转换为位图",
    "paint.paintEditor.vector": "转换为矢量图",
    "paint.paintEditor.stroke": "轮廓",
    "paint.brushMode.brush": "画笔",
    "paint.eraserMode.eraser": "橡皮擦",
    "paint.fillMode.fill": "填充",
    "paint.lineMode.line": "线段",
    "paint.ovalMode.oval": "圆",
    "paint.rectMode.rect": "矩形",
    "paint.reshapeMode.reshape": "变形",
    "paint.roundedRectMode.roundedRect": "圆角矩形",
    "paint.selectMode.select": "选择",
    "paint.textMode.text": "文本",
    "paint.colorPicker.swap": "交换",
    "gui.dialog.notConnectDevice": "没有连接设备，请从菜单选择需要连接的设备",
    "gui.dialog.prompt": "提示",
    "gui.dialog.openPort": "打开端口",
    "gui.dialog.error": "错误",
    "gui.dialog.notSelectDevice": "没有选择设备,请点击软件左下角”扩展“,</br>点击选择你需要连接的设备",
    "gui.dialog.connectDeviceError1": "连接设备(",
    "gui.dialog.connectDeviceError2": ")失败，请进行如下检查：</br> *更换usb口重新插拔usb线</br> *检查是否连接了",
    "gui.dialog.connectDeviceError3": "主控板</br> *加入官方交流群(671877416)反馈问题",
    "gui.dialog.calibration1": "</br> *请转动",
    "gui.dialog.calibration2": "主控板进行校准</br>",
    "gui.dialog.runAsManager": "请关闭软件后在软件图标上右键选择“以管理员身份运行”，启动后再次选择此功能",
    "gui.dialog.runErrorForVortex": "系统运行不正常，退出Vortex握手",
    "gui.dialog.runError": "系统运行不正常",
    "gui.dialog.close": "关闭",
    "gui.dialog.variableNameSpecialCharacters": "变量名 %1 不能包含特殊字符:[\\`~!-@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "函数名 %1 不能包含特殊字符:[\\`~!-@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "函数参数 %1 不能包含特殊字符:[\\`~!-@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "和",
    "gui.dialog.yes": "是",
    "gui.dialog.no": "否",
    "gui.dialog.continue": "继续",
    "gui.dialog.maxReset": "是否删除编辑区代码并加载出厂设置代码",
    "gui.dialog.feedbackCannotEmpty": "意见/反馈 不能为空!",
    "gui.dialog.noInstallCompiler": "没有安装编译器",
    "gui.dialog.install": "在线安装",
    "gui.dialog.cancle": "取消",
    "gui.dialog.installingCompiler": "正在安装编译器",
    "gui.dialog.uploading": "正在上传",
    "gui.dialog.changeLanuage": "切换语言将清除当前程序，是否继续?",
    "gui.dialog.boardNotSupportMpy": "当前主板: %1 不支持microPython",
    "gui.dialog.loadBlankItem": "不支持的项目</br> 正在重新加载一个空白的项目",
    "gui.dialog.switchBaudError": "切换波特率出错</br>",
    "gui.dialog.turnMicrobitCompass": "需要校准电子罗盘，请调整mciro:bit的姿态使闪烁的led灯移动，直到所有LED灯都点亮",
    "gui.dialog.turnCalliopeCompass": "需要校准电子罗盘，请调整calliope的姿态使闪烁的led灯移动，直到所有LED灯都点亮",
    "gui.dialog.operationShow": "操作演示",
    "gui.dialog.mpython": "掌控板",
    "gui.dialog.computer": "电脑",
    "gui.dialog.delete": "删除",
    "gui.dialog.sureDeleteFile": "真的要删除 %1 上的文件[%2]吗？",
    "gui.dialog.sureDeleteFolder": "真的要删除 %1 上的文件夹[%2]吗？",
    "gui.dialog.uncorrectPath": "没有选择正确路径",
    "gui.dialog.laterDownLoad": "稍后更新",
    "gui.dialog.downLoadUpdate": "下载更新",
    "gui.dialog.downLoading": "下载中...",
    "gui.dialog.dwnLoadWebsite": "去官网下载",
    "gui.dialog.laterUpdate": "稍后安装",
    "gui.dialog.update": "安装",
    "gui.dialog.getUpdateMsgError": "获取的更新显示信息出错",
    "gui.dialog.ok": "确定",
    "gui.dialog.closeCodeBoxes": "确定要关闭 %1 个代码框吗？",
    "gui.dialog.emailError": "邮箱格式不正确!",
    "gui.dialog.emailEmpty": "邮箱不能为空!",
    "gui.dialog.bigFilePrompt": "文件有点大, 请耐心等待, 谢谢!",
    "gui.dialog.successFeedbackPrompt1": "谢谢你的反馈, 3秒后将重新加载页面",
    "gui.dialog.successFeedbackPrompt2": "你的反馈意见已经提交, 请过几秒钟之后再提交吧",
    "gui.dialog.failedFeedbackPrompt": "提交反馈失败, 给你造成的不便我们感到非常抱歉, 你还可以加官方QQ交流群进行反馈, 谢谢",
    "gui.dialog.successFeedbackPrompt3": "谢谢你的反馈",
    "gui.dialog.forcedCloseSoftWare": "保存文件出现错误, 是否强制关闭软件",
    "gui.dialog.saveSuccess": "保存成功",
    "gui.dialog.noSelectTruePath": "没有选择正确的路径",
    "gui.dialog.newItemContent": "你将创建一个空白的项目来覆盖当前的项目，确认创建？",
    "gui.dialog.deleteBlocksError": "删除 %1 的积木块失败，请手动删除",
    "gui.dialog.netIsConnect": "请检查一下网络是否连接",
    "gui.dialog.needToUpdate": "是否需要更新编译器到最新版本: %1",
    "gui.dialog.cantUseMpythonInArduinoC": "没有安装编译器，在Arduino C下无法使用掌控板。若电脑没有网络可在官网下载使用“编译器离线插件包”",
    "gui.dialog.enterMpyMode": "进入micropython模式",
    "gui.dialog.runHardware": "要操纵硬件,请先连接设备",
    "gui.dialog.sureDelete": "你确定要删除吗?",
    "gui.dialog.notSupportWebGL": "加载舞台发生错误，可能是不支持WebGL，</br>下面是详细错误信息: </br> %1 </br>QQ群联系我们：671877416",
    "gui.dialog.boardIsnotMPython": "当前连接主控板不是mPython，请连接mPython",
    "gui.dialog.swdMicrobitUpload": "您的电脑必须使用SWD烧录micro:bit，请以管理员权限运行Mind+",
    "gui.dialog.openPortError": "打开串口 %1 错误: %2",
    "gui.dialog.pressedA": "请按住按键A，不要松开",
    "gui.dialog.unpressedA": "请松开按键A",
    "gui.dialog.unsupportProject": "不支持的项目</br>正在重新加载一个空白项目",
    "gui.dialog.openProjectError": "实时模式下的项目必须在实时模式下打开</br>请检查当前界面是在实时模式界面还是在上传模式界面",
    "gui.dialog.installCompilerError1": "编译器下载失败, 你可以下载离线安装器或尝试重新下载",
    "gui.dialog.installCompilerOffline": "下载离线安装器",
    "gui.dialog.installCompilerError2": "校验出错, 你可以下载离线安装器或尝试重新下载",
    "gui.dialog.installCompilerError3": "安装出错, 你可以下载离线安装器或尝试重新下载",
    "gui.dialog.installCompilerSuccess": "编译器已经安装完成，你可以在ArduinoC下使用掌控板",
    "gui.dialog.sureAdapter": "未找到适配器，请确认是否连接适配器",
    "gui.dialog.checkPort": "打开串口失败，请尝试重新连接串口，或检查串口是否被占用",
    "gui.dialog.changeBoard": "切换主控板将清除当前程序，是否继续？",
    "gui.dialog.removeBoard": "移除主控板将清除当前程序，是否继续？",
    "gui.crashMessage.somethingWrong": "哎呀！ 发生错误了.",
    "gui.crashMessage.cacheListPrompt": "你可以通过菜单「项目」>「缓存列表」中找到项目文件",
    "gui.crashMessage.reload": "重新启动",
    "gui.crashMessage.submit": "提交",
    "gui.crashMessage.attachment": "附件",
    "gui.crashMessage.errFeedbackNote": "你可以在这里写入错误信息或者建议, Mind+有你的加入会变得更好",
    "gui.crashMessage.promptContent": "很抱歉，好像Mind +已经崩溃。 你可以在右侧将此错误反馈给Mind +团队, 非常感谢！ 请刷新您的页面以重试。",
    "gui.dialog.isSaveProject": "是否保存当前项目？",
    "gui.dialog.save": "保存",
    "gui.dialog.notSave": "不保存",
    "gui.dialog.cancelSave": "取消保存",
    "gui.dialog.saveMPProjectToSb3": "当前选择格式为.sb3，仅保存实时模式下的部分基础积木，是否继续保存?",
    "gui.dialog.variableIsNull": "变量名不能为空",
    "gui.dialog.variableIsRepeat": "变量名不能重复，请修改",
    "gui.dialog.listNameIsNull": "列表名不能为空",
    "gui.dialog.pictureAINotNull": "不能为空",
    "gui.dialog.pictureAIInitServer": "请先初始化服务器",
    "gui.dialog.pictureAIInitServerError": "初始化服务器出错，请检查账号及秘钥",
    "gui.dialog.versionPrompt": "当前项目版本「%1」",
    "gui.dialog.versionGreater": "项目版本高于当前软件版本, 项目版本「%1」",
    "gui.dialog.noModulePrompt": "当前分类下无小模块，详见帮助",
    "gui.dialog.firstSelectBoard": "请先选择“主控板”或“套件”",
    "gui.dialog.downloadFileError": "下载文件出错",
    "gui.dialog.noSearchModules": "没有搜索到相关的模块, 请检查搜索路径",
    "gui.dialog.networkError": "网络错误",
    "gui.dialog.saveThirdModulesError": "保存用户库出错",
    "gui.dialog.coverUseExForLoadProject": "用户库中存在同名库，已经替换为项目中的用户库",
    "gui.dialog.isContinueUpdateThirdModule": "当前有相同的用户扩展库，继续更新会覆盖当前的用户库，是否继续？",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "修改了%1 的字段 「%2」, 需要手动在扩展库重新导入，本次刷新取消",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "解析 %1 出错, 将继续本次刷新, 但是不更新「%2」里的内容",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块，再刷新，本次刷新取消。",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh1": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块并检查精灵「%1」的积木块，再刷新，本次刷新取消。",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh2": "积木块形状或输入框类型修改，请检查精灵「%1」的积木块，再刷新，本次刷新取消。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块，再加载，本次加载取消。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad1": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块并检查精灵「%1」的积木块，再加载，本次加载取消。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad2": "积木块形状或输入框类型修改，请检查精灵 「%1」 的积木块，再加载，本次加载取消。",
    "gui.dialog.thirdex.filesEmpty": "files字段不能为空，可能会导致相关文件丢失，请检查导入的用户库文件！",
    "gui.dialog.thirdex.refreshing": "正在刷新...",
    "gui.dialog.thirdex.refreshSuccess": "刷新成功",
    "gui.dialog.isSaving": "正在保存...",
    "gui.dialog.isExporting": "正在导出...",
    "gui.dialog.mpySb3Prompt": "「%1」对micropython模式进行了更新，项目中的内容如有错误，需要您自己手动修改",
    "gui.dialog.translate.fieldError": "下面的这些错误字段，可能会导致积木块显示出错",
    "gui.dialog.translate.translateError": "文件有错误内容，请选择导出或依旧更新翻译",
    "gui.dialog.translate.startReadFile": "开始读取文件, 请耐心等待...",
    "gui.dialog.translate.fileFormatError": "没有相关的翻译文件内容，文件内容格式不匹配",
    "gui.dialog.connecting.requestCloseFW": "检测到本机的防火墙为打开状态，这会导致Tello无法正常连接，点击确定可关闭防火墙",
    "gui.dialog.cache.crash": "检测到软件异常关闭，可以通过缓存文件恢复项目。打开路径\"项目\">\"缓存列表\"",
    "gui.dialog.python.detectPip": "pip环境初始化中，请稍候",
    "gui.dialog.python.detectPipSucccess": "pip环境初始化完成",
    "gui.dialog.python.detectPipError": "pip初始化失败，库管理功能不可用。请检查网络连接，你也可以在命令行中运行 \"[PYTHON]\" -m pip install --upgrade pip ，成功后再重新使用该功能。",
    "gui.dialog.python.installPipreqsError": "[NAME] 安装失败，用户库将无法正常导出自定义库的依赖列表，请手动安装。",
    "gui.dialog.screenshoting": "正在截图...",
    "gui.dialog.saveScreenshot": "保存截图",
    "gui.dialog.saveScreenshotSuccess": "保存截图成功",
    "gui.dialog.serialDriverInstalling": "串口驱动安装中...",
    "gui.dialog.resetDeviceSuccess": "恢复设备成功",
    "gui.dialog.resetDeviceFailed": "恢复设备失败",
    "gui.dialog.exportSuccess": "导出成功",
    "gui.dialog.exportFailed": "导出失败",
    "gui.dialog.exportCostume": "导出角色",
    "gui.dialog.loadProjectError": "加载项目出错",
    "gui.dialog.readFileError": "读取文件出错",
    "gui.dialog.projectIsDeleted": "项目已经被删除",
    "gui.dialog.projectIsNotExist": "打开项目失败，文件不存在",
    "gui.dialog.checkNetwork": "当前网络不通，请检查电脑是否能上网",
    "gui.dialog.checkIotNetworkAndParameter": "物联网连接失败，请检查参数是否填写正确",
    "gui.dialog.knownError": "已知问题：",
    "gui.dialog.unknownError": "未知问题：",
    "gui.dialog.cancelInstall": "取消安装",
    "gui.dialog.cannotFindDriver": "没有找到驱动文件，请检查路径:",
    "gui.dialog.programError": "程序出错",
    "gui.dialog.pleaseHandleInstall": "请手动安装工具：",
    "gui.dialog.installSerialDriverError": "安装串口驱动出错",
    "gui.dialog.installSerialDriverSuccess": "串口驱动安装完成",
    "gui.dialog.hadInstall": "已安装",
    "gui.dialog.cache.sameAsCacheDeleteBlock": "缓存界面有相同的用户库，将会删除相关的积木块，是否继续",
    "gui.dialog.cache.saveAs": "另存为",
    "gui.dialog.cache.exportExtError": "导出扩展出错：",
    "gui.dialog.cache.exportExtSuccess": "导出扩展成功",
    "gui.dialog.thirdExt.lostVersionKeyWithThird": "缺少version字段，请检查version字段，应该与id字段同级",
    "gui.dialog.thirdExt.notFoundRelatedFile": "没有找到相关的文件",
    "gui.dialog.thirdExt.requestFileError": "请求文件出错",
    "gui.dialog.thirdExt.unzipLibrariesFailed": "解压libraries.zip失败",
    "gui.dialog.thirdExt.pipreqsLostAndFillConfig": "pipreqs 库未正确安装，自动导出依赖包清单出错。为方便用户使用，请手动在config.json中配置依赖库信息",
    "gui.dialog.thirdExt.exportExtToFolderSuccess": "成功导出扩展到目录",
    "gui.dialog.thirdExt.configJsonKeyLost": "'config.json中的[KEY]字段不能为空！'",
    "gui.dialog.thirdExt.configJsonKeyLost_ID": "config.json中的id是该模块的标识，不能为空！",
    "gui.dialog.thirdExt.configJsonKeyLost_platform": "platform定义支持的平台，至少支持一个平台（win,mac,web）！目前只支持win（windowns）平台",
    "gui.dialog.thirdExt.configJsonKeyLost_asset": "config.json中的asset定义支持的语言模式，不能为空！",
    "gui.dialog.thirdExt.configJsonError_asset": "config.json中的asset内容定义错误，至少支持一个语言模式",
    "gui.dialog.thirdExt.configJsonLost_version": "缺少version字段，请检查version字段，应该与id字段同级",
    "gui.dialog.thirdExt.configJsonLost_asset_mode": "config.json中的asset语言模式[MODE]的字段main不能为空！",
    "gui.dialog.thirdExt.configJsonError_mode_board": "语言模式[MODE]的字段board定义支持的主板情况，至少支持一个主板",
    "gui.dialog.stage.microphoneLost": "没有听到任何声音，请检查麦克风是否插好哦",
    "gui.dialog.ml5.closeMl5Modal": "关闭窗口会丢失训练中的数据，请及时保存，是否继续关闭",
    "gui.dialog.ml5.restartMl5Train": "该操作会删除所有分类，确认继续吗?",
    "gui.dialog.ml5.ml5DefaultLabel": "分类1",
    "gui.dialog.account.getCloudListError": "获取文件列表出错，是否重新加载",
    "gui.dialog.account.requestCloudList": "重新加载",
    "gui.dialog.python.getPIPListForUpdateError": "获取PIP更新数据超时",
    "gui.dialog.python.pipHasNoUpdate": "无可用更新",
    "gui.dialog.python.pipUpdateSucess": "成功升级[SUCCESS]个，[FAILED]个失败，分别为[INFO]",
    "gui.dialog.python.pipUpdateAllSucess": "升级成功，共[SUCCESS]个",
    "gui.dialog.python.pipUpdateError": "升级[NAME]失败，详情可在PIP模式页面查看    ",
    "gui.dialog.python.pipInstallError": "安装 [NAME] [VERSION]失败，详情可在PIP模式页面查看",
    "gui.dialog.python.pipUninstallSucess": "成功卸载[SUCCESS]个，[FAILED]个失败，分别为[INFO]",
    "gui.dialog.python.pipUninstallAllSucess": "卸载成功，共[COUNT]个",
    "gui.dialog.python.pipUninstallError": "卸载[NAME]失败，详情可在PIP模式页面查看",
    "gui.dialog.python.sourceUnknow": "未知源",
    "gui.dialog.python.sourceTsinghuaUniversity": "清华大学源",
    "gui.dialog.python.sourceDouban": "豆瓣源",
    "gui.dialog.python.sourceAli": "阿里源",
    "gui.dialog.python.sourceNeteasy": "网易源",
    "gui.dialog.python.sourceZGKJDX": "中国科技大学源",
    "gui.dialog.python.sourceOfficial": "Python官方源(国外)",
    "gui.dialog.python.rebootSoftForVsEditor": "代码编辑器相关功能的翻译需重启后生效",
    "gui.dialog.procedure.procedureNameIsRepeated": "函数「[NAME]」已定义，请重新命名",
    "gui.dialog.procedure.procedureNameIsEmpty": "函数名称不能为空",
    "gui.dialog.project.saveProjectCheck": "二次校验",
    "gui.dialog.project.trySaveProjectAgain": "抱歉，保存的文件解析出错了，请尝试重新保存",
    "gui.dialog.software.secondInstanceTips": "当前软件设置为不允许多开，如需修改，请在 [设置-系统设置-软件多开] 更新设置",
    "gui.dialog.project.saveFailed": "保存出错",
    "gui.dialog.pythonRemote.saveMPProjectToSb3": "当前选择格式为.sb3，仅保存实时模式下的部分基础积木，是否继续保存?",
    "gui.dialog.thirdExt.downloadNow": "立即下载",
    "gui.dialog.thirdExt.downloadingLib": "正在下载",
    "gui.dialog.thirdExt.downloadFailed": "下载失败",
    "gui.dialog.thirdExt.detectThirdExtLibrariesInfo": "检测到该用户库需要[NEED]个依赖库，[INSTALLED]个已存在，是否立即下载其余的[REST]个？",
    "gui.dialog.thirdExt.downloadFinishFailed": "下载[LENGTH]个库失败，为[INFO]，具体信息请至库管理查看",
    "gui.dialog.thirdExt.downloadFinishSuccess": "[LENGTH]个库下载成功, 具体信息请至库管理查看",
    "gui.dialog.pythonRemote.detectingRemoteLibraries": "检测[NAME]上的依赖库版本中，请稍候...",
    "gui.dialog.pythonRemote.detectRemoteLibrariesFinish": "检测完成，全部为新版本",
    "gui.dialog.pythonRemote.detectRemoteLibrariesInfo": "检测到<b>[NAME]</b>上有[LENGTH]个库缺失或版本过低，可能影响图形化功能的使用，是否通过Mind+离线更新？",
    "gui.dialog.pythonRemote.detectRemotePipError": "远程系统中PIP不可用，请确认Python环境存在后再使用库管理功能",
    "gui.dialog.thirdExt.supportedModesAndMotherboards": "用户库支持的模式和主板: ",
    "gui.dialog.thirdExt.offlineArduinoC": "上传模式 arduino C",
    "gui.dialog.thirdExt.offlineMicroPython": "上传模式 microPython",
    "gui.dialog.thirdExt.online": "实时模式",
    "gui.dialog.thirdExt.python": "Python模式",
    "gui.dialog.thirdExt.currentModeAndMotherboard": "Mind+ 当前模式和主板为",
    "gui.dialog.thirdExt.currentMode": "Mind+ 当前模式为",
    "gui.dialog.loadBlockError": "加载失败, 请切换到[%1]再加载该积木程序.",
    "gui.dialog.loadBoardError": "加载失败, 请先加载[%1]主板或套件.",
    "gui.dialog.exportTranslateFile": "导出",
    "gui.dialog.translate.endReadFile": "上传文件没有错误，正在更新语言",
    "BACKDROPS_ARCTIC": "Arctic",
    "BACKDROPS_BASEBALL": "棒球场",
    "BACKDROPS_BASEBALL_2": "棒球场2",
    "BACKDROPS_BASKETBALL": "篮球场",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Beach Malibu",
    "BACKDROPS_BEACH_RIO": "Beach Rio",
    "BACKDROPS_BEDROOM": "睡房",
    "BACKDROPS_BEDROOM_1": "睡房1",
    "BACKDROPS_BEDROOM_2": "睡房2",
    "BACKDROPS_BEDROOM_3": "Bedroom 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Bench With View",
    "BACKDROPS_BLUE_SKY": "蓝天",
    "BACKDROPS_BLUE_SKY_2": "蓝天2",
    "BACKDROPS_BLUE_SKY3": "蓝天3",
    "BACKDROPS_BOARDWALK": "Boardwalk",
    "BACKDROPS_CANYON": "Canyon",
    "BACKDROPS_CASTLE_1": "Castle 1",
    "BACKDROPS_CASTLE_2": "Castle 2",
    "BACKDROPS_CASTLE_3": "Castle 3",
    "BACKDROPS_CASTLE_4": "Castle 4",
    "BACKDROPS_CHALKBOARD": "Chalkboard",
    "BACKDROPS_CIRCLES": "圆",
    "BACKDROPS_CITY_WITH_WATER": "City With Water",
    "BACKDROPS_COLORFUL_CITY": "Colorful City",
    "BACKDROPS_CONCERT": "音乐会",
    "BACKDROPS_DESERT": "Desert",
    "BACKDROPS_FARM": "Farm",
    "BACKDROPS_FIELD_AT_MIT": "Field At Mit",
    "BACKDROPS_FLOWERS": "Flowers",
    "BACKDROPS_FOREST": "Forest",
    "BACKDROPS_GALAXY": "银河",
    "BACKDROPS_GARDEN-ROCK": "Garden-rock",
    "BACKDROPS_GREEK_THEATER": "Greek Theater",
    "BACKDROPS_HALL": "Hall",
    "BACKDROPS_HAY_FIELD": "Hay Field",
    "BACKDROPS_GRAFFITI": "涂鸦",
    "BACKDROPS_HEARTS1": "爱心1",
    "BACKDROPS_HEARTS2": "爱心2",
    "BACKDROPS_HILL": "Hill",
    "BACKDROPS_JUNGLE": "丛林",
    "BACKDROPS_JURASSIC": "侏罗纪",
    "BACKDROPS_LIGHT": "聚光灯",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": "月亮",
    "BACKDROPS_MOUNTAIN": "山脉",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "星云",
    "BACKDROPS_NEON_TUNNEL": "Neon Tunnel",
    "BACKDROPS_NIGHT_CITY": "Night City",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Night City With Street",
    "BACKDROPS_PARTY": "聚会",
    "BACKDROPS_PATHWAY": "Pathway",
    "BACKDROPS_PARTY_ROOM": "聚会房间",
    "BACKDROPS_PLAYGROUND": "Playground",
    "BACKDROPS_PLAYING_FIELD": "Playing Field",
    "BACKDROPS_POOL": "Pool",
    "BACKDROPS_PURPLE": "紫色",
    "BACKDROPS_RAYS": "光线",
    "BACKDROPS_REFRIGERATOR": "冰箱",
    "BACKDROPS_ROOM_1": "Room 1",
    "BACKDROPS_ROOM_2": "Room 2",
    "BACKDROPS_SAVANNA": "Savanna",
    "BACKDROPS_SCHOOL": "School",
    "BACKDROPS_SLOPES": "Slopes",
    "BACKDROPS_SOCCER": "足球场",
    "BACKDROPS_SOCCER_2": "足球场2",
    "BACKDROPS_SPACE": "太空",
    "BACKDROPS_SPACE_2": "太空2",
    "BACKDROPS_SPACE_3": "太空3",
    "BACKDROPS_SPACE_4": "太空4",
    "BACKDROPS_SPOTLIGHT-STAGE": "舞台聚光灯",
    "BACKDROPS_STARS": "群星",
    "BACKDROPS_STRIPES": "条纹",
    "BACKDROPS_THEATER": "剧场",
    "BACKDROPS_THEATER_2": "Theater 2",
    "BACKDROPS_TREE": "Tree",
    "BACKDROPS_UNDERWATER_1": "海底世界1",
    "BACKDROPS_UNDERWATER_2": "海底世界2",
    "BACKDROPS_URBAN": "Urban",
    "BACKDROPS_WALL_1": "Wall 1",
    "BACKDROPS_WALL_2": "Wall 2",
    "BACKDROPS_WATER_AND_ROCKS": "Water And Rocks",
    "BACKDROPS_WETLAND": "Wetland",
    "BACKDROPS_WINTER": "冬日",
    "BACKDROPS_WINTER-LIGHTS": "冬夜",
    "BACKDROPS_WITCH_HOUSE": "女巫小屋",
    "BACKDROPS_WOODS": "树林",
    "BACKDROPS_WOODS_AND_BENCH": "Woods And Bench",
    "BACKDROPS_XY-GRID": "Xy-grid",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "艾比-a",
    "COSTUMES_ABBY-B": "艾比-b",
    "COSTUMES_ABBY-C": "艾比-c",
    "COSTUMES_ABBY-D": "艾比-d",
    "COSTUMES_AMON": "amon",
    "COSTUMES_ANDIE-A": "andie-a",
    "COSTUMES_ANDIE-B": "andie-b",
    "COSTUMES_ANDIE-C": "andie-c",
    "COSTUMES_ANDIE-D": "andie-d",
    "COSTUMES_ANINA_POP_DOWN": "anina pop down",
    "COSTUMES_ANINA_POP_FRONT": "anina pop front",
    "COSTUMES_ANINA_POP_L_ARM": "anina pop L arm",
    "COSTUMES_ANINA_POP_LEFT": "anina pop left",
    "COSTUMES_ANINA_POP_R_ARM": "anina pop R arm",
    "COSTUMES_ANINA_POP_RIGHT": "anina pop right",
    "COSTUMES_ANINA_POP_STAND": "anina pop stand",
    "COSTUMES_ANINA_R_CROSS": "anina R cross",
    "COSTUMES_ANINA_STANCE": "anina stance",
    "COSTUMES_ANINA_TOP_FREEZE": "anina top freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "anina top L step",
    "COSTUMES_ANINA_TOP_R_STEP": "anina top R step",
    "COSTUMES_ANINA_TOP_STAND": "anina top stand",
    "COSTUMES_APPLE": "苹果",
    "COSTUMES_ARROW1-A": "箭头-右",
    "COSTUMES_ARROW1-B": "箭头-左",
    "COSTUMES_ARROW1-C": "箭头-下",
    "COSTUMES_ARROW1-D": "箭头-上",
    "COSTUMES_AVERY_WALKING-A": "艾弗里-行走-a",
    "COSTUMES_AVERY_WALKING-B": "艾弗里-行走-b",
    "COSTUMES_AVERY_WALKING-C": "艾弗里-行走-c",
    "COSTUMES_AVERY_WALKING-D": "艾弗里-行走-d",
    "COSTUMES_AVERY-A": "艾弗里-a",
    "COSTUMES_AVERY-B": "艾弗里-b",
    "COSTUMES_BALL-A": "球-黄",
    "COSTUMES_BALL-B": "球-蓝",
    "COSTUMES_BALL-C": "球-粉",
    "COSTUMES_BALL-D": "球-绿",
    "COSTUMES_BALL-E": "球-紫",
    "COSTUMES_BALLERINA-A": "芭蕾舞女孩-a",
    "COSTUMES_BALLERINA-B": "芭蕾舞女孩-b",
    "COSTUMES_BALLERINA-C": "芭蕾舞女孩-c",
    "COSTUMES_BALLERINA-D": "芭蕾舞女孩-d",
    "COSTUMES_BALLOON1-A": "气球-蓝",
    "COSTUMES_BALLOON1-B": "气球-黄",
    "COSTUMES_BALLOON1-C": "气球-紫",
    "COSTUMES_BANANAS": "香蕉",
    "COSTUMES_BASEBALL": "棒球",
    "COSTUMES_BASKETBALL": "篮球",
    "COSTUMES_BAT-A": "bat-a",
    "COSTUMES_BAT-B": "bat-b",
    "COSTUMES_BAT-C": "bat-c",
    "COSTUMES_BAT-D": "bat-d",
    "COSTUMES_BATTER-A": "batter-a",
    "COSTUMES_BATTER-B": "batter-b",
    "COSTUMES_BATTER-C": "batter-c",
    "COSTUMES_BATTER-D": "batter-d",
    "COSTUMES_BEACHBALL": "沙滩球",
    "COSTUMES_BEAR-A": "熊-a",
    "COSTUMES_BEAR-B": "熊-b",
    "COSTUMES_BEAR-WALK-A": "熊-行走-a",
    "COSTUMES_BEAR-WALK-B": "熊-行走-b",
    "COSTUMES_BEAR-WALK-C": "熊-行走-c",
    "COSTUMES_BEAR-WALK-D": "熊-行走-d",
    "COSTUMES_BEAR-WALK-E": "熊-行走-e",
    "COSTUMES_BEAR-WALK-F": "熊-行走-f",
    "COSTUMES_BEAR-WALK-G": "熊-行走-g",
    "COSTUMES_BEAR-WALK-H": "熊-行走-h",
    "COSTUMES_BEETLE": "甲壳虫",
    "COSTUMES_BELL1": "铃铛-a",
    "COSTUMES_BEN-A": "本-a",
    "COSTUMES_BEN-B": "本-b",
    "COSTUMES_BEN-C": "本-c",
    "COSTUMES_BEN-D": "本-d",
    "COSTUMES_BOWL-A": "碗-a",
    "COSTUMES_BOWTIE": "bowtie",
    "COSTUMES_BREAD": "面包",
    "COSTUMES_BROOM": "扫帚",
    "COSTUMES_BUILDING-A": "建筑-a",
    "COSTUMES_BUILDING-B": "建筑-b",
    "COSTUMES_BUILDING-C": "建筑-c",
    "COSTUMES_BUILDING-D": "建筑-d",
    "COSTUMES_BUILDING-E": "建筑-e",
    "COSTUMES_BUILDING-F": "建筑-f",
    "COSTUMES_BUILDING-G": "建筑-g",
    "COSTUMES_BUILDING-H": "建筑-h",
    "COSTUMES_BUILDING-I": "建筑-i",
    "COSTUMES_BUILDING-J": "建筑-j",
    "COSTUMES_BUTTERFLY1-A": "蝴蝶1-a",
    "COSTUMES_BUTTERFLY1-B": "butterfly1-b",
    "COSTUMES_BUTTERFLY1-C": "butterfly1-c",
    "COSTUMES_BUTTERFLY2-A": "butterfly2-a",
    "COSTUMES_BUTTERFLY2-B": "butterfly2-b",
    "COSTUMES_BUTTON1": "圆形按钮",
    "COSTUMES_BUTTON2-A": "椭圆形按钮-a",
    "COSTUMES_BUTTON2-B": "椭圆形按钮-b",
    "COSTUMES_BUTTON3-A": "方形按钮-a",
    "COSTUMES_BUTTON3-B": "方形按钮-b",
    "COSTUMES_BUTTON4-A": "勾形按钮-a",
    "COSTUMES_BUTTON4-B": "勾形按钮-b",
    "COSTUMES_BUTTON5-A": "叉形按钮-a",
    "COSTUMES_BUTTON5-B": "叉形按钮-b",
    "COSTUMES_CAKE-A": "蛋糕-a",
    "COSTUMES_CAKE-B": "蛋糕-b",
    "COSTUMES_CALVRETT_JUMPING": "calvrett jumping",
    "COSTUMES_CALVRETT_THINKING": "calvrett thinking",
    "COSTUMES_CASEY-A": "凯西-a",
    "COSTUMES_CASEY-B": "凯西-b",
    "COSTUMES_CASEY-C": "凯西-c",
    "COSTUMES_CASEY-D": "凯西-d",
    "COSTUMES_CASSY-A": "cassy-a",
    "COSTUMES_CASSY-B": "cassy-b",
    "COSTUMES_CASSY-C": "cassy-c",
    "COSTUMES_CASSY-D": "cassy-d",
    "COSTUMES_CAT_2": "cat 2",
    "COSTUMES_CATCHER-A": "catcher-a",
    "COSTUMES_CATCHER-B": "catcher-b",
    "COSTUMES_CATCHER-C": "catcher-c",
    "COSTUMES_CATCHER-D": "catcher-d",
    "COSTUMES_CENTAUR-A": "半人马-a",
    "COSTUMES_CENTAUR-B": "半人马-b",
    "COSTUMES_CENTAUR-C": "半人马-c",
    "COSTUMES_CENTAUR-D": "半人马-d",
    "COSTUMES_CHAMP99-A": "champ99-a",
    "COSTUMES_CHAMP99-B": "champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "cheesy puffs",
    "COSTUMES_CHICK-A": "chick-a",
    "COSTUMES_CHICK-B": "chick-b",
    "COSTUMES_CHICK-C": "chick-c",
    "COSTUMES_CITY_BUS-A": "City Bus-a",
    "COSTUMES_CITY_BUS-B": "City Bus-b",
    "COSTUMES_CLOUD": "白云",
    "COSTUMES_CLOUD-A": "白云1-a",
    "COSTUMES_CLOUD-B": "白云1-b",
    "COSTUMES_CLOUD-C": "白云1-c",
    "COSTUMES_CLOUD-D": "白云1-d",
    "COSTUMES_CONVERTIBLE": "convertible",
    "COSTUMES_CONVERTIBLE_3": "convertible 3",
    "COSTUMES_CRAB-A": "螃蟹-a",
    "COSTUMES_CRAB-B": "螃蟹-b",
    "COSTUMES_CRYSTAL-A": "钻石-a",
    "COSTUMES_CRYSTAL-B": "钻石-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "达尼-a",
    "COSTUMES_DANI-B": "达尼-b",
    "COSTUMES_DANI-C": "达尼-c",
    "COSTUMES_DEE-A": "迪伊-a",
    "COSTUMES_DEE-B": "迪伊-b",
    "COSTUMES_DEE-C": "迪伊-c",
    "COSTUMES_DEE-D": "迪伊-d",
    "COSTUMES_DEE-E": "迪伊-e",
    "COSTUMES_DEVIN-A": "德温-a",
    "COSTUMES_DEVIN-B": "德温-b",
    "COSTUMES_DEVIN-C": "德温-c",
    "COSTUMES_DEVIN-D": "德温-d",
    "COSTUMES_DINOSAUR1-A": "恐龙1-a",
    "COSTUMES_DINOSAUR1-B": "恐龙1-b",
    "COSTUMES_DINOSAUR1-C": "恐龙1-c",
    "COSTUMES_DINOSAUR1-D": "恐龙1-d",
    "COSTUMES_DINOSAUR2-A": "恐龙2-a",
    "COSTUMES_DINOSAUR2-B": "恐龙2-b",
    "COSTUMES_DINOSAUR2-C": "恐龙2-c",
    "COSTUMES_DINOSAUR2-D": "恐龙2-d",
    "COSTUMES_DINOSAUR3-A": "恐龙3-a",
    "COSTUMES_DINOSAUR3-B": "恐龙3-b",
    "COSTUMES_DINOSAUR3-C": "恐龙3-c",
    "COSTUMES_DINOSAUR3-D": "恐龙3-d",
    "COSTUMES_DINOSAUR3-E": "恐龙3-e",
    "COSTUMES_DINOSAUR4-A": "恐龙4-a",
    "COSTUMES_DINOSAUR4-B": "恐龙4-b",
    "COSTUMES_DINOSAUR4-C": "恐龙4-c",
    "COSTUMES_DINOSAUR4-D": "恐龙4-d",
    "COSTUMES_DIVER1": "潜水员1",
    "COSTUMES_DIVER2": "潜水员2",
    "COSTUMES_DM_FREEZE": "dm freeze",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "dm stance",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": "小狗1-a",
    "COSTUMES_DOG1-B": "小狗1-b",
    "COSTUMES_DOG2-A": "小狗2-a",
    "COSTUMES_DOG2-B": "小狗2-b",
    "COSTUMES_DOG2-C": "小狗2-c",
    "COSTUMES_DONUT": "圆环",
    "COSTUMES_DORIAN-A": "多里安人-a",
    "COSTUMES_DORIAN-B": "多里安人-b",
    "COSTUMES_DORIAN-C": "多里安人-c",
    "COSTUMES_DORIAN-D": "多里安人-d",
    "COSTUMES_DOT-A": "点点-a",
    "COSTUMES_DOT-B": "点点-b",
    "COSTUMES_DOT-C": "点点-c",
    "COSTUMES_DOT-D": "点点-d",
    "COSTUMES_DOVE-A": "鸽子-a",
    "COSTUMES_DOVE-B": "鸽子-b",
    "COSTUMES_DRAGON-A": "龙-a",
    "COSTUMES_DRAGON-B": "龙-b",
    "COSTUMES_DRAGON-C": "龙-c",
    "COSTUMES_DRAGON1-A": "龙-a",
    "COSTUMES_DRAGON1-B": "龙-b",
    "COSTUMES_DRAGONFLY-A": "Dragonfly-a",
    "COSTUMES_DRAGONFLY-B": "Dragonfly-b",
    "COSTUMES_DRESS-A": "dress-a",
    "COSTUMES_DRESS-B": "dress-b",
    "COSTUMES_DRESS-C": "dress-c",
    "COSTUMES_DRUM-A": "鼓-a",
    "COSTUMES_DRUM-B": "鼓-b",
    "COSTUMES_DRUM-CYMBAL-A": "镲-a",
    "COSTUMES_DRUM-CYMBAL-B": "镲-b",
    "COSTUMES_DRUM-HIGHHAT-A": "踩镲-a",
    "COSTUMES_DRUM-HIGHHAT-B": "踩镲-b",
    "COSTUMES_DRUM-KIT": "爵士鼓",
    "COSTUMES_DRUM-KIT-B": "爵士鼓-b",
    "COSTUMES_DRUM-SNARE-A": "小鼓-a",
    "COSTUMES_DRUM-SNARE-B": "小鼓-b",
    "COSTUMES_DRUMS_CONGA-A": "Drums Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Drums Conga-b",
    "COSTUMES_DUCK": "鸭子",
    "COSTUMES_EARTH": "地球",
    "COSTUMES_EASEL-A": "Easel-a",
    "COSTUMES_EASEL-B": "Easel-b",
    "COSTUMES_EASEL-C": "Easel-c",
    "COSTUMES_EGG-A": "蛋-a",
    "COSTUMES_EGG-B": "蛋-b",
    "COSTUMES_EGG-C": "蛋-c",
    "COSTUMES_EGG-D": "蛋-d",
    "COSTUMES_EGG-E": "蛋-e",
    "COSTUMES_EGG-F": "egg-f",
    "COSTUMES_ELEPHANT-A": "elephant-a",
    "COSTUMES_ELEPHANT-B": "elephant-b",
    "COSTUMES_ELF-A": "小精灵-a",
    "COSTUMES_ELF-B": "小精灵-b",
    "COSTUMES_ELF-C": "小精灵-c",
    "COSTUMES_ELF-D": "小精灵-d",
    "COSTUMES_ELF-E": "小精灵-e",
    "COSTUMES_FAIRY-A": "精灵-a",
    "COSTUMES_FAIRY-B": "精灵-b",
    "COSTUMES_FAIRY-C": "精灵-c",
    "COSTUMES_FAIRY-D": "精灵-d",
    "COSTUMES_FAIRY-E": "精灵-e",
    "COSTUMES_FISH-A": "鱼-a",
    "COSTUMES_FISH-B": "鱼-b",
    "COSTUMES_FISH-C": "鱼-c",
    "COSTUMES_FISH-D": "鱼-d",
    "COSTUMES_FISHBOWL-A": "Fishbowl-a",
    "COSTUMES_FISHBOWL-B": "Fishbowl-b",
    "COSTUMES_FOOD_TRUCK-A": "Food Truck-a",
    "COSTUMES_FOOD_TRUCK-B": "Food Truck-b",
    "COSTUMES_FOOD_TRUCK-C": "Food Truck-c",
    "COSTUMES_FOOTBALL_RUNNING": "football running",
    "COSTUMES_FOOTBALL_STANDING": "football standing",
    "COSTUMES_FORTUNE_COOKIE": "fortune cookie",
    "COSTUMES_FOX-A": "狐狸-a",
    "COSTUMES_FOX-B": "狐狸-b",
    "COSTUMES_FOX-C": "狐狸-c",
    "COSTUMES_FRANK-A": "frank-a",
    "COSTUMES_FRANK-B": "frank-b",
    "COSTUMES_FRANK-C": "frank-c",
    "COSTUMES_FRANK-D": "frank-d",
    "COSTUMES_FROG": "青蛙",
    "COSTUMES_FROG_2-A": "Frog 2-a",
    "COSTUMES_FROG_2-B": "Frog 2-b",
    "COSTUMES_FROG_2-C": "Frog 2-c",
    "COSTUMES_FRUIT_PLATTER": "fruit platter",
    "COSTUMES_FRUITSALAD": "水果沙拉",
    "COSTUMES_GHOST-A": "ghost-a",
    "COSTUMES_GHOST-B": "ghost-b",
    "COSTUMES_GHOST-C": "ghost-c",
    "COSTUMES_GHOST-D": "ghost-d",
    "COSTUMES_GIFT-A": "礼物-a",
    "COSTUMES_GIFT-B": "礼物-b",
    "COSTUMES_GIRAFFE-A": "giraffe-a",
    "COSTUMES_GIRAFFE-B": "giraffe-b",
    "COSTUMES_GIRAFFE-C": "giraffe-c",
    "COSTUMES_GLASS_WATER-A": "水杯-a",
    "COSTUMES_GLASS_WATER-B": "水杯-b",
    "COSTUMES_GLASSES-A": "glasses-a",
    "COSTUMES_GLASSES-B": "glasses-b",
    "COSTUMES_GLASSES-C": "glasses-c",
    "COSTUMES_GLASSES-E": "glasses-e",
    "COSTUMES_GOALIE-A": "守门员-a",
    "COSTUMES_GOALIE-B": "守门员-b",
    "COSTUMES_GOALIE-C": "守门员-c",
    "COSTUMES_GOALIE-D": "守门员-d",
    "COSTUMES_GOALIE-E": "守门员-e",
    "COSTUMES_GOBLIN-A": "哥布林-a",
    "COSTUMES_GOBLIN-B": "哥布林-b",
    "COSTUMES_GOBLIN-C": "哥布林-c",
    "COSTUMES_GOBLIN-D": "哥布林-d",
    "COSTUMES_GRASSHOPPER-A": "Grasshopper-a",
    "COSTUMES_GRASSHOPPER-B": "Grasshopper-b",
    "COSTUMES_GRASSHOPPER-C": "Grasshopper-c",
    "COSTUMES_GRASSHOPPER-D": "Grasshopper-d",
    "COSTUMES_GRASSHOPPER-E": "Grasshopper-e",
    "COSTUMES_GRASSHOPPER-F": "Grasshopper-f",
    "COSTUMES_GREEN_FLAG": "绿旗",
    "COSTUMES_GRIFFIN-A": "格里芬-a",
    "COSTUMES_GRIFFIN-B": "Griffin-b",
    "COSTUMES_GRIFFIN-C": "Griffin-c",
    "COSTUMES_GRIFFIN-D": "Griffin-d",
    "COSTUMES_GUITAR-A": "吉他-a",
    "COSTUMES_GUITAR-B": "吉他-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "电吉他1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "电吉他1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "电吉他-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "电吉他-b",
    "COSTUMES_HANNAH-A": "hannah-a",
    "COSTUMES_HANNAH-B": "hannah-b",
    "COSTUMES_HANNAH-C": "hannah-c",
    "COSTUMES_HARE-A": "hare-a",
    "COSTUMES_HARE-B": "hare-b",
    "COSTUMES_HARE-C": "hare-c",
    "COSTUMES_HARPER-A": "harper-a",
    "COSTUMES_HARPER-B": "harper-b",
    "COSTUMES_HARPER-C": "harper-c",
    "COSTUMES_HAT-A": "hat-a",
    "COSTUMES_HAT-B": "hat-b",
    "COSTUMES_HAT-C": "hat-c",
    "COSTUMES_HAT-D": "hat-d",
    "COSTUMES_HATCHLING-A": "hatchling-a",
    "COSTUMES_HATCHLING-B": "hatchling-b",
    "COSTUMES_HATCHLING-C": "hatchling-c",
    "COSTUMES_HEART_CODE": "糖心-b",
    "COSTUMES_HEART_FACE": "心表情",
    "COSTUMES_HEART_LOVE": "heart love",
    "COSTUMES_HEART_PURPLE": "爱心-紫",
    "COSTUMES_HEART_RED": "爱心-红",
    "COSTUMES_HEART_SMILE": "糖心-d",
    "COSTUMES_HEART_SWEET": "糖心-c",
    "COSTUMES_HEDGEHOG-A": "刺猬-a",
    "COSTUMES_HEDGEHOG-B": "刺猬-b",
    "COSTUMES_HEDGEHOG-C": "刺猬-c",
    "COSTUMES_HEDGEHOG-D": "刺猬-d",
    "COSTUMES_HEDGEHOG-E": "刺猬-e",
    "COSTUMES_HEN-A": "hen-a",
    "COSTUMES_HEN-B": "hen-b",
    "COSTUMES_HEN-C": "hen-c",
    "COSTUMES_HEN-D": "hen-d",
    "COSTUMES_HIPPO1-A": "河马-a",
    "COSTUMES_HIPPO1-B": "河马-b",
    "COSTUMES_HOME_BUTTON": "房型按钮",
    "COSTUMES_HORSE-A": "horse-a",
    "COSTUMES_HORSE-B": "horse-b",
    "COSTUMES_JAIME_WALKING-A": "jaime walking-a",
    "COSTUMES_JAIME_WALKING-B": "jaime walking-b",
    "COSTUMES_JAIME_WALKING-C": "jaime walking-c",
    "COSTUMES_JAIME_WALKING-D": "jaime walking-d",
    "COSTUMES_JAIME_WALKING-E": "jaime walking-e",
    "COSTUMES_JAIME-A": "jaime-a",
    "COSTUMES_JAIME-B": "jaime-b",
    "COSTUMES_JAMAL-A": "jamal-a",
    "COSTUMES_JAMAL-B": "jamal-b",
    "COSTUMES_JAMAL-C": "jamal-c",
    "COSTUMES_JAMAL-D": "jamal-d",
    "COSTUMES_JAR-A": "罐子-a",
    "COSTUMES_JAR-B": "罐子-b",
    "COSTUMES_JELLYFISH-A": "水母-a",
    "COSTUMES_JELLYFISH-B": "水母-b",
    "COSTUMES_JELLYFISH-C": "水母-c",
    "COSTUMES_JELLYFISH-D": "水母-d",
    "COSTUMES_JO_POP_DOWN": "jo pop down",
    "COSTUMES_JO_POP_FRONT": "jo pop front",
    "COSTUMES_JO_POP_L_ARM": "jo pop L arm",
    "COSTUMES_JO_POP_LEFT": "jo pop left",
    "COSTUMES_JO_POP_R_ARM": "jo pop R arm",
    "COSTUMES_JO_POP_RIGHT": "jo pop right",
    "COSTUMES_JO_POP_STAND": "jo pop stand",
    "COSTUMES_JO_STANCE": "jo stance",
    "COSTUMES_JO_TOP_L_CROSS": "jo top L cross",
    "COSTUMES_JO_TOP_L_LEG": "jo top L leg",
    "COSTUMES_JO_TOP_R_CROSS": "jo top R cross",
    "COSTUMES_JO_TOP_R_LEG": "jo top R leg",
    "COSTUMES_JO_TOP_STAND": "jo top stand",
    "COSTUMES_JORDYN-A": "乔丁-a",
    "COSTUMES_JORDYN-B": "乔丁-b",
    "COSTUMES_JORDYN-C": "乔丁-c",
    "COSTUMES_JORDYN-D": "乔丁-d",
    "COSTUMES_KAI-A": "kai-a",
    "COSTUMES_KAI-B": "kai-b",
    "COSTUMES_KEY": "钥匙",
    "COSTUMES_KEYBOARD-A": "键盘-a",
    "COSTUMES_KEYBOARD-B": "键盘-b",
    "COSTUMES_KIRAN-A": "基兰-a",
    "COSTUMES_KIRAN-B": "基兰-b",
    "COSTUMES_KIRAN-C": "基兰-c",
    "COSTUMES_KIRAN-D": "基兰-d",
    "COSTUMES_KIRAN-E": "基兰-e",
    "COSTUMES_KIRAN-F": "基兰-f",
    "COSTUMES_KNIGHT": "骑士",
    "COSTUMES_LADYBUG2": "七星瓢虫1",
    "COSTUMES_LADYBUG2-A": "七星瓢虫2-a",
    "COSTUMES_LADYBUG2-B": "七星瓢虫2-b",
    "COSTUMES_LAPTOP": "笔记本电脑",
    "COSTUMES_LB_POP_DOWN": "lb pop down",
    "COSTUMES_LB_POP_FRONT": "lb pop front",
    "COSTUMES_LB_POP_L_ARM": "lb pop L arm",
    "COSTUMES_LB_POP_LEFT": "lb pop left",
    "COSTUMES_LB_POP_R_ARM": "lb pop R arm",
    "COSTUMES_LB_POP_RIGHT": "lb pop right",
    "COSTUMES_LB_POP_STAND": "lb pop stand",
    "COSTUMES_LB_STANCE": "lb stance",
    "COSTUMES_LB_TOP_L_CROSS": "lb top L cross",
    "COSTUMES_LB_TOP_L_LEG": "lb top L leg",
    "COSTUMES_LB_TOP_R_CROSS": "lb top R cross",
    "COSTUMES_LB_TOP_R_LEG": "lb top R leg",
    "COSTUMES_LB_TOP_STAND": "lb top stand",
    "COSTUMES_LIGHTNING": "闪电",
    "COSTUMES_LINE": "线",
    "COSTUMES_LION-A": "狮子a",
    "COSTUMES_LION-B": "狮子-b",
    "COSTUMES_LION-C": "lion-c",
    "COSTUMES_LLAMA": "无峰驼",
    "COSTUMES_LLAMA-B": "无峰驼-a",
    "COSTUMES_LLAMA-C": "无峰驼-b",
    "COSTUMES_MAGICWAND": "魔棒",
    "COSTUMES_MAX-A": "麦克斯-a",
    "COSTUMES_MAX-B": "麦克斯-b",
    "COSTUMES_MAX-C": "麦克斯-c",
    "COSTUMES_MAX-D": "麦克斯-d",
    "COSTUMES_MERMAID-A": "美人鱼-a",
    "COSTUMES_MERMAID-B": "美人鱼-b",
    "COSTUMES_MERMAID-C": "mermaid-c",
    "COSTUMES_MERMAID-D": "mermaid-d",
    "COSTUMES_MICROPHONE-A": "麦克风-a",
    "COSTUMES_MICROPHONE-B": "麦克风-b",
    "COSTUMES_MILK-A": "牛奶-a",
    "COSTUMES_MILK-B": "牛奶-b",
    "COSTUMES_MILK-C": "牛奶-c",
    "COSTUMES_MILK-D": "牛奶-d",
    "COSTUMES_MILK-E": "牛奶-e",
    "COSTUMES_MONET-A": "莫奈-a",
    "COSTUMES_MONET-B": "莫奈-b",
    "COSTUMES_MONET-C": "莫奈-c",
    "COSTUMES_MONET-D": "莫奈-d",
    "COSTUMES_MONET-E": "莫奈-e",
    "COSTUMES_MONKEY-A": "猴子-a",
    "COSTUMES_MONKEY-B": "猴子-b",
    "COSTUMES_MONKEY-C": "猴子-c",
    "COSTUMES_MOTORCYCLE-A": "Motorcycle-a",
    "COSTUMES_MOTORCYCLE-B": "Motorcycle-b",
    "COSTUMES_MOTORCYCLE-C": "Motorcycle-c",
    "COSTUMES_MOTORCYCLE-D": "Motorcycle-d",
    "COSTUMES_MOUSE1-A": "老鼠-a",
    "COSTUMES_MOUSE1-B": "老鼠-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "小马驹",
    "COSTUMES_OCTOPUS-A": "章鱼-a",
    "COSTUMES_OCTOPUS-B": "章鱼-b",
    "COSTUMES_OCTOPUS-C": "章鱼-c",
    "COSTUMES_OCTOPUS-D": "章鱼-d",
    "COSTUMES_OCTOPUS-E": "章鱼-e",
    "COSTUMES_ORANGE": "橘子",
    "COSTUMES_ORANGE2-A": "橘子2-a",
    "COSTUMES_ORANGE2-B": "橘子2-b",
    "COSTUMES_OUTFIELDER-A": "outfielder-a",
    "COSTUMES_OUTFIELDER-B": "outfielder-b",
    "COSTUMES_OUTFIELDER-C": "outfielder-c",
    "COSTUMES_OUTFIELDER-D": "outfielder-d",
    "COSTUMES_OWL-A": "猫头鹰-a",
    "COSTUMES_OWL-B": "猫头鹰-b",
    "COSTUMES_OWL-C": "猫头鹰-c",
    "COSTUMES_PADDLE": "桨-a",
    "COSTUMES_PANTHER-A": "美洲豹-a",
    "COSTUMES_PANTHER-B": "美洲豹-b",
    "COSTUMES_PANTHER-C": "美洲豹-c",
    "COSTUMES_PANTS-A": "pants-a",
    "COSTUMES_PANTS-B": "pants-b",
    "COSTUMES_PARROT-A": "鹦鹉-a",
    "COSTUMES_PARROT-B": "鹦鹉-b",
    "COSTUMES_PARTY_HAT-A": "Party Hat-a",
    "COSTUMES_PARTY_HAT-B": "Party Hat-b",
    "COSTUMES_PARTY_HAT-E": "Party Hat-e",
    "COSTUMES_PENCIL-A": "铅笔-a",
    "COSTUMES_PENCIL-B": "铅笔-b",
    "COSTUMES_PENGUIN-A": "penguin-a",
    "COSTUMES_PENGUIN-B": "penguin-b",
    "COSTUMES_PENGUIN-C": "penguin-c",
    "COSTUMES_PENGUIN2-A": "企鹅2-a",
    "COSTUMES_PENGUIN2-B": "企鹅2-b",
    "COSTUMES_PENGUIN2-C": "企鹅2-c",
    "COSTUMES_PENGUIN2-D": "penguin2-d",
    "COSTUMES_PITCHER-A": "pitcher-a",
    "COSTUMES_PITCHER-B": "pitcher-b",
    "COSTUMES_PITCHER-C": "pitcher-c",
    "COSTUMES_PITCHER-D": "pitcher-d",
    "COSTUMES_PLANET2": "行星",
    "COSTUMES_POLAR_BEAR-A": "polar bear-a",
    "COSTUMES_POLAR_BEAR-B": "polar bear-b",
    "COSTUMES_POLAR_BEAR-C": "polar bear-c",
    "COSTUMES_POTION-A": "药剂-a",
    "COSTUMES_POTION-B": "药剂-b",
    "COSTUMES_POTION-C": "药剂-c",
    "COSTUMES_PRINCE": "王子",
    "COSTUMES_PRINCESS-A": "公主-a",
    "COSTUMES_PRINCESS-B": "公主-b",
    "COSTUMES_PRINCESS-C": "公主-c",
    "COSTUMES_PRINCESS-D": "公主-d",
    "COSTUMES_PRINCESS-E": "公主-e",
    "COSTUMES_PUFFERFISH-A": "河豚-a",
    "COSTUMES_PUFFERFISH-B": "河豚-b",
    "COSTUMES_PUFFERFISH-C": "河豚-c",
    "COSTUMES_PUFFERFISH-D": "河豚-d",
    "COSTUMES_PUPPY_BACK": "puppy back",
    "COSTUMES_PUPPY_RIGHT": "puppy right",
    "COSTUMES_PUPPY_SIDE": "puppy side",
    "COSTUMES_PUPPY_SIT": "puppy sit",
    "COSTUMES_RABBIT-A": "兔子-a",
    "COSTUMES_RABBIT-B": "兔子-b",
    "COSTUMES_RABBIT-C": "兔子-c",
    "COSTUMES_RABBIT-D": "兔子-d",
    "COSTUMES_RABBIT-E": "兔子-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": "彩虹",
    "COSTUMES_REFEREE-A": "裁判员-a",
    "COSTUMES_REFEREE-B": "裁判员-b",
    "COSTUMES_REFEREE-C": "裁判员-c",
    "COSTUMES_REFEREE-D": "裁判员-d",
    "COSTUMES_REINDEER": "驯鹿",
    "COSTUMES_RETRO_ROBOT_A": "Retro Robot a",
    "COSTUMES_RETRO_ROBOT_B": "Retro Robot b",
    "COSTUMES_RETRO_ROBOT_C": "Retro Robot c",
    "COSTUMES_RIPLEY-A": "里普利-a",
    "COSTUMES_RIPLEY-B": "里普利-b",
    "COSTUMES_RIPLEY-C": "里普利-c",
    "COSTUMES_RIPLEY-D": "里普利-d",
    "COSTUMES_RIPLEY-E": "里普利-e",
    "COSTUMES_RIPLEY-F": "里普利-f",
    "COSTUMES_ROBOT-A": "机器人-a",
    "COSTUMES_ROBOT-B": "机器人-b",
    "COSTUMES_ROBOT-C": "机器人-c",
    "COSTUMES_ROBOT-D": "机器人-d",
    "COSTUMES_ROCKETSHIP-A": "rocketship-a",
    "COSTUMES_ROCKETSHIP-B": "rocketship-b",
    "COSTUMES_ROCKETSHIP-C": "rocketship-c",
    "COSTUMES_ROCKETSHIP-D": "rocketship-d",
    "COSTUMES_ROCKETSHIP-E": "rocketship-e",
    "COSTUMES_ROCKS": "石头",
    "COSTUMES_ROOSTER-A": "rooster-a",
    "COSTUMES_ROOSTER-B": "rooster-b",
    "COSTUMES_ROOSTER-C": "rooster-c",
    "COSTUMES_RUBY-A": "ruby-a",
    "COSTUMES_RUBY-B": "ruby-b",
    "COSTUMES_SAILBOAT": "sailboat",
    "COSTUMES_SAM": "sam",
    "COSTUMES_SAXOPHONE-A": "萨克斯-a",
    "COSTUMES_SAXOPHONE-B": "萨克斯-b",
    "COSTUMES_SCARF-A": "Scarf-a",
    "COSTUMES_SCARF-B": "Scarf-b",
    "COSTUMES_SCARF-C": "Scarf-c",
    "COSTUMES_SHARK-A": "鲨鱼1-a",
    "COSTUMES_SHARK-B": "鲨鱼1-b",
    "COSTUMES_SHARK2-A": "shark2-a",
    "COSTUMES_SHARK2-B": "shark2-b",
    "COSTUMES_SHARK2-C": "shark2-c",
    "COSTUMES_SHIRT-A": "shirt-a",
    "COSTUMES_SHOES-A": "shoes-a",
    "COSTUMES_SHOES-B": "shoes-b",
    "COSTUMES_SHOES-C": "shoes-c",
    "COSTUMES_SHOES-D": "shoes-d",
    "COSTUMES_SHORTS-A": "shorts-a",
    "COSTUMES_SHORTS-B": "shorts-b",
    "COSTUMES_SHORTS-C": "shorts-c",
    "COSTUMES_SINGER1": "歌手1",
    "COSTUMES_SKELETON-A": "skeleton-a",
    "COSTUMES_SKELETON-B": "skeleton-b",
    "COSTUMES_SKELETON-D": "skeleton-d",
    "COSTUMES_SKELETON-E": "skeleton-e",
    "COSTUMES_SNAKE-A": "蛇-a",
    "COSTUMES_SNAKE-B": "蛇-b",
    "COSTUMES_SNAKE-C": "蛇-c",
    "COSTUMES_SNOWFLAKE": "雪花",
    "COSTUMES_SNOWMAN": "雪人",
    "COSTUMES_SOCCER_BALL": "足球",
    "COSTUMES_SPEAKER": "音响",
    "COSTUMES_SQUIRREL": "squirrel",
    "COSTUMES_STAR": "星星",
    "COSTUMES_STARFISH-A": "海星-a",
    "COSTUMES_STARFISH-B_": "海星-b ",
    "COSTUMES_STOP": "暂停按钮",
    "COSTUMES_STRAWBERRY-A": "草莓-a",
    "COSTUMES_STRAWBERRY-B": "草莓-b",
    "COSTUMES_STRAWBERRY-C": "草莓-c",
    "COSTUMES_STRAWBERRY-D": "草莓-d",
    "COSTUMES_STRAWBERRY-E": "草莓-e",
    "COSTUMES_SUN": "太阳",
    "COSTUMES_SUNGLASSES-A": "sunglasses-a",
    "COSTUMES_SUNGLASSES-B": "sunglasses-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-wizard",
    "COSTUMES_TAKEOUT-A": "外卖-a",
    "COSTUMES_TAKEOUT-B": "外卖-b",
    "COSTUMES_TAKEOUT-C": "外卖-c",
    "COSTUMES_TAKEOUT-D": "外卖-d",
    "COSTUMES_TAKEOUT-E": "外卖-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 pop down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 pop front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 pop L arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 pop left",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 pop R arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 pop right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 pop stand",
    "COSTUMES_TEN80_STANCE": "Ten80 stance",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 top freeze",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 top L step",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 top R cross",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 top R step",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 top stand",
    "COSTUMES_TENNISBALL": "tennisball",
    "COSTUMES_TOUCAN-A": "犀鸟-a",
    "COSTUMES_TOUCAN-B": "犀鸟-b",
    "COSTUMES_TOUCAN-C": "犀鸟-c",
    "COSTUMES_TRAMPOLINE": "trampoline",
    "COSTUMES_TREE1": "树",
    "COSTUMES_TREES-A": "trees-a",
    "COSTUMES_TREES-B": "trees-b",
    "COSTUMES_TRUCK-A": "Truck-a",
    "COSTUMES_TRUCK-B": "Truck-b",
    "COSTUMES_TRUCK-C": "Truck-c",
    "COSTUMES_TRUMPET-A": "trumpet-a",
    "COSTUMES_TRUMPET-B": "trumpet-b",
    "COSTUMES_UNICORN": "独角兽",
    "COSTUMES_UNICORN_2": "unicorn 2",
    "COSTUMES_UNICORN_RUNNING-A": "unicorn running-a",
    "COSTUMES_UNICORN_RUNNING-B": "unicorn running-b",
    "COSTUMES_UNICORN_RUNNING-C": "unicorn running-c",
    "COSTUMES_UNICORN_RUNNING-D": "unicorn running-d",
    "COSTUMES_UNICORN_RUNNING-E": "unicorn running-e",
    "COSTUMES_UNICORN_RUNNING-F": "unicorn running-f",
    "COSTUMES_WAND": "魔棒",
    "COSTUMES_WANDA": "流浪者",
    "COSTUMES_WATERMELON-A": "西瓜-a",
    "COSTUMES_WATERMELON-B": "西瓜-b",
    "COSTUMES_WATERMELON-C": "西瓜-c",
    "COSTUMES_WINTER_HAT": "Winter Hat",
    "COSTUMES_WITCH": "女巫",
    "COSTUMES_WITCH-A": "女巫-a",
    "COSTUMES_WITCH-B": "女巫-b",
    "COSTUMES_WITCH-C": "女巫-c",
    "COSTUMES_WITCH-D": "女巫-d",
    "COSTUMES_WIZARD_HAT": "Wizard Hat",
    "COSTUMES_WIZARD-A": "男巫-a",
    "COSTUMES_WIZARD-B": "男巫-b",
    "COSTUMES_WIZARD-C": "男巫-c",
    "COSTUMES_WIZARD-TOAD-A": "蛤蟆巫师-a",
    "COSTUMES_WIZARD-TOAD-B": "蛤蟆巫师-b",
    "COSTUMES_ZEBRA-A": "zebra-a",
    "COSTUMES_ZEBRA-B": "zebra-b",
    "COSTUMES_BLOCK-A": "块状-A",
    "COSTUMES_BLOCK-B": "块状-B",
    "COSTUMES_BLOCK-C": "块状-C",
    "COSTUMES_BLOCK-D": "块状-D",
    "COSTUMES_BLOCK-E": "块状-E",
    "COSTUMES_BLOCK-F": "块状-F",
    "COSTUMES_BLOCK-G": "块状-G",
    "COSTUMES_BLOCK-H": "块状-H",
    "COSTUMES_BLOCK-I": "块状-I",
    "COSTUMES_BLOCK-J": "块状-J",
    "COSTUMES_BLOCK-K": "块状-k",
    "COSTUMES_BLOCK-L": "块状-L",
    "COSTUMES_BLOCK-M": "块状-M",
    "COSTUMES_BLOCK-N": "块状-N",
    "COSTUMES_BLOCK-O": "块状-O",
    "COSTUMES_BLOCK-P": "块状-p",
    "COSTUMES_BLOCK-Q": "块状-Q",
    "COSTUMES_BLOCK-R": "块状-R",
    "COSTUMES_BLOCK-S": "块状-S",
    "COSTUMES_BLOCK-T": "块状-T",
    "COSTUMES_BLOCK-U": "块状-U",
    "COSTUMES_BLOCK-V": "块状-V",
    "COSTUMES_BLOCK-W": "块状-W",
    "COSTUMES_BLOCK-X": "块状-X",
    "COSTUMES_BLOCK-Y": "块状-Y",
    "COSTUMES_BLOCK-Z": "块状-Z",
    "COSTUMES_GLOW-0": "辉光-0",
    "COSTUMES_GLOW-1": "辉光-1",
    "COSTUMES_GLOW-2": "辉光-2",
    "COSTUMES_GLOW-3": "辉光-3",
    "COSTUMES_GLOW-4": "辉光-4",
    "COSTUMES_GLOW-5": "辉光-5",
    "COSTUMES_GLOW-6": "辉光-6",
    "COSTUMES_GLOW-7": "辉光-7",
    "COSTUMES_GLOW-8": "辉光-8",
    "COSTUMES_GLOW-9": "辉光-9",
    "COSTUMES_GLOW-A": "辉光-A",
    "COSTUMES_GLOW-B": "辉光-B",
    "COSTUMES_GLOW-C": "辉光-C",
    "COSTUMES_GLOW-D": "辉光-D",
    "COSTUMES_GLOW-E": "辉光-E",
    "COSTUMES_GLOW-F": "辉光-F",
    "COSTUMES_GLOW-G": "辉光-G",
    "COSTUMES_GLOW-H": "辉光-H",
    "COSTUMES_GLOW-I": "辉光-I",
    "COSTUMES_GLOW-J": "辉光-J",
    "COSTUMES_GLOW-K": "辉光-K",
    "COSTUMES_GLOW-L": "辉光-L",
    "COSTUMES_GLOW-M": "辉光-M",
    "COSTUMES_GLOW-N": "辉光-N",
    "COSTUMES_GLOW-O": "辉光-O",
    "COSTUMES_GLOW-P": "辉光-P",
    "COSTUMES_GLOW-Q": "辉光-Q",
    "COSTUMES_GLOW-R": "辉光-R",
    "COSTUMES_GLOW-S": "辉光-S",
    "COSTUMES_GLOW-T": "辉光-T",
    "COSTUMES_GLOW-U": "辉光-U",
    "COSTUMES_GLOW-V": "辉光-V",
    "COSTUMES_GLOW-W": "辉光-W",
    "COSTUMES_GLOW-X": "辉光-X",
    "COSTUMES_GLOW-Y": "辉光-Y",
    "COSTUMES_GLOW-Z": "辉光-Z",
    "COSTUMES_STORY-A-1": "故事-A-1",
    "COSTUMES_STORY-A-2": "故事-A-2",
    "COSTUMES_STORY-A-3": "故事-A-3",
    "COSTUMES_STORY-B-1": "故事-B-1",
    "COSTUMES_STORY-B-2": "故事-B-2",
    "COSTUMES_STORY-B-3": "故事-B-3",
    "COSTUMES_STORY-C-1": "故事-C-1",
    "COSTUMES_STORY-C-2": "故事-C-2",
    "COSTUMES_STORY-C-3": "故事-C-3",
    "COSTUMES_STORY-D-1": "故事-D-1",
    "COSTUMES_STORY-D-2": "故事-D-2",
    "COSTUMES_STORY-D-3": "故事-D-3",
    "COSTUMES_STORY-E-1": "故事-E-1",
    "COSTUMES_STORY-E-2": "故事-E-2",
    "COSTUMES_STORY-E-3": "故事-E-3",
    "COSTUMES_STORY-F-1": "故事-F-1",
    "COSTUMES_STORY-F-2": "故事-F-2",
    "COSTUMES_STORY-F-3": "故事-F-3",
    "COSTUMES_STORY-G-1": "故事-G-1",
    "COSTUMES_STORY-G-2": "故事-G-2",
    "COSTUMES_STORY-G-3": "故事-G-3",
    "COSTUMES_STORY-H-1": "故事-H-1",
    "COSTUMES_STORY-H-2": "故事-H-2",
    "COSTUMES_STORY-H-3": "故事-H-3",
    "COSTUMES_STORY-I-1": "故事-I-1",
    "COSTUMES_STORY-I-2": "故事-I-2",
    "COSTUMES_STORY-I-3": "故事-I-3",
    "COSTUMES_STORY-J-1": "故事-J-1",
    "COSTUMES_STORY-J-2": "故事-J-2",
    "COSTUMES_STORY-J-3": "故事-J-3",
    "COSTUMES_STORY-K-1": "故事-K-1",
    "COSTUMES_STORY-K-2": "故事-K-2",
    "COSTUMES_STORY-K-3": "故事-K-3",
    "COSTUMES_STORY-L-1": "故事-L-1",
    "COSTUMES_STORY-L-2": "故事-L-2",
    "COSTUMES_STORY-L-3": "故事-L-3",
    "COSTUMES_STORY-M-1": "故事-M-1",
    "COSTUMES_STORY-M-2": "故事-M-2",
    "COSTUMES_STORY-M-3": "故事-M-3",
    "COSTUMES_STORY-N-1": "故事-N-1",
    "COSTUMES_STORY-N-2": "故事-N-2",
    "COSTUMES_STORY-N-3": "故事-N-3",
    "COSTUMES_STORY-O-1": "故事-O-1",
    "COSTUMES_STORY-O-2": "故事-O-2",
    "COSTUMES_STORY-O-3": "故事-O-3",
    "COSTUMES_STORY-P-1": "故事-P-1",
    "COSTUMES_STORY-P-2": "故事-P-2",
    "COSTUMES_STORY-P-3": "故事-P-3",
    "COSTUMES_STORY-Q-1": "故事-Q-1",
    "COSTUMES_STORY-Q-2": "故事-Q-2",
    "COSTUMES_STORY-Q-3": "故事-Q-3",
    "COSTUMES_STORY-R-1": "故事-R-1",
    "COSTUMES_STORY-R-2": "故事-R-2",
    "COSTUMES_STORY-R-3": "故事-R-3",
    "COSTUMES_STORY-S-1": "故事-S-1",
    "COSTUMES_STORY-S-2": "故事-S-2",
    "COSTUMES_STORY-S-3": "故事-S-3",
    "COSTUMES_STORY-T-1": "故事-T-1",
    "COSTUMES_STORY-T-2": "故事-T-2",
    "COSTUMES_STORY-T-3": "故事-T-3",
    "COSTUMES_STORY-U-1": "故事-U-1",
    "COSTUMES_STORY-U-2": "故事-U-2",
    "COSTUMES_STORY-U-3": "故事-U-3",
    "COSTUMES_STORY-V-1": "故事-V-1",
    "COSTUMES_STORY-V-2": "故事-V-2",
    "COSTUMES_STORY-V-3": "故事-V-3",
    "COSTUMES_STORY-W-1": "故事-W-1",
    "COSTUMES_STORY-W-2": "故事-W-2",
    "COSTUMES_STORY-W-3": "故事-W-3",
    "COSTUMES_STORY-X-1": "故事-X-1",
    "COSTUMES_STORY-X-2": "故事-X-2",
    "COSTUMES_STORY-X-3": "故事-X-3",
    "COSTUMES_STORY-Y-1": "故事-Y-1",
    "COSTUMES_STORY-Y-2": "故事-Y-2",
    "COSTUMES_STORY-Y-3": "故事-Y-3",
    "COSTUMES_STORY-Z-1": "故事-Z-1",
    "COSTUMES_STORY-Z-2": "故事-Z-2",
    "COSTUMES_STORY-Z-3": "故事-Z-3",
    "COSTUMES_STEAMED-STUFFED-BUN": "包子",
    "COSTUMES_LUCKY-BAG": "福袋",
    "COSTUMES_DUMPLINGS": "饺子",
    "COSTUMES_GOLD-COINS": "金币",
    "COSTUMES_A-GIFT": "礼物-A",
    "COSTUMES_STEAMED-BREAD": "馒头",
    "COSTUMES_PLATES": "盘子",
    "COSTUMES_SILVER-INGOT": "元宝",
    "COSTUMES_FIRECRACKERS": "鞭炮",
    "COSTUMES_FIRECRACKERS-1": "鞭炮1",
    "COSTUMES_FIRECRACKERS-2": "鞭炮2",
    "COSTUMES_FIRECRACKERS-3": "鞭炮3",
    "COSTUMES_LANTERN": "灯笼",
    "COSTUMES_LANTERN-1": "灯笼1",
    "COSTUMES_LANTERN-2": "灯笼2",
    "COSTUMES_DRUM": "鼓-A",
    "COSTUMES_DRUM-1": "鼓1",
    "COSTUMES_DRUM-2": "鼓2",
    "COSTUMES_DRUM-3": "鼓3",
    "COSTUMES_RED-ENVELOPE": "红包",
    "COSTUMES_RED-ENVELOPE-1": "红包1",
    "COSTUMES_RED-ENVELOPE-2": "红包2",
    "COSTUMES_SCROLL": "卷轴",
    "COSTUMES_SCROLL-1": "卷轴1",
    "COSTUMES_SCROLL-2": "卷轴2",
    "COSTUMES_SCROLL-3": "卷轴3",
    "COSTUMES_YETI": "雪人-A",
    "COSTUMES_YETI-1": "雪人1",
    "COSTUMES_YETI-2": "雪人2",
    "COSTUMES_FIREWORKS": "烟花",
    "COSTUMES_FIREWORKS-1": "烟花1",
    "COSTUMES_FIREWORKS-2": "烟花2",
    "COSTUMES_FIREWORKS-3": "烟花3",
    "COSTUMES_FIREWORKS-4": "烟花4",
    "COSTUMES_FIREWORKS-5": "烟花5",
    "COSTUMES_FIREWORKS-6": "烟花6",
    "COSTUMES_FIREWORKS-7": "烟花7",
    "COSTUMES_FIREWORKS-8": "烟花8",
    "COSTUMES_FIREWORKS-9": "烟花9",
    "SOUNDS_A_BASS": "贝斯 A",
    "SOUNDS_A_ELEC_BASS": "电贝司 A",
    "SOUNDS_A_ELEC_GUITAR": "电吉他 A",
    "SOUNDS_A_ELEC_PIANO": "电子琴 A",
    "SOUNDS_A_GUITAR": "吉他 A",
    "SOUNDS_A_MINOR_UKULELE": "尤克里里 A",
    "SOUNDS_A_PIANO": "钢琴 A",
    "SOUNDS_A_SAX": "萨克斯 A",
    "SOUNDS_A_TROMBONE": "长号 A",
    "SOUNDS_A_TRUMPET": "小号A",
    "SOUNDS_AFRO_STRING": "非洲弦",
    "SOUNDS_ALERT": "警报",
    "SOUNDS_ALIEN_CREAK1": "嘎吱1",
    "SOUNDS_ALIEN_CREAK2": "嘎吱2",
    "SOUNDS_B_BASS": "贝斯 B",
    "SOUNDS_B_ELEC_BASS": "电贝司 B",
    "SOUNDS_B_ELEC_GUITAR": "电吉他 B",
    "SOUNDS_B_ELEC_PIANO": "电子琴 B",
    "SOUNDS_B_GUITAR": "吉他 B",
    "SOUNDS_B_PIANO": "钢琴 B",
    "SOUNDS_B_SAX": "萨克斯 B",
    "SOUNDS_B_TROMBONE": "长号 B",
    "SOUNDS_B_TRUMPET": "小号B",
    "SOUNDS_BAA": "咩",
    "SOUNDS_BARK": "汪",
    "SOUNDS_BASKETBALL_BOUNCE": "兵",
    "SOUNDS_BASS_BEATBOX": "节奏口技-低音",
    "SOUNDS_BEAT_BOX1": "电子节奏鼓1",
    "SOUNDS_BEAT_BOX2": "电子节奏鼓2",
    "SOUNDS_BELL_CYMBAL": "钟钹",
    "SOUNDS_BELL_TOLL": "钟声",
    "SOUNDS_BIG_BOING": "啵嘤1",
    "SOUNDS_BIRD": "鸟鸣",
    "SOUNDS_BIRTHDAY": "生日歌",
    "SOUNDS_BITE": "咬",
    "SOUNDS_BOING": "啵嘤",
    "SOUNDS_BONK": "梆",
    "SOUNDS_BOOM_CLOUD": "哐",
    "SOUNDS_BOOP_BING_BOP": "嘣兵啵",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": "碗摔碎",
    "SOUNDS_BUBBLES": "沸腾",
    "SOUNDS_BUZZ_WHIR": "嗡嗡",
    "SOUNDS_C_BASS": "贝斯 C",
    "SOUNDS_C_ELEC_BASS": "电贝司 C",
    "SOUNDS_C_ELEC_GUITAR": "电吉他 C",
    "SOUNDS_C_ELEC_PIANO": "电子琴 C",
    "SOUNDS_C_GUITAR": "吉他 C",
    "SOUNDS_C_MAJOR_UKULELE": "尤克里里 C",
    "SOUNDS_C_PIANO": "钢琴 C",
    "SOUNDS_C_SAX": "萨克斯 C",
    "SOUNDS_C_TROMBONE": "长号 C",
    "SOUNDS_C_TRUMPET": "小号C",
    "SOUNDS_C2_BASS": "贝斯 C2",
    "SOUNDS_C2_ELEC_BASS": "电贝斯 C2",
    "SOUNDS_C2_ELEC_GUITAR": "电吉他 C2",
    "SOUNDS_C2_ELEC_PIANO": "电子琴 C2",
    "SOUNDS_C2_GUITAR": "吉他 C2",
    "SOUNDS_C2_PIANO": "钢琴 C2",
    "SOUNDS_C2_SAX": "萨克斯 C2",
    "SOUNDS_C2_TROMBONE": "长号 C2",
    "SOUNDS_C2_TRUMPET": "小号C2",
    "SOUNDS_CAR_HORN": "汽车鸣笛",
    "SOUNDS_CAR_PASSING": "汽车行驶",
    "SOUNDS_CAR_VROOM": "汽车加速",
    "SOUNDS_CAVE": "陷落",
    "SOUNDS_CHATTER": "吱吱",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "欢呼",
    "SOUNDS_CHILL": "Chill",
    "SOUNDS_CHIRP": "啁啾",
    "SOUNDS_CHOMP": "咬牙",
    "SOUNDS_CHORD": "弦",
    "SOUNDS_CLANG": "当",
    "SOUNDS_CLAP_BEATBOX": "节奏口技-弹舌",
    "SOUNDS_CLAPPING": "掌声",
    "SOUNDS_CLASSICAL_PIANO": "Classical Piano",
    "SOUNDS_CLOCK_TICKING": "滴答滴答",
    "SOUNDS_CLOWN_HONK": "雁叫声",
    "SOUNDS_COIN": "鸟叫声",
    "SOUNDS_COLLECT": "叮",
    "SOUNDS_COMPUTER_BEEP": "哔哔",
    "SOUNDS_COMPUTER_BEEP2": "电脑哔哔声2",
    "SOUNDS_CONNECT": "连接提示音效",
    "SOUNDS_COUGH1": "咳嗽1",
    "SOUNDS_COUGH2": "咳嗽2",
    "SOUNDS_CRANK": "发条",
    "SOUNDS_CRASH_BEATBOX": "节奏口技-跨擦",
    "SOUNDS_CRASH_CYMBAL": "碎音钹",
    "SOUNDS_CRAZY_LAUGH": "大笑",
    "SOUNDS_CRICKET": "一只蟋蟀",
    "SOUNDS_CRICKETS": "一群蟋蟀",
    "SOUNDS_CROAK": "低沉沙哑",
    "SOUNDS_CROWD_GASP": "喘息声",
    "SOUNDS_CROWD_LAUGH": "哄堂大笑",
    "SOUNDS_CRUNCH": "嘎吱作响",
    "SOUNDS_CYMBAL": "镲",
    "SOUNDS_CYMBAL_CRASH": "碎音钹",
    "SOUNDS_CYMBAL_ECHO": "音响4",
    "SOUNDS_D_BASS": "贝斯 D",
    "SOUNDS_D_ELEC_BASS": "电贝斯 D",
    "SOUNDS_D_ELEC_GUITAR": "电吉他 D",
    "SOUNDS_D_ELEC_PIANO": "电子琴 D",
    "SOUNDS_D_GUITAR": "吉他 D",
    "SOUNDS_D_PIANO": "钢琴 D",
    "SOUNDS_D_SAX": "萨克斯 D",
    "SOUNDS_D_TROMBONE": "长号 D",
    "SOUNDS_D_TRUMPET": "小号D",
    "SOUNDS_DANCE_AROUND": "自由",
    "SOUNDS_DANCE_CELEBRATE": "庆祝",
    "SOUNDS_DANCE_CELEBRATE2": "dance celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": "悠闲",
    "SOUNDS_DANCE_ENERGETIC": "Dance Energetic",
    "SOUNDS_DANCE_FUNKY": "放克",
    "SOUNDS_DANCE_HEAD_NOD": "律动",
    "SOUNDS_DANCE_MAGIC": "魔力",
    "SOUNDS_DANCE_SITAR": "Dance Sitar",
    "SOUNDS_DANCE_SLOW_MO": "轻缓",
    "SOUNDS_DANCE_SNARE_BEAT": "鼓点",
    "SOUNDS_DANCE_SPACE": "空间",
    "SOUNDS_DISCONNECT": "连接断开提示音",
    "SOUNDS_DOG1": "汪汪",
    "SOUNDS_DOG2": "汪2",
    "SOUNDS_DOOR_CLOSING": "关门",
    "SOUNDS_DOOR_CREAK": "门嘎吱声",
    "SOUNDS_DOORBELL": "门铃声",
    "SOUNDS_DRIP_DROP": "滴落声",
    "SOUNDS_DRIVE_AROUND": "音响1",
    "SOUNDS_DRUM": "鼓",
    "SOUNDS_DRUM_BASS1": "低音大鼓1",
    "SOUNDS_DRUM_BASS2": "低音大鼓2",
    "SOUNDS_DRUM_BASS3": "低音大鼓3",
    "SOUNDS_DRUM_BOING": "鼓-啵嘤",
    "SOUNDS_DRUM_BUZZ": "鼓-嗡嗡",
    "SOUNDS_DRUM_FUNKY": "音响7",
    "SOUNDS_DRUM_JAM": "音响3",
    "SOUNDS_DRUM_MACHINE": "电子鼓",
    "SOUNDS_DRUM_ROLL": "击鼓声",
    "SOUNDS_DRUM_SATELLITE": "音响5",
    "SOUNDS_DRUM_SET1": "爵士鼓1",
    "SOUNDS_DRUM_SET2": "爵士鼓2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "嘎嘎",
    "SOUNDS_DUN_DUN_DUNNN": "咚咚当",
    "SOUNDS_E_BASS": "贝斯 E",
    "SOUNDS_E_ELEC_BASS": "电贝司 E",
    "SOUNDS_E_ELEC_GUITAR": "电吉他 E",
    "SOUNDS_E_ELEC_PIANO": "电子琴 E",
    "SOUNDS_E_GUITAR": "吉他 E",
    "SOUNDS_E_PIANO": "钢琴 E",
    "SOUNDS_E_SAX": "萨克斯 E",
    "SOUNDS_E_TROMBONE": "长号 E",
    "SOUNDS_E_TRUMPET": "小号E",
    "SOUNDS_EGGS": "蛋",
    "SOUNDS_ELEC_PIANO_A_MINOR": "电子琴 A小调",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "电子琴 C小调",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "电子琴 F小调",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "电子琴 G小调",
    "SOUNDS_ELEC_PIANO_LOOP": "电子琴loop",
    "SOUNDS_EMOTIONAL_PIANO": "Emotional Piano",
    "SOUNDS_ENGINE": "引擎",
    "SOUNDS_F_BASS": "贝斯 F",
    "SOUNDS_F_ELEC_BASS": "电子琴 F",
    "SOUNDS_F_ELEC_GUITAR": "电吉他 F",
    "SOUNDS_F_ELEC_PIANO": "电子琴 F",
    "SOUNDS_F_GUITAR": "吉他 F",
    "SOUNDS_F_MAJOR_UKULELE": "尤克里里 F小调",
    "SOUNDS_F_PIANO": "钢琴 F",
    "SOUNDS_F_SAX": "萨克斯 F",
    "SOUNDS_F_TROMBONE": "长号 F",
    "SOUNDS_F_TRUMPET": "小号F",
    "SOUNDS_FAIRYDUST": "精灵粉末",
    "SOUNDS_FINGER_SNAP": "响指",
    "SOUNDS_FLAM_SNARE": "倚音",
    "SOUNDS_FOOTSTEPS": "Footsteps",
    "SOUNDS_G_BASS": "贝斯 G",
    "SOUNDS_G_ELEC_BASS": "电贝司 G",
    "SOUNDS_G_ELEC_GUITAR": "电吉他 G",
    "SOUNDS_G_ELEC_PIANO": "电子琴 G",
    "SOUNDS_G_GUITAR": "吉他 G",
    "SOUNDS_G_PIANO": "钢琴 G",
    "SOUNDS_G_SAX": "萨克斯 G",
    "SOUNDS_G_TROMBONE": "长号 G",
    "SOUNDS_G_TRUMPET": "小号G",
    "SOUNDS_G_UKULELE": "尤克里里 G",
    "SOUNDS_GALLOP": "马蹄声",
    "SOUNDS_GARDEN": "花园",
    "SOUNDS_GLASS_BREAKING": "打碎玻璃",
    "SOUNDS_GLUG": "咕嘟咕嘟",
    "SOUNDS_GOAL_CHEER": "大声欢呼",
    "SOUNDS_GONG": "鸣锣",
    "SOUNDS_GOOSE": "鹅叫",
    "SOUNDS_GROWL": "咆哮",
    "SOUNDS_GRUNT": "呼噜声",
    "SOUNDS_GUITAR_CHORDS1": "吉他和弦1",
    "SOUNDS_GUITAR_CHORDS2": "吉他和弦2",
    "SOUNDS_GUITAR_STRUM": "吉他弹奏",
    "SOUNDS_HAND_CLAP": "拍手",
    "SOUNDS_HEAD_SHAKE": "摇头",
    "SOUNDS_HEY": "响指",
    "SOUNDS_HI_BEATBOX": "节奏口技-高音",
    "SOUNDS_HI_NA_TABLA": "塔布拉手鼓-Na-高",
    "SOUNDS_HI_TUN_TABLA": "塔布拉手鼓-Tun-高",
    "SOUNDS_HIGH_CONGA": "康加鼓-高",
    "SOUNDS_HIGH_HAT": "踩镲",
    "SOUNDS_HIGH_TOM": "高音鼓",
    "SOUNDS_HIGH_WHOOSH": "嘶嘶声-高",
    "SOUNDS_HIHAT_BEATBOX": "电子节奏鼓-踩镲",
    "SOUNDS_HIHAT_CYMBAL": "脚踏双面铜钹",
    "SOUNDS_HIP_HOP": "嘻哈",
    "SOUNDS_HORSE": "嘶",
    "SOUNDS_HORSE_GALLOP": "马蹄声",
    "SOUNDS_HUMAN_BEATBOX1": "节奏口技1",
    "SOUNDS_HUMAN_BEATBOX2": "节奏口技2",
    "SOUNDS_JUMP": "跳跃",
    "SOUNDS_JUNGLE": "丛林",
    "SOUNDS_JUNGLE_FROGS": "青蛙",
    "SOUNDS_KICK_BACK": "音响6",
    "SOUNDS_KICK_DRUM": "底鼓",
    "SOUNDS_LARGE_COWBELL": "牛铃",
    "SOUNDS_LASER1": "激光1",
    "SOUNDS_LASER2": "激光2",
    "SOUNDS_LAUGH1": "笑声1",
    "SOUNDS_LAUGH2": "笑声2",
    "SOUNDS_LAUGH3": "笑声3",
    "SOUNDS_LO_GEH_TABLA": "塔布拉手鼓-Geh-低",
    "SOUNDS_LO_GLISS_TABLA": "塔布拉手鼓-Gliss-低",
    "SOUNDS_LOSE": "失败",
    "SOUNDS_LOW_BOING": "啵嘤-低",
    "SOUNDS_LOW_CONGA": "康加鼓-低",
    "SOUNDS_LOW_SQUEAK": "吱吱声-低",
    "SOUNDS_LOW_TOM": "低音嗵鼓",
    "SOUNDS_LOW_WHOOSH": "嘶嘶声-低",
    "SOUNDS_MACHINE": "机械声",
    "SOUNDS_MAGIC_SPELL": "魔咒",
    "SOUNDS_MEDIEVAL1": "中世纪1",
    "SOUNDS_MEDIEVAL2": "中世纪2",
    "SOUNDS_MEOW": "喵",
    "SOUNDS_MEOW2": "喵2",
    "SOUNDS_MOO": "哞",
    "SOUNDS_MOTORCYCLE_PASSING": "摩托车行驶",
    "SOUNDS_MOVIE_1": "Movie 1",
    "SOUNDS_MOVIE_2": "Movie 2",
    "SOUNDS_MUTED_CONGA": "康加鼓-柔和",
    "SOUNDS_MYSTERY": "Mystery",
    "SOUNDS_OCEAN_WAVE": "海浪",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "哎呀",
    "SOUNDS_ORCHESTRA_TUNING": "管弦乐队",
    "SOUNDS_OWL": "猫头鹰",
    "SOUNDS_PARTY_NOISE": "聚会声",
    "SOUNDS_PEW": "piu",
    "SOUNDS_PING_PONG_HIT": "兵乓球",
    "SOUNDS_PLUCK": "猛拽",
    "SOUNDS_PLUNGE": "跳水",
    "SOUNDS_POLICE_SIREN": "警笛",
    "SOUNDS_POP": "啵",
    "SOUNDS_RAIN": "雨声",
    "SOUNDS_RATTLE": "咯咯声",
    "SOUNDS_REFEREE_WHISTLE": "裁判哨",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "弹飞",
    "SOUNDS_RIDE_CYMBAL": "高架钹",
    "SOUNDS_RING_TONE": "电话铃声",
    "SOUNDS_RIP": "撕布声",
    "SOUNDS_RIPPLES": "涟漪",
    "SOUNDS_ROLL_CYMBAL": "轧辊钹",
    "SOUNDS_ROOSTER": "打鸣",
    "SOUNDS_SCRAMBLING_FEET": "匆忙",
    "SOUNDS_SCRATCH_BEATBOX": "节奏口技-刮舌",
    "SOUNDS_SCRATCHY_BEAT": "音响2",
    "SOUNDS_SCREAM1": "尖叫1",
    "SOUNDS_SCREAM2": "尖叫2",
    "SOUNDS_SCREECH": "尖叫声",
    "SOUNDS_SEAGULLS": "海鸥",
    "SOUNDS_SEWING_MACHINE": "缝纫机",
    "SOUNDS_SHAKER": "振动器",
    "SOUNDS_SHIP_BELL": "船上报时闹钟",
    "SOUNDS_SIDESTICK_SNARE": "小鼓鼓边",
    "SOUNDS_SINGER1": "歌手1",
    "SOUNDS_SINGER2": "歌手2",
    "SOUNDS_SIREN_WHISTLE": "警报",
    "SOUNDS_SKID": "车打滑",
    "SOUNDS_SLIDE_WHISTLE": "溜溜笛",
    "SOUNDS_SMALL_COWBELL": "小牛铃",
    "SOUNDS_SNAP": "劈啪声",
    "SOUNDS_SNARE_BEATBOX": "节奏口技-小鼓1",
    "SOUNDS_SNARE_BEATBOX2": "节奏口技-小鼓2",
    "SOUNDS_SNARE_DRUM": "小军鼓",
    "SOUNDS_SNEAKER_SQUEAK": "运动鞋吱吱声",
    "SOUNDS_SNEEZE1": "打喷嚏1",
    "SOUNDS_SNEEZE2": "打喷嚏2",
    "SOUNDS_SNORING": "打鼾",
    "SOUNDS_SNORT": "蒸汽声",
    "SOUNDS_SPACE_AMBIENCE": "空间音效",
    "SOUNDS_SPACE_FLYBY": "飞机低空飞行",
    "SOUNDS_SPACE_NOISE": "空气轰鸣声",
    "SOUNDS_SPACE_RIPPLE": "空间涟漪",
    "SOUNDS_SPIRAL": "旋涡",
    "SOUNDS_SPLASH": "溅起水花",
    "SOUNDS_SPLASH_CYMBAL": " 水钹",
    "SOUNDS_SPOOKY_STRING": "骇人的弦声",
    "SOUNDS_SQUAWK": "Squawk",
    "SOUNDS_SQUEAKS": "短促的吱吱声",
    "SOUNDS_SQUEAKY_TOY": "阿偶",
    "SOUNDS_SQUISH_POP": "咯吱",
    "SOUNDS_STRING_ACCENT": "弦乐重音",
    "SOUNDS_STRING_PLUCK": "弹乐",
    "SOUNDS_SUCTION_CUP": "吸盘",
    "SOUNDS_SUSPENSE": "怀疑",
    "SOUNDS_TADA": "完成",
    "SOUNDS_TAMBURA": "坦布拉琴",
    "SOUNDS_TAP_CONGA": "敲康加琴",
    "SOUNDS_TAP_SNARE": "低弹起",
    "SOUNDS_TECHNO": "高科技舞曲",
    "SOUNDS_TECHNO2": "高科技舞曲2",
    "SOUNDS_TELEPHONE_RING": "电话铃声",
    "SOUNDS_TELEPHONE_RING2": "电话铃声2",
    "SOUNDS_TELEPORT": "传送",
    "SOUNDS_TELEPORT2": "传送2",
    "SOUNDS_TELEPORT3": "传送3",
    "SOUNDS_TENNIS_HIT": "网球声",
    "SOUNDS_THUNDER_STORM": "雷雨",
    "SOUNDS_TOM_DRUM": "嗵嗵鼓",
    "SOUNDS_TOY_HONK": "玩具车鸣笛",
    "SOUNDS_TOY_ZING": "玩具车车轮转动",
    "SOUNDS_TRAFFIC": "车流",
    "SOUNDS_TRAIN_WHISTLE": "火车鸣笛",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_TRIUMPH": "成功",
    "SOUNDS_TROPICAL_BIRDS": "热带飞禽",
    "SOUNDS_TRUMPET1": "喇叭1",
    "SOUNDS_TRUMPET2": "喇叭2",
    "SOUNDS_VIDEO_GAME_1": "Video Game 1",
    "SOUNDS_VIDEO_GAME_2": "Video Game 2",
    "SOUNDS_WAH_BEATBOX": "节奏口技-哇",
    "SOUNDS_WAND": "魔棒",
    "SOUNDS_WATER_DROP": "滴水",
    "SOUNDS_WHINNY": "马嘶声",
    "SOUNDS_WHISTLE_THUMP": "撞倒",
    "SOUNDS_WHIZ": "飕飕声",
    "SOUNDS_WHOOP": "哮喘声",
    "SOUNDS_WIN": "胜利",
    "SOUNDS_WOBBLE": "晃动",
    "SOUNDS_WOLF_HOWL": "狼嚎",
    "SOUNDS_WOOD_TAP": "敲木头",
    "SOUNDS_WUB_BEATBOX": "节奏口技-噗",
    "SOUNDS_XYLO1": "木琴1",
    "SOUNDS_XYLO2": "木琴2",
    "SOUNDS_XYLO3": "木琴3",
    "SOUNDS_XYLO4": "木琴4",
    "SOUNDS_YA": "呀",
    "SOUNDS_ZIP": "啧噗",
    "SOUNDS_ZOOP": "哫噗",
    "SPRITES_ABBY": "艾比",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_APPLE": "苹果",
    "SPRITES_ARROW1": "箭头",
    "SPRITES_AVERY": "艾弗里",
    "SPRITES_AVERY_WALKING": "艾弗里-行走",
    "SPRITES_BALL": "球",
    "SPRITES_BALLERINA": "芭蕾舞女孩",
    "SPRITES_BALLOON1": "气球",
    "SPRITES_BANANAS": "香蕉",
    "SPRITES_BASEBALL": "棒球",
    "SPRITES_BASKETBALL": "篮球",
    "SPRITES_BAT": "Bat",
    "SPRITES_BATTER": "Batter",
    "SPRITES_BEACHBALL": "沙滩球",
    "SPRITES_BEAR": "熊",
    "SPRITES_BEAR-WALKING": "熊-行走",
    "SPRITES_BEETLE": "甲壳虫",
    "SPRITES_BELL": "铃铛",
    "SPRITES_BEN": "本",
    "SPRITES_BOWL": "碗",
    "SPRITES_BOWTIE": "蝴蝶结",
    "SPRITES_BREAD": "面包",
    "SPRITES_BROOM": "扫帚",
    "SPRITES_BUILDINGS": "建筑物",
    "SPRITES_BUTTERFLY_1": "Butterfly 1",
    "SPRITES_BUTTERFLY_2": "Butterfly 2",
    "SPRITES_BUTTON1": "圆形按钮",
    "SPRITES_BUTTON2": "椭圆形按钮",
    "SPRITES_BUTTON3": "方形按钮",
    "SPRITES_BUTTON4": "勾形按钮",
    "SPRITES_BUTTON5": "叉形按钮",
    "SPRITES_CAKE": "蛋糕",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "凯西",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Cat 2",
    "SPRITES_CATCHER": "Catcher",
    "SPRITES_CENTAUR": "半人马",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Chick",
    "SPRITES_CITY_BUS": "City Bus",
    "SPRITES_CLOUD": "白云",
    "SPRITES_CLOUDS": "白云1",
    "SPRITES_CONVERTIBLE": "Convertible",
    "SPRITES_CONVERTIBLE_2": "Convertible 2",
    "SPRITES_CRAB": "螃蟹",
    "SPRITES_CRYSTAL": "钻石",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "达尼",
    "SPRITES_DEE": "迪伊",
    "SPRITES_DEVIN": "德温",
    "SPRITES_DINOSAUR1": "恐龙1",
    "SPRITES_DINOSAUR2": "恐龙2",
    "SPRITES_DINOSAUR3": "恐龙3",
    "SPRITES_DINOSAUR4": "恐龙4",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DIVER1": "潜水员1",
    "SPRITES_DIVER2": "潜水员2",
    "SPRITES_DOG1": "小狗1",
    "SPRITES_DOG2": "小狗2",
    "SPRITES_DONUT": "圆环",
    "SPRITES_DORIAN": "多里安人",
    "SPRITES_DOT": "点点",
    "SPRITES_DOVE": "鸽子",
    "SPRITES_DRAGON": "龙",
    "SPRITES_DRAGONFLY": "Dragonfly",
    "SPRITES_DRESS": "Dress",
    "SPRITES_DRUM": "鼓",
    "SPRITES_DRUM_KIT": "爵士鼓",
    "SPRITES_DRUM-CYMBAL": "镲",
    "SPRITES_DRUM-HIGHHAT": "踩镲",
    "SPRITES_DRUM-SNARE": "小鼓",
    "SPRITES_DRUMS_CONGA": "Drums Conga",
    "SPRITES_DRUMS_TABLA": "Drums Tabla",
    "SPRITES_DUCK": "鸭子",
    "SPRITES_EARTH": "地球",
    "SPRITES_EASEL": "Easel",
    "SPRITES_EGG": "蛋",
    "SPRITES_ELEPHANT": "大象",
    "SPRITES_ELF": "小精灵",
    "SPRITES_FAIRY": "精灵",
    "SPRITES_FISH": "鱼",
    "SPRITES_FISHBOWL": "Fishbowl",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Football",
    "SPRITES_FORTUNE_COOKIE": "Fortune Cookie",
    "SPRITES_FOX": "狐狸",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "青蛙",
    "SPRITES_FROG_2_": "Frog 2 ",
    "SPRITES_FRUIT_PLATTER": "Fruit Platter",
    "SPRITES_FRUIT_SALAD": "水果沙拉",
    "SPRITES_GHOST": "Ghost",
    "SPRITES_GIFT": "礼物",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GLASS_WATER": "水杯",
    "SPRITES_GLASSES": "眼镜",
    "SPRITES_GOALIE": "守门员",
    "SPRITES_GOBLIN": "哥布林",
    "SPRITES_GRASSHOPPER": "Grasshopper",
    "SPRITES_GREEN_FLAG": "绿旗",
    "SPRITES_GRIFFIN": "格里芬",
    "SPRITES_GUITAR": "吉他",
    "SPRITES_GUITAR-ELECTRIC1": "Guitar-electric1",
    "SPRITES_GUITAR-ELECTRIC2": "电吉他2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hare",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hat1 ",
    "SPRITES_HATCHLING": "Hatchling",
    "SPRITES_HEART": "爱心",
    "SPRITES_HEART_CANDY": "糖心",
    "SPRITES_HEART_FACE": "心表情",
    "SPRITES_HEDGEHOG": "刺猬",
    "SPRITES_HEN": "Hen",
    "SPRITES_HIPPO1": "河马",
    "SPRITES_HOME_BUTTON": "房型按钮",
    "SPRITES_HORSE": "Horse",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "罐子",
    "SPRITES_JELLYFISH": "水母",
    "SPRITES_JORDYN": "乔丁",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "钥匙",
    "SPRITES_KEYBOARD": "键盘",
    "SPRITES_KIRAN": "基兰",
    "SPRITES_KNIGHT": "骑士",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_LADYBUG1": "七星瓢虫1",
    "SPRITES_LADYBUG2": "七星瓢虫2",
    "SPRITES_LAPTOP": "笔记本电脑",
    "SPRITES_LIGHTNING": "闪电",
    "SPRITES_LINE": "线",
    "SPRITES_LION": "狮子",
    "SPRITES_LLAMA": "无峰驼",
    "SPRITES_MAGIC_WAND": "魔棒",
    "SPRITES_MAX": "麦克斯",
    "SPRITES_MERMAID": "美人鱼",
    "SPRITES_MICROPHONE": "麦克风",
    "SPRITES_MILK": "牛奶",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MONET": "莫奈",
    "SPRITES_MONKEY": "猴子",
    "SPRITES_MOTORCYCLE": "Motorcycle",
    "SPRITES_MOUSE1": "老鼠",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": "小马驹",
    "SPRITES_OCTOPUS": "章鱼",
    "SPRITES_ORANGE": "橘子",
    "SPRITES_ORANGE2": "橘子2",
    "SPRITES_OUTFIELDER": "Outfielder",
    "SPRITES_OWL": "猫头鹰",
    "SPRITES_PADDLE": "桨",
    "SPRITES_PANTHER": "美洲豹",
    "SPRITES_PANTS": "Pants",
    "SPRITES_PARROT": "鹦鹉",
    "SPRITES_PARTY_HATS": "Party Hats",
    "SPRITES_PENCIL": "铅笔",
    "SPRITES_PENGUIN": "Penguin",
    "SPRITES_PENGUIN_2": "Penguin 2",
    "SPRITES_PITCHER": "Pitcher",
    "SPRITES_PLANET2": "行星",
    "SPRITES_POLAR_BEAR": "Polar Bear",
    "SPRITES_POTION": "药剂",
    "SPRITES_PRINCE": "王子",
    "SPRITES_PRINCESS": "公主",
    "SPRITES_PUFFERFISH": "河豚",
    "SPRITES_PUPPY": "Puppy",
    "SPRITES_RABBIT": "兔子",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": "彩虹",
    "SPRITES_REFEREE": "裁判员",
    "SPRITES_REINDEER": "驯鹿",
    "SPRITES_RETRO_ROBOT": "Retro Robot",
    "SOUNDS_COMPUTER_BEEPS1": "computer beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "computer beeps2",
    "SPRITES_RIPLEY": "里普利",
    "SPRITES_ROBOT": "机器人",
    "SPRITES_ROCKETSHIP": "Rocketship",
    "SPRITES_ROCKS": "石头",
    "SPRITES_ROOSTER": "Rooster",
    "SPRITES_RUBY": "Ruby",
    "SPRITES_SAILBOAT": "Sailboat",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "萨克斯",
    "SPRITES_SCARF": "Scarf",
    "SPRITES_SHARK": "鲨鱼",
    "SPRITES_SHARK_2": "Shark 2",
    "SPRITES_SHIRT": "Shirt",
    "SPRITES_SHOES": "Shoes",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "歌手1",
    "SPRITES_SKELETON": "Skeleton",
    "SPRITES_SNAKE": "蛇",
    "SPRITES_SNOWFLAKE": "雪花",
    "SPRITES_SNOWMAN": "雪人",
    "SPRITES_SOCCER_BALL": "足球",
    "SPRITES_SPEAKER": "音响",
    "SPRITES_SQUIRREL": "Squirrel",
    "SPRITES_STAR": "星星",
    "SPRITES_STARFISH": "海星",
    "SPRITES_STOP": "暂停按钮",
    "SPRITES_STRAWBERRY": "草莓",
    "SPRITES_SUN": "太阳",
    "SPRITES_SUNGLASSES1": "太阳镜1",
    "SPRITES_TACO": "煎玉米卷",
    "SPRITES_TAKEOUT": "外卖",
    "SPRITES_TEN80_DANCE": "Ten80 Dance",
    "SPRITES_TENNIS_BALL": "Tennis Ball",
    "SPRITES_TOUCAN": "犀鸟",
    "SPRITES_TRAMPOLINE": "Trampoline",
    "SPRITES_TREE1": "树",
    "SPRITES_TREES": "树1",
    "SPRITES_TRUCK": "Truck",
    "SPRITES_TRUMPET": "小号",
    "SPRITES_UNICORN": "独角兽",
    "SPRITES_UNICORN_2": "Unicorn 2",
    "SPRITES_UNICORN_RUNNING": "Unicorn Running",
    "SPRITES_WAND": "魔棒",
    "SPRITES_WANDA": "流浪者",
    "SPRITES_WATERMELON": "西瓜",
    "SPRITES_WINTER_HAT": "Winter Hat",
    "SPRITES_WITCH": "女巫",
    "SPRITES_WIZARD": "男巫",
    "SPRITES_WIZARD_HAT": "Wizard Hat",
    "SPRITES_WIZARD-TOAD": "蛤蟆巫师",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "块状-A",
    "SPRITES_BLOCK-B": "块状-B",
    "SPRITES_BLOCK-C": "块状-C",
    "SPRITES_BLOCK-D": "块状-D",
    "SPRITES_BLOCK-E": "块状-E",
    "SPRITES_BLOCK-F": "块状-F",
    "SPRITES_BLOCK-G": "块状-G",
    "SPRITES_BLOCK-H": "块状-H",
    "SPRITES_BLOCK-I": "块状-I",
    "SPRITES_BLOCK-J": "块状-J",
    "SPRITES_BLOCK-K": "块状-K",
    "SPRITES_BLOCK-L": "块状-L",
    "SPRITES_BLOCK-M": "块状-M",
    "SPRITES_BLOCK-N": "块状-N",
    "SPRITES_BLOCK-O": "块状-O",
    "SPRITES_BLOCK-P": "块状-P",
    "SPRITES_BLOCK-Q": "块状-Q",
    "SPRITES_BLOCK-R": "块状-R",
    "SPRITES_BLOCK-S": "块状-S",
    "SPRITES_BLOCK-T": "块状-T",
    "SPRITES_BLOCK-U": "块状-U",
    "SPRITES_BLOCK-V": "块状-V",
    "SPRITES_BLOCK-W": "块状-W",
    "SPRITES_BLOCK-X": "块状-X",
    "SPRITES_BLOCK-Y": "块状-Y",
    "SPRITES_BLOCK-Z": "块状-Z",
    "SPRITES_GLOW-0": "辉光-0",
    "SPRITES_GLOW-1": "辉光-1",
    "SPRITES_GLOW-2": "辉光-2",
    "SPRITES_GLOW-3": "辉光-3",
    "SPRITES_GLOW-4": "辉光-4",
    "SPRITES_GLOW-5": "辉光-5",
    "SPRITES_GLOW-6": "辉光-6",
    "SPRITES_GLOW-7": "辉光-7",
    "SPRITES_GLOW-8": "辉光-8",
    "SPRITES_GLOW-9": "辉光-9",
    "SPRITES_GLOW-A": "辉光-A",
    "SPRITES_GLOW-B": "辉光-B",
    "SPRITES_GLOW-C": "辉光-C",
    "SPRITES_GLOW-D": "辉光-D",
    "SPRITES_GLOW-E": "辉光-E",
    "SPRITES_GLOW-F": "辉光-F",
    "SPRITES_GLOW-G": "辉光-G",
    "SPRITES_GLOW-H": "辉光-H",
    "SPRITES_GLOW-I": "辉光-I",
    "SPRITES_GLOW-J": "辉光-J",
    "SPRITES_GLOW-K": "辉光-K",
    "SPRITES_GLOW-L": "辉光-L",
    "SPRITES_GLOW-M": "辉光-M",
    "SPRITES_GLOW-N": "辉光-N",
    "SPRITES_GLOW-O": "辉光-O",
    "SPRITES_GLOW-P": "辉光-P",
    "SPRITES_GLOW-Q": "辉光-Q",
    "SPRITES_GLOW-R": "辉光-R",
    "SPRITES_GLOW-S": "辉光-S",
    "SPRITES_GLOW-T": "辉光-T",
    "SPRITES_GLOW-U": "辉光-U",
    "SPRITES_GLOW-V": "辉光-V",
    "SPRITES_GLOW-W": "辉光-W",
    "SPRITES_GLOW-X": "辉光-X",
    "SPRITES_GLOW-Y": "辉光-Y",
    "SPRITES_GLOW-Z": "辉光-Z",
    "SPRITES_STORY-A": "故事-A",
    "SPRITES_STORY-B": "故事-B",
    "SPRITES_STORY-C": "故事-C",
    "SPRITES_STORY-D": "故事-D",
    "SPRITES_STORY-E": "故事-E",
    "SPRITES_STORY-F": "故事-F",
    "SPRITES_STORY-G": "故事-G",
    "SPRITES_STORY-H": "故事-H",
    "SPRITES_STORY-I": "故事-I",
    "SPRITES_STORY-J": "故事-J",
    "SPRITES_STORY-K": "故事-K",
    "SPRITES_STORY-L": "故事-L",
    "SPRITES_STORY-M": "故事-M",
    "SPRITES_STORY-N": "故事-N",
    "SPRITES_STORY-O": "故事-O",
    "SPRITES_STORY-P": "故事-P",
    "SPRITES_STORY-Q": "故事-Q",
    "SPRITES_STORY-R": "故事-R",
    "SPRITES_STORY-S": "故事-S",
    "SPRITES_STORY-T": "故事-T",
    "SPRITES_STORY-U": "故事-U",
    "SPRITES_STORY-V": "故事-V",
    "SPRITES_STORY-W": "故事-W",
    "SPRITES_STORY-X": "故事-X",
    "SPRITES_STORY-Y": "故事-Y",
    "SPRITES_STORY-Z": "故事-Z",
    "SPRITES_MIND+2022_01_CIVIL-AIRCRAFT": "民航飞机",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT1": "民航飞机-正面",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT2": "民航飞机-侧面",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT3": "民航飞机-顶部",
    "SPRITES_MIND+2022_02_AEROSPACEPLANE": "航天飞机",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE1": "航天飞机-正面",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE2": "航天飞机-侧面",
    "SPRITES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM": "航天飞机机舱",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM1": "航天飞机机舱-正面",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM2": "航天飞机机舱-侧面",
    "SPRITES_MIND+2022_04_TRAFFIC-LIGHT": "表情红绿灯",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT1": "表情红绿灯-红",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT2": "表情红绿灯-黄",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT3": "表情红绿灯-绿",
    "SPRITES_MIND+2022_05_FLYBROW": "飞艇",
    "COSTUMES_MIND+2022_05_FLYBROW1": "飞艇-侧面",
    "COSTUMES_MIND+2022_05_FLYBROW2": "飞艇-仰视",
    "SPRITES_MIND+2022_06_HIGHSPEED-RAIL": "高铁",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL1": "高铁-正面",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL2": "高铁-侧面",
    "SPRITES_MIND+2022_07_POLICECAR": "警车",
    "COSTUMES_MIND+2022_07_POLICECAR1": "警车-运动1",
    "COSTUMES_MIND+2022_07_POLICECAR2": "警车-运动2",
    "COSTUMES_MIND+2022_07_POLICECAR3": "警车-运动3",
    "COSTUMES_MIND+2022_07_POLICECAR4": "警车-运动4",
    "COSTUMES_MIND+2022_07_POLICECAR5": "警车-亮灯1",
    "COSTUMES_MIND+2022_07_POLICECAR6": "警车-亮灯2",
    "COSTUMES_MIND+2022_07_POLICECAR7": "警车-静止",
    "SPRITES_MIND+2022_08_CAR": "小汽车",
    "COSTUMES_MIND+2022_08_CAR1": "小汽车-1",
    "COSTUMES_MIND+2022_08_CAR2": "小汽车-2",
    "COSTUMES_MIND+2022_08_CAR3": "小汽车-3",
    "COSTUMES_MIND+2022_08_CAR4": "小汽车-4",
    "COSTUMES_MIND+2022_08_CAR5": "小汽车-5",
    "COSTUMES_MIND+2022_08_CAR6": "小汽车-6",
    "SPRITES_MIND+2022_09_DININGCAR": "餐车",
    "SPRITES_MIND+2022_10_BUS": "公交车",
    "SPRITES_MIND+2022_11_GO-KART": "卡丁车",
    "SPRITES_MIND+2022_12_MOTORBIKE": "摩托车",
    "SPRITES_MIND+2022_13_TAXI": "出租车",
    "COSTUMES_MIND+2022_13_TAXI01": "出租车-正侧面",
    "COSTUMES_MIND+2022_13_TAXI02": "出租车-侧面",
    "COSTUMES_MIND+2022_13_TAXI03": "出租车-正侧面关灯",
    "COSTUMES_MIND+2022_13_TAXI04": "出租车-正侧面开灯1",
    "COSTUMES_MIND+2022_13_TAXI05": "出租车-正侧面开灯2",
    "COSTUMES_MIND+2022_13_TAXI06": "出租车-正面",
    "SPRITES_MIND+2022_14_PUMPKINCAR": "南瓜车",
    "COSTUMES_MIND+2022_14_PUMPKINCAR1": "南瓜车-1",
    "COSTUMES_MIND+2022_14_PUMPKINCAR2": "南瓜车-2",
    "COSTUMES_MIND+2022_14_PUMPKINCAR3": "南瓜车-3",
    "COSTUMES_MIND+2022_14_PUMPKINCAR4": "南瓜车-开门",
    "COSTUMES_MIND+2022_14_PUMPKINCAR5": "南瓜车-夜晚",
    "SPRITES_MIND+2022_15_LITTLESAILBOAT": "小帆船",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT1": "小帆船-侧面",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT2": "小帆船-顶侧",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT3": "小帆船-斜顶侧",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT4": "小帆船-斜顶侧1",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT5": "小帆船-斜顶侧2",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT6": "小帆船-斜顶侧3",
    "SPRITES_MIND+2022_16_RUBBLERACER": "小飞船",
    "COSTUMES_MIND+2022_16_RUBBLERACER1": "小飞船-01",
    "COSTUMES_MIND+2022_16_RUBBLERACER2": "小飞船-02",
    "COSTUMES_MIND+2022_16_RUBBLERACER3": "小飞船-03",
    "SPRITES_MIND+2022_17_BARNEY": "小矿车",
    "COSTUMES_MIND+2022_17_BARNEY1": "小矿车-侧面满",
    "COSTUMES_MIND+2022_17_BARNEY2": "小矿车-顶侧面",
    "COSTUMES_MIND+2022_17_BARNEY3": "小矿车-顶侧面2",
    "COSTUMES_MIND+2022_17_BARNEY4": "小矿车-顶侧面3",
    "COSTUMES_MIND+2022_17_BARNEY5": "小矿车-顶侧面空1",
    "SPRITES_MIND+2022_18_UTILITYCART": "小推车",
    "COSTUMES_MIND+2022_18_UTILITYCART1": "小推车-侧面",
    "COSTUMES_MIND+2022_18_UTILITYCART2": "小推车-顶侧面",
    "COSTUMES_MIND+2022_18_UTILITYCART3": "小推车-顶侧面满1",
    "COSTUMES_MIND+2022_18_UTILITYCART4": "小推车-顶侧面满2",
    "COSTUMES_MIND+2022_18_UTILITYCART5": "小推车-顶侧面满3",
    "SPRITES_MIND+2022_19_BIKE": "自行车",
    "COSTUMES_MIND+2022_19_BIKE1": "自行车-侧面1",
    "COSTUMES_MIND+2022_19_BIKE2": "自行车-侧面2",
    "COSTUMES_MIND+2022_19_BIKE3": "自行车-侧面3",
    "COSTUMES_MIND+2022_19_BIKE4": "自行车-侧面4",
    "COSTUMES_MIND+2022_19_BIKE5": "自行车-正面",
    "SPRITES_MIND+2022_20_RACECAR": "赛车",
    "COSTUMES_MIND+2022_20_RACECAR1": "赛车-侧面",
    "COSTUMES_MIND+2022_20_RACECAR2": "蓝色赛车-顶面1",
    "COSTUMES_MIND+2022_20_RACECAR3": "蓝色赛车-顶面2",
    "COSTUMES_MIND+2022_20_RACECAR4": "蓝色赛车-顶面3",
    "COSTUMES_MIND+2022_20_RACECAR5": "黄色赛车-顶面1",
    "COSTUMES_MIND+2022_20_RACECAR6": "黄色赛车-顶面2",
    "COSTUMES_MIND+2022_20_RACECAR7": "黄色赛车-顶面3",
    "COSTUMES_MIND+2022_20_RACECAR8": "粉色赛车-顶面1",
    "COSTUMES_MIND+2022_20_RACECAR9": "粉色赛车-顶面2",
    "COSTUMES_MIND+2022_20_RACECAR10": "粉色赛车-顶面3",
    "SPRITES_MIND+2022_21_METEORITES1": "陨石",
    "COSTUMES_MIND+2022_21_METEORITES1_1": "陨石1-01",
    "COSTUMES_MIND+2022_21_METEORITES1_2": "陨石1-02",
    "COSTUMES_MIND+2022_21_METEORITES1_3": "陨石1-03",
    "SPRITES_MIND+2022_22_METEORITES2": "陨石2",
    "COSTUMES_MIND+2022_22_METEORITES2_1": "陨石2-01",
    "COSTUMES_MIND+2022_22_METEORITES2_2": "陨石2-02",
    "COSTUMES_MIND+2022_22_METEORITES2_3": "陨石2-03",
    "SPRITES_MIND+2022_23_METEORITES3": "陨石3",
    "COSTUMES_MIND+2022_23_METEORITES3_1": "陨石3-01",
    "COSTUMES_MIND+2022_23_METEORITES3_2": "陨石3-02",
    "COSTUMES_MIND+2022_23_METEORITES3_3": "陨石3-03",
    "SPRITES_MIND+2022_24_METEORITES4": "陨石4",
    "COSTUMES_MIND+2022_24_METEORITES4_1": "陨石4-01",
    "COSTUMES_MIND+2022_24_METEORITES4_2": "陨石4-02",
    "COSTUMES_MIND+2022_24_METEORITES4_3": "陨石4-03",
    "COSTUMES_MIND+2022_24_METEORITES4_4": "陨石4-04",
    "SPRITES_MIND+2022_25_METEORITES5": "陨石5",
    "COSTUMES_MIND+2022_25_METEORITES5_1": "陨石5-01",
    "COSTUMES_MIND+2022_25_METEORITES5_2": "陨石5-02",
    "COSTUMES_MIND+2022_25_METEORITES5_3": "陨石5-03",
    "COSTUMES_MIND+2022_25_METEORITES5_4": "陨石5-04",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_01": "陨石大冒险A-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_02": "陨石大冒险A-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_01": "陨石大冒险B-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_02": "陨石大冒险B-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_03": "陨石大冒险B-3",
    "MIND+_FACE_SIDE": "Mind+人物表情-侧面",
    "MIND+_FACE_SIDE-1": "Mind+人物表情-侧面-1",
    "MIND+_FACE_SIDE-2": "Mind+人物表情-侧面-2",
    "MIND+_FACE_SIDE-3": "Mind+人物表情-侧面-3",
    "MIND+_FACE_SIDE-4": "Mind+人物表情-侧面-4",
    "MIND+_FACE_SIDE-5": "Mind+人物表情-侧面-5",
    "MIND+_FACE_SIDE-6": "Mind+人物表情-侧面-6",
    "MIND+_FACE_SIDE-7": "Mind+人物表情-侧面-7",
    "MIND+_FACE_SIDE-8": "Mind+人物表情-侧面-8",
    "MIND+_FACE_SIDE-9": "Mind+人物表情-侧面-9",
    "MIND+_FACE_SIDE-10": "Mind+人物表情-侧面-10",
    "MIND+_FACE_POSITIVE": "Mind+人物表情-正面",
    "MIND+_FACE_POSITIVE-1": "Mind+人物表情-正面-1",
    "MIND+_FACE_POSITIVE-2": "Mind+人物表情-正面-2",
    "MIND+_FACE_POSITIVE-3": "Mind+人物表情-正面-3",
    "MIND+_FACE_POSITIVE-4": "Mind+人物表情-正面-4",
    "MIND+_FACE_POSITIVE-5": "Mind+人物表情-正面-5",
    "MIND+_FACE_POSITIVE-6": "Mind+人物表情-正面-6",
    "MIND+_FACE_POSITIVE-7": "Mind+人物表情-正面-7",
    "MIND+_FACE_POSITIVE-8": "Mind+人物表情-正面-8",
    "MIND+_FACE_POSITIVE-9": "Mind+人物表情-正面-9",
    "MIND+_FACE_POSITIVE-10": "Mind+人物表情-正面-10",
    "MIND+_DOZE": "打瞌睡",
    "MIND+_DOZE-1": "打瞌睡-1",
    "MIND+_DOZE-2": "打瞌睡-2",
    "MIND+_DOZE-3": "打瞌睡-3",
    "MIND+_SQUAT": "蹲下起立",
    "MIND+_SQUAT-1": "蹲下起立-1",
    "MIND+_SQUAT-2": "蹲下起立-2",
    "MIND+_SQUAT-3": "蹲下起立-3",
    "MIND+_ARCHITECT": "Mind+建筑师",
    "MIND+_ARCHITECT-1": "Mind+建筑师-1",
    "MIND+_ARCHITECT-2": "Mind+建筑师-2",
    "MIND+_ARCHITECT-3": "Mind+建筑师-3",
    "MIND+_TRAFFIC_POLICE": "Mind+交警",
    "MIND+_TRAFFIC_POLICE-1": "Mind+交警-1",
    "MIND+_TRAFFIC_POLICE-2": "Mind+交警-2",
    "MIND+_TRAFFIC_POLICE-3": "Mind+交警-3",
    "MIND+_TRAFFIC_POLICE-4": "Mind+交警-4",
    "MIND+_TRAFFIC_POLICE-5": "Mind+交警-5",
    "MIND+_TRAFFIC_POLICE-6": "Mind+交警-6",
    "MIND+_POLICE": "Mind+警察",
    "MIND+_POLICE-1": "Mind+警察-1",
    "MIND+_POLICE-2": "Mind+警察-2",
    "MIND+_POLICE-3": "Mind+警察-3",
    "MIND+_POLICE-4": "Mind+警察-4",
    "MIND+_POLICE-5": "Mind+警察-5",
    "MIND+_POLICE-6": "Mind+警察-长裤1",
    "MIND+_POLICE-7": "Mind+警察-长裤2",
    "MIND+_SCIENTIST": "Mind+科学家",
    "MIND+_SCIENTIST-1": "Mind+科学家-1",
    "MIND+_SCIENTIST-2": "Mind+科学家-2",
    "MIND+_SCIENTIST-3": "Mind+科学家-3",
    "MIND+_SANTA_CLAUS": "Mind+圣诞老人",
    "MIND+_SANTA_CLAUS-1": "Mind+圣诞老人-挥手",
    "MIND+_SANTA_CLAUS-2": "Mind+圣诞老人-送礼",
    "MIND+_SANTA_CLAUS-3": "Mind+圣诞老人-舞蹈",
    "MIND+_FIREMAN": "Mind+消防员",
    "MIND+_FIREMAN-1": "Mind+消防员1",
    "MIND+_FIREMAN-2": "Mind+消防员2",
    "MIND+_FIREMAN-3": "Mind+消防员3",
    "MIND+_FIREMAN-4": "Mind+消防员4",
    "MIND+_DOCTOR": "Mind+医生",
    "MIND+_DOCTOR-1": "Mind+医生-1",
    "MIND+_DOCTOR-2": "Mind+医生-2",
    "MIND+_DOCTOR-3": "Mind+医生-3",
    "MIND+_ASTRONAUT_SPACECRAFT": "Mind+宇航员-飞船",
    "MIND+_ASTRONAUT_SPACECRAFT-1": "Mind+宇航员-飞船-1",
    "MIND+_ASTRONAUT_SPACECRAFT-2": "Mind+宇航员-飞船-2",
    "MIND+_ASTRONAUT_SPACECRAFT-3": "Mind+宇航员-飞船-3",
    "MIND+_ASTRONAUT_SPACECRAFT-4": "Mind+宇航员-飞船-4",
    "MIND+_ASTRONAUT_SPACECRAFT-5": "Mind+宇航员-飞船-5",
    "MIND+_ASTRONAUT_STANDING": "Mind+宇航员-站立",
    "MIND+_ASTRONAUT_STANDING-1": "Mind+宇航员-站立-1",
    "MIND+_ASTRONAUT_STANDING-2": "Mind+宇航员-站立-2",
    "BRUSHWOOD": "草丛",
    "BRUSHWOOD-1": "草丛-1",
    "BRUSHWOOD-2": "草丛-2",
    "BRUSHWOOD-3": "草丛-3",
    "BRUSHWOOD-4": "草丛-4",
    "STONE": "石头",
    "STONE-1": "石头-1",
    "STONE-2": "石头-2",
    "STONE-3": "石头-3",
    "STONE-4": "石头-4",
    "TREE": "树",
    "TREE-1": "树-1",
    "TREE-2": "树-2",
    "TREE-3": "树-3",
    "TREE-4": "树-4",
    "COPSE": "树丛",
    "COPSE-1": "树丛-1",
    "COPSE-2": "树丛-2",
    "COPSE-3": "树丛-3",
    "COPSE-4": "树丛-4",
    "SNOW_MOUNTAIN": "雪山",
    "SNOW_MOUNTAIN-1": "雪山-1",
    "SNOW_MOUNTAIN-2": "雪山-2",
    "SNOW_MOUNTAIN-3": "雪山-3",
    "SNOW_MOUNTAIN-4": "雪山-4",
    "CLOUD": "云",
    "CLOUD-1": "云-1",
    "CLOUD-2": "云-2",
    "CLOUD-3": "云-3",
    "CLOUD-4": "云-4",
    "CARTOON_PINK_BLUE_BUTTON_GROUP": "卡通粉蓝按钮组",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-1": "按钮-loading",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-2": "按钮-播放",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-3": "按钮-关闭",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-4": "按钮-继续挑战",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-5": "按钮-静音",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-6": "按钮-开始",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-7": "按钮-开始电源",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-8": "按钮-设置",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-9": "按钮-下一关",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-10": "按钮-音量",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-11": "按钮-暂停",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-12": "按钮-重复",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-13": "箭头-上",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-14": "箭头-下",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-15": "箭头-右",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-16": "箭头-左",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP": "科技紫按钮组",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-1": "按钮-空",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-2": "播放",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-3": "分享",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-4": "勾选",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-5": "关闭",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-6": "继续游戏",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-7": "静音",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-8": "开始游戏",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-9": "上",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-10": "设置",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-11": "下",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-12": "音乐",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-13": "游戏设置",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-14": "右",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-15": "暂停",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-16": "长方形按钮-空",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-17": "重新开始",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-18": "左",
    "DIALOG-1": "对话框-1",
    "DIALOG-2": "对话框-2",
    "DIALOG-3": "对话框-3",
    "DIALOG-4": "对话框-4",
    "DIALOG-5": "对话框-5",
    "DIALOG-6": "对话框-6",
    "DIALOG-7": "对话框-7",
    "DIALOG-8": "对话框-8",
    "DIALOG-9": "对话框-9",
    "DIALOG-10": "对话框-10",
    "Epidemic_Prevention_MIND+": "Mind+大白",
    "Epidemic_Prevention_MIND+-1": "穿防护服的Mind+1",
    "Epidemic_Prevention_MIND+-2": "穿防护服的Mind+2",
    "VIRUS": "病毒",
    "VIRUS-1": "病毒-a",
    "VIRUS-2": "病毒-b",
    "VIRUS-3": "病毒-c",
    "ANTIGEN_REAGENT": "抗原试剂",
    "ANTIGEN_REAGENT-1": "抗原试剂-横",
    "ANTIGEN_REAGENT-2": "抗原试剂-竖",
    "MASK": "口罩",
    "MASK-1": "口罩-1",
    "MASK-2": "口罩-2",
    "MASK-3": "口罩-3",
    "MASK-4": "口罩-4",
    "MASK-5": "口罩-5",
    "MASK-6": "口罩-6",
    "TEMPERATURE_MEASURING_GUN": "测温枪",
    "STETHOSCOPE": "听诊器",
    "DISINFECTANT": "消毒液",
    "NUMBER-0": "0",
    "NUMBER-1": "1",
    "NUMBER-2": "2",
    "NUMBER-3": "3",
    "NUMBER-4": "4",
    "NUMBER-5": "5",
    "NUMBER-6": "6",
    "NUMBER-7": "7",
    "NUMBER-8": "8",
    "NUMBER-9": "9",
    "LETTER-A": "A",
    "LETTER-B": "B",
    "LETTER-C": "C",
    "LETTER-D": "D",
    "LETTER-E": "E",
    "LETTER-F": "F",
    "LETTER-G": "G",
    "LETTER-H": "H",
    "LETTER-I": "I",
    "LETTER-J": "J",
    "LETTER-K": "K",
    "LETTER-L": "L",
    "LETTER-M": "M",
    "LETTER-N": "N",
    "LETTER-O": "O",
    "LETTER-P": "P",
    "LETTER-Q": "Q",
    "LETTER-R": "R",
    "LETTER-S": "S",
    "LETTER-T": "T",
    "LETTER-U": "U",
    "LETTER-V": "V",
    "LETTER-W": "W",
    "LETTER-X": "X",
    "LETTER-Y": "Y",
    "LETTER-Z": "Z",
    "EXCLAMATORY_MARK": "感叹号",
    "EXCLAMATORY_MARK-1": "感叹号-1",
    "EXCLAMATORY_MARK-2": "感叹号-2",
    "EXCLAMATORY_MARK-3": "感叹号-3",
    "YELLOW_PROGRESS_BAR": "黄色进度条",
    "YELLOW_PROGRESS_BAR-1": "黄色进度条-1",
    "YELLOW_PROGRESS_BAR-2": "黄色进度条-2",
    "YELLOW_PROGRESS_BAR-3": "黄色进度条-3",
    "PROGRESS_BAR": "进度条",
    "PROGRESS_BAR-1": "进度条30",
    "PROGRESS_BAR-2": "进度条60",
    "PROGRESS_BAR-3": "进度条100",
    "BLUE_PROGRESS_BAR": "蓝色进度条",
    "BLUE_PROGRESS_BAR-1": "蓝色进度条-1",
    "BLUE_PROGRESS_BAR-2": "蓝色进度条-2",
    "BLUE_PROGRESS_BAR-3": "蓝色进度条-3",
    "STEREOSCOPIC_QUESTION_MARK": "立体问号",
    "STEREOSCOPIC_QUESTION_MARK-1": "问号-灰",
    "STEREOSCOPIC_QUESTION_MARK-2": "问号-金",
    "BUBBLE_BOX": "气泡框",
    "BUBBLE_BOX-1": "气泡框-1",
    "BUBBLE_BOX-2": "气泡框-2",
    "VICTORY_FAIL": "胜利失败",
    "VICTORY_FAIL-1": "胜",
    "VICTORY_FAIL-2": "利",
    "VICTORY_FAIL-3": "失",
    "VICTORY_FAIL-4": "败",
    "REFERENCE_NO": "引用号",
    "REFERENCE_NO-1": "引用号-1",
    "REFERENCE_NO-2": "引用号-2",
    "RED_STRIPE": "红条",
    "GOLD_COIN1": "金币1",
    "BLUE_STRIPE": "蓝条",
    "LIFE": "生命",
    "QUADRANGULAR_STAR": "四角星",
    "QUESTION_MARK": "问号",
    "FIVE-POINTED_STAR": "五角星",
    "AIRSHIP": "小飞船1",
    "METEORITE": "陨星",
    "AIRCRAFT": "飞机",
    "AIRCRAFT-1": "飞机-1",
    "AIRCRAFT-2": "飞机-2",
    "AIRCRAFT-3": "飞机-3",
    "RETRO_AIRCRAFT": "复古飞机",
    "RETRO_AIRCRAFT-1": "复古飞机-1",
    "RETRO_AIRCRAFT-2": "复古飞机-2",
    "RETRO_AIRCRAFT-3": "复古飞机-3",
    "JEEP": "吉普车",
    "JEEP-1": "吉普车-1",
    "JEEP-2": "吉普车-2",
    "JEEP-3": "吉普车-3",
    "MOTORBOAT": "摩托艇",
    "MOTORBOAT-1": "摩托艇_侧视",
    "MOTORBOAT-2": "摩托艇_俯视",
    "MOTORBOAT-3": "摩托艇_后视",
    "MOTORBOAT-4": "摩托艇_正视",
    "SUBMARINE": "潜水艇",
    "SUBMARINE-1": "潜水艇_侧视",
    "SUBMARINE-2": "潜水艇_俯视",
    "SUBMARINE-3": "潜水艇_后视",
    "SUBMARINE-4": "潜水艇_正视",
    "SMALL_SAILBOAT": "小帆船1",
    "SMALL_SAILBOAT-1": "小帆船_侧视",
    "SMALL_SAILBOAT-2": "小帆船_俯视",
    "SMALL_SAILBOAT-3": "小帆船_后视",
    "SMALL_SAILBOAT-4": "小帆船_正视",
    "VAN": "小货车",
    "VAN-1": "小货车_侧视",
    "VAN-2": "小货车_俯视",
    "VAN-3": "小货车_后视",
    "VAN-4": "小货车_正视",
    "HELICOPTER": "直升机",
    "HELICOPTER-1": "直升机_侧视",
    "HELICOPTER-2": "直升机_俯视-1",
    "HELICOPTER-3": "直升机_俯视-2",
    "HELICOPTER-4": "直升机_俯视-3",
    "HELICOPTER-5": "直升机_后视",
    "HELICOPTER-6": "直升机_正视",
    "BICYCLE": "自行车-1",
    "FIRECRACKERS": "鞭炮",
    "FIRECRACKERS-1": "鞭炮-1",
    "FIRECRACKERS-2": "鞭炮-2",
    "FIRECRACKERS-3": "鞭炮-3",
    "COLORFUL_FLAGS": "彩旗",
    "COLORFUL_FLAGS-1": "彩旗-1",
    "COLORFUL_FLAGS-2": "彩旗-2",
    "HAPPY_KNOT": "福结",
    "HAPPY_KNOT-1": "福结-1",
    "HAPPY_KNOT-2": "福结-2",
    "HAPPY_KNOT-3": "福结-3",
    "HAPPY_KNOT-4": "福结-4",
    "HAPPY_KNOT-5": "福结-5",
    "HAPPY_KNOT-6": "福结-6",
    "RED_ENVELOPE_ANIMATION": "红包动画",
    "RED_ENVELOPE_ANIMATION-1": "红包动画-1",
    "RED_ENVELOPE_ANIMATION-2": "红包动画-2",
    "RED_ENVELOPE_ANIMATION-3": "红包动画-3",
    "RED_ENVELOPE_ANIMATION-4": "红包动画-4",
    "RED_ENVELOPE_ANIMATION-5": "红包动画-5",
    "RED_ENVELOPE_ANIMATION-6": "红包动画-6",
    "GOLD_COIN": "金币2",
    "GOLD_COIN-1": "金币-1",
    "GOLD_COIN-2": "金币-2",
    "GOLD_COIN-3": "金币-3",
    "ELK_CART": "麋鹿车",
    "ELK_CART-1": "麋鹿车-静止",
    "ELK_CART-2": "麋鹿车-行驶",
    "CALENDAR": "日历",
    "CHRISTMAS_CANDY": "圣诞彩杖糖",
    "CHRISTMAS_CANDY-1": "圣诞彩杖糖-1",
    "CHRISTMAS_CANDY-2": "圣诞彩杖糖-2",
    "CHRISTMAS_CANDY-3": "圣诞彩杖糖-3",
    "SANTA_CLAUS": "圣诞老人",
    "SANTA_CLAUS-1": "圣诞老人-1",
    "SANTA_CLAUS-2": "圣诞老人-2",
    "SANTA_CLAUS-3": "圣诞老人-3",
    "SANTA_CLAUS2": "圣诞老人2",
    "JINGLING_BELL": "圣诞铃铛",
    "JINGLING_BELL-1": "圣诞铃铛-1",
    "JINGLING_BELL-2": "圣诞铃铛-2",
    "JINGLING_BELL-3": "圣诞铃铛-金",
    "JINGLING_BELL-4": "圣诞铃铛-银",
    "CHRISTMAS_TREE": "圣诞树",
    "CHRISTMAS_TREE-1": "圣诞树-1",
    "CHRISTMAS_TREE-2": "圣诞树-2",
    "CHRISTMAS_TREE_DYNAMIC": "圣诞树-动态",
    "CHRISTMAS_TREE_DYNAMIC-1": "圣诞树-亮红灯",
    "CHRISTMAS_TREE_DYNAMIC-2": "圣诞树-亮黄灯",
    "CHRISTMAS_TREE_DYNAMIC-3": "圣诞树-全关灯",
    "CHRISTMAS_TREE_DYNAMIC-4": "圣诞树-全亮灯",
    "CHRISTMAS_STOCKING": "圣诞袜",
    "CHRISTMAS_STOCKING-1": "圣诞袜-红底绿星",
    "CHRISTMAS_STOCKING-2": "圣诞袜-绿底红星",
    "CHRISTMAS_STOCKING-3": "圣诞袜-条纹红",
    "CHRISTMAS_STOCKING-4": "圣诞袜-条纹绿",
    "CANDY": "糖果",
    "SOCKS": "袜子",
    "SMALL_WINDMILL": "小风车",
    "LITTLE_WOODEN_HORSE": "小木马",
    "SNOWFLAKE": "雪花",
    "SNOWMAN1": "雪人1",
    "SNOWMAN1-1": "雪人-初始",
    "SNOWMAN1-2": "雪人-戴帽子",
    "SNOWMAN1-3": "雪人-戴手套",
    "SNOWMAN1-4": "雪人-戴围巾",
    "SNOWMAN2": "雪人2",
    "SNOWMAN2-1": "雪人-1",
    "SNOWMAN2-2": "雪人-2",
    "SNOWMAN2-3": "雪人头",
    "REINDEER": "驯鹿",
    "REINDEER-1": "驯鹿-1",
    "REINDEER-2": "驯鹿-2",
    "REINDEER_CART": "驯鹿车",
    "REINDEER_CART-1": "驯鹿车-1",
    "REINDEER_CART-2": "驯鹿车-2",
    "ROUND_LANTERN": "圆灯笼",
    "ROUND_LANTERN-1": "圆灯笼-1",
    "ROUND_LANTERN-2": "圆灯笼-2",
    "LONG_LANTERN": "长灯笼",
    "LONG_LANTERN-1": "长灯笼-1",
    "LONG_LANTERN-2": "长灯笼-2",
    "PAPER_AIRPLANE": "纸飞机",
    "PAPER_AIRPLANE-1": "纸飞机-红",
    "PAPER_AIRPLANE-2": "纸飞机-黄",
    "PAPER_AIRPLANE-3": "纸飞机-蓝",
    "PAPER_AIRPLANE-4": "纸飞机-绿",
    "CHINESE_KNOT": "中国结",
    "CHINESE_KNOT-1": "中国结-1",
    "CHINESE_KNOT-2": "中国结-2",
    "SKIING_PEOPLE": "滑雪-人",
    "SKIING_PEOPLE-1": "滑雪-人-起跳1",
    "SKIING_PEOPLE-2": "滑雪-人-起跳2",
    "SKIING_PEOPLE-3": "滑雪-人-滑1",
    "SKIING_PEOPLE-4": "滑雪-人-滑2",
    "BOY": "男孩",
    "BOY-1": "男孩-1",
    "BOY-2": "男孩-2",
    "BOY-3": "男孩-3",
    "BOY-4": "男孩-4",
    "AVATAR_BOY": "男孩头像",
    "AVATAR_GIRL": "女孩头像",
    "GIRL": "女孩",
    "GIRL-1": "女孩-1",
    "GIRL-2": "女孩-2",
    "GIRL-3": "女孩-3",
    "GIRL-4": "女孩-4",
    "TURKEY": "火鸡",
    "GINGERBREAD_MAN": "姜饼人",
    "SKI_MARK": "滑雪痕",
    "SKI_MARK-1": "滑雪痕-1",
    "SKI_MARK-2": "滑雪痕-2",
    "SKI_MARK-3": "滑雪痕-3",
    "SKI_MARK-4": "滑雪痕-4",
    "BACKDROPS_2022_SKI-FIELD-1": "滑雪场-1",
    "BACKDROPS_2022_SKI-FIELD-2": "滑雪场-2",
    "BACKDROPS_2022_AIRPORT-RUNWAY": "机场跑道",
    "BACKDROPS_2022_INTERSECTION": "路口",
    "BACKDROPS_2022_SKY-1": "天空-1",
    "BACKDROPS_2022_SKY-2": "天空-2",
    "BACKDROPS_2022_SETTING-SUN-1": "夕阳-1",
    "BACKDROPS_2022_SETTING-SUN-2": "夕阳-2",
    "BACKDROPS_2022_SETTING-SUN-3": "夕阳-3",
    "BACKDROPS_2022_MARS": "火星",
    "BACKDROPS_2022_STARRY-SKY-1": "星空-1",
    "BACKDROPS_2022_STARRY-SKY-2": "星空-2",
    "BACKDROPS_2022_STARRY-SKY-3": "星空-3",
    "BACKDROPS_2022_MOON-1": "月球-1",
    "BACKDROPS_2022_MOON-2": "月球-2",
    "BACKDROPS_2022_SANTA-BACKGROUND": "圣诞老人背景",
    "BACKDROPS_2022_CHRISTMAS-THEME-BACKGROUND": "圣诞主题背景",
    "BACKDROPS_2022_SNOWMAN-BACKGROUND": "雪人背景",
    "WHITE-MOON": "白月亮",
    "GRASSLAND": "草地",
    "YELLOW-MOON": "黄月亮",
    "SKATEBOARD-1": "滑板-侧面",
    "SKATEBOARD-2": "滑板-底部",
    "SKATEBOARD-3": "滑板-顶部",
    "SKATEBOARD-4": "滑板-正面",
    "SKATEBOARD": "滑板",
    "CARTOON-ARTPLANES-1": "卡通飞机-正面",
    "CARTOON-ARTPLANES-2": "卡通飞机-底部",
    "CARTOON-ARTPLANES-3": "卡通飞机-右侧",
    "CARTOON-ARTPLANES-4": "卡通飞机-左侧",
    "CARTOON-ARTPLANES-5": "卡通飞机-顶部",
    "CARTOON-ARTPLANES-6": "卡通飞机-尾部",
    "CARTOON-ARTPLANES": "卡通飞机",
    "BICYCLE-GRAY-1": "自行车-灰-侧面",
    "BICYCLE-GRAY-2": "自行车-灰-顶部",
    "BICYCLE-GRAY-3": "自行车-灰-正面",
    "BICYCLE-GRAY-4": "自行车-灰-后面",
    "BICYCLE-GRAY": "自行车-灰",
    "BATS-1": "蝙蝠群-1",
    "BATS-2": "蝙蝠群-2",
    "BATS-3": "蝙蝠群-3",
    "BATS-4": "蝙蝠群-4",
    "BATS": "蝙蝠群",
    "CASTLE-1": "古堡-1",
    "CASTLE-2": "古堡-2",
    "CASTLE": "古堡",
    "JACK-o'-LANTERN-1": "南瓜灯1-1",
    "JACK-o'-LANTERN-2": "南瓜灯1-2",
    "JACK-o'-LANTERN-3": "南瓜灯1-3",
    "JACK-o'-LANTERN-4": "南瓜灯2",
    "JACK-o'-LANTERN-5": "南瓜灯3",
    "JACK-o'-LANTERN-6": "南瓜灯4",
    "JACK-o'-LANTERN": "南瓜灯",
    "WOODS-1": "树林-1",
    "WOODS-2": "树林-2",
    "WOODS": "树林",
    "DANCING-LION-RED-1": "醒狮-红-1",
    "DANCING-LION-RED-2": "醒狮-红-2",
    "DANCING-LION-RED-3": "醒狮-红-3",
    "DANCING-LION-RED-4": "醒狮-红-4",
    "DANCING-LION-RED-5": "醒狮-红-5",
    "DANCING-LION-RED-6": "醒狮-红-6",
    "DANCING-LION-RED": "醒狮-红",
    "DANCING-LION-BLUE-1": "醒狮-蓝-1",
    "DANCING-LION-BLUE-2": "醒狮-蓝-2",
    "DANCING-LION-BLUE-3": "醒狮-蓝-3",
    "DANCING-LION-BLUE": "醒狮-蓝",
    "DANCING-LION-GREEN-1": "醒狮-绿-1",
    "DANCING-LION-GREEN-2": "醒狮-绿-2",
    "DANCING-LION-GREEN-3": "醒狮-绿-3",
    "DANCING-LION-GREEN": "醒狮-绿",
    "SPECTRE-1": "幽灵-1",
    "SPECTRE-2": "幽灵-2",
    "SPECTRE-3": "幽灵-3",
    "SPECTRE-4": "幽灵-4",
    "SPECTRE": "幽灵",
    "SPIDER-1": "蜘蛛-1",
    "SPIDER-2": "蜘蛛-2",
    "SPIDER-3": "蜘蛛-3",
    "SPIDER": "蜘蛛",
    "GOLF-PEOPLE1-1": "高尔夫-人物1-1",
    "GOLF-PEOPLE1-2": "高尔夫-人物1-2",
    "GOLF-PEOPLE1-3": "高尔夫-人物1-3",
    "GOLF-PEOPLE1": "高尔夫-人物1",
    "GOLF-PEOPLE2-1": "高尔夫-人物2-1",
    "GOLF-PEOPLE2-2": "高尔夫-人物2-2",
    "GOLF-PEOPLE2-3": "高尔夫-人物2-3",
    "GOLF-PEOPLE2": "高尔夫-人物2",
    "GOLF": "高尔夫球",
    "GOLF-HOLES": "高尔夫球洞",
    "GOLF-SEATS": "高尔夫球座",
    "GREEN-LEAVES-1": "绿叶-1",
    "GREEN-LEAVES-2": "绿叶-2",
    "GREEN-LEAVES": "绿叶",
    "CACTUS-1": "仙人掌-1",
    "CACTUS-2": "仙人掌-2",
    "CACTUS": "仙人掌",
    "FLORETS-1": "小花-1",
    "FLORETS-2": "小花-2",
    "FLORETS-3": "小花-3",
    "FLORETS-4": "小花-4",
    "FLORETS-5": "小花-5",
    "FLORETS-6": "小花-6",
    "FLORETS-7": "小花-7",
    "FLORETS-8": "小花-8",
    "FLORETS": "小花"
  },
  "zh-tw": {
    "gui.gui.project": "項目",
    "gui.gui.newItem": "新建項目",
    "gui.gui.load": "打開項目",
    "gui.gui.iotDashboard": "可视化面板",
    "gui.gui.save": "保存項目",
    "gui.gui.saveAs": "另存項目",
    "gui.gui.saveAsMp": "另存为mp项目",
    "gui.gui.saveAsSb3": "另存为sb3项目",
    "gui.gui.cacheList": "緩存列表",
    "gui.gui.inCache": "緩存中",
    "gui.gui.learn": "教程",
    "gui.gui.clearRecord": "清空記錄",
    "gui.gui.onlineLearn": "官方文檔",
    "gui.gui.onlineForum": "在線論壇",
    "gui.gui.videoTutorial": "視頻教程",
    "gui.gui.offlineLearn": "示例程序",
    "gui.menuBar.turboModeOff": "關閉加速模式",
    "gui.menuBar.turboModeOn": "打開加速模式",
    "gui.menuBar.edit": "編輯",
    "gui.menuBar.restoreSprite": "復原刪除的角色",
    "gui.menuBar.restoreSound": "復原刪除的聲音",
    "gui.menuBar.restoreCostume": "復原刪除的造型",
    "gui.menuBar.restoreBackdrop": "復原刪除的背景",
    "gui.editorMind.restore": "恢復刪除",
    "gui.turboMode.active": "加速模式",
    "gui.gui.connectDevice": "連接設備",
    "gui.gui.disconnectDevice": "斷開設備",
    "gui.gui.installSerialportDriver": "一鍵安裝串口驅動",
    "gui.gui.openInstallSerialportDriverCourse": "查看驅動安裝教程",
    "gui.gui.openDM": "打開設備管理器",
    "gui.gui.restoreSetting": "恢復設備初始設置",
    "gui.gui.updateTips": "更新提示",
    "gui.gui.newVersion": "新版本",
    "gui.gui.downloadUpdate": "下載更新",
    "gui.gui.versionUpdate": "版本更新",
    "gui.gui.isCheckMd5": "正在校驗",
    "gui.gui.downloading": "检查更新中",
    "gui.gui.updateError": "更新錯誤",
    "gui.setting.feedbackMenu": "意見反饋",
    "gui.gui.arduino": "上傳模式",
    "gui.gui.scratch": "實時模式",
    "gui.gui.python": "Python模式",
    "gui.setting.officialWebsit": "官方網址",
    "gui.setting.officialQQGroup": "官方QQ交流群",
    "gui.setting.wechatOfficialAccounts": "官方微信公眾號",
    "gui.setting.currentVersion": "當前版本",
    "gui.setting.checkForUpdate": "檢查更新",
    "gui.setting.remindWhenUpdate": "有更新時自動下載",
    "gui.setting.currentIsLatest": "當前已是最新版本",
    "gui.setting.latestVersion": "最新版本",
    "gui.setting.download": "跳轉到下載頁面",
    "gui.setting.language": "語言(Language)",
    "gui.setting.uploadCsv": "上傳xlsx",
    "gui.setting.theme": "主題",
    "gui.setting.feedback": "反饋意見",
    "gui.setting.email": "電子郵箱(E-mail)",
    "gui.setting.opinionFeedback": "意見/反饋",
    "gui.variableMc.variableName": "變量名稱",
    "gui.variableMc.variableOff": "取消",
    "gui.variableMc.variableOn": "確定",
    "gui.variableMc.newVariable": "新建變量",
    "gui.setting.helpUsTranslate": "幫助我們翻譯",
    "gui.setting.checkUpdate": "檢查更新",
    "gui.setting.ok": "提交",
    "gui.setting.languageSetting": "語言",
    "gui.setting.themeSetting": "主題",
    "gui.setting.cacheSetting": "緩存",
    "gui.setting.versionUpdate": "版本更新",
    "gui.setting.connectUsTitle": "聯繫我們",
    "gui.setting.uploadAttachment": "上傳附件",
    "gui.setting.displayTitle": "顯示設置",
    "gui.setting.fontSizeSetting": "字體字號",
    "gui.setting.restartEffect": "重啟生效",
    "gui.setting.fontSizeLarge": "大",
    "gui.setting.fontSizeMiddle": "中",
    "gui.setting.fontSizeSmall": "小",
    "gui.setting.onlineRepair": "在线修复工具，点击打开 >",
    "gui.setting.cacheSwitch": "緩存(打開/關閉)",
    "gui.setting.freeCache": "空閒緩存",
    "gui.setting.spaceCache": "固定間隔時間",
    "gui.setting.cacheWay": "緩存方式",
    "gui.setting.interval": "時間間隔",
    "gui.setting.freeTime": "空閒",
    "gui.setting.second": "秒",
    "gui.setting.minute": "分鐘",
    "gui.gui.code": "代碼",
    "gui.menubar.cloudProject": "雲端項目",
    "gui.menubar.login": "登入",
    "gui.menubar.signout": "退出登入",
    "gui.menubar.saveToCloud": "保存到雲端",
    "gui.menubar.cloudServer": "雲端",
    "gui.setting.cleanCache": "資料清理",
    "gui.setting.programCache": "項目緩存",
    "gui.setting.configJson": "設定檔",
    "gui.setting.pythonEnv": "Python環境",
    "gui.setting.backpack": "書包文件 Schoolbag File",
    "gui.setting.compile": "編譯資訊",
    "gui.setting.thirdExt": "擴展用戶庫",
    "gui.setting.dashboard": "可视化面板",
    "gui.setting.cleanCacheBtn": "清除選擇項",
    "gui.setting.cleanCachePrompt": "資料清除後無法恢復，確定清除嗎？",
    "gui.setting.cleanPythonEnvPrompt": "為避免Python環境清除引起其他視窗異常，清除前請保證僅打開了一個Mind+軟體視窗。",
    "gui.setting.cleanPyEnvOnPythonPrompt": "Python模式下，清理資源後需重置環境，此過程需要等待一段時間。且為避免清除資源引起其他視窗異常，清除前請保證僅打開了一個Mind+軟體視窗。",
    "gui.setting.cleanDashboardPrompt": "将清除可视化面板中的所有项目，清除后不可恢复。",
    "gui.setting.expVersion": "體驗版",
    "gui.setting.expUpdateTips": "【更新須知】: 該版本為尚未正式發佈的嘗鮮版，部分功能可能不穩定，請確認後再下載;",
    "gui.setting.cleanConfigSuccess": "資料清理完成，請重啟Mind+。",
    "gui.setting.cleanConfigError": "資料清理過程中出現錯誤，請重新嘗試一次",
    "gui.setting.cleanPartError": "清理[PART]出錯......",
    "gui.setting.cleaningPyEnv": "正在清理Python環境.....",
    "gui.setting.cleanPythonEnvError": "Python環境資源釋放失敗，你可以關閉軟體，手動刪除資料夾 [PATH] 後，再重新打開軟體",
    "gui.setting.multiWindowSetting": "软件多开",
    "gui.setting.multiWindowSettingTips": "为避免同时打开多个软件导致系统卡顿，性能较弱的电脑推荐关闭该功能",
    "gui.setting.multiWindowTrue": "开",
    "gui.setting.multiWindowFalse": "关",
    "gui.setting.systemSetting": "系统设置",
    "gui.backpack.header": "背包",
    "gui.backpack.errorBackpack": "載入背包時發生錯誤",
    "gui.backpack.loadingBackpack": "正在載入…",
    "gui.backpack.more": "更多資訊",
    "gui.backpack.emptyBackpack": "背包空無一物",
    "gui.gui.blocks": "模塊",
    "gui.gui.costumesTab": "造型",
    "gui.gui.soundsTab": "音效",
    "gui.gui.backdropsTab": "背景",
    "gui.gui.addExtension": "添加擴展",
    "gui.costumeTab.addCostumeFromLibrary": "選個造型",
    "gui.costumeLibrary.chooseACostume": "範例造型",
    "gui.costumeTab.addBackdropFromLibrary": "選個背景",
    "gui.costumeTab.addBlankCostume": "繪畫",
    "gui.costumeTab.addSurpriseCostume": "驚喜",
    "gui.costumeTab.addFileBackdrop": "上傳",
    "gui.costumeTab.addFileCostume": "上傳",
    "gui.costumeTab.addCameraCostume": "拍照",
    "gui.sliderModal.min": "最小值",
    "gui.sliderModal.max": "最大值",
    "gui.sliderModal.title": "改变滑块范围",
    "gui.soundEditor.sound": "音效",
    "gui.soundEditor.play": "播放",
    "gui.soundEditor.stop": "停止",
    "gui.soundEditor.trim": "裁剪",
    "gui.soundEditor.save": "保存",
    "gui.soundEditor.undo": "復原",
    "gui.soundEditor.redo": "重做",
    "gui.soundEditor.faster": "快播",
    "gui.soundEditor.slower": "慢播",
    "gui.soundEditor.echo": "回音",
    "gui.soundEditor.robot": "機器",
    "gui.soundEditor.louder": "響亮",
    "gui.soundEditor.softer": "輕柔",
    "gui.soundEditor.reverse": "反轉",
    "gui.soundEditor.copy": "复制",
    "gui.soundEditor.paste": "粘贴",
    "gui.soundEditor.copyToNew": "新拷贝",
    "gui.soundEditor.delete": "删除",
    "gui.soundEditor.fadeOut": "渐弱",
    "gui.soundEditor.fadeIn": "渐强",
    "gui.soundEditor.mute": "静音",
    "gui.soundTab.recordSound": "錄製",
    "gui.soundTab.addSoundFromLibrary": "選個音效",
    "gui.soundTab.surpriseSound": "驚喜",
    "gui.soundTab.fileUploadSound": "上傳",
    "gui.soundTab.addSound": "添加聲音",
    "gui.controls.stop": "停止",
    "gui.controls.go": "開始",
    "gui.controls.customFirmware": "自定义固件",
    "gui.controls.defaultFirmware": "官方固件",
    "gui.controls.clearLocaleFirmware": "清空记录",
    "gui.controls.checkForUpdates": "检查更新",
    "gui.controls.alreadyLastVersion": "已经是最新了",
    "gui.controls.uploadFirmware": "本地加载",
    "gui.gui.startPython": "開始運行python",
    "gui.gui.stopPython": "停止運行python",
    "gui.controls.fullScreenControl": "舞台全屏",
    "gui.gui.stageSizeLarge": "大舞台模式",
    "gui.gui.stageSizeSmall": "小舞台模式",
    "gui.gui.stageSizeNostage": "無舞台模式",
    "gui.gui.stageSizeFull": "舞台全屏",
    "gui.stageHeader.stageSizeUnFull": "離開全螢幕模式",
    "gui.sprite.sprite": "精靈",
    "gui.SpriteInfo.show": "顯示",
    "gui.SpriteInfo.size": "尺寸",
    "gui.sprite.direction": "方向",
    "gui.sprite.rotation": "角度",
    "gui.directionPicker.rotationStyles.allAround": "不設限",
    "gui.directionPicker.rotationStyles.leftRight": "左-右",
    "gui.directionPicker.rotationStyles.dontRotate": "不旋轉",
    "gui.spriteSelectorItem.contextMenuDuplicate": "複製",
    "gui.spriteSelectorItem.contextMenuDelete": "刪除",
    "gui.spriteSelectorItem.contextMenuExport": "匯出",
    "gui.sprite.addSpriteFromLibrary": "角色庫",
    "gui.spriteSelector.addSpriteFromPaint": "繪畫",
    "gui.spriteSelector.addSpriteFromSurprise": "驚喜",
    "gui.spriteSelector.addSpriteFromFile": "上傳",
    "gui.sprite.addSpriteFromCamera": "攝像",
    "gui.stageSelector.stage": "舞台",
    "gui.stageSelector.backdrops": "背景",
    "gui.stage.addBackdropFromLibrary": "背景庫",
    "gui.stageSelector.addBackdropFromPaint": "繪畫",
    "gui.stageSelector.addBackdropFromSurprise": "驚喜",
    "gui.stageSelector.addBackdropFromFile": "上傳",
    "gui.stage.addBackdropFromCamera": "相機",
    "gui.modal.help": "帮助",
    "gui.modal.save": "保存",
    "gui.modal.run": "运行",
    "gui.modal.paused": "暂停",
    "gui.modal.continue": "继续",
    "gui.modal.back": "返回",
    "gui.extension.kit": "套件",
    "gui.extension.kitTitle": "選擇套件",
    "gui.extension.board": "主控板",
    "gui.extension.boardTitle": "選擇主控板",
    "gui.extension.spreadBoard": "擴展板",
    "gui.extension.spreadBoardTitle": "選擇擴展板",
    "gui.extension.sensor": "傳感器",
    "gui.extension.sensorTitle": "選擇傳感器",
    "gui.extension.actuator": "執行器",
    "gui.extension.actuatorTitle": "選擇執行器",
    "gui.extension.communicationModule": "通信模塊",
    "gui.extension.communicationModuleTitle": "選擇通信模塊",
    "gui.extension.display": "顯示器",
    "gui.extension.displayTitle": "選擇顯示器",
    "gui.extension.function": "功能模塊",
    "gui.extension.functionTitle": "選擇功能模塊",
    "gui.extension.internetService": "網絡服務",
    "gui.extension.internetServiceTitle": "選擇網絡服務",
    "gui.extension.thirdExpansion": "用户库",
    "gui.extension.thirdExpansionTitle": "选择用户库",
    "gui.extension.arduinContentLabel": "選擇設備",
    "gui.extension.openUserExFile": "打开你计算机上的用户库文件",
    "gui.extension.importUserEx": "导入用户库",
    "gui.extension.unavailable": "不可用",
    "gui.extension.thirdModulesHelp": "注意：用户库中的模块由Mind+爱好者制作，{clickHelp}查看{tutorial}以及{list}",
    "gui.extension.thirdTutorial": "开发教程",
    "gui.extension.thirdList": "用户库列表",
    "gui.extension.thirdModuleSearch": "搜索或输入项目网址",
    "gui.extension.libaryOffice": "官方库",
    "gui.extension.libaryPinpong": "pinpong库",
    "gui.library.filterPlaceholder": "搜尋",
    "gui.libraryTags.all": "全部",
    "gui.libraryTags.animals": "動物",
    "gui.libraryTags.dance": "舞蹈",
    "gui.libraryTags.effects": "效果",
    "gui.libraryTags.fantasy": "魔幻",
    "gui.libraryTags.fashion": "時尚",
    "gui.libraryTags.food": "食物",
    "gui.libraryTags.festival": "節日",
    "gui.libraryTags.traffic": "交通",
    "gui.libraryTags.indoors": "室內",
    "gui.libraryTags.loops": "循環",
    "gui.libraryTags.music": "音樂",
    "gui.libraryTags.notes": "音階",
    "gui.libraryTags.outdoors": "戶外",
    "gui.libraryTags.patterns": "圖案",
    "gui.libraryTags.people": "人物",
    "gui.libraryTags.percussion": "節奏",
    "gui.libraryTags.space": "太空",
    "gui.libraryTags.sports": "運動",
    "gui.libraryTags.underwater": "水中",
    "gui.libraryTags.voice": "人聲",
    "gui.libraryTags.wacky": "滑稽",
    "gui.libraryTags.animation": "動畫",
    "gui.libraryTags.art": "藝術",
    "gui.libraryTags.games": "遊戲",
    "gui.libraryTags.stories": "故事",
    "gui.libraryTags.letters": "字母",
    "gui.libraryTags.mind": "Mind+",
    "gui.libraryTags.natural": "自然",
    "gui.libraryTags.button": "按钮",
    "gui.libraryTags.dialog": "对话框",
    "gui.libraryTags.epidemicPrevention": "防疫",
    "gui.libraryTags.numberAndLetter": "数字&字母",
    "gui.libraryTags.gameComponents": "游戏组件",
    "gui.extension.backdropLib": "選擇背景",
    "gui.soundLibrary.chooseASound": "範例音效",
    "gui.SpriteInfo.spritePlaceholder": "名稱",
    "gui.extension.spriteLib": "選擇角色",
    "gui.gui.unselectedSerialport": "未連接設備",
    "gui.gui.unConnectedDev": "未連接設備",
    "gui.gui.pythonMode": "Python程序模式",
    "gui.gui.burnFirmware": "重新燒錄固件",
    "gui.gui.burnFirmwareError": "燒錄固件出錯",
    "gui.gui.connected": "連接設備成功",
    "gui.gui.failedConnected": "連接設備失敗",
    "gui.gui.connecting": "正在連接設備...",
    "gui.cards.all-how-tos": "全部教程",
    "gui.cards.how-tos": "入門教程",
    "gui.cards.remove": "退出教程",
    "gui.cards.more-things-to-try": "試試其它東西！",
    "gui.cards.see-more": "觀看更多",
    "gui.cards.shrink": "縮小",
    "gui.cards.expand": "放大",
    "gui.cards.close": "關閉",
    "gui.loader.message1": "正在創建積木…",
    "gui.loader.message2": "正在載入角色…",
    "gui.loader.message3": "正在載入音效…",
    "gui.loader.message4": "正在載入擴展…",
    "gui.loader.message5": "Herding cats …",
    "gui.loader.message6": "Transmitting nanos …",
    "gui.loader.message7": "Inflating gobos …",
    "gui.loader.message8": "正在準備表情符號…",
    "gui.loader.headline": "正在載入專案",
    "gui.cameraModal.cameraModalTitle": "拍張照片",
    "gui.cameraModal.loadingCameraMessage": "載入攝影裝置中…",
    "gui.cameraModal.permissionRequest": "我們需要你的同意來使用你的相機",
    "gui.cameraModal.retakePhoto": "重新拍照",
    "gui.cameraModal.save": "儲存",
    "gui.cameraModal.takePhoto": "拍照",
    "gui.cameraModal.loadingCaption": "正在載入…",
    "gui.cameraModal.enableCameraCaption": "啟用攝影裝置",
    "gui.recordModal.title": "錄音",
    "gui.recordStep.recordByClickBtn": "點擊下面的按鈕開始錄製聲音",
    "gui.recordStep.permissionMicrophone": "我們需要您的許可才能使用您的麥克風",
    "gui.recordStep.record": "錄製",
    "gui.recordStep.stopRecord": "停止錄製",
    "gui.playbackStep.stopMsg": "停止",
    "gui.playbackStep.playMsg": "播放",
    "gui.playbackStep.loadingMsg": "正在載入…",
    "gui.playbackStep.saveMsg": "儲存",
    "gui.playbackStep.reRecordMsg": "重新錄音",
    "gui.webglModal.label": "你的瀏覽器不支援 WebGL",
    "gui.webglModal.descriptionMind": "很不幸你的電腦 {webGlLink}. Mind+需要運行WebGL, {updateGpuDriver}",
    "gui.webglModal.restartMindplus": "重启Mind+",
    "gui.webglModal.clickToRestart": "点此重启",
    "gui.webglModal.webgllink": "不支援 WebGL",
    "gui.webglModal.updateGpuDriver": "請更新顯卡驅動",
    "gui.webglModal.ok": "確定",
    "gui.extension.scratchExampleLib": "示例程序-實時執行",
    "gui.extension.pythonExampleLib": "示例程序-Python",
    "gui.extension.arduinoExampleLib": "示例程序-上傳執行",
    "gui.prompt.cancel": "取消",
    "gui.prompt.ok": "確定",
    "gui.extension.makeBlock": "添加一個自定義模塊",
    "gui.customProcedures.addAnInputNumberText": "添加輸入方塊",
    "gui.customProcedures.addAnInputBoolean": "添加輸入方塊",
    "gui.customProcedures.numberTextType": "數字或文字",
    "gui.customProcedures.textType": "文本值",
    "gui.customProcedures.numberType": "数字值",
    "gui.customProcedures.booleanType": "布林值",
    "gui.customProcedures.addALabel": "添加說明文字",
    "gui.customProcedures.runWithoutScreenRefresh": "執行完畢再更新畫面",
    "gui.customProcedures.cancel": "取消",
    "gui.customProcedures.ok": "確定",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "DFRobot出品的Vortex教育機器人",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "DFRobot出品的探索者D1教育機器人",
    "gui.extension.arduinounoR3.name": "arduino uno開源硬件套件",
    "gui.extension.arduinounoR3.description": "與武漢創客課程結合基於arduino uno主控板的套件。",
    "gui.extension.maxbot.name": "Max:bot小車",
    "gui.extension.maxbot.description": "基於micro:bit平台的機器人小車",
    "gui.extension.max.name": "MAX小車",
    "gui.extension.max.description": "基於Arduino平台機器人小車",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "microbit 電機舵機專用擴展板",
    "gui.extension.maqueen.name": "micro:Maqueen（麥昆）",
    "gui.extension.maqueen.description": "基於micro:bit平台的入門級迷你機器人小車",
    "gui.extension.spreadEsp32e.name": "firebeetle esp32-e扩展板",
    "gui.extension.spreadEsp32e.description": "firebeetle esp32-e扩展板",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.microbit.description": "讓你的專案與實體世界連結。",
    "gui.extension.calliope.description": "把作品连接到实体世界。",
    "gui.extension.calliope.name": "CALLIOPE mini",
    "gui.extension.spread.name": "micro:bit&掌控擴展板",
    "gui.extension.spreadboson.name": "micro:bit&掌控扩展板",
    "gui.extension.microTouch.name": "鍵盤擴展板",
    "gui.extension.microNaturalScience.name": "自然主题扩展板",
    "gui.extension.microNaturalScienceV2.name": "自然主题扩展板V2.0",
    "gui.extension.microNaturalScienceV2.description": "集成丰富自然环境相关传感器的microbit学习产品。",
    "gui.extension.microNaturalScienceV2.request": "请求数据",
    "gui.extension.microNaturalScienceV2.readSoilValue": "[PIN] 引脚获取土壤湿度",
    "gui.extension.microNaturalScienceV2.readCO2TVOC": "[TYPE]",
    "gui.extension.microNaturalScienceV2.setBme280": "设置TVOC和CO2的基线[NUM]值",
    "gui.extension.microNaturalScienceV2.OLEDShowString": "OLED从 [NUM1] 到 [NUM2] 列在第 [NUM3] 行显示字符串 [STR]",
    "gui.extension.microNaturalScienceV2.OLEDShowNumber": "OLED从 [NUM1] 到 [NUM2] 列在第 [NUM3] 行显示数字 [STR]",
    "gui.extension.microNaturalScienceV2.OLEDClearXY": "清除OLED从 [NUM1] 到 [NUM2] 列在第 [NUM3] 行",
    "gui.extension.microNaturalScienceV2.OLEDClear": "清除OLED第 [NUM3] 行",
    "gui.extension.microNaturalScienceV2.MotorRun": "控制电机方向 [DIR] 速度[SPEED]",
    "gui.extension.microNaturalScienceV2.MotorStop": "控制停止",
    "gui.extension.microNaturalScienceV2.RGBSetBackground": "设置RGB亮度为 [NUM]",
    "gui.extension.microNaturalScienceV2.RGBShowColorFromWith": "RGB显示颜色 [COLOR]",
    "gui.extension.microNaturalScienceV2.RGBShowNumColorFromWith": "RGB灯[NUM]显示颜色 [COLOR]",
    "gui.extension.microNaturalScienceV2.RGBRange": "RGB范围从 [NUM1] 到 [NUM2]",
    "gui.extension.microNaturalScienceV2.RGBShowMulticolor": "设置RGB显示彩虹色从 [NUM1] 到 [NUM2]",
    "gui.extension.microNaturalScienceV2.RGBclear": "关闭全部RGB",
    "gui.extension.microNaturalScienceV2.IFTTTConnect": "IFTTT 配置 事件: [EVENT] 密码: [PASSWORD]",
    "gui.extension.microNaturalScienceV2.WIFIConnect": "Wi-Fi 配置 名称: [SSID] 密码: [PASSWORD] 启动连接",
    "gui.extension.microNaturalScienceV2.IFTTTSend": "IFTTT 发送 值1: [VAL1] 值2: [VAL2] 值3: [VAL3]",
    "gui.extension.microNaturalScienceV2.ThingSpeakConfigure": "ThingSpeak 配置 密钥: [SSID]",
    "gui.extension.microNaturalScienceV2.ThingSpeakSend": "ThingSpeak 发送 值1:[VALUE1]值2:[VALUE2]值3:[VALUE3]值4:[VALUE4]值5:[VALUE5]值6:[VALUE6]值7:[VALUE7]值8:[VALUE8]",
    "gui.extension.microNaturalScienceV2.ThingSpeakPrepareSend": "ThingSpeak 预发送 值[VALUE1] ： [VALUE2]",
    "gui.extension.microNaturalScienceV2.ThingSpeakRealSend": "ThingSpeak 发送",
    "gui.extension.microNaturalScienceV2.obloq_initialParameter": "MQTT 初始化 [PARAMETER]",
    "gui.extension.microNaturalScienceV2.sendmsgToCloudPlatform": "发送消息 [MSG] 到 [TOPIC]",
    "gui.extension.microNaturalScienceV2.addToCloudPlatform": "新增订阅 [TOPIC] : [MSG]",
    "gui.extension.microTouch.description": "集成4×4鍵盤和電機舵機驅動的擴展板",
    "gui.extension.microNaturalScience.description": "集成多种环境传感器的micro:bit扩展板",
    "gui.extension.microIoT.name": "物聯網主題擴展板",
    "gui.extension.microIoT.description": "集成顯示屏和物聯網功能的micro:bit擴展板",
    "gui.extension.microIoTCloud.name": "物联网主题扩展板",
    "gui.extension.microIoTCloud.description": "支持插WiFi卡联网的micro:bit扩展板，为物联网课堂教学提供了出色的解决方案",
    "gui.extension.spreadmaqueen.name": "麦昆扩展板",
    "gui.extension.spread.description": "使用micro:bit和掌控板搭配的I/O擴展板（帶電機驅動）",
    "gui.extension.spreadboson.description": "使用micro:bit和掌控板搭配的I/O扩展板（带电机驱动）",
    "gui.extension.spreadmaqueen.description": "使用掌控版搭配的入門級迷你機器人小車",
    "gui.extension.maqueenPlus.name": "麦昆Plus",
    "gui.extension.maqueenPlus.description": "支持micro:bit和掌控两种主板的进阶款教育机器人",
    "gui.extension.maqueenPlusV2.name": "麦昆Plus V2",
    "gui.extension.maqueenPlusV2.description": "支持micro:bit的新版麦昆plus款教育机器人",
    "gui.extension.pinpongBread.name": "PinPong扩展板",
    "gui.extension.pinpongBread.description": "在ArduinoUNO上进行Python学习的扩展板",
    "gui.extension.iot.name": "物聯網主題擴展板",
    "gui.extension.iot.description": "基於物聯網的擴展版",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Leonardo主控板控制的設備",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Uno主控板控制的設備",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Arduino Nano主控板控制的設備",
    "gui.extension.mpython.name": "掌控板",
    "gui.extension.mpython.description": "基於ESP32的主控板",
    "gui.extension.telloesp32.name": "RoboMaster TT(ESP32)",
    "gui.extension.telloesp32.description": "搭載大疆頂尖飛控演算法的教育無人機，極強的安全性與穩定性",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Mega 2560主控板控制的設備",
    "gui.extension.firebeetleesp32.name": "FireBeetle ESP32",
    "gui.extension.firebeetleesp32e.name": "FireBeetle ESP32-E",
    "gui.extension.firebeetleesp32.description": "FireBeetle ESP32 主控板控制的设备",
    "gui.extension.dfr0299.name": "DFPlayer MP3模塊",
    "gui.extension.dfr0299.description": "播放MP3、WAV格式音頻",
    "gui.extension.ser0006.name": "舵機模塊",
    "gui.extension.ser0006.description": "可以在0～180度間轉動到指定角度位置",
    "gui.extension.dfr0523.name": "蠕動泵",
    "gui.extension.dfr0523.description": "對彈性泵管交替擠壓、釋放來輸送流體",
    "gui.extension.dfr0017.name": "繼電器",
    "gui.extension.dfr0017.description": "用於控制大電流設備",
    "gui.extension.steppermotor.name": "步進電機",
    "gui.extension.steppermotor.description": "應用於3D打印機、移動機器人、中型移動平台等",
    "gui.extension.dfr0534.name": "串口MP3模塊",
    "gui.extension.dfr0534.description": "通過串口指令播放音樂，支持MP3、WAV、WMA",
    "gui.extension.servo360.name": "360度微型舵機",
    "gui.extension.servo360.description": "可控制旋轉方向、速度，不能控制角度",
    "gui.extension.tel0118.name": "OBLOQ物聯網模塊",
    "gui.extension.tel0118.description": "通過WIFI接收和發送物聯網信息",
    "gui.extension.dfr0095.name": "紅外發射模塊",
    "gui.extension.dfr0095.description": "可發射標準38KHz調製的紅外信號",
    "gui.extension.dfr0094.name": "紅外接收模塊",
    "gui.extension.dfr0094.description": "可接收標準38KHz調製的紅外信號",
    "gui.extension.midi.name": "MIDI",
    "gui.extension.midi.description": "MIDI播放器",
    "gui.extension.radio.name": "无线广播",
    "gui.extension.radio.description": "多个主板之间进行无线广播通讯",
    "gui.extension.tel0094.name": "GPS信號接收模塊",
    "gui.extension.tel0094.description": "GPS信號接收模塊",
    "gui.extension.tel0026.name": "藍牙模塊",
    "gui.extension.tel0026.description": "藍牙串口模塊",
    "gui.extension.dfr0486.name": "OLED-2864顯示屏",
    "gui.extension.dfr0647.name": "OLED-12832显示屏",
    "gui.extension.dfr0486.description": "小體積顯示屏，可用於智能穿戴設備的顯示器",
    "gui.extension.dfr0647.description": "小体积显示屏，可用于智能穿戴设备的显示器",
    "gui.extension.fit0352.name": "WS2812 RGB燈",
    "gui.extension.fit0352.description": "控制LED燈條變化產生如閃爍、流水、跳變等效果",
    "gui.extension.dfr0063.name": "LCD1602模塊（I2C接口）",
    "gui.extension.dfr0063.description": "液晶顯示器，可顯示2行，每行16個字符",
    "gui.extension.dfr0021.name": "數字LED發光模塊",
    "gui.extension.dfr0021.description": "高電平燈亮，低電平燈滅",
    "gui.extension.tm1650.name": "TM1650四位數碼管",
    "gui.extension.tm1650.description": "顯示模塊，可用於計時器、儀表控制等",
    "gui.extension.dfr0646.name": "TM1650八位数码管",
    "gui.extension.dfr0646.description": "显示模块，I2C接口，可用于计时器、仪表控制等",
    "gui.extension.matrix8_8.name": "MAX7219 8x8點陣",
    "gui.extension.matrix8_8.description": "迷你點陣顯示模塊",
    "gui.extension.dfr0522.name": "8×16 RGB LED點陣表情板",
    "gui.extension.dfr0522.description": "可用做機器人表情面板、彩色頻譜儀等",
    "gui.extension.music.name": "音樂",
    "gui.extension.music.description": "演奏樂器與節拍。",
    "gui.extension.pen.name": "畫筆",
    "gui.extension.pen.description": "使用你的角色來畫圖。",
    "gui.extension.video-sensing.name": "視頻偵測",
    "gui.extension.video-sensing.description": "用相機檢測動作.",
    "gui.extension.google-translate.name": "谷歌翻譯",
    "gui.extension.google-translate.description": "將文字翻譯成多種語言.",
    "gui.extension.bd-translate.name": "百度翻译",
    "gui.extension.bd-translate.description": "使用百度翻译服务将文字翻译成其他语言。",
    "gui.extension.text-speech.name": "文字朗讀",
    "gui.extension.text-speech.description": "讓你的項目開口說話",
    "gui.extension.softSerialport.name": "軟串口",
    "gui.extension.softSerialport.description": "使用I/O口模擬串口通訊功能",
    "gui.extension.IICScan.name": "I2C地址掃描",
    "gui.extension.IICScan.description": "掃描當前所有接到I2C接口上設備地址",
    "gui.extension.bluetooth.name": "藍牙",
    "gui.extension.bluetooth.description": "藍牙設備",
    "gui.extension.goble.name": "戈布爾",
    "gui.extension.goble.description": "搭配GoBle（走你）APP使用，可實現手機APP控制主控板",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "將數據保存到eeprom",
    "gui.extension.interrupt.name": "引腳中斷",
    "gui.extension.interrupt.description": "引腳中斷",
    "gui.extension.mpyfile.name": "板载文件",
    "gui.extension.mpyfile.description": "读写板载文件，可以存储数据和导出",
    "gui.extension.sdcard.name": "SD讀卡器模塊",
    "gui.extension.sdcard.description": "MicroSD卡 讀卡器模塊",
    "gui.extension.multithread.name": "多線程",
    "gui.extension.multithread.description": "允許多個程序與主程序同時運行",
    "gui.extension.speech.name": "言語",
    "gui.extension.speech.description": "與你的項目交談.",
    "gui.extension.sen0001.name": "超聲波測距傳感器",
    "gui.extension.bos0001.name": "旋钮模块",
    "gui.extension.bos0002.name": "按钮模块",
    "gui.extension.bos0003.name": "自锁开关",
    "gui.extension.bos0004.name": "环境光传感器",
    "gui.extension.bos0006.name": "水分传感器",
    "gui.extension.bos0007.name": "火焰传感器",
    "gui.extension.bos0008.name": "触摸传感器",
    "gui.extension.bos0009.name": "声音传感器",
    "gui.extension.bos0010.name": "智能灰度传感器",
    "gui.extension.bos0012.name": "电导开关",
    "gui.extension.bos0013.name": "运动传感器",
    "gui.extension.bos0038.name": "土壤湿度传感器",
    "gui.extension.bos0045.name": "超声波距离传感器",
    "gui.extension.bos0016.name": "高亮LED模块",
    "gui.extension.bos0017.name": "LED模块",
    "gui.extension.bos0019.name": "彩色灯带",
    "gui.extension.bos0021.name": "风扇模块",
    "gui.extension.bos0022.name": "录音模块",
    "gui.extension.bos0001.description": "模拟传感器，可检测旋转位置",
    "gui.extension.bos0002.description": "按下输出高电平，松开输出低电平",
    "gui.extension.bos0003.description": "按下状态输出高电平，弹起状态输出低电平",
    "gui.extension.bos0004.description": "检测环境光强度",
    "gui.extension.bos0006.description": "检测雨水、雾水，不可整体浸入水中",
    "gui.extension.bos0007.description": "探测火源或波长760~1100nm的光源",
    "gui.extension.bos0008.description": "触摸开关，可感应人体，金属等",
    "gui.extension.bos0009.description": "检测声音强度",
    "gui.extension.bos0010.description": "检测不同颜色产生的信号",
    "gui.extension.bos0012.description": "检测物体是否导体",
    "gui.extension.bos0013.description": "检测运动的人或动物身上发出的红外线",
    "gui.extension.bos0038.description": "检测土壤水分，水分越少输出值越小",
    "gui.extension.bos0045.description": "模拟距离传感器，检测范围2cm~1m",
    "gui.extension.bos0016.description": "白色发光模块",
    "gui.extension.bos0017.description": "发光二极管，能发出对应灯珠颜色的灯光",
    "gui.extension.bos0019.description": "七彩灯带，灯带长3米，颜色不能改变",
    "gui.extension.bos0021.description": "驱动扇叶旋转的模块",
    "gui.extension.bos0022.description": "可以录制一段10s的声音和播放录音",
    "gui.extension.sen0001.description": "準確測距，量程2～800cm",
    "gui.extension.dfr0023.name": "LM35線性模擬溫度傳感器",
    "gui.extension.dfr0023.description": "測量溫度，範圍0～100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22溫濕度傳感器",
    "gui.extension.dhtTHSensor.description": "測量環境溫度、濕度",
    "gui.extension.dsTSensor.name": "DS18B20溫度傳感器",
    "gui.extension.dsTSensor.description": "測量溫度，範圍－55～＋125℃",
    "gui.extension.sen0203.name": "心率傳感器",
    "gui.extension.sen0203.description": "測量人體靜態心率",
    "gui.extension.sen0177.name": "PM2.5激光粉塵傳感器",
    "gui.extension.sen0177.description": "測量空氣中顆粒物濃度，如PM2.5",
    "gui.extension.sen0014.name": "紅外測距傳感器",
    "gui.extension.sen0014.description": "探測距離，範圍10～80cm",
    "gui.extension.sen0204.name": "液位傳感器(非接觸式)",
    "gui.extension.sen0204.description": "非接觸檢測密閉容器的液位高度",
    "gui.extension.sen0160.name": "Hx711重量傳感器",
    "gui.extension.sen0160.description": "測量重量（小於1kg）",
    "gui.extension.sen0161.name": "模擬pH計",
    "gui.extension.sen0161.description": "測量溶液的pH值（酸鹼度）,供電5V",
    "gui.extension.sen0161-v2.name": "模擬pH計(V2)",
    "gui.extension.sen0161-v2.description": "測量溶液的pH值（酸鹼度）,供電3.3～5.5V",
    "gui.extension.sen0244.name": "模擬TDS傳感器",
    "gui.extension.sen0244.description": "測量溶液的TDS（溶解性固體總量），反應水潔淨度",
    "gui.extension.sen0165.name": "模擬ORP計",
    "gui.extension.sen0165.description": "測量溶液的ORP（氧化還原電位），評估水質",
    "gui.extension.sen0237.name": "模擬DO計",
    "gui.extension.sen0237.description": "測量水中的氧含量，評估水質",
    "gui.extension.dfr0300-H.name": "模擬EC計",
    "gui.extension.dfr0300-H.description": "測量溶液電導率，評估水質",
    "gui.extension.dfr0300.name": "模擬EC計(V2)",
    "gui.extension.dfr0300.description": "測量溶液電導率，評估水質",
    "gui.extension.sen0170.name": "風速傳感器",
    "gui.extension.sen0170.description": "測量風速",
    "gui.extension.sen0226.name": "BMP280溫度傳感器",
    "gui.extension.sen0226.description": "檢測溫度、大氣壓",
    "gui.extension.sen0228.name": "環境光傳感器",
    "gui.extension.sen0228.description": "精確測量環境光強度",
    "gui.extension.sen0236.name": "BME280模塊",
    "gui.extension.sen0236.description": "环境传感器 (温度，湿度，气压)",
    "gui.extension.sen0517.name": "ICP10111气压温度传感器",
    "gui.extension.sen0517.description": "精度±1Pa，高精度高稳定性气压传感器",
    "gui.extension.dfr0022.name": "模擬灰度傳感器",
    "gui.extension.dfr0022.description": "檢測不同的顏色，可用於巡線",
    "gui.extension.motucamera.name": "視覺識別攝像頭",
    "gui.extension.motucamera.description": "支持各種智能圖像識別的攝像頭模塊",
    "gui.extension.dfr0552.name": "12位數模DA轉換模塊",
    "gui.extension.dfr0552.description": "將數字量準確地轉換成對應的直流電壓信號（非PWM）",
    "gui.extension.dfr0699.name": "音频录放EDU",
    "gui.extension.dfr0699.description": "单片机控制录放音、可以储存 10 段 100s 时长音频",
    "gui.extension.dfr0760.name": "语音合成模块",
    "gui.extension.dfr0760.description": "Gravity接口的语音合成模块，无需联网即可合成语音",
    "gui.extension.dfr0715.name": "语音识别模块",
    "gui.extension.dfr0715.description": "Gravity接口的语音识别模块，无需联网",
    "gui.extension.sen0117.name": "語音合成模塊",
    "gui.extension.sen0117.description": "你只要輸入相應的中英文字符和數字，它就會讀出這些文字",
    "gui.extension.dfr0576.name": "I2C級聯擴展器",
    "gui.extension.dfr0576.description": "用於解決I2C器件地址的衝突",
    "gui.extension.sen0248.name": "BME680環境傳感器",
    "gui.extension.sen0248.description": "可測量VOC（揮發性有機物）、溫度、濕度、氣壓這四個參數",
    "gui.extension.sen0304.name": "I2C超聲波測距傳感器",
    "gui.extension.sen0304.description": "專為快速測距或避障應用設計的超聲波傳感器",
    "gui.extension.sen0307.name": "模擬超聲波測距傳感器",
    "gui.extension.sen0307.description": "開放式雙探頭模擬超聲波測距傳感器",
    "gui.extension.sen0250.name": "BMI160 6軸慣性運動傳感器",
    "gui.extension.sen0250.description": "16位3軸加速度+超低功耗3軸陀螺儀",
    "gui.extension.sen0224.name": "I2C LIS2DH 三軸加速度計",
    "gui.extension.sen0224.description": "超低功耗3軸加速度傳感器",
    "gui.extension.dfr0151.name": "實時時鐘DS1307",
    "gui.extension.dfr0151.description": "輸出, 年、月、日、星期、時、分、秒",
    "gui.extension.dfr0469.name": "實時時鐘SD2405",
    "gui.extension.dfr0469.description": "存取年、月、日、星期、時、分、秒",
    "gui.extension.dfr0126.name": "頻譜分析模塊",
    "gui.extension.dfr0126.description": "分析聲音的7個頻率",
    "gui.extension.dfr0231.name": "NFC模塊",
    "gui.extension.dfr0231.description": "用於近距離無線通訊，可以實現讀卡寫卡",
    "gui.extension.sen0245.name": "VL53L0X激光測距傳感器",
    "gui.extension.sen0245.description": "使用TOF技术精准测量距离，最高测距2米",
    "gui.extension.dfr0026.name": "模擬環境光線傳感器",
    "gui.extension.dfr0026.description": "測量環境光強度",
    "gui.extension.dfr0027.name": "數字震動傳感器",
    "gui.extension.dfr0027.description": "檢測振動信號，輸出數字信號",
    "gui.extension.dfr0028.name": "數字鋼球傾角傳感器",
    "gui.extension.dfr0028.description": "可作為數字開關，不能測量傾斜角度",
    "gui.extension.dfr0029.name": "數字大按鈕模塊",
    "gui.extension.dfr0029.description": "按下輸出高電平，鬆開輸出低電平，有抖動",
    "gui.extension.dfr0030.name": "數字觸摸傳感器",
    "gui.extension.dfr0030.description": "觸摸開關，可感應人體、金屬等",
    "gui.extension.sen0318.name": "CCS811空气质量传感器",
    "gui.extension.sen0318.description": "可测CO2、TVOC, 适用于空气测量应用",
    "gui.extension.sen0315.name": "PAJ7620U2手势识别传感器",
    "gui.extension.sen0315.description": "IIC通信，在最远20cm范围内，可以识别的手势多达13种",
    "gui.extension.sen0497.name": "DHT20温湿度传感器",
    "gui.extension.sen0497.description": "I2C通信的新一代温湿度传感器，是DHT11的升级产品",
    "gui.extension.sen0514.name": "ENS160空气质量传感器",
    "gui.extension.sen0514.description": "测量TVOC（总挥发性有机化合物）、eCO2（相对二氧化碳）、AQI（空气质量指数）",
    "gui.extension.sen0518.name": "MAX30102心率血氧传感器",
    "gui.extension.sen0518.description": "测量人体静态心率、血氧饱和度",
    "gui.extension.dfr0991.name": "I2C-RGB灯按钮模块",
    "gui.extension.dfr0991.description": "读取按钮的状态，控制RGB灯的颜色和亮灭",
    "gui.extension.sen0376.name": "酒精传感器",
    "gui.extension.sen0376.description": "出厂标定，无需校准，接线方便，0-5ppm",
    "gui.extension.sen0321.name": "臭氧传感器",
    "gui.extension.sen0321.description": "测量环境中臭氧的浓度",
    "gui.extension.sen0364.name": "可见光谱传感器",
    "gui.extension.sen0364.description": "测量可见光光谱，识别光谱颜色",
    "gui.extension.sen0359.name": "指纹传感器",
    "gui.extension.sen0359.description": "一款方便易用的指纹模块",
    "gui.extension.kit0176.name": "I2C重量传感器",
    "gui.extension.kit0176.description": "可测量物体重量，量程1Kg内",
    "gui.extension.bos0063.name": "ADC转I2C模块",
    "gui.extension.bos0063.description": "采集模拟数据转换为I2C信号输出",
    "gui.extension.dfr0033.name": "數字貼片磁感應傳感器",
    "gui.extension.dfr0033.description": "探測磁性材料（磁鐵），探測範圍約3cm",
    "gui.extension.dfr0034.name": "模擬聲音傳感器",
    "gui.extension.dfr0034.description": "測量環境中聲音強度",
    "gui.extension.sen0132.name": "模擬一氧化碳氣體傳感器",
    "gui.extension.sen0132.description": "測量一氧化碳氣體濃度，範圍20～2000ppm",
    "gui.extension.dfr0051.name": "模擬電壓檢測模塊",
    "gui.extension.dfr0051.description": "測量25V以下的直流（DC）電壓",
    "gui.extension.dfr0052.name": "模擬壓電陶瓷震動傳感器",
    "gui.extension.dfr0052.description": "檢測振動信號，輸出模擬信號",
    "gui.extension.dfr0058.name": "模擬多圈旋轉角度傳感器",
    "gui.extension.dfr0058.description": "調節電壓，基於高精度電位器",
    "gui.extension.dfr0061.name": "JoyStick搖桿模塊",
    "gui.extension.dfr0061.description": "可製作遙控器，2軸模擬輸出，1路數字輸出",
    "gui.extension.dfr0072.name": "Shiftout數碼管",
    "gui.extension.dfr0072.description": "使用SPI通信可以級聯的數碼管模塊",
    "gui.extension.dfr0563.name": "鋰電池電量計",
    "gui.extension.dfr0563.description": "測量鋰電池的當前電壓和已用電量",
    "gui.extension.dfr0553.name": "16位AD轉換模塊",
    "gui.extension.dfr0553.description": "16位AD轉換模塊可對模擬量信號進行精確的採集與轉換",
    "gui.extension.dfr0588.name": "溫濕度傳感器SHT30",
    "gui.extension.dfr0588.description": "溫濕度傳感器SHT30",
    "gui.extension.dfr0076.name": "火焰傳感器",
    "gui.extension.dfr0076.description": "探測火源或波長在760～1100納米的光源",
    "gui.extension.dfr0117.name": "I2C EEPROM數據存儲模塊",
    "gui.extension.dfr0117.description": "容量32kb",
    "gui.extension.dfr0143.name": "三軸加速度傳感器",
    "gui.extension.dfr0143.description": "檢測物體的姿態、運動方向",
    "gui.extension.sen0018.name": "人體紅外熱釋電運動傳感器",
    "gui.extension.sen0018.description": "檢測運動的人、動物發出的熱紅外線",
    "gui.extension.sen0114.name": "土壤濕度傳感器",
    "gui.extension.sen0114.description": "檢測土壤水分，缺水時傳感器輸出值減小",
    "gui.extension.sen0121.name": "水分傳感器",
    "gui.extension.sen0121.description": "檢測雨水、霧水、水蒸氣",
    "gui.extension.sen0212.name": "顏色識別傳感器",
    "gui.extension.sen0212.description": "識別物體表面顏色，輸出為RGB值",
    "gui.extension.sen0253.name": "10DOF姿態傳感器",
    "gui.extension.sen0253.description": "BNO055+BMP280智能10DOF絕對定向傳感器",
    "gui.extension.sen0290.name": "閃電傳感器",
    "gui.extension.sen0290.description": "閃電傳感器",
    "gui.extension.sen0291.name": "I2C數字功率計",
    "gui.extension.huskylens.name": "HUSKYLENS AI摄像头",
    "gui.extension.huskylens.description": "人工智能视觉传感器，支持人脸识别和学习功能",
    "gui.extension.ps2.name": "PS2 手柄",
    "gui.extension.ps2.description": "PS2 手柄",
    "gui.extension.sen0291.description": "高精度測量電壓、電流、功率",
    "gui.extension.sen0202.name": "3D手勢識別迷你傳感器",
    "gui.extension.sen0202.description": "識別手指運動方向以及手指轉動的順、逆時針",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "micro:bit專用鋰電池電機擴展板",
    "gui.extension.wifi.name": "無線上網",
    "gui.extension.wifi.description": "讓設備連接WIFI網絡",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "让设备获取网络时间",
    "gui.extension.udp.name": "UDP广播",
    "gui.extension.udp.description": "让局域网内的设备与设备通过UDP协议实现多机广播通信",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "讓設備通過HTTP請求信息",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "讓設備可以使用MQTT協議進行通訊",
    "gui.extension.internet.name": "网络",
    "gui.extension.internet.description": "网络通信",
    "gui.extension.speechRecognition.name": "語音識別",
    "gui.extension.speechRecognition.description": "語音識別",
    "gui.extension.pictureai.defaultTitleText": "视频窗口",
    "gui.extension.pictureai.cameraPermissionsTitle": "需要相机权限",
    "gui.extension.pictureai.cameraPermissionsMessage": "请前往设置中勾选授权。如已授权但仍无法使用，请确认摄像头已正常连接无其它软件占用，或重启软件。",
    "gui.extension.pictureai.cameraPermissionsCancel": "取消",
    "gui.extension.pictureai.cameraPermissionsGoToSettings": "前往设置",
    "gui.gui.loaded": "已加載",
    "gui.gui.notLoaded": "未加載",
    "gui.gui.cantFindYouWant": "找不到你想要的？數量很少？",
    "gui.gui.clickHere": "點擊這裡",
    "gui.gui.viewHelp": "查看幫助",
    "gui.gui.uploadToDev": "上傳到設備",
    "gui.gui.codeArea": "代码区",
    "gui.gui.fileSys": "文件系统",
    "gui.gui.compileAndUpload": "編譯後上傳",
    "gui.gui.compileOnly": "編譯",
    "gui.gui.compileOpen": "編譯後打開",
    "gui.gui.burnBootloader": "燒錄無線引導程序",
    "gui.gui.arduinoRun": "運行",
    "gui.gui.arduinoSave": "存儲",
    "gui.gui.autoGenerated": "自動生成",
    "gui.gui.manualEditing": "手動編輯",
    "gui.gui.codeBox": "個代碼框",
    "gui.gui.moduleCode": "模塊代碼",
    "gui.menu.edit.undo": "撤銷",
    "gui.menu.edit.redo": "恢復",
    "gui.menu.edit.selectAll": "全選",
    "gui.menu.edit.cut": "剪切",
    "gui.menu.edit.copy": "複製",
    "gui.menu.edit.paste": "粘貼",
    "gui.menu.edit.codeStyle": "代碼風格",
    "gui.menu.edit.fontSize": "字體大小",
    "gui.menu.edit.clearCache": "清除緩存",
    "gui.menu.edit.maxReset": "Max恢復出廠設置",
    "gui.gui.serial": "串口",
    "gui.gui.openSerial": "打開串口",
    "gui.gui.closeSerial": "關閉串口",
    "gui.gui.close": "關閉",
    "gui.gui.open": "打開",
    "gui.gui.clearOutput": "清除輸出",
    "gui.gui.scroll": "滾屏",
    "gui.gui.send": "發送",
    "gui.gui.baud": "波特率",
    "gui.gui.noLineEnd": "沒有結束符",
    "gui.gui.newLine": "換行符",
    "gui.gui.carriageReturn": "回車",
    "gui.gui.bothNLandCR": "回車加換行",
    "gui.gui.unavailableHexData": "不是有效的HEX格式，请以两个字符(0-9/A-F)表示一个HEX数据，以空格分隔，如: \"00 01 A2 FF\"。",
    "gui.gui.hexForm": "16进制",
    "gui.gui.openHexForm": "采用16进制格式显示和发送数据",
    "gui.gui.closeHexForm": "关闭",
    "gui.progress.burning": "正在上傳",
    "gui.progress.compiling": "正在編譯",
    "gui.burner.compileError": "編譯失敗",
    "gui.burner.linkError": "鏈接失敗",
    "gui.burner.generateHexError": "生成.hex失敗",
    "gui.burner.generateBinError": "生成.bin失敗",
    "gui.burner.burnError": "燒錄失敗",
    "gui.burner.eraseError": "擦除失敗",
    "gui.burner.findLeonardoPortError": "無法找到Leonardo的bootlaoder串口",
    "gui.burner.noMicrobit": "當前連接的設備不是micro: bit, 請連接micro: bit",
    "gui.burner.swdCfgError": "寫入cmsis-dap.cfg失敗",
    "gui.burner.UDiskPopUp": "U盤設備彈出, 請等待幾秒再試一下",
    "gui.burner.UDiskWriteError": "U盤設備寫入出錯",
    "gui.burner.openPortError": "打開串口失敗: 串口已經被占用",
    "gui.burner.firmwareError": "固件错误, 请初始化设备后重新连接",
    "gui.burner.uploadDone": "上传完毕",
    "gui.burner.connectPort": "连接串口",
    "gui.burner.writeLibFiles": "正在写入库文件",
    "gui.burner.burnFirmware": "烧录固件",
    "gui.burner.connectError": "连接设备失败，请重新插拔设备再次下载",
    "gui.burner.connectPico": "Pico固件下载成功，请重新选择串口连接",
    "gui.burner.printObjectTooBig": "項目太大了；請點擊右上角“幫助”按鈕減小文件大小。 \n為開發板[%BOARD]編譯時出錯。 ",
    "gui.burner.printFlashTooBig": "沒有足夠的內存；請點擊右上角“幫助”按鈕減小內存的用量。 \n為開發板[%BOARD]編譯時出錯。 ",
    "gui.burner.printFlashTooLow": "可用內存偏低，可能出現穩定性問題。 ",
    "gui.burner.printSizeError": "無法確定程序大小。 ",
    "gui.burner.printSize": "項目使用了[%TEXTSIZE]字節，佔用了([%TEXTSIZEPRE])程序存儲空間，餘留[%TEXTSIZESUR]字節，最大為[%TEXTSIZEMAX]字節。\r\n全局變量使用了[%DATASIZE]字節，([%DATASIZEPRE])的動態內存，餘留[%DATASIZESUR]字節局部變量，最大為[%DATASIZEMAX]字節。 \r\n",
    "gui.progress.compileSuccess": "編譯成功",
    "gui.progress.compileProgress": "編譯進度",
    "gui.progress.uploadProgress": "上傳進度",
    "gui.progress.uploadSuccess": "上傳成功",
    "gui.progress.uploadSuccessVm": "上传成功",
    "gui.progress.networkInterfaceCard": "网卡",
    "gui.codeLabel.dynamicVariables": "動態變量",
    "gui.codeLabel.functionDeclaration": "函數聲明",
    "gui.codeLabel.staticConstants": "靜態常量",
    "gui.codeLabel.createObject": "創建對象",
    "gui.codeLabel.mainProgramStart": "主程序開始",
    "gui.codeLabel.SubthreadProgramStarts": "子線程%1程序開始",
    "gui.codeLabel.customFunction": "自定義函數",
    "gui.codeLabel.eventCallbackFunction": "事件回調函數",
    "gui.codeLabel.globalCode": "全局变量",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "不允許拖入變量！",
    "gui.progress.errMsg": "上傳出錯, 錯誤信息詳見右下角小黑窗",
    "gui.progress.errMsg1": "上傳出錯, 錯誤信息詳見右下角小黑窗",
    "gui.progress.errMsg2": "上傳出錯, 錯誤信息詳見右下角小黑窗",
    "gui.howtos.spin-around.name": "旋轉",
    "gui.howtos.spin.step_dragTurn": "拖拽出一個 “向右旋轉” 積木塊",
    "gui.howtos.spin.step_clickTurn": "點擊積木塊運行",
    "gui.howtos.spin.step_clickControl": "點擊左側的 “控制” 模塊",
    "gui.howtos.spin.step_dragForever": "拖拽出一個 “循環執行” 積木塊",
    "gui.howtos.spin.step_clickForever": "點擊積木塊運行",
    "gui.howtos.spin.step_changeColor": "添加一個 “將顏色特效增加” 積木塊",
    "gui.howtos.say-hello.name": "說 hello",
    "gui.howtos.say-hello.step_addSprite": "添加一個精靈",
    "gui.howtos.say-hello.step_clickLooks": "點擊左側 “外觀” 模塊",
    "gui.howtos.say-hello.step_dragSay": "拖拽出一個 “說” 積木塊",
    "gui.howtos.say-hello.step_clickSay": "點擊積木塊運行",
    "gui.howtos.say-hello.step_anotherSay": "拖拽出另一個 “說” 積木塊",
    "gui.howtos.say-hello.step_editSay": "編輯積木塊",
    "gui.howtos.say-hello.step_clickStack": "點擊積木塊運行",
    "gui.howtos.run-away.name": "逃跑",
    "gui.howtos.run-away.step_dragGoTo": "拖拽出一個 “移到隨機位置” 積木塊",
    "gui.howtos.run-away.step_clickGoTo": "點擊積木塊運行",
    "gui.howtos.run-away.step3": "點擊左側 “事件” 模塊",
    "gui.howtos.run-away.step_addWhenClicked": "添加一個 “當角色被點擊” 積木塊",
    "gui.howtos.run-away.step_clickSprite": "點擊角色運行",
    "gui.howtos.run-away.step_addSound": "添加一個 “開始播放聲音” 積木塊",
    "gui.howtos.pick-up-apple.name": "雙人接蘋果",
    "gui.howtos.pick-up-apple.step_1": "切換到解釋執行界面",
    "gui.howtos.pick-up-apple.step_2": "切換產品",
    "gui.howtos.pick-up-apple.step_3": "連接設備",
    "gui.howtos.pick-up-apple.step_4": "校準指南針",
    "gui.howtos.pick-up-apple.step_5": "打開例程",
    "gui.howtos.pick-up-apple.step_6": "雙人接蘋果",
    "gui.howtos.move-left-right.name": "micro:bit遙控小貓",
    "gui.howtos.move-left-right.step_1": "新建項目",
    "gui.howtos.move-left-right.step_2": "拖動積木塊1",
    "gui.howtos.move-left-right.step_3": "拖動積木塊2",
    "gui.howtos.move-left-right.step_4": "micro:bit遙控小貓",
    "gui.howtos.install-driver.name": "安裝驅動",
    "gui.howtos.transform-expression.name": "按鍵顯示表情",
    "gui.howtos.transform-expression.step_1": "切換到上傳執行界面",
    "gui.howtos.transform-expression.step_2": "切換產品",
    "gui.howtos.transform-expression.step_3": "拖動積木塊1",
    "gui.howtos.transform-expression.step_4": "拖動積木塊2",
    "gui.howtos.transform-expression.step_5": "拖動積木塊3",
    "gui.howtos.transform-expression.step_6": "按鍵顯示表情",
    "gui.howtos.lot.name": "物聯網(IOT)",
    "gui.howtos.lot.step_1": "選擇例程",
    "gui.howtos.lot.step_2": "燒錄程序",
    "gui.howtos.lot.step_3": "物聯網(IOT)",
    "gui.howtos.touch-pin.name": "引腳觸摸",
    "gui.howtos.touch-pin.step_1": "切換到上傳執行界面",
    "gui.howtos.touch-pin.step_2": "選擇例程",
    "gui.howtos.touch-pin.step_3": "燒錄程序",
    "gui.howtos.touch-pin.step_4": "引腳觸摸",
    "gui.howtos.save-witch.name": "拯救女巫",
    "gui.howtos.save-witch.step_1": "切換到“實時執行”",
    "gui.howtos.save-witch.step_2": "擴展中選擇“micro:bit”主控板",
    "gui.howtos.save-witch.step_3": "選擇“示例程序”中的“拯救女巫”",
    "gui.howtos.save-witch.step_4": "連接設備，選擇microbit，等待連接成功",
    "gui.howtos.save-witch.step_5": "如果提示“校准設備”則旋轉主控板一圈至LED燈全滅",
    "gui.howtos.save-witch.step_6": "點擊綠旗，揮動手臂即可與動畫互動啦",
    "gui.howtos.microbit-touch.name": "microbit觸摸切換錶情",
    "gui.howtos.microbit-touch.step_1": "切換到“上傳執行”界面",
    "gui.howtos.microbit-touch.step_2": "打開“學習”菜單下的“示例程序”，選擇“引腳觸摸”",
    "gui.howtos.microbit-touch.step_3": "連接設備對應的COM口，點擊“上傳到設備”",
    "gui.howtos.microbit-touch.step_4": "按下不同按鍵即可顯示不同表情",
    "gui.howtos.microbit-calibration.name": "micro:bit校準",
    "gui.howtos.calliope-calibration.name": "calliope校准",
    "gui.howtos.microbit-calibration.step_1": "將micro:bit板子垂直於地面進行旋轉，板載LED燈會隨著旋轉依次點亮",
    "gui.howtos.microbit-calibration.step_2": "直到最外側LED燈全部點亮（如下圖），之後會顯示一個笑臉即為校準完成",
    "gui.extension.sen0251.name": "BMP388氣壓溫度傳感器",
    "gui.extension.sen0251.description": "具有溫度、大氣壓、海拔檢測功能",
    "gui.extension.sen0206.name": "非接觸式紅外溫度傳感器",
    "gui.extension.sen0206.description": "遠紅外非接觸式測量物體或環境溫度",
    "gui.gui.recentlyEdited": "最近編輯",
    "gui.extension.weather.name": "獲取天氣",
    "gui.extension.weather.description": "獲取天氣信息，需與WIFI模塊一起使用",
    "gui.extension.weather.description.javascript": "通过网络获取天气信息，电脑需要联网",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "操作TinyWebDB網絡數據庫，可配合APP Inventor使用",
    "gui.extension.pictureai.name": "AI圖像識別",
    "gui.extension.pictureai.description": "使用圖像AI，可以識別生活中有趣的東西，需要連接網絡使用",
    "gui.extension.iflytek.description": "IFLYTEK",
    "gui.gui.variableScopeOptionAllSprites": "適用於所有角色",
    "gui.gui.variableScopeOptionSpriteOnly": "僅適用當前角色",
    "gui.gui.variablePromptAllSpritesMessage": "這個變數適用於所有角色。",
    "gui.monitor.contextMenu.default": "一般顯示",
    "gui.monitor.contextMenu.large": "大型顯示",
    "gui.monitor.contextMenu.slider": "滑桿",
    "gui.monitor.contextMenu.sliderRange": "改变滑块范围",
    "gui.monitor.contextMenu.import": "匯入",
    "gui.monitor.contextMenu.export": "匯出",
    "gui.monitor.listMonitor.listLength": "長度{length}",
    "gui.monitor.listMonitor.empty": "(空)",
    "gui.costumeTab.backdrop": "背景",
    "gui.costumeTab.costume": "造型",
    "gui.opcodeLabels.direction": "方向",
    "gui.opcodeLabels.xposition": "x 座標",
    "gui.opcodeLabels.yposition": "y 座標",
    "gui.opcodeLabels.size": "尺寸",
    "gui.opcodeLabels.costumename": "造型名稱",
    "gui.opcodeLabels.costumenumber": "造型編號",
    "gui.opcodeLabels.backdropname": "背景名稱",
    "gui.opcodeLabels.backdropnumber": "背景編號",
    "gui.opcodeLabels.volume": "音量",
    "gui.opcodeLabels.tempo": "演奏速度",
    "gui.opcodeLabels.answer": "詢問的答案",
    "gui.opcodeLabels.loudness": "聲音響度",
    "gui.opcodeLabels.year": "年",
    "gui.opcodeLabels.month": "月",
    "gui.opcodeLabels.date": "日",
    "gui.opcodeLabels.dayofweek": "週",
    "gui.opcodeLabels.hour": "時",
    "gui.opcodeLabels.minute": "分",
    "gui.opcodeLabels.second": "秒",
    "gui.opcodeLabels.timer": "計時器",
    "gui.loadProject.error": "加載項目失敗",
    "gui.fileSystem.fileCatalogue": "文件目錄",
    "gui.fileSystem.boardFiles": "掌控板中的文件",
    "gui.fileSystem.boardFilesPico": "Pico中的文件",
    "gui.fileSystem.boardFiles2": "RMTT中的文件",
    "gui.fileSystem.boardFilesMaixduino": "Maixduino中的文件",
    "gui.fileSystem.boardFilesBoson": "BOSON MC1",
    "gui.fileSystem.computerFiles": "用户空间",
    "gui.fileSystem.userExFiles": "用户库中的文件",
    "gui.fileSystem.newFile": "新建文件",
    "gui.fileSystem.newFolder": "新建文件夾",
    "gui.fileSystem.deleteFolder": "刪除文件夾",
    "gui.fileSystem.deleteFile": "删除",
    "gui.fileSystem.rename": "重命名",
    "gui.fileSystem.openFolder": "打開文件夾位置",
    "gui.fileSystem.openFilePos": "打开文件位置",
    "gui.fileSystem.openInEditor": "在編輯區打開",
    "gui.fileSystem.runRightNow": "立即運行",
    "gui.fileSystem.stopRun": "停止運行",
    "gui.fileSystem.setBoot": "設為開機運行",
    "gui.fileSystem.deleteForever": "永久刪除",
    "gui.fileSystem.saveAs": "另存為",
    "gui.fileSystem.undoAllEdits": "取消所有修改",
    "gui.fileSystem.copyToComputer": "複製到電腦",
    "gui.fileSystem.uploadToBoard": "上傳到主控板",
    "gui.fileSystem.uploadAndRun": "上傳到主控板立即運行",
    "gui.wifi.pwdNotLegal": "！錯誤提示：密碼格式錯誤，wifi密碼必須是8-20位的字母或數字",
    "gui.spreadmaqueen.perror": "！錯誤提示：掌控麥昆擴展版P12引腳led燈不可使用，請選擇P8引腳led燈。",
    "gui.huskylens.perror": "！错误提示：huskylens只支持microbit V2",
    "gui.infrared.perror": "！错误提示：红外发送只支持microbit V2",
    "gui.gui.viewConflict": "點擊查看衝突",
    "gui.gui.clickViewHelp": "如何解决？",
    "gui.gui.conflict": "衝突",
    "gui.gui.conflictPrompt": "冲突警告提示",
    "gui.gui.clickPrompt": "單擊高亮積木塊，雙擊定位積木塊",
    "gui.extension.mpyUART.name": "串口UART",
    "gui.extension.mpyUART.description": "使用硬串口进行通信",
    "gui.extension.mpyI2C.name": "I2C通信",
    "gui.extension.mpyI2C.description": "使用软件模拟I2C协议传输数据",
    "gui.extension.mpyServo.name": "舵机模块",
    "gui.extension.mpyServo.description": "可以在0～180度间转动到指定角度位置",
    "gui.extension.mpyTimer.name": "定时器",
    "gui.extension.mpyTimer.description": "可以定时执行程序",
    "gui.extension.mpyList.name": "列表",
    "gui.extension.mpyList.description": "列表",
    "gui.extension.mpyDictionary.name": "字典",
    "gui.extension.mpyDictionary.description": "字典",
    "gui.extension.mpySet.name": "集合",
    "gui.extension.mpySet.description": "集合",
    "gui.extension.mpyTuple.name": "元组",
    "gui.extension.mpyTuple.description": "元组",
    "gui.connection.auto-scanning.noPeripheralsFound": "没有发现任何设备",
    "gui.connection.auto-scanning.prescan": "把设备放在边上，然后开始搜索",
    "gui.connection.auto-scanning.pressbutton": "按下设备上的按钮",
    "gui.connection.auto-scanning.start-search": "开始搜索",
    "gui.connection.connecting-searchbutton": "正在搜索……",
    "gui.connection.auto-scanning.try-again": "重试",
    "gui.connection.connected": "已连接",
    "gui.connection.disconnect": "断开连接",
    "gui.connection.go-to-editor": "返回编辑器",
    "gui.connection.connecting-cancelbutton": "正在连接……",
    "gui.connection.error.errorMessage": "呀，好像出错了。",
    "gui.connection.error.tryagainbutton": "重试",
    "gui.connection.error.helpbutton": "帮助",
    "gui.connection.peripheral-name-label": "设备名称",
    "gui.connection.connect": "连接",
    "gui.connection.scanning.lookingforperipherals": "正在查找设备",
    "gui.connection.scanning.noPeripheralsFound": "没有发现任何设备",
    "gui.connection.scanning.instructions": "选择上面列出的设备。",
    "gui.connection.scanning.instructionsForTello": "請先將電腦WiFi連接Tello或RMTT，再選擇上面列出的設備。",
    "gui.connection.connect.instructionsForTello": "請先在電腦WiFi列表中連接Tello或RMTT，再選擇上面列出的設備。",
    "gui.connection.scanning.connectTelloHandle": "選擇上面列出的設備，或手動連接飛機Wi-Fi。",
    "gui.connection.search": "刷新",
    "gui.connection.unavailable.installscratchlink": "确保Scratch Link已安装并正在运行",
    "gui.connection.unavailable.enablebluetooth": "请检查蓝牙已经开启",
    "gui.connection.unavailable.tryagainbutton": "重试",
    "gui.connection.unavailable.helpbutton": "帮助",
    "gui.extension.ev3.description": "搭建交互机器人等。",
    "gui.extension.ev3.connectingMessage": "正在连接。请确保EV3上的识别码(PIN)已经设置为1234。",
    "gui.extension.boost.description": "生动有趣的机器人创作",
    "gui.extension.boost.connectingMessage": "正在连接",
    "gui.extension.wedo2.description": "支持马达和传感器。",
    "gui.extension.wedo2.connectingMessage": "正在连接",
    "gui.extension.huskyLensPro.name": "HUSKYLENS教育版",
    "gui.extension.huskyLensPro.description": "使用HUSKYLENS直连电脑，学习AI人工智能基础知识",
    "gui.extension.huskyLensPro.connectingMessage": "HUSKYLENS正在努力请求连接中，请稍候",
    "gui.extension.machinelearning.name": "机器学习 (ML5)",
    "gui.extension.machinelearning.description": "基于机器学习算法，可以脱离网络实现人工智能应用",
    "gui.extension.huskylens.pcsetting": "配置电脑端摄像头",
    "gui.extension.huskylens.visualmodal": "AI可视化工具",
    "gui.extension.huskylens.KNNText": "电脑端摄像头模拟KNN分类",
    "gui.extension.huskylens.huskylensbasic": "基础功能",
    "gui.extension.huskylens.algorithmOperationLabel": "算法操作",
    "gui.extension.huskylens.displayFunctionLabel": "显示功能",
    "gui.extension.huskylens.cameraFunctionLabel": "拍照功能",
    "gui.extension.huskylens.modelOperationLabel": "模型操作",
    "gui.huskyLensPro.toConnect": "请先连接HUSKYLENS教育版",
    "gui.huskyLensPro.toClassificationData": "请先添加分类数据",
    "gui.huskyLensPro.startDeployment": "开始部署",
    "gui.huskyLensPro.switchingAlgorithmFailed": "无法切换到“物体分类”算法！",
    "gui.huskyLensPro.forgettingFailure": "无法遗忘学习数据！",
    "gui.huskyLensPro.failedToSetCategoryLabel": "设置“分类标签”失败！",
    "gui.huskyLensPro.requestFailed": "请求“部署”失败！",
    "gui.huskyLensPro.deploymentFailed": "“部署数据”失败！",
    "gui.huskyLensPro.completed": "完成",
    "gui.huskyLensPro.countdown": "，预计[TIME]后完成",
    "gui.huskyLensPro.minutes": "分",
    "gui.huskyLensPro.seconds": "秒",
    "gui.huskyLensPro.deploying": "正在部署模型",
    "gui.huskyLensPro.disconnected": "连接已断开，终止部署，请检查USB连线！",
    "gui.huskyLensPro.huskylenMaker": "此功能只有教育版可用",
    "gui.huskyLensPro.updateVersion": "请连接教育版且固件版本不低于0.5.1，详情见\"帮助\"",
    "gui.huskyLensPro.failedRetry": "失败，请重试！",
    "gui.connection.auto-scanning.notFoundHuskylensTip": "连接超时，请确认本机已连接到HuskyLens",
    "gui.extension.tello.name": "RoboMaster TT(單機)",
    "gui.extension.tello.description": "搭載大疆頂尖飛控演算法的教育無人機，極強的安全性與穩定性",
    "gui.extension.tello.connectingMessage": "正在連接...請確認已打開Tello",
    "gui.extension.telloGroup.name": "RoboMaster TT(組隊)",
    "gui.extension.telloGroup.description": "搭載大疆頂尖飛控演算法的教育無人機，極強的安全性與穩定性",
    "gui.connection.auto-scanning.notFoundTelloTip": "掃描超時，請確認已打開Tello",
    "gui.extension.AIVisualInterface.lable": "AI可视化工具",
    "gui.extension.AIVisualInterface.title": "暂无数据",
    "gui.extension.AIVisualInterface.imgCount": "[COUNT]张图片",
    "gui.extension.AIVisualInterface.delete": "删除",
    "gui.extension.AIVisualInterface.classificationCount": "共计[COUNT]个分类",
    "gui.extension.AIVisualInterface.recognitionResult": "画面识别结果为:",
    "gui.extension.AIVisualInterface.possibility": "可能性为:",
    "gui.extension.AIVisualInterface.addClass": "添加分类",
    "gui.extension.AIVisualInterface.startTraining": "开始训练",
    "gui.extension.AIVisualInterface.prediction": "预测视频图片",
    "gui.extension.AIVisualInterface.retrain": "重新训练",
    "gui.extension.AIVisualInterface.export": "导出模型",
    "gui.dialog.baiduServer.togglePersonalAccount": "当前人声的可用次数已到达上限，请切换至个人账户使用，并登录控制台检查额度",
    "gui.extension.ai.name": "人工智能",
    "gui.extension.AI.description": "支持模型调用与训练",
    "gui.extension.machineVision.name": "机器视觉",
    "gui.extension.machineVision.description": "图像处理、绘制、滤波、识别形状、颜色、维码、特征",
    "gui.extension.machineHearing.name": "机器听觉",
    "gui.extension.machineHearing.description": "通过训练实现语音识别",
    "gui.extension.maixduino.name": "Maixduino",
    "gui.extension.maixduino.description": "基于K210芯片的主控板",
    "gui.extension.boson.name": "Boson MC1",
    "gui.extension.boson.description": "Boson MC1主控板控制的设备",
    "gui.extension.esp8266.name": "FireBeetle ESP8266",
    "gui.extension.esp8266.description": "FireBeetle ESP8266主控板控制的设备",
    "gui.extension.pico.name": "Raspberry Pi Pico",
    "gui.extension.pico.description": "Raspberry Pi Pico主控板控制的设备",
    "gui.extension.pyTurtle.name": "海龟绘图",
    "gui.extension.pyTurtle.description": "脑洞万千，尺幅之间",
    "gui.extension.pyGenerator.name": "代码生成器",
    "gui.extension.pyGenerator.description": "直接在图像积木上书写代码实现图形化与代码混编",
    "gui.extension.unihiker.name": "行空板",
    "gui.extension.unihiker.description": "可以运行完整python的新一代学习板",
    "gui.extension.python.save": "保存",
    "gui.extension.python.execute": "运行",
    "gui.extension.python.pipmanger": "库管理",
    "gui.extension.python.filesystem": "文件系统",
    "gui.extension.python.fileinprogram": "项目中的文件",
    "gui.extension.python.fileinpc": "电脑中的文件",
    "gui.extension.python.terminal": "终端",
    "gui.extension.python.clearterminal": "清除输出",
    "gui.extension.python.tabcode": "代码",
    "gui.extension.python.piplist": "库列表",
    "gui.extension.python.commendatory": "推荐库",
    "gui.extension.python.handleinstall": "PIP模式",
    "gui.extension.python.selectall": "选择所有[COUNT]",
    "gui.extension.python.update": "更新",
    "gui.extension.python.uninstall": "卸载",
    "gui.extension.python.install": "安装",
    "gui.extension.python.installing": "安装中..",
    "gui.extension.python.installed": "已安装",
    "gui.extension.python.piphandleinstall": "PIP手动安装",
    "gui.extension.python.loadmore": "加载更多...",
    "gui.extension.python.hidemore": "隐藏更多...",
    "gui.extension.python.noavailable": "暂无可用库，快先去下载吧",
    "gui.extension.python.enterCommand": "请先输入库名称",
    "gui.extension.python.finishedtips": "命令运行完成",
    "gui.extension.python.stop": "停止",
    "gui.extension.python.executing": "运行中",
    "gui.extension.python.editorArea": "代码区",
    "gui.extension.python.toolbox": "图形区",
    "gui.extension.python.autoCreate": "自动生成",
    "gui.extension.python.emptyEditor": "点击右侧<b>项目中的文件</b>后面的<b>新建文件按钮</b>，创建一个属于你的.py文件",
    "gui.extension.python.userDocument": "帮助文档",
    "gui.extension.xiamiboard.name": "虾米扩展板",
    "gui.extension.xiamiboard.description": "基于microbit开发的多功能编程教育扩展板",
    "gui.gui.saveSb3ToCloud": "保存到云端",
    "gui.extension.pyMqtt.name": "MQTT-py",
    "gui.extension.pyMqtt.description": "使用电脑的网络，通过MQTT协议进行通讯",
    "gui.extension.pyGame.name": "Pygame",
    "gui.extension.pyGame.description": "用Python编写自己的游戏和动画",
    "gui.extension.pinpongBase.name": "pinpong初始化",
    "gui.extension.pinpongBase.description": "pinpong库初始化选择主控板功能和gpio",
    "gui.extension.pinpongServo.name": "舵机",
    "gui.extension.pinpongServo.description": "可以在0 ~ 180度间转动到指定角",
    "gui.extension.pinpongIrq.name": "引脚中断",
    "gui.extension.pinpongIrq.description": "pinpong库引脚中断功能",
    "gui.extension.pinpongTone.name": "蜂鸣器",
    "gui.extension.pinpongTone.description": "pinpong库蜂鸣器模块",
    "gui.extension.pinpongSr04_urm10.name": "超声波测距传感器",
    "gui.extension.pinpongSr04_urm10.description": "准确测距，量程2～800cm，兼容urm及SR04超声波",
    "gui.extension.pinpongDht.name": "DHT11/22温湿度传感器",
    "gui.extension.pinpongDht.description": "测量环境温度、湿度",
    "gui.extension.pinpongNeopixel.name": "WS2812 RGB灯",
    "gui.extension.pinpongNeopixel.description": "控制LED灯条变化产生如闪烁、流水、跳变等效果",
    "gui.extension.pinpongOled12864.name": "OLED显示屏",
    "gui.extension.pinpongOled12864.description": "小体积显示屏，可用于智能穿戴设备的显示器",
    "gui.extension.pinpongDS18B20.name": "DS18B20温度传感器",
    "gui.extension.pinpongDS18B20.description": "测量温度，范围－55～＋125℃",
    "gui.extension.pinpongMlx90614.name": "非接触式红外温度传感器",
    "gui.extension.pinpongMlx90614.description": "远程红外非接触式测量物体或环境温度",
    "gui.extension.pinpongBmp388.name": "BMP388气压温度传感器",
    "gui.extension.pinpongBmp388.description": "具有温度、大气压、海拔检测功能",
    "gui.extension.pinpongIRRecv.name": "红外接收模块",
    "gui.extension.pinpongIRRecv.description": "可接收标准38KHz调制的红外信号",
    "gui.extension.PinpongBoard.description": "pinpong board扩展板，xxxxxxx",
    "gui.extension.pythonsen0334.name": "SHT31-F数字温湿度传感器",
    "gui.extension.pythonsen0334.description": "可测量温湿度，低功耗、响应速度快、抗干扰能力强",
    "gui.extension.pythonsen0303.name": "VEML6075 紫外线传感器",
    "gui.extension.pythonsen0303.description": "能够检测出UVA和UVB强度",
    "gui.extension.pythonsen0377.name": "MEMS 气体传感器",
    "gui.extension.pythonsen0377.description": "支持CO、C2H5OH(Alcohol)H2、NO2、NH3等多种气体浓度的检测",
    "gui.extension.pythonsen0460.name": "空气质量传感器",
    "gui.extension.pythonsen0460.description": "Gravity接口的空气质量传感器，可测量PM2.5值",
    "gui.extension.obloqMp.name": "OBLOQ物联网模块",
    "gui.extension.obloqMp.description": "通过Wi-Fi接收和发送物联网信息",
    "gui.extension.pyAutoGui.name": "PyAutoGui",
    "gui.extension.pyAutoGui.description": "PyAutoGUI允许Python脚本控制鼠标和键盘，以自动化与其他应用程序的交互",
    "gui.extension.pyRequests.name": "Requests",
    "gui.extension.pyRequests.description": "让 HTTP 服务人类",
    "gui.extension.pyJson.name": "JSON",
    "gui.extension.pyJson.description": "JSON 编码和解码器",
    "gui.extension.pySchedule.name": "Schedule",
    "gui.extension.pySchedule.description": "面向人类的 Python 作业调度",
    "gui.extension.pyXlrd.name": "xlrd",
    "gui.extension.pyXlrd.description": "execl读取, 只支持xls文件",
    "gui.extension.pyXlwt.name": "xlwt",
    "gui.extension.pyXlwt.description": "execl写入, 只支持xls文件",
    "gui.extension.pyOpenCV.name": "OpenCV",
    "gui.extension.pyOpenCV.description": "OpenCV是一个包含数百种计算机视觉算法的开源库",
    "gui.extension.pyBaiDuAi.name": "百度人脸识别",
    "gui.extension.pyBaiDuAi.description": "使用百度AI提供的接口实现人脸识别",
    "gui.extension.pyBaiDuImage.name": "百度图像识别",
    "gui.extension.pyBaiDuImage.description": "使用百度AI提供的接口实现图像识别",
    "gui.extension.pyBaiDuSpeech.name": "百度语音",
    "gui.extension.pyBaiDuSpeech.description": "百度语音提供语音识别、语音合成等多种智能服务",
    "gui.extension.pyXfSpeech.name": "讯飞语音",
    "gui.extension.pyXfSpeech.description": "讯飞语音提供语音合成和语音识别功能",
    "gui.account.dialog.prompt": "提示",
    "gui.account.dialog.tips": "提示",
    "gui.account.dialog.signoutAlert": "退出后将无法使用云存储等功能，确定退出吗？",
    "gui.account.dialog.signoutSuccess": "已退出登录",
    "gui.account.dialog.sure": "确定",
    "gui.account.dialog.cancel": "取消",
    "gui.account.dialog.error": "错误",
    "gui.account.dialog.save": "保存",
    "gui.account.dialog.getCloudFileFailed": "获取云文件「[NAME]」出错，请检查文件是否存在",
    "gui.account.dialog.getCloudError": "获取云文件「[NAME]」出错",
    "gui.account.dialog.loadCloudSuccess": "加载云文件「[NAME]」成功",
    "gui.account.dialog.saveCloudError": "保存「[NAME]」到云端失败，请稍后尝试。",
    "gui.account.dialog.saveCloudSuccess": "保存「[NAME]」到云端成功",
    "gui.account.dialog.saveCloudCheckError": "保存失败，校验云端文件出错，请稍后再试",
    "gui.account.dialog.saveCloudRepeatPrompt": "云端已存在同名文件「[NAME]」，是否覆盖？",
    "gui.account.dialog.saveCloudRepeatAlert": "云端已存在同名文件「[NAME]」，请换个名字",
    "gui.account.dialog.saveCover": "覆盖",
    "gui.account.dialog.rename": "重命名",
    "gui.account.dialog.newProject": "新建文件",
    "gui.account.dialog.projectName": "项目名称",
    "gui.account.dialog.enterName": "请输入文件名",
    "gui.account.dialog.nameWithSymbol": "文件名不能包含特殊字符, 请更改",
    "gui.account.dialog.nameBeyondLength": "文件名长度不能超过30",
    "gui.account.dialog.renameSuccess": "重命名文件「[NAME1]」为「[NAME2]」成功",
    "gui.account.dialog.renameFailed": "重命名文件失败",
    "gui.account.dialog.downloadSuccess": "「[NAME]」下载完成",
    "gui.account.dialog.cancelDownload": "取消下载",
    "gui.account.dialog.deleteSucess": "删除云文件「[NAME]」成功",
    "gui.account.dialog.saveAs": "另存为",
    "gui.account.dialog.deleteFailed": "删除云文件「[NAME]」出错",
    "gui.account.dialog.deletePrompt": "确认删除云文件「[NAME]」吗？",
    "gui.account.open": "打开",
    "gui.account.upload": "上传",
    "gui.account.delete": "删除",
    "gui.account.usersSpace": "[NAME]的云端项目",
    "gui.account.cloudDataLoading": "云端数据加载中，请稍候",
    "gui.account.emptyUserSpace": "这里是空的，快去上传你的第一个项目吧。",
    "gui.account.dialog.login": "登录",
    "gui.account.dialog.signup": "注册",
    "gui.account.dialog.phoneNumber": "手机号",
    "gui.account.dialog.password": "密码",
    "gui.account.dialog.autoLogin": "记住密码，自动登录",
    "gui.account.dialog.forgotPasswd": "忘记密码?",
    "gui.account.dialog.getCheckCode": "获取验证码",
    "gui.account.dialog.reSend": "重新发送",
    "gui.account.dialog.checkCode": "验证码",
    "gui.account.dialog.readAndAgree": "我已阅读并接受",
    "gui.account.dialog.userAgreement": "用户协议",
    "gui.account.dialog.loginSuccess": "登录成功",
    "gui.account.dialog.passwordError": "账号或密码错误，请检查填写是否正确",
    "gui.account.dialog.checkNumberSended": "验证码已发送，请注意查收",
    "gui.account.dialog.checkedAgree": "同意协议",
    "gui.account.dialog.networkError": "网络错误，请检查连接后重试",
    "gui.account.dialog.readProtocolAndCheck": "请先阅读用户协议并勾选同意",
    "gui.account.dialog.signupError": "注册出错，",
    "gui.account.dialog.logininErrorWithNetwork": "服务器连接出错，登录失败，请检查网络",
    "gui.account.dialog.signupErrorDefault": "注册失败，请重新操作",
    "gui.account.dialog.accountExist": "账号已存在，请直接登录",
    "gui.account.dialog.phoneCodeError": "注册失败，验证码错误",
    "gui.account.dialog.signupSuccess": "注册成功，请返回登录界面继续操作",
    "gui.account.dialog.inputEmpty": "不能为空",
    "gui.account.dialog.phoneFormatIllegal": "用户名格式错误",
    "gui.account.dialog.unsupportProject": "打开项目出错，不受支持的项目",
    "gui.account.dialog.shouldLoginFirst": "云服务需要你的账号信息，请先登录",
    "gui.account.dialog.loginTimeout": "登录超时",
    "gui.account.account": "账号",
    "gui.account.serverAddr": "服务器地址",
    "gui.account.thirdPartyAccount": "第三方账号",
    "gui.account.dialog.saveCloudRenamePrompt": "文件名[NAME]已存在，是否重命名?",
    "gui.python.remoteSSH.connectSuccess": "已成功连接到[ADDRESS]",
    "gui.python.remoteSSH.connectFailed": "连接[ADDRESS]失败",
    "gui.python.remoteSSH.shouleReconnect": "已与[ADDRESS]断开连接, 是否重新连接？",
    "gui.python.remoteSSH.reconnect": "重新连接",
    "gui.python.remoteSSH.disconnected": "已与[ADDRESS]断开连接",
    "gui.python.remoteSSH.accountCheckFailed": "账号信息错误，与[ADDRESS]连接失败，请检查后输入",
    "gui.python.remoteSSH.connectError": "与[ADDRESS]连接出错，[MSG]，解决办法请查看帮助文档",
    "gui.python.remoteSSH.sshLogin": "SSH登录",
    "gui.python.remoteSSH.address": "地址",
    "gui.python.remoteSSH.addressCantEmpty": "地址不能为空",
    "gui.python.remoteSSH.user": "用户名",
    "gui.python.remoteSSH.userCantEmpty": "用户名不能为空",
    "gui.python.remoteSSH.passwd": "密码",
    "gui.python.remoteSSH.passwdCantEmpty": "密码不能为空",
    "gui.python.remoteSSH.areYouSureDisconnect": "当前已连接到远程[ADDRESS]，确认断开吗？",
    "gui.python.remoteSSH.statusConnecting": "连接中",
    "gui.python.remoteSSH.disconnectDevice": "断开远程终端",
    "gui.python.remoteSSH.connectDevice": "连接远程终端",
    "gui.python.remoteSSH.statusError": "连接出错",
    "gui.python.remoteSSH.statusDisconnect": "连接已断开",
    "gui.python.remoteSSH.handleInput": "手动输入",
    "gui.python.remoteSSH.openNetNetWork": "打开网络中心",
    "gui.python.remoteSSH.goHelp": "帮助文档",
    "gui.python.remoteSSH.waitForConnecting": "正在连接到[ADDRESS], 请稍候...",
    "gui.python.remoteSSH.cancelConnect": "取消连接",
    "gui.python.remoteSSH.connectTimeout": "连接超时",
    "gui.python.remoteSSH.addressIsLost": "地址不存在",
    "gui.python.remoteSSH.connectRefused": "请求被拒绝，请检查远程系统设置后重试",
    "gui.python.remoteSSH.runGUIProgramTips": "运行图形化程序时，如果想在远程桌面查看效果，需使用远程系统当前登录的用户名",
    "gui.python.remoteSSH.executeFileError": "运行项目出错",
    "gui.python.remoteSSH.waitForProjectUpload": "项目[NAME]正在上传中，请稍后...",
    "gui.python.remoteSSH.projectUploadSuccess": "项目上传成功，开始运行",
    "gui.python.remoteSSH.projectUploadError": "项目上传出错",
    "gui.python.filetree.boardNameInFT": "[BOARD]中的文件",
    "gui.python.filetree.replaceFilePrompt": "目标文件夹中已经存在名称为[NAME]的文件或文件夹。是否要替换它？",
    "gui.python.filetree.fileUploading": "上传[NAME]文件...",
    "gui.python.filetree.fileSaving": "保存[NAME]文件...",
    "gui.python.filetree.dirSaving": "保存[NAME]文件夹...",
    "gui.python.filetree.saveAs": "另存为",
    "gui.python.filetree.chooseDir": "选择文件夹",
    "gui.python.filetree.getDirList": "获取目录...",
    "gui.python.filetree.getDirListWithPath": "读取[PATH]目录",
    "gui.python.filetree.getRemoteDirList": "读取远程[PATH]目录",
    "gui.python.filetree.renameFile": "重命名[FROM]为[TO]",
    "gui.python.filetree.deleteDirPrompt": "确定删除文件夹[NAME]及其包含的文件吗？",
    "gui.python.filetree.deleteFilePrompt": "确定删除文件[NAME]吗？",
    "gui.python.filetree.downloadToPC": "下载文件到电脑",
    "gui.python.filetree.uploadToRemote": "上传文件到",
    "gui.python.filetree.exportFileTo": "导出[EXT]文件",
    "gui.python.filetree.rmFromeFileSystem": "从文件系统中移除",
    "gui.python.filetree.rmDirPrompt": "确定移除本地路径[NAME]及其子文件吗？",
    "gui.python.filetree.rmAllDirPrompt": "确定移除所有本地路径吗？",
    "gui.python.filetree.addLocalDir": "添加本地文件夹",
    "gui.python.filetree.removeActionDirWithAll": "移除所有本地文件夹",
    "gui.python.filetree.fileNameIsRequired": "必须提供文件或文件夹名",
    "gui.python.filetree.fileNameIsRepeat": "此位置已经存在文件或文件夹[NAME]。请选择其他名称。",
    "gui.python.filetree.dragParentNodeToChild": "父文件夹无法移动到子文件夹",
    "gui.python.filetree.dragPathIsConsistent": "文件拖动路径前后一致",
    "gui.python.filetree.fileOperateError": "文件操作出错",
    "gui.python.filetree.fileTreeMore": "更多",
    "gui.python.filetree.copy": "复制",
    "gui.python.filetree.paste": "粘贴",
    "gui.python.worker.initPythonHomeDir": "正在初始化文件夹...",
    "gui.python.worker.copyPythonSource": "初始化文件夹完成，正在复制Python资源文件...",
    "gui.python.worker.copyPythonSourceError": "文件操作出错，初始化Python环境失败，请重新进入Python模式或重启软件",
    "gui.python.worker.unzipPythonSource": "复制资源文件完成，正在解压...",
    "gui.python.worker.checkPythonSource": "解压完成，正在验证文件...",
    "gui.python.worker.unzipPythonSourceError": "解压出错，初始化Python环境失败，请重新进入Python模式或重启软件",
    "gui.python.xterm.local": "本地",
    "gui.python.xterm.detectPythonEnv": "正在检测环境，请稍后....",
    "gui.python.xterm.installingPythonEnv": "\r\n正在安装环境，请稍后....",
    "gui.python.xterm.installPythonEnvError": "Python环境初始化失败，请联系技术支持",
    "gui.python.xterm.detectPythonEnvFinished": "\r\n环境检测完成，启动中...\r\n",
    "gui.python.xterm.installPythonEnvFinished": "\r\n环境安装完成，启动中...\r\n",
    "gui.python.xterm.toRestartXterm": "双击重启终端",
    "gui.python.xterm.showRemoteConnect": "开启远程连接终端",
    "gui.python.xterm.remoteConnectShowed": "远程连接终端已打开",
    "gui.dialog.thirdExt.openPipManger": "打開庫管理",
    "music.categoryName": "音樂",
    "translate.categoryName": "翻譯",
    "sensor.categoryName": "傳感器",
    "actuator.categoryName": "執行器",
    "communicate.categoryName": "通信模塊",
    "display.categoryName": "顯示器",
    "extendFunction.categoryName": "功能模塊",
    "internetService.categoryName": "網絡服務",
    "arduinounoR3.categoryName": "UNO套件",
    "arduinoNano.categoryName": "納米",
    "microbit.categoryName": "micro:bit",
    "calliope.categoryName": "CALLIOPE",
    "esp32.categoryName": "掌控",
    "telloesp32.categoryName": "RMTT",
    "spread.categoryName": "擴展板",
    "speechRecognition.categoryName": "語音識別",
    "thirdExpansion.categoryName": "用户库",
    "gui.blocklyText.blockTitle.eventBlock": "事件程序",
    "gui.blocklyText.blockTitle.dotScreen": "點陣屏",
    "gui.blocklyText.blockTitle.music": "音樂",
    "gui.blocklyText.blockTitle.onBoardSensor": "板載傳感器",
    "gui.blocklyText.blockTitle.onBoardFunc": "板载功能",
    "gui.blocklyText.blockTitle.pinOperation": "引腳操作",
    "gui.blocklyText.blockTitle.wirelessCommunication": "無線通信",
    "gui.blocklyText.blockTitle.serialportOperation": "串口操作",
    "gui.blocklyText.blockTitle.systemResource": "系統資源",
    "gui.blocklyText.blockTitle.screenDisplay": "屏幕顯示",
    "gui.blocklyText.blockTitle.ledControl": "LED控制",
    "gui.blocklyText.blockTitle.functionalModule": "功能模塊",
    "gui.blocklyText.blockTitle.movement": "運動",
    "gui.blocklyText.blockTitle.expression": "表情",
    "gui.blocklyText.blockTitle.light": "燈光",
    "gui.blocklyText.blockTitle.dance": "舞蹈",
    "gui.blocklyText.blockTitle.sensor": "傳感器",
    "gui.blocklyText.blockTitle.motorControl": "電機控制",
    "gui.blocklyText.blockTitle.kitSensor": "套件傳感器",
    "gui.blocklyText.blockTitle.kitActuator": "套件執行器",
    "gui.blocklyText.blockTitle.commonModule": "常用模塊",
    "gui.blocklyText.blockTitle.infraredCommunication": "紅外通信",
    "gui.blocklyText.blockTitle.mp3Module": "mp3模塊",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 RGB燈",
    "gui.blocklyText.blockTitle.LCDDisplay": "LCD顯示屏",
    "gui.blocklyText.blockTitle.digitalTube": "數碼管",
    "gui.blocklyText.blockTitle.oledDisplay": "OLED顯示屏",
    "gui.blocklyText.blockTitle.onBoardSource": "板載資源",
    "gui.blocklyText.blockTitle.rgbControl": "RGB燈控制",
    "gui.blocklyText.blockTitle.maxbotResource": "max:bot資源",
    "gui.blocklyText.blockTitle.lineInspection": "巡線",
    "gui.blocklyText.blockTitle.outerSensor": "外置傳感器",
    "gui.blocklyText.blockTitle.consolePrint": "控制台輸出",
    "gui.blocklyText.blockTitle.radio": "无线广播",
    "gui.blocklyText.blockTitle.wifi": "wifi",
    "gui.blocklyText.blockTitle.motor": "电机控制",
    "gui.blocklyText.blockTitle.rgblightcontrol": "板载led",
    "gui.blocklyText.blockTitle.vibrationMotor": "振动马达",
    "gui.blocklyText.romeo.d1ProgramStart": "D1機器人程序開始",
    "gui.blocklyText.romeo.readdigital": "讀取 [PIN] 引腳 [MODULE] 的值",
    "gui.blocklyText.romeo.buttonModule": "按鈕模塊",
    "gui.blocklyText.romeo.digitalSensor": "數字傳感器",
    "gui.blocklyText.romeo.collisionSensor": "碰撞傳感器",
    "gui.blocklyText.romeo.lineSensor": "巡線傳感器",
    "gui.blocklyText.romeo.infraredSwitch": "紅外開關",
    "gui.blocklyText.romeo.vibrationSensor": "震動傳感器",
    "gui.blocklyText.romeo.readAnalog": "讀 [PIN] 引腳 [SENSOR] 的值",
    "gui.blocklyText.romeo.lightSensor": "光線傳感器",
    "gui.blocklyText.romeo.anologSensor": "模擬傳感器",
    "gui.blocklyText.romeo.soundSensor": "聲音傳感器",
    "gui.blocklyText.romeo.soilMoistureSensor": "土壤濕度傳感器",
    "gui.blocklyText.romeo.frameSensor": "火焰傳感器",
    "gui.blocklyText.romeo.open": "打開",
    "gui.blocklyText.romeo.close": "關閉",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [PIN] 引腳的 [MODULE]",
    "gui.blocklyText.romeo.ledLights": "LED燈",
    "gui.blocklyText.romeo.digitalActuator": "數字執行器",
    "gui.blocklyText.romeo.relay": "繼電器",
    "gui.blocklyText.romeo.fan": "風扇",
    "gui.blocklyText.romeo.writeAnalog": "設置 [PIN] 引腳 [MODULE] 值為 [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "模擬執行器",
    "gui.blocklyText.romeo.fanModule": "風扇模塊",
    "gui.blocklyText.romeo.robotSpeedMove": "機器人以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.romeo.forward": "前進",
    "gui.blocklyText.romeo.backward": "後退",
    "gui.blocklyText.romeo.turnLeft": "左轉",
    "gui.blocklyText.romeo.turnRight": "右轉",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "設置電機 [MOTOR] 以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.romeo.interface": "接口",
    "gui.blocklyText.romeo.motorForward": "正轉",
    "gui.blocklyText.romeo.motorReverse": "反轉",
    "gui.blocklyText.romeo.stopMotor": "機器人(M1和M2電機)停止",
    "gui.blocklyText.romeo.readUltrasonic": "讀取超聲波距離(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.romeo.lm35": "讀取 [PIN] 引腳LM35溫度(℃)",
    "gui.blocklyText.romeo.ds18b20": "讀取 [PIN] 引腳DS18B20溫度(℃)",
    "gui.blocklyText.romeo.pressInfrared": "紅外 [PIN] 引腳是否接收到 [BUTTON] 按下？",
    "gui.blocklyText.romeo.volumePlus": "音量+",
    "gui.blocklyText.romeo.switch": "開關",
    "gui.blocklyText.romeo.volumeMinus": "音量-",
    "gui.blocklyText.romeo.startPause": "播放/暫停",
    "gui.blocklyText.romeo.up": "上",
    "gui.blocklyText.romeo.down": "下",
    "gui.blocklyText.romeo.dataReadable": "串口有數據可讀？",
    "gui.blocklyText.romeo.readSerialData": "讀取串口數據",
    "gui.blocklyText.romeo.timer": "系統運行時間(ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "設置 [PIN] 引腳喇叭音調為 [TONE] 節拍為 [BEAT]",
    "gui.blocklyText.romeo.half": "1/2",
    "gui.blocklyText.romeo.quarter": "1/4",
    "gui.blocklyText.romeo.oneInEighth": "1/8",
    "gui.blocklyText.romeo.wholeBeat": "整拍",
    "gui.blocklyText.romeo.doubleBeat": "雙拍",
    "gui.blocklyText.romeo.stop": "停止",
    "gui.blocklyText.romeo.setServo": "設置 [PIN] 引腳伺服舵機為 [DEGREE] 度",
    "gui.blocklyText.pythondfr0548.setServo": " 设置 [PIN] 引脚伺服舵机为 [DEGREE] 度",
    "gui.blocklyText.romeo.segment": "設置八段數碼管(D6-D13引腳)顯示 [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "串口 [WAY] [STRING] [LINE]",
    "gui.blocklyText.romeo.originalOutput": "原始輸出",
    "gui.blocklyText.romeo.stringOutput": "字符串輸出",
    "gui.blocklyText.romeo.hexOutput": "十六進制輸出",
    "gui.blocklyText.romeo.wrap": "換行",
    "gui.blocklyText.romeo.noWrap": "不換行",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "從串口讀一個 [READTYPE]",
    "gui.blocklyText.arduinounoR3.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.romeo.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.max.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.maxbot.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.microbit.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.arduino.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.arduinoNano.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.mega2560.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.firebeetleesp32.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.romeo.int": "整數",
    "gui.blocklyText.romeo.float": "小數",
    "gui.blocklyText.romeo.setBaudRate": "設置串口波特率為 [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "舵機 [INDEX] 角度 [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "讀取引腳 [PIN] 脈衝寬度 模式 [LEVEL] 超時 [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "低電平",
    "gui.blocklyText.romeo.high": "高電平",
    "gui.blocklyText.romeo.noSound": "無音",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino主程序",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno 主程序",
    "gui.blocklyText.boson.starts": "BOSON MC1主程序",
    "gui.blocklyText.pico.starts": "Pico主程序",
    "gui.blocklyText.boson.mpyAttitudeEvent": "当BOSON MC1板 [ATTITUDE]",
    "gui.blocklyText.boson.mpyBoardShakeThrown": "BOSON MC1板 [ATTITUDE]?",
    "gui.blocklyText.boson.readAcceleration": "读取加速度的值(m-g) [TYPE]",
    "gui.blocklyText.boson.setAccelerationRange": "设置加速度范围为 [ACCRANGE]",
    "gui.blocklyText.boson.vibrationMotor": "[SWITCH] 振动马达",
    "gui.blocklyText.boson.vibrationMotorTime": "持续振动 [TIME] 秒",
    "gui.blocklyText.boson.displayClear.js": "屏幕清屏为 [COLOR]",
    "gui.blocklyText.boson.displayClear": "屏幕清屏为 [COLOR] [PUSH]",
    "gui.blocklyText.boson.displaySetLineWidth": "设置线宽为 [LINE] 方向 [DIRECTION]",
    "gui.blocklyText.boson.displayText": "屏幕显示文字 [TEXT] 在坐标x[X] y[Y] 颜色[COLOR] 字体[FONT] 背景色[BACKGROUND] [NOWRAP] ",
    "gui.blocklyText.boson.displayText.js": "屏幕显示文字 [TEXT] 在坐标x[X] y[Y] 颜色[COLOR] [NOWRAP]",
    "gui.blocklyText.boson.displayTextLineshow": "在屏幕第[RAW]行显示文字 [TEXT] 颜色[COLOR] 字体[FONT] 背景色[BACKGROUND] [NOWRAP] [MIDDLE] ",
    "gui.blocklyText.boson.displayPixel": "画点 x[X] y[Y] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayLine": "画线 起点x[X1] y[Y1] 终点x[X2] y[Y2] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayHline": "画水平线 起点x[X] y[Y] 长度 [LEN] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayVline": "画垂直线 起点x[X] y[Y] 长度 [LEN] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayRect": "画矩形 起点x[X] y[Y] 宽度[W] 高度[H] 颜色 [COLOR] 位置 [POSITION] [FILL]",
    "gui.blocklyText.boson.displayRect.js": "画矩形 起点x[X] y[Y] 宽度[W] 高度[H] 颜色 [COLOR] [FILL]",
    "gui.blocklyText.boson.displayCircle": "画圆 圆心x[X] y[Y] 半径[R] 颜色 [COLOR] 位置 [POSITION] [FILL]",
    "gui.blocklyText.boson.displayCircle.js": "画圆 圆心x[X] y[Y] 半径[R] 颜色 [COLOR] [FILL]",
    "gui.blocklyText.boson.displayTriangle": "画三角形 x1[X1] y1[Y1] x2[X2] y2[Y2] x3[X3] y3[Y3] 颜色 [COLOR] 位置 [POSITION] [FILL]",
    "gui.blocklyText.boson.displayRoundRect": "画圆角矩形 起点x[X] y[Y] 宽度[WIDTH] 高度[HEIGTH] 圆角半径[R] 颜色 [COLOR] 位置 [POSITION]",
    "gui.blocklyText.boson.displayThreePointArc": "三点画弧 x1[X1] y1[Y1] x2[X2] y2[Y2] x3[X3] y3[Y3] 颜色 [COLOR] 离散间距 [SPACE] 位置 [POSITION]",
    "gui.blocklyText.boson.mpyDisplayComputerImage": "从电脑加载图片 [IMAGE] 以灰度显示到 x[X] y[Y] 位置 [POSITION] 模式[TYPE] ",
    "gui.blocklyText.boson.mpyDisplayComputerRgbImage": "从电脑加载图片 [IMAGE] 显示到 x[X] y[Y] 位置 [POSITION]",
    "gui.blocklyText.boson.boson_mpyDisplayFileSystemImage": "从文件系统加载图像 [PATH]",
    "gui.blocklyText.boson.showPixelCoordinate": "在坐标x [X] y [Y] 显示 [SHOWMSG] 字体 [PIXEL] 颜色 [COLOR] 背景 [BGCOLOR]",
    "gui.blocklyText.boson.displayQRCode": "绘制二维码 [QRCODE] 起点x[X] y[Y] 大小 [QRSIZE] 顏色 [COLOR]",
    "gui.blocklyText.boson.mpyDisplayClockInit": "初始化时钟 [NAME] 起点x[X] y[Y] 半径 [RADIUS] 颜色 [COLOR] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.mpyDisplayClockUpdate": "更新时钟 [NAME]",
    "gui.blocklyText.boson.displayClockInit": "初始化时钟 [ID] 起点x[X] y[Y] 半径 [RADIUS] 颜色 [COLOR] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.displayClockUpdate": "更新时钟 [ID]",
    "gui.blocklyText.boson.mpyDisplayProgressInit": "初始化进度条 [NAME] 起点x[X] y[Y] 宽度 [WIDTH] 高度 [HEIGTH] 进度 [PROGRESS]% 进度颜色 [COLOR1] 进度背景色 [COLOR2] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.mpyDisplayProgressUpdate": "更新进度条 [NAME] 进度为 [PROGRESS]%",
    "gui.blocklyText.boson.displayProgressInit": "初始化进度条 [ID] 起点x[X] y[Y] 宽度 [WIDTH] 高度 [HEIGTH] 进度 [PROGRESS] 进度颜色 [COLOR1] 进度背景色 [COLOR2] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.displayProgressUpdate": "更新进度条 [ID] 进度为 [PROGRESS]",
    "gui.blocklyText.boson.displayBrightness": "屏幕亮度为 [VALUE]%",
    "gui.blocklyText.boson.font16_16": "字体font16_16",
    "gui.blocklyText.boson.font20_20": "字体font20_20",
    "gui.blocklyText.boson.middleMenu.true": "居中",
    "gui.blocklyText.boson.middleMenu.false": "正常",
    "gui.blocklyText.boson.directionMenu.false": "正常",
    "gui.blocklyText.boson.directionMenu.true": "反向",
    "gui.blocklyText.boson.positionMenu.customize": "自定义",
    "gui.blocklyText.boson.positionMenu.horizontalCenter": "水平居中",
    "gui.blocklyText.boson.positionMenu.leftCenter": "左居中",
    "gui.blocklyText.boson.positionMenu.rightCenter": "右居中",
    "gui.blocklyText.boson.positionMenu.upCenter": "上居中",
    "gui.blocklyText.boson.positionMenu.downCenter": "下居中",
    "gui.blocklyText.boson.positionMenu.leftTop": "左上角",
    "gui.blocklyText.boson.positionMenu.leftDown": "左下角",
    "gui.blocklyText.boson.positionMenu.rightTop": "右上角",
    "gui.blocklyText.boson.positionMenu.rightDown": "右下角",
    "gui.blocklyText.boson.strength": "强度",
    "gui.blocklyText.boson.switchMenu.on": "打开",
    "gui.blocklyText.boson.switchMenu.off": "关闭",
    "gui.blocklyText.boson.nowrapMenu.true": "换行",
    "gui.blocklyText.boson.nowrapMenu.false": "不换行",
    "gui.blocklyText.boson.fillMenu.false": "空心",
    "gui.blocklyText.boson.fillMenu.true": "实心",
    "gui.blocklyText.boson.push.false": "清除缓存",
    "gui.blocklyText.boson.push.true": "立刻生效",
    "gui.blocklyText.esp8266.starts": "ESP8266 主程序开始",
    "gui.blocklyText.esp32.displayInLine": "屏幕显示文字 [TEXT] 在第 [LINE] 行",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "屏幕写入缓存文字 [TEXT] 在第 [LINE] 行 模式 [SHOWMODE]",
    "gui.blocklyText.esp32.normal": "普通",
    "gui.blocklyText.esp32.rev": "反转",
    "gui.blocklyText.esp32.trans": "透明",
    "gui.blocklyText.esp32.XOR": "XOR",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "屏幕清除缓存第 [LINE] 行",
    "gui.blocklyText.esp32.displayClearInLine": "屏幕显示清除第 [LINE] 行",
    "gui.blocklyText.esp32.buzzPinC": "P6  (板载蜂鸣器)",
    "gui.blocklyText.esp32.buzzerRedirect": "蜂鸣器重定向到引脚 [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "全部",
    "gui.blocklyText.esp32.setTouchThreshold": "設置按鍵 [PIN] 觸摸阈值為 [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "讀取按鍵 [PIN] 觸摸值",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 主程序",
    "gui.blocklyText.esp32.btnIsPressed": "按鈕 [BUTTON] 被 [BUTTONSTATE]？",
    "gui.blocklyText.esp32.whenBtnPress": "當 [REFERENCE] 按鈕 [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "當觸摸按鍵 [REFERENCE] 被 [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "當 [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "觸摸按鍵 [PIN] 被 [TOUCHSTATE]？",
    "gui.blocklyText.esp32.buzzerSwitch": "停止後台播放",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "後台播放音符 [NOTE]",
    "gui.blocklyText.esp32.setBuzzerFreq": "播放音符 [NOTE] [BEAT] 拍",
    "gui.blocklyText.esp32.getSoundIntensity": "讀取麥克風聲音強度",
    "gui.blocklyText.esp32.readBrightness": "讀取環境光強度",
    "gui.blocklyText.esp32.setLightsColor": "燈號 [LIGHTS] 顯示顏色 [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "紅 [RED] 綠 [GREEN] 藍 [BLUE]",
    "gui.blocklyText.esp32.accelerationCalibration": "加速度校准 X轴偏移 [XOFFSET] Y轴偏移 [YOFFSET] Z轴偏移 [ZOFFSET] (m-g)",
    "gui.blocklyText.esp32.acceleration": "讀取加速度的值(mg) [TYPE]",
    "gui.blocklyText.esp32.esp32_tiltAngle": "读取倾斜角 [TYPE]",
    "gui.blocklyText.esp32.esp32_boardShakeThrown": "掌控板 [ATTITUDE]？",
    "gui.blocklyText.esp32.esp32_boardTiltState": "掌控板倾斜 [GESTUREMPY]",
    "gui.blocklyText.esp32.forward": "向前倾斜",
    "gui.blocklyText.esp32.back": "向后倾斜",
    "gui.blocklyText.esp32.left": "向左倾斜",
    "gui.blocklyText.esp32.right": "向右倾斜",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationRange": "设置加速度范围为 [ACCRANGE]",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationResolution": "设置加速度分辨率为 [ACCRESOLUTION]",
    "gui.blocklyText.esp32.esp32_mpyAccurateAccValue": "校准加速度值 X轴偏差 [XAXIS] Y轴偏差 [YAXIS] Z轴偏差 [ZAXIS]",
    "gui.blocklyText.esp32.esp32_mpyAccurateCompass": "校准指南针",
    "gui.blocklyText.esp32.esp32_mpyCompassDirection": "指南针方向",
    "gui.blocklyText.esp32.esp32_mpyAxisMagnetic": "[TYPE] 轴 磁力",
    "gui.blocklyText.esp32.esp32_mpyMagneticStrength": "磁场强度",
    "gui.blocklyText.esp32.esp32_mpyRemoveMagnetic": "去除当前环境磁场",
    "gui.blocklyText.esp32.esp32_mpyReturnRunTimeDiff": "返回运行时间差, 开始时间 [STARTTIME] 结束时间 [ENDTIME]",
    "gui.blocklyText.esp32.esp32_mpyRunTimer": "运行时间 [TIMETYPE]",
    "gui.blocklyText.esp32.millisecond": "毫秒",
    "gui.blocklyText.esp32.microsencond": "微秒",
    "gui.blocklyText.esp32.esp32_mpyLocalTime": "本地时间 [LOCALTIME]",
    "gui.blocklyText.esp32.year": "年",
    "gui.blocklyText.esp32.month": "月",
    "gui.blocklyText.esp32.day": "日",
    "gui.blocklyText.esp32.hour": "时",
    "gui.blocklyText.esp32.minute": "分",
    "gui.blocklyText.esp32.sencond": "秒",
    "gui.blocklyText.esp32.weeknumber": "星期数",
    "gui.blocklyText.esp32.daysnumber": "天数",
    "gui.blocklyText.esp32.esp32_mpyReset": "复位",
    "gui.blocklyText.esp32.esp32_mpyMacAddr": "MAC 地址",
    "gui.blocklyText.esp32.displayInXY": "屏幕顯示文字 [TEXT] 在坐標 X: [X] Y: [Y] 預覽 [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "屏幕写入缓存文字 [TEXT] 在坐标 X: [X] Y: [Y] 模式 [SHOWMODE] 预览 [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "屏幕顯示為 [COLOR]",
    "gui.blocklyText.esp32.fillScreenForMpy": "屏幕寫入緩存為 [COLORMPYTHON]",
    "gui.blocklyText.esp32.clear": "清屏",
    "gui.blocklyText.esp32.fullBright": "全亮",
    "gui.blocklyText.esp32.blackBackground": "黑底",
    "gui.blocklyText.esp32.whiteBackground": "白底",
    "gui.blocklyText.esp32.black": "全黑",
    "gui.blocklyText.esp32.white": "全白",
    "gui.blocklyText.esp32.lineDrawing": "畫線 起點x1:[X1] y1:[Y1] 終點x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "接通",
    "gui.blocklyText.esp32.disconnected": "斷開",
    "gui.blocklyText.esp32.rectangleDrawing": "畫矩形[FILL] 起點 x:[X] y:[Y] 寬:[WIDTH] 高:[HEIGHT]",
    "gui.blocklyText.esp32.mpyrectangleDrawing": "[DRAWERASE] [FILL] 画矩形 x:[X] y:[Y] 宽:[WIDTH] 高:[HEIGHT]",
    "gui.blocklyText.esp32.screenDisplay": "屏幕緩存顯示生效",
    "gui.blocklyText.esp32.circleDrawing": "畫圓[FILL] 圓心 x:[X] y:[Y] 半徑:[RADIUS]",
    "gui.blocklyText.esp32.fill": "填充",
    "gui.blocklyText.esp32.notFill": "不填充",
    "gui.blocklyText.esp32.pointDrawing": "畫點 x:[X] y:[Y]",
    "gui.blocklyText.esp32.esp32_mpyProgressBar": "进度条 x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT] 进度 [PROG]",
    "gui.blocklyText.esp32.esp32_mpyColumnarProgressBar": "柱状条 [COLUMNARSTATE] x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT] 进度 [PROG]",
    "gui.blocklyText.esp32.vertical": "垂直",
    "gui.blocklyText.esp32.horizontal": "水平",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseLine": "[DRAWERASE] [COLUMNARSTATE] 线 x [X] y [Y] 长度 [LENGTH]",
    "gui.blocklyText.esp32.draw": "绘制",
    "gui.blocklyText.esp32.erase": "擦除",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCornerBorder": "[DRAWERASE] 弧角边框 x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT] 半径 [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCircle": "[DRAWERASE] [FILL] 圆 x [X] y [Y] 半径 [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseTriangle": "[DRAWERASE] [FILL] 三角形 x1 [X1] y1 [Y1] x2 [X2] y2 [Y2] x3 [X3] y3 [Y3]",
    "gui.blocklyText.esp32.esp32_mpyShowPixelCoordinate": "在坐标 x [X] y [Y] 显示 [SHOWMSG] 以 [PIXEL] 字体 [LINE]",
    "gui.blocklyText.esp32.Digiface_11px": "仿数码管 11像素",
    "gui.blocklyText.esp32.Digiface_16px": "仿数码管 16像素",
    "gui.blocklyText.esp32.Digiface_21px": "仿数码管 21像素",
    "gui.blocklyText.esp32.Digiface_30px": "仿数码管 30像素",
    "gui.blocklyText.esp32.Digiface_44px": "仿数码管 44像素",
    "gui.blocklyText.esp32.Digiface_ltalic_30px": "仿数码管 斜体 30像素",
    "gui.blocklyText.esp32.Digiface_ltalic_42px": "仿数码管 斜体 42像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_12px": "DejaVu Sans Mono 12像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_16px": "DejaVu Sans Mono 16像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_21px": "DejaVu Sans Mono 21像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_12px": "DejaVu Sans Mono 黑体 12像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_16px": "DejaVu Sans Mono 黑体 16像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_21px": "DejaVu Sans Mono 黑体 21像素",
    "gui.blocklyText.esp32.esp32_mpyQRCode": "x [X] y [Y] 绘制二维码 [QRCODE] 尺寸 [IMGSIZE]",
    "gui.blocklyText.esp32.esp32_mpyInitClock": "初始化时钟 [CLOCKNAME] x [X] y [Y] 半径 [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyClockReadTime": "时钟 [CLOCKNAME] 读取时间",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseClock": "[DRAWERASE] 时钟 [CLOCKNAME]",
    "gui.blocklyText.esp32.setLineWidth": "設置線寬為 [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "設置數字引腳 [PIN] 輸出 [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "讀取數字引腳 [PIN]",
    "gui.blocklyText.esp32.analogWrite": "設置模擬引腳 [PIN] 輸出(PWM) [VALUE]",
    "gui.blocklyText.esp32.analogRead": "讀取模擬引腳 [PIN]",
    "gui.blocklyText.esp32.serialPort0": "串口0",
    "gui.blocklyText.esp32.serialPort1": "串口1",
    "gui.blocklyText.esp32.serialPort2": "串口2",
    "gui.blocklyText.esp32.seriaRemapping": "設置 [SERIAL] Rx [RX] Tx [TX] 波特率為 [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "屏幕顯示圖片 [IMAGE] 在坐標 x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "屏幕寫入緩存圖片 [IMAGE] 在坐標 x:[X] y:[Y]",
    "gui.blocklyText.esp32.pressed": "按下",
    "gui.blocklyText.esp32.loosened": "鬆開",
    "gui.blocklyText.esp32.all": "全部(-1)",
    "gui.blocklyText.esp32.setBaud": "設置 串口0 波特率[BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "從 [SERIAL] 讀取一個 [READTYPE]",
    "gui.blocklyText.esp32.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.esp32.setBrightness": "設置LED燈亮度為 [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "讀取LED燈亮度",
    "gui.blocklyText.esp32.print": "打印 [DATA]",
    "gui.blocklyText.esp32.TiltForward": "向前傾斜",
    "gui.blocklyText.esp32.TiltBack": "向後傾斜",
    "gui.blocklyText.esp32.TiltLeft": "向左傾斜",
    "gui.blocklyText.esp32.TiltRight": "向右傾斜",
    "gui.blocklyText.esp32.ScreenUp": "屏幕朝上",
    "gui.blocklyText.esp32.ScreenDown": "屏幕朝下",
    "gui.blocklyText.esp32.Shake": "搖一搖",
    "gui.blocklyText.esp32.isGesture": "當前姿態為 [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "關閉 [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "全部",
    "gui.blocklyText.esp32.rgbdisable.No.0": "0號",
    "gui.blocklyText.esp32.rgbdisable.No.1": "1號",
    "gui.blocklyText.esp32.rgbdisable.No.2": "2號",
    "gui.blocklyText.esp32.playMusic": "播放音乐 [MUSIC] 重复 [REPEAT]",
    "gui.blocklyText.esp32.Once": "播放一次",
    "gui.blocklyText.esp32.Forever": "无限循环",
    "gui.blocklyText.esp32.OnceInBackground": "在后台播放一次",
    "gui.blocklyText.esp32.ForeverInBackground": "在后台无限循环",
    "gui.blocklyText.radio.radioSwitch": "[DATA] 无线广播",
    "gui.blocklyText.radio.radioswitch.on": "打开",
    "gui.blocklyText.radio.radioswitch.off": "关闭",
    "gui.blocklyText.radio.setChannel": "设置无线广播 频道为 [DATA]",
    "gui.blocklyText.radio.radioSend": "无线广播 发送 [DATA]",
    "gui.blocklyText.radio.radioreceived": "无线广播 接收消息",
    "gui.blocklyText.radio.receivingEven": "当接收到 [DATA]",
    "gui.blocklyText.radio.Msg": "无线消息",
    "gui.blocklyText.radio.receivingSpecificEven": "当接收到特定消息 [DATA] ",
    "gui.blocklyText.esp32.radioSwitch": "[DATA] 无线广播",
    "gui.blocklyText.esp32.radioswitch.on": "打开",
    "gui.blocklyText.esp32.radioswitch.off": "关闭",
    "gui.blocklyText.esp32.setChannel": "设置无线广播 频道为 [DATA]",
    "gui.blocklyText.esp32.radioSend": "无线广播 发送 [DATA]",
    "gui.blocklyText.esp32.radioreceived": "无线广播 接收消息",
    "gui.blocklyText.esp32.receivingEven": "当 收到无线广播消息[DATA]时 执行",
    "gui.blocklyText.esp32.receivingSpecificEven": "当 收到特定无线广播消息 [DATA] 时 执行",
    "gui.blocklyText.esp32.connectWIFI": "连接Wi-Fi 名称 [SSID] 密码 [PASS]",
    "gui.blocklyText.esp32.disconnectWIFI": "断开Wi-Fi连接",
    "gui.blocklyText.esp32.checkWIFI": "已连接到Wi-Fi",
    "gui.blocklyText.esp32.configurationInformation": "Wi-Fi配置信息",
    "gui.blocklyText.esp32.configurationInformationIP": "Wi-Fi配置信息[IP]",
    "gui.blocklyText.esp32.wifi.IP": "IP",
    "gui.blocklyText.esp32.wifi.netmask": "netmask",
    "gui.blocklyText.esp32.wifi.geteway": "geteway",
    "gui.blocklyText.esp32.wifi.DNS": "DNS",
    "gui.blocklyText.esp32.openAP": "开启AP模式 名称 [SSID] 密码 [PASS] 信道 [CHANNEL]",
    "gui.blocklyText.esp32.closeAP": "关闭AP模式",
    "gui.blocklyText.esp32.synchronizeTime": "同步网络时间 时区 [TIMEZONE] 授时服务器 [SERVER]",
    "gui.blocklyText.esp32.timezone.UTC+8": "UTC+8",
    "gui.blocklyText.esp32.timezone.UTC+9": "UTC+9",
    "gui.blocklyText.esp32.timezone.UTC+10": "UTC+10",
    "gui.blocklyText.esp32.timezone.UTC+11": "UTC+11",
    "gui.blocklyText.esp32.timezone.UTC+12": "UTC+12",
    "gui.blocklyText.esp32.timezone.UTC-12": "UTC-12",
    "gui.blocklyText.esp32.timezone.UTC-11": "UTC-11",
    "gui.blocklyText.esp32.timezone.UTC-10": "UTC-10",
    "gui.blocklyText.esp32.timezone.UTC-9": "UTC-9",
    "gui.blocklyText.esp32.timezone.UTC-8": "UTC-8",
    "gui.blocklyText.esp32.timezone.UTC-7": "UTC-7",
    "gui.blocklyText.esp32.timezone.UTC-6": "UTC-6",
    "gui.blocklyText.esp32.timezone.UTC-5": "UTC-5",
    "gui.blocklyText.esp32.timezone.UTC-4": "UTC-4",
    "gui.blocklyText.esp32.timezone.UTC-3": "UTC-3",
    "gui.blocklyText.esp32.timezone.UTC-2": "UTC-2",
    "gui.blocklyText.esp32.timezone.UTC-1": "UTC-1",
    "gui.blocklyText.esp32.timezone.UTC": "UTC",
    "gui.blocklyText.esp32.timezone.UTC+1": "UTC+1",
    "gui.blocklyText.esp32.timezone.UTC+2": "UTC+2",
    "gui.blocklyText.esp32.timezone.UTC+3": "UTC+3",
    "gui.blocklyText.esp32.timezone.UTC+4": "UTC+4",
    "gui.blocklyText.esp32.timezone.UTC+5": "UTC+5",
    "gui.blocklyText.esp32.timezone.UTC+6": "UTC+6",
    "gui.blocklyText.esp32.timezone.UTC+7": "UTC+7",
    "gui.blocklyText.pico.pico_led": "LED输出 [STATE]",
    "gui.blocklyText.pico.pico_readTemp": "读取温度(℃)",
    "gui.blocklyText.pico.pico_pinMode": "设置 [PIN] 引脚模式为 [MODE]",
    "gui.blocklyText.pico.serialPort0": "USB",
    "gui.blocklyText.pico.serialPort1": "硬串口1",
    "gui.blocklyText.pico.serialPort2": "硬串口2",
    "gui.blocklyText.pico.seriaRemapping": "设置 [SERIALPORT] Rx [PINR] Tx [PINT] 波特率为 [BAUD]",
    "gui.blocklyText.telloesp32.arduinoUnoStarts": "RMTT ESP32主程序開始",
    "gui.blocklyText.telloesp32.ledControl": "燈光控制",
    "gui.blocklyText.telloesp32.matrixControl": "點陣控制",
    "gui.blocklyText.telloesp32.flyControl": "飛行控制",
    "gui.blocklyText.telloesp32.customCommand": "自訂命令",
    "gui.blocklyText.telloesp32.pinOperation": "引腳操作",
    "gui.blocklyText.telloesp32.readDigital": "讀取數字引腳 [PIN]",
    "gui.blocklyText.telloesp32.readAnalog": "讀取模擬引腳 [PIN]",
    "gui.blocklyText.telloesp32.setPwm": "設置PWM引腳 [PIN] 輸出 [VALUE]",
    "gui.blocklyText.telloesp32.setDigital": "設置數位引腳 [PIN] 輸出為 [LEVEL]",
    "gui.blocklyText.telloesp32.functionalModule": "功能模組",
    "gui.blocklyText.telloesp32.telloesp32_matrix": "顯示圖案[MARK]",
    "gui.blocklyText.telloesp32.showPatternWhenStart": "點陣屏開機顯示圖案[MARK]",
    "gui.blocklyText.telloesp32.setMatrixLight": "設置螢幕亮度[NUM]",
    "gui.blocklyText.telloesp32.matrixDisplayText": "顯示單個文字 [TEXT] 顏色 [COLOR] 亮度 [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayPreset": "顯示預設圖案 [PATTERN] 顏色 [COLOR] 亮度 [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayTextMpy": "顯示單個文字 [TEXT] 顏色 [COLOR]",
    "gui.blocklyText.telloesp32.matrixDisplayPresetMpy": "顯示預設圖案 [PATTERN] 顏色 [COLOR]",
    "gui.blocklyText.telloesp32.empty": "空",
    "gui.blocklyText.telloesp32.WiFi": "WiFi",
    "gui.blocklyText.telloesp32.DownLoad": "下載",
    "gui.blocklyText.telloesp32.Power": "電源",
    "gui.blocklyText.telloesp32.heart": "心 heart",
    "gui.blocklyText.telloesp32.heartOutline": "心框架 heart-outline",
    "gui.blocklyText.telloesp32.matrixR": "紅色",
    "gui.blocklyText.telloesp32.matrixB": "藍色",
    "gui.blocklyText.telloesp32.matrixP": "紫色",
    "gui.blocklyText.telloesp32.matrixDisplayRollPicture": "滾動顯示圖案 方向 [DIRECT] 移動頻率 [SPEED] 圖案[MARK]",
    "gui.blocklyText.telloesp32.matrixDisplayRollText": "滾動顯示文字 方向 [DIRECT] 移動頻率 [SPEED] 文字 [TEXT] 顏色 [COLOR]",
    "gui.blocklyText.telloesp32.telloSetWifiChannel": "設置Wi-Fi通道 [CHANNEL]",
    "gui.blocklyText.telloesp32.initTOF": "啟用TOF模組",
    "gui.blocklyText.telloesp32.readTOF": "讀取TOF測距 (毫米)",
    "gui.blocklyText.telloesp32.buttonIsPressed": "按鍵是否按下？",
    "gui.blocklyText.telloesp32.setRgbColor": "LED燈顏色 [COLOR]",
    "gui.blocklyText.telloesp32.rgbToColor": "LED燈顏色 紅: [RED] 綠: [GREEN] 藍: [BLUE]",
    "gui.blocklyText.telloesp32.rgbBreathWithColor": "LED 呼吸燈 顏色[COLOR] 頻率(Hz) [TIME]",
    "gui.blocklyText.telloesp32.rgbBreath": "LED呼吸燈 紅: [RED] 綠: [GREEN] 藍: [BLUE] 頻率(Hz): [SP]",
    "gui.blocklyText.telloesp32.rgbFlashingColor": "LED閃爍 顏色1 [COLOR1] 和 顏色2 [COLOR2] 頻率(Hz) [SP]",
    "gui.blocklyText.telloesp32.rgbFlashing": "LED閃爍 紅1: [RED1] 綠1: [GREEN1] 藍1: [BLUE1] - 紅2: [RED2] 綠2: [GREEN2] 藍2: [BLUE2] - 頻率(Hz): [SP]",
    "gui.blocklyText.telloesp32.ledCloseEffects": "LED關閉特效",
    "gui.blocklyText.telloesp32.matrixCloseEffects": "螢幕關閉特效",
    "gui.blocklyText.telloesp32.sendCustomCommand": "發送自訂命令 [CMD]",
    "gui.blocklyText.telloesp32.recvCustomCommand": "收到自訂回應字串 [MSG], 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.recvCustomCommandNum": "收到自訂回應數值, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.serialportOperation": "串口操作",
    "gui.blocklyText.telloesp32.dataReadable": "[SERIAL] 有數據可讀?",
    "gui.blocklyText.telloesp32.readSerialData": "讀取 [SERIAL] 數據",
    "gui.blocklyText.telloesp32.setBaud": "設置串口0串列傳輸速率為 [BAUD]",
    "gui.blocklyText.telloesp32.readSerialDataIntFloat": "從 [SERIAL] 讀取一個 [READTYPE]",
    "gui.blocklyText.telloesp32.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小數",
    "gui.blocklyText.telloesp32.seriaRemapping": "設置 [SERIAL] Rx [RX] Tx [TX] 串列傳輸速率為 [BAUD]",
    "gui.blocklyText.telloesp32.systemResource": "系統資源",
    "gui.blocklyText.telloesp32.timer": "系統執行時間(ms)",
    "gui.blocklyText.telloesp32.consolePrint": "控制台輸出",
    "gui.blocklyText.telloesp32.print": "列印 [DATA]",
    "gui.blocklyText.telloesp32.telloRiseDown": "[RISEDOWN] [NUMS] 釐米",
    "gui.blocklyText.telloesp32.rise": "上升",
    "gui.blocklyText.telloesp32.down": "下降",
    "gui.blocklyText.telloesp32.telloTurnDirection": "向 [DIRECTION] 飛 [NUMS] 釐米",
    "gui.blocklyText.telloesp32.turnleft": "左",
    "gui.blocklyText.telloesp32.turnright": "右",
    "gui.blocklyText.telloesp32.turnForward": "前",
    "gui.blocklyText.telloesp32.turnBack": "後",
    "gui.blocklyText.telloesp32.telloMotorOn": "進入起槳模式",
    "gui.blocklyText.telloesp32.telloMotorOff": "退出起槳模式",
    "gui.blocklyText.telloesp32.telloTakeOff": "起飛",
    "gui.blocklyText.telloesp32.telloThrowFly": "5秒內拋飛",
    "gui.blocklyText.telloesp32.telloLanding": "降落",
    "gui.blocklyText.telloesp32.telloClockwiseRotation": "[CLOCKWISE] 旋轉 [NUMS] 度",
    "gui.blocklyText.telloesp32.clockwise": "順時針",
    "gui.blocklyText.telloesp32.counterClockwise": "逆時針",
    "gui.blocklyText.telloesp32.telloStartControl": "開啟飛行控制（綠燈亮起時按下按鈕）直到成功",
    "gui.blocklyText.telloesp32.telloGetHeight": "獲取 [HEIGHT],超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.relatively": "相對高度(dm)",
    "gui.blocklyText.telloesp32.barometer": "氣壓計高度(m)",
    "gui.blocklyText.telloesp32.tof": "TOF高度(mm)",
    "gui.blocklyText.telloesp32.telloTemp": "主機板溫度(°C),超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.lowest": "最低",
    "gui.blocklyText.telloesp32.highest": "最高",
    "gui.blocklyText.telloesp32.telloAttitude": "獲取IMU三軸姿態資料, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.pitch": "俯仰軸",
    "gui.blocklyText.telloesp32.roll": "橫滾軸",
    "gui.blocklyText.telloesp32.translation": "平移軸",
    "gui.blocklyText.telloesp32.telloAcceleration": "獲取IMU三軸加速度數據(0.001g), 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.xaxis": "x軸",
    "gui.blocklyText.telloesp32.yaxis": "y軸",
    "gui.blocklyText.telloesp32.zaxis": "z軸",
    "gui.blocklyText.telloesp32.telloSetSpeed": "設置速度為 [NUMS]cm/s",
    "gui.blocklyText.telloesp32.telloSpeed": "[XYZAXIS] 速度(cm/s), 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloPower": "電池電量( %), 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.MissionPad": "挑戰卡編號, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloMissionPadXyz": "挑戰卡 [XYZ] (cm), 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloCommandResponse": "回復指令, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloSetyawXMid": "飛機相對挑戰卡[MID]旋轉至[YAW]度",
    "gui.blocklyText.telloesp32.telloGetMissionPadNum": "獲取挑戰卡編號, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloCurrentSpeed": "當前速度, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloTime": "電機執行時間 (s), 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloWIFISign": "Wi-Fi 訊噪比, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloSDKVersion": "Tello SDK 版本, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloBinVersion": "Tello固件版本, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloHasHardware": "獲取硬體版本, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.rmttWifiVersion": "擴展模組Wi-Fi版本, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloSN": "Tello SN 碼, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloFlyToXYZ": "飛往(相對於飛機的) x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s",
    "gui.blocklyText.telloesp32.getSpeed": "獲取當前設置速度(cm/s)",
    "gui.blocklyText.telloesp32.getBattery": "獲取當前電池剩餘電量的百分比值",
    "gui.blocklyText.telloesp32.getTime": "獲取電機運轉時間",
    "gui.blocklyText.telloesp32.getWifiNoise": "獲取Wi-Fi訊噪比",
    "gui.blocklyText.telloesp32.getSdkVersion": "獲取sdk版本號",
    "gui.blocklyText.telloesp32.getSnNum": "獲取SN碼",
    "gui.blocklyText.firebeetleesp32.Starts": "FireBeetle ESP32主程序开始",
    "gui.blocklyText.firebeetleesp32e.Starts": "FireBeetle ESP32-E 主程序开始",
    "gui.blocklyText.firebeetleesp32.pinOperation": "引脚操作",
    "gui.blocklyText.firebeetleesp32.readDigital": "读取数字引脚 [PIN]",
    "gui.blocklyText.firebeetleesp32.readAnalog": "读取模拟引脚 [PIN]",
    "gui.blocklyText.firebeetleesp32.setPwm": "设置PWM引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.firebeetleesp32.setDigital": "设置数字引脚 [PIN] 输出为 [LEVEL]",
    "gui.blocklyText.firebeetleesp32.functionalModule": "功能模块",
    "gui.blocklyText.firebeetleesp32.serialportOperation": "串口操作",
    "gui.blocklyText.firebeetleesp32.setBaud": "设置串口波特率为 [BAUD]",
    "gui.blocklyText.firebeetleesp32.serialSendStr": "串口 [WAY] [STRING] [LINE]",
    "gui.blocklyText.firebeetleesp32.dataReadable": "串口有数据可读？",
    "gui.blocklyText.firebeetleesp32.readSerialData": "读取串口数据",
    "gui.blocklyText.firebeetleesp32.timer": "系统运行时间(ms)",
    "gui.blocklyText.firebeetleesp32.originalOutput": "原始输出",
    "gui.blocklyText.firebeetleesp32.stringOutput": "字符串输出",
    "gui.blocklyText.firebeetleesp32.hexOutput": "十六进制输出",
    "gui.blocklyText.firebeetleesp32.wrap": "换行",
    "gui.blocklyText.firebeetleesp32.noWrap": "不换行",
    "gui.blocklyText.firebeetleesp32.high": "高电平",
    "gui.blocklyText.firebeetleesp32.low": "低电平",
    "gui.blocklyText.firebeetleesp32.readSerialDataIntFloat": "从串口读一个 [READTYPE]",
    "gui.blocklyText.firebeetleesp32.int": "整数",
    "gui.blocklyText.firebeetleesp32.float": "小数",
    "gui.blocklyText.firebeetleesp32.readUlrasonic": "读取超声波距离(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.firebeetleesp32.trumpet": "设置引脚 [PIN] 喇叭蜂鸣器音调为 [TONE] 节拍为 [BEAT]",
    "gui.blocklyText.firebeetleesp32.readInfrared": "读取红外接收模块 数字引脚 [PIN]",
    "gui.blocklyText.firebeetleesp32.readPinLevel": "读取引脚 [PIN] 脉冲宽度 模式 [LEVEL] 超时 [TIMEOUT](us)",
    "gui.blocklyText.firebeetleesp32.half": "1/2",
    "gui.blocklyText.firebeetleesp32.quarter": "1/4",
    "gui.blocklyText.firebeetleesp32.oneInEighth": "1/8",
    "gui.blocklyText.firebeetleesp32.wholeBeat": "整拍",
    "gui.blocklyText.firebeetleesp32.doubleBeat": "双拍",
    "gui.blocklyText.firebeetleesp32.noSound": "无音",
    "gui.blocklyText.firebeetleesp32.pwm1": "D5",
    "gui.blocklyText.firebeetleesp32.pwm2": "D9",
    "gui.blocklyText.firebeetleesp32.pwm3": "D8",
    "gui.blocklyText.firebeetleesp32.pwm4": "D7",
    "gui.blocklyText.firebeetleesp32.pwm5": "A4",
    "gui.blocklyText.firebeetleesp32.pwm6": "D2",
    "gui.blocklyText.firebeetleesp32.pwm7": "D3",
    "gui.blocklyText.firebeetleesp32.pwm8": "D4",
    "gui.blocklyText.firebeetleesp32.pwm9": "D6",
    "gui.blocklyText.mpy.MQTTinit": "MQTT初始化參數 [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInit": "MQTT初始化參數 [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInitPlatform": "平臺",
    "gui.blocklyText.mpy.mpyMqttInitPlatformNormal": "通用",
    "gui.blocklyText.mpy.mpyMqttInitPlatformTencent": "腾讯物联网",
    "gui.blocklyText.mpy.MQTTclient_id": "客户端id",
    "gui.blocklyText.mpy.MQTTserver": "服务器地址",
    "gui.blocklyText.mpy.MQTTport": "端口",
    "gui.blocklyText.mpy.MQTTuser": "用户名",
    "gui.blocklyText.mpy.MQTTpassowrd": "密码",
    "gui.blocklyText.mpy.MQTTkeepalive": "keepalive",
    "gui.blocklyText.mpy.MQTTtencent": "MQTT腾讯物联网 [SETTING]",
    "gui.blocklyText.mpy.TencentIOT": "腾讯IOT",
    "gui.blocklyText.mpy.TencentServer": "服务器",
    "gui.blocklyText.mpy.TencentPort": "端口",
    "gui.blocklyText.mpy.TencentAppld": "Appld",
    "gui.blocklyText.mpy.TencentDevice": "设备",
    "gui.blocklyText.mpy.siotServer": "服务器地址",
    "gui.blocklyText.mpy.siotServerUser": "账号",
    "gui.blocklyText.mpy.siotServerPass": "密码",
    "gui.blocklyText.mpy.Tencentoutput": "传出参数",
    "gui.blocklyText.mpy.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.mpy.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.mpy.mpy_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.mpy.EasyIOTServer": "服务器",
    "gui.blocklyText.mpy.EasyIOTServerChina": "中国",
    "gui.blocklyText.mpy.EasyIOTServerGlobal": "全球",
    "gui.blocklyText.mpy.EasyIOTclient_id": "客户端id",
    "gui.blocklyText.mpy.EasyIOT_id": "Iot_id",
    "gui.blocklyText.mpy.EasyIOT_password": "Iot_pwd",
    "gui.blocklyText.mpy.MQTTconnect": "连接MQTT",
    "gui.blocklyText.mpy.mpy_MQTTAddSub": "订阅主题 [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublish": "发布 [DATA] 至 主题 [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublishQos1": "发布 [DATA] 至 主题 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.mpy.mpy_waitMSG": "等待主题消息 以 [MODE] 模式",
    "gui.blocklyText.mpy.disconnectMQTT": "MQTT 断开连接",
    "gui.blocklyText.mpy.MQTTlastwill": "设置MQTT主题 [TOPIC] 离线消息 [MSG]",
    "gui.blocklyText.mpy.MQTTreceive": "当主题 [TOPIC] 接收到 [DATA]",
    "gui.blocklyText.mpy.mqttMsg": "MQTT消息",
    "gui.blocklyText.mpy.MQTTmode.block": "阻塞",
    "gui.blocklyText.mpy.MQTTmode.unblock": "非阻塞",
    "gui.blocklyText.mpyWeather.init": "「心知天气」 设定 [DATA] 为 [SETTING]",
    "gui.blocklyText.mpyWeather.weatherFeild": "[DATA] 「心知天气」 通用字段 [FIELD]",
    "gui.blocklyText.mpyWeather.weatherSituation": "[DATA] 「心知天气」 天气实况 [NOW]",
    "gui.blocklyText.mpyWeather.weatherForecast": "[DATA] 「心知天气」 3天天气预报 [DATE] 的 [FIELD]",
    "gui.blocklyText.mpyWeather.weatherLivingindex": "[DATA] 「心知天气」 6项生活指数 [FIELD]",
    "gui.blocklyText.mpyWeather.weatherFeild.name": "城市名称",
    "gui.blocklyText.mpyWeather.weatherFeild.id": "城市ID",
    "gui.blocklyText.mpyWeather.weatherFeild.code": "国家代码",
    "gui.blocklyText.mpyWeather.weatherFeild.path": "行政归属",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone": "时区",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone_offset": "UTC偏移量",
    "gui.blocklyText.mpyWeather.weatherFeild.Updated": "数据更新日期",
    "gui.blocklyText.mpyWeather.weatherFeild.updatetime": "数据更新时间",
    "gui.blocklyText.mpyWeather.weatherFeild.original": "原始字典对象",
    "gui.blocklyText.mpyWeather.weatherSituation.text": "天气现象",
    "gui.blocklyText.mpyWeather.weatherSituation.code": "天气现象代码",
    "gui.blocklyText.mpyWeather.weatherSituation.temperature": "温度",
    "gui.blocklyText.mpyWeather.weatherdate.today": "今天",
    "gui.blocklyText.mpyWeather.weatherdate.tomorrow": "明天",
    "gui.blocklyText.mpyWeather.weatherdate.dayaftertomorrow": "后天",
    "gui.blocklyText.mpyWeather.province": "省份",
    "gui.blocklyText.mpyWeather.city": "城市",
    "gui.blocklyText.mpyWeather.weatherforecast.highesttemperature": "当天最高温度",
    "gui.blocklyText.mpyWeather.weatherforecast.lowesttemperature": "当天最低温度",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweather": "白天天气现象",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweathercode": "白天天气现象代码",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweather": "晚间天气现象",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweathercode": "晚间天气现象代码",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirection": "风向",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirectiondegree": "风向角度",
    "gui.blocklyText.mpyWeather.weatherforecast.windspeed": "风速",
    "gui.blocklyText.mpyWeather.weatherforecast.windscale": "风力等级",
    "gui.blocklyText.mpyWeather.weatherforecast.date": "日期",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Carwashindex": "洗车指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Dressingindex": "穿衣指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Coldindex": "感冒指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Sportindex": "运动指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Travelindex": "旅游指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.uvindex": "紫外线指数",
    "gui.blocklyText.mpyWeather.type": "数据类型",
    "gui.blocklyText.mpyWeather.temperature": "温度单位",
    "gui.blocklyText.mpyWeather.APIkey": "API私钥",
    "gui.blocklyText.mpyWeather.language": "语言",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 主程序",
    "gui.blocklyText.esp32.MQTTclient_id": "客户端id",
    "gui.blocklyText.esp32.MQTTserver": "服务器地址",
    "gui.blocklyText.esp32.MQTTport": "端口",
    "gui.blocklyText.esp32.MQTTuser": "用户名",
    "gui.blocklyText.esp32.MQTTpassowrd": "密码",
    "gui.blocklyText.esp32.MQTTkeepalive": "keepalive",
    "gui.blocklyText.esp32.TencentIOT": "腾讯IOT",
    "gui.blocklyText.esp32.TencentServer": "服务器",
    "gui.blocklyText.esp32.TencentPort": "端口",
    "gui.blocklyText.esp32.TencentAppld": "Appld",
    "gui.blocklyText.esp32.TencentDevice": "设备",
    "gui.blocklyText.esp32.Tencentoutput": "传出参数",
    "gui.blocklyText.esp32.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.esp32.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.esp32.EasyIOTServer": "服务器",
    "gui.blocklyText.esp32.EasyIOTclient_id": "客户端id",
    "gui.blocklyText.esp32.EasyIOT_id": "IoT_id",
    "gui.blocklyText.esp32.EasyIOT_password": "密码",
    "gui.blocklyText.mega2560.digitalRead": "讀取數字引腳 [PIN]",
    "gui.blocklyText.mega2560.analogRead": "讀取模擬引腳 [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "設置pwm引腳 [PIN] 輸出 [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "設置數字引腳 [PIN] 輸出 [VALUE]",
    "gui.blocklyText.mega2560.level.low": "低電平",
    "gui.blocklyText.mega2560.level.high": "高電平",
    "gui.blocklyText.mega2560.irqRead": "讀取紅外接收模塊 數字引腳 [PIN]",
    "gui.blocklyText.mega2560.ultrasonicRead": "讀取超聲波傳感器trig為 [TRIG] echo為 [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "設置引腳 [PIN] 喇叭蜂鳴器音調為 [TONE] 節拍為 [BEAT]",
    "gui.blocklyText.mega2560.harf": "1/2",
    "gui.blocklyText.mega2560.quarter": "1/4",
    "gui.blocklyText.mega2560.oneInEighth": "1/8",
    "gui.blocklyText.mega2560.wholeBeat": "整拍",
    "gui.blocklyText.mega2560.doubleBeat": "雙拍",
    "gui.blocklyText.mega2560.stop": "停止",
    "gui.blocklyText.mega2560.servoSet": "設置引腳 [PIN] 伺服舵機為 [DEGREE] 度",
    "gui.blocklyText.mega2560.setBaud": "設置 [SERIAL] 波特率為 [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "從 [SERIAL] 讀取一個 [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "串口0",
    "gui.blocklyText.mega2560.serialPort1": "串口1",
    "gui.blocklyText.mega2560.serialPort2": "串口2",
    "gui.blocklyText.mega2560.serialPort3": "串口3",
    "gui.blocklyText.mega2560.dataReadable": "[SERIAL] 有數據可讀？",
    "gui.blocklyText.mega2560.readSerialData": "讀取 [SERIAL] 數據",
    "gui.blocklyText.mega2560.stringOutput": "字符串輸出",
    "gui.blocklyText.mega2560.originalOutput": "原始輸出",
    "gui.blocklyText.mega2560.hexOutput": "十六進制輸出",
    "gui.blocklyText.mega2560.wrap": "換行",
    "gui.blocklyText.mega2560.noWrap": "不換行",
    "gui.blocklyText.mega2560.readPinLevel": "讀取引腳 [PIN] 脈衝寬度 模式為 [LEVEL] 超時時間 [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "系統運行時間(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini 主程序",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo 主程序",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 主程序",
    "gui.blocklyText.arduino.setDigital": "設置數字引腳 [PIN] 輸出為 [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "讀取數字引腳 [PIN]",
    "gui.blocklyText.arduino.readSimilation": "讀取模擬引腳 [PIN]",
    "gui.blocklyText.arduino.setPwm": "設置pwm引腳 [PIN] 輸出 [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "讀取超聲波距離(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduino.trumpet": "設置引腳 [PIN] 喇叭蜂鳴器音調為 [TONE] 節拍為 [BEAT]",
    "gui.blocklyText.arduino.serialSendStr": "串口 [WAY] [STRING] [LINE]",
    "gui.blocklyText.arduino.originalOutput": "原始輸出",
    "gui.blocklyText.arduino.stringOutput": "字符串輸出",
    "gui.blocklyText.arduino.hexOutput": "十六進制輸出",
    "gui.blocklyText.arduino.wrap": "換行",
    "gui.blocklyText.arduino.noWrap": "不換行",
    "gui.blocklyText.arduino.readInfrared": "讀取紅外接收模塊 數字引腳 [PIN]",
    "gui.blocklyText.arduino.setBaud": "設置串口波特率為 [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "讀取引腳 [PIN] 脈衝寬度 模式 [LEVEL] 超時 [TIMEOUT](us)",
    "gui.blocklyText.arduino.noSound": "無音",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Nano 主程序開始",
    "gui.blocklyText.vortex.vortexStart": "Vortex機器人程序開始",
    "gui.blocklyText.vortex.vortexMoveSpeed": "Vortex機器人以 [SPEED] 速度 [DIRECTION]",
    "gui.blocklyText.vortex.forward": "前進",
    "gui.blocklyText.vortex.backup": "後退",
    "gui.blocklyText.vortex.turnLeft": "左轉",
    "gui.blocklyText.vortex.turnRight": "右轉",
    "gui.blocklyText.vortex.setMotor": "設置電機 [MOTOR] 以 [SPEED] 速度 [DIRECTION]",
    "gui.blocklyText.vortex.leftWheel": "左輪",
    "gui.blocklyText.vortex.rightWheel": "右輪",
    "gui.blocklyText.vortex.setExpression": "設置 [EXPRESSION] 表情的顏色為 [COLOR]",
    "gui.blocklyText.vortex.ledLights": "設置 [DIRECTION][LIGHT][SECOND] 秒內變化為 [COLOR]",
    "gui.blocklyText.vortex.all": "全部",
    "gui.blocklyText.vortex.rightAhead": "正前",
    "gui.blocklyText.vortex.leftRear": "左後",
    "gui.blocklyText.vortex.rightRear": "右後",
    "gui.blocklyText.vortex.rightAstern": "正後",
    "gui.blocklyText.vortex.topLights": "頂燈",
    "gui.blocklyText.vortex.bottomLights": "底燈",
    "gui.blocklyText.vortex.vortexStop": "vortex停止運動",
    "gui.blocklyText.vortex.setVolume": "設置音量大小為 [VALUE]",
    "gui.blocklyText.vortex.playMusic": "播放第 [VALUE] 首音樂",
    "gui.blocklyText.vortex.stopMusic": "停止音樂",
    "gui.blocklyText.vortex.startDance": "開始跳第 [VALUE] 支舞蹈",
    "gui.blocklyText.vortex.stopDance": "停止跳舞",
    "gui.blocklyText.vortex.barrierDetection": "前方有障礙物？",
    "gui.blocklyText.vortex.readSensor": "讀取 [SENSOR] 灰度傳感器的值",
    "gui.blocklyText.vortex.setSensor": "設置灰度傳感器監控變化量為 [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "灰度傳感器 [SENSOR] 方遇到 黑色？",
    "gui.blocklyText.vortex.positiveFrontLeft": "正左前",
    "gui.blocklyText.vortex.frontLeft": "左前",
    "gui.blocklyText.vortex.positiveFrontRight": "正右前",
    "gui.blocklyText.vortex.frontRight": "右前",
    "gui.blocklyText.vortex.backRight": "右後",
    "gui.blocklyText.vortex.backLeft": "左後",
    "gui.blocklyText.vortex.initialOldPCB": "初始化為老版本(藍色PCB)",
    "pen.categoryName": "畫筆",
    "pen.changeColorParam": "筆跡 [COLOR_PARAM] 改變 [VALUE]",
    "pen.changeHue": "筆跡顏色改變 [HUE]",
    "pen.changeShade": "筆跡亮度改變 [SHADE]",
    "pen.changeSize": "筆跡寬度改變 [SIZE]",
    "pen.clear": "筆跡全部清除",
    "pen.colorMenu.brightness": "亮度",
    "pen.colorMenu.color": "顏色",
    "pen.colorMenu.saturation": "彩度",
    "pen.colorMenu.transparency": "透明度",
    "pen.penDown": "下筆",
    "pen.penUp": "停筆",
    "pen.setColor": "筆跡顏色設為 [COLOR]",
    "pen.setColorParam": "筆跡 [COLOR_PARAM] 設為 [VALUE]",
    "pen.setHue": "筆跡顏色設為 [HUE]",
    "pen.setShade": "筆跡亮度設為 [SHADE]",
    "pen.setSize": "筆跡寬度設為 [SIZE]",
    "pen.stamp": "蓋章",
    "music.changeTempo": "演奏速度改變 [TEMPO]",
    "music.drumBass": "(2) 低音鼓",
    "music.drumBongo": "(13) 邦加鼓",
    "music.drumCabasa": "(15) 鐵沙鈴",
    "music.drumClaves": "(9) 梆子",
    "music.drumClosedHiHat": "(6) 合起的腳踏鈸",
    "music.drumConga": "(14) 康加鼓",
    "music.drumCowbell": "(11) 牛鈴",
    "music.drumCrashCymbal": "(4) 碎音鈸",
    "music.drumCuica": "(18) 庫加鼓",
    "music.drumGuiro": "(16) 刮瓜",
    "music.drumHandClap": "(8) 拍掌",
    "music.drumOpenHiHat": "(5) 打開的腳踏鈸",
    "music.drumSideStick": "(3) 鼓邊敲擊",
    "music.drumSnare": "(1) 軍鼓",
    "music.drumTambourine": "(7) 鈴鼓",
    "music.drumTriangle": "(12) 三角鐵",
    "music.drumVibraslap": "(17) 振盪器",
    "music.drumWoodBlock": "(10) 木魚",
    "music.getTempo": "演奏速度",
    "music.instrumentBass": "(6) 貝斯",
    "music.instrumentBassoon": "(14) 低音管",
    "music.instrumentCello": "(8) 大提琴",
    "music.instrumentChoir": "(15) 人聲合唱",
    "music.instrumentClarinet": "(10) 單簧管",
    "music.instrumentElectricGuitar": "(5) 電吉他",
    "music.instrumentElectricPiano": "(2) 電子琴",
    "music.instrumentFlute": "(12) 長笛",
    "music.instrumentGuitar": "(4) 吉他",
    "music.instrumentMarimba": "(19) 馬林巴",
    "music.instrumentMusicBox": "(17) 音樂盒",
    "music.instrumentOrgan": "(3) 風琴",
    "music.instrumentPiano": "(1) 鋼琴",
    "music.instrumentPizzicato": "(7) 撥奏",
    "music.instrumentSaxophone": "(11) 薩克斯風",
    "music.instrumentSteelDrum": "(18) 鋼鼓",
    "music.instrumentSynthLead": "(20) 合成主音",
    "music.instrumentSynthPad": "(21) 合成柔音",
    "music.instrumentTrombone": "(9) 長號",
    "music.instrumentVibraphone": "(16) 顫音琴",
    "music.instrumentWoodenFlute": "(13) 木笛",
    "music.midiPlayDrumForBeats": "演奏節拍 [DRUM] [BEATS] 拍",
    "music.midiSetInstrument": "演奏樂器設為 [INSTRUMENT]",
    "music.playDrumForBeats": "演奏節拍 [DRUM] [BEATS] 拍",
    "music.playNoteForBeats": "演奏音階 [NOTE] [BEATS] 拍",
    "music.restForBeats": "演奏休息 [BEATS] 拍",
    "music.setInstrument": "演奏樂器設為 [INSTRUMENT]",
    "music.setTempo": "演奏速度設為 [TEMPO]",
    "gui.blocklyText.microbitV2.logo": "徽标(V2)",
    "gui.blocklyText.microbitV2.ls": "蜂鸣器(V2)",
    "gui.blocklyText.microbit.microbitStarts": "micro:bit主程序開始",
    "gui.blocklyText.microbit.interruptExcute": "中斷引腳 [PIN] 模式 [MODE]執行",
    "gui.blocklyText.microbit.cancleInterrupt": "取消中斷引腳 [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "當無線接收到數據",
    "gui.blocklyText.microbit.whenBtnPress": "當 [REFERENCE] 按鈕按下",
    "gui.blocklyText.microbit.whenPinConnected": "當接口 [REFERENCE] 被接通",
    "gui.blocklyText.microbit.whenPosChange": "當[REFERENCE]",
    "gui.blocklyText.microbit.showFont": "显示图案或内置图案 [PIC]",
    "gui.blocklyText.microbit.showInput": "顯示文字 [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "停止滾動動畫",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] 坐標 x:[XAXIS], y:[YXAXIS]",
    "gui.blocklyText.microbit.hideAllLights": "熄滅所有點陣",
    "gui.blocklyText.microbit.microbitEnableLight": "點陣屏 [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "亮度",
    "gui.blocklyText.microbit.showLightWithBrightness": "設置亮度 [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "點亮 坐標x:[XAXIS], y:[YXAXIS], 亮度 [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "接口 [PIN] 後台播放聲音 [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "接口 [PIN] 播放聲音 [SOUND] 直到結束",
    "gui.blocklyText.microbit.playNote": "接口 [PIN] 播放音符 [NOTE] [BEAT] 拍",
    "gui.blocklyText.microbit.true": "啟用",
    "gui.blocklyText.microbit.false": "釋放",
    "gui.blocklyText.maqueen.playSound": "接口 P0 後台播放聲音 [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "接口 P0 播放聲音 [SOUND] 直到結束",
    "gui.blocklyText.maqueen.playNote": "接口 P0 播放音符 [NOTE] [BEAT] 拍",
    "gui.blocklyText.maqueen.RGB0": "RGB0（0）",
    "gui.blocklyText.maqueen.RGB1": "RGB1（1）",
    "gui.blocklyText.maqueen.RGB2": "RGB2（2）",
    "gui.blocklyText.maqueen.RGB3": "RGB3（3）",
    "gui.blocklyText.maqueen.All": "全部 (-1)",
    "gui.blocklyText.microbit.changeTempo": "將聲音速度(bpm)增加 [VALUE]",
    "gui.blocklyText.microbit.setTempo": "將聲音速度(bpm)設置為 [VALUE]",
    "gui.blocklyText.microbit.getTempo": "聲音速度 (bpm)",
    "gui.blocklyText.microbit.stopSound": "停止後台播放",
    "gui.blocklyText.microbit.btnIsPressed": "按鈕 [BUTTON] 被按下？",
    "gui.blocklyText.microbit.pinIsConnected": "接口 [PIN] 被接通？",
    "gui.blocklyText.microbit.isMove": "當前姿態為 [TYPE]？",
    "gui.blocklyText.microbit.compass": "讀取指南針朝向(°)",
    "gui.blocklyText.microbit.temperature": "讀取溫度值(℃)",
    "gui.blocklyText.microbit.digitalWrite": "設置數字引腳 [PIN] 輸出 [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "讀取數字引腳 [PIN]",
    "gui.blocklyText.microbit.analogWrite": "設置模擬引腳 [PIN] 輸出 [VALUE]",
    "gui.blocklyText.microbit.analogRead": "讀取模擬引腳 [PIN]",
    "gui.blocklyText.microbit.up": "徽標朝上",
    "gui.blocklyText.microbit.down": "徽標朝下",
    "gui.blocklyText.microbit.left": "向左傾斜",
    "gui.blocklyText.microbit.right": "向右傾斜",
    "gui.blocklyText.microbit.faceUp": "屏幕朝上",
    "gui.blocklyText.microbit.faceDown": "屏幕朝下",
    "gui.blocklyText.microbit.freefall": "自由落體運動",
    "gui.blocklyText.microbit.3g": "3克",
    "gui.blocklyText.microbit.6g": "6克",
    "gui.blocklyText.microbit.8g": "8克",
    "gui.blocklyText.microbit.shake": "搖一搖",
    "gui.blocklyText.microbit.show": "點亮",
    "gui.blocklyText.microbit.start": "打开",
    "gui.blocklyText.microbit.stop": "关闭",
    "gui.blocklyText.microbit.hide": "熄滅",
    "gui.blocklyText.microbit.low": "低電平",
    "gui.blocklyText.microbit.high": "高電平",
    "gui.blocklyText.microbit.microbitReadBrightness": "讀取環境光強度",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] 無線通信",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "設置無線頻道為 [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "通過無線發送 [TEXT]",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "無線接收數據",
    "gui.blocklyText.microbit.strength": "強度",
    "gui.blocklyText.microbit.acceleration": "讀取加速度的值(mg)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "讀取磁力計的值(µT)[TYPE]",
    "gui.blocklyText.microbit.microbitAccurateCompass": "校准电子罗盘",
    "gui.blocklyText.microbit.microbitGetSoundLevel": "读取声音强度(V2)",
    "gui.blocklyText.microbit.print": "打印 [DATA]",
    "gui.blocklyText.microbit.goUp": "上升",
    "gui.blocklyText.microbit.goDown": "下降",
    "gui.blocklyText.microbit.change": "电平变化",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "當接收到[WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "無線數據",
    "gui.blocklyText.calliope.whenBtnPress": "当 [REFERENCE] 按钮按下",
    "gui.blocklyText.calliope.calliopeEnableLight": "点阵屏 [ENABLE]",
    "gui.blocklyText.calliope.hideAllLights": "熄灭所有点阵",
    "gui.blocklyText.calliope.playSound": "接口 [PIN] 后台播放声音 [SOUND]",
    "gui.blocklyText.calliope.playSoundUntil": "接口 [PIN] 播放声音 [SOUND] 直到结束",
    "gui.blocklyText.calliope.playNote": "接口 [PIN] 播放音符 [NOTE] [BEAT] 拍",
    "gui.blocklyText.calliope.calliopeReadBrightness": "读取环境光强度",
    "gui.blocklyText.calliope.whenPinConnected": "当接口 [REFERENCE] 被接通",
    "gui.blocklyText.calliope.loudness": "读取响度值",
    "gui.blocklyText.calliope.rotation": "读取旋转角度(°) [TYPE]",
    "gui.blocklyText.calliope.calliopeControlSinglelMotor": "单电机控制 [RANGE]%",
    "gui.blocklyText.calliope.calliopeSingleMotorStop": "单电机 [STOPTYPE]",
    "gui.blocklyText.calliope.calliopeDoubleMotor": "控制电机 [MOTORTYPE] [RANGE]%",
    "gui.blocklyText.calliope.calliopeDoubleMotorStop": "停止电机 [MOTORTYPE]",
    "gui.blocklyText.calliope.calliopeLight": "设置LED为 [COLOR]",
    "gui.blocklyText.calliope.setRGBColor": "红 [RED] 绿 [GREEN] 蓝 [BLUE] 白 [WHITE]",
    "gui.blocklyText.calliope.calliopeCloseRGB": "关闭内置LED",
    "gui.blocklyText.calliope.pitch": "旋转",
    "gui.blocklyText.calliope.roll": "横滚",
    "gui.blocklyText.calliope.boardbuzzer": "板载蜂鸣器",
    "gui.blocklyText.calliope.motorbreak": "停止",
    "gui.blocklyText.calliope.motorcoast": "滑行",
    "gui.blocklyText.calliope.motorsleep": "睡眠",
    "gui.blocklyText.calliope.open": "打开",
    "gui.blocklyText.calliope.close": "关闭",
    "gui.blocklyText.bos0001.readRotary": "读取[PIN]旋钮模块值",
    "gui.blocklyText.bos0002.IsPINPress": "[PIN]按钮按下?",
    "gui.blocklyText.bos0003.IsPINPress": "[PIN]自锁开关按下？",
    "gui.blocklyText.bos0004.readLight": "读取[PIN]光线模块值",
    "gui.blocklyText.bos0006.readMoisture": "读取[PIN]水分模块值",
    "gui.blocklyText.bos0007.readFlame": "读取[PIN]火焰模块值",
    "gui.blocklyText.bos0008.IsPINTouch": "[PIN]触摸接通?",
    "gui.blocklyText.bos0009.readSound": "读取[PIN]声音模块值",
    "gui.blocklyText.bos0010.readSmartGray": "读取[PIN]灰度模块值",
    "gui.blocklyText.bos0012.IsConductivitySwitch": "[PIN]电导模块接通?",
    "gui.blocklyText.bos0013.IsMotionSensor": "[PIN]运动模块触发?",
    "gui.blocklyText.bos0038.readSoilHumidity": "读取[PIN]土壤湿度值",
    "gui.blocklyText.bos0045.readUltrasonicDistance": "读取[PIN]超声波测距值",
    "gui.blocklyText.bos0016.lightBrightLED": "[CONTROL][PIN]高亮LED灯",
    "gui.blocklyText.bos0017.LEDModule": "[CONTROL][PIN]LED灯",
    "gui.blocklyText.bos0019.LEDStringLight": "[CONTROL][PIN]固定色灯带",
    "gui.blocklyText.bos0021.fanModule": "[CONTROL][PIN]风扇",
    "gui.blocklyText.bos0022.voiceRecorderModule": "[PIN]播放一次录音",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot 主程序開始",
    "gui.blocklyText.maxbot.playSound": "播放聲音 [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "播放聲音 [SOUND] 直到結束",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "[LEFTRIGHT]發生碰撞",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "[LEFTRIGHT]檢測到黑線",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": " [MOTOR] 電機以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "讀取(P1,P2)超聲波傳感器距離 單位 [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "讀取超聲波傳感器距離 單位 [UNIT] trig為 [TRIG] echo為 [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "向前",
    "gui.blocklyText.maxbot.BACKWARD": "向後",
    "gui.blocklyText.motorbit.CW": "正轉",
    "gui.blocklyText.motorbit.CCW": "反轉",
    "gui.blocklyText.maxbot.CW": "正轉",
    "gui.blocklyText.maxbot.CCW": "反轉",
    "gui.blocklyText.maxbot.ANGLE": "角度",
    "gui.blocklyText.maxbot.CIRCLE": "圈數",
    "gui.blocklyText.maxbot.Left": "左側",
    "gui.blocklyText.maxbot.Right": "右側",
    "gui.blocklyText.maxbot.cm": "厘米",
    "gui.blocklyText.maxbot.inch": "英寸",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit 主程序開始",
    "gui.blocklyText.MotorBit.MotorRun": "電機 [MOTOR] 以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.MotorBit.MotorStop": "電機 [MOTOR] 停止",
    "gui.blocklyText.MotorBit.Stepper": "設置步進電機 [STEPPER] [ROTATEDIR] [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.pythondfr0548.MotorRun": "电机 [MOTOR] 以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.pythondfr0548.MotorStop": "电机 [MOTOR] 停止",
    "gui.blocklyText.pythondfr0548.Stepper": "设置步进电机 [STEPPER] [ROTATEDIR] [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "全部",
    "gui.blocklyText.pythonspread.extensionName": "两用扩展板",
    "gui.blocklyText.pythonMotorBit.MotorRun": "电机 [MOTOR] 以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.pythonMotorBit.MotorStop": "电机 [MOTOR] 停止",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen 主程序開始",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "讀取(P1 P2)超聲波距離(cm)",
    "gui.blocklyText.maqueen.motorStop": "機器人(左側和右側電機)停止",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT]LED燈",
    "gui.blocklyText.maqueen.redLineSensor": "讀取 [LEFTRIGHT] 巡線傳感器",
    "gui.blocklyText.maqueen.robotSpeedMove": "機器人以 [SPEED] 的速度 [DIR]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "設置電機 [LEFTRIGHT] 以 [SPEED] 的速度 [DIR]",
    "gui.blocklyText.maqueen.ledLeft": "左側(P8)",
    "gui.blocklyText.maqueen.ledRight": "右側(P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "右側(P12) (硬件不可用)",
    "gui.blocklyText.maqueen.lineSensorLeft": "左側(P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "右側(P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "當(P16)接收到[WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "紅外消息",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "讀取(P16)紅外信號的值",
    "gui.blocklyText.maqueen.numberOfLightsBright": "RGB燈 引腳 P15 燈總數4 亮度 [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "RGB燈 引腳 P15 燈號 [NUM1] 顯示顏色 [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "RGB燈 引腳 P15 燈號 [NUM1] 到 [NUM2] 顯示漸變顏色 色調從 [COLOR1] 到 [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "RGB燈 引腳 P15 移動 [NUM] 單位",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "RGB燈 引腳 P15 循環移動 [NUM] 單位",
    "gui.blocklyText.maqueen.setWs2812Brightness": "RGB燈 引腳 P15 設置亮度 [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "RGB燈 引腳 P15 燈號 [NUM1] 到 [NUM2] 顯示柱狀圖 當前值 [VALUE] 最大值 [MAX]",
    "gui.blocklyText.maqueen.clear": "RGB燈 引腳 P15 全部熄滅",
    "gui.blocklyText.maqueen.rgbColor": "紅 [RED] 綠 [GREEN] 藍 [BLUE]",
    "gui.blocklyText.tello.telloStarts": "Tello 主程序開始",
    "gui.blocklyText.tello.telloControlPlane": "控制飛機",
    "gui.blocklyText.tello.telloTakeOff": "起飛",
    "gui.blocklyText.tello.telloLanding": "降落",
    "gui.blocklyText.tello.telloRise": "上升 [NUMS]cm",
    "gui.blocklyText.tello.telloDown": "下降 [NUMS]cm",
    "gui.blocklyText.tello.telloGoahead": "向前飛 [NUMS]cm",
    "gui.blocklyText.tello.telloBack": "向後飛 [NUMS]cm",
    "gui.blocklyText.tello.telloTurnLeft": "向左飛 [NUMS]cm",
    "gui.blocklyText.tello.telloTurnRight": "向右飛 [NUMS]cm",
    "gui.blocklyText.tello.telloClockwiseRotation": "順時針旋轉 [NUMS]度",
    "gui.blocklyText.tello.telloCounterclockwiseSelection": "逆時針旋轉 [NUMS]度",
    "gui.blocklyText.tello.telloSpeed": "設置速度為 [NUMS]cm/s",
    "gui.blocklyText.tello.telloRoll": "翻滾[ROLL] ",
    "gui.blocklyText.tello.telloPower": "電池電量( %)",
    "gui.blocklyText.tello.telloRollForward": "向前(f)",
    "gui.blocklyText.tello.telloRollBack": "向後(b)",
    "gui.blocklyText.tello.telloRollLeft": "向左(l)",
    "gui.blocklyText.tello.telloRollRight": "向右(r)",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] 接收到消息？",
    "gui.blocklyText.obloq.initialParameter": "Obloq mqtt初始化 [PARAMETER] 接口 [SERIALPORT] Rx(綠) [PINR] Tx(藍) [PINT]",
    "gui.blocklyText.obloq.initialI2CParameter": "Obloq mqtt 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.obloq.httpInitialI2CParameter": "Obloq http 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq 發送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.obloq.sendmsgSaveToCloudPlatform": "Obloq 发送消息 [MSG] 至 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq 讀取來自 [TOPIC] 的消息",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq 讀取消息",
    "gui.blocklyText.obloq.httpInitialParameter": "Obloq http初始化 [PARAMETER] 接口 [SERIALPORT] Rx(綠) [PINR] Tx(藍) [PINT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] 超時(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL]content: [CONTENT] 超時(ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq HTTP（放）URL：[URL]內容：[CONTENT]超時（毫秒）：[TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "新增訂閱 [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "wifi名稱",
    "gui.blocklyText.obloq.password": "wifi密碼",
    "gui.blocklyText.obloq.iotService": "物聯網平台參數",
    "gui.blocklyText.obloq.iotId": "Iot_id",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd",
    "gui.blocklyText.obloq.iotTopic": "名Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP配置",
    "gui.blocklyText.obloq.ip": "IP地址",
    "gui.blocklyText.obloq.port": "端口號",
    "gui.blocklyText.obloq.addTopic": "添加Topic, 最多只能添加4個",
    "gui.blocklyText.obloq.deleteTopic": "刪除 Topic",
    "gui.blocklyText.obloq.moreTopic": "話題",
    "gui.blocklyText.obloq.server": "服務器",
    "gui.blocklyText.obloq.global": "全球",
    "gui.blocklyText.obloq.china": "中國",
    "gui.blocklyText.obloq.softwareSerial": "軟串口",
    "gui.blocklyText.obloq.hardwareSerial": "硬串口",
    "gui.blocklyText.obloq.hardwareSerial1": "硬串口1",
    "gui.blocklyText.obloq.hardwareSerial2": "硬串口2",
    "gui.blocklyText.obloq.hardwareSerial3": "硬串口3",
    "gui.blocklyText.obloq.WhenReceivedData": "當 [TOPIC] 接收到 [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq消息",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq 獲取 [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "調試信息",
    "gui.blocklyText.obloq.ERROR": "錯誤碼",
    "gui.blocklyText.pythonobloq.whenDebugInfo": "[NAME] 获取 [DEBUG] [ERROR]",
    "gui.blocklyText.pythonobloq.WhenReceivedData": "当 [EVENT] [TOPIC] 接收到 [MSG]",
    "gui.blocklyText.pythonobloq.DEBUG": "调试信息",
    "gui.blocklyText.pythonobloq.ERROR": "错误码",
    "gui.blocklyText.pythonobloq.ObloqMsg": "obloq消息",
    "gui.blocklyText.pythonobloq.initialI2CParameter": "[NAME] mqtt 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.pythonobloq.sendmsgToCloudPlatform": "[NAME] 发送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.pythonobloq.isReceivedData": "[NAME] [TOPIC] 接收到消息？",
    "gui.blocklyText.pythonobloq.readMsgFromCloudPlatformUno": "[NAME] 读取来自 [TOPIC] 的消息",
    "gui.blocklyText.pythonobloq.httpInitialI2CParameter": "[NAME] http 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.pythonobloq.httpGet": "[NAME] http(get) url: [URL] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.pythonobloq.httpPost": "[NAME] http(post) url: [URL]content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.pythonobloq.httpPut": "[NAME] http(put) url: [URL] content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "紅外發射 [TYPE] 管腳 [PIN] 數字 [VALUE] 比特數 [BIT]",
    "gui.blocklyText.infraredEmission.send": "紅外發送 引腳 [PIN] 發送消息：地址 [ADDRESS] 命令 [COMMAND]",
    "gui.blocklyText.oled2864.displayInit": "OLED 128*64初始化I2C地址 [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 第 [LINE] 行 顯示 [TEXT]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 在坐標 X: [X] Y:16* [Y] 顯示 [TEXT]",
    "gui.blocklyText.oled2864.screenRotation": "屏幕旋轉到[ROTATION]度",
    "gui.blocklyText.oled2864.clear": "OLED 128 * 64清屏",
    "gui.blocklyText.dfr0647.displayInit": "OLED 128*32初始化I2C地址 [ADDR]",
    "gui.blocklyText.dfr0647.displayShowTextInXY": "OLED 128*32 显示文字 [TEXT] 在坐标X:[X] Y:[Y]",
    "gui.blocklyText.dfr0647.clear": "OLED 128*32 清屏",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "讀取引腳 [PIN] [DHT] [TYPE]",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx引腳 [PIN] PM濃度值 [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 超聲波初始化 名稱 [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04超聲波距離 測量單位 [UNIT]",
    "gui.blocklyText.hcsr04.readData": "读取HC-SRO4 Trig [PINT] Echo [PINE] 单位 [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx引腳 [PIN] 0.1升空氣中直徑在 [DIAMETER] 顆粒物格個數",
    "gui.blocklyText.sen0170.readWindspeed": "讀取引腳 [PIN] 風速等級",
    "gui.blocklyText.sen0228.init": "初始化環境光(VEML7700)傳感器",
    "gui.blocklyText.sen0228.readAmbientLight": "讀取環境光(VEML7700)",
    "gui.blocklyText.pythonsen0228.init": "初始化环境光(VEML7700)传感器[NAME] I2C地址0x10",
    "gui.blocklyText.pythonsen0228.readAmbientLight": "读取[NAME]环境光(VEML7700)",
    "gui.blocklyText.sen0097.init": "初始化环境光(BH1750)传感器 I2C地址0x23",
    "gui.blocklyText.sen0097.readAmbientLight": "读取环境光(BH1750)",
    "gui.blocklyText.dfr0026.readAmbientLight": "讀取引腳 [PIN] 環境光",
    "gui.blocklyText.pythondfr0026.extensionName": "环境光",
    "gui.blocklyText.pythondfr0026.readAmbientLight": "读取引脚 [PIN] 环境光",
    "gui.blocklyText.sen0177.readDustSensor": "讀取 [TYPE] (ug/m3) 接口 [SERIALPORT] Rx [PINR] Tx [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "引腳(D) [PIN] 引腳(MCLR) [RST] 當前姿態為 [TYPE]？",
    "gui.blocklyText.sen0203.readHeartRate": "讀取引腳 [PIN] [TYPE] 撥向 [SWITCH]",
    "gui.blocklyText.pythonsen0203.init": "初始化心率传感器 [NAME] 引脚 [PIN] 模式 [MODE]",
    "gui.blocklyText.pythonsen0203.update": "[NAME] 获取一次数据",
    "gui.blocklyText.pythonsen0203.readHeartRate": "[NAME] 从数据中解析心率值（次/分）",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] 引腳 [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "讀取引腳 [PIN] 灰度值",
    "gui.blocklyText.pythondfr0022.extensionName": "灰度",
    "gui.blocklyText.pythondfr0022.readGrayscale": "读取引脚 [PIN] 灰度值",
    "gui.blocklyText.motucamera.initIICSerial": "初始化 [MU] 端口 [IICSERIAL] 直到成功",
    "gui.blocklyText.motucamera.resetConfig": " [MU] 恢復默認設置",
    "gui.blocklyText.motucamera.algorithmSwitch": " [MU] [SWITCH] 算法 [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": " [MU] led [LED] 識別到顏色 [COLOR1] 未識別到顏色 [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": " [MU] 設置算法 [ALGORITHM] 性能 [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": " [MU] 數碼變焦 [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": " [MU] 設置串口波特率 [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": " [MU] 設置攝像頭白平衡 [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": " [MU] 設置高幀率模式 [OPENED]",
    "gui.blocklyText.motucamera.isDetect": " [MU] 檢測到 [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": " [MU] 檢測到 顏色識別 x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": " [MU] 檢測到 色塊檢測 顏色= [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "獲取 [MU] 算法 [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "獲取 [MU] 顏色識別 [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": " [MU] 算法 形狀卡片 [CARD]",
    "gui.blocklyText.motucamera.trafficCard": " [MU] 算法 交通卡片 [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": " [MU] 算法 數字卡片 [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": " [MU] 算法 顏色識別 顏色 = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "色塊檢測",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "顏色識別",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "球體檢測",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "人體檢測",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "形狀卡片",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "交通卡片",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "數字卡片",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "啟用",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "關閉",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "關",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "紅",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "綠",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "藍",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "黃",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "紫",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "青",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "白",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "關",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "紅",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "綠",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "藍",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "黃",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "紫",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "青",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "白",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "自動",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "速度優先",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "性能均衡",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "準確率優先",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "自動",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "鎖定白平衡",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "白光模式",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "黃光模式",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "自動",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "等級1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "等級2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "等級3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "等級4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "等級5",
    "gui.blocklyText.motucamera.setHighFPSOff": "關",
    "gui.blocklyText.motucamera.setHighFPSOn": "開",
    "gui.blocklyText.motucamera.colorDetectBlack": "黑",
    "gui.blocklyText.motucamera.colorDetectWhite": "白",
    "gui.blocklyText.motucamera.colorDetectRed": "紅",
    "gui.blocklyText.motucamera.colorDetectYellow": "黃",
    "gui.blocklyText.motucamera.colorDetectGreem": "綠",
    "gui.blocklyText.motucamera.colorDetectCayan": "青",
    "gui.blocklyText.motucamera.colorDetectBlue": "藍",
    "gui.blocklyText.motucamera.colorDetectPerple": "紫",
    "gui.blocklyText.motucamera.colorDetectOthers": "其他",
    "gui.blocklyText.motucamera.colorDetectPositionX": "橫向坐標",
    "gui.blocklyText.motucamera.colorDetectPositionY": "縱向坐標",
    "gui.blocklyText.motucamera.colorDetectPositionW": "寬度",
    "gui.blocklyText.motucamera.colorDetectPositionH": "高度",
    "gui.blocklyText.motucamera.colorDetectPositionL": "標籤",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "紅色通道",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "綠色通道",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "藍色通道",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "標籤",
    "gui.blocklyText.motucamera.shapeCardTick": "鉤",
    "gui.blocklyText.motucamera.shapeCardCross": "叉",
    "gui.blocklyText.motucamera.shapeCardCircle": "圓形",
    "gui.blocklyText.motucamera.shapeCardSquare": "方形",
    "gui.blocklyText.motucamera.shapeCardTriangle": "三角形",
    "gui.blocklyText.motucamera.trafficCardForward": "向前",
    "gui.blocklyText.motucamera.trafficCardLeft": "向左",
    "gui.blocklyText.motucamera.trafficCardRight": "向右",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "掉頭",
    "gui.blocklyText.motucamera.trafficCardStop": "停車",
    "gui.blocklyText.motucamera.lightSensorUse": "[MU] 光线传感器 启用 [LIGHTFUNC]",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "[MU] 光线传感器 设置 灵敏度 [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "[MU] 光线传感器 读取 接近检测",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "[MU] 光线传感器 读取 环境光检测",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "[MU] 光线传感器 读取一次手势存入结果",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "[MU] 光线传感器 手势= [GESTURE]",
    "gui.blocklyText.motucamera.upward": "上划",
    "gui.blocklyText.motucamera.downward": "下划",
    "gui.blocklyText.motucamera.leftward": "左划",
    "gui.blocklyText.motucamera.rightward": "右划",
    "gui.blocklyText.motucamera.pull": "前进",
    "gui.blocklyText.motucamera.push": "后退",
    "gui.blocklyText.motucamera.none": "未知",
    "gui.blocklyText.motucamera.default": "默认",
    "gui.blocklyText.motucamera.low": "低",
    "gui.blocklyText.motucamera.middle": "中",
    "gui.blocklyText.motucamera.high": "高",
    "gui.blocklyText.motucamera.proximity": "接近检测",
    "gui.blocklyText.motucamera.ambientLight": "环境光检测",
    "gui.blocklyText.motucamera.gesture": "手势检测",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi 初始化 端口 [SERIALPORT] RX[RX] TX[TX] 串口波特率 [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi 设置 名称 [SSID] 密码 [PASSWORD] 模式 [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi 连接成功？",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi 断开连接",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi 设置目标 IP [IP] 端口 [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi 读取 目标IP",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi 读取 本地IP",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi 读取",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi 写入 [WRITE]",
    "gui.blocklyText.motucamera.client": "客户端",
    "gui.blocklyText.motucamera.hot-spot": "热点",
    "gui.blocklyText.dfr0552.init": "DA模塊 初始化I2C地址 [ADDR] 參考電壓 [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "DA模塊 輸出三角波 幅度 [AMPLITUDE]mV 頻率 [FREQ]Hz 直流偏置 [DCBIAS]mV 佔空比 [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "DA模塊 輸出正弦波 幅度 [AMPLITUDE]mV 頻率 [FREQ]Hz 直流偏置 [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "DA模塊 輸出電壓 [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "DA模塊 記憶電壓 [VOLTAGE]mV",
    "gui.blocklyText.dfr0699.init": "音频录放模块传感器初始化地址 [ADDR] 等待直到成功",
    "gui.blocklyText.dfr0699.chooseAudioNum": "选择 [AUDIO] 号音频",
    "gui.blocklyText.dfr0699.getAudioNum": "获取当前录音号",
    "gui.blocklyText.dfr0699.reRecord": "重新录音",
    "gui.blocklyText.dfr0699.startRecording": "开始录音",
    "gui.blocklyText.dfr0699.recordingEnd": "录音结束",
    "gui.blocklyText.dfr0699.recordingIsExist": "录音是否存在?",
    "gui.blocklyText.dfr0699.recordingPlay": "播放录音",
    "gui.blocklyText.dfr0699.waitRecordingPlay": "等待直到录音播放结束",
    "gui.blocklyText.dfr0699.fingerprintSpeechSynthesisMode": "语音合成语音 [VALUE]  文本 [STR] 数字 [MODE]",
    "gui.blocklyText.dfr0699.china": "中文",
    "gui.blocklyText.dfr0699.english": "英文",
    "gui.blocklyText.dfr0699.fingerprintSpeechSynthesis": "语音合成语音 [VALUE]  数字 [NUM]",
    "gui.blocklyText.dfr0699.alternately": "语音替换模式",
    "gui.blocklyText.dfr0699.synthesis": "语音合成模式",
    "gui.blocklyText.pythonsen0334.SHT31Init": "SHT31-F数字温湿度传感器初始化I2C地址[ADDRESS]等待直到成功",
    "gui.blocklyText.pythonsen0334.SHT31GetData": "读取SHT31-F的数据：[DATA]",
    "gui.blocklyText.pythonsen0334.TemperC": "温度（摄氏度℃）",
    "gui.blocklyText.pythonsen0334.TemperF": "温度（华氏度℉）",
    "gui.blocklyText.pythonsen0334.HumidRH": "湿  度 (%RH)",
    "gui.blocklyText.pythonsen0334.Number": "芯片序列号",
    "gui.blocklyText.pythonsen0460.extensionName": "粉尘浓度",
    "gui.blocklyText.pythonsen0460.begin": "初始化设备直到成功 I2C地址为0x19",
    "gui.blocklyText.pythonsen0460.readConcentration": "读取 [ENVIRONMENT] 环境下 [KAIGUAN] 颗粒物浓度（ug/m³）",
    "gui.blocklyText.pythonsen0460.readCount": "读取每0.1L空气中粒径为 [PARTICLEDIAMETER] 的颗粒物个数",
    "gui.blocklyText.pythonsen0460.readDeviceVersion": "获取传感器版本号",
    "gui.blocklyText.pythonsen0460.PM2_5": "PM2.5",
    "gui.blocklyText.pythonsen0460.PM1_0": "PM1.0",
    "gui.blocklyText.pythonsen0460.PM10": "PM10",
    "gui.blocklyText.pythonsen0460.standard": "标准颗粒物",
    "gui.blocklyText.pythonsen0460.atmospheric": "大气",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_0_3": "0.3um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_0_5": "0.5um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_1_0": "1.0um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_2_5": "2.5um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_5_0": "5.0um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_10": "10um",
    "gui.blocklyText.pythonsen0303.VEML6075Init": "初始化 VEML6075 紫外线传感器等待直到成功",
    "gui.blocklyText.pythonsen0303.VEML6075ReadyUV": "读取 VEML6075 [UV]强度",
    "gui.blocklyText.pythonsen0303.VEML6075Ready": "读取 VEML6075 紫外线指数",
    "gui.blocklyText.pythonsen0303.VEML6075ReadyME": "读取 VEML6075 辐射出射度mw/cm^2",
    "gui.blocklyText.pythonsen0303.UVA": "长波紫外线(UVA)",
    "gui.blocklyText.pythonsen0303.UVB": "中波紫外线(UVB)",
    "gui.blocklyText.pythonsen0377.MEMSgas_Iint": "MEMS气体传感器初始化 地址A0[A0] A1[A1]",
    "gui.blocklyText.pythonsen0377.MEMSgaswarm_up_time": "MEMS气体传感器设置唤醒时间[MIN]分钟",
    "gui.blocklyText.pythonsen0377.MEMSgas_get_power_mode": "MEMS气体传感器获取传感器是否为睡眠模式？",
    "gui.blocklyText.pythonsen0377.MEMSgasset_mode": "MEMS气体传感器设置模式[MODE]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_adc_data": "MEMS气体传感器获取传感器ADC数据[DATA]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_gas_exist": "MEMS气体传感器判断是否存在[TYPE]?",
    "gui.blocklyText.pythonsen0377.MEMSgasget_gas_ppm": "MEMS气体传感器获取[TYPE]数据",
    "gui.blocklyText.pythonsen0377.wakeup_mode": "唤醒模式",
    "gui.blocklyText.pythonsen0377.sleep_mode": "睡眠模式",
    "gui.blocklyText.pythonsen0377.OX_MODE": "氧化模式",
    "gui.blocklyText.pythonsen0377.RED_MODE": "还原模式",
    "gui.blocklyText.pythonsen0377.CO": "一氧化碳",
    "gui.blocklyText.pythonsen0377.CH4": "甲烷",
    "gui.blocklyText.pythonsen0377.C2H5OH": "乙醇",
    "gui.blocklyText.pythonsen0377.C3H8": "丙烷",
    "gui.blocklyText.pythonsen0377.C4H10": "异丁烷",
    "gui.blocklyText.pythonsen0377.H2": "氢气",
    "gui.blocklyText.pythonsen0377.H2S": "硫化氢",
    "gui.blocklyText.pythonsen0377.NH3": "氨气",
    "gui.blocklyText.pythonsen0377.NO": "一氧化氮",
    "gui.blocklyText.pythonsen0377.NO2": "二氧化氮",
    "gui.blocklyText.sen0117.init": "初始模塊接口 [SERIALPORT] Rx[RX] Tx[TX] Busy[BUSY]",
    "gui.blocklyText.sen0117.playText": "播放文字 [TEXT] 背景音樂 [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "播放聲音提示音 [INDEX] 等待結束 [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "播放和弦提示音 [INDEX] 等待結束 [WAIT]",
    "gui.blocklyText.sen0117.playChordCueTone": "播放和弦鈴聲 [INDEX] 等待結束 [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "設置 [INDEX] 為 [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "設置語速為 [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "獲取語音合成模塊 [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "前景音量",
    "gui.blocklyText.sen0117.Background": "背景音量",
    "gui.blocklyText.sen0117.Speed": "語速",
    "gui.blocklyText.sen0117.No": "無 (0)",
    "gui.blocklyText.sen0117.setPlayMode": "設置播放模式為 [MODE]",
    "gui.blocklyText.sen0117.pause": "暫停",
    "gui.blocklyText.sen0117.resume": "繼續",
    "gui.blocklyText.sen0117.stop": "停止",
    "gui.blocklyText.dfr0760.init": "初始化语音合成模块 I2C模式 [VERSION] I2C地址0x40",
    "gui.blocklyText.dfr0760.syntheticVoice": "合成语音 [VOICEVALUE]",
    "gui.blocklyText.dfr0760.flashStorage": "使用flash存储 [FALSH]",
    "gui.blocklyText.dfr0760.softwareSerial": "初始化 [VERSION] 语音合成模块接口 [SERIALPORT] Rx(绿)[PINR] Tx(蓝)[PINT]",
    "gui.blocklyText.dfr0760.speechSynthesisSettings": "设置 音量 [VOLUME]语速 [SPEED]语调 [INTONATION]发音人 [SPEEKER]",
    "gui.blocklyText.dfr0760.setEnglishPron": "设置单词发音方式 [Pron]",
    "gui.blocklyText.dfr0760.letter": "字母",
    "gui.blocklyText.dfr0760.word": "单词",
    "gui.blocklyText.dfr0760.manvoice1": "男声1(V1)",
    "gui.blocklyText.dfr0760.manvoice2": "男声2(V1)",
    "gui.blocklyText.dfr0760.womanvoice1": "女声1(V1)",
    "gui.blocklyText.dfr0760.womanvoice2": "女声2(V1)",
    "gui.blocklyText.dfr0760.womanvoice3": "女声3(V1)",
    "gui.blocklyText.dfr0760.donaldDuck": "唐老鸭(V1)",
    "gui.blocklyText.pythondfr0760.extensionName": "语音合成",
    "gui.blocklyText.pythondfr0760.init": "初始化语音合成模块 I2C模式 [VERSION] I2C地址0x40",
    "gui.blocklyText.pythondfr0760.syntheticVoice": "合成语音 [VOICEVALUE]",
    "gui.blocklyText.pythondfr0760.flashStorage": "使用flash存储 [FALSH]",
    "gui.blocklyText.pythondfr0760.speechSynthesisSettings": "设置 音量 [VOLUME]语速 [SPEED]语调 [INTONATION]发音人 [SPEEKER]",
    "gui.blocklyText.pythondfr0760.setEnglishPron": "设置单词发音方式 [Pron]",
    "gui.blocklyText.pythondfr0760.setNumberPron": "设置数字发音方式 [Pron]",
    "gui.blocklyText.pythondfr0760.manvoice1": "男声1(V1)",
    "gui.blocklyText.pythondfr0760.manvoice2": "男声2(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice1": "女声1(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice2": "女声2(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice3": "女声3(V1)",
    "gui.blocklyText.pythondfr0760.donaldDuck": "唐老鸭(V1)",
    "gui.blocklyText.pythondfr0760.auto": "自动",
    "gui.blocklyText.pythondfr0760.number": "号码",
    "gui.blocklyText.pythondfr0760.value": "数值",
    "gui.blocklyText.dfr0715.loop": "循环模式",
    "gui.blocklyText.dfr0715.password": "指令模式",
    "gui.blocklyText.dfr0715.button": "按钮模式",
    "gui.blocklyText.dfr0715.mic": "默认",
    "gui.blocklyText.dfr0715.word": "外部音频",
    "gui.blocklyText.dfr0715.ASRInit": "语音识别模块 初始化 识别模式为 [MODE]麦克风模式为 [PHONEMODE] I2C地址为0x4F",
    "gui.blocklyText.dfr0715.addKeywords": "添加关键词 [KEYWORD]编号为 [NUMBER]",
    "gui.blocklyText.dfr0715.beginToIdentify": "设置完成 开始识别",
    "gui.blocklyText.dfr0715.recognitionOfASpeech": "识别一次语音，获取编号",
    "gui.blocklyText.dfr0646.tmi1650Init": "初始化TMI1650显示器 I2C地址 [ADDRESS]",
    "gui.blocklyText.dfr0646.eightTubeMode": "八位数码管_TM1650 [TYPE]",
    "gui.blocklyText.dfr0646.eightTubeShowInput": "八位数码管_TM1650显示字符串 [TEXT]",
    "gui.blocklyText.dfr0646.eightTubeControlPoint": "八位数码管_TM1650第[ORDER] 个小数点 [STATUS]",
    "gui.blocklyText.pythondfr0715.extensionName": "语音识别",
    "gui.blocklyText.pythondfr0715.ASRInit": "语音识别模块 初始化 识别模式为 [MODE]麦克风模式为 [PHONEMODE] I2C地址为0x4F",
    "gui.blocklyText.pythondfr0715.addKeywords": "添加关键词 [KEYWORD]编号为 [NUMBER]",
    "gui.blocklyText.pythondfr0715.beginToIdentify": "设置完成 开始识别",
    "gui.blocklyText.pythondfr0715.recognitionOfASpeech": "识别一次语音，获取编号",
    "gui.blocklyText.sen0117.isIdle": "播放完成?",
    "gui.blocklyText.dfr0576.selectPort": "選通端口 [PORT]",
    "gui.blocklyText.dfr0576.begin": "初始化IIC級聯模塊地址為 [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680初始化",
    "gui.blocklyText.sen0248.readBME680": "獲取BME680傳感器 [TYPE]",
    "gui.blocklyText.pythonsen0248.bme680Init": "BME680初始化 I2C地址0x77",
    "gui.blocklyText.pythonsen0248.updateBme680": "获取一次BME680传感器数据",
    "gui.blocklyText.pythonsen0248.readBME680": "从数据中解析 [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "溫度 (°C)",
    "gui.blocklyText.sen0248.Pressure": "氣壓 (Pa)",
    "gui.blocklyText.sen0248.Humidity": "濕度 (%rh)",
    "gui.blocklyText.sen0248.Altitude": "海拔 (m)",
    "gui.blocklyText.sen0248.GasResistance": "氣體電阻值 (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "初始化I2C超聲波傳感器地址為 [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "更改I2C超聲波傳感器地址為 [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "讀取I2C超聲波傳感器距離(cm)",
    "gui.blocklyText.sen0304.readTemperature": "讀取I2C超聲波傳感器溫度(°C)",
    "gui.blocklyText.pythonsen0304.extensionName": "超声波（I2C）",
    "gui.blocklyText.pythonsen0304.initialIICAddr": "初始化I2C超声波传感器 [NAME] 地址为 [ADDR]",
    "gui.blocklyText.pythonsen0304.changeI2CAddr": "更改I2C超声波传感器 [NAME] 地址为 [ADDR] (断电重启生效)",
    "gui.blocklyText.pythonsen0304.readDistance": "读取I2C超声波传感器 [NAME] 距离(cm)",
    "gui.blocklyText.pythonsen0304.readTemperature": "读取I2C超声波传感器 [NAME] 温度(°C)",
    "gui.blocklyText.sen0307.readDistance": "讀取 [PIN] 模擬量超聲波(cm)",
    "gui.blocklyText.pythonsen0307.extensionName": "超声波(模拟量)",
    "gui.blocklyText.pythonsen0307.init": "模拟量超声波[NAME] 初始化 引脚[PIN] ",
    "gui.blocklyText.pythonsen0307.readDistance": "[NAME] 读取模拟量超声波(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 初始化 地址 [ADDR] 模式 [MODE]",
    "gui.blocklyText.sen0250.Step": "計步",
    "gui.blocklyText.sen0250.Measure": "測量",
    "gui.blocklyText.sen0250.readAccelerated": "BMI160 獲取 [AXIS] 軸加速度值(g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 獲取步數量",
    "gui.blocklyText.sen0250.0x69": "0×69（SDO = 1）",
    "gui.blocklyText.sen0250.0x68": "0x68（SDO = 0）",
    "gui.blocklyText.sen0250.Gyro": "陀螺儀",
    "gui.blocklyText.sen0250.Acc": "加速度 (g)",
    "gui.blocklyText.sen0224.init": "初始化LIS2DH傳感器",
    "gui.blocklyText.sen0224.readAccelerated": "讀取LIS2DH傳感器 [AXIS] 軸加速度值(mg)",
    "gui.blocklyText.dfr0023.readLM35": "讀取引腳 [PIN] LM35溫度(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "當引腳 [PIN] 檢測到震動時",
    "gui.blocklyText.dfr0028.readTiltSensor": "引腳 [PIN] 檢測到傾斜？",
    "gui.blocklyText.pythondfr0028.extensionName": "倾斜",
    "gui.blocklyText.pythondfr0028.readTiltSensor": "引脚 [PIN] 检测到倾斜？",
    "gui.blocklyText.dfr0029.readPress": "引腳 [PIN] 按鈕被按下？",
    "gui.blocklyText.pythondfr0029.extensionName": "按钮",
    "gui.blocklyText.pythondfr0029.readPress": "引脚 [PIN] 按钮被按下？",
    "gui.blocklyText.dfr0030.readTouch": "引腳 [PIN] 檢測到觸摸？",
    "gui.blocklyText.pythondfr0030.extensionName": "触摸",
    "gui.blocklyText.pythondfr0030.readTouch": "引脚 [PIN] 检测到触摸？",
    "gui.blocklyText.dfr0033.readMagnetic": "引腳 [PIN] 檢測到磁鐵？",
    "gui.blocklyText.pythondfr0033.extensionName": "磁力",
    "gui.blocklyText.pythondfr0033.readMagnetic": "引脚 [PIN] 检测到磁铁？",
    "gui.blocklyText.dfr0034.readSound": "讀取引腳 [PIN] 聲音強度",
    "gui.blocklyText.pythondfr0034.extensionName": "声音强度",
    "gui.blocklyText.pythondfr0034.readSound": "读取引脚 [PIN] 声音强度",
    "gui.blocklyText.dfr0094.readIRReceived": "讀取引腳 [PIN] 紅外接收值",
    "gui.blocklyText.dfr0094.onReceiveEvent": "當紅外接收到信號 引腳 [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "地址",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "命令",
    "gui.blocklyText.midi.midiInit": "MIDI初始化 [SERIALPORT] 引脚RX [RX] 引脚TX [TX]",
    "gui.blocklyText.midi.midiOutput": "MIDI 输出 通道 [CHANNEL] 音高 [PITCH] 速度 [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "讀取引腳 [PIN] 一氧化碳氣體傳感器",
    "gui.blocklyText.pythonsen0132.extensionName": "CO气体",
    "gui.blocklyText.pythonsen0132.readCarbonMonoxide": "读取引脚 [PIN] 一氧化碳气体传感器",
    "gui.blocklyText.dfr0051.readVoltage": "讀取引腳 [PIN] 電壓值(V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "讀取引腳 [PIN] 壓電陶瓷震動傳感器",
    "gui.blocklyText.pythondfr0052.extensionName": "震动",
    "gui.blocklyText.pythondfr0052.readPiezoSensor": "读取引脚 [PIN] 压电陶瓷震动传感器",
    "gui.blocklyText.dfr0058.readRotation": "讀取引腳 [PIN] 多圈旋轉角度傳感器",
    "gui.blocklyText.pythondfr0058.extensionName": "旋转角度",
    "gui.blocklyText.pythondfr0058.readRotation": "读取引脚 [PIN] 多圈旋转角度传感器",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "讀取引腳 [PIN] 搖桿 [AXIS] 軸",
    "gui.blocklyText.dfr0061.readJoyStickButton": "引腳 [PIN] 搖桿按鈕(Z)被按下？",
    "gui.blocklyText.pythondfr0061.extensionName": "摇杆",
    "gui.blocklyText.pythondfr0061.readJoyStickAxis": "读取引脚 [PIN] 摇杆 [AXIS] 轴",
    "gui.blocklyText.pythondfr0061.readJoyStickButton": "引脚 [PIN] 摇杆按钮(Z)被按下？",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOut初始化數據引腳 [DATAPIN] 時鐘引腳 [RTCPIN] 片選引腳 [CLSPIN] 字節序 [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "數碼管顯示段碼0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "數碼管顯示數字 [NUM]",
    "gui.blocklyText.dfr0072.numOff": "關閉",
    "gui.blocklyText.dfr0072.dfr0072Dot": "數碼管顯示點 [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "是",
    "gui.blocklyText.dfr0072.dotFalse": "否",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "讀取鋰電池 [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "電壓(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "剩餘電量(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD模塊 初始化I2C地址 [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD模塊 讀取 [PIN] 電壓 (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD模塊 獲取通道 [AISLE] 電壓差",
    "gui.blocklyText.pythondfr0553.dfr0553InitIICAddr": "AD模块[NAME] 初始化I2C地址 [ADDR]",
    "gui.blocklyText.pythondfr0553.dfr0553ReadVoltage": "AD模块[NAME] 读取 [PIN] 电压 (mV)",
    "gui.blocklyText.pythondfr0553.dfr0553GetAisleVoltageDifference": "AD模块[NAME] 获取通道 [AISLE] 电压差",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 和 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 和 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 和 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 和 3",
    "gui.blocklyText.dfr0588.initPin": "初始化 溫度引腳 [TEM] 濕度引腳 [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "獲取溫度 單位 [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "獲取濕度(%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "攝氏度",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "華氏度",
    "gui.blocklyText.dfr0076.readFlameSensor": "讀取引腳 [PIN] 火焰傳感器",
    "gui.blocklyText.pythondfr0076.extensionName": "火焰",
    "gui.blocklyText.pythondfr0076.readFlameSensor": "读取引脚 [PIN] 火焰传感器",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom 獲取 [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom 初始化I2C地址 [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom 寫入文本 [VALUE] 名稱 [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom 寫入數字 [VALUE] 名稱 [KEY]",
    "gui.blocklyText.dfr0117.text": "文本",
    "gui.blocklyText.dfr0117.number": "數字",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom 讀取文本 名稱 [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom 讀取數字 名稱 [KEY]",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom 刪除 名稱 [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom 串口打印全部名稱 波特率 [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom 名稱 [KEY] 存在?",
    "gui.blocklyText.dfr0117.eepromFormat": "i2c eeprom 格式化",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom 名稱 [KEY] 的值是 [TYPE] 類型?",
    "gui.blocklyText.dfr0117.nameCount": "名稱個數",
    "gui.blocklyText.dfr0117.memorySize": "儲存空間(byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "剩餘儲存空間(byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": "讀取引腳 [PIN] 三軸加速度計 [AXIS] 軸",
    "gui.blocklyText.sen0018.readInfraredMotion": "讀取引腳 [PIN] 人體紅外熱釋電運動傳感器",
    "gui.blocklyText.pythonsen0018.extensionName": "运动",
    "gui.blocklyText.pythonsen0018.readInfraredMotion": "读取引脚 [PIN] 人体红外热释电运动传感器?",
    "gui.blocklyText.sen0014.readDistance": "讀取引腳 [PIN] Sharp紅外測距傳感器(mm)",
    "gui.blocklyText.sen0114.readMoisture": "讀取引腳 [PIN] 土壤濕度傳感器",
    "gui.blocklyText.pythonsen0114.extensionName": "土壤湿度",
    "gui.blocklyText.pythonsen0114.readMoisture": "读取引脚 [PIN] 土壤湿度传感器",
    "gui.blocklyText.sen0121.readSteam": "讀取引腳 [PIN] 水分傳感器",
    "gui.blocklyText.pythonsen0121.extensionName": "水分",
    "gui.blocklyText.pythonsen0121.readSteam": "读取引脚 [PIN] 水分传感器",
    "gui.blocklyText.sen0204.readLiquidLevel": "引腳 [PIN] 液位到達？",
    "gui.blocklyText.pythonsen0204.extensionName": "液位",
    "gui.blocklyText.pythonsen0204.readLiquidLevel": "引脚 [PIN] 液位到达？",
    "gui.blocklyText.sen0203.heartRate": "心率(次/分)",
    "gui.blocklyText.sen0203.bloodOxygen": "血氧飽和度(HbO2)",
    "gui.blocklyText.sen0202.up": "起色",
    "gui.blocklyText.sen0202.down": "向下",
    "gui.blocklyText.sen0202.left": "向左",
    "gui.blocklyText.sen0202.right": "向右",
    "gui.blocklyText.sen0202.cw": "順時針",
    "gui.blocklyText.sen0202.ccw": "逆時針",
    "gui.blocklyText.dhtTHSensor.temperature": "溫度(°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "濕度(%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "RGB燈 引腳 [PIN] 燈號 [NUM1] 到 [NUM2] 顯示顏色 [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "紅 [RED] 綠 [GREEN] 藍 [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "RGB燈 引腳 [PIN] 燈號 [NUM1] 到 [NUM2] 顯示漸變顏色 色調從 [COLOR1] 到 [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "RGB燈 引腳 [PIN] 移動 [NUM] 單位",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "RGB燈 引腳 [PIN] 循環移動 [NUM] 單位",
    "gui.blocklyText.ws2812.numberOfLightsBright": "pin [PIN] [NUM] RGB LEDs",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "RGB燈 引腳 [PIN] 燈總數 亮度 [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "RGB燈 引腳 [PIN] 全部熄滅",
    "gui.blocklyText.ws2812.showHistogram": "RGB燈 引腳 [PIN] 燈號 [NUM1] 到 [NUM2] 顯示柱狀圖 當前值 [VALUE] 最大值 [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "RGB燈 設置引腳 [PIN] 燈帶亮度為 [BRIGHTNESS]",
    "gui.blocklyText.neopixel.init": "燈帶初始化 名稱 [NAME] 引腳 [PIN] 數量 [COUNT]",
    "gui.blocklyText.neopixel.setColor": "燈帶 [NAME] [INDEX] 號 顏色為 [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "燈帶 [NAME] [INDEX] 號 紅 [R] 綠 [G] 藍 [B]",
    "gui.blocklyText.neopixel.fillColor": "燈帶 [NAME] 全亮 顏色為 [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "燈帶 [NAME] 全亮 紅 [R] 綠 [G] 藍 [B]",
    "gui.blocklyText.neopixel.flush": "燈帶 [NAME] 設置生效",
    "gui.blocklyText.neopixel.off": "燈帶 [NAME] 關閉",
    "gui.blocklyText.neopixel.setBrightness": "燈帶 [NAME] 設置亮度為 [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "彩虹燈效 燈帶 [NAME] 數量 [COUNT] 亮度 [BRIGHTNESS] 偏移 [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7（掌控板）",
    "gui.blocklyText.neopixel.neopixel_P9": "P9（掌控實驗箱）",
    "gui.blocklyText.dsTSensor.ds18b20": "讀取引腳 [PIN] DS18B20溫度(℃)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280初始化",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "讀取BMP280傳感器 [TYPE] 的值",
    "gui.blocklyText.pythonsen0372.bmp280Init": "BMP280 [NAME] 初始化 I2C地址[ADDRESS]",
    "gui.blocklyText.pythonsen0372.readBmpSensor": "读取BMP280传感器[NAME] [TYPE] 的值",
    "gui.blocklyText.bmpTSensor.atmosPressure": "氣壓(Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "海拔(m)",
    "gui.blocklyText.bmpTSensor.temperature": "溫度(℃)",
    "gui.blocklyText.huskylens.init": "HuskyLens 初始化引脚为 [SETTING] 直到成功",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens 切换到 [ALGORITHM] 算法 直到成功",
    "gui.blocklyText.huskylens.requestFirst": "HuskyLens 请求一次数据 存入结果",
    "gui.blocklyText.huskylens.resultGetIsStudy": "HuskyLens 从结果中获取ID [IDNUM] 是否已学习？",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 是否在画面中？",
    "gui.blocklyText.huskylens.resultGetIDParameter": "HuskyLens 从结果中获取ID [IDNUM] [TYPE] 的参数 [PARAMETER]",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens 从结果中获取 已学ID总数",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens 从结果中获取 [BOXARROW] 总数",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 总数",
    "gui.blocklyText.huskylens.resultGetIDofWhichParameter": "HuskyLens 从结果中获取ID [IDNUM] 第 [BOXNUM] 个[TYPE]的参数 [PARAMETER]",
    "gui.blocklyText.huskylens.setIDname": "HuskyLens 设置当前算法ID [ID] 名字为 [NAME]",
    "gui.blocklyText.huskylens.TakeScreenshot": "HuskyLens 触发 [TYPE] 保存到SD卡",
    "gui.blocklyText.huskylens.writeOSD": "HuskyLens 屏幕叠加显示文字 [NAME] 在x [X] y [Y]",
    "gui.blocklyText.huskylens.clearOSD": "HuskyLens 清除屏幕显示的文字",
    "gui.blocklyText.huskylens.learnOnce": "HuskyLens 自动学习一次 ID [ID]",
    "gui.blocklyText.huskylens.clearLearn": "HuskyLens 遗忘当前算法的所有学习数据",
    "gui.blocklyText.huskylens.loadAndSaveModel": "HuskyLens [TYPE] 当前算法数据为SD卡 [INDEX] 号模型",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.A": "拍照",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.B": "截屏",
    "gui.blocklyText.huskylens.LOADSAVE.A": "保存",
    "gui.blocklyText.huskylens.LOADSAVE.B": "加载",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens 从结果中获取 [BOXARROW] 是否在画面中？",
    "gui.blocklyText.huskylens.resultGetNearCenter": "HuskyLens 从结果中获取靠近中心 [TYPE] 的 [PARAMETER2] 参数",
    "gui.blocklyText.huskylens.resultGetParameter": "HuskyLens 从结果中获取第 [IDNUM] 个 [TYPE] 的 [PARAMETER2] 参数",
    "gui.blocklyText.huskylens.box": "方框",
    "gui.blocklyText.huskylens.arrow": "箭頭",
    "gui.blocklyText.huskylens.faceRecognition": "人臉識別",
    "gui.blocklyText.huskylens.objectTracking": "物體追踪",
    "gui.blocklyText.huskylens.objectRecognition": "物體識別",
    "gui.blocklyText.huskylens.lineTracking": "巡線",
    "gui.blocklyText.huskylens.colorRecognition": "顏色識別",
    "gui.blocklyText.huskylens.tagRecognition": "標籤識別",
    "gui.blocklyText.huskylens.objectClassification": "物體分類",
    "gui.blocklyText.huskylens.QRRecognition": "二維碼識別 (教育版獨有)",
    "gui.blocklyText.huskylens.barcodeRecognition": "條形碼識別 (教育版獨有)",
    "gui.blocklyText.huskylens.ID": "ID",
    "gui.blocklyText.huskylens.x": "X中心",
    "gui.blocklyText.huskylens.y": "Y中心",
    "gui.blocklyText.huskylens.w": "寬度",
    "gui.blocklyText.huskylens.h": "高度",
    "gui.blocklyText.huskylens.x1": "X起點",
    "gui.blocklyText.huskylens.y1": "Y起點",
    "gui.blocklyText.huskylens.x2": "X終點",
    "gui.blocklyText.huskylens.y2": "Y終點",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "通訊方式",
    "gui.blocklyText.huskylens.address": "地址",
    "gui.blocklyText.huskylens.sdaGreen": "SDA(綠)",
    "gui.blocklyText.huskylens.sclBlue": "SCL(藍)",
    "gui.blocklyText.huskylens.rxGreen": "Rx(綠)",
    "gui.blocklyText.huskylens.txBlue": "Tx(藍)",
    "gui.blocklyText.pythonhuskylens.extensionName": "二哈识图",
    "gui.blocklyText.pythonhuskylens.init": "HuskyLens 初始化I2C地址为0x32",
    "gui.blocklyText.pythonhuskylens.changeAlgorithm": "HuskyLens 切换到 [ALGORITHM] 算法 直到成功",
    "gui.blocklyText.pythonhuskylens.requestFirst": "HuskyLens 请求一次数据 存入结果",
    "gui.blocklyText.pythonhuskylens.resultGetIsStudy": "HuskyLens 从结果中获取ID [IDNUM] 是否已学习？",
    "gui.blocklyText.pythonhuskylens.resultGetIDIsInPicture": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 是否在画面中？",
    "gui.blocklyText.pythonhuskylens.resultGetIDParameter": "HuskyLens 从结果中获取ID [IDNUM] [TYPE] 的参数 [PARAMETER]",
    "gui.blocklyText.pythonhuskylens.resultGetStudyedID": "HuskyLens 从结果中获取 已学ID总数",
    "gui.blocklyText.pythonhuskylens.resultGetIsBoxArrowTotal": "HuskyLens 从结果中获取 [BOXARROW] 总数",
    "gui.blocklyText.pythonhuskylens.resultGetIDsBoxArrowTotal": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 总数",
    "gui.blocklyText.pythonhuskylens.resultGetIDofWhichParameter": "HuskyLens 从结果中获取ID [IDNUM] 第 [BOXNUM] 个[TYPE]的参数 [PARAMETER]",
    "gui.blocklyText.pythonhuskylens.setIDname": "HuskyLens 设置当前算法ID [ID] 名字为 [IDNAME]",
    "gui.blocklyText.pythonhuskylens.TakeScreenshot": "HuskyLens 触发 [TYPE] 保存到SD卡",
    "gui.blocklyText.pythonhuskylens.writeOSD": "HuskyLens 屏幕叠加显示文字 [TEXT] 在x [X] y [Y]",
    "gui.blocklyText.pythonhuskylens.clearOSD": "HuskyLens 清除屏幕显示的文字",
    "gui.blocklyText.pythonhuskylens.learnOnce": "HuskyLens 自动学习一次 ID [ID]",
    "gui.blocklyText.pythonhuskylens.clearLearn": "HuskyLens 遗忘当前算法的所有学习数据",
    "gui.blocklyText.pythonhuskylens.loadAndSaveModel": "HuskyLens [TYPE] 当前算法数据为SD卡 [INDEX] 号模型",
    "gui.blocklyText.pythonhuskylens.resultGetIsInPicture": "HuskyLens 从结果中获取 [BOXARROW] 是否在画面中？",
    "gui.blocklyText.pythonhuskylens.resultGetNearCenter": "HuskyLens 从结果中获取靠近中心[TYPE]的 [PARAMETER2] 参数",
    "gui.blocklyText.pythonhuskylens.resultGetParameter": "HuskyLens 从结果中获取第 [IDNUM] 个 [TYPE] 的 [PARAMETER2] 参数",
    "gui.blocklyText.ps2.initPs2Handle": "初始化PS2手柄 DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] 振动反馈 [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2振动 [RUMBLE] 强度 [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "PS2刷新一次状态",
    "gui.blocklyText.ps2.ps2ButtonState": "PS2按键 [BUTTON] 状态为 [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2摇杆 [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "开",
    "gui.blocklyText.ps2.OFF": "关",
    "gui.blocklyText.ps2.Triangle": "三角形键",
    "gui.blocklyText.ps2.Circle": "圆形键",
    "gui.blocklyText.ps2.Cross": "x键",
    "gui.blocklyText.ps2.Square": "方形键",
    "gui.blocklyText.ps2.Left1": "左侧1",
    "gui.blocklyText.ps2.Left2": "左侧2",
    "gui.blocklyText.ps2.Left3": "左侧3",
    "gui.blocklyText.ps2.Right1": "右侧1",
    "gui.blocklyText.ps2.Right2": "右侧2",
    "gui.blocklyText.ps2.Right3": "右侧3",
    "gui.blocklyText.ps2.Up": "方向键上",
    "gui.blocklyText.ps2.Right": "方向键右",
    "gui.blocklyText.ps2.Down": "方向键下",
    "gui.blocklyText.ps2.Left": "方向键左",
    "gui.blocklyText.ps2.Select": "选择键",
    "gui.blocklyText.ps2.Start": "开始键",
    "gui.blocklyText.ps2.Hold": "按住",
    "gui.blocklyText.ps2.Pressed": "按下",
    "gui.blocklyText.ps2.Released": "松开",
    "gui.blocklyText.ps2.CHANGE": "改变",
    "gui.blocklyText.ps2.RightX": "右侧X值",
    "gui.blocklyText.ps2.RightY": "右侧Y值",
    "gui.blocklyText.ps2.LeftX": "左侧X值",
    "gui.blocklyText.ps2.LeftY": "左侧Y值",
    "gui.blocklyText.sen0236.bme280Init": "BME280初始化",
    "gui.blocklyText.sen0236.readbme280Sensor": "讀取I2C引腳BME280 [TYPE]",
    "gui.blocklyText.pythonsen0236.bme280Init": "BME280 初始化 I2C地址0x77",
    "gui.blocklyText.pythonsen0236.readbme280Sensor": "读取I2C引脚BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "溫度(℃)",
    "gui.blocklyText.sen0236.Humidity": "濕度(%rh)",
    "gui.blocklyText.sen0236.Altitude": "海拔(m)",
    "gui.blocklyText.sen0236.Pressure": "氣壓(Pa)",
    "gui.blocklyText.sen0517.icp10111Init": "ICP10111初始化 I2C地址0x63",
    "gui.blocklyText.sen0517.readicp10111Sensor": "获取ICP10111传感器 [TYPE] 的值",
    "gui.blocklyText.sen0517.Temperature": "温度(℃)",
    "gui.blocklyText.sen0517.Altitude": "海拔(m)",
    "gui.blocklyText.sen0517.Pressure": "气压(Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "初始化I2C液晶顯示屏 地址為[ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "I2C液晶顯示屏在第 [LINE] 行顯示 [TEXT]",
    "gui.blocklyText.LCD1602.displayInXY": "I2C液晶顯示屏在坐標 X: [X] Y: [Y] 顯示 [TEXT]",
    "gui.blocklyText.LCD1602.displayClear": "I2C液晶顯示屏清屏",
    "gui.blocklyText.pythonLCD1602.initialIICAddr": "初始化I2C液晶显示屏 [NAME] 地址为[ADDR]",
    "gui.blocklyText.pythonLCD1602.displayInLine": "I2C液晶显示屏 [NAME] 在第 [LINE] 行显示 [TEXT]",
    "gui.blocklyText.pythonLCD1602.displayInXY": "I2C液晶显示屏 [NAME] 在坐标 X: [X] Y: [Y] 显示 [TEXT]",
    "gui.blocklyText.pythonLCD1602.displayClear": "I2C液晶显示屏 [NAME] 清屏",
    "gui.blocklyText.IICScan.readICCAddr": "讀取掃描到的I2C設備地址",
    "gui.blocklyText.mpyfile.initFile": "初始化文本文件 [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsList": "读取文本文件 [FILENAME] 为列表, 以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.writeFile": "向文本文件 [FILENAME] 写入内容 [WRITEDATA] ,以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.initFileMaixduino": "初始化 [ROOT] 文件 [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsListMaixduino": "读取 [ROOT] 文件 [FILENAME] 为列表, 以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.writeFileMaixduino": "向 [ROOT] 文件 [FILENAME] 写入内容 [WRITEDATA],以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.enter": "回车",
    "gui.blocklyText.mpyfile.comma": "逗号",
    "gui.blocklyText.mpyfile.semicolon": "分号",
    "gui.blocklyText.mpyfile.space": "空格",
    "gui.blocklyText.sdcard.initCS": "SD 片選引腳 [CSPIN] 初始化成功？",
    "gui.blocklyText.sdcard.readFileNLine": "SD 讀取文件 [FILE] 第 [LINE] 行",
    "gui.blocklyText.sdcard.readFileAll": "SD 讀取文件 [FILE] 全部內容",
    "gui.blocklyText.sdcard.writeFile": "SD 向文件 [FILE] 寫入 [MSG] 模式 [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD 刪除文件 [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD 獲取文件 [FILE] 信息 [INFO]",
    "gui.blocklyText.sdcard.NAME": "名稱",
    "gui.blocklyText.sdcard.PATH": "路徑",
    "gui.blocklyText.sdcard.TYPE": "類型",
    "gui.blocklyText.sdcard.LINE": "行數",
    "gui.blocklyText.sdcard.SIZE": "大小",
    "gui.blocklyText.sdcard.APPEND": "加法",
    "gui.blocklyText.sdcard.REPLACE": "替換",
    "gui.blocklyText.sdcard.WARP": "換行",
    "gui.blocklyText.sdcard.NOWARP": "不換行",
    "gui.blocklyText.sdcard.lsFile": "SD 串口打印全部文件信息 波特率 [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "初始化軟串口 [SERIALPORT] Rx: [PINR] Tx: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "設置軟串口 [SERIALPORT] 波特率為 [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "軟串口 [SERIALPORT] [WAY] [TEXT] [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "軟串口 [SERIALPORT] 有數據可讀？",
    "gui.blocklyText.softSerialport.readSerialportData": "讀取軟串口 [SERIALPORT] 的數據",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "從軟串口 [SERIALPORT] 讀一個 [TYPE]",
    "gui.blocklyText.bluetooth.connectSuccess": "當藍牙連接成功時運行",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "當藍牙斷開連接時運行",
    "gui.blocklyText.bluetooth.receivedKey": "當藍牙接收到消息時運行 key [KEY] value [TYPE]",
    "gui.blocklyText.bluetooth.string": "字符串",
    "gui.blocklyText.bluetooth.number": "數字",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "當藍牙接收到數據並遇到 [SYMBOL] 時運行",
    "gui.blocklyText.bluetooth.sendStr": "藍牙發送字符串 key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "藍牙發送數字 key [KEY] value [VALUE]",
    "gui.blocklyText.bluetooth.openService": "打開藍牙服務",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "藍牙接收消息",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo主程序開始",
    "gui.blocklyText.leonardo.setBaud": "設置 [SERIAL] 波特率為 [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "從 [SERIAL] 讀取一個 [READTYPE]",
    "gui.blocklyText.leonardo.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.leonardo.serialPort1": "串口0",
    "gui.blocklyText.leonardo.serialPort2": "串口1",
    "gui.blocklyText.leonardo.dataReadable": "[SERIAL] 有數據可讀？",
    "gui.blocklyText.leonardo.readSerialData": "讀取 [SERIAL] 數據",
    "gui.blocklyText.arduinounoR3.initTcs34725": "初始化TCS34725顏色傳感器",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "初始化TMI1650顯示器",
    "gui.blocklyText.arduinounoR3.functionStarts": "uno套件程序開始",
    "gui.blocklyText.arduinounoR3.readdigital": "讀取 [PIN] 引腳 [MODULE] 的值",
    "gui.blocklyText.arduinounoR3.digitalSensor": "數字傳感器",
    "gui.blocklyText.arduinounoR3.buttonModule": "按鈕模塊",
    "gui.blocklyText.arduinounoR3.stickButton": "搖桿按鈕",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "震動傳感器",
    "gui.blocklyText.arduinounoR3.readAnalog": "讀取 [PIN] 引腳 [SENSOR] 的值",
    "gui.blocklyText.arduinounoR3.anologSensor": "模擬傳感器",
    "gui.blocklyText.arduinounoR3.lightSensor": "光線傳感器",
    "gui.blocklyText.arduinounoR3.soundSensor": "聲音傳感器",
    "gui.blocklyText.arduinounoR3.stickDirection": "搖桿方向",
    "gui.blocklyText.arduinounoR3.angleSensor": "角度傳感器",
    "gui.blocklyText.arduinounoR3.graySensor": "灰度傳感器",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "土壤濕度傳感器",
    "gui.blocklyText.arduinounoR3.open": "打開",
    "gui.blocklyText.arduinounoR3.close": "關閉",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] [PIN] 引腳的 [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "數字執行器",
    "gui.blocklyText.arduinounoR3.ledLights": "LED燈",
    "gui.blocklyText.arduinounoR3.fanModule": "風扇模塊",
    "gui.blocklyText.arduinounoR3.recordModule": "錄音模塊",
    "gui.blocklyText.arduinounoR3.relay": "繼電器",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "讀取超聲波距離(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "模擬執行器",
    "gui.blocklyText.arduinounoR3.writeAnalog": "設置 [PIN] 引腳 [MODULE] 的值為 [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "前進",
    "gui.blocklyText.arduinounoR3.backward": "後退",
    "gui.blocklyText.arduinounoR3.turnLeft": "左轉",
    "gui.blocklyText.arduinounoR3.turnRight": "右轉",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "機器人以 [SPEED] %速度 [DIR]",
    "gui.blocklyText.arduinounoR3.motorForward": "正轉",
    "gui.blocklyText.arduinounoR3.motorReverse": "反轉",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "設置電機 [MOTOR] 以 [SPEED] % 速度 [DIR]",
    "gui.blocklyText.arduinounoR3.stopMotor": "機器人(MA和MB電機)停止",
    "gui.blocklyText.arduinounoR3.setServo": "設置 [PIN] 引腳伺服舵機為 [DEGREE] 度",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "紅外發射 [TYPE] 引腳 [PIN] 數值 [VALUE] 比特數 [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "紅外 [PIN] 引腳是否接收到 [BUTTON] 按鈕按下？",
    "gui.blocklyText.arduinounoR3.noSound": "無聲",
    "gui.blocklyText.arduinounoR3.half": "1/2",
    "gui.blocklyText.arduinounoR3.quarter": "1/4",
    "gui.blocklyText.arduinounoR3.oneInEighth": "1/8",
    "gui.blocklyText.arduinounoR3.wholeBeat": "整拍",
    "gui.blocklyText.arduinounoR3.doubleBeat": "雙拍",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "設置 [PIN] 引腳喇叭音調為 [TONE] 節拍為 [BEAT]",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "設置 [PIN] 引腳MP3模塊的音量為 [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "設置 [PIN] 引腳MP3模塊的播放模式為 [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "停止",
    "gui.blocklyText.arduinounoR3.play": "播放",
    "gui.blocklyText.arduinounoR3.loop": "循環播放",
    "gui.blocklyText.arduinounoR3.random": "隨機播放",
    "gui.blocklyText.arduinounoR3.pause": "暫停",
    "gui.blocklyText.arduinounoR3.previous": "上一首",
    "gui.blocklyText.arduinounoR3.next": "下一首",
    "gui.blocklyText.arduinounoR3.VolumePlus": "音量加",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "音量減",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "設置 [PIN] 引腳MP3模塊播放第 [NUM] 首歌曲",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "讀取 [PIN] 引腳LM35溫度(℃)",
    "gui.blocklyText.arduinounoR3.readDHT11": "讀取 [PIN] 引腳DHT11的 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "紅色值",
    "gui.blocklyText.arduinounoR3.blue": "藍色值",
    "gui.blocklyText.arduinounoR3.green": "綠色值",
    "gui.blocklyText.arduinounoR3.readColorSensor": "讀取TCS34725顏色傳感器的 [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "四位數碼管_TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "開",
    "gui.blocklyText.arduinounoR3.tm1650Close": "關",
    "gui.blocklyText.arduinounoR3.clearScreen": "清屏",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": "四位數碼管_TM1650顯示字符串 [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "四位數碼管_TM1650第[ORDER] 個小數點 [STATUS]",
    "gui.blocklyText.arduinounoR3.bright": "亮",
    "gui.blocklyText.arduinounoR3.dark": "暗",
    "gui.blocklyText.arduinounoR3.setLightColor": "RGB燈 引腳 [PIN] 燈號 [NUM] [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "RGB燈 引腳 [PIN] 燈號 [NUM] R值:[R] G值:[G] B值:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "RGB燈 引腳 [PIN] 燈數 [NUM]",
    "gui.blocklyText.arduinounoR3.ledControl": "Led點陣(max7219)初始化參數 DIN引腳為 [DIN] CS管腳為 [CS] CLK管腳為 [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "Led點陣(max7219)顯示圖案 [PIC]",
    "videoSensing.categoryName": "視訊偵測",
    "videoSensing.direction": "方向",
    "videoSensing.motion": "動作",
    "videoSensing.off": "關閉",
    "videoSensing.on": "開啟",
    "videoSensing.onFlipped": "翻轉",
    "videoSensing.setVideoTransparency": "視訊透明度設為 [TRANSPARENCY]",
    "videoSensing.sprite": "角色",
    "videoSensing.stage": "舞台",
    "videoSensing.videoOn": "[SUBJECT] 的視訊 [ATTRIBUTE]",
    "videoSensing.videoToggle": "視訊設為 [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "當視訊動作 > [REFERENCE]",
    "gui.blocklyText.max.maxStart": "MAX主程序開始",
    "gui.blocklyText.max.onboardButtonPressed": "板載按鍵 [PRESSED]",
    "gui.blocklyText.max.pressed": "已按下",
    "gui.blocklyText.max.notPressed": "未按下",
    "gui.blocklyText.max.all": "-1 全部",
    "gui.blocklyText.max.A": "0 右後",
    "gui.blocklyText.max.B": "1 右前",
    "gui.blocklyText.max.C": "2左前",
    "gui.blocklyText.max.D": "3 左後",
    "gui.blocklyText.max.rgbLightsColor": "設置RGB燈 [TYPE] 的顏色為 [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "讀取聲音傳感器的值",
    "gui.blocklyText.max.playSoundEffect": "播放音效 [SOUND]",
    "gui.blocklyText.max.setDPinValue": "設置數字引腳 [PIN] 的值為 [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "讀取數字口 [PIN] 的值",
    "gui.blocklyText.max.readAPinValue": "讀取模擬口 [PIN] 的值",
    "gui.blocklyText.max.setServo": "設置 [PIN] 引腳舵機為 [DEGREE] 度",
    "gui.blocklyText.max.readLinefindingSensor": "讀取尋線傳感器 [DIR] 的值",
    "gui.blocklyText.max.left": "左",
    "gui.blocklyText.max.middle": "中",
    "gui.blocklyText.max.right": "權",
    "gui.blocklyText.max.setLineFidingCarLights": "設置尋線車燈 [SWITCH]",
    "gui.blocklyText.max.on": "亮",
    "gui.blocklyText.max.off": "滅",
    "gui.blocklyText.max.readUltrasoundSensor": "讀取超聲波傳感器的距離 (cm)",
    "gui.blocklyText.max.readLightSensor": "讀取光線傳感器的值 [LEFTRIGHT]",
    "gui.blocklyText.max.maxSpeedMove": "MAX以 [SPEED] 的速度 [DIR]",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] 以 [SPEED] 的速度 [DIR]",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] 停止",
    "gui.blocklyText.max.showUserDefineExpressions": "顯示自定義表情 [EXPRESSION] 顏色 [COLOR]",
    "gui.blocklyText.max.clear": "清屏",
    "gui.blocklyText.max.do": "1 樂譜do",
    "gui.blocklyText.max.re": "2 樂譜re",
    "gui.blocklyText.max.mi": "3 樂譜mi",
    "gui.blocklyText.max.fa": "4 樂譜fa",
    "gui.blocklyText.max.sou": "5 樂譜sou",
    "gui.blocklyText.max.la": "6 樂譜la",
    "gui.blocklyText.max.xi": "7 樂譜xi",
    "gui.blocklyText.max.highdo": "8 樂譜高音節do",
    "gui.blocklyText.max.brake": "9 剎車聲",
    "gui.blocklyText.max.rebound": "10 回彈的聲音",
    "gui.blocklyText.max.bullets": "11 兩次子彈的聲音",
    "gui.blocklyText.max.longecho": "12 拖長音的迴聲",
    "gui.blocklyText.max.playfulending": "14 俏皮的結束音",
    "gui.blocklyText.max.yo": "13 喲嚯",
    "gui.blocklyText.max.wo": "15 喔噢",
    "gui.blocklyText.max.robottalking": "16 機器人說話",
    "gui.blocklyText.sen0160.init": "設置Hx711重量傳感器校準值 [VALUE] 引腳 Dout [DOUT] SCK [SCK]",
    "gui.blocklyText.sen0160.readValue": "讀取Hx711重量傳感器(g) 引腳 Dout [DOUT] SCK [SCK]",
    "gui.blocklyText.knowflow.readORP": "讀取模擬引腳 [PIN] 氧化還原(ORP)傳感器 (mV)",
    "gui.blocklyText.knowflow.readEC": "讀取模擬引腳 [PIN] 電導率(EC)傳感器 (ms/cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "讀取模擬引腳 [PIN] 酸鹼度PH傳感器(V1)",
    "gui.blocklyText.knowflow.readECV2": "讀取模擬引腳 [PIN] 電導率(EC)傳感器 (ms/cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "讀取模擬引腳 [PIN] 酸鹼度PH傳感器(V2)",
    "gui.blocklyText.knowflow.readDO": "讀取模擬引腳 [PIN] 溶解氧DO傳感器 (mg/L)",
    "gui.blocklyText.peristalticPump.speedMove": "設置引腳 [PIN] 蠕動泵以 [SPEED]% 的速度 [DIR]",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] 引腳 [PIN] 的繼電器",
    "translate.translateBlock": "文字 [WORDS] 翻譯成 [LANGUAGE]",
    "translate.defaultTextToTranslate": "hello",
    "translate.viewerLanguage": "瀏覽者的語言",
    "gui.blocklyText.goble.readAxis": "讀取 GOBLE 搖桿 [AXIS] 軸",
    "gui.blocklyText.goble.buttonIsPressed": "GOBLE [BUTTON] 按鈕被按下？",
    "gui.blocklyText.eeprom.clear": "eeprom 清除",
    "gui.blocklyText.eeprom.read": "eeprom 讀取地址 [ADDR] 的數據",
    "gui.blocklyText.eeprom.write": "eeprom 給地址 [ADDR] 寫數據 [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom 獲取校驗值 從地址 [ADDRA] 到地址 [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom 獲取長度(byte)",
    "gui.blocklyText.tds.readAnalogPin": "讀取模擬引腳 [PIN] TDS 傳感器(ppm)",
    "gui.blocklyText.pythonsen0244.tdsInit": "TDS传感器[NAME]初始化 引脚[PIN]",
    "gui.blocklyText.pythonsen0244.readAnalogPin": "读取TDS传感器(ppm)[NAME]",
    "gui.blocklyText.steppermotor.initialJointXYParam": "双步進電機聯調 X軸 : [XSET] Y軸: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "单步進電機 [AXIS] 軸 每轉步數: [STEPS] 轉速(r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "单步進電機移動步數 [STEPS] 方向 [ROTATEDIR]",
    "gui.blocklyText.steppermotor.setJointSteps": "双步進電機聯調 X軸移動 [XSTEPS] 步 方向 [ROTATEDIRX] Y軸移動 [YSTEPS] 步 方向 [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] 程序開始",
    "gui.blocklyText.multithread.stopThread": "停止 [THREAD]",
    "gui.blocklyText.multithread.startThread": "啟動 [THREAD]",
    "gui.blocklyText.multithread.loop1": "子線程1",
    "gui.blocklyText.multithread.loop2": "子線程2",
    "gui.blocklyText.multithread.loop3": "子線程3",
    "gui.blocklyText.multithread.loop4": "子線程4",
    "gui.blocklyText.multithread.loop5": "子線程5",
    "gui.blocklyText.multithread.loop6": "子線程6",
    "gui.blocklyText.multithread.loop7": "子線程7",
    "gui.blocklyText.multithread.loop8": "子線程8",
    "gui.blocklyText.multithread.all": "全部子線程",
    "text2speech.speakAndWaitBlock": "唸出 [WORDS]",
    "text2speech.setVoiceBlock": "語音設為 [VOICE]",
    "text2speech.setLanguageBlock": "語言設為 [LANGUAGE]",
    "text2speech.setRequestSever": "切换为[SERVER]",
    "text2speech.setSever2Voice": "设置服务器2使用[VOICE]嗓音",
    "text2speech.setSever2Language": "设置服务器2的朗读语言[LANGUAGE]",
    "text2speech.setSever2Account": "设置服务器2账号[MODAL]",
    "text2speech.international": "服务器1(国际)",
    "text2speech.china": "服务器2(中国)",
    "text2speech.alto": "alto",
    "text2speech.tenor": "tenor",
    "text2speech.squeak": "尖細",
    "text2speech.giant": "低沉",
    "text2speech.kitten": "小貓",
    "text2speech.defaultTextToSpeak": "hello",
    "gui.blocklyText.text2speech.server2voice0": "度小美",
    "gui.blocklyText.text2speech.server2voice1": "度小宇",
    "gui.blocklyText.text2speech.server2voice3": "度逍遥",
    "gui.blocklyText.text2speech.server2voice4": "度丫丫",
    "gui.blocklyText.text2speech.server2voice106": "度博文",
    "gui.blocklyText.text2speech.server2voice110": "度小童",
    "gui.blocklyText.text2speech.server2voice111": "度小萌",
    "gui.blocklyText.text2speech.server2voice103": "度米多",
    "gui.blocklyText.text2speech.server2voice5": "度小娇",
    "gui.blocklyText.DFR0151.init": "DS1307 初始化",
    "gui.blocklyText.DFR0151.adjustTime": "調整DS1307時間為[YEAR]年[MONTH]月[DATE]日[HOUR]時[MINUTE]分[SECOND]秒",
    "gui.blocklyText.DFR0151.getTime": "獲取DS1307時間[INDEX]",
    "gui.blocklyText.DFR0151.Year": "年",
    "gui.blocklyText.DFR0151.month": "月",
    "gui.blocklyText.DFR0151.date": "日",
    "gui.blocklyText.DFR0151.hour": "時",
    "gui.blocklyText.DFR0151.minute": "分",
    "gui.blocklyText.DFR0151.second": "秒",
    "gui.blocklyText.DFR0151.week": "週",
    "gui.blocklyText.pythondfr0151.init": "DS1307 初始化 I2C地址0x68",
    "gui.blocklyText.pythondfr0151.adjustTime": "调整DS1307 时间为[YEAR]年[MONTH]月[DATE]日 周[WEEK] [HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.pythondfr0151.upTime": "获取一次时间数据",
    "gui.blocklyText.pythondfr0151.getTime": "从时间数据中解析[INDEX]",
    "gui.blocklyText.DFR0469.init": "SD2405 初始化",
    "gui.blocklyText.DFR0469.adjustTime": "調整SD2405時間為[YEAR]年[MONTH]月[DATE]日[HOUR]時[MINUTE]分[SECOND]秒",
    "gui.blocklyText.DFR0469.getTime": "獲取SD2405時間[INDEX]",
    "gui.blocklyText.pythondfr0469.init": "SD2405初始化 I2C地址0x32",
    "gui.blocklyText.pythondfr0469.adjustTime": "调整SD2405时间为[YEAR]年[MONTH]月[DATE]日 周[WEEK] [HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.pythondfr0469.upTime": "获取一次时间数据",
    "gui.blocklyText.pythondfr0469.getTime": "从时间数据中解析[INDEX]",
    "gui.blocklyText.DFR0126.setPin": "音頻分析模塊 設置引腳 #S[SPIN] #R[RPIN] 模擬引腳[APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "音頻分析模塊 讀取聲音頻率 頻段[FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "初始化NFC模塊接口 I2C",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "初始化NFC模塊接口 硬串口 Rx(绿)[RX] Tx(蓝)[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "初始化NFC模塊接口 [SERIALPORT] Rx(绿)[PINR] Tx(蓝)[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "初始化NFC模塊接口 [SERIALPORT] Rx(绿)[PINR] Tx(蓝)[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "NFC模塊 數據塊[DATABLOCK]第[BYTES]字節寫入[VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "讀取NFC模塊 數據塊[DATABLOCK]第[BYTES]字節(number)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "讀取NFC模塊 數據塊[DATABLOCK]所有數據(string)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "讀取NFC模塊 UID(srting)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "檢測到卡片 UID:[TEXT]？",
    "gui.blocklyText.DFR0126.scanSerialNfc": "檢測到卡片？",
    "gui.blocklyText.pythondfr0231.extensionName": "RFID/NFC",
    "gui.blocklyText.pythondfr0231.initSerialNfcIIC": "初始化RFID/NFC模块接口 I2C 地址0x24",
    "gui.blocklyText.pythondfr0231.writeSerialNfc": "RFID/NFC模块 数据块[DATABLOCK]第[BYTES]字节写入[VALUE]",
    "gui.blocklyText.pythondfr0231.readSerialNfcAll": "读取RFID/NFC模块 数据块[DATABLOCK]所有数据(string)",
    "gui.blocklyText.pythondfr0231.readSerialNfc": "读取RFID/NFC模块 数据块[DATABLOCK]第[BYTES]字节(number)",
    "gui.blocklyText.pythondfr0231.readSerialNfcUid": "读取RFID/NFC模块 UID(srting)",
    "gui.blocklyText.pythondfr0231.scanSerialNfcUid": "检测到卡片 UID:[TEXT]？",
    "gui.blocklyText.pythondfr0231.scanSerialNfc": "检测到卡片？",
    "gui.blocklyText.sen0245.init": "初始化VL53L0X激光測距傳感器",
    "gui.blocklyText.sen0245.setVL53L0X": "設置VL53L0X激光測距模塊[PRECISION]測量次數[VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "獲取VL53L0X激光測距值(mm)",
    "gui.blocklyText.pythonsen0245.extensionName": "激光测距",
    "gui.blocklyText.pythonsen0245.init": "初始化VL53L0X激光测距传感器I2C地址0x29",
    "gui.blocklyText.pythonsen0245.setVL53L0X": "设置VL53L0X激光测距模块[PRECISION]测量次数[VALUE]",
    "gui.blocklyText.pythonsen0245.readVL53L0X": "获取VL53L0X激光测距值(mm)",
    "gui.blocklyText.SEN0245.LOW": "低精度(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "高精度(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "單次測量",
    "gui.blocklyText.SEN0245.Continuous": "連續測量",
    "gui.blocklyText.sen0318.readValueBool": "读取CCS811空气质量是否有数据?",
    "gui.blocklyText.sen0318.readCO2TVCO": "获取空气质量 [TYPE] 的值",
    "gui.blocklyText.sen0318.CCS811init": "初始化CCS811数字空气质量传感器等待直到成功 获取数据时间:[TIME] I2C地址0x5A",
    "gui.blocklyText.sen0318.writeBaseline": "设置CCS811空气质量基数[BASE]",
    "gui.blocklyText.sen0318.CCS811SetBase": "设置基数:[BASE]",
    "gui.blocklyText.sen0318.250ms": "250ms",
    "gui.blocklyText.sen0318.1s": "1s",
    "gui.blocklyText.sen0318.10s": "10s",
    "gui.blocklyText.sen0318.60s": "60s",
    "gui.blocklyText.sen0318.close": "空闲",
    "gui.blocklyText.sen0318.CCS811ReadBaseline": "获取CCS811空气质量基数数值",
    "gui.blocklyText.pythonsen0318.readValueBool": "读取CCS811空气质量是否有数据?",
    "gui.blocklyText.pythonsen0318.readCO2TVCO": "获取空气质量 [TYPE] 的值",
    "gui.blocklyText.pythonsen0318.CCS811init": "初始化CCS811数字空气质量传感器 等待直到成功 获取数据时间:[TIME] I2C地址0x5A",
    "gui.blocklyText.pythonsen0318.writeBaseline": "设置CCS811空气质量基数[BASE]",
    "gui.blocklyText.pythonsen0318.CCS811ReadBaseline": "获取CCS811 空气质量基数数值",
    "gui.blocklyText.sen0315.PAJ7620U2init": "设置PAJ7620U2手势传感器 [MODE] 模式 I2C地址0x73",
    "gui.blocklyText.sen0315.high": "高速模式",
    "gui.blocklyText.sen0315.low": "低速模式",
    "gui.blocklyText.sen0315.PAJ7620U2GetValue": "PAJ7620U2手势传感器 获取一次数据",
    "gui.blocklyText.sen0315.PAJ7620U2IsTriggered": "PAJ7620U2手势传感器 [GESTURE] 被触发",
    "gui.blocklyText.sen0315.Right": "向右挥手",
    "gui.blocklyText.sen0315.Left": "向左挥手",
    "gui.blocklyText.sen0315.Up": "向上挥手",
    "gui.blocklyText.sen0315.Down": "向下挥手",
    "gui.blocklyText.sen0315.Forward": "靠近挥手",
    "gui.blocklyText.sen0315.Backward": "远离挥手",
    "gui.blocklyText.sen0315.Clockwise": "顺时针转动挥手",
    "gui.blocklyText.sen0315.Anti-Clockwise": "逆时针转动挥手",
    "gui.blocklyText.sen0315.Wave": "快速挥手",
    "gui.blocklyText.sen0315.WaveSlowlyDisorder": "低速混乱挥手",
    "gui.blocklyText.sen0315.WaveSlowlyLeftRight": "低速从左往右",
    "gui.blocklyText.sen0315.WaveSlowlyUpDown": "低速从上往下",
    "gui.blocklyText.sen0315.WaveSlowlyForwardBackward": "低速靠近再远离",
    "gui.blocklyText.pythonsen0315.extensionName": "手势识别",
    "gui.blocklyText.pythonsen0315.PAJ7620U2init": "设置PAJ7620U2手势传感器 [MODE] 模式直到成功 I2C地址0x73",
    "gui.blocklyText.pythonsen0315.PAJ7620U2GetValue": "PAJ7620U2手势传感器获取一次数据",
    "gui.blocklyText.pythonsen0315.PAJ7620U2IsTriggered": "PAJ7620U2手势传感器 [GESTURE] 被触发",
    "gui.blocklyText.sen0376.alcoholInit": "初始化电学酒精传感器直到成功 地址: [ADDR] 模式: [MODE]",
    "gui.blocklyText.sen0376.auto": "主动上传",
    "gui.blocklyText.sen0376.passive": "被动应答",
    "gui.blocklyText.sen0376.alcoholGetValue": "取[TIME]次酒精值的平均值(ppm)",
    "gui.blocklyText.sen0321.auto": "自动模式",
    "gui.blocklyText.sen0321.passive": "手动模式",
    "gui.blocklyText.sen0321.ozoneInit": "初始化I2C地址 [ADDR] 选择模式 [MODE]",
    "gui.blocklyText.sen0321.ozoneGetValue": "读取[TIME]次取一次平均值",
    "gui.blocklyText.sen0364.visibleSpectrumInit": "初始化光谱传感器 I2C地址0x39",
    "gui.blocklyText.sen0364.LEDswitch": "使能LED [SWITCH]",
    "gui.blocklyText.sen0364.LEDBrightness": "设置LED亮度为 [BRIGHTNESS]",
    "gui.blocklyText.sen0364.off": "关闭",
    "gui.blocklyText.sen0364.on": "打开",
    "gui.blocklyText.sen0364.spectrumGetValue": "读取传感器 [CHANNEL] 通道的值",
    "gui.blocklyText.sen0364.spectrumGetFreq": "光源的闪烁频率",
    "gui.blocklyText.sen0364.visibleSpectrumSet": "设置通道映射模式为 [MODE] (模式1可读取通道(F1-F4)，模式2可读取通道(F5-NIR))",
    "gui.blocklyText.kit0176.weightInit": "初始化重量传感器直到成功 地址:[ADDR]",
    "gui.blocklyText.kit0176.weightCal": "设置校准重量 [CAL]",
    "gui.blocklyText.kit0176.weightAutoCal": "设置重量传感器模块自动校准时的触发阈值 [CAL]",
    "gui.blocklyText.kit0176.weightStartUpCal": "启动自动校准",
    "gui.blocklyText.kit0176.IsSucesse": "校准是否成功?",
    "gui.blocklyText.kit0176.setCal": "设置/更新校准值",
    "gui.blocklyText.kit0176.getWeightValue": "获取物体重量",
    "gui.blocklyText.kit0176.getCalValue": "获取校准值",
    "gui.blocklyText.kit0176.setCalValue": "设置/更新校准值 [CAL] (手动模式)",
    "gui.blocklyText.sen0497.DHT20Init": "等待直到DHT20温湿度传感器模块I2C连接成功 I2C地址0x38",
    "gui.blocklyText.sen0497.DHT20Read": "读取DHT20 [INDEX]",
    "gui.blocklyText.pythonsen0497.DHT20Init": "等待直到DHT20温湿度传感器模块 I2C连接成功 I2C地址0x38",
    "gui.blocklyText.pythonsen0497.DHT20Read": "读取DHT20 [INDEX]",
    "gui.blocklyText.sen0497.temp": "温度(℃)",
    "gui.blocklyText.sen0497.humi": "湿度(%RH)",
    "gui.blocklyText.sen0514.ens160Init": "初始化ENS160 I2C地址 [ADDR]",
    "gui.blocklyText.sen0514.ens160SetMode": "ENS160设置模式[MODE]",
    "gui.blocklyText.pythonsen0514.ens160Init": "初始化ENS160 I2C地址 [ADDR]",
    "gui.blocklyText.pythonsen0514.ens160SetMode": "ENS160 设置模式[MODE]",
    "gui.blocklyText.sen0514.standard": "标准模式",
    "gui.blocklyText.sen0514.idle": "空闲模式",
    "gui.blocklyText.sen0514.sleep": "睡眠模式",
    "gui.blocklyText.sen0514.ens160CalComp": "ENS160校准和补偿温度 [TEMP]℃ 湿度[HUMI]%rh",
    "gui.blocklyText.sen0514.ens160GetValue": "ENS160读取 [VALUE]",
    "gui.blocklyText.pythonsen0514.ens160CalComp": "ENS160校准和补偿温度 [TEMP]℃ 湿度[HUMI]%rh",
    "gui.blocklyText.pythonsen0514.ens160GetValue": "ENS160读取 [VALUE]",
    "gui.blocklyText.sen0514.eco2": "CO2浓度",
    "gui.blocklyText.sen0514.tvoc": "TVOC浓度",
    "gui.blocklyText.sen0514.aqi": "空气质量指数",
    "gui.blocklyText.sen0514.status": "运行状态",
    "gui.blocklyText.dfr0991.rgbButtonInit": "初始化I2C RGB灯按钮模块等待直到成功[ADDR]",
    "gui.blocklyText.dfr0991.setRgbButtonColor1": "设置显示颜色 红[RED]绿[GREEN]蓝[BLUE]",
    "gui.blocklyText.dfr0991.setRgbButtonColor": "设置显示颜色[COLOR]",
    "gui.blocklyText.dfr0991.getRgbButtonStatus": "按键按下？",
    "gui.blocklyText.sen0518.max30102Init": "初始化MAX30102 I2C地址0x57",
    "gui.blocklyText.sen0518.initSerialMax30102": "初始化MAX30102模块接口 [SERIALPORT] Rx(绿)[PINR] Tx(蓝)[PINT]",
    "gui.blocklyText.sen0518.max30102StartCollect": "MAX30102开始收集",
    "gui.blocklyText.sen0518.max30102GetValue": "MAX30102读取[VALUE]",
    "gui.blocklyText.pythonsen0518.max30102Init": "初始化MAX30102 I2C地址0x57",
    "gui.blocklyText.pythonsen0518.max30102StartCollect": "MAX30102 开始收集",
    "gui.blocklyText.pythonsen0518.max30102GetValue": "MAX30102 读取[VALUE]",
    "gui.blocklyText.sen0518.spo2": "血氧%",
    "gui.blocklyText.sen0518.heartbeat": "心率Times/min",
    "gui.blocklyText.sen0518.temp": "传感器的温度℃",
    "gui.blocklyText.sen0359.fingerprintInit": "初始化指纹识别模块 I2C地址0x1F",
    "gui.blocklyText.sen0359.fingerprintSuccess": "等待直到指纹模块连接成功",
    "gui.blocklyText.sen0359.green": "绿色",
    "gui.blocklyText.sen0359.red": "红色",
    "gui.blocklyText.sen0359.yellow": "黄色",
    "gui.blocklyText.sen0359.blue": "蓝色",
    "gui.blocklyText.sen0359.blue1": "青色",
    "gui.blocklyText.sen0359.magenta": "洋红",
    "gui.blocklyText.sen0359.white": "白色",
    "gui.blocklyText.sen0359.breathe": "呼吸",
    "gui.blocklyText.sen0359.flash": "快闪",
    "gui.blocklyText.sen0359.noropen": "常开",
    "gui.blocklyText.sen0359.norclose": "常闭",
    "gui.blocklyText.sen0359.slowopen": "渐开",
    "gui.blocklyText.sen0359.slowclose": "渐关",
    "gui.blocklyText.sen0359.slowflash": "慢闪",
    "gui.blocklyText.sen0359.fingerprintSetLED": "设置LED灯 颜色[COLOR]模式 [MODE]次数 [TIMES]",
    "gui.blocklyText.sen0359.fingerTouch": "手指触碰?",
    "gui.blocklyText.sen0359.fingerprintCollection": "采集一次指纹是否成功? 超时时间 [TIME] 秒",
    "gui.blocklyText.sen0359.fingerprintMatch": "将采集的指纹与所有ID匹配",
    "gui.blocklyText.sen0359.fingerprintMatchID": "将采集的指纹与ID[ID]是否匹配？",
    "gui.blocklyText.sen0359.getFingerprintID": "获取一个可注册的ID",
    "gui.blocklyText.sen0359.fingerprintIDRegistrable": "ID [ID] 已注册?",
    "gui.blocklyText.sen0359.getRegisterNum": "获取注册用户数量",
    "gui.blocklyText.sen0359.saveID": "保存指纹到ID [ID] 是否成功?",
    "gui.blocklyText.sen0359.deleteID": "删除指纹ID [ID]",
    "gui.blocklyText.sen0359.deleteIDAll": "删除全部指纹",
    "gui.blocklyText.pythonsen0359.extensionName": "电容指纹",
    "gui.blocklyText.pythonsen0359.fingerprintInit": "初始化指纹识别模块 I2C地址0x1F",
    "gui.blocklyText.pythonsen0359.fingerprintSuccess": "等待直到指纹模块连接成功",
    "gui.blocklyText.pythonsen0359.fingerprintSetLED": "设置LED灯 颜色[COLOR]模式 [MODE]次数 [TIMES]",
    "gui.blocklyText.pythonsen0359.fingerTouch": "手指触碰?",
    "gui.blocklyText.pythonsen0359.fingerprintCollection": "采集一次指纹是否成功? 超时时间 [TIME] 秒",
    "gui.blocklyText.pythonsen0359.fingerprintMatch": "将采集的指纹与所有ID匹配",
    "gui.blocklyText.pythonsen0359.fingerprintMatchID": "将采集的指纹与ID[ID]是否匹配？",
    "gui.blocklyText.pythonsen0359.getFingerprintID": "获取一个可注册的ID",
    "gui.blocklyText.pythonsen0359.fingerprintIDRegistrable": "ID [ID] 已注册?",
    "gui.blocklyText.pythonsen0359.getRegisterNum": "获取注册用户数量",
    "gui.blocklyText.pythonsen0359.saveID": "保存指纹到ID [ID] 是否成功?",
    "gui.blocklyText.pythonsen0359.deleteID": "删除指纹ID [ID]",
    "gui.blocklyText.pythonsen0359.deleteIDAll": "删除全部指纹",
    "gui.blocklyText.serialMp3.initSerialMp3": "初始化串口MP3模塊接口 [SERIALPORT] Rx(綠) [RX] Tx(藍) [TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "設置串口MP3模塊播放模式為 [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "設置串口MP3模塊播放第[VALUE]首歌曲",
    "gui.blocklyText.serialMp3.setMP3Volume": "設置串口MP3模塊的音量為[VOLUME]%",
    "gui.blocklyText.serialMp3.play": "播放",
    "gui.blocklyText.serialMp3.pause": "暫停播放",
    "gui.blocklyText.serialMp3.end": "結束播放",
    "gui.blocklyText.serialMp3.prev": "上一曲",
    "gui.blocklyText.serialMp3.next": "下一曲",
    "gui.blocklyText.serialMp3.volumeUp": "音量加",
    "gui.blocklyText.serialMp3.volumeDown": "音量減",
    "gui.blocklyText.serialMp3.playlist": "播放曲目",
    "gui.blocklyText.serialMp3.insertTracks": "插播曲目",
    "gui.blocklyText.serialMp3.volume": "音量",
    "gui.blocklyText.servo360.setServo360": "設置引腳 [PIN] 以 [SPEED] % 的速度 [DIR]",
    "gui.blocklyText.servo360.CW": "正轉",
    "gui.blocklyText.servo360.CCW": "反轉",
    "gui.blocklyText.TCS34725.initTcs34725": "初始化TCS34725顏色傳感器",
    "gui.blocklyText.TCS34725.readTcs34725": "讀取TCS34725顏色傳感器[COLOR] [FORMAT]",
    "gui.blocklyText.pythonsen0212.extensionName": "颜色识别",
    "gui.blocklyText.pythonsen0212.initTcs34725": "初始化TCS34725颜色传感器 I2C地址0x29",
    "gui.blocklyText.pythonsen0212.updateTcs34725": "获取一次TCS34725数据",
    "gui.blocklyText.pythonsen0212.readTcs34725": "从数据中解析TCS34725颜色传感器 [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "轉GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "不轉GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "顯示文本[TEXT] [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "設置文字[DIR]滾動",
    "gui.blocklyText.regEmotion8_16.left": "向左",
    "gui.blocklyText.regEmotion8_16.right": "向右",
    "gui.blocklyText.regEmotion8_16.lightAll": "點亮所有點陣[COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "坐標 x:[XAXIS], y:[YXAXIS]顯示為[COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "清空點陣",
    "gui.mainHeader.err": "！錯誤提示：程序頭[%1]僅能同時使用一個，請刪除重複定義的模塊。",
    "gui.mainHeader.help": "！幫助提示：若需多個程序同時執行，請使用\"擴展\"中的\"多線程功能\"，詳見幫助文檔。",
    "gui.threadHeader.err1": "！錯誤提示：程序頭[%1] 不能同時使用，它們屬於不同的套件或主板。",
    "gui.threadHeader.err2": "！錯誤提示：事件頭[%1]僅能同時使用一個，請刪除重複定義的模塊。",
    "gui.blocklyText.mqtt.whenReceivedData": "當[TOPIC]接收到[MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT消息",
    "gui.blocklyText.wifi.wifiConnectByPwd": "WIFI 連接到 熱點:[ACCOUNT] 密碼:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "WIFI 連接成功？",
    "gui.blocklyText.wifi.wifiDisConnected": "WIFI 斷開連接",
    "gui.blocklyText.wifi.wifiGetLocalIP": "获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP 地址",
    "gui.blocklyText.wifi.getWiFiNetmask": "子网掩码",
    "gui.blocklyText.wifi.getGateway": "网关",
    "gui.blocklyText.wifi.wifiSetSoftAP": "WIFI 設置AP模式:[ACCOUNT2] 密碼:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "WIFI 獲取AP模式本機IP",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi 获取 [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "调试信息",
    "gui.blocklyText.wifi.whenDebugInfoError": "错误码",
    "gui.blocklyText.wifi.ping": "Wi-Fi ping [HOST]",
    "gui.blocklyText.wifi.scan": "Wi-Fi 扫描",
    "gui.blocklyText.pythonobloq.wifiGetLocalIP": "[NAME] 获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "NTP设置网络时间, 时区 [TIMEZONE] 授时服务器 [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "获取开发板当前 [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "西12区",
    "gui.blocklyText.ntp.UTCWest11_t": "西11区",
    "gui.blocklyText.ntp.UTCWest10_t": "西10区",
    "gui.blocklyText.ntp.UTCWest9_t": "西9区",
    "gui.blocklyText.ntp.UTCWest8_t": "西8区",
    "gui.blocklyText.ntp.UTCWest7_t": "西7区",
    "gui.blocklyText.ntp.UTCWest6_t": "西6区",
    "gui.blocklyText.ntp.UTCWest5_t": "西5区",
    "gui.blocklyText.ntp.UTCWest4_t": "西4区",
    "gui.blocklyText.ntp.UTCWest3_t": "西3区",
    "gui.blocklyText.ntp.UTCWest2_t": "西2区",
    "gui.blocklyText.ntp.UTCWest1_t": "西1区",
    "gui.blocklyText.ntp.UTCMiddle_t": "中时区",
    "gui.blocklyText.ntp.UTCEast1_t": "东1区",
    "gui.blocklyText.ntp.UTCEast2_t": "东2区",
    "gui.blocklyText.ntp.UTCEast3_t": "东3区",
    "gui.blocklyText.ntp.UTCEast4_t": "东4区",
    "gui.blocklyText.ntp.UTCEast5_t": "东5区",
    "gui.blocklyText.ntp.UTCEast6_t": "东6区",
    "gui.blocklyText.ntp.UTCEast7_t": "东7区",
    "gui.blocklyText.ntp.UTCEast8_t": "东8区",
    "gui.blocklyText.ntp.UTCEast9_t": "东9区",
    "gui.blocklyText.ntp.UTCEast10_t": "东10区",
    "gui.blocklyText.ntp.UTCEast11_t": "东11区",
    "gui.blocklyText.ntp.UTCEast12_t": "东12区",
    "gui.blocklyText.ntp.year": "年",
    "gui.blocklyText.ntp.month": "月",
    "gui.blocklyText.ntp.date": "日",
    "gui.blocklyText.ntp.hour": "时",
    "gui.blocklyText.ntp.minute": "分",
    "gui.blocklyText.ntp.second": "秒",
    "gui.blocklyText.ntp.week": "周",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "當UDP服務器收到 [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "設置UDP服務器端口 [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "UDP服務器發送消息 [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "當UDP客戶端收到 [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "設置UDP客戶端連接到服務器IP [IPADDR] 端口 [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "UPD客戶端發送消息 [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "廣播消息",
    "gui.blocklyText.udp.ClientRecvedMsg": "廣播消息",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] 中添加 key [KEY] value [VALUE]",
    "gui.blocklyText.http.requestHeader": "HTTP 請求體中添加 [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] 請求 URL [URL] 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.http.readMessage": "HTTP 讀取 [LINE] 內容",
    "gui.blocklyText.http.param": "參數",
    "gui.blocklyText.http.header": "頭部",
    "gui.blocklyText.http.oneline": "一行",
    "gui.blocklyText.http.allline": "全部",
    "gui.blocklyText.mqtt.mqttConnect": "MQTT 發起連接",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT 連接成功？",
    "gui.blocklyText.mqtt.acceptance.meaage": "當[TOPIC]接收到[MSG]",
    "gui.blocklyText.mqtt.mqttSubscribe": "MQTT 订阅[TOPIC]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT 發送消息[MSG]至[TOPIC]",
    "gui.blocklyText.mqtt.mqttSendSave": "MQTT 发送消息[MSG]至[TOPIC]并存数据库 (SIoT V2)",
    "gui.blocklyText.mqtt.acceptance.topic.meaage": "当接收到[TOPIC]的消息为[MSG]",
    "gui.blocklyText.mqtt.whenReceivedTopicData": "当接收到[TOPIC]的[MSG]",
    "gui.blocklyText.mqtt.mqttSendToTopic": "MQTT 发送消息[MSG]至[TOPIC]",
    "gui.blocklyText.mqtt.mqttSendToTopicSave": "MQTT 发送消息[MSG]至[TOPIC]并存数据库 (SIoT V2)",
    "gui.blocklyText.mqtt.initial": "MQTT 初始化參數 [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「訊飛語音」合成音頻 [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「訊飛語音」識別音頻 [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「訊飛語音」識別結果",
    "gui.blocklyText.iflytek.CommunicationText": "文字內容",
    "gui.blocklyText.iflytek.CommunicationTransfer": "轉存為音頻文件",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "待識別音頻文件",
    "gui.blocklyText.mqtt.mqttDisconnect": "MQTT 斷開連接",
    "gui.blocklyText.internet.internetGetpostInit": "网络请求初始化 [SETTING]",
    "gui.blocklyText.internet.initTypeAddr": "网络请求 设置请求类型 [POST] 地址 [ADDR] headers：[HEADERS] params：[URL] data：[DICT]",
    "gui.blocklyText.internet.internetPostFile": "Post提交文件 [SETTING]",
    "gui.blocklyText.internet.postFileAddrType": "POST提交文件 设置参数 网址 [ADDR] 文件路径 [PATH] MIME类型 [TYPE]",
    "gui.blocklyText.internet.postFileDict": "POST提交文件 添加字段为字典 [DICT] 并提交文件",
    "gui.blocklyText.internet.responseContent": "响应内容 形式[DATATYPE]",
    "gui.blocklyText.internet.responseAttributes": "响应属性 [ATTRIBUTES]",
    "gui.blocklyText.internet.createSocket": "创建 socket [SOKHANDEL] 地址 [AFINET] 类型 [SOCKTYPE]",
    "gui.blocklyText.internet.setSocket": "创建 socket [SOKHANDEL] 地址 [SOCKTYPE] 类型 [TYPENUM]",
    "gui.blocklyText.internet.closeSocket": "socket [SOKHANDEL] 关闭",
    "gui.blocklyText.internet.bindSocket": "socket [SOKHANDEL] 绑定地址 [SOCKADDR] 端口 [SOCKPORT]",
    "gui.blocklyText.internet.listenSocket": "监听 socket [SOKHANDEL] backlog 个数 [BACKLOGNUM]",
    "gui.blocklyText.internet.recvReturnTuple": "socket [SOKHANDEL] 接收链接请求并返回socket元组",
    "gui.blocklyText.internet.parseReturnIp": "解析 host [HOSTADDR] 端口 [SOCKPORT] 并返回 ip",
    "gui.blocklyText.internet.connectHost": "socket [SOKHANDEL] 连接 host [HOSTADDR] 端口 [SOCKPORT]",
    "gui.blocklyText.internet.sendReturnBytes": "socket [SOKHANDEL] 发送 [SOCKMSG] 并返回发送的字节数",
    "gui.blocklyText.internet.continueSendReturnBytes": "socket [SOKHANDEL] 连续发送 [SOCKMSG] 并返回发送的字节数",
    "gui.blocklyText.internet.sendToAddr": "socket [SOKHANDEL] 发送 [SOCKMSG] 到地址 [SOCKADDR]",
    "gui.blocklyText.internet.recvReturnRecvedObject": "socket [SOKHANDEL] 接收并返回接收到的数据对象 最大数据量为 [MAXNUM]",
    "gui.blocklyText.internet.recvReturnRecvedTuple": "socket [SOKHANDEL] 接收并返回接收到的数据元组 最大数据量为 [MAXNUM]",
    "gui.blocklyText.internet.setTimeout": "socket [SOKHANDEL] 设置超时时间为 [TIMEOUT] 秒",
    "gui.blocklyText.internet.setMode": "设置 socket [SOKHANDEL] 为 [SOCKMODE] 模式",
    "gui.blocklyText.internet.readReturnObject": "从 socket [SOKHANDEL] 读取 [READBYTE] 字节并返回字节对象",
    "gui.blocklyText.internet.text": "文本",
    "gui.blocklyText.internet.binary": "二进制",
    "gui.blocklyText.internet.dictionary": "字典",
    "gui.blocklyText.internet.block": "阻塞",
    "gui.blocklyText.internet.nonblock": "非阻塞",
    "gui.blocklyText.internet.initRequestsType": "请求类型",
    "gui.blocklyText.internet.initRequestsAddr": "请求地址",
    "gui.blocklyText.internet.initAddDict": "初始化字典",
    "gui.blocklyText.internet.postFileAddr": "请求网址",
    "gui.blocklyText.internet.postFilePath": "文件路径",
    "gui.blocklyText.internet.postFileMime": "MIME类型",
    "gui.blocklyText.internet.postFileAddDict": "初始化字典",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 初始化",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 讀取海拔高度(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 讀取大氣壓強(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 讀取溫度(℃)",
    "gui.blocklyText.sen0206.init": "初始化MLX90614地址为 [VALUE]",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 測量 [TYPE] [UINT]",
    "gui.blocklyText.sen0206.setEmissivityCorrectionCoefficient": "设置发射率校准系数 [VALUE]((范围0-1))",
    "gui.blocklyText.sen0206.setI2CAddress": "更改MLX90614地址为 [VALUE]",
    "gui.blocklyText.sen0206.object": "物體",
    "gui.blocklyText.sen0206.environment": "環境",
    "gui.blocklyText.sen0206.celsius": "攝氏溫度",
    "gui.blocklyText.sen0206.fahrenheit": "華氏溫度",
    "gui.blocklyText.sen0253.init": " 10DOF 初始化 'I2C_ADDR'引腳電平為 [LEVEL]",
    "gui.blocklyText.sen0253.reset": " 10DOF 傳感器復位",
    "gui.blocklyText.sen0253.power": " 10DOF 設置電源模式 [MODEL]",
    "gui.blocklyText.sen0253.getEuler": " 10DOF 獲取歐拉角 [EULER]",
    "gui.blocklyText.sen0253.getQua": " 10DOF 獲取四元數 [AXIS] 軸",
    "gui.blocklyText.sen0253.getAcc": " 10DOF 獲取加速度 [AXIS] 軸(mg)",
    "gui.blocklyText.sen0253.getMag": " 10DOF 獲取磁力計 [AXIS] 軸(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": " 10DOF 獲取線性加速度 [AXIS] 軸(mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": " 10DOF 獲取重力矢量 [AXIS] 軸(mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": " 10DOF 獲取角速度 [AXIS] 軸",
    "gui.blocklyText.sen0253.getBmp280": " 10DOF 獲取 [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "低電平",
    "gui.blocklyText.sen0253.HIGH": "高電平",
    "gui.blocklyText.sen0253.normal": "正常",
    "gui.blocklyText.sen0253.lowPower": "低功耗",
    "gui.blocklyText.sen0253.suspend": "掛起",
    "gui.blocklyText.sen0253.Roll": "水平滾角（°）",
    "gui.blocklyText.sen0253.Pitch": "俯仰角(°)",
    "gui.blocklyText.sen0253.Yaw": "航偏角(°)",
    "gui.blocklyText.sen0253.temperature": "溫度(℃)",
    "gui.blocklyText.sen0253.elevation": "海拔(m)",
    "gui.blocklyText.sen0253.airPressure": "氣壓(Pa)",
    "gui.blocklyText.sen0290.init": "閃電傳感器設置中斷引腳 [IRQPIN] 和I2C地址 [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "閃電傳感器初始化I2C完成？",
    "gui.blocklyText.sen0290.manualCal": "閃電傳感器設置電容 [CAPACITANCE] 模式 [MODE] 干擾 [DIST]",
    "gui.blocklyText.sen0290.indoors": "室內",
    "gui.blocklyText.sen0290.outdoors": "室外",
    "gui.blocklyText.sen0290.disturberDis": "干擾禁止",
    "gui.blocklyText.sen0290.disturberEn": "干擾使能",
    "gui.blocklyText.sen0290.errMsg": "錯誤信息",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "讀取閃電傳感器的狀態",
    "gui.blocklyText.sen0290.GetLightningDistKm": "閃電傳感器獲取閃電距離(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "閃電傳感器獲取閃電能量強度",
    "gui.blocklyText.sen0291.sen0291Init": "功率計 初始化I2C地址為 [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "功率計 讀取負載 [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "功率計校準 實際測量電流為 [EXT] mA",
    "gui.blocklyText.pythonsen0291.sen0291Init": "功率计[NAME] 初始化I2C地址为 [IICADDR]",
    "gui.blocklyText.pythonsen0291.sen0291Read": "功率计[NAME] 读取负载 [LOAD]",
    "gui.blocklyText.pythonsen0291.sen0291Calibration": "功率计[NAME] 校准实际测量电流为 [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "電壓 (mV)",
    "gui.blocklyText.sen0291.electric": "電流 (mA)",
    "gui.blocklyText.sen0291.power": "功率 (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "分流電壓 (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40的（00）",
    "gui.blocklyText.sen0291.iicaddr2": "的0x41（10）",
    "gui.blocklyText.sen0291.iicaddr3": "0×44（01）",
    "gui.blocklyText.sen0291.iicaddr4": "0×45（11）",
    "gui.blocklyText.weather.weatherGetWithCity": "省份 [COUNTRY] 城市 [CITY] 的 [WEATHER]",
    "gui.blocklyText.weather.temperaturHigh": "最高溫度(℃)",
    "gui.blocklyText.weather.temperaturLow": "最低溫度(℃)",
    "gui.blocklyText.weather.weather": "天氣",
    "gui.blocklyText.weather.weatherInit": "天气服务器初始化 [SETTING]",
    "gui.blocklyText.weather.serverAddr": "服务器地址",
    "gui.blocklyText.weather.appid": "账户ID(appid)",
    "gui.blocklyText.weather.appsecret": "账户密钥(appsecret)",
    "gui.blocklyText.weather.serverAddrTianqiAPI": "天气API网(www.tianqiapi.com)",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "設置服務器參數 [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "更新(update)標籤(tag) [TAG] 值(value) [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "讀取(get)標籤(tag) [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "刪除(delete)標籤(tag) [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "計數(count)",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "測試網絡連接",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "查詢(search) 起始編號(no) [NUMS] 變量個數(count) [NUMV] 標籤(tag) [TAG] 類型(type) [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "標籤",
    "gui.blocklyText.tinywebdb.value": "值",
    "gui.blocklyText.tinywebdb.both": "都",
    "gui.blocklyText.weather.anhui": "安徽",
    "gui.blocklyText.weather.aomen": "澳門",
    "gui.blocklyText.weather.beijing": "北京",
    "gui.blocklyText.weather.chongqing": "重慶",
    "gui.blocklyText.weather.fujian": "福建",
    "gui.blocklyText.weather.gansu": "甘肅",
    "gui.blocklyText.weather.guangdong": "廣東",
    "gui.blocklyText.weather.guangxi": "廣西",
    "gui.blocklyText.weather.guizhou": "貴州",
    "gui.blocklyText.weather.hainan": "海南",
    "gui.blocklyText.weather.hebei": "河北",
    "gui.blocklyText.weather.henan": "河南",
    "gui.blocklyText.weather.heilongjiang": "黑龍江",
    "gui.blocklyText.weather.hubei": "湖北",
    "gui.blocklyText.weather.hunan": "湖南",
    "gui.blocklyText.weather.jiangxi": "江西",
    "gui.blocklyText.weather.jiangsu": "江蘇",
    "gui.blocklyText.weather.jilin": "吉林",
    "gui.blocklyText.weather.liaoning": "遼寧",
    "gui.blocklyText.weather.neimenggu": "內蒙古",
    "gui.blocklyText.weather.ningxia": "寧夏",
    "gui.blocklyText.weather.qinghai": "青海",
    "gui.blocklyText.weather.sichuan": "四川",
    "gui.blocklyText.weather.shandong": "山東",
    "gui.blocklyText.weather.shanxi": "山西",
    "gui.blocklyText.weather.shan3xi": "陝西",
    "gui.blocklyText.weather.shanghai": "上海",
    "gui.blocklyText.weather.tianjin": "天津",
    "gui.blocklyText.weather.taiwan": "台灣",
    "gui.blocklyText.weather.xinjiang": "新疆",
    "gui.blocklyText.weather.xizang": "西藏",
    "gui.blocklyText.weather.xianggang": "香港",
    "gui.blocklyText.weather.yunnan": "雲南",
    "gui.blocklyText.weather.zhejiang": "浙江",
    "speech.whenIHear": "當收到語音 [PHRASE]",
    "speech.listenAndWait": "收聽並等待",
    "speech.getSpeech": "識別結果",
    "speech.setRecordTime": "設置每次聽 [TIME] 秒",
    "speech.stopRecord": "語音識別結束聽",
    "speech.controlSonogram": "[CONTROL] 聲波圖",
    "speech.setServer": "切換語音識別服務器為 [SERVER]",
    "speech.defaultWhenIHearValue": "開始吧",
    "speech.show": "顯示",
    "speech.hide": "隱藏",
    "speech.server1": "服務器1",
    "speech.server2": "服務器2",
    "gui.blocklyText.mp3.initMp3": "初始化DFPlayer MP3模塊接口 [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "設置DFPlayer MP3模塊音量為 [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "設置DFPlayer MP3模塊播放模式為 [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "設置DFPlayer MP3模塊播放第 [NUM] 首歌曲",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "設置 [PIN] 引腳DFPlayer MP3模塊的音量為 [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "設置 [PIN] 引腳DFPlayer MP3模塊的播放模式為 [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "設置 [PIN] 引腳DFPlayer MP3模塊播放第 [NUM] 首歌曲",
    "gui.blocklyText.tel0094.GPSInit": "GPS 初始化為 [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS 獲取地理位置 [LATLON](°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "GPS 獲取衛星時間 [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "經度",
    "gui.blocklyText.tel0094.LONGITUDE": "緯度",
    "gui.blocklyText.tel0094.YEAR": "年",
    "gui.blocklyText.tel0094.MONTH": "月",
    "gui.blocklyText.tel0094.DAY": "日",
    "gui.blocklyText.tel0094.HOUR": "時",
    "gui.blocklyText.tel0094.MINUTE": "分",
    "gui.blocklyText.tel0094.SECOND": "秒",
    "gui.blocklyText.tel0094.ALL": "全部",
    "gui.blocklyText.tel0094.GPSGet": "GPS 獲取 [GPS]",
    "gui.blocklyText.tel0094.getState": "狀態",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "緯度半球",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "經度半球",
    "gui.blocklyText.tel0094.getGroundSpeed": "地面速度",
    "gui.blocklyText.tel0094.getSatellitesCount": "衛星數量",
    "gui.blocklyText.tel0094.getAltitude": "海拔",
    "gui.blocklyText.tel0094.getPdop": "位置精度",
    "gui.blocklyText.tel0094.getHdop": "水平精度",
    "gui.blocklyText.tel0094.getVdop": "垂直精度",
    "gui.blocklyText.tel0094.getPositioningMode": "定位模式",
    "gui.blocklyText.microIoT.displayInLine": "OLED 顯示文字 [TEXT] 在第 [LINE] 行",
    "gui.blocklyText.microIoT.displayInXY": "OLED 顯示文字 [TEXT] 在坐標x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED 顯示圖片 [IMAGE] 在坐標x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED清屏",
    "gui.blocklyText.microIoT.pointDrawing": "OLED畫點X [X]和[Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED 設置線寬為 [LINEWIDTH] 個像素",
    "gui.blocklyText.microIoT.lineDrawing": "OLED 畫線 起點x [X1] y [Y1] 終點x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED 畫圓 [FILL] 圓心x [X] y [Y] 半徑 [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED 畫框 [FILL] 起點x [X] y [Y] 寬 [WIDTH] 高 [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB燈 設置亮度為 [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "RGB燈 全部熄滅",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "RGB燈 燈號 [NUM1] 到 [NUM2] 顯示顏色 [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "RGB燈 燈號 [NUM1] 到 [NUM2] 顯示漸變色 色調從 [COLOR1] 到 [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "RGB燈 循環移動 [NUM] 個像素",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "RGB燈 燈號 [NUM1] 到 [NUM2] 顯示顏色 [COLOR]",
    "gui.blocklyText.motorIot.all": "全部",
    "gui.blocklyText.microIot.servo": "舵機 [INDEX] 角度 [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "MQTT 初始化參數 [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT 發送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.microIot.mqttSendmsgSave": "MQTT 发送消息 [MSG] 至 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.microIot.MQTTMsg": "MQTT消息",
    "gui.blocklyText.microIoTCloud.MotorRun": "电机以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.microIoTCloud.MotorStop": "电机停止",
    "gui.blocklyText.microIoTCloud.setTime": "设置年 [Y] 月 [MON] 日 [D] 时 [H] 分 [MIN] 秒 [S]",
    "gui.blocklyText.microIoTCloud.setYear": "设置年 [Y]",
    "gui.blocklyText.microIoTCloud.setMonth": "设置月 [MON]",
    "gui.blocklyText.microIoTCloud.setDay": "设置日 [D]",
    "gui.blocklyText.microIoTCloud.setHour": "设置时 [H]",
    "gui.blocklyText.microIoTCloud.setMinute": "设置分 [MIN]",
    "gui.blocklyText.microIoTCloud.setSecond": "设置秒 [S]",
    "gui.blocklyText.microIoTCloud.getTime": "获取 [TIME]",
    "gui.blocklyText.microIoTCloud.sendmsgSaveToCloudPlatform": "发送消息 [MSG] 到 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.microTouch.whenBtnPress": "當按鍵 [KEY] 被按下時",
    "gui.blocklyText.microTouch.Any": "任意",
    "gui.blocklyText.microTouch.isButtonPressed": "按鍵 [KEY] 被按下?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "普通模式讀取按鍵",
    "gui.blocklyText.microTouch.readKeyMathMode": "數學模式讀取 [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "數字按鍵(num)",
    "gui.blocklyText.microTouch.Function": "功能按鍵(str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] 震動電機",
    "gui.blocklyText.microTouch.turnOn": "打開",
    "gui.blocklyText.microTouch.shutDown": "關閉",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "声音强度",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "自然光线",
    "gui.blocklyText.microNaturalScience.readColour": "读取 [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "红色值",
    "gui.blocklyText.microNaturalScience.G": "绿色值",
    "gui.blocklyText.microNaturalScience.B": "蓝色值",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "紫外线强度(μW/cm²)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "打开",
    "gui.blocklyText.microNaturalScience.off": "关闭",
    "gui.blocklyText.microNaturalScience.setTDSK": "设置TDS电极常数k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "获取TDS电极常数k",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS(ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "获取 [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "水温(℃)",
    "gui.blocklyText.microNaturalScience.Temperature": "环境温度(℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "环境湿度(%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "海拔高度(m)",
    "gui.blocklyText.microNaturalScience.Pressure": "大气压强(Pa)",
    "gui.blocklyText.microNaturalScienceV2.OLED": "OLED",
    "gui.blocklyText.microNaturalScienceV2.Motor": "Motor",
    "gui.blocklyText.microNaturalScienceV2.RGB": "RGB",
    "gui.blocklyText.microNaturalScienceV2.IoT": "IoT",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB燈 設置矩陣點 X [X] Y [Y] 顯示顏色 [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11不能放在中斷事件中，可能導致dht11讀數異",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22不能放在中斷事件中，可能導致dht22讀數異常",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5不能放在中斷事件中，可能導致pm2.5讀數異常",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5讀取數據，可能導致led點陣初始化失敗",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5讀取數據，可能導致led點陣顯示圖案失敗",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "mp3初始化不能放在中斷事件中，可能導致初始化失敗",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "mp3設置音量不能放在中斷事件中，可能導致設置音量失敗",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "mp3設置播放模式不能放在中斷事件中，可能導致設置播放模式失敗",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "mp3設置播放不能放在中斷事件中，可能導致設置播放失敗",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "串口mp3初始化不能放在中斷事件中，可能導致初始化失敗",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "串口mp3播放模式不能放在中斷事件中，可能導致設置播放模式失敗",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "串口mp3播放不能放在中斷事件中，可能導致播放失敗",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "串口mp3設置音量不能放在中斷事件中，可能導致設置音量失敗",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "obloq的mqtt初始化軟串口不能放在中斷事件中，可能導致軟串口通信失敗",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "obloq發送消息不能放在中斷事件中，可能導致發送失敗",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "obloq讀取mqtt消息不能放在中斷事件中，可能導致接收失敗",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "obloq的http的初始化軟串口不能放在中斷事件中，可能導致軟串口通信失敗",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "obloq http的get請求不能放在中斷事件中，可能導致請求失敗",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "obloq http的post請求不能放在中斷事件中，可能導致請求失敗",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "obloq http的put請求不能放在中斷事件中，可能導致請求失敗",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "紅外接收不能放在中斷事件中，可能導致接收失敗",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "紅外接收，可能導致led點陣初始化失敗",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "紅外接收，可能導致led點陣顯示圖案失敗",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "紅外發送不能放在中斷事件中，可能導致發送失敗",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB燈帶不能放在中斷事件中，可能導致初始化失敗",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB燈帶不能放在中斷事件中，可能導致設置引腳失敗",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB燈帶不能放在中斷事件中，可能導致引腳熄滅失敗",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB燈帶不能放在中斷事件中，可能導致顯示顏色失敗",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB燈帶不能放在中斷事件中，可能導致調整顏色失敗",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB燈帶不能放在中斷事件中，可能導致移動失敗",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB燈帶不能放在中斷事件中，可能導致循環移動失敗",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB燈帶不能放在中斷事件中，可能導致顯示柱狀圖失敗",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "led點陣不能放在中斷事件中，可能導致初始化失敗",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "led點陣不能放在中斷事件中，可能導致顯示圖案失敗",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "軟串口輸出不能放在中斷事件中，可能導致輸出內容異常",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "軟串口讀取數據不能放在中斷事件中，可能導致讀取內容異常",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "軟串口輸出數據與讀取數據同時使用，可能導致數據異常",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "軟串口讀取整數不能放在中斷事件中，可能導致讀取內容異常",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "軟串口輸出數據與讀取整數同時使用，可能導致讀取數據異常",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "當dht11和PM2.5分別位於兩個線程中時，如果pm2.5讀取太頻繁，dht11讀取可能會失敗，建議降低pm2.5讀取頻率",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "當dht11和ds18b20分別在兩個線程中，如果ds18b20讀取過於頻繁，可能導致dht11讀取失敗，建議降低ds18b20讀取頻率",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "當dht11和mp3初始化分別在兩個線程中，mp3初始化可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "當dht11和mp3設置音量分別在兩個線程中，mp3設置音量可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "當dht11和mp3設置模式分別在兩個線程中，mp3設置模式可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "當dht11和mp3播放分別在兩個線程中，mp3播放可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "當dht11和串口mp3初始化分別在兩個線程中，串口mp3初始化可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "當dht11和串口mp3控制模塊分別在兩個線程中，串口mp3控制模塊可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "當dht11和串口mp3播放分別在兩個線程中，串口mp3播放可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "當dht11和串口mp3設置音量分別在兩個線程中，串口mp3設置音量可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "當dht11和RGB初始化分別在兩個線程中，RGB初始化可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "當dht11和RGB設置亮度分別在兩個線程中，RGB設置亮度可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "當dht11和RGB熄滅分別在兩個線程中，RGB熄滅可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "當dht11和RGB顯示顏色分別在兩個線程中，RGB顯示顏色可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "當dht11和RGB顯示漸變色分別在兩個線程中，RGB顯示漸變色可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "當dht11和RGB移動像素分別在兩個線程中，RGB移動像素可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "當dht11和RGB循環移動像素分別在兩個線程中，RGB循環移動像素可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "當dht11和RGB顯示柱狀分別在兩個線程中，RGB顯示柱狀可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "當dht11和軟串口發送分別在兩個線程中，軟串口發送可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "當dht11和obloq mqtt初始化分別在兩個線程中，obloq mqtt初始化可能導致dht11讀取錯誤，建議等待初始化完成再讀取dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "當dht11和obloq mqtt發送消息分別在兩個線程中，obloq mqtt發送消息可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "當dht11和obloq http put請求分別在兩個線程中，可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "當dht11和obloq http初始化分別在兩個線程中，可能導致dht11讀取錯誤，建議等待初始化完成再讀取dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "當dht11和obloq http get請求分別在兩個線程中，可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "當dht11和obloq http post請求分別在兩個線程中，可能導致dht11讀取錯誤",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "當dht11和紅外發射分別在兩個線程中，可能導致dht11讀取錯誤，建議減小紅外發射頻率",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "當ds18b20和PM2.5分別在兩個線程中，可能導致PM2.5讀取錯誤，建議減ds18b20頻率",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "當ds18b20和obloq mqtt初始化分別在兩個線程中，可能導致mqtt初始化失敗，建議等待初始化完成再讀取ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "當ds18b20和obloq hqtt put請求分別在兩個線程中，可能導致put請求失敗，建議降低ds18b20讀取頻率",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "當ds18b20和obloq http初始化分別在兩個線程中，可能導致http初始化失敗，建議等待初始化完成再讀取ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "當ds18b20和obloq hqtt get請求分別在兩個線程中，可能導致get請求失敗，建議降低ds18b20讀取頻率",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "當ds18b20和obloq hqtt post請求分別在兩個線程中，可能導致post請求失敗，建議降低ds18b20讀取頻率",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "讀取ds18b20可能導致紅外接收不到數據，建議降低ds18b20讀取頻率",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "讀取ds18b20可能導致軟串口接收數據出錯，建議降低ds18b20讀取頻率",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "當PM2.5讀取和obloq mqtt初始化分別在兩個線程中，可能導致mqtt初始化失敗或PM2.5讀取出錯，建議避免mqtt初始化和PM2.5讀取同時執行",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "當PM2.5讀取和obloq mqtt發送消息分別在兩個線程中，可能導致PM2.5讀取出錯，建議降低PM2.5讀取讀取頻率",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "當PM2.5讀取和obloq http put請求分別在兩個線程中，可能導致put請求失敗或PM2.5讀取出錯，建議降低put請求頻率或者降低PM2.5讀取頻率",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "當PM2.5讀取和obloq http初始化分別在兩個線程中，可能導致http初始化失敗或PM2.5讀取出錯，建議避免http初始化和PM2.5讀取同時執行",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "當PM2.5讀取和obloq http get請求分別在兩個線程中，可能導致get請求失敗或PM2.5讀取出錯，建議降低get請求頻率或者降低PM2.5讀取頻率",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "當PM2.5讀取和obloq http post請求分別在兩個線程中，可能導致post請求失敗或PM2.5讀取出錯，建議降低post請求頻率或者降低PM2.5讀取頻率",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "當PM2.5讀取和MP3初始化分別在兩個線程中，可能致PM2.5讀取失敗，建議避免PM2.5讀取和MP3初始化同時執行",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "當PM2.5讀取和MP3設置音量分別在兩個線程中，可能致PM2.5讀取失敗，建議不要過於頻繁調節音量",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "當PM2.5讀取和MP3播放分別在兩個線程中，可能致PM2.5讀取失敗，建議不要過於頻繁設備播放模式",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "當PM2.5讀取和MP3播放分別在兩個線程中，可能致PM2.5讀取失敗，建議不要過於頻繁切換歌曲",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "當PM2.5讀取和串口MP3播放分別在兩個線程中，可能致PM2.5讀取失敗，建議不要過於頻繁設備播放模式",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "當PM2.5讀取和串口MP3播放分別在兩個線程中，可能致PM2.5讀取失敗，建議不要過於頻繁切換歌曲",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "當PM2.5讀取和串口MP3設置音量分別在兩個線程中，可能致PM2.5讀取失敗，建議不要過於頻繁調節音量",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "當PM2.5讀取可能導致紅外接收失敗，建議降低PM2.5讀取頻率",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "當PM2.5讀取和RGB初始化分別在兩個線程中，可能導致PM2.5讀取錯誤，建議避免PM2.5讀取和RGB初始化同時執行",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "當PM2.5讀取和RGB設置亮度分別在兩個線程中，可能導致PM2.5讀取錯誤，建議不要過於頻繁調節亮度",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "當PM2.5讀取和RGB熄滅分別在兩個線程中，可能導致PM2.5讀取錯誤，建議不要過於頻繁熄滅RGB",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "當PM2.5讀取和RGB顯示顏色分別在兩個線程中，可能導致PM2.5讀取錯誤，建議不要過於頻繁切換顏色",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "當PM2.5讀取和RGB顯示漸變色分別在兩個線程中，可能導致PM2.5讀取錯誤，建議不要過於頻繁切換漸變色",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "當PM2.5讀取和RGB移動像素分別在兩個線程中，可能導致PM2.5讀取錯誤，建議不要過於頻繁移動像素",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "使用Wi-Fi可能导致板载rgb显示颜色异常",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "udp使用了Wi-Fi，可能导致板载rgb显示颜色异常",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "使用Wi-Fi可能导致板载rgb设置三色异常",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "udp使用了Wi-Fi，可能导致板载rgb设置三色异常",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "使用Wi-Fi可能导致板载rgb设置亮度异常",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "udp使用了Wi-Fi，可能导致板载rgb设置亮度异常",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "使用Wi-Fi可能导致板载rgb获取亮度异常",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "udp使用了Wi-Fi，可能导致板载rgb获取亮度异常",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "使用wifi可能導致串口mp3初始化異常",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "udp使用了wifi，可能導致串口mp3初始化異常",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "使用wifi可能導致串口mp3播放異常",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "udp使用了wifi，可能導致串口mp3播放異常",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "使用wifi可能導致串口mp3播放曲目失敗",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "udp使用了wifi，可能導致串口mp3播放曲目失敗",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "使用wifi可能導致串口mp3設置音量失敗",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "udp使用了wifi，可能導致串口mp3設置音量失敗",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "使用wifi可能導致360舵機設置轉速異常",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "udp使用了wifi，可能導致360舵機設置轉速異常",
    "gui.blocklyText.prompt.telloesp32.emissionPinAndBit_telloWIFI": "Wi-Fi的使用，可能引起紅外發射模組異常",
    "gui.blocklyText.prompt.telloesp32.rgbnomal_rgbeffects": "使用呼吸燈和閃爍特效積木程式時不可再單獨設置LED燈，請移除其中一種積木",
    "gui.blocklyText.prompt.telloesp32.matrixnomal_matrixeffects": "使用點陣屏特效積木程式時不可再單獨設置點陣屏，請移除其中一種積木",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "當PM2.5讀取和RGB循環移動像素分別在兩個線程中，可能導致PM2.5讀取錯誤，建議不要過於頻繁執行循環移動像素",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "當PM2.5讀取和RGB顯示柱狀分別在兩個線程中，可能導致PM2.5讀取錯誤，建議不要過於頻繁執行RGB顯示柱狀",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "當PM2.5讀取和led點陣初始化分別在兩個線程中，可能導致led點陣初始化出錯，建議避免PM2.5讀取和led點陣初始化同時執行",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "當PM2.5讀取和led點陣顯示分別在兩個線程中，可能導致led點陣顯示出錯，建議降低PM2.5讀取頻率",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5讀取可能導致PM2.5讀取出錯，建議不要過於頻繁發送數據",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5讀取可能導致軟串口讀取失敗，建議不要過於頻繁讀取PM2.5",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3可能導致紅外接收不到數據，建議降低MP3使用頻率",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "當MP3和obloq mqtt初始化分別在兩個線程中，可能導致mqtt初始化失敗，建議避免mqtt初始化和MP3同時執行",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "當MP3和obloq http put請求分別在兩個線程中，可能導致put請求失敗，建議降低put請求頻率或者降低MP3使用頻率",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "當MP3和obloq http初始化分別在兩個線程中，可能導致http初始化失敗，建議避免http初始化和MP3同時執行",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "當MP3和obloq http get請求分別在兩個線程中，可能導致get請求失敗，建議降低get請求頻率或者降低MP3使用頻率",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "當MP3和obloq http post請求分別在兩個線程中，可能導致post請求失敗，建議降低post請求頻率或者降低MP3使用頻率",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3可能導致軟串口讀取失敗，建議不要過於頻繁使用MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "串口MP3可能導致紅外接收不到數據，建議降低串口MP3使用頻率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "當串口MP3和obloq mqtt初始化分別在兩個線程中，可能導致mqtt初始化失敗，建議避免mqtt初始化和串口MP3同時執行",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "當串口MP3和obloq http put請求分別在兩個線程中，可能導致put請求失敗，建議降低put請求頻率或者降低串口MP3使用頻率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "當串口MP3和obloq http初始化分別在兩個線程中，可能導致http初始化失敗，建議避免http初始化和串口MP3同時執行",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "當串口MP3和obloq http get請求分別在兩個線程中，可能導致get請求失敗，建議降低get請求頻率或者降低串口MP3使用頻率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "當串口MP3和obloq http post請求分別在兩個線程中，可能導致post請求失敗，建議降低post請求頻率或者降低串口MP3使用頻率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "串口MP3可能導致軟串口讀取失敗，建議不要過於頻繁使用串口MP3",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "紅外發射可能導致軟串口obloq接收出錯，建議降低紅外發射頻率",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "軟串口obloq可能導致紅外接收數據出錯，建議避免obloq頻繁發送消息",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "RGB燈帶初始化可能導致軟串口obloq接收消息出錯，建議避免頻繁使用RGB燈帶",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "燈帶設置亮度可能導致軟串口obloq接收消息出錯，建議避免頻繁調節亮度",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB燈帶熄滅可能導致軟串口obloq接收消息出錯，建議避免頻繁熄滅燈帶",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB燈帶顯可能導致軟串口obloq接收消息出錯，建議避免頻繁顯示顏色",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "RGB燈帶顯示漸變色可能導致軟串口obloq接收消息出錯，建議避免頻繁顯示漸變色",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB燈帶移動像素可能導致軟串口obloq接收消息出錯",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "RGB燈帶循環移動像素可能導致軟串口obloq接收消息出錯",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB燈帶顯示柱狀可能導致軟串口obloq接收消息出錯",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "當軟串口obloq和led點陣初始化分別在兩個線程中，可能導致led點陣初始化出錯，建議避免軟串口obloq和led點陣初始化同時執行",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "當軟串口obloq和led點陣顯示分別在兩個線程中，可能導致led點陣顯示出錯",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "軟串口發送可能導致軟串口obloq讀取出錯，建議不要過於頻繁發送數據",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "軟串口obloq發送可能導致軟串口讀取失敗，建議不要過於頻繁發送數據",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "紅外發射可能導致紅外接收不到數據，建議降低紅外發射頻率",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "當紅外發射可能導致軟串口讀取失敗，建議降低紅外發射頻率",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "RGB燈帶初始化可能導致紅外接收不到數據",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "RGB燈帶設置亮度可能導致紅外接收不到數據，建議避免RGB燈帶頻繁設置亮度",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "RGB燈帶熄滅可能導致紅外接收不到數據",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "RGB燈帶顯示顏色可能導致紅外接收不到數據，建議避免RGB燈帶頻繁顯示顏色",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "RGB燈帶顯示漸變色可能導致紅外接收不到數據，建議避免RGB燈帶頻繁顯示漸變色",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "RGB燈帶移動像素可能導致紅外接收不到數據，建議避免RGB燈帶頻繁移動像素",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "RGB燈帶循環移動像素可能導致紅外接收不到數據，建議避免RGB燈帶頻繁循環移動像素",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "RGB燈帶顯示柱狀可能導致紅外接收不到數據，建議避免RGB燈帶頻繁顯示柱狀",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "紅外接收可能導致led初始化失敗",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "紅外接收可能導致led顯示錯誤",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "軟串口發送可能導致紅外接收不到數據，建議避免軟串口頻繁發送",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB燈帶可能導致軟串口讀取失敗，建議降低RGB燈帶使用頻率",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "軟串口接收數據可能導致led點陣顯示出錯",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "軟串口發送可能導致軟串口接收數據出錯，建議避免發送數據和​​接收數據同時執行",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "串口MP3初始化可能導致紅外接收數據出錯，建議盡量避免同時執行",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "串口MP3設置播放模式可能導致紅外接收數據出錯，建議避免過於頻繁設置播放模式",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "串口MP3播放可能導致紅外接收數據出錯，建議避免過於頻繁切換歌曲",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "串口MP3設置音量可能導致紅外接收數據出錯，建議避免過於頻繁調節音量",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "max7219初始化可能導致紅外接收數據出錯，建議盡量避免同時執行",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "max7219顯示圖像可能導致紅外接收數據出錯，建議避免過於頻繁顯示圖像",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "串口MP3模塊可能導致引腳中斷不能觸發，建議避免過於頻繁使用串口MP3模塊",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "max7219顯示模塊可能導致引腳中斷不能觸發，建議避免過於頻繁使用max7219顯示",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "dht讀取可能導致紅外接收消息錯誤，建議降低dht讀取頻率",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "ds18b20讀取可能導致軟外接收消息錯誤，建議降低ds18b20讀取頻率",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5讀取可能導致軟外接收消息錯誤，建議降低PM2.5讀取頻率",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "mp3初始化讀取可能導致紅外接收消息錯誤，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "mp3設置音量可能導致紅外接收消息錯誤，建議避免過於頻繁調整音量",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "mp3設置模式可能導致紅外接收消息錯誤，建議避免過於頻繁設置模式",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "mp3播放可能導致紅外接收消息錯誤，建議避免過於頻繁切換音樂",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "串口mp3初始化讀取可能導致紅外接收消息錯誤，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "串口mp3播放可能導致紅外接收消息錯誤，建議避免過於頻繁切換音樂",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "串口mp3設置音量可能導致紅外接收消息錯誤，建議避免過於頻繁調整音量",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "紅外發射可能導致紅外接收消息錯誤，建議避免過於頻繁發送紅外",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "RGB燈帶初始化可能導致紅外接收消息錯誤，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "RGB燈帶設置亮度可能導致紅外接收消息錯誤，建議避免過於頻繁設置亮度",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB燈帶顯示可能導致紅外接收消息錯誤，建議避免過於頻繁執行RGB燈帶顯示",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "RGB燈帶顯示漸變色可能導致紅外接收消息錯誤，建議避免過於頻繁顯示漸變色",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "RGB燈帶移動像素可能導致紅外接收消息錯誤，建議避免過於頻繁移動像素",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "RGB燈帶循環移動像素可能導致紅外接收消息錯誤，建議避免過於頻繁執行循環移動像素",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB燈帶顯示柱狀可能導致紅外接收消息錯誤，建議避免過於頻繁執行顯示柱狀",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "紅外接收消息可能導致max7219初始化失敗",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "紅外接收消息可能導致max7219顯示出錯",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "軟串口打印可能導致紅外接收消息錯誤，建議避免過於頻繁執行軟串口打印",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "軟串口obloq mqtt初始化可能導致紅外接收數據出錯，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "軟串口obloq put請求可能導致紅外接收數據出錯，建議避免過於頻繁請求數據",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "軟串口obloq http初始化可能導致紅外接收數據出錯，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "軟串口obloq get請求可能導致紅外接收數據出錯，建議避免過於頻繁請求數據",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "軟串口obloq post請求可能導致紅外接收數據出錯，建議避免過於頻繁請求數據",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "RGB燈帶清除顯示可能導致軟紅外接收消息錯誤，建議避免過於頻繁清除顯示",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "ds18b20讀取可能導致軟串口接收消息錯誤，建議降低ds18b20讀取頻率",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5讀取可能導致軟串口接收消息錯誤，建議降低PM2.5讀取頻率",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "mp3初始化讀取可能導致軟串口接收消息錯誤，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "mp3設置音量可能導致軟串口接收消息錯誤，建議避免過於頻繁調整音量",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "mp3設置模式可能導致軟串口接收消息錯誤，建議避免過於頻繁設置模式",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "mp3播放可能導致軟串口接收消息錯誤，建議避免過於頻繁切換音樂",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "串口mp3初始化讀取可能導致軟串口接收消息錯誤，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "串口mp3設置模式可能導致軟串口接收消息錯誤，建議避免過於頻繁設置模式",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "串口mp3播放可能導致軟串口接收消息錯誤，建議避免過於頻繁切換音樂",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "串口mp3設置音量可能導致軟串口接收消息錯誤，建議避免過於頻繁調整音量",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "紅外發射可能導致軟串口接收消息錯誤，建議避免過於頻繁發送紅外",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "RGB燈帶初始化可能導致軟串口接收消息錯誤，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "RGB燈帶設置亮度可能導致軟串口接收消息錯誤，建議避免過於頻繁設置亮度",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "RGB燈帶清除顯示可能導致軟串口接收消息錯誤，建議避免過於頻繁清除顯示",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB燈帶顯示可能導致軟串口接收消息錯誤，建議避免過於頻繁執行RGB燈帶顯示",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB燈帶顯示漸變色可能導致軟串口接收消息錯誤，建議避免過於頻繁顯示漸變色",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "RGB燈帶移動像素可能導致軟串口接收消息錯誤，建議避免過於頻繁移動像素",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB燈帶循環移動像素可能導致軟串口接收消息錯誤，建議避免過於頻繁執行循環移動像素",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB燈帶顯示柱狀可能導致軟串口接收消息錯誤，建議避免過於頻繁執行顯示柱狀",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "軟串口接收消息可能導致max7219初始化失敗",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "軟串口接收消息可能導致max7219顯示出錯",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "軟串口打印可能導致軟串口接收消息錯誤，建議避免過於頻繁執行軟串口打印",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "dht讀取可能導致引腳中斷觸發失敗，建議降低dht讀取頻率",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "ds18b20讀取可能導致引腳中斷觸發失敗，建議降低ds18b20讀取頻率",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5讀取可能導致引腳中斷觸發失敗，建議降低PM2.5讀取頻率",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "mp3初始化讀取可能導致引腳中斷觸發失敗，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "mp3設置音量可能導致引腳中斷觸發失敗，建議避免過於頻繁調整音量",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "mp3設置模式可能導致引腳中斷觸發失敗，建議避免過於頻繁設置模式",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "mp3播放可能導致引腳中斷觸發失敗，建議避免過於頻繁切換音樂",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "串口mp3初始化讀取可能導致引腳中斷觸發失敗，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "串口mp3設置模式可能導致引腳中斷觸發失敗，建議避免過於頻繁設置模式",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "串口mp3播放可能導致引腳中斷觸發失敗，建議避免過於頻繁切換音樂",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "串口mp3設置音量可能導致引腳中斷觸發失敗，建議避免過於頻繁調整音量",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "紅外發射可能導致引腳中斷觸發失敗，建議避免過於頻繁發送紅外",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB燈帶初始化可能導致引腳中斷觸發失敗，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "RGB燈帶設置亮度可能導致引腳中斷觸發失敗，建議避免過於頻繁設置亮度",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB燈帶清除顯示可能導致引腳中斷觸發失敗，建議避免過於頻繁清除顯示",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB燈帶顯示可能導致引腳中斷觸發失敗，建議避免過於頻繁執行RGB燈帶顯示",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "RGB燈帶顯示漸變色可能導致引腳中斷觸發失敗，建議避免過於頻繁顯示漸變色",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB燈帶移動像素可能導致引腳中斷觸發失敗，建議避免過於頻繁移動像素",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB燈帶循環移動像素可能導致引腳中斷觸發失敗，建議避免過於頻繁執行循環移動像素",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB燈帶顯示柱狀可能導致引腳中斷觸發失敗，建議避免過於頻繁執行顯示柱狀",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "引腳中斷可能導致max7219初始化失敗",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "引腳中斷可能導致max7219顯示出錯",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "軟串口打印可能導致引腳中斷觸發失敗，建議避免過於頻繁執行軟串口打印",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "軟串口obloq mqtt初始化可能導致引腳中斷觸發失敗，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "軟串口obloq put請求可能導致引腳中斷觸發失敗，建議避免過於頻繁請求數據",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "軟串口obloq http初始化可能導致引腳中斷觸發失敗，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "軟串口obloq get請求可能導致引腳中斷觸發失敗，建議避免過於頻繁請求數據",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "軟串口obloq post請求可能導致引腳中斷觸發失敗，建議避免過於頻繁請求數據",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "dht讀取可能導致軟串口obloq接收消息錯誤，建議降低dht讀取頻率",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "ds18b20讀取可能導致軟串口obloq接收消息錯誤，建議降低ds18b20讀取頻率",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5讀取可能導致軟串口obloq接收消息錯誤，建議降低PM2.5讀取頻率",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "mp3初始化讀取可能導致軟串口obloq接收消息錯誤，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "mp3設置音量可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁調整音量",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "mp3設置模式可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁設置模式",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "mp3播放可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁切換音樂",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "串口mp3初始化讀取可能導致軟串口obloq接收消息錯誤，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "串口mp3設置模式可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁設置模式",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "串口mp3播放可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁切換音樂",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "串口mp3設置音量可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁調整音量",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "紅外發射可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁發送紅外",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "軟串口bloq發送消息可能導致紅外接收出錯，建議避免obloq過於頻繁發送消息",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB燈帶初始化可能導致軟串口obloq接收消息錯誤，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "RGB燈帶設置亮度可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁設置亮度",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "RGB燈帶清除顯示可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁清除顯示",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB燈帶顯示可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁執行RGB燈帶顯示",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB燈帶顯示漸變色可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁顯示漸變色",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "RGB燈帶移動像素可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁移動像素",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB燈帶循環移動像素可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁執行循環移動像素",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB燈帶顯示柱狀可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁執行顯示柱狀",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "軟串口obloq接收消息可能導致max7219初始化失敗",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "軟串口obloq接收消息可能導致max7219顯示出錯",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "軟串口打印可能導致軟串口obloq接收消息錯誤，建議避免過於頻繁執行軟串口打印",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "軟串口obloq發送數據可能導致軟串口接收數據出錯，建議避免obloq過於頻繁發送數據",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "軟串口obloq發送數據可能導致引腳中斷不能觸發，建議避免obloq過於頻繁發送數據",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "軟串口obloq mqtt初始化可能導致軟串口接收數據出錯，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "軟串口obloq put請求可能導致軟串口接收數據出錯，建議避免過於頻繁請求數據",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "軟串口obloq http初始化可能導致軟串口接收數據出錯，建議避免同時執行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "軟串口obloq get請求可能導致軟串口接收數據出錯，建議避免過於頻繁請求數據",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "軟串口obloq post請求可能導致軟串口接收數據出錯，建議避免過於頻繁請求數據",
    "gui.blocklyText.compatible.shapedBlock": "新版本變動點：由不帶變量的事件程序積木更換成自帶變量的事件程序積木。",
    "gui.blocklyText.compatible.shapedBlock.variable": "新版本變動點：由獨立程序積木更換成事件程序中的變量程序積木",
    "gui.blocklyText.compatible.booleanToRound": "新版本變動點：由條件程序積木變更為數值程序積木。",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "新版本變動點：程序積木中增加軟硬串口切換下拉選項。",
    "gui.blocklyText.compatible.mp3.deletePin": "新版本變動點：程序積木中刪除引腳下拉選項。",
    "gui.blocklyText.compatible.sen0206.addDropdown": "新版本變動點：程序積木中增加下拉選項。",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "新版本變動點：程序積木中增加引腳下拉選項。",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "新版本變動點：程序積木中修改串口下拉選項。",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "新版本變動點：由事件程序積木變更成條件程序積木。",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "新版本更新點：刪除了積木塊",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "新版本變動點：將方形下拉框修改為圓形下拉框。",
    "gui.blocklyText.compatible.shouldBlockInitFirst": "小模块存在修改，请重新设计程序",
    "gui.blocklyText.compatible.shapeChanged": "新版本变动点：积木形状发生改变，请重新拖动。",
    "gui.blocklyText.compatible.CCS811init": "新版本变动点：积木参数减少，拆分成2条积木，请重新拖动。",
    "gui.blocklyText.compatible.obloq": "新版本更新点：删除了积木块",
    "gui.blocklyText.pictureai.initToken": "初始化圖像AI[SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "從本地文件獲取圖片[IMAGE]",
    "gui.blocklyText.pictureai.cameraToggle": "切换至摄像头[CAMERA_LIST]",
    "gui.blocklyText.pictureai.getVideoImage": "從攝像頭畫面截取圖片",
    "gui.blocklyText.pictureai.webPicture": "從網址獲取圖片[TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH]保存視頻截圖到本地[TEXT]",
    "gui.blocklyText.pictureai.face": "識別圖中[TEXT]的人臉信息",
    "gui.blocklyText.pictureai.picture": "識別圖中[TEXT]包含的[SELECT]",
    "gui.blocklyText.pictureai.word": "識別圖中[TEXT]包含的[SELECT]",
    "gui.blocklyText.pictureai.gesture": "識別圖中[TEXT]包含的手勢",
    "gui.blocklyText.pictureai.TOPIC_W_A": "文字",
    "gui.blocklyText.pictureai.TOPIC_W_B": "數字",
    "gui.blocklyText.pictureai.TOPIC_W_C": "車牌號",
    "gui.blocklyText.pictureai.TOPIC_W_D": "手寫字",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "左腳踝",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "左耳",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "左手肘",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "左眼",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "左臀",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "左膝",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "左嘴角",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "左肩",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "左手腕",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "脖子",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "鼻子",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "右腳踝",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "右耳",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "右手肘",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "右眼",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "右臀",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "右膝",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "右嘴角",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "右肩",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "右手腕",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "頭部",
    "gui.blocklyText.pictureai.initUserToken": "切換至獨立賬戶[MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "識別圖中[TEXT]的人體關鍵點",
    "gui.blocklyText.pictureai.faceContrast": "對比圖片[TEXT]與圖片[TEXT2]中的人臉",
    "gui.blocklyText.pictureai.faceMerge": "融合圖片[TEXT]與圖片[TEXT2]中的人臉",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "圖像主體",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "通用物體和場景",
    "gui.blocklyText.pictureai.TOPIC_P_A": "植物",
    "gui.blocklyText.pictureai.TOPIC_P_B": "動物",
    "gui.blocklyText.pictureai.TOPIC_P_C": "花卉",
    "gui.blocklyText.pictureai.TOPIC_P_D": "果蔬",
    "gui.blocklyText.pictureai.TOPIC_P_E": "货币",
    "gui.blocklyText.pictureai.bodyPointSuccess": "人體關鍵點識別成功？",
    "gui.blocklyText.pictureai.bodyPointInfo": "人體關鍵點獲取結果 [BODY]的[XY]坐標",
    "gui.blocklyText.pictureai.noPower": "請檢查賬號是否已開啟該功能",
    "gui.blocklyText.pictureai.noPicture": "請先選擇圖片",
    "gui.blocklyText.pictureai.pictureError": "圖片格式不正確",
    "gui.blocklyText.pictureai.noVideo": "請確認攝像頭已正常連接無其它軟件佔用，並開啟攝像頭，或重啟軟件",
    "gui.blocklyText.pictureai.defaultTitleText": "视频窗口",
    "gui.blocklyText.pictureai.videoContainer": "使用[CON]顯示攝像頭畫面",
    "gui.blocklyText.pictureai.TOPIC_stage": "舞台",
    "gui.blocklyText.pictureai.TOPIC_popup": "彈窗",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "新版本變動點：有程序積木被刪除。",
    "gui.blocklyText.pictureai.gestureList": "手勢[TEXT]",
    "gui.blocklyText.pictureai.networkError": "網絡出錯，請檢查網絡連接",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "人臉識別成功？",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "人臉識別獲取結果[TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "人臉數目",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "年齡",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "顏值",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "性別",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "眼鏡",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "臉型",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "表情",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "人種可信度",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "距離左邊",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "距離頂部",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "寬度",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "高度",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "眼耳口鼻坐標",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "人臉遮擋概率",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "模糊度",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "人臉完整度",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "真人",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "卡通人物可能性",
    "gui.blocklyText.pictureai.TOPIC_FACE_emotion": "情緒",
    "gui.blocklyText.pictureai.TOPIC_FACE_mask": "戴口罩",
    "gui.blocklyText.pictureai.TOPIC_FACE_left_eye": "左眼張開度",
    "gui.blocklyText.pictureai.TOPIC_FACE_right_eye": "右眼張開度",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "打开",
    "gui.blocklyText.maqueenPlus.off": "关闭",
    "gui.blocklyText.maqueenPlus.Forward": "前进",
    "gui.blocklyText.maqueenPlus.Backward": "后退",
    "gui.blocklyText.maqueenPlus.left": "左侧",
    "gui.blocklyText.maqueenPlus.right": "右侧",
    "gui.blocklyText.maqueenPlus.all": "全部",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "电机 [DIR] 以 [SPEED] 的速度 [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "电机 [DIR] 停止",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "电机 [DIR] 速度补偿 [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "读取 [DIR] 电机速度",
    "gui.blocklyText.maqueenPlus.readMoterDir": "读取 [DIR] 电机方向 (0:停止 1:前进 2:后退)",
    "gui.blocklyText.maqueenPlus.clearMoterRunedCircle": "清除车轮圈数 [DIR]",
    "gui.blocklyText.maqueenPlus.getMoterRunedCircle": "获取车轮圈数 [DIR]",
    "gui.blocklyText.maqueenPlus.setServo": "舵机 [INDEX] 角度 [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "设置 [DIR] RGB灯显示 [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "读取巡线传感器 [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "读取巡线传感器 [INDEX] 灰度值",
    "gui.blocklyText.maqueenPlus.getVersion": "获取版本",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "读取超声波传感器 trig [TRIG] echo [ECHO] 单位 [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "当接收到 [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "红外数据",
    "gui.blocklyText.maqueenPlus.cm": "厘米",
    "gui.blocklyText.maqueenPlus.inch": "英寸",
    "gui.blocklyText.maqueenPlusV2.setMotorSpeedMove": "设置 [DIR] 方向 [SPEEDDIR] 速度 [SPEED]",
    "gui.blocklyText.maqueenPlusV2.setMotorStop": "设置 [DIR] 停止",
    "gui.blocklyText.maqueenPlusV2.readIR": "读红外线键值",
    "gui.blocklyText.maqueenPlusV2.left": "左侧电机",
    "gui.blocklyText.maqueenPlusV2.right": "右侧电机",
    "gui.blocklyText.maqueenPlusV2.all": "全部电机",
    "gui.blocklyText.maqueenPlusV2.leftLED": "左侧LED灯",
    "gui.blocklyText.maqueenPlusV2.rightLED": "右侧LED灯",
    "gui.blocklyText.maqueenPlusV2.allLED": "全部LED灯",
    "gui.blocklyText.maqueenPlusV2.on": "打开",
    "gui.blocklyText.maqueenPlusV2.off": "关闭",
    "gui.blocklyText.maqueenPlusV2.rotateForward": "前进",
    "gui.blocklyText.maqueenPlusV2.rotateBackward": "后退",
    "gui.blocklyText.maqueenPlusV2.readSensationSensor": "读取巡线传感器 [INDEX] 状态",
    "gui.blocklyText.maqueenPlusV2.setRgb": "设置 [DIR] [COLOR]",
    "gui.blocklyText.maqueenPlusV2.readSensationSensorYscaleValue": "读取巡线传感器 [INDEX] ADC数据",
    "gui.blocklyText.maqueenPlusV2.readUltrasonic": "读取超声波传感器 TRIG脚 [TRIG] ECHO脚 [ECHO] 单位：cm",
    "gui.blocklyText.maqueenPlusV2.getVersion": "读取版本号",
    "gui.blocklyText.esp32.audioRecognition": "识别 [TIME] 秒语音指令",
    "gui.blocklyText.esp32.audioRecordingFree": "录音 释放资源",
    "gui.blocklyText.esp32.audioRecordingStart": "录制音频 存储路径 [PATH] 时长 [TIME] 秒",
    "gui.blocklyText.esp32.audioRecordingInit": "录音 初始化",
    "gui.blocklyText.esp32.audioPlay": "音频 播放 [URL]",
    "gui.blocklyText.esp32.audioControl": "音频 [CONTROL]",
    "gui.blocklyText.esp32.AudioControl.A": "暂停",
    "gui.blocklyText.esp32.AudioControl.B": "继续播放",
    "gui.blocklyText.esp32.AudioControl.C": "停止",
    "gui.blocklyText.esp32.audioSetVolume": "音频 设置音量 [VOLUME]",
    "gui.blocklyText.esp32.audioFree": "音频 释放缓存",
    "gui.blocklyText.esp32.audioInit": "音频 初始化",
    "gui.blocklyText.esp32.audio": "音频",
    "gui.blocklyText.esp32.musicGetTempo": "获取当前节拍",
    "gui.blocklyText.esp32.musicSetTempo": "设置每一拍等同 [TICKS] 分音符，每分钟节拍数 [TEMPO]",
    "gui.blocklyText.esp32.musicSetPlaySpeed": "设置播放速度为 [SPEED] 倍",
    "gui.blocklyText.esp32.musicPlayMelodySet": "播放音乐 [MELODY] [WAIT] [LOOP] 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayMelody": "播放音乐 [MELODY] 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayNoteFrom": "播放音调 从 [MIN] 到 [AMX] 步长 [STEP] 时长 [DURATION] 毫秒 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayNote": "播放音调 [NOTE] 延时 [DELAY] 毫秒 引脚 [PIN]",
    "gui.blocklyText.esp32.musicTone": "音调 [NOTE]",
    "gui.blocklyText.esp32.musicPlayNoteContinuous": "播放连续音调 [NOTE] 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayList": "播放音符列表 [NOTELIST] [WAIT] [LOOP] 引脚 [PIN]",
    "gui.blocklyText.esp32.IsWait.A": "等待",
    "gui.blocklyText.esp32.IsWait.B": "不等待",
    "gui.blocklyText.esp32.IsLoop.A": "循环播放",
    "gui.blocklyText.esp32.IsLoop.B": "播放一次",
    "gui.blocklyText.esp32.getNote": "音符 [NOTE] 节拍 [BEAT]",
    "gui.blocklyText.esp32.musicPlay": "播放音符 [NOTE] 引脚 [PIN]",
    "gui.blocklyText.esp32.restore": "恢复音乐设置",
    "gui.blocklyText.esp32.stopPlay": "停止播放音乐 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPin_default": "默认",
    "gui.blocklyText.mpyUART.input": "從串口讀取文本 提示語句 [TITLE]",
    "gui.blocklyText.mpyUART.close": "關閉串口 [UART]",
    "gui.blocklyText.mpyUART.readLength": "串口 [UART] 讀取數據 長度 [LENGTH]",
    "gui.blocklyText.mpyUART.read": "串口 [UART] 讀取數據",
    "gui.blocklyText.mpyUART.readline": "串口 [UART] 讀取一行資料",
    "gui.blocklyText.mpyUART.any": "串口 [UART] 有可讀數據？",
    "gui.blocklyText.mpyUART.writeBytes": "串口 [UART] 寫入位元組陣列 [BYTES]",
    "gui.blocklyText.mpyUART.writeByte": "串口 [UART] 寫入單字節[BYTE]",
    "gui.blocklyText.mpyUART.init": "串口 [UART] 初始化 鮑率/串列傳輸速率 [BAUDRATE] tx [TXPIN] rx [RXPIN]",
    "gui.blocklyText.mpyUART.initTello": "串口 [UART] 初始化 rx [RXPIN] tx [TXPIN] 鮑率/串列傳輸速率 [BAUDRATE]",
    "gui.blocklyText.mpyUART.writeStringTello": "串口 [UART] 寫入文本 [TEXT] [ENDSYMBOL]",
    "gui.blocklyText.mpyUART.anyTello": "串口 [UART] 有可讀數據？",
    "gui.blocklyText.mpyUART.readTello": "串口 [UART] 讀取數據",
    "gui.blocklyText.mpyUART.closeTello": "關閉串口 [UART]",
    "gui.blocklyText.mpyUART.writeString": "串口 [UART] 寫入文本 [TEXT] [ENDSYMBOL]",
    "gui.blocklyText.mpyUART.no": "不換行",
    "gui.blocklyText.mpyUART.auto": "自動換行",
    "gui.blocklyText.mpyI2C.scan": "I2C 扫描结果",
    "gui.blocklyText.mpyI2C.read": "I2C 地址 [ADDRESS] 读取bytes数量 [BYTES]",
    "gui.blocklyText.mpyI2C.write": "I2C 地址 [ADDRESS] 写入 [DATA]",
    "gui.blocklyText.mpyI2C.init": "初始化I2C SCL [SCLPIN] SDA [SDAPIN] 波特率 [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoInitBoard": "初始化板载I2C 频率 [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoUniversal": "初始化通用I2C SCL [SCLPIN] SDA [SDAPIN] 频率 [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoWrite": "[OBJ] 地址 [ADDRESS] 写入 [DATA]",
    "gui.blocklyText.mpyI2C.maixduinoRead": "[OBJ] 地址 [ADDRESS] 读取bytes数量 [BYTES]",
    "gui.blocklyText.mpyI2C.maixduinoScan": "[OBJ] 扫描结果",
    "gui.blocklyText.esp32.readPinLevel": "[PIN] 外部 [LEVEL] 持续时间",
    "gui.blocklyText.esp32.noPull": "悬空",
    "gui.blocklyText.esp32.pullDown": "下拉",
    "gui.blocklyText.esp32.pullUp": "上拉",
    "gui.blocklyText.esp32.setPinMode": "设置引脚 [PIN] 为 [MODE] 模式 [PULL] 并设置为 [LEVEL]",
    "gui.blocklyText.esp32.leakyOutput": "开漏输出",
    "gui.blocklyText.mpyServo.setServo": "设置舵机 [PIN] 角度为 [ANGLE] 脉宽从 [MIN] 到 [MAX] 最大角度 [ANGLEMAX]",
    "gui.blocklyText.servo.init": "设置引脚 [PIN] 舵机角度为 [ANGLE] 度 定时器 [TIMER]",
    "gui.blocklyText.mpyServo.setAngle": "设置舵机 [PIN] 角度为 [ANGLE]",
    "gui.blocklyText.esp32.shaked": "被摇晃",
    "gui.blocklyText.esp32.thrown": "被抛起",
    "gui.blocklyText.esp32.tilt_forward": "向前倾斜",
    "gui.blocklyText.esp32.tilt_back": "向后倾斜",
    "gui.blocklyText.esp32.tilt_right": "向右倾斜",
    "gui.blocklyText.esp32.tilt_left": "向左倾斜",
    "gui.blocklyText.esp32.tilt_none": "平放",
    "gui.blocklyText.esp32.attitudeEvent": "当掌控板 [ATTITUDE]",
    "gui.blocklyText.esp32.to_high": "↗ 从低到高",
    "gui.blocklyText.esp32.to_low": "↘ 从高到低",
    "gui.blocklyText.esp32.pinLevelEvent": "当引脚 [PIN] 电平 [TRANSITION]",
    "gui.blocklyText.mpyTimer.setEvent": "设置定时器 [INDEX] [MODE] 周期 [PERIOD] 毫秒",
    "gui.blocklyText.mpyTimer.repeated": "重复执行",
    "gui.blocklyText.mpyTimer.delayed": "延时执行",
    "gui.blocklyText.mpyTimer.conditionEvent": "设置定时器 [INDEX] :当 [CONDITION]",
    "gui.blocklyText.mpyTimer.getCount": "定时器 [INDEX] 的计数值",
    "gui.blocklyText.mpyTimer.clearEvent": "清除 定时器/事件 [INDEX]",
    "gui.blocklyText.mpyDictionary.init": "初始化字典 [CONTENT]",
    "gui.blocklyText.mpyDictionary.getValue": "字典 [CONTENT] 键 [KEY] 的值",
    "gui.blocklyText.mpyDictionary.setValue": "字典 [CONTENT] 键 [KEY] 的值设置为 [VALUE]",
    "gui.blocklyText.mpyDictionary.deleteKey": "字典 [CONTENT] 删除键 [KEY]",
    "gui.blocklyText.mpyDictionary.clear": "清空字典 [CONTENT]",
    "gui.blocklyText.mpyDictionary.isInclude": "字典 [CONTENT] 包含键 [KEY]？",
    "gui.blocklyText.mpyDictionary.getLength": "字典 [CONTENT] 长度",
    "gui.blocklyText.mpyDictionary.list": "字典 [CONTENT] [TYPE] 的列表",
    "gui.blocklyText.mpyDictionary.TypeIndex.A": "键",
    "gui.blocklyText.mpyDictionary.TypeIndex.B": "值",
    "gui.blocklyText.mpyList.join": "列表 [LIST] 使用分隔符 [SYMBOL] 组合文本",
    "gui.blocklyText.mpyList.split": "文本 [TEXT] 使用分隔符 [SYMBOL] 制作列表",
    "gui.blocklyText.mpyList.init": "初始化列表 [CONTENT]",
    "gui.blocklyText.mpyList.clear": "清空列表 [CONTENT]",
    "gui.blocklyText.mpyList.getLength": "列表 [CONTENT] 的长度",
    "gui.blocklyText.mpyList.isEmpty": "列表 [CONTENT] 是空的？",
    "gui.blocklyText.mpyList.getValue": "列表 [CONTENT] 索引 [INDEX] 的值",
    "gui.blocklyText.mpyList.getValueRange": "返回列表 [CONTENT] 取 [TYPE1] [NUM1] 项到 [TYPE2] [NUM2] 项",
    "gui.blocklyText.mpyList.insert": "列表 [CONTENT] 在索引 [INDEX] 处插入 [VALUE]",
    "gui.blocklyText.mpyList.setValue": "列表 [CONTENT] 设置索引 [INDEX] 的值为 [VALUE]",
    "gui.blocklyText.mpyList.delete": "列表 [CONTENT] 删除索引 [INDEX] 的值",
    "gui.blocklyText.mpyList.append": "列表 [CONTENT] 将 [APPEND] 添加到末尾",
    "gui.blocklyText.mpyList.extend": "列表 [LIST1] 追加列表 [LIST2]",
    "gui.blocklyText.mpyList.findIndex": "列表 [CONTENT] 查找 [VALUE] 的索引",
    "gui.blocklyText.mpyList.sort": "列表 [LIST] 按 [TYPE] 排序为 [MODE]",
    "gui.blocklyText.mpyList.SortModeIndex.A": "升序",
    "gui.blocklyText.mpyList.SortModeIndex.B": "降序",
    "gui.blocklyText.mpyList.SortTypeIndex.A": "数字",
    "gui.blocklyText.mpyList.SortTypeIndex.B": "字母",
    "gui.blocklyText.mpyList.SortTypeIndex.C": "字母，忽略大小写",
    "gui.blocklyText.mpySet.init": "初始化集合 [CONTENT]",
    "gui.blocklyText.mpySet.update": "集合 [CONTENT1] 更新为与集合 [CONTENT2] 的 [TYPE]",
    "gui.blocklyText.mpySet.TypeIndex.A": "交集",
    "gui.blocklyText.mpySet.TypeIndex.B": "并集",
    "gui.blocklyText.mpySet.TypeIndex.C": "差集",
    "gui.blocklyText.mpySet.addValue": "集合 [CONTENT] 添加 [VALUE]",
    "gui.blocklyText.mpySet.isSubsetSuperset": "集合 [CONTENT1] 为集合 [CONTENT2] 的 [TYPE]？",
    "gui.blocklyText.mpySet.SubsetSupersetType.A": "子集",
    "gui.blocklyText.mpySet.SubsetSupersetType.B": "超集",
    "gui.blocklyText.mpySet.intersectionUnionSetDifference": "取 [TYPE] 集合 [CONTENT1] 集合 [CONTENT2]",
    "gui.blocklyText.mpySet.length": "集合 [CONTENT] 长度",
    "gui.blocklyText.mpySet.pop": "返回随机项 并从集合 [CONTENT] 移除",
    "gui.blocklyText.mpyTuple.init": "初始化元组 [CONTENT]",
    "gui.blocklyText.mpyTuple.minMaxLength": "元组 [CONTENT] [TYPE]",
    "gui.blocklyText.mpyTuple.TypeIndex.A": "最小值",
    "gui.blocklyText.mpyTuple.TypeIndex.B": "最大值",
    "gui.blocklyText.mpyTuple.TypeIndex.C": "长度",
    "gui.blocklyText.mpyTuple.isInclude": "元组 [CONTENT] 包含 [STRING]？",
    "gui.blocklyText.mpyTuple.getValueRange": "返回元组 [CONTENT] 取 [TYPE1] [NUM1] 项到 [TYPE2] [NUM2] 项",
    "gui.blocklyText.mpyTuple.IndexType.A": "第",
    "gui.blocklyText.mpyTuple.IndexType.B": "倒数第",
    "gui.blocklyText.mpyTuple.listToTulpe": "列表 [LIST] 转元组",
    "gui.blocklyText.mpyTuple.getValue": "元组 [CONTENT] 索引 [INDEX] 的值",
    "gui.blocklyText.mpyTuple.tupleToList": "元组 [CONTENT] 转变为列表",
    "gui.blocklyText.esp32.DisplayTypeMenu.A": "普通",
    "gui.blocklyText.esp32.DisplayTypeMenu.B": "反转",
    "gui.blocklyText.esp32.displayInnerImageInXY": "显示内置图像 [IMAGE] 到 x:[X] y:[Y] 模式 [TYPE]",
    "gui.blocklyText.esp32.displayFileSystemImageInXY": "从文件系统加载图像 [IMAGE] 显示到 x:[X] y:[Y] 模式 [TYPE]",
    "gui.blocklyText.weather.GetWeatherWithCityName": "获取[CITY]的[WEATHER]",
    "gui.blocklyText.pictureai.createFaceClass": "创建人脸库 [NAME]",
    "gui.blocklyText.pictureai.pictureaiAddFaceToClass": "命名识别结果为[RES]并添加至人脸库 [CLASSNAME]",
    "gui.blocklyText.pictureai.pictureaiSelectResultsFromClass": "在人脸库[CLASSNAME]中搜索识别结果可信度>=[NUMBER]",
    "gui.blocklyText.pictureai.pictureaiWhenSearchName": "当搜索到名字[NAME]时",
    "gui.blocklyText.pictureai.pictureaiSearchedName": "搜索结果名字",
    "gui.blocklyText.pictureai.pictureaiSelectResultReliability": "搜索结果可信度",
    "gui.blocklyText.pictureai.pleaseUseSelefAccount": "人脸库功能须切换至独立账户",
    "gui.blocklyText.pictureai.addfaceclasserror": "新建人脸库出错",
    "gui.blocklyText.pictureai.faceclassnameerror": "人脸库名称由数字、字母、下划线组成",
    "gui.blocklyText.pictureai.faceclassnameexist": "该人脸库名称已存在",
    "gui.blocklyText.pictureai.addfaceerror": "添加人脸出错",
    "gui.blocklyText.pictureai.selectfaceclasserror": "人脸库搜索出错",
    "gui.blocklyText.pictureai.notfoundmatch": "未找到匹配的人脸",
    "gui.blocklyText.pictureai.facenameerror": "人脸名称由数字、字母、下划线组成",
    "boost.color.any": "任何颜色",
    "boost.color.black": "黑色",
    "boost.color.blue": "蓝色",
    "boost.color.green": "绿色",
    "boost.color.red": "黄色",
    "boost.color.white": "白色",
    "boost.color.yellow": "黄色",
    "boost.getMotorPosition": "马达 [MOTOR_REPORTER_ID] 位置",
    "boost.getTiltAngle": "向[TILT_DIRECTION]倾角",
    "boost.motorDirection.backward": "逆时针",
    "boost.motorDirection.forward": "顺时针",
    "boost.motorDirection.reverse": "反转",
    "boost.motorOff": "关闭马达 [MOTOR_ID]",
    "boost.motorOn": "开启马达 [MOTOR_ID]",
    "boost.motorOnFor": "使马达 [MOTOR_ID]转动 [DURATION] 秒",
    "boost.motorOnForRotation": "使马达 [MOTOR_ID]转动 [ROTATION] 圈",
    "boost.seeingColor": "看到 [COLOR]积木 ?",
    "boost.setLightHue": "将灯光颜色设为[HUE]",
    "boost.setMotorDirection": "将马达[MOTOR_ID] 方向设为 [MOTOR_DIRECTION]",
    "boost.setMotorPower": "将马达 [MOTOR_ID] 速度设为 [POWER]%",
    "boost.tiltDirection.any": "任意方向",
    "boost.tiltDirection.down": "下",
    "boost.tiltDirection.left": "左",
    "boost.tiltDirection.right": "右",
    "boost.tiltDirection.up": "上",
    "boost.whenColor": "当看到[COLOR]积木",
    "boost.whenTilted": "当向[TILT_DIRECTION_ANY]倾斜",
    "ev3.beepNote": "鸣笛[NOTE][TIME]秒",
    "ev3.buttonPressed": "按下按钮[PORT]？",
    "ev3.getBrightness": "亮度",
    "ev3.getDistance": "距离",
    "ev3.getMotorPosition": "马达[PORT]的位置",
    "ev3.motorSetPower": "马达[PORT]功率设为[POWER]%",
    "ev3.motorTurnClockwise": "马达[PORT]正转[TIME]秒",
    "ev3.motorTurnCounterClockwise": "马达[PORT]反转[TIME]秒",
    "ev3.whenBrightnessLessThan": "当亮度 < [DISTANCE]",
    "ev3.whenButtonPressed": "当按下按钮[PORT]",
    "ev3.whenDistanceLessThan": "当距离 < [DISTANCE]",
    "wedo2.getDistance": "距离",
    "wedo2.getTiltAngle": "向[TILT_DIRECTION]倾角",
    "wedo2.isTilted": "向[TILT_DIRECTION_ANY]倾斜？",
    "wedo2.motorDirection.backward": "逆时针",
    "wedo2.motorDirection.forward": "顺时针",
    "wedo2.motorDirection.reverse": "反转",
    "wedo2.motorId.a": "马达A",
    "wedo2.motorId.all": "所有马达",
    "wedo2.motorId.b": "马达B",
    "wedo2.motorId.default": "马达",
    "wedo2.motorOff": "关闭[MOTOR_ID]",
    "wedo2.motorOn": "开启[MOTOR_ID]",
    "wedo2.motorOnFor": "开启[MOTOR_ID][DURATION]秒",
    "wedo2.playNoteFor": "演奏音符[NOTE][DURATION]秒",
    "wedo2.setLightHue": "将灯光颜色设为[HUE]",
    "wedo2.setMotorDirection": "将[MOTOR_ID]转向设为[MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "将[MOTOR_ID]功率设为[POWER]",
    "wedo2.tiltDirection.any": "任意方向",
    "wedo2.tiltDirection.down": "下",
    "wedo2.tiltDirection.left": "左",
    "wedo2.tiltDirection.right": "右",
    "wedo2.tiltDirection.up": "上",
    "wedo2.whenDistance": "当距离[OP][REFERENCE]",
    "wedo2.whenTilted": "当向[TILT_DIRECTION_ANY]倾斜",
    "gui.blocklyText.video.notfound": "无设备",
    "gui.blocklyText.machinelearning.drawResults": "[TOGGLE]绘制识别结果",
    "gui.blocklyText.machinelearning.initKNNImageClass": "初始化KNN分类器",
    "gui.blocklyText.machinelearning.imageClassificationFromCamera": "KNN将摄像头画面分类为[TEXT]",
    "gui.blocklyText.machinelearning.imageClassificationFromDir": "KNN将本地文件夹图片[DIR]分类为[TEXT]",
    "gui.blocklyText.machinelearning.startKNNTrain": "KNN开始分类训练",
    "gui.blocklyText.machinelearning.predictImageClass": "KNN[TOGGLE]识别摄像头画面分类",
    "gui.blocklyText.machinelearning.getImageClassification": "KNN识别分类结果",
    "gui.blocklyText.machinelearning.getImageClassificationReliability": "KNN识别可信度",
    "gui.blocklyText.machinelearning.clearImageClassificationLabel": "KNN清除标签[LABEL]",
    "gui.blocklyText.machinelearning.clearImageClassificationModel": "KNN清除分类模型数据",
    "gui.blocklyText.machinelearning.loadImageClassificationModel": "KNN从[PATH]加载电脑端分类模型",
    "gui.blocklyText.machinelearning.exportImageClassificationModel": "KNN保存分类模型",
    "gui.blocklyText.machinelearning.traceFace": "FaceAPI初始化人脸追踪",
    "gui.blocklyText.machinelearning.drawFaceAPI": "FaceAPI[TOGGLE]标记人脸关键点",
    "gui.blocklyText.machinelearning.traceFaceNumber": "FaceAPI人脸数目",
    "gui.blocklyText.machinelearning.getFaceByIndex": "FaceAPI获取第[INDEX]个人脸的[PART]坐标[TYPE]",
    "gui.blocklyText.machinelearning.initfaceContrast": "FaceAPI初始化特定人脸识别",
    "gui.blocklyText.machinelearning.refFaceFromCamera": "FaceAPI从摄像头采集人脸数据标记为[NAME]",
    "gui.blocklyText.machinelearning.refFaceFromDir": "FaceAPI从本地文件夹[DIR]采集人脸数据以文件夹名标记",
    "gui.blocklyText.machinelearning.faceAPIFindFace": "FaceAPI识别一次摄像头中的人脸",
    "gui.blocklyText.machinelearning.allowFaceAPIResult": "FaceAPI人脸识别识别结果",
    "gui.blocklyText.machinelearning.allowFaceAPIResultScore": "FaceAPI人脸识别识别结果可信度",
    "gui.blocklyText.machinelearning.clearFaceAPIModel": "FaceAPI清除人脸模型数据",
    "gui.blocklyText.machinelearning.ml5WhenSearchFaceName": "当搜索到标签[NAME]可信度>=[VALUE]%时",
    "gui.blocklyText.machinelearning.machinelearningTracePose": "PoseNet初始化姿态追踪",
    "gui.blocklyText.machinelearning.drawPoseNet": "PoseNet[TOGGLE]标记姿态关键点",
    "gui.blocklyText.machinelearning.getPoseByIndex": "PoseNet获取第[INDEX]个姿态的[PART]坐标[TYPE]",
    "gui.blocklyText.machinelearning.initImageClassify": "初始化物体识别图像分类器(mobileNet)",
    "gui.blocklyText.machinelearning.toggleImageClassify": "[TOGGLE]物体识别图像分类器 实时识别",
    "gui.blocklyText.machinelearning.imageClassifyResult": "物体识别识别结果",
    "gui.blocklyText.machinelearning.imageClassifyResultScore": "物体识别识别可信度",
    "gui.blocklyText.huskylens.initVisualModal": "初始化AI可视化工具为[TYPE]",
    "gui.blocklyText.huskylens.classifyImageFromCamera": "将电脑摄像头画面加入分类器[TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromHuskylens": "将HuskyLens摄像头画面加入分类器[TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromDir": "将本地文件夹图片[DIR]加入分类器[TEXT]",
    "gui.blocklyText.huskylens.visualModalClearLabel": "清除分类[LABEL]",
    "gui.blocklyText.huskylens.visualModalClearAllLabels": "清除分类器所有分类",
    "gui.blocklyText.huskylens.deployClassificationToHuskylens": "训练模型并部署到HuskyLens",
    "gui.blocklyText.machinelearning.loadlmodel": "模型路径",
    "gui.blocklyText.pictureai.TOPIC_BODY_mouth": "嘴巴",
    "gui.blocklyText.pictureai.TOPIC_BODY_jaw_outline": "下颌",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye_brow": "左眼眉",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye_brow": "右眼眉",
    "gui.blocklyText.huskylens.videoContainer": "使用[CON]显示电脑摄像头画面",
    "gui.blocklyText.huskylens.videoToggle": "[VIDEO_STATE]电脑摄像头",
    "gui.blocklyText.huskylens.cameraToggle": "切换至电脑摄像头[CAMERA_LIST]",
    "gui.blocklyText.huskylens.exportImage": "电脑摄像头拍照一张保存到本地文件夹 [TEXT]",
    "gui.blocklyText.dialog.ml5.loadModel": "加载模型中，页面可能会出现卡顿，请稍候...",
    "gui.blocklyText.dialog.ml5.reInitPictureClassify": "分类器中已存在图片数据，重新初始化会清空现有数据，确认继续吗？",
    "gui.blocklyText.dialog.ml5.whenDeleteImageAfterTrain": "该图片数据已经经过训练，删除后，需要重新训练才能生效",
    "gui.blocklyText.dialog.ml5.renameLabel": "标签[LABEL]已存在，是否合并?",
    "gui.blocklyText.dialog.ml5.modelIsEmpty": "该模型中无分类数据，请先训练模型",
    "gui.blocklyText.dialog.ml5.shouldInitKNN": "请先初始化KNN分类器",
    "gui.blocklyText.dialog.huskylens.emptyAllImage": "该操作会删除所有分类，确认继续吗?",
    "gui.blocklyText.dialog.huskylens.HuskyLensTakePhotoError": "从HuskyLens拍照失败，请重新尝试",
    "gui.blocklyText.dialog.huskylens.overImageMaxLen": "[TYPE]分类器数量上限为[LEN]张，已超出上限，请整理后重试",
    "gui.blocklyText.dialog.huskylens.notAllowChinese": "分类名称应由不超过50位的数字和字母组成，不能含有中文及特殊字符",
    "gui.blocklyText.dialog.ml5.isNotKNN": "人脸识别类型的分类器无法加入KNN训练",
    "gui.blocklyText.machinelearning.start": "开始",
    "gui.blocklyText.machinelearning.stop": "停止",
    "gui.blocklyText.huskyLensPro.ML5MODEL.A": "KNN分类",
    "gui.blocklyText.huskyLensPro.ML5MODEL.B": "人脸识别",
    "gui.blocklyText.huskyLensPro.changeAlgorithm": "HuskyLens 切换到 [ALGORITHM] 算法 直到成功",
    "gui.blocklyText.huskyLensPro.learndIDCount": "HuskyLens 获取已学习ID总数",
    "gui.blocklyText.huskyLensPro.isOnTheScreen": "HuskyLens [BOXARROW] 在画面中？",
    "gui.blocklyText.huskyLensPro.parametersNearTheCenter": "HuskyLens 获取靠近中心 [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.idIsLearned": "HuskyLens ID [ID] 已经学习？",
    "gui.blocklyText.huskyLensPro.idIsOnTheScreen": "HuskyLens ID [ID] [BOXARROW] 在画面中？",
    "gui.blocklyText.huskyLensPro.idParametersNearTheCenter": "HuskyLens ID [ID] [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.boxCount": "HuskyLens 获取 [BOXARROW] 的总数",
    "gui.blocklyText.huskyLensPro.boxarrowParameter": "HuskyLens 获取第 [INDEX] 个 [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.idBoxCount": "HuskyLens 获取ID [ID] [BOXARROW] 的总数",
    "gui.blocklyText.huskyLensPro.idBoxarrowParameter": "HuskyLens 获取ID [ID] 第 [INDEX] 个 [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.learnOnce": "HuskyLens 当前算法自动学习一次ID [ID]",
    "gui.blocklyText.huskyLensPro.clearLearn": "HuskyLens 遗忘当前算法的所有学习数据",
    "gui.blocklyText.huskyLensPro.setIDname": "HuskyLens 设置当前算法ID [ID] 名字为 [NAME]",
    "gui.blocklyText.huskyLensPro.writeOSD": "HuskyLens 屏幕叠加显示文字 [NAME] 在x [X] y [Y]",
    "gui.blocklyText.huskyLensPro.clearOSD": "HuskyLens 清除屏幕显示的文字",
    "gui.blocklyText.huskyLensPro.requestPicture": "HuskyLens [TYPE] 并保存到本地文件夹 [PATH]",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.A": "拍照",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.B": "截屏",
    "gui.blocklyText.huskyLensPro.TakeScreenshotToSDCard": "HuskyLens 触发 [TYPE] 保存到SD卡",
    "gui.blocklyText.huskyLensPro.xCenter": "X中心",
    "gui.blocklyText.huskyLensPro.yCenter": "Y中心",
    "gui.blocklyText.huskyLensPro.width": "宽度",
    "gui.blocklyText.huskyLensPro.height": "高度",
    "gui.blocklyText.huskyLensPro.xStart": "X起点",
    "gui.blocklyText.huskyLensPro.yStart": "Y起点",
    "gui.blocklyText.huskyLensPro.xEnd": "X终点",
    "gui.blocklyText.huskyLensPro.yEnd": "Y终点",
    "gui.blocklyText.tello.telloSetPitchRollYaw": "設置遙控器四個通道杆量 橫滾[ROLL] 俯仰[PITCH] 油門[STRENGTH] 偏航[YAW]",
    "gui.blocklyText.tello.telloQuerySpeed": "當前速度",
    "gui.blocklyText.tello.telloTime": "電機執行時間(s)",
    "gui.blocklyText.tello.telloWIFISign": "Wi-Fi訊噪比",
    "gui.blocklyText.tello.telloSDKVersion": "Tello SDK版本",
    "gui.blocklyText.tello.telloSN": "Tello SN碼",
    "gui.blocklyText.tello.telloFlyToXY": "飛往(相對於飛機的) x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s",
    "gui.blocklyText.tello.reset": "重置",
    "gui.blocklyText.tello.telloFlyCurve": "飛弧線經 x1[X1]cm y1[Y1]cm z1[Z1]cm 和 x2[X2]cm y2[Y2]cm z2[Z2]cm 速度[SPEED]cm/s",
    "gui.blocklyText.tello.telloStopEmerygency": "緊急停機",
    "gui.blocklyText.tello.telloGetHeight": "相對高度(cm)",
    "gui.blocklyText.tello.telloBarometer": "氣壓計高度(m)",
    "gui.blocklyText.tello.telloTofDistance": "TOF高度(cm)",
    "gui.blocklyText.tello.telloTempMin": "主機板最低溫度(°C)",
    "gui.blocklyText.tello.telloTempMax": "主機板最高溫度(°C)",
    "gui.blocklyText.tello.TelloAttitudePitch": "俯仰軸姿態角(°)",
    "gui.blocklyText.tello.TelloAttitudeRoll": "橫滾軸姿態角(°)",
    "gui.blocklyText.tello.TelloAttitudeYaw": "平移軸姿態角(°)",
    "gui.blocklyText.tello.telloAccelerationX": "X軸加速度(0.001g)",
    "gui.blocklyText.tello.telloAccelerationY": "Y軸加速度(0.001g)",
    "gui.blocklyText.tello.telloAccelerationZ": "Z軸加速度(0.001g)",
    "gui.blocklyText.tello.telloSpeedX": "X軸速度(cm/s)",
    "gui.blocklyText.tello.telloSpeedY": "Y軸速度(cm/s)",
    "gui.blocklyText.tello.telloSpeedZ": "Z軸速度(cm/s)",
    "gui.blocklyText.tello.telloFlyToMissionPad": "飛往挑戰卡的 x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.TelloFlyCurveMissionPad": "飛弧線經挑戰卡下 x1[X1]cm y1[Y1]cm z1[Z1]cm 和 x2[X2]cm y2[Y2]cm z2[Z2]cm 速度[SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.telloJumpInMissionPad": "跳躍沿挑戰卡 x[X]cm y[Y]cm z[Z]cm 速度 [SPEED]cm/s 偏航 [YAW] 度 從 Mid1 [MID1] 到 Mid2 [MID2]",
    "gui.blocklyText.tello.telloRotateWithYaw": "飛機相對挑戰卡[MID]旋轉至[YAW]角度",
    "gui.blocklyText.tello.telloStopAndHover": "停止運動並懸停",
    "gui.blocklyText.telloesp32.telloGetStatus": "獲取一次狀態碼存入結果, 超時 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloReturnStatusChallenge": "從結果中取 [STATUSCHALLENGE]",
    "gui.blocklyText.telloesp32.statusChallengeX": "相對挑戰卡X座標",
    "gui.blocklyText.telloesp32.statusChallengeY": "相對挑戰卡Y座標",
    "gui.blocklyText.telloesp32.statusChallengeZ": "相對挑戰卡Z座標",
    "gui.blocklyText.telloesp32.statusChallengePitch": "相對挑戰卡俯仰角",
    "gui.blocklyText.telloesp32.statusChallengeYaw": "相對挑戰卡偏航角",
    "gui.blocklyText.telloesp32.statusChallengeRoll": "相對挑戰卡橫滾角",
    "gui.blocklyText.telloesp32.statusChallengeId": "挑戰卡ID",
    "gui.blocklyText.telloesp32.telloReturnStatusAttitude": "從結果中取 [STATUSATTITUDE]",
    "gui.blocklyText.telloesp32.statusAttitudePitch": "俯仰角",
    "gui.blocklyText.telloesp32.statusAttitudeYaw": "偏航角",
    "gui.blocklyText.telloesp32.statusAttitudeRoll": "橫滾角",
    "gui.blocklyText.telloesp32.statusAttitudeXSpeed": "X軸速度",
    "gui.blocklyText.telloesp32.statusAttitudeYSpeed": "Y軸速度",
    "gui.blocklyText.telloesp32.statusAttitudeZSpeed": "Z軸速度",
    "gui.blocklyText.telloesp32.statusAttitudeXAcc": "X軸加速度",
    "gui.blocklyText.telloesp32.statusAttitudeYAcc": "Y軸加速度",
    "gui.blocklyText.telloesp32.statusAttitudeZAcc": "Z軸加速度",
    "gui.blocklyText.telloesp32.telloReturnStatusNormal": "從結果中取 [STATUSNORMAL]",
    "gui.blocklyText.telloesp32.statusNormalMaxTemp": "最高溫度",
    "gui.blocklyText.telloesp32.statusNormalLowTemp": "最低溫度",
    "gui.blocklyText.telloesp32.statusNormalTof": "ToF距離",
    "gui.blocklyText.telloesp32.statusNormalHeight": "相對高度",
    "gui.blocklyText.telloesp32.statusNormalBat": "當前電量",
    "gui.blocklyText.telloesp32.statusNormalBaro": "氣壓高度",
    "gui.blocklyText.telloesp32.statusNormalMotor": "電機時間",
    "gui.blocklyText.tello.MissionPad": "挑戰卡編號",
    "gui.blocklyText.tello.MissionPadX": "挑戰卡X(cm)",
    "gui.blocklyText.tello.MissionPadY": "挑戰卡Y(cm)",
    "gui.blocklyText.tello.telloMissionPadZ": "挑戰卡Z(cm)",
    "gui.blocklyText.tello.telloCommandResponse": "回復指令",
    "gui.blocklyText.tello.direction_front": "前視",
    "gui.blocklyText.tello.direction_blow": "下視",
    "gui.blocklyText.tello.direction_all": "所有",
    "gui.blocklyText.tello.mid": "挑戰卡",
    "gui.blocklyText.tello.midfastest": "m-1",
    "gui.blocklyText.tello.midlatest": "m-2",
    "gui.blocklyText.tello.telloToggleMon": "[TOGGLE]挑戰卡探測",
    "gui.blocklyText.tello.telloSetMDireaction": "設置探測位置[POSITION]",
    "gui.blocklyText.tello.telloSetWIFI": "修改Tello的Wi-Fi為熱點[NAME] 密碼[PASSWD]",
    "gui.blocklyText.tello.telloToggleGroup": "切換為station模式，連入熱點[NAME] 密碼[PASSWD]",
    "gui.blocklyText.huskyLensPro.QRRecognition": "二维码识别",
    "gui.blocklyText.huskyLensPro.barcodeRecognition": "条形码识别",
    "gui.blocklyText.huskyLensPro.saveFailed": "保存图片失败",
    "gui.blocklyText.huskyLensPro.yes": "是的",
    "gui.blocklyText.huskyLensPro.cancel": "取消",
    "gui.blocklyText.huskyLensPro.promptInitializeClassifier": "请先初始化图片分类器",
    "gui.blocklyText.huskyLensPro.promptInitializeKNNClassifier": "请先初始化KNN分类器",
    "gui.blocklyText.huskyLensPro.promptAddClassificationData": "请先添加分类数据",
    "gui.blocklyText.huskyLensPro.setTitleText": "HuskyLens AI-KNN图像分类",
    "gui.blocklyText.huskyLensPro.tooManyPictures": "图片数目太多，仅提取前五十张",
    "gui.blocklyText.huskyLensPro.checkPath": "请检查路径[PATH]下是否包含图片",
    "gui.blocklyText.huskyLensPro.success": "成功",
    "gui.blocklyText.huskyLensPro.importImg": "本次成功导入[COUNT1]张图片，[COUNT2]张失败",
    "gui.blocklyText.huskyLensPro.recognitionResult": "识别结果为：",
    "gui.blocklyText.telloGroup.telloRise": "上升",
    "gui.blocklyText.telloGroup.telloDown": "下降",
    "gui.blocklyText.telloGroup.telloGoahead": "前進",
    "gui.blocklyText.telloGroup.telloBack": "後退",
    "gui.blocklyText.telloGroup.telloTurnLeft": "向左",
    "gui.blocklyText.telloGroup.telloTurnRight": "向右",
    "gui.blocklyText.telloGroup.telloSetFly": "[DIRECTION]  [NUMS]cm",
    "gui.blocklyText.telloGroup.telloClockwiseRotation": "順時針",
    "gui.blocklyText.telloGroup.telloCounterclockwiseSelection": "逆時針",
    "gui.blocklyText.telloGroup.telloClockRotation": "[CLOCKWISE] 旋轉 [NUMS]度",
    "gui.blocklyText.telloGroup.setTelloIndexWithSN": "設置Tello SN碼 [SN] 編號為 [INDEX]",
    "gui.blocklyText.telloGroup.setTelloIndexWithSSID": "設置Tello SSID [SSID] 編號為 [INDEX]",
    "gui.blocklyText.telloGroup.scanTelloInNetwork": "在網路中掃描 [NUM] 台Tello 超時[TIME]秒",
    "gui.blocklyText.telloGroup.telloSynchronousCommand": "同步指令 等待時間[TIME]秒",
    "gui.blocklyText.tello.telloVideoToggle": "[VIDEO_STATE] Tello 攝像頭",
    "gui.blocklyText.telloGroup.TEMPH": "最高溫度",
    "gui.blocklyText.telloGroup.TEMPL": "最低溫度",
    "gui.blocklyText.telloGroup.NUM": "編號",
    "gui.blocklyText.telloGroup.pitch": "俯仰軸",
    "gui.blocklyText.telloGroup.roll": "橫滾軸",
    "gui.blocklyText.telloGroup.translation": "平移軸",
    "gui.blocklyText.telloGroup.telloAccelerationXYZ": "[XYZ]軸加速度(0.001g)",
    "gui.blocklyText.telloGroup.telloSpeedXYZ": "[XYZ]軸速度(cm/s)",
    "gui.blocklyText.telloGroup.TelloAttitudePitch": "[ANGEL]姿態角(°)",
    "gui.blocklyText.telloGroup.telloTempMinAndMax": "主機板[TEMP](°C)",
    "gui.blocklyText.tello.telloDisplayRollText": "滾動顯示文字 方向[DIRECT] 移動頻率[SPEED] 文字[TEXT] 顏色[COLOR]",
    "gui.blocklyText.tello.telloDisplayText": "顯示單個字元 [TEXT] 顏色[COLOR]",
    "gui.blocklyText.tello.telloRollText_up": "向上",
    "gui.blocklyText.tello.telloRollText_down": "向下",
    "gui.blocklyText.tello.telloRollText_left": "向左",
    "gui.blocklyText.tello.telloRollText_right": "向右",
    "gui.blocklyText.telloGroup.initTelloGroup": "斷開連接",
    "gui.blocklyText.tello.shutdownForPower": "電量已不足[POWER]，Tello即將關機",
    "gui.blocklyText.tello.portCantUse": "服務出錯，埠[PORT]訪問失敗, 請檢查是否被其他程式佔用",
    "gui.blocklyText.tello.telloReadESP32Version": "擴展模組固件版本",
    "gui.blocklyText.tello.telloSSID": "擴展模組SSID",
    "gui.blocklyText.tello.telloLightLEDWithColor": "LED顏色[COLOR]",
    "gui.blocklyText.tello.telloLightLEDWithColor_rgb": "LED顏色 紅:[RGBR] 綠:[RGBG] 藍:[RGBB]",
    "gui.blocklyText.tello.telloBreathLEDWithColor": "LED呼吸燈 顏色[COLOR]頻率[TIME](Hz)",
    "gui.blocklyText.tello.telloBreathLEDWithColor_rgb": "LED呼吸燈 紅:[RGBR] 綠:[RGBG]  藍:[RGBB] 頻率:[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor": "LED閃爍 顏色1[COLOR1]和顏色2[COLOR2]頻率[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor_rgb": "LED閃爍 紅1:[RGBR1] 綠1:[RGBG1] 藍1:[RGBB1] - 紅2:[RGBR2] 綠2:[RGBG2] 藍2:[RGBB2] 頻率:[TIME](Hz)",
    "gui.blocklyText.tello.telloShowPattern": "顯示圖案[MARK]",
    "gui.blocklyText.tello.telloShowPatternWhenStart": "設定開機圖案[MARK]",
    "gui.blocklyText.tello.telloShowRollPattern": "滾動顯示圖案 方向[DIRECT] 移動頻率[SPEED] 圖案[MARK]",
    "gui.blocklyText.tello.telloSetLight": "設置螢幕亮度[NUM]",
    "gui.blocklyText.tello.telloSendCMD": "發送自訂命令[CMD]",
    "gui.blocklyText.tello.telloSendDirective": "發送明碼訊息[CMD]",
    "gui.blocklyText.tello.telloReceiveMsg": "接收到字串訊息[MSG]",
    "gui.blocklyText.tello.telloReceiveCustomNum": "接收到自訂回復數值",
    "gui.blocklyText.tello.connectTelloWIFIError": "連接Tello Wi-Fi失敗，請嘗試手動連接Wi-Fi",
    "gui.blocklyText.tello.connectTelloError": "連接Tello失敗，請確認設備狀態，重新連接。你也可以嘗試手動連接Wi-Fi",
    "gui.blocklyText.tello.tempIsHigh": "Tello溫度過高，即將關機",
    "gui.blocklyText.tello.scanErrorWithOpenWlan": "掃描出錯了，請檢查是否打開Wi-Fi,你也可以嘗試手動連接Wi-Fi",
    "gui.blocklyText.tello.noTelloFound": "暫無可用的Tello設備，你也可以嘗試手動連接Wi-Fi",
    "gui.blocklyText.tello.connectIsBroke": "Tello連接已斷開，請檢查設備",
    "gui.blocklyText.tello.firstConnectTello": "請先連接Tello",
    "gui.blocklyText.tello.telloQueryWifiVersion": "擴展模組Wi-Fi版本",
    "gui.blocklyText.tello.telloQueryHardwareVersion": "獲取硬體版本",
    "gui.blocklyText.tello.telloToggleTOF": "[TOGGLE] TOF模組",
    "gui.blocklyText.tello.telloToggleTOF_enable": "啟用",
    "gui.blocklyText.tello.telloToggleTOF_disabled": "停用",
    "gui.blocklyText.tello.activeTelloFirst": "連接失敗，新飛機未啟動，請按照飛機說明書進行啟動",
    "gui.blocklyText.tello.startPaddle": "進入起槳模式",
    "gui.blocklyText.tello.stopPaddle": "退出起槳模式",
    "gui.blocklyText.tello.togglePaddle": "[TOGGLE]起槳模式",
    "gui.blocklyText.tello.telloTogglePaddle_enable": "進入",
    "gui.blocklyText.tello.telloTogglePaddle_disabled": "退出",
    "gui.blocklyText.tello.telloThrowAway": "5秒內拋飛",
    "gui.blocklyText.tello.telloResetPattern": "恢復預設開機圖案",
    "gui.blocklyText.tello.telloQueryTelloVersion": "Tello固件版本",
    "gui.blocklyText.tello.telloQueryBatActive": "Tello 查詢電池電量",
    "gui.blocklyText.tello.telloReadTOF": "讀取TOF測距 (mm)",
    "gui.blocklyText.telloGroup.allTellos": "所有的",
    "gui.blocklyText.tello.notAllowInput": "請輸入數位或英文字元,不能包含其它特殊字元",
    "gui.blocklyText.tello.checkFirewallAndTello": "連接已斷開，請檢查Tello連接狀態，同時請將軟體加入電腦防火牆白名單",
    "gui.blocklyText.telloGroup.checkWlanNum": "請確認本機連接到正確的網路，若已連接到多個網路，請先斷開其他連接",
    "gui.blocklyText.telloGroup.telloIsNotExist": "[INDEX]號Tello不存在",
    "gui.blocklyText.telloGroup.findTelloWithLost": "找到[FOUND]個,[NOTFOUND]未找到,ip:[IP]",
    "gui.blocklyText.telloGroup.findTello": "找到[FOUND]個,ip:[IP]",
    "gui.blocklyText.telloGroup.cantRepeatScan": "正在掃描中，請勿重複掃描..",
    "gui.blocklyText.telloGroup.setIndexForTelloFirst": "請先設置編號",
    "gui.blocklyText.maixduino.programMain": "K210 Maixduino 主程序",
    "gui.blocklyText.maixduino.LCDInit": "屏幕初始化为频率 [FREQ] 背景 [COLOR] 反色 [INVERT]",
    "gui.blocklyText.maixduino.LCDShowImageFromPath": "屏幕显示图像 path:[PATH]",
    "gui.blocklyText.maixduino.LCDShowImageFromImage": "屏幕显示图像 image:[IMAGE]",
    "gui.blocklyText.maixduino.LCDShowText": "屏幕显示文字 [TEXT] 在坐标X [X] Y [Y] 文字 [TEXT_COLOR] 背景 [BACKGROUND_COLOR]",
    "gui.blocklyText.maixduino.LCDFill": "屏幕显示为 [COLOR] (清屏)",
    "gui.blocklyText.maixduino.LCDRotation": "屏幕旋转 [ANGLE]",
    "gui.blocklyText.maixduino.LCDMirror": "屏幕镜像 [MIRROR]",
    "gui.blocklyText.maixduino.LCDSize": "屏幕分辨率 [SIZE]",
    "gui.blocklyText.maixduino.LCDRGB888": "RGB888 红 [RED] 绿 [GREEN] 蓝 [BLUE]",
    "gui.blocklyText.maixduino.LCDColor": "颜色 [COLOR]",
    "gui.blocklyText.blockTitle.maixduino.Camera": "摄像头",
    "gui.blocklyText.maixduino.CameraInit": "初始化为 [MODE] 参数 [SETTINGS]",
    "gui.blocklyText.maixduino.CameraGetImage": "相机获取图像",
    "gui.blocklyText.maixduino.CameraControl": "选择 [CONTROL] 摄像头(双目)",
    "gui.blocklyText.maixduino.CameraHorizontalMirroring": "相机 [CONTROL] 水平镜像",
    "gui.blocklyText.maixduino.CameraVerticalMirroring": "相机 [CONTROL] 垂直镜像",
    "gui.blocklyText.maixduino.CameraColorBarMode": "相机 [CONTROL] 彩条模式",
    "gui.blocklyText.maixduino.CameraSettingParameters": "相机设置 [PARAMETERS] 为 [VALUE]",
    "gui.blocklyText.maixduino.CameraSettingWindow": "相机设置窗口 宽为[WIDTH] 高为[HEIGHT]",
    "gui.blocklyText.maixduino.CameraGetParameters": "相机获取 [PARAMETERS]",
    "gui.blocklyText.blockTitle.audioAndVideo": "音视频",
    "gui.blocklyText.maixduino.AudioPlay": "音频播放 [PATH] [WAIT]",
    "gui.blocklyText.maixduino.AudioVolume": "音频音量 [VOLUME] %",
    "gui.blocklyText.maixduino.AudioState": "获取音频播放状态",
    "gui.blocklyText.maixduino.AudioRecord": "音频录制 [PATH] 时长 [TIME] s [WAIT]",
    "gui.blocklyText.maixduino.VideoPlay": "视频播放 [PATH] [WAIT]",
    "gui.blocklyText.maixduino.VideoVolume": "视频音量 [VOLUME] %",
    "gui.blocklyText.maixduino.VideoState": "获取视频播放状态",
    "gui.blocklyText.maixduino.VideoRecord": "视频录制 [PATH] 时长 [TIME] s [WAIT]",
    "gui.blocklyText.maixduino.digitalRead": "读取数字引脚 [PIN]",
    "gui.blocklyText.maixduino.analogRead": "读取模拟引脚 [PIN]",
    "gui.blocklyText.maixduino.digitalWrite": "设置数字引脚 [PIN] 输出 [LEVEL]",
    "gui.blocklyText.maixduino.PWMInit": "初始化PWM引脚 [PIN] 频率 [FREQ] Hz 定时器 [TIMER]",
    "gui.blocklyText.maixduino.PWMWrite": "设置PWM引脚 [PIN] 输出占空比 [DUTY]",
    "gui.blocklyText.maixduino.ticksDiff": "返回运行时间差，开始时间 [STARTTIME] 结束时间 [ENDTIME]",
    "gui.blocklyText.maixduino.runTimer": "运行时间 [TIMETYPE]",
    "gui.blocklyText.maixduino.gc": "[GC] 垃圾回收",
    "gui.blocklyText.maixduino.systemReset": "复位",
    "gui.blocklyText.maixduino.print": "打印 [DATA]",
    "gui.blocklyText.maixduino.LevelMenu.A": "高电平",
    "gui.blocklyText.maixduino.LevelMenu.B": "低电平",
    "gui.blocklyText.maixduino.MirrorMenu.A": "开启",
    "gui.blocklyText.maixduino.MirrorMenu.B": "关闭",
    "gui.blocklyText.maixduino.SelectMenu.A": "0号",
    "gui.blocklyText.maixduino.SelectMenu.B": "1号",
    "gui.blocklyText.maixduino.SettingParametersMenu.A": "对比度",
    "gui.blocklyText.maixduino.SettingParametersMenu.B": "亮度值",
    "gui.blocklyText.maixduino.SettingParametersMenu.C": "增益值",
    "gui.blocklyText.maixduino.SettingParametersMenu.D": "饱和度",
    "gui.blocklyText.maixduino.GetParametersMenu.A": "分辨率宽度",
    "gui.blocklyText.maixduino.GetParametersMenu.B": "分辨率高度",
    "gui.blocklyText.maixduino.GetParametersMenu.C": "当前增益值",
    "gui.blocklyText.maixduino.GetParametersMenu.D": "帧缓存图像",
    "gui.blocklyText.maixduino.GetParametersMenu.E": "硬件型号ID",
    "gui.blocklyText.maixduino.TimeTypeMenu.A": "秒",
    "gui.blocklyText.maixduino.TimeTypeMenu.B": "毫秒",
    "gui.blocklyText.maixduino.TimeTypeMenu.C": "微秒",
    "gui.blocklyText.maixduino.GcMenu.A": "自动",
    "gui.blocklyText.maixduino.GcMenu.B": "运行",
    "gui.blocklyText.maixduino.LcdSizeMenu.A": "宽度",
    "gui.blocklyText.maixduino.LcdSizeMenu.B": "高度",
    "gui.blocklyText.maixduino.WaitMenu.A": "等待",
    "gui.blocklyText.maixduino.WaitMenu.B": "后台",
    "gui.blocklyText.maixduino.CameraModeMenu.A": "单目",
    "gui.blocklyText.maixduino.CameraModeMenu.B": "双目",
    "gui.blocklyText.maixduino.AngleMenu.A": "0°",
    "gui.blocklyText.maixduino.AngleMenu.B": "90°",
    "gui.blocklyText.maixduino.AngleMenu.C": "180°",
    "gui.blocklyText.maixduino.AngleMenu.D": "270°",
    "gui.blocklyText.maixduino.camerainit.frameformat": "帧格式",
    "gui.blocklyText.maixduino.camerainit.frameformat.RGB565": "彩色图",
    "gui.blocklyText.maixduino.camerainit.frameformat.GRAYSCALE": "灰度图",
    "gui.blocklyText.maixduino.camerainit.framesize": "帧大小",
    "gui.blocklyText.maixduino.camerainit.imagecapture": "图像捕捉",
    "gui.blocklyText.maixduino.camerainit.imagecapture.on": "开启",
    "gui.blocklyText.maixduino.camerainit.imagecapture.off": "关闭",
    "gui.blocklyText.maixduino.camerainit.skipframes": "跳帧数",
    "gui.extension.AI.name": "人工智能",
    "gui.blocklyText.AI.anchorPoint": "锚点参数 [POINT]",
    "gui.blocklyText.AI.classNames": "物品名称 [NAMES]",
    "gui.blocklyText.AI.modelLoadingAddress": "模型加载 [ADDRESS]",
    "gui.blocklyText.AI.modelLoadingPath": "模型路径 [PATH]",
    "gui.blocklyText.AI.yolo2Init": "yolo2 初始化 网络模型 [MODEL] 概率阈值 [PT] box_iou门限 [BT] 锚点数[APC] 锚点参数 [AP]",
    "gui.blocklyText.AI.yolo2Run": "yolo2 运行网络 模型 [MODEL] 图像 [IMAGE]",
    "gui.blocklyText.AI.yolo2forward": "yolo2 向前运算 模型 [MODEL] 图像 [IMAGE]",
    "gui.blocklyText.AI.yolo2Parsing": "yolo2 模型解析 对象 [OBJ] 获取 [TYPE]",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.A": "X坐标",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.B": "Y坐标",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.C": "标识号",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.D": "置信度",
    "gui.blocklyText.machineHearing.newModel": "ASR 新建空白模型",
    "gui.blocklyText.machineHearing.training": "ASR 开始训练 语料 [CORPUS] 添加到模型 [MODEL]",
    "gui.blocklyText.machineHearing.run": "ASR 运行模型 [MODEL]",
    "gui.blocklyText.machineHearing.recognize": "ASR 语音识别结果",
    "gui.blocklyText.machineHearing.save": "ASR 保存模型 [MODEL] 到 [PATH]",
    "gui.blocklyText.machineHearing.load": "ASR 加载模型 [PATH]",
    "gui.extension.machineVision.imageBasis.name": "机器视觉 · 图像基础",
    "gui.blocklyText.machineVision.openEmptyImage": "打开空图",
    "gui.blocklyText.machineVision.openPathImage": "打开路径 [PATH]",
    "gui.blocklyText.machineVision.clearImage": "清除图像 [IMAGE]",
    "gui.blocklyText.machineVision.saveImage": "保存图像 [IMAGE] 路径 [PATH]",
    "gui.blocklyText.machineVision.modifyPixelColor": "修改图像 [IMAGE] 坐标xy [COORDINATE] 颜色 [COLOR]",
    "gui.blocklyText.machineVision.getPixelColor": "获取图像 [IMAGE] 坐标-xy [COORDINATE] 颜色",
    "gui.blocklyText.machineVision.captureImage": "截取图像 [IMAGE] 区域-xywh [AREA]",
    "gui.blocklyText.machineVision.compressedImage": "压缩图像 [IMAGE] 压缩率 [RATIO]",
    "gui.blocklyText.machineVision.getImageInfo": "获取图像 [IMAGE] [INFO]",
    "gui.blocklyText.machineVision.conversion": "将图像 [IMAGE] 转换为 [TYPE]",
    "gui.extension.machineVision.imageDrawing.name": "机器视觉 · 图像绘制",
    "gui.blocklyText.machineVision.drawText": "绘制文本 图像 [IMAGE] 起点-xy [COORDINATE] 内容 [CONTENT] 颜色 [COLOR] 字号 [SIZE]",
    "gui.blocklyText.machineVision.drawImage": "绘制图像 图像 [IMAGE] 坐标-xy [COORDINATE] 缩放-xy [ZOOM]",
    "gui.blocklyText.machineVision.drawLine": "绘制直线 图像 [IMAGE] 坐标-xyxy [COORDINATE] 颜色 [COLOR] 粗细 [SIZE]",
    "gui.blocklyText.machineVision.drawArrows": "绘制箭头 图像 [IMAGE] 坐标-xyxy [COORDINATE] 颜色 [COLOR] 大小 [SIZE]",
    "gui.blocklyText.machineVision.drawCross": "绘制十字 图像 [IMAGE] 坐标-xy [COORDINATE] 颜色 [COLOR] 延伸 [EXTEND] 粗细 [SIZE]",
    "gui.blocklyText.machineVision.drawRound": "绘制圆形 图像 [IMAGE] 坐标-xyr [COORDINATE] 颜色 [COLOR] 粗细 [SIZE] 填充 [FILLING]",
    "gui.blocklyText.machineVision.drawRectangle": "绘制矩形 图像 [IMAGE] 坐标-xywh [COORDINATE] 颜色 [COLOR] 粗细 [SIZE] 填充 [FILLING]",
    "gui.blocklyText.machineVision.drawFeaturePoints": "绘制特征点 图像 [IMAGE] 对象 [OBJ] 颜色 [COLOR] 大小 [SIZE] 粗细 [THICKNESS] 填充 [FILLING]",
    "gui.extension.machineVision.imageFiltering.name": "机器视觉 · 图像滤波",
    "gui.blocklyText.machineVision.correctionZoom": "校正缩放 图像 [IMAGE] 校正值 [CORRECTION] 缩放值 [ZOOM]",
    "gui.blocklyText.machineVision.histogramEqualization": "直方图均衡 图像 [IMAGE] 自适应 [ADAPTIVE] 对比度 [CONTRAST]",
    "gui.blocklyText.machineVision.fuzzyFilter": "模糊滤波 图像 [IMAGE] 卷积核 [CONVOLUTIONKERNEL] 自适应 [ADAPTIVE] 补偿 [MAKEUP]",
    "gui.blocklyText.machineVision.cartoonFilter": "卡通滤波 图像 [IMAGE] 原始差异 [ORIGINALDIFFERENCE] 相邻差异 [ADJACENTDIFFERENCE]",
    "gui.blocklyText.machineVision.imageCorrosion": "图像腐蚀 图像 [IMAGE] 卷积核 [CONVOLUTIONKERNEL] 阈值 [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageExpansion": "图像膨胀 图像 [IMAGE] 卷积核 [CONVOLUTIONKERNEL] 阈值 [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageFill": "图像填充 图像 [IMAGE] 坐标-xy [COORDINATE] 颜色 [COLOR] 原始差异 [ORIGINALDIFFERENCE] 相邻差异 [ADJACENTDIFFERENCE] 反转 [REVERSE] 清除 [CLEAR]",
    "gui.blocklyText.machineVision.ImageProjection": "图像投影 图像 [IMAGE] 垂直投影 [VERTICALPROJECTION]",
    "gui.blocklyText.machineVision.ImageInversion": "图像反转 图像 [IMAGE]",
    "gui.extension.machineVision.shapeRecognition.name": "机器视觉 · 形状识别",
    "gui.blocklyText.machineVision.straightLineAnalysis": "直线解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.circularAnalysis": "圆形解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.rectangleAnalysis": "矩形解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.lineRecognition": "直线识别 图像 [IMAGE] 区域-xywh [AREA] 阈值 [THRESHOLD] 合并角度 [MERGEANGLE] 合并斜率 [MERGESLOPE]",
    "gui.blocklyText.machineVision.lineSegmentRecognition": "线段识别 图像 [IMAGE] 区域-xywh [AREA] 间距 [SPACING] 合并角度 [MERGEANGLE]",
    "gui.blocklyText.machineVision.circleRecognition": "圆形识别 图像 [IMAGE] 区域-xywh [AREA] 阈值 [THRESHOLD] 最小r [MINIMUM] 最大r [MAXIMUM] 步长r [STRIDE] 合并x值 [COMBINEDX] 合并y值 [COMBINEDY] 合并r值 [COMBINEDR]",
    "gui.blocklyText.machineVision.rectangleRecognition": "矩形识别 图像 [IMAGE] 区域-xywh [AREA] 阈值 [THRESHOLD]",
    "gui.extension.machineVision.colorRecognition.name": "机器视觉 · 颜色识别",
    "gui.blocklyText.machineVision.LABFromRGB888": "LAB值 由RGB888 [RGB888]",
    "gui.blocklyText.machineVision.RGB888FromLAB": "RGB888值 由LAB [LAB]",
    "gui.blocklyText.machineVision.grayscaleFromRGB888": "灰度值 由RGB888 [RGB888]",
    "gui.blocklyText.machineVision.GRB888FromGrayscale": "RGB888 由灰度值 [GRAYSCALE]",
    "gui.blocklyText.machineVision.trackingAnalysis": "追踪解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.colorAnalysis": "颜色解析 对象 [OBJ] CDF [CDF] 获取 [INFO]",
    "gui.blocklyText.machineVision.colorPatchTracking": "色块追踪 图像 [IMAGE] 阈值LAB [THRESHOLDLAB] 区域-xywh [AREA] 滤掉区域 [FILTEROUTAREA] 滤掉素数 [FILTEROUTPRIMENUMBERS] 合并 [MERGE] 重叠 [OVERLAPPING]",
    "gui.blocklyText.machineVision.colorRecognition": "颜色识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.extension.machineVision.dimensionCodeRecognition.name": "机器视觉 · 维码识别",
    "gui.blocklyText.machineVision.barcodeAnalysis": "条形码解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.QRCodeAnalysis": "二维码解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.aprilTagAnalysis": "AprilTag解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.BarCodeRecognition": "条形码识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.blocklyText.machineVision.QRCodeRecognition": "二维码识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.blocklyText.machineVision.AprilTagRecognition": "AprilTag识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.extension.machineVision.featureRecognition.name": "机器视觉 · 特征识别",
    "gui.blocklyText.machineVision.featureInformation": "特征信息 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.directionalGradienMap": "定向梯度图 图像 [IMAGE] 区域-xywh [AREA] 线长 [LINELENGTH]",
    "gui.blocklyText.machineVision.featureRecognition": "特征识别 图像 [IMAGE] 区域-xywh [AREA] 关键点数 [KEYPOINTS] 角点数 [CORNERPOINTS] 比例因子 [SCALEFACTOR] 多比例 [MULTIRATIO]",
    "gui.blocklyText.machineVision.featureComparison": "特征比较 对象1 [OBJ1] 对象2 [OBJ2] 相似度 [SIMILARITY]",
    "gui.blocklyText.machineVision.FeatureInformationMenu.A": "坐标-xywh",
    "gui.blocklyText.machineVision.FeatureInformationMenu.B": "x分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.C": "y分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.D": "w分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.E": "h分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.F": "中心-x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.G": "中心-y",
    "gui.blocklyText.machineVision.FeatureInformationMenu.H": "匹配量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.I": "弧度",
    "gui.blocklyText.machineVision.FeatureInformationMenu.J": "匹配点-xy",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.H": "号码",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.I": "家族",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.J": "弧度",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.K": "x向距离",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.L": "y向距离",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.M": "z向距离",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.N": "x向弧度",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.O": "y向弧度",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.P": "z向弧度",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.F": "内容",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.G": "版本",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.H": "掩码",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.I": "类型",
    "gui.blocklyText.machineVision.BarcodeAnalysisMenu.H": "角度",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.A": "灰度图值",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.B": "LAB的L值",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.C": "LAB的A值",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.D": "LAB的B值",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.H": "像素量",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.J": "号码数",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.K": "合并量",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.L": "面积",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.M": "密度比",
    "gui.blocklyText.machineVision.CircularInformationMenu.A": "坐标-xyr",
    "gui.blocklyText.machineVision.CircularInformationMenu.D": "r分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.B": "x1分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.C": "y1分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.D": "x2分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.E": "y2分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.F": "长度",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.H": "斜率",
    "gui.blocklyText.machineVision.ImageInfoMenu.A": "宽度",
    "gui.blocklyText.machineVision.ImageInfoMenu.B": "高度",
    "gui.blocklyText.machineVision.ImageInfoMenu.C": "格式",
    "gui.blocklyText.machineVision.ImageInfoMenu.D": "大小",
    "gui.blocklyText.machineVision.ConversionTypeMenu.A": "灰度图",
    "gui.blocklyText.machineVision.ConversionTypeMenu.B": "彩色图",
    "gui.blocklyText.machineVision.ConversionTypeMenu.C": "彩虹图",
    "gui.blocklyText.machineVision.ConversionTypeMenu.D": "AI格式",
    "gui.extension.mpyirq.name": "引脚中断",
    "gui.blocklyText.mpyirq.event": "中断引脚 [PIN] 模式 [MODE] 执行",
    "gui.blocklyText.mpyirq.clean": "取消引脚中断 [PIN]",
    "gui.blocklyText.pyTurtle.init": "创建海龟绘图器[NAME]",
    "gui.blocklyText.pyTurtle.turtleSetShape": "[NAME] 形状设置为 [SHAPE]",
    "gui.blocklyText.pyTurtle.turtleDone": "绘制结束，继续保持窗口",
    "gui.blocklyText.pyTurtle.turtleExitClick": "点击退出绘制",
    "gui.blocklyText.pyTurtle.turtleMove": "[NAME]移动[DIRECTION][DISTANCE]",
    "gui.blocklyText.pyTurtle.turtleRotate": "[NAME]旋转[DIRECTION][ANGLE]",
    "gui.blocklyText.pyTurtle.turtleFaceTo": "[NAME]面向[ANGLE]度",
    "gui.blocklyText.pyTurtle.turtleMoveToPos": "[NAME]移动到坐标X[X] Y[Y]位置处",
    "gui.blocklyText.pyTurtle.turtleSetPos": "[NAME] [XY]坐标设置为[POS]",
    "gui.blocklyText.pyTurtle.turtleGetStatus": "[NAME] 获取当前[STATUS]",
    "gui.blocklyText.pyTurtle.turtleClearAll": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleClone": "[NAME] 复制海龟",
    "gui.blocklyText.pyTurtle.turtleVisible": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleSetThickness": "[NAME] 设置画笔粗细 [THICK]",
    "gui.blocklyText.pyTurtle.turtleSetScale": "[NAME] 设置海龟图标大小 垂直拉伸倍数[VERTICAL] 水平拉伸倍数[HOR] 轮廓宽度[OUTLINE]",
    "gui.blocklyText.pyTurtle.turtleSetSpeed": "[NAME] 设置画笔速度为[SPEED]",
    "gui.blocklyText.pyTurtle.turtleSetBackground": "[NAME] 设置背景颜色[COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetPenColor": "[NAME] 设置画笔颜色 [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetFillColor": "[NAME]设置画笔填充颜色[COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetColor": "[NAME] 设置画笔颜色 [COLOR1] 设置画笔填充颜色[COLOR2]",
    "gui.blocklyText.pyTurtle.turtleDrawAction": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleDrawCircle": "[NAME]绘制图形[CIRCLE] 半径为[RADIUS]",
    "gui.blocklyText.pyTurtle.turtleDrawCircleWithAngle": "[NAME]绘制圆形 半径为[RADIUS] 角度为[DEGREE]",
    "gui.blocklyText.pyTurtle.turtleInitScreen": "[NAME] 初始化海龟屏幕[SCREEN]",
    "gui.blocklyText.pyTurtle.turtleSaveDraw": "[NAME] 将绘制的图像保存为图片 [PICTURES]",
    "gui.blocklyText.pyTurtle.turtleSetAnimationInterval": "[NAME]设置动画播放时间间隔为[TIME]毫秒",
    "gui.blocklyText.pyTurtle.turtleDrawText": "[NAME] 书写 [TEXT]",
    "gui.blocklyText.pyTurtle.turtleDrawTextWithAttr": "[NAME] 书写 [TEXT] 画笔随文字移动[JUDGE] 文字对齐方向[ALIGN] 字体[FONT] 字号[FONTSIZE] 字形[TYPE]",
    "gui.blocklyText.pyTurtle.turtleGetFloat": "海龟获取输入的浮点数 标题为[TITLE] 提示语为[TIP] 默认值[VALUE] 最小值[MIN] 最大值[MAX]",
    "gui.blocklyText.pyTurtle.turtleGetString": "海龟获取输入的字符串 标题为[TITLE] 提示语为[TIP]",
    "gui.blocklyText.pyTurtle.roll_forward": "向前",
    "gui.blocklyText.pyTurtle.roll_backward": "向后",
    "gui.blocklyText.pyTurtle.roll_l_left": "向左",
    "gui.blocklyText.pyTurtle.roll_l_right": "向右",
    "gui.blocklyText.pyTurtle.xy_x": "X",
    "gui.blocklyText.pyTurtle.xy_y": "Y",
    "gui.blocklyText.pyTurtle.status_pos": "位置",
    "gui.blocklyText.pyTurtle.status_shape": "形状",
    "gui.blocklyText.pyTurtle.status_heading": "朝向",
    "gui.blocklyText.pyTurtle.status_width": "画笔宽度",
    "gui.blocklyText.pyTurtle.status_shapesize": "海龟图标大小",
    "gui.blocklyText.pyTurtle.status_speed": "速度",
    "gui.blocklyText.pyTurtle.action_clear": "清屏",
    "gui.blocklyText.pyTurtle.action_reset": "重置",
    "gui.blocklyText.pyTurtle.action_home": "返回原点",
    "gui.blocklyText.pyTurtle.pen_action_penup": "抬笔",
    "gui.blocklyText.pyTurtle.pen_action_pendown": "落笔",
    "gui.blocklyText.pyTurtle.visible_hideturtle": "隐藏海龟",
    "gui.blocklyText.pyTurtle.visible_showturtle": "显示海龟",
    "gui.blocklyText.pyTurtle.visible_turtle": "海龟",
    "gui.blocklyText.pyTurtle.visible_arrow": "箭头",
    "gui.blocklyText.pyTurtle.visible_circle": "圆形",
    "gui.blocklyText.pyTurtle.visible_square": "方块",
    "gui.blocklyText.pyTurtle.visible_triangle": "三角形",
    "gui.blocklyText.pyTurtle.visible_classic": "默认",
    "gui.blocklyText.pyTurtle.drawaction_begin": "开始填充",
    "gui.blocklyText.pyTurtle.drawaction_end": "结束填充",
    "gui.blocklyText.pyTurtle.circle_circle": "圆",
    "gui.blocklyText.pyTurtle.circle_dot": "点",
    "gui.blocklyText.pyTurtle.flag_False": "否",
    "gui.blocklyText.pyTurtle.flag_True": "是",
    "gui.blocklyText.pyTurtle.align_left": "居左",
    "gui.blocklyText.pyTurtle.align_center": "居中",
    "gui.blocklyText.pyTurtle.align_right": "居右",
    "gui.blocklyText.pyTurtle.align_normal": "标准",
    "gui.blocklyText.pyTurtle.align_bold": "加粗",
    "gui.blocklyText.pyTurtle.align_italic": "倾斜",
    "gui.blocklyText.pyTurtle.align_bi": "倾斜加粗",
    "gui.blocklyText.pyTurtle.turtleScreenSetup": "初始化海龟窗口大小 宽度[WIDTH] 高度[HEIGHT] 偏移 X [LEFT] Y [TOP]",
    "gui.blocklyText.pyTurtle.turtleScreenSize": "初始化海龟画布 宽度[WIDTH] 高度[HEIGHT] 背景颜色[COLOR]",
    "gui.blocklyText.pyGenerator.importModule": "import [NAME]",
    "gui.blocklyText.pyGenerator.importAs": "import [MOUDLE] as [NAME]",
    "gui.blocklyText.pyGenerator.fromImport": "from [FROM] import [MOUDLE]",
    "gui.blocklyText.pyGenerator.annotation": "# [TEXT]",
    "gui.blocklyText.pyGenerator.defineFunction": "def [FUNCTION]([ARGUMENT]):",
    "gui.blocklyText.pyGenerator.callFunction": "调用函数[FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.callFunctionWithValue": "调用函数获取返回值[FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.defineClass": "class [CLASS]:",
    "gui.blocklyText.pyGenerator.instanceClass": "[CLASS] [INSTANCE]",
    "gui.blocklyText.pyGenerator.reporterType": "Code [CODE]",
    "gui.blocklyText.pyGenerator.typeBoolean": "Code [CODE]",
    "gui.blocklyText.pyGenerator.annotationBlock": "块注释",
    "gui.blocklyText.pyGenerator.originCode": "Code [CODE]",
    "gui.extension.xiamiboard.title": "虾米扩展板",
    "gui.extension.xiamiboard.setMotor": "设置电机[Select]方向[Direction]速度[Speed]",
    "gui.extension.xiamiboard.stopMotor": "停止电机[Select]",
    "gui.extension.xiamiboard.setTrafficLights": "设置交通灯 红[Red] 黄[Yellow] 绿[Green]",
    "gui.extension.xiamiboard.readAngle": "读取角度传感器数据",
    "gui.extension.xiamiboard.readFlame": "读取火焰传感器数据",
    "gui.extension.xiamiboard.readMotorState": "读取电机[Select]状态[State]",
    "gui.extension.xiamiboard.readTrafficLights": "读取交通灯状态[State]",
    "gui.extension.xiamiboard.readRelay": "设置继电器[State]",
    "gui.extension.xiamiboard.readUltrasonicDistance": "读取超声波距离(cm)",
    "gui.extension.xiamiboard.initializationScreen": "OLED 初始化",
    "gui.extension.xiamiboard.screenDisplay": "OLED 显示文字[Text]在坐标x[Row]y[Column]",
    "gui.extension.xiamiboard.clearScreenAll": "OLED 清屏",
    "gui.extension.xiamiboard.clearScreen": "OLED 清除第[Row]行 从列[ColumnFrom]到列[ColumnTo]",
    "gui.extension.xiamiboard.readInfrared": "读取红外键值",
    "gui.extension.xiamiboard.receiveData": "当接收到[Data]",
    "gui.extension.xiamiboard.wirelessData": "红外数据",
    "gui.extension.xiamiboard.xiamiRGB": "红[Red]绿[Green]蓝[Blue]",
    "gui.extension.xiamiboard.setRGBLightsRange": "设置RGB灯点亮[Range] 显示颜色[Color]",
    "gui.extension.xiamiboard.setLightsColor": "设置RGB灯[Num]显示颜色[Color]",
    "gui.extension.xiamiboard.setLightsBrightness": "设置RGB灯亮度[Brightness]",
    "gui.extension.xiamiboard.coloseLightsAll": "关闭全部RGB灯",
    "gui.extension.xiamiboard.initializeST": "初始化 [TYPE] 温湿度传感器",
    "gui.extension.xiamiboard.readSTState": "读取 [TYPE] [State]",
    "gui.blocklyText.xiamiboard.all": "全部",
    "gui.blocklyText.xiamiboard.cw": "正转",
    "gui.blocklyText.xiamiboard.ccw": "反转",
    "gui.blocklyText.xiamiboard.speed": "速度",
    "gui.blocklyText.xiamiboard.dir": "方向",
    "gui.blocklyText.xiamiboard.temp": "温度",
    "gui.blocklyText.xiamiboard.hum": "湿度",
    "gui.blocklyText.xiamiboard.colorRed": "红色",
    "gui.blocklyText.xiamiboard.colorYellow": "黄色",
    "gui.blocklyText.xiamiboard.colorGreen": "绿色",
    "gui.blocklyText.xiamiboard.close": "闭合",
    "gui.blocklyText.xiamiboard.disconnect": "断开",
    "gui.blocklyText.xiamiboard.lightAll": "全部",
    "gui.extension.pyMqtt.initMqtt": "初始化MQTT[SETTING]",
    "gui.extension.pyMqtt.connect": "MQTT发起连接",
    "gui.extension.pyMqtt.loop_forever": "MQTT保持连接,永久",
    "gui.extension.pyMqtt.loopNeverForever": "MQTT保持连接,超时时间[Time]秒",
    "gui.extension.pyMqtt.disconnect": "MQTT断开连接",
    "gui.extension.pyMqtt.pushlish": "MQTT发布[INFO]到[Topic]",
    "gui.extension.pyMqtt.pushlish_save": "MQTT发布[INFO]到[Topic]并存数据库 (SIoT V2)",
    "gui.extension.pyMqtt.subscribe": "MQTT订阅[Topic]",
    "gui.extension.pyMqtt.received": "当MQTT从[Topic]接收到[MqttInfo]",
    "gui.extension.pyMqtt.topic": "MQTT主题",
    "gui.extension.pyMqtt.mqttInfo": "MQTT信息",
    "gui.blocklyText.pyMqtt.pyMqttInitPlatform": "MQTT平台",
    "gui.blocklyText.pyMqtt.EasyIOT_Server": "EasyIoT服务器",
    "gui.blocklyText.pyMqtt.EasyIOTServerChina": "EasyIoT 中国",
    "gui.blocklyText.pyMqtt.EasyIOTServerGlobal": "EasyIoT 全球",
    "gui.blocklyText.pyMqtt.EasyIOT_Port": "EasyIoT Port",
    "gui.blocklyText.pyMqtt.EasyIOT_id": "EasyIoT ID",
    "gui.blocklyText.pyMqtt.siotServer": "SIoT服务器",
    "gui.blocklyText.pyMqtt.siotServerPort": "SIoT Port",
    "gui.blocklyText.pyMqtt.siotServerUser": "SIoT 用户名",
    "gui.blocklyText.pyMqtt.siotServerPass": "SIoT 密码",
    "gui.blocklyText.microNaturalScienceV2.IOT_Username": "IOT_ID(用户)",
    "gui.blocklyText.microNaturalScienceV2.IOT_Password": "IOT_PWD(密码)",
    "gui.blocklyText.microNaturalScienceV2.IOT_topic": "Topic(默认topic_0)",
    "gui.extension.pyGame.pygameModule": "Pygame 模块",
    "gui.extension.pyGame.pygameInit": "初始化所有导入的 pygame 模块",
    "gui.extension.pyGame.pygameQuit": "卸载所有已初始化的 pygame 模块",
    "gui.extension.pyGame.displayModule": "Display 模块",
    "gui.extension.pyGame.displayInit": "初始化display模块",
    "gui.extension.pyGame.displayQuit": "卸载已初始化的display模块",
    "gui.extension.pyGame.windowInit": "初始化[Width]x[Height] [Flags]的窗口[Win]",
    "gui.extension.pyGame.windowUpdate": "更新窗口(部分软件)界面显示",
    "gui.extension.pyGame.windowSetIcon": "设置窗口图标[Value]",
    "gui.extension.pyGame.windowSetTitle": "设置窗口标题[Value]",
    "gui.extension.pyGame.windowFlip": "更新Surface对象至屏幕",
    "gui.extension.pyGame.windowDraw": "将[Suf]绘制到[Win]窗口的[Postion]",
    "gui.extension.pyGame.surfaceObject": "Surface对象",
    "gui.extension.pyGame.createSurface": "创建[Width]x[Height]的Surface对象[Surface]",
    "gui.extension.pyGame.blitSurface": "将[Obj]绘制到Surface对象[Surface]的[Pos]",
    "gui.extension.pyGame.fillSurface": "用[Color]填充Surface对象[Surface]",
    "gui.extension.pyGame.surfaceSetAt": "将Surface对象[Surface]的[Pos]像素点设置成[Color]",
    "gui.extension.pyGame.surfaceGetAt": "获取Surface对象[Surface]的[Pos]像素点的颜色",
    "gui.extension.pyGame.surfaceGetWidth": "获取Surface对象[Surface]的宽度",
    "gui.extension.pyGame.surfaceGetHeight": "获取Surface对象[Surface]的高度",
    "gui.extension.pyGame.surfaceGetRect": "获取Surface对象[Surface]的矩形区域",
    "gui.extension.pyGame.surfaceMove": "Surface对象[Surface]的偏移速度为[CONTENT]",
    "gui.extension.pyGame.eventModule": "Event 模块",
    "gui.extension.pyGame.windowEvent": "获取窗口事件",
    "gui.extension.pyGame.eventType": "[Event]事件类型",
    "gui.extension.pyGame.eventKey": "[Event][Mod]",
    "gui.extension.pyGame.event": "[Event]事件",
    "gui.extension.pyGame.eventConstant": "[Constant]键",
    "gui.extension.pyGame.eventModKey": "[Constant]组合键",
    "gui.extension.pyGame.eventMod": "检测[Keys]组合键是否被按下",
    "gui.extension.pyGame.eventOr": "[Key1]|[Key2]",
    "gui.extension.pyGame.fontModule": "Font 模块",
    "gui.extension.pyGame.fontInit": "初始化font模块",
    "gui.extension.pyGame.fontQuit": "卸载已初始化的font模块",
    "gui.extension.pyGame.createFont": "从[Font]创建[Size][Source]字体对象",
    "gui.extension.pyGame.getDefaultFont": "获取系统字体的文件名",
    "gui.extension.pyGame.getCanUsedFont": "获取系统可使用的字体列表",
    "gui.extension.pyGame.generationFont": "通过字体对象[Font]创建抗锯齿[Antialias]颜色[Color]透明度[Opacity]的文本[Text]",
    "gui.extension.pyGame.renderStyle": "设置字体[Font]样式 [Bold] [Italics] [Underline]",
    "gui.extension.pyGame.imageModule": "Image 模块",
    "gui.extension.pyGame.imageLoad": "从[FileName]加载图片",
    "gui.extension.pyGame.supportExtension": "判断加载的图片是不是支持的扩展?",
    "gui.extension.pyGame.imageSave": "保存[Img]为[FileName]图片",
    "gui.extension.pyGame.drawModule": "Draw 模块",
    "gui.extension.pyGame.drawRect": "绘制颜色为[Color]配置[Rect]边框粗细[Width]的矩形到[Win]",
    "gui.extension.pyGame.drawCircle": "绘制颜色为[Color]位置[Rect]半径[Size]边框粗细[Width]的圆形到[Win]",
    "gui.extension.pyGame.drawLine": "绘制颜色为[Color]起始位置[start_pos]结束位置[end_pos]粗细[Width]的线到[Win]",
    "gui.extension.pyGame.drawLines": "绘制颜色为[Color]闭合[Closed]位置[Pointlist]粗细[Width]的多条线到[Win]",
    "gui.extension.pyGame.mixerModule": "Mixer 模块",
    "gui.extension.pyGame.mixerInit": "初始化mixer模块",
    "gui.extension.pyGame.mixerQuit": "卸载已初始化的mixer模块",
    "gui.extension.pyGame.createSound": "从[FileName]加载声音对象",
    "gui.extension.pyGame.soundPlay": "声音[Sound]播放",
    "gui.extension.pyGame.soundStop": "声音[Sound]停止",
    "gui.extension.pyGame.soundFadeout": "设置声音[Sound]淡出(逐渐减弱直到消失)的时间[Time](ms)",
    "gui.extension.pyGame.soundSetVolume": "设置声音[Sound]音量[Value]%",
    "gui.extension.pyGame.soundGetbusy": "声音是否还在播放?",
    "gui.extension.pyGame.timeModule": "Time 模块",
    "gui.extension.pyGame.getRuntime": "获取运行时间(毫秒)",
    "gui.extension.pyGame.pauseWay": "用[Way]暂停[Time](毫秒)",
    "gui.extension.pyGame.setTimer": "创建[Time](毫秒)后[Once][Event]事件的定时器",
    "gui.extension.pyGame.clockInit": "初始化时钟[Clock]",
    "gui.extension.pyGame.updateClock": "更新时钟[Clock]",
    "gui.extension.pyGame.getClockUp": "获取[Clock]上一个刻度中使用的时间",
    "gui.extension.pyGame.getClockUpRaw": "获取[Clock]上一个刻度中使用的实际时间",
    "gui.extension.pyGame.getClockFps": "获取[Clock]计算时钟帧率",
    "gui.extension.pyGame.musicModule": "Music 模块",
    "gui.extension.pyGame.musicLoad": "从[FileName]加载音乐对象",
    "gui.extension.pyGame.setVolume": "设置音乐音量[Value]%",
    "gui.extension.pyGame.musicPlay": "音乐播放",
    "gui.extension.pyGame.musicRewind": "音乐重新播放",
    "gui.extension.pyGame.musicPause": "音乐暂停",
    "gui.extension.pyGame.musicUnpause": "音乐恢复播放",
    "gui.extension.pyGame.musicStop": "音乐停止",
    "gui.extension.pyGame.musicFadeout": "设置音乐淡出(逐渐减弱直到消失)的时间[Time](ms)",
    "gui.extension.pyGame.musicGetbusy": "音乐是否还在播放?",
    "gui.extension.pyGame.colorModule": "Color 模块",
    "gui.extension.pyGame.color": "[Color]转RGB",
    "gui.blocklyText.pyGame.gain": "焦点状态",
    "gui.blocklyText.pyGame.state": "程序状态",
    "gui.blocklyText.pyGame.unicode": "按键名称",
    "gui.blocklyText.pyGame.key": "按键值",
    "gui.blocklyText.pyGame.mod": "按键模式",
    "gui.blocklyText.pyGame.pos": "鼠标定位",
    "gui.blocklyText.pyGame.rel": "鼠标移动的相对位置",
    "gui.blocklyText.pyGame.buttons": "鼠标按键",
    "gui.blocklyText.pyGame.size": "窗口大小",
    "gui.blocklyText.pyGame.w": "窗口宽度",
    "gui.blocklyText.pyGame.h": "窗口高度",
    "gui.blocklyText.pyGame.once": "运行一次",
    "gui.blocklyText.pyGame.repeat": "重复运行",
    "gui.blocklyText.pyGame.close": "闭合",
    "gui.blocklyText.pyGame.noclose": "不闭合",
    "gui.blocklyText.pyGame.fullscreen": "全屏显示",
    "gui.blocklyText.pyGame.doublebuf": "双缓冲",
    "gui.blocklyText.pyGame.hwsurface": "硬件加速",
    "gui.blocklyText.pyGame.opengl": "渲染显示",
    "gui.blocklyText.pyGame.resizable": "窗口适应",
    "gui.blocklyText.pyGame.noframe": "无框架",
    "gui.blocklyText.pyGame.none": "无",
    "gui.blocklyText.pyGame.external": "自定义",
    "gui.blocklyText.pyGame.system": "系统",
    "gui.blocklyText.pyGame.normal": "正常",
    "gui.blocklyText.pyGame.italics": "斜体",
    "gui.blocklyText.pyGame.bold": "加粗",
    "gui.blocklyText.pyGame.underline": "下划线",
    "gui.blocklyText.pyGame.wait": "等待",
    "gui.blocklyText.pyGame.delay": "延迟",
    "gui.blocklyText.pyGame.false": "False",
    "gui.blocklyText.pyGame.true": "True",
    "gui.blocklyText.pyGame.quit": "窗口关闭",
    "gui.blocklyText.pyGame.activeevent": "窗口被激活或者隐藏",
    "gui.blocklyText.pyGame.keydown": "键盘按下",
    "gui.blocklyText.pyGame.keyup": "键盘松开",
    "gui.blocklyText.pyGame.mousemotion": "鼠标移动",
    "gui.blocklyText.pyGame.mousebuttonup": "鼠标松开",
    "gui.blocklyText.pyGame.mousebuttondown": "鼠标按下",
    "gui.blocklyText.pyGame.videoresize": "窗口缩放",
    "gui.blocklyText.pyGame.videoexpose": "窗口部分公开(expose)",
    "gui.blocklyText.pyGame.insert": "插入",
    "gui.blocklyText.pyGame.home": "起始",
    "gui.blocklyText.pyGame.end": "结束",
    "gui.blocklyText.pyGame.pageup": "上一页",
    "gui.blocklyText.pyGame.pagedown": "下一页",
    "gui.blocklyText.pyGame.numlock": "数字键盘锁定",
    "gui.blocklyText.pyGame.capslock": "大写锁定",
    "gui.blocklyText.pyGame.scrollock": "滚动锁",
    "gui.blocklyText.pyGame.rightshift": "右Shift",
    "gui.blocklyText.pyGame.leftshift": "左Shift",
    "gui.blocklyText.pyGame.rightcontrol": "右Control",
    "gui.blocklyText.pyGame.leftcontrol": "左Control",
    "gui.blocklyText.pyGame.rightalt": "右Alt",
    "gui.blocklyText.pyGame.leftalt": "左Alt",
    "gui.blocklyText.pyGame.rightmeta": "Right Meta",
    "gui.blocklyText.pyGame.leftmeta": "Left Meta",
    "gui.blocklyText.pyGame.leftWindowskey": "左Windows",
    "gui.blocklyText.pyGame.rightWindowskey": "右Windows",
    "gui.blocklyText.pyGame.modeshift": "mode Shift",
    "gui.blocklyText.pyGame.help": "帮助",
    "gui.blocklyText.pyGame.printscreen": "屏幕截图",
    "gui.blocklyText.pyGame.sysrq": "sysrq",
    "gui.blocklyText.pyGame.break": "中断暂停",
    "gui.blocklyText.pyGame.menu": "功能调节菜单",
    "gui.blocklyText.pyGame.power": "电源",
    "gui.blocklyText.pyGame.Euro": "Euro",
    "gui.blocklyText.pyGame.delete": "删除",
    "gui.blocklyText.pyGame.backspace": "回格",
    "gui.blocklyText.pyGame.tab": "制表",
    "gui.blocklyText.pyGame.clear": "清除",
    "gui.blocklyText.pyGame.return": "回车",
    "gui.blocklyText.pyGame.pause": "暂停",
    "gui.blocklyText.pyGame.escape": "ESC",
    "gui.blocklyText.pyGame.space": "空格",
    "gui.blocklyText.pyGame.quotedbl": "双引号",
    "gui.blocklyText.pyGame.quote": "单引号",
    "gui.blocklyText.pyGame.NONE": "无",
    "gui.blocklyText.pyGame.LSHIFT": "左Shift",
    "gui.blocklyText.pyGame.RSHIFT": "右Shift",
    "gui.blocklyText.pyGame.SHIFT": "Shift",
    "gui.blocklyText.pyGame.LCTRL": "左Ctrl",
    "gui.blocklyText.pyGame.RCTRL": "右Ctrl",
    "gui.blocklyText.pyGame.CTRL": "Ctrl",
    "gui.blocklyText.pyGame.LALT": "左Alt",
    "gui.blocklyText.pyGame.RALT": "右Alt",
    "gui.blocklyText.pyGame.ALT": "Alt",
    "gui.blocklyText.pyGame.LMETA": "左Meta",
    "gui.blocklyText.pyGame.RMETA": "右Meta",
    "gui.blocklyText.pyGame.META": "Meta",
    "gui.blocklyText.pyGame.CAPS": "大写锁定",
    "gui.blocklyText.pyGame.NUM": "数字键盘锁定",
    "gui.blocklyText.pyGame.MODE": "AltGr",
    "gui.extension.pinpongBase.pinpongInit": "pinpong初始化 板型[Board]端口[Port]",
    "gui.extension.pinpongBase.pinpongUpdateFirmware": "pinpong更新固件 板型[Board]端口[Port]",
    "gui.extension.pinpongBase.pinInit": "[Instance]pin初始化 引脚号[Pin] 模式[Mod]",
    "gui.extension.pinpongBase.readPinValue": "[Instance]读数字值",
    "gui.extension.pinpongBase.setPinValue": "[Instance]设置数字输出 值[Value]",
    "gui.extension.pinpongBase.readAnalogValue": "[Instance]读模拟值",
    "gui.extension.pinpongBase.setAnalogValue": "[Instance]设置模拟输出(PWM) 值[Value]",
    "gui.blocklyText.pinpongBase.uno": "Arduino UNO",
    "gui.blocklyText.pinpongBase.leonardo": "Arduino Leonardo",
    "gui.blocklyText.pinpongBase.nano": "Arduino Nano",
    "gui.blocklyText.pinpongBase.mega1280": "Arduino Mega1280",
    "gui.blocklyText.pinpongBase.mega2560": "Arduino Mega2560",
    "gui.blocklyText.pinpongBase.microbit": "micro:bit",
    "gui.blocklyText.pinpongBase.handpy": "掌控板",
    "gui.blocklyText.pinpongBase.xugu": "虚谷号",
    "gui.blocklyText.pinpongBase.auto": "自动识别",
    "gui.blocklyText.pinpongBase.unihiker": "行空板",
    "gui.blocklyText.pinpongBase.rpi": "树莓派",
    "gui.blocklyText.pinpongBase.firebeetle_esp32": "Firebeetle ESP32",
    "gui.blocklyText.pinpongBase.firebeetle_esp8266": "Firebeetle ESP8266",
    "gui.blocklyText.pinpongBase.lp": "LattePanda",
    "gui.blocklyText.pinpongBase.esp32": "ESP32",
    "gui.blocklyText.pinpongBase.esp8266": "ESP8266",
    "gui.blocklyText.pinpongBase.nezha": "哪吒板",
    "gui.blocklyText.pinpongBase.JH7100": "JH7100",
    "gui.blocklyText.pinpongBase.win": "win",
    "gui.blocklyText.pinpongBase.out": "数字输出",
    "gui.blocklyText.pinpongBase.in": "数字输入",
    "gui.blocklyText.pinpongBase.analog": "模拟输入",
    "gui.blocklyText.pinpongBase.pwm": "模拟输出",
    "gui.blocklyText.pinpongBase.null": "无",
    "gui.blocklyText.pinpongServo.name": "舵机",
    "gui.extension.pinpongServo.servoInit": "舵机[Servo] 初始化引脚[Pin]",
    "gui.extension.pinpongServo.setServo": "舵机[Servo]转动到[Deg]度位置",
    "gui.blocklyText.pinpongIrq.name": "中断",
    "gui.extension.pinpongIrq.setIrq": "设置引脚[Pin]中断[Trigger]回调函数[Handler]",
    "gui.extension.pinpongIrq.INTevent": "当回调函数[Event]触发 参数[Pin]",
    "gui.extension.pinpongIrq.pinInfo": "pin",
    "gui.blocklyText.pinpongIrq.rising": "上升沿",
    "gui.blocklyText.pinpongIrq.falling": "下降沿",
    "gui.blocklyText.pinpongIrq.change": "电平变化",
    "gui.blocklyText.pinpongTone.name": "蜂鸣器",
    "gui.extension.pinpongTone.toneInit": "蜂鸣器[Tone] 初始化引脚[Pin]",
    "gui.extension.pinpongTone.setToneFreq": "设置蜂鸣器[Tone]频率[Freq]",
    "gui.extension.pinpongTone.readToneFreq": "读取蜂鸣器[Tone]频率",
    "gui.extension.pinpongTone.onTone": "打开蜂鸣器[Tone]",
    "gui.extension.pinpongTone.offTone": "关闭蜂鸣器[Tone]",
    "gui.extension.pinpongTone.keep": "蜂鸣器[Tone]以[Freq]频率持续运行[Time]毫秒",
    "gui.blocklyText.pinpongSr04_urm10.name": "超声波",
    "gui.extension.pinpongSr04_urm10.sr04_urm10Init": "超声波传感器[Sr04_urm10]初始化 trig引脚[Triger] echo引脚[Echo]",
    "gui.extension.pinpongSr04_urm10.readSr04_urm10": "读取超声波传感器[Sr04_urm10]距离(cm)",
    "gui.extension.pinpongDht.dhtInit": "初始化温湿度传感器[Dht]型号[DhtMod] 引脚[Pin]",
    "gui.extension.pinpongDht.read": "[Dht]读取[Type]",
    "gui.extension.pinpongDht.Temperature": "温度(℃)",
    "gui.extension.pinpongDht.Humidity": "湿度(%rh)",
    "gui.blocklyText.pinpongNeopixel.name": "灯带",
    "gui.extension.pinpongNeopixel.neopixelInit": "初始化 RGB灯[Np] 引脚[Pin]灯数[Num]",
    "gui.extension.pinpongNeopixel.brightness": "RGB灯[Np] 设置亮度[BRIGHTNESS]",
    "gui.extension.pinpongNeopixel.neopixelClear": "RGB灯[Np] 全部熄灭",
    "gui.extension.pinpongNeopixel.setNeopixel": "[Np]设置第[No]个灯的颜色[Color]",
    "gui.extension.pinpongNeopixel.showColorFromWith": "RGB灯[Np] 灯号 [NUM1] 到 [NUM2] 显示颜色 [COLOR]",
    "gui.extension.pinpongNeopixel.color": "红[Red]绿[Green]蓝[Blue]",
    "gui.extension.pinpongNeopixel.showGradientColor": "RGB灯[Np] 灯号 [NUM1] 到 [NUM2] 显示渐变颜色 色调从 [COLOR1] 到 [COLOR2]",
    "gui.extension.pinpongNeopixel.shiftPixelsToShow": "RGB灯[Np] 移动 [NUM] 单位",
    "gui.extension.pinpongNeopixel.rotatePixelsToShow": "RGB灯[Np] 循环移动 [NUM] 单位",
    "gui.extension.pinpongNeopixel.showHistogram": "RGB灯[Np] 灯号 [NUM1] 到 [NUM2] 显示柱状图 当前值 [VALUE] 最大值 [MAX]",
    "gui.extension.pinpongOled12864.oled12864Init": "OLED显示屏[Oled]初始化 宽度[Width]高度：8×[Height]",
    "gui.extension.pinpongOled12864.oled12864Fill": "[Oled]全屏[Option]",
    "gui.extension.pinpongOled12864.oled12864ShowString": "[Oled]在坐标x[X]y[Y] 显示文本[String]",
    "gui.extension.pinpongOled12864.show": "[Oled]显示生效",
    "gui.blocklyText.pinpongOled12864.display": "填充",
    "gui.blocklyText.pinpongOled12864.hide": "熄灭",
    "gui.extension.pinpongDS18B20.ds18b20Init": "温度传感器[Ds18b20]初始化 引脚[Pin]",
    "gui.extension.pinpongDS18B20.readCelsius": "读取[Ds18b20]温度(℃)",
    "gui.extension.pinpongMlx90614.mlx90614Init": "红外测温传感器[IRT]初始化 地址[ADDR]",
    "gui.extension.pinpongMlx90614.read": "红外测温传感器[OBJ]读取[TYPE] [UINT]",
    "gui.extension.pinpongBmp388.BMP388Init": "BMP388[BMP]初始化  I2C地址0x77",
    "gui.extension.pinpongBmp388.read": "BMP388[BMP]读取[TYPE]",
    "gui.blocklyText.pinpongBmp388.altitude": "海拔高度(m)",
    "gui.blocklyText.pinpongBmp388.pressure": "大气压强(Pa)",
    "gui.blocklyText.pinpongBmp388.temperature": "温度(℃)",
    "gui.blocklyText.pinpongIRRecv.name": "红外接收",
    "gui.extension.pinpongIRRecv.iRRecvInit": "红外接收[IRRecv]初始化 引脚[Pin] 回调函数[Event]",
    "gui.extension.pinpongIRRecv.onReceive": "当回调函数[Event]接收到[Data]",
    "gui.extension.pinpongIRRecv.data": "红外信号",
    "gui.blocklyText.PinpongBoard.name": "PinPong 扩展板",
    "gui.extension.PinpongBoard.connectWifi": "PinPong扩展板连接热点[ssid]密码[password]直到成功",
    "gui.extension.PinpongBoard.getIp": "获取Wi-Fi配置IP地址",
    "gui.extension.PinpongBoard.TCPInit": "PinPong扩展板初始化WIFI[ip] 端口8081",
    "gui.extension.PinpongBoard.USBInit": "PinPong扩展板初始化USB COM口[port]",
    "gui.extension.PinpongBoard.LEDInit": "初始化板载LED灯(D2)",
    "gui.extension.PinpongBoard.LEDSetValue": "设置LED灯输出为[value]",
    "gui.blocklyText.PinpongBoard.high": "高电平",
    "gui.blocklyText.PinpongBoard.low": "低电平",
    "gui.blocklyText.PinpongBoard.temp": "温度(℃)",
    "gui.blocklyText.PinpongBoard.humi": "湿度(%RH)",
    "gui.blocklyText.PinpongBoard.ledControl": "LED灯控制",
    "gui.blocklyText.PinpongBoard.toneControl": "蜂鸣器控制",
    "gui.extension.PinpongBoard.toneInit": "初始化蜂鸣器(D4)",
    "gui.extension.PinpongBoard.toneSetFreq": "蜂鸣器设置频率[Freq]Hz",
    "gui.extension.PinpongBoard.toneSwitch": "蜂鸣器[switch]",
    "gui.blocklyText.PinpongBoard.on": "打开",
    "gui.blocklyText.PinpongBoard.off": "关闭",
    "gui.extension.PinpongBoard.toneGetFreq": "蜂鸣器获取频率",
    "gui.extension.PinpongBoard.keep": "蜂鸣器以[Freq]频率(Hz)持续运行[Time]毫秒",
    "gui.blocklyText.PinpongBoard.buttonControl": "按键控制",
    "gui.extension.PinpongBoard.buttonInit": "初始化引脚号[PIN]模式数字输入",
    "gui.extension.PinpongBoard.buttonGetVlue": "读取[PIN]引脚电平",
    "gui.blocklyText.PinpongBoard.RGBControl": "RGB灯控制",
    "gui.extension.PinpongBoard.RGBInit": "初始化RGB灯(D9)",
    "gui.extension.PinpongBoard.setRGB": "设置RGB灯颜色[Color]",
    "gui.extension.PinpongBoard.setRGBBrightness": "设置RGB灯亮度为 [BRIGHTNESS]",
    "gui.extension.PinpongBoard.closeRGB": "关闭RGB灯",
    "gui.blocklyText.PinpongBoard.irControl": "红外接收",
    "gui.extension.PinpongBoard.irInit": "初始化红外接收回调函数[Event]",
    "gui.blocklyText.PinpongBoard.angleSensor": "角度传感器",
    "gui.extension.PinpongBoard.angleInit": "初始化角度传感器(A3)",
    "gui.extension.PinpongBoard.getAngleValue": "读取角度传感器的值",
    "gui.blocklyText.PinpongBoard.lightSensor": "光线传感器",
    "gui.extension.PinpongBoard.lightInit": "初始化光线传感器(A2)",
    "gui.extension.PinpongBoard.getLightValue": "读取光线传感器的值",
    "gui.blocklyText.PinpongBoard.OLEDscreen": "OLED显示屏",
    "gui.extension.PinpongBoard.oled12864Init": "初始化OLED显示屏",
    "gui.extension.PinpongBoard.oled12864Fill": "OLED屏幕显示为[Option](清屏)",
    "gui.extension.PinpongBoard.oled12864ShowString": "OLED在第[X]行 显示[String]",
    "gui.extension.PinpongBoard.oled12864ShowStringXY": "OLED在坐标X:[X]Y:16*[Y] 显示[String]",
    "gui.extension.PinpongBoard.oledCal": "校准数据 [TEXT] [BIT]位",
    "gui.extension.PinpongBoard.show": "OLED显示生效",
    "gui.blocklyText.PinpongBoard.black": "黑色",
    "gui.blocklyText.PinpongBoard.white": "白色",
    "gui.blocklyText.PinpongBoard.aht20": "温湿度传感器",
    "gui.extension.PinpongBoard.aht20Init": "初始化温湿度传感器",
    "gui.extension.PinpongBoard.getTempValue": "读取温度值(°C)",
    "gui.extension.PinpongBoard.getHumiValue": "读取湿度值(%rh)",
    "gui.blocklyText.PinpongBoard.mpu6050": "MPU6050传感器",
    "gui.extension.PinpongBoard.mpu6050Init": "初始化MPU6050传感器",
    "gui.extension.PinpongBoard.getAccValue": "读取加速计[Option]的值(a/g)",
    "gui.extension.PinpongBoard.getGyroValue": "读取陀螺仪[Option]的值(a/g)",
    "gui.blocklyText.PinpongBoard.motor": "电机控制",
    "gui.extension.PinpongBoard.motorInit": "初始化电机",
    "gui.extension.PinpongBoard.getIrValue": "读取红外传感器的值",
    "gui.extension.PinpongBoard.LightsBright": "RGB灯设置亮度为[BRIGHTNESS]",
    "gui.extension.PinpongBoard.clear": "RGB灯熄灭",
    "gui.extension.PinpongBoard.RGBShowColorFromWith": "RGB灯显示颜色[COLOR]",
    "gui.extension.PinpongBoard.readSHTC3": "读取SHTC3[VALUE]值",
    "gui.extension.PinpongBoard.readPitch": "读取俯仰角的值",
    "gui.extension.PinpongBoard.readYaw": "读取偏航角的值",
    "gui.extension.PinpongBoard.readRoll": "读取翻滚角的值",
    "gui.blocklyText.obloqMp.WhenReceivedData": "当接收到 [EventType] 的 [MSG]",
    "gui.blocklyText.obloqMp.ObloqEventType": "Obloq事件类型",
    "gui.blocklyText.obloqMp.ObloqMsg": "Obloq信息",
    "gui.blocklyText.obloqMp.initialParameter": "Obloq mqtt初始化 [PARAMETER] Rx(绿) [PINR] Tx(蓝) [PINT]",
    "gui.blocklyText.obloqMp.initialI2CParameter": "Obloq mqtt 初始化 [PARAMETER] 接口I2C 回调函数[Event]",
    "gui.blocklyText.obloqMp.subscribe": "Obloq 订阅 [TOPIC]",
    "gui.blocklyText.obloqMp.sendmsgToCloudPlatform": "Obloq 发送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.obloqMp.whenDebugInfo": "Obloq 获取 [DEBUG] [ERROR]",
    "gui.blocklyText.obloqMp.DEBUG": "调试信息",
    "gui.blocklyText.obloqMp.ERROR": "错误码",
    "gui.blocklyText.obloqMp.httpInitialParameter": "Obloq http初始化 [PARAMETER] Rx(绿) [PINR] Tx(蓝) [PINT]",
    "gui.blocklyText.obloqMp.httpInitialI2CParameter": "Obloq http 初始化 [PARAMETER] 接口I2C",
    "gui.blocklyText.obloqMp.httpGet": "Obloq http(get) url: [URL] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.wifiGetLocalIP": "获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.obloqMp.getWiFiLocalIP": "IP地址",
    "gui.blocklyText.obloqMp.wifi": "Wi-Fi",
    "gui.blocklyText.obloqMp.SSID": "Wi-Fi名称",
    "gui.blocklyText.obloqMp.PWD": "Wi-Fi密码",
    "gui.blocklyText.obloqMp.iot": "平台",
    "gui.blocklyText.obloqMp.EasyIOT_Server": "Easy物联平台",
    "gui.blocklyText.obloqMp.EasyIOTServerChina": "中国",
    "gui.blocklyText.obloqMp.EasyIOTServerGlobal": "全球",
    "gui.blocklyText.obloqMp.Port": "端口",
    "gui.blocklyText.obloqMp.Username": "用户名",
    "gui.blocklyText.obloqMp.Password": "密码",
    "gui.blocklyText.obloqMp.siotServer": "Siot物联平台",
    "gui.blocklyText.serialPort.whenReceivedData": "当接收到串口消息 [MESSAGE] [VALUE]",
    "gui.blocklyText.serialPort.value": "值",
    "gui.blocklyText.serialPort.readSerialData": "读取串口 [MESSAGE] 的值",
    "gui.blocklyText.serialPort.serialPortSendMessage": "发送串口消息 [MESSAGE] 值 [VALUE]",
    "gui.extension.pyAutoGui.pyAutoGuiModule": "pyautogui 快捷功能",
    "gui.extension.pyAutoGui.init": "初始化pyautogui",
    "gui.extension.pyAutoGui.getPosition": "获取当前鼠标的坐标([X], [Y])",
    "gui.extension.pyAutoGui.getSize": "获取当前屏幕分辨率([Width], [Height])",
    "gui.extension.pyAutoGui.height": "高度",
    "gui.extension.pyAutoGui.width": "宽度",
    "gui.extension.pyAutoGui.onScreen": "判断坐标([X], [Y])是否在屏幕内",
    "gui.extension.pyAutoGui.setDelay": "设置PyAutoGUI调用延迟为[Delay]",
    "gui.extension.pyAutoGui.setFailSafe": "[Switch]故障安全模式",
    "gui.extension.pyAutoGui.mouse": "鼠标功能区",
    "gui.extension.pyAutoGui.mouseMoveToOrRel": "将鼠标移动到[Attribute]([X],[Y]) 延迟[Seconds]秒 设置样式[Style]",
    "gui.extension.pyAutoGui.mouseDragToOrRel": "将鼠标拖拽到[Attribute]([X],[Y]) 延迟[Seconds]秒 点击鼠标[Key]",
    "gui.extension.pyAutoGui.mouseClick": "将鼠标移动到([X], [Y]), 以间隔[Seconds]秒的速度鼠标[Key]点击[Frequency]次",
    "gui.extension.pyAutoGui.mouseDownOrUp": "将鼠标移动到([X], [Y]), 并[Action]鼠标[Key]",
    "gui.extension.pyAutoGui.mouseScroll": "鼠标滚动[NUM]个刻度",
    "gui.extension.pyAutoGui.keyboard": "键盘功能区",
    "gui.extension.pyAutoGui.keyboardWrite": "键盘以[NUM]秒的间隔输出[String]",
    "gui.extension.pyAutoGui.keyboardDownOrUp": "键盘点击[NUM]次[Key]键",
    "gui.extension.pyAutoGui.keyboardPress": "[Button][Key]",
    "gui.extension.pyAutoGui.keyboardHold": "保持按下[Key]",
    "gui.extension.pyAutoGui.keyboardHotkey": "按下快捷键(组合键)[Key]",
    "gui.extension.pyAutoGui.message": "信息框功能区",
    "gui.extension.pyAutoGui.messageSetting": "显示一个[SETTING]的消息框",
    "gui.extension.pyAutoGui.image": "图片功能区",
    "gui.extension.pyAutoGui.cbImg": "获取整个屏幕[Pos]的图像数据保存为图片[Screen]并返回对象",
    "gui.extension.pyAutoGui.getRgb": "获取[Obj]([X],[Y])的RGB颜色",
    "gui.extension.pyAutoGui.getPixel": "获取整个屏幕([X],[Y])的RGB颜色",
    "gui.extension.pyAutoGui.pixelMatchesColor": "屏幕上([X],[Y])的RGB颜色和[Rgb]是否相同 匹配范围+-[Tolerance]",
    "gui.extension.pyAutoGui.locateOnScreen": "获取屏幕中图片对象[Image][Setting]坐标 灰度匹配[Grayscale]",
    "gui.extension.pyAutoGui.locateOn": "获取[Screen]中图片对象[Image][Setting]坐标 灰度匹配[Grayscale]",
    "gui.extension.pyAutoGui.imgCenter": "获取图片对象[Image]的中心坐标",
    "gui.blocklyText.pyAutoGui.messageSetting": "显示一个[SETTING]的消息框",
    "gui.blocklyText.pyAutoGui.platform": "消息框类型",
    "gui.blocklyText.pyAutoGui.simpleMessageBox": "简单消息框",
    "gui.blocklyText.pyAutoGui.messageBox": "消息框",
    "gui.blocklyText.pyAutoGui.inputMessageBox": "输入消息框",
    "gui.blocklyText.pyAutoGui.inputPasswordMessageBox": "密码消息框",
    "gui.blocklyText.pyAutoGui.title": "标题",
    "gui.blocklyText.pyAutoGui.text": "文本",
    "gui.blocklyText.pyAutoGui.okButton": "确认按钮名称",
    "gui.blocklyText.pyAutoGui.cancelButton": "取消按钮名称",
    "gui.blocklyText.pyAutoGui.inputDefault": "输入框默认值",
    "gui.blocklyText.pyAutoGui.encrypted": "加密",
    "gui.blocklyText.pyAutoGui.false": "关闭",
    "gui.blocklyText.pyAutoGui.true": "开启",
    "gui.blocklyText.pyAutoGui.None": "无",
    "gui.blocklyText.pyAutoGui.mouseLeft": "左键",
    "gui.blocklyText.pyAutoGui.mouseMiddle": "中间键",
    "gui.blocklyText.pyAutoGui.mouseRight": "右键",
    "gui.blocklyText.pyAutoGui.INQUAD": "缓慢开始",
    "gui.blocklyText.pyAutoGui.OUTQUAD": "缓慢结束",
    "gui.blocklyText.pyAutoGui.INOUTQUAD": "缓慢开始结束",
    "gui.blocklyText.pyAutoGui.INBOUNCE": "小幅度橡皮筋",
    "gui.blocklyText.pyAutoGui.OUTELASTIC": "大幅度橡皮筋",
    "gui.blocklyText.pyAutoGui.ActionDown": "按下",
    "gui.blocklyText.pyAutoGui.ActionUp": "松开",
    "gui.blocklyText.pyAutoGui.DirDown": "向下",
    "gui.blocklyText.pyAutoGui.DirUp": "向上",
    "gui.blocklyText.pyAutoGui.ABS": "绝对位置",
    "gui.blocklyText.pyAutoGui.REL": "相对位置",
    "gui.extension.pyRequests.request": "request",
    "gui.extension.pyRequests.requestGet": "发起一个GET请求[Url]",
    "gui.extension.pyRequests.requestPost": "发起一个[Data]POST请求[Url]",
    "gui.extension.pyRequests.getStatusCode": "获取[ReqObj]请求代码",
    "gui.extension.pyRequests.raiseForStatus": "检查[ReqObj]请求是否成功",
    "gui.extension.pyRequests.apparentEncoding": "获取[ReqObj]的真实编码",
    "gui.extension.pyRequests.setEncoding": "设置[ReqObj]的编码为[Code]",
    "gui.extension.pyRequests.handleHTML": "解析[ReqObj]请求内容为[Option]",
    "gui.extension.pyRequests.handleHeader": "获取[ReqObj]头部",
    "gui.extension.pyRequests.getValue": "获取[Item]的[Key]的值",
    "gui.blocklyText.pyRequests.text": "文本格式",
    "gui.blocklyText.pyRequests.json": "JSON格式",
    "gui.blocklyText.pyRequests.content": "二进制格式",
    "gui.extension.pyJson.dumpName": "编码",
    "gui.extension.pyJson.jsonDump": "编码JSON数据[Data]并保存到[FileObj] ASCII码转义[Switch]",
    "gui.extension.pyJson.jsonDumps": "编码JSON数据[Data]成字符串 ASCII码转义[Switch]",
    "gui.extension.pyJson.loadName": "解码",
    "gui.extension.pyJson.jsonload": "解码JSON文件对象[FileObj]成Python对象[PyObj]",
    "gui.extension.pyJson.jsonloads": "解码JSON数据[Data]成Python对象[PyObj]",
    "gui.extension.pyJson.valueName": "值的操作",
    "gui.extension.pyJson.get": "获取[PyObj][Key]的值",
    "gui.extension.pyJson.set": "设置[PyObj][Key]的值为[Value]",
    "gui.extension.pySchedule.createJobTitle": "任务创建",
    "gui.extension.pySchedule.createJob": "创建每间隔[Num][TimeType]运行一次的任务[JobName]",
    "gui.extension.pySchedule.createOneJob": "创建每[WEEKTYPES]运行一次的任务[JobName]",
    "gui.extension.pySchedule.createRandomJob": "创建每间隔[Num]-[LastNum][TimeType]之间随机运行一次的任务[JobName]",
    "gui.extension.pySchedule.setJobExactTime": "设置任务[JobName]的准确时间[Time]",
    "gui.extension.pySchedule.setJobTags": "设置任务[JobName]的标签[Tags]",
    "gui.extension.pySchedule.setJobUntil": "设置任务[JobName]的截至时间[Until]",
    "gui.extension.pySchedule.setJobFunction": "设置任务[JobName]的运行方法[Function](参数[Args])",
    "gui.extension.pySchedule.taskManageTitle": "任务管理",
    "gui.extension.pySchedule.cancelJob": "取消任务[JobName]",
    "gui.extension.pySchedule.getAllJob": "获取所有符合条件[Tags]的任务",
    "gui.extension.pySchedule.cancelAllJob": "取消所有符合条件[Tag]的任务",
    "gui.extension.pySchedule.getNextJobTime": "获取下一次任务运行的时间(秒)",
    "gui.extension.pySchedule.runAllJob": "运行所有任务",
    "gui.extension.pySchedule.runAllJobToo": "立刻获取所有任务的结果, 设置任务间隔[Num]秒",
    "gui.blocklyText.pySchedule.WEEKS": "周",
    "gui.blocklyText.pySchedule.DAYS": "天",
    "gui.blocklyText.pySchedule.HOURS": "时",
    "gui.blocklyText.pySchedule.MINUTES": "分",
    "gui.blocklyText.pySchedule.SECONDS": "秒",
    "gui.blocklyText.pySchedule.monday": "周一",
    "gui.blocklyText.pySchedule.tuesday": "周二",
    "gui.blocklyText.pySchedule.wednesday": "周三",
    "gui.blocklyText.pySchedule.thursday": "周四",
    "gui.blocklyText.pySchedule.friday": "周五",
    "gui.blocklyText.pySchedule.saturday": "周六",
    "gui.blocklyText.pySchedule.sunday": "周天",
    "gui.extension.pyXlrd.init": "文件读取",
    "gui.extension.pyXlrd.openWorkbook": "打开Excel工作簿[File]为[FileObj]",
    "gui.extension.pyXlrd.tableTitle": "工作表",
    "gui.extension.pyXlrd.getSheets": "获取[FileObj]的所有工作表",
    "gui.extension.pyXlrd.getSheetsName": "获取[FileObj]的所有工作表名称",
    "gui.extension.pyXlrd.selectSheet": "获取[FileObj]的[Screen]为[Value]的工作表对象[TableObj]",
    "gui.extension.pyXlrd.rowTitle": "行的操作",
    "gui.extension.pyXlrd.getRowNum": "获取[TableObj]的行数",
    "gui.extension.pyXlrd.getRow": "获取[TableObj]第[Num]行的从第[StartNum]列到第[EndNum]列的所有信息",
    "gui.extension.pyXlrd.getGenerator": "返回一个迭代[TableObj]的每一行信息的生成器",
    "gui.extension.pyXlrd.getRowType": "获取[TableObj]第[Num]行的从第[StartNum]列到第[EndNum]列的数据类型",
    "gui.extension.pyXlrd.getRowValue": "获取[TableObj]第[Num]行的从第[StartNum]列到第[EndNum]列的数据值",
    "gui.extension.pyXlrd.getRowLen": "获取[TableObj]第[Num]行有多少列",
    "gui.extension.pyXlrd.colTitle": "列的操作",
    "gui.extension.pyXlrd.getColNum": "获取[TableObj]的列数",
    "gui.extension.pyXlrd.getCol": "获取[TableObj]第[Num]列的从第[StartNum]行到第[EndNum]行的所有信息",
    "gui.extension.pyXlrd.getColType": "获取[TableObj]第[Num]列的从第[StartNum]行到第[EndNum]行的数据类型",
    "gui.extension.pyXlrd.getColValue": "获取[TableObj]第[Num]列的从第[StartNum]行到第[EndNum]行的数据值",
    "gui.extension.pyXlrd.cellTitle": "单元格的操作",
    "gui.extension.pyXlrd.getCell": "获取[TableObj]第[RowNum]行第[ColNum]列的单元格对象[CellObj]",
    "gui.extension.pyXlrd.getCellType": "获取[TableObj]第[RowNum]行第[ColNum]列的单元格类型",
    "gui.extension.pyXlrd.getCellValue": "获取[TableObj]第[RowNum]行第[ColNum]列的单元格值",
    "gui.extension.pyXlrd.optionIndex": "索引",
    "gui.extension.pyXlrd.optionName": "名称",
    "gui.extension.pyXlwt.init": "文件编辑",
    "gui.extension.pyXlwt.createWorkbook": "创建Excel文件[FileObj]并设置文件编码[Encoding]",
    "gui.extension.pyXlwt.addSheet": "为Excel文件[FileObj]添加工作表[SheetName]设置覆盖[Bool]并返回[SheetObj]",
    "gui.extension.pyXlwt.saveWorkbook": "保存Excel文件[FileObj]到[Path]",
    "gui.extension.pyXlwt.editTable": "编辑Excel工作表",
    "gui.extension.pyXlwt.createFontStyle": "创建字体样式[FontObj]",
    "gui.extension.pyXlwt.setFontBaseStyle": "设置字体[FontObj]基础样式[Style][Value]",
    "gui.extension.pyXlwt.setFontSpecialStyle": "设置字体[FontObj]特殊样式[Style]",
    "gui.extension.pyXlwt.createBorderStyle": "创建边框样式[BorderObj]",
    "gui.extension.pyXlwt.setBorderBaseStyle": "设置边框[BorderObj]基础样式[Style][Value]",
    "gui.extension.pyXlwt.createAlignmentStyle": "创建对齐样式[AlignmentObj]",
    "gui.extension.pyXlwt.setAlignmentHorzStyle": "设置对齐样式[AlignmentObj]水平方向[HorzStyle]",
    "gui.extension.pyXlwt.setAlignmentVertStyle": "设置对齐样式[AlignmentObj]垂直方向[VertStyle]",
    "gui.extension.pyXlwt.setAlignmentWrap": "设置对齐样式[AlignmentObj]自动换行[Bool]",
    "gui.extension.pyXlwt.createStyle": "创建总样式[StyleObj]",
    "gui.extension.pyXlwt.setStyle": "设置总样式[StyleObj]的[Type][Value]",
    "gui.extension.pyXlwt.easyStyle": "创建简单样式[StyleObj]样式[StyleString]数据格式[NumFormat]",
    "gui.extension.pyXlwt.eadit": "将数据[Label]以[Style]的样式写入工作表[SheetObj]的第[Row]行第[Col]列的单元格中",
    "gui.blocklyText.pyXlwt.font": "字体样式",
    "gui.blocklyText.pyXlwt.border": "边框样式",
    "gui.blocklyText.pyXlwt.alignment": "对齐样式",
    "gui.blocklyText.pyXlwt.alignLeft": "左端对齐",
    "gui.blocklyText.pyXlwt.alignRight": "右端对齐",
    "gui.blocklyText.pyXlwt.alignTop": "上端对齐",
    "gui.blocklyText.pyXlwt.centerAlign": "居中对齐",
    "gui.blocklyText.pyXlwt.alignBotton": "底端对齐",
    "gui.blocklyText.pyXlwt.top": "上边框",
    "gui.blocklyText.pyXlwt.botton": "下边框",
    "gui.blocklyText.pyXlwt.left": "左边框",
    "gui.blocklyText.pyXlwt.right": "右边框",
    "gui.blocklyText.pyXlwt.name": "字体名称",
    "gui.blocklyText.pyXlwt.height": "字体大小",
    "gui.blocklyText.pyXlwt.color_index": "字体颜色索引",
    "gui.blocklyText.pyXlwt.bold": "加粗",
    "gui.blocklyText.pyXlwt.underline": "下划线",
    "gui.blocklyText.pyXlwt.italic": "斜体",
    "gui.extension.pyOpenCV.base": "基础操作",
    "gui.extension.pyOpenCV.imread": "读取图片文件[FileName]设置[Option]并返回[ImgObj]",
    "gui.extension.pyOpenCV.imwrite": "保存图片[ImgObj]到[FileName]",
    "gui.extension.pyOpenCV.imdecode": "以[Option]模式读取[Buff]中的数据 返回图像[ImgObj]",
    "gui.extension.pyOpenCV.imencode": "将[Ext]图片[ImgObj]写入内存缓冲区[Buff]并返回是否成功写入[Ret]",
    "gui.extension.pyOpenCV.windowShow": "窗口显示",
    "gui.extension.pyOpenCV.imshow": "显示图片[ImgObj]到窗口[WinName]",
    "gui.extension.pyOpenCV.fullWindow": "设置窗口[WinName]全屏显示",
    "gui.extension.pyOpenCV.namedWindow": "创建一个名为[WinName]的窗口",
    "gui.extension.pyOpenCV.destroyWindow": "销毁一个名为[WinName]的窗口",
    "gui.extension.pyOpenCV.destroyAllWindows": "销毁全部窗口",
    "gui.extension.pyOpenCV.moveWindow": "移动一个名为[WinName]的窗口到坐标([X],[Y])",
    "gui.extension.pyOpenCV.resizeWindow": "调整一个名为[WinName]的窗口的大小([Width],[Height])",
    "gui.extension.pyOpenCV.resizeImg": "调整图像[ImgObj]尺寸为宽[Width]高[Height] 输出[OutImg]",
    "gui.extension.pyOpenCV.intercept": "截取图像[ImgObj]中第[Start]到第[End][Type]的图像 输出[OutImg]",
    "gui.extension.pyOpenCV.copyImg": "复制图片[ImgObj]到新对象[CopyImg]",
    "gui.extension.pyOpenCV.rotate": "将图片[ImgObj][RotateType]",
    "gui.extension.pyOpenCV.waitKey": "等待按键[Key]时间[Second]毫秒",
    "gui.extension.pyOpenCV.key": "按键[Key]",
    "gui.extension.pyOpenCV.selectROIs": "对图片[ImgObj]创建鼠标截图交互窗[WindowsName] [SwitchCenter]中心矩形 [SwitchCrosshair]交叉线  输出到坐标[OutImg]",
    "gui.extension.pyOpenCV.imgAttr": "图片属性",
    "gui.extension.pyOpenCV.imgShape": "获取图片[ImgObj]的行数、列数和通道数",
    "gui.extension.pyOpenCV.imgSize": "获取图片[ImgObj]的大小(像素总数)",
    "gui.extension.pyOpenCV.imgDtype": "获取图片[ImgObj]的数据类型",
    "gui.extension.pyOpenCV.copyMakeBorder": "在图片[ImgObj]上用[BorderType]添加宽度为上[TopNum]下[BottomNum]左[LeftNum]右[RightNum]的边框 输出[OutImg]",
    "gui.extension.pyOpenCV.video": "视频操作",
    "gui.extension.pyOpenCV.VideoCapture": "创建VideoCapture对象[VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureOpen": "使用VideoCapture对象[VideoObj]打开视频\\设备[VideoId]",
    "gui.extension.pyOpenCV.SetVideoCaptureSize": "设置VideoCapture对象[VideoObj]抓取分辨率为[Width]×[Height]",
    "gui.extension.pyOpenCV.VideoCaptureOpenVideo": "使用VideoCapture对象[VideoObj]打开视频文件[Video]",
    "gui.extension.pyOpenCV.VideoCaptureIsOpened": "使用VideoCapture对象[VideoObj]是否初始化完成",
    "gui.extension.pyOpenCV.VideoCaptureRelease": "关闭VideoCapture对象[VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureGrab": "VideoCapture对象[VideoObj]中是否存在下一帧",
    "gui.extension.pyOpenCV.VideoCaptureRead": "从VideoCapture对象[VideoObj]中抓取下一帧[VideoFrame]以及状态[VideoRet]",
    "gui.extension.pyOpenCV.VideoCaptureGet": "获取VideoCapture对象[VideoObj]属性[Properties]的值",
    "gui.extension.pyOpenCV.VideoCaptureSet": "设置VideoCapture对象[VideoObj]属性[Properties]的值为[Value]",
    "gui.extension.pyOpenCV.imgConvert": "图片转换",
    "gui.extension.pyOpenCV.adaptiveThreshold": "使用[Method]并配置最大值[MaxNum]阙值类型[Type]邻域大小[Size]常数[Constant]来处理图片[ImgObj]输出为图片[OutImg]",
    "gui.extension.pyOpenCV.cvtColor": "将图片[ImgObj]使用[Flags]进行转换成[OutImg]",
    "gui.extension.pyOpenCV.distanceTransform": "计算图片[ImgObj]中每个非零像素到最近零像素的[distanceType] 掩码[maskSize] 输出[Distance]",
    "gui.extension.pyOpenCV.threshold": "对图片[ImgObj]进行阈值[ThreshValue]最大值[MaxNum]阈值类型[ThresholdTypes]处理输出阈值[Ret]图片[OutImg]",
    "gui.extension.pyOpenCV.ObjectDetection": "对象检测",
    "gui.extension.pyOpenCV.CascadeClassifier": "创建级联分类器[CascadeClassifierObj]",
    "gui.extension.pyOpenCV.CascadeClassifierLoad": "级联分类器[CascadeClassifierObj]加载文件[FileName]",
    "gui.extension.pyOpenCV.CascadeClassifierEmpty": "级联分类器[CascadeClassifierObj]是否已加载完成",
    "gui.extension.pyOpenCV.detectMultiScale": "使用级联分类器[CascadeClassifierObj]通过[Method]缩放比例[ScaleFactor]构成目标的最小相邻个数[MinNeighbors]检测输入图像[ImgObj]中不同大小的对象并返回 方法3额外参数输出[OutputRejectLevels]",
    "gui.extension.pyOpenCV.Draws": "绘图工具",
    "gui.extension.pyOpenCV.putText": "在图片[ImgObj]上绘制文字[Text]左下角坐标X[PositionX]Y[PositionY]颜色[Color]字体大小[FontSize]线宽[Thickness]",
    "gui.extension.pyOpenCV.arrowedLine": "在图片[ImgObj]上绘制一条开始点[StartP]结束点[EndP]颜色[Color]粗细[Thickness]线条类型[LineType]的箭头线",
    "gui.extension.pyOpenCV.circle": "在图片[ImgObj]上绘制一条圆心[CenterP]半径[Radius]颜色[Color]粗细[Thickness]线条类型[LineType]的圆圈",
    "gui.extension.pyOpenCV.clipLine": "根据大小为[ImgSize]的矩形上裁剪一条开始点[StartP]结束点[EndP]的线并返回是否在于指定矩形[Ret]开始点[P1]结束点[P2]",
    "gui.extension.pyOpenCV.drawMarker": "在图片[ImgObj]上绘制一条位置[P]颜色[Color]标记类型[MarkerType]粗细[Thickness]标记大小[MarkerSize]线型[LineType]的标记",
    "gui.extension.pyOpenCV.ellipse": "在图片[ImgObj]上绘制一个圆心点[CenterP]轴[Axes]角度[Angle]起始角度[StartAngle]结束角度[EndAngle]颜色[Color]粗细[Thickness]线型[LineType]的椭圆",
    "gui.extension.pyOpenCV.line": "在图片[ImgObj]上绘制一个起始点[StartP]结束点[EndP]颜色[Color]粗细[Thickness]线型[LineType]的线",
    "gui.extension.pyOpenCV.rectangle": "在图片[ImgObj]上绘制一个首部顶点[TopP]尾部顶点[BottomP]颜色[Color]粗细[Thickness]线型[LineType]的矩形",
    "gui.extension.pyOpenCV.gesture": "图片[Img]中的手势是[Gesture]?",
    "gui.extension.pyOpenCV.gestureByVideo": "摄像头中的手势是[Gesture]?",
    "gui.extension.pyOpenCV.gaussian": "对图片[ImgObj]进行高斯模糊平滑处理，输出为图像[OutImg]",
    "gui.extension.pyOpenCV.canny": "对图片[ImgObj]进行边缘检测，阈值1[ThreshValue1]、阈值2[ThreshValue2]，输出边缘二值化图像[OutImg]",
    "gui.extension.pyOpenCV.contours": "在图片[ImgObj]中查找轮廓点，[Mode]模式、[Method]方法，返回结果到序列[Contours]",
    "gui.extension.pyOpenCV.drawContours": "在图片[ImgObj]上绘制轮廓线[Contours][Color]线宽[Thickness]，输出带轮廓线图像[OutImg]",
    "gui.blocklyText.pyOpenCV.retr_external": "仅外轮廓",
    "gui.blocklyText.pyOpenCV.retr_list": "无等级",
    "gui.blocklyText.pyOpenCV.retr_ccomp": "两等级树",
    "gui.blocklyText.pyOpenCV.retr_tree": "一等级树",
    "gui.blocklyText.pyOpenCV.chain_approx_none": "所有轮廓点",
    "gui.blocklyText.pyOpenCV.chain_approx_simple": "仅终点坐标",
    "gui.blocklyText.pyOpenCV.chain_approx_tc89_l1": "近似算法",
    "gui.blocklyText.pyOpenCV.imread_unchanged": "原图",
    "gui.blocklyText.pyOpenCV.imread_grayscale": "灰度图",
    "gui.blocklyText.pyOpenCV.imread_color": "BGR图",
    "gui.blocklyText.pyOpenCV.border_replicate": "复刻法",
    "gui.blocklyText.pyOpenCV.border_reflect": "反射法",
    "gui.blocklyText.pyOpenCV.border_reflect101": "反射法101",
    "gui.blocklyText.pyOpenCV.border_wrap": "外包装法",
    "gui.blocklyText.pyOpenCV.border_constant": "常量法",
    "gui.blocklyText.pyOpenCV.column": "列",
    "gui.blocklyText.pyOpenCV.row": "行",
    "gui.blocklyText.pyOpenCV.rotate_90_clockwise": "顺时针旋转90度",
    "gui.blocklyText.pyOpenCV.rotate_180": "旋转180度",
    "gui.blocklyText.pyOpenCV.rotate_90_counterclockwise": "逆时针旋转90度",
    "gui.blocklyText.pyOpenCV.cap_prop_buffersize": "图像缓冲区大小",
    "gui.blocklyText.pyOpenCV.cap_prop_pos_msec": "当前视频播放到的位置",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_width": "当前视频宽度",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_height": "当前视频高度",
    "gui.blocklyText.pyOpenCV.cap_prop_fps": "当前视频帧率",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_count": "当前视频帧数",
    "gui.blocklyText.pyOpenCV.adaptive_thresh_mean_c": "平均值算法",
    "gui.blocklyText.pyOpenCV.adaptive_thresh_gaussian_c": "高斯均值算法",
    "gui.blocklyText.pyOpenCV.thresh_binary": "超过阙值操作取最大值, 除此之外取0",
    "gui.blocklyText.pyOpenCV.thresh_binary_inv": "超过阙值操作取0, 除此之外取最大值",
    "gui.blocklyText.pyOpenCV.thresh_trunc": "超过阙值操作取临界点, 除此之外不变",
    "gui.blocklyText.pyOpenCV.thresh_tozero": "超过阙值操作不变, 除此之外取0",
    "gui.blocklyText.pyOpenCV.thresh_tozero_inv": "超过阙值操作取0, 除此之外不变",
    "gui.blocklyText.pyOpenCV.color_bgr2bgra": "将 Alpha 通道添加到 BGR",
    "gui.blocklyText.pyOpenCV.color_rgb2rgba": "将 Alpha 通道添加到 RGB",
    "gui.blocklyText.pyOpenCV.color_bgra2bgr": "从 BGR 图像中删除 alpha 通道",
    "gui.blocklyText.pyOpenCV.color_rgba2rgb": "从 RGB 图像中删除 alpha 通道",
    "gui.blocklyText.pyOpenCV.color_bgr2rgba": "在 BGR 和 RGB 颜色空间之间转换",
    "gui.blocklyText.pyOpenCV.color_rgb2bgra": "在 RGB 和 BGR 颜色空间之间转换",
    "gui.blocklyText.pyOpenCV.color_bgr2gray": "在 BGR 和灰度之间转换",
    "gui.blocklyText.pyOpenCV.color_rgb2gray": "在 RGB 和灰度之间转换",
    "gui.blocklyText.pyOpenCV.color_gray2bgr": "从灰度空间转换到 BGR",
    "gui.blocklyText.pyOpenCV.color_bgr2hsv": "在 BGR 与 HSV 颜色空间之间相互转换",
    "gui.blocklyText.pyOpenCV.color_rgb2hsv": "在 RGB 与 HSV 颜色空间之间相互转换",
    "gui.blocklyText.pyOpenCV.dist_l1": "曼哈顿距离",
    "gui.blocklyText.pyOpenCV.dist_l2": "简单欧几里得的距离",
    "gui.blocklyText.pyOpenCV.dist_c": "切比雪夫距离",
    "gui.blocklyText.pyOpenCV.detectmultiscale": "常用检测方法",
    "gui.blocklyText.pyOpenCV.detectmultiscale2": "检测方法2",
    "gui.blocklyText.pyOpenCV.detectmultiscale3": "检测方法3",
    "gui.blocklyText.pyOpenCV.filled": "填充线",
    "gui.blocklyText.pyOpenCV.line_4": "4连线",
    "gui.blocklyText.pyOpenCV.line_8": "8连线",
    "gui.blocklyText.pyOpenCV.line_aa": "抗锯齿线",
    "gui.blocklyText.pyOpenCV.marker_cross": " 十字线标记形状",
    "gui.blocklyText.pyOpenCV.marker_tilted_cross": " x准线标记形状",
    "gui.blocklyText.pyOpenCV.marker_star": " 星形标记形状",
    "gui.blocklyText.pyOpenCV.marker_diamond": " 菱形标记形状",
    "gui.blocklyText.pyOpenCV.marker_square": " 方形标记形状",
    "gui.blocklyText.pyOpenCV.marker_triangle_up": " 向上指向的三角形标记形状",
    "gui.blocklyText.pyOpenCV.marker_triangle_down": " 向下指向的三角形标记形状",
    "gui.blocklyText.pyOpenCV.haarcascade_eye": "眼睛数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_eye_tree_eyeglasses": "是否带墨镜数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalcatface": "猫脸数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalcatface_extended": "猫脸延伸数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt": "人脸(alt)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt_tree": "人脸(alt_tree)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt2": "人脸(alt2)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_default": "人脸(默认)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_fullbody": "身体数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_lefteye_2splits": "眼部(左眼)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_licence_plate_rus_16stages": "证件数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_lowerbody": "下半身数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_profileface": "人脸(侧视)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_righteye_2splits": "眼部(右眼)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_russian_plate_number": "俄罗斯字母车牌号数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_smile": "嘴部数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_upperbody": "上半身数据模型",
    "gui.blocklyText.pyOpenCV.gesture1": "手势1",
    "gui.blocklyText.pyOpenCV.gesture2": "手势2",
    "gui.blocklyText.pyOpenCV.gesture3": "手势3",
    "gui.blocklyText.pyOpenCV.gesture4": "手势4",
    "gui.blocklyText.pyOpenCV.gesture5": "手势5",
    "gui.blocklyText.unihiker.updateParamsMenu_width": "宽",
    "gui.blocklyText.unihiker.updateParamsMenu_height": "高",
    "gui.blocklyText.unihiker.updateParamsMenu_radial": "半径",
    "gui.blocklyText.unihiker.updateParamsMenu_linewidth": "线宽",
    "gui.blocklyText.unihiker.updateParamsMenu_start": "起始点",
    "gui.blocklyText.unihiker.updateParamsMenu_end": "终止点",
    "gui.blocklyText.unihiker.standardPointMenu_center": "中心",
    "gui.blocklyText.unihiker.standardPointMenu_top": "上",
    "gui.blocklyText.unihiker.standardPointMenu_bottom": "下",
    "gui.blocklyText.unihiker.standardPointMenu_left": "左",
    "gui.blocklyText.unihiker.standardPointMenu_right": "右",
    "gui.blocklyText.unihiker.standardPointMenu_topleft": "左上角",
    "gui.blocklyText.unihiker.standardPointMenu_topright": "右上角",
    "gui.blocklyText.unihiker.standardPointMenu_bottomleft": "左下角",
    "gui.blocklyText.unihiker.standardPointMenu_bottomright": "右下角",
    "gui.blocklyText.unihiker.GESTURE_gesture1": "手势1",
    "gui.blocklyText.unihiker.GESTURE_gesture2": "手势2",
    "gui.blocklyText.unihiker.GESTURE_gesture3": "手势3",
    "gui.blocklyText.unihiker.GESTURE_gesture4": "手势4",
    "gui.blocklyText.unihiker.GESTURE_gesture5": "手势5",
    "gui.blocklyText.unihiker.xyzsMenu_S": "强度",
    "gui.blocklyText.unihiker.expressionMenu_Angry": "愤怒",
    "gui.blocklyText.unihiker.expressionMenu_Nerve": "紧张",
    "gui.blocklyText.unihiker.expressionMenu_Peace": "平静",
    "gui.blocklyText.unihiker.expressionMenu_Shock": "惊讶",
    "gui.blocklyText.unihiker.expressionMenu_Sleep": "睡觉",
    "gui.blocklyText.unihiker.expressionMenu_Smile": "微笑",
    "gui.blocklyText.unihiker.expressionMenu_Sweat": "冒汗",
    "gui.blocklyText.unihiker.expressionMenu_Think": "思考",
    "gui.blocklyText.unihiker.expressionMenu_Wink": "眨眼",
    "gui.blocklyText.unihiker.unihikerPinMenu_button": "键",
    "gui.blocklyText.unihiker.blockTitle.screenDisplay": "屏幕显示",
    "gui.blocklyText.unihiker.showText": "对象名[NAME]显示文字[TEXT]在X[X] Y[Y] 字号[FONTSIZE]颜色[COLOR]",
    "gui.blocklyText.unihiker.showTextWithDigitalTubeStyle": "对象名[NAME]显示仿数码管字体[TEXT]在:X[X] Y[Y] 字号[FONTSIZE]颜色[COLOR]",
    "gui.blocklyText.unihiker.showImage": "对象名[NAME]显示图片[IMG]在X[X] Y[Y]",
    "gui.blocklyText.unihiker.showInsetLiveExpression": "对象名[NAME]显示内置动态表情[EXPRESSION]在X[X] Y[Y] 间隔[DURATION]秒",
    "gui.blocklyText.unihiker.addButtonClickFun": " 对象名[NAME]增加按钮[BUTTON]在X[X] Y[Y] 宽[WIDTH]高[HEIGHT] 点击回调函数[FUN]",
    "gui.blocklyText.unihiker.showClock": "对象名[NAME]显示时钟在X[X] Y[Y] 半径[RADIUS] 颜色[COLOR]",
    "gui.blocklyText.unihiker.showClockWithFillStyle": "对象名[NAME]显示填充时钟在X[X] Y[Y] 半径[RADIUS]边框颜色[COLOR1]填充颜色[COLOR2]",
    "gui.blocklyText.unihiker.showQrCode": "对象名[NAME]显示二维码 内容[VALUE]在X[X] Y[Y]边长[WIDTH]",
    "gui.blocklyText.unihiker.drawLine": "对象名[NAME]显示线段 起点X[X1] Y[Y1] 终点X[X2] Y[Y2] 线宽[LINEWIDTH] 颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRectangle": "对象名[NAME]显示矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]线宽[LINEWIDTH]边框颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRectangleWithFillStyle": "对象名[NAME]显示填充矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]填充颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRoundRectangle": "对象名[NAME]显示圆角矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]圆角半径[RADIUS]线宽[LINEWIDTH]边框颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRoundRectangleWithFillStyle": "对象名[NAME]显示圆角填充矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]圆角半径[RADIUS]填充颜色[COLOR]",
    "gui.blocklyText.unihiker.drawCircle": "对象名[NAME]显示圆形在X[X] Y[Y] 半径[RADIUS] 线宽[LINEWIDTH] 边框颜色[COLOR]",
    "gui.blocklyText.unihiker.drawCircleWithFillStyle": "对象名[NAME]显示填充圆形在X[X] Y[Y] 半径[RADIUS] 填充颜色[COLOR]",
    "gui.blocklyText.unihiker.updateObjectNumberParams": "更新对象名[NAME]的数字参数[PARAMS]为[VALUE]",
    "gui.blocklyText.unihiker.updateObjectNumbersParams": "更新对象名[NAME]的数字参数[PARAMS1]为[VALUE1] [PARAMS2]为[VALUE2]",
    "gui.blocklyText.unihiker.updateObjectStringParams": "更新对象名[NAME]的文本内容参数为[VALUE]",
    "gui.blocklyText.unihiker.updateObjectColorPalette": "更新对象名[NAME]的颜色为[COLOR]",
    "gui.blocklyText.unihiker.updateObjectColorRGB": "更新对象名[NAME]的颜色为 红[RED]绿[GREEN]蓝[BLUE]",
    "gui.blocklyText.unihiker.updateObjectColorName": "更新对象名[NAME]的颜色为[COLOR]",
    "gui.blocklyText.unihiker.updateObjectStandardPoint": "更新对象名[NAME]的基准点为[POSITION]",
    "gui.blocklyText.unihiker.updateTimeObjectValue": "更新时钟对象[NAME]的时间为[HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.unihiker.setObjClickFun": "对象名[NAME]的点击回调函数为[FUNCTIONNAME]",
    "gui.blocklyText.unihiker.whenClickTriggerFun": "当点击回调函数[FUNCTIONNAME]被触发",
    "gui.blocklyText.unihiker.updateImgObjSrc": "更新图片对象[IMG]图片源为[SRC]",
    "gui.blocklyText.unihiker.updateExpressionObjSrc": "更新表情对象[NAME]表情源为[EXPRESSION]",
    "gui.blocklyText.unihiker.blockTitle.mouseAndKeyboardEvent": "鼠标键盘事件",
    "gui.blocklyText.unihiker.whenMouseMove": "当接收到鼠标移动事件 返回坐标[X] [Y]",
    "gui.blocklyText.unihiker.whenButtonABPress": "当行空板按键[BUTTONAB]被按下",
    "gui.blocklyText.unihiker.whenKeyboardPress": "当键盘按键[KEYBOARD]被按下",
    "gui.blocklyText.unihiker.numberKeyboard": "数字及字母键[KEYBOARD]",
    "gui.blocklyText.unihiker.otherKeyboard": "其他键盘[KEYBOARD]",
    "gui.blocklyText.unihiker.waitForWhenButtonABPress": "等待直到行空板按键[BUTTONAB]被按下",
    "gui.blocklyText.unihiker.blockTitle.multithreading": "多线程",
    "gui.blocklyText.unihiker.startThread": "线程对象[THREAD]启动",
    "gui.blocklyText.unihiker.quitThread": "线程对象[THREAD]停止",
    "gui.blocklyText.unihiker.whenThreadStart": "当线程对象[THREAD]启动后执行",
    "gui.blocklyText.unihiker.blockTitle.lmSensors": "板载传感器",
    "gui.blocklyText.unihiker.isButtonABPressed": "按钮[BUTTONAB]被按下？",
    "gui.blocklyText.unihiker.readAmbientLight": "读取环境光强度",
    "gui.blocklyText.unihiker.readMicrophoneSound": "读取麦克风声音强度",
    "gui.blocklyText.unihiker.readXYZSAcceleratedSpeed": "读取加速度的值[XYZS]",
    "gui.blocklyText.unihiker.readGyroscopeXYZ": "读取陀螺仪的值[XYZ]",
    "gui.blocklyText.unihiker.blockTitle.onboardBuzzer": "板载蜂鸣器",
    "gui.blocklyText.unihiker.playMusic": "播放音乐[MUSIC]重复[REPEAT]",
    "gui.blocklyText.unihiker.musicSetTempo": "设置每拍等同[TICKS]分音符， 每分钟节拍数[TEMPO]",
    "gui.blocklyText.unihiker.setBuzzerFreq": "播放音符[NOTE] [BEAT]拍",
    "gui.blocklyText.unihiker.backSetBuzzerFreq": "后台播放音符[NOTE]",
    "gui.blocklyText.unihiker.buzzerSwitch": "停止后台播放",
    "gui.blocklyText.unihiker.buzzerRedirect": "蜂鸣器重定向到引脚[PIN]",
    "gui.blocklyText.unihiker.blockTitle.pinOperation": "引脚操作",
    "gui.blocklyText.unihiker.unihikerPin": "行空板引脚[PIN]",
    "gui.blocklyText.unihiker.digitalRead": "读取数字引脚 [PIN]",
    "gui.blocklyText.unihiker.analogRead": "读取模拟引脚(ADC 0-4095)[PIN]",
    "gui.blocklyText.unihiker.digitalWrite": "设置数字引脚[PIN]输出[VALUE]",
    "gui.blocklyText.unihiker.analogWrite": "设置模拟引脚[PIN] 输出(PWM)[VALUE]",
    "gui.blocklyText.unihiker.blockTitle.audioPlayback": "音频录放",
    "gui.blocklyText.unihiker.getRecord": "录音[TIME]秒直到结束，文件名[NAME]",
    "gui.blocklyText.unihiker.getRecordBackground": "开始后台录音，文件名[NAME]",
    "gui.blocklyText.unihiker.stopRecordBackground": "结束后台录音",
    "gui.blocklyText.unihiker.playAudio": "播放音频文件[NAME]直到结束",
    "gui.blocklyText.unihiker.startPlayAudioBackground": "开始后台播放音频文件[NAME]",
    "gui.blocklyText.unihiker.getBgAudioRemainingDuration": "获取后台播放音频的剩余时长(秒)",
    "gui.blocklyText.unihiker.pauseBgAudio": "暂停后台播放",
    "gui.blocklyText.unihiker.continueBgAudio": "重新开始后台播放",
    "gui.blocklyText.unihiker.stopBgAudio": "结束后台播放",
    "gui.extension.unihiker.input.button": "按钮",
    "gui.extension.unihiker.input.hello": "你好",
    "gui.blocklyText.unihiker.updateButtonStatus": "更新按钮对象[BUTTON]为[STATUS]",
    "gui.blocklyText.unihiker.updateObjectParamWithInput": "更新对象[OBJECT]的参数[KEY]为[VALUE]",
    "gui.blocklyText.unihiker.updateObjectParamsWithInput": "更新对象[OBJECT]的参数1[KEY1]为[VALUE1] 参数2[KEY2]为[VALUE2]",
    "gui.blocklyText.unihiker.removeObject": "删除对象[OBJECT]",
    "gui.blocklyText.unihiker.removeAllObject": "删除所有对象",
    "gui.blocklyText.unihiker.buttonStatusMenu_normal": "启用",
    "gui.blocklyText.unihiker.buttonStatusMenu_disable": "禁用",
    "gui.blocklyText.unihiker.updateParamsMenu_fontSize": "字体大小",
    "gui.blocklyText.unihiker.generate.keyboardWithButtonConflict": "！错误提示：键盘按键[AB]与行空板按键[AB]事件仅能同时使用一个，请删除重复定义的模块",
    "gui.blocklyText.pythonsen0291.extensionName": "功率计",
    "gui.blocklyText.pythondfr0553.extensionName": "AD 模块",
    "gui.blocklyText.pythondfr0563.extensionName": "电量计",
    "gui.blocklyText.pyBaiDuAi.app_id": "AppID",
    "gui.blocklyText.pyBaiDuAi.api_key": "API Key",
    "gui.blocklyText.pyBaiDuAi.secret_key": "Secret Key",
    "gui.blocklyText.pyBaiDuAi.age": "年龄",
    "gui.blocklyText.pyBaiDuAi.gender": "性别",
    "gui.blocklyText.pyBaiDuAi.beauty": "颜值",
    "gui.blocklyText.pyBaiDuAi.face_shape": "脸型",
    "gui.blocklyText.pyBaiDuAi.glasses": "眼镜",
    "gui.blocklyText.pyBaiDuAi.expression": "表情",
    "gui.blocklyText.pyBaiDuAi.face_probability": "真人",
    "gui.blocklyText.pyBaiDuAi.Width": "宽度",
    "gui.blocklyText.pyBaiDuAi.Height": "高度",
    "gui.blocklyText.pyBaiDuAi.mask": "口罩",
    "gui.blocklyText.pyBaiDuAi.objectDetect": "图像单主体检测",
    "gui.blocklyText.pyBaiDuAi.advancedGeneral": "通用物体和场景",
    "gui.blocklyText.pyBaiDuAi.plantDetect": "植物",
    "gui.blocklyText.pyBaiDuAi.animalDetect": "动物",
    "gui.blocklyText.pyBaiDuAi.currency": "货币",
    "gui.blocklyText.pyBaiDuAi.basicGeneral": "通用文字",
    "gui.blocklyText.pyBaiDuAi.numbers": "数字",
    "gui.blocklyText.pyBaiDuAi.licensePlate": "车牌",
    "gui.blocklyText.pyBaiDuAi.handwriting": "手写文字",
    "gui.blocklyText.pyBaiDuAi.init": "初始化",
    "gui.blocklyText.pyBaiDuAi.AipFaceInit": "初始化百度人脸识别 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipImageClassifyInit": "初始化百度图像识别 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipOcrInit": "初始化百度文字识别 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipBodyAnalysisInit": "初始化百度人体分析 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.getFace": "获取人脸对象",
    "gui.blocklyText.pyBaiDuAi.getImage": "获取图片对象",
    "gui.blocklyText.pyBaiDuAi.face": "人脸识别",
    "gui.blocklyText.pyBaiDuAi.generationFaceFormBase64": "从本地生成人脸图片对象 [IMG]",
    "gui.blocklyText.pyBaiDuAi.generationFaceFormUrl": "从网络生成人脸图片对象 [URL]",
    "gui.blocklyText.pyBaiDuAi.detect": "识别 [Image] 人脸信息并存入结果",
    "gui.blocklyText.pyBaiDuAi.detectIsOk": "人脸识别是否成功 ?",
    "gui.blocklyText.pyBaiDuAi.detectGet": "获取人脸信息的 [TYPE]",
    "gui.blocklyText.pyBaiDuAi.match": "对比 [Face1] 和 [Face2] 的人脸相似度 ",
    "gui.blocklyText.pyBaiDuAi.groupAdd": "创建人脸库 [GroupName]",
    "gui.blocklyText.pyBaiDuAi.addUser": "添加名为 [FaceName] 的用户的人脸 [Face] 到人脸库 [GroupName]",
    "gui.blocklyText.pyBaiDuAi.img": "图像识别",
    "gui.blocklyText.pyBaiDuAi.imageDetectType": "识别图中 [IMG] 包含的 [DetectType]",
    "gui.blocklyText.pyBaiDuAi.ocr": "文字识别",
    "gui.blocklyText.pyBaiDuAi.body": "人体分析",
    "gui.blocklyText.pyBaiDuAi.gesture": "识别图中 [IMG] 包含的手势",
    "gui.blocklyText.pyBaiDuAi.gestureis": "手势[Gesture] 是 [GestureType]?",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysis": "识别图中 [IMG] 包含的人体关键点",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysisIsOk": "人体关键点结果是否识别成功",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysisGet": "获取人体关键点结果的[BODY]的[XY]坐标",
    "gui.blocklyText.pyBaiDuAi.BASE64": "BASE64",
    "gui.blocklyText.pyBaiDuAi.URL": "URL",
    "gui.blocklyText.pyBaiDuAi.FACE_TOKEN": "FACE_TOKEN",
    "gui.blocklyText.pyBaiDuAi.readImage": "读取图片 [IMG]",
    "gui.blocklyText.pyBaiDuAi.search": "从人脸库 [GroupName] 中搜索与 [Face] 相似度 >= [Score] 的名字",
    "gui.blocklyText.pyBaiDuSpeech.init": "初始化百度语音客户端 [SETTING]",
    "gui.blocklyText.pyBaiDuSpeech.asr": "识别一个 采样率 [Rate] [Format] 音频文件 [Speech] ",
    "gui.blocklyText.pyBaiDuSpeech.synthesisOption": "设置语音合成参数 格式[Format] 嗓音[Per] 语速[Spd] 音调[Pit] 音量[Vol]",
    "gui.blocklyText.pyBaiDuSpeech.synthesis": "请求将 [Text] 转换成语音",
    "gui.blocklyText.pyBaiDuSpeech.isOk": "语音合成成功?",
    "gui.blocklyText.pyBaiDuSpeech.saveAudio": "将合成结果保存 文件名[Audio]",
    "gui.blocklyText.pyBaiDuSpeech.pcm": "pcm文件格式",
    "gui.blocklyText.pyBaiDuSpeech.wav": "wav文件格式",
    "gui.blocklyText.pyBaiDuSpeech.amr": "amr文件格式",
    "gui.blocklyText.pyBaiDuSpeech.mp3": "mp3文件格式",
    "gui.extension.pyXfSpeech.init": "初始化",
    "gui.extension.pyXfSpeech.speechInit": "訊飛語音初始化 [SETTING]",
    "gui.extension.pyXfSpeech.speechSynthesis": "語音合成",
    "gui.extension.pyXfSpeech.speechSynthesisOption": "設定語音合成參數 語速[SPEED] 音調[PITCH] 音量[VOLUME] [SETTING]",
    "gui.extension.pyXfSpeech.speechSynthesisSend": "通過語音合成[Text]並保存到[File]中",
    "gui.extension.pyXfSpeech.speechRecognition": "語音識別",
    "gui.extension.pyXfSpeech.speechRecognitionSend": "識別[Audio]音频文件",
    "gui.blocklyText.pyXfSpeech.noBackgroundsound": "無",
    "gui.blocklyText.pyXfSpeech.withBackgroundsound": "有",
    "gui.blocklyText.pyXfSpeech.xiaoyan": "訊飛小燕",
    "gui.blocklyText.pyXfSpeech.aisjiuxu": "訊飛許久",
    "gui.blocklyText.pyXfSpeech.aisxping": "訊飛小萍",
    "gui.blocklyText.pyXfSpeech.aisjinger": "訊飛小婧",
    "gui.blocklyText.pyXfSpeech.aisbabyxu": "訊飛許小寶",
    "gui.blocklyText.pyXfSpeech.vcn": "發音人",
    "gui.blocklyText.pyXfSpeech.bgs": "背景音",
    "gui.blocklyText.pythondfr0095.extensionName": "红外发送",
    "gui.blocklyText.pythondfr0095.pinpongIRSend": "红外发送 引脚 [PIN] 发送 [DATA]",
    "gui.extension.pictureai.fist": "拳头",
    "gui.extension.pictureai.pray": "祈祷",
    "gui.extension.pictureai.bow": "作揖",
    "gui.extension.pictureai.farewell": "作别",
    "gui.extension.pictureai.one_heart": "单手比心",
    "gui.extension.pictureai.thumbs_up": "点赞",
    "gui.extension.pictureai.love_you": "我爱你",
    "gui.extension.pictureai.palm_up": "掌心向上",
    "gui.extension.pictureai.two_hearts": "双手比心",
    "gui.extension.pictureai.middle_finger": "竖中指",
    "gui.extension.pictureai.face": "脸",
    "gui.extension.pictureai.switch_account_failed": "切换独立账户失败",
    "gui.extension.pictureai.id_not_exist": "该id不存在，请检查后重试",
    "gui.extension.pictureai.error": "错误",
    "gui.extension.pictureai.file_not_exist_in_path": "该路径下不存在文件,请检查路径是否正确",
    "gui.extension.pictureai.path_not_exist": "该路径不存在,请检查路径是否正确",
    "gui.extension.pictureai.save_image_failed": "保存图片失败",
    "gui.extension.pictureai.image_recognition": "图像识别",
    "gui.extension.pictureai.no_stage_video_stream": "未获取到舞台视频流",
    "gui.extension.pictureai.please_fill_image_address": "请先填写图片地址",
    "gui.extension.pictureai.image_address_format_error": "图片地址格式错误",
    "gui.extension.pictureai.face_recognition": "人脸识别",
    "gui.extension.pictureai.main_character_in_image": "图中主要人物为",
    "gui.extension.pictureai.one_gender_age": "1个{gender}，大约{age}岁",
    "gui.extension.pictureai.male": "男生",
    "gui.extension.pictureai.female": "女生",
    "gui.extension.pictureai.wearing_glasses": ",还戴着眼镜",
    "gui.extension.pictureai.smiling": ", 在微笑",
    "gui.extension.pictureai.no_face_in_image": "图片中没有人脸，换一张再试试",
    "gui.extension.pictureai.app_authentication_failed": "应用鉴权失败，请至控制台检查是否开放人脸识别功能",
    "gui.extension.pictureai.app_usage_exceeded": "应用使用次数超出额度，请至控制台检查",
    "gui.extension.pictureai.square_face": "方脸",
    "gui.extension.pictureai.cone_face": "锥子脸",
    "gui.extension.pictureai.oval_face": "鹅蛋脸",
    "gui.extension.pictureai.heart_face": "心形脸",
    "gui.extension.pictureai.round_face": "圆脸",
    "gui.extension.pictureai.unknown_face": "未知脸",
    "gui.extension.pictureai.glasses": "眼镜",
    "gui.extension.pictureai.sunglasses": "墨镜",
    "gui.extension.pictureai.smile": "微笑",
    "gui.extension.pictureai.normal": "正常",
    "gui.extension.pictureai.angry": "愤怒",
    "gui.extension.pictureai.disgust": "厌恶",
    "gui.extension.pictureai.fear": "恐惧",
    "gui.extension.pictureai.happy": "高兴",
    "gui.extension.pictureai.sad": "悲伤",
    "gui.extension.pictureai.surprised": "惊讶",
    "gui.extension.pictureai.expressionless": "无表情",
    "gui.extension.pictureai.pouting": "撅嘴",
    "gui.extension.pictureai.making_faces": "鬼脸",
    "gui.extension.pictureai.no_mask": "没戴口罩",
    "gui.extension.pictureai.wearing_mask": "戴口罩",
    "gui.extension.pictureai.please_do_face_recognition_first": "请先进行人脸信息识别",
    "gui.extension.pictureai.face_comparison": "人脸对比",
    "gui.extension.pictureai.face_similarity": "两张图中人脸相似度为",
    "gui.extension.pictureai.ensure_faces_in_both_images": "请确定两张图片中都包含人脸",
    "gui.extension.pictureai.check_image": "请检查图片",
    "gui.extension.pictureai.text_recognition": "文字识别",
    "gui.extension.pictureai.handwriting": "手写字",
    "gui.extension.pictureai.license_plate": "车牌号",
    "gui.extension.pictureai.number": "数字",
    "gui.extension.pictureai.no_text_in_image": "图片中不包含文字",
    "gui.extension.pictureai.produced_in": ", 生产于",
    "gui.extension.pictureai.face_value": ", 面值为",
    "gui.extension.pictureai.possible_non_currency": "图中可能是非货币",
    "gui.extension.pictureai.possible_object": "图中可能是",
    "gui.extension.pictureai.gesture_recognition": "手势识别",
    "gui.extension.pictureai.someone_thumbs_up_in_image": "图片里有人为你点赞",
    "gui.extension.pictureai.someone_makes_heart_with_single_hands_in_image": "图片里有人为你单手比心",
    "gui.extension.pictureai.someone_booing_in_image": "图片里有人喝倒彩，要加油了",
    "gui.extension.pictureai.someone_says_congratulations_in_image": "图片里有人说恭喜",
    "gui.extension.pictureai.someone_says_ok_in_image": "图片里有人说OK",
    "gui.extension.pictureai.someone_prays_in_image": "图片里有人祈祷",
    "gui.extension.pictureai.someone_makes_heart_with_both_hands_in_image": "图片里有人为你双手比心",
    "gui.extension.pictureai.someone_says_goodbye_in_image": "图片里有人对你说在下告辞",
    "gui.extension.pictureai.fist_in_image": "图片里有一个拳头",
    "gui.extension.pictureai.rock_on": "摇滚起来",
    "gui.extension.pictureai.someone_despises_you_in_image": "图里有人鄙视你！",
    "gui.extension.pictureai.cannot_understand_gesture_in_image": "看不懂图片里的手势，换一张试试",
    "gui.extension.pictureai.face_in_image_no_gesture": "图里是人脸，没有手势",
    "gui.extension.pictureai.recognition_failed": "识别失败",
    "gui.extension.pictureai.human_keypoints_recognition": "人体关键点识别",
    "gui.extension.pictureai.check_image_no_valid_human": "请检查图片，图中没有找到有效人体，换一张再试试",
    "gui.extension.pictureai.result_as_shown": "结果如图",
    "gui.extension.pictureai.please_do_human_keypoints_analysis_first": "请先进行人体关键点分析",
    "gui.extension.serialPort.name": "发送数据至实时模式",
    "gui.extension.serialPortjJS.name": "接收上传模式数据",
    "gui.extension.serialPort.description": "便捷实现上传模式发送多组数据，实时模式通过串口接收数据功能",
    "gui.blocklyText.firebeetleesp32.stop": "停止",
    "gui.blocklyText.esp32.MQTTinit": "MQTT初始化参数 [SETTING]",
    "gui.blocklyText.esp32.MQTTtencent": "MQTT腾讯物联网 [SETTING]",
    "gui.blocklyText.esp32.esp32_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.esp32.MQTTconnect": "连接MQTT",
    "gui.blocklyText.esp32.MQTTpublish": "发布 [DATA] 至 主题 [TOPIC]",
    "gui.blocklyText.esp32.esp32_waitMSG": "等待主题消息 以 [MODE] 模式",
    "gui.blocklyText.esp32.disconnectMQTT": "MQTT 断开连接",
    "gui.blocklyText.esp32.MQTTlastwill": "设置MQTT主题 [TOPIC] 离线消息 [MSG]",
    "gui.blocklyText.esp32.MQTTreceive_1": "当主题 [TOPIC] 接收到消息为 [DATA] 时",
    "gui.blocklyText.esp32.MQTTreceive_2": "当主题 [TOPIC] 接收到消息为 [DATA] 时",
    "gui.blocklyText.weather.weatherFeild": "[DATA] 心知天气 通用字段 [FIELD]",
    "gui.blocklyText.weather.weatherSituation": "[DATA] 心知天气 天气实况 [FIELD]",
    "gui.blocklyText.weather.Weatherforecast": "[DATA] 心知天气 3天天气预报 [DATE] 的 [FIELD]",
    "gui.blocklyText.weather.Weatherlivingindex": "[DATA] 心知天气 6项生活指数 [FIELD]",
    "gui.blocklyText.esp32.MQTTmode.block": "阻塞",
    "gui.blocklyText.esp32.MQTTmode.unblock": "非阻塞",
    "gui.blocklyText.esp32.weatherfeild.name": "城市名称",
    "gui.blocklyText.esp32.weatherfeild.id": "城市ID",
    "gui.blocklyText.esp32.weatherfeild.code": "国家代码",
    "gui.blocklyText.esp32.weatherfeild.path": "行政归属",
    "gui.blocklyText.esp32.weatherfeild.timezone": "时区",
    "gui.blocklyText.esp32.weatherfeild.timezone_offset": "UTC偏移量",
    "gui.blocklyText.esp32.weatherfeild.Updated": "数据更新日期",
    "gui.blocklyText.esp32.weatherfeild.updatetime": "数据更新时间",
    "gui.blocklyText.esp32.weatherfeild.original": "原始字典对象",
    "gui.blocklyText.esp32.weatherSituation.text": "天气现象",
    "gui.blocklyText.esp32.weatherSituation.code": "天气现象代码",
    "gui.blocklyText.esp32.weatherSituation.temperature": "温度",
    "gui.blocklyText.esp32.weatherdate.today": "今天",
    "gui.blocklyText.esp32.weatherdate.tomorrow": "明天",
    "gui.blocklyText.esp32.weatherdate.dayaftertomorrow": "后天",
    "gui.blocklyText.esp32.weatherforecast.highesttemperature": "当天最高温度",
    "gui.blocklyText.esp32.weatherforecast.lowesttemperature": "当天最低温度",
    "gui.blocklyText.esp32.weatherforecast.daytimeweather": "白天天气现象",
    "gui.blocklyText.esp32.weatherforecast.daytimeweathercode": "白天天气现象代码",
    "gui.blocklyText.esp32.weatherforecast.nightweather": "晚间天气现象",
    "gui.blocklyText.esp32.weatherforecast.nightweathercode": "晚间天气现象代码",
    "gui.blocklyText.esp32.weatherforecast.winddirection": "风向",
    "gui.blocklyText.esp32.weatherforecast.winddirectiondegree": "风向角度",
    "gui.blocklyText.esp32.weatherforecast.windspeed": "风速",
    "gui.blocklyText.esp32.weatherforecast.windscale": "风力等级",
    "gui.blocklyText.esp32.weatherforecast.date": "日期",
    "gui.blocklyText.esp32.weatherlivingindex.Carwashindex": "洗车指数",
    "gui.blocklyText.esp32.weatherlivingindex.Dressingindex": "穿衣指数",
    "gui.blocklyText.esp32.weatherlivingindex.Coldindex": "感冒指数",
    "gui.blocklyText.esp32.weatherlivingindex.Sportindex": "运动指数",
    "gui.blocklyText.esp32.weatherlivingindex.Travelindex": "旅游指数",
    "gui.blocklyText.esp32.weatherlivingindex.uvindex": "紫外线指数",
    "gui.blocklyText.sen${name}gui.blocklyText.pythonsen0303.VEML6075Ready0497.DHT20Read": "读取DHT20[INDEX]",
    "gui.blocklyText.wifi.getDwifiNS": "DNS",
    "gui.blocklyText.pythonwifi.wifiGetLocalIP": "[NAME] 获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.dialog.ml5.ifLagTryEnableGPUInSettings": "如运行卡顿，可尝试打开设置中的GPU加速功能",
    "paint.paintEditor.hue": "顏色",
    "paint.paintEditor.saturation": "彩度",
    "paint.paintEditor.brightness": "亮度",
    "gui.comingSoon.message1": "別擔心，我們在做囉 {emoji}",
    "gui.comingSoon.message2": "即將推出...",
    "gui.comingSoon.message3": "努力趕工中 {emoji}",
    "paint.paintEditor.fill": "填滿",
    "paint.paintEditor.costume": "造型",
    "paint.paintEditor.group": "建立群組",
    "paint.paintEditor.ungroup": "解散群組",
    "paint.paintEditor.undo": "復原",
    "paint.paintEditor.redo": "重做",
    "paint.paintEditor.forward": "上移一層",
    "paint.paintEditor.backward": "下移一層",
    "paint.paintEditor.front": "移到最上層",
    "paint.paintEditor.back": "移到最下層",
    "paint.paintEditor.more": "更多",
    "paint.modeTools.brushSize": "尺寸",
    "paint.modeTools.eraserSize": "擦子寬度",
    "paint.modeTools.copy": "複製",
    "paint.modeTools.paste": "貼上",
    "paint.modeTools.delete": "刪除",
    "paint.modeTools.curved": "圓弧",
    "paint.modeTools.pointed": "尖角",
    "paint.modeTools.thickness": "線條寬度",
    "paint.modeTools.flipHorizontal": "橫向翻轉",
    "paint.modeTools.flipVertical": "直向翻轉",
    "paint.modeTools.filled": "填滿",
    "paint.modeTools.outlined": "外框",
    "paint.paintEditor.bitmap": "轉換成點陣圖",
    "paint.paintEditor.vector": "轉換成向量圖",
    "paint.paintEditor.stroke": "外框",
    "paint.brushMode.brush": "筆刷",
    "paint.eraserMode.eraser": "擦子",
    "paint.fillMode.fill": "填滿",
    "paint.lineMode.line": "線條",
    "paint.ovalMode.oval": "圓形",
    "paint.rectMode.rect": "方形",
    "paint.reshapeMode.reshape": "重新塑形",
    "paint.roundedRectMode.roundedRect": "圓角方形",
    "paint.selectMode.select": "選取",
    "paint.textMode.text": "文字",
    "paint.colorPicker.swap": "交換",
    "gui.dialog.notConnectDevice": "沒有連接設備，請從菜單選擇需要連接的設備",
    "gui.dialog.prompt": "提示",
    "gui.dialog.openPort": "打開端口",
    "gui.dialog.error": "錯誤",
    "gui.dialog.notSelectDevice": "沒有選擇設備,請點擊軟件左下角”擴展“,</br>點擊選擇你需要連接的設備",
    "gui.dialog.connectDeviceError1": "連接設備(",
    "gui.dialog.connectDeviceError2": ")失敗，請進行如下檢查：</br> *更換usb口重新插拔usb線</br> *檢查是否連接了",
    "gui.dialog.connectDeviceError3": "主控板</br> *加入官方交流群(671877416)反饋問題",
    "gui.dialog.calibration1": "</br> *請轉動",
    "gui.dialog.calibration2": "主控板進行校準</br>",
    "gui.dialog.runAsManager": "請關閉軟件後在軟件圖標上右鍵選擇“以管理員身份運行”，啟動後再次選擇此功能",
    "gui.dialog.runErrorForVortex": "系統運行不正常，退出Vortex握手",
    "gui.dialog.runError": "系統運行不正常",
    "gui.dialog.close": "關閉",
    "gui.dialog.variableNameSpecialCharacters": "變量名 %1 不能包含特殊字符:[\\`~!-@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "函數名 %1 不能包含特殊字符:[\\`~!-@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "函數參數 %1 不能包含特殊字符:[\\`~!-@#$%^&*()+<>?:{},./;\\'[]\\]",
    "gui.dialog.and": "和",
    "gui.dialog.yes": "是",
    "gui.dialog.no": "否",
    "gui.dialog.continue": "继续",
    "gui.dialog.maxReset": "是否刪除編輯區代碼並加載出廠設置代碼",
    "gui.dialog.feedbackCannotEmpty": "意見/反饋 不能為空!",
    "gui.dialog.noInstallCompiler": "沒有安裝編譯器",
    "gui.dialog.install": "在線安裝",
    "gui.dialog.cancle": "取消",
    "gui.dialog.installingCompiler": "正在安裝編譯器",
    "gui.dialog.uploading": "正在上傳",
    "gui.dialog.changeLanuage": "切換語言將清除當前程序，是否繼續?",
    "gui.dialog.boardNotSupportMpy": "當前主板: %1 不支持microPython",
    "gui.dialog.loadBlankItem": "不支持的項目</br> 正在重新加載一個空白的項目",
    "gui.dialog.switchBaudError": "切換波特率出錯</br>",
    "gui.dialog.turnMicrobitCompass": "需要校准电子罗盘，请调整mciro:bit的姿态使闪烁的led灯移动，直到所有LED灯都点亮",
    "gui.dialog.turnCalliopeCompass": "需要校準電子羅盤，請調整calliope的姿態使閃爍的led燈移動，直到所有LED燈都點亮",
    "gui.dialog.operationShow": "操作演示",
    "gui.dialog.mpython": "掌控板",
    "gui.dialog.computer": "電腦",
    "gui.dialog.delete": "刪除",
    "gui.dialog.sureDeleteFile": "真的要刪除 %1 上的文件[%2]嗎？",
    "gui.dialog.sureDeleteFolder": "真的要刪除 %1 上的文件夾[%2]嗎？",
    "gui.dialog.uncorrectPath": "沒有選擇正確路徑",
    "gui.dialog.laterDownLoad": "稍後更新",
    "gui.dialog.downLoadUpdate": "下載更新",
    "gui.dialog.downLoading": "下载中...",
    "gui.dialog.dwnLoadWebsite": "去官網下載",
    "gui.dialog.laterUpdate": "稍後安裝",
    "gui.dialog.update": "安裝",
    "gui.dialog.getUpdateMsgError": "獲取的更新顯示信息出錯",
    "gui.dialog.ok": "確定",
    "gui.dialog.closeCodeBoxes": "確定要關閉 %1 個代碼框嗎？",
    "gui.dialog.emailError": "郵箱格式不正確!",
    "gui.dialog.emailEmpty": "郵箱不能為空!",
    "gui.dialog.bigFilePrompt": "文件有點大, 請耐心等待, 謝謝!",
    "gui.dialog.successFeedbackPrompt1": "謝謝你的反饋, 3秒後將重新加載頁面",
    "gui.dialog.successFeedbackPrompt2": "你的反饋意見已經提交, 請過幾秒鐘之後再提交吧",
    "gui.dialog.failedFeedbackPrompt": "提交反饋失敗, 給你造成的不便我們感到非常抱歉, 你還可以加官方QQ交流群進行反饋, 謝謝",
    "gui.dialog.successFeedbackPrompt3": "謝謝你的反饋",
    "gui.dialog.forcedCloseSoftWare": "保存文件出現錯誤, 是否強制關閉軟件",
    "gui.dialog.saveSuccess": "保存成功",
    "gui.dialog.noSelectTruePath": "沒有選擇正確的路徑",
    "gui.dialog.newItemContent": "你將創建一個空白的項目來覆蓋當前的項目，確認創建？",
    "gui.dialog.deleteBlocksError": "刪除 %1 的積木塊失敗，請手動刪除",
    "gui.dialog.netIsConnect": "請檢查一下網絡是否連接",
    "gui.dialog.needToUpdate": "是否需要更新編譯器到最新版本: %1",
    "gui.dialog.cantUseMpythonInArduinoC": "沒有安裝編譯器，在Arduino C下無法使用掌控板。若電腦沒有網絡可在官網下載使用“編譯器離線插件包”",
    "gui.dialog.enterMpyMode": "進入micropython模式",
    "gui.dialog.runHardware": "要操縱硬件,請先連接設備",
    "gui.dialog.sureDelete": "你確定要刪除嗎?",
    "gui.dialog.notSupportWebGL": "加載舞台發生錯誤，可能是不支持WebGL，</br>下面是詳細錯誤信息: </br> %1 </br>QQ群聯繫我們：671877416",
    "gui.dialog.boardIsnotMPython": "當前連接主控板不是mPython，請連接mPython",
    "gui.dialog.swdMicrobitUpload": "您的電腦必須使用SWD燒錄micro:bit，請以管理員權限運行Mind+",
    "gui.dialog.openPortError": "打開串口 %1 錯誤: %2",
    "gui.dialog.pressedA": "請按住按鍵A，不要鬆開",
    "gui.dialog.unpressedA": "請鬆開按鍵A",
    "gui.dialog.unsupportProject": "不支持的項目</br>正在重新加載一個空白項目",
    "gui.dialog.openProjectError": "實時模式下的項目必須在實時模式下打開</br>請檢查當前界面是在實時模式界面還是在上傳模式界面",
    "gui.dialog.installCompilerError1": "編譯器下載失敗, 你可以下載離線安裝器或嘗試重新下載",
    "gui.dialog.installCompilerOffline": "下載離線安裝器",
    "gui.dialog.installCompilerError2": "校驗出錯, 你可以下載離線安裝器或嘗試重新下載",
    "gui.dialog.installCompilerError3": "安裝出錯, 你可以下載離線安裝器或嘗試重新下載",
    "gui.dialog.installCompilerSuccess": "編譯器已經安裝完成，你可以在ArduinoC下使用掌控板",
    "gui.dialog.sureAdapter": "未找到適配器，請確認是否連接適配器",
    "gui.dialog.checkPort": "打開串口失敗，請嘗試重新連接串口，或檢查串口是否被佔用",
    "gui.dialog.changeBoard": "切換主控板將清除當前程序，是否繼續？",
    "gui.dialog.removeBoard": "移除主控板將清除當前程序，是否繼續？",
    "gui.crashMessage.somethingWrong": "哎呀！發生錯誤了.",
    "gui.crashMessage.cacheListPrompt": "你可以通过菜单「项目」>「缓存列表」中找到项目文件",
    "gui.crashMessage.reload": "重新启动",
    "gui.crashMessage.submit": "提交",
    "gui.crashMessage.attachment": "附件",
    "gui.crashMessage.errFeedbackNote": "你可以在這裡寫入錯誤信息或者建議, Mind+有你的加入會變得更好",
    "gui.crashMessage.promptContent": "很抱歉，好像Mind +已經崩潰。你可以在右側將此錯誤反饋給Mind +團隊, 非常感謝！請刷新您的頁面以重試。",
    "gui.dialog.isSaveProject": "是否保存當前項目？",
    "gui.dialog.save": "存儲",
    "gui.dialog.notSave": "不保存",
    "gui.dialog.cancelSave": "取消保存",
    "gui.dialog.saveMPProjectToSb3": "当前选择格式为.sb3，仅保存实时模式下的部分基础积木，是否继续保存?",
    "gui.dialog.variableIsNull": "變量名不能為空",
    "gui.dialog.variableIsRepeat": "變量名不能重複，請修改",
    "gui.dialog.listNameIsNull": "列表名不能為空",
    "gui.dialog.pictureAINotNull": "不能為空",
    "gui.dialog.pictureAIInitServer": "請先初始化服務器",
    "gui.dialog.pictureAIInitServerError": "初始化服務器出錯，請檢查賬號及秘鑰",
    "gui.dialog.versionPrompt": "當前sb3文件版本「%1」",
    "gui.dialog.versionGreater": "sb3文件版本高於當前軟件版本, sb3文件版本「%1」",
    "gui.dialog.noModulePrompt": "当前分类下无小模块，详见帮助",
    "gui.dialog.firstSelectBoard": "请先选择“主控板”或“套件”",
    "gui.dialog.downloadFileError": "下载文件出错",
    "gui.dialog.noSearchModules": "没有搜索到相关的模块, 请检查搜索路径",
    "gui.dialog.networkError": "网络错误",
    "gui.dialog.saveThirdModulesError": "保存用户库出错",
    "gui.dialog.coverUseExForLoadProject": "用户库中存在同名库，已经替换为项目中的用户库",
    "gui.dialog.isContinueUpdateThirdModule": "当前有相同的用户扩展库，继续更新会覆盖当前的用户库，是否继续？",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "修改了%1 的字段 「%2」, 需要手动在扩展库重新导入，本次刷新取消",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "解析 %1 出错, 将继续本次刷新, 但是不更新「%2」里的内容",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块，再刷新，本次刷新取消。",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh1": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块并检查精灵「%1」的积木块，再刷新，本次刷新取消。",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh2": "积木块形状或输入框类型修改，请检查精灵「%1」的积木块，再刷新，本次刷新取消。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块，再加载，本次加载取消。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad1": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块并检查精灵「%1」的积木块，再加载，本次加载取消。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad2": "积木块形状或输入框类型修改，请检查精灵 「%1」 的积木块，再加载，本次加载取消。",
    "gui.dialog.thirdex.filesEmpty": "files字段不能为空，可能会导致相关文件丢失，请检查导入的用户库文件！",
    "gui.dialog.thirdex.refreshing": "正在刷新...",
    "gui.dialog.thirdex.refreshSuccess": "刷新成功",
    "gui.dialog.isSaving": "正在保存...",
    "gui.dialog.isExporting": "正在导出...",
    "gui.dialog.mpySb3Prompt": "「%1」对micropython模式进行了更新，项目中的内容如有错误，需要您自己手动修改",
    "gui.dialog.translate.fieldError": "以下錯誤字段可能會導致積木塊顯示錯誤",
    "gui.dialog.translate.startReadFile": "開始讀取檔案, 請耐心等待...",
    "gui.dialog.translate.fileFormatError": "沒有相關的翻譯檔案內容，檔案內容格式不匹配",
    "gui.dialog.connecting.requestCloseFW": "檢測到本機的防火牆為打開狀態，這會導致Tello無法正常連接，點擊確定可關閉防火牆",
    "gui.dialog.cache.crash": "檢測到軟體異常關閉，可以通過快取檔案恢復項目。打開路徑\"項目\">\"緩存列表\"",
    "gui.dialog.python.detectPip": "pip環境初始化中，請稍候",
    "gui.dialog.python.detectPipSucccess": "pip環境初始化完成",
    "gui.dialog.python.detectPipError": "pip初始化失敗，庫管理功能不可用。請檢查網路連接，你也可以在命令列中運行 \"[PYTHON]\" -m pip install --upgrade pip ，成功後再重新使用該功能。",
    "gui.dialog.python.installPipreqsError": "[NAME] 安裝失敗，用戶庫將無法正常匯出自訂庫的依賴列表，請手動安裝。",
    "gui.dialog.screenshoting": "正在截圖...",
    "gui.dialog.saveScreenshot": "保存截圖",
    "gui.dialog.saveScreenshotSuccess": "保存截圖成功",
    "gui.dialog.serialDriverInstalling": "串口驅動安裝中...",
    "gui.dialog.resetDeviceSuccess": "恢復設備成功",
    "gui.dialog.resetDeviceFailed": "恢復設備失敗",
    "gui.dialog.exportSuccess": "匯出成功",
    "gui.dialog.exportFailed": "匯出失敗",
    "gui.dialog.exportCostume": "匯出角色",
    "gui.dialog.loadProjectError": "載入項目出錯",
    "gui.dialog.readFileError": "讀取文件出錯",
    "gui.dialog.projectIsDeleted": "專案已經被刪除",
    "gui.dialog.projectIsNotExist": "打開專案失敗，檔不存在",
    "gui.dialog.checkNetwork": "當前網路不通，請檢查電腦是否能上網",
    "gui.dialog.checkIotNetworkAndParameter": "物聯網連接失敗，請檢查參數是否填寫正確",
    "gui.dialog.knownError": "已知问题：",
    "gui.dialog.unknownError": "未知问题：",
    "gui.dialog.cancelInstall": "取消安装",
    "gui.dialog.cannotFindDriver": "没有找到驱动文件，请检查路径:",
    "gui.dialog.programError": "程序出错",
    "gui.dialog.pleaseHandleInstall": "请手动安装工具：",
    "gui.dialog.installSerialDriverError": "安装串口驱动出错",
    "gui.dialog.installSerialDriverSuccess": "串口驱动安装完成",
    "gui.dialog.hadInstall": "已安装",
    "gui.dialog.cache.sameAsCacheDeleteBlock": "缓存界面有相同的用户库，将会删除相关的积木块，是否继续",
    "gui.dialog.cache.saveAs": "另存为",
    "gui.dialog.cache.exportExtError": "导出扩展出错：",
    "gui.dialog.cache.exportExtSuccess": "导出扩展成功",
    "gui.dialog.thirdExt.lostVersionKeyWithThird": "缺少version字段，请检查version字段，应该与id字段同级",
    "gui.dialog.thirdExt.notFoundRelatedFile": "没有找到相关的文件",
    "gui.dialog.thirdExt.requestFileError": "请求文件出错",
    "gui.dialog.thirdExt.unzipLibrariesFailed": "解压libraries.zip失败",
    "gui.dialog.thirdExt.pipreqsLostAndFillConfig": "pipreqs 库未正确安装，自动导出依赖包清单出错。为方便用户使用，请手动在config.json中配置依赖库信息",
    "gui.dialog.thirdExt.exportExtToFolderSuccess": "成功导出扩展到目录",
    "gui.dialog.thirdExt.configJsonKeyLost": "'config.json中的[KEY]字段不能为空！'",
    "gui.dialog.thirdExt.configJsonKeyLost_ID": "config.json中的id是该模块的标识，不能为空！",
    "gui.dialog.thirdExt.configJsonKeyLost_platform": "platform定义支持的平台，至少支持一个平台（win,mac,web）！目前只支持win（windowns）平台",
    "gui.dialog.thirdExt.configJsonKeyLost_asset": "config.json中的asset定义支持的语言模式，不能为空！",
    "gui.dialog.thirdExt.configJsonError_asset": "config.json中的asset内容定义错误，至少支持一个语言模式",
    "gui.dialog.thirdExt.configJsonLost_version": "缺少version字段，请检查version字段，应该与id字段同级",
    "gui.dialog.thirdExt.configJsonLost_asset_mode": "config.json中的asset语言模式[MODE]的字段main不能为空！",
    "gui.dialog.thirdExt.configJsonError_mode_board": "语言模式[MODE]的字段board定义支持的主板情况，至少支持一个主板",
    "gui.dialog.stage.microphoneLost": "没有听到任何声音，请检查麦克风是否插好哦",
    "gui.dialog.ml5.closeMl5Modal": "关闭窗口会丢失训练中的数据，请及时保存，是否继续关闭",
    "gui.dialog.ml5.restartMl5Train": "该操作会删除所有分类，确认继续吗?",
    "gui.dialog.ml5.ml5DefaultLabel": "分类1",
    "gui.dialog.account.getCloudListError": "获取文件列表出错，是否重新加载",
    "gui.dialog.account.requestCloudList": "重新加载",
    "gui.dialog.python.getPIPListForUpdateError": "获取PIP更新数据超时",
    "gui.dialog.python.pipHasNoUpdate": "无可用更新",
    "gui.dialog.python.pipUpdateSucess": "成功升级[SUCCESS]个，[FAILED]个失败，分别为[INFO]",
    "gui.dialog.python.pipUpdateAllSucess": "升级成功，共[SUCCESS]个",
    "gui.dialog.python.pipUpdateError": "升级[NAME]失败，详情可在PIP模式页面查看    ",
    "gui.dialog.python.pipInstallError": "安装 [NAME] [VERSION]失败，详情可在PIP模式页面查看",
    "gui.dialog.python.pipUninstallSucess": "成功卸载[SUCCESS]个，[FAILED]个失败，分别为[INFO]",
    "gui.dialog.python.pipUninstallAllSucess": "卸载成功，共[COUNT]个",
    "gui.dialog.python.pipUninstallError": "卸载[NAME]失败，详情可在PIP模式页面查看",
    "gui.dialog.python.sourceUnknow": "未知源",
    "gui.dialog.python.sourceTsinghuaUniversity": "清华大学源",
    "gui.dialog.python.sourceDouban": "豆瓣源",
    "gui.dialog.python.sourceAli": "阿里源",
    "gui.dialog.python.sourceNeteasy": "网易源",
    "gui.dialog.python.sourceZGKJDX": "中国科技大学源",
    "gui.dialog.python.sourceOfficial": "Python官方源(国外)",
    "gui.dialog.python.rebootSoftForVsEditor": "代码编辑器相关功能的翻译需重启后生效",
    "gui.dialog.procedure.procedureNameIsRepeated": "函数「[NAME]」已定义，请重新命名",
    "gui.dialog.procedure.procedureNameIsEmpty": "函数名称不能为空",
    "gui.dialog.project.saveProjectCheck": "二次校验",
    "gui.dialog.project.trySaveProjectAgain": "抱歉，保存的文件解析出错了，请尝试重新保存",
    "gui.dialog.software.secondInstanceTips": "当前软件设置为不允许多开，如需修改，请在 [设置-系统设置-软件多开] 更新设置",
    "gui.dialog.project.saveFailed": "保存出错",
    "gui.dialog.pythonRemote.saveMPProjectToSb3": "当前选择格式为.sb3，仅保存实时模式下的部分基础积木，是否继续保存?",
    "gui.dialog.thirdExt.downloadNow": "立即下载",
    "gui.dialog.thirdExt.downloadingLib": "正在下载",
    "gui.dialog.thirdExt.downloadFailed": "下载失败",
    "gui.dialog.thirdExt.detectThirdExtLibrariesInfo": "检测到该用户库需要[NEED]个依赖库，[INSTALLED]个已存在，是否立即下载其余的[REST]个？",
    "gui.dialog.thirdExt.downloadFinishFailed": "下载[LENGTH]个库失败，为[INFO]，具体信息请至库管理查看",
    "gui.dialog.thirdExt.downloadFinishSuccess": "[LENGTH]个库下载成功, 具体信息请至库管理查看",
    "gui.dialog.pythonRemote.detectingRemoteLibraries": "检测[NAME]上的依赖库版本中，请稍候...",
    "gui.dialog.pythonRemote.detectRemoteLibrariesFinish": "检测完成，全部为新版本",
    "gui.dialog.pythonRemote.detectRemoteLibrariesInfo": "检测到<b>[NAME]</b>上有[LENGTH]个库缺失或版本过低，可能影响图形化功能的使用，是否通过Mind+离线更新？",
    "gui.dialog.pythonRemote.detectRemotePipError": "远程系统中PIP不可用，请确认Python环境存在后再使用库管理功能",
    "gui.dialog.thirdExt.supportedModesAndMotherboards": "用户库支持的模式和主板: ",
    "gui.dialog.thirdExt.offlineArduinoC": "上传模式 arduino C",
    "gui.dialog.thirdExt.offlineMicroPython": "上传模式 microPython",
    "gui.dialog.thirdExt.online": "实时模式",
    "gui.dialog.thirdExt.python": "Python模式",
    "gui.dialog.thirdExt.currentModeAndMotherboard": "Mind+ 当前模式和主板为",
    "gui.dialog.thirdExt.currentMode": "Mind+ 当前模式为",
    "gui.dialog.loadBlockError": "加载失败, 请切换到[%1]再加载该积木程序.",
    "gui.dialog.loadBoardError": "加载失败, 请先加载[%1]主板或套件.",
    "BACKDROPS_ARCTIC": "北極",
    "BACKDROPS_BASEBALL": "棒球場",
    "BACKDROPS_BASEBALL_2": "棒球場2",
    "BACKDROPS_BASKETBALL": "籃球場",
    "BACKDROPS_BASKETBALL_2": "籃球2",
    "BACKDROPS_BEACH_MALIBU": "馬里布海灘",
    "BACKDROPS_BEACH_RIO": "里約熱內盧海灘",
    "BACKDROPS_BEDROOM": "睡房",
    "BACKDROPS_BEDROOM_1": "睡房1",
    "BACKDROPS_BEDROOM_2": "睡房2",
    "BACKDROPS_BEDROOM_3": "臥室3",
    "BACKDROPS_BENCH_WITH_VIEW": "板凳查看",
    "BACKDROPS_BLUE_SKY": "藍天",
    "BACKDROPS_BLUE_SKY_2": "藍天2",
    "BACKDROPS_BLUE_SKY3": "藍天3",
    "BACKDROPS_BOARDWALK": "浮橋",
    "BACKDROPS_CANYON": "峽谷",
    "BACKDROPS_CASTLE_1": "城堡1",
    "BACKDROPS_CASTLE_2": "城堡2",
    "BACKDROPS_CASTLE_3": "城堡3",
    "BACKDROPS_CASTLE_4": "城堡4",
    "BACKDROPS_CHALKBOARD": "黑板",
    "BACKDROPS_CIRCLES": "圓",
    "BACKDROPS_CITY_WITH_WATER": "城市用水",
    "BACKDROPS_COLORFUL_CITY": "七彩城",
    "BACKDROPS_CONCERT": "音樂會",
    "BACKDROPS_DESERT": "沙漠",
    "BACKDROPS_FARM": "農場",
    "BACKDROPS_FIELD_AT_MIT": "現場為我的",
    "BACKDROPS_FLOWERS": "花卉",
    "BACKDROPS_FOREST": "森林",
    "BACKDROPS_GALAXY": "銀河",
    "BACKDROPS_GARDEN-ROCK": "花園岩",
    "BACKDROPS_GREEK_THEATER": "希臘劇院",
    "BACKDROPS_HALL": "大廳",
    "BACKDROPS_HAY_FIELD": "乾草場",
    "BACKDROPS_GRAFFITI": "塗鴉",
    "BACKDROPS_HEARTS1": "愛心1",
    "BACKDROPS_HEARTS2": "愛心2",
    "BACKDROPS_HILL": "爬坡道",
    "BACKDROPS_JUNGLE": "叢林",
    "BACKDROPS_JURASSIC": "侏羅紀",
    "BACKDROPS_LIGHT": "聚光燈",
    "BACKDROPS_METRO": "地鐵",
    "BACKDROPS_MOON": "月亮",
    "BACKDROPS_MOUNTAIN": "山脈",
    "BACKDROPS_MURAL": "壁畫",
    "BACKDROPS_NEBULA": "星雲",
    "BACKDROPS_NEON_TUNNEL": "霓虹隧道",
    "BACKDROPS_NIGHT_CITY": "夜城",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "夜晚的城市，街道",
    "BACKDROPS_PARTY": "聚會",
    "BACKDROPS_PATHWAY": "路",
    "BACKDROPS_PARTY_ROOM": "聚會房間",
    "BACKDROPS_PLAYGROUND": "操場",
    "BACKDROPS_PLAYING_FIELD": "運動場",
    "BACKDROPS_POOL": "池",
    "BACKDROPS_PURPLE": "紫色",
    "BACKDROPS_RAYS": "光線",
    "BACKDROPS_REFRIGERATOR": "冰箱",
    "BACKDROPS_ROOM_1": "1室",
    "BACKDROPS_ROOM_2": "2室",
    "BACKDROPS_SAVANNA": "稀樹草原",
    "BACKDROPS_SCHOOL": "學校",
    "BACKDROPS_SLOPES": "連續下坡",
    "BACKDROPS_SOCCER": "足球場",
    "BACKDROPS_SOCCER_2": "足球場2",
    "BACKDROPS_SPACE": "太空",
    "BACKDROPS_SPACE_2": "太空2",
    "BACKDROPS_SPACE_3": "太空3",
    "BACKDROPS_SPACE_4": "太空4",
    "BACKDROPS_SPOTLIGHT-STAGE": "舞台聚光燈",
    "BACKDROPS_STARS": "群星",
    "BACKDROPS_STRIPES": "條紋",
    "BACKDROPS_THEATER": "劇場",
    "BACKDROPS_THEATER_2": "劇院2",
    "BACKDROPS_TREE": "樹",
    "BACKDROPS_UNDERWATER_1": "海底世界1",
    "BACKDROPS_UNDERWATER_2": "海底世界2",
    "BACKDROPS_URBAN": "城市的",
    "BACKDROPS_WALL_1": "牆1",
    "BACKDROPS_WALL_2": "華爾街2",
    "BACKDROPS_WATER_AND_ROCKS": "水和岩石",
    "BACKDROPS_WETLAND": "濕地",
    "BACKDROPS_WINTER": "冬日",
    "BACKDROPS_WINTER-LIGHTS": "冬夜",
    "BACKDROPS_WITCH_HOUSE": "女巫小屋",
    "BACKDROPS_WOODS": "樹林",
    "BACKDROPS_WOODS_AND_BENCH": "伍茲和替補",
    "BACKDROPS_XY-GRID": "XY網格",
    "BACKDROPS_XY-GRID-20PX": "xy網格，為20px",
    "BACKDROPS_XY-GRID-30PX": "XY-網30PX",
    "COSTUMES_ABBY-A": "艾比-a",
    "COSTUMES_ABBY-B": "艾比-b",
    "COSTUMES_ABBY-C": "艾比-c",
    "COSTUMES_ABBY-D": "艾比-d",
    "COSTUMES_AMON": "阿蒙",
    "COSTUMES_ANDIE-A": "俺爹-A",
    "COSTUMES_ANDIE-B": "安迪-B",
    "COSTUMES_ANDIE-C": "安迪-C",
    "COSTUMES_ANDIE-D": "安迪-d",
    "COSTUMES_ANINA_POP_DOWN": "ANINA時彈出",
    "COSTUMES_ANINA_POP_FRONT": "流行鉤前",
    "COSTUMES_ANINA_POP_L_ARM": "ANINA流行金屬手臂",
    "COSTUMES_ANINA_POP_LEFT": "ANINA彈出左",
    "COSTUMES_ANINA_POP_R_ARM": "ANINA彈出R機殼",
    "COSTUMES_ANINA_POP_RIGHT": "ANINA流行的權利",
    "COSTUMES_ANINA_POP_STAND": "ANINA流行的立場",
    "COSTUMES_ANINA_R_CROSS": "ANINAř橫",
    "COSTUMES_ANINA_STANCE": "ANINA立場",
    "COSTUMES_ANINA_TOP_FREEZE": "熱門ANINA凍結",
    "COSTUMES_ANINA_TOP_L_STEP": "ANINA頂部l步進",
    "COSTUMES_ANINA_TOP_R_STEP": "ANINA回到頂層步驟",
    "COSTUMES_ANINA_TOP_STAND": "ANINA頂架",
    "COSTUMES_APPLE": "蘋果",
    "COSTUMES_ARROW1-A": "箭頭-右",
    "COSTUMES_ARROW1-B": "箭頭-左",
    "COSTUMES_ARROW1-C": "箭頭-下",
    "COSTUMES_ARROW1-D": "箭頭-上",
    "COSTUMES_AVERY_WALKING-A": "艾弗里-行走-a",
    "COSTUMES_AVERY_WALKING-B": "艾弗里-行走-b",
    "COSTUMES_AVERY_WALKING-C": "艾弗里-行走-c",
    "COSTUMES_AVERY_WALKING-D": "艾弗里-行走-d",
    "COSTUMES_AVERY-A": "艾弗里-a",
    "COSTUMES_AVERY-B": "艾弗里-b",
    "COSTUMES_BALL-A": "球-黃",
    "COSTUMES_BALL-B": "球-藍",
    "COSTUMES_BALL-C": "球-粉",
    "COSTUMES_BALL-D": "球-綠",
    "COSTUMES_BALL-E": "球-紫",
    "COSTUMES_BALLERINA-A": "芭蕾舞女孩-a",
    "COSTUMES_BALLERINA-B": "芭蕾舞女孩-b",
    "COSTUMES_BALLERINA-C": "芭蕾舞女孩-c",
    "COSTUMES_BALLERINA-D": "芭蕾舞女孩-d",
    "COSTUMES_BALLOON1-A": "氣球-藍",
    "COSTUMES_BALLOON1-B": "氣球-黃",
    "COSTUMES_BALLOON1-C": "氣球-紫",
    "COSTUMES_BANANAS": "香蕉",
    "COSTUMES_BASEBALL": "棒球",
    "COSTUMES_BASKETBALL": "籃球",
    "COSTUMES_BAT-A": "蝙蝠一",
    "COSTUMES_BAT-B": "蝙蝠-B",
    "COSTUMES_BAT-C": "蝙蝠-C",
    "COSTUMES_BAT-D": "BAT-d",
    "COSTUMES_BATTER-A": "麵糊一",
    "COSTUMES_BATTER-B": "連擊-B",
    "COSTUMES_BATTER-C": "連擊-C",
    "COSTUMES_BATTER-D": "連擊-d",
    "COSTUMES_BEACHBALL": "沙灘球",
    "COSTUMES_BEAR-A": "熊-a",
    "COSTUMES_BEAR-B": "熊-b",
    "COSTUMES_BEAR-WALK-A": "熊-行走-a",
    "COSTUMES_BEAR-WALK-B": "熊-行走-b",
    "COSTUMES_BEAR-WALK-C": "熊-行走-c",
    "COSTUMES_BEAR-WALK-D": "熊-行走-d",
    "COSTUMES_BEAR-WALK-E": "熊-行走-e",
    "COSTUMES_BEAR-WALK-F": "熊-行走-f",
    "COSTUMES_BEAR-WALK-G": "熊-行走-g",
    "COSTUMES_BEAR-WALK-H": "熊-行走-h",
    "COSTUMES_BEETLE": "甲殼蟲",
    "COSTUMES_BELL1": "鈴鐺-a",
    "COSTUMES_BEN-A": "本-a",
    "COSTUMES_BEN-B": "本-b",
    "COSTUMES_BEN-C": "本-c",
    "COSTUMES_BEN-D": "本-d",
    "COSTUMES_BOWL-A": "碗-a",
    "COSTUMES_BOWTIE": "領結",
    "COSTUMES_BREAD": "麵包",
    "COSTUMES_BROOM": "掃帚",
    "COSTUMES_BUILDING-A": "建築-a",
    "COSTUMES_BUILDING-B": "建築-b",
    "COSTUMES_BUILDING-C": "建築-c",
    "COSTUMES_BUILDING-D": "建築-d",
    "COSTUMES_BUILDING-E": "建築-e",
    "COSTUMES_BUILDING-F": "建築-f",
    "COSTUMES_BUILDING-G": "建築-g",
    "COSTUMES_BUILDING-H": "建築-h",
    "COSTUMES_BUILDING-I": "建築-i",
    "COSTUMES_BUILDING-J": "建築-j",
    "COSTUMES_BUTTERFLY1-A": "蝴蝶1-a",
    "COSTUMES_BUTTERFLY1-B": "butterfly1-B",
    "COSTUMES_BUTTERFLY1-C": "butterfly1-C",
    "COSTUMES_BUTTERFLY2-A": "butterfly2-A",
    "COSTUMES_BUTTERFLY2-B": "butterfly2-B",
    "COSTUMES_BUTTON1": "圓形按鈕",
    "COSTUMES_BUTTON2-A": "橢圓形按鈕-a",
    "COSTUMES_BUTTON2-B": "橢圓形按鈕-b",
    "COSTUMES_BUTTON3-A": "方形按鈕-a",
    "COSTUMES_BUTTON3-B": "方形按鈕-b",
    "COSTUMES_BUTTON4-A": "勾形按鈕-a",
    "COSTUMES_BUTTON4-B": "勾形按鈕-b",
    "COSTUMES_BUTTON5-A": "叉形按鈕-a",
    "COSTUMES_BUTTON5-B": "叉形按鈕-b",
    "COSTUMES_CAKE-A": "蛋糕-a",
    "COSTUMES_CAKE-B": "蛋糕-b",
    "COSTUMES_CALVRETT_JUMPING": "calvrett跳躍",
    "COSTUMES_CALVRETT_THINKING": "calvrett思維",
    "COSTUMES_CASEY-A": "凱西-a",
    "COSTUMES_CASEY-B": "凱西-b",
    "COSTUMES_CASEY-C": "凱西-c",
    "COSTUMES_CASEY-D": "凱西-d",
    "COSTUMES_CASSY-A": "卡西-A",
    "COSTUMES_CASSY-B": "卡西-B",
    "COSTUMES_CASSY-C": "卡西-C",
    "COSTUMES_CASSY-D": "卡西-d",
    "COSTUMES_CAT_2": "貓2",
    "COSTUMES_CATCHER-A": "捕手，一",
    "COSTUMES_CATCHER-B": "捕手-B",
    "COSTUMES_CATCHER-C": "捕手-C",
    "COSTUMES_CATCHER-D": "捕手-d",
    "COSTUMES_CENTAUR-A": "半人馬-a",
    "COSTUMES_CENTAUR-B": "半人馬-b",
    "COSTUMES_CENTAUR-C": "半人馬-c",
    "COSTUMES_CENTAUR-D": "半人馬-d",
    "COSTUMES_CHAMP99-A": "champ99-A",
    "COSTUMES_CHAMP99-B": "champ99-B",
    "COSTUMES_CHAMP99-C": "champ99-C",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-E",
    "COSTUMES_CHAMP99-F": "champ99-F",
    "COSTUMES_CHAMP99-G": "champ99-克",
    "COSTUMES_CHEESY_PUFFS": "俗氣泡芙",
    "COSTUMES_CHICK-A": "小雞一個",
    "COSTUMES_CHICK-B": "小雞-B",
    "COSTUMES_CHICK-C": "小雞-C",
    "COSTUMES_CITY_BUS-A": "城市公交車，一",
    "COSTUMES_CITY_BUS-B": "城市客車-B",
    "COSTUMES_CLOUD": "白雲",
    "COSTUMES_CLOUD-A": "白雲1-a",
    "COSTUMES_CLOUD-B": "白雲1-b",
    "COSTUMES_CLOUD-C": "白雲1-c",
    "COSTUMES_CLOUD-D": "白雲1-d",
    "COSTUMES_CONVERTIBLE": "可兌換",
    "COSTUMES_CONVERTIBLE_3": "兌換3",
    "COSTUMES_CRAB-A": "螃蟹-a",
    "COSTUMES_CRAB-B": "螃蟹-b",
    "COSTUMES_CRYSTAL-A": "鑽石-a",
    "COSTUMES_CRYSTAL-B": "鑽石-b",
    "COSTUMES_DAN-A": "和一",
    "COSTUMES_DAN-B": "和-B",
    "COSTUMES_DANI-A": "達尼-a",
    "COSTUMES_DANI-B": "達尼-b",
    "COSTUMES_DANI-C": "達尼-c",
    "COSTUMES_DEE-A": "迪伊-a",
    "COSTUMES_DEE-B": "迪伊-b",
    "COSTUMES_DEE-C": "迪伊-c",
    "COSTUMES_DEE-D": "迪伊-d",
    "COSTUMES_DEE-E": "迪伊-e",
    "COSTUMES_DEVIN-A": "德溫-a",
    "COSTUMES_DEVIN-B": "德溫-b",
    "COSTUMES_DEVIN-C": "德溫-c",
    "COSTUMES_DEVIN-D": "德溫-d",
    "COSTUMES_DINOSAUR1-A": "恐龍1-a",
    "COSTUMES_DINOSAUR1-B": "恐龍1-b",
    "COSTUMES_DINOSAUR1-C": "恐龍1-c",
    "COSTUMES_DINOSAUR1-D": "恐龍1-d",
    "COSTUMES_DINOSAUR2-A": "恐龍2-a",
    "COSTUMES_DINOSAUR2-B": "恐龍2-b",
    "COSTUMES_DINOSAUR2-C": "恐龍2-c",
    "COSTUMES_DINOSAUR2-D": "恐龍2-d",
    "COSTUMES_DINOSAUR3-A": "恐龍3-a",
    "COSTUMES_DINOSAUR3-B": "恐龍3-b",
    "COSTUMES_DINOSAUR3-C": "恐龍3-c",
    "COSTUMES_DINOSAUR3-D": "恐龍3-d",
    "COSTUMES_DINOSAUR3-E": "恐龍3-e",
    "COSTUMES_DINOSAUR4-A": "恐龍4-a",
    "COSTUMES_DINOSAUR4-B": "恐龍4-b",
    "COSTUMES_DINOSAUR4-C": "恐龍4-c",
    "COSTUMES_DINOSAUR4-D": "恐龍4-d",
    "COSTUMES_DIVER1": "潛水員1",
    "COSTUMES_DIVER2": "潛水員2",
    "COSTUMES_DM_FREEZE": "DM凍結",
    "COSTUMES_DM_POP_DOWN": "DM時彈出",
    "COSTUMES_DM_POP_FRONT": "流行的dm前",
    "COSTUMES_DM_POP_L_ARM": "的dm流行金屬手臂",
    "COSTUMES_DM_POP_LEFT": "流行的dm左",
    "COSTUMES_DM_POP_R_ARM": "彈出的dm R機殼",
    "COSTUMES_DM_POP_RIGHT": "流行的dm權",
    "COSTUMES_DM_POP_STAND": "流行的dm立場",
    "COSTUMES_DM_STANCE": "DM立場",
    "COSTUMES_DM_TOP_L_LEG": "頂部的dm L形腿",
    "COSTUMES_DM_TOP_R_LEG": "的dm回到頂層腿",
    "COSTUMES_DM_TOP_R_LEG2": "的dm回到頂層LEG2",
    "COSTUMES_DM_TOP_STAND": "DM頂架",
    "COSTUMES_DOG1-A": "小狗1-a",
    "COSTUMES_DOG1-B": "小狗1-b",
    "COSTUMES_DOG2-A": "小狗2-a",
    "COSTUMES_DOG2-B": "小狗2-b",
    "COSTUMES_DOG2-C": "小狗2-c",
    "COSTUMES_DONUT": "圓環",
    "COSTUMES_DORIAN-A": "多里安人-a",
    "COSTUMES_DORIAN-B": "多里安人-b",
    "COSTUMES_DORIAN-C": "多里安人-c",
    "COSTUMES_DORIAN-D": "多里安人-d",
    "COSTUMES_DOT-A": "點點-a",
    "COSTUMES_DOT-B": "點點-b",
    "COSTUMES_DOT-C": "點點-c",
    "COSTUMES_DOT-D": "點點-d",
    "COSTUMES_DOVE-A": "鴿子-a",
    "COSTUMES_DOVE-B": "鴿子-b",
    "COSTUMES_DRAGON-A": "龍-a",
    "COSTUMES_DRAGON-B": "龍-b",
    "COSTUMES_DRAGON-C": "龍-c",
    "COSTUMES_DRAGON1-A": "龍-a",
    "COSTUMES_DRAGON1-B": "龍-b",
    "COSTUMES_DRAGONFLY-A": "蜻蜓-A",
    "COSTUMES_DRAGONFLY-B": "蜻蜓-B",
    "COSTUMES_DRESS-A": "裝扮一",
    "COSTUMES_DRESS-B": "禮服-B",
    "COSTUMES_DRESS-C": "禮服-C",
    "COSTUMES_DRUM-A": "鼓-a",
    "COSTUMES_DRUM-B": "鼓-b",
    "COSTUMES_DRUM-CYMBAL-A": "镲-a",
    "COSTUMES_DRUM-CYMBAL-B": "镲-b",
    "COSTUMES_DRUM-HIGHHAT-A": "踩镲-a",
    "COSTUMES_DRUM-HIGHHAT-B": "踩镲-b",
    "COSTUMES_DRUM-KIT": "爵士鼓",
    "COSTUMES_DRUM-KIT-B": "爵士鼓-b",
    "COSTUMES_DRUM-SNARE-A": "小鼓-a",
    "COSTUMES_DRUM-SNARE-B": "小鼓-b",
    "COSTUMES_DRUMS_CONGA-A": "康加鼓-A",
    "COSTUMES_DRUMS_CONGA-B": "康加鼓-B",
    "COSTUMES_DUCK": "鴨子",
    "COSTUMES_EARTH": "地球",
    "COSTUMES_EASEL-A": "畫架，一個",
    "COSTUMES_EASEL-B": "畫架-B",
    "COSTUMES_EASEL-C": "畫架-C",
    "COSTUMES_EGG-A": "蛋-a",
    "COSTUMES_EGG-B": "蛋-b",
    "COSTUMES_EGG-C": "蛋-c",
    "COSTUMES_EGG-D": "蛋-d",
    "COSTUMES_EGG-E": "蛋-e",
    "COSTUMES_EGG-F": "雞蛋-F",
    "COSTUMES_ELEPHANT-A": "大象一",
    "COSTUMES_ELEPHANT-B": "大象-B",
    "COSTUMES_ELF-A": "小精靈-a",
    "COSTUMES_ELF-B": "小精靈-b",
    "COSTUMES_ELF-C": "小精靈-c",
    "COSTUMES_ELF-D": "小精靈-d",
    "COSTUMES_ELF-E": "小精靈-e",
    "COSTUMES_FAIRY-A": "精靈-a",
    "COSTUMES_FAIRY-B": "精靈-b",
    "COSTUMES_FAIRY-C": "精靈-c",
    "COSTUMES_FAIRY-D": "精靈-d",
    "COSTUMES_FAIRY-E": "精靈-e",
    "COSTUMES_FISH-A": "魚-a",
    "COSTUMES_FISH-B": "魚-b",
    "COSTUMES_FISH-C": "魚-c",
    "COSTUMES_FISH-D": "魚-d",
    "COSTUMES_FISHBOWL-A": "魚缸-A",
    "COSTUMES_FISHBOWL-B": "魚缸-B",
    "COSTUMES_FOOD_TRUCK-A": "食品卡車一",
    "COSTUMES_FOOD_TRUCK-B": "餐車-B",
    "COSTUMES_FOOD_TRUCK-C": "餐車-C",
    "COSTUMES_FOOTBALL_RUNNING": "足球運行",
    "COSTUMES_FOOTBALL_STANDING": "足球地位",
    "COSTUMES_FORTUNE_COOKIE": "幸運餅乾",
    "COSTUMES_FOX-A": "狐狸-a",
    "COSTUMES_FOX-B": "狐狸-b",
    "COSTUMES_FOX-C": "狐狸-c",
    "COSTUMES_FRANK-A": "坦率-A",
    "COSTUMES_FRANK-B": "坦率-B",
    "COSTUMES_FRANK-C": "坦率-C",
    "COSTUMES_FRANK-D": "坦率-d",
    "COSTUMES_FROG": "青蛙",
    "COSTUMES_FROG_2-A": "青蛙2-A",
    "COSTUMES_FROG_2-B": "蛙2-B",
    "COSTUMES_FROG_2-C": "青蛙2-C",
    "COSTUMES_FRUIT_PLATTER": "水果拼盤",
    "COSTUMES_FRUITSALAD": "水果沙拉",
    "COSTUMES_GHOST-A": "鬼一",
    "COSTUMES_GHOST-B": "鬼-B",
    "COSTUMES_GHOST-C": "鬼-C",
    "COSTUMES_GHOST-D": "鬼-d",
    "COSTUMES_GIFT-A": "禮物-a",
    "COSTUMES_GIFT-B": "禮物-b",
    "COSTUMES_GIRAFFE-A": "長頸鹿一",
    "COSTUMES_GIRAFFE-B": "長頸鹿-B",
    "COSTUMES_GIRAFFE-C": "長頸鹿-C",
    "COSTUMES_GLASS_WATER-A": "水杯-a",
    "COSTUMES_GLASS_WATER-B": "水杯-b",
    "COSTUMES_GLASSES-A": "眼鏡一",
    "COSTUMES_GLASSES-B": "眼鏡-B",
    "COSTUMES_GLASSES-C": "眼鏡-C",
    "COSTUMES_GLASSES-E": "眼鏡-E",
    "COSTUMES_GOALIE-A": "守門員-a",
    "COSTUMES_GOALIE-B": "守門員-b",
    "COSTUMES_GOALIE-C": "守門員-c",
    "COSTUMES_GOALIE-D": "守門員-d",
    "COSTUMES_GOALIE-E": "守門員-e",
    "COSTUMES_GOBLIN-A": "哥布林-a",
    "COSTUMES_GOBLIN-B": "哥布林-b",
    "COSTUMES_GOBLIN-C": "哥布林-c",
    "COSTUMES_GOBLIN-D": "哥布林-d",
    "COSTUMES_GRASSHOPPER-A": "蟈蟈-A",
    "COSTUMES_GRASSHOPPER-B": "草蜢-B",
    "COSTUMES_GRASSHOPPER-C": "草蜢-C",
    "COSTUMES_GRASSHOPPER-D": "草蜢-d",
    "COSTUMES_GRASSHOPPER-E": "草蜢-E",
    "COSTUMES_GRASSHOPPER-F": "草蜢-F",
    "COSTUMES_GREEN_FLAG": "綠旗",
    "COSTUMES_GRIFFIN-A": "格里芬-a",
    "COSTUMES_GRIFFIN-B": "格里芬-B",
    "COSTUMES_GRIFFIN-C": "格里芬-C",
    "COSTUMES_GRIFFIN-D": "格里芬-d",
    "COSTUMES_GUITAR-A": "吉他-a",
    "COSTUMES_GUITAR-B": "吉他-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "電吉他1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "電吉他1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "電吉他-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "電吉他-b",
    "COSTUMES_HANNAH-A": "漢娜-A",
    "COSTUMES_HANNAH-B": "漢娜-B",
    "COSTUMES_HANNAH-C": "漢娜-C",
    "COSTUMES_HARE-A": "野兔一",
    "COSTUMES_HARE-B": "野兔-B",
    "COSTUMES_HARE-C": "野兔-C",
    "COSTUMES_HARPER-A": "哈珀-A",
    "COSTUMES_HARPER-B": "哈珀-B",
    "COSTUMES_HARPER-C": "哈珀-C",
    "COSTUMES_HAT-A": "帽子一",
    "COSTUMES_HAT-B": "帽子-B",
    "COSTUMES_HAT-C": "帽子-C",
    "COSTUMES_HAT-D": "帽子-d",
    "COSTUMES_HATCHLING-A": "幼體-A",
    "COSTUMES_HATCHLING-B": "幼體-B",
    "COSTUMES_HATCHLING-C": "幼體-C",
    "COSTUMES_HEART_CODE": "糖心-b",
    "COSTUMES_HEART_FACE": "心表情",
    "COSTUMES_HEART_LOVE": "心臟愛情",
    "COSTUMES_HEART_PURPLE": "愛心-紫",
    "COSTUMES_HEART_RED": "愛心-紅",
    "COSTUMES_HEART_SMILE": "糖心-d",
    "COSTUMES_HEART_SWEET": "糖心-c",
    "COSTUMES_HEDGEHOG-A": "刺猬-a",
    "COSTUMES_HEDGEHOG-B": "刺猬-b",
    "COSTUMES_HEDGEHOG-C": "刺猬-c",
    "COSTUMES_HEDGEHOG-D": "刺猬-d",
    "COSTUMES_HEDGEHOG-E": "刺猬-e",
    "COSTUMES_HEN-A": "母雞一",
    "COSTUMES_HEN-B": "母雞-B",
    "COSTUMES_HEN-C": "他們ç",
    "COSTUMES_HEN-D": "他們-d",
    "COSTUMES_HIPPO1-A": "河馬-a",
    "COSTUMES_HIPPO1-B": "河馬-b",
    "COSTUMES_HOME_BUTTON": "房型按鈕",
    "COSTUMES_HORSE-A": "馬一",
    "COSTUMES_HORSE-B": "馬-B",
    "COSTUMES_JAIME_WALKING-A": "海梅行走一",
    "COSTUMES_JAIME_WALKING-B": "海梅步行-B",
    "COSTUMES_JAIME_WALKING-C": "海梅步行-C",
    "COSTUMES_JAIME_WALKING-D": "海梅步行-d",
    "COSTUMES_JAIME_WALKING-E": "海梅步行-E",
    "COSTUMES_JAIME-A": "海梅-A",
    "COSTUMES_JAIME-B": "海梅-B",
    "COSTUMES_JAMAL-A": "賈馬爾 - 一個",
    "COSTUMES_JAMAL-B": "賈馬爾-B",
    "COSTUMES_JAMAL-C": "賈馬爾-C",
    "COSTUMES_JAMAL-D": "賈馬爾 - d",
    "COSTUMES_JAR-A": "罐子-a",
    "COSTUMES_JAR-B": "罐子-b",
    "COSTUMES_JELLYFISH-A": "水母-a",
    "COSTUMES_JELLYFISH-B": "水母-b",
    "COSTUMES_JELLYFISH-C": "水母-c",
    "COSTUMES_JELLYFISH-D": "水母-d",
    "COSTUMES_JO_POP_DOWN": "喬時彈出",
    "COSTUMES_JO_POP_FRONT": "你彈出前",
    "COSTUMES_JO_POP_L_ARM": "您流行金屬手臂",
    "COSTUMES_JO_POP_LEFT": "喬噗離開",
    "COSTUMES_JO_POP_R_ARM": "你彈出R機殼",
    "COSTUMES_JO_POP_RIGHT": "你彈出權",
    "COSTUMES_JO_POP_STAND": "你的娃娃立場",
    "COSTUMES_JO_STANCE": "是的立場",
    "COSTUMES_JO_TOP_L_CROSS": "我頂橫",
    "COSTUMES_JO_TOP_L_LEG": "我上面的腿",
    "COSTUMES_JO_TOP_R_CROSS": "裘回到頂層橫",
    "COSTUMES_JO_TOP_R_LEG": "裘回到頂層腿",
    "COSTUMES_JO_TOP_STAND": "您的榜首位置",
    "COSTUMES_JORDYN-A": "喬丁-a",
    "COSTUMES_JORDYN-B": "喬丁-b",
    "COSTUMES_JORDYN-C": "喬丁-c",
    "COSTUMES_JORDYN-D": "喬丁-d",
    "COSTUMES_KAI-A": "開一個",
    "COSTUMES_KAI-B": "佳-B",
    "COSTUMES_KEY": "鑰匙",
    "COSTUMES_KEYBOARD-A": "鍵盤-a",
    "COSTUMES_KEYBOARD-B": "鍵盤-b",
    "COSTUMES_KIRAN-A": "基蘭-a",
    "COSTUMES_KIRAN-B": "基蘭-b",
    "COSTUMES_KIRAN-C": "基蘭-c",
    "COSTUMES_KIRAN-D": "基蘭-d",
    "COSTUMES_KIRAN-E": "基蘭-e",
    "COSTUMES_KIRAN-F": "基蘭-f",
    "COSTUMES_KNIGHT": "騎士",
    "COSTUMES_LADYBUG2": "七星瓢蟲1",
    "COSTUMES_LADYBUG2-A": "七星瓢蟲2-a",
    "COSTUMES_LADYBUG2-B": "七星瓢蟲2-b",
    "COSTUMES_LAPTOP": "筆記本電腦",
    "COSTUMES_LB_POP_DOWN": "磅時彈出",
    "COSTUMES_LB_POP_FRONT": "磅流行前線",
    "COSTUMES_LB_POP_L_ARM": "磅流行金屬手臂",
    "COSTUMES_LB_POP_LEFT": "磅噗離開",
    "COSTUMES_LB_POP_R_ARM": "磅彈出R機殼",
    "COSTUMES_LB_POP_RIGHT": "磅彈出權",
    "COSTUMES_LB_POP_STAND": "磅流行的立場",
    "COSTUMES_LB_STANCE": "磅立場",
    "COSTUMES_LB_TOP_L_CROSS": "磅頂部大號橫",
    "COSTUMES_LB_TOP_L_LEG": "磅頂部的L形腿",
    "COSTUMES_LB_TOP_R_CROSS": "磅回到頂層橫",
    "COSTUMES_LB_TOP_R_LEG": "磅回到頂層腿",
    "COSTUMES_LB_TOP_STAND": "磅頂架",
    "COSTUMES_LIGHTNING": "閃電",
    "COSTUMES_LINE": "線",
    "COSTUMES_LION-A": "獅子a",
    "COSTUMES_LION-B": "獅子-b",
    "COSTUMES_LION-C": "獅子-C",
    "COSTUMES_LLAMA": "無峰駝",
    "COSTUMES_LLAMA-B": "無峰駝-a",
    "COSTUMES_LLAMA-C": "無峰駝-b",
    "COSTUMES_MAGICWAND": "魔棒",
    "COSTUMES_MAX-A": "麥克斯-a",
    "COSTUMES_MAX-B": "麥克斯-b",
    "COSTUMES_MAX-C": "麥克斯-c",
    "COSTUMES_MAX-D": "麥克斯-d",
    "COSTUMES_MERMAID-A": "美人魚-a",
    "COSTUMES_MERMAID-B": "美人魚-b",
    "COSTUMES_MERMAID-C": "美人魚-C",
    "COSTUMES_MERMAID-D": "美人魚d",
    "COSTUMES_MICROPHONE-A": "麥克風-a",
    "COSTUMES_MICROPHONE-B": "麥克風-b",
    "COSTUMES_MILK-A": "牛奶-a",
    "COSTUMES_MILK-B": "牛奶-b",
    "COSTUMES_MILK-C": "牛奶-c",
    "COSTUMES_MILK-D": "牛奶-d",
    "COSTUMES_MILK-E": "牛奶-e",
    "COSTUMES_MONET-A": "莫奈-a",
    "COSTUMES_MONET-B": "莫奈-b",
    "COSTUMES_MONET-C": "莫奈-c",
    "COSTUMES_MONET-D": "莫奈-d",
    "COSTUMES_MONET-E": "莫奈-e",
    "COSTUMES_MONKEY-A": "猴子-a",
    "COSTUMES_MONKEY-B": "猴子-b",
    "COSTUMES_MONKEY-C": "猴子-c",
    "COSTUMES_MOTORCYCLE-A": "摩托車-A",
    "COSTUMES_MOTORCYCLE-B": "摩托車-B",
    "COSTUMES_MOTORCYCLE-C": "摩托車-C",
    "COSTUMES_MOTORCYCLE-D": "摩托車-d",
    "COSTUMES_MOUSE1-A": "老鼠-a",
    "COSTUMES_MOUSE1-B": "老鼠-b",
    "COSTUMES_MUFFIN-A": "鬆餅，一",
    "COSTUMES_MUFFIN-B": "鬆餅-B",
    "COSTUMES_NEIGH_PONY": "小馬駒",
    "COSTUMES_OCTOPUS-A": "章魚-a",
    "COSTUMES_OCTOPUS-B": "章魚-b",
    "COSTUMES_OCTOPUS-C": "章魚-c",
    "COSTUMES_OCTOPUS-D": "章魚-d",
    "COSTUMES_OCTOPUS-E": "章魚-e",
    "COSTUMES_ORANGE": "橘子",
    "COSTUMES_ORANGE2-A": "橘子2-a",
    "COSTUMES_ORANGE2-B": "橘子2-b",
    "COSTUMES_OUTFIELDER-A": "外野手-A",
    "COSTUMES_OUTFIELDER-B": "外野手-B",
    "COSTUMES_OUTFIELDER-C": "外野手-C",
    "COSTUMES_OUTFIELDER-D": "外野手-d",
    "COSTUMES_OWL-A": "貓頭鷹-a",
    "COSTUMES_OWL-B": "貓頭鷹-b",
    "COSTUMES_OWL-C": "貓頭鷹-c",
    "COSTUMES_PADDLE": "槳-a",
    "COSTUMES_PANTHER-A": "美洲豹-a",
    "COSTUMES_PANTHER-B": "美洲豹-b",
    "COSTUMES_PANTHER-C": "美洲豹-c",
    "COSTUMES_PANTS-A": "褲子，一",
    "COSTUMES_PANTS-B": "褲子-B",
    "COSTUMES_PARROT-A": "鸚鵡-a",
    "COSTUMES_PARROT-B": "鸚鵡-b",
    "COSTUMES_PARTY_HAT-A": "黨的帽子，一",
    "COSTUMES_PARTY_HAT-B": "黨的帽子-B",
    "COSTUMES_PARTY_HAT-E": "黨的帽子-E",
    "COSTUMES_PENCIL-A": "鉛筆-a",
    "COSTUMES_PENCIL-B": "鉛筆-b",
    "COSTUMES_PENGUIN-A": "企鵝一",
    "COSTUMES_PENGUIN-B": "企鵝-B",
    "COSTUMES_PENGUIN-C": "企鵝-C",
    "COSTUMES_PENGUIN2-A": "企鵝2-a",
    "COSTUMES_PENGUIN2-B": "企鵝2-b",
    "COSTUMES_PENGUIN2-C": "企鵝2-c",
    "COSTUMES_PENGUIN2-D": "penguin2-d",
    "COSTUMES_PITCHER-A": "投手，一",
    "COSTUMES_PITCHER-B": "投手-B",
    "COSTUMES_PITCHER-C": "投手-C",
    "COSTUMES_PITCHER-D": "投手-d",
    "COSTUMES_PLANET2": "行星",
    "COSTUMES_POLAR_BEAR-A": "北極熊-A",
    "COSTUMES_POLAR_BEAR-B": "北極熊-B",
    "COSTUMES_POLAR_BEAR-C": "北極熊-C",
    "COSTUMES_POTION-A": "藥劑-a",
    "COSTUMES_POTION-B": "藥劑-b",
    "COSTUMES_POTION-C": "藥劑-c",
    "COSTUMES_PRINCE": "王子",
    "COSTUMES_PRINCESS-A": "公主-a",
    "COSTUMES_PRINCESS-B": "公主-b",
    "COSTUMES_PRINCESS-C": "公主-c",
    "COSTUMES_PRINCESS-D": "公主-d",
    "COSTUMES_PRINCESS-E": "公主-e",
    "COSTUMES_PUFFERFISH-A": "河豚-a",
    "COSTUMES_PUFFERFISH-B": "河豚-b",
    "COSTUMES_PUFFERFISH-C": "河豚-c",
    "COSTUMES_PUFFERFISH-D": "河豚-d",
    "COSTUMES_PUPPY_BACK": "小狗回來",
    "COSTUMES_PUPPY_RIGHT": "小狗權",
    "COSTUMES_PUPPY_SIDE": "小狗側",
    "COSTUMES_PUPPY_SIT": "小狗坐",
    "COSTUMES_RABBIT-A": "兔子-a",
    "COSTUMES_RABBIT-B": "兔子-b",
    "COSTUMES_RABBIT-C": "兔子-c",
    "COSTUMES_RABBIT-D": "兔子-d",
    "COSTUMES_RABBIT-E": "兔子-e",
    "COSTUMES_RADIO-A": "無線電一",
    "COSTUMES_RADIO-B": "無線電-B",
    "COSTUMES_RAINBOW": "彩虹",
    "COSTUMES_REFEREE-A": "裁判員-a",
    "COSTUMES_REFEREE-B": "試用研究員-b",
    "COSTUMES_REFEREE-C": "裁判員-c",
    "COSTUMES_REFEREE-D": "裁判員-d",
    "COSTUMES_REINDEER": "馴鹿",
    "COSTUMES_RETRO_ROBOT_A": "復古機器人",
    "COSTUMES_RETRO_ROBOT_B": "復古自動化控制裝置B",
    "COSTUMES_RETRO_ROBOT_C": "復古機器人Ç",
    "COSTUMES_RIPLEY-A": "里普利-a",
    "COSTUMES_RIPLEY-B": "里普利-b",
    "COSTUMES_RIPLEY-C": "里普利-c",
    "COSTUMES_RIPLEY-D": "里普利-d",
    "COSTUMES_RIPLEY-E": "里普利-e",
    "COSTUMES_RIPLEY-F": "里普利-f",
    "COSTUMES_ROBOT-A": "機器人-a",
    "COSTUMES_ROBOT-B": "機器人-b",
    "COSTUMES_ROBOT-C": "機器人-c",
    "COSTUMES_ROBOT-D": "機器人-d",
    "COSTUMES_ROCKETSHIP-A": "飛船-A",
    "COSTUMES_ROCKETSHIP-B": "飛船-B",
    "COSTUMES_ROCKETSHIP-C": "飛船-C",
    "COSTUMES_ROCKETSHIP-D": "飛船-d",
    "COSTUMES_ROCKETSHIP-E": "飛船-E",
    "COSTUMES_ROCKS": "石頭",
    "COSTUMES_ROOSTER-A": "公雞一",
    "COSTUMES_ROOSTER-B": "公雞-B",
    "COSTUMES_ROOSTER-C": "格-C",
    "COSTUMES_RUBY-A": "紅寶石一",
    "COSTUMES_RUBY-B": "紅寶石-B",
    "COSTUMES_SAILBOAT": "帆船",
    "COSTUMES_SAM": "他自己",
    "COSTUMES_SAXOPHONE-A": "薩克斯-a",
    "COSTUMES_SAXOPHONE-B": "薩克斯-b",
    "COSTUMES_SCARF-A": "圍巾，一",
    "COSTUMES_SCARF-B": "圍巾-B",
    "COSTUMES_SCARF-C": "圍巾-C",
    "COSTUMES_SHARK-A": "鯊魚1-a",
    "COSTUMES_SHARK-B": "鯊魚1-b",
    "COSTUMES_SHARK2-A": "shark2-A",
    "COSTUMES_SHARK2-B": "shark2-B",
    "COSTUMES_SHARK2-C": "shark2-C",
    "COSTUMES_SHIRT-A": "襯衫一",
    "COSTUMES_SHOES-A": "鞋，一",
    "COSTUMES_SHOES-B": "鞋-B",
    "COSTUMES_SHOES-C": "鞋-C",
    "COSTUMES_SHOES-D": "鞋-d",
    "COSTUMES_SHORTS-A": "短褲一個",
    "COSTUMES_SHORTS-B": "短褲-B",
    "COSTUMES_SHORTS-C": "短褲-C",
    "COSTUMES_SINGER1": "歌手1",
    "COSTUMES_SKELETON-A": "骨架一",
    "COSTUMES_SKELETON-B": "骨架-B",
    "COSTUMES_SKELETON-D": "骨架-d",
    "COSTUMES_SKELETON-E": "骨架-E",
    "COSTUMES_SNAKE-A": "蛇-a",
    "COSTUMES_SNAKE-B": "蛇-b",
    "COSTUMES_SNAKE-C": "蛇-c",
    "COSTUMES_SNOWFLAKE": "雪花",
    "COSTUMES_SNOWMAN": "雪人",
    "COSTUMES_SOCCER_BALL": "足球",
    "COSTUMES_SPEAKER": "音響",
    "COSTUMES_SQUIRREL": "松鼠",
    "COSTUMES_STAR": "星星",
    "COSTUMES_STARFISH-A": "海星-a",
    "COSTUMES_STARFISH-B_": "海星-b",
    "COSTUMES_STOP": "暫停按鈕",
    "COSTUMES_STRAWBERRY-A": "草莓-a",
    "COSTUMES_STRAWBERRY-B": "草莓-b",
    "COSTUMES_STRAWBERRY-C": "草莓-c",
    "COSTUMES_STRAWBERRY-D": "草莓-d",
    "COSTUMES_STRAWBERRY-E": "草莓-e",
    "COSTUMES_SUN": "太陽",
    "COSTUMES_SUNGLASSES-A": "太陽鏡，一",
    "COSTUMES_SUNGLASSES-B": "太陽鏡-B",
    "COSTUMES_TABLA-A": "表一",
    "COSTUMES_TABLA-B": "表-B",
    "COSTUMES_TACO": "塔克",
    "COSTUMES_TACO-WIZARD": "塔克精靈",
    "COSTUMES_TAKEOUT-A": "外賣-a",
    "COSTUMES_TAKEOUT-B": "外賣-b",
    "COSTUMES_TAKEOUT-C": "外賣-c",
    "COSTUMES_TAKEOUT-D": "外賣-d",
    "COSTUMES_TAKEOUT-E": "外賣-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80時彈出",
    "COSTUMES_TEN80_POP_FRONT": "Ten80流行前線",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80流行金屬手臂",
    "COSTUMES_TEN80_POP_LEFT": "Ten80噗離開",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80流行R機殼",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80彈出權",
    "COSTUMES_TEN80_POP_STAND": "Ten80流行的立場",
    "COSTUMES_TEN80_STANCE": "Ten80立場",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80頂部凍結",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80頂部l步進",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80回到頂層橫",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80回到頂層步驟",
    "COSTUMES_TEN80_TOP_STAND": "Ten80頂架",
    "COSTUMES_TENNISBALL": "網球",
    "COSTUMES_TOUCAN-A": "犀鳥-a",
    "COSTUMES_TOUCAN-B": "犀鳥-b",
    "COSTUMES_TOUCAN-C": "犀鳥-c",
    "COSTUMES_TRAMPOLINE": "蹦床",
    "COSTUMES_TREE1": "樹",
    "COSTUMES_TREES-A": "樹木，一",
    "COSTUMES_TREES-B": "樹木-B",
    "COSTUMES_TRUCK-A": "卡車一",
    "COSTUMES_TRUCK-B": "卡車-B",
    "COSTUMES_TRUCK-C": "卡車-C",
    "COSTUMES_TRUMPET-A": "喇叭一",
    "COSTUMES_TRUMPET-B": "小號-B",
    "COSTUMES_UNICORN": "獨角獸",
    "COSTUMES_UNICORN_2": "麒麟2",
    "COSTUMES_UNICORN_RUNNING-A": "麒麟磨合一",
    "COSTUMES_UNICORN_RUNNING-B": "麒麟運行-B",
    "COSTUMES_UNICORN_RUNNING-C": "麒麟運行-C",
    "COSTUMES_UNICORN_RUNNING-D": "麒麟運行-d",
    "COSTUMES_UNICORN_RUNNING-E": "麒麟運行-E",
    "COSTUMES_UNICORN_RUNNING-F": "麒麟運行-F",
    "COSTUMES_WAND": "魔棒",
    "COSTUMES_WANDA": "流浪者",
    "COSTUMES_WATERMELON-A": "西瓜-a",
    "COSTUMES_WATERMELON-B": "西瓜-b",
    "COSTUMES_WATERMELON-C": "西瓜-c",
    "COSTUMES_WINTER_HAT": "冬季帽子",
    "COSTUMES_WITCH": "女巫",
    "COSTUMES_WITCH-A": "女巫-a",
    "COSTUMES_WITCH-B": "女巫-b",
    "COSTUMES_WITCH-C": "女巫-c",
    "COSTUMES_WITCH-D": "女巫-d",
    "COSTUMES_WIZARD_HAT": "精靈帽",
    "COSTUMES_WIZARD-A": "男巫-a",
    "COSTUMES_WIZARD-B": "男巫-b",
    "COSTUMES_WIZARD-C": "男巫-c",
    "COSTUMES_WIZARD-TOAD-A": "蛤蟆巫師-a",
    "COSTUMES_WIZARD-TOAD-B": "蛤蟆巫師-b",
    "COSTUMES_ZEBRA-A": "斑馬一",
    "COSTUMES_ZEBRA-B": "斑馬-B",
    "COSTUMES_BLOCK-A": "塊狀-A",
    "COSTUMES_BLOCK-B": "塊狀-B",
    "COSTUMES_BLOCK-C": "塊狀-C",
    "COSTUMES_BLOCK-D": "塊狀-D",
    "COSTUMES_BLOCK-E": "塊狀-E",
    "COSTUMES_BLOCK-F": "塊狀-F",
    "COSTUMES_BLOCK-G": "塊狀-G",
    "COSTUMES_BLOCK-H": "塊狀-H",
    "COSTUMES_BLOCK-I": "塊狀-I",
    "COSTUMES_BLOCK-J": "塊狀-J",
    "COSTUMES_BLOCK-K": "塊狀-k",
    "COSTUMES_BLOCK-L": "塊狀-L",
    "COSTUMES_BLOCK-M": "塊狀-M",
    "COSTUMES_BLOCK-N": "塊狀-N",
    "COSTUMES_BLOCK-O": "塊狀-O",
    "COSTUMES_BLOCK-P": "塊狀-p",
    "COSTUMES_BLOCK-Q": "塊狀-Q",
    "COSTUMES_BLOCK-R": "塊狀-R",
    "COSTUMES_BLOCK-S": "塊狀-S",
    "COSTUMES_BLOCK-T": "塊狀-T",
    "COSTUMES_BLOCK-U": "塊狀-U",
    "COSTUMES_BLOCK-V": "塊狀-V",
    "COSTUMES_BLOCK-W": "塊狀-W",
    "COSTUMES_BLOCK-X": "塊狀-X",
    "COSTUMES_BLOCK-Y": "塊狀-Y",
    "COSTUMES_BLOCK-Z": "塊狀-Z",
    "COSTUMES_GLOW-0": "輝光-0",
    "COSTUMES_GLOW-1": "輝光-1",
    "COSTUMES_GLOW-2": "輝光-2",
    "COSTUMES_GLOW-3": "輝光-3",
    "COSTUMES_GLOW-4": "輝光-4",
    "COSTUMES_GLOW-5": "輝光-5",
    "COSTUMES_GLOW-6": "輝光-6",
    "COSTUMES_GLOW-7": "輝光-7",
    "COSTUMES_GLOW-8": "輝光-8",
    "COSTUMES_GLOW-9": "輝光-9",
    "COSTUMES_GLOW-A": "輝光-A",
    "COSTUMES_GLOW-B": "輝光-B",
    "COSTUMES_GLOW-C": "輝光-C",
    "COSTUMES_GLOW-D": "輝光-D",
    "COSTUMES_GLOW-E": "輝光-E",
    "COSTUMES_GLOW-F": "輝光-F",
    "COSTUMES_GLOW-G": "輝光-G",
    "COSTUMES_GLOW-H": "輝光-H",
    "COSTUMES_GLOW-I": "輝光-I",
    "COSTUMES_GLOW-J": "輝光-J",
    "COSTUMES_GLOW-K": "輝光-K",
    "COSTUMES_GLOW-L": "輝光-L",
    "COSTUMES_GLOW-M": "輝光-M",
    "COSTUMES_GLOW-N": "輝光-N",
    "COSTUMES_GLOW-O": "輝光-O",
    "COSTUMES_GLOW-P": "輝光-P",
    "COSTUMES_GLOW-Q": "輝光-Q",
    "COSTUMES_GLOW-R": "輝光-R",
    "COSTUMES_GLOW-S": "輝光-S",
    "COSTUMES_GLOW-T": "輝光-T",
    "COSTUMES_GLOW-U": "輝光-U",
    "COSTUMES_GLOW-V": "輝光-V",
    "COSTUMES_GLOW-W": "輝光-W",
    "COSTUMES_GLOW-X": "輝光-X",
    "COSTUMES_GLOW-Y": "輝光-Y",
    "COSTUMES_GLOW-Z": "輝光-Z",
    "COSTUMES_STORY-A-1": "故事-A-1",
    "COSTUMES_STORY-A-2": "故事-A-2",
    "COSTUMES_STORY-A-3": "故事-A-3",
    "COSTUMES_STORY-B-1": "故事-B-1",
    "COSTUMES_STORY-B-2": "故事-B-2",
    "COSTUMES_STORY-B-3": "故事-B-3",
    "COSTUMES_STORY-C-1": "故事-C-1",
    "COSTUMES_STORY-C-2": "故事-C-2",
    "COSTUMES_STORY-C-3": "故事-C-3",
    "COSTUMES_STORY-D-1": "故事-D-1",
    "COSTUMES_STORY-D-2": "故事-D-2",
    "COSTUMES_STORY-D-3": "故事-D-3",
    "COSTUMES_STORY-E-1": "故事-E-1",
    "COSTUMES_STORY-E-2": "故事-E-2",
    "COSTUMES_STORY-E-3": "故事-E-3",
    "COSTUMES_STORY-F-1": "故事-F-1",
    "COSTUMES_STORY-F-2": "故事-F-2",
    "COSTUMES_STORY-F-3": "故事-F-3",
    "COSTUMES_STORY-G-1": "故事-G-1",
    "COSTUMES_STORY-G-2": "故事-G-2",
    "COSTUMES_STORY-G-3": "故事-G-3",
    "COSTUMES_STORY-H-1": "故事-H-1",
    "COSTUMES_STORY-H-2": "故事-H-2",
    "COSTUMES_STORY-H-3": "故事-H-3",
    "COSTUMES_STORY-I-1": "故事-I-1",
    "COSTUMES_STORY-I-2": "故事-I-2",
    "COSTUMES_STORY-I-3": "故事-I-3",
    "COSTUMES_STORY-J-1": "故事-J-1",
    "COSTUMES_STORY-J-2": "故事-J-2",
    "COSTUMES_STORY-J-3": "故事-J-3",
    "COSTUMES_STORY-K-1": "故事-K-1",
    "COSTUMES_STORY-K-2": "故事-K-2",
    "COSTUMES_STORY-K-3": "故事-K-3",
    "COSTUMES_STORY-L-1": "故事-L-1",
    "COSTUMES_STORY-L-2": "故事-L-2",
    "COSTUMES_STORY-L-3": "故事-L-3",
    "COSTUMES_STORY-M-1": "故事-M-1",
    "COSTUMES_STORY-M-2": "故事-M-2",
    "COSTUMES_STORY-M-3": "故事-M-3",
    "COSTUMES_STORY-N-1": "故事-N-1",
    "COSTUMES_STORY-N-2": "故事-N-2",
    "COSTUMES_STORY-N-3": "故事-N-3",
    "COSTUMES_STORY-O-1": "故事-O-1",
    "COSTUMES_STORY-O-2": "故事-O-2",
    "COSTUMES_STORY-O-3": "故事-O-3",
    "COSTUMES_STORY-P-1": "故事-P-1",
    "COSTUMES_STORY-P-2": "故事-P-2",
    "COSTUMES_STORY-P-3": "故事-P-3",
    "COSTUMES_STORY-Q-1": "故事-Q-1",
    "COSTUMES_STORY-Q-2": "故事-Q-2",
    "COSTUMES_STORY-Q-3": "故事-Q-3",
    "COSTUMES_STORY-R-1": "故事-R-1",
    "COSTUMES_STORY-R-2": "故事-R-2",
    "COSTUMES_STORY-R-3": "故事-R-3",
    "COSTUMES_STORY-S-1": "故事-S-1",
    "COSTUMES_STORY-S-2": "故事-S-2",
    "COSTUMES_STORY-S-3": "故事-S-3",
    "COSTUMES_STORY-T-1": "故事-T-1",
    "COSTUMES_STORY-T-2": "故事-T-2",
    "COSTUMES_STORY-T-3": "故事-T-3",
    "COSTUMES_STORY-U-1": "故事-U-1",
    "COSTUMES_STORY-U-2": "故事-U-2",
    "COSTUMES_STORY-U-3": "故事-U-3",
    "COSTUMES_STORY-V-1": "故事-V-1",
    "COSTUMES_STORY-V-2": "故事-V-2",
    "COSTUMES_STORY-V-3": "故事-V-3",
    "COSTUMES_STORY-W-1": "故事-W-1",
    "COSTUMES_STORY-W-2": "故事-W-2",
    "COSTUMES_STORY-W-3": "故事-W-3",
    "COSTUMES_STORY-X-1": "故事-X-1",
    "COSTUMES_STORY-X-2": "故事-X-2",
    "COSTUMES_STORY-X-3": "故事-X-3",
    "COSTUMES_STORY-Y-1": "故事-Y-1",
    "COSTUMES_STORY-Y-2": "故事-Y-2",
    "COSTUMES_STORY-Y-3": "故事-Y-3",
    "COSTUMES_STORY-Z-1": "故事-Z-1",
    "COSTUMES_STORY-Z-2": "故事-Z-2",
    "COSTUMES_STORY-Z-3": "故事-Z-3",
    "COSTUMES_STEAMED-STUFFED-BUN": "包子",
    "COSTUMES_LUCKY-BAG": "福袋",
    "COSTUMES_DUMPLINGS": "饺子",
    "COSTUMES_GOLD-COINS": "金币",
    "COSTUMES_A-GIFT": "礼物-A",
    "COSTUMES_STEAMED-BREAD": "馒头",
    "COSTUMES_PLATES": "盘子",
    "COSTUMES_SILVER-INGOT": "元宝",
    "COSTUMES_FIRECRACKERS": "鞭炮",
    "COSTUMES_FIRECRACKERS-1": "鞭炮1",
    "COSTUMES_FIRECRACKERS-2": "鞭炮2",
    "COSTUMES_FIRECRACKERS-3": "鞭炮3",
    "COSTUMES_LANTERN": "灯笼",
    "COSTUMES_LANTERN-1": "灯笼1",
    "COSTUMES_LANTERN-2": "灯笼2",
    "COSTUMES_DRUM": "鼓-A",
    "COSTUMES_DRUM-1": "鼓1",
    "COSTUMES_DRUM-2": "鼓2",
    "COSTUMES_DRUM-3": "鼓3",
    "COSTUMES_RED-ENVELOPE": "红包",
    "COSTUMES_RED-ENVELOPE-1": "红包1",
    "COSTUMES_RED-ENVELOPE-2": "红包2",
    "COSTUMES_SCROLL": "卷轴",
    "COSTUMES_SCROLL-1": "卷轴1",
    "COSTUMES_SCROLL-2": "卷轴2",
    "COSTUMES_SCROLL-3": "卷轴3",
    "COSTUMES_YETI": "雪人-A",
    "COSTUMES_YETI-1": "雪人1",
    "COSTUMES_YETI-2": "雪人2",
    "COSTUMES_FIREWORKS": "烟花",
    "COSTUMES_FIREWORKS-1": "烟花1",
    "COSTUMES_FIREWORKS-2": "烟花2",
    "COSTUMES_FIREWORKS-3": "烟花3",
    "COSTUMES_FIREWORKS-4": "烟花4",
    "COSTUMES_FIREWORKS-5": "烟花5",
    "COSTUMES_FIREWORKS-6": "烟花6",
    "COSTUMES_FIREWORKS-7": "烟花7",
    "COSTUMES_FIREWORKS-8": "烟花8",
    "COSTUMES_FIREWORKS-9": "烟花9",
    "SOUNDS_A_BASS": "貝斯 A",
    "SOUNDS_A_ELEC_BASS": "電貝司 A",
    "SOUNDS_A_ELEC_GUITAR": "電吉他 A",
    "SOUNDS_A_ELEC_PIANO": "電子琴 A",
    "SOUNDS_A_GUITAR": "吉他 A",
    "SOUNDS_A_MINOR_UKULELE": "尤克里裡 A",
    "SOUNDS_A_PIANO": "鋼琴 A",
    "SOUNDS_A_SAX": "薩克斯 A",
    "SOUNDS_A_TROMBONE": "長號 A",
    "SOUNDS_A_TRUMPET": "小號A",
    "SOUNDS_AFRO_STRING": "非洲弦",
    "SOUNDS_ALERT": "警報",
    "SOUNDS_ALIEN_CREAK1": "嘎吱1",
    "SOUNDS_ALIEN_CREAK2": "嘎吱2",
    "SOUNDS_B_BASS": "貝斯 B",
    "SOUNDS_B_ELEC_BASS": "電貝司 B",
    "SOUNDS_B_ELEC_GUITAR": "電吉他 B",
    "SOUNDS_B_ELEC_PIANO": "電子琴 B",
    "SOUNDS_B_GUITAR": "吉他 B",
    "SOUNDS_B_PIANO": "鋼琴 B",
    "SOUNDS_B_SAX": "薩克斯 B",
    "SOUNDS_B_TROMBONE": "長號 B",
    "SOUNDS_B_TRUMPET": "小號B",
    "SOUNDS_BAA": "咩",
    "SOUNDS_BARK": "汪",
    "SOUNDS_BASKETBALL_BOUNCE": "兵",
    "SOUNDS_BASS_BEATBOX": "節奏口技-低音",
    "SOUNDS_BEAT_BOX1": "電子節奏鼓1",
    "SOUNDS_BEAT_BOX2": "電子節奏鼓2",
    "SOUNDS_BELL_CYMBAL": "鐘鈸",
    "SOUNDS_BELL_TOLL": "鐘聲",
    "SOUNDS_BIG_BOING": "啵嚶1",
    "SOUNDS_BIRD": "鳥鳴",
    "SOUNDS_BIRTHDAY": "生日歌",
    "SOUNDS_BITE": "咬",
    "SOUNDS_BOING": "啵嚶",
    "SOUNDS_BONK": "梆",
    "SOUNDS_BOOM_CLOUD": "哐",
    "SOUNDS_BOOP_BING_BOP": "嘣兵啵",
    "SOUNDS_BOSSA_NOVA": "波薩諾瓦",
    "SOUNDS_BOWLING_STRIKE": "碗摔碎",
    "SOUNDS_BUBBLES": "沸騰",
    "SOUNDS_BUZZ_WHIR": "嗡嗡",
    "SOUNDS_C_BASS": "貝斯 C",
    "SOUNDS_C_ELEC_BASS": "電貝司 C",
    "SOUNDS_C_ELEC_GUITAR": "電吉他 C",
    "SOUNDS_C_ELEC_PIANO": "電子琴 C",
    "SOUNDS_C_GUITAR": "吉他 C",
    "SOUNDS_C_MAJOR_UKULELE": "尤克里裡 C",
    "SOUNDS_C_PIANO": "鋼琴 C",
    "SOUNDS_C_SAX": "薩克斯 C",
    "SOUNDS_C_TROMBONE": "長號 C",
    "SOUNDS_C_TRUMPET": "小號C",
    "SOUNDS_C2_BASS": "貝斯 C2",
    "SOUNDS_C2_ELEC_BASS": "電貝斯 C2",
    "SOUNDS_C2_ELEC_GUITAR": "電吉他 C2",
    "SOUNDS_C2_ELEC_PIANO": "電子琴 C2",
    "SOUNDS_C2_GUITAR": "吉他 C2",
    "SOUNDS_C2_PIANO": "鋼琴 C2",
    "SOUNDS_C2_SAX": "薩克斯 C2",
    "SOUNDS_C2_TROMBONE": "長號 C2",
    "SOUNDS_C2_TRUMPET": "小號C2",
    "SOUNDS_CAR_HORN": "汽車鳴笛",
    "SOUNDS_CAR_PASSING": "汽車行駛",
    "SOUNDS_CAR_VROOM": "汽車加速",
    "SOUNDS_CAVE": "陷落",
    "SOUNDS_CHATTER": "吱吱",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "歡呼",
    "SOUNDS_CHILL": "寒意",
    "SOUNDS_CHIRP": "啁啾",
    "SOUNDS_CHOMP": "咬牙",
    "SOUNDS_CHORD": "弦",
    "SOUNDS_CLANG": "當",
    "SOUNDS_CLAP_BEATBOX": "節奏口技-彈舌",
    "SOUNDS_CLAPPING": "掌聲",
    "SOUNDS_CLASSICAL_PIANO": "古典鋼琴",
    "SOUNDS_CLOCK_TICKING": "滴答滴答",
    "SOUNDS_CLOWN_HONK": "雁叫聲",
    "SOUNDS_COIN": "鳥叫聲",
    "SOUNDS_COLLECT": "叮",
    "SOUNDS_COMPUTER_BEEP": "嗶嗶",
    "SOUNDS_COMPUTER_BEEP2": "電腦嗶嗶聲2",
    "SOUNDS_CONNECT": "連接提示音效",
    "SOUNDS_COUGH1": "咳嗽1",
    "SOUNDS_COUGH2": "咳嗽2",
    "SOUNDS_CRANK": "發條",
    "SOUNDS_CRASH_BEATBOX": "節奏口技-跨擦",
    "SOUNDS_CRASH_CYMBAL": "碎音鈸",
    "SOUNDS_CRAZY_LAUGH": "大笑",
    "SOUNDS_CRICKET": "一隻蟋蟀",
    "SOUNDS_CRICKETS": "一群蟋蟀",
    "SOUNDS_CROAK": "低沉沙啞",
    "SOUNDS_CROWD_GASP": "喘息聲",
    "SOUNDS_CROWD_LAUGH": "哄堂大笑",
    "SOUNDS_CRUNCH": "嘎吱作響",
    "SOUNDS_CYMBAL": "镲",
    "SOUNDS_CYMBAL_CRASH": "碎音鈸",
    "SOUNDS_CYMBAL_ECHO": "音響4",
    "SOUNDS_D_BASS": "貝斯 D",
    "SOUNDS_D_ELEC_BASS": "電貝斯 D",
    "SOUNDS_D_ELEC_GUITAR": "電吉他 D",
    "SOUNDS_D_ELEC_PIANO": "電子琴 D",
    "SOUNDS_D_GUITAR": "吉他 D",
    "SOUNDS_D_PIANO": "鋼琴 D",
    "SOUNDS_D_SAX": "薩克斯 D",
    "SOUNDS_D_TROMBONE": "長號 D",
    "SOUNDS_D_TRUMPET": "小號D",
    "SOUNDS_DANCE_AROUND": "自由",
    "SOUNDS_DANCE_CELEBRATE": "慶祝",
    "SOUNDS_DANCE_CELEBRATE2": "舞蹈celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": "悠閒",
    "SOUNDS_DANCE_ENERGETIC": "舞蹈充滿活力",
    "SOUNDS_DANCE_FUNKY": "放克",
    "SOUNDS_DANCE_HEAD_NOD": "律動",
    "SOUNDS_DANCE_MAGIC": "魔力",
    "SOUNDS_DANCE_SITAR": "舞蹈西塔",
    "SOUNDS_DANCE_SLOW_MO": "輕緩",
    "SOUNDS_DANCE_SNARE_BEAT": "鼓點",
    "SOUNDS_DANCE_SPACE": "空間",
    "SOUNDS_DISCONNECT": "連接斷開提示音",
    "SOUNDS_DOG1": "汪汪",
    "SOUNDS_DOG2": "汪2",
    "SOUNDS_DOOR_CLOSING": "關門",
    "SOUNDS_DOOR_CREAK": "門嘎吱聲",
    "SOUNDS_DOORBELL": "門鈴聲",
    "SOUNDS_DRIP_DROP": "滴落聲",
    "SOUNDS_DRIVE_AROUND": "音響1",
    "SOUNDS_DRUM": "鼓",
    "SOUNDS_DRUM_BASS1": "低音大鼓1",
    "SOUNDS_DRUM_BASS2": "低音大鼓2",
    "SOUNDS_DRUM_BASS3": "低音大鼓3",
    "SOUNDS_DRUM_BOING": "鼓-啵嚶",
    "SOUNDS_DRUM_BUZZ": "鼓-嗡嗡",
    "SOUNDS_DRUM_FUNKY": "音響7",
    "SOUNDS_DRUM_JAM": "音響3",
    "SOUNDS_DRUM_MACHINE": "電子鼓",
    "SOUNDS_DRUM_ROLL": "擊鼓聲",
    "SOUNDS_DRUM_SATELLITE": "音響5",
    "SOUNDS_DRUM_SET1": "爵士鼓1",
    "SOUNDS_DRUM_SET2": "爵士鼓2",
    "SOUNDS_DUBSTEP": "的Dubstep",
    "SOUNDS_DUCK": "嘎嘎",
    "SOUNDS_DUN_DUN_DUNNN": "咚咚當",
    "SOUNDS_E_BASS": "貝斯 E",
    "SOUNDS_E_ELEC_BASS": "電貝司 E",
    "SOUNDS_E_ELEC_GUITAR": "電吉他 E",
    "SOUNDS_E_ELEC_PIANO": "電子琴 E",
    "SOUNDS_E_GUITAR": "吉他 E",
    "SOUNDS_E_PIANO": "鋼琴 E",
    "SOUNDS_E_SAX": "薩克斯 E",
    "SOUNDS_E_TROMBONE": "長號 E",
    "SOUNDS_E_TRUMPET": "小號E",
    "SOUNDS_EGGS": "蛋",
    "SOUNDS_ELEC_PIANO_A_MINOR": "電子琴 A小調",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "電子琴 C小調",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "電子琴 F小調",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "電子琴 G小調",
    "SOUNDS_ELEC_PIANO_LOOP": "電子琴loop",
    "SOUNDS_EMOTIONAL_PIANO": "情感鋼琴",
    "SOUNDS_ENGINE": "引擎",
    "SOUNDS_F_BASS": "貝斯 F",
    "SOUNDS_F_ELEC_BASS": "電子琴 F",
    "SOUNDS_F_ELEC_GUITAR": "電吉他 F",
    "SOUNDS_F_ELEC_PIANO": "電子琴 F",
    "SOUNDS_F_GUITAR": "吉他 F",
    "SOUNDS_F_MAJOR_UKULELE": "尤克里裡 F小調",
    "SOUNDS_F_PIANO": "鋼琴 F",
    "SOUNDS_F_SAX": "薩克斯 F",
    "SOUNDS_F_TROMBONE": "長號 F",
    "SOUNDS_F_TRUMPET": "小號F",
    "SOUNDS_FAIRYDUST": "精靈粉末",
    "SOUNDS_FINGER_SNAP": "響指",
    "SOUNDS_FLAM_SNARE": "倚音",
    "SOUNDS_FOOTSTEPS": "步伐",
    "SOUNDS_G_BASS": "貝斯 G",
    "SOUNDS_G_ELEC_BASS": "電貝司 G",
    "SOUNDS_G_ELEC_GUITAR": "電吉他 G",
    "SOUNDS_G_ELEC_PIANO": "電子琴 G",
    "SOUNDS_G_GUITAR": "吉他 G",
    "SOUNDS_G_PIANO": "鋼琴 G",
    "SOUNDS_G_SAX": "薩克斯 G",
    "SOUNDS_G_TROMBONE": "長號 G",
    "SOUNDS_G_TRUMPET": "小號G",
    "SOUNDS_G_UKULELE": "尤克里裡 G",
    "SOUNDS_GALLOP": "馬蹄聲",
    "SOUNDS_GARDEN": "花園",
    "SOUNDS_GLASS_BREAKING": "打碎玻璃",
    "SOUNDS_GLUG": "咕嘟咕嘟",
    "SOUNDS_GOAL_CHEER": "大聲歡呼",
    "SOUNDS_GONG": "鳴鑼",
    "SOUNDS_GOOSE": "鵝叫",
    "SOUNDS_GROWL": "咆哮",
    "SOUNDS_GRUNT": "呼嚕聲",
    "SOUNDS_GUITAR_CHORDS1": "吉他和弦1",
    "SOUNDS_GUITAR_CHORDS2": "吉他和弦2",
    "SOUNDS_GUITAR_STRUM": "吉他彈奏",
    "SOUNDS_HAND_CLAP": "掌聲",
    "SOUNDS_HEAD_SHAKE": "搖頭",
    "SOUNDS_HEY": "響指",
    "SOUNDS_HI_BEATBOX": "節奏口技-高音",
    "SOUNDS_HI_NA_TABLA": "塔布拉手鼓-Na-高",
    "SOUNDS_HI_TUN_TABLA": "塔布拉手鼓-Tun-高",
    "SOUNDS_HIGH_CONGA": "康加鼓-高",
    "SOUNDS_HIGH_HAT": "踩镲",
    "SOUNDS_HIGH_TOM": "高音鼓",
    "SOUNDS_HIGH_WHOOSH": "嘶嘶聲-高",
    "SOUNDS_HIHAT_BEATBOX": "電子節奏鼓-踩镲",
    "SOUNDS_HIHAT_CYMBAL": "腳踏雙面銅鈸",
    "SOUNDS_HIP_HOP": "嘻哈",
    "SOUNDS_HORSE": "嘶",
    "SOUNDS_HORSE_GALLOP": "馬蹄聲",
    "SOUNDS_HUMAN_BEATBOX1": "節奏口技1",
    "SOUNDS_HUMAN_BEATBOX2": "節奏口技2",
    "SOUNDS_JUMP": "跳躍",
    "SOUNDS_JUNGLE": "叢林",
    "SOUNDS_JUNGLE_FROGS": "青蛙",
    "SOUNDS_KICK_BACK": "音響6",
    "SOUNDS_KICK_DRUM": "底鼓",
    "SOUNDS_LARGE_COWBELL": "牛鈴",
    "SOUNDS_LASER1": "激光1",
    "SOUNDS_LASER2": "激光2",
    "SOUNDS_LAUGH1": "笑聲1",
    "SOUNDS_LAUGH2": "笑聲2",
    "SOUNDS_LAUGH3": "笑聲3",
    "SOUNDS_LO_GEH_TABLA": "塔布拉手鼓-Geh-低",
    "SOUNDS_LO_GLISS_TABLA": "塔布拉手鼓-Gliss-低",
    "SOUNDS_LOSE": "失敗",
    "SOUNDS_LOW_BOING": "啵嚶-低",
    "SOUNDS_LOW_CONGA": "康加鼓-低",
    "SOUNDS_LOW_SQUEAK": "吱吱聲-低",
    "SOUNDS_LOW_TOM": "低音嗵鼓",
    "SOUNDS_LOW_WHOOSH": "嘶嘶聲-低",
    "SOUNDS_MACHINE": "機械聲",
    "SOUNDS_MAGIC_SPELL": "魔咒",
    "SOUNDS_MEDIEVAL1": "中世紀1",
    "SOUNDS_MEDIEVAL2": "中世紀2",
    "SOUNDS_MEOW": "喵",
    "SOUNDS_MEOW2": "喵2",
    "SOUNDS_MOO": "哞",
    "SOUNDS_MOTORCYCLE_PASSING": "摩托車行駛",
    "SOUNDS_MOVIE_1": "電影1",
    "SOUNDS_MOVIE_2": "電影2",
    "SOUNDS_MUTED_CONGA": "康加鼓-柔和",
    "SOUNDS_MYSTERY": "神秘",
    "SOUNDS_OCEAN_WAVE": "海浪",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "哎呀",
    "SOUNDS_ORCHESTRA_TUNING": "管弦樂隊",
    "SOUNDS_OWL": "貓頭鷹",
    "SOUNDS_PARTY_NOISE": "聚會聲",
    "SOUNDS_PEW": "更",
    "SOUNDS_PING_PONG_HIT": "兵乓球",
    "SOUNDS_PLUCK": "猛拽",
    "SOUNDS_PLUNGE": "跳水",
    "SOUNDS_POLICE_SIREN": "警笛",
    "SOUNDS_POP": "啵",
    "SOUNDS_RAIN": "雨聲",
    "SOUNDS_RATTLE": "咯咯聲",
    "SOUNDS_REFEREE_WHISTLE": "裁判哨",
    "SOUNDS_REGGAE": "雷鬼",
    "SOUNDS_RICOCHET": "彈飛",
    "SOUNDS_RIDE_CYMBAL": "高架鈸",
    "SOUNDS_RING_TONE": "電話鈴聲",
    "SOUNDS_RIP": "撕布聲",
    "SOUNDS_RIPPLES": "漣漪",
    "SOUNDS_ROLL_CYMBAL": "軋輥鈸",
    "SOUNDS_ROOSTER": "打鳴",
    "SOUNDS_SCRAMBLING_FEET": "匆忙",
    "SOUNDS_SCRATCH_BEATBOX": "節奏口技-刮舌",
    "SOUNDS_SCRATCHY_BEAT": "音響2",
    "SOUNDS_SCREAM1": "尖叫1",
    "SOUNDS_SCREAM2": "尖叫2",
    "SOUNDS_SCREECH": "尖叫聲",
    "SOUNDS_SEAGULLS": "海鷗",
    "SOUNDS_SEWING_MACHINE": "縫紉機",
    "SOUNDS_SHAKER": "振動器",
    "SOUNDS_SHIP_BELL": "船上報時鬧鐘",
    "SOUNDS_SIDESTICK_SNARE": "小鼓鼓邊",
    "SOUNDS_SINGER1": "歌手1",
    "SOUNDS_SINGER2": "歌手2",
    "SOUNDS_SIREN_WHISTLE": "警報",
    "SOUNDS_SKID": "車打滑",
    "SOUNDS_SLIDE_WHISTLE": "溜溜笛",
    "SOUNDS_SMALL_COWBELL": "小牛鈴",
    "SOUNDS_SNAP": "劈啪聲",
    "SOUNDS_SNARE_BEATBOX": "節奏口技-小鼓1",
    "SOUNDS_SNARE_BEATBOX2": "節奏口技-小鼓2",
    "SOUNDS_SNARE_DRUM": "小軍鼓",
    "SOUNDS_SNEAKER_SQUEAK": "運動鞋吱吱聲",
    "SOUNDS_SNEEZE1": "打噴嚏1",
    "SOUNDS_SNEEZE2": "打噴嚏2",
    "SOUNDS_SNORING": "打鼾",
    "SOUNDS_SNORT": "蒸汽聲",
    "SOUNDS_SPACE_AMBIENCE": "空間音效",
    "SOUNDS_SPACE_FLYBY": "飛機低空飛行",
    "SOUNDS_SPACE_NOISE": "空氣轟鳴聲",
    "SOUNDS_SPACE_RIPPLE": "空間漣漪",
    "SOUNDS_SPIRAL": "旋渦",
    "SOUNDS_SPLASH": "濺起水花",
    "SOUNDS_SPLASH_CYMBAL": " 水鈸",
    "SOUNDS_SPOOKY_STRING": "駭人的弦聲",
    "SOUNDS_SQUAWK": "發牢騷",
    "SOUNDS_SQUEAKS": "短促的吱吱聲",
    "SOUNDS_SQUEAKY_TOY": "阿偶",
    "SOUNDS_SQUISH_POP": "咯吱",
    "SOUNDS_STRING_ACCENT": "弦樂重音",
    "SOUNDS_STRING_PLUCK": "彈樂",
    "SOUNDS_SUCTION_CUP": "吸盤",
    "SOUNDS_SUSPENSE": "懷疑",
    "SOUNDS_TADA": "完成",
    "SOUNDS_TAMBURA": "坦布拉琴",
    "SOUNDS_TAP_CONGA": "敲康加琴",
    "SOUNDS_TAP_SNARE": "低彈起",
    "SOUNDS_TECHNO": "高科技舞曲",
    "SOUNDS_TECHNO2": "高科技舞曲2",
    "SOUNDS_TELEPHONE_RING": "電話鈴聲",
    "SOUNDS_TELEPHONE_RING2": "電話鈴聲2",
    "SOUNDS_TELEPORT": "傳送",
    "SOUNDS_TELEPORT2": "傳送2",
    "SOUNDS_TELEPORT3": "傳送3",
    "SOUNDS_TENNIS_HIT": "網球聲",
    "SOUNDS_THUNDER_STORM": "雷雨",
    "SOUNDS_TOM_DRUM": "嗵嗵鼓",
    "SOUNDS_TOY_HONK": "玩具車鳴笛",
    "SOUNDS_TOY_ZING": "玩具車車輪轉動",
    "SOUNDS_TRAFFIC": "車流",
    "SOUNDS_TRAIN_WHISTLE": "火車鳴笛",
    "SOUNDS_TRAP_BEAT": "陷阱節拍",
    "SOUNDS_TRIUMPH": "成功",
    "SOUNDS_TROPICAL_BIRDS": "熱帶飛禽",
    "SOUNDS_TRUMPET1": "喇叭1",
    "SOUNDS_TRUMPET2": "喇叭2",
    "SOUNDS_VIDEO_GAME_1": "視頻遊戲1",
    "SOUNDS_VIDEO_GAME_2": "視頻遊戲2",
    "SOUNDS_WAH_BEATBOX": "節奏口技-哇",
    "SOUNDS_WAND": "魔棒",
    "SOUNDS_WATER_DROP": "滴水",
    "SOUNDS_WHINNY": "馬嘶聲",
    "SOUNDS_WHISTLE_THUMP": "撞倒",
    "SOUNDS_WHIZ": "颼颼聲",
    "SOUNDS_WHOOP": "哮喘聲",
    "SOUNDS_WIN": "勝利",
    "SOUNDS_WOBBLE": "晃動",
    "SOUNDS_WOLF_HOWL": "狼嚎",
    "SOUNDS_WOOD_TAP": "敲木頭",
    "SOUNDS_WUB_BEATBOX": "節奏口技-噗",
    "SOUNDS_XYLO1": "木琴1",
    "SOUNDS_XYLO2": "木琴2",
    "SOUNDS_XYLO3": "木琴3",
    "SOUNDS_XYLO4": "木琴4",
    "SOUNDS_YA": "呀",
    "SOUNDS_ZIP": "嘖噗",
    "SOUNDS_ZOOP": "哫噗",
    "SPRITES_ABBY": "艾比",
    "SPRITES_AMON": "阿蒙",
    "SPRITES_ANDIE": "安迪",
    "SPRITES_ANINA_DANCE": "ANINA舞",
    "SPRITES_APPLE": "蘋果",
    "SPRITES_ARROW1": "箭頭",
    "SPRITES_AVERY": "艾弗里",
    "SPRITES_AVERY_WALKING": "艾弗里-行走",
    "SPRITES_BALL": "球",
    "SPRITES_BALLERINA": "芭蕾舞女孩",
    "SPRITES_BALLOON1": "氣球",
    "SPRITES_BANANAS": "香蕉",
    "SPRITES_BASEBALL": "棒球",
    "SPRITES_BASKETBALL": "籃球",
    "SPRITES_BAT": "一",
    "SPRITES_BATTER": "麵糊",
    "SPRITES_BEACHBALL": "沙灘球",
    "SPRITES_BEAR": "熊",
    "SPRITES_BEAR-WALKING": "熊-行走",
    "SPRITES_BEETLE": "甲殼蟲",
    "SPRITES_BELL": "鈴鐺",
    "SPRITES_BEN": "本",
    "SPRITES_BOWL": "碗",
    "SPRITES_BOWTIE": "蝴蝶結",
    "SPRITES_BREAD": "麵包",
    "SPRITES_BROOM": "掃帚",
    "SPRITES_BUILDINGS": "建築物",
    "SPRITES_BUTTERFLY_1": "蝴蝶1",
    "SPRITES_BUTTERFLY_2": "蝴蝶2",
    "SPRITES_BUTTON1": "圓形按鈕",
    "SPRITES_BUTTON2": "橢圓形按鈕",
    "SPRITES_BUTTON3": "方形按鈕",
    "SPRITES_BUTTON4": "勾形按鈕",
    "SPRITES_BUTTON5": "叉形按鈕",
    "SPRITES_CAKE": "蛋糕",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "凱西",
    "SPRITES_CASSY_DANCE": "卡西舞",
    "SPRITES_CAT_2": "貓2",
    "SPRITES_CATCHER": "捕手",
    "SPRITES_CENTAUR": "半人馬",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "芝士泡芙",
    "SPRITES_CHICK": "小雞",
    "SPRITES_CITY_BUS": "城市客車",
    "SPRITES_CLOUD": "白雲",
    "SPRITES_CLOUDS": "白雲1",
    "SPRITES_CONVERTIBLE": "可兌換",
    "SPRITES_CONVERTIBLE_2": "敞篷2",
    "SPRITES_CRAB": "螃蟹",
    "SPRITES_CRYSTAL": "鑽石",
    "SPRITES_D-MONEY_DANCE": "d-錢舞",
    "SPRITES_DAN": "和",
    "SPRITES_DANI": "達尼",
    "SPRITES_DEE": "迪伊",
    "SPRITES_DEVIN": "德溫",
    "SPRITES_DINOSAUR1": "恐龍1",
    "SPRITES_DINOSAUR2": "恐龍2",
    "SPRITES_DINOSAUR3": "恐龍3",
    "SPRITES_DINOSAUR4": "恐龍4",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-A",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-B",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-C",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-E",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-F",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-G",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5小時",
    "SPRITES_DIVER1": "潛水員1",
    "SPRITES_DIVER2": "潛水員2",
    "SPRITES_DOG1": "小狗1",
    "SPRITES_DOG2": "小狗2",
    "SPRITES_DONUT": "圓環",
    "SPRITES_DORIAN": "多里安人",
    "SPRITES_DOT": "點點",
    "SPRITES_DOVE": "鴿子",
    "SPRITES_DRAGON": "龍",
    "SPRITES_DRAGONFLY": "蜻蜓",
    "SPRITES_DRESS": "連衣裙",
    "SPRITES_DRUM": "鼓",
    "SPRITES_DRUM_KIT": "爵士鼓",
    "SPRITES_DRUM-CYMBAL": "镲",
    "SPRITES_DRUM-HIGHHAT": "踩镲",
    "SPRITES_DRUM-SNARE": "小鼓",
    "SPRITES_DRUMS_CONGA": "康加鼓",
    "SPRITES_DRUMS_TABLA": "鼓塔布拉",
    "SPRITES_DUCK": "鴨子",
    "SPRITES_EARTH": "地球",
    "SPRITES_EASEL": "畫架",
    "SPRITES_EGG": "蛋",
    "SPRITES_ELEPHANT": "大象",
    "SPRITES_ELF": "小精靈",
    "SPRITES_FAIRY": "精靈",
    "SPRITES_FISH": "魚",
    "SPRITES_FISHBOWL": "魚缸",
    "SPRITES_FOOD_TRUCK": "食物餐車",
    "SPRITES_FOOTBALL": "足球",
    "SPRITES_FORTUNE_COOKIE": "幸運餅乾",
    "SPRITES_FOX": "狐狸",
    "SPRITES_FRANK": "坦率",
    "SPRITES_FROG": "青蛙",
    "SPRITES_FROG_2_": "青蛙2",
    "SPRITES_FRUIT_PLATTER": "水果拼盤",
    "SPRITES_FRUIT_SALAD": "水果沙拉",
    "SPRITES_GHOST": "鬼",
    "SPRITES_GIFT": "禮物",
    "SPRITES_GIRAFFE": "長頸鹿",
    "SPRITES_GLASS_WATER": "水杯",
    "SPRITES_GLASSES": "眼鏡",
    "SPRITES_GOALIE": "守門員",
    "SPRITES_GOBLIN": "哥布林",
    "SPRITES_GRASSHOPPER": "蚱蜢",
    "SPRITES_GREEN_FLAG": "綠旗",
    "SPRITES_GRIFFIN": "格里芬",
    "SPRITES_GUITAR": "吉他",
    "SPRITES_GUITAR-ELECTRIC1": "吉他electric1",
    "SPRITES_GUITAR-ELECTRIC2": "電吉他2",
    "SPRITES_HANNAH": "漢娜",
    "SPRITES_HARE": "野兔",
    "SPRITES_HARPER": "哈珀",
    "SPRITES_HAT1_": "HAT1",
    "SPRITES_HATCHLING": "幼雛",
    "SPRITES_HEART": "愛心",
    "SPRITES_HEART_CANDY": "糖心",
    "SPRITES_HEART_FACE": "心表情",
    "SPRITES_HEDGEHOG": "刺猬",
    "SPRITES_HEN": "Hen",
    "SPRITES_HIPPO1": "河馬",
    "SPRITES_HOME_BUTTON": "房型按鈕",
    "SPRITES_HORSE": "馬",
    "SPRITES_JAIME": "海梅",
    "SPRITES_JAMAL": "賈馬爾",
    "SPRITES_JAR": "罐子",
    "SPRITES_JELLYFISH": "水母",
    "SPRITES_JORDYN": "喬丁",
    "SPRITES_JOUVI_DANCE": "Jouvi舞",
    "SPRITES_KAI": "凱",
    "SPRITES_KEY": "鑰匙",
    "SPRITES_KEYBOARD": "鍵盤",
    "SPRITES_KIRAN": "基蘭",
    "SPRITES_KNIGHT": "騎士",
    "SPRITES_LB_DANCE": "LB舞",
    "SPRITES_LADYBUG1": "七星瓢蟲1",
    "SPRITES_LADYBUG2": "七星瓢蟲2",
    "SPRITES_LAPTOP": "筆記本電腦",
    "SPRITES_LIGHTNING": "閃電",
    "SPRITES_LINE": "線",
    "SPRITES_LION": "獅子",
    "SPRITES_LLAMA": "無峰駝",
    "SPRITES_MAGIC_WAND": "魔棒",
    "SPRITES_MAX": "麥克斯",
    "SPRITES_MERMAID": "美人魚",
    "SPRITES_MICROPHONE": "麥克風",
    "SPRITES_MILK": "牛奶",
    "SPRITES_MIND+": "心靈+",
    "COSTUMES_MIND+1": "心靈+ 1",
    "SPRITES_MONET": "莫奈",
    "SPRITES_MONKEY": "猴子",
    "SPRITES_MOTORCYCLE": "摩托車",
    "SPRITES_MOUSE1": "老鼠",
    "SPRITES_MUFFIN": "鬆餅",
    "SPRITES_NEIGH_PONY": "小馬駒",
    "SPRITES_OCTOPUS": "章魚",
    "SPRITES_ORANGE": "橘子",
    "SPRITES_ORANGE2": "橘子2",
    "SPRITES_OUTFIELDER": "外野手",
    "SPRITES_OWL": "貓頭鷹",
    "SPRITES_PADDLE": "槳",
    "SPRITES_PANTHER": "美洲豹",
    "SPRITES_PANTS": "褲子",
    "SPRITES_PARROT": "鸚鵡",
    "SPRITES_PARTY_HATS": "派對帽子",
    "SPRITES_PENCIL": "鉛筆",
    "SPRITES_PENGUIN": "企鵝",
    "SPRITES_PENGUIN_2": "企鵝2",
    "SPRITES_PITCHER": "投手",
    "SPRITES_PLANET2": "行星",
    "SPRITES_POLAR_BEAR": "北極熊",
    "SPRITES_POTION": "藥劑",
    "SPRITES_PRINCE": "王子",
    "SPRITES_PRINCESS": "公主",
    "SPRITES_PUFFERFISH": "河豚",
    "SPRITES_PUPPY": "小狗",
    "SPRITES_RABBIT": "兔子",
    "SPRITES_RADIO": "無線電",
    "SPRITES_RAINBOW": "彩虹",
    "SPRITES_REFEREE": "試用研究員",
    "SPRITES_REINDEER": "馴鹿",
    "SPRITES_RETRO_ROBOT": "復古機器人",
    "SOUNDS_COMPUTER_BEEPS1": "電腦beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "計算機beeps2",
    "SPRITES_RIPLEY": "里普利",
    "SPRITES_ROBOT": "機器人",
    "SPRITES_ROCKETSHIP": "火箭飛船",
    "SPRITES_ROCKS": "石頭",
    "SPRITES_ROOSTER": "公雞",
    "SPRITES_RUBY": "紅寶石",
    "SPRITES_SAILBOAT": "帆船",
    "SPRITES_SAM": "他自己",
    "SPRITES_SAXOPHONE": "薩克斯",
    "SPRITES_SCARF": "圍巾",
    "SPRITES_SHARK": "鯊魚",
    "SPRITES_SHARK_2": "鯊魚2",
    "SPRITES_SHIRT": "襯衫",
    "SPRITES_SHOES": "鞋",
    "SPRITES_SHORTS": "短褲",
    "SPRITES_SINGER1": "歌手1",
    "SPRITES_SKELETON": "骨架",
    "SPRITES_SNAKE": "蛇",
    "SPRITES_SNOWFLAKE": "雪花",
    "SPRITES_SNOWMAN": "雪人",
    "SPRITES_SOCCER_BALL": "足球",
    "SPRITES_SPEAKER": "音響",
    "SPRITES_SQUIRREL": "松鼠",
    "SPRITES_STAR": "星星",
    "SPRITES_STARFISH": "海星",
    "SPRITES_STOP": "暫停按鈕",
    "SPRITES_STRAWBERRY": "草莓",
    "SPRITES_SUN": "太陽",
    "SPRITES_SUNGLASSES1": "太陽鏡1",
    "SPRITES_TACO": "煎玉米卷",
    "SPRITES_TAKEOUT": "外賣",
    "SPRITES_TEN80_DANCE": "Ten80舞",
    "SPRITES_TENNIS_BALL": "網球",
    "SPRITES_TOUCAN": "犀鳥",
    "SPRITES_TRAMPOLINE": "蹦床",
    "SPRITES_TREE1": "樹",
    "SPRITES_TREES": "樹1",
    "SPRITES_TRUCK": "卡車",
    "SPRITES_TRUMPET": "小號",
    "SPRITES_UNICORN": "獨角獸",
    "SPRITES_UNICORN_2": "麒麟2",
    "SPRITES_UNICORN_RUNNING": "獨角獸運行",
    "SPRITES_WAND": "魔棒",
    "SPRITES_WANDA": "流浪者",
    "SPRITES_WATERMELON": "西瓜",
    "SPRITES_WINTER_HAT": "冬季帽子",
    "SPRITES_WITCH": "女巫",
    "SPRITES_WIZARD": "男巫",
    "SPRITES_WIZARD_HAT": "精靈帽",
    "SPRITES_WIZARD-TOAD": "蛤蟆巫師",
    "SPRITES_ZEBRA": "斑馬",
    "SPRITES_BLOCK-A": "塊狀-A",
    "SPRITES_BLOCK-B": "塊狀-B",
    "SPRITES_BLOCK-C": "塊狀-C",
    "SPRITES_BLOCK-D": "塊狀-D",
    "SPRITES_BLOCK-E": "塊狀-E",
    "SPRITES_BLOCK-F": "塊狀-F",
    "SPRITES_BLOCK-G": "塊狀-G",
    "SPRITES_BLOCK-H": "塊狀-H",
    "SPRITES_BLOCK-I": "塊狀-I",
    "SPRITES_BLOCK-J": "塊狀-J",
    "SPRITES_BLOCK-K": "塊狀-K",
    "SPRITES_BLOCK-L": "塊狀-L",
    "SPRITES_BLOCK-M": "塊狀-M",
    "SPRITES_BLOCK-N": "塊狀-N",
    "SPRITES_BLOCK-O": "塊狀-O",
    "SPRITES_BLOCK-P": "塊狀-P",
    "SPRITES_BLOCK-Q": "塊狀-Q",
    "SPRITES_BLOCK-R": "塊狀-R",
    "SPRITES_BLOCK-S": "塊狀-S",
    "SPRITES_BLOCK-T": "塊狀-T",
    "SPRITES_BLOCK-U": "塊狀-U",
    "SPRITES_BLOCK-V": "塊狀-V",
    "SPRITES_BLOCK-W": "塊狀-W",
    "SPRITES_BLOCK-X": "塊狀-X",
    "SPRITES_BLOCK-Y": "塊狀-Y",
    "SPRITES_BLOCK-Z": "塊狀-Z",
    "SPRITES_GLOW-0": "輝光-0",
    "SPRITES_GLOW-1": "輝光-1",
    "SPRITES_GLOW-2": "輝光-2",
    "SPRITES_GLOW-3": "輝光-3",
    "SPRITES_GLOW-4": "輝光-4",
    "SPRITES_GLOW-5": "輝光-5",
    "SPRITES_GLOW-6": "輝光-6",
    "SPRITES_GLOW-7": "輝光-7",
    "SPRITES_GLOW-8": "輝光-8",
    "SPRITES_GLOW-9": "輝光-9",
    "SPRITES_GLOW-A": "輝光-A",
    "SPRITES_GLOW-B": "輝光-B",
    "SPRITES_GLOW-C": "輝光-C",
    "SPRITES_GLOW-D": "輝光-D",
    "SPRITES_GLOW-E": "輝光-E",
    "SPRITES_GLOW-F": "輝光-F",
    "SPRITES_GLOW-G": "輝光-G",
    "SPRITES_GLOW-H": "輝光-H",
    "SPRITES_GLOW-I": "輝光-I",
    "SPRITES_GLOW-J": "輝光-J",
    "SPRITES_GLOW-K": "輝光-K",
    "SPRITES_GLOW-L": "輝光-L",
    "SPRITES_GLOW-M": "輝光-M",
    "SPRITES_GLOW-N": "輝光-N",
    "SPRITES_GLOW-O": "輝光-O",
    "SPRITES_GLOW-P": "輝光-P",
    "SPRITES_GLOW-Q": "輝光-Q",
    "SPRITES_GLOW-R": "輝光-R",
    "SPRITES_GLOW-S": "輝光-S",
    "SPRITES_GLOW-T": "輝光-T",
    "SPRITES_GLOW-U": "輝光-U",
    "SPRITES_GLOW-V": "輝光-V",
    "SPRITES_GLOW-W": "輝光-W",
    "SPRITES_GLOW-X": "輝光-X",
    "SPRITES_GLOW-Y": "輝光-Y",
    "SPRITES_GLOW-Z": "輝光-Z",
    "SPRITES_STORY-A": "故事-A",
    "SPRITES_STORY-B": "故事-B",
    "SPRITES_STORY-C": "故事-C",
    "SPRITES_STORY-D": "故事-D",
    "SPRITES_STORY-E": "故事-E",
    "SPRITES_STORY-F": "故事-F",
    "SPRITES_STORY-G": "故事-G",
    "SPRITES_STORY-H": "故事-H",
    "SPRITES_STORY-I": "故事-I",
    "SPRITES_STORY-J": "故事-J",
    "SPRITES_STORY-K": "故事-K",
    "SPRITES_STORY-L": "故事-L",
    "SPRITES_STORY-M": "故事-M",
    "SPRITES_STORY-N": "故事-N",
    "SPRITES_STORY-O": "故事-O",
    "SPRITES_STORY-P": "故事-P",
    "SPRITES_STORY-Q": "故事-Q",
    "SPRITES_STORY-R": "故事-R",
    "SPRITES_STORY-S": "故事-S",
    "SPRITES_STORY-T": "故事-T",
    "SPRITES_STORY-U": "故事-U",
    "SPRITES_STORY-V": "故事-V",
    "SPRITES_STORY-W": "故事-W",
    "SPRITES_STORY-X": "故事-X",
    "SPRITES_STORY-Y": "故事-Y",
    "SPRITES_STORY-Z": "故事-Z",
    "SPRITES_MIND+2022_01_CIVIL-AIRCRAFT": "民航飞机",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT1": "民航飞机-正面",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT2": "民航飞机-侧面",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT3": "民航飞机-顶部",
    "SPRITES_MIND+2022_02_AEROSPACEPLANE": "航天飞机",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE1": "航天飞机-正面",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE2": "航天飞机-侧面",
    "SPRITES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM": "航天飞机机舱",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM1": "航天飞机机舱-正面",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM2": "航天飞机机舱-侧面",
    "SPRITES_MIND+2022_04_TRAFFIC-LIGHT": "表情红绿灯",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT1": "表情红绿灯-红",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT2": "表情红绿灯-黄",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT3": "表情红绿灯-绿",
    "SPRITES_MIND+2022_05_FLYBROW": "飞艇",
    "COSTUMES_MIND+2022_05_FLYBROW1": "飞艇-侧面",
    "COSTUMES_MIND+2022_05_FLYBROW2": "飞艇-仰视",
    "SPRITES_MIND+2022_06_HIGHSPEED-RAIL": "高铁",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL1": "高铁-正面",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL2": "高铁-侧面",
    "SPRITES_MIND+2022_07_POLICECAR": "警车",
    "COSTUMES_MIND+2022_07_POLICECAR1": "警车-运动1",
    "COSTUMES_MIND+2022_07_POLICECAR2": "警车-运动2",
    "COSTUMES_MIND+2022_07_POLICECAR3": "警车-运动3",
    "COSTUMES_MIND+2022_07_POLICECAR4": "警车-运动4",
    "COSTUMES_MIND+2022_07_POLICECAR5": "警车-亮灯1",
    "COSTUMES_MIND+2022_07_POLICECAR6": "警车-亮灯2",
    "COSTUMES_MIND+2022_07_POLICECAR7": "警车-静止",
    "SPRITES_MIND+2022_08_CAR": "小汽车",
    "COSTUMES_MIND+2022_08_CAR1": "小汽车-1",
    "COSTUMES_MIND+2022_08_CAR2": "小汽车-2",
    "COSTUMES_MIND+2022_08_CAR3": "小汽车-3",
    "COSTUMES_MIND+2022_08_CAR4": "小汽车-4",
    "COSTUMES_MIND+2022_08_CAR5": "小汽车-5",
    "COSTUMES_MIND+2022_08_CAR6": "小汽车-6",
    "SPRITES_MIND+2022_09_DININGCAR": "餐车",
    "SPRITES_MIND+2022_10_BUS": "公交车",
    "SPRITES_MIND+2022_11_GO-KART": "卡丁车",
    "SPRITES_MIND+2022_12_MOTORBIKE": "摩托车",
    "SPRITES_MIND+2022_13_TAXI": "出租车",
    "COSTUMES_MIND+2022_13_TAXI01": "出租车-正侧面",
    "COSTUMES_MIND+2022_13_TAXI02": "出租车-侧面",
    "COSTUMES_MIND+2022_13_TAXI03": "出租车-正侧面关灯",
    "COSTUMES_MIND+2022_13_TAXI04": "出租车-正侧面开灯1",
    "COSTUMES_MIND+2022_13_TAXI05": "出租车-正侧面开灯2",
    "COSTUMES_MIND+2022_13_TAXI06": "出租车-正面",
    "SPRITES_MIND+2022_14_PUMPKINCAR": "南瓜车",
    "COSTUMES_MIND+2022_14_PUMPKINCAR1": "南瓜车-1",
    "COSTUMES_MIND+2022_14_PUMPKINCAR2": "南瓜车-2",
    "COSTUMES_MIND+2022_14_PUMPKINCAR3": "南瓜车-3",
    "COSTUMES_MIND+2022_14_PUMPKINCAR4": "南瓜车-开门",
    "COSTUMES_MIND+2022_14_PUMPKINCAR5": "南瓜车-夜晚",
    "SPRITES_MIND+2022_15_LITTLESAILBOAT": "小帆船",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT1": "小帆船-侧面",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT2": "小帆船-顶侧",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT3": "小帆船-斜顶侧",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT4": "小帆船-斜顶侧1",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT5": "小帆船-斜顶侧2",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT6": "小帆船-斜顶侧3",
    "SPRITES_MIND+2022_16_RUBBLERACER": "小飞船",
    "COSTUMES_MIND+2022_16_RUBBLERACER1": "小飞船-01",
    "COSTUMES_MIND+2022_16_RUBBLERACER2": "小飞船-02",
    "COSTUMES_MIND+2022_16_RUBBLERACER3": "小飞船-03",
    "SPRITES_MIND+2022_17_BARNEY": "小矿车",
    "COSTUMES_MIND+2022_17_BARNEY1": "小矿车-侧面满",
    "COSTUMES_MIND+2022_17_BARNEY2": "小矿车-顶侧面",
    "COSTUMES_MIND+2022_17_BARNEY3": "小矿车-顶侧面2",
    "COSTUMES_MIND+2022_17_BARNEY4": "小矿车-顶侧面3",
    "COSTUMES_MIND+2022_17_BARNEY5": "小矿车-顶侧面空1",
    "SPRITES_MIND+2022_18_UTILITYCART": "小推车",
    "COSTUMES_MIND+2022_18_UTILITYCART1": "小推车-侧面",
    "COSTUMES_MIND+2022_18_UTILITYCART2": "小推车-顶侧面",
    "COSTUMES_MIND+2022_18_UTILITYCART3": "小推车-顶侧面满1",
    "COSTUMES_MIND+2022_18_UTILITYCART4": "小推车-顶侧面满2",
    "COSTUMES_MIND+2022_18_UTILITYCART5": "小推车-顶侧面满3",
    "SPRITES_MIND+2022_19_BIKE": "自行车",
    "COSTUMES_MIND+2022_19_BIKE1": "自行车-侧面1",
    "COSTUMES_MIND+2022_19_BIKE2": "自行车-侧面2",
    "COSTUMES_MIND+2022_19_BIKE3": "自行车-侧面3",
    "COSTUMES_MIND+2022_19_BIKE4": "自行车-侧面4",
    "COSTUMES_MIND+2022_19_BIKE5": "自行车-正面",
    "SPRITES_MIND+2022_20_RACECAR": "赛车",
    "COSTUMES_MIND+2022_20_RACECAR1": "赛车-侧面",
    "COSTUMES_MIND+2022_20_RACECAR2": "蓝色赛车-顶面1",
    "COSTUMES_MIND+2022_20_RACECAR3": "蓝色赛车-顶面2",
    "COSTUMES_MIND+2022_20_RACECAR4": "蓝色赛车-顶面3",
    "COSTUMES_MIND+2022_20_RACECAR5": "黄色赛车-顶面1",
    "COSTUMES_MIND+2022_20_RACECAR6": "黄色赛车-顶面2",
    "COSTUMES_MIND+2022_20_RACECAR7": "黄色赛车-顶面3",
    "COSTUMES_MIND+2022_20_RACECAR8": "粉色赛车-顶面1",
    "COSTUMES_MIND+2022_20_RACECAR9": "粉色赛车-顶面2",
    "COSTUMES_MIND+2022_20_RACECAR10": "粉色赛车-顶面3",
    "SPRITES_MIND+2022_21_METEORITES1": "陨石",
    "COSTUMES_MIND+2022_21_METEORITES1_1": "陨石1-01",
    "COSTUMES_MIND+2022_21_METEORITES1_2": "陨石1-02",
    "COSTUMES_MIND+2022_21_METEORITES1_3": "陨石1-03",
    "SPRITES_MIND+2022_22_METEORITES2": "陨石2",
    "COSTUMES_MIND+2022_22_METEORITES2_1": "陨石2-01",
    "COSTUMES_MIND+2022_22_METEORITES2_2": "陨石2-02",
    "COSTUMES_MIND+2022_22_METEORITES2_3": "陨石2-03",
    "SPRITES_MIND+2022_23_METEORITES3": "陨石3",
    "COSTUMES_MIND+2022_23_METEORITES3_1": "陨石3-01",
    "COSTUMES_MIND+2022_23_METEORITES3_2": "陨石3-02",
    "COSTUMES_MIND+2022_23_METEORITES3_3": "陨石3-03",
    "SPRITES_MIND+2022_24_METEORITES4": "陨石4",
    "COSTUMES_MIND+2022_24_METEORITES4_1": "陨石4-01",
    "COSTUMES_MIND+2022_24_METEORITES4_2": "陨石4-02",
    "COSTUMES_MIND+2022_24_METEORITES4_3": "陨石4-03",
    "COSTUMES_MIND+2022_24_METEORITES4_4": "陨石4-04",
    "SPRITES_MIND+2022_25_METEORITES5": "陨石5",
    "COSTUMES_MIND+2022_25_METEORITES5_1": "陨石5-01",
    "COSTUMES_MIND+2022_25_METEORITES5_2": "陨石5-02",
    "COSTUMES_MIND+2022_25_METEORITES5_3": "陨石5-03",
    "COSTUMES_MIND+2022_25_METEORITES5_4": "陨石5-04",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_01": "陨石大冒险A-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_02": "陨石大冒险A-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_01": "陨石大冒险B-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_02": "陨石大冒险B-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_03": "陨石大冒险B-3",
    "MIND+_FACE_SIDE": "Mind+人物表情-侧面",
    "MIND+_FACE_SIDE-1": "Mind+人物表情-侧面-1",
    "MIND+_FACE_SIDE-2": "Mind+人物表情-侧面-2",
    "MIND+_FACE_SIDE-3": "Mind+人物表情-侧面-3",
    "MIND+_FACE_SIDE-4": "Mind+人物表情-侧面-4",
    "MIND+_FACE_SIDE-5": "Mind+人物表情-侧面-5",
    "MIND+_FACE_SIDE-6": "Mind+人物表情-侧面-6",
    "MIND+_FACE_SIDE-7": "Mind+人物表情-侧面-7",
    "MIND+_FACE_SIDE-8": "Mind+人物表情-侧面-8",
    "MIND+_FACE_SIDE-9": "Mind+人物表情-侧面-9",
    "MIND+_FACE_SIDE-10": "Mind+人物表情-侧面-10",
    "MIND+_FACE_POSITIVE": "Mind+人物表情-正面",
    "MIND+_FACE_POSITIVE-1": "Mind+人物表情-正面-1",
    "MIND+_FACE_POSITIVE-2": "Mind+人物表情-正面-2",
    "MIND+_FACE_POSITIVE-3": "Mind+人物表情-正面-3",
    "MIND+_FACE_POSITIVE-4": "Mind+人物表情-正面-4",
    "MIND+_FACE_POSITIVE-5": "Mind+人物表情-正面-5",
    "MIND+_FACE_POSITIVE-6": "Mind+人物表情-正面-6",
    "MIND+_FACE_POSITIVE-7": "Mind+人物表情-正面-7",
    "MIND+_FACE_POSITIVE-8": "Mind+人物表情-正面-8",
    "MIND+_FACE_POSITIVE-9": "Mind+人物表情-正面-9",
    "MIND+_FACE_POSITIVE-10": "Mind+人物表情-正面-10",
    "MIND+_DOZE": "打瞌睡",
    "MIND+_DOZE-1": "打瞌睡-1",
    "MIND+_DOZE-2": "打瞌睡-2",
    "MIND+_DOZE-3": "打瞌睡-3",
    "MIND+_SQUAT": "蹲下起立",
    "MIND+_SQUAT-1": "蹲下起立-1",
    "MIND+_SQUAT-2": "蹲下起立-2",
    "MIND+_SQUAT-3": "蹲下起立-3",
    "MIND+_ARCHITECT": "Mind+建筑师",
    "MIND+_ARCHITECT-1": "Mind+建筑师-1",
    "MIND+_ARCHITECT-2": "Mind+建筑师-2",
    "MIND+_ARCHITECT-3": "Mind+建筑师-3",
    "MIND+_TRAFFIC_POLICE": "Mind+交警",
    "MIND+_TRAFFIC_POLICE-1": "Mind+交警-1",
    "MIND+_TRAFFIC_POLICE-2": "Mind+交警-2",
    "MIND+_TRAFFIC_POLICE-3": "Mind+交警-3",
    "MIND+_TRAFFIC_POLICE-4": "Mind+交警-4",
    "MIND+_TRAFFIC_POLICE-5": "Mind+交警-5",
    "MIND+_TRAFFIC_POLICE-6": "Mind+交警-6",
    "MIND+_POLICE": "Mind+警察",
    "MIND+_POLICE-1": "Mind+警察-1",
    "MIND+_POLICE-2": "Mind+警察-2",
    "MIND+_POLICE-3": "Mind+警察-3",
    "MIND+_POLICE-4": "Mind+警察-4",
    "MIND+_POLICE-5": "Mind+警察-5",
    "MIND+_POLICE-6": "Mind+警察-长裤1",
    "MIND+_POLICE-7": "Mind+警察-长裤2",
    "MIND+_SCIENTIST": "Mind+科学家",
    "MIND+_SCIENTIST-1": "Mind+科学家-1",
    "MIND+_SCIENTIST-2": "Mind+科学家-2",
    "MIND+_SCIENTIST-3": "Mind+科学家-3",
    "MIND+_SANTA_CLAUS": "Mind+圣诞老人",
    "MIND+_SANTA_CLAUS-1": "Mind+圣诞老人-挥手",
    "MIND+_SANTA_CLAUS-2": "Mind+圣诞老人-送礼",
    "MIND+_SANTA_CLAUS-3": "Mind+圣诞老人-舞蹈",
    "MIND+_FIREMAN": "Mind+消防员",
    "MIND+_FIREMAN-1": "Mind+消防员1",
    "MIND+_FIREMAN-2": "Mind+消防员2",
    "MIND+_FIREMAN-3": "Mind+消防员3",
    "MIND+_FIREMAN-4": "Mind+消防员4",
    "MIND+_DOCTOR": "Mind+医生",
    "MIND+_DOCTOR-1": "Mind+医生-1",
    "MIND+_DOCTOR-2": "Mind+医生-2",
    "MIND+_DOCTOR-3": "Mind+医生-3",
    "MIND+_ASTRONAUT_SPACECRAFT": "Mind+宇航员-飞船",
    "MIND+_ASTRONAUT_SPACECRAFT-1": "Mind+宇航员-飞船-1",
    "MIND+_ASTRONAUT_SPACECRAFT-2": "Mind+宇航员-飞船-2",
    "MIND+_ASTRONAUT_SPACECRAFT-3": "Mind+宇航员-飞船-3",
    "MIND+_ASTRONAUT_SPACECRAFT-4": "Mind+宇航员-飞船-4",
    "MIND+_ASTRONAUT_SPACECRAFT-5": "Mind+宇航员-飞船-5",
    "MIND+_ASTRONAUT_STANDING": "Mind+宇航员-站立",
    "MIND+_ASTRONAUT_STANDING-1": "Mind+宇航员-站立-1",
    "MIND+_ASTRONAUT_STANDING-2": "Mind+宇航员-站立-2",
    "BRUSHWOOD": "草丛",
    "BRUSHWOOD-1": "草丛-1",
    "BRUSHWOOD-2": "草丛-2",
    "BRUSHWOOD-3": "草丛-3",
    "BRUSHWOOD-4": "草丛-4",
    "STONE": "石头",
    "STONE-1": "石头-1",
    "STONE-2": "石头-2",
    "STONE-3": "石头-3",
    "STONE-4": "石头-4",
    "TREE": "树",
    "TREE-1": "树-1",
    "TREE-2": "树-2",
    "TREE-3": "树-3",
    "TREE-4": "树-4",
    "COPSE": "树丛",
    "COPSE-1": "树丛-1",
    "COPSE-2": "树丛-2",
    "COPSE-3": "树丛-3",
    "COPSE-4": "树丛-4",
    "SNOW_MOUNTAIN": "雪山",
    "SNOW_MOUNTAIN-1": "雪山-1",
    "SNOW_MOUNTAIN-2": "雪山-2",
    "SNOW_MOUNTAIN-3": "雪山-3",
    "SNOW_MOUNTAIN-4": "雪山-4",
    "CLOUD": "云",
    "CLOUD-1": "云-1",
    "CLOUD-2": "云-2",
    "CLOUD-3": "云-3",
    "CLOUD-4": "云-4",
    "CARTOON_PINK_BLUE_BUTTON_GROUP": "卡通粉蓝按钮组",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-1": "按钮-loading",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-2": "按钮-播放",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-3": "按钮-关闭",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-4": "按钮-继续挑战",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-5": "按钮-静音",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-6": "按钮-开始",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-7": "按钮-开始电源",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-8": "按钮-设置",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-9": "按钮-下一关",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-10": "按钮-音量",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-11": "按钮-暂停",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-12": "按钮-重复",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-13": "箭头-上",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-14": "箭头-下",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-15": "箭头-右",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-16": "箭头-左",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP": "科技紫按钮组",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-1": "按钮-空",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-2": "播放",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-3": "分享",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-4": "勾选",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-5": "关闭",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-6": "继续游戏",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-7": "静音",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-8": "开始游戏",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-9": "上",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-10": "设置",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-11": "下",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-12": "音乐",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-13": "游戏设置",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-14": "右",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-15": "暂停",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-16": "长方形按钮-空",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-17": "重新开始",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-18": "左",
    "DIALOG-1": "对话框-1",
    "DIALOG-2": "对话框-2",
    "DIALOG-3": "对话框-3",
    "DIALOG-4": "对话框-4",
    "DIALOG-5": "对话框-5",
    "DIALOG-6": "对话框-6",
    "DIALOG-7": "对话框-7",
    "DIALOG-8": "对话框-8",
    "DIALOG-9": "对话框-9",
    "DIALOG-10": "对话框-10",
    "Epidemic_Prevention_MIND+": "Mind+大白",
    "Epidemic_Prevention_MIND+-1": "穿防护服的Mind+1",
    "Epidemic_Prevention_MIND+-2": "穿防护服的Mind+2",
    "VIRUS": "病毒",
    "VIRUS-1": "病毒-a",
    "VIRUS-2": "病毒-b",
    "VIRUS-3": "病毒-c",
    "ANTIGEN_REAGENT": "抗原试剂",
    "ANTIGEN_REAGENT-1": "抗原试剂-横",
    "ANTIGEN_REAGENT-2": "抗原试剂-竖",
    "MASK": "口罩",
    "MASK-1": "口罩-1",
    "MASK-2": "口罩-2",
    "MASK-3": "口罩-3",
    "MASK-4": "口罩-4",
    "MASK-5": "口罩-5",
    "MASK-6": "口罩-6",
    "TEMPERATURE_MEASURING_GUN": "测温枪",
    "STETHOSCOPE": "听诊器",
    "DISINFECTANT": "消毒液",
    "NUMBER-0": "0",
    "NUMBER-1": "1",
    "NUMBER-2": "2",
    "NUMBER-3": "3",
    "NUMBER-4": "4",
    "NUMBER-5": "5",
    "NUMBER-6": "6",
    "NUMBER-7": "7",
    "NUMBER-8": "8",
    "NUMBER-9": "9",
    "LETTER-A": "A",
    "LETTER-B": "B",
    "LETTER-C": "C",
    "LETTER-D": "D",
    "LETTER-E": "E",
    "LETTER-F": "F",
    "LETTER-G": "G",
    "LETTER-H": "H",
    "LETTER-I": "I",
    "LETTER-J": "J",
    "LETTER-K": "K",
    "LETTER-L": "L",
    "LETTER-M": "M",
    "LETTER-N": "N",
    "LETTER-O": "O",
    "LETTER-P": "P",
    "LETTER-Q": "Q",
    "LETTER-R": "R",
    "LETTER-S": "S",
    "LETTER-T": "T",
    "LETTER-U": "U",
    "LETTER-V": "V",
    "LETTER-W": "W",
    "LETTER-X": "X",
    "LETTER-Y": "Y",
    "LETTER-Z": "Z",
    "EXCLAMATORY_MARK": "感叹号",
    "EXCLAMATORY_MARK-1": "感叹号-1",
    "EXCLAMATORY_MARK-2": "感叹号-2",
    "EXCLAMATORY_MARK-3": "感叹号-3",
    "YELLOW_PROGRESS_BAR": "黄色进度条",
    "YELLOW_PROGRESS_BAR-1": "黄色进度条-1",
    "YELLOW_PROGRESS_BAR-2": "黄色进度条-2",
    "YELLOW_PROGRESS_BAR-3": "黄色进度条-3",
    "PROGRESS_BAR": "进度条",
    "PROGRESS_BAR-1": "进度条30",
    "PROGRESS_BAR-2": "进度条60",
    "PROGRESS_BAR-3": "进度条100",
    "BLUE_PROGRESS_BAR": "蓝色进度条",
    "BLUE_PROGRESS_BAR-1": "蓝色进度条-1",
    "BLUE_PROGRESS_BAR-2": "蓝色进度条-2",
    "BLUE_PROGRESS_BAR-3": "蓝色进度条-3",
    "STEREOSCOPIC_QUESTION_MARK": "立体问号",
    "STEREOSCOPIC_QUESTION_MARK-1": "问号-灰",
    "STEREOSCOPIC_QUESTION_MARK-2": "问号-金",
    "BUBBLE_BOX": "气泡框",
    "BUBBLE_BOX-1": "气泡框-1",
    "BUBBLE_BOX-2": "气泡框-2",
    "VICTORY_FAIL": "胜利失败",
    "VICTORY_FAIL-1": "胜",
    "VICTORY_FAIL-2": "利",
    "VICTORY_FAIL-3": "失",
    "VICTORY_FAIL-4": "败",
    "REFERENCE_NO": "引用号",
    "REFERENCE_NO-1": "引用号-1",
    "REFERENCE_NO-2": "引用号-2",
    "RED_STRIPE": "红条",
    "GOLD_COIN1": "金币1",
    "BLUE_STRIPE": "蓝条",
    "LIFE": "生命",
    "QUADRANGULAR_STAR": "四角星",
    "QUESTION_MARK": "问号",
    "FIVE-POINTED_STAR": "五角星",
    "AIRSHIP": "小飞船1",
    "METEORITE": "陨星",
    "AIRCRAFT": "飞机",
    "AIRCRAFT-1": "飞机-1",
    "AIRCRAFT-2": "飞机-2",
    "AIRCRAFT-3": "飞机-3",
    "RETRO_AIRCRAFT": "复古飞机",
    "RETRO_AIRCRAFT-1": "复古飞机-1",
    "RETRO_AIRCRAFT-2": "复古飞机-2",
    "RETRO_AIRCRAFT-3": "复古飞机-3",
    "JEEP": "吉普车",
    "JEEP-1": "吉普车-1",
    "JEEP-2": "吉普车-2",
    "JEEP-3": "吉普车-3",
    "MOTORBOAT": "摩托艇",
    "MOTORBOAT-1": "摩托艇_侧视",
    "MOTORBOAT-2": "摩托艇_俯视",
    "MOTORBOAT-3": "摩托艇_后视",
    "MOTORBOAT-4": "摩托艇_正视",
    "SUBMARINE": "潜水艇",
    "SUBMARINE-1": "潜水艇_侧视",
    "SUBMARINE-2": "潜水艇_俯视",
    "SUBMARINE-3": "潜水艇_后视",
    "SUBMARINE-4": "潜水艇_正视",
    "SMALL_SAILBOAT": "小帆船1",
    "SMALL_SAILBOAT-1": "小帆船_侧视",
    "SMALL_SAILBOAT-2": "小帆船_俯视",
    "SMALL_SAILBOAT-3": "小帆船_后视",
    "SMALL_SAILBOAT-4": "小帆船_正视",
    "VAN": "小货车",
    "VAN-1": "小货车_侧视",
    "VAN-2": "小货车_俯视",
    "VAN-3": "小货车_后视",
    "VAN-4": "小货车_正视",
    "HELICOPTER": "直升机",
    "HELICOPTER-1": "直升机_侧视",
    "HELICOPTER-2": "直升机_俯视-1",
    "HELICOPTER-3": "直升机_俯视-2",
    "HELICOPTER-4": "直升机_俯视-3",
    "HELICOPTER-5": "直升机_后视",
    "HELICOPTER-6": "直升机_正视",
    "BICYCLE": "自行车-1",
    "FIRECRACKERS": "鞭炮",
    "FIRECRACKERS-1": "鞭炮-1",
    "FIRECRACKERS-2": "鞭炮-2",
    "FIRECRACKERS-3": "鞭炮-3",
    "COLORFUL_FLAGS": "彩旗",
    "COLORFUL_FLAGS-1": "彩旗-1",
    "COLORFUL_FLAGS-2": "彩旗-2",
    "HAPPY_KNOT": "福结",
    "HAPPY_KNOT-1": "福结-1",
    "HAPPY_KNOT-2": "福结-2",
    "HAPPY_KNOT-3": "福结-3",
    "HAPPY_KNOT-4": "福结-4",
    "HAPPY_KNOT-5": "福结-5",
    "HAPPY_KNOT-6": "福结-6",
    "RED_ENVELOPE_ANIMATION": "红包动画",
    "RED_ENVELOPE_ANIMATION-1": "红包动画-1",
    "RED_ENVELOPE_ANIMATION-2": "红包动画-2",
    "RED_ENVELOPE_ANIMATION-3": "红包动画-3",
    "RED_ENVELOPE_ANIMATION-4": "红包动画-4",
    "RED_ENVELOPE_ANIMATION-5": "红包动画-5",
    "RED_ENVELOPE_ANIMATION-6": "红包动画-6",
    "GOLD_COIN": "金币2",
    "GOLD_COIN-1": "金币-1",
    "GOLD_COIN-2": "金币-2",
    "GOLD_COIN-3": "金币-3",
    "ELK_CART": "麋鹿车",
    "ELK_CART-1": "麋鹿车-静止",
    "ELK_CART-2": "麋鹿车-行驶",
    "CALENDAR": "日历",
    "CHRISTMAS_CANDY": "圣诞彩杖糖",
    "CHRISTMAS_CANDY-1": "圣诞彩杖糖-1",
    "CHRISTMAS_CANDY-2": "圣诞彩杖糖-2",
    "CHRISTMAS_CANDY-3": "圣诞彩杖糖-3",
    "SANTA_CLAUS": "圣诞老人",
    "SANTA_CLAUS-1": "圣诞老人-1",
    "SANTA_CLAUS-2": "圣诞老人-2",
    "SANTA_CLAUS-3": "圣诞老人-3",
    "SANTA_CLAUS2": "圣诞老人2",
    "JINGLING_BELL": "圣诞铃铛",
    "JINGLING_BELL-1": "圣诞铃铛-1",
    "JINGLING_BELL-2": "圣诞铃铛-2",
    "JINGLING_BELL-3": "圣诞铃铛-金",
    "JINGLING_BELL-4": "圣诞铃铛-银",
    "CHRISTMAS_TREE": "圣诞树",
    "CHRISTMAS_TREE-1": "圣诞树-1",
    "CHRISTMAS_TREE-2": "圣诞树-2",
    "CHRISTMAS_TREE_DYNAMIC": "圣诞树-动态",
    "CHRISTMAS_TREE_DYNAMIC-1": "圣诞树-亮红灯",
    "CHRISTMAS_TREE_DYNAMIC-2": "圣诞树-亮黄灯",
    "CHRISTMAS_TREE_DYNAMIC-3": "圣诞树-全关灯",
    "CHRISTMAS_TREE_DYNAMIC-4": "圣诞树-全亮灯",
    "CHRISTMAS_STOCKING": "圣诞袜",
    "CHRISTMAS_STOCKING-1": "圣诞袜-红底绿星",
    "CHRISTMAS_STOCKING-2": "圣诞袜-绿底红星",
    "CHRISTMAS_STOCKING-3": "圣诞袜-条纹红",
    "CHRISTMAS_STOCKING-4": "圣诞袜-条纹绿",
    "CANDY": "糖果",
    "SOCKS": "袜子",
    "SMALL_WINDMILL": "小风车",
    "LITTLE_WOODEN_HORSE": "小木马",
    "SNOWFLAKE": "雪花",
    "SNOWMAN1": "雪人1",
    "SNOWMAN1-1": "雪人-初始",
    "SNOWMAN1-2": "雪人-戴帽子",
    "SNOWMAN1-3": "雪人-戴手套",
    "SNOWMAN1-4": "雪人-戴围巾",
    "SNOWMAN2": "雪人2",
    "SNOWMAN2-1": "雪人-1",
    "SNOWMAN2-2": "雪人-2",
    "SNOWMAN2-3": "雪人头",
    "REINDEER": "驯鹿",
    "REINDEER-1": "驯鹿-1",
    "REINDEER-2": "驯鹿-2",
    "REINDEER_CART": "驯鹿车",
    "REINDEER_CART-1": "驯鹿车-1",
    "REINDEER_CART-2": "驯鹿车-2",
    "ROUND_LANTERN": "圆灯笼",
    "ROUND_LANTERN-1": "圆灯笼-1",
    "ROUND_LANTERN-2": "圆灯笼-2",
    "LONG_LANTERN": "长灯笼",
    "LONG_LANTERN-1": "长灯笼-1",
    "LONG_LANTERN-2": "长灯笼-2",
    "PAPER_AIRPLANE": "纸飞机",
    "PAPER_AIRPLANE-1": "纸飞机-红",
    "PAPER_AIRPLANE-2": "纸飞机-黄",
    "PAPER_AIRPLANE-3": "纸飞机-蓝",
    "PAPER_AIRPLANE-4": "纸飞机-绿",
    "CHINESE_KNOT": "中国结",
    "CHINESE_KNOT-1": "中国结-1",
    "CHINESE_KNOT-2": "中国结-2",
    "SKIING_PEOPLE": "滑雪-人",
    "SKIING_PEOPLE-1": "滑雪-人-起跳1",
    "SKIING_PEOPLE-2": "滑雪-人-起跳2",
    "SKIING_PEOPLE-3": "滑雪-人-滑1",
    "SKIING_PEOPLE-4": "滑雪-人-滑2",
    "BOY": "男孩",
    "BOY-1": "男孩-1",
    "BOY-2": "男孩-2",
    "BOY-3": "男孩-3",
    "BOY-4": "男孩-4",
    "AVATAR_BOY": "男孩头像",
    "AVATAR_GIRL": "女孩头像",
    "GIRL": "女孩",
    "GIRL-1": "女孩-1",
    "GIRL-2": "女孩-2",
    "GIRL-3": "女孩-3",
    "GIRL-4": "女孩-4",
    "TURKEY": "火鸡",
    "GINGERBREAD_MAN": "姜饼人",
    "SKI_MARK": "滑雪痕",
    "SKI_MARK-1": "滑雪痕-1",
    "SKI_MARK-2": "滑雪痕-2",
    "SKI_MARK-3": "滑雪痕-3",
    "SKI_MARK-4": "滑雪痕-4",
    "BACKDROPS_2022_SKI-FIELD-1": "滑雪场-1",
    "BACKDROPS_2022_SKI-FIELD-2": "滑雪场-2",
    "BACKDROPS_2022_AIRPORT-RUNWAY": "机场跑道",
    "BACKDROPS_2022_INTERSECTION": "路口",
    "BACKDROPS_2022_SKY-1": "天空-1",
    "BACKDROPS_2022_SKY-2": "天空-2",
    "BACKDROPS_2022_SETTING-SUN-1": "夕阳-1",
    "BACKDROPS_2022_SETTING-SUN-2": "夕阳-2",
    "BACKDROPS_2022_SETTING-SUN-3": "夕阳-3",
    "BACKDROPS_2022_MARS": "火星",
    "BACKDROPS_2022_STARRY-SKY-1": "星空-1",
    "BACKDROPS_2022_STARRY-SKY-2": "星空-2",
    "BACKDROPS_2022_STARRY-SKY-3": "星空-3",
    "BACKDROPS_2022_MOON-1": "月球-1",
    "BACKDROPS_2022_MOON-2": "月球-2",
    "BACKDROPS_2022_SANTA-BACKGROUND": "圣诞老人背景",
    "BACKDROPS_2022_CHRISTMAS-THEME-BACKGROUND": "圣诞主题背景",
    "BACKDROPS_2022_SNOWMAN-BACKGROUND": "雪人背景",
    "WHITE-MOON": "白月亮",
    "GRASSLAND": "草地",
    "YELLOW-MOON": "黄月亮",
    "SKATEBOARD-1": "滑板-侧面",
    "SKATEBOARD-2": "滑板-底部",
    "SKATEBOARD-3": "滑板-顶部",
    "SKATEBOARD-4": "滑板-正面",
    "SKATEBOARD": "滑板",
    "CARTOON-ARTPLANES-1": "卡通飞机-正面",
    "CARTOON-ARTPLANES-2": "卡通飞机-底部",
    "CARTOON-ARTPLANES-3": "卡通飞机-右侧",
    "CARTOON-ARTPLANES-4": "卡通飞机-左侧",
    "CARTOON-ARTPLANES-5": "卡通飞机-顶部",
    "CARTOON-ARTPLANES-6": "卡通飞机-尾部",
    "CARTOON-ARTPLANES": "卡通飞机",
    "BICYCLE-GRAY-1": "自行车-灰-侧面",
    "BICYCLE-GRAY-2": "自行车-灰-顶部",
    "BICYCLE-GRAY-3": "自行车-灰-正面",
    "BICYCLE-GRAY-4": "自行车-灰-后面",
    "BICYCLE-GRAY": "自行车-灰",
    "BATS-1": "蝙蝠群-1",
    "BATS-2": "蝙蝠群-2",
    "BATS-3": "蝙蝠群-3",
    "BATS-4": "蝙蝠群-4",
    "BATS": "蝙蝠群",
    "CASTLE-1": "古堡-1",
    "CASTLE-2": "古堡-2",
    "CASTLE": "古堡",
    "JACK-o'-LANTERN-1": "南瓜灯1-1",
    "JACK-o'-LANTERN-2": "南瓜灯1-2",
    "JACK-o'-LANTERN-3": "南瓜灯1-3",
    "JACK-o'-LANTERN-4": "南瓜灯2",
    "JACK-o'-LANTERN-5": "南瓜灯3",
    "JACK-o'-LANTERN-6": "南瓜灯4",
    "JACK-o'-LANTERN": "南瓜灯",
    "WOODS-1": "树林-1",
    "WOODS-2": "树林-2",
    "WOODS": "树林",
    "DANCING-LION-RED-1": "醒狮-红-1",
    "DANCING-LION-RED-2": "醒狮-红-2",
    "DANCING-LION-RED-3": "醒狮-红-3",
    "DANCING-LION-RED-4": "醒狮-红-4",
    "DANCING-LION-RED-5": "醒狮-红-5",
    "DANCING-LION-RED-6": "醒狮-红-6",
    "DANCING-LION-RED": "醒狮-红",
    "DANCING-LION-BLUE-1": "醒狮-蓝-1",
    "DANCING-LION-BLUE-2": "醒狮-蓝-2",
    "DANCING-LION-BLUE-3": "醒狮-蓝-3",
    "DANCING-LION-BLUE": "醒狮-蓝",
    "DANCING-LION-GREEN-1": "醒狮-绿-1",
    "DANCING-LION-GREEN-2": "醒狮-绿-2",
    "DANCING-LION-GREEN-3": "醒狮-绿-3",
    "DANCING-LION-GREEN": "醒狮-绿",
    "SPECTRE-1": "幽灵-1",
    "SPECTRE-2": "幽灵-2",
    "SPECTRE-3": "幽灵-3",
    "SPECTRE-4": "幽灵-4",
    "SPECTRE": "幽灵",
    "SPIDER-1": "蜘蛛-1",
    "SPIDER-2": "蜘蛛-2",
    "SPIDER-3": "蜘蛛-3",
    "SPIDER": "蜘蛛",
    "GOLF-PEOPLE1-1": "高尔夫-人物1-1",
    "GOLF-PEOPLE1-2": "高尔夫-人物1-2",
    "GOLF-PEOPLE1-3": "高尔夫-人物1-3",
    "GOLF-PEOPLE1": "高尔夫-人物1",
    "GOLF-PEOPLE2-1": "高尔夫-人物2-1",
    "GOLF-PEOPLE2-2": "高尔夫-人物2-2",
    "GOLF-PEOPLE2-3": "高尔夫-人物2-3",
    "GOLF-PEOPLE2": "高尔夫-人物2",
    "GOLF": "高尔夫球",
    "GOLF-HOLES": "高尔夫球洞",
    "GOLF-SEATS": "高尔夫球座",
    "GREEN-LEAVES-1": "绿叶-1",
    "GREEN-LEAVES-2": "绿叶-2",
    "GREEN-LEAVES": "绿叶",
    "CACTUS-1": "仙人掌-1",
    "CACTUS-2": "仙人掌-2",
    "CACTUS": "仙人掌",
    "FLORETS-1": "小花-1",
    "FLORETS-2": "小花-2",
    "FLORETS-3": "小花-3",
    "FLORETS-4": "小花-4",
    "FLORETS-5": "小花-5",
    "FLORETS-6": "小花-6",
    "FLORETS-7": "小花-7",
    "FLORETS-8": "小花-8",
    "FLORETS": "小花"
  },
  "mn": {
    "gui.gui.project": "ᠲᠥᠰᠥᠯ",
    "gui.gui.newItem": " ᠰᠢᠨ᠎ᠡ ᠲᠥᠰᠥᠯ ᠪᠠᠢᠭᠤᠯᠬᠤ",
    "gui.gui.load": "ᠲᠥᠰᠥᠯ ᠢ ᠨᠡᠭᠡᠭᠡᠬᠦ",
    "gui.gui.iotDashboard": "可视化面板",
    "gui.gui.save": "ᠲᠥᠰᠥᠯ ᠢ ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ",
    "gui.gui.saveAs": " ᠲᠥᠰᠥᠯ ᠢ ᠥᠭᠡᠷ᠎ᠡ ᠨᠡᠷ᠎ᠡ ᠪᠡᠷ ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ",
    "gui.gui.saveAsMp": "另存为mp项目",
    "gui.gui.saveAsSb3": "另存为sb3项目",
    "gui.gui.cacheList": "缓存列表",
    "gui.gui.inCache": "缓存中",
    "gui.gui.learn": "ᠬᠢᠴᠢᠶᠡᠯ",
    "gui.gui.clearRecord": "ᠲᠡᠮᠳᠡᠭᠯᠡᠯ ᠢ ᠠᠷᠢᠯᠭᠠᠬᠤ",
    "gui.gui.onlineLearn": "ᠠᠯᠪᠠᠨ ᠤ ᠹᠠᠢᠯ",
    "gui.gui.onlineForum": " ᠰᠦᠯᠵᠢᠶᠡᠨ ᠲᠠᠪᠴᠠᠩ",
    "gui.gui.videoTutorial": "ᠸᠢᠳᠢᠣ᠋ ᠬᠢᠴᠢᠶᠡᠯ",
    "gui.gui.offlineLearn": "ᠵᠢᠱᠢᠶ᠎ᠡ ᠫᠷᠣᠭ᠍ᠷᠠᠮ",
    "gui.menuBar.turboModeOff": "ᠲᠦᠷᠭᠡᠳᠭᠡᠬᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢ ᠬᠠᠭᠠᠬᠤ",
    "gui.menuBar.turboModeOn": "ᠲᠦᠷᠭᠡᠳᠭᠡᠬᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢ ᠨᠡᠭᠡᠭᠡᠬᠦ",
    "gui.menuBar.edit": "ᠨᠠᠢᠷᠠᠭᠤᠯᠬᠤ",
    "gui.menuBar.restoreSprite": "ᠬᠠᠰᠤᠭᠰᠠᠨ ᠳᠦᠷᠢ ᠶᠢ ᠰᠡᠷᠭᠦᠭᠡᠬᠦ",
    "gui.menuBar.restoreSound": "ᠬᠠᠰᠤᠭᠰᠠᠨ ᠳᠠᠭᠤᠨ ᠢ ᠰᠡᠷᠭᠦᠭᠡᠬᠦ",
    "gui.menuBar.restoreCostume": "ᠬᠠᠰᠤᠭᠰᠠᠨ ᠲᠦᠷᠬᠦ ᠶᠢ ᠰᠡᠷᠭᠦᠭᠡᠬᠦ",
    "gui.menuBar.restoreBackdrop": "复原删除的背景",
    "gui.editorMind.restore": "ᠤᠰᠠᠳᠬᠠᠭᠰᠠᠨ ᠢᠶᠠᠨ ᠰᠡᠷᠭᠦᠭᠡᠬᠦ",
    "gui.turboMode.active": "ᠲᠦᠷᠭᠡᠳᠬᠡᠬᠦ ᠬᠡᠯᠪᠡᠷᠢ",
    "gui.gui.connectDevice": "ᠲᠣᠨᠣᠭ ᠢ ᠵᠠᠯᠭᠠᠬᠤ",
    "gui.gui.disconnectDevice": " ᠲᠣᠨᠣᠭ ᠢ ᠰᠠᠯᠭᠠᠬᠤ",
    "gui.gui.installSerialportDriver": " COM ᠵᠠᠯᠭᠠᠯᠲᠠ ᠶᠢᠨ ᠬᠥᠳᠡᠯᠭᠡᠬᠦ ᠫᠷᠣᠭ᠍ᠷᠠᠮ ᠢ ᠠᠦ᠋ᠲ᠋ᠣ᠋ ᠤᠭᠰᠠᠷᠠᠬᠤ",
    "gui.gui.openInstallSerialportDriverCourse": "查看驱动安装教程",
    "gui.gui.openDM": "ᠲᠣᠨᠣᠭ ᠬᠠᠮᠢᠶᠠᠷᠤᠭᠴᠢ ᠶᠢ ᠨᠡᠭᠡᠭᠡᠬᠦ",
    "gui.gui.restoreSetting": "恢复设备初始设置",
    "gui.gui.updateTips": "ᠰᠢᠨᠡᠳᠬᠡᠬᠦ ᠰᠠᠨᠠᠭᠤᠯᠤᠮᠵᠢ",
    "gui.gui.newVersion": " ᠬᠠᠮᠤᠭ ᠰᠢᠨ᠎ᠡ ᠬᠡᠪ",
    "gui.gui.downloadUpdate": " ᠪᠠᠭᠤᠯᠭᠠᠬᠤ",
    "gui.gui.versionUpdate": " ᠰᠢᠨᠡᠳᠬᠡᠯ ᠬᠡᠪ",
    "gui.gui.isCheckMd5": "正在校验",
    "gui.gui.downloading": "检查更新中",
    "gui.gui.updateError": "更新错误",
    "gui.setting.feedbackMenu": " ᠰᠠᠨᠠᠯ ᠳᠡᠪᠰᠢᠭᠦᠯᠬᠦ",
    "gui.gui.arduino": "ᠠᠴᠢᠶᠠᠯᠠᠬᠤ ᠬᠡᠯᠪᠡᠷᠢ",
    "gui.gui.scratch": "ᠪᠣᠳᠠᠲᠤ ᠬᠡᠯᠪᠡᠷᠢ",
    "gui.gui.python": "Python模式",
    "gui.setting.officialWebsit": " ᠠᠯᠪᠠᠨ ᠰᠦᠯᠵᠢᠶᠡᠨ ᠦ ᠬᠠᠶ᠋ᠢᠭ",
    "gui.setting.officialQQGroup": "ᠠᠯᠪᠠᠨ QQ ᠴᠤᠭᠯᠠᠷᠠᠯ",
    "gui.setting.wechatOfficialAccounts": " ᠠᠯᠪᠠᠨ ᠸᠡᠢᠴᠠᠲ ᠳᠠᠪᠴᠠᠩ",
    "gui.setting.currentVersion": "ᠣᠳᠣᠬᠠᠨ ᠤ ᠬᠡᠪ",
    "gui.setting.checkForUpdate": "ᠰᠢᠨᠡᠳᠬᠡᠯ ᠢ ᠪᠠᠢᠴᠠᠭᠠᠬᠤ",
    "gui.setting.remindWhenUpdate": "ᠰᠢᠨᠡᠳᠬᠡᠯ ᠪᠠᠢᠬᠤ ᠦᠶᠡᠰ᠂ ᠠᠦ᠋ᠲ᠋ᠣ᠋ ᠪᠠᠭᠤᠯᠭᠠᠬᠤ",
    "gui.setting.currentIsLatest": " ᠨᠢᠭᠡᠨᠲᠡ ᠬᠠᠮᠤᠭ ᠰᠢᠨ᠎ᠡ ᠬᠡᠪ ᠪᠣᠯᠪᠠ",
    "gui.setting.latestVersion": "ᠬᠠᠮᠤᠭ ᠰᠢᠨ᠎ᠡ ᠬᠡᠪ",
    "gui.setting.download": " ᠲᠠᠲᠠᠬᠤ ᠨᠢᠭᠤᠷ ᠲᠤ ᠠᠯᠭᠠᠰᠠᠨ ᠣᠷᠣᠬᠤ",
    "gui.setting.language": "ᠬᠡᠯᠡ",
    "gui.setting.uploadCsv": "xlsx ᠢ ᠳᠡᠪᠰᠢᠭᠦᠯᠬᠦ",
    "gui.setting.theme": " ᠭᠣᠣᠯ ᠰᠡᠳᠦᠪ",
    "gui.setting.feedback": " ᠰᠠᠨᠠᠯ ᠳᠡᠪᠰᠢᠭᠦᠯᠬᠦ",
    "gui.setting.email": "ᠢᠮᠧᠯ(E-mail)",
    "gui.setting.opinionFeedback": "ᠰᠠᠨᠠᠯ/ ᠲᠤᠰᠬᠠᠯ",
    "gui.variableMc.variableName": "ᠬᠤᠪᠢᠰᠬᠤᠨ ᠤ ᠨᠡᠷ᠎ᠡ",
    "gui.variableMc.variableOff": "ᠪᠣᠯᠢ",
    "gui.variableMc.variableOn": "ᠲᠡᠭᠡ",
    "gui.variableMc.newVariable": "ᠰᠢᠨ᠎ᠠ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠪᠠᠢᠭᠤᠯᠬᠤ",
    "gui.setting.helpUsTranslate": "ᠪᠢᠳᠡᠨ ᠢ ᠬᠠᠪᠰᠤᠷᠴᠤ ᠣᠷᠴᠢᠭᠤᠯᠬᠤ",
    "gui.setting.checkUpdate": "ᠰᠢᠨᠡᠳᠭᠡᠬᠦ ᠪᠠᠢᠴᠠᠭᠠᠯᠲᠠ",
    "gui.setting.ok": "ᠲᠤᠰᠢᠶᠠᠬᠤ",
    "gui.setting.languageSetting": "Хэл",
    "gui.setting.themeSetting": "Сэдэв",
    "gui.setting.cacheSetting": "缓存设置",
    "gui.setting.versionUpdate": "ᠬᠡᠪ ᠰᠢᠨᠡᠳᠭᠡᠬᠦ",
    "gui.setting.connectUsTitle": "ᠪᠢᠳᠡᠨ ᠲᠡᠢ ᠬᠠᠷᠢᠴᠠᠬᠤ",
    "gui.setting.uploadAttachment": "ᠳᠠᠭᠠᠯᠲᠠ ᠬᠠᠪᠰᠤᠷᠭᠠᠬᠤ",
    "gui.setting.displayTitle": "ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠶᠢ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ",
    "gui.setting.fontSizeSetting": "字体字号",
    "gui.setting.restartEffect": "重启生效",
    "gui.setting.fontSizeLarge": "ᠲᠣᠮᠤ",
    "gui.setting.fontSizeMiddle": "ᠳᠤᠮᠳᠠ",
    "gui.setting.fontSizeSmall": "ᠪᠠᠭ᠎ᠠ",
    "gui.setting.onlineRepair": "在线修复工具，点击打开 >",
    "gui.setting.cacheSwitch": "缓存(打开/关闭)",
    "gui.setting.freeCache": "空闲缓存",
    "gui.setting.spaceCache": "固定间隔时间",
    "gui.setting.cacheWay": "缓存方式",
    "gui.setting.interval": "时间间隔",
    "gui.setting.freeTime": "空闲",
    "gui.setting.second": "秒",
    "gui.setting.minute": "分钟",
    "gui.gui.code": "代码",
    "gui.menubar.cloudProject": "云端项目",
    "gui.menubar.login": "登录",
    "gui.menubar.signout": "退出登录",
    "gui.menubar.saveToCloud": "保存到云端",
    "gui.menubar.cloudServer": "云端",
    "gui.setting.cleanCache": "数据清理",
    "gui.setting.programCache": "项目缓存",
    "gui.setting.configJson": "配置文件",
    "gui.setting.pythonEnv": "Python环境",
    "gui.setting.backpack": "书包文件",
    "gui.setting.compile": "编译信息",
    "gui.setting.thirdExt": "扩展用户库",
    "gui.setting.dashboard": "可视化面板",
    "gui.setting.cleanCacheBtn": "清除选择项",
    "gui.setting.cleanCachePrompt": "数据清除后无法恢复，确定清除吗？",
    "gui.setting.cleanPythonEnvPrompt": "为避免Python环境清除引起其他窗口异常，清除前请保证仅打开了一个Mind+软件窗口。",
    "gui.setting.cleanPyEnvOnPythonPrompt": "Python模式下，清理资源后需重置环境，此过程需要等待一段时间。且为避免清除资源引起其他窗口异常，清除前请保证仅打开了一个Mind+软件窗口。",
    "gui.setting.cleanDashboardPrompt": "将清除可视化面板中的所有项目，清除后不可恢复。",
    "gui.setting.expVersion": "体验版",
    "gui.setting.expUpdateTips": "【更新须知】: 该版本为尚未正式发布的尝鲜版，部分功能可能不稳定，请确认后再下载;",
    "gui.setting.cleanConfigSuccess": "数据清理完成，请重启Mind+。",
    "gui.setting.cleanConfigError": "数据清理过程中出现错误，请重新尝试一次",
    "gui.setting.cleanPartError": "清理[PART]出错......",
    "gui.setting.cleaningPyEnv": "正在清理Python环境.....",
    "gui.setting.cleanPythonEnvError": "Python环境资源释放失败，你可以关闭软件，手动删除文件夹 [PATH] 后，再重新打开软件",
    "gui.setting.multiWindowSetting": "软件多开",
    "gui.setting.multiWindowSettingTips": "为避免同时打开多个软件导致系统卡顿，性能较弱的电脑推荐关闭该功能",
    "gui.setting.multiWindowTrue": "开",
    "gui.setting.multiWindowFalse": "关",
    "gui.setting.systemSetting": "系统设置",
    "gui.backpack.header": "书包",
    "gui.backpack.errorBackpack": "载入书包时出错了",
    "gui.backpack.loadingBackpack": "载入中……",
    "gui.backpack.more": "更多",
    "gui.backpack.emptyBackpack": "书包是空的，尝试拖入一些积木程序到书包收藏。",
    "gui.gui.blocks": "ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.gui.costumesTab": "ᠵᠣᠯᠭᠠᠴᠠ",
    "gui.gui.soundsTab": "ᠳᠠᠭᠤ",
    "gui.gui.backdropsTab": "ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ",
    "gui.gui.addExtension": "ᠥᠷᠭᠡᠳᠬᠡᠬᠦ",
    "gui.costumeTab.addCostumeFromLibrary": "ᠳᠦᠷᠰᠦ ᠶᠢᠨ ᠬᠥᠮᠥᠷᠭᠡ",
    "gui.costumeLibrary.chooseACostume": "ᠳᠦᠷᠰᠦ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.costumeTab.addBackdropFromLibrary": "ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ ᠦᠨ ᠬᠥᠮᠥᠷᠭᠡ",
    "gui.costumeTab.addBlankCostume": "ᠪᠢᠷ",
    "gui.costumeTab.addSurpriseCostume": "ᠲᠠᠰᠢᠷᠠᠮ",
    "gui.costumeTab.addFileBackdrop": "ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ  ᠢ ᠠᠴᠢᠶᠠᠯᠠᠬᠤ",
    "gui.costumeTab.addFileCostume": "ᠳᠦᠷᠰᠦ ᠠᠴᠢᠶᠠᠯᠠᠬᠤ",
    "gui.costumeTab.addCameraCostume": "ᠺᠠᠮᠧᠷᠠ",
    "gui.sliderModal.min": "最小值",
    "gui.sliderModal.max": "最大值",
    "gui.sliderModal.title": "改变滑块范围",
    "gui.soundEditor.sound": "ᠳᠠᠭᠤ",
    "gui.soundEditor.play": "ᠡᠬᠢᠯᠡᠬᠦ",
    "gui.soundEditor.stop": "ᠵᠣᠭᠰᠣᠭᠠᠬᠤ",
    "gui.soundEditor.trim": " ᠡᠰᠭᠡᠬᠦ",
    "gui.soundEditor.save": "ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ",
    "gui.soundEditor.undo": "ᠪᠤᠴᠠᠬᠤ",
    "gui.soundEditor.redo": "ᠲᠠᠬᠢᠨ ᠬᠢᠬᠦ",
    "gui.soundEditor.faster": "ᠲᠦᠷᠭᠡᠳᠬᠡᠬᠦ",
    "gui.soundEditor.slower": "ᠤᠳᠠᠭᠠᠰᠢᠷᠠᠭᠤᠯᠬᠤ",
    "gui.soundEditor.echo": " ᠴᠤᠤᠷᠢᠶᠠ",
    "gui.soundEditor.robot": " ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ",
    "gui.soundEditor.louder": "ᠳᠠᠭᠤ ᠶᠢ ᠶᠡᠬᠡᠰᠬᠡᠬᠦ",
    "gui.soundEditor.softer": "ᠨᠠᠮᠤᠭᠤᠬᠠᠨ",
    "gui.soundEditor.reverse": "ᠡᠰᠡᠷᠭᠦ",
    "gui.soundEditor.copy": "复制",
    "gui.soundEditor.paste": "粘贴",
    "gui.soundEditor.copyToNew": "新拷贝",
    "gui.soundEditor.delete": "删除",
    "gui.soundEditor.fadeOut": "渐弱",
    "gui.soundEditor.fadeIn": "渐强",
    "gui.soundEditor.mute": "静音",
    "gui.soundTab.recordSound": " ᠳᠠᠭᠤ ᠰᠢᠩᠭᠡᠭᠡᠬᠦ",
    "gui.soundTab.addSoundFromLibrary": " ᠳᠠᠭᠤᠨ ᠬᠥᠮᠥᠷᠭᠡ",
    "gui.soundTab.surpriseSound": "ᠲᠠᠰᠢᠷᠠᠮ",
    "gui.soundTab.fileUploadSound": "ᠳᠠᠭᠤ ᠠᠴᠢᠶᠠᠯᠠᠬᠤ",
    "gui.soundTab.addSound": "ᠳᠠᠭᠤ ᠨᠡᠮᠡᠬᠦ",
    "gui.controls.stop": "ᠵᠣᠭᠰᠣᠭᠠᠬᠤ",
    "gui.controls.go": "ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠬᠤ",
    "gui.controls.customFirmware": "自定义固件",
    "gui.controls.defaultFirmware": "官方固件",
    "gui.controls.clearLocaleFirmware": "清空记录",
    "gui.controls.checkForUpdates": "检查更新",
    "gui.controls.alreadyLastVersion": "已经是最新了",
    "gui.controls.uploadFirmware": "本地加载",
    "gui.gui.startPython": "python  ᠢ ᠡᠬᠢᠯᠡᠭᠦᠯᠬᠦ",
    "gui.gui.stopPython": "python  ᠢ ᠵᠣᠭᠰᠤᠭᠠᠬᠤ",
    "gui.controls.fullScreenControl": " ᠲᠠᠢᠰᠠ ᠶᠢ ᠳᠡᠯᠭᠡᠴᠡ ᠳᠦᠭᠦᠷᠭᠡᠬᠦ",
    "gui.gui.stageSizeLarge": "ᠲᠣᠮᠣ ᠲᠠᠢᠰᠠᠨ ᠤ ᠮᠣᠳᠧᠯ",
    "gui.gui.stageSizeSmall": "ᠵᠢᠵᠢᠭ ᠲᠠᠢᠰᠠᠨ ᠤ ᠮᠣᠳᠧᠯ",
    "gui.gui.stageSizeNostage": "ᠲᠠᠢᠰᠠ ᠦᠭᠡᠢ ᠮᠣᠳᠧᠯ",
    "gui.gui.stageSizeFull": " ᠲᠠᠢᠰᠠ ᠪᠠᠨ ᠲᠡᠯᠭᠡᠴᠡ ᠳᠦᠭᠦᠷᠭᠡᠬᠦ",
    "gui.stageHeader.stageSizeUnFull": "ᠲᠠᠢᠰᠠ ᠪᠠᠨ ᠪᠠᠭ᠎ᠠ  ᠳᠡᠯᠭᠡᠴᠡ ᠳᠦ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ",
    "gui.sprite.sprite": " ᠠᠨᠤᠬᠠᠢ",
    "gui.SpriteInfo.show": "ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ",
    "gui.SpriteInfo.size": "ᠬᠠᠷᠢᠴᠠᠯ",
    "gui.sprite.direction": "ᠴᠢᠭᠯᠡᠯ",
    "gui.sprite.rotation": "ᠥᠨᠴᠥᠭ",
    "gui.directionPicker.rotationStyles.allAround": "ᠴᠢᠯᠦᠭᠡᠲᠡᠢ ᠡᠷᠭᠢᠯᠳᠦᠬᠦ",
    "gui.directionPicker.rotationStyles.leftRight": " ᠵᠡᠭᠦᠨᠰᠢ ᠪᠠᠷᠠᠭᠤᠨᠰᠢ ᠡᠷᠭᠢᠭᠦᠯᠬᠦ",
    "gui.directionPicker.rotationStyles.dontRotate": " ᠥᠯᠥ ᠡᠷᠭᠢᠭᠦᠯᠬᠦ",
    "gui.spriteSelectorItem.contextMenuDuplicate": "ᠺᠣᠫᠢᠳᠠᠬᠤ",
    "gui.spriteSelectorItem.contextMenuDelete": "ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "gui.spriteSelectorItem.contextMenuExport": "ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠨ ᠭᠠᠷᠭᠠᠬᠤ",
    "gui.sprite.addSpriteFromLibrary": "ᠳᠦᠷᠢ ᠶᠢᠨ ᠬᠥᠮᠥᠷᠭᠡ",
    "gui.spriteSelector.addSpriteFromPaint": "ᠪᠢᠷ",
    "gui.spriteSelector.addSpriteFromSurprise": "ᠲᠠᠰᠢᠷᠠᠮ",
    "gui.spriteSelector.addSpriteFromFile": "ᠠᠴᠢᠶᠠᠯᠠᠬᠤ",
    "gui.sprite.addSpriteFromCamera": " ᠳᠦᠷᠢ ᠰᠢᠩᠭᠡᠭᠡᠬᠦ",
    "gui.stageSelector.stage": "ᠲᠠᠢᠰᠠ",
    "gui.stageSelector.backdrops": " ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ",
    "gui.stage.addBackdropFromLibrary": "ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ ᠦᠨ ᠬᠥᠮᠥᠷᠭᠡ",
    "gui.stageSelector.addBackdropFromPaint": "ᠪᠢᠷ",
    "gui.stageSelector.addBackdropFromSurprise": "ᠲᠠᠰᠢᠷᠠᠮ",
    "gui.stageSelector.addBackdropFromFile": "ᠳᠡᠪᠢᠰᠭᠡᠷ ᠳᠡᠪᠰᠢᠭᠦᠯᠬᠦ",
    "gui.stage.addBackdropFromCamera": " ᠺᠠᠮᠧᠷᠠ",
    "gui.modal.help": "帮助",
    "gui.modal.save": "保存",
    "gui.modal.run": "运行",
    "gui.modal.paused": "暂停",
    "gui.modal.continue": "继续",
    "gui.modal.back": "ᠪᠤᠴᠠᠬᠤ",
    "gui.extension.kit": " ᠢᠵᠢ ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ",
    "gui.extension.kitTitle": " ᠢᠵᠢ ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.extension.board": "ᠡᠵᠡᠮᠰᠢᠭᠦᠷ",
    "gui.extension.boardTitle": "ᠡᠵᠡᠮᠰᠢᠭᠦᠷ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.extension.spreadBoard": "ᠥᠷᠭᠡᠳᠭᠡᠯ ᠬᠠᠪᠲᠠᠰᠤ",
    "gui.extension.spreadBoardTitle": "ᠥᠷᠭᠡᠳᠭᠡᠯ ᠬᠠᠪᠲᠠᠰᠤ ᠶᠢ ᠰᠣᠩᠭᠤᠬᠤ",
    "gui.extension.sensor": "ᠨᠥᠯᠥᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sensorTitle": "ᠨᠥᠯᠥᠭᠡᠯᠡᠭᠦᠷ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.extension.actuator": "ᠭᠦᠢᠴᠡᠳᠬᠡᠭᠦᠷ",
    "gui.extension.actuatorTitle": "ᠭᠦᠢᠴᠡᠳᠬᠡᠭᠦᠷ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.extension.communicationModule": "ᠬᠣᠯᠪᠣᠭᠠᠨ ᠤ ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.communicationModuleTitle": "ᠬᠣᠯᠪᠣᠭᠠᠨ ᠤ ᠬᠡᠰᠡᠭᠲᠦ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.extension.display": "ᠳᠡᠯᠭᠡᠴᠡ",
    "gui.extension.displayTitle": "ᠳᠡᠯᠭᠡᠴᠡ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.extension.function": "ᠴᠢᠳᠠᠮᠵᠢ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.functionTitle": "ᠴᠢᠳᠠᠮᠵᠢ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.extension.internetService": " ᠰᠦᠯᠵᠢᠶᠡ",
    "gui.extension.internetServiceTitle": " ᠰᠦᠯᠵᠢᠶᠡᠨ ᠳ᠋ᠤ ᠵᠠᠯᠭᠠᠬᠤ",
    "gui.extension.thirdExpansion": "用户库",
    "gui.extension.thirdExpansionTitle": "选择用户库",
    "gui.extension.arduinContentLabel": " ᠬᠠᠪᠲᠠᠰᠤ ᠰᠣᠩᠭᠤᠬᠤ",
    "gui.extension.openUserExFile": "打开你计算机上的用户库文件",
    "gui.extension.importUserEx": "导入用户库",
    "gui.extension.unavailable": "不可用",
    "gui.extension.thirdModulesHelp": "注意：用户库中的模块由Mind+爱好者制作，{clickHelp}查看{tutorial}以及{list}",
    "gui.extension.thirdTutorial": "开发教程",
    "gui.extension.thirdList": "用户库列表",
    "gui.extension.thirdModuleSearch": "搜索或输入项目网址",
    "gui.extension.libaryOffice": "官方库",
    "gui.extension.libaryPinpong": "pinpong库",
    "gui.library.filterPlaceholder": "ᠬᠠᠢᠬᠤ",
    "gui.libraryTags.all": "ᠪᠦᠭᠦᠳᠡ",
    "gui.libraryTags.animals": "ᠠᠮᠢᠲᠠᠨ",
    "gui.libraryTags.dance": "ᠪᠥᠵᠢᠭ",
    "gui.libraryTags.effects": "ᠳᠠᠭᠤᠨ ᠨᠥᠯᠥᠭᠡ",
    "gui.libraryTags.fantasy": "ᠬᠠᠴᠢᠨ ᠭᠠᠢᠬᠠᠯᠲᠠᠢ",
    "gui.libraryTags.fashion": "ᠤᠷᠤᠰᠬᠠᠯ",
    "gui.libraryTags.food": "ᠢᠳᠡᠭᠡᠨ",
    "gui.libraryTags.festival": "节日",
    "gui.libraryTags.traffic": "交通",
    "gui.libraryTags.indoors": "ᠭᠡᠷ ᠳᠣᠲᠣᠷ᠎ᠠ",
    "gui.libraryTags.loops": "ᠣᠷᠴᠢᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ",
    "gui.libraryTags.music": "ᠬᠥᠭᠵᠢᠮ",
    "gui.libraryTags.notes": "ᠨᠣᠲ",
    "gui.libraryTags.outdoors": "ᠭᠡᠷ ᠦᠨ ᠭᠠᠳᠠᠨ᠎ᠠ",
    "gui.libraryTags.patterns": "ᠦᠯᠢᠭᠡᠷ ᠵᠢᠷᠤᠭ",
    "gui.libraryTags.people": "ᠬᠥᠮᠥᠨ",
    "gui.libraryTags.percussion": "ᠳᠡᠯᠡᠳᠬᠦ ᠬᠥᠭᠵᠢᠮᠳᠡᠭᠦᠷ",
    "gui.libraryTags.space": "ᠰᠠᠨᠰᠠᠷ",
    "gui.libraryTags.sports": "ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ",
    "gui.libraryTags.underwater": "ᠳᠠᠯᠠᠢ ᠳᠣᠣᠷᠠᠬᠢ ᠶᠢᠷᠲᠢᠨᠴᠦ",
    "gui.libraryTags.voice": "ᠠᠪᠢᠶ᠎ᠠ",
    "gui.libraryTags.wacky": "ᠳᠦᠷᠰᠦ ᠦᠭᠡᠢᠳᠡᠬᠦ",
    "gui.libraryTags.animation": "ᠺᠠᠷᠲ᠋ᠤᠨ",
    "gui.libraryTags.art": "ᠤᠷᠠᠯᠢᠭ",
    "gui.libraryTags.games": "ᠲᠣᠭᠯᠠᠭᠠᠮ",
    "gui.libraryTags.stories": "ᠦᠯᠢᠭᠡᠷ",
    "gui.libraryTags.letters": "ᠦᠰᠦᠭ",
    "gui.libraryTags.mind": "Mind+",
    "gui.libraryTags.natural": "自然",
    "gui.libraryTags.button": "按钮",
    "gui.libraryTags.dialog": "对话框",
    "gui.libraryTags.epidemicPrevention": "防疫",
    "gui.libraryTags.numberAndLetter": "数字&字母",
    "gui.libraryTags.gameComponents": "游戏组件",
    "gui.extension.backdropLib": "ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.soundLibrary.chooseASound": "ᠳᠠᠭᠤ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.SpriteInfo.spritePlaceholder": " ᠨᠡᠷᠡ",
    "gui.extension.spriteLib": "ᠳᠦᠷᠢ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.gui.unselectedSerialport": " ᠲᠣᠨᠣᠭ ᠢᠶᠠᠨ ᠰᠣᠩᠭᠣᠭᠰᠠᠨ ᠦᠭᠡᠢ  ᠪᠠᠢᠨ᠎ᠠ",
    "gui.gui.unConnectedDev": "ᠲᠣᠨᠣᠭ ᠢᠶᠠᠨ ᠵᠠᠯᠭᠠᠭᠰᠠᠨ ᠦᠭᠡᠢ  ᠪᠠᠢᠨ᠎ᠠ",
    "gui.gui.pythonMode": "Python ᠬᠡᠯᠡᠨ ᠦ ᠬᠡᠯᠪᠡᠷᠢ",
    "gui.gui.burnFirmware": " ᠬᠠᠲᠠᠭᠤ ᠲᠣᠨᠤᠭ  ᠳ᠋ᠤ ᠳᠠᠬᠢᠨ ᠤᠭᠰᠠᠷᠠᠬᠤ",
    "gui.gui.burnFirmwareError": " ᠬᠠᠲᠠᠭᠤ ᠲᠣᠨᠤᠭ  ᠲᠤ ᠤᠭᠰᠠᠷᠠᠯᠲᠠ ᠠᠯᠳᠠᠭ",
    "gui.gui.connected": "ᠲᠣᠨᠣᠭ ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠵᠠᠯᠭᠠᠭᠳᠠᠪᠠ",
    "gui.gui.failedConnected": "ᠲᠣᠨᠣᠭ ᠤᠨ ᠵᠠᠯᠭᠠᠯᠲᠠ ᠠᠯᠳᠠᠭ",
    "gui.gui.connecting": "ᠲᠣᠨᠣᠭ ᠲᠠᠢ ᠶᠠᠭ ᠵᠠᠯᠭᠠᠭᠳᠠᠵᠤ  ᠪᠠᠢᠨ᠎ᠠ",
    "gui.cards.all-how-tos": "ᠪᠦᠬᠦ ᠬᠢᠴᠢᠶᠡᠯ",
    "gui.cards.how-tos": "ᠰᠠᠭᠤᠷᠢ ᠬᠢᠴᠢᠶᠡᠯ",
    "gui.cards.remove": "ᠬᠢᠴᠢᠶᠡᠯ ᠡᠴᠡ ᠭᠠᠷᠬᠤ",
    "gui.cards.more-things-to-try": "ᠨᠡᠩ ᠣᠯᠠᠨ ᠬᠢᠴᠢᠶᠡᠯ",
    "gui.cards.see-more": "ᠨᠡᠩ ᠣᠯᠠᠨ ᠢ ᠦᠵᠡᠬᠦ",
    "gui.cards.shrink": "Жижигрүүлэх",
    "gui.cards.expand": "Томруулах",
    "gui.cards.close": "Хаах",
    "gui.loader.message1": " ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢ ᠪᠠᠢᠭᠤᠯᠬᠤ",
    "gui.loader.message2": "ᠳᠦᠷᠢ ᠶᠢ ᠠᠴᠢᠶᠠᠯᠠᠵᠤ   ᠪᠠᠢᠨ᠎ᠠᠠ",
    "gui.loader.message3": "ᠳᠠᠭᠤ ᠶᠢ ᠠᠴᠢᠶᠠᠯᠠᠵᠤ  ᠪᠠᠢᠨ᠎ᠠᠠ",
    "gui.loader.message4": "ᠥᠷᠭᠡᠳᠬᠡᠬᠦ ᠵᠠᠭᠪᠤᠷ ᠢ ᠠᠴᠢᠶᠠᠯᠠᠵᠤ  ᠪᠠᠢᠨ᠎ᠠ",
    "gui.loader.message5": "Herding cats …",
    "gui.loader.message6": "Transmitting nanos …",
    "gui.loader.message7": "Inflating gobos …",
    "gui.loader.message8": "ᠲᠦᠷᠬᠦ ᠪᠡᠯᠡᠳᠭᠡᠬᠦ",
    "gui.loader.headline": " ᠲᠥᠰᠥᠯ ᠢ ᠠᠴᠢᠶᠠᠯᠠᠬᠤ",
    "gui.cameraModal.cameraModalTitle": "ᠰᠡᠭᠦᠳᠡᠷᠯᠡᠬᠦ",
    "gui.cameraModal.loadingCameraMessage": " ᠺᠠᠮᠧᠷᠠ  ᠶᠢ ᠠᠴᠢᠶᠠᠯᠠᠵᠤ  ᠪᠠᠢᠨ᠎ᠠ",
    "gui.cameraModal.permissionRequest": "ᠪᠢᠳᠠᠨ ᠳᠤ ᠲᠠᠨ ᠤ camera ᠬᠡᠷᠡᠭᠯᠡᠬᠦ ᠡᠷᠬᠡ ᠶᠢᠨ ᠵᠥᠪᠰᠢᠶᠡᠷᠡᠯ ᠴᠢᠬᠤᠯᠠ ᠲᠠᠢ",
    "gui.cameraModal.retakePhoto": " ᠳᠠᠬᠢᠨ ᠰᠡᠭᠦᠳᠡᠷᠯᠡᠬᠦ",
    "gui.cameraModal.save": "ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ",
    "gui.cameraModal.takePhoto": "ᠰᠡᠭᠦᠳᠡᠷᠯᠡᠬᠦ",
    "gui.cameraModal.loadingCaption": "ᠠᠴᠢᠶᠠᠯᠠᠵᠤ  ᠪᠠᠢᠨ᠎ᠠ",
    "gui.cameraModal.enableCameraCaption": " ᠺᠠᠮᠧᠷᠠ  ᠶᠢ ᠡᠬᠢᠯᠡᠵᠦ ᠬᠡᠷᠡᠭᠯᠡᠬᠦ",
    "gui.recordModal.title": "ᠳᠠᠭᠤ ᠰᠢᠩᠭᠡᠭᠡᠬᠦ",
    "gui.recordStep.recordByClickBtn": "ᠳᠣᠣᠷᠠᠬᠢ ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢ ᠳᠣᠶ᠋ᠢᠨ ᠡᠬᠢᠯᠡᠵᠦ ᠳᠠᠭᠤ ᠰᠢᠩᠭᠡᠭᠡᠨ",
    "gui.recordStep.permissionMicrophone": "ᠪᠢᠳᠠ ᠲᠠᠨ ᠤ ᠵᠥᠪᠰᠢᠶᠡᠷᠡᠯ ᠢ ᠣᠯᠣᠭᠰᠠᠨ ᠤ ᠳᠠᠷᠠᠭ",
    "gui.recordStep.record": "ᠰᠢᠩᠭᠡᠭᠡᠬᠦ",
    "gui.recordStep.stopRecord": "ᠰᠢᠩᠭᠡᠭᠡᠬᠦ ᠶᠢ ᠵᠣᠭᠰᠣᠭᠠᠬᠤ",
    "gui.playbackStep.stopMsg": "ᠵᠣᠭᠰᠣᠭᠠᠬᠤ",
    "gui.playbackStep.playMsg": "ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ",
    "gui.playbackStep.loadingMsg": " ᠠᠴᠢᠶᠠᠯᠠᠵᠤ  ᠪᠠᠢᠨ᠎ᠠ",
    "gui.playbackStep.saveMsg": "ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ",
    "gui.playbackStep.reRecordMsg": " ᠳᠠᠬᠢᠨ ᠰᠢᠩᠭᠡᠭᠡᠬᠦ",
    "gui.webglModal.label": "ᠲᠠᠨ ᠤ ᠺᠣᠮᠫᠢᠦ᠋ᠲ᠋ᠧᠷ WebGL ᠢ ᠳᠡᠮᠵᠢᠬᠦ ᠦᠭᠡᠢ  ᠪᠠᠢᠨ᠎ᠠ",
    "gui.webglModal.descriptionMind": "ᠠᠭᠤᠴᠢᠯᠠᠭᠠᠷᠠᠢ᠂ ᠲᠠᠨ ᠤ ᠺᠣᠮᠫᠢᠦ᠋ᠲ᠋ᠧᠷ {webGlLink}. Mind+ ᠨᠢ WebGL ᠶᠢ ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠬᠤ ᠴᠢᠬᠤᠯᠠ ᠲᠠᠢ, {updateGpuDriver}",
    "gui.webglModal.restartMindplus": "重启Mind+",
    "gui.webglModal.clickToRestart": "点此重启",
    "gui.webglModal.webgllink": "WebGL ᠶᠢ ᠳᠡᠮᠵᠢᠬᠦ ᠦᠭᠡᠢ",
    "gui.webglModal.updateGpuDriver": "ᠸᠢᠳᠢᠣ᠋ ᠺᠠᠷᠲ ᠤᠨ ᠬᠥᠳᠡᠯᠭᠡᠬᠦ ᠫᠷᠣᠭ᠍ᠷᠠᠮ ᠢᠶᠠᠨ ᠰᠢᠨᠡᠴᠢᠯᠡᠭᠡᠷᠡᠢ",
    "gui.webglModal.ok": "ᠲᠡᠭᠡ",
    "gui.extension.scratchExampleLib": " ᠵᠢᠱᠢᠶᠡ ᠫᠷᠣᠭ᠍ᠷᠠᠮ",
    "gui.extension.pythonExampleLib": " ᠵᠢᠱᠢᠶᠡ ᠫᠷᠣᠭ᠍ᠷᠠᠮ-Python",
    "gui.extension.arduinoExampleLib": " ᠵᠢᠱᠢᠶᠡ ᠫᠷᠣᠭ᠍ᠷᠠᠮ",
    "gui.prompt.cancel": "ᠪᠤᠯᠢ",
    "gui.prompt.ok": "ᠲᠡᠭᠡ",
    "gui.extension.makeBlock": " ᠪᠡᠶᠡᠴᠢᠯᠡᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ ᠪᠣᠯᠬᠤᠢᠴᠠ ᠬᠡᠰᠡᠭᠲᠦ ᠨᠡᠮᠡᠬᠦ",
    "gui.customProcedures.addAnInputNumberText": "ᠨᠢᠭᠡ ᠫᠠᠷᠠᠮᠸ᠋ᠲᠷ ᠨᠡᠮᠡᠬᠦ",
    "gui.customProcedures.addAnInputBoolean": "ᠨᠢᠭᠡ ᠫᠠᠷᠠᠮᠸ᠋ᠲᠷ ᠨᠡᠮᠡᠬᠦ",
    "gui.customProcedures.numberTextType": "ᠲᠣᠭᠠᠨ ᠤ ᠬᠡᠯᠪᠡᠷᠢ ᠪᠤᠶᠤ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠶᠢᠨ ᠴᠣᠪᠣᠷᠠᠯ",
    "gui.customProcedures.textType": "文本值",
    "gui.customProcedures.numberType": "数字值",
    "gui.customProcedures.booleanType": " ᠪᠦᠦᠯᠢᠨ ᠬᠡᠯᠪᠡᠷᠢ",
    "gui.customProcedures.addALabel": "ᠱᠣᠰᠢᠭ",
    "gui.customProcedures.runWithoutScreenRefresh": "ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ ᠶᠠᠪᠤᠴᠠ ᠳᠤᠮᠳᠠ ᠳᠡᠯᠭᠡᠴᠡ ᠶᠢ ᠰᠢᠨᠡᠴᠢᠯᠡᠬᠦ ᠦᠭᠡᠢ",
    "gui.customProcedures.cancel": "ᠪᠤᠯᠢ",
    "gui.customProcedures.ok": "ᠲᠡᠭᠡ",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "DFRobot  ᠤᠨ ᠦᠢᠯᠡᠳᠪᠦᠷᠢᠯᠡᠭᠰᠡᠨ Vortex ᠰᠤᠷᠭᠠᠨ ᠬᠥᠮᠦᠵᠢᠯ ᠦᠨ ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ ",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "DFRobot  ᠶᠢᠨ  ᠲᠠᠨ ᠰᠦᠧᠧ ᠵᠧ D1 ᠰᠤᠷᠭᠠᠨ ᠬᠥᠮᠦᠵᠢᠯ ᠦᠨ ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ",
    "gui.extension.arduinounoR3.name": "Arduino unoR3",
    "gui.extension.arduinounoR3.description": " ᠺᠠᠶ ᠶᠤᠸᠠᠨ ᠬᠠᠲᠠᠭᠤ ᠬᠡᠷᠡᠭᠰᠡᠯ ᠦᠨ ᠥᠪᠴᠢ ᠲᠣᠨᠣᠭ ᠦᠬᠠᠨ ᠤ ᠡᠭᠦᠳᠦᠭᠴᠢ ᠶᠢᠨ ᠬᠢᠴᠢᠶᠡᠯ ᠲᠡᠶ ᠤᠶᠠᠯᠳᠤᠭᠤᠯᠤᠨ ArduinoUno ᠭᠣᠣᠯ ᠡᠵᠡᠮᠰᠢᠯ ᠬᠠᠪᠲᠠᠰᠤ ᠶᠢᠨ ᠢᠵᠢ ᠲᠣᠨᠣᠭᠯᠠᠭᠠᠰᠤ ᠳᠤ ᠣᠯᠠᠨ ᠵᠦᠢᠯ ᠦᠨ ᠳᠠᠭᠤᠨ ᠭᠡᠷᠡᠯ ᠦᠨ ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨᠲᠦ ᠡᠯᠧᠺᠲᠷᠣᠨ ᠬᠡᠰᠡᠭ ᠪᠠ ᠨᠦᠯᠦᠭᠡᠯᠡᠭᠦᠷ ᠢ ᠪᠠᠭᠲᠠᠭᠠᠳᠠᠭ ᠃ ",
    "gui.extension.maxbot.name": "Max:bot ᠪᠠᠭᠠ ᠲᠡᠷᠭᠡ ",
    "gui.extension.maxbot.description": "microbit ᠲᠠᠪᠴᠠᠩ ᠳᠤ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ ᠮᠠᠰᠢᠨ ᠲᠡᠷᠭᠡ  ",
    "gui.extension.max.name": "Arduino ᠲᠠᠪᠴᠠᠩ ᠳᠤ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ ᠮᠠᠰᠢᠨ ᠬᠥᠮᠦᠨ ᠦ ᠪᠠᠭᠠ ᠲᠡᠷᠭᠡ motor:bitmicrobit ᠴᠠᠬᠢᠯᠭᠠᠨ ᠮᠠᠰᠢᠨ ᠤ ᠲᠤᠰᠬᠠᠶ ᠥᠷᠭᠡᠳᠬᠡᠬᠦ ᠬᠠᠪᠲᠠᠰᠤ",
    "gui.extension.max.description": "Arduino ᠲᠠᠪᠴᠠᠩ ᠳᠤ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ ᠮᠠᠰᠢᠨ ᠪᠠᠭᠠ ᠲᠡᠷᠭᠡ",
    "gui.extension.motorbit.name": "microbit ",
    "gui.extension.motorbit.description": "microbit ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ ᠲᠤ ᠬᠡᠷᠡᠭᠯᠡᠬᠦ ᠥᠷᠭᠡᠳᠬᠡᠯ ᠬᠠᠪᠲᠠᠰᠤ",
    "gui.extension.maqueen.name": "micro:Maquen ( ᠮᠠᠶ ᠺᠦᠨ )",
    "gui.extension.maqueen.description": "microbit ᠲᠠᠪᠴᠠᠩ ᠳᠤ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ  ᠠᠩᠬᠠᠨ ᠤ  ᠪᠢᠴᠢᠬᠠᠨ ᠮᠠᠰᠢᠨ ᠲᠡᠷᠭᠡ  ",
    "gui.extension.spreadEsp32e.name": "firebeetle esp32-e扩展板",
    "gui.extension.spreadEsp32e.description": "firebeetle esp32-e扩展板",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.microbit.description": "micro:bit ᠭᠣᠣᠯ ᠬᠠᠪᠲᠠᠰᠤ ᠡᠵᠡᠮᠰᠢᠬᠦ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ",
    "gui.extension.calliope.description": "把作品连接到实体世界。",
    "gui.extension.calliope.name": "CALLIOPE mini",
    "gui.extension.spread.name": "micro:bit & mPython expansion board",
    "gui.extension.spreadboson.name": "micro:bit&掌控扩展板",
    "gui.extension.microTouch.name": "TouchKey",
    "gui.extension.microNaturalScience.name": "自然主题扩展板",
    "gui.extension.microNaturalScienceV2.name": "自然主题扩展板V2.0",
    "gui.extension.microNaturalScienceV2.description": "集成丰富自然环境相关传感器的microbit学习产品。",
    "gui.extension.microNaturalScienceV2.request": "请求数据",
    "gui.extension.microNaturalScienceV2.readSoilValue": "[PIN] 引脚获取土壤湿度",
    "gui.extension.microNaturalScienceV2.readCO2TVOC": "[TYPE]",
    "gui.extension.microNaturalScienceV2.setBme280": "设置TVOC和CO2的基线[NUM]值",
    "gui.extension.microNaturalScienceV2.OLEDShowString": "OLED从 [NUM1] 到 [NUM2] 列在第 [NUM3] 行显示字符串 [STR]",
    "gui.extension.microNaturalScienceV2.OLEDShowNumber": "OLED从 [NUM1] 到 [NUM2] 列在第 [NUM3] 行显示数字 [STR]",
    "gui.extension.microNaturalScienceV2.OLEDClearXY": "清除OLED从 [NUM1] 到 [NUM2] 列在第 [NUM3] 行",
    "gui.extension.microNaturalScienceV2.OLEDClear": "清除OLED第 [NUM3] 行",
    "gui.extension.microNaturalScienceV2.MotorRun": "控制电机方向 [DIR] 速度[SPEED]",
    "gui.extension.microNaturalScienceV2.MotorStop": "控制停止",
    "gui.extension.microNaturalScienceV2.RGBSetBackground": "设置RGB亮度为 [NUM]",
    "gui.extension.microNaturalScienceV2.RGBShowColorFromWith": "RGB显示颜色 [COLOR]",
    "gui.extension.microNaturalScienceV2.RGBShowNumColorFromWith": "RGB灯[NUM]显示颜色 [COLOR]",
    "gui.extension.microNaturalScienceV2.RGBRange": "RGB范围从 [NUM1] 到 [NUM2]",
    "gui.extension.microNaturalScienceV2.RGBShowMulticolor": "设置RGB显示彩虹色从 [NUM1] 到 [NUM2]",
    "gui.extension.microNaturalScienceV2.RGBclear": "关闭全部RGB",
    "gui.extension.microNaturalScienceV2.IFTTTConnect": "IFTTT 配置 事件: [EVENT] 密码: [PASSWORD]",
    "gui.extension.microNaturalScienceV2.WIFIConnect": "Wi-Fi 配置 名称: [SSID] 密码: [PASSWORD] 启动连接",
    "gui.extension.microNaturalScienceV2.IFTTTSend": "IFTTT 发送 值1: [VAL1] 值2: [VAL2] 值3: [VAL3]",
    "gui.extension.microNaturalScienceV2.ThingSpeakConfigure": "ThingSpeak 配置 密钥: [SSID]",
    "gui.extension.microNaturalScienceV2.ThingSpeakSend": "ThingSpeak 发送 值1:[VALUE1]值2:[VALUE2]值3:[VALUE3]值4:[VALUE4]值5:[VALUE5]值6:[VALUE6]值7:[VALUE7]值8:[VALUE8]",
    "gui.extension.microNaturalScienceV2.ThingSpeakPrepareSend": "ThingSpeak 预发送 值[VALUE1] ： [VALUE2]",
    "gui.extension.microNaturalScienceV2.ThingSpeakRealSend": "ThingSpeak 发送",
    "gui.extension.microNaturalScienceV2.obloq_initialParameter": "MQTT 初始化 [PARAMETER]",
    "gui.extension.microNaturalScienceV2.sendmsgToCloudPlatform": "发送消息 [MSG] 到 [TOPIC]",
    "gui.extension.microNaturalScienceV2.addToCloudPlatform": "新增订阅 [TOPIC] : [MSG]",
    "gui.extension.microTouch.description": "Integrated 4×4 keyboard and motor servo drive expansion board",
    "gui.extension.microNaturalScience.description": "集成多种环境传感器的micro:bit扩展板",
    "gui.extension.microIoT.name": "micro:IoT",
    "gui.extension.microIoT.description": "Integrated display and Internet of things micro:bit extension board",
    "gui.extension.microIoTCloud.name": "物联网主题扩展板",
    "gui.extension.microIoTCloud.description": "支持插WiFi卡联网的micro:bit扩展板，为物联网课堂教学提供了出色的解决方案",
    "gui.extension.spreadmaqueen.name": "麦昆扩展板",
    "gui.extension.spread.description": "I/O expansion board with micro:bit and control board (with motor driver)",
    "gui.extension.spreadboson.description": "使用micro:bit和掌控板搭配的I/O扩展板（带电机驱动）",
    "gui.extension.spreadmaqueen.description": "An entry-level minicar based on the control version",
    "gui.extension.maqueenPlus.name": "Maqueen Plus",
    "gui.extension.maqueenPlus.description": "The advanced educational robot that supports both micro:bit and mPython",
    "gui.extension.maqueenPlusV2.name": "麦昆Plus V2",
    "gui.extension.maqueenPlusV2.description": "支持micro:bit的新版麦昆plus款教育机器人",
    "gui.extension.pinpongBread.name": "PinPong扩展板",
    "gui.extension.pinpongBread.description": "在ArduinoUNO上进行Python学习的扩展板",
    "gui.extension.iot.name": "ᠪᠣᠳᠠᠰ ᠤᠨ ᠰᠦᠯᠵᠢᠶᠡᠨ ᠦ ᠰᠡᠳᠦᠪᠲᠦ ᠥᠷᠭᠡᠳᠭᠡᠯ ᠬᠠᠪᠲᠠᠰᠤ",
    "gui.extension.iot.description": "ᠪᠣᠳᠠᠰ ᠤᠨ ᠰᠦᠯᠵᠢᠶᠡᠨ ᠳᠦ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ ᠥᠷᠭᠡᠳᠭᠡᠯ ᠬᠠᠪᠲᠠᠰᠤ",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "Leonardoᠭᠣᠣᠯ ᠬᠠᠪᠲᠠᠰᠤ ᠡᠵᠡᠮᠰᠢᠬᠦ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "Arduino Uno ᠭᠣᠣᠯ ᠬᠠᠪᠲᠠᠰᠤ ᠡᠵᠡᠮᠰᠢᠬᠦ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "Arduino Nano ᠭᠣᠣᠯ ᠬᠠᠪᠲᠠᠰᠤ ᠡᠵᠡᠮᠰᠢᠬᠦ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ",
    "gui.extension.mpython.name": "掌控板",
    "gui.extension.mpython.description": "ESP32  ᠲᠤ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ ᠡᠵᠡᠮᠰᠢᠯ ᠬᠠᠪᠲᠠᠰᠤ ",
    "gui.extension.telloesp32.name": "RoboMaster TT(ESP32)",
    "gui.extension.telloesp32.description": "搭载大疆顶尖飞控算法的教育无人机，极强的安全性与稳定性",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "Mega2560 ᠭᠣᠣᠯ ᠬᠠᠪᠲᠠᠰᠤ ᠡᠵᠡᠮᠰᠢᠬᠦ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ",
    "gui.extension.firebeetleesp32.name": "FireBeetle ESP32",
    "gui.extension.firebeetleesp32e.name": "FireBeetle ESP32-E",
    "gui.extension.firebeetleesp32.description": "FireBeetle ESP32 主控板控制的设备",
    "gui.extension.dfr0299.name": "DFPlayer MP3 ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.dfr0299.description": "MP3  ᠠᠪᠢᠶᠠ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.ser0006.name": " ᠵᠠᠯᠠᠭᠤᠷ ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.ser0006.description": " ᠵᠠᠯᠠᠭᠤᠷ ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.dfr0523.name": "ᠭᠦᠷᠪᠡᠯᠵᠡᠬᠦ ᠬᠥᠭᠡᠷᠭᠡ ",
    "gui.extension.dfr0523.description": "ᠭᠦᠷᠪᠡᠯᠵᠡᠬᠦ ᠬᠥᠭᠡᠷᠭᠡ ᠬᠠᠷᠢᠮᠠᠬᠠᠶ ᠬᠥᠭᠡᠷᠭᠡ ᠶᠢ ᠡᠭᠡᠯᠵᠢᠯᠡᠨ ᠰᠢᠬᠠᠬᠤ ᠪᠠ ᠰᠤᠯᠠᠯᠠᠬᠤ ᠪᠠᠷ ᠳᠠᠮᠵᠢᠨ  ᠤᠷᠤᠰᠤᠩᠭᠢ ᠪᠡᠶᠡ ᠶᠢ ᠬᠦᠷᠭᠡᠨᠡ ",
    "gui.extension.dfr0017.name": "ᠲᠠᠲᠠᠭᠳᠠᠭᠤᠷ ",
    "gui.extension.dfr0017.description": "ᠶᠡᠬᠡ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠦᠶᠦᠳᠡᠯ ᠦᠨ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ᠶᠢ ᠡᠵᠡᠮᠰᠢᠵᠦ ᠂ ᠲᠣᠭᠠ ᠶᠢ ᠨᠢ ᠬᠢᠵᠠᠭᠠᠷᠯᠠᠵᠤ ᠪᠣᠯᠤᠨᠠ ",
    "gui.extension.steppermotor.name": "ᠦᠰᠦᠷᠦᠭᠡ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠮᠠᠰᠢᠨ ",
    "gui.extension.steppermotor.description": "ᠦᠰᠦᠷᠦᠭᠡ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠮᠠᠰᠢᠨ ᠯᠤᠭᠰᠢᠯ ᠤᠨ ᠡᠵᠡᠮᠳᠡᠯ ᠢᠶᠡᠷ ᠳᠠᠮᠵᠢᠨ ᠣᠨᠣᠪᠴᠢᠲᠠᠢ ᠣᠷᠣᠨ ᠪᠠᠢᠷᠢ ᠶᠢᠨ ᠡᠵᠡᠮᠰᠢᠯ ᠢ ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠵᠦ ᠳᠡᠶᠢᠯᠦᠨᠡ ",
    "gui.extension.dfr0534.name": "ᠤᠭᠰᠠᠷᠠᠭᠠ ᠵᠠᠯᠭᠠᠭᠤᠷᠲᠤ MP3 ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.dfr0534.description": "ᠤᠭᠰᠠᠷᠠᠭᠠ ᠵᠠᠯᠭᠠᠭᠤᠷᠲᠤ MP3 ᠠᠪᠢᠶᠠᠨ ᠤ  ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.servo360.name": "360 ᠭᠷᠠᠳᠦ᠋ᠰ ᠦᠨ ᠪᠢᠴᠢᠯ ᠵᠠᠯᠠᠭᠤᠷ ᠮᠠᠰᠢᠨ",
    "gui.extension.servo360.description": "ᠵᠥᠪᠬᠡᠨ ᠬᠤᠷᠳᠤᠴᠠ ᠪᠣᠯᠤᠨ ᠴᠢᠭᠯᠡᠯ ᠢ ᠶᠠᠮᠠᠷ ᠨᠢᠭᠡᠨ ᠥᠨᠴᠥᠭ ᠲᠦ ᠵᠣᠭᠰᠣᠵᠤ ᠲᠠᠢᠯᠬᠤ ᠦᠭᠡᠢ ᠵᠠᠯᠠᠭᠤᠷ ᠣᠨ ᠮᠠᠰᠢᠨ ",
    "gui.extension.tel0118.name": "OBLOQ ᠪᠣᠳᠠᠰ ᠤᠨ ᠨᠧᠲ᠋ ᠦᠨ ᠬᠡᠰᠡᠭ ",
    "gui.extension.tel0118.description": "ᠡᠳ᠋ ᠪᠠᠷᠠᠭᠠ ᠶᠢᠨ ᠮᠡᠳᠡᠭᠡ ᠵᠠᠩᠭᠢ ᠶᠢ ᠬᠤᠷᠢᠶᠠᠬᠤ ᠪᠠ ᠢᠯᠡᠭᠡᠬᠦ ",
    "gui.extension.dfr0095.name": "ᠤᠯᠠᠭᠠᠨ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠰᠠᠴᠤᠷᠠᠯ ᠣᠨ ᠬᠡᠰᠡᠭ ",
    "gui.extension.dfr0095.description": "ᠤᠯᠠᠭᠠᠨ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠰᠠᠴᠤᠷᠠᠯ ᠣᠨ ᠬᠡᠰᠡᠭ",
    "gui.extension.dfr0094.name": "ᠤᠯᠠᠭᠠᠨ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠬᠤᠷᠢᠶᠠᠬᠤ ᠬᠡᠰᠡᠭ ",
    "gui.extension.dfr0094.description": "ᠨᠢᠭᠡ 38KHz ᠬᠡᠲᠦ ᠤᠯᠠᠭᠠᠨ ᠰᠠᠴᠤᠷᠠᠭ ᠣᠨ ᠬᠦᠯᠢᠶᠡᠨ ᠠᠪᠬᠤ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.midi.name": "MIDI",
    "gui.extension.midi.description": "MIDI播放器",
    "gui.extension.radio.name": "无线广播",
    "gui.extension.radio.description": "多个主板之间进行无线广播通讯",
    "gui.extension.tel0094.name": "GPS ᠳᠣᠬᠢᠶᠠ ᠬᠤᠷᠢᠶᠠᠬᠤ ᠬᠡᠰᠡᠭ ",
    "gui.extension.tel0094.description": "GPS ᠳᠣᠬᠢᠶᠠ ᠬᠤᠷᠢᠶᠠᠬᠤ ᠬᠡᠰᠡᠭ ",
    "gui.extension.tel0026.name": "ᠬᠥᠬᠡ ᠰᠢᠳᠦᠨ ᠬᠡᠰᠡᠭ ",
    "gui.extension.tel0026.description": "ᠬᠥᠬᠡ ᠰᠢᠳᠦᠲᠦ ᠤᠭᠯᠤᠷᠭᠠ ᠬᠡᠰᠡᠭ ",
    "gui.extension.dfr0486.name": "OLED-12864 ᠦᠵᠡᠭᠦᠯᠬᠦ ᠳᠡᠯᠭᠡᠴᠡ",
    "gui.extension.dfr0647.name": "OLED-12832显示屏",
    "gui.extension.dfr0486.description": "ᠨᠢᠭᠡ ᠵᠦᠢᠯ ᠦᠨ ᠠᠷᠤ ᠭᠡᠷᠡᠯ ᠡᠭᠦᠰᠭᠡᠬᠦ ᠴᠢᠬᠤᠯᠠ ᠦᠭᠡᠢ ᠦᠵᠡᠭᠦᠯᠬᠦ ᠬᠡᠰᠡᠭ ( I2C ᠠᠭᠤᠯᠵᠠᠷ ) ",
    "gui.extension.dfr0647.description": "小体积显示屏，可用于智能穿戴设备的显示器",
    "gui.extension.fit0352.name": "WS2812RGB ᠳ᠋ᠧᠩ",
    "gui.extension.fit0352.description": "WS2812 ᠳᠤ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ ᠳ᠋ᠧᠩ ᠦᠨ ᠪᠦᠰᠡ ᠵᠢᠴᠢ ᠬᠠᠮᠢᠶᠠ ᠪᠦᠬᠦᠢ ᠬᠡᠰᠡᠭ ᠢ ᠡᠵᠡᠮᠳᠡᠵᠦ ᠪᠣᠯᠤᠨᠠ",
    "gui.extension.dfr0063.name": "LCD 1602 ᠬᠡᠰᠡᠭ ( I2C ᠠᠭᠤᠯᠵᠠᠷ )",
    "gui.extension.dfr0063.description": "LCD 1602 ᠰᠢᠩᠭᠡᠨ ᠲᠠᠯᠠᠰᠲᠤ ᠬᠡᠰᠡᠭ ᠂ 2 ᠮᠥᠷ ᠢᠯᠡᠷᠡᠨᠡ ᠂ ᠮᠥᠷ ᠪᠦᠷᠢ 16 ᠦᠰᠦᠭ ᠲᠡᠮᠲᠡᠭ",
    "gui.extension.dfr0021.name": "ᠲᠣᠭᠠᠨ LED ᠭᠡᠷᠡᠯᠲᠦᠭᠦᠯᠬᠦ ᠬᠡᠰᠡᠭ ",
    "gui.extension.dfr0021.description": "ᠴᠠᠭᠠᠨ ᠂ ᠤᠯᠠᠭᠠᠨ ᠂ ᠨᠣᠭᠣᠭᠠᠨ ᠂ ᠬᠥᠬᠡ LED  ᠶᠢᠨ ᠬᠡᠰᠡᠭ ᠢ ᠪᠠᠭᠲᠠᠭᠠᠨᠠ ",
    "gui.extension.tm1650.name": "TM 1650 ᠳᠥᠷᠪᠡᠨ ᠣᠷᠣᠨᠲᠤ ᠲᠣᠭᠠᠨ ᠬᠣᠭᠣᠯᠠᠶ",
    "gui.extension.tm1650.description": "ᠳᠥᠷᠪᠡᠨ ᠣᠷᠣᠨᠲᠤ ᠲᠣᠭᠠᠨ ᠬᠣᠭᠣᠯᠠᠶ ",
    "gui.extension.dfr0646.name": "TM1650八位数码管",
    "gui.extension.dfr0646.description": "显示模块，I2C接口，可用于计时器、仪表控制等",
    "gui.extension.matrix8_8.name": "MAX 7219 8 x 8 ᠴᠡᠭ ᠣᠨ ᠵᠢᠭᠰᠠᠭᠠᠯ",
    "gui.extension.matrix8_8.description": "8x8 ᠴᠡᠭ ᠵᠢᠭᠰᠠᠭᠠᠯ ᠤᠨ ᠬᠡᠰᠡᠭ",
    "gui.extension.dfr0522.name": "8×16 RGB LED ᠵᠢᠭᠰᠠᠭᠠᠯ ᠤᠨ ᠰᠡᠳᠬᠢᠯ ᠦᠨ ᠢᠯᠡᠳᠬᠡᠬᠦ ᠬᠠᠪᠲᠠᠰᠤ",
    "gui.extension.dfr0522.description": "8×16 RGB LED ᠵᠢᠭᠰᠠᠭᠠᠯ ᠤᠨ ᠰᠡᠳᠬᠢᠯ ᠦᠨ ᠢᠯᠡᠳᠬᠡᠬᠦ ᠬᠠᠪᠲᠠᠰᠤ",
    "gui.extension.music.name": " ᠳᠠᠭᠤᠤ ᠬᠥᠭᠵᠢᠮ ",
    "gui.extension.music.description": "ᠬᠥᠭᠵᠢᠮ ᠬᠥᠭᠵᠢᠮᠳᠡᠵᠦ ᠬᠠᠷᠠᠩᠭᠠ ᠬᠡᠩᠭᠡᠷᠭᠡ ᠳᠡᠯᠡᠳᠦᠨᠡ ᠃ ",
    "gui.extension.pen.name": "ᠵᠢᠷᠤᠭ ᠤᠨ ᠪᠢᠷ ",
    "gui.extension.pen.description": "ᠳᠦᠷᠢ ᠶᠢ ᠵᠢᠷᠤᠵᠤ ᠪᠦᠲᠦᠭᠡᠨᠡ ᠃ ",
    "gui.extension.video-sensing.name": "ᠬᠠᠷᠠᠭᠠᠨ ᠳᠠᠪᠲᠠᠮᠵᠢ ᠶᠢᠨ ᠲᠠᠩᠨᠠᠯᠲᠠ ᠬᠥᠳᠡᠯᠦᠯ ",
    "gui.extension.video-sensing.description": "ᠭᠡᠷᠡᠯ ᠵᠢᠷᠤᠭ ᠤᠨ ᠮᠠᠰᠢᠨ ᠢᠶᠠᠷ ᠰᠢᠯᠭᠠᠵᠤ ᠣᠶᠢᠵᠢᠨ ᠃ ",
    "gui.extension.google-translate.name": "ᠬᠥ᠋ ᠭᠧ ᠣᠷᠴᠢᠭᠤᠯᠤᠭᠴᠢ ",
    "gui.extension.google-translate.description": "ᠦᠰᠦᠭ ᠪᠢᠴᠢᠭ ᠢ ᠣᠯᠠᠨ ᠵᠦᠢᠯ ᠦᠨ ᠬᠡᠯᠡ ᠪᠡᠷ ᠣᠷᠴᠢᠭᠤᠯᠤᠨᠠ ᠃ ",
    "gui.extension.bd-translate.name": "百度翻译",
    "gui.extension.bd-translate.description": "使用百度翻译服务将文字翻译成其他语言。",
    "gui.extension.text-speech.name": "ᠥᠰᠥᠭ ᠦᠨ ᠤᠩᠰᠢᠯᠭᠠ ",
    "gui.extension.text-speech.description": "ᠥᠪᠡᠷ ᠦᠨ ᠰᠡᠳᠦᠪ ᠢᠶᠡᠨ ᠦᠭᠡ ᠶᠠᠷᠢ",
    "gui.extension.softSerialport.name": "ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ",
    "gui.extension.softSerialport.description": "I/O  ᠵᠠᠯᠭᠠᠭᠤᠷ ᠢᠶᠠᠷ ᠬᠡᠯᠬᠢᠶᠡᠯᠡᠨ ᠬᠠᠷᠢᠯᠴᠠᠬᠤ ᠴᠢᠳᠠᠪᠤᠷᠢ ᠶᠢ ᠬᠡᠷᠡᠭᠯᠡᠬᠦ ",
    "gui.extension.IICScan.name": "I2C ᠬᠠᠶ᠋ᠢᠭ ᠤᠨ ᠰᠢᠷᠪᠢᠯᠲᠡ",
    "gui.extension.IICScan.description": "ᠰᠢᠷᠪᠢᠯᠲᠡ ᠣᠳᠣᠬᠠᠨ ᠳᠤ I2C ᠵᠠᠯᠭᠠᠭᠠᠰᠤ ᠳᠡᠭᠡᠷᠡᠬᠢ ᠪᠣᠢ ᠪᠦᠬᠦᠢ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ᠶᠢᠨ ᠬᠠᠶ᠋ᠢᠭ ᠢ ᠣᠯᠪᠠ ",
    "gui.extension.bluetooth.name": "蓝牙",
    "gui.extension.bluetooth.description": "Bluetooth ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ",
    "gui.extension.goble.name": "GoBle ( e ) ",
    "gui.extension.goble.description": "GoBle (ᠲᠠᠨ ᠤ ᠵᠠᠮ ᠢᠶᠠᠷ ᠶᠠᠪᠤᠬᠤ )A P P ᠲᠠᠢ ᠵᠣᠬᠢᠴᠠᠭᠤᠯᠤᠨ ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠂ ᠭᠠᠷ ᠤᠲᠠᠰᠤᠨ ᠤ A P P ᠡᠵᠡᠮᠰᠢᠯ ᠦᠨ  ᠭᠤᠤᠯ ᠡᠵᠡᠮᠰᠢᠯ ᠢ ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠵᠦ ᠪᠣᠯᠤᠨᠠ ",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "将数据保存到eeprom",
    "gui.extension.interrupt.name": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠳᠠᠰᠤᠷᠠᠬᠤ",
    "gui.extension.interrupt.description": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠳᠠᠰᠤᠷᠠᠬᠤ",
    "gui.extension.mpyfile.name": "板载文件",
    "gui.extension.mpyfile.description": "读写板载文件，可以存储数据和导出",
    "gui.extension.sdcard.name": "SD读卡器模块",
    "gui.extension.sdcard.description": "MicroSD卡 读卡器模块",
    "gui.extension.multithread.name": " ᠤᠯᠠᠨ ᠲᠷᠢᠳ᠋",
    "gui.extension.multithread.description": " ᠤᠯᠠᠨ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠲᠠᠢ ᠵᠡᠷᠭᠡ ᠪᠡᠷ ᠠᠵᠢᠯᠯᠠᠬᠤ ᠶᠢ ᠵᠥᠪᠰᠢᠶᠡᠷᠡᠬᠦ ",
    "gui.extension.speech.name": "Speech ",
    "gui.extension.speech.description": "ᠴᠢᠨᠦ ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ ᠲᠠᠢ ᠶᠠᠷᠢᠯᠴᠠᠶᠠ ᠃ ",
    "gui.extension.sen0001.name": "ᠬᠡᠲᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠵᠠᠶ ᠬᠡᠮᠵᠢᠯᠲᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.bos0001.name": "旋钮模块",
    "gui.extension.bos0002.name": "按钮模块",
    "gui.extension.bos0003.name": "自锁开关",
    "gui.extension.bos0004.name": "环境光传感器",
    "gui.extension.bos0006.name": "水分传感器",
    "gui.extension.bos0007.name": "火焰传感器",
    "gui.extension.bos0008.name": "触摸传感器",
    "gui.extension.bos0009.name": "声音传感器",
    "gui.extension.bos0010.name": "智能灰度传感器",
    "gui.extension.bos0012.name": "电导开关",
    "gui.extension.bos0013.name": "运动传感器",
    "gui.extension.bos0038.name": "土壤湿度传感器",
    "gui.extension.bos0045.name": "超声波距离传感器",
    "gui.extension.bos0016.name": "高亮LED模块",
    "gui.extension.bos0017.name": "LED模块",
    "gui.extension.bos0019.name": "彩色灯带",
    "gui.extension.bos0021.name": "风扇模块",
    "gui.extension.bos0022.name": "录音模块",
    "gui.extension.bos0001.description": "模拟传感器，可检测旋转位置",
    "gui.extension.bos0002.description": "按下输出高电平，松开输出低电平",
    "gui.extension.bos0003.description": "按下状态输出高电平，弹起状态输出低电平",
    "gui.extension.bos0004.description": "检测环境光强度",
    "gui.extension.bos0006.description": "检测雨水、雾水，不可整体浸入水中",
    "gui.extension.bos0007.description": "探测火源或波长760~1100nm的光源",
    "gui.extension.bos0008.description": "触摸开关，可感应人体，金属等",
    "gui.extension.bos0009.description": "检测声音强度",
    "gui.extension.bos0010.description": "检测不同颜色产生的信号",
    "gui.extension.bos0012.description": "检测物体是否导体",
    "gui.extension.bos0013.description": "检测运动的人或动物身上发出的红外线",
    "gui.extension.bos0038.description": "检测土壤水分，水分越少输出值越小",
    "gui.extension.bos0045.description": "模拟距离传感器，检测范围2cm~1m",
    "gui.extension.bos0016.description": "白色发光模块",
    "gui.extension.bos0017.description": "发光二极管，能发出对应灯珠颜色的灯光",
    "gui.extension.bos0019.description": "七彩灯带，灯带长3米，颜色不能改变",
    "gui.extension.bos0021.description": "驱动扇叶旋转的模块",
    "gui.extension.bos0022.description": "可以录制一段10s的声音和播放录音",
    "gui.extension.sen0001.description": "ᠪᠠᠭᠲᠠᠭᠠᠨ ᠪᠠᠭᠲᠠᠭᠠᠬᠤ u r m ᠰᠢᠷᠢᠰ ᠵᠢᠴᠢ SR - 04 ᠬᠡᠳᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠃",
    "gui.extension.dfr0023.name": "LM35 ᠱᠤᠭᠤᠮᠤᠨ ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.dfr0023.description": "LM35  ᠳᠤ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ ᠬᠠᠭᠠᠰ ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠭᠴᠢ ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.dhtTHSensor.name": "DHT11/22 ᠳᠤᠯᠠᠭᠠᠴᠠ ᠴᠢᠭᠢᠭᠲᠦᠴᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.dhtTHSensor.description": "DHT11/22 ᠲᠣᠭᠠᠨ ᠴᠢᠭᠢᠭᠲᠦᠴᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.dsTSensor.name": "DS18B20 ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.dsTSensor.description": "DS18B20 ᠲᠣᠭᠠᠨ ᠤ ᠳᠤᠯᠠᠭᠠᠨ ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠭᠤᠷ ᠂ ᠣᠷᠴᠢᠨ ᠲᠣᠭᠣᠷᠢᠨ ᠤ ᠳᠤᠯᠠᠭᠠᠴᠠ ᠳᠤ ᠲᠣᠭᠲᠠᠭᠰᠠᠨ ᠬᠡᠮᠵᠢᠶᠡᠨ ᠦ ᠪᠠᠢᠴᠠᠭᠠᠯᠲᠠ ᠬᠢᠵᠦ ᠪᠣᠯᠤᠨᠠ",
    "gui.extension.sen0203.name": "ᠵᠢᠷᠦᠬᠡᠨ ᠳᠠᠪᠲᠠᠮᠵᠢ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.sen0203.description": "ᠬᠡᠲᠦ ᠵᠢᠵᠢᠭ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢᠨ ᠵᠢᠷᠦᠬᠡᠨ ᠳᠠᠪᠲᠠᠮᠵᠢ ᠶᠢᠨ ᠨᠦᠯᠦᠭᠡᠯᠡᠭᠦᠷ ᠂ ᠬᠠᠪᠲᠠᠰᠤᠨ ᠳᠡᠭᠡᠷᠡᠬᠢ ᠨᠡᠭᠡᠭᠡᠯᠭᠡ ᠪᠡᠷ ᠳᠠᠮᠵᠢᠨ ᠳᠥᠷᠪᠡᠯᠵᠢᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠪᠠ ᠰᠤᠳᠠᠯ ᠤᠨ ᠯᠤᠭᠰᠢᠯᠲᠠ ᠶᠢᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠭᠡᠬᠦ ᠬᠣᠶᠠᠷ ᠵᠦᠢᠯ ᠦᠨ ᠳᠣᠬᠢᠶᠠ ᠭᠠᠷᠭᠠᠬᠤ ᠵᠠᠭᠪᠤᠷ ᠢ ᠰᠣᠯᠢᠬᠤ ",
    "gui.extension.sen0177.name": "PM 2.5 ᠯᠠᠶᠢᠰᠧᠷ ᠲᠣᠭᠣᠰᠣᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.sen0177.description": "PM1 ᠂ PM2.5 ᠂ PM10  ᠶᠢ ᠬᠡᠮᠵᠢᠵᠦ ᠪᠣᠯᠤᠨᠠ ᠃ ᠤᠭᠰᠠᠷᠠᠭᠠ ᠠᠮᠠᠰᠠᠷᠲᠤ ᠳᠠᠮᠵᠢᠯᠭᠠᠨ ᠳ᠋ᠠᠶᠢᠲ᠋ᠠ",
    "gui.extension.sen0014.name": "ᠤᠯᠠᠭᠠᠨ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠵᠠᠶ ᠬᠡᠮᠵᠢᠯᠲᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.sen0014.description": "GP2Y0A21 ᠴᠢᠫ 80cm  ᠳᠤ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ ᠬᠠᠶᠢᠨ ᠬᠡᠮᠵᠢᠬᠦ ᠵᠠᠶ",
    "gui.extension.sen0204.name": "ᠰᠢᠩᠭᠡᠨ ᠲᠦᠪᠰᠢᠨ ᠦ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.sen0204.description": "ᠪᠢᠲᠡᠭᠦᠦ ᠰᠠᠪᠠᠨ ᠳᠠᠬᠢ ᠰᠢᠩᠭᠡᠨ ᠦ ᠪᠠᠶᠢᠷᠢ ᠶᠢᠨ ᠬᠦᠷᠦᠯᠴᠡᠯ ᠪᠤᠰᠤ ᠪᠠᠢᠴᠠᠭᠠᠯᠲᠠ ",
    "gui.extension.sen0160.name": "Hx711重量传感器",
    "gui.extension.sen0160.description": "测量重量（小于1kg）",
    "gui.extension.sen0161.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ pH ᠬᠡᠮᠵᠢᠭᠦᠷ ",
    "gui.extension.sen0161.description": "ᠠᠭᠤᠰᠤᠮᠠᠯ ᠤᠨpH ( ᠬᠦᠴᠢᠯ ᠱᠦᠯᠲᠦ ᠶᠢᠨ ᠬᠡᠮᠵᠢᠶᠡ )  ᠶᠢ ᠬᠡᠮᠵᠢᠬᠦ ",
    "gui.extension.sen0161-v2.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ pH ᠬᠡᠮᠵᠢᠭᠦᠷ(V2)",
    "gui.extension.sen0161-v2.description": "ᠠᠭᠤᠰᠤᠮᠠᠯ ᠤᠨpH ( ᠬᠦᠴᠢᠯ ᠱᠦᠯᠲᠦ ᠶᠢᠨ ᠬᠡᠮᠵᠢᠶᠡ )  ᠶᠢ ᠬᠡᠮᠵᠢᠬᠦ ",
    "gui.extension.sen0244.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ TDS ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.sen0244.description": "ᠠᠭᠤᠰᠤᠮᠠᠯ ᠤᠨ ᠳᠤᠮᠳᠠ TDS ( ᠶᠡᠷᠦᠩᠬᠡᠢ ᠠᠭᠤᠰᠬᠠᠭᠳᠠᠭᠰᠠᠨ ᠬᠠᠲᠠᠭᠤ ᠪᠡᠶᠡᠲᠦ )  ᠤᠨ ᠠᠭᠤᠯᠤᠭᠳᠠᠴᠠ ᠶᠢ ᠬᠡᠮᠵᠢᠪᠡᠯ ᠂ ᠤᠰᠤᠨ ᠤ ᠴᠡᠪᠡᠷ ᠣᠨ ᠬᠡᠮᠵᠢᠶᠡ ᠶᠢ ᠲᠤᠰᠬᠠᠵᠤ ᠂ ᠠᠮᠢᠳᠤᠷᠠᠯ ᠤᠨ ᠤᠰᠤ ᠂ ᠤᠰᠤᠨ ᠤ ᠲᠠᠬᠤᠯ ᠳᠤ ᠬᠡᠷᠡᠭᠯᠡᠨᠡ ",
    "gui.extension.sen0165.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ ORP ᠬᠡᠮᠵᠢᠭᠦᠷ ",
    "gui.extension.sen0165.description": "ᠠᠭᠤᠰᠤᠮᠠᠯ ᠤᠨ ORP ( ᠢᠰᠦᠯᠳᠦᠭᠦᠯᠦᠨ ᠠᠩᠭᠢᠵᠢᠷᠠᠭᠤᠯᠬᠤ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠪᠠᠢᠷᠢ )  ᠶᠢ ᠬᠡᠮᠵᠢᠨᠡ ᠃ ᠢᠰᠦᠯᠳᠦᠬᠦ ᠠᠩᠭᠢᠵᠢᠷᠠᠬᠤ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠪᠠᠢᠷᠢ ᠤᠯᠠᠮ ᠥᠨᠳᠥᠷ ᠪᠣᠯ ᠢᠰᠦᠯᠳᠦᠬᠦ ᠴᠢᠨᠠᠷ ᠤᠯᠠᠮ ᠴᠢᠩᠭᠠ ",
    "gui.extension.sen0237.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ DO ᠬᠡᠮᠵᠢᠭᠦᠷ ",
    "gui.extension.sen0237.description": "ᠠᠭᠤᠰᠤᠮᠠᠯ ᠤᠨ DO ( ᠬᠦᠴᠢᠯᠲᠥ᠋ᠷᠥᠭᠴᠢ ᠶᠢᠨ ᠠᠭᠤᠯᠤᠭᠳᠠᠴᠠ )  ᠶᠢ ᠬᠡᠮᠵᠢᠵᠦ ᠂ ᠰᠢᠩᠭᠡᠨ ᠪᠡᠶᠡᠲᠦ ᠳᠣᠲᠣᠷᠠᠬᠢ ᠬᠦᠴᠢᠯᠲᠥ᠋ᠷᠥᠭᠴᠢ ᠠᠭᠤᠰᠬᠠᠬᠤ ᠥᠳᠬᠡᠴᠡ ᠶᠢ ᠲᠤᠰᠬᠠᠨᠠ ",
    "gui.extension.dfr0300-H.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ EC ᠬᠡᠮᠵᠢᠭᠦᠷ ",
    "gui.extension.dfr0300-H.description": "ᠠᠭᠤᠰᠤᠮᠠᠯ ᠢ ᠬᠡᠮᠵᠢᠬᠦ EC ( ᠴᠠᠬᠢᠯᠭᠠᠨ ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠮᠵᠢ ) ᠃ ᠰᠢᠩᠭᠡᠨ ᠪᠡᠶᠡᠲᠦ ᠳᠣᠲᠣᠷᠠᠬᠢ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠵᠠᠳᠠᠯᠤᠭᠳᠠᠯ ᠤᠨ ᠥᠳᠬᠡᠴᠡ ᠶᠢ ᠲᠤᠰᠬᠠᠬᠤ ",
    "gui.extension.dfr0300.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ EC ᠬᠡᠮᠵᠢᠭᠦᠷ ",
    "gui.extension.dfr0300.description": "ᠠᠭᠤᠰᠤᠮᠠᠯ ᠢ ᠬᠡᠮᠵᠢᠬᠦ EC ( ᠴᠠᠬᠢᠯᠭᠠᠨ ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠮᠵᠢ ) ᠃ ᠰᠢᠩᠭᠡᠨ ᠪᠡᠶᠡᠲᠦ ᠳᠣᠲᠣᠷᠠᠬᠢ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠵᠠᠳᠠᠯᠤᠭᠳᠠᠯ ᠤᠨ ᠥᠳᠬᠡᠴᠡ ᠶᠢ ᠲᠤᠰᠬᠠᠬᠤ ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ E C ᠬᠡᠮᠵᠢᠭᠦᠷ ᠠᠭᠤᠰᠤᠮᠠᠯ ᠢ ᠬᠡᠮᠵᠢᠬᠦ EC ( ᠴᠠᠬᠢᠯᠭᠠᠨ ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠮᠵᠢ ) ᠃ ᠰᠢᠩᠭᠡᠨ ᠪᠡᠶᠡᠲᠦ ᠳᠣᠲᠣᠷᠠᠬᠢ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠵᠠᠳᠠᠯᠤᠭᠳᠠᠯ ᠤᠨ ᠥᠳᠬᠡᠴᠡ ᠶᠢ ᠲᠤᠰᠬᠠᠬᠤ ",
    "gui.extension.sen0170.name": "ᠰᠠᠯᠬᠢᠨ ᠤ ᠬᠤᠷᠳᠤᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.sen0170.description": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠬᠦᠴᠦᠳᠡᠯ ᠦᠨ ᠰᠠᠯᠬᠢᠨ ᠤ ᠬᠤᠷᠳᠤᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠂ ᠰᠠᠯᠬᠢᠨ ᠤ ᠬᠤᠷᠳᠤᠴᠠ ᠶᠢᠨ ᠳᠡᠰ ᠢ ᠬᠡᠮᠵᠢᠵᠦ ᠪᠣᠯᠤᠨᠠ ",
    "gui.extension.sen0226.name": "BMP280 ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.sen0226.description": "BMP280 ᠠᠭᠠᠷ ᠤᠨ ᠳᠠᠷᠤᠯᠲᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠂",
    "gui.extension.sen0228.name": "ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠪᠠᠢᠴᠠᠭᠠᠯᠲᠠ ᠪᠠ ᠠᠭᠠᠷ ᠮᠠᠨᠳᠠᠯ ᠤᠨ ᠳᠠᠷᠤᠴᠠ ᠶᠢᠨ ᠪᠠᠶᠢᠴᠠᠭᠠᠯᠲᠠ ᠶᠢᠨ ᠳᠠᠪᠬᠤᠷ ᠴᠢᠳᠠᠮᠵᠢ ᠲᠠᠶ ᠣᠷᠴᠢᠨ ᠤ ᠭᠡᠷᠡᠯ ᠦᠨ ᠰᠡᠷᠡᠯ ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠭᠤᠷ ",
    "gui.extension.sen0228.description": "I2C VEML7700 ᠣᠷᠴᠢᠨ ᠲᠣᠭᠣᠷᠢᠨ ᠤ ᠭᠡᠷᠡᠯ ᠦᠨ ᠨᠦᠯᠦᠭᠡᠯᠡᠭᠦᠷ ( 0 ~ 120 K l x )",
    "gui.extension.sen0236.name": "BME280模块",
    "gui.extension.sen0236.description": "Environmental sensors (temperature, humidity, air pressure)",
    "gui.extension.sen0517.name": "ICP10111气压温度传感器",
    "gui.extension.sen0517.description": "精度±1Pa，高精度高稳定性气压传感器",
    "gui.extension.dfr0022.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ ᠰᠠᠭᠠᠷᠠᠯᠠᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.dfr0022.description": "ᠭᠠᠵᠠᠷ ᠤᠨ ᠭᠠᠳᠠᠷᠭᠤ ᠪᠤᠶᠤ ᠰᠢᠷᠡᠭᠡᠨ ᠨᠢᠭᠤᠷ ᠤᠨ ᠠᠳᠠᠯᠢ ᠪᠤᠰᠤ ᠥᠩᠭᠡ ᠶᠢ ᠰᠡᠷᠡᠵᠦ ᠮᠡᠳᠡᠭᠡᠳ ᠬᠠᠷᠠᠭᠠᠯᠵᠠᠭᠰᠠᠨ ᠳᠣᠬᠢᠶᠠ ᠡᠭᠦᠰᠬᠡᠵᠡᠢ ",
    "gui.extension.motucamera.name": "ᠬᠠᠷᠠᠭᠠᠨ ᠰᠡᠷᠡᠯ ᠦᠨ ᠺᠠᠮᠧᠷᠠ",
    "gui.extension.motucamera.description": "ᠡᠯ᠎ᠡ ᠵᠦᠢᠯ ᠦᠨ ᠵᠢᠷᠤᠭ ᠳᠦᠷᠰᠦ ᠶᠢ ᠳᠠᠨᠢᠨ ᠮᠡᠳᠡᠷᠡᠳᠡᠭ ᠺᠠᠮᠧᠷᠠ ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.dfr0552.name": "12 ᠣᠷᠤᠨᠲᠤ DA ᠬᠦᠷᠪᠡᠭᠦᠯᠬᠦ ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.dfr0552.description": "ᠲᠣᠭ᠎ᠠ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠣᠨᠤᠪᠴᠢᠲᠠᠢ ᠪᠠᠷ ᠬᠠᠷᠠᠭᠠᠯᠵᠠᠭᠰᠠᠨ ᠲᠣᠭᠲᠠᠮᠠᠯ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠬᠦᠴᠦᠳᠡᠯᠲᠦ ᠳᠣᠬᠢᠶ᠎ᠠ ᠪᠣᠯᠭᠠᠬᠤ(PWM ᠪᠣᠰᠤ)",
    "gui.extension.dfr0699.name": "音频录放EDU",
    "gui.extension.dfr0699.description": "单片机控制录放音、可以储存 10 段 100s 时长音频",
    "gui.extension.dfr0760.name": "语音合成模块",
    "gui.extension.dfr0760.description": "Gravity接口的语音合成模块，无需联网即可合成语音",
    "gui.extension.dfr0715.name": "语音识别模块",
    "gui.extension.dfr0715.description": "Gravity接口的语音识别模块，无需联网",
    "gui.extension.sen0117.name": "语音合成模块",
    "gui.extension.sen0117.description": "你只要输入相应的中英文字符和数字，它就会读出这些文字",
    "gui.extension.dfr0576.name": "I2C ᠥᠷᠭᠡᠳᠭᠡᠭᠴᠢ",
    "gui.extension.dfr0576.description": "I2C ᠲᠣᠨᠤᠭ ᠤᠨ ᠬᠠᠶᠢᠭ ᠤᠨ ᠵᠥᠷᠢᠴᠡᠯ ᠢ ᠰᠢᠳᠪᠦᠷᠢᠯᠡᠬᠦ ᠳᠦ ᠬᠡᠷᠡᠭᠯᠡᠨ᠎ᠡ",
    "gui.extension.sen0248.name": "BME680 ᠣᠷᠴᠢᠨ ᠤ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sen0248.description": "VOC (ᠳᠡᠭᠳᠡᠮᠬᠡᠢ ᠤᠷᠭᠠᠨᠢᠭ ᠪᠣᠳᠠᠰ) ᠂ ᠳᠤᠯᠠᠭᠠᠴᠠ᠂ ᠴᠢᠭᠢᠭᠳᠡᠴᠡ᠂ ᠳᠠᠷᠤᠯᠲᠠ ᠶᠢ ᠬᠡᠮᠵᠢᠨ᠎ᠡ",
    "gui.extension.sen0304.name": "I2C ᠬᠡᠲᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠵᠠᠢ ᠬᠡᠮᠵᠢᠬᠦ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sen0304.description": "ᠲᠦᠷᠭᠡᠨ ᠵᠠᠢ ᠬᠡᠮᠵᠢᠯᠲᠡ ᠪᠤᠶᠤ ᠰᠠᠭᠠᠳ ᠵᠠᠢᠯᠠᠬᠤ ᠬᠡᠷᠡᠭᠯᠡᠭᠡᠨ ᠦ ᠬᠡᠲᠦ ᠳᠠᠭᠤ ᠲᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sen0307.name": "ᠳᠠᠭᠤᠷᠠᠮᠠᠯ ᠬᠡᠲᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠵᠠᠢ ᠬᠡᠮᠵᠢᠯᠲᠡ ᠶᠢᠨ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sen0307.description": "ᠬᠣᠶᠠᠷ ᠲᠣᠯᠤᠭᠠᠢᠲᠤ ᠨᠡᠭᠡᠭᠡᠯᠳᠡᠲᠦ ᠳᠠᠭᠤᠷᠠᠮᠠᠯ ᠬᠡᠲᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠵᠠᠢ ᠬᠡᠮᠵᠢᠯᠲᠡ ᠶᠢᠨ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sen0250.name": "BMI160 6 ᠲᠡᠩᠭᠡᠯᠢᠭᠲᠦ ᠢᠨᠧᠷᠽᠢ ᠶᠢᠨ ᠬᠥᠳᠡᠯᠭᠡᠨᠲᠦ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sen0250.description": "16 ᠣᠷᠤᠨᠲᠤ 3 ᠲᠡᠩᠭᠡᠯᠢᠭᠲᠦ ᠬᠤᠷᠳᠤᠳᠭᠠᠯ ᠨᠡᠮᠡᠬᠦ ᠳᠣᠣᠷ᠎ᠠ ᠴᠢᠳᠠᠯᠲᠤ 3 ᠲᠡᠩᠭᠡᠯᠢᠭᠲᠦ ᠲᠣᠭᠤᠷᠢᠭᠤᠯ ᠬᠡᠮᠵᠢᠭᠦᠷ",
    "gui.extension.sen0224.name": "I2C LIS2DH 3 ᠲᠡᠩᠭᠡᠯᠢᠭᠲᠦ ᠬᠤᠷᠳᠤᠳᠭᠠᠯ ᠬᠡᠮᠵᠢᠭᠦᠷ",
    "gui.extension.sen0224.description": "ᠳᠣᠣᠷ᠎ᠠ ᠴᠢᠳᠠᠯᠲᠤ 3 ᠲᠡᠩᠭᠡᠯᠢᠭᠲᠦ ᠬᠤᠷᠳᠤᠳᠭᠠᠯ ᠤᠨ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.dfr0151.name": "ᠠᠮᠵᠢᠯ ᠬᠤᠭᠤᠴᠠᠭᠠᠨ ᠤ ᠴᠠᠭ DS1307",
    "gui.extension.dfr0151.description": "ᠭᠠᠷᠭᠠᠯᠲᠠ ᠂ ᠣᠨ ᠂ ᠰᠠᠷᠠ ᠂ ᠡᠳᠦᠷ ᠂ ᠭᠠᠷᠠᠭ ᠂ ᠴᠠᠭ ᠂ ᠮᠢᠨᠦ᠋ᠲ ᠂ ᠰᠧᠺᠦ᠋ᠨ᠋ᠳ᠋ ",
    "gui.extension.dfr0469.name": "ᠠᠮᠵᠢᠯ ᠬᠤᠭᠤᠴᠠᠭᠠᠨ ᠤ ᠴᠠᠭ SD2405",
    "gui.extension.dfr0469.description": "ᠭᠠᠷᠭᠠᠯᠲᠠ ᠂ ᠣᠨ ᠂ ᠰᠠᠷᠠ ᠂ ᠡᠳᠦᠷ ᠂ ᠭᠠᠷᠠᠭ ᠂ ᠴᠠᠭ ᠂ ᠮᠢᠨᠦ᠋ᠲ ᠂ ᠰᠧᠺᠦ᠋ᠨ᠋ᠳ᠋ ",
    "gui.extension.dfr0126.name": "ᠳᠠᠪᠲᠠᠮᠵᠢ ᠶᠢᠨ ᠵᠢᠭᠰᠠᠯ ᠤᠨ ᠵᠠᠳᠠᠯᠬᠤ ᠬᠡᠰᠡᠭ ᠳᠠᠭᠤᠤ ᠳᠠᠭᠠᠬᠤ ",
    "gui.extension.dfr0126.description": "ᠳᠠᠪᠲᠠᠮᠵᠢ ᠨᠢ ᠡᠵᠡᠮᠰᠢᠭᠦᠷ ᠪᠣᠯᠤᠨ ᠳᠠᠭᠤᠨ ᠤ ᠬᠥᠳᠡᠯᠦᠯ ᠢ ᠴᠢᠨᠢ ᠡᠭᠦᠰᠬᠡᠵᠡᠢ ",
    "gui.extension.dfr0231.name": "ᠤᠭᠰᠠᠷᠠᠭᠠ ᠠᠮᠠᠨ NFC ᠬᠡᠰᠡᠭ ",
    "gui.extension.dfr0231.description": "NFC ᠣᠢᠷᠠ ᠲᠠᠯᠠᠪᠤᠷ ᠤᠨ ᠬᠣᠯᠪᠣᠭᠠᠨ ᠤ ᠬᠡᠰᠡᠭ ",
    "gui.extension.sen0245.name": "VL53L0X ᠯᠠᠶᠢᠰᠧᠷ ᠵᠠᠶ ᠬᠡᠮᠵᠢᠭᠦᠷ",
    "gui.extension.sen0245.description": "VL53L0X ᠵᠢᠷᠤᠭ ᠲᠥᠯᠥᠪᠯᠡᠭᠡ ᠳᠦ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ Gravity ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠦᠨ ᠬᠡᠰᠡᠭ",
    "gui.extension.dfr0026.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ ᠣᠷᠴᠢᠨ ᠢ ᠭᠡᠷᠡᠯ ᠱᠤᠭᠤᠮ ᠤᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.dfr0026.description": "ᠣᠷᠴᠢᠨ ᠲᠣᠭᠣᠷᠢᠨ ᠤ ᠭᠡᠷᠡᠯ ᠱᠤᠭᠤᠮ ᠤᠨ ᠬᠦᠴᠦᠯᠡᠴᠡ ᠳᠦ ᠪᠠᠢᠴᠠᠭᠠᠯᠲᠠ ᠬᠢᠨᠡ ",
    "gui.extension.dfr0027.name": "ᠲᠣᠭᠠᠨ ᠲᠤᠷᠭᠢᠶᠠ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.dfr0027.description": "ᠮᠡᠳᠡᠷᠡᠬᠦ ᠪᠠᠭᠤᠷᠠᠶ ᠳᠣᠷᠭᠢᠯᠲᠠ ᠶᠢᠨ ᠳᠣᠬᠢᠶᠠ ",
    "gui.extension.dfr0028.name": "ᠲᠣᠭᠠᠨ ᠪᠣᠯᠣᠳ ᠪᠥᠮᠪᠥᠭᠡ ᠶᠢᠨ ᠬᠡᠯᠪᠡᠶᠢᠯᠲᠡ ᠥᠨᠴᠥᠭ ᠦᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.dfr0028.description": "ᠨᠡᠭᠡᠭᠡᠯᠭᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠯ ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠭᠴᠢ ᠶᠢ ᠬᠡᠮᠵᠢᠵᠦ ᠂ ᠵᠢᠰᠢᠭᠦᠦ ᠥᠨᠴᠥᠭ ᠢ ᠬᠡᠮᠵᠢᠵᠦ ᠲᠠᠢᠯᠬᠤ ᠦᠭᠡᠢ ",
    "gui.extension.dfr0029.name": "ᠲᠣᠭᠠᠨ ᠲᠣᠮᠣ ᠳᠠᠷᠤᠪᠴᠢᠲᠤ ᠬᠡᠰᠡᠭ ",
    "gui.extension.dfr0029.description": "ᠥᠨᠳᠥᠷ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠦᠪᠰᠢᠨ ᠢ ᠳᠠᠷᠤᠵᠤ ᠂ ᠳᠣᠣᠷᠠ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠦᠪᠰᠢᠨ ᠢ ᠰᠤᠯᠠᠯᠠᠨ ᠂ ᠴᠡᠴᠡᠷᠡᠨᠡ ",
    "gui.extension.dfr0030.name": "ᠲᠣᠭᠠᠨ ᠲᠡᠮᠲᠡᠷᠢᠯᠳᠦ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.dfr0030.description": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠪᠠᠭᠲᠠᠭᠠᠮᠵᠢ ᠶᠢᠨ ᠰᠡᠷᠡᠯ ᠳᠦ ᠰᠠᠭᠤᠷᠢᠯᠠᠭᠰᠠᠨ ᠬᠦᠷᠦᠯᠴᠡᠬᠦ ᠨᠡᠭᠡᠭᠡᠯᠭᠡ ᠶᠢᠨ ᠬᠡᠰᠡᠭ ᠂ ᠬᠥᠮᠦᠨ ᠦ ᠪᠡᠶᠡ ᠪᠤᠶᠤ ᠲᠡᠮᠦᠷᠯᠢᠭ ᠢ ᠨᠥᠯᠥᠭᠡᠯᠡᠨᠡ ",
    "gui.extension.sen0318.name": "CCS811空气质量传感器",
    "gui.extension.sen0318.description": "可测CO2、TVOC, 适用于空气测量应用",
    "gui.extension.sen0315.name": "PAJ7620U2手势识别传感器",
    "gui.extension.sen0315.description": "IIC通信，在最远20cm范围内，可以识别的手势多达13种",
    "gui.extension.sen0497.name": "DHT20温湿度传感器",
    "gui.extension.sen0497.description": "I2C通信的新一代温湿度传感器，是DHT11的升级产品",
    "gui.extension.sen0514.name": "ENS160空气质量传感器",
    "gui.extension.sen0514.description": "测量TVOC（总挥发性有机化合物）、eCO2（相对二氧化碳）、AQI（空气质量指数）",
    "gui.extension.sen0518.name": "MAX30102心率血氧传感器",
    "gui.extension.sen0518.description": "测量人体静态心率、血氧饱和度",
    "gui.extension.dfr0991.name": "I2C-RGB灯按钮模块",
    "gui.extension.dfr0991.description": "读取按钮的状态，控制RGB灯的颜色和亮灭",
    "gui.extension.sen0376.name": "酒精传感器",
    "gui.extension.sen0376.description": "出厂标定，无需校准，接线方便，0-5ppm",
    "gui.extension.sen0321.name": "臭氧传感器",
    "gui.extension.sen0321.description": "测量环境中臭氧的浓度",
    "gui.extension.sen0364.name": "可见光谱传感器",
    "gui.extension.sen0364.description": "测量可见光光谱，识别光谱颜色",
    "gui.extension.sen0359.name": "指纹传感器",
    "gui.extension.sen0359.description": "一款方便易用的指纹模块",
    "gui.extension.kit0176.name": "I2C重量传感器",
    "gui.extension.kit0176.description": "可测量物体重量，量程1Kg内",
    "gui.extension.bos0063.name": "ADC转I2C模块",
    "gui.extension.bos0063.description": "采集模拟数据转换为I2C信号输出",
    "gui.extension.dfr0033.name": "ᠳ᠋ᠢᠭᠢᠲ ᠨᠦ᠋ᠯᠦᠬᠡᠯᠡᠯᠴᠡᠬᠦᠷ ",
    "gui.extension.dfr0033.description": "ᠬᠠᠶᠢᠨ ᠬᠡᠮᠵᠢᠵᠦ ᠪᠣᠯᠬᠤ ᠰᠣᠷᠢᠨᠵᠢᠨ ᠴᠢᠨᠠᠷᠲᠠᠶ ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ ( ᠰᠣᠷᠢᠨᠵᠢᠨ ᠲᠡᠮᠦᠷ ) ᠂ ᠬᠠᠶᠢᠨ ᠬᠡᠮᠵᠢᠬᠦ ᠬᠡᠪᠴᠢᠶᠡ ᠨᠢ 3cm ᠣᠷᠴᠢᠮ ᠬᠦᠷᠦᠨᠡ ( ᠬᠠᠶᠢᠨ ᠬᠡᠮᠵᠢᠬᠦ ᠬᠡᠪᠴᠢᠶᠡ ᠨᠢ ᠰᠣᠷᠢᠨᠵᠢᠨ ᠴᠢᠨᠠᠷᠲᠠᠶ ᠴᠢᠩᠭᠠ ᠪᠠᠭᠤᠷᠠᠶ ᠲᠠᠶ ᠬᠣᠯᠪᠣᠭᠳᠠᠯ ᠲᠠᠶ )",
    "gui.extension.dfr0034.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ ᠳᠠᠭᠤᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.dfr0034.description": "ᠣᠷᠴᠢᠨ ᠲᠣᠭᠣᠷᠢᠨ  ᠤ ᠳᠠᠭᠤ  ᠶᠢᠨ ᠬᠦᠴᠦᠯᠡᠴᠡ ᠳᠦ ᠪᠠᠢᠴᠠᠭᠠᠯᠲᠠ ᠬᠢᠨᠡ ",
    "gui.extension.sen0132.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ ᠨᠡᠭᠦᠷᠡᠰᠦᠲᠥ᠋ᠷᠥᠭᠴᠢ ᠶᠢᠨ ᠳᠠᠩ ᠢᠰᠦᠯ ᠣᠨ ᠬᠡᠢ ᠪᠡᠶᠡᠲᠦ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.sen0132.description": "ᠨᠡᠭᠦᠷᠡᠰᠦᠲᠥ᠋ᠷᠥᠭᠴᠢ ᠶᠢᠨ ᠳᠠᠩ ᠢᠰᠦᠯ ᠣᠨ ᠬᠡᠢ ᠶᠢᠨ ᠥᠳᠬᠡᠴᠡ ᠶᠢ ᠪᠠᠶᠢᠴᠠᠭᠠᠵᠤ ᠣᠶᠢᠵᠢᠨ ᠂ 20 ᠡᠴᠡ 2000 p m  ᠶᠢᠨ ᠬᠣᠭᠣᠷᠣᠨᠳᠣ",
    "gui.extension.dfr0051.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠬᠦᠴᠦᠳᠡᠯ ᠢ ᠪᠠᠢᠴᠠᠭᠠᠨ ᠬᠡᠮᠵᠢᠬᠦ ᠬᠡᠰᠡᠭ ",
    "gui.extension.dfr0051.description": "ᠲᠣᠭᠲᠠᠮᠠᠯ ᠭᠦᠶᠦᠳᠡᠯ DC ᠴᠠᠬᠢᠯᠭᠠᠨ ᠬᠦᠴᠦᠳᠡᠯ ᠢ ᠪᠠᠶᠢᠴᠠᠭᠠᠵᠤ ᠣᠶᠢᠵᠢᠨ ᠂ ᠬᠠᠮᠤᠭ ᠶᠡᠬᠡ ᠨᠢ +25V ᠂ ᠬᠠᠮᠤᠭ ᠪᠠᠭᠠ ᠨᠢ +0.0245V",
    "gui.extension.dfr0052.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ ᠮᠧᠬᠠᠨᠢᠭ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠱᠠᠵᠠᠩ ᠲᠤᠷᠭᠢᠯᠲᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.dfr0052.description": "ᠬᠡᠮᠵᠢᠬᠦ ᠂ ᠮᠡᠳᠡᠷᠡᠬᠦ ᠪᠠᠢᠳᠠᠯ ᠪᠤᠶᠤ ᠲᠠᠶᠢᠪᠤᠩ ᠲᠥᠯᠥᠪᠲᠦ ᠬᠤᠪᠢᠷᠠᠭᠠᠭᠳᠠᠯ ᠤᠨ ᠬᠦᠴᠦᠯᠡᠴᠡ ᠳᠦ ᠬᠡᠷᠡᠭᠯᠡᠬᠦ ",
    "gui.extension.dfr0058.name": "ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ ᠤᠯᠠᠨ ᠴᠠᠭᠠᠷᠢᠭᠲᠤ ᠡᠷᠭᠢᠯᠲᠡ ᠶᠢᠨ ᠥᠨᠴᠥᠭ ᠦᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.dfr0058.description": "ᠥᠨᠳᠥᠷ ᠨᠠᠷᠢᠪᠴᠢᠯᠠᠯᠳᠤ ᠬᠦᠴᠦᠳᠡᠯ ᠬᠤᠪᠢᠰᠬᠠᠭᠴᠢ ᠂ ᠠᠷᠪᠠᠨ ᠲᠣᠭᠣᠷᠢᠭ ᠡᠷᠭᠢᠯᠳᠦᠵᠦ ᠳᠡᠢᠯᠦᠨᠡ ",
    "gui.extension.dfr0061.name": "JoyStick ᠰᠠᠵᠢᠯᠠᠭᠤᠷ ᠤᠨ ᠬᠡᠰᠡᠭ ",
    "gui.extension.dfr0061.description": "( X ᠂ Y ) 2 ᠲᠡᠩᠭᠡᠯᠢᠭᠲᠦ ᠳᠠᠭᠤᠷᠢᠶᠠᠭᠰᠠᠨ ᠭᠠᠷᠭᠠᠯᠲᠠ ᠲᠠᠶ ( Z ) 1 ᠳ᠋ᠦᠭᠡᠷ ᠵᠠᠮ ᠤᠨ ᠳᠠᠷᠤᠪᠴᠢᠲᠤ ᠭᠠᠷᠭᠠᠯᠲᠠ ᠲᠠᠶ",
    "gui.extension.dfr0072.name": "Shiftout ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.dfr0072.description": "ᠠᠯᠢᠪᠠ ᠪᠠᠢᠳᠠᠯ ᠳᠤ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ ᠳᠠᠷᠠᠭᠠᠯᠠᠯ ᠨᠢ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠠᠲᠠᠭᠳᠠᠭᠤᠷ ᠤᠨ ᠬᠡᠰᠡᠭᠲᠦ ᠲᠠᠢ ᠬᠠᠷᠠᠭᠠᠯᠵᠠᠨ᠎ᠠ",
    "gui.extension.dfr0563.name": "ᠯᠢᠲ᠋ᠢ ᠳ᠋ᠢᠶᠠᠨ ᡂᠢ ᠶᠢᠨ ᠬᠡᠮᠵᠢᠭᠦᠷ",
    "gui.extension.dfr0563.description": "ᠯᠢᠲ᠋ᠢ ᠳ᠋ᠢᠶᠠᠨ ᡂᠢ ᠶᠢᠨ ᠣᠳᠤ ᠶᠢᠨ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠪᠠ ᠵᠠᠷᠤᠭᠰᠠᠨ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠢ ᠬᠡᠮᠵᠢᠬᠦ",
    "gui.extension.dfr0553.name": "15 ᠣᠷᠤᠨᠲᠤ AD ᠬᠦᠷᠪᠡᠭᠦᠯᠬᠦ ᠬᠡᠰᠡᠭᠲᠦ",
    "gui.extension.dfr0553.description": "16 ᠣᠷᠤᠨᠲᠤ AD ᠬᠦᠷᠪᠡᠭᠦᠯᠬᠦ ᠬᠡᠰᠡᠭᠲᠦ ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ ᠲᠣᠬᠢᠶ᠎ᠠ ᠶᠢ ᠣᠨᠤᠪᠴᠢᠲᠠᠢ ᠬᠦᠯᠢᠶᠡᠵᠦ ᠬᠦᠷᠪᠡᠭᠦᠯᠵᠦ ᠳᠡᠢᠯᠦᠨ᠎ᠡ",
    "gui.extension.dfr0588.name": "温湿度传感器SHT30",
    "gui.extension.dfr0588.description": "温湿度传感器SHT30",
    "gui.extension.dfr0076.name": "ᠳᠥᠯᠥ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.dfr0076.description": "ᠭᠠᠯ ᠤᠨ ᠡᠬᠢ ᠪᠤᠶᠤ ᠪᠤᠰᠤᠳ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠤᠷᠳᠤ ᠨᠢ 760nm ~ 1100nm  ᠤᠨ ᠬᠡᠪᠴᠢᠶᠡᠨ ᠳᠡᠬᠢ ᠭᠡᠷᠡᠯ ᠡᠭᠦᠰᠭᠡᠭᠴᠢ ᠶᠢ ᠬᠠᠶᠢᠨ ᠬᠡᠮᠵᠢᠬᠦ",
    "gui.extension.dfr0117.name": "I2C EEPROM数据存储模块",
    "gui.extension.dfr0117.description": "容量32kb",
    "gui.extension.dfr0143.name": "ᠭᠤᠷᠪᠠᠨ ᠲᠡᠩᠭᠡᠯᠢᠭᠲᠦ ᠬᠤᠷᠳᠤᠳᠬᠠᠬᠤ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.dfr0143.description": "MMA7361 ᠴᠠᠬᠢᠮ ᠳᠤ ᠰᠠᠭᠤᠷᠢᠯᠠᠵᠤ ᠂ ᠪᠡᠶᠡ ᠶᠢᠨ ᠪᠠᠢᠳᠠᠯ ᠪᠤᠶᠤ ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ ᠦ ᠴᠢᠭᠯᠡᠯ ᠳᠦ ᠪᠠᠢᠴᠠᠭᠠᠯᠲᠠ ᠬᠢᠨᠡ",
    "gui.extension.sen0018.name": "ᠬᠥᠮᠦᠨ ᠦ ᠪᠡᠶᠡ ᠶᠢᠨ ᠬᠡᠲᠦ ᠤᠯᠠᠭᠠᠨ ᠢᠯᠴᠢ ᠪᠡᠷ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠰᠠᠷᠨᠢᠭᠤᠯᠬᠤ ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ ᠦ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.sen0018.description": "ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ ᠪᠠᠢᠴᠠᠭᠠᠨ ᠰᠢᠯᠭᠠᠬᠤ ᠬᠥᠮᠦᠨ ᠦ ᠪᠡᠶᠡ ᠪᠤᠶᠤ ᠠᠮᠢᠲᠠᠨ ",
    "gui.extension.sen0114.name": "ᠬᠥᠷᠥᠰᠥᠨ ᠦ ᠴᠢᠭᠢᠭᠲᠦᠴᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.sen0114.description": "ᠬᠥᠷᠥᠰᠥᠨ ᠦ ᠤᠰᠤ ᠶᠢ ᠪᠠᠶᠢᠴᠠᠭᠠᠵᠤ ᠣᠶᠢᠵᠢᠨ ᠂ ᠬᠥᠷᠥᠰᠥ ᠤᠰᠤ ᠳᠤᠲᠠᠭᠳᠠᠬᠤ ᠦᠶᠡᠰ ᠂ ᠨᠦᠯᠦᠭᠡᠯᠡᠭᠦᠷ ᠦᠨ ᠭᠠᠷᠭᠠᠬᠤ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠨᠢ ᠪᠠᠭᠠᠰᠤᠨᠠ ",
    "gui.extension.sen0121.name": "ᠤᠰᠤᠨ ᠤ ᠰᠡᠷᠡᠯ ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠭᠤᠷ ",
    "gui.extension.sen0121.description": "ᠪᠣᠷᠣᠭᠠᠨ ᠤ ᠤᠰᠤ ᠶᠢ ᠪᠠᠶᠢᠴᠠᠭᠠᠬᠤ ᠠᠴᠠ ᠭᠠᠳᠠᠨᠠ ᠂ ᠮᠠᠨᠠ ᠪᠠ ᠣᠰᠣᠨ ᠠᠭᠤᠷ ᠢ ᠪᠦᠷ ᠪᠠᠶᠢᠴᠠᠭᠠᠵᠤ ᠳᠡᠶᠢᠯᠦᠨᠡ ",
    "gui.extension.sen0212.name": "ᠥᠩᠭᠡ ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠬᠤ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.extension.sen0212.description": "I2C ᠥᠩᠭᠡ ᠶᠢᠨ ᠢᠯᠭᠠᠯᠲᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.sen0253.name": "10DOF ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.sen0253.description": "10DOF ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.sen0290.name": "lightning ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sen0290.description": "lightning ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sen0291.name": "I2C数字功率计",
    "gui.extension.huskylens.name": "HUSKYLENS AI摄像头",
    "gui.extension.huskylens.description": "人工智能视觉传感器，支持人脸识别和学习功能",
    "gui.extension.ps2.name": "PS2 手柄",
    "gui.extension.ps2.description": "PS2通用手柄接收模块",
    "gui.extension.sen0291.description": "高精度测量电压、电流、功率",
    "gui.extension.sen0202.name": "3D ᠭᠠᠷ ᠳᠣᠬᠢᠶᠠ ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠬᠤ ᠪᠢᠴᠢᠯ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ",
    "gui.extension.sen0202.description": "ᠭᠠᠷ ᠤᠨ ᠬᠤᠷᠤᠭᠤᠨ ᠤ ᠴᠠᠭ ᠣᠨ ᠵᠡᠭᠦᠦ / ᠴᠠᠭ ᠣᠨ ᠵᠡᠭᠦᠦ ᠶᠢᠨ ᠡᠰᠡᠷᠭᠦ ᠡᠷᠭᠢᠬᠦ ᠴᠢᠭᠯᠡᠯ ᠪᠠ ᠭᠠᠷ ᠤᠨ ᠬᠤᠷᠤᠭᠤᠨ ᠤ ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ ᠦ ᠴᠢᠭᠯᠡᠯ ᠢ ᠢᠯᠭᠠᠨ ᠲᠠᠨᠢᠬᠤ ᠵᠡᠷᠭᠡ ",
    "gui.extension.iobox.name": "micro ᠄ IO - BOX ",
    "gui.extension.iobox.description": "microbit ᠲᠤᠰᠬᠠᠶ ᠬᠡᠷᠡᠭᠯᠡᠬᠦ ᠯᠢᠲ᠋ᠢ ᠳ᠋ᠢᠶᠠᠨ ᡂᠢ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠮᠠᠰᠢᠨ ᠤ ᠥᠷᠭᠡᠳᠬᠡᠭᠰᠡᠨ ᠬᠠᠪᠲᠠᠰᠤ ",
    "gui.extension.wifi.name": "Wi-Fi ᠬᠡᠷᠡᠭᠰᠡᠯ ",
    "gui.extension.wifi.description": "ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ᠶᠢ Wi-Fi ᠰᠦᠯᠵᠢᠶᠡ ᠲᠡᠶ ᠬᠣᠯᠪᠣᠬᠤ ",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "让设备获取网络时间",
    "gui.extension.udp.name": "UDP broadcast",
    "gui.extension.udp.description": "让局域网内的设备与设备通过UDP协议实现多机广播通信",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "让设备通过HTTP请求信息",
    "gui.extension.mqtt.name": "MQTT ",
    "gui.extension.mqtt.description": "ᠰᠢᠯᠭᠠᠯᠲᠠ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ᠪᠡᠷ MQTT ᠵᠥᠪᠯᠡᠯ ᠢᠶᠡᠷ ᠬᠣᠯᠪᠣᠭᠠ ᠠᠪᠴᠤ ᠪᠣᠯᠤᠨᠠ ",
    "gui.extension.internet.name": "网络",
    "gui.extension.internet.description": "网络通信",
    "gui.extension.speechRecognition.name": "ᠠᠪᠢᠶ᠎ᠠ ᠲᠠᠨᠢᠬᠤ",
    "gui.extension.speechRecognition.description": "ᠠᠪᠢᠶ᠎ᠠ ᠲᠠᠨᠢᠬᠤ",
    "gui.extension.pictureai.defaultTitleText": "视频窗口",
    "gui.extension.pictureai.cameraPermissionsTitle": "需要相机权限",
    "gui.extension.pictureai.cameraPermissionsMessage": "请前往设置中勾选授权。如已授权但仍无法使用，请确认摄像头已正常连接无其它软件占用，或重启软件。",
    "gui.extension.pictureai.cameraPermissionsCancel": "取消",
    "gui.extension.pictureai.cameraPermissionsGoToSettings": "前往设置",
    "gui.gui.loaded": " ᠠᠴᠢᠶᠠᠯᠠᠭᠰᠠᠨ",
    "gui.gui.notLoaded": " ᠠᠴᠢᠶᠠᠯᠠᠭᠰᠠᠨ ᠦᠭᠡᠢ",
    "gui.gui.cantFindYouWant": "ᠴᠢᠨᠦ ᠠᠪᠬᠤ ᠭᠡᠰᠡᠨ ᠶᠠᠭᠤᠮᠠ ᠶᠢ ᠡᠷᠢᠵᠦ ᠣᠯᠬᠤ ᠦᠭᠡᠢ  ᠪᠠᠢᠨ᠎ᠠᠠ ᠃ ",
    "gui.gui.clickHere": "ᠡᠨᠳᠡ ᠳᠣᠶᠢ",
    "gui.gui.viewHelp": "ᠬᠠᠪᠰᠤᠷᠤᠯᠭᠠ ᠶᠢ ᠪᠠᠢᠴᠠᠭᠠᠨ ᠦᠵᠡᠬᠦ ",
    "gui.gui.uploadToDev": "ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ᠳ᠋ᠤ ᠳᠡᠭᠡᠭᠰᠢ ᠳᠠᠮᠵᠢᠭᠤᠯᠬᠤ ",
    "gui.gui.codeArea": "代码区",
    "gui.gui.fileSys": "文件系统",
    "gui.gui.compileAndUpload": "ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠭᠰᠠᠨ ᠤ ᠳᠠᠷᠠᠭᠠ ᠳᠡᠭᠡᠭᠰᠢ ᠳᠠᠮᠵᠢᠭᠤᠯᠬᠤ ",
    "gui.gui.compileOnly": "ᠵᠥᠪᠬᠡᠨ ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠨ ᠣᠷᠴᠢᠭᠤᠯᠬᠤ ",
    "gui.gui.compileOpen": "ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠨ ᠣᠷᠴᠢᠭᠤᠯᠤᠭᠰᠠᠨ ᠤ ᠳᠠᠷᠠᠭᠠ ᠨᠡᠭᠡᠭᠡᠬᠦ ",
    "gui.gui.burnBootloader": "ᠰᠢᠲᠠᠭᠠᠨ ᠰᠢᠩᠭᠡᠭᠡᠬᠦ ᠷᠠᠳᠢᠣ᠋ ᠬᠥᠲᠥᠯᠬᠦ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ",
    "gui.gui.arduinoRun": "ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ ",
    "gui.gui.arduinoSave": "ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ",
    "gui.gui.autoGenerated": " ᠠᠦ᠋ᠲ᠋ᠣ᠋  ᠡᠭᠦᠰᠭᠡᠬᠦ",
    "gui.gui.manualEditing": " ᠥᠪᠡᠷᠲᠡᠭᠡᠨ ᠨᠠᠢᠷᠠᠭᠤᠯᠬᠤ",
    "gui.gui.codeBox": "ᠺᠣᠳ᠋ ᠤᠨ ᠴᠣᠩᠬᠤ",
    "gui.gui.moduleCode": "ᠬᠡᠰᠡᠭᠲᠦ ᠺᠣᠳ᠋",
    "gui.menu.edit.undo": " ᠬᠦᠴᠦᠨ ᠦᠭᠡᠢ ᠪᠣᠯᠭᠠᠬᠤ",
    "gui.menu.edit.redo": "ᠰᠡᠷᠭᠦᠭᠡᠬᠦ",
    "gui.menu.edit.selectAll": " ᠪᠦᠬᠦᠨ ᠢ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.menu.edit.cut": "ᠬᠠᠢᠴᠢᠯᠠᠬᠤ",
    "gui.menu.edit.copy": "ᠺᠣᠫᠢᠳᠠᠬᠤ",
    "gui.menu.edit.paste": "ᠨᠠᠭᠠᠬᠤ",
    "gui.menu.edit.codeStyle": " ᠺᠣᠳ᠋ ᠤᠨ  ᠨᠠᠮᠪᠠ",
    "gui.menu.edit.fontSize": "ᠥᠰᠥᠭ ᠦᠨ ᠲᠢᠭ ᠣᠨ ᠶᠡᠬᠡ ᠪᠠᠭᠠ ",
    "gui.menu.edit.clearCache": "ᠨᠠᠮᠬᠠᠳᠬᠠᠯ ᠬᠠᠳᠠᠭᠠᠯᠠᠯᠲᠠ ᠶᠢ ᠠᠷᠢᠯᠭᠠᠬᠤ",
    "gui.menu.edit.maxReset": "ᠳᠠᠬᠢᠨ ᠦᠢᠯᠡᠳᠪᠦᠷᠢ ᠡᠴᠡ ᠭᠠᠷᠭᠠᠬᠤ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠤᠯᠲᠠ ",
    "gui.gui.serial": "COM  ᠵᠠᠯᠭᠠᠭᠤᠷ",
    "gui.gui.openSerial": "COM ᠵᠠᠯᠭᠠᠭᠤᠷ ᠢ ᠨᠡᠭᠡᠭᠡᠬᠦ",
    "gui.gui.closeSerial": "COM ᠵᠠᠯᠭᠠᠭᠤᠷ ᠢ ᠬᠠᠭᠠᠬᠤ",
    "gui.gui.close": "ᠬᠠᠭᠠᠬᠤ",
    "gui.gui.open": "ᠨᠡᠭᠡᠭᠡᠬᠦ",
    "gui.gui.clearOutput": " ᠭᠠᠷᠭᠠᠯᠲᠠ ᠶᠢ ᠠᠷᠢᠯᠭᠠᠬᠤ",
    "gui.gui.scroll": " ᠥᠩᠬᠦᠷᠢᠬᠦ ᠳᠡᠯᠭᠡᠴᠡ",
    "gui.gui.send": " ᠢᠯᠡᠭᠡᠬᠦ",
    "gui.gui.baud": " ᠪᠦᠲ ᠨᠤᠷᠮᠠ",
    "gui.gui.noLineEnd": " ᠲᠡᠭᠦᠰᠬᠡᠯ ᠲᠡᠮᠲᠡᠭᠲᠦ ᠳᠤᠲᠠᠭᠤ  ᠪᠠᠢᠨ᠎ᠠ",
    "gui.gui.newLine": " ᠮᠥᠷ ᠰᠣᠯᠢᠬᠤ ᠲᠡᠮᠳᠡᠭᠲᠦ",
    "gui.gui.carriageReturn": " ᠪᠤᠴᠠᠬᠤ ᠲᠡᠮᠳᠡᠭᠲᠦ",
    "gui.gui.bothNLandCR": "ᠪᠤᠴᠠᠬᠤ ᠪᠥᠭᠡᠳ ᠮᠥᠷ ᠰᠣᠯᠢᠬᠤ",
    "gui.gui.unavailableHexData": "Invalid hex form! Please represent hex data with two characters ((0-9/A-F) and a blank, e.g. \"00 01 A2 FF\".",
    "gui.gui.hexForm": "Hex Form",
    "gui.gui.openHexForm": "Use hex form to show and send data",
    "gui.gui.closeHexForm": "Close",
    "gui.progress.burning": "ᠳᠡᠭᠡᠭᠰᠢ ᠳᠠᠮᠵᠢᠭᠤᠯᠵᠤ  ᠪᠠᠢᠨ᠎ᠠᠠ ",
    "gui.progress.compiling": "ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠨ ᠣᠷᠴᠢᠭᠤᠯᠵᠤ  ᠪᠠᠢᠨ᠎ᠠᠠ",
    "gui.burner.compileError": "Compile error",
    "gui.burner.linkError": "Link error",
    "gui.burner.generateHexError": "Generate .hex error",
    "gui.burner.generateBinError": "Generate .bin error",
    "gui.burner.burnError": "Burn error",
    "gui.burner.eraseError": "Erase error",
    "gui.burner.findLeonardoPortError": "Cannot find bootlaoder port of Leonardo",
    "gui.burner.noMicrobit": "Sorry it is not micro: bit, please connect micro: bit",
    "gui.burner.swdCfgError": "Cannot write to cmsis-dap.cfg",
    "gui.burner.UDiskPopUp": "UDisk pops up, please wait for a while and try again",
    "gui.burner.UDiskWriteError": "Write to UDisk error",
    "gui.burner.openPortError": "Could not open port: Access is denied",
    "gui.burner.firmwareError": "固件错误, 请初始化设备后重新连接",
    "gui.burner.uploadDone": "上传完毕",
    "gui.burner.connectPort": "连接串口",
    "gui.burner.writeLibFiles": "正在写入库文件",
    "gui.burner.burnFirmware": "烧录固件",
    "gui.burner.connectError": "连接设备失败，请重新插拔设备再次下载",
    "gui.burner.connectPico": "Pico固件下载成功，请重新选择串口连接",
    "gui.burner.printObjectTooBig": "The project is too large; please click the \"help\" in the right top to reduce its size. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooBig": "Not enough memory; please click the \"help\" in the right top to reduce memory occupancy. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Low memory available, stability problems may occur. ",
    "gui.burner.printSizeError": "无法确定程序大小 ",
    "gui.burner.printSize": "项目使用了[%TEXTSIZE]字节，占用了([%TEXTSIZEPRE])程序存储空间，余留[%TEXTSIZESUR]字节，最大为[%TEXTSIZEMAX]字节。\n全局变量使用了[%DATASIZE]字节，([%DATASIZEPRE])的动态内存，余留[%DATASIZESUR]字节局部变量，最大为[%DATASIZEMAX]字节。 ",
    "gui.progress.compileSuccess": "ᠨᠠᠢᠷᠠᠭᠤᠯᠪᠠ︕",
    "gui.progress.compileProgress": "ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠨ ᠣᠷᠴᠢᠭᠤᠯᠬᠤ ᠠᠬᠢᠴᠠ ",
    "gui.progress.uploadProgress": "ᠳᠡᠭᠡᠭᠰᠢ ᠳᠠᠮᠵᠢᠭᠤᠯᠬᠤ ᠠᠬᠢᠴᠠ ",
    "gui.progress.uploadSuccess": "ᠳᠡᠪᠰᠢᠭᠦᠯᠪᠡ︕",
    "gui.progress.uploadSuccessVm": "上传成功",
    "gui.progress.networkInterfaceCard": "网卡",
    "gui.codeLabel.dynamicVariables": "动态变量",
    "gui.codeLabel.functionDeclaration": "函数声明",
    "gui.codeLabel.staticConstants": "静态常量",
    "gui.codeLabel.createObject": "创建对象",
    "gui.codeLabel.mainProgramStart": "主程序开始",
    "gui.codeLabel.SubthreadProgramStarts": "子线程%1程序开始",
    "gui.codeLabel.customFunction": "自定义函数",
    "gui.codeLabel.eventCallbackFunction": "事件回调函数",
    "gui.codeLabel.globalCode": "全局变量",
    "gui.blocklyText.mpyTimer.generate.setEvent.period": "No variables allowed!",
    "gui.codeLabel.staticFunctions": "静态函数",
    "gui.progress.errMsg": " ᠳᠡᠭᠡᠭᠰᠢ ᠳᠠᠮᠵᠢᠭᠤᠯᠬᠤ ᠨᠢ ᠠᠯᠳᠠᠭᠠ ᠭᠠᠷᠴᠠᠢ ᠂  ᠠᠯᠳᠠᠭᠠ ᠶᠢᠨ ᠠᠭᠤᠯᠭᠠ ᠶᠢ ᠪᠠᠷᠠᠭᠤᠨ ᠳᠣᠣᠷᠠ ᠥᠨᠴᠥᠭ ᠲᠦ ᠦᠵᠡᠭᠡᠷᠡᠢ",
    "gui.progress.errMsg1": "上传出错，详细信息请打开「代码区」小黑窗查看",
    "gui.progress.errMsg2": "上传出错，详细信息请打开「代码区」小黑窗查看",
    "gui.howtos.spin-around.name": "ᠡᠷᠭᠢᠯᠳᠦᠬᠦ ",
    "gui.howtos.spin.step_dragTurn": "ᠨᠢᠭᠡᠨ 《 ᠪᠠᠷᠠᠭᠤᠨᠰᠢ ᠡᠷᠭᠢᠬᠦ 》  ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢ ᠴᠢᠷᠴᠦ ᠭᠠᠷᠭᠠᠪᠠ ᠃",
    "gui.howtos.spin.step_clickTurn": "ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠤ ᠬᠡᠰᠡᠭ ᠢ ᠳᠣᠶᠢᠵᠤ ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠬᠤ",
    "gui.howtos.spin.step_clickControl": "ᠡᠵᠡᠮᠰᠢᠯ ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢ ᠳᠣᠶᠢᠬᠤ",
    "gui.howtos.spin.step_dragForever": " ᠳᠠᠪᠲᠠᠨ ᠭᠦᠢᠴᠡᠳᠭᠡᠬᠦ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢ ᠴᠢᠷᠴᠦ ᠭᠠᠷᠭᠠᠬᠤ",
    "gui.howtos.spin.step_clickForever": " ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢ  ᠳᠣᠶᠢᠭᠠᠳ ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ",
    "gui.howtos.spin.step_changeColor": " ᠥᠩᠭᠡ ᠶᠢᠨ ᠦᠢᠯᠡᠳᠦᠯ ᠢ ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢ ᠨᠡᠮᠡᠬᠦ",
    "gui.howtos.say-hello.name": " ᠰᠠᠢᠨ ᠤᠤ ᠭᠡᠬᠦ",
    "gui.howtos.say-hello.step_addSprite": " ᠳᠦᠷᠢ ᠨᠡᠮᠡᠬᠦ",
    "gui.howtos.say-hello.step_clickLooks": " ᠭᠠᠳᠠᠷ ᠪᠠᠢᠳᠠᠯ ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢ ᠨᠡᠮᠡᠬᠦ",
    "gui.howtos.say-hello.step_dragSay": " ᠬᠡᠯᠡᠬᠦ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢ ᠴᠢᠷᠴᠦ ᠭᠠᠷᠭᠠᠬᠤ",
    "gui.howtos.say-hello.step_clickSay": " ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢ  ᠳᠣᠶᠢᠭᠠᠳ ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ",
    "gui.howtos.say-hello.step_anotherSay": " ᠥᠭᠡᠷᠡ ᠨᠢᠭᠡ ᠬᠡᠯᠡᠬᠦ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢ ᠴᠢᠷᠴᠦ ᠭᠠᠷᠭᠠᠬᠤ",
    "gui.howtos.say-hello.step_editSay": " ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢᠨ ᠠᠭᠤᠯᠭᠠ",
    "gui.howtos.say-hello.step_clickStack": " ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢ  ᠳᠣᠶᠢᠭᠠᠳ ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ",
    "gui.howtos.run-away.name": "ᠣᠷᠭᠣᠨ ᠳᠤᠲᠠᠭᠠᠬᠤ ",
    "gui.howtos.run-away.step_dragGoTo": "\" ᠲᠠᠰᠢᠷᠠᠮ ᠢᠶᠠᠷ ᠰᠢᠯᠵᠢᠬᠦ \"  ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠶᠢ ᠴᠢᠷᠴᠦ ᠭᠠᠷᠭᠠᠪᠠ ",
    "gui.howtos.run-away.step_clickGoTo": "ᠨᠦᠳᠦᠨ ᠴᠣᠬᠢᠬᠤ ᠵᠡᠷᠭᠡᠯᠡᠮᠡᠯ ᠮᠣᠳᠣᠨ ᠤ ᠬᠡᠰᠡᠭ ᠦᠨ ᠠᠵᠢᠯᠯᠠᠭᠠ ",
    "gui.howtos.run-away.step3": "ᠵᠡᠭᠦᠨ ᠲᠠᠯᠠ ᠶᠢᠨ 《 ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ 》  ᠢ ᠳᠠᠷᠤᠵᠤ ᠴᠣᠬᠢᠬᠤ ᠬᠡᠰᠡᠭ ",
    "gui.howtos.run-away.step_addWhenClicked": "\" ᠳᠦᠷᠢ ᠶᠢ ᠨᠢ ᠲᠣᠪᠰᠢᠯᠠᠭᠤᠯᠬᠤ \" ᠰᠠᠭᠤᠷᠢ ᠮᠣᠳᠣ ᠨᠡᠮᠡᠶᠡ ",
    "gui.howtos.run-away.step_clickSprite": "ᠳᠠᠷᠤᠵᠤ ᠲᠣᠭᠯᠠᠬᠤ ",
    "gui.howtos.run-away.step_addSound": "ᠡᠬᠢᠯᠡᠨ ᠲᠠᠯᠪᠢᠬᠤ ᠳᠠᠭᠤ ᠲᠠᠢ ᠮᠣᠳᠣ ᠨᠢᠭᠡ ᠨᠡᠮᠡᠭᠡᠷᠡᠢ ",
    "gui.howtos.pick-up-apple.name": " ᠠᠯᠮᠤᠷᠠᠳ ᠲᠡᠭᠦᠬᠦ",
    "gui.howtos.pick-up-apple.step_1": " ᠲᠠᠢᠯᠪᠤᠷᠢᠯᠠᠨ ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ ᠨᠢᠭᠤᠷ ᠲᠤ ᠰᠣᠯᠢᠬᠤ ",
    "gui.howtos.pick-up-apple.step_2": "ᠦᠢᠯᠡᠳᠬᠦᠨ ᠰᠣᠯᠢᠬᠤ ",
    "gui.howtos.pick-up-apple.step_3": "ᠵᠠᠯᠭᠠᠬᠤ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ",
    "gui.howtos.pick-up-apple.step_4": "ᠵᠦᠭᠯᠡᠭᠦᠷ ᠢ ᠰᠢᠯᠭᠠᠬᠤ ",
    "gui.howtos.pick-up-apple.step_5": "ᠵᠤᠷᠰᠢᠯᠲᠤ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠢ ᠨᠡᠭᠡᠭᠡᠬᠦ ",
    "gui.howtos.pick-up-apple.step_6": "ᠬᠣᠶᠠᠭᠤᠯᠠ ᠠᠯᠮᠤᠷᠠᠳ ᠵᠠᠯᠭᠠᠬᠤ ",
    "gui.howtos.move-left-right.name": "micro:bit  ᠠᠯᠤᠰ ᠡᠵᠡᠮᠰᠢᠯ ᠪᠦᠬᠦᠢ ᠪᠢᠴᠢᠬᠠᠨ ᠮᠤᠤᠷ ",
    "gui.howtos.move-left-right.step_1": "ᠰᠢᠨᠡ ᠪᠡᠷ ᠪᠠᠢᠭᠤᠯᠬᠤ ᠵᠦᠢᠯᠡᠰ ",
    "gui.howtos.move-left-right.step_2": "ᠵᠡᠷᠭᠡᠯᠡᠬᠦ ᠮᠣᠳᠣ 1 ᠶᠢ ᠴᠢᠷᠬᠦ",
    "gui.howtos.move-left-right.step_3": "ᠵᠡᠷᠭᠡᠯᠡᠬᠦ ᠮᠣᠳᠣ 2 ᠢ ᠴᠢᠷᠬᠦ",
    "gui.howtos.move-left-right.step_4": "micr:bit ᠠᠯᠤᠰ ᠡᠵᠡᠮᠰᠢᠯ ᠪᠦᠬᠦᠢ ᠪᠢᠴᠢᠬᠠᠨ ᠮᠤᠤᠷ ",
    "gui.howtos.install-driver.name": "ᠤᠭᠰᠠᠷᠠᠵᠤ ᠬᠥᠳᠡᠯᠭᠡᠬᠦ ",
    "gui.howtos.transform-expression.name": "ᠳᠠᠷᠤᠪᠴᠢᠲᠤ ᠢᠯᠡᠷᠡᠯ ᠳᠠᠮᠵᠢᠭᠤᠯᠬᠤ ",
    "gui.howtos.transform-expression.step_1": "ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ ᠵᠤᠯᠭᠠᠭᠤᠷᠢ ᠳᠤ ᠰᠣᠯᠢᠬᠤ ",
    "gui.howtos.transform-expression.step_2": "ᠦᠢᠯᠡᠳᠬᠦᠨ ᠰᠣᠯᠢᠬᠤ ",
    "gui.howtos.transform-expression.step_3": "ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ 1  ᠶᠢ ᠴᠢᠷᠬᠦ",
    "gui.howtos.transform-expression.step_4": "ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ 2  ᠶᠢ ᠴᠢᠷᠬᠦ",
    "gui.howtos.transform-expression.step_5": "ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ 3  ᠶᠢ ᠴᠢᠷᠬᠦ",
    "gui.howtos.transform-expression.step_6": "ᠳᠠᠷᠤᠪᠴᠢᠲᠤ ᠢᠯᠡᠷᠡᠯ ",
    "gui.howtos.lot.name": "ᠪᠣᠳᠠᠰ ᠤᠨ ᠰᠦᠯᠵᠢᠶᠡ(IOT)",
    "gui.howtos.lot.step_1": "ᠵᠤᠷᠰᠢᠯᠲᠤ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠢ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.howtos.lot.step_2": "ᠰᠢᠲᠠᠭᠠᠵᠤ ᠰᠢᠩᠭᠡᠭᠡᠬᠦ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠢᠶᠡᠷ ᠲᠡᠮᠲᠡᠷᠢᠬᠦ ",
    "gui.howtos.lot.step_3": "ᠪᠣᠳᠠᠰ ᠤᠨ ᠰᠦᠯᠵᠢᠶᠡ(IOT)",
    "gui.howtos.touch-pin.name": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠲᠠᠢ ᠬᠦᠷᠡᠯᠴᠡᠬᠦ",
    "gui.howtos.touch-pin.step_1": "ᠳᠡᠭᠡᠭᠰᠢ ᠳᠠᠮᠵᠢᠭᠤᠯᠵᠤ ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ ᠨᠢᠭᠤᠷ ᠲᠤ ᠰᠣᠯᠢᠬᠤ ",
    "gui.howtos.touch-pin.step_2": "ᠵᠤᠷᠰᠢᠯᠲᠤ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠢ ᠰᠣᠩᠭᠣᠬᠤ ",
    "gui.howtos.touch-pin.step_3": "ᠰᠢᠲᠠᠭᠠᠵᠤ ᠰᠢᠩᠭᠡᠭᠡᠬᠦ ᠫᠷᠤᠭ᠍ᠷᠠᠮ",
    "gui.howtos.touch-pin.step_4": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠢᠶᠡᠷ ᠲᠡᠮᠲᠡᠷᠢᠬᠦ ",
    "gui.howtos.save-witch.name": "ᠢᠳᠤᠭᠠᠨ ᠢ ᠠᠪᠤᠷᠠᠬᠤ ",
    "gui.howtos.save-witch.step_1": "《 ᠴᠠᠭ ᠲᠤᠬᠠᠶ ᠳ᠋ᠤ ᠨᠢ ᠬᠡᠷᠡᠭᠵᠢᠭᠦᠯᠬᠦ 》 ᠢ ᠰᠣᠯᠢᠬᠤ",
    "gui.howtos.save-witch.step_2": "ᠥᠷᠭᠡᠳᠬᠡᠬᠦ ᠶᠠᠪᠤᠴᠠ ᠳᠤ \" micro:bit \"  ᠭᠤᠤᠯ ᠡᠵᠡᠮᠳᠡᠬᠦ ᠬᠠᠪᠲᠠᠰᠤ ᠶᠢ  ᠰᠤᠩᠭᠤᠬᠤ",
    "gui.howtos.save-witch.step_3": "\" ᠵᠢᠱᠢᠶᠡ ᠦᠵᠡᠭᠦᠯᠬᠦ ᠫᠷᠤᠭ᠍ᠷᠠᠮ \"  ᠳᠠᠬᠢ \" ᠢᠳᠤᠭᠠᠨ ᠠᠪᠤᠷᠠᠬᠤ \"  ᠶᠢ ᠰᠣᠩᠭᠣᠬᠤ",
    "gui.howtos.save-witch.step_4": " ᠵᠠᠯᠭᠠᠬᠤ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ᠂ microbit  ᠢ ᠰᠣᠩᠭᠣᠵᠤ ᠂  ᠵᠠᠯᠭᠠᠯᠳᠤᠬᠤ ᠶᠢ ᠬᠦᠯᠢᠶᠡᠬᠦ",
    "gui.howtos.save-witch.step_5": "ᠬᠡᠷᠪᠡ \" ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ᠪᠡᠨ ᠰᠢᠯᠭᠠᠬᠤ\" ᠰᠠᠨᠠᠭᠤᠯᠪᠠᠯ ᠪᠦᠬᠦ LED ᠳ᠋ᠧᠩ ᠡᠵᠡᠮᠰᠢᠯ ᠬᠠᠪᠲᠠᠰᠤ ᠶᠢ ᠨᠢᠭᠡ  ᠲᠣᠭᠤᠷᠢᠵᠤ ᠭᠡᠷᠡᠯᠲᠦᠭᠡᠳ ᠤᠨᠲᠠᠷᠠᠨᠠ᠃",
    "gui.howtos.save-witch.step_6": "ᠨᠣᠭᠣᠭᠠᠨ ᠲᠤᠭ ᠢ  ᠳᠣᠶᠢᠵᠤ ᠂ ᠭᠠᠷ ᠢᠶᠠᠨ ᠰᠠᠵᠢᠯᠠᠪᠠᠯ ᠺᠠᠷᠲ᠋ᠣᠨ ᠲᠠᠢ ᠦᠢᠯᠡᠳᠦᠯᠴᠡᠵᠦ ᠪᠣᠯᠤᠨᠠ ᠃ ",
    "gui.howtos.microbit-touch.name": "microbit ᠲᠡᠮᠲᠡᠷᠢᠵᠦ ᠬᠡᠷᠴᠢᠵᠦ ᠰᠣᠯᠢᠬᠤ ᠢᠯᠡᠷᠡᠯ ",
    "gui.howtos.microbit-touch.step_1": "《 ᠳᠡᠭᠡᠭᠰᠢ ᠳᠠᠮᠵᠢᠭᠤᠯᠵᠤ ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ 》 ᠨᠢᠭᠤᠷ ᠲᠤ ᠰᠣᠯᠢᠬᠤ ",
    "gui.howtos.microbit-touch.step_2": "\" ᠰᠤᠷᠤᠯᠴᠠᠯᠭᠠ \"  ᠶᠢᠨ ᠲᠣᠪᠶᠣᠭ ᠤᠨ ᠳᠣᠣᠷᠠᠬᠢ \" ᠵᠢᠱᠢᠶᠡ ᠫᠷᠤᠭ᠍ᠷᠠᠮ\"  ᠢ ᠨᠡᠭᠡᠭᠡᠵᠦ ᠂ \" ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠢᠶᠡᠷ ᠲᠡᠮᠲᠡᠷᠢᠬᠦ \"  ᠶᠢ ᠰᠣᠩᠭᠣᠨᠠ ᠃ ",
    "gui.howtos.microbit-touch.step_3": "ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ᠶᠢᠨ ᠲᠣᠬᠢᠷᠠᠯᠴᠠᠬᠤ COM ᠠᠮᠠᠰᠠᠷ ᠂ \" ᠳᠡᠭᠡᠷᠡ ᠨᠢ ᠳᠠᠮᠵᠢᠭᠤᠯᠬᠤ ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ \"  ᠶᠢ ᠳᠠᠷᠤ ",
    "gui.howtos.microbit-touch.step_4": "ᠠᠳᠠᠯᠢ ᠪᠤᠰᠤ ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢ ᠳᠠᠷᠤᠪᠠᠯ ᠠᠳᠠᠯᠢ ᠪᠤᠰᠤ ᠢᠯᠡᠷᠡᠯ ᠢᠯᠡᠷᠡᠨᠡ",
    "gui.howtos.microbit-calibration.name": "microbit  ᠶᠢ ᠬᠠᠷᠭᠤᠭᠤᠯᠬᠤ ",
    "gui.howtos.calliope-calibration.name": "calliope校准",
    "gui.howtos.microbit-calibration.step_1": "micro:bit ᠬᠠᠪᠲᠠᠰᠤ ᠶᠢ ᠭᠠᠵᠠᠷ ᠳᠡᠭᠡᠷᠡ ᠡᠭᠴᠡ ᠪᠡᠷ ᠡᠷᠭᠢᠯᠳᠦᠭᠦᠯᠦᠨᠡ ᠂ ᠬᠠᠪᠲᠠᠰᠤᠨ ᠳᠡᠭᠡᠷᠡ LED ᠳ᠋ᠧᠩ ᠨᠢ ᠡᠷᠭᠢᠯᠳᠦᠬᠦ ᠶᠢ ᠳᠠᠭᠠᠯᠳᠤᠨ ᠳᠡᠰ ᠳᠠᠷᠠᠭᠠᠯᠠᠨ ᠭᠡᠷᠡᠯᠲᠦᠨᠡ ",
    "gui.howtos.microbit-calibration.step_2": "ᠬᠠᠮᠤᠭ ᠭᠠᠳᠠᠷ ᠲᠠᠯᠠ ᠶᠢᠨ LED ᠳ᠋ᠧᠩ ᠪᠦᠬᠦᠨ ᠢ ᠭᠡᠷᠡᠯᠲᠦᠭᠦᠯᠦᠨᠡ ︵ ᠳᠣᠣᠷᠠᠬᠢ ᠵᠢᠷᠤᠭ ︶ ᠂ ᠳᠠᠷᠠᠭᠠ ᠨᠢ ᠨᠢᠭᠡ ᠢᠨᠢᠶᠡᠵᠦ ᠪᠠᠶᠪᠠᠯ ᠳᠠᠷᠤᠢ ᠰᠢᠯᠭᠠᠵᠤ ᠪᠠᠷᠠᠬᠤ ᠪᠣᠯᠤᠨᠠ ",
    "gui.extension.sen0251.name": "BMP388 ᠠᠭᠠᠷ ᠤᠨ ᠳᠠᠷᠤᠯᠲᠠ ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sen0251.description": "ᠳᠤᠯᠠᠭᠠᠴᠠ᠂ ᠳᠠᠷᠤᠯᠲᠠ᠂ ᠳᠠᠯᠠᠢ ᠶᠢᠨ ᠲᠦᠪᠰᠢᠨ ᠢ ᠬᠡᠮᠵᠢᠨ᠎ᠡ",
    "gui.extension.sen0206.name": "ᠬᠦᠷᠡᠯᠴᠡᠭᠡᠲᠦ ᠪᠣᠰᠤ ᠤᠯᠠᠭᠠᠨ ᠤ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠲᠤᠶᠠᠭᠠᠨ ᠤ ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ",
    "gui.extension.sen0206.description": "Far-infrared non-contact measurement of object or ambient temperature",
    "gui.gui.recentlyEdited": "ᠤᠢᠷ᠎ᠠ ᠳᠤ ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠭᠰᠠᠨ",
    "gui.extension.weather.name": "ᠴᠠᠭ ᠠᠭᠤᠷ ᠤᠨ ᠪᠠᠢᠳᠠᠯ ᠢ ᠮᠡᠳᠡᠬᠦ",
    "gui.extension.weather.description": "ᠴᠠᠭ ᠠᠭᠤᠷ ᠤᠨ ᠪᠠᠢᠳᠠᠯ ᠢ ᠮᠡᠳᠡᠬᠦ᠂ Wi-Fi ᠲᠠᠢ ᠬᠡᠷᠡᠭᠯᠡᠬᠦ",
    "gui.extension.weather.description.javascript": "通过网络获取天气信息，电脑需要联网",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "TinyWebDB ᠰᠦᠯᠵᠢᠶᠡᠨ ᠦ ᠺᠣᠷᠫᠠᠰ ᠢ ᠡᠵᠡᠮᠳᠡᠬᠦ᠂ APP Inventor ᠲᠠᠢ ᠬᠠᠮᠲᠤ ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠪᠣᠯᠤᠨ᠎ᠠ",
    "gui.extension.pictureai.name": "AI图像识别",
    "gui.extension.pictureai.description": "使用图像AI，可以识别生活中有趣的东西，需要连接网络使用",
    "gui.extension.iflytek.description": "IFLYTEK",
    "gui.gui.variableScopeOptionAllSprites": "ᠪᠦᠬᠦ ᠳᠦᠷᠢ ᠳᠦ ᠬᠡᠷᠡᠭᠯᠡᠭᠦᠯᠬᠦ",
    "gui.gui.variableScopeOptionSpriteOnly": "ᠣᠳᠤᠬᠠᠨ ᠤ ᠳᠦᠷᠢ ᠳᠦ ᠯᠡ ᠬᠡᠷᠡᠭᠯᠡᠭᠦᠯᠬᠦ",
    "gui.gui.variablePromptAllSpritesMessage": "ᠲᠤᠰ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠢ ᠪᠦᠬᠦ ᠳᠦᠷᠢ ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠪᠣᠯᠤᠨ᠎ᠠ",
    "gui.monitor.contextMenu.default": "ᠡᠩ ᠦᠨ ᠢᠯᠡᠷᠡᠭᠦᠯᠲᠡ",
    "gui.monitor.contextMenu.large": "ᠲᠣᠮᠤᠰᠬᠠᠭᠰᠠᠨ ᠢᠯᠡᠷᠡᠭᠦᠯᠲᠡ",
    "gui.monitor.contextMenu.slider": "ᠭᠤᠯᠭᠤᠬᠤ ᠪᠠᠭᠠᠨ᠎ᠠ",
    "gui.monitor.contextMenu.sliderRange": "改变滑块范围",
    "gui.monitor.contextMenu.import": "ᠣᠷᠤᠭᠤᠯᠬᠤ",
    "gui.monitor.contextMenu.export": "ᠭᠠᠷᠭᠠᠬᠤ",
    "gui.monitor.listMonitor.listLength": "长度{length}",
    "gui.monitor.listMonitor.empty": "(空)",
    "gui.costumeTab.backdrop": "ᠳᠡᠪᠢᠰᠭᠡᠷ",
    "gui.costumeTab.costume": "ᠲᠦᠷᠬᠦ",
    "gui.opcodeLabels.direction": "ᠴᠢᠭᠯᠡᠯ",
    "gui.opcodeLabels.xposition": "x ᠪᠠᠢᠷᠢᠰᠢᠯ",
    "gui.opcodeLabels.yposition": "y ᠪᠠᠢᠷᠢᠰᠢᠯ",
    "gui.opcodeLabels.size": "ᠶᠡᠬᠡ ᠪᠠᠭ᠎ᠠ",
    "gui.opcodeLabels.costumename": "ᠲᠦᠷᠬᠦ ᠶᠢᠨ ᠨᠡᠷᠡᠢᠳᠦᠯ",
    "gui.opcodeLabels.costumenumber": "ᠲᠦᠷᠬᠦ ᠶᠢᠨ ᠨᠤᠮᠧᠷ",
    "gui.opcodeLabels.backdropname": "ᠳᠡᠪᠢᠰᠭᠡᠷ ᠦᠨ ᠨᠡᠷᠡᠢᠳᠦᠯ",
    "gui.opcodeLabels.backdropnumber": "ᠳᠡᠪᠢᠰᠭᠡᠷ ᠦᠨ ᠨᠣᠮᠧᠷ",
    "gui.opcodeLabels.volume": "ᠳᠠᠭᠤᠨ ᠤ ᠬᠡᠮᠵᠢᠶ᠎ᠡ",
    "gui.opcodeLabels.tempo": "ᠬᠥᠭᠵᠢᠮᠳᠡᠬᠦ ᠬᠤᠷᠳᠤᠴᠠ",
    "gui.opcodeLabels.answer": "ᠬᠠᠷᠢᠭᠤᠯᠲᠠ",
    "gui.opcodeLabels.loudness": "ᠳᠤᠤᠭᠠᠷᠴᠠ",
    "gui.opcodeLabels.year": "ᠤᠨ",
    "gui.opcodeLabels.month": "ᠰᠠᠷ᠎ᠠ",
    "gui.opcodeLabels.date": "ᠡᠳᠦᠷ",
    "gui.opcodeLabels.dayofweek": "ᠭᠠᠷᠠᠭ ᠤᠨ",
    "gui.opcodeLabels.hour": "ᠴᠠᠭ",
    "gui.opcodeLabels.minute": "ᠮᠢᠨᠦ᠋ᠲ",
    "gui.opcodeLabels.second": "ᠰᠧᠺᠦᠨ᠋ᠳ᠋",
    "gui.opcodeLabels.timer": "ᠴᠠᠭ ᠬᠡᠮᠵᠢᠭᠦᠷ",
    "gui.loadProject.error": "ᠠᠴᠢᠶᠠᠯᠠᠵᠤ ᠳᠡᠢᠯᠦᠭᠰᠡᠨ ᠦᠭᠡᠢ",
    "gui.fileSystem.fileCatalogue": "ᠹᠠᠢᠯ ᠤᠨ ᠭᠠᠷᠴᠠᠭ",
    "gui.fileSystem.boardFiles": "ᠡᠵᠡᠮᠰᠢᠯ ᠬᠠᠪᠲᠠᠰᠤᠨ ᠤ ᠹᠠᠢᠯ",
    "gui.fileSystem.boardFilesPico": "Pico中的文件",
    "gui.fileSystem.boardFiles2": "RMTT中的文件",
    "gui.fileSystem.boardFilesMaixduino": "Maixduino中的文件",
    "gui.fileSystem.boardFilesBoson": "BOSON MC1",
    "gui.fileSystem.computerFiles": "ᠺᠣᠮᠫᠢᠦ᠋ᠲ᠋ᠧᠷ ᠲᠡᠬᠢ ᠹᠠᠢᠯ",
    "gui.fileSystem.userExFiles": "用户库中的文件",
    "gui.fileSystem.newFile": "ᠰᠢᠨ᠎ᠡ ᠹᠠᠢᠯ ᠪᠠᠢᠭᠤᠯᠬᠤ",
    "gui.fileSystem.newFolder": "ᠰᠢᠨ᠎ᠡ ᠬᠠᠪᠴᠢᠭᠤᠷ ᠪᠠᠢᠭᠤᠯᠬᠤ",
    "gui.fileSystem.deleteFolder": "ᠬᠠᠪᠴᠢᠭᠤᠷ ᠢ ᠤᠰᠠᠳᠭᠠᠬᠤ",
    "gui.fileSystem.deleteFile": "删除",
    "gui.fileSystem.rename": "重命名",
    "gui.fileSystem.openFolder": "ᠬᠠᠪᠴᠢᠭᠤᠷ ᠤᠨ ᠪᠠᠢᠷᠢ ᠳᠤ ᠨᠡᠭᠡᠭᠡᠬᠦ",
    "gui.fileSystem.openFilePos": "打开文件位置",
    "gui.fileSystem.openInEditor": "ᠨᠠᠢᠷᠠᠭᠤᠯᠬᠤ ᠵᠠᠢ ᠳᠤ ᠨᠡᠭᠡᠭᠡᠬᠦ",
    "gui.fileSystem.runRightNow": "ᠳᠠᠷᠤᠢ ᠭᠦᠢᠴᠡᠳᠭᠡᠬᠦ",
    "gui.fileSystem.stopRun": "ᠭᠦᠢᠴᠡᠳᠭᠡᠬᠦ ᠪᠡᠨ ᠵᠣᠭᠰᠤᠬᠤ",
    "gui.fileSystem.setBoot": "ᠺᠣᠮᠫᠢᠦ᠋ᠲ᠋ᠧᠷ ᠨᠡᠭᠡᠭᠡᠬᠦ ᠪᠡᠷ ᠭᠦᠢᠴᠡᠳᠭᠡᠬᠦ",
    "gui.fileSystem.deleteForever": "ᠦᠨᠢᠳᠡ ᠤᠰᠠᠳᠭᠠᠬᠤ",
    "gui.fileSystem.saveAs": "ᠵᠢᠴᠢ ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ",
    "gui.fileSystem.undoAllEdits": "ᠪᠦᠬᠦ ᠵᠠᠯᠠᠷᠠᠭᠤᠯᠤᠯᠲᠠ ᠶᠢ ᠦᠭᠡᠢᠰᠭᠡᠬᠦ",
    "gui.fileSystem.copyToComputer": "ᠺᠣᠮᠫᠢᠦ᠋ᠲ᠋ᠧᠷ ᠳᠡᠭᠡᠷ᠎ᠡ ᠬᠠᠭᠤᠯᠪᠤᠷᠢᠳᠠᠬᠤ",
    "gui.fileSystem.uploadToBoard": "ᠡᠵᠡᠮᠰᠢᠯ ᠬᠠᠪᠲᠠᠰᠤᠨ ᠳᠤ ᠣᠷᠤᠭᠤᠯᠬᠤ",
    "gui.fileSystem.uploadAndRun": "ᠣᠷᠤᠭᠤᠯᠬᠤ ᠶᠢ ᠵᠡᠷᠭᠡ ᠪᠡᠷ ᠭᠦᠢᠴᠡᠳᠭᠡᠬᠦ",
    "gui.wifi.pwdNotLegal": "！错误提示：密码格式错误，Wi-Fi密码必须是8-20位的字母或数字",
    "gui.spreadmaqueen.perror": "# ᠪᠣᠷᠤᠭᠤᠳᠠᠭᠰᠠᠨ ᠰᠠᠨᠠᠭᠤᠯᠠᠮᠵᠢ᠄ led ᠳ᠋ᠧᠩ pin12 ᠢ ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠳᠡᠢᠯᠬᠦ ᠦᠭᠡᠢ᠂ pin8 ᠶᠢ ᠬᠡᠷᠡᠭᠯᠡᠭᠡᠷᠡᠢ᠃",
    "gui.huskylens.perror": "！错误提示：huskylens只支持microbit V2",
    "gui.infrared.perror": "！错误提示：红外发送只支持microbit V2",
    "gui.gui.viewConflict": "ᠳᠣᠶᠢᠭᠠᠳ ᠵᠦᠷᠢᠴᠡᠯ ᠢ ᠪᠠᠢᠴᠠᠭᠠᠭᠠᠷᠠᠢ",
    "gui.gui.clickViewHelp": "如何解决？",
    "gui.gui.conflict": "冲突",
    "gui.gui.conflictPrompt": "冲突警告提示",
    "gui.gui.clickPrompt": "单击高亮积木块，双击定位积木块",
    "gui.extension.mpyUART.name": "串口UART",
    "gui.extension.mpyUART.description": "使用硬串口进行通信",
    "gui.extension.mpyI2C.name": "I2C通信",
    "gui.extension.mpyI2C.description": "使用软件模拟I2C协议传输数据",
    "gui.extension.mpyServo.name": "舵机模块",
    "gui.extension.mpyServo.description": "可以在0～180度间转动到指定角度位置",
    "gui.extension.mpyTimer.name": "定时器",
    "gui.extension.mpyTimer.description": "可以定时执行程序",
    "gui.extension.mpyList.name": "列表",
    "gui.extension.mpyList.description": "列表",
    "gui.extension.mpyDictionary.name": "字典",
    "gui.extension.mpyDictionary.description": "字典",
    "gui.extension.mpySet.name": "集合",
    "gui.extension.mpySet.description": "集合",
    "gui.extension.mpyTuple.name": "元组",
    "gui.extension.mpyTuple.description": "元组",
    "gui.connection.auto-scanning.noPeripheralsFound": "没有发现任何设备",
    "gui.connection.auto-scanning.prescan": "把设备放在边上，然后开始搜索",
    "gui.connection.auto-scanning.pressbutton": "按下设备上的按钮",
    "gui.connection.auto-scanning.start-search": "开始搜索",
    "gui.connection.connecting-searchbutton": "正在搜索……",
    "gui.connection.auto-scanning.try-again": "重试",
    "gui.connection.connected": "已连接",
    "gui.connection.disconnect": "断开连接",
    "gui.connection.go-to-editor": "返回编辑器",
    "gui.connection.connecting-cancelbutton": "正在连接……",
    "gui.connection.error.errorMessage": "呀，好像出错了。",
    "gui.connection.error.tryagainbutton": "重试",
    "gui.connection.error.helpbutton": "帮助",
    "gui.connection.peripheral-name-label": "设备名称",
    "gui.connection.connect": "连接",
    "gui.connection.scanning.lookingforperipherals": "正在查找设备",
    "gui.connection.scanning.noPeripheralsFound": "没有发现任何设备",
    "gui.connection.scanning.instructions": "选择上面列出的设备。",
    "gui.connection.scanning.instructionsForTello": "请先将电脑WiFi连接Tello或RMTT，再选择上面列出的设备。",
    "gui.connection.connect.instructionsForTello": "请先在电脑WiFi列表中连接Tello或RMTT，再选择上面列出的设备。",
    "gui.connection.scanning.connectTelloHandle": "选择上面列出的设备，或手动连接飞机Wi-Fi。",
    "gui.connection.search": "刷新",
    "gui.connection.unavailable.installscratchlink": "确保Scratch Link已安装并正在运行",
    "gui.connection.unavailable.enablebluetooth": "请检查蓝牙已经开启",
    "gui.connection.unavailable.tryagainbutton": "重试",
    "gui.connection.unavailable.helpbutton": "帮助",
    "gui.extension.ev3.description": "搭建交互机器人等。",
    "gui.extension.ev3.connectingMessage": "正在连接。请确保EV3上的识别码(PIN)已经设置为1234。",
    "gui.extension.boost.description": "生动有趣的机器人创作",
    "gui.extension.boost.connectingMessage": "正在连接",
    "gui.extension.wedo2.description": "支持马达和传感器。",
    "gui.extension.wedo2.connectingMessage": "正在连接",
    "gui.extension.huskyLensPro.name": "HUSKYLENS教育版",
    "gui.extension.huskyLensPro.description": "使用HUSKYLENS直连电脑，学习AI人工智能基础知识",
    "gui.extension.huskyLensPro.connectingMessage": "HUSKYLENS正在努力请求连接中，请稍候",
    "gui.extension.machinelearning.name": "机器学习 (ML5)",
    "gui.extension.machinelearning.description": "基于机器学习算法，可以脱离网络实现人工智能应用",
    "gui.extension.huskylens.pcsetting": "配置电脑端摄像头",
    "gui.extension.huskylens.visualmodal": "AI可视化工具",
    "gui.extension.huskylens.KNNText": "电脑端摄像头模拟KNN分类",
    "gui.extension.huskylens.huskylensbasic": "基础功能",
    "gui.extension.huskylens.algorithmOperationLabel": "算法操作",
    "gui.extension.huskylens.displayFunctionLabel": "显示功能",
    "gui.extension.huskylens.cameraFunctionLabel": "拍照功能",
    "gui.extension.huskylens.modelOperationLabel": "模型操作",
    "gui.huskyLensPro.toConnect": "请先连接HUSKYLENS教育版",
    "gui.huskyLensPro.toClassificationData": "请先添加分类数据",
    "gui.huskyLensPro.startDeployment": "开始部署",
    "gui.huskyLensPro.switchingAlgorithmFailed": "无法切换到“物体分类”算法！",
    "gui.huskyLensPro.forgettingFailure": "无法遗忘学习数据！",
    "gui.huskyLensPro.failedToSetCategoryLabel": "设置“分类标签”失败！",
    "gui.huskyLensPro.requestFailed": "请求“部署”失败！",
    "gui.huskyLensPro.deploymentFailed": "“部署数据”失败！",
    "gui.huskyLensPro.completed": "完成",
    "gui.huskyLensPro.countdown": "，预计[TIME]后完成",
    "gui.huskyLensPro.minutes": "分",
    "gui.huskyLensPro.seconds": "秒",
    "gui.huskyLensPro.deploying": "正在部署模型",
    "gui.huskyLensPro.disconnected": "连接已断开，终止部署，请检查USB连线！",
    "gui.huskyLensPro.huskylenMaker": "此功能只有教育版可用",
    "gui.huskyLensPro.updateVersion": "请连接教育版且固件版本不低于0.5.1，详情见\"帮助\"",
    "gui.huskyLensPro.failedRetry": "失败，请重试！",
    "gui.connection.auto-scanning.notFoundHuskylensTip": "连接超时，请确认本机已连接到HuskyLens",
    "gui.extension.tello.name": "RoboMaster TT(单机)",
    "gui.extension.tello.description": "搭载大疆顶尖飞控算法的教育无人机，极强的安全性与稳定性",
    "gui.extension.tello.connectingMessage": "正在连接...请确认已打开Tello",
    "gui.extension.telloGroup.name": "RoboMaster TT(组队)",
    "gui.extension.telloGroup.description": "搭载大疆顶尖飞控算法的教育无人机，极强的安全性与稳定性",
    "gui.connection.auto-scanning.notFoundTelloTip": "扫描超时，请确认已打开Tello",
    "gui.extension.AIVisualInterface.lable": "AI可视化工具",
    "gui.extension.AIVisualInterface.title": "暂无数据",
    "gui.extension.AIVisualInterface.imgCount": "[COUNT]张图片",
    "gui.extension.AIVisualInterface.delete": "删除",
    "gui.extension.AIVisualInterface.classificationCount": "共计[COUNT]个分类",
    "gui.extension.AIVisualInterface.recognitionResult": "画面识别结果为:",
    "gui.extension.AIVisualInterface.possibility": "可能性为:",
    "gui.extension.AIVisualInterface.addClass": "添加分类",
    "gui.extension.AIVisualInterface.startTraining": "开始训练",
    "gui.extension.AIVisualInterface.prediction": "预测视频图片",
    "gui.extension.AIVisualInterface.retrain": "重新训练",
    "gui.extension.AIVisualInterface.export": "导出模型",
    "gui.dialog.baiduServer.togglePersonalAccount": "当前人声的可用次数已到达上限，请切换至个人账户使用，并登录控制台检查额度",
    "gui.extension.ai.name": "人工智能",
    "gui.extension.AI.description": "支持模型调用与训练",
    "gui.extension.machineVision.name": "机器视觉",
    "gui.extension.machineVision.description": "图像处理、绘制、滤波、识别形状、颜色、维码、特征",
    "gui.extension.machineHearing.name": "机器听觉",
    "gui.extension.machineHearing.description": "通过训练实现语音识别",
    "gui.extension.maixduino.name": "Maixduino",
    "gui.extension.maixduino.description": "基于K210芯片的主控板",
    "gui.extension.boson.name": "Boson MC1",
    "gui.extension.boson.description": "Boson MC1主控板控制的设备",
    "gui.extension.esp8266.name": "FireBeetle ESP8266",
    "gui.extension.esp8266.description": "FireBeetle ESP8266主控板控制的设备",
    "gui.extension.pico.name": "Raspberry Pi Pico",
    "gui.extension.pico.description": "Raspberry Pi Pico主控板控制的设备",
    "gui.extension.pyTurtle.name": "海龟绘图",
    "gui.extension.pyTurtle.description": "脑洞万千，尺幅之间",
    "gui.extension.pyGenerator.name": "代码生成器",
    "gui.extension.pyGenerator.description": "直接在图像积木上书写代码实现图形化与代码混编",
    "gui.extension.unihiker.name": "行空板",
    "gui.extension.unihiker.description": "可以运行完整python的新一代学习板",
    "gui.extension.python.save": "保存",
    "gui.extension.python.execute": "运行",
    "gui.extension.python.pipmanger": "库管理",
    "gui.extension.python.filesystem": "文件系统",
    "gui.extension.python.fileinprogram": "项目中的文件",
    "gui.extension.python.fileinpc": "电脑中的文件",
    "gui.extension.python.terminal": "终端",
    "gui.extension.python.clearterminal": "清除输出",
    "gui.extension.python.tabcode": "代码",
    "gui.extension.python.piplist": "库列表",
    "gui.extension.python.commendatory": "推荐库",
    "gui.extension.python.handleinstall": "PIP模式",
    "gui.extension.python.selectall": "选择所有[COUNT]",
    "gui.extension.python.update": "更新",
    "gui.extension.python.uninstall": "卸载",
    "gui.extension.python.install": "安装",
    "gui.extension.python.installing": "安装中..",
    "gui.extension.python.installed": "已安装",
    "gui.extension.python.piphandleinstall": "PIP手动安装",
    "gui.extension.python.loadmore": "加载更多...",
    "gui.extension.python.hidemore": "隐藏更多...",
    "gui.extension.python.noavailable": "暂无可用库，快先去下载吧",
    "gui.extension.python.enterCommand": "请先输入库名称",
    "gui.extension.python.finishedtips": "命令运行完成",
    "gui.extension.python.stop": "停止",
    "gui.extension.python.executing": "运行中",
    "gui.extension.python.editorArea": "代码区",
    "gui.extension.python.toolbox": "图形区",
    "gui.extension.python.autoCreate": "自动生成",
    "gui.extension.python.emptyEditor": "点击右侧<b>项目中的文件</b>后面的<b>新建文件按钮</b>，创建一个属于你的.py文件",
    "gui.extension.python.userDocument": "帮助文档",
    "gui.extension.xiamiboard.name": "虾米扩展板",
    "gui.extension.xiamiboard.description": "基于microbit开发的多功能编程教育扩展板",
    "gui.gui.saveSb3ToCloud": "保存到云端",
    "gui.extension.pyMqtt.name": "MQTT-py",
    "gui.extension.pyMqtt.description": "使用电脑的网络，通过MQTT协议进行通讯",
    "gui.extension.pyGame.name": "Pygame",
    "gui.extension.pyGame.description": "用Python编写自己的游戏和动画",
    "gui.extension.pinpongBase.name": "pinpong初始化",
    "gui.extension.pinpongBase.description": "pinpong库初始化选择主控板功能和gpio",
    "gui.extension.pinpongServo.name": "舵机",
    "gui.extension.pinpongServo.description": "可以在0 ~ 180度间转动到指定角",
    "gui.extension.pinpongIrq.name": "引脚中断",
    "gui.extension.pinpongIrq.description": "pinpong库引脚中断功能",
    "gui.extension.pinpongTone.name": "蜂鸣器",
    "gui.extension.pinpongTone.description": "pinpong库蜂鸣器模块",
    "gui.extension.pinpongSr04_urm10.name": "超声波测距传感器",
    "gui.extension.pinpongSr04_urm10.description": "准确测距，量程2～800cm，兼容urm及SR04超声波",
    "gui.extension.pinpongDht.name": "DHT11/22温湿度传感器",
    "gui.extension.pinpongDht.description": "测量环境温度、湿度",
    "gui.extension.pinpongNeopixel.name": "WS2812 RGB灯",
    "gui.extension.pinpongNeopixel.description": "控制LED灯条变化产生如闪烁、流水、跳变等效果",
    "gui.extension.pinpongOled12864.name": "OLED显示屏",
    "gui.extension.pinpongOled12864.description": "小体积显示屏，可用于智能穿戴设备的显示器",
    "gui.extension.pinpongDS18B20.name": "DS18B20温度传感器",
    "gui.extension.pinpongDS18B20.description": "测量温度，范围－55～＋125℃",
    "gui.extension.pinpongMlx90614.name": "非接触式红外温度传感器",
    "gui.extension.pinpongMlx90614.description": "远程红外非接触式测量物体或环境温度",
    "gui.extension.pinpongBmp388.name": "BMP388气压温度传感器",
    "gui.extension.pinpongBmp388.description": "具有温度、大气压、海拔检测功能",
    "gui.extension.pinpongIRRecv.name": "红外接收模块",
    "gui.extension.pinpongIRRecv.description": "可接收标准38KHz调制的红外信号",
    "gui.extension.PinpongBoard.description": "pinpong board扩展板，xxxxxxx",
    "gui.extension.pythonsen0334.name": "SHT31-F数字温湿度传感器",
    "gui.extension.pythonsen0334.description": "可测量温湿度，低功耗、响应速度快、抗干扰能力强",
    "gui.extension.pythonsen0303.name": "VEML6075 紫外线传感器",
    "gui.extension.pythonsen0303.description": "能够检测出UVA和UVB强度",
    "gui.extension.pythonsen0377.name": "MEMS 气体传感器",
    "gui.extension.pythonsen0377.description": "支持CO、C2H5OH(Alcohol)H2、NO2、NH3等多种气体浓度的检测",
    "gui.extension.pythonsen0460.name": "空气质量传感器",
    "gui.extension.pythonsen0460.description": "Gravity接口的空气质量传感器，可测量PM2.5值",
    "gui.extension.obloqMp.name": "OBLOQ物联网模块",
    "gui.extension.obloqMp.description": "通过Wi-Fi接收和发送物联网信息",
    "gui.extension.pyAutoGui.name": "PyAutoGui",
    "gui.extension.pyAutoGui.description": "PyAutoGUI允许Python脚本控制鼠标和键盘，以自动化与其他应用程序的交互",
    "gui.extension.pyRequests.name": "Requests",
    "gui.extension.pyRequests.description": "让 HTTP 服务人类",
    "gui.extension.pyJson.name": "JSON",
    "gui.extension.pyJson.description": "JSON 编码和解码器",
    "gui.extension.pySchedule.name": "Schedule",
    "gui.extension.pySchedule.description": "面向人类的 Python 作业调度",
    "gui.extension.pyXlrd.name": "xlrd",
    "gui.extension.pyXlrd.description": "execl读取, 只支持xls文件",
    "gui.extension.pyXlwt.name": "xlwt",
    "gui.extension.pyXlwt.description": "execl写入, 只支持xls文件",
    "gui.extension.pyOpenCV.name": "OpenCV",
    "gui.extension.pyOpenCV.description": "OpenCV是一个包含数百种计算机视觉算法的开源库",
    "gui.extension.pyBaiDuAi.name": "百度人脸识别",
    "gui.extension.pyBaiDuAi.description": "使用百度AI提供的接口实现人脸识别",
    "gui.extension.pyBaiDuImage.name": "百度图像识别",
    "gui.extension.pyBaiDuImage.description": "使用百度AI提供的接口实现图像识别",
    "gui.extension.pyBaiDuSpeech.name": "百度语音",
    "gui.extension.pyBaiDuSpeech.description": "百度语音提供语音识别、语音合成等多种智能服务",
    "gui.extension.pyXfSpeech.name": "讯飞语音",
    "gui.extension.pyXfSpeech.description": "讯飞语音提供语音合成和语音识别功能",
    "gui.account.dialog.prompt": "提示",
    "gui.account.dialog.tips": "提示",
    "gui.account.dialog.signoutAlert": "退出后将无法使用云存储等功能，确定退出吗？",
    "gui.account.dialog.signoutSuccess": "已退出登录",
    "gui.account.dialog.sure": "确定",
    "gui.account.dialog.cancel": "取消",
    "gui.account.dialog.error": "错误",
    "gui.account.dialog.save": "保存",
    "gui.account.dialog.getCloudFileFailed": "获取云文件「[NAME]」出错，请检查文件是否存在",
    "gui.account.dialog.getCloudError": "获取云文件「[NAME]」出错",
    "gui.account.dialog.loadCloudSuccess": "加载云文件「[NAME]」成功",
    "gui.account.dialog.saveCloudError": "保存「[NAME]」到云端失败，请稍后尝试。",
    "gui.account.dialog.saveCloudSuccess": "保存「[NAME]」到云端成功",
    "gui.account.dialog.saveCloudCheckError": "保存失败，校验云端文件出错，请稍后再试",
    "gui.account.dialog.saveCloudRepeatPrompt": "云端已存在同名文件「[NAME]」，是否覆盖？",
    "gui.account.dialog.saveCloudRepeatAlert": "云端已存在同名文件「[NAME]」，请换个名字",
    "gui.account.dialog.saveCover": "覆盖",
    "gui.account.dialog.rename": "重命名",
    "gui.account.dialog.newProject": "新建文件",
    "gui.account.dialog.projectName": "项目名称",
    "gui.account.dialog.enterName": "请输入文件名",
    "gui.account.dialog.nameWithSymbol": "文件名不能包含特殊字符, 请更改",
    "gui.account.dialog.nameBeyondLength": "文件名长度不能超过30",
    "gui.account.dialog.renameSuccess": "重命名文件「[NAME1]」为「[NAME2]」成功",
    "gui.account.dialog.renameFailed": "重命名文件失败",
    "gui.account.dialog.downloadSuccess": "「[NAME]」下载完成",
    "gui.account.dialog.cancelDownload": "取消下载",
    "gui.account.dialog.deleteSucess": "删除云文件「[NAME]」成功",
    "gui.account.dialog.saveAs": "另存为",
    "gui.account.dialog.deleteFailed": "删除云文件「[NAME]」出错",
    "gui.account.dialog.deletePrompt": "确认删除云文件「[NAME]」吗？",
    "gui.account.open": "打开",
    "gui.account.upload": "上传",
    "gui.account.delete": "删除",
    "gui.account.usersSpace": "[NAME]的云端项目",
    "gui.account.cloudDataLoading": "云端数据加载中，请稍候",
    "gui.account.emptyUserSpace": "这里是空的，快去上传你的第一个项目吧。",
    "gui.account.dialog.login": "登录",
    "gui.account.dialog.signup": "注册",
    "gui.account.dialog.phoneNumber": "手机号",
    "gui.account.dialog.password": "密码",
    "gui.account.dialog.autoLogin": "记住密码，自动登录",
    "gui.account.dialog.forgotPasswd": "忘记密码?",
    "gui.account.dialog.getCheckCode": "获取验证码",
    "gui.account.dialog.reSend": "重新发送",
    "gui.account.dialog.checkCode": "验证码",
    "gui.account.dialog.readAndAgree": "我已阅读并接受",
    "gui.account.dialog.userAgreement": "用户协议",
    "gui.account.dialog.loginSuccess": "登录成功",
    "gui.account.dialog.passwordError": "账号或密码错误，请检查填写是否正确",
    "gui.account.dialog.checkNumberSended": "验证码已发送，请注意查收",
    "gui.account.dialog.checkedAgree": "同意协议",
    "gui.account.dialog.networkError": "网络错误，请检查连接后重试",
    "gui.account.dialog.readProtocolAndCheck": "请先阅读用户协议并勾选同意",
    "gui.account.dialog.signupError": "注册出错，",
    "gui.account.dialog.logininErrorWithNetwork": "服务器连接出错，登录失败，请检查网络",
    "gui.account.dialog.signupErrorDefault": "注册失败，请重新操作",
    "gui.account.dialog.accountExist": "账号已存在，请直接登录",
    "gui.account.dialog.phoneCodeError": "注册失败，验证码错误",
    "gui.account.dialog.signupSuccess": "注册成功，请返回登录界面继续操作",
    "gui.account.dialog.inputEmpty": "不能为空",
    "gui.account.dialog.phoneFormatIllegal": "用户名格式错误",
    "gui.account.dialog.unsupportProject": "打开项目出错，不受支持的项目",
    "gui.account.dialog.shouldLoginFirst": "云服务需要你的账号信息，请先登录",
    "gui.account.dialog.loginTimeout": "登录超时",
    "gui.account.account": "账号",
    "gui.account.serverAddr": "服务器地址",
    "gui.account.thirdPartyAccount": "第三方账号",
    "gui.account.dialog.saveCloudRenamePrompt": "文件名[NAME]已存在，是否重命名?",
    "gui.python.remoteSSH.connectSuccess": "已成功连接到[ADDRESS]",
    "gui.python.remoteSSH.connectFailed": "连接[ADDRESS]失败",
    "gui.python.remoteSSH.shouleReconnect": "已与[ADDRESS]断开连接, 是否重新连接？",
    "gui.python.remoteSSH.reconnect": "重新连接",
    "gui.python.remoteSSH.disconnected": "已与[ADDRESS]断开连接",
    "gui.python.remoteSSH.accountCheckFailed": "账号信息错误，与[ADDRESS]连接失败，请检查后输入",
    "gui.python.remoteSSH.connectError": "与[ADDRESS]连接出错，[MSG]，解决办法请查看帮助文档",
    "gui.python.remoteSSH.sshLogin": "SSH登录",
    "gui.python.remoteSSH.address": "地址",
    "gui.python.remoteSSH.addressCantEmpty": "地址不能为空",
    "gui.python.remoteSSH.user": "用户名",
    "gui.python.remoteSSH.userCantEmpty": "用户名不能为空",
    "gui.python.remoteSSH.passwd": "密码",
    "gui.python.remoteSSH.passwdCantEmpty": "密码不能为空",
    "gui.python.remoteSSH.areYouSureDisconnect": "当前已连接到远程[ADDRESS]，确认断开吗？",
    "gui.python.remoteSSH.statusConnecting": "连接中",
    "gui.python.remoteSSH.disconnectDevice": "断开远程终端",
    "gui.python.remoteSSH.connectDevice": "连接远程终端",
    "gui.python.remoteSSH.statusError": "连接出错",
    "gui.python.remoteSSH.statusDisconnect": "连接已断开",
    "gui.python.remoteSSH.handleInput": "手动输入",
    "gui.python.remoteSSH.openNetNetWork": "打开网络中心",
    "gui.python.remoteSSH.goHelp": "帮助文档",
    "gui.python.remoteSSH.waitForConnecting": "正在连接到[ADDRESS], 请稍候...",
    "gui.python.remoteSSH.cancelConnect": "取消连接",
    "gui.python.remoteSSH.connectTimeout": "连接超时",
    "gui.python.remoteSSH.addressIsLost": "地址不存在",
    "gui.python.remoteSSH.connectRefused": "请求被拒绝，请检查远程系统设置后重试",
    "gui.python.remoteSSH.runGUIProgramTips": "运行图形化程序时，如果想在远程桌面查看效果，需使用远程系统当前登录的用户名",
    "gui.python.remoteSSH.executeFileError": "运行项目出错",
    "gui.python.remoteSSH.waitForProjectUpload": "项目[NAME]正在上传中，请稍后...",
    "gui.python.remoteSSH.projectUploadSuccess": "项目上传成功，开始运行",
    "gui.python.remoteSSH.projectUploadError": "项目上传出错",
    "gui.python.filetree.boardNameInFT": "[BOARD]中的文件",
    "gui.python.filetree.replaceFilePrompt": "目标文件夹中已经存在名称为[NAME]的文件或文件夹。是否要替换它？",
    "gui.python.filetree.fileUploading": "上传[NAME]文件...",
    "gui.python.filetree.fileSaving": "保存[NAME]文件...",
    "gui.python.filetree.dirSaving": "保存[NAME]文件夹...",
    "gui.python.filetree.saveAs": "另存为",
    "gui.python.filetree.chooseDir": "选择文件夹",
    "gui.python.filetree.getDirList": "获取目录...",
    "gui.python.filetree.getDirListWithPath": "读取[PATH]目录",
    "gui.python.filetree.getRemoteDirList": "读取远程[PATH]目录",
    "gui.python.filetree.renameFile": "重命名[FROM]为[TO]",
    "gui.python.filetree.deleteDirPrompt": "确定删除文件夹[NAME]及其包含的文件吗？",
    "gui.python.filetree.deleteFilePrompt": "确定删除文件[NAME]吗？",
    "gui.python.filetree.downloadToPC": "下载文件到电脑",
    "gui.python.filetree.uploadToRemote": "上传文件到",
    "gui.python.filetree.exportFileTo": "导出[EXT]文件",
    "gui.python.filetree.rmFromeFileSystem": "从文件系统中移除",
    "gui.python.filetree.rmDirPrompt": "确定移除本地路径[NAME]及其子文件吗？",
    "gui.python.filetree.rmAllDirPrompt": "确定移除所有本地路径吗？",
    "gui.python.filetree.addLocalDir": "添加本地文件夹",
    "gui.python.filetree.removeActionDirWithAll": "移除所有本地文件夹",
    "gui.python.filetree.fileNameIsRequired": "必须提供文件或文件夹名",
    "gui.python.filetree.fileNameIsRepeat": "此位置已经存在文件或文件夹[NAME]。请选择其他名称。",
    "gui.python.filetree.dragParentNodeToChild": "父文件夹无法移动到子文件夹",
    "gui.python.filetree.dragPathIsConsistent": "文件拖动路径前后一致",
    "gui.python.filetree.fileOperateError": "文件操作出错",
    "gui.python.filetree.fileTreeMore": "更多",
    "gui.python.filetree.copy": "复制",
    "gui.python.filetree.paste": "粘贴",
    "gui.python.worker.initPythonHomeDir": "正在初始化文件夹...",
    "gui.python.worker.copyPythonSource": "初始化文件夹完成，正在复制Python资源文件...",
    "gui.python.worker.copyPythonSourceError": "文件操作出错，初始化Python环境失败，请重新进入Python模式或重启软件",
    "gui.python.worker.unzipPythonSource": "复制资源文件完成，正在解压...",
    "gui.python.worker.checkPythonSource": "解压完成，正在验证文件...",
    "gui.python.worker.unzipPythonSourceError": "解压出错，初始化Python环境失败，请重新进入Python模式或重启软件",
    "gui.python.xterm.local": "本地",
    "gui.python.xterm.detectPythonEnv": "正在检测环境，请稍后....",
    "gui.python.xterm.installingPythonEnv": "\r\n正在安装环境，请稍后....",
    "gui.python.xterm.installPythonEnvError": "Python环境初始化失败，请联系技术支持",
    "gui.python.xterm.detectPythonEnvFinished": "\r\n环境检测完成，启动中...\r\n",
    "gui.python.xterm.installPythonEnvFinished": "\r\n环境安装完成，启动中...\r\n",
    "gui.python.xterm.toRestartXterm": "双击重启终端",
    "gui.python.xterm.showRemoteConnect": "开启远程连接终端",
    "gui.python.xterm.remoteConnectShowed": "远程连接终端已打开",
    "gui.dialog.thirdExt.openPipManger": "打开库管理",
    "music.categoryName": "ᠠᠶ᠎ᠠ",
    "translate.categoryName": "ᠣᠷᠴᠢᠭᠤᠯᠬᠤ",
    "sensor.categoryName": "ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "actuator.categoryName": "ᠬᠡᠷᠡᠭᠵᠢᠭᠦᠯᠦᠭᠦᠷ ",
    "communicate.categoryName": "ᠬᠣᠯᠪᠣᠭᠠᠨ ᠤ ᠬᠡᠰᠡᠭᠲᠦ",
    "display.categoryName": "ᠢᠯᠡᠷᠡᠭᠦᠯᠦᠭᠴᠢ ",
    "extendFunction.categoryName": "ᠴᠢᠳᠠᠪᠤᠷᠢ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ ",
    "internetService.categoryName": "ᠰᠦᠯᠵᠢᠶᠡᠨ ᠦ ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠡ ",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "calliope.categoryName": "CALLIOPE",
    "esp32.categoryName": "ᠡᠵᠡᠮᠰᠢᠬᠦ",
    "telloesp32.categoryName": "RMTT",
    "spread.categoryName": "ᠥᠷᠭᠡᠳᠬᠡᠯ ᠬᠠᠪᠲᠠᠰᠤ",
    "speechRecognition.categoryName": "ᠠᠪᠢᠶ᠎ᠠ ᠲᠠᠨᠢᠬᠤ",
    "thirdExpansion.categoryName": "用户库",
    "gui.blocklyText.blockTitle.eventBlock": "事件程序",
    "gui.blocklyText.blockTitle.dotScreen": "点阵屏",
    "gui.blocklyText.blockTitle.music": "音乐",
    "gui.blocklyText.blockTitle.onBoardSensor": "板载传感器",
    "gui.blocklyText.blockTitle.onBoardFunc": "板载功能",
    "gui.blocklyText.blockTitle.pinOperation": "引脚操作",
    "gui.blocklyText.blockTitle.wirelessCommunication": "无线通信",
    "gui.blocklyText.blockTitle.serialportOperation": "串口操作",
    "gui.blocklyText.blockTitle.systemResource": "系统资源",
    "gui.blocklyText.blockTitle.screenDisplay": "屏幕显示",
    "gui.blocklyText.blockTitle.ledControl": "LED控制",
    "gui.blocklyText.blockTitle.functionalModule": "功能模块",
    "gui.blocklyText.blockTitle.movement": "运动",
    "gui.blocklyText.blockTitle.expression": "表情",
    "gui.blocklyText.blockTitle.light": "灯光",
    "gui.blocklyText.blockTitle.dance": "舞蹈",
    "gui.blocklyText.blockTitle.sensor": "传感器",
    "gui.blocklyText.blockTitle.motorControl": "电机控制",
    "gui.blocklyText.blockTitle.kitSensor": "套件传感器",
    "gui.blocklyText.blockTitle.kitActuator": "套件执行器",
    "gui.blocklyText.blockTitle.commonModule": "常用模块",
    "gui.blocklyText.blockTitle.infraredCommunication": "红外通信",
    "gui.blocklyText.blockTitle.mp3Module": "mp3模块",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 RGB灯",
    "gui.blocklyText.blockTitle.LCDDisplay": "LCD显示屏",
    "gui.blocklyText.blockTitle.digitalTube": "数码管",
    "gui.blocklyText.blockTitle.oledDisplay": "OLED显示屏",
    "gui.blocklyText.blockTitle.onBoardSource": "板载资源",
    "gui.blocklyText.blockTitle.rgbControl": "RGB灯控制",
    "gui.blocklyText.blockTitle.maxbotResource": "max:bot资源",
    "gui.blocklyText.blockTitle.lineInspection": "巡线",
    "gui.blocklyText.blockTitle.outerSensor": "外置传感器",
    "gui.blocklyText.blockTitle.consolePrint": "控制台输出",
    "gui.blocklyText.blockTitle.radio": "无线广播",
    "gui.blocklyText.blockTitle.wifi": "wifi",
    "gui.blocklyText.blockTitle.motor": "电机控制",
    "gui.blocklyText.blockTitle.rgblightcontrol": "板载led",
    "gui.blocklyText.blockTitle.vibrationMotor": "振动马达",
    "gui.blocklyText.romeo.d1ProgramStart": "D1 ᠮᠠᠰᠢᠨ ᠬᠥᠮᠦᠨ ᠦ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.romeo.readdigital": "[PIN] ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [MODULE] ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.romeo.buttonModule": "ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ ",
    "gui.blocklyText.romeo.digitalSensor": "ᠲᠣᠭᠠᠨ ᠤ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.romeo.collisionSensor": "ᠮᠥᠷᠭᠥᠯᠲᠡ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.romeo.lineSensor": "ᠱᠤᠭᠤᠮ ᠮᠥᠷᠳᠡᠬᠦ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.romeo.infraredSwitch": "ᠤᠯᠠᠭᠠᠨ ᠤ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠨᠡᠭᠡᠭᠡᠯᠭᠡ ",
    "gui.blocklyText.romeo.vibrationSensor": "ᠳᠣᠷᠭᠢᠯᠲᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.romeo.readAnalog": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] [SENSOR] ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.romeo.lightSensor": "ᠭᠡᠷᠡᠯ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.romeo.anologSensor": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.romeo.soundSensor": "ᠳᠠᠭᠤᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.romeo.soilMoistureSensor": "ᠬᠥᠷᠥᠰᠥᠨ ᠦ ᠴᠢᠭᠢᠭᠲᠦᠴᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.romeo.frameSensor": "ᠳᠥᠯᠥ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.romeo.open": "ᠨᠡᠭᠡᠭᠡᠬᠦ ",
    "gui.blocklyText.romeo.close": "ᠬᠠᠭᠠᠬᠤ ",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [PIN] ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [MODULE] ",
    "gui.blocklyText.romeo.ledLights": "LED ᠳ᠋ᠧᠩ ",
    "gui.blocklyText.romeo.digitalActuator": "ᠲᠣᠭᠠᠨ ᠤ ᠬᠡᠷᠡᠭᠵᠢᠭᠦᠯᠦᠭᠦᠷ ",
    "gui.blocklyText.romeo.relay": "ᠲᠠᠲᠠᠭᠳᠠᠭᠤᠷ ",
    "gui.blocklyText.romeo.fan": "ᠰᠠᠯᠬᠢᠨ ᠰᠠᠩᠰᠠ ",
    "gui.blocklyText.romeo.writeAnalog": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] [MODULE] ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠨᠢ [VALUE] ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "gui.blocklyText.romeo.simulationActuator": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ ᠬᠡᠷᠡᠭᠵᠢᠭᠦᠯᠦᠭᠦᠷ ",
    "gui.blocklyText.romeo.fanModule": "ᠰᠠᠩᠰᠠ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ ",
    "gui.blocklyText.romeo.robotSpeedMove": "ᠮᠠᠰᠢᠨ ᠬᠥᠮᠦᠨ [SPEED] ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR]",
    "gui.blocklyText.romeo.forward": "ᠤᠷᠤᠭᠰᠢᠯᠠᠬᠤ ",
    "gui.blocklyText.romeo.backward": "ᠤᠬᠤᠷᠢᠬᠤ ",
    "gui.blocklyText.romeo.turnLeft": "ᠵᠡᠭᠦᠨᠰᠢ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.romeo.turnRight": "ᠪᠠᠷᠠᠭᠤᠨᠰᠢ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ [MOTOR] ᠪᠠᠶᠢᠷᠢᠯᠠᠭᠤᠯᠬᠤ [SPEED] ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR] ",
    "gui.blocklyText.romeo.interface": "ᠠᠭᠤᠯᠵᠠᠷ ",
    "gui.blocklyText.romeo.motorForward": "ᠵᠥᠪ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.romeo.motorReverse": "ᠭᠡᠳᠡᠷᠭᠦ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.romeo.stopMotor": "ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ (M1 ᠪᠣᠯᠤᠨ M2 ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ) ᠵᠣᠭᠰᠣᠬᠤ ",
    "gui.blocklyText.romeo.readUltrasonic": "ᠬᠡᠳᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ trig ᠨᠢ [TRIG] echo ᠨᠢ [ECHO]",
    "gui.blocklyText.romeo.lm35": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ (LM35)  ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.romeo.ds18b20": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ (DS18B20)  ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.romeo.pressInfrared": "ᠬᠡᠳᠦ ᠤᠯᠠᠭᠠᠨ [PIN] ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠢ [BUTTON] ᠳᠠᠷᠤᠵᠤ ᠠᠪᠬᠤ ᠤᠤ ? ",
    "gui.blocklyText.romeo.volumePlus": "ᠳᠠᠭᠤᠨ ᠤ ᠬᠡᠮᠵᠢᠶᠡ+",
    "gui.blocklyText.romeo.switch": "ᠨᠡᠭᠡᠭᠡᠯᠭᠡ ",
    "gui.blocklyText.romeo.volumeMinus": "ᠳᠠᠭᠤᠨ ᠤ ᠬᠡᠮᠵᠢᠶᠡ-",
    "gui.blocklyText.romeo.startPause": "ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ / ᠲᠦᠷ ᠵᠣᠭᠰᠣᠭᠠᠬᠤ ",
    "gui.blocklyText.romeo.up": "ᠳᠡᠭᠡᠷ᠎ᠡ ",
    "gui.blocklyText.romeo.down": "ᠳᠣᠣᠷ᠎ᠠ",
    "gui.blocklyText.romeo.dataReadable": "ᠵᠠᠮ ᠤᠨ ᠠᠭᠤᠯᠵᠠᠷ ᠳᠤ ᠤᠩᠰᠢᠬᠤ  ᠳ᠋ᠠᠢᠲ᠋ᠠ ᠲᠠᠶ ᠤᠤ︖",
    "gui.blocklyText.romeo.readSerialData": " ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠠᠮᠠᠰᠠᠷᠲᠤ ᠳ᠋ᠠᠶᠢᠲ᠋ᠠ ",
    "gui.blocklyText.romeo.timer": "ᠰᠢᠰᠲ᠋ᠧᠮ ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ ᠴᠠᠭ ",
    "gui.blocklyText.romeo.setBuzzerSound": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠤᠨ ᠪᠦᠷᠢᠶᠡᠨ ᠦ ᠬᠥᠭ [TONE] ᠬᠡᠮᠨᠡᠯ ᠨᠢ [BEAT] ",
    "gui.blocklyText.romeo.half": "1/2",
    "gui.blocklyText.romeo.quarter": "1/4",
    "gui.blocklyText.romeo.oneInEighth": "1/8 ",
    "gui.blocklyText.romeo.wholeBeat": "ᠪᠦᠬᠦᠯᠢ ᠴᠣᠬᠢᠯᠭ᠎ᠠ ",
    "gui.blocklyText.romeo.doubleBeat": "ᠬᠣᠣᠰ ᠴᠣᠬᠢᠯᠭ᠎ᠠ ",
    "gui.blocklyText.romeo.stop": "ᠵᠣᠭᠰᠣᠭᠠᠬᠤ ",
    "gui.blocklyText.romeo.setServo": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠦᠢᠯᠡᠴᠢᠯᠡᠬᠦ ᠵᠠᠯᠠᠭᠤᠷ ᠮᠠᠰᠢᠨ  ᠪᠠᠶᠢᠷᠢᠯᠠᠭᠤᠯᠬᠤ [DEGREE]",
    "gui.blocklyText.pythondfr0548.setServo": " 设置 [PIN] 引脚伺服舵机为 [DEGREE] 度",
    "gui.blocklyText.romeo.segment": "ᠨᠠᠶᠮᠠᠨ ᠪᠠᠳᠠᠭᠲᠤ ᠲᠣᠭᠠᠨ ᠤ ᠬᠣᠭᠣᠯᠠᠶ (D6-D13  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ) ᠶᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ ᠢᠯᠡᠷᠡᠭᠦᠯᠦᠯᠲᠡ [TUBE] ",
    "gui.blocklyText.romeo.serialSendString": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ [WAY] [STRING] [LINE]",
    "gui.blocklyText.romeo.originalOutput": "ᠠᠩᠬᠠᠨ ᠤ ᠭᠠᠷᠭᠠᠯᠲᠠ ",
    "gui.blocklyText.romeo.stringOutput": "ᠦᠰᠦᠭ ᠲᠡᠮᠲᠡᠭ ᠦᠨ ᠬᠡᠯᠬᠢᠶᠠᠨ  ᠤ ᠭᠠᠷᠭᠠᠯᠲᠠ",
    "gui.blocklyText.romeo.hexOutput": "ᠠᠷᠪᠠᠨ ᠵᠢᠷᠭᠤᠭᠠ ᠪᠠᠷ ᠳᠠᠪᠰᠢᠬᠤ ᠳᠦᠷᠢᠮ ᠤᠨ ᠭᠠᠷᠭᠠᠯᠲᠠ ",
    "gui.blocklyText.romeo.wrap": "ᠮᠥᠷ ᠰᠣᠯᠢᠬᠤ ",
    "gui.blocklyText.romeo.noWrap": "ᠮᠥᠷ ᠰᠣᠯᠢᠬᠤ ᠦᠭᠡᠢ ",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠠᠴᠠ [READTYPE] ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.arduinounoR3.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.romeo.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.max.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.maxbot.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.microbit.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.arduino.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.arduinoNano.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.mega2560.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.firebeetleesp32.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.romeo.int": "ᠪᠦᠬᠦᠯᠢ ᠲᠣᠭ᠎ᠠ",
    "gui.blocklyText.romeo.float": "ᠪᠤᠲᠠᠷᠬᠠᠢ ᠲᠣᠭ᠎ᠠ",
    "gui.blocklyText.romeo.setBaudRate": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠮ ᠤᠨ ᠪᠦᠲ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ ᠨᠤᠷᠮᠠ ᠨᠢ [BAUD] ",
    "gui.blocklyText.maqueen.robotServo": "ᠵᠠᠯᠠᠭᠤᠷ ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ [INDEX] ᠥᠨᠴᠥᠭ [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠤᠨ ᠯᠤᠭᠰᠢᠯ ᠤᠨ ᠬᠡᠪᠴᠢᠶᠡ ᠢ ᠤᠩᠰᠢᠬᠤ ᠬᠡᠪ ᠨᠢ [LEVEL] ᠴᠠᠭ ᠠᠴᠠ ᠬᠡᠲᠦᠷᠡᠭᠰᠡᠨ ᠴᠠᠭ [TIMEOUT] ",
    "gui.blocklyText.romeo.low": "ᠳᠣᠣᠷ᠎ᠠ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠦᠪᠰᠢᠨ ",
    "gui.blocklyText.romeo.high": "ᠥᠨᠳᠥᠷ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠦᠪᠰᠢᠨ ",
    "gui.blocklyText.romeo.noSound": "ᠳᠠᠭᠤ ᠦᠭᠡᠢ",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ",
    "gui.blocklyText.boson.starts": "BOSON MC1主程序",
    "gui.blocklyText.pico.starts": "Pico主程序",
    "gui.blocklyText.boson.mpyAttitudeEvent": "当BOSON MC1板 [ATTITUDE]",
    "gui.blocklyText.boson.mpyBoardShakeThrown": "BOSON MC1板 [ATTITUDE]?",
    "gui.blocklyText.boson.readAcceleration": "读取加速度的值(m-g) [TYPE]",
    "gui.blocklyText.boson.setAccelerationRange": "设置加速度范围为 [ACCRANGE]",
    "gui.blocklyText.boson.vibrationMotor": "[SWITCH] 振动马达",
    "gui.blocklyText.boson.vibrationMotorTime": "持续振动 [TIME] 秒",
    "gui.blocklyText.boson.displayClear.js": "屏幕清屏为 [COLOR]",
    "gui.blocklyText.boson.displayClear": "屏幕清屏为 [COLOR] [PUSH]",
    "gui.blocklyText.boson.displaySetLineWidth": "设置线宽为 [LINE] 方向 [DIRECTION]",
    "gui.blocklyText.boson.displayText": "屏幕显示文字 [TEXT] 在坐标x[X] y[Y] 颜色[COLOR] 字体[FONT] 背景色[BACKGROUND] [NOWRAP] ",
    "gui.blocklyText.boson.displayText.js": "屏幕显示文字 [TEXT] 在坐标x[X] y[Y] 颜色[COLOR] [NOWRAP]",
    "gui.blocklyText.boson.displayTextLineshow": "在屏幕第[RAW]行显示文字 [TEXT] 颜色[COLOR] 字体[FONT] 背景色[BACKGROUND] [NOWRAP] [MIDDLE] ",
    "gui.blocklyText.boson.displayPixel": "画点 x[X] y[Y] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayLine": "画线 起点x[X1] y[Y1] 终点x[X2] y[Y2] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayHline": "画水平线 起点x[X] y[Y] 长度 [LEN] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayVline": "画垂直线 起点x[X] y[Y] 长度 [LEN] 颜色 [COLOR]",
    "gui.blocklyText.boson.displayRect": "画矩形 起点x[X] y[Y] 宽度[W] 高度[H] 颜色 [COLOR] 位置 [POSITION] [FILL]",
    "gui.blocklyText.boson.displayRect.js": "画矩形 起点x[X] y[Y] 宽度[W] 高度[H] 颜色 [COLOR] [FILL]",
    "gui.blocklyText.boson.displayCircle": "画圆 圆心x[X] y[Y] 半径[R] 颜色 [COLOR] 位置 [POSITION] [FILL]",
    "gui.blocklyText.boson.displayCircle.js": "画圆 圆心x[X] y[Y] 半径[R] 颜色 [COLOR] [FILL]",
    "gui.blocklyText.boson.displayTriangle": "画三角形 x1[X1] y1[Y1] x2[X2] y2[Y2] x3[X3] y3[Y3] 颜色 [COLOR] 位置 [POSITION] [FILL]",
    "gui.blocklyText.boson.displayRoundRect": "画圆角矩形 起点x[X] y[Y] 宽度[WIDTH] 高度[HEIGTH] 圆角半径[R] 颜色 [COLOR] 位置 [POSITION]",
    "gui.blocklyText.boson.displayThreePointArc": "三点画弧 x1[X1] y1[Y1] x2[X2] y2[Y2] x3[X3] y3[Y3] 颜色 [COLOR] 离散间距 [SPACE] 位置 [POSITION]",
    "gui.blocklyText.boson.mpyDisplayComputerImage": "从电脑加载图片 [IMAGE] 以灰度显示到 x[X] y[Y] 位置 [POSITION] 模式[TYPE] ",
    "gui.blocklyText.boson.mpyDisplayComputerRgbImage": "从电脑加载图片 [IMAGE] 显示到 x[X] y[Y] 位置 [POSITION]",
    "gui.blocklyText.boson.boson_mpyDisplayFileSystemImage": "从文件系统加载图像 [PATH]",
    "gui.blocklyText.boson.showPixelCoordinate": "在坐标x [X] y [Y] 显示 [SHOWMSG] 字体 [PIXEL] 颜色 [COLOR] 背景 [BGCOLOR]",
    "gui.blocklyText.boson.displayQRCode": "绘制二维码 [QRCODE] 起点x[X] y[Y] 大小 [QRSIZE] 顏色 [COLOR]",
    "gui.blocklyText.boson.mpyDisplayClockInit": "初始化时钟 [NAME] 起点x[X] y[Y] 半径 [RADIUS] 颜色 [COLOR] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.mpyDisplayClockUpdate": "更新时钟 [NAME]",
    "gui.blocklyText.boson.displayClockInit": "初始化时钟 [ID] 起点x[X] y[Y] 半径 [RADIUS] 颜色 [COLOR] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.displayClockUpdate": "更新时钟 [ID]",
    "gui.blocklyText.boson.mpyDisplayProgressInit": "初始化进度条 [NAME] 起点x[X] y[Y] 宽度 [WIDTH] 高度 [HEIGTH] 进度 [PROGRESS]% 进度颜色 [COLOR1] 进度背景色 [COLOR2] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.mpyDisplayProgressUpdate": "更新进度条 [NAME] 进度为 [PROGRESS]%",
    "gui.blocklyText.boson.displayProgressInit": "初始化进度条 [ID] 起点x[X] y[Y] 宽度 [WIDTH] 高度 [HEIGTH] 进度 [PROGRESS] 进度颜色 [COLOR1] 进度背景色 [COLOR2] 背景色 [BACKCOLOR]",
    "gui.blocklyText.boson.displayProgressUpdate": "更新进度条 [ID] 进度为 [PROGRESS]",
    "gui.blocklyText.boson.displayBrightness": "屏幕亮度为 [VALUE]%",
    "gui.blocklyText.boson.font16_16": "字体font16_16",
    "gui.blocklyText.boson.font20_20": "字体font20_20",
    "gui.blocklyText.boson.middleMenu.true": "居中",
    "gui.blocklyText.boson.middleMenu.false": "正常",
    "gui.blocklyText.boson.directionMenu.false": "正常",
    "gui.blocklyText.boson.directionMenu.true": "反向",
    "gui.blocklyText.boson.positionMenu.customize": "自定义",
    "gui.blocklyText.boson.positionMenu.horizontalCenter": "水平居中",
    "gui.blocklyText.boson.positionMenu.leftCenter": "左居中",
    "gui.blocklyText.boson.positionMenu.rightCenter": "右居中",
    "gui.blocklyText.boson.positionMenu.upCenter": "上居中",
    "gui.blocklyText.boson.positionMenu.downCenter": "下居中",
    "gui.blocklyText.boson.positionMenu.leftTop": "左上角",
    "gui.blocklyText.boson.positionMenu.leftDown": "左下角",
    "gui.blocklyText.boson.positionMenu.rightTop": "右上角",
    "gui.blocklyText.boson.positionMenu.rightDown": "右下角",
    "gui.blocklyText.boson.strength": "强度",
    "gui.blocklyText.boson.switchMenu.on": "打开",
    "gui.blocklyText.boson.switchMenu.off": "关闭",
    "gui.blocklyText.boson.nowrapMenu.true": "换行",
    "gui.blocklyText.boson.nowrapMenu.false": "不换行",
    "gui.blocklyText.boson.fillMenu.false": "空心",
    "gui.blocklyText.boson.fillMenu.true": "实心",
    "gui.blocklyText.boson.push.false": "清除缓存",
    "gui.blocklyText.boson.push.true": "立刻生效",
    "gui.blocklyText.esp8266.starts": "ESP8266 主程序开始",
    "gui.blocklyText.esp32.displayInLine": "屏幕显示文字 [TEXT] 在第 [LINE] 行",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "屏幕写入缓存文字 [TEXT] 在第 [LINE] 行 模式 [SHOWMODE]",
    "gui.blocklyText.esp32.normal": "普通",
    "gui.blocklyText.esp32.rev": "反转",
    "gui.blocklyText.esp32.trans": "透明",
    "gui.blocklyText.esp32.XOR": "XOR",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "屏幕清除缓存第 [LINE] 行",
    "gui.blocklyText.esp32.displayClearInLine": "屏幕显示清除第 [LINE] 行",
    "gui.blocklyText.esp32.buzzPinC": "P6  (板载蜂鸣器)",
    "gui.blocklyText.esp32.buzzerRedirect": "蜂鸣器重定向到引脚 [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "全部",
    "gui.blocklyText.esp32.setTouchThreshold": "设置按键 [PIN] 触摸阈值为 [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "读取按键 [PIN] 触摸值",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32 ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ",
    "gui.blocklyText.esp32.btnIsPressed": "ᠳᠠᠷᠤᠪᠴᠢ [BUTTON]  ᠨᠢ [BUTTONSTATE] ᠪᠡᠷ ᠭᠦᠢᠴᠡᠳᠭᠡᠭᠳᠡᠬᠦ",
    "gui.blocklyText.esp32.whenBtnPress": "[REFERENCE] ᠳᠠᠷᠤᠪᠴᠢ  ᠶᠢ ᠳᠠᠷᠤᠬᠤ ᠳ᠋ᠤ [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "ᠲᠡᠮᠲᠡᠷᠢᠭᠦᠯ ᠳᠠᠷᠤᠭᠤᠯ [REFERENCE]  ᠢ ᠲᠡᠮᠲᠡᠷᠢᠬᠦ ᠳᠦ [TOUCHSTATE] ᠪᠡᠷ ᠭᠦᠢᠴᠡᠳᠭᠡᠭᠳᠡᠬᠦ",
    "gui.blocklyText.esp32.onGesture": "当 [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "ᠳᠠᠷᠤᠭᠤᠯ [PIN]  ᠢ [TOUCHSTATE]  ᠪᠡᠷ ᠭᠦᠢᠴᠡᠳᠭᠡᠭᠳᠡᠬᠦ ᠦᠦ? ",
    "gui.blocklyText.esp32.buzzerSwitch": "ᠠᠷᠤ ᠲᠠᠢᠰᠠᠨ ᠳᠤ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠶᠢ ᠵᠣᠭᠰᠣᠭᠠᠬᠤ ",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "ᠠᠷᠤ ᠲᠠᠢᠰᠠᠨ ᠳᠤ [NOTE] ᠨᠣᠲ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ",
    "gui.blocklyText.esp32.setBuzzerFreq": " ᠨᠣᠲ [NOTE] ᠢ [BEAT] ᠴᠣᠬᠢᠯᠭ᠎ᠠ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ",
    "gui.blocklyText.esp32.getSoundIntensity": "ᠮᠢᠺᠷᠣᠹᠣᠨ ᠤ ᠳᠠᠭᠤᠨ ᠬᠦᠴᠦᠯᠡᠴᠡ ᠶᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.esp32.readBrightness": "ᠣᠷᠴᠢᠨ ᠢ ᠭᠡᠷᠡᠯ ᠦᠨ ᠬᠦᠴᠦᠯᠡᠴᠡ ᠶᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.esp32.setLightsColor": "ᠳ᠋ᠧᠩ ᠦᠨ ᠨᠣᠮᠧᠷ [LIGHTS] ᠢᠯᠡᠷᠡᠬᠦ ᠥᠩᠭᠡ [COLOR] ",
    "gui.blocklyText.esp32.rgbToColor": "ᠤᠯᠠᠭᠠᠨ [RED] ᠨᠣᠭᠣᠭᠠᠨ [GREEN] ᠬᠥᠬᠡ [BLUE] ",
    "gui.blocklyText.esp32.accelerationCalibration": "加速度校准 X轴偏移 [XOFFSET] Y轴偏移 [YOFFSET] Z轴偏移 [ZOFFSET] (m-g)",
    "gui.blocklyText.esp32.acceleration": "ᠬᠤᠷᠳᠤᠳᠬᠠᠯ ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ(g) [TYPE] ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.esp32.esp32_tiltAngle": "读取倾斜角 [TYPE]",
    "gui.blocklyText.esp32.esp32_boardShakeThrown": "掌控板 [ATTITUDE]？",
    "gui.blocklyText.esp32.esp32_boardTiltState": "掌控板倾斜 [GESTUREMPY]",
    "gui.blocklyText.esp32.forward": "向前倾斜",
    "gui.blocklyText.esp32.back": "向后倾斜",
    "gui.blocklyText.esp32.left": "向左倾斜",
    "gui.blocklyText.esp32.right": "向右倾斜",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationRange": "设置加速度范围为 [ACCRANGE]",
    "gui.blocklyText.esp32.esp32_mpySetAccelerationResolution": "设置加速度分辨率为 [ACCRESOLUTION]",
    "gui.blocklyText.esp32.esp32_mpyAccurateAccValue": "校准加速度值 X轴偏差 [XAXIS] Y轴偏差 [YAXIS] Z轴偏差 [ZAXIS]",
    "gui.blocklyText.esp32.esp32_mpyAccurateCompass": "校准指南针",
    "gui.blocklyText.esp32.esp32_mpyCompassDirection": "指南针方向",
    "gui.blocklyText.esp32.esp32_mpyAxisMagnetic": "[TYPE] 轴 磁力",
    "gui.blocklyText.esp32.esp32_mpyMagneticStrength": "磁场强度",
    "gui.blocklyText.esp32.esp32_mpyRemoveMagnetic": "去除当前环境磁场",
    "gui.blocklyText.esp32.esp32_mpyReturnRunTimeDiff": "返回运行时间差, 开始时间 [STARTTIME] 结束时间 [ENDTIME]",
    "gui.blocklyText.esp32.esp32_mpyRunTimer": "运行时间 [TIMETYPE]",
    "gui.blocklyText.esp32.millisecond": "毫秒",
    "gui.blocklyText.esp32.microsencond": "微秒",
    "gui.blocklyText.esp32.esp32_mpyLocalTime": "本地时间 [LOCALTIME]",
    "gui.blocklyText.esp32.year": "年",
    "gui.blocklyText.esp32.month": "月",
    "gui.blocklyText.esp32.day": "日",
    "gui.blocklyText.esp32.hour": "时",
    "gui.blocklyText.esp32.minute": "分",
    "gui.blocklyText.esp32.sencond": "秒",
    "gui.blocklyText.esp32.weeknumber": "星期数",
    "gui.blocklyText.esp32.daysnumber": "天数",
    "gui.blocklyText.esp32.esp32_mpyReset": "复位",
    "gui.blocklyText.esp32.esp32_mpyMacAddr": "MAC 地址",
    "gui.blocklyText.esp32.displayInXY": "ᠳᠡᠯᠭᠡᠴᠡᠨ ᠳᠦ ᠢᠯᠡᠷᠡᠬᠦ ᠦᠰᠦᠭ [TEXT] ᠶᠢᠨ ᠪᠠᠶᠢᠷᠢᠰᠢᠯX: [X]Y: [Y] ᠤᠷᠢᠳᠴᠢᠯᠠᠨ ᠦᠵᠡᠬᠦ [PREVIEW] ",
    "gui.blocklyText.esp32.displayInXYForMpy": "write cache [TEXT] at X: [X] Y: [Y], mode [SHOWMODE] preview [PREVIEW]",
    "gui.blocklyText.esp32.fillScreen": "ᠳᠡᠯᠭᠡᠴᠡ ᠳᠡᠭᠡᠷ᠎ᠡ ᠢᠯᠡᠷᠡᠭᠰᠡᠨ ᠨᠢ [COLOR] (clear screen)",
    "gui.blocklyText.esp32.fillScreenForMpy": "ᠳᠡᠯᠭᠡᠴᠡ ᠶᠢ ᠠᠯᠭᠤᠷ ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ [COLORMPYTHON] (clear screen)",
    "gui.blocklyText.esp32.clear": "清屏",
    "gui.blocklyText.esp32.fullBright": "全亮",
    "gui.blocklyText.esp32.blackBackground": "黑底",
    "gui.blocklyText.esp32.whiteBackground": "白底",
    "gui.blocklyText.esp32.black": "all black",
    "gui.blocklyText.esp32.white": " ᠴᠣᠯ ᠴᠠᠭᠠᠨ ",
    "gui.blocklyText.esp32.lineDrawing": "ᠵᠢᠷᠤᠭ ᠤᠨ ᠱᠤᠭᠤᠮ ᠤᠨ ᠡᠬᠢᠯᠡᠯᠲᠡ ᠶᠢᠨ ᠴᠡᠭ x1:[X1] y1:[Y1] ᠲᠡᠭᠦᠰᠬᠡᠯ ᠣᠨ ᠴᠡᠭ x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ",
    "gui.blocklyText.esp32.disconnected": "ᠲᠠᠰᠤᠯᠬᠤ ",
    "gui.blocklyText.esp32.rectangleDrawing": "ᠵᠢᠷᠤᠭ ᠤᠨ ᠲᠡᠭᠰᠢ ᠥᠨᠴᠥᠭᠲᠦ [FILL] ᠡᠬᠢᠯᠡᠯᠲᠡ ᠶᠢᠨ ᠴᠡᠭ x:[X] y:[Y] ᠥᠷᠭᠡᠨ:[WIDTH] ᠥᠨᠳᠥᠷ:[HEIGHT] ",
    "gui.blocklyText.esp32.mpyrectangleDrawing": "[DRAWERASE] [FILL] 画矩形 x:[X] y:[Y] 宽:[WIDTH] 高:[HEIGHT]",
    "gui.blocklyText.esp32.screenDisplay": "ᠳᠡᠯᠭᠡᠴᠡ ᠨᠠᠮᠬᠠᠳᠬᠠᠬᠤ ᠦᠵᠡᠭᠦᠯᠬᠦ ᠬᠦᠴᠦᠨ ᠲᠡᠶ ᠪᠣᠯᠬᠤ ",
    "gui.blocklyText.esp32.circleDrawing": "ᠲᠣᠭᠣᠷᠢᠭ ᠵᠢᠷᠤᠬᠤ [FILL] ᠲᠣᠭᠣᠷᠢᠭ ᠣᠨ ᠲᠥᠪ x:[X] y:[Y] ᠷᠠᠳᠢᠦ᠋ᠰ:[RADIUS]",
    "gui.blocklyText.esp32.fill": "ᠳᠦᠭᠦᠷᠭᠡᠬᠦ ",
    "gui.blocklyText.esp32.notFill": "ᠳᠦᠭᠦᠷᠭᠡᠬᠦ ᠥᠬᠡᠶ ",
    "gui.blocklyText.esp32.pointDrawing": "ᠴᠡᠭ ᠵᠢᠷᠤᠬᠤ x:[X] y:[Y]",
    "gui.blocklyText.esp32.esp32_mpyProgressBar": "进度条 x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT] 进度 [PROG]",
    "gui.blocklyText.esp32.esp32_mpyColumnarProgressBar": "柱状条 [COLUMNARSTATE] x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT] 进度 [PROG]",
    "gui.blocklyText.esp32.vertical": "垂直",
    "gui.blocklyText.esp32.horizontal": "水平",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseLine": "[DRAWERASE] [COLUMNARSTATE] 线 x [X] y [Y] 长度 [LENGTH]",
    "gui.blocklyText.esp32.draw": "绘制",
    "gui.blocklyText.esp32.erase": "擦除",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCornerBorder": "[DRAWERASE] 弧角边框 x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT] 半径 [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseCircle": "[DRAWERASE] [FILL] 圆 x [X] y [Y] 半径 [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseTriangle": "[DRAWERASE] [FILL] 三角形 x1 [X1] y1 [Y1] x2 [X2] y2 [Y2] x3 [X3] y3 [Y3]",
    "gui.blocklyText.esp32.esp32_mpyShowPixelCoordinate": "在坐标 x [X] y [Y] 显示 [SHOWMSG] 以 [PIXEL] 字体 [LINE]",
    "gui.blocklyText.esp32.Digiface_11px": "仿数码管 11像素",
    "gui.blocklyText.esp32.Digiface_16px": "仿数码管 16像素",
    "gui.blocklyText.esp32.Digiface_21px": "仿数码管 21像素",
    "gui.blocklyText.esp32.Digiface_30px": "仿数码管 30像素",
    "gui.blocklyText.esp32.Digiface_44px": "仿数码管 44像素",
    "gui.blocklyText.esp32.Digiface_ltalic_30px": "仿数码管 斜体 30像素",
    "gui.blocklyText.esp32.Digiface_ltalic_42px": "仿数码管 斜体 42像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_12px": "DejaVu Sans Mono 12像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_16px": "DejaVu Sans Mono 16像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_21px": "DejaVu Sans Mono 21像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_12px": "DejaVu Sans Mono 黑体 12像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_16px": "DejaVu Sans Mono 黑体 16像素",
    "gui.blocklyText.esp32.DejaVu_Sans_Mono_Bold_21px": "DejaVu Sans Mono 黑体 21像素",
    "gui.blocklyText.esp32.esp32_mpyQRCode": "x [X] y [Y] 绘制二维码 [QRCODE] 尺寸 [IMGSIZE]",
    "gui.blocklyText.esp32.esp32_mpyInitClock": "初始化时钟 [CLOCKNAME] x [X] y [Y] 半径 [RADIUS]",
    "gui.blocklyText.esp32.esp32_mpyClockReadTime": "时钟 [CLOCKNAME] 读取时间",
    "gui.blocklyText.esp32.esp32_mpyDrawEraseClock": "[DRAWERASE] 时钟 [CLOCKNAME]",
    "gui.blocklyText.esp32.setLineWidth": "ᠱᠤᠭᠤᠮ ᠤᠨ ᠥᠷᠭᠡᠨ ᠨᠢ [LINEWIDTH] ",
    "gui.blocklyText.esp32.digitalWrite": "ᠲᠣᠭᠠᠨ ᠤ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠭᠠᠷᠭᠠᠯᠲᠠ [VALUE] ",
    "gui.blocklyText.esp32.digitalRead": "ᠲᠣᠭᠠᠨ ᠤ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ",
    "gui.blocklyText.esp32.analogWrite": "ᠲᠥᠰᠦᠭᠡᠳᠦᠭᠦᠯᠦᠭᠰᠡᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠭᠠᠷᠭᠠᠬᠤ [VALUE] (PWM)",
    "gui.blocklyText.esp32.analogRead": " ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ [PIN]",
    "gui.blocklyText.esp32.serialPort0": " ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 0 ",
    "gui.blocklyText.esp32.serialPort1": " ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 1 ",
    "gui.blocklyText.esp32.serialPort2": " ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 2 ",
    "gui.blocklyText.esp32.seriaRemapping": "ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [SERIAL]Rx[RX] Tx [TX] ᠫᠤᠲ᠋ᠲ᠋ᠸᠷ ᠨᠤᠷᠮᠠ ᠨᠢ [BAUD] ",
    "gui.blocklyText.esp32.imageDrawing": "ᠳᠡᠯᠭᠡᠴᠡ ᠳᠡᠭᠡᠷ᠎ᠡ ᠢᠯᠡᠷᠡᠭᠰᠡᠨ ᠵᠢᠷᠤᠭ [IMAGE]  ᠤᠨ ᠪᠠᠶᠢᠷᠢᠰᠢᠯX: [X] Y: [Y] ",
    "gui.blocklyText.esp32.imageDrawingForMpy": "ᠳᠡᠯᠭᠡᠴᠡ ᠶᠢ ᠠᠯᠭᠤᠷ ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ ᠭᠡᠷᠡᠯ ᠵᠢᠷᠤᠭ ᠤᠨ [IMAGE] ᠪᠠᠶᠢᠷᠢᠰᠢᠯX: [X] Y: [Y] ",
    "gui.blocklyText.esp32.pressed": "ᠳᠠᠷᠤᠬᠤ",
    "gui.blocklyText.esp32.loosened": " ᠰᠤᠯᠠᠯᠠᠬᠤ",
    "gui.blocklyText.esp32.all": " ᠪᠦᠭᠦᠳᠡ ",
    "gui.blocklyText.esp32.setBaud": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠠᠮᠠᠰᠠᠷᠲᠤ 0 ᠫᠣᠲ᠋ᠸᠨ᠋ᠲ  ᠤᠨ ᠨᠤᠷᠮᠠ [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "从 [SERIAL] 读取一个 [READTYPE]",
    "gui.blocklyText.esp32.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.esp32.setBrightness": "LED ᠳ᠋ᠧᠩ ᠦᠨ ᠭᠡᠷᠡᠯᠲᠦᠴᠡ [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "LED ᠳ᠋ᠧᠩ ᠦᠨ ᠭᠡᠷᠡᠯ ᠦᠨ ᠬᠡᠮᠵᠢᠶ᠎ᠡ ",
    "gui.blocklyText.esp32.print": "打印 [DATA]",
    "gui.blocklyText.esp32.TiltForward": "向前倾斜",
    "gui.blocklyText.esp32.TiltBack": "向后倾斜",
    "gui.blocklyText.esp32.TiltLeft": "向左倾斜",
    "gui.blocklyText.esp32.TiltRight": "向右倾斜",
    "gui.blocklyText.esp32.ScreenUp": "屏幕朝上",
    "gui.blocklyText.esp32.ScreenDown": "屏幕朝下",
    "gui.blocklyText.esp32.Shake": "摇一摇",
    "gui.blocklyText.esp32.isGesture": "当前姿态为 [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "关闭 [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "全部",
    "gui.blocklyText.esp32.rgbdisable.No.0": "0号",
    "gui.blocklyText.esp32.rgbdisable.No.1": "1号",
    "gui.blocklyText.esp32.rgbdisable.No.2": "2号",
    "gui.blocklyText.esp32.playMusic": "播放音乐 [MUSIC] 重复 [REPEAT]",
    "gui.blocklyText.esp32.Once": "播放一次",
    "gui.blocklyText.esp32.Forever": "无限循环",
    "gui.blocklyText.esp32.OnceInBackground": "在后台播放一次",
    "gui.blocklyText.esp32.ForeverInBackground": "在后台无限循环",
    "gui.blocklyText.radio.radioSwitch": "[DATA] 无线广播",
    "gui.blocklyText.radio.radioswitch.on": "打开",
    "gui.blocklyText.radio.radioswitch.off": "关闭",
    "gui.blocklyText.radio.setChannel": "设置无线广播 频道为 [DATA]",
    "gui.blocklyText.radio.radioSend": "无线广播 发送 [DATA]",
    "gui.blocklyText.radio.radioreceived": "无线广播 接收消息",
    "gui.blocklyText.radio.receivingEven": "当接收到 [DATA]",
    "gui.blocklyText.radio.Msg": "无线消息",
    "gui.blocklyText.radio.receivingSpecificEven": "当接收到特定消息 [DATA] ",
    "gui.blocklyText.esp32.radioSwitch": "[DATA] 无线广播",
    "gui.blocklyText.esp32.radioswitch.on": "打开",
    "gui.blocklyText.esp32.radioswitch.off": "关闭",
    "gui.blocklyText.esp32.setChannel": "设置无线广播 频道为 [DATA]",
    "gui.blocklyText.esp32.radioSend": "无线广播 发送 [DATA]",
    "gui.blocklyText.esp32.radioreceived": "无线广播 接收消息",
    "gui.blocklyText.esp32.receivingEven": "当 收到无线广播消息[DATA]时 执行",
    "gui.blocklyText.esp32.receivingSpecificEven": "当 收到特定无线广播消息 [DATA] 时 执行",
    "gui.blocklyText.esp32.connectWIFI": "连接Wi-Fi 名称 [SSID] 密码 [PASS]",
    "gui.blocklyText.esp32.disconnectWIFI": "断开Wi-Fi连接",
    "gui.blocklyText.esp32.checkWIFI": "已连接到Wi-Fi",
    "gui.blocklyText.esp32.configurationInformation": "Wi-Fi配置信息",
    "gui.blocklyText.esp32.configurationInformationIP": "Wi-Fi配置信息[IP]",
    "gui.blocklyText.esp32.wifi.IP": "IP",
    "gui.blocklyText.esp32.wifi.netmask": "netmask",
    "gui.blocklyText.esp32.wifi.geteway": "geteway",
    "gui.blocklyText.esp32.wifi.DNS": "DNS",
    "gui.blocklyText.esp32.openAP": "开启AP模式 名称 [SSID] 密码 [PASS] 信道 [CHANNEL]",
    "gui.blocklyText.esp32.closeAP": "关闭AP模式",
    "gui.blocklyText.esp32.synchronizeTime": "同步网络时间 时区 [TIMEZONE] 授时服务器 [SERVER]",
    "gui.blocklyText.esp32.timezone.UTC+8": "东8区",
    "gui.blocklyText.esp32.timezone.UTC+9": "东9区",
    "gui.blocklyText.esp32.timezone.UTC+10": "东10区",
    "gui.blocklyText.esp32.timezone.UTC+11": "东11区",
    "gui.blocklyText.esp32.timezone.UTC+12": "东12区",
    "gui.blocklyText.esp32.timezone.UTC-12": "西12区",
    "gui.blocklyText.esp32.timezone.UTC-11": "西11区",
    "gui.blocklyText.esp32.timezone.UTC-10": "西10区",
    "gui.blocklyText.esp32.timezone.UTC-9": "西9区",
    "gui.blocklyText.esp32.timezone.UTC-8": "西8区",
    "gui.blocklyText.esp32.timezone.UTC-7": "西7区",
    "gui.blocklyText.esp32.timezone.UTC-6": "西6区",
    "gui.blocklyText.esp32.timezone.UTC-5": "西5区",
    "gui.blocklyText.esp32.timezone.UTC-4": "西4区",
    "gui.blocklyText.esp32.timezone.UTC-3": "西3区",
    "gui.blocklyText.esp32.timezone.UTC-2": "西2区",
    "gui.blocklyText.esp32.timezone.UTC-1": "西1区",
    "gui.blocklyText.esp32.timezone.UTC": "中时区",
    "gui.blocklyText.esp32.timezone.UTC+1": "东1区",
    "gui.blocklyText.esp32.timezone.UTC+2": "东2区",
    "gui.blocklyText.esp32.timezone.UTC+3": "东3区",
    "gui.blocklyText.esp32.timezone.UTC+4": "东4区",
    "gui.blocklyText.esp32.timezone.UTC+5": "东5区",
    "gui.blocklyText.esp32.timezone.UTC+6": "东6区",
    "gui.blocklyText.esp32.timezone.UTC+7": "东7区",
    "gui.blocklyText.pico.pico_led": "LED输出 [STATE]",
    "gui.blocklyText.pico.pico_readTemp": "读取温度(℃)",
    "gui.blocklyText.pico.pico_pinMode": "设置 [PIN] 引脚模式为 [MODE]",
    "gui.blocklyText.pico.serialPort0": "USB",
    "gui.blocklyText.pico.serialPort1": "硬串口1",
    "gui.blocklyText.pico.serialPort2": "硬串口2",
    "gui.blocklyText.pico.seriaRemapping": "设置 [SERIALPORT] Rx [PINR] Tx [PINT] 波特率为 [BAUD]",
    "gui.blocklyText.telloesp32.arduinoUnoStarts": "RMTT ESP32主程序开始",
    "gui.blocklyText.telloesp32.ledControl": "灯光控制",
    "gui.blocklyText.telloesp32.matrixControl": "点阵控制",
    "gui.blocklyText.telloesp32.flyControl": "飞行控制",
    "gui.blocklyText.telloesp32.customCommand": "自定义命令",
    "gui.blocklyText.telloesp32.pinOperation": "引脚操作",
    "gui.blocklyText.telloesp32.readDigital": "读取数字引脚 [PIN]",
    "gui.blocklyText.telloesp32.readAnalog": "读取模拟引脚 [PIN]",
    "gui.blocklyText.telloesp32.setPwm": "设置PWM引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.telloesp32.setDigital": "设置数字引脚 [PIN] 输出为 [LEVEL]",
    "gui.blocklyText.telloesp32.functionalModule": "功能模块",
    "gui.blocklyText.telloesp32.telloesp32_matrix": "显示图案[MARK]",
    "gui.blocklyText.telloesp32.showPatternWhenStart": "点阵屏开机显示图案[MARK]",
    "gui.blocklyText.telloesp32.setMatrixLight": "设置屏幕亮度[NUM]",
    "gui.blocklyText.telloesp32.matrixDisplayText": "显示单个文字 [TEXT] 颜色 [COLOR] 亮度 [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayPreset": "显示预设图案 [PATTERN] 颜色 [COLOR] 亮度 [LIGHT]",
    "gui.blocklyText.telloesp32.matrixDisplayTextMpy": "显示单个文字 [TEXT] 颜色 [COLOR]",
    "gui.blocklyText.telloesp32.matrixDisplayPresetMpy": "显示预设图案 [PATTERN] 颜色 [COLOR]",
    "gui.blocklyText.telloesp32.empty": "空",
    "gui.blocklyText.telloesp32.WiFi": "WiFi",
    "gui.blocklyText.telloesp32.DownLoad": "DownLoad",
    "gui.blocklyText.telloesp32.Power": "Power",
    "gui.blocklyText.telloesp32.heart": "heart",
    "gui.blocklyText.telloesp32.heartOutline": "heart-outline",
    "gui.blocklyText.telloesp32.matrixR": "红色",
    "gui.blocklyText.telloesp32.matrixB": "蓝色",
    "gui.blocklyText.telloesp32.matrixP": "紫色",
    "gui.blocklyText.telloesp32.matrixDisplayRollPicture": "滚动显示图案 方向 [DIRECT] 移动频率 [SPEED] 图案[MARK]",
    "gui.blocklyText.telloesp32.matrixDisplayRollText": "滚动显示文字 方向 [DIRECT] 移动频率 [SPEED] 文字 [TEXT] 颜色 [COLOR]",
    "gui.blocklyText.telloesp32.telloSetWifiChannel": "设置Wi-Fi信道 [CHANNEL]",
    "gui.blocklyText.telloesp32.initTOF": "启用TOF模组",
    "gui.blocklyText.telloesp32.readTOF": "读取TOF测距 (毫米)",
    "gui.blocklyText.telloesp32.buttonIsPressed": "按键是否按下？",
    "gui.blocklyText.telloesp32.setRgbColor": "LED灯颜色 [COLOR]",
    "gui.blocklyText.telloesp32.rgbToColor": "LED灯颜色 红: [RED] 绿: [GREEN] 蓝: [BLUE]",
    "gui.blocklyText.telloesp32.rgbBreathWithColor": "LED 呼吸灯 颜色[COLOR] 频率(Hz) [TIME]",
    "gui.blocklyText.telloesp32.rgbBreath": "LED呼吸灯 红: [RED] 绿: [GREEN] 蓝: [BLUE] 频率(Hz): [SP]",
    "gui.blocklyText.telloesp32.rgbFlashingColor": "LED闪烁 颜色1 [COLOR1] 和 颜色2 [COLOR2] 频率(Hz) [SP]",
    "gui.blocklyText.telloesp32.rgbFlashing": "LED闪烁 红1: [RED1] 绿1: [GREEN1] 蓝1: [BLUE1] - 红2: [RED2] 绿2: [GREEN2] 蓝2: [BLUE2] - 频率(Hz): [SP]",
    "gui.blocklyText.telloesp32.ledCloseEffects": "LED关闭特效",
    "gui.blocklyText.telloesp32.matrixCloseEffects": "屏幕关闭特效",
    "gui.blocklyText.telloesp32.sendCustomCommand": "发送自定义命令 [CMD]",
    "gui.blocklyText.telloesp32.recvCustomCommand": "收到自定义回应字符串 [MSG], 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.recvCustomCommandNum": "收到自定义回应数值, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.serialportOperation": "串口操作",
    "gui.blocklyText.telloesp32.dataReadable": "[SERIAL] 有数据可读?",
    "gui.blocklyText.telloesp32.readSerialData": "读取 [SERIAL] 数据",
    "gui.blocklyText.telloesp32.setBaud": "设置串口0波特率为 [BAUD]",
    "gui.blocklyText.telloesp32.readSerialDataIntFloat": "从 [SERIAL] 读取一个 [READTYPE]",
    "gui.blocklyText.telloesp32.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.telloesp32.seriaRemapping": "设置 [SERIAL] Rx [RX] Tx [TX] 波特率为 [BAUD]",
    "gui.blocklyText.telloesp32.systemResource": "系统资源",
    "gui.blocklyText.telloesp32.timer": "系统运行时间(ms)",
    "gui.blocklyText.telloesp32.consolePrint": "控制台输出",
    "gui.blocklyText.telloesp32.print": "打印 [DATA]",
    "gui.blocklyText.telloesp32.telloRiseDown": "[RISEDOWN] [NUMS] 厘米",
    "gui.blocklyText.telloesp32.rise": "上升",
    "gui.blocklyText.telloesp32.down": "下降",
    "gui.blocklyText.telloesp32.telloTurnDirection": "向 [DIRECTION] 飞 [NUMS] 厘米",
    "gui.blocklyText.telloesp32.turnleft": "左",
    "gui.blocklyText.telloesp32.turnright": "右",
    "gui.blocklyText.telloesp32.turnForward": "前",
    "gui.blocklyText.telloesp32.turnBack": "后",
    "gui.blocklyText.telloesp32.telloMotorOn": "进入起桨模式",
    "gui.blocklyText.telloesp32.telloMotorOff": "退出起桨模式",
    "gui.blocklyText.telloesp32.telloTakeOff": "起飞",
    "gui.blocklyText.telloesp32.telloThrowFly": "5秒内抛飞",
    "gui.blocklyText.telloesp32.telloLanding": "降落",
    "gui.blocklyText.telloesp32.telloClockwiseRotation": "[CLOCKWISE] 旋转 [NUMS] 度",
    "gui.blocklyText.telloesp32.clockwise": "顺时针",
    "gui.blocklyText.telloesp32.counterClockwise": "逆时针",
    "gui.blocklyText.telloesp32.telloStartControl": "开启飞行控制（绿灯亮起时按下按钮）直到成功",
    "gui.blocklyText.telloesp32.telloGetHeight": "获取 [HEIGHT],超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.relatively": "相对高度(dm)",
    "gui.blocklyText.telloesp32.barometer": "气压计高度(m)",
    "gui.blocklyText.telloesp32.tof": "TOF高度(mm)",
    "gui.blocklyText.telloesp32.telloTemp": "主板温度(°C),超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.lowest": "最低",
    "gui.blocklyText.telloesp32.highest": "最高",
    "gui.blocklyText.telloesp32.telloAttitude": "获取IMU三轴姿态数据, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.pitch": "俯仰轴",
    "gui.blocklyText.telloesp32.roll": "横滚轴",
    "gui.blocklyText.telloesp32.translation": "平移轴",
    "gui.blocklyText.telloesp32.telloAcceleration": "获取IMU三轴加速度数据(0.001g), 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.xaxis": "x轴",
    "gui.blocklyText.telloesp32.yaxis": "y轴",
    "gui.blocklyText.telloesp32.zaxis": "z轴",
    "gui.blocklyText.telloesp32.telloSetSpeed": "设置速度为 [NUMS]cm/s",
    "gui.blocklyText.telloesp32.telloSpeed": "[XYZAXIS] 速度(cm/s), 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloPower": "电池电量( %), 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.MissionPad": "挑战卡编号, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloMissionPadXyz": "挑战卡 [XYZ] (cm), 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloCommandResponse": "回复指令, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloSetyawXMid": "飞机相对挑战卡[MID]旋转至[YAW]度",
    "gui.blocklyText.telloesp32.telloGetMissionPadNum": "获取挑战卡编号, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloCurrentSpeed": "当前速度, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloTime": "电机运行时间 (s), 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloWIFISign": "Wi-Fi 信噪比, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloSDKVersion": "Tello SDK 版本, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloBinVersion": "Tello固件版本, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloHasHardware": "获取硬件版本, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.rmttWifiVersion": "扩展模块Wi-Fi版本, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloSN": "Tello SN 码, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloFlyToXYZ": "飞往(相对于飞机的) x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s",
    "gui.blocklyText.telloesp32.getSpeed": "获取当前设置速度(cm/s)",
    "gui.blocklyText.telloesp32.getBattery": "获取当前电池剩余电量的百分比值",
    "gui.blocklyText.telloesp32.getTime": "获取电机运转时间",
    "gui.blocklyText.telloesp32.getWifiNoise": "获取Wi-Fi信噪比",
    "gui.blocklyText.telloesp32.getSdkVersion": "获取sdk版本号",
    "gui.blocklyText.telloesp32.getSnNum": "获取SN码",
    "gui.blocklyText.firebeetleesp32.Starts": "FireBeetle ESP32主程序开始",
    "gui.blocklyText.firebeetleesp32e.Starts": "FireBeetle ESP32-E 主程序开始",
    "gui.blocklyText.firebeetleesp32.pinOperation": "引脚操作",
    "gui.blocklyText.firebeetleesp32.readDigital": "读取数字引脚 [PIN]",
    "gui.blocklyText.firebeetleesp32.readAnalog": "读取模拟引脚 [PIN]",
    "gui.blocklyText.firebeetleesp32.setPwm": "设置PWM引脚 [PIN] 输出 [VALUE]",
    "gui.blocklyText.firebeetleesp32.setDigital": "设置数字引脚 [PIN] 输出为 [LEVEL]",
    "gui.blocklyText.firebeetleesp32.functionalModule": "功能模块",
    "gui.blocklyText.firebeetleesp32.serialportOperation": "串口操作",
    "gui.blocklyText.firebeetleesp32.setBaud": "设置串口波特率为 [BAUD]",
    "gui.blocklyText.firebeetleesp32.serialSendStr": "串口 [WAY] [STRING] [LINE]",
    "gui.blocklyText.firebeetleesp32.dataReadable": "串口有数据可读？",
    "gui.blocklyText.firebeetleesp32.readSerialData": "读取串口数据",
    "gui.blocklyText.firebeetleesp32.timer": "系统运行时间(ms)",
    "gui.blocklyText.firebeetleesp32.originalOutput": "原始输出",
    "gui.blocklyText.firebeetleesp32.stringOutput": "字符串输出",
    "gui.blocklyText.firebeetleesp32.hexOutput": "十六进制输出",
    "gui.blocklyText.firebeetleesp32.wrap": "换行",
    "gui.blocklyText.firebeetleesp32.noWrap": "不换行",
    "gui.blocklyText.firebeetleesp32.high": "高电平",
    "gui.blocklyText.firebeetleesp32.low": "低电平",
    "gui.blocklyText.firebeetleesp32.readSerialDataIntFloat": "从串口读一个 [READTYPE]",
    "gui.blocklyText.firebeetleesp32.int": "整数",
    "gui.blocklyText.firebeetleesp32.float": "小数",
    "gui.blocklyText.firebeetleesp32.readUlrasonic": "读取超声波距离(cm) trig [TRIG] echo [ECHO]",
    "gui.blocklyText.firebeetleesp32.trumpet": "设置引脚 [PIN] 喇叭蜂鸣器音调为 [TONE] 节拍为 [BEAT]",
    "gui.blocklyText.firebeetleesp32.readInfrared": "读取红外接收模块 数字引脚 [PIN]",
    "gui.blocklyText.firebeetleesp32.readPinLevel": "读取引脚 [PIN] 脉冲宽度 模式 [LEVEL] 超时 [TIMEOUT](us)",
    "gui.blocklyText.firebeetleesp32.half": "1/2",
    "gui.blocklyText.firebeetleesp32.quarter": "1/4",
    "gui.blocklyText.firebeetleesp32.oneInEighth": "1/8",
    "gui.blocklyText.firebeetleesp32.wholeBeat": "整拍",
    "gui.blocklyText.firebeetleesp32.doubleBeat": "双拍",
    "gui.blocklyText.firebeetleesp32.noSound": "无音",
    "gui.blocklyText.firebeetleesp32.pwm1": "D5",
    "gui.blocklyText.firebeetleesp32.pwm2": "D9",
    "gui.blocklyText.firebeetleesp32.pwm3": "D8",
    "gui.blocklyText.firebeetleesp32.pwm4": "D7",
    "gui.blocklyText.firebeetleesp32.pwm5": "A4",
    "gui.blocklyText.firebeetleesp32.pwm6": "D2",
    "gui.blocklyText.firebeetleesp32.pwm7": "D3",
    "gui.blocklyText.firebeetleesp32.pwm8": "D4",
    "gui.blocklyText.firebeetleesp32.pwm9": "D6",
    "gui.blocklyText.mpy.MQTTinit": "MQTT初始化参数 [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInit": "MQTT初始化参数 [SETTING]",
    "gui.blocklyText.mpy.mpyMqttInitPlatform": "平台",
    "gui.blocklyText.mpy.mpyMqttInitPlatformNormal": "通用",
    "gui.blocklyText.mpy.mpyMqttInitPlatformTencent": "腾讯物联网",
    "gui.blocklyText.mpy.MQTTclient_id": "客户端id",
    "gui.blocklyText.mpy.MQTTserver": "服务器地址",
    "gui.blocklyText.mpy.MQTTport": "端口",
    "gui.blocklyText.mpy.MQTTuser": "用户名",
    "gui.blocklyText.mpy.MQTTpassowrd": "密码",
    "gui.blocklyText.mpy.MQTTkeepalive": "keepalive",
    "gui.blocklyText.mpy.MQTTtencent": "MQTT腾讯物联网 [SETTING]",
    "gui.blocklyText.mpy.TencentIOT": "腾讯IOT",
    "gui.blocklyText.mpy.TencentServer": "服务器",
    "gui.blocklyText.mpy.TencentPort": "端口",
    "gui.blocklyText.mpy.TencentAppld": "Appld",
    "gui.blocklyText.mpy.TencentDevice": "设备",
    "gui.blocklyText.mpy.siotServer": "服务器地址",
    "gui.blocklyText.mpy.siotServerUser": "账号",
    "gui.blocklyText.mpy.siotServerPass": "密码",
    "gui.blocklyText.mpy.Tencentoutput": "传出参数",
    "gui.blocklyText.mpy.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.mpy.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.mpy.mpy_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.mpy.EasyIOTServer": "服务器",
    "gui.blocklyText.mpy.EasyIOTServerChina": "中国",
    "gui.blocklyText.mpy.EasyIOTServerGlobal": "全球",
    "gui.blocklyText.mpy.EasyIOTclient_id": "客户端id",
    "gui.blocklyText.mpy.EasyIOT_id": "Iot_id",
    "gui.blocklyText.mpy.EasyIOT_password": "Iot_pwd",
    "gui.blocklyText.mpy.MQTTconnect": "连接MQTT",
    "gui.blocklyText.mpy.mpy_MQTTAddSub": "订阅主题 [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublish": "发布 [DATA] 至 主题 [TOPIC]",
    "gui.blocklyText.mpy.MQTTpublishQos1": "发布 [DATA] 至 主题 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.mpy.mpy_waitMSG": "等待主题消息 以 [MODE] 模式",
    "gui.blocklyText.mpy.disconnectMQTT": "MQTT 断开连接",
    "gui.blocklyText.mpy.MQTTlastwill": "设置MQTT主题 [TOPIC] 离线消息 [MSG]",
    "gui.blocklyText.mpy.MQTTreceive": "当主题 [TOPIC] 接收到 [DATA]",
    "gui.blocklyText.mpy.mqttMsg": "MQTT消息",
    "gui.blocklyText.mpy.MQTTmode.block": "阻塞",
    "gui.blocklyText.mpy.MQTTmode.unblock": "非阻塞",
    "gui.blocklyText.mpyWeather.init": "「心知天气」 设定 [DATA] 为 [SETTING]",
    "gui.blocklyText.mpyWeather.weatherFeild": "[DATA] 「心知天气」 通用字段 [FIELD]",
    "gui.blocklyText.mpyWeather.weatherSituation": "[DATA] 「心知天气」 天气实况 [NOW]",
    "gui.blocklyText.mpyWeather.weatherForecast": "[DATA] 「心知天气」 3天天气预报 [DATE] 的 [FIELD]",
    "gui.blocklyText.mpyWeather.weatherLivingindex": "[DATA] 「心知天气」 6项生活指数 [FIELD]",
    "gui.blocklyText.mpyWeather.weatherFeild.name": "城市名称",
    "gui.blocklyText.mpyWeather.weatherFeild.id": "城市ID",
    "gui.blocklyText.mpyWeather.weatherFeild.code": "国家代码",
    "gui.blocklyText.mpyWeather.weatherFeild.path": "行政归属",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone": "时区",
    "gui.blocklyText.mpyWeather.weatherFeild.timezone_offset": "UTC偏移量",
    "gui.blocklyText.mpyWeather.weatherFeild.Updated": "数据更新日期",
    "gui.blocklyText.mpyWeather.weatherFeild.updatetime": "数据更新时间",
    "gui.blocklyText.mpyWeather.weatherFeild.original": "原始字典对象",
    "gui.blocklyText.mpyWeather.weatherSituation.text": "天气现象",
    "gui.blocklyText.mpyWeather.weatherSituation.code": "天气现象代码",
    "gui.blocklyText.mpyWeather.weatherSituation.temperature": "温度",
    "gui.blocklyText.mpyWeather.weatherdate.today": "今天",
    "gui.blocklyText.mpyWeather.weatherdate.tomorrow": "明天",
    "gui.blocklyText.mpyWeather.weatherdate.dayaftertomorrow": "后天",
    "gui.blocklyText.mpyWeather.province": "省份",
    "gui.blocklyText.mpyWeather.city": "城市",
    "gui.blocklyText.mpyWeather.weatherforecast.highesttemperature": "当天最高温度",
    "gui.blocklyText.mpyWeather.weatherforecast.lowesttemperature": "当天最低温度",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweather": "白天天气现象",
    "gui.blocklyText.mpyWeather.weatherforecast.daytimeweathercode": "白天天气现象代码",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweather": "晚间天气现象",
    "gui.blocklyText.mpyWeather.weatherforecast.nightweathercode": "晚间天气现象代码",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirection": "风向",
    "gui.blocklyText.mpyWeather.weatherforecast.winddirectiondegree": "风向角度",
    "gui.blocklyText.mpyWeather.weatherforecast.windspeed": "风速",
    "gui.blocklyText.mpyWeather.weatherforecast.windscale": "风力等级",
    "gui.blocklyText.mpyWeather.weatherforecast.date": "日期",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Carwashindex": "洗车指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Dressingindex": "穿衣指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Coldindex": "感冒指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Sportindex": "运动指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.Travelindex": "旅游指数",
    "gui.blocklyText.mpyWeather.weatherlivingindex.uvindex": "紫外线指数",
    "gui.blocklyText.mpyWeather.type": "数据类型",
    "gui.blocklyText.mpyWeather.temperature": "温度单位",
    "gui.blocklyText.mpyWeather.APIkey": "API私钥",
    "gui.blocklyText.mpyWeather.language": "语言",
    "gui.blocklyText.mega2560.functionStarts": "Mege 2560 ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ",
    "gui.blocklyText.esp32.MQTTclient_id": "客户端id",
    "gui.blocklyText.esp32.MQTTserver": "服务器地址",
    "gui.blocklyText.esp32.MQTTport": "端口",
    "gui.blocklyText.esp32.MQTTuser": "用户名",
    "gui.blocklyText.esp32.MQTTpassowrd": "密码",
    "gui.blocklyText.esp32.MQTTkeepalive": "keepalive",
    "gui.blocklyText.esp32.TencentIOT": "腾讯IOT",
    "gui.blocklyText.esp32.TencentServer": "服务器",
    "gui.blocklyText.esp32.TencentPort": "端口",
    "gui.blocklyText.esp32.TencentAppld": "Appld",
    "gui.blocklyText.esp32.TencentDevice": "设备",
    "gui.blocklyText.esp32.Tencentoutput": "传出参数",
    "gui.blocklyText.esp32.TencentReporttopic": "ReportTopic",
    "gui.blocklyText.esp32.TencentContrltopic": "ContrlTopic",
    "gui.blocklyText.esp32.EasyIOTServer": "服务器",
    "gui.blocklyText.esp32.EasyIOTclient_id": "客户端id",
    "gui.blocklyText.esp32.EasyIOT_id": "IoT_id",
    "gui.blocklyText.esp32.EasyIOT_password": "密码",
    "gui.blocklyText.mega2560.digitalRead": "ᠲᠣᠭᠠᠨ ᠤ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.mega2560.analogRead": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "pwm  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠭᠠᠷᠭᠠᠬᠤ [VALUE] ",
    "gui.blocklyText.mega2560.digitalWrite": "ᠲᠣᠭᠠᠨ ᠤ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠭᠠᠷᠭᠠᠯᠲᠠ [VALUE] ",
    "gui.blocklyText.mega2560.level.low": "ᠳᠣᠣᠷ᠎ᠠᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠦᠪᠰᠢᠨ ",
    "gui.blocklyText.mega2560.level.high": "ᠥᠨᠳᠥᠷ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠦᠪᠰᠢᠨ ",
    "gui.blocklyText.mega2560.irqRead": "ᠬᠡᠲᠦ ᠤᠯᠠᠭᠠᠨ ᠰᠠᠴᠤᠷᠠᠭᠲᠤ ᠬᠦᠯᠢᠶᠡᠨ ᠠᠪᠬᠤ ᠬᠡᠰᠡᠭᠲᠦ ᠦᠨ ᠲᠣᠭᠠᠨ ᠤ ᠡᠰᠢ [PIN] ",
    "gui.blocklyText.mega2560.ultrasonicRead": "ᠬᠡᠳᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷtrigᠨᠢ [TRIG]echoᠨᠢ [ECHO] ",
    "gui.blocklyText.mega2560.buzzerSet": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠳᠠᠭᠤ ᠨᠢ [TONE] ᠬᠡᠮᠨᠡᠯ ᠨᠢ [BEAT] ",
    "gui.blocklyText.mega2560.harf": "1/2 ",
    "gui.blocklyText.mega2560.quarter": "1/4",
    "gui.blocklyText.mega2560.oneInEighth": "1/8 ",
    "gui.blocklyText.mega2560.wholeBeat": "ᠪᠦᠬᠦᠯᠢ ᠴᠣᠬᠢᠯᠭ᠎ᠠ ",
    "gui.blocklyText.mega2560.doubleBeat": "ᠬᠣᠣᠰ ᠴᠣᠬᠢᠯᠭ᠎ᠠ ",
    "gui.blocklyText.mega2560.stop": "ᠵᠣᠭᠰᠣᠭᠠᠬᠤ ",
    "gui.blocklyText.mega2560.servoSet": "[PIN] pwm  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠦᠢᠯᠡᠴᠢᠯᠡᠬᠦ ᠵᠠᠯᠠᠭᠤᠷ ᠮᠠᠰᠢᠨ ᠪᠠᠶᠢᠷᠢᠯᠠᠭᠤᠯᠬᠤ [DEGREE]",
    "gui.blocklyText.mega2560.setBaud": "ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [SERIAL] ᠫᠤᠲ᠋ᠲ᠋ᠸᠷ ᠨᠤᠷᠮᠠ ᠨᠢ [BAUD] ",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "[SERIAL] ᠡᠴᠡ [READTYPE] ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.mega2560.serialPort0": " ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 0 ",
    "gui.blocklyText.mega2560.serialPort1": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 1 ",
    "gui.blocklyText.mega2560.serialPort2": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 2 ",
    "gui.blocklyText.mega2560.serialPort3": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 3 ",
    "gui.blocklyText.mega2560.dataReadable": "[SERIAL] ᠤᠩᠰᠢᠵᠤ ᠪᠣᠯᠬᠤ  ᠳ᠋ᠠᠢᠲ᠋ᠠ ᠲᠠᠶ ᠤᠤ ︖ ",
    "gui.blocklyText.mega2560.readSerialData": "[SERIAL] ᠳ᠋ᠠᠶᠢᠲ᠋ᠠ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.mega2560.stringOutput": "ᠦᠰᠦᠭ ᠲᠡᠮᠲᠡᠭ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ᠭᠠᠷᠭᠠᠬᠤ ",
    "gui.blocklyText.mega2560.originalOutput": "ᠠᠩᠬᠠᠨ ᠤ ᠭᠠᠷᠭᠠᠯᠲᠠ",
    "gui.blocklyText.mega2560.hexOutput": "ᠠᠷᠪᠠᠨ ᠵᠢᠷᠭᠤᠭᠠ ᠪᠠᠷ ᠳᠠᠪᠰᠢᠬᠤ ᠳᠦᠷᠢᠮ ᠤᠨ ᠭᠠᠷᠭᠠᠯᠲᠠ ",
    "gui.blocklyText.mega2560.wrap": "ᠮᠥᠷ ᠰᠣᠯᠢᠬᠤ ",
    "gui.blocklyText.mega2560.noWrap": "ᠮᠥᠷ ᠰᠣᠯᠢᠬᠤ ᠦᠭᠡᠢ ",
    "gui.blocklyText.mega2560.readPinLevel": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠤᠨ ᠯᠤᠭᠰᠢᠯ ᠤᠨ ᠬᠡᠪᠴᠢᠶᠡ ᠢ ᠤᠩᠰᠢᠬᠤ ᠬᠡᠪ ᠨᠢ [LEVEL] ᠴᠠᠭ ᠠᠴᠠ ᠬᠡᠲᠦᠷᠡᠭᠰᠡᠨ ᠴᠠᠭ [TIMEOUT] ",
    "gui.blocklyText.mega2560.readTimer": "ᠰᠢᠰᠲ᠋ᠧᠮ ᠭᠦᠢᠴᠡᠳᠬᠡᠬᠦ ᠴᠠᠭ ",
    "gui.blocklyText.beetleProMini.Starts": "BeetleProMini ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega 2560 ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ",
    "gui.blocklyText.arduino.setDigital": "ᠲᠣᠭᠠᠨ ᠤ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠤᠨ ᠭᠠᠷᠭᠠᠬᠤ ᠨᠢ [LEVEL] ",
    "gui.blocklyText.arduino.readDigital": "ᠲᠣᠭᠠᠨ ᠤ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.arduino.readSimilation": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN]",
    "gui.blocklyText.arduino.setPwm": "Pwm  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠭᠠᠷᠭᠠᠬᠤ [VALUE] ",
    "gui.blocklyText.arduino.readUlrasonic": "ᠬᠡᠳᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷtrigᠨᠢ [TRIG]echoᠨᠢ [ECHO] ",
    "gui.blocklyText.arduino.trumpet": "ᠠᠯᠬᠤᠴᠠ ᠶᠢᠨ [PIN] ᠯᠠᠪᠠᠶ ᠬᠥᠭ [TONE] ᠠᠢᠵᠠᠮ ᠨᠢ [BEAT] ",
    "gui.blocklyText.arduino.serialSendStr": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠠᠮᠠᠰᠠᠷ [STRING] [WAY], [LINE]",
    "gui.blocklyText.arduino.originalOutput": "ᠠᠩᠬᠠᠨ ᠤ ᠭᠠᠷᠭᠠᠯᠲᠠ ",
    "gui.blocklyText.arduino.stringOutput": "ᠦᠰᠦᠭ ᠲᠡᠮᠲᠡᠭ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ᠭᠠᠷᠭᠠᠬᠤ ",
    "gui.blocklyText.arduino.hexOutput": "ᠠᠷᠪᠠᠨ ᠵᠢᠷᠭᠤᠭᠠ ᠪᠠᠷ ᠳᠠᠪᠰᠢᠬᠤ ᠳᠦᠷᠢᠮ ᠤᠨ ᠭᠠᠷᠭᠠᠯᠲᠠ ",
    "gui.blocklyText.arduino.wrap": "ᠮᠥᠷ ᠰᠣᠯᠢᠬᠤ ",
    "gui.blocklyText.arduino.noWrap": "ᠮᠥᠷ ᠰᠣᠯᠢᠬᠤ ᠦᠭᠡᠢ ",
    "gui.blocklyText.arduino.readInfrared": "ᠬᠡᠲᠦ ᠤᠯᠠᠭᠠᠨ ᠰᠠᠴᠤᠷᠠᠭᠲᠤ ᠬᠦᠯᠢᠶᠡᠨ ᠠᠪᠬᠤ ᠬᠡᠰᠡᠭᠲᠦ ᠦᠨ ᠲᠣᠭᠠᠨ ᠤ ᠡᠰᠢ [PIN] ",
    "gui.blocklyText.arduino.setBaud": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠮ ᠤᠨ ᠪᠦᠲ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ ᠨᠤᠷᠮᠠ ᠨᠢ [BAUD] ",
    "gui.blocklyText.arduino.readPinLevel": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠤᠨ ᠯᠤᠭᠰᠢᠯ ᠤᠨ ᠬᠡᠪᠴᠢᠶᠡ ᠢ ᠤᠩᠰᠢᠬᠤ ᠬᠡᠪ ᠨᠢ [LEVEL] ᠴᠠᠭ ᠠᠴᠠ ᠬᠡᠲᠦᠷᠡᠭᠰᠡᠨ ᠴᠠᠭ [TIMEOUT] ",
    "gui.blocklyText.arduino.noSound": "ᠳᠠᠭᠤ ᠦᠭᠡᠢ",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Nano ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.vortex.vortexStart": "Vortex ᠮᠠᠰᠢᠨ ᠬᠥᠮᠦᠨ ᠦ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.vortex.vortexMoveSpeed": "Vortex ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ ᠪᠠ [SPEED] ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIRECTION] ",
    "gui.blocklyText.vortex.forward": "ᠤᠷᠤᠭᠰᠢᠯᠠᠬᠤ ",
    "gui.blocklyText.vortex.backup": "ᠤᠬᠤᠷᠢᠬᠤ ",
    "gui.blocklyText.vortex.turnLeft": "ᠵᠡᠭᠦᠨᠰᠢ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.vortex.turnRight": "ᠪᠠᠷᠠᠭᠤᠨᠰᠢ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.vortex.setMotor": " ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ [MOTOR] ᠪᠠᠶᠢᠷᠢᠯᠠᠭᠤᠯᠬᠤ [SPEED] ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIRECTION] ",
    "gui.blocklyText.vortex.leftWheel": "ᠵᠡᠭᠦᠨ ᠬᠦᠷᠳᠦ ",
    "gui.blocklyText.vortex.rightWheel": "ᠪᠠᠷᠠᠭᠤᠨ ᠬᠦᠷᠳᠦ ",
    "gui.blocklyText.vortex.setExpression": "[EXPRESSION] ᠰᠡᠳᠬᠢᠯᠭᠡ ᠶᠢᠨ ᠢᠯᠡᠷᠡᠯ ᠣᠨ ᠦᠩᠭᠡ ᠨᠢ [COLOR] ",
    "gui.blocklyText.vortex.ledLights": "[DIRECTION] [LIGHT] [SECOND] ᠰᠧᠺᠦ᠋ᠨ᠋ᠳ᠋ ᠦᠨ ᠳᠣᠲᠣᠭᠠᠳᠤ ᠬᠤᠪᠢᠷᠠᠯᠲᠠ ᠨᠢ [COLOR] ",
    "gui.blocklyText.vortex.all": "ᠪᠦᠭᠦᠳᠡ ",
    "gui.blocklyText.vortex.rightAhead": "ᠠᠰ ᠡᠮᠦᠨ᠎ᠡ ",
    "gui.blocklyText.vortex.leftRear": "ᠵᠡᠭᠦᠨ ᠬᠣᠢᠰᠢ",
    "gui.blocklyText.vortex.rightRear": "ᠪᠠᠷᠠᠭᠤᠨ ᠬᠣᠢᠰᠢ ",
    "gui.blocklyText.vortex.rightAstern": "ᠠᠰ ᠬᠣᠶᠢᠨᠠ ",
    "gui.blocklyText.vortex.topLights": "ᠳᠡᠭᠡᠪᠦᠷ ᠳ᠋ᠧᠩ ",
    "gui.blocklyText.vortex.bottomLights": "ᠰᠠᠭᠤᠷᠢ ᠳ᠋ᠧᠩ ",
    "gui.blocklyText.vortex.vortexStop": "vortex ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ ᠬᠢᠬᠦ ᠪᠡᠨ ᠵᠣᠭᠰᠤᠬᠤ",
    "gui.blocklyText.vortex.setVolume": "ᠳᠠᠭᠤᠨ ᠤ ᠬᠡᠮᠵᠢᠶᠡ ᠶᠢᠨ ᠶᠡᠬᠡ ᠪᠠᠭ᠎ᠠ ᠨᠢ [VALUE] ",
    "gui.blocklyText.vortex.playMusic": "[VALUE] ᠠᠩᠬᠠᠨ ᠤ ᠬᠥᠭᠵᠢᠮ ᠢ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ",
    "gui.blocklyText.vortex.stopMusic": "ᠳᠠᠭᠤᠤ ᠪᠠᠨ ᠵᠣᠭᠰᠤᠭᠠᠬᠤ",
    "gui.blocklyText.vortex.startDance": "[VALUE] ᠪᠥᠵᠢᠭ  ᠢ ᠪᠥᠵᠢᠭᠯᠡᠬᠦ",
    "gui.blocklyText.vortex.stopDance": " ᠪᠥᠵᠢᠭᠯᠡᠬᠦ ᠪᠡᠨ  ᠵᠣᠭᠰᠤᠬᠤ",
    "gui.blocklyText.vortex.barrierDetection": "ᠡᠮᠦᠨ᠎ᠡ ᠲᠠᠯᠠ ᠳᠤᠨᠢ ᠰᠠᠭᠠᠳ ᠪᠠᠶᠬᠤ ᠤᠤ ? ",
    "gui.blocklyText.vortex.readSensor": "[SENSOR] ᠴᠠᠢᠢᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠦᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.vortex.setSensor": "ᠴᠠᠢᠢᠷᠠᠴᠠ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠦᠨ ᠬᠢᠨᠠᠨ ᠡᠵᠡᠮᠰᠢᠯ ᠦᠨ ᠬᠤᠪᠢᠷᠠᠯᠲᠠ ᠶᠢᠨ ᠬᠡᠮᠵᠢᠶ᠎ᠡ [VALUE] ",
    "gui.blocklyText.vortex.sensorMeetBlack": "ᠴᠠᠢᠢᠷᠠᠴᠠ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ [SENSOR] ᠬᠠᠷ᠎ᠠ ᠥᠩᠭᠡ ᠲᠠᠢ ᠤᠴᠠᠷᠠᠬᠤ ᠤᠤ ? ",
    "gui.blocklyText.vortex.positiveFrontLeft": "ᠠᠰ ᠵᠡᠭᠦᠨ ᠡᠮᠦᠨ᠎ᠡ ",
    "gui.blocklyText.vortex.frontLeft": "ᠵᠡᠭᠦᠨ ᠤᠷᠤᠭᠰᠢ ",
    "gui.blocklyText.vortex.positiveFrontRight": "ᠠᠰ ᠪᠠᠷᠠᠭᠤᠨ ᠡᠮᠦᠨᠡ",
    "gui.blocklyText.vortex.frontRight": "ᠪᠠᠷᠠᠭᠤᠨ ᠡᠮᠦᠨ᠎ᠡ ",
    "gui.blocklyText.vortex.backRight": "ᠪᠠᠷᠠᠭᠤᠨ ᠬᠣᠢᠲᠤ ",
    "gui.blocklyText.vortex.backLeft": "ᠵᠡᠭᠦᠨ ᠬᠣᠢᠰᠢ",
    "gui.blocklyText.vortex.initialOldPCB": "ᠬᠠᠭᠤᠴᠢᠨ ᠬᠡᠪᠯᠡᠯ ᠳ᠋ᠤ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ (ᠬᠥᠬᠡPCB)",
    "pen.categoryName": "ᠪᠢᠷ ",
    "pen.changeColorParam": "ᠪᠢᠷ ᠦᠨ [COLOR_PARAM]  ᠢ [VALUE]ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "pen.changeHue": "ᠪᠢᠷ ᠦᠨ ᠥᠩᠭᠡ ᠶᠢ [HUE] ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "pen.changeShade": "ᠪᠢᠷ ᠦᠨ ᠭᠡᠷᠡᠯᠲᠦᠴᠡ ᠶᠢ [SHADE] ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "pen.changeSize": " ᠪᠢᠷ ᠦᠨ ᠥᠷᠭᠡᠴᠡ ᠶᠢ [SIZE] ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "pen.clear": "ᠪᠦᠬᠦᠨ ᠢ ᠠᠷᠢᠯᠭᠠᠬᠤ",
    "pen.colorMenu.brightness": " ᠭᠡᠷᠡᠯᠲᠦᠴᠡ",
    "pen.colorMenu.color": " ᠥᠩᠭᠡ",
    "pen.colorMenu.saturation": " ᠥᠩᠭᠡ ᠶᠢᠨ ᠬᠠᠨᠤᠴᠠ",
    "pen.colorMenu.transparency": " ᠨᠡᠪᠲᠡᠷᠡᠴᠡ",
    "pen.penDown": "ᠵᠢᠷᠤᠬᠤ",
    "pen.penUp": "ᠵᠢᠷᠤᠬᠤ ᠪᠠᠨ ᠵᠣᠭᠰᠤᠬᠤ",
    "pen.setColor": " ᠪᠢᠷ ᠦᠨ ᠥᠩᠭᠡ ᠶᠢ [COLOR] ᠪᠣᠯᠭᠠᠬᠤ",
    "pen.setColorParam": "ᠪᠢᠷ ᠦᠨ [COLOR_PARAM]  ᠢ [VALUE]ᠪᠣᠯᠭᠠᠬᠤ",
    "pen.setHue": "ᠪᠢᠷ ᠦᠨ ᠥᠩᠭᠡ ᠶᠢ [HUE]ᠪᠣᠯᠭᠠᠬᠤ",
    "pen.setShade": "ᠪᠢᠷ ᠦᠨ ᠭᠡᠷᠡᠯᠲᠦᠴᠡ ᠶᠢ [SHADE] ᠪᠣᠯᠭᠠᠬᠤ",
    "pen.setSize": " ᠪᠢᠷ ᠦᠨ ᠥᠷᠭᠡᠴᠡ ᠶᠢ [SIZE]  ᠪᠣᠯᠭᠠᠬᠤ",
    "pen.stamp": "ᠲᠠᠮᠠᠭ᠎ᠠ",
    "music.changeTempo": " ᠬᠤᠷᠳᠤᠴᠠ ᠶᠢ [TEMPO] ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "music.drumBass": "(2) 低音鼓",
    "music.drumBongo": "(13) 邦戈鼓",
    "music.drumCabasa": "(15) 卡巴萨",
    "music.drumClaves": "(9) 音棒",
    "music.drumClosedHiHat": "(6) 闭击踩镲",
    "music.drumConga": "(14) 康加鼓",
    "music.drumCowbell": "(11) 牛铃",
    "music.drumCrashCymbal": "(4) 碎音钹",
    "music.drumCuica": "(18) 锯加鼓",
    "music.drumGuiro": "(16) 刮瓜",
    "music.drumHandClap": "(8) 手掌",
    "music.drumOpenHiHat": "(5) 开击踩镲",
    "music.drumSideStick": "(3) 敲鼓边",
    "music.drumSnare": "(1) 小军鼓",
    "music.drumTambourine": "(7) 铃鼓",
    "music.drumTriangle": "(12) 三角铁",
    "music.drumVibraslap": "(17) 颤音器",
    "music.drumWoodBlock": "(10) 木鱼",
    "music.getTempo": " ᠬᠤᠷᠳᠤᠴᠠ",
    "music.instrumentBass": "(6) 贝斯",
    "music.instrumentBassoon": "(14) 巴松管",
    "music.instrumentCello": "(8) 大提琴",
    "music.instrumentChoir": "(15) 唱诗班",
    "music.instrumentClarinet": "(10) 单簧管",
    "music.instrumentElectricGuitar": "(5) 电吉他",
    "music.instrumentElectricPiano": "(2) 电钢琴",
    "music.instrumentFlute": "(12) 长笛",
    "music.instrumentGuitar": "(4) 吉他",
    "music.instrumentMarimba": "(19) 马林巴琴",
    "music.instrumentMusicBox": "(17) 八音盒",
    "music.instrumentOrgan": "(3) 风琴",
    "music.instrumentPiano": "(1) 钢琴",
    "music.instrumentPizzicato": "(7) 拨弦",
    "music.instrumentSaxophone": "(11) 萨克斯管",
    "music.instrumentSteelDrum": "(18) 钢鼓",
    "music.instrumentSynthLead": "(20) 合成主音",
    "music.instrumentSynthPad": "(21) 合成柔音",
    "music.instrumentTrombone": "(9) 长号",
    "music.instrumentVibraphone": "(16) 颤音琴",
    "music.instrumentWoodenFlute": "(13) 木长笛",
    "music.midiPlayDrumForBeats": " ᠬᠡᠩᠭᠡᠷᠭᠡ [DRUM] ᠶᠢ [BEATS] ᠴᠣᠬᠢᠯᠭ᠎ᠠ  ᠬᠥᠭᠵᠢᠮᠳᠡᠬᠦ",
    "music.midiSetInstrument": " ᠬᠥᠭᠵᠢᠮᠳᠡᠭᠦᠷ ᠢ[INSTRUMENT] ᠪᠣᠯᠭᠠᠬᠤ",
    "music.playDrumForBeats": " ᠬᠡᠩᠭᠡᠷᠭᠡ [DRUM] ᠶᠢ [BEATS] ᠴᠣᠬᠢᠯᠭ᠎ᠠ  ᠬᠥᠭᠵᠢᠮᠳᠡᠬᠦ",
    "music.playNoteForBeats": "[NOTE]ᠨᠣᠲ ᠢ [BEATS]ᠴᠣᠬᠢᠯᠭ᠎ᠠ ᠬᠥᠭᠵᠢᠮᠳᠡᠬᠦ",
    "music.restForBeats": " [BEATS] ᠴᠣᠬᠢᠯᠭ᠎ᠠ ᠠᠮᠠᠷᠠᠬᠤ",
    "music.setInstrument": " ᠬᠥᠭᠵᠢᠮᠳᠡᠭᠦᠷ ᠢ[INSTRUMENT] ᠪᠣᠯᠭᠠᠬᠤ",
    "music.setTempo": " ᠬᠤᠷᠳᠤᠴᠠ ᠶᠢ [TEMPO]  ᠪᠣᠯᠭᠠᠬᠤ",
    "gui.blocklyText.microbitV2.logo": "徽标(V2)",
    "gui.blocklyText.microbitV2.ls": "蜂鸣器(V2)",
    "gui.blocklyText.microbit.microbitStarts": "microbit ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠪᠡ ",
    "gui.blocklyText.microbit.interruptExcute": "ᠲᠠᠰᠤᠯᠤᠯ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢᠨ [MODE] ᠭᠦᠢᠴᠡᠳᠬᠡᠯ ",
    "gui.blocklyText.microbit.cancleInterrupt": "ᠲᠠᠰᠤᠯᠳᠠᠯ ᠢ ᠦᠭᠡᠶᠢᠰᠬᠡᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "ᠤᠲᠠᠰᠤ ᠦᠭᠡᠢ ᠪᠡᠷ  ᠳ᠋ᠠᠢᠲ᠋ᠠ ᠬᠤᠷᠢᠶᠠᠬᠤ ",
    "gui.blocklyText.microbit.whenBtnPress": "ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢ ᠳᠠᠷᠤᠬᠤ [REFERENCE] ",
    "gui.blocklyText.microbit.whenPinConnected": "ᠵᠠᠯᠭᠠᠭᠠᠰᠤ [REFERENCE] ",
    "gui.blocklyText.microbit.whenPosChange": "ᠵᠠᠯᠭᠠᠭᠠᠰᠤ [REFERENCE] ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠦᠭᠳᠡᠬᠦ ᠳ᠋ᠠᠩ ",
    "gui.blocklyText.microbit.showFont": "ᠦᠵᠡᠭᠦᠯᠬᠦ ᠵᠢᠷᠤᠭ [PIC]",
    "gui.blocklyText.microbit.showInput": "ᠦᠵᠡᠭᠦᠯᠬᠦ ᠦᠰᠦᠭ [TEXT] ",
    "gui.blocklyText.microbit.stopAnimation": "ᠥᠩᠬᠥᠷᠢᠭᠡ ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨᠲᠦ ᠵᠢᠷᠤᠭ ᠢ ᠵᠣᠭᠰᠣᠭᠠᠬᠤ ",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] ᠪᠠᠶᠢᠷᠢᠰᠢᠯX: [XAXIS]Y: [YXAXIS] ᠠᠯᠢᠪᠠ ᠴᠡᠭ ᠣᠨ ᠵᠢᠭᠰᠠᠯ ᠢ ᠤᠨᠲᠠᠷᠠᠭᠠᠬᠤ ",
    "gui.blocklyText.microbit.hideAllLights": " ᠪᠦᠬᠦ ᠵᠢᠭᠰᠠᠭᠠᠯ ᠢ ᠤᠨᠲᠠᠷᠠᠭᠠᠬᠤ",
    "gui.blocklyText.microbit.microbitEnableLight": "ᠴᠡᠭᠲᠦ ᠳᠡᠯᠭᠡᠴᠡ [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "ᠭᠡᠷᠡᠯᠲᠦᠴᠡ ",
    "gui.blocklyText.microbit.showLightWithBrightness": "ᠭᠡᠷᠡᠯᠲᠦᠴᠡ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "ᠭᠢᠷᠠᠬᠠᠶ ᠪᠠᠶᠢᠷᠢᠰᠢᠯX: [XAXIS]Y: [YXAXIS] ᠭᠡᠷᠡᠯᠲᠦᠴᠡ [BRIGHTVALUE] ",
    "gui.blocklyText.microbit.playSound": "ᠵᠠᠯᠭᠠᠭᠠᠰᠤ [PIN] ᠠᠷᠤ ᠲᠠᠪᠴᠠᠩ ᠣᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠳᠠᠭᠤ [SOUND] ",
    "gui.blocklyText.microbit.playSoundUntil": "ᠵᠠᠯᠭᠠᠭᠠᠰᠤ [PIN] ᠳᠠᠭᠤ [SOUND]  ᠢ ᠳᠠᠭᠤᠰᠲᠠᠯᠠ ᠲᠠᠯᠪᠢᠨᠠ ",
    "gui.blocklyText.microbit.playNote": "ᠵᠠᠯᠭᠠᠭᠠᠰᠤ [PIN]  ᠨᠣᠲ [NOTE]  ᠶᠢ [BEAT] ᠴᠣᠬᠢᠯᠭ᠎ᠠ ᠲᠠᠯᠪᠢᠨᠠ ",
    "gui.blocklyText.microbit.true": "ᠬᠡᠷᠡᠭᠯᠡᠬᠦ",
    "gui.blocklyText.microbit.false": "ᠰᠤᠯᠠᠯᠠᠬᠤ",
    "gui.blocklyText.maqueen.playSound": "ᠵᠠᠯᠭᠠᠭᠠᠰᠤ P0  ᠶᠢᠨ ᠳᠠᠭᠤ [SOUND]  ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠦᠨᠡ",
    "gui.blocklyText.maqueen.playSoundUntil": "ᠵᠠᠯᠭᠠᠭᠠᠰᠤ P0 ᠳᠠᠭᠤ [SOUND] ᠳᠠᠭᠤᠰᠲᠠᠯᠠ ᠪᠠᠨ ᠲᠠᠯᠪᠢᠨᠠ ",
    "gui.blocklyText.maqueen.playNote": "ᠵᠠᠯᠭᠠᠭᠠᠰᠤ P0  ᠶᠢᠨ  ᠨᠣᠲ [NOTE]  ᠶᠢ [BEAT] ᠴᠣᠬᠢᠯᠭ᠎ᠠ ᠲᠠᠯᠪᠢᠨᠠ ",
    "gui.blocklyText.maqueen.RGB0": "RGB0 (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1 (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2 (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3 (3)",
    "gui.blocklyText.maqueen.All": "ᠪᠦᠭᠦᠳᠡ (-1)",
    "gui.blocklyText.microbit.changeTempo": "ᠳᠠᠭᠤᠨ ᠤ ᠬᠤᠷᠳᠤᠴᠠ (bpm)  ᠶᠢ [VALUE] ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ ",
    "gui.blocklyText.microbit.setTempo": "ᠳᠠᠭᠤᠨ ᠤ ᠬᠤᠷᠳᠤᠴᠠ (bpm)  ᠶᠢ [VALUE] ᠪᠣᠯᠭᠠᠬᠤ",
    "gui.blocklyText.microbit.getTempo": "ᠳᠠᠭᠤᠨ ᠤ ᠬᠤᠷᠳᠤᠴᠠ (bpm)",
    "gui.blocklyText.microbit.stopSound": "ᠠᠷᠤ ᠲᠠᠢᠰᠠᠨ ᠳᠤ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠶᠢ ᠵᠣᠭᠰᠣᠭᠠᠬᠤ ",
    "gui.blocklyText.microbit.btnIsPressed": "ᠳᠠᠷᠤᠭᠤᠯ [BUTTON] ᠳᠠᠷᠤᠭᠳᠠᠭᠰᠠᠨ  ᠤᠤ ? ",
    "gui.blocklyText.microbit.pinIsConnected": "ᠵᠠᠯᠭᠠᠭᠠᠰᠤ [PIN]  ᠢ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠦᠭᠰᠡᠨ   ᠤᠤ? ",
    "gui.blocklyText.microbit.isMove": "ᠣᠳᠣᠬᠠᠨ ᠤ ᠪᠠᠢᠳᠠᠯ ᠨᠢ [TYPE]  ᠤᠤ ? ",
    "gui.blocklyText.microbit.compass": "ᠵᠦᠭᠯᠡᠭᠦᠷ ᠦᠨ ᠴᠢᠭᠯᠡᠯ  ᠢ ᠤᠩᠰᠢᠬᠤ ( ° )",
    "gui.blocklyText.microbit.temperature": " ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ (℃)",
    "gui.blocklyText.microbit.digitalWrite": "ᠲᠣᠭᠠᠨ ᠤ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠵᠤ [VALUE] ᠶᠢ ᠭᠠᠷᠭᠠᠬᠤ",
    "gui.blocklyText.microbit.digitalRead": "ᠲᠣᠭᠠᠨ ᠤ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.microbit.analogWrite": "ᠲᠥᠰᠦᠭᠡᠳᠦᠭᠦᠯᠦᠭᠰᠡᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠭᠠᠷᠭᠠᠬᠤ [VALUE] ",
    "gui.blocklyText.microbit.analogRead": " ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN]",
    "gui.blocklyText.microbit.up": "ᠲᠡᠮᠳᠡᠭ ᠳᠡᠭᠡᠭᠰᠢ ᠬᠠᠨᠳᠤᠬᠤ ",
    "gui.blocklyText.microbit.down": "ᠲᠡᠮᠳᠡᠭ ᠳᠣᠷᠣᠭᠰᠢ ᠬᠠᠨᠳᠤᠬᠤ ",
    "gui.blocklyText.microbit.left": "ᠵᠡᠭᠦᠨᠰᠢ ᠬᠡᠯᠪᠡᠶᠢᠬᠦ ",
    "gui.blocklyText.microbit.right": "ᠪᠠᠷᠠᠭᠤᠨᠰᠢ ᠬᠡᠯᠪᠡᠶᠢᠬᠦ ",
    "gui.blocklyText.microbit.faceUp": "ᠳᠡᠯᠭᠡᠴᠡ ᠳᠡᠭᠡᠭᠰᠢ ᠬᠠᠨᠳᠤᠨᠠ ",
    "gui.blocklyText.microbit.faceDown": "ᠳᠡᠯᠭᠡᠴᠡ ᠳᠣᠷᠣᠭᠰᠢ ᠬᠠᠨᠳᠤᠨᠠ ",
    "gui.blocklyText.microbit.freefall": "ᠴᠢᠯᠥᠭᠡᠲᠦ ᠤᠨᠠᠯᠲᠠ ᠶᠢᠨ ᠪᠡᠶᠡ ᠶᠢᠨ ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ ",
    "gui.blocklyText.microbit.3g": "3g ",
    "gui.blocklyText.microbit.6g": "6g ",
    "gui.blocklyText.microbit.8g": "8g ",
    "gui.blocklyText.microbit.shake": "ᠲᠠᠢᠪᠠᠭᠠᠨᠠᠬᠤ ",
    "gui.blocklyText.microbit.show": " ᠨᠣᠴᠤᠭᠠᠬᠤ",
    "gui.blocklyText.microbit.start": "打开",
    "gui.blocklyText.microbit.stop": "关闭",
    "gui.blocklyText.microbit.hide": "ᠤᠨᠲᠠᠷᠠᠬᠤ ",
    "gui.blocklyText.microbit.low": "ᠳᠣᠣᠷ᠎ᠠ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠦᠪᠰᠢᠨ ",
    "gui.blocklyText.microbit.high": "ᠥᠨᠳᠥᠷ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠦᠪᠰᠢᠨ ",
    "gui.blocklyText.microbit.microbitReadBrightness": " ᠣᠷᠴᠢᠨ ᠢ ᠭᠡᠷᠡᠯ ᠦᠨ ᠬᠦᠴᠦᠯᠡᠴᠡ ᠶᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] ᠷᠠᠳᠢᠣ᠋ ᠬᠣᠯᠪᠣᠭ᠎ᠠ ",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "ᠤᠲᠠᠰᠤ ᠦᠭᠡᠢ ᠰᠤᠪᠠᠭ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ ᠨᠢ [NUM] ",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "ᠷᠠᠳᠢᠣ᠋ ᠪᠠᠷ ᠢᠯᠡᠭᠡᠬᠦ [TEXT] ",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "ᠷᠠᠳᠢᠣ᠋ ᠬᠤᠷᠢᠶᠠᠬᠤ  ᠳ᠋ᠠᠢᠲ᠋ᠠ ",
    "gui.blocklyText.microbit.strength": "ᠬᠦᠴᠦᠯᠡᠴᠡ ",
    "gui.blocklyText.microbit.acceleration": "ᠬᠤᠷᠳᠤᠳᠬᠠᠯ ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ [TYPE] ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.microbit.magneticForce": "ᠰᠣᠷᠢᠨᠵᠢᠨ ᠬᠦᠴᠦᠨ ᠦ ᠬᠡᠮᠵᠢᠭᠦᠷ ᠦᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ [TYPE]",
    "gui.blocklyText.microbit.microbitAccurateCompass": "校准电子罗盘",
    "gui.blocklyText.microbit.microbitGetSoundLevel": "读取声音强度(V2)",
    "gui.blocklyText.microbit.print": " [DATA] ᠶᠢ ᠳᠠᠷᠤᠮᠠᠯᠯᠠᠬᠤ",
    "gui.blocklyText.microbit.goUp": "ᠳᠡᠭᠡᠭᠰᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.microbit.goDown": "ᠳᠣᠷᠣᠭᠰᠢᠯᠠᠬᠤ ",
    "gui.blocklyText.microbit.change": "电平变化",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "[WirelessData] ᠢ ᠬᠦᠯᠢᠶᠡᠨ ᠠᠪᠬᠤ ᠳᠤ",
    "gui.blocklyText.microbit.wirelessData": "ᠤᠲᠠᠰᠤ ᠦᠭᠡᠢ ᠳ᠋ᠠᠢᠲ᠋ᠠ",
    "gui.blocklyText.calliope.whenBtnPress": "当 [REFERENCE] 按钮按下",
    "gui.blocklyText.calliope.calliopeEnableLight": "点阵屏 [ENABLE]",
    "gui.blocklyText.calliope.hideAllLights": "熄灭所有点阵",
    "gui.blocklyText.calliope.playSound": "接口 [PIN] 后台播放声音 [SOUND]",
    "gui.blocklyText.calliope.playSoundUntil": "接口 [PIN] 播放声音 [SOUND] 直到结束",
    "gui.blocklyText.calliope.playNote": "接口 [PIN] 播放音符 [NOTE] [BEAT] 拍",
    "gui.blocklyText.calliope.calliopeReadBrightness": "读取环境光强度",
    "gui.blocklyText.calliope.whenPinConnected": "当接口 [REFERENCE] 被接通",
    "gui.blocklyText.calliope.loudness": "读取响度值",
    "gui.blocklyText.calliope.rotation": "读取旋转角度(°) [TYPE]",
    "gui.blocklyText.calliope.calliopeControlSinglelMotor": "单电机控制 [RANGE]%",
    "gui.blocklyText.calliope.calliopeSingleMotorStop": "单电机 [STOPTYPE]",
    "gui.blocklyText.calliope.calliopeDoubleMotor": "控制电机 [MOTORTYPE] [RANGE]%",
    "gui.blocklyText.calliope.calliopeDoubleMotorStop": "停止电机 [MOTORTYPE]",
    "gui.blocklyText.calliope.calliopeLight": "设置LED为 [COLOR]",
    "gui.blocklyText.calliope.setRGBColor": "红 [RED] 绿 [GREEN] 蓝 [BLUE] 白 [WHITE]",
    "gui.blocklyText.calliope.calliopeCloseRGB": "关闭内置LED",
    "gui.blocklyText.calliope.pitch": "旋转",
    "gui.blocklyText.calliope.roll": "横滚",
    "gui.blocklyText.calliope.boardbuzzer": "板载蜂鸣器",
    "gui.blocklyText.calliope.motorbreak": "停止",
    "gui.blocklyText.calliope.motorcoast": "滑行",
    "gui.blocklyText.calliope.motorsleep": "睡眠",
    "gui.blocklyText.calliope.open": "打开",
    "gui.blocklyText.calliope.close": "关闭",
    "gui.blocklyText.bos0001.readRotary": "读取[PIN]旋钮模块值",
    "gui.blocklyText.bos0002.IsPINPress": "[PIN]按钮按下?",
    "gui.blocklyText.bos0003.IsPINPress": "[PIN]自锁开关按下？",
    "gui.blocklyText.bos0004.readLight": "读取[PIN]光线模块值",
    "gui.blocklyText.bos0006.readMoisture": "读取[PIN]水分模块值",
    "gui.blocklyText.bos0007.readFlame": "读取[PIN]火焰模块值",
    "gui.blocklyText.bos0008.IsPINTouch": "[PIN]触摸接通?",
    "gui.blocklyText.bos0009.readSound": "读取[PIN]声音模块值",
    "gui.blocklyText.bos0010.readSmartGray": "读取[PIN]灰度模块值",
    "gui.blocklyText.bos0012.IsConductivitySwitch": "[PIN]电导模块接通?",
    "gui.blocklyText.bos0013.IsMotionSensor": "[PIN]运动模块触发?",
    "gui.blocklyText.bos0038.readSoilHumidity": "读取[PIN]土壤湿度值",
    "gui.blocklyText.bos0045.readUltrasonicDistance": "读取[PIN]超声波测距值",
    "gui.blocklyText.bos0016.lightBrightLED": "[CONTROL][PIN]高亮LED灯",
    "gui.blocklyText.bos0017.LEDModule": "[CONTROL][PIN]LED灯",
    "gui.blocklyText.bos0019.LEDStringLight": "[CONTROL][PIN]固定色灯带",
    "gui.blocklyText.bos0021.fanModule": "[CONTROL][PIN]风扇",
    "gui.blocklyText.bos0022.voiceRecorderModule": "[PIN]播放一次录音",
    "gui.blocklyText.maxbot.microbitStarts": "MAXbot ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.maxbot.playSound": "ᠳᠠᠭᠤ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ [SOUND] ",
    "gui.blocklyText.maxbot.playSoundUntil": "ᠳᠠᠭᠤ [SOUND]  ᠶᠢ ᠳᠠᠭᠤᠰᠲᠠᠯ᠎ᠠ ᠳᠠᠯᠪᠢᠬᠤ",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "[LEFTRIGHT] ᠮᠥᠷᠭᠥᠯᠳᠦᠪᠡ ᠃ ",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "[LEFTRIGHT] ᠬᠠᠷ᠎ᠠ ᠱᠤᠭᠤᠮ ᠢ ᠪᠠᠢᠴᠠᠭᠠᠨ ᠣᠯᠬᠤ ",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "[MOTOR] ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ ᠢ [SPEED] ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR] ",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": " ( P1 , P2 ) ᠬᠡᠳᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠦᠨ ᠵᠠᠢ ᠶᠢ ᠤᠩᠰᠢᠬᠤ ᠨᠢᠭᠡᠴᠢ [UNIT] ",
    "gui.blocklyText.sen0001.readUltrasound": "ᠬᠡᠳᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠤᠨ ᠵᠠᠶ ᠶᠢᠨ ᠨᠢᠭᠡᠴᠢ [UNIT]trigᠨᠢ [TRIG]echoᠨᠢ [ECHO] ",
    "gui.blocklyText.maxbot.FORWARD": "ᠤᠷᠤᠭᠰᠢ ",
    "gui.blocklyText.maxbot.BACKWARD": "ᠬᠣᠶᠢᠰᠢ ",
    "gui.blocklyText.motorbit.CW": "ᠴᠠᠭ ᠣᠨ ᠵᠡᠭᠦᠦ ᠳᠠᠭᠠᠤ ",
    "gui.blocklyText.motorbit.CCW": "ᠴᠠᠭ ᠣᠨ ᠰᠥᠷᠭᠡᠦ ᠴᠢᠭᠯᠡᠯ ",
    "gui.blocklyText.maxbot.CW": "ᠴᠠᠭ ᠣᠨ ᠵᠡᠭᠦᠦ ᠳᠠᠭᠠᠤ ",
    "gui.blocklyText.maxbot.CCW": "ᠴᠠᠭ ᠣᠨ ᠰᠥᠷᠭᠡᠦ ᠴᠢᠭᠯᠡᠯ ",
    "gui.blocklyText.maxbot.ANGLE": "ᠥᠨᠴᠥᠭ ",
    "gui.blocklyText.maxbot.CIRCLE": "ᠴᠠᠭᠠᠷᠢᠭ ᠤᠨ ᠲᠣᠭ᠎ᠠ ",
    "gui.blocklyText.maxbot.Left": "ᠵᠡᠭᠦᠨ ᠲᠠᠯ᠎ᠠ ",
    "gui.blocklyText.maxbot.Right": "ᠪᠠᠷᠠᠭᠤᠨ ᠲᠠᠯ᠎ᠠ ",
    "gui.blocklyText.maxbot.cm": "ᠰᠠᠨᠲ᠋ᠢᠮᠧᠲ᠋ᠷ ",
    "gui.blocklyText.maxbot.inch": "ᠢᠨᠴ ",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.MotorBit.MotorRun": " ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ [MOTOR] ᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [SPEED] ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR]",
    "gui.blocklyText.MotorBit.MotorStop": " ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ [MOTOR] ᠵᠣᠭᠰᠣᠬᠤ ",
    "gui.blocklyText.MotorBit.Stepper": "ᠦᠰᠦᠷᠦᠭᠡ ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ [STEPPER] [ROTATEDIR] [NUM] [ANGLECIRCLE] ",
    "gui.blocklyText.pythondfr0548.MotorRun": "电机 [MOTOR] 以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.pythondfr0548.MotorStop": "电机 [MOTOR] 停止",
    "gui.blocklyText.pythondfr0548.Stepper": "设置步进电机 [STEPPER] [ROTATEDIR] [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": " ᠪᠦᠭᠦᠳᠡ ",
    "gui.blocklyText.pythonspread.extensionName": "两用扩展板",
    "gui.blocklyText.pythonMotorBit.MotorRun": "电机 [MOTOR] 以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.pythonMotorBit.MotorStop": "电机 [MOTOR] 停止",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.maqueen.readUltrasonicDistance": " (P1 P2) ᠬᠡᠳᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠵᠠᠶ ᠶᠢ (cm) ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.maqueen.motorStop": "ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ ( ᠵᠡᠭᠦᠨ ᠲᠠᠯ᠎ᠠ ᠪᠠ ᠪᠠᠷᠠᠭᠤᠨ ᠲᠠᠯᠠ ᠶᠢᠨ ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ ) ᠵᠣᠭᠰᠣᠬᠤ ",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] LED ᠳ᠋ᠧᠩ ",
    "gui.blocklyText.maqueen.redLineSensor": "[LEFTRIGHT] ᠱᠤᠭᠤᠮ ᠮᠥᠷᠳᠡᠬᠦ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.maqueen.robotSpeedMove": "ᠮᠠᠰᠢᠨ ᠬᠥᠮᠦᠨ [SPEED] ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR] ",
    "gui.blocklyText.maqueen.setMotorSpeedMove": " ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ [LEFTRIGHT] ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [SPEED] ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR]",
    "gui.blocklyText.maqueen.ledLeft": "ᠵᠡᠭᠦᠨ ᠲᠠᠯ᠎ᠠ ",
    "gui.blocklyText.maqueen.ledRight": "ᠪᠠᠷᠠᠭᠤᠨ ᠲᠠᠯ᠎ᠠ ",
    "gui.blocklyText.spreadmaqueen.ledRight": "右侧(P12) (硬件不可用)",
    "gui.blocklyText.maqueen.lineSensorLeft": "ᠵᠡᠭᠦᠨ ᠲᠠᠯ᠎ᠠ ( p13) ",
    "gui.blocklyText.maqueen.lineSensorRight": "ᠪᠠᠷᠠᠭᠤᠨ ᠲᠠᠯ᠎ᠠ ( P14) ",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "ᠤᠯᠠᠭᠠᠨ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠳᠣᠬᠢᠶ᠎ᠡ ᠬᠦᠷᠲᠡᠯᠡ ᠬᠤᠷᠢᠶᠠᠬᠤ ᠣᠶᠢᠶᠠ ᠳᠤ  [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "ᠤᠯᠠᠭᠠᠨ ᠤ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠳᠣᠬᠢᠶ᠎ᠠ",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "P16 ᠤᠯᠠᠭᠠᠨ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠳᠣᠬᠢᠶ᠎ᠠ ᠤᠩᠰᠢᠬᠤ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.maqueen.numberOfLightsBright": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ P 15 ᠳ᠋ᠧᠩ ᠦᠨ ᠶᠡᠷᠦᠩᠬᠡᠶ ᠲᠣᠭ᠎ᠠ 4 ᠭᠡᠷᠡᠯᠲᠦᠴᠡ [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ P 15 ᠳ᠋ᠧᠩ ᠦᠨ ᠳ᠋ᠤᠭᠠᠷ [NUM1] ᠢᠯᠡᠷᠡᠬᠦ ᠥᠩᠭᠡ [COLOR] ",
    "gui.blocklyText.maqueen.showGradientColor": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ P15  ᠳ᠋ᠧᠩ ᠦᠨ ᠳ᠋ᠤᠭᠠᠷ [NUM1] ᠡᠴᠡ [NUM2] ᠢᠯᠡᠷᠡᠬᠦ ᠦᠩᠭᠡ ᠨᠢ ᠠᠯᠭᠤᠷ ᠬᠤᠪᠢᠷᠠᠬᠤ [COLOR1] ᠡᠴᠡ [COLOR2] ᠬᠦᠷᠲᠡᠯᠡᠬᠢ ",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ P15 ᠰᠢᠯᠵᠢᠮᠡᠯ [NUM] ᠨᠢᠭᠡᠴᠢ ",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ P15 ᠡᠷᠭᠢᠯᠲᠡ ᠰᠢᠯᠵᠢᠭᠦᠯᠬᠦ [NUM] ᠨᠢᠭᠡᠴᠢ ",
    "gui.blocklyText.maqueen.setWs2812Brightness": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ P15  ᠶᠢᠨ ᠭᠡᠷᠡᠯᠲᠦᠴᠡ [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ P15 ᠳ᠋ᠧᠩ ᠦᠨ ᠨᠣᠮᠧᠷ [NUM1] ᠡᠴᠡ [NUM2] ᠢᠯᠡᠷᠡᠬᠦ ᠪᠠᠭᠠᠨᠠ ᠶᠢᠨ ᠵᠢᠷᠤᠭ ᠤᠨ ᠣᠳᠣᠬᠢ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ [VALUE] ᠬᠠᠮᠤᠭ ᠤᠨ ᠶᠡᠬᠡ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ [MAX] ",
    "gui.blocklyText.maqueen.clear": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ P15 ᠪᠦᠬᠦᠨ ᠢᠶᠡᠷ ᠢᠶᠡᠨ ᠤᠨᠲᠠᠷᠠᠪᠠ ",
    "gui.blocklyText.maqueen.rgbColor": "ᠤᠯᠠᠭᠠᠨ [RED] ᠨᠣᠭᠣᠭᠠᠨ [GREEN] ᠬᠥᠬᠡ [BLUE] ",
    "gui.blocklyText.tello.telloStarts": "Tello 主程序开始",
    "gui.blocklyText.tello.telloControlPlane": "控制飞机",
    "gui.blocklyText.tello.telloTakeOff": "起飞",
    "gui.blocklyText.tello.telloLanding": "降落",
    "gui.blocklyText.tello.telloRise": "上升 [NUMS]cm",
    "gui.blocklyText.tello.telloDown": "下降 [NUMS]cm",
    "gui.blocklyText.tello.telloGoahead": "向前飞 [NUMS]cm",
    "gui.blocklyText.tello.telloBack": "向后飞 [NUMS]cm",
    "gui.blocklyText.tello.telloTurnLeft": "向左飞 [NUMS]cm",
    "gui.blocklyText.tello.telloTurnRight": "向右飞 [NUMS]cm",
    "gui.blocklyText.tello.telloClockwiseRotation": "顺时针旋转 [NUMS]度",
    "gui.blocklyText.tello.telloCounterclockwiseSelection": "逆时针旋转 [NUMS]度",
    "gui.blocklyText.tello.telloSpeed": "设置速度为 [NUMS]cm/s",
    "gui.blocklyText.tello.telloRoll": "翻滚[ROLL] ",
    "gui.blocklyText.tello.telloPower": "电池电量( %)",
    "gui.blocklyText.tello.telloRollForward": "向前(f)",
    "gui.blocklyText.tello.telloRollBack": "向后(b)",
    "gui.blocklyText.tello.telloRollLeft": "向左(l)",
    "gui.blocklyText.tello.telloRollRight": "向右(r)",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC]ᠴᠢᠮᠡᠭᠡ ᠣᠯᠤᠭᠰᠠᠨ ᠤᠤ？",
    "gui.blocklyText.obloq.initialParameter": "Obloq mqtt  ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ :[PARAMETER]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠬᠣᠷᠢᠶᠠᠨ ᠠᠪᠬᠤ [SERIALPORT] ( ᠨᠣᠭᠣᠭᠠᠨ ᠤᠲᠠᠰᠤ ) :[PINR] ᠲᠠᠷᠬᠠᠭᠠᠬᠤ ( ᠬᠥᠬᠡ ᠤᠲᠠᠰᠤ ) :[PINT] ",
    "gui.blocklyText.obloq.initialI2CParameter": "Obloq mqtt 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.obloq.httpInitialI2CParameter": "Obloq http 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq ᠮᠡᠳᠡᠭᠡ ᠶᠠᠪᠤᠭᠤᠯᠬᠤ [MSG] ᠡᠴᠡ [TOPIC] ",
    "gui.blocklyText.obloq.sendmsgSaveToCloudPlatform": "Obloq 发送消息 [MSG] 至 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq ᠨᠢ [TOPIC] ᠡᠴᠡ ᠢᠯᠡᠭᠡᠭᠰᠡᠨ ᠴᠢᠮᠡᠭᠡ ᠶᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq ᠮᠡᠳᠡᠭᠡ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.obloq.httpInitialParameter": "Obloqhttp ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ :[PARAMETER] [SERIALPORT] ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠬᠣᠷᠢᠶᠠᠨ ᠠᠪᠬᠤ ( ᠨᠣᠭᠣᠭᠠᠨ ᠤᠲᠠᠰᠤ ) :[PINR] ᠲᠠᠷᠬᠠᠭᠠᠬᠤ ( ᠬᠥᠬᠡ ᠤᠲᠠᠰᠤ ) :[PINT] ",
    "gui.blocklyText.obloq.httpGet": "Obloqhttp (get)URL: [URL] ᠴᠠᠭ ᠠᠴᠠ ᠬᠡᠲᠦᠷᠡᠭᠰᠡᠨ (ms) : [TIMEOUT] ",
    "gui.blocklyText.obloq.httpPost": "Obloqhttp (post)URL: [URL]Content: [CONTENT] ᠴᠠᠭ ᠠᠴᠠ ᠬᠡᠲᠦᠷᠡᠭᠰᠡᠨ (ms) : [TIMEOUT] ",
    "gui.blocklyText.obloq.httpPut": "Obloqhttp (put)URL: [URL]Content: [CONTENT] ᠴᠠᠭ ᠠᠴᠠ ᠬᠡᠲᠦᠷᠡᠭᠰᠡᠨ (ms) : [TIMEOUT] ",
    "gui.blocklyText.obloq.addSubscribe": "ᠰᠢᠨ᠎ᠡ ᠪᠡᠷ ᠨᠡᠮᠡᠭᠰᠡᠨ ᠵᠠᠬᠢᠶᠠᠯᠠᠭ᠎ᠠ [TOPIC] :[MSG] ",
    "gui.blocklyText.obloq.username": "Wi-Fi ᠨᠡᠷ᠎ᠡ ",
    "gui.blocklyText.obloq.password": "Wi-Fi ᠨᠢᠭᠤᠴᠠ ᠨᠣᠮᠧᠷ",
    "gui.blocklyText.obloq.iotService": "ᠪᠣᠳᠠᠰᠯᠢᠭ ᠰᠦᠯᠵᠢᠶᠡ ᠶᠢᠨ ᠲᠠᠪᠴᠠᠩ ᠣᠨ ᠫᠠᠷᠠᠮᠧᠲ᠋ᠷ ",
    "gui.blocklyText.obloq.iotId": "Iot-ide ",
    "gui.blocklyText.obloq.iotPwd": "Iot_pwd ",
    "gui.blocklyText.obloq.iotTopic": "Topic0 ",
    "gui.blocklyText.obloq.httpSetting": "HTTP ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ",
    "gui.blocklyText.obloq.ip": "IP ᠬᠠᠶ᠋ᠢᠭ ",
    "gui.blocklyText.obloq.port": "ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠨᠣᠮᠧᠷ ",
    "gui.blocklyText.obloq.addTopic": "Topic ᠨᠡᠮᠡᠵᠦ ᠂ ᠬᠠᠮᠤᠭ ᠣᠯᠠᠨ ᠳᠠᠭᠠᠨ 4 ᠨᠡᠮᠡᠵᠦ ᠪᠣᠯᠤᠨᠠ ",
    "gui.blocklyText.obloq.deleteTopic": "Topic  ᠶᠢ ᠬᠠᠰᠤᠬᠤ ",
    "gui.blocklyText.obloq.moreTopic": "Topic ",
    "gui.blocklyText.obloq.server": " ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ ",
    "gui.blocklyText.obloq.global": " ᠪᠦᠬᠦ ᠳᠡᠯᠡᠬᠡᠶ ",
    "gui.blocklyText.obloq.china": "ᠳᠤᠮᠳᠠᠳᠤ ᠤᠯᠤᠰ ",
    "gui.blocklyText.obloq.softwareSerial": "ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ",
    "gui.blocklyText.obloq.hardwareSerial": "ᠬᠠᠲᠠᠭᠤ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ",
    "gui.blocklyText.obloq.hardwareSerial1": "ᠬᠠᠲᠠᠭᠤ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 1",
    "gui.blocklyText.obloq.hardwareSerial2": "ᠬᠠᠲᠠᠭᠤ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 2",
    "gui.blocklyText.obloq.hardwareSerial3": "ᠬᠠᠲᠠᠭᠤ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 3",
    "gui.blocklyText.obloq.WhenReceivedData": " [TOPIC] ᠨᠢ [MSG]ᠴᠢᠮᠡᠭᠡ ᠶᠢ ᠣᠯᠬᠤ ᠳᠤ",
    "gui.blocklyText.obloq.ObloqMsg": "Obloq ᠴᠢᠮᠡᠭᠡ",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq [DEBUG] [ERROR] ᠢ ᠣᠯᠬᠤ",
    "gui.blocklyText.obloq.DEBUG": "ᠲᠤᠷᠰᠢᠯᠲᠠ ᠶᠢᠨ ᠰᠤᠷᠠᠭ ᠴᠢᠮᠡᠭᠡ",
    "gui.blocklyText.obloq.ERROR": "ᠪᠤᠷᠤᠭᠤ ᠺᠣᠳ᠋",
    "gui.blocklyText.pythonobloq.whenDebugInfo": "[NAME] 获取 [DEBUG] [ERROR]",
    "gui.blocklyText.pythonobloq.WhenReceivedData": "当 [EVENT] [TOPIC] 接收到 [MSG]",
    "gui.blocklyText.pythonobloq.DEBUG": "调试信息",
    "gui.blocklyText.pythonobloq.ERROR": "错误码",
    "gui.blocklyText.pythonobloq.ObloqMsg": "obloq消息",
    "gui.blocklyText.pythonobloq.initialI2CParameter": "[NAME] mqtt 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.pythonobloq.sendmsgToCloudPlatform": "[NAME] 发送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.pythonobloq.isReceivedData": "[NAME] [TOPIC] 接收到消息？",
    "gui.blocklyText.pythonobloq.readMsgFromCloudPlatformUno": "[NAME] 读取来自 [TOPIC] 的消息",
    "gui.blocklyText.pythonobloq.httpInitialI2CParameter": "[NAME] http 初始化 [PARAMETER] 接口I2C 地址0x16",
    "gui.blocklyText.pythonobloq.httpGet": "[NAME] http(get) url: [URL] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.pythonobloq.httpPost": "[NAME] http(post) url: [URL]content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.pythonobloq.httpPut": "[NAME] http(put) url: [URL] content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "ᠬᠡᠲᠦ ᠤᠯᠠᠭᠠᠨ ᠰᠠᠴᠤᠷᠠᠯ [TYPE] ᠬᠣᠭᠣᠯᠠᠶ ᠶᠢᠨ [PIN] ᠲᠣᠭ᠎ᠠ [VALUE] ᠪᠢᠲ ᠦᠨ ᠲᠣᠭ᠎ᠠ [BIT] ",
    "gui.blocklyText.infraredEmission.send": "Infrared send pin [PIN] Send message: address [ADDRESS] command [COMMAND]",
    "gui.blocklyText.oled2864.displayInit": "OLED 128*64初始化I2C地址 [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128 × 64 ᠳ᠋ᠦ᠋ᠭᠡᠷ [LINE] ᠮᠥᠷ ᠦᠨ ᠢᠯᠡᠷᠡᠯ [TEXT] ",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128 × 64  ᠳᠤ ᠪᠠᠶᠢᠷᠢᠰᠢᠯX:[X] Y:16* [Y] ᠢᠯᠡᠷᠡᠭᠦᠯᠦᠭᠰᠡᠨ [TEXT] ",
    "gui.blocklyText.oled2864.screenRotation": "ᠳᠡᠯᠭᠡᠴᠡ ᠡᠷᠭᠢᠯᠳᠦᠭᠡᠳ [ROTATION] ᠭᠷᠠᠳᠦ᠋ᠰ ᠬᠦᠷᠬᠦ",
    "gui.blocklyText.oled2864.clear": "OLED 128 × 64 ᠳᠡᠯᠭᠡᠴᠡ ᠤᠩᠰᠢᠵᠤ ᠠᠪᠬᠤ ",
    "gui.blocklyText.dfr0647.displayInit": "OLED 128*32初始化I2C地址 [ADDR]",
    "gui.blocklyText.dfr0647.displayShowTextInXY": "OLED 128*32 显示文字 [TEXT] 在坐标X:[X] Y:[Y]",
    "gui.blocklyText.dfr0647.clear": "OLED 128*32 清屏",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠤᠨ [DHT] [TYPE] ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.mpythonMpyPm.readLevel": "rx pin [PIN] PM concentration value [LEVEL]",
    "gui.blocklyText.hcsr04.init": "HC-SR04 ultrasonic initialization Name [NAME] Trig [PINT] Echo [PINE]",
    "gui.blocklyText.hcsr04.read": "[NAME] HC-SR04 ultrasonic distance measurement unit [UNIT]",
    "gui.blocklyText.hcsr04.readData": "读取HC-SRO4 Trig [PINT] Echo [PINE] 单位 [UNIT]",
    "gui.blocklyText.mpythonMpyPm.readCount": "rx pin [PIN] 0.1 liters of air in [DIAMETER] number of particles",
    "gui.blocklyText.sen0170.readWindspeed": "ᠰᠠᠯᠬᠢᠨ ᠤ ᠬᠤᠷᠳᠤᠴᠠ ᠶᠢᠨ ᠳᠡᠰ [PIN]  ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.sen0228.init": "初始化环境光(VEML7700)传感器",
    "gui.blocklyText.sen0228.readAmbientLight": "ᠣᠷᠴᠢᠨ ᠠᠬᠤᠢ ᠶᠢᠨ ᠭᠡᠷᠡᠯ (VEML7700 )  ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.pythonsen0228.init": "初始化环境光(VEML7700)传感器[NAME] I2C地址0x10",
    "gui.blocklyText.pythonsen0228.readAmbientLight": "读取[NAME]环境光(VEML7700)",
    "gui.blocklyText.sen0097.init": "初始化环境光(BH1750)传感器 I2C地址0x23",
    "gui.blocklyText.sen0097.readAmbientLight": "读取环境光(BH1750)",
    "gui.blocklyText.dfr0026.readAmbientLight": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠣᠷᠴᠢᠨ ᠢ ᠭᠡᠷᠡᠯ ",
    "gui.blocklyText.pythondfr0026.extensionName": "环境光",
    "gui.blocklyText.pythondfr0026.readAmbientLight": "读取引脚 [PIN] 环境光",
    "gui.blocklyText.sen0177.readDustSensor": "[TYPE] [SERIALPORT] Tx: [PINT]Rx: [PINR] ",
    "gui.blocklyText.sen0202.readGestureSensor": "ᠡᠰᠢ ᠪᠠᠷᠢᠮᠲᠠ ( D ) [PIN] ᠡᠰᠢ ᠪᠠᠷᠢᠮᠲᠠ (MCLR) [RST]  ᠶᠢᠨ ᠣᠳᠣᠬᠢ ᠪᠠᠢᠳᠠᠯ ᠨᠢ [TYPE] ᠪᠣᠯᠤᠨᠠ ᠤᠤ ? ",
    "gui.blocklyText.sen0203.readHeartRate": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] [TYPE] ᠴᠢᠭᠯᠡᠯ [SWITCH] ",
    "gui.blocklyText.pythonsen0203.init": "初始化心率传感器 [NAME] 引脚 [PIN] 模式 [MODE]",
    "gui.blocklyText.pythonsen0203.update": "[NAME] 获取一次数据",
    "gui.blocklyText.pythonsen0203.readHeartRate": "[NAME] 从数据中解析心率值（次/分）",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] LED ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.dfr0022.readGrayscale": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠴᠠᠶᠢᠴᠠ ᠶᠢᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.pythondfr0022.extensionName": "灰度",
    "gui.blocklyText.pythondfr0022.readGrayscale": "读取引脚 [PIN] 灰度值",
    "gui.blocklyText.motucamera.initIICSerial": " [MU] ᠢ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ [IICSERIAL]ᠵᠠᠯᠭᠠᠭᠤᠷ",
    "gui.blocklyText.motucamera.resetConfig": " [MU] ᠳᠤᠭᠤᠢ ᠮᠡᠳᠡᠷᠡᠬᠦ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠶᠢ ᠰᠡᠷᠭᠦᠭᠡᠬᠦ",
    "gui.blocklyText.motucamera.algorithmSwitch": " [MU] [SWITCH] ᠪᠣᠳᠤᠬᠤ ᠠᠷᠭ᠎ᠠ [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": " [MU] led [LED] ᠥᠩᠭᠡ [COLOR1]  ᠶᠢ ᠲᠠᠨᠢᠵᠤ ᠂ ᠥᠩᠭᠡ [COLOR2]  ᠢ ᠡᠰᠡ ᠲᠠᠨᠢᠪᠠ",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": " [MU] ᠪᠣᠳᠤᠬᠤ ᠠᠷᠭ᠎ᠠ [ALGORITHM] ᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ᠂ ᠴᠢᠳᠠᠮᠵᠢ [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": " [MU] ᠲᠣᠭᠠᠴᠢᠯᠠᠭᠰᠠᠨ ᠲᠦᠪᠯᠡᠷᠡᠯ ᠦᠨ ᠴᠡᠭ ᠦᠨ ᠬᠤᠪᠢᠷᠠᠯᠲᠠ [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": " [MU] ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠪᠤᠳ᠋ ᠨᠣᠷᠮ᠎ᠠ ᠶᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": " [MU] ᠺᠠᠮᠧᠷᠠ ᠶᠢᠨ ᠴᠠᠢᠷᠠᠴᠠ ᠶᠢ ᠲᠡᠩᠴᠡᠭᠦᠯᠬᠦ [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": " [MU] 设置高帧率模式 [OPENED]",
    "gui.blocklyText.motucamera.isDetect": " [MU]  [ALGORITHM] ᠢ ᠴᠠᠭᠳᠠᠨ ᠣᠯᠪᠠ",
    "gui.blocklyText.motucamera.colorRecognizeXY": " [MU] ᠥᠩᠭᠡ ᠮᠡᠳᠡᠷᠡᠬᠦ x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": " [MU] ᠥᠩᠭᠡ ᠮᠡᠳᠡᠷᠡᠬᠦ ᠥᠩᠭᠡ = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": " [MU] ᠪᠣᠳᠤᠬᠤ ᠠᠷᠭ᠎ᠠ [ALGORITHM] [POSITION]ᠣᠯᠬᠤ",
    "gui.blocklyText.motucamera.colorRecognizeChannel": " [MU] ᠥᠩᠭᠡ ᠲᠠᠨᠢᠬᠤ [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": " [MU] ᠪᠣᠳᠤᠬᠤ ᠠᠷᠭ᠎ᠠ ᠳᠦᠷᠰᠦᠲᠦ ᠺᠠᠷᠲ [CARD]",
    "gui.blocklyText.motucamera.trafficCard": " [MU] ᠪᠣᠳᠤᠬᠤ ᠠᠷᠭ᠎ᠠ᠂ ᠵᠠᠮ ᠬᠠᠷᠢᠯᠴᠠᠭᠠᠨ ᠤ ᠺᠠᠷᠲ[DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": " [MU] ᠪᠣᠳᠤᠬᠤ ᠠᠷᠭ᠎ᠠ᠂ ᠲᠣᠭᠠᠨ ᠤ ᠺᠠᠷᠲ [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": " [MU] ᠪᠣᠳᠤᠬᠤ ᠠᠷᠭ᠎ᠠ᠂  ᠥᠩᠭᠡ ᠲᠠᠨᠢᠨ ᠮᠡᠳᠡᠷᠡᠬᠦ ᠥᠩᠭᠡ = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "ᠥᠩᠭᠡ ᠰᠢᠯᠭᠠᠬᠤ",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "ᠥᠩᠭᠡ ᠲᠠᠨᠢᠬᠤ",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "ᠪᠥᠮᠪᠦᠭᠡ ᠰᠢᠯᠭᠠᠬᠤ",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "ᠬᠦᠮᠦᠨ ᠦ ᠪᠡᠶ᠎ᠡ ᠶᠢ ᠰᠢᠯᠭᠠᠬᠤ",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "ᠳᠦᠷᠰᠦᠲᠦ ᠺᠠᠷᠲ",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "ᠵᠠᠮ ᠬᠠᠷᠢᠯᠴᠠᠭᠠᠨ ᠤ ᠺᠠᠷᠲ",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "ᠲᠣᠭᠠᠨ ᠤ ᠺᠠᠷᠲ",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "ᠬᠡᠷᠡᠭᠯᠡᠬᠦ",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "ᠬᠠᠭᠠᠬᠤ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "ᠬᠠᠭᠠᠬᠤ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "ᠤᠯᠠᠭᠠᠨ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "ᠨᠣᠭᠤᠭᠠᠨ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "ᠬᠥᠬᠡ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "ᠰᠢᠷ᠎ᠠ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "ᠬᠦᠷᠢᠨ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "ᠬᠥᠬᠡᠮᠳᠦᠭ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "ᠴᠠᠭᠠᠨ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "ᠬᠠᠭᠠᠬᠤ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "ᠤᠯᠠᠭᠠᠨ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "ᠨᠣᠭᠤᠭᠠᠨ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "ᠬᠥᠬᠡ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "ᠰᠢᠷ᠎ᠠ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "ᠬᠦᠷᠢᠨ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "ᠬᠥᠬᠡᠮᠳᠦᠭ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "ᠴᠠᠭᠠᠨ",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "ᠠᠦ᠋ᠲ᠋ᠤ᠋",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "ᠬᠤᠷᠳᠤᠴᠠ ᠪᠠᠷ ᠭᠣᠣᠯᠠᠬᠤ",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "ᠲᠡᠩᠴᠡᠭᠦᠬᠡᠨ",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "ᠣᠨᠤᠪᠴᠢ ᠪᠠᠷ ᠭᠣᠣᠯᠠᠬᠤ",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "ᠠᠦ᠋ᠲ᠋ᠤ᠋",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "ᠴᠠᠭᠠᠨ ᠲᠡᠩᠴᠡᠭᠦᠷᠢ ᠶᠢ ᠪᠠᠲᠤᠯᠠᠬᠤ",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "ᠴᠠᠭᠠᠨ ᠭᠡᠷᠡᠯ ᠦᠨ ᠬᠡᠯᠪᠡᠷᠢ",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "ᠰᠢᠷ᠎ᠠ ᠭᠡᠷᠡᠯ ᠦᠨ ᠬᠡᠯᠪᠡᠷᠢ",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "ᠠᠦ᠋ᠲ᠋ᠤ᠋",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "ᠲᠡᠰ 1",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "ᠲᠡᠰ 2",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "ᠲᠡᠰ 3",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "ᠲᠡᠰ 4",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "ᠲᠡᠰ 5",
    "gui.blocklyText.motucamera.setHighFPSOff": "ᠬᠠᠭᠠᠬᠤ",
    "gui.blocklyText.motucamera.setHighFPSOn": "ᠨᠡᠭᠡᠭᠡᠬᠦ",
    "gui.blocklyText.motucamera.colorDetectBlack": "ᠬᠠᠷ᠎ᠠ",
    "gui.blocklyText.motucamera.colorDetectWhite": "ᠴᠠᠭᠠᠨ",
    "gui.blocklyText.motucamera.colorDetectRed": "ᠤᠯᠠᠭᠠᠨ",
    "gui.blocklyText.motucamera.colorDetectYellow": "ᠰᠢᠷ᠎ᠠ",
    "gui.blocklyText.motucamera.colorDetectGreem": "ᠨᠣᠭᠤᠭᠠᠨ",
    "gui.blocklyText.motucamera.colorDetectCayan": "ᠬᠥᠬᠡᠮᠳᠦᠭ",
    "gui.blocklyText.motucamera.colorDetectBlue": "ᠬᠦᠬᠡ",
    "gui.blocklyText.motucamera.colorDetectPerple": "ᠬᠦᠷᠢᠨ",
    "gui.blocklyText.motucamera.colorDetectOthers": "ᠪᠤᠰᠤᠳ",
    "gui.blocklyText.motucamera.colorDetectPositionX": "ᠬᠥᠨᠳᠡᠯᠡᠨ ᠲᠡᠩᠭᠡᠯᠢᠭ",
    "gui.blocklyText.motucamera.colorDetectPositionY": "ᠭᠤᠯᠳᠤ ᠲᠡᠩᠭᠡᠯᠢᠭ",
    "gui.blocklyText.motucamera.colorDetectPositionW": "ᠥᠷᠭᠡᠴᠡ",
    "gui.blocklyText.motucamera.colorDetectPositionH": "ᠥᠨᠳᠦᠷᠴᠡ",
    "gui.blocklyText.motucamera.colorDetectPositionL": "ᠱᠤᠰᠢᠭ᠎ᠠ",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "ᠤᠯᠠᠭᠠᠨ ᠰᠤᠪᠠᠭ",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "ᠨᠥᠭᠤᠭᠠᠨ ᠰᠤᠪᠠᠭ",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "ᠬᠦᠬᠡ ᠰᠤᠪᠠᠭ",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "ᠱᠤᠰᠢᠭ᠎ᠠ",
    "gui.blocklyText.motucamera.shapeCardTick": "ᠭᠣᠬ᠎ᠠ",
    "gui.blocklyText.motucamera.shapeCardCross": "ᠵᠠᠯᠠᠮᠠᠭᠠᠢ",
    "gui.blocklyText.motucamera.shapeCardCircle": "ᠲᠣᠭᠤᠷᠢᠭ",
    "gui.blocklyText.motucamera.shapeCardSquare": "ᠳᠦᠷᠪᠡᠯᠵᠢᠨ",
    "gui.blocklyText.motucamera.shapeCardTriangle": "ᠭᠤᠷᠪᠠᠯᠵᠢᠨ",
    "gui.blocklyText.motucamera.trafficCardForward": "ᠡᠮᠦᠨᠡᠰᠢ",
    "gui.blocklyText.motucamera.trafficCardLeft": "ᠵᠡᠭᠦᠨᠰᠢ",
    "gui.blocklyText.motucamera.trafficCardRight": "ᠪᠠᠷᠠᠭᠤᠨᠰᠢ",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "ᠴᠢᠭᠯᠡᠯ ᠰᠣᠯᠢᠬᠤ",
    "gui.blocklyText.motucamera.trafficCardStop": "ᠵᠣᠭᠰᠤᠬᠤ",
    "gui.blocklyText.motucamera.lightSensorUse": "[MU] 光线传感器 启用 [LIGHTFUNC]",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "[MU] 光线传感器 设置 灵敏度 [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "[MU] 光线传感器 读取 接近检测",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "[MU] 光线传感器 读取 环境光检测",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "[MU] 光线传感器 读取一次手势存入结果",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "[MU] 光线传感器 手势= [GESTURE]",
    "gui.blocklyText.motucamera.upward": "上划",
    "gui.blocklyText.motucamera.downward": "下划",
    "gui.blocklyText.motucamera.leftward": "左划",
    "gui.blocklyText.motucamera.rightward": "右划",
    "gui.blocklyText.motucamera.pull": "前进",
    "gui.blocklyText.motucamera.push": "后退",
    "gui.blocklyText.motucamera.none": "未知",
    "gui.blocklyText.motucamera.default": "默认",
    "gui.blocklyText.motucamera.low": "低",
    "gui.blocklyText.motucamera.middle": "中",
    "gui.blocklyText.motucamera.high": "高",
    "gui.blocklyText.motucamera.proximity": "接近检测",
    "gui.blocklyText.motucamera.ambientLight": "环境光检测",
    "gui.blocklyText.motucamera.gesture": "手势检测",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi 初始化 端口 [SERIALPORT] RX[RX] TX[TX] 串口波特率 [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi 设置 名称 [SSID] 密码 [PASSWORD] 模式 [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi 连接成功？",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi 断开连接",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi 设置目标 IP [IP] 端口 [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi 读取 目标IP",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi 读取 本地IP",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi 读取",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi 写入 [WRITE]",
    "gui.blocklyText.motucamera.client": "客户端",
    "gui.blocklyText.motucamera.hot-spot": "热点",
    "gui.blocklyText.dfr0552.init": "ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ ᠬᠠᠶᠢᠭ [ADDR] ᠯᠠᠪᠯᠠᠯᠲᠠ ᠬᠦᠴᠦᠳᠡᠯ [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "ᠭᠤᠷᠪᠠᠯᠵᠢᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠭᠠᠷᠭᠠᠯᠲᠠ ᠰᠠᠵᠢᠴᠠ [AMPLITUDE]mV ᠳᠠᠪᠲᠠᠮᠵᠢ [FREQ]Hz ᠲᠣᠭᠲᠠᠮᠠᠯ ᠭᠦᠶᠦᠳᠡᠯ ᠦᠨ ᠵᠠᠢᠳᠠᠯ [DCBIAS]mV ᠬᠣᠭᠤᠰᠳᠠᠬᠤ ᠨᠣᠷᠮ᠎ᠠ [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "sin ᠲᠣᠯᠭᠢᠶᠠᠨ ᠭᠠᠷᠭᠠᠯᠲᠠ ᠰᠠᠵᠢᠴᠠ  [AMPLITUDE]mV ᠳᠠᠪᠲᠠᠮᠵᠢ [FREQ]Hz ᠲᠣᠭᠲᠠᠮᠠᠯ ᠭᠦᠶᠦᠳᠡᠯ ᠦᠨ ᠵᠠᠢᠳᠠᠯ [DCBIAS]mV",
    "gui.blocklyText.dfr0552.outputVoltage": "ᠭᠠᠷᠭᠠᠯᠲᠠ ᠬᠦᠴᠦᠳᠡᠯ [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "ᠴᠠᠭᠡᠵᠢᠯᠡᠬᠦ ᠬᠦᠴᠦᠳᠡᠯ [VOLTAGE]mV",
    "gui.blocklyText.dfr0699.init": "音频录放模块传感器初始化地址 [ADDR] 等待直到成功",
    "gui.blocklyText.dfr0699.chooseAudioNum": "选择 [AUDIO] 号音频",
    "gui.blocklyText.dfr0699.getAudioNum": "获取当前录音号",
    "gui.blocklyText.dfr0699.reRecord": "重新录音",
    "gui.blocklyText.dfr0699.startRecording": "开始录音",
    "gui.blocklyText.dfr0699.recordingEnd": "录音结束",
    "gui.blocklyText.dfr0699.recordingIsExist": "录音是否存在?",
    "gui.blocklyText.dfr0699.recordingPlay": "播放录音",
    "gui.blocklyText.dfr0699.waitRecordingPlay": "等待直到录音播放结束",
    "gui.blocklyText.dfr0699.fingerprintSpeechSynthesisMode": "语音合成语音 [VALUE]  文本 [STR] 数字 [MODE]",
    "gui.blocklyText.dfr0699.china": "中文",
    "gui.blocklyText.dfr0699.english": "英文",
    "gui.blocklyText.dfr0699.fingerprintSpeechSynthesis": "语音合成语音 [VALUE]  数字 [NUM]",
    "gui.blocklyText.dfr0699.alternately": "语音替换模式",
    "gui.blocklyText.dfr0699.synthesis": "语音合成模式",
    "gui.blocklyText.pythonsen0334.SHT31Init": "SHT31-F数字温湿度传感器初始化I2C地址[ADDRESS]等待直到成功",
    "gui.blocklyText.pythonsen0334.SHT31GetData": "读取SHT31-F的数据：[DATA]",
    "gui.blocklyText.pythonsen0334.TemperC": "温度（摄氏度℃）",
    "gui.blocklyText.pythonsen0334.TemperF": "温度（华氏度℉）",
    "gui.blocklyText.pythonsen0334.HumidRH": "湿  度 (%RH)",
    "gui.blocklyText.pythonsen0334.Number": "芯片序列号",
    "gui.blocklyText.pythonsen0460.extensionName": "粉尘浓度",
    "gui.blocklyText.pythonsen0460.begin": "初始化设备直到成功 I2C地址为0x19",
    "gui.blocklyText.pythonsen0460.readConcentration": "读取 [ENVIRONMENT] 环境下 [KAIGUAN] 颗粒物浓度（ug/m³）",
    "gui.blocklyText.pythonsen0460.readCount": "读取每0.1L空气中粒径为 [PARTICLEDIAMETER] 的颗粒物个数",
    "gui.blocklyText.pythonsen0460.readDeviceVersion": "获取传感器版本号",
    "gui.blocklyText.pythonsen0460.PM2_5": "PM2.5",
    "gui.blocklyText.pythonsen0460.PM1_0": "PM1.0",
    "gui.blocklyText.pythonsen0460.PM10": "PM10",
    "gui.blocklyText.pythonsen0460.standard": "标准颗粒物",
    "gui.blocklyText.pythonsen0460.atmospheric": "大气",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_0_3": "0.3um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_0_5": "0.5um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_1_0": "1.0um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_2_5": "2.5um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_5_0": "5.0um",
    "gui.blocklyText.pythonsen0460.PARTICLENUM_10": "10um",
    "gui.blocklyText.pythonsen0303.VEML6075Init": "初始化 VEML6075 紫外线传感器等待直到成功",
    "gui.blocklyText.pythonsen0303.VEML6075ReadyUV": "读取 VEML6075 [UV]强度",
    "gui.blocklyText.pythonsen0303.VEML6075Ready": "读取 VEML6075 紫外线指数",
    "gui.blocklyText.pythonsen0303.VEML6075ReadyME": "读取 VEML6075 辐射出射度mw/cm^2",
    "gui.blocklyText.pythonsen0303.UVA": "长波紫外线(UVA)",
    "gui.blocklyText.pythonsen0303.UVB": "中波紫外线(UVB)",
    "gui.blocklyText.pythonsen0377.MEMSgas_Iint": "MEMS气体传感器初始化 地址A0[A0] A1[A1]",
    "gui.blocklyText.pythonsen0377.MEMSgaswarm_up_time": "MEMS气体传感器设置唤醒时间[MIN]分钟",
    "gui.blocklyText.pythonsen0377.MEMSgas_get_power_mode": "MEMS气体传感器获取传感器是否为睡眠模式？",
    "gui.blocklyText.pythonsen0377.MEMSgasset_mode": "MEMS气体传感器设置模式[MODE]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_adc_data": "MEMS气体传感器获取传感器ADC数据[DATA]",
    "gui.blocklyText.pythonsen0377.MEMSgasget_gas_exist": "MEMS气体传感器判断是否存在[TYPE]?",
    "gui.blocklyText.pythonsen0377.MEMSgasget_gas_ppm": "MEMS气体传感器获取[TYPE]数据",
    "gui.blocklyText.pythonsen0377.wakeup_mode": "唤醒模式",
    "gui.blocklyText.pythonsen0377.sleep_mode": "睡眠模式",
    "gui.blocklyText.pythonsen0377.OX_MODE": "氧化模式",
    "gui.blocklyText.pythonsen0377.RED_MODE": "还原模式",
    "gui.blocklyText.pythonsen0377.CO": "一氧化碳",
    "gui.blocklyText.pythonsen0377.CH4": "甲烷",
    "gui.blocklyText.pythonsen0377.C2H5OH": "乙醇",
    "gui.blocklyText.pythonsen0377.C3H8": "丙烷",
    "gui.blocklyText.pythonsen0377.C4H10": "异丁烷",
    "gui.blocklyText.pythonsen0377.H2": "氢气",
    "gui.blocklyText.pythonsen0377.H2S": "硫化氢",
    "gui.blocklyText.pythonsen0377.NH3": "氨气",
    "gui.blocklyText.pythonsen0377.NO": "一氧化氮",
    "gui.blocklyText.pythonsen0377.NO2": "二氧化氮",
    "gui.blocklyText.sen0117.init": "初始模块接口 [SERIALPORT] Rx[RX] Tx[TX] Busy[BUSY]",
    "gui.blocklyText.sen0117.playText": "播放文字 [TEXT] 背景音乐 [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "播放声音提示音 [INDEX] 等待结束 [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "播放和弦提示音 [INDEX] 等待结束 [WAIT]",
    "gui.blocklyText.sen0117.playChordCueTone": "播放和弦铃声 [INDEX] 等待结束 [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "设置 [INDEX] 为 [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "设置语速为 [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "获取语音合成模块 [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "前景音量",
    "gui.blocklyText.sen0117.Background": "背景音量",
    "gui.blocklyText.sen0117.Speed": "语速",
    "gui.blocklyText.sen0117.No": "无 (0)",
    "gui.blocklyText.sen0117.setPlayMode": "设置播放模式为 [MODE]",
    "gui.blocklyText.sen0117.pause": "暂停",
    "gui.blocklyText.sen0117.resume": "继续",
    "gui.blocklyText.sen0117.stop": "停止",
    "gui.blocklyText.dfr0760.init": "初始化语音合成模块 I2C模式 [VERSION] I2C地址0x40",
    "gui.blocklyText.dfr0760.syntheticVoice": "合成语音 [VOICEVALUE]",
    "gui.blocklyText.dfr0760.flashStorage": "使用flash存储 [FALSH]",
    "gui.blocklyText.dfr0760.softwareSerial": "初始化 [VERSION] 语音合成模块接口 [SERIALPORT] Rx(绿)[PINR] Tx(蓝)[PINT]",
    "gui.blocklyText.dfr0760.speechSynthesisSettings": "设置 音量 [VOLUME]语速 [SPEED]语调 [INTONATION]发音人 [SPEEKER]",
    "gui.blocklyText.dfr0760.setEnglishPron": "设置单词发音方式 [Pron]",
    "gui.blocklyText.dfr0760.letter": "字母",
    "gui.blocklyText.dfr0760.word": "单词",
    "gui.blocklyText.dfr0760.manvoice1": "男声1(V1)",
    "gui.blocklyText.dfr0760.manvoice2": "男声2(V1)",
    "gui.blocklyText.dfr0760.womanvoice1": "女声1(V1)",
    "gui.blocklyText.dfr0760.womanvoice2": "女声2(V1)",
    "gui.blocklyText.dfr0760.womanvoice3": "女声3(V1)",
    "gui.blocklyText.dfr0760.donaldDuck": "唐老鸭(V1)",
    "gui.blocklyText.pythondfr0760.extensionName": "语音合成",
    "gui.blocklyText.pythondfr0760.init": "初始化语音合成模块 I2C模式 [VERSION] I2C地址0x40",
    "gui.blocklyText.pythondfr0760.syntheticVoice": "合成语音 [VOICEVALUE]",
    "gui.blocklyText.pythondfr0760.flashStorage": "使用flash存储 [FALSH]",
    "gui.blocklyText.pythondfr0760.speechSynthesisSettings": "设置 音量 [VOLUME]语速 [SPEED]语调 [INTONATION]发音人 [SPEEKER]",
    "gui.blocklyText.pythondfr0760.setEnglishPron": "设置单词发音方式 [Pron]",
    "gui.blocklyText.pythondfr0760.setNumberPron": "设置数字发音方式 [Pron]",
    "gui.blocklyText.pythondfr0760.manvoice1": "男声1(V1)",
    "gui.blocklyText.pythondfr0760.manvoice2": "男声2(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice1": "女声1(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice2": "女声2(V1)",
    "gui.blocklyText.pythondfr0760.womanvoice3": "女声3(V1)",
    "gui.blocklyText.pythondfr0760.donaldDuck": "唐老鸭(V1)",
    "gui.blocklyText.pythondfr0760.auto": "自动",
    "gui.blocklyText.pythondfr0760.number": "号码",
    "gui.blocklyText.pythondfr0760.value": "数值",
    "gui.blocklyText.dfr0715.loop": "循环模式",
    "gui.blocklyText.dfr0715.password": "指令模式",
    "gui.blocklyText.dfr0715.button": "按钮模式",
    "gui.blocklyText.dfr0715.mic": "默认",
    "gui.blocklyText.dfr0715.word": "外部音频",
    "gui.blocklyText.dfr0715.ASRInit": "语音识别模块 初始化 识别模式为 [MODE]麦克风模式为 [PHONEMODE] I2C地址为0x4F",
    "gui.blocklyText.dfr0715.addKeywords": "添加关键词 [KEYWORD]编号为 [NUMBER]",
    "gui.blocklyText.dfr0715.beginToIdentify": "设置完成 开始识别",
    "gui.blocklyText.dfr0715.recognitionOfASpeech": "识别一次语音，获取编号",
    "gui.blocklyText.dfr0646.tmi1650Init": "初始化TMI1650显示器 I2C地址 [ADDRESS]",
    "gui.blocklyText.dfr0646.eightTubeMode": "八位数码管_TM1650 [TYPE]",
    "gui.blocklyText.dfr0646.eightTubeShowInput": "八位数码管_TM1650显示字符串 [TEXT]",
    "gui.blocklyText.dfr0646.eightTubeControlPoint": "八位数码管_TM1650第[ORDER] 个小数点 [STATUS]",
    "gui.blocklyText.pythondfr0715.extensionName": "语音识别",
    "gui.blocklyText.pythondfr0715.ASRInit": "语音识别模块 初始化 识别模式为 [MODE]麦克风模式为 [PHONEMODE] I2C地址为0x4F",
    "gui.blocklyText.pythondfr0715.addKeywords": "添加关键词 [KEYWORD]编号为 [NUMBER]",
    "gui.blocklyText.pythondfr0715.beginToIdentify": "设置完成 开始识别",
    "gui.blocklyText.pythondfr0715.recognitionOfASpeech": "识别一次语音，获取编号",
    "gui.blocklyText.sen0117.isIdle": "播放完成?",
    "gui.blocklyText.dfr0576.selectPort": "ᠰᠤᠩᠭᠤᠨ ᠨᠡᠪᠲᠡᠷᠡᠬᠦ ᠵᠠᠯᠭᠠᠭᠤᠷ [PORT]",
    "gui.blocklyText.dfr0576.begin": "ᠬᠠᠶᠢᠭ ᠢ [ADDR] ᠭᠡᠵᠦ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ",
    "gui.blocklyText.sen0248.bme680Init": "BME680初始化",
    "gui.blocklyText.sen0248.readBME680": "BME680 ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ ᠢ ᠣᠯᠬᠤ [TYPE]",
    "gui.blocklyText.pythonsen0248.bme680Init": "BME680初始化 I2C地址0x77",
    "gui.blocklyText.pythonsen0248.updateBme680": "获取一次BME680传感器数据",
    "gui.blocklyText.pythonsen0248.readBME680": "从数据中解析 [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "ᠳᠤᠯᠠᠭᠠᠴᠠ (°C)",
    "gui.blocklyText.sen0248.Pressure": "ᠠᠭᠠᠷ ᠮᠠᠨᠳᠠᠯ ᠤᠨ ᠳᠠᠷᠤᠯᠲᠠ (Pa)",
    "gui.blocklyText.sen0248.Humidity": "ᠴᠢᠭᠢᠭᠳᠡᠴᠡ (%rh)",
    "gui.blocklyText.sen0248.Altitude": "ᠳᠠᠯᠠᠢ ᠶᠢᠨ ᠲᠦᠪᠰᠢᠨ (m)",
    "gui.blocklyText.sen0248.GasResistance": "气体电阻值 (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "I2C ᠬᠡᠲᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ ᠢ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ ᠬᠠᠶᠢᠭ ᠨᠢ [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "I2C ᠬᠡᠲᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ ᠦᠨ ᠬᠠᠶᠢᠭ ᠢ [ADDR] ᠪᠣᠯᠭᠠᠬᠤ",
    "gui.blocklyText.sen0304.readDistance": "I2C ᠬᠡᠲᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ ᠦᠨ ᠵᠠᠢ ᠶᠢ(cm) ᠬᠡᠮᠵᠢᠬᠦ",
    "gui.blocklyText.sen0304.readTemperature": "I2C ᠬᠡᠲᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ ᠦᠨ ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢ(°C) ᠬᠡᠮᠵᠢᠬᠦ",
    "gui.blocklyText.pythonsen0304.extensionName": "超声波（I2C）",
    "gui.blocklyText.pythonsen0304.initialIICAddr": "初始化I2C超声波传感器 [NAME] 地址为 [ADDR]",
    "gui.blocklyText.pythonsen0304.changeI2CAddr": "更改I2C超声波传感器 [NAME] 地址为 [ADDR] (断电重启生效)",
    "gui.blocklyText.pythonsen0304.readDistance": "读取I2C超声波传感器 [NAME] 距离(cm)",
    "gui.blocklyText.pythonsen0304.readTemperature": "读取I2C超声波传感器 [NAME] 温度(°C)",
    "gui.blocklyText.sen0307.readDistance": "[PIN] ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ ᠬᠡᠲᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠵᠠᠢ(cm)",
    "gui.blocklyText.pythonsen0307.extensionName": "超声波(模拟量)",
    "gui.blocklyText.pythonsen0307.init": "模拟量超声波[NAME] 初始化 引脚[PIN] ",
    "gui.blocklyText.pythonsen0307.readDistance": "[NAME] 读取模拟量超声波(cm)",
    "gui.blocklyText.sen0250.setMode": "ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ ᠂ ᠬᠠᠶᠢᠭ [ADDR] ᠂ ᠬᠡᠪ [MODE]",
    "gui.blocklyText.sen0250.Step": "ᠠᠯᠬᠤᠮ",
    "gui.blocklyText.sen0250.Measure": "ᠬᠡᠮᠵᠢᠯᠲᠡ",
    "gui.blocklyText.sen0250.readAccelerated": " [AXIS]  ᠤᠨ ᠬᠤᠷᠳᠤᠳᠭᠠᠯ ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ(g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 ᠠᠯᠬᠤᠮ ᠦᠨ ᠲᠣᠭ᠎ᠠ ᠶᠢ ᠣᠯᠬᠤ",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "ᠲᠣᠭᠤᠷᠢᠭᠤᠯ ᠬᠡᠮᠵᠢᠭᠦᠷᠯᠢᠭ",
    "gui.blocklyText.sen0250.Acc": "ᠬᠤᠷᠳᠤᠳᠭᠠᠯ (g)",
    "gui.blocklyText.sen0224.init": "初始化LIS2DH传感器",
    "gui.blocklyText.sen0224.readAccelerated": " [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ ᠦᠨ ᠬᠤᠷᠳᠤᠳᠭᠠᠯ ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ (mg)",
    "gui.blocklyText.dfr0023.readLM35": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠳᠤᠯᠠᠭᠠᠴᠠ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ",
    "gui.blocklyText.dfr0027.whenVibration": "ᠳᠣᠷᠭᠢᠯᠲᠠ ᠶᠢ [PIN] ᠪᠠᠢᠴᠠᠭᠠᠨ ᠬᠡᠮᠵᠢᠬᠦ ᠣᠶᠢᠶᠠ ᠳᠤ ",
    "gui.blocklyText.dfr0028.readTiltSensor": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN]ᠬᠠᠵᠠᠢᠭᠰᠠᠨ ᠤᠤ ? ",
    "gui.blocklyText.pythondfr0028.extensionName": "倾斜",
    "gui.blocklyText.pythondfr0028.readTiltSensor": "引脚 [PIN] 检测到倾斜？",
    "gui.blocklyText.dfr0029.readPress": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢ ᠳᠠᠷᠤᠵᠤ ᠠᠪᠬᠤ ᠤᠤ ? ",
    "gui.blocklyText.pythondfr0029.extensionName": "按钮",
    "gui.blocklyText.pythondfr0029.readPress": "引脚 [PIN] 按钮被按下？",
    "gui.blocklyText.dfr0030.readTouch": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠲᠡᠮᠲᠡᠷᠢᠬᠦ ᠶᠢ ᠪᠠᠶᠢᠴᠠᠭᠠᠵᠤ ᠣᠯᠤᠭᠰᠠᠨ ᠤᠤ ? ",
    "gui.blocklyText.pythondfr0030.extensionName": "触摸",
    "gui.blocklyText.pythondfr0030.readTouch": "引脚 [PIN] 检测到触摸？",
    "gui.blocklyText.dfr0033.readMagnetic": "ᠡᠰᠢ ᠪᠠᠷᠢᠮᠲᠠ [PIN] ᠰᠣᠷᠢᠨᠵᠢᠨ ᠲᠡᠮᠦᠷ ᠢ ᠪᠠᠢᠴᠠᠭᠠᠨ ᠬᠡᠮᠵᠢᠵᠦ ᠣᠯᠤᠭᠰᠠᠨ ᠤᠤ ? ",
    "gui.blocklyText.pythondfr0033.extensionName": "磁力",
    "gui.blocklyText.pythondfr0033.readMagnetic": "引脚 [PIN] 检测到磁铁？",
    "gui.blocklyText.dfr0034.readSound": "ᠤᠩᠰᠢᠯᠭ᠎ᠠ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠳᠠᠭᠤᠨ ᠬᠦᠴᠦᠯᠡᠴᠡ ᠶᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.pythondfr0034.extensionName": "声音强度",
    "gui.blocklyText.pythondfr0034.readSound": "读取引脚 [PIN] 声音强度",
    "gui.blocklyText.dfr0094.readIRReceived": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠤᠯᠠᠭᠠᠨ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠬᠤᠷᠢᠶᠠᠬᠤ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.dfr0094.onReceiveEvent": "When the infrared signal is received Pin [PIN] [ADDRESS] [COMMAND]",
    "gui.blocklyText.dfr0094.onReceiveEventAddress": "address",
    "gui.blocklyText.dfr0094.onReceiveEventCommand": "command",
    "gui.blocklyText.midi.midiInit": "MIDI初始化 [SERIALPORT] 引脚RX [RX] 引脚TX [TX]",
    "gui.blocklyText.midi.midiOutput": "MIDI 输出 通道 [CHANNEL] 音高 [PITCH] 速度 [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠨᠡᠭᠦᠷᠡᠰᠦᠲᠥ᠋ᠷᠥᠭᠴᠢ ᠶᠢᠨ ᠳᠠᠩ ᠢᠰᠦᠯ ᠣᠨ ᠬᠡᠢ ᠪᠡᠶᠡᠲᠦ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.pythonsen0132.extensionName": "CO气体",
    "gui.blocklyText.pythonsen0132.readCarbonMonoxide": "读取引脚 [PIN] 一氧化碳气体传感器",
    "gui.blocklyText.dfr0051.readVoltage": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠬᠦᠴᠦᠳᠡᠯ ᠦᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ( V ) ᠤᠩᠰᠢᠭᠤᠷ ",
    "gui.blocklyText.dfr0052.readPiezoSensor": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ  [PIN] ᠮᠧᠬᠠᠨᠢᠭ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠱᠠᠵᠠᠩ ᠳᠣᠷᠭᠢᠯᠲᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.pythondfr0052.extensionName": "震动",
    "gui.blocklyText.pythondfr0052.readPiezoSensor": "读取引脚 [PIN] 压电陶瓷震动传感器",
    "gui.blocklyText.dfr0058.readRotation": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ  [PIN] ᠤᠯᠠᠨ ᠴᠠᠭᠠᠷᠢᠭ ᠣᠨ ᠡᠷᠭᠢᠯᠲᠡ ᠶᠢᠨ ᠥᠨᠴᠥᠭ ᠦᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.pythondfr0058.extensionName": "旋转角度",
    "gui.blocklyText.pythondfr0058.readRotation": "读取引脚 [PIN] 多圈旋转角度传感器",
    "gui.blocklyText.dfr0061.readJoyStickAxis": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠰᠠᠵᠢᠯᠠᠭᠤᠷ ᠤᠨ [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ ",
    "gui.blocklyText.dfr0061.readJoyStickButton": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠰᠠᠵᠢᠯᠠᠭᠤᠷ ᠤᠨ ᠳᠠᠷᠤᠭᠤᠯ ( Z )  ᠨᠢ ᠳᠠᠷᠤᠭᠳᠠᠭᠰᠠᠨ ᠤᠤ ? ",
    "gui.blocklyText.pythondfr0061.extensionName": "摇杆",
    "gui.blocklyText.pythondfr0061.readJoyStickAxis": "读取引脚 [PIN] 摇杆 [AXIS] 轴",
    "gui.blocklyText.pythondfr0061.readJoyStickButton": "引脚 [PIN] 摇杆按钮(Z)被按下？",
    "gui.blocklyText.dfr0072.dfr0072Init": "shiftOutᠲᠣᠭᠠᠨ ᠤ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ [DATAPIN] ᠴᠠᠭ ᠤᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ[RTCPIN] CLS ᠵᠠᠯᠭᠠᠳᠠᠰᠤ[CLSPIN] ᠦᠰᠦᠭ ᠦᠨ ᠦᠶ᠎ᠡ ᠶᠢᠨ ᠳᠠᠷᠠᠭᠠᠯᠠᠯ [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "ᠲᠣᠭᠠᠨ ᠤ ᠬᠣᠭᠤᠯᠠᠢ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ ᠬᠡᠰᠡᠭᠲᠦ ᠺᠣᠳ᠋0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "ᠲᠣᠭᠠᠨ ᠤ ᠬᠣᠭᠤᠯᠠᠢ ᠲᠣᠭ᠎ᠠ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ  [NUM]",
    "gui.blocklyText.dfr0072.numOff": "ᠬᠠᠭᠠᠬᠤ",
    "gui.blocklyText.dfr0072.dfr0072Dot": "ᠲᠣᠭᠠᠨ ᠤ ᠬᠣᠭᠤᠯᠠᠢ ᠳᠤᠰᠤᠯ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ  [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "ᠵᠢᠩᠬᠢᠨᠢ",
    "gui.blocklyText.dfr0072.dotFalse": "ᠵᠢᠳᠠ",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "ᠯᠢᠲ᠋ᠢ ᠳ᠋ᠢᠶᠠᠨ ᡂᠢ ᠶᠢ ᠤᠩᠰᠢᠬᠤ [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠬᠦᠴᠦᠳᠡᠯ(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "ᠦᠯᠡᠳᠡᠭᠰᠡᠨ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠬᠡᠮᠵᠢᠶ᠎ᠡ(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "AD ᠬᠡᠰᠡᠭᠲᠦ I2C ᠬᠠᠶᠢᠭ ᠢ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "AD ᠬᠡᠰᠡᠭᠲᠦ [PIN] ᠢ ᠤᠩᠰᠢᠬᠤ᠂ ᠬᠦᠴᠦᠳᠡᠯ (mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "AD ᠬᠡᠰᠡᠭᠲᠦ ᠵᠠᠮ [AISLE] ᠢ ᠣᠯᠬᠤ ᠬᠦᠴᠦᠳᠡᠯ ᠦᠨ ᠵᠦᠷᠢᠶ᠎ᠡ",
    "gui.blocklyText.pythondfr0553.dfr0553InitIICAddr": "AD模块[NAME] 初始化I2C地址 [ADDR]",
    "gui.blocklyText.pythondfr0553.dfr0553ReadVoltage": "AD模块[NAME] 读取 [PIN] 电压 (mV)",
    "gui.blocklyText.pythondfr0553.dfr0553GetAisleVoltageDifference": "AD模块[NAME] 获取通道 [AISLE] 电压差",
    "gui.blocklyText.dfr0553.ZeroAndOne": "0 ᠪᠠ 1",
    "gui.blocklyText.dfr0553.ZeroAndThree": "0 ᠪᠠ 3",
    "gui.blocklyText.dfr0553.OneAndThree": "1 ᠪᠠ 3",
    "gui.blocklyText.dfr0553.TwoAndThree": "2 ᠪᠠ 3",
    "gui.blocklyText.dfr0588.initPin": "初始化 温度引脚 [TEM] 湿度引脚 [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "获取温度 单位 [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "获取湿度(%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "摄氏度",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "华氏度",
    "gui.blocklyText.dfr0076.readFlameSensor": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠳᠥᠯᠥ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.pythondfr0076.extensionName": "火焰",
    "gui.blocklyText.pythondfr0076.readFlameSensor": "读取引脚 [PIN] 火焰传感器",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom 获取 [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom 初始化I2C地址 [IICADDR]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom 写入文本 [VALUE] 名称 [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom 写入数字 [VALUE] 名称 [KEY] ",
    "gui.blocklyText.dfr0117.text": "文本",
    "gui.blocklyText.dfr0117.number": "数字",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom 读取文本 名称 [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom 读取数字 名称 [KEY]",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom 删除 名称 [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom 串口打印全部名称 波特率 [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom 名称 [KEY] 存在?",
    "gui.blocklyText.dfr0117.eepromFormat": "i2c eeprom 格式化",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom 名称 [KEY] 的值是 [TYPE] 类型?",
    "gui.blocklyText.dfr0117.nameCount": "名称个数",
    "gui.blocklyText.dfr0117.memorySize": "储存空间(byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "剩余储存空间(byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠭᠤᠷᠪᠠᠨ ᠲᠡᠩᠭᠡᠯᠢᠭᠲᠦ ᠬᠤᠷᠳᠤᠳᠬᠠᠯ ᠤᠨ ᠬᠡᠮᠵᠢᠭᠦᠷ [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ ",
    "gui.blocklyText.sen0018.readInfraredMotion": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠬᠥᠮᠦᠨ ᠦ ᠪᠡᠶᠡ ᠶᠢᠨ ᠤᠯᠠᠭᠠᠨ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠢᠯᠴᠢ ᠪᠡᠷ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠠᠷᠭᠠᠬᠤ ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ ᠦ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.pythonsen0018.extensionName": "运动",
    "gui.blocklyText.pythonsen0018.readInfraredMotion": "读取引脚 [PIN] 人体红外热释电运动传感器?",
    "gui.blocklyText.sen0014.readDistance": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] Sharp ᠬᠡᠲᠦ ᠤᠯᠠᠭᠠᠨ ᠰᠠᠴᠤᠷᠠᠭ ᠣᠨ ᠵᠠᠶ ᠬᠡᠮᠵᠢᠭᠦᠷ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ (mm) ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.sen0114.readMoisture": "[PIN] ᠬᠥᠷᠥᠰᠥᠨ ᠦ ᠴᠢᠭᠢᠭᠲᠦᠴᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.pythonsen0114.extensionName": "土壤湿度",
    "gui.blocklyText.pythonsen0114.readMoisture": "读取引脚 [PIN] 土壤湿度传感器",
    "gui.blocklyText.sen0121.readSteam": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ  [PIN] ᠢ ᠤᠩᠰᠢᠬᠤ᠂ ᠤᠰᠤᠨ ᠤ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.pythonsen0121.extensionName": "水分",
    "gui.blocklyText.pythonsen0121.readSteam": "读取引脚 [PIN] 水分传感器",
    "gui.blocklyText.sen0204.readLiquidLevel": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN]  ᠤᠨ ᠰᠢᠩᠭᠡᠨ ᠦ ᠪᠠᠢᠷᠢ ᠬᠦᠷᠴᠦ ᠢᠷᠡᠬᠦ ᠦᠸ ? ",
    "gui.blocklyText.pythonsen0204.extensionName": "液位",
    "gui.blocklyText.pythonsen0204.readLiquidLevel": "引脚 [PIN] 液位到达？",
    "gui.blocklyText.sen0203.heartRate": "ᠵᠢᠷᠦᠬᠡᠨ ᠳᠠᠪᠲᠠᠮᠵᠢ ",
    "gui.blocklyText.sen0203.bloodOxygen": "ᠴᠢᠰᠤᠨ ᠤ ᠬᠦᠴᠢᠯᠲᠥ᠋ᠷᠥᠭᠴᠢ ᠶᠢᠨ ᠬᠠᠨᠤᠴᠠ ",
    "gui.blocklyText.sen0202.up": "ᠳᠡᠭᠡᠭᠰᠢ ᠬᠠᠨᠳᠤᠬᠤ ",
    "gui.blocklyText.sen0202.down": "ᠳᠣᠷᠣᠭᠰᠢ ",
    "gui.blocklyText.sen0202.left": "ᠵᠡᠭᠦᠨᠰᠢ ᠪᠡᠨ ",
    "gui.blocklyText.sen0202.right": "ᠪᠠᠷᠠᠭᠤᠨᠰᠢ ",
    "gui.blocklyText.sen0202.cw": "ᠴᠠᠭ ᠣᠨ ᠵᠡᠭᠦᠦ ᠳᠠᠭᠠᠤ ",
    "gui.blocklyText.sen0202.ccw": "ᠰᠥᠷᠭᠡᠦ ᠴᠢᠭᠯᠡᠯ ",
    "gui.blocklyText.dhtTHSensor.temperature": "ᠳᠤᠯᠠᠭᠠᠴᠠ ",
    "gui.blocklyText.dhtTHSensor.moisture": "ᠴᠢᠭᠢᠭᠲᠦᠴᠡ ",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠳ᠋ᠧᠩ ᠦᠨ ᠨᠣᠮᠧᠷ [NUM1] ᠡᠴᠡ [NUM2] ᠢᠯᠡᠷᠡᠬᠦ ᠦᠩᠭᠡ [COLOR] ",
    "gui.blocklyText.ws2812.rgbColor": "ᠤᠯᠠᠭᠠᠨ [RED] ᠨᠣᠭᠣᠭᠠᠨ [GREEN] ᠬᠥᠬᠡ [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠳ᠋ᠧᠩ ᠦᠨ ᠨᠣᠮᠧᠷ [NUM1] ᠡᠴᠡ [NUM2] ᠢᠯᠡᠷᠡᠬᠦ ᠦᠩᠭᠡ ᠨᠢ ᠠᠯᠭᠤᠷ ᠬᠤᠪᠢᠷᠠᠬᠤ [COLOR1] ᠡᠴᠡ [COLOR2] ᠬᠦᠷᠲᠡᠯᠡᠬᠢ ",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠰᠢᠯᠵᠢᠬᠦ [NUM] ᠨᠢᠭᠡᠴᠢ ",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠡᠷᠭᠢᠯᠲᠡ ᠶᠢᠨ ᠰᠢᠯᠵᠢᠬᠦ [NUM] ᠨᠢᠭᠡᠴᠢ ᠡᠬᠢᠨ ᠦ ",
    "gui.blocklyText.ws2812.numberOfLightsBright": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠡᠰᠢ ᠶᠢᠨ ᠪᠥᠬᠥ ᠲᠣᠭ᠎ᠠ [PIN] [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "RGBᠳ᠋ᠧᠩ ᠦᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠳ᠋ᠧᠩ ᠦᠨ ᠶᠡᠷᠦᠩᠬᠡᠶ ᠭᠡᠷᠡᠯᠲᠦᠴᠡ [BRIGHT] ",
    "gui.blocklyText.ws2812.clear": "RGBᠳ᠋ᠧᠩ ᠦᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠪᠦᠬᠦᠨ ᠢ ᠤᠨᠲᠠᠷᠠᠭᠠᠬᠤ ",
    "gui.blocklyText.ws2812.showHistogram": "RGBᠳ᠋ᠧᠩ ᠦᠨ ᠡᠰᠢ ᠶᠢᠨ [PIN] ᠳ᠋ᠧᠩ ᠦᠨ ᠨᠣᠮᠧᠷ [NUM1] ᠡᠴᠡ [NUM2] ᠢᠯᠡᠷᠡᠬᠦ ᠪᠠᠭᠠᠨᠠ ᠶᠢᠨ ᠵᠢᠷᠤᠭ ᠤᠨ ᠣᠳᠣᠬᠢ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ [VALUE] ᠬᠠᠮᠤᠭ ᠤᠨ ᠶᠡᠬᠡ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ [MAX] ",
    "gui.blocklyText.ws2812.setBrightness": "RGBᠳ᠋ᠧᠩ ᠲᠠᠭᠠᠷᠠᠭᠤᠯᠤᠭᠰᠠᠨ [PIN] ᠳ᠋ᠧᠩ ᠦᠨ ᠪᠦᠰᠡ ᠶᠢᠨ ᠭᠡᠷᠡᠯᠲᠦᠴᠡ ᠨᠢ [BRIGHTNESS] ",
    "gui.blocklyText.neopixel.init": "Lamp initialization name [NAME] pin [PIN] number [COUNT]",
    "gui.blocklyText.neopixel.setColor": "Light strip [NAME] [INDEX] Color [COLOR]",
    "gui.blocklyText.neopixel.setColorRGB": "Light strip [NAME] [INDEX] Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.fillColor": "The light strip [NAME] is fully lit and the color is [COLOR]",
    "gui.blocklyText.neopixel.fillColorRGB": "Light strip [NAME] All on Red [R] Green [G] Blue [B]",
    "gui.blocklyText.neopixel.flush": "Light strip [NAME] settings take effect",
    "gui.blocklyText.neopixel.off": "Light strip [NAME] is off",
    "gui.blocklyText.neopixel.setBrightness": "Light strip [NAME] sets brightness to [BRIGHTNESS]",
    "gui.blocklyText.neopixel.rainbowLightEffect": "Rainbow light effect [NAME] Quantity [COUNT] Brightness [BRIGHTNESS] Offset [OFFSET]",
    "gui.blocklyText.neopixel.neopixel_P7": "P7(mPython board)",
    "gui.blocklyText.neopixel.neopixel_P9": "P9(mPython box)",
    "gui.blocklyText.dsTSensor.ds18b20": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ (DS18B20)  ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280初始化",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "BMP 280 ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ [TYPE]  ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.pythonsen0372.bmp280Init": "BMP280 [NAME] 初始化 I2C地址[ADDRESS]",
    "gui.blocklyText.pythonsen0372.readBmpSensor": "读取BMP280传感器[NAME] [TYPE] 的值",
    "gui.blocklyText.bmpTSensor.atmosPressure": "ᠠᠭᠠᠷ ᠤᠨ ᠳᠠᠷᠤᠯᠲᠠ ",
    "gui.blocklyText.bmpTSensor.altitude": "ᠲᠡᠯᠡᠢ ᠶᠢᠨ ᠲᠦᠪᠰᠢᠨ ᠡᠴᠡ ᠳᠡᠭᠡᠭᠰᠢ ",
    "gui.blocklyText.bmpTSensor.temperature": "ᠳᠤᠯᠠᠭᠠᠴᠠ ",
    "gui.blocklyText.huskylens.init": "HuskyLens 初始化引脚为 [SETTING] 直到成功",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens 切换到 [ALGORITHM] 算法 直到成功",
    "gui.blocklyText.huskylens.requestFirst": "HuskyLens 请求一次数据 存入结果",
    "gui.blocklyText.huskylens.resultGetIsStudy": "HuskyLens 从结果中获取ID [IDNUM] 是否已学习？",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 是否在画面中？",
    "gui.blocklyText.huskylens.resultGetIDParameter": "HuskyLens 从结果中获取ID [IDNUM] [TYPE] 的参数 [PARAMETER]",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens 从结果中获取 已学ID总数",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens 从结果中获取 [BOXARROW] 总数",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 总数",
    "gui.blocklyText.huskylens.resultGetIDofWhichParameter": "HuskyLens 从结果中获取ID [IDNUM] 第 [BOXNUM] 个[TYPE]的参数 [PARAMETER]",
    "gui.blocklyText.huskylens.setIDname": "HuskyLens 设置当前算法ID [ID] 名字为 [NAME]",
    "gui.blocklyText.huskylens.TakeScreenshot": "HuskyLens 触发 [TYPE] 保存到SD卡",
    "gui.blocklyText.huskylens.writeOSD": "HuskyLens 屏幕叠加显示文字 [NAME] 在x [X] y [Y]",
    "gui.blocklyText.huskylens.clearOSD": "HuskyLens 清除屏幕显示的文字",
    "gui.blocklyText.huskylens.learnOnce": "HuskyLens 自动学习一次 ID [ID]",
    "gui.blocklyText.huskylens.clearLearn": "HuskyLens 遗忘当前算法的所有学习数据",
    "gui.blocklyText.huskylens.loadAndSaveModel": "HuskyLens [TYPE] 当前算法数据为SD卡 [INDEX] 号模型",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.A": "拍照",
    "gui.blocklyText.huskylens.TAKESCREENSHOT.B": "截屏",
    "gui.blocklyText.huskylens.LOADSAVE.A": "保存",
    "gui.blocklyText.huskylens.LOADSAVE.B": "加载",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens 从结果中获取 [BOXARROW] 是否在画面中？",
    "gui.blocklyText.huskylens.resultGetNearCenter": "HuskyLens 从结果中获取靠近中心[TYPE]的 [PARAMETER2] 参数",
    "gui.blocklyText.huskylens.resultGetParameter": "HuskyLens 从结果中获取第 [IDNUM] 个 [TYPE] 的 [PARAMETER2] 参数",
    "gui.blocklyText.huskylens.box": "方框",
    "gui.blocklyText.huskylens.arrow": "箭头",
    "gui.blocklyText.huskylens.faceRecognition": "人脸识别",
    "gui.blocklyText.huskylens.objectTracking": "物体追踪",
    "gui.blocklyText.huskylens.objectRecognition": "物体识别",
    "gui.blocklyText.huskylens.lineTracking": "巡线",
    "gui.blocklyText.huskylens.colorRecognition": "颜色识别",
    "gui.blocklyText.huskylens.tagRecognition": "标签识别",
    "gui.blocklyText.huskylens.objectClassification": "物体分类",
    "gui.blocklyText.huskylens.QRRecognition": "二维码识别 (教育版独有)",
    "gui.blocklyText.huskylens.barcodeRecognition": "条形码识别 (教育版独有)",
    "gui.blocklyText.huskylens.ID": "ID",
    "gui.blocklyText.huskylens.x": "X中心",
    "gui.blocklyText.huskylens.y": "Y中心",
    "gui.blocklyText.huskylens.w": "宽度",
    "gui.blocklyText.huskylens.h": "高度",
    "gui.blocklyText.huskylens.x1": "X起点",
    "gui.blocklyText.huskylens.y1": "Y起点",
    "gui.blocklyText.huskylens.x2": "X终点",
    "gui.blocklyText.huskylens.y2": "Y终点",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "通讯方式",
    "gui.blocklyText.huskylens.address": "地址",
    "gui.blocklyText.huskylens.sdaGreen": "SDA(绿)",
    "gui.blocklyText.huskylens.sclBlue": "SCL(蓝)",
    "gui.blocklyText.huskylens.rxGreen": "Rx(绿)",
    "gui.blocklyText.huskylens.txBlue": "Tx(蓝)",
    "gui.blocklyText.pythonhuskylens.extensionName": "二哈识图",
    "gui.blocklyText.pythonhuskylens.init": "HuskyLens 初始化I2C地址为0x32",
    "gui.blocklyText.pythonhuskylens.changeAlgorithm": "HuskyLens 切换到 [ALGORITHM] 算法 直到成功",
    "gui.blocklyText.pythonhuskylens.requestFirst": "HuskyLens 请求一次数据 存入结果",
    "gui.blocklyText.pythonhuskylens.resultGetIsStudy": "HuskyLens 从结果中获取ID [IDNUM] 是否已学习？",
    "gui.blocklyText.pythonhuskylens.resultGetIDIsInPicture": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 是否在画面中？",
    "gui.blocklyText.pythonhuskylens.resultGetIDParameter": "HuskyLens 从结果中获取ID [IDNUM] [TYPE] 的参数 [PARAMETER]",
    "gui.blocklyText.pythonhuskylens.resultGetStudyedID": "HuskyLens 从结果中获取 已学ID总数",
    "gui.blocklyText.pythonhuskylens.resultGetIsBoxArrowTotal": "HuskyLens 从结果中获取 [BOXARROW] 总数",
    "gui.blocklyText.pythonhuskylens.resultGetIDsBoxArrowTotal": "HuskyLens 从结果中获取ID [IDNUM] [BOXARROW] 总数",
    "gui.blocklyText.pythonhuskylens.resultGetIDofWhichParameter": "HuskyLens 从结果中获取ID [IDNUM] 第 [BOXNUM] 个[TYPE]的参数 [PARAMETER]",
    "gui.blocklyText.pythonhuskylens.setIDname": "HuskyLens 设置当前算法ID [ID] 名字为 [IDNAME]",
    "gui.blocklyText.pythonhuskylens.TakeScreenshot": "HuskyLens 触发 [TYPE] 保存到SD卡",
    "gui.blocklyText.pythonhuskylens.writeOSD": "HuskyLens 屏幕叠加显示文字 [TEXT] 在x [X] y [Y]",
    "gui.blocklyText.pythonhuskylens.clearOSD": "HuskyLens 清除屏幕显示的文字",
    "gui.blocklyText.pythonhuskylens.learnOnce": "HuskyLens 自动学习一次 ID [ID]",
    "gui.blocklyText.pythonhuskylens.clearLearn": "HuskyLens 遗忘当前算法的所有学习数据",
    "gui.blocklyText.pythonhuskylens.loadAndSaveModel": "HuskyLens [TYPE] 当前算法数据为SD卡 [INDEX] 号模型",
    "gui.blocklyText.pythonhuskylens.resultGetIsInPicture": "HuskyLens 从结果中获取 [BOXARROW] 是否在画面中？",
    "gui.blocklyText.pythonhuskylens.resultGetNearCenter": "HuskyLens 从结果中获取靠近中心[TYPE]的 [PARAMETER2] 参数",
    "gui.blocklyText.pythonhuskylens.resultGetParameter": "HuskyLens 从结果中获取第 [IDNUM] 个 [TYPE] 的 [PARAMETER2] 参数",
    "gui.blocklyText.ps2.initPs2Handle": "初始化PS2手柄 DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] 振动反馈 [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2振动 [RUMBLE] 强度 [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "PS2刷新一次状态",
    "gui.blocklyText.ps2.ps2ButtonState": "PS2按键 [BUTTON] 状态为 [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2摇杆 [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "开",
    "gui.blocklyText.ps2.OFF": "关",
    "gui.blocklyText.ps2.Triangle": "三角形键",
    "gui.blocklyText.ps2.Circle": "圆形键",
    "gui.blocklyText.ps2.Cross": "x键",
    "gui.blocklyText.ps2.Square": "方形键",
    "gui.blocklyText.ps2.Left1": "左侧1",
    "gui.blocklyText.ps2.Left2": "左侧2",
    "gui.blocklyText.ps2.Left3": "左侧3",
    "gui.blocklyText.ps2.Right1": "右侧1",
    "gui.blocklyText.ps2.Right2": "右侧2",
    "gui.blocklyText.ps2.Right3": "右侧3",
    "gui.blocklyText.ps2.Up": "方向键上",
    "gui.blocklyText.ps2.Right": "方向键右",
    "gui.blocklyText.ps2.Down": "方向键下",
    "gui.blocklyText.ps2.Left": "方向键左",
    "gui.blocklyText.ps2.Select": "选择键",
    "gui.blocklyText.ps2.Start": "开始键",
    "gui.blocklyText.ps2.Hold": "按住",
    "gui.blocklyText.ps2.Pressed": "按下",
    "gui.blocklyText.ps2.Released": "松开",
    "gui.blocklyText.ps2.CHANGE": "改变",
    "gui.blocklyText.ps2.RightX": "右侧X值",
    "gui.blocklyText.ps2.RightY": "右侧Y值",
    "gui.blocklyText.ps2.LeftX": "左侧X值",
    "gui.blocklyText.ps2.LeftY": "左侧Y值",
    "gui.blocklyText.sen0236.bme280Init": "BME280初始化",
    "gui.blocklyText.sen0236.readbme280Sensor": "读取I2C引脚BME280 [TYPE]",
    "gui.blocklyText.pythonsen0236.bme280Init": "BME280 初始化 I2C地址0x77",
    "gui.blocklyText.pythonsen0236.readbme280Sensor": "读取I2C引脚BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "温度(℃)",
    "gui.blocklyText.sen0236.Humidity": "湿度(%rh)",
    "gui.blocklyText.sen0236.Altitude": "海拔(m)",
    "gui.blocklyText.sen0236.Pressure": "气压(Pa)",
    "gui.blocklyText.sen0517.icp10111Init": "ICP10111初始化 I2C地址0x63",
    "gui.blocklyText.sen0517.readicp10111Sensor": "获取ICP10111传感器 [TYPE] 的值",
    "gui.blocklyText.sen0517.Temperature": "温度(℃)",
    "gui.blocklyText.sen0517.Altitude": "海拔(m)",
    "gui.blocklyText.sen0517.Pressure": "气压(Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "ᠡᠬᠢᠵᠢᠬᠦᠯᠦᠭᠰᠡᠨ I2C ᠰᠢᠩᠭᠡᠨ ᠲᠠᠯᠠᠰᠲᠤ ᠦᠵᠡᠭᠦᠯᠬᠦ ᠳᠡᠯᠭᠡᠴᠡ ᠶᠢᠨ ᠬᠠᠶ᠋ᠢᠭ ᠨᠢ [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "I2C ᠵᠢᠨ ᠰᠢᠩᠭᠡᠨ ᠲᠠᠯᠠᠰᠲᠤ ᠦᠵᠡᠭᠦᠯᠬᠦ ᠳᠡᠯᠭᠡᠴᠡ [LINE]  ᠳᠤ ᠢᠯᠡᠷᠡᠭᠰᠡᠨ [TEXT] ",
    "gui.blocklyText.LCD1602.displayInXY": "I2C ᠵᠢᠨ ᠰᠢᠩᠭᠡᠨ ᠲᠠᠯᠠᠰᠲᠤ ᠦᠵᠡᠭᠦᠯᠬᠦ ᠳᠡᠯᠭᠡᠴᠡ ᠨᠢ ᠪᠠᠶᠢᠷᠢᠰᠢᠯ X:[X] Y᠄[Y] ᠢᠯᠡᠷᠡᠭᠦᠯᠦᠭᠰᠡᠨ [TEXT] ",
    "gui.blocklyText.LCD1602.displayClear": "I2C ᠰᠢᠩᠭᠡᠨ ᠲᠠᠯᠠᠰᠲᠤ ᠦᠵᠡᠭᠦᠯᠬᠦ ᠳᠡᠯᠭᠡᠴᠡ ᠶᠢᠨ ᠳᠡᠯᠭᠡᠴᠡ ",
    "gui.blocklyText.pythonLCD1602.initialIICAddr": "初始化I2C液晶显示屏 [NAME] 地址为[ADDR]",
    "gui.blocklyText.pythonLCD1602.displayInLine": "I2C液晶显示屏 [NAME] 在第 [LINE] 行显示 [TEXT]",
    "gui.blocklyText.pythonLCD1602.displayInXY": "I2C液晶显示屏 [NAME] 在坐标 X: [X] Y: [Y] 显示 [TEXT]",
    "gui.blocklyText.pythonLCD1602.displayClear": "I2C液晶显示屏 [NAME] 清屏",
    "gui.blocklyText.IICScan.readICCAddr": "ᠤᠩᠰᠢᠵᠤ ᠣᠯᠤᠭᠰᠠᠨ I2C ᠲᠥᠬᠥᠭᠡᠷᠦᠮᠵᠢ ᠶᠢᠨ ᠬᠠᠶ᠋ᠢᠭ ",
    "gui.blocklyText.mpyfile.initFile": "初始化文本文件 [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsList": "读取文本文件 [FILENAME] 为列表, 以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.writeFile": "向文本文件 [FILENAME] 写入内容 [WRITEDATA] ,以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.initFileMaixduino": "初始化 [ROOT] 文件 [FILENAME]",
    "gui.blocklyText.mpyfile.readFileAsListMaixduino": "读取 [ROOT] 文件 [FILENAME] 为列表, 以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.writeFileMaixduino": "向 [ROOT] 文件 [FILENAME] 写入内容 [WRITEDATA],以 [SEPARATE] 分隔",
    "gui.blocklyText.mpyfile.enter": "回车",
    "gui.blocklyText.mpyfile.comma": "逗号",
    "gui.blocklyText.mpyfile.semicolon": "分号",
    "gui.blocklyText.mpyfile.space": "空格",
    "gui.blocklyText.sdcard.initCS": "SD 片选引脚 [CSPIN] 初始化成功？",
    "gui.blocklyText.sdcard.readFileNLine": "SD 读取文件 [FILE] 第 [LINE] 行",
    "gui.blocklyText.sdcard.readFileAll": "SD 读取文件 [FILE] 全部内容",
    "gui.blocklyText.sdcard.writeFile": "SD 向文件 [FILE] 写入 [MSG] 模式 [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD 删除文件 [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD 获取文件 [FILE] 信息 [INFO]",
    "gui.blocklyText.sdcard.NAME": "名称",
    "gui.blocklyText.sdcard.PATH": "路径",
    "gui.blocklyText.sdcard.TYPE": "类型",
    "gui.blocklyText.sdcard.LINE": "行数",
    "gui.blocklyText.sdcard.SIZE": "大小",
    "gui.blocklyText.sdcard.APPEND": "追加",
    "gui.blocklyText.sdcard.REPLACE": "替换",
    "gui.blocklyText.sdcard.WARP": "换行",
    "gui.blocklyText.sdcard.NOWARP": "不换行",
    "gui.blocklyText.sdcard.lsFile": "SD 串口打印全部文件信息 波特率 [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "ᠵᠥᠭᠡᠯᠡᠨ ᠬᠣᠯᠪᠣᠭᠠᠰᠤ [SERIALPORT] RX:[PINR] TX:[PINT] ",
    "gui.blocklyText.softSerialport.setBaud": "ᠵᠥᠭᠡᠯᠡᠨ ᠵᠠᠯᠭᠠᠭᠤᠷ [SERIALPORT] ᠫᠤᠲ᠋ᠲ᠋ᠸᠷ  ᠤᠨ ᠨᠤᠷᠮᠠ ᠨᠢ [BAUD] ",
    "gui.blocklyText.softSerialport.printStr": "ᠵᠥᠭᠡᠯᠡᠨ ᠵᠠᠯᠭᠠᠭᠤᠷ [SERIALPORT] [WAY] [TEXT] [LINE] ",
    "gui.blocklyText.softSerialport.dataReadable": "ᠵᠥᠭᠡᠯᠡᠨ ᠵᠠᠯᠭᠠᠭᠤᠷ [SERIALPORT]  ᠢ ᠤᠩᠰᠢᠵᠤ ᠪᠣᠯᠬᠤ ᠤᠤ ? ",
    "gui.blocklyText.softSerialport.readSerialportData": "ᠵᠥᠭᠡᠯᠡᠨ ᠵᠠᠯᠭᠠᠭᠤᠷ [SERIALPORT]  ᠤᠨ ᠳ᠋ᠠᠶᠢᠲ᠋ᠠ ᠶᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠠᠭᠤᠯᠵᠠᠭᠤᠷ [SERIALPORT] ᠡᠴᠡ [TYPE] ᠶᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.bluetooth.connectSuccess": "bluetooth ᠠᠮᠵᠢᠯᠲᠠ ᠣᠯᠬᠤ ᠦᠶᠡᠰ ᠠᠵᠢᠯᠯᠠᠨᠠ ᠃ ",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "bluetooth ᠵᠠᠯᠭᠠᠯᠲᠠ ᠲᠠᠰᠤᠷᠠᠬᠤ ᠦᠶᠡᠰ ᠬᠥᠳᠡᠯᠬᠦ ",
    "gui.blocklyText.bluetooth.receivedKey": "bluetooth ᠴᠢᠮᠡᠭᠡ ᠬᠦᠯᠢᠶᠡᠵᠦ ᠠᠪᠬᠤ ᠣᠶᠢᠶᠠ ᠳᠤkey[KEY]VALUE[TYPE] ᠠᠶᠠᠯᠠᠳᠠᠭ ᠃ ",
    "gui.blocklyText.bluetooth.string": "ᠦᠰᠦᠭ ᠲᠡᠮᠲᠡᠭ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ",
    "gui.blocklyText.bluetooth.number": "ᠲᠣᠭ᠎ᠠ ",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "bluetooth  ᠳ᠋ᠠᠢᠲ᠋ᠠ ᠶᠢ ᠬᠦᠯᠢᠶᠡᠵᠦ ᠠᠪᠤᠭᠠᠳ [SYMBOL] ᠲᠠᠢ ᠤᠴᠠᠷᠠᠬᠤ ᠦᠶᠡᠰ ᠠᠵᠢᠯᠯᠠᠨᠠ ᠃ ",
    "gui.blocklyText.bluetooth.sendStr": "ᠯᠠᠨᠶᠨ ᠬᠦᠷᠭᠡᠬᠦ ᠦᠰᠦᠭ ᠲᠡᠮᠲᠡᠭ ᠦᠨ ᠬᠡᠯᠬᠢᠶᠡkey[KEY]VALUE[VALUE] ",
    "gui.blocklyText.bluetooth.sendNumber": "ᠯᠠᠨᠶᠠ ᠶᠢᠨ ᠶᠠᠪᠤᠭᠤᠯᠤᠭᠰᠠᠨ ᠲᠣᠭᠠKEY[KEY]VALUE[VALUE] ",
    "gui.blocklyText.bluetooth.openService": "bluetooth ᠶᠢ ᠨᠡᠭᠡᠭᠡᠬᠦ ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠡ ",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "bluetooth ᠴᠢᠮᠡᠭᠡ ᠬᠦᠯᠢᠶᠡᠨ᠎ᠡ ",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.leonardo.setBaud": "ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [SERIAL] ᠫᠤᠲ᠋ᠲ᠋ᠸᠷ ᠨᠤᠷᠮᠠ ᠨᠢ [BAUD] ",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": " [SERIAL] ᠡᠴᠡ ᠨᠢᠭᠡ [READTYPE] ᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.leonardo.getNumOfFloat": "[TARGET] 保留 [NNUMS] 位小数",
    "gui.blocklyText.leonardo.serialPort1": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 0 ",
    "gui.blocklyText.leonardo.serialPort2": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 1",
    "gui.blocklyText.leonardo.dataReadable": "[SERIAL] ᠤᠩᠰᠢᠵᠤ ᠪᠣᠯᠬᠤ  ᠳ᠋ᠠᠢᠲ᠋ᠠ ᠲᠠᠶ ᠤᠤ ︖ ",
    "gui.blocklyText.leonardo.readSerialData": "[SERIAL] ᠳ᠋ᠠᠶᠢᠲ᠋ᠠ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.arduinounoR3.initTcs34725": "初始化TCS34725颜色传感器",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "初始化TMI1650显示器",
    "gui.blocklyText.arduinounoR3.functionStarts": "uno ᠢᠵᠢ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.arduinounoR3.readdigital": "[PIN] ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [MODULE] ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.arduinounoR3.digitalSensor": "ᠲᠣᠭᠠᠨ ᠤ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.arduinounoR3.buttonModule": "ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ ",
    "gui.blocklyText.arduinounoR3.stickButton": "ᠰᠠᠵᠢᠯᠠᠭᠤᠷ ᠤᠨ ᠳᠠᠷᠤᠪᠴᠢ ",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "ᠳᠣᠷᠭᠢᠯᠲᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.arduinounoR3.readAnalog": "ᠤᠩᠰᠢᠬᠤ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠤᠨ [SENSOR] ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.arduinounoR3.anologSensor": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.arduinounoR3.lightSensor": "ᠭᠡᠷᠡᠯ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.arduinounoR3.soundSensor": "ᠳᠠᠭᠤᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.arduinounoR3.stickDirection": "ᠰᠠᠵᠢᠯᠠᠭᠤᠷ ᠤᠨ ᠴᠢᠭᠯᠡᠯ ",
    "gui.blocklyText.arduinounoR3.angleSensor": "ᠥᠨᠴᠥᠭ ᠦᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.arduinounoR3.graySensor": "ᠴᠠᠢᠢᠷᠠᠴᠠ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "ᠬᠥᠷᠥᠰᠥᠨ ᠦ ᠴᠢᠭᠢᠭᠲᠦᠴᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.arduinounoR3.open": "ᠨᠡᠭᠡᠭᠡᠬᠦ ",
    "gui.blocklyText.arduinounoR3.close": "ᠬᠠᠭᠠᠬᠤ ",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [MODULE] [PIN]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "ᠲᠣᠭᠠᠨ ᠤ ᠬᠡᠷᠡᠭᠵᠢᠭᠦᠯᠦᠭᠦᠷ ",
    "gui.blocklyText.arduinounoR3.ledLights": "LED ᠳ᠋ᠧᠩ ",
    "gui.blocklyText.arduinounoR3.fanModule": "ᠰᠠᠩᠰᠠ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ ",
    "gui.blocklyText.arduinounoR3.recordModule": "ᠰᠢᠩᠭᠡᠭᠡᠬᠦ ᠬᠡᠰᠡᠭᠲᠦ ",
    "gui.blocklyText.arduinounoR3.relay": "ᠲᠠᠲᠠᠭᠳᠠᠭᠤᠷ ",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "ᠬᠡᠳᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷtrigᠨᠢ [TRIG]echoᠨᠢ [ECHO] ",
    "gui.blocklyText.arduinounoR3.simulationActuator": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ ᠬᠡᠷᠡᠭᠵᠢᠭᠦᠯᠦᠭᠦᠷ ",
    "gui.blocklyText.arduinounoR3.writeAnalog": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [MODULE] ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠨᠢ [VALUE] ",
    "gui.blocklyText.arduinounoR3.forward": "ᠤᠷᠤᠭᠰᠢᠯᠠᠬᠤ ",
    "gui.blocklyText.arduinounoR3.backward": "ᠤᠬᠤᠷᠢᠬᠤ ",
    "gui.blocklyText.arduinounoR3.turnLeft": "ᠵᠡᠭᠦᠨᠰᠢ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.arduinounoR3.turnRight": "ᠪᠠᠷᠠᠭᠤᠨᠰᠢ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ [SPEED] % ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR] ",
    "gui.blocklyText.arduinounoR3.motorForward": "ᠵᠥᠪ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.arduinounoR3.motorReverse": "ᠭᠡᠳᠡᠷᠭᠦ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": " ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ [MOTOR]  ᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [SPEED]  ᠢᠢᠨ ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR] ",
    "gui.blocklyText.arduinounoR3.stopMotor": "ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ ( MA ᠪᠣᠯᠤᠨ MB  ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ ) ᠵᠣᠭᠰᠣᠬᠤ ",
    "gui.blocklyText.arduinounoR3.setServo": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠦᠢᠯᠡᠴᠢᠯᠡᠬᠦ ᠵᠠᠯᠠᠭᠤᠷ ᠮᠠᠰᠢᠨ  ᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [DEGREE]",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "ᠬᠡᠲᠦ ᠤᠯᠠᠭᠠᠨ ᠰᠠᠴᠤᠷᠠᠭᠤᠯᠭ᠎ᠠ [TYPE]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠲᠣᠭ᠎ᠠ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ [VALUE] ᠪᠢᠲ ᠦᠨ ᠲᠣᠭ᠎ᠠ [BIT] ",
    "gui.blocklyText.arduinounoR3.pressInfrared": "ᠬᠡᠳᠦ ᠤᠯᠠᠭᠠᠨ [PIN] ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠢ [BUTTON] ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢ ᠳᠠᠷᠤᠵᠤ ᠠᠪᠬᠤ ᠤᠤ ? ",
    "gui.blocklyText.arduinounoR3.noSound": "ᠳᠠᠭᠤ ᠦᠭᠡᠢ ",
    "gui.blocklyText.arduinounoR3.half": "1/2 ",
    "gui.blocklyText.arduinounoR3.quarter": "1/4",
    "gui.blocklyText.arduinounoR3.oneInEighth": "1/8 ",
    "gui.blocklyText.arduinounoR3.wholeBeat": "ᠪᠦᠬᠦᠯᠢ ᠴᠣᠬᠢᠯᠭ᠎ᠠ ",
    "gui.blocklyText.arduinounoR3.doubleBeat": "ᠬᠣᠣᠰ ᠴᠣᠬᠢᠯᠭ᠎ᠠ ",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ ᠪᠦᠷᠢᠶᠡᠨ ᠦ ᠬᠥᠭ [TONE] ᠬᠡᠮᠨᠡᠯ ᠨᠢ [BEAT] ",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ MP3 ᠬᠡᠰᠡᠭᠲᠦ ᠦᠨ ᠳᠠᠭᠤᠨ ᠤ ᠬᠡᠮᠵᠢᠶ᠎ᠡ ᠨᠢ [VOLUME] ",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ MP3 ᠬᠡᠰᠡᠭᠲᠦ ᠦᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠨᠢ [MODE] ",
    "gui.blocklyText.arduinounoR3.stop": "ᠵᠣᠭᠰᠣᠭᠠᠬᠤ ",
    "gui.blocklyText.arduinounoR3.play": "ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠭᠡ ",
    "gui.blocklyText.arduinounoR3.loop": "ᠡᠭᠡᠯᠵᠢᠯᠡᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ",
    "gui.blocklyText.arduinounoR3.random": "ᠲᠠᠰᠢᠷᠠᠮ ᠤᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠭᠡ ",
    "gui.blocklyText.arduinounoR3.pause": "ᠲᠦᠷ ᠵᠣᠭᠰᠣᠬᠤ ",
    "gui.blocklyText.arduinounoR3.previous": "ᠳᠡᠭᠡᠷ᠎ᠡ ᠨᠢᠭᠡ ᠳᠠᠭᠤᠤ ",
    "gui.blocklyText.arduinounoR3.next": "ᠳᠠᠷᠠᠭᠠᠴᠢ ᠶᠢᠨ ᠳᠠᠭᠤᠤ ",
    "gui.blocklyText.arduinounoR3.VolumePlus": "ᠳᠠᠭᠤ ᠶᠢᠨ ᠨᠡᠮᠡᠭᠦᠷᠢ ",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "ᠳᠠᠭᠤ ᠶᠢᠨ ᠬᠠᠰᠤᠭᠳᠠᠯ ",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ MP3 ᠬᠡᠰᠡᠭᠲᠦ [NUM] ᠠᠩᠬᠠᠨ ᠤ ᠳᠠᠭᠤᠤ ᠶᠢ ᠲᠠᠯᠪᠢᠨᠠ ",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ (LM35)  ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.arduinounoR3.readDHT11": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [TYPE] ",
    "gui.blocklyText.arduinounoR3.red": "ᠤᠯᠠᠭᠠᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ",
    "gui.blocklyText.arduinounoR3.blue": "ᠬᠥᠬᠡ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ",
    "gui.blocklyText.arduinounoR3.green": "ᠨᠣᠭᠣᠭᠠᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ",
    "gui.blocklyText.arduinounoR3.readColorSensor": "TCS34725 ᠥᠩᠭᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠦᠨ [COLORTYPE]  ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "ᠳᠥᠷᠪᠡᠨ ᠣᠷᠣᠨᠲᠤ ᠲᠣᠭᠠᠨ ᠤ ᠬᠣᠭᠣᠯᠠᠶ_TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "ᠨᠡᠭᠡᠭᠡᠬᠦ ",
    "gui.blocklyText.arduinounoR3.tm1650Close": "ᠬᠠᠭᠠᠬᠤ",
    "gui.blocklyText.arduinounoR3.clearScreen": "ᠳᠡᠯᠭᠡᠴᠡ ᠴᠡᠪᠡᠷᠯᠡᠬᠦ ",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": "ᠳᠥᠷᠪᠡᠨ ᠣᠷᠣᠨᠲᠤ ᠲᠣᠭᠠᠨ ᠤ ᠬᠣᠭᠣᠯᠠᠶ_TM1650显示字符串 [TEXT] ",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "ᠳᠥᠷᠪᠡᠨ ᠣᠷᠣᠨᠲᠤ ᠲᠣᠭᠠᠨ ᠤ ᠬᠣᠭᠣᠯᠠᠶ_TM1650第[ORDER] 个小数点 [STATUS]",
    "gui.blocklyText.arduinounoR3.bright": "ᠭᠡᠭᠡᠬᠡᠨ ",
    "gui.blocklyText.arduinounoR3.dark": "ᠬᠠᠷᠠᠩᠭᠤᠶ ",
    "gui.blocklyText.arduinounoR3.setLightColor": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠳ᠋ᠧᠩ ᠦᠨ ᠨᠣᠮᠧᠷ [NUM] [COLOR] ",
    "gui.blocklyText.arduinounoR3.lightsRGB": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠳ᠋ᠧᠩ ᠦᠨ ᠨᠣᠮᠧᠷ [NUM] R ᠬᠡᠮᠵᠢᠭᠳᠡᠯ :[R] G ᠬᠡᠮᠵᠢᠭᠳᠡᠯ :[G] B ᠬᠡᠮᠵᠢᠭᠳᠡᠯ :[B] ",
    "gui.blocklyText.arduinounoR3.numberOfLights": "RGB ᠳ᠋ᠧᠩ ᠦᠨ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠳ᠋ᠧᠩ ᠦᠨ ᠲᠣᠭ᠎ᠠ [PIN] [NUM] ",
    "gui.blocklyText.arduinounoR3.ledControl": "Led ᠵᠢᠭᠰᠠᠭᠠᠯ ᠤᠨ ᠴᠡᠭ (MAX7219) ᠠᠩᠬᠠᠨ ᠤ ᠪᠣᠯᠵᠣᠯᠲᠤ ᠲᠣᠭ᠎ᠠ  ᠤᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠨᠢ [DIN] CS ᠬᠣᠭᠣᠯᠠᠶ ᠶᠢᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠨᠢ [CS] C L K ᠬᠣᠭᠣᠯᠠᠶ ᠶᠢᠨ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠨᠢ [CLK] ",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "Led ᠵᠢᠭᠰᠠᠭᠠᠯ ᠤᠨ ᠴᠡᠭ (MAX7219) ᠢᠯᠡᠷᠡᠯ ᠵᠢᠷᠤᠭ [PIC] ",
    "videoSensing.categoryName": "视频侦测",
    "videoSensing.direction": "方向",
    "videoSensing.motion": "运动",
    "videoSensing.off": "关闭",
    "videoSensing.on": "开启",
    "videoSensing.onFlipped": "镜像开启",
    "videoSensing.setVideoTransparency": "将视频透明度设为[TRANSPARENCY]",
    "videoSensing.sprite": "角色",
    "videoSensing.stage": "舞台",
    "videoSensing.videoOn": "相对于[SUBJECT]的视频[ATTRIBUTE]",
    "videoSensing.videoToggle": "[VIDEO_STATE]摄像头",
    "videoSensing.whenMotionGreaterThan": "当视频运动 > [REFERENCE]",
    "gui.blocklyText.max.maxStart": "MAX ᠭᠣᠣᠯ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.max.onboardButtonPressed": "ᠬᠠᠪᠲᠠᠰᠤᠨ ᠳᠡᠭᠡᠷᠡᠬᠢ ᠳᠠᠷᠤᠭᠤᠯ [PRESSED]",
    "gui.blocklyText.max.pressed": "ᠳᠠᠷᠤᠭᠰᠠᠨ ",
    "gui.blocklyText.max.notPressed": "ᠳᠠᠷᠤᠭᠰᠠᠨ ᠦᠭᠡᠢ ",
    "gui.blocklyText.max.all": "-1  ᠪᠦᠭᠦᠳᠡ",
    "gui.blocklyText.max.A": "0 ᠪᠠᠷᠠᠭᠤᠨ ᠬᠣᠶᠢᠨᠠ ",
    "gui.blocklyText.max.B": "1 ᠪᠠᠷᠠᠭᠤᠨ ᠡᠮᠦᠨ᠎ᠡ ",
    "gui.blocklyText.max.C": "2 ᠵᠡᠭᠦᠨ ᠡᠮᠦᠨ᠎ᠡ ",
    "gui.blocklyText.max.D": "3 ᠵᠡᠭᠦᠨ ᠬᠣᠶᠢᠨᠠ ",
    "gui.blocklyText.max.rgbLightsColor": "RGB ᠳ᠋ᠧᠩ ᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [TYPE]  ᠤᠨ ᠥᠩᠭᠡ ᠨᠢ [COLOR] ",
    "gui.blocklyText.max.readSoundSensor": " ᠳᠠᠭᠤ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠦᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.max.playSoundEffect": "ᠳᠠᠭᠤᠨ ᠤ ᠦᠢᠯᠡᠳᠦᠯ [SOUND]",
    "gui.blocklyText.max.setDPinValue": "ᠲᠣᠭᠠᠨ ᠤ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠨᠢ [LEVEL] ",
    "gui.blocklyText.max.readDPinValue": "ᠲᠣᠭᠠᠨ ᠤ ᠠᠮᠠᠰᠠᠷ [PIN]  ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.max.readAPinValue": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ ᠠᠮᠠᠰᠠᠷ ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ [PIN]",
    "gui.blocklyText.max.setServo": "[PIN] ᠲᠠᠲᠠᠬᠤ ᠵᠠᠯᠠᠭᠤᠷ ᠢ [DEGREE] ᠪᠠᠶᠢᠷᠢᠯᠠᠭᠤᠯᠬᠤ ᠬᠡᠮᠵᠢᠶ᠎ᠡ ",
    "gui.blocklyText.max.readLinefindingSensor": " ᠱᠤᠭᠤᠮ ᠤᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ [DIR]  ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.max.left": "ᠵᠡᠭᠦᠨ ",
    "gui.blocklyText.max.middle": "ᠳᠤᠮᠳᠠ ",
    "gui.blocklyText.max.right": "ᠪᠠᠷᠠᠭᠤᠨ ",
    "gui.blocklyText.max.setLineFidingCarLights": " ᠱᠤᠭᠤᠮ ᠮᠥᠷᠳᠡᠬᠦ ᠲᠡᠷᠭᠡᠨ ᠤ ᠳ᠋ᠧᠩ ᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [SWITCH]",
    "gui.blocklyText.max.on": " ᠨᠣᠴᠤᠭᠠᠬᠤ",
    "gui.blocklyText.max.off": "ᠤᠨᠲᠠᠷᠠᠭᠠᠬᠤ",
    "gui.blocklyText.max.readUltrasoundSensor": "ᠬᠡᠳᠦ ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ᠦᠨ ᠵᠠᠶ ( cm ) ",
    "gui.blocklyText.max.readLightSensor": "ᠭᠡᠷᠡᠯ  [LEFTRIGHT] ᠤᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ  ᠶᠢᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠤᠩᠰᠢᠬᠤ ",
    "gui.blocklyText.max.maxSpeedMove": "MAX [SPEED] ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR] ",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL][SPEED] ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR] ",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] ᠵᠣᠭᠰᠤᠬᠤ ",
    "gui.blocklyText.max.showUserDefineExpressions": "ᠦᠵᠡᠭᠦᠯᠬᠦ ᠥᠪᠡᠷ ᠦᠨ ᠲᠣᠮᠢᠶᠠᠯᠠᠯ ᠣᠨ ᠢᠯᠡᠷᠡᠯ [EXPRESSION] ᠥᠩᠭᠡ [COLOR] ",
    "gui.blocklyText.max.clear": "ᠳᠡᠯᠭᠡᠴᠡ ᠴᠡᠪᠡᠷᠯᠡᠬᠦ ",
    "gui.blocklyText.max.do": "do",
    "gui.blocklyText.max.re": "re",
    "gui.blocklyText.max.mi": "mi",
    "gui.blocklyText.max.fa": "fa",
    "gui.blocklyText.max.sou": "sou",
    "gui.blocklyText.max.la": "la",
    "gui.blocklyText.max.xi": "xi",
    "gui.blocklyText.max.highdo": " ᠥᠨᠳᠥᠷ do",
    "gui.blocklyText.max.brake": "9ᠲᠣᠷᠮᠣᠰᠯᠠᠬᠤ ᠳᠠᠭᠤ ",
    "gui.blocklyText.max.rebound": "10ᠬᠠᠷᠢᠮᠠᠬᠠᠶ ᠳᠠᠭᠤ ",
    "gui.blocklyText.max.bullets": "11ᠬᠣᠶᠠᠷ ᠤᠳᠠᠭᠠᠨ ᠤ ᠰᠤᠮᠤ ᠶᠢᠨ ᠳᠠᠭᠤᠨ ",
    "gui.blocklyText.max.longecho": "12ᠴᠢᠷᠭᠡᠦᠯ ᠠᠶᠠᠰ ᠤᠨ ᠴᠤᠤᠷᠢᠶ᠎ᠡ ",
    "gui.blocklyText.max.playfulending": "14ᠱᠣᠭ ᠬᠡᠯᠡᠨ ᠦ ᠲᠡᠭᠦᠰᠬᠡᠯ ᠣᠨ ᠠᠪᠢᠶ᠎ᠡ ",
    "gui.blocklyText.max.yo": "13 ᠶᠤᠬ᠎ᠠ  ",
    "gui.blocklyText.max.wo": "15ᠠ ",
    "gui.blocklyText.max.robottalking": "16ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ ᠦᠭᠡ ᠶᠠᠷᠢᠨᠠ ",
    "gui.blocklyText.sen0160.init": "设置Hx711重量传感器校准值 [VALUE] 引脚 Dout [DOUT] SCK [SCK]",
    "gui.blocklyText.sen0160.readValue": "读取Hx711重量传感器(g) 引脚 Dout [DOUT] SCK [SCK]",
    "gui.blocklyText.knowflow.readORP": " ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠢᠰᠦᠯᠳᠦᠭᠦᠯᠦᠨ ᠠᠩᠭᠢᠵᠢᠷᠠᠭᠤᠯᠬᠤ (ORP) ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ (mV)",
    "gui.blocklyText.knowflow.readEC": " ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠴᠠᠬᠢᠯᠭᠠᠨ ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠮᠵᠢ (EC) ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ （ms/cm） ( V 1) ",
    "gui.blocklyText.knowflow.readPH": " ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠬᠦᠴᠢᠯ ᠱᠦᠯᠲᠦ ᠶᠢᠨ ᠬᠡᠮᠵᠢᠶ᠎ᠡ PH ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ( V 1 ) ",
    "gui.blocklyText.knowflow.readECV2": " ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠴᠠᠬᠢᠯᠭᠠᠨ ᠳᠠᠮᠵᠢᠭᠤᠯᠤᠮᠵᠢ (EC) ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ (ms/ c m ) ( V 2 ) ",
    "gui.blocklyText.knowflow.readPHV2": " ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] ᠬᠦᠴᠢᠯ ᠱᠦᠯᠲᠦ ᠶᠢᠨ ᠬᠡᠮᠵᠢᠶ᠎ᠡ PH ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ (V2) ",
    "gui.blocklyText.knowflow.readDO": " ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠠᠭᠤᠰᠬᠠᠭᠴᠢ DO ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ （mg/L） ",
    "gui.blocklyText.peristalticPump.speedMove": " ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] ᠭᠦᠷᠪᠡᠯᠵᠡᠭᠦᠷ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [SPEED] % ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR] ",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN]  ᠤᠨ ᠲᠠᠲᠠᠭᠳᠠᠭᠤᠷ ",
    "translate.translateBlock": "[WORDS]  ᠢ [LANGUAGE] ᠭᠡᠵᠦ ᠣᠷᠴᠢᠭᠤᠯᠪᠠ ",
    "translate.defaultTextToTranslate": "ᠰᠠᠢᠨ ᠪᠠᠢᠨᠠ ᠤᠤ ",
    "translate.viewerLanguage": "ᠣᠳᠣᠬᠢ ᠰᠣᠹᠲ ᠦᠨ ᠬᠡᠯᠡ ",
    "gui.blocklyText.goble.readAxis": "ᠤᠩᠰᠢᠬᠤ GOBLE ᠰᠠᠵᠢᠯᠭ᠎ᠠ [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ ",
    "gui.blocklyText.goble.buttonIsPressed": "GOBLE [BUTTON] ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢ ᠳᠠᠷᠤᠵᠤ ᠠᠪᠬᠤ ᠤᠤ ? ",
    "gui.blocklyText.eeprom.clear": "eeprom 清除",
    "gui.blocklyText.eeprom.read": "eeprom 读取地址 [ADDR] 的数据",
    "gui.blocklyText.eeprom.write": "eeprom 给地址 [ADDR] 写数据 [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom 获取校验值 从地址 [ADDRA] 到地址 [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom 获取长度(byte)",
    "gui.blocklyText.tds.readAnalogPin": "ᠳᠠᠭᠤᠷᠢᠶᠠᠯᠲᠠ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ [PIN] TDS ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ ",
    "gui.blocklyText.pythonsen0244.tdsInit": "TDS传感器[NAME]初始化 引脚[PIN]",
    "gui.blocklyText.pythonsen0244.readAnalogPin": "读取TDS传感器(ppm)[NAME]",
    "gui.blocklyText.steppermotor.initialJointXYParam": "ᠦᠰᠦᠷᠦᠭᠡ  ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ ᠦᠨ ᠬᠣᠯᠪᠣᠯᠳᠤᠯXᠲᠡᠩᠭᠡᠯᠢᠭ [XSET]Yᠲᠡᠩᠭᠡᠯᠢᠭ :[YSET] ",
    "gui.blocklyText.steppermotor.setXYStepsRs": "ᠦᠰᠦᠷᠦᠭᠡ  ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ ᠦᠨ ᠡᠷᠭᠢᠯᠲᠡ ᠶᠢᠨ ᠠᠯᠬᠤᠮ ᠤᠨ ᠲᠣᠭ᠎ᠠ :[STEPS] ᠡᠷᠭᠢᠯᠲᠡ ᠶᠢᠨ ᠬᠤᠷᠳᠤᠴᠠ (rmin) :[SPEED] ",
    "gui.blocklyText.steppermotor.moveSteps": "ᠦᠰᠦᠷᠦᠭᠡ  ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ ᠦᠨ ᠰᠢᠯᠵᠢᠬᠦ ᠠᠯᠬᠤᠮ ᠤᠨ ᠲᠣᠭ᠎ᠠ [STEPS] ᠴᠢᠭᠯᠡᠯ [ROTATEDIR] ",
    "gui.blocklyText.steppermotor.setJointSteps": "ᠦᠰᠦᠷᠦᠭᠡ  ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ ᠦᠨ ᠬᠣᠯᠪᠣᠯᠳᠤᠯXᠲᠡᠩᠭᠡᠯᠢᠭ ᠰᠢᠯᠵᠢᠬᠦ [XSTEPS] ᠠᠯᠬᠤᠮ ᠤᠨ ᠴᠢᠭᠯᠡᠯ [ROTATEDIRX]Yᠲᠡᠩᠭᠡᠯᠢᠭ ᠰᠢᠯᠵᠢᠬᠦ [YSTEPS] ᠠᠯᠬᠤᠮ ᠤᠨ ᠴᠢᠭᠯᠡᠯ [ROTATEDIRY] ",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠡᠬᠢᠯᠡᠬᠦ ",
    "gui.blocklyText.multithread.stopThread": "[THREAD]ᠵᠣᠭᠰᠣᠭᠠᠬᠤ ",
    "gui.blocklyText.multithread.startThread": "[THREAD]  ᠢ ᠬᠥᠳᠡᠯᠭᠡᠬᠦ ",
    "gui.blocklyText.multithread.loop1": "thread 1",
    "gui.blocklyText.multithread.loop2": "thread 2",
    "gui.blocklyText.multithread.loop3": "thread 3",
    "gui.blocklyText.multithread.loop4": "thread 4",
    "gui.blocklyText.multithread.loop5": "thread 5",
    "gui.blocklyText.multithread.loop6": "thread 6",
    "gui.blocklyText.multithread.loop7": "thread 7",
    "gui.blocklyText.multithread.loop8": "thread 8",
    "gui.blocklyText.multithread.all": "ᠪᠦᠬᠦ ᠬᠤᠪᠢᠶᠠᠷᠢ ᠤᠲᠠᠰᠤ ",
    "text2speech.speakAndWaitBlock": "[WORDS] ᠢ ᠤᠩᠰᠢᠬᠤ",
    "text2speech.setVoiceBlock": "[VOICE] ᠬᠣᠭᠣᠯᠠᠶ ᠶᠢ ᠬᠡᠷᠡᠭᠯᠡᠬᠦ",
    "text2speech.setLanguageBlock": "[LANGUAGE] ᠬᠡᠯᠡ ᠪᠡᠷ ᠤᠩᠰᠢᠬᠤ ᠪᠡᠷ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "text2speech.setRequestSever": "切换为[SERVER]",
    "text2speech.setSever2Voice": "设置服务器2使用[VOICE]嗓音",
    "text2speech.setSever2Language": "设置服务器2的朗读语言[LANGUAGE]",
    "text2speech.setSever2Account": "设置服务器2账号[MODAL]",
    "text2speech.international": "服务器1(国际)",
    "text2speech.china": "服务器2(中国)",
    "text2speech.alto": "ᠬᠠᠩᠭᠢᠨᠠᠮᠠ ᠬᠣᠭᠣᠯᠠᠶ ",
    "text2speech.tenor": "ᠡᠷᠡᠭᠲᠡᠶ ᠶᠢᠨ ᠬᠠᠩᠭᠢᠨᠠᠮᠠ ᠬᠣᠭᠣᠯᠠᠶ ",
    "text2speech.squeak": "ᠨᠠᠷᠢᠨ ᠥᠨᠳᠦᠷ",
    "text2speech.giant": "ᠠᠪᠤᠷᠭᠤ ᠬᠥᠮᠥᠨ ",
    "text2speech.kitten": " ᠪᠢᠴᠢᠬᠠᠨ ᠮᠤᠤᠷ ",
    "text2speech.defaultTextToSpeak": "你好",
    "gui.blocklyText.text2speech.server2voice0": "度小美",
    "gui.blocklyText.text2speech.server2voice1": "度小宇",
    "gui.blocklyText.text2speech.server2voice3": "度逍遥",
    "gui.blocklyText.text2speech.server2voice4": "度丫丫",
    "gui.blocklyText.text2speech.server2voice106": "度博文",
    "gui.blocklyText.text2speech.server2voice110": "度小童",
    "gui.blocklyText.text2speech.server2voice111": "度小萌",
    "gui.blocklyText.text2speech.server2voice103": "度米多",
    "gui.blocklyText.text2speech.server2voice5": "度小娇",
    "gui.blocklyText.DFR0151.init": "DS1307 初始化",
    "gui.blocklyText.DFR0151.adjustTime": "DS1307 ᠴᠠᠭ ᠢ [YEAR] ᠣᠨ [MONTH] ᠰᠠᠷᠠ ᠶᠢᠨ [DATE] ᠶᠠᠫᠣᠨ ᠤ ᠭᠠᠷᠠᠭ ᠤᠨ [HOUR] ᠴᠠᠭ [MINUTE] ᠮᠢᠨᠦ᠋ᠲ [SECOND] ᠰᠧᠺᠦ᠋ᠨ᠋ᠳ᠋ ",
    "gui.blocklyText.DFR0151.getTime": "DS1307 ᠴᠠᠭ [INDEX]  ᠢ ᠣᠯᠬᠤ ",
    "gui.blocklyText.DFR0151.Year": "ᠣᠨ ",
    "gui.blocklyText.DFR0151.month": "ᠰᠠᠷ᠎ᠠ ",
    "gui.blocklyText.DFR0151.date": "ᠡᠳᠦᠷ ",
    "gui.blocklyText.DFR0151.hour": "ᠴᠠᠭ ",
    "gui.blocklyText.DFR0151.minute": "ᠮᠢᠨᠦ᠋ᠲ ",
    "gui.blocklyText.DFR0151.second": "ᠰᠧᠺᠦ᠋ᠨ᠋ᠳ᠋ ",
    "gui.blocklyText.DFR0151.week": "ᠭᠠᠷᠠᠭ ",
    "gui.blocklyText.pythondfr0151.init": "DS1307 初始化 I2C地址0x68",
    "gui.blocklyText.pythondfr0151.adjustTime": "调整DS1307 时间为[YEAR]年[MONTH]月[DATE]日 周[WEEK] [HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.pythondfr0151.upTime": "获取一次时间数据",
    "gui.blocklyText.pythondfr0151.getTime": "从时间数据中解析[INDEX]",
    "gui.blocklyText.DFR0469.init": "SD2405 初始化",
    "gui.blocklyText.DFR0469.adjustTime": "SD2405 ᠴᠠᠭ ᠢ [YEAR] ᠣᠨ [MONTH] ᠰᠠᠷᠠ ᠶᠢᠨ [DATE] [HOUR] ᠴᠠᠭ [MINUTE] ᠮᠢᠨᠦ᠋ᠲ [SECOND] ᠰᠧᠺᠦ᠋ᠨ᠋ᠳ᠋  ᠳ᠋ᠤ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "gui.blocklyText.DFR0469.getTime": "SD2405 ᠴᠠᠭ [INDEX]  ᠢ ᠣᠯᠬᠤ ",
    "gui.blocklyText.pythondfr0469.init": "SD2405初始化 I2C地址0x32",
    "gui.blocklyText.pythondfr0469.adjustTime": "调整SD2405时间为[YEAR]年[MONTH]月[DATE]日 周[WEEK] [HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.pythondfr0469.upTime": "获取一次时间数据",
    "gui.blocklyText.pythondfr0469.getTime": "从时间数据中解析[INDEX]",
    "gui.blocklyText.DFR0126.setPin": "ᠳᠠᠭᠤᠨ ᠳᠠᠪᠲᠠᠮᠵᠢᠨ ᠵᠠᠳᠠᠯᠤᠯᠲᠠ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ ᠲᠦ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ #S[SPIN] #R[RPIN] ᠳᠠᠭᠤᠷᠢᠶᠠᠮᠠᠯ  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [APIN] ",
    "gui.blocklyText.DFR0126.readSoundFreq": "ᠳᠠᠭᠤᠨ ᠳᠠᠪᠲᠠᠮᠵᠢᠨ ᠵᠠᠳᠠᠯᠬᠤ ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ  ᠳᠠᠭᠤᠨ ᠳᠠᠪᠲᠠᠮᠵᠢ [FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "NFC ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠢ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ I2C",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "NFC ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠢ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ᠂ ᠬᠠᠲᠠᠭᠤ ᠵᠠᠯᠭᠠᠭᠤᠷ Rx [RX] Tx [TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "ᠡᠬᠢᠵᠢᠬᠦᠯᠦᠭᠰᠡᠨ ᠵᠠᠯᠭᠠᠰᠤ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ ᠰᠣᠩᠭᠣᠯᠲᠠ [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "ᠡᠬᠢᠵᠢᠬᠦᠯᠦᠭᠰᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ ᠰᠣᠩᠭᠣᠯᠲᠠ[SERIALPORT] Rx[PINR] Tx[PINT] ",
    "gui.blocklyText.DFR0126.writeSerialNfc": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠠᠮᠠᠰᠠᠷᠲᠤ NFC ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ ᠳ᠋ᠠᠶᠢᠲ᠋ᠠ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ [DATABLOCK] [BYTES] ᠥᠰᠥᠭ ᠦᠨ ᠦᠶ᠎ᠡ [VALUE] ᠪᠢᠴᠢᠨ ᠣᠷᠣᠭᠤᠯᠬᠤ ",
    "gui.blocklyText.DFR0126.readSerialNfc": "NFC ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ ᠳ᠋ᠠᠢᠲ᠋ᠠ ᠬᠡᠰᠡᠭᠲᠦ [DATABLOCK]  ᠤᠨ [BYTES] ᠥᠰᠥᠭ ᠦᠨ ᠦᠶ᠎ᠡ (number) ",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "NFC ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ  ᠳ᠋ᠠᠢᠲ᠋ᠠ ᠶᠢᠨ ᠬᠡᠰᠡᠭᠲᠦ [DATABLOCK]  ᠢ ᠤᠩᠰᠢᠵᠤ ᠠᠪᠬᠤ ᠪᠣᠶ ᠪᠦᠬᠦᠢ  ᠳ᠋ᠠᠢᠲ᠋ᠠ (string) ",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "NFC ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ UID (srting)  ᠶᠢ ᠤᠩᠰᠢᠵᠤ ᠠᠪᠬᠤ ",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "ᠺᠠᠷᠲ ᠤᠨ UID:[TEXT] ",
    "gui.blocklyText.DFR0126.scanSerialNfc": "ᠺᠠᠷᠲ ᠢᠶᠠᠨ ᠰᠢᠯᠭᠠᠵᠤ ᠣᠯᠪᠠ ᠤᠤ ? ",
    "gui.blocklyText.pythondfr0231.extensionName": "RFID/NFC",
    "gui.blocklyText.pythondfr0231.initSerialNfcIIC": "初始化RFID/NFC模块接口 I2C 地址0x24",
    "gui.blocklyText.pythondfr0231.writeSerialNfc": "RFID/NFC模块 数据块[DATABLOCK]第[BYTES]字节写入[VALUE]",
    "gui.blocklyText.pythondfr0231.readSerialNfcAll": "读取RFID/NFC模块 数据块[DATABLOCK]所有数据(string)",
    "gui.blocklyText.pythondfr0231.readSerialNfc": "读取RFID/NFC模块 数据块[DATABLOCK]第[BYTES]字节(number)",
    "gui.blocklyText.pythondfr0231.readSerialNfcUid": "读取RFID/NFC模块 UID(srting)",
    "gui.blocklyText.pythondfr0231.scanSerialNfcUid": "检测到卡片 UID:[TEXT]？",
    "gui.blocklyText.pythondfr0231.scanSerialNfc": "检测到卡片？",
    "gui.blocklyText.sen0245.init": "初始化VL53L0X激光测距传感器",
    "gui.blocklyText.sen0245.setVL53L0X": "VL53L0X ᠯᠠᠶᠢᠰᠧᠷ ᠵᠠᠶ ᠬᠡᠮᠵᠢᠬᠦ ᠬᠡᠰᠡᠭᠲᠦ [PRECISION]  ᠢ ᠪᠠᠶᠢᠷᠢᠯᠠᠭᠤᠯᠬᠤ ᠬᠡᠮᠵᠢᠯᠲᠡ ᠶᠢᠨ ᠲᠣᠭ᠎ᠠ [VALUE] ",
    "gui.blocklyText.sen0245.readVL53L0X": "VL53L0X ᠯᠠᠶᠢᠰᠧᠷ ᠵᠠᠶ ᠬᠡᠮᠵᠢᠯᠲᠡ (mm)  ᠶᠢ ᠣᠯᠬᠤ ",
    "gui.blocklyText.pythonsen0245.extensionName": "激光测距",
    "gui.blocklyText.pythonsen0245.init": "初始化VL53L0X激光测距传感器I2C地址0x29",
    "gui.blocklyText.pythonsen0245.setVL53L0X": "设置VL53L0X激光测距模块[PRECISION]测量次数[VALUE]",
    "gui.blocklyText.pythonsen0245.readVL53L0X": "获取VL53L0X激光测距值(mm)",
    "gui.blocklyText.SEN0245.LOW": "ᠳᠣᠣᠷ᠎ᠠ ᠨᠠᠷᠢᠪᠴᠢᠯᠠᠴᠠ (1mm)",
    "gui.blocklyText.SEN0245.HIGH": "ᠥᠨᠳᠥᠷ ᠨᠠᠷᠢᠪᠴᠢᠯᠠᠴᠠ (0.25 mm) ",
    "gui.blocklyText.SEN0245.Single": "ᠭᠠᠭᠴᠠ ᠤᠳᠠᠭᠠ ᠶᠢᠨ ᠬᠡᠮᠵᠢᠯᠲᠡ ",
    "gui.blocklyText.SEN0245.Continuous": "ᠦᠷᠭᠦᠯᠵᠢᠯᠡᠭᠰᠡᠨ ᠬᠡᠮᠵᠢᠯᠲᠡ ",
    "gui.blocklyText.sen0318.readValueBool": "读取CCS811空气质量是否有数据?",
    "gui.blocklyText.sen0318.readCO2TVCO": "获取空气质量 [TYPE] 的值",
    "gui.blocklyText.sen0318.CCS811init": "初始化CCS811数字空气质量传感器等待直到成功 获取数据时间:[TIME] I2C地址0x5A",
    "gui.blocklyText.sen0318.writeBaseline": "设置CCS811空气质量基数[BASE]",
    "gui.blocklyText.sen0318.CCS811SetBase": "设置基数:[BASE]",
    "gui.blocklyText.sen0318.250ms": "250ms",
    "gui.blocklyText.sen0318.1s": "1s",
    "gui.blocklyText.sen0318.10s": "10s",
    "gui.blocklyText.sen0318.60s": "60s",
    "gui.blocklyText.sen0318.close": "空闲",
    "gui.blocklyText.sen0318.CCS811ReadBaseline": "获取CCS811空气质量基数数值",
    "gui.blocklyText.pythonsen0318.readValueBool": "读取CCS811空气质量是否有数据?",
    "gui.blocklyText.pythonsen0318.readCO2TVCO": "获取空气质量 [TYPE] 的值",
    "gui.blocklyText.pythonsen0318.CCS811init": "初始化CCS811数字空气质量传感器 等待直到成功 获取数据时间:[TIME] I2C地址0x5A",
    "gui.blocklyText.pythonsen0318.writeBaseline": "设置CCS811空气质量基数[BASE]",
    "gui.blocklyText.pythonsen0318.CCS811ReadBaseline": "获取CCS811 空气质量基数数值",
    "gui.blocklyText.sen0315.PAJ7620U2init": "设置PAJ7620U2手势传感器 [MODE] 模式 I2C地址0x73",
    "gui.blocklyText.sen0315.high": "高速模式",
    "gui.blocklyText.sen0315.low": "低速模式",
    "gui.blocklyText.sen0315.PAJ7620U2GetValue": "PAJ7620U2手势传感器 获取一次数据",
    "gui.blocklyText.sen0315.PAJ7620U2IsTriggered": "PAJ7620U2手势传感器 [GESTURE] 被触发",
    "gui.blocklyText.sen0315.Right": "向右挥手",
    "gui.blocklyText.sen0315.Left": "向左挥手",
    "gui.blocklyText.sen0315.Up": "向上挥手",
    "gui.blocklyText.sen0315.Down": "向下挥手",
    "gui.blocklyText.sen0315.Forward": "靠近挥手",
    "gui.blocklyText.sen0315.Backward": "远离挥手",
    "gui.blocklyText.sen0315.Clockwise": "顺时针转动挥手",
    "gui.blocklyText.sen0315.Anti-Clockwise": "逆时针转动挥手",
    "gui.blocklyText.sen0315.Wave": "快速挥手",
    "gui.blocklyText.sen0315.WaveSlowlyDisorder": "低速混乱挥手",
    "gui.blocklyText.sen0315.WaveSlowlyLeftRight": "低速从左往右",
    "gui.blocklyText.sen0315.WaveSlowlyUpDown": "低速从上往下",
    "gui.blocklyText.sen0315.WaveSlowlyForwardBackward": "低速靠近再远离",
    "gui.blocklyText.pythonsen0315.extensionName": "手势识别",
    "gui.blocklyText.pythonsen0315.PAJ7620U2init": "设置PAJ7620U2手势传感器 [MODE] 模式直到成功 I2C地址0x73",
    "gui.blocklyText.pythonsen0315.PAJ7620U2GetValue": "PAJ7620U2手势传感器获取一次数据",
    "gui.blocklyText.pythonsen0315.PAJ7620U2IsTriggered": "PAJ7620U2手势传感器 [GESTURE] 被触发",
    "gui.blocklyText.sen0376.alcoholInit": "初始化电学酒精传感器直到成功 地址: [ADDR] 模式: [MODE]",
    "gui.blocklyText.sen0376.auto": "主动上传",
    "gui.blocklyText.sen0376.passive": "被动应答",
    "gui.blocklyText.sen0376.alcoholGetValue": "取[TIME]次酒精值的平均值(ppm)",
    "gui.blocklyText.sen0321.auto": "自动模式",
    "gui.blocklyText.sen0321.passive": "手动模式",
    "gui.blocklyText.sen0321.ozoneInit": "初始化I2C地址 [ADDR] 选择模式 [MODE]",
    "gui.blocklyText.sen0321.ozoneGetValue": "读取[TIME]次取一次平均值",
    "gui.blocklyText.sen0364.visibleSpectrumInit": "初始化光谱传感器 I2C地址0x39",
    "gui.blocklyText.sen0364.LEDswitch": "使能LED [SWITCH]",
    "gui.blocklyText.sen0364.LEDBrightness": "设置LED亮度为 [BRIGHTNESS]",
    "gui.blocklyText.sen0364.off": "关闭",
    "gui.blocklyText.sen0364.on": "打开",
    "gui.blocklyText.sen0364.spectrumGetValue": "读取传感器 [CHANNEL] 通道的值",
    "gui.blocklyText.sen0364.spectrumGetFreq": "光源的闪烁频率",
    "gui.blocklyText.sen0364.visibleSpectrumSet": "设置通道映射模式为 [MODE] (模式1可读取通道(F1-F4)，模式2可读取通道(F5-NIR))",
    "gui.blocklyText.kit0176.weightInit": "初始化重量传感器直到成功 地址:[ADDR]",
    "gui.blocklyText.kit0176.weightCal": "设置校准重量 [CAL]",
    "gui.blocklyText.kit0176.weightAutoCal": "设置重量传感器模块自动校准时的触发阈值 [CAL]",
    "gui.blocklyText.kit0176.weightStartUpCal": "启动自动校准",
    "gui.blocklyText.kit0176.IsSucesse": "校准是否成功?",
    "gui.blocklyText.kit0176.setCal": "设置/更新校准值",
    "gui.blocklyText.kit0176.getWeightValue": "获取物体重量",
    "gui.blocklyText.kit0176.getCalValue": "获取校准值",
    "gui.blocklyText.kit0176.setCalValue": "设置/更新校准值 [CAL] (手动模式)",
    "gui.blocklyText.sen0497.DHT20Init": "等待直到DHT20温湿度传感器模块I2C连接成功 I2C地址0x38",
    "gui.blocklyText.sen0497.DHT20Read": "读取DHT20 [INDEX]",
    "gui.blocklyText.pythonsen0497.DHT20Init": "等待直到DHT20温湿度传感器模块 I2C连接成功 I2C地址0x38",
    "gui.blocklyText.pythonsen0497.DHT20Read": "读取DHT20 [INDEX]",
    "gui.blocklyText.sen0497.temp": "温度(℃)",
    "gui.blocklyText.sen0497.humi": "湿度(%RH)",
    "gui.blocklyText.sen0514.ens160Init": "初始化ENS160 I2C地址 [ADDR]",
    "gui.blocklyText.sen0514.ens160SetMode": "ENS160设置模式[MODE]",
    "gui.blocklyText.pythonsen0514.ens160Init": "初始化ENS160 I2C地址 [ADDR]",
    "gui.blocklyText.pythonsen0514.ens160SetMode": "ENS160 设置模式[MODE]",
    "gui.blocklyText.sen0514.standard": "标准模式",
    "gui.blocklyText.sen0514.idle": "空闲模式",
    "gui.blocklyText.sen0514.sleep": "睡眠模式",
    "gui.blocklyText.sen0514.ens160CalComp": "ENS160校准和补偿温度 [TEMP]℃ 湿度[HUMI]%rh",
    "gui.blocklyText.sen0514.ens160GetValue": "ENS160读取 [VALUE]",
    "gui.blocklyText.pythonsen0514.ens160CalComp": "ENS160校准和补偿温度 [TEMP]℃ 湿度[HUMI]%rh",
    "gui.blocklyText.pythonsen0514.ens160GetValue": "ENS160读取 [VALUE]",
    "gui.blocklyText.sen0514.eco2": "CO2浓度",
    "gui.blocklyText.sen0514.tvoc": "TVOC浓度",
    "gui.blocklyText.sen0514.aqi": "空气质量指数",
    "gui.blocklyText.sen0514.status": "运行状态",
    "gui.blocklyText.dfr0991.rgbButtonInit": "初始化I2C RGB灯按钮模块等待直到成功[ADDR]",
    "gui.blocklyText.dfr0991.setRgbButtonColor1": "设置显示颜色 红[RED]绿[GREEN]蓝[BLUE]",
    "gui.blocklyText.dfr0991.setRgbButtonColor": "设置显示颜色[COLOR]",
    "gui.blocklyText.dfr0991.getRgbButtonStatus": "按键按下？",
    "gui.blocklyText.sen0518.max30102Init": "初始化MAX30102 I2C地址0x57",
    "gui.blocklyText.sen0518.initSerialMax30102": "初始化MAX30102模块接口 [SERIALPORT] Rx(绿)[PINR] Tx(蓝)[PINT]",
    "gui.blocklyText.sen0518.max30102StartCollect": "MAX30102开始收集",
    "gui.blocklyText.sen0518.max30102GetValue": "MAX30102读取[VALUE]",
    "gui.blocklyText.pythonsen0518.max30102Init": "初始化MAX30102 I2C地址0x57",
    "gui.blocklyText.pythonsen0518.max30102StartCollect": "MAX30102 开始收集",
    "gui.blocklyText.pythonsen0518.max30102GetValue": "MAX30102 读取[VALUE]",
    "gui.blocklyText.sen0518.spo2": "血氧%",
    "gui.blocklyText.sen0518.heartbeat": "心率Times/min",
    "gui.blocklyText.sen0518.temp": "传感器的温度℃",
    "gui.blocklyText.sen0359.fingerprintInit": "初始化指纹识别模块 I2C地址0x1F",
    "gui.blocklyText.sen0359.fingerprintSuccess": "等待直到指纹模块连接成功",
    "gui.blocklyText.sen0359.green": "绿色",
    "gui.blocklyText.sen0359.red": "红色",
    "gui.blocklyText.sen0359.yellow": "黄色",
    "gui.blocklyText.sen0359.blue": "蓝色",
    "gui.blocklyText.sen0359.blue1": "青色",
    "gui.blocklyText.sen0359.magenta": "洋红",
    "gui.blocklyText.sen0359.white": "白色",
    "gui.blocklyText.sen0359.breathe": "呼吸",
    "gui.blocklyText.sen0359.flash": "快闪",
    "gui.blocklyText.sen0359.noropen": "常开",
    "gui.blocklyText.sen0359.norclose": "常闭",
    "gui.blocklyText.sen0359.slowopen": "渐开",
    "gui.blocklyText.sen0359.slowclose": "渐关",
    "gui.blocklyText.sen0359.slowflash": "慢闪",
    "gui.blocklyText.sen0359.fingerprintSetLED": "设置LED灯 颜色[COLOR]模式 [MODE]次数 [TIMES]",
    "gui.blocklyText.sen0359.fingerTouch": "手指触碰?",
    "gui.blocklyText.sen0359.fingerprintCollection": "采集一次指纹是否成功? 超时时间 [TIME] 秒",
    "gui.blocklyText.sen0359.fingerprintMatch": "将采集的指纹与所有ID匹配",
    "gui.blocklyText.sen0359.fingerprintMatchID": "将采集的指纹与ID[ID]是否匹配？",
    "gui.blocklyText.sen0359.getFingerprintID": "获取一个可注册的ID",
    "gui.blocklyText.sen0359.fingerprintIDRegistrable": "ID [ID] 已注册?",
    "gui.blocklyText.sen0359.getRegisterNum": "获取注册用户数量",
    "gui.blocklyText.sen0359.saveID": "保存指纹到ID [ID] 是否成功?",
    "gui.blocklyText.sen0359.deleteID": "删除指纹ID [ID]",
    "gui.blocklyText.sen0359.deleteIDAll": "删除全部指纹",
    "gui.blocklyText.pythonsen0359.extensionName": "电容指纹",
    "gui.blocklyText.pythonsen0359.fingerprintInit": "初始化指纹识别模块 I2C地址0x1F",
    "gui.blocklyText.pythonsen0359.fingerprintSuccess": "等待直到指纹模块连接成功",
    "gui.blocklyText.pythonsen0359.fingerprintSetLED": "设置LED灯 颜色[COLOR]模式 [MODE]次数 [TIMES]",
    "gui.blocklyText.pythonsen0359.fingerTouch": "手指触碰?",
    "gui.blocklyText.pythonsen0359.fingerprintCollection": "采集一次指纹是否成功? 超时时间 [TIME] 秒",
    "gui.blocklyText.pythonsen0359.fingerprintMatch": "将采集的指纹与所有ID匹配",
    "gui.blocklyText.pythonsen0359.fingerprintMatchID": "将采集的指纹与ID[ID]是否匹配？",
    "gui.blocklyText.pythonsen0359.getFingerprintID": "获取一个可注册的ID",
    "gui.blocklyText.pythonsen0359.fingerprintIDRegistrable": "ID [ID] 已注册?",
    "gui.blocklyText.pythonsen0359.getRegisterNum": "获取注册用户数量",
    "gui.blocklyText.pythonsen0359.saveID": "保存指纹到ID [ID] 是否成功?",
    "gui.blocklyText.pythonsen0359.deleteID": "删除指纹ID [ID]",
    "gui.blocklyText.pythonsen0359.deleteIDAll": "删除全部指纹",
    "gui.blocklyText.serialMp3.initSerialMp3": "ᠡᠬᠢᠵᠢᠬᠦᠯᠦᠭᠰᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ MP3 ᠬᠡᠰᠡᠭᠲᠦ [SERIALPORT] #RX[RX] #TX[TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ MP3 ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢ ᠡᠵᠡᠮᠰᠢᠬᠦ [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ MP3 ᠬᠡᠰᠡᠭᠲᠦ ᠲᠦ ᠭᠥᠭᠵᠢᠮ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ [VALUE] ",
    "gui.blocklyText.serialMp3.setMP3Volume": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ MP3 ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ ᠳᠠᠭᠤᠨ ᠤ ᠬᠡᠮᠵᠢᠶ᠎ᠡ ᠨᠢ [VOLUME] ",
    "gui.blocklyText.serialMp3.play": "ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ",
    "gui.blocklyText.serialMp3.pause": "ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠭᠡ ᠲᠦᠷ ᠵᠣᠭᠰᠣᠬᠤ ",
    "gui.blocklyText.serialMp3.end": "ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠭᠡ ᠳᠠᠭᠤᠰᠬᠤ ",
    "gui.blocklyText.serialMp3.prev": " ᠳᠡᠭᠡᠷ᠎ᠡ ᠨᠢᠭᠡ ᠳᠠᠭᠤᠤ ",
    "gui.blocklyText.serialMp3.next": "ᠳᠠᠷᠠᠭ᠎ᠠ  ᠨᠢᠭᠡ ᠳᠠᠭᠤᠤ ",
    "gui.blocklyText.serialMp3.volumeUp": "ᠳᠠᠭᠤ ᠨᠠᠮᠡᠬᠦ",
    "gui.blocklyText.serialMp3.volumeDown": "ᠳᠠᠭᠤ ᠬᠠᠰᠤᠬᠤ",
    "gui.blocklyText.serialMp3.playlist": "ᠠᠶ᠎ᠡ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ",
    "gui.blocklyText.serialMp3.insertTracks": " ᠬᠠᠪᠴᠢᠭᠤᠯᠵᠤ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ",
    "gui.blocklyText.serialMp3.volume": "ᠳᠠᠭᠤᠨ ᠤ ᠬᠡᠮᠵᠢᠶ᠎ᠡ ",
    "gui.blocklyText.servo360.setServo360": "ᠵᠠᠯᠭᠠᠳᠠᠰᠤ [PIN] [SPEED] %  ᠢᠢᠨ ᠬᠤᠷᠳᠤᠴᠠ ᠪᠡᠷ [DIR] ",
    "gui.blocklyText.servo360.CW": "ᠵᠥᠪ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.servo360.CCW": "ᠭᠡᠳᠡᠷᠭᠦ ᠡᠷᠭᠢᠬᠦ ",
    "gui.blocklyText.TCS34725.initTcs34725": "初始化TCS34725颜色传感器",
    "gui.blocklyText.TCS34725.readTcs34725": "TCS34725 ᠥᠩᠭᠡ ᠶᠢᠨ ᠰᠡᠷᠡᠭᠳᠡᠭᠦᠷ [COLOR] [FORMAT] ᠢ ᠤᠩᠰᠢᠬᠤ",
    "gui.blocklyText.pythonsen0212.extensionName": "颜色识别",
    "gui.blocklyText.pythonsen0212.initTcs34725": "初始化TCS34725颜色传感器 I2C地址0x29",
    "gui.blocklyText.pythonsen0212.updateTcs34725": "获取一次TCS34725数据",
    "gui.blocklyText.pythonsen0212.readTcs34725": "从数据中解析TCS34725颜色传感器 [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "GAMMA  ᠶᠢ ᠡᠷᠭᠢᠭᠦᠯᠬᠦ ",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "GAMMA ᠶᠢ ᠡᠰᠡ ᠡᠷᠭᠢᠭᠦᠯᠬᠦ",
    "gui.blocklyText.regEmotion8_16.showText": " ᠲᠠᠢᠺᠸᠰᠲ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ [TEXT] [COLOR] ",
    "gui.blocklyText.regEmotion8_16.scroll": "ᠦᠰᠦᠭ ᠪᠢᠴᠢᠭ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ ᠥᠩᠬᠥᠷᠢᠭᠦᠯ [DIR]",
    "gui.blocklyText.regEmotion8_16.left": "ᠵᠡᠭᠦᠨᠰᠢ",
    "gui.blocklyText.regEmotion8_16.right": "ᠪᠠᠷᠠᠭᠤᠨᠰᠢ ",
    "gui.blocklyText.regEmotion8_16.lightAll": "ᠪᠥᠬᠥᠳᠡ ᠴᠡᠭᠯᠡᠭᠰᠡᠨ ᠵᠢᠭᠰᠠᠭᠠᠯ ᠤᠨ [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "ᠪᠠᠶᠢᠷᠢᠰᠢᠯX: [XAXIS]Y: [YXAXIS] [COLOR] ᠢᠯᠡᠷᠡᠨ᠎ᠡ ",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "ᠬᠣᠭᠣᠰᠣᠨ ᠴᠡᠭ ᠣᠨ ᠵᠢᠭᠰᠠᠭᠠᠯ ",
    "gui.mainHeader.err": "ᠠᠯᠳᠠᠭᠠ ᠶᠢᠨ ᠰᠠᠨᠠᠭᠤᠯᠭᠠ ᠄ ᠫᠷᠤᠭ᠍ᠷᠠᠮ  \" %1 \"  ᠶᠢ ᠵᠥᠪᠬᠡᠨ ᠨᠢᠭᠡ ᠯᠠ ᠤᠳᠠᠭᠠ ᠵᠡᠷᠭᠡ ᠪᠡᠷ ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠪᠣᠯᠤᠨᠠ ᠂ ᠳᠠᠪᠲᠠᠨ ᠲᠣᠳᠣᠷᠬᠠᠶᠢᠯᠠᠭᠰᠠᠨ ᠬᠡᠰᠡᠭ ᠢ ᠬᠠᠰᠤᠭᠠᠷᠠᠶ ᠃",
    "gui.mainHeader.help": "ᠬᠠᠪᠰᠤᠷᠤᠮᠵᠢ ᠄ ᠬᠡᠷᠪᠡ ᠣᠯᠠᠨ ᠫᠷᠤᠭ᠍ᠷᠠᠮ ᠢ ᠵᠡᠷᠭᠡ ᠪᠡᠷ ᠬᠡᠷᠡᠭᠵᠢᠭᠦᠯᠬᠦ ᠴᠢᠬᠤᠯᠠᠲᠠᠶ ᠪᠣᠯ ᠂ 《 ᠥᠷᠭᠡᠳᠬᠡᠯ 》 ᠳᠣᠲᠣᠷᠠᠬᠢ 《 ᠤᠯᠠᠨ ᠱᠤᠭᠤᠮ ᠤᠨ ᠴᠢᠳᠠᠪᠤᠷᠢ 》  ᠶᠢ ᠬᠡᠷᠡᠭᠯᠡᠭᠡᠷᠡᠶ ᠂ ᠨᠠᠷᠢᠨ ᠵᠦᠢᠯ ᠢ ᠬᠠᠪᠰᠤᠷᠬᠤ ᠪᠢᠴᠢᠭ ᠬᠠᠷᠠ ᠳᠤ ᠣᠶᠢᠵᠢᠨ᠃",
    "gui.threadHeader.err1": "ᠠᠯᠳᠠᠭ᠎ᠠ ᠶᠢᠨ ᠰᠠᠨᠠᠭᠤᠯᠭ᠎ᠠ ᠄ ᠫᠷᠤᠭ᠍ᠷᠠᠮ \" %1 \"  ᠶᠢ  ᠵᠡᠷᠭᠡ ᠳᠤ ᠨᠢ ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠪᠣᠯᠬᠤ ᠥᠬᠡᠶ ᠂ ᠲᠡᠳᠡᠭᠡᠷ ᠨᠢ ᠠᠳᠠᠯᠢ ᠪᠤᠰᠤ ᠢᠵᠢ ᠲᠣᠨᠣᠭ ᠪᠤᠶᠤ  ᠭᠤᠤᠯ ᠬᠠᠪᠲᠠᠰᠤᠨ ᠳᠤ ᠬᠠᠷᠢᠶᠠᠯᠠᠭᠳᠠᠨᠠ ᠃",
    "gui.threadHeader.err2": "ᠠᠯᠳᠠᠭᠠ ᠶᠢᠨ ᠰᠠᠨᠠᠭᠤᠯᠭᠠ ᠄ ᠬᠡᠷᠡᠭ \" %1 \"  ᠶᠢ ᠵᠥᠪᠬᠡᠨ ᠨᠢᠭᠡ ᠯᠠ ᠤᠳᠠᠭᠠ ᠵᠡᠷᠭᠡ ᠪᠡᠷ ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠪᠣᠯᠤᠨᠠ ᠂ ᠳᠠᠪᠲᠠᠨ ᠲᠣᠳᠣᠷᠬᠠᠶᠢᠯᠠᠭᠰᠠᠨ ᠬᠡᠰᠡᠭ ᠢ ᠬᠠᠰᠤᠭᠠᠷᠠᠶ ᠃",
    "gui.blocklyText.mqtt.whenReceivedData": "[TOPIC] ᠴᠢᠮᠡᠭᠡ ᠬᠦᠯᠢᠶᠡᠨ ᠠᠪᠬᠤ ᠦᠶᠡᠰ ᠠᠵᠢᠯᠯᠠᠬᠤ [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "MQTT ᠴᠢᠮᠡᠭᠡ",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi ᠬᠠᠯᠠᠮᠰᠢᠯ ᠴᠡᠭ : [ACCOUNT] ᠨᠢᠭᠤᠴᠠ ᠨᠣᠮᠧᠷ :[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "Wi-Fi  ᠵᠠᠯᠭᠠᠭᠳᠠᠪᠠ ᠤᠤ ︖ ",
    "gui.blocklyText.wifi.wifiDisConnected": "Wi-Fi ᠳᠠᠰᠤᠯᠬᠤ",
    "gui.blocklyText.wifi.wifiGetLocalIP": "Get Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "IP address",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi AP ᠬᠡᠯᠪᠡᠷᠢ ᠳᠦ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ :[ACCOUNT2] ᠨᠢᠭᠤᠴᠠ ᠨᠣᠮᠧᠷ:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi Ap ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢᠨ ᠲᠤᠰ ᠮᠠᠰᠢᠨ ᠤ IP",
    "gui.blocklyText.wifi.whenDebugInfo": "Wi-Fi get [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "Debug info",
    "gui.blocklyText.wifi.whenDebugInfoError": "Error code",
    "gui.blocklyText.wifi.ping": "Wi-Fi ping [HOST]",
    "gui.blocklyText.wifi.scan": "Wi-Fi 扫描",
    "gui.blocklyText.pythonobloq.wifiGetLocalIP": "[NAME] 获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "NTP设置网络时间, 时区 [TIMEZONE] 授时服务器 [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "获取开发板当前 [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "西12区",
    "gui.blocklyText.ntp.UTCWest11_t": "西11区",
    "gui.blocklyText.ntp.UTCWest10_t": "西10区",
    "gui.blocklyText.ntp.UTCWest9_t": "西9区",
    "gui.blocklyText.ntp.UTCWest8_t": "西8区",
    "gui.blocklyText.ntp.UTCWest7_t": "西7区",
    "gui.blocklyText.ntp.UTCWest6_t": "西6区",
    "gui.blocklyText.ntp.UTCWest5_t": "西5区",
    "gui.blocklyText.ntp.UTCWest4_t": "西4区",
    "gui.blocklyText.ntp.UTCWest3_t": "西3区",
    "gui.blocklyText.ntp.UTCWest2_t": "西2区",
    "gui.blocklyText.ntp.UTCWest1_t": "西1区",
    "gui.blocklyText.ntp.UTCMiddle_t": "中时区",
    "gui.blocklyText.ntp.UTCEast1_t": "东1区",
    "gui.blocklyText.ntp.UTCEast2_t": "东2区",
    "gui.blocklyText.ntp.UTCEast3_t": "东3区",
    "gui.blocklyText.ntp.UTCEast4_t": "东4区",
    "gui.blocklyText.ntp.UTCEast5_t": "东5区",
    "gui.blocklyText.ntp.UTCEast6_t": "东6区",
    "gui.blocklyText.ntp.UTCEast7_t": "东7区",
    "gui.blocklyText.ntp.UTCEast8_t": "东8区",
    "gui.blocklyText.ntp.UTCEast9_t": "东9区",
    "gui.blocklyText.ntp.UTCEast10_t": "东10区",
    "gui.blocklyText.ntp.UTCEast11_t": "东11区",
    "gui.blocklyText.ntp.UTCEast12_t": "东12区",
    "gui.blocklyText.ntp.year": "年",
    "gui.blocklyText.ntp.month": "月",
    "gui.blocklyText.ntp.date": "日",
    "gui.blocklyText.ntp.hour": "时",
    "gui.blocklyText.ntp.minute": "分",
    "gui.blocklyText.ntp.second": "秒",
    "gui.blocklyText.ntp.week": "周",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "UDP ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ [RMSGS] ᠢ ᠣᠯᠬᠤ ᠳᠤ",
    "gui.blocklyText.udp.udpSetServerPort": "UDP ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ ᠦᠨ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠢ [PORT] ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "gui.blocklyText.udp.udpServerSendBroadcast": "UDP ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ ᠴᠢᠮᠡᠭᠡ ᠳᠡᠮᠵᠢᠭᠤᠯᠬᠤ [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "UDP ᠬᠡᠷᠡᠭᠯᠡᠭᠴᠢ [RMSGC] ᠢ ᠬᠦᠯᠢᠶᠡᠨ ᠠᠪᠬᠤ ᠳᠤ",
    "gui.blocklyText.udp.udpSetClientIpPort": "UDP ᠬᠡᠷᠡᠭᠯᠡᠴᠢ ᠶᠢᠨ IP [IPADDR] ᠵᠠᠯᠭᠠᠭᠤᠷ [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "UPD ᠬᠡᠷᠡᠭᠯᠡᠭᠴᠢ ᠴᠢᠮᠡᠭᠡ ᠳᠠᠮᠵᠢᠭᠤᠯᠬᠤ[MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "ᠴᠢᠮᠡᠭᠡ",
    "gui.blocklyText.udp.ClientRecvedMsg": "ᠴᠢᠮᠡᠭᠡ",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] 中添加 key [KEY] value [VALUE]",
    "gui.blocklyText.http.requestHeader": "HTTP 请求体中添加 [TEXT]",
    "gui.blocklyText.http.getPost": "HTTP [GETPOST] 请求 URL [URL] 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.http.readMessage": "HTTP 读取 [LINE] 内容",
    "gui.blocklyText.http.param": "参数",
    "gui.blocklyText.http.header": "头部",
    "gui.blocklyText.http.oneline": "一行",
    "gui.blocklyText.http.allline": "全部",
    "gui.blocklyText.mqtt.mqttConnect": "ᠬᠥᠳᠡᠯᠭᠡᠬᠦ MQTT ᠵᠠᠯᠭᠠᠯᠲᠠ ",
    "gui.blocklyText.mqtt.mqttConnected": "MQTT ᠠᠮᠵᠢᠯᠲᠠᠲᠠᠶ  ᠬᠣᠯᠪᠤᠭᠳᠠᠭᠰᠠᠨ ᠤᠤ ︖ ",
    "gui.blocklyText.mqtt.acceptance.meaage": "[TOPIC] ᠴᠢᠮᠡᠭᠡ [MSG] ᠬᠦᠯᠢᠶᠡᠨ ᠠᠪᠬᠤ ᠳᠤ",
    "gui.blocklyText.mqtt.mqttSubscribe": "MQTT 订阅[TOPIC]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT ᠮᠡᠳᠡᠭᠡ ᠶᠠᠪᠤᠭᠤᠯᠬᠤ [MSG] ᠡᠴᠡ [TOPIC] ",
    "gui.blocklyText.mqtt.mqttSendSave": "MQTT 发送消息[MSG]至[TOPIC]并存数据库 (SIoT V2)",
    "gui.blocklyText.mqtt.acceptance.topic.meaage": "当接收到[TOPIC]的消息为[MSG]",
    "gui.blocklyText.mqtt.whenReceivedTopicData": "当接收到[TOPIC]的[MSG]",
    "gui.blocklyText.mqtt.mqttSendToTopic": "MQTT 发送消息[MSG]至[TOPIC]",
    "gui.blocklyText.mqtt.mqttSendToTopicSave": "MQTT 发送消息[MSG]至[TOPIC]并存数据库 (SIoT V2)",
    "gui.blocklyText.mqtt.initial": "MQTT ᠫᠠᠷᠠᠮᠧᠲ᠋ᠷ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ [SETTING]",
    "gui.blocklyText.iflytek.audioSynthesis": "「讯飞语音」合成音频 [SETTING]",
    "gui.blocklyText.iflytek.audioIdentify": "「讯飞语音」识别音频 [SETTING]",
    "gui.blocklyText.iflytek.identifyResult": "「讯飞语音」识别结果",
    "gui.blocklyText.iflytek.CommunicationText": "文字内容",
    "gui.blocklyText.iflytek.CommunicationTransfer": "转存为音频文件",
    "gui.blocklyText.iflytek.CommunicationIdentigied": "待识别音频文件",
    "gui.blocklyText.mqtt.mqttDisconnect": "MQTT ᠬᠣᠯᠪᠤᠭᠠᠰᠤ ᠳᠠᠰᠤᠯᠬᠤ",
    "gui.blocklyText.internet.internetGetpostInit": "网络请求初始化 [SETTING]",
    "gui.blocklyText.internet.initTypeAddr": "网络请求 设置请求类型 [POST] 地址 [ADDR] headers：[HEADERS] params：[URL] data：[DICT]",
    "gui.blocklyText.internet.internetPostFile": "Post提交文件 [SETTING]",
    "gui.blocklyText.internet.postFileAddrType": "POST提交文件 设置参数 网址 [ADDR] 文件路径 [PATH] MIME类型 [TYPE]",
    "gui.blocklyText.internet.postFileDict": "POST提交文件 添加字段为字典 [DICT] 并提交文件",
    "gui.blocklyText.internet.responseContent": "响应内容 形式[DATATYPE]",
    "gui.blocklyText.internet.responseAttributes": "响应属性 [ATTRIBUTES]",
    "gui.blocklyText.internet.createSocket": "创建 socket [SOKHANDEL] 地址 [AFINET] 类型 [SOCKTYPE]",
    "gui.blocklyText.internet.setSocket": "创建 socket [SOKHANDEL] 地址 [SOCKTYPE] 类型 [TYPENUM]",
    "gui.blocklyText.internet.closeSocket": "socket [SOKHANDEL] 关闭",
    "gui.blocklyText.internet.bindSocket": "socket [SOKHANDEL] 绑定地址 [SOCKADDR] 端口 [SOCKPORT]",
    "gui.blocklyText.internet.listenSocket": "监听 socket [SOKHANDEL] backlog 个数 [BACKLOGNUM]",
    "gui.blocklyText.internet.recvReturnTuple": "socket [SOKHANDEL] 接收链接请求并返回socket元组",
    "gui.blocklyText.internet.parseReturnIp": "解析 host [HOSTADDR] 端口 [SOCKPORT] 并返回 ip",
    "gui.blocklyText.internet.connectHost": "socket [SOKHANDEL] 连接 host [HOSTADDR] 端口 [SOCKPORT]",
    "gui.blocklyText.internet.sendReturnBytes": "socket [SOKHANDEL] 发送 [SOCKMSG] 并返回发送的字节数",
    "gui.blocklyText.internet.continueSendReturnBytes": "socket [SOKHANDEL] 连续发送 [SOCKMSG] 并返回发送的字节数",
    "gui.blocklyText.internet.sendToAddr": "socket [SOKHANDEL] 发送 [SOCKMSG] 到地址 [SOCKADDR]",
    "gui.blocklyText.internet.recvReturnRecvedObject": "socket [SOKHANDEL] 接收并返回接收到的数据对象 最大数据量为 [MAXNUM]",
    "gui.blocklyText.internet.recvReturnRecvedTuple": "socket [SOKHANDEL] 接收并返回接收到的数据元组 最大数据量为 [MAXNUM]",
    "gui.blocklyText.internet.setTimeout": "socket [SOKHANDEL] 设置超时时间为 [TIMEOUT] 秒",
    "gui.blocklyText.internet.setMode": "设置 socket [SOKHANDEL] 为 [SOCKMODE] 模式",
    "gui.blocklyText.internet.readReturnObject": "从 socket [SOKHANDEL] 读取 [READBYTE] 字节并返回字节对象",
    "gui.blocklyText.internet.text": "文本",
    "gui.blocklyText.internet.binary": "二进制",
    "gui.blocklyText.internet.dictionary": "字典",
    "gui.blocklyText.internet.block": "阻塞",
    "gui.blocklyText.internet.nonblock": "非阻塞",
    "gui.blocklyText.internet.initRequestsType": "请求类型",
    "gui.blocklyText.internet.initRequestsAddr": "请求地址",
    "gui.blocklyText.internet.initAddDict": "初始化字典",
    "gui.blocklyText.internet.postFileAddr": "请求网址",
    "gui.blocklyText.internet.postFilePath": "文件路径",
    "gui.blocklyText.internet.postFileMime": "MIME类型",
    "gui.blocklyText.internet.postFileAddDict": "初始化字典",
    "gui.blocklyText.sen0251.bmp388Init": "BMP388 初始化",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 ᠥᠨᠳᠦᠷᠴᠡ ᠶᠢ ᠬᠡᠮᠵᠢᠬᠦ(m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 ᠠᠭᠠᠷ ᠮᠠᠨᠳᠠᠯ ᠤᠨ ᠳᠠᠷᠤᠴᠠ ᠶᠢ ᠬᠡᠮᠵᠢᠬᠦ(Pa)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 ᠳᠤᠯᠠᠭᠠᠴᠠ ᠶᠢ ᠬᠡᠮᠵᠢᠬᠦ(℃)",
    "gui.blocklyText.sen0206.init": "初始化MLX90614地址为 [VALUE]",
    "gui.blocklyText.sen0206.sen0206Measuring": "MLX90614 ᠬᠡᠮᠵᠢᠯᠲᠡ [TYPE] [UINT]",
    "gui.blocklyText.sen0206.setEmissivityCorrectionCoefficient": "设置发射率校准系数 [VALUE]((范围0-1))",
    "gui.blocklyText.sen0206.setI2CAddress": "更改MLX90614地址为 [VALUE]",
    "gui.blocklyText.sen0206.object": "ᠪᠡᠶᠡᠲᠦ",
    "gui.blocklyText.sen0206.environment": "ᠣᠷᠴᠢᠨ ᠲᠣᠭᠤᠷᠢᠨ",
    "gui.blocklyText.sen0206.celsius": "ᠼᠧᠯᠢᠰ ᠭᠷᠠᠲᠦ᠋ᠰ",
    "gui.blocklyText.sen0206.fahrenheit": "ᠹᠠᠷᠤᠨ᠋ᠾᠤᠲ ᠭᠷᠠᠳᠦ᠋ᠰ",
    "gui.blocklyText.sen0253.init": " 10D ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ 'I2C_ADDR' ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠥᠪᠰᠢᠨ [LEVEL]",
    "gui.blocklyText.sen0253.reset": " 10DOF ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ ᠢ ᠪᠤᠴᠠᠭᠠᠬᠤ",
    "gui.blocklyText.sen0253.power": " 10DOF ᠴᠠᠬᠢᠯᠭᠠᠨ ᠡᠭᠦᠰᠭᠡᠭᠴᠢ ᠶᠢᠨ ᠬᠡᠯᠪᠡᠷᠢ [MODEL]",
    "gui.blocklyText.sen0253.getEuler": " 10DOF ᠡᠸᠯᠧᠷ ᠥᠨᠴᠦᠭ ᠢ ᠣᠯᠬᠤ [EULER]",
    "gui.blocklyText.sen0253.getQua": " 10DOF ᠳᠥᠷᠪᠡᠨ ᠡᠯᠧᠮᠧᠨ᠋ᠲ ᠢ ᠣᠯᠬᠤ [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ",
    "gui.blocklyText.sen0253.getAcc": " 10DOF ᠬᠤᠷᠳᠤᠳᠭᠠᠯ ᠢᠣᠯᠬᠤ [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ",
    "gui.blocklyText.sen0253.getMag": " 10DOF ᠰᠣᠷᠢᠨᠵᠢᠨ ᠬᠡᠮᠵᠢᠭᠦᠷ ᠢ ᠣᠯᠬᠤ [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ",
    "gui.blocklyText.sen0253.getLinearAcc": " 10DOF ᠱᠤᠭᠤᠮᠠᠨ ᠬᠤᠷᠳᠤᠳᠭᠠᠯ ᠢ ᠣᠯᠬᠤ [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ",
    "gui.blocklyText.sen0253.getGravitCapacity": " 10DOF ᠬᠦᠨᠳᠦ ᠶᠢᠨ ᠬᠦᠴᠦᠨ ᠦ ᠬᠤᠷᠳᠤᠳᠭᠠᠯ ᠢ ᠣᠯᠬᠤ [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ",
    "gui.blocklyText.sen0253.getAngularSpeed": " 10DOF ᠥᠨᠴᠦᠭ ᠦᠨ ᠬᠤᠷᠳᠤᠴᠠ ᠶᠢ ᠣᠯᠬᠤ [AXIS] ᠲᠡᠩᠭᠡᠯᠢᠭ",
    "gui.blocklyText.sen0253.getBmp280": " 10DOF  [PARAMETER] ᠢ ᠣᠯᠬᠤ",
    "gui.blocklyText.sen0253.LOW": "ᠳᠣᠣᠷ᠎ᠠ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠣᠪᠰᠢᠨ",
    "gui.blocklyText.sen0253.HIGH": "ᠳᠡᠭᠡᠷ᠎ᠡ ᠴᠠᠬᠢᠯᠭᠠᠨ ᠲᠥᠪᠰᠢᠨ",
    "gui.blocklyText.sen0253.normal": "ᠡᠩ ᠦᠨ",
    "gui.blocklyText.sen0253.lowPower": "ᠳᠣᠣᠷ᠎ᠠ ᠴᠢᠳᠠᠯ",
    "gui.blocklyText.sen0253.suspend": "ᠡᠯᠭᠦᠬᠦ",
    "gui.blocklyText.sen0253.Roll": "ᠬᠥᠨᠳᠡᠯᠡᠨ ᠥᠩᠬᠦᠷᠢᠬᠦ ᠥᠨᠴᠦᠭ",
    "gui.blocklyText.sen0253.Pitch": "俯仰角",
    "gui.blocklyText.sen0253.Yaw": "航偏角",
    "gui.blocklyText.sen0253.temperature": "ᠳᠤᠯᠠᠭᠠᠴᠠ(℃)",
    "gui.blocklyText.sen0253.elevation": "ᠳᠠᠯᠠᠢ ᠶᠢᠨ ᠡᠷᠭᠦᠴᠡ(m)",
    "gui.blocklyText.sen0253.airPressure": "ᠠᠭᠠᠷ ᠮᠠᠨᠳᠠᠯ ᠤᠨ ᠳᠠᠷᠤᠯᠲᠠ(Pa)",
    "gui.blocklyText.sen0290.init": "SEN0290 ᠨᠣᠴᠤᠭᠠᠬᠤ ᠨᠥᠯᠦᠭᠡᠯᠡᠭᠦᠷ ᠢ ᠲᠠᠰᠤᠯᠬᠤ[IRQPIN] i2c ᠬᠠᠶᠢᠭ [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "SEN0290 ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ I2C",
    "gui.blocklyText.sen0290.manualCal": "SEN0290 ᠴᠠᠬᠢᠯᠭᠠᠨ ᠪᠠᠭᠲᠠᠭᠠᠮᠵᠢ [CAPACITANCE] ᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ᠂ ᠬᠡᠯᠪᠡᠷᠢ [MODE] ᠬᠠᠷᠰᠢᠯᠠᠯ [DIST]",
    "gui.blocklyText.sen0290.indoors": "ᠭᠡᠷ ᠲᠡᠬᠢ",
    "gui.blocklyText.sen0290.outdoors": "ᠭᠡᠷ ᠦᠨ ᠭᠠᠳᠠᠨ᠎ᠠ",
    "gui.blocklyText.sen0290.disturberDis": "ᠬᠠᠷᠰᠢᠯᠠᠯ ᠢ ᠵᠣᠭᠰᠤᠭᠠᠬᠤ",
    "gui.blocklyText.sen0290.disturberEn": "ᠬᠠᠷᠰᠢᠯᠠᠬᠤ",
    "gui.blocklyText.sen0290.errMsg": "ᠪᠤᠷᠤᠭᠤ ᠴᠢᠮᠡᠭᠡ",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "SEN0290 ᠪᠠᠢᠴᠠᠭᠠᠯᠲᠠ ᠲᠠᠰᠤᠯᠲᠠᠪᠠ",
    "gui.blocklyText.sen0290.GetLightningDistKm": "SEN0290ᠭᠢᠪᠡᠭᠡᠨ ᠦ ᠵᠠᠢ ᠶᠢ ᠣᠯᠬᠤ",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "SEN0290ᠭᠢᠯᠪᠡᠭᠡᠨ ᠦ ᠡᠨᠧᠷᠭᠢ ᠶᠢᠨ ᠬᠦᠴᠦᠯᠡᠴᠡ ᠶᠢ ᠣᠯᠬᠤ",
    "gui.blocklyText.sen0291.sen0291Init": "功率计 初始化I2C地址为 [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "功率计 读取负载 [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "功率计校准 实际测量电流为 [EXT] mA",
    "gui.blocklyText.pythonsen0291.sen0291Init": "功率计[NAME] 初始化I2C地址为 [IICADDR]",
    "gui.blocklyText.pythonsen0291.sen0291Read": "功率计[NAME] 读取负载 [LOAD]",
    "gui.blocklyText.pythonsen0291.sen0291Calibration": "功率计[NAME] 校准实际测量电流为 [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "电压 (mV)",
    "gui.blocklyText.sen0291.electric": "电流 (mA)",
    "gui.blocklyText.sen0291.power": "功率 (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "分流电压 (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.weather.weatherGetWithCity": "ᠮᠣᠵᠢ [COUNTRY] ᠬᠣᠲᠠ [CITY] ᠶᠢᠨ [WEATHER]",
    "gui.blocklyText.weather.temperaturHigh": "ᠬᠠᠮᠤᠭ ᠤᠨ ᠥᠨᠳᠥᠷ ᠳᠤᠯᠠᠭᠠᠴᠠ(℃)",
    "gui.blocklyText.weather.temperaturLow": "ᠬᠠᠮᠤᠭ ᠤᠨ ᠳᠣᠣᠷ᠎ᠠ ᠳᠤᠯᠠᠭᠠᠴᠠ(℃)",
    "gui.blocklyText.weather.weather": "ᠴᠠᠭ ᠠᠭᠤᠷ",
    "gui.blocklyText.weather.weatherInit": "天气服务器初始化 [SETTING]",
    "gui.blocklyText.weather.serverAddr": "服务器地址",
    "gui.blocklyText.weather.appid": "账户ID(appid)",
    "gui.blocklyText.weather.appsecret": "账户密钥(appsecret)",
    "gui.blocklyText.weather.serverAddrTianqiAPI": "天气API网(www.tianqiapi.com)",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ ᠦᠨ ᠫᠠᠷᠠᠮᠧᠲ᠋ᠧᠷ ᠢ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "ᠰᠢᠨᠡᠳᠭᠡᠬᠦ(update)ᠱᠤᠰᠢᠭ᠎ᠠ(tag) [TAG] ᠬᠡᠮᠵᠢᠭᠳᠡᠯ(value) [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "ᠤᠩᠰᠢᠬᠤ(get)ᠱᠤᠰᠢᠭ᠎ᠠ(tag) [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "ᠤᠰᠠᠳᠭᠠᠬᠤ(delete)ᠱᠤᠰᠢᠭ᠎ᠠ(tag) [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "ᠲᠣᠭᠠᠯᠠᠬᠤ(count)",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "ᠰᠦᠯᠵᠢᠶ᠎ᠡ ᠬᠣᠯᠪᠤᠭᠳᠠᠭᠰᠠᠨ ᠡᠰᠡᠬᠦ ᠶᠢ ᠪᠠᠢᠴᠠᠭᠠᠨ᠎ᠠ",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "ᠬᠠᠢᠬᠤ(search) ᠡᠬᠢᠯᠡᠯᠲᠡ ᠶᠢᠨ ᠨᠣᠮᠧᠷ(no) [NUMS] ᠬᠣᠪᠢᠰᠬᠤᠨ ᠤ ᠲᠣᠭ᠎ᠠ(count) [NUMV] ᠱᠤᠰᠢᠭ᠎ᠠ(tag) [TAG] ᠬᠡᠪ(type) [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "tag",
    "gui.blocklyText.tinywebdb.value": "value",
    "gui.blocklyText.tinywebdb.both": "both",
    "gui.blocklyText.weather.anhui": "安徽",
    "gui.blocklyText.weather.aomen": "澳门",
    "gui.blocklyText.weather.beijing": "北京",
    "gui.blocklyText.weather.chongqing": "重庆",
    "gui.blocklyText.weather.fujian": "福建",
    "gui.blocklyText.weather.gansu": "甘肃",
    "gui.blocklyText.weather.guangdong": "广东",
    "gui.blocklyText.weather.guangxi": "广西",
    "gui.blocklyText.weather.guizhou": "贵州",
    "gui.blocklyText.weather.hainan": "海南",
    "gui.blocklyText.weather.hebei": "河北",
    "gui.blocklyText.weather.henan": "河南",
    "gui.blocklyText.weather.heilongjiang": "黑龙江",
    "gui.blocklyText.weather.hubei": "湖北",
    "gui.blocklyText.weather.hunan": "湖南",
    "gui.blocklyText.weather.jiangxi": "江西",
    "gui.blocklyText.weather.jiangsu": "江苏",
    "gui.blocklyText.weather.jilin": "吉林",
    "gui.blocklyText.weather.liaoning": "辽宁",
    "gui.blocklyText.weather.neimenggu": "内蒙古",
    "gui.blocklyText.weather.ningxia": "宁夏",
    "gui.blocklyText.weather.qinghai": "青海",
    "gui.blocklyText.weather.sichuan": "四川",
    "gui.blocklyText.weather.shandong": "山东",
    "gui.blocklyText.weather.shanxi": "山西",
    "gui.blocklyText.weather.shan3xi": "陕西",
    "gui.blocklyText.weather.shanghai": "上海",
    "gui.blocklyText.weather.tianjin": "天津",
    "gui.blocklyText.weather.taiwan": "台湾",
    "gui.blocklyText.weather.xinjiang": "新疆",
    "gui.blocklyText.weather.xizang": "西藏",
    "gui.blocklyText.weather.xianggang": "香港",
    "gui.blocklyText.weather.yunnan": "云南",
    "gui.blocklyText.weather.zhejiang": "浙江",
    "speech.whenIHear": "[PHRASE] ᠶᠢ ᠣᠯᠵᠤ ᠰᠣᠨᠤᠰᠬᠤ ᠳᠤ",
    "speech.listenAndWait": "ᠠᠪᠢᠶ᠎ᠠ ᠪᠠᠷ ᠣᠷᠤᠭᠤᠯᠬᠤ ᠶᠢ ᠰᠣᠨᠤᠰᠬᠤ",
    "speech.getSpeech": "ᠲᠠᠨᠢᠨ ᠮᠡᠳᠡᠷᠡᠭᠰᠡᠨ ᠦᠷ᠎ᠡ ᠳ᠋ᠦᠩ",
    "speech.setRecordTime": "ᠤᠳᠠᠭ᠎ᠠ ᠪᠦᠷᠢ [TIME] ᠰᠧᠺᠦ᠋ᠨ᠋ᠲ ᠰᠣᠨᠤᠰᠤᠨ᠎ᠠ",
    "speech.stopRecord": "ᠠᠪᠢᠶ᠎ᠠ ᠪᠠᠷ ᠲᠠᠨᠢᠨ ᠮᠡᠳᠡᠷᠡᠬᠦ ᠶᠢ ᠵᠣᠭᠰᠤᠭᠠᠬᠤ",
    "speech.controlSonogram": "[CONTROL] ᠳᠠᠭᠤᠨ ᠳᠣᠯᠭᠢᠶᠠᠨ ᠤ ᠵᠢᠷᠤᠭ",
    "speech.setServer": "ᠠᠪᠢᠶ᠎ᠠ ᠮᠡᠳᠡᠷᠡᠬᠦ ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ ᠢ [SERVER] ᠪᠣᠯᠭᠠᠬᠤ",
    "speech.defaultWhenIHearValue": "ᠡᠬᠢᠯᠡᠬᠦ",
    "speech.show": "ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ",
    "speech.hide": "ᠳᠠᠯᠳᠠᠯᠠᠬᠤ",
    "speech.server1": "ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ1",
    "speech.server2": "ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ2",
    "gui.blocklyText.mp3.initMp3": "DFPlayer MP3 ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ ᠵᠠᠯᠭᠠᠭᠤᠷ [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "DFPlayer MP3ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ ᠳᠠᠭᠤ ᠶᠢ [VOLUME]% ᠪᠣᠯᠭᠠᠬᠤ",
    "gui.blocklyText.mp3.setMP3PlayMode": "DFPlayer MP3 ᠬᠡᠰᠡᠭᠲᠦ ᠶᠢᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢ [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "DFPlayer MP3 ᠬᠡᠰᠡᠭᠲᠦ [NUM] ᠳ᠋ᠤᠭᠠᠷ ᠳᠠᠭᠤᠤ ᠶᠢ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ DFPlayer MP3 ᠬᠡᠰᠡᠭᠲᠦ ᠦᠨ ᠳᠠᠭᠤᠨ ᠤ ᠬᠡᠮᠵᠢᠶ᠎ᠡ ᠨᠢ [VOLUME] ",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ DFPlayer MP3 ᠬᠡᠰᠡᠭᠲᠦ ᠦᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠨᠢ [MODE] ",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "[PIN]  ᠵᠠᠯᠭᠠᠳᠠᠰᠤ DFPlayer MP3 ᠬᠡᠰᠡᠭᠲᠦ [NUM] ᠠᠩᠬᠠᠨ ᠤ ᠳᠠᠭᠤᠤ ᠶᠢ ᠲᠠᠯᠪᠢᠨᠠ ",
    "gui.blocklyText.tel0094.GPSInit": "GPS 初始化为 [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS 获取地理位置 [LATLON](°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "GPS 获取卫星时间 [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "经度",
    "gui.blocklyText.tel0094.LONGITUDE": "纬度",
    "gui.blocklyText.tel0094.YEAR": "年",
    "gui.blocklyText.tel0094.MONTH": "月",
    "gui.blocklyText.tel0094.DAY": "日",
    "gui.blocklyText.tel0094.HOUR": "时",
    "gui.blocklyText.tel0094.MINUTE": "分",
    "gui.blocklyText.tel0094.SECOND": "秒",
    "gui.blocklyText.tel0094.ALL": "全部",
    "gui.blocklyText.tel0094.GPSGet": "GPS 获取 [GPS]",
    "gui.blocklyText.tel0094.getState": "状态",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "纬度半球",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "经度半球",
    "gui.blocklyText.tel0094.getGroundSpeed": "地面速度",
    "gui.blocklyText.tel0094.getSatellitesCount": "卫星数量",
    "gui.blocklyText.tel0094.getAltitude": "海拔",
    "gui.blocklyText.tel0094.getPdop": "位置精度",
    "gui.blocklyText.tel0094.getHdop": "水平精度",
    "gui.blocklyText.tel0094.getVdop": "垂直精度",
    "gui.blocklyText.tel0094.getPositioningMode": "定位模式",
    "gui.blocklyText.microIoT.displayInLine": "OLED 显示文字 [TEXT] 在第 [LINE] 行",
    "gui.blocklyText.microIoT.displayInXY": "OLED 显示文字 [TEXT] 在坐标x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED 显示图片 [IMAGE] 在坐标x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED 清屏",
    "gui.blocklyText.microIoT.pointDrawing": "OLED 画点x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED 设置线宽为 [LINEWIDTH] 个像素",
    "gui.blocklyText.microIoT.lineDrawing": "OLED 画线 起点x [X1] y [Y1] 终点x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED 画圆 [FILL] 圆心x [X] y [Y] 半径 [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED 画框 [FILL] 起点x [X] y [Y] 宽 [WIDTH] 高 [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB灯 设置亮度为 [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "RGB灯 全部熄灭",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "RGB灯 灯号 [NUM1] 到 [NUM2] 显示颜色 [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "RGB灯 灯号 [NUM1] 到 [NUM2] 显示渐变色 色调从 [COLOR1] 到 [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "RGB灯 循环移动 [NUM] 个像素",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "RGB灯 灯号 [NUM1] 到 [NUM2] 显示颜色 [COLOR]",
    "gui.blocklyText.motorIot.all": "全部",
    "gui.blocklyText.microIot.servo": "舵机 [INDEX] 角度 [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "MQTT 初始化参数 [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT 发送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.microIot.mqttSendmsgSave": "MQTT 发送消息 [MSG] 至 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.microIot.MQTTMsg": "MQTT消息",
    "gui.blocklyText.microIoTCloud.MotorRun": "电机以 [SPEED] 速度 [DIR]",
    "gui.blocklyText.microIoTCloud.MotorStop": "电机停止",
    "gui.blocklyText.microIoTCloud.setTime": "设置年 [Y] 月 [MON] 日 [D] 时 [H] 分 [MIN] 秒 [S]",
    "gui.blocklyText.microIoTCloud.setYear": "设置年 [Y]",
    "gui.blocklyText.microIoTCloud.setMonth": "设置月 [MON]",
    "gui.blocklyText.microIoTCloud.setDay": "设置日 [D]",
    "gui.blocklyText.microIoTCloud.setHour": "设置时 [H]",
    "gui.blocklyText.microIoTCloud.setMinute": "设置分 [MIN]",
    "gui.blocklyText.microIoTCloud.setSecond": "设置秒 [S]",
    "gui.blocklyText.microIoTCloud.getTime": "获取 [TIME]",
    "gui.blocklyText.microIoTCloud.sendmsgSaveToCloudPlatform": "发送消息 [MSG] 到 [TOPIC] 并存数据库(SIoT V2)",
    "gui.blocklyText.microTouch.whenBtnPress": "当按键 [KEY] 被按下时",
    "gui.blocklyText.microTouch.Any": "任意",
    "gui.blocklyText.microTouch.isButtonPressed": "按键 [KEY] 被按下?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "普通模式读取按键",
    "gui.blocklyText.microTouch.readKeyMathMode": "数学模式读取 [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "数字按键(num)",
    "gui.blocklyText.microTouch.Function": "功能按键(str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] 震动电机",
    "gui.blocklyText.microTouch.turnOn": "打开",
    "gui.blocklyText.microTouch.shutDown": "关闭",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "声音强度",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "自然光线",
    "gui.blocklyText.microNaturalScience.readColour": "读取 [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "红色值",
    "gui.blocklyText.microNaturalScience.G": "绿色值",
    "gui.blocklyText.microNaturalScience.B": "蓝色值",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "紫外线强度(μW/cm2)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "打开",
    "gui.blocklyText.microNaturalScience.off": "关闭",
    "gui.blocklyText.microNaturalScience.setTDSK": "设置TDS电极常数k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "获取TDS电极常数k",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS(ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "获取 [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "水温(℃)",
    "gui.blocklyText.microNaturalScience.Temperature": "环境温度(℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "环境湿度(%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "海拔高度(m)",
    "gui.blocklyText.microNaturalScience.Pressure": "大气压强(Pa)",
    "gui.blocklyText.microNaturalScienceV2.OLED": "OLED",
    "gui.blocklyText.microNaturalScienceV2.Motor": "Motor",
    "gui.blocklyText.microNaturalScienceV2.RGB": "RGB",
    "gui.blocklyText.microNaturalScienceV2.IoT": "IoT",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB灯 设置矩阵点 X [X] Y [Y] 显示颜色 [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht22ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ dht22 ᠶᠢ ᠪᠣᠷᠤᠭᠤ ᠤᠩᠰᠢᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht11ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ dht12 ᠶᠢ ᠪᠣᠷᠤᠭᠤ ᠤᠩᠰᠢᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ PM2.5 ᠶᠢ ᠪᠣᠷᠤᠭᠤ ᠤᠩᠰᠢᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 ᠳ᠋ᠠᠢᠲ᠋ᠠ ᠤᠩᠰᠢᠵᠤ ᠪᠠᠢᠨ᠎ᠠ᠂ led ᠴᠡᠭᠲᠦ ᠵᠢᠭᠰᠠᠭᠠᠯ ᠡᠬᠢᠵᠢᠭᠦᠯᠬᠦ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.6 ᠳ᠋ᠠᠢᠲ᠋ᠠ ᠤᠩᠰᠢᠵᠤ ᠪᠠᠢᠨ᠎ᠠ᠂ led ᠴᠡᠭᠲᠦ ᠵᠢᠭᠰᠠᠭᠠᠯ ᠳᠦᠷᠰᠦ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "mp3 ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "mp3 ᠳᠠᠭᠤᠨ ᠤ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "mp3 ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "mp3 ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠶᠢᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3 ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3 ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3 ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ ᠶᠢᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3 ᠳᠠᠭᠤᠨ ᠤ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "obloq ᠶᠢᠨ mqtt ᠶᠢᠨ ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠶᠢ ᠲᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ  ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "obloq ᠶᠢᠨ ᠴᠢᠮᠡᠭᠡ ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ ᠶᠢ ᠲᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ  ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠴᠢᠮᠡᠭᠡ ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "obloq ᠶᠢᠨ mqtt ᠶᠢᠨ ᠴᠢᠮᠡᠭᠡ ᠤᠩᠰᠢᠬᠤ ᠶᠢ ᠲᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ  ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠴᠢᠮᠡᠭᠡ ᠤᠩᠰᠢᠬᠤ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "obloq ᠶᠢᠨ http ᠶᠢᠨ ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠶᠢ ᠲᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ  ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "obloq http ᠶᠢᠨ get ᠭᠣᠶᠤᠴᠢᠯᠠᠯ ᠢ ᠲᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠭᠤᠶᠤᠴᠢᠯᠠᠯ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "obloq http ᠶᠢᠨ post ᠭᠣᠶᠤᠴᠢᠯᠠᠯ ᠢ ᠲᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠭᠤᠶᠤᠴᠢᠯᠠᠯ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "obloq http ᠶᠢᠨ put ᠭᠣᠶᠤᠴᠢᠯᠠᠯ ᠢ ᠲᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠭᠤᠶᠤᠴᠢᠯᠠᠯ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "ᠤᠯᠠᠭᠠᠨ ᠤ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠬᠦᠯᠢᠶᠡᠯᠲᠡ ᠶᠢ ᠲᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠬᠦᠯᠢᠶᠡᠯᠲᠡ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "ᠤᠯᠠᠭᠠᠨ ᠤ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠬᠦᠯᠢᠶᠡᠯᠲᠡ᠂ led ᠴᠡᠭᠲᠦ ᠵᠢᠭᠰᠠᠯ ᠤᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "ᠤᠯᠠᠭᠠᠨ ᠤ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠬᠦᠯᠢᠶᠡᠯᠲᠡ᠂ led ᠴᠡᠭᠲᠦ ᠵᠢᠭᠰᠠᠯ ᠤᠨ ᠳᠦᠷᠰᠦ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "ᠤᠯᠠᠭᠠᠨ ᠤ ᠭᠠᠳᠠᠨᠠᠬᠢ ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ ᠶᠢ ᠲᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠪᠠᠷ ᠤᠨᠲᠠᠷᠠᠭᠠᠬᠤ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠥᠩᠭᠡ ᠶᠢᠨ ᠢᠯᠡᠷᠡᠭᠦᠯᠦᠯᠲᠡ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠥᠩᠭᠡ ᠶᠢᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠣᠷᠴᠢᠯ ᠬᠣᠳᠡᠯᠭᠡᠭᠡᠨ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "RGB ᠳ᠋ᠧᠩ ᠦᠨ ᠬᠡᠯᠬᠢᠶ᠎ᠡ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠪᠠᠭᠠᠨ᠎ᠠ ᠶᠢᠨ ᠬᠡᠯᠪᠡᠷᠢᠲᠦ ᠵᠢᠷᠤᠭ ᠤᠨ ᠢᠯᠡᠷᠡᠭᠦᠯᠦᠯᠲᠡ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "led ᠴᠡᠭᠲᠦ ᠵᠢᠭᠰᠠᠯ ᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "led ᠴᠡᠭᠲᠦ ᠵᠢᠭᠰᠠᠯ ᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠵᠢᠷᠤᠭ ᠢᠯᠡᠷᠡᠭᠦᠯᠦᠯᠲᠡ ᠢᠯᠠᠭᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠭᠠᠷᠭᠠᠯᠲᠠ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠭᠠᠷᠭᠠᠯᠲᠠ ᠪᠤᠷᠤᠭᠤᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠣᠷᠤᠭᠤᠯᠤᠯᠲᠠ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠣᠷᠤᠭᠤᠯᠤᠯᠲᠠ ᠪᠤᠷᠤᠭᠤᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠭᠠᠷᠭᠠᠯᠲᠠ ᠪᠠ ᠣᠷᠤᠭᠤᠯᠤᠯᠲᠠ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠭᠠᠷᠭᠠᠯᠲᠠ ᠪᠤᠷᠤᠭᠤᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠪᠦᠬᠦᠯᠢ ᠲᠣᠭ᠎ᠠ ᠤᠩᠰᠢᠬᠤ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠪᠤᠷᠤᠭᠤᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠭᠠᠷᠭᠠᠯᠲᠠ ᠶᠢ ᠳᠠᠰᠤᠯᠳᠠᠭᠰᠠᠨ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠳᠤ ᠪᠠᠢᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ᠂ ᠭᠠᠷᠭᠠᠯᠲᠠ ᠪᠤᠷᠤᠭᠤᠳᠠᠵᠤ ᠮᠡᠳᠡᠨ᠎ᠡ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "dht11 ᠪᠠ PM2.5 ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ ᠬᠡᠷᠪᠡ pm2.5 ᠪᠠᠢᠩᠭᠤ ᠤᠩᠰᠢᠵᠤ ᠪᠠᠢᠪᠠᠯ᠂ dht11 ᠢ ᠤᠩᠰᠢᠵᠤ ᠳᠡᠢᠯᠬᠦ ᠦᠭᠡᠢ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ᠂ pm2.5 ᠢ ᠤᠩᠰᠢᠬᠤ ᠲᠣᠭ᠎ᠠ ᠶᠢ ᠪᠠᠭᠠᠰᠭᠠᠬᠤ ᠬᠡᠷᠡᠭᠲᠡᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "dht11 ᠪᠠ ds18b20 ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ ᠬᠡᠷᠪᠡ ds18b20 ᠪᠠᠢᠩᠭᠤ ᠤᠩᠰᠢᠵᠤ ᠪᠠᠢᠪᠠᠯ᠂ dht11 ᠢ ᠤᠩᠰᠢᠵᠤ ᠳᠡᠢᠯᠬᠦ ᠦᠭᠡᠢ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ᠂ ds18b20 ᠢ ᠤᠩᠰᠢᠬᠤ ᠲᠣᠭ᠎ᠠ ᠶᠢ ᠪᠠᠭᠠᠰᠭᠠᠬᠤ ᠬᠡᠷᠡᠭᠲᠡᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "dht11 ᠪᠠ mp3  ᠶᠢᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ mp3 ᠶᠢᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "dht11 ᠪᠠ mp3  ᠶᠢᠨ ᠳᠠᠭᠤᠨ ᠤ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ mp3 ᠶᠢᠨ ᠳᠠᠭᠤᠨ ᠤ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "dht11 ᠪᠠ mp3  ᠶᠢᠨ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ mp3 ᠶᠢᠨ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "dht11 ᠪᠠ mp3  ᠶᠢᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠭᠡ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ mp3 ᠶᠢᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠭᠡ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "dht11 ᠪᠠ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3  ᠶᠢᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3 ᠶᠢᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "dht11 ᠪᠠ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3  ᠶᠢᠨ ᠡᠵᠡᠮᠰᠢᠯ ᠬᠡᠰᠡᠭᠲᠦ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3  ᠶᠢᠨ ᠡᠵᠡᠮᠰᠢᠯ ᠬᠡᠰᠡᠭᠲᠦ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "dht11 ᠪᠠ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3  ᠶᠢᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠭᠡ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3 ᠶᠢᠨ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠭᠡ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "dht11 ᠪᠠ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3  ᠶᠢᠨ ᠳᠠᠭᠤᠨ ᠤ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠡ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ mp3 ᠶᠢᠨ ᠳᠠᠭᠤᠨ ᠤ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠡ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "dht11 ᠪᠠ RGB  ᠶᠢᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ RGB ᠶᠢᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "dht11 ᠪᠠ RGB  ᠶᠢᠨ ᠭᠡᠷᠡᠯᠲᠦᠴᠡ ᠶᠢᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ RGB ᠶᠢᠨ ᠭᠡᠷᠡᠯᠲᠦᠴᠡ ᠶᠢᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "dht11 ᠪᠠ RGB  ᠶᠢᠨ ᠤᠨᠲᠠᠷᠠᠭᠠᠬᠤ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ RGB ᠶᠢᠨ ᠤᠨᠲᠠᠷᠠᠭᠠᠬᠤ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "dht11 ᠪᠠ RGB  ᠶᠢᠨ ᠥᠩᠭᠡ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ RGB ᠶᠢᠨ ᠥᠩᠭᠡ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "dht11 ᠪᠠ RGB  ᠶᠢᠨ ᠠᠯᠭᠤᠷ ᠥᠩᠭᠡ ᠬᠣᠪᠢᠷᠭᠠᠯᠲᠠ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ RGB ᠶᠢᠨ ᠠᠯᠭᠤᠷ ᠥᠩᠭᠡ ᠬᠣᠪᠢᠷᠭᠠᠯᠲᠠ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "dht11 ᠪᠠ RGB  ᠶᠢᠨ ᠫᠢᠺᠰᠧᠯ ᠢ ᠬᠥᠳᠡᠯᠭᠡᠬᠦ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ RGB ᠶᠢᠨ ᠫᠢᠺᠰᠧᠯ ᠢ ᠬᠥᠳᠡᠯᠭᠡᠬᠦ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "dht11 ᠪᠠ RGB  ᠶᠢᠨ ᠫᠢᠺᠰᠧᠯ ᠢ ᠣᠷᠴᠢᠨ ᠬᠥᠳᠡᠯᠭᠡᠬᠦ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ RGB ᠶᠢᠨ  ᠫᠢᠺᠰᠧᠯ ᠢ ᠣᠷᠴᠢᠨ ᠬᠥᠳᠡᠯᠭᠡᠬᠦ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "dht11 ᠪᠠ RGB  ᠶᠢᠨ ᠪᠠᠭᠠᠨ᠎ᠠ ᠶᠢᠨ ᠵᠢᠷᠤᠭ ᠤᠨ ᠢᠯᠡᠷᠡᠭᠦᠯᠲᠡ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ RGB ᠶᠢᠨ ᠪᠠᠭᠠᠨ᠎ᠠ ᠶᠢᠨ ᠵᠢᠷᠤᠭ ᠤᠨ ᠢᠯᠡᠷᠡᠭᠦᠯᠲᠡ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "dht11 ᠪᠠ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "dht11 ᠪᠠ obloq mqtt  ᠶᠢᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ obloq mqtt ᠶᠢᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "dht11 ᠪᠠ obloq mqtt  ᠶᠢᠨ ᠴᠢᠮᠡᠭᠡ ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂ obloq mqtt ᠶᠢᠨ ᠴᠢᠮᠡᠭᠡ ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ ᠨᠢ dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "dht11 ᠪᠠ obloq http put ᠭᠤᠶᠤᠴᠢᠯᠠᠯ ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂  dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "dht11 ᠪᠠ obloq http  ᠶᠢᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂  dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "dht11 ᠪᠠ obloq http get  ᠶᠢᠨ ᠡᠬᠢᠵᠢᠭᠦᠯᠦᠯᠲᠡ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂  dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "dht11 ᠪᠠ obloq http post ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂  dht10 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠵᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "dht11 ᠪᠠ obloq http post ᠨᠢ ᠲᠤᠰ ᠲᠤᠰ ᠬᠣᠶᠠᠷ ᠱᠤᠭᠤᠮ ᠤᠨ ᠵᠠᠢ ᠳᠤ ᠪᠠᠢᠬᠤ ᠦᠶ᠎ᠡ ᠳᠦ᠂  dht11 ᠢ ᠪᠤᠷᠤᠭᠤ ᠤᠩᠰᠢᠭᠤᠯᠬᠤ ᠪᠣᠯᠤᠯᠴᠠᠭ᠎ᠠ ᠲᠠᠢ",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "当ds18b20和PM2.5分别在两个线程中，可能导致PM2.5读取错误，建议减ds18b20频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "当ds18b20和obloq mqtt初始化分别在两个线程中，可能导致mqtt初始化失败，建议等待初始化完成再读取ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "当ds18b20和obloq hqtt put请求分别在两个线程中，可能导致put请求失败，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "当ds18b20和obloq http初始化分别在两个线程中，可能导致http初始化失败，建议等待初始化完成再读取ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "当ds18b20和obloq hqtt get请求分别在两个线程中，可能导致get请求失败，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "当ds18b20和obloq hqtt post请求分别在两个线程中，可能导致post请求失败，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "读取ds18b20可能导致红外接收不到数据，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "读取ds18b20可能导致软串口接收数据出错，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "当PM2.5读取和obloq mqtt初始化分别在两个线程中，可能导致mqtt初始化失败或PM2.5读取出错，建议避免mqtt初始化和PM2.5读取同时执行",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "当PM2.5读取和obloq mqtt发送消息分别在两个线程中，可能导致PM2.5读取出错，建议降低PM2.5读取读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "当PM2.5读取和obloq http put请求分别在两个线程中，可能导致put请求失败或PM2.5读取出错，建议降低put请求频率或者降低PM2.5读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "当PM2.5读取和obloq http初始化分别在两个线程中，可能导致http初始化失败或PM2.5读取出错，建议避免http初始化和PM2.5读取同时执行",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "当PM2.5读取和obloq http get请求分别在两个线程中，可能导致get请求失败或PM2.5读取出错，建议降低get请求频率或者降低PM2.5读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "当PM2.5读取和obloq http post请求分别在两个线程中，可能导致post请求失败或PM2.5读取出错，建议降低post请求频率或者降低PM2.5读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "当PM2.5读取和MP3初始化分别在两个线程中，可能致PM2.5读取失败，建议避免PM2.5读取和MP3初始化同时执行",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "当PM2.5读取和MP3设置音量分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁调节音量",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "当PM2.5读取和MP3播放分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁设备播放模式",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "当PM2.5读取和MP3播放分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁切换歌曲",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "当PM2.5读取和串口MP3播放分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁设备播放模式",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "当PM2.5读取和串口MP3播放分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁切换歌曲",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "当PM2.5读取和串口MP3设置音量分别在两个线程中，可能致PM2.5读取失败，建议不要过于频繁调节音量",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "当PM2.5读取可能导致红外接收失败，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "当PM2.5读取和RGB初始化分别在两个线程中，可能导致PM2.5读取错误，建议避免PM2.5读取和RGB初始化同时执行",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "当PM2.5读取和RGB设置亮度分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁调节亮度",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "当PM2.5读取和RGB熄灭分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁熄灭RGB",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "当PM2.5读取和RGB显示颜色分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁切换颜色",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "当PM2.5读取和RGB显示渐变色分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁切换渐变色",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "当PM2.5读取和RGB移动像素分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁移动像素",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "使用Wi-Fi可能导致板载rgb显示颜色异常",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "udp使用了Wi-Fi，可能导致板载rgb显示颜色异常",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "使用Wi-Fi可能导致板载rgb设置三色异常",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "udp使用了Wi-Fi，可能导致板载rgb设置三色异常",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "使用Wi-Fi可能导致板载rgb设置亮度异常",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "udp使用了Wi-Fi，可能导致板载rgb设置亮度异常",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "使用Wi-Fi可能导致板载rgb获取亮度异常",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "udp使用了Wi-Fi，可能导致板载rgb获取亮度异常",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "使用Wi-Fi可能导致串口mp3初始化异常",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "udp使用了Wi-Fi，可能导致串口mp3初始化异常",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "使用Wi-Fi可能导致串口mp3播放异常",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "udp使用了Wi-Fi，可能导致串口mp3播放异常",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "使用Wi-Fi可能导致串口mp3播放曲目失败",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "udp使用了Wi-Fi，可能导致串口mp3播放曲目失败",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "使用Wi-Fi可能导致串口mp3设置音量失败",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "udp使用了Wi-Fi，可能导致串口mp3设置音量失败",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "使用Wi-Fi可能导致360舵机设置转速异常",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "udp使用了Wi-Fi，可能导致360舵机设置转速异常",
    "gui.blocklyText.prompt.telloesp32.emissionPinAndBit_telloWIFI": "Wi-Fi的使用，可能引起红外发射模块异常",
    "gui.blocklyText.prompt.telloesp32.rgbnomal_rgbeffects": "使用呼吸灯和闪烁特效积木程序时不可再单独设置LED灯，请移除其中一种积木",
    "gui.blocklyText.prompt.telloesp32.matrixnomal_matrixeffects": "使用点阵屏特效积木程序时不可再单独设置点阵屏，请移除其中一种积木",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "当PM2.5读取和RGB循环移动像素分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁执行循环移动像素",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "当PM2.5读取和RGB显示柱状分别在两个线程中，可能导致PM2.5读取错误，建议不要过于频繁执行RGB显示柱状",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "当PM2.5读取和led点阵初始化分别在两个线程中，可能导致led点阵初始化出错，建议避免PM2.5读取和led点阵初始化同时执行",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "当PM2.5读取和led点阵显示分别在两个线程中，可能导致led点阵显示出错，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "PM2.5读取可能导致PM2.5读取出错，建议不要过于频繁发送数据",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "PM2.5读取可能导致软串口读取失败，建议不要过于频繁读取PM2.5",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3可能导致红外接收不到数据，建议降低MP3使用频率",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "当MP3和obloq mqtt初始化分别在两个线程中，可能导致mqtt初始化失败，建议避免mqtt初始化和MP3同时执行",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "当MP3和obloq http put请求分别在两个线程中，可能导致put请求失败，建议降低put请求频率或者降低MP3使用频率",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "当MP3和obloq http初始化分别在两个线程中，可能导致http初始化失败，建议避免http初始化和MP3同时执行",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "当MP3和obloq http get请求分别在两个线程中，可能导致get请求失败，建议降低get请求频率或者降低MP3使用频率",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "当MP3和obloq http post请求分别在两个线程中，可能导致post请求失败，建议降低post请求频率或者降低MP3使用频率",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3可能导致软串口读取失败，建议不要过于频繁使用MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "串口MP3可能导致红外接收不到数据，建议降低串口MP3使用频率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "当串口MP3和obloq mqtt初始化分别在两个线程中，可能导致mqtt初始化失败，建议避免mqtt初始化和串口MP3同时执行",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "当串口MP3和obloq http put请求分别在两个线程中，可能导致put请求失败，建议降低put请求频率或者降低串口MP3使用频率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "当串口MP3和obloq http初始化分别在两个线程中，可能导致http初始化失败，建议避免http初始化和串口MP3同时执行",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "当串口MP3和obloq http get请求分别在两个线程中，可能导致get请求失败，建议降低get请求频率或者降低串口MP3使用频率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "当串口MP3和obloq http post请求分别在两个线程中，可能导致post请求失败，建议降低post请求频率或者降低串口MP3使用频率",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "串口MP3可能导致软串口读取失败，建议不要过于频繁使用串口MP3",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "红外发射可能导致软串口obloq接收出错，建议降低红外发射频率",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "软串口obloq可能导致红外接收数据出错，建议避免obloq频繁发送消息",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "RGB灯带初始化可能导致软串口obloq接收消息出错，建议避免频繁使用RGB灯带",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "灯带设置亮度可能导致软串口obloq接收消息出错，建议避免频繁调节亮度",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "RGB灯带熄灭可能导致软串口obloq接收消息出错，建议避免频繁熄灭灯带",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "RGB灯带显可能导致软串口obloq接收消息出错，建议避免频繁显示颜色",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "RGB灯带显示渐变色可能导致软串口obloq接收消息出错，建议避免频繁显示渐变色",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "RGB灯带移动像素可能导致软串口obloq接收消息出错",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "RGB灯带循环移动像素可能导致软串口obloq接收消息出错",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "RGB灯带显示柱状可能导致软串口obloq接收消息出错",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "当软串口obloq和led点阵初始化分别在两个线程中，可能导致led点阵初始化出错，建议避免软串口obloq和led点阵初始化同时执行",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "当软串口obloq和led点阵显示分别在两个线程中，可能导致led点阵显示出错",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "软串口发送可能导致软串口obloq读取出错，建议不要过于频繁发送数据",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "软串口obloq发送可能导致软串口读取失败，建议不要过于频繁发送数据",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "红外发射可能导致红外接收不到数据，建议降低红外发射频率",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "当红外发射可能导致软串口读取失败，建议降低红外发射频率",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "RGB灯带初始化可能导致红外接收不到数据",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "RGB灯带设置亮度可能导致红外接收不到数据，建议避免RGB灯带频繁设置亮度",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "RGB灯带熄灭可能导致红外接收不到数据",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "RGB灯带显示颜色可能导致红外接收不到数据，建议避免RGB灯带频繁显示颜色",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "RGB灯带显示渐变色可能导致红外接收不到数据，建议避免RGB灯带频繁显示渐变色",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "RGB灯带移动像素可能导致红外接收不到数据，建议避免RGB灯带频繁移动像素",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "RGB灯带循环移动像素可能导致红外接收不到数据，建议避免RGB灯带频繁循环移动像素",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "RGB灯带显示柱状可能导致红外接收不到数据，建议避免RGB灯带频繁显示柱状",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "红外接收可能导致led初始化失败",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "红外接收可能导致led显示错误",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "软串口发送可能导致红外接收不到数据，建议避免软串口频繁发送",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "RGB灯带可能导致软串口读取失败，建议降低RGB灯带使用频率",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "软串口接收数据可能导致led点阵显示出错",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "软串口发送可能导致软串口接收数据出错，建议避免发送数据和接收数据同时执行",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "串口MP3初始化可能导致红外接收数据出错，建议尽量避免同时执行",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "串口MP3设置播放模式可能导致红外接收数据出错，建议避免过于频繁设置播放模式",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "串口MP3播放可能导致红外接收数据出错，建议避免过于频繁切换歌曲",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "串口MP3设置音量可能导致红外接收数据出错，建议避免过于频繁调节音量",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "max7219初始化可能导致红外接收数据出错，建议尽量避免同时执行",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "max7219显示图像可能导致红外接收数据出错，建议避免过于频繁显示图像",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "串口MP3模块可能导致引脚中断不能触发，建议避免过于频繁使用串口MP3模块",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "max7219显示模块可能导致引脚中断不能触发，建议避免过于频繁使用max7219显示",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "dht读取可能导致红外接收消息错误，建议降低dht读取频率",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "ds18b20读取可能导致软外接收消息错误，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "PM2.5读取可能导致软外接收消息错误，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "mp3初始化读取可能导致红外接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "mp3设置音量可能导致红外接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "mp3设置模式可能导致红外接收消息错误，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "mp3播放可能导致红外接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "串口mp3初始化读取可能导致红外接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "串口mp3播放可能导致红外接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "串口mp3设置音量可能导致红外接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "红外发射可能导致红外接收消息错误，建议避免过于频繁发送红外",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "RGB灯带初始化可能导致红外接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "RGB灯带设置亮度可能导致红外接收消息错误，建议避免过于频繁设置亮度",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "RGB灯带显示可能导致红外接收消息错误，建议避免过于频繁执行RGB灯带显示",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "RGB灯带显示渐变色可能导致红外接收消息错误，建议避免过于频繁显示渐变色",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "RGB灯带移动像素可能导致红外接收消息错误，建议避免过于频繁移动像素",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "RGB灯带循环移动像素可能导致红外接收消息错误，建议避免过于频繁执行循环移动像素",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "RGB灯带显示柱状可能导致红外接收消息错误，建议避免过于频繁执行显示柱状",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "红外接收消息可能导致max7219初始化失败",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "红外接收消息可能导致max7219显示出错",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "软串口打印可能导致红外接收消息错误，建议避免过于频繁执行软串口打印",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "软串口obloq mqtt初始化可能导致红外接收数据出错，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "软串口obloq put请求可能导致红外接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "软串口obloq http初始化可能导致红外接收数据出错，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "软串口obloq get请求可能导致红外接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "软串口obloq post请求可能导致红外接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "RGB灯带清除显示可能导致软红外接收消息错误，建议避免过于频繁清除显示",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "ds18b20读取可能导致软串口接收消息错误，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "PM2.5读取可能导致软串口接收消息错误，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "mp3初始化读取可能导致软串口接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "mp3设置音量可能导致软串口接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "mp3设置模式可能导致软串口接收消息错误，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "mp3播放可能导致软串口接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "串口mp3初始化读取可能导致软串口接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "串口mp3设置模式可能导致软串口接收消息错误，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "串口mp3播放可能导致软串口接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "串口mp3设置音量可能导致软串口接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "红外发射可能导致软串口接收消息错误，建议避免过于频繁发送红外",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "RGB灯带初始化可能导致软串口接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "RGB灯带设置亮度可能导致软串口接收消息错误，建议避免过于频繁设置亮度",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "RGB灯带清除显示可能导致软串口接收消息错误，建议避免过于频繁清除显示",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "RGB灯带显示可能导致软串口接收消息错误，建议避免过于频繁执行RGB灯带显示",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "RGB灯带显示渐变色可能导致软串口接收消息错误，建议避免过于频繁显示渐变色",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "RGB灯带移动像素可能导致软串口接收消息错误，建议避免过于频繁移动像素",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "RGB灯带循环移动像素可能导致软串口接收消息错误，建议避免过于频繁执行循环移动像素",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "RGB灯带显示柱状可能导致软串口接收消息错误，建议避免过于频繁执行显示柱状",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "软串口接收消息可能导致max7219初始化失败",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "软串口接收消息可能导致max7219显示出错",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "软串口打印可能导致软串口接收消息错误，建议避免过于频繁执行软串口打印",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "dht读取可能导致引脚中断触发失败，建议降低dht读取频率",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "ds18b20读取可能导致引脚中断触发失败，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "PM2.5读取可能导致引脚中断触发失败，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "mp3初始化读取可能导致引脚中断触发失败，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "mp3设置音量可能导致引脚中断触发失败，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "mp3设置模式可能导致引脚中断触发失败，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "mp3播放可能导致引脚中断触发失败，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "串口mp3初始化读取可能导致引脚中断触发失败，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "串口mp3设置模式可能导致引脚中断触发失败，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "串口mp3播放可能导致引脚中断触发失败，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "串口mp3设置音量可能导致引脚中断触发失败，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "红外发射可能导致引脚中断触发失败，建议避免过于频繁发送红外",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "RGB灯带初始化可能导致引脚中断触发失败，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "RGB灯带设置亮度可能导致引脚中断触发失败，建议避免过于频繁设置亮度",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "RGB灯带清除显示可能导致引脚中断触发失败，建议避免过于频繁清除显示",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "RGB灯带显示可能导致引脚中断触发失败，建议避免过于频繁执行RGB灯带显示",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "RGB灯带显示渐变色可能导致引脚中断触发失败，建议避免过于频繁显示渐变色",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "RGB灯带移动像素可能导致引脚中断触发失败，建议避免过于频繁移动像素",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "RGB灯带循环移动像素可能导致引脚中断触发失败，建议避免过于频繁执行循环移动像素",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "RGB灯带显示柱状可能导致引脚中断触发失败，建议避免过于频繁执行显示柱状",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "引脚中断可能导致max7219初始化失败",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "引脚中断可能导致max7219显示出错",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "软串口打印可能导致引脚中断触发失败，建议避免过于频繁执行软串口打印",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "软串口obloq mqtt初始化可能导致引脚中断触发失败，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "软串口obloq put请求可能导致引脚中断触发失败，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "软串口obloq http初始化可能导致引脚中断触发失败，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "软串口obloq get请求可能导致引脚中断触发失败，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "软串口obloq post请求可能导致引脚中断触发失败，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "dht读取可能导致软串口obloq接收消息错误，建议降低dht读取频率",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "ds18b20读取可能导致软串口obloq接收消息错误，建议降低ds18b20读取频率",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "PM2.5读取可能导致软串口obloq接收消息错误，建议降低PM2.5读取频率",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "mp3初始化读取可能导致软串口obloq接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "mp3设置音量可能导致软串口obloq接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "mp3设置模式可能导致软串口obloq接收消息错误，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "mp3播放可能导致软串口obloq接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "串口mp3初始化读取可能导致软串口obloq接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "串口mp3设置模式可能导致软串口obloq接收消息错误，建议避免过于频繁设置模式",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "串口mp3播放可能导致软串口obloq接收消息错误，建议避免过于频繁切换音乐",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "串口mp3设置音量可能导致软串口obloq接收消息错误，建议避免过于频繁调整音量",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "红外发射可能导致软串口obloq接收消息错误，建议避免过于频繁发送红外",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "软串口bloq发送消息可能导致红外接收出错，建议避免obloq过于频繁发送消息",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "RGB灯带初始化可能导致软串口obloq接收消息错误，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "RGB灯带设置亮度可能导致软串口obloq接收消息错误，建议避免过于频繁设置亮度",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "RGB灯带清除显示可能导致软串口obloq接收消息错误，建议避免过于频繁清除显示",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "RGB灯带显示可能导致软串口obloq接收消息错误，建议避免过于频繁执行RGB灯带显示",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "RGB灯带显示渐变色可能导致软串口obloq接收消息错误，建议避免过于频繁显示渐变色",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "RGB灯带移动像素可能导致软串口obloq接收消息错误，建议避免过于频繁移动像素",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "RGB灯带循环移动像素可能导致软串口obloq接收消息错误，建议避免过于频繁执行循环移动像素",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "RGB灯带显示柱状可能导致软串口obloq接收消息错误，建议避免过于频繁执行显示柱状",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "软串口obloq接收消息可能导致max7219初始化失败",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "软串口obloq接收消息可能导致max7219显示出错",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "软串口打印可能导致软串口obloq接收消息错误，建议避免过于频繁执行软串口打印",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "软串口obloq发送数据可能导致软串口接收数据出错，建议避免obloq过于频繁发送数据",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "软串口obloq发送数据可能导致引脚中断不能触发，建议避免obloq过于频繁发送数据",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "软串口obloq mqtt初始化可能导致软串口接收数据出错，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "软串口obloq put请求可能导致软串口接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "软串口obloq http初始化可能导致软串口接收数据出错，建议避免同时执行",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "软串口obloq get请求可能导致软串口接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "软串口obloq post请求可能导致软串口接收数据出错，建议避免过于频繁请求数据",
    "gui.blocklyText.compatible.shapedBlock": "ᠰᠢᠨ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠬᠣᠪᠢᠷᠠᠯᠲᠠ᠄ ᠬᠣᠪᠢᠰᠬᠤᠨ ᠦᠭᠡᠢ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠤᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠠᠴᠠ ᠬᠣᠪᠢᠰᠬᠤᠨ ᠲᠠᠢ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠦᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠪᠣᠯᠵᠠᠢ᠃",
    "gui.blocklyText.compatible.shapedBlock.variable": "ᠰᠢᠨ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠬᠣᠪᠢᠷᠠᠯᠲᠠ᠄ ᠲᠤᠰᠭᠠᠷ ᠫᠷᠥᠭᠷᠠᠮ ᠤᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠠᠴᠠ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠦᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤᠨ ᠤ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠤ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠪᠣᠯᠵᠠᠢ᠃",
    "gui.blocklyText.compatible.booleanToRound": "ᠰᠢᠨ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠬᠣᠪᠢᠷᠠᠯᠲᠠ᠄ ᠨᠥᠬᠦᠴᠡᠯ ᠤᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠠᠴᠠ ᠲᠣᠭ᠎ᠠ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠦᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠪᠣᠯᠵᠠᠢ᠃",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "ᠰᠢᠨ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠬᠣᠪᠢᠷᠠᠯᠲᠠ᠄ ᠫᠷᠥᠭᠷᠠᠮ ᠤᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤᠨ ᠤ ᠵᠥᠭᠡᠯᠡᠨ ᠬᠠᠲᠠᠭᠤ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠢ ᠰᠣᠯᠪᠢᠴᠠᠭᠤᠯᠬᠤ ᠳᠣᠭᠤᠭᠰᠢ ᠲᠠᠲᠠᠬᠤ ᠳᠣᠪᠶᠤᠭ ᠢ ᠨᠡᠮᠡᠪᠡ᠃",
    "gui.blocklyText.compatible.mp3.deletePin": "ᠰᠢᠨ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠬᠣᠪᠢᠷᠠᠯᠲᠠ᠄ ᠫᠷᠥᠭᠷᠠᠮ ᠤᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤᠨ ᠤ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ ᠳᠣᠭᠤᠭᠰᠢ ᠲᠠᠲᠠᠬᠤ ᠳᠣᠪᠶᠤᠭ ᠢ ᠤᠰᠠᠳᠭᠠᠬᠤ",
    "gui.blocklyText.compatible.sen0206.addDropdown": "ᠰᠢᠨ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠬᠣᠪᠢᠷᠠᠯᠲᠠ᠄ ᠫᠷᠥᠭᠷᠠᠮ ᠤᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤᠨ ᠳᠤ ᠳᠣᠭᠤᠭᠰᠢ ᠲᠠᠲᠠᠬᠤ ᠳᠣᠪᠶᠤᠭ ᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠪᠡ᠃",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "ᠰᠢᠨ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠬᠣᠪᠢᠷᠠᠯᠲᠠ᠄ ᠫᠷᠥᠭᠷᠠᠮ ᠤᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤᠨ ᠤ ᠵᠠᠯᠭᠠᠳᠠᠰᠤ ᠶᠢᠨ ᠳᠣᠭᠤᠭᠰᠢ ᠲᠠᠲᠠᠬᠤ ᠳᠣᠪᠶᠤᠭ ᠢ ᠨᠡᠮᠡᠪᠡ᠃",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "ᠰᠢᠨ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠬᠣᠪᠢᠷᠠᠯᠲᠠ᠄ ᠫᠷᠥᠭᠷᠠᠮ ᠤᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤᠨ ᠤ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠤᠨ ᠳᠣᠭᠤᠭᠰᠢ ᠲᠠᠲᠠᠬᠤ ᠳᠣᠪᠶᠤᠭ ᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠪᠡ᠃",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "ᠰᠢᠨ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠬᠣᠪᠢᠷᠠᠯᠲᠠ᠄ ᠬᠡᠷᠡᠭ ᠶᠠᠪᠤᠳᠠᠯ ᠤᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠠᠴᠠ ᠨᠣᠬᠦᠴᠡᠯ ᠦᠨ ᠵᠡᠷᠭᠡᠯᠡᠭᠡ ᠮᠣᠳᠤ ᠪᠣᠯᠵᠠᠢ᠃",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "新版本更新点：删除了积木块",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "新版本变动点：将方形下拉框修改为圆形下拉框。",
    "gui.blocklyText.compatible.shouldBlockInitFirst": "小模块存在修改，请重新设计程序",
    "gui.blocklyText.compatible.shapeChanged": "新版本变动点：积木形状发生改变，请重新拖动。",
    "gui.blocklyText.compatible.CCS811init": "新版本变动点：积木参数减少，拆分成2条积木，请重新拖动。",
    "gui.blocklyText.compatible.obloq": "新版本更新点：删除了积木块",
    "gui.blocklyText.pictureai.initToken": "初始化图像AI[SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "从本地文件获取图片[IMAGE]",
    "gui.blocklyText.pictureai.cameraToggle": "切换至摄像头[CAMERA_LIST]",
    "gui.blocklyText.pictureai.getVideoImage": "从摄像头画面截取图片",
    "gui.blocklyText.pictureai.webPicture": "从网址获取图片[TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH]保存视频截图到本地[TEXT]",
    "gui.blocklyText.pictureai.face": "识别图中[TEXT]的人脸信息",
    "gui.blocklyText.pictureai.picture": "识别图中[TEXT]包含的[SELECT]",
    "gui.blocklyText.pictureai.word": "识别图中[TEXT]包含的[SELECT]",
    "gui.blocklyText.pictureai.gesture": "识别图中[TEXT]包含的手势",
    "gui.blocklyText.pictureai.TOPIC_W_A": "文字",
    "gui.blocklyText.pictureai.TOPIC_W_B": "数字",
    "gui.blocklyText.pictureai.TOPIC_W_C": "车牌号",
    "gui.blocklyText.pictureai.TOPIC_W_D": "手写字",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "左脚踝",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "左耳",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "左手肘",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "左眼",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "左臀",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "左膝",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "左嘴角",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "左肩",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "左手腕",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "脖子",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "鼻子",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "右脚踝",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "右耳",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "右手肘",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "右眼",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "右臀",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "右膝",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "右嘴角",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "右肩",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "右手腕",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "头部",
    "gui.blocklyText.pictureai.initUserToken": "切换至独立账户[MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "识别图中[TEXT]的人体关键点",
    "gui.blocklyText.pictureai.faceContrast": "对比图片[TEXT]与图片[TEXT2]中的人脸",
    "gui.blocklyText.pictureai.faceMerge": "融合图片[TEXT]与图片[TEXT2]中的人脸",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "图像主体",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "通用物体和场景",
    "gui.blocklyText.pictureai.TOPIC_P_A": "植物",
    "gui.blocklyText.pictureai.TOPIC_P_B": "动物",
    "gui.blocklyText.pictureai.TOPIC_P_C": "花卉",
    "gui.blocklyText.pictureai.TOPIC_P_D": "果蔬",
    "gui.blocklyText.pictureai.TOPIC_P_E": "货币",
    "gui.blocklyText.pictureai.bodyPointSuccess": "人体关键点识别成功？",
    "gui.blocklyText.pictureai.bodyPointInfo": "人体关键点获取结果 [BODY]的[XY]坐标",
    "gui.blocklyText.pictureai.noPower": "请检查账号是否已开启该功能",
    "gui.blocklyText.pictureai.noPicture": "请先选择图片",
    "gui.blocklyText.pictureai.pictureError": "图片格式不正确",
    "gui.blocklyText.pictureai.noVideo": "请确认摄像头已正常连接无其它软件占用，并开启摄像头，或重启软件",
    "gui.blocklyText.pictureai.defaultTitleText": "视频窗口",
    "gui.blocklyText.pictureai.videoContainer": "使用[CON]显示摄像头画面",
    "gui.blocklyText.pictureai.TOPIC_stage": "舞台",
    "gui.blocklyText.pictureai.TOPIC_popup": "弹窗",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "新版本变动点：有程序积木被删除。",
    "gui.blocklyText.pictureai.gestureList": "手势[TEXT]",
    "gui.blocklyText.pictureai.networkError": "网络出错，请检查网络连接",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "人脸识别成功？",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "人脸识别获取结果[TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "人脸数目",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "年纪",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "颜值",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "性别",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "眼镜",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "脸型",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "表情",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "人种可信度",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "距离左边",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "距离顶部",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "宽度",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "高度",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "眼耳口鼻坐标",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "人脸遮挡概率",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "模糊度",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "人脸完整度",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "真人可能性",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "卡通人物可能性",
    "gui.blocklyText.pictureai.TOPIC_FACE_emotion": "情绪",
    "gui.blocklyText.pictureai.TOPIC_FACE_mask": "戴口罩",
    "gui.blocklyText.pictureai.TOPIC_FACE_left_eye": "左眼张开度",
    "gui.blocklyText.pictureai.TOPIC_FACE_right_eye": "右眼张开度",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "打开",
    "gui.blocklyText.maqueenPlus.off": "关闭",
    "gui.blocklyText.maqueenPlus.Forward": "前进",
    "gui.blocklyText.maqueenPlus.Backward": "后退",
    "gui.blocklyText.maqueenPlus.left": "左侧",
    "gui.blocklyText.maqueenPlus.right": "右侧",
    "gui.blocklyText.maqueenPlus.all": "全部",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "电机 [DIR] 以 [SPEED] 的速度 [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "电机 [DIR] 停止",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "电机 [DIR] 速度补偿 [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "读取 [DIR] 电机速度",
    "gui.blocklyText.maqueenPlus.readMoterDir": "读取 [DIR] 电机方向 (0:停止 1:前进 2:后退)",
    "gui.blocklyText.maqueenPlus.clearMoterRunedCircle": "清除车轮圈数 [DIR]",
    "gui.blocklyText.maqueenPlus.getMoterRunedCircle": "获取车轮圈数 [DIR]",
    "gui.blocklyText.maqueenPlus.setServo": "舵机 [INDEX] 角度 [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "设置 [DIR] RGB灯显示 [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "读取巡线传感器 [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "读取巡线传感器 [INDEX] 灰度值",
    "gui.blocklyText.maqueenPlus.getVersion": "获取版本",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "读取超声波传感器 trig [TRIG] echo [ECHO] 单位 [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "当接收到 [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "红外数据",
    "gui.blocklyText.maqueenPlus.cm": "厘米",
    "gui.blocklyText.maqueenPlus.inch": "英寸",
    "gui.blocklyText.maqueenPlusV2.setMotorSpeedMove": "设置 [DIR] 方向 [SPEEDDIR] 速度 [SPEED]",
    "gui.blocklyText.maqueenPlusV2.setMotorStop": "设置 [DIR] 停止",
    "gui.blocklyText.maqueenPlusV2.readIR": "读红外线键值",
    "gui.blocklyText.maqueenPlusV2.left": "左侧电机",
    "gui.blocklyText.maqueenPlusV2.right": "右侧电机",
    "gui.blocklyText.maqueenPlusV2.all": "全部电机",
    "gui.blocklyText.maqueenPlusV2.leftLED": "左侧LED灯",
    "gui.blocklyText.maqueenPlusV2.rightLED": "右侧LED灯",
    "gui.blocklyText.maqueenPlusV2.allLED": "全部LED灯",
    "gui.blocklyText.maqueenPlusV2.on": "打开",
    "gui.blocklyText.maqueenPlusV2.off": "关闭",
    "gui.blocklyText.maqueenPlusV2.rotateForward": "前进",
    "gui.blocklyText.maqueenPlusV2.rotateBackward": "后退",
    "gui.blocklyText.maqueenPlusV2.readSensationSensor": "读取巡线传感器 [INDEX] 状态",
    "gui.blocklyText.maqueenPlusV2.setRgb": "设置 [DIR] [COLOR]",
    "gui.blocklyText.maqueenPlusV2.readSensationSensorYscaleValue": "读取巡线传感器 [INDEX] ADC数据",
    "gui.blocklyText.maqueenPlusV2.readUltrasonic": "读取超声波传感器 TRIG脚 [TRIG] ECHO脚 [ECHO] 单位：cm",
    "gui.blocklyText.maqueenPlusV2.getVersion": "读取版本号",
    "gui.blocklyText.esp32.audioRecognition": "识别 [TIME] 秒语音指令",
    "gui.blocklyText.esp32.audioRecordingFree": "录音 释放资源",
    "gui.blocklyText.esp32.audioRecordingStart": "录制音频 存储路径 [PATH] 时长 [TIME] 秒",
    "gui.blocklyText.esp32.audioRecordingInit": "录音 初始化",
    "gui.blocklyText.esp32.audioPlay": "音频 播放 [URL]",
    "gui.blocklyText.esp32.audioControl": "音频 [CONTROL]",
    "gui.blocklyText.esp32.AudioControl.A": "暂停",
    "gui.blocklyText.esp32.AudioControl.B": "继续播放",
    "gui.blocklyText.esp32.AudioControl.C": "停止",
    "gui.blocklyText.esp32.audioSetVolume": "音频 设置音量 [VOLUME]",
    "gui.blocklyText.esp32.audioFree": "音频 释放缓存",
    "gui.blocklyText.esp32.audioInit": "音频 初始化",
    "gui.blocklyText.esp32.audio": "音频",
    "gui.blocklyText.esp32.musicGetTempo": "获取当前节拍",
    "gui.blocklyText.esp32.musicSetTempo": "设置每一拍等同 [TICKS] 分音符，每分钟节拍数 [TEMPO]",
    "gui.blocklyText.esp32.musicSetPlaySpeed": "设置播放速度为 [SPEED] 倍",
    "gui.blocklyText.esp32.musicPlayMelodySet": "播放音乐 [MELODY] [WAIT] [LOOP] 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayMelody": "播放音乐 [MELODY] 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayNoteFrom": "播放音调 从 [MIN] 到 [AMX] 步长 [STEP] 时长 [DURATION] 毫秒 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayNote": "播放音调 [NOTE] 延时 [DELAY] 毫秒 引脚 [PIN]",
    "gui.blocklyText.esp32.musicTone": "音调 [NOTE]",
    "gui.blocklyText.esp32.musicPlayNoteContinuous": "播放连续音调 [NOTE] 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPlayList": "播放音符列表 [NOTELIST] [WAIT] [LOOP] 引脚 [PIN]",
    "gui.blocklyText.esp32.IsWait.A": "等待",
    "gui.blocklyText.esp32.IsWait.B": "不等待",
    "gui.blocklyText.esp32.IsLoop.A": "循环播放",
    "gui.blocklyText.esp32.IsLoop.B": "播放一次",
    "gui.blocklyText.esp32.getNote": "音符 [NOTE] 节拍 [BEAT]",
    "gui.blocklyText.esp32.musicPlay": "播放音符 [NOTE] 引脚 [PIN]",
    "gui.blocklyText.esp32.restore": "恢复音乐设置",
    "gui.blocklyText.esp32.stopPlay": "停止播放音乐 引脚 [PIN]",
    "gui.blocklyText.esp32.musicPin_default": "默认",
    "gui.blocklyText.mpyUART.input": "从串口读取文本 提示语句 [TITLE]",
    "gui.blocklyText.mpyUART.close": "关闭串口 [UART]",
    "gui.blocklyText.mpyUART.readLength": "串口 [UART] 读取数据 长度 [LENGTH]",
    "gui.blocklyText.mpyUART.read": "串口 [UART] 读取数据",
    "gui.blocklyText.mpyUART.readline": "串口 [UART] 读取一行数据",
    "gui.blocklyText.mpyUART.any": "串口 [UART] 有可读数据？",
    "gui.blocklyText.mpyUART.writeBytes": "串口 [UART] 写入字节数组 [BYTES]",
    "gui.blocklyText.mpyUART.writeByte": "串口 [UART] 写入单字节[BYTE]",
    "gui.blocklyText.mpyUART.init": "串口 [UART] 初始化 波特率 [BAUDRATE] tx [TXPIN] rx [RXPIN]",
    "gui.blocklyText.mpyUART.initTello": "串口 [UART] 初始化 rx [RXPIN] tx [TXPIN] 波特率 [BAUDRATE]",
    "gui.blocklyText.mpyUART.writeStringTello": "串口 [UART] 写入文本 [TEXT] [ENDSYMBOL]",
    "gui.blocklyText.mpyUART.anyTello": "串口 [UART] 有可读数据？",
    "gui.blocklyText.mpyUART.readTello": "串口 [UART] 读取数据",
    "gui.blocklyText.mpyUART.closeTello": "关闭串口 [UART]",
    "gui.blocklyText.mpyUART.writeString": "串口 [UART] 写入文本 [TEXT] [ENDSYMBOL]",
    "gui.blocklyText.mpyUART.no": "不换行",
    "gui.blocklyText.mpyUART.auto": "自动换行",
    "gui.blocklyText.mpyI2C.scan": "I2C 扫描结果",
    "gui.blocklyText.mpyI2C.read": "I2C 地址 [ADDRESS] 读取bytes数量 [BYTES]",
    "gui.blocklyText.mpyI2C.write": "I2C 地址 [ADDRESS] 写入 [DATA]",
    "gui.blocklyText.mpyI2C.init": "初始化I2C SCL [SCLPIN] SDA [SDAPIN] 波特率 [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoInitBoard": "初始化板载I2C 频率 [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoUniversal": "初始化通用I2C SCL [SCLPIN] SDA [SDAPIN] 频率 [BAUDRATE]",
    "gui.blocklyText.mpyI2C.maixduinoWrite": "[OBJ] 地址 [ADDRESS] 写入 [DATA]",
    "gui.blocklyText.mpyI2C.maixduinoRead": "[OBJ] 地址 [ADDRESS] 读取bytes数量 [BYTES]",
    "gui.blocklyText.mpyI2C.maixduinoScan": "[OBJ] 扫描结果",
    "gui.blocklyText.esp32.readPinLevel": "[PIN] 外部 [LEVEL] 持续时间",
    "gui.blocklyText.esp32.noPull": "悬空",
    "gui.blocklyText.esp32.pullDown": "下拉",
    "gui.blocklyText.esp32.pullUp": "上拉",
    "gui.blocklyText.esp32.setPinMode": "设置引脚 [PIN] 为 [MODE] 模式 [PULL] 并设置为 [LEVEL]",
    "gui.blocklyText.esp32.leakyOutput": "开漏输出",
    "gui.blocklyText.mpyServo.setServo": "设置舵机 [PIN] 角度为 [ANGLE] 脉宽从 [MIN] 到 [MAX] 最大角度 [ANGLEMAX]",
    "gui.blocklyText.servo.init": "设置引脚 [PIN] 舵机角度为 [ANGLE] 度 定时器 [TIMER]",
    "gui.blocklyText.mpyServo.setAngle": "设置舵机 [PIN] 角度为 [ANGLE]",
    "gui.blocklyText.esp32.shaked": "被摇晃",
    "gui.blocklyText.esp32.thrown": "被抛起",
    "gui.blocklyText.esp32.tilt_forward": "向前倾斜",
    "gui.blocklyText.esp32.tilt_back": "向后倾斜",
    "gui.blocklyText.esp32.tilt_right": "向右倾斜",
    "gui.blocklyText.esp32.tilt_left": "向左倾斜",
    "gui.blocklyText.esp32.tilt_none": "平放",
    "gui.blocklyText.esp32.attitudeEvent": "当掌控板 [ATTITUDE]",
    "gui.blocklyText.esp32.to_high": "↗ 从低到高",
    "gui.blocklyText.esp32.to_low": "↘ 从高到低",
    "gui.blocklyText.esp32.pinLevelEvent": "当引脚 [PIN] 电平 [TRANSITION]",
    "gui.blocklyText.mpyTimer.setEvent": "设置定时器 [INDEX] [MODE] 周期 [PERIOD] 毫秒",
    "gui.blocklyText.mpyTimer.repeated": "重复执行",
    "gui.blocklyText.mpyTimer.delayed": "延时执行",
    "gui.blocklyText.mpyTimer.conditionEvent": "设置定时器 [INDEX] :当 [CONDITION]",
    "gui.blocklyText.mpyTimer.getCount": "定时器 [INDEX] 的计数值",
    "gui.blocklyText.mpyTimer.clearEvent": "清除 定时器/事件 [INDEX]",
    "gui.blocklyText.mpyDictionary.init": "初始化字典 [CONTENT]",
    "gui.blocklyText.mpyDictionary.getValue": "字典 [CONTENT] 键 [KEY] 的值",
    "gui.blocklyText.mpyDictionary.setValue": "字典 [CONTENT] 键 [KEY] 的值设置为 [VALUE]",
    "gui.blocklyText.mpyDictionary.deleteKey": "字典 [CONTENT] 删除键 [KEY]",
    "gui.blocklyText.mpyDictionary.clear": "清空字典 [CONTENT]",
    "gui.blocklyText.mpyDictionary.isInclude": "字典 [CONTENT] 包含键 [KEY]？",
    "gui.blocklyText.mpyDictionary.getLength": "字典 [CONTENT] 长度",
    "gui.blocklyText.mpyDictionary.list": "字典 [CONTENT] [TYPE] 的列表",
    "gui.blocklyText.mpyDictionary.TypeIndex.A": "键",
    "gui.blocklyText.mpyDictionary.TypeIndex.B": "值",
    "gui.blocklyText.mpyList.join": "列表 [LIST] 使用分隔符 [SYMBOL] 组合文本",
    "gui.blocklyText.mpyList.split": "文本 [TEXT] 使用分隔符 [SYMBOL] 制作列表",
    "gui.blocklyText.mpyList.init": "初始化列表 [CONTENT]",
    "gui.blocklyText.mpyList.clear": "清空列表 [CONTENT]",
    "gui.blocklyText.mpyList.getLength": "列表 [CONTENT] 的长度",
    "gui.blocklyText.mpyList.isEmpty": "列表 [CONTENT] 是空的？",
    "gui.blocklyText.mpyList.getValue": "列表 [CONTENT] 索引 [INDEX] 的值",
    "gui.blocklyText.mpyList.getValueRange": "返回列表 [CONTENT] 取 [TYPE1] [NUM1] 项到 [TYPE2] [NUM2] 项",
    "gui.blocklyText.mpyList.insert": "列表 [CONTENT] 在索引 [INDEX] 处插入 [VALUE]",
    "gui.blocklyText.mpyList.setValue": "列表 [CONTENT] 设置索引 [INDEX] 的值为 [VALUE]",
    "gui.blocklyText.mpyList.delete": "列表 [CONTENT] 删除索引 [INDEX] 的值",
    "gui.blocklyText.mpyList.append": "列表 [CONTENT] 将 [APPEND] 添加到末尾",
    "gui.blocklyText.mpyList.extend": "列表 [LIST1] 追加列表 [LIST2]",
    "gui.blocklyText.mpyList.findIndex": "列表 [CONTENT] 查找 [VALUE] 的索引",
    "gui.blocklyText.mpyList.sort": "列表 [LIST] 按 [TYPE] 排序为 [MODE]",
    "gui.blocklyText.mpyList.SortModeIndex.A": "升序",
    "gui.blocklyText.mpyList.SortModeIndex.B": "降序",
    "gui.blocklyText.mpyList.SortTypeIndex.A": "数字",
    "gui.blocklyText.mpyList.SortTypeIndex.B": "字母",
    "gui.blocklyText.mpyList.SortTypeIndex.C": "字母，忽略大小写",
    "gui.blocklyText.mpySet.init": "初始化集合 [CONTENT]",
    "gui.blocklyText.mpySet.update": "集合 [CONTENT1] 更新为与集合 [CONTENT2] 的 [TYPE]",
    "gui.blocklyText.mpySet.TypeIndex.A": "交集",
    "gui.blocklyText.mpySet.TypeIndex.B": "并集",
    "gui.blocklyText.mpySet.TypeIndex.C": "差集",
    "gui.blocklyText.mpySet.addValue": "集合 [CONTENT] 添加 [VALUE]",
    "gui.blocklyText.mpySet.isSubsetSuperset": "集合 [CONTENT1] 为集合 [CONTENT2] 的 [TYPE]？",
    "gui.blocklyText.mpySet.SubsetSupersetType.A": "子集",
    "gui.blocklyText.mpySet.SubsetSupersetType.B": "超集",
    "gui.blocklyText.mpySet.intersectionUnionSetDifference": "取 [TYPE] 集合 [CONTENT1] 集合 [CONTENT2]",
    "gui.blocklyText.mpySet.length": "集合 [CONTENT] 长度",
    "gui.blocklyText.mpySet.pop": "返回随机项 并从集合 [CONTENT] 移除",
    "gui.blocklyText.mpyTuple.init": "初始化元组 [CONTENT]",
    "gui.blocklyText.mpyTuple.minMaxLength": "元组 [CONTENT] [TYPE]",
    "gui.blocklyText.mpyTuple.TypeIndex.A": "最小值",
    "gui.blocklyText.mpyTuple.TypeIndex.B": "最大值",
    "gui.blocklyText.mpyTuple.TypeIndex.C": "长度",
    "gui.blocklyText.mpyTuple.isInclude": "元组 [CONTENT] 包含 [STRING]？",
    "gui.blocklyText.mpyTuple.getValueRange": "返回元组 [CONTENT] 取 [TYPE1] [NUM1] 项到 [TYPE2] [NUM2] 项",
    "gui.blocklyText.mpyTuple.IndexType.A": "第",
    "gui.blocklyText.mpyTuple.IndexType.B": "倒数第",
    "gui.blocklyText.mpyTuple.listToTulpe": "列表 [LIST] 转元组",
    "gui.blocklyText.mpyTuple.getValue": "元组 [CONTENT] 索引 [INDEX] 的值",
    "gui.blocklyText.mpyTuple.tupleToList": "元组 [CONTENT] 转变为列表",
    "gui.blocklyText.esp32.DisplayTypeMenu.A": "普通",
    "gui.blocklyText.esp32.DisplayTypeMenu.B": "反转",
    "gui.blocklyText.esp32.displayInnerImageInXY": "显示内置图像 [IMAGE] 到 x:[X] y:[Y] 模式 [TYPE]",
    "gui.blocklyText.esp32.displayFileSystemImageInXY": "从文件系统加载图像 [IMAGE] 显示到 x:[X] y:[Y] 模式 [TYPE]",
    "gui.blocklyText.weather.GetWeatherWithCityName": "获取[CITY]的[WEATHER]",
    "gui.blocklyText.pictureai.createFaceClass": "创建人脸库 [NAME]",
    "gui.blocklyText.pictureai.pictureaiAddFaceToClass": "命名识别结果为[RES]并添加至人脸库 [CLASSNAME]",
    "gui.blocklyText.pictureai.pictureaiSelectResultsFromClass": "在人脸库[CLASSNAME]中搜索识别结果可信度>=[NUMBER]",
    "gui.blocklyText.pictureai.pictureaiWhenSearchName": "当搜索到名字[NAME]时",
    "gui.blocklyText.pictureai.pictureaiSearchedName": "搜索结果名字",
    "gui.blocklyText.pictureai.pictureaiSelectResultReliability": "搜索结果可信度",
    "gui.blocklyText.pictureai.pleaseUseSelefAccount": "人脸库功能须切换至独立账户",
    "gui.blocklyText.pictureai.addfaceclasserror": "新建人脸库出错",
    "gui.blocklyText.pictureai.faceclassnameerror": "人脸库名称由数字、字母、下划线组成",
    "gui.blocklyText.pictureai.faceclassnameexist": "该人脸库名称已存在",
    "gui.blocklyText.pictureai.addfaceerror": "添加人脸出错",
    "gui.blocklyText.pictureai.selectfaceclasserror": "人脸库搜索出错",
    "gui.blocklyText.pictureai.notfoundmatch": "未找到匹配的人脸",
    "gui.blocklyText.pictureai.facenameerror": "人脸名称由数字、字母、下划线组成",
    "boost.color.any": "任何颜色",
    "boost.color.black": "黑色",
    "boost.color.blue": "蓝色",
    "boost.color.green": "绿色",
    "boost.color.red": "黄色",
    "boost.color.white": "白色",
    "boost.color.yellow": "黄色",
    "boost.getMotorPosition": "马达 [MOTOR_REPORTER_ID] 位置",
    "boost.getTiltAngle": "向[TILT_DIRECTION]倾角",
    "boost.motorDirection.backward": "逆时针",
    "boost.motorDirection.forward": "顺时针",
    "boost.motorDirection.reverse": "反转",
    "boost.motorOff": "关闭马达 [MOTOR_ID]",
    "boost.motorOn": "开启马达 [MOTOR_ID]",
    "boost.motorOnFor": "使马达 [MOTOR_ID]转动 [DURATION] 秒",
    "boost.motorOnForRotation": "使马达 [MOTOR_ID]转动 [ROTATION] 圈",
    "boost.seeingColor": "看到 [COLOR]积木 ?",
    "boost.setLightHue": "将灯光颜色设为[HUE]",
    "boost.setMotorDirection": "将马达[MOTOR_ID] 方向设为 [MOTOR_DIRECTION]",
    "boost.setMotorPower": "将马达 [MOTOR_ID] 速度设为 [POWER]%",
    "boost.tiltDirection.any": "任意方向",
    "boost.tiltDirection.down": "下",
    "boost.tiltDirection.left": "左",
    "boost.tiltDirection.right": "右",
    "boost.tiltDirection.up": "上",
    "boost.whenColor": "当看到[COLOR]积木",
    "boost.whenTilted": "当向[TILT_DIRECTION_ANY]倾斜",
    "ev3.beepNote": "鸣笛[NOTE][TIME]秒",
    "ev3.buttonPressed": "按下按钮[PORT]？",
    "ev3.getBrightness": "亮度",
    "ev3.getDistance": "距离",
    "ev3.getMotorPosition": "马达[PORT]的位置",
    "ev3.motorSetPower": "马达[PORT]功率设为[POWER]%",
    "ev3.motorTurnClockwise": "马达[PORT]正转[TIME]秒",
    "ev3.motorTurnCounterClockwise": "马达[PORT]反转[TIME]秒",
    "ev3.whenBrightnessLessThan": "当亮度 < [DISTANCE]",
    "ev3.whenButtonPressed": "当按下按钮[PORT]",
    "ev3.whenDistanceLessThan": "当距离 < [DISTANCE]",
    "wedo2.getDistance": "距离",
    "wedo2.getTiltAngle": "向[TILT_DIRECTION]倾角",
    "wedo2.isTilted": "向[TILT_DIRECTION_ANY]倾斜？",
    "wedo2.motorDirection.backward": "逆时针",
    "wedo2.motorDirection.forward": "顺时针",
    "wedo2.motorDirection.reverse": "反转",
    "wedo2.motorId.a": "马达A",
    "wedo2.motorId.all": "所有马达",
    "wedo2.motorId.b": "马达B",
    "wedo2.motorId.default": "马达",
    "wedo2.motorOff": "关闭[MOTOR_ID]",
    "wedo2.motorOn": "开启[MOTOR_ID]",
    "wedo2.motorOnFor": "开启[MOTOR_ID][DURATION]秒",
    "wedo2.playNoteFor": "演奏音符[NOTE][DURATION]秒",
    "wedo2.setLightHue": "将灯光颜色设为[HUE]",
    "wedo2.setMotorDirection": "将[MOTOR_ID]转向设为[MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "将[MOTOR_ID]功率设为[POWER]",
    "wedo2.tiltDirection.any": "任意方向",
    "wedo2.tiltDirection.down": "下",
    "wedo2.tiltDirection.left": "左",
    "wedo2.tiltDirection.right": "右",
    "wedo2.tiltDirection.up": "上",
    "wedo2.whenDistance": "当距离[OP][REFERENCE]",
    "wedo2.whenTilted": "当向[TILT_DIRECTION_ANY]倾斜",
    "gui.blocklyText.video.notfound": "无设备",
    "gui.blocklyText.machinelearning.drawResults": "[TOGGLE]绘制识别结果",
    "gui.blocklyText.machinelearning.initKNNImageClass": "初始化KNN分类器",
    "gui.blocklyText.machinelearning.imageClassificationFromCamera": "KNN将摄像头画面分类为[TEXT]",
    "gui.blocklyText.machinelearning.imageClassificationFromDir": "KNN将本地文件夹图片[DIR]分类为[TEXT]",
    "gui.blocklyText.machinelearning.startKNNTrain": "KNN开始分类训练",
    "gui.blocklyText.machinelearning.predictImageClass": "KNN[TOGGLE]识别摄像头画面分类",
    "gui.blocklyText.machinelearning.getImageClassification": "KNN识别分类结果",
    "gui.blocklyText.machinelearning.getImageClassificationReliability": "KNN识别可信度",
    "gui.blocklyText.machinelearning.clearImageClassificationLabel": "KNN清除标签[LABEL]",
    "gui.blocklyText.machinelearning.clearImageClassificationModel": "KNN清除分类模型数据",
    "gui.blocklyText.machinelearning.loadImageClassificationModel": "KNN从[PATH]加载电脑端分类模型",
    "gui.blocklyText.machinelearning.exportImageClassificationModel": "KNN保存分类模型",
    "gui.blocklyText.machinelearning.traceFace": "FaceAPI初始化人脸追踪",
    "gui.blocklyText.machinelearning.drawFaceAPI": "FaceAPI[TOGGLE]标记人脸关键点",
    "gui.blocklyText.machinelearning.traceFaceNumber": "FaceAPI人脸数目",
    "gui.blocklyText.machinelearning.getFaceByIndex": "FaceAPI获取第[INDEX]个人脸的[PART]坐标[TYPE]",
    "gui.blocklyText.machinelearning.initfaceContrast": "FaceAPI初始化特定人脸识别",
    "gui.blocklyText.machinelearning.refFaceFromCamera": "FaceAPI从摄像头采集人脸数据标记为[NAME]",
    "gui.blocklyText.machinelearning.refFaceFromDir": "FaceAPI从本地文件夹[DIR]采集人脸数据以文件夹名标记",
    "gui.blocklyText.machinelearning.faceAPIFindFace": "FaceAPI识别一次摄像头中的人脸",
    "gui.blocklyText.machinelearning.allowFaceAPIResult": "FaceAPI人脸识别识别结果",
    "gui.blocklyText.machinelearning.allowFaceAPIResultScore": "FaceAPI人脸识别识别结果可信度",
    "gui.blocklyText.machinelearning.clearFaceAPIModel": "FaceAPI清除人脸模型数据",
    "gui.blocklyText.machinelearning.ml5WhenSearchFaceName": "当搜索到标签[NAME]可信度>=[VALUE]%时",
    "gui.blocklyText.machinelearning.machinelearningTracePose": "PoseNet初始化姿态追踪",
    "gui.blocklyText.machinelearning.drawPoseNet": "PoseNet[TOGGLE]标记姿态关键点",
    "gui.blocklyText.machinelearning.getPoseByIndex": "PoseNet获取第[INDEX]个姿态的[PART]坐标[TYPE]",
    "gui.blocklyText.machinelearning.initImageClassify": "初始化物体识别图像分类器(mobileNet)",
    "gui.blocklyText.machinelearning.toggleImageClassify": "[TOGGLE]物体识别图像分类器 实时识别",
    "gui.blocklyText.machinelearning.imageClassifyResult": "物体识别识别结果",
    "gui.blocklyText.machinelearning.imageClassifyResultScore": "物体识别识别可信度",
    "gui.blocklyText.huskylens.initVisualModal": "初始化AI可视化工具为[TYPE]",
    "gui.blocklyText.huskylens.classifyImageFromCamera": "将电脑摄像头画面加入分类器[TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromHuskylens": "将HuskyLens摄像头画面加入分类器[TEXT]",
    "gui.blocklyText.huskylens.classifyImageFromDir": "将本地文件夹图片[DIR]加入分类器[TEXT]",
    "gui.blocklyText.huskylens.visualModalClearLabel": "清除分类[LABEL]",
    "gui.blocklyText.huskylens.visualModalClearAllLabels": "清除分类器所有分类",
    "gui.blocklyText.huskylens.deployClassificationToHuskylens": "训练模型并部署到HuskyLens",
    "gui.blocklyText.machinelearning.loadlmodel": "模型路径",
    "gui.blocklyText.pictureai.TOPIC_BODY_mouth": "嘴巴",
    "gui.blocklyText.pictureai.TOPIC_BODY_jaw_outline": "下颌",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye_brow": "左眼眉",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye_brow": "右眼眉",
    "gui.blocklyText.huskylens.videoContainer": "使用[CON]显示电脑摄像头画面",
    "gui.blocklyText.huskylens.videoToggle": "[VIDEO_STATE]电脑摄像头",
    "gui.blocklyText.huskylens.cameraToggle": "切换至电脑摄像头[CAMERA_LIST]",
    "gui.blocklyText.huskylens.exportImage": "电脑摄像头拍照一张保存到本地文件夹 [TEXT]",
    "gui.blocklyText.dialog.ml5.loadModel": "加载模型中，页面可能会出现卡顿，请稍候...",
    "gui.blocklyText.dialog.ml5.reInitPictureClassify": "分类器中已存在图片数据，重新初始化会清空现有数据，确认继续吗？",
    "gui.blocklyText.dialog.ml5.whenDeleteImageAfterTrain": "该图片数据已经经过训练，删除后，需要重新训练才能生效",
    "gui.blocklyText.dialog.ml5.renameLabel": "标签[LABEL]已存在，是否合并?",
    "gui.blocklyText.dialog.ml5.modelIsEmpty": "该模型中无分类数据，请先训练模型",
    "gui.blocklyText.dialog.ml5.shouldInitKNN": "请先初始化KNN分类器",
    "gui.blocklyText.dialog.huskylens.emptyAllImage": "该操作会删除所有分类，确认继续吗?",
    "gui.blocklyText.dialog.huskylens.HuskyLensTakePhotoError": "从HuskyLens拍照失败，请重新尝试",
    "gui.blocklyText.dialog.huskylens.overImageMaxLen": "[TYPE]分类器数量上限为[LEN]张，已超出上限，请整理后重试",
    "gui.blocklyText.dialog.huskylens.notAllowChinese": "分类名称应由不超过50位的数字和字母组成，不能含有中文及特殊字符",
    "gui.blocklyText.dialog.ml5.isNotKNN": "人脸识别类型的分类器无法加入KNN训练",
    "gui.blocklyText.machinelearning.start": "开始",
    "gui.blocklyText.machinelearning.stop": "停止",
    "gui.blocklyText.huskyLensPro.ML5MODEL.A": "KNN分类",
    "gui.blocklyText.huskyLensPro.ML5MODEL.B": "人脸识别",
    "gui.blocklyText.huskyLensPro.changeAlgorithm": "HuskyLens 切换到 [ALGORITHM] 算法 直到成功",
    "gui.blocklyText.huskyLensPro.learndIDCount": "HuskyLens 获取已学习ID总数",
    "gui.blocklyText.huskyLensPro.isOnTheScreen": "HuskyLens [BOXARROW] 在画面中？",
    "gui.blocklyText.huskyLensPro.parametersNearTheCenter": "HuskyLens 获取靠近中心 [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.idIsLearned": "HuskyLens ID [ID] 已经学习？",
    "gui.blocklyText.huskyLensPro.idIsOnTheScreen": "HuskyLens ID [ID] [BOXARROW] 在画面中？",
    "gui.blocklyText.huskyLensPro.idParametersNearTheCenter": "HuskyLens ID [ID] [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.boxCount": "HuskyLens 获取 [BOXARROW] 的总数",
    "gui.blocklyText.huskyLensPro.boxarrowParameter": "HuskyLens 获取第 [INDEX] 个 [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.idBoxCount": "HuskyLens 获取ID [ID] [BOXARROW] 的总数",
    "gui.blocklyText.huskyLensPro.idBoxarrowParameter": "HuskyLens 获取ID [ID] 第 [INDEX] 个 [BOXARROW] 的参数 [PARAMETER]",
    "gui.blocklyText.huskyLensPro.learnOnce": "HuskyLens 当前算法自动学习一次ID [ID]",
    "gui.blocklyText.huskyLensPro.clearLearn": "HuskyLens 遗忘当前算法的所有学习数据",
    "gui.blocklyText.huskyLensPro.setIDname": "HuskyLens 设置当前算法ID [ID] 名字为 [NAME]",
    "gui.blocklyText.huskyLensPro.writeOSD": "HuskyLens 屏幕叠加显示文字 [NAME] 在x [X] y [Y]",
    "gui.blocklyText.huskyLensPro.clearOSD": "HuskyLens 清除屏幕显示的文字",
    "gui.blocklyText.huskyLensPro.requestPicture": "HuskyLens [TYPE] 并保存到本地文件夹 [PATH]",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.A": "拍照",
    "gui.blocklyText.huskyLensPro.TAKESCREENSHOT.B": "截屏",
    "gui.blocklyText.huskyLensPro.TakeScreenshotToSDCard": "HuskyLens 触发 [TYPE] 保存到SD卡",
    "gui.blocklyText.huskyLensPro.xCenter": "X中心",
    "gui.blocklyText.huskyLensPro.yCenter": "Y中心",
    "gui.blocklyText.huskyLensPro.width": "宽度",
    "gui.blocklyText.huskyLensPro.height": "高度",
    "gui.blocklyText.huskyLensPro.xStart": "X起点",
    "gui.blocklyText.huskyLensPro.yStart": "Y起点",
    "gui.blocklyText.huskyLensPro.xEnd": "X终点",
    "gui.blocklyText.huskyLensPro.yEnd": "Y终点",
    "gui.blocklyText.tello.telloSetPitchRollYaw": "设置遥控器四个通道杆量 横滚[ROLL] 俯仰[PITCH] 油门[STRENGTH] 偏航[YAW]",
    "gui.blocklyText.tello.telloQuerySpeed": "当前速度",
    "gui.blocklyText.tello.telloTime": "电机运行时间(s)",
    "gui.blocklyText.tello.telloWIFISign": "Wi-Fi信噪比",
    "gui.blocklyText.tello.telloSDKVersion": "Tello SDK版本",
    "gui.blocklyText.tello.telloSN": "Tello SN码",
    "gui.blocklyText.tello.telloFlyToXY": "飞往(相对于飞机的) x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s",
    "gui.blocklyText.tello.reset": "重置",
    "gui.blocklyText.tello.telloFlyCurve": "飞弧线经 x1[X1]cm y1[Y1]cm z1[Z1]cm 和 x2[X2]cm y2[Y2]cm z2[Z2]cm 速度[SPEED]cm/s",
    "gui.blocklyText.tello.telloStopEmerygency": "紧急停机",
    "gui.blocklyText.tello.telloGetHeight": "相对高度(cm)",
    "gui.blocklyText.tello.telloBarometer": "气压计高度(m)",
    "gui.blocklyText.tello.telloTofDistance": "TOF高度(cm)",
    "gui.blocklyText.tello.telloTempMin": "主板最低温度(°C)",
    "gui.blocklyText.tello.telloTempMax": "主板最高温度(°C)",
    "gui.blocklyText.tello.TelloAttitudePitch": "俯仰轴姿态角(°)",
    "gui.blocklyText.tello.TelloAttitudeRoll": "横滚轴姿态角(°)",
    "gui.blocklyText.tello.TelloAttitudeYaw": "平移轴姿态角(°)",
    "gui.blocklyText.tello.telloAccelerationX": "X轴加速度(0.001g)",
    "gui.blocklyText.tello.telloAccelerationY": "Y轴加速度(0.001g)",
    "gui.blocklyText.tello.telloAccelerationZ": "Z轴加速度(0.001g)",
    "gui.blocklyText.tello.telloSpeedX": "X轴速度(cm/s)",
    "gui.blocklyText.tello.telloSpeedY": "Y轴速度(cm/s)",
    "gui.blocklyText.tello.telloSpeedZ": "Z轴速度(cm/s)",
    "gui.blocklyText.tello.telloFlyToMissionPad": "飞往挑战卡的 x[X]cm y[Y]cm z[Z]cm 速度[SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.TelloFlyCurveMissionPad": "飞弧线经挑战卡下 x1[X1]cm y1[Y1]cm z1[Z1]cm 和 x2[X2]cm y2[Y2]cm z2[Z2]cm 速度[SPEED]cm/s Mid[MID]",
    "gui.blocklyText.tello.telloJumpInMissionPad": "跳跃沿挑战卡 x[X]cm y[Y]cm z[Z]cm 速度 [SPEED]cm/s 偏航 [YAW] 度 从 Mid1 [MID1] 到 Mid2 [MID2]",
    "gui.blocklyText.tello.telloRotateWithYaw": "飞机相对挑战卡[MID]旋转至[YAW]角度",
    "gui.blocklyText.tello.telloStopAndHover": "停止运动并悬停",
    "gui.blocklyText.telloesp32.telloGetStatus": "获取一次状态码存入结果, 超时 [TIMEOUT] 毫秒",
    "gui.blocklyText.telloesp32.telloReturnStatusChallenge": "从结果中取 [STATUSCHALLENGE]",
    "gui.blocklyText.telloesp32.statusChallengeX": "相对挑战卡X坐标",
    "gui.blocklyText.telloesp32.statusChallengeY": "相对挑战卡Y坐标",
    "gui.blocklyText.telloesp32.statusChallengeZ": "相对挑战卡Z坐标",
    "gui.blocklyText.telloesp32.statusChallengePitch": "相对挑战卡俯仰角",
    "gui.blocklyText.telloesp32.statusChallengeYaw": "相对挑战卡偏航角",
    "gui.blocklyText.telloesp32.statusChallengeRoll": "相对挑战卡横滚角",
    "gui.blocklyText.telloesp32.statusChallengeId": "挑战卡ID",
    "gui.blocklyText.telloesp32.telloReturnStatusAttitude": "从结果中取 [STATUSATTITUDE]",
    "gui.blocklyText.telloesp32.statusAttitudePitch": "俯仰角",
    "gui.blocklyText.telloesp32.statusAttitudeYaw": "偏航角",
    "gui.blocklyText.telloesp32.statusAttitudeRoll": "横滚角",
    "gui.blocklyText.telloesp32.statusAttitudeXSpeed": "X轴速度",
    "gui.blocklyText.telloesp32.statusAttitudeYSpeed": "Y轴速度",
    "gui.blocklyText.telloesp32.statusAttitudeZSpeed": "Z轴速度",
    "gui.blocklyText.telloesp32.statusAttitudeXAcc": "X轴加速度",
    "gui.blocklyText.telloesp32.statusAttitudeYAcc": "Y轴加速度",
    "gui.blocklyText.telloesp32.statusAttitudeZAcc": "Z轴加速度",
    "gui.blocklyText.telloesp32.telloReturnStatusNormal": "从结果中取 [STATUSNORMAL]",
    "gui.blocklyText.telloesp32.statusNormalMaxTemp": "最高温度",
    "gui.blocklyText.telloesp32.statusNormalLowTemp": "最低温度",
    "gui.blocklyText.telloesp32.statusNormalTof": "ToF距离",
    "gui.blocklyText.telloesp32.statusNormalHeight": "相对高度",
    "gui.blocklyText.telloesp32.statusNormalBat": "当前电量",
    "gui.blocklyText.telloesp32.statusNormalBaro": "气压高度",
    "gui.blocklyText.telloesp32.statusNormalMotor": "电机时间",
    "gui.blocklyText.tello.MissionPad": "挑战卡编号",
    "gui.blocklyText.tello.MissionPadX": "挑战卡X(cm)",
    "gui.blocklyText.tello.MissionPadY": "挑战卡Y(cm)",
    "gui.blocklyText.tello.telloMissionPadZ": "挑战卡Z(cm)",
    "gui.blocklyText.tello.telloCommandResponse": "回复指令",
    "gui.blocklyText.tello.direction_front": "前视",
    "gui.blocklyText.tello.direction_blow": "下视",
    "gui.blocklyText.tello.direction_all": "所有",
    "gui.blocklyText.tello.mid": "挑战卡",
    "gui.blocklyText.tello.midfastest": "m-1",
    "gui.blocklyText.tello.midlatest": "m-2",
    "gui.blocklyText.tello.telloToggleMon": "[TOGGLE]挑战卡探测",
    "gui.blocklyText.tello.telloSetMDireaction": "设置探测位置[POSITION]",
    "gui.blocklyText.tello.telloSetWIFI": "修改Tello的Wi-Fi为热点[NAME] 密码[PASSWD]",
    "gui.blocklyText.tello.telloToggleGroup": "切换为station模式，连入热点[NAME] 密码[PASSWD]",
    "gui.blocklyText.huskyLensPro.QRRecognition": "二维码识别",
    "gui.blocklyText.huskyLensPro.barcodeRecognition": "条形码识别",
    "gui.blocklyText.huskyLensPro.saveFailed": "保存图片失败",
    "gui.blocklyText.huskyLensPro.yes": "是的",
    "gui.blocklyText.huskyLensPro.cancel": "取消",
    "gui.blocklyText.huskyLensPro.promptInitializeClassifier": "请先初始化图片分类器",
    "gui.blocklyText.huskyLensPro.promptInitializeKNNClassifier": "请先初始化KNN分类器",
    "gui.blocklyText.huskyLensPro.promptAddClassificationData": "请先添加分类数据",
    "gui.blocklyText.huskyLensPro.setTitleText": "HuskyLens AI-KNN图像分类",
    "gui.blocklyText.huskyLensPro.tooManyPictures": "图片数目太多，仅提取前五十张",
    "gui.blocklyText.huskyLensPro.checkPath": "请检查路径[PATH]下是否包含图片",
    "gui.blocklyText.huskyLensPro.success": "成功",
    "gui.blocklyText.huskyLensPro.importImg": "本次成功导入[COUNT1]张图片，[COUNT2]张失败",
    "gui.blocklyText.huskyLensPro.recognitionResult": "识别结果为：",
    "gui.blocklyText.telloGroup.telloRise": "上升",
    "gui.blocklyText.telloGroup.telloDown": "下降",
    "gui.blocklyText.telloGroup.telloGoahead": "前进",
    "gui.blocklyText.telloGroup.telloBack": "后退",
    "gui.blocklyText.telloGroup.telloTurnLeft": "向左",
    "gui.blocklyText.telloGroup.telloTurnRight": "向右",
    "gui.blocklyText.telloGroup.telloSetFly": "[DIRECTION]  [NUMS]cm",
    "gui.blocklyText.telloGroup.telloClockwiseRotation": "顺时针",
    "gui.blocklyText.telloGroup.telloCounterclockwiseSelection": "逆时针",
    "gui.blocklyText.telloGroup.telloClockRotation": "[CLOCKWISE] 旋转 [NUMS]度",
    "gui.blocklyText.telloGroup.setTelloIndexWithSN": "设置Tello SN码[SN] 编号为 [INDEX]",
    "gui.blocklyText.telloGroup.setTelloIndexWithSSID": "设置Tello SSID [SSID] 编号为 [INDEX]",
    "gui.blocklyText.telloGroup.scanTelloInNetwork": "在网络中扫描 [NUM] 台Tello 超时[TIME]秒",
    "gui.blocklyText.telloGroup.telloSynchronousCommand": "同步指令 等待时间[TIME]秒",
    "gui.blocklyText.tello.telloVideoToggle": "[VIDEO_STATE] Tello 摄像头",
    "gui.blocklyText.telloGroup.TEMPH": "最高温度",
    "gui.blocklyText.telloGroup.TEMPL": "最低温度",
    "gui.blocklyText.telloGroup.NUM": "编号",
    "gui.blocklyText.telloGroup.pitch": "俯仰轴",
    "gui.blocklyText.telloGroup.roll": "横滚轴",
    "gui.blocklyText.telloGroup.translation": "平移轴",
    "gui.blocklyText.telloGroup.telloAccelerationXYZ": "[XYZ]轴加速度(0.001g)",
    "gui.blocklyText.telloGroup.telloSpeedXYZ": "[XYZ]轴速度(cm/s)",
    "gui.blocklyText.telloGroup.TelloAttitudePitch": "[ANGEL]姿态角(°)",
    "gui.blocklyText.telloGroup.telloTempMinAndMax": "主板[TEMP](°C)",
    "gui.blocklyText.tello.telloDisplayRollText": "滚动显示文字 方向[DIRECT] 移动频率[SPEED] 文字[TEXT] 颜色[COLOR]",
    "gui.blocklyText.tello.telloDisplayText": "显示单个字符 [TEXT] 颜色[COLOR]",
    "gui.blocklyText.tello.telloRollText_up": "向上",
    "gui.blocklyText.tello.telloRollText_down": "向下",
    "gui.blocklyText.tello.telloRollText_left": "向左",
    "gui.blocklyText.tello.telloRollText_right": "向右",
    "gui.blocklyText.telloGroup.initTelloGroup": "断开连接",
    "gui.blocklyText.tello.shutdownForPower": "电量已不足[POWER]，Tello即将关机",
    "gui.blocklyText.tello.portCantUse": "服务出错，端口[PORT]访问失败, 请检查是否被其他程序占用",
    "gui.blocklyText.tello.telloReadESP32Version": "扩展模块固件版本",
    "gui.blocklyText.tello.telloSSID": "扩展模块SSID",
    "gui.blocklyText.tello.telloLightLEDWithColor": "LED颜色[COLOR]",
    "gui.blocklyText.tello.telloLightLEDWithColor_rgb": "LED颜色 红:[RGBR] 绿:[RGBG] 蓝:[RGBB]",
    "gui.blocklyText.tello.telloBreathLEDWithColor": "LED呼吸灯 颜色[COLOR]频率[TIME](Hz)",
    "gui.blocklyText.tello.telloBreathLEDWithColor_rgb": "LED呼吸灯 红:[RGBR] 绿:[RGBG]  蓝:[RGBB] 频率:[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor": "LED闪烁 颜色1[COLOR1]和颜色2[COLOR2]频率[TIME](Hz)",
    "gui.blocklyText.tello.telloFlickerLEDWithColor_rgb": "LED闪烁 红1:[RGBR1] 绿1:[RGBG1] 蓝1:[RGBB1] - 红2:[RGBR2] 绿2:[RGBG2] 蓝2:[RGBB2] 频率:[TIME](Hz)",
    "gui.blocklyText.tello.telloShowPattern": "显示图案[MARK]",
    "gui.blocklyText.tello.telloShowPatternWhenStart": "设定开机图案[MARK]",
    "gui.blocklyText.tello.telloShowRollPattern": "滚动显示图案 方向[DIRECT] 移动频率[SPEED] 图案[MARK]",
    "gui.blocklyText.tello.telloSetLight": "设置屏幕亮度[NUM]",
    "gui.blocklyText.tello.telloSendCMD": "发送自定义命令[CMD]",
    "gui.blocklyText.tello.telloSendDirective": "发送明码消息[CMD]",
    "gui.blocklyText.tello.telloReceiveMsg": "接收到字符串消息[MSG]",
    "gui.blocklyText.tello.telloReceiveCustomNum": "接收到自定义回复数值",
    "gui.blocklyText.tello.connectTelloWIFIError": "连接Tello Wi-Fi失败，请尝试手动连接Wi-Fi",
    "gui.blocklyText.tello.connectTelloError": "连接Tello失败，请确认设备状态，重新连接。你也可以尝试手动连接Wi-Fi",
    "gui.blocklyText.tello.tempIsHigh": "Tello温度过高，即将关机",
    "gui.blocklyText.tello.scanErrorWithOpenWlan": "扫描出错了，请检查是否打开Wi-Fi,你也可以尝试手动连接Wi-Fi",
    "gui.blocklyText.tello.noTelloFound": "暂无可用的Tello设备，你也可以尝试手动连接Wi-Fi",
    "gui.blocklyText.tello.connectIsBroke": "Tello连接已断开，请检查设备",
    "gui.blocklyText.tello.firstConnectTello": "请先连接Tello",
    "gui.blocklyText.tello.telloQueryWifiVersion": "扩展模块Wi-Fi版本",
    "gui.blocklyText.tello.telloQueryHardwareVersion": "获取硬件版本",
    "gui.blocklyText.tello.telloToggleTOF": "[TOGGLE] TOF模组",
    "gui.blocklyText.tello.telloToggleTOF_enable": "启用",
    "gui.blocklyText.tello.telloToggleTOF_disabled": "停用",
    "gui.blocklyText.tello.activeTelloFirst": "连接失败，新飞机未激活，请按照飞机说明书进行激活",
    "gui.blocklyText.tello.startPaddle": "进入起桨模式",
    "gui.blocklyText.tello.stopPaddle": "退出起桨模式",
    "gui.blocklyText.tello.togglePaddle": "[TOGGLE]起桨模式",
    "gui.blocklyText.tello.telloTogglePaddle_enable": "进入",
    "gui.blocklyText.tello.telloTogglePaddle_disabled": "退出",
    "gui.blocklyText.tello.telloThrowAway": "5秒内抛飞",
    "gui.blocklyText.tello.telloResetPattern": "恢复默认开机图案",
    "gui.blocklyText.tello.telloQueryTelloVersion": "Tello固件版本",
    "gui.blocklyText.tello.telloQueryBatActive": "Tello 查询电池电量",
    "gui.blocklyText.tello.telloReadTOF": "读取TOF测距 (mm)",
    "gui.blocklyText.telloGroup.allTellos": "所有的",
    "gui.blocklyText.tello.notAllowInput": "请输入数字或英文字符,不能包含其它特殊字符",
    "gui.blocklyText.tello.checkFirewallAndTello": "连接已断开，请检查tello连接状态，同时请将软件加入电脑防火墙白名单",
    "gui.blocklyText.telloGroup.checkWlanNum": "请确认本机连接到正确的网络，若已连接到多个网络，请先断开其他连接",
    "gui.blocklyText.telloGroup.telloIsNotExist": "[INDEX]号Tello不存在",
    "gui.blocklyText.telloGroup.findTelloWithLost": "找到[FOUND]个,[NOTFOUND]未找到,ip:[IP]",
    "gui.blocklyText.telloGroup.findTello": "找到[FOUND]个,ip:[IP]",
    "gui.blocklyText.telloGroup.cantRepeatScan": "正在扫描中，请勿重复扫描..",
    "gui.blocklyText.telloGroup.setIndexForTelloFirst": "请先设置编号",
    "gui.blocklyText.maixduino.programMain": "K210 Maixduino 主程序",
    "gui.blocklyText.maixduino.LCDInit": "屏幕初始化为频率 [FREQ] 背景 [COLOR] 反色 [INVERT]",
    "gui.blocklyText.maixduino.LCDShowImageFromPath": "屏幕显示图像 path:[PATH]",
    "gui.blocklyText.maixduino.LCDShowImageFromImage": "屏幕显示图像 image:[IMAGE]",
    "gui.blocklyText.maixduino.LCDShowText": "屏幕显示文字 [TEXT] 在坐标X [X] Y [Y] 文字 [TEXT_COLOR] 背景 [BACKGROUND_COLOR]",
    "gui.blocklyText.maixduino.LCDFill": "屏幕显示为 [COLOR] (清屏)",
    "gui.blocklyText.maixduino.LCDRotation": "屏幕旋转 [ANGLE]",
    "gui.blocklyText.maixduino.LCDMirror": "屏幕镜像 [MIRROR]",
    "gui.blocklyText.maixduino.LCDSize": "屏幕分辨率 [SIZE]",
    "gui.blocklyText.maixduino.LCDRGB888": "RGB888 红 [RED] 绿 [GREEN] 蓝 [BLUE]",
    "gui.blocklyText.maixduino.LCDColor": "颜色 [COLOR]",
    "gui.blocklyText.blockTitle.maixduino.Camera": "摄像头",
    "gui.blocklyText.maixduino.CameraInit": "初始化为 [MODE] 参数 [SETTINGS]",
    "gui.blocklyText.maixduino.CameraGetImage": "相机获取图像",
    "gui.blocklyText.maixduino.CameraControl": "选择 [CONTROL] 摄像头(双目)",
    "gui.blocklyText.maixduino.CameraHorizontalMirroring": "相机 [CONTROL] 水平镜像",
    "gui.blocklyText.maixduino.CameraVerticalMirroring": "相机 [CONTROL] 垂直镜像",
    "gui.blocklyText.maixduino.CameraColorBarMode": "相机 [CONTROL] 彩条模式",
    "gui.blocklyText.maixduino.CameraSettingParameters": "相机设置 [PARAMETERS] 为 [VALUE]",
    "gui.blocklyText.maixduino.CameraSettingWindow": "相机设置窗口 宽为[WIDTH] 高为[HEIGHT]",
    "gui.blocklyText.maixduino.CameraGetParameters": "相机获取 [PARAMETERS]",
    "gui.blocklyText.blockTitle.audioAndVideo": "音视频",
    "gui.blocklyText.maixduino.AudioPlay": "音频播放 [PATH] [WAIT]",
    "gui.blocklyText.maixduino.AudioVolume": "音频音量 [VOLUME] %",
    "gui.blocklyText.maixduino.AudioState": "获取音频播放状态",
    "gui.blocklyText.maixduino.AudioRecord": "音频录制 [PATH] 时长 [TIME] s [WAIT]",
    "gui.blocklyText.maixduino.VideoPlay": "视频播放 [PATH] [WAIT]",
    "gui.blocklyText.maixduino.VideoVolume": "视频音量 [VOLUME] %",
    "gui.blocklyText.maixduino.VideoState": "获取视频播放状态",
    "gui.blocklyText.maixduino.VideoRecord": "视频录制 [PATH] 时长 [TIME] s [WAIT]",
    "gui.blocklyText.maixduino.digitalRead": "读取数字引脚 [PIN]",
    "gui.blocklyText.maixduino.analogRead": "读取模拟引脚 [PIN]",
    "gui.blocklyText.maixduino.digitalWrite": "设置数字引脚 [PIN] 输出 [LEVEL]",
    "gui.blocklyText.maixduino.PWMInit": "初始化PWM引脚 [PIN] 频率 [FREQ] Hz 定时器 [TIMER]",
    "gui.blocklyText.maixduino.PWMWrite": "设置PWM引脚 [PIN] 输出占空比 [DUTY]",
    "gui.blocklyText.maixduino.ticksDiff": "返回运行时间差，开始时间 [STARTTIME] 结束时间 [ENDTIME]",
    "gui.blocklyText.maixduino.runTimer": "运行时间 [TIMETYPE]",
    "gui.blocklyText.maixduino.gc": "[GC] 垃圾回收",
    "gui.blocklyText.maixduino.systemReset": "复位",
    "gui.blocklyText.maixduino.print": "打印 [DATA]",
    "gui.blocklyText.maixduino.LevelMenu.A": "高电平",
    "gui.blocklyText.maixduino.LevelMenu.B": "低电平",
    "gui.blocklyText.maixduino.MirrorMenu.A": "开启",
    "gui.blocklyText.maixduino.MirrorMenu.B": "关闭",
    "gui.blocklyText.maixduino.SelectMenu.A": "0号",
    "gui.blocklyText.maixduino.SelectMenu.B": "1号",
    "gui.blocklyText.maixduino.SettingParametersMenu.A": "对比度",
    "gui.blocklyText.maixduino.SettingParametersMenu.B": "亮度值",
    "gui.blocklyText.maixduino.SettingParametersMenu.C": "增益值",
    "gui.blocklyText.maixduino.SettingParametersMenu.D": "饱和度",
    "gui.blocklyText.maixduino.GetParametersMenu.A": "分辨率宽度",
    "gui.blocklyText.maixduino.GetParametersMenu.B": "分辨率高度",
    "gui.blocklyText.maixduino.GetParametersMenu.C": "当前增益值",
    "gui.blocklyText.maixduino.GetParametersMenu.D": "帧缓存图像",
    "gui.blocklyText.maixduino.GetParametersMenu.E": "硬件型号ID",
    "gui.blocklyText.maixduino.TimeTypeMenu.A": "秒",
    "gui.blocklyText.maixduino.TimeTypeMenu.B": "毫秒",
    "gui.blocklyText.maixduino.TimeTypeMenu.C": "微秒",
    "gui.blocklyText.maixduino.GcMenu.A": "自动",
    "gui.blocklyText.maixduino.GcMenu.B": "运行",
    "gui.blocklyText.maixduino.LcdSizeMenu.A": "宽度",
    "gui.blocklyText.maixduino.LcdSizeMenu.B": "高度",
    "gui.blocklyText.maixduino.WaitMenu.A": "等待",
    "gui.blocklyText.maixduino.WaitMenu.B": "后台",
    "gui.blocklyText.maixduino.CameraModeMenu.A": "单目",
    "gui.blocklyText.maixduino.CameraModeMenu.B": "双目",
    "gui.blocklyText.maixduino.AngleMenu.A": "0°",
    "gui.blocklyText.maixduino.AngleMenu.B": "90°",
    "gui.blocklyText.maixduino.AngleMenu.C": "180°",
    "gui.blocklyText.maixduino.AngleMenu.D": "270°",
    "gui.blocklyText.maixduino.camerainit.frameformat": "帧格式",
    "gui.blocklyText.maixduino.camerainit.frameformat.RGB565": "彩色图",
    "gui.blocklyText.maixduino.camerainit.frameformat.GRAYSCALE": "灰度图",
    "gui.blocklyText.maixduino.camerainit.framesize": "帧大小",
    "gui.blocklyText.maixduino.camerainit.imagecapture": "图像捕捉",
    "gui.blocklyText.maixduino.camerainit.imagecapture.on": "开启",
    "gui.blocklyText.maixduino.camerainit.imagecapture.off": "关闭",
    "gui.blocklyText.maixduino.camerainit.skipframes": "跳帧数",
    "gui.extension.AI.name": "人工智能",
    "gui.blocklyText.AI.anchorPoint": "锚点参数 [POINT]",
    "gui.blocklyText.AI.classNames": "物品名称 [NAMES]",
    "gui.blocklyText.AI.modelLoadingAddress": "模型加载 [ADDRESS]",
    "gui.blocklyText.AI.modelLoadingPath": "模型路径 [PATH]",
    "gui.blocklyText.AI.yolo2Init": "yolo2 初始化 网络模型 [MODEL] 概率阈值 [PT] box_iou门限 [BT] 锚点数[APC] 锚点参数 [AP]",
    "gui.blocklyText.AI.yolo2Run": "yolo2 运行网络 模型 [MODEL] 图像 [IMAGE]",
    "gui.blocklyText.AI.yolo2forward": "yolo2 向前运算 模型 [MODEL] 图像 [IMAGE]",
    "gui.blocklyText.AI.yolo2Parsing": "yolo2 模型解析 对象 [OBJ] 获取 [TYPE]",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.A": "X坐标",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.B": "Y坐标",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.C": "标识号",
    "gui.blocklyText.AI.Yolo2ParsingTypeMenu.D": "置信度",
    "gui.blocklyText.machineHearing.newModel": "ASR 新建空白模型",
    "gui.blocklyText.machineHearing.training": "ASR 开始训练 语料 [CORPUS] 添加到模型 [MODEL]",
    "gui.blocklyText.machineHearing.run": "ASR 运行模型 [MODEL]",
    "gui.blocklyText.machineHearing.recognize": "ASR 语音识别结果",
    "gui.blocklyText.machineHearing.save": "ASR 保存模型 [MODEL] 到 [PATH]",
    "gui.blocklyText.machineHearing.load": "ASR 加载模型 [PATH]",
    "gui.extension.machineVision.imageBasis.name": "机器视觉 · 图像基础",
    "gui.blocklyText.machineVision.openEmptyImage": "打开空图",
    "gui.blocklyText.machineVision.openPathImage": "打开路径 [PATH]",
    "gui.blocklyText.machineVision.clearImage": "清除图像 [IMAGE]",
    "gui.blocklyText.machineVision.saveImage": "保存图像 [IMAGE] 路径 [PATH]",
    "gui.blocklyText.machineVision.modifyPixelColor": "修改图像 [IMAGE] 坐标xy [COORDINATE] 颜色 [COLOR]",
    "gui.blocklyText.machineVision.getPixelColor": "获取图像 [IMAGE] 坐标-xy [COORDINATE] 颜色",
    "gui.blocklyText.machineVision.captureImage": "截取图像 [IMAGE] 区域-xywh [AREA]",
    "gui.blocklyText.machineVision.compressedImage": "压缩图像 [IMAGE] 压缩率 [RATIO]",
    "gui.blocklyText.machineVision.getImageInfo": "获取图像 [IMAGE] [INFO]",
    "gui.blocklyText.machineVision.conversion": "将图像 [IMAGE] 转换为 [TYPE]",
    "gui.extension.machineVision.imageDrawing.name": "机器视觉 · 图像绘制",
    "gui.blocklyText.machineVision.drawText": "绘制文本 图像 [IMAGE] 起点-xy [COORDINATE] 内容 [CONTENT] 颜色 [COLOR] 字号 [SIZE]",
    "gui.blocklyText.machineVision.drawImage": "绘制图像 图像 [IMAGE] 坐标-xy [COORDINATE] 缩放-xy [ZOOM]",
    "gui.blocklyText.machineVision.drawLine": "绘制直线 图像 [IMAGE] 坐标-xyxy [COORDINATE] 颜色 [COLOR] 粗细 [SIZE]",
    "gui.blocklyText.machineVision.drawArrows": "绘制箭头 图像 [IMAGE] 坐标-xyxy [COORDINATE] 颜色 [COLOR] 大小 [SIZE]",
    "gui.blocklyText.machineVision.drawCross": "绘制十字 图像 [IMAGE] 坐标-xy [COORDINATE] 颜色 [COLOR] 延伸 [EXTEND] 粗细 [SIZE]",
    "gui.blocklyText.machineVision.drawRound": "绘制圆形 图像 [IMAGE] 坐标-xyr [COORDINATE] 颜色 [COLOR] 粗细 [SIZE] 填充 [FILLING]",
    "gui.blocklyText.machineVision.drawRectangle": "绘制矩形 图像 [IMAGE] 坐标-xywh [COORDINATE] 颜色 [COLOR] 粗细 [SIZE] 填充 [FILLING]",
    "gui.blocklyText.machineVision.drawFeaturePoints": "绘制特征点 图像 [IMAGE] 对象 [OBJ] 颜色 [COLOR] 大小 [SIZE] 粗细 [THICKNESS] 填充 [FILLING]",
    "gui.extension.machineVision.imageFiltering.name": "机器视觉 · 图像滤波",
    "gui.blocklyText.machineVision.correctionZoom": "校正缩放 图像 [IMAGE] 校正值 [CORRECTION] 缩放值 [ZOOM]",
    "gui.blocklyText.machineVision.histogramEqualization": "直方图均衡 图像 [IMAGE] 自适应 [ADAPTIVE] 对比度 [CONTRAST]",
    "gui.blocklyText.machineVision.fuzzyFilter": "模糊滤波 图像 [IMAGE] 卷积核 [CONVOLUTIONKERNEL] 自适应 [ADAPTIVE] 补偿 [MAKEUP]",
    "gui.blocklyText.machineVision.cartoonFilter": "卡通滤波 图像 [IMAGE] 原始差异 [ORIGINALDIFFERENCE] 相邻差异 [ADJACENTDIFFERENCE]",
    "gui.blocklyText.machineVision.imageCorrosion": "图像腐蚀 图像 [IMAGE] 卷积核 [CONVOLUTIONKERNEL] 阈值 [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageExpansion": "图像膨胀 图像 [IMAGE] 卷积核 [CONVOLUTIONKERNEL] 阈值 [THRESHOLD]",
    "gui.blocklyText.machineVision.ImageFill": "图像填充 图像 [IMAGE] 坐标-xy [COORDINATE] 颜色 [COLOR] 原始差异 [ORIGINALDIFFERENCE] 相邻差异 [ADJACENTDIFFERENCE] 反转 [REVERSE] 清除 [CLEAR]",
    "gui.blocklyText.machineVision.ImageProjection": "图像投影 图像 [IMAGE] 垂直投影 [VERTICALPROJECTION]",
    "gui.blocklyText.machineVision.ImageInversion": "图像反转 图像 [IMAGE]",
    "gui.extension.machineVision.shapeRecognition.name": "机器视觉 · 形状识别",
    "gui.blocklyText.machineVision.straightLineAnalysis": "直线解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.circularAnalysis": "圆形解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.rectangleAnalysis": "矩形解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.lineRecognition": "直线识别 图像 [IMAGE] 区域-xywh [AREA] 阈值 [THRESHOLD] 合并角度 [MERGEANGLE] 合并斜率 [MERGESLOPE]",
    "gui.blocklyText.machineVision.lineSegmentRecognition": "线段识别 图像 [IMAGE] 区域-xywh [AREA] 间距 [SPACING] 合并角度 [MERGEANGLE]",
    "gui.blocklyText.machineVision.circleRecognition": "圆形识别 图像 [IMAGE] 区域-xywh [AREA] 阈值 [THRESHOLD] 最小r [MINIMUM] 最大r [MAXIMUM] 步长r [STRIDE] 合并x值 [COMBINEDX] 合并y值 [COMBINEDY] 合并r值 [COMBINEDR]",
    "gui.blocklyText.machineVision.rectangleRecognition": "矩形识别 图像 [IMAGE] 区域-xywh [AREA] 阈值 [THRESHOLD]",
    "gui.extension.machineVision.colorRecognition.name": "机器视觉 · 颜色识别",
    "gui.blocklyText.machineVision.LABFromRGB888": "LAB值 由RGB888 [RGB888]",
    "gui.blocklyText.machineVision.RGB888FromLAB": "RGB888值 由LAB [LAB]",
    "gui.blocklyText.machineVision.grayscaleFromRGB888": "灰度值 由RGB888 [RGB888]",
    "gui.blocklyText.machineVision.GRB888FromGrayscale": "RGB888 由灰度值 [GRAYSCALE]",
    "gui.blocklyText.machineVision.trackingAnalysis": "追踪解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.colorAnalysis": "颜色解析 对象 [OBJ] CDF [CDF] 获取 [INFO]",
    "gui.blocklyText.machineVision.colorPatchTracking": "色块追踪 图像 [IMAGE] 阈值LAB [THRESHOLDLAB] 区域-xywh [AREA] 滤掉区域 [FILTEROUTAREA] 滤掉素数 [FILTEROUTPRIMENUMBERS] 合并 [MERGE] 重叠 [OVERLAPPING]",
    "gui.blocklyText.machineVision.colorRecognition": "颜色识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.extension.machineVision.dimensionCodeRecognition.name": "机器视觉 · 维码识别",
    "gui.blocklyText.machineVision.barcodeAnalysis": "条形码解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.QRCodeAnalysis": "二维码解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.aprilTagAnalysis": "AprilTag解析 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.BarCodeRecognition": "条形码识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.blocklyText.machineVision.QRCodeRecognition": "二维码识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.blocklyText.machineVision.AprilTagRecognition": "AprilTag识别 图像 [IMAGE] 区域-xywh [AREA]",
    "gui.extension.machineVision.featureRecognition.name": "机器视觉 · 特征识别",
    "gui.blocklyText.machineVision.featureInformation": "特征信息 对象 [OBJ] 获取 [INFO]",
    "gui.blocklyText.machineVision.directionalGradienMap": "定向梯度图 图像 [IMAGE] 区域-xywh [AREA] 线长 [LINELENGTH]",
    "gui.blocklyText.machineVision.featureRecognition": "特征识别 图像 [IMAGE] 区域-xywh [AREA] 关键点数 [KEYPOINTS] 角点数 [CORNERPOINTS] 比例因子 [SCALEFACTOR] 多比例 [MULTIRATIO]",
    "gui.blocklyText.machineVision.featureComparison": "特征比较 对象1 [OBJ1] 对象2 [OBJ2] 相似度 [SIMILARITY]",
    "gui.blocklyText.machineVision.FeatureInformationMenu.A": "坐标-xywh",
    "gui.blocklyText.machineVision.FeatureInformationMenu.B": "x分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.C": "y分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.D": "w分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.E": "h分量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.F": "中心-x",
    "gui.blocklyText.machineVision.FeatureInformationMenu.G": "中心-y",
    "gui.blocklyText.machineVision.FeatureInformationMenu.H": "匹配量",
    "gui.blocklyText.machineVision.FeatureInformationMenu.I": "弧度",
    "gui.blocklyText.machineVision.FeatureInformationMenu.J": "匹配点-xy",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.H": "号码",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.I": "家族",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.J": "弧度",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.K": "x向距离",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.L": "y向距离",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.M": "z向距离",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.N": "x向弧度",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.O": "y向弧度",
    "gui.blocklyText.machineVision.AprilTagAnalysisMenu.P": "z向弧度",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.F": "内容",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.G": "版本",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.H": "掩码",
    "gui.blocklyText.machineVision.QRCodeAnalysisMenu.I": "类型",
    "gui.blocklyText.machineVision.BarcodeAnalysisMenu.H": "角度",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.A": "灰度图值",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.B": "LAB的L值",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.C": "LAB的A值",
    "gui.blocklyText.machineVision.ColorAnalysisMenu.D": "LAB的B值",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.H": "像素量",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.J": "号码数",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.K": "合并量",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.L": "面积",
    "gui.blocklyText.machineVision.TrackingAnalysisMenu.M": "密度比",
    "gui.blocklyText.machineVision.CircularInformationMenu.A": "坐标-xyr",
    "gui.blocklyText.machineVision.CircularInformationMenu.D": "r分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.B": "x1分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.C": "y1分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.D": "x2分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.E": "y2分量",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.F": "长度",
    "gui.blocklyText.machineVision.StraightLineInfoMenu.H": "斜率",
    "gui.blocklyText.machineVision.ImageInfoMenu.A": "宽度",
    "gui.blocklyText.machineVision.ImageInfoMenu.B": "高度",
    "gui.blocklyText.machineVision.ImageInfoMenu.C": "格式",
    "gui.blocklyText.machineVision.ImageInfoMenu.D": "大小",
    "gui.blocklyText.machineVision.ConversionTypeMenu.A": "灰度图",
    "gui.blocklyText.machineVision.ConversionTypeMenu.B": "彩色图",
    "gui.blocklyText.machineVision.ConversionTypeMenu.C": "彩虹图",
    "gui.blocklyText.machineVision.ConversionTypeMenu.D": "AI格式",
    "gui.extension.mpyirq.name": "引脚中断",
    "gui.blocklyText.mpyirq.event": "中断引脚 [PIN] 模式 [MODE] 执行",
    "gui.blocklyText.mpyirq.clean": "取消引脚中断 [PIN]",
    "gui.blocklyText.pyTurtle.init": "创建海龟绘图器[NAME]",
    "gui.blocklyText.pyTurtle.turtleSetShape": "[NAME] 形状设置为 [SHAPE]",
    "gui.blocklyText.pyTurtle.turtleDone": "绘制结束，继续保持窗口",
    "gui.blocklyText.pyTurtle.turtleExitClick": "点击退出绘制",
    "gui.blocklyText.pyTurtle.turtleMove": "[NAME]移动[DIRECTION][DISTANCE]",
    "gui.blocklyText.pyTurtle.turtleRotate": "[NAME]旋转[DIRECTION][ANGLE]",
    "gui.blocklyText.pyTurtle.turtleFaceTo": "[NAME]面向[ANGLE]度",
    "gui.blocklyText.pyTurtle.turtleMoveToPos": "[NAME]移动到坐标X[X] Y[Y]位置处",
    "gui.blocklyText.pyTurtle.turtleSetPos": "[NAME] [XY]坐标设置为[POS]",
    "gui.blocklyText.pyTurtle.turtleGetStatus": "[NAME] 获取当前[STATUS]",
    "gui.blocklyText.pyTurtle.turtleClearAll": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleClone": "[NAME] 复制海龟",
    "gui.blocklyText.pyTurtle.turtleVisible": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleSetThickness": "[NAME] 设置画笔粗细 [THICK]",
    "gui.blocklyText.pyTurtle.turtleSetScale": "[NAME] 设置海龟图标大小 垂直拉伸倍数[VERTICAL] 水平拉伸倍数[HOR] 轮廓宽度[OUTLINE]",
    "gui.blocklyText.pyTurtle.turtleSetSpeed": "[NAME] 设置画笔速度为[SPEED]",
    "gui.blocklyText.pyTurtle.turtleSetBackground": "[NAME] 设置背景颜色[COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetPenColor": "[NAME] 设置画笔颜色 [COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetFillColor": "[NAME]设置画笔填充颜色[COLOR]",
    "gui.blocklyText.pyTurtle.turtleSetColor": "[NAME] 设置画笔颜色 [COLOR1] 设置画笔填充颜色[COLOR2]",
    "gui.blocklyText.pyTurtle.turtleDrawAction": "[NAME] [ACTION]",
    "gui.blocklyText.pyTurtle.turtleDrawCircle": "[NAME]绘制图形[CIRCLE] 半径为[RADIUS]",
    "gui.blocklyText.pyTurtle.turtleDrawCircleWithAngle": "[NAME]绘制圆形 半径为[RADIUS] 角度为[DEGREE]",
    "gui.blocklyText.pyTurtle.turtleInitScreen": "[NAME] 初始化海龟屏幕[SCREEN]",
    "gui.blocklyText.pyTurtle.turtleSaveDraw": "[NAME] 将绘制的图像保存为图片 [PICTURES]",
    "gui.blocklyText.pyTurtle.turtleSetAnimationInterval": "[NAME]设置动画播放时间间隔为[TIME]毫秒",
    "gui.blocklyText.pyTurtle.turtleDrawText": "[NAME] 书写 [TEXT]",
    "gui.blocklyText.pyTurtle.turtleDrawTextWithAttr": "[NAME] 书写 [TEXT] 画笔随文字移动[JUDGE] 文字对齐方向[ALIGN] 字体[FONT] 字号[FONTSIZE] 字形[TYPE]",
    "gui.blocklyText.pyTurtle.turtleGetFloat": "海龟获取输入的浮点数 标题为[TITLE] 提示语为[TIP] 默认值[VALUE] 最小值[MIN] 最大值[MAX]",
    "gui.blocklyText.pyTurtle.turtleGetString": "海龟获取输入的字符串 标题为[TITLE] 提示语为[TIP]",
    "gui.blocklyText.pyTurtle.roll_forward": "向前",
    "gui.blocklyText.pyTurtle.roll_backward": "向后",
    "gui.blocklyText.pyTurtle.roll_l_left": "向左",
    "gui.blocklyText.pyTurtle.roll_l_right": "向右",
    "gui.blocklyText.pyTurtle.xy_x": "X",
    "gui.blocklyText.pyTurtle.xy_y": "Y",
    "gui.blocklyText.pyTurtle.status_pos": "位置",
    "gui.blocklyText.pyTurtle.status_shape": "形状",
    "gui.blocklyText.pyTurtle.status_heading": "朝向",
    "gui.blocklyText.pyTurtle.status_width": "画笔宽度",
    "gui.blocklyText.pyTurtle.status_shapesize": "海龟图标大小",
    "gui.blocklyText.pyTurtle.status_speed": "速度",
    "gui.blocklyText.pyTurtle.action_clear": "清屏",
    "gui.blocklyText.pyTurtle.action_reset": "重置",
    "gui.blocklyText.pyTurtle.action_home": "返回原点",
    "gui.blocklyText.pyTurtle.pen_action_penup": "抬笔",
    "gui.blocklyText.pyTurtle.pen_action_pendown": "落笔",
    "gui.blocklyText.pyTurtle.visible_hideturtle": "隐藏海龟",
    "gui.blocklyText.pyTurtle.visible_showturtle": "显示海龟",
    "gui.blocklyText.pyTurtle.visible_turtle": "海龟",
    "gui.blocklyText.pyTurtle.visible_arrow": "箭头",
    "gui.blocklyText.pyTurtle.visible_circle": "圆形",
    "gui.blocklyText.pyTurtle.visible_square": "方块",
    "gui.blocklyText.pyTurtle.visible_triangle": "三角形",
    "gui.blocklyText.pyTurtle.visible_classic": "默认",
    "gui.blocklyText.pyTurtle.drawaction_begin": "开始填充",
    "gui.blocklyText.pyTurtle.drawaction_end": "结束填充",
    "gui.blocklyText.pyTurtle.circle_circle": "圆",
    "gui.blocklyText.pyTurtle.circle_dot": "点",
    "gui.blocklyText.pyTurtle.flag_False": "否",
    "gui.blocklyText.pyTurtle.flag_True": "是",
    "gui.blocklyText.pyTurtle.align_left": "居左",
    "gui.blocklyText.pyTurtle.align_center": "居中",
    "gui.blocklyText.pyTurtle.align_right": "居右",
    "gui.blocklyText.pyTurtle.align_normal": "标准",
    "gui.blocklyText.pyTurtle.align_bold": "加粗",
    "gui.blocklyText.pyTurtle.align_italic": "倾斜",
    "gui.blocklyText.pyTurtle.align_bi": "倾斜加粗",
    "gui.blocklyText.pyTurtle.turtleScreenSetup": "初始化海龟窗口大小 宽度[WIDTH] 高度[HEIGHT] 偏移 X [LEFT] Y [TOP]",
    "gui.blocklyText.pyTurtle.turtleScreenSize": "初始化海龟画布 宽度[WIDTH] 高度[HEIGHT] 背景颜色[COLOR]",
    "gui.blocklyText.pyGenerator.importModule": "import [NAME]",
    "gui.blocklyText.pyGenerator.importAs": "import [MOUDLE] as [NAME]",
    "gui.blocklyText.pyGenerator.fromImport": "from [FROM] import [MOUDLE]",
    "gui.blocklyText.pyGenerator.annotation": "# [TEXT]",
    "gui.blocklyText.pyGenerator.defineFunction": "def [FUNCTION]([ARGUMENT]):",
    "gui.blocklyText.pyGenerator.callFunction": "调用函数[FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.callFunctionWithValue": "调用函数获取返回值[FUNCTION]([ARGUMENT])",
    "gui.blocklyText.pyGenerator.defineClass": "class [CLASS]:",
    "gui.blocklyText.pyGenerator.instanceClass": "[CLASS] [INSTANCE]",
    "gui.blocklyText.pyGenerator.reporterType": "Code [CODE]",
    "gui.blocklyText.pyGenerator.typeBoolean": "Code [CODE]",
    "gui.blocklyText.pyGenerator.annotationBlock": "块注释",
    "gui.blocklyText.pyGenerator.originCode": "Code [CODE]",
    "gui.extension.xiamiboard.title": "虾米扩展板",
    "gui.extension.xiamiboard.setMotor": "设置电机[Select]方向[Direction]速度[Speed]",
    "gui.extension.xiamiboard.stopMotor": "停止电机[Select]",
    "gui.extension.xiamiboard.setTrafficLights": "设置交通灯 红[Red] 黄[Yellow] 绿[Green]",
    "gui.extension.xiamiboard.readAngle": "读取角度传感器数据",
    "gui.extension.xiamiboard.readFlame": "读取火焰传感器数据",
    "gui.extension.xiamiboard.readMotorState": "读取电机[Select]状态[State]",
    "gui.extension.xiamiboard.readTrafficLights": "读取交通灯状态[State]",
    "gui.extension.xiamiboard.readRelay": "设置继电器[State]",
    "gui.extension.xiamiboard.readUltrasonicDistance": "读取超声波距离(cm)",
    "gui.extension.xiamiboard.initializationScreen": "OLED 初始化",
    "gui.extension.xiamiboard.screenDisplay": "OLED 显示文字[Text]在坐标x[Row]y[Column]",
    "gui.extension.xiamiboard.clearScreenAll": "OLED 清屏",
    "gui.extension.xiamiboard.clearScreen": "OLED 清除第[Row]行 从列[ColumnFrom]到列[ColumnTo]",
    "gui.extension.xiamiboard.readInfrared": "读取红外键值",
    "gui.extension.xiamiboard.receiveData": "当接收到[Data]",
    "gui.extension.xiamiboard.wirelessData": "红外数据",
    "gui.extension.xiamiboard.xiamiRGB": "红[Red]绿[Green]蓝[Blue]",
    "gui.extension.xiamiboard.setRGBLightsRange": "设置RGB灯点亮[Range] 显示颜色[Color]",
    "gui.extension.xiamiboard.setLightsColor": "设置RGB灯[Num]显示颜色[Color]",
    "gui.extension.xiamiboard.setLightsBrightness": "设置RGB灯亮度[Brightness]",
    "gui.extension.xiamiboard.coloseLightsAll": "关闭全部RGB灯",
    "gui.extension.xiamiboard.initializeST": "初始化 [TYPE] 温湿度传感器",
    "gui.extension.xiamiboard.readSTState": "读取 [TYPE] [State]",
    "gui.blocklyText.xiamiboard.all": "全部",
    "gui.blocklyText.xiamiboard.cw": "正转",
    "gui.blocklyText.xiamiboard.ccw": "反转",
    "gui.blocklyText.xiamiboard.speed": "速度",
    "gui.blocklyText.xiamiboard.dir": "方向",
    "gui.blocklyText.xiamiboard.temp": "温度",
    "gui.blocklyText.xiamiboard.hum": "湿度",
    "gui.blocklyText.xiamiboard.colorRed": "红色",
    "gui.blocklyText.xiamiboard.colorYellow": "黄色",
    "gui.blocklyText.xiamiboard.colorGreen": "绿色",
    "gui.blocklyText.xiamiboard.close": "闭合",
    "gui.blocklyText.xiamiboard.disconnect": "断开",
    "gui.blocklyText.xiamiboard.lightAll": "全部",
    "gui.extension.pyMqtt.initMqtt": "初始化MQTT[SETTING]",
    "gui.extension.pyMqtt.connect": "MQTT发起连接",
    "gui.extension.pyMqtt.loop_forever": "MQTT保持连接,永久",
    "gui.extension.pyMqtt.loopNeverForever": "MQTT保持连接,超时时间[Time]秒",
    "gui.extension.pyMqtt.disconnect": "MQTT断开连接",
    "gui.extension.pyMqtt.pushlish": "MQTT发布[INFO]到[Topic]",
    "gui.extension.pyMqtt.pushlish_save": "MQTT发布[INFO]到[Topic]并存数据库 (SIoT V2)",
    "gui.extension.pyMqtt.subscribe": "MQTT订阅[Topic]",
    "gui.extension.pyMqtt.received": "当MQTT从[Topic]接收到[MqttInfo]",
    "gui.extension.pyMqtt.topic": "MQTT主题",
    "gui.extension.pyMqtt.mqttInfo": "MQTT信息",
    "gui.blocklyText.pyMqtt.pyMqttInitPlatform": "MQTT平台",
    "gui.blocklyText.pyMqtt.EasyIOT_Server": "EasyIoT服务器",
    "gui.blocklyText.pyMqtt.EasyIOTServerChina": "EasyIoT 中国",
    "gui.blocklyText.pyMqtt.EasyIOTServerGlobal": "EasyIoT 全球",
    "gui.blocklyText.pyMqtt.EasyIOT_Port": "EasyIoT Port",
    "gui.blocklyText.pyMqtt.EasyIOT_id": "EasyIoT ID",
    "gui.blocklyText.pyMqtt.siotServer": "SIoT服务器",
    "gui.blocklyText.pyMqtt.siotServerPort": "SIoT Port",
    "gui.blocklyText.pyMqtt.siotServerUser": "SIoT 用户名",
    "gui.blocklyText.pyMqtt.siotServerPass": "SIoT 密码",
    "gui.blocklyText.microNaturalScienceV2.IOT_Username": "IOT_ID(用户)",
    "gui.blocklyText.microNaturalScienceV2.IOT_Password": "IOT_PWD(密码)",
    "gui.blocklyText.microNaturalScienceV2.IOT_topic": "Topic(默认topic_0)",
    "gui.extension.pyGame.pygameModule": "Pygame 模块",
    "gui.extension.pyGame.pygameInit": "初始化所有导入的 pygame 模块",
    "gui.extension.pyGame.pygameQuit": "卸载所有已初始化的 pygame 模块",
    "gui.extension.pyGame.displayModule": "Display 模块",
    "gui.extension.pyGame.displayInit": "初始化display模块",
    "gui.extension.pyGame.displayQuit": "卸载已初始化的display模块",
    "gui.extension.pyGame.windowInit": "初始化[Width]x[Height] [Flags]的窗口[Win]",
    "gui.extension.pyGame.windowUpdate": "更新窗口(部分软件)界面显示",
    "gui.extension.pyGame.windowSetIcon": "设置窗口图标[Value]",
    "gui.extension.pyGame.windowSetTitle": "设置窗口标题[Value]",
    "gui.extension.pyGame.windowFlip": "更新Surface对象至屏幕",
    "gui.extension.pyGame.windowDraw": "将[Suf]绘制到[Win]窗口的[Postion]",
    "gui.extension.pyGame.surfaceObject": "Surface对象",
    "gui.extension.pyGame.createSurface": "创建[Width]x[Height]的Surface对象[Surface]",
    "gui.extension.pyGame.blitSurface": "将[Obj]绘制到Surface对象[Surface]的[Pos]",
    "gui.extension.pyGame.fillSurface": "用[Color]填充Surface对象[Surface]",
    "gui.extension.pyGame.surfaceSetAt": "将Surface对象[Surface]的[Pos]像素点设置成[Color]",
    "gui.extension.pyGame.surfaceGetAt": "获取Surface对象[Surface]的[Pos]像素点的颜色",
    "gui.extension.pyGame.surfaceGetWidth": "获取Surface对象[Surface]的宽度",
    "gui.extension.pyGame.surfaceGetHeight": "获取Surface对象[Surface]的高度",
    "gui.extension.pyGame.surfaceGetRect": "获取Surface对象[Surface]的矩形区域",
    "gui.extension.pyGame.surfaceMove": "Surface对象[Surface]的偏移速度为[CONTENT]",
    "gui.extension.pyGame.eventModule": "Event 模块",
    "gui.extension.pyGame.windowEvent": "获取窗口事件",
    "gui.extension.pyGame.eventType": "[Event]事件类型",
    "gui.extension.pyGame.eventKey": "[Event][Mod]",
    "gui.extension.pyGame.event": "[Event]事件",
    "gui.extension.pyGame.eventConstant": "[Constant]键",
    "gui.extension.pyGame.eventModKey": "[Constant]组合键",
    "gui.extension.pyGame.eventMod": "检测[Keys]组合键是否被按下",
    "gui.extension.pyGame.eventOr": "[Key1]|[Key2]",
    "gui.extension.pyGame.fontModule": "Font 模块",
    "gui.extension.pyGame.fontInit": "初始化font模块",
    "gui.extension.pyGame.fontQuit": "卸载已初始化的font模块",
    "gui.extension.pyGame.createFont": "从[Font]创建[Size][Source]字体对象",
    "gui.extension.pyGame.getDefaultFont": "获取系统字体的文件名",
    "gui.extension.pyGame.getCanUsedFont": "获取系统可使用的字体列表",
    "gui.extension.pyGame.generationFont": "通过字体对象[Font]创建抗锯齿[Antialias]颜色[Color]透明度[Opacity]的文本[Text]",
    "gui.extension.pyGame.renderStyle": "设置字体[Font]样式 [Bold] [Italics] [Underline]",
    "gui.extension.pyGame.imageModule": "Image 模块",
    "gui.extension.pyGame.imageLoad": "从[FileName]加载图片",
    "gui.extension.pyGame.supportExtension": "判断加载的图片是不是支持的扩展?",
    "gui.extension.pyGame.imageSave": "保存[Img]为[FileName]图片",
    "gui.extension.pyGame.drawModule": "Draw 模块",
    "gui.extension.pyGame.drawRect": "绘制颜色为[Color]配置[Rect]边框粗细[Width]的矩形到[Win]",
    "gui.extension.pyGame.drawCircle": "绘制颜色为[Color]位置[Rect]半径[Size]边框粗细[Width]的圆形到[Win]",
    "gui.extension.pyGame.drawLine": "绘制颜色为[Color]起始位置[start_pos]结束位置[end_pos]粗细[Width]的线到[Win]",
    "gui.extension.pyGame.drawLines": "绘制颜色为[Color]闭合[Closed]位置[Pointlist]粗细[Width]的多条线到[Win]",
    "gui.extension.pyGame.mixerModule": "Mixer 模块",
    "gui.extension.pyGame.mixerInit": "初始化mixer模块",
    "gui.extension.pyGame.mixerQuit": "卸载已初始化的mixer模块",
    "gui.extension.pyGame.createSound": "从[FileName]加载声音对象",
    "gui.extension.pyGame.soundPlay": "声音[Sound]播放",
    "gui.extension.pyGame.soundStop": "声音[Sound]停止",
    "gui.extension.pyGame.soundFadeout": "设置声音[Sound]淡出(逐渐减弱直到消失)的时间[Time](ms)",
    "gui.extension.pyGame.soundSetVolume": "设置声音[Sound]音量[Value]%",
    "gui.extension.pyGame.soundGetbusy": "声音是否还在播放?",
    "gui.extension.pyGame.timeModule": "Time 模块",
    "gui.extension.pyGame.getRuntime": "获取运行时间(毫秒)",
    "gui.extension.pyGame.pauseWay": "用[Way]暂停[Time](毫秒)",
    "gui.extension.pyGame.setTimer": "创建[Time](毫秒)后[Once][Event]事件的定时器",
    "gui.extension.pyGame.clockInit": "初始化时钟[Clock]",
    "gui.extension.pyGame.updateClock": "更新时钟[Clock]",
    "gui.extension.pyGame.getClockUp": "获取[Clock]上一个刻度中使用的时间",
    "gui.extension.pyGame.getClockUpRaw": "获取[Clock]上一个刻度中使用的实际时间",
    "gui.extension.pyGame.getClockFps": "获取[Clock]计算时钟帧率",
    "gui.extension.pyGame.musicModule": "Music 模块",
    "gui.extension.pyGame.musicLoad": "从[FileName]加载音乐对象",
    "gui.extension.pyGame.setVolume": "设置音乐音量[Value]%",
    "gui.extension.pyGame.musicPlay": "音乐播放",
    "gui.extension.pyGame.musicRewind": "音乐重新播放",
    "gui.extension.pyGame.musicPause": "音乐暂停",
    "gui.extension.pyGame.musicUnpause": "音乐恢复播放",
    "gui.extension.pyGame.musicStop": "音乐停止",
    "gui.extension.pyGame.musicFadeout": "设置音乐淡出(逐渐减弱直到消失)的时间[Time](ms)",
    "gui.extension.pyGame.musicGetbusy": "音乐是否还在播放?",
    "gui.extension.pyGame.colorModule": "Color 模块",
    "gui.extension.pyGame.color": "[Color]转RGB",
    "gui.blocklyText.pyGame.gain": "焦点状态",
    "gui.blocklyText.pyGame.state": "程序状态",
    "gui.blocklyText.pyGame.unicode": "按键名称",
    "gui.blocklyText.pyGame.key": "按键值",
    "gui.blocklyText.pyGame.mod": "按键模式",
    "gui.blocklyText.pyGame.pos": "鼠标定位",
    "gui.blocklyText.pyGame.rel": "鼠标移动的相对位置",
    "gui.blocklyText.pyGame.buttons": "鼠标按键",
    "gui.blocklyText.pyGame.size": "窗口大小",
    "gui.blocklyText.pyGame.w": "窗口宽度",
    "gui.blocklyText.pyGame.h": "窗口高度",
    "gui.blocklyText.pyGame.once": "运行一次",
    "gui.blocklyText.pyGame.repeat": "重复运行",
    "gui.blocklyText.pyGame.close": "闭合",
    "gui.blocklyText.pyGame.noclose": "不闭合",
    "gui.blocklyText.pyGame.fullscreen": "全屏显示",
    "gui.blocklyText.pyGame.doublebuf": "双缓冲",
    "gui.blocklyText.pyGame.hwsurface": "硬件加速",
    "gui.blocklyText.pyGame.opengl": "渲染显示",
    "gui.blocklyText.pyGame.resizable": "窗口适应",
    "gui.blocklyText.pyGame.noframe": "无框架",
    "gui.blocklyText.pyGame.none": "无",
    "gui.blocklyText.pyGame.external": "自定义",
    "gui.blocklyText.pyGame.system": "系统",
    "gui.blocklyText.pyGame.normal": "正常",
    "gui.blocklyText.pyGame.italics": "斜体",
    "gui.blocklyText.pyGame.bold": "加粗",
    "gui.blocklyText.pyGame.underline": "下划线",
    "gui.blocklyText.pyGame.wait": "等待",
    "gui.blocklyText.pyGame.delay": "延迟",
    "gui.blocklyText.pyGame.false": "False",
    "gui.blocklyText.pyGame.true": "True",
    "gui.blocklyText.pyGame.quit": "窗口关闭",
    "gui.blocklyText.pyGame.activeevent": "窗口被激活或者隐藏",
    "gui.blocklyText.pyGame.keydown": "键盘按下",
    "gui.blocklyText.pyGame.keyup": "键盘松开",
    "gui.blocklyText.pyGame.mousemotion": "鼠标移动",
    "gui.blocklyText.pyGame.mousebuttonup": "鼠标松开",
    "gui.blocklyText.pyGame.mousebuttondown": "鼠标按下",
    "gui.blocklyText.pyGame.videoresize": "窗口缩放",
    "gui.blocklyText.pyGame.videoexpose": "窗口部分公开(expose)",
    "gui.blocklyText.pyGame.insert": "插入",
    "gui.blocklyText.pyGame.home": "起始",
    "gui.blocklyText.pyGame.end": "结束",
    "gui.blocklyText.pyGame.pageup": "上一页",
    "gui.blocklyText.pyGame.pagedown": "下一页",
    "gui.blocklyText.pyGame.numlock": "数字键盘锁定",
    "gui.blocklyText.pyGame.capslock": "大写锁定",
    "gui.blocklyText.pyGame.scrollock": "滚动锁",
    "gui.blocklyText.pyGame.rightshift": "右Shift",
    "gui.blocklyText.pyGame.leftshift": "左Shift",
    "gui.blocklyText.pyGame.rightcontrol": "右Control",
    "gui.blocklyText.pyGame.leftcontrol": "左Control",
    "gui.blocklyText.pyGame.rightalt": "右Alt",
    "gui.blocklyText.pyGame.leftalt": "左Alt",
    "gui.blocklyText.pyGame.rightmeta": "Right Meta",
    "gui.blocklyText.pyGame.leftmeta": "Left Meta",
    "gui.blocklyText.pyGame.leftWindowskey": "左Windows",
    "gui.blocklyText.pyGame.rightWindowskey": "右Windows",
    "gui.blocklyText.pyGame.modeshift": "mode Shift",
    "gui.blocklyText.pyGame.help": "帮助",
    "gui.blocklyText.pyGame.printscreen": "屏幕截图",
    "gui.blocklyText.pyGame.sysrq": "sysrq",
    "gui.blocklyText.pyGame.break": "中断暂停",
    "gui.blocklyText.pyGame.menu": "功能调节菜单",
    "gui.blocklyText.pyGame.power": "电源",
    "gui.blocklyText.pyGame.Euro": "Euro",
    "gui.blocklyText.pyGame.delete": "删除",
    "gui.blocklyText.pyGame.backspace": "回格",
    "gui.blocklyText.pyGame.tab": "制表",
    "gui.blocklyText.pyGame.clear": "清除",
    "gui.blocklyText.pyGame.return": "回车",
    "gui.blocklyText.pyGame.pause": "暂停",
    "gui.blocklyText.pyGame.escape": "ESC",
    "gui.blocklyText.pyGame.space": "空格",
    "gui.blocklyText.pyGame.quotedbl": "双引号",
    "gui.blocklyText.pyGame.quote": "单引号",
    "gui.blocklyText.pyGame.NONE": "无",
    "gui.blocklyText.pyGame.LSHIFT": "左Shift",
    "gui.blocklyText.pyGame.RSHIFT": "右Shift",
    "gui.blocklyText.pyGame.SHIFT": "Shift",
    "gui.blocklyText.pyGame.LCTRL": "左Ctrl",
    "gui.blocklyText.pyGame.RCTRL": "右Ctrl",
    "gui.blocklyText.pyGame.CTRL": "Ctrl",
    "gui.blocklyText.pyGame.LALT": "左Alt",
    "gui.blocklyText.pyGame.RALT": "右Alt",
    "gui.blocklyText.pyGame.ALT": "Alt",
    "gui.blocklyText.pyGame.LMETA": "左Meta",
    "gui.blocklyText.pyGame.RMETA": "右Meta",
    "gui.blocklyText.pyGame.META": "Meta",
    "gui.blocklyText.pyGame.CAPS": "大写锁定",
    "gui.blocklyText.pyGame.NUM": "数字键盘锁定",
    "gui.blocklyText.pyGame.MODE": "AltGr",
    "gui.extension.pinpongBase.pinpongInit": "pinpong初始化 板型[Board]端口[Port]",
    "gui.extension.pinpongBase.pinpongUpdateFirmware": "pinpong更新固件 板型[Board]端口[Port]",
    "gui.extension.pinpongBase.pinInit": "[Instance]pin初始化 引脚号[Pin] 模式[Mod]",
    "gui.extension.pinpongBase.readPinValue": "[Instance]读数字值",
    "gui.extension.pinpongBase.setPinValue": "[Instance]设置数字输出 值[Value]",
    "gui.extension.pinpongBase.readAnalogValue": "[Instance]读模拟值",
    "gui.extension.pinpongBase.setAnalogValue": "[Instance]设置模拟输出(PWM) 值[Value]",
    "gui.blocklyText.pinpongBase.uno": "Arduino UNO",
    "gui.blocklyText.pinpongBase.leonardo": "Arduino Leonardo",
    "gui.blocklyText.pinpongBase.nano": "Arduino Nano",
    "gui.blocklyText.pinpongBase.mega1280": "Arduino Mega1280",
    "gui.blocklyText.pinpongBase.mega2560": "Arduino Mega2560",
    "gui.blocklyText.pinpongBase.microbit": "micro:bit",
    "gui.blocklyText.pinpongBase.handpy": "掌控板",
    "gui.blocklyText.pinpongBase.xugu": "虚谷号",
    "gui.blocklyText.pinpongBase.auto": "自动识别",
    "gui.blocklyText.pinpongBase.unihiker": "行空板",
    "gui.blocklyText.pinpongBase.rpi": "树莓派",
    "gui.blocklyText.pinpongBase.firebeetle_esp32": "Firebeetle ESP32",
    "gui.blocklyText.pinpongBase.firebeetle_esp8266": "Firebeetle ESP8266",
    "gui.blocklyText.pinpongBase.lp": "LattePanda",
    "gui.blocklyText.pinpongBase.esp32": "ESP32",
    "gui.blocklyText.pinpongBase.esp8266": "ESP8266",
    "gui.blocklyText.pinpongBase.nezha": "哪吒板",
    "gui.blocklyText.pinpongBase.JH7100": "JH7100",
    "gui.blocklyText.pinpongBase.win": "win",
    "gui.blocklyText.pinpongBase.out": "数字输出",
    "gui.blocklyText.pinpongBase.in": "数字输入",
    "gui.blocklyText.pinpongBase.analog": "模拟输入",
    "gui.blocklyText.pinpongBase.pwm": "模拟输出",
    "gui.blocklyText.pinpongBase.null": "无",
    "gui.blocklyText.pinpongServo.name": "舵机",
    "gui.extension.pinpongServo.servoInit": "舵机[Servo] 初始化引脚[Pin]",
    "gui.extension.pinpongServo.setServo": "舵机[Servo]转动到[Deg]度位置",
    "gui.blocklyText.pinpongIrq.name": "中断",
    "gui.extension.pinpongIrq.setIrq": "设置引脚[Pin]中断[Trigger]回调函数[Handler]",
    "gui.extension.pinpongIrq.INTevent": "当回调函数[Event]触发 参数[Pin]",
    "gui.extension.pinpongIrq.pinInfo": "pin",
    "gui.blocklyText.pinpongIrq.rising": "上升沿",
    "gui.blocklyText.pinpongIrq.falling": "下降沿",
    "gui.blocklyText.pinpongIrq.change": "电平变化",
    "gui.blocklyText.pinpongTone.name": "蜂鸣器",
    "gui.extension.pinpongTone.toneInit": "蜂鸣器[Tone] 初始化引脚[Pin]",
    "gui.extension.pinpongTone.setToneFreq": "设置蜂鸣器[Tone]频率[Freq]",
    "gui.extension.pinpongTone.readToneFreq": "读取蜂鸣器[Tone]频率",
    "gui.extension.pinpongTone.onTone": "打开蜂鸣器[Tone]",
    "gui.extension.pinpongTone.offTone": "关闭蜂鸣器[Tone]",
    "gui.extension.pinpongTone.keep": "蜂鸣器[Tone]以[Freq]频率持续运行[Time]毫秒",
    "gui.blocklyText.pinpongSr04_urm10.name": "超声波",
    "gui.extension.pinpongSr04_urm10.sr04_urm10Init": "超声波传感器[Sr04_urm10]初始化 trig引脚[Triger] echo引脚[Echo]",
    "gui.extension.pinpongSr04_urm10.readSr04_urm10": "读取超声波传感器[Sr04_urm10]距离(cm)",
    "gui.extension.pinpongDht.dhtInit": "初始化温湿度传感器[Dht]型号[DhtMod] 引脚[Pin]",
    "gui.extension.pinpongDht.read": "[Dht]读取[Type]",
    "gui.extension.pinpongDht.Temperature": "温度(℃)",
    "gui.extension.pinpongDht.Humidity": "湿度(%rh)",
    "gui.blocklyText.pinpongNeopixel.name": "灯带",
    "gui.extension.pinpongNeopixel.neopixelInit": "初始化 RGB灯[Np] 引脚[Pin]灯数[Num]",
    "gui.extension.pinpongNeopixel.brightness": "RGB灯[Np] 设置亮度[BRIGHTNESS]",
    "gui.extension.pinpongNeopixel.neopixelClear": "RGB灯[Np] 全部熄灭",
    "gui.extension.pinpongNeopixel.setNeopixel": "[Np]设置第[No]个灯的颜色[Color]",
    "gui.extension.pinpongNeopixel.showColorFromWith": "RGB灯[Np] 灯号 [NUM1] 到 [NUM2] 显示颜色 [COLOR]",
    "gui.extension.pinpongNeopixel.color": "红[Red]绿[Green]蓝[Blue]",
    "gui.extension.pinpongNeopixel.showGradientColor": "RGB灯[Np] 灯号 [NUM1] 到 [NUM2] 显示渐变颜色 色调从 [COLOR1] 到 [COLOR2]",
    "gui.extension.pinpongNeopixel.shiftPixelsToShow": "RGB灯[Np] 移动 [NUM] 单位",
    "gui.extension.pinpongNeopixel.rotatePixelsToShow": "RGB灯[Np] 循环移动 [NUM] 单位",
    "gui.extension.pinpongNeopixel.showHistogram": "RGB灯[Np] 灯号 [NUM1] 到 [NUM2] 显示柱状图 当前值 [VALUE] 最大值 [MAX]",
    "gui.extension.pinpongOled12864.oled12864Init": "OLED显示屏[Oled]初始化 宽度[Width]高度：8×[Height]",
    "gui.extension.pinpongOled12864.oled12864Fill": "[Oled]全屏[Option]",
    "gui.extension.pinpongOled12864.oled12864ShowString": "[Oled]在坐标x[X]y[Y] 显示文本[String]",
    "gui.extension.pinpongOled12864.show": "[Oled]显示生效",
    "gui.blocklyText.pinpongOled12864.display": "填充",
    "gui.blocklyText.pinpongOled12864.hide": "熄灭",
    "gui.extension.pinpongDS18B20.ds18b20Init": "温度传感器[Ds18b20]初始化 引脚[Pin]",
    "gui.extension.pinpongDS18B20.readCelsius": "读取[Ds18b20]温度(℃)",
    "gui.extension.pinpongMlx90614.mlx90614Init": "红外测温传感器[IRT]初始化 地址[ADDR]",
    "gui.extension.pinpongMlx90614.read": "红外测温传感器[OBJ]读取[TYPE] [UINT]",
    "gui.extension.pinpongBmp388.BMP388Init": "BMP388[BMP]初始化  I2C地址0x77",
    "gui.extension.pinpongBmp388.read": "BMP388[BMP]读取[TYPE]",
    "gui.blocklyText.pinpongBmp388.altitude": "海拔高度(m)",
    "gui.blocklyText.pinpongBmp388.pressure": "大气压强(Pa)",
    "gui.blocklyText.pinpongBmp388.temperature": "温度(℃)",
    "gui.blocklyText.pinpongIRRecv.name": "红外接收",
    "gui.extension.pinpongIRRecv.iRRecvInit": "红外接收[IRRecv]初始化 引脚[Pin] 回调函数[Event]",
    "gui.extension.pinpongIRRecv.onReceive": "当回调函数[Event]接收到[Data]",
    "gui.extension.pinpongIRRecv.data": "红外信号",
    "gui.blocklyText.PinpongBoard.name": "PinPong 扩展板",
    "gui.extension.PinpongBoard.connectWifi": "PinPong扩展板连接热点[ssid]密码[password]直到成功",
    "gui.extension.PinpongBoard.getIp": "获取Wi-Fi配置IP地址",
    "gui.extension.PinpongBoard.TCPInit": "PinPong扩展板初始化WIFI[ip] 端口8081",
    "gui.extension.PinpongBoard.USBInit": "PinPong扩展板初始化USB COM口[port]",
    "gui.extension.PinpongBoard.LEDInit": "初始化板载LED灯(D2)",
    "gui.extension.PinpongBoard.LEDSetValue": "设置LED灯输出为[value]",
    "gui.blocklyText.PinpongBoard.high": "高电平",
    "gui.blocklyText.PinpongBoard.low": "低电平",
    "gui.blocklyText.PinpongBoard.temp": "温度(℃)",
    "gui.blocklyText.PinpongBoard.humi": "湿度(%RH)",
    "gui.blocklyText.PinpongBoard.ledControl": "LED灯控制",
    "gui.blocklyText.PinpongBoard.toneControl": "蜂鸣器控制",
    "gui.extension.PinpongBoard.toneInit": "初始化蜂鸣器(D4)",
    "gui.extension.PinpongBoard.toneSetFreq": "蜂鸣器设置频率[Freq]Hz",
    "gui.extension.PinpongBoard.toneSwitch": "蜂鸣器[switch]",
    "gui.blocklyText.PinpongBoard.on": "打开",
    "gui.blocklyText.PinpongBoard.off": "关闭",
    "gui.extension.PinpongBoard.toneGetFreq": "蜂鸣器获取频率",
    "gui.extension.PinpongBoard.keep": "蜂鸣器以[Freq]频率(Hz)持续运行[Time]毫秒",
    "gui.blocklyText.PinpongBoard.buttonControl": "按键控制",
    "gui.extension.PinpongBoard.buttonInit": "初始化引脚号[PIN]模式数字输入",
    "gui.extension.PinpongBoard.buttonGetVlue": "读取[PIN]引脚电平",
    "gui.blocklyText.PinpongBoard.RGBControl": "RGB灯控制",
    "gui.extension.PinpongBoard.RGBInit": "初始化RGB灯(D9)",
    "gui.extension.PinpongBoard.setRGB": "设置RGB灯颜色[Color]",
    "gui.extension.PinpongBoard.setRGBBrightness": "设置RGB灯亮度为 [BRIGHTNESS]",
    "gui.extension.PinpongBoard.closeRGB": "关闭RGB灯",
    "gui.blocklyText.PinpongBoard.irControl": "红外接收",
    "gui.extension.PinpongBoard.irInit": "初始化红外接收回调函数[Event]",
    "gui.blocklyText.PinpongBoard.angleSensor": "角度传感器",
    "gui.extension.PinpongBoard.angleInit": "初始化角度传感器(A3)",
    "gui.extension.PinpongBoard.getAngleValue": "读取角度传感器的值",
    "gui.blocklyText.PinpongBoard.lightSensor": "光线传感器",
    "gui.extension.PinpongBoard.lightInit": "初始化光线传感器(A2)",
    "gui.extension.PinpongBoard.getLightValue": "读取光线传感器的值",
    "gui.blocklyText.PinpongBoard.OLEDscreen": "OLED显示屏",
    "gui.extension.PinpongBoard.oled12864Init": "初始化OLED显示屏",
    "gui.extension.PinpongBoard.oled12864Fill": "OLED屏幕显示为[Option](清屏)",
    "gui.extension.PinpongBoard.oled12864ShowString": "OLED在第[X]行 显示[String]",
    "gui.extension.PinpongBoard.oled12864ShowStringXY": "OLED在坐标X:[X]Y:16*[Y] 显示[String]",
    "gui.extension.PinpongBoard.oledCal": "校准数据 [TEXT] [BIT]位",
    "gui.extension.PinpongBoard.show": "OLED显示生效",
    "gui.blocklyText.PinpongBoard.black": "黑色",
    "gui.blocklyText.PinpongBoard.white": "白色",
    "gui.blocklyText.PinpongBoard.aht20": "温湿度传感器",
    "gui.extension.PinpongBoard.aht20Init": "初始化温湿度传感器",
    "gui.extension.PinpongBoard.getTempValue": "读取温度值(°C)",
    "gui.extension.PinpongBoard.getHumiValue": "读取湿度值(%rh)",
    "gui.blocklyText.PinpongBoard.mpu6050": "MPU6050传感器",
    "gui.extension.PinpongBoard.mpu6050Init": "初始化MPU6050传感器",
    "gui.extension.PinpongBoard.getAccValue": "读取加速计[Option]的值(a/g)",
    "gui.extension.PinpongBoard.getGyroValue": "读取陀螺仪[Option]的值(a/g)",
    "gui.blocklyText.PinpongBoard.motor": "电机控制",
    "gui.extension.PinpongBoard.motorInit": "初始化电机",
    "gui.extension.PinpongBoard.getIrValue": "读取红外传感器的值",
    "gui.extension.PinpongBoard.LightsBright": "RGB灯设置亮度为[BRIGHTNESS]",
    "gui.extension.PinpongBoard.clear": "RGB灯熄灭",
    "gui.extension.PinpongBoard.RGBShowColorFromWith": "RGB灯显示颜色[COLOR]",
    "gui.extension.PinpongBoard.readSHTC3": "读取SHTC3[VALUE]值",
    "gui.extension.PinpongBoard.readPitch": "读取俯仰角的值",
    "gui.extension.PinpongBoard.readYaw": "读取偏航角的值",
    "gui.extension.PinpongBoard.readRoll": "读取翻滚角的值",
    "gui.blocklyText.obloqMp.WhenReceivedData": "当接收到 [EventType] 的 [MSG]",
    "gui.blocklyText.obloqMp.ObloqEventType": "Obloq事件类型",
    "gui.blocklyText.obloqMp.ObloqMsg": "Obloq信息",
    "gui.blocklyText.obloqMp.initialParameter": "Obloq mqtt初始化 [PARAMETER] Rx(绿) [PINR] Tx(蓝) [PINT]",
    "gui.blocklyText.obloqMp.initialI2CParameter": "Obloq mqtt 初始化 [PARAMETER] 接口I2C 回调函数[Event]",
    "gui.blocklyText.obloqMp.subscribe": "Obloq 订阅 [TOPIC]",
    "gui.blocklyText.obloqMp.sendmsgToCloudPlatform": "Obloq 发送消息 [MSG] 至 [TOPIC]",
    "gui.blocklyText.obloqMp.whenDebugInfo": "Obloq 获取 [DEBUG] [ERROR]",
    "gui.blocklyText.obloqMp.DEBUG": "调试信息",
    "gui.blocklyText.obloqMp.ERROR": "错误码",
    "gui.blocklyText.obloqMp.httpInitialParameter": "Obloq http初始化 [PARAMETER] Rx(绿) [PINR] Tx(蓝) [PINT]",
    "gui.blocklyText.obloqMp.httpInitialI2CParameter": "Obloq http 初始化 [PARAMETER] 接口I2C",
    "gui.blocklyText.obloqMp.httpGet": "Obloq http(get) url: [URL] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.httpPost": "Obloq http(post) url: [URL] content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.httpPut": "Obloq http(put) url: [URL] content: [CONTENT] 超时(ms): [TIMEOUT]",
    "gui.blocklyText.obloqMp.wifiGetLocalIP": "获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.obloqMp.getWiFiLocalIP": "IP地址",
    "gui.blocklyText.obloqMp.wifi": "Wi-Fi",
    "gui.blocklyText.obloqMp.SSID": "Wi-Fi名称",
    "gui.blocklyText.obloqMp.PWD": "Wi-Fi密码",
    "gui.blocklyText.obloqMp.iot": "平台",
    "gui.blocklyText.obloqMp.EasyIOT_Server": "Easy物联平台",
    "gui.blocklyText.obloqMp.EasyIOTServerChina": "中国",
    "gui.blocklyText.obloqMp.EasyIOTServerGlobal": "全球",
    "gui.blocklyText.obloqMp.Port": "端口",
    "gui.blocklyText.obloqMp.Username": "用户名",
    "gui.blocklyText.obloqMp.Password": "密码",
    "gui.blocklyText.obloqMp.siotServer": "Siot物联平台",
    "gui.blocklyText.serialPort.whenReceivedData": "当接收到串口消息 [MESSAGE] [VALUE]",
    "gui.blocklyText.serialPort.value": "值",
    "gui.blocklyText.serialPort.readSerialData": "读取串口 [MESSAGE] 的值",
    "gui.blocklyText.serialPort.serialPortSendMessage": "发送串口消息 [MESSAGE] 值 [VALUE]",
    "gui.extension.pyAutoGui.pyAutoGuiModule": "pyautogui 快捷功能",
    "gui.extension.pyAutoGui.init": "初始化pyautogui",
    "gui.extension.pyAutoGui.getPosition": "获取当前鼠标的坐标([X], [Y])",
    "gui.extension.pyAutoGui.getSize": "获取当前屏幕分辨率([Width], [Height])",
    "gui.extension.pyAutoGui.height": "高度",
    "gui.extension.pyAutoGui.width": "宽度",
    "gui.extension.pyAutoGui.onScreen": "判断坐标([X], [Y])是否在屏幕内",
    "gui.extension.pyAutoGui.setDelay": "设置PyAutoGUI调用延迟为[Delay]",
    "gui.extension.pyAutoGui.setFailSafe": "[Switch]故障安全模式",
    "gui.extension.pyAutoGui.mouse": "鼠标功能区",
    "gui.extension.pyAutoGui.mouseMoveToOrRel": "将鼠标移动到[Attribute]([X],[Y]) 延迟[Seconds]秒 设置样式[Style]",
    "gui.extension.pyAutoGui.mouseDragToOrRel": "将鼠标拖拽到[Attribute]([X],[Y]) 延迟[Seconds]秒 点击鼠标[Key]",
    "gui.extension.pyAutoGui.mouseClick": "将鼠标移动到([X], [Y]), 以间隔[Seconds]秒的速度鼠标[Key]点击[Frequency]次",
    "gui.extension.pyAutoGui.mouseDownOrUp": "将鼠标移动到([X], [Y]), 并[Action]鼠标[Key]",
    "gui.extension.pyAutoGui.mouseScroll": "鼠标滚动[NUM]个刻度",
    "gui.extension.pyAutoGui.keyboard": "键盘功能区",
    "gui.extension.pyAutoGui.keyboardWrite": "键盘以[NUM]秒的间隔输出[String]",
    "gui.extension.pyAutoGui.keyboardDownOrUp": "键盘点击[NUM]次[Key]键",
    "gui.extension.pyAutoGui.keyboardPress": "[Button][Key]",
    "gui.extension.pyAutoGui.keyboardHold": "保持按下[Key]",
    "gui.extension.pyAutoGui.keyboardHotkey": "按下快捷键(组合键)[Key]",
    "gui.extension.pyAutoGui.message": "信息框功能区",
    "gui.extension.pyAutoGui.messageSetting": "显示一个[SETTING]的消息框",
    "gui.extension.pyAutoGui.image": "图片功能区",
    "gui.extension.pyAutoGui.cbImg": "获取整个屏幕[Pos]的图像数据保存为图片[Screen]并返回对象",
    "gui.extension.pyAutoGui.getRgb": "获取[Obj]([X],[Y])的RGB颜色",
    "gui.extension.pyAutoGui.getPixel": "获取整个屏幕([X],[Y])的RGB颜色",
    "gui.extension.pyAutoGui.pixelMatchesColor": "屏幕上([X],[Y])的RGB颜色和[Rgb]是否相同 匹配范围+-[Tolerance]",
    "gui.extension.pyAutoGui.locateOnScreen": "获取屏幕中图片对象[Image][Setting]坐标 灰度匹配[Grayscale]",
    "gui.extension.pyAutoGui.locateOn": "获取[Screen]中图片对象[Image][Setting]坐标 灰度匹配[Grayscale]",
    "gui.extension.pyAutoGui.imgCenter": "获取图片对象[Image]的中心坐标",
    "gui.blocklyText.pyAutoGui.messageSetting": "显示一个[SETTING]的消息框",
    "gui.blocklyText.pyAutoGui.platform": "消息框类型",
    "gui.blocklyText.pyAutoGui.simpleMessageBox": "简单消息框",
    "gui.blocklyText.pyAutoGui.messageBox": "消息框",
    "gui.blocklyText.pyAutoGui.inputMessageBox": "输入消息框",
    "gui.blocklyText.pyAutoGui.inputPasswordMessageBox": "密码消息框",
    "gui.blocklyText.pyAutoGui.title": "标题",
    "gui.blocklyText.pyAutoGui.text": "文本",
    "gui.blocklyText.pyAutoGui.okButton": "确认按钮名称",
    "gui.blocklyText.pyAutoGui.cancelButton": "取消按钮名称",
    "gui.blocklyText.pyAutoGui.inputDefault": "输入框默认值",
    "gui.blocklyText.pyAutoGui.encrypted": "加密",
    "gui.blocklyText.pyAutoGui.false": "关闭",
    "gui.blocklyText.pyAutoGui.true": "开启",
    "gui.blocklyText.pyAutoGui.None": "无",
    "gui.blocklyText.pyAutoGui.mouseLeft": "左键",
    "gui.blocklyText.pyAutoGui.mouseMiddle": "中间键",
    "gui.blocklyText.pyAutoGui.mouseRight": "右键",
    "gui.blocklyText.pyAutoGui.INQUAD": "缓慢开始",
    "gui.blocklyText.pyAutoGui.OUTQUAD": "缓慢结束",
    "gui.blocklyText.pyAutoGui.INOUTQUAD": "缓慢开始结束",
    "gui.blocklyText.pyAutoGui.INBOUNCE": "小幅度橡皮筋",
    "gui.blocklyText.pyAutoGui.OUTELASTIC": "大幅度橡皮筋",
    "gui.blocklyText.pyAutoGui.ActionDown": "按下",
    "gui.blocklyText.pyAutoGui.ActionUp": "松开",
    "gui.blocklyText.pyAutoGui.DirDown": "向下",
    "gui.blocklyText.pyAutoGui.DirUp": "向上",
    "gui.blocklyText.pyAutoGui.ABS": "绝对位置",
    "gui.blocklyText.pyAutoGui.REL": "相对位置",
    "gui.extension.pyRequests.request": "request",
    "gui.extension.pyRequests.requestGet": "发起一个GET请求[Url]",
    "gui.extension.pyRequests.requestPost": "发起一个[Data]POST请求[Url]",
    "gui.extension.pyRequests.getStatusCode": "获取[ReqObj]请求代码",
    "gui.extension.pyRequests.raiseForStatus": "检查[ReqObj]请求是否成功",
    "gui.extension.pyRequests.apparentEncoding": "获取[ReqObj]的真实编码",
    "gui.extension.pyRequests.setEncoding": "设置[ReqObj]的编码为[Code]",
    "gui.extension.pyRequests.handleHTML": "解析[ReqObj]请求内容为[Option]",
    "gui.extension.pyRequests.handleHeader": "获取[ReqObj]头部",
    "gui.extension.pyRequests.getValue": "获取[Item]的[Key]的值",
    "gui.blocklyText.pyRequests.text": "文本格式",
    "gui.blocklyText.pyRequests.json": "JSON格式",
    "gui.blocklyText.pyRequests.content": "二进制格式",
    "gui.extension.pyJson.dumpName": "编码",
    "gui.extension.pyJson.jsonDump": "编码JSON数据[Data]并保存到[FileObj] ASCII码转义[Switch]",
    "gui.extension.pyJson.jsonDumps": "编码JSON数据[Data]成字符串 ASCII码转义[Switch]",
    "gui.extension.pyJson.loadName": "解码",
    "gui.extension.pyJson.jsonload": "解码JSON文件对象[FileObj]成Python对象[PyObj]",
    "gui.extension.pyJson.jsonloads": "解码JSON数据[Data]成Python对象[PyObj]",
    "gui.extension.pyJson.valueName": "值的操作",
    "gui.extension.pyJson.get": "获取[PyObj][Key]的值",
    "gui.extension.pyJson.set": "设置[PyObj][Key]的值为[Value]",
    "gui.extension.pySchedule.createJobTitle": "任务创建",
    "gui.extension.pySchedule.createJob": "创建每间隔[Num][TimeType]运行一次的任务[JobName]",
    "gui.extension.pySchedule.createOneJob": "创建每[WEEKTYPES]运行一次的任务[JobName]",
    "gui.extension.pySchedule.createRandomJob": "创建每间隔[Num]-[LastNum][TimeType]之间随机运行一次的任务[JobName]",
    "gui.extension.pySchedule.setJobExactTime": "设置任务[JobName]的准确时间[Time]",
    "gui.extension.pySchedule.setJobTags": "设置任务[JobName]的标签[Tags]",
    "gui.extension.pySchedule.setJobUntil": "设置任务[JobName]的截至时间[Until]",
    "gui.extension.pySchedule.setJobFunction": "设置任务[JobName]的运行方法[Function](参数[Args])",
    "gui.extension.pySchedule.taskManageTitle": "任务管理",
    "gui.extension.pySchedule.cancelJob": "取消任务[JobName]",
    "gui.extension.pySchedule.getAllJob": "获取所有符合条件[Tags]的任务",
    "gui.extension.pySchedule.cancelAllJob": "取消所有符合条件[Tag]的任务",
    "gui.extension.pySchedule.getNextJobTime": "获取下一次任务运行的时间(秒)",
    "gui.extension.pySchedule.runAllJob": "运行所有任务",
    "gui.extension.pySchedule.runAllJobToo": "立刻获取所有任务的结果, 设置任务间隔[Num]秒",
    "gui.blocklyText.pySchedule.WEEKS": "周",
    "gui.blocklyText.pySchedule.DAYS": "天",
    "gui.blocklyText.pySchedule.HOURS": "时",
    "gui.blocklyText.pySchedule.MINUTES": "分",
    "gui.blocklyText.pySchedule.SECONDS": "秒",
    "gui.blocklyText.pySchedule.monday": "周一",
    "gui.blocklyText.pySchedule.tuesday": "周二",
    "gui.blocklyText.pySchedule.wednesday": "周三",
    "gui.blocklyText.pySchedule.thursday": "周四",
    "gui.blocklyText.pySchedule.friday": "周五",
    "gui.blocklyText.pySchedule.saturday": "周六",
    "gui.blocklyText.pySchedule.sunday": "周天",
    "gui.extension.pyXlrd.init": "文件读取",
    "gui.extension.pyXlrd.openWorkbook": "打开Excel工作簿[File]为[FileObj]",
    "gui.extension.pyXlrd.tableTitle": "工作表",
    "gui.extension.pyXlrd.getSheets": "获取[FileObj]的所有工作表",
    "gui.extension.pyXlrd.getSheetsName": "获取[FileObj]的所有工作表名称",
    "gui.extension.pyXlrd.selectSheet": "获取[FileObj]的[Screen]为[Value]的工作表对象[TableObj]",
    "gui.extension.pyXlrd.rowTitle": "行的操作",
    "gui.extension.pyXlrd.getRowNum": "获取[TableObj]的行数",
    "gui.extension.pyXlrd.getRow": "获取[TableObj]第[Num]行的从第[StartNum]列到第[EndNum]列的所有信息",
    "gui.extension.pyXlrd.getGenerator": "返回一个迭代[TableObj]的每一行信息的生成器",
    "gui.extension.pyXlrd.getRowType": "获取[TableObj]第[Num]行的从第[StartNum]列到第[EndNum]列的数据类型",
    "gui.extension.pyXlrd.getRowValue": "获取[TableObj]第[Num]行的从第[StartNum]列到第[EndNum]列的数据值",
    "gui.extension.pyXlrd.getRowLen": "获取[TableObj]第[Num]行有多少列",
    "gui.extension.pyXlrd.colTitle": "列的操作",
    "gui.extension.pyXlrd.getColNum": "获取[TableObj]的列数",
    "gui.extension.pyXlrd.getCol": "获取[TableObj]第[Num]列的从第[StartNum]行到第[EndNum]行的所有信息",
    "gui.extension.pyXlrd.getColType": "获取[TableObj]第[Num]列的从第[StartNum]行到第[EndNum]行的数据类型",
    "gui.extension.pyXlrd.getColValue": "获取[TableObj]第[Num]列的从第[StartNum]行到第[EndNum]行的数据值",
    "gui.extension.pyXlrd.cellTitle": "单元格的操作",
    "gui.extension.pyXlrd.getCell": "获取[TableObj]第[RowNum]行第[ColNum]列的单元格对象[CellObj]",
    "gui.extension.pyXlrd.getCellType": "获取[TableObj]第[RowNum]行第[ColNum]列的单元格类型",
    "gui.extension.pyXlrd.getCellValue": "获取[TableObj]第[RowNum]行第[ColNum]列的单元格值",
    "gui.extension.pyXlrd.optionIndex": "索引",
    "gui.extension.pyXlrd.optionName": "名称",
    "gui.extension.pyXlwt.init": "文件编辑",
    "gui.extension.pyXlwt.createWorkbook": "创建Excel文件[FileObj]并设置文件编码[Encoding]",
    "gui.extension.pyXlwt.addSheet": "为Excel文件[FileObj]添加工作表[SheetName]设置覆盖[Bool]并返回[SheetObj]",
    "gui.extension.pyXlwt.saveWorkbook": "保存Excel文件[FileObj]到[Path]",
    "gui.extension.pyXlwt.editTable": "编辑Excel工作表",
    "gui.extension.pyXlwt.createFontStyle": "创建字体样式[FontObj]",
    "gui.extension.pyXlwt.setFontBaseStyle": "设置字体[FontObj]基础样式[Style][Value]",
    "gui.extension.pyXlwt.setFontSpecialStyle": "设置字体[FontObj]特殊样式[Style]",
    "gui.extension.pyXlwt.createBorderStyle": "创建边框样式[BorderObj]",
    "gui.extension.pyXlwt.setBorderBaseStyle": "设置边框[BorderObj]基础样式[Style][Value]",
    "gui.extension.pyXlwt.createAlignmentStyle": "创建对齐样式[AlignmentObj]",
    "gui.extension.pyXlwt.setAlignmentHorzStyle": "设置对齐样式[AlignmentObj]水平方向[HorzStyle]",
    "gui.extension.pyXlwt.setAlignmentVertStyle": "设置对齐样式[AlignmentObj]垂直方向[VertStyle]",
    "gui.extension.pyXlwt.setAlignmentWrap": "设置对齐样式[AlignmentObj]自动换行[Bool]",
    "gui.extension.pyXlwt.createStyle": "创建总样式[StyleObj]",
    "gui.extension.pyXlwt.setStyle": "设置总样式[StyleObj]的[Type][Value]",
    "gui.extension.pyXlwt.easyStyle": "创建简单样式[StyleObj]样式[StyleString]数据格式[NumFormat]",
    "gui.extension.pyXlwt.eadit": "将数据[Label]以[Style]的样式写入工作表[SheetObj]的第[Row]行第[Col]列的单元格中",
    "gui.blocklyText.pyXlwt.font": "字体样式",
    "gui.blocklyText.pyXlwt.border": "边框样式",
    "gui.blocklyText.pyXlwt.alignment": "对齐样式",
    "gui.blocklyText.pyXlwt.alignLeft": "左端对齐",
    "gui.blocklyText.pyXlwt.alignRight": "右端对齐",
    "gui.blocklyText.pyXlwt.alignTop": "上端对齐",
    "gui.blocklyText.pyXlwt.centerAlign": "居中对齐",
    "gui.blocklyText.pyXlwt.alignBotton": "底端对齐",
    "gui.blocklyText.pyXlwt.top": "上边框",
    "gui.blocklyText.pyXlwt.botton": "下边框",
    "gui.blocklyText.pyXlwt.left": "左边框",
    "gui.blocklyText.pyXlwt.right": "右边框",
    "gui.blocklyText.pyXlwt.name": "字体名称",
    "gui.blocklyText.pyXlwt.height": "字体大小",
    "gui.blocklyText.pyXlwt.color_index": "字体颜色索引",
    "gui.blocklyText.pyXlwt.bold": "加粗",
    "gui.blocklyText.pyXlwt.underline": "下划线",
    "gui.blocklyText.pyXlwt.italic": "斜体",
    "gui.extension.pyOpenCV.base": "基础操作",
    "gui.extension.pyOpenCV.imread": "读取图片文件[FileName]设置[Option]并返回[ImgObj]",
    "gui.extension.pyOpenCV.imwrite": "保存图片[ImgObj]到[FileName]",
    "gui.extension.pyOpenCV.imdecode": "以[Option]模式读取[Buff]中的数据 返回图像[ImgObj]",
    "gui.extension.pyOpenCV.imencode": "将[Ext]图片[ImgObj]写入内存缓冲区[Buff]并返回是否成功写入[Ret]",
    "gui.extension.pyOpenCV.windowShow": "窗口显示",
    "gui.extension.pyOpenCV.imshow": "显示图片[ImgObj]到窗口[WinName]",
    "gui.extension.pyOpenCV.fullWindow": "设置窗口[WinName]全屏显示",
    "gui.extension.pyOpenCV.namedWindow": "创建一个名为[WinName]的窗口",
    "gui.extension.pyOpenCV.destroyWindow": "销毁一个名为[WinName]的窗口",
    "gui.extension.pyOpenCV.destroyAllWindows": "销毁全部窗口",
    "gui.extension.pyOpenCV.moveWindow": "移动一个名为[WinName]的窗口到坐标([X],[Y])",
    "gui.extension.pyOpenCV.resizeWindow": "调整一个名为[WinName]的窗口的大小([Width],[Height])",
    "gui.extension.pyOpenCV.resizeImg": "调整图像[ImgObj]尺寸为宽[Width]高[Height] 输出[OutImg]",
    "gui.extension.pyOpenCV.intercept": "截取图像[ImgObj]中第[Start]到第[End][Type]的图像 输出[OutImg]",
    "gui.extension.pyOpenCV.copyImg": "复制图片[ImgObj]到新对象[CopyImg]",
    "gui.extension.pyOpenCV.rotate": "将图片[ImgObj][RotateType]",
    "gui.extension.pyOpenCV.waitKey": "等待按键[Key]时间[Second]毫秒",
    "gui.extension.pyOpenCV.key": "按键[Key]",
    "gui.extension.pyOpenCV.selectROIs": "对图片[ImgObj]创建鼠标截图交互窗[WindowsName] [SwitchCenter]中心矩形 [SwitchCrosshair]交叉线  输出到坐标[OutImg]",
    "gui.extension.pyOpenCV.imgAttr": "图片属性",
    "gui.extension.pyOpenCV.imgShape": "获取图片[ImgObj]的行数、列数和通道数",
    "gui.extension.pyOpenCV.imgSize": "获取图片[ImgObj]的大小(像素总数)",
    "gui.extension.pyOpenCV.imgDtype": "获取图片[ImgObj]的数据类型",
    "gui.extension.pyOpenCV.copyMakeBorder": "在图片[ImgObj]上用[BorderType]添加宽度为上[TopNum]下[BottomNum]左[LeftNum]右[RightNum]的边框 输出[OutImg]",
    "gui.extension.pyOpenCV.video": "视频操作",
    "gui.extension.pyOpenCV.VideoCapture": "创建VideoCapture对象[VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureOpen": "使用VideoCapture对象[VideoObj]打开视频\\设备[VideoId]",
    "gui.extension.pyOpenCV.SetVideoCaptureSize": "设置VideoCapture对象[VideoObj]抓取分辨率为[Width]×[Height]",
    "gui.extension.pyOpenCV.VideoCaptureOpenVideo": "使用VideoCapture对象[VideoObj]打开视频文件[Video]",
    "gui.extension.pyOpenCV.VideoCaptureIsOpened": "使用VideoCapture对象[VideoObj]是否初始化完成",
    "gui.extension.pyOpenCV.VideoCaptureRelease": "关闭VideoCapture对象[VideoObj]",
    "gui.extension.pyOpenCV.VideoCaptureGrab": "VideoCapture对象[VideoObj]中是否存在下一帧",
    "gui.extension.pyOpenCV.VideoCaptureRead": "从VideoCapture对象[VideoObj]中抓取下一帧[VideoFrame]以及状态[VideoRet]",
    "gui.extension.pyOpenCV.VideoCaptureGet": "获取VideoCapture对象[VideoObj]属性[Properties]的值",
    "gui.extension.pyOpenCV.VideoCaptureSet": "设置VideoCapture对象[VideoObj]属性[Properties]的值为[Value]",
    "gui.extension.pyOpenCV.imgConvert": "图片转换",
    "gui.extension.pyOpenCV.adaptiveThreshold": "使用[Method]并配置最大值[MaxNum]阙值类型[Type]邻域大小[Size]常数[Constant]来处理图片[ImgObj]输出为图片[OutImg]",
    "gui.extension.pyOpenCV.cvtColor": "将图片[ImgObj]使用[Flags]进行转换成[OutImg]",
    "gui.extension.pyOpenCV.distanceTransform": "计算图片[ImgObj]中每个非零像素到最近零像素的[distanceType] 掩码[maskSize] 输出[Distance]",
    "gui.extension.pyOpenCV.threshold": "对图片[ImgObj]进行阈值[ThreshValue]最大值[MaxNum]阈值类型[ThresholdTypes]处理输出阈值[Ret]图片[OutImg]",
    "gui.extension.pyOpenCV.ObjectDetection": "对象检测",
    "gui.extension.pyOpenCV.CascadeClassifier": "创建级联分类器[CascadeClassifierObj]",
    "gui.extension.pyOpenCV.CascadeClassifierLoad": "级联分类器[CascadeClassifierObj]加载文件[FileName]",
    "gui.extension.pyOpenCV.CascadeClassifierEmpty": "级联分类器[CascadeClassifierObj]是否已加载完成",
    "gui.extension.pyOpenCV.detectMultiScale": "使用级联分类器[CascadeClassifierObj]通过[Method]缩放比例[ScaleFactor]构成目标的最小相邻个数[MinNeighbors]检测输入图像[ImgObj]中不同大小的对象并返回 方法3额外参数输出[OutputRejectLevels]",
    "gui.extension.pyOpenCV.Draws": "绘图工具",
    "gui.extension.pyOpenCV.putText": "在图片[ImgObj]上绘制文字[Text]左下角坐标X[PositionX]Y[PositionY]颜色[Color]字体大小[FontSize]线宽[Thickness]",
    "gui.extension.pyOpenCV.arrowedLine": "在图片[ImgObj]上绘制一条开始点[StartP]结束点[EndP]颜色[Color]粗细[Thickness]线条类型[LineType]的箭头线",
    "gui.extension.pyOpenCV.circle": "在图片[ImgObj]上绘制一条圆心[CenterP]半径[Radius]颜色[Color]粗细[Thickness]线条类型[LineType]的圆圈",
    "gui.extension.pyOpenCV.clipLine": "根据大小为[ImgSize]的矩形上裁剪一条开始点[StartP]结束点[EndP]的线并返回是否在于指定矩形[Ret]开始点[P1]结束点[P2]",
    "gui.extension.pyOpenCV.drawMarker": "在图片[ImgObj]上绘制一条位置[P]颜色[Color]标记类型[MarkerType]粗细[Thickness]标记大小[MarkerSize]线型[LineType]的标记",
    "gui.extension.pyOpenCV.ellipse": "在图片[ImgObj]上绘制一个圆心点[CenterP]轴[Axes]角度[Angle]起始角度[StartAngle]结束角度[EndAngle]颜色[Color]粗细[Thickness]线型[LineType]的椭圆",
    "gui.extension.pyOpenCV.line": "在图片[ImgObj]上绘制一个起始点[StartP]结束点[EndP]颜色[Color]粗细[Thickness]线型[LineType]的线",
    "gui.extension.pyOpenCV.rectangle": "在图片[ImgObj]上绘制一个首部顶点[TopP]尾部顶点[BottomP]颜色[Color]粗细[Thickness]线型[LineType]的矩形",
    "gui.extension.pyOpenCV.gesture": "图片[Img]中的手势是[Gesture]?",
    "gui.extension.pyOpenCV.gestureByVideo": "摄像头中的手势是[Gesture]?",
    "gui.extension.pyOpenCV.gaussian": "对图片[ImgObj]进行高斯模糊平滑处理，输出为图像[OutImg]",
    "gui.extension.pyOpenCV.canny": "对图片[ImgObj]进行边缘检测，阈值1[ThreshValue1]、阈值2[ThreshValue2]，输出边缘二值化图像[OutImg]",
    "gui.extension.pyOpenCV.contours": "在图片[ImgObj]中查找轮廓点，[Mode]模式、[Method]方法，返回结果到序列[Contours]",
    "gui.extension.pyOpenCV.drawContours": "在图片[ImgObj]上绘制轮廓线[Contours][Color]线宽[Thickness]，输出带轮廓线图像[OutImg]",
    "gui.blocklyText.pyOpenCV.retr_external": "仅外轮廓",
    "gui.blocklyText.pyOpenCV.retr_list": "无等级",
    "gui.blocklyText.pyOpenCV.retr_ccomp": "两等级树",
    "gui.blocklyText.pyOpenCV.retr_tree": "一等级树",
    "gui.blocklyText.pyOpenCV.chain_approx_none": "所有轮廓点",
    "gui.blocklyText.pyOpenCV.chain_approx_simple": "仅终点坐标",
    "gui.blocklyText.pyOpenCV.chain_approx_tc89_l1": "近似算法",
    "gui.blocklyText.pyOpenCV.imread_unchanged": "原图",
    "gui.blocklyText.pyOpenCV.imread_grayscale": "灰度图",
    "gui.blocklyText.pyOpenCV.imread_color": "BGR图",
    "gui.blocklyText.pyOpenCV.border_replicate": "复刻法",
    "gui.blocklyText.pyOpenCV.border_reflect": "反射法",
    "gui.blocklyText.pyOpenCV.border_reflect101": "反射法101",
    "gui.blocklyText.pyOpenCV.border_wrap": "外包装法",
    "gui.blocklyText.pyOpenCV.border_constant": "常量法",
    "gui.blocklyText.pyOpenCV.column": "列",
    "gui.blocklyText.pyOpenCV.row": "行",
    "gui.blocklyText.pyOpenCV.rotate_90_clockwise": "顺时针旋转90度",
    "gui.blocklyText.pyOpenCV.rotate_180": "旋转180度",
    "gui.blocklyText.pyOpenCV.rotate_90_counterclockwise": "逆时针旋转90度",
    "gui.blocklyText.pyOpenCV.cap_prop_buffersize": "图像缓冲区大小",
    "gui.blocklyText.pyOpenCV.cap_prop_pos_msec": "当前视频播放到的位置",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_width": "当前视频宽度",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_height": "当前视频高度",
    "gui.blocklyText.pyOpenCV.cap_prop_fps": "当前视频帧率",
    "gui.blocklyText.pyOpenCV.cap_prop_frame_count": "当前视频帧数",
    "gui.blocklyText.pyOpenCV.adaptive_thresh_mean_c": "平均值算法",
    "gui.blocklyText.pyOpenCV.adaptive_thresh_gaussian_c": "高斯均值算法",
    "gui.blocklyText.pyOpenCV.thresh_binary": "超过阙值操作取最大值, 除此之外取0",
    "gui.blocklyText.pyOpenCV.thresh_binary_inv": "超过阙值操作取0, 除此之外取最大值",
    "gui.blocklyText.pyOpenCV.thresh_trunc": "超过阙值操作取临界点, 除此之外不变",
    "gui.blocklyText.pyOpenCV.thresh_tozero": "超过阙值操作不变, 除此之外取0",
    "gui.blocklyText.pyOpenCV.thresh_tozero_inv": "超过阙值操作取0, 除此之外不变",
    "gui.blocklyText.pyOpenCV.color_bgr2bgra": "将 Alpha 通道添加到 BGR",
    "gui.blocklyText.pyOpenCV.color_rgb2rgba": "将 Alpha 通道添加到 RGB",
    "gui.blocklyText.pyOpenCV.color_bgra2bgr": "从 BGR 图像中删除 alpha 通道",
    "gui.blocklyText.pyOpenCV.color_rgba2rgb": "从 RGB 图像中删除 alpha 通道",
    "gui.blocklyText.pyOpenCV.color_bgr2rgba": "在 BGR 和 RGB 颜色空间之间转换",
    "gui.blocklyText.pyOpenCV.color_rgb2bgra": "在 RGB 和 BGR 颜色空间之间转换",
    "gui.blocklyText.pyOpenCV.color_bgr2gray": "在 BGR 和灰度之间转换",
    "gui.blocklyText.pyOpenCV.color_rgb2gray": "在 RGB 和灰度之间转换",
    "gui.blocklyText.pyOpenCV.color_gray2bgr": "从灰度空间转换到 BGR",
    "gui.blocklyText.pyOpenCV.color_bgr2hsv": "在 BGR 与 HSV 颜色空间之间相互转换",
    "gui.blocklyText.pyOpenCV.color_rgb2hsv": "在 RGB 与 HSV 颜色空间之间相互转换",
    "gui.blocklyText.pyOpenCV.dist_l1": "曼哈顿距离",
    "gui.blocklyText.pyOpenCV.dist_l2": "简单欧几里得的距离",
    "gui.blocklyText.pyOpenCV.dist_c": "切比雪夫距离",
    "gui.blocklyText.pyOpenCV.detectmultiscale": "常用检测方法",
    "gui.blocklyText.pyOpenCV.detectmultiscale2": "检测方法2",
    "gui.blocklyText.pyOpenCV.detectmultiscale3": "检测方法3",
    "gui.blocklyText.pyOpenCV.filled": "填充线",
    "gui.blocklyText.pyOpenCV.line_4": "4连线",
    "gui.blocklyText.pyOpenCV.line_8": "8连线",
    "gui.blocklyText.pyOpenCV.line_aa": "抗锯齿线",
    "gui.blocklyText.pyOpenCV.marker_cross": " 十字线标记形状",
    "gui.blocklyText.pyOpenCV.marker_tilted_cross": " x准线标记形状",
    "gui.blocklyText.pyOpenCV.marker_star": " 星形标记形状",
    "gui.blocklyText.pyOpenCV.marker_diamond": " 菱形标记形状",
    "gui.blocklyText.pyOpenCV.marker_square": " 方形标记形状",
    "gui.blocklyText.pyOpenCV.marker_triangle_up": " 向上指向的三角形标记形状",
    "gui.blocklyText.pyOpenCV.marker_triangle_down": " 向下指向的三角形标记形状",
    "gui.blocklyText.pyOpenCV.haarcascade_eye": "眼睛数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_eye_tree_eyeglasses": "是否带墨镜数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalcatface": "猫脸数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalcatface_extended": "猫脸延伸数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt": "人脸(alt)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt_tree": "人脸(alt_tree)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_alt2": "人脸(alt2)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_frontalface_default": "人脸(默认)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_fullbody": "身体数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_lefteye_2splits": "眼部(左眼)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_licence_plate_rus_16stages": "证件数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_lowerbody": "下半身数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_profileface": "人脸(侧视)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_righteye_2splits": "眼部(右眼)数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_russian_plate_number": "俄罗斯字母车牌号数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_smile": "嘴部数据模型",
    "gui.blocklyText.pyOpenCV.haarcascade_upperbody": "上半身数据模型",
    "gui.blocklyText.pyOpenCV.gesture1": "手势1",
    "gui.blocklyText.pyOpenCV.gesture2": "手势2",
    "gui.blocklyText.pyOpenCV.gesture3": "手势3",
    "gui.blocklyText.pyOpenCV.gesture4": "手势4",
    "gui.blocklyText.pyOpenCV.gesture5": "手势5",
    "gui.blocklyText.unihiker.updateParamsMenu_width": "宽",
    "gui.blocklyText.unihiker.updateParamsMenu_height": "高",
    "gui.blocklyText.unihiker.updateParamsMenu_radial": "半径",
    "gui.blocklyText.unihiker.updateParamsMenu_linewidth": "线宽",
    "gui.blocklyText.unihiker.updateParamsMenu_start": "起始点",
    "gui.blocklyText.unihiker.updateParamsMenu_end": "终止点",
    "gui.blocklyText.unihiker.standardPointMenu_center": "中心",
    "gui.blocklyText.unihiker.standardPointMenu_top": "上",
    "gui.blocklyText.unihiker.standardPointMenu_bottom": "下",
    "gui.blocklyText.unihiker.standardPointMenu_left": "左",
    "gui.blocklyText.unihiker.standardPointMenu_right": "右",
    "gui.blocklyText.unihiker.standardPointMenu_topleft": "左上角",
    "gui.blocklyText.unihiker.standardPointMenu_topright": "右上角",
    "gui.blocklyText.unihiker.standardPointMenu_bottomleft": "左下角",
    "gui.blocklyText.unihiker.standardPointMenu_bottomright": "右下角",
    "gui.blocklyText.unihiker.GESTURE_gesture1": "手势1",
    "gui.blocklyText.unihiker.GESTURE_gesture2": "手势2",
    "gui.blocklyText.unihiker.GESTURE_gesture3": "手势3",
    "gui.blocklyText.unihiker.GESTURE_gesture4": "手势4",
    "gui.blocklyText.unihiker.GESTURE_gesture5": "手势5",
    "gui.blocklyText.unihiker.xyzsMenu_S": "强度",
    "gui.blocklyText.unihiker.expressionMenu_Angry": "愤怒",
    "gui.blocklyText.unihiker.expressionMenu_Nerve": "紧张",
    "gui.blocklyText.unihiker.expressionMenu_Peace": "平静",
    "gui.blocklyText.unihiker.expressionMenu_Shock": "惊讶",
    "gui.blocklyText.unihiker.expressionMenu_Sleep": "睡觉",
    "gui.blocklyText.unihiker.expressionMenu_Smile": "微笑",
    "gui.blocklyText.unihiker.expressionMenu_Sweat": "冒汗",
    "gui.blocklyText.unihiker.expressionMenu_Think": "思考",
    "gui.blocklyText.unihiker.expressionMenu_Wink": "眨眼",
    "gui.blocklyText.unihiker.unihikerPinMenu_button": "键",
    "gui.blocklyText.unihiker.blockTitle.screenDisplay": "屏幕显示",
    "gui.blocklyText.unihiker.showText": "对象名[NAME]显示文字[TEXT]在X[X] Y[Y] 字号[FONTSIZE]颜色[COLOR]",
    "gui.blocklyText.unihiker.showTextWithDigitalTubeStyle": "对象名[NAME]显示仿数码管字体[TEXT]在:X[X] Y[Y] 字号[FONTSIZE]颜色[COLOR]",
    "gui.blocklyText.unihiker.showImage": "对象名[NAME]显示图片[IMG]在X[X] Y[Y]",
    "gui.blocklyText.unihiker.showInsetLiveExpression": "对象名[NAME]显示内置动态表情[EXPRESSION]在X[X] Y[Y] 间隔[DURATION]秒",
    "gui.blocklyText.unihiker.addButtonClickFun": " 对象名[NAME]增加按钮[BUTTON]在X[X] Y[Y] 宽[WIDTH]高[HEIGHT] 点击回调函数[FUN]",
    "gui.blocklyText.unihiker.showClock": "对象名[NAME]显示时钟在X[X] Y[Y] 半径[RADIUS] 颜色[COLOR]",
    "gui.blocklyText.unihiker.showClockWithFillStyle": "对象名[NAME]显示填充时钟在X[X] Y[Y] 半径[RADIUS]边框颜色[COLOR1]填充颜色[COLOR2]",
    "gui.blocklyText.unihiker.showQrCode": "对象名[NAME]显示二维码 内容[VALUE]在X[X] Y[Y]边长[WIDTH]",
    "gui.blocklyText.unihiker.drawLine": "对象名[NAME]显示线段 起点X[X1] Y[Y1] 终点X[X2] Y[Y2] 线宽[LINEWIDTH] 颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRectangle": "对象名[NAME]显示矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]线宽[LINEWIDTH]边框颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRectangleWithFillStyle": "对象名[NAME]显示填充矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]填充颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRoundRectangle": "对象名[NAME]显示圆角矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]圆角半径[RADIUS]线宽[LINEWIDTH]边框颜色[COLOR]",
    "gui.blocklyText.unihiker.drawRoundRectangleWithFillStyle": "对象名[NAME]显示圆角填充矩形 在X[X] Y[Y] 宽[WIDTH]高[HEIGHT]圆角半径[RADIUS]填充颜色[COLOR]",
    "gui.blocklyText.unihiker.drawCircle": "对象名[NAME]显示圆形在X[X] Y[Y] 半径[RADIUS] 线宽[LINEWIDTH] 边框颜色[COLOR]",
    "gui.blocklyText.unihiker.drawCircleWithFillStyle": "对象名[NAME]显示填充圆形在X[X] Y[Y] 半径[RADIUS] 填充颜色[COLOR]",
    "gui.blocklyText.unihiker.updateObjectNumberParams": "更新对象名[NAME]的数字参数[PARAMS]为[VALUE]",
    "gui.blocklyText.unihiker.updateObjectNumbersParams": "更新对象名[NAME]的数字参数[PARAMS1]为[VALUE1] [PARAMS2]为[VALUE2]",
    "gui.blocklyText.unihiker.updateObjectStringParams": "更新对象名[NAME]的文本内容参数为[VALUE]",
    "gui.blocklyText.unihiker.updateObjectColorPalette": "更新对象名[NAME]的颜色为[COLOR]",
    "gui.blocklyText.unihiker.updateObjectColorRGB": "更新对象名[NAME]的颜色为 红[RED]绿[GREEN]蓝[BLUE]",
    "gui.blocklyText.unihiker.updateObjectColorName": "更新对象名[NAME]的颜色为[COLOR]",
    "gui.blocklyText.unihiker.updateObjectStandardPoint": "更新对象名[NAME]的基准点为[POSITION]",
    "gui.blocklyText.unihiker.updateTimeObjectValue": "更新时钟对象[NAME]的时间为[HOUR]时[MINUTE]分[SECOND]秒",
    "gui.blocklyText.unihiker.setObjClickFun": "对象名[NAME]的点击回调函数为[FUNCTIONNAME]",
    "gui.blocklyText.unihiker.whenClickTriggerFun": "当点击回调函数[FUNCTIONNAME]被触发",
    "gui.blocklyText.unihiker.updateImgObjSrc": "更新图片对象[IMG]图片源为[SRC]",
    "gui.blocklyText.unihiker.updateExpressionObjSrc": "更新表情对象[NAME]表情源为[EXPRESSION]",
    "gui.blocklyText.unihiker.blockTitle.mouseAndKeyboardEvent": "鼠标键盘事件",
    "gui.blocklyText.unihiker.whenMouseMove": "当接收到鼠标移动事件 返回坐标[X] [Y]",
    "gui.blocklyText.unihiker.whenButtonABPress": "当行空板按键[BUTTONAB]被按下",
    "gui.blocklyText.unihiker.whenKeyboardPress": "当键盘按键[KEYBOARD]被按下",
    "gui.blocklyText.unihiker.numberKeyboard": "数字及字母键[KEYBOARD]",
    "gui.blocklyText.unihiker.otherKeyboard": "其他键盘[KEYBOARD]",
    "gui.blocklyText.unihiker.waitForWhenButtonABPress": "等待直到行空板按键[BUTTONAB]被按下",
    "gui.blocklyText.unihiker.blockTitle.multithreading": "多线程",
    "gui.blocklyText.unihiker.startThread": "线程对象[THREAD]启动",
    "gui.blocklyText.unihiker.quitThread": "线程对象[THREAD]停止",
    "gui.blocklyText.unihiker.whenThreadStart": "当线程对象[THREAD]启动后执行",
    "gui.blocklyText.unihiker.blockTitle.lmSensors": "板载传感器",
    "gui.blocklyText.unihiker.isButtonABPressed": "按钮[BUTTONAB]被按下？",
    "gui.blocklyText.unihiker.readAmbientLight": "读取环境光强度",
    "gui.blocklyText.unihiker.readMicrophoneSound": "读取麦克风声音强度",
    "gui.blocklyText.unihiker.readXYZSAcceleratedSpeed": "读取加速度的值[XYZS]",
    "gui.blocklyText.unihiker.readGyroscopeXYZ": "读取陀螺仪的值[XYZ]",
    "gui.blocklyText.unihiker.blockTitle.onboardBuzzer": "板载蜂鸣器",
    "gui.blocklyText.unihiker.playMusic": "播放音乐[MUSIC]重复[REPEAT]",
    "gui.blocklyText.unihiker.musicSetTempo": "设置每拍等同[TICKS]分音符， 每分钟节拍数[TEMPO]",
    "gui.blocklyText.unihiker.setBuzzerFreq": "播放音符[NOTE] [BEAT]拍",
    "gui.blocklyText.unihiker.backSetBuzzerFreq": "后台播放音符[NOTE]",
    "gui.blocklyText.unihiker.buzzerSwitch": "停止后台播放",
    "gui.blocklyText.unihiker.buzzerRedirect": "蜂鸣器重定向到引脚[PIN]",
    "gui.blocklyText.unihiker.blockTitle.pinOperation": "引脚操作",
    "gui.blocklyText.unihiker.unihikerPin": "行空板引脚[PIN]",
    "gui.blocklyText.unihiker.digitalRead": "读取数字引脚 [PIN]",
    "gui.blocklyText.unihiker.analogRead": "读取模拟引脚(ADC 0-4095)[PIN]",
    "gui.blocklyText.unihiker.digitalWrite": "设置数字引脚[PIN]输出[VALUE]",
    "gui.blocklyText.unihiker.analogWrite": "设置模拟引脚[PIN] 输出(PWM)[VALUE]",
    "gui.blocklyText.unihiker.blockTitle.audioPlayback": "音频录放",
    "gui.blocklyText.unihiker.getRecord": "录音[TIME]秒直到结束，文件名[NAME]",
    "gui.blocklyText.unihiker.getRecordBackground": "开始后台录音，文件名[NAME]",
    "gui.blocklyText.unihiker.stopRecordBackground": "结束后台录音",
    "gui.blocklyText.unihiker.playAudio": "播放音频文件[NAME]直到结束",
    "gui.blocklyText.unihiker.startPlayAudioBackground": "开始后台播放音频文件[NAME]",
    "gui.blocklyText.unihiker.getBgAudioRemainingDuration": "获取后台播放音频的剩余时长(秒)",
    "gui.blocklyText.unihiker.pauseBgAudio": "暂停后台播放",
    "gui.blocklyText.unihiker.continueBgAudio": "重新开始后台播放",
    "gui.blocklyText.unihiker.stopBgAudio": "结束后台播放",
    "gui.extension.unihiker.input.button": "按钮",
    "gui.extension.unihiker.input.hello": "你好",
    "gui.blocklyText.unihiker.updateButtonStatus": "更新按钮对象[BUTTON]为[STATUS]",
    "gui.blocklyText.unihiker.updateObjectParamWithInput": "更新对象[OBJECT]的参数[KEY]为[VALUE]",
    "gui.blocklyText.unihiker.updateObjectParamsWithInput": "更新对象[OBJECT]的参数1[KEY1]为[VALUE1] 参数2[KEY2]为[VALUE2]",
    "gui.blocklyText.unihiker.removeObject": "删除对象[OBJECT]",
    "gui.blocklyText.unihiker.removeAllObject": "删除所有对象",
    "gui.blocklyText.unihiker.buttonStatusMenu_normal": "启用",
    "gui.blocklyText.unihiker.buttonStatusMenu_disable": "禁用",
    "gui.blocklyText.unihiker.updateParamsMenu_fontSize": "字体大小",
    "gui.blocklyText.unihiker.generate.keyboardWithButtonConflict": "！错误提示：键盘按键[AB]与行空板按键[AB]事件仅能同时使用一个，请删除重复定义的模块",
    "gui.blocklyText.pythonsen0291.extensionName": "功率计",
    "gui.blocklyText.pythondfr0553.extensionName": "AD 模块",
    "gui.blocklyText.pythondfr0563.extensionName": "电量计",
    "gui.blocklyText.pyBaiDuAi.app_id": "AppID",
    "gui.blocklyText.pyBaiDuAi.api_key": "API Key",
    "gui.blocklyText.pyBaiDuAi.secret_key": "Secret Key",
    "gui.blocklyText.pyBaiDuAi.age": "年龄",
    "gui.blocklyText.pyBaiDuAi.gender": "性别",
    "gui.blocklyText.pyBaiDuAi.beauty": "颜值",
    "gui.blocklyText.pyBaiDuAi.face_shape": "脸型",
    "gui.blocklyText.pyBaiDuAi.glasses": "眼镜",
    "gui.blocklyText.pyBaiDuAi.expression": "表情",
    "gui.blocklyText.pyBaiDuAi.face_probability": "真人",
    "gui.blocklyText.pyBaiDuAi.Width": "宽度",
    "gui.blocklyText.pyBaiDuAi.Height": "高度",
    "gui.blocklyText.pyBaiDuAi.mask": "口罩",
    "gui.blocklyText.pyBaiDuAi.objectDetect": "图像单主体检测",
    "gui.blocklyText.pyBaiDuAi.advancedGeneral": "通用物体和场景",
    "gui.blocklyText.pyBaiDuAi.plantDetect": "植物",
    "gui.blocklyText.pyBaiDuAi.animalDetect": "动物",
    "gui.blocklyText.pyBaiDuAi.currency": "货币",
    "gui.blocklyText.pyBaiDuAi.basicGeneral": "通用文字",
    "gui.blocklyText.pyBaiDuAi.numbers": "数字",
    "gui.blocklyText.pyBaiDuAi.licensePlate": "车牌",
    "gui.blocklyText.pyBaiDuAi.handwriting": "手写文字",
    "gui.blocklyText.pyBaiDuAi.init": "初始化",
    "gui.blocklyText.pyBaiDuAi.AipFaceInit": "初始化百度人脸识别 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipImageClassifyInit": "初始化百度图像识别 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipOcrInit": "初始化百度文字识别 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.AipBodyAnalysisInit": "初始化百度人体分析 [SETTING]",
    "gui.blocklyText.pyBaiDuAi.getFace": "获取人脸对象",
    "gui.blocklyText.pyBaiDuAi.getImage": "获取图片对象",
    "gui.blocklyText.pyBaiDuAi.face": "人脸识别",
    "gui.blocklyText.pyBaiDuAi.generationFaceFormBase64": "从本地生成人脸图片对象 [IMG]",
    "gui.blocklyText.pyBaiDuAi.generationFaceFormUrl": "从网络生成人脸图片对象 [URL]",
    "gui.blocklyText.pyBaiDuAi.detect": "识别 [Image] 人脸信息并存入结果",
    "gui.blocklyText.pyBaiDuAi.detectIsOk": "人脸识别是否成功 ?",
    "gui.blocklyText.pyBaiDuAi.detectGet": "获取人脸信息的 [TYPE]",
    "gui.blocklyText.pyBaiDuAi.match": "对比 [Face1] 和 [Face2] 的人脸相似度 ",
    "gui.blocklyText.pyBaiDuAi.groupAdd": "创建人脸库 [GroupName]",
    "gui.blocklyText.pyBaiDuAi.addUser": "添加名为 [FaceName] 的用户的人脸 [Face] 到人脸库 [GroupName]",
    "gui.blocklyText.pyBaiDuAi.img": "图像识别",
    "gui.blocklyText.pyBaiDuAi.imageDetectType": "识别图中 [IMG] 包含的 [DetectType]",
    "gui.blocklyText.pyBaiDuAi.ocr": "文字识别",
    "gui.blocklyText.pyBaiDuAi.body": "人体分析",
    "gui.blocklyText.pyBaiDuAi.gesture": "识别图中 [IMG] 包含的手势",
    "gui.blocklyText.pyBaiDuAi.gestureis": "手势[Gesture] 是 [GestureType]?",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysis": "识别图中 [IMG] 包含的人体关键点",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysisIsOk": "人体关键点结果是否识别成功",
    "gui.blocklyText.pyBaiDuAi.bodyAnalysisGet": "获取人体关键点结果的[BODY]的[XY]坐标",
    "gui.blocklyText.pyBaiDuAi.BASE64": "BASE64",
    "gui.blocklyText.pyBaiDuAi.URL": "URL",
    "gui.blocklyText.pyBaiDuAi.FACE_TOKEN": "FACE_TOKEN",
    "gui.blocklyText.pyBaiDuAi.readImage": "读取图片 [IMG]",
    "gui.blocklyText.pyBaiDuAi.search": "从人脸库 [GroupName] 中搜索与 [Face] 相似度 >= [Score] 的名字",
    "gui.blocklyText.pyBaiDuSpeech.init": "初始化百度语音客户端 [SETTING]",
    "gui.blocklyText.pyBaiDuSpeech.asr": "识别一个 采样率 [Rate] [Format] 音频文件 [Speech] ",
    "gui.blocklyText.pyBaiDuSpeech.synthesisOption": "设置语音合成参数 格式[Format] 嗓音[Per] 语速[Spd] 音调[Pit] 音量[Vol]",
    "gui.blocklyText.pyBaiDuSpeech.synthesis": "请求将 [Text] 转换成语音",
    "gui.blocklyText.pyBaiDuSpeech.isOk": "语音合成成功?",
    "gui.blocklyText.pyBaiDuSpeech.saveAudio": "将合成结果保存 文件名[Audio]",
    "gui.blocklyText.pyBaiDuSpeech.pcm": "pcm文件格式",
    "gui.blocklyText.pyBaiDuSpeech.wav": "wav文件格式",
    "gui.blocklyText.pyBaiDuSpeech.amr": "amr文件格式",
    "gui.blocklyText.pyBaiDuSpeech.mp3": "mp3文件格式",
    "gui.extension.pyXfSpeech.init": "初始化",
    "gui.extension.pyXfSpeech.speechInit": "讯飞语音初始化 [SETTING]",
    "gui.extension.pyXfSpeech.speechSynthesis": "语音合成",
    "gui.extension.pyXfSpeech.speechSynthesisOption": "设置语音合成参数 语速[SPEED] 音调[PITCH] 音量[VOLUME] [SETTING]",
    "gui.extension.pyXfSpeech.speechSynthesisSend": "通过语音合成[Text]并保存到[File]中",
    "gui.extension.pyXfSpeech.speechRecognition": "语音识别",
    "gui.extension.pyXfSpeech.speechRecognitionSend": "识别[Audio]音频文件",
    "gui.blocklyText.pyXfSpeech.noBackgroundsound": "无",
    "gui.blocklyText.pyXfSpeech.withBackgroundsound": "有",
    "gui.blocklyText.pyXfSpeech.xiaoyan": "讯飞小燕",
    "gui.blocklyText.pyXfSpeech.aisjiuxu": "讯飞许久",
    "gui.blocklyText.pyXfSpeech.aisxping": "讯飞小萍",
    "gui.blocklyText.pyXfSpeech.aisjinger": "讯飞小婧",
    "gui.blocklyText.pyXfSpeech.aisbabyxu": "讯飞许小宝",
    "gui.blocklyText.pyXfSpeech.vcn": "发音人",
    "gui.blocklyText.pyXfSpeech.bgs": "背景音",
    "gui.blocklyText.pythondfr0095.extensionName": "红外发送",
    "gui.blocklyText.pythondfr0095.pinpongIRSend": "红外发送 引脚 [PIN] 发送 [DATA]",
    "gui.extension.pictureai.fist": "拳头",
    "gui.extension.pictureai.pray": "祈祷",
    "gui.extension.pictureai.bow": "作揖",
    "gui.extension.pictureai.farewell": "作别",
    "gui.extension.pictureai.one_heart": "单手比心",
    "gui.extension.pictureai.thumbs_up": "点赞",
    "gui.extension.pictureai.love_you": "我爱你",
    "gui.extension.pictureai.palm_up": "掌心向上",
    "gui.extension.pictureai.two_hearts": "双手比心",
    "gui.extension.pictureai.middle_finger": "竖中指",
    "gui.extension.pictureai.face": "脸",
    "gui.extension.pictureai.switch_account_failed": "切换独立账户失败",
    "gui.extension.pictureai.id_not_exist": "该id不存在，请检查后重试",
    "gui.extension.pictureai.error": "错误",
    "gui.extension.pictureai.file_not_exist_in_path": "该路径下不存在文件,请检查路径是否正确",
    "gui.extension.pictureai.path_not_exist": "该路径不存在,请检查路径是否正确",
    "gui.extension.pictureai.save_image_failed": "保存图片失败",
    "gui.extension.pictureai.image_recognition": "图像识别",
    "gui.extension.pictureai.no_stage_video_stream": "未获取到舞台视频流",
    "gui.extension.pictureai.please_fill_image_address": "请先填写图片地址",
    "gui.extension.pictureai.image_address_format_error": "图片地址格式错误",
    "gui.extension.pictureai.face_recognition": "人脸识别",
    "gui.extension.pictureai.main_character_in_image": "图中主要人物为",
    "gui.extension.pictureai.one_gender_age": "1个{gender}，大约{age}岁",
    "gui.extension.pictureai.male": "男生",
    "gui.extension.pictureai.female": "女生",
    "gui.extension.pictureai.wearing_glasses": ",还戴着眼镜",
    "gui.extension.pictureai.smiling": ", 在微笑",
    "gui.extension.pictureai.no_face_in_image": "图片中没有人脸，换一张再试试",
    "gui.extension.pictureai.app_authentication_failed": "应用鉴权失败，请至控制台检查是否开放人脸识别功能",
    "gui.extension.pictureai.app_usage_exceeded": "应用使用次数超出额度，请至控制台检查",
    "gui.extension.pictureai.square_face": "方脸",
    "gui.extension.pictureai.cone_face": "锥子脸",
    "gui.extension.pictureai.oval_face": "鹅蛋脸",
    "gui.extension.pictureai.heart_face": "心形脸",
    "gui.extension.pictureai.round_face": "圆脸",
    "gui.extension.pictureai.unknown_face": "未知脸",
    "gui.extension.pictureai.glasses": "眼镜",
    "gui.extension.pictureai.sunglasses": "墨镜",
    "gui.extension.pictureai.smile": "微笑",
    "gui.extension.pictureai.normal": "正常",
    "gui.extension.pictureai.angry": "愤怒",
    "gui.extension.pictureai.disgust": "厌恶",
    "gui.extension.pictureai.fear": "恐惧",
    "gui.extension.pictureai.happy": "高兴",
    "gui.extension.pictureai.sad": "悲伤",
    "gui.extension.pictureai.surprised": "惊讶",
    "gui.extension.pictureai.expressionless": "无表情",
    "gui.extension.pictureai.pouting": "撅嘴",
    "gui.extension.pictureai.making_faces": "鬼脸",
    "gui.extension.pictureai.no_mask": "没戴口罩",
    "gui.extension.pictureai.wearing_mask": "戴口罩",
    "gui.extension.pictureai.please_do_face_recognition_first": "请先进行人脸信息识别",
    "gui.extension.pictureai.face_comparison": "人脸对比",
    "gui.extension.pictureai.face_similarity": "两张图中人脸相似度为",
    "gui.extension.pictureai.ensure_faces_in_both_images": "请确定两张图片中都包含人脸",
    "gui.extension.pictureai.check_image": "请检查图片",
    "gui.extension.pictureai.text_recognition": "文字识别",
    "gui.extension.pictureai.handwriting": "手写字",
    "gui.extension.pictureai.license_plate": "车牌号",
    "gui.extension.pictureai.number": "数字",
    "gui.extension.pictureai.no_text_in_image": "图片中不包含文字",
    "gui.extension.pictureai.produced_in": ", 生产于",
    "gui.extension.pictureai.face_value": ", 面值为",
    "gui.extension.pictureai.possible_non_currency": "图中可能是非货币",
    "gui.extension.pictureai.possible_object": "图中可能是",
    "gui.extension.pictureai.gesture_recognition": "手势识别",
    "gui.extension.pictureai.someone_thumbs_up_in_image": "图片里有人为你点赞",
    "gui.extension.pictureai.someone_makes_heart_with_single_hands_in_image": "图片里有人为你单手比心",
    "gui.extension.pictureai.someone_booing_in_image": "图片里有人喝倒彩，要加油了",
    "gui.extension.pictureai.someone_says_congratulations_in_image": "图片里有人说恭喜",
    "gui.extension.pictureai.someone_says_ok_in_image": "图片里有人说OK",
    "gui.extension.pictureai.someone_prays_in_image": "图片里有人祈祷",
    "gui.extension.pictureai.someone_makes_heart_with_both_hands_in_image": "图片里有人为你双手比心",
    "gui.extension.pictureai.someone_says_goodbye_in_image": "图片里有人对你说在下告辞",
    "gui.extension.pictureai.fist_in_image": "图片里有一个拳头",
    "gui.extension.pictureai.rock_on": "摇滚起来",
    "gui.extension.pictureai.someone_despises_you_in_image": "图里有人鄙视你！",
    "gui.extension.pictureai.cannot_understand_gesture_in_image": "看不懂图片里的手势，换一张试试",
    "gui.extension.pictureai.face_in_image_no_gesture": "图里是人脸，没有手势",
    "gui.extension.pictureai.recognition_failed": "识别失败",
    "gui.extension.pictureai.human_keypoints_recognition": "人体关键点识别",
    "gui.extension.pictureai.check_image_no_valid_human": "请检查图片，图中没有找到有效人体，换一张再试试",
    "gui.extension.pictureai.result_as_shown": "结果如图",
    "gui.extension.pictureai.please_do_human_keypoints_analysis_first": "请先进行人体关键点分析",
    "gui.extension.serialPort.name": "发送数据至实时模式",
    "gui.extension.serialPortjJS.name": "接收上传模式数据",
    "gui.extension.serialPort.description": "便捷实现上传模式发送多组数据，实时模式通过串口接收数据功能",
    "gui.blocklyText.firebeetleesp32.stop": "停止",
    "gui.blocklyText.esp32.MQTTinit": "MQTT初始化参数 [SETTING]",
    "gui.blocklyText.esp32.MQTTtencent": "MQTT腾讯物联网 [SETTING]",
    "gui.blocklyText.esp32.esp32_MQTTEasyIOT": "MQTT Easy-IOT [SETTING]",
    "gui.blocklyText.esp32.MQTTconnect": "连接MQTT",
    "gui.blocklyText.esp32.MQTTpublish": "发布 [DATA] 至 主题 [TOPIC]",
    "gui.blocklyText.esp32.esp32_waitMSG": "等待主题消息 以 [MODE] 模式",
    "gui.blocklyText.esp32.disconnectMQTT": "MQTT 断开连接",
    "gui.blocklyText.esp32.MQTTlastwill": "设置MQTT主题 [TOPIC] 离线消息 [MSG]",
    "gui.blocklyText.esp32.MQTTreceive_1": "当主题 [TOPIC] 接收到消息为 [DATA] 时",
    "gui.blocklyText.esp32.MQTTreceive_2": "当主题 [TOPIC] 接收到消息为 [DATA] 时",
    "gui.blocklyText.weather.weatherFeild": "[DATA] 心知天气 通用字段 [FIELD]",
    "gui.blocklyText.weather.weatherSituation": "[DATA] 心知天气 天气实况 [FIELD]",
    "gui.blocklyText.weather.Weatherforecast": "[DATA] 心知天气 3天天气预报 [DATE] 的 [FIELD]",
    "gui.blocklyText.weather.Weatherlivingindex": "[DATA] 心知天气 6项生活指数 [FIELD]",
    "gui.blocklyText.esp32.MQTTmode.block": "阻塞",
    "gui.blocklyText.esp32.MQTTmode.unblock": "非阻塞",
    "gui.blocklyText.esp32.weatherfeild.name": "城市名称",
    "gui.blocklyText.esp32.weatherfeild.id": "城市ID",
    "gui.blocklyText.esp32.weatherfeild.code": "国家代码",
    "gui.blocklyText.esp32.weatherfeild.path": "行政归属",
    "gui.blocklyText.esp32.weatherfeild.timezone": "时区",
    "gui.blocklyText.esp32.weatherfeild.timezone_offset": "UTC偏移量",
    "gui.blocklyText.esp32.weatherfeild.Updated": "数据更新日期",
    "gui.blocklyText.esp32.weatherfeild.updatetime": "数据更新时间",
    "gui.blocklyText.esp32.weatherfeild.original": "原始字典对象",
    "gui.blocklyText.esp32.weatherSituation.text": "天气现象",
    "gui.blocklyText.esp32.weatherSituation.code": "天气现象代码",
    "gui.blocklyText.esp32.weatherSituation.temperature": "温度",
    "gui.blocklyText.esp32.weatherdate.today": "今天",
    "gui.blocklyText.esp32.weatherdate.tomorrow": "明天",
    "gui.blocklyText.esp32.weatherdate.dayaftertomorrow": "后天",
    "gui.blocklyText.esp32.weatherforecast.highesttemperature": "当天最高温度",
    "gui.blocklyText.esp32.weatherforecast.lowesttemperature": "当天最低温度",
    "gui.blocklyText.esp32.weatherforecast.daytimeweather": "白天天气现象",
    "gui.blocklyText.esp32.weatherforecast.daytimeweathercode": "白天天气现象代码",
    "gui.blocklyText.esp32.weatherforecast.nightweather": "晚间天气现象",
    "gui.blocklyText.esp32.weatherforecast.nightweathercode": "晚间天气现象代码",
    "gui.blocklyText.esp32.weatherforecast.winddirection": "风向",
    "gui.blocklyText.esp32.weatherforecast.winddirectiondegree": "风向角度",
    "gui.blocklyText.esp32.weatherforecast.windspeed": "风速",
    "gui.blocklyText.esp32.weatherforecast.windscale": "风力等级",
    "gui.blocklyText.esp32.weatherforecast.date": "日期",
    "gui.blocklyText.esp32.weatherlivingindex.Carwashindex": "洗车指数",
    "gui.blocklyText.esp32.weatherlivingindex.Dressingindex": "穿衣指数",
    "gui.blocklyText.esp32.weatherlivingindex.Coldindex": "感冒指数",
    "gui.blocklyText.esp32.weatherlivingindex.Sportindex": "运动指数",
    "gui.blocklyText.esp32.weatherlivingindex.Travelindex": "旅游指数",
    "gui.blocklyText.esp32.weatherlivingindex.uvindex": "紫外线指数",
    "gui.blocklyText.sen${name}gui.blocklyText.pythonsen0303.VEML6075Ready0497.DHT20Read": "读取DHT20[INDEX]",
    "gui.blocklyText.wifi.getDwifiNS": "DNS",
    "gui.blocklyText.pythonwifi.wifiGetLocalIP": "[NAME] 获取Wi-Fi配置 [CONFIGURATION]",
    "gui.blocklyText.dialog.ml5.ifLagTryEnableGPUInSettings": "如运行卡顿，可尝试打开设置中的GPU加速功能",
    "paint.paintEditor.hue": "ᠥᠩᠭᠡ",
    "paint.paintEditor.saturation": " ᠬᠠᠨᠤᠴᠠ",
    "paint.paintEditor.brightness": " ᠲᠣᠳᠣᠷᠠᠴᠠ",
    "gui.comingSoon.message1": " ᠪᠤᠤ ᠰᠠᠨᠳᠤᠷᠤᠭᠠᠷᠠᠢ᠂  ᠪᠢᠳᠠ ᠶᠠᠭ ᠶᠠᠭᠠᠷᠠᠯᠲᠠᠢ ᠠᠵᠢᠯᠯᠠᠵᠤ ᠪᠠᠢᠨ᠎ᠠ {emoji}",
    "gui.comingSoon.message2": " ᠲᠤᠩ ᠤᠳᠠᠬᠤ ᠦᠭᠡᠢ...",
    "gui.comingSoon.message3": " ᠪᠢᠳᠠ ᠶᠠᠭ ᠡᠭᠦᠨ ᠳᠦ ᠶᠠᠭᠠᠷᠠᠯᠲᠠᠢ ᠠᠵᠢᠯᠯᠠᠵᠤ ᠪᠠᠢᠨ᠎ᠠ  {emoji}",
    "paint.paintEditor.fill": " ᠴᠢᠭᠵᠢᠬᠦ",
    "paint.paintEditor.costume": " ᠵᠠᠭᠪᠤᠷ",
    "paint.paintEditor.group": " ᠬᠠᠮᠲᠤᠷᠠᠭᠤᠯᠬᠤ",
    "paint.paintEditor.ungroup": " ᠰᠠᠯᠭᠠᠬᠤ",
    "paint.paintEditor.undo": " ᠪᠤᠴᠠᠭᠠᠬᠤ",
    "paint.paintEditor.redo": " ᠰᠡᠷᠭᠦᠭᠡᠬᠦ",
    "paint.paintEditor.forward": " ᠤᠷᠤᠭᠰᠢᠯᠠᠬᠤ",
    "paint.paintEditor.backward": " ᠤᠬᠤᠷᠢᠬᠤ",
    "paint.paintEditor.front": " ᠬᠠᠮᠤᠭ ᠡᠮᠦᠨ᠎ᠡ",
    "paint.paintEditor.back": " ᠬᠠᠮᠤᠭ ᠠᠷᠤ ᠳᠤ",
    "paint.paintEditor.more": " ᠨᠡᠩ ᠣᠯᠠᠨ",
    "paint.modeTools.brushSize": " ᠪᠦᠳᠦᠭᠦᠨ ᠨᠠᠷᠢᠨ",
    "paint.modeTools.eraserSize": " ᠪᠠᠯᠠᠯᠤᠭᠤᠷ ᠤᠨ ᠪᠦᠳᠦᠭᠦᠨ ᠨᠠᠷᠢᠨ",
    "paint.modeTools.copy": "ᠺᠣᠫᠢᠳᠠᠬᠤ",
    "paint.modeTools.paste": "ᠨᠠᠭᠠᠬᠤ",
    "paint.modeTools.delete": "ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "paint.modeTools.curved": " ᠮᠥᠷᠥᠢ ᠱᠤᠭᠤᠮ",
    "paint.modeTools.pointed": " ᠬᠤᠭᠤᠷᠠᠯᠲᠠ ᠶᠢᠨ ᠱᠤᠭᠤᠮ",
    "paint.modeTools.thickness": " ᠪᠦᠳᠦᠭᠦᠨ ᠨᠠᠷᠢᠨ",
    "paint.modeTools.flipHorizontal": " ᠬᠥᠨᠳᠡᠯᠡᠨ ᠬᠥᠷᠪᠡᠭᠦᠯᠬᠦ",
    "paint.modeTools.flipVertical": " ᠪᠣᠰᠣᠭ᠎ᠠ ᠬᠥᠷᠪᠡᠭᠦᠯᠬᠦ",
    "paint.modeTools.filled": " ᠴᠤᠯᠤ",
    "paint.modeTools.outlined": " ᠬᠦᠷᠢᠶ᠎ᠡ",
    "paint.paintEditor.bitmap": "  ᠴᠡᠭᠲᠦ ᠵᠢᠷᠤᠭ ᠪᠣᠯᠭᠠᠬᠤ",
    "paint.paintEditor.vector": " ᠸᠢᠺᠲ᠋ᠣᠷ ᠵᠢᠷᠤᠭ ᠪᠣᠯᠭᠠᠬᠤ",
    "paint.paintEditor.stroke": "ᠬᠦᠷᠢᠶ᠎ᠡ",
    "paint.brushMode.brush": " ᠪᠢᠷ",
    "paint.eraserMode.eraser": " ᠪᠠᠯᠠᠯᠤᠭᠤᠷ",
    "paint.fillMode.fill": " ᠴᠢᠭᠵᠢᠬᠦ",
    "paint.lineMode.line": " ᠬᠡᠷᠴᠢᠮ",
    "paint.ovalMode.oval": " ᠲᠣᠭᠣᠷᠢᠭ",
    "paint.rectMode.rect": " ᠳᠥᠷᠪᠡᠯᠵᠢᠨ",
    "paint.reshapeMode.reshape": "变形",
    "paint.roundedRectMode.roundedRect": " ᠲᠣᠭᠣᠷᠢᠭ ᠥᠨᠴᠥᠭᠲᠦ ᠳᠥᠷᠪᠡᠯᠵᠢᠨ",
    "paint.selectMode.select": " ᠰᠣᠩᠭᠣᠬᠤ",
    "paint.textMode.text": " ᠲᠡᠺᠰᠲ",
    "paint.colorPicker.swap": " ᠰᠣᠯᠢᠯᠴᠠᠬᠤ",
    "gui.dialog.notConnectDevice": "ᠲᠣᠨᠣᠭ ᠢᠶᠠᠨ ᠵᠠᠯᠭᠠᠭᠰᠠᠨ ᠦᠭᠡᠢ ᠪᠠᠢᠨ᠎ᠠ᠂ ᠲᠣᠪᠶᠣᠭ ᠠᠴᠠ ᠵᠠᠯᠭᠠᠬᠤ ᠲᠣᠨᠣᠭ ᠢᠶᠠᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.prompt": " ᠰᠠᠨᠠᠭᠤᠯᠤᠮᠵᠢ",
    "gui.dialog.openPort": " ᠵᠠᠯᠭᠠᠭᠤᠷ  ᠢ ᠨᠡᠭᠡᠭᠡᠬᠦ",
    "gui.dialog.error": " ᠠᠯᠳᠠᠭ᠎ᠠ",
    "gui.dialog.notSelectDevice": "ᠲᠣᠨᠣᠭ ᠢᠶᠠᠨ ᠰᠣᠩᠭᠣᠭᠰᠠᠨ ᠦᠭᠡᠢ ᠪᠠᠢᠨ᠎ᠠ᠂  ᠲᠠ  ᠵᠡᠭᠦᠨ ᠳᠣᠣᠷᠠᠬᠢ \" ᠥᠷᠭᠡᠳᠬᠡᠯ \"  ᠢ  ᠲᠣᠪᠴᠢᠳᠠᠨ </br> ᠵᠠᠯᠭᠠᠬᠤ ᠲᠣᠨᠣᠭ ᠢᠶᠠᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.connectDeviceError1": " ᠲᠣᠨᠣᠭ  ᠲᠠᠢ ᠵᠠᠯᠭᠠᠭᠳᠠᠬᠤ (",
    "gui.dialog.connectDeviceError2": ") ᠠᠯᠳᠠᠭ᠎ᠠ ᠭᠠᠷᠴᠠᠢ᠂  ᠲᠠ ᠳᠣᠣᠷᠠᠬᠢ ᠮᠡᠲᠦ ᠰᠢᠯᠭᠠᠭᠠᠷᠠᠢ :</br> *USB ᠵᠠᠯᠭᠠᠯᠲᠠ ᠪᠠᠨ ᠰᠣᠯᠢᠬᠤ ᠪᠤᠶᠤUSB ᠤᠲᠠᠰᠤ ᠪᠠᠨ ᠰᠣᠯᠢᠭᠠᠷᠠᠢ</br> * ᠵᠠᠯᠭᠠᠭᠳᠠᠭᠰᠠᠨ ᠡᠰᠡᠬᠦ ᠶᠢ ᠲᠠᠬᠢᠨ ᠰᠢᠯᠭᠠᠭᠠᠷᠠᠢ ",
    "gui.dialog.connectDeviceError3": "ᠭᠣᠣᠯ ᠡᠵᠡᠮᠰᠢᠭᠦᠷ </br> *  ᠠᠯᠪᠠᠨ ᠴᠤᠭᠯᠠᠷᠠᠯ (671877416)  ᠳᠤ ᠬᠣᠯᠪᠣᠭᠳᠠᠨ ᠠᠰᠠᠭᠤᠳᠠᠯ ᠢᠶᠠᠨ ᠲᠤᠰᠬᠠᠭᠠᠷᠠᠢ",
    "gui.dialog.calibration1": "</br> * ᠡᠷᠭᠢᠭᠦᠯᠦᠭᠡᠷᠡᠢ ",
    "gui.dialog.calibration2": " ᠭᠣᠣᠯ ᠡᠵᠡᠮᠰᠢᠭᠦᠷ ᠢᠶᠡᠨ ᠰᠢᠯᠭᠠᠭᠠᠷᠠᠢ </br>",
    "gui.dialog.runAsManager": " ᠲᠠ ᠬᠠᠮᠢᠶᠠᠷᠤᠭᠴᠢ ᠶᠢᠨ ᠡᠷᠬᠡ ᠪᠡᠷ Mind+  ᠶᠢ ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠤᠭᠠᠷᠠᠢ ",
    "gui.dialog.runErrorForVortex": "ᠰᠢᠰᠲ᠋ᠧᠮ ᠦᠨ ᠠᠵᠢᠯᠯᠠᠭ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠪᠤᠰᠤ  ᠪᠠᠢᠨ᠎ᠠ᠂ Vortex ᠵᠠᠯᠭᠠᠯᠲᠠ ᠠᠴᠠ ᠪᠤᠴᠠᠨ ᠭᠠᠷᠪᠠ",
    "gui.dialog.runError": " ᠰᠢᠰᠲ᠋ᠧᠮ ᠦᠨ ᠠᠵᠢᠯᠯᠠᠭ᠎ᠠ ᠬᠡᠪ ᠦᠨ ᠪᠤᠰᠤ  ᠪᠠᠢᠨ᠎ᠠ",
    "gui.dialog.close": " ᠬᠠᠭᠠᠬᠤ ",
    "gui.dialog.variableNameSpecialCharacters": "ᠬᠤᠪᠢᠰᠬᠤᠨ ᠤ ᠨᠡᠷ᠎ᠡ  %1 ᠣᠨᠴᠠᠭᠠᠢ ᠲᠡᠮᠲᠡᠭᠲᠦ ᠬᠠᠷᠢᠶᠠᠭᠳᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ :[\\`~!@#$%^&*()+<>?:\"{},./;\\'[]\\]",
    "gui.dialog.funcNameSpecialCharacters": "ᠹᠥᠩᠺᠽ ᠦᠨ ᠨᠡᠷ᠎ᠡ %1 ᠣᠨᠴᠠᠭᠠᠢ ᠲᠡᠮᠲᠡᠭᠲᠦ ᠬᠠᠷᠢᠶᠠᠭᠳᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ :[\\`~!@#$%^&*()+<>?:\"{},./;\\'[]\\]",
    "gui.dialog.funcArgsSpecialCharacters": "ᠹᠥᠩᠺᠽ ᠦᠨ  ᠫᠠᠷᠠᠮᠸ᠋ᠲᠷ %1 ᠣᠨᠴᠠᠭᠠᠢ ᠲᠡᠮᠲᠡᠭᠲᠦ ᠬᠠᠷᠢᠶᠠᠭᠳᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ :[\\`~!@#$%^&*()+<>?:\"{},./;\\'[]\\]",
    "gui.dialog.and": " ᠪᠣᠯᠣᠨ",
    "gui.dialog.yes": " ᠲᠡᠭᠡ",
    "gui.dialog.no": " ᠪᠤᠯᠢ",
    "gui.dialog.continue": "继续",
    "gui.dialog.maxReset": " ᠲᠠ ᠦᠨᠡᠬᠡᠷ ᠨᠠᠢᠷᠠᠭᠤᠯᠬᠤ ᠪᠠᠢᠷᠢᠨ ᠳᠠᠬᠢ ᠺᠣᠳ᠋ ᠢ ᠤᠰᠠᠳᠬᠠᠵᠤ ᠦᠢᠯᠡᠳᠪᠦᠷᠢ ᠡᠴᠡ ᠭᠠᠷᠬᠤ ᠦᠶᠡᠰ ᠦᠨ ᠺᠣᠳ᠋ ᠢ ᠠᠴᠢᠶᠠᠯᠠᠬᠤ ᠤᠤ︖",
    "gui.dialog.feedbackCannotEmpty": " ᠲᠠᠨ ᠤ ᠰᠠᠨᠠᠯ ᠬᠣᠭᠣᠰᠤᠨ ᠪᠠᠢᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ︕",
    "gui.dialog.noInstallCompiler": " ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠨ ᠣᠷᠴᠢᠭᠤᠯᠤᠭᠤᠷ ᠤᠭᠰᠠᠷᠠᠭᠰᠠᠨ ᠦᠭᠡᠢ ᠪᠠᠢᠨ᠎ᠠ",
    "gui.dialog.install": " ᠰᠠᠭᠤᠯᠭᠠᠬᠤ ",
    "gui.dialog.cancle": " ᠦᠭᠡᠢᠰᠬᠡᠬᠦ ",
    "gui.dialog.installingCompiler": "ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠨ ᠣᠷᠴᠢᠭᠤᠯᠤᠭᠤᠷ ᠤᠭᠰᠠᠷᠠᠵᠤ ᠪᠠᠢᠨ᠎ᠠ",
    "gui.dialog.uploading": " ᠠᠴᠢᠶᠠᠯᠠᠵᠤ ᠪᠠᠢᠨ᠎ᠠ",
    "gui.dialog.changeLanuage": " ᠬᠡᠯᠡ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ ᠦᠶᠡᠰ ᠣᠳᠣᠬᠢ ᠫᠷᠣᠭ᠍ᠷᠠᠮ ᠢ ᠤᠰᠠᠳᠬᠠᠳᠠᠭ᠂ ᠲᠠ ᠦᠨᠡᠬᠡᠷ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ ᠤᠤ︖",
    "gui.dialog.boardNotSupportMpy": "ᠲᠤᠰ ᠡᠵᠡᠮᠰᠢᠭᠦᠷ᠄ %1 micopython ᠲᠠᠢ ᠲᠤᠬᠢᠷᠠᠯᠴᠠᠬᠤ ᠦᠭᠡᠢ ᠪᠠᠢᠨ᠎ᠠ᠃",
    "gui.dialog.loadBlankItem": " ᠲᠤᠬᠢᠷᠠᠯᠴᠠᠬᠤ ᠦᠭᠡᠢ ᠲᠥᠰᠥᠯ</br>  ᠳᠠᠬᠢᠨ ᠬᠣᠭᠣᠰᠣᠨ ᠲᠥᠰᠥᠯ ᠢ ᠠᠴᠢᠶᠠᠯᠠᠵᠤ ᠪᠠᠢᠨ᠎ᠠ",
    "gui.dialog.switchBaudError": " ᠳᠠᠪᠲᠠᠮᠵᠢ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ ᠦᠶᠡᠰ ᠠᠯᠳᠠᠭ᠎ᠠ ᠭᠠᠷᠴᠠᠢ᠃</br>",
    "gui.dialog.turnMicrobitCompass": "Need to calibrate the electronic compass, please adjust the attitude of mciro: bit to move the blinking LED lights until all LED lights are on",
    "gui.dialog.turnCalliopeCompass": "需要校准电子罗盘，请调整calliope的姿态使闪烁的led灯移动，直到所有LED灯都点亮",
    "gui.dialog.operationShow": " ᠵᠢᠱᠢᠶ᠎ᠡ ᠵᠢᠯᠣᠭᠣᠳᠤᠯᠭ᠎ᠠ ",
    "gui.dialog.mpython": " ᠡᠵᠡᠮᠰᠢᠭᠦᠷ ",
    "gui.dialog.computer": " ᠺᠣᠮᠫᠢᠦ᠋ᠲ᠋ᠧᠷ ",
    "gui.dialog.delete": "ᠤᠰᠠᠳᠬᠠᠬᠤ ",
    "gui.dialog.sureDeleteFile": "ᠲᠠ ᠦᠨᠡᠬᠡᠷ \"%1\" ᠳᠡᠭᠡᠷᠡᠬᠢ %2 ᠹᠠᠢᠯ ᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ ᠤᠤ︖",
    "gui.dialog.sureDeleteFolder": "ᠲᠠ ᠦᠨᠡᠬᠡᠷ \"%1\" ᠳᠡᠭᠡᠷᠡᠬᠢ %2 ᠹᠠᠢᠯ  ᠦᠨ  ᠴᠣᠮᠣᠭ ᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ ᠤᠤ︖",
    "gui.dialog.uncorrectPath": " ᠵᠢᠮ ᠢᠶᠡᠨ ᠪᠤᠷᠤᠭᠤ ᠰᠣᠩᠭᠣᠭᠰᠠᠨ ᠪᠠᠢᠨ᠎ᠠ",
    "gui.dialog.laterDownLoad": " ᠰᠡᠭᠦᠯᠡᠷ ᠪᠠᠭᠤᠯᠭᠠᠬᠤ ",
    "gui.dialog.downLoadUpdate": "下载更新",
    "gui.dialog.downLoading": "下载中...",
    "gui.dialog.dwnLoadWebsite": " ᠪᠠᠭᠤᠯᠭᠠᠬᠤ ",
    "gui.dialog.laterUpdate": " ᠰᠡᠭᠦᠯᠡᠷ ᠰᠢᠨᠡᠳᠬᠡᠬᠦ ",
    "gui.dialog.update": " ᠰᠢᠨᠡᠳᠬᠡᠬᠦ ",
    "gui.dialog.getUpdateMsgError": " ᠰᠢᠨᠡᠳᠬᠡᠯ ᠦᠨ ᠮᠡᠳᠡᠭᠡ ᠶᠢ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ ᠦᠶᠡᠰ ᠠᠯᠳᠠᠭ᠎ᠠ ᠭᠠᠷᠴᠠᠢ",
    "gui.dialog.ok": " ᠪᠣᠯᠣᠨ᠎ᠠ",
    "gui.dialog.closeCodeBoxes": " ᠲᠠ ᠺᠣᠳ᠋ᠯᠠᠬᠤ ᠴᠣᠩᠬᠤ  ᠶᠢ ᠬᠠᠭᠠᠬᠤ ᠤᠤ %1︖",
    "gui.dialog.emailError": " ᠢᠮᠸᠯ  ᠦᠨ ᠬᠡᠯᠪᠡᠷᠢ ᠪᠤᠷᠤᠭᠤ︕",
    "gui.dialog.emailEmpty": " ᠢᠮᠸᠯ ᠬᠣᠭᠤᠰᠤᠨ ᠪᠠᠢᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ︕",
    "gui.dialog.bigFilePrompt": " ᠹᠠᠢᠯ ᠵᠢᠭᠠᠬᠠᠨ ᠶᠡᠬᠡ ᠪᠠᠢᠨ᠎ᠠ᠂ ᠲᠠ ᠲᠤᠷ ᠬᠦᠯᠢᠶᠡᠭᠡᠴᠢ︕",
    "gui.dialog.successFeedbackPrompt1": "ᠲᠠᠨ ᠤ ᠰᠠᠨᠠᠯ ᠳᠡᠪᠰᠢᠭᠦᠯᠦᠭᠰᠡᠨ ᠳᠦ ᠪᠠᠶᠠᠷᠯᠠᠯ᠎ᠠ᠂ 3  ᠰᠧᠭᠦ᠋ᠨᠳ᠋ ᠦᠨ ᠳᠠᠷᠠᠭ᠎ᠠ  ᠰᠠᠶ᠋ᠢ ᠳᠠᠬᠢᠨ ᠠᠴᠢᠶᠠᠯᠠᠨ᠎ᠠ᠃",
    "gui.dialog.successFeedbackPrompt2": " ᠲᠠᠨ ᠤ ᠰᠠᠨᠠᠯ ᠨᠢᠭᠡᠨᠲᠡ ᠳᠡᠪᠰᠢᠭᠦᠯᠦᠭᠳᠡᠪᠡ᠂ ᠬᠡᠳᠦᠨ ᠰᠧᠺᠦ᠋ᠨᠳ᠋ ᠦᠨ ᠳᠠᠷᠠᠭ᠎ᠠ  ᠰᠠᠶ᠋ᠢ ᠳᠠᠬᠢᠨ  ᠲᠤᠰᠢᠶᠠᠵᠤ ᠪᠣᠯᠣᠨ᠎ᠠ᠃",
    "gui.dialog.failedFeedbackPrompt": " ᠲᠠᠨ ᠤ ᠳᠡᠪᠰᠢᠭᠦᠯᠦᠭᠰᠡᠨ ᠰᠠᠨᠠᠯ ᠲᠤᠰᠢᠶᠠᠬᠤ ᠦᠶᠡᠰ ᠠᠯᠳᠠᠭ᠎ᠠ ᠭᠠᠷᠴᠠᠢ᠂ ᠠᠭᠤᠴᠢᠯᠠᠭᠠᠷᠠᠢ᠂ ᠲᠠᠨ ᠳᠤ ᠰᠠᠭᠠᠳ ᠤᠴᠠᠷᠠᠭᠤᠯᠤᠭᠰᠠᠨ ᠳᠤ ᠪᠠᠨ ᠣᠰᠣᠯ ᠬᠦᠯᠢᠶᠡᠵᠦ ᠪᠠᠢᠨ᠎ᠠ᠃ ᠲᠠ ᠠᠯᠪᠠᠨ ᠤ QQ  ᠴᠤᠭᠯᠠᠷᠠᠯ ᠳᠤ ᠬᠣᠯᠪᠣᠭᠳᠠᠨ ᠰᠠᠨᠠᠯ ᠢᠶᠠᠨ ᠢᠯᠡᠳᠬᠡᠬᠦ ᠪᠣᠯᠪᠠᠣ᠃",
    "gui.dialog.successFeedbackPrompt3": " ᠲᠠᠨ ᠤ ᠰᠠᠨᠠᠯ ᠳᠡᠪᠰᠢᠭᠦᠯᠦᠭᠰᠡᠨ ᠳᠤ ᠴᠢᠨᠢ ᠲᠠᠯᠠᠷᠬᠠᠵᠤ ᠪᠠᠢᠨ᠎ᠠ᠃",
    "gui.dialog.forcedCloseSoftWare": "ᠹᠠᠢᠯ ᠢ ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ ᠦᠶᠡᠰ ᠠᠯᠳᠠᠭ᠎ᠠ ᠭᠠᠷᠴᠠᠢ᠂ ᠰᠣᠹᠲ ᠢ ᠠᠯᠪᠠᠳᠠᠨ ᠬᠠᠭᠠᠬᠤ ᠤᠤ ︖",
    "gui.dialog.saveSuccess": " ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠳᠠᠪᠠ ",
    "gui.dialog.noSelectTruePath": "ᠵᠢᠮ ᠢᠶᠡᠨ ᠪᠤᠷᠤᠭᠤ ᠰᠣᠩᠭᠣᠭᠰᠠᠨ ᠪᠠᠢᠨ᠎ᠠ",
    "gui.dialog.newItemContent": "ᠲᠠ ᠰᠢᠨ᠎ᠡ ᠪᠠᠢᠭᠤᠯᠤᠭᠰᠠᠨ  ᠬᠣᠭᠣᠰᠣᠨ ᠲᠥᠰᠥᠯ ᠢᠶᠡᠷ ᠤᠤᠯ ᠤᠨ ᠲᠥᠰᠥᠯ ᠢ ᠪᠦᠷᠬᠦᠬᠦ ᠭᠡᠵᠤ ᠪᠣᠢ᠂ ᠦᠨᠡᠬᠡᠷ ᠰᠢᠨᠡᠴᠢᠯᠡᠨ ᠪᠠᠢᠭᠤᠯᠬᠤ ᠤᠤ ︖",
    "gui.dialog.deleteBlocksError": "ᠵᠡᠷᠭᠡᠯᠡᠬᠦ ᠮᠣᠳᠣ %1  ᠢ  ᠤᠰᠠᠳᠬᠠᠬᠤ ᠦᠶᠡᠰ ᠠᠯᠳᠠᠭ᠎ᠠ ᠭᠠᠷᠴᠠᠢ᠂ ᠲᠠ  ᠥᠪᠡᠷᠲᠡᠭᠡᠨ ᠢᠶᠡᠨ ᠤᠰᠠᠳᠬᠠᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.netIsConnect": " ᠲᠠ ᠰᠦᠯᠵᠢᠶ᠎ᠡ ᠬᠣᠯᠪᠣᠭᠳᠠᠵᠤ ᠪᠠᠢᠬᠤ ᠡᠰᠡᠬᠦ ᠪᠡᠨ ᠰᠢᠯᠭᠠᠭᠠᠷᠠᠢ",
    "gui.dialog.needToUpdate": "ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠨ ᠣᠷᠴᠢᠭᠤᠯᠤᠭᠤᠷ  ᠢ ᠬᠠᠮᠤᠭ ᠰᠢᠨ᠎ᠡ  ᠬᠡᠪ %1 ᠳᠦ ᠰᠢᠨᠡᠳᠬᠡᠬᠦ ᠱᠠᠭᠠᠷᠳᠠᠯᠭ᠎ᠠ ᠲᠠᠢ ᠤᠤ",
    "gui.dialog.cantUseMpythonInArduinoC": " ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠨ ᠣᠷᠴᠢᠭᠤᠯᠤᠭᠤᠷ  ᠤᠭᠰᠠᠷᠠᠭᠰᠠᠨ ᠦᠭᠡᠢ ᠪᠠᠢᠨ᠎ᠠ᠂ ᠲᠠArduino C ᠳᠣᠣᠷ᠎ᠠ ᠡᠵᠡᠮᠰᠢᠭᠦᠷ ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠪᠣᠩᠬᠤ ᠦᠭᠡᠢ᠃",
    "gui.dialog.enterMpyMode": "  ᠮᠸᠺᠷᠤᠹᠤᠨ  ᠤ ᠪᠠᠢᠳᠠᠯ ᠳᠤ ᠣᠷᠣᠭᠤᠯᠬᠤ",
    "gui.dialog.runHardware": "ᠬᠠᠲᠠᠭᠤ ᠲᠣᠨᠣᠭ ᠢ ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠬᠤ ᠳᠤ᠂ ᠤᠷᠢᠳᠠᠪᠠᠷ ᠲᠣᠨᠣᠭ ᠢᠶᠠᠨ ᠵᠠᠯᠭᠠᠬᠤ ᠴᠢᠬᠤᠯᠠᠲᠠᠢ᠃",
    "gui.dialog.sureDelete": " ᠲᠠ ᠡᠭᠦᠨ ᠢ ᠦᠨᠡᠬᠡᠷ ᠤᠰᠠᠳᠬᠠᠬᠤ ᠤᠤ︖",
    "gui.dialog.notSupportWebGL": "ᠲᠠᠢᠰᠠᠨ ᠤ ᠵᠠᠭᠪᠤᠷ ᠢ ᠠᠴᠢᠶᠠᠯᠠᠬᠤ ᠦᠶᠡᠰ ᠠᠯᠳᠠᠭ᠎ᠠ ᠭᠠᠷᠴᠠᠢ᠂ WebGL  ᠶᠢ ᠳᠡᠮᠵᠢᠬᠦ ᠦᠭᠡᠢ ᠮᠠᠭᠠᠳ᠂</br>  ᠳᠣᠣᠷᠠᠬᠢ ᠪᠣᠯ ᠠᠯᠳᠠᠭ᠎ᠠ ᠶᠢᠨ ᠨᠠᠷᠢᠨ ᠮᠡᠳᠡᠭᠡ᠄ </br> %1 </br>QQ  ᠴᠤᠭᠯᠠᠷᠠᠯ 671877416 ᠲᠠᠢ ᠬᠣᠯᠪᠣᠭᠳᠠᠨ ᠪᠢᠳᠠᠨ ᠲᠠᠢ ᠬᠠᠷᠢᠯᠴᠠᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.boardIsnotMPython": " ᠲᠤᠰ ᠡᠵᠡᠮᠰᠢᠭᠦᠷ mPython ᠪᠢᠰᠢ ᠪᠠᠢᠨ᠎ᠠ᠂  ᠲᠠ mPython ᠶᠢ ᠵᠠᠯᠭᠠᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.swdMicrobitUpload": " ᠲᠠᠨ ᠤ ᠺᠣᠮᠫᠢᠦ᠋ᠲ᠋ᠧᠷ ᠵᠠᠪᠠᠯ SWD  ᠢᠶᠠᠷ micro:bit ᠳᠤ ᠠᠴᠢᠶᠠᠯᠠᠬᠤ ᠴᠢᠬᠤᠯᠠᠲᠠᠢ᠂ ᠲᠠ ᠬᠠᠮᠢᠶᠠᠷᠤᠭᠴᠢ ᠶᠢᠨ ᠡᠷᠬᠡ ᠪᠡᠷ Mind+  ᠶᠢ ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠤᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.openPortError": "ᠵᠠᠯᠭᠠᠯᠲᠠ %1   ᠨᠡᠭᠡᠭᠡᠬᠦ ᠦᠶᠡᠰ ᠠᠯᠳᠠᠭ᠎ᠠ᠄ %2",
    "gui.dialog.pressedA": "A ᠳᠠᠷᠤᠭᠤᠯ ᠢ ᠳᠠᠷᠤᠵᠤ ᠪᠠᠢᠭᠠᠷᠠᠢ᠂ ᠪᠢᠲᠡᠭᠡᠢ ᠰᠤᠯᠠᠯᠠ᠃",
    "gui.dialog.unpressedA": "A ᠳᠠᠷᠤᠭᠤᠯ ᠢ ᠰᠤᠯᠠᠯᠠᠭᠠᠷᠠᠢ ",
    "gui.dialog.unsupportProject": "ᠳᠡᠮᠵᠢᠯᠭᠡ ᠦᠵᠡᠭᠦᠯᠬᠦ ᠦᠭᠡᠢ ᠲᠥᠰᠥᠯ</br>  ᠳᠠᠬᠢᠨ ᠬᠣᠭᠣᠰᠣᠨ ᠲᠥᠰᠥᠯ ᠢ ᠠᠴᠢᠶᠠᠯᠠᠵᠤ ᠪᠠᠢᠨ᠎ᠠ᠃",
    "gui.dialog.openProjectError": " ᠵᠢᠷᠤᠭᠲᠤ ᠬᠡᠯᠪᠡᠷᠢ  ᠶᠢᠨ ᠳᠦᠰᠦᠯ  ᠢ ᠵᠥᠪᠬᠡᠨ ᠵᠢᠷᠤᠭᠲᠤ ᠬᠡᠯᠪᠡᠷᠢ  ᠪᠡᠷ  ᠨᠡᠭᠡᠭᠡᠬᠦ ᠬᠡᠷᠡᠭᠲᠡᠢ᠂  ᠲᠠ ᠣᠳᠣ  ᠶᠢᠨ  ᠬᠡᠯᠪᠡᠷᠢ  ᠪᠡᠨ  ᠵᠢᠷᠤᠭᠲᠤ ᠬᠡᠯᠪᠡᠷᠢ ᠪᠤᠶᠤ arduino  ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢᠨ ᠠᠯᠢ ᠨᠢ ᠪᠣᠯᠬᠤ  ᠶᠢ ᠲᠣᠳᠤᠯᠠᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.installCompilerError1": " ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠭᠴᠢ  ᠢ ᠪᠠᠭᠤᠯᠭᠠᠵᠤ ᠳᠡᠢᠯᠦᠭᠰᠡᠨ ᠦᠭᠡᠢ᠂  ᠲᠠ ᠰᠦᠯᠵᠢᠶᠡᠨ ᠦᠭᠡᠢ  ᠤᠭᠰᠠᠷᠠᠭᠴᠢ  ᠶᠢ  ᠤᠭᠰᠠᠷᠠᠬᠤ ᠪᠤᠶᠤ  ᠳᠠᠬᠢᠨ ᠤᠭᠰᠠᠷᠠᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.installCompilerOffline": " ᠰᠦᠯᠵᠢᠶᠡᠨ ᠦᠭᠡᠢ ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠭᠴᠢ  ᠶᠢ  ᠤᠭᠰᠠᠷᠠᠬᠤ",
    "gui.dialog.installCompilerError2": " ᠬᠠᠷᠭᠤᠯᠠᠯᠲᠠ  ᠶᠢᠨ ᠠᠯᠳᠠᠭᠠ᠂  ᠲᠠ ᠰᠦᠯᠵᠢᠶᠡᠨ ᠦᠭᠡᠢ  ᠤᠭᠰᠠᠷᠠᠭᠴᠢ  ᠶᠢ  ᠤᠭᠰᠠᠷᠠᠬᠤ ᠪᠤᠶᠤ  ᠳᠠᠬᠢᠨ ᠤᠭᠰᠠᠷᠠᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.installCompilerError3": " ᠤᠭᠰᠠᠠᠷᠠᠯᠲᠠ  ᠶᠢᠨ ᠠᠯᠳᠠᠭᠠ᠂ ᠲᠠ ᠰᠦᠯᠵᠢᠶᠡᠨ ᠦᠭᠡᠢ  ᠤᠭᠰᠠᠷᠠᠭᠴᠢ  ᠶᠢ  ᠤᠭᠰᠠᠷᠠᠬᠤ ᠪᠤᠶᠤ  ᠳᠠᠬᠢᠨ ᠤᠭᠰᠠᠷᠠᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.installCompilerSuccess": " ᠨᠠᠢᠷᠠᠭᠤᠯᠤᠭᠴᠢ  ᠶᠢ ᠤᠭᠰᠠᠷᠠᠪᠠ᠂  ᠲᠠ ArduinoC   ᠦᠨ ᠣᠷᠴᠢᠨ ᠳ᠋ᠤ Esp32  ᠢ ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠪᠣᠯᠤᠨᠠ᠃",
    "gui.dialog.sureAdapter": " ᠲᠣᠬᠢᠷᠠᠯᠠᠭᠤᠷ  ᠢ ᠣᠯᠤᠭᠰᠠᠨ ᠦᠭᠡᠢ᠂  ᠲᠣᠬᠢᠷᠠᠯᠠᠭᠤᠷ  ᠵᠠᠯᠭᠠᠭᠳᠠᠭᠰᠠᠨ ᠡᠰᠡᠬᠦ  ᠶᠢ ᠪᠠᠢᠴᠠᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.checkPort": " ᠵᠠᠯᠭᠠᠭᠤᠷ  ᠢ ᠨᠡᠭᠡᠭᠡᠵᠦ ᠳᠡᠢᠯᠦᠭᠰᠡᠨ ᠦᠭᠡᠢ᠂ ᠵᠠᠯᠭᠠᠭᠤᠷ ᠡᠵᠡᠯᠡᠭᠳᠡᠭᠰᠡᠨ ᠡᠰᠡᠬᠦ  ᠶᠢ ᠪᠠᠢᠴᠠᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.changeBoard": "ᠬᠠᠪᠲᠠᠰᠤ ᠰᠣᠯᠢᠪᠠᠯ ᠫᠷᠦᠭᠷᠠᠮ ᠠᠷᠢᠯᠭᠠᠭᠳᠠᠨ᠎ᠠ᠂ ᠰᠣᠯᠢᠨ᠎ᠡ ᠤᠤ︖",
    "gui.crashMessage.somethingWrong": "  ᠬᠠᠢ ᠶᠠ᠂ ᠵᠠᠷᠢᠮ ᠠᠯᠳᠠᠭ᠎ᠠ ᠭᠠᠷᠴᠠᠢ᠃",
    "gui.crashMessage.cacheListPrompt": "你可以通过菜单「项目」>「缓存列表」中找到项目文件",
    "gui.crashMessage.reload": " ᠳᠠᠬᠢᠨ ᠠᠴᠢᠶᠠᠯᠠᠬᠤ",
    "gui.crashMessage.submit": " ᠲᠤᠰᠢᠶᠠᠬᠤ ",
    "gui.crashMessage.attachment": " ᠳᠠᠭᠠᠯᠲᠠ ",
    "gui.crashMessage.errFeedbackNote": " ᠡᠨᠳᠡ ᠲᠠ ᠪᠤᠷᠤᠭᠤ ᠮᠡᠳᠡᠭᠡᠯᠡᠯ ᠪᠤᠶᠤ ᠰᠠᠨᠠᠯ ᠵᠥᠪᠯᠡᠯᠭᠡ ᠪᠡᠨ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ᠂ Mind+ ᠨᠢ ᠲᠠᠨ ᠤ ᠣᠷᠣᠯᠴᠠᠯᠤᠭ᠎ᠠ ᠠᠴᠠ ᠪᠣᠯᠵᠤ ᠨᠡᠩ ᠰᠠᠢᠨ ᠰᠠᠢᠬᠠᠨ ᠪᠣᠯᠣᠨ᠎ᠠ᠃",
    "gui.crashMessage.promptContent": "ᠠᠭᠤᠴᠢᠯᠠᠭᠠᠷᠠᠢ᠂ Mind + ᠪᠠᠷᠤᠭ ᠨᠢᠭᠡᠨᠲᠡ ᠰᠦᠢᠷᠡᠭᠰᠡᠨ ᠪᠣᠯᠣᠯᠲᠠᠢ᠃ ᠲᠠ ᠪᠠᠷᠠᠭᠤᠨ ᠬᠠᠵᠠᠭᠤ ᠲᠠᠯ᠎ᠠ ᠳᠡᠭᠡᠷ᠎ᠡMind + ᠪᠦᠯᠬᠦᠮ ᠳᠦ ᠰᠠᠨᠠᠯ ᠵᠥᠪᠯᠡᠯᠭᠡ ᠪᠡᠨ ᠳᠡᠪᠰᠢᠭᠦᠯᠬᠦ ᠪᠣᠯᠪᠠᠤ᠂ ᠪᠠᠶᠠᠷᠯᠠᠯ᠎ᠠ︕ ᠲᠠ ᠨᠢᠭᠤᠷ ᠢ ᠰᠢᠷᠪᠢᠨ ᠳᠠᠬᠢᠨ ᠤᠳᠠᠭ᠎ᠠ ᠲᠤᠷᠰᠢᠭᠠᠷᠠᠢ᠃",
    "gui.dialog.isSaveProject": "ᠲᠤᠰ ᠲᠦᠰᠦᠯ ᠢ ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ ᠤᠤ︖",
    "gui.dialog.save": "ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ",
    "gui.dialog.notSave": "ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ ᠦᠭᠡᠢ",
    "gui.dialog.cancelSave": "取消保存",
    "gui.dialog.saveMPProjectToSb3": "当前选择格式为.sb3，仅保存实时模式下的部分基础积木，是否继续保存?",
    "gui.dialog.variableIsNull": "变量名不能为空",
    "gui.dialog.variableIsRepeat": "变量名不能重复，请修改",
    "gui.dialog.listNameIsNull": "列表名不能为空",
    "gui.dialog.pictureAINotNull": "不能为空",
    "gui.dialog.pictureAIInitServer": "请先初始化服务器",
    "gui.dialog.pictureAIInitServerError": "初始化服务器出错，请检查账号及秘钥",
    "gui.dialog.versionPrompt": "当前项目版本「%1」",
    "gui.dialog.versionGreater": "项目版本高于当前软件版本, 项目版本「%1」",
    "gui.dialog.noModulePrompt": "当前分类下无小模块，详见帮助",
    "gui.dialog.firstSelectBoard": "请先选择“主控板”或“套件”",
    "gui.dialog.downloadFileError": "下载文件出错",
    "gui.dialog.noSearchModules": "没有搜索到相关的模块, 请检查搜索路径",
    "gui.dialog.networkError": "网络错误",
    "gui.dialog.saveThirdModulesError": "保存用户库出错",
    "gui.dialog.coverUseExForLoadProject": "用户库中存在同名库，已经替换为项目中的用户库",
    "gui.dialog.isContinueUpdateThirdModule": "当前有相同的用户扩展库，继续更新会覆盖当前的用户库，是否继续？",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "修改了%1 的字段 「%2」, 需要手动在扩展库重新导入，本次刷新取消",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "解析 %1 出错, 将继续本次刷新, 但是不更新「%2」里的内容",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块，再刷新，本次刷新取消。",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh1": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块并检查精灵「%1」的积木块，再刷新，本次刷新取消。",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh2": "积木块形状或输入框类型修改，请检查精灵「%1」的积木块，再刷新，本次刷新取消。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块，再加载，本次加载取消。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad1": "积木块形状或输入框类型修改，请先手动删除编程区高亮的积木块并检查精灵「%1」的积木块，再加载，本次加载取消。",
    "gui.dialog.thirdex.blockAlterAndCancleLoad2": "积木块形状或输入框类型修改，请检查精灵 「%1」 的积木块，再加载，本次加载取消。",
    "gui.dialog.thirdex.filesEmpty": "files字段不能为空，可能会导致相关文件丢失，请检查导入的用户库文件！",
    "gui.dialog.thirdex.refreshing": "正在刷新...",
    "gui.dialog.thirdex.refreshSuccess": "刷新成功",
    "gui.dialog.isSaving": "正在保存...",
    "gui.dialog.isExporting": "正在导出...",
    "gui.dialog.mpySb3Prompt": "「%1」对micropython模式进行了更新，项目中的内容如有错误，需要您自己手动修改",
    "gui.dialog.translate.fieldError": "下面的这些错误字段，可能会导致积木块显示出错",
    "gui.dialog.translate.startReadFile": "开始读取文件, 请耐心等待...",
    "gui.dialog.translate.fileFormatError": "没有相关的翻译文件内容，文件内容格式不匹配",
    "gui.dialog.connecting.requestCloseFW": "检测到本机的防火墙为打开状态，这会导致Tello无法正常连接，点击确定可关闭防火墙",
    "gui.dialog.cache.crash": "检测到软件异常关闭，可以通过缓存文件恢复项目。打开路径\"项目\">\"缓存列表\"",
    "gui.dialog.python.detectPip": "pip环境初始化中，请稍候",
    "gui.dialog.python.detectPipSucccess": "pip环境初始化完成",
    "gui.dialog.python.detectPipError": "pip初始化失败，库管理功能不可用。请检查网络连接，你也可以在命令行中运行 \"[PYTHON]\" -m pip install --upgrade pip ，成功后再重新使用该功能。",
    "gui.dialog.python.installPipreqsError": "[NAME] 安装失败，用户库将无法正常导出自定义库的依赖列表，请手动安装。",
    "gui.dialog.screenshoting": "正在截图...",
    "gui.dialog.saveScreenshot": "保存截图",
    "gui.dialog.saveScreenshotSuccess": "保存截图成功",
    "gui.dialog.serialDriverInstalling": "串口驱动安装中...",
    "gui.dialog.resetDeviceSuccess": "恢复设备成功",
    "gui.dialog.resetDeviceFailed": "恢复设备失败",
    "gui.dialog.exportSuccess": "导出成功",
    "gui.dialog.exportFailed": "导出失败",
    "gui.dialog.exportCostume": "导出角色",
    "gui.dialog.loadProjectError": "加载项目出错",
    "gui.dialog.readFileError": "读取文件出错",
    "gui.dialog.projectIsDeleted": "项目已经被删除",
    "gui.dialog.projectIsNotExist": "打开项目失败，文件不存在",
    "gui.dialog.checkNetwork": "当前网络不通，请检查电脑是否能上网",
    "gui.dialog.checkIotNetworkAndParameter": "物联网连接失败，请检查参数是否填写正确",
    "gui.dialog.knownError": "已知问题：",
    "gui.dialog.unknownError": "未知问题：",
    "gui.dialog.cancelInstall": "取消安装",
    "gui.dialog.cannotFindDriver": "没有找到驱动文件，请检查路径:",
    "gui.dialog.programError": "程序出错",
    "gui.dialog.pleaseHandleInstall": "请手动安装工具：",
    "gui.dialog.installSerialDriverError": "安装串口驱动出错",
    "gui.dialog.installSerialDriverSuccess": "串口驱动安装完成",
    "gui.dialog.hadInstall": "已安装",
    "gui.dialog.cache.sameAsCacheDeleteBlock": "缓存界面有相同的用户库，将会删除相关的积木块，是否继续",
    "gui.dialog.cache.saveAs": "另存为",
    "gui.dialog.cache.exportExtError": "导出扩展出错：",
    "gui.dialog.cache.exportExtSuccess": "导出扩展成功",
    "gui.dialog.thirdExt.lostVersionKeyWithThird": "缺少version字段，请检查version字段，应该与id字段同级",
    "gui.dialog.thirdExt.notFoundRelatedFile": "没有找到相关的文件",
    "gui.dialog.thirdExt.requestFileError": "请求文件出错",
    "gui.dialog.thirdExt.unzipLibrariesFailed": "解压libraries.zip失败",
    "gui.dialog.thirdExt.pipreqsLostAndFillConfig": "pipreqs 库未正确安装，自动导出依赖包清单出错。为方便用户使用，请手动在config.json中配置依赖库信息",
    "gui.dialog.thirdExt.exportExtToFolderSuccess": "成功导出扩展到目录",
    "gui.dialog.thirdExt.configJsonKeyLost": "'config.json中的[KEY]字段不能为空！'",
    "gui.dialog.thirdExt.configJsonKeyLost_ID": "config.json中的id是该模块的标识，不能为空！",
    "gui.dialog.thirdExt.configJsonKeyLost_platform": "platform定义支持的平台，至少支持一个平台（win,mac,web）！目前只支持win（windowns）平台",
    "gui.dialog.thirdExt.configJsonKeyLost_asset": "config.json中的asset定义支持的语言模式，不能为空！",
    "gui.dialog.thirdExt.configJsonError_asset": "config.json中的asset内容定义错误，至少支持一个语言模式",
    "gui.dialog.thirdExt.configJsonLost_version": "缺少version字段，请检查version字段，应该与id字段同级",
    "gui.dialog.thirdExt.configJsonLost_asset_mode": "config.json中的asset语言模式[MODE]的字段main不能为空！",
    "gui.dialog.thirdExt.configJsonError_mode_board": "语言模式[MODE]的字段board定义支持的主板情况，至少支持一个主板",
    "gui.dialog.stage.microphoneLost": "没有听到任何声音，请检查麦克风是否插好哦",
    "gui.dialog.ml5.closeMl5Modal": "关闭窗口会丢失训练中的数据，请及时保存，是否继续关闭",
    "gui.dialog.ml5.restartMl5Train": "该操作会删除所有分类，确认继续吗?",
    "gui.dialog.ml5.ml5DefaultLabel": "分类1",
    "gui.dialog.account.getCloudListError": "获取文件列表出错，是否重新加载",
    "gui.dialog.account.requestCloudList": "重新加载",
    "gui.dialog.python.getPIPListForUpdateError": "获取PIP更新数据超时",
    "gui.dialog.python.pipHasNoUpdate": "无可用更新",
    "gui.dialog.python.pipUpdateSucess": "成功升级[SUCCESS]个，[FAILED]个失败，分别为[INFO]",
    "gui.dialog.python.pipUpdateAllSucess": "升级成功，共[SUCCESS]个",
    "gui.dialog.python.pipUpdateError": "升级[NAME]失败，详情可在PIP模式页面查看    ",
    "gui.dialog.python.pipInstallError": "安装 [NAME] [VERSION]失败，详情可在PIP模式页面查看",
    "gui.dialog.python.pipUninstallSucess": "成功卸载[SUCCESS]个，[FAILED]个失败，分别为[INFO]",
    "gui.dialog.python.pipUninstallAllSucess": "卸载成功，共[COUNT]个",
    "gui.dialog.python.pipUninstallError": "卸载[NAME]失败，详情可在PIP模式页面查看",
    "gui.dialog.python.sourceUnknow": "未知源",
    "gui.dialog.python.sourceTsinghuaUniversity": "清华大学源",
    "gui.dialog.python.sourceDouban": "豆瓣源",
    "gui.dialog.python.sourceAli": "阿里源",
    "gui.dialog.python.sourceNeteasy": "网易源",
    "gui.dialog.python.sourceZGKJDX": "中国科技大学源",
    "gui.dialog.python.sourceOfficial": "Python官方源(国外)",
    "gui.dialog.python.rebootSoftForVsEditor": "代码编辑器相关功能的翻译需重启后生效",
    "gui.dialog.procedure.procedureNameIsRepeated": "函数「[NAME]」已定义，请重新命名",
    "gui.dialog.procedure.procedureNameIsEmpty": "函数名称不能为空",
    "gui.dialog.project.saveProjectCheck": "二次校验",
    "gui.dialog.project.trySaveProjectAgain": "抱歉，保存的文件解析出错了，请尝试重新保存",
    "gui.dialog.software.secondInstanceTips": "当前软件设置为不允许多开，如需修改，请在 [设置-系统设置-软件多开] 更新设置",
    "gui.dialog.project.saveFailed": "保存出错",
    "gui.dialog.pythonRemote.saveMPProjectToSb3": "当前选择格式为.sb3，仅保存实时模式下的部分基础积木，是否继续保存?",
    "gui.dialog.thirdExt.downloadNow": "立即下载",
    "gui.dialog.thirdExt.downloadingLib": "正在下载",
    "gui.dialog.thirdExt.downloadFailed": "下载失败",
    "gui.dialog.thirdExt.detectThirdExtLibrariesInfo": "检测到该用户库需要[NEED]个依赖库，[INSTALLED]个已存在，是否立即下载其余的[REST]个？",
    "gui.dialog.thirdExt.downloadFinishFailed": "下载[LENGTH]个库失败，为[INFO]，具体信息请至库管理查看",
    "gui.dialog.thirdExt.downloadFinishSuccess": "[LENGTH]个库下载成功, 具体信息请至库管理查看",
    "gui.dialog.pythonRemote.detectingRemoteLibraries": "检测[NAME]上的依赖库版本中，请稍候...",
    "gui.dialog.pythonRemote.detectRemoteLibrariesFinish": "检测完成，全部为新版本",
    "gui.dialog.pythonRemote.detectRemoteLibrariesInfo": "检测到<b>[NAME]</b>上有[LENGTH]个库缺失或版本过低，可能影响图形化功能的使用，是否通过Mind+离线更新？",
    "gui.dialog.pythonRemote.detectRemotePipError": "远程系统中PIP不可用，请确认Python环境存在后再使用库管理功能",
    "gui.dialog.thirdExt.supportedModesAndMotherboards": "用户库支持的模式和主板: ",
    "gui.dialog.thirdExt.offlineArduinoC": "上传模式 arduino C",
    "gui.dialog.thirdExt.offlineMicroPython": "上传模式 microPython",
    "gui.dialog.thirdExt.online": "实时模式",
    "gui.dialog.thirdExt.python": "Python模式",
    "gui.dialog.thirdExt.currentModeAndMotherboard": "Mind+ 当前模式和主板为",
    "gui.dialog.thirdExt.currentMode": "Mind+ 当前模式为",
    "gui.dialog.loadBlockError": "加载失败, 请切换到[%1]再加载该积木程序.",
    "gui.dialog.loadBoardError": "加载失败, 请先加载[%1]主板或套件.",
    "BACKDROPS_ARCTIC": "Arctic",
    "BACKDROPS_BASEBALL": "ᠰᠢᠳᠠᠮ ᠪᠦᠮᠪᠦᠭᠡ ᠶᠢᠨ ᠲᠠᠯᠠᠪᠠᠢ",
    "BACKDROPS_BASEBALL_2": "ᠰᠢᠳᠠᠮ ᠪᠦᠮᠪᠦᠭᠡ ᠶᠢᠨ ᠲᠠᠯᠠᠪᠠᠢ 2",
    "BACKDROPS_BASKETBALL": " ᠰᠠᠭᠰᠤᠨ ᠪᠥᠮᠪᠦᠭᠡ ᠶᠢᠨ ᠲᠠᠯᠠᠪᠠᠢ",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Beach Malibu",
    "BACKDROPS_BEACH_RIO": "Beach Rio",
    "BACKDROPS_BEDROOM": " ᠤᠨᠲᠠᠬᠤ ᠥᠷᠥᠭᠡ",
    "BACKDROPS_BEDROOM_1": "睡房1",
    "BACKDROPS_BEDROOM_2": "ᠤᠨᠲᠠᠬᠤ ᠥᠷᠥᠭᠡ2",
    "BACKDROPS_BEDROOM_3": "Bedroom 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Bench With View",
    "BACKDROPS_BLUE_SKY": " ᠬᠥᠬᠡ ᠲᠡᠭᠷᠢ",
    "BACKDROPS_BLUE_SKY_2": "ᠬᠥᠬᠡ ᠲᠡᠭᠷᠢ2",
    "BACKDROPS_BLUE_SKY3": "ᠬᠥᠬᠡ ᠲᠡᠭᠷᠢ3",
    "BACKDROPS_BOARDWALK": "Boardwalk",
    "BACKDROPS_CANYON": "Canyon",
    "BACKDROPS_CASTLE_1": "Castle 1",
    "BACKDROPS_CASTLE_2": "Castle 2",
    "BACKDROPS_CASTLE_3": "Castle 3",
    "BACKDROPS_CASTLE_4": "Castle 4",
    "BACKDROPS_CHALKBOARD": "Chalkboard",
    "BACKDROPS_CIRCLES": " ᠲᠥᠭᠥᠷᠢᠭ",
    "BACKDROPS_CITY_WITH_WATER": "City With Water",
    "BACKDROPS_COLORFUL_CITY": "Colorful City",
    "BACKDROPS_CONCERT": " ᠳᠠᠭᠤᠤ ᠬᠥᠭᠵᠢᠮ ᠦᠨ ᠲᠣᠭᠯᠠᠯᠲᠠ",
    "BACKDROPS_DESERT": "Desert",
    "BACKDROPS_FARM": "Farm",
    "BACKDROPS_FIELD_AT_MIT": "Field At Mit",
    "BACKDROPS_FLOWERS": "Flowers",
    "BACKDROPS_FOREST": "Forest",
    "BACKDROPS_GALAXY": " ᠲᠡᠭᠷᠢ ᠶᠢᠨ ᠣᠶᠤᠳᠠᠯ",
    "BACKDROPS_GARDEN-ROCK": "Garden-rock",
    "BACKDROPS_GREEK_THEATER": "Greek Theater",
    "BACKDROPS_HALL": "Hall",
    "BACKDROPS_HAY_FIELD": "Hay Field",
    "BACKDROPS_GRAFFITI": " ᠡᠷᠢᠶᠡᠴᠢᠬᠦ",
    "BACKDROPS_HEARTS1": "ᠬᠠᠢᠷ᠎ᠠ1",
    "BACKDROPS_HEARTS2": "ᠬᠠᠢᠷ᠎ᠠ2",
    "BACKDROPS_HILL": "Hill",
    "BACKDROPS_JUNGLE": " ᠬᠥᠪᠴᠢ ᠣᠢ",
    "BACKDROPS_JURASSIC": " ᠵᠦᠷ  ᠦᠨ ᠭᠠᠯᠪᠠ",
    "BACKDROPS_LIGHT": "ᠭᠡᠷᠡᠯ ᠲᠥᠪᠯᠡᠷᠡᠭᠦᠯᠬᠦ ᠳ᠋ᠧᠩ",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": " ᠰᠠᠷᠠ",
    "BACKDROPS_MOUNTAIN": " ᠠᠭᠤᠯᠠ",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": " ᠣᠳᠣᠨ ᠮᠡᠴᠢᠳ",
    "BACKDROPS_NEON_TUNNEL": "Neon Tunnel",
    "BACKDROPS_NIGHT_CITY": "Night City",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Night City With Street",
    "BACKDROPS_PARTY": " ᠴᠤᠭᠯᠠᠭᠠᠨ",
    "BACKDROPS_PATHWAY": "Pathway",
    "BACKDROPS_PARTY_ROOM": " ᠴᠤᠭᠯᠠᠭᠠᠨ ᠤ ᠥᠷᠥᠭᠡ",
    "BACKDROPS_PLAYGROUND": "Playground",
    "BACKDROPS_PLAYING_FIELD": "Playing Field",
    "BACKDROPS_POOL": "Pool",
    "BACKDROPS_PURPLE": " ᠬᠦᠷᠢᠨ ᠥᠩᠭᠡ",
    "BACKDROPS_RAYS": " ᠭᠡᠷᠡᠯ",
    "BACKDROPS_REFRIGERATOR": " ᠬᠥᠷᠥᠭᠡᠭᠴᠢ",
    "BACKDROPS_ROOM_1": "Room 1",
    "BACKDROPS_ROOM_2": "Room 2",
    "BACKDROPS_SAVANNA": "Savanna",
    "BACKDROPS_SCHOOL": "School",
    "BACKDROPS_SLOPES": "Slopes",
    "BACKDROPS_SOCCER": " ᠬᠥᠯ ᠪᠥᠮᠪᠦᠭᠡᠨ ᠦ ᠲᠠᠯᠠᠪᠠᠢ",
    "BACKDROPS_SOCCER_2": "ᠬᠥᠯ ᠪᠥᠮᠪᠦᠭᠡᠨ ᠦ ᠲᠠᠯᠠᠪᠠᠢ2",
    "BACKDROPS_SPACE": " ᠰᠠᠨᠰᠠᠷ ᠣᠭᠲᠠᠷᠭᠤᠢ",
    "BACKDROPS_SPACE_2": "ᠰᠠᠨᠰᠠᠷ ᠣᠭᠲᠠᠷᠭᠤᠢ 2",
    "BACKDROPS_SPACE_3": "ᠰᠠᠨᠰᠠᠷ ᠣᠭᠲᠠᠷᠭᠤᠢ 3",
    "BACKDROPS_SPACE_4": "ᠰᠠᠨᠰᠠᠷ ᠣᠭᠲᠠᠷᠭᠤᠢ 4",
    "BACKDROPS_SPOTLIGHT-STAGE": " ᠲᠠᠢᠰᠠᠨ ᠤ ᠭᠡᠷᠡᠯ ᠲᠥᠪᠯᠡᠷᠡᠭᠦᠯᠦᠭᠴᠢ ᠳ᠋ᠧᠩ",
    "BACKDROPS_STARS": " ᠣᠳᠣᠨ ᠮᠡᠴᠢᠳ",
    "BACKDROPS_STRIPES": " ᠵᠢᠷᠤᠭᠠᠰᠤ",
    "BACKDROPS_THEATER": " ᠲᠢᠶᠲ᠋ᠡᠷ",
    "BACKDROPS_THEATER_2": "Theater 2",
    "BACKDROPS_TREE": "Tree",
    "BACKDROPS_UNDERWATER_1": "Underwater 1",
    "BACKDROPS_UNDERWATER_2": "Underwater 2",
    "BACKDROPS_URBAN": "Urban",
    "BACKDROPS_WALL_1": "Wall 1",
    "BACKDROPS_WALL_2": "Wall 2",
    "BACKDROPS_WATER_AND_ROCKS": "Water And Rocks",
    "BACKDROPS_WETLAND": "Wetland",
    "BACKDROPS_WINTER": " ᠡᠪᠦᠯ",
    "BACKDROPS_WINTER-LIGHTS": " ᠡᠪᠦᠯ ᠦᠨ ᠰᠦᠨᠢ",
    "BACKDROPS_WITCH_HOUSE": " ᠢᠲᠤᠭᠠᠨ ᠤ ᠭᠡᠷ",
    "BACKDROPS_WOODS": " ᠣᠢ ᠮᠣᠳᠣ",
    "BACKDROPS_WOODS_AND_BENCH": "Woods And Bench",
    "BACKDROPS_XY-GRID": "Xy-grid",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": " ᠠᠢᠪᠢ-a",
    "COSTUMES_ABBY-B": " ᠠᠢᠪᠢ-b",
    "COSTUMES_ABBY-C": " ᠠᠢᠪᠢ-c",
    "COSTUMES_ABBY-D": " ᠠᠢᠪᠢ-d",
    "COSTUMES_AMON": "amon",
    "COSTUMES_ANDIE-A": "andie-a",
    "COSTUMES_ANDIE-B": "andie-b",
    "COSTUMES_ANDIE-C": "andie-c",
    "COSTUMES_ANDIE-D": "andie-d",
    "COSTUMES_ANINA_POP_DOWN": "anina pop down",
    "COSTUMES_ANINA_POP_FRONT": "anina pop front",
    "COSTUMES_ANINA_POP_L_ARM": "anina pop L arm",
    "COSTUMES_ANINA_POP_LEFT": "anina pop left",
    "COSTUMES_ANINA_POP_R_ARM": "anina pop R arm",
    "COSTUMES_ANINA_POP_RIGHT": "anina pop right",
    "COSTUMES_ANINA_POP_STAND": "anina pop stand",
    "COSTUMES_ANINA_R_CROSS": "anina R cross",
    "COSTUMES_ANINA_STANCE": "anina stance",
    "COSTUMES_ANINA_TOP_FREEZE": "anina top freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "anina top L step",
    "COSTUMES_ANINA_TOP_R_STEP": "anina top R step",
    "COSTUMES_ANINA_TOP_STAND": "anina top stand",
    "COSTUMES_APPLE": " ᠠᠯᠮᠤᠷᠠᠳ",
    "COSTUMES_ARROW1-A": " ᠰᠤᠮᠤᠨ ᠤ ᠦᠵᠦᠭᠦᠷ- ᠪᠠᠷᠠᠭᠤᠨ",
    "COSTUMES_ARROW1-B": "ᠰᠤᠮᠤᠨ ᠤ ᠦᠵᠦᠭᠦᠷ- ᠵᠡᠭᠦᠨ",
    "COSTUMES_ARROW1-C": "ᠰᠤᠮᠤᠨ ᠤ ᠦᠵᠦᠭᠦᠷ-  ᠳᠣᠷᠣᠭᠰᠢ",
    "COSTUMES_ARROW1-D": "ᠰᠤᠮᠤᠨ ᠤ ᠦᠵᠦᠭᠦᠷ- ᠳᠡᠭᠡᠭᠰᠢ",
    "COSTUMES_AVERY_WALKING-A": " ᠠᠢᠹᠯᠢ  ᠶᠠᠪᠤᠬᠤ-a",
    "COSTUMES_AVERY_WALKING-B": " ᠠᠢᠹᠯᠢ  ᠶᠠᠪᠤᠬᠤ-b",
    "COSTUMES_AVERY_WALKING-C": " ᠠᠢᠹᠯᠢ  ᠶᠠᠪᠤᠬᠤ-c",
    "COSTUMES_AVERY_WALKING-D": " ᠠᠢᠹᠯᠢ  ᠶᠠᠪᠤᠬᠤ-d",
    "COSTUMES_AVERY-A": " ᠠᠢᠹᠯᠢ-a",
    "COSTUMES_AVERY-B": " ᠠᠢᠹᠯᠢ-b",
    "COSTUMES_BALL-A": " ᠪᠥᠮᠪᠥᠭᠡ- ᠰᠢᠷ᠎ᠠ",
    "COSTUMES_BALL-B": " ᠪᠥᠮᠪᠥᠭᠡ- ᠬᠥᠬᠡ",
    "COSTUMES_BALL-C": " ᠪᠥᠮᠪᠥᠭᠡ-  ᠶᠠᠭᠠᠨ",
    "COSTUMES_BALL-D": " ᠪᠥᠮᠪᠥᠭᠡ- ᠨᠣᠭᠣᠭᠠᠨ",
    "COSTUMES_BALL-E": " ᠪᠥᠮᠪᠥᠭᠡ- ᠬᠦᠷᠢᠨ",
    "COSTUMES_BALLERINA-A": " ᠪᠡᠯᠸ᠋ᠲ ᠪᠥᠵᠢᠭᠴᠢᠨ ᠥᠬᠢᠨ-a",
    "COSTUMES_BALLERINA-B": " ᠪᠡᠯᠸ᠋ᠲ ᠪᠥᠵᠢᠭᠴᠢᠨ ᠥᠬᠢᠨ-b",
    "COSTUMES_BALLERINA-C": " ᠪᠡᠯᠸ᠋ᠲ ᠪᠥᠵᠢᠭᠴᠢᠨ ᠥᠬᠢᠨ-c",
    "COSTUMES_BALLERINA-D": " ᠪᠡᠯᠸ᠋ᠲ ᠪᠥᠵᠢᠭᠴᠢᠨ ᠥᠬᠢᠨ-d",
    "COSTUMES_BALLOON1-A": " ᠬᠡᠢ ᠪᠥᠮᠪᠥᠭᠡ- ᠬᠥᠬᠡ",
    "COSTUMES_BALLOON1-B": " ᠬᠡᠢ ᠪᠥᠮᠪᠥᠭᠡ- ᠰᠢᠷ᠎ᠠ",
    "COSTUMES_BALLOON1-C": " ᠬᠡᠢ ᠪᠥᠮᠪᠥᠭᠡ- ᠬᠦᠷᠢᠨ",
    "COSTUMES_BANANAS": " ᠪᠠᠨᠠᠨᠠ",
    "COSTUMES_BASEBALL": " ᠰᠢᠳᠠᠮ ᠪᠥᠮᠪᠥᠭᠡ",
    "COSTUMES_BASKETBALL": " ᠰᠠᠭᠰᠤᠨ ᠪᠥᠮᠪᠥᠭᠡ",
    "COSTUMES_BAT-A": "bat-a",
    "COSTUMES_BAT-B": "bat-b",
    "COSTUMES_BAT-C": "bat-c",
    "COSTUMES_BAT-D": "bat-d",
    "COSTUMES_BATTER-A": "batter-a",
    "COSTUMES_BATTER-B": "batter-b",
    "COSTUMES_BATTER-C": "batter-c",
    "COSTUMES_BATTER-D": "batter-d",
    "COSTUMES_BEACHBALL": " ᠨᠠᠷᠠᠨ ᠰᠢᠷᠠᠯᠠᠭ᠎ᠠ ᠶᠢᠨ ᠪᠥᠮᠪᠥᠭᠡ",
    "COSTUMES_BEAR-A": " ᠪᠠᠭᠠᠪᠠᠭᠠᠢ-a",
    "COSTUMES_BEAR-B": " ᠪᠠᠭᠠᠪᠠᠭᠠᠢ-b",
    "COSTUMES_BEAR-WALK-A": "ᠪᠠᠭᠠᠪᠠᠭᠠᠢ- ᠶᠠᠪᠤᠬᠤ-a",
    "COSTUMES_BEAR-WALK-B": "ᠪᠠᠭᠠᠪᠠᠭᠠᠢ- ᠶᠠᠪᠤᠬᠤ-b",
    "COSTUMES_BEAR-WALK-C": "ᠪᠠᠭᠠᠪᠠᠭᠠᠢ-ᠶᠠᠪᠤᠬᠤ-c",
    "COSTUMES_BEAR-WALK-D": "ᠪᠠᠭᠠᠪᠠᠭᠠᠢ-ᠶᠠᠪᠤᠬᠤ-d",
    "COSTUMES_BEAR-WALK-E": "ᠪᠠᠭᠠᠪᠠᠭᠠᠢ-ᠶᠠᠪᠤᠬᠤ-e",
    "COSTUMES_BEAR-WALK-F": "ᠪᠠᠭᠠᠪᠠᠭᠠᠢ-ᠶᠠᠪᠤᠬᠤ-f",
    "COSTUMES_BEAR-WALK-G": "ᠪᠠᠭᠠᠪᠠᠭᠠᠢ-ᠶᠠᠪᠤᠬᠤ-g",
    "COSTUMES_BEAR-WALK-H": "ᠪᠠᠭᠠᠪᠠᠭᠠᠢ-ᠶᠠᠪᠤᠬᠤ-h",
    "COSTUMES_BEETLE": " ᠬᠤᠶᠠᠭᠲᠤ ᠬᠣᠷᠤᠬᠠᠢ",
    "COSTUMES_BELL1": " ᠬᠣᠩᠬᠣ-a",
    "COSTUMES_BEN-A": " ᠪᠧᠨ-a",
    "COSTUMES_BEN-B": " ᠪᠧᠨ-b",
    "COSTUMES_BEN-C": " ᠪᠧᠨ-c",
    "COSTUMES_BEN-D": " ᠪᠧᠨ-d",
    "COSTUMES_BOWL-A": " ᠠᠶᠠᠭ᠎ᠠ-a",
    "COSTUMES_BOWTIE": "bowtie",
    "COSTUMES_BREAD": " ᠲᠠᠯᠬ᠎ᠠ",
    "COSTUMES_BROOM": " ᠱᠦᠭᠦᠷ",
    "COSTUMES_BUILDING-A": " ᠪᠠᠷᠢᠯᠭ᠎ᠠ-a",
    "COSTUMES_BUILDING-B": "ᠪᠠᠷᠢᠯᠭ᠎ᠠ-b",
    "COSTUMES_BUILDING-C": "ᠪᠠᠷᠢᠯᠭ᠎ᠠ-c",
    "COSTUMES_BUILDING-D": "ᠪᠠᠷᠢᠯᠭ᠎ᠠ-d",
    "COSTUMES_BUILDING-E": "ᠪᠠᠷᠢᠯᠭ᠎ᠠ-e",
    "COSTUMES_BUILDING-F": "ᠪᠠᠷᠢᠯᠭ᠎ᠠ-f",
    "COSTUMES_BUILDING-G": "ᠪᠠᠷᠢᠯᠭ᠎ᠠ-g",
    "COSTUMES_BUILDING-H": "ᠪᠠᠷᠢᠯᠭ᠎ᠠ-h",
    "COSTUMES_BUILDING-I": "ᠪᠠᠷᠢᠯᠭ᠎ᠠ-i",
    "COSTUMES_BUILDING-J": "ᠪᠠᠷᠢᠯᠭ᠎ᠠ-j",
    "COSTUMES_BUTTERFLY1-A": " ᠡᠷᠪᠡᠬᠡᠢ-a",
    "COSTUMES_BUTTERFLY1-B": "butterfly1-b",
    "COSTUMES_BUTTERFLY1-C": "butterfly1-c",
    "COSTUMES_BUTTERFLY2-A": "butterfly2-a",
    "COSTUMES_BUTTERFLY2-B": "butterfly2-b",
    "COSTUMES_BUTTON1": " ᠲᠥᠭᠥᠷᠢᠭ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ",
    "COSTUMES_BUTTON2-A": "ᠲᠥᠭᠥᠷᠢᠭ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ-a",
    "COSTUMES_BUTTON2-B": "ᠲᠥᠭᠥᠷᠢᠭ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ-b",
    "COSTUMES_BUTTON3-A": "ᠲᠥᠭᠥᠷᠢᠭ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ-a",
    "COSTUMES_BUTTON3-B": "ᠲᠥᠭᠥᠷᠢᠭ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ-b",
    "COSTUMES_BUTTON4-A": "ᠲᠥᠭᠥᠷᠢᠭ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ-a",
    "COSTUMES_BUTTON4-B": "ᠲᠥᠭᠥᠷᠢᠭ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ-b",
    "COSTUMES_BUTTON5-A": "ᠲᠥᠭᠥᠷᠢᠭ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ-a",
    "COSTUMES_BUTTON5-B": "ᠲᠥᠭᠥᠷᠢᠭ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ-b",
    "COSTUMES_CAKE-A": " ᠥᠨᠳᠡᠭᠡ ᠲᠠᠢ ᠪᠣᠭᠣᠷᠰᠣᠭ-a",
    "COSTUMES_CAKE-B": " ᠥᠨᠳᠡᠭᠡ ᠲᠠᠢ ᠪᠣᠭᠣᠷᠰᠣᠭ-b",
    "COSTUMES_CALVRETT_JUMPING": "calvrett jumping",
    "COSTUMES_CALVRETT_THINKING": "calvrett thinking",
    "COSTUMES_CASEY-A": " ᠺᠠᠰᠸᠢ-a",
    "COSTUMES_CASEY-B": " ᠺᠠᠰᠸᠢ-b",
    "COSTUMES_CASEY-C": " ᠺᠠᠰᠸᠢ-c",
    "COSTUMES_CASEY-D": " ᠺᠠᠰᠸᠢ-d",
    "COSTUMES_CASSY-A": "cassy-a",
    "COSTUMES_CASSY-B": "cassy-b",
    "COSTUMES_CASSY-C": "cassy-c",
    "COSTUMES_CASSY-D": "cassy-d",
    "COSTUMES_CAT_2": "cat 2",
    "COSTUMES_CATCHER-A": "catcher-a",
    "COSTUMES_CATCHER-B": "catcher-b",
    "COSTUMES_CATCHER-C": "catcher-c",
    "COSTUMES_CATCHER-D": "catcher-d",
    "COSTUMES_CENTAUR-A": " ᠬᠥᠮᠥᠨ ᠮᠣᠷᠢ-a",
    "COSTUMES_CENTAUR-B": " ᠬᠥᠮᠥᠨ ᠮᠣᠷᠢ-b",
    "COSTUMES_CENTAUR-C": " ᠬᠥᠮᠥᠨ ᠮᠣᠷᠢ-c",
    "COSTUMES_CENTAUR-D": " ᠬᠥᠮᠥᠨ ᠮᠣᠷᠢ-d",
    "COSTUMES_CHAMP99-A": "champ99-a",
    "COSTUMES_CHAMP99-B": "champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "cheesy puffs",
    "COSTUMES_CHICK-A": "chick-a",
    "COSTUMES_CHICK-B": "chick-b",
    "COSTUMES_CHICK-C": "chick-c",
    "COSTUMES_CITY_BUS-A": "City Bus-a",
    "COSTUMES_CITY_BUS-B": "City Bus-b",
    "COSTUMES_CLOUD": " ᠴᠠᠭᠠᠨ ᠡᠭᠦᠯᠡ",
    "COSTUMES_CLOUD-A": "ᠴᠠᠭᠠᠨ ᠡᠭᠦᠯᠡ-a",
    "COSTUMES_CLOUD-B": "ᠴᠠᠭᠠᠨ ᠡᠭᠦᠯᠡ-b",
    "COSTUMES_CLOUD-C": "ᠴᠠᠭᠠᠨ ᠡᠭᠦᠯᠡ-c",
    "COSTUMES_CLOUD-D": "ᠴᠠᠭᠠᠨ ᠡᠭᠦᠯᠡ-d",
    "COSTUMES_CONVERTIBLE": "convertible",
    "COSTUMES_CONVERTIBLE_3": "convertible 3",
    "COSTUMES_CRAB-A": " ᠨᠠᠢ᠌ᠮᠠᠯᠵᠢ-a",
    "COSTUMES_CRAB-B": " ᠨᠠᠢ᠌ᠮᠠᠯᠵᠢ-b",
    "COSTUMES_CRYSTAL-A": " ᠠᠯᠮᠠᠰ-a",
    "COSTUMES_CRYSTAL-B": " ᠠᠯᠮᠠᠰ-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": " ᠳ᠋ᠠᠨᠢ-a",
    "COSTUMES_DANI-B": " ᠳ᠋ᠠᠨᠢ-b",
    "COSTUMES_DANI-C": " ᠳ᠋ᠠᠨᠢ-c",
    "COSTUMES_DEE-A": " ᠳ᠋ᠢᠶ᠋ᠢ-a",
    "COSTUMES_DEE-B": " ᠳ᠋ᠢᠶ᠋ᠢ-b",
    "COSTUMES_DEE-C": " ᠳ᠋ᠢᠶ᠋ᠢ-c",
    "COSTUMES_DEE-D": " ᠳ᠋ᠢᠶ᠋ᠢ-d",
    "COSTUMES_DEE-E": " ᠳ᠋ᠢᠶ᠋ᠢ-e",
    "COSTUMES_DEVIN-A": " ᠳ᠋ᠡᠸᠢᠨ-a",
    "COSTUMES_DEVIN-B": " ᠳ᠋ᠡᠸᠢᠨ-b",
    "COSTUMES_DEVIN-C": " ᠳ᠋ᠡᠸᠢᠨ-c",
    "COSTUMES_DEVIN-D": " ᠳ᠋ᠡᠸᠢᠨ-d",
    "COSTUMES_DINOSAUR1-A": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ1-a",
    "COSTUMES_DINOSAUR1-B": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ1-b",
    "COSTUMES_DINOSAUR1-C": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ1-c",
    "COSTUMES_DINOSAUR1-D": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ1-d",
    "COSTUMES_DINOSAUR2-A": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ2-a",
    "COSTUMES_DINOSAUR2-B": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ2-b",
    "COSTUMES_DINOSAUR2-C": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ2-c",
    "COSTUMES_DINOSAUR2-D": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ2-d",
    "COSTUMES_DINOSAUR3-A": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ3-a",
    "COSTUMES_DINOSAUR3-B": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ3-b",
    "COSTUMES_DINOSAUR3-C": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ3-c",
    "COSTUMES_DINOSAUR3-D": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ3-d",
    "COSTUMES_DINOSAUR3-E": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ3-e",
    "COSTUMES_DINOSAUR4-A": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ4-a",
    "COSTUMES_DINOSAUR4-B": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ4-b",
    "COSTUMES_DINOSAUR4-C": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ4-c",
    "COSTUMES_DINOSAUR4-D": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ4-d",
    "COSTUMES_DIVER1": "ᠱᠤᠩᠭᠤᠭᠴᠢ1",
    "COSTUMES_DIVER2": "ᠱᠤᠩᠭᠤᠭᠴᠢ2",
    "COSTUMES_DM_FREEZE": "dm freeze",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "dm stance",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": " ᠭᠥᠯᠦᠭᠡ1-a",
    "COSTUMES_DOG1-B": " ᠭᠥᠯᠦᠭᠡ1-b",
    "COSTUMES_DOG2-A": " ᠭᠥᠯᠦᠭᠡ2-a",
    "COSTUMES_DOG2-B": " ᠭᠥᠯᠦᠭᠡ2-b",
    "COSTUMES_DOG2-C": " ᠭᠥᠯᠦᠭᠡ2-c",
    "COSTUMES_DONUT": " ᠴᠠᠭᠠᠷᠢᠭ",
    "COSTUMES_DORIAN-A": "᠋ ᠳ᠋ᠤᠷᠢᠶᠠᠨ  ᠬᠥᠮᠦᠨ-a",
    "COSTUMES_DORIAN-B": "᠋ ᠳ᠋ᠤᠷᠢᠶᠠᠨ  ᠬᠥᠮᠦᠨ-b",
    "COSTUMES_DORIAN-C": "᠋ ᠳ᠋ᠤᠷᠢᠶᠠᠨ  ᠬᠥᠮᠦᠨ-c",
    "COSTUMES_DORIAN-D": "᠋ ᠳ᠋ᠤᠷᠢᠶᠠᠨ  ᠬᠥᠮᠦᠨ-d",
    "COSTUMES_DOT-A": " ᠲᠤᠰᠤᠯ-a",
    "COSTUMES_DOT-B": " ᠲᠤᠰᠤᠯ-b",
    "COSTUMES_DOT-C": " ᠲᠤᠰᠤᠯ-c",
    "COSTUMES_DOT-D": " ᠲᠤᠰᠤᠯ-d",
    "COSTUMES_DOVE-A": " ᠲᠠᠭᠲᠠᠭ᠎ᠠ-a",
    "COSTUMES_DOVE-B": " ᠳᠠᠭᠲᠠᠭ᠎ᠠ-b",
    "COSTUMES_DRAGON-A": " ᠯᠤᠤ-a",
    "COSTUMES_DRAGON-B": " ᠯᠤᠤ-b",
    "COSTUMES_DRAGON-C": " ᠯᠤᠤ-c",
    "COSTUMES_DRAGON1-A": "ᠯᠤᠤ1-a",
    "COSTUMES_DRAGON1-B": "ᠯᠤᠤ1-b",
    "COSTUMES_DRAGONFLY-A": "Dragonfly-a",
    "COSTUMES_DRAGONFLY-B": "Dragonfly-b",
    "COSTUMES_DRESS-A": "dress-a",
    "COSTUMES_DRESS-B": "dress-b",
    "COSTUMES_DRESS-C": "dress-c",
    "COSTUMES_DRUM-A": " ᠬᠡᠩᠭᠡᠷᠭᠡ-a",
    "COSTUMES_DRUM-B": " ᠬᠡᠩᠭᠡᠷᠭᠡ-b",
    "COSTUMES_DRUM-CYMBAL-A": " ᠴᠠᠩ-a",
    "COSTUMES_DRUM-CYMBAL-B": " ᠴᠠᠩ-b",
    "COSTUMES_DRUM-HIGHHAT-A": " ᠴᠠᠩ-a",
    "COSTUMES_DRUM-HIGHHAT-B": " ᠴᠠᠩ-b",
    "COSTUMES_DRUM-KIT": " ᠵᠠᠽᠢ ᠬᠡᠩᠭᠡᠷᠭᠡ",
    "COSTUMES_DRUM-KIT-B": " ᠵᠠᠽᠢ ᠬᠡᠩᠭᠡᠷᠭᠡ-b",
    "COSTUMES_DRUM-SNARE-A": " ᠪᠠᠭᠠ ᠬᠡᠩᠭᠡᠷᠭᠡ-a",
    "COSTUMES_DRUM-SNARE-B": " ᠪᠠᠭᠠ ᠬᠡᠩᠭᠡᠷᠭᠡ-b",
    "COSTUMES_DRUMS_CONGA-A": "Drums Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Drums Conga-b",
    "COSTUMES_DUCK": " ᠨᠤᠭᠤᠰᠤ ",
    "COSTUMES_EARTH": " ᠳᠡᠯᠡᠬᠡᠢ ᠶᠢᠨ ᠪᠥᠮᠪᠥᠷᠴᠡᠭ",
    "COSTUMES_EASEL-A": "Easel-a",
    "COSTUMES_EASEL-B": "Easel-b",
    "COSTUMES_EASEL-C": "Easel-c",
    "COSTUMES_EGG-A": " ᠥᠨᠳᠡᠭᠡ-a",
    "COSTUMES_EGG-B": " ᠥᠨᠳᠡᠭᠡ-b",
    "COSTUMES_EGG-C": " ᠥᠨᠳᠡᠭᠡ-c",
    "COSTUMES_EGG-D": " ᠥᠨᠳᠡᠭᠡ-d",
    "COSTUMES_EGG-E": " ᠥᠨᠳᠡᠭᠡ-e",
    "COSTUMES_EGG-F": "egg-f",
    "COSTUMES_ELEPHANT-A": "elephant-a",
    "COSTUMES_ELEPHANT-B": "elephant-b",
    "COSTUMES_ELF-A": " ᠪᠢᠴᠢᠬᠠᠨ ᠠᠨᠤᠬᠠᠢ-a",
    "COSTUMES_ELF-B": "ᠪᠢᠴᠢᠬᠠᠨ ᠠᠨᠤᠬᠠᠢ-b",
    "COSTUMES_ELF-C": "ᠪᠢᠴᠢᠬᠠᠨ ᠠᠨᠤᠬᠠᠢ-c",
    "COSTUMES_ELF-D": "ᠪᠢᠴᠢᠬᠠᠨ ᠠᠨᠤᠬᠠᠢ-d",
    "COSTUMES_ELF-E": "ᠪᠢᠴᠢᠬᠠᠨ ᠠᠨᠤᠬᠠᠢ-e",
    "COSTUMES_FAIRY-A": " ᠠᠨᠤᠬᠠᠢ-a",
    "COSTUMES_FAIRY-B": " ᠠᠨᠤᠬᠠᠢ-b",
    "COSTUMES_FAIRY-C": " ᠠᠨᠤᠬᠠᠢ-c",
    "COSTUMES_FAIRY-D": " ᠠᠨᠤᠬᠠᠢ-d",
    "COSTUMES_FAIRY-E": " ᠠᠨᠤᠬᠠᠢ-e",
    "COSTUMES_FISH-A": " ᠵᠢᠭᠠᠰᠤ-a",
    "COSTUMES_FISH-B": " ᠵᠢᠭᠠᠰᠤ-b",
    "COSTUMES_FISH-C": " ᠵᠢᠭᠠᠰᠤ-c",
    "COSTUMES_FISH-D": " ᠵᠢᠭᠠᠰᠤ-d",
    "COSTUMES_FISHBOWL-A": "Fishbowl-a",
    "COSTUMES_FISHBOWL-B": "Fishbowl-b",
    "COSTUMES_FOOD_TRUCK-A": "Food Truck-a",
    "COSTUMES_FOOD_TRUCK-B": "Food Truck-b",
    "COSTUMES_FOOD_TRUCK-C": "Food Truck-c",
    "COSTUMES_FOOTBALL_RUNNING": "football running",
    "COSTUMES_FOOTBALL_STANDING": "football standing",
    "COSTUMES_FORTUNE_COOKIE": "fortune cookie",
    "COSTUMES_FOX-A": "ᠦᠨᠡᠭᠡ-a",
    "COSTUMES_FOX-B": " ᠦᠨᠡᠭᠡ-b",
    "COSTUMES_FOX-C": " ᠦᠨᠡᠭᠡ-c",
    "COSTUMES_FRANK-A": "frank-a",
    "COSTUMES_FRANK-B": "frank-b",
    "COSTUMES_FRANK-C": "frank-c",
    "COSTUMES_FRANK-D": "frank-d",
    "COSTUMES_FROG": " ᠮᠡᠯᠡᠬᠡᠢ",
    "COSTUMES_FROG_2-A": "Frog 2-a",
    "COSTUMES_FROG_2-B": "Frog 2-b",
    "COSTUMES_FROG_2-C": "Frog 2-c",
    "COSTUMES_FRUIT_PLATTER": "fruit platter",
    "COSTUMES_FRUITSALAD": " ᠵᠢᠮᠢᠰᠦᠨ ᠦ ᠰᠠᠯᠠᠲ",
    "COSTUMES_GHOST-A": "ghost-a",
    "COSTUMES_GHOST-B": "ghost-b",
    "COSTUMES_GHOST-C": "ghost-c",
    "COSTUMES_GHOST-D": "ghost-d",
    "COSTUMES_GIFT-A": " ᠪᠡᠯᠡᠭ-a",
    "COSTUMES_GIFT-B": " ᠪᠡᠯᠡᠭ-b",
    "COSTUMES_GIRAFFE-A": "giraffe-a",
    "COSTUMES_GIRAFFE-B": "giraffe-b",
    "COSTUMES_GIRAFFE-C": "giraffe-c",
    "COSTUMES_GLASS_WATER-A": " ᠴᠣᠮᠣ-a",
    "COSTUMES_GLASS_WATER-B": " ᠴᠣᠮᠣ-b",
    "COSTUMES_GLASSES-A": "glasses-a",
    "COSTUMES_GLASSES-B": "glasses-b",
    "COSTUMES_GLASSES-C": "glasses-c",
    "COSTUMES_GLASSES-E": "glasses-e",
    "COSTUMES_GOALIE-A": " ᠡᠭᠦᠳᠡᠴᠢ-a",
    "COSTUMES_GOALIE-B": "ᠡᠭᠦᠳᠡᠴᠢ-b",
    "COSTUMES_GOALIE-C": "ᠡᠭᠦᠳᠡᠴᠢ-c",
    "COSTUMES_GOALIE-D": "ᠡᠭᠦᠳᠡᠴᠢ-d",
    "COSTUMES_GOALIE-E": "ᠡᠭᠦᠳᠡᠴᠢ-e",
    "COSTUMES_GOBLIN-A": " ᠭᠣᠪᠯᠢᠨ-a",
    "COSTUMES_GOBLIN-B": "ᠭᠣᠪᠯᠢᠨ-b",
    "COSTUMES_GOBLIN-C": "ᠭᠣᠪᠯᠢᠨ-c",
    "COSTUMES_GOBLIN-D": "ᠭᠣᠪᠯᠢᠨ-d",
    "COSTUMES_GRASSHOPPER-A": "Grasshopper-a",
    "COSTUMES_GRASSHOPPER-B": "Grasshopper-b",
    "COSTUMES_GRASSHOPPER-C": "Grasshopper-c",
    "COSTUMES_GRASSHOPPER-D": "Grasshopper-d",
    "COSTUMES_GRASSHOPPER-E": "Grasshopper-e",
    "COSTUMES_GRASSHOPPER-F": "Grasshopper-f",
    "COSTUMES_GREEN_FLAG": " ᠨᠣᠭᠣᠭᠠᠨ ᠲᠤᠭ",
    "COSTUMES_GRIFFIN-A": " ᠭᠷᠢᠹᠹᠢᠨ-a",
    "COSTUMES_GRIFFIN-B": "Griffin-b",
    "COSTUMES_GRIFFIN-C": "Griffin-c",
    "COSTUMES_GRIFFIN-D": "Griffin-d",
    "COSTUMES_GUITAR-A": " ᠭᠢᠲ᠋ᠠᠷ-a",
    "COSTUMES_GUITAR-B": " ᠭᠢᠲ᠋ᠠᠷ-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷ1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷ1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷ2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷ2-b",
    "COSTUMES_HANNAH-A": "hannah-a",
    "COSTUMES_HANNAH-B": "hannah-b",
    "COSTUMES_HANNAH-C": "hannah-c",
    "COSTUMES_HARE-A": "hare-a",
    "COSTUMES_HARE-B": "hare-b",
    "COSTUMES_HARE-C": "hare-c",
    "COSTUMES_HARPER-A": "harper-a",
    "COSTUMES_HARPER-B": "harper-b",
    "COSTUMES_HARPER-C": "harper-c",
    "COSTUMES_HAT-A": "hat-a",
    "COSTUMES_HAT-B": "hat-b",
    "COSTUMES_HAT-C": "hat-c",
    "COSTUMES_HAT-D": "hat-d",
    "COSTUMES_HATCHLING-A": "hatchling-a",
    "COSTUMES_HATCHLING-B": "hatchling-b",
    "COSTUMES_HATCHLING-C": "hatchling-c",
    "COSTUMES_HEART_CODE": " ᠬᠠᠢᠷ᠎ᠠ",
    "COSTUMES_HEART_FACE": " ᠬᠠᠢᠷ᠎ᠠ ᠶᠢᠨ ᠳᠥᠷᠰᠦ",
    "COSTUMES_HEART_LOVE": "heart love",
    "COSTUMES_HEART_PURPLE": " ᠬᠠᠢᠷ᠎ᠠ- ᠬᠦᠷᠢᠨ",
    "COSTUMES_HEART_RED": " ᠬᠠᠢᠷ᠎ᠠ- ᠤᠯᠠᠭᠠᠨ",
    "COSTUMES_HEART_SMILE": " ᠬᠠᠢᠷ᠎ᠠ",
    "COSTUMES_HEART_SWEET": " ᠬᠠᠢᠷ᠎ᠠ",
    "COSTUMES_HEDGEHOG-A": " ᠵᠠᠷᠠᠭ᠎ᠠ-a",
    "COSTUMES_HEDGEHOG-B": " ᠵᠠᠷᠠᠭ᠎ᠠ-b",
    "COSTUMES_HEDGEHOG-C": " ᠵᠠᠷᠠᠭ᠎ᠠ-c",
    "COSTUMES_HEDGEHOG-D": " ᠵᠠᠷᠠᠭ᠎ᠠ-d",
    "COSTUMES_HEDGEHOG-E": " ᠵᠠᠷᠠᠭ᠎ᠠ-e",
    "COSTUMES_HEN-A": "hen-a",
    "COSTUMES_HEN-B": "hen-b",
    "COSTUMES_HEN-C": "hen-c",
    "COSTUMES_HEN-D": "hen-d",
    "COSTUMES_HIPPO1-A": " ᠤᠰᠤᠨ ᠳᠡᠬᠢ-a",
    "COSTUMES_HIPPO1-B": " ᠤᠰᠤᠨ ᠳᠡᠬᠢ-b",
    "COSTUMES_HOME_BUTTON": " ᠭᠡᠷ ᠦᠨ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ",
    "COSTUMES_HORSE-A": "horse-a",
    "COSTUMES_HORSE-B": "horse-b",
    "COSTUMES_JAIME_WALKING-A": "jaime walking-a",
    "COSTUMES_JAIME_WALKING-B": "jaime walking-b",
    "COSTUMES_JAIME_WALKING-C": "jaime walking-c",
    "COSTUMES_JAIME_WALKING-D": "jaime walking-d",
    "COSTUMES_JAIME_WALKING-E": "jaime walking-e",
    "COSTUMES_JAIME-A": "jaime-a",
    "COSTUMES_JAIME-B": "jaime-b",
    "COSTUMES_JAMAL-A": "jamal-a",
    "COSTUMES_JAMAL-B": "jamal-b",
    "COSTUMES_JAMAL-C": "jamal-c",
    "COSTUMES_JAMAL-D": "jamal-d",
    "COSTUMES_JAR-A": " ᠯᠣᠩᠬᠣ-a",
    "COSTUMES_JAR-B": " ᠯᠣᠩᠬᠣ-b",
    "COSTUMES_JELLYFISH-A": " ᠵᠠᠯᠬᠠᠭ-a",
    "COSTUMES_JELLYFISH-B": " ᠵᠠᠯᠬᠠᠭ-b",
    "COSTUMES_JELLYFISH-C": " ᠵᠠᠯᠬᠠᠭ-c",
    "COSTUMES_JELLYFISH-D": " ᠵᠠᠯᠬᠠᠭ-d",
    "COSTUMES_JO_POP_DOWN": "jo pop down",
    "COSTUMES_JO_POP_FRONT": "jo pop front",
    "COSTUMES_JO_POP_L_ARM": "jo pop L arm",
    "COSTUMES_JO_POP_LEFT": "jo pop left",
    "COSTUMES_JO_POP_R_ARM": "jo pop R arm",
    "COSTUMES_JO_POP_RIGHT": "jo pop right",
    "COSTUMES_JO_POP_STAND": "jo pop stand",
    "COSTUMES_JO_STANCE": "jo stance",
    "COSTUMES_JO_TOP_L_CROSS": "jo top L cross",
    "COSTUMES_JO_TOP_L_LEG": "jo top L leg",
    "COSTUMES_JO_TOP_R_CROSS": "jo top R cross",
    "COSTUMES_JO_TOP_R_LEG": "jo top R leg",
    "COSTUMES_JO_TOP_STAND": "jo top stand",
    "COSTUMES_JORDYN-A": " ᠵᠣᠷᠳ᠋ᠸ᠋ᠨ-a",
    "COSTUMES_JORDYN-B": " ᠵᠣᠷᠳ᠋ᠸ᠋ᠨ-b",
    "COSTUMES_JORDYN-C": " ᠵᠣᠷᠳ᠋ᠸ᠋ᠨ-c",
    "COSTUMES_JORDYN-D": " ᠵᠣᠷᠳ᠋ᠸ᠋ᠨ-d",
    "COSTUMES_KAI-A": "kai-a",
    "COSTUMES_KAI-B": "kai-b",
    "COSTUMES_KEY": " ᠲᠦᠯᠬᠢᠭᠦᠷ",
    "COSTUMES_KEYBOARD-A": " ᠳᠠᠷᠤᠭᠤᠯ ᠤᠨ ᠲᠠᠪᠠᠭ-a",
    "COSTUMES_KEYBOARD-B": " ᠳᠠᠷᠤᠭᠤᠯ ᠤᠨ ᠲᠠᠪᠠᠭ-b",
    "COSTUMES_KIRAN-A": " ᠺᠢᠷᠠᠨ-a",
    "COSTUMES_KIRAN-B": " ᠺᠢᠷᠠᠨ-b",
    "COSTUMES_KIRAN-C": " ᠺᠢᠷᠠᠨ-c",
    "COSTUMES_KIRAN-D": " ᠺᠢᠷᠠᠨ-d",
    "COSTUMES_KIRAN-E": " ᠺᠢᠷᠠᠨ-e",
    "COSTUMES_KIRAN-F": " ᠺᠢᠷᠠᠨ-f",
    "COSTUMES_KNIGHT": " ᠮᠣᠷᠢᠲᠠᠨ",
    "COSTUMES_LADYBUG2": " ᠰᠢᠭᠦᠷ ᠬᠣᠷᠤᠬᠠᠢ1",
    "COSTUMES_LADYBUG2-A": " ᠰᠢᠭᠦᠷ ᠬᠣᠷᠤᠬᠠᠢ2-a",
    "COSTUMES_LADYBUG2-B": " ᠰᠢᠭᠦᠷ ᠬᠣᠷᠤᠬᠠᠢ2-b",
    "COSTUMES_LAPTOP": " ᠪᠢᠴᠢᠯ ᠺᠣᠮᠫᠢᠦ᠋ᠲ᠋ᠧᠷ",
    "COSTUMES_LB_POP_DOWN": "lb pop down",
    "COSTUMES_LB_POP_FRONT": "lb pop front",
    "COSTUMES_LB_POP_L_ARM": "lb pop L arm",
    "COSTUMES_LB_POP_LEFT": "lb pop left",
    "COSTUMES_LB_POP_R_ARM": "lb pop R arm",
    "COSTUMES_LB_POP_RIGHT": "lb pop right",
    "COSTUMES_LB_POP_STAND": "lb pop stand",
    "COSTUMES_LB_STANCE": "lb stance",
    "COSTUMES_LB_TOP_L_CROSS": "lb top L cross",
    "COSTUMES_LB_TOP_L_LEG": "lb top L leg",
    "COSTUMES_LB_TOP_R_CROSS": "lb top R cross",
    "COSTUMES_LB_TOP_R_LEG": "lb top R leg",
    "COSTUMES_LB_TOP_STAND": "lb top stand",
    "COSTUMES_LIGHTNING": " ᠳᠣᠩᠭᠣᠳᠬᠤ",
    "COSTUMES_LINE": " ᠤᠲᠠᠰᠤ",
    "COSTUMES_LION-A": " ᠠᠷᠰᠯᠠᠨ-a",
    "COSTUMES_LION-B": " ᠠᠷᠰᠯᠠᠨ-b",
    "COSTUMES_LION-C": "lion-c",
    "COSTUMES_LLAMA": " ᠪᠡᠰᠡᠷᠡᠭ ᠲᠡᠮᠡᠭᠡ",
    "COSTUMES_LLAMA-B": " ᠪᠡᠰᠡᠷᠡᠭ ᠲᠡᠮᠡᠭᠡ-b",
    "COSTUMES_LLAMA-C": " ᠪᠡᠰᠡᠷᠡᠭ ᠲᠡᠮᠡᠭᠡ-c",
    "COSTUMES_MAGICWAND": " ᠰᠢᠳᠢᠲᠦ ᠰᠢᠳᠠᠮ",
    "COSTUMES_MAX-A": " ᠮᠠᠺᠰ-a",
    "COSTUMES_MAX-B": " ᠮᠠᠺᠰ-b",
    "COSTUMES_MAX-C": " ᠮᠠᠺᠰ-c",
    "COSTUMES_MAX-D": " ᠮᠠᠺᠰ-d",
    "COSTUMES_MERMAID-A": " ᠬᠦᠮᠦᠨ ᠵᠢᠭᠠᠰᠤ-a",
    "COSTUMES_MERMAID-B": " ᠬᠦᠮᠦᠨ ᠵᠢᠭᠠᠰᠤ-b",
    "COSTUMES_MERMAID-C": "mermaid-c",
    "COSTUMES_MERMAID-D": "mermaid-d",
    "COSTUMES_MICROPHONE-A": " ᠮᠸᠺᠷᠣᠹᠣᠨ-a",
    "COSTUMES_MICROPHONE-B": " ᠮᠸᠺᠷᠣᠹᠣᠨ-b",
    "COSTUMES_MILK-A": " ᠰᠦ-a",
    "COSTUMES_MILK-B": " ᠰᠦ-b",
    "COSTUMES_MILK-C": " ᠰᠦ-c",
    "COSTUMES_MILK-D": " ᠰᠦ-d",
    "COSTUMES_MILK-E": " ᠰᠦ-e",
    "COSTUMES_MONET-A": " ᠮᠣᠨᠠᠢ-a",
    "COSTUMES_MONET-B": " ᠮᠣᠨᠠᠢ-b",
    "COSTUMES_MONET-C": " ᠮᠣᠨᠠᠢ-c",
    "COSTUMES_MONET-D": " ᠮᠣᠨᠠᠢ-d",
    "COSTUMES_MONET-E": " ᠮᠣᠨᠠᠢ-e",
    "COSTUMES_MONKEY-A": " ᠰᠠᠮᠵᠠ-a",
    "COSTUMES_MONKEY-B": " ᠰᠠᠮᠵᠠ-b",
    "COSTUMES_MONKEY-C": " ᠰᠠᠮᠵᠠ-c",
    "COSTUMES_MOTORCYCLE-A": "Motorcycle-a",
    "COSTUMES_MOTORCYCLE-B": "Motorcycle-b",
    "COSTUMES_MOTORCYCLE-C": "Motorcycle-c",
    "COSTUMES_MOTORCYCLE-D": "Motorcycle-d",
    "COSTUMES_MOUSE1-A": "ᠬᠤᠯᠤᠭᠠᠨ᠎ᠠ1-a",
    "COSTUMES_MOUSE1-B": "ᠬᠤᠯᠤᠭᠠᠨ᠎ᠠ1-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "ᠤᠨᠠᠭ᠎ᠠ ",
    "COSTUMES_OCTOPUS-A": "ᠨᠢᠤᠮᠠᠷ᠎ᠠ-a",
    "COSTUMES_OCTOPUS-B": "ᠨᠢᠤᠮᠠᠷ᠎ᠠ-b",
    "COSTUMES_OCTOPUS-C": "ᠨᠢᠤᠮᠠᠷ᠎ᠠ-c",
    "COSTUMES_OCTOPUS-D": "ᠨᠢᠤᠮᠠᠷ᠎ᠠ-d",
    "COSTUMES_OCTOPUS-E": "ᠨᠢᠤᠮᠠᠷ᠎ᠠ-e",
    "COSTUMES_ORANGE": " ᠵᠦᠷᠵᠢ",
    "COSTUMES_ORANGE2-A": "ᠵᠦᠷᠵᠢ2-a",
    "COSTUMES_ORANGE2-B": "ᠵᠦᠷᠵᠢ2-b",
    "COSTUMES_OUTFIELDER-A": "outfielder-a",
    "COSTUMES_OUTFIELDER-B": "outfielder-b",
    "COSTUMES_OUTFIELDER-C": "outfielder-c",
    "COSTUMES_OUTFIELDER-D": "outfielder-d",
    "COSTUMES_OWL-A": " ᠤᠭᠤᠯᠢ-a",
    "COSTUMES_OWL-B": " ᠤᠭᠤᠯᠢ-b",
    "COSTUMES_OWL-C": " ᠤᠭᠤᠯᠢ-c",
    "COSTUMES_PADDLE": " ᠰᠡᠯᠢᠭᠦᠷ",
    "COSTUMES_PANTHER-A": " ᠠᠮᠧᠷᠢᠺᠠ ᠲᠢᠪ ᠦᠨ ᠢᠷᠪᠢᠰ-a",
    "COSTUMES_PANTHER-B": "ᠠᠮᠧᠷᠢᠺᠠ ᠲᠢᠪ ᠦᠨ ᠢᠷᠪᠢᠰ-b",
    "COSTUMES_PANTHER-C": "ᠠᠮᠧᠷᠢᠺᠠ ᠲᠢᠪ ᠦᠨ ᠢᠷᠪᠢᠰ-c",
    "COSTUMES_PANTS-A": "pants-a",
    "COSTUMES_PANTS-B": "pants-b",
    "COSTUMES_PARROT-A": " ᠲᠣᠳᠢ-a",
    "COSTUMES_PARROT-B": " ᠲᠣᠳᠢ-b",
    "COSTUMES_PARTY_HAT-A": "Party Hat-a",
    "COSTUMES_PARTY_HAT-B": "Party Hat-b",
    "COSTUMES_PARTY_HAT-E": "Party Hat-e",
    "COSTUMES_PENCIL-A": " ᠬᠠᠷᠠᠨᠳᠠ-a",
    "COSTUMES_PENCIL-B": " ᠬᠠᠷᠠᠨᠳᠠ-b",
    "COSTUMES_PENGUIN-A": "penguin-a",
    "COSTUMES_PENGUIN-B": "penguin-b",
    "COSTUMES_PENGUIN-C": "penguin-c",
    "COSTUMES_PENGUIN2-A": "ᠶᠠᠪᠠᠭᠠᠨ ᠭᠠᠯᠠᠭᠤ2-a",
    "COSTUMES_PENGUIN2-B": "ᠶᠠᠪᠠᠭᠠᠨ ᠭᠠᠯᠠᠭᠤ2-b",
    "COSTUMES_PENGUIN2-C": "ᠶᠠᠪᠠᠭᠠᠨ ᠭᠠᠯᠠᠭᠤ2-c",
    "COSTUMES_PENGUIN2-D": "penguin2-d",
    "COSTUMES_PITCHER-A": "pitcher-a",
    "COSTUMES_PITCHER-B": "pitcher-b",
    "COSTUMES_PITCHER-C": "pitcher-c",
    "COSTUMES_PITCHER-D": "pitcher-d",
    "COSTUMES_PLANET2": " ᠭᠠᠷᠠᠭ ᠣᠳᠣ",
    "COSTUMES_POLAR_BEAR-A": "polar bear-a",
    "COSTUMES_POLAR_BEAR-B": "polar bear-b",
    "COSTUMES_POLAR_BEAR-C": "polar bear-c",
    "COSTUMES_POTION-A": " ᠡᠮ-a",
    "COSTUMES_POTION-B": " ᠡᠮ-b",
    "COSTUMES_POTION-C": " ᠡᠮ-c",
    "COSTUMES_PRINCE": " ᠸᠠᠩ ᠤᠨ ᠬᠥᠪᠡᠭᠦᠨ",
    "COSTUMES_PRINCESS-A": " ᠭᠦᠩᠵᠦ-a",
    "COSTUMES_PRINCESS-B": "ᠭᠦᠩᠵᠦ-b",
    "COSTUMES_PRINCESS-C": "ᠭᠦᠩᠵᠦ-c",
    "COSTUMES_PRINCESS-D": "ᠭᠦᠩᠵᠦ-d",
    "COSTUMES_PRINCESS-E": "ᠭᠦᠩᠵᠦ-e",
    "COSTUMES_PUFFERFISH-A": " ᠬᠤᠰᠬ᠎ᠠ-a",
    "COSTUMES_PUFFERFISH-B": "ᠬᠤᠰᠬ᠎ᠠ-b",
    "COSTUMES_PUFFERFISH-C": "ᠬᠤᠰᠬ᠎ᠠ-c",
    "COSTUMES_PUFFERFISH-D": "ᠬᠤᠰᠬ᠎ᠠ-d",
    "COSTUMES_PUPPY_BACK": "puppy back",
    "COSTUMES_PUPPY_RIGHT": "puppy right",
    "COSTUMES_PUPPY_SIDE": "puppy side",
    "COSTUMES_PUPPY_SIT": "puppy sit",
    "COSTUMES_RABBIT-A": " ᠲᠠᠤᠯᠠᠢ-a",
    "COSTUMES_RABBIT-B": "ᠲᠠᠤᠯᠠᠢ-b",
    "COSTUMES_RABBIT-C": "ᠲᠠᠤᠯᠠᠢ-c",
    "COSTUMES_RABBIT-D": "ᠲᠠᠤᠯᠠᠢ-d",
    "COSTUMES_RABBIT-E": "ᠲᠠᠤᠯᠠᠢ-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": " ᠰᠣᠯᠣᠩᠭ᠎ᠠ",
    "COSTUMES_REFEREE-A": " ᠰᠢᠭᠦᠭᠴᠢ-a",
    "COSTUMES_REFEREE-B": " ᠰᠢᠭᠦᠭᠴᠢ-b",
    "COSTUMES_REFEREE-C": " ᠰᠢᠭᠦᠭᠴᠢ-c",
    "COSTUMES_REFEREE-D": " ᠰᠢᠭᠦᠭᠴᠢ-d",
    "COSTUMES_REINDEER": " ᠴᠠ ᠪᠤᠭᠤ",
    "COSTUMES_RETRO_ROBOT_A": "Retro Robot a",
    "COSTUMES_RETRO_ROBOT_B": "Retro Robot b",
    "COSTUMES_RETRO_ROBOT_C": "Retro Robot c",
    "COSTUMES_RIPLEY-A": " ᠷᠢᠫᠯᠢ-a",
    "COSTUMES_RIPLEY-B": " ᠷᠢᠫᠯᠢ-b",
    "COSTUMES_RIPLEY-C": " ᠷᠢᠫᠯᠢ-c",
    "COSTUMES_RIPLEY-D": " ᠷᠢᠫᠯᠢ-d",
    "COSTUMES_RIPLEY-E": " ᠷᠢᠫᠯᠢ-e",
    "COSTUMES_RIPLEY-F": " ᠷᠢᠫᠯᠢ-f",
    "COSTUMES_ROBOT-A": " ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ-a",
    "COSTUMES_ROBOT-B": " ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ-b",
    "COSTUMES_ROBOT-C": " ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ-c",
    "COSTUMES_ROBOT-D": " ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ-d",
    "COSTUMES_ROCKETSHIP-A": "rocketship-a",
    "COSTUMES_ROCKETSHIP-B": "rocketship-b",
    "COSTUMES_ROCKETSHIP-C": "rocketship-c",
    "COSTUMES_ROCKETSHIP-D": "rocketship-d",
    "COSTUMES_ROCKETSHIP-E": "rocketship-e",
    "COSTUMES_ROCKS": " ᠴᠢᠯᠠᠭᠤ",
    "COSTUMES_ROOSTER-A": "rooster-a",
    "COSTUMES_ROOSTER-B": "rooster-b",
    "COSTUMES_ROOSTER-C": "rooster-c",
    "COSTUMES_RUBY-A": "ruby-a",
    "COSTUMES_RUBY-B": "ruby-b",
    "COSTUMES_SAILBOAT": "sailboat",
    "COSTUMES_SAM": "sam",
    "COSTUMES_SAXOPHONE-A": " ᠰᠠᠺᠦᠰ-a",
    "COSTUMES_SAXOPHONE-B": " ᠰᠠᠺᠦᠰ-b",
    "COSTUMES_SCARF-A": "Scarf-a",
    "COSTUMES_SCARF-B": "Scarf-b",
    "COSTUMES_SCARF-C": "Scarf-c",
    "COSTUMES_SHARK-A": " ᠳᠤᠯᠤᠨ-a",
    "COSTUMES_SHARK-B": " ᠳᠤᠯᠤᠨ1-b",
    "COSTUMES_SHARK2-A": "shark2-a",
    "COSTUMES_SHARK2-B": "shark2-b",
    "COSTUMES_SHARK2-C": "shark2-c",
    "COSTUMES_SHIRT-A": "shirt-a",
    "COSTUMES_SHOES-A": "shoes-a",
    "COSTUMES_SHOES-B": "shoes-b",
    "COSTUMES_SHOES-C": "shoes-c",
    "COSTUMES_SHOES-D": "shoes-d",
    "COSTUMES_SHORTS-A": "shorts-a",
    "COSTUMES_SHORTS-B": "shorts-b",
    "COSTUMES_SHORTS-C": "shorts-c",
    "COSTUMES_SINGER1": "ᠳᠠᠭᠤᠴᠢᠨ1",
    "COSTUMES_SKELETON-A": "skeleton-a",
    "COSTUMES_SKELETON-B": "skeleton-b",
    "COSTUMES_SKELETON-D": "skeleton-d",
    "COSTUMES_SKELETON-E": "skeleton-e",
    "COSTUMES_SNAKE-A": " ᠮᠣᠭᠠᠢ-a",
    "COSTUMES_SNAKE-B": " ᠮᠣᠭᠠᠢ-b",
    "COSTUMES_SNAKE-C": " ᠮᠣᠭᠠᠢ-c",
    "COSTUMES_SNOWFLAKE": " ᠴᠠᠰᠤᠨ ᠯᠠᠪᠰᠠ",
    "COSTUMES_SNOWMAN": " ᠴᠠᠰᠤᠨ ᠬᠥᠮᠥᠨ",
    "COSTUMES_SOCCER_BALL": " ᠬᠥᠯ ᠪᠥᠮᠪᠥᠭᠡ",
    "COSTUMES_SPEAKER": "ᠬᠦᠩᠭᠢᠨᠡᠭᠦᠷ",
    "COSTUMES_SQUIRREL": "squirrel",
    "COSTUMES_STAR": " ᠣᠳᠣ",
    "COSTUMES_STARFISH-A": " ᠳᠠᠯᠠᠢ ᠶᠢᠨ ᠣᠳᠣᠨ-a",
    "COSTUMES_STARFISH-B_": " ᠳᠠᠯᠠᠢ ᠶᠢᠨ ᠣᠳᠣᠨ-b ",
    "COSTUMES_STOP": " ᠲᠦᠷ ᠵᠣᠭᠰᠣᠭᠠᠬᠤ ᠳᠠᠷᠤᠪᠴᠢ",
    "COSTUMES_STRAWBERRY-A": " ᠬᠦᠵᠡᠯᠵᠡᠭᠡᠨ᠎ᠡ-a",
    "COSTUMES_STRAWBERRY-B": "ᠬᠦᠵᠡᠯᠵᠡᠭᠡᠨ᠎ᠡ-b",
    "COSTUMES_STRAWBERRY-C": "ᠬᠦᠵᠡᠯᠵᠡᠭᠡᠨ᠎ᠡ-c",
    "COSTUMES_STRAWBERRY-D": "ᠬᠦᠵᠡᠯᠵᠡᠭᠡᠨ᠎ᠡ-d",
    "COSTUMES_STRAWBERRY-E": "ᠬᠦᠵᠡᠯᠵᠡᠭᠡᠨ᠎ᠡ-e",
    "COSTUMES_SUN": " ᠨᠠᠷᠠ",
    "COSTUMES_SUNGLASSES-A": "sunglasses-a",
    "COSTUMES_SUNGLASSES-B": "sunglasses-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-wizard",
    "COSTUMES_TAKEOUT-A": "ᠵᠠᠬᠢᠶᠠᠯᠠᠭ᠎ᠠ ᠬᠣᠭᠣᠯᠠ-a",
    "COSTUMES_TAKEOUT-B": "ᠵᠠᠬᠢᠶᠠᠯᠠᠭ᠎ᠠ ᠬᠣᠭᠣᠯᠠ-b",
    "COSTUMES_TAKEOUT-C": "ᠵᠠᠬᠢᠶᠠᠯᠠᠭ᠎ᠠ ᠬᠣᠭᠣᠯᠠ-c",
    "COSTUMES_TAKEOUT-D": "ᠵᠠᠬᠢᠶᠠᠯᠠᠭ᠎ᠠ ᠬᠣᠭᠣᠯᠠ-d",
    "COSTUMES_TAKEOUT-E": "ᠵᠠᠬᠢᠶᠠᠯᠠᠭ᠎ᠠ ᠬᠣᠭᠣᠯᠠ-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 pop down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 pop front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 pop L arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 pop left",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 pop R arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 pop right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 pop stand",
    "COSTUMES_TEN80_STANCE": "Ten80 stance",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 top freeze",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 top L step",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 top R cross",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 top R step",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 top stand",
    "COSTUMES_TENNISBALL": "tennisball",
    "COSTUMES_TOUCAN-A": " ᠬᠡᠷᠢᠰ ᠰᠢᠪᠠᠭᠤ-a",
    "COSTUMES_TOUCAN-B": " ᠬᠡᠷᠢᠰ ᠰᠢᠪᠠᠭᠤ-b",
    "COSTUMES_TOUCAN-C": " ᠬᠡᠷᠢᠰ ᠰᠢᠪᠠᠭᠤ-c",
    "COSTUMES_TRAMPOLINE": "trampoline",
    "COSTUMES_TREE1": "ᠮᠣᠳᠣ1",
    "COSTUMES_TREES-A": " ᠮᠣᠳᠣᠳ-a",
    "COSTUMES_TREES-B": " ᠮᠣᠳᠣᠳ-b",
    "COSTUMES_TRUCK-A": "Truck-a",
    "COSTUMES_TRUCK-B": "Truck-b",
    "COSTUMES_TRUCK-C": "Truck-c",
    "COSTUMES_TRUMPET-A": " ᠪᠦᠷᠢᠶᠡ-a",
    "COSTUMES_TRUMPET-B": " ᠪᠦᠷᠢᠶᠡ-b",
    "COSTUMES_UNICORN": "ᠭᠠᠭᠴᠠ ᠡᠪᠡᠷᠲᠦ",
    "COSTUMES_UNICORN_2": "unicorn 2",
    "COSTUMES_UNICORN_RUNNING-A": "unicorn running-a",
    "COSTUMES_UNICORN_RUNNING-B": "unicorn running-b",
    "COSTUMES_UNICORN_RUNNING-C": "unicorn running-c",
    "COSTUMES_UNICORN_RUNNING-D": "unicorn running-d",
    "COSTUMES_UNICORN_RUNNING-E": "unicorn running-e",
    "COSTUMES_UNICORN_RUNNING-F": "unicorn running-f",
    "COSTUMES_WAND": " ᠰᠢᠳᠢᠲᠦ ᠮᠣᠳᠣ",
    "COSTUMES_WANDA": " ᠲᠡᠨᠦᠭᠦᠯᠴᠢᠨ",
    "COSTUMES_WATERMELON-A": "ᠲᠠᠷᠪᠤᠰ-a",
    "COSTUMES_WATERMELON-B": "ᠲᠠᠷᠪᠤᠰ-b",
    "COSTUMES_WATERMELON-C": "ᠲᠠᠷᠪᠤᠰ-c",
    "COSTUMES_WINTER_HAT": "Winter Hat",
    "COSTUMES_WITCH": " ᠢᠲᠤᠭᠠᠨ",
    "COSTUMES_WITCH-A": "ᠢᠲᠤᠭᠠᠨ-a",
    "COSTUMES_WITCH-B": "ᠢᠲᠤᠭᠠᠨ-b",
    "COSTUMES_WITCH-C": "ᠢᠲᠤᠭᠠᠨ-c",
    "COSTUMES_WITCH-D": "ᠢᠲᠤᠭᠠᠨ-d",
    "COSTUMES_WIZARD_HAT": "Wizard Hat",
    "COSTUMES_WIZARD-A": " ᠪᠥᠭᠡ-a",
    "COSTUMES_WIZARD-B": " ᠪᠥᠭᠡ-b",
    "COSTUMES_WIZARD-C": " ᠪᠥᠭᠡ-c",
    "COSTUMES_WIZARD-TOAD-A": " ᠮᠡᠯᠡᠬᠡᠢ ᠳᠣᠮᠴᠢ-a",
    "COSTUMES_WIZARD-TOAD-B": " ᠮᠡᠯᠡᠬᠡᠢ ᠳᠣᠮᠴᠢ-b",
    "COSTUMES_ZEBRA-A": "zebra-a",
    "COSTUMES_ZEBRA-B": "zebra-b",
    "COSTUMES_BLOCK-A": "ᠬᠡᠰᠡᠭᠲᠦ-a",
    "COSTUMES_BLOCK-B": "ᠬᠡᠰᠡᠭᠲᠦ-b",
    "COSTUMES_BLOCK-C": "ᠬᠡᠰᠡᠭᠲᠦ-c",
    "COSTUMES_BLOCK-D": "ᠬᠡᠰᠡᠭᠲᠦ-d",
    "COSTUMES_BLOCK-E": "ᠬᠡᠰᠡᠭᠲᠦ-e",
    "COSTUMES_BLOCK-F": "ᠬᠡᠰᠡᠭᠲᠦ-f",
    "COSTUMES_BLOCK-G": "ᠬᠡᠰᠡᠭᠲᠦ-g",
    "COSTUMES_BLOCK-H": "ᠬᠡᠰᠡᠭᠲᠦ-h",
    "COSTUMES_BLOCK-I": "ᠬᠡᠰᠡᠭᠲᠦ-i",
    "COSTUMES_BLOCK-J": "ᠬᠡᠰᠡᠭᠲᠦ-j",
    "COSTUMES_BLOCK-K": "ᠬᠡᠰᠡᠭᠲᠦ-k",
    "COSTUMES_BLOCK-L": "ᠬᠡᠰᠡᠭᠲᠦ-l",
    "COSTUMES_BLOCK-M": "ᠬᠡᠰᠡᠭᠲᠦ-m",
    "COSTUMES_BLOCK-N": "ᠬᠡᠰᠡᠭᠲᠦ-n",
    "COSTUMES_BLOCK-O": "ᠬᠡᠰᠡᠭᠲᠦ-o",
    "COSTUMES_BLOCK-P": "ᠬᠡᠰᠡᠭᠲᠦ-p",
    "COSTUMES_BLOCK-Q": "ᠬᠡᠰᠡᠭᠲᠦ-q",
    "COSTUMES_BLOCK-R": "ᠬᠡᠰᠡᠭᠲᠦ-r",
    "COSTUMES_BLOCK-S": "ᠬᠡᠰᠡᠭᠲᠦ-s",
    "COSTUMES_BLOCK-T": "ᠬᠡᠰᠡᠭᠲᠦ-t",
    "COSTUMES_BLOCK-U": "ᠬᠡᠰᠡᠭᠲᠦ-u",
    "COSTUMES_BLOCK-V": "ᠬᠡᠰᠡᠭᠲᠦ-v",
    "COSTUMES_BLOCK-W": "ᠬᠡᠰᠡᠭᠲᠦ-w",
    "COSTUMES_BLOCK-X": "ᠬᠡᠰᠡᠭᠲᠦ-x",
    "COSTUMES_BLOCK-Y": "ᠬᠡᠰᠡᠭᠲᠦ-y",
    "COSTUMES_BLOCK-Z": "ᠬᠡᠰᠡᠭᠲᠦ-z",
    "COSTUMES_GLOW-0": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-0",
    "COSTUMES_GLOW-1": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-1",
    "COSTUMES_GLOW-2": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-2",
    "COSTUMES_GLOW-3": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-3",
    "COSTUMES_GLOW-4": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-4",
    "COSTUMES_GLOW-5": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-5",
    "COSTUMES_GLOW-6": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-6",
    "COSTUMES_GLOW-7": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-7",
    "COSTUMES_GLOW-8": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-8",
    "COSTUMES_GLOW-9": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-9",
    "COSTUMES_GLOW-A": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-A",
    "COSTUMES_GLOW-B": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-B",
    "COSTUMES_GLOW-C": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-C",
    "COSTUMES_GLOW-D": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-D",
    "COSTUMES_GLOW-E": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-E",
    "COSTUMES_GLOW-F": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-F",
    "COSTUMES_GLOW-G": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-G",
    "COSTUMES_GLOW-H": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-H",
    "COSTUMES_GLOW-I": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-I",
    "COSTUMES_GLOW-J": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-J",
    "COSTUMES_GLOW-K": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-K",
    "COSTUMES_GLOW-L": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-L",
    "COSTUMES_GLOW-M": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-M",
    "COSTUMES_GLOW-N": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-N",
    "COSTUMES_GLOW-O": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-O",
    "COSTUMES_GLOW-P": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-P",
    "COSTUMES_GLOW-Q": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-Q",
    "COSTUMES_GLOW-R": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-R",
    "COSTUMES_GLOW-S": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-S",
    "COSTUMES_GLOW-T": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-T",
    "COSTUMES_GLOW-U": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-U",
    "COSTUMES_GLOW-V": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-V",
    "COSTUMES_GLOW-W": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-W",
    "COSTUMES_GLOW-X": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-X",
    "COSTUMES_GLOW-Y": "  ᠭᠢᠯᠪᠠᠭ᠎ᠠ-Y",
    "COSTUMES_GLOW-Z": "  ᠭᠢᠯᠪᠠᠭ᠎ᠠ  -Z",
    "COSTUMES_STORY-A-1": "ᠦᠯᠢᠭᠡᠷ-A-1",
    "COSTUMES_STORY-A-2": "ᠦᠯᠢᠭᠡᠷ-A-2",
    "COSTUMES_STORY-A-3": "ᠦᠯᠢᠭᠡᠷ-A-3",
    "COSTUMES_STORY-B-1": "ᠦᠯᠢᠭᠡᠷ-B-1",
    "COSTUMES_STORY-B-2": "ᠦᠯᠢᠭᠡᠷ-B-2",
    "COSTUMES_STORY-B-3": "ᠦᠯᠢᠭᠡᠷ-B-3",
    "COSTUMES_STORY-C-1": "ᠦᠯᠢᠭᠡᠷ-C-1",
    "COSTUMES_STORY-C-2": "ᠦᠯᠢᠭᠡᠷ-C-2",
    "COSTUMES_STORY-C-3": "ᠦᠯᠢᠭᠡᠷ-C-3",
    "COSTUMES_STORY-D-1": "ᠦᠯᠢᠭᠡᠷ-D-1",
    "COSTUMES_STORY-D-2": "ᠦᠯᠢᠭᠡᠷ-D-2",
    "COSTUMES_STORY-D-3": "ᠦᠯᠢᠭᠡᠷ-D-3",
    "COSTUMES_STORY-E-1": "ᠦᠯᠢᠭᠡᠷ-E-1",
    "COSTUMES_STORY-E-2": "ᠦᠯᠢᠭᠡᠷ-E-2",
    "COSTUMES_STORY-E-3": "ᠦᠯᠢᠭᠡᠷ-E-3",
    "COSTUMES_STORY-F-1": "ᠦᠯᠢᠭᠡᠷ-F-1",
    "COSTUMES_STORY-F-2": "ᠦᠯᠢᠭᠡᠷ-F-2",
    "COSTUMES_STORY-F-3": "ᠦᠯᠢᠭᠡᠷ-F-3",
    "COSTUMES_STORY-G-1": "ᠦᠯᠢᠭᠡᠷ-G-1",
    "COSTUMES_STORY-G-2": "ᠦᠯᠢᠭᠡᠷ-G-2",
    "COSTUMES_STORY-G-3": "ᠦᠯᠢᠭᠡᠷ-G-3",
    "COSTUMES_STORY-H-1": "ᠦᠯᠢᠭᠡᠷ-H-1",
    "COSTUMES_STORY-H-2": "ᠦᠯᠢᠭᠡᠷ-H-2",
    "COSTUMES_STORY-H-3": "ᠦᠯᠢᠭᠡᠷ-H-3",
    "COSTUMES_STORY-I-1": "ᠦᠯᠢᠭᠡᠷ-I-1",
    "COSTUMES_STORY-I-2": "ᠦᠯᠢᠭᠡᠷ-I-2",
    "COSTUMES_STORY-I-3": "ᠦᠯᠢᠭᠡᠷ-I-3",
    "COSTUMES_STORY-J-1": "ᠦᠯᠢᠭᠡᠷ-J-1",
    "COSTUMES_STORY-J-2": "ᠦᠯᠢᠭᠡᠷ-J-2",
    "COSTUMES_STORY-J-3": "ᠦᠯᠢᠭᠡᠷ-J-3",
    "COSTUMES_STORY-K-1": "ᠦᠯᠢᠭᠡᠷ-K-1",
    "COSTUMES_STORY-K-2": "ᠦᠯᠢᠭᠡᠷ-K-2",
    "COSTUMES_STORY-K-3": "ᠦᠯᠢᠭᠡᠷ-K-3",
    "COSTUMES_STORY-L-1": "ᠦᠯᠢᠭᠡᠷ-L-1",
    "COSTUMES_STORY-L-2": "ᠦᠯᠢᠭᠡᠷ-L-2",
    "COSTUMES_STORY-L-3": "ᠦᠯᠢᠭᠡᠷ-L-3",
    "COSTUMES_STORY-M-1": "ᠦᠯᠢᠭᠡᠷ-M-1",
    "COSTUMES_STORY-M-2": "ᠦᠯᠢᠭᠡᠷ-M-2",
    "COSTUMES_STORY-M-3": "ᠦᠯᠢᠭᠡᠷ-M-3",
    "COSTUMES_STORY-N-1": "ᠦᠯᠢᠭᠡᠷ-N-1",
    "COSTUMES_STORY-N-2": "ᠦᠯᠢᠭᠡᠷ-N-2",
    "COSTUMES_STORY-N-3": "ᠦᠯᠢᠭᠡᠷ-N-3",
    "COSTUMES_STORY-O-1": "ᠦᠯᠢᠭᠡᠷ-O-1",
    "COSTUMES_STORY-O-2": "ᠦᠯᠢᠭᠡᠷ-O-2",
    "COSTUMES_STORY-O-3": "ᠦᠯᠢᠭᠡᠷ-O-3",
    "COSTUMES_STORY-P-1": "ᠦᠯᠢᠭᠡᠷ-P-1",
    "COSTUMES_STORY-P-2": "ᠦᠯᠢᠭᠡᠷ-P-2",
    "COSTUMES_STORY-P-3": "ᠦᠯᠢᠭᠡᠷ-P-3",
    "COSTUMES_STORY-Q-1": "ᠦᠯᠢᠭᠡᠷ-Q-1",
    "COSTUMES_STORY-Q-2": "ᠦᠯᠢᠭᠡᠷ-Q-2",
    "COSTUMES_STORY-Q-3": "ᠦᠯᠢᠭᠡᠷ-Q-3",
    "COSTUMES_STORY-R-1": "ᠦᠯᠢᠭᠡᠷ-R-1",
    "COSTUMES_STORY-R-2": "ᠦᠯᠢᠭᠡᠷ-R-2",
    "COSTUMES_STORY-R-3": "ᠦᠯᠢᠭᠡᠷ-R-3",
    "COSTUMES_STORY-S-1": "ᠦᠯᠢᠭᠡᠷ-S-1",
    "COSTUMES_STORY-S-2": "ᠦᠯᠢᠭᠡᠷ-S-2",
    "COSTUMES_STORY-S-3": "ᠦᠯᠢᠭᠡᠷ-S-3",
    "COSTUMES_STORY-T-1": "ᠦᠯᠢᠭᠡᠷ-T-1",
    "COSTUMES_STORY-T-2": "ᠦᠯᠢᠭᠡᠷ-T-2",
    "COSTUMES_STORY-T-3": "ᠦᠯᠢᠭᠡᠷ-T-3",
    "COSTUMES_STORY-U-1": "ᠦᠯᠢᠭᠡᠷ-U-1",
    "COSTUMES_STORY-U-2": "ᠦᠯᠢᠭᠡᠷ-U-2",
    "COSTUMES_STORY-U-3": "ᠦᠯᠢᠭᠡᠷ-U-3",
    "COSTUMES_STORY-V-1": "ᠦᠯᠢᠭᠡᠷ-V-1",
    "COSTUMES_STORY-V-2": "ᠦᠯᠢᠭᠡᠷ-V-2",
    "COSTUMES_STORY-V-3": "ᠦᠯᠢᠭᠡᠷ-V-3",
    "COSTUMES_STORY-W-1": "ᠦᠯᠢᠭᠡᠷ-W-1",
    "COSTUMES_STORY-W-2": "ᠦᠯᠢᠭᠡᠷ-W-2",
    "COSTUMES_STORY-W-3": "ᠦᠯᠢᠭᠡᠷ-W-3",
    "COSTUMES_STORY-X-1": "ᠦᠯᠢᠭᠡᠷ-X-1",
    "COSTUMES_STORY-X-2": "ᠦᠯᠢᠭᠡᠷ-X-2",
    "COSTUMES_STORY-X-3": "ᠦᠯᠢᠭᠡᠷ-X-3",
    "COSTUMES_STORY-Y-1": "ᠦᠯᠢᠭᠡᠷ-Y-1",
    "COSTUMES_STORY-Y-2": "ᠦᠯᠢᠭᠡᠷ-Y-2",
    "COSTUMES_STORY-Y-3": "ᠦᠯᠢᠭᠡᠷ-Y-3",
    "COSTUMES_STORY-Z-1": "ᠦᠯᠢᠭᠡᠷ-Z-1",
    "COSTUMES_STORY-Z-2": "ᠦᠯᠢᠭᠡᠷ-Z-2",
    "COSTUMES_STORY-Z-3": "ᠦᠯᠢᠭᠡᠷ-Z-3",
    "COSTUMES_STEAMED-STUFFED-BUN": "包子",
    "COSTUMES_LUCKY-BAG": "福袋",
    "COSTUMES_DUMPLINGS": "饺子",
    "COSTUMES_GOLD-COINS": "金币",
    "COSTUMES_A-GIFT": "礼物-A",
    "COSTUMES_STEAMED-BREAD": "馒头",
    "COSTUMES_PLATES": "盘子",
    "COSTUMES_SILVER-INGOT": "元宝",
    "COSTUMES_FIRECRACKERS": "鞭炮",
    "COSTUMES_FIRECRACKERS-1": "鞭炮1",
    "COSTUMES_FIRECRACKERS-2": "鞭炮2",
    "COSTUMES_FIRECRACKERS-3": "鞭炮3",
    "COSTUMES_LANTERN": "灯笼",
    "COSTUMES_LANTERN-1": "灯笼1",
    "COSTUMES_LANTERN-2": "灯笼2",
    "COSTUMES_DRUM": "鼓-A",
    "COSTUMES_DRUM-1": "鼓1",
    "COSTUMES_DRUM-2": "鼓2",
    "COSTUMES_DRUM-3": "鼓3",
    "COSTUMES_RED-ENVELOPE": "红包",
    "COSTUMES_RED-ENVELOPE-1": "红包1",
    "COSTUMES_RED-ENVELOPE-2": "红包2",
    "COSTUMES_SCROLL": "卷轴",
    "COSTUMES_SCROLL-1": "卷轴1",
    "COSTUMES_SCROLL-2": "卷轴2",
    "COSTUMES_SCROLL-3": "卷轴3",
    "COSTUMES_YETI": "雪人-A",
    "COSTUMES_YETI-1": "雪人1",
    "COSTUMES_YETI-2": "雪人2",
    "COSTUMES_FIREWORKS": "烟花",
    "COSTUMES_FIREWORKS-1": "烟花1",
    "COSTUMES_FIREWORKS-2": "烟花2",
    "COSTUMES_FIREWORKS-3": "烟花3",
    "COSTUMES_FIREWORKS-4": "烟花4",
    "COSTUMES_FIREWORKS-5": "烟花5",
    "COSTUMES_FIREWORKS-6": "烟花6",
    "COSTUMES_FIREWORKS-7": "烟花7",
    "COSTUMES_FIREWORKS-8": "烟花8",
    "COSTUMES_FIREWORKS-9": "烟花9",
    "SOUNDS_A_BASS": " ᠪᠧᠰ A",
    "SOUNDS_A_ELEC_BASS": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠪᠧᠰ A",
    "SOUNDS_A_ELEC_GUITAR": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷ A",
    "SOUNDS_A_ELEC_PIANO": "  ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨ A",
    "SOUNDS_A_GUITAR": " ᠭᠢᠲ᠋ᠠᠷ A",
    "SOUNDS_A_MINOR_UKULELE": " ᠶᠤᠺᠷᠢ A",
    "SOUNDS_A_PIANO": "ᠲᠡᠭᠦᠯᠳᠡᠷ ᠬᠤᠭᠤᠷ A",
    "SOUNDS_A_SAX": " ᠰᠠᠺᠰ A",
    "SOUNDS_A_TROMBONE": " ᠲᠷᠤᠮᠪᠤᠨ A",
    "SOUNDS_A_TRUMPET": " ᠲᠷᠤᠢᠮᠫᠸ᠋ᠲ A",
    "SOUNDS_AFRO_STRING": " ᠠᠹᠷᠢᠺᠠ ᠲᠢᠪ ᠤᠨ ᠴᠢᠪᠬᠠᠳᠠᠰᠤ",
    "SOUNDS_ALERT": " ᠰᠡᠷᠡᠮᠵᠢ ᠶᠢᠨ ᠳᠣᠬᠢᠶ᠎ᠠ",
    "SOUNDS_ALIEN_CREAK1": "ᠵᠢᠷ ᠵᠠᠷ1",
    "SOUNDS_ALIEN_CREAK2": "ᠵᠢᠷ ᠵᠠᠷ2",
    "SOUNDS_B_BASS": " ᠪᠧᠰ B",
    "SOUNDS_B_ELEC_BASS": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠪᠧᠰ B",
    "SOUNDS_B_ELEC_GUITAR": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷ B",
    "SOUNDS_B_ELEC_PIANO": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨ B",
    "SOUNDS_B_GUITAR": " ᠭᠢᠲ᠋ᠠᠷ B",
    "SOUNDS_B_PIANO": " ᠲᠡᠭᠦᠯᠳᠡᠷ ᠬᠤᠭᠤᠷ B",
    "SOUNDS_B_SAX": " ᠰᠠᠺᠰ B",
    "SOUNDS_B_TROMBONE": " ᠲᠷᠤᠮᠪᠤᠨ B",
    "SOUNDS_B_TRUMPET": "ᠲᠷᠤᠢᠮᠫᠸ᠋ᠲ B",
    "SOUNDS_BAA": " ᠮᠠᠢ ᠮᠠᠢ",
    "SOUNDS_BARK": " ᠬᠤᠪ",
    "SOUNDS_BASKETBALL_BOUNCE": "Basketball Bounce",
    "SOUNDS_BASS_BEATBOX": " ᠠᠢᠵᠠᠮ ᠲᠠᠢ ᠠᠮᠠᠨ ᠡᠷᠳᠡᠮ- ᠨᠠᠮ ᠠᠶ᠎ᠠ",
    "SOUNDS_BEAT_BOX1": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠠᠢᠵᠠᠮᠲᠤ ᠬᠡᠩᠭᠡᠷᠭᠡ1",
    "SOUNDS_BEAT_BOX2": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠠᠢᠵᠠᠮᠲᠤ ᠬᠡᠩᠭᠡᠷᠭᠡ2",
    "SOUNDS_BELL_CYMBAL": " ᠴᠠᠩ",
    "SOUNDS_BELL_TOLL": " ᠬᠣᠩᠬᠣᠨ ᠤ ᠳᠠᠭᠤ",
    "SOUNDS_BIG_BOING": " ᠪᠥᠩ",
    "SOUNDS_BIRD": " ᠰᠢᠪᠠᠭᠤᠨ ᠤ ᠵᠢᠷᠭᠢᠬᠦ ᠳᠠᠭᠤ",
    "SOUNDS_BIRTHDAY": " ᠲᠥᠷᠥᠭᠰᠡᠨ ᠡᠳᠥᠷ ᠦᠨ ᠳᠠᠭᠤᠤ",
    "SOUNDS_BITE": " ᠬᠠᠵᠠᠬᠤ᠂ ᠵᠠᠭᠤᠬᠤ",
    "SOUNDS_BOING": " ᠪᠥᠩ",
    "SOUNDS_BONK": " ᠲᠣᠩᠰᠢᠭᠤᠷ",
    "SOUNDS_BOOM_CLOUD": " ᠲᠤᠸᠠᠩ",
    "SOUNDS_BOOP_BING_BOP": " ᠪᠥᠩ ᠪᠢᠩ ᠪᠦᠸ᠋ᠸ᠋",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": " ᠠᠶᠠᠭ᠎ᠠ ᠬᠠᠭᠠᠷᠠᠬᠤ",
    "SOUNDS_BUBBLES": " ᠣᠷᠭᠢᠯᠬᠤ",
    "SOUNDS_BUZZ_WHIR": " ᠸᠦᠩ ᠸᠦᠩ",
    "SOUNDS_C_BASS": " ᠪᠧᠰ C ",
    "SOUNDS_C_ELEC_BASS": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠪᠧᠰ C",
    "SOUNDS_C_ELEC_GUITAR": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷ C ",
    "SOUNDS_C_ELEC_PIANO": "  ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨ C ",
    "SOUNDS_C_GUITAR": " ᠭᠢᠲ᠋ᠠᠷ C ",
    "SOUNDS_C_MAJOR_UKULELE": "C  ᠶᠦᠺᠷᠢ",
    "SOUNDS_C_PIANO": " ᠲᠡᠭᠦᠯᠳᠡᠷ ᠬᠤᠭᠤᠷ C",
    "SOUNDS_C_SAX": " ᠰᠠᠺᠰ C",
    "SOUNDS_C_TROMBONE": " ᠲᠷᠤᠮᠪᠤᠨ C",
    "SOUNDS_C_TRUMPET": "ᠲᠷᠤᠢᠮᠫᠸ᠋ᠲ  C",
    "SOUNDS_C2_BASS": " ᠪᠧᠰ C2",
    "SOUNDS_C2_ELEC_BASS": "ᠴᠠᠬᠢᠯᠭᠠᠨ  ᠪᠧᠰ C2",
    "SOUNDS_C2_ELEC_GUITAR": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷ C2",
    "SOUNDS_C2_ELEC_PIANO": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨ C2",
    "SOUNDS_C2_GUITAR": "ᠭᠢᠲ᠋ᠠᠷ C2",
    "SOUNDS_C2_PIANO": "ᠲᠡᠭᠦᠯᠳᠡᠷ ᠬᠤᠭᠤᠷ C2",
    "SOUNDS_C2_SAX": " ᠰᠠᠺᠰ C2",
    "SOUNDS_C2_TROMBONE": "ᠲᠷᠤᠮᠪᠤᠨ  C2",
    "SOUNDS_C2_TRUMPET": "ᠲᠷᠤᠢᠮᠫᠸ᠋ᠲ C2",
    "SOUNDS_CAR_HORN": "ᠲᠡᠷᠭᠡ ᠪᠦᠷᠢᠶ᠎ᠡ ᠳᠤᠤᠭᠠᠷᠭᠠᠬᠤ",
    "SOUNDS_CAR_PASSING": "ᠲᠡᠷᠭᠡ ᠶᠠᠪᠤᠬᠤ",
    "SOUNDS_CAR_VROOM": " ᠮᠠᠰᠢᠨ ᠲᠡᠷᠭᠡ ᠬᠤᠷᠳᠤᠴᠠ ᠪᠠᠨ ᠨᠡᠮᠡᠬᠦ",
    "SOUNDS_CAVE": " ᠴᠥᠮᠥᠷᠡᠬᠦ",
    "SOUNDS_CHATTER": " ᠵᠢᠷ ᠵᠠᠷ",
    "SOUNDS_CHEE_CHEE": " ᠵᠢᠷ ᠵᠠᠷ",
    "SOUNDS_CHEER": " ᠪᠠᠶᠠᠷᠯᠠᠯᠳᠤᠨ ᠬᠠᠰᠭᠢᠷᠬᠤ",
    "SOUNDS_CHILL": "Chill",
    "SOUNDS_CHIRP": " ᠵᠢᠷ ᠵᠢᠷ",
    "SOUNDS_CHOMP": " ᠰᠢᠳᠦ ᠪᠡᠨ ᠵᠠᠭᠤᠬᠤ",
    "SOUNDS_CHORD": " ᠴᠢᠪᠬᠠᠳᠠᠰᠤ",
    "SOUNDS_CLANG": " ᠳ᠋ᠠᠩ",
    "SOUNDS_CLAP_BEATBOX": " ᠠᠢᠵᠠᠮ ᠲᠠᠢ ᠠᠮᠠᠨ ᠡᠷᠳᠡᠮ- ᠬᠡᠯᠡ ᠪᠡᠷ ᠬᠥᠭᠵᠢᠮᠳᠡᠬᠦ",
    "SOUNDS_CLAPPING": " ᠠᠯᠠᠭ᠎ᠠ ᠲᠠᠰᠢᠯᠲᠠ ᠶᠢᠨ ᠳᠠᠭᠤ",
    "SOUNDS_CLASSICAL_PIANO": "Classical Piano",
    "SOUNDS_CLOCK_TICKING": " ᠱᠠᠷ ᠱᠠᠷ",
    "SOUNDS_CLOWN_HONK": " ᠭᠠᠯᠠᠭᠤᠨ ᠤ ᠭᠠᠩᠭᠠᠨᠠᠬᠤ ᠳᠠᠭᠤ",
    "SOUNDS_COIN": " ᠰᠢᠪᠠᠭᠤᠨ ᠤ ᠵᠢᠷᠭᠢᠬᠦ ᠳᠠᠭᠤ",
    "SOUNDS_COLLECT": " ᠳ᠋ᠢᠩ",
    "SOUNDS_COMPUTER_BEEP": " ᠬᠤᠷ ᠬᠤᠷ",
    "SOUNDS_COMPUTER_BEEP2": "ᠺᠣᠮᠫᠢᠦ᠋ᠲ᠋ᠧᠷ ᠤᠨ ᠬᠤᠷ ᠬᠤᠷ ᠭᠡᠬᠦ ᠳᠠᠭᠤ2",
    "SOUNDS_CONNECT": " ᠰᠠᠨᠠᠭᠤᠯᠬᠤ ᠳᠠᠭᠤ ᠶᠢ ᠵᠠᠯᠭᠠᠬᠤ",
    "SOUNDS_COUGH1": "ᠬᠠᠨᠢᠶᠠᠬᠤ1",
    "SOUNDS_COUGH2": "ᠬᠠᠨᠢᠶᠠᠬᠤ2",
    "SOUNDS_CRANK": " ᠡᠷᠴᠢᠯᠡᠭᠦᠷ",
    "SOUNDS_CRASH_BEATBOX": "ᠠᠢᠵᠠᠮ ᠲᠠᠢ ᠠᠮᠠᠨ ᠡᠷᠳᠡᠮ-  ᠺᠸ᠋ᠽ",
    "SOUNDS_CRASH_CYMBAL": " ᠵᠢᠩᠭᠢᠨᠡᠭᠦᠷ",
    "SOUNDS_CRAZY_LAUGH": " ᠬᠠᠳᠠᠭᠠᠨᠠᠲᠠᠯ᠎ᠠ ᠢᠨᠢᠶᠡᠬᠦ",
    "SOUNDS_CRICKET": " ᠬᠦᠷᠡᠯᠵᠡᠭᠡᠨ᠎ᠡ",
    "SOUNDS_CRICKETS": " ᠰᠦᠷᠦᠭ ᠬᠦᠷᠡᠯᠵᠡᠭᠡᠨ᠎ᠡ",
    "SOUNDS_CROAK": " ᠨᠠᠮ ᠳᠠᠭᠤᠨ ᠢᠶᠠᠷ ᠴᠢᠷᠠᠯᠠᠬᠤ",
    "SOUNDS_CROWD_GASP": " ᠠᠮᠢᠰᠬᠤᠭᠠᠳᠠᠬᠤ ᠳᠠᠭᠤ",
    "SOUNDS_CROWD_LAUGH": " ᠬᠠᠳᠠᠭᠠᠨᠠᠲᠠᠯ᠎ᠠ ᠢᠨᠢᠶᠡᠬᠦ",
    "SOUNDS_CRUNCH": " ᠵᠢᠷ ᠵᠢᠷ ᠭᠡᠵᠦ ᠳᠤᠤᠭᠠᠷᠬᠤ",
    "SOUNDS_CYMBAL": " ᠴᠠᠩ",
    "SOUNDS_CYMBAL_CRASH": " ᠵᠢᠩᠭᠢᠨᠡᠭᠦᠷ",
    "SOUNDS_CYMBAL_ECHO": "ᠬᠦᠩᠭᠢᠨᠡᠭᠦᠷ4",
    "SOUNDS_D_BASS": " ᠪᠧᠰ D ",
    "SOUNDS_D_ELEC_BASS": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠪᠧᠰ D",
    "SOUNDS_D_ELEC_GUITAR": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷD",
    "SOUNDS_D_ELEC_PIANO": "  ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨD",
    "SOUNDS_D_GUITAR": " ᠭᠢᠲ᠋ᠠᠷ D",
    "SOUNDS_D_PIANO": " ᠲᠡᠭᠦᠯᠳᠡᠷ ᠬᠤᠭᠤᠷD",
    "SOUNDS_D_SAX": " ᠰᠠᠺᠰD",
    "SOUNDS_D_TROMBONE": "ᠲᠷᠤᠮᠪᠤᠨ  D",
    "SOUNDS_D_TRUMPET": "ᠲᠷᠤᠢᠮᠫᠸ᠋ᠲ D",
    "SOUNDS_DANCE_AROUND": " ᠡᠷᠬᠡ ᠴᠢᠯᠦᠭᠡ",
    "SOUNDS_DANCE_CELEBRATE": " ᠪᠠᠶᠠᠷ ᠬᠦᠷᠭᠡᠬᠦ",
    "SOUNDS_DANCE_CELEBRATE2": "dance celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": " ᠰᠡᠯᠡᠭᠦᠦ",
    "SOUNDS_DANCE_ENERGETIC": "Dance Energetic",
    "SOUNDS_DANCE_FUNKY": " ᠹᠠᠩᠺ  ᠠᠢᠵᠢᠮ",
    "SOUNDS_DANCE_HEAD_NOD": " ᠠᠢᠵᠠᠮ ᠴᠣᠬᠢᠯᠭ᠎ᠠ",
    "SOUNDS_DANCE_MAGIC": " ᠰᠢᠳᠢᠲᠦ ᠬᠦᠴᠦᠨ",
    "SOUNDS_DANCE_SITAR": "Dance Sitar",
    "SOUNDS_DANCE_SLOW_MO": " ᠠᠯᠭᠤᠷ ᠠᠭᠠᠵᠢᠮ",
    "SOUNDS_DANCE_SNARE_BEAT": " ᠬᠡᠩᠭᠡᠷᠭᠡ ᠶᠢᠨ ᠠᠢᠵᠠᠮ ᠴᠣᠬᠢᠯᠭ᠎ᠠ",
    "SOUNDS_DANCE_SPACE": " ᠣᠷᠣᠨ ᠵᠠᠢ",
    "SOUNDS_DISCONNECT": " ᠵᠠᠯᠭᠠᠯᠲᠠ ᠳᠠᠰᠤᠷᠠᠭᠰᠠᠨ  ᠰᠡᠷᠡᠮᠵᠢ",
    "SOUNDS_DOG1": "ᠬᠣᠪ ᠬᠤᠪ1",
    "SOUNDS_DOG2": "ᠬᠤᠪ2",
    "SOUNDS_DOOR_CLOSING": " ᠡᠭᠦᠳᠡ ᠬᠠᠭᠠᠬᠤ",
    "SOUNDS_DOOR_CREAK": " ᠡᠭᠦᠳᠡᠨ ᠦ ᠵᠢᠷ ᠵᠢᠷ ᠭᠡᠬᠦ ᠳᠠᠭᠤ",
    "SOUNDS_DOORBELL": " ᠡᠭᠦᠳᠡᠨ ᠬᠣᠩᠬᠣᠨ ᠤ ᠳᠠᠭᠤ",
    "SOUNDS_DRIP_DROP": " ᠤᠷᠤᠰᠤᠨ ᠤᠨᠠᠬᠤ ᠳᠠᠭᠤ",
    "SOUNDS_DRIVE_AROUND": "ᠬᠦᠩᠭᠢᠨᠡᠭᠦᠷ1",
    "SOUNDS_DRUM": " ᠬᠡᠩᠭᠡᠷᠭᠡ",
    "SOUNDS_DRUM_BASS1": "ᠨᠠᠮ ᠳᠠᠭᠤᠲᠤ ᠬᠡᠩᠭᠡᠷᠭᠡ1",
    "SOUNDS_DRUM_BASS2": "ᠨᠠᠮ ᠳᠠᠭᠤᠲᠤ ᠬᠡᠩᠭᠡᠷᠭᠡ2",
    "SOUNDS_DRUM_BASS3": "ᠨᠠᠮ ᠳᠠᠭᠤᠲᠤ ᠬᠡᠩᠭᠡᠷᠭᠡ3",
    "SOUNDS_DRUM_BOING": " ᠬᠡᠩᠭᠡᠷᠭᠡ- ᠪᠢᠩ",
    "SOUNDS_DRUM_BUZZ": "ᠬᠡᠩᠭᠡᠷᠭᠡ- ᠸᠥᠩ",
    "SOUNDS_DRUM_FUNKY": "ᠬᠦᠩᠭᠢᠨᠡᠭᠦᠷ 7",
    "SOUNDS_DRUM_JAM": "ᠬᠦᠩᠭᠢᠨᠡᠭᠦᠷ 3",
    "SOUNDS_DRUM_MACHINE": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠬᠡᠩᠭᠡᠷᠭᠡ",
    "SOUNDS_DRUM_ROLL": " ᠬᠡᠩᠭᠡᠷᠭᠡ ᠳᠡᠯᠡᠳᠬᠦ ᠳᠠᠭᠤᠨ",
    "SOUNDS_DRUM_SATELLITE": "ᠬᠦᠩᠭᠢᠨᠡᠭᠦᠷ 5",
    "SOUNDS_DRUM_SET1": "ᠬᠡᠩᠭᠡᠷᠭᠡ1",
    "SOUNDS_DRUM_SET2": "ᠬᠡᠩᠭᠡᠷᠭᠡ2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": " ᠭᠠ ᠭᠠ",
    "SOUNDS_DUN_DUN_DUNNN": " ᠳ᠋ᠦᠩ ᠳ᠋ᠦᠩ ᠳ᠋ᠠᠩ",
    "SOUNDS_E_BASS": " ᠪᠧᠰ E",
    "SOUNDS_E_ELEC_BASS": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠪᠧᠰ E",
    "SOUNDS_E_ELEC_GUITAR": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷE",
    "SOUNDS_E_ELEC_PIANO": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨE",
    "SOUNDS_E_GUITAR": " ᠭᠢᠲ᠋ᠠᠷE",
    "SOUNDS_E_PIANO": " ᠲᠡᠭᠦᠯᠳᠡᠷ ᠬᠤᠭᠤᠷE",
    "SOUNDS_E_SAX": " ᠰᠸ᠋ᠺᠰE",
    "SOUNDS_E_TROMBONE": "ᠲᠷᠤᠮᠪᠤᠨ E",
    "SOUNDS_E_TRUMPET": "ᠲᠷᠤᠢᠮᠫᠸ᠋ᠲ E",
    "SOUNDS_EGGS": " ᠥᠨᠳᠡᠭᠡ",
    "SOUNDS_ELEC_PIANO_A_MINOR": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨ A ᠮᠢᠨᠤᠷ  ᠬᠥᠭ",
    "SOUNDS_ELEC_PIANO_C_MAJOR": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨ C ᠮᠢᠨᠤᠷ  ᠬᠥᠭ",
    "SOUNDS_ELEC_PIANO_F_MAJOR": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨ F ᠮᠢᠨᠤᠷ  ᠬᠥᠭ",
    "SOUNDS_ELEC_PIANO_G_MAJOR": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨ G ᠮᠢᠨᠤᠷ  ᠬᠥᠭ",
    "SOUNDS_ELEC_PIANO_LOOP": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨ Loop",
    "SOUNDS_EMOTIONAL_PIANO": "Emotional Piano",
    "SOUNDS_ENGINE": " ᠬᠥᠳᠡᠯᠭᠡᠭᠦᠷ",
    "SOUNDS_F_BASS": " ᠪᠧᠰ F ",
    "SOUNDS_F_ELEC_BASS": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨF ",
    "SOUNDS_F_ELEC_GUITAR": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷF ",
    "SOUNDS_F_ELEC_PIANO": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨF",
    "SOUNDS_F_GUITAR": " ᠭᠢᠲ᠋ᠠᠷF",
    "SOUNDS_F_MAJOR_UKULELE": "F  ᠶᠦᠺᠷᠢ",
    "SOUNDS_F_PIANO": " ᠲᠡᠭᠦᠯᠳᠡᠷ ᠬᠤᠭᠤᠷF",
    "SOUNDS_F_SAX": " ᠰᠠᠺᠰF",
    "SOUNDS_F_TROMBONE": "ᠲᠷᠤᠮᠪᠤᠨF",
    "SOUNDS_F_TRUMPET": "ᠲᠷᠤᠢᠮᠫᠸ᠋ᠲ F",
    "SOUNDS_FAIRYDUST": " ᠠᠨᠠᠬᠠᠢ ᠶᠢᠨ ᠥᠷᠥᠪᠳᠡᠰᠦ",
    "SOUNDS_FINGER_SNAP": " ᠬᠤᠷᠤᠭᠤ ᠢᠨᠴᠡᠳᠡᠬᠦ",
    "SOUNDS_FLAM_SNARE": " ᠳᠦᠰᠢᠭ ᠠᠶᠠ",
    "SOUNDS_FOOTSTEPS": "Footsteps",
    "SOUNDS_G_BASS": " ᠪᠧᠰ G",
    "SOUNDS_G_ELEC_BASS": " ᠴᠠᠬᠢᠯᠭᠠᠨ  ᠪᠧᠰ G",
    "SOUNDS_G_ELEC_GUITAR": " ᠴᠠᠬᠢᠯᠭᠠᠨ  ᠭᠢᠲ᠋ᠠᠷG",
    "SOUNDS_G_ELEC_PIANO": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠤᠷᠭᠠᠨ G",
    "SOUNDS_G_GUITAR": " ᠭᠢᠲ᠋ᠠᠷ G",
    "SOUNDS_G_PIANO": " ᠲᠡᠭᠦᠯᠳᠡᠷ ᠬᠤᠭᠤᠷG",
    "SOUNDS_G_SAX": " ᠰᠠᠺᠰG",
    "SOUNDS_G_TROMBONE": " ᠲᠷᠤᠮᠪᠤᠨ G",
    "SOUNDS_G_TRUMPET": "ᠲᠷᠤᠢᠮᠫᠸ᠋ᠲ G",
    "SOUNDS_G_UKULELE": "G  ᠶᠦᠺᠷᠢ",
    "SOUNDS_GALLOP": " ᠮᠣᠷᠢᠨ ᠲᠤᠭᠤᠷᠠᠢ ᠶᠢᠨ ᠳᠠᠭᠤᠨ",
    "SOUNDS_GARDEN": " ᠴᠡᠴᠡᠷᠯᠢᠭ",
    "SOUNDS_GLASS_BREAKING": " ᠰᠢᠯ ᠢ ᠬᠠᠭᠠᠯᠬᠤ",
    "SOUNDS_GLUG": " ᠭᠦᠳ ᠭᠦᠳ",
    "SOUNDS_GOAL_CHEER": " ᠶᠡᠬᠡ ᠳᠠᠭᠤᠨ ᠢᠶᠠᠷ ᠪᠠᠶᠠᠷᠯᠠᠯᠳᠤᠬᠤ",
    "SOUNDS_GONG": " ᠬᠠᠷᠠᠩᠭ᠎ᠠ ᠴᠣᠬᠢᠬᠤ",
    "SOUNDS_GOOSE": " ᠭᠠᠯᠠᠭᠤ ᠳᠤᠤᠭᠠᠷᠬᠤ",
    "SOUNDS_GROWL": " ᠣᠷᠢᠯᠠᠬᠤ",
    "SOUNDS_GRUNT": " ᠬᠤᠷᠬᠢᠷᠠᠬᠤ ᠳᠠᠭᠤ",
    "SOUNDS_GUITAR_CHORDS1": "ᠭᠢᠲ᠋ᠠᠷ ᠪᠠ ᠴᠢᠪᠬᠠᠳᠠᠰᠤ1",
    "SOUNDS_GUITAR_CHORDS2": "ᠭᠢᠲ᠋ᠠᠷ ᠪᠠ ᠴᠢᠪᠬᠠᠳᠠᠰᠤ2",
    "SOUNDS_GUITAR_STRUM": " ᠭᠢᠲ᠋ᠠᠷ ᠢᠶᠠᠷ ᠬᠥᠭᠵᠢᠮᠳᠡᠬᠦ",
    "SOUNDS_HAND_CLAP": "ᠠᠯᠠᠭ᠎ᠠ ᠲᠠᠰᠢᠯᠲᠠ",
    "SOUNDS_HEAD_SHAKE": " ᠲᠣᠯᠣᠭᠠᠢ ᠰᠡᠭᠰᠦᠷᠬᠦ",
    "SOUNDS_HEY": " ᠬᠤᠷᠤᠭᠤ ᠢᠨᠴᠡᠳᠡᠬᠦ",
    "SOUNDS_HI_BEATBOX": "ᠠᠢᠵᠠᠮ ᠲᠠᠢ ᠠᠮᠠᠨ ᠡᠷᠳᠡᠮ- ᠥᠨᠳᠥᠷ",
    "SOUNDS_HI_NA_TABLA": "ᠲᠠᠪᠦᠯᠠ ᠬᠡᠩᠭᠡᠷᠭᠡ Na",
    "SOUNDS_HI_TUN_TABLA": "ᠲᠠᠪᠦᠯᠠ ᠬᠡᠩᠭᠡᠷᠭᠡ Tun",
    "SOUNDS_HIGH_CONGA": "᠋᠍ ᠺᠣᠩᠭᠠ",
    "SOUNDS_HIGH_HAT": " ᠥᠨᠳᠥᠷ ᠴᠠᠩ",
    "SOUNDS_HIGH_TOM": " ᠬᠡᠩᠭᠡᠷᠭᠡ ᠥᠨᠳᠥᠷ",
    "SOUNDS_HIGH_WHOOSH": " ᠱᠠᠷᠵᠢᠭᠢᠨᠠᠬᠤ- ᠥᠨᠳᠦᠷ",
    "SOUNDS_HIHAT_BEATBOX": " ᠴᠠᠬᠢᠯᠭᠠᠨ ᠬᠢᠰᠬᠢᠬᠦ ᠴᠠᠩ",
    "SOUNDS_HIHAT_CYMBAL": " ᠬᠢᠰᠭᠢᠬᠦ ᠴᠠᠩ",
    "SOUNDS_HIP_HOP": " ᠬᠢᠫ ᠬᠣᠫ",
    "SOUNDS_HORSE": "  ᠮᠣᠷᠢ",
    "SOUNDS_HORSE_GALLOP": " ᠲᠤᠭᠤᠷᠠᠢ ᠶᠢᠨ ᠳᠠᠭᠤ",
    "SOUNDS_HUMAN_BEATBOX1": "ᠠᠢᠵᠠᠮ ᠲᠠᠢ ᠠᠮᠠᠨ ᠡᠷᠳᠡᠮ1",
    "SOUNDS_HUMAN_BEATBOX2": "ᠠᠢᠵᠠᠮ ᠲᠠᠢ ᠠᠮᠠᠨ ᠡᠷᠳᠡᠮ2",
    "SOUNDS_JUMP": " ᠦᠰᠦᠷᠬᠦ",
    "SOUNDS_JUNGLE": " ᠤᠢ",
    "SOUNDS_JUNGLE_FROGS": " ᠤᠢ  ᠶᠢᠨ ᠮᠡᠯᠡᠬᠡᠢ",
    "SOUNDS_KICK_BACK": " ᠬᠥᠩᠭᠢᠨᠠᠭᠦᠷ 6",
    "SOUNDS_KICK_DRUM": " ᠬᠥᠯ ᠬᠡᠩᠭᠡᠷᠭᠡ",
    "SOUNDS_LARGE_COWBELL": " ᠥᠬᠡᠷ ᠬᠣᠩᠬᠤ",
    "SOUNDS_LASER1": " ᠯᠠᠢᠰᠧᠷ1",
    "SOUNDS_LASER2": " ᠯᠠᠢᠰᠧᠷ1",
    "SOUNDS_LAUGH1": " ᠢᠨᠢᠶᠡᠳᠦ1",
    "SOUNDS_LAUGH2": " ᠢᠨᠢᠶᠡᠳᠦ2",
    "SOUNDS_LAUGH3": " ᠢᠨᠢᠶᠡᠳᠦ3",
    "SOUNDS_LO_GEH_TABLA": "ᠲᠠᠪᠤᠯᠠ  ᠬᠡᠩᠭᠡᠷᠭᠡ Geh  ᠳᠣᠣᠷᠠ",
    "SOUNDS_LO_GLISS_TABLA": "ᠲᠠᠪᠤᠯᠠ  ᠬᠡᠩᠭᠡᠷᠭᠡ Gliss ᠳᠣᠣᠷᠠ",
    "SOUNDS_LOSE": " ᠢᠯᠠᠭᠳᠠᠪᠠ",
    "SOUNDS_LOW_BOING": "Low Boing",
    "SOUNDS_LOW_CONGA": " ᠺᠣᠩᠭᠠ  ᠬᠡᠩᠭᠡᠷᠭᠡ-  ᠳᠣᠣᠷᠠ",
    "SOUNDS_LOW_SQUEAK": "  ᠵᠢᠷᠵᠢᠭᠢᠨᠡᠬᠦ - ᠳᠣᠣᠷᠠ",
    "SOUNDS_LOW_TOM": " ᠬᠡᠩᠭᠡᠷᠭᠡ-ᠳᠣᠣᠷᠠ",
    "SOUNDS_LOW_WHOOSH": " ᠱᠠᠷᠵᠢᠭᠢᠨᠠᠬᠤ- ᠳᠣᠣᠷᠠ",
    "SOUNDS_MACHINE": " ᠮᠠᠰᠢᠨ",
    "SOUNDS_MAGIC_SPELL": " ᠲᠠᠷᠨᠢ",
    "SOUNDS_MEDIEVAL1": " ᠳᠤᠮᠳᠠᠳᠤ ᠵᠠᠭᠤᠨ1",
    "SOUNDS_MEDIEVAL2": " ᠳᠤᠮᠳᠠᠳᠤ ᠵᠠᠭᠤᠨ2",
    "SOUNDS_MEOW": " ᠮᠢᠶᠣᠣ",
    "SOUNDS_MEOW2": " ᠮᠢᠶᠣᠣ 2",
    "SOUNDS_MOO": " ᠮᠥᠭᠡ",
    "SOUNDS_MOTORCYCLE_PASSING": " ᠮᠣᠲ᠋ᠣᠷ ᠥᠩᠭᠡᠷᠡᠬᠦ",
    "SOUNDS_MOVIE_1": "Movie 1",
    "SOUNDS_MOVIE_2": "Movie 2",
    "SOUNDS_MUTED_CONGA": " ᠺᠣᠩᠭᠠ  ᠬᠡᠩᠭᠡᠷᠭᠡ- ᠵᠥᠭᠡᠯᠡᠨ",
    "SOUNDS_MYSTERY": "Mystery",
    "SOUNDS_OCEAN_WAVE": " ᠳᠠᠪᠠᠯᠭᠠᠨ",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": " ᠡᠪᠡᠢ",
    "SOUNDS_ORCHESTRA_TUNING": "  ᠤᠷᠠᠺᠧᠰᠲ᠋ᠷᠠ",
    "SOUNDS_OWL": " ᠤᠭᠤᠯᠢ",
    "SOUNDS_PARTY_NOISE": " ᠴᠤᠭᠯᠠᠭᠠᠨ ᠤ ᠳᠡᠪᠵᠢᠶᠡᠨ",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": " ᠫᠢᠩ ᠫᠠᠩ ᠪᠥᠮᠪᠦᠭᠡ",
    "SOUNDS_PLUCK": " ᠴᠢᠩᠭᠠᠭᠠᠬᠤ",
    "SOUNDS_PLUNGE": " ᠤᠰᠤ ᠬᠠᠷᠠᠢᠬᠤ",
    "SOUNDS_POLICE_SIREN": " ᠴᠠᠭᠳᠠᠭᠠᠨ  ᠤ ᠬᠣᠩᠬᠤ",
    "SOUNDS_POP": "pop",
    "SOUNDS_RAIN": " ᠪᠣᠷᠤᠭᠠᠨ",
    "SOUNDS_RATTLE": "Rattle",
    "SOUNDS_REFEREE_WHISTLE": " ᠬᠢᠨᠠᠭᠴᠢ  ᠶᠢᠨ ᠪᠢᠰᠭᠢᠭᠦᠷ",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": " ᠦᠰᠦᠷᠭᠡᠬᠦ",
    "SOUNDS_RIDE_CYMBAL": " ᠡᠯᠭᠦᠭᠦᠷ ᠴᠠᠩ",
    "SOUNDS_RING_TONE": " ᠤᠲᠠᠰᠤᠨ  ᠤ ᠬᠣᠩᠬᠤ",
    "SOUNDS_RIP": " ᠪᠦᠰ ᠤᠷᠠᠬᠤ",
    "SOUNDS_RIPPLES": " ᠠᠷ",
    "SOUNDS_ROLL_CYMBAL": " ᠬᠡᠪᠰᠢᠭᠦᠯᠭᠡᠨ ᠴᠠᠩ",
    "SOUNDS_ROOSTER": " ᠲᠠᠬᠢᠶᠠᠨ ᠳᠠᠭᠤ",
    "SOUNDS_SCRAMBLING_FEET": " ᠶᠠᠭᠠᠷᠠᠤ ᠠᠯᠬᠤᠭᠠ",
    "SOUNDS_SCRATCH_BEATBOX": " ᠠᠢᠵᠠᠮ ᠲᠠᠢ ᠠᠮᠠᠨ ᠡᠷᠳᠡᠮ- ᠬᠡᠯᠡ ᠱᠦᠷᠭᠦᠭᠡᠬᠦ",
    "SOUNDS_SCRATCHY_BEAT": " ᠬᠥᠩᠭᠢᠨᠠᠭᠦᠷ 2",
    "SOUNDS_SCREAM1": " ᠪᠠᠷᠬᠢᠷᠠᠭᠠ1",
    "SOUNDS_SCREAM2": " ᠪᠠᠷᠬᠢᠷᠠᠭᠠ2",
    "SOUNDS_SCREECH": " ᠣᠷᠢᠯᠠᠬᠤ",
    "SOUNDS_SEAGULLS": " ᠴᠠᠬᠤᠯᠠᠢ",
    "SOUNDS_SEWING_MACHINE": " ᠣᠶᠤᠳᠠᠯ  ᠤᠨ ᠮᠠᠰᠢᠨ",
    "SOUNDS_SHAKER": " ᠬᠡᠯᠪᠡᠯᠵᠡᠭᠦᠷ",
    "SOUNDS_SHIP_BELL": " ᠣᠩᠭᠣᠴᠠᠨ  ᠤ ᠬᠣᠩᠬᠤ",
    "SOUNDS_SIDESTICK_SNARE": " ᠪᠠᠭᠠ ᠬᠡᠩᠭᠡᠷᠭᠡ  ᠶᠢᠨ ᠬᠦᠪᠡᠭᠡ",
    "SOUNDS_SINGER1": " ᠳᠠᠭᠤᠴᠢᠨ1",
    "SOUNDS_SINGER2": " ᠳᠠᠭᠤᠴᠢᠨ2",
    "SOUNDS_SIREN_WHISTLE": " ᠰᠡᠷᠡᠮᠵᠢ  ᠶᠢᠨ ᠳᠠᠭᠤ",
    "SOUNDS_SKID": " ᠭᠤᠯᠭᠤᠷᠢᠳᠬᠤ",
    "SOUNDS_SLIDE_WHISTLE": " ᠪᠢᠰᠭᠢᠭᠦᠷ",
    "SOUNDS_SMALL_COWBELL": " ᠲᠤᠭᠤᠯ ᠤᠨ ᠬᠣᠩᠬᠤ",
    "SOUNDS_SNAP": " ᠫᠢᠵᠢᠭᠢᠨᠠᠬᠤ",
    "SOUNDS_SNARE_BEATBOX": " ᠠᠢᠵᠠᠮ ᠲᠠᠢ ᠠᠮᠠᠨ ᠡᠷᠳᠡᠮ-   ᠪᠠᠭᠠ ᠬᠡᠩᠭᠡᠷᠭᠡ 1",
    "SOUNDS_SNARE_BEATBOX2": " ᠠᠢᠵᠠᠮ ᠲᠠᠢ ᠠᠮᠠᠨ ᠡᠷᠳᠡᠮ-   ᠪᠠᠭᠠ ᠬᠡᠩᠭᠡᠷᠭᠡ 2",
    "SOUNDS_SNARE_DRUM": " ᠪᠠᠭᠠ ᠬᠡᠩᠭᠡᠷᠭᠡ",
    "SOUNDS_SNEAKER_SQUEAK": " ᠱᠠᠬᠠᠢ  ᠶᠢᠨ ᠵᠢᠷᠵᠢᠭᠢᠨᠡᠬᠦ ᠳᠠᠭᠤ",
    "SOUNDS_SNEEZE1": " ᠨᠠᠢᠲᠠᠭᠠᠬᠤ1",
    "SOUNDS_SNEEZE2": " ᠨᠠᠢᠲᠠᠭᠠᠬᠤ2",
    "SOUNDS_SNORING": " ᠬᠣᠷᠬᠢᠷᠠᠬᠤ",
    "SOUNDS_SNORT": " ᠠᠭᠤᠷ  ᠤᠨ ᠳᠠᠭᠤ",
    "SOUNDS_SPACE_AMBIENCE": " ᠳᠠᠭᠤᠨ  ᠤ ᠦᠢᠯᠡᠳᠦᠯ",
    "SOUNDS_SPACE_FLYBY": " ᠳᠣᠣᠷᠠ ᠨᠢᠰᠦᠯᠲᠡ",
    "SOUNDS_SPACE_NOISE": " ᠬᠡᠢ ᠶᠢᠨ ᠤᠷᠤᠰᠭᠠᠯ  ᠤᠨ ᠳᠠᠭᠤᠨ",
    "SOUNDS_SPACE_RIPPLE": " ᠤᠷᠤᠨ ᠵᠠᠢ  ᠶᠢᠨ ᠢᠷᠠᠯᠵᠢ",
    "SOUNDS_SPIRAL": " ᠣᠢᠯ",
    "SOUNDS_SPLASH": " ᠤᠰᠤ ᠦᠰᠦᠷᠬᠦ",
    "SOUNDS_SPLASH_CYMBAL": " ᠤᠰᠤᠨ ᠴᠠᠩ",
    "SOUNDS_SPOOKY_STRING": " ᠠᠶᠤᠳᠠᠰᠤ ᠲᠠᠢ ᠴᠢᠪᠬᠠᠳᠠᠰᠤ  ᠶᠢᠨ ᠳᠠᠭᠤ",
    "SOUNDS_SQUAWK": " ᠭᠤᠸᠠᠭᠯᠠᠬᠤ",
    "SOUNDS_SQUEAKS": " ᠣᠬᠤᠷ ᠵᠢᠷᠵᠢᠭᠢᠨᠡᠬᠦ",
    "SOUNDS_SQUEAKY_TOY": "  ᠬᠡᠦᠬᠡᠯᠳᠡᠢ",
    "SOUNDS_SQUISH_POP": "Squish Pop",
    "SOUNDS_STRING_ACCENT": " ᠴᠢᠪᠬᠠᠳᠠᠰᠤᠨ  ᠬᠥᠭᠵᠢᠮ  ᠦᠨ ᠡᠷᠭᠦᠯᠲᠡ",
    "SOUNDS_STRING_PLUCK": " ᠴᠢᠪᠬᠠᠳᠠᠰᠤᠨ  ᠬᠥᠭᠵᠢᠮ",
    "SOUNDS_SUCTION_CUP": " ᠰᠣᠷᠤᠭᠤᠯ",
    "SOUNDS_SUSPENSE": " ᠰᠡᠵᠢᠭᠯᠡᠬᠦ",
    "SOUNDS_TADA": "ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠬᠦ ",
    "SOUNDS_TAMBURA": " ᠲᠠᠮᠪᠤᠷᠠ",
    "SOUNDS_TAP_CONGA": " ᠺᠣᠩᠭᠠ  ᠬᠥᠭᠵᠢᠮᠳᠡᠬᠦ",
    "SOUNDS_TAP_SNARE": "tap snare",
    "SOUNDS_TECHNO": " ᠲᠧᠭᠨᠢᠭ  ᠦᠨ ᠳᠠᠭᠤ",
    "SOUNDS_TECHNO2": " ᠲᠧᠭᠨᠢᠭ  ᠦᠨ ᠳᠠᠭᠤ 2",
    "SOUNDS_TELEPHONE_RING": "ᠭᠠᠷ ᠤᠲᠠᠰᠤᠨ ᠤ ᠬᠣᠩᠬᠣᠨ ᠳᠠᠭᠤ",
    "SOUNDS_TELEPHONE_RING2": "ᠭᠠᠷ ᠤᠲᠠᠰᠤᠨ ᠤ ᠬᠣᠩᠬᠣᠨ ᠳᠠᠭᠤ2",
    "SOUNDS_TELEPORT": " ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ",
    "SOUNDS_TELEPORT2": "ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ2",
    "SOUNDS_TELEPORT3": "ᠳᠠᠮᠵᠢᠭᠤᠯᠭ᠎ᠠ3",
    "SOUNDS_TENNIS_HIT": " ᠲᠣᠣᠷ ᠪᠥᠮᠪᠦᠭᠡᠨ ᠦ ᠳᠠᠭᠤ",
    "SOUNDS_THUNDER_STORM": " ᠠᠶᠤᠩᠭᠠᠲᠤ ᠪᠣᠷᠣᠭᠠᠨ",
    "SOUNDS_TOM_DRUM": " ᠲᠣᠮᠤ ᠬᠡᠩᠭᠡᠷᠭᠡ",
    "SOUNDS_TOY_HONK": " ᠲᠣᠭᠯᠠᠭᠠᠮ ᠮᠠᠰᠢᠨ ᠤ ᠪᠦᠷᠢᠶ᠎ᠡ ᠳᠤᠤᠭᠠᠷᠬᠤ",
    "SOUNDS_TOY_ZING": " ᠲᠣᠭᠯᠠᠭᠠᠮ ᠮᠠᠰᠢᠨ ᠤ ᠬᠦᠷᠳᠦ ᠶᠢ ᠡᠷᠭᠢᠯᠳᠦᠭᠦᠯᠬᠦ",
    "SOUNDS_TRAFFIC": " ᠲᠡᠷᠭᠡᠨ ᠦ ᠤᠷᠤᠰᠬᠠᠯ",
    "SOUNDS_TRAIN_WHISTLE": " ᠭᠠᠯᠲᠤ ᠲᠡᠷᠭᠡᠨ ᠤ ᠪᠦᠷᠢᠶ᠎ᠡ ᠳᠤᠤᠭᠠᠷᠬᠤ",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_TRIUMPH": " ᠠᠮᠵᠢᠯᠲᠠ ᠳᠤ ᠬᠦᠷᠬᠦ",
    "SOUNDS_TROPICAL_BIRDS": " ᠬᠠᠯᠠᠭᠤᠨ ᠪᠦᠰᠡᠨ  ᠦ ᠰᠢᠪᠠᠭᠤ",
    "SOUNDS_TRUMPET1": "ᠪᠦᠷᠢᠶ᠎ᠡ1",
    "SOUNDS_TRUMPET2": "ᠪᠦᠷᠢᠶ᠎ᠡ2",
    "SOUNDS_VIDEO_GAME_1": "Video Game 1",
    "SOUNDS_VIDEO_GAME_2": "Video Game 2",
    "SOUNDS_WAH_BEATBOX": " ᠠᠢᠵᠠᠮ ᠲᠠᠢ ᠠᠮᠠᠨ ᠡᠷᠳᠡᠮ-  ᠸᠠ",
    "SOUNDS_WAND": "  ᠰᠢᠳᠠᠮ",
    "SOUNDS_WATER_DROP": " ᠤᠰᠤᠨ ᠲᠤᠰᠤᠯ",
    "SOUNDS_WHINNY": " ᠦᠦᠷᠰᠡᠭᠡ",
    "SOUNDS_WHISTLE_THUMP": " ᠮᠥᠷᠭᠦᠬᠦ",
    "SOUNDS_WHIZ": " ᠰᠡᠷᠴᠢᠭᠢᠨᠡᠬᠦ ᠳᠠᠭᠤ",
    "SOUNDS_WHOOP": " ᠬᠢᠷᠬᠢᠷᠡᠬᠦ ᠳᠠᠭᠤ",
    "SOUNDS_WIN": " ᠢᠯᠠᠯᠲᠠ",
    "SOUNDS_WOBBLE": " ᠰᠠᠵᠢᠯᠠᠬᠤ",
    "SOUNDS_WOLF_HOWL": " ᠤᠯᠢᠬᠤ",
    "SOUNDS_WOOD_TAP": " ᠮᠣᠳᠤᠨ  ᠤ ᠳᠠᠭᠤ",
    "SOUNDS_WUB_BEATBOX": " ᠠᠢᠵᠠᠮ-ᠫᠦ",
    "SOUNDS_XYLO1": "xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": " ᠠᠢᠪᠢ",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_APPLE": " ᠠᠯᠮᠤᠷᠠᠳ",
    "SPRITES_ARROW1": " ᠰᠣᠮᠤᠨ ᠦᠵᠦᠭᠦᠷ1",
    "SPRITES_AVERY": " ᠠᠹᠧᠷᠢ",
    "SPRITES_AVERY_WALKING": " ᠠᠹᠧᠷᠢ  ᠠᠯᠬᠤᠬᠤ",
    "SPRITES_BALL": " ᠪᠥᠮᠪᠦᠭᠡ",
    "SPRITES_BALLERINA": " ᠪᠥᠵᠢᠭᠴᠢᠨ",
    "SPRITES_BALLOON1": " ᠬᠡᠢ ᠪᠥᠮᠪᠦᠭᠡ1",
    "SPRITES_BANANAS": " ᠪᠠᠨᠠᠨᠠ",
    "SPRITES_BASEBALL": " ᠰᠢᠳᠠᠮ ᠪᠥᠮᠪᠦᠭᠡ",
    "SPRITES_BASKETBALL": " ᠰᠠᠭᠰᠤᠨ ᠪᠥᠮᠪᠦᠭᠡ",
    "SPRITES_BAT": "Bat",
    "SPRITES_BATTER": "Batter",
    "SPRITES_BEACHBALL": " ᠲᠠᠩ  ᠤᠨ ᠪᠥᠮᠪᠦᠭᠡ",
    "SPRITES_BEAR": " ᠪᠠᠭᠠᠪᠠᠭᠠᠢ",
    "SPRITES_BEAR-WALKING": " ᠪᠠᠭᠠᠪᠠᠭᠠᠢ- ᠠᠯᠬᠤᠬᠤ",
    "SPRITES_BEETLE": " ᠬᠤᠶᠠᠭᠲᠤ ᠬᠣᠷᠤᠬᠠᠢ",
    "SPRITES_BELL": " ᠬᠣᠩᠬᠣ",
    "SPRITES_BEN": " ᠪᠧᠨ",
    "SPRITES_BOWL": " ᠠᠶᠠᠭ᠎ᠠ",
    "SPRITES_BOWTIE": " ᠡᠷᠪᠡᠬᠡᠢ ᠵᠠᠩᠭᠢᠶ᠎ᠠ",
    "SPRITES_BREAD": " ᠲᠠᠯᠬ᠎ᠠ",
    "SPRITES_BROOM": " ᠱᠦᠬᠦᠷ",
    "SPRITES_BUILDINGS": " ᠪᠠᠷᠢᠯᠭ᠎ᠠ",
    "SPRITES_BUTTERFLY_1": "Butterfly 1",
    "SPRITES_BUTTERFLY_2": "Butterfly 2",
    "SPRITES_BUTTON1": " ᠲᠣᠭᠤᠷᠢᠭ ᠳᠠᠷᠤᠪᠴᠢ1",
    "SPRITES_BUTTON2": " ᠵᠤᠤᠪᠠᠩ  ᠳᠠᠷᠤᠪᠴᠢ2",
    "SPRITES_BUTTON3": " ᠲᠦᠷᠪᠡᠯᠵᠢᠨ ᠳᠠᠷᠤᠪᠴᠢ3",
    "SPRITES_BUTTON4": " ᠭᠣᠬᠠᠲᠤ ᠳᠠᠷᠤᠪᠴᠢ4",
    "SPRITES_BUTTON5": " ᠠᠴᠠ ᠳᠠᠷᠤᠪᠴᠢ5",
    "SPRITES_CAKE": " ᠥᠨᠳᠡᠭᠡ ᠲᠠᠢ ᠪᠣᠭᠣᠷᠰᠣᠭ",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": " ᠺᠠᠰᠧᠢ",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Cat 2",
    "SPRITES_CATCHER": "Catcher",
    "SPRITES_CENTAUR": " ᠬᠦᠮᠦᠨ ᠮᠣᠷᠢ",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Chick",
    "SPRITES_CITY_BUS": "City Bus",
    "SPRITES_CLOUD": " ᠴᠠᠭᠠᠨ ᠡᠭᠦᠯᠡ",
    "SPRITES_CLOUDS": " ᠴᠠᠭᠠᠨ ᠡᠭᠦᠯᠡ",
    "SPRITES_CONVERTIBLE": "Convertible",
    "SPRITES_CONVERTIBLE_2": "Convertible 2",
    "SPRITES_CRAB": " ᠨᠠᠢ᠌ᠮᠠᠯᠵᠢ",
    "SPRITES_CRYSTAL": " ᠠᠯᠮᠠᠰ ᠴᠢᠯᠠᠭᠤ",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": " ᠳ᠋ᠠᠨᠢ",
    "SPRITES_DEE": " ᠳ᠋ᠸᠸ",
    "SPRITES_DEVIN": " ᠳ᠋ᠧᠸᠢᠨ",
    "SPRITES_DINOSAUR1": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ",
    "SPRITES_DINOSAUR2": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ2",
    "SPRITES_DINOSAUR3": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ3",
    "SPRITES_DINOSAUR4": "ᠠᠪᠤᠷᠭᠤ ᠭᠦᠷᠪᠡᠯ4",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DIVER1": "ᠱᠤᠮᠪᠠᠭᠴᠢ1",
    "SPRITES_DIVER2": "ᠱᠤᠮᠪᠠᠭᠴᠢ2",
    "SPRITES_DOG1": "ᠨᠣᠬᠠᠢ1",
    "SPRITES_DOG2": "ᠨᠣᠬᠠᠢ2",
    "SPRITES_DONUT": " ᠴᠠᠭᠠᠷᠢᠭ",
    "SPRITES_DORIAN": " ᠳ᠋ᠤᠷᠢᠶᠠᠨ ᠬᠦᠮᠦᠨ",
    "SPRITES_DOT": " ᠳᠤᠰᠤᠯ",
    "SPRITES_DOVE": " ᠳᠠᠭᠲᠠᠭ᠎ᠠ",
    "SPRITES_DRAGON": " ᠯᠤᠤ",
    "SPRITES_DRAGONFLY": "Dragonfly",
    "SPRITES_DRESS": "Dress",
    "SPRITES_DRUM": " ᠬᠡᠩᠭᠡᠷᠭᠡ",
    "SPRITES_DRUM_KIT": " ᠵᠠᠽᠢ ᠬᠡᠩᠭᠡᠷᠭᠡ",
    "SPRITES_DRUM-CYMBAL": " ᠴᠠᠩ",
    "SPRITES_DRUM-HIGHHAT": " ᠬᠦᠯ ᠴᠠᠩ",
    "SPRITES_DRUM-SNARE": " ᠪᠠᠭ᠎ᠠ ᠬᠡᠩᠭᠡᠷᠭᠡ",
    "SPRITES_DRUMS_CONGA": "Drums Conga",
    "SPRITES_DRUMS_TABLA": "Drums Tabla",
    "SPRITES_DUCK": " ᠨᠤᠭᠤᠰᠤ",
    "SPRITES_EARTH": " ᠳᠡᠯᠡᠬᠡᠢ ᠶᠢᠨ ᠪᠥᠮᠪᠥᠷᠴᠡᠭ",
    "SPRITES_EASEL": "Easel",
    "SPRITES_EGG": " ᠥᠨᠳᠡᠭᠡ",
    "SPRITES_ELEPHANT": " ᠵᠠᠭᠠᠨ",
    "SPRITES_ELF": " ᠪᠢᠴᠢᠬᠠᠨ ᠠᠨᠠᠬᠠᠢ",
    "SPRITES_FAIRY": " ᠠᠨᠠᠬᠠᠢ",
    "SPRITES_FISH": " ᠵᠢᠭᠠᠰᠤ",
    "SPRITES_FISHBOWL": "Fishbowl",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Football",
    "SPRITES_FORTUNE_COOKIE": "Fortune Cookie",
    "SPRITES_FOX": " ᠦᠨᠡᠭᠡ",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": " ᠮᠡᠯᠡᠬᠡᠢ",
    "SPRITES_FROG_2_": "Frog 2 ",
    "SPRITES_FRUIT_PLATTER": "Fruit Platter",
    "SPRITES_FRUIT_SALAD": " ᠵᠢᠮᠢᠰᠦᠨ ᠰᠠᠯᠠᠲ",
    "SPRITES_GHOST": "Ghost",
    "SPRITES_GIFT": " ᠪᠡᠯᠡᠭ",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GLASS_WATER": " ᠤᠰᠤᠨ ᠴᠣᠮᠣ",
    "SPRITES_GLASSES": " ᠨᠢᠳᠦᠨ ᠰᠢᠯ",
    "SPRITES_GOALIE": " ᠡᠭᠦᠳᠡᠴᠢ",
    "SPRITES_GOBLIN": " ᠭᠪᠦᠯᠢᠨ",
    "SPRITES_GRASSHOPPER": "Grasshopper",
    "SPRITES_GREEN_FLAG": " ᠨᠣᠭᠣᠭᠠᠨ ᠲᠤᠭ",
    "SPRITES_GRIFFIN": "ᠭᠷᠢᠹᠸ᠋ᠨ",
    "SPRITES_GUITAR": " ᠭᠢᠲ᠋ᠠᠷ",
    "SPRITES_GUITAR-ELECTRIC1": "Guitar-electric1",
    "SPRITES_GUITAR-ELECTRIC2": "ᠴᠠᠬᠢᠯᠭᠠᠨ ᠭᠢᠲ᠋ᠠᠷ2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hare",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hat1 ",
    "SPRITES_HATCHLING": "Hatchling",
    "SPRITES_HEART": " ᠬᠠᠢᠷ᠎ᠠ",
    "SPRITES_HEART_CANDY": " ᠬᠠᠢᠷ᠎ᠠ",
    "SPRITES_HEART_FACE": " ᠰᠡᠳᠬᠢᠯ ᠦᠨ ᠲᠦᠷᠰᠦ",
    "SPRITES_HEDGEHOG": " ᠵᠠᠷᠠᠭ᠎ᠠ",
    "SPRITES_HEN": "Hen",
    "SPRITES_HIPPO1": " ᠤᠰᠤᠨ ᠳᠡᠬᠢ",
    "SPRITES_HOME_BUTTON": " ᠪᠠᠢᠰᠢᠩ ᠬᠡᠯᠪᠡᠷᠢᠲᠡᠢ ᠳᠠᠷᠤᠪᠴᠢ",
    "SPRITES_HORSE": "Horse",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": " ᠯᠣᠩᠬᠣ",
    "SPRITES_JELLYFISH": " ᠵᠠᠯᠬᠠᠭ",
    "SPRITES_JORDYN": " ᠵᠢᠣᠷᠳ᠋ᠸ᠋ᠨ",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": " ᠲᠦᠯᠬᠢᠭᠦᠷ",
    "SPRITES_KEYBOARD": " ᠳᠠᠷᠤᠭᠤᠯ",
    "SPRITES_KIRAN": " ᠺᠢᠷᠠᠨ",
    "SPRITES_KNIGHT": " ᠮᠣᠷᠢᠲᠠᠨ",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_LADYBUG1": " ᠰᠢᠭᠦᠷ ᠬᠣᠷᠤᠬᠠᠢ1",
    "SPRITES_LADYBUG2": " ᠰᠢᠭᠦᠷ ᠬᠣᠷᠤᠬᠠᠢ2",
    "SPRITES_LAPTOP": " ᠪᠢᠴᠢᠯ ᠺᠣᠮᠫᠢᠦ᠋ᠲ᠋ᠧᠷ",
    "SPRITES_LIGHTNING": " ᠭᠢᠯᠪᠠᠭᠠᠨ",
    "SPRITES_LINE": " ᠤᠲᠠᠰᠤ",
    "SPRITES_LION": " ᠪᠠᠷᠰ",
    "SPRITES_LLAMA": " ᠲᠡᠮᠡᠭᠡ",
    "SPRITES_MAGIC_WAND": " ᠰᠢᠳᠢᠲᠦ ᠮᠣᠳᠣ",
    "SPRITES_MAX": " ᠮᠠᠺᠰ",
    "SPRITES_MERMAID": " ᠬᠥᠮᠥᠨ ᠵᠢᠭᠠᠰᠤ",
    "SPRITES_MICROPHONE": " ᠮᠢᠺᠷᠤᠹᠤᠨ",
    "SPRITES_MILK": " ᠰᠦ",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MONET": "  ᠮᠣᠨᠠᠢ",
    "SPRITES_MONKEY": " ᠰᠠᠮᠵᠠ",
    "SPRITES_MOTORCYCLE": "Motorcycle",
    "SPRITES_MOUSE1": " ᠬᠤᠯᠤᠭᠠᠨ᠎ᠠ",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": " ᠤᠨᠠᠭ᠎ᠠ",
    "SPRITES_OCTOPUS": " ᠲᠣᠯᠤ ᠵᠢᠭᠠᠰᠤ",
    "SPRITES_ORANGE": " ᠵᠦᠷᠵᠢ",
    "SPRITES_ORANGE2": "ᠵᠦᠷᠵᠢ2",
    "SPRITES_OUTFIELDER": "Outfielder",
    "SPRITES_OWL": " ᠤᠭᠤᠯᠢ",
    "SPRITES_PADDLE": " ᠰᠡᠯᠢᠭᠦᠷ",
    "SPRITES_PANTHER": " ᠠᠮᠧᠷᠢᠺᠠ ᠶᠢᠨ ᠢᠷᠪᠢᠰ",
    "SPRITES_PANTS": "Pants",
    "SPRITES_PARROT": " ᠲᠣᠳᠢ",
    "SPRITES_PARTY_HATS": "Party Hats",
    "SPRITES_PENCIL": " ᠬᠠᠷᠠᠨᠳᠠ",
    "SPRITES_PENGUIN": "Penguin",
    "SPRITES_PENGUIN_2": "Penguin 2",
    "SPRITES_PITCHER": "Pitcher",
    "SPRITES_PLANET2": "ᠭᠠᠷᠠᠭ ᠣᠳᠣ2",
    "SPRITES_POLAR_BEAR": "Polar Bear",
    "SPRITES_POTION": " ᠡᠮ ᠨᠠᠢᠷᠠᠯᠭ᠎ᠠ",
    "SPRITES_PRINCE": " ᠸᠠᠩ ᠤᠨ ᠬᠥᠪᠡᠭᠦᠨ",
    "SPRITES_PRINCESS": " ᠭᠦᠩᠵᠦ",
    "SPRITES_PUFFERFISH": " ᠬᠤᠰᠬ᠎ᠠ",
    "SPRITES_PUPPY": "Puppy",
    "SPRITES_RABBIT": " ᠲᠠᠤᠯᠠᠢ",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": " ᠰᠣᠯᠣᠩᠭ᠎ᠠ",
    "SPRITES_REFEREE": " ᠬᠢᠨᠠᠭᠴᠢ",
    "SPRITES_REINDEER": " ᠴᠠ ᠪᠤᠭᠤ",
    "SPRITES_RETRO_ROBOT": "Retro Robot",
    "SOUNDS_COMPUTER_BEEPS1": "computer beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "computer beeps2",
    "SPRITES_RIPLEY": " ᠯᠢᠫᠦᠯᠢ",
    "SPRITES_ROBOT": " ᠮᠠᠰᠢᠨ ᠬᠥᠮᠥᠨ",
    "SPRITES_ROCKETSHIP": "Rocketship",
    "SPRITES_ROCKS": " ᠴᠢᠯᠠᠭᠤ",
    "SPRITES_ROOSTER": "Rooster",
    "SPRITES_RUBY": "Ruby",
    "SPRITES_SAILBOAT": "Sailboat",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": " ᠰᠸ᠋ᠺᠰᠹᠣᠨ",
    "SPRITES_SCARF": "Scarf",
    "SPRITES_SHARK": " ᠳᠤᠯᠤᠨ",
    "SPRITES_SHARK_2": "Shark 2",
    "SPRITES_SHIRT": "Shirt",
    "SPRITES_SHOES": "Shoes",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "ᠳᠠᠭᠤᠴᠢᠨ1",
    "SPRITES_SKELETON": "Skeleton",
    "SPRITES_SNAKE": " ᠮᠣᠭᠠᠢ",
    "SPRITES_SNOWFLAKE": " ᠴᠠᠰᠤᠨ ᠴᠡᠴᠡᠭ",
    "SPRITES_SNOWMAN": " ᠴᠠᠰᠤᠨ ᠬᠥᠮᠥᠨ",
    "SPRITES_SOCCER_BALL": " ᠬᠥᠯ ᠪᠥᠮᠪᠦᠭᠡ",
    "SPRITES_SPEAKER": " ᠬᠦᠩᠭᠢᠨᠡᠭᠦᠷ",
    "SPRITES_SQUIRREL": "Squirrel",
    "SPRITES_STAR": " ᠣᠳᠣ",
    "SPRITES_STARFISH": " ᠳᠠᠯᠠᠢ ᠶᠢᠨ ᠣᠳᠣᠨ",
    "SPRITES_STOP": " ᠲᠦᠷ ᠵᠣᠭ ᠬᠢᠬᠦ ᠳᠠᠷᠤᠪᠴᠢ",
    "SPRITES_STRAWBERRY": " ᠬᠦᠵᠡᠯᠵᠡᠭᠡᠨ᠎ᠡ",
    "SPRITES_SUN": " ᠨᠠᠷᠠ",
    "SPRITES_SUNGLASSES1": "ᠨᠠᠷᠠᠨ ᠰᠢᠯ1",
    "SPRITES_TACO": " ᠣᠷᠢᠶᠠᠮᠠᠭ",
    "SPRITES_TAKEOUT": "ᠵᠠᠬᠢᠶᠠᠯᠠᠭ᠎ᠠ ᠬᠣᠭᠣᠯᠠ-b",
    "SPRITES_TEN80_DANCE": "Ten80 Dance",
    "SPRITES_TENNIS_BALL": "Tennis Ball",
    "SPRITES_TOUCAN": " ᠬᠡᠷᠢᠰ ᠰᠢᠪᠠᠭᠤ",
    "SPRITES_TRAMPOLINE": "Trampoline",
    "SPRITES_TREE1": "ᠮᠣᠳᠣ1",
    "SPRITES_TREES": " ᠮᠣᠳᠣᠳ",
    "SPRITES_TRUCK": "Truck",
    "SPRITES_TRUMPET": " ᠪᠠᠭ᠎ᠠ ᠨᠣᠮᠧᠷᠲᠤ",
    "SPRITES_UNICORN": "ᠭᠠᠭᠴᠠ ᠡᠪᠡᠷᠲᠦ ᠠᠮᠢᠲᠠᠨ",
    "SPRITES_UNICORN_2": "Unicorn 2",
    "SPRITES_UNICORN_RUNNING": "Unicorn Running",
    "SPRITES_WAND": " ᠰᠢᠳᠢᠲᠦ ᠮᠣᠳᠣ",
    "SPRITES_WANDA": " ᠲᠡᠨᠦᠭᠦᠯᠴᠢᠨ",
    "SPRITES_WATERMELON": " ᠲᠠᠷᠪᠤᠰ",
    "SPRITES_WINTER_HAT": "Winter Hat",
    "SPRITES_WITCH": " ᠢᠲᠤᠭᠠᠨ",
    "SPRITES_WIZARD": " ᠪᠥᠭᠡ",
    "SPRITES_WIZARD_HAT": "Wizard Hat",
    "SPRITES_WIZARD-TOAD": " ᠮᠡᠯᠡᠬᠡᠢ ᠳᠣᠮᠴᠢ",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "ᠬᠡᠰᠡᠭᠲᠦ-A",
    "SPRITES_BLOCK-B": "ᠬᠡᠰᠡᠭᠲᠦ-B",
    "SPRITES_BLOCK-C": "ᠬᠡᠰᠡᠭᠲᠦ-C",
    "SPRITES_BLOCK-D": "ᠬᠡᠰᠡᠭᠲᠦ-D",
    "SPRITES_BLOCK-E": "ᠬᠡᠰᠡᠭᠲᠦ-E",
    "SPRITES_BLOCK-F": "ᠬᠡᠰᠡᠭᠲᠦ-F",
    "SPRITES_BLOCK-G": "ᠬᠡᠰᠡᠭᠲᠦ-G",
    "SPRITES_BLOCK-H": "ᠬᠡᠰᠡᠭᠲᠦ-H",
    "SPRITES_BLOCK-I": "ᠬᠡᠰᠡᠭᠲᠦ-I",
    "SPRITES_BLOCK-J": "ᠬᠡᠰᠡᠭᠲᠦ-J",
    "SPRITES_BLOCK-K": "ᠬᠡᠰᠡᠭᠲᠦ-K",
    "SPRITES_BLOCK-L": "ᠬᠡᠰᠡᠭᠲᠦ-L",
    "SPRITES_BLOCK-M": "ᠬᠡᠰᠡᠭᠲᠦ-M",
    "SPRITES_BLOCK-N": "ᠬᠡᠰᠡᠭᠲᠦ-N",
    "SPRITES_BLOCK-O": "ᠬᠡᠰᠡᠭᠲᠦ-O",
    "SPRITES_BLOCK-P": "ᠬᠡᠰᠡᠭᠲᠦ-P",
    "SPRITES_BLOCK-Q": "ᠬᠡᠰᠡᠭᠲᠦ-Q",
    "SPRITES_BLOCK-R": "ᠬᠡᠰᠡᠭᠲᠦ-R",
    "SPRITES_BLOCK-S": "ᠬᠡᠰᠡᠭᠲᠦ-S",
    "SPRITES_BLOCK-T": "ᠬᠡᠰᠡᠭᠲᠦ-T",
    "SPRITES_BLOCK-U": "ᠬᠡᠰᠡᠭᠲᠦ-U",
    "SPRITES_BLOCK-V": "ᠬᠡᠰᠡᠭᠲᠦ-V",
    "SPRITES_BLOCK-W": "ᠬᠡᠰᠡᠭᠲᠦ-W",
    "SPRITES_BLOCK-X": "ᠬᠡᠰᠡᠭᠲᠦ-X",
    "SPRITES_BLOCK-Y": "ᠬᠡᠰᠡᠭᠲᠦ-Y",
    "SPRITES_BLOCK-Z": "ᠬᠡᠰᠡᠭᠲᠦ-Z",
    "SPRITES_GLOW-0": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-0",
    "SPRITES_GLOW-1": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-1",
    "SPRITES_GLOW-2": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-2",
    "SPRITES_GLOW-3": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-3",
    "SPRITES_GLOW-4": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-4",
    "SPRITES_GLOW-5": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-5",
    "SPRITES_GLOW-6": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-6",
    "SPRITES_GLOW-7": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-7",
    "SPRITES_GLOW-8": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-8",
    "SPRITES_GLOW-9": "ᠭᠢᠯᠪᠠᠭ᠎ᠠ-9",
    "SPRITES_GLOW-A": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-A",
    "SPRITES_GLOW-B": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-B",
    "SPRITES_GLOW-C": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-C",
    "SPRITES_GLOW-D": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-D",
    "SPRITES_GLOW-E": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-E",
    "SPRITES_GLOW-F": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-F",
    "SPRITES_GLOW-G": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-G",
    "SPRITES_GLOW-H": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-H",
    "SPRITES_GLOW-I": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-I",
    "SPRITES_GLOW-J": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-J",
    "SPRITES_GLOW-K": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-K",
    "SPRITES_GLOW-L": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-L",
    "SPRITES_GLOW-M": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-M",
    "SPRITES_GLOW-N": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-N",
    "SPRITES_GLOW-O": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-O",
    "SPRITES_GLOW-P": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-P",
    "SPRITES_GLOW-Q": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-Q",
    "SPRITES_GLOW-R": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-R",
    "SPRITES_GLOW-S": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-S",
    "SPRITES_GLOW-T": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-T",
    "SPRITES_GLOW-U": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-U",
    "SPRITES_GLOW-V": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-V",
    "SPRITES_GLOW-W": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-W",
    "SPRITES_GLOW-X": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-X",
    "SPRITES_GLOW-Y": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-Y",
    "SPRITES_GLOW-Z": " ᠭᠢᠯᠪᠠᠭ᠎ᠠ-Z",
    "SPRITES_STORY-A": "ᠦᠯᠢᠭᠡᠷ-A",
    "SPRITES_STORY-B": "ᠦᠯᠢᠭᠡᠷ-B",
    "SPRITES_STORY-C": "ᠦᠯᠢᠭᠡᠷ-C",
    "SPRITES_STORY-D": "ᠦᠯᠢᠭᠡᠷ-D",
    "SPRITES_STORY-E": "ᠦᠯᠢᠭᠡᠷ-E",
    "SPRITES_STORY-F": "ᠦᠯᠢᠭᠡᠷ-F",
    "SPRITES_STORY-G": "ᠦᠯᠢᠭᠡᠷ-G",
    "SPRITES_STORY-H": "ᠦᠯᠢᠭᠡᠷ-H",
    "SPRITES_STORY-I": "ᠦᠯᠢᠭᠡᠷ-I",
    "SPRITES_STORY-J": "ᠦᠯᠢᠭᠡᠷ-J",
    "SPRITES_STORY-K": "ᠦᠯᠢᠭᠡᠷ-K",
    "SPRITES_STORY-L": "ᠦᠯᠢᠭᠡᠷ-L",
    "SPRITES_STORY-M": "ᠦᠯᠢᠭᠡᠷ-M",
    "SPRITES_STORY-N": "ᠦᠯᠢᠭᠡᠷ-N",
    "SPRITES_STORY-O": "ᠦᠯᠢᠭᠡᠷ-O",
    "SPRITES_STORY-P": "ᠦᠯᠢᠭᠡᠷ-P",
    "SPRITES_STORY-Q": "ᠦᠯᠢᠭᠡᠷ-Q",
    "SPRITES_STORY-R": "ᠦᠯᠢᠭᠡᠷ-R",
    "SPRITES_STORY-S": "ᠦᠯᠢᠭᠡᠷ-S",
    "SPRITES_STORY-T": "ᠦᠯᠢᠭᠡᠷ-T",
    "SPRITES_STORY-U": "ᠦᠯᠢᠭᠡᠷ-U",
    "SPRITES_STORY-V": "ᠦᠯᠢᠭᠡᠷ-V",
    "SPRITES_STORY-W": "ᠦᠯᠢᠭᠡᠷ-W",
    "SPRITES_STORY-X": "ᠦᠯᠢᠭᠡᠷ-X",
    "SPRITES_STORY-Y": "ᠦᠯᠢᠭᠡᠷ-Y",
    "SPRITES_STORY-Z": "ᠦᠯᠢᠭᠡᠷ -Z",
    "SPRITES_MIND+2022_01_CIVIL-AIRCRAFT": "民航飞机",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT1": "民航飞机-正面",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT2": "民航飞机-侧面",
    "COSTUMES_MIND+2022_01_CIVIL-AIRCRAFT3": "民航飞机-顶部",
    "SPRITES_MIND+2022_02_AEROSPACEPLANE": "航天飞机",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE1": "航天飞机-正面",
    "COSTUMES_MIND+2022_02_AEROSPACEPLANE2": "航天飞机-侧面",
    "SPRITES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM": "航天飞机机舱",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM1": "航天飞机机舱-正面",
    "COSTUMES_MIND+2022_03_AEROSPACEPLANE-ENGINEROOM2": "航天飞机机舱-侧面",
    "SPRITES_MIND+2022_04_TRAFFIC-LIGHT": "表情红绿灯",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT1": "表情红绿灯-红",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT2": "表情红绿灯-黄",
    "COSTUMES_MIND+2022_04_TRAFFIC-LIGHT3": "表情红绿灯-绿",
    "SPRITES_MIND+2022_05_FLYBROW": "飞艇",
    "COSTUMES_MIND+2022_05_FLYBROW1": "飞艇-侧面",
    "COSTUMES_MIND+2022_05_FLYBROW2": "飞艇-仰视",
    "SPRITES_MIND+2022_06_HIGHSPEED-RAIL": "高铁",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL1": "高铁-正面",
    "COSTUMES_MIND+2022_06_HIGHSPEED-RAIL2": "高铁-侧面",
    "SPRITES_MIND+2022_07_POLICECAR": "警车",
    "COSTUMES_MIND+2022_07_POLICECAR1": "警车-运动1",
    "COSTUMES_MIND+2022_07_POLICECAR2": "警车-运动2",
    "COSTUMES_MIND+2022_07_POLICECAR3": "警车-运动3",
    "COSTUMES_MIND+2022_07_POLICECAR4": "警车-运动4",
    "COSTUMES_MIND+2022_07_POLICECAR5": "警车-亮灯1",
    "COSTUMES_MIND+2022_07_POLICECAR6": "警车-亮灯2",
    "COSTUMES_MIND+2022_07_POLICECAR7": "警车-静止",
    "SPRITES_MIND+2022_08_CAR": "小汽车",
    "COSTUMES_MIND+2022_08_CAR1": "小汽车-1",
    "COSTUMES_MIND+2022_08_CAR2": "小汽车-2",
    "COSTUMES_MIND+2022_08_CAR3": "小汽车-3",
    "COSTUMES_MIND+2022_08_CAR4": "小汽车-4",
    "COSTUMES_MIND+2022_08_CAR5": "小汽车-5",
    "COSTUMES_MIND+2022_08_CAR6": "小汽车-6",
    "SPRITES_MIND+2022_09_DININGCAR": "餐车",
    "SPRITES_MIND+2022_10_BUS": "公交车",
    "SPRITES_MIND+2022_11_GO-KART": "卡丁车",
    "SPRITES_MIND+2022_12_MOTORBIKE": "摩托车",
    "SPRITES_MIND+2022_13_TAXI": "出租车",
    "COSTUMES_MIND+2022_13_TAXI01": "出租车-正侧面",
    "COSTUMES_MIND+2022_13_TAXI02": "出租车-侧面",
    "COSTUMES_MIND+2022_13_TAXI03": "出租车-正侧面关灯",
    "COSTUMES_MIND+2022_13_TAXI04": "出租车-正侧面开灯1",
    "COSTUMES_MIND+2022_13_TAXI05": "出租车-正侧面开灯2",
    "COSTUMES_MIND+2022_13_TAXI06": "出租车-正面",
    "SPRITES_MIND+2022_14_PUMPKINCAR": "南瓜车",
    "COSTUMES_MIND+2022_14_PUMPKINCAR1": "南瓜车-1",
    "COSTUMES_MIND+2022_14_PUMPKINCAR2": "南瓜车-2",
    "COSTUMES_MIND+2022_14_PUMPKINCAR3": "南瓜车-3",
    "COSTUMES_MIND+2022_14_PUMPKINCAR4": "南瓜车-开门",
    "COSTUMES_MIND+2022_14_PUMPKINCAR5": "南瓜车-夜晚",
    "SPRITES_MIND+2022_15_LITTLESAILBOAT": "小帆船",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT1": "小帆船-侧面",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT2": "小帆船-顶侧",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT3": "小帆船-斜顶侧",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT4": "小帆船-斜顶侧1",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT5": "小帆船-斜顶侧2",
    "COSTUMES_MIND+2022_15_LITTLESAILBOAT6": "小帆船-斜顶侧3",
    "SPRITES_MIND+2022_16_RUBBLERACER": "小飞船",
    "COSTUMES_MIND+2022_16_RUBBLERACER1": "小飞船-01",
    "COSTUMES_MIND+2022_16_RUBBLERACER2": "小飞船-02",
    "COSTUMES_MIND+2022_16_RUBBLERACER3": "小飞船-03",
    "SPRITES_MIND+2022_17_BARNEY": "小矿车",
    "COSTUMES_MIND+2022_17_BARNEY1": "小矿车-侧面满",
    "COSTUMES_MIND+2022_17_BARNEY2": "小矿车-顶侧面",
    "COSTUMES_MIND+2022_17_BARNEY3": "小矿车-顶侧面2",
    "COSTUMES_MIND+2022_17_BARNEY4": "小矿车-顶侧面3",
    "COSTUMES_MIND+2022_17_BARNEY5": "小矿车-顶侧面空1",
    "SPRITES_MIND+2022_18_UTILITYCART": "小推车",
    "COSTUMES_MIND+2022_18_UTILITYCART1": "小推车-侧面",
    "COSTUMES_MIND+2022_18_UTILITYCART2": "小推车-顶侧面",
    "COSTUMES_MIND+2022_18_UTILITYCART3": "小推车-顶侧面满1",
    "COSTUMES_MIND+2022_18_UTILITYCART4": "小推车-顶侧面满2",
    "COSTUMES_MIND+2022_18_UTILITYCART5": "小推车-顶侧面满3",
    "SPRITES_MIND+2022_19_BIKE": "自行车",
    "COSTUMES_MIND+2022_19_BIKE1": "自行车-侧面1",
    "COSTUMES_MIND+2022_19_BIKE2": "自行车-侧面2",
    "COSTUMES_MIND+2022_19_BIKE3": "自行车-侧面3",
    "COSTUMES_MIND+2022_19_BIKE4": "自行车-侧面4",
    "COSTUMES_MIND+2022_19_BIKE5": "自行车-正面",
    "SPRITES_MIND+2022_20_RACECAR": "赛车",
    "COSTUMES_MIND+2022_20_RACECAR1": "赛车-侧面",
    "COSTUMES_MIND+2022_20_RACECAR2": "蓝色赛车-顶面1",
    "COSTUMES_MIND+2022_20_RACECAR3": "蓝色赛车-顶面2",
    "COSTUMES_MIND+2022_20_RACECAR4": "蓝色赛车-顶面3",
    "COSTUMES_MIND+2022_20_RACECAR5": "黄色赛车-顶面1",
    "COSTUMES_MIND+2022_20_RACECAR6": "黄色赛车-顶面2",
    "COSTUMES_MIND+2022_20_RACECAR7": "黄色赛车-顶面3",
    "COSTUMES_MIND+2022_20_RACECAR8": "粉色赛车-顶面1",
    "COSTUMES_MIND+2022_20_RACECAR9": "粉色赛车-顶面2",
    "COSTUMES_MIND+2022_20_RACECAR10": "粉色赛车-顶面3",
    "SPRITES_MIND+2022_21_METEORITES1": "陨石",
    "COSTUMES_MIND+2022_21_METEORITES1_1": "陨石1-01",
    "COSTUMES_MIND+2022_21_METEORITES1_2": "陨石1-02",
    "COSTUMES_MIND+2022_21_METEORITES1_3": "陨石1-03",
    "SPRITES_MIND+2022_22_METEORITES2": "陨石2",
    "COSTUMES_MIND+2022_22_METEORITES2_1": "陨石2-01",
    "COSTUMES_MIND+2022_22_METEORITES2_2": "陨石2-02",
    "COSTUMES_MIND+2022_22_METEORITES2_3": "陨石2-03",
    "SPRITES_MIND+2022_23_METEORITES3": "陨石3",
    "COSTUMES_MIND+2022_23_METEORITES3_1": "陨石3-01",
    "COSTUMES_MIND+2022_23_METEORITES3_2": "陨石3-02",
    "COSTUMES_MIND+2022_23_METEORITES3_3": "陨石3-03",
    "SPRITES_MIND+2022_24_METEORITES4": "陨石4",
    "COSTUMES_MIND+2022_24_METEORITES4_1": "陨石4-01",
    "COSTUMES_MIND+2022_24_METEORITES4_2": "陨石4-02",
    "COSTUMES_MIND+2022_24_METEORITES4_3": "陨石4-03",
    "COSTUMES_MIND+2022_24_METEORITES4_4": "陨石4-04",
    "SPRITES_MIND+2022_25_METEORITES5": "陨石5",
    "COSTUMES_MIND+2022_25_METEORITES5_1": "陨石5-01",
    "COSTUMES_MIND+2022_25_METEORITES5_2": "陨石5-02",
    "COSTUMES_MIND+2022_25_METEORITES5_3": "陨石5-03",
    "COSTUMES_MIND+2022_25_METEORITES5_4": "陨石5-04",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_01": "陨石大冒险A-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREA_02": "陨石大冒险A-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_01": "陨石大冒险B-1",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_02": "陨石大冒险B-2",
    "BACKDROPS_2022_METEORITE-ADVENTUREB_03": "陨石大冒险B-3",
    "MIND+_FACE_SIDE": "Mind+人物表情-侧面",
    "MIND+_FACE_SIDE-1": "Mind+人物表情-侧面-1",
    "MIND+_FACE_SIDE-2": "Mind+人物表情-侧面-2",
    "MIND+_FACE_SIDE-3": "Mind+人物表情-侧面-3",
    "MIND+_FACE_SIDE-4": "Mind+人物表情-侧面-4",
    "MIND+_FACE_SIDE-5": "Mind+人物表情-侧面-5",
    "MIND+_FACE_SIDE-6": "Mind+人物表情-侧面-6",
    "MIND+_FACE_SIDE-7": "Mind+人物表情-侧面-7",
    "MIND+_FACE_SIDE-8": "Mind+人物表情-侧面-8",
    "MIND+_FACE_SIDE-9": "Mind+人物表情-侧面-9",
    "MIND+_FACE_SIDE-10": "Mind+人物表情-侧面-10",
    "MIND+_FACE_POSITIVE": "Mind+人物表情-正面",
    "MIND+_FACE_POSITIVE-1": "Mind+人物表情-正面-1",
    "MIND+_FACE_POSITIVE-2": "Mind+人物表情-正面-2",
    "MIND+_FACE_POSITIVE-3": "Mind+人物表情-正面-3",
    "MIND+_FACE_POSITIVE-4": "Mind+人物表情-正面-4",
    "MIND+_FACE_POSITIVE-5": "Mind+人物表情-正面-5",
    "MIND+_FACE_POSITIVE-6": "Mind+人物表情-正面-6",
    "MIND+_FACE_POSITIVE-7": "Mind+人物表情-正面-7",
    "MIND+_FACE_POSITIVE-8": "Mind+人物表情-正面-8",
    "MIND+_FACE_POSITIVE-9": "Mind+人物表情-正面-9",
    "MIND+_FACE_POSITIVE-10": "Mind+人物表情-正面-10",
    "MIND+_DOZE": "打瞌睡",
    "MIND+_DOZE-1": "打瞌睡-1",
    "MIND+_DOZE-2": "打瞌睡-2",
    "MIND+_DOZE-3": "打瞌睡-3",
    "MIND+_SQUAT": "蹲下起立",
    "MIND+_SQUAT-1": "蹲下起立-1",
    "MIND+_SQUAT-2": "蹲下起立-2",
    "MIND+_SQUAT-3": "蹲下起立-3",
    "MIND+_ARCHITECT": "Mind+建筑师",
    "MIND+_ARCHITECT-1": "Mind+建筑师-1",
    "MIND+_ARCHITECT-2": "Mind+建筑师-2",
    "MIND+_ARCHITECT-3": "Mind+建筑师-3",
    "MIND+_TRAFFIC_POLICE": "Mind+交警",
    "MIND+_TRAFFIC_POLICE-1": "Mind+交警-1",
    "MIND+_TRAFFIC_POLICE-2": "Mind+交警-2",
    "MIND+_TRAFFIC_POLICE-3": "Mind+交警-3",
    "MIND+_TRAFFIC_POLICE-4": "Mind+交警-4",
    "MIND+_TRAFFIC_POLICE-5": "Mind+交警-5",
    "MIND+_TRAFFIC_POLICE-6": "Mind+交警-6",
    "MIND+_POLICE": "Mind+警察",
    "MIND+_POLICE-1": "Mind+警察-1",
    "MIND+_POLICE-2": "Mind+警察-2",
    "MIND+_POLICE-3": "Mind+警察-3",
    "MIND+_POLICE-4": "Mind+警察-4",
    "MIND+_POLICE-5": "Mind+警察-5",
    "MIND+_POLICE-6": "Mind+警察-长裤1",
    "MIND+_POLICE-7": "Mind+警察-长裤2",
    "MIND+_SCIENTIST": "Mind+科学家",
    "MIND+_SCIENTIST-1": "Mind+科学家-1",
    "MIND+_SCIENTIST-2": "Mind+科学家-2",
    "MIND+_SCIENTIST-3": "Mind+科学家-3",
    "MIND+_SANTA_CLAUS": "Mind+圣诞老人",
    "MIND+_SANTA_CLAUS-1": "Mind+圣诞老人-挥手",
    "MIND+_SANTA_CLAUS-2": "Mind+圣诞老人-送礼",
    "MIND+_SANTA_CLAUS-3": "Mind+圣诞老人-舞蹈",
    "MIND+_FIREMAN": "Mind+消防员",
    "MIND+_FIREMAN-1": "Mind+消防员1",
    "MIND+_FIREMAN-2": "Mind+消防员2",
    "MIND+_FIREMAN-3": "Mind+消防员3",
    "MIND+_FIREMAN-4": "Mind+消防员4",
    "MIND+_DOCTOR": "Mind+医生",
    "MIND+_DOCTOR-1": "Mind+医生-1",
    "MIND+_DOCTOR-2": "Mind+医生-2",
    "MIND+_DOCTOR-3": "Mind+医生-3",
    "MIND+_ASTRONAUT_SPACECRAFT": "Mind+宇航员-飞船",
    "MIND+_ASTRONAUT_SPACECRAFT-1": "Mind+宇航员-飞船-1",
    "MIND+_ASTRONAUT_SPACECRAFT-2": "Mind+宇航员-飞船-2",
    "MIND+_ASTRONAUT_SPACECRAFT-3": "Mind+宇航员-飞船-3",
    "MIND+_ASTRONAUT_SPACECRAFT-4": "Mind+宇航员-飞船-4",
    "MIND+_ASTRONAUT_SPACECRAFT-5": "Mind+宇航员-飞船-5",
    "MIND+_ASTRONAUT_STANDING": "Mind+宇航员-站立",
    "MIND+_ASTRONAUT_STANDING-1": "Mind+宇航员-站立-1",
    "MIND+_ASTRONAUT_STANDING-2": "Mind+宇航员-站立-2",
    "BRUSHWOOD": "草丛",
    "BRUSHWOOD-1": "草丛-1",
    "BRUSHWOOD-2": "草丛-2",
    "BRUSHWOOD-3": "草丛-3",
    "BRUSHWOOD-4": "草丛-4",
    "STONE": "石头",
    "STONE-1": "石头-1",
    "STONE-2": "石头-2",
    "STONE-3": "石头-3",
    "STONE-4": "石头-4",
    "TREE": "树",
    "TREE-1": "树-1",
    "TREE-2": "树-2",
    "TREE-3": "树-3",
    "TREE-4": "树-4",
    "COPSE": "树丛",
    "COPSE-1": "树丛-1",
    "COPSE-2": "树丛-2",
    "COPSE-3": "树丛-3",
    "COPSE-4": "树丛-4",
    "SNOW_MOUNTAIN": "雪山",
    "SNOW_MOUNTAIN-1": "雪山-1",
    "SNOW_MOUNTAIN-2": "雪山-2",
    "SNOW_MOUNTAIN-3": "雪山-3",
    "SNOW_MOUNTAIN-4": "雪山-4",
    "CLOUD": "云",
    "CLOUD-1": "云-1",
    "CLOUD-2": "云-2",
    "CLOUD-3": "云-3",
    "CLOUD-4": "云-4",
    "CARTOON_PINK_BLUE_BUTTON_GROUP": "卡通粉蓝按钮组",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-1": "按钮-loading",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-2": "按钮-播放",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-3": "按钮-关闭",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-4": "按钮-继续挑战",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-5": "按钮-静音",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-6": "按钮-开始",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-7": "按钮-开始电源",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-8": "按钮-设置",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-9": "按钮-下一关",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-10": "按钮-音量",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-11": "按钮-暂停",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-12": "按钮-重复",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-13": "箭头-上",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-14": "箭头-下",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-15": "箭头-右",
    "CARTOON_PINK_BLUE_BUTTON_GROUP-16": "箭头-左",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP": "科技紫按钮组",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-1": "按钮-空",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-2": "播放",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-3": "分享",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-4": "勾选",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-5": "关闭",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-6": "继续游戏",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-7": "静音",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-8": "开始游戏",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-9": "上",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-10": "设置",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-11": "下",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-12": "音乐",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-13": "游戏设置",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-14": "右",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-15": "暂停",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-16": "长方形按钮-空",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-17": "重新开始",
    "SCIENTIFIC_PURPLE_BUTTON_GROUP-18": "左",
    "DIALOG-1": "对话框-1",
    "DIALOG-2": "对话框-2",
    "DIALOG-3": "对话框-3",
    "DIALOG-4": "对话框-4",
    "DIALOG-5": "对话框-5",
    "DIALOG-6": "对话框-6",
    "DIALOG-7": "对话框-7",
    "DIALOG-8": "对话框-8",
    "DIALOG-9": "对话框-9",
    "DIALOG-10": "对话框-10",
    "Epidemic_Prevention_MIND+": "Mind+大白",
    "Epidemic_Prevention_MIND+-1": "穿防护服的Mind+1",
    "Epidemic_Prevention_MIND+-2": "穿防护服的Mind+2",
    "VIRUS": "病毒",
    "VIRUS-1": "病毒-a",
    "VIRUS-2": "病毒-b",
    "VIRUS-3": "病毒-c",
    "ANTIGEN_REAGENT": "抗原试剂",
    "ANTIGEN_REAGENT-1": "抗原试剂-横",
    "ANTIGEN_REAGENT-2": "抗原试剂-竖",
    "MASK": "口罩",
    "MASK-1": "口罩-1",
    "MASK-2": "口罩-2",
    "MASK-3": "口罩-3",
    "MASK-4": "口罩-4",
    "MASK-5": "口罩-5",
    "MASK-6": "口罩-6",
    "TEMPERATURE_MEASURING_GUN": "测温枪",
    "STETHOSCOPE": "听诊器",
    "DISINFECTANT": "消毒液",
    "NUMBER-0": "0",
    "NUMBER-1": "1",
    "NUMBER-2": "2",
    "NUMBER-3": "3",
    "NUMBER-4": "4",
    "NUMBER-5": "5",
    "NUMBER-6": "6",
    "NUMBER-7": "7",
    "NUMBER-8": "8",
    "NUMBER-9": "9",
    "LETTER-A": "A",
    "LETTER-B": "B",
    "LETTER-C": "C",
    "LETTER-D": "D",
    "LETTER-E": "E",
    "LETTER-F": "F",
    "LETTER-G": "G",
    "LETTER-H": "H",
    "LETTER-I": "I",
    "LETTER-J": "J",
    "LETTER-K": "K",
    "LETTER-L": "L",
    "LETTER-M": "M",
    "LETTER-N": "N",
    "LETTER-O": "O",
    "LETTER-P": "P",
    "LETTER-Q": "Q",
    "LETTER-R": "R",
    "LETTER-S": "S",
    "LETTER-T": "T",
    "LETTER-U": "U",
    "LETTER-V": "V",
    "LETTER-W": "W",
    "LETTER-X": "X",
    "LETTER-Y": "Y",
    "LETTER-Z": "Z",
    "EXCLAMATORY_MARK": "感叹号",
    "EXCLAMATORY_MARK-1": "感叹号-1",
    "EXCLAMATORY_MARK-2": "感叹号-2",
    "EXCLAMATORY_MARK-3": "感叹号-3",
    "YELLOW_PROGRESS_BAR": "黄色进度条",
    "YELLOW_PROGRESS_BAR-1": "黄色进度条-1",
    "YELLOW_PROGRESS_BAR-2": "黄色进度条-2",
    "YELLOW_PROGRESS_BAR-3": "黄色进度条-3",
    "PROGRESS_BAR": "进度条",
    "PROGRESS_BAR-1": "进度条30",
    "PROGRESS_BAR-2": "进度条60",
    "PROGRESS_BAR-3": "进度条100",
    "BLUE_PROGRESS_BAR": "蓝色进度条",
    "BLUE_PROGRESS_BAR-1": "蓝色进度条-1",
    "BLUE_PROGRESS_BAR-2": "蓝色进度条-2",
    "BLUE_PROGRESS_BAR-3": "蓝色进度条-3",
    "STEREOSCOPIC_QUESTION_MARK": "立体问号",
    "STEREOSCOPIC_QUESTION_MARK-1": "问号-灰",
    "STEREOSCOPIC_QUESTION_MARK-2": "问号-金",
    "BUBBLE_BOX": "气泡框",
    "BUBBLE_BOX-1": "气泡框-1",
    "BUBBLE_BOX-2": "气泡框-2",
    "VICTORY_FAIL": "胜利失败",
    "VICTORY_FAIL-1": "胜",
    "VICTORY_FAIL-2": "利",
    "VICTORY_FAIL-3": "失",
    "VICTORY_FAIL-4": "败",
    "REFERENCE_NO": "引用号",
    "REFERENCE_NO-1": "引用号-1",
    "REFERENCE_NO-2": "引用号-2",
    "RED_STRIPE": "红条",
    "GOLD_COIN1": "金币1",
    "BLUE_STRIPE": "蓝条",
    "LIFE": "生命",
    "QUADRANGULAR_STAR": "四角星",
    "QUESTION_MARK": "问号",
    "FIVE-POINTED_STAR": "五角星",
    "AIRSHIP": "小飞船1",
    "METEORITE": "陨星",
    "AIRCRAFT": "飞机",
    "AIRCRAFT-1": "飞机-1",
    "AIRCRAFT-2": "飞机-2",
    "AIRCRAFT-3": "飞机-3",
    "RETRO_AIRCRAFT": "复古飞机",
    "RETRO_AIRCRAFT-1": "复古飞机-1",
    "RETRO_AIRCRAFT-2": "复古飞机-2",
    "RETRO_AIRCRAFT-3": "复古飞机-3",
    "JEEP": "吉普车",
    "JEEP-1": "吉普车-1",
    "JEEP-2": "吉普车-2",
    "JEEP-3": "吉普车-3",
    "MOTORBOAT": "摩托艇",
    "MOTORBOAT-1": "摩托艇_侧视",
    "MOTORBOAT-2": "摩托艇_俯视",
    "MOTORBOAT-3": "摩托艇_后视",
    "MOTORBOAT-4": "摩托艇_正视",
    "SUBMARINE": "潜水艇",
    "SUBMARINE-1": "潜水艇_侧视",
    "SUBMARINE-2": "潜水艇_俯视",
    "SUBMARINE-3": "潜水艇_后视",
    "SUBMARINE-4": "潜水艇_正视",
    "SMALL_SAILBOAT": "小帆船1",
    "SMALL_SAILBOAT-1": "小帆船_侧视",
    "SMALL_SAILBOAT-2": "小帆船_俯视",
    "SMALL_SAILBOAT-3": "小帆船_后视",
    "SMALL_SAILBOAT-4": "小帆船_正视",
    "VAN": "小货车",
    "VAN-1": "小货车_侧视",
    "VAN-2": "小货车_俯视",
    "VAN-3": "小货车_后视",
    "VAN-4": "小货车_正视",
    "HELICOPTER": "直升机",
    "HELICOPTER-1": "直升机_侧视",
    "HELICOPTER-2": "直升机_俯视-1",
    "HELICOPTER-3": "直升机_俯视-2",
    "HELICOPTER-4": "直升机_俯视-3",
    "HELICOPTER-5": "直升机_后视",
    "HELICOPTER-6": "直升机_正视",
    "BICYCLE": "自行车-1",
    "FIRECRACKERS": "鞭炮",
    "FIRECRACKERS-1": "鞭炮-1",
    "FIRECRACKERS-2": "鞭炮-2",
    "FIRECRACKERS-3": "鞭炮-3",
    "COLORFUL_FLAGS": "彩旗",
    "COLORFUL_FLAGS-1": "彩旗-1",
    "COLORFUL_FLAGS-2": "彩旗-2",
    "HAPPY_KNOT": "福结",
    "HAPPY_KNOT-1": "福结-1",
    "HAPPY_KNOT-2": "福结-2",
    "HAPPY_KNOT-3": "福结-3",
    "HAPPY_KNOT-4": "福结-4",
    "HAPPY_KNOT-5": "福结-5",
    "HAPPY_KNOT-6": "福结-6",
    "RED_ENVELOPE_ANIMATION": "红包动画",
    "RED_ENVELOPE_ANIMATION-1": "红包动画-1",
    "RED_ENVELOPE_ANIMATION-2": "红包动画-2",
    "RED_ENVELOPE_ANIMATION-3": "红包动画-3",
    "RED_ENVELOPE_ANIMATION-4": "红包动画-4",
    "RED_ENVELOPE_ANIMATION-5": "红包动画-5",
    "RED_ENVELOPE_ANIMATION-6": "红包动画-6",
    "GOLD_COIN": "金币2",
    "GOLD_COIN-1": "金币-1",
    "GOLD_COIN-2": "金币-2",
    "GOLD_COIN-3": "金币-3",
    "ELK_CART": "麋鹿车",
    "ELK_CART-1": "麋鹿车-静止",
    "ELK_CART-2": "麋鹿车-行驶",
    "CALENDAR": "日历",
    "CHRISTMAS_CANDY": "圣诞彩杖糖",
    "CHRISTMAS_CANDY-1": "圣诞彩杖糖-1",
    "CHRISTMAS_CANDY-2": "圣诞彩杖糖-2",
    "CHRISTMAS_CANDY-3": "圣诞彩杖糖-3",
    "SANTA_CLAUS": "圣诞老人",
    "SANTA_CLAUS-1": "圣诞老人-1",
    "SANTA_CLAUS-2": "圣诞老人-2",
    "SANTA_CLAUS-3": "圣诞老人-3",
    "SANTA_CLAUS2": "圣诞老人2",
    "JINGLING_BELL": "圣诞铃铛",
    "JINGLING_BELL-1": "圣诞铃铛-1",
    "JINGLING_BELL-2": "圣诞铃铛-2",
    "JINGLING_BELL-3": "圣诞铃铛-金",
    "JINGLING_BELL-4": "圣诞铃铛-银",
    "CHRISTMAS_TREE": "圣诞树",
    "CHRISTMAS_TREE-1": "圣诞树-1",
    "CHRISTMAS_TREE-2": "圣诞树-2",
    "CHRISTMAS_TREE_DYNAMIC": "圣诞树-动态",
    "CHRISTMAS_TREE_DYNAMIC-1": "圣诞树-亮红灯",
    "CHRISTMAS_TREE_DYNAMIC-2": "圣诞树-亮黄灯",
    "CHRISTMAS_TREE_DYNAMIC-3": "圣诞树-全关灯",
    "CHRISTMAS_TREE_DYNAMIC-4": "圣诞树-全亮灯",
    "CHRISTMAS_STOCKING": "圣诞袜",
    "CHRISTMAS_STOCKING-1": "圣诞袜-红底绿星",
    "CHRISTMAS_STOCKING-2": "圣诞袜-绿底红星",
    "CHRISTMAS_STOCKING-3": "圣诞袜-条纹红",
    "CHRISTMAS_STOCKING-4": "圣诞袜-条纹绿",
    "CANDY": "糖果",
    "SOCKS": "袜子",
    "SMALL_WINDMILL": "小风车",
    "LITTLE_WOODEN_HORSE": "小木马",
    "SNOWFLAKE": "雪花",
    "SNOWMAN1": "雪人1",
    "SNOWMAN1-1": "雪人-初始",
    "SNOWMAN1-2": "雪人-戴帽子",
    "SNOWMAN1-3": "雪人-戴手套",
    "SNOWMAN1-4": "雪人-戴围巾",
    "SNOWMAN2": "雪人2",
    "SNOWMAN2-1": "雪人-1",
    "SNOWMAN2-2": "雪人-2",
    "SNOWMAN2-3": "雪人头",
    "REINDEER": "驯鹿",
    "REINDEER-1": "驯鹿-1",
    "REINDEER-2": "驯鹿-2",
    "REINDEER_CART": "驯鹿车",
    "REINDEER_CART-1": "驯鹿车-1",
    "REINDEER_CART-2": "驯鹿车-2",
    "ROUND_LANTERN": "圆灯笼",
    "ROUND_LANTERN-1": "圆灯笼-1",
    "ROUND_LANTERN-2": "圆灯笼-2",
    "LONG_LANTERN": "长灯笼",
    "LONG_LANTERN-1": "长灯笼-1",
    "LONG_LANTERN-2": "长灯笼-2",
    "PAPER_AIRPLANE": "纸飞机",
    "PAPER_AIRPLANE-1": "纸飞机-红",
    "PAPER_AIRPLANE-2": "纸飞机-黄",
    "PAPER_AIRPLANE-3": "纸飞机-蓝",
    "PAPER_AIRPLANE-4": "纸飞机-绿",
    "CHINESE_KNOT": "中国结",
    "CHINESE_KNOT-1": "中国结-1",
    "CHINESE_KNOT-2": "中国结-2",
    "SKIING_PEOPLE": "滑雪-人",
    "SKIING_PEOPLE-1": "滑雪-人-起跳1",
    "SKIING_PEOPLE-2": "滑雪-人-起跳2",
    "SKIING_PEOPLE-3": "滑雪-人-滑1",
    "SKIING_PEOPLE-4": "滑雪-人-滑2",
    "BOY": "男孩",
    "BOY-1": "男孩-1",
    "BOY-2": "男孩-2",
    "BOY-3": "男孩-3",
    "BOY-4": "男孩-4",
    "AVATAR_BOY": "男孩头像",
    "AVATAR_GIRL": "女孩头像",
    "GIRL": "女孩",
    "GIRL-1": "女孩-1",
    "GIRL-2": "女孩-2",
    "GIRL-3": "女孩-3",
    "GIRL-4": "女孩-4",
    "TURKEY": "火鸡",
    "GINGERBREAD_MAN": "姜饼人",
    "SKI_MARK": "滑雪痕",
    "SKI_MARK-1": "滑雪痕-1",
    "SKI_MARK-2": "滑雪痕-2",
    "SKI_MARK-3": "滑雪痕-3",
    "SKI_MARK-4": "滑雪痕-4",
    "BACKDROPS_2022_SKI-FIELD-1": "滑雪场-1",
    "BACKDROPS_2022_SKI-FIELD-2": "滑雪场-2",
    "BACKDROPS_2022_AIRPORT-RUNWAY": "机场跑道",
    "BACKDROPS_2022_INTERSECTION": "路口",
    "BACKDROPS_2022_SKY-1": "天空-1",
    "BACKDROPS_2022_SKY-2": "天空-2",
    "BACKDROPS_2022_SETTING-SUN-1": "夕阳-1",
    "BACKDROPS_2022_SETTING-SUN-2": "夕阳-2",
    "BACKDROPS_2022_SETTING-SUN-3": "夕阳-3",
    "BACKDROPS_2022_MARS": "火星",
    "BACKDROPS_2022_STARRY-SKY-1": "星空-1",
    "BACKDROPS_2022_STARRY-SKY-2": "星空-2",
    "BACKDROPS_2022_STARRY-SKY-3": "星空-3",
    "BACKDROPS_2022_MOON-1": "月球-1",
    "BACKDROPS_2022_MOON-2": "月球-2",
    "BACKDROPS_2022_SANTA-BACKGROUND": "圣诞老人背景",
    "BACKDROPS_2022_CHRISTMAS-THEME-BACKGROUND": "圣诞主题背景",
    "BACKDROPS_2022_SNOWMAN-BACKGROUND": "雪人背景",
    "WHITE-MOON": "白月亮",
    "GRASSLAND": "草地",
    "YELLOW-MOON": "黄月亮",
    "SKATEBOARD-1": "滑板-侧面",
    "SKATEBOARD-2": "滑板-底部",
    "SKATEBOARD-3": "滑板-顶部",
    "SKATEBOARD-4": "滑板-正面",
    "SKATEBOARD": "滑板",
    "CARTOON-ARTPLANES-1": "卡通飞机-正面",
    "CARTOON-ARTPLANES-2": "卡通飞机-底部",
    "CARTOON-ARTPLANES-3": "卡通飞机-右侧",
    "CARTOON-ARTPLANES-4": "卡通飞机-左侧",
    "CARTOON-ARTPLANES-5": "卡通飞机-顶部",
    "CARTOON-ARTPLANES-6": "卡通飞机-尾部",
    "CARTOON-ARTPLANES": "卡通飞机",
    "BICYCLE-GRAY-1": "自行车-灰-侧面",
    "BICYCLE-GRAY-2": "自行车-灰-顶部",
    "BICYCLE-GRAY-3": "自行车-灰-正面",
    "BICYCLE-GRAY-4": "自行车-灰-后面",
    "BICYCLE-GRAY": "自行车-灰",
    "BATS-1": "蝙蝠群-1",
    "BATS-2": "蝙蝠群-2",
    "BATS-3": "蝙蝠群-3",
    "BATS-4": "蝙蝠群-4",
    "BATS": "蝙蝠群",
    "CASTLE-1": "古堡-1",
    "CASTLE-2": "古堡-2",
    "CASTLE": "古堡",
    "JACK-o'-LANTERN-1": "南瓜灯1-1",
    "JACK-o'-LANTERN-2": "南瓜灯1-2",
    "JACK-o'-LANTERN-3": "南瓜灯1-3",
    "JACK-o'-LANTERN-4": "南瓜灯2",
    "JACK-o'-LANTERN-5": "南瓜灯3",
    "JACK-o'-LANTERN-6": "南瓜灯4",
    "JACK-o'-LANTERN": "南瓜灯",
    "WOODS-1": "树林-1",
    "WOODS-2": "树林-2",
    "WOODS": "树林",
    "DANCING-LION-RED-1": "醒狮-红-1",
    "DANCING-LION-RED-2": "醒狮-红-2",
    "DANCING-LION-RED-3": "醒狮-红-3",
    "DANCING-LION-RED-4": "醒狮-红-4",
    "DANCING-LION-RED-5": "醒狮-红-5",
    "DANCING-LION-RED-6": "醒狮-红-6",
    "DANCING-LION-RED": "醒狮-红",
    "DANCING-LION-BLUE-1": "醒狮-蓝-1",
    "DANCING-LION-BLUE-2": "醒狮-蓝-2",
    "DANCING-LION-BLUE-3": "醒狮-蓝-3",
    "DANCING-LION-BLUE": "醒狮-蓝",
    "DANCING-LION-GREEN-1": "醒狮-绿-1",
    "DANCING-LION-GREEN-2": "醒狮-绿-2",
    "DANCING-LION-GREEN-3": "醒狮-绿-3",
    "DANCING-LION-GREEN": "醒狮-绿",
    "SPECTRE-1": "幽灵-1",
    "SPECTRE-2": "幽灵-2",
    "SPECTRE-3": "幽灵-3",
    "SPECTRE-4": "幽灵-4",
    "SPECTRE": "幽灵",
    "SPIDER-1": "蜘蛛-1",
    "SPIDER-2": "蜘蛛-2",
    "SPIDER-3": "蜘蛛-3",
    "SPIDER": "蜘蛛",
    "GOLF-PEOPLE1-1": "高尔夫-人物1-1",
    "GOLF-PEOPLE1-2": "高尔夫-人物1-2",
    "GOLF-PEOPLE1-3": "高尔夫-人物1-3",
    "GOLF-PEOPLE1": "高尔夫-人物1",
    "GOLF-PEOPLE2-1": "高尔夫-人物2-1",
    "GOLF-PEOPLE2-2": "高尔夫-人物2-2",
    "GOLF-PEOPLE2-3": "高尔夫-人物2-3",
    "GOLF-PEOPLE2": "高尔夫-人物2",
    "GOLF": "高尔夫球",
    "GOLF-HOLES": "高尔夫球洞",
    "GOLF-SEATS": "高尔夫球座",
    "GREEN-LEAVES-1": "绿叶-1",
    "GREEN-LEAVES-2": "绿叶-2",
    "GREEN-LEAVES": "绿叶",
    "CACTUS-1": "仙人掌-1",
    "CACTUS-2": "仙人掌-2",
    "CACTUS": "仙人掌",
    "FLORETS-1": "小花-1",
    "FLORETS-2": "小花-2",
    "FLORETS-3": "小花-3",
    "FLORETS-4": "小花-4",
    "FLORETS-5": "小花-5",
    "FLORETS-6": "小花-6",
    "FLORETS-7": "小花-7",
    "FLORETS-8": "小花-8",
    "FLORETS": "小花"
  },
  "khm": {
    "gui.gui.project": "គម្រោង",
    "gui.gui.newItem": "គម្រោងថ្មី",
    "gui.gui.load": "បើកគម្រោង",
    "gui.gui.save": "រក្សាទុកគម្រោង",
    "gui.gui.saveAs": "រក្សាទុកជា",
    "gui.gui.learn": "កំពុងរៀន",
    "gui.gui.clearRecord": "លុបកំណត់ត្រា",
    "gui.gui.onlineLearn": "ឯកសារផ្លូវការ",
    "gui.gui.onlineForum": "វេទិកាតាមអ៊ីនធឺណិត",
    "gui.gui.videoTutorial": "ការបង្រៀនតាមវីដេអូ",
    "gui.gui.offlineLearn": "កម្មវិធីឧទាហរណ៍",
    "gui.menuBar.turboModeOff": "បិទម៉ូត Turbo",
    "gui.menuBar.turboModeOn": "បើកម៉ូត Turbo",
    "gui.menuBar.edit": "កែសម្រួល",
    "gui.menuBar.restoreSprite": "ស្តារតួអង្គឡើងវិញ",
    "gui.menuBar.restoreSound": "ស្តារសម្លេងឡើងវិញ",
    "gui.menuBar.restoreCostume": "ស្តាររូបរាងឡើងវិញ",
    "gui.menuBar.restoreBackdrop": "ស្តារផ្ទាំងខាងក្រោយឡើងវិញ",
    "gui.editorMind.restore": "ស្តារឡើងវិញ",
    "gui.turboMode.active": "ម៉ូត Turbo",
    "gui.gui.connectDevice": "ភ្ជាប់ជាមួយឧបករណ៍",
    "gui.gui.disconnectDevice": "ដកឧបករណ៍ចេញ",
    "gui.gui.installSerialportDriver": "ដំឡើងកម្មវិធីឈ្នាន់ច្រកសេរ៊ី",
    "gui.gui.openDM": "បើកកម្មវិធីគ្រប់គ្រងឧបករណ៍",
    "gui.gui.restoreSetting": "ស្តារការកំណត់ដំបូងនៃឧបករណ៍",
    "gui.gui.updateTips": "ធ្វើបច្ចុប្បន្នភាពព័ត៌មានខ្លឹម",
    "gui.gui.newVersion": "ជំនាន់ចុងក្រោយបំផុត",
    "gui.gui.downloadUpdate": "ទាញយកដើម្បីធ្វើបច្ចុប្បន្នភាព",
    "gui.gui.versionUpdate": "ធ្វើបច្ចុប្បន្នភាពជំនាន់",
    "gui.gui.isCheckMd5": "កំពុងផ្ទៀងផ្ទាត់ឯកសារ",
    "gui.gui.downloading": "Checking Updator",
    "gui.gui.updateError": "ធ្វើបច្ចុប្បន្នភាពមានកុំហុស",
    "gui.setting.feedbackMenu": "ការចូលរួមយោបល់",
    "gui.gui.arduino": "Offline",
    "gui.gui.scratch": "Online",
    "gui.setting.officialWebsit": "គេហទំព័រ​ផ្លូវការ",
    "gui.setting.officialQQGroup": "ក្រុមផ្លូវការ QQ",
    "gui.setting.wechatOfficialAccounts": "គណនីផ្លូវការ WeChat",
    "gui.setting.currentVersion": "ជំនាន់បច្ចុប្បន្ន",
    "gui.setting.checkForUpdate": "ពិនិត្យមើលដើម្បីធ្វើបច្ចុប្បន្នភាព",
    "gui.setting.remindWhenUpdate": "ទាញយកនៅពេលធ្វើបច្ចុប្បន្នភាព",
    "gui.setting.currentIsLatest": "ជំនាន់បច្ចុប្បន្នគឺជាជំនាន់ចុងក្រោយបំផុត",
    "gui.setting.latestVersion": "ជំនាន់ចុងក្រោយបំផុត",
    "gui.setting.download": "រំលងដើម្បីទាញយកគេហទំព័រ",
    "gui.setting.language": "ភាសា",
    "gui.setting.uploadCsv": "ផ្ញើចេញឯកសារប្រភេទ xlsx",
    "gui.setting.theme": "រចនាបទខាងក្រៅ",
    "gui.setting.feedback": "ទំនាក់ទំនង",
    "gui.setting.email": "អ៊ីមែល",
    "gui.setting.opinionFeedback": "ការចូលរួមយោបល់",
    "gui.variableMc.variableName": "ឈ្មោះអថេរ",
    "gui.variableMc.variableOff": "បោះបង់",
    "gui.variableMc.variableOn": "បញ្ជាក់",
    "gui.variableMc.newVariable": "អថេរថ្មី",
    "gui.setting.helpUsTranslate": "ជួយយើងបកប្រែ",
    "gui.setting.checkUpdate": "ពិនិត្យបច្ចុប្បន្នភាព",
    "gui.setting.ok": "អូខេ",
    "gui.setting.languageSetting": "ភាសា",
    "gui.setting.themeSetting": "ប្រធានបទ",
    "gui.setting.versionUpdate": "ធ្វើបច្ចុប្បន្នភាពជំនាន់",
    "gui.setting.connectUsTitle": "ទាក់ទង​មក​ពួក​យើង",
    "gui.setting.uploadAttachment": "ផ្ញើចេញឯកសារភ្ជាប់",
    "gui.setting.displayTitle": "កំណត់ការបង្ហាញ",
    "gui.setting.fontSizeLarge": "ធំ",
    "gui.setting.fontSizeMiddle": "កណ្តាល",
    "gui.setting.fontSizeSmall": "តូច",
    "gui.setting.onlineRepair": "ឧបករណ៍ជួសជុលតាមអ៊ីនធឺណិត, ចុចដើម្បីបើក>",
    "gui.gui.blocks": "ប្លុក",
    "gui.gui.costumesTab": "រូបរាង",
    "gui.gui.soundsTab": "សម្លេង",
    "gui.gui.backdropsTab": "ផ្ទាំងខាងក្រោយ",
    "gui.gui.addExtension": "កម្មវិធីបន្ថែម",
    "gui.costumeTab.addCostumeFromLibrary": "ជ្រើសរើសតួអង្គ",
    "gui.costumeLibrary.chooseACostume": "ជ្រើសរើសរូបរាង",
    "gui.costumeTab.addBackdropFromLibrary": "ជ្រើសរើសផ្ទាំងខាងក្រោយ",
    "gui.costumeTab.addBlankCostume": "កម្មវិធីគូរ",
    "gui.costumeTab.addSurpriseCostume": "ចៃដន្យ",
    "gui.costumeTab.addFileBackdrop": "ជ្រើសរើសផ្ទាំងខាងក្រោយពីកុំព្យុទ័រ",
    "gui.costumeTab.addFileCostume": "ជ្រើសរើសរូបរាងពីកុំព្យុទ័រ",
    "gui.costumeTab.addCameraCostume": "ម៉ាស៊ីនថត",
    "gui.soundEditor.trim": "កាត់តម្រឹម",
    "gui.soundEditor.stop": "បញ្ឈប់",
    "gui.soundEditor.sound": "សម្លេង",
    "gui.soundEditor.play": "លេង",
    "gui.soundEditor.save": "រក្សាទុក",
    "gui.soundEditor.undo": "មិនធ្វើវិញ",
    "gui.soundEditor.redo": "ធ្វើឡើងវិញ",
    "gui.soundEditor.faster": "លឿនជាងមុន",
    "gui.soundEditor.slower": "យឺតជាងមុន",
    "gui.soundEditor.echo": "ជះត្រឡប់",
    "gui.soundEditor.robot": "រ៉ូបូ",
    "gui.soundEditor.louder": "លឺជាងមុន",
    "gui.soundEditor.softer": "ខ្សោយជាងមុន",
    "gui.soundEditor.reverse": "បញ្ជ្រាស់",
    "gui.soundTab.recordSound": "ថត",
    "gui.soundTab.addSoundFromLibrary": "ជ្រើសរើសសម្លេង",
    "gui.soundTab.surpriseSound": "ចៃដន្យ",
    "gui.soundTab.fileUploadSound": "ជ្រើសរើសសម្លេងពីកុំព្យុទ័រ",
    "gui.soundTab.addSound": "បន្ថែមសម្លេង",
    "gui.controls.stop": "ឈប់",
    "gui.controls.go": "ទៅ",
    "gui.gui.startPython": "ចាប់ផ្តើមជាមួយ Python",
    "gui.gui.stopPython": "បញ្ឈប់ Python",
    "gui.controls.fullScreenControl": "ប្តូរជាអេក្រង់ពេញ",
    "gui.gui.stageSizeLarge": "ប្តូរជាអេក្រង់ធំ",
    "gui.gui.stageSizeSmall": "ប្តូរជាអេក្រង់តូច",
    "gui.gui.stageSizeNostage": "ប្តូរទៅជាគ្មានឆាក",
    "gui.gui.stageSizeFull": "ប្តូរទៅជាអេក្រង់ពេញ",
    "gui.stageHeader.stageSizeUnFull": "ចាកចេញពីអេក្រង់ពេញ",
    "gui.sprite.sprite": "តួអង្គ",
    "gui.SpriteInfo.show": "បង្ហាញ",
    "gui.SpriteInfo.size": "ទំហំ",
    "gui.sprite.direction": "ទិសដៅ",
    "gui.sprite.rotation": "បង្វិល",
    "gui.directionPicker.rotationStyles.allAround": "នៅ​ជុំវិញ​ទាំង​អស់",
    "gui.directionPicker.rotationStyles.leftRight": "ឆ្វេង/ស្ដាំ",
    "gui.directionPicker.rotationStyles.dontRotate": "កុំបង្វិល",
    "gui.spriteSelectorItem.contextMenuDuplicate": "ថតចម្លង",
    "gui.spriteSelectorItem.contextMenuDelete": "លុបចោល",
    "gui.spriteSelectorItem.contextMenuExport": "នាំចេញ",
    "gui.sprite.addSpriteFromLibrary": "បណ្ណាល័យតួអង្គ",
    "gui.spriteSelector.addSpriteFromPaint": "កម្មវិធីគូរ",
    "gui.spriteSelector.addSpriteFromSurprise": "ចៃដន្យ",
    "gui.spriteSelector.addSpriteFromFile": "ជ្រើសរើសតួអង្គពីកុំព្យុទ័រ",
    "gui.sprite.addSpriteFromCamera": "ម៉ាស៊ីនថត",
    "gui.stageSelector.stage": "ឆាក",
    "gui.stageSelector.backdrops": "ផ្ទាំងខាងក្រោយ",
    "gui.stage.addBackdropFromLibrary": "ជ្រើសរើសផ្ទាំងខាងក្រោយ",
    "gui.stageSelector.addBackdropFromPaint": "កម្មវិធីគូរ",
    "gui.stageSelector.addBackdropFromSurprise": "ចៃដន្យ",
    "gui.stageSelector.addBackdropFromFile": "ជ្រើសរើសផ្ទាំងខាងក្រោយពីកុំព្យុទ័រ",
    "gui.stage.addBackdropFromCamera": "ម៉ាស៊ីនថត",
    "gui.modal.back": "ត្រឡប់ក្រោយ",
    "gui.extension.kit": "ឈុតឧបករណ៍",
    "gui.extension.kitTitle": "ជ្រើសរើសឈុតឧបករណ៍",
    "gui.extension.board": "ផ្ទាំងបញ្ជា",
    "gui.extension.boardTitle": "ជ្រើសរើស​ផ្ទាំងបញ្ជា",
    "gui.extension.spreadBoard": "ឧបករណ៍បន្តុប",
    "gui.extension.spreadBoardTitle": "ជ្រើសរើសឧបករណ៍បន្តុប",
    "gui.extension.sensor": "ឧបករណ៍ចាប់សញ្ញា",
    "gui.extension.sensorTitle": "ជ្រើសរើស​​ឧបករណ៍ចាប់សញ្ញា",
    "gui.extension.actuator": "ម៉ូឌុលសកម្មភាព",
    "gui.extension.actuatorTitle": "ជ្រើសរើសម៉ូឌុលសកម្មភាព",
    "gui.extension.communicationModule": "ម៉ូឌុលទំនាក់ទំនង",
    "gui.extension.communicationModuleTitle": "ជ្រើសរើសម៉ូឌុលទំនាក់ទំនង",
    "gui.extension.display": "ផ្ទាំងបង្ហាញ",
    "gui.extension.displayTitle": "ជ្រើសរើសផ្ទាំងបង្ហាញ",
    "gui.extension.function": "មុខងារ",
    "gui.extension.functionTitle": "ជ្រើសរើសមុខងារ",
    "gui.extension.internetService": "អ៊ីនធឺណិត",
    "gui.extension.internetServiceTitle": "ជ្រើសរើសអ៊ីនធឺណិត",
    "gui.extension.thirdExpansion": "បន្ថែមដោយអ្នកប្រើប្រាស់",
    "gui.extension.thirdExpansionTitle": "ជ្រើសរើសកម្មវិធីបន្ថែមដោយអ្នកប្រើប្រាស់",
    "gui.extension.arduinContentLabel": "ជ្រើសរើសឧបករណ៍",
    "gui.extension.unavailable": "មិនមាន",
    "gui.extension.thirdModuleSearch": "ស្វែងរកឬបញ្ជូល URL​ របស់គម្រោង",
    "gui.library.filterPlaceholder": "ស្វែងរក",
    "gui.libraryTags.all": "ទាំងអស់",
    "gui.libraryTags.animals": "សត្វ",
    "gui.libraryTags.dance": "រាំ",
    "gui.libraryTags.effects": "បែបផែន",
    "gui.libraryTags.fantasy": "ស្រមើស្រមៃ",
    "gui.libraryTags.fashion": "ម៉ូត",
    "gui.libraryTags.food": "អាហារ",
    "gui.libraryTags.indoors": "ខាងក្នុង",
    "gui.libraryTags.loops": "រង្វិលជុំ",
    "gui.libraryTags.music": "តន្រ្តី",
    "gui.libraryTags.notes": "កំណត់សម្គាល់",
    "gui.libraryTags.outdoors": "ខាងក្រៅ",
    "gui.libraryTags.patterns": "លំនាំ",
    "gui.libraryTags.people": "មនុស្ស",
    "gui.libraryTags.percussion": "សម្លេងវាយ",
    "gui.libraryTags.space": "លំហ",
    "gui.libraryTags.sports": "កីឡា",
    "gui.libraryTags.underwater": "ក្នុងទឹក",
    "gui.libraryTags.voice": "សម្លេង",
    "gui.libraryTags.wacky": "សម្លេងចម្លែកៗ",
    "gui.libraryTags.animation": "ការធ្វើចលនា",
    "gui.libraryTags.art": "សិល្បៈ",
    "gui.libraryTags.games": "ហ្គេម",
    "gui.libraryTags.stories": "រឿង",
    "gui.libraryTags.letters": "អក្សរ",
    "gui.extension.backdropLib": "ជ្រើសរើសផ្ទាំងខាងក្រោយ",
    "gui.soundLibrary.chooseASound": "ជ្រើសរើសសម្លេង",
    "gui.SpriteInfo.spritePlaceholder": "ឈ្មោះ",
    "gui.extension.spriteLib": "ជ្រើសតួអង្គ",
    "gui.gui.unselectedSerialport": "មិនមានឧបករណ៍តភ្ជាប់",
    "gui.gui.unConnectedDev": "មិនមានឧបករណ៍តភ្ជាប់",
    "gui.gui.pythonMode": "ម៉ូត Python",
    "gui.gui.burnFirmware": "ដុតបញ្ចូលកម្មវិធី Firmware ម្តងទៀត",
    "gui.gui.burnFirmwareError": "កំហុសពេលដុតបញ្ចូលកម្មវិធី Firmware",
    "gui.gui.connected": "បានភ្ជាប់",
    "gui.gui.failedConnected": "បរាជ័យក្នុងការភ្ជាប់",
    "gui.gui.connecting": "កំពុងភ្ជាប់...",
    "gui.cards.all-how-tos": "សេចក្តីណែនាំទាំងអស់",
    "gui.cards.how-tos": "ការបង្រៀន",
    "gui.cards.remove": "យកចេញ",
    "gui.cards.more-things-to-try": "ព្យាយាមបន្ថែមទៀត!",
    "gui.cards.see-more": "មើល​បន្ថែម​ទៀត",
    "gui.loader.message1": "កំពុងបង្កើតប្លុក",
    "gui.loader.message2": "កំពុងទាញយកតួអង្គ ...",
    "gui.loader.message3": "កំពុងទាញយកសម្លេង ...",
    "gui.loader.message4": "កំពុងទាញយកកម្មវិធីបន្ថែម…",
    "gui.loader.message5": "ឃ្វាល Mind+ …",
    "gui.loader.message6": "កំពុងបញ្ជូន Nanos ...",
    "gui.loader.message7": "កំពុងបំប៉ោង Gobos …",
    "gui.loader.message8": "កំពុងរៀបចំសញ្ញាអារម្មណ៍ ...",
    "gui.loader.headline": "កំពុងទាញយកគម្រោង",
    "gui.cameraModal.cameraModalTitle": "ថតរូប",
    "gui.cameraModal.loadingCameraMessage": "កំពុងទាញយកម៉ាស៊ីនថត",
    "gui.cameraModal.permissionRequest": "យើងត្រូវការការអនុញ្ញាតពីអ្នកដើម្បីប្រើប្រាស់ម៉ាស៊ីនថត",
    "gui.cameraModal.retakePhoto": "ថតរូបម្តងទៀត",
    "gui.cameraModal.save": "រក្សាទុក",
    "gui.cameraModal.takePhoto": "ថតរូប",
    "gui.cameraModal.loadingCaption": "កំពុងទាញយក",
    "gui.cameraModal.enableCameraCaption": "បើកម៉ាស៊ីនថត",
    "gui.recordModal.title": "ថតសម្លេង",
    "gui.recordStep.recordByClickBtn": "ចុចប៊ូតុងខាងក្រោមដើម្បីចាប់ផ្តើមការថត",
    "gui.recordStep.permissionMicrophone": "យើងត្រូវការការអនុញ្ញាតពីអ្នកដើម្បីប្រើប្រាស់មីក្រូហ្វូន",
    "gui.recordStep.record": "ថត",
    "gui.recordStep.stopRecord": "បញ្ឈប់ការថត",
    "gui.playbackStep.stopMsg": "បញ្ឈប់",
    "gui.playbackStep.playMsg": "លេង",
    "gui.playbackStep.loadingMsg": "កំពុងទាញយក...",
    "gui.playbackStep.saveMsg": "រក្សារទុក",
    "gui.playbackStep.reRecordMsg": "ថតសារជាថ្មី",
    "gui.webglModal.label": "កម្មវិធីអ៊ីនធឺណិតរបស់អ្នកមិនគាំទ្រ WebGL ទេ",
    "gui.webglModal.descriptionMind": "សូមអភ័យទោស វាហាក់ដូចជាកុំព្យូទ័ររបស់អ្នកត្រូវការ {webGlLink} ។ បច្ចេកវិទ្យានេះចាំបាច់សម្រាប់ Mind + ដំណើរការ។ {updateGpuDriver}",
    "gui.webglModal.webgllink": "មិនគាំទ្រ WebGL",
    "gui.webglModal.updateGpuDriver": "សូមព្យាយាមធ្វើបច្ចុប្បន្នភាពកម្មវិធីឈ្នាន់នៃកាតក្រាហ្វិក",
    "gui.webglModal.ok": "អូខេ",
    "gui.extension.scratchExampleLib": "បណ្ណាល័យឧទាហណ៏ Scratch",
    "gui.extension.pythonExampleLib": "បណ្ណាល័យឧទាហណ៏ Python",
    "gui.extension.arduinoExampleLib": "បណ្ណាល័យឧទាហណ៏ Arduino",
    "gui.prompt.cancel": "បោះបង់",
    "gui.prompt.ok": "អូខេ",
    "gui.extension.makeBlock": "បង្កើតប្លុក",
    "gui.customProcedures.addAnInputNumberText": "បន្ថែមអញ្ញាតចូល",
    "gui.customProcedures.addAnInputBoolean": "បន្ថែមអញ្ញាតចូល",
    "gui.customProcedures.numberTextType": "លេខឬអត្ថបទ",
    "gui.customProcedures.textType": "អត្ថបទ",
    "gui.customProcedures.numberType": "លេខ",
    "gui.customProcedures.booleanType": "ប៊ូលីន",
    "gui.customProcedures.addALabel": "បន្ថែមស្លាកសញ្ញា",
    "gui.customProcedures.runWithoutScreenRefresh": "ដំណើរការដោយមិនត្រូវការលោតទំព័រជាថ្មី",
    "gui.customProcedures.cancel": "បោះបង់",
    "gui.customProcedures.ok": "អូខេ",
    "gui.extension.vortex.name": "Vortex",
    "gui.extension.vortex.description": "Vortex គឺជារ៉ូបូដែលបង្កើតឡើងសម្រាប់ការអប់រំពីក្រុមហ៊ុន DFRobot",
    "gui.extension.romeo.name": "Romeo",
    "gui.extension.romeo.description": "រ៉ូបូសម្រាប់ការអប់រំ Explorer D1 របស់ក្រុមហ៊ុន DFRobot",
    "gui.extension.arduinounoR3.name": "ArduinounoR3",
    "gui.extension.arduinounoR3.description": "Wuhan Maker Course បង្កើតឈុតឧបករណ៍ដោយប្រើផ្ទាំងបញ្ជាប្រភេទ Arduino Uno",
    "gui.extension.maxbot.name": "Max:bot",
    "gui.extension.maxbot.description": "Max:bot គឺជារ៉ូបូដែលប្រើបន្ទះបញ្ជា micro:bit",
    "gui.extension.max.name": "MAX",
    "gui.extension.max.description": "MAX(ROB013) គឺជារ៉ូបូដែលប្រើ Arduino",
    "gui.extension.motorbit.name": "motor:bit",
    "gui.extension.motorbit.description": "Motor:bit គឺជាផ្ទាំងបន្ថែមសម្រាប់បញ្ជា ម៉ូទ័រនិងស៊ែវ៊ូ",
    "gui.extension.maqueen.name": "Maqueen",
    "gui.extension.maqueen.description": "micro:Maqueen គឺជារ៉ូបូដែលប្រើបន្ទះបញ្ជា micro:bit",
    "gui.extension.microbit.name": "micro:bit",
    "gui.extension.microbit.description": "ភ្ជាប់គម្រោងរបស់អ្នកជាមួយពិភពរូបពិត",
    "gui.extension.spread.name": "កម្មវីធីបន្ថែម​ mphyton",
    "gui.extension.microTouch.name": "សោប៉ះ",
    "gui.extension.microNaturalScience.name": "micro:naturalScience",
    "gui.extension.microTouch.description": "មានរួមបញ្ជូលនូវ ក្តាចុច 4×4  ផ្ទាំងបន្ថែមសម្រាប់ម៉ូទ័រស៊ែវ៊ូ",
    "gui.extension.microNaturalScience.description": "ផ្ទាំងបន្ថែមរបស់ Micro:bit មានរូមបញ្ចូលនូវឧបករណ៍ចាប់សញ្ញាជាច្រើន",
    "gui.extension.microIoT.name": "micro:IoT",
    "gui.extension.microIoT.description": "ផ្ទាំងបន្ថែមរបស់ Micro:bit មានរូមបញ្ចូលនូវ ផ្ទាំងបង្ហាញ និង អ៊ីនធឺណិតនៃវត្ថុ",
    "gui.extension.spread.description": "ផ្ទាំងបន្ថែមសម្រាប់​ mpython",
    "gui.extension.spreadmaqueen.description": "កម្រិតចូលនៃ minicar ផ្អែកលើជំនាន់បញ្ជា",
    "gui.extension.maqueenPlus.name": "Maqueen Plus (ចិន)",
    "gui.extension.maqueenPlus.description": "រ៉ូបូសម្រាប់ការអប់រំកម្រឹតខ្ពស់ដែលប្រើបានជាមួយ micro:bit និង mPython",
    "gui.extension.iot.name": "អ៊ីនធឺណិតនៃវត្ថុ",
    "gui.extension.iot.description": "ការបោះពុម្ភបន្ថែមដោយផ្អែកលើអ៊ីនធឺណិតនៃវត្ថុ",
    "gui.extension.leonardo.name": "Leonardo",
    "gui.extension.leonardo.description": "ឧបករណ៍គ្រប់គ្រងដោយ​ Leonardo",
    "gui.extension.arduinouno.name": "Arduino Uno",
    "gui.extension.arduinouno.description": "ម៉ូឌុលផ្ទាំងបញ្ជាគោល Arduino Uno",
    "gui.extension.arduinonano.name": "Arduino Nano",
    "gui.extension.arduinonano.description": "ឧបករណ៍គ្រប់គ្រងដោយ​ Arduino Nano",
    "gui.extension.mpython.name": "handpy",
    "gui.extension.mpython.description": "ផ្ទាំងបញ្ជាគោលផ្អែកលើ ESP32",
    "gui.extension.mega2560.name": "Mega2560",
    "gui.extension.mega2560.description": "ឧបករណ៍គ្រប់គ្រងដោយ Mega2560",
    "gui.extension.dfr0299.name": "DFPlayer ម៉ូឌុល MP3 ",
    "gui.extension.dfr0299.description": "ម៉ូឌុលចាក់ MP3",
    "gui.extension.ser0006.name": "មីក្រូស៊ែវ៊ូ",
    "gui.extension.ser0006.description": "មីក្រូស៊ែវ៊ូ",
    "gui.extension.dfr0523.name": "ម៉ូទ័របូម Peristaltic",
    "gui.extension.dfr0523.description": "បញ្ជូនសារធាតុរាវដោយស្នប់ប្រភេទ ច្របាច់និងព្រលែងឆ្លាស់គ្នា",
    "gui.extension.dfr0017.name": "ម៉ូឌុលរ៉ឺលេ",
    "gui.extension.dfr0017.description": "ឧបករណ៍គ្រប់គ្រងចរន្តធំ",
    "gui.extension.steppermotor.name": "ម៉ូទ័រជំហាន",
    "gui.extension.steppermotor.description": "ដឹងពីការគ្រប់គ្រងទីតាំងបានត្រឹមត្រូវដោយការគ្រប់គ្រងជីពចរ",
    "gui.extension.dfr0534.name": "ម៉ូឌុល MP3 សេរី",
    "gui.extension.dfr0534.description": "ម៉ូឌុលសំលេង MP3 សេរី",
    "gui.extension.servo360.name": "360° មីក្រូស៊ែវ៊ូ",
    "gui.extension.servo360.description": "គ្រប់គ្រងល្បឿននិងទិសដៅ",
    "gui.extension.tel0118.name": "ម៉ូឌុល OBLOQ IoT",
    "gui.extension.tel0118.description": "ឧបករណ៍បំលែង Wi-Fi ទៅ សេរ៊ី",
    "gui.extension.dfr0095.name": "ម៉ូឌុលបញ្ជូនពន្លឺអាំងហ្វ្រាក្រហម",
    "gui.extension.dfr0095.description": "បញ្ជូនសញ្ញាអ៊ីនហ្វ្រាក្រហមស្តង់ដារ 38KHz",
    "gui.extension.dfr0094.name": "ម៉ូឌុលទទួលពន្លឺអាំងហ្វ្រាក្រហម",
    "gui.extension.dfr0094.description": "ទទួលសញ្ញាអ៊ីនហ្វ្រាក្រហមស្តង់ដារ 38KHz",
    "gui.extension.midi.name": "MIDI",
    "gui.extension.midi.description": "ម៉ាស៊ីនចាក់ MIDI ",
    "gui.extension.tel0094.name": "ម៉ូឌុលទទួលសញ្ញា GPS",
    "gui.extension.tel0094.description": "ម៉ូឌុលទទួលសញ្ញា GPS",
    "gui.extension.tel0026.name": "ម៉ូឌុលប៊្លូធូស",
    "gui.extension.tel0026.description": "ម៉ូឌុលប៊្លូធូសសេរ៊ី",
    "gui.extension.dfr0486.name": "អេក្រង់ OLED-12864",
    "gui.extension.dfr0486.description": "ម៉ូឌុលអេក្រង់ I2C OLED-12864",
    "gui.extension.fit0352.name": "អំពូលបន្ទះ LED WS2812 RGB",
    "gui.extension.fit0352.description": "ត្រួតពិនិត្យម៉ូឌុលដែលមានផ្គុំពីអំពូលបន្ទះ LED WS2812",
    "gui.extension.dfr0063.name": "អេក្រង់ LCD1602",
    "gui.extension.dfr0063.description": "ម៉ូឌុល LCD ដែលអាចបង្ហាញ 2 ជួរ និង 16 ព្យញ្ជនៈនៅក្នុងមួយជួរៗ",
    "gui.extension.dfr0021.name": "ម៉ូឌុលពន្លឺ LED ឌីជីថល",
    "gui.extension.dfr0021.description": "ម៉ូឌុល LED ដែលមានពណ៌ ស,​ ក្រហម, បៃតង និងខៀវ",
    "gui.extension.tm1650.name": "TM1650​ បំពង់លេខបួនខ្ទង់",
    "gui.extension.tm1650.description": "បំពង់លេខបួនខ្ទង់",
    "gui.extension.matrix8_8.name": "MAX7219 ឧបករណ៍បញ្ជាម៉ាទ្រីសទំហំ 8x8",
    "gui.extension.matrix8_8.description": "ម៉ូឌុលម៉ាទ្រីស LED ទំហំ 8x8",
    "gui.extension.dfr0522.name": "ផ្ទាំងម៉ាទ្រីស LED RGB ទំហំ 8x16 ",
    "gui.extension.dfr0522.description": "បង្ហាញរូបភាព,លេខ... អាចធ្វើការបង្ហាញបែបរំកិល និងតាមការកំណត់ដោយអ្នកប្រើ ",
    "gui.extension.music.name": "តន្ត្រី",
    "gui.extension.music.description": "លេងឧបករណ៍និងស្គរ",
    "gui.extension.pen.name": "ប៊ិច",
    "gui.extension.pen.description": "គូសជាមួយតួអង្គ ",
    "gui.extension.video-sensing.name": "ចាប់សញ្ញាវីដេអូ",
    "gui.extension.video-sensing.description": "ឧបករណ៍ចាប់ចលនាដោយប្រើម៉ាស៊ីនថត",
    "gui.extension.google-translate.name": "បកប្រែ",
    "gui.extension.google-translate.description": "បកប្រែអត្ថបទទៅជាភាសាជាច្រើន",
    "gui.extension.text-speech.name": "អត្ថបទទៅជាសម្លេង",
    "gui.extension.text-speech.description": "ធ្វើឱ្យគម្រោងរបស់អ្នកចេះនិយាយ",
    "gui.extension.softSerialport.name": "ច្រកសេរ៊ីប្រើកម្មវិធី",
    "gui.extension.softSerialport.description": "ប្រើច្រក I/O ដើម្បីធ្វើត្រាប់មុខងារទំនាក់ទំនងបែបសេរី",
    "gui.extension.IICScan.name": "ស្កេន I2C",
    "gui.extension.IICScan.description": "ស្វែងរកអាសយដ្ឋានឧបករណ៍ទាំងអស់ដែលភ្ជាប់ទៅនឹងច្រកចេញចូល I2C",
    "gui.extension.bluetooth.name": "ប៊្លូធូស",
    "gui.extension.bluetooth.description": "ឧបករណ៍ប៊្លូធូស",
    "gui.extension.goble.name": "GoBle",
    "gui.extension.goble.description": "កម្មវិធី GoBle អាចជួយអ្នកគ្រប់គ្រងផ្ទាំងបញ្ជាគោលតាមរយៈទូរស័ព្ទឆ្លាតវៃ",
    "gui.extension.eeprom.name": "EEPROM",
    "gui.extension.eeprom.description": "ប្រើវាដើម្បីអានឬសរសេរ និងរក្សាទុកទិន្នន័យជាមួយ eeprom",
    "gui.extension.interrupt.name": "រំខាន",
    "gui.extension.interrupt.description": "ជើងរំខាន",
    "gui.extension.sdcard.name": "ម៉ូឌុលសំរាប់អានកាត SD",
    "gui.extension.sdcard.description": "ម៉ូឌុលសំរាប់អានកាត MicroSD",
    "gui.extension.multithread.name": "MultiThread",
    "gui.extension.multithread.description": "អនុញ្ញាតឱ្យមានកម្មវិធីផ្សេងៗទៀត និងកម្មវិធីគោលដំណើរការដំណាលគ្នា",
    "gui.extension.speech.name": "សម្តី",
    "gui.extension.speech.description": "និយាយទៅកាន់គម្រោងរបស់អ្នក",
    "gui.extension.sen0001.name": "ឧបករណ៍ចាប់សញ្ញាប្រើសម្លេងអ៊ុលត្រា",
    "gui.extension.sen0001.description": "ចម្ងាយដែលអាចវាស់បានត្រឹមត្រូវស្ថិតនៅចន្លោះ​ 2~800cm ",
    "gui.extension.dfr0023.name": "LM35 ឧបករណ៍វាស់សីតុណ្ហភាពប្រភេទអាណាឡូក",
    "gui.extension.dfr0023.description": "LM35 ជាឧបករណ៍វាស់សីតុណ្ហាភាពបែបអេឡិចត្រូនិច និងអាចវាស់បានសីតុណ្ហាភាពនៅចន្លោះ 0~100℃",
    "gui.extension.dhtTHSensor.name": "DHT11/22 ឧបករណ៍វាស់សីតុណ្ហភាពនិងសំណើម ",
    "gui.extension.dhtTHSensor.description": "ចាប់យកសីតុណ្ហភាពបរិយាកាសនិងសំណើម",
    "gui.extension.dsTSensor.name": "DS18B20 ឧបករណ៍វាស់សីតុណ្ហភាព",
    "gui.extension.dsTSensor.description": "ចាប់យកសីតុណ្ហភាពនៅជុំវិញដែលនៅចន្លោះ 55~+125℃ ",
    "gui.extension.sen0203.name": "ឧបករណ៍វាស់ចង្វាក់បេះដូង",
    "gui.extension.sen0203.description": "ឧបករណ៍វាស់ចង្វាក់បេះដូងខ្នាតតូច ដែលអាចផ្តល់លទ្ធផលជា ជីពចរអាណាឡូក និងរលកឌីជីថល",
    "gui.extension.sen0177.name": "PM2.5 ឧបករណ៍វាស់គុណភាពខ្យល់ប្រើពន្លឺឡាស៊ែរ",
    "gui.extension.sen0177.description": "វាស់តម្លៃពី PM1, PM2.5 និង PM10។ បញ្ជូនទិន្នន័យដែលវាស់បានតាមរយះច្រកសេរ៊ី",
    "gui.extension.sen0014.name": "GP2Y0A21 ឧបករណ៍វាស់ចម្ងាយប្រើពន្លឺអាំងហ្វ្រាក្រហម ",
    "gui.extension.sen0014.description": "ឧបករណ៏ពន្លឺអាំងហ្វ្រាក្រហមផ្អែកលើ GP2Y0A21 ដែលមានទំហំវាស់ 10~80 សម",
    "gui.extension.sen0204.name": "ឧបករណ៍វាស់កម្ពស់វត្ថុរាវដោយប្រយោល",
    "gui.extension.sen0204.description": "ចាប់យកកម្ពស់វត្ថុរាវ ប៉ុន្តែមិនបានប៉ះវត្ថុនោះទេ",
    "gui.extension.sen0160.name": "Hx711 ឧបករណ៍ថ្លឹងទំងន់ ",
    "gui.extension.sen0160.description": "វាស់ដោយប្រើឧបករណ៍ថ្លឹងទំងន់ Hx711",
    "gui.extension.sen0161.name": "ឧបករណ៍វាស់ pH ប្រភេទអាណាឡូក",
    "gui.extension.sen0161.description": "វាស់ pH​ នៃវត្ថុរាវ ជាមួយនឹងការផ្គត់ផ្គង់ថាមពល 5V",
    "gui.extension.sen0161-v2.name": "ឧបករណ៍វាស់ pH ប្រភេទអាណាឡូក (V2)",
    "gui.extension.sen0161-v2.description": "វាស់ pH​ នៃវត្ថុរាវ ជាមួយនឹងការផ្គត់ផ្គង់ថាមពលនៅចន្លោះ 3.3~5.5V",
    "gui.extension.sen0244.name": "ឧបករណ៍វាស់ TDS ប្រភេទអាណាឡូក",
    "gui.extension.sen0244.description": "វាស់ TDS (សូលុយស្យុងរលាយសរុប) នៃវត្ថុរាវដែលបង្ហាញពីភាពស្អាតនៃទឹក",
    "gui.extension.sen0165.name": "ឧបករណ៍វាស់ ORP ប្រភេទអាណាឡូក",
    "gui.extension.sen0165.description": "វាស់ ORP (សក្តានុពលក្នុងការកាត់បន្ថយអុកស៊ីតកម្ម) នៃវត្ថុរាវដើម្បីសាកល្បងគុណភាពទឹក",
    "gui.extension.sen0237.name": "ឧបករណ៍វាស់ អុកស៊ីសែនរលាយ ប្រភេទអាណាឡូក",
    "gui.extension.sen0237.description": "វាស់ បរិមាណនៃអុកស៊ីសែនរលាយក្នុងវត្ថុរាវ ដើម្បីវាយតម្លៃគុណភាពទឹក",
    "gui.extension.dfr0300-H.name": "ឧបករណ៍វាស់ កម្រឹតចម្លងចរន្តអគ្គិសនី ប្រភេទអាណាឡូក",
    "gui.extension.dfr0300-H.description": "វាស់ កម្រឹតចម្លងចរន្តអគ្គិសនីនៃវត្ថុរាវ ដើម្បីវាយតម្លៃគុណភាពទឹក",
    "gui.extension.dfr0300.name": "ឧបករណ៍វាស់ កម្រឹតចម្លងចរន្តអគ្គិសនី ប្រភេទអាណាឡូក (V2)",
    "gui.extension.dfr0300.description": "វាស់ កម្រឹតចម្លងចរន្តអគ្គិសនីនៃវត្ថុរាវ ដើម្បីវាយតម្លៃគុណភាពទឹក",
    "gui.extension.sen0170.name": "ឧបករណ៍វាស់ល្បឿនខ្យល់",
    "gui.extension.sen0170.description": "វាស់កម្រិតល្បឿនខ្យល់ និងបញ្ជេញសញ្ញាជាតុងស្យុង",
    "gui.extension.sen0226.name": "BMP280 ឧបករណ៍វាស់សីតុណ្ហភាព ",
    "gui.extension.sen0226.description": "BMP280 ជាឧបករណ៏វាស់សីតុណ្ហភាព និងសម្ពាធធរណីមាត្រ",
    "gui.extension.sen0228.name": "VEML7700 ឧបករណ៍វាស់ពន្លឺនៃមជ្ឈដ្ឋានតាមទំនាក់ទំនង I2C",
    "gui.extension.sen0228.description": "ឧបករណ៏វាស់ពន្លឺនៃមជ្ឈដ្ឋានឌីជីថល ដែលមានភាពត្រឹមត្រូវកម្រឹតខ្ពស់ និងកំរិតនៃការបែងចែករហូតដល់ 16-bit",
    "gui.extension.sen0236.name": "ម៉ូឌុល BME280",
    "gui.extension.sen0236.description": "ម៉ូឌុល BME280",
    "gui.extension.dfr0022.name": "ឧបករណ៍វាស់កម្រឹតប្រផេះ ប្រភេទអាណាឡូក",
    "gui.extension.dfr0022.description": "ចាប់យកដង់ស៊ីតេពន្លឺ និងបង្ហាញសញ្ញាត្រូវគ្នាជាតុងស្យុងអាណាឡូក នឹងដែលអាចយកទៅអនុវត្តសម្រាប់ការតាមដានខ្សែបន្ទាត់",
    "gui.extension.motucamera.name": "ផ្ទាំងចាប់សញ្ញា MU",
    "gui.extension.motucamera.description": "ការពិពណ៌នាសម្រាប់ផ្ទាំងបន្ថែម 'ផ្ទាំងចាប់សញ្ញា MU'",
    "gui.extension.dfr0552.name": "ម៉ូឌុលបំលែង​ពី ឌីជីថលទៅអាណាឡូក 12-bit (DAC)",
    "gui.extension.dfr0552.description": "បំលែងបរិមាណឌីជីថល ទៅជាសញ្ញាតង់ស្យុង DC ដែលត្រូវគ្នាយ៉ាងសុក្រឹត (មិនប្រើ PWM)",
    "gui.extension.sen0117.name": "ម៉ូឌុលសំយោគសម្តី",
    "gui.extension.sen0117.description": "អ្នកគ្រាន់តែបញ្ចូល ព្យញ្ជនៈនិងលេខជា ភាសា ចិន​និងអង់គ្លេស វានឹងអានពាក្យទាំងនេះ",
    "gui.extension.dfr0576.name": "ឧបករណ៍ពង្រីកបណ្តាញ I2C",
    "gui.extension.dfr0576.description": "ត្រូវបានប្រើដើម្បីដោះស្រាយបញ្ហាជាន់គ្នានៃអាសយដ្ឋានឧបករណ៍ I2C",
    "gui.extension.sen0248.name": "BME680 ឧបករណ៏វាស់បរិស្ថាន",
    "gui.extension.sen0248.description": "VOC (សមាសធាតុសរីរាង្គងាយនឹងបង្កជាហេតុ) សីតុណ្ហភាព, សំណើម និងសម្ពាធខ្យល់ សុទ្ធតែអាចវាស់បាន",
    "gui.extension.sen0304.name": "ឧបករណ៍វាស់ចម្ងាយប្រើសម្លេងអ៊ុលត្រា តាមទំនាក់ទំនង I2C",
    "gui.extension.sen0304.description": "ឧបករណ៍វាស់ចម្ងាយប្រើសម្លេងអ៊ុលត្រា ត្រូវបានរចនាឡើងដើម្បីវាស់ចម្ងាយអោយបានឆាប់ ឬប្រើប្រាស់សម្រាប់ធ្វើកម្មវិធីចៀសវាងឧបសគ្គនានា",
    "gui.extension.sen0307.name": "ឧបករណ៍វាស់ចម្ងាយប្រើសម្លេងអ៊ុលត្រា ប្រភេទអាណាឡូក",
    "gui.extension.sen0307.description": "បើកឧបករណ៏វាស់ស្ទង់គូរ ដែលជាម៉ូឌុលវាស់ចម្ងាយប្រើសម្លេងអ៊ុលត្រាប្រភេទអាណាឡូក",
    "gui.extension.sen0250.name": "BMI160 ឧបករណ៏វាស់ចលនាតាមអ័ក្សទាំង 6",
    "gui.extension.sen0250.description": "16-bit 3 អ័ក្សសំទុះ និង 3 អ័ក្សត្រីវិស័យ ជាមួយនឹងការប្រើប្រាស់ថាមពលតិចតួចខ្លាំងមែនទែន",
    "gui.extension.sen0224.name": "LIS2DH ឧបករណ៍វាស់សំទុះ 3 អ័ក្ស តាមទំនាក់ទំនង I2C ",
    "gui.extension.sen0224.description": "ឧបករណ៍វាស់សំទុះ 3 អ័ក្ស និងប្រើប្រាស់ថាមពលតិចតួចខ្លាំងមែនទែន",
    "gui.extension.dfr0151.name": "DS1307 នាឡិកាពេលវេលាបច្ចុប្បន្ន",
    "gui.extension.dfr0151.description": "កំហុសលំអៀងនៃពេលវេលាមានត្រឹមតែប្រហែល 1 វិនាទីក្នុងរយៈពេល 24 ម៉ោងតែប៉ុណ្ណោះ",
    "gui.extension.dfr0469.name": "SD2405 នាឡិកាពេលវេលាបច្ចុប្បន្ន",
    "gui.extension.dfr0469.description": "នាឡិកាពេលវេលាបច្ចុប្បន្នដែលសុក្រឹត (RTC) ដោយប្រើគ្រីស្តាល់សម្រាប់ទូរទាត់, រួមទាំងមានសៀគ្វីសាកថ្មនៅជាមួយផង",
    "gui.extension.dfr0126.name": "ម៉ូឌុលវិភាគវិសាលគមន៍",
    "gui.extension.dfr0126.description": "តាមដានហ្វ្រេកង់ស៍សម្លេង",
    "gui.extension.dfr0231.name": "ម៉ូឌុល NFC",
    "gui.extension.dfr0231.description": "សមរម្យ​សម្រាប់ការទំនាក់ទំនងឥតខ្សែចម្ងាយជិត",
    "gui.extension.sen0245.name": "VL53L0X ឧបករណ៍វាស់ចម្ងាយប្រើពន្លឺ​ឡាស៊ែរ",
    "gui.extension.sen0245.description": "Use TOF technology to accurately measure distance, up to 2 meters",
    "gui.extension.dfr0026.name": "ឧបករណ៍វាស់ពន្លឺនៃមជ្ឈដ្ឋាន ប្រភេទអាណាឡូក",
    "gui.extension.dfr0026.description": "វាស់ដង់ស៊ីតេពន្លឺនៃមជ្ឈដ្ឋាន",
    "gui.extension.dfr0027.name": "ឧបករណ៍ចាប់សញ្ញារំញ័រ ប្រភេទឌីជីថល",
    "gui.extension.dfr0027.description": "ចាប់សញ្ញារំញ័រ និងបញ្ចេញជាសញ្ញាឌីជីថល",
    "gui.extension.dfr0028.name": "ឧបករណ៍ចាប់សញ្ញាទេរ ប្រភេទឌីជីថល",
    "gui.extension.dfr0028.description": "ដោយផ្អែកលើចំនួនកុងតាក់បារត វាអាចប្រើជាឧបករណ៏ចាប់សញ្ញាទេរ",
    "gui.extension.dfr0029.name": "ប៊ូតុងចុចឌីជីថល",
    "gui.extension.dfr0029.description": "រុញចុះក្រោម: កម្រិតខ្ពស់, ឈប់ចុច: កម្រិតទាប",
    "gui.extension.dfr0030.name": "ឧបករណ៍ចាប់សញ្ញាប៉ះ ប្រភេទឌីជីថល ប្រើកង់ដង់សាទ័រ",
    "gui.extension.dfr0030.description": "ម៉ូឌុលកុងតាក់ប៉ះ ប្រភេទឌីជីថល ប្រើកង់ដង់សាទ័រ ប្រើសម្រាប់ចាប់សញ្ញាពី លោហៈធាតុ និងរាងកាយមនុស្ស",
    "gui.extension.dfr0033.name": "ឧបករណ៍វាស់ដែនម៉ាញេទិក ប្រភេទឌីជីថល",
    "gui.extension.dfr0033.description": "ចាប់វត្ថុធាតុម៉ាញ៉េទិកបានក្នុងចម្ងាយ 3cm",
    "gui.extension.dfr0034.name": "ឧបករណ៍ចាប់សំឡេង ប្រភេទអាណាឡូក",
    "gui.extension.dfr0034.description": "ចាប់កម្រិតឮ​នៃមជ្ឈដ្ឋាន",
    "gui.extension.sen0132.name": "ឧបករណ៍វាស់កម្រឹតកាបូនម៉ូណូអុកស៊ីត ប្រភេទអាណាឡូក",
    "gui.extension.sen0132.description": "វាស់ឃើញ កំហាប់ឧស្ម័ន CO-gas ក្នុងចន្លោះពី 20 ទៅ 2000ppm",
    "gui.extension.dfr0051.name": "ការបែងចែកតុងស្យុងអាណាឡូក",
    "gui.extension.dfr0051.description": "វាស់បានតុងស្យុង (DC) ពី 0.0245V ដល់ 25V",
    "gui.extension.dfr0052.name": "ឧបករណ៏ចាប់សញ្ញារំញ័រ ប្រើថាស Piezo ប្រភេទអាណាឡូក ",
    "gui.extension.dfr0052.description": "ដើម្បីវាស់កំហូចរាង បែប dynamic និង Quasi-static រួចបញ្ចេញសញ្ញាប្រភេទអាណាឡូក",
    "gui.extension.dfr0058.name": "ឧបករណ៍ចាប់សញ្ញាអាណាឡូក ប្រភេទប៉ូតង់ស្យូមម៉ែត្រមួល",
    "gui.extension.dfr0058.description": "ប៉ូតង់ស្យូមម៉ែត្រដែលមានភាពសុក្រឹតខ្ពស់ ជាមួយចំនួនបង្វិលអតិបរមារហូតដល់ 10 ជុំ",
    "gui.extension.dfr0061.name": "ម៉ូឌុល Joystick",
    "gui.extension.dfr0061.description": "(X,Y) ច្រកចេញអាណាឡូក, (Z) 1 ច្រកចេញឌីជីថល និងអាចត្រូវបានប្រើជាឧបករណ៍គ្រប់គ្រងពីចម្ងាយ (តេឡេ)",
    "gui.extension.dfr0072.name": "ឧបករណ៏ចាប់សញ្ញា Shiftout",
    "gui.extension.dfr0072.description": "សេចក្ដីពិពណ៌នាសម្រាប់ផ្នែកបន្ថែម 'ឧបករណ៏ចាប់សញ្ញា Shiftout'",
    "gui.extension.dfr0563.name": "ឧបករណ៍វាស់ថ្ម",
    "gui.extension.dfr0563.description": "ការពិពណ៌នាសម្រាប់ផ្នែកបន្ថែម \"ឧបករណ៍វាស់ថ្ម\"",
    "gui.extension.dfr0553.name": "ម៉ូឌុលបំលែង​ពី អាណាឡូកទៅឌីជីថល 16-bit (ADC)",
    "gui.extension.dfr0553.description": "ការពិពណ៌នាសម្រាប់ផ្នែកបន្ថែម 'ម៉ូឌុលបំលែង​ពី អាណាឡូកទៅឌីជីថល 16-bit'",
    "gui.extension.dfr0588.name": "ឧបករណ៏វាស់សីតុណ្ហភាពនិងសំណើម",
    "gui.extension.dfr0588.description": "ឧបករណ៏វាស់សីតុណ្ហភាពនិងសំណើម",
    "gui.extension.dfr0076.name": "ឧបករណ៍ចាប់សញ្ញាអណ្តាតភ្លើង ប្រភេទអាណាឡូក",
    "gui.extension.dfr0076.description": "ចាប់សញ្ញា អណ្តាតភ្លើង ឬពន្លឺដែលមានជំហានរលកចន្លោះ​ពី 760nm ទៅ 1100nm",
    "gui.extension.dfr0117.name": "EEPROM ប្រើទំនាក់ទំនង I2C",
    "gui.extension.dfr0117.description": "ម៉ូឌុលផ្ទុកទិន្នន័យ​ ទំហំ 32kb",
    "gui.extension.dfr0143.name": "MMA7361 ឧបករណ៍វាស់សំទុះ 3 អ័ក្ស",
    "gui.extension.dfr0143.description": "ប្រើឈីប MMA7361,​ អាចចាប់សញ្ញា កាយវិការនិងទិសដៅចលនា",
    "gui.extension.sen0018.name": "ឧបករណ៍ចាប់ចលនាប្រើអ៊ីនហ្វ្រាក្រហម ប្រភេទឌីជីថល",
    "gui.extension.sen0018.description": "វាស់កម្រឹតកាំរស្មីអ៊ីនហ្វ្រាក្រហមដែលចេញពីមនុស្សឬសត្វពេលធ្វើចលនា",
    "gui.extension.sen0114.name": "ឧបករណ៍វាស់សំណើមដី ប្រភេទអាណាឡូក",
    "gui.extension.sen0114.description": "វាស់បរិមាណសំណើមដែលមាននៅក្នុងដីជុំវិញវា",
    "gui.extension.sen0121.name": "ឧបករណ៍ចាប់សញ្ញាចំហាយ",
    "gui.extension.sen0121.description": "ចាប់សញ្ញា ទឹកភ្លៀង, ចំហាយ និងផ្សែងទឹក",
    "gui.extension.sen0212.name": "ឧបករណ៍កំណត់អត្តសញ្ញាណពណ៌",
    "gui.extension.sen0212.description": "កំណត់ពណ៌នៃវត្ថុ និងបញ្ជេញជាតម្លៃ RGB",
    "gui.extension.sen0253.name": "10-DOF ឧបករណ៍ចាប់សញ្ញាចលនា",
    "gui.extension.sen0253.description": "BBNO055 ជា IC ចាប់សញ្ញាថ្មី សម្រាប់ធ្វើឧបករណ៏ឆ្លាតវៃចាប់ទិសដាច់ខាតចំនួន 9 អ័ក្ស",
    "gui.extension.sen0290.name": "ឧបករណ៍ចាប់សញ្ញារន្ទះ",
    "gui.extension.sen0290.description": "ឧបករណ៍ចាប់សញ្ញារន្ទះឆ្លាតវៃ",
    "gui.extension.sen0291.name": "នាឡិកាវាស់ថាមពល ប្រភេទឌីជីថល ប្រើទំនាក់ទំនង I2C",
    "gui.extension.huskylens.name": "ម៉ាស៊ីនថតប្រើបញ្ញាសិប្បនិម្មិត (AI) ម៉ាក HUSKYLENS",
    "gui.extension.huskylens.description": "ឧបករណ៍ចាប់រូបភាពប្រើបញ្ញាសិប្បនិម្មិត (AI) ដែលអាចស្គាល់មុខនិងអាចរៀន",
    "gui.extension.ps2.name": "ដៃបញ្ជាប្រភេទ PS2",
    "gui.extension.ps2.description": "ដៃបញ្ជាប្រភេទ PS2",
    "gui.extension.sen0291.description": "វាស់ តុងស្យុង, ចរន្ត និង អនុភាព ជាមួយភាពជាក់លាក់ខ្ពស់",
    "gui.extension.sen0202.name": "ឧបករណ៍ចាប់សញ្ញាកាយវិការ 3D ខ្នាតតូច",
    "gui.extension.sen0202.description": "ចាប់សញ្ញានៃ ចលនារង្វិលតាម ទ្រនិចនាឡិកា និងច្រាស់ទ្រនិចនាឡិកា និង​ទិសដៅនៃចលនា",
    "gui.extension.iobox.name": "micro:IO-BOX",
    "gui.extension.iobox.description": "Micro:bit ផ្ទាំងបន្តរសម្រាប់ម៉ូទ័រនិងថ្មលីចូម",
    "gui.extension.wifi.name": "Wi-Fi",
    "gui.extension.wifi.description": "អនុញ្ញាតឱ្យឧបករណ៍ភ្ជាប់ទៅបណ្តាញ Wi-Fi",
    "gui.extension.ntp.name": "NTP",
    "gui.extension.ntp.description": "អនុញ្ញាតឱ្យឧបករណ៍ទទួលបានពេលវេលាក្នុងស្រុក",
    "gui.extension.udp.name": "UDP broadcast",
    "gui.extension.udp.description": "Let devices and devices in the local area network realize multi-machine broadcast communication through UDP protocol",
    "gui.extension.http.name": "HTTP",
    "gui.extension.http.description": "អនុញ្ញាតឱ្យឧបករណ៍ស្នើសុំព័ត៌មានតាមរយៈ HTTP",
    "gui.extension.mqtt.name": "MQTT",
    "gui.extension.mqtt.description": "អនុញ្ញាតឱ្យឧបករណ៍ទំនាក់ទំនងប្រើប្រាស់ វិធីការ MQTT",
    "gui.extension.speechRecognition.name": "ការទទួលស្គាល់សម្តី",
    "gui.extension.speechRecognition.description": "ការទទួលស្គាល់សម្តី",
    "gui.gui.loaded": "ទាញយករួចរាល់",
    "gui.gui.notLoaded": "មិនទាន់ទាញយករួចរាល់",
    "gui.gui.cantFindYouWant": "រកមិនឃើញអ្វីដែលអ្នកចង់បានទេ?",
    "gui.gui.clickHere": "ចុចទីនេះ",
    "gui.gui.viewHelp": "ស្វែងរកបន្ថែមទៀត",
    "gui.gui.uploadToDev": "ផ្ញើចេញ",
    "gui.gui.compileAndUpload": "កំផាលនិងផ្ញើរចេញ",
    "gui.gui.compileOnly": "កំផាលតែមួយមុខ",
    "gui.gui.compileOpen": "កំផាលនិងបើក",
    "gui.gui.burnBootloader": "ដុតបញ្ចូល កម្មវិធីចាប់ផ្ដើមប្រព័ន្ធឥតខ្សែ",
    "gui.gui.arduinoRun": "ដំណើការ",
    "gui.gui.arduinoSave": "រក្សាទុក",
    "gui.gui.autoGenerated": "បង្កើតដោយស្វ័យប្រវត្តិ",
    "gui.gui.manualEditing": "កែសម្រួលដោយដៃ",
    "gui.gui.codeBox": "ប្រអប់កូដ",
    "gui.gui.moduleCode": "ម៉ូឌុលកូដ",
    "gui.menu.edit.undo": "មិនធ្វើវិញ",
    "gui.menu.edit.redo": "ធ្វើឡើងវិញ",
    "gui.menu.edit.selectAll": "ជ្រើសរើសទាំងអស់",
    "gui.menu.edit.cut": "កាត់",
    "gui.menu.edit.copy": "ថតចម្លង",
    "gui.menu.edit.paste": "បិទភ្ជាប់",
    "gui.menu.edit.codeStyle": "ស្តាយកូដ",
    "gui.menu.edit.fontSize": "ទំហំ​អក្សរ",
    "gui.menu.edit.clearCache": "លុបឃ្លាំងសម្ងាត់",
    "gui.menu.edit.maxReset": "ស្តារតំលៃអតិបរមាទៅតំលៃដើមដែលបានកំណត់ទុកពីរបស់រោងចក្រ",
    "gui.gui.serial": "ច្រកសេរ៊ី",
    "gui.gui.openSerial": "បើក​ច្រកសេរ៊ី",
    "gui.gui.closeSerial": "បិទ​​ច្រកសេរ៊ី",
    "gui.gui.close": "បិទ  ",
    "gui.gui.open": "បើក​  ",
    "gui.gui.clearOutput": "លុបលទ្ធផលទាំងអស់",
    "gui.gui.scroll": "រមូរ",
    "gui.gui.send": "បញ្ជូន",
    "gui.gui.baud": "បូត (Baud)",
    "gui.gui.noLineEnd": "គ្មានព្យញ្ជនៈបញ្ចប់",
    "gui.gui.newLine": "ចុះបន្ទាត់ (LF)",
    "gui.gui.carriageReturn": "ថយក្រោយ (CR)",
    "gui.gui.bothNLandCR": "LF & CR",
    "gui.progress.burning": "ដុតបញ្ចូល",
    "gui.progress.compiling": "កំពុងកំផាយ",
    "gui.burner.compileError": "កំផាលមានកំហុស",
    "gui.burner.linkError": "តំណមានកំហុស",
    "gui.burner.generateHexError": "បង្កើត .hex មានកំហុស",
    "gui.burner.generateBinError": "បង្កើត .bin មានកំហុស",
    "gui.burner.burnError": "ដុតបញ្ចូលមានកំហុស",
    "gui.burner.eraseError": "លុបមានកំហុស",
    "gui.burner.findLeonardoPortError": "មិនអាចរក​ កម្មវិធីចាប់ផ្ដើមប្រព័ន្ធ របស់ Leonardo ទេ",
    "gui.burner.noMicrobit": "សូមអភ័យទោសវាមិនមែនជា micro: bit ទេ, សូមភ្ជាប់micro: bit ",
    "gui.burner.swdCfgError": "មិនអាចសរសេរលើ cmsis-dap.cfg",
    "gui.burner.UDiskPopUp": "UDisk លេចឡើង, សូមរង់ចាំមួយរយៈហើយព្យាយាមម្តងទៀត",
    "gui.burner.UDiskWriteError": "សរសេរ​លើ UDisk មានកំហុស",
    "gui.burner.printObjectTooBig": "The project is too large; please click the \"help\" in the right top to reduce its size. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooBig": "Not enough memory; please click the \"help\" in the right top to reduce memory occupancy. \nError compiling for board [%BOARD]. ",
    "gui.burner.printFlashTooLow": "Low memory available, stability problems may occur. ",
    "gui.burner.printSizeError": "មិនអាចកំណត់ ទំហំកម្មវិធី បានទេ ",
    "gui.burner.printSize": "គម្រោងប្រើ [%TEXTSIZE] បៃ (bytes), កាន់កាប់ ([%TEXTSIZEPRE]) ទំហំអង្គចង់ចាំ ដោយទុកទំនេរពី [%TEXTSIZESUR] បៃ, រហូតដល់ទៅ [%TEXTSIZEMAX] បៃ។\r\r\nអថេរសកលប្រើ [%DATASIZE] បៃ, ([%DATASIZEPRE]) នៃអង្គចងចាំថាមវន្ត ដោយបន្សល់ទុកអថេរមូលដ្ឋានពី​ [%DATASIZESUR] បៃ  រហូតដល់ [%DATASIZEMAX] បៃ។ ",
    "gui.progress.compileSuccess": "កំផាលជោគជ័យ",
    "gui.progress.compileProgress": "កំពុងកំផាល",
    "gui.progress.uploadProgress": "កំពុងផ្ញើចេញ",
    "gui.progress.uploadSuccess": "ផ្ញើចេញជោគជ័យ",
    "gui.codeLabel.dynamicVariables": "អថេរបែប Dynamic",
    "gui.codeLabel.functionDeclaration": "ការប្រកាសអនុគមន៍",
    "gui.codeLabel.staticConstants": "តំលៃថេរបែប Static",
    "gui.codeLabel.createObject": "បង្កើត Object",
    "gui.codeLabel.mainProgramStart": "ចាប់ផ្តើមកម្មវិធីគោល",
    "gui.codeLabel.SubthreadProgramStarts": "កម្មវិធី Subthread ចាប់ផ្តើមបាន %1 ",
    "gui.codeLabel.customFunction": "អនុគមន៍ផ្ទាល់ខ្លួន",
    "gui.codeLabel.eventCallbackFunction": "អនុគមន៍ដែលអាចហៅតាមរយៈព្រឹត្តិការណ៍មកវិញ",
    "gui.codeLabel.staticFunctions": "អនុគមន៍បែប Static",
    "gui.progress.errMsg": "!កំហុសក្នុងការផ្ញើចេញ, សូមពិនិត្យមើលព័ត៌មានលំអិតនៅក្នុងផ្ទាំងខ្មៅនៅផ្នែកខាងក្រោម-ស្តាំ",
    "gui.howtos.spin-around.name": "វិលជុំវិញ",
    "gui.howtos.spin.step_dragTurn": "អូសចេញប្លុក [បង្វិល]",
    "gui.howtos.spin.step_clickTurn": "ចុចប្លុកដើម្បីដំណើរការ",
    "gui.howtos.spin.step_clickControl": "ចុចលើប្រភេទ [គ្រប់គ្រង]",
    "gui.howtos.spin.step_dragForever": "អូសចេញប្លុក [រហូត]",
    "gui.howtos.spin.step_clickForever": "ចុចប្លុកដើម្បីដំណើរការ",
    "gui.howtos.spin.step_changeColor": "បន្ថែមប្លុក [ប្តូរបែបផែនពណ៌]",
    "gui.howtos.say-hello.name": "និយាយសួស្ដី",
    "gui.howtos.say-hello.step_addSprite": "បន្ថែមតួអង្គថ្មី",
    "gui.howtos.say-hello.step_clickLooks": "ចុចលើប្រភេទ​ [រូបរាង]",
    "gui.howtos.say-hello.step_dragSay": "អូសចេញប្លុក [និយាយ]",
    "gui.howtos.say-hello.step_clickSay": "ចុចប្លុកដើម្បីដំណើរការ",
    "gui.howtos.say-hello.step_anotherSay": "អូសចេញប្លុក [និយាយ] មួយផ្សេងទៀត",
    "gui.howtos.say-hello.step_editSay": "និយាយអ្វីផ្សេង",
    "gui.howtos.say-hello.step_clickStack": "ចុចប្លុកដើម្បីដំណើរការ",
    "gui.howtos.run-away.name": "រត់​ចេញ",
    "gui.howtos.run-away.step_dragGoTo": "អូសចេញប្លុក​​ [ទៅកាន់ទីតាំងចៃដន្យ]",
    "gui.howtos.run-away.step_clickGoTo": "ចុចប្លុកដើម្បីដំណើរការ",
    "gui.howtos.run-away.step3": "ចុចលើប្រភេទ [ព្រឹត្តិការណ៍]",
    "gui.howtos.run-away.step_addWhenClicked": "បន្ថែមប្លុក [ពេលចុចតួអង្គ]",
    "gui.howtos.run-away.step_clickSprite": "ចុចប្លុកដើម្បីដំណើរការ",
    "gui.howtos.run-away.step_addSound": "បន្ថែមប្លុក [ផ្តើមសម្លេង]",
    "gui.howtos.pick-up-apple.name": "ល្បែងចាប់ផ្លែប៉ោម",
    "gui.howtos.pick-up-apple.step_1": "ប្តូរទៅ Scratch",
    "gui.howtos.pick-up-apple.step_2": "ប្តូរឧបករណ៍",
    "gui.howtos.pick-up-apple.step_3": "ភ្ជាប់ឧបករណ៍",
    "gui.howtos.pick-up-apple.step_4": "កែតម្រូវត្រីវិស័យ",
    "gui.howtos.pick-up-apple.step_5": "បើកកម្មវិធីឧទាហរណ៍​ Scratch ",
    "gui.howtos.pick-up-apple.step_6": "ល្បែងចាប់ផ្លែប៉ោម",
    "gui.howtos.move-left-right.name": "micro:bit និង Meow",
    "gui.howtos.move-left-right.step_1": "បង្កើតគម្រោងថ្មី",
    "gui.howtos.move-left-right.step_2": "អូសប្លុក1",
    "gui.howtos.move-left-right.step_3": "អូសប្លុក2",
    "gui.howtos.move-left-right.step_4": "ផ្លាស់ទៅឆ្វេងនិងទៅស្តាំ",
    "gui.howtos.install-driver.name": "ដំឡើងកម្មវិធីឈ្នាន់",
    "gui.howtos.transform-expression.name": "ញញឹឬយំ",
    "gui.howtos.transform-expression.step_1": "ប្តូរទៅកូដ",
    "gui.howtos.transform-expression.step_2": "ប្តូរឧបករណ៍",
    "gui.howtos.transform-expression.step_3": "អូសប្លុក1",
    "gui.howtos.transform-expression.step_4": "អូសប្លុក2",
    "gui.howtos.transform-expression.step_5": "អូសប្លុក3",
    "gui.howtos.transform-expression.step_6": "ប្តូរសញ្ញាអារម្មណ៍ញញឹមឬយំ",
    "gui.howtos.lot.name": "អ៊ីនធឺណិតនៃវត្ថុ (IoTs)",
    "gui.howtos.lot.step_1": "ជ្រើសរើសកម្មវិធីឧទាហរណ៍",
    "gui.howtos.lot.step_2": "ដុតបញ្ចូលកម្មវិធី",
    "gui.howtos.lot.step_3": "ដ្យាក្រាមលក្ខណៈពិសេស",
    "gui.howtos.touch-pin.name": "ជើងប៉ះ",
    "gui.howtos.touch-pin.step_1": "ប្តូរទៅកូដ",
    "gui.howtos.touch-pin.step_2": "ជ្រើសរើសមុខងារ",
    "gui.howtos.touch-pin.step_3": "ដុតបញ្ចូលកម្មវិធី",
    "gui.howtos.touch-pin.step_4": "ជើងប៉ះ",
    "gui.howtos.save-witch.name": "សង្គ្រោះមេធ្មប់",
    "gui.howtos.save-witch.step_1": "ប្តូរទៅ Scratch",
    "gui.howtos.save-witch.step_2": "នៅក្នុងផ្នែកបន្ថែមសូមជ្រើសរើស 'micro:bit'",
    "gui.howtos.save-witch.step_3": "ក្នុងកម្មវិធីឧទាហរណ៍ ជ្រើសរើស  'សង្គ្រោះមេធ្មប់'",
    "gui.howtos.save-witch.step_4": "ភ្ជាប់ឧបករណ៍, ជ្រើសរើស micro:bit ហើយរង់ចាំការតភ្ជាប់បានជោគជ័យ",
    "gui.howtos.save-witch.step_5": "សូមបង្វិល micro:bit​  រហូតដល់ អំពូល LED រលត់, នៅពេលផ្ទាំងជូនដំណឹង [calibrate the device] លេចចេញមក",
    "gui.howtos.save-witch.step_6": "ចុចលើទង់ពណ៌បៃតង់, ហើយគ្រវីដៃរបស់អ្នកដើម្បីធ្វើអន្តរកម្មជាមួយរូបចលនា",
    "gui.howtos.microbit-touch.name": "ចុចដើម្បីបង្ហាញសញ្ញាអារម្មណ៍ញញឹមឬយំ",
    "gui.howtos.microbit-touch.step_1": "ប្តូរទៅម៉ូតកូដ",
    "gui.howtos.microbit-touch.step_2": "ចុច 'រៀន' ដើម្បីបើកកម្មវិធីឧទាហរណ៍ ហើយជ្រើសរើស 'ជើងប៉ះ'",
    "gui.howtos.microbit-touch.step_3": "ភ្ជាប់ច្រក COM ដែលប្រើជាមួយឧបករណ៍, ចុច \"ផ្ញើរចេញទៅឧបករណ៍\" ",
    "gui.howtos.microbit-touch.step_4": "ចុចប៊ូតុងផ្សេងគ្នា ដើម្បីបង្ហាញអារម្មណ៍ផ្សេងគ្នា",
    "gui.howtos.microbit-calibration.name": "micro:bit ការកែតម្រូវ",
    "gui.howtos.microbit-calibration.step_1": "បង្វិលបន្ទះ micro:bit កែងនឹងផ្ទៃដី ហើយអំពូល LED នៅលើបន្ទះនឹងភ្លឺទៅតាមនោះ",
    "gui.howtos.microbit-calibration.step_2": "រហូតដល់អំពូល LED នៅខាងក្រៅបង្អស់ភ្លឺទាំងអស់ (ដូចបង្ហាញខាងក្រោម), អ្នកនឹងឃើញសញ្ញាញញឹមនៅពេលការកែតម្រូវបានបញ្ចប់",
    "gui.extension.sen0251.name": "BMP388 ឧបករណ៍វាស់កំដៅនិងសម្ពាធខ្យល់",
    "gui.extension.sen0251.description": "សីតុណ្ហភាព, សម្ពាធ​បរិយាកាស, វាស់កម្ពស់",
    "gui.extension.sen0206.name": "ឧបករណ៍វាស់សីតុណ្ហភាពពីចម្ងាយដោយប្រើអាំងហ្វ្រាក្រហម",
    "gui.extension.sen0206.description": "ការវាស់សីតុណ្ហភាពពីចម្ងាយ ឬ វាស់ចម្ងាយនៃវត្ថុ ដោយប្រើ ឆ្ងាយ-អាំងហ្វ្រាក្រហម",
    "gui.gui.recentlyEdited": "ទើបតែកែសម្រួលរួច",
    "gui.extension.weather.name": "ទាញយកព័ត៌មានអាកាសធាតុ",
    "gui.extension.weather.description": "ត្រូវការប្រើម៉ូឌុល Wi-Fi ដើម្បីទទួលបានព័ត៌មានស្តីអំពីអាកាសធាតុ",
    "gui.extension.tinywebdb.name": "TinyWebDB",
    "gui.extension.tinywebdb.description": "ប្រតិបត្តិលើបណ្តាញមូលដ្ឋានទិន្នន័យ TinyWebDB,​​​​ អាចប្រើបានជាមួយ APP Inventor",
    "gui.extension.pictureai.name": "ការទទួលស្គាល់រូបភាពដោយ បញ្ញាសិប្បនិម្មិត (AI)",
    "gui.extension.pictureai.description": "ធ្វើអ្វីមួយអស្ចារ្យជាមួយរូបភាព បញ្ញាសិប្បនិម្មិត (AI)",
    "gui.gui.variableScopeOptionAllSprites": "សម្រាប់គ្រប់តួអង្គ",
    "gui.gui.variableScopeOptionSpriteOnly": "សម្រាប់តែតួអង្គបច្ចុប្បន្ន ",
    "gui.gui.variablePromptAllSpritesMessage": "តួអង្គទាំងឡាយអាចប្រើអថេរនេះ",
    "gui.monitor.contextMenu.default": "បង្ហាញធម្មតា",
    "gui.monitor.contextMenu.large": "បង្ហាញឱ្យធំ",
    "gui.monitor.contextMenu.slider": "របាររំកិល",
    "gui.monitor.contextMenu.import": "នាំចូល",
    "gui.monitor.contextMenu.export": "នាំចេញ",
    "gui.monitor.listMonitor.listLength": "ប្រវែង {length}",
    "gui.monitor.listMonitor.empty": "(ទទេ)",
    "gui.costumeTab.backdrop": "ផ្ទាំងខាងក្រោយ",
    "gui.costumeTab.costume": "រូបរាង",
    "gui.opcodeLabels.direction": "ទិសដៅ",
    "gui.opcodeLabels.xposition": "ទីតាំង​ x",
    "gui.opcodeLabels.yposition": "ទីតាំង y",
    "gui.opcodeLabels.size": "ទំហំ",
    "gui.opcodeLabels.costumename": "ឈ្មោះរូបរាង",
    "gui.opcodeLabels.costumenumber": "លេខរូបរាង",
    "gui.opcodeLabels.backdropname": "ឈ្មោះផ្ទាំងខាងក្រោយ",
    "gui.opcodeLabels.backdropnumber": "លេខផ្ទាំងខាងក្រោយ",
    "gui.opcodeLabels.volume": "កម្រិតសម្លេង",
    "gui.opcodeLabels.tempo": "សង្វាក់",
    "gui.opcodeLabels.answer": "ចម្លើយ",
    "gui.opcodeLabels.loudness": "កំរិតសម្លេង",
    "gui.opcodeLabels.year": "ឆ្នាំ",
    "gui.opcodeLabels.month": "ខែ",
    "gui.opcodeLabels.date": "កាលបរិច្ឆេទ",
    "gui.opcodeLabels.dayofweek": "ថ្ងៃនៃសប្តាហ៍",
    "gui.opcodeLabels.hour": "ម៉ោង",
    "gui.opcodeLabels.minute": "នាទី",
    "gui.opcodeLabels.second": "វិនាទី",
    "gui.opcodeLabels.timer": "ឧបករណ៍កំណត់ពេលវេលា",
    "gui.loadProject.error": "ផ្ទុកគម្រោងមានកំហុស",
    "gui.fileSystem.fileCatalogue": "កាតាឡុក",
    "gui.fileSystem.boardFiles": "កម្មវិធីបន្ថែម",
    "gui.fileSystem.computerFiles": "ឯកសាកុំព្យូទ័រ",
    "gui.fileSystem.newFile": "ឯកសាថ្មី",
    "gui.fileSystem.newFolder": "ថតដាក់ឯកសារថ្មី",
    "gui.fileSystem.deleteFolder": "លុបថតដាក់ឯកសារ",
    "gui.fileSystem.openFolder": "បើកថតដាក់ឯកសារ",
    "gui.fileSystem.openInEditor": "បើកក្នុងកម្មវិធីកែ",
    "gui.fileSystem.runRightNow": "ចាប់ផ្ដើមដំណើការឥឡូវ​នេះ",
    "gui.fileSystem.stopRun": "បញ្ឈប់ដំណើការ",
    "gui.fileSystem.setBoot": "ដំណើការពេលបើកពីដំបូង",
    "gui.fileSystem.deleteForever": "លុបចោលជារៀងរហូត",
    "gui.fileSystem.saveAs": "រក្សាទុកជា",
    "gui.fileSystem.undoAllEdits": "បោះបង់រាល់កំណែប្រែ",
    "gui.fileSystem.copyToComputer": "ថតចម្លងទៅកុំព្យូទ័រ",
    "gui.fileSystem.uploadToBoard": "ផ្ញើរចេញទៅផ្ទាំងបញ្ជា",
    "gui.fileSystem.uploadAndRun": "ផ្ញើរចេញនិងដាក់ឱ្យដំណើរការ",
    "gui.wifi.pwdNotLegal": "! កំហុស៖ ពាក្យសម្ងាត់មានទម្រង់មិនត្រឹមត្រូវ។ ពាក្យសម្ងាត់ Wi-Fi ត្រូវជា ព្យញ្ជនៈឬលេខ ចំនួនពី 8 ទៅ 20 តួ",
    "gui.spreadmaqueen.perror": "! កំហុស៖ មិនមានអំពូល LED ភ្ជាប់ទៅជើង P12 ទេ។សូមជ្រើសរើសជើងអំពូល LED ជើង P8 វិញ",
    "gui.gui.viewConflict": "មើលទំនាស់",
    "gui.gui.clickViewHelp": "ជំនួយ",
    "gui.gui.conflict": "ទំនាស់",
    "gui.gui.conflictPrompt": "ដាស់តឿនទំនាស់",
    "gui.gui.clickPrompt": "ចុចដើម្បីរំលេចប្លុក, ចុចទ្វេដងដើម្បីស្វែងរកទីតាំងប្លុក",
    "music.categoryName": "តន្ត្រី",
    "translate.categoryName": "បកប្រែ",
    "sensor.categoryName": "ឧបករណ៍ចាប់សញ្ញា",
    "actuator.categoryName": "ម៉ូឌុលសកម្មភាព",
    "communicate.categoryName": "ម៉ូឌុលទំនាក់ទំនង",
    "display.categoryName": "អេក្រង់",
    "extendFunction.categoryName": "មុខងារ",
    "internetService.categoryName": "អ៊ីនធឺណិត",
    "arduinounoR3.categoryName": "UNO Kit",
    "arduinoNano.categoryName": "Nano",
    "microbit.categoryName": "micro:bit",
    "esp32.categoryName": "handpy",
    "spread.categoryName": "ពង្រីកផ្ទាំងបញ្ជា",
    "speechRecognition.categoryName": "ការទទួលស្គាល់សម្តី",
    "thirdExpansion.categoryName": "បន្ថែមដោយអ្នកប្រើប្រាស់",
    "gui.blocklyText.blockTitle.eventBlock": "កម្មវិធីព្រឹត្តិការណ៍",
    "gui.blocklyText.blockTitle.dotScreen": "អេក្រង់ចុចៗ",
    "gui.blocklyText.blockTitle.music": "តន្ត្រី",
    "gui.blocklyText.blockTitle.onBoardSensor": "ផ្ទាំងបញ្ជារបស់ឧបករណ៍ចាប់សញ្ញា",
    "gui.blocklyText.blockTitle.pinOperation": "ជើងប្រតិបត្តិការ",
    "gui.blocklyText.blockTitle.wirelessCommunication": "ការទំនាក់ទំនងឥតខ្សែ",
    "gui.blocklyText.blockTitle.serialportOperation": "ប្រតិបត្តិការច្រកសេរ៊ី",
    "gui.blocklyText.blockTitle.systemResource": "ធនធានប្រព័ន្ធ",
    "gui.blocklyText.blockTitle.screenDisplay": "អេក្រង់បង្ហាញ",
    "gui.blocklyText.blockTitle.ledControl": "ការបញ្ជាអំពូល LED",
    "gui.blocklyText.blockTitle.functionalModule": "ម៉ូឌុលមុខងារ",
    "gui.blocklyText.blockTitle.movement": "ចលនា",
    "gui.blocklyText.blockTitle.expression": "អារម្មណ៍",
    "gui.blocklyText.blockTitle.light": "ពន្លឺ",
    "gui.blocklyText.blockTitle.dance": "រាំ",
    "gui.blocklyText.blockTitle.sensor": "ឧបករណ៍ចាប់សញ្ញា",
    "gui.blocklyText.blockTitle.motorControl": "ការបញ្ជាម៉ូទ័រ",
    "gui.blocklyText.blockTitle.kitSensor": "ឈុតឧបករណ៍ចាប់សញ្ញា",
    "gui.blocklyText.blockTitle.kitActuator": "ឈុតឧបករណ៍ធ្វើចលនា",
    "gui.blocklyText.blockTitle.commonModule": "ម៉ូឌុលរួម",
    "gui.blocklyText.blockTitle.infraredCommunication": "ការទំនាក់ទំនងតាមអ៊ីនហ្វ្រាក្រហម",
    "gui.blocklyText.blockTitle.mp3Module": "ម៉ូឌុល mp3",
    "gui.blocklyText.blockTitle.ws2812RGB": "WS2812 អំពូល RGB",
    "gui.blocklyText.blockTitle.LCDDisplay": "ផ្ទាំងបង្ហាញ LCD",
    "gui.blocklyText.blockTitle.digitalTube": "បំពង់ឌីជីថល",
    "gui.blocklyText.blockTitle.oledDisplay": "ផ្ទាំងបង្ហាញ OLED",
    "gui.blocklyText.blockTitle.onBoardSource": "ប្រភពផ្ទាំងបញ្ជា",
    "gui.blocklyText.blockTitle.rgbControl": "ការគ្រប់គ្រងអំពូល RGB",
    "gui.blocklyText.blockTitle.maxbotResource": "ប្រភព max:bot",
    "gui.blocklyText.blockTitle.lineInspection": "ត្រួតពិនិត្យខ្សែបន្ទាត់",
    "gui.blocklyText.blockTitle.outerSensor": "ឧបករណ៍ចាប់សញ្ញានៅខាងក្រៅ",
    "gui.blocklyText.blockTitle.consolePrint": "លទ្ធផលកុងសូល",
    "gui.blocklyText.romeo.d1ProgramStart": "D1 កម្មវិធីរ៉ូបូតចាប់ផ្តើម",
    "gui.blocklyText.romeo.readdigital": "អានតម្លៃជើង[PIN] [MODULE] ",
    "gui.blocklyText.romeo.buttonModule": "ម៉ូឌុលប៊ូតុង",
    "gui.blocklyText.romeo.digitalSensor": "ឧបករណ៍ចាប់សញ្ញាឌីជីថល",
    "gui.blocklyText.romeo.collisionSensor": "ឧបករណ៍ចាប់សញ្ញាគាំង",
    "gui.blocklyText.romeo.lineSensor": "ឧបករណ៍ចាប់សញ្ញាខ្សែបន្ទាត់",
    "gui.blocklyText.romeo.infraredSwitch": "កុងតាក់អ៊ីនហ្វ្រាក្រហម",
    "gui.blocklyText.romeo.vibrationSensor": "ឧបករណ៍ចាប់សញ្ញារំញ័រ",
    "gui.blocklyText.romeo.readAnalog": "អានជើង [PIN] [SENSOR] ",
    "gui.blocklyText.romeo.lightSensor": "ឧបករណ៍វាស់ពន្លឺ",
    "gui.blocklyText.romeo.anologSensor": "ឧបករណ៍ចាប់សញ្ញាអាណាឡូក",
    "gui.blocklyText.romeo.soundSensor": "ឧបករណ៍ចាប់សម្លេង",
    "gui.blocklyText.romeo.soilMoistureSensor": "ឧបករណ៍វាស់សំណើមដី",
    "gui.blocklyText.romeo.frameSensor": "ឧបករណ៍ចាប់សញ្ញាអណ្តាតភ្លើង",
    "gui.blocklyText.romeo.open": "បើក",
    "gui.blocklyText.romeo.close": "បិទ",
    "gui.blocklyText.romeo.writeDigital": "[SWITCH] [MODULE] នៃជើង [PIN]",
    "gui.blocklyText.romeo.ledLights": "អំពូល LED",
    "gui.blocklyText.romeo.digitalActuator": "ម៉ូឌុលសកម្មភាពប្រភេទឌីជីថល",
    "gui.blocklyText.romeo.relay": "រ៉ឺលេ",
    "gui.blocklyText.romeo.fan": "កង្ហារ",
    "gui.blocklyText.romeo.writeAnalog": "ដាក់ជើង [PIN] [MODULE] ទៅ [VALUE]",
    "gui.blocklyText.romeo.simulationActuator": "ការធ្វើត្រាប់ជាម៉ូឌុលសកម្មភាព",
    "gui.blocklyText.romeo.fanModule": "ម៉ូឌុលកង្ហារ",
    "gui.blocklyText.romeo.robotSpeedMove": "រ៉ូបូត [DIR] នៅល្បឿន [SPEED]",
    "gui.blocklyText.romeo.forward": "ផ្លាស់ទីទៅមុខ",
    "gui.blocklyText.romeo.backward": "ផ្លាសទីមកក្រោយ",
    "gui.blocklyText.romeo.turnLeft": "បត់ឆ្វេង",
    "gui.blocklyText.romeo.turnRight": "បត់ស្តាំ",
    "gui.blocklyText.romeo.setMotorMoveSpeed": "ម៉ូទ័រ [MOTOR] [DIR] នៅល្បឿន [SPEED]",
    "gui.blocklyText.romeo.interface": "អន្តរមុខ",
    "gui.blocklyText.romeo.motorForward": "ទៅមុខ",
    "gui.blocklyText.romeo.motorReverse": "ថយក្រោយ",
    "gui.blocklyText.romeo.stopMotor": "រ៉ូបូត (ម៉ូទ័រ M1 និង M2) ឈប់ផ្លាស់ទី",
    "gui.blocklyText.romeo.readUltrasonic": "កំពុងអានចំងាយប្រើសម្លេងអ៊ុលត្រា (cm) ទ្រីគ័រ [TRIG] ជះត្រឡប់ [ECHO]",
    "gui.blocklyText.romeo.lm35": "អានជើង [PIN] LM35 សីតុណ្ហភាព (°C)",
    "gui.blocklyText.romeo.ds18b20": "អានជើង [PIN] DS18B20 សីតុណ្ហភាព (°C)",
    "gui.blocklyText.romeo.pressInfrared": "ជើងអ៊ីនហ្វ្រាក្រហម [PIN] បានទទួលការចុចពីប៊ូតុង [BUTTON]?",
    "gui.blocklyText.romeo.volumePlus": "កម្រិតសម្លេង+",
    "gui.blocklyText.romeo.switch": "ប្តូរ",
    "gui.blocklyText.romeo.volumeMinus": "កម្រិតសម្លេង-",
    "gui.blocklyText.romeo.startPause": "ចាប់ផ្តើម/ផ្អាក",
    "gui.blocklyText.romeo.up": "ឡើង",
    "gui.blocklyText.romeo.down": "ចុះ",
    "gui.blocklyText.romeo.dataReadable": "តើមានទិន្នន័យដើម្បីអានពីច្រកសេរ៊ីដែរឬទេ?",
    "gui.blocklyText.romeo.readSerialData": "អានទិន្នន័យសេរ៊ី",
    "gui.blocklyText.romeo.timer": "ពេលវេលាដំណើរការប្រព័ន្ធ (ms)",
    "gui.blocklyText.romeo.setBuzzerSound": "ជើង [PIN] លេងសម្លេងត្រែ [TONE] ចំនួន [BEAT] ប៊ីត",
    "gui.blocklyText.romeo.half": "ពាក់កណ្តាល",
    "gui.blocklyText.romeo.quarter": "មួយភាគបួន",
    "gui.blocklyText.romeo.oneInEighth": "មួយភាគប្រាំបី",
    "gui.blocklyText.romeo.wholeBeat": "ប៊ីតទាំងមូល",
    "gui.blocklyText.romeo.doubleBeat": "ប៊ីតពីរដង",
    "gui.blocklyText.romeo.stop": "ឈប់",
    "gui.blocklyText.romeo.setServo": "ដាក់ជើង [PIN] ស៊ែរវូទៅមុំ [DEGREE] ដឺក្រេ",
    "gui.blocklyText.romeo.segment": "អំពូល eight-segment LED (ជើង D6-D13) បង្ហាញ [TUBE]",
    "gui.blocklyText.romeo.serialSendString": "លទ្ធផលពីស៊េរី [STRING] [WAY],[LINE]",
    "gui.blocklyText.romeo.originalOutput": "គល់តម្រុយ",
    "gui.blocklyText.romeo.stringOutput": "អក្សរ",
    "gui.blocklyText.romeo.hexOutput": "ប្រព័ន្ធគោល16",
    "gui.blocklyText.romeo.wrap": "Wrap",
    "gui.blocklyText.romeo.noWrap": "No-Wrap",
    "gui.blocklyText.romeo.readSerialDataIntFloat": "អានទិន្នន័យសេរ៊ី [READTYPE]",
    "gui.blocklyText.romeo.int": "ចំនួនគត់",
    "gui.blocklyText.romeo.float": "ទសភាគ",
    "gui.blocklyText.romeo.setBaudRate": "ដាក់ល្បឿនបញ្ជូនតាមច្រកស៊េរី [BAUD]",
    "gui.blocklyText.maqueen.robotServo": "ស៊ែវូ [INDEX] មុំ [ANGLE]",
    "gui.blocklyText.romeo.readPinWidth": "អានជើង [PIN] ទំហំអាំងពុលស្យុងក្រោមកម្រិត [LEVEL] ម៉ូត, អស់ពេល [TIMEOUT](us)",
    "gui.blocklyText.romeo.low": "ទាប",
    "gui.blocklyText.romeo.high": "ខ្ពស់",
    "gui.blocklyText.romeo.noSound": "គ្មានសំឡេង",
    "gui.blocklyText.arduino.arduinoStarts": "Arduino ចាប់ផ្តើម",
    "gui.blocklyText.arduino.arduinoUnoStarts": "Uno ចាប់ផ្តើម",
    "gui.blocklyText.esp32.displayInLine": "បង្ហាញ [TEXT] នៅ [LINE] បន្ទាត់",
    "gui.blocklyText.esp32.forMpyDisplayInLine": "ឃ្លាំងសម្ងាត់ [TEXT] នៅ​ជួរ [LINE] [SHOWMODE]",
    "gui.blocklyText.esp32.forMpyDisplayClearLine": "លុបឃ្លាំងសម្ងាត់នៅជួរ [LINE]",
    "gui.blocklyText.esp32.displayClearInLine": "លុបនៅជួរ [LINE]",
    "gui.blocklyText.esp32.buzzPinC": "P6  (Onboard buzzer)",
    "gui.blocklyText.esp32.buzzerRedirect": "Buzzer បានបញ្ជូនបន្តទៅជើង [PIN]",
    "gui.blocklyText.esp32.touchPinAll": "ទាំងអស់",
    "gui.blocklyText.esp32.setTouchThreshold": "ដាក់ជើងប៊ូតុង [PIN] តម្លៃនៃកម្រិតចាប់ផ្តើមរបស់ការប៉ះគឺ [THRESHOLD]",
    "gui.blocklyText.esp32.readTouchValue": "អានប៊ូតុង [PIN] តម្លៃប៉ះ",
    "gui.blocklyText.esp32.arduinoUnoStarts": "ESP32​ ចាប់ផ្តើម",
    "gui.blocklyText.esp32.btnIsPressed": "[BUTTON]​​ ប៊ូតុង [BUTTONSTATE]?",
    "gui.blocklyText.esp32.whenBtnPress": "នៅពេលប៊ូតុង [REFERENCE] [BUTTONSTATE]",
    "gui.blocklyText.esp32.whenPinConnected": "នៅពេលជើង [REFERENCE] គឺ [TOUCHSTATE]",
    "gui.blocklyText.esp32.onGesture": "នៅពេល [GESTURE]",
    "gui.blocklyText.esp32.pinIsConnected": "ជើង [PIN] គឺ [TOUCHSTATE]?",
    "gui.blocklyText.esp32.buzzerSwitch": "បញ្ឈប់ការចាក់សារឡើងវិញនៃផ្ទាំងខាងក្រោយ",
    "gui.blocklyText.esp32.backSetBuzzerFreq": "លេងណូត [NOTE] នៅផ្ទាំងខាងក្រោយ",
    "gui.blocklyText.esp32.setBuzzerFreq": "លេងណូត [NOTE] ចំនួន [BEAT] ប៊ីត",
    "gui.blocklyText.esp32.getSoundIntensity": "អានអាំងដង់ស៊ីតេសម្លេងរបស់មីក្រូហ្វូន",
    "gui.blocklyText.esp32.readBrightness": "អានកម្រិតពន្លឺនៃមជ្ឈដ្ឋាន",
    "gui.blocklyText.esp32.setLightsColor": "LED [LIGHTS] បង្ហាញ [COLOR]",
    "gui.blocklyText.esp32.rgbToColor": "ពណ៌ក្រហម [RED] ពណ៍បៃតង [GREEN] ពណ៍ខៀវ [BLUE] ",
    "gui.blocklyText.esp32.acceleration": "អានសន្ទុះ(mg) [TYPE]",
    "gui.blocklyText.esp32.displayInXY": "បង្ហាញ [TEXT] នៅកូអរដោណេ X: [X]  Y: [Y], មើលជាមុន​ [PREVIEW]",
    "gui.blocklyText.esp32.displayInXYForMpy": "សរសេរឃ្លាំងសម្ងាត់ [TEXT] នៅកូអរដោណេ X: [X] Y: [Y], មើលជាមុន [PREVIEW] [SHOWMODE]",
    "gui.blocklyText.esp32.fillScreen": "អេក្រង់បង្ហាញពណ៌ [COLOR] (លុបអេក្រង់)",
    "gui.blocklyText.esp32.fillScreenForMpy": "សរសេរឃ្លាំងសម្ងាត់ដែលអេក្រង់មានពណ៌ [COLORMPYTHON] (លុបអេក្រង់)",
    "gui.blocklyText.esp32.black": "ពណ៌ខ្មៅទាំងអស់",
    "gui.blocklyText.esp32.white": "ពណ៌សទាំងអស់",
    "gui.blocklyText.esp32.lineDrawing": "គូសបន្ទាត់ពីចំណុច x1:[X1] y1:[Y1] ទៅចំនុច x2:[X2] y2:[Y2]",
    "gui.blocklyText.esp32.connected": "បានភ្ជាប់",
    "gui.blocklyText.esp32.disconnected": "បានផ្តាច់",
    "gui.blocklyText.esp32.rectangleDrawing": "គូរចតុកោណកែង [FILL] ជាមួយជ្រុងខាងឆ្វេងនៃផ្នែកខាងលើនៅ x: [X] y: [Y], W: [WIDTH], H: [HEIGHT]",
    "gui.blocklyText.esp32.screenDisplay": "ឃ្លាំងសម្ងាត់អេក្រង់បង្ហាញបែបផែន",
    "gui.blocklyText.esp32.circleDrawing": "គូសរង្វង់​ [FILL]​ ជាមួយផ្ចិតនៅ x: [X] y:[Y], r:[RADIUS]",
    "gui.blocklyText.esp32.fill": "បំពេញ",
    "gui.blocklyText.esp32.notFill": "មិនបំពេញ",
    "gui.blocklyText.esp32.pointDrawing": "គូសចំនុចនៅ x:[X] y:[Y]",
    "gui.blocklyText.esp32.setLineWidth": "ដាក់ទំហំបន្ទាត់ត្រឹម [LINEWIDTH]",
    "gui.blocklyText.esp32.digitalWrite": "ជើងឌីជីថល [PIN] បញ្ចេញតម្លៃ [VALUE]",
    "gui.blocklyText.esp32.digitalRead": "អានជើងឌីជីថល [PIN]",
    "gui.blocklyText.esp32.analogWrite": "ជើងអាណាឡូក [PIN] បញ្ចេញតម្លៃ ​[VALUE]",
    "gui.blocklyText.esp32.analogRead": "អានជើងអាណាឡូក​ [PIN]",
    "gui.blocklyText.esp32.serialPort0": "ស៊េរី0",
    "gui.blocklyText.esp32.serialPort1": "ស៊េរី1",
    "gui.blocklyText.esp32.serialPort2": "ស៊េរី2",
    "gui.blocklyText.esp32.seriaRemapping": "[SERIAL] Rx [RX] Tx [TX] ល្បឿនបញ្ជូន [BAUD]",
    "gui.blocklyText.esp32.imageDrawing": "អេក្រង់បង្ហាញរូបភាព​ [IMAGE] នៅ x:[X] y:[Y]",
    "gui.blocklyText.esp32.imageDrawingForMpy": "សរសេរឃ្លាំងសម្ងាត់រូបភាព [IMAGE] នៅ x: [X] y: [Y]",
    "gui.blocklyText.esp32.pressed": "បានចុច",
    "gui.blocklyText.esp32.loosened": "បានលែង",
    "gui.blocklyText.esp32.all": "ទាំងអស់(-1)",
    "gui.blocklyText.esp32.setBaud": "ល្បឿនទិន្នន័យនៃច្រកសេរ៊ី0​ [BAUD]",
    "gui.blocklyText.esp32.readSerialDataIntFloat": "ពី​ [SERIAL]​ អាន​ទិន្នន័យសេរ៊ីជាចំនួនគត់ឬចំនួនទសភាគ [READTYPE]",
    "gui.blocklyText.esp32.setBrightness": "ដាក់កម្រិតពន្លឺ ​LED [BRIGHTVALUE]",
    "gui.blocklyText.esp32.getLightness": "ទទួលកម្រិតពន្លឺ LED",
    "gui.blocklyText.esp32.print": "ព្រីន [DATA]",
    "gui.blocklyText.esp32.TiltForward": "ទេរទៅមុខ",
    "gui.blocklyText.esp32.TiltBack": "ទេរទៅក្រោយ",
    "gui.blocklyText.esp32.TiltLeft": "ទេរទៅឆ្វេង",
    "gui.blocklyText.esp32.TiltRight": "ទេរទៅស្ដាំ",
    "gui.blocklyText.esp32.ScreenUp": "បញ្ឈរអេក្រង់",
    "gui.blocklyText.esp32.ScreenDown": "អេក្រង់ចុះក្រោម",
    "gui.blocklyText.esp32.Shake": "ក្រឡុក",
    "gui.blocklyText.esp32.isGesture": "គឺ [GESTURE]?",
    "gui.blocklyText.esp32.rgbDisable": "បិទដំណើរការ [INDEX] LED",
    "gui.blocklyText.esp32.rgbdisable.all": "ទាំងអស់",
    "gui.blocklyText.esp32.rgbdisable.No.0": "លេខ 0",
    "gui.blocklyText.esp32.rgbdisable.No.1": "លេខ 1",
    "gui.blocklyText.esp32.rgbdisable.No.2": "លេខ 2",
    "gui.blocklyText.esp32.playMusic": "លេងតន្ត្រី [MUSIC] ធ្វើឡើងវិញ​ [REPEAT]",
    "gui.blocklyText.esp32.Once": "ម្តង",
    "gui.blocklyText.esp32.Forever": "រហូត",
    "gui.blocklyText.esp32.OnceInBackground": "ធ្វើម្ដងនៅក្នុងផ្ទៃខាងក្រោយ",
    "gui.blocklyText.esp32.ForeverInBackground": "ធ្វើរហូតនៅក្នុងផ្ទៃខាងក្រោយ",
    "gui.blocklyText.mega2560.functionStarts": "Mega2560 ចាប់ផ្តើម",
    "gui.blocklyText.mega2560.digitalRead": "អានជើងឌីជីថល [PIN]",
    "gui.blocklyText.mega2560.analogRead": "អានជើងអាណាឡូក​ [PIN]",
    "gui.blocklyText.mega2560.pwmWrite": "ជើង PWM [PIN] បញ្ចេញតម្លៃ [VALUE]",
    "gui.blocklyText.mega2560.digitalWrite": "ជើងឌីជីថល [PIN] បញ្ចេញតម្លៃ [VALUE]",
    "gui.blocklyText.mega2560.level.low": "ទាប",
    "gui.blocklyText.mega2560.level.high": "ខ្ពស់",
    "gui.blocklyText.mega2560.irqRead": "អានជើងឌីជីថល [PIN] នៃការទទួលអាំងហ្វ្រាក្រហម",
    "gui.blocklyText.mega2560.ultrasonicRead": "អានឧបករណ៍ចាប់សញ្ញាប្រើសម្លេងអ៊ុលត្រា ទ្រីគ័រ [TRIG], ជះត្រឡប់ [ECHO]",
    "gui.blocklyText.mega2560.buzzerSet": "ជើង [PIN] លេងសម្លេងរោទិ៍ buzzer [TONE] ចំនួន [BEAT] ប៊ីត",
    "gui.blocklyText.mega2560.harf": "ពាក់កណ្តាល",
    "gui.blocklyText.mega2560.quarter": "មួយភាគបួន",
    "gui.blocklyText.mega2560.oneInEighth": "មួយភាគប្រាំបី",
    "gui.blocklyText.mega2560.wholeBeat": "ប៊ីតទាំងមូល",
    "gui.blocklyText.mega2560.doubleBeat": "ប៊ីតពីរដង",
    "gui.blocklyText.mega2560.stop": "បញ្ឈប់",
    "gui.blocklyText.mega2560.servoSet": "ដាក់ជើង [PIN] ស៊ែវូទៅមុំ [DEGREE] ដឺក្រេ",
    "gui.blocklyText.mega2560.setBaud": "ដាក់ [SERIAL] ល្បឿនបញ្ជូន [BAUD]",
    "gui.blocklyText.mega2560.readSerialDataIntFloat": "ពី​ [SERIAL]​ អាន​ទិន្នន័យសេរ៊ីជាចំនួនគត់ឬចំនួនទសភាគ [READTYPE]",
    "gui.blocklyText.mega2560.serialPort0": "ច្រកស៊េរី0",
    "gui.blocklyText.mega2560.serialPort1": "ច្រកស៊េរី1",
    "gui.blocklyText.mega2560.serialPort2": "ច្រកស៊េរី2",
    "gui.blocklyText.mega2560.serialPort3": "ច្រកស៊េរី3",
    "gui.blocklyText.mega2560.dataReadable": "តើមានទិន្នន័យដើម្បីអាននៅ [SERIAL]?",
    "gui.blocklyText.mega2560.readSerialData": "អានទិន្នន័យនៅ [SERIAL]",
    "gui.blocklyText.mega2560.stringOutput": "នៅក្នុងអក្សរ",
    "gui.blocklyText.mega2560.originalOutput": "លទ្ធផលដើម",
    "gui.blocklyText.mega2560.hexOutput": "នៅក្នុងប្រព័ន្ធគោល16",
    "gui.blocklyText.mega2560.wrap": "Wrap",
    "gui.blocklyText.mega2560.noWrap": "No-Wrap",
    "gui.blocklyText.mega2560.readPinLevel": "អានជើង [PIN] ទំហំអាំងពុលស្យុងក្រោមកម្រិត [LEVEL] ម៉ូត, អស់ពេល [TIMEOUT](us)",
    "gui.blocklyText.mega2560.readTimer": "ពេលវេលាដំណើរការប្រព័ន្ធ(ms)",
    "gui.blocklyText.beetleProMini.Starts": "Beetle Pro Mini ចាប់ផ្តើម",
    "gui.blocklyText.arduino.arduinoLeonardoStarts": "Leonardo ចាប់ផ្តើម",
    "gui.blocklyText.arduino.arduinoMega2560Starts": "Mega2560 ចាប់ផ្តើម",
    "gui.blocklyText.arduino.setDigital": "ជើងឌីជីថល [PIN] បញ្ចេញតម្លៃ [LEVEL]",
    "gui.blocklyText.arduino.readDigital": "អានជើងឌីជីថល [PIN]",
    "gui.blocklyText.arduino.readSimilation": "អានជើងអាណាឡូក​ [PIN]",
    "gui.blocklyText.arduino.setPwm": "ជើង PWM [PIN] បញ្ចេញតម្លៃ [VALUE]",
    "gui.blocklyText.arduino.readUlrasonic": "អានចម្ងាយប្រើសម្លេងអ៊ុលត្រា(cm) ទីគ័រ [TRIG] ជះត្រឡប់ [ECHO]",
    "gui.blocklyText.arduino.trumpet": "ជើង [PIN] លេងសម្លេង trumpet [TONE] ចំនួន​ [BEAT] ប៊ីត",
    "gui.blocklyText.arduino.serialSendStr": "លទ្ធផលពីស៊េរី [STRING] [WAY],[LINE]",
    "gui.blocklyText.arduino.originalOutput": "នៅក្នុងគល់តម្រុយ",
    "gui.blocklyText.arduino.stringOutput": "នៅក្នុងពាក្យ",
    "gui.blocklyText.arduino.hexOutput": "នៅក្នុងប្រព័ន្ធគោល16",
    "gui.blocklyText.arduino.wrap": "Wrap",
    "gui.blocklyText.arduino.noWrap": "No-Wrap",
    "gui.blocklyText.arduino.readInfrared": "អានជើងឌីជីថល [PIN] នៃការទទួលអាំងហ្វ្រាក្រហម",
    "gui.blocklyText.arduino.setBaud": "ដាក់ល្បឿនបញ្ជូនតាមច្រកស៊េរី [BAUD]",
    "gui.blocklyText.arduino.readPinLevel": "អានជើង [PIN] ទំហំអាំងពុលស្យុងជាមួយលំនាំ [LEVEL], អស់ពេល [TIMEOUT] (us)",
    "gui.blocklyText.arduino.noSound": "គ្មានសំឡេង",
    "gui.blocklyText.arduinoNano.arduinoNanoStarts": "Arduino Nano ចាប់ផ្តើម",
    "gui.blocklyText.vortex.vortexStart": "កម្មវិធីរ៉ូបូត Vortex ចាប់ផ្តើម",
    "gui.blocklyText.vortex.vortexMoveSpeed": "រ៉ូបូតVortex​ [DIRECTION] នៅល្បឿន [SPEED]",
    "gui.blocklyText.vortex.forward": "ទៅមុខ",
    "gui.blocklyText.vortex.backup": "ថយក្រោយ",
    "gui.blocklyText.vortex.turnLeft": "បត់ឆ្វេង",
    "gui.blocklyText.vortex.turnRight": "បត់ស្តាំ",
    "gui.blocklyText.vortex.setMotor": "ម៉ូទ័រ [MOTOR] [DIRECTION] នៅល្បឿន [SPEED]",
    "gui.blocklyText.vortex.leftWheel": "ឆ្វេង",
    "gui.blocklyText.vortex.rightWheel": "ស្តាំ",
    "gui.blocklyText.vortex.setExpression": "បង្ហាញលំនាំ​​ [EXPRESSION] ក្នុង [COLOR]",
    "gui.blocklyText.vortex.ledLights": "[DIRECTION][LIGHT]​ ប្តូរទៅជា [COLOR] នៅក្នុង [SECOND] វិនាទី",
    "gui.blocklyText.vortex.all": "ទាំងអស់",
    "gui.blocklyText.vortex.rightAhead": "មុខ",
    "gui.blocklyText.vortex.leftRear": "ខាងក្រោយខាងឆ្វេង",
    "gui.blocklyText.vortex.rightRear": "ខាងក្រោយខាងស្តាំ",
    "gui.blocklyText.vortex.rightAstern": "ខាងក្រោយ",
    "gui.blocklyText.vortex.topLights": "ពន្លឺខាងលើ",
    "gui.blocklyText.vortex.bottomLights": "ពន្លឺខាងក្រោម",
    "gui.blocklyText.vortex.vortexStop": "Vortex​ ឈប់ផ្លាស់ទី",
    "gui.blocklyText.vortex.setVolume": "ដាក់កម្រិតសម្លេងត្រឹម [VALUE]",
    "gui.blocklyText.vortex.playMusic": "លេង [VALUE] តន្រ្តី",
    "gui.blocklyText.vortex.stopMusic": "បញ្ឈប់ការលេងតន្ត្រី",
    "gui.blocklyText.vortex.startDance": "ចាប់ផ្តើម​ [VALUE] រាំ",
    "gui.blocklyText.vortex.stopDance": "បញ្ឈប់ការរាំ",
    "gui.blocklyText.vortex.barrierDetection": "ឧបសគ្គនៅ​ខាងមុខ?",
    "gui.blocklyText.vortex.readSensor": "អានឧបករណ៍វាស់កម្រិតប្រផេះ [SENSOR]",
    "gui.blocklyText.vortex.setSensor": "ដាក់ញាណឧបករណ៍វាស់កម្រិតប្រផេះត្រឹម [VALUE]",
    "gui.blocklyText.vortex.sensorMeetBlack": "ឧបករណ៍វាស់កម្រិតប្រផេះជួបពណ៌ខ្មៅនៅទីតាំងរបស់វា [SENSOR]?",
    "gui.blocklyText.vortex.positiveFrontLeft": "ផ្នែកខាងមុខខាងឆ្វេងត្រង់",
    "gui.blocklyText.vortex.frontLeft": "ផ្នែកខាងមុខខាងឆ្វេង",
    "gui.blocklyText.vortex.positiveFrontRight": "ផ្នែកខាងមុខខាងស្តាំត្រង់",
    "gui.blocklyText.vortex.frontRight": "ផ្នែកខាងមុខខាងស្តាំ",
    "gui.blocklyText.vortex.backRight": "ខាងក្រោយខាងស្តាំ",
    "gui.blocklyText.vortex.backLeft": "ខាងក្រោយខាងឆ្វេង",
    "gui.blocklyText.vortex.initialOldPCB": "ចាប់ផ្តើមទៅកំណែចាស់ (PCB ខៀវ)",
    "pen.categoryName": "ប៊ិច",
    "pen.changeColorParam": "ប្តូរពណ៌ប៊ិច [COLOR_PARAM]​​ ត្រឹម [VALUE]",
    "pen.changeHue": "ប្តូរពណ៌ប៊ិចត្រឹម [HUE]",
    "pen.changeShade": "ប្តូរកម្រិតពន្លឺប៊ិចត្រឹម [SHADE]",
    "pen.changeSize": "ប្តូរទំហំប៊ិចត្រឹម [SIZE]",
    "pen.clear": "លុបទាំងអស់",
    "pen.colorMenu.brightness": "កម្រិតពន្លឺ",
    "pen.colorMenu.color": "ពណ៌",
    "pen.colorMenu.saturation": "កម្រិតដិត",
    "pen.colorMenu.transparency": "ភាពច្បាស់",
    "pen.penDown": "ទាញប៊ិចចុះ",
    "pen.penUp": "លើកប៊ិចឡើង",
    "pen.setColor": "ដាក់ពណ៌ប៊ិចត្រឹម [COLOR]",
    "pen.setColorParam": "ដាក់ប៊ិច [COLOR_PARAM] ត្រឹម [VALUE]",
    "pen.setHue": "ដាក់ពណ៌ប៊ិចទៅជា [HUE]",
    "pen.setShade": "ដាក់កម្រិតពន្លឺប៊ិចទៅជា [SHADE]",
    "pen.setSize": "ដាក់ទំហំប៊ិចត្រឹម [SIZE]",
    "pen.stamp": "ត្រា",
    "music.changeTempo": "ប្តូរចង្វាក់ត្រឹម [TEMPO]",
    "music.drumBass": "(2) Bass Drum",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15)  Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Closed Hi-Hat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Cowbell",
    "music.drumCrashCymbal": "(4) Crash Cymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Hand Clap",
    "music.drumOpenHiHat": "(5) Open Hi-Hat",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Snare Drum",
    "music.drumTambourine": "(7) Tambourine",
    "music.drumTriangle": "(12) Triangle",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Wood Block",
    "music.getTempo": "ចង្វាក់",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Bassoon",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Choir",
    "music.instrumentClarinet": "(10) Clarinet",
    "music.instrumentElectricGuitar": "(5) Electric Guitar",
    "music.instrumentElectricPiano": "(2) Electric Piano",
    "music.instrumentFlute": "(12) Flute",
    "music.instrumentGuitar": "(4) Guitar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Music Box",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxophone",
    "music.instrumentSteelDrum": "(18) Steel Drum",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibraphone",
    "music.instrumentWoodenFlute": "(13) Wooden Flute",
    "music.midiPlayDrumForBeats": "លេងស្គរ [DRUM] ចំនួន [BEATS] ប៊ីត",
    "music.midiSetInstrument": "ដាក់ឧបករណ៍តន្ត្រីទៅជា [INSTRUMENT]",
    "music.playDrumForBeats": "លេងស្គរ [DRUM] ចំនួន [BEATS] ប៊ីត",
    "music.playNoteForBeats": "លេងណូត [NOTE] ចំនួន [BEATS] ប៊ីត",
    "music.restForBeats": "ផ្អាកចំនួន [BEATS] ប៊ីត",
    "music.setInstrument": "ដាក់ឧបករណ៍តន្ត្រីទៅជា [INSTRUMENT]",
    "music.setTempo": "ដាក់ចង្វាក់ត្រឹម [TEMPO]",
    "gui.blocklyText.microbit.microbitStarts": "micro:bit ចាប់ផ្តើម",
    "gui.blocklyText.microbit.interruptExcute": "ជើងរំខាន [PIN] ម៉ូត [MODE] ប្រតិបត្តិការ",
    "gui.blocklyText.microbit.cancleInterrupt": "លុបចោលជើងរំខាន [PIN]",
    "gui.blocklyText.microbit.wirelessReceiveData": "នៅពេលដែលបានទទួលទិន្នន័យឥតខ្សែ",
    "gui.blocklyText.microbit.whenBtnPress": "នៅពេលដែលប៊ូតុង [REFERENCE] ត្រូវបានចុច",
    "gui.blocklyText.microbit.whenPinConnected": "នៅពេលដែលជើង microbit [REFERENCE] ត្រូវបានភ្ជាប់",
    "gui.blocklyText.microbit.whenPosChange": "នៅពេល [REFERENCE]",
    "gui.blocklyText.microbit.showFont": "បង្ហាញលំនាំ [PIC]",
    "gui.blocklyText.microbit.showInput": "បង្ហាញ [TEXT]",
    "gui.blocklyText.microbit.stopAnimation": "បញ្ឈប់គំនូរជីវចល",
    "gui.blocklyText.microbit.controlLight": "[CONTROL] កូអរដោណេនៃចំណុច (x:[XAXIS], y:[YXAXIS]）",
    "gui.blocklyText.microbit.hideAllLights": "លុបម៉ាទ្រីសចំណុចទាំងអស់",
    "gui.blocklyText.microbit.microbitEnableLight": "អេក្រង់ម៉ាទ្រីស [ENABLE]",
    "gui.blocklyText.microbit.getLightness": "កម្រិតពន្លឺ",
    "gui.blocklyText.microbit.showLightWithBrightness": "ដាក់កម្រិតពន្លឺ [BRIGHTVALUE]",
    "gui.blocklyText.microbit.controlLightBrightness": "បង្ហាញកូអរដោណេនៃចំណុច (x:[XAXIS], y:[YXAXIS]), កម្រិតពន្លឺ [BRIGHTVALUE]",
    "gui.blocklyText.microbit.playSound": "ជើង [PIN] លេងសម្លេង [SOUND]",
    "gui.blocklyText.microbit.playSoundUntil": "ជើង [PIN] លេងសម្លេង [SOUND] រហូតដល់ចប់",
    "gui.blocklyText.microbit.playNote": "ជើង [PIN] លេងណូត [NOTE] ចំនួន [BEAT] ប៊ីត",
    "gui.blocklyText.microbit.true": "បើកដំណើរការ",
    "gui.blocklyText.microbit.false": "បិទដំណើរការ",
    "gui.blocklyText.maqueen.playSound": "ជើង P0 លេងសម្លេង [SOUND]",
    "gui.blocklyText.maqueen.playSoundUntil": "ជើង P0 លេងសម្លេង [SOUND] រហូតដល់ចប់",
    "gui.blocklyText.maqueen.playNote": "ជើង P0 លេងណូត [NOTE] ចំនួន [BEAT] ប៊ីត",
    "gui.blocklyText.maqueen.RGB0": "RGB0 (0)",
    "gui.blocklyText.maqueen.RGB1": "RGB1 (1)",
    "gui.blocklyText.maqueen.RGB2": "RGB2 (2)",
    "gui.blocklyText.maqueen.RGB3": "RGB3 (3)",
    "gui.blocklyText.maqueen.All": "ទាំងអស់ (-1)",
    "gui.blocklyText.microbit.changeTempo": "ប្តូរចង្វាក់ (bpm) ត្រឹម [VALUE]",
    "gui.blocklyText.microbit.setTempo": "ដាក់ចង្វាក់ (bpm) ត្រឹម [VALUE]",
    "gui.blocklyText.microbit.getTempo": "ចង្វាក់ (bpm)",
    "gui.blocklyText.microbit.stopSound": "បញ្ឈប់ការចាក់សារឡើងវិញនៃផ្ទាំងខាងក្រោយ",
    "gui.blocklyText.microbit.btnIsPressed": "[BUTTON] ប៊ូតុងត្រូវបានចុច?",
    "gui.blocklyText.microbit.pinIsConnected": "[PIN] ជើងត្រូវបានភ្ជាប់?",
    "gui.blocklyText.microbit.isMove": "ស្ថានភាពបច្ចុប្បន្ន [TYPE]",
    "gui.blocklyText.microbit.compass": "អានទិសដៅត្រីវិស័យ",
    "gui.blocklyText.microbit.temperature": "អានសីតុណ្ហភាព",
    "gui.blocklyText.microbit.digitalWrite": "ជើងឌីជីថល [PIN] ច្រកចេញ [VALUE]",
    "gui.blocklyText.microbit.digitalRead": "អានជើងឌីជីថល [PIN]",
    "gui.blocklyText.microbit.analogWrite": "អានជើងអាណាឡូក [PIN] បញ្ចេញតម្លៃ [VALUE]",
    "gui.blocklyText.microbit.analogRead": "អានជើងអាណាឡូក [PIN]",
    "gui.blocklyText.microbit.up": "រូបសញ្ញាឡើង",
    "gui.blocklyText.microbit.down": "រូបសញ្ញាចុះ",
    "gui.blocklyText.microbit.left": "ផ្អៀងទៅឆ្វេង",
    "gui.blocklyText.microbit.right": "ផ្អៀងទៅស្តាំ",
    "gui.blocklyText.microbit.faceUp": "ងើបមុខឡើង",
    "gui.blocklyText.microbit.faceDown": "អោនមុខចុះ",
    "gui.blocklyText.microbit.freefall": "ចលនាទំលាក់សេរី",
    "gui.blocklyText.microbit.3g": "៣g",
    "gui.blocklyText.microbit.6g": "៦g",
    "gui.blocklyText.microbit.8g": "៨g",
    "gui.blocklyText.microbit.shake": "អង្រួន",
    "gui.blocklyText.microbit.show": "បង្ហាញ",
    "gui.blocklyText.microbit.hide": "លាក់",
    "gui.blocklyText.microbit.low": "ទាប",
    "gui.blocklyText.microbit.high": "ខ្ពស់",
    "gui.blocklyText.microbit.microbitReadBrightness": "អានពន្លឺនៃមជ្ឈដ្ឋាន",
    "gui.blocklyText.microbit.microbitOpenWireless": "[SWITCH] ​ការទំនាក់ទំនងឥតខ្សែ",
    "gui.blocklyText.microbit.microbitSetWirelessChannel": "ដាក់កាណាល់ឥតខ្សែទៅកាន់ [NUM]",
    "gui.blocklyText.microbit.microbitSendStrByWireless": "បញ្ជូនអក្សរ [TEXT] តាមបច្ចេកវិទ្យាឥតខ្សែ",
    "gui.blocklyText.microbit.microbitReceivedDataByWireless": "ទទួលទិន្នន័យតាមបច្ចេកវិទ្យាឥតខ្សែ",
    "gui.blocklyText.microbit.strength": "កម្លាំង",
    "gui.blocklyText.microbit.acceleration": "អានសំទុះ (mg)[TYPE]",
    "gui.blocklyText.microbit.magneticForce": "អានកម្លាំងម៉ាញ៉េទិក (µT)[TYPE]",
    "gui.blocklyText.microbit.print": "ព្រីន [DATA]",
    "gui.blocklyText.microbit.goUp": "ឡើង",
    "gui.blocklyText.microbit.goDown": "ចុះ",
    "gui.blocklyText.microbit.specialWirelessReceiveData": "នៅពេលបានទទួល [WirelessData]",
    "gui.blocklyText.microbit.wirelessData": "ទិន្នន័យឥតខ្សែ",
    "gui.blocklyText.maxbot.microbitStarts": "Maxbot ចាប់ផ្តើម",
    "gui.blocklyText.maxbot.playSound": "លេងសម្លេង [SOUND]",
    "gui.blocklyText.maxbot.playSoundUntil": "លេងសម្លេង [SOUND] រហូតដល់ចប់",
    "gui.blocklyText.maxbot.maxbotLeftRightCollision": "ការប៉ះទង្គិចកើតឡើង [LEFTRIGHT]?",
    "gui.blocklyText.maxbot.maxbotLeftRightBlackLine": "បន្ទាត់ពណ៌ខ្មៅត្រូវបានរកឃើញនៅលើ [LEFTRIGHT]?",
    "gui.blocklyText.maxbot.maxbotSetMotorMoveSpeed": "ម៉ូទ័រ [MOTOR] [DIR] នៅល្បឿន [SPEED]",
    "gui.blocklyText.maxbot.maxbotReadUltrasound": "អាន (P1, P2) ឧបករណ៍ចាប់សញ្ញាប្រើសម្លេងអ៊ុលត្រា [UNIT]",
    "gui.blocklyText.sen0001.readUltrasound": "អានឧបករណ៍ចាប់សញ្ញាប្រើសម្លេងអ៊ុយត្រា [UNIT], ទីគ័រ [TRIG], ជះត្រឡប់ [ECHO]",
    "gui.blocklyText.maxbot.FORWARD": "ទៅមុខ",
    "gui.blocklyText.maxbot.BACKWARD": "ថយក្រោយ",
    "gui.blocklyText.motorbit.CW": "បង្វិលតាមទ្រនិចនាឡិកា",
    "gui.blocklyText.motorbit.CCW": "បង្វិលបញ្ច្រាសទ្រនិចនាឡិកា",
    "gui.blocklyText.maxbot.CW": "បង្វិលតាមទ្រនិចនាឡិកា",
    "gui.blocklyText.maxbot.CCW": "បង្វិលបញ្ច្រាសទ្រនិចនាឡិកា",
    "gui.blocklyText.maxbot.ANGLE": "ដឺក្រេ",
    "gui.blocklyText.maxbot.CIRCLE": "ចំនួនជុំ",
    "gui.blocklyText.maxbot.Left": "ឆ្វេង",
    "gui.blocklyText.maxbot.Right": "ស្តាំ",
    "gui.blocklyText.maxbot.cm": "សង់ទីម៉ែត្រ",
    "gui.blocklyText.maxbot.inch": "អ៊ីញ",
    "gui.blocklyText.MotorBit.microbitStarts": "MotorBit ចាប់ផ្តើម",
    "gui.blocklyText.MotorBit.MotorRun": "ម៉ូទ័រ [MOTOR] វិល [DIR] នៅល្បឿន [SPEED]",
    "gui.blocklyText.MotorBit.MotorStop": "ម៉ូទ័រ [MOTOR] ឈប់វិល",
    "gui.blocklyText.MotorBit.Stepper": "ម៉ូទ័រ stepper [STEPPER] វិល [ROTATEDIR] ត្រឹម [NUM] [ANGLECIRCLE]",
    "gui.blocklyText.MotorBit.all": "ទាំងអស់",
    "gui.blocklyText.maqueen.maqueenStarts": "Maqueen ចាប់ផ្តើម",
    "gui.blocklyText.maqueen.readUltrasonicDistance": "អាន (P1, P2) ឧបករណ៍ចាប់សញ្ញាប្រើសម្លេងអ៊ុយត្រា (cm)",
    "gui.blocklyText.maqueen.motorStop": "រ៉ូបូត (ម៉ូទ័រឆ្វេងនិងស្តាំ) ឈប់",
    "gui.blocklyText.maqueen.controlLeds": "[SWITCH] [LEFTRIGHT] អំពូល LED",
    "gui.blocklyText.maqueen.redLineSensor": "អាន [LEFTRIGHT] ឧបករណ៍តាមដានខ្សែបន្ទាត់",
    "gui.blocklyText.maqueen.robotSpeedMove": "រ៉ូបូត [DIR] នៅល្បឿន [SPEED]",
    "gui.blocklyText.maqueen.setMotorSpeedMove": "ម៉ូទ័រ [LEFTRIGHT] ផ្លាស់ទី [DIR] នៅល្បឿន [SPEED]",
    "gui.blocklyText.maqueen.ledLeft": "ឆ្វេង (P8)",
    "gui.blocklyText.maqueen.ledRight": "ស្តាំ (P12)",
    "gui.blocklyText.spreadmaqueen.ledRight": "ស្តាំ (P12) (មិនអាចប្រើបាន)",
    "gui.blocklyText.maqueen.lineSensorLeft": "ឆ្វេង (P13)",
    "gui.blocklyText.maqueen.lineSensorRight": "ស្តាំ (P14)",
    "gui.blocklyText.maqueen.whenReceiveInfraredSignal": "នៅពេល (P16) បានទទួល [WirelessData]",
    "gui.blocklyText.maqueen.wirelessSignalData": "សញ្ញាអ៊ីនហ្វ្រាក្រហម",
    "gui.blocklyText.maqueen.readInfraredSignalValue": "អាន (P16) សញ្ញាអ៊ីនហ្វ្រាក្រហម",
    "gui.blocklyText.maqueen.numberOfLightsBright": "អំពូល RGB ជើង P15 ចំនួនអំពូល 4 ភ្លឺ [BRIGHT]",
    "gui.blocklyText.maqueen.allLedsShowColorFromWith": "អំពូល RGB ជើង P15 [NUM1] បង្ហាញពណ៌ [COLOR]",
    "gui.blocklyText.maqueen.showGradientColor": "អំពូល RGB ជើង P15 ពីលេខ [NUM1] ដល់ [NUM2] បង្ហាញពណ៌ជម្រាលចាប់ពី [COLOR1] ដល់ [COLOR2]",
    "gui.blocklyText.maqueen.shiftPixelsToShow": "អំពូល RGB ជើង P15 រំកិលភីកសែលត្រឹម [NUM]",
    "gui.blocklyText.maqueen.rotatePixelsToShow": "អំពូល RGB ជើង P15 បង្វិលភីកសែលត្រឹម [NUM]",
    "gui.blocklyText.maqueen.setWs2812Brightness": "អំពូល RGB ជើង P15 ភ្លឺត្រឹម [BRIGHTNESS]",
    "gui.blocklyText.maqueen.showHistogram": "អំពូល RGB ជើង P15 ពី [NUM1] ដល់ [NUM2] បង្ហាញអ៊ីស្តូក្រាម, តម្លៃ [VALUE], អតិបរមា [MAX]",
    "gui.blocklyText.maqueen.clear": "អំពូល RGB ជើង P15 បិទទាំងអស់",
    "gui.blocklyText.maqueen.rgbColor": "ក្រហម [RED] បៃតង [GREEN] ខៀវ [BLUE]",
    "gui.blocklyText.obloq.isReceivedData": "[TOPIC] បានទទួលទិន្នន័យ?",
    "gui.blocklyText.obloq.initialParameter": "Obloq mqtt លក្ខខណ្ឌដំបូង: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.sendmsgToCloudPlatform": "Obloq ផ្ញើសារ [MSG] ទៅកាន់ [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatformUno": "Obloq អានសារពី [TOPIC]",
    "gui.blocklyText.obloq.readMsgFromCloudPlatform": "Obloq អានសារ",
    "gui.blocklyText.obloq.httpInitialParameter": "Obloq http លក្ខខណ្ឌដំបូង: [PARAMETER] [SERIALPORT] TX [PINR] RX [PINT]",
    "gui.blocklyText.obloq.httpGet": "Obloq http(get) url: [URL] អស់ពេល (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPost": "Obloq http(post) url: [URL] មាតិកា: [CONTENT] អស់ពេល (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.httpPut": "Obloq http(put) url: [URL] មាតិកា: [CONTENT] អស់ពេល (ms): [TIMEOUT]",
    "gui.blocklyText.obloq.addSubscribe": "បន្ថែមការជាវ [TOPIC] : [MSG]",
    "gui.blocklyText.obloq.username": "ឈ្មោះ Wi-Fi",
    "gui.blocklyText.obloq.password": "លេខកូដសំងាត់",
    "gui.blocklyText.obloq.iotService": "សេវា IoT",
    "gui.blocklyText.obloq.iotId": "lot_id",
    "gui.blocklyText.obloq.iotPwd": "lot_pwd",
    "gui.blocklyText.obloq.iotTopic": "Topic_0",
    "gui.blocklyText.obloq.httpSetting": "HTTP",
    "gui.blocklyText.obloq.ip": "អាសយដ្ឋាន IP",
    "gui.blocklyText.obloq.port": "ច្រក",
    "gui.blocklyText.obloq.addTopic": "បន្ថែម Topic, អាចបន្ថែមបានត្រឹម ៤",
    "gui.blocklyText.obloq.deleteTopic": "លុប Topic",
    "gui.blocklyText.obloq.moreTopic": "Topic",
    "gui.blocklyText.obloq.server": "ម៉ាស៊ីនមេ",
    "gui.blocklyText.obloq.global": "សាកល",
    "gui.blocklyText.obloq.china": "ចិន",
    "gui.blocklyText.obloq.softwareSerial": "ច្រកសេរ៊ីប្រើកម្មវិធី",
    "gui.blocklyText.obloq.hardwareSerial": "ច្រកសេរ៊ីប្រើឧបករណ៍",
    "gui.blocklyText.obloq.hardwareSerial1": "ច្រកសេរ៊ីប្រើឧបករណ៍១",
    "gui.blocklyText.obloq.hardwareSerial2": "ច្រកសេរ៊ីប្រើឧបករណ៍២",
    "gui.blocklyText.obloq.hardwareSerial3": "ច្រកសេរ៊ីប្រើឧបករណ៍៣",
    "gui.blocklyText.obloq.WhenReceivedData": "នៅពេល [TOPIC] បានទទួល [MSG]",
    "gui.blocklyText.obloq.ObloqMsg": "សារ Obloq",
    "gui.blocklyText.obloq.whenDebugInfo": "Obloq ទទួល [DEBUG] [ERROR]",
    "gui.blocklyText.obloq.DEBUG": "ពត៌មាន Debug",
    "gui.blocklyText.obloq.ERROR": "កូដកំហុស",
    "gui.blocklyText.infraredEmission.emissionPinAndBit": "ការបំភាយអ៊ីនហ្វ្រាក្រហម [TYPE] ជើង [PIN] តម្លៃ [VALUE] ចំនួនប៊ីត [BIT]",
    "gui.blocklyText.oled2864.displayInit": "OLED 128*64 បង្ហាញអាសយដ្ឋានចាប់ផ្តើម [ADDR]",
    "gui.blocklyText.oled2864.displayInLine": "OLED 128*64 បង្ហាញ [TEXT] នៅជួរទី [LINE]",
    "gui.blocklyText.oled2864.displayInXY": "OLED 128*64 បង្ហាញ [TEXT] នៅទីតាំង X: [X] Y*16: [Y]",
    "gui.blocklyText.oled2864.screenRotation": "អេក្រង់បង្វិលទៅមុំ [ROTATION] ដឺក្រេ",
    "gui.blocklyText.oled2864.clear": "OLED 128*64 លុបចោល",
    "gui.blocklyText.dhtTHSensor.readMoistureTemperature": "អានជើង [PIN] [DHT] [TYPE]",
    "gui.blocklyText.sen0170.readWindspeed": "អានជើង [PIN] មាត្រដ្ឋានខ្យល់",
    "gui.blocklyText.sen0228.init": "ដំណើរការពីដំបូងនៃឧបករណ៍វាស់ពន្លឺនៃមជ្ឈដ្ឋាន (VEML7700)",
    "gui.blocklyText.sen0228.readAmbientLight": "អានពន្លឺនៃមជ្ឈដ្ឋាន (VEML7700)",
    "gui.blocklyText.dfr0026.readAmbientLight": "អានជើង [PIN] ពន្លឺនៃមជ្ឈដ្ឋាន",
    "gui.blocklyText.sen0177.readDustSensor": "អាន [TYPE] (ug/m3) អន្តរមុខ [SERIALPORT] RX [PINR] TX [PINT]",
    "gui.blocklyText.sen0202.readGestureSensor": "ជើង(D) [PIN] ជើង(MCLR) [RST] កាយវិការបច្ចុប្បន្ន [TYPE]?",
    "gui.blocklyText.sen0203.readHeartRate": "អានជើង [PIN] [TYPE] ប្តូរទៅ [SWITCH]",
    "gui.blocklyText.dfr0021.setLed": "[SWITCH] ជើង [PIN] LED",
    "gui.blocklyText.dfr0022.readGrayscale": "អានជើង [PIN] ជាមួយកម្រិតប្រផេះ",
    "gui.blocklyText.motucamera.initIICSerial": "ចាប់ផ្តើមកាមេរ៉ា [MU] អន្តរមុខ [IICSERIAL] រហូតដល់ការចាប់ផ្តើមជោគជ័យ",
    "gui.blocklyText.motucamera.resetConfig": "កាមេរ៉ា [MU] កំណត់ឡើងវិញទៅតាមលំនាំដើម",
    "gui.blocklyText.motucamera.algorithmSwitch": "កាមេរ៉ា [MU] [SWITCH] ក្បួនដោះស្រាយ [ALGORITHM]",
    "gui.blocklyText.motucamera.detectAndUndetectColorWithLed": "កាមេរ៉ា [MU] LED [LED] នៅពេលចាប់បានពណ៌ [COLOR1] និងនៅពេលដែលចាប់មិនបានពណ៌ [COLOR2]",
    "gui.blocklyText.motucamera.setAlgorithmPerformance": "កាមេរ៉ា [MU] ដាក់ក្បួនដោះស្រាយ [ALGORITHM] កម្រិត [PERFORMANCE]",
    "gui.blocklyText.motucamera.setDigitalZoom": "កាមេរ៉ា [MU] ពង្រីកបែបឌីជីថល [DIGITALZOOM]",
    "gui.blocklyText.motucamera.setBaudrate": "កាមេរ៉ា [MU] ដាក់ល្បឿនបញ្ជូន [BAUDRATE]",
    "gui.blocklyText.motucamera.setWhiteBalance": "កាមេរ៉ា [MU] ដាក់លំនឹងតុល្យភាពពណ៌ស [WHITEBALANCE]",
    "gui.blocklyText.motucamera.setHighFPS": "កាមេរ៉ា [MU] ដាក់ម៉ូត FPS ខ្ពស់ [OPENED]",
    "gui.blocklyText.motucamera.isDetect": "កាមេរ៉ា [MU] ចាប់បាន [ALGORITHM]",
    "gui.blocklyText.motucamera.colorRecognizeXY": "កាមេរ៉ា [MU] ចាប់បានពណ៌ដែលស្គាល់នៅ x= [NUMX] y=[NUMY]",
    "gui.blocklyText.motucamera.colorDetect": "កាមេរ៉ា [MU] ចាប់បានពណ៌។ ពណ៌ដែលចាប់បាន = [COLOR3]",
    "gui.blocklyText.motucamera.colorDetectPosition": "បានទទួល [MU] ក្បួនដោះស្រាយ [ALGORITHM] [POSITION]",
    "gui.blocklyText.motucamera.colorRecognizeChannel": "បានទទួល [MU] ស្គាល់ពណ៌ [CHANNEL]",
    "gui.blocklyText.motucamera.shapeCard": "កាមេរ៉ា [MU] រូបរាងកាត [CARD]",
    "gui.blocklyText.motucamera.trafficCard": "កាមេរ៉ា [MU] កាតចរាចរណ៍ [DIRECTION]",
    "gui.blocklyText.motucamera.numberCard": "កាមេរ៉ា [MU] លេខកាត [NUM]",
    "gui.blocklyText.motucamera.colorRecognizeColor": "កាមេរ៉ា [MU] ពណ៌ដែលស្គាល់ = [COLOR3]",
    "gui.blocklyText.motucamera.algorithmSwitchColorDetect": "ចាប់បានពណ៌",
    "gui.blocklyText.motucamera.algorithmSwitchColorRecognize": "ការទទួលស្គាល់ពណ៌",
    "gui.blocklyText.motucamera.algorithmSwitchBallDetect": "ចាប់បានស្វ៊ែរ",
    "gui.blocklyText.motucamera.algorithmSwitchBodyDetect": "រកឃើញរាងកាយមនុស្ស",
    "gui.blocklyText.motucamera.algorithmSwitchShapeCard": "រូបរាងកាត",
    "gui.blocklyText.motucamera.algorithmSwitchTrafficCard": "កាតចរាចរណ៍",
    "gui.blocklyText.motucamera.algorithmSwitchNumberCard": "លេខកាត",
    "gui.blocklyText.motucamera.algorithmSwitchEnable": "បើកដំណើរការ",
    "gui.blocklyText.motucamera.algorithmSwitchDisable": "បិទដំណើរការ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedOff": "បិទ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedRed": "ពណ៌ក្រហម",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedGreen": "ពណ៌បៃតង",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedBlue": "ពណ៌ខៀវ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedYellow": "ពណ៌លឿង",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedPurple": "ពណ៌ស្វាយ",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedCayan": "ពណ៌ខៀវខ្ចី",
    "gui.blocklyText.motucamera.detectAndUndetectColor2WithLedWhite": "ពណ៌ស",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedOff": "បិទ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedRed": "ពណ៌ក្រហម",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedGreen": "ពណ៌បៃតង",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedBlue": "ពណ៌ខៀវ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedYellow": "ពណ៌លឿង",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedPurple": "ពណ៌ស្វាយ",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedCayan": "ពណ៌ខៀវខ្ចី",
    "gui.blocklyText.motucamera.detectAndUndetectColor1WithLedWhite": "ពណ៌ស",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAuto": "ស្វ័យប្រវត្តិ",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceSpeed": "ល្បឿន",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceBalance": "តុល្យភាព",
    "gui.blocklyText.motucamera.setAlgorithmPerformanceAccuracy": "ភាពត្រឹមត្រូវ",
    "gui.blocklyText.motucamera.setWhiteBalanceAuto": "ស្វ័យប្រវត្តិ",
    "gui.blocklyText.motucamera.setWhiteBalanceLock": "ចាក់សោរ",
    "gui.blocklyText.motucamera.setWhiteBalanceWhite": "ពណ៌ស",
    "gui.blocklyText.motucamera.setWhiteBalanceYellow": "ពណ៌លឿង",
    "gui.blocklyText.motucamera.setDigitalZoomAuto": "ស្វ័យប្រវត្តិ",
    "gui.blocklyText.motucamera.setDigitalZoomLevel1": "កម្រិត១",
    "gui.blocklyText.motucamera.setDigitalZoomLevel2": "កម្រិត២",
    "gui.blocklyText.motucamera.setDigitalZoomLevel3": "កម្រិត៣",
    "gui.blocklyText.motucamera.setDigitalZoomLevel4": "កម្រិត៤",
    "gui.blocklyText.motucamera.setDigitalZoomLevel5": "កម្រិត៥",
    "gui.blocklyText.motucamera.setHighFPSOff": "បិទ",
    "gui.blocklyText.motucamera.setHighFPSOn": "បើក",
    "gui.blocklyText.motucamera.colorDetectBlack": "ពណ៌ខ្មៅ",
    "gui.blocklyText.motucamera.colorDetectWhite": "ពណ៌ស",
    "gui.blocklyText.motucamera.colorDetectRed": "ពណ៌ក្រហម",
    "gui.blocklyText.motucamera.colorDetectYellow": "ពណ៌លឿង",
    "gui.blocklyText.motucamera.colorDetectGreem": "ពណ៌បៃតង",
    "gui.blocklyText.motucamera.colorDetectCayan": "ពណ៌ខៀវខ្ចី",
    "gui.blocklyText.motucamera.colorDetectBlue": "ពណ៌ខៀវ",
    "gui.blocklyText.motucamera.colorDetectPerple": "ពណ៌ស្វាយ",
    "gui.blocklyText.motucamera.colorDetectOthers": "ផ្សេងៗ",
    "gui.blocklyText.motucamera.colorDetectPositionX": "កូអរដោណេដេក",
    "gui.blocklyText.motucamera.colorDetectPositionY": "កូអរដោណេឈរ",
    "gui.blocklyText.motucamera.colorDetectPositionW": "ទទឹង",
    "gui.blocklyText.motucamera.colorDetectPositionH": "កម្ពស់",
    "gui.blocklyText.motucamera.colorDetectPositionL": "ស្លាកសញ្ញា",
    "gui.blocklyText.motucamera.colorRecognizeChannelRed": "កាណាល់ក្រហម",
    "gui.blocklyText.motucamera.colorRecognizeChannelGreen": "កាណាល់បៃតង",
    "gui.blocklyText.motucamera.colorRecognizeChannelBlue": "កាណាល់ខៀវ",
    "gui.blocklyText.motucamera.colorRecognizeChannelLabel": "កាណាល់ស្លាកសញ្ញា",
    "gui.blocklyText.motucamera.shapeCardTick": "គូស",
    "gui.blocklyText.motucamera.shapeCardCross": "ឆ្លងកាត់",
    "gui.blocklyText.motucamera.shapeCardCircle": "រង្វង់",
    "gui.blocklyText.motucamera.shapeCardSquare": "ការេ",
    "gui.blocklyText.motucamera.shapeCardTriangle": "ត្រីកោណ",
    "gui.blocklyText.motucamera.trafficCardForward": "ទៅមុខ",
    "gui.blocklyText.motucamera.trafficCardLeft": "ឆ្វេង",
    "gui.blocklyText.motucamera.trafficCardRight": "ស្ដាំ",
    "gui.blocklyText.motucamera.trafficCardTurnAround": "បង្វិល​ជុំ",
    "gui.blocklyText.motucamera.trafficCardStop": "ឈប់/បញ្ឈប់",
    "gui.blocklyText.motucamera.lightSensorUse": "កាមេរ៉ា mu [MU] ឧបករណ៍វាស់ពន្លឺបើកដំណើរការ [LIGHTFUNC]",
    "gui.blocklyText.motucamera.lightSensorSensitivity": "កាមេរ៉ា mu [MU] ញាណរបស់ឧបករណ៍វាស់ពន្លឺ [SENSITIVITY]",
    "gui.blocklyText.motucamera.lightSensorReadProximity": "កាមេរ៉ា mu [MU] ឧបករណ៍វាស់ពន្លឺអានការវាស់ដែលនៅជិត",
    "gui.blocklyText.motucamera.lightSensorReadAmbient": "កាមេរ៉ា mu [MU] ឧបករណ៍វាស់ពន្លឺអានពន្លឺនៃមជ្ឈដ្ឋានដែលវាស់បាន",
    "gui.blocklyText.motucamera.lightSensorDetectGesture": "កាមេរ៉ា mu [MU] ឧបករណ៍វាស់ពន្លឺចាប់កាយវិការម្ដងរួចរក្សាទុក",
    "gui.blocklyText.motucamera.lightSensorGestureIs": "កាមេរ៉ា mu [MU] ឧបករណ៍វាស់ពន្លឺ កាយវិការ = [GESTURE]",
    "gui.blocklyText.motucamera.upward": "ឡើងលើ",
    "gui.blocklyText.motucamera.downward": "ចុះក្រោម",
    "gui.blocklyText.motucamera.leftward": "ទៅខាងឆ្វេង",
    "gui.blocklyText.motucamera.rightward": "ទៅខាងស្ដាំ",
    "gui.blocklyText.motucamera.pull": "ទាញ",
    "gui.blocklyText.motucamera.push": "រុញ",
    "gui.blocklyText.motucamera.none": "គ្មាន",
    "gui.blocklyText.motucamera.default": "លំនាំដើម",
    "gui.blocklyText.motucamera.low": "ទាប",
    "gui.blocklyText.motucamera.middle": "មធ្យម",
    "gui.blocklyText.motucamera.high": "ខ្ពស់",
    "gui.blocklyText.motucamera.proximity": "ភាពនៅជិត",
    "gui.blocklyText.motucamera.ambientLight": "ពន្លឺនៃមជ្ឈដ្ឋាន",
    "gui.blocklyText.motucamera.gesture": "កាយវិការ",
    "gui.blocklyText.motucamera.MUWifiInit": "MU Wi-Fi ចាប់ផ្តើមច្រក [SERIALPORT] RX [RX] TX [TX] ល្បឿនបញ្ជូនតាម UART [BAUDRATE]",
    "gui.blocklyText.motucamera.MUWifiSetSsid": "MU Wi-Fi កំណត់ ssid [SSID] លេខកូដសម្ងាត់ [PASSWORD] ម៉ូត [MODE]",
    "gui.blocklyText.motucamera.MUWifiCheck": "MU Wi-Fi ត្រូវបានភ្ជាប់ដោយជោគជ័យ？",
    "gui.blocklyText.motucamera.MUWifiDisconnect": "MU Wi-Fi ត្រូវបានផ្តាច់",
    "gui.blocklyText.motucamera.MUWifiSetIP": "MU Wi-Fi កំណត់ IP គោលដៅ [IP] ច្រក [PORT]",
    "gui.blocklyText.motucamera.MUWifiReadTargetIP": "MU Wi-Fi អាន IP គោលដៅ ",
    "gui.blocklyText.motucamera.MUWifiReadLocalIP": "MU Wi-Fi អាន local IP ",
    "gui.blocklyText.motucamera.MUWifiRead": "MU Wi-Fi អាន",
    "gui.blocklyText.motucamera.MUWifiWrite": "MU Wi-Fi សរសេរ [WRITE]",
    "gui.blocklyText.dfr0552.init": "ចាប់ផ្តើមអាសយដ្ឋានម៉ូឌុលតាមទំនាក់ទំនង IIC [ADDR] តង់ស្យុងយោង [REF] mV",
    "gui.blocklyText.dfr0552.outputTriangularWave": "ម៉ូឌុល DS បញ្ចេញជារលករាងត្រីកោណដែលមានអំព្លីទុត [AMPLITUDE]mV ហ្វ្រេកង់ស៍ [FREQ]Hz លំអៀង dc [DCBIAS]mV របបដំណើរការ [DUTY]%",
    "gui.blocklyText.dfr0552.outputSineWave": "ម៉ូឌុល DA បញ្ចេញជារលកស៊ីនុយសូអ៊ីតដែលមានអំព្លីទុត [AMPLITUDE] mV ហ្វ្រេកង់ស៍ [FREQ]Hz លំអៀង dc [DCBIAS]mV ",
    "gui.blocklyText.dfr0552.outputVoltage": "ម៉ូឌុល DA បញ្ចេញតង់ស្យុង [VOLTAGE]mV",
    "gui.blocklyText.dfr0552.memoryVoltage": "ម៉ូឌុល DA មានអង្គចងចាំតង់ស្យុង [VOLTAGE]mV",
    "gui.blocklyText.sen0117.init": "ដំណើរការពីដំបូងច្រកចេញចូលនៃម៉ូឌុល [SERIALPORT] Rx[RX] Tx[TX] Busy[BUSY]",
    "gui.blocklyText.sen0117.playText": "បង្ហាញអត្ថបទ [TEXT] តន្ត្រី [BACKMUSIC]",
    "gui.blocklyText.sen0117.playSoundCue": "ចាក់សំលេងភ្លាមៗ [INDEX] រង់ចាំ [WAIT]",
    "gui.blocklyText.sen0117.playChordCue": "លេង chord prompts [INDEX] រង់ចាំ [WAIT]",
    "gui.blocklyText.sen0117.setVolume": "តំឡើង [INDEX] ទៅ [VOLUME]",
    "gui.blocklyText.sen0117.setSpeed": "ដាក់ល្បឿននៃសម្តី [SPEED]",
    "gui.blocklyText.sen0117.getSpeedVolume": "ទទួលម៉ូឌុលសំយោគសម្តី [TYPE]",
    "gui.blocklyText.sen0117.Prospects": "សម្លេងរំពឹងទុក",
    "gui.blocklyText.sen0117.Background": "សម្លេងផ្ទៃខាងក្រោយ",
    "gui.blocklyText.sen0117.Speed": "ល្បឿន",
    "gui.blocklyText.sen0117.playChordCueTone": "លេងសំលេងរោទ៍ chord [INDEX] រង់ចាំ [WAIT]",
    "gui.blocklyText.sen0117.No": "ទេ (0)",
    "gui.blocklyText.sen0117.setPlayMode": "ដាក់ម៉ូតចាក់សារឡើងវិញទៅ [MODE]",
    "gui.blocklyText.sen0117.pause": "ផ្អាក",
    "gui.blocklyText.sen0117.resume": "បន្ត",
    "gui.blocklyText.sen0117.stop": "ឈប់",
    "gui.blocklyText.sen0117.isIdle": "ការលេងចប់ឬនៅ?",
    "gui.blocklyText.dfr0576.selectPort": "ជ្រើសរើសច្រក [PORT]",
    "gui.blocklyText.dfr0576.begin": "ដំណើរការពីដំបូងនៃអាសយដ្ឋានម៉ូឌុលបណ្តាញ IIC [ADDR]",
    "gui.blocklyText.sen0248.bme680Init": "BME680 ចាប់ផ្តើម",
    "gui.blocklyText.sen0248.readBME680": "ទទួលឧបករណ៍ចាប់សញ្ញា BME680 [TYPE]",
    "gui.blocklyText.sen0248.Temperature": "សីតុណ្ហភាព (°C)",
    "gui.blocklyText.sen0248.Pressure": "សម្ពាធ (Pa)",
    "gui.blocklyText.sen0248.Humidity": "សំណើម (%rh)",
    "gui.blocklyText.sen0248.Altitude": "រយៈកម្ពស់ (m)",
    "gui.blocklyText.sen0248.GasResistance": "ភាពធន់នឹងឧស្ម័ន (ohm)",
    "gui.blocklyText.sen0304.initialIICAddr": "ដំណើរការពីដំបូងនៃអាសយដ្ឋានឧបករណ៍ចាប់សញ្ញាប្រើសម្លេងអ៊ុលត្រាតាម I2C [ADDR]",
    "gui.blocklyText.sen0304.changeI2CAddr": "ប្តូរអាសយដ្ឋានឧបករណ៍ចាប់សញ្ញាប្រើសម្លេងអ៊ុលត្រាតាម I2C [ADDR]",
    "gui.blocklyText.sen0304.readDistance": "អានចម្ងាយនៃឧបករណ៍ចាប់សញ្ញាប្រើសម្លេងអ៊ុលត្រា(cm)",
    "gui.blocklyText.sen0304.readTemperature": "អានសីតុណ្ហភាពនៃឧបករណ៍ចាប់សញ្ញាប្រើសម្លេងអ៊ុលត្រា(°C)",
    "gui.blocklyText.sen0307.readDistance": "អាន [PIN] រលកសម្លេងអ៊ុលត្រាប្រភេទអាណាឡូក(cm)",
    "gui.blocklyText.sen0250.setMode": "BMI160 ចាប់ផ្តើមអាសយដ្ឋាន [ADDR] ម៉ូត [MODE]",
    "gui.blocklyText.sen0250.Step": "ជំហាន",
    "gui.blocklyText.sen0250.Measure": "វាស់",
    "gui.blocklyText.sen0250.readAccelerated": "ទទួលអ័ក្ស [AXIS] អ័ក្សល្បឿន (g)",
    "gui.blocklyText.sen0250.getStepNumber": "BMI160 ទទួលបានចំនួនជំហាន",
    "gui.blocklyText.sen0250.0x69": "0x69 (SDO=1)",
    "gui.blocklyText.sen0250.0x68": "0x68 (SDO=0)",
    "gui.blocklyText.sen0250.Gyro": "Gyro",
    "gui.blocklyText.sen0250.Acc": "សន្ទុះ (g)",
    "gui.blocklyText.sen0224.init": "ដាក់ដំណើរការពីដំបូងនៃឧបករណ៍ចាប់សញា LIS2DH",
    "gui.blocklyText.sen0224.readAccelerated": "អានតម្លៃសន្ទុះនៃឧបករណ៍ចាប់សញ្ញា LIS2DH [AXIS] អ័ក្ស (mg)",
    "gui.blocklyText.dfr0023.readLM35": "អានជើង [PIN] សីតុណ្ហភាពរបស់ LM35(℃)",
    "gui.blocklyText.dfr0027.whenVibration": "នៅពេលជើង [PIN] ចាប់បានរំញ័រ",
    "gui.blocklyText.dfr0028.readTiltSensor": "ជើង [PIN] ចាប់បានភាពទេរ?",
    "gui.blocklyText.dfr0029.readPress": "ជើង [PIN] ប៊ូតុងត្រូវបានចុច?",
    "gui.blocklyText.dfr0030.readTouch": "ជើង [PIN] ចាប់បានការប៉ះ?",
    "gui.blocklyText.dfr0033.readMagnetic": "ជើង [PIN] ចាប់បានមេដែក?",
    "gui.blocklyText.dfr0034.readSound": "អានជើង [PIN] សម្លេងខ្លាំង",
    "gui.blocklyText.dfr0094.readIRReceived": "អានជើង [PIN] ទិន្នន័យអាំងហ្វ្រារ៉េដ",
    "gui.blocklyText.midi.midiInit": "ចាប់ផ្តើមច្រកចេញចូលនៃម៉ូឌុល MIDI [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.midi.midiOutput": "MIDI លទ្ធផលកាណាល់ [CHANNEL] ជំហាន [PITCH] ល្បឿន [SPEED]",
    "gui.blocklyText.sen0132.readCarbonMonoxide": "អានជើង [PIN] ឧបករណ៍ចាប់សញ្ញាកាបូនម៉ូណូអុកស៊ីត (CO)",
    "gui.blocklyText.dfr0051.readVoltage": "អានជើង [PIN] តុងស្យុង (V)",
    "gui.blocklyText.dfr0052.readPiezoSensor": "អានជើង [PIN] ឧបករណ៍ចាប់សញ្ញារំញ័រ Piezo Disk",
    "gui.blocklyText.dfr0058.readRotation": "អានជើង [PIN] ឧបករណ៍ចាប់សញ្ញារង្វិល",
    "gui.blocklyText.dfr0061.readJoyStickAxis": "អានជើង [PIN] JoyStick [AXIS] អ័ក្ស",
    "gui.blocklyText.dfr0061.readJoyStickButton": "ជើង [PIN] ប៊ូតុង JoyStick (Z) ត្រូវបានចុច?",
    "gui.blocklyText.dfr0072.dfr0072Init": "ជើងទន្និន័យដើម shiftOut [DATAPIN] ជើង RTC [RTCPIN] ជើង cls [CLSPIN] endian [ENDIAN]",
    "gui.blocklyText.dfr0072.dfr0072ShowCode": "បំពង់ឌីជីថលបង្ហាញកូដកូឡិចទ័រ 0x [CODE]",
    "gui.blocklyText.dfr0072.dfr0072ShowNumber": "បំពង់ឌីជីថលបង្ហាញលេខ  [NUM]",
    "gui.blocklyText.dfr0072.dfr0072Dot": "បំពង់ឌីជីថលបង្ហាញចំណុច [DOT]",
    "gui.blocklyText.dfr0072.dotTrue": "ពិត",
    "gui.blocklyText.dfr0072.dotFalse": "មិនពិត",
    "gui.blocklyText.dfr0072.numOff": "បិទ",
    "gui.blocklyText.dfr0563.dfr0563ReadingLithiumBattery": "អានថ្មលីចូម [TYPE]",
    "gui.blocklyText.dfr0563.voltage": "តុងស្យុង(mV)",
    "gui.blocklyText.dfr0563.remainingBattery": "ថ្មនៅសល់(%)",
    "gui.blocklyText.dfr0553.dfr0553InitIICAddr": "អាសយដ្ឋានដំបូងនៃម៉ូឌុល AD តាម IIC [ADDR]",
    "gui.blocklyText.dfr0553.dfr0553ReadVoltage": "ម៉ូឌុល AD អាន [PIN] តុងស្យុង(mV)",
    "gui.blocklyText.dfr0553.dfr0553GetAisleVoltageDifference": "ម៉ូឌុល AD ទទួលច្រកផ្លូវ [AISLE] ផលសងតុងស្យុង",
    "gui.blocklyText.dfr0553.ZeroAndOne": "០ និង ១",
    "gui.blocklyText.dfr0553.ZeroAndThree": "០ និង ៣",
    "gui.blocklyText.dfr0553.OneAndThree": "១ និង ៣",
    "gui.blocklyText.dfr0553.TwoAndThree": "២ និង ៣",
    "gui.blocklyText.dfr0588.initPin": "ជើងសីតុណ្ហភាពដំបូង [TEM] ជើងសំណើម [HUM]",
    "gui.blocklyText.dfr0588.getTemperature": "ទទួលសីតុណ្ហភាពឯកតា [UNIT]",
    "gui.blocklyText.dfr0588.getHumidity": "ទទួលសំណើម (%rh)",
    "gui.blocklyText.dfr0588.CELSIUS": "អង្សាសេ",
    "gui.blocklyText.dfr0588.FAHRENHEIT": "ហ្វារិនហៃ",
    "gui.blocklyText.dfr0076.readFlameSensor": "អានជើង [PIN] ឧបករណ៍ចាប់សញ្ញាអណ្តាតភ្លើង",
    "gui.blocklyText.dfr0117.eepromGetCount": "i2c eeprom ទទួល [INFOTYPE]",
    "gui.blocklyText.dfr0117.eepromReadString": "i2c eeprom អានឈ្មោះអត្ថបទ [KEY]",
    "gui.blocklyText.dfr0117.eepromReadNumber": "i2c eeprom អានឈ្មោះលេខ [KEY]",
    "gui.blocklyText.dfr0117.eepromSensorInit": "i2c eeprom អាសយដ្ឋានដំបូងតាមទំនាក់ទំនង i2c [IICADDR]]",
    "gui.blocklyText.dfr0117.eepromWriteString": "i2c eeprom សរសេរអត្ថបទ [VALUE] ឈ្មោះ [KEY]",
    "gui.blocklyText.dfr0117.eepromWriteNumber": "i2c eeprom សរសេរលេខ [VALUE] ឈ្មោះ [KEY]",
    "gui.blocklyText.dfr0117.text": "អត្ថបទ",
    "gui.blocklyText.dfr0117.number": "លេខ",
    "gui.blocklyText.dfr0117.eepromDeleteData": "i2c eeprom លុបឈ្មោះ [KEY]",
    "gui.blocklyText.dfr0117.eepromPrint": "i2c eeprom ស៊េរីព្រីនឈ្មោះ baud ទាំងអស់ [BAUD]",
    "gui.blocklyText.dfr0117.eepromGetIsName": "i2c eeprom ឈ្មោះ [KEY] មានទេ?",
    "gui.blocklyText.dfr0117.eepromFormat": "ទម្រង់ eeprom i2c",
    "gui.blocklyText.dfr0117.eepromGetType": "i2c eeprom ឈ្មោះ [KEY] គឺ [TYPE]?",
    "gui.blocklyText.dfr0117.nameCount": "រាប់ឈ្មោះ",
    "gui.blocklyText.dfr0117.memorySize": "ទំហំផ្ទុក(byte)",
    "gui.blocklyText.dfr0117.residualMemorySize": "ទំហំផ្ទុកនៅសល់(byte)",
    "gui.blocklyText.dfr0143.readAccelerometer": "អានជើង [PIN] ឧបករណ៍វាស់សំទុះ 3 អ័ក្ស [AXIS] អ័ក្ស",
    "gui.blocklyText.sen0018.readInfraredMotion": "អានជើង [PIN] ឧបករណ៍ចាប់ចលនាប្រើអ៊ីនហ្វ្រាក្រហមប្រភេទឌីជីថល",
    "gui.blocklyText.sen0014.readDistance": "អានជើង [PIN] ឧបករណ៍វាស់ចំងាយប្រើពន្លឺអ៊ីនហ្វ្រាក្រហម(mm) ",
    "gui.blocklyText.sen0114.readMoisture": "អានជើង [PIN] ឧបករណ៍វាស់សំណើមដី",
    "gui.blocklyText.sen0121.readSteam": "អានជើង [PIN] ឧបករណ៍ចាប់សញ្ញាចំហាយ",
    "gui.blocklyText.sen0204.readLiquidLevel": "ជើង [PIN] កម្រិតអង្គធាតុរាវឡើងដល់កម្ពស់ណាមួយ?",
    "gui.blocklyText.sen0203.heartRate": "ចង្វាក់បេះដូង(bpm)",
    "gui.blocklyText.sen0203.bloodOxygen": "តិត្ថិភាពអុកស៊ីសែនក្នុងឈាម(HbO2)",
    "gui.blocklyText.sen0202.up": "ឡើងលើ",
    "gui.blocklyText.sen0202.down": "ចុះក្រោម",
    "gui.blocklyText.sen0202.left": "ឆ្វេង",
    "gui.blocklyText.sen0202.right": "ស្ដាំ",
    "gui.blocklyText.sen0202.cw": "បង្វិលតាមទ្រនិចនាឡិកា",
    "gui.blocklyText.sen0202.ccw": "បង្វិលបញ្ច្រាសទ្រនិចនាឡិកា",
    "gui.blocklyText.dhtTHSensor.temperature": "សីតុណ្ហភាព (°C)",
    "gui.blocklyText.dhtTHSensor.moisture": "សំណើម (%rh)",
    "gui.blocklyText.ws2812.allLedsShowColorFromWith": "ជើង [PIN] RGB [NUM1] ទៅ [NUM2] បង្ហាញពណ៌ [COLOR]",
    "gui.blocklyText.ws2812.rgbColor": "ក្រហម [RED] បៃតង [GREEN] ខៀវ [BLUE]",
    "gui.blocklyText.ws2812.showGradientColor": "ជើង [PIN] [NUM1] ទៅ [NUM2] RGB បង្ហាញពណ៌ជម្រាលពី [COLOR1] ដល់ [COLOR2]",
    "gui.blocklyText.ws2812.shiftPixelsToShow": "ជើង [PIN] រំកិល​ភីកសែលត្រឹម [NUM]",
    "gui.blocklyText.ws2812.rotatePixelsToShow": "ជើង [PIN] បង្វិលភីកសែលត្រឹម [NUM]",
    "gui.blocklyText.ws2812.numberOfLightsBright": "ជើង [PIN] [NUM] RGB LEDs",
    "gui.blocklyText.ws2812.numberOfLightsBrightTest": "ជើង [PIN] កម្រិតពន្លឺ [BRIGHT]",
    "gui.blocklyText.ws2812.clear": "ជើង [PIN] បិទអំពូល LED ទាំងអស់",
    "gui.blocklyText.ws2812.showHistogram": "ជើង [PIN] [NUM1] ទៅ [NUM2] RGB LEDs បង្ហាញតម្លៃអ៊ីស្ត្រូក្រាមបច្ចុប្បន្ន៖ [VALUE] តម្លៃអតិបរិមា៖ [MAX]",
    "gui.blocklyText.ws2812.setBrightness": "ជើង [PIN] កម្រិតពន្លឺនៃអំពូល LED [BRIGHTNESS]",
    "gui.blocklyText.dsTSensor.ds18b20": "អាន [PIN] ជើង DS1818B20 សីតុណ្ហភាព (°C)",
    "gui.blocklyText.bmpTSensor.bmp280Init": "BMP280 ចាប់ផ្តើមដំណើរការដំបូង",
    "gui.blocklyText.bmpTSensor.readBmpSensor": "អាន [TYPE] ឧបករណ៍ចាប់សញ្ញា BMP280",
    "gui.blocklyText.bmpTSensor.atmosPressure": "barometric (Pa)",
    "gui.blocklyText.bmpTSensor.altitude": "រយៈកំពស់ (m)",
    "gui.blocklyText.bmpTSensor.temperature": "សីតុណ្ហភាព (℃)",
    "gui.blocklyText.huskylens.init": "HuskyLens ជើងដំណើរការដំបូង [SETTING] រហូតដល់ជោគជ័យ",
    "gui.blocklyText.huskylens.changeAlgorithm": "HuskyLens ប្តូរ [ALGORITHM] ក្បួនដោះស្រាយរហូតដល់ទទួលបានជោគជ័យ",
    "gui.blocklyText.huskylens.requestFirst": "សំណើរបស់ HuskyLens នៅពេលដែលបញ្ចូលលទ្ធផល",
    "gui.blocklyText.huskylens.resultGetIsStudy": "HuskyLens ទទួលបានពីលទ្ធផលលេខសម្គាល់ [IDNUM] ត្រូវបានដឹង?",
    "gui.blocklyText.huskylens.resultGetIDIsInPicture": "HuskyLens ទទួលបានពីលទ្ធផលលេខសម្គាល់ [IDNUM] [BOXARROW] ក្នុងរូបភាព?",
    "gui.blocklyText.huskylens.resultGetStudyedID": "HuskyLens ទទួលបានពីលទ្ធផលលេខសម្គាល់",
    "gui.blocklyText.huskylens.resultGetIsBoxArrowTotal": "HuskyLens ទទួលបានពីលទ្ធផល [BOXARROW] សរុប",
    "gui.blocklyText.huskylens.resultGetIDsBoxArrowTotal": "HuskyLens ទទួលបានពីលទ្ធផលលេខសម្គាល់ [IDNUM] [BOXARROW] សរុប",
    "gui.blocklyText.huskylens.resultGetIsInPicture": "HuskyLens ទទួលបានពីលទ្ធផល [BOXARROW] ក្នុងរូបភាព",
    "gui.blocklyText.huskylens.box": "ប្រអប់",
    "gui.blocklyText.huskylens.arrow": "អឺរ៉េ",
    "gui.blocklyText.huskylens.faceRecognition": "ការទទួលស្គាល់មុខ",
    "gui.blocklyText.huskylens.objectTracking": "ការតាមដានវត្ថុ",
    "gui.blocklyText.huskylens.objectRecognition": "ការទទួលស្គាល់វត្ថុ",
    "gui.blocklyText.huskylens.lineTracking": "ការតាមដានខ្សែបន្ទាត់",
    "gui.blocklyText.huskylens.colorRecognition": "ការចំណំាំពណ៌",
    "gui.blocklyText.huskylens.tagRecognition": "ការទទួលស្គាល់ស្លាក",
    "gui.blocklyText.huskylens.ID": "ID",
    "gui.blocklyText.huskylens.x": "កូអរដោនេ X",
    "gui.blocklyText.huskylens.y": "កូអរដោនេ Y",
    "gui.blocklyText.huskylens.w": "ទទឹងវត្ថុ",
    "gui.blocklyText.huskylens.h": "កម្ពស់វត្ថុ",
    "gui.blocklyText.huskylens.x1": "X1 ចំណុចចាប់ផ្តើម",
    "gui.blocklyText.huskylens.y1": "Y1 ចំណុចចាប់ផ្តើម",
    "gui.blocklyText.huskylens.x2": "X2 ចំណុចបញ្ចប់",
    "gui.blocklyText.huskylens.y2": "Y2 ចំណុចបញ្ចប់",
    "gui.blocklyText.huskylens.i2c": "I2C",
    "gui.blocklyText.huskylens.CommunicationMethod": "វិធីសាស្រ្តទំនាក់ទំនង",
    "gui.blocklyText.huskylens.address": "អាសយដ្ឋាន",
    "gui.blocklyText.huskylens.sdaGreen": "SDA (បៃតង)",
    "gui.blocklyText.huskylens.sclBlue": "SCL (ខៀវ)",
    "gui.blocklyText.huskylens.rxGreen": "Rx (បៃតង)",
    "gui.blocklyText.huskylens.txBlue": "Tx (ខៀវ)",
    "gui.blocklyText.ps2.initPs2Handle": "init PS2 ដោះស្រាយ DAT [DAT] CMD [CMD] CS [SEL] CLK [CLK] setRunmble [RUMBLE]",
    "gui.blocklyText.ps2.ps2OpenRunmble": "PS2 បើក Rumble [RUMBLE] កម្លាំង [STRENGTH]",
    "gui.blocklyText.ps2.ps2Reflush": "PS2 reflush states",
    "gui.blocklyText.ps2.ps2ButtonState": "ប៊ូតុង PS2 [BUTTON] គឺនៅ [STATE]",
    "gui.blocklyText.ps2.ps2Joystick": "PS2 Joystick [JOYSTICK]",
    "gui.blocklyText.ps2.ON": "បើក",
    "gui.blocklyText.ps2.OFF": "បិទ",
    "gui.blocklyText.ps2.Triangle": "ត្រីកោណ",
    "gui.blocklyText.ps2.Circle": "រង្វង់",
    "gui.blocklyText.ps2.Cross": "ឆ្លងកាត់",
    "gui.blocklyText.ps2.Square": "ការ៉េ",
    "gui.blocklyText.ps2.Left1": "ឆ្វេង ១",
    "gui.blocklyText.ps2.Left2": "ឆ្វេង ២",
    "gui.blocklyText.ps2.Left3": "ឆ្វេង ៣",
    "gui.blocklyText.ps2.Right1": "ស្ដាំ ១",
    "gui.blocklyText.ps2.Right2": "ស្ដាំ ២",
    "gui.blocklyText.ps2.Right3": "ស្ដាំ ៣",
    "gui.blocklyText.ps2.Up": "ឡើងលើ",
    "gui.blocklyText.ps2.Right": "ស្ដាំ",
    "gui.blocklyText.ps2.Down": "ចុះក្រោម",
    "gui.blocklyText.ps2.Left": "ឆ្វេង",
    "gui.blocklyText.ps2.Select": "ជ្រើសរើស",
    "gui.blocklyText.ps2.Start": "ចាប់ផ្តើម",
    "gui.blocklyText.ps2.Hold": "ចុចជាប់",
    "gui.blocklyText.ps2.Pressed": "ត្រូវបានចុច",
    "gui.blocklyText.ps2.Released": "ត្រូវបានលែង",
    "gui.blocklyText.ps2.CHANGE": "ផ្លាស់ប្តូរ",
    "gui.blocklyText.ps2.RightX": "ស្តាំ X",
    "gui.blocklyText.ps2.RightY": "ស្តាំ Y",
    "gui.blocklyText.ps2.LeftX": "ឆ្វេង X",
    "gui.blocklyText.ps2.LeftY": "ឆ្វេង Y",
    "gui.blocklyText.sen0236.bme280Init": "BME280 ចាប់ផ្តើមដំណើរការពីដំបូង",
    "gui.blocklyText.sen0236.readbme280Sensor": "អានឧបករណ៍ចាប់សញ្ញា BME280 [TYPE]",
    "gui.blocklyText.sen0236.Temperature": "សីតុណ្ហភាព (℃)",
    "gui.blocklyText.sen0236.Humidity": "សំណើម (%rh)",
    "gui.blocklyText.sen0236.Altitude": "រយៈកំពស់ (m)",
    "gui.blocklyText.sen0236.Pressure": "សម្ពាធ (Pa)",
    "gui.blocklyText.LCD1602.initialIICAddr": "អាសយដ្ឋានចាប់ផ្តើមនៃអេក្រង់ LCD តាម IIC [ADDR]",
    "gui.blocklyText.LCD1602.displayInLine": "បង្ហាញ [TEXT] ក្នុងបន្ទាត់ LCD [LINE]",
    "gui.blocklyText.LCD1602.displayInXY": "បង្ហាញ [TEXT] នៅទីតាំង LCD X: [X] Y: [Y]",
    "gui.blocklyText.LCD1602.displayClear": "លុបអេក្រង់",
    "gui.blocklyText.IICScan.readICCAddr": "អានអាសយដ្ឋានឧបករណ៍ IIC ដែលត្រូវបានស្កេន",
    "gui.blocklyText.sdcard.initCS": "ជើង SD film [CSPIN] ចាប់ផ្តើមដោយជោគជ័យ?",
    "gui.blocklyText.sdcard.readFileNLine": "SD អានឯកសារ [FILE] បន្ទាត់ [LINE]",
    "gui.blocklyText.sdcard.readFileAll": "SD អានឯកសារ [FILE] មាតិកាទាំងអស់",
    "gui.blocklyText.sdcard.writeFile": "SD សរសេរឯកសារ [FILE] មាតិកា [MSG]  ម៉ូត [TYPE] [WRAP]",
    "gui.blocklyText.sdcard.deleteFile": "SD លុបឯកសារ [FILE]",
    "gui.blocklyText.sdcard.getFileMsg": "SD ទទួលបានឯកសារ [FILE] ព័ត៌មាន [INFO]",
    "gui.blocklyText.sdcard.NAME": "ឈ្មោះ",
    "gui.blocklyText.sdcard.PATH": "កន្លែងទុក",
    "gui.blocklyText.sdcard.TYPE": "ប្រភេទ",
    "gui.blocklyText.sdcard.LINE": "បន្ទាត់",
    "gui.blocklyText.sdcard.SIZE": "ទំហំ",
    "gui.blocklyText.sdcard.APPEND": "បំពេញបន្ថែម",
    "gui.blocklyText.sdcard.REPLACE": "ជំនួស",
    "gui.blocklyText.sdcard.WARP": "ផ្តាច់បន្ទាត់",
    "gui.blocklyText.sdcard.NOWARP": "គ្មានការផ្តាច់បន្ទាត់",
    "gui.blocklyText.sdcard.lsFile": "ច្រកស៊េរី SD ព្រីនឯកសារព័ត៌មាន baud ទាំងអស់ [BAUD]",
    "gui.blocklyText.softSerialport.initialSerialport": "ចាប់ផ្តើមសេរ៊ីប្រើកម្មវិធី [SERIALPORT] RX: [PINR] TX: [PINT]",
    "gui.blocklyText.softSerialport.setBaud": "សេរ៊ីប្រើកម្មវិធី [SERIALPORT] ល្បឿនបញ្ជូន [BAUD]",
    "gui.blocklyText.softSerialport.printStr": "សេរ៊ីប្រើកម្មវិធី [SERIALPORT] ច្រកចេញ [TEXT] [WAY], [LINE]",
    "gui.blocklyText.softSerialport.dataReadable": "តើមានទិន្នន័យដើម្បីអានតាមសេរ៊ីប្រើកម្មវិធី  [SERIALPORT] ទេ?",
    "gui.blocklyText.softSerialport.readSerialportData": "អានតាមសេរ៊ីប្រើកម្មវិធី [SERIALPORT]",
    "gui.blocklyText.softSerialport.readSerialportIntFloat": "អាន [TYPE] តាមសេរ៊ីប្រើកម្មវិធី [SERIALPORT]",
    "gui.blocklyText.bluetooth.connectSuccess": "ពេលប៊្លូធូសបានភ្ជាប់",
    "gui.blocklyText.bluetooth.disconnectBluetooth": "ពេលប៊្លូធូសបានផ្តាច់",
    "gui.blocklyText.bluetooth.receivedKey": "នៅពេលប៊្លូធូសបានទទួលច្នុច [KEY] [TYPE]",
    "gui.blocklyText.bluetooth.string": "អក្សរ",
    "gui.blocklyText.bluetooth.number": "លេខ",
    "gui.blocklyText.bluetooth.receivedDataAndMeet": "នៅពេលប៊្លូធូសបានទទួលទិន្នន័យនិងជួប [SYMBOL]",
    "gui.blocklyText.bluetooth.sendStr": "បញ្ជូនច្នុចអក្សរ [KEY] តម្លៃ [VALUE]",
    "gui.blocklyText.bluetooth.sendNumber": "បញ្ជូនច្នុចលេខ [KEY] តម្លៃ [VALUE]",
    "gui.blocklyText.bluetooth.openService": "បើកសេវាប៊្លូធូស",
    "gui.blocklyText.bluetooth.bluetoothReceiveData": "បានទទួលទិន្នន័យពីប៊្លូធូស",
    "gui.blocklyText.leonardo.functionStarts": "Leonardo ចាប់ផ្តើម",
    "gui.blocklyText.leonardo.setBaud": "ដាក់ [SERIAL] ល្បឿនបញ្ជូនទៅ [BAUD]",
    "gui.blocklyText.leonardo.readSerialDataIntFloat": "ពី [SERIAL] អានទិន្នន័យសេរ៊ីជាចំនួនគត់ឬទសភាគ [READTYPE]",
    "gui.blocklyText.leonardo.serialPort1": "ច្រកសេរ៊ី ០",
    "gui.blocklyText.leonardo.serialPort2": "ច្រកសេរ៊ី ១",
    "gui.blocklyText.leonardo.dataReadable": "តើមានទិន្នន័យត្រូវអាននៅលើ [SERIAL] ទេ?",
    "gui.blocklyText.leonardo.readSerialData": "អានទិន្នន័យ [SERIAL]",
    "gui.blocklyText.arduinounoR3.initTcs34725": "ចាប់ផ្តើមឧបករណ៍ចាប់ពណ៌ TCS34725",
    "gui.blocklyText.arduinounoR3.functionStarts": "កម្មវិធី Uno kit ចាប់ផ្តើម",
    "gui.blocklyText.arduinounoR3.tmi1650Init": "ដំណើរការពីដំបូននៃការបង្ហាញ TMI1650",
    "gui.blocklyText.arduinounoR3.readdigital": "អានជើង [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalSensor": "ឧបករណ៍ចាប់សញ្ញាឌីជីថល",
    "gui.blocklyText.arduinounoR3.buttonModule": "ម៉ូឌុលប៊ូតុង",
    "gui.blocklyText.arduinounoR3.stickButton": "ប៊ូតុង Rocker",
    "gui.blocklyText.arduinounoR3.vibrationSensor": "ឧបករណ៍ចាប់សញ្ញារំញ័រ",
    "gui.blocklyText.arduinounoR3.readAnalog": "អានជើង [PIN] [SENSOR]",
    "gui.blocklyText.arduinounoR3.anologSensor": "ឧបករណ៍ចាប់សញ្ញាអាណាឡូក",
    "gui.blocklyText.arduinounoR3.lightSensor": "ឧបករណ៍ចាប់សញ្ញាពន្លឺ",
    "gui.blocklyText.arduinounoR3.soundSensor": "ឧបករណ៍ចាប់សញ្ញាសំឡេង",
    "gui.blocklyText.arduinounoR3.stickDirection": "ទិសដៅ Rocker",
    "gui.blocklyText.arduinounoR3.angleSensor": "ឧបករណ៍ចាប់សញ្ញាមុំ",
    "gui.blocklyText.arduinounoR3.graySensor": "ឧបករណ៍វាស់កម្រឹតប្រផេះ",
    "gui.blocklyText.arduinounoR3.soilMoistureSensor": "ឧបករណ៍វាស់សំណើមដី",
    "gui.blocklyText.arduinounoR3.open": "បើក",
    "gui.blocklyText.arduinounoR3.close": "បិទ",
    "gui.blocklyText.arduinounoR3.writeDigital": "[SWITCH] ជើង [PIN] [MODULE]",
    "gui.blocklyText.arduinounoR3.digitalActuator": "ម៉ូឌុលសកម្មភាពប្រភេទឌីជីថល",
    "gui.blocklyText.arduinounoR3.ledLights": "ពន្លឺអំពូល LED",
    "gui.blocklyText.arduinounoR3.fanModule": "ម៉ូឌុលកង្ហារ",
    "gui.blocklyText.arduinounoR3.recordModule": "ម៉ូឌុលកំណត់ត្រា",
    "gui.blocklyText.arduinounoR3.relay": "រ៉ឺលេ",
    "gui.blocklyText.arduinounoR3.readUltrasonic": "ការអានចម្ងាយសម្លេងអ៊ុលត្រា(cm) ទ្រីគ័រ [TRIG] អេកូ [ECHO]",
    "gui.blocklyText.arduinounoR3.simulationActuator": "ការធ្វើត្រាប់ជាម៉ូឌុលសកម្មភាព",
    "gui.blocklyText.arduinounoR3.writeAnalog": "ដាក់ជើង [PIN] [MODULE] ត្រឹម [VALUE]",
    "gui.blocklyText.arduinounoR3.forward": "ផ្លាស់ទីទៅមុខ",
    "gui.blocklyText.arduinounoR3.backward": "ផ្លាស់ទីមកក្រោយ",
    "gui.blocklyText.arduinounoR3.turnLeft": "បត់ឆ្វេង",
    "gui.blocklyText.arduinounoR3.turnRight": "បត់​ស្តាំ",
    "gui.blocklyText.arduinounoR3.robotSpeedMove": "រ៉ូបូត [DIR] នៅល្បឿន [SPEED]% ",
    "gui.blocklyText.arduinounoR3.motorForward": "ផ្លាស់ទីទៅមុខ",
    "gui.blocklyText.arduinounoR3.motorReverse": "ផ្លាស់ទីមកក្រោយ",
    "gui.blocklyText.arduinounoR3.setMotorMoveSpeed": "ម៉ូទ័រ [MOTOR] [DIR] នៅល្បឿន [SPEED]% ",
    "gui.blocklyText.arduinounoR3.stopMotor": "រ៉ូបូត (ម៉ូទ័រ MA និង MB) ឈប់ផ្លាស់ទី",
    "gui.blocklyText.arduinounoR3.setServo": "ដាក់ជើង [PIN] ម៉ូទ័រស៊ែវូទៅមុំ [DEGREE] ដឺក្រេ",
    "gui.blocklyText.arduinounoR3.emissionPinAndBit": "ការបំភាយអាំងហ្វ្រាក្រហម [TYPE] ជើង [PIN] តម្លៃ [VALUE] ចំនួនបីត [BIT]",
    "gui.blocklyText.arduinounoR3.pressInfrared": "ជើងអាំងហ្វ្រាក្រហម [PIN] បានទទួល [BUTTON] សញ្ញាត្រូវបានចុច?",
    "gui.blocklyText.arduinounoR3.noSound": "គ្មានសំលេង",
    "gui.blocklyText.arduinounoR3.half": "ពាក់កណ្តាល",
    "gui.blocklyText.arduinounoR3.quarter": "មួយភាគបួន",
    "gui.blocklyText.arduinounoR3.oneInEighth": "មួយភាគប្រាំបី",
    "gui.blocklyText.arduinounoR3.wholeBeat": "ប៊ីតទាំងមូល",
    "gui.blocklyText.arduinounoR3.doubleBeat": "ប៊ីតពីរដង",
    "gui.blocklyText.arduinounoR3.setBuzzerSound": "ជើង [PIN] ចាក់សម្លេងត្រែ [TONE] ចំនួន [BEAT] ប៊ីត",
    "gui.blocklyText.arduinounoR3.setMP3Volume": "ដាក់ជើង [PIN] កម្រិតសម្លេងរបស់ម៉ូឌុល MP3 ត្រឹម [VOLUME]",
    "gui.blocklyText.arduinounoR3.setMP3PlayMode": "ដាក់ជើង [PIN] ម៉ូតលេងរបស់ម៉ូឌុល MP3 ទៅជា [MODE]",
    "gui.blocklyText.arduinounoR3.stop": "ឈប់",
    "gui.blocklyText.arduinounoR3.play": "លេង",
    "gui.blocklyText.arduinounoR3.loop": "រង្វិលជុំ",
    "gui.blocklyText.arduinounoR3.random": "ចៃដន្យ",
    "gui.blocklyText.arduinounoR3.pause": "ផ្អាក",
    "gui.blocklyText.arduinounoR3.previous": "មុន",
    "gui.blocklyText.arduinounoR3.next": "បន្ទាប់",
    "gui.blocklyText.arduinounoR3.VolumePlus": "កម្រិតសំឡេង +",
    "gui.blocklyText.arduinounoR3.VolumeMinus": "កម្រិតសំឡេង -",
    "gui.blocklyText.arduinounoR3.setMP3PlaySong": "ជើង [PIN] ម៉ូឌុល MP3 ចាក់ចម្រៀង [NUM]",
    "gui.blocklyText.arduinounoR3.lm35Temperature": "អានជើង [PIN] LM35 សីតុណ្ហភាព(°C)",
    "gui.blocklyText.arduinounoR3.readDHT11": "អានជើង [PIN] DHT11 [TYPE]",
    "gui.blocklyText.arduinounoR3.red": "ពណ៌ក្រហម",
    "gui.blocklyText.arduinounoR3.blue": "ពណ៌ខៀវ",
    "gui.blocklyText.arduinounoR3.green": "ពណ៌បៃតង",
    "gui.blocklyText.arduinounoR3.readColorSensor": "អានឧបករណ៍ចាប់ពណ៌ TCS34725 [COLORTYPE]",
    "gui.blocklyText.arduinounoR3.fourTubeMode": "four-bit nixie tube TM1650 [TYPE]",
    "gui.blocklyText.arduinounoR3.tm1650Open": "បើក",
    "gui.blocklyText.arduinounoR3.tm1650Close": "បិទ",
    "gui.blocklyText.arduinounoR3.clearScreen": "លុបអេក្រង់",
    "gui.blocklyText.arduinounoR3.fourTubeShowInput": " 4-bit nixie tube TM1650 បង្ហាញអក្សរ [TEXT]",
    "gui.blocklyText.arduinounoR3.fourTubeControlPoint": "[STATUS] [ORDER] ចំណុចគោលនៃ4-bit nixie tube TM1650",
    "gui.blocklyText.arduinounoR3.bright": "ធ្វើឱ្យភ្លឺ",
    "gui.blocklyText.arduinounoR3.dark": "ធ្វើឱ្យងងឹត",
    "gui.blocklyText.arduinounoR3.setLightColor": "ជើង [PIN] [NUM] ការបង្ហាញអំពូល LED [COLOR]",
    "gui.blocklyText.arduinounoR3.lightsRGB": "ជើង [PIN] [NUM] LED R:[R] G:[G] B:[B]",
    "gui.blocklyText.arduinounoR3.numberOfLights": "ជើង [PIN] [NUM] អំពូល LEDs",
    "gui.blocklyText.arduinounoR3.ledControl": "អំពូលម៉ាទ្រីស LED (max7219) ជើងប៉ារ៉ាម៉ែត្រចាប់ផ្តើម DIN [DIN], ជើង CS [CS], ជើង CLK [CLK]",
    "gui.blocklyText.arduinounoR3.ledLatticeShowPic": "អំពូលម៉ាទ្រីស LED (max7219) បង្ហាញលំនាំ [PIC]",
    "videoSensing.categoryName": "ញាណតាមវីដេអូ",
    "videoSensing.direction": "ទិសដៅ",
    "videoSensing.motion": "ចលនា",
    "videoSensing.off": "បិទ",
    "videoSensing.on": "បើក",
    "videoSensing.onFlipped": "បកត្រឡប់",
    "videoSensing.setVideoTransparency": "ដាក់កម្រិតច្បាស់អោយវីដេអូទៅជា [TRANSPARENCY]",
    "videoSensing.sprite": "តួអង្គ",
    "videoSensing.stage": "ឆាក",
    "videoSensing.videoOn": "វីដេអូ [ATTRIBUTE] លើ [SUBJECT]",
    "videoSensing.videoToggle": "បើកវីដេអូ [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "នៅពេលដែលចលនាវីដេអូ > [REFERENCE]",
    "gui.blocklyText.max.maxStart": "Max ចាប់ផ្តើម",
    "gui.blocklyText.max.onboardButtonPressed": "ប៊ូតុងនៅលើក្តារបញ្ជា [PRESSED]?",
    "gui.blocklyText.max.pressed": "ត្រូវបានចុច",
    "gui.blocklyText.max.notPressed": "មិនត្រូវបានចុច",
    "gui.blocklyText.max.all": "-1 ទាំងអស់",
    "gui.blocklyText.max.A": " 0 A",
    "gui.blocklyText.max.B": " 1 B",
    "gui.blocklyText.max.C": " 2 C",
    "gui.blocklyText.max.D": " 3 D",
    "gui.blocklyText.max.rgbLightsColor": "ដាក់អំពូល RGB [TYPE] ពណ៌ [COLOR]",
    "gui.blocklyText.max.readSoundSensor": "អានឧបករណ៍ចាប់សញ្ញាសម្លេង",
    "gui.blocklyText.max.playSoundEffect": "លេងបែបផែនសម្លេង [SOUND]",
    "gui.blocklyText.max.setDPinValue": "ដាក់ជើងឌីជីថល [PIN] ទៅ [LEVEL]",
    "gui.blocklyText.max.readDPinValue": "អានជើងឌីជីថល [PIN]",
    "gui.blocklyText.max.readAPinValue": "អានជើងអាណាឡូក [PIN]",
    "gui.blocklyText.max.setServo": "ដាក់ជើង [PIN] ម៉ូទ័រស៊ែវូទៅមុំ [DEGREE] ដឺក្រេ",
    "gui.blocklyText.max.readLinefindingSensor": "អាន [DIR] ឧបករណ៍តាមដានខ្សែបន្ទាត់",
    "gui.blocklyText.max.left": "ឆ្វេង",
    "gui.blocklyText.max.middle": "កណ្តាល",
    "gui.blocklyText.max.right": "ស្ដាំ",
    "gui.blocklyText.max.setLineFidingCarLights": "អំពូល LED រថយន្ត [SWITCH]",
    "gui.blocklyText.max.on": "បើក",
    "gui.blocklyText.max.off": "បិទ",
    "gui.blocklyText.max.readUltrasoundSensor": "អានឧបករណ៍ចាប់សម្លេងអ៊ុលត្រា(cm)",
    "gui.blocklyText.max.readLightSensor": "អាន [LEFTRIGHT] ឧបករណ៍វាស់ពន្លឺ",
    "gui.blocklyText.max.maxSpeedMove": "MAX ផ្លាស់ទី [DIR] នៅល្បឿន [SPEED]",
    "gui.blocklyText.max.setMaxMove": "MAX [WHEEL] ផ្លាស់ទី [DIR] នៅល្បឿន [SPEED]",
    "gui.blocklyText.max.stopMax": "MAX [WHEEL] ឈប់",
    "gui.blocklyText.max.showUserDefineExpressions": "បង្ហាញលំនាំ [EXPRESSION] នៅក្នុង [COLOR]",
    "gui.blocklyText.max.clear": "លុប",
    "gui.blocklyText.max.do": "do (ដូ)",
    "gui.blocklyText.max.re": "re (រ៉េ)",
    "gui.blocklyText.max.mi": "mi (មី)",
    "gui.blocklyText.max.fa": "fa (ហ្វា)",
    "gui.blocklyText.max.sou": "so (សូ)",
    "gui.blocklyText.max.la": "la (ឡា)",
    "gui.blocklyText.max.xi": "xi (ស៊ី)",
    "gui.blocklyText.max.highdo": "highdo",
    "gui.blocklyText.max.brake": "ហ្វ្រាំង",
    "gui.blocklyText.max.rebound": "ងើបឡើងវិញ",
    "gui.blocklyText.max.bullets": "bullets",
    "gui.blocklyText.max.longecho": "ជះត្រឡប់យូរ",
    "gui.blocklyText.max.playfulending": "បញ្ចប់",
    "gui.blocklyText.max.yo": "yo (យូ)",
    "gui.blocklyText.max.wo": "wo (វ៉ូ)",
    "gui.blocklyText.max.robottalking": "រ៉ូបូតកំពុងនិយាយ",
    "gui.blocklyText.sen0160.init": "ដាក់តម្លៃកែតម្រូវឧបករណ៍ថ្លឹងទំងន់  Hx711 [VALUE] ជាមួយជើង Dout [DOUT] ជើង SCK [SCK]",
    "gui.blocklyText.sen0160.readValue": "អានឧបករណ៍ថ្លឹងទំងន់  Hx711 (g) ជើង Dout [DOUT] ជើង SCK [SCK]",
    "gui.blocklyText.knowflow.readORP": "អានជើង [PIN] ឧបករណ៍ចាប់សញ្ញា ORP (mV)",
    "gui.blocklyText.knowflow.readEC": "អានជើង [PIN] ឧបករណ៍ចាប់សញ្ញា EC (ms /cm) (V1)",
    "gui.blocklyText.knowflow.readPH": "អានជើង [PIN] ឧបករណ៍ចាប់សញ្ញា PH (V1)",
    "gui.blocklyText.knowflow.readECV2": "អានជើង [PIN] ឧបករណ៍ចាប់សញ្ញា EC (ms / cm) (V2)",
    "gui.blocklyText.knowflow.readPHV2": "អានជើង [PIN] ឧបករណ៍ចាប់សញ្ញា PH (V2)",
    "gui.blocklyText.knowflow.readDO": "កូដ PIN អាន [PIN] ឧបករណ៍ចាប់សញ្ញា DO (mg / L)",
    "gui.blocklyText.peristalticPump.speedMove": "ជើង [PIN] ម៉ាស៊ីនបូមទឹកបញ្ច្រាសបង្វិល [DIR] នៅល្បឿន [SPEED]%",
    "gui.blocklyText.dfr0017.setRelay": "[SWITCH] ជើង [PIN] រ៉ឺលេ",
    "translate.translateBlock": "បកប្រែ [WORDS] ទៅ [LANGUAGE]",
    "translate.defaultTextToTranslate": "សួស្តី",
    "translate.viewerLanguage": "ភាសាអ្នកមើល",
    "gui.blocklyText.goble.readAxis": "អាន GOBLE rocker [AXIS] អ័ក្ស",
    "gui.blocklyText.goble.buttonIsPressed": "ប៊ូតុង GOBEL [BUTTON] ត្រូវបានចុច?",
    "gui.blocklyText.eeprom.clear": "លុប eeprom​",
    "gui.blocklyText.eeprom.read": "eeprom អានពីអាសយដ្ឋាន [ADDR]",
    "gui.blocklyText.eeprom.write": "eeprom សរសេរទៅអាសយដ្ឋាន [ADDR] ជាមួយទិន្នន័យ [DATA]",
    "gui.blocklyText.eeprom.getCrc": "eeprom ទទួល crc ពីអាសយដ្ឋាន [ADDRA] ទៅអាសយដ្ឋាន [ADDRB]",
    "gui.blocklyText.eeprom.length": "eeprom ទទួលប្រវែង",
    "gui.blocklyText.tds.readAnalogPin": "អានជើងអាណាឡូក [PIN] ឧបករណ៍ចាប់សញ្ញា TDS(ppm)",
    "gui.blocklyText.steppermotor.initialJointXYParam": "ម៉ូទ័រ stepper ពីរ ដំណើរការក្នុងពេលដំណាលគ្នានៅអ័ក្ស X: [XSET] អ័ក្ស y: [YSET]",
    "gui.blocklyText.steppermotor.setXYStepsRs": "ម៉ូទ័រ stepper [AXIS] អ័ក្ស [STEPS] ជំហានក្នុងមួយល្បឿនវិលជុំ (r/min): [SPEED]",
    "gui.blocklyText.steppermotor.moveSteps": "ម៉ូទ័រ stepper ផ្លាស់ប្តូរ [ROTATEDIR] សម្រាប់ជំហាន [STEPS]",
    "gui.blocklyText.steppermotor.setJointSteps": "ម៉ូទ័រ Stepper ពីរ ដំណើរការក្នុងពេលដំណាលគ្នា។ ផ្លាស់ទី [XSTEPS] ជំហាននៅលើអ័ក្សដេក [ROTATEDIRX] ផ្លាស់ទី [YSTEPS] ជំហាននៅលើអ័ក្សឈរ [ROTATEDIRY]",
    "gui.blocklyText.multithread.threadsCode": "[THREAD] ចាប់ផ្តើម",
    "gui.blocklyText.multithread.stopThread": "បញ្ឈប់ [THREAD]",
    "gui.blocklyText.multithread.startThread": "ចាប់ផ្តើម [THREAD]",
    "gui.blocklyText.multithread.loop1": "រង្វិលជុំ១",
    "gui.blocklyText.multithread.loop2": "រង្វិលជុំ២",
    "gui.blocklyText.multithread.loop3": "រង្វិលជុំ៣",
    "gui.blocklyText.multithread.loop4": "រង្វិលជុំ៤",
    "gui.blocklyText.multithread.loop5": "រង្វិលជុំ៥",
    "gui.blocklyText.multithread.loop6": "រង្វិលជុំ៦",
    "gui.blocklyText.multithread.loop7": "រង្វិលជុំ៧",
    "gui.blocklyText.multithread.loop8": "រង្វិលជុំ៨",
    "gui.blocklyText.multithread.all": "ទាំងអស់",
    "text2speech.speakAndWaitBlock": "និយាយ [WORDS]",
    "text2speech.setVoiceBlock": "ដាក់សំលេងទៅ [VOICE]",
    "text2speech.setLanguageBlock": "ដាក់ភាសាទៅ [LANGUAGE]",
    "text2speech.alto": "alto",
    "text2speech.tenor": "លក្ខណៈធម្មតា",
    "text2speech.squeak": "squeak",
    "text2speech.giant": "យក្ស",
    "text2speech.kitten": "កូនក្មេង",
    "text2speech.defaultTextToSpeak": "សួស្តី",
    "gui.blocklyText.DFR0151.init": "ដំណើរការពីដំបូងនៃ DS1307",
    "gui.blocklyText.DFR0151.adjustTime": "កែតម្រូវពេលវេលា DS1307 ទៅ [YEAR] ឆ្នាំ [MONTH] ខែ [DATE] កាលបរិច្ឆេទ [HOUR] ម៉ោង [MINUTE] នាទី [SECOND] វិនាទី",
    "gui.blocklyText.DFR0151.getTime": "ទទួលពេលវេលា DS1307 [INDEX]",
    "gui.blocklyText.DFR0151.Year": "ឆ្នាំ",
    "gui.blocklyText.DFR0151.month": "ខែ",
    "gui.blocklyText.DFR0151.date": "កាលបរិច្ឆេទ",
    "gui.blocklyText.DFR0151.hour": "ម៉ោង",
    "gui.blocklyText.DFR0151.minute": "នាទី",
    "gui.blocklyText.DFR0151.second": "ទីពីរ",
    "gui.blocklyText.DFR0151.week": "សប្តាហ៍",
    "gui.blocklyText.DFR0469.init": "SD2405 ចាប់ផ្តើម",
    "gui.blocklyText.DFR0469.adjustTime": "កែតម្រូវពេលវេលា SD2405 ទៅ [YEAR] ឆ្នាំ [MONTH] ខែ [DATE] កាលបរិច្ឆេទ [HOUR] ម៉ោង [MINUTE] នាទី [SECOND] វិនាទី",
    "gui.blocklyText.DFR0469.getTime": "ទទួលពេលវេលា SD2405 [INDEX]",
    "gui.blocklyText.DFR0126.setPin": "ជើងម៉ូឌុលវិភាគសម្លេង #S[SPIN] #R[RPIN] ជើងអាណាឡូក [APIN]",
    "gui.blocklyText.DFR0126.readSoundFreq": "អានបន្ទារហ្វ្រេកង់ស៍សម្លេង [FREQ]Hz",
    "gui.blocklyText.DFR0126.initSerialNfcIIC": "ចាប់ផ្តើមច្រកចេញចូលនៃម៉ូឌុល NFC តាម IIC",
    "gui.blocklyText.DFR0126_microbit.initSerialNfcMicrobit": "ចាប់ផ្តើមច្រកចេញចូលនៃ Hardware របស់ម៉ូឌុល NFC​ ច្រកស៊េរី Rx[RX] Tx[TX]",
    "gui.blocklyText.DFR0126.initSerialNfc": "ចាប់ផ្តើមច្រកចេញចូលនៃម៉ូឌុល NFC [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126_esp32.initSerialNfc": "ចាប់ផ្តើមច្រកចេញចូលនៃម៉ូឌុល NFC [SERIALPORT] Rx[PINR] Tx[PINT]",
    "gui.blocklyText.DFR0126.writeSerialNfc": "ប្លុកម៉ូឌុល NFC [DATABLOCK] byte [BYTES] សរសេរ [VALUE]",
    "gui.blocklyText.DFR0126.readSerialNfc": "អានប្លុកម៉ូឌុល NFC [DATABLOCK] ជាមួយ [BYTES] byte (លេខ)",
    "gui.blocklyText.DFR0126.readSerialNfcAll": "អានប្លុកម៉ូឌុល NFC ប្លុក [DATABLOCK] ទិន្នន័យទាំងអស់(អក្សរ)",
    "gui.blocklyText.DFR0126.readSerialNfcUid": "អានម៉ូឌុល NFC ប្លុក UID (អក្សរ)",
    "gui.blocklyText.DFR0126.scanSerialNfcUid": "រកឃើញកាត UID: [TEXT]?",
    "gui.blocklyText.DFR0126.scanSerialNfc": "បានរកឃើញកាត?",
    "gui.blocklyText.sen0245.init": "ចាប់ផ្តើម VL53L0X​​​​​ ឧបករណ៍វាស់ចម្ងាយប្រើពន្លឺ​ឡាស៊ែរ",
    "gui.blocklyText.sen0245.setVL53L0X": "ភាពត្រឹមត្រូវ​នៃ VL53L0X [PRECISION]​​ ម៉ូតវាស់​ [VALUE]",
    "gui.blocklyText.sen0245.readVL53L0X": "VL53L0X ទទួលបានចម្ងាយដោយប្រើពន្លឺឡាស៊ែរ (mm)",
    "gui.blocklyText.SEN0245.LOW": "ទាប(1mm)",
    "gui.blocklyText.SEN0245.HIGH": "ខ្ពស់(0.25mm)",
    "gui.blocklyText.SEN0245.Single": "ការវាស់វែងតែមួយ",
    "gui.blocklyText.SEN0245.Continuous": "ការវាស់វែងជាបន្តបន្ទាប់",
    "gui.blocklyText.serialMp3.initSerialMp3": "ចាប់ផ្តើមស៊េរីច្រកចេញចូលនៃម៉ូឌុល MP3 [SERIALPORT] RX[RX] TX[TX]",
    "gui.blocklyText.serialMp3.controlSerialMp3": "ដាក់ម៉ូតចាក់ចម្រៀងរបស់ស៊េរីម៉ូឌុល MP3 ទៅ [CONTROL]",
    "gui.blocklyText.serialMp3.controlSerialMp3Play": "ដាក់ស៊េរីម៉ូឌុល MP3 ដើម្បីចាក់បទចំរៀង [VALUE] បទ",
    "gui.blocklyText.serialMp3.setMP3Volume": "ដាក់ស៊េរីម៉ូឌុល MP3 ដើម្បីកម្រិតសម្លេងត្រឹម [VOLUME]%",
    "gui.blocklyText.serialMp3.play": "លេង",
    "gui.blocklyText.serialMp3.pause": "ផ្អាក",
    "gui.blocklyText.serialMp3.end": "ចប់",
    "gui.blocklyText.serialMp3.prev": "បកក្រោយ",
    "gui.blocklyText.serialMp3.next": "បន្ទាប់",
    "gui.blocklyText.serialMp3.volumeUp": "ដំឡើងសម្លេង",
    "gui.blocklyText.serialMp3.volumeDown": "បន្ថយសម្លេង",
    "gui.blocklyText.serialMp3.playlist": "បញ្ជីចាក់",
    "gui.blocklyText.serialMp3.insertTracks": "បញ្ចូលបទ",
    "gui.blocklyText.serialMp3.volume": "កម្រិតសម្លេង",
    "gui.blocklyText.servo360.setServo360": "ជើង [PIN] ម៉ូទ័រស៊ែរវូបង្វិល [DIR] នៅល្បឿន [SPEED]% ",
    "gui.blocklyText.servo360.CW": "បង្វិលតាមទ្រនិចនាឡិកា",
    "gui.blocklyText.servo360.CCW": "បង្វិលបញ្រ្ចាសទ្រនិចនាឡិកា",
    "gui.blocklyText.TCS34725.initTcs34725": "ចាប់ផ្តើមឧបករណ៍ចាប់ចាប់ពណ៌ TCS34725",
    "gui.blocklyText.TCS34725.readTcs34725": "អានឧបករណ៍ចាប់សញ្ញាពណ៍ TCS34725 [COLOR] [FORMAT]",
    "gui.blocklyText.TCS34725.turnTheGAMMA": "បង្វិល GAMMA",
    "gui.blocklyText.TCS34725.dontTurnGAMMA": "កុំបង្វិល GAMMA",
    "gui.blocklyText.regEmotion8_16.showText": "បង្ហាញអត្ថបទ [TEXT] ពណ៌​ [COLOR]",
    "gui.blocklyText.regEmotion8_16.scroll": "អូសអត្ថបទ [DIR]",
    "gui.blocklyText.regEmotion8_16.left": "ខាងឆ្វេង",
    "gui.blocklyText.regEmotion8_16.right": "ខាងស្តាំ",
    "gui.blocklyText.regEmotion8_16.lightAll": "បង្ហាញម៉ាទ្រីសចំណុចទាំងអស់នៅក្នុង [COLOR]",
    "gui.blocklyText.regEmotion8_16.controlLight": "បង្ហាញកូអរដោនេនៃចំនុច (x:[XAXIS], y:[YXAXIS]) នៅក្នុង [COLOR]",
    "gui.blocklyText.regEmotion8_16.cleanScreen": "លុបអេក្រង់",
    "gui.mainHeader.err": "!កំហុស៖ បឋមកថាកម្មវិធីតែមួយ [%1] មិនអាចត្រូវបានប្រើក្នុងពេលតែមួយបានទេ។ សូមលុបប្លុកស្ទួនចោល។",
    "gui.mainHeader.help": "!គន្លឹះ៖ ប្រសិនបើកម្មវិធីច្រើនត្រូវការប្រតិបត្តិក្នុងពេលតែមួយសូមប្រើ \"multi-threading function\" នៅក្នុង \"Extension\" យោងទៅលើឯកសារជំនួយសម្រាប់ព័ត៌មានលម្អិត។",
    "gui.threadHeader.err1": "! កំហុស៖ បឋមកថាកម្មវិធី [%1] មិនអាចត្រូវបានប្រើក្នុងពេលតែមួយបានទេ។ ពួកវាជាឈុតឧបករណ៍ឬក្តារបញ្ជាផ្សេងគ្នា។",
    "gui.threadHeader.err2": "!ព័ត៌មានជំនួយកំហុស៖ បឋមកថាព្រឹត្តិការណ៍ [%1] មិនអាចត្រូវបានប្រើក្នុងពេលតែមួយបានទេ។ សូមលុបប្លុកស្ទួនចោល។",
    "gui.blocklyText.mqtt.whenReceivedData": "ពេល​ [TOPIC] ទទួលបាន [MSG]",
    "gui.blocklyText.mqtt.mqttMsg": "សារ MQTT",
    "gui.blocklyText.wifi.wifiConnectByPwd": "Wi-Fi ភ្ជាប់ទៅគណនី:[ACCOUNT] លេខកូដសម្ងាត់:[PASSWORD]",
    "gui.blocklyText.wifi.wifiConnected": "តើ Wi-Fi បានភ្ជាប់ឬនៅ?",
    "gui.blocklyText.wifi.wifiDisConnected": "ផ្តាច់ Wi-Fi",
    "gui.blocklyText.wifi.wifiGetLocalIP": "ទទួល Wi-Fi configuration [CONFIGURATION]",
    "gui.blocklyText.wifi.getWiFiLocalIP": "អាសយដ្ឋាន IP",
    "gui.blocklyText.wifi.getWiFiNetmask": "Subnet Mask",
    "gui.blocklyText.wifi.getGateway": "Gateway",
    "gui.blocklyText.wifi.wifiSetSoftAP": "Wi-Fi soft AP ម៉ូត:[ACCOUNT2] លេខកូដសម្ងាត់:[PASSWORD2]",
    "gui.blocklyText.wifi.wifiGetSoftAPIP": "Wi-Fi ទទួល soft ap ip",
    "gui.blocklyText.wifi.whenDebugInfo": "ទទួលបានWi-Fi​ [DEBUG] [ERROR]",
    "gui.blocklyText.wifi.whenDebugInfoDebug": "ព័ត៍មាន debug",
    "gui.blocklyText.wifi.whenDebugInfoError": "កូដកំហុស",
    "gui.blocklyText.ntp.ntpSetNetworkTime": "ntp ដាក់ម៉ោងបណ្តាញ, ម៉ោងក្នុងតំបន់ [TIMEZONE] កំណត់ម៉ោងម៉ាស៊ីនមេ [TIMESERVER]",
    "gui.blocklyText.ntp.ntpGetLocalTime": "ntp ទទួលម៉ោងក្នុងតំបន់ [TYPE]",
    "gui.blocklyText.ntp.UTCWest12_t": "UTCWest12_t",
    "gui.blocklyText.ntp.UTCWest11_t": "UTCWest11_t",
    "gui.blocklyText.ntp.UTCWest10_t": "UTCWest10_t",
    "gui.blocklyText.ntp.UTCWest9_t": "UTCWest9_t",
    "gui.blocklyText.ntp.UTCWest8_t": "UTCWest8_t",
    "gui.blocklyText.ntp.UTCWest7_t": "UTCWest7_t",
    "gui.blocklyText.ntp.UTCWest6_t": "UTCWest6_t",
    "gui.blocklyText.ntp.UTCWest5_t": "UTCWest5_t",
    "gui.blocklyText.ntp.UTCWest4_t": "UTCWest4_t",
    "gui.blocklyText.ntp.UTCWest3_t": "UTCWest3_t",
    "gui.blocklyText.ntp.UTCWest2_t": "UTCWest2_t",
    "gui.blocklyText.ntp.UTCWest1_t": "UTCWest1_t",
    "gui.blocklyText.ntp.UTCMiddle_t": "UTCMiddle_t",
    "gui.blocklyText.ntp.UTCEast1_t": "UTCEast1_t",
    "gui.blocklyText.ntp.UTCEast2_t": "UTCEast2_t",
    "gui.blocklyText.ntp.UTCEast3_t": "UTCEast3_t",
    "gui.blocklyText.ntp.UTCEast4_t": "UTCEast4_t",
    "gui.blocklyText.ntp.UTCEast5_t": "UTCEast5_t",
    "gui.blocklyText.ntp.UTCEast6_t": "UTCEast6_t",
    "gui.blocklyText.ntp.UTCEast7_t": "UTCEast7_t",
    "gui.blocklyText.ntp.UTCEast8_t": "UTCEast8_t",
    "gui.blocklyText.ntp.UTCEast9_t": "UTCEast9_t",
    "gui.blocklyText.ntp.UTCEast10_t": "UTCEast10_t",
    "gui.blocklyText.ntp.UTCEast11_t": "UTCEast11_t",
    "gui.blocklyText.ntp.UTCEast12_t": "UTCEast12_t",
    "gui.blocklyText.ntp.year": "ឆ្នាំ",
    "gui.blocklyText.ntp.month": "ខែ",
    "gui.blocklyText.ntp.date": "ថ្ងៃ",
    "gui.blocklyText.ntp.hour": "ម៉ោង",
    "gui.blocklyText.ntp.minute": "នាទី",
    "gui.blocklyText.ntp.second": "វិនាទី",
    "gui.blocklyText.ntp.week": "សប្តាហ៍",
    "gui.blocklyText.udp.udpWhenServerRecvMsg": "នៅពេលម៉ាស៊ីនមេ udp ទទួល [RMSGS]",
    "gui.blocklyText.udp.udpSetServerPort": "កំណត់ច្រកម៉ាស៊ីនមេ udp [PORT]",
    "gui.blocklyText.udp.udpServerSendBroadcast": "ម៉ាស៊ីនមេ udp បញ្ជូនការផ្សាយ [MSGS]",
    "gui.blocklyText.udp.udpWhenClientRecvMsg": "នៅពេលដែលអតិថូបករណ៍ udp ទទួល [RMSGC]",
    "gui.blocklyText.udp.udpSetClientIpPort": "កំណត់អាសយដ្ឋាន IP របស់អតិថូបករណ៍ udp [IPADDR] ច្រក [PORT]",
    "gui.blocklyText.udp.udpClientSendBroadcast": "អតិថូបករណ៍ udp បញ្ជូនការផ្សាយ [MSGC]",
    "gui.blocklyText.udp.ServerRecvedMsg": "សារ",
    "gui.blocklyText.udp.ClientRecvedMsg": "សារ",
    "gui.blocklyText.http.paramHeader": "HTTP [PARAMHEADER] បន្ថែមច្នុច [KEY] តម្លៃ [VALUE]",
    "gui.blocklyText.http.requestHeader": "បឋមកថាសំណើ HTTP បន្ថែម [TEXT]",
    "gui.blocklyText.http.getPost": "អស់ពេល HTTP [GETPOST] URL [URL] អស់ពេល [TIMEOUT] ms",
    "gui.blocklyText.http.readMessage": "HTTP អាន [LINE] សារបន្ទាត់",
    "gui.blocklyText.http.param": "ប៉ារ៉ាម៉ែត្រ",
    "gui.blocklyText.http.header": "បឋមកថា",
    "gui.blocklyText.http.oneline": "មួយ",
    "gui.blocklyText.http.allline": "ទាំងអស់",
    "gui.blocklyText.mqtt.mqttConnect": "ភ្ជាប់ MQTT",
    "gui.blocklyText.mqtt.mqttConnected": "តើំ MQTT បានភ្ជាប់ឬនៅ?",
    "gui.blocklyText.mqtt.acceptance.meaage": "ពេល​ [TOPIC] បានទទួលសារ [MSG]",
    "gui.blocklyText.mqtt.mqttSend": "MQTT ផ្ញើសារ [MSG] ទៅកាន់ [TOPIC]",
    "gui.blocklyText.mqtt.initial": "MQTT​ ចាប់ផ្តើម [SETTING]",
    "gui.blocklyText.mqtt.mqttDisconnect": "ផ្តាច់ MQTT",
    "gui.blocklyText.sen0251.bmp388Init": "ចាប់ផ្តើម BMP388",
    "gui.blocklyText.sen0251.readAltitude": "BMP388 អានរយៈកម្ពស់ (m)",
    "gui.blocklyText.sen0251.readPressure": "BMP388 អានសម្ពាធ (m)",
    "gui.blocklyText.sen0251.readTemperature": "BMP388 អានសីតុណ្ហភាព (℃)",
    "gui.blocklyText.sen0206.sen0206Measuring": "រង្វាស់ MLX90614 [TYPE] [UINT]",
    "gui.blocklyText.sen0206.object": "វត្ថុ",
    "gui.blocklyText.sen0206.environment": "បរិស្ថាន",
    "gui.blocklyText.sen0206.celsius": "អង្សាសេ",
    "gui.blocklyText.sen0206.fahrenheit": "ហ្វារិនហៃ",
    "gui.blocklyText.sen0253.init": "10DOF ជើងដំបូង I2C_ADDR កម្រិត [LEVEL]",
    "gui.blocklyText.sen0253.reset": "10DOF កំណត់ឧបករណ៍ចាប់សញ្ញាសារឡើងវិញ",
    "gui.blocklyText.sen0253.power": "10DOF ដាក់ម៉ូតថាមពល [MODEL]",
    "gui.blocklyText.sen0253.getEuler": "10DOF ទទួលមុំអឺល័រ [EULER]",
    "gui.blocklyText.sen0253.getQua": "10DOF ទទួល qua [AXIS] អ័ក្ស ",
    "gui.blocklyText.sen0253.getAcc": "10DOF ទទួលឧបករណ៍វាស់សំទុះនៅអ័ក្ស [AXIS] (mg)",
    "gui.blocklyText.sen0253.getMag": "10DOF ទទួលឧបករណ៍វាស់មេដែកនៅអ័ក្ស [AXIS] ​(μT)",
    "gui.blocklyText.sen0253.getLinearAcc": "10DOF​ ទទួលសំទុះលីនេអ៊ែរនៅអ័ក្ស [AXIS] (mg)",
    "gui.blocklyText.sen0253.getGravitCapacity": "10DOF ទទួលសំទុះទំនាញដីនៅអ័ក្ស [AXIS] (mg)",
    "gui.blocklyText.sen0253.getAngularSpeed": "10DOF ទទួលល្បឿនមុំនៅអ័ក្ស [AXIS]",
    "gui.blocklyText.sen0253.getBmp280": "10DOF ទទួល [PARAMETER]",
    "gui.blocklyText.sen0253.LOW": "ទាប",
    "gui.blocklyText.sen0253.HIGH": "ខ្ពស់",
    "gui.blocklyText.sen0253.normal": "ធម្មតា",
    "gui.blocklyText.sen0253.lowPower": "ថាមពលទាប",
    "gui.blocklyText.sen0253.suspend": "ផ្អាក",
    "gui.blocklyText.sen0253.Roll": "Roll(°)",
    "gui.blocklyText.sen0253.Pitch": "Pitch(°)",
    "gui.blocklyText.sen0253.Yaw": "Yaw(°)",
    "gui.blocklyText.sen0253.temperature": "សីតុណ្ហភាព",
    "gui.blocklyText.sen0253.elevation": "ការតម្លើង",
    "gui.blocklyText.sen0253.airPressure": "សម្ពាធខ្យល់",
    "gui.blocklyText.sen0290.init": "ឧបករណ៍ចាប់ពន្លឺ ដាក់ជើង irq [IRQPIN] និងអាសយដ្ឋាន i2c [IICADDR]",
    "gui.blocklyText.sen0290.IICinit": "lighting sensor lightning sensor iic init ok？",
    "gui.blocklyText.sen0290.manualCal": "ឧបករណ៍ចាប់ពន្លឺ ដាក់ជើង capacitance [CAPACITANCE] ម៉ូត [MODE] dist [DIST]",
    "gui.blocklyText.sen0290.indoors": "ខាងក្នុង",
    "gui.blocklyText.sen0290.outdoors": "ខាងក្រៅ",
    "gui.blocklyText.sen0290.disturberDis": "disturber បិទដំណើរការ",
    "gui.blocklyText.sen0290.disturberEn": "disturber បើកដំណើរការ",
    "gui.blocklyText.sen0290.errMsg": "សារកំហុស",
    "gui.blocklyText.sen0290.sen0290GetInterruptSrc": "ស្ថានភាពរបស់ឧបករណ៍ចាប់ពន្លឺ",
    "gui.blocklyText.sen0290.GetLightningDistKm": "ឧបករណ៍ចាប់សញ្ញារន្ទះទទួលចម្ងាយរន្ទះ(Km)",
    "gui.blocklyText.sen0290.GetStrikeEnergyRaw": "ឧបករណ៍ចាប់សញ្ញាពន្លឺ់ទទួលបានកូដកម្មថាមពលដើម",
    "gui.blocklyText.sen0291.sen0291Init": "ឧបករណ៍វាស់ថាមពលអគ្គីសនីចាប់ផ្តើមអាសយដ្ឋាន I2C [IICADDR]",
    "gui.blocklyText.sen0291.sen0291Read": "ឧបករណ៍វាស់អានុភាពអានបន្ទុក [LOAD]",
    "gui.blocklyText.sen0291.sen0291Calibration": "ការវាស់ចរន្តជាក់ស្តែងនៃការកែតម្រូវឧបករណ៍វាស់ថាមពលគឺ [EXT] mA",
    "gui.blocklyText.sen0291.voltage": "តង់ស្យុង​ (mV)",
    "gui.blocklyText.sen0291.electric": "ចរន្ត (mA)",
    "gui.blocklyText.sen0291.power": "អានុភាព (mW)",
    "gui.blocklyText.sen0291.shuntVoltage": "តង់ស្យុង Shunt (mV)",
    "gui.blocklyText.sen0291.iicaddr1": "0x40 (00)",
    "gui.blocklyText.sen0291.iicaddr2": "0x41 (10)",
    "gui.blocklyText.sen0291.iicaddr3": "0x44 (01)",
    "gui.blocklyText.sen0291.iicaddr4": "0x45 (11)",
    "gui.blocklyText.weather.weatherGetWithCity": "[WEATHER] នៅ [CITY] ទីក្រុង [COUNTRY] ខេត្ត",
    "gui.blocklyText.weather.temperaturHigh": "សីតុណ្ហភាពអតិបរមា(℃)",
    "gui.blocklyText.weather.temperaturLow": "សីតុណ្ហភាពអប្បរមា(℃)",
    "gui.blocklyText.weather.weather": "អាកាសធាតុ",
    "gui.blocklyText.weather.weatherInit": "ម៉ាស៊ីនមេអាកាសធាតុដាក់ដំណើរការដំបូង [SETTING]",
    "gui.blocklyText.weather.serverAddr": "អាសយដ្ឋានម៉ាស៊ីនមេ",
    "gui.blocklyText.weather.appid": "appid",
    "gui.blocklyText.weather.appsecret": "ការពិនិត្យមើល",
    "gui.blocklyText.tinywebdb.tinywebdbSetServerParameter": "ដាក់ប៉ារ៉ាម៉ែត្រម៉ាស៊ីនមេ [TINYWEBDBINIT]",
    "gui.blocklyText.tinywebdb.tinywebdbUpdateTag": "ធ្វើបច្ចុប្បន្នភាពស្លាកថ្មី [TAG] តម្លៃ [VALUE]",
    "gui.blocklyText.tinywebdb.tinywebdbReadTag": "អានស្លាក [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbDeleteTag": "លុបស្លាក [TAG]",
    "gui.blocklyText.tinywebdb.tinywebdbCount": "ស្លាករាប់",
    "gui.blocklyText.tinywebdb.tinywebdbTestConnect": "តេស្តការភ្ជាប់បណ្តាញ",
    "gui.blocklyText.tinywebdb.tinywebdbSearchTag": "ស្វែងរកស្លាកពីលេខចាប់ផ្តើម [NUMS] រាប់ចំនួនអថេរ [NUMV] ស្លាក [TAG] ប្រភេទ [TAGTYPE]",
    "gui.blocklyText.tinywebdb.tag": "ស្លាក",
    "gui.blocklyText.tinywebdb.value": "តម្លៃ",
    "gui.blocklyText.tinywebdb.both": "ទាំងពីរ",
    "gui.blocklyText.weather.anhui": "Anhui",
    "gui.blocklyText.weather.aomen": "Aomen",
    "gui.blocklyText.weather.beijing": "Beijing",
    "gui.blocklyText.weather.chongqing": "Chongqing",
    "gui.blocklyText.weather.fujian": "Fujian",
    "gui.blocklyText.weather.gansu": "Gansu",
    "gui.blocklyText.weather.guangdong": "Guangdong",
    "gui.blocklyText.weather.guangxi": "Guangxi",
    "gui.blocklyText.weather.guizhou": "Guizhou",
    "gui.blocklyText.weather.hainan": "Hainan",
    "gui.blocklyText.weather.hebei": "Hebei",
    "gui.blocklyText.weather.henan": "Henan",
    "gui.blocklyText.weather.heilongjiang": "Heilongjiang",
    "gui.blocklyText.weather.hubei": "Hubei",
    "gui.blocklyText.weather.hunan": "Hunan",
    "gui.blocklyText.weather.jiangxi": "Jiangxi",
    "gui.blocklyText.weather.jiangsu": "Jiangsu",
    "gui.blocklyText.weather.jilin": "Jilin",
    "gui.blocklyText.weather.liaoning": "Liaoning",
    "gui.blocklyText.weather.neimenggu": "Nei Mongol",
    "gui.blocklyText.weather.ningxia": "Ningxia",
    "gui.blocklyText.weather.qinghai": "Qinghai",
    "gui.blocklyText.weather.sichuan": "Sichuan",
    "gui.blocklyText.weather.shandong": "Shandong",
    "gui.blocklyText.weather.shanxi": "Shanxi",
    "gui.blocklyText.weather.shan3xi": "Shan3xi",
    "gui.blocklyText.weather.shanghai": "Shanghai",
    "gui.blocklyText.weather.tianjin": "Tianjin",
    "gui.blocklyText.weather.taiwan": "Taiwan",
    "gui.blocklyText.weather.xinjiang": "Xinjiang",
    "gui.blocklyText.weather.xizang": "Tibet",
    "gui.blocklyText.weather.xianggang": "HongKong",
    "gui.blocklyText.weather.yunnan": "Yunnan",
    "gui.blocklyText.weather.zhejiang": "Zhejiang",
    "speech.whenIHear": "នៅពេលលឺ [PHRASE]",
    "speech.listenAndWait": "ស្តាប់និងរង់ចាំ",
    "speech.getSpeech": "លទ្ធផលនៃការទទួលស្គាល់",
    "speech.setRecordTime": "ដាក់ពេលថតសម្លេងទៅ [TIME]",
    "speech.stopRecord": "បញ្ឈប់ការទទួលស្គាល់សម្តី",
    "speech.controlSonogram": "[CONTROL] Sonogram",
    "speech.setServer": "ប្តូរការទទួលស្គាល់សម្តីទៅជា [SERVER]",
    "speech.defaultWhenIHearValue": "សួស្តី",
    "speech.show": "បង្ហាញ",
    "speech.hide": "លាក់",
    "speech.server1": "ម៉ាស៊ីនមេ១",
    "speech.server2": "ម៉ាស៊ីនមេ២",
    "gui.blocklyText.mp3.initMp3": "ចាប់ផ្តើមច្រកចេញចូលនៃម៉ូឌុល DFPlayer MP3 [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.mp3.setMP3Volume": "ដាក់ម៉ូឌុល DFPlayer MP3 ជាមួយកម្រិតសម្លេងត្រឹម [VOLUME]%",
    "gui.blocklyText.mp3.setMP3PlayMode": "ដាក់ម៉ូឌុល DFPlayer MP3 ម៉ូតដែលកំពុងលេងទៅជា [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySong": "ដាក់ម៉ូឌុល DFPlayer MP3 លេង [NUM] បទ",
    "gui.blocklyText.mp3.setMP3VolumeJavascript": "ដាក់ជើង [PIN] កម្រិតសម្លេងរបស់ម៉ូឌុល DFPlayer MP3 ត្រឹម [VOLUME]",
    "gui.blocklyText.mp3.setMP3PlayModeJavascript": "ដាក់ជើង [PIN] ម៉ូតលេងរបស់ម៉ូឌុល DFPlayer MP3 ទៅជា [MODE]",
    "gui.blocklyText.mp3.setMP3PlaySongJavascript": "ជើង [PIN] ម៉ូឌុល DFPlayer MP3 ចាក់ចម្រៀង [NUM]",
    "gui.blocklyText.tel0094.GPSInit": "GPS ដំណើរការពីដំបូង [SERIALPORT] Rx[RX] Tx[TX]",
    "gui.blocklyText.tel0094.GPSGetGeographicLocation": "GPS ទទួលទីតាំង [LATLON](°)",
    "gui.blocklyText.tel0094.GPSGetSatelliteTime": "GPS ទទួលពេលវេលាពីផ្កាយរណប [TIME]",
    "gui.blocklyText.tel0094.LATITUDE": "រយៈបណ្តោយ",
    "gui.blocklyText.tel0094.LONGITUDE": "រយៈទទឹង",
    "gui.blocklyText.tel0094.YEAR": "ឆ្នាំ",
    "gui.blocklyText.tel0094.MONTH": "ខែ",
    "gui.blocklyText.tel0094.DAY": "ថ្ងៃ",
    "gui.blocklyText.tel0094.HOUR": "ម៉ោង",
    "gui.blocklyText.tel0094.MINUTE": "នាទី",
    "gui.blocklyText.tel0094.SECOND": "វិនាទី",
    "gui.blocklyText.tel0094.ALL": "ទាំងអស់",
    "gui.blocklyText.tel0094.GPSGet": "GPS ទទួល [GPS]",
    "gui.blocklyText.tel0094.getState": "ស្ថានភាព",
    "gui.blocklyText.tel0094.getLatitudeHemisphere": "អឌ្ឍគោលរយៈទទឹង",
    "gui.blocklyText.tel0094.getLongitudeHemisphere": "អឌ្ឍគោលរយៈបណ្តោយ",
    "gui.blocklyText.tel0094.getGroundSpeed": "ល្បឿនលើដី",
    "gui.blocklyText.tel0094.getSatellitesCount": "ចំនួនផ្កាយរណប",
    "gui.blocklyText.tel0094.getAltitude": "រយៈកម្ពស់",
    "gui.blocklyText.tel0094.getPdop": "ភាពត្រឹមត្រូវនៃទីតាំង",
    "gui.blocklyText.tel0094.getHdop": "ភាពត្រឹមត្រូវនៃអ័ក្សដេក",
    "gui.blocklyText.tel0094.getVdop": "ភាពត្រឹមត្រូវនៃអ័ក្សឈរ",
    "gui.blocklyText.tel0094.getPositioningMode": "ម៉ូតកំណត់ទីតាំង",
    "gui.blocklyText.microIoT.displayInLine": "OLED បង្ហាញបន្ទាត់ [LINE] អត្ថបទ [TEXT]",
    "gui.blocklyText.microIoT.displayInXY": "OLED បង្ហាញអត្ថបទ [TEXT] នៅកូអរដោណេ x [X] y [Y]",
    "gui.blocklyText.microIoT.imageDrawing": "OLED បង្ហាញរូបភាព​ [IMAGE] x [X] y [Y]",
    "gui.blocklyText.microIoT.clear": "OLED លុបទាំងអស់",
    "gui.blocklyText.microIoT.pointDrawing": "OLED គូសចំណុច x [X] y [Y]",
    "gui.blocklyText.microIoT.setLineWidth": "OLED ដាក់ទំហំខ្សែបន្ទាត់ [LINEWIDTH]",
    "gui.blocklyText.microIoT.lineDrawing": "OLED គូសខ្សែបន្ទាត់ដែលចំណុចចាប់ផ្តើម x [X1] y [Y1] និងចំណុចបញ្ចប់ x [X2] y [Y2]",
    "gui.blocklyText.microIoT.circleDrawing": "OLED គូសរង្វង់ [FILL] ចំណុចកណ្តាលរង្វង់ x [X] y [Y] កាំ [RADIUS]",
    "gui.blocklyText.microIoT.rectangleDrawing": "OLED គូសប្រអប់ [FILL] ដែលចំណុចចាប់ផ្តើម x [X] y [Y] ទទឹង [WIDTH] កម្ពស់ [HEIGHT]",
    "gui.blocklyText.microiot.rgbBrightness": "RGB ដាក់កម្រិតពន្លឺត្រឹម [BRIGHTNESS]",
    "gui.blocklyText.microiot.rgbOff": "អំពូល RGB ទាំងអស់ត្រូវបានបិទ",
    "gui.blocklyText.microiot.allLedsShowColorFromWith": "អំពូល RGB  [NUM1] ទៅ [NUM2] បង្ហាញពណ៌ [COLOR]",
    "gui.blocklyText.micoiot.showGradientColor": "អំពូល RGB  [NUM1] ទៅ [NUM2] បង្ហាញពណ៌ជម្រាលពី [COLOR1] ទៅ [COLOR2]",
    "gui.blocklyText.microiot.rotatePixelsToShow": "RGB រង្វិលជុំដោយ [NUM] ភីកសែល",
    "gui.blocklyText.micoiot.allLedsShowColorFromWith": "អំពូល RGB  [NUM1] ទៅ [NUM2] បង្ហាញពណ៌ [COLOR]",
    "gui.blocklyText.motorIot.all": "ទាំងអស់",
    "gui.blocklyText.microIot.servo": "ម៉ូទ័រស៊ែវូ [INDEX] មុំ [ANGLE]",
    "gui.blocklyText.microIot.mqttInit": "MQTT ប៉ារ៉ាម៉ែត្រចាប់ផ្តើម [PARAMETER]",
    "gui.blocklyText.microIot.mqttSendmsg": "MQTT ផ្ញើសារ [MSG] ទៅកាន់ [TOPIC]",
    "gui.blocklyText.microIot.MQTTMsg": "សារ MQTT",
    "gui.blocklyText.microTouch.whenBtnPress": "នៅពេលដែលគ្រាប់ចុច [KEY] ត្រូវបានចុច",
    "gui.blocklyText.microTouch.Any": "ណាមួយ",
    "gui.blocklyText.microTouch.isButtonPressed": "ច្នុច [KEY] ត្រូវបានចុចឬ?",
    "gui.blocklyText.microTouch.readKeyNormalMode": "ម៉ូតធម្មតាអានច្នុច",
    "gui.blocklyText.microTouch.readKeyMathMode": "អានរបៀបម៉ូតគណិតវិទ្យា [KEYTYPE]",
    "gui.blocklyText.microTouch.Number": "ច្នុចលេខ(num)",
    "gui.blocklyText.microTouch.Function": "មុខងារច្នុច(str)",
    "gui.blocklyText.microTouch.vibrationMotor": "[SWITCH] ម៉ូទ័ររំញ័រ",
    "gui.blocklyText.microTouch.turnOn": "បើក",
    "gui.blocklyText.microTouch.shutDown": "បិទ",
    "gui.blocklyText.microNaturalScience.readSoundIntensity": "អាំងដង់ស៊ីតេសម្លេង",
    "gui.blocklyText.microNaturalScience.readNaturalLight": "ពន្លឺធម្មជាតិ",
    "gui.blocklyText.microNaturalScience.readColour": "អាន [COLOUR]",
    "gui.blocklyText.microNaturalScience.R": "តម្លៃពណ៌ក្រហម",
    "gui.blocklyText.microNaturalScience.G": "តម្លៃពណ៌បៃតង",
    "gui.blocklyText.microNaturalScience.B": "តម្លៃពណ៌ខៀវ",
    "gui.blocklyText.microNaturalScience.readUltravioletLight": "អាំងដង់ស៊ីតេកាំរស្មីយូវី (μW/cm²)",
    "gui.blocklyText.microNaturalScience.switchLED": "[SWITCH] LED",
    "gui.blocklyText.microNaturalScience.on": "បើក",
    "gui.blocklyText.microNaturalScience.off": "បិទ",
    "gui.blocklyText.microNaturalScience.setTDSK": "ដាក់អេឡិចត្រុង TS ជាមួយចំនួនថេរ k [NUM]",
    "gui.blocklyText.microNaturalScience.readTDSK": "ទទួលអេឡិចត្រុង TS ជាមួយចំនួនថេរ k ",
    "gui.blocklyText.microNaturalScience.readTDS": "TDS(ppm)",
    "gui.blocklyText.microNaturalScience.readBme280": "ទទួល [TYPE]",
    "gui.blocklyText.microNaturalScience.readWaterTemperature": "សីតុណ្ហភាពទឹក (°C)",
    "gui.blocklyText.microNaturalScience.Temperature": "សីតុណ្ហភាព(℃)",
    "gui.blocklyText.microNaturalScience.Humidity": "សំណើម(%rh)",
    "gui.blocklyText.microNaturalScience.Altitude": "រយៈកម្ពស់(m)",
    "gui.blocklyText.microNaturalScience.Pressure": "សម្ពាធ(Pa)",
    "gui.blocklyText.micoiot.coordinateDisplay": "RGB កំណត់ចំណុចម៉ាទ្រីស X [X] Y [Y] បង្ហាញពណ៌ [COLOR]",
    "gui.blocklyText.prompt.uno.dhtTHSensorReadMoistureTemperature_interruptExcute": "dht11 មិនអាចដាក់ក្នុងព្រឹតិ្តការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យ dht11 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.uno.dsTSensorReaddtemperature_interruptExcute": "dht22 មិនអាចដាក់ក្នុងព្រឹតិ្តការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យ dht22 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.uno.readDustSensor_ledControl": "PM2.5 មិនអាចដាក់ក្នុងព្រឹតិ្តការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យ pm2.5 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeControl": "PM2.5 អានទិន្នន័យ ដែលអាចបណ្តាលអោយដំណើរការពីដំបូងរបស់ម៉ាទ្រីសចំណុច LED បរាជ័យ",
    "gui.blocklyText.prompt.uno.readDustSensor_ledLatticeShowPic": "PM2.5 អានទិន្នន័យ ដែលអាចបណ្តាលអោយដំណើរការពីដំបូងរបស់ម៉ាទ្រីសចំណុច LED បង្ហាញលំនាំបរាជ័យ",
    "gui.blocklyText.prompt.uno.initMp3_interruptExcute": "Mp3 ដំណើរការពីដំបូងមិនអាចត្រូវបានគេដាក់ក្នុងព្រឹតិ្តការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យក្នុងការចាប់ផ្តើម",
    "gui.blocklyText.prompt.uno.setMP3Volume_interruptExcute": "ការដាក់កម្រិតសម្លេង mp3 មិនអាចត្រូវបានគេដាក់ក្នុងព្រឹតិ្តការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យក្នុងការកំណត់កម្រិតសម្លេង",
    "gui.blocklyText.prompt.uno.setMP3PlayMode_interruptExcute": "ម៉ូតចាក់ចំរៀង mp3 មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យក្នុងម៉ូតចាក់ចំរៀង",
    "gui.blocklyText.prompt.uno.setMP3PlaySong_interruptExcute": "ការចាក់សារថ្មី mp3 មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យការកំណត់ការចាក់សារថ្មីបរាជ័យ",
    "gui.blocklyText.prompt.uno.initSerialMp3_interruptExcute": "ការចាប់ផ្តើមច្រកសេរ៊ី mp3 មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យក្នុងការចាប់ផ្តើម",
    "gui.blocklyText.prompt.uno.controlSerialMp3_interruptExcute": "ច្រកសេរ៊ី mp3 នៃម៉ូតចាក់សារថ្មីមិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបាត់បង់ការកំណត់ម៉ូតចាក់ចំរៀង",
    "gui.blocklyText.prompt.uno.controlSerialMp3Play_interruptExcute": "ច្រកសេរ៊ី mp3 នៃការចាក់សារថ្មីមិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យការចាក់ឡើងវិញ",
    "gui.blocklyText.prompt.uno.volumeSerialMp3Play_interruptExcute": "ច្រកសេរ៊ី mp3 នៃការដាក់កម្រិតសម្លេងមិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យការកម្រិតសម្លេង",
    "gui.blocklyText.prompt.uno.obloqInitialParameter_interruptExcute": "Obloq's mqtt ច្រកសេរ៊ីប្រើកម្មវិធីមិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យការទំនាក់ទំនងច្រកសេរ៊ីប្រើកម្មវិធី",
    "gui.blocklyText.prompt.uno.obloqSendmsgToCloudPlatform_interruptExcute": "Obloq ផ្ញើសារដែលមិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យការបញ្ជូន",
    "gui.blocklyText.prompt.uno.obloqReadMsgFromCloudPlatform_interruptExcute": "Obloq អានសារ mqtt មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យការទទួល",
    "gui.blocklyText.prompt.uno.httpInitialParameter_interruptExcute": "ការចាប់ផ្តើមតាមច្រកសេរ៊ីប្រើកម្មវិធី  នៃ http នៃ obloq មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យការទំនាក់ទំនងតាមច្រកសេរ៊ីប្រើកម្មវិធី ",
    "gui.blocklyText.prompt.uno.httpGet_interruptExcute": "ការទទួលសំណើ obloq http មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យសំណើបរាជ័យ",
    "gui.blocklyText.prompt.uno.httpPost_interruptExcute": "សំណើ Obloq http post មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យសំណើបរាជ័យ",
    "gui.blocklyText.prompt.uno.httpPut_interruptExcute": "ការដាក់សំណើនៃ Obloq http មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យសំណើបរាជ័យ",
    "gui.blocklyText.prompt.uno.readIRReceived_interruptExcute": "ការទទួលអាំងហ្វ្រាក្រហមមិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យការទទួល",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeControl": "ការទទួលអាំងហ្វ្រាក្រហមអាចបណ្តាលឱ្យការចាប់ផ្តើមម៉ាទ្រីសចំណុច LED បរាជ័យ",
    "gui.blocklyText.prompt.uno.readIRReceived_ledLatticeShowPic": "ការទទួលអាំងហ្វ្រាក្រហមអាចបណ្តាលឱ្យម៉ាទ្រីសចំណុច LED បរាជ័យក្នុងការបង្ហាញលំនាំ",
    "gui.blocklyText.prompt.uno.emissionPinAndBit_interruptExcute": "ការបញ្ជូនអាំងហ្វ្រាក្រហមមិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យការបញ្ជូន",
    "gui.blocklyText.prompt.uno.ws2812NumberOfLightsBright_interruptExcute": "អំពូលបន្ទះ RGB មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ និងអាចបណ្តាលឱ្យដំណើរការដំបូងបរាជ័យ",
    "gui.blocklyText.prompt.uno.ws2812SetBrightness_interruptExcute": "អំពូលបន្ទះ RGB មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ និងអាចបណ្តាលឱ្យការកំណត់ជើងបរាជ័យ",
    "gui.blocklyText.prompt.uno.ws2812Clear_interruptExcute": "អំពូលបន្ទះ RGB មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ និងអាចបណ្តាលឱ្យជើងបរាជ័យ",
    "gui.blocklyText.prompt.uno.ws2812AllLedsShowColorFromWith_interruptExcute": "អំពូលបន្ទះ RGB មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ និងអាចបណ្តាលឱ្យការបង្ហាញពណ៌បរាជ័យ",
    "gui.blocklyText.prompt.uno.ws2812ShowGradientColor_interruptExcute": "អំពូលបន្ទះ RGB មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ និងអាចបណ្តាលឱ្យការកែពណ៌បរាជ័យ",
    "gui.blocklyText.prompt.uno.ws2812ShiftPixelsToShow_interruptExcute": "អំពូលបន្ទះ RGB មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ និងអាចបណ្តាលឱ្យការផ្លាស់ទីបរាជ័យ",
    "gui.blocklyText.prompt.uno.ws2812RotatePixelsToShow_interruptExcute": "អំពូលបន្ទះ RGB មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ និងអាចបណ្តាលឱ្យវដ្តនៃចលនាបរាជ័យ",
    "gui.blocklyText.prompt.uno.ws2812ShowHistogram_interruptExcute": "អំពូលបន្ទះ RGB មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ និងអាចបណ្តាលឱ្យការបង្ហាញអ៊ីស្ត្រូក្រាមបរាជ័យ",
    "gui.blocklyText.prompt.uno.ledLatticeControl_interruptExcute": "ម៉ាទ្រីសចំណុច LED មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យដំណើរការពីដំបូងបរាជ័យ",
    "gui.blocklyText.prompt.uno.ledLatticeShowPic_interruptExcute": "ម៉ាទ្រីសចំណុច LED មិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យបរាជ័យក្នុងការបង្ហាញលំនាំ",
    "gui.blocklyText.prompt.uno.printStr_interruptExcute": "ច្រកចេញនៃសេរ៊ីប្រើកម្មវិធីមិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យលទ្ធផលមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.uno.readSerialportData_interruptExcute": "ទិន្នន័យអានដោយច្រកសេរ៊ីប្រើកម្មវិធីមិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យការអានមាតិកាមិនប្រក្រតី",
    "gui.blocklyText.prompt.uno.readSerialportData_printStr": "ទិន្នន័យនៃច្រកសេរ៊ីប្រើកម្មវិធីនិងការអានទិន្នន័យត្រូវបានប្រើក្នុងពេលតែមួយ ដែលអាចបណ្តាលឱ្យទិន្នន័យមានភាពមិនប្រក្រតី",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_interruptExcute": "ការអានចំនួនគត់នៃច្រកសេរ៊ីប្រើកម្មវិធីមិនអាចដាក់នៅក្នុងព្រឹត្តិការណ៍រំខានទេ ដែលអាចបណ្តាលឱ្យការអានមាតិកាមិនប្រក្រតី",
    "gui.blocklyText.prompt.uno.readSerialportIntFloat_printStr": "ទិន្នន័យលទ្ធផលច្រកសេរ៊ីប្រើកម្មវិធីនិងការអានចំនួនគត់ត្រូវបានប្រើក្នុងពេលតែមួយ ដែលអាចបណ្តាលឱ្យមានភាពមិនប្រក្រតីនៃការអានទិន្នន័យ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readDustSensor": "នៅពេលដែល dht11 និង PM2.5 ស្ថិតនៅក្នុងខ្សែស្រឡាយពីរ ការអាន dht11 អាចនឹងបរាជ័យប្រសិនបើអាន pm2.5 ញឹកញាប់ពេក។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន pm2.5",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_readdtemperatur": "នៅពេលដែល dht11 និង ds18b20 ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ប្រសិនបើ ds18b20 អានញឹកញាប់ពេក dht11 អាចនឹងបរាជ័យក្នុងការអាន។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initMp3": "នៅពេលដែល dht11 និង mp3 ត្រូវបានដាក់អោយដំណើរការពីដំបូងនៅក្នុងខ្សែស្រឡាយពីរ, ការចាប់ផ្តើម mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3Volume": "នៅពេលដែល dht11 និង mp3 ដាក់កម្រិតសម្លេងនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការដាក់កម្រិតសម្លេង mp3 អាចបណ្តាលឱ្យ ​dht11 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlayMode": "នៅពេលដែល dht11 និង mp3 ដំឡើងម៉ូតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការដំឡើងម៉ូត mp3 អាចបណ្តាលឱ្យ ​dht11 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setMP3PlaySong": "នៅពេលដែល dht11 និង mp3 player​ ស្ថិតនៅក្នុងខ្សែស្រឡាយពីរ, mp3 player អាចបណ្តាលឱ្យ ​dht11 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initSerialMp3": "នៅពេលដែល dht11 និងសេរ៊ី mp3 ត្រូវបានដាក់អោយដំណើរការពីដំបូងនៅក្នុងខ្សែស្រឡាយពីរ, ការចាប់ផ្តើមសេរ៊ី mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3": "នៅពេលដែល dht11 និងម៉ូឌុលគ្រប់គ្រងសេរ៊ី mp3 ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ម៉ូឌុលគ្រប់គ្រង mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht12",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_controlSerialMp3Play": "នៅពេលដែល dht11 និងសេរ៊ី mp3 player ស្ថិតនៅក្នុងខ្សែស្រឡាយពីរ, សេរ៊ី mp3 player អាចបណ្តាលឱ្យ dht11 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_volumeSerialMp3Play": "នៅពេលដែល dht11 និងសេរ៊ី mp3 ដាក់កម្រិតសម្លេងនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការដាក់កម្រិតសម្លេងសេរ៊ី mp3 អាចបណ្តាលឱ្យ dht11 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_numberOfLightsBright": "នៅពេលដែល dht11 និង RGB ត្រូវបានដាក់អោយដំណើរការពីដំបូងនៅក្នុងខ្សែស្រឡាយពីរ, ការចាប់ផ្តើម RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_setBrightness": "នៅពេលដែល dht11 និង RGB ដាក់កម្រិតពន្លឺនៅក្នុងខ្សែស្រឡាយពីរ, ការដាក់កម្រិតពន្លឺ RGB អាចបណ្តាលឱ្យ dht11 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_clear": "នៅពេលដែល dht11 និង RGB ត្រូវបានលុបនៅក្នុងខ្សែស្រឡាយពីរ, ការលុប RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_allLedsShowColorFromWith": "នៅពេលដែល dht11 និង RGB បង្ហាញពណ៌នៅក្នុងខ្សែស្រឡាយពីរ, ការបង្ហាញពណ៌ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showGradientColor": "នៅពេលដែល dht11 និង RGB បង្ហាញពណ៌ជម្រាលនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការបង្ហាញពណ៌ជម្រាល RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_shiftPixelsToShow": "នៅពេលដែល dht11 និង RGB ផ្លាស់ទីភីកសែលនៅក្នុងខ្សែស្រឡាយពីរ, ការផ្លាស់ទីភីកសែល RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_rotatePixelsToShow": "នៅពេលដែល dht11 និង RGB ផ្លាស់ទីភីកសែលរង្វិលជុំនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការផ្លាស់ទីភីកសែលរង្វិលជុំ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_showHistogram": "នៅពេលដែល dht11 និង RGB បង្ហាញជួរឈរនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការបង្ហាញរបារ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_printStr": "នៅពេលដែល dht11 និងសេរ៊ីប្រើកម្មវិធីបញ្ជូននៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការបញ្ជូនសេរ៊ីប្រើកម្មវិធីអាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_initialParameter": "នៅពេលដែល dht11 និង obloq MQTT ត្រូវបានដាក់អោយដំណើរការពីដំបូងនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការចាប់ផ្តើម obloq MQTT អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11។ វាត្រូវណែនាំដើម្បីរង់ចាំអោយបញ្ចប់ការដំណើរការដំបូងមុននឹងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_sendmsgToCloudPlatform": "នៅពេលដែល dht11 និង obloq MQTT ផ្ញើសារនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការផ្ញើសារ obloq MQTT អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPut": "នៅពេលដែល dht11 និង obloq HTTP ដាក់សំណើនៅក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpInitialParameter": "នៅពេលដែល dht11 និង obloq HTTP ត្រូវបានដាក់អោយដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីររៀងគ្នា, វាអាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11។ អ្នកគួររង់ចាំដំណើរការដំបូងអោយចប់ជាមុនសិន មុននឹងអាន dht11",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpGet": "នៅពេលដែល dht11 និង obloq HTTP ទទួលសំណើនៅក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យ dht11 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_httpPost": "នៅពេលដែល dht11 និង obloq HTTP ទទួលសំណើសំណូមពរនៅក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យ dht11 អានមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.microbit.dhtTHSensorReadMoistureTemperature_emissionPinAndBit": "នៅពេលដែល dht11 និងការបំភាយអ៊ីនហ្វ្រាក្រហម ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, វាអាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន dht11។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការបំភាយអ៊ីនហ្វ្រាក្រហម",
    "gui.blocklyText.prompt.microbit.readdtemperature_readDustSensor": "នៅពេលដែល ds18b20 និង PM2.5 ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, វាអាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន PM2.5, ដូច្នេះអ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_initialParameter": "នៅពេលដែល ds18b20 និង obloq MQTT ត្រូវបានដាក់អោយដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការចាប់ផ្តើម MQTT អាចបរាជ័យ។ អ្នកគួររង់ចាំដំណើរការដំបូងអោយចប់ជាមុនសិន មុននឹងអាន ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPut": "នៅពេលដែល ds18b20 និង obloq HQTT ដាក់សំណើនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការដាក់សំណើអាចបរាជ័យ។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpInitialParameter": "នៅពេលដែល ds18b20 និង obloq HTTP ត្រូវបានដាក់អោយដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការចាប់ផ្តើម HTTP អាចបរាជ័យ។ អ្នកគួររង់ចាំដំណើរការដំបូងអោយចប់ជាមុនសិន មុននឹងអាន ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpGet": "នៅពេលដែល ds18b20 និង obloq HQTT ទទួលសំណើនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការទទួលសំណើអាចបរាជ័យ។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_httpPost": "នៅពេលដែល ds18b20 និង obloq HQTT សំណើសំណូមពរនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការសំណើសំណូមពរអាចបរាជ័យ។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readIRReceived": "ការអាន ds18b20 អាចបណ្តាលឱ្យទិន្នន័យអ៊ីនហ្វ្រាក្រហមមិនត្រូវបានទទួល, ដូច្នេះអ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20",
    "gui.blocklyText.prompt.microbit.readdtemperature_readSerialportData": "ការអាន ds18b20 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យតាមច្រកសេរ៊ីប្រើកម្មវិធី, ដូច្នេះអ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20",
    "gui.blocklyText.prompt.microbit.readDustSensor_initialParameter": "នៅពេលដែលការអាន PM2.5 និង obloq MQTT ដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការចាប់ផ្តើម MQTT អាចបរាជ័យ ឬអាចមានកំហុសក្នុងការអាន PM2.5។ អ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នានៃការចាប់ផ្តើម MQTT និងការអាន PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_sendmsgToCloudPlatform": "នៅពេលដែលការអាន PM2.5 និង obloq MQTT ផ្ញើសារនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, វាអាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន PM2.5។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPut": "នៅពេលដែលការអាន PM2.5 និង obloq HTTP ដាក់សំណើនៅក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យការដាក់សំណើបរាជ័យ ឬមានកំហុសក្នុងការអាន PM2.5។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការដាក់សំណើ ឬការអាន PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpInitialParameter": "នៅពេលដែលការអាន PM2.5 និង obloq HTTP ដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការចាប់ផ្តើម HTTP អាចបរាជ័យ ឬការអាន PM2.5 អាចខុស។ អ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នានៃការចាប់ផ្តើម HTTP និងការអាន PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpGet": "នៅពេលដែលការអាន PM2.5 និង obloq HTTP ទទួលសំណើនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, វាអាចបណ្តាលឱ្យការទទួលសំណើបរាជ័យ ឬមានកំហុសក្នុងការអាន PM2.5។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការទទួលសំណើ ឬការអាន PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_httpPost": "នៅពេលដែលការអាន PM2.5 និង obloq HTTP សំណើសំណូមពរនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, វាអាចបណ្តាលឱ្យការទទួលសំណើបរាជ័យ ឬមានកំហុសក្នុងការអាន PM2.5។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃសំណើសំណូមពរ ឬការអាន PM2.5",
    "gui.blocklyText.prompt.microbit.readDustSensor_initMp3": "នៅពេលដែលការអាន PM2.5 និង MP3 ដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការអាន PM2.5 អាចបរាជ័យ។ អ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នានៃការអាន PM2.5 និងការចាប់ផ្តើម MP3 ",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3Volume": "នៅពេលដែលការអាន PM2.5 និងការដាក់កម្រិតសម្លេង MP3 នៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា,  ការអាន PM2.5 អាចបរាជ័យ។ អ្នកមិនគួរកែសម្រួលកម្រិតសម្លេងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlayMode": "នៅពេលដែលការអាន PM2.5 និងការលេង MP3 នៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា,  ការអាន PM2.5 អាចបរាជ័យ។ អ្នកមិនគួរលេងឧបករណ៍ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSensor_setMP3PlaySong": "នៅពេលដែលការអាន PM2.5 និងការលេង MP3 នៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា,  ការអាន PM2.5 អាចបរាជ័យ។ អ្នកមិនគួរប្តូរ​បទចំរៀងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3": "នៅពេលដែលការអាន PM2.5 និងសេរ៊ី MP3 player នៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា,  ការអាន PM2.5 អាចបរាជ័យ។ អ្នកមិនគួរលេងឧបករណ៍ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSensor_controlSerialMp3Play": "នៅពេលដែលការអាន PM2.5 និងការលេងសេរ៊ី MP3 នៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា,  ការអាន PM2.5 អាចបរាជ័យ។ អ្នកមិនគួរប្តូរ​បទចំរៀងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSensor_volumeSerialMp3Play": "នៅពេលដែលការអាន PM2.5 និងការដាក់កម្រិតសម្លេងរបស់សេរ៊ី MP3 នៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា,  ការអាន PM2.5 អាចបរាជ័យ។ អ្នកមិនគួរកែសម្រួលកម្រិតសម្លេងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSenso_readIRReceived": "នៅពេលការអាន PM2.5 អាចបណ្តាលឱ្យបរាជ័យក្នុងការទទួលអ៊ីនហ្វ្រាក្រហម, អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន PM2.5។",
    "gui.blocklyText.prompt.microbit.readDustSenso_numberOfLightsBright": "នៅពេលដែលការអាន PM2.5 និង RGB ដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការអាន PM2.5 អាចមានកំហុស។ អ្នកគួរជៀសវាងការប្រតិបត្តិដំណាលគ្នានៃការអាន PM2.5 និងការចាប់ផ្តើម RGB។",
    "gui.blocklyText.prompt.microbit.readDustSenso_setBrightness": "នៅពេលដែលការអាន PM2.5 និងការដាក់កម្រិតពន្លឺ RGB នៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការអាន PM2.5 អាចមានកំហុស។ អ្នកមិនគួរកែសម្រួលកម្រិតពន្លឺញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSenso_clear": "នៅពេលដែលការអាន PM2.5 និងការលុប RGB នៅក្នុងខ្សែស្រឡាយពីរ, ការអាន PM2.5 អាចមានកំហុស។ អ្នកមិនគួរលុប RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSenso_allLedsShowColorFromWith": "នៅពេលដែលការអាន PM2.5 និងការបង្ហាញពណ៌ RGB ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការអាន PM2.5 អាចមានកំហុស។ អ្នកមិនគួរប្តូរពណ៌ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSenso_showGradientColor": "នៅពេលដែលការអាន PM2.5 និងការបង្ហាញពណ៌ជម្រាល RGB ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការអាន PM2.5 អាចមានកំហុស។ អ្នកមិនគួរប្តូរពណ៌ជម្រាលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSenso_shiftPixelsToShow": "នៅពេលដែលការអាន PM2.5 និងការផ្លាស់ទីភីកសែល RGB ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការអាន PM2.5 អាចមានកំហុស។ អ្នកមិនគួរផ្លាស់ទីភីកសែលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_wifi": "ការប្រើ Wi-Fi អាចបណ្តាលឱ្យផ្ទាំងបញ្ជា rgb បង្ហាញពណ៌មិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.esp32SetLightsColor_udp": "Udp ប្រើប្រាស់ Wi-Fi ដែលអាចបណ្តាលឱ្យផ្ទាំងបញ្ជា rgb បង្ហាញពណ៌មិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_wifi": "ការប្រើប្រាស់ Wi-Fi អាចបណ្តាលឱ្យផ្ទាំងបញ្ជា rgb ដាក់ការលើកលែងបីពណ៌",
    "gui.blocklyText.prompt.esp32.esp32RgbToColor_udp": "Udp ប្រើប្រាស់ Wi-Fi ដែលអាចបណ្តាលឱ្យផ្ទាំងបញ្ជា rgb ដាក់ការលើកលែងបីពណ៌",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_wifi": "ការប្រើប្រាស់ Wi-Fi អាចបណ្តាលឱ្យការដាក់កម្រិតពន្លឺនៅផ្ទាំងបញ្ជា rgb មានភាពមិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.esp32SetBrightness_udp": "Udp ប្រើប្រាស់ Wi-Fi ដែលអាចបណ្តាលឱ្យផ្ទាំងបញ្ជា rgb ដាក់កម្រិតពន្លឺមិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_wifi": "ការប្រើប្រាស់ Wi-Fi អាចបណ្តាលឱ្យផ្ទាំងបញ្ជា rgb ទទួលកម្រិតពន្លឺមិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.esp32GetBrightness_udp": "Udp ប្រើប្រាស់ Wi-Fi ដែលអាចបណ្តាលឱ្យផ្ទាំងបញ្ជា rgb ទទួលកម្រិតពន្លឺមិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.initSerialMp3_wifi": "ការប្រើប្រាស់ Wi-Fi អាចបណ្តាលឱ្យច្រកសេរ៊ី mp3 ដំណើរការពីដំបូងមានភាពមិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.initSerialMp3_udp": "Udp ប្រើប្រាស់ Wi-Fi ដែលអាចបណ្តាលឱ្យច្រកសេរ៊ី mp3 ដំណើរការពីដំបូងមានភាពមិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_wifi": "ការប្រើប្រាស់ Wi-Fi អាចបណ្តាលឱ្យច្រកសេរ៊ី mp3 លេងមិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.controlSerialMp3_udp": "Udp ប្រើប្រាស់ Wi-Fi ដែលអាចបណ្តាលឱ្យច្រកសេរ៊ី mp3 លេងមិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_wifi": "ការប្រើប្រាស់ Wi-Fi អាចបណ្តាលឱ្យច្រកសេរ៊ី mp3 ចាក់បទចំរៀងបរាជ័យ",
    "gui.blocklyText.prompt.esp32.controlSerialMp3Play_udp": "Udp ប្រើប្រាស់ Wi-Fi ដែលអាចបណ្តាលឱ្យច្រកសេរ៊ី mp3 ចាក់បទចំរៀងបរាជ័យ",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_wifi": "ការប្រើប្រាស់ Wi-Fi អាចបណ្តាលឱ្យច្រកសេរ៊ី mp3 ដាក់កម្រិតសម្លេងបរាជ័យ",
    "gui.blocklyText.prompt.esp32.volumeSerialMp3Play_udp": "Udp ប្រើប្រាស់ Wi-Fi ដែលអាចបណ្តាលឱ្យច្រកសេរ៊ី mp3 ដាក់កម្រិតសម្លេងបរាជ័យ",
    "gui.blocklyText.prompt.esp32.setServo360_wifi": "ការប្រើប្រាស់ Wi-Fi អាចបណ្តាលឱ្យចង្កូត ៣៦០ កំណត់ល្បឿនមិនប្រក្រតី",
    "gui.blocklyText.prompt.esp32.setServo360_udp": "Udp ប្រើប្រាស់ Wi-Fi ដែលអាចបណ្តាលឱ្យចង្កូត ៣៦០ កំណត់ល្បឿនមិនប្រក្រតី",
    "gui.blocklyText.prompt.microbit.readDustSenso_rotatePixelsToShow": "នៅពេលដែលការអាន PM2.5 និងផ្លាស់ទីភីកសែលរង្វិលជុំ RGB ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការអាន PM2.5 អាចមានកំហុស។ អ្នកមិនគួរផ្លាស់ទីភីកសែលរង្វិលជុំញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSenso_showHistogram": "នៅពេលដែលការអាន PM2.5 និងការបង្ហាញជួរឈរ RGB ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការអាន PM2.5 អាចមានកំហុស។ អ្នកមិនគួរធ្វើការបង្ហាញជួរឈរ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeControl": "នៅពេលដែលការអាន PM2.5 និងការដំណើរការអារ៉េចំណុច LED ដំបូង ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការដំណើរការពីដំបូងរបស់អារ៉េចំណុច LED អាចខុស។  អ្នកគួរជៀសវាងការប្រតិបត្តិដំណាលគ្នានៃការអាន PM2.5 និងការចាប់ផ្តើមអារ៉េចំណុច LED ។",
    "gui.blocklyText.prompt.microbit.readDustSensor_ledLatticeShowPic": "នៅពេលដែលការអាន PM2.5 និងការបង្ហាញអារ៉េចំណុច LED ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការបង្ហាញរបស់អារ៉េចំណុច LED អាចខុស ហើយអ្នកគួរកាត់បន្ថយការអាន PM2.5 ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSensor_printStr": "ការអាន PM2.5 អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន PM2.5។​ អ្នកមិនគួរបញ្ជូនទិន្នន័យញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readDustSensor_readSerialportData": "ការអាន PM2.5 អាចបណ្តាលឱ្យបរាជ័យក្នុងការអានតាមសេរ៊ីប្រើកម្មវិធី។ អ្នកមិនគួរអាន PM2.5 ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.initMp3_readIRReceived": "MP3 មិនអាចទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, អ្នកគួរកាត់បន្ថយចំនួនដងនៃការប្រើប្រាស់ MP3។",
    "gui.blocklyText.prompt.microbit.initMp3_initialParameter": "នៅពេលដែល MP3 និង obloq MQTT ដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យបរាជ័យក្នុងការដំណើរពីដំបូង MQTT។ អ្នកគួរជៀសវាងការប្រតិបត្តិដំណាលគ្នានៃការចាប់ផ្តើម MQTT និងការចាប់ផ្តើម MP3។",
    "gui.blocklyText.prompt.microbit.initMp3_httpPut": "នៅពេលដែល MP3 និង obloq HTTP ដាក់សំណើនៅក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យការដាក់សំណើបរាជ័យ។  អ្នកគួរកាត់បន្ថយចំនួនដងនៃការដាក់សំណើ ឬកាត់បន្ថយចំនួនដងនៃការប្រើប្រាស់ MP3។",
    "gui.blocklyText.prompt.microbit.initMp3_httpInitialParameter": "នៅពេលដែល MP3 និង obloq HTTP ដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យបរាជ័យក្នុងការដំណើរពីដំបូង HTTP។ អ្នកគួរជៀសវាងដំណើរការពីដំបូងទាំងពីរនៃ HTTP និង MP3។",
    "gui.blocklyText.prompt.microbit.initMp3_httpGet": "នៅពេលដែល MP3 និង obloq HTTP ទទួលសំណើនៅក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យការទទួលសំណើបរាជ័យ។  អ្នកគួរកាត់បន្ថយចំនួនដងនៃការទទួលសំណើ ឬកាត់បន្ថយចំនួនដងនៃការប្រើប្រាស់ MP3។",
    "gui.blocklyText.prompt.microbit.initMp3_httpPost": "នៅពេលដែល MP3 និង obloq HTTP សំណើសំណូមពរនៅក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យការស្នើសំណើបរាជ័យ។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការសំណើសំណូមពរ ឬកាត់បន្ថយចំនួនដងនៃការប្រើប្រាស់ MP3",
    "gui.blocklyText.prompt.microbit.initMp3_readSerialportData": "MP3 អាចបណ្តាលឱ្យបរាជ័យក្នុងការអានច្រកសេរ៊ីប្រើកម្មវិធី, អ្នកមិនគួរប្រើ MP3 ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readIRReceived": "សេរ៊ី MP3 អាចមិនទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, អ្នកគួរកាត់បន្ថយចំនួនដងនៃសេរ៊ី MP3។",
    "gui.blocklyText.prompt.microbit.initSerialMp3_initialParameter": "នៅពេលដែលច្រកសេរ៊ី MP3 និង obloq MQTT ដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីររៀងគ្នា, ការដំណើរពីដំបូង MQTT អាចបរាជ័យ។ អ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នានៃការចាប់ផ្តើម MQTT និងច្រកសេរ៊ី MP3។",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPut": "នៅពេលដែលសេរ៊ី MP3 និង obloq HTTP ដាក់សំណើនៅក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យការដាក់សំណើបរាជ័យ។  អ្នកគួរកាត់បន្ថយចំនួនដងនៃការដាក់សំណើ ឬកាត់បន្ថយចំនួនដងនៃសេរ៊ី MP3។",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpInitialParameter": "ដំណើរការពីដំបូង HTTP អាចបរាជ័យនៅពេលដែលសេរ៊ី MP3 និង obloq ត្រូវបានដាក់ដំណើរការពីដំបូងក្នុងខ្សែស្រឡាយពីរ។  អ្នកគួរជៀសវាងដំណើរការពីដំបូងទាំងពីរនៃ HTTP និង MP3។",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpGet": "នៅពេលដែលច្រកសេរ៊ី MP3 និង obloq HTTP ទទួលសំណើនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, វាអាចបណ្តាលឱ្យការទទួលសំណើបរាជ័យ។  វាត្រូវបានណែនាំឱ្យកាត់បន្ថយចំនួនដងនៃការទទួលសំណើ ឬកាត់បន្ថយចំនួនដងនៃច្រកសេរ៊ី MP3",
    "gui.blocklyText.prompt.microbit.initSerialMp3_httpPost": "នៅពេលដែលច្រកសេរ៊ី MP3 និង obloq HTTP សំណើសំណូមពរនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, វាអាចបណ្តាលឱ្យការសំណើសំណូមពរបរាជ័យ។  អ្នកគួរកាត់បន្ថយចំនួនដងនៃការសំណើសំណូមពរ ឬកាត់បន្ថយចំនួនដងនៃច្រកសេរ៊ី MP3។",
    "gui.blocklyText.prompt.microbit.initSerialMp3_readSerialportData": "ច្រកសេរ៊ី MP3 អាចបណ្តាលឱ្យបរាជ័យក្នុងការអានតាមច្រកសេរ៊ីប្រើកម្មវិធី, អ្នកមិនគួរប្រើច្រកសេរ៊ី MP3 ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_emissionPinAndBit": "ការបំភាយអ៊ីនហ្វ្រាក្រហមអាចនាំឱ្យមានកំហុសឆ្គងដែលទទួលបានតាមសេរ៊ីប្រើកម្មវិធី obloq, អ្នកគួរកាត់បន្ថយចំនួនដងនៃការបំភាយអ៊ីនហ្វ្រាក្រហម។",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readIRReceived": "សេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការផ្ញើសារញឹកញាប់ដោយ obloq។",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_numberOfLightsBright": "ការដំណើរការដំបូងនៃបន្ទារអំពូល RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពី obloq។ អ្នកគួរជៀសវាងការប្រើប្រាស់ជាញឹកញាប់នៃបន្ទារអំពូល RGB។",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_setBrightness": "ការកំណត់កម្រិតពន្លឺនៃអំពូលខ្សែអាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពី obloq។ អ្នកគួរជៀសវាងការប្រើប្រាស់ការកែតម្រូវកម្រិតពន្លឺជាញឹកញាប់។",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_clear": "ការបិទអំពូលខ្សែ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពី obloq, អ្នកគួរជៀសវាងការបិទអំពូលខ្សែជាញឹបញាប់។",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_allLedsShowColorFromWith": "ការបង្ហាញឆ្នូតពន្លឺ RGB អាចបណ្តាលឱ្យមានកំហុសនៅក្នុងការទទួលសារពី obloq, អ្នកគួរជៀសវាងការបង្ហាញពណ៌ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showGradientColor": "ការបង្ហាញពណ៌ជម្រាលនៅលើបន្ទារ RGB អាចបណ្តាលឱ្យមានកំហុសនៅក្នុងការទទួលសារពី obloq, អ្នកគួរជៀសវាងការបង្ហាញពណ៌ជម្រាលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_shiftPixelsToShow": "បន្ទារ RGB ផ្លាស់ទីភីកសែលអាចបណ្តាលឱ្យសេរ៊ីប្រើកម្មវិធី obloq ទទួលសារមិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_rotatePixelsToShow": "បន្ទារ RGB ផ្លាស់ទីភីកសែលរង្វិលជុំ អាចបណ្តាលឱ្យសេរ៊ីប្រើកម្មវិធី obloq មានកំហុសក្នុងការទទួលសារ",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_showHistogram": "អំពូលខ្សែ RGB ជាមួយនឹងការបង្ហាញជួរឈរអាចបណ្តាលឱ្យមានកំហុសនៃការទទួលសារពីសេរ៊ីប្រើកម្មវិធី obloq",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeControl": "\r\nនៅពេលដែលសេរ៊ីប្រើកម្មវិធី obloq និងដំណើរការដំបូងរបស់អារ៉េចំណុច LED ស្ថិតនៅក្នុងខ្សែស្រឡាយពីររៀងគ្នា, វាអាចបណ្តាលឱ្យមានកំហុសក្នុងការចាប់ផ្តើមដំបូងរបស់ អារ៉េចំណុច LED។ អ្នកគួរជៀសវាងប្រតិបត្តិដំណាលគ្នានៃសេរ៊ីប្រើកម្មវិធី obloq និងការចាប់ផ្តើមអឺរ៉េចំណុច LED។",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_ledLatticeShowPic": "នៅពេលដែលសេរ៊ីប្រើកម្មវិធី obloq និងការបង្ហាញអារ៉េចំណុច LED ស្ថិតនៅក្នុងខ្សែស្រឡាយពីរ, វាអាចបណ្តាលឱ្យមានកំហុសក្នុងការបង្ហាញអឺរ៉េចំណុច LED",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_printStr": "ការបញ្ជូនតាមច្រកសេរ៊ីប្រើកម្មវិធី អាចបណ្តាលឱ្យមានកំហុសក្នុងការអាន obloq, អ្នកមិនគួរបញ្ជូនទិន្នន័យញឹកញាប់ពេកទេ។",
    "gui.blocklyText.prompt.microbit.obloqInitialParameter_readSerialportData": "ការបញ្ជូនតាមច្រកសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យការអានតាមច្រកសេរ៊ីប្រើកម្មវិធីបរាជ័យ, ដូច្នេះអ្នកមិនគួរបញ្ជូនទិន្នន័យញឹកញាប់ពេកទេ។",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readIRReceived": "ការបំភាយអ៊ីនហ្វ្រាក្រហមអាចនាំឱ្យគ្មានទិន្នន័យក្នុងការទទួលអ៊ីនហ្រ្វាក្រហម, ដូច្នេះអ្នកគួរកាត់បន្ថយចំនួនដងនៃការបំភាយអ៊ីនហ្វ្រាក្រហម។",
    "gui.blocklyText.prompt.microbit.emissionPinAndBit_readSerialportData": "នៅពេលដែលការបំភាយអ៊ីនហ្វ្រាក្រហមអាចបណ្តាលឱ្យការអានតាមច្រកសេរ៊ីប្រើកម្មវិធីបរាជ័យ,ដូច្នេះអ្នកគួរកាត់បន្ថយចំនួនដងនៃការបំភាយអ៊ីនហ្វ្រាក្រហម។",
    "gui.blocklyText.prompt.microbit.readIRReceived_numberOfLightsBright": "ការដំណើរការដំបូងនៃបន្ទារអំពូល RGB អាចបណ្តាលឱ្យគ្មានទិន្នន័យក្នុងការទទួលអ៊ីនហ្វ្រាក្រហម",
    "gui.blocklyText.prompt.microbit.readIRReceived_setBrightness": "ការកំណត់កម្រិតពន្លឺនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យទិន្នន័យអ៊ីនហ្វ្រាក្រហមមិនអាចត្រូវបានទទួល, ដូច្នេះអ្នកគួរជៀសវាងការកំណត់កម្រិតពន្លឺអំពូលខ្សែ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readIRReceived_clear": "អំពូលខ្សែ RGB បានបិទ, ដែលអាចបណ្តាលឱ្យទិន្នន័យអ៊ីនហ្វ្រាក្រហមមិនអាចត្រូវបានទទួល",
    "gui.blocklyText.prompt.microbit.readIRReceived_allLedsShowColorFromWith": "ការបង្ហាញពណ៌អំពូលខ្សែ RGB អាចបណ្តាលឱ្យទិន្នន័យអ៊ីនហ្វ្រាក្រហមមិនអាចត្រូវបានទទួល, ដូច្នេះអ្នកគួរជៀសវាងការបង្ហាញពណ៌អំពូលខ្សែ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readIRReceived_showGradientColor": "ការបង្ហាញពណ៌ជម្រាលនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យទិន្នន័យអ៊ីនហ្វ្រាក្រហមមិនអាចត្រូវបានទទួល, ដូច្នេះអ្នកគួរជៀសវាងការបង្ហាញពណ៌ជម្រាលនៃអំពូលខ្សែ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readIRReceived_shiftPixelsToShow": "ការផ្លាស់ទីភីកសែលអំពូលខ្សែ RGB អាចបណ្តាលឱ្យទិន្នន័យអ៊ីនហ្វ្រាក្រហមមិនអាចត្រូវបានទទួល, ដូច្នេះអ្នកគួរជៀសវាងការផ្លាស់ទីភីកសែលអំពូលខ្សែ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readIRReceived_rotatePixelsToShow": "អំពូលខ្សែ RGB អាចផ្លាស់ទីភីកសែលនៅក្នុងរង្វិលជុំដែលអាចបណ្តាលឱ្យមិនទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងចលនាភីកសែលជាញឹកញាប់នៅក្នុងអំពូលខ្សែ RGB ។",
    "gui.blocklyText.prompt.microbit.readIRReceived_showHistogram": "ការបង្ហាញជួរឈរនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យទិន្នន័យអ៊ីនហ្វ្រាក្រហមមិនអាចត្រូវបានទទួល, ដូច្នេះអ្នកគួរជៀសវាងការបង្ហាញជួរឈរអំពូលខ្សែ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeControl": "ការទទួលអ៊ីនហ្វ្រាក្រហមអាចបណ្តាលឱ្យដំណើរការដំបូងនៃ LED បរាជ័យ",
    "gui.blocklyText.prompt.microbit.readIRReceived_ledLatticeShowPic": "ការទទួលអ៊ីនហ្វ្រាក្រហមអាចបណ្តាលឱ្យមានកំហុសក្នុងការបង្ហាញ LED",
    "gui.blocklyText.prompt.microbit.readIRReceived_printStr": "ការបញ្ជូនតាមច្រកសេរ៊ីប្រើកម្មវិធីអាចបណ្តាលឱ្យទិន្នន័យអ៊ីនហ្វ្រាក្រហមមិនអាចត្រូវបានទទួល, ដូច្នេះអ្នកគួរជៀសវាងការបញ្ជូនតាមច្រកសេរ៊ីប្រើកម្មវិធីញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.ws2812NumberOfLightsBright_readSerialportData": "បន្ទារ RGB អាចបណ្តាលឱ្យការអានតាមច្រកសេរ៊ីប្រើកម្មវិធីបរាជ័យ, ដូច្នេះអ្នកគួរកាត់បន្ថយការប្រើប្រាស់បន្ទារ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.microbit.ledLatticeControl_readSerialportData": "ទិន្នន័យដែលទទួលតាមច្រកសេរ៊ីប្រើកម្មវិធីអាចបណ្តាលឱ្យមានកំហុសក្នុងការបង្ហាញម៉ាទ្រីសចំណុច LED",
    "gui.blocklyText.prompt.microbit.printStr_readSerialportData": "ការបញ្ជូនតាមច្រកសេរ៊ីប្រើកម្មវិធីអាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យតាមច្រកសេរ៊ីប្រើកម្មវិធី។ អ្នកគួរជៀសវាងការបញ្ជូនទិន្នន័យ និងទទួលទិន្នន័យក្នុងពេលតែមួយ។",
    "gui.blocklyText.prompt.mega2560.readIRReceived_initSerialMp3": "ដំណើរការពីដំបូងនៃច្រកសេរ៊ី MP3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, វាត្រូវបានណែនាំឱ្យជៀសវាងការប្រតិបត្តិដំណាលគ្នា",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3": "ម៉ូតចាក់ចំរៀងនៃច្រកសេរ៊ី MP3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការដាក់ម៉ូតចាក់ចំរៀងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.mega2560.readIRReceived_controlSerialMp3Play": "សេរ៊ី MP3 player អាចបណ្តាលឱ្យមានកំហុសទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការដូរបទចំរៀងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.mega2560.readIRReceived_volumeSerialMp3Play": "ការដាក់កម្រិតសម្លេងនៃច្រកសេរ៊ី MP3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការកែតម្រូវកម្រិតសម្លេងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeControl": "ដំណើរការពីដំបូងនៃ max7219 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.mega2560.readDustSensor_ledLatticeShowPic": "ការបង្ហាញរូបភាព Max7219 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការបង្ហាញរូបភាពញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.mega2560.initSerialMp3_readdtemperatur": "ម៉ូឌុល MP3 នៃច្រកសេរ៊ីអាចបណ្តាលឱ្យជើងរំខានមិនអាចត្រូវបានទ្រីគ័រ។ អ្នកគួរជៀសវាងការប្រើប្រាស់ម៉ូឌុល MP3 នៃច្រកសេរ៊ីញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.mega2560.ledLatticeControl_readdtemperatur": "ការបង្ហាញម៉ូឌុល max7219 នៃច្រកសេរ៊ីអាចបណ្តាលឱ្យជើងរំខានមិនអាចត្រូវបានទ្រីគ័រ។ អ្នកគួរជៀសវាងការប្រើប្រាស់ការបង្ហាញ max7219 ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readMoistureTemperature": "ការអាន DHT អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារអ៊ីនហ្វ្រាក្រហម។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន DHT។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readdtemperatur": "ការអាន Ds18b20 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារ soft external, ដូច្នេះអ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_readDustSensor": "ការអាន PM2.5 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីប្រភពខាងក្រៅសូវែហ្វ។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន PM2.5។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initMp3": "ការអានដំបូងនៃ mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3Volume": "ការដាក់កម្រិតសម្លេងនៃ mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម។ អ្នកគួរជៀសវាងការកែតម្រូវកម្រិតសម្លេងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlayMode": "ការកំណត់ម៉ូត mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម។ អ្នកគួរជៀសវាងការកំណត់ម៉ូតញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setMP3PlaySong": "mp3 player អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការប្តូរបទចម្រៀងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initSerialMp3": "ការដាក់ម៉ូតនៃច្រកសេរ៊ី mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការកំណត់ម៉ូតញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_controlSerialMp3Play": "សេរ៊ី mp3 player អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការប្តូរបទចម្រៀងញឹកញាប់ពេក",
    "gui.blocklyText.prompt.leonardo.readIRReceived_volumeSerialMp3Play": "ការដាក់កម្រិតសម្លេងនៃច្រកសេរ៊ី mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម។ អ្នកគួរជៀសវាងការបញ្ជូនអ៊ីនហ្វ្រាក្រហមញឹកញាប់ពេក",
    "gui.blocklyText.prompt.leonardo.readIRReceived_emissionPinAndBit": "ការបំភាយអ៊ីនហ្វ្រាក្រហមអាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការបញ្ជូនអ៊ីនហ្វ្រាក្រហមញឹកញាប់ពេក",
    "gui.blocklyText.prompt.leonardo.readIRReceived_numberOfLightsBright": "ដំណើរការដំបូងនៃបន្ទារ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម។ អ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា",
    "gui.blocklyText.prompt.leonardo.readIRReceived_setBrightness": "ការដាក់កម្រិតពន្លឺនៃបន្ទារ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម។ អ្នកគួរជៀសវាងការដាក់កម្រិតពន្លឺញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_allLedsShowColorFromWith": "ការបង្ហាញបន្ទារ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម។ អ្នកគួរជៀសវាងការបង្ហាញបន្ទារ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showGradientColor": "\r\nការបំលែងពណ៌ជម្រាលនៃការបង្ហាញបន្ទារអំពូល RGB អាចនាំឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម។ អ្នកគួរជៀសវាងបង្ហាញការប្រែពណ៌ជម្រាលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_shiftPixelsToShow": "ការផ្លាស់ទីភីកសែលជាមួយអំពូលខ្សែ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម។ អ្នកគួរជៀសវាងការផ្លាស់ទីភីកសែលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_rotatePixelsToShow": "ចលនារាងជារង្វង់នៃភីកសែលជាមួយនឹងអំពូលខ្សែ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពីអ៊ីនហ្វ្រាក្រហម។ អ្នកគួរជៀសវាងប្រតិបត្តិការចលនារង្វង់នៃភីកសែលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_showHistogram": "អំពូលខ្សែ RGB ជាមួយនឹងការបង្ហាញជួរឈរអាចបណ្តាលឱ្យមានកំហុសនៃការទទួលសារពីអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការបង្ហាញជួរឈរញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeControl": "ការទទួលសារពីអ៊ីនហ្វ្រាក្រហមអាចបណ្តាលឱ្យដំណើរការពីដំបូងនៃ max7219 បរាជ័យ",
    "gui.blocklyText.prompt.leonardo.readIRReceived_ledLatticeShowPic": "ការទទួលសារពីអ៊ីនហ្វ្រាក្រហមអាចបណ្តាលឱ្យមានកំហុសក្នុងការបង្ហាញ max7219 ",
    "gui.blocklyText.prompt.leonardo.readIRReceived_printStr": "ការព្រីនតាមសេរ៊ីប្រើកម្មវិធីអាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការព្រីនតាមសេរ៊ីប្រើកម្មវិធីញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_initialParameter": "ដំណើរការពីដំបូងនៃច្រកសេរ៊ីប្រើកម្មវិធី obloq MQTT អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPut": "ការដាក់សំណើនៃសេរ៊ីប្រើកម្មវិធី  obloq អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការស្នើសុំទិន្នន័យញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpInitialParameter": "ដំណើរការពីដំបូងនៃច្រកសេរ៊ីប្រើកម្មវិធី  obloq HTTP អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpGet": "ការទទួលសំណើនៃច្រកសេរ៊ីប្រើកម្មវិធីអាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការស្នើសុំទិន្នន័យញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_httpPost": "ការសំណើសំណូមពរនៃសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យអ៊ីនហ្វ្រាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការស្នើសុំទិន្នន័យញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readIRReceived_clear": "ការលុបផ្ទាំងបង្ហាញនៃបន្ទារអំពូល RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារពី soft infrared។ អ្នកគួរជៀសវាងការលុបផ្ទាំងបង្ហាញញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readdtemperatur": "ការអាន ds18b20  អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារច្រកសេរ៊ីប្រើកម្មវិធី , ដូច្នេះអ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20",
    "gui.blocklyText.prompt.leonardo.readSerialportData_readDustSensor": "ការអាន PM2.5 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារដោយច្រកសេរ៊ីប្រើកម្មវិធី ។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអានPM2.5។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initMp3": "ការអានក្នុងដំណើរការដំបូងនៃ mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមសេរ៊ីប្រើកម្មវិធី, ហើយអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3Volume": "ការដាក់កម្រិតសម្លេងនៃ mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី ។ អ្នកគួរជៀសវាងការកែតម្រូវកម្រិតសម្លេងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlayMode": "ការដាក់ម៉ូតនៃ mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី។ អ្នកគួរជៀសវាងការដាក់ម៉ូតញឹកញាប់ពេក",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setMP3PlaySong": "ការចាក់សារថ្មី mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី ។ អ្នកគួរជៀសវាងការប្តូរតន្ត្រីញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initSerialMp3": "ការអានដំណើរការពីដំបូងនៃច្រកសេរ៊ី mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារច្រកសេរ៊ីប្រើកម្មវិធី, អ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3": "ការដាក់ម៉ូតនៃច្រកសេរ៊ី mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារដោយច្រកសេរ៊ីប្រើកម្មវិធី ។ អ្នកគួរជៀសវាងការដាក់ម៉ូតញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_controlSerialMp3Play": "ការចាក់ចម្រៀងនៅលើច្រកសេរ៊ី mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារលើច្រកសេរ៊ីប្រើកម្មវិធី ។ អ្នកគួរជៀសវាងការ​ប្តូរបទចម្រៀងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_volumeSerialMp3Play": "ការដាក់កម្រិតសម្លេងនៃ serial port mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី ។ អ្នកគួរជៀសវាងការ​កែតម្រូវកម្រិតសម្លេងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_emissionPinAndBit": "ការសាយភាយអ៊ីនហ្វ្រាក្រហមអាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី។ អ្នកគួរជៀសវាងការ​ផ្ញើសារអ៊ីនហ្វ្រាក្រហមញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_numberOfLightsBright": "ដំណើរការពីដំបូងនៃបន្ទារ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី ។ អ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_setBrightness": "ការដាក់កម្រិតពន្លឺនៃបន្ទារ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី។ អ្នកគួរជៀសវាងការដាក់កម្រិតពន្លឺញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_clear": "ការលុបផ្ទាំងបង្ហាញនៃបន្ទារអំពូល RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារដោយច្រកសេរ៊ីប្រើកម្មវិធី។ អ្នកគួរជៀសវាងការលុបផ្ទាំងបង្ហាញញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_allLedsShowColorFromWith": "ការបង្ហាញបន្ទារ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី។ អ្នកគួរជៀសវាងការបង្ហាញបន្ទារ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showGradientColor": "អំពូល RGB ដែលមានពណ៌ជម្រាលអាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី។ អ្នកគួរជៀសវាងការបង្ហាញពណ៌ជម្រាលញឹកញាប់ពេក។\r\n",
    "gui.blocklyText.prompt.leonardo.readSerialportData_shiftPixelsToShow": "ការផ្លាស់ទីភីកសែលជាមួយអំពូលខ្សែ RGB អាចបណ្តាលឱ្យច្រកសេរ៊ីប្រើកម្មវិធីទទួលសារមិនត្រឹមត្រូវ។ អ្នកគួរជៀសវាងការផ្លាស់ទីភីកសែលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_rotatePixelsToShow": "ចលនារាងជារង្វង់នៃភីកសែលរបស់អំពូលខ្សែ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី, ដូច្នេះអ្នកគួរជៀសវាងប្រតិបត្តិការចលនារាងជារង្វង់នៃភីកសែលញឹកញាប់ពេក",
    "gui.blocklyText.prompt.leonardo.readSerialportData_showHistogram": "អំពូលខ្សែ RGB ដែលបង្ហាញជួរឈរអាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី, ដូច្នេះអ្នកគួរជៀសវាងការបង្ហាញជួរឈរញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeControl": "ការទទួលសារតាមសេរ៊ីប្រើកម្មវិធី អាចបណ្តាលឱ្យដំណើរការពីដំបូងនៃ max7219 បរាជ័យ",
    "gui.blocklyText.prompt.leonardo.readSerialportData_ledLatticeShowPic": "ការទទួលសារតាមសេរ៊ីប្រើកម្មវិធីអាចបណ្តាលឱ្យមានកំហុសក្នុងការបង្ហាញ max7219 បរាជ័យ",
    "gui.blocklyText.prompt.leonardo.readSerialportData_printStr": "ការព្រីនតាមសេរ៊ីប្រើកម្មវិធីអាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី។ អ្នកគួរជៀសវាងការព្រីនសេរ៊ីប្រើកម្មវិធីញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readMoistureTemperature": "ការអាន DHT អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន DHT។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readdtemperatur": "ការអាន ds18b20 អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងទ្រីគ័រ។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_readDustSensor": "ការអាន PM2.5 អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន PM2.5។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initMp3": "ការអានដំណើរការពីដំបូង mp3 អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ ហើយអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3Volume": "ការដាក់កម្រិតសម្លេង mp3 អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ។ អ្នកគួរជៀសវាងការកែតម្រូវកម្រិតសម្លេងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlayMode": "ការដាក់ម៉ូត mp3 អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ។ អ្នកគួរជៀសវាងការដាក់ម៉ូតញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setMP3PlaySong": "mp3 player អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ។ អ្នកគួរជៀសវាងការប្តូរបទចម្រៀងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initSerialMp3": "ការអានដំណើរការពីដំបូង mp3 អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ, ដូច្នេះអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3": "ការដាក់ម៉ូតនៃច្រកសេរ៊ី mp3 អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ។ អ្នកគួរជៀសវាងការដាក់ម៉ូតញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_controlSerialMp3Play": "សេរ៊ី mp3 player អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ, ដូច្នេះអ្នកគួរជៀសវាងការប្តូរបទចម្រៀងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_volumeSerialMp3Play": "ការដាក់កម្រិតសម្លេងនៃច្រកសេរ៊ី mp3 អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ។ អ្នកគួរជៀសវាងការកែតម្រូវកម្រិតសម្លេងញឹកញាប់ពេក",
    "gui.blocklyText.prompt.leonardo.interruptExcute_emissionPinAndBit": "ការបំភាយអ៊ីនហ្វ្រាក្រហមអាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ, ដូច្នេះអ្នកគួរជៀសវាងការបញ្ជូនអ៊ីនហ្វ្រាក្រហមញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_numberOfLightsBright": "ដំណើរការដំបូងនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ ហើយអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_setBrightness": "ដំណើរការដំបូងនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ ហើយអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_clear": "ការលុបផ្ទាំងបង្ហាញនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ, ដូច្នេះអ្នកគួរជៀសវាងការលុបផ្ទាំងបង្ហាញញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_allLedsShowColorFromWith": "ការបង្ហាញឆ្នូតពន្លឺនៃ RGB អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ។ អ្នកគួរជៀសវាងការបង្ហាញឆ្នូតពន្លឺ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showGradientColor": "ការបង្ហាញឆ្នូតពន្លឺនៃពណ៌ជម្រាល RGB អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ។ អ្នកគួរជៀសវាងការបង្ហាញពណ៌ជម្រាលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_shiftPixelsToShow": "ការផ្លាស់ទីភីកសែលនៃបន្ទារ RGB អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ ហើយអ្នកគួរជៀសវាងការផ្លាស់ទីភីកសែលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_rotatePixelsToShow": "ការផ្លាស់ទីភីកសែលជារង្វិលជុំនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ, ដូច្នេះអ្នកគួរជៀសវាងការផ្លាស់ទីភីកសែលជារង្វិលជុំញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_showHistogram": "ការបង្ហាញជួរឈរនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ។ អ្នកគួរជៀសវាងការបង្ហាញជួរឈរញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeControl": "ជើងរំខានអាចបណ្តាលឱ្យដំណើរការពីដំបូងនៃ max7219 បរាជ័យ",
    "gui.blocklyText.prompt.leonardo.interruptExcute_ledLatticeShowPic": "ជើងរំខានអាចបណ្តាលឱ្យការបង្ហាញនៃ max7219 មិនត្រឹមត្រូវ",
    "gui.blocklyText.prompt.leonardo.interruptExcute_printStr": "ការព្រីនច្រកសេរ៊ីអាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ, ដូច្នេះអ្នកគួរជៀសវាងការព្រីនច្រកសេរ៊ីញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_initialParameter": "ដំណើរការដំបូងតាមសេរ៊ីប្រើកម្មវិធី obloq MQTT អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ ហើយអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPut": "ការដាក់សំណើតាមសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ ហើយអ្នកគួរជៀសវាងការស្នើទិន្នន័យញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpInitialParameter": "ដំណើរការដំបូងតាមសេរ៊ីប្រើកម្មវិធី obloq HTTP អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ, ដូច្នេះអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpGet": "ការទទួលសំណើតាមសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ, ដូច្នេះអ្នកគួរជៀសវាងការស្នើទិន្នន័យញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.interruptExcute_httpPost": "សំណើសំណូមពរតាមសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យជើងរំខានបរាជ័យក្នុងការទ្រីគ័រ, ដូច្នេះអ្នកគួរជៀសវាងការស្នើទិន្នន័យញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readMoistureTemperature": "ការអាន DHT អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារសេរ៊ីប្រើកម្មវិធី obloq, ដូច្នេះអ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន DHT។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_readdtemperatur": "ការអាន ds18b20 អាចបណ្តាលឱ្យ obloq ទទួលសារមិនត្រឹមត្រូវ, ដូច្នេះអ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន ds18b20។",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readDustSensor": "ការអាន PM2.5 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារ obloq។ អ្នកគួរកាត់បន្ថយចំនួនដងនៃការអាន PM2.5។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initMp3": "ការអានដំបូង mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមសេរ៊ីប្រើកម្មវិធី  obloq, ដូច្នេះអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3Volume": "ការដាក់កម្រិតសម្លេង mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមសេរ៊ីប្រើកម្មវិធី  obloq។ អ្នកគួរជៀសវាងការកែតម្រូវកម្រិតសម្លេងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlayMode": "ការដាក់ម៉ូត mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមសេរ៊ីប្រើកម្មវិធី  obloq, ដូច្នេះអ្នកគួរជៀសវាងការដាក់ម៉ូតញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setMP3PlaySong": "ការចាក់សារថ្មី mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមសេរ៊ីប្រើកម្មវិធី  obloq, ដូច្នេះអ្នកគួរជៀសវាងការប្តូរបទចម្រៀងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_initSerialMp3": "ការអានដំណើរការពីដំបូង mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី obloq, ដូច្នេះអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3": "ការដាក់ម៉ូតច្រកសេរ៊ី mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី  obloq, ដូច្នេះអ្នកគួរជៀសវាងការដាក់ម៉ូតញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_controlSerialMp3Play": "ច្រកសេរ៊ី mp3 player អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី obloq, ដូច្នេះអ្នកគួរជៀសវាងការប្តូរបទចម្រៀងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_volumeSerialMp3Play": "ការដាក់កម្រិតសម្លេងនៃច្រកសេរ៊ី mp3 អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី  obloq ដូច្នេះអ្នកគួរជៀសវាងការកែតម្រូវកម្រិតសម្លេងញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_emissionPinAndBit": "ការបំភាយអ៊ីនហ្រ្វាក្រហមអាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសាតាមសេរ៊ីប្រើកម្មវិធី obloq, ដូច្នេះអ្នកគួរជៀសវាងការបញ្ជូនអ៊ីនហ្វ្រាក្រហមញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardoreadMsgFromCloudPlatform_readIRReceived": "ការផ្ញើសារនៃសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលអ៊ីនហ្រ្វាក្រហម, ដូច្នេះអ្នកគួរជៀសវាងការផ្ញើសារ obloq ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_numberOfLightsBright": "ដំណើរពីដំបូងនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមសេរ៊ីប្រើកម្មវិធី obloq, ដូច្នេះអ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_setBrightness": "ការដាក់កម្រិតពន្លឺនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារ obloq។ អ្នកគួរជៀសវាងការដាក់កម្រិតពន្លឺញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_clear": "ការលុបផ្ទាំងបង្ហាញនៃបន្ទារអំពូល RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារ obloq។ អ្នកគួរជៀសវាងការលុបផ្ទាំងបង្ហាញញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_allLedsShowColorFromWith": "ការបង្ហាញឆ្នូតពន្លឺ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសាររបស់ obloq។ អ្នកគួរជៀសវាងការបង្ហាញឆ្នូតពន្លឺ RGB ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showGradientColor": "ឆ្នូតពន្លឺ RGB បង្ហាញពណ៌ជម្រាលអាចបណ្តាលឱ្យមានកំហុសសារតាមច្រកសេរ៊ីប្រើកម្មវិធី obloq។ អ្នកគួរជៀសវាងការបង្ហាញពណ៌ជម្រាលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_shiftPixelsToShow": "ការផ្លាសទីភីកសែលនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសាររបស់ obloq។ អ្នកគួរជៀសវាងការផ្លាស់ទីភីកសែលញឹកញាប់ពេក",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_rotatePixelsToShow": "ចលនារាងជារង្វង់នៃភីកសែលរបស់អំពូលខ្សែ RGB អាចនាំឱ្យមានកំហុសក្នុងការទទួលសារតាមច្រកសេរ៊ីប្រើកម្មវិធី obloq។ អ្នកគួរជៀសវាងការផ្លាស់ប្តូរភីកសែលញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatform_showHistogram": "ការបង្ហាញជួរឈរនៃអំពូលខ្សែ RGB អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលសារតាមសេរ៊ីប្រើកម្មវិធី obloq។ អ្នកគួរជៀសវាងការបង្ហាញជួរឈរញឹកញាប់ពេក។ ",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeControl": "ការទទួលសារតាមសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យដំណើរការដំបូងរបស់ max7219 បរាជ័យ",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_ledLatticeShowPic": "ការទទួលសារតាមសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យ max7219 មានបញ្ហាក្នុងការបង្ហាញ",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_printStr": "ការព្រីនតាមសេរ៊ីប្រើកម្មវិធីអាចបណ្តាលឱ្យ obloq ទទួលសារមិនត្រឹមត្រូវ។ អ្នកគួរជៀសវាងការព្រីនតាមសេរ៊ីប្រើកម្មវិធីញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_readSerialportData": "ការបញ្ជូនទិន្នន័យតាម​ច្រកសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យមានកំហុសក្នុងការទទួលទិន្នន័យតាមច្រកសេរ៊ីប្រើកម្មវិធី។ អ្នកគួរជៀសវាងការបញ្ជូនទិន្នន័យតាម obloq ញឹកញាប់ពេក។ ",
    "gui.blocklyText.prompt.leonardo.readMsgFromCloudPlatformr_interruptExcute": "ការបញ្ជូនទិន្នន័យតាមច្រកសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យជើងរំខានមិនអាចត្រូវបានទ្រីគ័រ។ អ្នកគួរជៀសវាងការបញ្ជូនទិន្នន័យតាម obloq ញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_initialParameter": "ការចាប់ផ្តើមតាមច្រកសេរ៊ីប្រើកម្មវិធី obloq MQTT អាចបណ្តាលឱ្យច្រកសេរ៊ីមានកំហុសក្នុងការទទួលទិន្នន័យ។ អ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPut": "ការដាក់ស្នើតាមសេរ៊ីប្រើកម្មវិធី obloq អាចបណ្តាលឱ្យច្រកសេរ៊ីប្រើកម្មវិធីមានកំហុសក្នុងការទទួលទិន្នន័យ។ អ្នកគួរជៀសវាងការស្នើសុំទិន្នន័យញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpInitialParameter": "ការចាប់ផ្តើមតាមច្រកសេរ៊ីប្រើកម្មវិធី obloq HTTP អាចបង្កឱ្យច្រកសេរ៊ីប្រើកម្មវិធីមានកំហុសក្នុងការទទួលទិន្នន័យ។ អ្នកគួរជៀសវាងប្រតិបត្តិការដំណាលគ្នា។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpGet": "សេរ៊ីប្រើកម្មវិធី  obloq ទទួលសំណើអាចបណ្តាលឱ្យច្រកសេរ៊ីប្រើកម្មវិធីមានកំហុសក្នុងការទទួលទិន្នន័យ។ អ្នកគួរជៀសវាងការស្នើសុំទិន្នន័យញឹកញាប់ពេក។",
    "gui.blocklyText.prompt.leonardo.readSerialportData_httpPost": "ច្រកសេរ៊ីប្រើកម្មវិធី obloq ដាក់សំណើអាចបណ្តាលឱ្យច្រកសេរ៊ីប្រើកម្មវិធីមានកំហុសក្នុងការទទួលទិន្នន័យ។ អ្នកគួរជៀសវាងការស្នើសុំទិន្នន័យញឹកញាប់ពេក។",
    "gui.blocklyText.compatible.shapedBlock": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ ប្លុកសម្រាប់បង្កើតកម្មវិធីព្រឹត្តិការណ៍មានអថេររបស់វាត្រូវបានជំនួសដោយប្លុកសម្រាប់បង្កើតកម្មវិធីព្រឹត្តិការណ៍គ្មានអថេរ។",
    "gui.blocklyText.compatible.shapedBlock.variable": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ ផ្លាស់ប្តូរពីប្លុកបង្កើតកម្មវិធីឯករាជ្យទៅជាប្លុកបង្កើតកម្មវិធីដែលប្រែប្រួលនៅក្នុងកម្មវិធីព្រឹត្តិការណ៍",
    "gui.blocklyText.compatible.booleanToRound": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ ផ្លាស់ប្តូរពីប្លុកបង្កើតកម្មវិធីជាលក្ខខណ្ឌទៅជាប្លុកបង្កើតកម្មវិធីជាលេខ។",
    "gui.blocklyText.compatible.addSoftserialHardSerial": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ បន្ថែមប៊ូតុង drop-down ដែលសម្រាប់ជម្រើសច្រកសេរ៊ីប្រើកម្មវិធី  និងច្រកសេរ៊ីឧបករណ៍ ជាមួយជម្រើសប៊ូតុង drop-down ក្នុងប្លុកបង្កើតកម្មវិធី ។",
    "gui.blocklyText.compatible.mp3.deletePin": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ លុបប៊ូតុង drop-down ដែលសម្រាប់ជ្រើសរើសជើងក្នុងប្លុកបង្កើតកម្មវិធី។",
    "gui.blocklyText.compatible.sen0206.addDropdown": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ បន្ថែមប៊ូតុង drop-down ក្នុងប្លុកបង្កើតកម្មវិធី។",
    "gui.blocklyText.compatible.mega2560.dfr0231.addPinDrodown": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ បង្កើនប៊ូតុង drop-down ដែលសម្រាប់ជ្រើសរើសជើងក្នុងប្លុកបង្កើតកម្មវិធី។",
    "gui.blocklyText.compatible.esp32.dfr0231.alterDropdowValue": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ ផ្លាស់ប្ដូរប៊ូតុង drop-down ដែលសម្រាប់ជ្រើសរើសច្រកស៊េរីក្នុងប្លុកបង្កើតកម្មវិធី។ ",
    "gui.blocklyText.compatible.obloq.whenReceivedData": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ ផ្លាស់ប្តូរពីប្លុកបង្កើតកម្មវិធីព្រឹត្តិការណ៍ ទៅជាប្លុកបង្កើតកម្មវិធីមានលក្ខខណ្ឌ។",
    "gui.blocklyText.compatible.maqueen.delete.numberOfLightsBright": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ លុបប្លុក",
    "gui.blocklyText.compatible.alterSquareToRoundDropdown": "ចំណុចដែលត្រូវបានផ្លាស់ប្តូរក្នុងកំណែថ្មី៖ កែប្រែប្រអប់ drop-down ពីការ៉េទៅជារង្វង់",
    "gui.blocklyText.pictureai.initToken": "ដំណើរការដំបូងរបស់ AI នៃរូបភាព [SERVER]",
    "gui.blocklyText.pictureai.fileChoose": "ទទួលរូបភាពពីឯកសារមូលដ្ឋាន [IMAGE]",
    "gui.blocklyText.pictureai.getVideoImage": "ថតរូបភាពពីអេក្រង់កាមេរ៉ាវីដេអូ",
    "gui.blocklyText.pictureai.webPicture": "ទទួលរូបភាពពីគេហទំព័រ [TEXT]",
    "gui.blocklyText.pictureai.exportImage": "[SWITCH] រក្សាទុករូបថតអេក្រង់ជាមូលដ្ឋាន [TEXT]",
    "gui.blocklyText.pictureai.face": "កំណត់អត្តសញ្ញាណរូបភាព [TEXT] ដែលមានមុខមនុស្ស",
    "gui.blocklyText.pictureai.word": "កំណត់អត្តសញ្ញាណរូបភាព [TEXT] ដែលមាន [SELECT]",
    "gui.blocklyText.pictureai.picture": "កំណត់អត្តសញ្ញាណរូបភាព [TEXT] ដែលមាន [SELECT]",
    "gui.blocklyText.pictureai.gesture": "កំណត់អត្តសញ្ញាណរូបភាព [TEXT] ដែលមានកាយវិការ",
    "gui.blocklyText.pictureai.TOPIC_W_A": "ពាក្យ",
    "gui.blocklyText.pictureai.TOPIC_W_B": "លេខ",
    "gui.blocklyText.pictureai.TOPIC_W_C": "លេខរថយន្ត",
    "gui.blocklyText.pictureai.TOPIC_W_D": "ការសរសេរដោយដៃ",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ankle": "កជើងខាងឆ្វេង",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_ear": "ត្រចៀកខាងឆ្វេង",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_elbow": "កែងដៃខាងឆ្វេង",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_eye": "ភ្នែកខាងឆ្វេង",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_hip": "ត្រគាកខាងឆ្វេង",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_knee": "ជង្គង់ខាងឆ្វេង",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_mouth_corner": "ជ្រុងមាត់ខាងឆ្វេង",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_shoulder": "ស្មាខាងឆ្វេង",
    "gui.blocklyText.pictureai.TOPIC_BODY_left_wrist": "កដៃខាងឆ្វេង",
    "gui.blocklyText.pictureai.TOPIC_BODY_neck": "ក",
    "gui.blocklyText.pictureai.TOPIC_BODY_nose": "ច្រមុះ",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ankle": "កជើងខាងស្តាំ",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_ear": "ត្រចៀកខាងស្តាំ",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_elbow": "កែងដៃខាងស្ដាំ",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_eye": "ភ្នែកខាងស្ដាំ",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_hip": "ត្រគាកខាងស្ដាំ",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_knee": "ជង្គង់ខាងស្ដាំ",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_mouth_corner": "ជ្រុងមាត់ខាងស្ដាំ",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_shoulder": "ស្មាខាងស្ដាំ",
    "gui.blocklyText.pictureai.TOPIC_BODY_right_wrist": "កដៃខាងស្ដាំ",
    "gui.blocklyText.pictureai.TOPIC_BODY_top_head": "ក្បាលខាងលើ",
    "gui.blocklyText.pictureai.initUserToken": "ប្តូរទៅគណនីដាច់ដោយឡែកមួយ [MODAL]",
    "gui.blocklyText.pictureai.bodyPoint": "កំណត់អត្តសញ្ញាណរូបភាព [TEXT] ដែលមានចំណុចរាងកាយ",
    "gui.blocklyText.pictureai.faceContrast": "ប្រៀបធៀបមុខមាត់ក្នុងរូបភាព [TEXT] និងរូបភាព [TEXT2]",
    "gui.blocklyText.pictureai.faceMerge": "ការលាយបញ្ចូលគ្នានៃមុខមាត់នៅក្នុងរូបភាព [TEXT] និងរូបភាព [TEXT2]",
    "gui.blocklyText.pictureai.TOPIC_P_A0": "រាងកាយរូបភាព",
    "gui.blocklyText.pictureai.TOPIC_P_A1": "វត្ថុទូទៅនិងឈុតឆាក",
    "gui.blocklyText.pictureai.TOPIC_P_A": "រុក្ខជាតិ",
    "gui.blocklyText.pictureai.TOPIC_P_B": "សត្វ",
    "gui.blocklyText.pictureai.TOPIC_P_C": "ផ្កា",
    "gui.blocklyText.pictureai.TOPIC_P_D": "បន្លែ",
    "gui.blocklyText.pictureai.bodyPointSuccess": "ការទទួលស្គាល់ដោយជោគជ័យនូវចំនុចសំខាន់ៗនៃរាងកាយមនុស្ស?",
    "gui.blocklyText.pictureai.bodyPointInfo": "ការទទួលបានចំណុចសំខាន់នៃរាងកាយរបស់មនុស្ស [BODY] កូអរដោនេ [XY] ",
    "gui.blocklyText.pictureai.noPower": "សូមពិនិត្យមើលថាតើគណនីអាចដំណើរការបានដែរឬទេ",
    "gui.blocklyText.pictureai.pictureError": "រូបភាពមិនមានទម្រង់ត្រឹមត្រូវទេ",
    "gui.blocklyText.pictureai.noPicture": "សូមជ្រើសរើសរូបភាពជាមុនសិន",
    "gui.blocklyText.pictureai.noVideo": "សូមប្រាកដថាកាមេរ៉ាត្រូវបានភ្ជាប់យ៉ាងត្រឹមត្រូវ ហើយមិនមានកម្មវិធីផ្សេងទៀតត្រូវកំពុងប្រើប្រាស់កាមេរ៉ាទេ ឬត្រូវចាប់ផ្តើមបើកកម្មវិធីឡើងវិញ",
    "gui.blocklyText.pictureai.videoContainer": "ប្រើ [CON] ដើម្បីបង្ហាញអេក្រង់កាមេរ៉ា",
    "gui.blocklyText.pictureai.TOPIC_stage": "ឆាក",
    "gui.blocklyText.pictureai.TOPIC_popup": "ផុស​ឡើង",
    "gui.blocklyText.compatible.deleteIoboxGetLightness": "ការផ្លាស់ប្តូរនៅក្នុងកំណែថ្មី៖ ប្លុកកម្មវិធីមួយចំនួនត្រូវបានលុបចោល។",
    "gui.blocklyText.pictureai.gestureList": "កាយវិការ [TEXT]",
    "gui.blocklyText.pictureai.networkError": "កំហុសបណ្តាញ, សូមពិនិត្យការភ្ជាប់បណ្តាញ",
    "gui.blocklyText.pictureai.pictureaiFaceSuccess": "ការទទួលស្គាល់មុខបានជោគជ័យ?",
    "gui.blocklyText.pictureai.pictureaiFaceInfo": "លទ្ធផលនៃការទទួលស្គាល់មុខ [TYPE]",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_num": "ចំនួនមុខ",
    "gui.blocklyText.pictureai.TOPIC_FACE_age": "អាយុ",
    "gui.blocklyText.pictureai.TOPIC_FACE_beauty": "ភាពស្រស់ស្អាត",
    "gui.blocklyText.pictureai.TOPIC_FACE_gender": "ភេទ",
    "gui.blocklyText.pictureai.TOPIC_FACE_glasses": "វ៉ែនតា",
    "gui.blocklyText.pictureai.TOPIC_FACE_type": "ប្រភេទ",
    "gui.blocklyText.pictureai.TOPIC_FACE_expression": "ការបញ្ចេញមតិ",
    "gui.blocklyText.pictureai.TOPIC_FACE_face_probability": "ប្រូបាប៊ីលីតេនៃមុខមាត់",
    "gui.blocklyText.pictureai.TOPIC_FACE_left": "ខាងឆ្វេង",
    "gui.blocklyText.pictureai.TOPIC_FACE_top": "ខាងលើ",
    "gui.blocklyText.pictureai.TOPIC_FACE_width": "ទទឹង",
    "gui.blocklyText.pictureai.TOPIC_FACE_height": "កម្ពស់",
    "gui.blocklyText.pictureai.TOPIC_FACE_landmark": "សញ្ញាសំគាល់",
    "gui.blocklyText.pictureai.TOPIC_FACE_occlusion": "occlusion",
    "gui.blocklyText.pictureai.TOPIC_FACE_blur": "មិនច្បាស់",
    "gui.blocklyText.pictureai.TOPIC_FACE_completeness": "ភាពពេញលេញ",
    "gui.blocklyText.pictureai.TOPIC_FACE_human": "មនុស្ស",
    "gui.blocklyText.pictureai.TOPIC_FACE_cartoon": "តុក្កតា",
    "gui.blocklyText.maqueenPlus.PidSwitch": "PID [SWITCH]",
    "gui.blocklyText.maqueenPlus.on": "បើក",
    "gui.blocklyText.maqueenPlus.off": "បិទ",
    "gui.blocklyText.maqueenPlus.Forward": "ទៅមុខ",
    "gui.blocklyText.maqueenPlus.Backward": "ថយក្រោយ",
    "gui.blocklyText.maqueenPlus.left": "ឆ្វេង",
    "gui.blocklyText.maqueenPlus.right": "ស្ដាំ",
    "gui.blocklyText.maqueenPlus.all": "ទាំងអស់",
    "gui.blocklyText.maqueenPlus.setMotorSpeedMove": "ដាក់ម៉ូទ័រ [DIR] ផ្លាស់ទីដោយ [SPEED] ល្បឿន [SPEEDDIR]",
    "gui.blocklyText.maqueenPlus.setMotorStop": "ដាក់ម៉ូទ័រ [DIR] ឈប់",
    "gui.blocklyText.maqueenPlus.setMotorCompensation": "ម៉ូទ័រ [DIR] សំណងល្បឿន [SPEED]",
    "gui.blocklyText.maqueenPlus.readMoterSpeed": "អាន [DIR] ល្បឿនម៉ូទ័រ",
    "gui.blocklyText.maqueenPlus.readMoterDir": "អាន [DIR] ទិសដៅម៉ូទ័រ (០៖ឈប់ ១៖ទៅមុខ ២៖ថយក្រោយ)",
    "gui.blocklyText.maqueenPlus.setServo": "ម៉ូទ័រស៊ែវូ [INDEX] មុំ [ANGLE]",
    "gui.blocklyText.maqueenPlus.setRgb": "ដាក់ [DIR] RGB បង្ហាញ [COLOR]",
    "gui.blocklyText.maqueenPlus.readSensationSensor": "អានឧបករណ៍ចាប់សញ្ញាអារម្មណ៍ [INDEX]",
    "gui.blocklyText.maqueenPlus.readSensationSensorYscaleValue": "អានឧបករណ៍ចាប់សញ្ញាអារម្មណ៍ [INDEX] តម្លៃ yscale",
    "gui.blocklyText.maqueenPlus.getVersion": "ទទួលបានកំណែ",
    "gui.blocklyText.maqueenPlus.readUltrasonic": "អានឧបករណ៍ចាប់សម្លេងអ៊ុលត្រា ទ្រីគ័រ [TRIG] អេកូ [ECHO] ឯកតា [UNIT]",
    "gui.blocklyText.maqueenPlus.eventInfrared": "នៅពេលទទួល [InfraredData]",
    "gui.blocklyText.maqueenPlus.eventInfraredData": "ទិន្នន័យអាំងហ្វ្រាក្រហម",
    "gui.blocklyText.maqueenPlus.cm": "សង់​ទី​ម៉ែ​ត",
    "gui.blocklyText.maqueenPlus.inch": "អ៊ីញ",
    "paint.paintEditor.hue": "ពណ៌",
    "paint.paintEditor.saturation": "កម្រិតដិត",
    "paint.paintEditor.brightness": "កម្រិតពន្លឺ",
    "gui.comingSoon.message1": "កុំបារម្ភ, យើងកំពុងធ្វើវា {emoji}",
    "gui.comingSoon.message2": "មកដល់ឆាប់ៗនេះ ...",
    "gui.comingSoon.message3": "យើងកំពុងធ្វើវា {emoji}",
    "paint.paintEditor.fill": "បំពេញ",
    "paint.paintEditor.costume": "រូបរាង",
    "paint.paintEditor.group": "ដាក់ជាក្រុម",
    "paint.paintEditor.ungroup": "បំបែកក្រុម",
    "paint.paintEditor.undo": "មិនធ្វើវិញ",
    "paint.paintEditor.redo": "ធ្វើឡើងវិញ",
    "paint.paintEditor.forward": "ទៅមុខ",
    "paint.paintEditor.backward": "ថយក្រោយ",
    "paint.paintEditor.front": "មុខគេ",
    "paint.paintEditor.back": "ក្រោយគេ",
    "paint.paintEditor.more": "មានទៀត",
    "paint.modeTools.brushSize": "ទំហំ",
    "paint.modeTools.eraserSize": "ទំហំជ័រលុប",
    "paint.modeTools.copy": "ថតចម្លង",
    "paint.modeTools.paste": "បិទភ្ជាប់",
    "paint.modeTools.delete": "លុប",
    "paint.modeTools.curved": "កោង",
    "paint.modeTools.pointed": "ចង្អុល",
    "paint.modeTools.thickness": "កម្រាស់",
    "paint.modeTools.flipHorizontal": "ត្រឡប់ផ្តេក",
    "paint.modeTools.flipVertical": "ត្រឡប់បញ្ឈរ",
    "paint.modeTools.filled": "បំពេញ",
    "paint.modeTools.outlined": "បន្ទាត់គែម",
    "paint.paintEditor.bitmap": "បំលែងជា Bitmap",
    "paint.paintEditor.vector": "បំលែងជាំវ៉ិចទ័រ",
    "paint.paintEditor.stroke": "បន្ទាត់គែម",
    "paint.brushMode.brush": "ជក់",
    "paint.eraserMode.eraser": "ជ័រលុប",
    "paint.fillMode.fill": "ផ្ទៃ",
    "paint.lineMode.line": "បន្ទាត់",
    "paint.ovalMode.oval": "រង្វង់",
    "paint.rectMode.rect": "ចតុកោណកែង",
    "paint.reshapeMode.reshape": "កំណត់រូបរាងឡើងវិញ",
    "paint.roundedRectMode.roundedRect": "ចតុកោណលុបជ្រុង",
    "paint.selectMode.select": "ជ្រើសរើស",
    "paint.textMode.text": "អត្ថបទ",
    "paint.colorPicker.swap": "ប្តូរកន្លែង",
    "gui.dialog.notConnectDevice": "មិនមានឧបករណ៍ណាមួយកំពុងភ្ជាប់ទំនាក់ទំនងទេ",
    "gui.dialog.prompt": "ចំណាំ",
    "gui.dialog.openPort": "បើកច្រក",
    "gui.dialog.error": "កំហុស",
    "gui.dialog.notSelectDevice": "មិនទាន់មានឧបករណ៍ណាមួយត្រូវបានជ្រើសរើសទេ, សូមចុចនៅលើ [កម្មវិធីបន្ថែម] ដែលស្ថិតនៅផ្នែក ខាងក្រោម-ឆ្វេង នៃកម្មវិធី,</br>សូមចុចដើម្បីជ្រើសរើស ឧបករណ៍ ដែលអ្នកចង់ភ្ជាប់ជាមួយ",
    "gui.dialog.connectDeviceError1": "ភ្ជាប់ជាមួយឧបករណ៍(",
    "gui.dialog.connectDeviceError2": ")បរាជ័យ,  សូមធ្វើតាមការណែនាំដូចខាងក្រោម:</br> *ប្តូរច្រក USB ហើយដោតខ្សែ USB ចូលសាជាថ្មី</br> *ផ្ទៀងផ្ទាត់ថាតើ ឧបករណ៍របស់អ្នកបានភ្ជាប់ដោយជោគជ័យហើយឬនៅ",
    "gui.dialog.connectDeviceError3": "ផ្ទាំងបញ្ជាគោល</br> * ចូលរួមជាមួយ ក្រុមទំនាក់ទំនងផ្លូវការ (671877416) ដើម្បីរាយការណ៍ពីបញ្ហា",
    "gui.dialog.calibration1": "</br> *សូម មួល/បង្វិល",
    "gui.dialog.calibration2": "កែតម្រូវផ្ទាំងបញ្ជាគោល</br>",
    "gui.dialog.runAsManager": "សូមចាកចេញពីកម្មវិធី Mind+ រួចប្រើម៉ៅស៍ខាងស្តាំចុចលើ រូបតំណាងកម្មវិធី បន្ទាប់មកជ្រើសយក \"Run as administrator\". រួចបើកកម្មវិធីសាជាថ្មី",
    "gui.dialog.runErrorForVortex": "ប្រព័ន្ធដំណើរការមិនប្រក្រតី, សូមដក Vortex ចេញ",
    "gui.dialog.runError": "ប្រព័ន្ធដំណើរការមិនប្រក្រតី",
    "gui.dialog.close": "បិទ/ចាកចេញ",
    "gui.dialog.variableNameSpecialCharacters": "ឈ្មោះអថេរ %1 មិនអាចមានតួអក្សរពិសេសដូចជា:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\] ទេ",
    "gui.dialog.funcNameSpecialCharacters": "ឈ្មោះអនុគមន៍ %1 មិនអាចមានតួអក្សរពិសេសដូចជា:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\] ទេ",
    "gui.dialog.funcArgsSpecialCharacters": "អញ្ញតនៃអនុគមន៍ %1 មិនអាចមានតួអក្សរពិសេសដូចជា:[\\`~!@#$%^&*()+<>?:{},./;\\'[]\\] ទេ",
    "gui.dialog.and": "និង",
    "gui.dialog.yes": "យល់ព្រម",
    "gui.dialog.no": "មិនយល់ព្រម",
    "gui.dialog.continue": "បន្តទៀត",
    "gui.dialog.maxReset": "លុបចោលកូដដែលមាននៅក្នុងតំបន់កែប្រែ ហើយជំនួសដោយកូដដែលមានស្រាប់ពីរោងចក្រ?",
    "gui.dialog.feedbackCannotEmpty": "សូមបំពេញ ប្រអប់ចូលរួមយោបល់/ប្រអប់នេះមិនអាចទុកនៅទំនេរទេ!",
    "gui.dialog.noInstallCompiler": "មិនមាន កំផាយល័រ ដែលដំឡើងរួចហើយទេ",
    "gui.dialog.install": "ដំឡើងតាមអ៊ិនធឺណែត",
    "gui.dialog.cancle": "បោះបង់",
    "gui.dialog.installingCompiler": "កំពុងដំឡើង កំផាយល័រ",
    "gui.dialog.uploading": "កំពុងផ្ញើរចេញ",
    "gui.dialog.changeLanuage": "ផ្លាស់ប្ដូរ ភាសា នឹងធ្វើឱ្យកម្មវិធីដែលអ្នកកំពុងធ្វើនេះត្រូវបានលុបចោល, តើអ្នកនៅតែចង់បន្តទៀតមែនទេ?",
    "gui.dialog.boardNotSupportMpy": "ផ្ទាំងបញ្ជានេះ: %1 មិនអាចប្រើជាមួយ ភាសា micropython ទេ",
    "gui.dialog.loadBlankItem": "ធាតុដែលមិនមាន </br> កំពុងតែដំឡើងដោយធាតុទទេ",
    "gui.dialog.switchBaudError": "កំហុសកើតឡើងដោយសារការប្តូរល្បឿនបញ្ជូន</br>",
    "gui.dialog.turnMicrobitCompass": "កាន់ micro:bit ដាក់ផ្តេកហើយផ្អៀងវាធ្វើយ៉ាងណាឱ្យអំពូលភ្លឺគ្រប់គ្រាប់ ដើម្បីកែតម្រូវត្រីវិស័យ",
    "gui.dialog.operationShow": "ការធ្វើបទបង្ហាញពីប្រតិបត្តិការ",
    "gui.dialog.mpython": "mpython",
    "gui.dialog.computer": "កំព្យុទ័រ",
    "gui.dialog.delete": "លុបចោល",
    "gui.dialog.sureDeleteFile": "ច្បាស់ទេថាអ្នកពិតជាចង់លុប ឯកសារ [%2] ដែលមាននៅលើ %1?",
    "gui.dialog.sureDeleteFolder": "ច្បាស់ទេថាអ្នកពិតជាចង់លុប ថតដាក់ឯកសារ [%2] ដែលមាននៅលើ %1?",
    "gui.dialog.uncorrectPath": "មិនបានជ្រើសរើស កន្លែងទុក ត្រឹមត្រូវទេ",
    "gui.dialog.laterDownLoad": "ធ្វើបច្ចុប្បន្នភាពពេលក្រោយ",
    "gui.dialog.downLoadUpdate": "ទាញយកបច្ចុប្បន្នភាព",
    "gui.dialog.dwnLoadWebsite": "ទាញយកគេហទំព៍រ",
    "gui.dialog.laterUpdate": "ដំឡើងពេលក្រោយ",
    "gui.dialog.update": "ដំឡើង",
    "gui.dialog.getUpdateMsgError": "កំហុសកើតឡើងពេលកំពុងធ្វើបច្ចុប្បន្នភាព",
    "gui.dialog.ok": "អូខេ",
    "gui.dialog.closeCodeBoxes": "ច្បាស់ទេថាអ្នកពិតជាចង់​ ចាកចេញពី ប្រអប់កូដ ទាំងនេះ %1 ?",
    "gui.dialog.emailError": "ទម្រង់ អ៊ីម៉ែល មិនត្រឹមត្រូវ!",
    "gui.dialog.emailEmpty": "អ៊ីម៉ែល មិនអាចទុកនៅទំនេរទេ!",
    "gui.dialog.bigFilePrompt": "ឯកសារមានទំហំធំ, សូមមេត្តារង់ចាំដោយអត់ធ្មត់, សូមអគុណ!",
    "gui.dialog.successFeedbackPrompt1": "អរគុណសម្រាប់ការចូលរួមយោបល់, សូមមេត្តារង់ចាំ ទំព័រនឹងបើកឡើងវិញនៅ ៣ វិនាទីក្រោយ",
    "gui.dialog.successFeedbackPrompt2": "ការចូលរួមយោបល់របស់អ្នកបានបញ្ជូនទៅហើយ, សូមរង់ចាំប៉ុន្មានវិនាទីសិន មុននឹងផ្ញើម្តងទៀត.",
    "gui.dialog.failedFeedbackPrompt": "បញ្ជូនចេញមានបញ្ហា... យើងពិតជាសោកស្តាយចំពោះបញ្ហានេះ។ ដើម្បីទុកការចូលរួមយោបល់របស់អ្នក សូមចូលទៅកាន់ វេទិការបស់យើងតាមអ៊ិនធឺណែត តាមតំណhttps://www.dfrobot.com/forum/  សូមអរគុណ!",
    "gui.dialog.successFeedbackPrompt3": "អរគុណសម្រាប់ការចូលរួមយោបល់របស់អ្នក",
    "gui.dialog.forcedCloseSoftWare": "កំហុសកើតឡើងពេលកំពុង រក្សាទុកឯកសារ, តើអ្នកចង់ បង្ខំឱ្យចាកចេញ ពីកម្មវិធី ទេ?",
    "gui.dialog.saveSuccess": "គម្រោងត្រូវបានរក្សាទុករួចរាល់ហើយ",
    "gui.dialog.noSelectTruePath": "មិនបានជ្រើសរើស កន្លែងទុក ត្រឹមត្រូវទេ",
    "gui.dialog.newItemContent": "អ្នកនឹងបង្កើតគម្រោងថ្មីមួយដើម្បីជំនួសគម្រោងបច្ចុប្បន្ន, តើអ្នកពិតជាចង់ធ្វើបែបនេះមែនទេ?",
    "gui.dialog.deleteBlocksError": "បរាជ័យក្នុងការលុប %1 ប្លុក, សូមលុបវាដោយខ្លួនឯង",
    "gui.dialog.netIsConnect": "សូមផ្ទៀងផ្ទាត់ថាតើអ្នកបានភ្ជាប់ជាមួយបណ្តាញហើយឬនៅ?",
    "gui.dialog.needToUpdate": "តើអ្នកត្រូវការ ធ្វើបច្ចុប្បន្នភាពកំផាយល័រ ទៅជំនាន់ចុងក្រោយបំផុត ដែរឬទេ: %1?",
    "gui.dialog.cantUseMpythonInArduinoC": "មិនមាន កំផាយល័រ ដែលដំឡើងរួចហើយទេ, អ្នកមិនអាចប្រើ ភាសា mpython នៅក្នុង ភាសា Arduino C បានទេ។ ប្រសិនបើអ្នកមិនអាចភ្ជាប់ទៅបណ្តាញបានទេ, អ្នកអាចរកវិធីណាមួយដើម្បីទាញយក 'Compiler offline plug-in package' ពី គេហទំព័រផ្លូវការរបស់យើង",
    "gui.dialog.enterMpyMode": "ចូលទៅកាន់ម៉ូត micropython",
    "gui.dialog.runHardware": "សូមភ្ជាប់ឧបករណ៍សិន មុននឹងដាក់វាឱ្យដំណើរការ",
    "gui.dialog.sureDelete": "ច្បាស់ទេថាអ្នកពិតជាចង់ លុប វាចោល?",
    "gui.dialog.notSupportWebGL": "កំហុសកើតឡើងពេលកំពុង ទាញយកឆាក, ហេតុការណ៍នេះកើតឡើងបណ្តាលមកពី កំព្យុទ៍រ របស់អ្នកមិនទទួលយក WebGL,</br> សូមមើលព័ត៌មានលំអិតបន្ថែមនៅខាងក្រោម: </br> %1 </br>ប្រើក្រុម QQ ហើយទាក់ទងយើងតាម: 671877416",
    "gui.dialog.boardIsnotMPython": "ផ្ទាំងបញ្ជាគោលដែលអ្នកកំពុងភ្ជាប់ជាមួយ មិនមែនជាផ្ទាំងបញ្ជា mPython ទេ,​ សូមប្រើប្រាស់ផ្ទាំងបញ្ជា mPython",
    "gui.dialog.swdMicrobitUpload": "កំព្យុទ័ររបស់អ្នកត្រូវប្រើ SWD ដើម្បីបញ្ចូលកូដទៅក្នុង micro:bit, សូមដាក់ Mind+ ឱ្យដំណើរការក្នុងរបប 'Run as administrator'.",
    "gui.dialog.openPortError": "បើកច្រក %1 កំហុស: %2",
    "gui.dialog.pressedA": "សូម ចុចប៉ូតុង A ឱ្យជាប់, សូមកុំលែង",
    "gui.dialog.unpressedA": "សូមលែងប៉ូតុង A",
    "gui.dialog.unsupportProject": "ធាតុដែលមិនមាន </br> កំពុងតែដំឡើងដោយធាតុទទេ",
    "gui.dialog.openProjectError": "គម្រោងដែលបង្កើតនៅក្នុងម៉ូត Scratch ត្រូវបើកឡើងវិញក្នុងម៉ូត Scratch</br> សូមផ្ទៀងផ្ទាត់ម៉ូតបច្ចុប្បន្នដែលអ្នកកំពុងប្រើ",
    "gui.dialog.installCompilerError1": "ទាញយកបរាជ័យ, អ្នកអាចទាញយក កម្មវិធីដំឡើងដែលមិនត្រូវការអ៊ីនធឺណែត ឬ អ្នកអាចសាកល្បងម្តងទៀត",
    "gui.dialog.installCompilerOffline": "ទាញយក កំផាយល័រដែលមិនត្រូវការអ៊ីនធឺណែត",
    "gui.dialog.installCompilerError2": "កំហុសពេលផ្ទៀងផ្ទាត់,  អ្នកអាចទាញយក កម្មវិធីដំឡើងដែលមិនត្រូវការអ៊ីនធឺណែត ឬ អ្នកអាចសាកល្បងទាញយកម្តងទៀត",
    "gui.dialog.installCompilerError3": "កំហុសពេលដំឡើង,  អ្នកអាចទាញយក កម្មវិធីដំឡើងដែលមិនត្រូវការអ៊ីនធឺណែត ឬ អ្នកអាចសាកល្បងទាញយកម្តងទៀត",
    "gui.dialog.installCompilerSuccess": "កំផាយល័រ បានដំឡើងរួចរាល់, អ្នកអាចប្រើ Esp32 ជាមួយភាសា ArduinoC បាន",
    "gui.dialog.sureAdapter": "រកមិនឃើញឧបករណ៍សម្រួលទេ, សូមបញ្ជាក់ថាអ្នកបានភ្ជាប់ឧបករណ៍សម្រួលរួចហើយ",
    "gui.dialog.checkPort": "បរាជ័យក្នុងការបើក ច្រកសេរ៊ី, សូមផ្ទៀងផ្ទាត់ក្រែង ច្រកសេរ៊ី ដែលអ្នកជ្រើសរើស កំពុងជាប់រវល់.",
    "gui.dialog.changeBoard": "ផ្លាស់ប្ដូរ ផ្ទាំងបញ្ជា នឹងធ្វើឱ្យកម្មវិធីដែលអ្នកកំពុងធ្វើនេះត្រូវបានលុបចោល, តើអ្នកនៅតែចង់បន្តទៀតមែនទេ?",
    "gui.crashMessage.somethingWrong": "អូហ៍! មានអ្វីមិនប្រក្រតីហើយ",
    "gui.crashMessage.reload": "ផ្ទុកឡើងវិញ",
    "gui.crashMessage.submit": "បញ្ជូន",
    "gui.crashMessage.attachment": "ឯកសារភ្ជាប់",
    "gui.crashMessage.errFeedbackNote": "អ្នកអាចសរសេរ សាររៀបរាប់ពីកំហុស ឬ ការណែនាំនៅទីនេះ, Mind+ នឹងកាន់តែមានភាពប្រសើរឡើងប្រសិនបើមានអ្នកចូលរួមជាមួយ",
    "gui.crashMessage.promptContent": "យើងពិតជាមានការសោកស្តាយខ្លាំងណាស់ ដែល​ Mind + បានគាំង...​ អ្នកអាចផ្ញើ ប្រភេទកំហុសនេះទៅកាន់ក្រុមការងារ Mind + នៅខាងស្តាំ, សូមអរគុណ! សូមមេត្តាសាកល្បងបើក ទំព័ររបស់អ្នក ម្តងទៀត.",
    "gui.dialog.isSaveProject": "រក្សាទុកគម្រោងបច្ចុប្បន្នទេ?",
    "gui.dialog.save": "រក្សាទុក",
    "gui.dialog.notSave": "មិនរក្សាទុក",
    "gui.dialog.variableIsNull": "ឈ្មោះអថេរ មិនអាចនៅចំហរចោលទេ",
    "gui.dialog.listNameIsNull": "ឈ្មោះបញ្ជី មិនអាចនៅចំហរចោលទេ",
    "gui.dialog.variableIsRepeat": "ឈ្មោះអថេរ​ មិនអាចស្ទួនគ្នាទេ, សូមធ្វើការកែប្រែឈ្មោះ",
    "gui.dialog.pictureAINotNull": "មិនអាចជាប្រភេទ ទទេ ទេ",
    "gui.dialog.pictureAIInitServer": "សូមដាក់ម៉ាស៊ីនមេឱ្យដំណើរការពីដំបូងជាមុនសិន",
    "gui.dialog.pictureAIInitServerError": "កំហុសកើតឡើងពេលដាក់ម៉ាស៊ីនមេកំពុងដំណើរការពីដំបូង, សូមមេត្តាផ្ទៀងផ្ទាត់ លេខគណនី និង សោសម្ងាត់",
    "gui.dialog.versionPrompt": "ជំនាន់ឯកសារ sb3 បច្ចុប្បន្ន「%1」",
    "gui.dialog.versionGreater": "ជំនាន់ឯកសារ sb3 មានខ្ពស់ជាង ជំនាន់ឯកសារដែលអ្នកកំពុងប្រើបច្ចុប្បន្ន, ជំនាន់ឯកសារ​ sb3「%1」",
    "gui.dialog.noModulePrompt": "មិនមានម៉ូឌុលតូចៗដែលស្ថិតនៅក្នុងការបែងចែកបច្ចុប្បន្នទេ, សូមមើល ជំនួយ ដើម្បីបានព័ត៌មានលំអិត",
    "gui.dialog.firstSelectBoard": "សូមជ្រើសរើស 'ផ្ទាំងបញ្ជា' ឬ 'ឈុតឧបករណ៍' ជាមុនសិន.",
    "gui.dialog.downloadFileError": "មានកំហុសពេលទាញយកឯកសារ",
    "gui.dialog.noSearchModules": "រកមិនឃើញម៉ូឌុលដែលទាក់ទងទេ, សូមផ្ទៀងផ្ទាត់ កន្លែងទុក",
    "gui.dialog.networkError": "បណ្តាញមានបញ្ហា",
    "gui.dialog.saveThirdModulesError": "មានកំហុសពេលកំពុងយក បណ្ណាល័យអ្នកប្រើប្រាស់ រក្សាទុក",
    "gui.dialog.isContinueUpdateThirdModule": "បច្ចុប្បន្នមាន​ បណ្ណាល័យអ្នកប្រើប្រាស់បន្ថែម ដូចគ្នា, បើអ្នកបន្តធ្វើបច្ចុប្បនភាព នោះអ្នកនឹងសរសេរជាន់ពីលើ បណ្ណាល័យអ្នកប្រើប្រាស់បច្ចុប្បន្ន, តើអ្នកនៅតែចង់បន្តធ្វើបច្ចុប្បន្នភាព?",
    "gui.dialog.thirdex.alterFieldsAndCancelRefresh": "កែប្រែឈ្មោះទិន្នន័យ 「%2」 នៃ %1, បង្ករឱ្យមានការទាញយក​ បណ្ណាល័យអ្នកប្រើប្រាស់ ដោយខ្លួនអ្នកផ្ទាល់។ ការទាញយកជាថ្មីនេះត្រូវបានបោះបង់ចោល",
    "gui.dialog.thirdex.parseErrorAndNotRefresh": "មានកំហុសពេលកំពុងវិភាគ %1, ការទាញយកជាថ្មីនឹងនៅតែបន្ត ប៉ុន្តែមាតិកានៅក្នុង 「%2」 នឹងមិនត្រូវបានធ្វើបច្ចុប្បន្នភាព​ទេ",
    "gui.dialog.thirdex.blockAlterAndCancleRefresh": "រូបរាងរបស់ប្លុក ឬ ប្រភេទអញ្ញតនៃ ប្រអប់បញ្ចូល ត្រូវបានកែប្រែ, សូមធ្វើការលុប ប្លុក ដែលជាប់សញ្ញាគូសបញ្ជាក់ ចេញពីកន្លែងធ្វើកម្មវិធីដោយខ្លួនឯង បន្ទាប់មកចាំទាញយកប្លុកនោះមកជាថ្មី។ ការទាញយកជាថ្មីត្រូវបានបោះបង់",
    "gui.dialog.thirdex.blockAlterAndCancleLoad": "រាល់ការកែប្រែរូបរាងរបស់ប្លុក ឬ ប្រភេទអញ្ញតនៃ ប្រអប់បញ្ចូល, សូមធ្វើការលុប ប្លុកជាប់សញ្ញា ចេញពីកន្លែងធ្វើកម្មវិធីដោយខ្លួនឯង បន្ទាប់មកចាំទាញយកប្លុកនោះមកជាថ្មី។ ការទាញយកត្រូវបានបោះបង់",
    "gui.dialog.thirdex.filesEmpty": "កន្លែងបំពេញឯកសារមិនអាចទុកនៅទំនេរទេ វានឹងបណ្តាលឱ្យឯកសារដែលទាក់ទងផ្សេងទៀតបាត់បង់។ សូមផ្ទៀងផ្ទាត់ ឯកសារនៃបណ្ណាល័យអ្នកប្រើប្រាស់ ដែលបាននាំចូល!",
    "BACKDROPS_ARCTIC": "Arctic",
    "BACKDROPS_BASEBALL": "Baseball",
    "BACKDROPS_BASEBALL_2": "Baseball 2",
    "BACKDROPS_BASKETBALL": "Basketball",
    "BACKDROPS_BASKETBALL_2": "Basketball 2",
    "BACKDROPS_BEACH_MALIBU": "Beach Malibu",
    "BACKDROPS_BEACH_RIO": "Beach Rio",
    "BACKDROPS_BEDROOM": "Bedroom",
    "BACKDROPS_BEDROOM_2": "Bedroom 2",
    "BACKDROPS_BEDROOM_3": "Bedroom 3",
    "BACKDROPS_BENCH_WITH_VIEW": "Bench With View",
    "BACKDROPS_BLUE_SKY": "Blue Sky",
    "BACKDROPS_BLUE_SKY_2": "Blue Sky 2",
    "BACKDROPS_BLUE_SKY3": "Blue Sky3",
    "BACKDROPS_BOARDWALK": "Boardwalk",
    "BACKDROPS_CANYON": "Canyon",
    "BACKDROPS_CASTLE_1": "Castle 1",
    "BACKDROPS_CASTLE_2": "Castle 2",
    "BACKDROPS_CASTLE_3": "Castle 3",
    "BACKDROPS_CASTLE_4": "Castle 4",
    "BACKDROPS_CHALKBOARD": "Chalkboard",
    "BACKDROPS_CIRCLES": "Circles",
    "BACKDROPS_CITY_WITH_WATER": "City With Water",
    "BACKDROPS_COLORFUL_CITY": "Colorful City",
    "BACKDROPS_CONCERT": "Concert",
    "BACKDROPS_DESERT": "Desert",
    "BACKDROPS_FARM": "Farm",
    "BACKDROPS_FIELD_AT_MIT": "Field At Mit",
    "BACKDROPS_FLOWERS": "Flowers",
    "BACKDROPS_FOREST": "Forest",
    "BACKDROPS_GALAXY": "Galaxy",
    "BACKDROPS_GARDEN-ROCK": "Garden-rock",
    "BACKDROPS_GREEK_THEATER": "Greek Theater",
    "BACKDROPS_HALL": "Hall",
    "BACKDROPS_HAY_FIELD": "Hay Field",
    "BACKDROPS_GRAFFITI": "Graffiti",
    "BACKDROPS_HEARTS1": "Hearts1",
    "BACKDROPS_HEARTS2": "Hearts2",
    "BACKDROPS_HILL": "Hill",
    "BACKDROPS_JUNGLE": "Jungle",
    "BACKDROPS_JURASSIC": "Jurassic",
    "BACKDROPS_LIGHT": "Light",
    "BACKDROPS_METRO": "Metro",
    "BACKDROPS_MOON": "Moon",
    "BACKDROPS_MOUNTAIN": "Mountain",
    "BACKDROPS_MURAL": "Mural",
    "BACKDROPS_NEBULA": "Nebula",
    "BACKDROPS_NEON_TUNNEL": "Neon Tunnel",
    "BACKDROPS_NIGHT_CITY": "Night City",
    "BACKDROPS_NIGHT_CITY_WITH_STREET": "Night City With Street",
    "BACKDROPS_PARTY": "Party",
    "BACKDROPS_PATHWAY": "Pathway",
    "BACKDROPS_PARTY_ROOM": "Party Room",
    "BACKDROPS_PLAYGROUND": "Playground",
    "BACKDROPS_PLAYING_FIELD": "Playing Field",
    "BACKDROPS_POOL": "Pool",
    "BACKDROPS_PURPLE": "Purple",
    "BACKDROPS_RAYS": "Rays",
    "BACKDROPS_REFRIGERATOR": "Refrigerator",
    "BACKDROPS_ROOM_1": "Room 1",
    "BACKDROPS_ROOM_2": "Room 2",
    "BACKDROPS_SAVANNA": "Savanna",
    "BACKDROPS_SCHOOL": "School",
    "BACKDROPS_SLOPES": "Slopes",
    "BACKDROPS_SOCCER": "Soccer",
    "BACKDROPS_SOCCER_2": "Soccer 2",
    "BACKDROPS_SPACE": "Space",
    "BACKDROPS_SPACE_2": "Space 2",
    "BACKDROPS_SPACE_3": "Space 3",
    "BACKDROPS_SPACE_4": "Space 4",
    "BACKDROPS_SPOTLIGHT-STAGE": "Spotlight-stage",
    "BACKDROPS_STARS": "Stars",
    "BACKDROPS_STRIPES": "Stripes",
    "BACKDROPS_THEATER": "Theater",
    "BACKDROPS_THEATER_2": "Theater 2",
    "BACKDROPS_TREE": "Tree",
    "BACKDROPS_UNDERWATER_1": "Underwater 1",
    "BACKDROPS_UNDERWATER_2": "Underwater 2",
    "BACKDROPS_URBAN": "Urban",
    "BACKDROPS_WALL_1": "Wall 1",
    "BACKDROPS_WALL_2": "Wall 2",
    "BACKDROPS_WATER_AND_ROCKS": "Water And Rocks",
    "BACKDROPS_WETLAND": "Wetland",
    "BACKDROPS_WINTER": "Winter",
    "BACKDROPS_WINTER-LIGHTS": "Winter-lights",
    "BACKDROPS_WITCH_HOUSE": "Witch House",
    "BACKDROPS_WOODS": "Woods",
    "BACKDROPS_WOODS_AND_BENCH": "Woods And Bench",
    "BACKDROPS_XY-GRID": "Xy-grid",
    "BACKDROPS_XY-GRID-20PX": "Xy-grid-20px",
    "BACKDROPS_XY-GRID-30PX": "Xy-grid-30px",
    "COSTUMES_ABBY-A": "Abby-a",
    "COSTUMES_ABBY-B": "Abby-b",
    "COSTUMES_ABBY-C": "Abby-c",
    "COSTUMES_ABBY-D": "Abby-d",
    "COSTUMES_AMON": "Amon",
    "COSTUMES_ANDIE-A": "Andie-a",
    "COSTUMES_ANDIE-B": "Andie-b",
    "COSTUMES_ANDIE-C": "Andie-c",
    "COSTUMES_ANDIE-D": "Andie-d",
    "COSTUMES_ANINA_POP_DOWN": "Anina Pop Down",
    "COSTUMES_ANINA_POP_FRONT": "Anina Pop Front",
    "COSTUMES_ANINA_POP_L_ARM": "Anina Pop L Arm",
    "COSTUMES_ANINA_POP_LEFT": "Anina Pop Left",
    "COSTUMES_ANINA_POP_R_ARM": "Anina Pop R Arm",
    "COSTUMES_ANINA_POP_RIGHT": "Anina Pop Right",
    "COSTUMES_ANINA_POP_STAND": "Anina Pop Stand",
    "COSTUMES_ANINA_R_CROSS": "Anina R Cross",
    "COSTUMES_ANINA_STANCE": "Anina Stance",
    "COSTUMES_ANINA_TOP_FREEZE": "Anina Top Freeze",
    "COSTUMES_ANINA_TOP_L_STEP": "Anina Top L Step",
    "COSTUMES_ANINA_TOP_R_STEP": "Anina Top R Step",
    "COSTUMES_ANINA_TOP_STAND": "Anina Top Stand",
    "COSTUMES_APPLE": "Apple",
    "COSTUMES_ARROW1-A": "Arrow1-a",
    "COSTUMES_ARROW1-B": "Arrow1-b",
    "COSTUMES_ARROW1-C": "Arrow1-c",
    "COSTUMES_ARROW1-D": "Arrow1-d",
    "COSTUMES_AVERY_WALKING-A": "Avery walking-a",
    "COSTUMES_AVERY_WALKING-B": "Avery walking-b",
    "COSTUMES_AVERY_WALKING-C": "Avery walking-c",
    "COSTUMES_AVERY_WALKING-D": "Avery walking-d",
    "COSTUMES_AVERY-A": "Avery-a",
    "COSTUMES_AVERY-B": "Avery-b",
    "COSTUMES_BALL-A": "Ball-a",
    "COSTUMES_BALL-B": "Ball-b",
    "COSTUMES_BALL-C": "Ball-c",
    "COSTUMES_BALL-D": "Ball-d",
    "COSTUMES_BALL-E": "Ball-e",
    "COSTUMES_BALLERINA-A": "Ballerina-a",
    "COSTUMES_BALLERINA-B": "Ballerina-b",
    "COSTUMES_BALLERINA-C": "Ballerina-c",
    "COSTUMES_BALLERINA-D": "Ballerina-d",
    "COSTUMES_BALLOON1-A": "Balloon1-a",
    "COSTUMES_BALLOON1-B": "Balloon1-b",
    "COSTUMES_BALLOON1-C": "Balloon1-c",
    "COSTUMES_BANANAS": "Bananas",
    "COSTUMES_BASEBALL": "Baseball",
    "COSTUMES_BASKETBALL": "Basketball",
    "COSTUMES_BAT-A": "Bat-a",
    "COSTUMES_BAT-B": "Bat-b",
    "COSTUMES_BAT-C": "Bat-c",
    "COSTUMES_BAT-D": "Bat-d",
    "COSTUMES_BATTER-A": "Batter-a",
    "COSTUMES_BATTER-B": "Batter-b",
    "COSTUMES_BATTER-C": "Batter-c",
    "COSTUMES_BATTER-D": "Batter-d",
    "COSTUMES_BEACHBALL": "Beachball",
    "COSTUMES_BEAR-A": "Bear-a",
    "COSTUMES_BEAR-B": "Bear-b",
    "COSTUMES_BEAR-WALK-A": "Bear-walk-a",
    "COSTUMES_BEAR-WALK-B": "Bear-walk-b",
    "COSTUMES_BEAR-WALK-C": "Bear-walk-c",
    "COSTUMES_BEAR-WALK-D": "Bear-walk-d",
    "COSTUMES_BEAR-WALK-E": "Bear-walk-e",
    "COSTUMES_BEAR-WALK-F": "Bear-walk-f",
    "COSTUMES_BEAR-WALK-G": "Bear-walk-g",
    "COSTUMES_BEAR-WALK-H": "Bear-walk-h",
    "COSTUMES_BEETLE": "Beetle",
    "COSTUMES_BELL1": "Bell1",
    "COSTUMES_BEN-A": "Ben-a",
    "COSTUMES_BEN-B": "Ben-b",
    "COSTUMES_BEN-C": "Ben-c",
    "COSTUMES_BEN-D": "Ben-d",
    "COSTUMES_BOWL-A": "Bowl-a",
    "COSTUMES_BOWTIE": "Bowtie",
    "COSTUMES_BREAD": "Bread",
    "COSTUMES_BROOM": "Broom",
    "COSTUMES_BUILDING-A": "Building-a",
    "COSTUMES_BUILDING-B": "Building-b",
    "COSTUMES_BUILDING-C": "Building-c",
    "COSTUMES_BUILDING-D": "Building-d",
    "COSTUMES_BUILDING-E": "Building-e",
    "COSTUMES_BUILDING-F": "Building-f",
    "COSTUMES_BUILDING-G": "Building-g",
    "COSTUMES_BUILDING-H": "Building-h",
    "COSTUMES_BUILDING-I": "Building-i",
    "COSTUMES_BUILDING-J": "Building-j",
    "COSTUMES_BUTTERFLY1-A": "Butterfly1-a",
    "COSTUMES_BUTTERFLY1-B": "Butterfly1-b",
    "COSTUMES_BUTTERFLY1-C": "Butterfly1-c",
    "COSTUMES_BUTTERFLY2-A": "Butterfly2-a",
    "COSTUMES_BUTTERFLY2-B": "Butterfly2-b",
    "COSTUMES_BUTTON1": "Button1",
    "COSTUMES_BUTTON2-A": "Button2-a",
    "COSTUMES_BUTTON2-B": "Button2-b",
    "COSTUMES_BUTTON3-A": "Button3-a",
    "COSTUMES_BUTTON3-B": "Button3-b",
    "COSTUMES_BUTTON4-A": "Button4-a",
    "COSTUMES_BUTTON4-B": "Button4-b",
    "COSTUMES_BUTTON5-A": "Button5-a",
    "COSTUMES_BUTTON5-B": "Button5-b",
    "COSTUMES_CAKE-A": "Cake-a",
    "COSTUMES_CAKE-B": "Cake-b",
    "COSTUMES_CALVRETT_JUMPING": "Calvrett Jumping",
    "COSTUMES_CALVRETT_THINKING": "Calvrett Thinking",
    "COSTUMES_CASEY-A": "Casey-a",
    "COSTUMES_CASEY-B": "Casey-b",
    "COSTUMES_CASEY-C": "Casey-c",
    "COSTUMES_CASEY-D": "casey-d",
    "COSTUMES_CASSY-A": "Cassy-a",
    "COSTUMES_CASSY-B": "Cassy-b",
    "COSTUMES_CASSY-C": "Cassy-c",
    "COSTUMES_CASSY-D": "Cassy-d",
    "COSTUMES_CAT_2": "Cat 2",
    "COSTUMES_CATCHER-A": "Catcher-a",
    "COSTUMES_CATCHER-B": "Catcher-b",
    "COSTUMES_CATCHER-C": "Catcher-c",
    "COSTUMES_CATCHER-D": "Catcher-d",
    "COSTUMES_CENTAUR-A": "Centaur-a",
    "COSTUMES_CENTAUR-B": "Centaur-b",
    "COSTUMES_CENTAUR-C": "centaur-c",
    "COSTUMES_CENTAUR-D": "centaur-d",
    "COSTUMES_CHAMP99-A": "Champ99-a",
    "COSTUMES_CHAMP99-B": "Champ99-b",
    "COSTUMES_CHAMP99-C": "champ99-c",
    "COSTUMES_CHAMP99-D": "champ99-d",
    "COSTUMES_CHAMP99-E": "champ99-e",
    "COSTUMES_CHAMP99-F": "champ99-f",
    "COSTUMES_CHAMP99-G": "champ99-g",
    "COSTUMES_CHEESY_PUFFS": "Cheesy Puffs",
    "COSTUMES_CHICK-A": "chick-a",
    "COSTUMES_CHICK-B": "chick-b",
    "COSTUMES_CHICK-C": "chick-c",
    "COSTUMES_CITY_BUS-A": "City Bus-a",
    "COSTUMES_CITY_BUS-B": "City Bus-b",
    "COSTUMES_CLOUD": "Cloud",
    "COSTUMES_CLOUD-A": "Cloud-a",
    "COSTUMES_CLOUD-B": "Cloud-b",
    "COSTUMES_CLOUD-C": "Cloud-c",
    "COSTUMES_CLOUD-D": "Cloud-d",
    "COSTUMES_CONVERTIBLE": "Convertible",
    "COSTUMES_CONVERTIBLE_3": "Convertible 2",
    "COSTUMES_CRAB-A": "Crab-a",
    "COSTUMES_CRAB-B": "Crab-b",
    "COSTUMES_CRYSTAL-A": "Crystal-a",
    "COSTUMES_CRYSTAL-B": "Crystal-b",
    "COSTUMES_DAN-A": "dan-a",
    "COSTUMES_DAN-B": "dan-b",
    "COSTUMES_DANI-A": "Dani-a",
    "COSTUMES_DANI-B": "Dani-b",
    "COSTUMES_DANI-C": "Dani-c",
    "COSTUMES_DEE-A": "Dee-a",
    "COSTUMES_DEE-B": "Dee-b",
    "COSTUMES_DEE-C": "Dee-c",
    "COSTUMES_DEE-D": "Dee-d",
    "COSTUMES_DEE-E": "Dee-e",
    "COSTUMES_DEVIN-A": "Devin-a",
    "COSTUMES_DEVIN-B": "Devin-b",
    "COSTUMES_DEVIN-C": "Devin-c",
    "COSTUMES_DEVIN-D": "Devin-d",
    "COSTUMES_DINOSAUR1-A": "Dinosaur1-a",
    "COSTUMES_DINOSAUR1-B": "Dinosaur1-b",
    "COSTUMES_DINOSAUR1-C": "Dinosaur1-c",
    "COSTUMES_DINOSAUR1-D": "Dinosaur1-d",
    "COSTUMES_DINOSAUR2-A": "Dinosaur2-a",
    "COSTUMES_DINOSAUR2-B": "Dinosaur2-b",
    "COSTUMES_DINOSAUR2-C": "Dinosaur2-c",
    "COSTUMES_DINOSAUR2-D": "Dinosaur2-d",
    "COSTUMES_DINOSAUR3-A": "Dinosaur3-a",
    "COSTUMES_DINOSAUR3-B": "Dinosaur3-b",
    "COSTUMES_DINOSAUR3-C": "Dinosaur3-c",
    "COSTUMES_DINOSAUR3-D": "Dinosaur3-d",
    "COSTUMES_DINOSAUR3-E": "Dinosaur3-e",
    "COSTUMES_DINOSAUR4-A": "Dinosaur4-a",
    "COSTUMES_DINOSAUR4-B": "Dinosaur4-b",
    "COSTUMES_DINOSAUR4-C": "Dinosaur4-c",
    "COSTUMES_DINOSAUR4-D": "Dinosaur4-d",
    "COSTUMES_DIVER1": "Diver1",
    "COSTUMES_DIVER2": "Diver2",
    "COSTUMES_DM_FREEZE": "dm freeze",
    "COSTUMES_DM_POP_DOWN": "dm pop down",
    "COSTUMES_DM_POP_FRONT": "dm pop front",
    "COSTUMES_DM_POP_L_ARM": "dm pop L arm",
    "COSTUMES_DM_POP_LEFT": "dm pop left",
    "COSTUMES_DM_POP_R_ARM": "dm pop R arm",
    "COSTUMES_DM_POP_RIGHT": "dm pop right",
    "COSTUMES_DM_POP_STAND": "dm pop stand",
    "COSTUMES_DM_STANCE": "dm stance",
    "COSTUMES_DM_TOP_L_LEG": "dm top L leg",
    "COSTUMES_DM_TOP_R_LEG": "dm top R leg",
    "COSTUMES_DM_TOP_R_LEG2": "dm top R leg2",
    "COSTUMES_DM_TOP_STAND": "dm top stand",
    "COSTUMES_DOG1-A": "Dog1-a",
    "COSTUMES_DOG1-B": "Dog1-b",
    "COSTUMES_DOG2-A": "Dog2-a",
    "COSTUMES_DOG2-B": "Dog2-b",
    "COSTUMES_DOG2-C": "Dog2-c",
    "COSTUMES_DONUT": "Donut",
    "COSTUMES_DORIAN-A": "Dorian-a",
    "COSTUMES_DORIAN-B": "Dorian-b",
    "COSTUMES_DORIAN-C": "Dorian-c",
    "COSTUMES_DORIAN-D": "Dorian-d",
    "COSTUMES_DOT-A": "Dot-a",
    "COSTUMES_DOT-B": "Dot-b",
    "COSTUMES_DOT-C": "Dot-c",
    "COSTUMES_DOT-D": "Dot-d",
    "COSTUMES_DOVE-A": "Dove-a",
    "COSTUMES_DOVE-B": "Dove-b",
    "COSTUMES_DRAGON-A": "Dragon-a",
    "COSTUMES_DRAGON-B": "Dragon-b",
    "COSTUMES_DRAGON-C": "Dragon-c",
    "COSTUMES_DRAGON1-A": "Dragon1-a",
    "COSTUMES_DRAGON1-B": "Dragon1-b",
    "COSTUMES_DRAGONFLY-A": "Dragonfly-a",
    "COSTUMES_DRAGONFLY-B": "Dragonfly-b",
    "COSTUMES_DRESS-A": "Dress-a",
    "COSTUMES_DRESS-B": "Dress-b",
    "COSTUMES_DRESS-C": "Dress-c",
    "COSTUMES_DRUM-A": "Drum-a",
    "COSTUMES_DRUM-B": "Drum-b",
    "COSTUMES_DRUM-CYMBAL-A": "Drum-cymbal-a",
    "COSTUMES_DRUM-CYMBAL-B": "Drum-cymbal-b",
    "COSTUMES_DRUM-HIGHHAT-A": "Drum-highhat-a",
    "COSTUMES_DRUM-HIGHHAT-B": "Drum-highhat-b",
    "COSTUMES_DRUM-KIT": "Drum-kit",
    "COSTUMES_DRUM-KIT-B": "Drum-kit-b",
    "COSTUMES_DRUM-SNARE-A": "Drum-snare-a",
    "COSTUMES_DRUM-SNARE-B": "Drum-snare-b",
    "COSTUMES_DRUMS_CONGA-A": "Drums Conga-a",
    "COSTUMES_DRUMS_CONGA-B": "Drums Conga-b",
    "COSTUMES_DUCK": "Duck",
    "COSTUMES_EARTH": "Earth",
    "COSTUMES_EASEL-A": "Easel-a",
    "COSTUMES_EASEL-B": "Easel-b",
    "COSTUMES_EASEL-C": "Easel-c",
    "COSTUMES_EGG-A": "Egg-a",
    "COSTUMES_EGG-B": "Egg-b",
    "COSTUMES_EGG-C": "Egg-c",
    "COSTUMES_EGG-D": "Egg-d",
    "COSTUMES_EGG-E": "Egg-e",
    "COSTUMES_EGG-F": "Egg-f",
    "COSTUMES_ELEPHANT-A": "Elephant-a",
    "COSTUMES_ELEPHANT-B": "Elephant-b",
    "COSTUMES_ELF-A": "Elf-a",
    "COSTUMES_ELF-B": "Elf-b",
    "COSTUMES_ELF-C": "Elf-c",
    "COSTUMES_ELF-D": "Elf-d",
    "COSTUMES_ELF-E": "Elf-e",
    "COSTUMES_FAIRY-A": "Fairy-a",
    "COSTUMES_FAIRY-B": "Fairy-b",
    "COSTUMES_FAIRY-C": "Fairy-c",
    "COSTUMES_FAIRY-D": "Fairy-d",
    "COSTUMES_FAIRY-E": "Fairy-e",
    "COSTUMES_FISH-A": "Fish-a",
    "COSTUMES_FISH-B": "Fish-b",
    "COSTUMES_FISH-C": "Fish-c",
    "COSTUMES_FISH-D": "Fish-d",
    "COSTUMES_FISHBOWL-A": "Fishbowl-a",
    "COSTUMES_FISHBOWL-B": "Fishbowl-b",
    "COSTUMES_FOOD_TRUCK-A": "Food Truck-a",
    "COSTUMES_FOOD_TRUCK-B": "Food Truck-b",
    "COSTUMES_FOOD_TRUCK-C": "Food Truck-c",
    "COSTUMES_FOOTBALL_RUNNING": "Football Running",
    "COSTUMES_FOOTBALL_STANDING": "Football Standing",
    "COSTUMES_FORTUNE_COOKIE": "Fortune Cookie",
    "COSTUMES_FOX-A": "Fox-a",
    "COSTUMES_FOX-B": "Fox-b",
    "COSTUMES_FOX-C": "Fox-c",
    "COSTUMES_FRANK-A": "Frank-a",
    "COSTUMES_FRANK-B": "Frank-b",
    "COSTUMES_FRANK-C": "Frank-c",
    "COSTUMES_FRANK-D": "Frank-d",
    "COSTUMES_FROG": "Frog",
    "COSTUMES_FROG_2-A": "Frog 2-a",
    "COSTUMES_FROG_2-B": "Frog 2-b",
    "COSTUMES_FROG_2-C": "Frog 2-c",
    "COSTUMES_FRUIT_PLATTER": "Fruit Platter",
    "COSTUMES_FRUITSALAD": "Fruitsalad",
    "COSTUMES_GHOST-A": "Ghost-a",
    "COSTUMES_GHOST-B": "Ghost-b",
    "COSTUMES_GHOST-C": "Ghost-c",
    "COSTUMES_GHOST-D": "Ghost-d",
    "COSTUMES_GIFT-A": "Gift-a",
    "COSTUMES_GIFT-B": "Gift-b",
    "COSTUMES_GIRAFFE-A": "Giraffe-a",
    "COSTUMES_GIRAFFE-B": "Giraffe-b",
    "COSTUMES_GIRAFFE-C": "Giraffe-c",
    "COSTUMES_GLASS_WATER-A": "Glass Water-a",
    "COSTUMES_GLASS_WATER-B": "Glass water-b",
    "COSTUMES_GLASSES-A": "Glasses-a",
    "COSTUMES_GLASSES-B": "Glasses-b",
    "COSTUMES_GLASSES-C": "Glasses-c",
    "COSTUMES_GLASSES-E": "Glasses-e",
    "COSTUMES_GOALIE-A": "Goalie-a",
    "COSTUMES_GOALIE-B": "Goalie-b",
    "COSTUMES_GOALIE-C": "goalie-c",
    "COSTUMES_GOALIE-D": "Goalie-d",
    "COSTUMES_GOALIE-E": "Goalie-e",
    "COSTUMES_GOBLIN-A": "Goblin-a",
    "COSTUMES_GOBLIN-B": "Goblin-b",
    "COSTUMES_GOBLIN-C": "Goblin-c",
    "COSTUMES_GOBLIN-D": "Goblin-d",
    "COSTUMES_GRASSHOPPER-A": "Grasshopper-a",
    "COSTUMES_GRASSHOPPER-B": "Grasshopper-b",
    "COSTUMES_GRASSHOPPER-C": "Grasshopper-c",
    "COSTUMES_GRASSHOPPER-D": "Grasshopper-d",
    "COSTUMES_GRASSHOPPER-E": "Grasshopper-e",
    "COSTUMES_GRASSHOPPER-F": "Grasshopper-f",
    "COSTUMES_GREEN_FLAG": "Green Flag",
    "COSTUMES_GRIFFIN-A": "Griffin-a",
    "COSTUMES_GRIFFIN-B": "Griffin-b",
    "COSTUMES_GRIFFIN-C": "Griffin-c",
    "COSTUMES_GRIFFIN-D": "Griffin-d",
    "COSTUMES_GUITAR-A": "guitar-a",
    "COSTUMES_GUITAR-B": "guitar-b",
    "COSTUMES_GUITAR-ELECTRIC1-A": "Guitar-electric1-a",
    "COSTUMES_GUITAR-ELECTRIC1-B": "Guitar-electric1-b",
    "COSTUMES_GUITAR-ELECTRIC2-A": "Guitar-electric2-a",
    "COSTUMES_GUITAR-ELECTRIC2-B": "Guitar-electric2-b",
    "COSTUMES_HANNAH-A": "Hannah-a",
    "COSTUMES_HANNAH-B": "Hannah-b",
    "COSTUMES_HANNAH-C": "Hannah-c",
    "COSTUMES_HARE-A": "Hare-a",
    "COSTUMES_HARE-B": "Hare-b",
    "COSTUMES_HARE-C": "Hare-c",
    "COSTUMES_HARPER-A": "Harper-a",
    "COSTUMES_HARPER-B": "Harper-b",
    "COSTUMES_HARPER-C": "Harper-c",
    "COSTUMES_HAT-A": "Hat-a",
    "COSTUMES_HAT-B": "Hat-b",
    "COSTUMES_HAT-C": "Hat-c",
    "COSTUMES_HAT-D": "Hat-d",
    "COSTUMES_HATCHLING-A": "Hatchling-a",
    "COSTUMES_HATCHLING-B": "Hatchling-b",
    "COSTUMES_HATCHLING-C": "Hatchling-c",
    "COSTUMES_HEART_CODE": "Heart Code",
    "COSTUMES_HEART_FACE": "Heart Face",
    "COSTUMES_HEART_LOVE": "Heart Love",
    "COSTUMES_HEART_PURPLE": "Heart Purple",
    "COSTUMES_HEART_RED": "Heart Red",
    "COSTUMES_HEART_SMILE": "Heart Smile",
    "COSTUMES_HEART_SWEET": "Heart Sweet",
    "COSTUMES_HEDGEHOG-A": "Hedgehog-a",
    "COSTUMES_HEDGEHOG-B": "Hedgehog-b",
    "COSTUMES_HEDGEHOG-C": "Hedgehog-c",
    "COSTUMES_HEDGEHOG-D": "Hedgehog-d",
    "COSTUMES_HEDGEHOG-E": "Hedgehog-e",
    "COSTUMES_HEN-A": "Hen-a",
    "COSTUMES_HEN-B": "Hen-b",
    "COSTUMES_HEN-C": "Hen-c",
    "COSTUMES_HEN-D": "Hen-d",
    "COSTUMES_HIPPO1-A": "Hippo1-a",
    "COSTUMES_HIPPO1-B": "Hippo1-b",
    "COSTUMES_HOME_BUTTON": "Home Button",
    "COSTUMES_HORSE-A": "Horse-a",
    "COSTUMES_HORSE-B": "Horse-b",
    "COSTUMES_JAIME_WALKING-A": "Jaime Walking-a",
    "COSTUMES_JAIME_WALKING-B": "Jaime Walking-b",
    "COSTUMES_JAIME_WALKING-C": "Jaime Walking-c",
    "COSTUMES_JAIME_WALKING-D": "Jaime Walking-d",
    "COSTUMES_JAIME_WALKING-E": "Jaime Walking-e",
    "COSTUMES_JAIME-A": "Jaime-a",
    "COSTUMES_JAIME-B": "Jaime-b",
    "COSTUMES_JAMAL-A": "Jamal-a",
    "COSTUMES_JAMAL-B": "Jamal-b",
    "COSTUMES_JAMAL-C": "Jamal-c",
    "COSTUMES_JAMAL-D": "Jamal-d",
    "COSTUMES_JAR-A": "Jar-a",
    "COSTUMES_JAR-B": "Jar-b",
    "COSTUMES_JELLYFISH-A": "Jellyfish-a",
    "COSTUMES_JELLYFISH-B": "Jellyfish-b",
    "COSTUMES_JELLYFISH-C": "Jellyfish-c",
    "COSTUMES_JELLYFISH-D": "Jellyfish-d",
    "COSTUMES_JO_POP_DOWN": "Jo Pop Down",
    "COSTUMES_JO_POP_FRONT": "Jo Pop Front",
    "COSTUMES_JO_POP_L_ARM": "Jo Pop L Arm",
    "COSTUMES_JO_POP_LEFT": "Jo Pop Left",
    "COSTUMES_JO_POP_R_ARM": "Jo Pop R Arm",
    "COSTUMES_JO_POP_RIGHT": "Jo Pop Right",
    "COSTUMES_JO_POP_STAND": "Jo Pop Stand",
    "COSTUMES_JO_STANCE": "Jo Stance",
    "COSTUMES_JO_TOP_L_CROSS": "Jo Top L Cross",
    "COSTUMES_JO_TOP_L_LEG": "Jo Top L Leg",
    "COSTUMES_JO_TOP_R_CROSS": "Jo Top R Cross",
    "COSTUMES_JO_TOP_R_LEG": "Jo Top R Leg",
    "COSTUMES_JO_TOP_STAND": "Jo Top Stand",
    "COSTUMES_JORDYN-A": "Jordyn-a",
    "COSTUMES_JORDYN-B": "Jordyn-b",
    "COSTUMES_JORDYN-C": "Jordyn-c",
    "COSTUMES_JORDYN-D": "Jordyn-d",
    "COSTUMES_KAI-A": "Kai-a",
    "COSTUMES_KAI-B": "Kai-b",
    "COSTUMES_KEY": "Key",
    "COSTUMES_KEYBOARD-A": "Keyboard-a",
    "COSTUMES_KEYBOARD-B": "Keyboard-b",
    "COSTUMES_KIRAN-A": "Kiran-a",
    "COSTUMES_KIRAN-B": "Kiran-b",
    "COSTUMES_KIRAN-C": "Kiran-c",
    "COSTUMES_KIRAN-D": "Kiran-d",
    "COSTUMES_KIRAN-E": "Kiran-e",
    "COSTUMES_KIRAN-F": "Kiran-f",
    "COSTUMES_KNIGHT": "Knight",
    "COSTUMES_LADYBUG2": "Ladybug1",
    "COSTUMES_LADYBUG2-A": "Ladybug2-a",
    "COSTUMES_LADYBUG2-B": "Ladybug2-b",
    "COSTUMES_LAPTOP": "Laptop",
    "COSTUMES_LB_POP_DOWN": "Lb Pop Down",
    "COSTUMES_LB_POP_FRONT": "Lb Pop Front",
    "COSTUMES_LB_POP_L_ARM": "Lb Pop L Arm",
    "COSTUMES_LB_POP_LEFT": "Lb Pop Left",
    "COSTUMES_LB_POP_R_ARM": "Lb Pop R Arm",
    "COSTUMES_LB_POP_RIGHT": "Lb Pop Right",
    "COSTUMES_LB_POP_STAND": "Lb Pop Stand",
    "COSTUMES_LB_STANCE": "lb Stance",
    "COSTUMES_LB_TOP_L_CROSS": "Lb Top L Cross",
    "COSTUMES_LB_TOP_L_LEG": "Lb Top L Leg",
    "COSTUMES_LB_TOP_R_CROSS": "Lb Top R Cross",
    "COSTUMES_LB_TOP_R_LEG": "Lb Top R Leg",
    "COSTUMES_LB_TOP_STAND": "Lb Top Stand",
    "COSTUMES_LIGHTNING": "Lightning",
    "COSTUMES_LINE": "Line",
    "COSTUMES_LION-A": "Lion-a",
    "COSTUMES_LION-B": "Lion-b",
    "COSTUMES_LION-C": "Lion-c",
    "COSTUMES_LLAMA": "Llama",
    "COSTUMES_LLAMA-B": "Llama-b",
    "COSTUMES_LLAMA-C": "Llama-c",
    "COSTUMES_MAGICWAND": "Magicwand",
    "COSTUMES_MAX-A": "Max-a",
    "COSTUMES_MAX-B": "Max-b",
    "COSTUMES_MAX-C": "Max-c",
    "COSTUMES_MAX-D": "Max-d",
    "COSTUMES_MERMAID-A": "Mermaid-a",
    "COSTUMES_MERMAID-B": "Mermaid-b",
    "COSTUMES_MERMAID-C": "Mermaid-c",
    "COSTUMES_MERMAID-D": "Mermaid-d",
    "COSTUMES_MICROPHONE-A": "Microphone-a",
    "COSTUMES_MICROPHONE-B": "Microphone-b",
    "COSTUMES_MILK-A": "Milk-a",
    "COSTUMES_MILK-B": "Milk-b",
    "COSTUMES_MILK-C": "Milk-c",
    "COSTUMES_MILK-D": "Milk-d",
    "COSTUMES_MILK-E": "Milk-e",
    "COSTUMES_MONET-A": "Monet-a",
    "COSTUMES_MONET-B": "Monet-b",
    "COSTUMES_MONET-C": "Monet-c",
    "COSTUMES_MONET-D": "Monet-d",
    "COSTUMES_MONET-E": "Monet-e",
    "COSTUMES_MONKEY-A": "Monkey-a",
    "COSTUMES_MONKEY-B": "Monkey-b",
    "COSTUMES_MONKEY-C": "Monkey-c",
    "COSTUMES_MOTORCYCLE-A": "Motorcycle-a",
    "COSTUMES_MOTORCYCLE-B": "Motorcycle-b",
    "COSTUMES_MOTORCYCLE-C": "Motorcycle-c",
    "COSTUMES_MOTORCYCLE-D": "Motorcycle-d",
    "COSTUMES_MOUSE1-A": "Mouse1-a",
    "COSTUMES_MOUSE1-B": "Mouse1-b",
    "COSTUMES_MUFFIN-A": "muffin-a",
    "COSTUMES_MUFFIN-B": "muffin-b",
    "COSTUMES_NEIGH_PONY": "Neigh Pony",
    "COSTUMES_OCTOPUS-A": "Octopus-a",
    "COSTUMES_OCTOPUS-B": "Octopus-b",
    "COSTUMES_OCTOPUS-C": "Octopus-c",
    "COSTUMES_OCTOPUS-D": "Octopus-d",
    "COSTUMES_OCTOPUS-E": "Octopus-e",
    "COSTUMES_ORANGE": "Orange",
    "COSTUMES_ORANGE2-A": "Orange2-a",
    "COSTUMES_ORANGE2-B": "Orange2-b",
    "COSTUMES_OUTFIELDER-A": "Outfielder-a",
    "COSTUMES_OUTFIELDER-B": "Outfielder-b",
    "COSTUMES_OUTFIELDER-C": "Outfielder-c",
    "COSTUMES_OUTFIELDER-D": "Outfielder-d",
    "COSTUMES_OWL-A": "Owl-a",
    "COSTUMES_OWL-B": "Owl-b",
    "COSTUMES_OWL-C": "Owl-c",
    "COSTUMES_PADDLE": "Paddle",
    "COSTUMES_PANTHER-A": "Panther-a",
    "COSTUMES_PANTHER-B": "Panther-b",
    "COSTUMES_PANTHER-C": "Panther-c",
    "COSTUMES_PANTS-A": "Pants-a",
    "COSTUMES_PANTS-B": "Pants-b",
    "COSTUMES_PARROT-A": "Parrot-a",
    "COSTUMES_PARROT-B": "Parrot-b",
    "COSTUMES_PARTY_HAT-A": "Party Hat-a",
    "COSTUMES_PARTY_HAT-B": "Party Hat-b",
    "COSTUMES_PARTY_HAT-E": "Party Hat-c",
    "COSTUMES_PENCIL-A": "Pencil-a",
    "COSTUMES_PENCIL-B": "Pencil-b",
    "COSTUMES_PENGUIN-A": "Penguin-a",
    "COSTUMES_PENGUIN-B": "Penguin-b",
    "COSTUMES_PENGUIN-C": "Penguin-c",
    "COSTUMES_PENGUIN2-A": "Penguin2-a",
    "COSTUMES_PENGUIN2-B": "Penguin2-b",
    "COSTUMES_PENGUIN2-C": "Penguin2-c",
    "COSTUMES_PENGUIN2-D": "Penguin2-d",
    "COSTUMES_PITCHER-A": "Pitcher-a",
    "COSTUMES_PITCHER-B": "Pitcher-b",
    "COSTUMES_PITCHER-C": "Pitcher-c",
    "COSTUMES_PITCHER-D": "Pitcher-d",
    "COSTUMES_PLANET2": "Planet2",
    "COSTUMES_POLAR_BEAR-A": "Polar Bear-a",
    "COSTUMES_POLAR_BEAR-B": "Polar Bear-b",
    "COSTUMES_POLAR_BEAR-C": "Polar Bear-c",
    "COSTUMES_POTION-A": "Potion-a",
    "COSTUMES_POTION-B": "Potion-b",
    "COSTUMES_POTION-C": "Potion-c",
    "COSTUMES_PRINCE": "Prince",
    "COSTUMES_PRINCESS-A": "Princess-a",
    "COSTUMES_PRINCESS-B": "Princess-b",
    "COSTUMES_PRINCESS-C": "Princess-c",
    "COSTUMES_PRINCESS-D": "Princess-d",
    "COSTUMES_PRINCESS-E": "Princess-e",
    "COSTUMES_PUFFERFISH-A": "Pufferfish-a",
    "COSTUMES_PUFFERFISH-B": "Pufferfish-b",
    "COSTUMES_PUFFERFISH-C": "Pufferfish-c",
    "COSTUMES_PUFFERFISH-D": "Pufferfish-d",
    "COSTUMES_PUPPY_BACK": "Puppy Back",
    "COSTUMES_PUPPY_RIGHT": "Puppy Right",
    "COSTUMES_PUPPY_SIDE": "Puppy Side",
    "COSTUMES_PUPPY_SIT": "Puppy Sit",
    "COSTUMES_RABBIT-A": "Rabbit-a",
    "COSTUMES_RABBIT-B": "Rabbit-b",
    "COSTUMES_RABBIT-C": "Rabbit-c",
    "COSTUMES_RABBIT-D": "Rabbit-d",
    "COSTUMES_RABBIT-E": "Rabbit-e",
    "COSTUMES_RADIO-A": "Radio-a",
    "COSTUMES_RADIO-B": "Radio-b",
    "COSTUMES_RAINBOW": "Rainbow",
    "COSTUMES_REFEREE-A": "referee-a",
    "COSTUMES_REFEREE-B": "referee-b",
    "COSTUMES_REFEREE-C": "referee-c",
    "COSTUMES_REFEREE-D": "referee-d",
    "COSTUMES_REINDEER": "reindeer",
    "COSTUMES_RETRO_ROBOT_A": "Retro Robot a",
    "COSTUMES_RETRO_ROBOT_B": "Retro Robot b",
    "COSTUMES_RETRO_ROBOT_C": "Retro Robot c",
    "COSTUMES_RIPLEY-A": "Ripley-a",
    "COSTUMES_RIPLEY-B": "Ripley-b",
    "COSTUMES_RIPLEY-C": "Ripley-c",
    "COSTUMES_RIPLEY-D": "Ripley-d",
    "COSTUMES_RIPLEY-E": "Ripley-e",
    "COSTUMES_RIPLEY-F": "Ripley-f",
    "COSTUMES_ROBOT-A": "Robot-a",
    "COSTUMES_ROBOT-B": "Robot-b",
    "COSTUMES_ROBOT-C": "Robot-c",
    "COSTUMES_ROBOT-D": "Robot-d",
    "COSTUMES_ROCKETSHIP-A": "Rocketship-a",
    "COSTUMES_ROCKETSHIP-B": "Rocketship-b",
    "COSTUMES_ROCKETSHIP-C": "Rocketship-c",
    "COSTUMES_ROCKETSHIP-D": "Rocketship-d",
    "COSTUMES_ROCKETSHIP-E": "Rocketship-e",
    "COSTUMES_ROCKS": "Rocks",
    "COSTUMES_ROOSTER-A": "Rooster-a",
    "COSTUMES_ROOSTER-B": "Rooster-b",
    "COSTUMES_ROOSTER-C": "Rooster-c",
    "COSTUMES_RUBY-A": "Ruby-a",
    "COSTUMES_RUBY-B": "Ruby-b",
    "COSTUMES_SAILBOAT": "Sailboat",
    "COSTUMES_SAM": "Sam",
    "COSTUMES_SAXOPHONE-A": "Saxophone-a",
    "COSTUMES_SAXOPHONE-B": "Saxophone-b",
    "COSTUMES_SCARF-A": "Scarf-a",
    "COSTUMES_SCARF-B": "Scarf-b",
    "COSTUMES_SCARF-C": "Scarf-c",
    "COSTUMES_SHARK-A": "Shark-a",
    "COSTUMES_SHARK-B": "Shark-b",
    "COSTUMES_SHARK2-A": "Shark2-a",
    "COSTUMES_SHARK2-B": "Shark2-b",
    "COSTUMES_SHARK2-C": "Shark2-c",
    "COSTUMES_SHIRT-A": "Shirt-a",
    "COSTUMES_SHOES-A": "Shoes-a",
    "COSTUMES_SHOES-B": "Shoes-b",
    "COSTUMES_SHOES-C": "Shoes-c",
    "COSTUMES_SHOES-D": "Shoes-d",
    "COSTUMES_SHORTS-A": "Shorts-a",
    "COSTUMES_SHORTS-B": "Shorts-b",
    "COSTUMES_SHORTS-C": "Shorts-c",
    "COSTUMES_SINGER1": "Singer1",
    "COSTUMES_SKELETON-A": "Skeleton-a",
    "COSTUMES_SKELETON-B": "Skeleton-b",
    "COSTUMES_SKELETON-D": "Skeleton-d",
    "COSTUMES_SKELETON-E": "Skeleton-e",
    "COSTUMES_SNAKE-A": "Snake-a",
    "COSTUMES_SNAKE-B": "Snake-b",
    "COSTUMES_SNAKE-C": "Snake-c",
    "COSTUMES_SNOWFLAKE": "Snowflake",
    "COSTUMES_SNOWMAN": "Snowman",
    "COSTUMES_SOCCER_BALL": "Soccer Ball",
    "COSTUMES_SPEAKER": "Speaker",
    "COSTUMES_SQUIRREL": "Squirrel",
    "COSTUMES_STAR": "Star",
    "COSTUMES_STARFISH-A": "Starfish-a",
    "COSTUMES_STARFISH-B_": "Starfish-b ",
    "COSTUMES_STOP": "Stop",
    "COSTUMES_STRAWBERRY-A": "Strawberry-a",
    "COSTUMES_STRAWBERRY-B": "Strawberry-b",
    "COSTUMES_STRAWBERRY-C": "Strawberry-c",
    "COSTUMES_STRAWBERRY-D": "Strawberry-d",
    "COSTUMES_STRAWBERRY-E": "Strawberry-e",
    "COSTUMES_SUN": "Sun",
    "COSTUMES_SUNGLASSES-A": "Sunglasses-a",
    "COSTUMES_SUNGLASSES-B": "Sunglasses-b",
    "COSTUMES_TABLA-A": "Tabla-a",
    "COSTUMES_TABLA-B": "Tabla-b",
    "COSTUMES_TACO": "Taco",
    "COSTUMES_TACO-WIZARD": "Taco-wizard",
    "COSTUMES_TAKEOUT-A": "Takeout-a",
    "COSTUMES_TAKEOUT-B": "Takeout-b",
    "COSTUMES_TAKEOUT-C": "Takeout-c",
    "COSTUMES_TAKEOUT-D": "Takeout-d",
    "COSTUMES_TAKEOUT-E": "Takeout-e",
    "COSTUMES_TEN80_POP_DOWN": "Ten80 Pop Down",
    "COSTUMES_TEN80_POP_FRONT": "Ten80 Pop Front",
    "COSTUMES_TEN80_POP_L_ARM": "Ten80 Pop L Arm",
    "COSTUMES_TEN80_POP_LEFT": "Ten80 Pop Left",
    "COSTUMES_TEN80_POP_R_ARM": "Ten80 Pop R Arm",
    "COSTUMES_TEN80_POP_RIGHT": "Ten80 Pop Right",
    "COSTUMES_TEN80_POP_STAND": "Ten80 Pop Stand",
    "COSTUMES_TEN80_STANCE": "Ten80 stance",
    "COSTUMES_TEN80_TOP_FREEZE": "Ten80 top freeze",
    "COSTUMES_TEN80_TOP_L_STEP": "Ten80 top L step",
    "COSTUMES_TEN80_TOP_R_CROSS": "Ten80 top R cross",
    "COSTUMES_TEN80_TOP_R_STEP": "Ten80 top R step",
    "COSTUMES_TEN80_TOP_STAND": "Ten80 top stand",
    "COSTUMES_TENNISBALL": "Tennisball",
    "COSTUMES_TOUCAN-A": "Toucan-a",
    "COSTUMES_TOUCAN-B": "Toucan-b",
    "COSTUMES_TOUCAN-C": "Toucan-c",
    "COSTUMES_TRAMPOLINE": "Trampoline",
    "COSTUMES_TREE1": "Tree1",
    "COSTUMES_TREES-A": "Trees-a",
    "COSTUMES_TREES-B": "Trees-b",
    "COSTUMES_TRUCK-A": "Truck-a",
    "COSTUMES_TRUCK-B": "Truck-b",
    "COSTUMES_TRUCK-C": "Truck-c",
    "COSTUMES_TRUMPET-A": "Trumpet-a",
    "COSTUMES_TRUMPET-B": "Trumpet-b",
    "COSTUMES_UNICORN": "Unicorn",
    "COSTUMES_UNICORN_2": "Unicorn 2",
    "COSTUMES_UNICORN_RUNNING-A": "Unicorn Running-a",
    "COSTUMES_UNICORN_RUNNING-B": "Unicorn Running-b",
    "COSTUMES_UNICORN_RUNNING-C": "Unicorn Running-c",
    "COSTUMES_UNICORN_RUNNING-D": "Unicorn Running-d",
    "COSTUMES_UNICORN_RUNNING-E": "Unicorn Running-e",
    "COSTUMES_UNICORN_RUNNING-F": "Unicorn Running-f",
    "COSTUMES_WAND": "Wand",
    "COSTUMES_WANDA": "Wanda",
    "COSTUMES_WATERMELON-A": "Watermelon-a",
    "COSTUMES_WATERMELON-B": "Watermelon-b",
    "COSTUMES_WATERMELON-C": "Watermelon-c",
    "COSTUMES_WINTER_HAT": "Winter Hat",
    "COSTUMES_WITCH": "Witch",
    "COSTUMES_WITCH-A": "Witch-a",
    "COSTUMES_WITCH-B": "Witch-b",
    "COSTUMES_WITCH-C": "Witch-c",
    "COSTUMES_WITCH-D": "Witch-d",
    "COSTUMES_WIZARD_HAT": "Wizard Hat",
    "COSTUMES_WIZARD-A": "Wizard-a",
    "COSTUMES_WIZARD-B": "Wizard-b",
    "COSTUMES_WIZARD-C": "Wizard-c",
    "COSTUMES_WIZARD-TOAD-A": "Wizard-toad-a",
    "COSTUMES_WIZARD-TOAD-B": "Wizard-toad-b",
    "COSTUMES_ZEBRA-A": "Zebra-a",
    "COSTUMES_ZEBRA-B": "Zebra-b",
    "COSTUMES_BLOCK-A": "Block-a",
    "COSTUMES_BLOCK-B": "Block-b",
    "COSTUMES_BLOCK-C": "Block-c",
    "COSTUMES_BLOCK-D": "Block-d",
    "COSTUMES_BLOCK-E": "Block-e",
    "COSTUMES_BLOCK-F": "Block-f",
    "COSTUMES_BLOCK-G": "Block-g",
    "COSTUMES_BLOCK-H": "Block-h",
    "COSTUMES_BLOCK-I": "Block-i",
    "COSTUMES_BLOCK-J": "Block-j",
    "COSTUMES_BLOCK-K": "Block-k",
    "COSTUMES_BLOCK-L": "Block-l",
    "COSTUMES_BLOCK-M": "Block-m",
    "COSTUMES_BLOCK-N": "Block-n",
    "COSTUMES_BLOCK-O": "Block-o",
    "COSTUMES_BLOCK-P": "Block-p",
    "COSTUMES_BLOCK-Q": "Block-q",
    "COSTUMES_BLOCK-R": "Block-r",
    "COSTUMES_BLOCK-S": "Block-s",
    "COSTUMES_BLOCK-T": "Block-t",
    "COSTUMES_BLOCK-U": "Block-u",
    "COSTUMES_BLOCK-V": "Block-v",
    "COSTUMES_BLOCK-W": "Block-w",
    "COSTUMES_BLOCK-X": "Block-x",
    "COSTUMES_BLOCK-Y": "Block-y",
    "COSTUMES_BLOCK-Z": "Block-z",
    "COSTUMES_GLOW-0": "Glow-0",
    "COSTUMES_GLOW-1": "Glow-1",
    "COSTUMES_GLOW-2": "Glow-2",
    "COSTUMES_GLOW-3": "Glow-3",
    "COSTUMES_GLOW-4": "Glow-4",
    "COSTUMES_GLOW-5": "Glow-5",
    "COSTUMES_GLOW-6": "Glow-6",
    "COSTUMES_GLOW-7": "Glow-7",
    "COSTUMES_GLOW-8": "Glow-8",
    "COSTUMES_GLOW-9": "Glow-9",
    "COSTUMES_GLOW-A": "Glow-A",
    "COSTUMES_GLOW-B": "Glow-B",
    "COSTUMES_GLOW-C": "Glow-C",
    "COSTUMES_GLOW-D": "Glow-D",
    "COSTUMES_GLOW-E": "Glow-E",
    "COSTUMES_GLOW-F": "Glow-F",
    "COSTUMES_GLOW-G": "Glow-G",
    "COSTUMES_GLOW-H": "Glow-H",
    "COSTUMES_GLOW-I": "Glow-I",
    "COSTUMES_GLOW-J": "Glow-J",
    "COSTUMES_GLOW-K": "Glow-K",
    "COSTUMES_GLOW-L": "Glow-L",
    "COSTUMES_GLOW-M": "Glow-M",
    "COSTUMES_GLOW-N": "Glow-N",
    "COSTUMES_GLOW-O": "Glow-O",
    "COSTUMES_GLOW-P": "Glow-P",
    "COSTUMES_GLOW-Q": "Glow-Q",
    "COSTUMES_GLOW-R": "Glow-R",
    "COSTUMES_GLOW-S": "Glow-S",
    "COSTUMES_GLOW-T": "Glow-T",
    "COSTUMES_GLOW-U": "Glow-U",
    "COSTUMES_GLOW-V": "Glow-V",
    "COSTUMES_GLOW-W": "Glow-W",
    "COSTUMES_GLOW-X": "Glow-X",
    "COSTUMES_GLOW-Y": "Glow-Y",
    "COSTUMES_GLOW-Z": "Glow-Z",
    "COSTUMES_STORY-A-1": "Story-A-1",
    "COSTUMES_STORY-A-2": "Story-A-2",
    "COSTUMES_STORY-A-3": "Story-A-3",
    "COSTUMES_STORY-B-1": "Story-B-1",
    "COSTUMES_STORY-B-2": "Story-B-2",
    "COSTUMES_STORY-B-3": "Story-B-3",
    "COSTUMES_STORY-C-1": "Story-C-1",
    "COSTUMES_STORY-C-2": "Story-C-2",
    "COSTUMES_STORY-C-3": "Story-C-3",
    "COSTUMES_STORY-D-1": "Story-D-1",
    "COSTUMES_STORY-D-2": "Story-D-2",
    "COSTUMES_STORY-D-3": "Story-D-3",
    "COSTUMES_STORY-E-1": "Story-E-1",
    "COSTUMES_STORY-E-2": "Story-E-2",
    "COSTUMES_STORY-E-3": "Story-E-3",
    "COSTUMES_STORY-F-1": "Story-F-1",
    "COSTUMES_STORY-F-2": "Story-F-2",
    "COSTUMES_STORY-F-3": "Story-F-3",
    "COSTUMES_STORY-G-1": "Story-G-1",
    "COSTUMES_STORY-G-2": "Story-G-2",
    "COSTUMES_STORY-G-3": "Story-G-3",
    "COSTUMES_STORY-H-1": "Story-H-1",
    "COSTUMES_STORY-H-2": "Story-H-2",
    "COSTUMES_STORY-H-3": "Story-H-3",
    "COSTUMES_STORY-I-1": "Story-I-1",
    "COSTUMES_STORY-I-2": "Story-I-2",
    "COSTUMES_STORY-I-3": "Story-I-3",
    "COSTUMES_STORY-J-1": "Story-J-1",
    "COSTUMES_STORY-J-2": "Story-J-2",
    "COSTUMES_STORY-J-3": "Story-J-3",
    "COSTUMES_STORY-K-1": "Story-K-1",
    "COSTUMES_STORY-K-2": "Story-K-2",
    "COSTUMES_STORY-K-3": "story-K-3",
    "COSTUMES_STORY-L-1": "Story-L-1",
    "COSTUMES_STORY-L-2": "Story-L-2",
    "COSTUMES_STORY-L-3": "Story-L-3",
    "COSTUMES_STORY-M-1": "Story-M-1",
    "COSTUMES_STORY-M-2": "Story-M-2",
    "COSTUMES_STORY-M-3": "Story-M-3",
    "COSTUMES_STORY-N-1": "Story-N-1",
    "COSTUMES_STORY-N-2": "Story-N-2",
    "COSTUMES_STORY-N-3": "Story-N-3",
    "COSTUMES_STORY-O-1": "Story-O-1",
    "COSTUMES_STORY-O-2": "Story-O-2",
    "COSTUMES_STORY-O-3": "Story-O-3",
    "COSTUMES_STORY-P-1": "Story-P-1",
    "COSTUMES_STORY-P-2": "Story-P-2",
    "COSTUMES_STORY-P-3": "Story-P-3",
    "COSTUMES_STORY-Q-1": "Story-Q-1",
    "COSTUMES_STORY-Q-2": "Story-Q-2",
    "COSTUMES_STORY-Q-3": "Story-Q-3",
    "COSTUMES_STORY-R-1": "Story-R-1",
    "COSTUMES_STORY-R-2": "Story-R-2",
    "COSTUMES_STORY-R-3": "Story-R-3",
    "COSTUMES_STORY-S-1": "Story-S-1",
    "COSTUMES_STORY-S-2": "Story-S-2",
    "COSTUMES_STORY-S-3": "Story-S-3",
    "COSTUMES_STORY-T-1": "Story-T-1",
    "COSTUMES_STORY-T-2": "Story-T-2",
    "COSTUMES_STORY-T-3": "Story-T-3",
    "COSTUMES_STORY-U-1": "Story-U-1",
    "COSTUMES_STORY-U-2": "Story-U-2",
    "COSTUMES_STORY-U-3": "Story-U-3",
    "COSTUMES_STORY-V-1": "Story-V-1",
    "COSTUMES_STORY-V-2": "Story-V-2",
    "COSTUMES_STORY-V-3": "Story-V-3",
    "COSTUMES_STORY-W-1": "Story-W-1",
    "COSTUMES_STORY-W-2": "Story-W-2",
    "COSTUMES_STORY-W-3": "Story-W-3",
    "COSTUMES_STORY-X-1": "Story-X-1",
    "COSTUMES_STORY-X-2": "Story-X-2",
    "COSTUMES_STORY-X-3": "Story-X-3",
    "COSTUMES_STORY-Y-1": "Story-Y-1",
    "COSTUMES_STORY-Y-2": "Story-Y-2",
    "COSTUMES_STORY-Y-3": "Story-Y-3",
    "COSTUMES_STORY-Z-1": "Story-Z-1",
    "COSTUMES_STORY-Z-2": "Story-Z-2",
    "COSTUMES_STORY-Z-3": "Story-Z-3",
    "SOUNDS_A_BASS": "A Bass",
    "SOUNDS_A_ELEC_BASS": "A Elec Bass",
    "SOUNDS_A_ELEC_GUITAR": "A Elec Guitar",
    "SOUNDS_A_ELEC_PIANO": "A Elec Piano",
    "SOUNDS_A_GUITAR": "A Guitar",
    "SOUNDS_A_MINOR_UKULELE": "A Minor Ukulele",
    "SOUNDS_A_PIANO": "A Piano",
    "SOUNDS_A_SAX": "A Sax",
    "SOUNDS_A_TROMBONE": "A Trombone",
    "SOUNDS_A_TRUMPET": "A Trumpet",
    "SOUNDS_AFRO_STRING": "Afro String",
    "SOUNDS_ALERT": "Alert",
    "SOUNDS_ALIEN_CREAK1": "Alien Creak1",
    "SOUNDS_ALIEN_CREAK2": "Alien Creak2",
    "SOUNDS_B_BASS": "B Bass",
    "SOUNDS_B_ELEC_BASS": "B Elec Bass",
    "SOUNDS_B_ELEC_GUITAR": "B Elec Guitar",
    "SOUNDS_B_ELEC_PIANO": "B Elec Piano",
    "SOUNDS_B_GUITAR": "B Guitar",
    "SOUNDS_B_PIANO": "B Piano",
    "SOUNDS_B_SAX": "B Sax",
    "SOUNDS_B_TROMBONE": "B Trombone",
    "SOUNDS_B_TRUMPET": "B Trumpet",
    "SOUNDS_BAA": "Baa",
    "SOUNDS_BARK": "Bark",
    "SOUNDS_BASKETBALL_BOUNCE": "Basketball Bounce",
    "SOUNDS_BASS_BEATBOX": "Bass Beatbox",
    "SOUNDS_BEAT_BOX1": "Beat Box1",
    "SOUNDS_BEAT_BOX2": "Beat Box2",
    "SOUNDS_BELL_CYMBAL": "Bell Cymbal",
    "SOUNDS_BELL_TOLL": "Bell Toll",
    "SOUNDS_BIG_BOING": "Big Boing",
    "SOUNDS_BIRD": "Bird",
    "SOUNDS_BIRTHDAY": "Birthday",
    "SOUNDS_BITE": "Bite",
    "SOUNDS_BOING": "Boing",
    "SOUNDS_BONK": "Bonk",
    "SOUNDS_BOOM_CLOUD": "Boom Cloud",
    "SOUNDS_BOOP_BING_BOP": "Boop Bing Bop",
    "SOUNDS_BOSSA_NOVA": "Bossa Nova",
    "SOUNDS_BOWLING_STRIKE": "Bowling Strike",
    "SOUNDS_BUBBLES": "Bubbles",
    "SOUNDS_BUZZ_WHIR": "Buzz Whir",
    "SOUNDS_C_BASS": "C Bass",
    "SOUNDS_C_ELEC_BASS": "C Elec Bass",
    "SOUNDS_C_ELEC_GUITAR": "C Elec Guitar",
    "SOUNDS_C_ELEC_PIANO": "C Elec Piano",
    "SOUNDS_C_GUITAR": "C Guitar",
    "SOUNDS_C_MAJOR_UKULELE": "C Major Ukulele",
    "SOUNDS_C_PIANO": "C Piano",
    "SOUNDS_C_SAX": "C Sax",
    "SOUNDS_C_TROMBONE": "C Trombone",
    "SOUNDS_C_TRUMPET": "C Trumpet",
    "SOUNDS_C2_BASS": "C2 Bass",
    "SOUNDS_C2_ELEC_BASS": "C2 Elec Bass",
    "SOUNDS_C2_ELEC_GUITAR": "C2 Elec Guitar",
    "SOUNDS_C2_ELEC_PIANO": "C2 Elec Piano",
    "SOUNDS_C2_GUITAR": "C2 guitar",
    "SOUNDS_C2_PIANO": "C2 Piano",
    "SOUNDS_C2_SAX": "C2 Sax",
    "SOUNDS_C2_TROMBONE": "C2 Trombone",
    "SOUNDS_C2_TRUMPET": "C2 Trumpet",
    "SOUNDS_CAR_HORN": "Car Horn",
    "SOUNDS_CAR_PASSING": "Car Passing",
    "SOUNDS_CAR_VROOM": "Car Vroom",
    "SOUNDS_CAVE": "Cave",
    "SOUNDS_CHATTER": "Chatter",
    "SOUNDS_CHEE_CHEE": "Chee Chee",
    "SOUNDS_CHEER": "Cheer",
    "SOUNDS_CHILL": "Chill",
    "SOUNDS_CHIRP": "Chirp",
    "SOUNDS_CHOMP": "Chomp",
    "SOUNDS_CHORD": "Chord",
    "SOUNDS_CLANG": "Clang",
    "SOUNDS_CLAP_BEATBOX": "Clap Beatbox",
    "SOUNDS_CLAPPING": "Clapping",
    "SOUNDS_CLASSICAL_PIANO": "Classical Piano",
    "SOUNDS_CLOCK_TICKING": "Clock Ticking",
    "SOUNDS_CLOWN_HONK": "Clown Honk",
    "SOUNDS_COIN": "Coin",
    "SOUNDS_COLLECT": "Collect",
    "SOUNDS_COMPUTER_BEEP": "Computer Beep1",
    "SOUNDS_COMPUTER_BEEP2": "Computer Beep2",
    "SOUNDS_CONNECT": "Connect",
    "SOUNDS_COUGH1": "Cough1",
    "SOUNDS_COUGH2": "Cough2",
    "SOUNDS_CRANK": "Crank",
    "SOUNDS_CRASH_BEATBOX": "Crash Beatbox",
    "SOUNDS_CRASH_CYMBAL": "crash cymbal",
    "SOUNDS_CRAZY_LAUGH": "Crazy Laugh",
    "SOUNDS_CRICKET": "Cricket",
    "SOUNDS_CRICKETS": "Crickets",
    "SOUNDS_CROAK": "Croak",
    "SOUNDS_CROWD_GASP": "Crowd Gasp",
    "SOUNDS_CROWD_LAUGH": "Crowd Laugh",
    "SOUNDS_CRUNCH": "Crunch",
    "SOUNDS_CYMBAL": "Cymbal",
    "SOUNDS_CYMBAL_CRASH": "Cymbal Crash",
    "SOUNDS_CYMBAL_ECHO": "Cymbal Echo",
    "SOUNDS_D_BASS": "D Bass",
    "SOUNDS_D_ELEC_BASS": "D Elec Bass",
    "SOUNDS_D_ELEC_GUITAR": "D Elec Guitar",
    "SOUNDS_D_ELEC_PIANO": "D Elec Piano",
    "SOUNDS_D_GUITAR": "D Guitar",
    "SOUNDS_D_PIANO": "D Piano",
    "SOUNDS_D_SAX": "D Sax",
    "SOUNDS_D_TROMBONE": "D Trombone",
    "SOUNDS_D_TRUMPET": "D Trumpet",
    "SOUNDS_DANCE_AROUND": "Dance Around",
    "SOUNDS_DANCE_CELEBRATE": "Dance Celebrate",
    "SOUNDS_DANCE_CELEBRATE2": "Dance Celebrate2",
    "SOUNDS_DANCE_CHILL_OUT": "Dance Chill Out",
    "SOUNDS_DANCE_ENERGETIC": "Dance Energetic",
    "SOUNDS_DANCE_FUNKY": "Dance Funky",
    "SOUNDS_DANCE_HEAD_NOD": "Dance Head Nod",
    "SOUNDS_DANCE_MAGIC": "Dance Magic",
    "SOUNDS_DANCE_SITAR": "Dance Sitar",
    "SOUNDS_DANCE_SLOW_MO": "Dance Slow Mo",
    "SOUNDS_DANCE_SNARE_BEAT": "Dance Snare Beat",
    "SOUNDS_DANCE_SPACE": "Dance Space",
    "SOUNDS_DISCONNECT": "Disconnect",
    "SOUNDS_DOG1": "Dog",
    "SOUNDS_DOG2": "Dog2",
    "SOUNDS_DOOR_CLOSING": "Door Closing",
    "SOUNDS_DOOR_CREAK": "Door Creak",
    "SOUNDS_DOORBELL": "Doorbell",
    "SOUNDS_DRIP_DROP": "Drip Drop",
    "SOUNDS_DRIVE_AROUND": "Drive Around",
    "SOUNDS_DRUM": "Drum",
    "SOUNDS_DRUM_BASS1": "Drum Bass1",
    "SOUNDS_DRUM_BASS2": "Drum Bass2",
    "SOUNDS_DRUM_BASS3": "Drum Bass3",
    "SOUNDS_DRUM_BOING": "Drum Boing",
    "SOUNDS_DRUM_BUZZ": "Drum Buzz",
    "SOUNDS_DRUM_FUNKY": "Drum Funky",
    "SOUNDS_DRUM_JAM": "Drum Jam",
    "SOUNDS_DRUM_MACHINE": "Drum Machine",
    "SOUNDS_DRUM_ROLL": "Drum Roll",
    "SOUNDS_DRUM_SATELLITE": "Drum Satellite",
    "SOUNDS_DRUM_SET1": "Drum Set1",
    "SOUNDS_DRUM_SET2": "Drum Set2",
    "SOUNDS_DUBSTEP": "Dubstep",
    "SOUNDS_DUCK": "Duck",
    "SOUNDS_DUN_DUN_DUNNN": "Dun Dun Dunnn",
    "SOUNDS_E_BASS": "E Bass",
    "SOUNDS_E_ELEC_BASS": "E Elec Bass",
    "SOUNDS_E_ELEC_GUITAR": "E Elec Guitar",
    "SOUNDS_E_ELEC_PIANO": "E Elec Piano",
    "SOUNDS_E_GUITAR": "E Guitar",
    "SOUNDS_E_PIANO": "E Piano",
    "SOUNDS_E_SAX": "E Sax",
    "SOUNDS_E_TROMBONE": "E Trombone",
    "SOUNDS_E_TRUMPET": "E Trumpet",
    "SOUNDS_EGGS": "Eggs",
    "SOUNDS_ELEC_PIANO_A_MINOR": "Elec Piano A Minor",
    "SOUNDS_ELEC_PIANO_C_MAJOR": "Elec Piano C Major",
    "SOUNDS_ELEC_PIANO_F_MAJOR": "Elec Piano F Major",
    "SOUNDS_ELEC_PIANO_G_MAJOR": "Elec Piano G Major",
    "SOUNDS_ELEC_PIANO_LOOP": "Elec Piano Loop",
    "SOUNDS_EMOTIONAL_PIANO": "Emotional Piano",
    "SOUNDS_ENGINE": "Engine",
    "SOUNDS_F_BASS": "F Bass",
    "SOUNDS_F_ELEC_BASS": "F Elec Bass",
    "SOUNDS_F_ELEC_GUITAR": "F Elec Guitar",
    "SOUNDS_F_ELEC_PIANO": "F Elec Piano",
    "SOUNDS_F_GUITAR": "F Guitar",
    "SOUNDS_F_MAJOR_UKULELE": "F Major Ukulele",
    "SOUNDS_F_PIANO": "F Piano",
    "SOUNDS_F_SAX": "F Sax",
    "SOUNDS_F_TROMBONE": "F Trombone",
    "SOUNDS_F_TRUMPET": "F Trumpet",
    "SOUNDS_FAIRYDUST": "Fairydust",
    "SOUNDS_FINGER_SNAP": "Finger Snap",
    "SOUNDS_FLAM_SNARE": "Flam Snare",
    "SOUNDS_FOOTSTEPS": "Footsteps",
    "SOUNDS_G_BASS": "G Bass",
    "SOUNDS_G_ELEC_BASS": "G Elec Bass",
    "SOUNDS_G_ELEC_GUITAR": "G Elec Guitar",
    "SOUNDS_G_ELEC_PIANO": "G Elec Piano",
    "SOUNDS_G_GUITAR": "G Guitar",
    "SOUNDS_G_PIANO": "G Piano",
    "SOUNDS_G_SAX": "G Sax",
    "SOUNDS_G_TROMBONE": "G Trombone",
    "SOUNDS_G_TRUMPET": "G Trumpet",
    "SOUNDS_G_UKULELE": "G Ukulele",
    "SOUNDS_GALLOP": "Gallop",
    "SOUNDS_GARDEN": "Garden",
    "SOUNDS_GLASS_BREAKING": "Glass Breaking",
    "SOUNDS_GLUG": "Glug",
    "SOUNDS_GOAL_CHEER": "Goal Cheer",
    "SOUNDS_GONG": "Gong",
    "SOUNDS_GOOSE": "Goose",
    "SOUNDS_GROWL": "Growl",
    "SOUNDS_GRUNT": "Grunt",
    "SOUNDS_GUITAR_CHORDS1": "Guitar Chords1",
    "SOUNDS_GUITAR_CHORDS2": "Guitar Chords2",
    "SOUNDS_GUITAR_STRUM": "Guitar Strum",
    "SOUNDS_HAND_CLAP": "Hand Clap",
    "SOUNDS_HEAD_SHAKE": "Head Shake",
    "SOUNDS_HEY": "Hey",
    "SOUNDS_HI_BEATBOX": "Hi Beatbox",
    "SOUNDS_HI_NA_TABLA": "Hi Na Tabla",
    "SOUNDS_HI_TUN_TABLA": "Hi Tun Tabla",
    "SOUNDS_HIGH_CONGA": "High Conga",
    "SOUNDS_HIGH_HAT": "High Hat",
    "SOUNDS_HIGH_TOM": "High Tom",
    "SOUNDS_HIGH_WHOOSH": "High Whoosh",
    "SOUNDS_HIHAT_BEATBOX": "Hihat Beatbox",
    "SOUNDS_HIHAT_CYMBAL": "Hihat Cymbal",
    "SOUNDS_HIP_HOP": "Hip Hop",
    "SOUNDS_HORSE": "Horse",
    "SOUNDS_HORSE_GALLOP": "horse gallop",
    "SOUNDS_HUMAN_BEATBOX1": "Human Beatbox1",
    "SOUNDS_HUMAN_BEATBOX2": "Human Beatbox2",
    "SOUNDS_JUMP": "Jump",
    "SOUNDS_JUNGLE": "Jungle",
    "SOUNDS_JUNGLE_FROGS": "Jungle Frogs",
    "SOUNDS_KICK_BACK": "Kick Back",
    "SOUNDS_KICK_DRUM": "Kick Drum",
    "SOUNDS_LARGE_COWBELL": "Large Cowbell",
    "SOUNDS_LASER1": "laser1",
    "SOUNDS_LASER2": "laser2",
    "SOUNDS_LAUGH1": "Laugh1",
    "SOUNDS_LAUGH2": "Laugh2",
    "SOUNDS_LAUGH3": "Laugh3",
    "SOUNDS_LO_GEH_TABLA": "Lo Geh Tabla",
    "SOUNDS_LO_GLISS_TABLA": "Lo Gliss Tabla",
    "SOUNDS_LOSE": "Lose",
    "SOUNDS_LOW_BOING": "Low Boing",
    "SOUNDS_LOW_CONGA": "Low Conga",
    "SOUNDS_LOW_SQUEAK": "Low Squeak",
    "SOUNDS_LOW_TOM": "Low Tom",
    "SOUNDS_LOW_WHOOSH": "Low Whoosh",
    "SOUNDS_MACHINE": "Machine",
    "SOUNDS_MAGIC_SPELL": "Magic Spell",
    "SOUNDS_MEDIEVAL1": "Medieval1",
    "SOUNDS_MEDIEVAL2": "Medieval2",
    "SOUNDS_MEOW": "Meow",
    "SOUNDS_MEOW2": "meow2",
    "SOUNDS_MOO": "Moo",
    "SOUNDS_MOTORCYCLE_PASSING": "Motorcycle Passing",
    "SOUNDS_MOVIE_1": "Movie 1",
    "SOUNDS_MOVIE_2": "Movie 2",
    "SOUNDS_MUTED_CONGA": "Muted Conga",
    "SOUNDS_MYSTERY": "Mystery",
    "SOUNDS_OCEAN_WAVE": "Ocean Wave",
    "SOUNDS_ODESONG": "Odesong",
    "SOUNDS_OOPS": "Oops",
    "SOUNDS_ORCHESTRA_TUNING": "Orchestra Tuning",
    "SOUNDS_OWL": "Owl",
    "SOUNDS_PARTY_NOISE": "Party Noise",
    "SOUNDS_PEW": "Pew",
    "SOUNDS_PING_PONG_HIT": "Ping Pong Hit",
    "SOUNDS_PLUCK": "Pluck",
    "SOUNDS_PLUNGE": "Plunge",
    "SOUNDS_POLICE_SIREN": "Police Siren",
    "SOUNDS_POP": "Pop",
    "SOUNDS_RAIN": "Rain",
    "SOUNDS_RATTLE": "Rattle",
    "SOUNDS_REFEREE_WHISTLE": "Referee Whistle",
    "SOUNDS_REGGAE": "Reggae",
    "SOUNDS_RICOCHET": "Ricochet",
    "SOUNDS_RIDE_CYMBAL": "Ride Cymbal",
    "SOUNDS_RING_TONE": "Ring Tone",
    "SOUNDS_RIP": "Rip",
    "SOUNDS_RIPPLES": "Ripples",
    "SOUNDS_ROLL_CYMBAL": "Roll Cymbal",
    "SOUNDS_ROOSTER": "Rooster",
    "SOUNDS_SCRAMBLING_FEET": "Scrambling Feet",
    "SOUNDS_SCRATCH_BEATBOX": "scratch beatbox",
    "SOUNDS_SCRATCHY_BEAT": "Scratchy Beat",
    "SOUNDS_SCREAM1": "Scream1",
    "SOUNDS_SCREAM2": "Scream2",
    "SOUNDS_SCREECH": "Screech",
    "SOUNDS_SEAGULLS": "Seagulls",
    "SOUNDS_SEWING_MACHINE": "Sewing Machine",
    "SOUNDS_SHAKER": "Shaker",
    "SOUNDS_SHIP_BELL": "Ship Bell",
    "SOUNDS_SIDESTICK_SNARE": "Sidestick Snare",
    "SOUNDS_SINGER1": "Singer1",
    "SOUNDS_SINGER2": "Singer2",
    "SOUNDS_SIREN_WHISTLE": "Siren Whistle",
    "SOUNDS_SKID": "Skid",
    "SOUNDS_SLIDE_WHISTLE": "Slide Whistle",
    "SOUNDS_SMALL_COWBELL": "Small Cowbell",
    "SOUNDS_SNAP": "Snap",
    "SOUNDS_SNARE_BEATBOX": "Snare Beatbox",
    "SOUNDS_SNARE_BEATBOX2": "Snare Beatbox2",
    "SOUNDS_SNARE_DRUM": "Snare Drum",
    "SOUNDS_SNEAKER_SQUEAK": "Sneaker Squeak",
    "SOUNDS_SNEEZE1": "Sneeze1",
    "SOUNDS_SNEEZE2": "Sneeze2",
    "SOUNDS_SNORING": "Snoring",
    "SOUNDS_SNORT": "Snort",
    "SOUNDS_SPACE_AMBIENCE": "Space Ambience",
    "SOUNDS_SPACE_FLYBY": "Space Flyby",
    "SOUNDS_SPACE_NOISE": "Space Noise",
    "SOUNDS_SPACE_RIPPLE": "Space Ripple",
    "SOUNDS_SPIRAL": "Spiral",
    "SOUNDS_SPLASH": "Splash",
    "SOUNDS_SPLASH_CYMBAL": "Splash Cymbal",
    "SOUNDS_SPOOKY_STRING": "Spooky String",
    "SOUNDS_SQUAWK": "Squawk",
    "SOUNDS_SQUEAKS": "Squeaks",
    "SOUNDS_SQUEAKY_TOY": "Squeaky Toy",
    "SOUNDS_SQUISH_POP": "Squish Pop",
    "SOUNDS_STRING_ACCENT": "String Accent",
    "SOUNDS_STRING_PLUCK": "String Pluck",
    "SOUNDS_SUCTION_CUP": "Suction Cup",
    "SOUNDS_SUSPENSE": "Suspense",
    "SOUNDS_TADA": "Tada",
    "SOUNDS_TAMBURA": "Tambura",
    "SOUNDS_TAP_CONGA": "Tap Conga",
    "SOUNDS_TAP_SNARE": "Tap Snare",
    "SOUNDS_TECHNO": "Techno",
    "SOUNDS_TECHNO2": "Techno2",
    "SOUNDS_TELEPHONE_RING": "Telephone Ring",
    "SOUNDS_TELEPHONE_RING2": "Telephone Ring2",
    "SOUNDS_TELEPORT": "Teleport",
    "SOUNDS_TELEPORT2": "Teleport2",
    "SOUNDS_TELEPORT3": "Teleport3",
    "SOUNDS_TENNIS_HIT": "Tennis Hit",
    "SOUNDS_THUNDER_STORM": "Thunder Storm",
    "SOUNDS_TOM_DRUM": "Tom Drum",
    "SOUNDS_TOY_HONK": "Toy Honk",
    "SOUNDS_TOY_ZING": "Toy Zing",
    "SOUNDS_TRAFFIC": "Traffic",
    "SOUNDS_TRAIN_WHISTLE": "Train Whistle",
    "SOUNDS_TRAP_BEAT": "Trap Beat",
    "SOUNDS_TRIUMPH": "Triumph",
    "SOUNDS_TROPICAL_BIRDS": "Tropical Birds",
    "SOUNDS_TRUMPET1": "Trumpet1",
    "SOUNDS_TRUMPET2": "Trumpet2",
    "SOUNDS_VIDEO_GAME_1": "Video Game 1",
    "SOUNDS_VIDEO_GAME_2": "Video Game 2",
    "SOUNDS_WAH_BEATBOX": "Wah Beatbox",
    "SOUNDS_WAND": "Wand",
    "SOUNDS_WATER_DROP": "Water drop",
    "SOUNDS_WHINNY": "Whinny",
    "SOUNDS_WHISTLE_THUMP": "Whistle Thump",
    "SOUNDS_WHIZ": "Whiz",
    "SOUNDS_WHOOP": "Whoop",
    "SOUNDS_WIN": "Win",
    "SOUNDS_WOBBLE": "Wobble",
    "SOUNDS_WOLF_HOWL": "Wolf Howl",
    "SOUNDS_WOOD_TAP": "Wood Tap",
    "SOUNDS_WUB_BEATBOX": "Wub Beatbox",
    "SOUNDS_XYLO1": "Xylo1",
    "SOUNDS_XYLO2": "Xylo2",
    "SOUNDS_XYLO3": "Xylo3",
    "SOUNDS_XYLO4": "Xylo4",
    "SOUNDS_YA": "Ya",
    "SOUNDS_ZIP": "Zip",
    "SOUNDS_ZOOP": "Zoop",
    "SPRITES_ABBY": "Abby",
    "SPRITES_AMON": "Amon",
    "SPRITES_ANDIE": "Andie",
    "SPRITES_ANINA_DANCE": "Anina Dance",
    "SPRITES_APPLE": "Apple",
    "SPRITES_ARROW1": "Arrow1",
    "SPRITES_AVERY": "Avery",
    "SPRITES_AVERY_WALKING": "Avery Walking",
    "SPRITES_BALL": "Ball",
    "SPRITES_BALLERINA": "Ballerina",
    "SPRITES_BALLOON1": "Balloon1",
    "SPRITES_BANANAS": "Bananas",
    "SPRITES_BASEBALL": "Baseball",
    "SPRITES_BASKETBALL": "Basketball",
    "SPRITES_BAT": "Bat",
    "SPRITES_BATTER": "Batter",
    "SPRITES_BEACHBALL": "Beachball",
    "SPRITES_BEAR": "Bear",
    "SPRITES_BEAR-WALKING": "Bear-walking",
    "SPRITES_BEETLE": "Beetle",
    "SPRITES_BELL": "Bell",
    "SPRITES_BEN": "Ben",
    "SPRITES_BOWL": "Bowl",
    "SPRITES_BOWTIE": "Bowtie",
    "SPRITES_BREAD": "Bread",
    "SPRITES_BROOM": "Broom",
    "SPRITES_BUILDINGS": "Buildings",
    "SPRITES_BUTTERFLY_1": "Butterfly 1",
    "SPRITES_BUTTERFLY_2": "Butterfly 2",
    "SPRITES_BUTTON1": "Button1",
    "SPRITES_BUTTON2": "Button2",
    "SPRITES_BUTTON3": "Button3",
    "SPRITES_BUTTON4": "Button4",
    "SPRITES_BUTTON5": "Button5",
    "SPRITES_CAKE": "Cake",
    "SPRITES_CALVRETT": "Calvrett",
    "SPRITES_CASEY": "Casey",
    "SPRITES_CASSY_DANCE": "Cassy Dance",
    "SPRITES_CAT_2": "Cat 2",
    "SPRITES_CATCHER": "Catcher",
    "SPRITES_CENTAUR": "Centaur",
    "SPRITES_CHAMP99": "Champ99",
    "SPRITES_CHEESY_PUFFS": "Cheesy Puffs",
    "SPRITES_CHICK": "Chick",
    "SPRITES_CITY_BUS": "City Bus",
    "SPRITES_CLOUD": "Cloud",
    "SPRITES_CLOUDS": "Clouds",
    "SPRITES_CONVERTIBLE": "Convertible",
    "SPRITES_CONVERTIBLE_2": "Convertible 2",
    "SPRITES_CRAB": "Crab",
    "SPRITES_CRYSTAL": "Crystal",
    "SPRITES_D-MONEY_DANCE": "D-Money Dance",
    "SPRITES_DAN": "Dan",
    "SPRITES_DANI": "Dani",
    "SPRITES_DEE": "Dee",
    "SPRITES_DEVIN": "Devin",
    "SPRITES_DINOSAUR1": "Dinosaur1",
    "SPRITES_DINOSAUR2": "Dinosaur2",
    "SPRITES_DINOSAUR3": "Dinosaur3",
    "SPRITES_DINOSAUR4": "Dinosaur4",
    "SPRITES_DINOSAUR5": "Dinosaur5",
    "COSTUMES_DINOSAUR5-A": "Dinosaur5-a",
    "COSTUMES_DINOSAUR5-B": "Dinosaur5-b",
    "COSTUMES_DINOSAUR5-C": "Dinosaur5-c",
    "COSTUMES_DINOSAUR5-D": "Dinosaur5-d",
    "COSTUMES_DINOSAUR5-E": "Dinosaur5-e",
    "COSTUMES_DINOSAUR5-F": "Dinosaur5-f",
    "COSTUMES_DINOSAUR5-G": "Dinosaur5-g",
    "COSTUMES_DINOSAUR5-H": "Dinosaur5-h",
    "SPRITES_DIVER1": "Diver1",
    "SPRITES_DIVER2": "Diver2",
    "SPRITES_DOG1": "Dog1",
    "SPRITES_DOG2": "Dog2",
    "SPRITES_DONUT": "Donut",
    "SPRITES_DORIAN": "Dorian",
    "SPRITES_DOT": "Dot",
    "SPRITES_DOVE": "Dove",
    "SPRITES_DRAGON": "Dragon",
    "SPRITES_DRAGONFLY": "Dragonfly",
    "SPRITES_DRESS": "Dress",
    "SPRITES_DRUM": "Drum",
    "SPRITES_DRUM_KIT": "Drum Kit",
    "SPRITES_DRUM-CYMBAL": "Drum-cymbal",
    "SPRITES_DRUM-HIGHHAT": "Drum-highhat",
    "SPRITES_DRUM-SNARE": "Drum-snare",
    "SPRITES_DRUMS_CONGA": "Drums Conga",
    "SPRITES_DRUMS_TABLA": "Drums Tabla",
    "SPRITES_DUCK": "Duck",
    "SPRITES_EARTH": "Earth",
    "SPRITES_EASEL": "Easel",
    "SPRITES_EGG": "Egg",
    "SPRITES_ELEPHANT": "Elephant",
    "SPRITES_ELF": "Elf",
    "SPRITES_FAIRY": "Fairy",
    "SPRITES_FISH": "Fish",
    "SPRITES_FISHBOWL": "Fishbowl",
    "SPRITES_FOOD_TRUCK": "Food Truck",
    "SPRITES_FOOTBALL": "Football",
    "SPRITES_FORTUNE_COOKIE": "Fortune Cookie",
    "SPRITES_FOX": "Fox",
    "SPRITES_FRANK": "Frank",
    "SPRITES_FROG": "Frog",
    "SPRITES_FROG_2_": "Frog 2 ",
    "SPRITES_FRUIT_PLATTER": "Fruit Platter",
    "SPRITES_FRUIT_SALAD": "Fruit Salad",
    "SPRITES_GHOST": "Ghost",
    "SPRITES_GIFT": "Gift",
    "SPRITES_GIRAFFE": "Giraffe",
    "SPRITES_GLASS_WATER": "Glass Water",
    "SPRITES_GLASSES": "Glasses",
    "SPRITES_GOALIE": "Goalie",
    "SPRITES_GOBLIN": "Goblin",
    "SPRITES_GRASSHOPPER": "Grasshopper",
    "SPRITES_GREEN_FLAG": "Green Flag",
    "SPRITES_GRIFFIN": "Griffin",
    "SPRITES_GUITAR": "Guitar",
    "SPRITES_GUITAR-ELECTRIC1": "Guitar-electric1",
    "SPRITES_GUITAR-ELECTRIC2": "Guitar-electric2",
    "SPRITES_HANNAH": "Hannah",
    "SPRITES_HARE": "Hare",
    "SPRITES_HARPER": "Harper",
    "SPRITES_HAT1_": "Hat1 ",
    "SPRITES_HATCHLING": "Hatchling",
    "SPRITES_HEART": "Heart",
    "SPRITES_HEART_CANDY": "Heart Candy",
    "SPRITES_HEART_FACE": "Heart Face",
    "SPRITES_HEDGEHOG": "Hedgehog",
    "SPRITES_HEN": "Hen",
    "SPRITES_HIPPO1": "Hippo1",
    "SPRITES_HOME_BUTTON": "Home Button",
    "SPRITES_HORSE": "Horse",
    "SPRITES_JAIME": "Jaime",
    "SPRITES_JAMAL": "Jamal",
    "SPRITES_JAR": "Jar",
    "SPRITES_JELLYFISH": "Jellyfish",
    "SPRITES_JORDYN": "Jordyn",
    "SPRITES_JOUVI_DANCE": "Jouvi Dance",
    "SPRITES_KAI": "Kai",
    "SPRITES_KEY": "Key",
    "SPRITES_KEYBOARD": "Keyboard",
    "SPRITES_KIRAN": "Kiran",
    "SPRITES_KNIGHT": "Knight",
    "SPRITES_LB_DANCE": "LB Dance",
    "SPRITES_LADYBUG1": "Ladybug1",
    "SPRITES_LADYBUG2": "Ladybug2",
    "SPRITES_LAPTOP": "Laptop",
    "SPRITES_LIGHTNING": "Lightning",
    "SPRITES_LINE": "Line",
    "SPRITES_LION": "Lion",
    "SPRITES_LLAMA": "Llama",
    "SPRITES_MAGIC_WAND": "Magic Wand",
    "SPRITES_MAX": "Max",
    "SPRITES_MERMAID": "Mermaid",
    "SPRITES_MICROPHONE": "Microphone",
    "SPRITES_MILK": "Milk",
    "SPRITES_MIND+": "Mind+",
    "COSTUMES_MIND+1": "Mind+1",
    "SPRITES_MONET": "Monet",
    "SPRITES_MONKEY": "Monkey",
    "SPRITES_MOTORCYCLE": "Motorcycle",
    "SPRITES_MOUSE1": "Mouse1",
    "SPRITES_MUFFIN": "Muffin",
    "SPRITES_NEIGH_PONY": "Neigh Pony",
    "SPRITES_OCTOPUS": "Octopus",
    "SPRITES_ORANGE": "Orange",
    "SPRITES_ORANGE2": "Orange2",
    "SPRITES_OUTFIELDER": "Outfielder",
    "SPRITES_OWL": "Owl",
    "SPRITES_PADDLE": "Paddle",
    "SPRITES_PANTHER": "Panther",
    "SPRITES_PANTS": "Pants",
    "SPRITES_PARROT": "Parrot",
    "SPRITES_PARTY_HATS": "Party Hats",
    "SPRITES_PENCIL": "Pencil",
    "SPRITES_PENGUIN": "Penguin",
    "SPRITES_PENGUIN_2": "Penguin 2",
    "SPRITES_PITCHER": "Pitcher",
    "SPRITES_PLANET2": "Planet2",
    "SPRITES_POLAR_BEAR": "Polar Bear",
    "SPRITES_POTION": "Potion",
    "SPRITES_PRINCE": "Prince",
    "SPRITES_PRINCESS": "Princess",
    "SPRITES_PUFFERFISH": "Pufferfish",
    "SPRITES_PUPPY": "Puppy",
    "SPRITES_RABBIT": "Rabbit",
    "SPRITES_RADIO": "Radio",
    "SPRITES_RAINBOW": "Rainbow",
    "SPRITES_REFEREE": "Referee",
    "SPRITES_REINDEER": "Reindeer",
    "SPRITES_RETRO_ROBOT": "Retro Robot",
    "SOUNDS_COMPUTER_BEEPS1": "computer beeps1",
    "SOUNDS_COMPUTER_BEEPS2": "computer beeps2",
    "SPRITES_RIPLEY": "Ripley",
    "SPRITES_ROBOT": "Robot",
    "SPRITES_ROCKETSHIP": "Rocketship",
    "SPRITES_ROCKS": "Rocks",
    "SPRITES_ROOSTER": "Rooster",
    "SPRITES_RUBY": "Ruby",
    "SPRITES_SAILBOAT": "Sailboat",
    "SPRITES_SAM": "Sam",
    "SPRITES_SAXOPHONE": "Saxophone",
    "SPRITES_SCARF": "Scarf",
    "SPRITES_SHARK": "Shark",
    "SPRITES_SHARK_2": "Shark 2",
    "SPRITES_SHIRT": "Shirt",
    "SPRITES_SHOES": "Shoes",
    "SPRITES_SHORTS": "Shorts",
    "SPRITES_SINGER1": "Singer1",
    "SPRITES_SKELETON": "Skeleton",
    "SPRITES_SNAKE": "Snake",
    "SPRITES_SNOWFLAKE": "Snowflake",
    "SPRITES_SNOWMAN": "Snowman",
    "SPRITES_SOCCER_BALL": "Soccer Ball",
    "SPRITES_SPEAKER": "Speaker",
    "SPRITES_SQUIRREL": "Squirrel",
    "SPRITES_STAR": "Star",
    "SPRITES_STARFISH": "Starfish",
    "SPRITES_STOP": "Stop",
    "SPRITES_STRAWBERRY": "Strawberry",
    "SPRITES_SUN": "Sun",
    "SPRITES_SUNGLASSES1": "Sunglasses1",
    "SPRITES_TACO": "Taco",
    "SPRITES_TAKEOUT": "Takeout",
    "SPRITES_TEN80_DANCE": "Ten80 Dance",
    "SPRITES_TENNIS_BALL": "Tennis Ball",
    "SPRITES_TOUCAN": "Toucan",
    "SPRITES_TRAMPOLINE": "Trampoline",
    "SPRITES_TREE1": "Tree1",
    "SPRITES_TREES": "Trees",
    "SPRITES_TRUCK": "Truck",
    "SPRITES_TRUMPET": "Trumpet",
    "SPRITES_UNICORN": "Unicorn",
    "SPRITES_UNICORN_2": "Unicorn 2",
    "SPRITES_UNICORN_RUNNING": "Unicorn Running",
    "SPRITES_WAND": "Wand",
    "SPRITES_WANDA": "Wanda",
    "SPRITES_WATERMELON": "Watermelon",
    "SPRITES_WINTER_HAT": "Winter Hat",
    "SPRITES_WITCH": "Witch",
    "SPRITES_WIZARD": "Wizard",
    "SPRITES_WIZARD_HAT": "Wizard Hat",
    "SPRITES_WIZARD-TOAD": "Wizard-toad",
    "SPRITES_ZEBRA": "Zebra",
    "SPRITES_BLOCK-A": "Block-A",
    "SPRITES_BLOCK-B": "Block-B",
    "SPRITES_BLOCK-C": "Block-C",
    "SPRITES_BLOCK-D": "Block-D",
    "SPRITES_BLOCK-E": "Block-E",
    "SPRITES_BLOCK-F": "Block-F",
    "SPRITES_BLOCK-G": "Block-G",
    "SPRITES_BLOCK-H": "Block-H",
    "SPRITES_BLOCK-I": "Block-I",
    "SPRITES_BLOCK-J": "Block-J",
    "SPRITES_BLOCK-K": "Block-K",
    "SPRITES_BLOCK-L": "Block-L",
    "SPRITES_BLOCK-M": "Block-M",
    "SPRITES_BLOCK-N": "Block-N",
    "SPRITES_BLOCK-O": "Block-O",
    "SPRITES_BLOCK-P": "Block-P",
    "SPRITES_BLOCK-Q": "Block-Q",
    "SPRITES_BLOCK-R": "Block-R",
    "SPRITES_BLOCK-S": "Block-S",
    "SPRITES_BLOCK-T": "Block-T",
    "SPRITES_BLOCK-U": "Block-U",
    "SPRITES_BLOCK-V": "Block-V",
    "SPRITES_BLOCK-W": "Block-W",
    "SPRITES_BLOCK-X": "Block-X",
    "SPRITES_BLOCK-Y": "Block-Y",
    "SPRITES_BLOCK-Z": "Block-Z",
    "SPRITES_GLOW-0": "Glow-0",
    "SPRITES_GLOW-1": "Glow-1",
    "SPRITES_GLOW-2": "Glow-2",
    "SPRITES_GLOW-3": "Glow-3",
    "SPRITES_GLOW-4": "Glow-4",
    "SPRITES_GLOW-5": "Glow-5",
    "SPRITES_GLOW-6": "Glow-6",
    "SPRITES_GLOW-7": "Glow-7",
    "SPRITES_GLOW-8": "Glow-8",
    "SPRITES_GLOW-9": "Glow-9",
    "SPRITES_GLOW-A": "Glow-A",
    "SPRITES_GLOW-B": "Glow-B",
    "SPRITES_GLOW-C": "Glow-C",
    "SPRITES_GLOW-D": "Glow-D",
    "SPRITES_GLOW-E": "Glow-E",
    "SPRITES_GLOW-F": "Glow-F",
    "SPRITES_GLOW-G": "Glow-G",
    "SPRITES_GLOW-H": "Glow-H",
    "SPRITES_GLOW-I": "Glow-I",
    "SPRITES_GLOW-J": "Glow-J",
    "SPRITES_GLOW-K": "Glow-K",
    "SPRITES_GLOW-L": "Glow-L",
    "SPRITES_GLOW-M": "Glow-M",
    "SPRITES_GLOW-N": "Glow-N",
    "SPRITES_GLOW-O": "Glow-O",
    "SPRITES_GLOW-P": "Glow-P",
    "SPRITES_GLOW-Q": "Glow-Q",
    "SPRITES_GLOW-R": "Glow-R",
    "SPRITES_GLOW-S": "Glow-S",
    "SPRITES_GLOW-T": "Glow-T",
    "SPRITES_GLOW-U": "Glow-U",
    "SPRITES_GLOW-V": "Glow-V",
    "SPRITES_GLOW-W": "Glow-W",
    "SPRITES_GLOW-X": "Glow-X",
    "SPRITES_GLOW-Y": "Glow-Y",
    "SPRITES_GLOW-Z": "Glow-Z",
    "SPRITES_STORY-A": "Story-A",
    "SPRITES_STORY-B": "Story-B",
    "SPRITES_STORY-C": "Story-C",
    "SPRITES_STORY-D": "Story-D",
    "SPRITES_STORY-E": "Story-E",
    "SPRITES_STORY-F": "Story-F",
    "SPRITES_STORY-G": "Story-G",
    "SPRITES_STORY-H": "Story-H",
    "SPRITES_STORY-I": "Story-I",
    "SPRITES_STORY-J": "Story-J",
    "SPRITES_STORY-K": "Story-K",
    "SPRITES_STORY-L": "Story-L",
    "SPRITES_STORY-M": "Story-M",
    "SPRITES_STORY-N": "Story-N",
    "SPRITES_STORY-O": "Story-O",
    "SPRITES_STORY-P": "Story-P",
    "SPRITES_STORY-Q": "Story-Q",
    "SPRITES_STORY-R": "Story-R",
    "SPRITES_STORY-S": "Story-S",
    "SPRITES_STORY-T": "Story-T",
    "SPRITES_STORY-U": "Story-U",
    "SPRITES_STORY-V": "Story-V",
    "SPRITES_STORY-W": "Story-W",
    "SPRITES_STORY-X": "Story-X",
    "SPRITES_STORY-Y": "Story-Y",
    "SPRITES_STORY-Z": "Story-Z"
  }
};
