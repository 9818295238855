const VirtualMachine = require('../virtual-machine');
const formatMessage = require('format-message');
const dispatch = require('../dispatch/central-dispatch');
const DFRuntime = require('./df-runtime');

class DFVirtualMachine extends VirtualMachine {
    constructor (codeMode = 'scratch') {
        super();
        // 扩展移除事件
        this.runtime.on(DFRuntime.EXTENSION_REMOVED, extensionIdWithVersion => {
            this.emit(DFRuntime.EXTENSION_REMOVED, extensionIdWithVersion);
        });
        // 设备未连接
        this.runtime.on(DFRuntime.DEVICE_NOT_CONNECTED, () => {
            this.emit(DFRuntime.DEVICE_NOT_CONNECTED);
        });

        this.runtime.on(DFRuntime.PERIPHERAL_REQUEST_ERROR, data => {
            this.emit(DFRuntime.PERIPHERAL_REQUEST_ERROR, data);
        });
        this.runtime.setCodeMode(codeMode);
    }

    attachDeviceManager (deviceManager) {
        this.runtime.deviceManager = deviceManager;
    }

    setLocale (locale, messages) {
        // messages 是gui从redux中传过来的,是不可变对象,需要在此重新赋值
        const msgs = Object.assign({}, messages);
        if (locale !== formatMessage.setup().locale) {
            formatMessage.setup({locale: locale, translations: {[locale]: msgs}});
            // 设置外部扩展的语言环境
            this.extensionManager.getAllTargetsExtensions().map(serviceName => {
                // 有些扩展没有setLocale方法, 所以要用try catch包裹
                try {
                    dispatch.callSync(serviceName, 'setLocale', locale);
                } catch (error) {
                    //
                }
            });
        }

        return this.extensionManager.refreshBlocks();
    }

    // 获取extensionObj
    getExtensionObj (extensionId) {
        const serverName = this.extensionManager.getServerName(extensionId);
        return dispatch.services[serverName];
    }

    // installTargets有三个调用场景: 1.加载sb3, 2.添加设备/角色, 3.加载sprite3
    // extensions参数被废弃, 从target.extensions读取
    async installTargets (targets, extensions, wholeProject) {
        targets = targets.filter(target => !!target);

        targets.forEach(target => {
            this.runtime.addTarget(target);
            (/** @type RenderedTarget */ target).updateAllDrawableProperties();
            // Ensure unique sprite name
            if (target.isSprite()) this.renameSprite(target.id, target.getName());
        });
        // Sort the executable targets by layerOrder.
        // Remove layerOrder property after use.
        this.runtime.executableTargets.sort((a, b) => a.layerOrder - b.layerOrder);
        targets.forEach(target => {
            delete target.layerOrder;
        });

        let deviceId = '';
        // 先加载主板
        // extensions 若未undefined 会出错
        extensions && extensions.extensionIDs.forEach(extensionIdWithVersion => {
            if (extensionIdWithVersion.indexOf('dev-') === 0) {
                deviceId = extensionIdWithVersion;
            }
        });
        if (deviceId) await this.extensionManager.loadDevice(deviceId);
        // 再加载小模块
        const extensionPromises = [];

        // extensions 若未undefined 会出错
        extensions && extensions.extensionIDs.forEach(extensionIdWithVersion => {
            // 怎么判断该extensionId是设备还是扩展?
            // 方案1: 先在设备库搜索, 再到扩展库搜索
            // 方案2: 设备和扩展命名规范, dev-DFRobot-microbit, ext-DFRobot-oled2864,  采用此方案

            // 设备
            if (extensionIdWithVersion.indexOf('dev-') !== 0) {
                extensionPromises.push(this.extensionManager.loadExtension(extensionIdWithVersion));
            }
            // extensionPromises.push(this.extensionManager.loadExtension(extensionIdWithVersion).catch(() => {
            //     return extensionPromises.push(this.extensionManager.loadDevice(extensionIdWithVersion))
            // }))
        });

        // 3.等待设备和扩展资源下载完成
        return Promise.all(extensionPromises)
            .then(() => {
                // targets.forEach(target => {
                //     this.runtime.addTarget(target);
                //     (/** @type RenderedTarget */ target).updateAllDrawableProperties();
                //     // Ensure unique sprite name
                //     if (target.isSprite()) this.renameSprite(target.id, target.getName());
                // });
                // // Sort the executable targets by layerOrder.
                // // Remove layerOrder property after use.
                // this.runtime.executableTargets.sort((a, b) => a.layerOrder - b.layerOrder);
                // targets.forEach(target => {
                //     delete target.layerOrder;
                // });

                // Select the first target for editing, e.g., the first sprite.
                if (wholeProject && (targets.length > 1)) {
                    this.editingTarget = targets[1];
                } else {
                    this.editingTarget = targets[0];
                }
                if (!wholeProject) {
                    this.editingTarget.fixUpVariableReferences();
                }

                // Update the VM user's knowledge of targets and blocks on the workspace.
                this.emitTargetsUpdate(false /* Don't emit project change */);
                this.runtime.setEditingTarget(this.editingTarget);
                this.emitWorkspaceUpdate();
                this.runtime.ioDevices.cloud.setStage(this.runtime.getTargetForStage());
            });
    }

    setEditingTarget (targetId) {
        // Has the target id changed? If not, exit.
        if (this.editingTarget && targetId === this.editingTarget.id) {
            return;
        }
        const target = this.runtime.getTargetById(targetId);
        if (target) {
            this.editingTarget = target;
            // Emit appropriate UI updates.
            this.emitTargetsUpdate(false /* Don't emit project change */);
            this.emitWorkspaceUpdate();
            this.runtime.setEditingTarget(target);
        }
    }

    getVariableValueForCurTarget (variableId) {
        const target = this.runtime.getEditingTarget();
        if (target) {
            const variable = target.lookupVariableById(variableId);
            if (variable) {
                return variable.value;
            }
        }
        return null;
    }

    // 判断是否是老版本项目JSON
    checkIsOldVersionProject (projectJSON) {
        if (projectJSON.meta && projectJSON.meta.hasOwnProperty('refactorVersion')) {
            return false;
        }
        return true;
    }

    // 读取 page{index} 数据
    getProjectDataJSONfromIndex (projectJSON) {
        if (projectJSON.pageIndex !== undefined) {
            const index = this.runtime.getCodeModeIndex();
            if (projectJSON[`page${index}`]) {
                return Object.assign({}, projectJSON[`page${index}`]);
            }
        }
        return projectJSON;
    }

    deserializeProject (projectJSON, zip) {
        // Clear the current runtime
        this.clear();

        if (typeof performance !== 'undefined') {
            performance.mark('scratch-vm-deserialize-start');
        }
        const runtime = this.runtime;

        const deserializePromise = () => {
            let tempProjectJSON = projectJSON;
            const needCompatibility = zip ? this.checkIsOldVersionProject(tempProjectJSON) : false;
            // 判断是否是老版本项目JSON
            if (needCompatibility) {
                tempProjectJSON = this.getProjectDataJSONfromIndex(tempProjectJSON);
            }
            const projectVersion = tempProjectJSON.projectVersion || 3;
            if (projectVersion === 2) {
                const sb2 = require('../serialization/sb2');
                return sb2.deserialize(tempProjectJSON, runtime, false, zip);
            }
            if (projectVersion === 3) {
                const sb3 = require('../serialization/sb3');
                return sb3.deserialize(tempProjectJSON, runtime, zip, false, needCompatibility);
            }
            return Promise.reject('Unable to verify Scratch Project version.');
        };
        return deserializePromise()
            .then(({targets, extensions}) => {
                if (typeof performance !== 'undefined') {
                    performance.mark('scratch-vm-deserialize-end');
                    performance.measure('scratch-vm-deserialize',
                        'scratch-vm-deserialize-start', 'scratch-vm-deserialize-end');
                }
                return this.installTargets(targets, extensions, true);
            });
    }

    emitWorkspaceUpdate () {
        if (!this.editingTarget) return;
        super.emitWorkspaceUpdate();
    }

    // 增加加载项目之前，先移除已加载设备逻辑
    loadProject (input) {
        if (typeof input === 'object' && !(input instanceof ArrayBuffer) &&
            !ArrayBuffer.isView(input)) {
            input = JSON.stringify(input);
        }
        const removeDevicePromise = Promise.resolve(this.runtime.deviceManager.deleteDevice());
        const validationPromise = new Promise((resolve, reject) => {
            const validate = require('scratch-parser');
            // The second argument of false below indicates to the validator that the
            // input should be parsed/validated as an entire project (and not a single sprite)
            validate(input, false, (error, res) => {
                if (error) return reject(error);
                resolve(res);
            });
        }).catch(error => {
            const {SB1File, ValidationError} = require('scratch-sb1-converter');
            try {
                const sb1 = new SB1File(input);
                const json = sb1.json;
                json.projectVersion = 2;
                return Promise.resolve([json, sb1.zip]);
            } catch (sb1Error) {
                if (sb1Error instanceof ValidationError) {
                    // The input does not validate as a Scratch 1 file.
                } else {
                    // The project appears to be a Scratch 1 file but it
                    // could not be successfully translated into a Scratch 2
                    // project.
                    return Promise.reject(sb1Error);
                }
            }
            return Promise.reject(error);
        });
        return removeDevicePromise
            .then(() => validationPromise)
            .then(validatedInput => this.deserializeProject(validatedInput[0], validatedInput[1]))
            .then(() => this.runtime.emitProjectLoaded())
            .catch(error => {
                // Intentionally rejecting here (want errors to be handled by caller)
                if (error.hasOwnProperty('validationError')) {
                    return Promise.reject(JSON.stringify(error));
                }
                return Promise.reject(error);
            });
    }

}

module.exports = DFVirtualMachine;
