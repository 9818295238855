import { useEffect, useMemo, useState } from "react"
import config from "config/config"
import { MINDPLUS_MODE, IS_WEB_PLATFORM } from "config/config"
import { useSelector } from "react-redux"
import { selectIntl } from "redux/intl/intlSlice"
import message from "component/df-message/Message"

const CACHE_EXPIRY_TIME = 1000 * 60 * 10; // 设置缓存过期时间

const tagConfigCache = new Map();
const filterItemsConfigCache = new Map();

const checkCacheExpiry = (cache, key) => {
    const cachedData = cache.get(key);
    if (!cachedData) return false;

    const { timestamp } = cachedData;
    const now = Date.now();
    if (now - timestamp > CACHE_EXPIRY_TIME) {
        cache.delete(key); // 如果缓存过期，删除缓存
        return false;
    }
    return true;
};


type FilterContentItem = {
    label: string
    value: string
    order: number
}

type FilterItem = {
    isMainFilter?: boolean // 是否是主筛选条件
    key: string
    name: string
    order: number
    checkbox: boolean
    items: FilterContentItem[]
}

type SelectedTagObj = {
    [key: string]: {
        // 是否是主筛选条件
        isMainFilter: boolean,
        // 选中的值
        values: string[],
        // 可选项
        options: FilterContentItem[]
        // 是否是多选
        checkbox: boolean
    }
}

// 获取筛选条件item
export const useFilterExtension = (type: "extension" | "device" | "stage", librariesData: any, searchVisible: boolean) => {
    const { locale } = useSelector(selectIntl)
    const [filterLoading, setFilterLoading] = useState(false)
    const [filterItems, setFilterItems] = useState<FilterItem[]>([])
    const [selectedTag, setSelectedTag] = useState<SelectedTagObj>({})
    const [extensionTagConfig, setExtensionTagConfig] = useState<{ [key: string]: { [key: string]: string[] } }>({})
    const handleSelectTag = (key: string, values: string[]) => {
        // 判断是增加，还是删除
        if (!selectedTag[key] || (selectedTag[key] && selectedTag[key].values.length < values.length)) {
            // 增加
            let count = Object.keys(selectedTag).reduce((pre, cur) => pre + selectedTag[cur].values.length, 0)
            if (count >= 5) {
                message.error('最多只能选择5个标签')
                return false
            }
        }
        const selectedFilterItem = filterItems.find(item => item.key === key)
        const isMainFilter = !!selectedFilterItem?.isMainFilter
        const options = selectedFilterItem?.items
        const checkbox = selectedFilterItem?.checkbox
        setSelectedTag({ ...selectedTag, [key]: { isMainFilter, values, checkbox, options } } as SelectedTagObj)
    }

    const handleRemoveSelectedTag = (key: string, value: string) => {
        setSelectedTag({ ...selectedTag, [key]: { ...selectedTag[key], values: selectedTag[key].values.filter(item => item !== value) } })
    }

    const handleClearSelectedTag = () => {
        setSelectedTag({})
    }

    // 获取扩展标签配置
    useEffect(() => {
        if (IS_WEB_PLATFORM) {
            const libType = type === 'stage' ? 'stage_extension' : type
            const loadExtensionTagConfig = async () => {
                const cacheKey = `${libType}_${locale}`
                if (checkCacheExpiry(tagConfigCache, cacheKey)) {
                    setExtensionTagConfig(tagConfigCache.get(cacheKey).data);
                    return;
                }
                try {
                    let url = `${config.EXTENSION_ASSET_SERVER}/filter/tags_${MINDPLUS_MODE}_${libType}_${locale}.json`;
                    let res = await fetch(url)
                    if (res.status !== 200) {
                        let url = `${config.EXTENSION_ASSET_SERVER}/filter/tags_${MINDPLUS_MODE}_${libType}_en.json`;
                        res = await fetch(url)
                    }
                    const data = await res.json()
                    setExtensionTagConfig(data)
                    tagConfigCache.set(cacheKey, { timestamp: Date.now(), data }); // 设置缓存并附带时间戳
                } catch (error) {
                    setExtensionTagConfig({})
                    console.log("error===", error)
                }
            }
            loadExtensionTagConfig()
        }
    }, [locale, type])

    // 获取扩展搜索配置
    useEffect(() => {
        if (IS_WEB_PLATFORM) {
            const libType = type === 'stage' ? 'stage_extension' : type
            const loadExtensionSearchConfig = async () => {
                const cacheKey = `${libType}_${locale}`
                if (checkCacheExpiry(filterItemsConfigCache, cacheKey)) {
                    console.log("filterItemsConfigCache===", filterItemsConfigCache.get(cacheKey).data)
                    setFilterItems(filterItemsConfigCache.get(cacheKey).data);
                    return;
                }
                try {
                    setFilterLoading(true)
                    let url = `${config.EXTENSION_ASSET_SERVER}/filter/filter_${MINDPLUS_MODE}_${libType}_${locale}.json`;
                    let res = await fetch(url)
                    if (res.status !== 200) {
                        let url = `${config.EXTENSION_ASSET_SERVER}/filter/filter_${MINDPLUS_MODE}_${libType}_en.json`;
                        res = await fetch(url)
                    }
                    const data = await res.json()
                    const temp: FilterItem[] = [];
                    for (const key in data) {
                        if (Object.prototype.hasOwnProperty.call(data, key)) {
                            let item = data[key];
                            item.key = key;
                            temp.push(item);
                            item.items = item.items.sort((a, b) => a.order - b.order)
                        }
                    }
                    // 排序
                    const sortedData = temp.sort((a, b) => a.order - b.order)
                    // 标记第一个筛选条件
                    sortedData[0].isMainFilter = true
                    filterItemsConfigCache.set(cacheKey, { timestamp: Date.now(), data: sortedData }); // 设置缓存并附带时间戳
                    setFilterItems(sortedData);
                    setFilterLoading(false)
                } catch (error) {
                    console.log("error===", error)
                } finally {
                    setFilterLoading(false)
                }

            }
            loadExtensionSearchConfig()
        }
    }, [locale, type])

    // 过滤逻辑
    const filteredItems = useMemo(() => {
        console.log("extensionTagConfig====", extensionTagConfig)
        let result = [...librariesData]
        // 没有筛选条件，直接返回原数组
        if (Object.keys(selectedTag).length === 0 || Object.keys(selectedTag).every(key => selectedTag[key].values.length === 0)) return result;
        // 没有获取到公共扩展配置标签，直接返回原数组
        if (!extensionTagConfig || extensionTagConfig.toString() === '{}') {
            return result;
        }
        // 找到非空的selectedTag
        const filterTags = {}
        for (const key in selectedTag) {
            if (Object.prototype.hasOwnProperty.call(selectedTag, key)) {
                const element = selectedTag[key];
                if (element.values.length) {
                    filterTags[key] = element
                }
            }
        }
        return result.filter(item => {
            const id = item[0][type === 'device' ? 'deviceId' : 'extensionId'];
            const tagConfig = extensionTagConfig[id];
            if (!tagConfig) return false;
            // 遍历所有的筛选条件，确保每个条件都被满足
            return Object.keys(filterTags).every(filterKey => {
                const { values, checkbox } = filterTags[filterKey];
                // 如果该筛选条件是多选
                if (checkbox) {
                    // 只要`tagConfig[filterKey]`和`values`有任意交集即可
                    return values.some(value => tagConfig[filterKey]?.includes(value));
                } else {
                    // 单选条件
                    return values.length === 1 && tagConfig[filterKey]?.includes(values[0]);
                }
            });
        });
    }, [extensionTagConfig, librariesData, selectedTag, type]);


    // 是否显示主筛选条件栏
    const showMainFilter = useMemo(() => {
        let show = true;
        for (const key in selectedTag) {
            if (Object.prototype.hasOwnProperty.call(selectedTag, key)) {
                const element = selectedTag[key];
                if (element.isMainFilter !== true && element.values.length > 0) {
                    show = false
                    break
                }
            }
        }
        return show && !searchVisible
    }, [searchVisible, selectedTag])

    // 切换后重置筛选标签
    useEffect(() => {
        setSelectedTag({})
    }, [type])


    return {
        filterItems,
        handleSelectTag,
        selectedTag,
        showMainFilter,
        handleRemoveSelectedTag,
        handleClearSelectedTag,
        filteredItems
    }
};
