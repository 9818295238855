import {useCallback, useEffect, useRef} from "react";
import ScratchBlocks from "lib/scratch-blocks";
import {defaultWorkspaceOptions} from "./defaultWorkspaceOptions";
import {vm} from "lib/scratch-vm";
import {useDispatch, useSelector} from "react-redux";
import {selectWorkspaceMetrics, updateMetrics} from "redux/workspace-metrics/workspaceMetricsSlice";

const addFunctionListener = (object, property, callback) => {
    const oldFn = object[property];
    object[property] = function (...args) {
        const result = oldFn.apply(this, args);
        callback.apply(this, result);
        return result;
    };
};
/**
* @Description: 1.创建workspace 2.绑定新建变量、列表、函数回调 3.监听workspaceUpdate事件，更新metrics信息
* @author LiuSheng
* @date 2023/5/4
*/
export const useWorkspace = () => {
    const workspaceRef = useRef<any>();
    const dispatch = useDispatch();
    const workspaceMetrics = useSelector(selectWorkspaceMetrics);
    const onWorkspaceMetricsChange = useCallback(() => {
        const workspace = workspaceRef.current;
        const target = vm.editingTarget;
        if (target && target.id) {
            // Dispatch updateMetrics later, since onWorkspaceMetricsChange may be (very indirectly)
            // called from a reducer, i.e. when you create a custom procedure.
            // TODO: Is this a vehement hack?
            setTimeout(() => {
                dispatch(updateMetrics({
                    targetID: target.id,
                    scrollX: workspace.scrollX,
                    scrollY: workspace.scrollY,
                    scale: workspace.scale
                }))
            }, 0);
        }
    }, [])

    useEffect(() => {
        // todo: 绑定连接状态回调(这种连接方式是否移除?) ScratchBlocks.statusButtonCallback
        // todo: 绑定声音录制回调 ScratchBlocks.recordSoundCallback
        // todo: 替换scratch-blocks中的alert和conrim提示框
        // todo: 色盘回调 ScratchBlocks.FieldColourSlider.activateEyedropper_
        // todo:

        // 创建工作区
        const workspace = ScratchBlocks.inject('blocklyDiv', {
            ...defaultWorkspaceOptions,
            // toolbox:
            media: '/static/blocks-media/',
        });
        workspaceRef.current =  workspace;
        const setToolboxRefreshEnabled = workspace.setToolboxRefreshEnabled.bind(workspace);
        workspace.setToolboxRefreshEnabled = () => {
            setToolboxRefreshEnabled(false);
        };

        const toolboxWorkspace = workspaceRef.current.getFlyout().getWorkspace();
        const varListButtonCallback = type =>
            (() => ScratchBlocks.Variables.createVariable(workspaceRef.current, null, type));
        // 绑定新建变量的回调
        toolboxWorkspace.registerButtonCallback('MAKE_A_VARIABLE', varListButtonCallback(''));
        toolboxWorkspace.registerButtonCallback('MAKE_A_LIST', varListButtonCallback('list'));

        addFunctionListener(workspace, 'translate', onWorkspaceMetricsChange);
        addFunctionListener(workspace, 'zoom', onWorkspaceMetricsChange);

        // 下拉框选项, [新建函数]的回调
        const procButtonCallback = () => {
            ScratchBlocks.Procedures.createProcedureDefCallback_(workspace.current);
        };
        toolboxWorkspace.registerButtonCallback('MAKE_A_PROCEDURE', procButtonCallback);

        return () => {
            toolboxWorkspace.removeButtonCallback('MAKE_A_VARIABLE');
            toolboxWorkspace.removeButtonCallback('MAKE_A_LIST');
            toolboxWorkspace.removeButtonCallback('MAKE_A_PROCEDURE');
            // todo: 如果workspace要卸载, 存在内存泄漏风险, 'translate'/'zoom'
            // 页面卸载时, 释放资源
            workspaceRef.current?.dispose();
        }
    }, [onWorkspaceMetricsChange])

    // 当workspace刷新时, 更新当前target的workspace移动/缩放信息
    useEffect(() => {
        const onWorkspaceUpdate = () => {
            const workspace = workspaceRef.current;
            // 当前有editingTarget, 但没有metrics信息
            if (vm.editingTarget && !workspaceMetrics.targets[vm.editingTarget.id]) {
                // 更新metrics
                onWorkspaceMetricsChange();
            }

            // 有editingTarget, 有metrics信息
            if (vm.editingTarget && workspaceMetrics.targets[vm.editingTarget.id]) {
                // 更新workspace的scroll和scale
                const {scrollX, scrollY, scale} = workspaceMetrics.targets[vm.editingTarget.id];
                workspace.scrollX = scrollX;
                workspace.scrollY = scrollY;
                workspace.scale = scale;
                workspace.resize();
            }
        }
        vm.on('workspaceUpdate', onWorkspaceUpdate);

        return () => {
            vm.removeListener('workspaceUpdate', onWorkspaceUpdate);
        };
    }, [onWorkspaceMetricsChange, workspaceMetrics])

    return {
        workspaceRef
    }
}
