import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

interface HoverTargetState {
    sprite: null|string
    receivedBlocks: boolean
}

const initialState: HoverTargetState = {
    sprite: null,
    receivedBlocks: false
};

export const hoverTargetSlice = createSlice({
    name: 'hoverTarget',
    initialState,
    reducers: {
        setHoveredSprite: (state, action: PayloadAction<string|null>) => {
            state.sprite = action.payload;
            state.receivedBlocks = false;
        },
        setReceivedBlocks: (state, action: PayloadAction<boolean>) => {
            state.receivedBlocks = action.payload;
        },
    },
})

export const {setHoveredSprite, setReceivedBlocks} = hoverTargetSlice.actions;
export const selectHoverTarget= (state: RootState) => state.hoverTarget

export default hoverTargetSlice.reducer
