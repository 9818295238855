import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";
import {OrderedMap} from 'immutable';



// Define the initial state using that type
// const initialState: OrderedMap<string, any> = OrderedMap();

const initialState = {}
export const monitorsSlice = createSlice({
    name: 'monitors',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        updateMonitors: (state, action: PayloadAction<any>) => {
            return action.payload;
        }
    },
})

export const {updateMonitors} = monitorsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectMonitors = (state: RootState) => state.monitors

export default monitorsSlice.reducer
