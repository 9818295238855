import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

// Define a type for the slice state
interface ModalsState {
  backdropLibrary: boolean;
  costumeLibrary: boolean;
  extensionLibrary: boolean;
  loadingProject: boolean;
  telemetryModal: boolean;
  soundLibrary: boolean;
  spriteLibrary: boolean;
  soundRecorder: boolean;
  connectionModal: boolean;
  tipsLibrary: boolean;
  cameraCapture: boolean;
  loginModal: boolean;
}

// Define the initial state using that type
const initialState: ModalsState = {
  backdropLibrary: false,
  costumeLibrary: false,
  extensionLibrary: false,
  loadingProject: false,
  telemetryModal: false,
  soundLibrary: false,
  spriteLibrary: false,
  soundRecorder: false,
  connectionModal: false,
  tipsLibrary: false,
  cameraCapture: false,
  loginModal: false,
};

export const modalsSlice = createSlice({
  name: "modals",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    openSoundRecorderModal: (state) => {
      state.soundRecorder = true;
    },
    closeSoundRecorderModal: (state) => {
      state.soundRecorder = false;
    },
    openConnectionModal: (state) => {
      state.connectionModal = true;
    },
    closeConnectionModal: (state) => {
      state.connectionModal = false;
    },
    openCameraCapture: (state) => {
      state.cameraCapture = true;
    },
    closeCameraCapture: (state) => {
      state.cameraCapture = false;
    },
    openLoginModal: (state) => {
      state.loginModal = true
    },
    closeLoginModal: (state) => {
      state.loginModal = false
    },
  },
});

export const {
  // openExtensionLibrary, closeExtensionLibrary
} = modalsSlice.actions;

export const selectModals = (state: RootState) => state.modals;

export const selectExtensionModal = (state: RootState) =>
  state.modals.extensionLibrary;

export const selectSoundRecorderModal = (state: RootState) =>
  state.modals.soundRecorder;

export const {
  openSoundRecorderModal,
  closeSoundRecorderModal,
  openConnectionModal,
  closeConnectionModal,
  openCameraCapture,
  closeCameraCapture,
  openLoginModal,
  closeLoginModal,
} = modalsSlice.actions;

export default modalsSlice.reducer;
