import { useEffect } from "react"
import { vm } from "lib/scratch-vm"

/**
 * 定义扩展中，flyout按钮回调函数
 * @param workspaceRef 
 */
export const useExtensionFlyoutButtonCallback = (workspaceRef:any) => { 
  useEffect(() => {
    if (!workspaceRef.current) return;  
    const toolboxWorkspace = workspaceRef.current.getFlyout().getWorkspace();
    const HuskyLensPro_openAIWindow = () => {
      if (vm.runtime.ml5TrainComponent) {
        vm.runtime.ml5TrainComponent.comShow()
      } else {
        console.error('ml5TrainComponent not found')
      }
    }
    const REGISTER_BAIDUCLOUD = () => {
      //TODO: 离线版支持
      window.open('https://login.bce.baidu.com/new-reg', '_blank')
    }
    toolboxWorkspace.registerButtonCallback('HuskyLensPro_openAIWindow', HuskyLensPro_openAIWindow);
    toolboxWorkspace.registerButtonCallback('REGISTER_BAIDUCLOUD', REGISTER_BAIDUCLOUD);
  },[workspaceRef])
}
