// @flow
import { vm } from "lib/scratch-vm";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectVmStatus } from "redux/vm-status/vmStatusSlice";
import styles from "./VmControl.module.scss";
import greenFlagIcon from "./icon--green-flag.svg";
import stopFlagIcon from "./icon--stop-all.svg";

type Props = {};
const messages = defineMessages({
  goTitle: {
    id: "gui.controls.go",
    defaultMessage: "Go",
    description: "Green flag button title",
  },
  stopTitle: {
    id: "gui.controls.stop",
    defaultMessage: "Stop",
    description: "Stop button title",
  },
});

export const VmControl = (props: Props) => {
  const { running, started } = useSelector(selectVmStatus);
  const intl = useIntl();
  const onRunClick = (e) => {
    e.preventDefault();
    // todo: 按住shift点击, 打开turbo模式
    if (!started) {
      vm.start();
    }
    vm.greenFlag();
  };

  const onStopClick = (e) => {
    e.preventDefault();
    vm.stopAll();
  };


  const containerStyle = running ? styles.runningContainer : styles.container;
  return (
    <div className={containerStyle}>
      <div
        className={styles.run}
        title={intl.formatMessage(messages.goTitle)}
        onClick={onRunClick}
      >
        <img className={styles.greenFlag} src={greenFlagIcon} alt="" />
      </div>
      <div
        className={styles.stop}
        title={intl.formatMessage(messages.stopTitle)}
        onClick={onStopClick}
      >
        <img className={styles.greenFlag} src={stopFlagIcon} alt="" />
      </div>
    </div>
  );
};
