import {ExtensionDataType} from "./type";
import {vm} from "../../../lib/scratch-vm";

// 加载扩展
export const loadExtension = (item: ExtensionDataType): Promise<any> => {
    // 扩展已加载
    if (vm.extensionManager.isExtensionLoaded(item.extensionId)) {
        return Promise.resolve();
    }
    return vm.extensionManager.loadExtension((item as any).extensionIdWithVersion||item.extensionId);
}

// 移除扩展
export const unloadExtension = (item: ExtensionDataType) => {
    vm.runtime.deviceManager.removeModule((item as any).extensionIdWithVersion || item.extensionId);
    // 刷新workspace中的block
    vm.refreshWorkspace();
}