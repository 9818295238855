import LibraryComponent from "component/library/library.jsx";
import analytics from "lib/analytics";
import bindAll from "lodash.bindall";
import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import decksLibraryContent from "lib/libraries/decks/index.jsx";

const messages = defineMessages({
  tipsLibraryTitle: {
    id: "gui.cards.how-tos",
    description: 'title for "How-Tos"',
    defaultMessage: "How-Tos",
  },
});

class TipsLibrary extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this, ["handleItemSelect"]);
  }
  handleItemSelect(item) {
    this.props.onActivateDeck(item.id);
    analytics.event({
      category: "library",
      action: "select video",
      label: item.id,
    });
  }
  render() {
    const decksLibraryThumbnailData = Object.keys(decksLibraryContent).map(
      (id) => ({
        rawURL: decksLibraryContent[id].img,
        id: id,
        name: decksLibraryContent[id].name,
        featured: true,
      })
    );

    if (!this.props.visible) return null;
    return (
      <LibraryComponent
        filterable={false}
        data={decksLibraryThumbnailData}
        id="tipsLibrary"
        title={this.props.intl.formatMessage(messages.tipsLibraryTitle)}
        visible={this.props.visible}
        onItemSelected={this.handleItemSelect}
        onRequestClose={this.props.onRequestClose}
      />
    );
  }
}

export default injectIntl(TipsLibrary);
