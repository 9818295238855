import message from "component/df-message/Message"
import { IS_WEB_PLATFORM } from "config/config"
import { vm } from "lib/scratch-vm"
import { useDispatch, useSelector } from "react-redux"
import { selectProject, setCanSave } from "redux/project/projectSlice"
import { setUserInfo, clearUserInfo } from "redux/user/userSlice"
import { clearLocalStorage, saveToken, setToken } from "service/api/token"
import { getUserInfo_API, login_API } from "service/api/user-api"
import { useCreateCloudProject, useUpdateProjectInfo } from "service/project/useProject"

export const useLogin = () => {
    const dispatch = useDispatch()
    const getUserInfo = useGetUserInfo();
    const {projectId, projectTitle} = useSelector(selectProject)
    const createCloudProject = useCreateCloudProject();
    const updateProjectInfo = useUpdateProjectInfo()

    const login_ = (username: string, password: string, autoLogin:boolean = false) => {
        // 1.api登录
        return login_API(username, password)
        // 2.设置token
        .then(({token}) => {
            // redux设置token
            dispatch(setUserInfo({token}))
            // 如果勾选自动登录, localStorage保存token
            if (autoLogin) {
                saveToken(token);
            } else {
                setToken(token);
            }
        })
        // 3.获取用户信息, 并刷新显示
        .then(getUserInfo)
        .then(() => {
            // 当登录成功时
            // 在线版的新建项目, 创建云端项目
            if (IS_WEB_PLATFORM && projectId === 0) {
                createCloudProject(projectTitle, JSON.stringify(vm.toJSON()));
            } 
            // 打开的云端项目, 重新获取一次projectInfo, 刷新canSave
            else if (projectId > 0) {
                updateProjectInfo(projectId)
            }
        })
        .catch((e) => {
            message.error("获取用户信息失败")
        })
    }
    return login_
}

// 退出登录
export const useLogout = () => {
    const dispatch = useDispatch()
    const logout = () => {
        dispatch(setCanSave(false))
        dispatch(clearUserInfo())
        clearLocalStorage();
    }

    return logout
}

export const useGetUserInfo = () => {
    const dispatch = useDispatch()
    const getUserInf =  () => {
        return getUserInfo_API()
            .then((userInfo) => {
                // redux 设置userinfo
                dispatch(setUserInfo(userInfo))
            })
    }
    return getUserInf;
}