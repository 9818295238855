export const defaultWorkspaceOptions = {
    zoom: {
        controls: true,
        wheel: true,
        startScale: 0.675
    },
    grid: {
        spacing: 40,
        length: 2,
        colour: '#ddd'
    },
    colours: {
        workspace: '#F9F9F9',
        text: '#575E75',
        buttonInFlyoutHover: 'rgba(0,0,0,.55)',
        flyout: '#fff',
        toolbox: '#FFFFFF',
        toolboxText: '#575e75',
        toolboxSelected: '#E9EEF2',
        scrollbar: '#CECDCE',
        scrollbarHover: '#CECDCE',
        insertionMarker: '#000000',
        insertionMarkerOpacity: 0.2,
        fieldShadow: 'rgba(255, 255, 255, 0.3)',
        dragShadowOpacity: 0.6
    },
    comments: true,
    collapse: false,
    sounds: false,
    scrollbars: true,
    trashcan:true,
};
