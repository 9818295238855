import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { ProjectInfo } from 'service/api/project-api';
import {RootState} from "../store";

// Define a type for the slice state
interface ProjectState {
    projectError?: Error|null // 加载错误
    projectChanged: boolean
    projectLoadingStatus: "creating"|"loading"|false // 正在加载, 用于显示loading页面
    projectId: number 
    projectTitle: string // 项目名称

    canSave: boolean // 是否能云端保存, 登录并且项目作者是当前用户
    projectPath?: string // 离线版, 打开的文件路径
    saveStatus: "saving"|"saveSuccess"|"savingError"|"" // 正在保存项目

    // projectInfo?: ProjectInfo
}

// Define the initial state using that type
const initialState: ProjectState = {
    projectTitle: '',
    projectId: -1, // -1表示软件启动, 还未加载项目
    projectChanged: false,
    projectLoadingStatus: "loading",
    canSave: false,
    saveStatus: "",
}

// 本地默认项目编号
export const defaultProjectId = 0; 


export const projectSlice = createSlice({
    name: 'project',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setProjectChanged: (state) => {
            state.projectChanged = true
        },
        setProjectUnchanged: (state) => {
            state.projectChanged = false
        },
        setProjectTitle: (state, action: PayloadAction<string>) => {
            state.projectTitle = action.payload
        },
        setProjectLoadingStatus: (state, action: PayloadAction<"creating"|"loading"|false>) => {
            state.projectLoadingStatus = action.payload
        },
        setProjectError: (state, action: PayloadAction<Error|null>) => {
            state.projectError = action.payload
        },
        setProjectId: (state, action: PayloadAction<number>) => {
            state.projectId = action.payload
        },
        setProjectPath: (state, action: PayloadAction<string>) => {
            state.projectPath = action.payload
        },
        setCanSave: (state, action: PayloadAction<boolean>) => {
            state.canSave = action.payload
        },
        setProjectSavingStatus: (state, action: PayloadAction<"saving"|"saveSuccess"|"savingError"|"">) => {
            state.saveStatus = action.payload
        },
        resetProject: (state) => {
            state.projectTitle = ""
            state.projectError = undefined
            state.projectPath = undefined
            state.canSave = false
            state.saveStatus = ""
            state.projectId = 0
            state.projectChanged = false
        }
    },
})

export const {
    setProjectChanged, 
    setProjectUnchanged,
    setProjectTitle,
    setProjectLoadingStatus,
    setProjectError,
    setProjectId,
    setProjectPath,
    setCanSave,
    setProjectSavingStatus,
    resetProject,
} = projectSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectProject = (state: RootState) => state.project

export default projectSlice.reducer
