const BlockUtility = require('../engine/block-utility');

class DFBlockUtility extends BlockUtility {
    constructor (sequencer = null, thread = null) {
        super(sequencer = null, thread = null);
    }
    initSpecialBlockParams () {
        this.thread.initSpecialBlockParams();
    }
    pushSpecialBlockParam (paramName, paramValue) {
        this.thread.pushSpecialBlockParam(paramName, paramValue);
    }

    getSpecialBlockParam (paramName) {
        return this.thread.getSpecialBlockParam(paramName);
    }
}

module.exports = DFBlockUtility;
