import ExtensionButton from "component/extension-button/ExtensionButton";
import { useSelector } from "react-redux";
import { selectTab } from "redux/tab/tabSlice";
import { selectTargets } from "redux/tagets/targetsSlice";
import Block from "./block/Block";
import styles from './BlockWrapper.module.scss';
import { CostumeTab } from "./costume-tab/index";
// import {OnlineSubHeader} from "./online-header/OnlineHeader";
import { Watermark } from 'component/watermark/index';
import { SoundTab } from "./sound-tab";
import { Tabs } from './tabs/Tabs';

const BlockWrapper = () => {
  const { currentTab } = useSelector(selectTab);
  //监听editingTarget变化,判断是否显示二级header(属于模式的功能区)
  const { targetsUpdateCount } = useSelector(selectTargets)
  return (
    <div className={styles.container}>
      <div className={styles.flexWrapper}>
        <div className={styles.tabsWrapper}>
          <Tabs />
        </div>
        <div className={styles.blockWrapper}>
          <Block visibility={currentTab === "block"} />
          <ExtensionButton visibility={currentTab === "block"} />
          {currentTab === "backdrop" || currentTab === "costume" ? (
            <CostumeTab />
          ) : null}
          {currentTab === "sound" && <SoundTab />}
          {currentTab === "block" ? (
            <div className={styles.watermark}>
              <Watermark />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BlockWrapper;
