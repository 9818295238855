class BrowserUtils {
    constructor () {

    }
    static removeClass (el, classes) {
        classes
            .split(/\s+/)
            .forEach(cls => removeSingleClass(el, cls));

        function removeSingleClass(el, cls) {
            if (el.classList) {
                el.classList.remove(cls);
            } else {
                el.className.baseVal = (el.className + "")
                    .split(/\s+/)
                    .filter(c => c != cls)
                    .join(" ");
            }
        }
    }
    static addClass (el, classes) {
        classes
            .split(/\s+/)
            .forEach(cls => addSingleClass(el, cls));

        function addSingleClass(el, cls) {
            if (el.classList) {
                el.classList.add(cls);
            } else {
                const classes = (el.className + "").split(/\s+/);
                if (classes.indexOf(cls) < 0) {
                    el.className.baseVal += " " + cls;
                }
            }
        }
    }
    static toArray (a) {
        if (Array.isArray(a)) {
            return a;
        }
        let r = []
        if (!a) return r;
        for (let i = 0; i < a.length; ++i)
            r.push(a[i])
        return r
    }
    static replaceAll (replIn, x, y) {
        return replIn.split(x).join(y)
    }
    static htmlQuote (s) {
        s = BrowserUtils.replaceAll(s, "&", "&amp;")
        s = BrowserUtils.replaceAll(s, "<", "&lt;")
        s = BrowserUtils.replaceAll(s, ">", "&gt;")
        s = BrowserUtils.replaceAll(s, "\"", "&quot;")
        s = BrowserUtils.replaceAll(s, "\'", "&#39;")
        return s;
    }
    static html2Quote (s) {
        if (!s) return s;
        return BrowserUtils.htmlQuote(s.replace(/\&([#a-z0-9A-Z]+);/g, (f, ent) => {
            switch (ent) {
                case "amp": return "&";
                case "lt": return "<";
                case "gt": return ">";
                case "quot": return "\"";
                default:
                    if (ent[0] == "#")
                        return String.fromCharCode(parseInt(ent.slice(1)));
                    else return f
            }
        }))
    }
    static loadAjaxAsync (url) {
        return new Promise((resolve, reject) => {
            let httprequest = new XMLHttpRequest();
            httprequest.onreadystatechange = function () {
                if (httprequest.readyState == XMLHttpRequest.DONE) {
                    if (httprequest.status == 200) {
                        resolve(httprequest.responseText);
                    }
                    else {
                        reject(httprequest.status);
                    }
                }
            };
            httprequest.open("GET", url, true);
            httprequest.send();
        })
    }
    static hasNavigator () {
        return typeof navigator !== "undefined";
    }
    static serializeNode (sg) {
        return BrowserUtils.serializeSvgString(new XMLSerializer().serializeToString(sg));
    }
    static serializeSvgString (xmlString) {
        return xmlString
            .replace(new RegExp('&nbsp;', 'g'), '&#160;');
    }
    static isEdge () {
        return BrowserUtils.hasNavigator() && /Edge/i.test(navigator.userAgent);
    }
    static isSafari () {
        return !BrowserUtils.isChrome() && !BrowserUtils.isEdge() && !!navigator && /(Macintosh|Safari|iPod|iPhone|iPad)/i.test(navigator.userAgent);
    }
    static isChrome () {
        return !BrowserUtils.isEdge() && !!navigator && (/Chrome/i.test(navigator.userAgent) || /Chromium/i.test(navigator.userAgent));
    }
    static isWindows() {
        return BrowserUtils.hasNavigator() && /(Win32|Win64|WOW64)/i.test(navigator.platform);
    }

    static isWindows10() {
        return BrowserUtils.hasNavigator() && /(Win32|Win64|WOW64)/i.test(navigator.platform) && /Windows NT 10/i.test(navigator.userAgent);
    }

    static isMobile () {
        return BrowserUtils.hasNavigator() && /mobi/i.test(navigator.userAgent);
    }

    static isIOS () {
        return BrowserUtils.hasNavigator() && /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    //MacIntel on modern Macs
    static isMac () {
        return BrowserUtils.hasNavigator() && /Mac/i.test(navigator.platform);
    }

    //This is generally appears for Linux
    //Android *sometimes* returns this
    static isLinux () {
        return !!navigator && /Linux/i.test(navigator.platform);
    }

    // Detects if we are running on ARM (Raspberry pi)
    static isARM () {
        return BrowserUtils.hasNavigator() && /arm/i.test(navigator.platform);
    }

    // Detects if we are running inside the UWP runtime (Microsoft Edge)
    static isUwpEdge () {
        return typeof window !== "undefined" && !!(window).Windows;
    }
    static browserVersion () {
        if (!BrowserUtils.hasNavigator()) return null;
        //Unsurprisingly browsers also lie about this and include other browser versions...
        let matches = [];
        if (BrowserUtils.isOpera()) {
            matches = /(Opera|OPR)\/([0-9\.]+)/i.exec(navigator.userAgent);
        }
        if (BrowserUtils.isEpiphany()) {
            matches = /Epiphany\/([0-9\.]+)/i.exec(navigator.userAgent);
        }
        else if (BrowserUtils.isMidori()) {
            matches = /Midori\/([0-9\.]+)/i.exec(navigator.userAgent);
        }
        else if (BrowserUtils.isSafari()) {
            matches = /Version\/([0-9\.]+)/i.exec(navigator.userAgent);
            // pinned web sites and WKWebview for embedded browsers have a different user agent
            // Mozilla/5.0 (iPhone; CPU iPhone OS 10_2_1 like Mac OS X) AppleWebKit/602.4.6 (KHTML, like Gecko) Mobile/14D27
            // Mozilla/5.0 (iPad; CPU OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Mobile/14G60
            // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_5) AppleWebKit/605.1.15 (KHTML, like Gecko)
            if (!matches)
                matches = /(Macintosh|iPod|iPhone|iPad); (CPU|Intel).*?OS (X )?(\d+)/i.exec(navigator.userAgent);
        }
        else if (BrowserUtils.isChrome()) {
            matches = /(Chrome|Chromium)\/([0-9\.]+)/i.exec(navigator.userAgent);
        }
        else if (BrowserUtils.isEdge()) {
            matches = /Edge\/([0-9\.]+)/i.exec(navigator.userAgent);
        }
        else if (BrowserUtils.isIE()) {
            matches = /(MSIE |rv:)([0-9\.]+)/i.exec(navigator.userAgent);
        }
        else {
            matches = /(Firefox|Seamonkey)\/([0-9\.]+)/i.exec(navigator.userAgent);
        }
        if (!matches || matches.length == 0) {
            return null;
        }
        return matches[matches.length - 1];
    }
    static isSafari () {
        return !BrowserUtils.isChrome() && !BrowserUtils.isEdge() && !!navigator && /(Macintosh|Safari|iPod|iPhone|iPad)/i.test(navigator.userAgent);
    }
    static isFirefox() {
        return !BrowserUtils.isSafari() && !!navigator && (/Firefox/i.test(navigator.userAgent) || /Seamonkey/i.test(navigator.userAgent));
    }

    static isElectron () {
        return /Electron/.test(navigator.userAgent);
    }

    //These days Opera's core is based on Chromium so we shouldn't distinguish between them too much
    static isOpera(){
        return BrowserUtils.hasNavigator() && /Opera|OPR/i.test(navigator.userAgent);
    }

    //Midori *was* the default browser on Raspbian, however isn't any more
    static isMidori() {
        return BrowserUtils.hasNavigator() && /Midori/i.test(navigator.userAgent);
    }

    //Epiphany (code name for GNOME Web) is the default browser on Raspberry Pi
    //Epiphany also lies about being Chrome, Safari, and Chromium
    static isEpiphany() {
        return BrowserUtils.hasNavigator() && /Epiphany/i.test(navigator.userAgent);
    }
    static isEdge() {
        return BrowserUtils.hasNavigator() && /Edge/i.test(navigator.userAgent);
    }

    //IE11 also lies about its user agent, but has Trident appear somewhere in
    //the user agent. Detecting the different between IE11 and Microsoft Edge isn't
    //super-important because the UI is similar enough
    static isIE() {
        return BrowserUtils.hasNavigator() && /Trident/i.test(navigator.userAgent);
    }
    static isBrowserDownloadInSameWindow () {
        const windowOpen = BrowserUtils.isMobile() && BrowserUtils.isSafari() && !/downloadWindowOpen=0/i.test(window.location.href);
        return windowOpen;
    }
    static browserDownloadDataUri (uri, name, userContextWindow) {
        const windowOpen = BrowserUtils.isBrowserDownloadInSameWindow();
        const versionString = BrowserUtils.browserVersion();
        const v = parseInt(versionString || "0")
        if (windowOpen) {
            if (userContextWindow) userContextWindow.location.href = uri;
            else window.open(uri, "_self");
        } else if (BrowserUtils.isSafari()
            && (v < 10 || (versionString.indexOf('10.0') == 0) || BrowserUtils.isMobile())) {
            // For Safari versions prior to 10.1 and all Mobile Safari versions
            // For mysterious reasons, the "link" trick closes the
            // PouchDB database
            let iframe = document.getElementById("downloader");
            if (!iframe) {
                iframe = document.createElement("iframe");
                iframe.id = "downloader";
                iframe.style.position = "absolute";
                iframe.style.right = "0";
                iframe.style.bottom = "0";
                iframe.style.zIndex = "-1";
                iframe.style.width = "1px";
                iframe.style.height = "1px";
                document.body.appendChild(iframe);
            }
            iframe.src = uri;
        } else if (BrowserUtils.isEdge() || BrowserUtils.isIE()) {
            //Fix for edge
            let byteString = atob(uri.split(',')[1]);
            let ia = BrowserUtils.stringToUint8Array(byteString);
            let blob = new Blob([ia], { type: "img/png" });
            window.navigator.msSaveOrOpenBlob(blob, name);
        } else {
            let link = window.document.createElement('a');
            if (typeof link.download == "string") {
                link.href = uri;
                link.download = name;
                document.body.appendChild(link); // for FF
                link.click();
                document.body.removeChild(link);
            } else {
                document.location.href = uri;
            }
        }
    }
    static stringToUint8Array(input) {
        let len = input.length;
        let res = new Uint8Array(len)
        for (let i = 0; i < len; ++i)
            res[i] = input.charCodeAt(i) & 0xff;
        return res;
    }
    static loadImageAsync (data) {
        const img = document.createElement("img");
        return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = () => resolve(undefined);
            img.crossOrigin = "anonymous";
            img.src = data;
        });
    }
}

export default BrowserUtils;
