
export type PlatformType = "web"|"win"|"mac"|"linux"
export type AppModeType = "scratch"|"arduino"|"python"|"micropython"

const webConfig = {
    PLATFORM: "web",
    // wss, 需要域名访问(离线版, 断网环境, 没有更新DNS, 域名能解析成功吗? 用ws?)
    COMMAND_WS_URL: "ws://127.0.0.1:6601", // link命令url
    TRANSMIT_WS_URL: "ws://127.0.0.1:6602", // link通信url
    EXTENSION_ASSET_SERVER: 'https://resource.mindplus.top/mindplus', // 扩展资源服务器地址
    SPRITE_ASSET_SERVER: window.location.origin, // 角色/声音/图片...服务器地址, 解决路由导致图片加载失败
}

const winConfig = {
    PLATFORM: "win",
    // wss, 需要域名访问(离线版, 断网环境, 没有更新DNS, 域名能解析成功吗? 用ws?)
    COMMAND_WS_URL: "ws://127.0.0.1:6601", // link命令url
    TRANSMIT_WS_URL: "ws://127.0.0.1:6602", // link通信url
    EXTENSION_ASSET_SERVER: 'https://resource.mindplus.top/mindplus', // 扩展资源服务器地址
    SPRITE_ASSET_SERVER: "", // 角色/声音/图片...服务器地址
}
export const IS_WEB_PLATFORM = process.env.REACT_APP_CUSTOM_PLATFORM === "web";
export const IS_WIN_PLATFORM = process.env.REACT_APP_CUSTOM_PLATFORM === "win";

let config;
if (IS_WEB_PLATFORM) {
    config = webConfig;
}

if (IS_WIN_PLATFORM) {
    config = winConfig;
}

if (!config) {
    alert("env config error!!!")
}


export const PLATFORM: PlatformType = config.PLATFORM;
export const COMMAND_WS_URL = config.COMMAND_WS_URL;
export const TRANSMIT_WS_URL = config.TRANSMIT_WS_URL;
export const EXTENSION_ASSET_SERVER = config.EXTENSION_ASSET_SERVER;
export const SPRITE_ASSET_SERVER = config.SPRITE_ASSET_SERVER;
// 当前模式
export const MINDPLUS_MODE: any|AppModeType = "scratch";
export const IS_SCRATCH_MODE = MINDPLUS_MODE === "scratch";
export const IS_ARDUINO_MODE = MINDPLUS_MODE === "arduino";
export const IS_PYTHON_MODE = MINDPLUS_MODE === "python";

export const LINK_DOWNLOAD_URL_WIN = "https://download3.dfrobot.com.cn/mindplus-2.0/link/Mind+_Link_2.0-win.exe"
export default config
