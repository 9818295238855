// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".scrollable-canvas_vertical-scrollbar_gyXyM, .scrollable-canvas_horizontal-scrollbar_OBOUt {\n    background: rgba(190, 190, 190, 0.8);\n    border-radius: calc(8px / 2);\n    width: 100%;\n    height: 100%;\n}\n.scrollable-canvas_vertical-scrollbar-wrapper_1cMNm {\n    position: absolute;\n    width: calc(8px + 4px);\n    right: 0;\n    top: 4px;\n    height: calc(100% - 8px - 2 * 4px);\n}\n.scrollable-canvas_horizontal-scrollbar-wrapper_2BUMa {\n    position: absolute;\n    height: calc(8px + 4px);\n    left: 4px;\n    bottom: 0;\n    width: calc(100% - 8px - 2 * 4px);\n}\n.scrollable-canvas_vertical-scrollbar-hitbox_2QprH, .scrollable-canvas_horizontal-scrollbar-hitbox_EBg8r {\n    position: absolute;\n    cursor: pointer;\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n}\n.scrollable-canvas_vertical-scrollbar-hitbox_2QprH {\n    width: calc(8px + 4px);\n    padding-right: 4px;\n}\n.scrollable-canvas_horizontal-scrollbar-hitbox_EBg8r {\n    height: calc(8px + 4px);\n    padding-bottom: 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"vertical-scrollbar": "scrollable-canvas_vertical-scrollbar_gyXyM",
	"verticalScrollbar": "scrollable-canvas_vertical-scrollbar_gyXyM",
	"horizontal-scrollbar": "scrollable-canvas_horizontal-scrollbar_OBOUt",
	"horizontalScrollbar": "scrollable-canvas_horizontal-scrollbar_OBOUt",
	"vertical-scrollbar-wrapper": "scrollable-canvas_vertical-scrollbar-wrapper_1cMNm",
	"verticalScrollbarWrapper": "scrollable-canvas_vertical-scrollbar-wrapper_1cMNm",
	"horizontal-scrollbar-wrapper": "scrollable-canvas_horizontal-scrollbar-wrapper_2BUMa",
	"horizontalScrollbarWrapper": "scrollable-canvas_horizontal-scrollbar-wrapper_2BUMa",
	"vertical-scrollbar-hitbox": "scrollable-canvas_vertical-scrollbar-hitbox_2QprH",
	"verticalScrollbarHitbox": "scrollable-canvas_vertical-scrollbar-hitbox_2QprH",
	"horizontal-scrollbar-hitbox": "scrollable-canvas_horizontal-scrollbar-hitbox_EBg8r",
	"horizontalScrollbarHitbox": "scrollable-canvas_horizontal-scrollbar-hitbox_EBg8r"
};
module.exports = exports;
