import { useEffect, useMemo, useState } from "react";

type SorterMethod = "default" | "hot" | "cold" | "newest" | "oldest"
export const useSortExtension = (dataSource, libType) => {
    const [sortMethod, setSortMethod] = useState<SorterMethod>("default");
    const handleSortMethodChange = (method: SorterMethod) => {
        setSortMethod((beforeVal) => {
            if (beforeVal === 'default') return method
            if (method === 'hot') {
                if (beforeVal === 'hot') {
                    return 'cold'
                }
                if (beforeVal === 'cold') {
                    return 'hot'
                }
                return method
            }
            if (method === 'newest') {
                if (beforeVal === 'newest') {
                    return 'oldest'
                }
                if (beforeVal === 'oldest') {
                    return 'newest'
                }
                return method
            }
            return method
        })
    }

    const sortedData = useMemo(() => {
        switch (sortMethod) {
            case "default":
                return dataSource
            case "hot":
                break;
            case "cold":
                break;
            case "newest":
                break;
            case "oldest":
                break;
        }
    }, [dataSource, sortMethod])
    useEffect(() => {
        setSortMethod('default')
    }, [libType])

    return {
        sortMethod,
        handleSortMethodChange,
        sortedData
    }
}
