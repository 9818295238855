import { FC, useEffect, useRef, useState } from "react";
import ScratchBlocks from "lib/scratch-blocks";
import { Modal } from "component/modal/index";
import Box from "component/box/box";
import styles from "./FunctionPrompt.module.scss";
import booleanInputIcon from "./icon--boolean-input.svg";
import textInputIcon from "./icon--text-input.svg";
import labelIcon from "./icon--label.svg";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import alertDialog from 'component/df-alert/DFAlert'
import { IS_SCRATCH_MODE } from "config/config";


const messages = defineMessages({
    myblockModalTitle: {
        defaultMessage: "Make a Block",
        description: "Title for the modal where you create a custom block.",
        id: "gui.extension.makeBlock",
    },
    prompt: {
        id: "gui.dialog.prompt",
        defaultMessage: "Note"
    },
    procedureNameIsEmpty: { //函数名称不能为空, 
        id: "gui.dialog.procedure.procedureNameIsEmpty",
        // defaultMessage: "Note"
    },
    procedureNameIsRepeated: { // 函数「[NAME]」已定义，请重新命名`
        id: "gui.dialog.procedure.procedureNameIsRepeated",
        // defaultMessage: "Note"
    },
    variableIsRepeat: { // 变量名已定义
        id: 'gui.dialog.variableIsRepeat',
        defaultMessage: 'variable names cannot be repeated, please modify'
    },
    funcNameSpecialCharacters: { // 函数名包含特殊字符
        id: 'gui.dialog.funcNameSpecialCharacters',
        defaultMessage: 'function name %1 cannot contain special characters:[\`~!@#$%^&*()+<>?:"{},.\/;\'[]\\]'
    },
    funcArgsSpecialCharacters: { // 参数名包含特殊字符
        id: 'gui.dialog.funcArgsSpecialCharacters',
        defaultMessage: 'function arguments %1 cannot contain special characters:[\`~!@#$%^&*()+<>?:"{},.\/;\'[]\\]'
    },
});

interface FunctionModalProps {
    isRtl?: boolean;
    mainWorkspace: any;
}

const FunctionPrompt: FC<FunctionModalProps> = ({ isRtl, mainWorkspace }) => {
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const [wrap, setWrap] = useState(false);
    const workspaceRef = useRef<any | null>(null);
    const mutatorRef = useRef<Element | null>();
    const mutationRootRef = useRef<any>();
    const callbackRef = useRef<Function | null>();
    const rtlOffsetRef = useRef(0);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        // 绑定创建函数时的回调
        ScratchBlocks.Procedures.externalProcedureDefCallback = (
            data: Element,
            callback: any,
            isEdit_?: boolean
        ) => {
            console.log("externalProcedureDefCallback==", data)
            mutatorRef.current = data;
            callbackRef.current = callback;
            setIsEdit(!!isEdit_)
            setIsOpen(true);
        };

        return () => {
            ScratchBlocks.Procedures.externalProcedureDefCallback = null;
            workspaceRef.current?.dispose();
        };
    }, []);

    const onOpen = (blocks: HTMLDivElement) => {
        if (!blocks) return;
        // inject时toolbox参数为空就会使用默认toolbox, 所以先清除默认toolbox
        const old = ScratchBlocks.Blocks.defaultToolbox;
        ScratchBlocks.Blocks.defaultToolbox = null;
        // 创建自定义block的工作区
        workspaceRef.current = ScratchBlocks.inject("custome-procedure", {
            // toolbox: '',
            comments: false,
            scrollbars: true,
            media: "/static/blocks-media/",
            zoom: {
                controls: false,
                wheel: false,
                startScale: 0.9,
            },
        });
        ScratchBlocks.Blocks.defaultToolbox = old;

        // 在工作区内添加一个声明block
        mutationRootRef.current = workspaceRef.current.newBlock(
            "procedures_declaration"
        );
        // 不可移动
        mutationRootRef.current.setMovable(false);
        // 不可删除
        mutationRootRef.current.setDeletable(false);
        // 没有右键菜单
        mutationRootRef.current.contextMenu = false;
        // 设置mutate数据
        if (mutationRootRef.current.domToMutation)
            mutationRootRef.current.domToMutation(mutatorRef.current!);
        // 显示
        mutationRootRef.current.initSvg();
        mutationRootRef.current.render();

        workspaceRef.current.addChangeListener(() => {
            mutationRootRef.current.onChangeFn();
            // Keep the block centered on the workspace
            const metrics = workspaceRef.current.getMetrics();
            const { x, y } = mutationRootRef.current.getRelativeToSurfaceXY();
            const dy =
                metrics.viewHeight / 2 - mutationRootRef.current.height / 2 - y;
            let dx;
            if (isRtl) {
                // // TODO: https://github.com/LLK/scratch-gui/issues/2838
                // This is temporary until we can figure out what's going on width
                // block positioning on the workspace for RTL.
                // Workspace is always origin top-left, with x increasing to the right
                // Calculate initial starting offset and save it, every other move
                // has to take the original offset into account.
                // Calculate a new left postion based on new width
                // Convert current x position into LTR (mirror) x position (uses original offset)
                // Use the difference between ltrX and mirrorX as the amount to move
                const ltrX =
                    metrics.viewWidth / 2 - mutationRootRef.current.width / 2 + 25;
                const mirrorX = x - (x - rtlOffsetRef.current) * 2;
                if (mirrorX === ltrX) {
                    return;
                }
                dx = mirrorX - ltrX;
                const midPoint = metrics.viewWidth / 2;
                if (x === 0) {
                    // if it's the first time positioning, it should always move right
                    if (mutationRootRef.current.width < midPoint) {
                        dx = ltrX;
                    } else if (mutationRootRef.current.width < metrics.viewWidth) {
                        dx =
                            midPoint -
                            (metrics.viewWidth - mutationRootRef.current.width) / 2;
                    } else {
                        dx = midPoint + (mutationRootRef.current.width - metrics.viewWidth);
                    }
                    mutationRootRef.current.moveBy(dx, dy);
                    rtlOffsetRef.current =
                        mutationRootRef.current.getRelativeToSurfaceXY().x;
                    return;
                }
                if (mutationRootRef.current.width > metrics.viewWidth) {
                    dx = dx + mutationRootRef.current.width - metrics.viewWidth;
                }
            } else {
                dx = metrics.viewWidth / 2 - mutationRootRef.current.width / 2 - x;
                // If the procedure declaration is wider than the view width,
                // keep the right-hand side of the procedure in view.
                if (mutationRootRef.current.width > metrics.viewWidth) {
                    dx = metrics.viewWidth - mutationRootRef.current.width - x;
                }
            }
            mutationRootRef.current.moveBy(dx, dy);
        });

        setTimeout(() => {
            mutationRootRef.current.focusLastEditor_();
        });
    };

    const closeModal = () => {
        setIsOpen(false);
        workspaceRef.current?.dispose();
        workspaceRef.current = null;
        callbackRef.current = null;
        mutationRootRef.current = null;
        mutatorRef.current = null;
    };

    const handleOk = () => {
        if (!mutatorRef.current || !callbackRef.current) return;

        // 先更新当前编辑的内容, 生成新的dom, 否则获取currentName会获取到旧值
        const newMutation = mutationRootRef.current.mutationToDom(true);
        // 新的函数名称
        const currentFuncName = newMutation!.getAttribute('proccode')!.split("%")[0].trim();
        const lastFuncName = mutatorRef.current.getAttribute('proccode')!.split("%")[0].trim();
        // 函数名称是否为空
        if (!currentFuncName) {
            alertDialog(intl.formatMessage(messages.prompt), intl.formatMessage(messages.procedureNameIsEmpty), { timeout: 4000, zIndex: 5001, mode: 1 });
            return
        }
        // 获取所有自定义积木
        let mutations = ScratchBlocks.Procedures.allProcedureMutations(mainWorkspace.current);
        // 判断名称是否重复
        for (let item of mutations) {
            const name = item.getAttribute('proccode').split("%")[0].trim();
            // 在编辑状态下, 新的名字可以和旧的名字相同, 但不能和其他函数名相同
            if (currentFuncName === name && (!isEdit || isEdit && currentFuncName !== lastFuncName)) {
                alertDialog(intl.formatMessage(messages.prompt), intl.formatMessage(messages.procedureNameIsRepeated).replace(/\[NAME\]/, currentFuncName), { timeout: 4000, zIndex: 5001, mode: 1 });
                return
            }
        }
        const reg = /[`~!\-@#$%^&*()+<>?:"{},.\/;'[\]\\]/im
        // 函数名特殊字符判断(scratch不处理)
        if (!IS_SCRATCH_MODE && reg.test(currentFuncName)) {
            alertDialog(intl.formatMessage(messages.prompt), intl.formatMessage(messages.funcNameSpecialCharacters).replace('%1', currentFuncName), { timeout: 3000, zIndex: 5001, mode: 1 });
            return
        }

        // scratch不处理
        if (!IS_SCRATCH_MODE) {
            // 获取函数参数名
            let args = newMutation.getAttribute('argumentnames');
            console.log("args==", args)
            let argsName: any = [];
            try {
                const args_ = JSON.parse(args);
                for (let item of args_) {
                    if (reg.test(item)) {
                        // 参数名有特殊字符
                        alertDialog(intl.formatMessage(messages.prompt), intl.formatMessage(messages.funcNameSpecialCharacters).replace('%1', currentFuncName), { timeout: 3000, zIndex: 5001, mode: 1 });
                        return
                    }
                    // 参数名是否重复
                    if (argsName.indexOf(item) !== -1) {
                        alertDialog(intl.formatMessage(messages.prompt), intl.formatMessage(messages.variableIsRepeat), { timeout: 3000, zIndex: 5001, mode: 1 });
                        return
                    }
                    argsName.push(item)
                }
            } catch (err) {
                return;
            }
        }
        callbackRef.current(newMutation)
        // 刷新toolbox
        mainWorkspace.current?.refreshToolboxSelection_();
        // 滚动到自定义积木category
        // mainWorkspace.current?.toolbox_.scrollToCategoryById('myBlocks');
        // 弹窗关闭
        closeModal();
    };
    const handleToggleWrap = () => {
        if (mutationRootRef.current) {
            const newWarp = mutationRootRef.current.getWarp();
            mutationRootRef.current.setWarp(newWarp);
            setWrap(newWarp);
        }
    };

    return (
        <>
            {isOpen && (
                <CustomProcedures
                    intl={intl}
                    componentRef={onOpen}
                    wrap={wrap}
                    onAddBoolean={() => mutationRootRef.current?.addBooleanExternal()}
                    onAddLabel={() => mutationRootRef.current?.addLabelExternal()}
                    onAddNumber={() => { mutationRootRef.current?.addNumberExternal() }}
                    onAddTextNumber={() => {
                        if (IS_SCRATCH_MODE) return mutationRootRef.current?.addStringNumberExternal()
                        return mutationRootRef.current?.addStringExternal()
                    }}
                    onCancel={closeModal}
                    onOk={handleOk}
                    onToggleWarp={handleToggleWrap}
                />
            )}
        </>
    );
};

const CustomProcedures = (props) => (
    <Modal
        className={styles.modalContent}
        contentLabel={props.intl.formatMessage(messages.myblockModalTitle)}
        onRequestClose={props.onCancel}
    >
        {/* <Box className={styles.workspace} componentRef={props.componentRef} /> */}
        <div
            className={styles.workspace}
            ref={props.componentRef}
            id="custome-procedure"
        />
        <Box className={styles.body}>
            <div className={styles.optionsRow}>
                <div
                    className={styles.optionCard}
                    role="button"
                    onClick={props.onAddTextNumber}
                >
                    <img className={styles.optionIcon} src={textInputIcon} alt="" />
                    <div className={styles.optionTitle}>
                        <FormattedMessage
                            defaultMessage="Add an input"
                            description="Label for button to add a number/text input"
                            id="gui.customProcedures.addAnInputNumberText"
                        />
                    </div>
                    <div className={styles.optionDescription}>
                        {IS_SCRATCH_MODE ?
                            <FormattedMessage
                                defaultMessage="number or text"
                                description="Description of the number/text input type"
                                id="gui.customProcedures.numberTextType"
                            /> :
                            <FormattedMessage
                                defaultMessage="text"
                                description="Description of the text input type"
                                id="gui.customProcedures.textType"
                            />}
                    </div>
                </div>
                {!IS_SCRATCH_MODE && <div
                    className={styles.optionCard}
                    role="button"
                    onClick={props.onAddNumber}
                >
                    <img
                        className={styles.optionIcon}
                        src={textInputIcon}
                        alt=""
                    />
                    <div className={styles.optionTitle}>
                        <FormattedMessage
                            defaultMessage="Add an input"
                            description="Label for button to add a number/text input"
                            id="gui.customProcedures.addAnInputNumberText"
                        />
                    </div>
                    <div
                        className={styles.optionDescription}
                        style={{
                            display: 'inline'
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="text"
                            description="Description of the number input type"
                            id="gui.customProcedures.numberType"
                        />
                    </div>
                </div>}
                <div
                    className={styles.optionCard}
                    role="button"
                    onClick={props.onAddBoolean}
                >
                    <img className={styles.optionIcon} src={booleanInputIcon} alt="" />
                    <div className={styles.optionTitle}>
                        <FormattedMessage
                            defaultMessage="Add an input"
                            description="Label for button to add a boolean input"
                            id="gui.customProcedures.addAnInputBoolean"
                        />
                    </div>
                    <div className={styles.optionDescription}>
                        <FormattedMessage
                            defaultMessage="boolean"
                            description="Description of the boolean input type"
                            id="gui.customProcedures.booleanType"
                        />
                    </div>
                </div>
                <div
                    className={styles.optionCard}
                    role="button"
                    onClick={props.onAddLabel}
                >
                    <img className={styles.optionIcon} src={labelIcon} alt="" />
                    <div className={styles.optionTitle}>
                        <FormattedMessage
                            defaultMessage="Add a label"
                            description="Label for button to add a label"
                            id="gui.customProcedures.addALabel"
                        />
                    </div>
                </div>
            </div>
            {
                IS_SCRATCH_MODE && <div className={styles.checkboxRow}>
                    <label>
                        <input
                            checked={props.warp}
                            type="checkbox"
                            onChange={props.onToggleWarp}
                        />
                        <FormattedMessage
                            defaultMessage="Run without screen refresh"
                            description="Label for checkbox to run without screen refresh"
                            id="gui.customProcedures.runWithoutScreenRefresh"
                        />
                    </label>
                </div>
            }
            <Box className={styles.buttonRow}>
                <button className={styles.cancelButton} onClick={props.onCancel}>
                    <FormattedMessage
                        defaultMessage="Cancel"
                        description="Label for button to cancel custom procedure edits"
                        id="gui.customProcedures.cancel"
                    />
                </button>
                <button className={styles.okButton} onClick={props.onOk}>
                    <FormattedMessage
                        defaultMessage="OK"
                        description="Label for button to save new custom procedure"
                        id="gui.customProcedures.ok"
                    />
                </button>
            </Box>
        </Box>
    </Modal>
);

export default FunctionPrompt;
