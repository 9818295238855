import { createSlice } from '@reduxjs/toolkit';
import { RootState } from "../store";


interface AssetLibStatus {
    spriteLibVisible: boolean
    costumeLibVisible: boolean
    soundLibVisible: boolean
    backdropLibVisible: boolean
    extensionLibVisible: boolean
    tipsLibraryVisible: boolean
}

const initialState: AssetLibStatus = {
    spriteLibVisible: false,
    costumeLibVisible: false,
    soundLibVisible: false,
    backdropLibVisible: false,
    extensionLibVisible: false,
    tipsLibraryVisible:false
}

export const assetLibStatusSlice = createSlice({
    name: 'assetLibStatus',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        openSpriteLib: (state) => {
            state.spriteLibVisible = true;
        },
        closeSpriteLib: (state) => {
            state.spriteLibVisible = false;
        },
        openCostumeLib: (state) => {
            state.costumeLibVisible = true;
        },
        closeCostumeLib: (state) => {
            state.costumeLibVisible = false;
        },
        openSoundLib: (state) => {
            state.soundLibVisible = true;
        },
        closeSoundLib: (state) => {
            state.soundLibVisible = false;
        },
        openBackdropLib: (state) => {
            state.backdropLibVisible = true;
        },
        closeBackdropLib: (state) => {
            state.backdropLibVisible = false;
        },
        openDeviceLib: (state) => {
            state.extensionLibVisible = true;
        },
        closeDeviceLib: (state) => {
            state.extensionLibVisible = false;
        },
        openExtensionLib: (state) => {
            state.extensionLibVisible = true;
        },
        closeExtensionLib: (state) => {
            state.extensionLibVisible = false;
        },
        openTipsLibrary: (state) => {
          state.tipsLibraryVisible = true;
        },
        closeTipsLibrary: (state) => {
          state.tipsLibraryVisible = false;
        }
    },
})

export const {
    openSpriteLib, closeSpriteLib,
    openCostumeLib, closeCostumeLib,
    openSoundLib, closeSoundLib,
    openBackdropLib, closeBackdropLib,
    openDeviceLib, closeDeviceLib,
    openExtensionLib, closeExtensionLib,
    openTipsLibrary, closeTipsLibrary
} = assetLibStatusSlice.actions;
export const selectAssetLibStatus = (state: RootState) => state.assetLibStatus

export default assetLibStatusSlice.reducer
