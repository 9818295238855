/* eslint-disable import/no-anonymous-default-export */
import { FormattedMessage } from "react-intl";
import huskyLensPro from './HUSKYLENS(edu).png';
import machinelearningModalImage from './machinelearning-modal.png';
import serialImage from './connection-modal-component/icons/serialPort.svg';
import SerialCommunicationImage from './serialcommunication.jpg'

export default [
  {
    name: (
      <FormattedMessage
        default="HUSKYLENS EDU"
        defaultMessage="HUSKYLENS EDU"
        description="Name for the 'HUSKYLENS AI Camera' extension"
        id="gui.extension.huskyLensPro.name"
      />
    ),
    extensionId: 'ext-DFRobot-HuskyLensPro',
    iconURL: huskyLensPro,
    description: (
      <FormattedMessage
        defaultMessage="Use HUSKYLENS to connect directly to a computer to learn the basics of AI"
        description="description for the 'HUSKYLENS AI Camera' extension"
        id="gui.extension.huskyLensPro.description"
      />
    ),
    launchPeripheralConnectionFlow: true,
    connectionIconURL: machinelearningModalImage,
    connectionSmallIconURL: machinelearningModalImage,
    connectionTipIconURL: machinelearningModalImage,
    connectingMessage: (
      <FormattedMessage
        defaultMessage="all this for you with offline, no more interesting"
        description="all this for you with offline, no more interesting"
        id="gui.extension.huskyLensPro.connectingMessage"
      />
    ),
    helpLink: 'https://mindplus.dfrobot.com.cn/huskylens',
  },
  {
    name: (
      <FormattedMessage
        defaultMessage="Serial communication"
        description="Name for the 'serialPort' extension"
        id="gui.extension.serialPort.name"
      />
    ),
    extensionId: 'ext-DFRobot-serialPort',
    iconURL: SerialCommunicationImage,
    description: (
      <FormattedMessage defaultMessage="data communication between upload mode and real-time mode"
        description="description for the 'serialPort' extension"
        id="gui.extension.serialPort.description" />
    ),
    launchPeripheralConnectionFlow: true,
    useAutoScan: false,
    connectionIconURL: serialImage,
    connectionSmallIconURL: serialImage,
    connectionTipIconURL: serialImage,
    connectingMessage: (
      <FormattedMessage defaultMessage="all this for you with offline, no more interesting"
        description="all this for you with offline, no more interesting"
        id="gui.extension.huskyLensPro.connectingMessage"
      />
    ),
    helpLink: 'https://mindplus.dfrobot.com.cn/scsstx',
  }
]
