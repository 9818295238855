import React from "react";
import styles from "./monitor.module.scss";
import recordIcon from "./icon--add-sound-record.svg";

class SpeechRecordMonitor extends React.Component {
  render() {
    return (
      <div className={styles.speechRecordWrapper}>
        <img src={recordIcon}  alt=""/>
      </div>
    );
  }
}
SpeechRecordMonitor.propTypes = {};

export default SpeechRecordMonitor;
