// Do not edit this file; automatically generated by build.py.
'use strict';


Blockly.constants={};Blockly.DRAG_RADIUS=3;Blockly.FLYOUT_DRAG_RADIUS=10;Blockly.SNAP_RADIUS=48;Blockly.CONNECTING_SNAP_RADIUS=68;Blockly.CURRENT_CONNECTION_PREFERENCE=20;Blockly.BUMP_DELAY=0;Blockly.COLLAPSE_CHARS=30;Blockly.LONGPRESS=750;Blockly.LINE_SCROLL_MULTIPLIER=15;Blockly.SOUND_LIMIT=100;Blockly.DRAG_STACK=!0;Blockly.HSV_SATURATION=.45;Blockly.HSV_VALUE=.65;Blockly.SPRITE={width:96,height:124,url:"sprites.png"};Blockly.SVG_NS="http://www.w3.org/2000/svg";Blockly.HTML_NS="http://www.w3.org/1999/xhtml";
Blockly.INPUT_VALUE=1;Blockly.OUTPUT_VALUE=2;Blockly.NEXT_STATEMENT=3;Blockly.PREVIOUS_STATEMENT=4;Blockly.DUMMY_INPUT=5;Blockly.ALIGN_LEFT=-1;Blockly.ALIGN_CENTRE=0;Blockly.ALIGN_RIGHT=1;Blockly.DRAG_NONE=0;Blockly.DRAG_STICKY=1;Blockly.DRAG_BEGIN=1;Blockly.DRAG_FREE=2;Blockly.OPPOSITE_TYPE=[];Blockly.OPPOSITE_TYPE[Blockly.INPUT_VALUE]=Blockly.OUTPUT_VALUE;Blockly.OPPOSITE_TYPE[Blockly.OUTPUT_VALUE]=Blockly.INPUT_VALUE;Blockly.OPPOSITE_TYPE[Blockly.NEXT_STATEMENT]=Blockly.PREVIOUS_STATEMENT;
Blockly.OPPOSITE_TYPE[Blockly.PREVIOUS_STATEMENT]=Blockly.NEXT_STATEMENT;Blockly.TOOLBOX_AT_TOP=0;Blockly.TOOLBOX_AT_BOTTOM=1;Blockly.TOOLBOX_AT_LEFT=2;Blockly.TOOLBOX_AT_RIGHT=3;Blockly.OUTPUT_SHAPE_HEXAGONAL=1;Blockly.OUTPUT_SHAPE_ROUND=2;Blockly.OUTPUT_SHAPE_SQUARE=3;Blockly.Categories={motion:"motion",looks:"looks",sound:"sounds",pen:"pen",data:"data",dataLists:"data-lists",event:"events",control:"control",sensing:"sensing",operators:"operators",more:"more"};Blockly.DELETE_AREA_NONE=null;
Blockly.DELETE_AREA_TRASH=1;Blockly.DELETE_AREA_TOOLBOX=2;Blockly.VARIABLE_CATEGORY_NAME="VARIABLE";Blockly.STRING_NUMBER_VARIABLE_CATEGORY_NAME="STRING_NUMBER_VARIABLE";Blockly.PROCEDURE_CATEGORY_NAME="PROCEDURE";Blockly.RENAME_VARIABLE_ID="RENAME_VARIABLE_ID";Blockly.DELETE_VARIABLE_ID="DELETE_VARIABLE_ID";Blockly.NEW_BROADCAST_MESSAGE_ID="NEW_BROADCAST_MESSAGE_ID";Blockly.BROADCAST_MESSAGE_VARIABLE_TYPE="broadcast_msg";Blockly.LIST_VARIABLE_TYPE="list";Blockly.SCALAR_VARIABLE_TYPE="";
Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE="procedures_definition";Blockly.PROCEDURES_PROTOTYPE_BLOCK_TYPE="procedures_prototype";Blockly.PROCEDURES_CALL_BLOCK_TYPE="procedures_call";Blockly.StatusButtonState={READY:"ready",NOT_READY:"not ready"};Blockly.LanguageJavascript="javascript";Blockly.LanguageCpp="cpp";Blockly.LanguageMicropython="micropython";Blockly.LanguagePython="python";
Blockly.Blocks.colour={};function randomColour(){return"#"+("00000"+Math.floor(Math.random()*Math.pow(2,24)).toString(16)).substr(-6)}Blockly.Blocks.colour_picker={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_colour_slider",name:"COLOUR",colour:a&&(a.colour||a.color)||randomColour()}],outputShape:Blockly.OUTPUT_SHAPE_ROUND,output:"Colour"})}};
Blockly.Blocks.colour_palette={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_colour_palette",name:"COLOUR",colour:a&&(a.colour||a.color)||"#0000ff",colours:a&&a.colours,columns:a&&a.columns}],outputShape:Blockly.OUTPUT_SHAPE_ROUND,output:"Colour"})}};/*

 Visual Blocks Editor

 Copyright 2016 Massachusetts Institute of Technology
 All rights reserved.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

   http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
Blockly.Colours={motion:{primary:"#4C97FF",secondary:"#4280D7",tertiary:"#3373CC"},looks:{primary:"#9966FF",secondary:"#855CD6",tertiary:"#774DCB"},sounds:{primary:"#CF63CF",secondary:"#C94FC9",tertiary:"#BD42BD"},control:{primary:"#FFAB19",secondary:"#EC9C13",tertiary:"#CF8B17"},event:{primary:"#FFBF00",secondary:"#E6AC00",tertiary:"#CC9900"},sensing:{primary:"#5CB1D6",secondary:"#47A8D1",tertiary:"#2E8EB8"},pen:{primary:"#0fBD8C",secondary:"#0DA57A",tertiary:"#0B8E69"},operators:{primary:"#59C059",
secondary:"#46B946",tertiary:"#389438"},data:{primary:"#FF8C1A",secondary:"#FF8000",tertiary:"#DB6E00"},data_string:{primary:"#921AFF",secondary:"#841FE0",tertiary:"#6403BC"},data_lists:{primary:"#FF661A",secondary:"#FF5500",tertiary:"#E64D00"},more:{primary:"#FF6680",secondary:"#FF4D6A",tertiary:"#FF3355"},text:"#575E75",workspace:"#F9F9F9",toolboxHover:"#4C97FF",toolboxSelected:"#e9eef2",toolboxText:"#575E75",toolbox:"#FFFFFF",flyout:"#F9F9F9",buttonInFlyoutHover:"#FFFFFF",scrollbar:"#CECDCE",scrollbarHover:"#CECDCE",
textField:"#FFFFFF",insertionMarker:"#000000",insertionMarkerOpacity:.2,dragShadowOpacity:.3,stackGlow:"#FFF200",stackGlowSize:4,stackGlowOpacity:1,replacementGlow:"#FFFFFF",replacementGlowSize:2,replacementGlowOpacity:1,colourPickerStroke:"#FFFFFF",fieldShadow:"rgba(0,0,0,0.1)",dropDownShadow:"rgba(0, 0, 0, .3)",numPadBackground:"#547AB2",numPadBorder:"#435F91",numPadActiveBackground:"#435F91",numPadText:"white",valueReportBackground:"#FFFFFF",valueReportBorder:"#AAAAAA"};
Blockly.Colours.overrideColours=function(a){if(a)for(var b in a)if(a.hasOwnProperty(b)&&Blockly.Colours.hasOwnProperty(b)){var c=a[b];if(goog.isObject(c))for(var d in c)c.hasOwnProperty(d)&&Blockly.Colours[b].hasOwnProperty(d)&&(Blockly.Colours[b][d]=c[d]);else Blockly.Colours[b]=c}};Blockly.Blocks.math={};Blockly.Blocks.math_number={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_number",name:"NUM",value:"0",max:a&&a.max,min:a&&a.min,noLimit:a&&a.noLimit,"class":a&&a.validate,isHex:a&&a.isHex,precision:a&&a.precision}],output:"Number",outputShape:Blockly.OUTPUT_SHAPE_ROUND,colour:Blockly.Colours.textField,colourSecondary:Blockly.Colours.textField,colourTertiary:Blockly.Colours.textField})}};
Blockly.Blocks.math_integer={init:function(){this.jsonInit({message0:"%1",args0:[{type:"field_number",name:"NUM",precision:1}],output:"Number",outputShape:Blockly.OUTPUT_SHAPE_ROUND,colour:Blockly.Colours.textField,colourSecondary:Blockly.Colours.textField,colourTertiary:Blockly.Colours.textField})}};
Blockly.Blocks.math_whole_number={init:function(){this.jsonInit({message0:"%1",args0:[{type:"field_number",name:"NUM",min:0,precision:1}],output:"Number",outputShape:Blockly.OUTPUT_SHAPE_ROUND,colour:Blockly.Colours.textField,colourSecondary:Blockly.Colours.textField,colourTertiary:Blockly.Colours.textField})}};
Blockly.Blocks.math_positive_number={init:function(){this.jsonInit({message0:"%1",args0:[{type:"field_number",name:"NUM",min:0}],output:"Number",outputShape:Blockly.OUTPUT_SHAPE_ROUND,colour:Blockly.Colours.textField,colourSecondary:Blockly.Colours.textField,colourTertiary:Blockly.Colours.textField})}};
Blockly.Blocks.math_angle={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_angle",name:"NUM",value:90,edge:a&&a.edge}],output:"Number",outputShape:Blockly.OUTPUT_SHAPE_ROUND,colour:Blockly.Colours.textField,colourSecondary:Blockly.Colours.textField,colourTertiary:Blockly.Colours.textField})}};
Blockly.Blocks.math_slider={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_slider",name:"NUM",max:a&&a.rangeMax||255,min:a&&a.rangeMin||0,step:a&&a.step||1,defaultValue:90}],output:"Number",outputShape:Blockly.OUTPUT_SHAPE_ROUND,colour:Blockly.Colours.textField,colourSecondary:Blockly.Colours.textField,colourTertiary:Blockly.Colours.textField})}};Blockly.Blocks.matrix={};Blockly.Blocks.matrix={init:function(){this.jsonInit({message0:"%1",args0:[{type:"field_matrix",name:"MATRIX"}],outputShape:Blockly.OUTPUT_SHAPE_ROUND,output:"Number",extensions:["colours_pen"]})}};
Blockly.Blocks.matrix_icons={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_matrix_icons",name:"MATRIX",row:a&&a.row||5,column:a&&a.column||5,isSplit:a&&a.isSplit||!1,isRGB:a&&a.isRGB||!1,disabledChannel:a&&a.disabledChannel||[],isSwitch:a&&a.isSwitch,rgbColor:a&&a.rgbColor,builtinMatrixs:a&&a.builtinMatrixs||[]}],outputShape:Blockly.OUTPUT_SHAPE_ROUND,output:"Number",colour:a&&a.colour||"#F08080"})}};Blockly.Blocks.note={};Blockly.Blocks.note={init:function(){this.jsonInit({message0:"%1",args0:[{type:"field_note",name:"NOTE",value:60}],outputShape:Blockly.OUTPUT_SHAPE_ROUND,output:"Number",colour:Blockly.Colours.textField,colourSecondary:Blockly.Colours.textField,colourTertiary:Blockly.Colours.textField})}};Blockly.Blocks.Piano={};Blockly.Blocks.piano={init:function(){this.jsonInit({message0:"%1",args0:[{type:"field_piano",name:"PIANO",value:131,theme:"blocklyCommonPiano",width:40,height:24}],output:"String",outputShape:Blockly.OUTPUT_SHAPE_ROUND,colour:Blockly.Colours.textField,colourSecondary:Blockly.Colours.textField,colourTertiary:Blockly.Colours.textField})}};Blockly.Blocks.settings={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_settings",name:"SETTINGS",componentInfo:a&&a.componentInfo,width:32,height:32}],extensions:["output_string"],colour:a&&a.color||"#5867D6",colourSecondary:a&&a.color||"#5867D6",colourTertiary:a&&a.color||"#5867D6"})}};
Blockly.Blocks.form_settings={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_form_settings",name:"FORM_SETTINGS",width:32,height:32,formItems:a.formItems||[],formOption:a.formOption||{}}],colour:"#87C482",colourSecondary:a&&a.color||"#7FB57B",colourTertiary:a&&a.color||"#559E4F",extensions:["output_string"]})}};
Blockly.Blocks.text_preview={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_text_preview",width:32,height:24}],extensions:["output_string"],colour:a&&a.color||"#87C482",colourSecondary:a&&a.color||"#7FB57B",colourTertiary:a&&a.color||"#559E4F"})}};
Blockly.Blocks.img_preview={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_img_preview",name:"ESP32_IMG",initialInfo:'{"imgSrc": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAACHUlEQVRoQ+2Y4ZLDIAiEk/d/6HTsnB1CwF3QeEmb+9l4yscCguuyLNsy6W/b7KPWde22oOwwBaRCaKO936NkU0CQseg7AzUNBIVPgUFrWkAPCCN3XcN4m1lzCUWKEV7o3CZHCsRXVC0LRN4pPUlewy2U7PpCixigc6A3J3S+0CA6NCIetcIqCoKcSIG0kpFJVEuNVvJLb2uHeQ6kQbIVx1ODAYk4EIJkPK49mu2vUPhJ2yBIrTgZRXrUQOfqSkiDHKrEX+stvea16c0budHCD1dE03sVhAlD2bZ87gAHRu6HCgaliJbZ8lQEAuWQd4HW8LbOokCsOySbwDrMmDyyQlafD0FaB0U64Mhay0moi3iDZJI0YtgZaw+KtCiQF84w0LvV0VlrBflvo5Gh1vddu3JnkF11e0AysXDC/3yq6s8pMqNERwvOrvuVinhDywkRAbdsOc5qVQ7ld4bnIUXj6Uj2fa4i1iLm0Jlrihq1ae0GkbE8QkEdKsgxdGhVam9Dr/NFBljfM3vdHkSGlZ5ZzGS/qiJNkNrGS7luDVKk0sTWfK3n7N5kjxYOa335bTdYeZtmEjKT+Ki4WC821TYXxJutq3LZAQgBsu9negSnQViwSKhZRjMPDbpivZ0rZ/Zo0+apwuzDGuwpOEyRVoggVRhQFIJTQJARI74/ICO8OHKPr1DEffvNPkCP9HBkr98BiXjlCmtlGb/tI7ZulV4HB1ENaBCwMgAAAABJRU5ErkJggg==", "imgAddr": "", "imgWidth": "50", "imgHeight": "50"}',width:32,
height:32}],extensions:["output_string"],colour:a&&a.color||"#87C482",colourSecondary:a&&a.color||"#7FB57B",colourTertiary:a&&a.color||"#559E4F"})}};Blockly.Blocks.img_setting={init:function(a){a=a||{};this.jsonInit({message0:"%1",args0:[{type:"field_img_setting",initialInfo:"{}",name:"pictureai_img",width:32,height:32}],colour:a.color1||"#8b92e8",colourSecondary:a.color2||"#8b92e8",colourTertiary:a.color3||"#889ce8",extensions:["output_string"]})}};
Blockly.Blocks.online_img_setting={init:function(){this.jsonInit({message0:"%1",args0:[{type:"field_online_img_setting",name:"pictureai_webimg",width:32,height:32}],colour:"#8b92e8",colourSecondary:"#8b92e8",colourTertiary:"#889ce8",extensions:["output_string"]})}};
Blockly.Blocks.dir_setting={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_dir_setting",width:32,height:32,name:"pictureai_dir"}],colour:a&&a.color1||"#8b92e8",colourSecondary:a&&a.color2||"#8b92e8",colourTertiary:a&&a.color3||"#889ce8",extensions:["output_string"]})}};
Blockly.Blocks.textarea={init:function(a){var b=a?a.color:"";this.jsonInit({message0:"%1",args0:[{type:"field_textarea",name:"CODE",inputValue:(a?a.inputValue:"")||"",width:32,height:32}],extensions:["output_string"],colour:b||"",colourSecondary:b||"",colourTertiary:b||""})}};
Blockly.Blocks.builtin_img={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_builtin_img",name:"MPYIMG",value:a&&a.value||"face/1.pbm",width:32,height:32}],extensions:["output_string"],colour:a&&a.color||"#87C482",colourSecondary:a&&a.color||"#7FB57B",colourTertiary:a&&a.color||"#559E4F"})}};
Blockly.Blocks.cameralist_menu={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_dropdown",name:"SETTINGS",options:[]}],extensions:["output_string"],colour:a&&a.color||"#5867D6",colourSecondary:a&&a.color||"#5867D6",colourTertiary:a&&a.color||"#5867D6"})}};
Blockly.Blocks.obloq_settings={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_obloq_settings",name:"OBLOQ",initialInfo:'{"wifi": {"username": "dfrobotYanfa", "pwd": "hidfrobot"}, "iot": {"id": "SJISVLvoz", "pwd": "r1xUSVUwsz", "topic": "BkMH48Djf"}, "server": "siot"}',width:32,height:32,validate:a&&a.validate||null}],extensions:["output_string"],colour:a&&a.color||"#5867D6",colourSecondary:a&&a.color||"#5867D6",colourTertiary:a&&a.color||"#5867D6"})}};
Blockly.Blocks.mqtt_settings={init:function(){this.jsonInit({message0:"%1",args0:[{type:"field_mqtt_settings",name:"MQTT",initialInfo:'{"platform": "easyiot", "params": {"server1": "cn-shanghai", "server2": "mqtt.heclouds.com", "server5": "183.230.40.96", "server3": "iot.dfrobot.com.cn", "topic": ""}}',width:32,height:32}],extensions:["output_string"],colour:"#8B92E8",colourSecondary:"#8B92E8",colourTertiary:"#8B92E8"})}};Blockly.Blocks.texts={};Blockly.Blocks.text={init:function(a){this.jsonInit({message0:"%1",args0:[{type:"field_input",name:"TEXT",limit:a&&a.limit?a.limit:null,"class":a&&a.validate}],output:"String",outputShape:Blockly.OUTPUT_SHAPE_ROUND,colour:Blockly.Colours.textField,colourSecondary:Blockly.Colours.textField,colourTertiary:Blockly.Colours.textField})}};
Blockly.Blocks.text_infrared_btns={init:function(){this.jsonInit({message0:"%1",args0:[{type:"field_infrared_btns",name:"TEXT",arrbtns:[["Power","VOL+","FUNC/STOP"],["Back","Start/Pause","Next"],["Down","VOL-","Up"],["0","EQ","ST/REPT"],["1","2","3"],["4","5","6"],["7","8","9"]],value:"Power",width:40,height:24}],output:"String",outputShape:Blockly.OUTPUT_SHAPE_ROUND,colour:Blockly.Colours.textField,colourSecondary:Blockly.Colours.textField,colourTertiary:Blockly.Colours.textField})}};
