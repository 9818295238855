

// 登录
export const login_API = (username: string, password: string) => {


    return Promise.resolve({
        token: "xxxxx"
    })
}

// 注册
export const signupWithPhone_API = () => {

}

export interface UserInfo {
    username: string // 用户名
    thumbnailUrl?: string // 头像
}

// 获取用户信息(token)
export const getUserInfo_API = (): Promise<UserInfo> => {

    return Promise.resolve({
        username: "张三"
    })
}

// 获取用户消息(token)
export const getUserMessages_API = () => {
    
}
