import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

interface Position {
    x: number
    y: number
}

interface AssetDragState {
    dragging: boolean
    currentOffset: Position|null
    initialOffset: Position|null
    img: any
    dragType?: string|null
    index?: number|null
    payload?: any
}

const initialState = {
    dragging: false,
    currentOffset: null,
    initialOffset: null,
    dragType: null,
    index: null,
    img: null
}

export const assetDragSlice = createSlice({
    name: 'assetDrag',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        updateAssetDrag: (state, action: PayloadAction<Partial<AssetDragState>>) => {
            return Object.assign({}, state, action.payload);
        }
    },
})

export const {updateAssetDrag} = assetDragSlice.actions;
export const selectAssetDragDragging = (state: RootState) => state.assetDrag.dragging
export const selectAssetDragDragType = (state: RootState) => state.assetDrag.dragType
export const selectAssetDragIndex = (state: RootState) => state.assetDrag.index

export const selectAssetDrag = (state: RootState) => state.assetDrag
export default assetDragSlice.reducer
