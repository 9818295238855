import { useCallback, useEffect, useRef, useState } from "react";
import { vm } from "lib/scratch-vm";
import { getEventXY } from "lib/touch-utils";
import { useDispatch, useSelector } from "react-redux";
import {
  deactivateColorPicker,
  selectColorPicking,
} from "redux/color-picking/colorPickingSlice";
import { selectMode } from "redux/mode/modeSlice";
import { set } from "immutable";

// 鼠标事件(1.处理角色的拖动 2.处理画笔的取色 3.实时刷新vm中的鼠标位置, 用于侦测模块的功能)
export const useMouseEvent = (rectRef: any, updateRect: Function) => {
  const colorPicking = useSelector(selectColorPicking);
  const intervalIdRef = useRef<any>();
  const pickXRef = useRef<number>();
  const pickYRef = useRef<number>();
  // const [pickX,setPickX] = useState<number>();
  // const [pickY,setPickY] = useState<number>();
  const [colorInfo, setColorInfo] = useState<any>();
  const { isFullScreen, isPlayerOnly } = useSelector(selectMode);
  const dispatch = useDispatch();
  // mousedown
  const onMousedownRef = useRef<any>();
  // mouseup
  const onMouseupRef = useRef<any>();
  // mousemove
  const onMousemoveRef = useRef<any>();
  // wheel 滚轮
  const onWheelRef = useRef<any>();
  // 拖动层canvas
  const dragCanvasRef = useRef<any>();
  // 开始拖动处理函数
  const onDragStartRef = useRef<any>();
  // 停止拖动处理函数
  const onDragStopRef = useRef<any>();

  const mouseStatus = useRef<any>({
    mouseDown: null, // 鼠标是否按下
    mouseDownPosition: null, // 鼠标按下位置
    mouseDownTimeoutId: null, // 拖动400ms延时判断(是否拖动: 1.移动距离超过最小距离 2.鼠标按下400ms)
    dragId: null, // 拖动的target id
    isDragging: false, // 是否正在拖动
    dragOffset: null, // [x, y] target偏移量
  });
  const getColorInfo = (x: number, y: number) => {
    return {
      x: x,
      y: y,
      ...vm.renderer.extractColor(x, y, 20),
    };
  };
  useEffect(() => {
    if (colorPicking.active) {
      intervalIdRef.current = setInterval(() => {
        const color = getColorInfo(pickXRef.current as number, pickYRef.current as number);
        setColorInfo(color);
      }, 30);
    } else {
      clearInterval(intervalIdRef.current);
    }
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [colorPicking.active]);

  // 清除拖动定时器
  const cancelMouseDownTimeout = useCallback(() => {
    if (mouseStatus.current.mouseDownTimeoutId !== null) {
      clearTimeout(mouseStatus.current.mouseDownTimeoutId);
    }
    mouseStatus.current.mouseDownTimeoutId = null;
  }, []);

  // 清除拖动角色
  const clearDragCanvas = () => {
    dragCanvasRef.current.width = dragCanvasRef.current.height = 0;
    dragCanvasRef.current.style.display = "none";
  };

  // 绘制拖动角色
  const drawDragCanvas = useCallback((drawableData, x, y) => {
    const {
      imageData,
      x: boundsX,
      y: boundsY,
      width: boundsWidth,
      height: boundsHeight,
    } = drawableData;
    dragCanvasRef.current.width = imageData.width;
    dragCanvasRef.current.height = imageData.height;
    // On high-DPI devices, the canvas size in layout-pixels is not equal to the size of the extracted data.
    dragCanvasRef.current.style.width = `${boundsWidth}px`;
    dragCanvasRef.current.style.height = `${boundsHeight}px`;

    dragCanvasRef.current.getContext("2d").putImageData(imageData, 0, 0);
    // Position so that pick location is at (0, 0) so that  positionDragCanvas()
    // can use translation to move to mouse position smoothly.
    dragCanvasRef.current.style.left = `${boundsX - x}px`;
    dragCanvasRef.current.style.top = `${boundsY - y}px`;
    dragCanvasRef.current.style.display = "block";
  }, []);

  // 定位拖动角色
  const positionDragCanvas = useCallback((mouseX, mouseY) => {
    // mouseX/Y are relative to stage top/left, and dragCanvas is already
    // positioned so that the pick location is at (0,0).
    dragCanvasRef.current.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
  }, []);

  const getScratchCoords = useCallback((x, y) => {
    const nativeSize = vm.renderer.getNativeSize();
    return [
      (nativeSize[0] / rectRef.current.width) * (x - rectRef.current.width / 2),
      (nativeSize[1] / rectRef.current.height) *
        (y - rectRef.current.height / 2),
    ];
  }, []);

  // drag start
  useEffect(() => {
    onDragStartRef.current = (x, y) => {
      // 已经开始拖动 return
      if (mouseStatus.current.dragId) return;
      // 通过坐标在scratch-render找到绘制id
      const drawableId = vm.renderer.pick(x, y);
      if (drawableId === null) return;
      // 获取targetId
      const targetId = vm.getTargetIdForDrawableId(drawableId);
      if (targetId === null) return;

      const target = vm.runtime.getTargetById(targetId);
      // 是否能拖动(1.在全屏/播放模式下,target设置了不能拖动)
      if ((isPlayerOnly || isFullScreen) && !target.draggable) return;
      // Dragging always brings the target to the front
      target.goToFront();

      const [scratchMouseX, scratchMouseY] = getScratchCoords(x, y);
      const offsetX = target.x - scratchMouseX;
      const offsetY = -(target.y + scratchMouseY);

      vm.startDrag(targetId);
      mouseStatus.current = {
        ...mouseStatus.current,
        isDragging: true,
        dragId: targetId,
        dragOffset: [offsetX, offsetY],
      };
      if (!isFullScreen && !isPlayerOnly) {
        // 获取被拖动target的图像数据
        const drawableData = vm.renderer.extractDrawableScreenSpace(drawableId);
        // 将其绘制到拖动层
        drawDragCanvas(drawableData, x, y);
        positionDragCanvas(x, y);
        // 将scratch-render中的当前角色设置为隐藏
        vm.postSpriteInfo({ visible: false });
        vm.renderer.draw();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen, isPlayerOnly, drawDragCanvas]);

  // drag stop
  useEffect(() => {
    onDragStopRef.current = (mouseX, mouseY) => {
      const dragId = mouseStatus.current.dragId;
      const commonStopDragActions = () => {
        vm.stopDrag(dragId);
        mouseStatus.current = {
          ...mouseStatus.current,
          isDragging: false,
          dragOffset: null,
          dragId: null,
        };
      };
      if (!isFullScreen && !isPlayerOnly) {
        // Need to sequence these actions to prevent flickering.
        const spriteInfo: any = { visible: true };
        // First update the sprite position if dropped in the stage.
        if (
          mouseX > 0 &&
          mouseX < rectRef.current.width &&
          mouseY > 0 &&
          mouseY < rectRef.current.height
        ) {
          const spritePosition = getScratchCoords(mouseX, mouseY);
          spriteInfo.x = spritePosition[0] + mouseStatus.current.dragOffset[0];
          spriteInfo.y = -(
            spritePosition[1] + mouseStatus.current.dragOffset[1]
          );
          spriteInfo.force = true;
        }
        vm.postSpriteInfo(spriteInfo);
        // 拖动结束, 清除拖动层
        clearDragCanvas();
        commonStopDragActions();
        vm.renderer.draw();
      } else {
        commonStopDragActions();
      }
    };
  }, [getScratchCoords, isFullScreen, isPlayerOnly]);

  // mouse down
  useEffect(() => {
    onMousedownRef.current = (e) => {
      updateRect();
      const { x, y } = getEventXY(e);
      const mousePosition = [x - rectRef.current.left, y - rectRef.current.top];
      //判断是画笔取色 还是 角色拖动
      if (colorPicking.active && colorInfo) {
        // todo: 画笔取色的逻辑判断
        console.log("colorInfo===", colorInfo);
        const { r, g, b } = colorInfo.color;
        const componentToString = (c) => {
          const hex = c.toString(16);
          return hex.length === 1 ? `0${hex}` : hex;
        };
        const colorString = `#${componentToString(r)}${componentToString(
          g
        )}${componentToString(b)}`;
        dispatch(deactivateColorPicker({ color: colorString }));
        setColorInfo(null);
      } else {
        // 1.拖动判断
        if (e.button === 0 || (window.TouchEvent && e instanceof TouchEvent)) {
          mouseStatus.current = {
            mouseDown: true,
            mouseDownPosition: mousePosition,
            mouseDownTimeoutId: setTimeout(
              // 400ms后开始拖动
              () => onDragStartRef.current(mousePosition[0], mousePosition[1]),
              400
            ),
          };
        }
        // 2.vm记录鼠标位置
        const data = {
          isDown: true,
          x: mousePosition[0],
          y: mousePosition[1],
          canvasWidth: rectRef.current.width,
          canvasHeight: rectRef.current.height,
        };
        vm.postIOData("mouse", data);
        if (e.preventDefault) {
          // Prevent default to prevent touch from dragging page
          e.preventDefault();
          // But we do want any active input to be blurred
          if (
            document.activeElement &&
            (document.activeElement as HTMLInputElement).blur
          ) {
            (document.activeElement as HTMLInputElement).blur();
          }
        }
      }
    };
  }, [updateRect, colorPicking, rectRef, colorInfo, dispatch]);

  // mouse move
  useEffect(() => {
    onMousemoveRef.current = (e) => {
      const { x, y } = getEventXY(e);
      const mousePosition = [x - rectRef.current.left, y - rectRef.current.top];
      pickXRef.current = mousePosition[0];
      pickYRef.current = mousePosition[1];
      // setPickX(mousePosition[0]);
      // setPickY(mousePosition[1]);
      // 画笔取色逻辑
      if (colorPicking.active) {
 
      }
      // 鼠标按下, 还没开始拖动
      if (mouseStatus.current.mouseDown && !mouseStatus.current.isDragging) {
        // 计算位移距离
        const distanceFromMouseDown = Math.sqrt(
          Math.pow(
            mousePosition[0] - mouseStatus.current.mouseDownPosition[0],
            2
          ) +
            Math.pow(
              mousePosition[1] - mouseStatus.current.mouseDownPosition[1],
              2
            )
        );
        // 大于3个像素, 开始拖动
        if (distanceFromMouseDown > 3) {
          cancelMouseDownTimeout();
          onDragStartRef.current(...mouseStatus.current.mouseDownPosition);
        }
      }
      // 鼠标按下, 且开始拖动
      if (mouseStatus.current.mouseDown && mouseStatus.current.isDragging) {
        // Editor drag style only updates the drag canvas, does full update at the end of drag
        // Non-editor drag style just updates the sprite continuously.
        //
        if (!isFullScreen && !isPlayerOnly) {
          // 正常模式, 刷新拖动角色的位置
          positionDragCanvas(mousePosition[0], mousePosition[1]);
        } else {
          // 全屏模式下
          const spritePosition = getScratchCoords(
            mousePosition[0],
            mousePosition[1]
          );
          vm.postSpriteInfo({
            x: spritePosition[0] + mouseStatus.current.dragOffset[0],
            y: -(spritePosition[1] + mouseStatus.current.dragOffset[1]),
            force: true,
          });
        }
      }
      const coordinates = {
        x: mousePosition[0],
        y: mousePosition[1],
        canvasWidth: rectRef.current.width,
        canvasHeight: rectRef.current.height,
      };
      // 向vm报告鼠标位置(用于侦测模块)
      vm.postIOData("mouse", coordinates);
    };
  }, [cancelMouseDownTimeout, colorPicking, isFullScreen, isPlayerOnly,  positionDragCanvas, getScratchCoords]);

  // mouse up
  useEffect(() => {
    onMouseupRef.current = (e) => {
      const { x, y } = getEventXY(e);
      const mousePosition = [x - rectRef.current.left, y - rectRef.current.top];
      cancelMouseDownTimeout();
      mouseStatus.current = {
        ...mouseStatus.current,
        mouseDown: false,
        mouseDownPosition: null,
      };
      const data = {
        isDown: false,
        x: x - rectRef.current.left,
        y: y - rectRef.current.top,
        canvasWidth: rectRef.current.width,
        canvasHeight: rectRef.current.height,
        wasDragged: mouseStatus.current.isDragging,
      };
      if (mouseStatus.current.isDragging) {
        // 停止拖动
        onDragStopRef.current(mousePosition[0], mousePosition[1]);
      }
      vm.postIOData("mouse", data);

      if (
        colorPicking.active &&
        mousePosition[0] > 0 &&
        mousePosition[0] < rectRef.current.width &&
        mousePosition[1] > 0 &&
        mousePosition[1] < rectRef.current.height
      ) {
        // todo: 画笔取色
      }
    };
  }, [cancelMouseDownTimeout, colorPicking]);

  useEffect(() => {
    const canvas = vm.renderer.canvas;
    const onMouseDown = (e) => onMousedownRef.current(e);
    const onMouseMove = (e) => onMousemoveRef.current(e);
    const onMouseUp = (e) => onMouseupRef.current(e);
    const onWheel = (e) => {
      const data = {
        deltaX: e.deltaX,
        deltaY: e.deltaY,
      };
      vm.postIOData("mouseWheel", data);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("touchmove", onMouseMove);
    document.addEventListener("touchend", onMouseUp);
    canvas.addEventListener("mousedown", onMouseDown);
    canvas.addEventListener("touchstart", onMouseDown);
    canvas.addEventListener("wheel", onWheel);
    return () => {
      canvas.removeEventListener("mousedown", onMouseDown);
      canvas.removeEventListener("touchstart", onMouseDown);
      canvas.removeEventListener("wheel", onWheel);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("touchmove", onMouseMove);
      document.removeEventListener("touchend", onMouseUp);
    };
  }, []);

  return {
    dragCanvasRef,
    colorInfo
  };
};
