import { vm } from 'lib/scratch-vm';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { closeAlertWithId, showStandardAlert } from 'redux/alerts/alertsSlice';
import { closeSpriteLib, openSpriteLib, selectAssetLibStatus } from 'redux/asset-lib/assetLibStatusSlice';
import { selectBlockDrag } from 'redux/block-drag/blockDragSlice';
import { selectHoverTarget, setReceivedBlocks } from 'redux/hover-target/hoverTargetSlice';
import { selectIntl } from 'redux/intl/intlSlice';
import { setRestore } from 'redux/restore-deletion/restoreDeletionSlice';
import { selectStageSize } from 'redux/stage-size/stageSizeSlice';
import { openBackdropTab, openCostumeTab, openSoundTab, showBlockTab } from 'redux/tab/tabSlice';
import { highlightTarget, selectTargets } from 'redux/tagets/targetsSlice';
import { selectWorkspaceMetrics } from 'redux/workspace-metrics/workspaceMetricsSlice';
import TargetPaneScratch from './target-pane';

export const TargetPane = () => {
    const {editingTarget} = useSelector(selectTargets);
    const hoveredTarget = useSelector(selectHoverTarget)
    const { isRtl,locale} = useSelector(selectIntl);
    const intl = useIntl();
    const {spriteLibVisible} = useSelector(selectAssetLibStatus);
    const blockDrag = useSelector(selectBlockDrag);
    const workspaceMetrics = useSelector(selectWorkspaceMetrics);
    const dispatch = useDispatch();
    const {stageSize} = useSelector(selectStageSize)

    const sprites = (vm.runtime.targets || [])
        .filter(target => !target.isStage)
        .filter(
            // Don't report clones.
            target => !target.hasOwnProperty('isOriginal') || target.isOriginal
        ).map(
            target => target.toJSON()
        )
        .reduce(
            (targets, target, listId) => Object.assign(
                targets,
                {[target.id]: {order: listId, ...target}}
            ),
            {}
        )

    let stage = vm.runtime.getTargetForStage();
    if (!stage) return null;
    stage = stage.toJSON();
    return (
        <TargetPaneScratch
            editingTarget={editingTarget}
            hoveredTarget={hoveredTarget}
            stageSize={stageSize}
            isRtl={isRtl}
            intl={intl}
            locale={locale}
            spriteLibraryVisible={spriteLibVisible}
            sprites={sprites}
            stage={stage}
            raiseSprites={blockDrag}
            workspaceMetrics={workspaceMetrics}
            vm={vm}
            onNewSpriteClick={e => {
                e.preventDefault();
                dispatch(openSpriteLib());
            }}
            onRequestCloseSpriteLibrary={() => {
                dispatch(closeSpriteLib());
            }}
            onActivateTab={tabIndex => {
                switch (tabIndex) {
                    case 1:
                        // 舞台
                        if (editingTarget === vm.runtime.getTargetForStage()?.id) {
                            return dispatch(openBackdropTab());
                        }
                        // 角色
                        return dispatch(openCostumeTab());
                    case 2:
                        return dispatch(openSoundTab());
                    case 0:
                    default:
                        return dispatch(showBlockTab());
                }
            }}
            onReceivedBlocks={receivedBlocks => {
                dispatch(setReceivedBlocks(receivedBlocks));
            }}
            dispatchUpdateRestore={restoreState => {
                dispatch(setRestore(restoreState));
            }}
            onHighlightTarget={id => {
                dispatch(highlightTarget(id));
            }}
            onCloseImporting={() => dispatch(closeAlertWithId('importingAsset'))}
            onShowImporting={() => dispatch(showStandardAlert('importingAsset'))}
        />
    )
};
