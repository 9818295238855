import React from 'react';
import Stage from "./stage/Stage";
import styles from './StageWrapper.module.scss'
// import {TargetPane} from "./target-pane/TargetPane";
import { TargetPane } from "component/target-pane";
import { ControlBar } from "./control-bar/ControlBar";
import { useSelector } from "react-redux";
import { selectMode } from "redux/mode/modeSlice";
import { selectIntl } from 'redux/intl/intlSlice';
const StageWrapper = () => {
  const { isFullScreen } = useSelector(selectMode)
  const { isRtl } = useSelector(selectIntl)

  return (
    <div dir={isRtl ? 'rtl' : 'ltr'} className={styles.container}>
      {isFullScreen ? (
        <div className={styles.fullscreenStageWrapper}>
          <div className={styles.controlBar}><ControlBar /></div>
          <div className={styles.stage}><Stage /></div>
        </div>
      ) : (
        <>
          <div className={styles.controlBar}><ControlBar /></div>
          <div className={styles.stage}><Stage /></div>
        </>
      )}
      <div className={styles.sprite}><TargetPane /></div>
    </div>
  );
};

export default StageWrapper;
