import React, { useState, useEffect, useMemo, useRef, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import styles from './DFSteps.module.scss';

// 无法点击step切换步骤
interface DFStepsProps {
    items: React.ReactNode[];
}

const DFSteps: React.FC<DFStepsProps> = ({
    items
}) => {
    return (
        <div className={`${styles.steps} `}>
            {items.map((item, index) => (
                <div className={styles.step}>
                    <div className={styles.numberWrapper}>
                        <div className={styles.number}>{index+1}</div>
                    </div>
                    <div className={styles.stepContent}>
                        {item}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DFSteps;
