// 从扩展实例id中获取扩展id
export function getExtensionIdFromInstanceId(extensionInstanceId) {
    if (extensionInstanceId.indexOf('_') === -1) {
        // 兼容scratch文件, 没有下划线
        return extensionInstanceId;
    } else {
        return extensionInstanceId.slice(0, extensionInstanceId.indexOf('_'));
    }
}

// 从opcode中获取扩展id
export function getExtensionIdFromOpcode(opcode) {
    return opcode.slice(0, opcode.indexOf('_'));
}

// 获取扩展实例id, 替换掉 .0 .1 ...
export function getExtensionInstanceId(opcode) {
    // 无版本号,目前可以认为就是设备中的扩展
    if (opcode.indexOf('@') === -1) {
        return opcode.slice(0, opcode.lastIndexOf('_')).replace(/\.\d+/, '');
    }
    // 有版本号,目前可以认为就是扩展
    return opcode.slice(0, opcode.lastIndexOf('_'))
}

// 获取原始的opcode(用户声明的opcode)
export function getRawOpcode(opcode) {
    return opcode.slice(opcode.lastIndexOf('_') + 1);
}

export function replaceDeviceExtensionOpcode(opcode) {
    if (opcode.indexOf('@') === -1) {
        // 去掉opcode中的 .0 .1
        return opcode.replace(/_\d+/, '');
    }
    return opcode;
}
