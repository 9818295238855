import { compareVersions } from "service/ext-asset-manager/device/util";
import {useDeviceData} from "./useDeviceData";
import {useExtensionData} from "./useExtensionData";

export const useLibrariesData = (type: 'extension'|'device'|'stage'): any[] => {
    const deviceData = useDeviceData();
    const extensionData = useExtensionData();
    let ret: any[] = []; 
    if (type === 'device') {
        ret = deviceData;
    } else if (type === 'stage') { // 舞台扩展
        ret = extensionData.filter(items => {
            for (let item of items) {
                if (!(item.supportDevices&&Object.keys(item.supportDevices).length)) return true;
            }
            return false;
        });
    } else { // 小模块
        ret = extensionData.filter(items => {
            for (let item of items) {
                if (item.supportDevices&&Object.keys(item.supportDevices).length) return true;
            }
            return false;
        });
    }
    // 排序, 按版本由高到低
    ret = ret.map((items) => {
        return (items as any).sort((a, b) => compareVersions(a.version, b.version))
    })
    // 排序, 按id
    ret = ret.sort((a: any, b: any) => (a[0].id > b[0].id ? 1 : -1))
    return ret;
}
