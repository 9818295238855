import React, { useEffect } from "react";
import alertIcon from "./alert.png";
import deleteIcon from "./delete.png";
import styles from "./DFAlert.module.scss";

const DFAlertDialogComponent = ({ title, content, obj, onDelete }) => {
  useEffect(() => {
    if (obj.timeout) {
      const timer = setTimeout(onDelete, obj.timeout);
      return () => clearTimeout(timer);
    }
  }, [obj.timeout, onDelete]);

  const handleDelete = () => {
    onDelete();
    obj.callBack?.();
  };
  // 将字符串中的 <br/> 替换为 <br/> React 元素
  const replaceBrWithBreakElement = (text) => {
    return text.split(/<\/br\s*\/?>/gi).map((part, index, arr) => (
      <React.Fragment key={index}>
        {part}
        {index < arr.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const renderContent = () => {
    if (Array.isArray(content)) {
      return content.map((c, index) => <span key={index}>{replaceBrWithBreakElement(c)}</span>);
    }
    return <span>{replaceBrWithBreakElement(content)}</span>;
  };

  const renderDetails = () => {
    if (!obj.details) return null;

    return (
      <div className={styles["content-detail"]}>
        {obj.details.split(";").map((detail, idx) => {
          const isTitleVersion = detail.includes("titleVtitleV");
          const isSpace = detail === "spacespace";
          return (
            <span
              key={idx}
              className={
                isTitleVersion
                  ? styles["update-little-version"]
                  : isSpace
                  ? styles["spaceline"]
                  : ""
              }
            >
              {isTitleVersion ? detail.replace("titleVtitleV", "") : detail}
            </span>
          );
        })}
      </div>
    );
  };

  const renderButtons = () => {
    if (!obj.btns || obj.btns.length === 0) return null;

    return (
      <div className={styles["btns-wrapper"]} style={obj.btnWrapperCss}>
        {obj.btns.map((btn, idx) => (
          <button
            key={idx}
            type="button"
            className={`${styles["dialog-btns"]} ${
              btn.className === "now-update-dialog"
                ? styles["now-update-dialog"]
                : ""
            }`}
            style={obj.btnCss}
            onClick={() => {
              handleDelete();
              btn.callBack?.();
            }}
          >
            {btn.text}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div
      className={
        obj.cover && styles["custom-dialog-cover"] 
      }
    >
      <div className={styles["child-custom-dialog"]}>
        <div className={styles["title-wrapper"]} style={obj.titleWrapperCss}>
          <span
            className={styles["title-wrapper-first-span"]}
            style={obj.noIcon ? { visibility: "hidden", opacity: 0 } : {}}
          >
            <img
              src={alertIcon}
              alt="alert"
              style={{ width: "100%", height: "96%" }}
            />
          </span>
          <span
            className={styles["title-wrapper-last-span"]}
            style={obj.titleCss}
          >
            {title}
          </span>
        </div>

        <div
          className={`${styles["content-wrapper"]} ${
            obj.btns?.length ? styles["have-btns-content"] : ""
          }`}
          style={obj.contentWrapperCss}
        >
          {renderContent()}
          {renderDetails()}
        </div>

        {renderButtons()}

        {!obj.noCloseBtn && (
          <span className={styles["closeBtn"]} onClick={handleDelete}>
            <img src={deleteIcon} alt="close" />
          </span>
        )}
      </div>
    </div>
  );
};

export default DFAlertDialogComponent;
