import bindAll from 'lodash.bindall';
import omit from 'lodash.omit';
import React from 'react';
import getCostumeUrl from 'lib/get-costume-url';

import WatermarkComponent from './watermark-component/watermark.jsx';

class Watermark extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'getCostumeData'
        ]);
    }

    getCostumeData () {
        if (!this.props.asset) return null;

        return getCostumeUrl(this.props.asset);
    }

    render () {
        const componentProps = omit(this.props, ['asset', 'vm']);
        return (
            <WatermarkComponent
                costumeURL={this.getCostumeData()}
                {...componentProps}
            />
        );
    }
}

export default Watermark;
