// @flow
import turboIcon from 'asset/image/icon--turbo-base.svg';
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { STAGE_SIZE_MODES } from "../../../../lib/layout-constants";
import { selectIntl } from "../../../../redux/intl/intlSlice";
import { selectStageSize } from "../../../../redux/stage-size/stageSizeSlice";
import { selectVmStatus } from "../../../../redux/vm-status/vmStatusSlice";
import styles from "./TurboMode.module.scss";

type Props = {};

const messages = defineMessages({
  active: {
    id: 'gui.turboMode.active',
    defaultMessage: 'Turbo Mode',
    description: 'gui.turboMode.active'
  }
});
export const TurboMode = (props: Props) => {
  const { stageSize } = useSelector(selectStageSize)
  const { locale } = useSelector(selectIntl)
  const { turbo } = useSelector(selectVmStatus)
  const intl = useIntl()
  if (!turbo) return null
  return (
    <div className={styles.turboContainer} title={locale !== 'zh-cn' ? intl.formatMessage(messages.active) : ''}>
      <img className={`${styles.turboIcon} ${stageSize === STAGE_SIZE_MODES.small ? styles.turboIconSmall : ''}`}
        src={turboIcon} alt=''
      />
      {(stageSize === STAGE_SIZE_MODES.large) ?
        (
          <div className={styles.turboLabel}>
            {intl.formatMessage(messages.active)}
          </div>
        ) : null
      }
    </div>
  );
};
