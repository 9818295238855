import React, { ReactNode, useState } from 'react';
import styles from './DFCheckbox.module.scss';

interface CheckboxProps {
    children: ReactNode;
    checked?: boolean;
    defaultChecked?: boolean;
    onChange?: (checked: boolean) => void;
    disabled?: boolean;
}

export const DFCheckbox: React.FC<CheckboxProps> = ({
    children,
    checked: controlledChecked,
    defaultChecked,
    onChange,
    disabled,
}) => {
    const [checked, setChecked] = useState(controlledChecked || defaultChecked || false);

    const handleChange = () => {
        if (disabled) {
            return;
        }
        const newChecked = !checked;
        setChecked(newChecked);
        if (onChange) {
            onChange(newChecked);
        }
    };

    return (
        <label className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
            <span className={styles.checkbox}>
                <input
                    type="checkbox"
                    className={styles.checkboxInput}
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <span className={`${styles.checkboxInner}  ${checked ? styles.checked : ''}`}></span>
            </span>
            <span className={`${styles.checkboxLabel}`}>
                {children}
            </span>
        </label>
    );
};



interface CheckboxOption {
    label: ReactNode;
    value: string;
}

interface CheckboxGroupProps {
    options: CheckboxOption[];
    value?: string[];
    onChange?: (selectedValues: string[]) => void;
    disabled?: boolean;
    block?: boolean;
    wrapperStyle?: React.CSSProperties;
}

export const DFCheckboxGroup: React.FC<CheckboxGroupProps> = ({
    options,
    value: controlledValue,
    onChange,
    disabled,
    block = false,
    wrapperStyle
}) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(controlledValue || []);

    const handleCheckboxChange = (value: string) => {
        const newSelectedValues = selectedValues.includes(value)
            ? selectedValues.filter((v) => v !== value)
            : [...selectedValues, value];

        setSelectedValues(newSelectedValues);
        if (onChange) {
            onChange(newSelectedValues);
        }
    };

    return (
        <div style={{ display: block ? 'block' : 'inline-block', ...wrapperStyle }}>
            {options.map((option) => (
                <DFCheckbox
                    key={option.value}
                    checked={selectedValues.includes(option.value)}
                    onChange={() => handleCheckboxChange(option.value)}
                    disabled={disabled}
                >
                    {option.label}
                </DFCheckbox>
            ))}
        </div>
    );
};
