import { commandWs } from "./websocket/commandWs"

function networkInterfaces() {
  return commandWs.sendRemoteRequest("getNetworkInterfaces",undefined);
}

function homedir() {
  return commandWs.sendRemoteRequest("getHomedir", undefined);
}

export default {
  networkInterfaces,
  homedir,
};
