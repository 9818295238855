import PaintEditor from './containers/paint-editor.jsx';
import ScratchPaintReducer from './reducers/scratch-paint-reducer';
import {Provider} from 'react-redux';
import {createStore, combineReducers, compose} from 'redux';
import React from 'react';

const store = createStore(
    combineReducers({scratchPaint: ScratchPaintReducer})
);

class PaintEditorWapper extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Provider store={store}>
                <PaintEditor {...this.props}/>
            </Provider>
        )
    }
}

export default PaintEditorWapper

