import React from 'react';
import styles from './monitor.module.scss';
import Waveform from '../../waveform/waveform.jsx';
import Box from '../../box/box.jsx';

class sonogramMonitor extends React.Component {
    render () {
        return (
            <Box className={styles.waveformContainer}>
                {this.props.levels ? (
                    <Waveform
                        data={this.props.levels}
                        height={180}
                        level={0}
                        width={450}
                    />
                ) : (
                    <span className={styles.helpText}>
                        {`没有数据levels`}
                    </span>
                )}
            </Box>
        )
    }
}
sonogramMonitor.propTypes = {
}

export default sonogramMonitor;
