import {FC} from "react";
import 'asset/iconfont/iconfont.js'
import styles from './DFIcon.module.scss'
import { useSelector } from "react-redux";
import { selectTheme } from "redux/theme/themeSlice";
import { themeColor } from "app";

type Props = {
    name: string
    color?: string
    size?:number
};

// 没传color, 默认主题色
const DFIcon: FC<Props> = ({name, size, color}) => {
    const theme = useSelector(selectTheme);
    const colorStyle = {color: color || themeColor[theme]}
    const sizeStyle = size && {fontSize: size}
    return (
        <svg className={styles.container} style={{...colorStyle,...sizeStyle}} aria-hidden="true">
            <use xlinkHref={`#df-${name}`}/>
        </svg>
    );
};

export default DFIcon
