
/**
* @Description:选择sb2/sb3文件上传函数，并resole文件内容
* @author LiuSheng
* @date 2023/5/4
*/
export function selectAndUploadLocaleProject () {
    return new Promise((resolve, reject) => {
        let fileReader: any = new FileReader();
        let fileToUpload: any;
        fileReader.onload = () => {
            // 文件读取完成
            resolve([fileReader.result, fileToUpload.name]); // 返回文件内容和文件名
        }
        // create <custom-input> element and add it to DOM
        let inputElement: any = document.createElement('input');
        inputElement.accept = '.sb,.sb2,.sb3,.mprt,.mp';
        inputElement.style = 'display: none;';
        inputElement.type = 'file';
        inputElement.onchange = (e) => {
            const thisFileInput = e.target;
            if (thisFileInput.files) { // Don't attempt to load if no file was selected
                // 获取到用户选择的文件
                fileToUpload = thisFileInput.files[0];
                // 读取文件
                fileReader.readAsArrayBuffer(fileToUpload);
            } else {
                // 没有选择文件, 返回null
                resolve(null);
            }
        }
        document.body.appendChild(inputElement);
        // simulate a click to open file chooser dialog
        inputElement.click();
    })

}
