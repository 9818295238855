import { Mutex } from "async-mutex";
import { getDeviceIDWithVersion } from "service/ext-asset-manager/device/util";
import { getExtensionIDWithVersion } from "service/ext-asset-manager/extension/util";
import { getLocalDevicePath, getLocalDevJsonPath, getLocalExtensionPath, getLocalExtJsonPath } from "service/path/assetPath";
import {
  EXTENSION_ASSET_SERVER,
  MINDPLUS_MODE
} from "../../config/config";
import { DeviceDataType } from "../ext-asset-manager/device/type";
import { ExtensionDataType } from "../ext-asset-manager/extension/type";
import { addPackageIndex, installBoard, installLibrary, updatePackageIndex } from "./arduinoCli";
import * as fs from "./fs";
import { commandWs } from "./websocket/commandWs";

// dev.json写入互斥锁
let devMutex = new Mutex();
// ext.json写入互斥锁
let extMutex = new Mutex();


// 下载主控资源
export const downloadDeviceResource = (config: DeviceDataType) => {
    if (!commandWs.isOpen) return Promise.reject("link未启动")
    const deviceZipUrl = `${EXTENSION_ASSET_SERVER}/${MINDPLUS_MODE}/devices/${config.deviceIdWithVersion}.zip`;
    const deviceZipLocalPath = `${getLocalDevicePath()}/${config.deviceIdWithVersion}.zip`;
    const deviceLocalPath = `${getLocalDevicePath()}/${config.deviceIdWithVersion}`;
    // 1.下载zip到本地
    let filePromise = commandWs.sendRemoteRequest("downloadFile", {
        url: deviceZipUrl,
        targetPath: deviceZipLocalPath,
    })
    // .catch((err) => Promise.reject(`download file ERROR: ${err}`))

    // 2.解压
    filePromise = filePromise.then(() => commandWs.sendRemoteRequest("unzip", {
        zipFile: deviceZipLocalPath,
        unzipPath: deviceLocalPath,
    }))
    // .catch((err) => Promise.reject(`unzip file ERROR: ${err}`))

    let arduinoCliPromise = Promise.resolve();
    // 1.添加index到arduino-cli
    if (config.boardConfig && config.boardConfig.boardsURL) {
        arduinoCliPromise = addPackageIndex(config.boardConfig.boardsURL).catch(err => Promise.reject(`add package_index.json error: ${err}`))
        // 更新package_index.json
        arduinoCliPromise = arduinoCliPromise.then(() => updatePackageIndex()).catch(err => Promise.reject(`update package_index.json error: ${err}`))
    }
    // 2.install board
    if (config.boardConfig && config.boardConfig.board) {
        const board = config.boardConfig.board.split(':').slice(0, 2).join(':');
        arduinoCliPromise = arduinoCliPromise.then(() => installBoard(board, config.boardConfig?.version))
    }

    // 3.install library
    if (config.libraryConfig) {
        arduinoCliPromise = arduinoCliPromise.then(async () => {
            for (let item of config.libraryConfig!) {
                await installLibrary(item);
            }
        })
    }

    return Promise.all([filePromise, arduinoCliPromise])
        .then(async () => {
            // 获取写入锁
            const release = await devMutex.acquire();
            // 更新dev.json
            let configJsonPromise = fs.readFile(`${deviceLocalPath}/config.json`);
            // dev.json不存在的情况, 返回空字符串
            let devJsonPromise = fs.readFile(getLocalDevJsonPath()).catch(e => "");
            return Promise.all([configJsonPromise, devJsonPromise])
                .then(([configJson, devJson]) => {
                    try {
                        configJson = JSON.parse(configJson)
                    } catch (err) {
                        return Promise.reject(`config.json parse error: ${err}`)
                    }
                    try {
                        devJson = JSON.parse(devJson)
                    } catch (err) {
                        // return Promise.reject(`dev.json parse error: ${err}`)
                        devJson = []
                    }
                    let flag = true;
                    // dev.json是否有旧的内容
                    for (let i = 0; i < devJson.length; i++) {
                        // 替换旧信息
                        if (getDeviceIDWithVersion(devJson[i]) === config.deviceIdWithVersion) {
                            devJson.splice(i, 1, configJson);
                            flag = false;
                            break;
                        }
                    }
                    // 新增
                    if (flag) devJson.push(configJson)
                    // 写入dev.json
                    return fs.writeFile(getLocalDevJsonPath(), JSON.stringify(devJson));
                })
                .finally(() => {
                    release();
                })
        })
}

// 下载扩展资源
export const downloadExtensionResource = (config: ExtensionDataType) => {
    const extensionZipUrl = `${EXTENSION_ASSET_SERVER}/${MINDPLUS_MODE}/extensions/${config.extensionIdWithVersion}.zip`;
    const extensionZipLocalPath = `${getLocalExtensionPath()}/${config.extensionIdWithVersion}.zip`;
    const extensionLocalPath = `${getLocalExtensionPath()}/${config.extensionIdWithVersion}`;
    // 1.下载zip到本地
    let filePromise = commandWs.sendRemoteRequest("downloadFile", {
        url: extensionZipUrl,
        targetPath: extensionZipLocalPath,
    }).catch((err) => Promise.reject(`download file ERROR: ${err}`))

    // 2.解压
    filePromise = filePromise.then(() => commandWs.sendRemoteRequest("unzip", {
        zipFile: extensionZipLocalPath,
        unzipPath: extensionLocalPath,
    })).catch((err) => Promise.reject(`unzip file ERROR: ${err}`))

    let arduinoCliPromise = Promise.resolve();
    // 1.install library
    // if (IS_ARDUINO_MODE && config.libraryConfig) {
    //     arduinoCliPromise = arduinoCliPromise.then(async () => {
    //         for (let item of config.libraryConfig!) {
    //             await installLibrary(item);
    //         }
    //     })
    // }

    return Promise.all([filePromise])
        .then(async () => {
            const release = await extMutex.acquire();
            // 更新dev.json
            let configJsonPromise = fs.readFile(`${extensionLocalPath}/config.json`);
            let extJsonPromise = fs.readFile(getLocalExtJsonPath()).catch(e => "");
            return Promise.all([configJsonPromise, extJsonPromise])
                .then(([configJson, extJson]) => {
                    try {
                        configJson = JSON.parse(configJson)
                    } catch (err) {
                        return Promise.reject(`config.json parse error: ${err}`)
                    }
                    try {
                        extJson = JSON.parse(extJson)
                    } catch (err) {
                        // return Promise.reject(`ext.json parse error: ${err}`)
                        extJson = [];
                    }
                    let flag = true;
                    // dev.json是否有旧的内容
                    for (let i = 0; i < extJson.length; i++) {
                        // 替换旧信息
                        if (getExtensionIDWithVersion(extJson[i]) === config.extensionIdWithVersion) {
                            extJson.splice(i, 1, configJson);
                            flag = false;
                            break;
                        }
                    }
                    // 新增
                    if (flag) extJson.push(configJson)
                    // 写入dev.json
                    return fs.writeFile(getLocalExtJsonPath(), JSON.stringify(extJson));
                })
                .finally(() => {
                    release();
                })
        })
}

//从本地删除主控资源
export const deleteExtensionResource =async (config: ExtensionDataType) => {
  if (!commandWs.isOpen) return Promise.reject("link未启动")
  const extensionLocalPath = `${getLocalExtensionPath()}/${config.extensionIdWithVersion}`;
  const extensionZipLocalPath = `${getLocalExtensionPath()}/${config.extensionIdWithVersion}.zip`;
  let extJson = await fs.readFile(getLocalExtJsonPath());
  extJson = JSON.parse(extJson)
  let configJson = await fs.readFile(`${extensionLocalPath}/config.json`);
  configJson = JSON.parse(configJson)
  // 删除ext.json中的configJson
  for (let i = 0; i < extJson.length; i++) {
    if (getExtensionIDWithVersion(extJson[i]) === config.extensionIdWithVersion) {
      extJson.splice(i, 1);
      break;
    }
  }
  // 写入ext.json
  await fs.writeFile(getLocalExtJsonPath(), JSON.stringify(extJson));
  await fs.unlink(extensionZipLocalPath)
  await fs.rmdir(extensionLocalPath, { recursive: true })
}


export const deleteDeviceResource = async (config: DeviceDataType) => {
  if (!commandWs.isOpen) return Promise.reject("link未启动")
  try {
    const deviceZipLocalPath = `${getLocalDevicePath()}/${config.deviceIdWithVersion}.zip`;
    const deviceLocalPath = `${getLocalDevicePath()}/${config.deviceIdWithVersion}`;
    let configJson = await fs.readFile(`${deviceLocalPath}/config.json`);
    configJson = JSON.parse(configJson)
    let devJson = await fs.readFile(getLocalDevJsonPath());
    devJson = JSON.parse(devJson)
    // 删除dev.json中的configJson
    for (let i = 0; i < devJson.length; i++) {
      if (getDeviceIDWithVersion(devJson[i]) === config.deviceIdWithVersion) {
        devJson.splice(i, 1);
        break;
      }
    }
    // 写入dev.json
    await fs.writeFile(getLocalDevJsonPath(), JSON.stringify(devJson));
    await fs.unlink(deviceZipLocalPath)
    await fs.rmdir(deviceLocalPath, { recursive: true })
  } catch (error) {
    throw Error("删除主控资源失败")
  }
}
