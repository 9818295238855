import JSONRPC from "./jsonrpc";
import LinkWebSocket from "./link-websocket";

class MqttWs extends JSONRPC {
  ws: LinkWebSocket;
  constructor() {
    super();
    this.ws = new LinkWebSocket("MQTT");
    this.ws.setOnOpen(this._onWsOpen.bind(this));
    this.ws.setOnClose(this._onWsClose.bind(this));
    this.ws.setOnError(this._onWsError.bind(this));
    this.ws.setHandleMessage(this._handleMessage.bind(this));
    // 重写jsonrpc的方法
    this._sendMessage = this.ws.sendMessage.bind(this.ws);
    this.open();
  }

  sendRemoteRequest(method: string, params?: any) {
    if (!this.isOpen()) return Promise.reject("websocket not connected");
    return super.sendRemoteRequest(method, params);
  }

  sendRemoteNotification(method: string, params?: any) {
    if (!this.isOpen()) return Promise.reject("websocket not connected");
    super.sendRemoteNotification(method, params);
  }

  // 连接ws
  open() {
    this.ws.open();
  }

  close() {
    this.ws.close();
  }

  isOpen() {
    return this.ws.isOpen();
  }

  _onWsOpen() {
    this.emit("wsOpen");
  }

  _onWsClose() {
    this.emit("close");
  }

  _onWsError(err) {
    this.emit("error", err.toString());
  }


  didReceiveCall(method, params) {
    switch (method) {
      case "connect":
        this.emit("connect");
        break;
      case "error":
        this.emit("mqtt_error", params);
        break;
      case "message":
        this.emit("message", params);
        break;
      case "offline":
        this.emit("offline", params);
        break;
      case "reconnect":
        this.emit("reconnect", params);
        break;
      case "close":
        this.emit("close", params);
        break;
    }
  }

  connect(url: string, options: any) {
    return this.sendRemoteNotification("connect", { url, options });
  }

  subscribe(topic: string) {
    return this.sendRemoteRequest("subscribe", { topic });
  }

  unsubscribe(params: any) {
    return this.sendRemoteRequest("unsubscribe", params);
  }

  publish(topic: string, message: any, options: any) {
    return this.sendRemoteRequest("publish", { topic, message, options });
  }

  end() {
    return this.sendRemoteNotification("end");
  }
}

export default MqttWs;
