import ScratchPaintWrapper from './paint-editor-wrapper'
import {vm} from "../../../../lib/scratch-vm";
import {useSelector} from "react-redux";
import {selectIntl} from "../../../../redux/intl/intlSlice";


export const PaintEditorWrapper = ({selectedCostumeIndex}: { selectedCostumeIndex: number }) => {
    const {isRtl} = useSelector(selectIntl)
    const targetId = vm.editingTarget.id;
    const sprite = vm.editingTarget.sprite;
    const index = selectedCostumeIndex < sprite.costumes.length ?
        selectedCostumeIndex : sprite.costumes.length - 1;
    const costume = vm.editingTarget.sprite.costumes[index];
    return <ScratchPaintWrapper
        name={costume && costume.name}
        rotationCenterX={costume && costume.rotationCenterX}
        rotationCenterY={costume && costume.rotationCenterY}
        imageFormat={costume && costume.dataFormat}
        imageId={targetId && `${targetId}${costume.skinId}`}
        isRtl={isRtl}
        selectedCostumeIndex={index}
        vm={vm}
        zoomLevelId={targetId}
    />
}
