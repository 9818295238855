/**
 * @license
 * Visual Blocks Language
 *
 * Copyright 2012 Google Inc.
 * https://developers.google.com/blockly/
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview English strings.
 * @author ascii@media.mit.edu (Andrew Sliwinski)
 *
 * After modifying this file, run `npm run translate` from the root directory
 * to regenerate `./msg/json/en.json`.
 * IMPORTANT:
 * All message strings must use single quotes for the scripts to work properly
 */
'use strict';

goog.provide('Blockly.Msg.en');

goog.require('Blockly.Msg');

// Control blocks
Blockly.Msg.CONTROL_FOREVER = 'forever';
Blockly.Msg.CONTROL_LOOP_BREAKCONTINUE = "%1";
Blockly.Msg.CONTROL_LOOP_BREAKCONTINUE_BREAK = "break";
Blockly.Msg.CONTROL_LOOP_BREAKCONTINUE_CONTINUE = "continue";
Blockly.Msg.CONTROL_TRUE_FALSE = '%1';
Blockly.Msg.CONTROL_TRUE_FALSE_TRUE = 'true';
Blockly.Msg.CONTROL_TRUE_FALSE_FALSE = 'false';
Blockly.Msg.CONTROL_TRY = 'try';
Blockly.Msg.CONTROL_EXCEPT = 'except';
Blockly.Msg.CONTROL_FINALLY = 'finally';
Blockly.Msg.CONTROL_REPEAT = 'repeat %1';
Blockly.Msg.CONTROL_IF = 'if %1 then';
Blockly.Msg.CONTROL_ELSE = 'else';
Blockly.Msg.CONTROL_STOP = 'stop';
Blockly.Msg.CONTROL_STOP_ALL = 'all';
Blockly.Msg.CONTROL_STOP_THIS = 'this script';
Blockly.Msg.CONTROL_STOP_OTHER = 'other scripts in sprite';
Blockly.Msg.CONTROL_WAIT = 'wait %1 seconds';
Blockly.Msg.CONTROL_WAIT_ESP32_MPY = 'wait %1 %2';
Blockly.Msg.CONTROL_WAIT_ESP32_MPY_S = 'second(s)';
Blockly.Msg.CONTROL_WAIT_ESP32_MPY_MS = 'millisecond(ms)';
Blockly.Msg.CONTROL_WAIT_ESP32_MPY_US = 'microsecond(us)';
Blockly.Msg.CONTROL_WAITUNTIL = 'wait until %1';
Blockly.Msg.CONTROL_REPEATUNTIL = 'repeat until %1';
Blockly.Msg.CONTROL_WHILE = 'while %1';
Blockly.Msg.CONTROL_FOREACH = 'for each %1 in %2';
Blockly.Msg.CONTROL_STARTASCLONE = 'when I start as a clone';
Blockly.Msg.CONTROL_CREATECLONEOF = 'create clone of %1';
Blockly.Msg.CONTROL_CREATECLONEOF_MYSELF = 'myself';
Blockly.Msg.CONTROL_DELETETHISCLONE = 'delete this clone';
Blockly.Msg.CONTROL_COUNTER = 'counter';
Blockly.Msg.CONTROL_INCRCOUNTER = 'increment counter';
Blockly.Msg.CONTROL_CLEARCOUNTER = 'clear counter';
Blockly.Msg.CONTROL_ALLATONCE = 'all at once';
Blockly.Msg.CONTROL_FOR_FROM_SEQUENCE = 'use %1 from sequence %2'

// Data blocks
Blockly.Msg.DATA_SETVARIABLETO = 'set %1 to %2';
Blockly.Msg.DATA_CHANGEVARIABLEBY = 'change %1 by %2';
Blockly.Msg.DATA_SHOWVARIABLE = 'show variable %1';
Blockly.Msg.DATA_HIDEVARIABLE = 'hide variable %1';
Blockly.Msg.DATA_ADDTOLIST = 'add %1 to %2';
Blockly.Msg.DATA_DELETEOFLIST = 'delete %1 of %2';
Blockly.Msg.DATA_DELETEALLOFLIST = 'delete all of %1';
Blockly.Msg.DATA_INSERTATLIST = 'insert %1 at %2 of %3';
Blockly.Msg.DATA_REPLACEITEMOFLIST = 'replace item %1 of %2 with %3';
Blockly.Msg.DATA_ITEMOFLIST = 'item %1 of %2';
Blockly.Msg.DATA_ITEMNUMOFLIST = 'item # of %1 in %2';
Blockly.Msg.DATA_LENGTHOFLIST = 'length of %1';
Blockly.Msg.DATA_LISTCONTAINSITEM = '%1 contains %2?';
Blockly.Msg.DATA_SHOWLIST = 'show list %1';
Blockly.Msg.DATA_HIDELIST = 'hide list %1';
Blockly.Msg.DATA_INDEX_ALL = 'all';
Blockly.Msg.DATA_INDEX_LAST = 'last';
Blockly.Msg.DATA_INDEX_RANDOM = 'random';

// Event blocks
Blockly.Msg.EVENT_WHENFLAGCLICKED = 'when %1 clicked';
Blockly.Msg.EVENT_WHENTHISSPRITECLICKED = 'when this sprite clicked';
Blockly.Msg.EVENT_WHENSTAGECLICKED = 'when stage clicked';
Blockly.Msg.EVENT_WHENTOUCHINGOBJECT = 'when this sprite touches %1';
Blockly.Msg.EVENT_WHENBROADCASTRECEIVED = 'when I receive %1';
Blockly.Msg.EVENT_WHENBACKDROPSWITCHESTO = 'when backdrop switches to %1';
Blockly.Msg.EVENT_WHENGREATERTHAN = 'when %1 > %2';
Blockly.Msg.EVENT_WHENGREATERTHAN_TIMER = 'timer';
Blockly.Msg.EVENT_WHENGREATERTHAN_LOUDNESS = 'loudness';
Blockly.Msg.EVENT_BROADCAST = 'broadcast %1';
Blockly.Msg.EVENT_BROADCASTANDWAIT = 'broadcast %1 and wait';
Blockly.Msg.EVENT_WHENKEYPRESSED = 'when %1 key pressed';
Blockly.Msg.EVENT_WHENKEYPRESSED_SPACE = 'space';
Blockly.Msg.EVENT_WHENKEYPRESSED_LEFT = 'left arrow';
Blockly.Msg.EVENT_WHENKEYPRESSED_RIGHT = 'right arrow';
Blockly.Msg.EVENT_WHENKEYPRESSED_DOWN = 'down arrow';
Blockly.Msg.EVENT_WHENKEYPRESSED_UP = 'up arrow';
Blockly.Msg.EVENT_WHENKEYPRESSED_ANY = 'any';

// Looks blocks
Blockly.Msg.LOOKS_SAYFORSECS = 'say %1 for %2 seconds';
Blockly.Msg.LOOKS_SAY = 'say %1';
Blockly.Msg.LOOKS_HELLO = 'Hello!';
Blockly.Msg.LOOKS_THINKFORSECS = 'think %1 for %2 seconds';
Blockly.Msg.LOOKS_THINK = 'think %1';
Blockly.Msg.LOOKS_HMM = 'Hmm...';
Blockly.Msg.LOOKS_SHOW = 'show';
Blockly.Msg.LOOKS_HIDE = 'hide';
Blockly.Msg.LOOKS_HIDEALLSPRITES = 'hide all sprites';
Blockly.Msg.LOOKS_EFFECT_COLOR = 'color';
Blockly.Msg.LOOKS_EFFECT_FISHEYE = 'fisheye';
Blockly.Msg.LOOKS_EFFECT_WHIRL = 'whirl';
Blockly.Msg.LOOKS_EFFECT_PIXELATE = 'pixelate';
Blockly.Msg.LOOKS_EFFECT_MOSAIC = 'mosaic';
Blockly.Msg.LOOKS_EFFECT_BRIGHTNESS = 'brightness';
Blockly.Msg.LOOKS_EFFECT_GHOST = 'ghost';
Blockly.Msg.LOOKS_CHANGEEFFECTBY = 'change %1 effect by %2';
Blockly.Msg.LOOKS_SETEFFECTTO = 'set %1 effect to %2';
Blockly.Msg.LOOKS_CLEARGRAPHICEFFECTS = 'clear graphic effects';
Blockly.Msg.LOOKS_CHANGESIZEBY = 'change size by %1';
Blockly.Msg.LOOKS_SETSIZETO = 'set size to %1 %';
Blockly.Msg.LOOKS_SIZE = 'size';
Blockly.Msg.LOOKS_CHANGESTRETCHBY = 'change stretch by %1';
Blockly.Msg.LOOKS_SETSTRETCHTO = 'set stretch to %1 %';
Blockly.Msg.LOOKS_SWITCHCOSTUMETO = 'switch costume to %1';
Blockly.Msg.LOOKS_NEXTCOSTUME = 'next costume';
Blockly.Msg.LOOKS_SWITCHBACKDROPTO = 'switch backdrop to %1';
Blockly.Msg.LOOKS_GOTOFRONTBACK = 'go to %1 layer';
Blockly.Msg.LOOKS_GOTOFRONTBACK_FRONT = 'front';
Blockly.Msg.LOOKS_GOTOFRONTBACK_BACK = 'back';
Blockly.Msg.LOOKS_GOFORWARDBACKWARDLAYERS = 'go %1 %2 layers';
Blockly.Msg.LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD = 'forward';
Blockly.Msg.LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD = 'backward';
Blockly.Msg.LOOKS_BACKDROPNUMBERNAME = 'backdrop %1';
Blockly.Msg.LOOKS_COSTUMENUMBERNAME = 'costume %1';
Blockly.Msg.LOOKS_NUMBERNAME_NUMBER = 'number';
Blockly.Msg.LOOKS_NUMBERNAME_NAME = 'name';
Blockly.Msg.LOOKS_SWITCHBACKDROPTOANDWAIT = 'switch backdrop to %1 and wait';
Blockly.Msg.LOOKS_NEXTBACKDROP_BLOCK = 'next backdrop';
Blockly.Msg.LOOKS_NEXTBACKDROP = 'next backdrop';
Blockly.Msg.LOOKS_PREVIOUSBACKDROP = 'previous backdrop';
Blockly.Msg.LOOKS_RANDOMBACKDROP = 'random backdrop';

// Motion blocks
Blockly.Msg.MOTION_MOVESTEPS = 'move %1 steps';
Blockly.Msg.MOTION_TURNLEFT = 'turn %1 %2 degrees';
Blockly.Msg.MOTION_TURNRIGHT = 'turn %1 %2 degrees';
Blockly.Msg.MOTION_POINTINDIRECTION = 'point in direction %1';
Blockly.Msg.MOTION_POINTTOWARDS = 'point towards %1';
Blockly.Msg.MOTION_POINTTOWARDS_POINTER = 'mouse-pointer';
Blockly.Msg.MOTION_POINTTOWARDS_RANDOM = 'random direction';
Blockly.Msg.MOTION_GOTO = 'go to %1';
Blockly.Msg.MOTION_GOTO_POINTER = 'mouse-pointer';
Blockly.Msg.MOTION_GOTO_RANDOM = 'random position';
Blockly.Msg.MOTION_GOTOXY = 'go to x: %1 y: %2';
Blockly.Msg.MOTION_GLIDESECSTOXY = 'glide %1 secs to x: %2 y: %3';
Blockly.Msg.MOTION_GLIDETO = 'glide %1 secs to %2';
Blockly.Msg.MOTION_GLIDETO_POINTER = 'mouse-pointer';
Blockly.Msg.MOTION_GLIDETO_RANDOM = 'random position';
Blockly.Msg.MOTION_CHANGEXBY = 'change x by %1';
Blockly.Msg.MOTION_SETX = 'set x to %1';
Blockly.Msg.MOTION_CHANGEYBY = 'change y by %1';
Blockly.Msg.MOTION_SETY = 'set y to %1';
Blockly.Msg.MOTION_IFONEDGEBOUNCE = 'if on edge, bounce';
Blockly.Msg.MOTION_SETROTATIONSTYLE = 'set rotation style %1';
Blockly.Msg.MOTION_SETROTATIONSTYLE_LEFTRIGHT = 'left-right';
Blockly.Msg.MOTION_SETROTATIONSTYLE_DONTROTATE = 'don\'t rotate';
Blockly.Msg.MOTION_SETROTATIONSTYLE_ALLAROUND = 'all around';
Blockly.Msg.MOTION_XPOSITION = 'x position';
Blockly.Msg.MOTION_YPOSITION = 'y position';
Blockly.Msg.MOTION_DIRECTION = 'direction';
Blockly.Msg.MOTION_SCROLLRIGHT = 'scroll right %1';
Blockly.Msg.MOTION_SCROLLUP = 'scroll up %1';
Blockly.Msg.MOTION_ALIGNSCENE = 'align scene %1';
Blockly.Msg.MOTION_ALIGNSCENE_BOTTOMLEFT = 'bottom-left';
Blockly.Msg.MOTION_ALIGNSCENE_BOTTOMRIGHT = 'bottom-right';
Blockly.Msg.MOTION_ALIGNSCENE_MIDDLE = 'middle';
Blockly.Msg.MOTION_ALIGNSCENE_TOPLEFT = 'top-left';
Blockly.Msg.MOTION_ALIGNSCENE_TOPRIGHT = 'top-right';
Blockly.Msg.MOTION_XSCROLL = 'x scroll';
Blockly.Msg.MOTION_YSCROLL = 'y scroll';
Blockly.Msg.MOTION_STAGE_SELECTED = 'Stage selected: no motion blocks';

// Operators blocks
Blockly.Msg.OPERATORS_ADD = '%1 + %2';
Blockly.Msg.OPERATORS_SUBTRACT = '%1 - %2';
Blockly.Msg.OPERATORS_MULTIPLY = '%1 * %2';
Blockly.Msg.OPERATORS_DIVIDE = '%1 / %2';
Blockly.Msg.OPERATORS_RANDOM = 'pick random %1 to %2';
Blockly.Msg.OPERATORS_GT = '%1 > %2';
Blockly.Msg.OPERATORS_GT_EQUALS = '%1 >= %2'; // @add
Blockly.Msg.OPERATORS_LT = '%1 < %2';
Blockly.Msg.OPERATORS_LT_EQUALS = '%1 <= %2'; // @add
Blockly.Msg.OPERATORS_EQUALS = '%1 = %2';
Blockly.Msg.OPERATORS_AND = '%1 and %2';
Blockly.Msg.OPERATORS_OR = '%1 or %2';
Blockly.Msg.OPERATORS_NOT = 'not %1';

Blockly.Msg.OPERATORS_NONE = 'None';
Blockly.Msg.OPERATORS_STRING_BUILDER = '%1 查找 %2 在 3% 中首次出现的位置';
Blockly.Msg.OPERATORS_JOIN = 'join %1 %2';
Blockly.Msg.OPERATORS_JOIN_APPLE = 'apple';
Blockly.Msg.OPERATORS_JOIN_BANANA = 'banana';
Blockly.Msg.OPERATORS_LETTEROF = 'letter %1 of %2';
Blockly.Msg.OPERATORS_LETTEROF_APPLE = 'a';
Blockly.Msg.OPERATORS_LENGTH = 'length of %1';
Blockly.Msg.OPERATORS_CONTAINS = '%1 contains %2?';
Blockly.Msg.OPERATORS_MOD = '%1 mod %2';
Blockly.Msg.OPERATORS_ROUND = 'round %1';
Blockly.Msg.OPERATORS_MATHOP = '%1 of %2';
Blockly.Msg.OPERATORS_MATHOP_ABS = 'abs';
Blockly.Msg.OPERATORS_MATHOP_FLOOR = 'floor';
Blockly.Msg.OPERATORS_MATHOP_CEILING = 'ceiling';
Blockly.Msg.OPERATORS_MATHOP_SQRT = 'sqrt';
Blockly.Msg.OPERATORS_MATHOP_SIN = 'sin';
Blockly.Msg.OPERATORS_MATHOP_COS = 'cos';
Blockly.Msg.OPERATORS_MATHOP_TAN = 'tan';
Blockly.Msg.OPERATORS_MATHOP_ASIN = 'asin';
Blockly.Msg.OPERATORS_MATHOP_ACOS = 'acos';
Blockly.Msg.OPERATORS_MATHOP_ATAN = 'atan';
Blockly.Msg.OPERATORS_MATHOP_LN = 'ln';
Blockly.Msg.OPERATORS_MATHOP_LOG = 'log';
Blockly.Msg.OPERATORS_MATHOP_EEXP = 'e ^';
Blockly.Msg.OPERATORS_MATHOP_10EXP = '10 ^';
Blockly.Msg.OPERATORS_MATHOP_NEGATIVE = '-';
Blockly.Msg.OPERATORS_MATHOP_INT = 'int';
Blockly.Msg.OPERATORS_MATHOP_FLOAT = 'float';
// Blockly.Msg.OPERATORS_TRY = 'try';
// Blockly.Msg.OPERATORS_EXCEPT = 'except';
// Blockly.Msg.OPERATORS_FINALLY = 'finally';

// Procedures blocks
Blockly.Msg.PROCEDURES_DEFINITION = 'define %1';

// Sensing blocks
Blockly.Msg.SENSING_TOUCHINGOBJECT = 'touching %1?';
Blockly.Msg.SENSING_TOUCHINGOBJECT_POINTER = 'mouse-pointer';
Blockly.Msg.SENSING_TOUCHINGOBJECT_EDGE = 'edge';
Blockly.Msg.SENSING_TOUCHINGCOLOR = 'touching color %1?';
Blockly.Msg.SENSING_COLORISTOUCHINGCOLOR = 'color %1 is touching %2?';
Blockly.Msg.SENSING_DISTANCETO = 'distance to %1';
Blockly.Msg.SENSING_DISTANCETO_POINTER = 'mouse-pointer';
Blockly.Msg.SENSING_ASKANDWAIT = 'ask %1 and wait';
Blockly.Msg.SENSING_ASK_TEXT = 'What\'s your name?';
Blockly.Msg.SENSING_ANSWER = 'answer';
Blockly.Msg.SENSING_KEYPRESSED = 'key %1 pressed?';
Blockly.Msg.SENSING_MOUSEDOWN = 'mouse down?';
Blockly.Msg.SENSING_MOUSEX = 'mouse x';
Blockly.Msg.SENSING_MOUSEY = 'mouse y';
Blockly.Msg.SENSING_SETDRAGMODE = 'set drag mode %1';
Blockly.Msg.SENSING_SETDRAGMODE_DRAGGABLE = 'draggable';
Blockly.Msg.SENSING_SETDRAGMODE_NOTDRAGGABLE = 'not draggable';
Blockly.Msg.SENSING_LOUDNESS = 'loudness';
Blockly.Msg.SENSING_LOUD = 'loud?';
Blockly.Msg.SENSING_TIMER = 'timer';
Blockly.Msg.SENSING_RESETTIMER = 'reset timer';
Blockly.Msg.SENSING_OF = '%1 of %2';
Blockly.Msg.SENSING_OF_XPOSITION = 'x position';
Blockly.Msg.SENSING_OF_YPOSITION = 'y position';
Blockly.Msg.SENSING_OF_DIRECTION = 'direction';
Blockly.Msg.SENSING_OF_COSTUMENUMBER = 'costume #';
Blockly.Msg.SENSING_OF_COSTUMENAME = 'costume name';
Blockly.Msg.SENSING_OF_SIZE = 'size';
Blockly.Msg.SENSING_OF_VOLUME = 'volume';
Blockly.Msg.SENSING_OF_BACKDROPNUMBER = 'backdrop #';
Blockly.Msg.SENSING_OF_BACKDROPNAME = 'backdrop name';
Blockly.Msg.SENSING_OF_STAGE = 'Stage';
Blockly.Msg.SENSING_CURRENT = 'current %1';
Blockly.Msg.SENSING_CURRENT_YEAR = 'year';
Blockly.Msg.SENSING_CURRENT_MONTH = 'month';
Blockly.Msg.SENSING_CURRENT_DATE = 'date';
Blockly.Msg.SENSING_CURRENT_DAYOFWEEK = 'day of week';
Blockly.Msg.SENSING_CURRENT_HOUR = 'hour';
Blockly.Msg.SENSING_CURRENT_MINUTE = 'minute';
Blockly.Msg.SENSING_CURRENT_SECOND = 'second';
Blockly.Msg.SENSING_DAYSSINCE2000 = 'days since 2000';
Blockly.Msg.SENSING_USERNAME = 'username';
Blockly.Msg.SENSING_USERID = 'user id';

// Sound blocks
Blockly.Msg.SOUND_PLAY = 'start sound %1';
Blockly.Msg.SOUND_PLAYUNTILDONE = 'play sound %1 until done';
Blockly.Msg.SOUND_STOPALLSOUNDS = 'stop all sounds';
Blockly.Msg.SOUND_SETEFFECTO = 'set %1 effect to %2';
Blockly.Msg.SOUND_CHANGEEFFECTBY = 'change %1 effect by %2';
Blockly.Msg.SOUND_CLEAREFFECTS = 'clear sound effects';
Blockly.Msg.SOUND_EFFECTS_PITCH = 'pitch';
Blockly.Msg.SOUND_EFFECTS_PAN = 'pan left/right';
Blockly.Msg.SOUND_CHANGEVOLUMEBY = 'change volume by %1';
Blockly.Msg.SOUND_SETVOLUMETO = 'set volume to %1%';
Blockly.Msg.SOUND_VOLUME = 'volume';
Blockly.Msg.SOUND_RECORD = 'record...';

// Category labels
Blockly.Msg.CATEGORY_MOTION = 'Motion';
Blockly.Msg.CATEGORY_LOOKS = 'Looks';
Blockly.Msg.CATEGORY_SOUND = 'Sound';
Blockly.Msg.CATEGORY_EVENTS = 'Events';
Blockly.Msg.CATEGORY_CONTROL = 'Control';
Blockly.Msg.CATEGORY_SENSING = 'Sensing';
Blockly.Msg.CATEGORY_OPERATORS = 'Operators';
Blockly.Msg.CATEGORY_VARIABLES = 'Variables';
Blockly.Msg.CATEGORY_MYBLOCKS = 'My Blocks';
Blockly.Msg.CATEGORY_LISTS = 'List';
Blockly.Msg.CATEGORY_TUPLE = 'Tuple';
Blockly.Msg.CATEGORY_DICTIONARYS = 'Dictionary';
Blockly.Msg.CATEGORY_NUMBERS = 'Number';
Blockly.Msg.CATEGORY_TEXT = 'Text';
Blockly.Msg.CATEGORY_SETS = 'Set';

// Context menus
Blockly.Msg.DUPLICATE = 'Duplicate';
Blockly.Msg.DELETE = 'Delete';
Blockly.Msg.ADD_COMMENT = 'Add Comment';
Blockly.Msg.REMOVE_COMMENT = 'Remove Comment';
Blockly.Msg.DELETE_BLOCK = 'Delete Block';
Blockly.Msg.DELETE_X_BLOCKS = 'Delete %1 Blocks';
Blockly.Msg.DELETE_ALL_BLOCKS = 'Delete all %1 blocks?';
Blockly.Msg.CLEAN_UP = 'Clean up Blocks';
Blockly.Msg.HELP = 'Help';
Blockly.Msg.UNDO = 'Undo';
Blockly.Msg.REDO = 'Redo';
Blockly.Msg.EDIT_PROCEDURE = 'Edit';
Blockly.Msg.SHOW_PROCEDURE_DEFINITION = 'Go to definition';
Blockly.Msg.WORKSPACE_COMMENT_DEFAULT_TEXT = 'Say something...';

// Color
Blockly.Msg.COLOUR_HUE_LABEL = 'Color';
Blockly.Msg.COLOUR_SATURATION_LABEL = 'Saturation';
Blockly.Msg.COLOUR_BRIGHTNESS_LABEL = 'Brightness';

// Variables
// @todo Remove these once fully managed by Scratch VM / Scratch GUI
Blockly.Msg.CHANGE_VALUE_TITLE = 'Change value:';
Blockly.Msg.RENAME_VARIABLE = 'Rename variable';
Blockly.Msg.RENAME_VARIABLE_STRING = 'Rename string variable'; // @add
Blockly.Msg.RENAME_VARIABLE_NUMBER = 'Rename number variable'; // @add
Blockly.Msg.RENAME_VARIABLE_LIST = 'Rename list variable'; // @add
Blockly.Msg.RENAME_VARIABLE_TITLE = 'Rename all "%1" variables to:';
Blockly.Msg.RENAME_VARIABLE_MODAL_TITLE = 'Rename Variable';
Blockly.Msg.NEW_VARIABLE = 'Make a Variable';
Blockly.Msg.NEW_VARIABLE_TITLE = 'New variable name:';
Blockly.Msg.VARIABLE_MODAL_TITLE = 'New Variable';
Blockly.Msg.VARIABLE_ALREADY_EXISTS = 'A variable named "%1" already exists.';
Blockly.Msg.VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE = 'A variable named "%1" already exists for another variable of type "%2".';
Blockly.Msg.DELETE_VARIABLE_CONFIRMATION = 'Delete %1 uses of the "%2" variable?';
Blockly.Msg.CANNOT_DELETE_VARIABLE_PROCEDURE = 'Can\'t delete the variable "%1" because it\'s part of the definition of the function "%2"';
Blockly.Msg.DELETE_VARIABLE = 'Delete the "%1" variable';
Blockly.Msg.DELETE_VARIABLE_STRING = 'Delete the "%1" string variable'; // @add
Blockly.Msg.DELETE_VARIABLE_NUMBER = 'Delete the "%1" number variable'; // @add
Blockly.Msg.DELETE_VARIABLE_LIST = 'Delete the "%1" list variable'; // @add

// Custom Procedures
// @todo Remove these once fully managed by Scratch VM / Scratch GUI
Blockly.Msg.NEW_PROCEDURE = 'Make a Block';
Blockly.Msg.PROCEDURE_ALREADY_EXISTS = 'A procedure named "%1" already exists.';
Blockly.Msg.PROCEDURE_DEFAULT_NAME = 'block name';

// Lists
// @todo Remove these once fully managed by Scratch VM / Scratch GUI
Blockly.Msg.NEW_LIST = 'Make a List';
Blockly.Msg.NEW_LIST_TITLE = 'New list name:';
Blockly.Msg.LIST_MODAL_TITLE = 'New List';
Blockly.Msg.LIST_ALREADY_EXISTS = 'A list named "%1" already exists.';
Blockly.Msg.RENAME_LIST_TITLE = 'Rename all "%1" lists to:';
Blockly.Msg.RENAME_LIST_MODAL_TITLE = 'Rename List';
Blockly.Msg.DEFAULT_LIST_ITEM = 'thing';
Blockly.Msg.DELETE_LIST = 'Delete the "%1" list';
Blockly.Msg.RENAME_LIST = 'Rename list';

// Broadcast Messages
// @todo Remove these once fully managed by Scratch VM / Scratch GUI
Blockly.Msg.NEW_BROADCAST_MESSAGE = 'New message';
Blockly.Msg.NEW_BROADCAST_MESSAGE_TITLE = 'New message name:';
Blockly.Msg.BROADCAST_MODAL_TITLE = 'New Message';
Blockly.Msg.DEFAULT_BROADCAST_MESSAGE_NAME = 'message1';
// @add============================
Blockly.Msg.DELETE_PROCEDURE = 'To delete a block definition, first remove all uses of the block';
Blockly.Msg.OK = 'OK';
Blockly.Msg.CANCEL = 'Cancel';
Blockly.Msg.PROMPT = 'Prompt';
Blockly.Msg.ALL_SCRIPTS = 'all';
Blockly.Msg.THIS_SCRIPT = 'this script';
Blockly.Msg.OTHER_SCRIPT = 'other scripts in sprite';
Blockly.Msg.STOP = 'stop';
Blockly.Msg.STRING_TYPE = 'string';
Blockly.Msg.NEW_STRING = 'new string';
Blockly.Msg.NEW_NUMBER_VARIABLE = 'new numberic variable';
// obloq mqtt initial
/// wifi
Blockly.Msg.WIFI = 'Wi-Fi';
// userName
Blockly.Msg.USERNAME = 'user name';
/// password
Blockly.Msg.PWD = 'password';
// Internet of Things Service
Blockly.Msg.IOT_SERVICE = 'IOT Service';
/// iot id
Blockly.Msg.IOT_ID = 'iot id';
/// iot pwd
Blockly.Msg.IOT_PWD = 'iot pwd';
Blockly.Msg.IP = 'IP Address'
/// iot topic
Blockly.Msg.IOT_TOPIC = 'Topic_0';
/// iot topic
Blockly.Msg.MORE_TOPIC = 'Topic';

// obloq http initial
// http 配置
Blockly.Msg.OBLOQ_HTTP = 'HTTP';
// ip 地址
Blockly.Msg.OBLOQ_IP = 'IP Address'
// 端口号
Blockly.Msg.OBLOQ_PORT = 'Port';
Blockly.Msg.WIFI_VALIDATE = 'Wi-Fi password must be 8-20 letters and numbers';
// 添加Topic
Blockly.Msg.ADD_TOPIC = 'Add Topic, only add up to 4';
//删除Topic1
Blockly.Msg.DELETE_TOPIC = 'Delete Topic';
// 服务器
Blockly.Msg.IOT_SERVER = 'Server';
// 中国
Blockly.Msg.CHINA = 'China';

// 全球
Blockly.Msg.GLOBAL = 'Global';
// 自定义
Blockly.Msg.SIOT = 'SIOT';
// piano
Blockly.Msg.LOW_C = 'Low C/C3';      //131
Blockly.Msg.LOW_C$ = 'Low C#/C#3';    //139
Blockly.Msg.LOW_D = 'Low D/D3';      //147
Blockly.Msg.LOW_D$ = 'Low D#/D#3';    //156
Blockly.Msg.LOW_E = 'Low E/E3';      //165
Blockly.Msg.LOW_F = 'Low F/F3';      //175
Blockly.Msg.LOW_F$ = 'Low F#/F#3';    //185
Blockly.Msg.LOW_G = 'Low G/G3';      //196
Blockly.Msg.LOW_G$ = 'Low G#/G#3';    //208
Blockly.Msg.LOW_A = 'Low A/A3';      //220
Blockly.Msg.LOW_A$ = 'Low A#/A#3';    //233
Blockly.Msg.LOW_B = 'Low B/B3';      //247

Blockly.Msg.MIDDLE_C = 'Middle C/C4';//262
Blockly.Msg.MIDDLE_C$ = 'Middle C#/C#4';//277
Blockly.Msg.MIDDLE_D = 'Middle D/D4';  //294
Blockly.Msg.MIDDLE_D$ = 'Middle D#/D#4';//311
Blockly.Msg.MIDDLE_E = 'Middle E/E4';  //330
Blockly.Msg.MIDDLE_F = 'Middle F/F4';  //349
Blockly.Msg.MIDDLE_F$ = 'Middle F#/F#4';//370
Blockly.Msg.MIDDLE_G = 'Middle G/G4';  //392
Blockly.Msg.MIDDLE_G$ = 'Middle G#/G#4';//415
Blockly.Msg.MIDDLE_A = 'Middle A/A4';  //440
Blockly.Msg.MIDDLE_A$ = 'Middle A#/A#4';//466
Blockly.Msg.MIDDLE_B = 'Middle B/B4';  //494

Blockly.Msg.HIGH_C = 'High C/C5';      //523
Blockly.Msg.HIGH_C$ = 'High C#/C#5';    //554
Blockly.Msg.HIGH_D = 'High D/D5';      //587
Blockly.Msg.HIGH_D$ = 'High D#/D#5';    //622
Blockly.Msg.HIGH_E = 'High E/E5';      //659
Blockly.Msg.HIGH_F = 'High F/F5';      //698
Blockly.Msg.HIGH_F$ = 'High F#/F#5';    //740
Blockly.Msg.HIGH_G = 'High G/G5';      //784
Blockly.Msg.HIGH_G$ = 'High G#/G#5';    //831
Blockly.Msg.HIGH_A = 'High A/A5';      //880
Blockly.Msg.HIGH_A$ = 'High A#/A#5';    //932
Blockly.Msg.HIGH_B = 'High B/B5';      //988
// string to numbers
Blockly.Msg.OPERATORS_STRINGTONUMBER = 'transform string %1 to %2';
Blockly.Msg.INTEGER = 'Integer';
Blockly.Msg.DECIMAL = 'Decimal';
Blockly.Msg.OPERATORS_NUMTOASCII = 'transform number %1 to ASCII';
Blockly.Msg.OPERATORS_STRINGTOASCII = 'transform string %1 to ASCII';
Blockly.Msg.OPERATORS_NUMTOSTRING = 'transform number %1 to string';

Blockly.Msg.OPERATORS_MAP = "map %1 from[ %2 , %3 ] to[ %4 , %5 ]";
Blockly.Msg.OPERATORS_CONSTRAIN = 'constrain %1 between(min) %2 and(max) %3';
// steps for every turns
Blockly.Msg.STEPS_PER_TURN = 'steps per turn';
Blockly.Msg.ROTATE_SPEED = 'rotate speed(r/s)';
// ai
Blockly.Msg.NEW_AI = 'Make an AI Block';
Blockly.Msg.ASK_AND_PAINT = 'please draw a number';
Blockly.Msg.GET_IDENTIFIED_NUM = 'number recognized';
Blockly.Msg.READ_NUM_AND_SAY = 'speak out a number recognized';

// esp32 image
Blockly.Msg.IMAGE_ADDR = "Image Address";
Blockly.Msg.IMAGE_PREVIEW = "Image Preview";
Blockly.Msg.IMAGE_OPEN = "open";
Blockly.Msg.IMAGE_SIZE = "Image Size";
Blockly.Msg.IMAGE_WIDTH = "W";
Blockly.Msg.IMAGE_HEIGHT = "H";
Blockly.Msg.VARIABLE = "%1";
Blockly.Msg.VARIABLE_LIST = "%1";
Blockly.Msg.SET_STRING_VARIABLE = "set %1 to %2";
Blockly.Msg.STRING_START_WITH = "%1 start with %2";
// 列表下拉框
Blockly.Msg.DATA_MPINSERTATLIST_INSERT = "insert";
Blockly.Msg.DATA_MPINSERTATLIST_CHANGE = "change";
//
Blockly.Msg.DATA_MPINSERTATLIST_FOUR = "%1 %2 list %3 remove value at %4";
Blockly.Msg.DATA_MPINSERTATLIST_FIVE = "%1 %2 list %3 add value %4 to end";
Blockly.Msg.DATA_MPINSERTATLIST_SIX = "%1 %2 list %3 converted to tuple";
Blockly.Msg.DATA_MPINSERTATLIST_SEVEN = "%1 %2 list %3 find index of %4";
Blockly.Msg.DATA_MPINSERTATLIST_EIGHT = "%1 %2 list %3 sort %4";
// 列表下拉框
Blockly.Msg.DATA_MPINSERTATLIST_EIGHT_FALSE = "Ascend";
Blockly.Msg.DATA_MPINSERTATLIST_EIGHT_TRUE = "Descend";
Blockly.Msg.DATA_MPINSERTATLIST_EIGHT_REVERSE = "Reverse";
// 数字
Blockly.Msg.OPERATORS_RANDOMA = "%1 %2 takes a random %5 between %3 and %4";
Blockly.Msg.OPERATORS_MODA = "%1 %2 %3 divided by the remainder of %4";
Blockly.Msg.OPERATORS_ROUNDA = "%1 %2 rounded to %3";
Blockly.Msg.OPERATORS_MATHOPA = '%1 %2 %3 %4';
//Blockly.Msg.OPERATORS_MATHOP_FIVE = "%1 %2 increases the variable %3 by %4";
Blockly.Msg.OPERATORS_MATHOP_SIX = "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]";
Blockly.Msg.OPERATORS_MATHOP_SEVEN = "%1 %2 constrain %3 between(min) %4 and(max) %5";
Blockly.Msg.OPERATORS_NUM_INPUT = "%1 %2 number %3";
Blockly.Msg.OPERATORS_NUMS_CACULATE = "%1 %2 %3 %4 %5";
Blockly.Msg.OPERATORS_SPECIAL_NUM = "%1 %2 %3";
Blockly.Msg.OPERATORS_NUMS_TYPE = "%1 %2 %3 %4?";
Blockly.Msg.OPERATORS_SAVE2DECIMAL = "%1 %2 %3 save %4 decimal places";
Blockly.Msg.OPERATORS_LIST_CACULATE = "%1 %2 %3 %4";
Blockly.Msg.OPERATORS_QUOTIENT_INT = "%1 %2 %3 ÷ %4 integer part of quotient";
Blockly.Msg.OPERATORS_RANDOM_DECIMAL = "%1 %2 random decimal";
Blockly.Msg.OPERATORS_BIN_NEGATION = "%1 %2 binary negation %3";
Blockly.Msg.OPERATORS_INT_TO_STR = "%1 %2 decimal integer %3 transform into %4 string";
Blockly.Msg.OPERATORS_BINSTR_TO_INT = "%1 %2 %3 string %4 transform into decimal integer";
Blockly.Msg.OPERATORS_INT_TO_BYTE = "%1 %2 decimal integer %3 transform into %4 byte";
Blockly.Msg.OPERATORS_INT_TO_ASCII = "%1 %2 int %3 transform into ASCII character";
Blockly.Msg.OPERATORS_ASCII_TO_INT = "%1 %2 ASCII character %3 to int";
Blockly.Msg.OPERATORS_STR_TO_INT = "%1 %2 string %3 to hex string";
Blockly.Msg.OPERATORS_HEXSTR_TO_BIN = "%1 %2 hex string %3 to bin byte";
Blockly.Msg.OPERATORS_STR_TO_BASE64 = "%1 %2 %3 encodes in base64 format returns a byte object";
Blockly.Msg.OPERATORS_BASE64_TO_BYTE = "%1 %2 decode base64 data %3 returns a byte object";

// 数字下拉框
Blockly.Msg.OPERATORS_MATHOP_ABSA = "abs";
Blockly.Msg.OPERATORS_MATHOP_FLOORA = "floor";
Blockly.Msg.OPERATORS_MATHOP_CEILINGA = "ceiling";
Blockly.Msg.OPERATORS_MATHOP_SQRTA = "square";

Blockly.Msg.OPERATORS_MATHOP_SQUARE = '**';
Blockly.Msg.OPERATORS_MATHOP_LOGIC_OR = "|",
Blockly.Msg.OPERATORS_MATHOP_LOGIC_AND = "&",
Blockly.Msg.OPERATORS_MATHOP_LOGIC_POWER = "^",
Blockly.Msg.OPERATORS_MATHOP_LOGIC_LEFT = "<<",
Blockly.Msg.OPERATORS_MATHOP_LOGIC_RIGHT = ">>",

Blockly.Msg.OPERATORS_MATHOP_PI = 'π';
Blockly.Msg.OPERATORS_MATHOP_LOGARITHM = 'e';
Blockly.Msg.OPERATORS_MATHOP_FAI = 'φ';
Blockly.Msg.OPERATORS_MATHOP_SQRT2 = 'sqrt(2)';
Blockly.Msg.OPERATORS_MATHOP_SQRTHALF = 'sqrt(½)';
Blockly.Msg.OPERATORS_MATHOP_UNLIMITED = '∞';

Blockly.Msg.OPERATORS_MATHOP_ISEVEN = 'is even';
Blockly.Msg.OPERATORS_MATHOP_ISODD = 'is odd';
Blockly.Msg.OPERATORS_MATHOP_ISINT = 'is int';
Blockly.Msg.OPERATORS_MATHOP_ISPOSITIVE = 'is positive';
Blockly.Msg.OPERATORS_MATHOP_ISNEGATIVE = 'is negative';
//Blockly.Msg.OPERATORS_MATHOP_DIVISIBLE = 'can be divisible';

Blockly.Msg.OPERATORS_MATHOP_LIST_SUM = 'sum of the numbers in the list';
Blockly.Msg.OPERATORS_MATHOP_LIST_MIN = 'minimum value in list',
Blockly.Msg.OPERATORS_MATHOP_LIST_MAX = 'maximum value in the list',
Blockly.Msg.OPERATORS_MATHOP_LIST_AVERAGE = 'average in list',
Blockly.Msg.OPERATORS_MATHOP_LIST_NUM = 'number of digits in the list',
Blockly.Msg.OPERATORS_MATHOP_LIST_MODE = 'list mode',
Blockly.Msg.OPERATORS_MATHOP_LIST_STDDEVIATION = 'standard deviation in the list',
Blockly.Msg.OPERATORS_MATHOP_LIST_RANDOM = 'random item of list',

Blockly.Msg.OPERATORS_MATHOP_INTTOSTR_BIN = 'binary',
Blockly.Msg.OPERATORS_MATHOP_INTTOSTR_OCT = 'octal',
Blockly.Msg.OPERATORS_MATHOP_INTTOSTR_HEX = 'hex',
// 加入的运算符
Blockly.Msg.OPERATORS_MATHOP_LENGHT = "length %1";
Blockly.Msg.OPERATORS_MATHOP_MAXMIN = "%1 %2";
Blockly.Msg.OPERATORS_RETURN = "return %1";
Blockly.Msg.OPERATORS_VALUE_IS_TRUE = "value %1 is true?";
Blockly.Msg.OPERATORS_VALUE_TYPE = "value %1 type";
Blockly.Msg.OPERATORS_VALUE_TYPE_IS = "value %1 type is %2 ?";
Blockly.Msg.OPERATORS_CACULATE_RESULT = "eval %1";
// 运算符的下拉框
Blockly.Msg.OPERATORS_MATHOP_MAXMIN_MAX = "max";
Blockly.Msg.OPERATORS_MATHOP_MAXMIN_MIN = "min";
Blockly.Msg.OPERATORS_VALUE_TYPE_INT, "int";
Blockly.Msg.OPERATORS_VALUE_TYPE_FLOAT, "float";
Blockly.Msg.OPERATORS_VALUE_TYPE_BOOL, "bool";
Blockly.Msg.OPERATORS_VALUE_TYPE_STR, "str";
Blockly.Msg.OPERATORS_VALUE_TYPE_LIST, "list";
Blockly.Msg.OPERATORS_VALUE_TYPE_TUPLE, "tuple";
Blockly.Msg.OPERATORS_VALUE_TYPE_SET, "set";
Blockly.Msg.OPERATORS_VALUE_TYPE_DICT, "dict";
Blockly.Msg.OPERATORS_VALUE_TYPE_BYTES, "bytes";
Blockly.Msg.OPERATORS_VALUE_TYPE_BYTEARRAY, "bytearray";
Blockly.Msg.OPERATORS_VALUE_TYPE_COMPLEX, "complex";

Blockly.Msg.OPERATORS_MATHOP_INTTOBYTE_TWO = "2";
Blockly.Msg.OPERATORS_MATHOP_INTTOBYTE_FOUR = "4";

// 文本
Blockly.Msg.OPERATORS_TEXTS_ONE = "%1 %2 merge %3 %4";
Blockly.Msg.OPERATORS_TEXTS_TWO = "%1 %2 get the character of %3 with index %4";
Blockly.Msg.OPERATORS_TEXTS_THREE = "%1 %2 %3 contain %4 ?";
Blockly.Msg.OPERATORS_TEXTS_FOUR = "%1 %2 %3 get subString from %4 %5th to %6 %7th";
Blockly.Msg.OPERATORS_TEXTS_FOUR_NEW = "%1 gets %2 %3 characters to %4 %5characters";
Blockly.Msg.OPERATORS_TEXTS_NEW = "%1 %2 find %3 in %4  %5 Emerging position";
Blockly.Msg.OPERATORS_TEXTS_NEW_OP = "find where %1 appears in %2 %3";
Blockly.Msg.OPERATORS_INPUTSTRING = "%1 %2 string %3";
Blockly.Msg.OPERATORS_TRANSINTOTEXT = "%1 %2 transform into %3";
Blockly.Msg.OPERATORS_TRANSINTOTEXT_ADD = "add";
Blockly.Msg.OPERATORS_TRANSINTOTEXT_ADD_ITEM = "item";
Blockly.Msg.OPERATORS_FORMATSTRING2F = "%1 %2 format string %3 % %4";
Blockly.Msg.OPERATORS_FORMATSTRING3 = "%1 %2 format string %3 format %4";
Blockly.Msg.OPERATORS_TEXT_ESCAPE = "%1 %2 text escape character %3";
Blockly.Msg.BAR_N = "\\n";
Blockly.Msg.BAR_NN = "\\n\\n";
Blockly.Msg.BAR_R = "\\r";
Blockly.Msg.BAR_RN = "\\r\\n";
Blockly.Msg.BAR_B = "\\b";
Blockly.Msg.BAR_T = "\\t";
Blockly.Msg.BAR_BAR = "\\\\";
Blockly.Msg.OPERATORS_TEXT_NUMORLETTER = "%1 %2 %3 %4?";
Blockly.Msg.OPERATORS_TEXT_NUMORLETTER_NUM = "is number";
Blockly.Msg.OPERATORS_TEXT_NUMORLETTER_LETTER = "is letter";
Blockly.Msg.OPERATORS_TEXT_LENGTH = "%1 %2 %3 length";
Blockly.Msg.OPERATORS_TEXT_ISNONE = "%1 %2 %3 is none?";
Blockly.Msg.OPERATORS_FROMTEXT_GETCHAR = "%1 %2 in text %3 %4";
Blockly.Msg.OPERATORS_FROMTEXT_GETCHAR_FIRST = "first letter";
Blockly.Msg.OPERATORS_FROMTEXT_GETCHAR_LAST = "last letter";
Blockly.Msg.OPERATORS_FROMTEXT_GETCHAR_RANDOM = "random letter";
// Blockly.Msg.OPERATORS_FROMTEXT_GETFROMTO = "%1 %2 in text %3 get substring from %4 %5 to %6 %7";
// Blockly.Msg.OPERATORS_FROMTEXT_GETFROMTO_LETTER1 = "letter #";
// Blockly.Msg.OPERATORS_FROMTEXT_GETFROMTO_LETTEREND1 = "letter # from end";
// Blockly.Msg.OPERATORS_FROMTEXT_GETFROMTO_FIRST = "first letter";
// Blockly.Msg.OPERATORS_FROMTEXT_GETFROMTO_LETTER2 = "letter #";
// Blockly.Msg.OPERATORS_FROMTEXT_GETFROMTO_LETTEREND2 = "letter # from end";
// Blockly.Msg.OPERATORS_FROMTEXT_GETFROMTO_LAST = "last letter";
Blockly.Msg.OPERATORS_TEXT_UPPLOW = "%1 %2 %3 %4";
Blockly.Msg.OPERATORS_TEXT_UPPLOW_UPP = "uppercase";
Blockly.Msg.OPERATORS_TEXT_UPPLOW_LOW = "lowercase";
Blockly.Msg.OPERATORS_TEXT_SIDES_SPACE = "%1 %2 %3 %4";
Blockly.Msg.OPERATORS_TEXT_SIDES_SPACE_BOTH = "clear spaces on both sides";
Blockly.Msg.OPERATORS_TEXT_SIDES_SPACE_LEFT = "clear left space";
Blockly.Msg.OPERATORS_TEXT_SIDES_SPACE_RIGHT = "clear right space";
Blockly.Msg.OPERATORS_TEXT_STRTOBYTE = "%1 %2 turn byte %3";
Blockly.Msg.OPERATORS_TEXT_BYTETOSTR = "%1 %2 byte %3 convert to string";
Blockly.Msg.OPERATORS_TEXT_DICTTOJSONSTR = "%1 %2 dict %3 transform into json string";
Blockly.Msg.OPERATORS_TEXT_PARSEJSONSTR = "%1 %2 parse json string %3 and return object";

//文本下拉框
Blockly.Msg.OPERATORS_TEXTS_FOUR_ONE = 'forward';
Blockly.Msg.OPERATORS_TEXTS_FOUR_TWO = "reverse";
Blockly.Msg.OPERATORS_TEXTS_FOUR_THREE = "first";
Blockly.Msg.OPERATORS_TEXTS_FOUR_FOUR = "last";
Blockly.Msg.OPERATORS_TEXTS_FOUR_F = "first";
Blockly.Msg.OPERATORS_TEXTS_FOUR_L = "last";
// =======================================
Blockly.Msg.OPERATORS_STRINGTONUMBERA = "%1 %2 converts the string %3 to %4";
Blockly.Msg.OPERATORS_NUMTOASCIIA = "%1 %2 converts the number %3 to ASCII characters";
Blockly.Msg.OPERATORS_STRINGTOASCIIA = "%1 %2 converts the character %3 to an ASCII value";
Blockly.Msg.OPERATORS_NUMTOSTRINGA = "%1 %2 converts the number %3 to a string";
// 变量
Blockly.Msg.NEW_MC = 'new variable';
Blockly.Msg.RENAME_MCNUMBER_TITLE = 'rename all variables whose variable name is \"%1\" to:';
Blockly.Msg.RENAME_MCNUMBER_MODAL_TITLE = "rename variable";
Blockly.Msg.RENAME_VARIABLE_MCNUMBER = "rename variable";
Blockly.Msg.DELETE_VARIABLE_MCNUMBER = "delete variable \"%1\"";
Blockly.Msg.SET_MC_VARIABLE = "set %1 to %2";
Blockly.Msg.GLOBAL_MPY_VARIABLE = 'global %1';
// mqtt settings
Blockly.Msg.SIOT = "SIOT";
Blockly.Msg.ALIYUN = "Aliyun";
Blockly.Msg.ONENET = "OneNet";
Blockly.Msg.ONENETNEW = "OneNet(New)";
Blockly.Msg.EASYIOT = "Easy Iot";
Blockly.Msg.SHANGHAI = "shanghai";
Blockly.Msg.QINGDAO = "qingdao";
Blockly.Msg.BEIJING = "beijing";
Blockly.Msg.ZHANGJIAKOU = "zhangjiakou";
Blockly.Msg.HUHEHAOTE = "huhehaote";
Blockly.Msg.HANGZHOU = "hangzhou";
Blockly.Msg.SHENZHEN = "shenzhen";
Blockly.Msg.HONGKONG = "hongkong";
Blockly.Msg.SINGAPORE = "singapore";
Blockly.Msg.SYDNEY = "Sydney";
Blockly.Msg.KUALALUMPUR = "Luala Lumpur";
Blockly.Msg.JAKARTA = "Jakarta";
Blockly.Msg.MUMBAI = "Mumbai";
Blockly.Msg.TOKYO = "Tokyo";
Blockly.Msg.SILICONVALLEY = "Silicon Valley";
Blockly.Msg.VIRGINIA = "Virginia";
Blockly.Msg.FRANKFURT = "Frankfurt";
Blockly.Msg.LONDON = "London";
Blockly.Msg.DUBAI = "Dubai";
Blockly.Msg.IOT_PLATFORM = "Iot Platform";
Blockly.Msg.PARAMS = "Params";
Blockly.Msg.SERVER_ATTR = "Server Addr";
Blockly.Msg.PRODUCTID = "ProductId";
Blockly.Msg.DEVICEID = "DeviceId";
Blockly.Msg.DEVICENAME = "DeviceName";
Blockly.Msg.TINYDB_SECRET = "secret (secret)";
Blockly.Msg.TINYDB_USER = "user (user)";
Blockly.Msg.APIADDR = "Api Addr";
Blockly.Msg.SOFTWARE_SERIAL = "Software Serial";
Blockly.Msg.HARDWARE_SERIAL = "Hardware Serial";
Blockly.Msg.HARDWARE_SERIAL1 = "Hardware Serial1";
Blockly.Msg.HARDWARE_SERIAL2 = "Hardware Serial2";
Blockly.Msg.HARDWARE_SERIAL3 = "Hardware Serial3";
Blockly.Msg.CHECKTYPE_TIPS = "The data type received by the input box does not match the output type of the building block";
Blockly.Msg.HIGHLIGHT_BLOCK = "hight light block";
Blockly.Msg.HIGHLIGHT_ALL_CONFLICT_BLOCKS = "highlight all blocks with warnings";
Blockly.Msg.SNAPSHOT = "Snapshot (CTRL+G)";
Blockly.Msg.pictureaiPath = "please enter the picture path or URL";
Blockly.Msg.pictureaiType = "account type";
Blockly.Msg.pictureaiAccountDefault = "default account";
Blockly.Msg.pictureaiAccountBaidu = "baidu account";
Blockly.Msg.pictureaiwebImgAddr = "web image address";
Blockly.Msg.MITRIXICON_EDIT = "light up";
Blockly.Msg.MITRIXICON_ERASER = "eraser";
Blockly.Msg.MITRIXICON_DUSTBIN = "clear";
Blockly.Msg.MITRIXICON_REVERSE = "reverse";
Blockly.Msg.MITRIXICON_COLOR = "select color";
Blockly.Msg.MITRIXICON_SAVE = "save";
Blockly.Msg.MITRIXICON_EXPORT = "export";
Blockly.Msg.MITRIXICON_UPLOAD = "upload";
Blockly.Msg.ROLL_MOUSE = "Rolling mouse";
Blockly.Msg.MOUSE_ZOOM_IN_OUT = "Scroll the mouse to zoom in and out";
Blockly.Msg.UPLOAD_CORRECT_JSON_FILE = "Please upload the json file in the correct format";
Blockly.Msg.EXPORT_PROMPT = "There are no custom patterns to export. Please click the save button in the lower right corner to save the custom pattern before attempting to export.";
Blockly.Msg.HEART = "Heart";
Blockly.Msg.HEART_SMALL = "Small heart";
Blockly.Msg.HAPPY = "Happy";
Blockly.Msg.SMILE = "Smile";
Blockly.Msg.SAD = "Sad";
Blockly.Msg.CONFUSED = "Confused";
Blockly.Msg.ANGRY = "Angry";
Blockly.Msg.SLEEP = "Sleep";
Blockly.Msg.SURPRISED = "Surprised";
Blockly.Msg.SILLY = "Silly";
Blockly.Msg.WONDERFUL = "Wonderful";
Blockly.Msg.BORED = "Bored";
Blockly.Msg.ROCK = "Rock";
Blockly.Msg.ROCK_SMALL = "Small rock";
Blockly.Msg.PAPER = "Paper";
Blockly.Msg.PAPER_SMALL = "Small paper";
Blockly.Msg.SCISSORS = "scissors";
Blockly.Msg.SCISSORS_SMALL = "Small scissors";
Blockly.Msg.RED = "red";
Blockly.Msg.GREEN = "green";
Blockly.Msg.BLUE = "blue";
Blockly.Msg.SEARCH_BLOCKS = "搜索积木";
Blockly.Msg.NO_SEARCH_BLOCKS = "没有搜索积木";
Blockly.Msg.SEARCH_DEFAULT_LABEL_THINK = "思考";
Blockly.Msg.SEARCH_DEFAULT_LABEL_IF = "如果";
Blockly.Msg.SEARCH_DEFAULT_LABEL_WAIT = "等待";
Blockly.Msg.SEARCH_DEFAULT_LABEL_CONVERT = "转换";
Blockly.Msg.SEARCH_DEFAULT_LABEL_BROADCAST = "广播";

