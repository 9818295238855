
import DFIcon from "component/df-icon/DFIcon"
import DFModal from "component/df-modal/DFModal"
import DFSteps from "component/df-steps/DFSteps"
import { LINK_DOWNLOAD_URL_WIN } from "config/config"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeLinkModal, selectLinkModal } from "redux/link-modal/linkModalSlice"
import { commandWs } from "service/link-adapter/websocket/commandWs"
import LinkConnectTip from "./LinkConnectTip.svg"
import styles from "./LinkModal.module.scss"

export const LinkModal = () => {
    const { type, linkModal } = useSelector(selectLinkModal)
    const dispatch = useDispatch()

    const closeModal = useCallback(() => {
        dispatch(closeLinkModal())
    }, [])

    useEffect(() => {
        if (linkModal) {
            // 唤起Link
            window.location.href = "mind+link://";

            commandWs.on("open", closeModal)
            // 定时扫描
            const intevalID = setInterval(() => {
                commandWs.open();
            }, 1200)
            return () => {
                clearInterval(intevalID);
                commandWs.off("open", closeModal)
            }
        }
    }, [linkModal, closeModal])

    // 如果ws已经连接, 返回null
    if (commandWs.isOpen()) {
        return null;
    }

    const downloadWin = () => {
        let a = document.createElement("a");
        a.href = LINK_DOWNLOAD_URL_WIN;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    return (
        <DFModal
            isShow={linkModal}
            onClose={closeModal}
            width={"680px"}
            // height={"500px"}
            title={"运行Mind+ Link"}
            icon={<DFIcon name="link" color="#FFFFFF" size={26} />}
        >
            <div className={styles.content}>
                <div className={styles.propmt}>
                    <div className={styles.helpIcon}><DFIcon name="help" color="#AAAAAA" size={20} /></div>
                    <div className={styles.propmtText}>{
                        "由于Mind+运行在浏览器上，与硬件主控板进行连接需要中间插件进行通信，因此在使用Mind+在线版时请确保Mind+ Link正在运行。"
                    }</div>
                </div>
                <div className={styles.steps}>
                    <DFSteps items={[
                        <div className={styles.step}>
                            <div className={styles.stepText}>
                                首次使用，下载并安装对应系统的Mind+ Link。
                            </div>
                            <div className={styles.download}>
                                <div className={styles.downloadItem} onClick={downloadWin}>
                                    <div className={styles.icon}><DFIcon name="windows" color="#D8D8D8" size={28} /></div>
                                    <div className={styles.platform}>Windows</div>
                                    <div className={styles.downloadButton} >
                                        点击下载
                                        <div className={styles.img}><DFIcon name="cursor" color="#A26A00" size={18} /></div>
                                    </div>
                                </div>
                                <div className={styles.downloadItem}>
                                    <div className={styles.icon}><DFIcon name="mac" color="#D8D8D8" size={28} /></div>
                                    <div className={styles.platform}>Mac</div>
                                    <div className={`${styles.downloadButton} ${styles.disabled}`}>
                                        即将推出
                                        {/* <div className={styles.img}><DFIcon name="cursor" color="#A26A00" size={18}/></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>,
                        <div className={styles.step}>
                            <div className={styles.stepText}>
                                保持Mind+ Link后台运行，即可连接主控板。
                            </div>
                            <div className={styles.download}>
                                <div className={styles.downloadItem}>
                                    <img style={{ width: "18rem" }} src={LinkConnectTip} alt=""/>
                                </div>
                            </div>
                        </div>
                    ]} />
                </div>
                <div className={styles.more}></div>
            </div>
        </DFModal>
    );
}
