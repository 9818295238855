import makeToolboxXML from "lib/make-toolbox-xml";

export function getToolboxXML (vm: any) {
    // Use try/catch because this requires digging pretty deep into the VM
    // Code inside intentionally ignores several error situations (no stage, etc.)
    // Because they would get caught by this try/catch
    try {
        let {editingTarget: target, runtime} = vm;
        const stage = runtime.getTargetForStage();
        if (!stage) return null;
        if (!target) target = stage; // If no editingTarget, use the stage

        const stageCostumes = stage.getCostumes();
        const targetCostumes = target.getCostumes();
        const targetSounds = target.getSounds();
        const dynamicBlocksXML = vm.runtime.getBlocksXML(target);
        return makeToolboxXML(false, target.isStage, target.isDevice, false, target.id, dynamicBlocksXML,
            targetCostumes[targetCostumes.length - 1].name,
            stageCostumes[stageCostumes.length - 1].name,
            targetSounds.length > 0 ? targetSounds[targetSounds.length - 1].name : ''
        );
    } catch(e) {
        console.log('e==', e)
        return null;
    }
}
