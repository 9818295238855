import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ReactModal from 'react-modal';
import App from './app';
import CustomProvider from "view/custom-provider/CustomProvider";

(App as any).setAppElement = ReactModal.setAppElement;
export const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
(App as any).setAppElement(document.getElementById('root'))
root.render(
    // <React.StrictMode>
    <CustomProvider>
        <App/>
    </CustomProvider>
    // </React.StrictMode>
);
