import ScratchMonitor from './monitor';
import {vm} from "../../lib/scratch-vm";
import {useDispatch, useSelector} from "react-redux";
import {
    addMonitorRect,
    removeMonitorRect,
    resizeMonitorRect,
    selectMonitorLayout
} from "../../redux/monitor-layout/monitorLayoutSlice";
type Props = {

}
export const Monitor = (props: Props) => {
    const monitorLayout = useSelector(selectMonitorLayout)
    const dispatch = useDispatch();
    const addMonitorRect_ = (id, rect, savePosition) => dispatch(addMonitorRect({
        monitorId:id, upperStart: rect.upperStart, lowerEnd:rect.lowerEnd, savePosition
    }));
    const resizeMonitorRect_ = (id, newWidth, newHeight) => dispatch(resizeMonitorRect({monitorId: id, newWidth, newHeight}));
    const removeMonitorRect_ = id => dispatch(removeMonitorRect({monitorId: id}))
    // todo: toolboxXml更新时, 刷新组件
    return <ScratchMonitor
        monitorLayout={monitorLayout}
        toolboxXML={null}
        vm={vm}
        addMonitorRect={addMonitorRect_}
        resizeMonitorRect={resizeMonitorRect_}
        removeMonitorRect={removeMonitorRect_}
        {...props}
    />
}
