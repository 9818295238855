import { CSSProperties, FC, useEffect, useMemo, useRef } from "react"
import styles from './DFDrawer.module.scss'
import { createPortal } from "react-dom"

type DFDrawerProps = {
    keyboard?: boolean
    open: boolean
    onClose: () => void
    placement: 'left' | 'right' | 'top' | 'bottom',
    rootStyle?: CSSProperties
    children: React.ReactNode
    destroyOnClose?: boolean
    mask?: boolean
    maskClosable?: boolean
    maskStyle?: CSSProperties
    height?: number
    width?: number
    zIndex?: number
}

export const DFDrawer: FC<DFDrawerProps> = ({ keyboard = true, open, onClose, placement = 'right', rootStyle, children, destroyOnClose = false, mask = true, maskClosable = true, maskStyle, height, width, zIndex }) => {
    const rootContainerRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (destroyOnClose && !open && rootContainerRef.current) {
            document.body.removeChild(rootContainerRef.current)
        }
    }, [open, destroyOnClose])

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && open) {
                onClose()
            }
        }
        if (keyboard) {
            document.addEventListener('keydown', handleKeyDown)
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [keyboard, onClose, open])

    const computedContentStyle = useMemo(() => {
        if (placement === 'right' || placement === 'left') {
            if (width) {
                return { width }
            }
        }
        if (placement === 'top' || placement === 'bottom') {
            if (height) {
                return { height }
            }
        }
    }, [height, placement, width])

    const handleMaskClick = () => {
        if (maskClosable) {
            onClose()
        }
    }
    return (
        <>
            {open && createPortal(<div className={styles.rootContainer} style={{ ...rootStyle, zIndex }} ref={rootContainerRef}>
                {mask && <div className={styles.mask} style={{ ...maskStyle, zIndex }} onClick={handleMaskClick}></div>}
                <div className={`${styles.drawer} ${styles[placement]}`} style={{ ...computedContentStyle, zIndex }} onClick={e => e.stopPropagation()}>
                    {children}
                </div>
            </div>, document.body)}
        </>
    )

}
