import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import VM from "scratch-vm";

import spriteLibraryContent from "lib/asset/sprite/sprites.json";
import randomizeSpritePosition from "lib/randomize-sprite-position";
import spriteTags from "lib/asset/sprite/sprite-tags";

import LibraryComponent from "component/library/library";

const messages = defineMessages({
  libraryTitle: {
    id: "gui.extension.spriteLib",
    description: "Label for extension of the spriteLib",
    defaultMessage: "Sprite Library",
  },
});

class SpriteLibrary extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this, [
      "handleItemSelect",
      "handleMouseEnter",
      "handleMouseLeave",
      "rotateCostume",
      "startRotatingCostumes",
      "stopRotatingCostumes",
    ]);
    this.state = {
      activeSprite: null,
      costumeIndex: 0,
      sprites: spriteLibraryContent,
    };
  }
  handleMouseEnter(item) {
    this.stopRotatingCostumes();
    if(item.costumes.length === 0) return;
    this.setState({ activeSprite: item }, this.startRotatingCostumes);
  }
  handleMouseLeave() {
    this.stopRotatingCostumes();
  }
  startRotatingCostumes() {
    if (!this.state.activeSprite) return;
    this.rotateCostume();
    this.intervalId = setInterval(this.rotateCostume, 300);
  }
  stopRotatingCostumes() {
    this.intervalId = clearInterval(this.intervalId);
  }
  rotateCostume() {
    const costumes = this.state.activeSprite.costumes;
    const nextCostumeIndex = (this.state.costumeIndex + 1) % costumes.length;
    this.setState({
      costumeIndex: nextCostumeIndex,
      sprites: this.state.sprites.map((sprite) => {
        if (sprite.name === this.state.activeSprite.name) {
             return {
               ...sprite,
               costumes: [
                 ...sprite.costumes.slice(nextCostumeIndex),
                 ...sprite.costumes.slice(0, nextCostumeIndex),
               ],
             };
        }
        return sprite;
      }),
    });
  }
  handleItemSelect(item) {
    this.stopRotatingCostumes();
    // Randomize position of library sprite
    randomizeSpritePosition(item);
    this.props.vm.addSprite(JSON.stringify(item)).then(() => {
      this.props.onActivateBlocksTab();
    });
  }
  render() {
    return (
      <LibraryComponent
        data={this.state.sprites}
        id="spriteLibrary"
        tags={spriteTags}
        title={this.props.intl.formatMessage(messages.libraryTitle)}
        onItemSelected={this.handleItemSelect}
        onRequestClose={this.props.onRequestClose}
        onItemMouseEnter={this.handleMouseEnter}
        onItemMouseLeave={this.handleMouseLeave}
      />
    );
  }
}

SpriteLibrary.propTypes = {
  onActivateBlocksTab: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func,
  vm: PropTypes.instanceOf(VM).isRequired,
};

export default injectIntl(SpriteLibrary);
