import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "../store";

export type ThemeType = 'base' | 'eye-protect' | 'blue' | 'red' | 'green' | 'purple' | 'orange' | 'violet'

const supportedThemes: ThemeType[] = ['base', 'eye-protect', 'blue', 'red', 'green', 'purple', 'orange', 'violet']

const initialState = localStorage.getItem('theme') || 'base'

export const themeSlice = createSlice({
  name: 'theme',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<ThemeType>) => {
      if (!supportedThemes.includes(action.payload)) { 
        return state
      }
      localStorage.setItem('theme', action.payload)
      return action.payload
    }
  },
})

export const { changeTheme } = themeSlice.actions;
export const selectTheme = (state: RootState) => state.theme

export default themeSlice.reducer
