import { FC, MouseEventHandler, ReactNode, useEffect, useRef, useState } from "react";
import styles from './DFExtensionDropdown.module.scss';

export type DFDropdownItem = {
    label: string;
    key: string | number;
    disabled?: boolean;
};

type DFDropdownProps = {
    items: DFDropdownItem[];
    onItemClick?: (item: DFDropdownItem) => void;
    children: ReactNode;
    position: 'right' | 'left' | 'top' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
};

export const DFExtensionDropdown: FC<DFDropdownProps> = (props) => {
    const [visible, setVisible] = useState<boolean>(false);
    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target as Node)) {
                setVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClick: MouseEventHandler = (e) => {
        setVisible(prev => !prev);
    };

    const handleDropdownItemClick = (item: DFDropdownItem) => {
        if (item.disabled) return;
        if (props.onItemClick) {
            props.onItemClick(item);
        }
    };

    useEffect(() => {
        if (visible) {
            const dropdownContainerRect = dropdownContainerRef.current?.getBoundingClientRect();
            const containerRect = containerRef.current?.getBoundingClientRect();
            if (dropdownContainerRect && containerRect) {
                const dropdownPosition = {
                    left: 0,
                    top: 0,
                };
                switch (props.position) {
                    case 'right':
                        dropdownPosition.left = dropdownContainerRect.width;
                        dropdownPosition.top = dropdownContainerRect.height / 2;
                        if (containerRect.left + containerRect.width > window.innerWidth - 100) {
                            dropdownPosition.left = dropdownPosition.left - containerRect.width;
                        }
                        break;
                }
                // 设置样式
                if (containerRef.current) {
                    containerRef.current.style.left = `${dropdownPosition.left}px`;
                    containerRef.current.style.top = `${dropdownPosition.top}px`;
                }
            }
        }
    }, [props.position, visible]);

    return (
        <div className={`${styles.dropdownContainer}`} onClick={handleClick} ref={dropdownContainerRef}>
            {props.children}
            {visible && (
                <div className={styles.container} ref={containerRef}>
                    <div className={`${styles.wrapper} ${!visible ? styles.hidden : styles.show}`}>
                        <ul className={styles.itemList}>
                            {props.items.map(item => (
                                <li
                                    className={`${styles.item} ${item.disabled ? styles.disabled : ''}`}
                                    key={item.key}
                                    onClick={() => handleDropdownItemClick(item)}
                                >
                                    {item.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};
