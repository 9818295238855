import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

interface Target {
    id: string
    isStage?: boolean
    costumes?: [
        {
            url?: string
            name: string
            skinId?: number
        }
    ],
    sounds?: [
        {
            name: string
        }
    ]
}


// 因Redux的数据不可变性, sprites等内容不能放在Redux中, 需要将原先Redux状态的数据缓存和页面渲染两部分功能拆解,
// sprites等数据缓存放在vm中, Redux中用一个无意义的number类型状态刷新页面.
interface TargetsState {
    // sprites: {[id: string]: Target},
    // stage: Target|{},
    targetsUpdateCount: number, // 没有任何实际意义, 只是为了刷新页面
    highlightedTargetId: string|null,
    highlightedTargetTime: number|null,
    editingTarget?: string
}

// Define the initial state using that type
const initialState: TargetsState = {
    targetsUpdateCount: 0,
    editingTarget: undefined,
    highlightedTargetId: null,
    highlightedTargetTime: null
}

export const targetsSlice = createSlice({
    name: 'targets',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // 更新targets
        updateTargets: (state) => {
            // 通知页面渲染
            state.targetsUpdateCount += 1;
        },
        updateEditingTarget: (state, action: PayloadAction<string>) => {
            // 更新编辑对象
            state.editingTarget = action.payload;
        },
        highlightTarget: (state, action: PayloadAction<string>) => {
            state.highlightedTargetId = action.payload;
            state.highlightedTargetTime = Date.now();
        }
    },
})

export const {updateTargets, highlightTarget, updateEditingTarget} = targetsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectTargets = (state: RootState) => state.targets

export default targetsSlice.reducer
