import React from "react";
import styles from "./Gui.module.scss";
import MenuBar from "./menu-bar/MenuBar";
import StageWrapper from "./stage-wrapper/StageWrapper";
import BlockWrapper from "./block-wrapper/BlockWrapper";
import { useInit } from "./util/useInit";
import { AssetLib } from "./asset-lib/AssetLib";
import { DragLayer } from "./drag-layer/DragLayer";
import { ConnectionModal } from "../component/connection-modal";
import { useSelector } from "react-redux";
import { selectModals } from "../redux/modals/modalsSlice";
import { selectProject } from "redux/project/projectSlice";
import Loader from "component/loader/loader";
import DFCameraModal from "component/df-camera-modal/DFCameraModal";
import Ml5Traninmodal from "component/ml5-trainmodal/ml5-traninmodal";
import { vm } from "lib/scratch-vm";
import { HuskyProgress } from "component/husky-progress/HuskyProgress";

const Gui = () => {
  const { isInit } = useInit();
  const { projectLoadingStatus } = useSelector(selectProject)
  const { connectionModal } = useSelector(selectModals);
  if (!isInit) return null;

  return (
    <div className={styles.mainContainer} dir={"ltr"}>
      {/* TODO: 创建项目时，messageId应该不一致*/}
      {(projectLoadingStatus === "loading" || projectLoadingStatus === "creating") && <Loader />}
      <div className={styles.header}>
        <MenuBar />
      </div>
      <div className={styles.content}>
        <div className={styles.blockWrapper}>
          <BlockWrapper />
        </div>
        <StageWrapper />
      </div>
      <AssetLib />
      <DragLayer />
      <DFCameraModal />
      <Ml5Traninmodal vm={vm} />
      <HuskyProgress />
      {connectionModal ? <ConnectionModal /> : null}
    </div>
  );
};

export default Gui;
