import { vm } from 'lib/scratch-vm';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssetDrag, updateAssetDrag } from 'redux/asset-drag/assetDragSlice';
import { selectHoverTarget, setHoveredSprite } from 'redux/hover-target/hoverTargetSlice';
import SpriteSelectorItemScratch from './sprite-selector-item';


export const SpriteSelectorItem = (props: any) => {
    const {dragging} = useSelector(selectAssetDrag);
    const {receivedBlocks, sprite} = useSelector(selectHoverTarget)
    const dispatch = useDispatch();
    return (
        <SpriteSelectorItemScratch
            {...props}
            dragging={dragging}
            receivedBlocks={receivedBlocks&& sprite === props.id}
            vm={vm}
            dispatchSetHoveredSprite={spriteId => {
                dispatch(setHoveredSprite(spriteId));
            }}
            onDrag={data => dispatch(updateAssetDrag(data))}
        />
    )
}