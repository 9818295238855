// @flow
import * as React from 'react';
import {useDispatch} from "react-redux";
import {closeSoundRecorderModal} from "redux/modals/modalsSlice";
import RecordModalScratch from './record-modal'
import {vm} from "lib/scratch-vm";

type Props = {
    onNewSound:()=>void
};
export const RecordModal = (props: Props) => {
    const dispatch = useDispatch()
    const onClose = ()=>{
        dispatch(closeSoundRecorderModal())
    }
    return (
        <RecordModalScratch
            onClose={onClose}
            vm={vm}
            onNewSound={props.onNewSound}
        />
    );
};
