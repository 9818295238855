import React from 'react';
import bindAll from 'lodash.bindall';
import { injectIntl } from 'react-intl';
import Ml5TraninModalComponent from './component/ml5-trainmodal';
import message from 'component/df-message/Message'
import messagesAlert from './component/message';
const alertObj = { mode: 1, timeout: 5000, zIndex: 6666 };
const tipsInfo = {
    //摄像头打开失败
    whenDeleteImageAfterTrain: {
        id: "gui.blocklyText.dialog.ml5.whenDeleteImageAfterTrain",
        description: 'text for delete image',
        default: '该图片数据已经经过训练，删除后，需要重新训练才能生效'
    },
    renameLabel: {
        id: "gui.blocklyText.dialog.ml5.renameLabel",
        description: 'text for delete image',
        default: 'The tag [LABEL] already exists, do you want to merge it?'
    },
    notAllowChinese: {
        id: 'gui.blocklyText.dialog.huskylens.notAllowChinese',
        default: 'not Allow Chinese',
        description: 'The category name should be composed of numbers and letters with no more than 50 digits, and cannot contain Chinese and special characters'
    }
}

class Ml5TraninModal extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "setCollapse",
            "close",
            "addLabel",
            "deleteImage",
            "deleteLabel",
            "addTrain",
            "restartTrain",
            "exportModel",
            "setChooseItem",
            "predictImage",
            "setImgHide",
            "updateLabel",
            "renameLabel",
            "focusInput",
            "setImgList",
            "onscroll"
        ]);
        this.state = {
            open: this.props.open || false,
            showPopup: true,
            labelList: [],
            chooseItem: 0,
            imgResult: {},
            collapse: true
        };
        this.runtime = props.vm.runtime;
    }
    componentDidMount() {
        this.attachRuntime();
        this.getImgList();
    }

    attachRuntime() {
        this.runtime.ml5TrainComponent = this.runtime.ml5TrainComponent || {};
        this.runtime.ml5TrainComponent = Object.assign(
          this.runtime.ml5TrainComponent,
          {
            comShow: (callback) => {
              this.show();
              if (callback) callback();
            },
            comHide: () => this.close(),
            setLoading: (loading, text) => {
              this.setState({ loading: loading, loadingTips: text });
            },
            setImgList: (data) => {
              this.setImgList(data);
            },
            setResults: (label, score) => {
              this.setState({ imgResult: { label: label, score: score } });
            },
            setVisualType: (visualType) => {
              this.setState({ visualType: visualType });
            },
            setHasTrained: (judge) => {
              this.setState({ hasTrained: judge });
            },
            attachMethod: (name, callback) => {
              this.runtime.ml5TrainComponent[name] = callback;
            },
            resetAll: () => {
              console.log('resetAll----------------------');
              this.setState({open: false, showPopup: true, labelList: [], chooseItem: 0, imgResult: {}, visualType: 'image', hasTrained: false })
            }
          }
        );
    }

    componentWillUnmount() {
        this.runtime.ml5TrainComponent = null;
    }

    setCollapse() {
        this.setState({ collapse: !this.state.collapse });
    }

    setImgList(data) {
        data && this.setState({ labelList: Object.assign([], data) });
    }

    getImgList() {
        if (this.runtime.ml5TrainComponent.getImgList) {
            let data = this.runtime.ml5TrainComponent.getImgList() || [];
            this.setState({ labelList: data });
        }

    }

    show() {
        this.setState({ open: true, showPopup: true });
        this.getImgList();
    }

    close() {
        this.setState({ open: false });
        return;
    }

    initMl5Classifiy() {
        return !9;
    }

    whenModifyImage() {
        let { hasTrained } = this.state;
        //避免多次重复提醒
        if (hasTrained && !this.deleteKey) {
            this.deleteKey = new Date().getTime();
            message.info({
                content: this.props.intl.formatMessage(tipsInfo.whenDeleteImageAfterTrain),
            });
            setTimeout(() => {
                message.info({
                    content: this.props.intl.formatMessage(tipsInfo.whenDeleteImageAfterTrain),
                    duration: 2
                });
                this.deleteKey = null;
            }, 2000);
        }
    }

    deleteImage(label_index, img_index) {
        let { labelList } = this.state;
        this.whenModifyImage();
        if (labelList[label_index] && labelList[label_index].imgInstance.length) {
            //labelList[label_index].img.splice(img_index, 1);
            labelList[label_index].imgInstance.splice(img_index, 1);
            //labelList[label_index].imgData.splice(img_index, 1);
            this.setState({ labelList: Object.assign([], labelList) });
        }
    }

    getCameraImg() {
        let mm = this.runtime.ml5TrainComponent.getVideoImage();
        return mm ? "data:image/png;base64," + mm : null;
    }

    setChooseItem(index, e) {
        e && e.preventDefault();

        let { labelList } = this.state;
        index = index == undefined || index == null ? 0 : index;
        if(labelList[index])labelList[index].hide = false
        this.setState({ labelList: Object.assign([], labelList), chooseItem: index });
    }

    setImgHide(label_index, e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.initMl5Classifiy()) return;

        let { labelList } = this.state;
        if (labelList[label_index]) {
            labelList[label_index].hide = !labelList[label_index].hide;
            this.setState({ labelList: Object.assign([], labelList) });
        }
    }

    //第一次添加的分类，点击修改图标显示重命名输入框
    updateLabel(index, e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.initMl5Classifiy()) return;

        let { labelList } = this.state;
        for (let i in labelList) {
            if (i == index) {
                labelList[i].modify = true;
                this.oldLabel = labelList[i].label;
                this.setState({ labelList: Object.assign([], labelList) });
                break;
            }
        }
    }

    focusInput(e) {
        e.stopPropagation();
        let len = e.target.value && e.target.value.length;
        e.target.setSelectionRange(0, len);
    }

    //输入框修改后进行重命名
    renameLabel(index, e, judge) {
        if (this.initMl5Classifiy()) return;
        //e.preventDefault();
        e.stopPropagation();

        let newName = e.target.value;
        newName = newName.replace(/ /g, "");

        if (newName.match(/[\u4E00-\u9FA5?？,，.。~`·、\/&……%￥#@！!]+/) || newName.length > 50) {
            this.runtime.dfrobotAlert(this.props.intl.formatMessage(messagesAlert.prompt), this.props.intl.formatMessage(tipsInfo.notAllowChinese), alertObj);
            return;
        }

        let { labelList } = this.state;
        if (judge) {
            labelList[index].label = newName;
            this.setState({ labelList: Object.assign([], labelList) });
            return;
        }

        labelList[index].modify = false;
        if (!newName) {
            labelList[index].label = this.oldLabel;
            this.setState({ labelList: Object.assign([], labelList) });
            return;
        }

        if (newName == this.oldLabel) {
            this.setState({ labelList: Object.assign([], labelList) });
            return;
        }

        this.setState({ labelList: Object.assign([], labelList) });
        //this.addLabel(newName);
        this.addLabel(index, newName);
    }

    addLabel(index, newLabel) {
        if (this.initMl5Classifiy()) return;

        let { labelList } = this.state;
        let count = 0;
        for (let i in labelList) {
            if (i != index && labelList[i].label == newLabel) {
                count++;
                this.runtime.dfrobotAlert(this.props.intl.formatMessage(messagesAlert.prompt), this.props.intl.formatMessage(tipsInfo.renameLabel).replace('[LABEL]', newLabel), {
                    mode: 1, cover: true, zIndex: 6666, noCloseBtn: true,
                    btns: [
                        {
                            callBack: () => {
                                this.addTrain(index, newLabel, labelList[i]);
                            }, text: this.props.intl.formatMessage(messagesAlert.yes)
                        },
                        {
                            text: this.props.intl.formatMessage(messagesAlert.cancle),
                            callBack: () => {
                                if (this.oldLabel) labelList[index].label = this.oldLabel;
                                this.setState({ labelList: Object.assign([], labelList) });
                            }
                        }
                    ]
                });
                this.whenModifyImage();
            }
            //if(count && count == labelList.length){
            //this.addLabelAndChoose(newLabel);
            //}
        }

        if (count == 0 && newLabel != this.oldLabel) {
            this.setState({ labelList: Object.assign([], labelList) });
        }
        //this.addLabelAndChoose(newLabel);
    }

    getRepeatData(index, addlabeltext) {
        let choose_item = null;
        let { labelList } = this.state;
        for (let i in labelList) {
            if (i == index && labelList[i].label == addlabeltext) {
                choose_item = labelList[i];
                break;
            }
        }

        if (!choose_item) {
            choose_item = {
                label: addlabeltext,
                img: []
            }
            labelList.push(choose_item);
        }
        return choose_item;
    }

    addLabelAndChoose(newLabel) {
        let { labelList } = this.state;

        newLabel = {
            label: newLabel,
            index: labelList.length,
            img: [],
            updated: false
        }
        labelList.push(newLabel);
        this.setState({ labelList: labelList });
        this.setChooseItem(labelList.length - 1);
    }

    deleteLabel(index) {
        return;
    }

    addTrain(index, newName, img_arr) {
        if (this.initMl5Classifiy()) return;
        let { labelList } = this.state;
        let choose_item = labelList[index];
        for (let i in choose_item.imgInstance) {
            choose_item.imgInstance[i] && img_arr.imgInstance.push(choose_item.imgInstance[i]);
        }
        labelList.splice(index, 1);

        this.runtime.ml5TrainComponent.setImageListFromVisual && this.runtime.ml5TrainComponent.setImageListFromVisual(labelList, () => {
            this.setState({ labelList: Object.assign([], labelList) });
        });

    }

    predictImage() {
        this.runtime.ml5TrainComponent.predictImageClass('on');
        this.runtime.ml5TrainComponent.predictImageClass('off');
        setTimeout(() => {
            let imgResult = this.runtime.ml5TrainComponent.getImageResults() || {};
            this.setState({ imgResult: imgResult });
        }, 500);
    }

    //
    restartTrain() {
        if (this.initMl5Classifiy()) return;
        this.runtime.dfrobotAlert(this.props.intl.formatMessage(messagesAlert.prompt), this.props.intl.formatMessage(messagesAlert.restartMl5Train),
            {
                mode: 1, cover: true, zIndex: 5555, btns: [
                    {
                        callBack: () => {
                            this.runtime.ml5TrainComponent.clearImageClassificationModel();
                            this.setState({
                                chooseItem: 0,
                                labelList: [{
                                    label: this.props.intl.formatMessage(messagesAlert.ml5DefaultLabel),
                                    img: []
                                }]
                            });
                        }, text: this.props.intl.formatMessage(messagesAlert.yes)
                    },
                    {
                        text: this.props.intl.formatMessage(messagesAlert.cancle)
                    }
                ]
            });
    }

    exportModel() {
        if (this.initMl5Classifiy()) return;
        this.runtime.ml5TrainComponent.exportImageClassificationModel();
    }

    onscroll(e, labelType) {
        //console.log(e.target);
        let scrollNode = e.target.parentElement && e.target.parentElement.parentElement;
        if (!scrollNode) return;
        if (scrollNode.scrollWidth <= scrollNode.offsetWidth) return;

        let { labelList } = this.state;
        e.preventDefault();
        e.stopPropagation();

        //图片只加载前二十张，避免全部加载引起页面渲染崩溃，鼠标滚动时模拟瀑布流效果加载剩余图片
        if (e.deltaY > 0) {
            if (scrollNode.scrollWidth - scrollNode.scrollLeft <= 570 + scrollNode.offsetWidth) {
                labelType.showNum = (labelType.showNum ? labelType.showNum : 0) + 3;
                this.setState({ labelList: Object.assign([], labelList) });
            }
            scrollNode.scrollLeft += 570;
        } else {
            scrollNode.scrollLeft -= 570;
            if (scrollNode.scrollLeft <= 570 + scrollNode.offsetWidth) {
                labelType.showNum = 10;
                this.setState({ labelList: Object.assign([], labelList) });
            }
        }
    }

    dragWindow(e) {
        //e.preventDefault();
        e.stopPropagation();

        var window_target = e.target;
        //窗口置顶
        let lastNode = document.getElementsByClassName("higher-level");
        if (lastNode && lastNode.length) {
            lastNode = lastNode[0];
            lastNode.className = lastNode.className.replace(/higher-level| higher-level/g, "");
        }
        if (document.getElementById("ml5TrainPop"))document.getElementById("ml5TrainPop").className += " higher-level"
        // 窗口移动
        if (window_target.id != 'ml5TrainPop') {
            return;
        }
        var window_targetW = Math.abs(e.pageX - e.target.offsetLeft);
        var window_targetH = Math.abs(e.pageY - e.target.offsetTop);
        window_target.style.transform = 'translate(0px)';

        window.onmousemove = function (event) {
            window_target.style.top = (event.pageY - window_targetH) + 'px';
            window_target.style.left = (event.pageX - window_targetW) + 'px';
        }

        window.onmouseup = function (event) {
            window.onmousemove = null;
            window.onmouseup = null;
        }

    }

    render() {
        const {
            vm
        } = this.props;

        const {
            open, imgResult, labelList, chooseItem, showPopup, collapse, visualType
        } = this.state;

        return (
            <Ml5TraninModalComponent
                labelList={labelList}
                vm={vm}
                open={open}
                setCollapse={this.setCollapse}
                collapse={collapse}
                imgResult={imgResult}
                showPopup={showPopup}
                chooseItem={chooseItem}
                close={this.close}
                dragWindow={this.dragWindow}
                deleteImage={this.deleteImage}
                addLabel={this.addLabel}
                deleteLabel={this.deleteLabel}
                addTrain={this.addTrain}
                restartTrain={this.restartTrain}
                exportModel={this.exportModel}
                setChooseItem={this.setChooseItem}
                predictImage={this.predictImage}
                setImgHide={this.setImgHide}
                updateLabel={this.updateLabel}
                renameLabel={this.renameLabel}
                focusInput={this.focusInput}
                onscroll={this.onscroll}
                visualType={this.visualType}
            />
        );
    }
}

export default injectIntl(Ml5TraninModal);
