import React from "react";
import { FormattedMessage } from "react-intl";

// pick-up-apple
import apple2 from "./apple/pick-up-apple-2.gif";
import apple3 from "./apple/pick-up-apple-3.gif";
import apple4 from "./apple/pick-up-apple-4.gif";
import apple5 from "./apple/pick-up-apple-5.gif";
import apple6 from "./apple/pick-up-apple-6.gif";
// move-let-right
import move1 from "./move-left-right/move-1.gif";
import move2 from "./move-left-right/move-2.gif";
import move3 from "./move-left-right/move-3.gif";
import move4 from "./move-left-right/move-4.gif";
import installDriverThumb from "./videos/installDriver.png";
import calibrationThumb from "./videos/calibration.png";
import calliopepng from "./videos/calliope.png";
//save-witch
import witch2 from "./save-witch/witch2.gif";
import witch3 from "./save-witch/witch3.gif";
import witch4 from "./save-witch/witch4.gif";
import witch5 from "./save-witch/witch5.gif";
import witch6 from "./save-witch/witch6.gif";

import installDriverMp4 from "./videos/install-driver.mp4";
import microbitCalibrationMp4 from "./videos/microbit-calibration.mp4";
import calliopeCalibrationMp4 from "./videos/calliope-calibration.mp4";

const data = {
  "install-driver": {
    name: (
      <FormattedMessage
        defaultMessage="Install Driver"
        description="Name for the 'Glide around' how-to"
        id="gui.howtos.install-driver.name"
      />
    ),
    img: installDriverThumb,
    steps: [
      {
        video: installDriverMp4,
      },
      {
        deckIds: ["pick-up-apple", "move-left-right", "save-witch"],
      },
    ],
  },
  "move-left-right": {
    name: (
      <FormattedMessage
        defaultMessage="move left right"
        description="Name for the 'Run away' how-to"
        id="gui.howtos.move-left-right.name"
      />
    ),
    img: move4,
    steps: [
      {
        title: (
          <FormattedMessage
            defaultMessage="New project"
            description="Step name for 'Drag out a “go to random position” block' step"
            id="gui.howtos.move-left-right.step_1"
          />
        ),
        image: move1,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Drag block 1"
            description="Step name for 'Click the block to run it' step"
            id="gui.howtos.move-left-right.step_2"
          />
        ),
        image: move2,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Drag block 2"
            description="Step 3 title"
            id="gui.howtos.move-left-right.step_3"
          />
        ),
        image: move3,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="move left and right"
            description="Step name for 'Add a “when this sprite clicked” block' step"
            id="gui.howtos.move-left-right.step_4"
          />
        ),
        image: move4,
      },
      {
        deckIds: ["install-driver", "save-witch", "pick-up-apple"],
      },
    ],
  },
  "pick-up-apple": {
    name: (
      <FormattedMessage
        defaultMessage="pick-up apple"
        description="Name for the 'Run away' how-to"
        id="gui.howtos.pick-up-apple.name"
      />
    ),
    img: apple6,
    steps: [
      {
        title: (
          <FormattedMessage
            defaultMessage="Switch product"
            description="Step name for 'Click the block to run it' step"
            id="gui.howtos.pick-up-apple.step_2"
          />
        ),
        image: apple2,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Connect the device"
            description="Step 3 title"
            id="gui.howtos.pick-up-apple.step_3"
          />
        ),
        image: apple3,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Calibration compass"
            description="Step name for 'Add a “when this sprite clicked” block' step"
            id="gui.howtos.pick-up-apple.step_4"
          />
        ),
        image: apple4,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Open the routine"
            description="Step name for 'Click the sprite to run it' step"
            id="gui.howtos.pick-up-apple.step_5"
          />
        ),
        image: apple5,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="pick up apple"
            description="Step name for 'Add a “start sound” block' step"
            id="gui.howtos.pick-up-apple.step_6"
          />
        ),
        image: apple6,
      },
      {
        deckIds: ["save-witch", "move-left-right", "install-driver"],
      },
    ],
  },
  "save-witch": {
    name: (
      <FormattedMessage
        defaultMessage="Save Witch"
        description="Name for the 'Say hello' how-to"
        id="gui.howtos.save-witch.name"
      />
    ),
    img: witch6,
    steps: [
      {
        title: (
          <FormattedMessage
            defaultMessage="Select 'micro:bit' main control board for expansion"
            description="Step name for 'Click the “Looks” category' step"
            id="gui.howtos.save-witch.step_2"
          />
        ),
        image: witch2,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Select 'Save the Witch' in Example Program"
            description="Step name for 'Drag out a “say” block' step"
            id="gui.howtos.save-witch.step_3"
          />
        ),
        image: witch3,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Connect the device, select the microbit, wait for the connection to be successful"
            description="Step name for 'Click the block to run it' step"
            id="gui.howtos.save-witch.step_4"
          />
        ),
        image: witch4,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="If you are prompted to 'calibrate the device', rotate the main control board one turn until the LED turns off."
            description="Step name for 'Drag out another “say” block' step"
            id="gui.howtos.save-witch.step_5"
          />
        ),
        image: witch5,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Click on the green flag and swing your arm to interact with the animation"
            description="Step name for 'Say something else' step"
            id="gui.howtos.save-witch.step_6"
          />
        ),
        image: witch6,
      },
      {
        deckIds: ["pick-up-apple", "move-left-right", "install-driver"],
      },
    ],
  },
  "microbit-calibration": {
    name: (
      <FormattedMessage
        defaultMessage="micro:bit Calibration"
        description="Name for the 'microbit-calibration' how-to"
        id="gui.howtos.microbit-calibration.name"
      />
    ),
    img: calibrationThumb,
    steps: [
      {
        video: microbitCalibrationMp4,
      },
      {
        deckIds: ["save-witch", "pick-up-apple", "move-left-right"],
      },
    ],
  },
  "calliope-calibration": {
    name: (
      <FormattedMessage
        defaultMessage="micro:bit Calibration"
        description="Name for the 'microbit-calibration' how-to"
        id="gui.howtos.calliope-calibration.name"
      />
    ),
    img: calliopepng,
    steps: [
      {
        video: calliopeCalibrationMp4,
      },
      {
        deckIds: ["save-witch", "pick-up-apple", "move-left-right"],
      },
    ],
  },
};

export default data;
