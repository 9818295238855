import { useEffect, useMemo, useState } from "react"

export const useStatusFilterTags = (type: "extension" | "device" | "stage", dataSource: any) => {
    const [selectedStatusTags, setSelectedStatusTags] = useState<string[]>(['isOffice'])
    const handleSelectedStatusTagChange = (val) => {
        setSelectedStatusTags(val)
    }

    // 状态过滤后的数据
    const statusFiltedData = useMemo(() => {
        if (selectedStatusTags.length === 0) return dataSource
        return dataSource.filter((item) => item.find(data => selectedStatusTags.some(tag => data[tag] === true)))
    }, [dataSource, selectedStatusTags])

    
    const statusFilterTags = useMemo(() => {
        const tags = [
            { label: "官方库", value: "isOffice" },
            { label: "已收藏", value: "isFavorite" },
            { label: "已加载", value: "isLoaded" },
        ]
        if (type === 'device' || type==='extension') {
            return tags
        }
        if (type === 'stage') {
            tags.push({ label: "支持当前主控", value: "isSupportCurrentDevice" })
            return tags
        }
        return []
    }, [type])

    // 切换后重置状态筛选
    useEffect(() => {
        setSelectedStatusTags(['isOffice'])
    }, [type])

    return { statusFilterTags, selectedStatusTags, handleSelectedStatusTagChange, statusFiltedData }

}
