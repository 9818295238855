import { MINDPLUS_MODE } from 'config/config';
import { vm } from 'lib/scratch-vm';
import path from 'path';
import {getBasePathConfig, getLinkAppRootPath, getHomeDirPath} from './base'

// 通过调用方法获取路径, 能更好的兼容在线版

// 获取软件数据目录路径
export function getAppdataPath() {
    const APP_DATA_WIN = path.join(getHomeDirPath(), 'AppData/Local', 'mind+');
    const APP_DATA_MAC = path.join(getHomeDirPath(), 'Library', 'mind+');
    // todo: 平台判断
    return APP_DATA_WIN;
}

// 获取本地扩展库路径
export function getLocalExtensionPath() {
    //TODO
    //路径中增加模式
    return path.join(getAppdataPath(), 'download', MINDPLUS_MODE, 'extensions');
}

// 获取本地扩展库配置json
export function getLocalExtJsonPath() {
    return path.join(getLocalExtensionPath(), 'ext.json');
}

// 获取内置扩展库路径
export function getBuiltinExtensionPath() {
    //TODO
    //路径中增加模式
    return path.join(getLinkAppRootPath(), MINDPLUS_MODE, 'extensions');
}

// 获取内置扩展库json
export function getBuiltinExtJsonPath() {
    return path.join(getBuiltinExtensionPath(),  'ext.json');
}


// 获取本地设备库路径
export function getLocalDevicePath() {
    //TODO
    //路径中增加模式
    return path.join(getAppdataPath(), 'download', MINDPLUS_MODE, 'devices');
}

// 获取本地扩展库配置json
export function getLocalDevJsonPath() {
    return path.join(getLocalDevicePath(), 'dev.json');
}

// 获取内置设备库路径
export function getBuiltinDevicePath() {
    //TODO
    //路径中增加模式
    return path.join(getLinkAppRootPath(), MINDPLUS_MODE, 'devices');
}

// 获取内置设备库json
export function getBuiltinDevJsonPath() {
    return path.join(getBuiltinDevicePath(), 'dev.json');
}

// 获取arduino-cli.exe的路径
export function getArduinoCliPath() {
    return path.join(getLinkAppRootPath(), 'tool/arduino-cli.exe')
}

// 获取arduino-cli.yaml的路径
export function getArduinoCliConfigPath() {
    return path.join(getAppdataPath(), 'Arduino/arduino-cli.yaml')
}

// 获取Arduino路径
export function getArduinoPath() {
    return path.join(getAppdataPath(), 'Arduino')
}

// 获取当前主板的临时目录路径
export function getCurrentDeviceTempPath() {
    return vm.runtime.deviceManager.currentDevice?.getTempDirPath();
}

// 获取当前主板的资源路径
export function getCurrentDevicePath() {
    if (!vm.runtime.deviceManager.currentDevice) return;
    let config = vm.runtime.deviceManager.currentDevice.config;
    if (config.isBuiltin) { // 安装目录下的extension
        return path.join(getBuiltinDevicePath(), config.deviceIdWithVersion);
    } else {
        return path.join(getLocalDevicePath(), config.deviceIdWithVersion);
    }
}