import React, { useMemo, useState } from 'react';
import styles from './DFPagination.module.scss';
import message from 'component/df-message/Message';

interface PaginationProps {
    currentPage: number;
    totalCount: number; // 总记录数
    pageSize?: number; // 每页大小
    onPageChange: (page: number) => void;
    showTotal?: (total: number) => React.ReactNode;
}

const DFPagination: React.FC<PaginationProps> = ({
    currentPage,
    totalCount,
    pageSize = 10, // 默认每页大小为10
    onPageChange,
    showTotal = (total) => `总数： ${total}`,
}) => {
    const totalPages = Math.ceil(totalCount / pageSize); // 计算总页数
    const [inputPage, setInputPage] = useState<string>(currentPage.toString()); // 输入框状态
    const [ellipsisStart, setEllipsisStart] = useState<number | null>(null); // 记录省略号位置

    const handlePageChange = (page: number) => {
        if (page < 1 || page > totalPages) return; // 防止无效页码
        onPageChange(page);
        setInputPage(page.toString()); // 更新输入框
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputPage(e.target.value);
    };

    const handleInputSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const pageNumber = Number(inputPage);
            if (pageNumber >= 1 && pageNumber <= totalPages) {
                handlePageChange(pageNumber);
            } else {
                message.error(`请输入有效的页码（1-${totalPages}）`);
            }
        }
    };

    const pageNumbers = useMemo(() => {
        const pages: (number | '...')[] = [];
        const showEllipses = totalPages > 5; // 如果总页数大于5，显示省略号

        if (!showEllipses) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages.push(1);
            let startPage = Math.max(2, currentPage - 2);
            let endPage = Math.min(totalPages - 1, currentPage + 2);
            if (currentPage <= 3) {
                startPage = 2;
                endPage = 5;
            } else if (currentPage >= totalPages - 2) {
                startPage = totalPages - 4;
                endPage = totalPages - 1;
            }
            if ((currentPage > 5) && (startPage > 2)) pages.push('...');

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            if ((currentPage < totalPages - 3) && (endPage < totalPages - 1)) pages.push('...');

            if (totalPages > 1) pages.push(totalPages);
        }

        return pages;
    }, [currentPage, totalPages]);

    const renderPageNumbers = () => {
        return pageNumbers.map((number, index) => {
            if (number === '...') {
                return (
                    <button
                        key={index}
                        onClick={() => {
                        }}
                    >
                        {number}
                    </button>
                );
            } else {
                return (
                    <button
                        key={index}
                        onClick={() => handlePageChange(number as number)}
                        className={currentPage === number ? styles.active : ''}
                    >
                        {number}
                    </button>
                );
            }
        });
    };

    return (
        <div className={styles.pagination}>
            {showTotal && <div className={styles.total}>{showTotal(totalCount)}</div>}
            <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={styles.operator}
            >
                &lt;
            </button>
            {renderPageNumbers()}
            <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={styles.operator}
            >
                &gt;
            </button>
            <div className={styles.quickJumper}>
                <span>跳至</span>
                <input
                    type="number"
                    onKeyDown={handleInputSubmit}
                    value={inputPage}
                    onChange={handleInputChange}
                    min={1}
                    max={totalPages}
                    className={styles.pageInput}
                    placeholder="输入页码"
                />
                <span>页</span>
            </div>
        </div>
    );
};

export default DFPagination;
