import { useEffect, useState } from "react";
import { DeviceDataType } from "../../../../service/ext-asset-manager/device/type";
import { getCoverPathFromConfig, getImageBase64URL } from "../../../../service/ext-asset-manager/extAssetManager";
import { ExtensionDataType } from "../../../../service/ext-asset-manager/extension/type";

export const useShowingImage = (props: DeviceDataType | ExtensionDataType | undefined) => {
    const [imgSrc, setImgSrc] = useState(() => {
        if (props === undefined) {
            return ''
        }
        if ((props as any).iconURL) {
            return (props as any).iconURL;
        }
        if (!props.isDownloaded) {
            return getCoverPathFromConfig(props as any);
        }
    });
    useEffect(() => {
        if (props) {
            // scratch 内置扩展的图片
            if (!(props.isDevice) && props.isBuiltinScratch) {
                setImgSrc((props as any).iconURL)
                return
            }
            const filePath = getCoverPathFromConfig(props as any);
            // 扩展已下载, fs读取图片
            if (props.isDownloaded) {
                getImageBase64URL(filePath)
                    .then((url) => {
                        setImgSrc(url)
                    }).catch(() => {
                      setImgSrc("")
                    })
            } else {
                setImgSrc(filePath)
            }
        }

    }, [props]);

    return {
        imageSrc: imgSrc
    }
}
