class FavoritesUtil {
    storageKey: string;
    constructor(storageKey: string = 'dfFavorites') {
        this.storageKey = storageKey;
    }
    getFavorites(): string[] {
        return JSON.parse(localStorage.getItem(this.storageKey) || '[]');
    }
    // 添加收藏项
    addFavorite(id: string) {
        const favorites = this.getFavorites();
        if (!favorites.includes(id)) {
            favorites.push(id);
            localStorage.setItem(this.storageKey, JSON.stringify(favorites));
        }
    }
    // 移除收藏项
    removeFavorite(id: string): void {
        let favorites = this.getFavorites();
        favorites = favorites.filter(favorite => favorite !== id);
        localStorage.setItem(this.storageKey, JSON.stringify(favorites));
    }

    // 检查是否已收藏
    isFavorite(id: string): boolean {
        const favorites = this.getFavorites();
        return favorites.includes(id);
    }

    // 清空所有收藏项
    clearFavorites(): void {
        localStorage.removeItem(this.storageKey);
    }
}
const favoritesUtil = new FavoritesUtil();

export default favoritesUtil;
