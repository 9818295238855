import ScratchCostumeTab from './costume-tab'
import {useDispatch, useSelector} from "react-redux";
import {openSoundTab} from "../../../redux/tab/tabSlice";
import {openBackdropLib, openCostumeLib} from "../../../redux/asset-lib/assetLibStatusSlice";
import {setRestore} from "../../../redux/restore-deletion/restoreDeletionSlice";
import {closeAlertWithId, showStandardAlert} from "../../../redux/alerts/alertsSlice";
import {selectTargets} from "../../../redux/tagets/targetsSlice";
import {selectIntl} from "../../../redux/intl/intlSlice";
import {vm} from "../../../lib/scratch-vm";
import {selectAssetDrag} from "../../../redux/asset-drag/assetDragSlice";
import {useIntl} from "react-intl";
import {useSelectedIndex} from "./util/useSelectedIndex";
import {closeCameraCapture, openCameraCapture, selectModals} from "../../../redux/modals/modalsSlice";

export const CostumeTab = (props: any) => {
    const {selectedIndex, onSelectedIndex} = useSelectedIndex()
    const dispatch = useDispatch()
    const {editingTarget} = useSelector(selectTargets)
    const {dragging} = useSelector(selectAssetDrag)
    const {isRtl} = useSelector(selectIntl)
    const {cameraCapture} = useSelector(selectModals)
    const intl = useIntl()
    const sprites = (vm.runtime.targets || [])
        .filter(target => !target.isStage)
        .filter(
            // Don't report clones.
            target => !target.hasOwnProperty('isOriginal') || target.isOriginal
        ).map(
            target => target.toJSON()
        )
        .reduce(
            (targets, target, listId) => Object.assign(
                targets,
                {[target.id]: {order: listId, ...target}}
            ),
            {}
        )
    const stage = vm.runtime.getTargetForStage();
    const onActivateSoundsTab = () => dispatch(openSoundTab())
    const onNewLibraryBackdropClick = (e) => {
        e.preventDefault();
        dispatch(openBackdropLib());
    }
    const onNewLibraryCostumeClick = e => {
        e.preventDefault();
        dispatch(openCostumeLib());
    }
    const onNewCostumeFromCameraClick = () => {
        dispatch(openCameraCapture());
    }
    const onRequestCloseCameraModal = () => {
        dispatch(closeCameraCapture())
    }

    const dispatchUpdateRestore = (restoreState: any) => {
        dispatch(setRestore(restoreState));
    }
    const onShowImporting = () => dispatch(showStandardAlert('importingAsset'))
    const onCloseImporting = () => dispatch(closeAlertWithId('importingAsset'))


    return <ScratchCostumeTab
        selectedIndex={selectedIndex}
        onSelectedIndex={onSelectedIndex}
        vm={vm}
        intl={intl}
        editingTarget={editingTarget}
        isRtl={isRtl}
        sprites={sprites}
        stage={stage}
        dragging={dragging}
        cameraModalVisible={cameraCapture}
        onActivateSoundsTab={onActivateSoundsTab}
        onNewLibraryBackdropClick={onNewLibraryBackdropClick}
        onNewLibraryCostumeClick={onNewLibraryCostumeClick}
        onNewCostumeFromCameraClick={onNewCostumeFromCameraClick}
        onRequestCloseCameraModal={onRequestCloseCameraModal}
        dispatchUpdateRestore={dispatchUpdateRestore}
        onCloseImporting={onCloseImporting}
        onShowImporting={onShowImporting}
    />
}
