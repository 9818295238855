import { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

export const useSearchExtension = (extensions: any, libType: string) => {
    const [searchText, setSearchText] = useState<string>('')
    const intl = useIntl()
    const handleSearchTextChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value)
    }
    const searchedExtensions = useMemo(() => {
        if (searchText === '') return extensions
        const upperCaseSearchText = searchText.toUpperCase();
        return extensions.filter((item) => {
            return item.find((i) => {
                // 内置扩展
                if (!i.isDevice && i.isBuiltinScratch) {
                    let name = ''
                    let description = ''
                    if (typeof i.name === 'string') {
                        name = i.name
                    }
                    if (typeof i.name === 'object') {
                        name = intl.formatMessage(i.name.props)
                    }
                    if (typeof i.description === 'string') {
                        description = i.description
                    }
                    if (typeof i.description === 'object') {
                        description = intl.formatMessage(i.description.props)
                    }
                    return name.toUpperCase().includes(upperCaseSearchText) || description.toUpperCase().includes(upperCaseSearchText);
                }
                return (i.name["zh-cn"] && i.name["zh-cn"].toUpperCase().includes(upperCaseSearchText))
                    || (i.name["en"] && i.name["en"].toUpperCase().includes(upperCaseSearchText))
                    || (i.description["zh-cn"] && i.description["zh-cn"].toUpperCase().includes(upperCaseSearchText))
                    || (i.description["en"] && i.description["en"].toUpperCase().includes(upperCaseSearchText))
                    || (i.sku && i.sku.toUpperCase().includes(upperCaseSearchText))
            });
        })
    }, [extensions, intl, searchText])
    useEffect(() => {
        if (libType) {
            setSearchText('')
        }
    }, [libType])
    return { searchText, handleSearchTextChange, searchedExtensions }
}
