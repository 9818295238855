import {DeviceDataType} from "./type";
import {getBuiltinDevicePath, getLocalDevicePath} from "../../path/assetPath";
import path from "path";

// 获取extension的唯一ID
export const getDeviceID = (extensionData: { author: string, id: string }) => {
    return `dev-${extensionData.author}-${extensionData.id}`;
}

export const getDeviceIDWithVersion = (extensionData: { author: string, id: string, version: string }) => {
    return `dev-${extensionData.author}-${extensionData.id}@${extensionData.version}`;
}

// 获取翻译之后的name和description
export const getNameOrDescription = (data: { [locale: string]: string } | string, locale: string) => {
    if (typeof data === 'string') return data;
    return data[locale] || '';
}

//
export const getOnlineFirmwarePath = (config: DeviceDataType) => {
    if (!config.firmware) return "";
    if (config.isBuiltin) { // 安装目录下的extension
        // return path.join(getBuiltinDevicePath(), config.deviceId, config.firmware['online']);
        //路径修改为deviceIdWithVersion
        return path.join(getBuiltinDevicePath(), config.deviceIdWithVersion, config.firmware['online']);
    } else {
        // return path.join(getLocalDevicePath(), config.deviceId, config.firmware['online']);
        //路径修改为deviceIdWithVersion
        return path.join(getLocalDevicePath(), config.deviceIdWithVersion, config.firmware['online']);
    }
}

export const getFirmwarePath = (config: DeviceDataType, firmware) => {
    if (!config.firmware || !config.firmware[firmware]) return "";
    if (config.isBuiltin) { // 安装目录下的extension
        // return path.join(getBuiltinDevicePath(), config.deviceId, config.firmware['online']);
        //路径修改为deviceIdWithVersion
        return path.join(getBuiltinDevicePath(), config.deviceIdWithVersion, config.firmware[firmware]);
    } else {
        // return path.join(getLocalDevicePath(), config.deviceId, config.firmware['online']);
        //路径修改为deviceIdWithVersion
        return path.join(getLocalDevicePath(), config.deviceIdWithVersion, config.firmware[firmware]);
    }
}

export const compareVersions = (version1: string, version2: string): number => {
    const v1 = version1.split('.').map(Number);
    const v2 = version2.split('.').map(Number);

    for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
        const num1 = v1[i] || 0;
        const num2 = v2[i] || 0;

        if (num1 < num2) {
            return -1;
        } else if (num1 > num2) {
            return 1;
        }
    }

    return 0;
}

export const satisfiesRule = (version: string, rule: string) => {
    if (rule.startsWith('^')) {
        const targetVersion = rule.substring(1);
        const v = version.split('.').map(Number);
        const target = targetVersion.split('.').map(Number);
        if (v[0] === target[0]) {
            return compareVersions(version, targetVersion) >= 0;
        } else {
            return false;
        }
    } else if (rule.startsWith('~')) {
        const targetVersion = rule.substring(1);
        const v = version.split('.').map(Number);
        const target = targetVersion.split('.').map(Number);
        // 检查主版本号是否相同，次版本号是否相同，且补丁版本号是否在范围内
        if (v[0] === target[0] && v[1] === target[1]) {
            return v[2] >= target[2] && v[2] < target[2] + 1;
        } else {
            return false;
        }
    } else if (rule.startsWith('*')) {
        return true;
    }
}

