import {MutableRefObject, useEffect} from "react";
import {vm} from "lib/scratch-vm";
import ScratchBlocks from "lib/scratch-blocks";

/**
* @Description: 1. workspace挂载vm.blockListener 2. flyoutWorkspace挂载vm.flyoutBlockListener/vm.monitorBlockListener 3.监听vm部分事件
* @author LiuSheng
* @date 2023/5/4
*/
export const useBlockListener = (workspaceRef:MutableRefObject<any>) => {
    useEffect(() => {
        const workspace = workspaceRef.current;
        // 监听workspace中的变化, 改变vm中的数据缓存
        workspace.addChangeListener(vm.blockListener);
        const flyoutWorkspace = workspace
            .getFlyout()
            .getWorkspace();
        flyoutWorkspace.addChangeListener(vm.flyoutBlockListener);
        flyoutWorkspace.addChangeListener(vm.monitorBlockListener);
        return () => {
            workspace.removeChangeListener(vm.blockListener);
            flyoutWorkspace.removeChangeListener(vm.flyoutBlockListener);
            flyoutWorkspace.removeChangeListener(vm.monitorBlockListener);
        };
    }, []);

    useEffect(() => {
        const workspace = workspaceRef.current;
        const onScriptGlowOn = (data) => {
            workspace.glowStack(data.id, true);
        }
        const onScriptGlowOff = (data) => {
            workspace.glowStack(data.id, false);
        }
        const onBlockGlowOn = (data) => {
            workspace.glowBlock(data.id, true);
        }
        const onBlockGlowOff = (data) => {
            workspace.glowBlock(data.id, false);
        }
        const onVisualReport = (data) => {
            workspace.reportValue(data.id, data.value);
        }
        const handleMonitorsUpdate = (monitors) => {
            const flyout = workspaceRef.current.getFlyout();
            for (const monitor of monitors.values()) {
                const blockId = monitor.get('id');
                const isVisible = monitor.get('visible');
                // toolbox中block的勾选框状态
                flyout.setCheckboxState(blockId, isVisible);
                // We also need to update the isMonitored flag for this block on the VM, since it's used to determine
                // whether the checkbox is activated or not when the checkbox is re-displayed (e.g. local variables/blocks
                // when switching between sprites).
                const block = vm.runtime.monitorBlocks.getBlock(blockId);
                if (block) {
                    block.isMonitored = isVisible;
                }
            }
        }
        const handleStatusButtonUpdate = () => {
            ScratchBlocks.refreshStatusButtons(workspaceRef.current);
        }
        vm.addListener('SCRIPT_GLOW_ON', onScriptGlowOn);
        vm.addListener('SCRIPT_GLOW_OFF', onScriptGlowOff);
        vm.addListener('BLOCK_GLOW_ON', onBlockGlowOn);
        vm.addListener('BLOCK_GLOW_OFF', onBlockGlowOff);
        vm.addListener('VISUAL_REPORT', onVisualReport);
        vm.addListener('MONITORS_UPDATE', handleMonitorsUpdate);
        vm.addListener('PERIPHERAL_CONNECTED', handleStatusButtonUpdate);
        vm.addListener('PERIPHERAL_DISCONNECTED', handleStatusButtonUpdate);
        return () => {
            vm.removeListener('SCRIPT_GLOW_ON', onScriptGlowOn);
            vm.removeListener('SCRIPT_GLOW_OFF', onScriptGlowOff);
            vm.removeListener('BLOCK_GLOW_ON', onBlockGlowOn);
            vm.removeListener('BLOCK_GLOW_OFF', onBlockGlowOff);
            vm.removeListener('VISUAL_REPORT', onVisualReport);
            vm.removeListener('MONITORS_UPDATE', handleMonitorsUpdate);
            vm.removeListener('PERIPHERAL_CONNECTED', handleStatusButtonUpdate);
            vm.removeListener('PERIPHERAL_DISCONNECTED', handleStatusButtonUpdate);
        }
    }, [])



}
