import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

type Position = {
    x: number,
    y: number
}

interface MonitorLayoutState {
    monitors: {[monitorId: string]: {upperStart: Position, lowerEnd: Position}},
    savedMonitorPositions: {[monitorId: string]: Position}
}

// Define the initial state using that type
const initialState: MonitorLayoutState = {
    monitors: {},
    savedMonitorPositions: {}
}

export const monitorLayoutSlice = createSlice({
    name: 'monitorLayout',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        addMonitorRect: (state, action: PayloadAction<{monitorId, upperStart, lowerEnd, savePosition}>) => {
            if (state.monitors.hasOwnProperty(action.payload.monitorId)) {
                console.error(`Can't add monitor, monitor with id ${action.payload.monitorId} already exists.`);
                return state;
            }
            if (!_verifyRect(action.payload.upperStart, action.payload.lowerEnd)) {
                console.error(`Monitor rectangle not formatted correctly`);
                return state;
            }
            state.monitors[action.payload.monitorId] = {
                upperStart: action.payload.upperStart,
                lowerEnd: action.payload.lowerEnd
            }
            if (action.payload.savePosition) {
                state.savedMonitorPositions[action.payload.monitorId] = {
                    x: action.payload.upperStart.x,
                    y: action.payload.upperStart.y
                }
            }
        },
        moveMonitorRect: (state, action: PayloadAction<{monitorId, newX, newY}>) => {
            if (!state.monitors.hasOwnProperty(action.payload.monitorId)) {
                console.error(`Can't move monitor, monitor with id ${action.payload.monitorId} does not exist.`);
                return state;
            }
            if (isNaN(action.payload.newX) || isNaN(action.payload.newY)) {
                console.error(`Monitor rectangle not formatted correctly`);
                return state;
            }

            const oldMonitor = state.monitors[action.payload.monitorId];
            if (oldMonitor.upperStart.x === action.payload.newX &&
                oldMonitor.upperStart.y === action.payload.newY) {
                // Hasn't moved
                return state;
            }
            const monitorWidth = oldMonitor.lowerEnd.x - oldMonitor.upperStart.x;
            const monitorHeight = oldMonitor.lowerEnd.y - oldMonitor.upperStart.y;
            return {
                monitors: Object.assign({}, state.monitors, {
                    [action.payload.monitorId]: {
                        upperStart: {x: action.payload.newX, y: action.payload.newY},
                        lowerEnd: {x: action.payload.newX + monitorWidth, y: action.payload.newY + monitorHeight}
                    }
                }),
                // User generated position is saved
                savedMonitorPositions: Object.assign({}, state.savedMonitorPositions, {
                    [action.payload.monitorId]: {x: action.payload.newX, y: action.payload.newY}
                })
            };
        },
        resizeMonitorRect: (state, action: PayloadAction<{monitorId, newWidth, newHeight}>) => {
            if (!state.monitors.hasOwnProperty(action.payload.monitorId)) {
                console.error(`Can't resize monitor, monitor with id ${action.payload.monitorId} does not exist.`);
                return state;
            }
            if (isNaN(action.payload.newWidth) || isNaN(action.payload.newHeight) ||
                action.payload.newWidth <= 0 || action.payload.newHeight <= 0) {
                console.error(`Monitor rectangle not formatted correctly`);
                return state;
            }

            const oldMonitor = state.monitors[action.payload.monitorId];
            const newMonitor = {
                upperStart: oldMonitor.upperStart,
                lowerEnd: {
                    x: oldMonitor.upperStart.x + action.payload.newWidth,
                    y: oldMonitor.upperStart.y + action.payload.newHeight
                }
            };
            if (newMonitor.lowerEnd.x === oldMonitor.lowerEnd.x &&
                newMonitor.lowerEnd.y === oldMonitor.lowerEnd.y) {
                // no change
                return state;
            }

            return {
                monitors: Object.assign({}, state.monitors, {[action.payload.monitorId]: newMonitor}),
                savedMonitorPositions: state.savedMonitorPositions
            };
        },
        removeMonitorRect: (state, action: PayloadAction<{monitorId}>) => {
            if (!state.monitors.hasOwnProperty(action.payload.monitorId)) {
                console.error(`Can't remove monitor, monitor with id ${action.payload.monitorId} does not exist.`);
                return state;
            }

            const newMonitors = Object.assign({}, state.monitors);
            delete newMonitors[action.payload.monitorId];
            return {
                monitors: newMonitors,
                savedMonitorPositions: state.savedMonitorPositions
            };
        }
    },
})

export const {addMonitorRect, moveMonitorRect, resizeMonitorRect, removeMonitorRect} = monitorLayoutSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectMonitorLayout = (state: RootState) => state.monitorLayout

export default monitorLayoutSlice.reducer


// Verify that the rectangle formed by the 2 points is well-formed
const _verifyRect = function (upperStart, lowerEnd) {
    if (isNaN(upperStart.x) || isNaN(upperStart.y) || isNaN(lowerEnd.x) || isNaN(lowerEnd.y)) {
        return false;
    }
    if (!(upperStart.x < lowerEnd.x)) {
        return false;
    }
    if (!(upperStart.y < lowerEnd.y)) {
        return false;
    }
    return true;
};


export const PADDING = 5;
// @todo fix these numbers when we fix https://github.com/LLK/scratch-gui/issues/980
export const SCREEN_WIDTH = 400;
export const SCREEN_HEIGHT = 300;
export const SCREEN_EDGE_BUFFER = 40;
const _rectsIntersect = function (rect1, rect2) {
    // If one rectangle is on left side of other
    if (rect1.upperStart.x >= rect2.lowerEnd.x || rect2.upperStart.x >= rect1.lowerEnd.x) return false;
    // If one rectangle is above other
    if (rect1.upperStart.y >= rect2.lowerEnd.y || rect2.upperStart.y >= rect1.lowerEnd.y) return false;
    return true;
};
// We need to place a monitor with the given width and height. Return a rect defining where it should be placed.
export const getInitialPosition = function (state, monitorId, eltWidth, eltHeight) {
    // If this monitor was purposefully moved to a certain position before, put it back in that position
    if (state.savedMonitorPositions.hasOwnProperty(monitorId)) {
        const saved = state.savedMonitorPositions[monitorId];
        return {
            upperStart: saved,
            lowerEnd: {x: saved.x + eltWidth, y: saved.y + eltHeight}
        };
    }

    // Try all starting positions for the new monitor to find one that doesn't intersect others
    const endXs = [0];
    const endYs = [0];
    let lastX: number|null = null;
    let lastY: number|null  = null;
    for (const monitor in state.monitors) {
        let x = state.monitors[monitor].lowerEnd.x;
        x = Math.ceil(x / 50) * 50; // Try to choose a sensible "tab width" so more monitors line up
        endXs.push(x);
        endYs.push(Math.ceil(state.monitors[monitor].lowerEnd.y));
    }
    endXs.sort((a, b) => a - b);
    endYs.sort((a, b) => a - b);
    // We'll use plan B if the monitor doesn't fit anywhere (too long or tall)
    let planB: any = null;
    for (const x of endXs) {
        if (x === lastX) {
            continue;
        }
        lastX = x;
        outer:
            for (const y of endYs) {
                if (y === lastY) {
                    continue;
                }
                lastY = y;
                const monitorRect = {
                    upperStart: {x: x + PADDING, y: y + PADDING},
                    lowerEnd: {x: x + PADDING + eltWidth, y: y + PADDING + eltHeight}
                };
                // Intersection testing rect that includes padding
                const rect = {
                    upperStart: {x, y},
                    lowerEnd: {x: x + eltWidth + (2 * PADDING), y: y + eltHeight + (2 * PADDING)}
                };
                for (const monitor in state.monitors) {
                    if (_rectsIntersect(state.monitors[monitor], rect)) {
                        continue outer;
                    }
                }
                // If the rect overlaps the ends of the screen
                if (rect.lowerEnd.x > SCREEN_WIDTH || rect.lowerEnd.y > SCREEN_HEIGHT) {
                    // If rect is not too close to completely off screen, set it as plan B
                    if (!planB &&
                        !(rect.upperStart.x + SCREEN_EDGE_BUFFER > SCREEN_WIDTH ||
                            rect.upperStart.y + SCREEN_EDGE_BUFFER > SCREEN_HEIGHT)) {
                        planB = monitorRect;
                    }
                    continue;
                }
                return monitorRect;
            }
    }
    // If the monitor is too long to fit anywhere, put it in the leftmost spot available
    // that intersects the right or bottom edge and isn't too close to the edge.
    if (planB) {
        return planB;
    }

    // If plan B fails and there's nowhere reasonable to put it, plan C is to place the monitor randomly
    const randX = Math.ceil(Math.random() * (SCREEN_WIDTH / 2));
    const randY = Math.ceil(Math.random() * (SCREEN_HEIGHT - SCREEN_EDGE_BUFFER));
    return {
        upperStart: {
            x: randX,
            y: randY
        },
        lowerEnd: {
            x: randX + eltWidth,
            y: randY + eltHeight
        }
    };
};
