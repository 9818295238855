// @flow
import message from "component/df-message/Message";
import { IS_SCRATCH_MODE, IS_WEB_PLATFORM } from "config/config";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { openLinkConnect } from "redux/link-modal/linkModalSlice";
import {
  loadDevice,
  unloadDevice,
} from "service/ext-asset-manager/device/loadDevice";
import { getConfigLocaleContent } from "service/ext-asset-manager/extAssetManager";
import { loadExtension } from "service/ext-asset-manager/extension/loadExtension";
import { commandWs } from "service/link-adapter/websocket/commandWs";
import { vm } from "../../../lib/scratch-vm";
import { DeviceDataType } from "../../../service/ext-asset-manager/device/type";
import { compareVersions } from "../../../service/ext-asset-manager/device/util";
import { ExtensionDataType } from "../../../service/ext-asset-manager/extension/type";
import { WaveLoader } from "../../wave-loader/WaveLoader";
import favoritesUtil from "../util/favoritesUtil";
import { LibDeviceData } from "../util/useDeviceData";
import { LibExtensionData } from "../util/useExtensionData";
import { DFDropdownItem, DFExtensionDropdown } from "./component/DFExtensionDropdown";
import styles from "./ExtensionLibrariesItem.module.scss";
import officialIcon from './official.svg';
import userIcon from './user.svg';
import { useShowingImage } from "./util/useShowingImage";
import { addDownloadingExtension, removeDownloadingExtension, selectDownloadingExtensions } from "redux/extension-downloading/extensionDownloadingSlice";
import DFIcon from "component/df-icon/DFIcon";


export type LibrariesItemProps = {
  libType: "extension" | "device" | "stage";
  items: LibDeviceData[] | LibExtensionData[]; // 传递的是该扩展的不同版本的config.json
};
export const ExtensionLibrariesItem = (props: LibrariesItemProps) => {
  const intl = useIntl()
  const dispatch = useDispatch();
  const descriptionRef = React.useRef<HTMLDivElement>(null);
  // 显示版本的config
  const [activeItem, setActiveItem] = useState<
    LibDeviceData | LibExtensionData
  >(props.items[props.items.length - 1]);
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [showCollect, setShowCollect] = useState<boolean>(false)
  const downloadingExtensions = useSelector(selectDownloadingExtensions)

  useEffect(() => {
    const idWithVersion = activeItem.isDevice ? activeItem.deviceIdWithVersion : activeItem.extensionIdWithVersion;
    if (downloadingExtensions.includes(idWithVersion as string)) {
      setDownloading(true)
    }
  }, [activeItem, downloadingExtensions])

  const handleUnCollect: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    favoritesUtil.removeFavorite(activeItem.id as string)
    vm.emit("refreshExtensionLib", false);
    setShowCollect(true)
  }

  const handleCollect: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    favoritesUtil.addFavorite(activeItem.id as string)
    vm.emit("refreshExtensionLib", false);
    setShowCollect(false)
  }

  // 显示的图片
  const { imageSrc } = useShowingImage(activeItem);

  const isDevice = props.libType === "device";

  let isSupportCurrentDevice = true;
  if (!activeItem.isDevice) {
    isSupportCurrentDevice = activeItem.isSupportCurrentDevice;
  }
  // 是否加载了主板
  const currentDeviceId = vm.runtime.deviceManager.getCurrentDeviceId();
  // 当扩展被刷新
  useEffect(() => {
    // 优先显示被加载的版本
    let loadedItem;
    props.items.forEach((item) => {
      if (item.isLoaded) {
        loadedItem = item;
      }
    });
    // 如果没有加载, 显示最新版本
    if (loadedItem) {
      setActiveItem(loadedItem);
    } else {
      setActiveItem(props.items[props.items.length - 1]);
    }
  }, [props.items]);

  const handleDownloadResource = (e) => {
    e.stopPropagation();
    //TODO: 在线版兼容 没有运行link的情况给用户提示
    setDownloading(true);
    let promise: Promise<any> = Promise.resolve();
    const idWithVersion = activeItem.isDevice ? activeItem.deviceIdWithVersion : activeItem.extensionIdWithVersion;
    dispatch(addDownloadingExtension(idWithVersion as string))
    if (activeItem.isDevice) {
      promise = vm.runtime.extAssetManager.downloadDevice(
        activeItem as DeviceDataType
      );

    } else {
      promise = vm.runtime.extAssetManager.downloadExtension(
        activeItem as ExtensionDataType
      );
    }
    promise
      .then(() => {
        // 刷新扩展库列表
        vm.emit("refreshExtensionLib");
      })
      .catch(() => {
        message.error("下载失败");
      })
      .finally(() => {
        // 结束下载状态
        setDownloading(false);
        dispatch(removeDownloadingExtension(idWithVersion as string))
      });
  };

  const handleUpdateResource = async (e) => {
    e.stopPropagation();
    if (!activeItem) return;
    let updateItem: any = null;
    // 找到未下载的 版本号大于当前选中的版本号 且支持当前主控版本的的
    props.items.some((item) => {
      if (
        !item.isDownloaded &&
        compareVersions(item.version, activeItem.version) === 1
      ) {
        // 如果是主控
        if (item.isDevice) {
          updateItem = item;
          return true;
          // 如果是小模块
        } else if (item.isSupportCurrentDevice) {
          updateItem = item;
          return true;
        }
      }
      return false;
    });
    if (!updateItem) return;
    setDownloading(true);
    try {
      if (isDevice) {
        await vm.runtime.extAssetManager.downloadDevice(
          updateItem as DeviceDataType
        );
      } else {
        await vm.runtime.extAssetManager.downloadExtension(
          updateItem as ExtensionDataType
        );
      }
    } catch (e) {
      console.log("eeee======", e);
    } finally {
      setDownloading(false);
    }
  };

  const showSupportDevice = () => {
    if (
      props.libType === "extension" &&
      !(activeItem as LibExtensionData).isSupportCurrentDevice
    ) {
      if (!currentDeviceId) {
        message.info("请先加载主控!");
      } else {
        message.info(
          `当前模块支持的主控:\n ${Object.keys((activeItem as any).supportDevices).map(key => {
            return `${vm.runtime.extAssetManager.getLocaleNameWithAuthorById(key)}`
            // return `${vm.runtime.extAssetManager.getLocaleNameWithAuthorById(key)}: ${(activeItem as any).supportDevices[key]}`
          }).join("\n")
          }`, 8000, "showSupportDevices"
        );
      }
      return;
    }
  }

  // 点击加载
  const onClickItem = () => {
    if (!activeItem) return;
    if (activeItem.isLoaded) return;
    // 离线版, 未下载的不能加载
    if (!IS_WEB_PLATFORM && !activeItem?.isDownloaded) {
      message.info("未下载的扩展, 无法加载");
      return;
    }
    // 在线版, 主控未下载 不能加载
    if (IS_WEB_PLATFORM && activeItem.isDevice && !activeItem?.isDownloaded) {
      message.info("未下载的扩展, 无法加载");
      return;
    }
    // 判断link是否启动, 主控加载必须启动link
    if (activeItem.isDevice && !commandWs.isOpen()) {
      // 触发link启动弹窗
      dispatch(openLinkConnect());
      return;
    }

    let promise = new Promise((resolve, reject) => {
      if (activeItem.isDevice) {
        if (vm.runtime.deviceManager.currentDevice) {
          vm.runtime.dfrobotAlert(
            intl.formatMessage(messages.prompt),
            intl.formatMessage(messages.changeBoard),
            {
              cover: true,
              btns: [
                { text: intl.formatMessage(messages.yes), callBack: () => resolve(loadDevice(activeItem)) },
                { text: intl.formatMessage(messages.no), callBack: () => reject("cancel") }
              ]
            });
        } else {
          resolve(loadDevice(activeItem));
        }
      } else {
        resolve(loadExtension(activeItem));
      }
    })
    promise
      .then(() => {
        console.log("加载成功");
        // dispatch(closeExtensionLib())
        vm.emit("refreshExtensionLib");
      })
      .catch((e) => {
        if (e === 'cancel') return
        message.error("加载扩展失败!");
      });
  };

  // 卸载
  const removeExtension = (e) => {
    e.stopPropagation()
    let id;
    if (activeItem.isDevice) {
      id = activeItem.deviceIdWithVersion;
    } else {
      id = activeItem.extensionIdWithVersion || activeItem.extensionId;
    }

    // 方案1: 不提示, 直接移除, 可能会删除该扩展以外的block
    // vm.extensionManager.deleteExtension(id)

    // 方案2
    // 1.检测当前workspace有没有该扩展的block
    let ret = vm.runtime.workspaceHasExtensionBlock(id);

    // 2.没有就直接移除
    if (!ret) {
      if (activeItem.isDevice) {
        unloadDevice().then(() => {
          // 刷新扩展库页面
          vm.emit("refreshExtensionLib")
        })
      } else {
        vm.extensionManager.deleteExtension(id);
        // 刷新扩展库页面
        vm.emit("refreshExtensionLib")
      }
    } else {
      if (activeItem.isDevice) {
        vm.runtime.dfrobotAlert(
          intl.formatMessage(messages.prompt),
          intl.formatMessage(messages.removeBoard),
          {
            cover: true,
            btns: [
              {
                text: intl.formatMessage(messages.yes), callBack: () => {
                  return unloadDevice().then(() => {
                    // 刷新扩展库页面
                    vm.emit("refreshExtensionLib")
                  })
                }
              },
              { text: intl.formatMessage(messages.no), callBack: () => { } }
            ]
          });
      } else {
        // 提示 扩展正在被使用, 无法移除
        message.warning("扩展正在使用, 无法移除", 2000);
      }
    }
  }

  // 切换版本
  const onChangeVersion = (val: string) => {
    // 1.等于当前版本
    if (val === activeItem.version) return;
    let id;
    if (activeItem.isDevice) {
      id = activeItem.deviceIdWithVersion;
    } else {
      id = activeItem.extensionIdWithVersion || activeItem.extensionId;
    }
    const newItem = (props.items as any).find((item) => item.version === val);
    // 2.当前扩展已加载
    if (activeItem.isLoaded) {
      // 1.检测当前workspace有没有该扩展的block
      let ret = vm.runtime.workspaceHasExtensionBlock(id);
      if (ret) {
        // 有的话, 提示 扩展正在被使用, 无法切换版本
        message.warning("扩展正在使用, 无法切换版本", 4);
        return;
      } else {
        // 移除旧的扩展
        vm.extensionManager.deleteExtension(id);
        // 加载新的扩展
        let promise = Promise.resolve();
        if (newItem.isDevice) {
          promise = loadDevice(newItem);
        } else {
          promise = loadExtension(newItem);
        }

        promise
          .then(() => {
            message.success("切换版本成功");
            vm.emit("refreshExtensionLib");
          })
          .catch((e) => {
            message.error("切换版本失败");
          });
      }
    }

    setActiveItem(newItem);
  };

  const deleteResource = async () => {
    if (!activeItem.isDownloaded) return;
    try {
      if (activeItem.isDevice) {
        if (activeItem.isLoaded) {
          await unloadDevice()
        }
        await vm.runtime.extAssetManager.removeDeviceFromLocal(activeItem)
        message.success("删除主控成功");
      } else {
        if (activeItem.isLoaded) {
          vm.extensionManager.deleteExtension(activeItem.extensionIdWithVersion);
        }
        await vm.runtime.extAssetManager.removeExtensionFromLocal(activeItem)
        message.success("删除扩展成功");
      }
      vm.emit("refreshExtensionLib", true);
    } catch (error) {
      console.log("error===", error)
      message.error("删除扩展失败");
    }
  }
  // 1.已加载的(ok)

  // 2.不支持的(ok)

  // 3.有更新的

  // 4.下载

  // 5.更新

  let showStatus = "";
  // 1.离线版有更新/下载, 已加载的扩展不显示更新/下载按钮
  // 2.在线版的主控有更新/下载
  if ((!IS_WEB_PLATFORM || activeItem.isDevice || !IS_SCRATCH_MODE) && !activeItem.isLoaded) {
    // link未连接
    if (!commandWs.isOpen()) {
      showStatus = "needLink";
    } else if (!activeItem.isDownloaded) {
      showStatus = "download";
    } else if (activeItem.hasUpdate) {
      showStatus = "update";
    }
  }


  // 设置描述信息样式
  useEffect(() => {
    const applyEllipsis = (element, lines) => {
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      const maxHeight = lineHeight * lines;
      element.style.maxHeight = `${maxHeight}px`;

      let text = element.innerText;
      while (element.scrollHeight > element.clientHeight) {
        text = text.substring(0, text.length - 1);
        element.innerText = text + "...";
      }
    };

    if (descriptionRef.current) {
      applyEllipsis(descriptionRef.current, 2);
    }
  }, []);


  const onMouseOver = () => {
    if (activeItem.isFavorite) return;
    setShowCollect(true);
  }

  const onMouseLeave = () => {
    if (activeItem.isFavorite) return;
    setShowCollect(false);
  }
  return (
    <div
      className={`${styles.spriteCard} ${activeItem.isLoaded ? styles.selected : ""
        }`}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {
        activeItem.isFavorite && (
          <div className={styles.favorite} onClick={handleUnCollect}>
            <DFIcon name="uncollect" size={20} />
          </div>
        )
      }
      {
        showCollect && !activeItem.isFavorite && showStatus !== "needLink" && (
          <div className={styles.favorite} onClick={handleCollect}>
            <DFIcon name="collect" size={20} />
          </div>
        )
      }
      {isDownloading && (
        <div className={styles.downloading}>
          <WaveLoader itemCount={3} loadingText={"下载中"} />
        </div>
      )}
      {showStatus === "needLink" && (
        <div className={styles.needLink}>
          <div className={styles.needLinkTag}>请先连接Mind+Link</div>
        </div>
      )}
      {!isSupportCurrentDevice && showStatus !== "needLink" && (
        <div className={styles.disabled} onClick={showSupportDevice}>
          <div className={styles.disabledTag}>{"不支持当前主控"}</div>
        </div>
      )}

      {showStatus === "download" && isSupportCurrentDevice && (
        <div className={styles.download} onClick={handleDownloadResource} title="下载" >
          <DownloadIcon pathClassName={styles.downloadIcon} borderClassName={styles.border} />
        </div>
      )}
      {activeItem.isLoaded && (
        <div className={styles.remove} onClick={removeExtension}>
          <DFIcon name="remove" size={20} />
          <span>移除</span>
        </div>
      )}
      <div onClick={onClickItem}>
        <div className={styles.image}>
          <img src={imageSrc} alt="" />
          <div className={styles.versions} onClick={(e) => e.stopPropagation()}>
          </div>
        </div>
        <div className={styles.sku}>
          {activeItem.sku && activeItem.sku.split('|').map((sku) => <SkuComponent sku={sku} key={sku} />)}
        </div>
        <div className={styles.featuredText}>
          {
            !activeItem.isDevice && activeItem.isBuiltinScratch ? (
              <>
                <div className={styles.name}>{activeItem?.name as any}</div>
                <div className={styles.description} ref={descriptionRef}>
                  {activeItem?.description as any}
                </div>
              </>
            ) : (
              <>
                <div className={styles.name}>
                  {activeItem && activeItem.name && getConfigLocaleContent(activeItem.name)}
                </div>
                <div className={styles.description} ref={descriptionRef}>
                  {activeItem &&
                    activeItem.description &&
                    getConfigLocaleContent(activeItem.description)}
                </div>
              </>
            )
          }

        </div>
      </div>
      <ExtensionFooter
        onDeleteResource={deleteResource}
        onChangeVersion={onChangeVersion}
        activeItem={activeItem}
        isOfficial={true}
        versions={props.items.map((item) => ({ label: item.version, value: item.version }))}
      />
    </div >
  );
};


const SkuComponent = (props) => (<div className={styles.skuItem}>{props.sku}</div>)


type FooterProps = {
  onChangeVersion: (val: string) => void
  activeItem: any
  isOfficial: boolean
  versions: any
  onDeleteResource: () => void
}



const ExtensionFooter = (props: FooterProps) => {
  const { onChangeVersion, activeItem, isOfficial, versions, onDeleteResource } = props
  const handleItemClick = (item: any) => {
    switch (item.key) {
      case "delete":
        onDeleteResource()
        break
      case "more":
        // TODO
        message.info("此功能暂未开放")
        break
    }
  }
  const dropdownItems = useMemo(() => {
    const items: DFDropdownItem[] = [{ label: "更多信息", key: "more", disabled: true }]
    if (!activeItem.isDownloaded) {
      return items
    }
    // 在线版,非主控不能删除
    if(IS_WEB_PLATFORM && !activeItem.isDevice) {
      return items
    }
    if (activeItem.isLoaded) {
      items.splice(0, 0, { label: "删除下载", key: "delete", disabled: true })
      return items
    }
    items.splice(0, 0, { label: "删除下载", key: "delete", disabled: false })
    return items
  }, [activeItem])


  return <div className={styles.footer}>
    <div className={styles.author}>
      <img alt="" src={isOfficial ? officialIcon : userIcon} />
      <span>{activeItem.author}</span>
    </div>
    <div className={styles.versions}></div>
    <div className={styles.more}>
      <DFExtensionDropdown position="right" items={dropdownItems} onItemClick={handleItemClick}>
        <DFIcon name="more" />
      </DFExtensionDropdown>
    </div>
  </div>
}

const DownloadIcon = (props: { pathClassName: string, borderClassName }) => {
  const { borderClassName, pathClassName } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="22"
      height="22" viewBox="0 0 22 22" className={pathClassName}>
      <g>
        <g>
          <rect x="0" y="0" width="22" height="22" rx="6" fill="#FFFFFF" fillOpacity="1" />
          <rect x="0.5" y="0.5" width="21" height="21" rx="5.5" fillOpacity="0" strokeOpacity="1" stroke="#F0B037"
            fill="none" strokeWidth="1" className={borderClassName} />
        </g>
        <g>
          <path
            d="M5,15.7L17,15.7L17,17L5,17L5,15.7ZM11.66667,9.2L16.3333,9.2L11,14.4L5.666667,9.2L10.33333,9.2L10.33333,4L11.66667,4L11.66667,9.2Z"
            fill="#F0B037" fillOpacity="1" />
        </g>
      </g>
    </svg>

  )
}

const messages = defineMessages({
  prompt: {
    id: "gui.dialog.prompt",
    defaultMessage: "Note"
  },
  changeBoard: {
    id: 'gui.dialog.changeBoard',
    defaultMessage: 'Switching board will clear the current program and continue'
  },
  removeBoard: {
    id: 'gui.dialog.removeBoard',
    defaultMessage: 'Removing board will clear the current program. Are you sure you want to continue?'
  },
  yes: {
    id: 'gui.dialog.yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'gui.dialog.no',
    defaultMessage: 'No'
  },
})
