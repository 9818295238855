import VM from "../../lib/scratch-vm";
import { getDeviceData } from "./device/getDeviceData";
import { DeviceDataType } from "./device/type";
import { getExtensionData } from "./extension/getExtensionData";
import { ExtensionDataType, ExternalExtensionDataType } from "./extension/type";
import fs from "../link-adapter/fs";
import {
    getBuiltinDevicePath,
    getBuiltinExtensionPath,
    getLocalDevicePath,
    getLocalExtensionPath
} from "../path/assetPath";
import path from "path";
import { deleteDeviceResource, deleteExtensionResource, downloadDeviceResource, downloadExtensionResource } from "../link-adapter/resource";
import { commandWs } from "service/link-adapter/websocket/commandWs";
import { EXTENSION_ASSET_SERVER, IS_SCRATCH_MODE, IS_WEB_PLATFORM, MINDPLUS_MODE } from "config/config";
import { requestTextFile } from "util/http";
import { intl } from "redux/intl/intlSlice";

const STALE_TIME = 30; // 数据过期时间, 秒

interface DataMap<T> {
    [id: string]: {
        [version: string]: T
    }
}

export type DeviceMaps = DataMap<DeviceDataType>;
export type ExtensionMaps = DataMap<ExtensionDataType>;

class ExtAssetManager {
    vm: VM
    _devices: DeviceMaps = {}
    deviceRefreshTime: number = 0 // 数据刷新时间
    deviceRefreshPromise: null | Promise<DeviceMaps> = null
    deviceCallback: any = null
    _extensions: ExtensionMaps = {}
    extensionRefreshTime: number = 0 // 数据刷新时间
    extensionRefreshPromise: Promise<ExtensionMaps> | null = null
    extensionCallback: any = null

    _loadedExtensionVersion: { [id: string]: string } = {}
    constructor(vm) {
        this.vm = vm;
        commandWs.on("open", () => {
            console.log("清除计时!!")
            this.deviceRefreshTime = 0;
            this.extensionRefreshTime = 0;
        })
    }

    // 刷新主控列表, 用于主控库显示主控列表
    refreshDeviceList(callback?: (data: DeviceMaps) => void, immediate = false): Promise<DeviceMaps> {
        console.log("refreshDeviceList!!")
        // 先用旧数据刷新一下
        if (callback) callback(this._devices);
        // 数据未过期
        if (Date.now() - this.deviceRefreshTime < STALE_TIME * 1000 && !immediate) {
            return Promise.resolve(this._devices);
        }
        // 数据过期
        if (callback) this.deviceCallback = callback;
        // 1. 如果正在获取
        if (this.deviceRefreshPromise) return this.deviceRefreshPromise;
        // 2. 重新获取
        this.deviceRefreshPromise = getDeviceData((data) => {
            if (this.deviceCallback) this.deviceCallback(data);
        }).then((devices) => {
            this.deviceCallback = null;
            // 缓存主控数据
            if (devices) this._devices = devices;
            // 记录刷新时间
            this.deviceRefreshTime = Date.now();
            // 清除promise
            this.deviceRefreshPromise = null;
            return this._devices;
        })
        return this.deviceRefreshPromise;
    }

    refreshExtensionList(callback?: (data: ExtensionMaps) => void, immediate = false): Promise<ExtensionMaps> {
        // 先用旧数据刷新一下
        if (callback) callback(this._extensions);
        // 数据未过期
        if (Date.now() - this.extensionRefreshTime < STALE_TIME * 1000 && !immediate) {
            return Promise.resolve(this._extensions);
        }
        // 数据过期
        if (callback) this.extensionCallback = callback;
        // 1. 如果正在获取
        if (this.extensionRefreshPromise) return this.extensionRefreshPromise;
        // 如果数据过期, 重新获取
        this.extensionRefreshPromise = getExtensionData((data) => {
            if (this.extensionCallback) this.extensionCallback(data);
        }).then((extensions) => {
            this.extensionCallback = null;
            // 缓存扩展数据
            if (extensions) this._extensions = extensions;
            // 记录刷新时间
            this.extensionRefreshTime = Date.now();
            // 清除promise
            this.extensionRefreshPromise = null;
            return this._extensions;
        })
        return this.extensionRefreshPromise;
    }

    // 记录已加载的扩展/主控版本号
    setLoadedExtensionVersion(extensionId, version) {
        // 去除extensionId的版本号
        extensionId = extensionId.replace(/@.+$/, '');
        this._loadedExtensionVersion[extensionId] = version;
    }

    // 读取已加载的扩展/主控版本号(扩展库页面显示)
    getLoadedExtensionVersion(extensionId) {
        return this._loadedExtensionVersion[extensionId] || '';
    }


    // 通过id获取主控config,
    getDeviceConfigDataById(deviceIdWithVersion: string): Promise<DeviceDataType | undefined> {
        let version = ''
        if (deviceIdWithVersion.indexOf('@') !== -1) {
            version = deviceIdWithVersion.slice(deviceIdWithVersion.indexOf('@') + 1)
        }
        let deviceId = deviceIdWithVersion.replace(/@.+$/, '');
        // 如果version为空, 选择最新的版本
        if (!version && this._devices[deviceId]) {
            version = Object.keys(this._devices[deviceId]).sort((a, b) => a < b ? 1 : -1)[0]
        }
        // 去除版本号
        if (version && this._devices[deviceId] && this._devices[deviceId][version]) return Promise.resolve(this._devices[deviceId][version]);
        return this.refreshDeviceList().then(() => {
            // 如果version为空, 选择最新的版本
            if (!version && this._devices[deviceId]) {
                version = Object.keys(this._devices[deviceId]).sort((a, b) => a < b ? 1 : -1)[0]
            }
            return this._devices[deviceId] && this._devices[deviceId][version];
        })
    }

    async getDeviceMainJsById(deviceIdWithVersion: string): Promise<string> {
        const config = await this.getDeviceConfigDataById(deviceIdWithVersion)
        if (!config) return Promise.reject(`没有找到该主控:${deviceIdWithVersion}`);
        // 主控有没有被下载(WEB端也要下载)
        if (!config.isDownloaded) {
            // 下载
            let err = await this.downloadDevice(config).catch(() => true)
            if (err) return Promise.reject(`${deviceIdWithVersion} 下载失败`)
        }
        // 读取main.js
        const jsCode: string = await this.getFileData(getMainPathFromConfig(config));
        return jsCode;
    }

    // 下载主控
    downloadDevice(config: DeviceDataType) {
        // 下载成功之后, 要将main/cover的路径转换成本地路径
        return downloadDeviceResource(config)
            .then(() => {
                // 下载成功后,修改数据源
                if (!this._devices[config.deviceId]) this._devices[config.deviceId] = {}
                this._devices[config.deviceId][config.version || "0.0.1"] = {
                    ...config,
                    isDownloaded: true,
                    isBuiltin: false
                };
                // 刷新扩展库显示
                this.vm.emit("refreshDeviceList")
            })
    }

    // 通过id获取扩展config
    getExtensionConfigDataById(extensionIdWithVersion: string) {
        let version = ''
        if (extensionIdWithVersion.indexOf('@') !== -1) {
            version = extensionIdWithVersion.slice(extensionIdWithVersion.indexOf('@') + 1)
        }
        let extensionId = extensionIdWithVersion.replace(/@.+$/, '');
        // 如果version为空, 选择最新的版本
        if (!version && this._extensions[extensionId]) {
            version = Object.keys(this._extensions[extensionId]).sort((a, b) => a < b ? 1 : -1)[0]
        }
        // 去除版本号
        if (version && this._extensions[extensionId] && this._extensions[extensionId][version]) return Promise.resolve(this._extensions[extensionId][version]);
        return this.refreshExtensionList().then(() => {
            // 如果version为空, 选择最新的版本
            if (!version && this._extensions[extensionId]) {
                version = Object.keys(this._extensions[extensionId]).sort((a, b) => a < b ? 1 : -1)[0]
            }
            return this._extensions[extensionId] && this._extensions[extensionId][version];
        })
    }

    async getExtensionMainJsById(extensionIdWithVersion: string) {
        const config = await this.getExtensionConfigDataById(extensionIdWithVersion)
        if (!config) return Promise.reject(`没有找到该扩展:${extensionIdWithVersion}`);
        if (config.isBuiltinScratch) return '';
        // 扩展有没有被下载(在线版, scratch模式不下载)
        if (!config.isDownloaded && !(IS_SCRATCH_MODE && IS_WEB_PLATFORM)) {
            // 下载
            let err = await this.downloadExtension(config).catch(() => true)
            if (err) return Promise.reject(`${extensionIdWithVersion} 下载失败`)
        }
        // 读取main.js
        const jsCode: string = await this.getFileData(getMainPathFromConfig(config)).catch(e => {
            console.error(e)
            return "";
        })
        if (!jsCode) return Promise.reject("读取main.js失败!")
        return jsCode;
    }

    // 下载主控
    downloadExtension(config: ExtensionDataType) {
        // 下载成功之后, 要将main/cover的路径转换成本地路径
        return downloadExtensionResource(config)
            .then(() => {
                if (!this._extensions[config.extensionId]) this._extensions[config.extensionId] = {}
                this._extensions[config.extensionId][config.version || "0.0.1"] = { ...config, isDownloaded: true };
                this.vm.emit("refreshExtensionList")
            })
    }

    getFileData(url: string) {
        if (!url) return Promise.reject()
        // todo: 避免同时读取
        if (url.indexOf("http") === 0) {
            // 在线版
            return requestTextFile(url);
        } else {
            // 离线版
            return fs.readFile(url);
        }
    }

    removeDeviceFromLocal(config: DeviceDataType) {
        return deleteDeviceResource(config)
    }

    removeExtensionFromLocal(config: ExtensionDataType) {
        return deleteExtensionResource(config)
    }

    // 通过id获取扩展/主控名称
    getLocaleNameWithAuthorById(id: string) {
        let version = ''
        if (id.indexOf('@') !== -1) {
            version = id.slice(id.indexOf('@') + 1)
        }
        let _id = id.replace(/@.+$/, '');
        let items: any = this._extensions;
        // 1.找到config.json
        if (id.indexOf("dev-") === 0) {
            items = this._devices;
        }
        // 如果version为空, 选择最新的版本
        if (!version && items[_id]) {
            version = Object.keys(items[_id]).sort((a, b) => a < b ? 1 : -1)[0]
        }
        if (version && items[_id] && items[_id][version]) {
            let config = items[_id][version];
            // 2. 获取name
            return config.author + "-" + getConfigLocaleContent(config.name);
        }
        return "";
    }
}

export default ExtAssetManager;

// 获取cover的路径
export const getCoverPathFromConfig = (config: DeviceDataType | ExternalExtensionDataType) => {
    // 如果cover为空, 显示默认图片
    if (!config.cover) {
        // todo: 替换成默认图片
        return '';
    }
    // 在线版或还没下载, 返回图片链接, config需要加上originURL
    if (!config.isDownloaded) {
        return `${EXTENSION_ASSET_SERVER}`
            + `/${MINDPLUS_MODE}`
            + `/${config.isDevice ? "devices" : "extensions"}`
            + `/${config.isDevice ? config.deviceIdWithVersion : config.extensionIdWithVersion}`
            + `/${config.cover}`
    }

    if (config.isDevice) {
        if (config.isBuiltin) { // 安装目录下的extension
            return path.join(getBuiltinDevicePath(), config.deviceIdWithVersion, config.cover);
        } else {
            return path.join(getLocalDevicePath(), config.deviceIdWithVersion, config.cover);
        }
    } else {
        if (config.isBuiltin) { // 安装目录下的extension
            return path.join(getBuiltinExtensionPath(), config.extensionIdWithVersion, config.cover);
        } else {
            return path.join(getLocalExtensionPath(), config.extensionIdWithVersion, config.cover);
        }
    }
}

// 获取main的路径
export const getMainPathFromConfig = (config: DeviceDataType | ExternalExtensionDataType) => {
    if (!config.main) {
        return '';
    }
    // 在线版
    if (!config.isDownloaded) {
        return `${EXTENSION_ASSET_SERVER}`
            + `/${MINDPLUS_MODE}`
            + `/${config.isDevice ? "devices" : "extensions"}`
            + `/${config.isDevice ? config.deviceIdWithVersion : config.extensionIdWithVersion}`
            + `/${config.main || "main.js"}`
    }

    if (config.isDevice) {
        if (config.isBuiltin) { // 安装目录下的extension
            return path.join(getBuiltinDevicePath(), config.deviceIdWithVersion, config.main);
        } else {
            return path.join(getLocalDevicePath(), config.deviceIdWithVersion, config.main);
        }
    } else {
        if (config.isBuiltin) { // 安装目录下的extension
            return path.join(getBuiltinExtensionPath(), config.extensionIdWithVersion, config.main);
        } else {
            return path.join(getLocalExtensionPath(), config.extensionIdWithVersion, config.main);
        }
    }
}

// 获取图片的base64URL todo: 缓存
export function getImageBase64URL(filePath) {
    if (!filePath) return Promise.resolve('');
    return fs.readFile(filePath, { encoding: 'base64' })
        .then((data: string) => {
            const suffix = filePath.slice(filePath.lastIndexOf('.') + 1)
            return `data:image/${suffix};base64,${data}`
        })
}

// 获取extension翻译之后name/description
export function getConfigLocaleContent(items: any) {
    // 缺少翻译, 默认显示英文
    return items[intl.locale] || items["en"]
}
