import SharedAudioContext from './shared-audio-context.js';
import VolumeEffect from './effects/volume-effect.js';

class ReSamples {
    constructor (samples,samplesRate) {
        this.audioContext = new SharedAudioContext();
        this.buffer = this.audioContext.createBuffer(1, samples.length, samplesRate);
        this.buffer.getChannelData(0).set(samples);
        
        let sampleCount = this.buffer.length;
        let playbackRate = 1;
        const self = this;
        if (window.OfflineAudioContext) {
            const sampleScale = 16000 / this.buffer.sampleRate;
            this.audioContext = new window.OfflineAudioContext(1, sampleScale * sampleCount, 16000);
        } else {
            // Need to use webkitOfflineAudioContext, which doesn't support all sample rates.
            // Resample by adjusting sample count to make room and set offline context to desired sample rate.
            const sampleScale = 16000 / this.buffer.sampleRate;
            this.audioContext = new window.webkitOfflineAudioContext(1, sampleScale * sampleCount, 16000);
        }

        // For the reverse effect we need to manually reverse the data into a new audio buffer
        // to prevent overwriting the original, so that the undo stack works correctly.
        // Doing buffer.reverse() would mutate the original data.

        this.source = this.audioContext.createBufferSource();
        this.source.buffer = this.buffer;
        this.source.playbackRate.value = playbackRate;
        // this.name = name;
    }
    process (done) {
        let input;
        let output;
        ({input, output} = new VolumeEffect(this.audioContext, 1.25, null, null));
        if (input && output) {
            this.source.connect(input);
            output.connect(this.audioContext.destination);
        } else {
            // No effects nodes are needed, wire directly to the output
            this.source.connect(this.audioContext.destination);
        }
        // this.source.connect(this.audioContext.destination);
        this.source.start();

        this.audioContext.startRendering();
        this.audioContext.oncomplete = done;
    }
}

export default ReSamples;
