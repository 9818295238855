import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

// Define a type for the slice state
interface VmStatusState {
    running: boolean,
    started: boolean,
    turbo: boolean
}

// Define the initial state using that type
const initialState: VmStatusState = {
    running: false,
    started: false,
    turbo: false
}

export const vmStatusSlice = createSlice({
    name: 'vmStatus',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setStartedState: (state, action: PayloadAction<boolean>) => {
            state.started = action.payload;
        },
        setRunningState: (state, action: PayloadAction<boolean>) => {
            state.running = action.payload;
        },
        setTurboState: (state, action: PayloadAction<boolean>) => {
            state.turbo = action.payload;
        },
    },
})

export const {setStartedState, setRunningState, setTurboState} = vmStatusSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectVmStatus = (state: RootState) => state.vmStatus

export default vmStatusSlice.reducer
