const ArgumentType = require('../extension-support/argument-type');
const Variable = require('../engine/variable');
const CORE_EXTENSIONS = [
    'argument',
    'colour',
    'control',
    'data',
    'event',
    'looks',
    'math',
    'motion',
    'operator',
    'procedures',
    'sensing',
    'sound'
];


const customParse = function (object, target) {

    // df: 设备对象的属性
    if (object.hasOwnProperty('isDevice')) {
        target.isDevice = object.isDevice;
        // 不显示图片
        target.visible = false;
    }
    if (object.hasOwnProperty('deviceId')) {
        target.deviceId = object.deviceId;
    }
    // 解析扩展
    if (object.hasOwnProperty('version')) {
        target.version = object.version;
    }
    // 解析字符串变量
    if (object.hasOwnProperty('strings')) {
        for (const stringId in object.strings) {
            const variable = object.strings[stringId];
            const newVariable = new Variable(
                stringId,
                variable[0],
                Variable.STRING_TYPE,
                false
            );
            newVariable.value = variable[1];
            target.variables[newVariable.id] = newVariable;
        }
    }

};

// DFRobot-microbit@0.0.1_xxxxxxx.0_digitalRead ==> DFRobot-microbit@0.0.1_xxxx_digitalRead
const getExtensionIdForOpcode = function (opcode) {
    // // 先去掉 .0 .1 ...
    // opcode = opcode.replace(/\.\d+/, '');
    // // Allowed ID characters are those matching the regular expression [\w-]: A-Z, a-z, 0-9, and hyphen ("-").
    // const index = opcode.lastIndexOf('_');
    // const forbiddenSymbols = /[^\w-]/g;
    // const prefix = opcode.substring(0, index).replace(forbiddenSymbols, '-');
    // if (CORE_EXTENSIONS.indexOf(prefix) === -1) {
    //     if (prefix !== '') return prefix;
    // }

    // scratch内置block的opcode, 会使用下划线,
    // 如: math_positive_number, math是extensionId, positive_number是opcode

    // 内置block
    if (CORE_EXTENSIONS.indexOf(opcode.substring(0, opcode.indexOf('_'))) !== -1) {
        return;
    }
    // 自定义的field 中有xx_xx的形式
    if (Object.values(ArgumentType).includes(opcode) || ['mqtt_settings', 'obloq_settings'].includes(opcode)) {
        return;
    }
    // 添加的扩展
    const index = opcode.indexOf('_');
    // 获取extensionId
    let extensionId = opcode.substring(0, index);
    // 先去掉 _0 _1 ...
    extensionId = extensionId.replace(/_\d+$/, '');
    // 将特殊字符转换为 -
    const forbiddenSymbols = /[^\w\-\.@]/g;
    extensionId = extensionId.replace(forbiddenSymbols, '-');
    return extensionId;
};

module.exports = {
    customParse,
    getExtensionIdForOpcode
};
