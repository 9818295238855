

export const base64ToStr = (base64str) => {
    const byteArray = Buffer.from(base64str, 'base64');
    return byteArray;
}

export const strToBase64 = (str) => {
    const base64str = Buffer.from(str).toString('base64');
    return base64str;
}
