
import { vm } from 'lib/scratch-vm';
import { useDispatch, useSelector } from 'react-redux';
import { closeAlertWithId, showStandardAlert } from 'redux/alerts/alertsSlice';
import { openBackdropLib } from 'redux/asset-lib/assetLibStatusSlice';
import { selectBlockDrag } from 'redux/block-drag/blockDragSlice';
import { selectHoverTarget, setHoveredSprite } from 'redux/hover-target/hoverTargetSlice';
import { openBackdropTab, openCostumeTab, openSoundTab, showBlockTab } from 'redux/tab/tabSlice';
import { selectTargets } from 'redux/tagets/targetsSlice';
import StageSelectorScratch from './stage-selector'


interface StageSelectorProps {
    id: string,
    onCloseImporting: Function,
    onSelect: Function,
    onShowImporting: Function
    asset:any
    selected: boolean
};

export const StageSelector = (props: StageSelectorProps) => {
    const {receivedBlocks, sprite} = useSelector(selectHoverTarget)
    const {editingTarget} = useSelector(selectTargets);
    const raised = useSelector(selectBlockDrag)
    const dispatch = useDispatch();
    return <StageSelectorScratch 
        {...props}
        vm={vm}
        url={props.asset && props.asset.encodeDataURI()}
        receivedBlocks={receivedBlocks&& sprite === props.id}
        raised={raised}
        onNewBackdropClick={e => {
            e.stopPropagation();
            dispatch(openBackdropLib());
        }}
        onActivateTab={tabIndex => {
            switch(tabIndex){
                case 1:
                    // 舞台
                    if (editingTarget === vm.runtime.getTargetForStage()?.id) {
                        return dispatch(openBackdropTab());
                    }
                    // 角色
                    return dispatch(openCostumeTab());
                case 2:
                    return dispatch(openSoundTab());
                case 0:
                default:
                    return dispatch(showBlockTab());
            }
        }}
        dispatchSetHoveredSprite={spriteId => {
            dispatch(setHoveredSprite(spriteId));
        }}
        onCloseImporting={() => dispatch(closeAlertWithId('importingAsset'))}
        onShowImporting={() => dispatch(showStandardAlert('importingAsset'))}
    />
}