const Sprite = require('../sprites/sprite');
const StringUtil = require('../util/string-util');

class DFSprite extends Sprite {
    constructor(blocks, runtime) {
        super(blocks, runtime);
    }
    addCostumeAt(costumeObject, index) {
        if (!costumeObject.name) {
            costumeObject.name = '';
        }
        const costumeObjCopy = Object.assign({}, costumeObject)
        const usedNames = this.costumes_.map(costume => costume.name);
        costumeObjCopy.name = StringUtil.unusedName(costumeObjCopy.name, usedNames);
        this.costumes_.splice(index, 0, costumeObjCopy);
    }
}

module.exports = DFSprite;
