// 用于记录项目的加载状态和数据
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

interface TabState {
    currentTab: 'block'|'sound'|'costume'|'backdrop'
}

const initialState: TabState = {
    currentTab: 'block'
}

export const tabSlice = createSlice({
    name: 'tab',
    initialState,
    reducers: {
        openSoundTab: (state) => {
            state.currentTab = 'sound';
        },
        closeSoundTab: (state) => {
            state.currentTab = 'block';
        },
        openCostumeTab: (state) => {
            state.currentTab = 'costume';
        },
        closeCostumeTab: (state) => {
            state.currentTab = 'block';
        },
        showBlockTab: (state) => {
            state.currentTab = 'block';
        },
        openBackdropTab: (state) => {
            state.currentTab = 'backdrop';
        },
        closeBackdropTab: (state) => {
            state.currentTab = 'block';
        }
    },
})

export const {
    openSoundTab,
    closeSoundTab,
    openCostumeTab,
    closeCostumeTab,
    showBlockTab,
    closeBackdropTab,
    openBackdropTab
} = tabSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectTab = (state: RootState) => state.tab

export default tabSlice.reducer
