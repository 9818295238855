import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "redux/theme/themeSlice";
import './app.module.scss'
import { ConfigProvider } from 'antd';
import Gui from "./view/ScratchGui";
import { useMediaQuery } from 'react-responsive'
import layoutConstants from 'lib/layout-constants';
import { selectStageSize, setLargeConstrainedStage, setLargeStage, setSmallStage } from 'redux/stage-size/stageSizeSlice';
import { selectProject } from 'redux/project/projectSlice';

export const themeColor = {
  "base": "#f8ad30",
  "blue": "#3498db",
  "orange": "#fa8c16",
  "purple": "#E3C1F8",
  "red": "#C61075",
  "green": "#6BD9A9",
  "violet": "#443256",
  "eye-protect": "#ff4400"
}

function Scratch() {
  const ref = useRef<HTMLBodyElement | null>(null)
  const theme = useSelector(selectTheme)
  const { projectTitle } = useSelector(selectProject)
  // 监听窗口是否大于最小宽度
  const screenBiggerThanMinWidth = useMediaQuery({ minWidth: layoutConstants.fullSizeMinWidth })
  const { stageSize } = useSelector(selectStageSize)
  const dispatch = useDispatch()

  // 将projectTitle设置到document.title
  useEffect(() => {
    document.title = projectTitle
  }, [projectTitle])

  useEffect(() => {
    // 若此时stageSize是small,则不做任何操作
    if (stageSize === 'small') return
    // 若此时stageSize不是small,且符合最小宽度尺寸，设置为large
    if (screenBiggerThanMinWidth) {
      dispatch(setLargeStage())
    } else {
      // 设置为中号stageSize(可以将largeConstrained视为大舞台模式的一种)
      dispatch(setLargeConstrainedStage())
    }
  }, [dispatch, screenBiggerThanMinWidth, stageSize])

  useEffect(() => {
    const body = document.body as HTMLBodyElement
    if (body) {
      ref.current = body
      ref.current.dataset.theme = theme
    }
  }, [theme])

  // 全局错误捕获
  useEffect(() => {
    window.addEventListener('error', (event) => {
      console.log("global error", event)
      return true
    }, true)

    window.addEventListener('unhandledrejection', (event) => {
      console.log("global unhandledrejection", event)
      return true
    }, true)

  }, [])
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: themeColor[theme],
        },
      }}
    >
      <Gui />
    </ConfigProvider>
  )
}

export default Scratch;

