import {vm} from "lib/scratch-vm";
import {useSelector} from "react-redux";
import {selectTargets} from "redux/tagets/targetsSlice";

export const useSelectedIndex = () => {
    // 当切换sprite/添加造型时, targetsUpdateCount发生变化, 重新渲染该组件
    const {targetsUpdateCount} = useSelector(selectTargets);
    // 获取vm中的index
    const selectedIndex = getCostumeIndexFromVm();

    const onSelectedIndex = (index) => {
        vm.editingTarget.setCostume(index);
    }

    return {
        selectedIndex,
        onSelectedIndex
    }
}

const getCostumeIndexFromVm = () => {
    const sprite = vm.editingTarget.sprite;
    let selectedIndex = vm.editingTarget?.currentCostume||0;
    selectedIndex = selectedIndex < sprite.costumes.length ?
        selectedIndex : sprite.costumes.length - 1;
    return selectedIndex;
}
