
let BASE_PATH_CONFIG = {
    // todo: 路径放在link中去解析, 浏览器中无法通过os.homedir()获取路径
    HOMEDIR: '%HOMEDIR%',
    // mind+软件根目录
    APPROOT: "",
    // link根目录(link和mind+可能不在一个路径下)
    LINKAPPROOT: "%APPROOT%"
}

export const getBasePathConfig = () => {
    return BASE_PATH_CONFIG
}

export const getHomeDirPath = () => BASE_PATH_CONFIG.HOMEDIR;

export const getAppRootPath = () => BASE_PATH_CONFIG.APPROOT;

export const getLinkAppRootPath = () => BASE_PATH_CONFIG.LINKAPPROOT;

// 在command ws连接成功之后, 获取并设置路径信息
export const setPathInfo = (pathInfo) => {
    if (pathInfo) BASE_PATH_CONFIG = Object.assign({}, BASE_PATH_CONFIG, pathInfo);
}
