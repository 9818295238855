const messages = {
  notConnectDevice: {
    id: "gui.dialog.notConnectDevice",
    default: "not Connect Device"
  },
  notSelectDevice: {
    id: "gui.dialog.notSelectDevice",
    default: "No device is selected, please click on 'Extension' in the lower left corner of the software,</br>click to select the device you want to connect to"
  },
  prompt: {
    id: "gui.dialog.prompt",
    default: "Note"
  },
  openPort: {
    id: "gui.dialog.openPort",
    default: "Open Port"
  },
  error: {
    id: "gui.dialog.error",
    default: "Error"
  },
  connectDeviceError1: {
    id: "gui.dialog.connectDeviceError1",
    default: "Connect device("
  },
  connectDeviceError2: {
    id: "gui.dialog.connectDeviceError2",
    default: ")failed, Please check the following:</br> *Replace the usb port and re-plug the usb cable</br> *Check if it is connected"
  },
  connectDeviceError3: {
    id: "gui.dialog.connectDeviceError3",
    default: "Main Control Panel</br> * Join Official Communications Group (671877416) Feedback Questions"
  },
  calibration1: {
    id: "gui.dialog.calibration1",
    default: "</br> *Please turn"
  },
  calibration2: {
    id: "gui.dialog.calibration2",
    default: "Main control board for calibration</br>"
  },
  runAsManager: {
    id: "gui.dialog.runAsManager",
    default: "Please close Mind+ and right click on the software icon and select \"Run as administrator\". After starting, select this function again."
  },
  runErrorForVortex: {
    id: "gui.dialog.runErrorForVortex",
    default: "The system is not operating properly, exit Vortex handshaking"
  },
  runError: {
    id: "gui.dialog.runError",
    default: "The system is not operating normally"
  },
  close: {
    id: "gui.dialog.close",
    default: "close"
  },
  feedbackCannotEmpty: {
    id: 'gui.dialog.feedbackCannotEmpty',
    default: 'Please fill in the feedback.'
  },
  variableNameSpecialCharacters: {
    id: 'gui.dialog.variableNameSpecialCharacters',
    default: 'variable name %1 cannot contain special characters:[\`~!@#$%^&*()+<>?:"{},.\/;\'[]\\]'
  },
  funcNameSpecialCharacters: {
    id: 'gui.dialog.funcNameSpecialCharacters',
    default: 'function name %1 cannot contain special characters:[\`~!@#$%^&*()+<>?:"{},.\/;\'[]\\]'
  },
  funcArgsSpecialCharacters: {
    id: 'gui.dialog.funcArgsSpecialCharacters',
    default: 'function arguments %1 cannot contain special characters:[\`~!@#$%^&*()+<>?:"{},.\/;\'[]\\]'
  },
  and: {
    id: 'gui.dialog.and',
    default: 'and'
  },
  yes: {
    id: 'gui.dialog.yes',
    default: 'Yes'
  },
  no: {
    id: 'gui.dialog.no',
    default: 'No'
  },
  continue: {
    id: 'gui.dialog.continue',
    default: 'Continue'
  },
  maxReset: {
    id: 'gui.dialog.maxReset',
    default: 'Whether to delete the editing area code and load the factory setting code'
  },
  noInstallCompiler: {
    id: 'gui.dialog.noInstallCompiler',
    default: 'Not installed compiler'
  },
  install: {
    id: 'gui.dialog.install',
    default: 'install online'
  },
  cancle: {
    id: 'gui.dialog.cancle',
    default: 'cancle'
  },
  installingCompiler: {
    id: 'gui.dialog.installingCompiler',
    default: 'installing compiler'
  },
  uploading: {
    id: 'gui.dialog.uploading',
    default: 'uploading'
  },
  changeLanuage: {
    id: 'gui.dialog.changeLanuage',
    default: 'Switching the language will clear the current program, will it continue?'
  },
  boardNotSupportMpy: {
    id: 'gui.dialog.boardNotSupportMpy',
    default: 'current board: %1 not support micopython'
  },
  loadBlankItem: {
    id: 'gui.dialog.loadBlankItem',
    default: 'Unsupported item</br> is reloading a blank item'
  },
  switchBaudError: {
    id: 'gui.dialog.switchBaudError',
    default: 'error in switching baud rate</br>'
  },
  turnMicrobitCompass: {
    id: 'gui.dialog.turnMicrobitCompass',
    default: 'Need to calibrate the electronic compass, please adjust the attitude of mciro: bit to move the blinking LED lights until all LED lights are on'
  },
  turnCalliopeCompass: {
    id: 'gui.dialog.turnCalliopeCompass',
    default: 'Need to calibrate the electronic compass, please adjust the attitude of calliope to move the blinking LED lights until all LED lights are on'
  },
  operationShow: {
    id: 'gui.dialog.operationShow',
    default: 'Operational demonstration'
  },
  mpython: {
    id: 'gui.dialog.mpython',
    default: 'mpython'
  },
  computer: {
    id: 'gui.dialog.computer',
    default: 'computer'
  },
  delete: {
    id: 'gui.dialog.delete',
    default: 'delete'
  },
  sureDeleteFile: {
    id: 'gui.dialog.sureDeleteFile',
    default: 'Are you sure you want to delete the file \"%2\" on %1?'
  },
  sureDeleteFolder: {
    id: 'gui.dialog.sureDeleteFolder',
    default: 'Are you sure you want to delete the folder \"%2\" on %1?'
  },
  uncorrectPath: {
    id: 'gui.dialog.uncorrectPath',
    default: 'did not choose the correct path'
  },
  laterDownLoad: {
    id: 'gui.dialog.laterDownLoad',
    default: 'Download Later'
  },
  downLoadUpdate: {
    id: 'gui.dialog.downLoadUpdate',
    default: 'Download Update'
  },
  downLoading: {
    id: 'gui.dialog.downLoading',
    default: 'DownLoading...'
  },
  dwnLoadWebsite: {
    id: 'gui.dialog.dwnLoadWebsite',
    default: 'Download Website'
  },
  laterUpdate: {
    id: 'gui.dialog.laterUpdate',
    default: 'Install Later'
  },
  update: {
    id: 'gui.dialog.update',
    default: 'Install'
  },
  getUpdateMsgError: {
    id: 'gui.dialog.getUpdateMsgError',
    default: 'Error getting updated message'
  },
  ok: {
    id: 'gui.dialog.ok',
    default: 'Ok'
  },
  closeCodeBoxes: {
    id: 'gui.dialog.closeCodeBoxes',
    default: 'Are you sure you want to close %1 code boxes?'
  },
  emailError: {
    id: 'gui.dialog.emailError',
    default: 'E-mail format is incorrect!'
  },
  emailEmpty: {
    id: 'gui.dialog.emailEmpty',
    default: 'E-mail can not be empty!'
  },
  bigFilePrompt: {
    id: 'gui.dialog.bigFilePrompt',
    default: 'The file is a bit big, please be patient, thank you'
  },
  successFeedbackPrompt1: {
    id: 'gui.dialog.successFeedbackPrompt1',
    default: 'Thank you for your feedback, the page will reload after 3 seconds.'
  },
  successFeedbackPrompt2: {
    id: 'gui.dialog.successFeedbackPrompt2',
    default: 'Your feedback has been submitted. Please submit it in a few seconds.'
  },
  failedFeedbackPrompt: {
    id: 'gui.dialog.failedFeedbackPrompt',
    default: 'We are very sorry to submit the feedback, we are very sorry for the inconvenience caused, you can also add the official QQ exchange group for feedback, thank you'
  },
  successFeedbackPrompt3: {
    id: 'gui.dialog.successFeedbackPrompt3',
    default: 'thank you for your feedback'
  },
  forcedCloseSoftWare: {
    id: 'gui.dialog.forcedCloseSoftWare',
    default: 'An error occurred in saving the file. Force close the software?'
  },
  saveSuccess: {
    id: 'gui.dialog.saveSuccess',
    default: 'The project is saved'
  },
  noSelectTruePath: {
    id: 'gui.dialog.noSelectTruePath',
    default: 'did not select the correct path'
  },
  newItemContent: {
    id: 'gui.dialog.newItemContent',
    default: 'You will create a blank project to overwrite the current project, are you sure to create it?'
  },
  deleteBlocksError: {
    id: 'gui.dialog.deleteBlocksError',
    default: 'Failed to delete %1 block, please delete it manually'
  },
  netIsConnect: {
    id: 'gui.dialog.netIsConnect',
    default: 'Please check if the network is connected'
  },
  needToUpdate: {
    id: 'gui.dialog.needToUpdate',
    default: 'Do I need to update the compiler to the latest version: 1%'
  },
  cantUseMpythonInArduinoC: {
    id: 'gui.dialog.cantUseMpythonInArduinoC',
    default: 'No compiler installed, you can\'t use mpython under Arduino C.If the computer does not have a network, you can download the "Compiler offline plug-in package" on the official website.'
  },
  runHardware: {
    id: 'gui.dialog.runHardware',
    default: 'Connect the device first to manipulate the hardware'
  },
  sureDelete: {
    id: 'gui.dialog.sureDelete',
    default: 'Are you sure you want to delete this?'
  },
  notSupportWebGL: {
    id: 'gui.dialog.notSupportWebGL',
    default: 'There is an error loading the stage, it may not support WebGL,</br> The following is a detailed error message: </br> %1 </br>QQ group contact us: 671877416'
  },
  boardIsnotMPython: {
    id: 'gui.dialog.boardIsnotMPython',
    default: 'The current connection main control board is not mPython, please connect to mPython'
  },
  swdMicrobitUpload: {
    id: 'gui.dialog.swdMicrobitUpload',
    default: 'Your computer must use the SWD to burn micro:bit, please run Mind+ with administrator privileges.'
  },
  openPortError: {
    id: 'gui.dialog.openPortError',
    default: 'open port %1 error: %2'
  },
  pressedA: {
    id: 'gui.dialog.pressedA',
    default: 'Please press and hold button A, do not release'
  },
  unpressedA: {
    id: 'gui.dialog.unpressedA',
    default: 'Please unpressed A'
  },
  unsupportProject: {
    id: 'gui.dialog.unsupportProject',
    default: 'Unsupported item</br> is reloading a blank item'
  },
  openProjectError: {
    id: 'gui.dialog.openProjectError',
    default: 'Projects in Scratch mode must be opened in scratch mode</br> Please check if the current interface is in scratch mode or arduino mode'
  },
  installCompilerError1: {
    id: 'gui.dialog.installCompilerError1',
    default: 'The compiler failed to download, you can download the offline installer or try to download again'
  },
  installCompilerOffline: {
    id: 'gui.dialog.installCompilerOffline',
    default: 'Install Compiler offline'
  },
  installCompilerError2: {
    id: 'gui.dialog.installCompilerError2',
    default: 'Verification error, you can download the offline installer or try to download again'
  },
  installCompilerError3: {
    id: 'gui.dialog.installCompilerError3',
    default: 'Installation error, you can download the offline installer or try to download again'
  },
  installCompilerSuccess: {
    id: 'gui.dialog.installCompilerSuccess',
    default: 'The compiler has been installed, you can use Esp32 under ArduinoC'
  },
  sureAdapter: {
    id: 'gui.dialog.sureAdapter',
    default: 'The adapter was not found, please confirm whether the adapter is connected'
  },
  checkPort: {
    id: 'gui.dialog.checkPort',
    default: 'Failed to open the serial port, please check if the serial port is occupied.'
  },
  isSaveProject: {
    id: 'gui.dialog.isSaveProject',
    default: 'Whether to save the current project'
  },
  save: {
    id: 'gui.dialog.save',
    default: 'Save'
  },
  notSave: {
    id: 'gui.dialog.notSave',
    default: 'Not Save'
  },
  cancelSave: {
    id: 'gui.dialog.cancelSave',
    default: 'Cancel Save'
  },
  saveMPProjectToSb3: {
    id: 'gui.dialog.saveMPProjectToSb3',
    default: 'The currently selected format is .sb3, only some basic building blocks in real-time mode are saved, do you want to continue saving?'
  },
  changeBoard: {
    id: 'gui.dialog.changeBoard',
    default: 'Switching board will clear the current program and continue'
  },
  variableIsNull: {
    id: 'gui.dialog.variableIsNull',
    default: 'Variable name cannot be empty'
  },
  listNameIsNull: {
    id: 'gui.dialog.listNameIsNull',
    default: 'list name cannot be empty'
  },
  variableIsRepeat: {
    id: 'gui.dialog.variableIsRepeat',
    default: 'variable names cannot be repeated, please modify'
  },
  pictureAINotNull: {
    id: 'gui.dialog.pictureAINotNull',
    default: 'cannot be null,a'
  },
  pictureAIInitServer: {
    id: 'gui.dialog.pictureAIInitServer',
    default: 'please initialize the server first'
  },
  pictureAIInitServerError: {
    id: 'gui.dialog.pictureAIInitServerError',
    default: 'Error initializing the server, please check the account number and secret key'
  },
  versionPrompt: {
    id: 'gui.dialog.versionPrompt',
    default: 'current sb3 file version「%1」'
  },
  versionGreater: {
    id: 'gui.dialog.versionGreater',
    default: 'Sb3 file version is higher than current software version, sb3 file version「%1」'
  },
  enterMpyMode: {
    id: 'gui.dialog.enterMpyMode',
    default: 'enter micropython mode'
  },
  noModulePrompt: {
    id: 'gui.dialog.noModulePrompt',
    default: 'There are no small modules under the current classification. See help for details.'
  },
  firstSelectBoard: {
    id: 'gui.dialog.firstSelectBoard',
    default: 'Please select "Board" or "Kit" first.'
  },
  downloadFileError: {
    id: 'gui.dialog.downloadFileError',
    default: 'Download file error'
  },
  noSearchModules: {
    id: 'gui.dialog.noSearchModules',
    default: 'No related modules found, please check the search path'
  },
  networkError: {
    id: 'gui.dialog.networkError',
    default: 'Network error'
  },
  saveThirdModulesError: {
    id: 'gui.dialog.saveThirdModulesError',
    default: 'Error saving user library'
  },
  coverUseExForLoadProject: {
    id: 'gui.dialog.coverUseExForLoadProject',
    default: 'A library with the same name exists in the user library, which has been replaced by the user library in the project'
  },
  isContinueUpdateThirdModule: {
    id: 'gui.dialog.isContinueUpdateThirdModule',
    default: 'There are currently the same user extension libraries. Continue to update will overwrite the current user library. Do you want to continue?'
  },
  alterFieldsAndCancelRefresh: {
    id: 'gui.dialog.thirdex.alterFieldsAndCancelRefresh',
    default: 'Modified the field 「%2」 of %1, which needs to be manually import in the extension library. This refresh is canceled'
  },
  parseErrorAndNotRefresh: {
    id: 'gui.dialog.thirdex.parseErrorAndNotRefresh',
    default: 'Error parsing %1, this refresh will continue, but the content in 「%2」 will not be updated'
  },
  blockAlterAndCancleRefresh: {
    id: 'gui.dialog.thirdex.blockAlterAndCancleRefresh',
    default: 'The shape of the building block or the type of the input box is modified. Please manually delete the highlighted building block in the programming area, and then refresh it. This refresh is cancelled.'
  },
  blockAlterAndCancleRefresh1: {
    id: 'gui.dialog.thirdex.blockAlterAndCancleRefresh1',
    default: 'To modify the shape of the block or the input box type, please manually delete the highlighted block in the programming area and check the sprite「%1」before refreshing.'
  },
  blockAlterAndCancleRefresh2: {
    id: 'gui.dialog.thirdex.blockAlterAndCancleRefresh2',
    default: 'The shape of the block or the type of the input box is modified. Please check the sprite「%1」and refresh it again. This refresh is canceled.'
  },
  filesEmpty: {
    id: 'gui.dialog.thirdex.filesEmpty',
    default: 'The files field cannot be empty, which may cause related files to be lost. Please check the imported user library files!'
  },
  blockAlterAndCancleLoad: {
    id: 'gui.dialog.thirdex.blockAlterAndCancleLoad',
    default: 'Modification of the shape of the building block or the type of the input box, please manually delete the highlighted building block in the programming area before loading, and the loading is canceled.'
  },
  blockAlterAndCancleLoad1: {
    id: 'gui.dialog.thirdex.blockAlterAndCancleLoad1',
    default: 'To modify the shape of the block or the type of the input box, please manually delete the highlighted block in the programming area and check the sprite「%1」before loading, the loading is canceled this time.'
  },
  blockAlterAndCancleLoad2: {
    id: 'gui.dialog.thirdex.blockAlterAndCancleLoad2',
    default: 'The shape of the block or the input box type is modified. Please check the sprite「%1」and load it again. This loading is canceled.'
  },
  refreshing: {
    id: 'gui.dialog.thirdex.refreshing',
    default: 'refreshing...'
  },
  refreshSuccess: {
    id: 'gui.dialog.thirdex.refreshSuccess',
    default: 'refresh success'
  },
  isSaving: {
    id: 'gui.dialog.isSaving',
    default: 'Saving...'
  },
  mpySb3Prompt: {
    id: 'gui.dialog.mpySb3Prompt',
    default: '「%1」has updated the micropython mode. If there is any error in the content of the project, you need to manually modify it'
  },
  isExporting: {
    id: 'gui.dialog.isExporting',
    default: 'Exporting...'
  },
  fieldError: {
    id: 'gui.dialog.translate.fieldError',
    default: 'The following error fields may cause errors in the display of building blocks'
  },
  startReadFile: {
    id: 'gui.dialog.translate.startReadFile',
    default: 'Start reading files, please be patient...'
  },
  fileFormatError: {
    id: 'gui.dialog.translate.fileFormatError',
    default: 'There is no relevant translation file content, the file content format does not match'
  },
  serialDriverInstalling: "gui.dialog.serialDriverInstalling",
  resetDeviceSuccess: "gui.dialog.resetDeviceSuccess",
  resetDeviceFailed: "gui.dialog.resetDeviceFailed",
  exportSuccess: "gui.dialog.exportSuccess",
  exportFailed: "gui.dialog.exportFailed",
  exportCostume: "gui.dialog.exportCostume",
  knownError: "gui.dialog.knownError", //已知问题：
  unknownError: "gui.dialog.unknownError", //未知问题：
  cancelInstall: "gui.dialog.cancelInstall",  //取消安装
  cannotFindDriver: "gui.dialog.cannotFindDriver",  // 没有找到驱动文件，请检查路径:
  programError: "gui.dialog.programError",  // 程序出错
  pleaseHandleInstall: "gui.dialog.pleaseHandleInstall",  // 请手动安装工具：
  installSerialDriverError: "gui.dialog.installSerialDriverError",  // 安装串口驱动出错
  installSerialDriverSuccess: "gui.dialog.installSerialDriverSuccess",  // 串口驱动安装完成
  hadInstall: "gui.dialog.hadInstall",  // 已安装

  loadProjectError: "gui.dialog.loadProjectError", //加载项目出错
  readFileError: "gui.dialog.readFileError", //读取文件出错
  projectIsNotExist: "gui.dialog.projectIsNotExist", //打开项目失败，文件不存在
  projectIsDeleted: "gui.dialog.projectIsDeleted",  //项目已经被删除  
  checkNetwork: "gui.dialog.checkNetwork",//当前网络不通，请检查电脑是否能上网
  checkIotNetworkAndParameter: "gui.dialog.checkIotNetworkAndParameter", //物联网连接失败，请检查参数是否填写正确
  sameAsCacheDeleteBlock: "gui.dialog.cache.sameAsCacheDeleteBlock", //缓存界面有相同的用户库，将会删除相关的积木块，是否继续
  saveAs: "gui.dialog.cache.saveAs", //另存为
  exportExtError: "gui.dialog.cache.exportExtError", // 导出扩展出错：
  exportExtSuccess: "gui.dialog.cache.exportExtSuccess", // 导出扩展成功
  lostVersionKeyWithThird: "gui.dialog.thirdExt.lostVersionKeyWithThird", //`缺少version字段，请检查version字段，应该与id字段同级`
  notFoundRelatedFile: "gui.dialog.thirdExt.notFoundRelatedFile", //没有找到相关的文件
  requestFileError: "gui.dialog.thirdExt.requestFileError",  //请求文件出错
  unzipLibrariesFailed: "gui.dialog.thirdExt.unzipLibrariesFailed",  //解压libraries.zip失败
  pipreqsLostAndFillConfig: "gui.dialog.thirdExt.pipreqsLostAndFillConfig",  //pipreqs 库未正确安装，自动导出依赖包清单出错。为方便用户使用，请手动在config.json中配置依赖库信息
  exportExtToFolderSuccess: "gui.dialog.thirdExt.exportExtToFolderSuccess",  //成功导出扩展到目录
  configJsonKeyLost: "gui.dialog.thirdExt.configJsonKeyLost",  //'config.json中的[KEY]字段不能为空！'
  configJsonKeyLost_ID: "gui.dialog.thirdExt.configJsonKeyLost_ID",  //config.json中的id是该模块的标识，不能为空！
  configJsonKeyLost_platform: "gui.dialog.thirdExt.configJsonKeyLost_platform",  //platform定义支持的平台，至少支持一个平台（win,mac,web）！目前只支持win（windowns）平台
  configJsonKeyLost_asset: "gui.dialog.thirdExt.configJsonKeyLost_asset",  //config.json中的asset定义支持的语言模式，不能为空！
  configJsonError_asset: "gui.dialog.thirdExt.configJsonError_asset",  //config.json中的asset内容定义错误，至少支持一个语言模式
  configJsonLost_version: "gui.dialog.thirdExt.configJsonLost_version",  //缺少version字段，请检查version字段，应该与id字段同级
  configJsonLost_asset_mode: "gui.dialog.thirdExt.configJsonLost_asset_mode",  //config.json中的asset语言模式[MODE]的字段main不能为空！
  configJsonError_mode_board: "gui.dialog.thirdExt.configJsonError_mode_board",  //语言模式[MODE]的字段board定义支持的主板情况，至少支持一个主板
  microphoneLost: "gui.dialog.stage.microphoneLost",  //没有听到任何声音，请检查麦克风是否插好哦
  closeMl5Modal: "gui.dialog.ml5.closeMl5Modal",  //关闭窗口会丢失训练中的数据，请及时保存，是否继续关闭
  restartMl5Train: "gui.dialog.ml5.restartMl5Train",  //该操作会删除所有分类，确认继续吗?
  ml5DefaultLabel: "gui.dialog.ml5.ml5DefaultLabel",  //分类1


  getCloudListError: "gui.dialog.account.getCloudListError",  //获取文件列表出错，是否重新加载
  requestCloudList: "gui.dialog.account.requestCloudList",  //重新加载

  getPIPListForUpdateError: "gui.dialog.python.getPIPListForUpdateError",//获取PIP更新数据出错
  pipHasNoUpdate: "gui.dialog.python.pipHasNoUpdate",  // 无可用更新
  pipUpdateSucess: "gui.dialog.python.pipUpdateSucess",  // 成功升级[SUCCESS]个，[FAILED]个失败，分别为[INFO]
  pipUpdateAllSucess: "gui.dialog.python.pipUpdateAllSucess",  // 升级成功，共[SUCCESS]个
  pipUpdateError: "gui.dialog.python.pipUpdateError",  // 升级[NAME]失败，详情可在PIP模式页面查看    
  pipInstallError: "gui.dialog.python.pipInstallError",  // 安装 [NAME] [VERSION]失败，详情可在PIP模式页面查看
  pipUninstallSucess: "gui.dialog.python.pipUninstallSucess",  // 成功卸载[SUCCESS]个，[FAILED]个失败，分别为[INFO]
  pipUninstallAllSucess: "gui.dialog.python.pipUninstallAllSucess",  // 卸载成功，共[COUNT]个
  pipUninstallError: "gui.dialog.python.pipUninstallError",  // 卸载[NAME]失败，详情可在PIP模式页面查看
  rebootSoftForVsEditor: "gui.dialog.python.rebootSoftForVsEditor", // 代码编辑器相关功能的翻译需重启后生效

  procedureNameIsRepeated: "gui.dialog.procedure.procedureNameIsRepeated", // 函数「[NAME]」已定义，请重新命名`
  procedureNameIsEmpty: "gui.dialog.procedure.procedureNameIsEmpty", //函数名称不能为空,
  saveProjectCheck: "gui.dialog.project.saveProjectCheck", //二次校验
  trySaveProjectAgain: "gui.dialog.project.trySaveProjectAgain", //抱歉，保存的文件解析出错了，请尝试重新保存

  secondInstanceTips: "gui.dialog.software.secondInstanceTips", //当前软件设置为不允许多开，如需修改请到 [设置-显示-软件多开] 更新设置
  saveFailed: "gui.dialog.project.saveFailed", // 保存出错
  saveMPProjectToSb3: "gui.dialog.pythonRemote.saveMPProjectToSb3", //当前选择格式为.sb3，仅保存实时模式下的部分基础积木，是否继续保存?


  downloadNow: "gui.dialog.thirdExt.downloadNow", //立即下载
  downloadingLib: "gui.dialog.thirdExt.downloadingLib", //正在下载
  downloadFailed: "gui.dialog.thirdExt.downloadFailed", //下载失败
  detectThirdExtLibrariesInfo: "gui.dialog.thirdExt.detectThirdExtLibrariesInfo", //检测到该用户库需要[NEED]个依赖库，[INSTALLED]个已存在，是否立即下载其余的[REST]个？
  downloadFinishFailed: "gui.dialog.thirdExt.downloadFinishFailed",// 下载[LENGTH]个库失败，为[INFO]，具体信息请至库管理查看
  downloadFinishSuccess: "gui.dialog.thirdExt.downloadFinishSuccess",// [LENGTH]个库下载成功, 具体信息请至库管理查看
  detectingRemoteLibraries: "gui.dialog.pythonRemote.detectingRemoteLibraries",// "正在检测[NAME]上的依赖库版本，请稍候..."
  detectRemoteLibrariesFinish: "gui.dialog.pythonRemote.detectRemoteLibrariesFinish",// "检测完成，全部为新版本"
  detectRemoteLibrariesInfo: "gui.dialog.pythonRemote.detectRemoteLibrariesInfo", //检测到<b>[NAME]</b>上有[EXT]等[LENGTH]个库可以升级，是否立即下载？`,
  detectRemotePipError: "gui.dialog.pythonRemote.detectRemotePipError", //远程系统中PIP不可用，请确认Python环境存在后再使用库管理功能"
};

export default messages;
