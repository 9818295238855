import styles from './WaveLoader.module.scss'

type Props = {
    itemCount?: number
    loadingText?: string
};
export const WaveLoader = ({itemCount = 3, loadingText}: Props) => {
    return (
        <div className={styles.container}>

            <div className={styles.text}>{loadingText}</div>
            <div className={styles.loaderContainer}>
                {
                    new Array(itemCount).fill('').map((_, index) => {
                        const delay = index * 0.1
                        return <div className={styles.loaderBar} key={index}
                                    style={{animationDelay: `${delay}s`}}></div>
                    })
                }
            </div>
        </div>
    );
};
