import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

// visible
const initialState = false;

export const micIndicatorSlice = createSlice({
    name: 'micIndicator',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        updateMicIndicator: (state, action: PayloadAction<boolean>) => {
            return action.payload;
        }
    },
})

export const {updateMicIndicator} = micIndicatorSlice.actions;
export const selectMicIndicator = (state: RootState) => state.micIndicator

export default micIndicatorSlice.reducer
