const RenderedTarget = require('../sprites/rendered-target');
const StringUtil = require("../util/string-util");

class DFRenderedTarget extends RenderedTarget{
    constructor(sprite, runtime) {
        super(sprite, runtime);
    }

    toJSON () {
        const costumes = this.getCostumes();
        return {
            id: this.id,
            name: this.getName(),
            isStage: this.isStage,
            x: this.x,
            y: this.y,
            size: this.size,
            direction: this.direction,
            draggable: this.draggable,
            currentCostume: this.currentCostume,
            costume: costumes[this.currentCostume],
            costumeCount: costumes.length,
            visible: this.isDevice?false:this.visible, // 设备不显示角色图片
            rotationStyle: this.rotationStyle,
            comments: this.comments,
            blocks: this.blocks._blocks,
            variables: this.variables,
            costumes: costumes,
            sounds: this.getSounds(),
            textToSpeechLanguage: this.textToSpeechLanguage,
            tempo: this.tempo,
            volume: this.volume,
            videoTransparency: this.videoTransparency,
            videoState: this.videoState,
            // 设备target属性
            isDevice: this.isDevice,
            deviceId: this.deviceId,
            version: this.version
        };
    }

    addSound (soundObject, index) {
        const usedNames = this.sprite.sounds.map(sound => sound.name);
        const soundObjectCopy = Object.assign({},soundObject)
        soundObjectCopy.name = StringUtil.unusedName(soundObjectCopy.name, usedNames);
        if (typeof index === 'number' && !isNaN(index)) {
            this.sprite.sounds.splice(index, 0, soundObjectCopy);
        } else {
            this.sprite.sounds.push(soundObjectCopy);
        }
    }
}

module.exports = DFRenderedTarget;
