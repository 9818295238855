import { commandWs } from "./websocket/commandWs";

export function resolve(...args:string []) {
  return commandWs.sendRemoteRequest("resolve", { path: args });
}

export function dirname(dirname: string) {
  return commandWs.sendRemoteRequest("dirname", { dirname });
}

export default {
  resolve,
  dirname,
};
