import { base64ToStr, strToBase64 } from "util/base64Chinese";
import JSONRPC from "./jsonrpc";
import LinkWebSocket from "./link-websocket";
import { message } from "antd";

class UdpWs extends JSONRPC {
  ws: LinkWebSocket;
  constructor() {
    super();
    this.ws = new LinkWebSocket("UDP");
    this.ws.setOnOpen(this._onWsOpen.bind(this));
    this.ws.setOnClose(this._onWsClose.bind(this));
    this.ws.setOnError(this._onWsError.bind(this));
    this.ws.setHandleMessage(this._handleMessage.bind(this));
    // 重写jsonrpc的方法
    this._sendMessage = this.ws.sendMessage.bind(this.ws);
    this.open();
  }

  sendRemoteRequest(method: string, params?: any) {
    if (!this.isOpen()) return Promise.reject("websocket not connected");
    return super.sendRemoteRequest(method, params);
  }

  sendRemoteNotification(method: string, params?: any) {
    if (!this.isOpen()) return;
    super.sendRemoteNotification(method, params);
  }

  // 连接command ws
  open() {
    this.ws.open();
  }

  close() {
    this.ws.close();
  }

  isOpen() {
    return this.ws.isOpen();
  }

  _onWsOpen() {
    this.emit("wsOpen");
  }

  _onWsClose() {
    this.emit("close");
  }

  _onWsError(err) {
    this.emit("error", err.toString());
  }

  // 重写jsonrpc的方法
  // 这里是处理 下位机-->上位机 的request, 不是response
  didReceiveCall(method, params) {
    // 子进程的消息上报
    switch (method) {
      case "error":
        this.emit("error", params);
        break;
      case "open":
        console.log("触发open");
        this.emit("open");
        break;
      case "close":
        this.emit("close");
        break;
      case "message":
        this.emit("message",{message:base64ToStr(params.message),rinfo:params.rinfo});
        break;
    }
  }

  createSocket(type: string) {
    this.sendRemoteNotification("createSocket", type);
  }

  bindPort(port: number, ip?: string) {
    return this.sendRemoteRequest("bindPort", { port, ip });
  }

  setBroadcast(brodcast: boolean) {
    this.sendRemoteNotification("setBroadcast", brodcast);
  }

  sendUdpMessage(options: any) {
    this.sendRemoteNotification("sendUdpMessage", options);
  }

  closeUdp() {
    return this.sendRemoteRequest("closeUdp", undefined);
  }
}

export default UdpWs;
