import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {UserInfo} from "service/api/user-api"
import { getToken } from "service/api/token";

const TOKEN_KEY = 'Access_JWT::token';
const USER_DETAILS_KEY = 'User::info';

interface LoginState {
  token?: string
}

type IntlState = LoginState & Partial<UserInfo>


// Define the initial state using that type
const initialState: IntlState = {
  token: getToken()
};

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<IntlState>) => {
      if (action.payload.username) state.username = action.payload.username
      if (action.payload.thumbnailUrl) state.thumbnailUrl = action.payload.thumbnailUrl
      if (action.payload.token) state.token = action.payload.token
    },
    clearUserInfo: (state) => {
      state.username = ""
      state.thumbnailUrl = ""
      state.token = ""
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user;

export const { setUserInfo, clearUserInfo } = userSlice.actions;
export default userSlice.reducer;
