// @flow
import * as React from 'react';
import styles from './DragLayer.module.scss'
import {useSelector} from "react-redux";
import {selectAssetDrag} from "redux/asset-drag/assetDragSlice";

type Props = {

};
export const DragLayer = (props: Props) => {
    const {dragging, currentOffset, img} = useSelector(selectAssetDrag);
    if (!currentOffset||!img||!dragging) return null;
    return (
        <div className={styles.container}>
            <div
                className={styles.dragging}
                style={{
                    transform: `translate(${(currentOffset as any).x}px, ${(currentOffset as any).y}px)`
                }}
            >
                <img src={img!}/>
            </div>
        </div>
    );
};
