import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './DFCameraModal.module.scss';
import classNames from 'classnames';
import downloadIcon from './download.png';
import hiddenIcon from './hidden.png';
import toggleIcon from './rotate.png';
import closeIcon from './close.png';
import { Spin } from 'antd';
import { vm } from "lib/scratch-vm";
import { useIntl } from "react-intl";
import { flushSync } from 'react-dom';

const messagesAlert = {
  error: {
    id: "gui.dialog.error",
    default: "Error"
  }
}

const tipsInfo = {
  defaultTitleText: {
    id: "gui.extension.pictureai.defaultTitleText",
    description: "Name for the 'HuskyLens AI Camera' extension",
    default: "Video window",
  },
  noVideo: {
    id: "gui.blocklyText.pictureai.noVideo",
    description: 'video',
    default: 'please make sure the camera is connected properly or restart software'
  }
};


type ListenerCallback = () => void;

interface EventListenerObject {
  [key: string]: ListenerCallback[];
}

const alertObj = { mode: 1, zIndex: 9999, timeout: 4000 };


const DFCameraModal: React.FC = () => {

  const [videoOpen, setVideoOpen] = useState<boolean>(false); // 是否打开摄像头
  const [isOpenWithPopup, setIsOpenWithPopup] = useState<boolean>(true); // 弹框形式下是否打开摄像头
  const [modalShow, setModalShow] = useState<boolean>(false); // 弹窗组件显示状态
  const [openPreview, setOpenPreview] = useState<boolean>(false); // 显示/关闭预览元素
  const [pauseVideo, setPauseVideo] = useState<boolean>(false); // 视频是否暂停
  const [modalTitle, setModalTitle] = useState<string>("视频窗口"); // 弹窗组件标题
  const [previewImg, setPreviewImg] = useState<string>(""); // 预览中的图片
  const [previewResult, setPreviewResult] = useState<string>(""); // 预览中的文字
  const [imgPreviewScale, setImgPreviewScale] = useState<boolean>(false); // 图片预览放大
  const [togglePosition, setTogglePosition] = useState<boolean>(false); // 截图和摄像头交换窗口
  const [loading, setLoading] = useState<boolean>(false); // 是否加载中
  const [loadingTips, setLoadingTips] = useState<string>("加载中..."); // 加载文字
  const [eventListeners, setEventListeners] = useState<EventListenerObject>({}); // 该组件绑定的事件
  const videoTransparencyRef = useRef<number>(0); // 摄像头视频透明度
  const imgScaleTimerRef = useRef<NodeJS.Timeout>(); // 图片预览放大
  const videoInstanceRef = useRef<HTMLVideoElement | null>(); // 视频video实例
  const videoStreamRef = useRef<any>(); // 视频流
  const mediaStreamTrackRef = useRef<any>(); // 媒体流跟踪
  const videoTimerRef = useRef<any>(); // 连接视频定时器
  const videoOpenState = useRef<string>("off"); // 摄像头展示的状态（开/关/镜像）
  const chooseCameraId = useRef<string>(""); // 选中的摄像头id
  const openPreviewRef = useRef<boolean>(false); // 预览元素是否显示
  const modalShowRef = useRef<boolean>(false); 

  const intl = useIntl();


  const attachRuntime = () => {
    let pictureAIComponent = {
      comShow: (title?: string) => openVideo(title),
      comHide: (callback: () => void) => closeModal(callback),
      videoHide: () => videoHide(),
      comPreShow: (type: string) => { comPreShow(type) },
      comChangeHtml: (img: string | null, result: string | null) => updatePreviewContent(img, result),
      getPlayContainer: () => { return getPlayContainer() },
      getVideoImageData: () => { return getShortCut() },
      setTitleText: (text: string) => { setModalTitle(text) },
      setLoading: (loading: boolean, text: string) => { setLoading(loading); setLoadingTips(text) },
      getVideoInstance: () => { return videoInstanceRef.current },
      storageImageAnimation: () => { storageImageAnimation() },
      popupVideo: (state: string, id?: string) => { return popupVideo(state, id) },
      getVideoState: () => { return videoOpenState },
      setVideoTransparent: (value: number) => { setVideoTransparency(value) },
      // 其余地方绑定回调
      attachMethod: (name: string, callback) => {
        vm.runtime.pictureAIComponent[name] = callback;
      },
      on: (key: string, callback: ListenerCallback) => { on(key, callback) },
      off: (key: string, callback: ListenerCallback) => { off(key, callback) },
      resetAll: () => {
        setIsOpenWithPopup(true);
        setModalShow(false);
        modalShowRef.current = false;
        openPreviewRef.current = false;
        setOpenPreview(false);
        setPauseVideo(false);
        setModalTitle(intl.formatMessage(tipsInfo.defaultTitleText));
        setPreviewImg("");
        setPreviewResult("");
        setImgPreviewScale(false);
        setTogglePosition(false);
        setLoading(false)
        setEventListeners({});
        videoInstanceRef.current = null;
        videoStreamRef.current = null;
        mediaStreamTrackRef.current = null;
        videoTimerRef.current = null;
        videoOpenState.current = "off";
        chooseCameraId.current = "";
        setImgPreviewScale(false);
        setLoading(false);
        setLoadingTips("加载中...");
        removeListener();
      }
    };
    vm.runtime.attachMethod("pictureAIComponent", pictureAIComponent);
  }

  // 绑定事件监听
  const on = (key: string, callback: ListenerCallback) => {
    setEventListeners(prevEventListeners => {
      // 如果该key已存在，则在原有数组基础上添加新函数
      if (prevEventListeners[key]) {
        return {
          ...prevEventListeners,
          [key]: [...prevEventListeners[key], callback]
        };
      }
      // 否则，为该key创建一个新的数组并添加新函数
      return {
        ...prevEventListeners,
        [key]: [callback]
      };
    });
  };

  const off = (key: string, callback: ListenerCallback) => {
    setEventListeners(prevEventListeners => {
      // 首先检查key是否存在
      if (prevEventListeners[key]) {
        // 创建一个新数组，过滤掉与callback严格相等的函数
        const updatedFunctions = prevEventListeners[key].filter(func => func !== callback);
        // 如果过滤后的数组为空，说明所有匹配的函数都被移除了，可以从eventListeners中删除这个key
        if (updatedFunctions.length === 0) {
          const { [key]: removedKey, ...rest } = prevEventListeners;
          return rest;
        }
        // 否则，使用过滤后的数组更新key对应的值
        return {
          ...prevEventListeners,
          [key]: updatedFunctions
        };
      }
      // 如果key不存在，直接返回原状态
      return prevEventListeners;
    });
  }

  const removeListener = (key?: string) => {
    setEventListeners(prevEventListeners => {
      // 如果key存在，则清空该key对应的数组
      if (key !== undefined && prevEventListeners.hasOwnProperty(key)) {
        const { [key]: _, ...rest } = prevEventListeners; // 使用解构赋值移除指定key
        return rest; // 返回不包含指定key的新对象
      }
      // 如果key未提供或不存在，重置为一个空对象
      return {};
    });
  }

  const emmit = (key: string) => {
    const functions = eventListeners[key];
    if (functions && Array.isArray(functions)) {
      functions.forEach(func => {
        if (typeof func === 'function') {
          func();
        }
      });
    } else {
      console.warn(`No functions found for key "${key}"`);
    }
  }

  const videoHide = () => {
    if (openPreviewRef.current) {
      setVideoOpen(false)
    } else {
      setModalShow(false) 
      modalShowRef.current = false;
    };
  }

  // 点击右上角图标关闭弹窗
  const closeModal = (callback?: any) => {
    setIsOpenWithPopup(false);
    setModalShow(false);
    modalShowRef.current = false;
    setTogglePosition(false);
    popupVideo('off');
    if (callback) callback();
    emmit("close");
    removeListener();
  }

  // 以弹框形式打开视频（非舞台模式）
  const openVideo = (title?: string) => {
    setIsOpenWithPopup(true);
    setVideoOpen(true);
    if (modalShowRef.current) return;
    flushSync(() => {
      setModalShow(true);
      modalShowRef.current = true;
      setModalTitle(title || "");
      setTogglePosition(false);
      setPreviewResult("");
      setOpenPreview(false);
      openPreviewRef.current = false;
    })
    let facePopEle = document.getElementById('facePop');
    if (!facePopEle) return;
    facePopEle.style.transform = 'translate(0px)';
    facePopEle.style.top = (window.innerHeight - facePopEle.clientHeight) / 2 + 'px';
    facePopEle.style.left = (window.innerWidth - facePopEle.clientWidth) / 2 + 'px';

  }
  // 设置画面透明度
  const setVideoTransparency = (value: number) => {
    value = Math.round(value);
    value = value > 100 ? 100 : (value < 0 ? 0 : value);
    videoTransparencyRef.current = (value / 100);
  }

  /**
   * 根据传入的显示状态和选择的摄像头ID，打开或关闭视频
   * 处理摄像头切换逻辑，以及错误处理和重试机制
   */
  const popupVideo = (state: string, id?: string) => {
    var videoId = id || vm.runtime.ioDevices.video.getCameraList()[0][1];
    let cameraAutoConnect = 0;
    let openCamera = (videoId?: string) => {
      let setting = {
        video: videoId ? ({ deviceId: { exact: videoId } }) : true,
        width: 480,
        height: 360,
        audio: false,
      };
      return new Promise((resolve, reject) => {
        navigator.mediaDevices && navigator.mediaDevices.getUserMedia(setting)
          .then(function (stream) {
            if (mediaStreamTrackRef.current) mediaStreamTrackRef.current.stop();
            videoStreamRef.current = stream;
            dealVideoStream(state);
            setTimeout(() => resolve(""), 2000);
            cameraAutoConnect = 0;
            chooseCameraId.current = videoId || "";
          })
          .catch(function (err) {
            // 自动重连十次
            cameraAutoConnect = cameraAutoConnect || 0;
            reject(err);
            if (setting && cameraAutoConnect < 10) {
              openCamera();
            } else {
              closeModal();
              if (mediaStreamTrackRef.current) {
                mediaStreamTrackRef.current.stop();
                mediaStreamTrackRef.current = false;
              }
              if (videoTimerRef.current) clearInterval(videoTimerRef.current);
              vm.runtime.dfrobotAlert(intl.formatMessage(messagesAlert.error), intl.formatMessage(tipsInfo.noVideo), alertObj);
            }
            cameraAutoConnect += 1;
            console.log(err);
          });
      })
    }

    return new Promise((resolve, reject) => {
      if (state != 'off' && state == videoOpenState.current && !id) {
        resolve("");
        return;
      }
      if (state != 'off' && (videoOpenState.current != state || videoId != chooseCameraId.current)) {
        resolve(openCamera(videoId));
      } else if (state != 'off') {
        resolve(dealVideoStream(state));
      } else if (state == 'off') {
        videoInstanceRef.current = null;
        videoHide();
        if (mediaStreamTrackRef.current) {
          mediaStreamTrackRef.current.stop();
          mediaStreamTrackRef.current = false;
        }
        if (videoTimerRef.current) clearInterval(videoTimerRef.current);
        videoOpenState.current = state;
      }
      resolve("");
    })
  }

  // 处理视频流的显示逻辑
  const dealVideoStream = (state: string) => {
    videoInstanceRef.current = videoInstanceRef.current || document.createElement("video");

    let video = videoInstanceRef.current;
    let stream = videoStreamRef.current;

    if (video && stream) {
      mediaStreamTrackRef.current = typeof stream.stop === 'function' ? stream : stream.getTracks()[0];
      video.srcObject = stream;
      video.play();
      // 将摄像头内容绘制到canvas
      openVideo();
      videoOpenState.current = state;
      let videoCanvas = getPlayContainer();
      if (!videoCanvas) {
        console.log('find canvas error');
        return;
      }
      let videoCanvas_context = videoCanvas.getContext('2d');
      video.width = videoCanvas.width;
      video.height = videoCanvas.height;
      // 每次先重置回去，再开启镜像，避免镜像一直切换
      if (!videoCanvas_context) return;
      videoCanvas_context.scale(1, 1);
      videoCanvas_context.setTransform(1, 0, 0, 1, 0, 0);
      if (state != "on-flipped") {
        videoCanvas_context.scale(-1, 1);
        videoCanvas_context.translate(videoCanvas.width * -1, 0);
      }
      if (videoTimerRef.current) clearInterval(videoTimerRef.current);
      // videoCanvas_context.drawImage(video, 0, 0, 480, 360);
      videoTimerRef.current = setInterval(() => {
        // 判断摄像头是否存在
        let cameraIdExist = vm.runtime.ioDevices.video.getCameraList().join().match(chooseCameraId.current);
        if (video && cameraIdExist && videoCanvas_context) {
          // 设置透明度
          !pauseVideo && videoCanvas_context.drawImage(video, 0, 0, 480, 360);
          videoCanvas_context.fillStyle = "rgba(255, 255, 255," + videoTransparencyRef.current + ")";
          videoCanvas_context.fillRect(0, 0, 480, 360);
          // 白色遮罩模拟透明度效果
        } else {
          clearInterval(videoTimerRef.current);
          popupVideo('off');
          vm.runtime.dfrobotAlert(intl.formatMessage(messagesAlert.error), intl.formatMessage(tipsInfo.noVideo), alertObj);
        }
      }, 50);
    } else {
      clearInterval(videoTimerRef.current);
    }
  }

  const comPreShow = (type: string) => {
    setOpenPreview(true);
    openPreviewRef.current = true;
    if (type === "stage") {
      setVideoOpen(false);
    }
    if (!modalShow) {
      setModalShow(true)
      modalShowRef.current = true;
    };
  }


  // 图片信息预览（是否放大）
  const setImgScale = (imgScaleShow: boolean) => {
    imgScaleTimerRef.current && clearTimeout(imgScaleTimerRef.current);
    if (!imgScaleShow) {
      setImgPreviewScale(imgScaleShow);
      return;
    }
    imgScaleTimerRef.current = setTimeout(() => {
      setImgPreviewScale(imgScaleShow)
    }, 1000);
  }

  // 打开摄像头
  const startVideo = (cam_id?: string) => {
    chooseCameraId.current = cam_id || "";
    popupVideo('on', chooseCameraId.current);
  }
  const closeVideo = () => {
    popupVideo('off');
  }

  // 截图和摄像头交换窗口
  const toggleWindow = () => {
    setTogglePosition(!togglePosition)
    closeVideo()
    startVideo();
  }

  // 隐藏/显示视频
  const toggleVideoShow = () => {
    setIsOpenWithPopup(!isOpenWithPopup);
  }

  // 拖拽弹窗
  const dragWindow = (e) => {
    e.stopPropagation();
    let window_target = e.target;
    //窗口点击置顶
    let lastNode: any = document.getElementsByClassName("higher-level");
    if (lastNode && lastNode.length) {
      lastNode = lastNode[0];
      lastNode.className = lastNode.className.replace(/higher-level| higher-level/g, "");
    }
    let ele = document.getElementById("facePop")
    if (ele) ele.className += " higher-level";
    if (e.target.id != 'facePop') return;


    if (window_target.style.transform != 'translate(0px)') {
      window_target.style.top = (e.target.offsetTop - e.target.offsetHeight / 2) + 'px';
      window_target.style.left = (e.target.offsetLeft - e.target.offsetWidth / 2) + 'px';
    }

    let window_targetW = Math.abs(e.pageX - e.target.offsetLeft);
    let window_targetH = Math.abs(e.pageY - e.target.offsetTop);
    window_target.style.transform = 'translate(0px)';

    window.onmousemove = function (event) {
      let changedX = event.pageX - window_targetW;
      let changedY = event.pageY - window_targetH;
      if (event.pageX < 1 || event.pageX > window.innerWidth || event.pageY < 1 || event.pageY > window.innerHeight) return;
      window_target.style.top = changedY + 'px';
      window_target.style.left = changedX + 'px';
    }

    window.onmouseup = function (event) {
      window.onmousemove = null;
      window.onmouseup = null;
    }
  }

  // 获取视频实例元素
  const getPlayContainer = () => {
    return document.getElementById('cameraPlay') as HTMLCanvasElement;
  }

  // 获取当前一帧画面（base64）
  const getShortCut = () => {
    let ele = document.getElementById('cameraPlay') as HTMLCanvasElement;
    let image = (ele && ele.toDataURL('png')) || "";
    return image.split(',')[1];
  }

  // 截图摄像头动画
  const storageImageAnimation = () => {
    let dom = document.getElementById('cameraPlay');
    setPauseVideo(true)
    dom && (dom.className += ' shortcutAnimation');
    setTimeout(() => {
      dom && (dom.className = dom.className.replace(' shortcutAnimation', ''));
      setPauseVideo(false)
    }, 1500);
  }


  // 更新预览内容
  const updatePreviewContent = (img: string | null, result: string | null) => {
    if (img || img == null) setPreviewImg(img || '');
    if (result || result == null) setPreviewResult(result || '');
  }

  // 修改时间格式
  const formatDateToYYYYMMDDHHMMSS = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }


  useEffect(() => {
    attachRuntime();
    return () => {
      closeModal();
      if (videoTimerRef.current) clearInterval(videoTimerRef.current);
      vm.runtime.detachMethod("pictureAIComponent");
    };
  }, []);

  if (!modalShow) return null;
  return (
    <div id="facePop" className={classNames("pictureAI_facepop", styles.facePop, { [styles.centerPop]: true })} onMouseDown={(e) => dragWindow(e)}>
      <img style={{ maxWidth: '100%' }}
        className={imgPreviewScale ? styles.scaleImg : styles.scaleImg0}
        src={previewImg}
        alt="预览图片"
      />
      <div className={styles.pictureAI_title} >
        {
          modalTitle ? modalTitle : intl.formatMessage(tipsInfo.defaultTitleText)
        }
      </div>
      <div className={styles.right_icon}>
        {openPreview && videoOpen && isOpenWithPopup ?
          <div className={styles.icon} onClick={() => { toggleWindow() }}>
            <img src={toggleIcon} />
          </div>
          : null
        }
        {
          openPreview ?
            <div className={styles.icon}
              style={{ opacity: videoOpen && isOpenWithPopup ? 1 : 0.5 }}
              onClick={() => { toggleVideoShow() }} >
              <img src={hiddenIcon} />
            </div>
            : null
        }
        <div className={styles.icon} onClick={() => { closeModal() }}>
          <img src={closeIcon} />
        </div>
      </div>

      {!togglePosition ?
        <div>
          {openPreview ?
            <div className={styles.section}>
              <div className={styles.modalHead}>
                <span style={{ width: "170px", minHeight: "120px", flex: 1.2 }}
                  onMouseLeave={() => { setImgScale(false) }}
                  onMouseEnter={() => { setImgScale(true) }}>
                  <div id="videoPreview"
                    style={{
                      maxWidth: '100%',
                      height: "100%",
                      backgroundImage: "url(" + previewImg + ")",
                      backgroundSize: "cover"
                    }}
                  >
                  </div>
                  <a className={styles.download}
                    href={previewImg}
                    title="下载/download"
                    download={'mindplus_img_' + formatDateToYYYYMMDDHHMMSS(new Date()) + '.png'}>
                    <img src={downloadIcon} />
                  </a>
                </span>
                <span className={'result-text ' + styles.resultText}
                  dangerouslySetInnerHTML={{ __html: previewResult }}>
                </span>
              </div>
            </div>

            : null
          }
          <div className={`${styles.section}  ${styles.modalBody}`}
            style={{ display: videoOpen && isOpenWithPopup ? 'block' : 'none' }} >
            <Spin
              spinning={loading}
              tip={loadingTips} >
              <canvas id="cameraPlay" width="480" height="360" > </canvas>
            </Spin>
          </div>
        </div>
        :
        <div>
          <div className={styles.section}>
            <div className={styles.modalHead}>
              <div style={{ position: "relative" }}>
                <Spin
                  spinning={loading}
                  tip={loadingTips}>
                  <canvas id="cameraPlay"
                    className="videoPreview"
                    width="480"
                    height="360"
                    style={{ zoom: .333 }}
                  >
                  </canvas>
                </Spin>
              </div>
              <span className={'result-text ' + styles.resultText}
                dangerouslySetInnerHTML={{ __html: previewResult }}>
              </span>
            </div>
          </div>
          <div className={`${styles.section}  ${styles.modalBody}`}
            style={{ display: videoOpen && isOpenWithPopup ? 'block' : 'none' }} >
            {previewImg ?
              <span style={{ textAlign: "center", height: '360px', display: 'block' }} >
                <div id="videoPreview"
                  style={{
                    maxWidth: '100%',
                    height: "100%",
                    backgroundImage: "url(" + previewImg + ")",
                    backgroundSize: "cover"
                  }}
                >
                </div>
                <a className={styles.download}
                  href={previewImg}
                  title="下载/download"
                  download={'mindplus_img_' + formatDateToYYYYMMDDHHMMSS(new Date()) + '.png'} >
                  <img src={downloadIcon} />
                </a>
              </span>
              : null}
          </div>
        </div>
      }
    </div>
  );
};

export default DFCameraModal;
