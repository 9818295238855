import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";


const initialState = {
  active: false,
  callback: (arg) => {
    throw new Error("Color picker callback not initialized");
  },
};

export const colorPickingSlice = createSlice({
  name: "colorPicking",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setColorPickingActive: (state, action: PayloadAction<any>) => {
      state.active = true;
      state.callback = action.payload.callback;
    },
    deactivateColorPicker: (state,action:PayloadAction<any>) => { 
      state.active = false;
      if (typeof action.payload.color === 'string') {
        state.callback(action.payload.color);
      }
    }
  },
});

export const { setColorPickingActive, deactivateColorPicker } =
  colorPickingSlice.actions;
export const selectColorPicking = (state: RootState) => state.colorPicking

export default colorPickingSlice.reducer
