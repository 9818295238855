import {
    closeDeviceLib, closeExtensionLib,
} from "../../../redux/asset-lib/assetLibStatusSlice";
import {useDispatch} from "react-redux";

export const useClose = (isDevice: boolean) => {
    const dispatch = useDispatch();
    if (isDevice) {
        return () => dispatch(closeDeviceLib())
    } else {
        return () => dispatch(closeExtensionLib())
    }
}
