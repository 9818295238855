// @flow
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  selectConnectionModalExtensionId
} from "../../redux/connection-modal-extensionId/connectionModalExtensionIdSlice";
import { closeConnectionModal } from "../../redux/modals/modalsSlice";
import ConnectionModalScratch from "./connection-modal";
import { vm } from "lib/scratch-vm";

type Props = {

};
export const ConnectionModal = (props: Props) => {
  const dispatch = useDispatch()
  const extensionId = useSelector(selectConnectionModalExtensionId)
  const onCancel = () => {
    dispatch(closeConnectionModal())
  }
  console.log('ConnectionModal', extensionId)
  return (
    <ConnectionModalScratch
      extensionId={extensionId}
      vm={vm}
      onCancel={onCancel}
    />
  );
};
