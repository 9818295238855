import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";


const initialState = {
    visible: true,
    alertsList: []
}

export const alertsSlice = createSlice({
    name: 'alerts',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        showExtensionAlert: (state, action: PayloadAction<any>) => {
            // todo:
        },
        closeAlert: (state, action: PayloadAction<any>) => {
            // todo:
        },
        closeAlertsWithId: (state, action: PayloadAction<any>) => {
            // todo:
        },
        closeAlertWithId: (state, action: PayloadAction<any>) => {
            // todo:
        },
        showStandardAlert: (state, action: PayloadAction<any>) => {
            // todo:
        },
        showAlertWithTimeout: (state, action: PayloadAction<any>) => {
            // todo:
        },
    },
})

export const {
    showExtensionAlert,
    closeAlert,
    closeAlertsWithId,
    closeAlertWithId,
    showStandardAlert,
    showAlertWithTimeout,
} = alertsSlice.actions;
export const selectAlerts = (state: RootState) => state.alerts

export default alertsSlice.reducer
