// @flow
import { CompressOutlined, ExpandOutlined } from "@ant-design/icons";
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { selectMode, setFullScreen } from "redux/mode/modeSlice";
import styles from './FullScreenControl.module.scss';
type Props = {

};
const messages = defineMessages({
  fullscreenControl: {
    defaultMessage: 'Stage Size Toggle - Full Screen',
    description: 'Button to change stage size to full screen',
    id: 'gui.gui.stageSizeFull'
  },
});
export const FullScreenControl = (props: Props) => {
    const { isFullScreen } = useSelector(selectMode)
    const intl = useIntl()
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(setFullScreen(!isFullScreen))
    }
    return (
        <div className={styles.container} onClick={onClick} title={intl.formatMessage(messages.fullscreenControl)}>
            {isFullScreen? <CompressOutlined  /> : <ExpandOutlined />}
        </div>
    );
};
