import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "../store";
import decks from 'lib/libraries/decks/index.jsx';

interface CardState {
  visible: boolean;
  content: any;
  activeDeckId: string | null;
  step: number;
  x: number;
  y: number;
  dragging: boolean;
  expanded: boolean
}

const initialState: CardState = {
  expanded: true,
  visible: false,
  content: decks,
  activeDeckId: null,
  step: 0,
  x: 0,
  y: 0,
  dragging: false
}

export const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    toggleCardExpanded: (state) => {
      state.expanded = !state.expanded;
    },
    closeCard: (state) => {
      state.visible = false;
    },
    openCard: (state) => {
      state.visible = true;
    },
    activeDeck: (state, action: PayloadAction<string | null>) => {
      state.activeDeckId = action.payload;
      state.step = 0;
      state.visible = true;
    },
    setNextStep: (state) => {
      if (state.activeDeckId !==null) {
        state.step += 1;
      }
    },
    setPrevStep: (state) => {
      if (state.activeDeckId !== null) {
        if (state.step > 0) {
          state.step -= 1;
        }
      }
    },
    setDragCard: (state, action: PayloadAction<{x: number, y: number}>) => {
      state.x = action.payload.x;
      state.y = action.payload.y;
    },
    setStartDarg: (state) => {
      state.dragging = true;
    },
    endDrag: (state) => {
      state.dragging = false;
    }
  }
})

export const {
  closeCard,
  openCard,
  activeDeck,
  setNextStep,
  setPrevStep,
  setDragCard,
  setStartDarg,
  endDrag,
  toggleCardExpanded
} = cardSlice.actions;

export const selectCard = (state: RootState) => state.card;

export default cardSlice.reducer
