import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl, useIntl } from "react-intl";

import Box from "../box/box.jsx";
import SpriteInfo from "component/sprite-info/sprite-info.jsx";
import SpriteList from "./sprite-list";
import ActionMenu from "component/action-menu/action-menu.jsx";
import { STAGE_DISPLAY_SIZES } from "lib/layout-constants";
import { isRtl } from "scratch-l10n";

import styles from "./sprite-selector.module.scss";

import fileUploadIcon from "../action-menu/icon--file-upload.svg";
import paintIcon from "../action-menu/icon--paint.svg";
import spriteIcon from "../action-menu/icon--sprite.svg";
import surpriseIcon from "../action-menu/icon--surprise.svg";
import searchIcon from "../action-menu/icon--search.svg";

const messages = defineMessages({
  addSpriteFromLibrary: {
    id: "gui.sprite.addSpriteFromLibrary",
    description: "Button to add a sprite in the target pane from library",
    defaultMessage: "Sprite Library",
  },
  addSpriteFromPaint: {
    id: "gui.spriteSelector.addSpriteFromPaint",
    description: "Button to add a sprite in the target pane from paint",
    defaultMessage: "Paint",
  },
  addSpriteFromSurprise: {
    id: "gui.spriteSelector.addSpriteFromSurprise",
    description: "Button to add a random sprite in the target pane",
    defaultMessage: "Surprise",
  },
  addSpriteFromFile: {
    id: "gui.spriteSelector.addSpriteFromFile",
    description: "Button to add a sprite in the target pane from file",
    defaultMessage: "Upload Sprite",
  },
});

const SpriteSelectorComponent = function (props) {
  const {
    editingTarget,
    hoveredTarget,
    onChangeSpriteDirection,
    onChangeSpriteName,
    onChangeSpriteRotationStyle,
    onChangeSpriteSize,
    onChangeSpriteVisibility,
    onChangeSpriteX,
    onChangeSpriteY,
    onDrop,
    onDeleteSprite,
    onDuplicateSprite,
    onExportSprite,
    onFileUploadClick,
    onNewSpriteClick,
    onPaintSpriteClick,
    onSelectSprite,
    onSpriteUpload,
    onSurpriseSpriteClick,
    raised,
    selectedId,
    spriteFileInput,
    sprites,
    stageSize,
    ...componentProps
  } = props;
  const intl = useIntl();

  let selectedSprite = sprites[selectedId];
  let spriteInfoDisabled = false;
  if (typeof selectedSprite === "undefined") {
    selectedSprite = {};
    spriteInfoDisabled = true;
  }
  return (
    <Box className={styles.spriteSelector} {...componentProps}>
      <SpriteInfo
        direction={selectedSprite.direction}
        disabled={spriteInfoDisabled}
        name={selectedSprite.name}
        rotationStyle={selectedSprite.rotationStyle}
        size={selectedSprite.size}
        stageSize={stageSize}
        visible={selectedSprite.visible}
        x={selectedSprite.x}
        y={selectedSprite.y}
        onChangeDirection={onChangeSpriteDirection}
        onChangeName={onChangeSpriteName}
        onChangeRotationStyle={onChangeSpriteRotationStyle}
        onChangeSize={onChangeSpriteSize}
        onChangeVisibility={onChangeSpriteVisibility}
        onChangeX={onChangeSpriteX}
        onChangeY={onChangeSpriteY}
      />

      <SpriteList
        editingTarget={editingTarget}
        hoveredTarget={hoveredTarget}
        items={Object.keys(sprites).map((id) => sprites[id])}
        raised={raised}
        selectedId={selectedId}
        onDeleteSprite={onDeleteSprite}
        onDrop={onDrop}
        onDuplicateSprite={onDuplicateSprite}
        onExportSprite={onExportSprite}
        onSelectSprite={onSelectSprite}
      />
      <ActionMenu
        className={styles.addButton}
        img={spriteIcon}
        moreButtons={[
          {
            title: intl.formatMessage(messages.addSpriteFromFile),
            img: fileUploadIcon,
            onClick: onFileUploadClick,
            fileAccept:
              ".svg, .png, .bmp, .jpg, .jpeg, .sprite2, .sprite3, .gif",
            fileChange: onSpriteUpload,
            fileInput: spriteFileInput,
            fileMultiple: true,
          },
          {
            title: intl.formatMessage(messages.addSpriteFromSurprise),
            img: surpriseIcon,
            onClick: onSurpriseSpriteClick, // TODO need real function for this
          },
          {
            title: intl.formatMessage(messages.addSpriteFromPaint),
            img: paintIcon,
            onClick: onPaintSpriteClick, // TODO need real function for this
          },
          {
            title: intl.formatMessage(messages.addSpriteFromLibrary),
            img: searchIcon,
            onClick: onNewSpriteClick,
          },
        ]}
        title={intl.formatMessage(messages.addSpriteFromLibrary)}
        tooltipPlace={isRtl(intl.locale) ? "right" : "left"}
        onClick={onNewSpriteClick}
      />
    </Box>
  );
};

export default SpriteSelectorComponent;
